import React from 'react';
import './Canyonlands.css';

export default function Canyonlands(props) {
 
    return (
        <div className={`canyonlands-container ${props.size}`}>
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id}>
            <g className="canyonlands-image">
        
            <path onClick={() => props.onFill(0)} fill={props.fillColors[0]} d="M1399.5,206c0,45,0,90,0,135c-3.46,0.2-6.93,0.34-10.39,0.62c-5.77,0.46-11.53,1.01-17.3,1.49
                c-3.09,0.26-6.19,0.66-9.28,0.61c-4.81-0.09-9.61-0.78-14.41-0.73c-10.64,0.11-21.29,0.95-31.9,0.6
                c-3.92-0.13-7.09,0.03-10.12,2.51c-6.75,5.52-14.35,6.84-22.96,5.54c-6.02-0.91-12.28-0.67-18.4-0.32
                c-3.2,0.19-4.89-0.72-6.34-3.4c-4.87-9.06-9.79-17.99-11.75-28.35c-0.7-3.7-1.4-6.61,1.39-9.61c2.55-2.74,2.21-5.75-0.98-7.58
                c-4.96-2.85-10.04-5.64-15.37-7.67c-8.6-3.28-17.45-5.91-26.19-8.82c0.11-0.78,0.21-1.57,0.32-2.35
                c12.7-0.66,25.39-1.66,38.09-1.86c7.44-0.12,14.89,1.11,22.34,1.61c5.14,0.35,10.29,0.79,15.43,0.65
                c10.26-0.27,20.64-0.12,29.58-6.77c1.59-1.18,4.52-1.36,6.62-0.94c12.7,2.53,25.32,5.41,37.99,8.08
                c9.1,1.91,17.84-0.29,26.47-2.82c1.11-0.32,2.63-2.34,2.48-3.34c-0.98-6.27-4.67-9.55-11.09-10.33c-3.3-0.4-6.65-0.46-9.94-0.94
                c-4.95-0.72-9.86-1.68-14.79-2.54c-0.02-0.54-0.04-1.07-0.05-1.61c4.45-2.56,8.9-5.13,14.05-8.1c-3.05-0.99-5.91-2.03-8.83-2.84
                c-4.96-1.38-9.92-2.88-14.97-3.85c-6.49-1.24-13.13-3.05-19.61-2.73c-11.23,0.56-22.38,2.58-33.57,3.94
                c-2.29,0.28-4.63,0.25-6.92,0.55c-4.82,0.63-9.63,1.42-14.45,2.06c-2.72,0.36-5.91,1.6-8.11,0.65c-9.57-4.13-19.5-3.65-29.49-3.58
                c-4.46,0.03-9.18,0.27-13.33-1.03c-7.84-2.45-15.21-2.04-23,0.2c-15.16,4.36-30.48,8.19-45.77,12.09c-2,0.51-4.33,0.59-6.33,0.13
                c-7.27-1.68-14.48-3.62-21.67-5.58c-7.56-2.07-15.84-1.68-22.12-7.9c-9.49-9.41-20.39-12.97-33.42-7.11
                c-8.99,4.05-15.68,10.01-20.33,18.82c-2.23,4.21-5.59,8.68-9.62,10.91c-11.26,6.24-19.87,14.31-24.28,26.63
                c-1.81,5.05-4.67,9.36-9.28,12.63c-2.42,1.72-4.29,4.68-5.54,7.47c-3.34,7.41-6.29,15.01-9.22,22.6
                c-1.18,3.05-2.84,4.42-6.32,4.22c-5.13-0.29-10.3,0.07-15.45,0.12c-6.44,0.07-13.17,1.37-19.26-0.06
                c-20.55-4.83-41.35-7.76-62.33-6.75c-22.06,1.05-44.02,3.98-66.03,6.09c-8.09,0.77-16.22,1.21-24.25,2.4
                c-8.51,1.26-17.23,2.35-25.25,5.2c-6.55,2.32-12.62,2.1-19,1.13c-3.12-0.47-6.15-1.53-9.27-1.94c-15.5-1.99-30.94-3.92-46.69-1.83
                c-11.48,1.52-23.12,1.91-34.71,2.39c-10.84,0.45-21.43,5.16-32.52,1.89c-1.29-0.38-3.11,0.01-4.37,0.66
                c-8.17,4.2-16.1,8.95-25.55,9.82c-7.73-1.05-15.44-2.77-23.2-3.04c-20.75-0.7-41.55-0.1-62.26-1.31
                c-18.03-1.06-35.87-4.21-52.7-11.47c-1.36-0.58-3.21,0.03-4.84,0.04c-4.88,0.03-9.86,0.67-14.61-0.11
                c-6.2-1.02-12.19-1.79-18.12,0.75c-4.32,1.84-4.52,3.41-1.33,6.76c1.3,1.36,2.22,3.09,3.65,5.14c-1.95,0-3.11,0.18-4.19-0.03
                c-9.14-1.72-18.38-3.1-27.36-5.45c-4.67-1.22-8.85-4.25-13.3-6.36c-3.7-1.76-7.36-3.76-11.25-4.94
                c-4.59-1.39-9.37-2.33-14.14-2.89c-6.39-0.76-12.84-0.97-19.27-1.41c-1.97-0.14-4.11,0.19-5.9-0.46
                c-7.19-2.59-14.28-5.44-21.38-8.27c-8.38-3.34-15.95-0.86-23.23,3.25c-2.22,1.25-3.06,3.35-0.61,5.2
                c6.19,4.68,12.37,9.41,18.88,13.59c1.32,0.85,4.13-0.63,6.25-1.04c2.53,1.57,5.06,3.14,7.59,4.7c-8.97,2.6-16.92,1.17-25.02-1.32
                c-10.92-3.37-22.12-5.84-33.21-8.64c-5.63-1.42-11.51-2.15-16.9-4.21c-10.24-3.9-20.09-8.81-30.3-12.78
                c-9.86-3.83-19.9-7.25-29.99-10.4c-6.78-2.12-13.81-3.44-20.72-5.13c-4.36-1.07-8.65-2.5-13.07-3.16
                c-7.18-1.07-14.44-1.61-21.66-2.44c-9.84-1.13-19.54-0.83-27.47,5.92c-4.88,4.15-8.94,9.28-13.29,14.04
                c-2.83,3.09-2.13,6.14,1.33,7.76c3.99,1.87,8.37,2.95,12.62,4.22c3.96,1.19,8.77,1.18,11.78,3.53c4.26,3.34,8.34,3.21,13.07,2.92
                c3.38-0.2,6.92,0.18,10.22,1c4.56,1.14,7.94,5.09,13.92,3.02c3.89-1.34,9.08,0.96,13.66,1.76c18.18,3.2,36.37,6.28,54.5,9.75
                c12.21,2.34,24.71,4.11,36.37,8.19c21.29,7.46,42.02,16.49,63.03,24.75c5.05,1.98,10.31,3.43,15.44,5.19
                c5.31,1.83,10.47,4.17,15.89,5.53c10.59,2.66,21.3,4.82,31.97,7.13c5.83,1.26,11.7,2.35,17.52,3.63c4.68,1.03,9.5,1.75,13.93,3.46
                c7.7,2.96,15.39,5.23,23.77,4.75c2.26-0.13,4.56,0.7,6.85,1.04c6.24,0.93,12.45,2.25,18.72,2.68c14.76,1.01,29.57,1.28,44.32,2.43
                c15.74,1.23,31.42,3.35,47.16,4.63c8.59,0.69,17.26,0.21,25.87,0.67c8.67,0.46,17.12,0.92,25.39-2.97
                c3.73-1.75,8.27-1.81,13.57-2.85c-1.56,3.19-2.82,5.15-3.5,7.29c-0.95,2.97-1.87,6.04-2.08,9.12c-0.32,4.72,0.32,9.51-0.08,14.22
                c-0.64,7.35-1.54,8.07-8.84,9.27c-1.8,0.29-3.67,0.49-5.47,0.34c-21.97-1.85-43.77,0.49-65.46,3.29
                c-12.36,1.59-24.33,0.52-36.16-2.14c-12.48-2.81-24.34-0.77-35.69,3.55c-8.39,3.19-15.94,5.94-24.74,1.53
                c-3.58-1.79-8.08-1.72-12.16-2.53c-3.42-0.68-7.13-0.77-10.19-2.21c-15.3-7.19-31.93-6.04-48.02-7.45
                c-11.84-1.04-23.92,0.61-35.88,1.2c-15.79,0.78-31.6,1.98-45.96-6.85c-0.96-0.59-2.2-0.81-3.34-0.98
                c-4.91-0.73-10.01-0.86-13.19-5.76c-0.46-0.7-2.13-0.61-3.25-0.89c-14.02-3.44-28.18-6.42-42.03-10.47
                c-11.59-3.38-23.06-6.03-35.26-5.51c-16.61,0.7-33.24,0.95-49.86,1.13c-6.46,0.07-10.69-3.92-12.4-10.25
                c-0.3-1.11-1.82-2.56-2.89-2.67c-7.73-0.77-15.49-1.61-23.25-1.72c-29.89-0.44-59.39,6.72-89.37,4.63
                c-0.16-0.01-0.33,0.05-0.49,0.08c0-125.33,0-250.67,0-376c1.62,0.35,3.24,0.68,4.84,1.06c9.95,2.38,19.58,5.12,30.26,2.21
                c14.36-3.91,29.24-4.11,44.04-1.95c7.57,1.11,15.09,2.85,22.69,3.28c10.59,0.6,21.25-0.02,31.86,0.38
                c10.94,0.41,21.87,1.33,32.79,2.17c5.08,0.39,10.15,0.99,15.2,1.68c4.59,0.63,9.14,1.58,13.72,2.25
                c8.05,1.17,16.45,1.16,24.07,3.62c9.14,2.94,18.2,3.54,27.54,3.46c7.32-0.06,14.65-0.16,21.96,0.11
                c11.29,0.42,22.66,2.3,33.85,1.48c24.43-1.8,47.65,1.62,69.96,11.75c3.89,1.77,8.17,3.09,12.39,3.64
                c10.04,1.32,20.18,1.82,30.22,3.11c11.18,1.44,22.53,2.45,33.36,5.34c13.61,3.63,26.55,9.73,41.14,9.08
                c4.79-0.21,9.63,0.46,14.42,0.97c13.37,1.43,26.7,3.37,40.1,4.41c15.36,1.19,30.7,3.26,46.13-0.66
                c10.83-2.75,21.89-3.16,33.07-0.24c4.96,1.3,10.54,0.64,15.8,0.25c6.74-0.51,13.08-0.22,19.53,2.47
                c5.43,2.26,11.4,3.38,17.24,4.46c8.33,1.53,16.75,2.58,25.13,3.8c6.41,0.93,12.84,2.59,19.26,2.54
                c26.76-0.2,52.49,5.33,78.03,12.57c5.86,1.66,12.12,1.85,18.12,3.1c7.78,1.62,16.48-1.71,23.46,4.41c0.63,0.55,2.25,0.01,3.4-0.09
                c2.65-0.23,5.35-1,7.93-0.69c17.64,2.13,35.42,3.59,52.81,7.02c20.05,3.95,39.62,10.26,59.62,14.47
                c15.27,3.21,30.85,5.02,46.35,6.99c20.94,2.65,41.93,4.89,62.9,7.24c9.14,1.02,18.64-0.28,26.9,5.68
                c2.53,1.82,6.28,1.95,9.48,2.84c6.08,1.7,12.5,2.67,18.18,5.25c8.97,4.08,18.27,5.81,27.9,7.03c7.9,1,15.8,2.45,23.47,4.56
                c5.78,1.6,11.21,1.98,17.16,1.18c5.63-0.76,11.47,0.18,17.23,0.31c5.38,0.12,10.78,0.39,16.15,0.16
                c9.41-0.4,18.81-1.39,28.22-1.65c7.95-0.22,15.92,0.52,23.87,0.33c11.94-0.28,23.77-1.11,35.82,0.34c11.5,1.38,23.23,5.28,35,0.84
                c0.79-0.3,1.93,0.37,2.91,0.49c8.06,1.01,16.12,2.18,24.2,2.94C1386.61,205.6,1393.06,205.69,1399.5,206z M930.75,271.34
                c-3.09-0.39-5.34-0.83-7.61-0.92c-6.97-0.26-13.98,0.02-20.9-0.69c-5.91-0.61-11.74-2.16-17.55-3.53
                c-7.85-1.85-12.23,0.76-14.15,8.23c-0.3,1.16-2.54,2.58-3.88,2.58c-3.81-0.02-7.62-0.62-11.41-1.18
                c-18.23-2.71-36.45-5.46-54.66-8.25c-1.44-0.22-3.8-0.47-4.09-1.28c-2.24-6.48-7.4-4.24-11.65-4.05
                c-22.65,1.06-45.29,0.93-67.63-3.14c-13.86-2.53-27.62-6.01-41.13-10.06c-24.16-7.26-49.29-9.18-73.83-14.33
                c-9.88-2.07-20.13-2.35-30.14-3.88c-7.38-1.13-14.61-3.26-21.99-4.39c-9.49-1.46-19.04-2.7-28.61-3.35
                c-3.37-0.23-7.12,1.05-10.2,2.62c-1.25,0.64-2.2,4.32-1.54,5.86c4.34,10.06,11.02,18.09,21.36,22.67
                c2.05,0.91,3.66,2.81,5.47,4.25c-1.86,1.2-3.13,1.43-4.36,1.3c-13.06-1.39-26.1-2.96-39.17-4.23c-16.54-1.6-33.09-3.28-49.68-4.33
                c-20.14-1.27-40.22,0.08-60.15,3.24c-1.75,0.28-3.39,1.23-5.08,1.86c-0.03,0.64-0.05,1.27-0.08,1.91
                c8.98,3.24,17.86,6.79,26.97,9.64c23.79,7.45,47.59,14.86,71.54,21.76c15.8,4.55,31.68,9.51,47.87,11.91
                c23.92,3.54,48.07,3.59,71.72-3.34c1.65-0.48,3.7,0.34,5.56,0.6c2.66,0.38,5.74,1.91,7.89,1.05c12.84-5.13,26.16-5.89,39.74-5.81
                c13.29,0.09,26.64-1.24,39.86-0.37c18.55,1.21,36.97,4.29,55.51,5.81c20.35,1.67,40.72,2.7,60.69-3.93
                c7.94-2.64,16.3-3.53,23.55,2.83c0.79,0.69,2.53,0.59,3.77,0.44c8.4-1.02,16.78-2.29,25.19-3.22c11.71-1.29,23.5-3.67,35.15-3.19
                c14.6,0.6,28.81,0.01,43.04-3.24c4.45-1.01,6.26-3.59,7.58-7.13c-5.29-2.19-10.28-4.25-15.52-6.42
                C929.08,274.19,929.64,273.23,930.75,271.34z M292.54,243.9c3.08-8.58,6.16-16.91,9.02-25.31c0.56-1.64,0.53-3.59,0.35-5.35
                c-1.33-13.08-8.52-22.47-19.39-29.04c-3.84-2.32-8.48-3.75-12.94-4.46c-4.53-0.72-9.3,0.29-13.92-0.06
                c-7.15-0.53-13.07,1.84-18.16,6.6c-8.39,7.85-14.52,16.85-13.82,29.04c0.27,4.69,0.75,9.37,1.16,14.46
                c-2.45-0.99-4.74-1.91-7.02-2.86c-2.23-0.93-4.45-1.9-6.67-2.85c-0.13,0.44-0.25,0.87-0.38,1.31c27.71,13,56.89,19.72,87.64,19.71
                C296.38,244.68,294.36,244.27,292.54,243.9z"/>
            <path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M1399.5,909c0,25.67,0,51.33,0,77c-5.17,3.31-4.13,9.28-6,14c-464.34,0-928.67,0-1393.01,0
                c0-16.67,0-33.33,0-50c5.93,4.08,8.49,10.35,11.22,16.65c2.63,6.07,5.92,11.87,8.92,17.78c0.85-0.19,1.71-0.38,2.56-0.57
                c0.11-1.86,0.61-3.8,0.21-5.54c-0.43-1.88-1.67-3.6-2.63-5.34c-1.54-2.8-3.36-5.49,0.04-8.38c0.64-0.54,0.9-2.44,0.46-3.2
                c-3.41-5.87-2.28-12.18-2.44-18.48c-0.3-11.78-1.21-23.54-1.98-35.3c-0.09-1.4-0.91-2.74-1.39-4.11c-0.64,1.49-1.9,3.01-1.82,4.45
                c0.38,6.64,1.48,13.25,1.66,19.89c0.24,8.61-0.15,17.23-0.29,26.72c-1.69-1.08-2.13-1.2-2.19-1.43
                c-3.5-12.76-6.94-25.54-10.47-38.3c-0.37-1.35-1.24-2.56-1.88-3.84c0-9.33,0-18.67,0-28c10.05,0.78,17.95,9.4,28.65,7.91
                c0.7-0.1,1.61,0.48,2.24,0.98c3.72,2.91,7.21,2.62,10.88-0.24c3.54-2.76,7.02-3.07,10.72,0.33c3.83,3.52,8.1,2.93,11.77-0.95
                c3.71-3.92,8.63-4.75,12.79-1.46c3.61,2.85,7.08,3.01,11.17,1.6c2.14-0.74,4.53-1.09,6.8-1.07c4.28,0.04,8.79,1.44,12.78,0.49
                c4.9-1.16,8.37,0.18,12,2.85c2,1.47,4.21,2.66,6.31,4.01c2.37,1.53,4.04,1.19,5.93-1.14c1.72-2.12,4.25-3.58,6.41-5.33
                c2.46-2,5.42-3.63,7.27-6.09c2.65-3.52,5.28-5.24,9.1-2.27c4.49,3.5,8.12,2.75,11.56-1.5c1.86-2.31,4.12-3.3,6.89-1.12
                c3.86,3.04,8.09,4.51,13.04,2.91c0.71-0.23,1.92-0.17,2.4,0.29c4.5,4.29,12.19,4.42,14.55,11.67c1,3.06,3.62,5.54,5.04,8.51
                c0.86,1.8,1.34,4.11,1.09,6.06c-0.67,5.06,0.49,7.41,5.32,9.12c5.73,2.02,9.45,0.5,12.1-4.95c3.06-6.3,5.32-6.88,11.19-3.33
                c4.26,2.57,8.67,4.89,13.13,7.1c1.06,0.53,3.03,0.75,3.77,0.14c5.64-4.71,11.86-5.22,18.65-3.4c1.01,0.27,2.26-0.44,3.42-0.55
                c1.47-0.15,3.04-0.5,4.42-0.16c8.05,2,15.97,4.62,24.1,6.11c3.66,0.67,8.11,0.05,11.48-1.52c4.24-1.98,8.26-3.1,12.39-1.17
                c3.98,1.86,7.81,4.28,11.25,7.03c3.65,2.93,7.01,3.71,11.47,1.92c3.54-1.42,7.54-1.66,11.26-2.7c3.04-0.85,6.05-1.94,8.91-3.27
                c2.78-1.3,5.6-4.65,7.99-4.29c8.58,1.29,16.63,1.16,24.19-3.53c0.13-0.08,0.34-0.08,0.49-0.04c5.72,1.53,10.74-0.66,15.79-2.85
                c1.31-0.57,2.82-0.69,5.42-1.28c-1.61,9.64-3.09,18.49-4.57,27.34c0.53,0.28,1.06,0.57,1.59,0.85c1-1.38,2.57-2.63,2.88-4.15
                c0.93-4.55,1.35-9.21,2.01-13.83c1.45-10.15,3.02-10.78,11.71-5.37c3.03,1.89,6.81,4.95,9.6,4.37c6.62-1.38,14.18,0.47,19.89-4.91
                c0.9-0.84,3.1-1.27,4.19-0.77c4.58,2.12,8.88,2.29,13.46-0.05c4.18-2.14,8.53-4.02,12.94-5.63c2.44-0.89,5.48-1.98,7.69-1.26
                c5.02,1.63,9.42,1.61,13.58-1.01c2.96,1.12,5.94,1.43,8.91,1.85c1.87,0.27,4.51,0.07,5.42,1.2c3.73,4.64,5.86,2.3,8.48-1.07
                c3.17-4.07,6.52-7.89,12.38-8.44c1.25-0.12,3.02-2.86,3.22-4.55c0.97-8.31,2.06-9.63,10.45-9.61c3.12,0.01,6.77-0.14,9.23,1.35
                c5.34,3.22,8.25-0.8,10.72-3.44c3.91-4.16,4.83-4.77,9.86-1.58c1.46,0.93,3.36,1.9,4.95,1.74c3.56-0.36,7.34-0.82,10.52-2.32
                c5.98-2.83,9.96,3.57,15.34,2.65c2.5,6.37,8.22,3.96,12.83,4.91c6.48,1.34,13.47,2.46,14.3,11.32c0.09,1,1.49,2.38,2.55,2.7
                c8.94,2.67,17.94,5.14,26.94,7.62c0.77,0.21,1.66-0.03,2.49,0.04c4.91,0.42,9.81,0.86,14.72,1.3c-0.25,0.37-0.5,0.74-0.74,1.11
                c1.28,1.28,2.8,3.84,3.8,3.65c10.29-1.95,18.35,2.91,26.58,7.78c1.98,1.17,5.08,2.1,7,1.38c4.87-1.82,9.27-1.03,14.06,0.01
                c5.49,1.18,11.34,0.8,16.73,2.23c5.26,1.4,10.07,3.03,15.4,0.35c1.36-0.68,3.53,0.48,5.28,0.3c1.98-0.2,4.77-0.31,5.7-1.57
                c5.48-7.47,12.97-8.53,21.26-7.45c3.63,0.47,6.52,0.43,9.1-3.2c1.4-1.97,5.77-3.97,7.57-3.17c10.38,4.61,20.24,0.79,30.08-1.23
                c4.17-0.85,6.96-0.36,9.8,2.44c2.72,2.68,5.45,5.39,8.46,7.73c5.32,4.13,11.34,1.96,17.27,1.86c11.91-0.2,23.83,0.36,35.74,0.89
                c6.83,0.3,12.85,2.95,18.25,7.25c1.29,1.03,3.54,1.42,5.26,1.25c5.56-0.55,11.05-1.87,16.61-2.19c4.18-0.24,7.68-0.86,10.74-4.12
                c4.34-4.63,9.45-7.68,16.39-6.54c1.61,0.26,4.4-1.23,5.24-2.71c3.05-5.4,7.21-8.46,13.46-8.94c5.36-0.41,10.31-3.77,16.17-0.13
                c2.96,1.84,7.78,1.87,11.46,1.17c9.86-1.87,19.43-5.27,29.69-1.54c2.56,0.93,5.95-0.71,8.87-0.37c4.73,0.55,9.34,2.28,14.06,2.59
                c13.4,0.86,26.56-0.17,39.03-5.99c2.21-1.03,5.73-1.72,7.57-0.68c9.41,5.37,19.55,5.91,29.94,6c3.38,0.03,6.87,0.78,10.11,1.84
                c4,1.31,6.96,1,9.5-2.75c3.4-5.02,8.47-6.38,14.08-4.75c4.62,1.35,9.3,2.91,13.47,5.23c3.49,1.94,6,2.32,9.46-0.03
                c4.15-2.81,8.86-2.47,12.25,1.41c3.16,3.61,5.68,3.27,9.35,0.81c7.88-5.27,12.01-4.37,17.8,3.1c0.71,0.92,1.41,1.86,2.05,2.83
                c1.8,2.78,4.17,3.08,6.89,1.54c1.88-1.07,3.57-2.87,5.55-3.27c3.07-0.62,7.35-1.67,9.32-0.17c5.56,4.24,9.82,2.89,14.93-0.45
                c2.1-1.37,5.41-0.78,8.12-1.33c1.62-0.32,4.11-0.72,4.54-1.77c1.84-4.5,4.91-3.4,8.25-2.63c5.74,1.31,11.33,3.92,17.48,2.25
                c4.8-1.3,9.12,0,13.39,2.3c7.84,4.23,15.66,4.91,23.69-0.04C1393.37,909.88,1396.63,909.75,1399.5,909z M1317.48,990.13
                c2.12,3.12,3.92,1.9,5.91-0.28c4.25-4.65,8.61-9.21,12.97-13.76c2.64-2.75,5.48-5.33,7.96-8.21c0.6-0.7,0.16-2.31,0.19-3.5
                c-1.14,0.33-2.64,0.3-3.36,1.04c-7.98,8.2-15.83,16.52-23.72,24.81c2.14-12.99,7.1-24.74,15-35.31c0.88-1.18,0.4-3.39,0.55-5.12
                c-1.46,0.49-3.78,0.55-4.25,1.52c-5.63,11.46-11.07,23.01-16.31,34.66c-0.67,1.48,0.08,3.61,0.17,5.44
                C1314.21,990.99,1315.84,990.57,1317.48,990.13z M238.27,969.87l0.07,0.07c2.27-9.69,4.61-19.36,6.77-29.07
                c0.64-2.87,0.75-5.87,1.11-8.8c-0.84-0.17-1.67-0.35-2.51-0.52c-3.12,11.25-6.25,22.51-9.45,34.06c-1.74-1.6-2.88-2.64-4.01-3.68
                c-0.41,0.16-0.83,0.31-1.24,0.47c0.81,7.27,1.62,14.54,2.45,22.09c1.5-0.41,2.83-0.76,3.72-1c0.49,1.24,0.88,2.23,1.28,3.21
                c1.13-0.53,2.55-0.78,3.33-1.63c4.16-4.53,8.42-9,12.15-13.88c4.38-5.73,8.26-11.84,12.36-17.79c-6.43,1.11-8.02,6.93-11.39,10.97
                c-3.53,4.23-7.04,8.48-10.55,12.71c-0.51-0.34-1.02-0.68-1.53-1.02c3.71-12.65,12.52-23.58,13.36-37.25
                c-1.59,1.16-2.29,2.67-3.01,4.17C246.86,951.95,242.56,960.91,238.27,969.87z M180.19,908.52c-4.4,3.45-4.7,8.9-6.62,13.47
                c-1.99,4.75-3.57,9.69-5.1,14.62c-1.53,4.93-2.78,9.96-4.15,14.94c-0.41-0.05-0.82-0.1-1.23-0.16c0-5.94-0.57-11.96,0.13-17.82
                c1.06-8.89,3.1-17.66,4.48-26.52c0.22-1.41-0.81-3.02-1.26-4.53c-0.83,1.19-1.85,2.3-2.44,3.61c-0.46,1.02-0.36,2.29-0.57,3.43
                c-1.6,8.68-3.23,17.35-4.84,26.02c-0.29,0.01-0.57,0.01-0.86,0.02c-1.49-6.12-2.98-12.25-4.47-18.37
                c-0.71,0.19-1.42,0.37-2.13,0.56c3.91,21.76,7.81,43.52,11.74,65.38c4.44-2.8,19.24-37.8,17.06-42.85
                c-3.98,8.5-7.53,16.1-11.4,24.36C167.18,944.33,176.73,927.15,180.19,908.52z M488.08,975.49c-0.59-0.97-0.99-1.35-1.03-1.76
                c-0.09-0.99-0.18-2.03,0.04-2.97c2.93-12.44,5.74-24.91,8.98-37.27c1.17-4.46,3.56-8.59,5.03-13c0.3-0.89-1.05-2.32-1.63-3.5
                c-0.89,0.79-2.25,1.42-2.59,2.4c-2.67,7.69-5.71,15.31-7.56,23.21c-3.25,13.91-5.77,27.99-8.48,42.03
                c-0.22,1.13,0.49,2.43,0.76,3.65c1.06-0.53,2.1-1.08,3.17-1.59c1.66-0.79,3.29-1.67,5-2.32c3.15-1.19,6.73-1.66,9.46-3.46
                c8.15-5.38,15.98-11.25,23.93-16.93c1.08-0.77,2.17-1.51,3.26-2.27c-0.37-0.63-0.74-1.26-1.11-1.89
                c-7.32,4.17-14.64,8.33-21.96,12.5c-0.21-0.3-0.42-0.59-0.63-0.89c10.03-8.64,20.06-17.28,30.09-25.92c-0.5-0.59-1-1.18-1.5-1.76
                c-12.53,10.48-25.07,20.95-38.19,31.93c3.44-7.54,6.32-14.74,9.93-21.55c3.58-6.73,7.94-13.04,11.84-19.61
                c0.47-0.79,0.18-2.02,0.25-3.05c-1.14,0.2-2.37,0.2-3.37,0.69c-0.62,0.31-0.88,1.36-1.27,2.09
                c-6.75,12.61-13.48,25.23-20.24,37.83C489.7,973.14,488.97,974.12,488.08,975.49z M866.2,962.63c-0.26,0.05-0.52,0.09-0.78,0.14
                c-0.52-1.02-1.1-2.02-1.56-3.08c-1.73-3.97-3.26-8.03-5.23-11.87c-0.6-1.16-2.4-1.69-3.66-2.51c-0.03,1.57-0.54,3.34,0.01,4.67
                c1.17,2.9,3.13,5.48,4.33,8.37c3.85,9.21,7.45,18.52,11.25,27.75c1.01,2.45,2.24,4.83,3.61,7.1c0.39,0.64,1.61,0.78,2.45,1.16
                c0.26-0.84,1.01-2.05,0.7-2.46c-2.29-3.07-0.8-5.45,0.87-8.12c1.82-2.91,3.44-6.01,4.62-9.22c0.54-1.46,0.04-3.43-0.35-5.09
                c-0.34-1.43-1.95-2.9-1.69-4.06c1.48-6.63,3.43-13.15,5.04-19.75c0.82-3.37,1.39-6.82,1.73-10.26c0.07-0.73-1.32-1.6-2.03-2.41
                c-0.69,0.74-1.83,1.38-2.01,2.23c-2.82,13.28-5.51,26.59-8.26,39.89c-0.04,0.22-0.48,0.35-1.17,0.81
                c-2.55-8.56-5.24-16.85-4.27-25.88c0.13-1.22-1.1-2.59-1.7-3.89c-0.85,1.21-2.39,2.4-2.42,3.63
                C865.59,954.06,866,958.35,866.2,962.63z M1293.72,946.17c-0.98-4.05-1.5-7.37-2.67-10.45c-0.45-1.18-2.35-1.82-3.59-2.7
                c-0.66,1.16-1.89,2.33-1.9,3.49c0,6.31,0.23,12.63,0.51,18.94c0.19,4.35,0.58,8.7,0.97,14.28c-1.26-1.63-1.55-2.32-2.06-2.61
                c-1.62-0.94-3.32-1.73-4.99-2.57c-0.4,1.67-1.03,3.32-1.16,5.02c-0.19,2.72-0.05,5.46-0.05,9.28c-1.24-1.93-1.7-3.27-2.64-3.97
                c-1.23-0.92-2.82-1.33-4.26-1.96c-0.27,1.46-0.99,3.01-0.72,4.36c1.27,6.21,2.82,12.36,4.42,19.17c1.67-1.35,3.35-2.7,4.76-3.83
                c1.88,0.37,3.7,0.72,5.52,1.08c0-1.88,0.23-3.79-0.04-5.63c-0.73-5-1.66-9.97-2.51-14.95c0.64-0.23,1.28-0.46,1.92-0.69
                c0.72,1.33,1.85,2.59,2.06,4c0.31,2.1-0.37,4.37,0.01,6.45c0.33,1.79,1.55,3.41,2.38,5.11c0.89-1.73,2.55-3.48,2.51-5.19
                c-0.13-5.8-0.82-11.59-1.36-17.38c-0.6-6.5-1.26-12.99-1.91-19.67C1291.02,945.93,1292.09,946.03,1293.72,946.17z M309.66,927.62
                c-0.53,0.31-1.05,0.62-1.58,0.93c3.11,8.03,6.18,16.08,9.34,24.09c3.25,8.21,6.6,16.38,10.06,24.96
                c-5.24-2.11-7.69-9.36-14.59-7.31c2.39,2.93,4.95,5.54,7.96,7.41c3.1,1.93,6.65,3.15,10.01,4.65c0.9,0.4,1.85,0.68,2.77,1.01
                c1.38,0.5,2.75,1.01,4.13,1.52c0.29-1.7,1.18-3.56,0.76-5.06c-1.89-6.83-4.18-13.55-6.28-20.32c-3.19-10.3-6.28-20.62-9.58-30.88
                c-0.35-1.1-1.8-1.85-2.74-2.76c-0.29,1.25-1.08,2.64-0.78,3.72c2.75,9.57,5.68,19.1,8.58,28.63c1.43,4.7,2.93,9.38,4.4,14.08
                c-2.06-1.3-3.3-2.99-4.12-4.87c-5.16-11.88-10.19-23.81-15.38-35.68C312,930.22,310.68,928.99,309.66,927.62z M779.97,974.03
                c-0.14,0.03-0.27,0.05-0.41,0.08c-1.36-5.47-2.98-10.89-4-16.42c-0.59-3.21-0.34-6.6-0.28-9.9c0.07-4.01,0.5-8.01,0.41-12.01
                c-0.02-0.94-1.51-1.85-2.32-2.77c-0.76,0.96-1.77,1.83-2.21,2.92c-0.41,1.03-0.2,2.3-0.27,3.46c-1.03,15.91,3.84,30.93,6.96,46.22
                c0.29,1.4,1.72,2.57,3.45,5.01c0.51-2.76,0.37-4.13,0.99-4.84c0.76-0.86,2.71-1.93,3.2-1.58c3.05,2.2,3.84,0.61,4.89-2.07
                c2.6-6.62,5.64-13.07,8.22-19.7c0.8-2.06,0.68-4.48,0.98-6.74c-1.58,1.45-3.76,2.62-4.64,4.42c-2.94,6.04-5.41,12.31-8.06,18.5
                c-0.43-0.05-0.86-0.1-1.3-0.15c-0.61-7.74-1.54-15.48-1.69-23.22c-0.06-3.22,1.69-6.45,2.26-9.74c0.2-1.16-0.66-2.5-1.04-3.76
                c-1.19,0.54-3.2,0.86-3.43,1.67c-0.88,3.17-1.54,6.48-1.65,9.76C779.8,960.09,779.97,967.06,779.97,974.03z M679.17,969.02
                c-0.45-1.56-0.72-1.95-0.61-2.16c6.98-13.74,14.06-27.44,20.92-41.24c0.86-1.72,0.58-4.01,0.83-6.04
                c-1.59,0.88-3.97,1.36-4.65,2.7c-8.07,16.04-15.9,32.2-23.78,48.34c-1.24,2.54-2.64,5.02-3.54,7.68
                c-0.32,0.92,0.32,2.89,1.08,3.27c0.84,0.43,2.53-0.12,3.48-0.78c1.28-0.9,2.1-2.43,3.31-3.46c2.59-2.19,5.74-3.86,7.88-6.39
                c8.57-10.15,16.86-20.54,25.18-30.9c0.9-1.12,1.3-2.65,1.93-3.99c-1.7,0.41-3.95,0.28-5.01,1.33c-4.12,4.1-7.96,8.49-11.72,12.93
                C689.47,956.24,684.64,962.32,679.17,969.02z M380.19,972.16c-0.37,0-0.74-0.01-1.12-0.01c0-1.12-0.01-2.25,0-3.37
                c0.05-8.29,0.21-16.59,0.09-24.88c-0.03-1.97-0.99-3.92-1.52-5.88c-0.67,0.09-1.35,0.18-2.02,0.27
                c-0.57,13.25-1.15,26.51-1.72,39.76c-0.53,0.04-1.07,0.08-1.6,0.12c-0.99-2.23-1.86-4.53-3.03-6.66
                c-0.37-0.67-1.69-1.37-2.31-1.18c-0.68,0.2-1.68,1.7-1.48,2.15c2.16,4.82,3.85,10.11,7.14,14.04c1.59,1.9,6.3,1.52,9.6,1.56
                c4.4,0.05,7.39-4.26,5.18-10.02c-1.08,2.17-2.15,4.35-3.23,6.52c-0.57-0.38-1.14-0.77-1.72-1.15c2.19-11.14,4.39-22.29,6.58-33.43
                c-0.67-0.18-1.34-0.37-2.01-0.55C384.74,957.02,382.46,964.59,380.19,972.16z M1366.67,964.98c2.15-3.46,3.82-6.46,5.8-9.25
                c2.15-3.02,4.36-6.07,6.95-8.7c3.6-3.65,7.68-6.83,11.34-10.43c0.79-0.78,0.66-2.5,0.96-3.78c-1.13,0.23-2.5,0.12-3.33,0.74
                c-2.52,1.89-4.81,4.07-7.22,6.11c-2.14,1.8-4.31,3.56-6.47,5.34c-0.75-1.92-0.48-2.98-0.34-4.06c0.23-1.66,0.43-3.33,0.45-4.99
                c0-0.14-1.93-0.66-2.13-0.39c-1.03,1.4-2.12,2.91-2.56,4.55c-0.64,2.41-0.75,4.97-1.11,7.63c-6.33-2.3-10.13-10.46-9.54-17.58
                c0.25-3.07-0.76-6.3-1.53-9.37c-0.19-0.76-1.67-1.19-2.56-1.77c-0.49,0.91-1.53,1.93-1.38,2.72c1.44,7.8,2.76,15.64,4.79,23.29
                c1.1,4.14,3.73,7.57,9.4,5.62C1367.28,955.51,1362.85,959.69,1366.67,964.98z M965.13,964.38c-0.42,0.12-0.85,0.25-1.27,0.37
                c-1.64-3.71-3.15-7.5-5.02-11.1c-0.54-1.04-2.15-1.51-3.28-2.25c-0.32,1.36-1.33,3.09-0.84,4.03
                c5.03,9.72,10.28,19.34,15.56,28.93c0.4,0.72,1.52,1.52,2.22,1.46c0.71-0.06,2.01-1.49,1.88-1.77c-1.72-3.66,1.91-5.13,2.84-7.65
                c0.73-1.97,0.45-4.31,0.62-6.48c-1.56,0.97-3.12,1.94-4.95,3.08c-0.1-0.12-0.75-0.55-0.84-1.07c-1.65-9.42-3.21-18.85-4.87-28.27
                c-0.2-1.13-0.83-2.17-1.27-3.26c-0.78,0.91-1.9,1.71-2.25,2.76c-0.41,1.2-0.25,2.64-0.13,3.95
                C964.03,952.87,964.59,958.62,965.13,964.38z M683.05,924.39c-0.49-0.7-1.26-1.79-2.02-2.88c-1.35,1.39-3.44,2.55-3.94,4.2
                c-3.16,10.5-5.65,21.21-9,31.64c-2.56,7.96-6.08,15.61-9.11,23.41c-0.33,0.84-0.66,2.31-0.27,2.66c0.73,0.64,2.25,1.19,2.99,0.83
                c1.39-0.68,2.98-1.8,3.55-3.14c1.69-3.95,3.03-8.07,4.31-12.18c4.23-13.61,8.37-27.25,12.54-40.88
                C682.39,927.1,682.6,926.13,683.05,924.39z M1236.58,933.74c0.12-0.97,0.43-1.96,0.31-2.91c-0.15-1.24-0.64-2.44-0.98-3.66
                c-0.95,0.98-2.14,1.83-2.79,2.98c-0.53,0.94-0.64,2.26-0.58,3.39c0.34,7.29,0.73,14.57,1.25,21.85c0.08,1.09,0.91,2.12,1.39,3.18
                c0.44-0.01,0.88-0.01,1.32-0.02c3.85-10.17,7.7-20.34,11.96-31.58c-2.36,1.31-3.78,2.1-6.12,3.4c1.86-3.5,3.23-6.09,4.73-8.93
                C1241.91,920.88,1241.91,920.88,1236.58,933.74z M628.54,912.33c-0.73,0.44-1.45,0.89-2.18,1.33c1.2,3.67,2.98,7.27,3.49,11.03
                c2.43,17.71,9.21,33.94,16.38,50.06c0.39,0.89,1.53,1.45,2.32,2.16c0.61-1,1.85-2.1,1.72-2.99c-0.35-2.25-1.25-4.42-2.05-6.59
                c-3.68-9.94-7.66-19.78-11-29.84c-2.14-6.46-3.17-13.28-5.07-19.83C631.59,915.71,629.78,914.1,628.54,912.33z M109.02,971.26
                c-1.32-5.04-2.39-9.51-3.7-13.91c-0.27-0.91-1.43-1.55-2.18-2.31c-0.56,0.68-1.73,1.51-1.6,2.01c1.35,5.29,2.53,10.69,4.67,15.67
                c0.49,1.13,4.52,1.02,6.88,0.86c0.68-0.05,1.62-2.24,1.75-3.52c1.15-11.85,2.2-23.7,3.09-35.57c0.09-1.19-1.09-2.48-1.69-3.72
                c-1.1,1.08-2.88,2.01-3.15,3.27c-0.6,2.71-0.27,5.61-0.59,8.4C111.43,951.77,110.25,961.1,109.02,971.26z M1030.27,934.61
                c0.38-0.04,0.76-0.07,1.13-0.11c-0.2-2.32-0.18-4.68-0.69-6.93c-0.27-1.19-1.46-2.17-2.23-3.24c-0.61,1.16-1.8,2.35-1.75,3.48
                c0.65,14.41,1.35,28.83,2.32,43.22c0.38,5.61,1.27,11.22,2.35,16.74c0.26,1.33,2.2,2.34,3.37,3.49c0.41-1.48,1.32-3.01,1.14-4.41
                c-0.5-3.94-1.77-7.79-2.15-11.74C1032.46,961.63,1031.41,948.11,1030.27,934.61z M1045.98,984.67c1,1.44,1.68,2.41,2.35,3.38
                c0.96-0.97,2.26-1.77,2.84-2.93c3.34-6.66,5.89-13.8,9.87-20.04c7.23-11.36,15.31-22.17,22.89-33.3c0.81-1.2,0.66-3.05,0.95-4.6
                c-1.3,0.76-3.03,1.22-3.82,2.34c-8.95,12.79-17.94,25.57-26.52,38.62C1051.2,973.23,1048.88,978.99,1045.98,984.67z
                M1052.7,932.86c-0.23-0.06-0.46-0.11-0.69-0.17c0.11-0.99,0.54-2.1,0.24-2.95c-0.43-1.2-1.39-2.2-2.13-3.28
                c-0.61,1.15-1.59,2.24-1.77,3.45c-0.59,3.93-0.7,7.93-1.35,11.85c-1.86,11.3-3.87,22.59-5.89,33.86
                c-0.73,4.06-1.73,8.07-2.45,12.13c-0.2,1.11,0.15,2.31,0.25,3.47c1.31-0.4,3.35-0.42,3.79-1.27c1.03-1.99,1.55-4.35,1.82-6.62
                c1.38-11.36,2.38-22.78,4.02-34.1C1049.35,943.69,1051.28,938.31,1052.7,932.86z M1295.95,984.53c0.73,1.13,1.7,2.62,2.66,4.11
                c1.04-1.23,2.79-2.35,3.01-3.71c2.25-14.37,4.17-28.79,6.39-43.16c0.45-2.91,1.89-5.65,2.67-8.53c0.37-1.36,0.26-2.85,0.36-4.28
                c-1.17,1.04-3.04,1.88-3.39,3.15c-1.91,6.88-3.54,13.84-5.07,20.82C1300.34,963.15,1298.27,973.42,1295.95,984.53z M473.51,987.41
                c1.27-2.18,2.71-3.56,2.84-5.06c0.75-9.04,1.27-18.1,1.73-27.16c0.51-10.19,0.91-20.38,1.21-30.57c0.03-1.05-0.79-2.3-1.57-3.12
                c-0.26-0.27-1.85,0.3-2.42,0.89c-0.45,0.48-0.33,1.56-0.37,2.37c-0.92,20.02-1.82,40.03-2.69,60.05
                C472.22,985.23,472.64,985.67,473.51,987.41z M645.86,914.29c0.2-0.04,0.4-0.07,0.61-0.11c0.1-0.82,0.5-1.77,0.23-2.45
                c-0.61-1.51-1.56-2.89-2.37-4.32c-0.63,1.44-1.86,2.91-1.77,4.3c0.38,6.13,1.06,12.25,1.78,18.36c1.06,9.07,2.17,18.14,3.43,27.19
                c0.19,1.39,1.25,2.66,1.91,3.99c1.04-1.4,2.91-2.78,2.94-4.2c0.05-2.94-0.92-5.88-1.4-8.84
                C649.41,936.91,647.64,925.6,645.86,914.29z M1214.87,970.31c-6.54-0.92-10.02,3.95-13.86,8.74c-0.04-0.97-0.14-1.31-0.06-1.61
                c2.43-9.12,4.94-18.22,7.23-27.38c0.28-1.1-0.63-2.5-0.99-3.76c-0.84,0.86-2.09,1.58-2.45,2.61c-1.65,4.69-3.17,9.44-4.5,14.23
                c-1.38,4.96-2.95,9.94-3.46,15.01c-0.19,1.88,2.22,4.2,3.83,5.95c0.35,0.38,2.79-0.46,3.6-1.35
                C1207.76,978.9,1211.06,974.81,1214.87,970.31z M33.49,923.8c-0.51-0.22-1.02-0.44-1.54-0.67c-0.78,1.62-2.01,3.17-2.27,4.87
                c-1.85,12.28-3.51,24.59-5.21,36.89c-0.3,2.14-0.82,4.32-0.63,6.43c0.13,1.47,1.31,2.84,2.01,4.26c0.86-1.37,2.25-2.65,2.49-4.12
                c1.07-6.38,1.96-12.8,2.62-19.24C31.95,942.77,32.66,933.28,33.49,923.8z M1191.18,984.11c0.66,0.18,1.32,0.36,1.98,0.54
                c0.38-1.48,1.34-3.07,1.06-4.41c-2.91-13.97,0.81-27.62,1.83-41.38c0.34-4.55-0.21-7.66-3.58-10.33
                C1192.46,947.6,1186.96,965.7,1191.18,984.11z M922.05,962.49c0.78,0.08,1.56,0.16,2.33,0.24c0.42-2.92,0.37-6.02,1.4-8.7
                c1.11-2.89,3.42-5.29,4.86-8.09c1.32-2.56,2.18-5.37,3.24-8.07c-0.57-0.31-1.14-0.62-1.71-0.92c-2.1,2.44-4.21,4.88-6.31,7.31
                c-0.53-0.17-1.07-0.34-1.6-0.51c0-3.08,0.12-6.17-0.05-9.24c-0.07-1.31-0.75-2.59-1.15-3.88c-0.89,1.27-2.44,2.5-2.53,3.83
                c-0.25,3.63,0.03,7.3,0.26,10.95C921.14,951.12,921.62,956.8,922.05,962.49z M714.91,956.82c-0.31-0.49-0.62-0.98-0.93-1.48
                c-1.12,0.25-2.42,0.23-3.34,0.81c-6.6,4.19-13.2,8.37-19.63,12.82c-2.98,2.06-5.71,4.55-8.25,7.15c-0.78,0.8-0.54,2.61-0.76,3.95
                c1.3-0.26,2.87-0.13,3.85-0.84c8.23-5.9,16.38-11.91,24.48-17.99C712.02,959.99,713.39,958.31,714.91,956.82z M1083.39,965.44
                c-0.51-0.63-0.97-1.56-1.29-1.51c-1.02,0.14-2.2,0.4-2.95,1.05c-8.51,7.34-17.06,14.65-25.32,22.27
                c-1.32,1.22-1.29,3.91-1.88,5.92c1.83-0.57,4.15-0.61,5.42-1.79c8.22-7.68,16.22-15.59,24.26-23.45
                C1082.3,967.26,1082.74,966.36,1083.39,965.44z M556.8,966.77c0.36,0.12,0.73,0.23,1.09,0.35c13.42-10.56,26.84-21.13,41.23-32.46
                c-2.62,0.61-4.58,0.54-5.82,1.45c-11.47,8.49-22.85,17.09-34.11,25.85C557.92,962.95,557.57,965.14,556.8,966.77z M90.12,923.24
                c-0.13-0.67-0.27-1.34-0.4-2.01c-1.54,0.4-3.21,0.55-4.6,1.26c-2.19,1.12-4.18,2.62-6.25,3.96c-8.64,5.59-17.32,11.12-25.88,16.83
                c-1.43,0.95-2.33,2.7-3.48,4.08c0.3,0.29,0.6,0.58,0.9,0.87C63.65,939.91,76.89,931.58,90.12,923.24z M1129.37,926.96
                c-0.5,0.27-1,0.53-1.5,0.8c0.23,1.23,0.36,2.48,0.71,3.68c3.06,10.49,6.1,20.99,9.3,31.44c0.46,1.49,1.72,2.74,2.61,4.1
                c0.43-1.71,1.59-3.64,1.16-5.09c-3.12-10.48-6.52-20.87-9.94-31.26C1131.26,929.29,1130.17,928.18,1129.37,926.96z
                M1142.06,911.71c-0.78,2.06-1.86,3.54-1.79,4.96c0.62,11.9,1.39,23.79,2.31,35.67c0.09,1.13,1.53,2.15,2.35,3.22
                c0.53-1.22,1.62-2.49,1.51-3.65C1145.13,938.85,1143.63,925.79,1142.06,911.71z M1118.49,934.71c-0.94,0.74-1.88,1.48-2.83,2.22
                c2.18,4.52,4.39,9.03,6.54,13.57c2.91,6.15,5.67,12.38,8.75,18.45c0.59,1.15,2.34,1.71,3.56,2.54c0.17-1.52,0.45-3.03,0.47-4.55
                c0.01-0.59-0.52-1.2-0.83-1.78c-4.87-9.06-9.77-18.1-14.6-27.18C1119.02,936.99,1118.83,935.81,1118.49,934.71z M52.36,957.2
                c11.54-6.17,22.65-12.1,33.76-18.04C77.46,938.05,54.09,951.01,52.36,957.2z M341.14,970.15c0.38-0.15,0.77-0.29,1.15-0.44
                c0-1.36,0.2-2.76-0.03-4.09c-1.19-6.68-2.42-13.36-3.82-19.99c-0.32-1.53-1.36-2.9-2.07-4.34c-0.75,1.62-2.35,3.39-2.09,4.83
                c1.28,7.16,3,14.25,4.73,21.32C339.25,968.45,340.4,969.25,341.14,970.15z M1190.1,988.93c-2.39-8.35-4.66-16.3-6.95-24.24
                c-0.09-0.31-0.27-0.73-0.52-0.82c-1.01-0.38-2.05-0.64-3.08-0.95c-0.11,1.02-0.48,2.09-0.27,3.04c0.46,2.08,1.22,4.09,1.83,6.14
                c1.65,5.56,3.2,11.15,5.01,16.66c0.31,0.94,1.76,1.51,2.68,2.25C1189.28,990.23,1189.76,989.46,1190.1,988.93z M116.49,972.88
                c0.22,0.38,0.83,1.41,1.44,2.45c0.9-0.57,2.4-0.97,2.62-1.73c1.61-5.75,3.03-11.55,4.32-17.38c0.15-0.69-0.81-1.62-1.25-2.45
                c-0.83,0.61-2.16,1.07-2.4,1.86C119.59,961.05,118.2,966.52,116.49,972.88z M49.26,938.47c0.46,0.37,0.93,0.74,1.39,1.12
                c3.59-1.1,15.95-18.1,14.74-22.73C60.02,924.06,54.64,931.27,49.26,938.47z M1347.61,978.91c-4.63-0.86-16.15,9.86-16.27,15.14
                C1334.87,993.52,1344.49,984.57,1347.61,978.91z M601.37,898.68c-4.3,3.89-3.95,17.89,0,22.22
                C601.37,912.91,601.37,905.79,601.37,898.68z M560.14,951.63c5.62-7.57,10.6-14.28,15.75-21.22
                C570.79,929.86,559.52,944.45,560.14,951.63z M560.02,974.05c0.28,0.42,0.56,0.84,0.84,1.26c6.39-4.25,12.77-8.5,19.33-12.85
                C576.62,959.92,563.74,967.66,560.02,974.05z M439.4,916.17c-0.55-0.24-1.11-0.47-1.66-0.71c-2.34,1.2-8.86,13.54-8.27,15.82
                c0.14,0.56,0.76,1.43,1.11,1.41c0.87-0.05,2.23-0.31,2.48-0.89C435.29,926.64,437.31,921.39,439.4,916.17z M655.25,957.21
                c5.38-5.47,4.47-17.15,0-18.47C655.25,944.89,655.25,951.05,655.25,957.21z M591.21,911.47c-0.3,0.09-0.61,0.17-0.91,0.26
                c0.39,1.3,0.58,2.72,1.29,3.82c0.25,0.39,2.35,0.28,2.58-0.15c0.65-1.21,1.22-2.79,0.99-4.07c-0.59-3.25-1.45-6.49-2.59-9.59
                c-0.4-1.1-1.94-1.78-2.96-2.65c-0.23,1.27-0.83,2.6-0.61,3.79C589.54,905.78,590.45,908.61,591.21,911.47z M804.98,978.33
                c-1.18-1.88-1.62-3.07-1.98-3.05c-1.02,0.06-2.21,0.39-2.96,1.04c-2.45,2.13-4.78,4.41-7.07,6.73c-0.59,0.6-0.84,1.55-1.25,2.33
                c0.96,0.47,2.41,1.6,2.81,1.3C798.1,984.07,801.47,981.2,804.98,978.33z M1019.16,968.6c-0.32,1.98-0.88,3.17-0.59,4.09
                c1.18,3.76,2.55,7.46,4.03,11.11c0.28,0.68,1.4,1.02,2.13,1.52c0.53-0.89,1.68-1.96,1.49-2.65c-1.05-3.78-2.32-7.52-3.75-11.17
                C1022.12,970.61,1020.89,970.07,1019.16,968.6z M634.13,966.63c2.28,5.2,4.04,9.4,5.98,13.51c0.34,0.72,1.4,1.09,2.13,1.63
                c0.48-0.94,1.59-2.1,1.34-2.77c-1.48-4.01-3.09-7.99-5.1-11.75C638.1,966.53,635.77,966.83,634.13,966.63z M517.67,975.26
                c0.45,0.86,0.89,1.72,1.34,2.59c6.75-1.08,10.86-6.88,16.67-9.62c-0.36-0.66-0.72-1.33-1.08-1.99
                C528.96,969.24,523.31,972.25,517.67,975.26z M704.96,979.59c2.54,0,4.82,0.7,5.8-0.14c2.34-1.98,4.19-4.6,5.96-7.16
                c0.45-0.65-0.15-2.01-0.26-3.05c-0.98,0.38-2.21,0.51-2.91,1.2C711,972.95,708.63,975.64,704.96,979.59z M922.09,970.92
                c3.34,2.47,5.91-0.47,7.74-2.28c1.61-1.58,1.81-4.58,2.63-6.96c-1.04-0.16-2.66-0.82-3.01-0.4
                C927,964.18,924.81,967.29,922.09,970.92z M555.2,940.64c4.03-1.22,12.75-11.15,12.36-13.01c-0.12-0.59-2.5-1.38-2.62-1.21
                C561.84,930.72,558.91,935.13,555.2,940.64z M772.53,987.52c0.63-1.09,1.6-2.1,1.39-2.54c-1.65-3.49-3.45-6.91-5.42-10.22
                c-0.21-0.36-2.05-0.28-2.55,0.21c-0.45,0.44-0.43,1.87-0.06,2.58c1.42,2.76,3.02,5.43,4.63,8.08
                C770.91,986.24,771.62,986.67,772.53,987.52z M1085.59,979.91c-4.55,2.29-8.37,4.12-12.07,6.16c-0.67,0.37-1.18,1.57-1.2,2.4
                c-0.02,0.49,0.99,1.31,1.66,1.47C1076.65,990.58,1084.78,984.13,1085.59,979.91z M463.19,969.46c-1.57,3.21-0.5,14.85,1.25,16.55
                c0.47,0.46,1.89,0.79,2.05,0.57c0.51-0.71,1.05-1.8,0.86-2.55C466.12,979.33,464.7,974.66,463.19,969.46z M42.32,962.32
                c-0.76-0.77-1.52-1.54-2.29-2.3c-3.38,3.83-6.76,7.65-10.86,12.3C35.5,973.17,40.75,968.44,42.32,962.32z M837.7,953.47
                c-0.35,0.41-0.71,0.82-1.06,1.24c2.23,5.15,7.47,9.16,13.1,8.36C845.06,959.35,841.38,956.41,837.7,953.47z M900.89,950.41
                c-5.49,2.34-5.97,6.22-6.42,10c-0.09,0.78,0.68,1.66,1.06,2.49c0.92-0.49,2.44-0.79,2.67-1.5
                C899.19,958.2,899.83,954.88,900.89,950.41z M439.63,930.36c0.49,0.44,0.98,0.87,1.47,1.31c4.46-1.13,7.77-5.45,6.18-10.39
                C444.73,924.3,442.18,927.33,439.63,930.36z M587.65,967.88c-5.95,0.97-7.87,5.77-12.27,8.67
                C581.03,977.46,584.55,974.85,587.65,967.88z M989.83,956.68c-0.8-0.19-1.6-0.37-2.4-0.56c-0.81,3.92-1.61,7.83-2.42,11.75
                c0.71,0.16,1.41,0.32,2.12,0.47C989.77,964.89,989.63,960.74,989.83,956.68z M68.81,958.85c-0.57-0.88-1.14-1.76-1.7-2.64
                c-3.09,2.22-6.19,4.43-9.28,6.65c0.47,0.71,0.93,1.42,1.4,2.12C62.42,962.94,65.61,960.9,68.81,958.85z M353.8,949.81
                c0.64-0.18,1.28-0.37,1.92-0.55c-0.59-2.65-1.1-5.32-1.86-7.91c-0.12-0.42-1.73-0.93-1.97-0.7c-0.61,0.59-1.34,1.76-1.12,2.38
                C351.59,945.36,352.76,947.56,353.8,949.81z M615.49,899.09c-5.85-0.58-7.22,1.1-6.13,6.68
                C611.42,903.53,613.05,901.75,615.49,899.09z M825.26,940.57c1.21,2.7,2.22,4.97,3.33,7.18c0.02,0.04,1.27-0.54,1.95-0.83
                c-0.99-2.12-1.97-4.24-3.01-6.33C827.47,940.46,826.94,940.57,825.26,940.57z M625.26,897.51c0.7,0.11,1.39,0.21,2.09,0.32
                c0.17-1.35,0.39-2.7,0.41-4.06c0-0.13-1.32-0.27-2.02-0.41C625.57,894.75,625.41,896.13,625.26,897.51z M815.39,980.06
                c-0.95,1.06-2.01,1.73-1.98,2.33c0.04,0.66,0.99,1.47,1.73,1.8c0.29,0.13,1.53-0.85,1.53-1.34
                C816.7,982.09,816.03,981.32,815.39,980.06z"/>
            <path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M0.5,18c0-6,0-12,0-18c466.33,0,932.67,0,1399,0c0,42.33,0,84.67,0,127c-16.15-2.53-32.17-1.42-48.16,1.55
                c-2.3,0.43-4.88-0.23-7.26-0.7c-5.36-1.07-10.8-3.68-16.01-3.25c-10.2,0.83-20.47,2.56-30.32,5.31
                c-11.05,3.08-21.42,2.07-32.35-0.46c-9.79-2.27-19.99-3.06-30.06-3.7c-9.09-0.58-17.73,1.51-25.79,6.3
                c-2.98,1.78-6.93,2.11-10.51,2.67c-4.26,0.67-8.9,0.04-12.83,1.46c-7.88,2.86-13.73,0.2-19.3-4.95c-1.94-1.8-4.09-3.45-6.38-4.79
                c-3.7-2.17-7.4-4.89-11.45-5.79c-4.81-1.07-8.95-2.4-12.72-5.82c-2.03-1.84-5.64-3.21-8.26-2.85c-5.99,0.83-10.68-1.51-15.49-4.2
                c-1.29-0.72-2.73-1.35-4.18-1.63c-16.07-3.02-30.02-12.79-46.47-14.56c-0.64-0.07-1.3-0.36-1.87-0.69
                c-11.92-6.85-24.47-12.67-34.18-22.93c-2.36-2.49-6.13-3.82-9.46-5.16c-3.19-1.27-6.27-1.32-7.09,3.37
                c-0.17,0.98-1.77,1.73-2.75,2.54c-1.22,1.01-2.47,1.98-3.71,2.96c1.08,1.16,1.97,2.61,3.27,3.42c8.6,5.36,17.2,10.56,23.89,18.53
                c3.03,3.62,7.42,6.1,11.19,9.09c1.22,0.97,2.44,1.96,3.66,2.93c-0.18,0.56-0.36,1.11-0.54,1.67c-3.95-0.64-7.95-1.05-11.82-1.98
                c-5.76-1.39-11.36-3.49-17.16-4.65c-6.32-1.27-12.8-1.73-19.19-2.69c-6.08-0.92-12.14-1.99-18.18-3.1
                c-5.54-1.01-11.04-2.23-16.58-3.23c-10.77-1.95-21.51-4.97-32.35-5.42c-15.4-0.64-30.94-0.03-46.32,1.24
                c-24.13,1.99-48.08,1.3-72.16-1c-16.33-1.56-32.84-1.22-49.28-1.66c-4.12-0.11-8.29,0.42-12.36,0
                c-5.53-0.58-10.99-2.59-16.48-2.55c-9.29,0.06-18.07-1.54-27-3.95c-6.58-1.78-13.74-2.21-20.6-1.97
                c-8.26,0.28-16.45,2.54-24.7,2.81c-16.09,0.52-32.21,0.3-48.32,0.27c-2.3,0-4.6-0.67-6.89-1.07c-13.4-2.35-26.73-5.25-40.22-6.9
                c-8.92-1.09-17.86-1.15-26.08-5.52c-2.13-1.13-4.77-1.51-7.22-1.77c-15.49-1.65-31-3.16-46.5-4.72c-0.49-0.05-0.97-0.24-1.47-0.3
                c-8.43-0.96-17.02-3.35-25.24-2.43c-7.07,0.8-13.4-0.23-20.04-1.46c-9.42-1.76-18.86-4.28-28.36-4.64
                c-17.23-0.65-34.52,0.05-51.79,0.17c-0.66,0-1.33-0.18-1.99-0.14c-6.39,0.4-12.78,0.75-19.15,1.28
                c-6.55,0.55-13.07,1.51-19.62,1.86c-4.96,0.27-9.98,0.08-14.94-0.29c-3.44-0.25-7.3-0.31-10.15-1.93
                c-9.17-5.19-19.05-6.46-29.27-7.1c-14.41-0.9-28.7-3.72-43.26-1.65c-4.36,0.62-8.96,0.38-13.35-0.19
                c-9.06-1.18-18.04-2.98-27.09-4.29c-8.71-1.26-17.51-3.32-26.2-3.03c-12.76,0.42-25.42,2.55-38.29,0.57
                c-5.86-0.9-12.03-1.09-17.89-0.3c-11.27,1.52-22.22,2.4-32.32-4.27c-0.53-0.35-1.26-0.42-1.92-0.51
                c-8.86-1.27-17.69-3.17-26.59-3.62C23.46,26.55,11.92,22.68,0.5,18z"/>
            <path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M0.5,776c0-6,0-12,0-18c5.58,0.75,11.16,1.6,16.75,2.23c13.82,1.56,27.65,3.11,41.49,4.47
                c3.61,0.36,7.3,0.14,10.94-0.05c1.19-0.06,2.34-0.89,4.27-1.68c-1.48-0.79-1.89-1.17-2.32-1.21c-14.36-1.26-28.74-2.28-43.07-3.78
                c-9.39-0.98-18.71-2.64-28.06-3.99c0-4,0-8,0-12c7.61-0.09,15.22-0.38,22.82-0.19c6.1,0.16,12.19,0.94,18.29,1.39
                c5.61,0.42,11.22,0.79,16.84,1.12c1.12,0.07,2.26-0.15,3.39-0.24c0.09-0.57,0.17-1.14,0.26-1.72c-1.85-0.58-3.68-1.61-5.54-1.67
                c-18.68-0.66-37.36-1.16-56.05-1.7c0-15.33,0-30.67,0-46c18.4-2.87,36.95-5.01,55.17-8.75c47.34-9.72,93.46-23.99,139.47-38.61
                c6.82-2.17,13.36-4.42,19.36-8.63c7.52-5.29,16.19-6.79,25.54-6.97c16.58-0.33,33.39-0.54,49.63-3.47
                c13.38-2.42,26.1-8.58,39.03-13.31c8.51-3.12,16.19-8.05,25.68-9.06c13.65-1.45,27.43-3.3,39.36-11.22
                c0.92-0.61,2.23-0.62,3.31-1.03c7.18-2.7,14.36-5.4,21.52-8.17c1.07-0.41,1.92-1.35,2.97-1.82c3.89-1.77,7.84-4.93,11.72-4.85
                c8.89,0.17,15.29-4.81,22.24-8.69c7.59-4.23,13.58-10.36,18.83-17.46c-6.45-1.45-12.43-3.05-18.51-4
                c-1.75-0.27-3.79,1.35-5.69,2.1l0.1-0.07c-1.89,0.57-3.88,0.92-5.65,1.74c-10.78,5.02-21.18,10.84-33.16,12.93
                c-6.01,1.05-11.83,3.22-17.75,4.82c-14.51,3.93-29.06,7.2-44.22,3.74c-12.1-2.76-24.12-0.09-36.16,0.88
                c-4.87,0.39-9.84,1.46-14.6,0.88c-13.85-1.68-27.17-5.11-41.64-0.69c-7.93,2.42-17.65-0.74-26.51-1.77
                c-10.03-1.16-19.87-2.75-30.14-0.84c-15.55,2.89-30.9,9.27-47.21,4.16c-1.21-0.38-2.66-0.15-3.97-0.01
                c-13.21,1.39-26.44,2.68-39.62,4.3c-4.41,0.54-8.73,1.9-13.08,2.94c-5.48,1.31-10.95,2.68-16.41,4.04
                c-4.5,1.12-9.07,2.05-13.48,3.44c-9.69,3.05-19.44,7.11-29.65,3.51C24.88,588.8,12.78,589.39,0.5,591c0-54.33,0-108.67,0-163
                c5.46,0.23,10.93,0.69,16.39,0.62c6.25-0.08,12.5-0.52,18.73-1.05c3.13-0.27,6.2-1.44,9.32-1.6c14.74-0.75,29.49-1.52,44.24-1.81
                c6.83-0.13,13.69,0.75,20.52,1.35c0.85,0.07,1.98,1.11,2.34,1.96c2.38,5.76,10.75,11.55,17.14,11.36
                c18.43-0.54,36.86-1.26,55.3-1.78c3.29-0.09,6.71,0.04,9.88,0.83c11.27,2.81,22.51,5.78,33.66,9.01
                c11.21,3.25,23.17,4.06,33.4,10.58c1.96,1.25,4.79,1.1,7.22,1.63c1.92,0.42,4.1,0.44,5.69,1.41c12.4,7.62,26.2,7.09,39.89,7.06
                c4.06-0.01,8.12-0.99,12.18-1.02c4.23-0.02,8.47,0.91,12.69,0.83c7.78-0.16,15.55-1.09,23.32-1.09c6.62,0,13.27,0.59,19.85,1.41
                c5.37,0.67,10.69,1.92,15.96,3.19c5.77,1.39,11.45,3.13,17.18,4.67c4.79,1.29,9.91,1.86,14.31,3.95
                c5.77,2.75,10.87,2.35,16.76,0.45c9.44-3.05,19.17-5.24,28.85-7.47c2.32-0.54,4.94,0.08,7.41,0.32
                c12.05,1.17,24.09,3.38,36.13,3.32c11.17-0.06,22.28-2.97,33.48-3.8c17.02-1.26,34.1-1.8,52.37-2.7c-1.22,7.27-2.08,15.31-4,23.09
                c-2.71,10.96-6.71,21.61-9.22,32.61c-3.24,14.21-6.86,28.1-15.47,40.21c-2.64,3.72-1.17,6.87,3.38,8.19
                c1.67,0.48,3.34,0.93,5.47,1.52c-0.94,1.66-1.53,2.81-2.22,3.91c-4.61,7.3-6.27,15.38-5.61,23.85c0.17,2.16,1.66,4.79,3.38,6.13
                c5.09,3.97,5.32,5.51-0.27,8.74c-5.83,3.37-7.82,8.27-6.83,14.71c0.23,1.52,0.21,3.09,0.36,5.39
                c-9.89-3.64-18.91-6.91-27.88-10.33c-0.92-0.35-2.28-1.8-2.13-2.4c1.28-4.97-2.14-7.29-5.18-9.82c-2.41-2.01-2.1-3.61,0.15-5.66
                c7.27-6.61,6.81-11.19-2.38-17.27c2.06-1.72,4.24-3.07,5.81-4.93c4.66-5.56,2.4-14.22-4.38-15.41c-8.36-1.47-16.31-3.7-24.34-6.56
                c-13.21-4.71-26.3-3.87-39.63,2.28c-10.13,4.67-15.16,11.85-17.72,21.78c-0.95,3.69,0.67,6.52,3.93,8.35
                c2.83,1.59,5.72,3.05,9.17,4.87c-4.84,4.34-8.25,8.46-8.13,15.16c0.15,8.31,8.77,6.46,12.06,11.25
                c-6.55,5.84-12.82,11.51-19.21,17.03c-0.74,0.64-2.2,0.62-3.33,0.62c-11.56,0.04-23.15,0.95-33.78,5.47
                c-9.57,4.07-19.37,9.07-23.76,19.36c-3.35,7.84-8.29,11.24-16.7,11.06c-11.92-0.25-22.97,2.94-33.01,9.66
                c-1.22,0.82-4.27,0.44-5.53-0.57c-13.79-10.96-29.99-14.64-46.97-15.62c-13.72-0.79-27.17,3.79-41.15,1.7
                c-14.87-2.22-28.2,4.6-41.73,9.56c-6.06,2.22-12.15,4.9-18.45,5.78c-9.51,1.34-19.31,0.65-28.83,1.97
                c-14.36,2-29.46,0.98-42.48,9.31c-2.09,1.34-4.36,2.67-6.73,3.22c-5.5,1.27-8.5,5.1-11.76,9.27c-4.68,5.99-8.93,12.87-17.93,13.55
                c-1.66,0.12-3.16,2.26-4.74,3.46c0.31,0.48,0.63,0.96,0.94,1.45c2.59-0.63,5.17-1.26,9.08-2.22c-2.33,4.04-4.23,6.91-5.69,9.99
                c-1.13,2.39-2.65,5.14-2.37,7.54c0.66,5.55,2.32,10.98,3.55,16.46c2.1,9.36,0.95,10.76-9,10.71c-2.49-0.01-4.99-0.46-7.47-0.43
                c-7.95,0.08-16-0.42-23.84,0.61C28.03,780.08,14.28,778.46,0.5,776z"/>
            <path d="M0.5,776c13.78,2.46,27.53,4.08,41.62,2.24c7.84-1.02,15.88-0.52,23.84-0.61c2.49-0.03,4.98,0.42,7.47,0.43
                c9.96,0.05,11.11-1.35,9-10.71c-1.23-5.48-2.89-10.91-3.55-16.46c-0.28-2.4,1.24-5.15,2.37-7.54c1.46-3.08,3.36-5.95,5.69-9.99
                c-3.91,0.96-6.5,1.59-9.08,2.22c-0.31-0.48-0.63-0.96-0.94-1.45c1.58-1.21,3.08-3.34,4.74-3.46c9-0.67,13.25-7.55,17.93-13.55
                c3.26-4.17,6.26-8.01,11.76-9.27c2.37-0.55,4.65-1.88,6.73-3.22c13.02-8.33,28.12-7.32,42.48-9.31
                c9.52-1.32,19.31-0.64,28.83-1.97c6.3-0.88,12.39-3.56,18.45-5.78c13.53-4.97,26.87-11.78,41.73-9.56
                c13.98,2.08,27.43-2.5,41.15-1.7c16.98,0.98,33.18,4.67,46.97,15.62c1.26,1,4.31,1.38,5.53,0.57c10.04-6.72,21.09-9.91,33.01-9.66
                c8.41,0.17,13.34-3.22,16.7-11.06c4.4-10.29,14.2-15.29,23.76-19.36c10.63-4.52,22.22-5.43,33.78-5.47c1.13,0,2.59,0.02,3.33-0.62
                c6.39-5.52,12.66-11.19,19.21-17.03c-3.29-4.78-11.91-2.94-12.06-11.25c-0.12-6.71,3.29-10.82,8.13-15.16
                c-3.44-1.82-6.34-3.29-9.17-4.87c-3.26-1.83-4.88-4.66-3.93-8.35c2.56-9.94,7.59-17.11,17.72-21.78
                c13.33-6.15,26.42-6.99,39.63-2.28c8.02,2.86,15.98,5.09,24.34,6.56c6.78,1.19,9.05,9.85,4.38,15.41
                c-1.56,1.86-3.74,3.21-5.81,4.93c9.19,6.08,9.66,10.65,2.38,17.27c-2.25,2.05-2.57,3.64-0.15,5.66c3.04,2.54,6.47,4.86,5.18,9.82
                c-0.16,0.6,1.2,2.05,2.13,2.4c8.96,3.42,17.99,6.69,27.88,10.33c-0.14-2.3-0.13-3.86-0.36-5.39c-0.98-6.44,1-11.34,6.83-14.71
                c5.59-3.23,5.36-4.77,0.27-8.74c-1.72-1.34-3.21-3.98-3.38-6.13c-0.66-8.46,1-16.54,5.61-23.85c0.69-1.09,1.28-2.24,2.22-3.91
                c-2.13-0.59-3.81-1.04-5.47-1.52c-4.55-1.32-6.02-4.47-3.38-8.19c8.61-12.11,12.22-26.01,15.47-40.21
                c2.51-11,6.52-21.64,9.22-32.61c1.92-7.78,2.78-15.82,4-23.09c-18.27,0.9-35.35,1.44-52.37,2.7c-11.2,0.83-22.32,3.75-33.48,3.8
                c-12.04,0.06-24.08-2.14-36.13-3.32c-2.47-0.24-5.09-0.86-7.41-0.32c-9.68,2.24-19.41,4.42-28.85,7.47
                c-5.89,1.9-10.99,2.3-16.76-0.45c-4.4-2.09-9.52-2.66-14.31-3.95c-5.73-1.55-11.41-3.28-17.18-4.67
                c-5.27-1.27-10.59-2.52-15.96-3.19c-6.58-0.82-13.23-1.41-19.85-1.41c-7.77,0-15.54,0.93-23.32,1.09
                c-4.22,0.09-8.46-0.85-12.69-0.83c-4.06,0.02-8.12,1.01-12.18,1.02c-13.69,0.03-27.49,0.57-39.89-7.06
                c-1.59-0.98-3.77-0.99-5.69-1.41c-2.42-0.53-5.26-0.38-7.22-1.63c-10.23-6.52-22.19-7.34-33.4-10.58
                c-11.16-3.23-22.39-6.2-33.66-9.01c-3.17-0.79-6.59-0.92-9.88-0.83c-18.43,0.51-36.86,1.24-55.3,1.78
                c-6.39,0.19-14.76-5.6-17.14-11.36c-0.35-0.86-1.49-1.89-2.34-1.96c-6.83-0.6-13.69-1.49-20.52-1.35
                c-14.75,0.29-29.5,1.06-44.24,1.81c-3.13,0.16-6.19,1.33-9.32,1.6c-6.23,0.53-12.48,0.97-18.73,1.05
                c-5.46,0.07-10.92-0.39-16.39-0.62c0-1,0-2,0-3c0.16-0.03,0.33-0.09,0.49-0.08c29.98,2.1,59.49-5.07,89.37-4.63
                c7.76,0.11,15.52,0.95,23.25,1.72c1.07,0.11,2.59,1.56,2.89,2.67c1.71,6.33,5.94,10.32,12.4,10.25
                c16.62-0.18,33.25-0.43,49.86-1.13c12.2-0.51,23.67,2.13,35.26,5.51c13.85,4.04,28.01,7.02,42.03,10.47
                c1.11,0.27,2.79,0.18,3.25,0.89c3.18,4.9,8.28,5.03,13.19,5.76c1.14,0.17,2.38,0.39,3.34,0.98c14.36,8.83,30.17,7.63,45.96,6.85
                c11.96-0.59,24.05-2.24,35.88-1.2c16.09,1.41,32.72,0.26,48.02,7.45c3.07,1.44,6.78,1.53,10.19,2.21
                c4.08,0.81,8.58,0.74,12.16,2.53c8.8,4.4,16.34,1.66,24.74-1.53c11.35-4.31,23.21-6.36,35.69-3.55
                c11.82,2.66,23.8,3.73,36.16,2.14c21.68-2.8,43.49-5.14,65.46-3.29c1.8,0.15,3.67-0.05,5.47-0.34c7.3-1.19,8.2-1.92,8.84-9.27
                c0.41-4.71-0.24-9.5,0.08-14.22c0.21-3.08,1.13-6.15,2.08-9.12c0.68-2.14,1.94-4.1,3.5-7.29c-5.31,1.05-9.84,1.1-13.57,2.85
                c-8.27,3.89-16.72,3.43-25.39,2.97c-8.61-0.46-17.29,0.02-25.87-0.67c-15.74-1.27-31.41-3.4-47.16-4.63
                c-14.75-1.15-29.56-1.42-44.32-2.43c-6.27-0.43-12.48-1.75-18.72-2.68c-2.29-0.34-4.59-1.17-6.85-1.04
                c-8.37,0.48-16.07-1.79-23.77-4.75c-4.43-1.71-9.26-2.43-13.93-3.46c-5.82-1.28-11.69-2.36-17.52-3.63
                c-10.67-2.31-21.38-4.47-31.97-7.13c-5.42-1.36-10.59-3.7-15.89-5.53c-5.14-1.77-10.39-3.21-15.44-5.19
                c-21.01-8.25-41.75-17.29-63.03-24.75c-11.65-4.08-24.16-5.85-36.37-8.19c-18.12-3.48-36.32-6.55-54.5-9.75
                c-4.58-0.81-9.77-3.11-13.66-1.76c-5.98,2.07-9.36-1.88-13.92-3.02c-3.29-0.82-6.83-1.21-10.22-1c-4.72,0.28-8.8,0.41-13.07-2.92
                c-3-2.35-7.82-2.34-11.78-3.53c-4.25-1.28-8.63-2.35-12.62-4.22c-3.47-1.62-4.16-4.67-1.33-7.76c4.35-4.76,8.41-9.89,13.29-14.04
                c7.93-6.75,17.63-7.04,27.47-5.92c7.22,0.83,14.48,1.37,21.66,2.44c4.42,0.66,8.71,2.09,13.07,3.16
                c6.91,1.69,13.94,3.02,20.72,5.13c10.1,3.15,20.14,6.57,29.99,10.4c10.21,3.97,20.07,8.88,30.3,12.78
                c5.38,2.05,11.26,2.78,16.9,4.21c11.09,2.8,22.29,5.28,33.21,8.64c8.1,2.5,16.05,3.92,25.02,1.32c-2.53-1.57-5.06-3.14-7.59-4.7
                l0.06,0.08c4.03-0.51,8.6,2.35,10.51,6.57c-4.49,3.05-16.93,4.09-24.12,1.33c-13.26-5.09-26.7-9.13-40.97-10.28
                c-4.39-0.35-8.82-1.9-12.95-3.59c-14.47-5.93-28.74-12.35-43.26-18.16c-5.96-2.38-12.31-3.82-18.55-5.43
                c-3.99-1.03-8.31-1.06-12.12-2.49c-9.1-3.39-18.07-6.75-28.1-5.34c-2.03,0.29-4.26-0.6-6.36-1.09
                c-12.07-2.8-23.76-3.23-34.12,5.22c-0.76,0.62-1.67,1.17-2.6,1.45c-5.94,1.82-8.44,6.87-12.34,12.35
                c5.11,1.93,9.27,4.17,13.7,5.04c12.82,2.52,25.08,8.23,38.58,6.95c0.63-0.06,1.3,0.25,1.96,0.38
                c26.32,4.97,52.52,10.81,79.01,14.68c29.43,4.31,56.3,15.66,83.31,26.95c4.42,1.85,8.34,5.01,12.84,6.5
                c10.52,3.49,21.13,6.83,31.92,9.33c18.12,4.2,36.41,7.67,54.61,11.52c2.75,0.58,5.43,1.54,8.12,2.37
                c16.1,4.94,32.07,9.56,49.32,9.6c15.69,0.04,31.43,2.07,47.05,4c19.97,2.47,39.79,6.07,60.08,3.44
                c5.01-0.65,10.25,0.65,15.38,0.61c6.09-0.05,12.22-0.29,18.26-1.02c4.76-0.57,9.48-1.77,14.08-3.16c2.9-0.88,5.82-2.25,8.25-4.05
                c6.59-4.88,13.17-9.5,21.86-9.65c1.25-0.02,2.71-1.01,3.67-1.95c3.97-3.93,7.57-8.26,11.72-11.98c4.9-4.38,10.21-8.19,17.5-5.26
                c1.16,0.47,2.66,0.34,3.96,0.18c6.29-0.78,11.09,2.46,13.89,7.17c4.49,7.55,10.53,9.48,18.53,7.98c5.2-0.98,10-0.77,14.66,2.7
                c2.02,1.5,5.9,1.91,8.36,1.1c4.19-1.38,7.81-4.4,11.89-6.23c8.44-3.79,16.77-8.33,25.64-10.56c9.92-2.49,20.43-2.52,30.61-4.06
                c14.8-2.23,29.54-4.87,44.31-7.31c15.01-2.48,30.01-5.05,45.05-7.34c7.34-1.12,14.76-2.38,22.14-2.34
                c9.13,0.05,17.64-1.04,26.24-4.6c9.75-4.04,19.85-7.61,30.81-7.55c6.2,0.03,10.43-3.43,12-8.47c3.34-10.72,2.97-11.71-8.32-10
                c-13.2,2-25.82-0.14-38.38-3.32c-17.19-4.36-34.7-3.36-52.1-3.39c-10.11-0.02-20.23,1.47-30.32,2.46
                c-12.4,1.21-24.78,2.57-37.17,3.92c-8.09,0.88-16.26,3.03-24.23,2.42c-8.01-0.61-15.43,0.43-22.3,3.65
                c-8.31,3.9-16.3,3.43-24.74,1.37c-6.27-1.53-12.63-3.32-19.02-3.62c-10.57-0.5-21.19,0.22-31.79,0.2
                c-19.75-0.05-38.93,4.33-58.31,7.19c-0.96,0.14-2.05,0.28-2.93-0.03c-7.21-2.54-14.01-3.4-19.06,4.1
                c-0.25,0.37-0.78,0.77-1.19,0.79c-5.24,0.27-10.49,0.47-15.73,0.69l0.08,0.08c9.44-0.87,17.38-5.61,25.55-9.82
                c1.26-0.65,3.07-1.05,4.37-0.66c11.09,3.28,21.68-1.43,32.52-1.89c11.59-0.48,23.24-0.87,34.71-2.39
                c15.75-2.09,31.19-0.16,46.69,1.83c3.12,0.4,6.15,1.46,9.27,1.94c6.39,0.96,12.46,1.19,19-1.13c8.03-2.85,16.75-3.93,25.25-5.2
                c8.02-1.19,16.16-1.63,24.25-2.4c22.01-2.11,43.97-5.03,66.03-6.09c20.98-1,41.77,1.93,62.33,6.75
                c6.09,1.43,12.82,0.13,19.26,0.06c5.15-0.06,10.32-0.41,15.45-0.12c3.48,0.19,5.14-1.17,6.32-4.22c2.93-7.59,5.88-15.18,9.22-22.6
                c1.25-2.79,3.12-5.75,5.54-7.47c4.61-3.28,7.48-7.58,9.28-12.63c4.41-12.32,13.03-20.39,24.28-26.63
                c4.02-2.23,7.39-6.7,9.62-10.91c4.66-8.81,11.35-14.77,20.33-18.82c13.03-5.87,23.94-2.3,33.42,7.11
                c6.27,6.22,14.56,5.83,22.12,7.9c7.2,1.97,14.41,3.9,21.67,5.58c2,0.46,4.33,0.39,6.33-0.13c15.29-3.9,30.61-7.73,45.77-12.09
                c7.79-2.24,15.15-2.65,23-0.2c4.15,1.3,8.87,1.06,13.33,1.03c9.99-0.07,19.92-0.55,29.49,3.58c2.21,0.95,5.4-0.28,8.11-0.65
                c4.82-0.64,9.62-1.43,14.45-2.06c2.29-0.3,4.62-0.27,6.92-0.55c11.19-1.36,22.35-3.39,33.57-3.94c6.48-0.32,13.12,1.49,19.61,2.73
                c5.05,0.96,10,2.47,14.97,3.85c2.92,0.81,5.78,1.85,8.83,2.84c-5.15,2.97-9.6,5.53-14.05,8.1c0.02,0.54,0.04,1.07,0.05,1.61
                c4.93,0.86,9.84,1.81,14.79,2.54c3.29,0.48,6.64,0.54,9.94,0.94c6.42,0.77,10.11,4.06,11.09,10.33c0.16,1-1.37,3.02-2.48,3.34
                c-8.63,2.53-17.37,4.73-26.47,2.82c-12.67-2.66-25.3-5.55-37.99-8.08c-2.1-0.42-5.03-0.24-6.62,0.94
                c-8.94,6.65-19.31,6.49-29.58,6.77c-5.13,0.14-10.29-0.3-15.43-0.65c-7.45-0.5-14.91-1.73-22.34-1.61
                c-12.7,0.2-25.39,1.2-38.09,1.86c-0.11,0.78-0.21,1.57-0.32,2.35c8.74,2.91,17.59,5.54,26.19,8.82
                c5.33,2.03,10.41,4.82,15.37,7.67c3.19,1.83,3.53,4.85,0.98,7.58c-2.79,3-2.09,5.91-1.39,9.61c1.96,10.36,6.88,19.29,11.75,28.35
                c1.44,2.69,3.14,3.59,6.34,3.4c6.12-0.36,12.38-0.59,18.4,0.32c8.6,1.3,16.21-0.02,22.96-5.54c3.03-2.48,6.2-2.63,10.12-2.51
                c10.62,0.34,21.26-0.49,31.9-0.6c4.8-0.05,9.6,0.64,14.41,0.73c3.09,0.06,6.19-0.35,9.28-0.61c5.77-0.48,11.53-1.04,17.3-1.49
                c3.46-0.27,6.93-0.42,10.39-0.62c0,1,0,2,0,3c-3.77,0.07-7.54,0.31-11.3,0.16c-3.1-0.12-6.51-1.74-9.22-0.9
                c-9.08,2.83-18.22,2.87-27.49,2.25c-0.33-0.02-0.66,0.11-0.99,0.12c-12.9,0.18-25.81,0.3-38.71,0.6c-1.27,0.03-2.69,0.95-3.76,1.8
                c-6.07,4.85-12.67,7.6-20.71,6.88c-6.94-0.62-13.95-0.57-20.93-0.68c-3.36-0.05-4.44,1.61-3.18,4.85
                c1.69,4.33,2.87,8.9,4.95,13.03c1.03,2.05,3.41,4.49,5.46,4.79c9.17,1.35,18.46,1.87,27.68,2.93c9.9,1.14,19.47-0.09,29.11-2.52
                c4.83-1.22,10.18-0.81,15.26-0.54c7.79,0.42,15.56,1.29,23.33,2.13c2.62,0.28,5.47,0.48,7.72,1.67
                c7.21,3.79,14.89,4.45,22.77,4.44c0,1,0,2,0,3c-2.63-0.25-5.29-0.34-7.89-0.79c-4.21-0.73-8.39-1.68-12.57-2.57
                c-5.62-1.2-11.2-2.66-16.86-3.62c-6.03-1.02-12.13-1.79-18.23-2.28c-4.51-0.36-9.56-1.37-13.53,0.13
                c-11.14,4.21-22.3,4.88-33.87,3.21c-2.94-0.43-5.99-0.14-8.97-0.34c-5.25-0.35-10.49-0.83-15.82-1.26
                c0.25,3.97-0.19,9.31,1.05,14.24c2.8,11.1,6.63,21.92,9.66,32.97c4.41,16.08,7.59,32.3,6.37,49.2c-0.65,9.01-1.14,17.78-6,25.79
                c-4.15,6.84-6.14,14.25-5.16,22.43c1.18,9.88-5.46,19.54-1.03,29.58c0.04,0.1-0.09,0.33-0.2,0.44c-3.24,3.1-1.11,6.51-0.55,9.81
                c0.64,3.74,1.6,7.46,1.89,11.23c0.67,8.9-0.32,17.93,1.92,26.72c0.45,1.77,0.85,3.54,1.22,5.08
                c8.08-10.72,15.42-21.43,23.79-31.27c8.56-10.05,12.9-21.7,14.78-34.27c2.27-15.21,7.1-28.36,21.99-35.54
                c0.55-0.27,0.91-0.92,1.43-1.46c-1.76-2.64-3.74-5.04-5.06-7.76c-1.07-2.2-2.22-4.93-1.84-7.17c1.53-8.88,9.47-13.3,18.44-10.99
                c3.67,0.95,7.5,1.25,11.18,2.18c7.81,1.98,15.97,2.91,21.38,10.41c3.79,5.26,3.66,13.12-0.62,16.32
                c4.22,3.09,8.65,6.33,13.09,9.58c0,2.67,0,5.33,0,8c-2.57-2.03-4.77-4.84-8.8-3.21c-0.79,0.32-2.11-0.78-3.22-1.11
                c-3.64-1.11-7.24-2.42-10.95-3.19c-11.95-2.49-23.93-3.21-36.15-1.57c-3.94,0.53-6.01,1.93-8.38,4.72
                c-5.32,6.26-5.91,13.96-7.44,21.39c-2.88,14-7.18,27.27-17.32,37.98c-2.27,2.4-3.93,5.38-6.02,8.3
                c11.95-1.32,23.12-2.75,34.34-3.73c19.18-1.68,38.39-3.97,57.52,0.63c2.09,0.5,4.28,0.55,6.43,0.8c0,2.33,0,4.67,0,7
                c-8.95,0.6-17.54-0.49-25.93-5.93c0.37,5.04-3.2,5.09-6.34,5.01c-3.79-0.09-7.56-0.91-11.36-1.23c-6.75-0.57-13.58-1.86-20.24-1.3
                c-11.56,0.97-23.04,3.02-34.53,4.81c-7.99,1.25-16.45,12.44-15.97,21.48c2.57-0.12,5.18-0.1,7.76-0.38
                c3.14-0.34,6.26-1.39,9.37-1.34c21.1,0.35,42.21,0.79,63.3,1.49c4.93,0.16,9.84,1.32,14.72,2.26c6.43,1.24,12.81,2.73,19.21,4.11
                c0,2.67,0,5.33,0,8c-4.6-0.57-9.21-1.12-13.81-1.7c-5.9-0.74-11.77-1.7-17.69-2.21c-8.24-0.71-16.51-1.41-24.78-1.5
                c-8.39-0.09-16.64,1.25-25.23,0c-8.13-1.18-16.67,0.4-25.02,0.77c-0.18,0.62-0.37,1.23-0.55,1.85c1.45,0.8,2.82,1.8,4.35,2.34
                c2,0.71,4.5,0.53,6.15,1.66c10.45,7.17,19.56,15.18,21.12,29.16c0.48,4.26,3.04,8.95,6.05,12.12
                c7.58,8.01,12.65,16.95,14.09,27.92c0.95,7.25,2.1,14.47,3.02,21.73c1.25,9.87,2.14,19.79,3.69,29.61
                c0.48,3.05,2.59,5.84,3.95,8.75c0.47-0.07,0.94-0.14,1.41-0.21c-0.84,1.07-1.68,2.15-2.41,3.08c-2.47-1.14-4.53-2.1-6.52-3.02
                c0.37-1.71,1.26-3.33,0.93-4.64c-2.35-9.18-4.69-18.34-5.43-27.85c-0.2-2.63-1.15-5.19-1.7-7.79
                c-2.19-10.23-4.32-20.47-6.59-30.68c-0.41-1.84-1.35-3.56-2.04-5.34c-0.37-0.9-0.56-1.94-1.14-2.67
                c-4.64-5.97-8.98-12.25-14.23-17.64c-2.27-2.33-5.42-3.44-5.65-7.41c-0.04-0.71-2.4-1.66-3.78-1.85
                c-4.91-0.68-9.86-1.28-14.81-1.52c-4.92-0.23-5.81,1.15-4.29,6.03c1.43,4.59,3.25,9.07,4.44,13.72c1.08,4.19,3.23,4.8,7.27,4.23
                c4.63-0.65,9.65-0.9,14.12,0.2c6.23,1.54,12.11,4.49,18.14,6.84c-1.63,3.78-4.09,1.41-6.27,0.82c-7.88-2.12-14.97-7.34-23.91-5.32
                c-4.31,0.98-6.03,2.56-4.65,7.06c2.58,8.4,5.08,16.84,7.32,25.35c3.25,12.37,6.32,24.79,9.29,37.23c0.68,2.85,1.76,3.7,4.78,3.35
                c3.14-0.37,6.4,0.26,9.61,0.45c-0.02,0.67-0.05,1.35-0.07,2.02c-2.2,0.11-4.4,0.32-6.6,0.31c-5.21-0.01-5.87,0.73-5.06,5.78
                c1.34,8.36,2.76,16.72,3.91,25.11c1.51,11.01,1.39,11.19,12.24,12.31c22.1,2.29,44.23,4.38,66.34,6.55c0,0.67,0,1.33,0,2
                c-11.9-0.54-23.84-0.76-35.71-1.73c-11.76-0.96-23.45-2.88-35.2-4.02c-6.24-0.6-6.76,0.31-5.48,6.29
                c1.54,7.16,3.19,14.3,4.56,21.49c0.82,4.33,1.87,7.84,7.54,6.26c0.98-0.27,2.22,0.43,3.35,0.68c-0.03,0.32-0.06,0.64-0.09,0.96
                c-2.5,0.5-5,1-8.15,1.63c3.62,11.42,7.05,22.23,10.67,33.64c2.43-1.6,3.88-2.56,6.07-4c-1.82-1.26-2.91-2.16-4.13-2.83
                c-4.43-2.41-4.59-3.66-1-7.32c5.46-5.59,11.42-7.05,19-4.03c3.14,1.25,7.58,0.92,10.85-0.27c4.77-1.74,9.28-2.76,14.04-0.97
                c3.54,1.33,6.7,1.33,9.92-0.8c1.03-0.68,2.5-0.68,3.77-0.99c0,1,0,2,0,3c-1.2,0.57-2.54,0.95-3.58,1.73
                c-3.71,2.8-7.1,4.03-11.74,1.16c-2.1-1.3-6.29-1.13-8.58,0.12c-6.25,3.41-12.02,2.58-18.2,0.25c-6.03-2.27-8.73-0.97-11.94,4.85
                c1.21,1.39,2.14,3.36,3.63,4c3.29,1.4,7.32,3.69,10.23,2.83c6.68-1.96,12.6-1.5,18.49,1.73c0.97,0.53,1.6,1.69,2.39,2.57
                c-1.3,0.36-2.61,1.04-3.9,1c-2.98-0.08-6.02-0.19-8.9-0.86c-3.6-0.83-6.67-0.53-9.49,2.01c-0.43,0.39-1.96,0.34-2.31-0.08
                c-4.2-4.95-8.62-1.98-13.53-0.41c2.37,8.48,4.64,16.96,7.18,25.35c0.34,1.13,1.9,2.47,3.08,2.67c6.01,1.04,12.08,1.73,18.33,2.57
                c2.63-8.87-6.99-6.12-10.62-9.39c6.6-3.36,15.06,1.21,15.34,8.03c0.2,4.98,1.76,6.24,6.61,4.96c2.23-0.59,4.29-1.8,6.51-2.42
                c3.63-1.02,7.32-1.8,10.99-2.68c0,1.33,0,2.67,0,4c-2.87,0.75-6.13,0.88-8.55,2.37c-8.02,4.95-15.84,4.26-23.69,0.04
                c-4.27-2.3-8.59-3.6-13.39-2.3c-6.15,1.67-11.74-0.94-17.48-2.25c-3.34-0.76-6.41-1.87-8.25,2.63c-0.43,1.05-2.92,1.44-4.54,1.77
                c-2.71,0.54-6.03-0.04-8.12,1.33c-5.12,3.34-9.38,4.68-14.93,0.45c-1.97-1.5-6.25-0.45-9.32,0.17c-1.99,0.4-3.67,2.21-5.55,3.27
                c-2.72,1.54-5.08,1.24-6.89-1.54c-0.63-0.98-1.33-1.91-2.05-2.83c-5.79-7.47-9.92-8.36-17.8-3.1c-3.67,2.45-6.2,2.8-9.35-0.81
                c-3.39-3.88-8.1-4.22-12.25-1.41c-3.47,2.35-5.97,1.98-9.46,0.03c-4.17-2.33-8.85-3.88-13.47-5.23
                c-5.61-1.64-10.68-0.28-14.08,4.75c-2.54,3.75-5.5,4.06-9.5,2.75c-3.23-1.06-6.72-1.81-10.11-1.84c-10.39-0.09-20.53-0.64-29.94-6
                c-1.84-1.05-5.36-0.35-7.57,0.68c-12.47,5.82-25.64,6.86-39.03,5.99c-4.72-0.3-9.34-2.04-14.06-2.59c-2.92-0.34-6.3,1.3-8.87,0.37
                c-10.26-3.73-19.83-0.33-29.69,1.54c-3.69,0.7-8.5,0.68-11.46-1.17c-5.86-3.64-10.81-0.28-16.17,0.13
                c-6.25,0.48-10.41,3.54-13.46,8.94c-0.84,1.48-3.63,2.98-5.24,2.71c-6.95-1.14-12.05,1.91-16.39,6.54
                c-3.05,3.26-6.56,3.88-10.74,4.12c-5.56,0.32-11.06,1.63-16.61,2.19c-1.72,0.17-3.97-0.22-5.26-1.25
                c-5.4-4.29-11.42-6.95-18.25-7.25c-11.91-0.52-23.83-1.08-35.74-0.89c-5.93,0.1-11.95,2.27-17.27-1.86
                c-3.01-2.34-5.74-5.04-8.46-7.73c-2.84-2.81-5.63-3.3-9.8-2.44c-9.84,2.02-19.7,5.84-30.08,1.23c-1.8-0.8-6.17,1.2-7.57,3.17
                c-2.58,3.63-5.47,3.67-9.1,3.2c-8.29-1.08-15.77-0.01-21.26,7.45c-0.93,1.26-3.72,1.37-5.7,1.57c-1.75,0.18-3.92-0.99-5.28-0.3
                c-5.33,2.68-10.14,1.05-15.4-0.35c-5.39-1.43-11.25-1.05-16.73-2.23c-4.79-1.03-9.2-1.83-14.06-0.01c-1.93,0.72-5.02-0.21-7-1.38
                c-8.23-4.87-16.3-9.73-26.58-7.78c-1,0.19-2.52-2.37-3.8-3.65c0.25-0.37,0.5-0.74,0.74-1.11c-4.91-0.44-9.81-0.88-14.72-1.3
                c-0.83-0.07-1.71,0.17-2.49-0.04c-9-2.48-18-4.95-26.94-7.62c-1.06-0.32-2.45-1.7-2.55-2.7c-0.83-8.85-7.82-9.98-14.3-11.32
                c-4.61-0.95-10.33,1.46-12.83-4.91c-5.38,0.92-9.36-5.48-15.34-2.65c-3.18,1.5-6.95,1.96-10.52,2.32
                c-1.59,0.16-3.49-0.81-4.95-1.74c-5.04-3.19-5.95-2.58-9.86,1.58c-2.47,2.63-5.38,6.66-10.72,3.44c-2.47-1.49-6.12-1.34-9.23-1.35
                c-8.39-0.03-9.48,1.29-10.45,9.61c-0.2,1.69-1.97,4.43-3.22,4.55c-5.85,0.55-9.21,4.36-12.38,8.44c-2.61,3.36-4.75,5.71-8.48,1.07
                c-0.91-1.13-3.55-0.94-5.42-1.2c-2.97-0.43-5.95-0.73-8.91-1.85c-4.16,2.62-8.56,2.63-13.58,1.01c-2.21-0.72-5.25,0.37-7.69,1.26
                c-4.41,1.61-8.76,3.49-12.94,5.63c-4.58,2.34-8.88,2.17-13.46,0.05c-1.08-0.5-3.29-0.08-4.19,0.77
                c-5.72,5.37-13.27,3.53-19.89,4.91c-2.79,0.58-6.57-2.48-9.6-4.37c-8.69-5.41-10.26-4.77-11.71,5.37
                c-0.66,4.61-1.08,9.27-2.01,13.83c-0.31,1.52-1.89,2.77-2.88,4.15c-0.53-0.28-1.06-0.57-1.59-0.85
                c1.48-8.85,2.96-17.71,4.57-27.34c-2.6,0.59-4.11,0.71-5.42,1.28c-5.06,2.19-10.08,4.38-15.79,2.85
                c-0.15-0.04-0.37-0.04-0.49,0.04c-7.57,4.7-15.62,4.82-24.19,3.53c-2.38-0.36-5.2,3-7.99,4.29c-2.86,1.33-5.87,2.42-8.91,3.27
                c-3.72,1.05-7.72,1.28-11.26,2.7c-4.46,1.79-7.82,1.01-11.47-1.92c-3.43-2.75-7.26-5.17-11.25-7.03
                c-4.13-1.93-8.15-0.82-12.39,1.17c-3.37,1.58-7.82,2.19-11.48,1.52c-8.13-1.49-16.06-4.11-24.1-6.11
                c-1.38-0.34-2.95,0.01-4.42,0.16c-1.15,0.11-2.4,0.82-3.42,0.55c-6.79-1.82-13.02-1.31-18.65,3.4c-0.74,0.61-2.71,0.39-3.77-0.14
                c-4.45-2.21-8.87-4.53-13.13-7.1c-5.87-3.55-8.13-2.97-11.19,3.33c-2.65,5.45-6.38,6.98-12.1,4.95c-4.83-1.71-5.99-4.05-5.32-9.12
                c0.26-1.96-0.23-4.26-1.09-6.06c-1.42-2.97-4.04-5.46-5.04-8.51c-2.36-7.25-10.05-7.38-14.55-11.67c-0.48-0.45-1.68-0.52-2.4-0.29
                c-4.95,1.6-9.19,0.13-13.04-2.91c-2.77-2.18-5.03-1.19-6.89,1.12c-3.43,4.25-7.06,5-11.56,1.5c-3.81-2.97-6.45-1.25-9.1,2.27
                c-1.85,2.46-4.8,4.09-7.27,6.09c-2.17,1.76-4.69,3.22-6.41,5.33c-1.89,2.33-3.56,2.67-5.93,1.14c-2.09-1.35-4.3-2.54-6.31-4.01
                c-3.63-2.67-7.1-4.01-12-2.85c-3.99,0.95-8.5-0.45-12.78-0.49c-2.27-0.02-4.66,0.34-6.8,1.07c-4.09,1.41-7.56,1.25-11.17-1.6
                c-4.17-3.29-9.09-2.46-12.79,1.46c-3.67,3.89-7.94,4.47-11.77,0.95c-3.7-3.4-7.18-3.09-10.72-0.33
                c-3.67,2.86-7.16,3.14-10.88,0.24c-0.63-0.5-1.55-1.08-2.24-0.98c-10.71,1.49-18.6-7.13-28.65-7.91c0-1,0-2,0-3
                c2.28,0.22,4.75-0.03,6.81,0.77c4.66,1.82,6.45-0.75,8.33-4.38C10.29,875.9,5.4,875.45,0.5,875c0-0.67,0-1.33,0-2
                c2.71-1.73,4.52-3.27,8.44-0.46c5.54,3.97,13.29,0.27,15.87-7c-8.29-0.53-16.3-1.04-24.31-1.54c0-1,0-2,0-3
                c5.77,0.45,11.61,0.53,17.3,1.46c6.83,1.12,12.17,0.36,17.16-5.52c14.59-17.18,30.11-33.57,44.66-50.78
                c5.15-6.09,13.3-11.92,7.43-22.38c-1.59-2.84-2.66-4.04-5.88-3.51c-3.42,0.56-7.03,1.19-10.39,0.68
                c-10.61-1.62-21.05-1.74-31.67,0.34C26.15,783.82,13.32,780.8,0.5,779C0.5,778,0.5,777,0.5,776z M602,569.02
                c0.14,0.04,0.28,0.08,0.42,0.12c-0.16-0.02-0.33-0.03-0.49-0.05c-3.74-1.12-7.48-2.24-11.22-3.36c-0.15,0.81-0.3,1.62-0.44,2.44
                C594.18,568.45,598.09,568.74,602,569.02z M631.13,611.06c7.11-0.82,14.22-1.64,21.33-2.46c-0.09-0.51-0.17-1.02-0.26-1.53
                c-7,1.35-14.01,2.7-21.02,4.05c-2.24,0-4.47,0-6.71,0c0.04,0.47,0.09,0.94,0.13,1.41C626.79,612.04,628.96,611.55,631.13,611.06z
                M1309.52,644.07c-2.28-2.71-4.57-5.42-6.85-8.13c-0.44,0.44-0.89,0.87-1.33,1.31c2.75,2.25,5.5,4.5,8.25,6.76
                c2.48,4.33,4.96,8.66,7.44,13C1317.84,650.53,1315,646.43,1309.52,644.07z M616.1,442.01c3.75-0.52,7.51-1.04,11.26-1.56
                c0.16,0.66,0.32,1.32,0.48,1.98c-5.76,1.97-11.52,3.93-18.63,6.35c3.75,32.92-10.56,63.08-17.83,95.07
                c4.73-0.63,8.5-1.59,12.24-1.49c3.87,0.1,6.51-0.57,8.59-4.28c2.27-4.04,5.19-3.51,7.71,0.32c0.94,1.43,2.97,2.3,4.68,3
                c1.54,0.63,3.33,0.69,5,1c0.04,0.47,0.07,0.94,0.11,1.4c-2.81,0.69-5.62,1.38-9.08,2.22c1.12,5.5,2.26,10.63,3.13,15.81
                c0.21,1.22-0.51,2.6-0.8,3.9c-1.27-0.73-3.15-1.17-3.67-2.25c-0.97-2.01-1.24-4.39-1.63-6.64c-0.73-4.29-1.32-8.59-1.97-12.89
                c-0.64,0-1.28,0.01-1.91,0.01c-0.3,1.4-1.03,2.87-0.81,4.17c0.5,2.9,1.73,5.69,2.16,8.59c0.24,1.62-0.55,3.38-0.88,5.08
                c-1.28-1.13-3.23-2.03-3.72-3.43c-1.07-3.12-1.26-6.53-2.13-9.73c-0.34-1.24-1.64-3.25-2.36-3.2c-4.94,0.4-9.98,0.8-14.67,2.25
                c-1.49,0.46-1.94,4.13-2.96,6.28c-0.34,0.71-1.04,1.24-1.42,1.94c-0.89,1.63-1.69,3.31-2.54,5c8.19,1.82,15.32,3.38,22.43,5
                c2.89,0.66,7.53-1.88,7.67,4.11c0.01,0.23,1.77,0.58,2.72,0.61c4.65,0.17,9.32,0.1,13.95,0.42c3.2,0.23,4.54-0.81,4.53-4.11
                c-0.01-7.95,0.21-15.9,0.33-23.86c0.14-9.63,0.86-19.31,0.16-28.89c-0.35-4.72,0.59-7.45,4.28-10.05c1.87-1.32,4.16-3.7,4.23-5.66
                c0.37-11.28,0.19-22.58,0.05-33.87c-0.04-3.31-2.11-4.4-5.11-3.3c-5.77,2.1-11.47,4.39-17.23,6.5c-1.61,0.59-3.34,0.81-5.02,1.2
                c-0.19-0.54-0.37-1.07-0.56-1.61c1.57-1.02,3.02-2.38,4.73-3c6.24-2.26,12.61-4.17,18.85-6.46c1.44-0.53,3.54-1.95,3.62-3.08
                c0.62-8.77,0.77-17.58,1.17-26.36c0.19-4.19-2.1-5.16-5.65-4.72c-2.52,0.31-5.03,0.65-8.29,1.08c0.68-1.67,1-2.37,1.25-3.11
                c0.22-0.65,0.36-1.34,0.6-2.23c-11.57,2.52-18.84,9.1-23.02,20.15c4.13-0.71,7.51-1.29,10.89-1.86c0.09,0.49,0.18,0.97,0.27,1.46
                c-1.71,0.93-3.43,1.86-5.14,2.78c-1.85,0.55-3.69,1.11-5.54,1.66c0.2,0.42,0.4,0.85,0.6,1.27
                C612.85,444.01,614.48,443.01,616.1,442.01z M479.13,679.07c-3.42-8.13-8.02-12.1-12.22-10.3c2.21,1.89,7.19,1.81,5.01,6.83
                c-0.1,0.23,1.58,1.46,2.57,1.9c1.51,0.65,3.14,1.01,4.72,1.5c-4.25,3.65-9.17,2.14-11.95-3.21c-0.92-1.77-2.92-3.79-4.73-4.13
                c-8.62-1.63-17.34-2.68-26.01-4.04c-1.82-0.29-3.56-1.04-5.33-1.57c0.18-0.84,0.36-1.67,0.54-2.51c4.59,0.52,9.18,1.05,13.77,1.56
                c6.48,0.71,13.26,3.62,19.1-2.41c-2.24-5.72-5.23-10.91-11.88-10.61c-9.75,0.43-19.56,1.32-29.13,3.14
                c-5.24,1-10.03,4.32-14.56,6.38c1.32,3.09,2.09,4.9,2.86,6.71c-1.75-0.37-3.54-1.18-5.22-0.99c-2.38,0.27-5.62,0.57-6.82,2.14
                c-2.65,3.46-4.26,7.73-6.32,11.7c5.66,3.97,11.51,5.98,17.72,4.23c2.56-0.72,4.45-3.79,6.71-5.71c0.56-0.48,1.76-1.11,1.99-0.91
                c0.63,0.56,1.27,1.54,1.23,2.32c-0.21,4.51-5.33,9.36-9.83,8.8c-5.67-0.71-11.25-2.16-16.89-3.2c-1.93-0.35-4.19-1.14-5.8-0.49
                c-3.23,1.3-6.22,3.31-9.15,5.27c-8.21,5.48-18.35,9.12-18.53,21.81c-0.05,3.93,0,6.52,3.78,8.61c2.36,1.31,4.72,3.51,5.9,5.9
                c2.67,5.39,7.32,6.65,12.49,7.39c0.82,0.12,1.69,0.2,2.43,0.53c4.16,1.88,8.29,3.83,12.43,5.76c-0.31,0.68-0.61,1.36-0.92,2.04
                c-2.98-1.03-5.94-2.15-8.96-3.08c-2.98-0.91-6.02-1.62-10.31-2.75c3.74,9.65,7.17,17.57,9.9,25.73c3.8,11.33,2.04,9.81,13.2,9.23
                c14.93-0.77,29.26,0.49,41.73,9.99c1.2,0.91,3.09,1.25,4.67,1.26c11.48,0.03,22.97,0.14,34.43-0.34c2.53-0.11,5.49-1.96,7.35-3.88
                c3.66-3.78,6.69-8.17,10.35-12.78c-2.19-1.75-4.71-3.55-6.97-5.65c-1.13-1.04-2.67-2.74-2.5-3.92
                c1.37-9.55,4.64-18.01,14.45-22.12c3.32-1.39,6.59-2.9,10.28-4.54c-2.75-2.23-6.58-4.15-2.82-8.42c0.4-0.45-0.47-2.96-1.33-3.42
                c-6.42-3.36-6.81-9.58-8.04-15.58c-0.88-4.33-2.46-8.47-7.7-10.35c-2.04,11.18,3.2,18.86,10.34,26.12
                c-1.77,0.5-3.35,0.8-3.98,0.16c-4.36-4.42-10.05-8.15-10.21-15.24C490.78,693.78,488.11,684.95,479.13,679.07z M1237.71,311.89
                c4.05-2.29,5.75-6.86,2.81-8.53c-3.76-2.13-8.24-3.05-12.47-4.27c-3.17-0.91-7.27-0.48-9.55-2.36
                c-12.18-10.06-25.48-7.38-38.9-4.28c-2.15,0.5-4.12,1.75-6.18,2.66c0.07,0.57,0.15,1.15,0.22,1.72c5.7-0.21,11.42-0.85,17.08-0.52
                c6.12,0.36,12.23,1.39,18.27,2.56c5.75,1.12,11.39,2.79,17.08,4.22c-0.15,0.69-0.29,1.39-0.44,2.08
                c-14.4-2.29-28.64-6.27-43.27-6.27c-0.07,0.54-0.14,1.08-0.21,1.62c9.53,1.28,19.05,2.56,28.58,3.85
                c-0.06,0.66-0.12,1.32-0.18,1.99c-4.79-0.3-9.59-0.59-14.38-0.89c-6.45-0.4-12.96-1.52-19.34-1.03
                c-8.82,0.67-17.67,5.94-15.2,18.15c0.44,2.18,0.4,4.8-0.38,6.84c-2.83,7.36-3.51,14.82-2.12,22.52c1.21,6.67,2.96,13.28,3.63,20
                c1.33,13.4,2.15,26.85,3.03,40.29c0.16,2.45-0.33,4.96-0.6,7.43c-1.14,10.37-2.72,20.71-3.35,31.1c-0.44,7.31,1.73,8.98,8.8,6.87
                c14.14-4.22,28.27-1.15,42.45-1.11c12.24,0.03,25.88,3.61,35.36-8.99c3.59,4.24-2.15,6.31-2.15,8.69
                c12.62,5.62,24.86,11.08,37.45,16.69c0.1-0.73,0.48-2.14,0.43-3.53c-0.3-7.38,0.2-14.66-2.03-22.09
                c-4.49-14.93-7.76-30.23-11.54-45.37c-1.29-5.17-2.37-10.4-3.97-15.47c-3.9-12.38-7.46-24.92-12.29-36.94
                c-3.63-9.04-11.06-16.32-12.15-26.75c-0.39-3.77-2.89-7.33-4.42-10.99L1237.71,311.89z M899.89,555
                c5.36-1.07,10.69-2.31,16.09-3.17c13.29-2.11,26.6-4.09,39.91-6.05c23.53-3.48,47.22-5.25,70.96-6.39
                c3.37-0.16,6.75-0.02,10.13-0.02c0.35-0.4,0.69-0.81,1.04-1.21c-5.35-5.22-10.38-10.83-16.17-15.49
                c-2.96-2.38-7.35-2.94-10.98-4.56c-2.07-0.92-4.57-1.85-5.77-3.55c-8.16-11.54-18.67-20.73-29.36-29.68
                c-3.58-2.99-7.96-5.33-12.34-6.99c-4.73-1.79-10.11-1.89-14.81-3.75c-5.28-2.09-10.37-1.99-15.64-0.94
                c-4.23,0.85-8.39,2.34-12.65,2.69c-7.56,0.61-10.48,6.45-13.37,11.74c-3.51,6.43-5.73,8.05-11.87,3.71
                c-6.67-4.72-14.06-6.52-21.69-7.92c-6.46-1.19-13-2.17-19.55-2.65c-3.39-0.25-6.87,0.81-10.32,1.26
                c-9.54,1.25-18.07,4.76-27.09,12.04c8.68,3.44,16.16,6.22,23.46,9.39c2.85,1.24,5.88,2.89,7.84,5.19
                c2.99,3.51,4.54,4.04,8.14,0.97c0.68-0.58,1.72-0.9,2.64-1c14.75-1.69,29.55-3.05,44.26-5.04c16.46-2.22,32.86-4.89,49.23-7.63
                c3.77-0.63,6.23,0.38,8.83,3c3.14,3.16,6.44,6.65,10.38,8.39c4.04,1.79,8.94,1.66,13.46,2.36c0.1-0.5,0.2-1,0.3-1.5
                c-4.96-1.1-9.91-2.19-14.87-3.29c0.17-0.64,0.34-1.27,0.51-1.91c7.27,0.49,15.23-2.15,21.13,5.38c-2.82,5.9-8,5.18-12.92,4.81
                c-8.92-0.67-17.52-3.84-23.17-10.22c-3.56-4.03-5.88-4.49-10.2-3.5c-14.23,3.28-28.36,7.33-43.22,6.59
                c-1.15-0.06-2.53-0.15-3.43,0.39c-1.57,0.93-2.85,2.35-5.24,4.42c13.42,0,25.07,0,36.72,0c-1.84,1.48-3.81,2.14-5.82,2.3
                c-8.79,0.66-17.59,1.35-26.4,1.66c-4.03,0.14-6.85,0.77-6.55,5.67c0.04,0.7-0.97,1.46-1.54,2.26c-1.86-2.54-3.06-5.84-5.23-6.71
                c-2.7-1.08-6.21-0.05-9.37-0.1c-16.9-0.25-33.81-0.29-50.7-0.95c-8.21-0.32-16.39-1.71-24.55-2.86
                c-7.21-1.02-14.34-2.94-21.57-3.42c-7.39-0.49-14.88,0.11-22.3,0.62c-2.6,0.18-5.12,1.51-7.68,2.32c3.4,0.73,6.53,0.81,9.66,0.71
                c2.92-0.1,6.08-1.8,8.84,1.6c-0.99,0.58-1.62,1.23-2.31,1.31c-5.25,0.59-10.6,0.62-15.75,1.68c-7.7,1.57-15.64,1.33-23.03,5.3
                c-17.42,9.34-27.38,23.65-30.95,42.79c-0.22,1.16-0.03,2.39-0.03,5.23c7.11-7.86,13.25-14.64,19.84-21.93
                c0.57,1.97,0.93,3.21,0.95,3.27c6.81,0.3,12.85,0.66,18.89,0.81c3.13,0.07,7.26,0.99,9.21-0.6c6.22-5.05,13.27-4.61,20.35-4.8
                c7.15-0.19,14.3-0.23,21.45-0.31c3.82-0.04,7.63-0.01,11.45-0.01c0.01,0.94,0.03,1.88,0.04,2.83c-15.23,0.5-30.46,1-45.69,1.5
                c0.01,0.48,0.02,0.97,0.02,1.45c0.9,0.16,1.79,0.42,2.7,0.47c9.8,0.52,19.69,0.36,29.4,1.63c19.11,2.49,38.2,5.37,57.15,8.89
                c10.54,1.95,20.59,0.98,30.39-2.35c15.58-5.29,30.59-12.54,46.45-16.66c23.78-6.17,48.08-10.37,72.17-15.33
                c1.08-0.22,2.33,0.36,3.5,0.57c0.02,0.46,0.03,0.91,0.05,1.37c-2.81,0.42-5.64,0.71-8.42,1.28c-18.89,3.92-37.9,7.4-56.6,12.08
                c-9.72,2.43-18.9,7.01-28.32,10.62c-0.23,0.08-0.46,0.17-0.43,0.16C899.48,555.24,899.69,555.12,899.89,555z M857.15,622.84
                c-1.88,5.39-0.33,8.8,4.9,10.74c6.57,2.43,7.67,9.71,11.93,14.24c0.58,0.61,0.4,2.29,0.09,3.34c-1.58,5.38-0.74,10.36,1.46,15.47
                c2.84,6.59,3.73,14.74,14.32,13.38c-1.74,1.7-3.62,2.67-3.86,3.94c-1.04,5.48-1.61,6.27-6.27,2.82
                c-6.45-4.77-13.37-4.98-20.76-4.65c-7.13,0.32-14.29,0.03-21.43,0.28c-8.22,0.28-16.46,1.43-24.65,1.12
                c-7.71-0.29-15.34-2.34-23.06-2.98c-16.03-1.34-32.08-3.92-48.18-0.43c-1.76,0.38-3.67,0.05-5.51,0.05
                c0.7-1.73,1.57-2.68,2.52-2.78c7.59-0.8,15.23-2.24,22.8-1.94c14.79,0.58,29.52,2.47,44.31,3.22c15.44,0.79,30.91,0.91,46.36,1.25
                c5.35,0.12,10.71,0.02,16.21,0.02c-2.14-7.16-4.81-12.8-5.33-18.62c-0.83-9.31-7.69-13.96-13.13-19.76
                c-2.11-2.25-4.9-5.35-7.43-5.41c-7.43-0.16-10.53-5.63-13.61-10.33c-6.9-10.53-12.95-21.63-19.27-32.54
                c-0.57-0.98-0.63-2.26-0.92-3.4c0.51-0.34,1.02-0.68,1.53-1.02c2.87,2.85,5.55,5.87,7.64,9.49c5.57,9.65,11.05,19.36,16.79,28.91
                c1.03,1.71,3.15,2.76,4.32,3.74c1.58-7.47,2.64-14.03,4.39-20.4c3.14-11.5,6.5-22.94,10.13-34.29c2.42-7.56,2.3-8.11-5.42-9.21
                c-20.72-2.96-41.38-6.51-62.18-8.78c-15.02-1.64-30.22-1.58-45.34-2.32c-2.27-0.11-4.83,0.12-6.74-0.84
                c-9.64-4.82-21.85,0.6-24.57,11.11c-0.22,0.84-1.01,1.53-1.53,2.29c-0.2-0.55-0.4-1.1-0.6-1.65c-2.45,0.34-5.17,0.13-7.3,1.14
                c-3.87,1.83-6.68,4.79-7.04,9.56c-0.34,4.53-0.79,9.1-1.75,13.53c-0.8,3.66-2.77,7.06-3.64,10.71
                c-5.33,22.5-10.24,45.1-15.89,67.52c-1.24,4.92-4.12,9.94-7.58,13.67c-6.17,6.66-13.39,12.35-20.11,18.5
                c-1.47,1.34-2.7,2.94-4.87,5.34c2.23-0.13,3.02-0.1,3.79-0.24c7.16-1.27,14.28-3.39,21.48-3.7c13.95-0.59,27.94-0.21,41.92-0.14
                c10.46,0.06,20.94-0.06,31.38,0.49c11.06,0.58,22.09,1.87,33.13,2.84c2.19,0.19,4.38,0.33,6.56,0.5c0,0.56,0.01,1.11,0.01,1.67
                c-6.64,0.5-13.35,1.83-19.91,1.34c-14-1.06-27.9-3.42-41.89-4.56c-8.59-0.7-17.28-0.29-25.92,0c-1.46,0.05-3.57,1.69-4.13,3.09
                c-1.08,2.73-1.11,5.86-1.93,8.73c-0.29,1-1.62,1.71-2.48,2.55c-0.5-1.21-1.63-2.56-1.4-3.61c0.74-3.34,1.99-6.57,3.59-11.54
                c-12.51,1.67-23.94,3.2-35.37,4.71c-3.31,0.44-6.63,0.82-9.95,1.23c-0.73-0.55-1.51,0.36-1.31,0.91c2.12,5.82-2.39,7.46-5.97,9.66
                c-1.98,1.22-4.32,2.02-6,3.55c-3.03,2.77-5.69,5.95-8.51,8.96c0.32,0.48,0.64,0.95,0.97,1.43c1.93,0,3.87-0.13,5.79,0.02
                c7.28,0.6,14.99-0.01,21.71,2.27c8.52,2.89,16.63,3.66,25.38,2.46c4.12-0.57,8.51,0.3,12.71,0.92c5.04,0.75,10.13,3.14,15.01,2.7
                c11.89-1.06,23.75-2.92,35.46-5.28c7.72-1.55,15-0.85,22.65,0.25c9.6,1.37,18.75,4.8,28.87,4.23c9.15-0.51,18.45,1.75,27.7,2.69
                c20.52,2.1,41.05,4.01,61.55,6.23c15.68,1.7,31.33,5.3,46.98,5.27c16.65-0.03,33.34-3.07,49.93-5.36c7.2-1,14.21-4.97,21.29-4.94
                c23.41,0.12,46.83,1.37,70.23,2.38c6.58,0.28,13.14,1.24,21.18,2.04c0-9.37-0.09-18.3,0.02-27.22
                c0.14-11.09,0.51-22.17,0.65-33.26c0.04-3.12,0.18-6.42-0.68-9.35c-2.95-10.03-6.18-19.98-9.62-29.86
                c-1.45-4.18-3.69-8.09-5.8-12.62c0.19-0.27,1.04-1.48,1.93-2.74c-2.41-1.07-4.64-1.41-5.86-2.71
                c-6.89-7.31-15.93-9.15-25.21-10.69c-1.26-0.21-2.3-1.8-3.44-2.75c1.49-0.46,3.27-1.7,4.43-1.27c8.95,3.31,18.94,4.42,26.12,11.73
                c0.21,0.21,0.87-0.01,1.91-0.06c-1.21-3.54-2.11-6.88-3.46-10.03c-8.91-20.71-15.04-42.34-21.09-63.97
                c-3.87-13.85-4.65-14.67-18.52-13.43c-16.04,1.44-32.09,2.71-48.11,4.27c-14.72,1.44-29.52,2.55-44.09,4.97
                c-13.42,2.23-26.56,6.12-39.88,9.03c-3.86,0.84-7.99,0.47-11.85,1.32c-5.66,1.25-11.19,3.11-16.29,4.56
                c-0.23,0.98-0.3,1.98-0.68,2.83c-0.72,1.59-2.2,3.05-2.36,4.66c-0.78,7.94-1.08,15.93-1.86,23.87
                c-0.66,6.73-1.76,13.41-2.83,21.33c10.76,0,21.04-1.17,30.91,0.32c10.29,1.56,19.82,0.26,29.5-2.39c2.09-0.57,4.18-1.15,6.31-1.54
                c1.13-0.21,2.32-0.04,4.49-0.04c-1.18,1.42-1.49,2.16-1.96,2.3c-11.94,3.54-23.65,7.04-36.65,5.24
                C878.86,621.68,867.96,622.84,857.15,622.84z M297.23,884.97c0.44,0.03,1.56,0.24,2.63,0.13c11.52-1.23,19.56-9.96,20.82-22.54
                c0.81-8.1,9.32-18.25,17.1-18.86c-1.84,2.55-3.14,4.45-4.54,6.26c-2.44,3.14-5.85,5.87-7.25,9.41
                c-2.63,6.63-4.13,13.72-6.22,21.05c0.81,0.06,2.23,0.35,3.62,0.23c5.93-0.51,11.88-0.97,17.76-1.83c1.94-0.28,3.8-1.58,5.56-2.64
                c3.94-2.37,7.59-6.29,11.76-6.92c4.15-0.63,7.54-1.64,10.85-3.99c2.76-1.96,5.92-3.82,9.08-0.97c3.34,3.02,5.77,2.38,8.19-1.02
                c3.08-4.33,7.22-5.49,12.32-4.1c2.72,0.74,5.55,1.29,8.35,1.43c4.48,0.22,6.01-1.89,4.61-6.12
                c-6.74-20.36-13.61-40.68-20.26-61.07c-2.06-6.32-3.48-12.86-5.43-19.22c-0.54-1.78-1.61-4.32-2.99-4.76
                c-4.82-1.53-9.88-3.16-14.85-3.17c-9.11-0.02-18.23,1.16-27.35,1.64c-1.35,0.07-2.75-0.91-4.13-1.41
                c1.06-0.89,1.99-2.19,3.21-2.58c2.51-0.79,6.02-0.22,7.61-1.77c5.43-5.27,12.27-4.34,18.66-5.18c4.01-0.53,8.14-0.1,13.92-0.1
                c-4.49-9.39-8.43-17.61-11.67-24.4c-8.59,0-15.32,0-22.04,0c-0.1-0.85-0.2-1.71-0.3-2.56c5.08-1.09,10.15-2.17,16.64-3.56
                c-4.06-2.38-7.15-3.89-9.9-5.89c-6.23-4.51-13.23-8.23-14.2-17.42c-0.29-2.74-2.81-5.81-5.15-7.69
                c-11.65-9.34-25.43-13.56-40.04-14.89c-15.83-1.44-31.34,4.84-47.54,1.37c-8.51-1.83-17.37,1.72-25.48,5.54
                c-2.55,1.2-5.3,1.97-7.96,2.93c0.06,0.46,0.13,0.92,0.19,1.38c5.51,1.25,11.03,2.5,16.54,3.76c-0.07,0.55-0.14,1.1-0.21,1.65
                c-1.02,0.2-2.08,0.72-3.04,0.56c-5.22-0.9-10.39-2.3-15.64-2.84c-2.82-0.29-5.8,0.7-8.66,1.29c-2.17,0.45-4.25,1.55-6.43,1.73
                c-19.05,1.58-38.11,2.92-57.15,4.58c-3.71,0.32-7.32,1.71-12.11,2.88c6.23,1.94,10.84,3.7,15.62,4.77
                c4.66,1.04,9.54,2.19,14.23,1.86c11.57-0.81,23.05-2.66,34.61-3.7c25.61-2.3,51.19-5.54,76.98-3.27c2.11,0.19,4.15,1.25,6.22,1.9
                c-0.13,0.63-0.25,1.26-0.38,1.88c-2.85,0.23-5.69,0.46-8.51,0.69c-0.71,4.74-3.2,6.31-10.06,4.96c2.72-1.31,4.48-2.15,6.23-2.99
                c-0.34-0.62-0.68-1.24-1.02-1.86c-5.72-0.63-11.49-2.05-17.16-1.74c-14.8,0.8-29.56,2.35-44.25,3.6
                c17.33,0.96,34.73,1.92,52.12,2.88c0.02,0.75,0.03,1.5,0.05,2.25c-5.68,0.29-10.81,3.35-17.11,1.03
                c-4.2-1.55-8.99-1.72-13.55-1.97c-9.38-0.51-18.79-0.65-28.18-0.97c-4.9-0.17-10.08-1.5-14.64-0.35
                c-11.24,2.84-22.26,2.91-33.49,0.53c-6.77-1.43-13.58-3.05-20.45-3.55c-4.56-0.34-9.26,1.14-13.9,1.81
                c0.06,0.56,0.11,1.12,0.17,1.68c0.91,0.35,1.79,0.87,2.73,1.04c17.48,3.13,34.93,6.48,52.48,9.22c15.34,2.4,30.36-0.54,45.38-3.6
                c6.14-1.25,12.57-1.82,18.82-1.55c10.68,0.46,21.32,1.81,31.98,2.82c22.83,2.16,45.66,4.34,68.48,6.55
                c2.12,0.21,4.2,0.71,6.31,1.07c0.02,0.54,0.04,1.08,0.06,1.62c-1.56,0.66-3.07,1.71-4.68,1.92c-4.93,0.63-9.9,1.03-14.87,1.36
                c-4.79,0.32-9.72,1.25-14.36,0.49c-14.9-2.46-29.63-5.91-44.54-8.3c-6.48-1.04-13.23-0.22-19.84-0.59
                c-17.36-0.98-34.6,0.22-51.48,4.09c-9,2.06-17.61,4.48-26.83,1.68c-2.96-0.9-6.2-0.88-9.31-1.32c-6.07-0.84-12.45-0.8-18.14-2.77
                c-8.64-2.99-17.21-5.7-26.39-5.8c-1.64-0.02-3.96,0.61-4.82,1.79c-5.38,7.35-10.87,14.69-15.36,22.58
                c-1.52,2.66-0.52,6.95-0.15,10.44c0.33,3.12,1.27,6.19,2.16,9.22c1.56,5.32,3.28,10.59,4.75,15.34c5.66-1.38,10.4-2.54,14.47-3.53
                c-2.01-5.49-3.88-10.66-5.82-15.8c-0.22-0.58-1.02-0.94-1.25-1.53c-1.23-3.12-2.59-6.23-3.4-9.46c-0.24-0.96,1.14-2.34,1.78-3.52
                c0.95,1.13,2.26,2.12,2.75,3.42c0.74,1.95,0.66,4.21,1.38,6.17c1.23,3.33,3.11,6.44,4.19,9.81c1.26,3.92,6.55,8.41,10.04,8.09
                c6.77-0.63,13.56-1.06,20.33-1.73c7.77-0.77,15.64-1.12,23.25-2.71c12.98-2.7,25.78-5.77,39.18-6.41
                c18.41-0.87,36.75-3.07,55.14-4.56c3.46-0.28,7.82-1.18,10.27,0.45c5.53,3.68,10.77,1.55,16.19,1.21
                c1.39-0.09,2.79-0.01,4.18-0.01c0.12,0.62,0.25,1.24,0.37,1.87c-1.85,0.76-3.66,2.07-5.54,2.18c-8.11,0.47-16.25,0.6-24.38,0.82
                c-8.11,0.22-16.27-0.06-24.34,0.69c-12.53,1.17-24.99,3.03-37.48,4.62c-12.03,1.53-24.12,2.76-36.08,4.74
                c-10.77,1.78-21.41,4.36-32.1,6.66c-5.49,1.18-11.1,2.09-16.37,3.94c-3.68,1.29-6.69,4.44-10.33,5.95
                c-8.27,3.42-10.62,11.53-15.35,18.04c9.09-1.3,17.84-3.69,26.9-1.66c-1.41,1-2.91,1.28-4.41,1.58c-8.48,1.69-17.02,3.14-25.4,5.23
                c-2.77,0.69-5.11,3.07-7.65,4.68c0.27,0.37,0.54,0.74,0.81,1.11c9.31-1.28,18.6-2.84,27.94-3.77c9.4-0.93,18.86-1.46,28.31-1.76
                c9.17-0.29,18.35-0.06,27.52-0.06c0.04,0.43,0.08,0.86,0.12,1.3c-4.88,0.65-9.74,1.54-14.63,1.88
                c-6.41,0.45-12.86,0.32-19.28,0.69c-4.31,0.25-8.66,1.68-12.87,1.28c-14.25-1.38-27.77,2.14-41.36,5.33
                c-1.75,0.41-3.56,1.86-4.69,3.33c-9.13,11.82-21.73,20.75-28.66,34.41c-0.24,0.48-1.19,0.56-1.7,0.96
                c-2.48,1.99-4.92,4.03-7.58,6.22c1.59,1.46,2.43,2.24,4.38,4.04c-7.2-1.09-10.66,1.97-13.62,7.97c7.25,2.47,14.4,3.62,21.63,4.44
                c0.42,0.05,0.94-0.33,1.35-0.61c5.05-3.51,9.75-2.96,14.81,0.4c1.47,0.97,5.6,0.65,6.36-0.51c3.29-4.95,9.42-6.58,12.63-4.55
                c7.91,5.02,15.66,1.69,23.44,2.18c3.45,0.21,7.19,1.97,10.25,1.1c6.08-1.73,10.62-0.34,15.08,3.7c1.43,1.29,4.51,2.75,5.6,2.09
                c5.01-3.03,10.08-6.29,14.19-10.41c4.69-4.7,9.43-6.7,15.06-3.58c2.89,1.6,4.3,0.61,6.52-0.95c2.3-1.62,6.09-4.09,7.65-3.25
                c6.43,3.47,12.91,4.95,20.16,4.69c2.07-0.07,4.2,3.41,6.36,3.49c6.55,0.23,7.7,5.25,10.01,9.58c2.29,4.28,5.03,8.31,7.52,12.36
                c5.55-3.17,10.75-5.12,14.61-8.59c4.93-4.43,10.19-6.21,16.6-6.79c4.66-0.42,9.17-2.53,13.76-3.82c2.25-0.63,4.58-1.68,6.8-1.52
                c6.55,0.49,13.06,1.48,19.58,2.28c-0.19-0.68-0.38-1.36-0.57-2.04c1.21-1.01,2.29-2.6,3.66-2.94c6.8-1.67,11.9-5.28,15.97-11.09
                c3.13-4.48,4.19-9.24,4.88-14.39c0.14-1.03,1.76-1.87,2.7-2.79c0.48,1.12,1.31,2.22,1.39,3.36c0.37,5.46-0.41,10.76-4.24,14.94
                C306.59,875.73,301.88,880.24,297.23,884.97z M1224.03,900.9c0.22-0.44,0.44-0.88,0.66-1.31c-9.86-9.29-18.63-19.92-31.72-25.27
                c-0.63-0.26-1.02-1.16-1.46-1.8c-1.08-1.56-1.84-3.48-3.23-4.67c-5.31-4.52-10.71-8.97-16.29-13.16
                c-13.3-9.97-26.77-19.72-40.07-29.67c-3.95-2.95-8.32-5.67-9-11.36c-0.3-2.51-0.31-5.51-1.68-7.37
                c-6.29-8.56-13.03-16.8-19.6-25.16c-1.92-2.44-4.02-2.76-7-1.62c-2.12,0.81-5.14,1.25-6.99,0.32c-3.84-1.93-13.87-1.74-17.64-0.38
                c-4.93,1.78-10.4,2.08-15.63,3c-0.12,0.02-0.28-0.16-0.66-0.39c0-1.08,0-2.31,0-3.68c-23.96,0-47.72,0-71.48,0
                c0,0.91-0.01,1.83-0.01,2.74c21.42,0,42.84,0,64.27,0c0.04,0.69,0.09,1.38,0.13,2.08c-1.15,0.37-2.29,1.04-3.45,1.07
                c-12.6,0.3-25.21,0.84-37.81,0.66c-23.71-0.33-47.41-1.27-71.12-1.62c-18.72-0.28-37.42,0.75-56.17-1.1
                c-16.36-1.61-32.91-1.27-49.34-2.34c-10.08-0.66-20.08-2.49-30.15-3.58c-9.07-0.98-18.2-1.43-27.25-2.57
                c-4.04-0.51-9.23,1.99-11.86-3.59c-0.1-0.22-1.47-0.21-1.87,0.13c-4.37,3.77-9.54,1.52-14.33,2.04
                c-7.76,0.85-15.5,2.21-23.28,2.56c-13.92,0.63-27.93,0-41.8,1.15c-11.51,0.95-23.35-1.93-34.53,2.8
                c-4.77-2.39-6.99,1.15-9.59,4.03c-8.56,9.46-17.2,18.86-25.78,28.31c-0.66,0.73-1.85,1.81-1.67,2.33c1.6,4.52-1.74,4.65-4.51,5.75
                c-1.53,0.61-2.8,2.18-3.88,3.55c-1.74,2.22-3.42,4.52-4.79,6.98c-4.43,7.96-8.78,15.96-12.96,24.05c-0.56,1.08-0.4,2.95,0.15,4.1
                c1.77,3.74,4.08,7.22,5.79,10.98c1.48,3.24,3.08,5.23,7.15,4.26c1.78-0.42,4.19,0.01,5.78,0.96c4.72,2.8,9.53,2.11,13.92,0.13
                c7.62-3.43,14.74-0.39,20.14,3.28c3.96,2.69,7.46,3.46,11.66,4.48c6.08,1.48,12.78,2.27,16.41,8.94c1.47,2.71,3.85,5.72,6.54,6.8
                c6.22,2.52,12.8,4.41,19.39,5.7c6.31,1.23,12.82,1.49,19.26,1.97c7.72,0.58,11.57-2.25,11.12-9.7
                c-0.69-11.18,9.74-15.02,13.86-22.94c0.33-0.63,2.04-0.41,2.7-1.03c1.62-1.53,3.25-3.16,4.41-5.05c2.28-3.71,3.36-8.68,6.48-11.26
                c9.45-7.83,19.26-15.58,32.78-14.8c1.75,0.1,3.64-0.55,5.32-1.21c1.16-0.45,2.73-1.37,2.97-2.35c0.94-3.93,3.83-4.06,6.92-4.27
                c3.11-0.21,7.24,0.52,9.09-1.17c4.42-4.03,7.55-0.88,11.2,0.48c3.96,1.48,8.06,4.32,11.92,4.05c12.77-0.89,19.66,7.51,26.68,15.59
                c7.96,9.16,9.43,22.85,21.25,29.94c0.76-2.4,1.51-5.05,2.44-7.64c1.5-4.17,2.59-8.6,4.81-12.36c3.69-6.24,7.84-12.27,12.34-17.95
                c4.1-5.18,8.79-9.93,13.51-14.58c1.57-1.55,3.93-2.61,6.11-3.15c7.09-1.74,14.23-3.4,21.43-4.54c3.58-0.57,7.36,0.14,11.05,0.14
                c2.98,0,5.97-0.07,8.94-0.32c4.62-0.39,9.22-1.29,13.83-1.35c5.26-0.06,11.07-0.7,15.66,1.24c5.05,2.14,9.36,6.56,13.29,10.66
                c4.86,5.07,6.46,13.24,13.57,16.12c8.52,3.46,10.98,10.14,12.22,18.31c0.5,3.27,2.78,6.24,4.11,9.41c1.15,2.75,2.93,5.57,3,8.4
                c0.24,10.56,3.88,16.59,13.88,18.84c4.27,0.96,9.13-0.32,13.66-1.02c4.9-0.76,10.25-3.87,14.47-2.67
                c8.18,2.34,16.17,0.98,24.21,1.52c1.47,0.1,2.92,0.58,4.38,0.83c3.73,0.64,7.55,2.09,11.17,1.7c10.76-1.17,21.68-1.44,31.97-5.88
                c3.15-1.36,8.03-1.75,10.86-0.24c7.18,3.83,14.43,5.29,22.44,5.18c5.03-0.07,10.06,1.41,15.13,1.83c1.69,0.14,4.01-0.27,5.14-1.37
                c5.91-5.73,12.56-7.09,20.2-4.36C1219.45,899.29,1221.74,900.09,1224.03,900.9z M1194.87,596.58c16.66-5.01,33.28-3.3,49.96-3.08
                c-0.11-2.01-0.37-3.51-0.2-4.95c0.07-0.62,0.95-1.49,1.57-1.6c0.59-0.1,1.82,0.53,1.94,1.04c1.67,7.1,7.45,6.48,12.67,6.87
                c2.98,0.23,5.97,0.31,8.96,0.46c-0.01,0.45-0.01,0.9-0.02,1.34c-2.82,0.45-5.63,0.9-8.7,1.39c0.66,2.3,1.17,4.05,1.77,6.14
                c-4.59-0.56-5.63-7.09-11.38-5.19c1.39,5.48,2.84,10.73,4.03,16.04c0.67,2.98,2.3,4.02,5.24,4.15c2.93,0.13,5.83,0.86,8.75,1.33
                c-0.06,0.46-0.13,0.92-0.19,1.37c-2.78,0.19-5.56,0.37-8.34,0.56c-0.11,0.49-0.22,0.98-0.33,1.47c5.85,3.07,11.71,6.14,18.66,9.78
                c-0.67-6.66-0.99-11.58-1.7-16.45c-0.88-6.09-1.85-12.18-3.23-18.17c-0.72-3.15-2.19-6.19-3.66-9.11
                c-6.52-12.99-20.86-17.07-30.52-26.5c-0.69-0.67-2.21-0.59-3.36-0.68c-9.28-0.76-18.55-1.69-27.85-2.13
                c-8.23-0.39-16.48-0.24-24.73-0.27c-0.62,0-1.4,0.2-1.84,0.6c-4.02,3.72-8.8,3.01-13.68,2.83c-10.13-0.38-20.27-0.47-30.41-0.44
                c-3.76,0.01-5.82,1.76-4.82,6.13c2.36,10.37,4.61,20.76,6.59,31.21c0.69,3.62,2.1,4.07,5.45,3.32c9.41-2.11,18.89-3.88,28.38-5.62
                c2.1-0.38,4.33-0.06,6.5-0.06c-0.03,0.51-0.07,1.03-0.1,1.54c-1.13,0.14-2.28,0.19-3.39,0.42c-10.91,2.23-21.83,4.43-32.71,6.84
                c-1.1,0.24-1.91,1.81-2.85,2.77c0.95,0.62,1.93,1.81,2.84,1.76c9.25-0.48,18.49-1.19,27.74-1.74c1.8-0.11,3.62,0.16,5.44,0.26
                c-1.1,1.36-2.16,1.81-3.24,1.88c-7.47,0.44-14.94,0.94-22.41,1.16c-9.61,0.27-11.69,6.76-9.19,13.66c1.13,3.1,2.06,6.33,2.58,9.58
                c2.58,16.25,4.83,32.55,7.54,48.78c1.53,9.17,1.83,9.05,10.97,8.38c11.71-0.85,22.86-3.69,33.09-8.34
                c0.22-8.05-7.37-13.34-5.84-21.45c3.45,4.67,5.94,9.77,8.67,14.73c2.02,3.66,4.35,7.14,6.54,10.7c0.64,0.6-0.78,1.84-0.6,2.76
                c0.35,1.72,1.51,4.56,2.54,4.65c9.75,0.89,19.53,1.34,29.31,1.84c2.6,0.13,5.2,0.02,7.81,0.02c0.02,0.48,0.05,0.95,0.07,1.43
                c-2.03,0.37-4.06,1.06-6.09,1.05c-8.63-0.07-17.26-0.17-25.87-0.61c-5.45-0.28-5.96,0.47-4.73,5.83
                c1.48,6.44,2.53,13.01,3.24,19.58c1.33,12.38,1.52,24.91,6.04,36.79c1.22,3.2,2.85,4.92,6.31,5.33c6.77,0.8,13.51,1.9,20.26,2.95
                c1.26,0.19,2.47,0.7,3.7,1.06c-0.17,0.74-0.33,1.49-0.5,2.23c-11.53-1.79-23.05-3.58-34.58-5.37c0.36-0.49,0.73-0.98,1.09-1.48
                c-0.45-3.15-0.86-6.31-1.37-9.45c-1.09-6.69-2.45-13.33-3.31-20.05c-1.07-8.41-1.23-16.96-2.8-25.26
                c-0.95-5.04-1.21-11.73-9.09-12.04c-0.1,0-0.18-0.57-0.26-0.88c1.32-1.06,2.64-2.12,3.96-3.18c-0.23-0.38-0.45-0.77-0.68-1.15
                c-1.19,0.3-2.44,0.46-3.55,0.93c-10.4,4.47-20.7,9.18-31.19,13.4c-3.96,1.59-4.16,3.53-3.12,7.23
                c3.8,13.52,7.36,27.11,10.67,40.75c0.77,3.19,2.04,3.58,5.02,3.82c7.62,0.61,15.19,1.95,22.78,3c-0.07,0.63-0.14,1.27-0.21,1.9
                c-2.88-0.18-5.76-0.28-8.63-0.56c-3.79-0.38-7.56-1.17-11.35-1.23c-1.69-0.03-3.4,1.32-5.1,2.04c1.31,1.91,2.21,4.72,4.02,5.56
                c3.71,1.72,7.85,2.81,11.93,3.42c23.03,3.42,46.08,6.75,69.16,9.82c6.23,0.83,12.59,0.67,18.9,0.97
                c-0.01-9.63-7.55-5.43-11.58-8.09c1.4-0.93,2.41-1.6,3.41-2.26c-0.1-0.34-0.19-0.68-0.29-1.01c-4.55,0-9.1,0-13.64,0
                c-0.05-0.6-0.09-1.2-0.14-1.81c2.77-0.44,5.61-1.5,8.3-1.2c5.52,0.62,10.99,1.8,16.4,3.12c2.08,0.51,3.82,2.35,5.9,2.96
                c3.98,1.17,8.11,1.84,12.14,2.85c1.03,0.26,1.92,1.04,2.88,1.58c-0.13,0.25-0.26,0.49-0.38,0.74c-6.15-0.79-12.3-1.58-18.62-2.4
                c-0.88,5.43,0.22,9.04,4.9,10.85c4.65,1.8,9.34,3.5,14.01,5.26c3.69,1.39,6.81,3.39,8.58,7.2c-8.98-3.57-17.52-6.96-26.52-10.55
                c-0.67,4.87-1.17,8.54-1.67,12.22c-0.64-0.04-1.28-0.07-1.92-0.11c0-2.59-0.05-5.18,0.01-7.76c0.18-7.21-0.98-8.14-8.03-9.03
                c-21.1-2.66-42.17-5.63-63.25-8.46c-10.47-1.41-20.95-2.78-31.94-4.24c0.9,5.8,1.32,11.27,2.7,16.49c1,3.8,3.15,7.34,5.03,10.86
                c0.79,1.47,2.06,3.54,3.36,3.75c6.04,0.96,12.18,1.25,18.26,1.99c9.81,1.2,20.04-0.8,29.44,4.01c1.37,0.7,3.69-0.22,5.51-0.67
                c1.99-0.48,3.91-1.86,5.85-1.84c12.8,0.13,25.61,0.22,38.39,0.95c9.27,0.53,18.49,2.03,27.73,3.09c2.91,0.33,5.28,0.41,4.87-3.88
                c-2.07-21.55-5.01-42.97-10.6-63.91c-4.54-17.02-9.5-33.92-14.39-50.85c-2.35-8.14-4.96-16.2-7.45-24.3
                c-1.17-3.8-2.69-7.54-3.43-11.42c-1.31-6.85-2.05-13.81-3.25-20.68c-0.26-1.48-1.06-3.54-2.21-4.09
                c-5.5-2.62-11.2-4.81-18.18-7.71c1.45,8.51,1.58,16.55,4.37,23.53c2.54,6.36,3.82,11.73,0.7,17.95c-1.31,2.61-0.25,3.93,2.65,5.57
                c5.98,3.37,11.26,7.97,16.83,12.06c-0.26,0.48-0.52,0.95-0.78,1.43c-4.42-1.27-8.84-2.54-13.84-3.98
                c-0.24-2.08,0.52-5.91-4.63-5.62c-0.53,8.77,1.01,16.29,9.07,21.21c5.54,3.38,10.93,7.01,16.34,10.6
                c1.32,0.88,2.45,2.05,3.67,3.08c-0.39,0.54-0.77,1.09-1.15,1.63c-7.14-4.1-14.29-8.21-21.43-12.31c-0.48,0.29-0.95,0.59-1.43,0.88
                c1.35,5,2.71,10,4.06,15c-4.78-2.26-5.98-6.35-7.01-10.7c-1.33-5.59-3.04-11.09-4.53-16.65c-0.55-2.05-1.35-4.16-1.3-6.22
                c0.2-7.73,0.27-7.77-7.35-8.4c-4.09-0.34-8.17-0.74-12.25-1.11c0.01-0.51,0.01-1.01,0.02-1.52c1.16-0.19,2.33-0.56,3.49-0.54
                c3.48,0.06,6.97,0.36,10.45,0.35c1.21,0,2.84-0.23,3.57-1.02c4.15-4.41,4.78-13.71,2.19-19.14c-1.6-3.35-2.45-7.13-3.15-10.81
                c-0.68-3.58-0.65-7.3-1.1-10.93c-0.57-4.54-1.93-8.48-7.41-9.21c-4.18-0.56-8.32-1.95-12.49-1.97
                c-10.28-0.05-20.56,0.57-30.84,0.67c-1.98,0.02-3.97-1.05-5.95-1.62c0.18-0.9,0.36-1.81,0.54-2.71
                c17.14,0.51,34.28,1.01,51.78,1.53c-1.53-5.97-3.02-12.92-5.23-19.64c-0.53-1.6-3.37-3.16-5.32-3.4c-4.43-0.55-9-0.63-13.46-0.25
                c-9.59,0.82-19.15,1.95-28.7,3.14c-3.64,0.45-5.31,2.01-3.13,6.08c2.52,4.7,2.27,9.52-0.94,14.14c-4.13,5.93-6.98,12.31-5.8,19.88
                c0.1,0.67-0.71,1.78-1.39,2.16c-0.43,0.24-1.81-0.33-2.06-0.86c-0.53-1.15-0.94-2.55-0.8-3.77c0.64-5.38,0.38-10.97,4.23-15.6
                c1.62-1.95,2.49-4.53,3.83-7.07c-2.25-0.46-3.99-0.82-6.62-1.36c1.42-1.03,1.88-1.61,2.44-1.74c4.27-0.95,4.52-2.99,2.84-6.84
                c-2.04-4.69-3.21-9.78-4.43-14.78c-0.41-1.66,0.18-3.56,0.32-5.35c0.46-0.04,0.91-0.08,1.37-0.11
                C1191.7,587.83,1193.2,591.96,1194.87,596.58z M1092.26,730.59c0.07-0.74,0.14-1.47,0.21-2.21c11.86-1.02,23.06,4.58,34.76,5.16
                c12.17,0.6,24.27,1.57,36.06,6.52c-7.98-29.84-19.64-58.24-18.82-89.86c-14.54-2.31-27.76-4.36-40.96-6.57
                c-1.49-0.25-2.84-1.37-4.25-2.08c0.2-0.68,0.39-1.35,0.59-2.03c14.04,1.44,27.95,3.93,42.73,8.03
                c-1.28-6.55-2.31-11.73-3.31-16.91c-0.88-4.56-2.58-9.15-2.45-13.69c0.48-16.93-4.98-32.82-7.98-49.13
                c-2.03-11.02-4.91-21.88-7.25-32.85c-0.69-3.24-0.42-6.74-1.4-9.87c-0.81-2.57-2.98-4.67-4.26-7.14
                c-2.81-5.44-5.18-11.18-12-12.92c-1.02-0.26-2.32-1.16-2.67-2.08c-2.18-5.81-6.44-3.57-10.41-3.03c-4.88,0.66-9.8,1.54-14.69,1.41
                c-7.1-0.19-13.63-1.62-21.22,1.11c-7.28,2.62-12.15,6.65-15.83,12.33c-3.58,5.52-5.74,11.46-0.11,18.09
                c3.47,4.08,4.98,10,6.77,15.29c2.87,8.5,5.23,17.16,7.81,25.75c4.42-9.46,7.78-18.63,10.92-27.87c1.56-4.57,5.29-6.55,10.98-4.12
                c-7.45,0.96-7.9,6.15-8.93,11.03c-0.27,1.27-1.13,2.41-1.56,3.66c-2.29,6.59-4.73,13.14-6.7,19.83c-0.91,3.07-1.87,6.7-1.08,9.59
                c2.02,7.36,5.06,14.43,7.62,21.65c2.83,7.99,5.48,16.05,8.39,24.01c0.98,2.69,2.54,5.17,3.83,7.75c0.75,1.09-0.16,2.8,0.19,4.18
                c2.27,8.85,4.82,17.62,7.27,26.42c0.87,3.12,3.03,3.86,6.07,3.9c5.98,0.08,11.95,0.46,17.91,0.9c1.57,0.12,3.09,0.94,4.64,1.43
                c-0.1,0.51-0.19,1.02-0.29,1.53c-7.93,0-15.86,0-23.11,0c-0.76,7.87-1.87,14.93-2.01,22.01c-0.15,7.29,0.61,14.61,1,21.91
                c0.22,3.95,1.56,8.18,0.58,11.8c-3.33,12.32-1.13,22.95,6.3,33.36c7.08,9.92,14.96,15.49,28.03,14.99
                c14.51-0.56,29.14,1.49,43.7,2.53c1.73,0.12,3.4,1.08,5.1,1.66c-0.18,0.87-0.36,1.74-0.54,2.6c-19.43-3.23-38.89-5.34-58.56-3.04
                c2.63,6.97,4.78,8.39,11.33,7.83c7.93-0.68,15.86-1.32,23.8-1.84c1.11-0.07,2.38,0.46,3.4,1.03c1.15,0.64,2.01,2.23,3.14,2.4
                c7.84,1.18,15.72,2.1,23.33,3.07c-0.97-2.33-2.33-4.64-2.9-7.13c-2.16-9.4-4.23-18.83-5.98-28.32c-0.88-4.75-3.35-7.11-7.95-8.28
                c-21.17-5.41-42.5-9.42-64.48-8.83c-1.92,0.05-3.86-0.79-5.8-1.22c0.14-0.6,0.29-1.21,0.43-1.81c25.48-3.26,49.62,5.54,74.91,9.05
                c-0.75-3.09-1.59-6.43-3.61-7.47c-10.33-5.3-21.47-7.24-33.11-7.94c-12.22-0.73-24.35-3.01-36.51-4.65
                C1092.96,731.47,1092.63,730.92,1092.26,730.59z M1072.31,249.17c-0.6,0.35-1.14,0.92-1.66,0.92
                c-8.71-0.11-13.96,6.15-20.06,10.85c-1.77,1.36-3.63,2.61-6.26,4.49c0,0.69,0,2.7,0,4.95c16-12.59,23.82-16.49,29.2-14.43
                c-0.46,0.55-0.77,1.31-1.27,1.45c-10.22,2.96-18.43,8.92-25.5,16.71c-0.62,0.69-2.18,0.43-3.18,0.89
                c-1.44,0.66-3.41,1.23-4.02,2.42c-3.34,6.48-6.91,12.94-9.31,19.79c-3.5,10.02-3.4,20.54-0.3,30.6
                c2.38,7.72,3.61,15.03,1.17,22.94c-1.25,4.04-1.7,8.43-1.85,12.68c-0.19,5.2,0.69,10.43,0.45,15.62
                c-0.52,11.55-0.71,11.14,11,10.56c10.47-0.52,20.24,1.63,26.35,11.6c1.56,2.56,3.33,4.99,4.86,7.56
                c2.59,4.35,5.32,8.23,11.02,8.97c3.26,0.42,6.56,1.44,9.52,2.87c2.38,1.15,4.52,3.09,6.32,5.09c3.55,3.96,6.2,8.93,10.29,12.14
                c5.3,4.17,11.66,6.98,17.5,10.47c1.69,1.01,3.35,2.19,4.74,3.57c2.87,2.87,2.49,5.28-0.99,7.66c-7.11,4.86-14.12,9.9-20.95,15.13
                c-1.85,1.42-4.53,4.3-4.12,5.7c2.4,8.06-5.26,19.38,7.47,24.15c0.41,0.15,0.61,0.78,0.99,1.11c2.52,2.2,3.73,1.69,4.79-1.63
                c1.33-4.18,3.19-8.25,5.32-12.1c2.33-4.21,5.06-8.23,7.93-12.09c2.42-3.25,5.03-6.46,8.04-9.14c2.57-2.28,5.81-3.83,8.81-5.62
                c3.26-1.94,4.8-4.51,4.89-8.54c0.16-7.63,0.97-15.24,1.51-22.86c0.53-7.47,1.33-14.93,1.55-22.4c0.59-20.46-7.71-40.08-5.34-60.87
                c1.45-12.8,0.38-25.87,1.21-38.77c0.43-6.66,0.39-12.82-3.11-18.71c-1.86-3.13-2.11-6.25,1.57-8.66c2.41-1.58,4.27-4.25,2.13-6.68
                c-1.49-1.69-4.55-2.14-7.01-2.76c-1.77-0.45-3.7-0.28-5.56-0.38c0.01-0.51,0.02-1.02,0.03-1.52c1.4-0.54,2.81-1.07,5.11-1.96
                c-2.69-1.96-4.57-4.08-6.9-4.9c-8.54-3.01-17.08-6.94-25.92-7.96c-9.68-1.12-17.63-4.42-24.5-10.82
                c-10.77-10.03-22.21-7.82-33.02,0.96c-2.09,1.7-4.14,3.45-6.21,5.17c0.48,0.56,0.96,1.12,1.43,1.68
                c3.51-2.01,6.93-4.21,10.57-5.97C1065.7,246.85,1070.03,246.97,1072.31,249.17z M679.01,616.51c-11.61-2.14-21.95-2.18-32.31,0.02
                c-6.5,1.38-13.38,1.65-19.48,3.99c-5.31,2.03-10.23,2.25-15.56,1.64c-4.27-0.49-8.65-1.31-12.83-0.79
                c-4.09,0.5-7.92,2.86-11.97-0.34c-0.59-0.46-2.53,0.42-3.64,1.05c-4.78,2.71-6.36,8.53-4.33,14.49c1.01,2.96,1.72,6.03,2.39,9.09
                c2.25,10.31,4.46,20.62,6.57,30.95c0.33,1.61,0.05,3.34,0.05,5.63c-3.32-1.68-5.63-3.28-8.19-4.03c-3.63-1.06-4.89-3.5-5.58-6.85
                c-1.68-8.25-3.33-16.52-5.47-24.65c-0.66-2.5-2.39-5.44-4.51-6.72c-12.73-7.68-26.98-9.12-41.35-8.14
                c-15.23,1.04-30.35,3.82-45.58,4.54c-9.42,0.45-12.1,8.37-18.17,11.6c3.14,3.52,6.33,5.98,7.98,9.22
                c2.31,4.54,5.23,5.58,10.03,5.1c11.41-1.13,22.86-1.74,34.31-2.43c1.4-0.08,2.84,0.67,4.27,1.03c-0.1,0.47-0.19,0.93-0.29,1.4
                c-4.31,0.36-8.62,0.71-12.94,1.07c2.14,0.34,4.22,0.68,6.3,1.02c0,0.47,0.01,0.94,0.01,1.41c-1.53,0.4-3.04,0.9-4.59,1.19
                c-4.07,0.77-8.14,1.55-12.24,2.11c-1.5,0.2-3.09-0.21-4.64-0.35c0.54-1.45,1.09-2.9,1.72-4.59c-4.23,0.52-8.6,1.06-14.29,1.76
                c5.14,6.34,9.42,11.91,14.05,17.18c1.79,2.04,4.13,4.19,6.62,4.93c6.47,1.92,9.3,6.59,11,12.45c0.6,2.07,0.91,4.23,1.51,6.31
                c2.11,7.29,2.38,7.2,9.98,6.41c4.68-0.48,9.6-0.89,13.92,2.28c-2.21,1.13-4.36,1.35-6.52,1.35c-2.65,0-5.35-0.57-7.93-0.21
                c-4.2,0.57-4.9,2.58-2.76,6.25c5.14,8.82,13.51,11.85,24.59,13.6c-4.48-17.71-8.73-34.51-12.92-51.1
                c-3.8-0.52-6.86-0.93-10.18-1.38c0.48,1.15,0.84,2.02,1.71,4.09c-7.27-1.41-13.55-2.56-19.78-3.88c-1.22-0.26-2.28-1.24-3.45-1.81
                c-1.49-0.72-3.05-1.3-4.54-2.04c-1.05-0.52-2.03-1.2-3.04-1.81c0.99-0.68,1.96-1.9,2.98-1.95c3.31-0.15,6.68-0.15,9.93,0.39
                c1.21,0.2,2.14,2.05,3.2,3.14c-0.24,0.54-0.47,1.09-0.71,1.63c2.86,0.16,5.73,0.58,8.57,0.41c2.26-0.14,4.46-1.4,6.7-1.43
                c2.35-0.03,4.71,0.81,7.18,1.29c0-1.19-0.1-1.87,0.02-2.51c0.32-1.74,0.72-3.47,1.09-5.2c1.12,1.64,2.76,3.14,3.26,4.95
                c1.29,4.63,1.92,9.44,3.13,14.1c0.57,2.2,1.31,5.27,2.92,6.1c4.77,2.46,5.98,6.87,7.66,11.17c1.92,4.91,3.78,9.84,5.48,14.27
                c10.41,0.79,20.21,1.53,30.01,2.27c-0.02,0.45-0.05,0.89-0.07,1.34c-9.4,3.93-19.09-0.5-30.41,0.19
                c5.15,6.23,3.57,13.97,9.09,18.84c4.58,4.04,4.61,8.73,2.74,14.1c-0.97,2.78-1.07,5.87-1.65,9.35
                c17.69-5.29,34.25-10.23,51.16-15.29c-4-3.54-5.05-8.82-2.92-13.69c2.62-6.01,6.17-11.75,10.14-16.99
                c3.02-3.99,7.65-6.73,10.97-10.55c4.99-5.74,9.32-12.04,14.21-17.88c2.94-3.52,6.4-6.6,9.73-9.98c-2.12-1.32-4.17-2.42-5.99-3.82
                c-0.91-0.7-1.39-1.96-2.06-2.97c1.26-0.56,2.59-1.72,3.76-1.57c2.94,0.38,6.11,0.82,8.61,2.24c2.68,1.53,4.14,0.82,5.26-1.33
                c2.28-4.4,5.02-8.75,6.29-13.46c3.04-11.23,5.3-22.67,7.94-34.01C676.33,626.72,677.66,621.81,679.01,616.51z M1027.48,901.05
                c-0.88-6.06-1.71-12.08-2.68-18.06c-0.2-1.22-0.67-2.65-1.51-3.48c-3.98-3.91-5.86-8.53-6.53-14.1
                c-0.43-3.54-1.55-8.64-4.03-10.01c-9.37-5.18-16.13-12.05-20.64-21.81c-1.51-3.27-5.38-6.65-8.82-7.58
                c-6.06-1.64-12.62-1.84-18.98-1.86c-4.57-0.01-9.12,1.43-13.71,1.9c-9.05,0.93-18.12,1.72-27.19,2.43
                c-13.69,1.07-20.93,10.74-27.93,20.77c-1.6,2.29-3.21,4.57-4.81,6.87c-4.05,5.8-9.68,10.77-10.52,18.33
                c-0.82,7.3-5.04,12.84-8.74,18.76c-1.82,2.92-2.75,6.4-4.63,10.93c9.19-1.61,12.51,4.59,17.34,8.44c2.76,2.2,5.94,4.93,9.16,5.27
                c7.35,0.78,14.91,0.84,22.27,0.09c13.72-1.4,26.87-0.38,39.27,6.16c3.27,1.73,6.42,2.61,10.37,1.07c3.1-1.21,6.95-1.37,10.29-0.85
                c4.49,0.7,7.72-0.49,10.66-3.73c4.46-4.92,9.69-7.88,16.78-6.92c1.51,0.2,4.09-1.46,4.85-2.95c2.26-4.45,5.7-6.77,10.4-7.69
                C1021.23,902.41,1024.29,901.72,1027.48,901.05z M731.44,418.12c5.96,6.41,11.99,12.39,17.41,18.88c5.78,6.92,11,8.79,19.66,6.04
                c3.14-0.99,6.38-2.51,9.54-2.43c9.78,0.25,19.53,1.2,29.3,1.75c1.94,0.11,4.47,0.39,5.78-0.62c9.54-7.36,19.85-5.85,30.54-3.66
                c10.92,2.23,21.93,3.5,33.18,1.59c11.25-1.91,22.61-3.2,33.95-4.5c12.58-1.44,24.51-4.83,35.31-11.5
                c2.19-1.36,4.35-3.83,5.12-6.24c2.71-8.48,5.09-17.09,7.06-25.77c1.89-8.32,3.12-16.78,4.75-25.8c-1.98,0.23-5.03,0.01-7.64,0.98
                c-8.06,2.99-15.78,7.02-23.97,9.52c-5.9,1.8-12.33,1.96-18.56,2.52c-6.24,0.56-12.6,0.19-18.75,1.2c-4.55,0.75-8.73,2.7-13.6,1.12
                c-1.99-0.65-5-0.31-6.75,0.81c-7.81,5.01-16.53,5.05-25.31,6.16c-23.7,2.98-47.27,6.96-70.92,10.37
                c-4.72,0.68-9.66,0.1-14.28,1.08c-4.65,0.98-9.44,2.54-13.44,5.02C743.42,408.59,737.61,413.52,731.44,418.12z M1092.75,773.9
                c0.24-0.56,0.48-1.13,0.72-1.69c-2.04-3.38-4.18-6.71-6.11-10.15c-1.52-2.72-3.26-4.07-6.74-3.66
                c-10.99,1.3-21.85,0.72-32.38-3.24c-2.91-1.09-6.08-1.77-9.18-2.05c-8.27-0.75-16.55-1.44-24.85-1.66
                c-9.27-0.24-18.61-0.52-27.8,0.36c-6.01,0.57-11.85,3.12-17.73,4.87c-5.78,1.73-11.47,4.9-17.29,5.12
                c-15.87,0.61-31.8,0.19-47.69-0.25c-4.92-0.14-10.43-0.49-14.57-2.76c-6.57-3.59-13.52-2.75-20.21-3.52
                c-12.86-1.49-25.88-1.48-38.8-2.49c-13.39-1.04-26.75-3.73-40.1-3.52c-12.42,0.2-24.4-1.97-36.59-2.94
                c-6.37-0.51-12.86-0.29-19.23,0.35c-2.5,0.25-4.79,2.54-7.18,3.9c0.2,0.68,0.39,1.37,0.59,2.05c-6.34-4.28-12.31-2.1-18.39-0.06
                c-1.77,0.59-3.91,0.3-5.86,0.13c-12.29-1.07-24.58-2.23-36.86-3.35c-0.32-0.03-0.75-0.04-0.97,0.13
                c-3.56,2.85-6.95,0.93-10.26-0.47c-4.25-1.8-8.25-4.94-12.62-5.55c-6.95-0.97-14.16-0.76-21.18-0.18
                c-3.46,0.28-9.01,1.84-9.66,4.01c-1.38,4.57,3.87,6.52,7.16,8.94c4.4,3.24,9.27,6.07,12.93,10.01c3.63,3.91,7.19,5.64,12.24,4.11
                c8.67-2.63,17.39-1.55,26.22-0.87c10.93,0.84,21.94,1.33,32.88,0.99c12.57-0.39,25.09-2.06,37.65-2.81
                c9.9-0.59,19.86-1.16,29.75-0.72c4.89,0.22,9.61,3.58,14.54,4.03c17.19,1.59,34.43,2.73,51.67,3.68
                c26.21,1.43,52.44,2.7,78.67,3.74c11.29,0.45,22.65,0.72,33.92,0.05c18.75-1.12,37.42-3.78,56.17-4.48
                c20.76-0.78,41.57-0.08,62.35-0.03C1076.23,773.92,1084.49,773.9,1092.75,773.9z M647.16,552.73c-0.13-0.56-0.26-1.11-0.4-1.67
                c2.04-0.85,4.15-2.52,6.12-2.38c5.07,0.38,10.16,1.28,15.11,2.49c6.09,1.48,12.1,3.35,18.06,5.31c4.51,1.48,6.25-0.96,7.78-4.51
                c2.44-5.63,3.89-12.42,7.98-16.44c6.31-6.19,8.16-12.35,5.7-20.38c-0.24-0.79-0.36-1.65-0.34-2.47c0.1-3.15-1.39-4.57-4.51-4.53
                c-13.92,0.14-27.85,0.24-41.77,0.35c-1.75,0.01-3.5,0-6.08,0c1.03-1.55,1.21-2.11,1.58-2.32c0.72-0.4,1.53-0.82,2.32-0.85
                c13.73-0.38,27.48-1.17,41.2-0.81c6.31,0.17,6.99,0.5,8.13-5.86c1.42-7.97,3.06-15.91,5.02-23.76c1.62-6.48,1.51-7.2-4.95-9.22
                c-2.22-0.69-4.53-1.56-6.79-1.53c-9.1,0.11-18.19,0.59-27.29,0.82c-1.86,0.05-3.73-0.35-5.6-0.55c0.06-0.81,0.12-1.63,0.19-2.44
                c14.74-2.19,29.48-2.49,44.36,1.62c0.33-1.16,0.85-1.98,0.71-2.67c-1.22-5.85-2.49-11.7-3.89-17.51
                c-0.73-3.05-2.65-4.65-5.98-4.95c-1.31-0.12-2.47-1.79-4.59-3.44c2.84-0.62,4.23-0.93,5.62-1.24c0.02-0.41,0.05-0.83,0.07-1.24
                c-1.08-0.24-2.23-0.85-3.23-0.67c-6.91,1.25-10.22-0.62-12.19-7.28c-0.95-3.19-1.51-6.51-2.67-9.62
                c-1.62-4.33-3.07-8.89-5.63-12.64c-2.52-3.68-16.42-5.46-20.2-2.84c-4.9,3.39-9.5,7.27-13.92,11.28c-1.5,1.36-2.06,3.75-3.62,6.8
                c2.73-0.76,3.95-1.34,5.2-1.41c2.59-0.15,6.76-1.02,7.51,0.16c2.8,4.42,6.93,3.04,10.61,3.75c1.68,0.33,3.33,0.8,5,1.2
                c-0.14,0.76-0.27,1.53-0.41,2.29c-4.77-0.52-9.64-0.6-14.27-1.71c-3.21-0.77-4.48-0.04-4.73,2.92c-0.69,8.3-1.52,16.59-1.87,24.9
                c-0.71,16.91-1.09,33.84-1.73,50.76c-0.17,4.43-0.45,9.11-7,8.47c-0.37-0.04-0.8,0.52-1,0.66c0.19,1.85,0.61,3.36,0.44,4.8
                c-2.24,18.24,1.43,36.16,2.83,54.2c0.3,3.87,1.64,5.37,5.72,4.24c2.67-0.74,5.58-0.78,8.39-0.81c9.76-0.09,19.52-0.03,29.83-0.03
                c0.77-2.1,1.86-5.05,2.92-8.01c0.99-2.75-0.01-3.73-2.92-4.19c-5.37-0.86-10.64-2.35-15.96-3.51c-7.02-1.52-13.65-5.78-21.32-2.91
                C649.76,553.74,648.36,552.97,647.16,552.73z M374.63,867.65c-4.13,2.17-7.58,5.17-11.35,5.65c-4.71,0.61-7.73,3.08-11.27,5.51
                c-3.18,2.19-6.9,4.38-10.6,4.86c-7.69,1.01-15.54,0.87-23.32,1.23c-1.98,0.09-4.37-0.42-5.86,0.5
                c-8.54,5.31-17.91,3.95-27.15,4.02c-9.17,0.07-18.47-2.03-27.45,1.78c-3.15,1.34-6.58,2.8-9.89,2.8
                c-5.15,0.01-9.36,1.1-13.08,4.84c-1.8,1.82-4.5,2.73-6.73,4.17c-2.21,1.43-4.47,2.84-6.44,4.56c-0.96,0.83-1.39,2.32-1.86,3.59
                c-0.07,0.2,1.26,1.23,1.38,1.14c1.09-0.82,2.32-1.65,3-2.78c3.85-6.44,10.19-7.68,16.48-3.36c3.14,2.16,6.62,3.8,9.89,5.77
                c3.03,1.82,5.56,1.22,8.17-1c1.6-1.36,4.33-3.58,5.38-3.06c6.36,3.19,12.72,2.03,19.16,1.08c0.3-0.04,0.64,0.16,0.95,0.26
                c6.32,1.93,12.63,3.91,18.96,5.79c4.83,1.44,9.38,1.02,13.97-1.42c5.67-3.03,11.59-2.88,17.13,0.58c3.23,2.02,6.29,4.33,9.32,6.65
                c2.64,2.01,4.98,2.32,8.24,0.82c3.42-1.58,7.43-1.83,11.13-2.84c2.26-0.61,5.03-0.95,6.54-2.45c5.18-5.12,11.13-6.2,17.92-4.82
                c1.93,0.39,4.61,1.49,5.75,0.65c6.05-4.5,12.66-5.26,19.78-4.32c0.58,0.08,1.28-0.35,1.86-0.66c10.51-5.69,20.11-4.05,29.08,3.47
                c3.32,2.78,6.85,3.19,11.14,1.63c4.85-1.76,10.53-1.1,14.61-5.3c0.62-0.64,2.21-0.44,3.35-0.44c5.57,0.04,11.17,0.49,16.69,0.06
                c2.36-0.19,4.95-1.77,6.79-3.44c4-3.64,8.32-4.99,13.26-2.81c3.93,1.73,7.33,1.45,10.62-1.21c-1.66-9.89-6.12-13.66-15.22-13.5
                c-1.38,0.02-3.54-1.74-4.05-3.14c-1.13-3.12-2.68-4.54-5.92-3.35c-3.36,1.23-5.12-0.08-6.63-3.18c-3.36-6.92-5.61-7.85-13.16-6.2
                c-3.49,0.76-6.25,0.64-8.51-3.01c-2.86-4.62-7.8-6.79-12.97-5.66c-8.89,1.94-16.78-1.4-24.91-3.5c-6.14-1.59-8.39-0.07-7.99,6.13
                c0.06,0.99-0.63,2.04-0.98,3.06c-0.95-0.45-2.05-0.73-2.81-1.4c-0.7-0.62-1.02-1.66-1.51-2.51c-0.25,0.55-0.5,1.1-0.75,1.65
                c-3.6,0-7.36,0.68-10.77-0.13c-10.24-2.41-13.27-1.29-19.51,9.46c2.51,0.49,4.94,1.74,6.74,1.13c2.97-1,5.41-3.47,8.3-4.83
                c7.58-3.56,9.14-3.06,14.74,3.98c-2.15,2.43-4.18,1.66-6.4,0.05c-0.99-0.72-2.59-1.79-3.32-1.44c-3,1.42-6.44,2.77-8.45,5.19
                c-2.59,3.13-5.11,2.8-7.89,1.49c-2.79-1.31-5.31-3.17-8.76-5.28c0.54,7.31-1.45,11.56-9.37,10.75c-1.82-0.18-3.98,2.89-5.9,4.41
                c1.14,1.85,1.85,2.99,2.69,4.36c-5.96-0.16-8.34-1.37-10.19-5.12c1.53-0.87,3.4-1.45,4.58-2.68c3.07-3.22,6.2-5.92,11.03-6
                c0.8-0.01,2.09-2.03,2.24-3.23C374.86,873.92,374.63,871.12,374.63,867.65z M469.73,814.49c5.07,6.15,10.05,11.9,14.71,17.89
                c8.67,11.15,17.08,22.49,25.72,33.65c7.22,9.32,12.99,19.28,15.28,31.04c0.24,1.23,1.86,3.03,2.96,3.13
                c7.48,0.68,15.35,2.51,21.05-4.84c0.48-0.62,1.6-0.74,2.06-1.36c1.29-1.75,3.11-3.53,3.41-5.5c0.81-5.29,3.81-8.31,8.74-8.74
                c5.07-0.44,10.23-0.03,15.34,0.26c3.82,0.22,7.05-2.55,5.38-5.41c-3.23-5.51-7.19-10.61-10.97-15.79c-1.05-1.45-2.8-2.4-3.77-3.89
                c-6.81-10.42-7.62-22.51-9.06-34.35c-0.72-5.9-0.51-11.9-0.86-17.85c-0.4-6.72-0.15-13.56-4.47-19.47
                c-1.43-1.95-2.62-4.43-2.92-6.79c-0.48-3.82-2.16-4.94-5.85-4.9c-13.06,0.14-26.12-0.24-39.17,0.14
                c-2.84,0.08-6.34,1.82-8.27,3.94c-3.28,3.62-5.59,8.14-8.25,12.32C484.73,797.54,481.52,809.3,469.73,814.49z M740.29,918.86
                c5.71-4.02,10.97-1.19,16.32,0.34c1.11,0.32,2.28,0.44,3.43,0.62c6.05,0.96,12.08,2.53,18.14,2.68
                c5.63,0.14,11.29-1.23,16.94-1.97c0.27-0.04,0.53-0.39,0.74-0.65c6.28-7.51,14.79-7.76,23.6-7.58c2.2,0.04,5.04-0.78,6.53-2.27
                c4.52-4.53,9.55-5.07,15.13-3.04c4.86,1.77,9.44,2.7,14.27-0.43c1.6-1.04,4.29-0.25,6.22-0.98c1.69-0.63,4.07-2.03,4.31-3.4
                c0.75-4.44,0.63-9.02,0.9-13.55c0.25-4.2-0.24-7.81-4.81-9.86c-1.36-0.61-2.13-2.83-2.89-4.44
                c-5.39-11.39-10.04-23.35-21.67-30.16c-2.57-1.51-5.67-2.27-8.62-2.94c-3.98-0.91-8.23-0.89-12.07-2.16
                c-9.15-3-17.79-2.89-26.42,1.82c-3.52,1.92-7.57,3.8-11.45,3.97c-11.44,0.49-20.52,5.94-29.17,12.49
                c-3.95,2.99-6.67,6.83-6.85,12.2c-0.06,1.8-1.14,4.91-2.18,5.1c-5.52,1.01-8.49,4.37-10.49,9.18c-0.27,0.64-1.25,1.12-2.01,1.41
                c-3.57,1.36-4.95,4.15-5.35,7.72c-0.66,5.96,0.22,12.49-7.51,16.16c6.63,2.46,11.68,4.59,16.89,6.18
                c4.21,1.29,8.21-2.62,9.05-6.92c1.81-9.34,4.2-18.58,6.54-27.81c0.33-1.32,1.5-2.89,2.7-3.44c3.97-1.83,6.4-4.59,8.31-8.6
                c0.87-1.81,4.57-2.87,7.09-3.08c1.82-0.15,3.79,1.68,5.69,2.63c-0.21,0.51-0.43,1.01-0.64,1.52c-0.92,0.08-1.92,0.41-2.75,0.18
                c-4.08-1.13-6.22,0.15-7.11,4.47c-0.3,1.49-2.56,2.51-3.76,3.88c-2.07,2.37-4.4,4.64-5.92,7.35c-1.44,2.57-2.03,5.63-2.89,8.5
                c-1.08,3.62-3.08,7.33-2.85,10.89C746.02,910.51,744.08,914.57,740.29,918.86z M755.62,445.19c-0.34,0.29-0.69,0.57-1.03,0.86
                c1.82,3.99,3.99,7.87,5.39,12c4.47,13.22,8.65,26.53,12.93,39.8c1.21,3.77,3.25,6.21,7.65,6.59c3.95,0.34,7.83,1.53,11.72,2.4
                c5.79,1.29,9.26-0.04,11.81-5.42c5.5-11.59,15.13-17.74,26.95-21.07c6.51-1.84,13.31-2.84,19.62-5.18
                c3.56-1.32,6.39-6.84,9.55,0.22c0.09,0.2,0.6,0.23,0.92,0.3c8.1,1.76,16.13,4.08,24.33,5.02c3.96,0.45,8.29-1.46,12.32-2.72
                c5.32-1.65,10.41-4.09,15.78-5.5c7.22-1.9,14.56-3.6,21.96-4.52c6.46-0.81,8.52-3.92,9.04-10.12c0.91-10.87,2.77-21.67,4.24-32.6
                c-0.99,0-1.59-0.19-1.93,0.03c-13.24,8.56-28.72,10.08-43.53,13.46c-6.34,1.45-13.39-0.2-19.72,1.27
                c-14.61,3.39-29.13,1.93-43.62,0.36c-8.72-0.94-16.72-1.07-24.23,4.39c-1.79,1.3-5.08,1.09-7.58,0.77
                c-8.05-1.03-16.03-2.59-24.08-3.57c-2.09-0.25-4.34,0.86-6.51,1.33c-5.24,1.13-9.18,6.64-15.3,4.7
                C760.01,447.26,757.84,446.13,755.62,445.19z M1190.76,841.33c5.83,0,10.97-0.64,15.89,0.15c7.23,1.16,14.23,3.58,21.44-0.31
                c1.48-0.8,3.85,0.07,5.82,0.18c2.18,0.12,4.46,0.67,6.53,0.26c4.87-0.97,8.11,0.94,8.41,5.68c0.07,1.12-0.66,2.3-1.02,3.45
                c-0.82-0.81-1.62-1.64-2.45-2.44c-1.15-1.11-2.89-3.35-3.38-3.11c-3.88,1.93-7.46,1.97-11.16-0.3c-0.43-0.26-1.48-0.06-1.89,0.31
                c-3.43,3.11-7.16,4.16-11.66,2.48c-2.17-0.81-4.52-1.39-6.82-1.52c-4.84-0.26-9.72-0.41-14.53,0.01c-1.18,0.1-3.23,2.72-3.05,3.9
                c1.5,9.75,2.44,19.36,12.1,25.95c9.8,6.68,18.37,15.19,27.69,23.08c-1.26,1.55-2.07,2.53-3.43,4.2
                c5.61-1.01,10.32-1.59,14.82-2.89c0.87-0.25,1.89-3.93,1.21-4.95c-4.15-6.33-8.72-12.4-13.3-18.44c-0.55-0.73-2.04-0.83-3.13-1.03
                c-1.15-0.21-2.33-0.19-4.03-0.3c3.58-3.75,9.43-3.82,11.69-0.69c3.97,5.48,8.16,10.84,11.72,16.59c1.41,2.28,1.36,5.47,2.03,8.23
                c0.35,1.45,0.39,3.59,1.32,4.13c1.44,0.83,3.84,1.28,5.28,0.64c10.33-4.57,15.59-3.92,23.15,4.28c2.75,2.99,4.79,3.02,8.03,1.06
                c3.88-2.34,8.55-4.54,12.69-1.66c5.02,3.5,9.08,2.96,13.53-0.49c1-0.78,2.44-1.01,3.7-1.43c5.73-1.92,6.35-3.52,2.81-8.39
                c-1.03-1.41-2.88-2.19-4.09-3.5c-2.94-3.2-5.77-6.5-8.55-9.84c-1.14-1.36-1.74-3.39-3.12-4.29c-3.2-2.09-3.87-4.38-2.65-8
                c1.56-4.65-0.17-7.71-4.9-9.23c-2.34-0.75-5.05-0.45-7.3-1.35c-1.67-0.67-4.23-2.75-4.05-3.8c0.8-4.61-2.1-6.18-5.3-7.16
                c-3.12-0.95-6.48-1.37-9.76-1.46c-6.12-0.17-8.71-3.03-7.91-9.21c0.24-1.9,0.25-3.83,0.39-6.19c-1.74,0.24-3.2,0-3.88,0.62
                c-3.4,3.06-6.11,1.47-9.77-0.01c-6.15-2.49-12.89-5.58-19.58-3.03c-4.45,1.69-6.52,1.75-9.06-2.73
                c-3.39-5.97-10.63-5.91-15.1-0.98c-1.18,1.3-3.75,1.75-5.72,1.84C1194.48,829.91,1189.16,836.92,1190.76,841.33z M1186.6,458.45
                c-6.27,1.33-11.55,3.77-16.51,3.24c-12.23-1.31-19.81,6.67-28.56,12.46c-1.75,1.16-3.42,2.72-4.58,4.47
                c-4.14,6.23-8.18,12.53-11.94,18.99c-1.2,2.06-1.5,4.67-2.03,7.07c-0.7,3.14,0.83,4.12,3.83,4.01c5.81-0.22,11.63-0.32,17.45-0.27
                c9.14,0.08,18.3,0.73,27.42,0.37c8.65-0.34,17.2,1.52,26.06-1.59c10.92-3.84,22.68-2.21,34.07-0.75
                c7.32,0.94,14.79,1.64,21.78,3.78c6.25,1.91,11.94,5.65,17.24,8.27c3.16-6.99,6.92-14.92,10.31-23c1.03-2.46,1.06-5.34,1.77-9.23
                c-6.5,4.48-6.33,4.34-8.69-1.78c-2.14-5.57-4.59-10.96-11.23-13.08c-5.02-1.6-10.16-3.56-14.46-6.52
                c-7.87-5.4-16.44-4.69-24.76-4.12c-0.72,1.61-1.66,3.02-2.67,3.06c-4.63,0.21-9.28,0.11-13.92-0.03c-1.4-0.04-2.78-0.58-4.17-0.9
                c0.03-0.47,0.06-0.94,0.09-1.41c2.29-0.6,4.58-1.2,6.87-1.81c-4.24-0.94-8.45-0.19-12.56-0.57c-5.67-0.52-6.87,2.56-7.91,7.14
                c-2.48,10.94-0.23,21.44,1.93,32.04c0.33,1.64-0.48,3.51-0.76,5.27c-1.19-1.53-3.16-2.93-3.45-4.62
                C1184.99,486.01,1182.63,473.03,1186.6,458.45z M1001.72,446.42c0.34,0.02,0.69,0.04,1.03,0.06c0.69-2.48,1.37-4.97,2.08-7.44
                c2.89-10.02,7.59-19.58,7.46-30.36c-0.09-7.08,4.43-11.46,9.35-15.68c1.58-1.36,3.38-3.51,3.45-5.36
                c0.21-5.27-0.53-10.57-0.68-15.87c-0.24-8.16,0.79-16.08,2.65-24.12c1.22-5.29,0.76-11.89-1.43-16.78
                c-1.82-4.07-4.69-7.17-1.83-11.74c-2.24-0.38-3.8-0.65-5.36-0.92c-0.01-0.43-0.01-0.85-0.02-1.28c1.79-0.56,3.58-1.13,5.73-1.81
                c-0.26-2.14-0.5-4.2-0.74-6.17c-5.48-0.53-9.5,4.97-14.95,2.43c2.66-1.84,5.54-2.94,8.38-4.13c6.5-2.71,6.49-2.73,6.64-5.11
                c-3.15,1.15-6.19,2.27-9.23,3.38c-0.25-0.6-0.5-1.2-0.75-1.8c2.43-1.15,5.02-2.07,7.25-3.52c2.13-1.38,4.55-2.96,5.69-5.07
                c3.38-6.26,6.22-12.82,9.21-19.29c0.68-1.47,1.14-3.03,1.7-4.56c-2.31,0.71-3.66,1.92-5.2,2.8c-9.53,5.46-16.82,12.81-20.81,23.29
                c-2.19,5.74-4.73,11.34-10.39,14.83c-1.65,1.02-2.67,3.05-4.25,4.94c6.07,0.31,11.36,0.57,16.65,0.84c0.05,0.57,0.1,1.13,0.15,1.7
                c-2.68,0.97-5.88,1.31-7.95,3.02c-9.11,7.54-19.67,14.12-21.67,27.31c-0.74,4.86-1.11,9.78-1.62,14.68
                c-0.28,2.69-0.8,5.46,3.29,5.81c0.64,0.05,1.18,1.34,1.77,2.06c-0.83,0.64-1.57,1.5-2.51,1.85c-0.77,0.29-1.76-0.02-3.27-0.1
                c0,13.21,0.52,26.02-0.12,38.78c-0.94,18.74-2.48,37.46-4.25,56.15c-0.67,7.05,0.11,12.75,6.95,16.34
                c1.27,0.67,2.15,2.05,3.24,3.07c6.38,5.97,12.77,11.93,19.35,18.07c1.74-5.18,3.64-10.77,5.46-16.38c0.18-0.57,0.13-1.44-0.19-1.9
                c-1.72-2.45-3.82-4.67-5.31-7.24c-2.72-4.7-8.66-7.53-7.85-14.38c0.28-2.36-1.08-4.91-1.7-7.38c-0.3-2.86,0.41-5.94,0.52-9.05
                c0.58-16.23-4.99-31.34-8.81-46.75c-1.15-4.65-0.42-8.75,3.37-10.86c0.15,2.77,0.07,5.52,0.49,8.18c0.46,2.9,1.44,5.71,2.11,8.57
                c0.68,2.9,1.22,5.83,1.89,8.74c1.07,4.68,2.37,9.32,3.22,14.04C1000.76,436.97,1001.14,441.71,1001.72,446.42z M1153.3,290.01
                c3.56,0.61,6.43,1.82,9.05,1.42c6.5-0.99,12.92-2.58,19.29-4.24c2.35-0.61,4.48-2.11,7.05-3.38c1.28,0.07,3.44,0.81,5.04,0.16
                c12.31-5.01,25.49-4.43,38.29-6.16c4.38-0.59,8.96,0.14,13.43,0.41c14.26,0.85,28.51,2.17,42.79,2.5
                c7.28,0.17,14.45-1.49,20.41-6.66c3.43-2.98,7.68-2.59,11.85-1.06c2.96,1.08,5.98,2.03,9.05,2.7c8.12,1.76,16.33,3.16,24.43,5.02
                c7.39,1.7,14.59,1.09,21.63-1.26c1.47-0.49,3.61-2.92,3.34-3.84c-0.49-1.66-2.35-3.63-4.02-4.08c-3.66-0.99-7.65-0.75-11.33-1.67
                c-6.87-1.72-13.65-3.82-20.38-6.03c-1.03-0.34-1.6-2.11-2.38-3.22c1.2-0.43,2.4-0.89,3.62-1.29c3.04-0.99,6.08-1.96,9.13-2.94
                c-4.06-2.38-7.73-3.36-11.37-4.46c-8.66-2.63-17.39-4.52-26.54-3.17c-9.68,1.43-19.38,2.77-29.07,4.07
                c-2.44,0.33-4.94,0.28-7.39,0.56c-4.16,0.47-8.5,0.49-12.41,1.78c-4.11,1.36-7.74,1.97-12.07,0.72
                c-5.68-1.64-11.6-2.72-17.48-3.31c-8.39-0.85-17.07-0.02-25.2-1.8c-7.41-1.62-14.07-1.05-21.05,0.95
                c-12.13,3.47-24.29,6.81-36.47,10.07c-2.94,0.79-6.03,1.03-10.38,1.75c4.05,3.42,6.9,6.13,10.04,8.43
                c5.03,3.67,5.92,7.49,2.58,12.73C1155.91,286.08,1155,287.42,1153.3,290.01z M469.38,822.4c-0.27-0.81-0.29-1.42-0.6-1.7
                c-5.66-5.09-11.29-10.23-17.12-15.11c-0.93-0.78-2.96-0.84-4.27-0.47c-5.97,1.72-11.39-0.17-17.08-1.66
                c-8.11-2.11-16.42-3.6-24.72-4.84c-5.15-0.77-6.19,1.18-4.41,6.05c1.94,5.3,3.56,10.73,5.3,16.1c3.17,9.81,6.39,19.61,9.47,29.46
                c0.94,3,2.25,3.68,5.49,2.83c2.84-0.74,6.12-0.23,9.14,0.21c7.33,1.08,14.58,3.06,21.93,3.51c6.57,0.39,12.58,1.02,17.14,6.32
                c2.05,2.38,4.42,3.02,7.57,2.16c4.5-1.22,8.79-0.12,11.8,3.5c2.94,3.55,5.68,6.81,10.92,6.33c0.97-0.09,2.51,0.7,3.01,1.55
                c3.44,5.76,10.09,3.32,15.03,5.98c-4.18-7.01-8.88-13.39-13.9-19.5c-1.39-1.69-4.18-2.22-5.56-3.91
                c-3.57-4.35-7.04-8.85-9.97-13.65c-5.44-8.92-12.08-15.29-23.54-13.59c-0.8,0.12-1.68-0.02-2.48-0.23
                c-6.63-1.7-13.25-3.44-19.87-5.17c0.03-0.54,0.05-1.09,0.08-1.63C450.82,818.9,460.46,825.03,469.38,822.4z M1344.66,902.95
                c-1.73-6.37-3.11-11.96-4.76-17.47c-6.01-20.08-12.04-40.15-18.3-60.15c-0.47-1.51-2.67-3.16-4.31-3.48
                c-4.86-0.93-10.43,0.73-13.91-4.66c-0.26-0.4-2.65,0.52-4.02,0.92c-4.02,1.17-7.97,2.99-12.05,3.38c-2.82,0.27-5.8-2.01-8.75-2.15
                c-14.27-0.67-28.56-1.08-42.85-1.44c-12.46-0.32-24.93-0.33-37.38-0.76c-3.79-0.13-7.55-1.21-11.76-1.93
                c0.75,4.13,1.66,9.2,2.44,13.51c4.36-1.36,8-2.48,11.62-3.65c0.61-0.2,1.2-0.54,1.7-0.95c6.13-5.02,10.95-5.48,17.41-1.25
                c4.07,2.67,7.6,6.29,13.28,3.77c1.38-0.61,3.52,0.55,5.32,0.83c6.51,1,13.09,3.04,19.54,2.68c7.89-0.44,10.15,0.47,9.8,8.51
                c-0.19,4.48,1.34,6.01,5.92,6.39c6.52,0.54,12.48,2.94,16.93,8.37c1.73,2.12,4.2,4.03,6.73,5.01c4.78,1.86,10.04,4.34,9.63,9.52
                c-0.6,7.64,4.59,12.11,7.21,17.94c0.82,1.83,2.88,3.1,4.35,4.64c2.67,2.79,5.34,5.59,7.98,8.4c0.87,0.92,1.57,2.62,2.51,2.74
                C1333.79,902.29,1338.72,902.51,1344.66,902.95z M720.94,447.58c0.02-0.59,0.05-1.18,0.07-1.77c4.74-1.32,10.41-1.89,13.79,1.48
                c5.02,5,9.64,1.99,14.77,1.83c-0.12-0.97-0.05-1.67-0.3-2.22c-5.25-11.42-14.84-19-23.98-26.95c-0.64-0.56-2.74-0.64-3.08-0.16
                c-2.62,3.65-6.84,7.43-6.99,11.3c-0.26,7,1.05,14.42,3.39,21.07c3.1,8.78,3.27,17.21,0.82,25.9c-1.49,5.28-3.52,10.41-4.93,15.7
                c-1.86,6.96-0.5,8.9,6.28,10.04c0.56,0.09,1.06,0.53,1.78,0.91c-2,3.99-9.28-0.32-8.95,5.93c0.2,3.79,1.35,7.53,2.3,12.41
                c5.53-3.5,9.69-6.52,14.2-8.85c4.21-2.18,8.8-3.62,13.23-5.39c-0.17-0.48-0.35-0.96-0.52-1.44c-2.85-0.3-5.71-0.6-8.56-0.91
                c0-0.58,0-1.15,0-1.73c2.01-0.35,4-0.96,6.02-1.02c7.97-0.23,15.95-0.43,23.93-0.35c4.98,0.05,4.97-2.31,3.71-6.02
                c-1.5-4.4-2.66-8.91-4-13.37c-1.63-5.39-3.3-10.76-4.86-15.83c-10.02-2.8-19.42-5.42-28.81-8.04c0.16-0.72,0.33-1.43,0.49-2.15
                c8.55,0.55,16.87,2.68,25.33,5.22c-0.55-5.53-4.79-5.63-8.37-6.68c-4.59-1.34-9.92-1.78-13.49-4.5c-3.17-2.42-5.8-3.94-9.66-3.69
                C723.35,448.37,722.14,447.83,720.94,447.58z M1261.97,525.24c3.76,1.12,1.96,3.79,1.26,5.82c-0.96,2.79-2.6,5.36-3.41,8.18
                c-0.36,1.25,0.58,2.88,0.93,4.33c0.71-1.26,1.73-2.45,2.07-3.8c0.48-1.92,0.22-4.03,0.75-5.92c0.34-1.21,1.53-2.19,2.35-3.27
                c0.39,0.2,0.78,0.4,1.16,0.61c-0.34,3.04-1.01,6.08-0.93,9.1c0.09,3.02,0.93,6.02,1.45,9.03c0.65-0.05,1.31-0.11,1.96-0.16
                c1.05-5.93,2.12-11.85,3.13-17.78c0.07-0.43-0.17-1.02-0.45-1.4c-3.09-4.02-6.21-8.01-8.25-10.62c-3.46,0-5.83,0.61-7.7-0.1
                c-17.22-6.52-34.93-7.89-52.93-4.86c-11.65,1.96-23.19,4.07-35.09,3c-4.33-0.39-8.73-0.06-13.47-0.06
                c20.87,19.6,39.11,21.56,57.91,18.93c0.3-4.74,0.5-9.52,0.98-14.27c0.15-1.48,1.09-2.87,1.67-4.31c0.63,1.54,1.86,3.11,1.78,4.6
                c-0.19,3.3-1.46,6.56-1.44,9.84c0.01,1.5,2.03,4.28,3.13,4.27c6.26-0.04,12.52-0.75,18.79-1.23c0-0.19,0-0.39,0-0.58
                c-1.69-0.28-3.39-0.56-5.08-0.84c-0.15-0.49-0.29-0.99-0.44-1.48c2.06-0.72,4.33-2.38,6.16-1.99c4.96,1.06,9.74,2.98,14.64,4.58
                c-0.4,0.53-1.8,1.58-1.68,2.41c0.53,3.68-1.95,2.68-3.78,2.72c-9.06,0.17-18.15,0.02-27.18,0.57c-9.45,0.58-18.84,2.03-28.28,2.76
                c-6.28,0.48-12.6,0.39-18.91,0.49c-3.33,0.05-5.96,1.29-5.07,4.94c0.53,2.16,2.37,4.96,4.23,5.57c1.92,0.62,5.84-0.38,6.68-1.89
                c2.34-4.22,5.87-4.03,9.44-3.79c14.59,0.97,29.18,2.01,43.73,3.37c2.7,0.25,5.23,2.16,7.9,3.14c1.52,0.56,3.16,0.78,4.75,1.14
                c3.16,0.71,6.66,0.8,9.39,2.29c3,1.64,5.17,4.74,7.94,6.89c2.65,2.04,5.61,3.67,9.38,6.09c-0.44-5.55-0.67-9.67-1.18-13.75
                c-0.11-0.91-1.09-2.17-1.94-2.45c-12.1-3.93-23.58-10.37-36.96-9.33c-1.4,0.11-2.86-0.64-4.29-0.99c0.02-0.75,0.04-1.51,0.07-2.26
                c9.28-0.52,18.57-1.04,27.73-1.56C1257.16,536.03,1259.43,530.93,1261.97,525.24z M583.98,814.64c5.92,0.62,11.26,3.94,15.54-0.63
                c10.57-11.28,20.7-22.95,31.09-34.4c2.63-2.9,2.59-4.84-0.34-7.9c-3.58-3.74-6.23-5.18-12.06-4c-12.48,2.53-25.39,2.83-38.07,4.49
                c-7.25,0.95-14.38,2.79-21.41,4.2c1.18,3.64,2.04,7.2,3.54,10.46c0.58,1.26,2.51,2.42,3.98,2.65c2.77,0.42,5.65-0.02,8.47,0.19
                c1.53,0.11,3.03,0.8,4.54,1.23c-0.08,0.55-0.15,1.09-0.23,1.64c-3.06,0.16-6.13,0.51-9.19,0.45c-4.19-0.09-5.69,1.96-5.21,5.84
                c0.46,3.78,0.82,7.6,1.59,11.32c0.6,2.93,2.23,4.2,5.38,2.38c2.09-1.21,4.47-2.2,6.83-2.55c4.42-0.65,8.91-0.87,13.39-1.05
                c1.92-0.08,3.87,0.38,5.81,0.6c0.02,0.51,0.03,1.02,0.05,1.54C593.44,812.18,589.21,813.28,583.98,814.64z M1004.22,456.94
                c1.51,0.07,2.06,0.27,2.42,0.09c1.32-0.69,2.58-1.5,3.86-2.28c9.32-5.63,18.91-9.57,30.13-5.87c5.41,1.79,6.67,3.8,4.38,9.18
                c-0.48,1.14-1.91,1.87-2.9,2.79c-0.36-1.13-1.09-2.3-1.01-3.4c0.2-2.79-0.04-5.16-3.41-5.53c-6.92-0.78-13.85-2.01-20.17,2.63
                c-2.93,2.15-6.12,4.39-9.53,5.31c-5.76,1.57-5.7,6.39-4.08,9.29c2.49,4.45,6.87,7.9,10.75,11.45c0.66,0.6,2.88-0.16,4.22-0.7
                c7.72-3.1,15.18-7.03,23.13-9.29c6.93-1.97,12.61-8.65,21.93-5.22c-6.22-5.84-2.98-12.18-2.92-17.84
                c0.07-5.9,2.18-11.78,3.42-17.75c-11.62-3.85-33.4-1.59-44.21,2.47c-3.5,1.32-8.08,2.9-9.6,5.74
                C1007.59,443.71,1006.33,450.34,1004.22,456.94z M1190.18,862.33c0.12-1.22,0.43-2.04,0.24-2.74c-1.44-5.46-3.5-10.82-4.37-16.36
                c-1.57-9.96-2.48-20.03-3.6-30.06c-0.72-6.42-2.4-7.86-8.94-7.94c-5.48-0.07-10.96-0.51-16.45-0.62
                c-8.64-0.18-17.28-0.37-25.91-0.17c-1.16,0.03-3.06,2.15-3.27,3.51c-0.86,5.67,1.84,9.59,6.49,12.91
                c11.76,8.39,23.27,17.13,34.86,25.76C1176.1,851.72,1182.93,856.88,1190.18,862.33z M1069.97,431.67c2.8,0.97,5.26,2.4,7.78,2.54
                c2.51,0.13,5.08-1.05,8.11-1.77c-3.34,5.61-9.12,7.31-14.31,4.16c-3.54-2.15-5.06-0.89-5.41,2.43c-0.51,4.92-0.73,9.9-0.66,14.84
                c0.03,1.89,2.06,3.96,1.68,5.61c-0.97,4.26,0.65,5.54,4.49,5.35c5.3-0.26,10.61-0.61,15.91-0.71c1.52-0.03,3.06,0.67,4.59,1.04
                c-0.05,0.57-0.1,1.13-0.16,1.7c-4.81,0.24-9.63,0.48-14.44,0.71c0.04,0.45,0.08,0.89,0.12,1.34c5.25-0.34,10.96-2.17,15.64-0.7
                c10.04,3.16,18.23,1.58,25.92-5.28c2.77-2.47,6.05-4.38,9.35-6.73c-0.84-1.08-1.35-2.26-2.24-2.79
                c-7.39-4.39-14.89-8.61-22.27-13.03c-1.43-0.86-2.9-2.13-3.64-3.57c-1.64-3.16-2.04-7.3-4.33-9.73
                C1087.76,418.25,1075.96,420.24,1069.97,431.67z M506.93,568.38c-10.87-2.72-21.07-1.31-31.32,3.55
                c-7.21,3.42-13.73,10.35-14.05,18.39c-0.05,1.25,0.71,3.08,1.7,3.73c4.6,3.03,9.25,5.71,15.32,3.98c5.1-1.45,10.4-2.31,15.67-3
                c10.67-1.4,21.43-2.22,32.05-3.92c3.69-0.59,7.33-2.69,10.48-4.86c1.5-1.03,3-4.43,2.37-5.68c-1-1.98-3.59-3.62-5.86-4.43
                c-5.87-2.09-11.91-3.72-17.93-5.35c-1.23-0.33-2.71,0.05-4.04,0.3c-1.31,0.25-2.57,0.74-3.85,1.12
                C507.29,570.96,507.11,569.67,506.93,568.38z M536.79,764.9c0.01-0.35,0.02-0.69,0.02-1.04c3.64-0.23,7.28-0.38,10.9-0.7
                c3.01-0.27,6.43,0.44,7.8-3.73c1.38-4.18-1.11-6.62-3.54-9.14c-1.02-1.06-2.22-2.15-3.55-2.7c-8.43-3.46-16.87-6.9-25.4-10.11
                c-9.67-3.64-23.44,3.24-26.08,13.27c-0.71,2.71-0.7,6.72,0.84,8.67c3.09,3.91,7.52,5.95,13.14,5.61
                C519.52,764.51,528.17,764.9,536.79,764.9z M646.92,580.43c-2.51,0.31-4.53,1.15-5.19,0.54c-4.22-3.93-9.45-3.4-14.46-3.68
                c-7.45-0.42-7.7-0.05-9.09,7.21c-0.35,1.8-1.43,3.47-2.17,5.2c-1.87-0.89-4.1-1.4-5.52-2.76c-2.35-2.24-3.72-5.7-6.3-7.47
                c-6.46-4.43-16.3-1.22-19.38,5.99c-1.23,2.88-2.13,5.96-2.76,9.04c-1.46,7.24-0.2,8.44,7.02,6.8c6.62-1.51,13.13-4.13,19.82-4.63
                c10.86-0.82,21.82-0.17,32.73-0.54c1.81-0.06,4.82-1.93,5.07-3.36C647.38,588.82,646.92,584.66,646.92,580.43z M1042.35,414.7
                c-0.51-0.05-1.02-0.11-1.54-0.16c0-1.51-0.42-4.17,0.07-4.35c3.15-1.17,6.52-2.44,9.79-2.39c2.71,0.05,5.39,1.88,8.47,3.06
                c-0.17,0.66-0.51,1.97-0.88,3.4c2.51,0.33,4.62,0.78,6.73,0.81c1.18,0.02,2.7-0.32,3.43-1.1c0.48-0.52,0.17-2.32-0.38-3.16
                c-2.7-4.18-5.44-8.36-8.51-12.27c-1.14-1.45-3.17-2.93-4.89-3.07c-8.06-0.63-16.26-1.72-24.23-0.9
                c-9.48,0.97-15.62,11.6-12.61,19.73c7.25-3.81,14.89-2.61,19.97,4.08c-2.71,0-5.61,0.8-6.4-0.15c-2.26-2.73-4.42-1.52-6.72-0.86
                c-1.42,0.41-2.79,1.59-4.14,1.53c-4.81-0.23-5.81,2.51-5.92,6.94c12.8-3.81,25.36-6.4,38.47-4.56c-2.45,1.28-5.03,1.87-7.64,2.19
                c-2.55,0.31-5.13,0.34-7.7,0.49c0.05,0.54,0.1,1.08,0.15,1.62c1.51-0.19,3.03-0.41,4.54-0.55c3.79-0.37,7.61-0.56,11.38-1.11
                c1.45-0.21,2.74-1.63,4.17-1.75c3.23-0.28,6.5-0.09,10.07-0.09c-0.34,2.01-0.54,3.17-0.74,4.33c0.43,0.21,0.87,0.42,1.3,0.63
                c0.81-1.56,2.53-4.31,2.29-4.49c-2.42-1.85-5.27-4.74-7.79-4.55c-6.87,0.53-9.47,0.28-12.54-5.26c-1.8-3.26-3.56-2.27-5.57-0.88
                C1043.95,412.56,1043.22,413.73,1042.35,414.7z M444.75,794.65c-3.03-7.28-9.97-13.58-18.05-15.07
                c-7.77-1.44-15.75-2.06-23.66-2.37c-10.29-0.4-11.45,1.6-7.63,11.32c0.49,1.24,0.67,2.67,1.43,3.7c0.67,0.91,1.88,1.86,2.95,1.98
                c8.04,0.89,16.11,1.65,24.17,2.32C430.86,797.1,437.82,797.89,444.75,794.65z M467.2,613.62c3.32,0.81,6.44,2.04,9.62,2.22
                c4.96,0.29,10,0.21,14.94-0.32c10.2-1.09,20.53-1.56,29.91-6.68c4.32-2.35,9.06-3.92,13.58-5.91c1.22-0.54,2.35-1.29,3.53-1.94
                c-1.2-0.91-2.36-2.51-3.61-2.6c-4.13-0.29-8.36-0.49-12.43,0.11c-15.93,2.36-31.81,5.01-47.69,7.63
                C471.22,606.77,468.28,608.63,467.2,613.62z M1041.99,499.19c-0.02,0.29-0.04,0.58-0.07,0.88c1.93-0.31,3.93-0.36,5.76-0.96
                c15.83-5.2,32.07-4.36,48.33-3.52c1.38,0.07,3.74-0.71,4.02-1.61c0.45-1.44-0.19-3.43-0.89-4.94c-0.47-1.03-1.84-1.65-3.31-2.88
                c7.35-1.61,2.65-7.66,5.5-10.85c-0.16-0.57-0.31-1.13-0.47-1.7c-12.22-0.6-24.45-1.19-36.59-1.79c0.18,3.03,0.36,5.99,0.53,8.85
                c3.84-0.37,7.26-0.69,10.69-1.02c0.07,0.72,0.14,1.44,0.21,2.16c-5.01,1.19-9.88,3.57-15.62,0.55c0,1.36,0,2.63,0,3.37
                c9.99,0,19.84,0,29.69,0c0.04,0.3,0.08,0.6,0.12,0.89c-4.69,0.78-9.38,1.55-14.06,2.33c7.37,1.43,14.87-0.54,21.98,2.37
                c-1.64,0.73-3.26,0.56-4.84,0.76c-11.95,1.5-24.26-1.66-36,3.33C1052.29,497.42,1047,497.98,1041.99,499.19z M587.82,605.35
                c0.03,0.44,0.06,0.87,0.09,1.31c0.4,0.22,0.85,0.69,1.21,0.63c9.11-1.55,18.16-1.04,27.18,0.69c0.96,0.18,2,0.11,2.98-0.02
                c11.98-1.64,23.97-3.27,35.94-5c3.64-0.52,7.66-2.28,7.91,2.64c3.94-0.61,7.5-1.6,11.06-1.64c7.33-0.07,7.34,0.14,8.34-7.16
                c0.09-0.63,0.01-1.29,0.01-2.28c-7.13,0.02-14.22-3.18-20.78,1.75c-0.47,0.36-1.25,0.35-1.89,0.43c-6.3,0.73-12.59,1.57-18.9,2.14
                c-8.72,0.79-17.56,2.48-26.17,1.75C605.02,599.78,596.94,604.56,587.82,605.35z M976.9,383.94c-1.3,0-1.82-0.15-2.18,0.03
                c-0.89,0.42-2.01,0.79-2.52,1.54c-4.17,6.03-9.59,11.63-12.03,18.31c-6.47,17.68-8.87,36.41-11.58,54.99
                c-0.46,3.17-1.04,6.32-1.6,9.65c3.73,0.44,6.78,0.81,10.52,1.25c0.83-6.83,1.73-13.37,2.41-19.93
                c1.41-13.52,2.69-27.06,4.04-40.59c0.11-1.14,0.81-2.78,0.31-3.34c-4.21-4.65,0.13-7.05,2.45-9.9
                C969.88,392.06,973.19,388.29,976.9,383.94z M1169.95,533.14c-6.9-4.48-12.73-8.26-18.56-12.05c-0.57,0.22-1.14,0.45-1.71,0.67
                c0.45,2.65,0.94,5.3,1.32,7.96c0.2,1.38,0.2,2.78,0.29,4.18c-1.32-0.71-3.58-1.21-3.8-2.17c-0.77-3.39-0.71-6.97-1.22-10.43
                c-0.22-1.47-1-4.01-1.69-4.07c-7.55-0.61-15.15-1.04-22.31,2.43c1.15,4.13,2.4,7.74,3.12,11.46c0.55,2.83,1.74,3.84,4.66,3.83
                c5.15-0.02,10.51-0.37,15.4,0.89C1153.75,537.98,1160.95,534.48,1169.95,533.14z M1385.54,499.99c-1.14-2.21-1.61-3.37-2.28-4.39
                c-3.42-5.17-8.85-6.76-14.46-7.81c-7.14-1.34-14.29-2.75-21.5-3.57c-4.56-0.51-6.21,3.21-7.15,6.91c-1,3.89,1.96,5.65,4.73,6.49
                c4.06,1.23,8.36,2.1,12.59,2.25C1366.45,500.22,1375.47,499.99,1385.54,499.99z M594.01,819.67c-8.6-0.47-16.8-0.98-25.02-1.24
                c-0.65-0.02-1.84,1.73-1.95,2.75c-1.1,9.82,0.98,19.14,5,28.06c0.67,1.48,1.93,2.69,2.63,3.64
                C581.08,841.89,587.21,831.35,594.01,819.67z M485.08,787.4c-12.86,0-24.71,0-37.25,0c0.94,2.15,1.22,3.31,1.87,4.2
                c3.25,4.48,6.39,9.07,9.97,13.28c3.17,3.73,7.21,3.77,11.56,1.44C478.63,802.35,480.53,794.65,485.08,787.4z M971.52,474.55
                c0.8-7.06,1.57-13.8,2.33-20.54c0.75-6.61,1.49-13.21,2.33-20.76c-3.42,1.95-5.54,3.15-8.33,4.74c3.09-6.13,5.68-11.26,8.65-17.14
                c-6.63,2.93-11.28,9.59-11.64,15.04c-0.64,9.77-1.33,19.54-2.34,29.27C961.7,473.13,962.32,474.06,971.52,474.55z M857.2,582.87
                c-0.55-0.16-1.1-0.32-1.65-0.48c-1.18,2.98-2.68,5.89-3.48,8.97c-1.84,7.06-2.6,14.49-5.21,21.22
                c-2.12,5.45-2.29,10.66-1.46,16.01c0.32,2.09,2.39,3.97,3.84,5.79c0.78,0.98,2.04,1.56,2.94,2.46c4.35,4.29,8.69,8.59,12.92,12.99
                c0.82,0.85,1.47,2.12,1.65,3.28c0.85,5.69,1.16,11.48,2.4,17.08c0.63,2.86,2.8,5.38,4.28,8.06c0.46-0.25,0.93-0.5,1.39-0.75
                c-0.45-1.17-0.92-2.33-1.33-3.51c-2.22-6.38-5.59-12.38-4.81-19.61c0.82-7.52-4.42-14.86-11.25-18.41
                c-2.37-1.24-5.11-4.6-5.09-6.97c0.04-3.91-1.26-5.27-5.04-5.4c1.86-2.12,4.31-3.62,4.63-5.48
                C853.96,606.41,855.5,594.63,857.2,582.87z M379.6,687.34c-3.07,0-5.41-0.13-7.73,0.02c-9.48,0.65-18.36,3.06-25.69,9.5
                c-1.04,0.92-2.22,2.35-2.28,3.59c-0.28,5.47,6.12,13.56,11.52,15.4c2.21-5.98,3.12-13.06,6.98-17.61
                C366.45,693.47,373.35,691.11,379.6,687.34z M843.24,510.65c0.08-0.53,0.15-1.05,0.23-1.58c-9.98-4.06-19.97-8.12-30.53-12.41
                c-1.37,2.4-2.93,5.08-4.43,7.79c-2.26,4.08-1.5,5.98,3.21,6.11C822.22,510.84,832.73,510.65,843.24,510.65z M1283.94,630.52
                c0.99,5.95,1.86,11.61,2.95,17.22c0.19,0.96,1.27,2.37,2.08,2.48c6.48,0.83,13,1.39,19.76,2.06
                C1306.97,645.58,1291.69,632.16,1283.94,630.52z M1015.11,501.82c14.27-3.13,28.07-6.16,43.12-9.46
                c-1.99-2.57-3.18-5.58-4.63-5.71c-3.86-0.36-7.83,0.52-11.76,0.93c-0.13,0.01-0.34,0.21-0.34,0.33c0,5.7-3.22,2.72-5.88,2.5
                c-1.98-0.17-4.13,0.95-6.13,1.69c-4.87,1.77-9.7,3.66-15.16,5.73C1014.46,498.43,1014.75,499.95,1015.11,501.82z M1040.66,504.13
                c-10.51-0.4-18.93,5.64-28.64,7.26c0.09,0.56,0.18,1.12,0.27,1.69c6.52,3.21,13.04,6.43,18.94,9.34
                C1034.22,516.62,1037.26,510.72,1040.66,504.13z M685.38,579.33c-1.41,0-3.37-0.61-4.2,0.1c-4.18,3.6-8.8,2.5-13.47,1.92
                c-1.12-0.14-2.3,0.24-3.45,0.37c-4.35,0.48-2.72,3.79-2.79,6.15c-0.08,2.82,1.76,3.01,4.07,2.76c3.11-0.33,6.3-0.73,9.38-0.4
                C683.44,591.12,685.97,588.7,685.38,579.33z M1391.76,519.84c0.21-0.65,0.42-1.3,0.62-1.94c-2.75-1.08-5.43-2.48-8.28-3.16
                c-3.69-0.87-8.68,1.07-9.47-5.22c-0.07-0.57-1.94-1.08-3.04-1.31c-2.43-0.51-4.91-1.14-7.37-1.15c-7.21-0.02-14.97-1.85-20.67,5.7
                C1360.29,511.81,1376.27,514.19,1391.76,519.84z M964.23,387.97c2.53-2.15,4.53-4.15,6.81-5.72c6.42-4.41,7.95-8.7,5.65-16.69
                C968.46,366.95,961.76,378.48,964.23,387.97z M976.76,393.5c-0.85-0.2-1.7-0.39-2.55-0.59c-2.94,9.73-5.88,19.46-8.82,29.19
                c4.16-1.53,7.12-3.31,9.88-5.36c0.78-0.58,1.41-1.91,1.43-2.9C976.82,407.06,976.76,400.28,976.76,393.5z M1155.58,699.29
                c1.35,2.63,1.52,5.63,5.36,3.63c0.76-0.4,2.35-0.53,2.75-0.08c2.42,2.8,4.82,1.17,7.21,0.2c6.91-2.82,13.82-5.66,20.69-8.6
                c0.92-0.39,1.58-1.39,2.77-2.5C1181.08,694.46,1168.77,696.8,1155.58,699.29z M692.4,415.6c0.23,3.82,6.09,10.64,9.23,10.92
                c0.69,0.06,1.57-0.42,2.15-0.9c3.31-2.74,6.55-5.56,10.14-8.63c-0.91-0.63-2.44-2.58-3.96-2.57
                C704.2,414.46,698.45,415.14,692.4,415.6z M1371.87,622.98c-13.82-3.99-28.12-3.93-42.53-2.07
                C1343.52,621.6,1357.7,622.29,1371.87,622.98z M1033.6,481.65c-4.96,1.1-9.94,2.11-14.85,3.4c-0.71,0.19-1.71,2.17-1.46,2.53
                c0.62,0.88,1.89,1.78,2.92,1.8c2.58,0.06,5.18-0.39,7.77-0.59C1032.53,488.45,1034.57,486.08,1033.6,481.65z M579.66,674.44
                c0.95-0.21,1.89-0.41,2.84-0.62c-1.71-8.57-3.36-17.15-5.23-25.69c-0.11-0.52-1.89-0.68-3.19-1.11
                C576.06,656.75,577.86,665.6,579.66,674.44z M491.28,624.41c1,1.19,1.21,1.63,1.39,1.62c8.72-0.39,17.44-0.77,26.15-1.36
                c0.65-0.04,1.21-1.56,1.81-2.39c-0.95-0.42-1.94-1.31-2.83-1.2C509.21,622.06,500.62,623.21,491.28,624.41z M1149.42,539.39
                c0.01-0.19,0.03-0.39,0.04-0.58c-6.13,0-12.27-0.24-18.36,0.21c-1.17,0.09-2.13,2.9-3.19,4.46c1.49,0.33,3.13,1.24,4.45,0.9
                C1138.09,542.91,1143.74,541.08,1149.42,539.39z M1123.97,798.04c-0.02,0.62-0.04,1.24-0.06,1.86c13.16,0,26.31,0,36.82,0
                C1150.29,798.57,1137.2,796.28,1123.97,798.04z M657.55,586.81c0.36-0.33,0.73-0.66,1.09-0.99c-1.52-1.52-2.99-4.28-4.57-4.35
                c-5.27-0.23-2.83,4.44-3.38,6.99c-0.41,1.89-0.34,4.51,3.09,4.32C657.87,592.55,657.97,589.89,657.55,586.81z M549.36,741.36
                c-3-7.96-6.06-16.07-9.12-24.18c0.23,5.43,1.91,10.63,2.02,15.87C542.38,738.89,545.79,740.26,549.36,741.36z M1293.23,620.92
                c8.47,0,17.33,0,26.19,0c-0.02-0.48-0.05-0.96-0.07-1.44C1310.58,621.34,1301.4,615.3,1293.23,620.92z M891.82,507.71
                c-8.26,1.36-15.74,2.6-23.22,3.84C879.55,514.15,888.99,512.55,891.82,507.71z M608.65,764.79c-4.77-3.22-15.37-2.06-22.24,2.81
                C594.56,766.57,601.39,765.71,608.65,764.79z M1141.37,545.3c-0.04,0.72-0.09,1.44-0.13,2.16c4.15,0.2,8.31,0.52,12.46,0.5
                c1.04-0.01,2.07-1.12,3.1-1.72c-1.06-0.71-2.13-2.05-3.17-2.01C1149.54,544.35,1145.46,544.9,1141.37,545.3z M892.87,486.24
                c3.61,0.52,4.53,5.98,8.43,2.17c1.84-1.81,2.59-4.74,4.27-8.01C901.2,482.4,897.7,484.01,892.87,486.24z M669.15,744.12
                c-0.02,0.61-0.04,1.22-0.05,1.83c5.05,0.53,10.09,1.05,15.14,1.58c0.05-0.47,0.1-0.94,0.15-1.42
                C679.45,744.32,674.67,741.22,669.15,744.12z M1157.88,309.56c2.3-4.7,3.97-8.13,6.04-12.36c-2.57-0.46-4.56-0.82-7.14-1.28
                c0.48,2.93,0.91,4.84,1.07,6.77C1158,304.55,1157.88,306.43,1157.88,309.56z M1142.62,552.61c-7.41-2.4-11.98-0.07-11.07,5.36
                C1135.04,556.29,1138.25,554.73,1142.62,552.61z M1157.79,365.52c-0.73,0.06-1.46,0.12-2.19,0.18c0.45,5.12,0.9,10.24,1.34,15.36
                c0.65-0.06,1.3-0.11,1.95-0.17C1158.53,375.77,1158.16,370.64,1157.79,365.52z M1038.08,480.59c1.62,3.35,8.16,4.34,9.47,2
                c0.29-0.52-1.02-2.78-1.64-2.8C1043.5,479.73,1041.07,480.23,1038.08,480.59z M1068.13,755.59c0.1-0.76,0.2-1.52,0.31-2.28
                c-4.71-0.78-9.42-1.55-14.14-2.33c-0.12,0.67-0.24,1.35-0.36,2.02C1058.67,753.86,1063.4,754.72,1068.13,755.59z M1038.91,475.75
                c5.9,1.09,10.93,0.1,14.72-5.64C1048.29,472.16,1043.6,473.95,1038.91,475.75z M1142.56,512.43c-0.03-0.31-0.05-0.62-0.08-0.93
                c-7.33,0.45-14.66,0.89-21.99,1.34c0.03,0.45,0.06,0.9,0.09,1.35C1127.9,513.61,1135.23,513.02,1142.56,512.43z M616.12,577.04
                c-2.85,1.19-4.31,1.8-5.77,2.41c0.88,1.98,1.76,3.96,2.65,5.94c0.5-0.15,1-0.3,1.51-0.46
                C614.91,582.92,615.33,580.9,616.12,577.04z M534.41,618.82c-0.75,0.38-1.51,0.76-2.26,1.14c0.57,1.56,0.9,3.29,1.86,4.56
                c0.25,0.33,2.42-0.78,3.7-1.23C536.61,621.8,535.51,620.31,534.41,618.82z M523.74,612.86c5.8,3.57,6.3-1.1,7.69-4.51
                C528.86,609.85,526.3,611.36,523.74,612.86z M1168.73,558.97c0.05-0.62,0.1-1.23,0.15-1.85c-0.88-0.83-1.75-1.65-3.13-2.96
                c0.46,6.24-4.58,2.95-6.43,5.72C1162.45,559.58,1165.59,559.28,1168.73,558.97z M669.27,577.44c-0.01-0.32-0.01-0.63-0.02-0.95
                c-4.05,0-8.09,0-12.14,0c0.01,0.32,0.01,0.63,0.02,0.95C661.18,577.44,665.22,577.44,669.27,577.44z M469.5,620.2
                c0,0.38,0,0.76,0.01,1.14c4.32,0.22,8.63,0.43,12.95,0.65c0.02-0.59,0.04-1.19,0.06-1.78C478.18,620.2,473.84,620.2,469.5,620.2z
                M999.93,322.61c-0.39-0.47-0.78-0.93-1.16-1.4c-2.17,1.88-4.34,3.77-6.51,5.65c0.41,0.47,0.82,0.94,1.23,1.41
                C995.63,326.38,997.78,324.5,999.93,322.61z M1149.29,511.93c0,0.28-0.01,0.56-0.01,0.84c5.42,0,10.84,0,16.25,0
                c-1.28-0.68-2.57-0.82-3.86-0.83C1157.54,511.91,1153.42,511.93,1149.29,511.93z M465.55,827.5c-0.05,0.26-0.1,0.52-0.16,0.78
                c2.87,0,5.74,0,8.61,0c0.01-0.26,0.03-0.52,0.04-0.78C471.2,827.5,468.38,827.5,465.55,827.5z M1220.13,553.89
                c-0.05,0.69-0.09,1.39-0.14,2.08c2.13,0.15,4.26,0.29,6.39,0.44c0.05-0.71,0.1-1.42,0.15-2.13
                C1224.39,554.15,1222.26,554.02,1220.13,553.89z M1055.01,482.74c0.21-2.72,0.34-4.31,0.53-6.8c-1.98,1.01-3.11,1.59-4.64,2.37
                C1052.3,479.82,1053.23,480.82,1055.01,482.74z M447.19,801.17c-0.13-0.69-0.26-1.37-0.38-2.06c-2.11,0.44-4.21,0.87-6.32,1.31
                c0.08,0.48,0.16,0.97,0.24,1.45C442.88,801.64,445.03,801.4,447.19,801.17z M1235.85,557.68c0.11-0.76,0.23-1.52,0.34-2.28
                c-1.59-0.21-3.18-0.43-4.77-0.64c-0.11,0.61-0.22,1.23-0.33,1.84C1232.67,556.96,1234.26,557.32,1235.85,557.68z M725.31,745.12
                c-0.08-0.57-0.16-1.13-0.24-1.7c-1.81,0.31-3.61,0.61-5.42,0.92c0.07,0.47,0.14,0.94,0.21,1.41
                C721.68,745.54,723.5,745.33,725.31,745.12z M1162.87,544.99c-0.34,0-0.68,0-1.02,0c0,1.43,0,2.86,0,4.3c0.34,0,0.68,0,1.02,0
                C1162.87,547.86,1162.87,546.42,1162.87,544.99z M485.54,627.46c0.02-0.63,0.04-1.25,0.06-1.88c-1.53,0.28-3.06,0.56-4.59,0.85
                c0.05,0.44,0.11,0.88,0.16,1.32C482.62,627.66,484.08,627.56,485.54,627.46z M598.86,616.06c0.01,0.1,0.03,0.21,0.04,0.31
                c2.59,0,5.18,0,7.77,0c-0.02-0.3-0.04-0.61-0.06-0.91C604.02,615.66,601.44,615.86,598.86,616.06z M526.23,623.67
                c0.58-0.15,1.16-0.3,1.75-0.45c-0.19-1.1-0.38-2.21-0.57-3.31c-0.77,0.18-1.53,0.36-2.3,0.54
                C525.48,621.52,525.86,622.6,526.23,623.67z M1026.98,426.85c-0.11-0.35-0.23-0.71-0.34-1.06c-2.01,0.69-4.02,1.38-6.03,2.08
                c0.1,0.27,0.2,0.55,0.31,0.82C1022.93,428.08,1024.95,427.47,1026.98,426.85z M1190.65,556.68c-0.08-0.22-0.16-0.43-0.23-0.65
                c-1.83,0.18-3.66,0.35-5.49,0.53c0.07,0.39,0.15,0.77,0.22,1.16C1186.98,557.37,1188.82,557.03,1190.65,556.68z M612.91,763.24
                c0.03,0.25,0.06,0.49,0.09,0.74c1.79-0.11,3.59-0.22,5.38-0.32c-0.04-0.35-0.07-0.71-0.11-1.06
                C616.48,762.81,614.69,763.03,612.91,763.24z M1012.5,430.73c0.17,0.38,0.34,0.77,0.51,1.15c1.18-0.55,2.37-1.1,3.55-1.65
                c-0.15-0.31-0.29-0.63-0.44-0.94C1014.91,429.77,1013.7,430.25,1012.5,430.73z M1167.67,300.87c0.27,0.47,0.53,0.95,0.8,1.42
                c0.87-0.5,1.74-0.99,2.61-1.49c-0.21-0.42-0.43-0.83-0.64-1.25C1169.51,299.99,1168.59,300.43,1167.67,300.87z M1205.67,553.02
                c0.01-0.29,0.02-0.58,0.03-0.87c-1.35,0-2.71,0-4.06,0c-0.01,0.17-0.02,0.34-0.03,0.51
                C1202.96,552.79,1204.32,552.9,1205.67,553.02z M581,567.51c-0.12,0.29-0.23,0.57-0.35,0.86c0.79,0.31,1.58,0.62,2.37,0.94
                c0.11-0.27,0.22-0.55,0.33-0.82C582.57,568.16,581.79,567.84,581,567.51z M1184.55,513.65c-0.04-0.28-0.09-0.55-0.13-0.83
                c-1,0.18-2,0.35-3,0.53c0.04,0.22,0.07,0.44,0.11,0.66C1182.53,513.89,1183.54,513.77,1184.55,513.65z M603.89,610.75
                c0.03-0.11,0.06-0.23,0.09-0.34c-0.99,0-1.98,0-2.98,0c0,0.11,0,0.23,0,0.34C601.96,610.75,602.92,610.75,603.89,610.75z
                M1272.05,578.92c-0.36,0.16-0.73,0.31-1.09,0.47c0.36,0.63,0.72,1.26,1.07,1.89c0.19-0.09,0.38-0.18,0.57-0.27
                C1272.42,580.32,1272.24,579.62,1272.05,578.92z M1155.1,552.43c0.07-0.43,0.14-0.86,0.21-1.29c-0.85,0-1.69,0-2.54,0
                c-0.03,0.27-0.05,0.53-0.08,0.8C1153.49,552.1,1154.3,552.26,1155.1,552.43z M1366.37,588.29c0.02,0.19,0.03,0.37,0.05,0.56
                c0.83-0.04,1.66-0.08,2.49-0.12c-0.01-0.21-0.03-0.43-0.04-0.64C1368.04,588.16,1367.21,588.23,1366.37,588.29z M1171.66,513.27
                c0,0.11,0,0.21,0,0.32c0.73,0,1.47,0,2.2,0c0-0.11,0-0.21,0-0.32C1173.13,513.27,1172.4,513.27,1171.66,513.27z M712.19,423.99
                c-0.2-0.18-0.41-0.36-0.61-0.54c-0.51,0.57-1.02,1.14-1.53,1.71c0.2,0.18,0.41,0.36,0.61,0.54
                C711.17,425.13,711.68,424.56,712.19,423.99z M97.13,786.99c0.26,0.17,0.51,0.32,0.75,0.48c0.12-0.28,0.36-0.57,0.33-0.84
                c-0.02-0.17-0.43-0.29-0.66-0.43C97.41,786.45,97.27,786.72,97.13,786.99z M761.63,743.08c0.01,0.26,0.01,0.51,0.02,0.77
                c0.57-0.04,1.14-0.09,1.71-0.13c-0.01-0.17-0.02-0.34-0.03-0.51C762.76,743.16,762.2,743.12,761.63,743.08z M598.76,573.69
                C599.33,573.51,599.05,573.6,598.76,573.69C599.06,573.64,599.35,573.59,598.76,573.69z M952.17,757.51
                c-0.14-0.02-0.28-0.04-0.41-0.07c0.06,0.09,0.11,0.17,0.17,0.26C952.01,757.64,952.09,757.57,952.17,757.51z M1375.88,624.56
                l0.39-0.1l-0.29-0.21L1375.88,624.56z M750.12,452.77c0.13,0.01,0.28,0.06,0.4,0.02c0.05-0.02,0.04-0.22,0.05-0.33
                c-0.14-0.01-0.28-0.05-0.4-0.01C750.12,452.46,750.14,452.66,750.12,452.77z M1281.95,815.41l0.06,0.23l0.28-0.14L1281.95,815.41z
                M1380.5,504.47c0.11-0.05,0.26-0.06,0.31-0.15c0.04-0.06-0.05-0.21-0.08-0.31c-0.11,0.05-0.26,0.07-0.31,0.15
                C1380.38,504.22,1380.46,504.36,1380.5,504.47z M518.69,616.17c-0.06-0.14-0.09-0.35-0.18-0.39c-0.11-0.05-0.28,0.07-0.42,0.11
                c0.11,0.2,0.21,0.4,0.32,0.6C518.5,616.39,518.6,616.28,518.69,616.17z M606.36,574.66c0.16,0.01,0.31,0.02,0.47,0.02
                c-0.14-0.13-0.29-0.26-0.43-0.39C606.39,574.42,606.37,574.54,606.36,574.66z M977.45,779.48l-0.35,0.1l0.26,0.18L977.45,779.48z
                M1146.37,552.38l0.28,0.19l0.05-0.31L1146.37,552.38z M1277.11,478.16l0.18-0.26l-0.28-0.05L1277.11,478.16z"/>
            <path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M0.5,47c0-9.33,0-18.67,0-28c14.16,8.57,30.64,8.23,46.05,12.01c8.35,2.05,16.65,4.35,25.02,6.32
                c3.85,0.9,7.83,1.77,11.75,1.77c6.63-0.01,13.26-1.13,19.88-1.06c9.63,0.1,19.38,2.08,28.85,1.08c22.52-2.38,44.5,0.72,66.43,4.7
                c8.78,1.59,17.26,2.52,26.18,1.24c6.34-0.91,12.93-0.5,19.38-0.13c9.46,0.54,18.97,1.12,28.32,2.56
                c5.43,0.84,10.92,2.8,15.77,5.39c4.29,2.3,8.35,3.69,13.2,3.5c6.48-0.25,12.99,0.24,19.45-0.14c5.89-0.35,11.85-0.94,17.57-2.32
                c5.61-1.36,10.94-2.43,16.66-0.68c2.29,0.7,4.9,0.5,7.36,0.43c6.28-0.2,12.56-0.71,18.84-0.81c8.48-0.13,17.01,0.6,25.43-0.14
                c9.5-0.83,18.47,0.75,27.6,2.92c7.21,1.72,14.68,2.43,22.06,3.29c3.95,0.46,8.01-0.05,11.96,0.41
                c15.33,1.81,30.62,3.85,45.94,5.74c2.29,0.28,4.63,0.16,6.94,0.34c9.04,0.69,18.45-0.23,26.01,6.58c1.05,0.95,3.12,0.84,4.75,1.05
                c15.63,2.02,31.26,4.01,46.89,6.01c7.88,1.01,15.75,2.18,23.66,2.97c4.29,0.43,8.65,0.09,12.97,0.27
                c15.98,0.66,31.8-0.02,47.65-2.82c6.6-1.17,14.05-0.65,20.5,1.21c9.7,2.79,19.19,5.31,29.37,4.49c2.75-0.22,6.28-0.59,8.22,0.81
                c4.43,3.2,9.09,2.85,13.85,2.61c7.14-0.36,14.3-1.67,21.39-1.34c16.28,0.76,32.5,2.62,48.78,3.36c15.43,0.7,30.9,0.93,46.34,0.65
                c17.78-0.32,35.55-1.94,53.33-2.01c13.81-0.05,27.47,2.27,40.62,6.95c2.63,0.94,5.48,1.34,8.26,1.76
                c7.21,1.09,14.62,1.4,21.64,3.22c13.3,3.45,27.39,2.26,40.4,8.06c4.71,2.1,11.14,0.34,16.79,0.34c0.21-0.58,0.42-1.15,0.63-1.73
                c-1.82-1.45-3.64-2.9-5.47-4.34c-5.6-4.41-11.22-8.8-16.81-13.22c-6.21-4.92-12.38-9.89-18.62-14.78c-1.24-0.98-2.69-1.7-4.29-2.7
                c2.13-1.72,4.31-2.74,5.29-4.45c1.61-2.81,3.66-2.56,5.92-1.71c1.51,0.57,3.1,1.38,4.21,2.52c9.67,9.91,21.59,16.53,33.5,23.15
                c2.14,1.19,4.56,1.93,6.93,2.64c6.31,1.89,12.7,3.53,18.98,5.51c4.59,1.45,9,3.48,13.59,4.89c7.91,2.42,16.25,3.62,22.78,9.52
                c1.06,0.96,3.47,0.82,5.18,0.62c5.14-0.62,9.2,1.33,13.2,4.32c2.11,1.58,4.86,2.35,7.39,3.29c4.25,1.59,8.83,2.55,12.78,4.68
                c4.2,2.27,8.24,5.18,11.62,8.55c5.02,5.01,9.76,5.25,16.75,3.7c11.89-2.64,23.71-4.44,35.44-8.69
                c15.63-5.65,32.16-2.83,48.16,1.64c6.02,1.68,12.55,3.37,18.58,2.7c10.97-1.22,21.8-4.01,32.6-6.49
                c9.98-2.29,19.39-2.67,27.08,4.95c1.93-1.03,3.81-2.72,5.9-3.05c7.71-1.2,15.47-2.38,23.24-2.73c7.44-0.34,14.93,0.37,22.39,0.62
                c0,24.67,0,49.33,0,74c-13.08-0.02-26.11-0.41-39.01-3.12c-4.09-0.86-8.53-0.17-12.8-0.06c-2.96,0.08-5.94,0.72-8.86,0.52
                c-15.42-1.04-30.81-2.61-46.25-3.33c-12.43-0.58-24.9-0.37-37.35-0.22c-7.13,0.08-14.36,0.08-21.34,1.3
                c-7.84,1.37-15.28,1.67-23.14-0.11c-4.99-1.13-10.49-0.04-15.75,0.12c-2.64,0.08-5.36,0.8-7.9,0.37
                c-12.61-2.15-25.32-4-37.73-7.06c-15.31-3.78-30.35-8.64-45.5-13.08c-1.1-0.32-2.04-1.14-3.14-1.5
                c-4.36-1.45-8.67-3.53-13.16-4.04c-8.58-0.97-17.32-0.5-25.88-1.56c-21.62-2.67-43.1-6.59-64.77-8.62
                c-22.86-2.14-44.94-7.44-66.89-13.58c-30.7-8.59-61.97-13.23-93.74-15.31c-12.72-0.83-25.57-2.27-37.94-5.21
                c-23.82-5.66-47.43-11.34-72.18-11.86c-14.36-0.3-28.64-3.74-43-5.32c-9.02-1-17.58-3.08-25.93-6.69
                c-3.31-1.43-7.54-1.83-11.14-1.29c-8,1.22-15.71,1.13-23.73-0.29c-6.75-1.2-13.89-1.11-20.77-0.49
                c-8.24,0.74-16.38,3.82-24.56,3.8c-15.87-0.05-31.77-1.23-47.61-2.56c-11.91-1-23.73-3.13-35.62-4.43
                c-6.6-0.72-13.51,0.18-19.86-1.35c-15.72-3.8-30.32-11.69-47.02-12.15c-9.07-0.25-18.08-2.22-27.15-2.73
                c-9.69-0.55-18.94-1.85-28.19-5.44c-11.11-4.32-22.76-8.75-34.46-9.75c-24.81-2.13-49.81-2.09-74.73-2.91
                c-10.29-0.34-20.72,0.35-30.84-1.11c-17.7-2.54-35.22-6.4-52.82-9.65c-1.79-0.33-3.64-0.38-5.46-0.49
                c-11.27-0.68-22.54-1.35-33.81-2.01c-2.82-0.17-5.64-0.37-8.47-0.43c-10.78-0.25-21.95,1.3-32.26-1.01
                c-26.18-5.87-52.27-2.1-78.34-1C13.32,49.63,7,48.96,0.5,47z"/>
            <path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M0.5,691c0-32.67,0-65.33,0-98c1.82,0,3.65,0.03,5.47,0c9.45-0.2,18.8-0.81,28.06,2.64
                c8.16,3.04,16.75,2.01,25.09-0.15c15.87-4.12,31.6-8.88,47.62-12.34c9.99-2.16,20.47-1.96,30.59-3.66
                c8.07-1.36,15.77-0.9,23.71,0.63c3.89,0.75,8.12,0.18,12.14-0.27c4.93-0.55,9.8-1.6,14.7-2.41c2.94-0.49,6.2-0.31,8.77-1.55
                c10.49-5.06,21.28-2.25,31.82-1.26c10.92,1.03,21.69,1.12,32.6,0.25c9.48-0.75,18.95-2.6,28.54,0.84
                c5.69,2.04,12.05,2.24,18.13,3.14c1.1,0.16,2.3-0.18,3.42-0.42c5.63-1.22,11.2-3.1,16.9-3.6c8.2-0.72,16.53-1.09,24.72-0.46
                c14.87,1.15,29.48,0.82,43.79-3.8c6.74-2.17,13.34-5.21,20.25-6.28c5.64-0.87,11.16-0.5,15.13-5.66c0.67-0.87,2.8-0.58,4.25-0.88
                c5.78-1.17,10.74-3.67,14.03-8.81c0,0-0.1,0.07-0.1,0.07c7.28-1.82,11.87,6.52,19.31,4.51c-6.89,8.93-14.93,15.52-25.43,18.24
                c-18.81,4.87-36.67,12.19-54.57,19.64c-9.86,4.1-20.53,6.33-30.94,8.98c-5.6,1.43-12.23,0.51-16.99,3.17
                c-13.37,7.48-27.82,11.79-42.13,16.62c-4.71,1.59-9.34,4.12-14.16,4.57c-14.05,1.3-28.2,2.73-42.26,2.28
                c-13.17-0.42-24.49,3.13-35.21,10.11c-3.32,2.16-6.65,4.65-10.34,5.86c-46.52,15.15-93.28,29.59-141.25,39.43
                C37.78,686.2,19.06,688.2,0.5,691z"/>
            <path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M1399.5,518c-4.44-3.25-8.87-6.49-13.09-9.58c4.28-3.2,4.41-11.05,0.62-16.32
                c-5.41-7.51-13.57-8.43-21.38-10.41c-3.67-0.93-7.51-1.24-11.18-2.18c-8.98-2.31-16.91,2.11-18.44,10.99
                c-0.39,2.24,0.77,4.97,1.84,7.17c1.32,2.72,3.3,5.12,5.06,7.76c-0.51,0.54-0.88,1.19-1.43,1.46
                c-14.89,7.19-19.72,20.33-21.99,35.54c-1.87,12.57-6.22,24.22-14.78,34.27c-8.37,9.83-15.71,20.55-23.79,31.27
                c-0.37-1.54-0.77-3.31-1.22-5.08c-2.24-8.8-1.25-17.82-1.92-26.72c-0.28-3.77-1.25-7.48-1.89-11.23c-0.56-3.31-2.7-6.71,0.55-9.81
                c0.11-0.11,0.25-0.34,0.2-0.44c-4.43-10.04,2.21-19.7,1.03-29.58c-0.98-8.18,1.02-15.59,5.16-22.43c4.85-8.01,5.34-16.77,6-25.79
                c1.22-16.91-1.95-33.13-6.37-49.2c-3.03-11.04-6.87-21.87-9.66-32.97c-1.24-4.92-0.8-10.27-1.05-14.24
                c5.33,0.43,10.57,0.91,15.82,1.26c2.99,0.2,6.03-0.09,8.97,0.34c11.57,1.67,22.73,1.01,33.87-3.21c3.97-1.5,9.01-0.49,13.53-0.13
                c6.1,0.49,12.19,1.26,18.23,2.28c5.66,0.96,11.24,2.41,16.86,3.62c4.18,0.9,8.36,1.84,12.57,2.57c2.6,0.45,5.26,0.54,7.89,0.79
                C1399.5,431.33,1399.5,474.67,1399.5,518z"/>
            <path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M1332.5,685.07c0.69,1.78,1.63,3.5,2.04,5.34c2.27,10.21,4.39,20.45,6.59,30.68
                c0.56,2.6,1.5,5.17,1.7,7.79c0.74,9.51,3.07,18.67,5.43,27.85c0.33,1.31-0.55,2.93-0.93,4.64c1.99,0.92,4.05,1.88,6.52,3.02
                c0.73-0.93,1.57-2.01,2.41-3.08c-0.47,0.07-0.94,0.14-1.41,0.21c-1.36-2.91-3.47-5.7-3.95-8.75c-1.55-9.82-2.43-19.74-3.69-29.61
                c-0.92-7.25-2.07-14.48-3.02-21.73c-1.44-10.98-6.51-19.91-14.09-27.92c-3-3.17-5.57-7.87-6.05-12.12
                c-1.56-13.97-10.67-21.99-21.12-29.16c-1.65-1.13-4.15-0.95-6.15-1.66c-1.53-0.55-2.91-1.54-4.35-2.34
                c0.18-0.62,0.37-1.23,0.55-1.85c8.36-0.37,16.89-1.96,25.02-0.77c8.58,1.25,16.83-0.09,25.23,0c8.26,0.09,16.53,0.79,24.78,1.5
                c5.91,0.51,11.79,1.47,17.69,2.21c4.6,0.58,9.21,1.13,13.81,1.7c0,8,0,16,0,24c-1.25-0.38-2.48-0.83-3.74-1.14
                c-5.42-1.32-10.96-4.03-16.25-3.62c-16.5,1.28-32.92,3.72-49.36,5.81c-1.06,0.13-2.02,1.03-3.02,1.58
                c0.11,0.52,0.21,1.05,0.32,1.57c2.19,0,4.42,0.25,6.57-0.04c14.44-1.95,28.86-4.12,43.31-5.96c2.89-0.37,6.06-0.1,8.85,0.72
                c4.55,1.33,8.89,3.36,13.32,5.09c0,7.33,0,14.67,0,22c-2.97,0.17-5.93,0.34-8.9,0.51c-0.06,0.66-0.12,1.31-0.18,1.97
                c3.03,0.51,6.05,1.01,9.08,1.52c0,15.67,0,31.33,0,47c-18.25-4.72-44.89-3.69-50.12,2.86c3.78-0.66,6.68-1.23,9.61-1.66
                c13.61-1.97,27.09-0.86,40.5,1.79c0,7.67,0,15.33,0,23c-13.11-1.45-29.99,3.29-32.6,10.17c4.07-0.8,7.65-1.21,11.03-2.29
                c2.37-0.75,4.34-3.1,6.7-3.53c4.88-0.88,9.91-0.95,14.87-1.36c0,15,0,30,0,45c-22.12-2.17-44.24-4.26-66.34-6.55
                c-10.85-1.12-10.73-1.3-12.24-12.31c-1.15-8.39-2.57-16.74-3.91-25.11c-0.81-5.04-0.15-5.79,5.06-5.78c2.2,0,4.4-0.2,6.6-0.31
                c0.02-0.67,0.05-1.35,0.07-2.02c-3.21-0.19-6.47-0.81-9.61-0.45c-3.01,0.35-4.1-0.5-4.78-3.35c-2.97-12.44-6.04-24.86-9.29-37.23
                c-2.24-8.5-4.74-16.94-7.32-25.35c-1.38-4.49,0.34-6.08,4.65-7.06c8.94-2.03,16.03,3.19,23.91,5.32c2.18,0.59,4.64,2.96,6.27-0.82
                L1332.5,685.07z M1340.38,731.47c-8.88-5.61-25.16-7.2-29.67-3.01C1320.45,729.45,1329.69,730.39,1340.38,731.47z M1370.76,712.79
                c-0.09,0.91-0.17,1.83-0.26,2.74c8.89,0.58,17.77,1.15,26.66,1.73c0.05-0.87,0.11-1.73,0.16-2.6
                C1388.47,714.04,1379.61,713.41,1370.76,712.79z M1349.02,680.95c0.09,0.65,0.18,1.31,0.27,1.96c7.48-0.63,14.96-1.25,22.44-1.88
                c-0.05-0.62-0.11-1.25-0.16-1.87C1363.96,678.54,1356.35,677.89,1349.02,680.95z M1309.24,708.07c0.01,0.55,0.03,1.1,0.04,1.65
                c5.09,0.66,10.19,1.31,15.28,1.97c0.08-0.69,0.15-1.38,0.23-2.07C1319.72,708.07,1314.71,706.08,1309.24,708.07z"/>
            <path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M1399.5,587c-2.15-0.26-4.34-0.3-6.43-0.8c-19.13-4.59-38.34-2.31-57.52-0.63
                c-11.21,0.98-22.38,2.42-34.34,3.73c2.09-2.92,3.75-5.91,6.02-8.3c10.15-10.7,14.44-23.97,17.32-37.98
                c1.53-7.43,2.12-15.13,7.44-21.39c2.37-2.79,4.44-4.19,8.38-4.72c12.23-1.65,24.2-0.92,36.15,1.57c3.71,0.77,7.31,2.09,10.95,3.19
                c1.11,0.34,2.42,1.43,3.22,1.11c4.03-1.62,6.23,1.18,8.8,3.21C1399.5,546.33,1399.5,566.67,1399.5,587z M1345.97,576.58
                c0.65,0.42,1.3,0.84,1.94,1.26c2.81-4.13,5.7-8.21,8.3-12.47c0.29-0.48-1.15-1.93-1.63-3c-0.88-1.94-2.26-3.9-2.35-5.91
                c-0.21-4.55-0.93-9.05-5.05-11.27c-6.83-3.68-13.91-0.97-20.88,0.61c3.42,1.13,6.84,1.1,10.26,1.14c3.51,0.04,7.52-1.25,10.4,4.07
                c-4.92-0.65-8.73-1.15-12.54-1.65c-0.16,0.73-0.31,1.46-0.47,2.19c2.49,0.81,4.93,2.02,7.49,2.33c3.83,0.46,5.7,2.09,5.42,5.26
                c-5.04,0.68-10.04,0.55-14.42,2.16c-4.5,1.65-8.14,5.16-7.77,11.04c2.16-1.25,4.07-2.74,5.52-4.59c3.5-4.45,7.53-6.2,13.34-4.73
                c5.01,1.27,7.03,4.37,4.66,8.94C1347.39,573.46,1346.71,575.03,1345.97,576.58z M1358.91,560.12c7.49,2.06,15.98,2.08,20.41,10.66
                c0.51-2.28,0.7-4.51-0.31-5.79C1374.1,558.82,1364.73,556.33,1358.91,560.12z M1361.12,524.64c-7.63-3.17-25.53-0.98-29.77,3.65
                C1341.43,527.05,1350.56,525.93,1361.12,524.64z M1377.7,527.59c-0.03-0.64-0.06-1.29-0.08-1.93c-2.14,0.16-4.28,0.32-6.43,0.48
                c0.03,0.48,0.06,0.97,0.09,1.45C1373.42,527.59,1375.56,527.59,1377.7,527.59z"/>
            <path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M0.5,779c12.82,1.8,25.65,4.82,38.63,2.28c10.62-2.07,21.05-1.96,31.67-0.34
                c3.36,0.51,6.97-0.12,10.39-0.68c3.22-0.53,4.29,0.67,5.88,3.51c5.87,10.46-2.28,16.28-7.43,22.38
                c-14.56,17.21-30.07,33.6-44.66,50.78c-4.99,5.88-10.33,6.64-17.16,5.52c-5.7-0.93-11.53-1-17.3-1.46c0-1,0-2,0-3
                c7.93,0.58,15.86,1.16,23.78,1.73c0.06-0.8,0.12-1.61,0.18-2.41c-7.99-0.77-15.98-1.55-23.97-2.32c0-2.33,0-4.67,0-7
                c13.79-0.81,28,2.56,41.21-3.43c-13.74,0.14-27.47,0.28-41.21,0.43c0-2,0-4,0-6c12.94-0.06,25.88-0.03,38.82-0.24
                c3.37-0.05,6.73-0.84,10.09-1.29c-0.07-0.49-0.14-0.98-0.22-1.47c-16.23,0-32.46,0-48.69,0c0-5,0-10,0-15
                c17.83,0.24,35.69,2.51,53.44-0.75c-17.81-0.75-35.63-1.5-53.44-2.25c0-7.33,0-14.67,0-22c9.46,1.69,18.95,3.23,28.36,5.12
                c13.45,2.71,26.78,0.61,40.15-0.55c3.68-0.32,7.36-0.7,11.04-1.05c-1.2-1.22-2.4-1.53-3.58-1.46c-8.27,0.52-16.63,2.19-24.78,1.47
                c-17.12-1.52-34.14-4.29-51.19-6.54C0.5,788.33,0.5,783.67,0.5,779z"/>
            <path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M1399.5,385c-7.88,0.01-15.56-0.65-22.77-4.44c-2.25-1.18-5.1-1.39-7.72-1.67
                c-7.76-0.83-15.54-1.7-23.33-2.13c-5.08-0.28-10.42-0.68-15.26,0.54c-9.65,2.44-19.21,3.66-29.11,2.52
                c-9.22-1.06-18.51-1.58-27.68-2.93c-2.06-0.3-4.43-2.74-5.46-4.79c-2.08-4.13-3.26-8.7-4.95-13.03c-1.26-3.24-0.19-4.9,3.18-4.85
                c6.98,0.11,13.99,0.05,20.93,0.68c8.03,0.72,14.63-2.02,20.71-6.88c1.07-0.85,2.48-1.77,3.76-1.8c12.9-0.3,25.8-0.42,38.71-0.6
                c0.33,0,0.66-0.14,0.99-0.12c9.27,0.62,18.41,0.58,27.49-2.25c2.71-0.84,6.12,0.77,9.22,0.9c3.76,0.15,7.54-0.09,11.3-0.16
                C1399.5,357.67,1399.5,371.33,1399.5,385z"/>
            <path d="M1399.5,129c-7.47-0.25-14.95-0.96-22.39-0.62c-7.78,0.35-15.53,1.54-23.24,2.73c-2.09,0.33-3.97,2.01-5.9,3.05
                c-7.69-7.61-17.1-7.23-27.08-4.95c-10.81,2.48-21.63,5.27-32.6,6.49c-6.04,0.67-12.57-1.02-18.58-2.7
                c-16.01-4.47-32.54-7.29-48.16-1.64c-11.73,4.24-23.54,6.05-35.44,8.69c-6.99,1.55-11.73,1.3-16.75-3.7
                c-3.38-3.37-7.42-6.28-11.62-8.55c-3.95-2.13-8.52-3.09-12.78-4.68c-2.53-0.95-5.28-1.72-7.39-3.29
                c-3.99-2.99-8.06-4.94-13.2-4.32c-1.71,0.21-4.12,0.34-5.18-0.62c-6.53-5.9-14.87-7.1-22.78-9.52c-4.6-1.41-9.01-3.44-13.59-4.89
                c-6.28-1.98-12.67-3.61-18.98-5.51c-2.36-0.71-4.79-1.45-6.93-2.64c-11.91-6.62-23.83-13.25-33.5-23.15
                c-1.11-1.14-2.69-1.95-4.21-2.52c-2.26-0.85-4.31-1.1-5.92,1.71c-0.98,1.71-3.16,2.73-5.29,4.45c1.61,1,3.05,1.73,4.29,2.7
                c6.23,4.89,12.4,9.86,18.62,14.78c5.59,4.42,11.21,8.81,16.81,13.22c1.83,1.44,3.65,2.89,5.47,4.34
                c-0.21,0.58-0.42,1.15-0.63,1.73c-5.65,0-12.08,1.76-16.79-0.34c-13.01-5.79-27.1-4.61-40.4-8.06
                c-7.02-1.82-14.42-2.12-21.64-3.22c-2.78-0.42-5.63-0.82-8.26-1.76c-13.15-4.68-26.81-7-40.62-6.95
                c-17.78,0.06-35.54,1.69-53.33,2.01c-15.44,0.28-30.91,0.05-46.34-0.65c-16.28-0.74-32.5-2.6-48.78-3.36
                c-7.09-0.33-14.25,0.98-21.39,1.34c-4.76,0.24-9.43,0.59-13.85-2.61c-1.94-1.4-5.47-1.03-8.22-0.81
                c-10.19,0.82-19.67-1.7-29.37-4.49c-6.46-1.86-13.9-2.38-20.5-1.21c-15.84,2.8-31.67,3.48-47.65,2.82
                c-4.32-0.18-8.68,0.16-12.97-0.27c-7.91-0.78-15.77-1.96-23.66-2.97c-15.63-2-31.26-3.98-46.89-6.01
                c-1.62-0.21-3.69-0.11-4.75-1.05c-7.57-6.81-16.98-5.89-26.01-6.58c-2.31-0.18-4.65-0.05-6.94-0.34
                c-15.32-1.89-30.61-3.94-45.94-5.74c-3.95-0.47-8.01,0.04-11.96-0.41c-7.39-0.85-14.86-1.57-22.06-3.29
                c-9.13-2.18-18.09-3.75-27.6-2.92c-8.42,0.73-16.95,0-25.43,0.14c-6.28,0.1-12.56,0.61-18.84,0.81c-2.46,0.08-5.07,0.27-7.36-0.43
                c-5.72-1.75-11.05-0.67-16.66,0.68c-5.71,1.38-11.68,1.98-17.57,2.32c-6.46,0.38-12.97-0.11-19.45,0.14
                c-4.85,0.19-8.91-1.2-13.2-3.5c-4.85-2.6-10.34-4.55-15.77-5.39c-9.35-1.45-18.85-2.02-28.32-2.56
                c-6.45-0.37-13.04-0.79-19.38,0.13c-8.92,1.29-17.4,0.35-26.18-1.24c-21.93-3.97-43.91-7.08-66.43-4.7
                c-9.47,1-19.22-0.97-28.85-1.08c-6.62-0.07-13.25,1.05-19.88,1.06c-3.92,0.01-7.9-0.86-11.75-1.77
                c-8.37-1.97-16.67-4.27-25.02-6.32C31.14,27.23,14.66,27.57,0.5,19c0-0.33,0-0.67,0-1c11.42,4.68,22.96,8.55,35.54,9.19
                c8.9,0.45,17.73,2.35,26.59,3.62c0.65,0.09,1.39,0.17,1.92,0.51c10.1,6.67,21.05,5.78,32.32,4.27c5.86-0.79,12.03-0.6,17.89,0.3
                c12.87,1.98,25.52-0.16,38.29-0.57c8.69-0.29,17.49,1.78,26.2,3.03c9.05,1.3,18.03,3.1,27.09,4.29c4.39,0.57,8.99,0.81,13.35,0.19
                c14.56-2.06,28.85,0.75,43.26,1.65c10.22,0.64,20.09,1.91,29.27,7.1c2.85,1.61,6.71,1.67,10.15,1.93
                c4.96,0.37,9.98,0.56,14.94,0.29c6.55-0.36,13.07-1.32,19.62-1.86c6.38-0.53,12.77-0.88,19.15-1.28c0.66-0.04,1.33,0.14,1.99,0.14
                c17.26-0.13,34.55-0.83,51.79-0.17c9.5,0.36,18.93,2.88,28.36,4.64c6.64,1.24,12.97,2.26,20.04,1.46
                c8.22-0.92,16.81,1.47,25.24,2.43c0.49,0.06,0.97,0.25,1.47,0.3c15.5,1.57,31,3.08,46.5,4.72c2.45,0.26,5.09,0.64,7.22,1.77
                c8.22,4.37,17.16,4.44,26.08,5.52c13.49,1.65,26.82,4.54,40.22,6.9c2.29,0.4,4.59,1.06,6.89,1.07
                c16.11,0.03,32.23,0.25,48.32-0.27c8.26-0.27,16.44-2.52,24.7-2.81c6.86-0.23,14.02,0.19,20.6,1.97c8.93,2.41,17.72,4.01,27,3.95
                c5.48-0.04,10.95,1.98,16.48,2.55c4.08,0.43,8.24-0.11,12.36,0c16.43,0.44,32.95,0.1,49.28,1.66c24.08,2.31,48.03,2.99,72.16,1
                c15.38-1.27,30.92-1.88,46.32-1.24c10.84,0.45,21.58,3.48,32.35,5.42c5.54,1,11.04,2.22,16.58,3.23c6.05,1.1,12.11,2.18,18.18,3.1
                c6.39,0.97,12.87,1.43,19.19,2.69c5.8,1.16,11.4,3.26,17.16,4.65c3.88,0.93,7.88,1.34,11.82,1.98c0.18-0.56,0.36-1.11,0.54-1.67
                c-1.22-0.98-2.44-1.96-3.66-2.93c-3.77-3-8.15-5.48-11.19-9.09c-6.69-7.97-15.29-13.17-23.89-18.53c-1.3-0.81-2.19-2.26-3.27-3.42
                c1.24-0.99,2.49-1.95,3.71-2.96c0.98-0.81,2.58-1.56,2.75-2.54c0.82-4.69,3.9-4.64,7.09-3.37c3.33,1.33,7.11,2.66,9.46,5.16
                c9.71,10.26,22.27,16.09,34.18,22.93c0.57,0.33,1.22,0.62,1.87,0.69c16.46,1.77,30.41,11.54,46.47,14.56
                c1.45,0.27,2.88,0.9,4.18,1.63c4.82,2.69,9.5,5.03,15.49,4.2c2.63-0.36,6.23,1.01,8.26,2.85c3.77,3.42,7.91,4.74,12.72,5.82
                c4.05,0.9,7.75,3.62,11.45,5.79c2.28,1.34,4.43,2.99,6.38,4.79c5.57,5.15,11.42,7.81,19.3,4.95c3.93-1.43,8.57-0.79,12.83-1.46
                c3.58-0.56,7.52-0.89,10.51-2.67c8.05-4.79,16.7-6.89,25.79-6.3c10.07,0.65,20.27,1.44,30.06,3.7c10.92,2.53,21.3,3.53,32.35,0.46
                c9.86-2.74,20.13-4.47,30.32-5.31c5.21-0.43,10.64,2.18,16.01,3.25c2.39,0.48,4.96,1.13,7.26,0.7
                c15.99-2.97,32.01-4.08,48.16-1.55C1399.5,127.67,1399.5,128.33,1399.5,129z"/>
            <path d="M0.5,47c6.5,1.96,12.82,2.63,19.89,2.33c26.07-1.1,52.15-4.87,78.34,1c10.31,2.31,21.48,0.76,32.26,1.01
                c2.82,0.07,5.65,0.26,8.47,0.43c11.27,0.66,22.54,1.33,33.81,2.01c1.83,0.11,3.67,0.16,5.46,0.49c17.6,3.26,35.12,7.11,52.82,9.65
                c10.13,1.45,20.55,0.77,30.84,1.11c24.92,0.82,49.92,0.78,74.73,2.91c11.7,1.01,23.35,5.43,34.46,9.75
                c9.25,3.59,18.5,4.89,28.19,5.44c9.07,0.51,18.08,2.48,27.15,2.73c16.7,0.46,31.3,8.34,47.02,12.15
                c6.36,1.54,13.26,0.63,19.86,1.35c11.89,1.31,23.71,3.43,35.62,4.43c15.84,1.33,31.73,2.51,47.61,2.56
                c8.18,0.02,16.32-3.06,24.56-3.8c6.87-0.62,14.01-0.71,20.77,0.49c8.02,1.42,15.74,1.51,23.73,0.29c3.6-0.55,7.83-0.14,11.14,1.29
                c8.36,3.61,16.91,5.69,25.93,6.69c14.36,1.59,28.64,5.02,43,5.32c24.75,0.52,48.36,6.2,72.18,11.86
                c12.37,2.94,25.22,4.38,37.94,5.21c31.77,2.08,63.04,6.72,93.74,15.31c21.95,6.14,44.03,11.44,66.89,13.58
                c21.67,2.03,43.15,5.95,64.77,8.62c8.56,1.06,17.31,0.59,25.88,1.56c4.48,0.51,8.79,2.59,13.16,4.04c1.09,0.36,2.04,1.18,3.14,1.5
                c15.14,4.44,30.19,9.3,45.5,13.08c12.4,3.06,25.11,4.91,37.73,7.06c2.54,0.43,5.26-0.29,7.9-0.37c5.27-0.16,10.77-1.25,15.75-0.12
                c7.87,1.79,15.31,1.49,23.14,0.11c6.98-1.22,14.21-1.22,21.34-1.3c12.45-0.14,24.92-0.36,37.35,0.22
                c15.43,0.72,30.83,2.29,46.25,3.33c2.93,0.2,5.9-0.44,8.86-0.52c4.28-0.11,8.71-0.8,12.8,0.06c12.91,2.71,25.93,3.09,39.01,3.12
                c0,1,0,2,0,3c-6.44-0.31-12.89-0.4-19.3-1c-8.09-0.76-16.14-1.93-24.2-2.94c-0.99-0.12-2.12-0.79-2.91-0.49
                c-11.77,4.44-23.51,0.54-35-0.84c-12.05-1.45-23.87-0.62-35.82-0.34c-7.95,0.19-15.92-0.55-23.87-0.33
                c-9.42,0.26-18.81,1.25-28.22,1.65c-5.37,0.23-10.77-0.05-16.15-0.16c-5.75-0.13-11.6-1.07-17.23-0.31
                c-5.95,0.8-11.38,0.42-17.16-1.18c-7.66-2.12-15.57-3.56-23.47-4.56c-9.63-1.22-18.93-2.95-27.9-7.03
                c-5.68-2.58-12.09-3.55-18.18-5.25c-3.2-0.89-6.95-1.02-9.48-2.84c-8.26-5.95-17.76-4.65-26.9-5.68
                c-20.97-2.35-41.96-4.59-62.9-7.24c-15.5-1.96-31.09-3.77-46.35-6.99c-20-4.21-39.58-10.52-59.62-14.47
                c-17.39-3.42-35.18-4.88-52.81-7.02c-2.58-0.31-5.28,0.46-7.93,0.69c-1.15,0.1-2.77,0.64-3.4,0.09
                c-6.99-6.12-15.69-2.79-23.46-4.41c-6-1.25-12.26-1.44-18.12-3.1c-25.54-7.24-51.27-12.77-78.03-12.57
                c-6.41,0.05-12.84-1.61-19.26-2.54c-8.39-1.21-16.8-2.26-25.13-3.8c-5.84-1.07-11.81-2.2-17.24-4.46
                c-6.45-2.69-12.79-2.97-19.53-2.47c-5.25,0.39-10.84,1.05-15.8-0.25c-11.18-2.92-22.24-2.51-33.07,0.24
                c-15.43,3.92-30.78,1.85-46.13,0.66c-13.4-1.04-26.73-2.97-40.1-4.41c-4.79-0.51-9.64-1.19-14.42-0.97
                c-14.59,0.65-27.53-5.45-41.14-9.08c-10.83-2.89-22.19-3.9-33.36-5.34c-10.04-1.29-20.19-1.79-30.22-3.11
                c-4.23-0.56-8.5-1.87-12.39-3.64c-22.31-10.14-45.54-13.55-69.96-11.75c-11.18,0.82-22.55-1.07-33.85-1.48
                c-7.31-0.27-14.64-0.17-21.96-0.11c-9.34,0.08-18.4-0.52-27.54-3.46c-7.62-2.45-16.03-2.44-24.07-3.62
                c-4.59-0.67-9.13-1.62-13.72-2.25c-5.05-0.69-10.12-1.29-15.2-1.68c-10.92-0.83-21.85-1.76-32.79-2.17
                c-10.61-0.39-21.27,0.23-31.86-0.38c-7.6-0.43-15.13-2.17-22.69-3.28c-14.81-2.17-29.68-1.96-44.04,1.95
                c-10.68,2.91-20.3,0.17-30.26-2.21C3.74,49.68,2.11,49.35,0.5,49C0.5,48.33,0.5,47.67,0.5,47z"/>
            <path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M1399.5,852c-1.27,0.31-2.74,0.31-3.77,0.99c-3.23,2.13-6.38,2.13-9.92,0.8
                c-4.76-1.79-9.27-0.78-14.04,0.97c-3.27,1.2-7.71,1.52-10.85,0.27c-7.58-3.02-13.54-1.56-19,4.03c-3.58,3.67-3.43,4.91,1,7.32
                c1.22,0.66,2.32,1.57,4.13,2.83c-2.19,1.44-3.63,2.39-6.07,4c-3.62-11.42-7.04-22.22-10.67-33.64c3.15-0.63,5.65-1.13,8.15-1.63
                c0.03-0.32,0.06-0.64,0.09-0.96c-1.12-0.25-2.37-0.95-3.35-0.68c-5.67,1.58-6.72-1.93-7.54-6.26c-1.37-7.19-3.02-14.33-4.56-21.49
                c-1.28-5.97-0.76-6.89,5.48-6.29c11.75,1.13,23.44,3.05,35.2,4.02c11.87,0.97,23.8,1.18,35.71,1.73c0,12,0,24,0,36
                c-12.27,0-24.54,0-36.81,0c-0.02,0.53-0.05,1.06-0.07,1.58c12.29,0.47,24.58,0.94,36.88,1.42
                C1399.5,848.67,1399.5,850.33,1399.5,852z"/>
            <path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M1399.5,623c-6.4-1.38-12.79-2.86-19.21-4.11c-4.87-0.94-9.79-2.1-14.72-2.26
                c-21.09-0.7-42.2-1.14-63.3-1.49c-3.12-0.05-6.24,0.99-9.37,1.34c-2.58,0.28-5.19,0.27-7.76,0.38
                c-0.48-9.04,7.98-20.23,15.97-21.48c11.48-1.79,22.96-3.84,34.53-4.81c6.67-0.56,13.5,0.73,20.24,1.3
                c3.79,0.32,7.56,1.14,11.36,1.23c3.14,0.08,6.7,0.03,6.34-5.01c8.39,5.44,16.98,6.52,25.93,5.93
                C1399.5,603.67,1399.5,613.33,1399.5,623z"/>
            <path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M1399.5,905c-3.67,0.88-7.36,1.66-10.99,2.68c-2.22,0.63-4.29,1.84-6.51,2.42
                c-4.85,1.28-6.41,0.02-6.61-4.96c-0.28-6.82-8.74-11.39-15.34-8.03c3.63,3.27,13.24,0.51,10.62,9.39
                c-6.25-0.84-12.32-1.54-18.33-2.57c-1.18-0.2-2.74-1.54-3.08-2.67c-2.54-8.39-4.81-16.87-7.18-25.35
                c4.92-1.57,9.34-4.54,13.53,0.41c0.35,0.41,1.88,0.47,2.31,0.08c2.82-2.54,5.89-2.84,9.49-2.01c2.88,0.67,5.92,0.78,8.9,0.86
                c1.29,0.03,2.6-0.65,3.9-1c-0.79-0.87-1.42-2.03-2.39-2.57c-5.89-3.23-11.81-3.69-18.49-1.73c-2.91,0.86-6.94-1.43-10.23-2.83
                c-1.5-0.64-2.43-2.61-3.63-4c3.21-5.82,5.91-7.12,11.94-4.85c6.18,2.33,11.95,3.16,18.2-0.25c2.29-1.25,6.47-1.43,8.58-0.12
                c4.64,2.87,8.03,1.64,11.74-1.16c1.04-0.78,2.38-1.17,3.58-1.73c0,9.33,0,18.67,0,28c-2.44-0.74-4.97-1.25-7.29-2.26
                c-2.75-1.2-5.1-1.72-6.86,0.66c4.96,3.37,9.55,6.48,14.14,9.6C1399.5,895.67,1399.5,900.33,1399.5,905z"/>
            <path d="M450.22,548.96c-3.29,5.14-8.25,7.65-14.03,8.81c-1.45,0.29-3.58,0.01-4.25,0.88c-3.97,5.16-9.49,4.79-15.13,5.66
                c-6.91,1.07-13.51,4.1-20.25,6.28c-14.31,4.62-28.92,4.95-43.79,3.8c-8.19-0.63-16.52-0.26-24.72,0.46
                c-5.69,0.5-11.26,2.37-16.9,3.6c-1.12,0.24-2.33,0.58-3.42,0.42c-6.08-0.9-12.44-1.1-18.13-3.14c-9.59-3.44-19.06-1.59-28.54-0.84
                c-10.91,0.86-21.67,0.78-32.6-0.25c-10.54-1-21.33-3.81-31.82,1.26c-2.57,1.24-5.83,1.06-8.77,1.55c-4.9,0.82-9.77,1.87-14.7,2.41
                c-4.02,0.45-8.25,1.02-12.14,0.27c-7.93-1.53-15.64-1.99-23.71-0.63c-10.12,1.71-20.6,1.51-30.59,3.66
                c-16.01,3.46-31.74,8.22-47.62,12.34c-8.33,2.16-16.93,3.19-25.09,0.15c-9.25-3.44-18.61-2.84-28.06-2.64
                c-1.82,0.04-3.64,0.01-5.47,0c0-0.67,0-1.33,0-2c12.28-1.61,24.38-2.2,36.46,2.05c10.21,3.59,19.96-0.46,29.65-3.51
                c4.42-1.39,8.98-2.32,13.48-3.44c5.47-1.36,10.93-2.73,16.41-4.04c4.35-1.04,8.67-2.4,13.08-2.94c13.18-1.62,26.41-2.91,39.62-4.3
                c1.32-0.14,2.76-0.37,3.97,0.01c16.31,5.11,31.67-1.27,47.21-4.16c10.27-1.91,20.1-0.32,30.14,0.84
                c8.87,1.02,18.59,4.19,26.51,1.77c14.47-4.42,27.79-0.99,41.64,0.69c4.76,0.58,9.73-0.49,14.6-0.88
                c12.04-0.97,24.06-3.64,36.16-0.88c15.16,3.45,29.71,0.18,44.22-3.74c5.92-1.6,11.73-3.77,17.75-4.82
                c11.98-2.09,22.38-7.92,33.16-12.93C446.35,549.88,448.33,549.53,450.22,548.96z"/>
            <path d="M0.5,691c18.56-2.8,37.28-4.8,55.64-8.57c47.98-9.84,94.73-24.28,141.25-39.43c3.69-1.2,7.02-3.7,10.34-5.86
                c10.73-6.97,22.04-10.53,35.21-10.11c14.05,0.45,28.21-0.98,42.26-2.28c4.82-0.45,9.45-2.98,14.16-4.57
                c14.31-4.83,28.76-9.14,42.13-16.62c4.76-2.66,11.39-1.74,16.99-3.17c10.41-2.65,21.08-4.88,30.94-8.98
                c17.9-7.45,35.76-14.77,54.57-19.64c10.5-2.72,18.54-9.31,25.43-18.24c-7.44,2.01-12.03-6.33-19.31-4.51
                c1.91-0.75,3.95-2.37,5.69-2.1c6.08,0.95,12.05,2.55,18.51,4c-5.25,7.11-11.24,13.23-18.83,17.46
                c-6.96,3.88-13.35,8.85-22.24,8.69c-3.88-0.07-7.83,3.09-11.72,4.85c-1.05,0.48-1.91,1.41-2.97,1.82
                c-7.15,2.78-14.33,5.48-21.52,8.17c-1.08,0.41-2.39,0.42-3.31,1.03c-11.93,7.92-25.71,9.78-39.36,11.22
                c-9.49,1.01-17.16,5.94-25.68,9.06c-12.93,4.73-25.65,10.9-39.03,13.31c-16.23,2.93-33.04,3.14-49.63,3.47
                c-9.34,0.19-18.02,1.69-25.54,6.97c-5.99,4.21-12.54,6.46-19.36,8.63c-46.01,14.62-92.13,28.89-139.47,38.61
                C37.45,687.99,18.9,690.13,0.5,693C0.5,692.33,0.5,691.67,0.5,691z"/>
            <path d="M0.5,911c0.64,1.28,1.51,2.49,1.88,3.84c3.53,12.76,6.97,25.54,10.47,38.3c0.06,0.23,0.5,0.35,2.19,1.43
                c0.13-9.49,0.52-18.11,0.29-26.72c-0.18-6.64-1.28-13.25-1.66-19.89c-0.08-1.45,1.18-2.97,1.82-4.45c0.48,1.37,1.3,2.72,1.39,4.11
                c0.77,11.76,1.68,23.52,1.98,35.3c0.16,6.29-0.98,12.61,2.44,18.48c0.44,0.76,0.18,2.66-0.46,3.2c-3.4,2.9-1.58,5.58-0.04,8.38
                c0.96,1.75,2.2,3.46,2.63,5.34c0.4,1.74-0.11,3.69-0.21,5.54c-0.85,0.19-1.71,0.38-2.56,0.57c-3-5.92-6.29-11.71-8.92-17.78
                C8.99,960.35,6.43,954.08,0.5,950c0-3.33,0-6.67,0-10c1.58,2.67,3.15,5.33,4.73,8c0.51-0.22,1.03-0.44,1.54-0.67
                C4.68,941.22,2.59,935.11,0.5,929C0.5,923,0.5,917,0.5,911z"/>
            <path d="M1399.5,659c-4.43-1.73-8.77-3.76-13.32-5.09c-2.79-0.82-5.96-1.09-8.85-0.72c-14.46,1.84-28.87,4.01-43.31,5.96
                c-2.15,0.29-4.38,0.04-6.57,0.04c-0.11-0.52-0.21-1.05-0.32-1.57c1.01-0.54,1.97-1.44,3.02-1.58c16.44-2.08,32.85-4.53,49.36-5.81
                c5.29-0.41,10.83,2.3,16.25,3.62c1.27,0.31,2.5,0.76,3.74,1.14C1399.5,656.33,1399.5,657.67,1399.5,659z"/>
            <path d="M0.5,754c9.35,1.35,18.67,3.01,28.06,3.99c14.33,1.5,28.71,2.52,43.07,3.78c0.43,0.04,0.84,0.42,2.32,1.21
                c-1.93,0.79-3.08,1.61-4.27,1.68c-3.64,0.19-7.32,0.41-10.94,0.05c-13.84-1.37-27.67-2.91-41.49-4.47
                c-5.6-0.63-11.17-1.48-16.75-2.23C0.5,756.67,0.5,755.33,0.5,754z"/>
            <path d="M0.5,793c17.06,2.25,34.07,5.02,51.19,6.54c8.15,0.72,16.51-0.95,24.78-1.47c1.18-0.07,2.38,0.24,3.58,1.46
                c-3.68,0.35-7.36,0.73-11.04,1.05c-13.37,1.16-26.7,3.25-40.15,0.55c-9.42-1.9-18.91-3.43-28.36-5.12C0.5,795,0.5,794,0.5,793z"/>
            <path d="M0.5,739c18.68,0.54,37.37,1.04,56.05,1.7c1.86,0.07,3.7,1.09,5.54,1.67c-0.09,0.57-0.17,1.14-0.26,1.72
                c-1.13,0.09-2.27,0.3-3.39,0.24c-5.61-0.33-11.23-0.71-16.84-1.12c-6.1-0.45-12.19-1.24-18.29-1.39c-7.6-0.2-15.21,0.1-22.82,0.19
                C0.5,741,0.5,740,0.5,739z"/>
            <path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M0.5,864c8.01,0.51,16.01,1.02,24.31,1.54c-2.58,7.26-10.33,10.97-15.87,7c-3.92-2.81-5.73-1.27-8.44,0.46
                C0.5,870,0.5,867,0.5,864z"/>
            <path d="M1399.5,735c-13.41-2.66-26.89-3.76-40.5-1.79c-2.93,0.42-5.83,1-9.61,1.66c5.22-6.55,31.87-7.59,50.12-2.86
                C1399.5,733,1399.5,734,1399.5,735z"/>
            <path d="M0.5,836c16.23,0,32.46,0,48.69,0c0.07,0.49,0.14,0.98,0.22,1.47c-3.36,0.45-6.72,1.24-10.09,1.29
                c-12.94,0.21-25.88,0.18-38.82,0.24C0.5,838,0.5,837,0.5,836z"/>
            <path d="M1399.5,761c-4.97,0.4-9.99,0.48-14.87,1.36c-2.36,0.43-4.33,2.77-6.7,3.53c-3.38,1.07-6.96,1.48-11.03,2.29
                c2.61-6.88,19.49-11.62,32.6-10.17C1399.5,759,1399.5,760,1399.5,761z"/>
            <path d="M0.5,818c17.81,0.75,35.63,1.5,53.44,2.25c-17.76,3.26-35.62,0.99-53.44,0.75C0.5,820,0.5,819,0.5,818z"/>
            <path d="M0.5,845c13.74-0.14,27.47-0.28,41.21-0.43C28.5,850.56,14.29,847.19,0.5,848C0.5,847,0.5,846,0.5,845z"/>
            <path d="M1399.5,847c-12.29-0.47-24.58-0.94-36.88-1.42c0.02-0.53,0.05-1.06,0.07-1.58c12.27,0,24.54,0,36.81,0
                C1399.5,845,1399.5,846,1399.5,847z"/>
            <path d="M0.5,855c7.99,0.77,15.98,1.55,23.97,2.32c-0.06,0.8-0.12,1.61-0.18,2.41c-7.93-0.58-15.86-1.16-23.78-1.73
                C0.5,857,0.5,856,0.5,855z"/>
            <path d="M1399.5,891c-4.59-3.12-9.19-6.24-14.14-9.6c1.75-2.38,4.11-1.86,6.86-0.66c2.32,1.01,4.85,1.53,7.29,2.26
                C1399.5,885.67,1399.5,888.33,1399.5,891z"/>
            <path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M0.5,875c4.9,0.45,9.79,0.9,15.13,1.39c-1.87,3.63-3.67,6.19-8.33,4.38c-2.06-0.8-4.52-0.54-6.81-0.77
                C0.5,878.33,0.5,876.67,0.5,875z"/>
            <path d="M1393.5,1000c1.87-4.72,0.82-10.69,6-14c0,4.67,0,9.33,0,14C1397.5,1000,1395.5,1000,1393.5,1000z"/>
            <path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M0.5,929c2.09,6.11,4.18,12.22,6.27,18.33c-0.51,0.22-1.03,0.44-1.54,0.67c-1.58-2.67-3.15-5.33-4.73-8
                C0.5,936.33,0.5,932.67,0.5,929z"/>
            <path d="M1399.5,685c-3.03-0.51-6.05-1.01-9.08-1.52c0.06-0.66,0.12-1.31,0.18-1.97c2.97-0.17,5.93-0.34,8.9-0.51
                C1399.5,682.33,1399.5,683.67,1399.5,685z"/>
            <path d="M930.75,271.34c-1.11,1.89-1.67,2.85-2.52,4.3c5.24,2.17,10.23,4.23,15.52,6.42c-1.32,3.54-3.13,6.12-7.58,7.13
                c-14.23,3.24-28.44,3.83-43.04,3.24c-11.66-0.48-23.45,1.9-35.15,3.19c-8.41,0.93-16.79,2.2-25.19,3.22
                c-1.25,0.15-2.98,0.25-3.77-0.44c-7.25-6.36-15.62-5.47-23.55-2.83c-19.96,6.64-40.33,5.6-60.69,3.93
                c-18.54-1.52-36.96-4.6-55.51-5.81c-13.22-0.86-26.57,0.46-39.86,0.37c-13.58-0.09-26.9,0.68-39.74,5.81
                c-2.15,0.86-5.23-0.67-7.89-1.05c-1.86-0.27-3.91-1.09-5.56-0.6c-23.65,6.94-47.8,6.88-71.72,3.34
                c-16.19-2.4-32.07-7.36-47.87-11.91c-23.95-6.9-47.76-14.31-71.54-21.76c-9.1-2.85-17.98-6.41-26.97-9.64
                c0.03-0.64,0.05-1.27,0.08-1.91c1.69-0.64,3.33-1.58,5.08-1.86c19.93-3.17,40.01-4.51,60.15-3.24
                c16.58,1.05,33.13,2.73,49.68,4.33c13.07,1.26,26.11,2.84,39.17,4.23c1.23,0.13,2.51-0.1,4.36-1.3c-1.81-1.44-3.42-3.34-5.47-4.25
                c-10.34-4.58-17.02-12.61-21.36-22.67c-0.67-1.54,0.29-5.23,1.54-5.86c3.08-1.57,6.83-2.85,10.2-2.62
                c9.57,0.66,19.12,1.89,28.61,3.35c7.38,1.14,14.61,3.27,21.99,4.39c10.01,1.53,20.26,1.81,30.14,3.88
                c24.54,5.15,49.67,7.07,73.83,14.33c13.5,4.06,27.26,7.53,41.13,10.06c22.34,4.08,44.98,4.2,67.63,3.14
                c4.25-0.2,9.41-2.43,11.65,4.05c0.28,0.81,2.64,1.06,4.09,1.28c18.21,2.79,36.43,5.54,54.66,8.25c3.78,0.56,7.6,1.16,11.41,1.18
                c1.34,0.01,3.58-1.41,3.88-2.58c1.92-7.46,6.3-10.08,14.15-8.23c5.81,1.37,11.64,2.92,17.55,3.53c6.92,0.71,13.93,0.43,20.9,0.69
                C925.4,270.51,927.66,270.95,930.75,271.34z M875.4,280.88c-0.78,0.24-1.27,0.58-1.69,0.5c-10.11-1.86-20.21-3.79-30.31-5.67
                c-2.45-0.46-4.9-0.91-7.37-1.19c-12.86-1.47-25.73-2.82-38.57-4.44c-1.39-0.18-2.57-1.9-3.87-2.87c-1.33-0.99-2.72-2.81-4.02-2.75
                c-4.3,0.2-8.58,1.74-12.83,1.59c-16.79-0.58-33.66-0.75-50.3-2.77c-11.93-1.45-23.5-5.71-35.28-8.53
                c-30.05-7.18-60.59-11.94-90.94-17.57c-10.44-1.94-21.13-2.44-31.66-3.96c-8.17-1.18-16.24-3.09-24.41-4.32
                c-6.03-0.91-12.2-0.91-18.2-1.94c-4.29-0.74-8.92-2.84-11.94,1.45c-2.75,3.92,0.07,7.92,2.06,11.57c0.32,0.58,0.66,1.24,1.17,1.61
                c6.04,4.29,12.05,8.63,18.2,12.74c3.32,2.22,6.9,4.05,11.42,6.66c-2.31,0.71-3.34,1.34-4.33,1.28
                c-5.59-0.29-11.18-0.69-16.75-1.17c-13.75-1.17-27.53-2.06-41.22-3.69c-20.49-2.43-40.93-5.36-61.65-4.25
                c-4.88,0.26-9.74,0.78-14.61,1.18c-0.11,0.35-0.22,0.71-0.34,1.06c2.02,1.43,4.03,2.86,6.05,4.29c-0.35,0.36-0.7,0.71-1.05,1.07
                c-9.24-2.72-18.56-4.83-28.35-3.98c5.53,3.01,11.13,5.55,16.98,7.27c10.52,3.1,21.62,4.64,31.71,8.75
                c22.42,9.14,45.65,15.51,68.84,22.03c22.86,6.43,46.4,6.68,69.88,5.7c8.19-0.34,16.71-1.65,24.33-4.51
                c7.23-2.71,14.38-3.85,21.82-4.08c16.63-0.5,33.32-0.07,49.9-1.2c30.68-2.09,60.5,6.4,90.95,6.46
                c13.42,0.03,27.03,1.23,40.06-3.19c8.61-2.92,17.14-3.61,26.02-1.59c2.34,0.53,4.94,0.02,7.41-0.07c1.14-0.04,2.29-0.41,3.4-0.31
                c4.29,0.4,8.6,1.53,12.84,1.3c9.46-0.52,18.86-1.91,28.32-2.56c17.05-1.17,34.14-1.91,51.19-3.15c3.89-0.28,7.68-1.85,11.51-2.82
                c-4.64-2.87-9.04-4.38-13.2-6.37c-1.62-0.78-2.72-2.66-5.03-5.05c-1.77,0.09-5.25,0.43-8.71,0.4
                c-10.02-0.09-19.82-1.27-29.39-4.71c-2.34-0.85-6.03,0.53-8.5,1.94c-2.97,1.68-1.56,4.6-0.35,7.08
                C874.99,278.96,875.12,279.91,875.4,280.88z"/>
            <path d="M303.23,349.96c-2.12,0.41-4.93,1.89-6.25,1.04c-6.52-4.18-12.69-8.91-18.88-13.59c-2.45-1.85-1.61-3.95,0.61-5.2
                c7.28-4.11,14.85-6.59,23.23-3.25c7.1,2.83,14.19,5.68,21.38,8.27c1.79,0.65,3.92,0.32,5.9,0.46c6.43,0.44,12.88,0.66,19.27,1.41
                c4.77,0.57,9.55,1.5,14.14,2.89c3.89,1.18,7.55,3.19,11.25,4.94c4.45,2.11,8.63,5.14,13.3,6.36c8.98,2.35,18.22,3.73,27.36,5.45
                c1.09,0.2,2.24,0.03,4.19,0.03c-1.44-2.05-2.36-3.77-3.65-5.14c-3.19-3.35-2.99-4.92,1.33-6.76c5.93-2.53,11.92-1.77,18.12-0.75
                c4.75,0.78,9.73,0.14,14.61,0.11c1.63-0.01,3.48-0.62,4.84-0.04c16.83,7.26,34.67,10.41,52.7,11.47
                c20.71,1.22,41.51,0.61,62.26,1.31c7.76,0.26,15.47,1.98,23.2,3.04c0,0-0.08-0.08-0.08-0.08c-1.66,0.8-3.34,2.34-4.96,2.28
                c-9.76-0.37-19.49-1.34-29.25-1.66c-12.08-0.4-24.23,0.33-36.25-0.67c-14.86-1.24-29.68-3.35-44.39-5.86
                c-8.03-1.37-15.79-4.34-23.69-6.53c-0.75-0.21-1.71-0.25-2.39,0.04c-6.22,2.73-12.28,1.53-18.53-0.12
                c-4.61-1.22-9.47-1.52-14.88,2.61c3.2,2.54,5.83,4.63,8.82,7.01c-0.41,3.42-3.46,4.02-6.48,3.51
                c-13.24-2.24-26.44-4.74-39.65-7.15c-0.32-0.06-0.71-0.14-0.93-0.35c-8.42-8.16-19.33-10.28-30.21-12.27
                c-5.78-1.06-11.69-1.4-17.54-2.11c-4.53-0.55-9.17-0.74-13.56-1.86c-4.6-1.17-8.99-3.22-13.44-4.97
                c-3.19-1.26-6.28-2.79-9.51-3.91c-1.24-0.43-2.88-0.43-4.12-0.01c-1.86,0.63-3.62,1.68-5.25,2.8c-1.83,1.26-3.47,2.8-5.2,4.22
                c1.82,1.83,3.33,4.26,5.51,5.38c5.55,2.88,11.41,5.17,17.14,7.7L303.23,349.96z"/>
            <path d="M292.54,243.9c1.82,0.37,3.84,0.78,5.86,1.19c-30.76,0.01-59.94-6.7-87.64-19.71c0.13-0.44,0.25-0.87,0.38-1.31
                c2.22,0.95,4.44,1.92,6.67,2.85c2.28,0.95,4.58,1.86,7.02,2.86c-0.42-5.09-0.89-9.77-1.16-14.46
                c-0.7-12.19,5.43-21.18,13.82-29.04c5.09-4.76,11.01-7.13,18.16-6.6c4.62,0.34,9.4-0.66,13.92,0.06c4.46,0.71,9.1,2.14,12.94,4.46
                c10.87,6.56,18.06,15.96,19.39,29.04c0.18,1.76,0.21,3.71-0.35,5.35C298.7,226.99,295.62,235.32,292.54,243.9z M264.84,182.63
                c-6.16,1.07-13.06,0.72-18.32,3.49c-14.85,7.82-20.4,21.71-17.51,38.24c0.58,3.3,3.41,8.09,6.1,8.81
                c14.72,3.92,29.66,7.13,44.68,9.71c3,0.52,8.06-1.82,9.71-4.41c3.42-5.36,6.16-11.59,7.39-17.81c1.93-9.76-1.34-18.83-8.28-25.86
                C282.22,188.31,275.39,181.8,264.84,182.63z"/>
            <path d="M180.19,908.52c-3.46,18.63-13.01,35.81-11.66,56.16c3.87-8.26,7.42-15.87,11.4-24.36c2.18,5.06-12.62,40.05-17.06,42.85
                c-3.92-21.86-7.83-43.62-11.74-65.38c0.71-0.19,1.42-0.37,2.13-0.56c1.49,6.12,2.98,12.25,4.47,18.37
                c0.29-0.01,0.57-0.01,0.86-0.02c1.62-8.67,3.24-17.35,4.84-26.02c0.21-1.15,0.11-2.41,0.57-3.43c0.59-1.3,1.61-2.42,2.44-3.61
                c0.45,1.52,1.48,3.12,1.26,4.53c-1.38,8.86-3.42,17.63-4.48,26.52c-0.7,5.86-0.13,11.87-0.13,17.82c0.41,0.05,0.82,0.1,1.23,0.16
                c1.37-4.98,2.62-10.01,4.15-14.94c1.53-4.93,3.11-9.87,5.1-14.62C175.48,917.42,175.79,911.97,180.19,908.52z"/>
            <path d="M488.08,975.49c0.89-1.37,1.62-2.35,2.19-3.41c6.76-12.6,13.49-25.22,20.24-37.83c0.39-0.73,0.65-1.78,1.27-2.09
                c0.99-0.49,2.23-0.49,3.37-0.69c-0.06,1.03,0.22,2.27-0.25,3.05c-3.9,6.57-8.26,12.88-11.84,19.61
                c-3.62,6.81-6.5,14.01-9.93,21.55c13.13-10.97,25.66-21.45,38.19-31.93c0.5,0.59,1,1.18,1.5,1.76
                c-10.03,8.64-20.06,17.28-30.09,25.92c0.21,0.3,0.42,0.59,0.63,0.89c7.32-4.17,14.64-8.33,21.96-12.5
                c0.37,0.63,0.74,1.26,1.11,1.89c-1.09,0.75-2.18,1.5-3.26,2.27c-7.96,5.68-15.78,11.55-23.93,16.93c-2.73,1.8-6.31,2.27-9.46,3.46
                c-1.72,0.65-3.35,1.52-5,2.32c-1.07,0.51-2.11,1.06-3.17,1.59c-0.28-1.22-0.98-2.52-0.76-3.65c2.71-14.03,5.23-28.12,8.48-42.03
                c1.85-7.9,4.89-15.52,7.56-23.21c0.34-0.98,1.7-1.61,2.59-2.4c0.59,1.18,1.93,2.62,1.63,3.5c-1.47,4.4-3.86,8.53-5.03,13
                c-3.24,12.36-6.05,24.83-8.98,37.27c-0.22,0.95-0.13,1.99-0.04,2.97C487.09,974.14,487.49,974.52,488.08,975.49z"/>
            <path d="M866.2,962.63c-0.21-4.28-0.62-8.57-0.5-12.84c0.03-1.23,1.57-2.42,2.42-3.63c0.6,1.3,1.83,2.67,1.7,3.89
                c-0.97,9.03,1.72,17.32,4.27,25.88c0.68-0.46,1.12-0.59,1.17-0.81c2.75-13.3,5.44-26.61,8.26-39.89c0.18-0.85,1.32-1.49,2.01-2.23
                c0.72,0.81,2.1,1.68,2.03,2.41c-0.35,3.45-0.91,6.9-1.73,10.26c-1.61,6.6-3.56,13.12-5.04,19.75c-0.26,1.16,1.35,2.63,1.69,4.06
                c0.39,1.65,0.89,3.62,0.35,5.09c-1.19,3.21-2.8,6.31-4.62,9.22c-1.67,2.67-3.16,5.05-0.87,8.12c0.31,0.41-0.44,1.62-0.7,2.46
                c-0.84-0.37-2.06-0.51-2.45-1.16c-1.37-2.27-2.6-4.65-3.61-7.1c-3.79-9.23-7.4-18.54-11.25-27.75c-1.21-2.89-3.16-5.47-4.33-8.37
                c-0.54-1.34-0.04-3.1-0.01-4.67c1.25,0.82,3.06,1.35,3.66,2.51c1.98,3.84,3.5,7.91,5.23,11.87c0.46,1.05,1.03,2.05,1.56,3.08
                C865.68,962.72,865.94,962.68,866.2,962.63z"/>
            <path d="M238.27,969.87c4.3-8.96,8.6-17.92,12.89-26.88c0.72-1.5,1.42-3.02,3.01-4.17c-0.84,13.67-9.66,24.6-13.36,37.25
                c0.51,0.34,1.02,0.68,1.53,1.02c3.52-4.24,7.03-8.48,10.55-12.71c3.37-4.04,4.96-9.86,11.39-10.97
                c-4.1,5.95-7.97,12.06-12.36,17.79c-3.73,4.87-7.99,9.35-12.15,13.88c-0.78,0.85-2.2,1.1-3.33,1.63
                c-0.39-0.99-0.79-1.97-1.28-3.21c-0.89,0.24-2.21,0.6-3.72,1c-0.84-7.55-1.65-14.82-2.45-22.09c0.41-0.16,0.83-0.31,1.24-0.47
                c1.13,1.04,2.26,2.08,4.01,3.68c3.21-11.55,6.33-22.81,9.45-34.06c0.84,0.17,1.67,0.35,2.51,0.52c-0.35,2.94-0.47,5.93-1.11,8.8
                c-2.16,9.71-4.5,19.38-6.77,29.07c-1.21,1.32-2.42,2.63-3.63,3.95c0.55,0.37,1.1,0.73,1.65,1.1
                C236.99,973.28,237.63,971.58,238.27,969.87z"/>
            <path d="M1293.72,946.17c-1.63-0.15-2.7-0.24-4.79-0.43c0.65,6.68,1.31,13.17,1.91,19.67c0.53,5.79,1.23,11.58,1.36,17.38
                c0.04,1.71-1.63,3.46-2.51,5.19c-0.83-1.7-2.05-3.32-2.38-5.11c-0.38-2.08,0.3-4.34-0.01-6.45c-0.21-1.4-1.34-2.67-2.06-4
                c-0.64,0.23-1.28,0.46-1.92,0.69c0.85,4.98,1.79,9.95,2.51,14.95c0.27,1.84,0.04,3.75,0.04,5.63c-1.82-0.36-3.64-0.71-5.52-1.08
                c-1.41,1.14-3.09,2.49-4.76,3.83c-1.6-6.82-3.15-12.97-4.42-19.17c-0.28-1.35,0.45-2.9,0.72-4.36c1.44,0.63,3.04,1.04,4.26,1.96
                c0.94,0.7,1.4,2.03,2.64,3.97c0-3.82-0.14-6.56,0.05-9.28c0.12-1.69,0.75-3.34,1.16-5.02c1.67,0.85,3.37,1.64,4.99,2.57
                c0.51,0.3,0.8,0.99,2.06,2.61c-0.39-5.58-0.78-9.92-0.97-14.28c-0.28-6.31-0.52-12.63-0.51-18.94c0-1.16,1.23-2.33,1.9-3.49
                c1.24,0.88,3.14,1.52,3.59,2.7C1292.22,938.8,1292.74,942.12,1293.72,946.17z"/>
            <path d="M309.66,927.62c1.02,1.37,2.33,2.6,3,4.12c5.19,11.87,10.22,23.8,15.38,35.68c0.82,1.88,2.05,3.57,4.12,4.87
                c-1.47-4.69-2.96-9.37-4.4-14.08c-2.91-9.53-5.84-19.05-8.58-28.63c-0.31-1.08,0.49-2.47,0.78-3.72c0.94,0.91,2.38,1.66,2.74,2.76
                c3.3,10.26,6.4,20.59,9.58,30.88c2.1,6.77,4.38,13.49,6.28,20.32c0.42,1.5-0.47,3.36-0.76,5.06c-1.38-0.51-2.75-1.01-4.13-1.52
                c-0.92-0.34-1.88-0.62-2.77-1.01c-3.37-1.5-6.91-2.72-10.01-4.65c-3.02-1.87-5.58-4.49-7.96-7.41c6.89-2.06,9.35,5.2,14.59,7.31
                c-3.47-8.58-6.82-16.75-10.06-24.96c-3.17-8.01-6.23-16.06-9.34-24.09C308.61,928.24,309.14,927.93,309.66,927.62z"/>
            <path d="M779.97,974.03c0-6.97-0.16-13.94,0.07-20.9c0.11-3.28,0.77-6.59,1.65-9.76c0.23-0.81,2.24-1.13,3.43-1.67
                c0.38,1.26,1.24,2.6,1.04,3.76c-0.57,3.29-2.33,6.52-2.26,9.74c0.15,7.75,1.08,15.48,1.69,23.22c0.43,0.05,0.86,0.1,1.3,0.15
                c2.65-6.18,5.12-12.45,8.06-18.5c0.87-1.8,3.06-2.96,4.64-4.42c-0.3,2.26-0.18,4.68-0.98,6.74c-2.58,6.63-5.62,13.08-8.22,19.7
                c-1.05,2.69-1.84,4.28-4.89,2.07c-0.48-0.35-2.43,0.72-3.2,1.58c-0.62,0.7-0.48,2.08-0.99,4.84c-1.73-2.45-3.16-3.61-3.45-5.01
                c-3.12-15.28-7.99-30.31-6.96-46.22c0.07-1.16-0.14-2.43,0.27-3.46c0.43-1.09,1.45-1.95,2.21-2.92c0.81,0.92,2.3,1.83,2.32,2.77
                c0.09,4-0.34,8-0.41,12.01c-0.06,3.31-0.31,6.69,0.28,9.9c1.02,5.53,2.63,10.95,4,16.42
                C779.69,974.08,779.83,974.06,779.97,974.03z"/>
            <path d="M679.17,969.02c5.47-6.7,10.29-12.78,15.3-18.7c3.75-4.44,7.6-8.83,11.72-12.93c1.06-1.06,3.31-0.92,5.01-1.33
                c-0.63,1.34-1.03,2.86-1.93,3.99c-8.32,10.36-16.61,20.75-25.18,30.9c-2.14,2.54-5.3,4.2-7.88,6.39
                c-1.21,1.03-2.03,2.57-3.31,3.46c-0.95,0.66-2.63,1.2-3.48,0.78c-0.76-0.38-1.4-2.35-1.08-3.27c0.91-2.65,2.3-5.14,3.54-7.68
                c7.88-16.14,15.7-32.3,23.78-48.34c0.67-1.34,3.06-1.82,4.65-2.7c-0.25,2.03,0.03,4.32-0.83,6.04
                c-6.86,13.8-13.94,27.5-20.92,41.24C678.46,967.07,678.72,967.47,679.17,969.02z"/>
            <path d="M380.19,972.16c2.28-7.57,4.55-15.13,6.83-22.7c0.67,0.18,1.34,0.37,2.01,0.55c-2.19,11.14-4.39,22.29-6.58,33.43
                c0.57,0.38,1.14,0.77,1.72,1.15c1.08-2.17,2.15-4.35,3.23-6.52c2.21,5.76-0.78,10.06-5.18,10.02c-3.3-0.04-8.01,0.34-9.6-1.56
                c-3.29-3.93-4.98-9.23-7.14-14.04c-0.2-0.45,0.8-1.95,1.48-2.15c0.63-0.19,1.95,0.52,2.31,1.18c1.17,2.13,2.05,4.43,3.03,6.66
                c0.53-0.04,1.07-0.08,1.6-0.12c0.57-13.25,1.15-26.51,1.72-39.76c0.67-0.09,1.35-0.18,2.02-0.27c0.53,1.96,1.49,3.91,1.52,5.88
                c0.12,8.29-0.03,16.59-0.09,24.88c-0.01,1.12,0,2.25,0,3.37C379.44,972.15,379.81,972.15,380.19,972.16z"/>
            <path d="M1366.67,964.98c-3.82-5.29,0.61-9.47,1.53-14.33c-5.67,1.95-8.3-1.48-9.4-5.62c-2.03-7.65-3.35-15.49-4.79-23.29
                c-0.15-0.8,0.89-1.81,1.38-2.72c0.89,0.58,2.37,1.02,2.56,1.77c0.77,3.07,1.79,6.3,1.53,9.37c-0.59,7.11,3.21,15.28,9.54,17.58
                c0.36-2.66,0.47-5.22,1.11-7.63c0.44-1.64,1.53-3.15,2.56-4.55c0.2-0.27,2.13,0.25,2.13,0.39c-0.02,1.67-0.23,3.33-0.45,4.99
                c-0.15,1.08-0.41,2.14,0.34,4.06c2.16-1.78,4.34-3.54,6.47-5.34c2.41-2.03,4.7-4.22,7.22-6.11c0.83-0.62,2.2-0.51,3.33-0.74
                c-0.29,1.29-0.16,3-0.96,3.78c-3.66,3.6-7.73,6.78-11.34,10.43c-2.59,2.63-4.8,5.68-6.95,8.7
                C1370.49,958.52,1368.82,961.53,1366.67,964.98z"/>
            <path d="M965.13,964.38c-0.54-5.75-1.1-11.51-1.59-17.27c-0.11-1.32-0.27-2.76,0.13-3.95c0.36-1.05,1.47-1.85,2.25-2.76
                c0.43,1.08,1.07,2.13,1.27,3.26c1.65,9.42,3.21,18.85,4.87,28.27c0.09,0.53,0.74,0.95,0.84,1.07c1.83-1.13,3.39-2.11,4.95-3.08
                c-0.17,2.18,0.11,4.51-0.62,6.48c-0.93,2.52-4.57,3.99-2.84,7.65c0.13,0.28-1.17,1.7-1.88,1.77c-0.71,0.06-1.83-0.74-2.22-1.46
                c-5.28-9.59-10.53-19.21-15.56-28.93c-0.49-0.94,0.52-2.67,0.84-4.03c1.12,0.73,2.73,1.21,3.28,2.25
                c1.87,3.59,3.38,7.38,5.02,11.1C964.28,964.62,964.7,964.5,965.13,964.38z"/>
            <path d="M683.05,924.39c-0.45,1.73-0.66,2.71-0.95,3.66c-4.17,13.63-8.31,27.27-12.54,40.88c-1.28,4.11-2.62,8.23-4.31,12.18
                c-0.57,1.34-2.16,2.46-3.55,3.14c-0.73,0.36-2.26-0.19-2.99-0.83c-0.4-0.35-0.06-1.82,0.27-2.66c3.03-7.81,6.55-15.46,9.11-23.41
                c3.35-10.43,5.84-21.14,9-31.64c0.5-1.65,2.59-2.81,3.94-4.2C681.8,922.6,682.56,923.69,683.05,924.39z"/>
            <path d="M1236.58,933.74c5.33-12.86,5.33-12.86,10.49-12.29c-1.5,2.83-2.88,5.43-4.73,8.93c2.35-1.3,3.77-2.09,6.12-3.4
                c-4.26,11.24-8.11,21.41-11.96,31.58c-0.44,0.01-0.88,0.01-1.32,0.02c-0.48-1.06-1.31-2.1-1.39-3.18
                c-0.52-7.28-0.9-14.56-1.25-21.85c-0.05-1.13,0.05-2.45,0.58-3.39c0.65-1.15,1.83-2,2.79-2.98c0.34,1.22,0.83,2.42,0.98,3.66
                C1237.01,931.78,1236.7,932.77,1236.58,933.74z"/>
            <path d="M628.54,912.33c1.24,1.77,3.05,3.38,3.61,5.34c1.9,6.55,2.93,13.37,5.07,19.83c3.33,10.06,7.32,19.9,11,29.84
                c0.8,2.16,1.7,4.34,2.05,6.59c0.14,0.89-1.1,1.99-1.72,2.99c-0.79-0.71-1.92-1.27-2.32-2.16c-7.17-16.12-13.95-32.35-16.38-50.06
                c-0.52-3.77-2.3-7.36-3.49-11.03C627.09,913.21,627.82,912.77,628.54,912.33z"/>
            <path d="M109.02,971.26c1.23-10.16,2.41-19.48,3.48-28.83c0.32-2.79-0.01-5.69,0.59-8.4c0.28-1.26,2.06-2.19,3.15-3.27
                c0.59,1.24,1.77,2.53,1.69,3.72c-0.89,11.87-1.94,23.72-3.09,35.57c-0.12,1.28-1.06,3.47-1.75,3.52
                c-2.36,0.16-6.39,0.27-6.88-0.86c-2.14-4.98-3.33-10.38-4.67-15.67c-0.13-0.5,1.04-1.33,1.6-2.01c0.75,0.76,1.91,1.4,2.18,2.31
                C106.63,961.74,107.7,966.21,109.02,971.26z"/>
            <path d="M1030.27,934.61c1.15,13.5,2.2,27.02,3.5,40.51c0.38,3.94,1.65,7.8,2.15,11.74c0.18,1.4-0.73,2.94-1.14,4.41
                c-1.17-1.16-3.11-2.16-3.37-3.49c-1.08-5.53-1.97-11.13-2.35-16.74c-0.97-14.39-1.67-28.81-2.32-43.22
                c-0.05-1.13,1.14-2.32,1.75-3.48c0.77,1.07,1.96,2.05,2.23,3.24c0.51,2.25,0.49,4.61,0.69,6.93
                C1031.02,934.54,1030.64,934.57,1030.27,934.61z"/>
            <path d="M1045.98,984.67c2.9-5.69,5.22-11.45,8.56-16.53c8.57-13.05,17.56-25.83,26.52-38.62c0.79-1.12,2.52-1.58,3.82-2.34
                c-0.29,1.55-0.14,3.41-0.95,4.6c-7.58,11.14-15.66,21.95-22.89,33.3c-3.97,6.24-6.53,13.38-9.87,20.04
                c-0.58,1.16-1.87,1.96-2.84,2.93C1047.66,987.09,1046.99,986.12,1045.98,984.67z"/>
            <path d="M1052.7,932.86c-1.42,5.46-3.35,10.84-4.15,16.38c-1.65,11.32-2.64,22.74-4.02,34.1c-0.28,2.27-0.79,4.62-1.82,6.62
                c-0.44,0.85-2.48,0.87-3.79,1.27c-0.1-1.16-0.44-2.37-0.25-3.47c0.73-4.06,1.73-8.07,2.45-12.13c2.02-11.28,4.02-22.56,5.89-33.86
                c0.65-3.92,0.75-7.92,1.35-11.85c0.18-1.21,1.16-2.31,1.77-3.45c0.74,1.09,1.71,2.09,2.13,3.28c0.3,0.85-0.13,1.96-0.24,2.95
                C1052.24,932.74,1052.47,932.8,1052.7,932.86z"/>
            <path d="M1295.95,984.53c2.32-11.11,4.39-21.38,6.63-31.61c1.53-6.98,3.17-13.94,5.07-20.82c0.35-1.26,2.22-2.11,3.39-3.15
                c-0.11,1.43,0.01,2.92-0.36,4.28c-0.78,2.87-2.22,5.62-2.67,8.53c-2.22,14.37-4.14,28.79-6.39,43.16
                c-0.21,1.36-1.97,2.48-3.01,3.71C1297.65,987.14,1296.68,985.65,1295.95,984.53z"/>
            <path d="M473.51,987.41c-0.87-1.74-1.28-2.18-1.26-2.59c0.87-20.02,1.78-40.04,2.69-60.05c0.04-0.82-0.09-1.9,0.37-2.37
                c0.56-0.59,2.16-1.16,2.42-0.89c0.78,0.82,1.6,2.06,1.57,3.12c-0.3,10.19-0.7,20.39-1.21,30.57c-0.45,9.06-0.98,18.12-1.73,27.16
                C476.22,983.85,474.77,985.23,473.51,987.41z"/>
            <path d="M645.86,914.29c1.78,11.31,3.55,22.62,5.36,33.93c0.47,2.95,1.45,5.9,1.4,8.84c-0.03,1.42-1.9,2.8-2.94,4.2
                c-0.66-1.33-1.72-2.6-1.91-3.99c-1.25-9.05-2.37-18.12-3.43-27.19c-0.71-6.11-1.4-12.23-1.78-18.36c-0.09-1.39,1.15-2.87,1.77-4.3
                c0.81,1.43,1.76,2.81,2.37,4.32c0.27,0.67-0.13,1.62-0.23,2.45C646.26,914.22,646.06,914.26,645.86,914.29z"/>
            <path d="M1214.87,970.31c-3.81,4.5-7.11,8.59-10.66,12.45c-0.81,0.89-3.25,1.72-3.6,1.35c-1.62-1.75-4.02-4.07-3.83-5.95
                c0.51-5.07,2.08-10.05,3.46-15.01c1.33-4.79,2.85-9.54,4.5-14.23c0.36-1.03,1.61-1.74,2.45-2.61c0.36,1.26,1.27,2.66,0.99,3.76
                c-2.29,9.16-4.8,18.26-7.23,27.38c-0.08,0.3,0.02,0.64,0.06,1.61C1204.85,974.26,1208.33,969.39,1214.87,970.31z"/>
            <path d="M1317.48,990.14c-1.63,0.43-3.27,0.85-4.9,1.28c-0.09-1.83-0.84-3.95-0.17-5.44c5.24-11.64,10.68-23.2,16.31-34.66
                c0.48-0.97,2.79-1.04,4.25-1.52c-0.15,1.73,0.33,3.94-0.55,5.12c-7.89,10.57-12.86,22.33-15,35.31
                C1317.42,990.24,1317.48,990.14,1317.48,990.14z"/>
            <path d="M33.49,923.8c-0.82,9.48-1.54,18.97-2.51,28.43c-0.66,6.44-1.55,12.86-2.62,19.24c-0.25,1.47-1.63,2.75-2.49,4.12
                c-0.71-1.42-1.88-2.79-2.01-4.26c-0.19-2.11,0.33-4.29,0.63-6.43c1.7-12.3,3.36-24.61,5.21-36.89c0.26-1.7,1.49-3.25,2.27-4.87
                C32.46,923.36,32.97,923.58,33.49,923.8z"/>
            <path d="M1191.18,984.11c-4.22-18.41,1.28-36.51,1.29-55.59c3.37,2.67,3.91,5.78,3.58,10.33c-1.02,13.77-4.75,27.42-1.83,41.38
                c0.28,1.34-0.68,2.94-1.06,4.41C1192.5,984.47,1191.84,984.29,1191.18,984.11z"/>
            <path d="M922.05,962.49c-0.43-5.69-0.92-11.38-1.27-17.07c-0.23-3.65-0.51-7.32-0.26-10.95c0.09-1.33,1.64-2.56,2.53-3.83
                c0.4,1.29,1.07,2.57,1.15,3.88c0.17,3.07,0.05,6.16,0.05,9.24c0.53,0.17,1.07,0.34,1.6,0.51c2.1-2.44,4.21-4.88,6.31-7.31
                c0.57,0.31,1.14,0.62,1.71,0.92c-1.06,2.7-1.92,5.5-3.24,8.07c-1.44,2.79-3.75,5.2-4.86,8.09c-1.03,2.68-0.98,5.78-1.4,8.7
                C923.61,962.65,922.83,962.57,922.05,962.49z"/>
            <path d="M714.91,956.82c-1.52,1.49-2.89,3.17-4.57,4.43c-8.1,6.08-16.25,12.09-24.48,17.99c-0.98,0.7-2.55,0.58-3.85,0.84
                c0.23-1.35-0.02-3.15,0.76-3.95c2.53-2.6,5.27-5.09,8.25-7.15c6.43-4.44,13.03-8.63,19.63-12.82c0.92-0.58,2.21-0.55,3.34-0.81
                C714.29,955.84,714.6,956.33,714.91,956.82z"/>
            <path d="M1083.39,965.44c-0.65,0.93-1.09,1.82-1.76,2.48c-8.04,7.87-16.04,15.78-24.26,23.45c-1.26,1.18-3.58,1.22-5.42,1.79
                c0.59-2.01,0.56-4.7,1.88-5.92c8.26-7.62,16.81-14.93,25.32-22.27c0.75-0.64,1.93-0.91,2.95-1.05
                C1082.42,963.88,1082.88,964.81,1083.39,965.44z"/>
            <path d="M556.8,966.77c0.78-1.63,1.12-3.82,2.4-4.81c11.26-8.76,22.65-17.36,34.11-25.85c1.23-0.91,3.2-0.83,5.82-1.45
                c-14.4,11.33-27.81,21.9-41.23,32.46C557.53,967,557.16,966.89,556.8,966.77z"/>
            <path d="M90.12,923.24c-13.24,8.33-26.48,16.67-39.71,25c-0.3-0.29-0.6-0.58-0.9-0.87c1.15-1.38,2.05-3.13,3.48-4.08
                c8.56-5.71,17.24-11.24,25.88-16.83c2.07-1.34,4.07-2.83,6.25-3.96c1.38-0.71,3.06-0.86,4.6-1.26
                C89.85,921.9,89.99,922.57,90.12,923.24z"/>
            <path d="M1129.37,926.96c0.79,1.22,1.89,2.33,2.33,3.67c3.43,10.38,6.83,20.78,9.94,31.26c0.43,1.45-0.73,3.38-1.16,5.09
                c-0.89-1.36-2.15-2.61-2.61-4.1c-3.2-10.45-6.24-20.95-9.3-31.44c-0.35-1.19-0.48-2.45-0.71-3.68
                C1128.37,927.49,1128.87,927.22,1129.37,926.96z"/>
            <path d="M1142.06,911.71c1.56,14.09,3.06,27.14,4.37,40.21c0.12,1.15-0.98,2.43-1.51,3.65c-0.82-1.07-2.26-2.1-2.35-3.22
                c-0.92-11.88-1.69-23.77-2.31-35.67C1140.2,915.25,1141.29,913.76,1142.06,911.71z"/>
            <path d="M1317.42,990.24c7.89-8.29,15.74-16.61,23.72-24.81c0.72-0.74,2.22-0.71,3.36-1.04c-0.04,1.19,0.41,2.8-0.19,3.5
                c-2.48,2.88-5.32,5.46-7.96,8.21c-4.36,4.55-8.72,9.11-12.97,13.76c-1.99,2.18-3.78,3.39-5.91,0.28
                C1317.48,990.14,1317.42,990.24,1317.42,990.24z"/>
            <path d="M1118.49,934.71c0.35,1.1,0.54,2.28,1.07,3.28c4.83,9.08,9.73,18.12,14.6,27.18c0.31,0.58,0.84,1.19,0.83,1.78
                c-0.03,1.52-0.3,3.03-0.47,4.55c-1.22-0.83-2.98-1.39-3.56-2.54c-3.08-6.07-5.84-12.3-8.75-18.45c-2.15-4.54-4.36-9.05-6.54-13.57
                C1116.6,936.19,1117.54,935.45,1118.49,934.71z"/>
            <path d="M52.36,957.2c1.73-6.19,25.1-19.15,33.76-18.04C75,945.09,63.89,951.03,52.36,957.2z"/>
            <path d="M341.14,970.15c-0.73-0.9-1.89-1.7-2.14-2.71c-1.73-7.07-3.44-14.16-4.73-21.32c-0.26-1.44,1.35-3.21,2.09-4.83
                c0.71,1.44,1.75,2.82,2.07,4.34c1.4,6.64,2.63,13.31,3.82,19.99c0.24,1.32,0.03,2.72,0.03,4.09
                C341.91,969.86,341.52,970,341.14,970.15z"/>
            <path d="M1190.1,988.93c-0.33,0.53-0.81,1.3-1.3,2.07c-0.92-0.74-2.37-1.31-2.68-2.25c-1.82-5.5-3.36-11.1-5.01-16.66
                c-0.61-2.05-1.38-4.06-1.83-6.14c-0.21-0.95,0.16-2.02,0.27-3.04c1.03,0.31,2.08,0.58,3.08,0.95c0.24,0.09,0.43,0.51,0.52,0.82
                C1185.44,972.63,1187.71,980.57,1190.1,988.93z"/>
            <path d="M116.49,972.88c1.71-6.36,3.1-11.84,4.72-17.25c0.24-0.79,1.57-1.25,2.4-1.86c0.45,0.82,1.41,1.76,1.25,2.45
                c-1.29,5.83-2.71,11.63-4.32,17.38c-0.21,0.76-1.71,1.16-2.62,1.73C117.32,974.3,116.71,973.26,116.49,972.88z"/>
            <path d="M49.26,938.47c5.38-7.21,10.76-14.41,16.13-21.62c1.21,4.63-11.15,21.63-14.74,22.73
                C50.19,939.22,49.73,938.84,49.26,938.47z"/>
            <path d="M1347.61,978.91c-3.12,5.67-12.74,14.62-16.27,15.14C1331.46,988.77,1342.98,978.05,1347.61,978.91z"/>
            <path d="M601.37,898.68c0,7.12,0,14.23,0,22.22C597.42,916.56,597.07,902.56,601.37,898.68z"/>
            <path d="M560.14,951.63c-0.62-7.17,10.66-21.77,15.75-21.22C570.73,937.35,565.76,944.06,560.14,951.63z"/>
            <path d="M560.02,974.05c3.72-6.38,16.61-14.13,20.17-11.59c-6.55,4.36-12.94,8.61-19.33,12.85
                C560.58,974.89,560.3,974.47,560.02,974.05z"/>
            <path d="M439.4,916.17c-2.08,5.22-4.11,10.47-6.34,15.63c-0.25,0.57-1.61,0.83-2.48,0.89c-0.35,0.02-0.97-0.85-1.11-1.41
                c-0.59-2.28,5.93-14.62,8.27-15.82C438.29,915.7,438.84,915.94,439.4,916.17z"/>
            <path d="M655.25,957.21c0-6.16,0-12.32,0-18.47C659.72,940.06,660.63,951.74,655.25,957.21z"/>
            <path d="M591.21,911.47c-0.76-2.86-1.67-5.69-2.21-8.59c-0.22-1.18,0.38-2.52,0.61-3.79c1.02,0.87,2.56,1.55,2.96,2.65
                c1.14,3.1,2,6.33,2.59,9.59c0.23,1.28-0.35,2.86-0.99,4.07c-0.23,0.43-2.33,0.54-2.58,0.15c-0.71-1.09-0.9-2.52-1.29-3.82
                C590.61,911.65,590.91,911.56,591.21,911.47z"/>
            <path d="M804.98,978.33c-3.51,2.86-6.89,5.73-10.45,8.35c-0.4,0.29-1.85-0.83-2.81-1.3c0.41-0.79,0.66-1.73,1.25-2.33
                c2.28-2.32,4.61-4.6,7.07-6.73c0.75-0.65,1.94-0.98,2.96-1.04C803.36,975.26,803.8,976.46,804.98,978.33z"/>
            <path d="M1019.16,968.6c1.73,1.47,2.96,2.01,3.31,2.9c1.43,3.66,2.71,7.39,3.75,11.17c0.19,0.69-0.96,1.76-1.49,2.65
                c-0.73-0.5-1.85-0.84-2.13-1.52c-1.48-3.65-2.85-7.35-4.03-11.11C1018.28,971.77,1018.84,970.58,1019.16,968.6z"/>
            <path d="M634.13,966.63c1.64,0.2,3.97-0.11,4.36,0.62c2.01,3.76,3.62,7.74,5.1,11.75c0.25,0.67-0.86,1.83-1.34,2.77
                c-0.73-0.53-1.79-0.91-2.13-1.63C638.17,976.03,636.41,971.84,634.13,966.63z"/>
            <path d="M517.67,975.26c5.64-3.01,11.29-6.02,16.93-9.03c0.36,0.66,0.72,1.33,1.08,1.99c-5.82,2.75-9.92,8.54-16.67,9.62
                C518.56,976.99,518.12,976.12,517.67,975.26z"/>
            <path d="M704.96,979.59c3.67-3.95,6.04-6.64,8.6-9.15c0.69-0.68,1.92-0.81,2.91-1.2c0.11,1.04,0.71,2.4,0.26,3.05
                c-1.77,2.56-3.62,5.18-5.96,7.16C709.78,980.28,707.49,979.59,704.96,979.59z"/>
            <path d="M922.09,970.92c2.71-3.63,4.91-6.74,7.36-9.63c0.36-0.42,1.98,0.24,3.01,0.4c-0.82,2.38-1.03,5.38-2.63,6.96
                C928,970.45,925.43,973.39,922.09,970.92z"/>
            <path d="M555.2,940.64c3.71-5.51,6.64-9.92,9.74-14.21c0.13-0.18,2.5,0.62,2.62,1.21C567.94,929.49,559.22,939.41,555.2,940.64z"
                />
            <path d="M772.53,987.52c-0.91-0.85-1.62-1.28-2-1.91c-1.62-2.65-3.22-5.32-4.63-8.08c-0.36-0.71-0.39-2.14,0.06-2.58
                c0.5-0.48,2.34-0.57,2.55-0.21c1.96,3.32,3.77,6.74,5.42,10.22C774.13,985.42,773.16,986.43,772.53,987.52z"/>
            <path d="M1085.59,979.91c-0.81,4.22-8.94,10.67-11.61,10.03c-0.67-0.16-1.68-0.98-1.66-1.47c0.03-0.83,0.53-2.03,1.2-2.4
                C1077.22,984.03,1081.04,982.2,1085.59,979.91z"/>
            <path d="M463.19,969.46c1.51,5.2,2.93,9.86,4.16,14.58c0.2,0.75-0.35,1.84-0.86,2.55c-0.15,0.21-1.57-0.12-2.05-0.57
                C462.69,984.32,461.62,972.67,463.19,969.46z"/>
            <path d="M42.32,962.32c-1.58,6.12-6.82,10.84-13.15,10c4.11-4.65,7.48-8.48,10.86-12.3C40.8,960.79,41.56,961.56,42.32,962.32z"/>
            <path d="M837.7,953.47c3.68,2.94,7.36,5.88,12.03,9.6c-5.63,0.79-10.86-3.22-13.1-8.36C836.99,954.3,837.34,953.88,837.7,953.47z"
                />
            <path d="M900.89,950.41c-1.05,4.47-1.69,7.79-2.7,10.98c-0.22,0.71-1.74,1.01-2.67,1.5c-0.38-0.83-1.15-1.71-1.06-2.49
                C894.92,956.63,895.39,952.75,900.89,950.41z"/>
            <path d="M439.63,930.36c2.55-3.03,5.1-6.06,7.65-9.09c1.59,4.95-1.72,9.26-6.18,10.39C440.61,931.23,440.12,930.79,439.63,930.36z
                "/>
            <path d="M587.65,967.88c-3.1,6.97-6.62,9.58-12.27,8.67C579.78,973.65,581.7,968.85,587.65,967.88z"/>
            <path d="M989.83,956.68c-0.2,4.06-0.06,8.21-2.7,11.66c-0.71-0.16-1.41-0.32-2.12-0.47c0.81-3.92,1.61-7.83,2.42-11.75
                C988.23,956.31,989.03,956.49,989.83,956.68z"/>
            <path d="M68.81,958.85c-3.2,2.04-6.39,4.09-9.59,6.13c-0.47-0.71-0.93-1.42-1.4-2.12c3.09-2.22,6.19-4.43,9.28-6.65
                C67.67,957.1,68.24,957.97,68.81,958.85z"/>
            <path d="M353.8,949.81c-1.04-2.25-2.21-4.45-3.03-6.78c-0.22-0.62,0.51-1.79,1.12-2.38c0.24-0.23,1.85,0.28,1.97,0.7
                c0.76,2.6,1.27,5.27,1.86,7.91C355.08,949.44,354.44,949.63,353.8,949.81z"/>
            <path d="M615.49,899.09c-2.44,2.66-4.08,4.44-6.13,6.68C608.27,900.19,609.65,898.51,615.49,899.09z"/>
            <path d="M825.26,940.57c1.68,0,2.21-0.11,2.27,0.02c1.04,2.09,2.02,4.22,3.01,6.33c-0.68,0.29-1.93,0.87-1.95,0.83
                C827.48,945.53,826.47,943.27,825.26,940.57z"/>
            <path d="M625.26,897.51c0.16-1.38,0.31-2.76,0.47-4.15c0.71,0.14,2.03,0.28,2.02,0.41c-0.01,1.35-0.24,2.71-0.41,4.06
                C626.65,897.73,625.95,897.62,625.26,897.51z"/>
            <path d="M815.39,980.06c0.63,1.26,1.3,2.03,1.29,2.79c-0.01,0.49-1.25,1.47-1.53,1.34c-0.74-0.33-1.69-1.14-1.73-1.8
                C813.39,981.78,814.44,981.12,815.39,980.06z"/>
            <path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M857.15,622.84c10.81,0,21.71-1.16,32.24,0.3c13,1.8,24.71-1.7,36.65-5.24c0.48-0.14,0.78-0.88,1.96-2.3
                c-2.17,0-3.37-0.17-4.49,0.04c-2.12,0.4-4.22,0.97-6.31,1.54c-9.68,2.65-19.21,3.95-29.5,2.39c-9.87-1.49-20.15-0.32-30.91-0.32
                c1.07-7.93,2.16-14.61,2.83-21.33c0.78-7.94,1.08-15.93,1.86-23.87c0.16-1.61,1.64-3.06,2.36-4.66c0.39-0.85,0.46-1.85,0.68-2.83
                c5.1-1.46,10.63-3.31,16.29-4.56c3.86-0.85,7.99-0.48,11.85-1.32c13.32-2.91,26.46-6.8,39.88-9.03
                c14.57-2.42,29.37-3.53,44.09-4.97c16.02-1.57,32.07-2.83,48.11-4.27c13.87-1.25,14.65-0.42,18.52,13.43
                c6.05,21.64,12.18,43.26,21.09,63.97c1.35,3.15,2.25,6.49,3.46,10.03c-1.04,0.05-1.7,0.28-1.91,0.06
                c-7.18-7.3-17.16-8.41-26.12-11.73c-1.16-0.43-2.94,0.8-4.43,1.27c1.14,0.95,2.18,2.54,3.44,2.75
                c9.29,1.54,18.32,3.38,25.21,10.69c1.22,1.29,3.45,1.64,5.86,2.71c-0.89,1.26-1.74,2.48-1.93,2.74c2.11,4.53,4.34,8.44,5.8,12.62
                c3.44,9.87,6.67,19.83,9.62,29.86c0.86,2.93,0.72,6.23,0.68,9.35c-0.14,11.09-0.51,22.17-0.65,33.26
                c-0.11,8.92-0.02,17.84-0.02,27.22c-8.04-0.8-14.6-1.76-21.18-2.04c-23.4-1.01-46.82-2.26-70.23-2.38
                c-7.08-0.04-14.09,3.94-21.29,4.94c-16.59,2.3-33.28,5.33-49.93,5.36c-15.66,0.03-31.3-3.57-46.98-5.27
                c-20.5-2.23-41.04-4.14-61.55-6.23c-9.24-0.94-18.54-3.2-27.7-2.69c-10.12,0.56-19.27-2.86-28.87-4.23
                c-7.65-1.09-14.93-1.8-22.65-0.25c-11.71,2.36-23.57,4.22-35.46,5.28c-4.88,0.44-9.97-1.95-15.01-2.7
                c-4.2-0.63-8.59-1.49-12.71-0.92c-8.75,1.2-16.86,0.43-25.38-2.46c-6.72-2.28-14.44-1.67-21.71-2.27
                c-1.92-0.16-3.86-0.02-5.79-0.02c-0.32-0.48-0.64-0.95-0.97-1.43c2.82-3.01,5.47-6.19,8.51-8.96c1.68-1.53,4.01-2.33,6-3.55
                c3.58-2.2,8.09-3.83,5.97-9.66c-0.2-0.55,0.58-1.45,1.31-0.91c3.32-0.41,6.64-0.79,9.95-1.23c11.43-1.51,22.86-3.04,35.37-4.71
                c-1.6,4.98-2.85,8.2-3.59,11.54c-0.23,1.05,0.89,2.4,1.4,3.61c0.86-0.84,2.19-1.54,2.48-2.55c0.82-2.86,0.85-6,1.93-8.73
                c0.56-1.4,2.67-3.04,4.13-3.09c8.64-0.28,17.33-0.7,25.92,0c14,1.14,27.9,3.5,41.89,4.56c6.57,0.5,13.27-0.83,19.91-1.34
                c0-0.56-0.01-1.11-0.01-1.67c-2.19-0.16-4.38-0.3-6.56-0.5c-11.04-0.97-22.07-2.25-33.13-2.84c-10.44-0.55-20.92-0.44-31.38-0.49
                c-13.98-0.07-27.97-0.45-41.92,0.14c-7.2,0.3-14.32,2.42-21.48,3.7c-0.76,0.14-1.56,0.1-3.79,0.24c2.17-2.4,3.4-4,4.87-5.34
                c6.72-6.15,13.94-11.84,20.11-18.5c3.46-3.73,6.34-8.75,7.58-13.67c5.65-22.42,10.57-45.02,15.89-67.52
                c0.86-3.65,2.84-7.05,3.64-10.71c0.97-4.43,1.41-9,1.75-13.53c0.36-4.77,3.16-7.73,7.04-9.56c2.14-1.01,4.85-0.8,7.3-1.14
                c0.2,0.55,0.4,1.1,0.6,1.65c0.52-0.76,1.31-1.45,1.53-2.29c2.72-10.52,14.93-15.94,24.57-11.11c1.91,0.96,4.47,0.73,6.74,0.84
                c15.12,0.75,30.32,0.69,45.34,2.32c20.8,2.27,41.46,5.82,62.18,8.78c7.73,1.1,7.85,1.65,5.42,9.21
                c-3.64,11.35-6.99,22.8-10.13,34.29c-1.74,6.37-2.81,12.93-4.39,20.4c-1.17-0.98-3.29-2.03-4.32-3.74
                c-5.74-9.55-11.22-19.25-16.79-28.91c-2.09-3.62-4.77-6.64-7.64-9.49c-0.51,0.34-1.02,0.68-1.53,1.02c0.3,1.14,0.35,2.42,0.92,3.4
                c6.32,10.91,12.36,22.01,19.27,32.54c3.08,4.7,6.19,10.17,13.61,10.33c2.54,0.06,5.33,3.16,7.43,5.41
                c5.44,5.81,12.3,10.45,13.13,19.76c0.52,5.83,3.19,11.46,5.33,18.62c-5.5,0-10.86,0.1-16.21-0.02
                c-15.46-0.34-30.93-0.46-46.36-1.25c-14.79-0.75-29.52-2.65-44.31-3.22c-7.57-0.29-15.21,1.15-22.8,1.94
                c-0.95,0.1-1.82,1.05-2.52,2.78c1.84,0,3.75,0.33,5.51-0.05c16.1-3.49,32.15-0.91,48.18,0.43c7.72,0.64,15.35,2.69,23.06,2.98
                c8.19,0.31,16.42-0.84,24.65-1.12c7.14-0.24,14.3,0.05,21.43-0.28c7.39-0.33,14.3-0.13,20.76,4.65c4.67,3.45,5.24,2.66,6.27-2.82
                c0.24-1.28,2.13-2.24,3.86-3.94c-10.59,1.36-11.48-6.79-14.32-13.38c-2.2-5.12-3.04-10.09-1.46-15.47
                c0.31-1.05,0.49-2.73-0.09-3.34c-4.26-4.53-5.37-11.8-11.93-14.24C856.82,631.64,855.27,628.23,857.15,622.84z M1014.05,713.78
                c-0.1-0.84-0.2-1.69-0.3-2.53c-3.24,0-6.47,0.03-9.71-0.01c-12.43-0.14-24.86-0.48-37.28-0.39c-12.94,0.09-25.89,0.98-38.83,0.73
                c-5.87-0.11-11.72-2.07-17.56-3.27c-4.39-0.9-13.63,3.23-17.08,8.43c2.27-0.18,3.86-0.26,5.43-0.43
                c4.37-0.48,8.76-1.62,13.09-1.39c16.07,0.85,32.11,2.15,48.16,3.29c1.98,0.14,4,0.14,5.93,0.55c18.03,3.77,36.26,3.92,54.54,3.02
                c0.99-0.05,2.34,0.08,2.89-0.49c1.36-1.4,3.68-3.91,3.26-4.64c-1.24-2.19-3.41-4.07-5.63-5.39c-0.84-0.5-2.79,0.87-4.23,1.39
                c1.43,1.35,2.87,2.7,4.3,4.05c-6.01,2.78-47.64,0.78-52.54-2.69c1.02-0.15,1.83-0.38,2.62-0.36c10.44,0.32,20.88,0.79,31.33,0.97
                C1006.32,714.67,1010.19,714.07,1014.05,713.78z M705.83,580.56c-0.56-0.16-1.12-0.32-1.67-0.47c-0.64,1.15-1.7,2.24-1.84,3.45
                c-0.81,6.73-1.43,13.48-2.05,20.23c-0.52,5.65-0.82,11.26-7.41,13.82c-1.64,0.63-2.72,2.71-4.05,4.13
                c0.33,0.44,0.66,0.88,0.99,1.32c2.71-1.17,5.41-2.33,9.16-3.95c-0.19,3.45-0.31,6-0.48,8.54c-0.15,2.31-0.55,4.63-0.43,6.92
                c0.04,0.78,1.32,1.49,2.03,2.24c0.5-0.77,1.3-1.49,1.44-2.32c0.37-2.28,0.28-4.64,0.7-6.91c2.14-11.57,2.56-12.53,14.72-12.16
                c14.41,0.43,28.77,2.19,43.16,3.25c2.95,0.22,5.94-0.12,8.92-0.2c-0.01-0.96-0.02-1.91-0.02-2.87c-21.63-1.3-43.27-2.6-65.08-3.91
                C704.52,601.66,705.18,591.11,705.83,580.56z M942.94,666.57c-0.02,0.5-0.04,1.01-0.05,1.51c-12.02,0.85-24.04,1.72-36.07,2.53
                c-5.67,0.38-11.07,1.43-16.28,4.9c16.8-1.8,32.76-4.69,48.75-4.9c16.06-0.21,32.15,2.23,48.23,3.52c0.05-0.65,0.1-1.31,0.14-1.96
                c-10.68-1.14-21.35-2.28-32.94-3.51c13.36-4.48,25.49,1.85,38.17,0.34c-1.53-1.4-3.31-3.05-5.16-3.14
                c-9.76-0.45-19.54-0.45-29.31-0.72c-5.1-0.14-10.21-0.42-15.3-0.84c-5.29-0.43-10.55-1.2-15.84-1.62
                c-1.45-0.12-2.96,0.46-4.45,0.71c0,0.57,0,1.14,0,1.71C929.54,665.59,936.24,666.08,942.94,666.57z M844.08,708.09
                c-0.04-0.58-0.07-1.17-0.11-1.75c-2.54,0.12-5.07,0.31-7.61,0.34c-6.11,0.08-12.26,0.51-18.33,0.01
                c-5.71-0.48-11.32-2.15-17.02-2.98c-8.21-1.2-16.45-2.16-24.69-3.15c-5.07-0.6-10.16-1.06-15.24-1.58
                c-0.07,0.72-0.14,1.44-0.22,2.15c12.66,1.86,25.32,3.73,37.99,5.6c-1.34,0.49-2.79,1.02-4.24,1.55c-0.01,0.34-0.01,0.67-0.02,1.01
                c7.36,1.6,14.72,3.21,22.07,4.81c0.17-0.46,0.34-0.93,0.51-1.39c-3.6-1.67-7.2-3.33-10.8-5c6.48,0.46,12.56,2.91,18.71,3.13
                C831.38,711.06,837.74,709.1,844.08,708.09z M678.86,664.94c1.78,0.2,2.18,0.37,2.41,0.25c0.72-0.38,1.35-0.95,2.08-1.3
                c7.49-3.62,15.03-7.35,23.7-6.11c14.08,2.01,28.57,0.92,42.31,5.67c1.71,0.59,3.8,0.08,5.72,0.08c0.02-0.48,0.04-0.96,0.06-1.43
                c-0.87-0.37-1.71-0.94-2.61-1.07c-14.41-2.12-28.85-4.04-43.24-6.33c-9.85-1.56-18.79,0.47-27,5.87
                C681,661.41,680.27,663.09,678.86,664.94z M955.86,645.94c-1.51-8.57-2.85-16.77-4.52-24.9c-0.23-1.11-2.14-1.87-3.27-2.79
                c-0.28,1.37-1.13,2.9-0.77,4.07c3.02,9.78,3.19,19.4-0.67,29.03c-0.85,2.13-1.36,4.51-1.41,6.8c-0.03,1.35,1.16,2.72,1.8,4.08
                c0.79-1.19,1.87-2.29,2.32-3.6c1.15-3.33-0.36-7.98,5.09-9.09C955.17,649.39,955.45,647.04,955.86,645.94z M954.51,611.47
                c0.08,0.8,0.16,1.59,0.24,2.39c1.33,0,2.66,0.05,3.99-0.01c3.3-0.16,6.6-0.66,9.88-0.51c11.78,0.55,23.74-1.3,35.31,2.41
                c4.49,1.44,8.98,2.89,13.49,4.28c1.71,0.53,3.47,0.9,5.21,1.35c0.11-0.65,0.22-1.31,0.32-1.96c-18.1-12.49-39.32-6.62-59.1-9.2
                C960.85,609.84,957.64,611.02,954.51,611.47z M1041.89,714.93c0.05,0.87,0.11,1.74,0.16,2.6c7.77,2.48,15.57,5.01,23.96,4.56
                c1.82-0.1,3.57-1.52,5.35-2.33c-1.67-2.01-3.06-5.34-5.06-5.76c-5.1-1.07-10.49-1.12-15.75-1.01
                C1047.65,713.05,1044.77,714.25,1041.89,714.93z M887.85,712.25c-0.08-0.5-0.16-1-0.24-1.5c-16.86-3.07-33.16,1.7-49.73,3.54
                c5.51,2.1,10.97,2.63,16.83,1.54c7.12-1.32,14.37-1.9,21.58-2.69C880.13,712.72,883.99,712.54,887.85,712.25z M959.02,551.09
                c0.03,0.42,0.07,0.84,0.1,1.26c17.55,0.83,35.09,1.67,52.64,2.5c0.02-0.62,0.03-1.24,0.05-1.87
                C994.22,552.23,976.78,546.6,959.02,551.09z M794.81,665.92c-0.12,0.8-0.23,1.59-0.35,2.39c4.3,0.69,8.61,1.33,12.9,2.07
                c5.05,0.87,10.2,1.43,15.1,2.83c8.27,2.36,16.23,0.31,24.31-1.07C829.46,670.06,812.14,667.99,794.81,665.92z M747.22,692.7
                c-10.96-2.77-21.92-5.54-32.68-8.27C715.67,688.98,738.28,695.85,747.22,692.7z M692.67,685.06c6.72-3.37,9.85-16.66,5.32-21.44
                C696.22,670.75,694.56,677.43,692.67,685.06z M879.61,665.79c-0.71,3.9,0.6,5.4,4.47,4.54c2.08-0.46,4.2-0.69,6.28-1.15
                c2.7-0.6,5.38-1.32,8.06-1.99c-0.08-0.47-0.17-0.94-0.25-1.41C892.06,665.79,885.96,665.79,879.61,665.79z M818.23,620.2
                c-4.4,0.51-8.8,1.01-13.7,1.57C807.91,626.04,813.72,625.59,818.23,620.2z M787.65,666.6c0.01-0.52,0.02-1.05,0.03-1.57
                c-5.32,0-10.63,0-15.95,0c0.01,0.52,0.03,1.05,0.04,1.57C777.06,666.6,782.35,666.6,787.65,666.6z M776.51,616.61
                c-0.04,0.87-0.08,1.73-0.13,2.6c3.52,0,7.05,0,10.57,0c0.03-0.67,0.06-1.33,0.09-2C783.53,617.01,780.02,616.81,776.51,616.61z
                M1035.3,555.11c-0.12-0.71-0.23-1.42-0.35-2.13c-3.57,0.58-7.15,1.16-10.72,1.75c0.11,0.67,0.21,1.33,0.32,2
                C1028.48,558.75,1031.74,555.81,1035.3,555.11z M795.93,620.55c0-0.34,0.01-0.68,0.01-1.02c-1.07,0-2.14,0-3.21,0
                c0,0.34-0.01,0.68-0.01,1.02C793.8,620.55,794.86,620.55,795.93,620.55z"/>
            <path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M297.23,884.97c4.66-4.73,9.37-9.24,13.76-14.04c3.83-4.18,4.61-9.48,4.24-14.94
                c-0.08-1.15-0.9-2.24-1.39-3.36c-0.94,0.93-2.56,1.76-2.7,2.79c-0.69,5.15-1.75,9.91-4.88,14.39c-4.07,5.81-9.17,9.42-15.97,11.09
                c-1.38,0.34-2.45,1.93-3.66,2.94c0.19,0.68,0.38,1.36,0.57,2.04c-6.52-0.8-13.03-1.8-19.58-2.28c-2.22-0.16-4.55,0.89-6.8,1.52
                c-4.59,1.29-9.1,3.4-13.76,3.82c-6.41,0.57-11.67,2.36-16.6,6.79c-3.85,3.46-9.06,5.42-14.61,8.59
                c-2.49-4.05-5.24-8.08-7.52-12.36c-2.32-4.34-3.46-9.35-10.01-9.58c-2.16-0.08-4.29-3.56-6.36-3.49
                c-7.25,0.26-13.73-1.22-20.16-4.69c-1.56-0.84-5.35,1.64-7.65,3.25c-2.21,1.56-3.63,2.55-6.52,0.95
                c-5.64-3.13-10.37-1.12-15.06,3.58c-4.11,4.12-9.18,7.38-14.19,10.41c-1.09,0.66-4.17-0.79-5.6-2.09c-4.46-4.04-9-5.43-15.08-3.7
                c-3.06,0.87-6.8-0.89-10.25-1.1c-7.78-0.48-15.53,2.84-23.44-2.18c-3.21-2.03-9.34-0.4-12.63,4.55c-0.77,1.16-4.9,1.49-6.36,0.51
                c-5.06-3.37-9.76-3.91-14.81-0.4c-0.4,0.28-0.92,0.66-1.35,0.61c-7.23-0.82-14.38-1.96-21.63-4.44c2.96-6,6.43-9.06,13.62-7.97
                c-1.95-1.8-2.79-2.57-4.38-4.04c2.66-2.19,5.1-4.23,7.58-6.22c0.51-0.41,1.45-0.48,1.7-0.96c6.93-13.66,19.53-22.58,28.66-34.41
                c1.13-1.47,2.95-2.92,4.69-3.33c13.59-3.19,27.11-6.71,41.36-5.33c4.21,0.41,8.56-1.03,12.87-1.28
                c6.42-0.37,12.87-0.25,19.28-0.69c4.9-0.34,9.76-1.23,14.63-1.88c-0.04-0.43-0.08-0.86-0.12-1.3c-9.18,0-18.36-0.23-27.52,0.06
                c-9.45,0.3-18.91,0.82-28.31,1.76c-9.35,0.93-18.63,2.49-27.94,3.77c-0.27-0.37-0.54-0.74-0.81-1.11
                c2.54-1.61,4.88-3.98,7.65-4.68c8.38-2.1,16.92-3.54,25.4-5.23c1.5-0.3,3.01-0.58,4.41-1.58c-9.07-2.02-17.81,0.37-26.9,1.66
                c4.73-6.51,7.08-14.62,15.35-18.04c3.64-1.51,6.65-4.65,10.33-5.95c5.27-1.85,10.88-2.75,16.37-3.94
                c10.68-2.3,21.33-4.88,32.1-6.66c11.96-1.98,24.04-3.21,36.08-4.74c12.49-1.59,24.95-3.44,37.48-4.62
                c8.06-0.76,16.22-0.47,24.34-0.69c8.13-0.22,16.26-0.35,24.38-0.82c1.88-0.11,3.7-1.42,5.54-2.18c-0.12-0.62-0.25-1.24-0.37-1.87
                c-1.39,0-2.79-0.07-4.18,0.01c-5.42,0.34-10.66,2.47-16.19-1.21c-2.45-1.63-6.82-0.73-10.27-0.45
                c-18.38,1.49-36.73,3.7-55.14,4.56c-13.4,0.63-26.2,3.7-39.18,6.41c-7.61,1.59-15.48,1.94-23.25,2.71
                c-6.77,0.67-13.56,1.11-20.33,1.73c-3.49,0.32-8.78-4.16-10.04-8.09c-1.09-3.37-2.96-6.48-4.19-9.81
                c-0.73-1.96-0.64-4.22-1.38-6.17c-0.5-1.3-1.81-2.29-2.75-3.42c-0.64,1.19-2.02,2.56-1.78,3.52c0.81,3.24,2.17,6.34,3.4,9.46
                c0.23,0.58,1.03,0.94,1.25,1.53c1.94,5.14,3.81,10.31,5.82,15.8c-4.06,0.99-8.8,2.15-14.47,3.53c-1.48-4.74-3.2-10.01-4.75-15.34
                c-0.89-3.03-1.82-6.1-2.16-9.22c-0.37-3.49-1.37-7.78,0.15-10.44c4.49-7.89,9.98-15.23,15.36-22.58c0.86-1.17,3.18-1.8,4.82-1.79
                c9.18,0.1,17.75,2.82,26.39,5.8c5.69,1.97,12.07,1.93,18.14,2.77c3.11,0.43,6.35,0.42,9.31,1.32c9.23,2.79,17.83,0.38,26.83-1.68
                c16.88-3.86,34.12-5.07,51.48-4.09c6.61,0.37,13.36-0.45,19.84,0.59c14.91,2.39,29.64,5.84,44.54,8.3
                c4.64,0.77,9.57-0.17,14.36-0.49c4.97-0.33,9.93-0.72,14.87-1.36c1.62-0.21,3.12-1.25,4.68-1.92c-0.02-0.54-0.04-1.08-0.06-1.62
                c-2.1-0.36-4.19-0.87-6.31-1.07c-22.83-2.21-45.65-4.38-68.48-6.55c-10.65-1.01-21.3-2.36-31.98-2.82
                c-6.25-0.27-12.69,0.3-18.82,1.55c-15.02,3.06-30.04,6-45.38,3.6c-17.54-2.75-34.99-6.1-52.48-9.22
                c-0.94-0.17-1.82-0.68-2.73-1.04c-0.06-0.56-0.11-1.12-0.17-1.68c4.64-0.67,9.34-2.15,13.9-1.81c6.87,0.51,13.68,2.12,20.45,3.55
                c11.23,2.38,22.25,2.31,33.49-0.53c4.56-1.15,9.74,0.19,14.64,0.35c9.4,0.32,18.8,0.46,28.18,0.97c4.56,0.25,9.36,0.43,13.55,1.97
                c6.3,2.32,11.43-0.74,17.11-1.03c-0.02-0.75-0.03-1.5-0.05-2.25c-17.39-0.96-34.79-1.92-52.12-2.88
                c14.69-1.24,29.45-2.8,44.25-3.6c5.67-0.31,11.43,1.11,17.16,1.74c0.34,0.62,0.68,1.24,1.02,1.86c-1.75,0.84-3.51,1.68-6.23,2.99
                c6.86,1.34,9.35-0.22,10.06-4.96c2.81-0.23,5.66-0.46,8.51-0.69c0.13-0.63,0.25-1.26,0.38-1.88c-2.07-0.66-4.11-1.72-6.22-1.9
                c-25.79-2.26-51.38,0.97-76.98,3.27c-11.55,1.04-23.04,2.89-34.61,3.7c-4.68,0.33-9.56-0.81-14.23-1.86
                c-4.77-1.07-9.39-2.83-15.62-4.77c4.79-1.17,8.4-2.56,12.11-2.88c19.04-1.66,38.11-3,57.15-4.58c2.17-0.18,4.26-1.28,6.43-1.73
                c2.87-0.59,5.85-1.58,8.66-1.29c5.25,0.54,10.41,1.94,15.64,2.84c0.95,0.16,2.02-0.36,3.04-0.56c0.07-0.55,0.14-1.1,0.21-1.65
                c-5.51-1.25-11.03-2.5-16.54-3.76c-0.06-0.46-0.13-0.92-0.19-1.38c2.66-0.96,5.41-1.73,7.96-2.93c8.11-3.82,16.96-7.37,25.48-5.54
                c16.2,3.48,31.72-2.81,47.54-1.37c14.61,1.33,28.39,5.55,40.04,14.89c2.34,1.87,4.86,4.94,5.15,7.69
                c0.97,9.18,7.98,12.91,14.2,17.42c2.75,1.99,5.85,3.5,9.9,5.89c-6.48,1.39-11.56,2.47-16.64,3.56c0.1,0.85,0.2,1.71,0.3,2.56
                c6.73,0,13.46,0,22.04,0c3.25,6.79,7.18,15.01,11.67,24.4c-5.78,0-9.91-0.43-13.92,0.1c-6.39,0.84-13.23-0.09-18.66,5.18
                c-1.59,1.54-5.1,0.97-7.61,1.77c-1.22,0.39-2.15,1.69-3.21,2.58c1.38,0.5,2.78,1.48,4.13,1.41c9.12-0.48,18.23-1.66,27.35-1.64
                c4.97,0.01,10.03,1.64,14.85,3.17c1.38,0.44,2.44,2.98,2.99,4.76c1.95,6.36,3.36,12.9,5.43,19.22
                c6.65,20.39,13.52,40.71,20.26,61.07c1.4,4.23-0.13,6.33-4.61,6.12c-2.8-0.14-5.63-0.69-8.35-1.43c-5.1-1.39-9.24-0.23-12.32,4.1
                c-2.42,3.4-4.85,4.03-8.19,1.02c-3.15-2.85-6.31-0.99-9.08,0.97c-3.31,2.34-6.7,3.36-10.85,3.99c-4.17,0.63-7.82,4.55-11.76,6.92
                c-1.76,1.06-3.62,2.36-5.56,2.64c-5.89,0.86-11.83,1.32-17.76,1.83c-1.39,0.12-2.82-0.17-3.62-0.23
                c2.09-7.33,3.59-14.42,6.22-21.05c1.4-3.54,4.81-6.27,7.25-9.41c1.41-1.81,2.7-3.71,4.54-6.26c-7.78,0.61-16.29,10.75-17.1,18.86
                c-1.26,12.59-9.3,21.32-20.82,22.54C298.78,885.21,297.67,884.99,297.23,884.97z M385.76,790.41c-0.02-0.58-0.05-1.16-0.07-1.74
                c-1.67,0-3.36-0.16-5.02,0.03c-8.59,0.96-17.16,2.67-25.77,2.9c-39.78,1.08-79.41,3.91-118.9,8.73c-1.57,0.19-3.08,0.86-4.61,1.31
                c0.08,0.6,0.16,1.2,0.23,1.8c3.02,0,6.06,0.26,9.04-0.04c20.52-2.08,41.02-4.3,61.53-6.41c2.02-0.21,4.08-0.03,6.36-0.03
                c-2.83,8.83-2.83,8.59-10.81,8.65c-7.97,0.06-15.93,0.87-23.9,0.98c-12.85,0.18-25.43,7.04-38.69,0.11
                c-6.87-3.59-14.99-4.98-22.71-6.64c-3.84-0.82-8.09-0.43-12.04,0.17c-6.16,0.94-12.21,2.58-18.32,3.83
                c-3.25,0.67-6.6,0.92-9.79,1.78c-6.48,1.74-10.64,5.78-11.83,12.65c-0.27,1.55-0.86,3.72-1.99,4.33
                c-7.63,4.11-7.33,11.38-7.42,18.32c-0.06,4.16,1.65,7.83,6.21,9.45c0.06-1.87-0.36-3.5-1.04-5.03c-3.46-7.8-1.16-15.72,5.8-20.34
                c3.2-2.13,6.2-4.57,10.02-7.41c-2.69-0.94-4.11-1.43-6.83-2.38c2.94-2.33,4.83-4.94,7.19-5.49c7.09-1.65,14.36-2.53,21.58-3.63
                c1.58-0.24,3.57,0.41,4.76-0.34c9.91-6.24,19.01-0.17,28.37,1.64c0.73,0.14,1.67,0.83,1.94,1.49c2.17,5.45,6.94,4.43,11.1,4.37
                c4.8-0.07,9.6-0.58,14.39-1.05c17.19-1.67,34.39-3.32,51.56-5.22c1.65-0.18,3.24-1.84,4.65-3.05c2.07-1.77,3.79-5.15,5.95-5.42
                c9.11-1.11,18.39-0.69,26.57,3.9c4.22,2.37,8.08,3.3,12.67,2.29c2.75-0.6,5.6-0.74,8.37-1.31c2.82-0.57,5.59-1.41,8.38-2.13
                c-0.11-0.46-0.22-0.93-0.33-1.39c-1.96-0.09-3.98,0.07-5.86-0.35c-2.07-0.47-3.97-1.81-6.05-2.17c-2.38-0.41-4.99-0.62-7.31-0.1
                c-6.2,1.39-10.02,1.12-16.81-2.14C353.1,794.88,369.63,794.41,385.76,790.41z M209.62,814.99c-3.83-3.5-21.12-3.72-24.51-1.4
                c-0.69,0.47-1.34,1.6-1.3,2.38c0.04,0.74,0.87,1.79,1.6,2.08c2.15,0.84,4.4,1.47,6.65,2c8.8,2.05,12.88,6.79,13.91,15.81
                c0.16,1.37,1.56,2.59,2.39,3.88c0.66-1.49,1.98-3.04,1.87-4.47c-0.67-8.56-4.96-14.6-13.22-17.52c-1.45-0.51-2.89-1.03-4.34-1.54
                c0.05-0.4,0.1-0.8,0.16-1.21C198.43,814.99,204.03,814.99,209.62,814.99z M133.53,839.71c-0.5-0.07-1-0.15-1.51-0.22
                c-3.04,2.98-2.83,6.88-2.4,10.78c0.81,7.29,0.54,14.23-5.06,19.93c-1.62,1.65-3.06,3.57-4.13,5.6c-0.64,1.2-0.41,2.86-0.58,4.32
                c1.4-0.57,3.34-0.73,4.12-1.77c3.47-4.62,6.73-9.42,9.78-14.33c0.78-1.26,0.7-3.17,0.67-4.78c-0.11-5.29-0.36-10.58-0.59-15.87
                C133.78,842.14,133.63,840.92,133.53,839.71z M319.66,761c-4.74-6.36-21.62-5.71-27.98,0.71C301,767.16,310.11,764.29,319.66,761z
                M141.6,803.95c-4.75,7.57-13.56,1.58-20.07,5.66c8.06,3.1,37.94,0.04,40.96-4.06C155.84,803.38,148.67,810.25,141.6,803.95z
                M152.95,759.06c5.42,2.86,11.11,2.53,16.91,1.64c3.52-0.54,7.12-0.61,10.65-1.13c2.63-0.39,5.2-1.16,7.8-1.77
                c-0.16-0.82-0.31-1.64-0.47-2.45C176.21,756.58,164.58,757.82,152.95,759.06z M290.28,704.84c0,0.05-0.01,0.1-0.01,0.15
                c-0.98-0.07-2.18-0.53-2.9-0.13c-1.43,0.79-2.6,2.05-3.87,3.12c1.29,0.68,2.56,1.86,3.88,1.92c2.26,0.1,4.58-0.37,6.81-0.88
                c0.74-0.17,1.78-1.25,1.75-1.88c-0.03-0.69-1-1.69-1.77-1.94C292.96,704.81,291.58,704.93,290.28,704.84z M218.74,755.27
                c-6.1,0-11.34,0-17.01,0C205.69,759.34,213.32,759.46,218.74,755.27z M346.95,851.88c-0.36-0.51-0.71-1.02-1.07-1.53
                c-3.57,2.45-7.13,4.9-11.66,8.01C339.82,859.75,344.56,856.79,346.95,851.88z M340.95,841.03c4.77-0.63,8.64-1.15,12.72-1.69
                C350.35,834.72,344.52,835.25,340.95,841.03z M223.53,687.72c-0.07,0.79-0.13,1.58-0.2,2.38c2.74,0.19,5.48,0.38,8.22,0.58
                c0.05-0.61,0.1-1.23,0.15-1.84C228.97,688.46,226.25,688.09,223.53,687.72z M310.6,709.17c-0.04-0.79-0.07-1.57-0.11-2.36
                c-1.92,0.12-3.85,0.24-5.77,0.35c0.03,0.67,0.06,1.34,0.09,2C306.74,709.17,308.67,709.17,310.6,709.17z M149,756.12
                c0.69-0.31,1.37-0.62,2.06-0.93c-0.7-1.52-1.4-3.04-2.09-4.56c-0.63,0.29-1.27,0.58-1.9,0.87
                C147.71,753.03,148.35,754.57,149,756.12z M152.82,740.68c-1.01-0.88-1.6-1.69-2.37-1.92c-0.37-0.11-1.07,0.86-1.62,1.34
                c0.58,0.65,1.06,1.51,1.78,1.84C150.98,742.1,151.79,741.3,152.82,740.68z"/>
            <path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M1224.03,900.9c-2.29-0.81-4.58-1.61-6.86-2.42c-7.64-2.73-14.29-1.37-20.2,4.36
                c-1.13,1.09-3.44,1.51-5.14,1.37c-5.06-0.43-10.1-1.9-15.13-1.83c-8.01,0.11-15.26-1.34-22.44-5.18
                c-2.83-1.51-7.71-1.12-10.86,0.24c-10.28,4.44-21.21,4.71-31.97,5.88c-3.62,0.39-7.45-1.06-11.17-1.7
                c-1.47-0.25-2.91-0.73-4.38-0.83c-8.04-0.55-16.03,0.81-24.21-1.52c-4.22-1.2-9.57,1.91-14.47,2.67
                c-4.53,0.7-9.39,1.98-13.66,1.02c-10-2.25-13.65-8.27-13.88-18.84c-0.06-2.82-1.84-5.64-3-8.4c-1.33-3.17-3.61-6.14-4.11-9.41
                c-1.24-8.17-3.69-14.86-12.22-18.31c-7.11-2.88-8.71-11.05-13.57-16.12c-3.93-4.1-8.24-8.52-13.29-10.66
                c-4.59-1.94-10.4-1.3-15.66-1.24c-4.61,0.05-9.21,0.96-13.83,1.35c-2.97,0.25-5.96,0.32-8.94,0.32c-3.69,0-7.46-0.71-11.05-0.14
                c-7.2,1.14-14.34,2.8-21.43,4.54c-2.18,0.54-4.54,1.6-6.11,3.15c-4.72,4.65-9.41,9.4-13.51,14.58
                c-4.5,5.68-8.65,11.71-12.34,17.95c-2.22,3.76-3.31,8.19-4.81,12.36c-0.93,2.59-1.68,5.24-2.44,7.64
                c-11.82-7.1-13.29-20.79-21.25-29.94c-7.02-8.08-13.91-16.48-26.68-15.59c-3.86,0.27-7.96-2.57-11.92-4.05
                c-3.65-1.36-6.78-4.52-11.2-0.48c-1.85,1.69-5.98,0.95-9.09,1.17c-3.09,0.21-5.98,0.35-6.92,4.27c-0.24,0.99-1.81,1.9-2.97,2.35
                c-1.68,0.66-3.57,1.31-5.32,1.21c-13.52-0.79-23.33,6.97-32.78,14.8c-3.12,2.58-4.2,7.55-6.48,11.26
                c-1.15,1.88-2.79,3.51-4.41,5.05c-0.65,0.62-2.37,0.4-2.7,1.03c-4.12,7.91-14.55,11.76-13.86,22.94c0.46,7.45-3.4,10.28-11.12,9.7
                c-6.44-0.49-12.95-0.74-19.26-1.97c-6.6-1.29-13.17-3.18-19.39-5.7c-2.69-1.09-5.07-4.1-6.54-6.8
                c-3.63-6.67-10.33-7.47-16.41-8.94c-4.2-1.02-7.7-1.79-11.66-4.48c-5.41-3.67-12.53-6.71-20.14-3.28
                c-4.39,1.97-9.19,2.67-13.92-0.13c-1.59-0.94-4.01-1.38-5.78-0.96c-4.07,0.97-5.67-1.03-7.15-4.26
                c-1.72-3.76-4.03-7.24-5.79-10.98c-0.55-1.16-0.7-3.03-0.15-4.1c4.18-8.09,8.53-16.1,12.96-24.05c1.37-2.46,3.05-4.77,4.79-6.98
                c1.08-1.38,2.35-2.94,3.88-3.55c2.77-1.11,6.11-1.23,4.51-5.75c-0.18-0.52,1-1.61,1.67-2.33c8.59-9.44,17.22-18.84,25.78-28.31
                c2.61-2.88,4.82-6.43,9.59-4.03c11.18-4.73,23.03-1.85,34.53-2.8c13.87-1.15,27.87-0.52,41.8-1.15
                c7.78-0.35,15.52-1.71,23.28-2.56c4.79-0.52,9.96,1.73,14.33-2.04c0.4-0.34,1.76-0.35,1.87-0.13c2.63,5.58,7.82,3.08,11.86,3.59
                c9.05,1.15,18.18,1.6,27.25,2.57c10.06,1.09,20.06,2.92,30.15,3.58c16.43,1.08,32.98,0.73,49.34,2.34
                c18.75,1.85,37.45,0.82,56.17,1.1c23.71,0.35,47.41,1.29,71.12,1.62c12.6,0.18,25.2-0.36,37.81-0.66c1.16-0.03,2.3-0.7,3.45-1.07
                c-0.04-0.69-0.09-1.38-0.13-2.08c-21.42,0-42.84,0-64.27,0c0-0.91,0.01-1.83,0.01-2.74c23.76,0,47.52,0,71.48,0
                c0,1.37,0,2.6,0,3.68c0.38,0.23,0.53,0.41,0.66,0.39c5.24-0.92,10.7-1.23,15.63-3c3.77-1.36,13.81-1.55,17.64,0.38
                c1.85,0.93,4.87,0.48,6.99-0.32c2.98-1.14,5.09-0.82,7,1.62c6.57,8.36,13.3,16.6,19.6,25.16c1.37,1.87,1.38,4.86,1.68,7.37
                c0.68,5.69,5.05,8.41,9,11.36c13.31,9.96,26.77,19.7,40.07,29.67c5.58,4.18,10.98,8.63,16.29,13.16c1.39,1.18,2.15,3.1,3.23,4.67
                c0.44,0.64,0.83,1.54,1.46,1.8c13.09,5.36,21.86,15.98,31.72,25.27C1224.47,900.02,1224.25,900.46,1224.03,900.9z M640.16,831.04
                c11.31-2.29,21.56-5.67,32.53-4.7c0.63,0.06,1.55,0.22,1.79,0.64c2.32,4.03,6.21,2.93,9.76,3.26c7.91,0.73,15.82,1.5,23.68,2.63
                c5.42,0.78,10.72,2.42,16.13,3.27c9.3,1.46,18.63,2.82,28,3.81c5.61,0.59,11.21-0.14,16.56-2.55
                c-23.04-0.65-45.51-4.04-67.72-9.12c0.05-0.52,0.09-1.03,0.14-1.55c18.93,0.94,37.87,1.87,56.8,2.81
                c0.13-0.49,0.26-0.99,0.39-1.48c-2.53-0.76-5.03-2.06-7.59-2.19c-8.28-0.44-16.72,0.47-24.85-0.8
                c-20.23-3.15-40.5-0.34-60.72-1.38c-1.31-0.07-2.64,0.39-3.96,0.44c-11.83,0.44-23.02,6.67-35.32,3.7c-2.29-0.55-5.15,1.27-7.74,2
                c0.12,0.64,0.23,1.28,0.35,1.92c1.34,0.07,2.7,0.03,4.02,0.22c5.49,0.82,11.26,0.91,16.38,2.78c7.41,2.71,14.08,7.57,21.56,9.97
                c10.19,3.27,20.84,5.09,31.32,7.47c0.64,0.14,1.41-0.32,2.13-0.5c0.07-0.52,0.13-1.05,0.2-1.57c-9.1-2.08-18.49-3.32-27.21-6.46
                C657.94,840.48,647.97,839.15,640.16,831.04z M605.38,833.04c0.36,0.4,0.73,0.81,1.09,1.21c3.68-2.67,7.35-5.36,11.05-8.01
                c2.28-1.63,4.37-3.97,6.92-4.69c8.63-2.44,17.39-4.48,26.17-6.33c11.36-2.4,23.19-3.28,34.08-6.97
                c18.74-6.35,36.69-3.49,54.82,1.45c13.1,3.57,26.16,7.32,39.26,10.92c7.54,2.07,15.08,4.11,22.68,5.94
                c3.36,0.81,6.86,1.19,10.31,1.46c1.19,0.09,2.45-0.72,4.59-1.41c-1.51-1.06-1.96-1.58-2.51-1.74
                c-16.75-4.83-33.5-9.64-50.26-14.42c-1.92-0.55-3.88-0.91-5.83-1.36c-6.64-1.53-13.22-3.46-19.93-4.5
                c-11.95-1.85-24.02-4.57-35.97-4.25c-10.21,0.28-20.33,4.32-30.49,6.69c-13.74,3.21-27.36,7.2-41.27,9.35
                c-10.59,1.63-16.73,8.89-24.07,14.91C605.6,831.62,605.58,832.44,605.38,833.04z M1002.41,817.51c5.32,8.09,71.23,7.84,76.37-0.24
                c-12.86,1.2-25.47,3.26-38.1,3.3C1028.02,820.61,1015.35,818.63,1002.41,817.51z M959.73,810.28c0.08-0.33,0.16-0.66,0.24-0.98
                c1.65,0,3.64,0.58,4.88-0.13c2.04-1.17,4.05-2.94,5.14-4.96c0.39-0.72-1.6-3.38-3.05-4.32c-3.16-2.06-6.66-3.59-10.04-5.32
                c-3.75-1.91-13.3,2.79-13.43,7c-0.06,1.78,1.54,4.64,3.08,5.25C950.75,808.48,955.31,809.19,959.73,810.28z M934.63,804.22
                c2.7-2.82,2.6-5.48-0.85-6.57c-4.21-1.33-8.67-2.1-13.07-2.48c-3.48-0.3-6.2,2.29-5.64,5.45c0.35,1.96,3.47,3.84,5.74,4.93
                c1.62,0.77,3.89,0.23,5.87,0.14c1.23-0.05,2.46-0.34,3.68-0.52c-0.37-1.37-0.73-2.74-1.45-5.46
                C931.18,801.5,932.74,802.74,934.63,804.22z M831.4,832.59c-0.13,0.54-0.26,1.07-0.39,1.61c8.77,2.04,17.55,4.07,26.32,6.11
                C852.24,834.37,837.22,830.65,831.4,832.59z M1101.59,840.45c-0.02-0.66-0.04-1.32-0.05-1.98c-7.99-1.61-30.57,0.7-34.03,4.3
                C1079.49,841.95,1090.54,841.2,1101.59,840.45z M739.14,859.04c-3.9-5.18-21.54-6.96-26.28-2.63
                C722.37,857.36,730.75,858.2,739.14,859.04z M983.2,795c-2.46,3.06-4.23,5.27-6.01,7.48c1.68,1.23,4,3.86,4.92,3.44
                c2.68-1.24,4.8-3.69,7.14-5.67C987.58,798.8,985.91,797.35,983.2,795z M1117.13,810.92c-3.52-1.99-17.71-1.18-20.02,1.17
                c-0.47,0.48-0.84,1.59-0.6,2.1c0.25,0.54,1.38,1.1,1.98,0.97C1104.68,813.83,1110.85,812.37,1117.13,810.92z M1131.64,836.86
                c-0.04-0.79-0.09-1.58-0.13-2.38c-2.97,0.39-5.93,0.79-8.9,1.18c0.08,0.64,0.16,1.27,0.24,1.91
                C1125.78,837.33,1128.71,837.09,1131.64,836.86z"/>
            <path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M303.29,350.04c-5.73-2.53-11.59-4.83-17.14-7.7c-2.18-1.13-3.69-3.55-5.51-5.38
                c1.72-1.42,3.37-2.96,5.2-4.22c1.63-1.12,3.4-2.17,5.25-2.8c1.24-0.42,2.88-0.42,4.12,0.01c3.23,1.13,6.32,2.66,9.51,3.91
                c4.45,1.75,8.84,3.8,13.44,4.97c4.39,1.12,9.02,1.31,13.56,1.86c5.85,0.71,11.76,1.06,17.54,2.11
                c10.88,1.99,21.79,4.11,30.21,12.27c0.22,0.21,0.6,0.29,0.93,0.35c13.21,2.41,26.41,4.91,39.65,7.15
                c3.02,0.51,6.07-0.09,6.48-3.51c-3-2.38-5.63-4.47-8.82-7.01c5.4-4.12,10.27-3.82,14.88-2.61c6.25,1.65,12.31,2.85,18.53,0.12
                c0.68-0.3,1.65-0.25,2.39-0.04c7.9,2.19,15.66,5.16,23.69,6.53c14.71,2.5,29.53,4.62,44.39,5.86c12.02,1,24.17,0.27,36.25,0.67
                c9.76,0.32,19.49,1.29,29.25,1.66c1.62,0.06,3.31-1.48,4.96-2.28c5.24-0.22,10.49-0.42,15.73-0.69c0.42-0.02,0.94-0.42,1.19-0.79
                c5.05-7.49,11.85-6.63,19.06-4.1c0.87,0.31,1.97,0.17,2.93,0.03c19.38-2.86,38.56-7.24,58.31-7.19c10.6,0.03,21.22-0.7,31.79-0.2
                c6.39,0.3,12.75,2.09,19.02,3.62c8.44,2.06,16.42,2.53,24.74-1.37c6.86-3.22,14.28-4.26,22.3-3.65
                c7.97,0.61,16.14-1.55,24.23-2.42c12.39-1.34,24.77-2.71,37.17-3.92c10.1-0.98,20.22-2.47,30.32-2.46
                c17.4,0.03,34.91-0.97,52.1,3.39c12.56,3.19,25.18,5.32,38.38,3.32c11.29-1.71,11.67-0.72,8.32,10c-1.57,5.04-5.8,8.5-12,8.47
                c-10.96-0.05-21.05,3.51-30.81,7.55c-8.6,3.56-17.11,4.65-26.24,4.6c-7.38-0.04-14.8,1.22-22.14,2.34
                c-15.04,2.29-30.04,4.86-45.05,7.34c-14.77,2.44-29.51,5.08-44.31,7.31c-10.19,1.54-20.69,1.57-30.61,4.06
                c-8.87,2.23-17.2,6.77-25.64,10.56c-4.08,1.83-7.7,4.85-11.89,6.23c-2.47,0.81-6.34,0.4-8.36-1.1c-4.67-3.47-9.47-3.68-14.66-2.7
                c-8,1.51-14.04-0.42-18.53-7.98c-2.8-4.71-7.6-7.95-13.89-7.17c-1.3,0.16-2.8,0.29-3.96-0.18c-7.28-2.92-12.6,0.88-17.5,5.26
                c-4.15,3.71-7.75,8.05-11.72,11.98c-0.96,0.95-2.42,1.93-3.67,1.95c-8.69,0.14-15.26,4.77-21.86,9.65
                c-2.43,1.8-5.35,3.17-8.25,4.05c-4.6,1.39-9.32,2.59-14.08,3.16c-6.04,0.73-12.17,0.96-18.26,1.02
                c-5.13,0.04-10.36-1.26-15.38-0.61c-20.29,2.63-40.11-0.98-60.08-3.44c-15.62-1.93-31.36-3.96-47.05-4
                c-17.25-0.04-33.22-4.67-49.32-9.6c-2.7-0.83-5.37-1.79-8.12-2.37c-18.2-3.85-36.49-7.32-54.61-11.52
                c-10.79-2.5-21.4-5.84-31.92-9.33c-4.5-1.49-8.43-4.65-12.84-6.5c-27.01-11.29-53.88-22.64-83.31-26.95
                c-26.48-3.87-52.68-9.71-79.01-14.68c-0.65-0.12-1.32-0.44-1.96-0.38c-13.5,1.28-25.76-4.44-38.58-6.95
                c-4.43-0.87-8.58-3.11-13.7-5.04c3.9-5.49,6.39-10.53,12.34-12.35c0.93-0.28,1.84-0.83,2.6-1.45c10.36-8.45,22.05-8.02,34.12-5.22
                c2.1,0.49,4.33,1.37,6.36,1.09c10.03-1.41,18.99,1.96,28.1,5.34c3.82,1.42,8.13,1.45,12.12,2.49c6.24,1.61,12.59,3.04,18.55,5.43
                c14.52,5.81,28.78,12.24,43.26,18.16c4.13,1.69,8.56,3.24,12.95,3.59c14.27,1.14,27.71,5.19,40.97,10.28
                c7.19,2.76,19.63,1.73,24.12-1.33C311.89,352.39,307.32,349.53,303.29,350.04z"/>
            <path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M1194.87,596.58c-1.67-4.62-3.17-8.76-4.66-12.89c-0.46,0.04-0.91,0.08-1.37,0.11
                c-0.14,1.79-0.73,3.69-0.32,5.35c1.22,5,2.4,10.09,4.43,14.78c1.67,3.85,1.42,5.89-2.84,6.84c-0.56,0.13-1.02,0.7-2.44,1.74
                c2.63,0.54,4.37,0.9,6.62,1.36c-1.34,2.54-2.21,5.12-3.83,7.07c-3.84,4.62-3.59,10.22-4.23,15.6c-0.15,1.22,0.27,2.62,0.8,3.77
                c0.25,0.53,1.62,1.1,2.06,0.86c0.68-0.37,1.49-1.49,1.39-2.16c-1.18-7.57,1.66-13.95,5.8-19.88c3.22-4.61,3.46-9.44,0.94-14.14
                c-2.18-4.07-0.51-5.62,3.13-6.08c9.55-1.19,19.11-2.33,28.7-3.14c4.46-0.38,9.02-0.3,13.46,0.25c1.94,0.24,4.79,1.81,5.32,3.4
                c2.21,6.72,3.7,13.67,5.23,19.64c-17.5-0.52-34.64-1.02-51.78-1.53c-0.18,0.9-0.36,1.81-0.54,2.71c1.98,0.57,3.98,1.64,5.95,1.62
                c10.28-0.11,20.56-0.73,30.84-0.67c4.16,0.02,8.31,1.41,12.49,1.97c5.48,0.73,6.84,4.68,7.41,9.21c0.46,3.63,0.42,7.35,1.1,10.93
                c0.7,3.68,1.55,7.46,3.15,10.81c2.6,5.43,1.96,14.73-2.19,19.14c-0.74,0.78-2.36,1.01-3.57,1.02c-3.48,0-6.96-0.3-10.45-0.35
                c-1.16-0.02-2.32,0.35-3.49,0.54c-0.01,0.51-0.01,1.01-0.02,1.52c4.08,0.37,8.16,0.78,12.25,1.11c7.61,0.63,7.55,0.66,7.35,8.4
                c-0.05,2.06,0.75,4.17,1.3,6.22c1.49,5.56,3.2,11.06,4.53,16.65c1.03,4.35,2.23,8.44,7.01,10.7c-1.35-5-2.71-10-4.06-15
                c0.48-0.29,0.95-0.59,1.43-0.88c7.14,4.1,14.29,8.21,21.43,12.31c0.39-0.54,0.77-1.09,1.15-1.63c-1.22-1.04-2.35-2.21-3.67-3.08
                c-5.41-3.59-10.8-7.22-16.34-10.6c-8.06-4.92-9.6-12.44-9.07-21.21c5.15-0.29,4.39,3.54,4.63,5.62c5.01,1.44,9.42,2.71,13.84,3.98
                c0.26-0.48,0.52-0.95,0.78-1.43c-5.57-4.09-10.86-8.69-16.83-12.06c-2.91-1.64-3.96-2.96-2.65-5.57
                c3.12-6.22,1.85-11.59-0.7-17.95c-2.79-6.98-2.92-15.01-4.37-23.53c6.98,2.9,12.69,5.09,18.18,7.71c1.15,0.55,1.95,2.61,2.21,4.09
                c1.19,6.88,1.94,13.83,3.25,20.68c0.74,3.88,2.26,7.62,3.43,11.42c2.49,8.1,5.1,16.16,7.45,24.3
                c4.89,16.92,9.85,33.83,14.39,50.85c5.59,20.94,8.53,42.36,10.6,63.91c0.41,4.29-1.96,4.22-4.87,3.88
                c-9.24-1.06-18.46-2.56-27.73-3.09c-12.77-0.73-25.59-0.82-38.39-0.95c-1.94-0.02-3.86,1.36-5.85,1.84
                c-1.82,0.44-4.14,1.37-5.51,0.67c-9.4-4.81-19.62-2.81-29.44-4.01c-6.08-0.74-12.22-1.03-18.26-1.99
                c-1.3-0.21-2.57-2.27-3.36-3.75c-1.88-3.52-4.03-7.06-5.03-10.86c-1.38-5.22-1.8-10.69-2.7-16.49c11,1.46,21.47,2.83,31.94,4.24
                c21.08,2.83,42.15,5.8,63.25,8.46c7.05,0.89,8.21,1.81,8.03,9.03c-0.07,2.59-0.01,5.17-0.01,7.76c0.64,0.04,1.28,0.07,1.92,0.11
                c0.5-3.68,1-7.35,1.67-12.22c9,3.58,17.53,6.97,26.52,10.55c-1.77-3.81-4.9-5.8-8.58-7.2c-4.67-1.76-9.36-3.46-14.01-5.26
                c-4.67-1.81-5.78-5.43-4.9-10.85c6.32,0.81,12.47,1.6,18.62,2.4c0.13-0.25,0.26-0.49,0.38-0.74c-0.96-0.54-1.85-1.32-2.88-1.58
                c-4.03-1.01-8.16-1.68-12.14-2.85c-2.08-0.61-3.82-2.46-5.9-2.96c-5.4-1.31-10.88-2.49-16.4-3.12c-2.69-0.3-5.53,0.76-8.3,1.2
                c0.05,0.6,0.09,1.2,0.14,1.81c4.55,0,9.1,0,13.64,0c0.1,0.34,0.19,0.68,0.29,1.01c-1,0.67-2,1.33-3.41,2.26
                c4.03,2.66,11.57-1.55,11.58,8.09c-6.3-0.29-12.66-0.14-18.9-0.97c-23.08-3.07-46.13-6.4-69.16-9.82
                c-4.07-0.61-8.21-1.7-11.93-3.42c-1.81-0.84-2.71-3.64-4.02-5.56c1.7-0.72,3.41-2.06,5.1-2.04c3.79,0.06,7.56,0.85,11.35,1.23
                c2.87,0.28,5.75,0.38,8.63,0.56c0.07-0.63,0.14-1.27,0.21-1.9c-7.59-1.05-15.16-2.38-22.78-3c-2.98-0.24-4.24-0.63-5.02-3.82
                c-3.31-13.65-6.87-27.24-10.67-40.75c-1.04-3.7-0.84-5.64,3.12-7.23c10.5-4.22,20.79-8.93,31.19-13.4
                c1.11-0.48,2.36-0.63,3.55-0.93c0.23,0.38,0.45,0.77,0.68,1.15c-1.32,1.06-2.64,2.12-3.97,3.19c0.08,0.31,0.16,0.87,0.26,0.88
                c7.88,0.31,8.14,7.01,9.09,12.04c1.57,8.3,1.73,16.85,2.8,25.26c0.86,6.71,2.22,13.36,3.31,20.05c0.51,3.14,0.92,6.3,1.37,9.45
                c-0.36,0.49-0.73,0.98-1.09,1.48c11.53,1.79,23.05,3.58,34.58,5.37c0.17-0.74,0.33-1.49,0.5-2.23c-1.23-0.36-2.44-0.86-3.7-1.06
                c-6.74-1.04-13.48-2.14-20.26-2.95c-3.46-0.41-5.09-2.13-6.31-5.33c-4.52-11.89-4.71-24.41-6.04-36.79
                c-0.71-6.57-1.76-13.14-3.24-19.58c-1.23-5.36-0.72-6.11,4.73-5.83c8.61,0.44,17.24,0.54,25.87,0.61
                c2.03,0.02,4.06-0.68,6.09-1.05c-0.02-0.48-0.05-0.95-0.07-1.43c-2.6,0-5.21,0.11-7.81-0.02c-9.78-0.5-19.57-0.95-29.31-1.84
                c-1.03-0.09-2.19-2.93-2.54-4.65c-0.18-0.92,1.24-2.16,0.6-2.76c-2.19-3.56-4.53-7.04-6.54-10.7c-2.73-4.96-5.22-10.06-8.67-14.73
                c-1.53,8.1,6.05,13.4,5.84,21.45c-10.23,4.65-21.39,7.49-33.09,8.34c-9.15,0.67-9.44,0.79-10.97-8.38
                c-2.71-16.23-4.96-32.53-7.54-48.78c-0.52-3.25-1.46-6.48-2.58-9.58c-2.5-6.9-0.42-13.39,9.19-13.66
                c7.48-0.21,14.95-0.71,22.41-1.16c1.08-0.06,2.14-0.52,3.24-1.88c-1.81-0.1-3.64-0.37-5.44-0.26c-9.25,0.56-18.49,1.27-27.74,1.74
                c-0.91,0.05-1.89-1.14-2.84-1.76c0.94-0.95,1.75-2.52,2.85-2.77c10.87-2.41,21.79-4.61,32.71-6.84c1.11-0.23,2.26-0.28,3.39-0.42
                c0.03-0.51,0.07-1.03,0.1-1.54c-2.17,0-4.4-0.33-6.5,0.06c-9.49,1.73-18.97,3.51-28.38,5.62c-3.34,0.75-4.76,0.3-5.45-3.32
                c-1.98-10.44-4.23-20.84-6.59-31.21c-1-4.36,1.06-6.11,4.82-6.13c10.14-0.03,20.28,0.06,30.41,0.44
                c4.88,0.19,9.67,0.89,13.68-2.83c0.43-0.4,1.22-0.6,1.84-0.6c8.24,0.04,16.5-0.12,24.73,0.27c9.3,0.44,18.57,1.38,27.85,2.13
                c1.15,0.09,2.67,0.01,3.36,0.68c9.67,9.44,24,13.51,30.52,26.5c1.47,2.92,2.94,5.95,3.66,9.11c1.37,5.99,2.35,12.08,3.23,18.17
                c0.7,4.87,1.03,9.79,1.7,16.45c-6.95-3.64-12.8-6.71-18.66-9.78c0.11-0.49,0.22-0.98,0.33-1.47c2.78-0.19,5.56-0.37,8.34-0.56
                c0.06-0.46,0.13-0.92,0.19-1.37c-2.92-0.47-5.82-1.2-8.75-1.33c-2.95-0.13-4.58-1.18-5.24-4.15c-1.19-5.31-2.64-10.55-4.03-16.04
                c5.75-1.9,6.79,4.62,11.38,5.19c-0.6-2.1-1.11-3.84-1.77-6.14c3.07-0.49,5.88-0.94,8.7-1.39c0.01-0.45,0.01-0.9,0.02-1.34
                c-2.99-0.15-5.98-0.23-8.96-0.46c-5.22-0.4-11,0.23-12.67-6.87c-0.12-0.5-1.34-1.14-1.94-1.04c-0.62,0.11-1.5,0.98-1.57,1.6
                c-0.16,1.45,0.09,2.95,0.2,4.95C1228.15,593.29,1211.53,591.57,1194.87,596.58z M1229.59,672.85c0.2-0.32,0.39-0.64,0.59-0.96
                c-0.42-0.62-0.7-1.51-1.29-1.82c-4.39-2.35-8.62-5.53-13.32-6.65c-9.98-2.37-20.18-4.2-30.39-5.18
                c-6.96-0.66-13.34-2.04-19.44-5.46c-3.13-1.75-6.75-2.67-10.23-3.7c-0.99-0.3-2.24,0.3-3.37,0.49c8,4.35,16.04,7.65,24.2,10.6
                c2.43,0.88,5.24,0.73,7.87,1.08c14.28,1.88,28.87,2.46,41.74,10.19C1227.05,672.08,1228.37,672.38,1229.59,672.85z
                M1279.98,731.37c-0.01-0.58-0.03-1.16-0.04-1.73c2.23-1.32,4.46-2.65,6.92-4.11c-2.01-1.98-4.59-1.33-6.37,0.73
                C1279.58,727.32,1280.11,729.63,1279.98,731.37z M1268.86,659.11c0.39,0.14,0.78,0.28,1.17,0.43c2.37-2.01,4.74-4.01,7.12-6.02
                c-0.31-0.56-0.63-1.12-0.94-1.68c-2.2,0.89-4.67,1.44-6.5,2.82C1268.81,655.33,1269.1,657.57,1268.86,659.11z M1301.4,786.17
                c0.1,0.61,0.2,1.21,0.3,1.82c3.19-0.53,6.38-1.05,9.58-1.58c-0.09-0.56-0.18-1.12-0.28-1.68
                C1307.58,783.82,1304.3,783.85,1301.4,786.17z M1286.48,742.74c0.17,0.69,0.34,1.38,0.5,2.07c1.31-0.33,2.65-0.6,3.91-1.06
                c0.11-0.04-0.23-1.3-0.36-2C1289.19,742.08,1287.83,742.41,1286.48,742.74z M1275.58,730.98c-0.69,0.04-1.37,0.08-2.06,0.12
                c0.19,1.76,0.38,3.51,0.56,5.27c0.65-0.06,1.3-0.13,1.95-0.19C1275.88,734.45,1275.73,732.71,1275.58,730.98z M1267.14,611.24
                c0.51-0.6,1.02-1.2,1.52-1.79c-1.37-0.96-2.75-1.92-4.12-2.88c-0.37,0.47-0.74,0.93-1.11,1.4
                C1264.66,609.06,1265.9,610.15,1267.14,611.24z M1191.02,651.41c-0.59-0.48-0.96-0.93-1.43-1.09c-0.18-0.06-0.89,0.66-0.83,0.83
                c0.17,0.48,0.58,0.93,1,1.24C1189.84,652.46,1190.39,651.91,1191.02,651.41z"/>
            <path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M1092.26,730.59c0.36,0.32,0.7,0.88,1.1,0.93c12.16,1.65,24.29,3.92,36.51,4.65
                c11.64,0.69,22.78,2.63,33.11,7.94c2.02,1.04,2.86,4.38,3.61,7.47c-25.3-3.51-49.43-12.3-74.91-9.05
                c-0.14,0.6-0.29,1.21-0.43,1.81c1.93,0.43,3.88,1.27,5.8,1.22c21.99-0.59,43.32,3.42,64.48,8.83c4.6,1.17,7.07,3.53,7.95,8.28
                c1.75,9.48,3.82,18.91,5.98,28.32c0.57,2.49,1.93,4.8,2.9,7.13c-7.61-0.97-15.49-1.89-23.33-3.07c-1.14-0.17-1.99-1.76-3.14-2.4
                c-1.02-0.57-2.28-1.1-3.4-1.03c-7.94,0.52-15.87,1.16-23.8,1.84c-6.54,0.56-8.7-0.85-11.33-7.83c19.66-2.3,39.12-0.19,58.56,3.04
                c0.18-0.87,0.36-1.74,0.54-2.6c-1.7-0.57-3.37-1.53-5.1-1.66c-14.56-1.05-29.18-3.09-43.7-2.53c-13.08,0.5-20.96-5.07-28.03-14.99
                c-7.43-10.41-9.62-21.04-6.3-33.36c0.98-3.62-0.37-7.85-0.58-11.8c-0.4-7.3-1.16-14.62-1-21.91c0.15-7.08,1.25-14.13,2.01-22.01
                c7.26,0,15.18,0,23.11,0c0.1-0.51,0.19-1.02,0.29-1.53c-1.55-0.5-3.07-1.31-4.64-1.43c-5.96-0.45-11.94-0.82-17.91-0.9
                c-3.04-0.04-5.2-0.78-6.07-3.9c-2.45-8.8-5-17.57-7.27-26.42c-0.35-1.38,0.56-3.09-0.19-4.18c-1.29-2.58-2.85-5.06-3.83-7.75
                c-2.91-7.96-5.57-16.02-8.39-24.01c-2.55-7.22-5.6-14.29-7.62-21.65c-0.8-2.9,0.17-6.52,1.08-9.59c1.97-6.69,4.41-13.24,6.7-19.83
                c0.43-1.25,1.29-2.39,1.56-3.66c1.03-4.88,1.47-10.07,8.93-11.03c-5.7-2.43-9.43-0.45-10.98,4.12
                c-3.15,9.24-6.51,18.41-10.92,27.87c-2.58-8.59-4.94-17.26-7.81-25.75c-1.79-5.3-3.31-11.21-6.77-15.29
                c-5.63-6.63-3.46-12.57,0.11-18.09c3.68-5.68,8.55-9.71,15.83-12.33c7.58-2.73,14.11-1.3,21.22-1.11
                c4.88,0.13,9.81-0.75,14.69-1.41c3.98-0.54,8.24-2.77,10.41,3.03c0.34,0.92,1.64,1.82,2.67,2.08c6.83,1.74,9.19,7.48,12,12.92
                c1.27,2.46,3.45,4.57,4.26,7.14c0.98,3.13,0.7,6.62,1.4,9.87c2.34,10.97,5.22,21.83,7.25,32.85c3,16.31,8.46,32.19,7.98,49.13
                c-0.13,4.54,1.57,9.13,2.45,13.69c1,5.18,2.02,10.35,3.31,16.91c-14.78-4.1-28.69-6.59-42.73-8.03c-0.2,0.68-0.39,1.35-0.59,2.03
                c1.41,0.72,2.76,1.83,4.25,2.08c13.2,2.21,26.42,4.26,40.96,6.57c-0.82,31.62,10.84,60.02,18.82,89.86
                c-11.79-4.95-23.89-5.92-36.06-6.52c-11.7-0.58-22.9-6.17-34.76-5.16C1092.4,729.12,1092.33,729.86,1092.26,730.59z
                M1064.39,585.59c20.15,2.02,40.31,4.03,60.32,6.03C1116.19,586.06,1070.35,581.41,1064.39,585.59z M1081.78,541.5
                c-0.02,0.66-0.03,1.32-0.05,1.97c2.53,0,5.07,0.15,7.58-0.03c5.93-0.43,10.92,0.86,15.31,5.44c3.02,3.15,7.19,5.24,10.99,7.57
                c0.97,0.59,2.48,0.31,3.74,0.42c0.15-0.44,0.29-0.88,0.44-1.33c-6.48-4.73-12.51-10.36-19.59-13.88
                C1094.7,538.93,1087.86,537.94,1081.78,541.5z M1114.75,570.06c-5.66-5.08-33.39-8.45-38.57-3.97
                C1089.39,567.44,1102.18,568.76,1114.75,570.06z M1092.87,548.85c-7.86-3.22-15.11-2.43-21.85,2.57
                c-2.73,2.02-5.27,3.96-3.74,9.35C1074.38,552.75,1082.4,548.14,1092.87,548.85z M1074.48,597.15c5.61-0.92,10.26-2.34,14.88-2.26
                c4.68,0.08,9.34,1.63,14.19,2.57C1098.25,589.12,1081.68,588.52,1074.48,597.15z M1085.34,524.19c0.48-0.06,1.12,0.05,1.39-0.22
                c2.95-2.92,5.72-5.34,7.42,1.03c0.12,0.46,1.44,1.01,1.98,0.84c0.56-0.18,1.35-1.54,1.18-1.79c-1.82-2.69-3.3-6.35-5.89-7.57
                C1088.72,515.21,1085.36,519.91,1085.34,524.19z M1143.68,683.7c0.07-0.47,0.14-0.94,0.21-1.41c-5.08-0.85-10.15-1.7-16.05-2.69
                C1131.54,684.69,1137.18,685.87,1143.68,683.7z M1090.63,692.8c0.03,0.98,0.06,1.96,0.09,2.94c4.32-0.3,8.64-0.6,12.95-0.89
                c-0.05-0.88-0.11-1.77-0.16-2.65C1099.22,692.39,1094.93,692.59,1090.63,692.8z M1119.89,563.25c0.24-0.35,0.49-0.71,0.73-1.06
                c-3.55-4.19-9.85-7.06-13.97-5.11C1111.06,559.14,1115.47,561.19,1119.89,563.25z"/>
            <path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M1072.31,249.17c-2.28-2.2-6.61-2.32-11.29-0.06c-3.64,1.76-7.05,3.96-10.57,5.97
                c-0.48-0.56-0.96-1.12-1.43-1.68c2.07-1.72,4.12-3.47,6.21-5.17c10.82-8.78,22.25-10.99,33.02-0.96c6.87,6.4,14.82,9.7,24.5,10.82
                c8.84,1.02,17.38,4.95,25.92,7.96c2.34,0.82,4.21,2.94,6.9,4.9c-2.3,0.88-3.71,1.42-5.11,1.96c-0.01,0.51-0.02,1.02-0.03,1.52
                c1.86,0.11,3.79-0.07,5.56,0.38c2.46,0.62,5.52,1.07,7.01,2.76c2.14,2.43,0.29,5.09-2.13,6.68c-3.69,2.41-3.43,5.54-1.57,8.66
                c3.51,5.89,3.54,12.05,3.11,18.71c-0.83,12.9,0.24,25.97-1.21,38.77c-2.36,20.79,5.93,40.41,5.34,60.87
                c-0.21,7.48-1.01,14.94-1.55,22.4c-0.54,7.62-1.36,15.23-1.51,22.86c-0.08,4.03-1.63,6.59-4.89,8.54c-3,1.79-6.24,3.33-8.81,5.62
                c-3.02,2.68-5.63,5.89-8.04,9.14c-2.87,3.87-5.6,7.89-7.93,12.09c-2.13,3.84-3.99,7.92-5.32,12.1c-1.06,3.32-2.27,3.82-4.79,1.63
                c-0.37-0.32-0.58-0.95-0.99-1.11c-12.73-4.77-5.08-16.09-7.47-24.15c-0.42-1.4,2.27-4.28,4.12-5.7
                c6.84-5.24,13.84-10.27,20.95-15.13c3.48-2.38,3.87-4.79,0.99-7.66c-1.39-1.39-3.05-2.56-4.74-3.57
                c-5.84-3.49-12.2-6.31-17.5-10.47c-4.08-3.21-6.74-8.18-10.29-12.14c-1.79-2-3.94-3.94-6.32-5.09c-2.96-1.43-6.26-2.45-9.52-2.87
                c-5.69-0.74-8.43-4.62-11.02-8.97c-1.53-2.57-3.3-5.01-4.86-7.56c-6.1-9.97-15.88-12.12-26.35-11.6c-11.71,0.58-11.52,1-11-10.56
                c0.23-5.19-0.64-10.43-0.45-15.62c0.15-4.25,0.6-8.64,1.85-12.68c2.44-7.91,1.21-15.22-1.17-22.94
                c-3.11-10.06-3.21-20.58,0.3-30.6c2.4-6.85,5.97-13.31,9.31-19.79c0.61-1.19,2.59-1.76,4.02-2.42c1-0.46,2.55-0.2,3.18-0.89
                c7.07-7.79,15.28-13.75,25.5-16.71c0.49-0.14,0.81-0.91,1.27-1.45c-5.38-2.06-13.2,1.84-29.2,14.43c0-2.26,0-4.26,0-4.95
                c2.63-1.88,4.49-3.13,6.26-4.49c6.1-4.69,11.35-10.95,20.06-10.85C1071.17,250.09,1071.71,249.52,1072.31,249.17z M1116.77,390.81
                c0.3,0.04,0.6,0.07,0.9,0.11c0,2.49-0.12,5,0.07,7.48c0.06,0.78,0.92,1.49,1.42,2.24c0.73-0.57,1.77-0.99,2.1-1.74
                c0.45-1.02,0.38-2.27,0.5-3.43c1.48-13.86,3.06-27.71,4.36-41.58c0.41-4.43,0.54-8.98-0.01-13.38
                c-1.29-10.39-3.21-20.69-1.14-31.23c0.3-1.54-0.91-3.37-1.42-5.07c-1,1.47-2.06,2.89-2.96,4.42c-0.31,0.53-0.33,1.3-0.29,1.95
                c0.53,9.3,1.25,18.59,1.6,27.9c0.22,5.95,0.42,11.97-0.25,17.86C1120.34,367.87,1118.43,379.32,1116.77,390.81z M1129.24,426.21
                c0.67-0.06,1.35-0.11,2.02-0.17c0-5.73-0.27-11.48,0.06-17.2c0.63-10.76,2.25-21.5,2.25-32.24c0-12.06-1.38-24.12-2.06-36.18
                c-0.22-3.98-0.04-7.98-0.23-11.96c-0.08-1.61-0.68-3.2-1.04-4.79c-0.57-0.01-1.14-0.01-1.71-0.02c-0.4,1.74-1.27,3.52-1.13,5.21
                c0.85,10.38,1.94,20.74,2.91,31.11c0.17,1.82,0.29,3.67,0.16,5.48c-0.74,10.28-1.74,20.55-2.31,30.85
                c-0.43,7.8-0.46,15.64-0.41,23.45C1127.79,421.9,1128.72,424.06,1129.24,426.21z M1070.68,299.48c-0.63-0.15-1.26-0.3-1.88-0.45
                c-0.94,1.78-2.47,3.47-2.74,5.35c-1.33,9.49-3.38,19.04-3.2,28.53c0.27,14.34-1.9,28.08-6,41.67c-0.47,1.56-0.71,3.27-0.64,4.89
                c0.03,0.68,1.04,1.55,1.8,1.86c0.35,0.14,1.58-0.76,1.71-1.35c2.36-10.35,5.3-20.62,6.74-31.11c2.06-15.01,3.02-30.18,4.4-45.28
                C1070.99,302.23,1070.75,300.85,1070.68,299.48z M1079.8,344.58c-0.69,0.13-1.37,0.25-2.06,0.38c0.31,3.18,0.34,6.43,0.99,9.54
                c2.9,14.08,6.12,28.09,8.91,42.19c1.03,5.18,1.14,10.53,1.81,15.79c0.37,2.88,1.62,4.97,5.02,4.25
                C1091.79,392.04,1086.93,368.07,1079.8,344.58z M1119.78,296.25c-0.05-0.44-0.11-0.88-0.16-1.32c-7.34-1.76-14.68-3.52-22.97-5.52
                c1.43-0.59,2.03-0.71,2.49-1.04c1.04-0.75,2.01-1.6,3-2.41c-1.1-0.52-2.15-1.21-3.3-1.54c-2.69-0.77-5.4-1.52-8.15-2
                c-2.02-0.36-4.11-0.28-6.18-0.39c-0.1,0.59-0.2,1.18-0.29,1.78c1.59,0.82,3.17,1.64,4.84,2.5c-1.17,1.11-2.15,2.05-3.37,3.2
                c3.07,0.73,5.7,1.23,8.25,1.97c6.44,1.88,12.8,4.09,19.31,5.69C1115.23,297.66,1117.59,296.61,1119.78,296.25z M1131.23,293.36
                c-0.05,0.86-0.1,1.72-0.15,2.58c5.38,0.67,10.76,1.35,16.13,2.02c0.34-0.76,0.68-1.52,1.03-2.28c-2.74-2.26-5.16-5.24-8.29-6.58
                c-3.54-1.52-7.72-2.18-11.61-2.1c-5.17,0.1-10.39,1.08-14.28,5.41c7.73-1.38,15.25-3.81,23.62,0.11
                C1134.93,292.88,1133.08,293.12,1131.23,293.36z M1134.68,271.82c0.04-0.65,0.09-1.29,0.13-1.94c-0.86-0.32-1.7-0.79-2.59-0.92
                c-12.25-1.91-24.46-4.3-36.78-5.39c-5.15-0.46-10.56,1.78-15.81,3c-0.79,0.18-1.32,1.47-1.97,2.25c0.13,0.55,0.25,1.1,0.38,1.65
                C1097.19,261.2,1115.83,270.52,1134.68,271.82z M1051.67,288.17c-8.58-1.85-16.75,3.44-17.52,11.58c3.21-0.38,6.61-0.03,9.22-1.3
                c2.38-1.16,3.98-4.02,5.8-6.24C1050.11,291.05,1050.76,289.66,1051.67,288.17z M1070.43,284.57c-0.01-0.7-0.03-1.39-0.04-2.09
                c-2.67-0.33-5.73-1.64-7.91-0.76c-2.84,1.15-4.98,4.03-7.42,6.17c2.15,2.33,3.91,5.53,6.57,6.77c3.05,1.42,5.77-0.58,6.97-4.45
                c-3.27-0.47-7.35,3.3-8.97-2.93C1063.23,286.39,1066.83,285.48,1070.43,284.57z M1058.65,301.34c-0.48-0.42-0.97-0.84-1.45-1.27
                c-3.15,3.44-6.49,6.72-9.33,10.4c-1.02,1.31-0.82,3.57-1.18,5.39c2.04,0.33,4.07,0.67,7.6,1.25
                C1049.12,308.8,1059.14,307.06,1058.65,301.34z M1094.18,307.01c-0.07,0.34-0.14,0.68-0.21,1.02c2.08,0.3,4.16,0.72,6.25,0.85
                c0.87,0.05,1.93-0.32,2.6-0.87c0.29-0.24,0.06-1.71-0.39-2.1c-0.64-0.55-1.78-0.99-2.56-0.82
                C1097.92,305.52,1096.07,306.34,1094.18,307.01z M1111.93,307.38c-0.96-0.16-1.92-0.32-2.87-0.47c-0.27,2.04-0.67,4.08-0.69,6.12
                c0,0.31,1.78,0.64,2.73,0.96C1111.38,311.78,1111.65,309.58,1111.93,307.38z M1133.14,304.43c0.63-0.5,1.26-1.01,1.9-1.51
                c-1.26-1.58-2.51-3.16-3.77-4.75c-0.64,0.6-1.89,1.65-1.82,1.75C1130.58,301.49,1131.88,302.95,1133.14,304.43z M1136.9,277.44
                c0.01-0.69,0.02-1.38,0.03-2.08c-2.51,0-5.02,0-7.52,0c-0.03,0.56-0.05,1.11-0.08,1.67
                C1131.86,277.17,1134.38,277.31,1136.9,277.44z M1088.67,251.05c-0.31-0.6-0.63-1.2-0.94-1.8c-2.05,1.12-4.11,2.23-6.16,3.35
                c0.27,0.52,0.55,1.04,0.82,1.56C1084.48,253.12,1086.57,252.09,1088.67,251.05z M1078.45,284.88c-0.1-0.68-0.21-1.36-0.31-2.04
                c-1.76,0.25-3.51,0.51-5.27,0.76c0.1,0.69,0.21,1.38,0.31,2.06C1074.94,285.4,1076.69,285.14,1078.45,284.88z M1072.01,330.28
                c0.13,0.66,0.27,1.32,0.4,1.98c1.87-0.35,3.74-0.7,5.61-1.04c-0.15-0.74-0.3-1.48-0.45-2.22
                C1075.71,329.42,1073.86,329.85,1072.01,330.28z M1058.31,318.85c0.84-1.14,1.89-2.19,1.74-2.41c-0.58-0.86-1.49-1.61-2.43-2.03
                c-0.25-0.11-1.67,1.3-1.54,1.63C1056.42,316.95,1057.3,317.66,1058.31,318.85z M1144.91,302.79c0.09-0.61,0.18-1.21,0.26-1.82
                c-1.33-0.26-2.66-0.52-3.99-0.77c-0.09,0.73-0.18,1.45-0.27,2.18C1142.24,302.52,1143.58,302.66,1144.91,302.79z M1084.99,304.14
                c-1.06-0.47-1.56-0.84-2.1-0.88c-0.28-0.02-0.91,0.73-0.86,0.8c0.3,0.48,0.69,1.1,1.16,1.2
                C1083.55,305.34,1084.06,304.74,1084.99,304.14z M1052.3,293.73c-0.26-0.1-0.57-0.34-0.76-0.27c-0.18,0.07-0.24,0.47-0.36,0.72
                c0.26,0.1,0.57,0.35,0.75,0.28C1052.12,294.37,1052.18,293.98,1052.3,293.73z"/>
            <path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M679.01,616.51c-1.35,5.3-2.68,10.2-3.83,15.16c-2.65,11.34-4.91,22.78-7.94,34.01
                c-1.27,4.71-4.01,9.06-6.29,13.46c-1.12,2.15-2.58,2.86-5.26,1.33c-2.5-1.43-5.67-1.86-8.61-2.24c-1.16-0.15-2.5,1.01-3.76,1.57
                c0.67,1.01,1.15,2.27,2.06,2.97c1.82,1.4,3.87,2.5,5.99,3.82c-3.33,3.38-6.79,6.46-9.73,9.98c-4.88,5.84-9.22,12.14-14.21,17.88
                c-3.32,3.82-7.95,6.55-10.97,10.55c-3.97,5.24-7.52,10.98-10.14,16.99c-2.13,4.88-1.08,10.16,2.92,13.69
                c-16.91,5.05-33.47,10-51.16,15.29c0.58-3.48,0.68-6.56,1.65-9.35c1.88-5.37,1.84-10.06-2.74-14.1
                c-5.52-4.87-3.94-12.61-9.09-18.84c11.32-0.69,21,3.74,30.41-0.19c0.02-0.45,0.05-0.89,0.07-1.34c-9.8-0.74-19.6-1.48-30.01-2.27
                c-1.7-4.43-3.56-9.36-5.48-14.27c-1.68-4.29-2.89-8.71-7.66-11.17c-1.61-0.83-2.35-3.9-2.92-6.1c-1.21-4.66-1.84-9.47-3.13-14.1
                c-0.51-1.81-2.14-3.31-3.26-4.95c-0.37,1.73-0.78,3.46-1.09,5.2c-0.12,0.64-0.02,1.31-0.02,2.51c-2.46-0.48-4.83-1.32-7.18-1.29
                c-2.24,0.03-4.44,1.29-6.7,1.43c-2.84,0.17-5.71-0.24-8.57-0.41c0.24-0.54,0.47-1.09,0.71-1.63c-1.06-1.1-2-2.94-3.2-3.14
                c-3.25-0.54-6.62-0.54-9.93-0.39c-1.02,0.05-1.99,1.27-2.98,1.95c1.01,0.61,1.99,1.29,3.04,1.81c1.49,0.74,3.05,1.32,4.54,2.04
                c1.17,0.56,2.23,1.55,3.45,1.81c6.24,1.33,12.51,2.48,19.78,3.88c-0.86-2.06-1.23-2.94-1.71-4.09c3.32,0.45,6.38,0.87,10.18,1.38
                c4.19,16.59,8.44,33.39,12.92,51.1c-11.08-1.74-19.46-4.78-24.59-13.6c-2.13-3.66-1.44-5.67,2.76-6.25
                c2.59-0.35,5.28,0.21,7.93,0.21c2.15,0,4.31-0.22,6.52-1.35c-4.32-3.16-9.24-2.76-13.92-2.28c-7.61,0.79-7.87,0.88-9.98-6.41
                c-0.6-2.07-0.9-4.23-1.51-6.31c-1.7-5.85-4.54-10.52-11-12.45c-2.49-0.74-4.83-2.89-6.62-4.93c-4.63-5.27-8.91-10.85-14.05-17.18
                c5.69-0.7,10.06-1.24,14.29-1.76c-0.63,1.69-1.18,3.14-1.72,4.59c1.55,0.13,3.14,0.55,4.64,0.35c4.1-0.56,8.17-1.34,12.24-2.11
                c1.55-0.29,3.06-0.79,4.59-1.19c0-0.47-0.01-0.94-0.01-1.41c-2.08-0.34-4.17-0.67-6.3-1.02c4.31-0.36,8.63-0.71,12.94-1.07
                c0.1-0.47,0.19-0.93,0.29-1.4c-1.42-0.36-2.87-1.12-4.27-1.03c-11.45,0.68-22.91,1.29-34.31,2.43c-4.8,0.48-7.72-0.56-10.03-5.1
                c-1.65-3.24-4.84-5.69-7.98-9.22c6.07-3.23,8.75-11.15,18.17-11.6c15.23-0.72,30.35-3.5,45.58-4.54
                c14.37-0.98,28.62,0.47,41.35,8.14c2.12,1.28,3.85,4.22,4.51,6.72c2.15,8.13,3.79,16.4,5.47,24.65c0.68,3.35,1.94,5.79,5.58,6.85
                c2.56,0.75,4.87,2.35,8.19,4.03c0-2.28,0.28-4.02-0.05-5.63c-2.11-10.33-4.32-20.65-6.57-30.95c-0.67-3.06-1.38-6.13-2.39-9.09
                c-2.03-5.97-0.45-11.78,4.33-14.49c1.11-0.63,3.05-1.51,3.64-1.05c4.05,3.19,7.88,0.84,11.97,0.34c4.18-0.52,8.57,0.3,12.83,0.79
                c5.33,0.61,10.26,0.39,15.56-1.64c6.11-2.34,12.99-2.61,19.48-3.99C657.05,614.33,667.4,614.37,679.01,616.51z M632.04,663.04
                c0.11,0.63,0.23,1.27,0.34,1.9c1.76-0.17,3.75,0.14,5.23-0.59c4.41-2.19,8.71-4.64,12.86-7.29c1.9-1.21,4.9-3.46,4.64-4.66
                c-0.43-2.08-2.68-4.35-4.75-5.43c-8.58-4.46-18.03-3.87-27.31-3.86c-1.86,0-3.72,0.95-5.58,1.47c0.05,0.45,0.1,0.91,0.15,1.36
                c1.2,0.38,2.44,1.19,3.58,1.06c4.61-0.53,8.19,0.8,11.47,4.25c1.8,1.89,4.64,3.74,8.1,1.17c-2.27-1.45-4.19-2.67-6.11-3.9
                c0.25-0.55,0.51-1.11,0.76-1.66c5.2,1.57,10.41,3.15,15.61,4.72c-0.07,0.43-0.13,0.87-0.2,1.3
                C644.57,656.27,638.3,659.65,632.04,663.04z M600.19,731.64c0.23-3.22,1.25-6.75,0.58-9.94c-2.16-10.33-4.93-20.53-7.49-30.78
                c-0.16-0.63-0.41-1.66-0.77-1.73c-0.91-0.18-2.7-0.06-2.75,0.2c-0.54,2.94-1.04,5.92-1.11,8.9c-0.07,2.9,0.84,5.86,0.51,8.7
                c-0.63,5.38,0.09,10.17,3.63,14.47C595.16,724.35,597.2,727.5,600.19,731.64z M601.71,677.98c12.53,2.8,24.65,9.36,37.44-1.01
                c-12.06-1.44-23.47-2.8-35.16-4.19C603.3,674.34,602.59,675.97,601.71,677.98z M516.63,654.05c3,3.71,2.81,8.57,7.8,10.15
                C525.48,657.06,523.36,653.9,516.63,654.05z M542.1,691.96c3.72,6.27,9.54,6.72,12.81,1.89
                C550.45,693.2,546.28,692.58,542.1,691.96z M566.59,720.64c0.02-0.59,0.04-1.18,0.07-1.77c-5.39-2.93-10.91-2.94-13.97,0.7
                C557.71,719.96,562.15,720.3,566.59,720.64z M576.07,721.98c4.88,0,9.11,0,14.14,0C586.98,718.6,580.39,718.81,576.07,721.98z
                M541.9,666.13c0.05,0.58,0.1,1.15,0.15,1.73c3.3,2.36,5.57-0.23,8.2-1.14c-0.11-0.62-0.23-1.24-0.34-1.86
                C547.24,665.29,544.57,665.71,541.9,666.13z M534.16,666.29c-0.24-0.77-0.48-1.55-0.72-2.32c-1.79,0.62-3.57,1.23-5.36,1.85
                c0.2,0.67,0.4,1.34,0.61,2C530.51,667.32,532.34,666.81,534.16,666.29z"/>
            <path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M899.95,555.07c9.42-3.61,18.6-8.19,28.32-10.62c18.71-4.68,37.71-8.16,56.6-12.08
                c2.78-0.58,5.61-0.86,8.42-1.28c-0.02-0.46-0.03-0.91-0.05-1.37c-1.17-0.21-2.42-0.8-3.5-0.57c-24.09,4.96-48.39,9.15-72.17,15.33
                c-15.85,4.12-30.87,11.37-46.45,16.66c-9.8,3.33-19.86,4.3-30.39,2.35c-18.95-3.51-38.04-6.39-57.15-8.89
                c-9.7-1.27-19.59-1.11-29.4-1.63c-0.91-0.05-1.8-0.31-2.7-0.47c-0.01-0.48-0.02-0.97-0.02-1.45c15.23-0.5,30.46-1,45.69-1.5
                c-0.01-0.94-0.03-1.88-0.04-2.83c-3.82,0-7.63-0.03-11.45,0.01c-7.15,0.07-14.3,0.11-21.45,0.31c-7.07,0.19-14.12-0.25-20.35,4.8
                c-1.96,1.59-6.08,0.67-9.21,0.6c-6.04-0.14-12.08-0.5-18.89-0.81c-0.02-0.06-0.38-1.3-0.95-3.27
                c-6.59,7.29-12.73,14.07-19.84,21.93c0-2.84-0.19-4.07,0.03-5.23c3.57-19.14,13.53-33.45,30.95-42.79
                c7.4-3.97,15.34-3.72,23.03-5.3c5.15-1.05,10.5-1.09,15.75-1.68c0.69-0.08,1.32-0.73,2.31-1.31c-2.77-3.4-5.93-1.7-8.84-1.6
                c-3.12,0.11-6.25,0.02-9.66-0.71c2.56-0.81,5.08-2.14,7.68-2.32c7.42-0.51,14.91-1.11,22.3-0.62c7.23,0.48,14.36,2.4,21.57,3.42
                c8.16,1.15,16.34,2.54,24.55,2.86c16.89,0.66,33.8,0.7,50.7,0.95c3.16,0.05,6.66-0.98,9.37,0.1c2.17,0.86,3.37,4.17,5.23,6.71
                c0.57-0.79,1.58-1.56,1.54-2.26c-0.3-4.9,2.51-5.53,6.55-5.67c8.81-0.31,17.61-1,26.4-1.66c2.01-0.15,3.98-0.81,5.82-2.3
                c-11.65,0-23.3,0-36.72,0c2.39-2.07,3.67-3.48,5.24-4.42c0.91-0.54,2.28-0.45,3.43-0.39c14.85,0.74,28.98-3.31,43.22-6.59
                c4.32-1,6.64-0.53,10.2,3.5c5.65,6.38,14.25,9.55,23.17,10.22c4.92,0.37,10.11,1.09,12.92-4.81c-5.9-7.53-13.86-4.89-21.13-5.38
                c-0.17,0.64-0.34,1.27-0.51,1.91c4.96,1.1,9.91,2.19,14.87,3.29c-0.1,0.5-0.2,1-0.3,1.5c-4.52-0.71-9.42-0.57-13.46-2.36
                c-3.94-1.75-7.24-5.23-10.38-8.39c-2.6-2.62-5.06-3.63-8.83-3c-16.38,2.74-32.78,5.41-49.23,7.63
                c-14.71,1.99-29.51,3.35-44.26,5.04c-0.91,0.1-1.96,0.43-2.64,1c-3.6,3.07-5.15,2.54-8.14-0.97c-1.96-2.3-4.99-3.95-7.84-5.19
                c-7.31-3.17-14.78-5.95-23.46-9.39c9.02-7.29,17.55-10.79,27.09-12.04c3.44-0.45,6.93-1.51,10.32-1.26
                c6.55,0.48,13.08,1.46,19.55,2.65c7.64,1.4,15.02,3.21,21.69,7.92c6.14,4.34,8.36,2.72,11.87-3.71
                c2.89-5.29,5.81-11.13,13.37-11.74c4.26-0.34,8.42-1.84,12.65-2.69c5.28-1.06,10.36-1.15,15.64,0.94
                c4.7,1.86,10.08,1.96,14.81,3.75c4.38,1.66,8.76,3.99,12.34,6.99c10.7,8.95,21.21,18.14,29.36,29.68c1.2,1.7,3.71,2.64,5.77,3.55
                c3.64,1.62,8.02,2.18,10.98,4.56c5.79,4.66,10.82,10.27,16.17,15.49c-0.35,0.4-0.69,0.81-1.04,1.21c-3.38,0-6.76-0.14-10.13,0.02
                c-23.74,1.14-47.43,2.91-70.96,6.39c-13.31,1.97-26.62,3.94-39.91,6.05c-5.39,0.86-10.73,2.1-16.09,3.17
                C899.89,555,899.95,555.07,899.95,555.07z M716.11,546.52c0.47,0.21,0.94,0.41,1.41,0.62c2.75-5.94,5.5-11.87,8.57-18.52
                C720.23,530.55,715.08,540.7,716.11,546.52z M784.67,516.38c-3.14-5.56-5.54-5.7-9.65-1.13
                C778.62,515.68,781.64,516.03,784.67,516.38z"/>
            <path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M1237.77,311.8c1.54,3.66,4.03,7.21,4.42,10.99c1.09,10.44,8.52,17.71,12.15,26.75
                c4.82,12.02,8.39,24.56,12.29,36.94c1.6,5.07,2.68,10.3,3.97,15.47c3.78,15.14,7.05,30.44,11.54,45.37
                c2.23,7.43,1.73,14.71,2.03,22.09c0.06,1.39-0.33,2.79-0.43,3.53c-12.59-5.61-24.83-11.06-37.45-16.69
                c-0.01-2.38,5.74-4.45,2.15-8.69c-9.48,12.59-23.12,9.01-35.36,8.99c-14.17-0.03-28.3-3.1-42.45,1.11
                c-7.07,2.11-9.24,0.44-8.8-6.87c0.63-10.4,2.2-20.74,3.35-31.1c0.27-2.47,0.76-4.97,0.6-7.43c-0.88-13.44-1.7-26.89-3.03-40.29
                c-0.67-6.72-2.42-13.33-3.63-20c-1.39-7.7-0.71-15.16,2.12-22.52c0.78-2.04,0.82-4.66,0.38-6.84
                c-2.47-12.21,6.38-17.48,15.2-18.15c6.38-0.49,12.89,0.63,19.34,1.03c4.79,0.3,9.59,0.59,14.38,0.89
                c0.06-0.66,0.12-1.32,0.18-1.99c-9.53-1.28-19.05-2.56-28.58-3.85c0.07-0.54,0.14-1.08,0.21-1.62c14.63,0,28.87,3.97,43.27,6.27
                c0.15-0.69,0.29-1.39,0.44-2.08c-5.69-1.43-11.33-3.1-17.08-4.22c-6.03-1.17-12.15-2.2-18.27-2.56
                c-5.66-0.33-11.39,0.31-17.08,0.52c-0.07-0.57-0.15-1.15-0.22-1.72c2.06-0.9,4.03-2.16,6.18-2.66c13.42-3.1,26.72-5.78,38.9,4.28
                c2.27,1.88,6.37,1.44,9.55,2.36c4.23,1.22,8.71,2.14,12.47,4.27c2.94,1.67,1.25,6.24-2.81,8.53c-4.65-1.52-9.3-3.04-13.95-4.56
                C1225.97,312.4,1232.23,313.71,1237.77,311.8z M1226.45,388c-9.69,1.91-20.06-2.01-29.49,6.05c9.53,1.55,17.69,2.26,25.64-1.56
                c0.97,1.67,1.51,3.14,2.5,4.19c6.44,6.91,12.41,14.04,14.28,23.73c0.13,0.7,1.73,1.11,2.64,1.65c0.43-0.93,1.39-1.99,1.2-2.77
                c-0.53-2.21-1.25-4.49-2.41-6.42c-3.74-6.23-7.71-12.32-11.82-18.81c4.58-1.22,4.34-3.49,1.45-6.6c-1.18-1.27-1.5-3.3-2.38-4.9
                c-0.85-1.54-1.91-2.97-2.87-4.45c-0.37,0.19-0.75,0.38-1.12,0.57C1224.87,381.78,1225.66,384.89,1226.45,388z M1189.7,403.49
                c0.49,0.14,0.99,0.27,1.48,0.41c7.35-5.71,7.3-14.34-0.25-19.03c-4.3-2.67-8.13-5.87-7.94-11.21c0.36-10.08,1.4-20.13,2.04-30.2
                c0.09-1.42-0.5-2.89-0.77-4.33c-0.96,1.14-2.38,2.13-2.8,3.44c-2.25,7.15-2.16,14.57-2.78,21.98
                c-0.92,11.04,0.46,19.83,11.28,25.02c2.61,1.25,2.75,3.15,1.81,5.72C1190.82,397.92,1190.37,400.74,1189.7,403.49z
                M1186.24,453.26c0.51-0.19,1.01-0.38,1.52-0.57c0.11-0.79,0.33-1.59,0.3-2.37c-0.06-1.66-0.19-3.32-0.41-4.96
                c-1.74-12.51-5.24-25.02-0.02-37.56c0.28-0.68-0.35-1.73-0.56-2.61c-1.12,0.44-2.96,0.61-3.23,1.36
                c-1.23,3.39-2.32,6.89-2.9,10.44c-1.74,10.73,1.79,21,3.21,31.44C1184.38,450.1,1185.52,451.65,1186.24,453.26z M1242.42,428.59
                c-0.4,0.48-0.79,0.96-1.19,1.43c1.15,1.28,2.26,3.61,3.46,3.66c4.77,0.19,9.57-0.34,14.82-0.63
                C1254.99,425.05,1248.01,429.77,1242.42,428.59z M1219.02,349.58c5.05-12.56,3.9-18.65-2.58-18.99c0.86,3.09,2.06,6.12,2.49,9.25
                C1219.35,343.04,1219.02,346.33,1219.02,349.58z M1243.23,440.71c-2.53,1.35-6.12,8.44-5.04,10.53c0.35,0.68,2.26,1.29,2.93,0.94
                C1243.33,451.03,1244.54,443.5,1243.23,440.71z M1228.12,371.62c2.89-0.49,4.15-0.65,5.38-0.92c1.25-0.27,2.48-0.65,3.72-0.98
                c-0.55-1.29-0.76-3.14-1.73-3.73c-1.17-0.72-3.56-1.03-4.4-0.32C1229.81,366.76,1229.4,368.88,1228.12,371.62z M1259.1,440.86
                c-2.9-6.52-7.81-7.81-11.94-3.72C1251.01,438.34,1254.54,439.44,1259.1,440.86z M1266,447.33c-2.85-1.43-4.78-2.41-7.35-3.7
                C1259.93,449.26,1260.73,449.68,1266,447.33z M1247.17,371.77c0.04-0.78,0.08-1.56,0.12-2.33c-2.31,0-4.61,0-6.92,0
                c-0.02,0.57-0.05,1.14-0.07,1.71C1242.59,371.35,1244.88,371.56,1247.17,371.77z"/>
            <path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M479.13,679.07c8.98,5.88,11.65,14.71,11.89,24.84c0.17,7.1,5.85,10.82,10.21,15.24
                c0.63,0.64,2.21,0.34,3.98-0.16c-7.15-7.26-12.38-14.94-10.34-26.12c5.24,1.88,6.82,6.02,7.7,10.35c1.22,6,1.62,12.22,8.04,15.58
                c0.86,0.45,1.73,2.97,1.33,3.42c-3.76,4.27,0.07,6.19,2.82,8.42c-3.7,1.64-6.97,3.15-10.28,4.54
                c-9.81,4.11-13.08,12.57-14.45,22.12c-0.17,1.18,1.37,2.87,2.5,3.92c2.26,2.09,4.78,3.9,6.97,5.65c-3.66,4.61-6.69,9-10.35,12.78
                c-1.86,1.92-4.81,3.77-7.35,3.88c-11.46,0.48-22.95,0.37-34.43,0.34c-1.57,0-3.47-0.35-4.67-1.26
                c-12.47-9.49-26.8-10.75-41.73-9.99c-11.16,0.57-9.4,2.1-13.2-9.23c-2.74-8.16-6.16-16.09-9.9-25.73
                c4.29,1.13,7.33,1.84,10.31,2.75c3.02,0.92,5.98,2.04,8.96,3.08c0.31-0.68,0.61-1.36,0.92-2.04c-4.14-1.93-8.27-3.88-12.43-5.76
                c-0.74-0.33-1.61-0.41-2.43-0.53c-5.17-0.75-9.82-2-12.49-7.39c-1.18-2.39-3.54-4.59-5.9-5.9c-3.77-2.08-3.83-4.68-3.78-8.61
                c0.18-12.69,10.32-16.33,18.53-21.81c2.93-1.96,5.91-3.97,9.15-5.27c1.61-0.65,3.87,0.14,5.8,0.49c5.64,1.04,11.21,2.48,16.89,3.2
                c4.51,0.57,9.62-4.29,9.83-8.8c0.04-0.78-0.61-1.76-1.23-2.32c-0.23-0.2-1.43,0.43-1.99,0.91c-2.26,1.92-4.15,4.99-6.71,5.71
                c-6.21,1.75-12.07-0.26-17.72-4.23c2.05-3.97,3.66-8.24,6.32-11.7c1.2-1.57,4.44-1.87,6.82-2.14c1.68-0.19,3.48,0.62,5.22,0.99
                c-0.77-1.81-1.55-3.62-2.86-6.71c4.53-2.06,9.32-5.38,14.56-6.38c9.57-1.82,19.38-2.71,29.13-3.14c6.65-0.3,9.63,4.89,11.88,10.61
                c-5.84,6.03-12.62,3.12-19.1,2.41c-4.59-0.5-9.18-1.04-13.77-1.56c-0.18,0.84-0.36,1.67-0.54,2.51c1.78,0.54,3.52,1.29,5.33,1.57
                c8.67,1.36,17.39,2.41,26.01,4.04c1.8,0.34,3.81,2.37,4.73,4.13c2.77,5.35,7.7,6.87,11.95,3.21L479.13,679.07z M491.59,713.49
                c0.01-0.93,0.02-1.85,0.03-2.78c-21.21,0.15-42.28-3.34-63.51-2.44c-0.04,0.9-0.07,1.8-0.11,2.69
                C449.24,710.79,470.29,714.97,491.59,713.49z M405.17,742.36c-0.04,0.64-0.08,1.28-0.12,1.92c7.41,0,14.83,0,22.24,0
                c0.01-0.63,0.02-1.26,0.04-1.9C420,740.53,412.6,740.73,405.17,742.36z M395.55,706.01c-4.83-3.66-18.62-1.53-22.23,3.54
                C380.72,708.37,388.14,707.19,395.55,706.01z M414.84,675.25c-0.07-0.56-0.14-1.13-0.21-1.69c-4.58-2.23-12.72-0.37-16.56,4.89
                C404.45,677.23,409.65,676.24,414.84,675.25z M439.69,744.68c4.66,3.27,7.21,2.56,9.6-2.51
                C445.54,743.15,442.62,743.91,439.69,744.68z"/>
            <path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M1027.48,901.05c-3.18,0.67-6.24,1.36-9.32,1.96c-4.7,0.92-8.14,3.24-10.4,7.69
                c-0.75,1.48-3.34,3.15-4.85,2.95c-7.09-0.96-12.32,2-16.78,6.92c-2.94,3.24-6.17,4.43-10.66,3.73c-3.34-0.52-7.19-0.36-10.29,0.85
                c-3.96,1.54-7.1,0.66-10.37-1.07c-12.4-6.54-25.54-7.56-39.27-6.16c-7.36,0.75-14.92,0.69-22.27-0.09
                c-3.22-0.34-6.4-3.07-9.16-5.27c-4.82-3.85-8.15-10.05-17.34-8.44c1.88-4.53,2.81-8.01,4.63-10.93c3.7-5.92,7.92-11.46,8.74-18.76
                c0.84-7.56,6.47-12.53,10.52-18.33c1.6-2.29,3.21-4.57,4.81-6.87c7-10.03,14.24-19.7,27.93-20.77c9.07-0.71,18.14-1.49,27.19-2.43
                c4.59-0.47,9.14-1.91,13.71-1.9c6.35,0.02,12.91,0.22,18.98,1.86c3.44,0.93,7.3,4.31,8.82,7.58c4.51,9.76,11.28,16.63,20.64,21.81
                c2.48,1.37,3.61,6.47,4.03,10.01c0.67,5.58,2.55,10.19,6.53,14.1c0.84,0.83,1.31,2.26,1.51,3.48
                C1025.77,888.98,1026.59,894.99,1027.48,901.05z M979.05,844.75c0.09-0.57,0.17-1.14,0.26-1.71c-2.59-1.46-5.05-3.75-7.8-4.18
                c-4.47-0.7-9.16-0.56-13.69-0.08c-3.53,0.38-6.44,5.34-6.21,9.29c0.31,0.07,0.66,0.26,0.93,0.18c0.46-0.13,1.1-0.33,1.26-0.68
                c3.24-7.08,9.38-5.32,15.02-4.93c2.5,0.18,4.92,0.54,5.19,4.07c0.06,0.83,2.46,2.41,3.21,2.13c6.63-2.48,6.93,2.34,8.26,6.89
                c-4.68,1.23-9.12,2.4-13.56,3.56c0.16,0.79,0.31,1.58,0.47,2.38c2.4-0.32,4.79-0.69,7.2-0.94c7.02-0.72,14.76-5.45,20.11,3.59
                c0.32,0.54,1.97,0.84,2.57,0.49c0.55-0.32,0.88-1.73,0.7-2.52c-1.03-4.33-4.48-6.13-8.34-6.87c-3.37-0.65-5.29-2.41-6.28-5.5
                C986.9,845.41,983.99,843.38,979.05,844.75z M1006.37,896.23c4.55-3.69,6.5-15.16,3.64-18.78c-0.83-1.05-2.44-1.92-3.77-2.04
                c-4.38-0.39-8.8-0.59-13.2-0.5c-5.96,0.13-11.95,0.29-17.84,1.06c-3.11,0.41-6.2,1.89-8.96,3.48c-0.7,0.4-0.12,3.05-0.12,4.66
                c1.29-0.58,2.62-1.1,3.87-1.77c1.75-0.93,3.36-2.58,5.18-2.85c4.03-0.59,8.17-0.71,12.26-0.61c2.55,0.06,5.07,0.96,7.62,1.34
                c1.53,0.23,3.25,0.62,4.64,0.16c5.75-1.88,7.07-1.08,6.99,4.97c-0.03,1.98-0.6,3.95-0.7,5.94
                C1005.91,892.76,1006.2,894.23,1006.37,896.23z M930.97,853.4c-5.2-6.47-17.86-6.53-22.41-1.23c-1.21,1.4-1.8,4.66-1,6.19
                c1.78,3.4,0.26,5.27-1.28,8.04c-1.92,3.44-3.27,7.54-3.57,11.46c-0.29,3.89,0.96,7.91,1.69,11.85c0.15,0.81,0.98,1.49,1.5,2.24
                c0.69-0.57,1.93-1.11,1.97-1.73c0.27-3.78,0.99-7.74,0.17-11.33c-1-4.34-0.64-7.85,2.44-10.95c2.67-2.68,3.19-5.43,1.74-9.05
                c-0.57-1.43,0.09-4.67,1.16-5.22c4.06-2.12,8.4-4.03,13.07-0.85C927.57,853.57,929.44,853.23,930.97,853.4z M940.99,865.21
                c0.34,5.64,2.78,7.74,9.18,7.87C946.8,870.18,944.35,868.08,940.99,865.21z"/>
            <path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M731.44,418.12c6.17-4.6,11.98-9.53,18.39-13.49c4-2.48,8.8-4.03,13.44-5.02
                c4.63-0.98,9.57-0.4,14.28-1.08c23.65-3.42,47.22-7.39,70.92-10.37c8.78-1.1,17.49-1.15,25.31-6.16c1.75-1.12,4.76-1.46,6.75-0.81
                c4.86,1.58,9.05-0.37,13.6-1.12c6.14-1.02,12.51-0.64,18.75-1.2c6.23-0.56,12.66-0.72,18.56-2.52c8.2-2.49,15.91-6.52,23.97-9.52
                c2.61-0.97,5.66-0.75,7.64-0.98c-1.63,9.01-2.86,17.48-4.75,25.8c-1.97,8.68-4.35,17.3-7.06,25.77c-0.77,2.41-2.93,4.89-5.12,6.24
                c-10.8,6.67-22.73,10.06-35.31,11.5c-11.34,1.3-22.7,2.6-33.95,4.5c-11.25,1.91-22.26,0.64-33.18-1.59
                c-10.7-2.18-21-3.7-30.54,3.66c-1.32,1.02-3.84,0.73-5.78,0.62c-9.77-0.55-19.53-1.5-29.3-1.75c-3.16-0.08-6.4,1.44-9.54,2.43
                c-8.66,2.74-13.88,0.88-19.66-6.04C743.43,430.51,737.4,424.53,731.44,418.12z"/>
            <path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M1092.75,773.9c-8.26,0-16.52,0.01-24.78,0c-20.79-0.04-41.6-0.75-62.35,0.03
                c-18.75,0.7-37.43,3.36-56.17,4.48c-11.27,0.67-22.63,0.4-33.92-0.05c-26.23-1.04-52.46-2.31-78.67-3.74
                c-17.24-0.94-34.48-2.09-51.67-3.68c-4.93-0.46-9.65-3.82-14.54-4.03c-9.89-0.44-19.85,0.13-29.75,0.72
                c-12.56,0.75-25.09,2.42-37.65,2.81c-10.94,0.34-21.96-0.15-32.88-0.99c-8.83-0.68-17.55-1.76-26.22,0.87
                c-5.05,1.53-8.61-0.2-12.24-4.11c-3.66-3.94-8.53-6.77-12.93-10.01c-3.29-2.42-8.54-4.36-7.16-8.94c0.66-2.17,6.21-3.72,9.66-4.01
                c7.03-0.58,14.24-0.79,21.18,0.18c4.38,0.61,8.37,3.75,12.62,5.55c3.3,1.4,6.69,3.32,10.26,0.47c0.22-0.18,0.65-0.16,0.97-0.13
                c12.29,1.12,24.57,2.27,36.86,3.35c1.95,0.17,4.08,0.46,5.86-0.13c6.08-2.04,12.05-4.22,18.39,0.06c-0.2-0.68-0.39-1.37-0.59-2.05
                c2.39-1.36,4.68-3.65,7.18-3.9c6.36-0.64,12.86-0.86,19.23-0.35c12.19,0.97,24.17,3.14,36.59,2.94
                c13.34-0.21,26.71,2.48,40.1,3.52c12.92,1.01,25.95,1,38.8,2.49c6.69,0.78,13.64-0.07,20.21,3.52c4.14,2.27,9.65,2.62,14.57,2.76
                c15.89,0.44,31.82,0.86,47.69,0.25c5.82-0.22,11.51-3.39,17.29-5.12c5.89-1.76,11.72-4.3,17.73-4.87c9.2-0.88,18.54-0.6,27.8-0.36
                c8.29,0.21,16.58,0.91,24.85,1.66c3.1,0.28,6.27,0.96,9.18,2.05c10.54,3.96,21.39,4.54,32.38,3.24c3.48-0.41,5.22,0.94,6.74,3.66
                c1.93,3.45,4.06,6.78,6.11,10.15C1093.23,772.77,1092.99,773.34,1092.75,773.9z"/>
            <path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M647.16,552.73c1.2,0.24,2.59,1,3.56,0.64c7.68-2.87,14.3,1.38,21.32,2.91
                c5.32,1.16,10.59,2.65,15.96,3.51c2.91,0.47,3.9,1.44,2.92,4.19c-1.06,2.96-2.16,5.91-2.92,8.01c-10.31,0-20.07-0.05-29.83,0.03
                c-2.81,0.03-5.72,0.07-8.39,0.81c-4.08,1.12-5.42-0.37-5.72-4.24c-1.4-18.04-5.08-35.96-2.83-54.2c0.18-1.43-0.25-2.94-0.44-4.8
                c0.21-0.14,0.63-0.7,1-0.66c6.55,0.64,6.83-4.03,7-8.47c0.64-16.92,1.02-33.84,1.73-50.76c0.35-8.31,1.18-16.61,1.87-24.9
                c0.25-2.96,1.52-3.69,4.73-2.92c4.63,1.11,9.5,1.19,14.27,1.71c0.14-0.76,0.27-1.53,0.41-2.29c-1.66-0.4-3.32-0.88-5-1.2
                c-3.68-0.71-7.81,0.67-10.61-3.75c-0.75-1.18-4.92-0.31-7.51-0.16c-1.25,0.07-2.47,0.64-5.2,1.41c1.56-3.04,2.12-5.44,3.62-6.8
                c4.42-4.01,9.02-7.89,13.92-11.28c3.78-2.62,17.68-0.85,20.2,2.84c2.57,3.75,4.02,8.31,5.63,12.64c1.16,3.11,1.72,6.43,2.67,9.62
                c1.97,6.65,5.28,8.53,12.19,7.28c1-0.18,2.15,0.42,3.23,0.67c-0.02,0.41-0.05,0.83-0.07,1.24c-1.39,0.31-2.79,0.61-5.62,1.24
                c2.12,1.66,3.28,3.32,4.59,3.44c3.33,0.3,5.25,1.9,5.98,4.95c1.4,5.81,2.67,11.66,3.89,17.51c0.14,0.69-0.38,1.51-0.71,2.67
                c-14.88-4.11-29.62-3.81-44.36-1.62c-0.06,0.81-0.12,1.63-0.19,2.44c1.87,0.19,3.74,0.6,5.6,0.55c9.1-0.24,18.19-0.71,27.29-0.82
                c2.26-0.03,4.57,0.84,6.79,1.53c6.47,2.02,6.57,2.74,4.95,9.22c-1.96,7.85-3.6,15.79-5.02,23.76c-1.14,6.36-1.81,6.03-8.13,5.86
                c-13.72-0.36-27.47,0.44-41.2,0.81c-0.79,0.02-1.61,0.45-2.32,0.85c-0.37,0.21-0.55,0.76-1.58,2.32c2.58,0,4.33,0.01,6.08,0
                c13.92-0.11,27.85-0.21,41.77-0.35c3.12-0.03,4.61,1.38,4.51,4.53c-0.03,0.82,0.1,1.68,0.34,2.47c2.45,8.03,0.61,14.19-5.7,20.38
                c-4.1,4.02-5.55,10.81-7.98,16.44c-1.54,3.55-3.27,6-7.78,4.51c-5.96-1.96-11.97-3.82-18.06-5.31
                c-4.95-1.21-10.04-2.11-15.11-2.49c-1.97-0.15-4.07,1.53-6.12,2.38C646.9,551.62,647.03,552.18,647.16,552.73z M692.12,441.93
                c0.05-0.43,0.09-0.86,0.14-1.3c-10.9-0.37-21.75-2.35-32.68-1.2c-0.03,0.83-0.05,1.66-0.08,2.5
                C670.37,441.93,681.25,441.93,692.12,441.93z M659.43,494.99c0.17,0.98,0.35,1.95,0.52,2.93c6.36-0.93,12.72-1.86,19.08-2.8
                c-0.12-0.86-0.24-1.72-0.36-2.57C672.25,493.37,665.84,494.18,659.43,494.99z M699.5,454.68c-6.5-3.59-19.45-3.24-22.97,0
                C683.88,454.68,691.03,454.68,699.5,454.68z M653.21,409.34c0.06,0.46,0.13,0.92,0.19,1.39c4.31,1.82,8.19,1.12,11.01-2.9
                C660.09,408.41,656.65,408.87,653.21,409.34z M703.35,446.21c0.23-0.89,0.47-1.78,0.7-2.67c-2.11-0.5-4.21-1-6.32-1.5
                c-0.2,0.74-0.4,1.48-0.6,2.22C699.2,444.92,701.28,445.56,703.35,446.21z M669.03,406.86c0.13,0.79,0.25,1.58,0.38,2.36
                c1.9-0.41,3.81-0.82,5.71-1.23c-0.14-0.68-0.28-1.37-0.42-2.05C672.81,406.26,670.92,406.56,669.03,406.86z M681.64,419.57
                c-0.15-0.66-0.3-1.33-0.45-1.99c-1.49,0.35-2.99,0.7-4.48,1.05c0.15,0.64,0.29,1.28,0.44,1.93
                C678.64,420.23,680.14,419.9,681.64,419.57z"/>
            <path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M374.63,867.65c0,3.47,0.23,6.28-0.11,9.01c-0.15,1.2-1.44,3.21-2.24,3.23c-4.83,0.08-7.95,2.78-11.03,6
                c-1.18,1.23-3.04,1.82-4.58,2.68c1.85,3.75,4.23,4.96,10.19,5.12c-0.84-1.37-1.55-2.51-2.69-4.36c1.92-1.51,4.08-4.59,5.9-4.41
                c7.92,0.8,9.92-3.44,9.37-10.75c3.45,2.11,5.98,3.97,8.76,5.28c2.78,1.31,5.3,1.64,7.89-1.49c2.01-2.43,5.45-3.77,8.45-5.19
                c0.73-0.35,2.33,0.73,3.32,1.44c2.22,1.6,4.25,2.38,6.4-0.05c-5.6-7.04-7.16-7.53-14.74-3.98c-2.89,1.36-5.33,3.83-8.3,4.83
                c-1.8,0.6-4.22-0.64-6.74-1.13c6.24-10.75,9.27-11.87,19.51-9.46c3.41,0.8,7.17,0.13,10.77,0.13c0.25-0.55,0.5-1.1,0.75-1.65
                c0.49,0.85,0.82,1.89,1.51,2.51c0.76,0.67,1.86,0.95,2.81,1.4c0.34-1.02,1.04-2.06,0.98-3.06c-0.4-6.2,1.85-7.72,7.99-6.13
                c8.13,2.1,16.03,5.45,24.91,3.5c5.17-1.13,10.12,1.04,12.97,5.66c2.26,3.65,5.01,3.78,8.51,3.01c7.55-1.65,9.79-0.72,13.16,6.2
                c1.51,3.1,3.27,4.41,6.63,3.18c3.24-1.19,4.78,0.23,5.92,3.35c0.51,1.4,2.66,3.16,4.05,3.14c9.1-0.16,13.56,3.61,15.22,13.5
                c-3.28,2.67-6.69,2.95-10.61,1.22c-4.94-2.18-9.27-0.83-13.26,2.81c-1.83,1.67-4.43,3.25-6.79,3.44
                c-5.53,0.44-11.12-0.02-16.69-0.06c-1.14-0.01-2.73-0.2-3.35,0.44c-4.07,4.2-9.76,3.54-14.61,5.3c-4.29,1.56-7.83,1.15-11.14-1.63
                c-8.97-7.51-18.57-9.16-29.08-3.47c-0.58,0.31-1.28,0.74-1.86,0.66c-7.12-0.95-13.74-0.18-19.78,4.32
                c-1.13,0.84-3.82-0.26-5.75-0.65c-6.79-1.39-12.74-0.31-17.92,4.82c-1.51,1.5-4.28,1.83-6.54,2.45c-3.7,1-7.71,1.26-11.13,2.84
                c-3.25,1.5-5.6,1.19-8.24-0.82c-3.03-2.31-6.09-4.63-9.32-6.65c-5.54-3.46-11.45-3.61-17.13-0.58c-4.58,2.45-9.14,2.86-13.97,1.42
                c-6.33-1.88-12.64-3.86-18.96-5.79c-0.32-0.1-0.66-0.31-0.95-0.26c-6.45,0.94-12.8,2.11-19.16-1.08
                c-1.04-0.52-3.77,1.69-5.38,3.06c-2.61,2.22-5.14,2.82-8.17,1c-3.27-1.97-6.76-3.61-9.89-5.77c-6.29-4.32-12.62-3.08-16.48,3.36
                c-0.68,1.13-1.91,1.96-3,2.78c-0.12,0.09-1.46-0.94-1.38-1.14c0.47-1.27,0.91-2.76,1.86-3.59c1.98-1.72,4.23-3.14,6.44-4.56
                c2.22-1.43,4.92-2.35,6.73-4.17c3.71-3.75,7.92-4.83,13.08-4.84c3.31-0.01,6.74-1.47,9.89-2.8c8.98-3.81,18.28-1.7,27.45-1.78
                c9.24-0.07,18.61,1.29,27.15-4.02c1.48-0.92,3.88-0.41,5.86-0.5c7.78-0.36,15.62-0.22,23.32-1.23c3.7-0.48,7.42-2.67,10.6-4.86
                c3.54-2.43,6.56-4.9,11.27-5.51C367.05,872.81,370.5,869.81,374.63,867.65z M464.9,891.12c0.45,0.72,0.9,1.43,1.35,2.15
                c1.61-0.8,3.39-1.37,4.78-2.45c3.36-2.61,18.22-3.23,21.78-1.33c3.54,1.89,7.46,3.13,11.32,4.29c0.98,0.29,2.37-0.8,3.57-1.26
                c0.02-0.54,0.03-1.08,0.05-1.61c-2.34-0.91-4.67-1.86-7.03-2.72c-1.37-0.5-2.77-0.93-4.18-1.24c-3.57-0.79-7.15-2.04-10.74-2.07
                c-3.54-0.03-6.1-0.33-7.34-4.15c-1.22-3.75-4.19-5.61-8.02-5.59c-5.7,0.03-11.4,0.43-17.11,0.58c-1.28,0.03-2.62-0.66-3.84-0.47
                c-3.64,0.56-7.28,1.25-10.8,2.3c-0.5,0.15-0.31,2.58-0.44,3.95c1.33-0.22,3.28,0.04,3.87-0.74c2.2-2.89,4.5-3.02,7.28-1.15
                c0.68,0.46,1.66,1.46,2.03,1.27c5.3-2.69,10.93-0.05,16.04-1c4.66-0.87,5.92,0.39,6.76,3.23
                C470.81,886.03,467.85,888.58,464.9,891.12z M395.97,899.78c-0.31-0.19-0.71-0.63-1.15-0.67c-5.9-0.6-11.8-1.51-17.71-1.51
                c-2.07,0-4.55,1.9-6.08,3.61c-1.11,1.25-0.98,3.61-1.4,5.48c1.67-0.87,3.74-1.38,4.9-2.7c1.66-1.87,2.69-3.77,5.53-1.68
                c2.7,1.98,12.84,1.34,15.36-0.78C395.73,901.27,395.72,900.65,395.97,899.78z M441.63,893.03c-0.02-0.38-0.05-0.76-0.07-1.14
                c-0.81-0.39-1.59-0.92-2.45-1.16c-4.52-1.25-9.06-2.41-13.58-3.63c-4.28-1.16-8.03-0.46-10.22,3.67
                c-0.56,1.06,0.29,2.87,0.48,4.34c1.21-0.61,2.89-0.9,3.56-1.88c2.52-3.68,3.28-3.65,6.4-0.14c0.94,1.05,2.97,1.47,4.53,1.55
                c2.39,0.13,4.82-0.2,7.21-0.51C438.9,893.95,440.25,893.41,441.63,893.03z M323.35,902.27c-2.43-2.39-4.21-4.33-6.19-6.06
                c-2.78-2.42-8.25-1.79-10.22,1.31c-0.65,1.03-0.32,2.7-0.43,4.07c1.23-0.39,2.93-0.39,3.59-1.23c2.27-2.88,3.87-2.32,5.83,0.29
                C317.55,902.81,319.56,904.6,323.35,902.27z"/>
            <path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M469.73,814.49c11.79-5.19,15-16.95,21.07-26.49c2.66-4.18,4.97-8.7,8.25-12.32
                c1.93-2.13,5.42-3.86,8.27-3.94c13.05-0.38,26.11,0,39.17-0.14c3.7-0.04,5.38,1.08,5.85,4.9c0.29,2.36,1.49,4.83,2.92,6.79
                c4.32,5.91,4.08,12.75,4.47,19.47c0.35,5.95,0.15,11.95,0.86,17.85c1.44,11.84,2.25,23.92,9.06,34.35
                c0.97,1.48,2.72,2.44,3.77,3.89c3.78,5.18,7.73,10.28,10.97,15.79c1.67,2.85-1.55,5.63-5.38,5.41
                c-5.11-0.29-10.27-0.71-15.34-0.26c-4.94,0.43-7.93,3.46-8.74,8.74c-0.3,1.97-2.13,3.75-3.41,5.5c-0.46,0.62-1.58,0.74-2.06,1.36
                c-5.7,7.35-13.57,5.52-21.05,4.84c-1.11-0.1-2.72-1.9-2.96-3.13c-2.3-11.76-8.06-21.72-15.28-31.04
                c-8.65-11.16-17.06-22.51-25.72-33.65C479.78,826.39,474.8,820.64,469.73,814.49z M513.02,849.42c0.4-4.69,0.14-8.47,1.22-11.82
                c0.76-2.37,3.06-4.61,5.26-5.94c0.83-0.5,3.4,1.59,5.01,2.73c3.34,2.36,6.05,5.07,5.53,9.79c-0.09,0.81,1.71,1.82,2.64,2.74
                c0.39-1.1,1.1-2.2,1.12-3.31c0.09-5.37-3.48-8.62-6.94-12.1c-5.26-5.3-9.41-5.86-13.15,0.45c-2.48,4.19-2.34,9.97-3.17,15.07
                C510.47,847.52,511.75,848.23,513.02,849.42z M537.06,849.99c-0.58,0.28-1.16,0.57-1.75,0.85c0.62,1.71,0.8,4.49,1.92,4.95
                c4.91,2,6.89,5.4,7.56,10.51c0.23,1.78,2.78,3.75,4.72,4.72c4.15,2.07,8.56,3.62,12.93,5.22c1.54,0.56,3.26,0.6,4.89,0.88
                c0.16-0.52,0.32-1.04,0.47-1.56c-1.84-0.75-3.69-1.48-5.52-2.25c-6.52-2.72-13.1-4.87-16.34-12.49
                C544.21,856.69,540.1,853.56,537.06,849.99z M538.35,788.92c-5.92-1.8-11.1-4.09-16.47-4.73c-3.35-0.4-7,1.72-10.51,2.71
                c2.85,1.36,5.67,0.98,7.38,2.18C525.87,794.07,529.73,794.33,538.35,788.92z M517.91,809.77c-0.04-0.66-0.08-1.31-0.12-1.97
                c-11.36,0.21-22.9-1.51-33.87,2.87c0.04,0.42,0.08,0.84,0.12,1.27C495.33,811.22,506.62,810.49,517.91,809.77z M528.76,807.49
                c-0.04,0.52-0.09,1.04-0.13,1.55c7.58,0.81,15.16,1.62,22.74,2.44c0.06-0.69,0.11-1.38,0.17-2.08
                C544.05,807.67,536.6,805.42,528.76,807.49z"/>
            <path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M740.29,918.86c3.79-4.29,5.73-8.35,5.37-13.97c-0.23-3.55,1.77-7.27,2.85-10.89
                c0.86-2.88,1.45-5.93,2.89-8.5c1.52-2.71,3.84-4.98,5.92-7.35c1.2-1.38,3.46-2.4,3.76-3.88c0.89-4.32,3.03-5.6,7.11-4.47
                c0.84,0.23,1.83-0.11,2.75-0.18c0.21-0.51,0.43-1.01,0.64-1.52c-1.9-0.95-3.88-2.78-5.69-2.63c-2.51,0.2-6.22,1.27-7.09,3.08
                c-1.92,4-4.35,6.77-8.31,8.6c-1.2,0.55-2.36,2.12-2.7,3.44c-2.34,9.23-4.72,18.47-6.54,27.81c-0.84,4.3-4.84,8.21-9.05,6.92
                c-5.21-1.59-10.26-3.73-16.89-6.18c7.73-3.67,6.85-10.21,7.51-16.16c0.4-3.58,1.77-6.36,5.35-7.72c0.75-0.29,1.74-0.76,2.01-1.41
                c2.01-4.81,4.98-8.17,10.49-9.18c1.04-0.19,2.12-3.3,2.18-5.1c0.17-5.37,2.9-9.21,6.85-12.2c8.65-6.55,17.73-12,29.17-12.49
                c3.88-0.16,7.93-2.05,11.45-3.97c8.63-4.71,17.27-4.82,26.42-1.82c3.84,1.26,8.09,1.25,12.07,2.16c2.95,0.67,6.05,1.43,8.62,2.94
                c11.63,6.81,16.28,18.77,21.67,30.16c0.76,1.61,1.53,3.83,2.89,4.44c4.57,2.05,5.06,5.66,4.81,9.86
                c-0.27,4.53-0.14,9.11-0.9,13.55c-0.23,1.37-2.62,2.77-4.31,3.4c-1.94,0.72-4.62-0.06-6.22,0.98c-4.83,3.14-9.4,2.2-14.27,0.43
                c-5.57-2.03-10.6-1.49-15.13,3.04c-1.49,1.49-4.33,2.32-6.53,2.27c-8.8-0.17-17.32,0.08-23.6,7.58c-0.21,0.25-0.47,0.61-0.74,0.65
                c-5.64,0.74-11.31,2.11-16.94,1.97c-6.07-0.15-12.1-1.72-18.14-2.68c-1.15-0.18-2.32-0.3-3.43-0.62
                C751.26,917.67,746,914.85,740.29,918.86z M813.52,887.36c5.36,1.3,8-1.59,10.76-3.96c2.82-2.42,12.84-3.6,15.33-1.31
                c2.89,2.66,7.77,3.5,8.16,8.62c0.04,0.57,1.1,1.36,1.81,1.52c2.91,0.69,4.7,2.02,4.62,5.4c-0.02,0.71,1.74,1.46,2.68,2.19
                c0.59-0.87,1.71-1.75,1.69-2.61c-0.09-3.17-0.34-6.3-4.16-7.71c-1.32-0.49-2.57-1.96-3.24-3.29c-1.77-3.49-10.28-9.69-14.05-9.37
                c-3.52,0.3-6.15-0.7-8.26-3.44c-0.5-0.64-1.14-1.42-1.85-1.62c-7.12-1.97-14.23-4.03-21.45-5.6c-2.63-0.57-5.58,0.52-8.29,0.13
                c-5.22-0.74-17.62,6.46-18.97,11.49c-0.22,0.81,0.27,1.8,0.44,2.71c0.87-0.24,1.83-0.33,2.58-0.76c1.78-1.03,3.51-2.16,5.15-3.4
                c1.26-0.94,2.88-1.9,3.4-3.22c1.23-3.14,3.11-3.9,6.23-3.16c2.35,0.56,4.82,0.67,7.24,0.88c2.24,0.19,4.54-0.01,6.72,0.43
                c4.14,0.82,8.23,1.91,12.29,3.04c1.11,0.31,2.08,1.17,3.69,2.12C821.76,880.19,818.07,883.4,813.52,887.36z M812.09,842.24
                c-2.01,0.92-4.53,1.57-6.36,3.06c-1.7,1.39-3.99,5.14-3.73,5.38c2.91,2.71,4.12-0.73,5.82-2.15c3.58-3,5.28-2.58,7.83,1.2
                c0.93,1.38,3.2,1.89,4.9,2.71c1.18,0.57,2.45,0.95,3.66,1.47c1.3,0.56,2.58,1.17,3.86,1.77c1.34,0.63,2.65,1.32,4,1.89
                c1.23,0.52,2.51,0.92,3.77,1.37c0.05-1.5,0.56-3.18,0.03-4.43c-0.51-1.21-1.96-2.22-3.23-2.88c-5.23-2.72-10.53-5.31-15.85-7.86
                C815.48,843.15,814,842.85,812.09,842.24z M785.35,856.78c4.69-2.35,9.08-4.56,13.69-6.88
                C793.55,846.09,786.38,849.69,785.35,856.78z"/>
            <path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M755.62,445.19c2.22,0.94,4.39,2.07,6.68,2.8c6.12,1.93,10.06-3.57,15.3-4.7
                c2.18-0.47,4.43-1.59,6.51-1.33c8.05,0.98,16.04,2.54,24.08,3.57c2.5,0.32,5.79,0.54,7.58-0.77c7.51-5.46,15.51-5.34,24.23-4.39
                c14.49,1.57,29.01,3.04,43.62-0.36c6.33-1.47,13.38,0.18,19.72-1.27c14.81-3.38,30.29-4.9,43.53-13.46
                c0.33-0.22,0.93-0.03,1.93-0.03c-1.48,10.93-3.33,21.73-4.24,32.6c-0.52,6.2-2.58,9.31-9.04,10.12
                c-7.4,0.93-14.74,2.63-21.96,4.52c-5.37,1.41-10.46,3.85-15.78,5.5c-4.03,1.25-8.36,3.17-12.32,2.72
                c-8.19-0.94-16.23-3.26-24.33-5.02c-0.32-0.07-0.84-0.1-0.92-0.3c-3.16-7.05-5.99-1.54-9.55-0.22c-6.31,2.34-13.1,3.34-19.62,5.18
                c-11.81,3.33-21.45,9.48-26.95,21.07c-2.55,5.38-6.02,6.71-11.81,5.42c-3.9-0.87-7.77-2.06-11.72-2.4
                c-4.4-0.38-6.44-2.82-7.65-6.59c-4.28-13.28-8.47-26.59-12.93-39.8c-1.4-4.14-3.57-8.01-5.39-12
                C754.93,445.76,755.28,445.47,755.62,445.19z"/>
            <path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M1190.76,841.33c-1.59-4.42,3.73-11.43,9.66-11.69c1.97-0.09,4.54-0.54,5.72-1.84
                c4.47-4.92,11.72-4.99,15.1,0.98c2.54,4.48,4.61,4.42,9.06,2.73c6.7-2.55,13.44,0.54,19.58,3.03c3.67,1.48,6.38,3.07,9.77,0.01
                c0.68-0.62,2.14-0.38,3.88-0.62c-0.14,2.36-0.14,4.29-0.39,6.19c-0.79,6.18,1.79,9.03,7.91,9.21c3.27,0.09,6.64,0.51,9.76,1.46
                c3.2,0.98,6.1,2.54,5.3,7.16c-0.18,1.05,2.38,3.13,4.05,3.8c2.25,0.9,4.96,0.6,7.3,1.35c4.73,1.53,6.46,4.58,4.9,9.23
                c-1.22,3.63-0.55,5.92,2.65,8c1.38,0.9,1.98,2.93,3.12,4.29c2.78,3.34,5.61,6.64,8.55,9.84c1.21,1.31,3.06,2.09,4.09,3.5
                c3.54,4.86,2.92,6.47-2.81,8.39c-1.26,0.42-2.7,0.65-3.7,1.43c-4.45,3.45-8.51,3.99-13.53,0.49c-4.14-2.89-8.81-0.69-12.69,1.66
                c-3.24,1.96-5.28,1.93-8.03-1.06c-7.56-8.2-12.81-8.85-23.15-4.28c-1.44,0.64-3.84,0.19-5.28-0.64c-0.93-0.53-0.97-2.68-1.32-4.13
                c-0.67-2.76-0.61-5.94-2.03-8.23c-3.56-5.75-7.74-11.1-11.72-16.59c-2.27-3.13-8.12-3.06-11.69,0.69c1.69,0.11,2.88,0.09,4.03,0.3
                c1.09,0.2,2.58,0.31,3.13,1.03c4.59,6.04,9.15,12.11,13.3,18.44c0.67,1.03-0.34,4.7-1.21,4.95c-4.51,1.3-9.22,1.89-14.82,2.89
                c1.36-1.67,2.17-2.66,3.43-4.2c-9.33-7.89-17.89-16.4-27.69-23.08c-9.66-6.59-10.6-16.21-12.1-25.95
                c-0.18-1.18,1.87-3.79,3.05-3.9c4.81-0.41,9.69-0.26,14.53-0.01c2.3,0.12,4.66,0.7,6.82,1.52c4.5,1.69,8.23,0.64,11.66-2.48
                c0.41-0.37,1.47-0.57,1.89-0.31c3.7,2.27,7.28,2.23,11.16,0.3c0.49-0.24,2.23,2,3.38,3.11c0.83,0.8,1.63,1.62,2.45,2.44
                c0.36-1.15,1.09-2.33,1.02-3.45c-0.3-4.74-3.54-6.65-8.41-5.68c-2.08,0.41-4.35-0.14-6.53-0.26c-1.96-0.1-4.34-0.97-5.82-0.18
                c-7.22,3.89-14.21,1.47-21.44,0.31C1201.73,840.69,1196.59,841.33,1190.76,841.33z M1263.84,869.02c4.24,1.33,7.22,2.13,10.09,3.2
                c1.62,0.61,4.21,1.49,4.39,2.57c0.44,2.69-1.5,3.77-4.33,4.1c-2.41,0.28-4.69,1.68-7.03,2.58c0.19,0.55,0.38,1.1,0.58,1.65
                c1.4-0.24,2.85-0.85,4.19-0.65c2.35,0.35,5.93,0.49,6.71,1.94c2.93,5.46,7.97,6.25,13.02,7.86c3.34,1.06,6.58,3.18,9.16,5.59
                c1.08,1.01,0.17,4.01,0.44,6.07c0.1,0.75,1.16,2.06,1.52,1.97c0.97-0.23,2.15-0.87,2.63-1.7c1.94-3.39,0.45-9.43-3.25-11.43
                c-4.76-2.58-9.95-4.36-14.75-6.86c-1.71-0.89-3.29-2.7-4.1-4.47c-0.76-1.65-0.08-3.92-0.64-5.72c-0.71-2.27-2.11-6.23-3.08-6.19
                C1274.54,869.73,1270.02,863.88,1263.84,869.02z"/>
            <path onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M1186.6,458.45c-3.97,14.58-1.6,27.56,0.62,40.5c0.29,1.69,2.26,3.09,3.45,4.62
                c0.28-1.76,1.09-3.63,0.76-5.27c-2.15-10.6-4.41-21.1-1.93-32.04c1.04-4.58,2.24-7.66,7.91-7.14c4.11,0.38,8.32-0.37,12.56,0.57
                c-2.29,0.6-4.58,1.2-6.87,1.81c-0.03,0.47-0.06,0.94-0.09,1.41c1.39,0.31,2.77,0.85,4.17,0.9c4.64,0.14,9.29,0.24,13.92,0.03
                c1.01-0.05,1.96-1.46,2.67-3.06c8.31-0.57,16.88-1.28,24.76,4.12c4.3,2.95,9.44,4.91,14.46,6.52c6.65,2.12,9.09,7.51,11.23,13.08
                c2.35,6.12,2.18,6.26,8.69,1.78c-0.71,3.89-0.74,6.77-1.77,9.23c-3.39,8.08-7.14,16.01-10.31,23c-5.3-2.62-10.99-6.36-17.24-8.27
                c-6.99-2.14-14.47-2.85-21.78-3.78c-11.39-1.46-23.15-3.09-34.07,0.75c-8.86,3.11-17.4,1.25-26.06,1.59
                c-9.12,0.36-18.28-0.29-27.42-0.37c-5.82-0.05-11.64,0.05-17.45,0.27c-3,0.11-4.54-0.87-3.83-4.01c0.54-2.4,0.84-5.02,2.03-7.07
                c3.76-6.46,7.81-12.76,11.94-18.99c1.16-1.74,2.82-3.31,4.58-4.47c8.76-5.79,16.33-13.78,28.56-12.46
                C1175.05,462.22,1180.33,459.78,1186.6,458.45z M1248.19,478.28c-4.09-1.74-6.61,0.38-6.43,6.9c0.24,8.93-2.64,12.48-11.45,14.45
                c-0.97,0.22-1.74,1.35-2.61,2.06c1.08,0.74,2.2,2.19,3.22,2.12c7.07-0.5,13.24-7.04,14.15-13.95
                C1245.57,486.16,1247,482.58,1248.19,478.28z M1149.71,501.89c2.59,3.19,3.75,3.4,8.61,2.58c6.38-1.08,12.86-5.14,19.17,1.01
                c-0.06-5.11-2.51-7.26-7.83-6.66C1162.91,499.57,1156.22,500.87,1149.71,501.89z M1243.62,498.75c-0.14,0.51-0.28,1.02-0.42,1.53
                c1.69,0.39,3.43,1.26,5.05,1.08c4.62-0.52,8.53,0.6,11.88,3.83c0.71,0.68,1.5,1.51,2.38,1.7c0.79,0.17,1.9-0.26,2.56-0.81
                c0.29-0.24,0.01-1.57-0.39-2.15C1260.87,498.34,1250.46,495.82,1243.62,498.75z M1146.82,502.9c-0.16-0.76-0.33-1.52-0.49-2.28
                c-1.91,0.45-3.83,0.87-5.74,1.35c-3.04,0.76-6.12,2.17-8.38-1.6c-0.34-0.56-1.61-0.56-2.45-0.82c-0.12,1.04-0.7,2.71-0.28,3.01
                c1.89,1.37,4.14,3.4,6.12,3.26C1139.39,505.54,1143.08,503.95,1146.82,502.9z"/>
            <path onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M1001.72,446.42c-0.58-4.71-0.96-9.45-1.8-14.11c-0.84-4.72-2.15-9.36-3.22-14.04
                c-0.66-2.9-1.21-5.84-1.89-8.74c-0.67-2.87-1.65-5.68-2.11-8.57c-0.43-2.67-0.35-5.41-0.49-8.18c-3.79,2.11-4.53,6.21-3.37,10.86
                c3.82,15.41,9.4,30.52,8.81,46.75c-0.11,3.11-0.82,6.19-0.52,9.05c0.61,2.46,1.97,5.02,1.7,7.38c-0.81,6.85,5.12,9.68,7.85,14.38
                c1.49,2.57,3.59,4.79,5.31,7.24c0.32,0.46,0.38,1.34,0.19,1.9c-1.82,5.61-3.72,11.2-5.46,16.38
                c-6.58-6.15-12.97-12.11-19.35-18.07c-1.09-1.02-1.98-2.41-3.24-3.07c-6.84-3.59-7.62-9.29-6.95-16.34
                c1.77-18.68,3.31-37.41,4.25-56.15c0.64-12.76,0.12-25.58,0.12-38.78c1.51,0.08,2.5,0.39,3.27,0.1c0.93-0.35,1.68-1.21,2.51-1.85
                c-0.59-0.72-1.12-2.01-1.77-2.06c-4.09-0.35-3.57-3.12-3.29-5.81c0.51-4.9,0.88-9.81,1.62-14.68c2-13.18,12.56-19.76,21.67-27.31
                c2.07-1.71,5.27-2.05,7.95-3.02c-0.05-0.57-0.1-1.13-0.15-1.7c-5.29-0.27-10.58-0.53-16.65-0.84c1.58-1.89,2.6-3.93,4.25-4.94
                c5.67-3.48,8.21-9.09,10.39-14.83c3.99-10.48,11.29-17.83,20.81-23.29c1.54-0.88,2.89-2.09,5.2-2.8c-0.56,1.52-1.02,3.09-1.7,4.56
                c-3,6.47-5.83,13.03-9.21,19.29c-1.14,2.11-3.57,3.69-5.69,5.07c-2.24,1.45-4.82,2.37-7.25,3.52c0.25,0.6,0.5,1.2,0.75,1.8
                c3.04-1.11,6.08-2.23,9.23-3.38c-0.15,2.38-0.15,2.39-6.64,5.11c-2.84,1.19-5.72,2.29-8.38,4.13c5.44,2.55,9.47-2.96,14.95-2.43
                c0.24,1.96,0.48,4.03,0.74,6.17c-2.16,0.68-3.95,1.25-5.73,1.81c0.01,0.43,0.01,0.85,0.02,1.28c1.56,0.27,3.12,0.53,5.36,0.92
                c-2.85,4.56,0.01,7.66,1.83,11.74c2.19,4.9,2.65,11.49,1.43,16.78c-1.86,8.04-2.89,15.97-2.65,24.12
                c0.16,5.29,0.9,10.6,0.68,15.87c-0.07,1.85-1.87,4.01-3.45,5.36c-4.92,4.22-9.44,8.6-9.35,15.68c0.14,10.78-4.57,20.34-7.46,30.36
                c-0.71,2.47-1.39,4.96-2.08,7.44C1002.41,446.46,1002.07,446.44,1001.72,446.42z M988.29,467.09c1.1,2.4,2.2,4.8,3.3,7.2
                c0.72-0.54,1.99-1.01,2.07-1.63C994.04,469.53,992.77,467.32,988.29,467.09z M991.62,477.21c-0.91-0.21-1.81-0.42-2.72-0.64
                c-0.28,1.59-0.69,3.19-0.71,4.79c0,0.3,1.55,0.61,2.39,0.92C990.92,480.59,991.27,478.9,991.62,477.21z M991.11,387.98
                c-0.18-0.75-0.36-1.5-0.54-2.25c-1.87,0.52-3.74,1.05-5.62,1.57c0.17,0.64,0.35,1.29,0.52,1.93
                C987.35,388.82,989.23,388.4,991.11,387.98z"/>
            <path onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M1153.3,290.01c1.7-2.58,2.61-3.93,3.48-5.3c3.34-5.25,2.45-9.06-2.58-12.73c-3.15-2.3-6-5-10.04-8.43
                c4.35-0.71,7.44-0.96,10.38-1.75c12.18-3.26,24.35-6.6,36.47-10.07c6.98-2,13.64-2.57,21.05-0.95c8.14,1.78,16.81,0.95,25.2,1.8
                c5.89,0.59,11.8,1.67,17.48,3.31c4.32,1.25,7.95,0.64,12.07-0.72c3.91-1.29,8.25-1.3,12.41-1.78c2.45-0.28,4.94-0.23,7.39-0.56
                c9.7-1.3,19.39-2.64,29.07-4.07c9.15-1.36,17.88,0.53,26.54,3.17c3.63,1.1,7.3,2.08,11.37,4.46c-3.04,0.98-6.09,1.94-9.13,2.94
                c-1.22,0.4-2.41,0.86-3.62,1.29c0.78,1.11,1.35,2.88,2.38,3.22c6.73,2.21,13.51,4.3,20.38,6.03c3.69,0.93,7.67,0.68,11.33,1.67
                c1.67,0.45,3.53,2.42,4.02,4.08c0.27,0.92-1.87,3.35-3.34,3.84c-7.04,2.35-14.24,2.96-21.63,1.26c-8.1-1.86-16.3-3.26-24.43-5.02
                c-3.07-0.66-6.1-1.62-9.05-2.7c-4.17-1.52-8.42-1.92-11.85,1.06c-5.95,5.17-13.13,6.83-20.41,6.66
                c-14.27-0.34-28.52-1.65-42.79-2.5c-4.48-0.27-9.05-1-13.43-0.41c-12.8,1.73-25.98,1.14-38.29,6.16c-1.6,0.65-3.76-0.09-5.04-0.16
                c-2.57,1.26-4.7,2.76-7.05,3.38c-6.37,1.66-12.79,3.24-19.29,4.24C1159.73,291.83,1156.86,290.62,1153.3,290.01z"/>
            <path onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="M616.16,442.08c1.71-0.93,3.43-1.86,5.14-2.78c-0.09-0.49-0.18-0.97-0.27-1.46
                c-3.38,0.58-6.77,1.16-10.89,1.86c4.18-11.05,11.45-17.63,23.02-20.15c-0.23,0.9-0.37,1.58-0.6,2.23
                c-0.25,0.73-0.57,1.44-1.25,3.11c3.26-0.42,5.77-0.77,8.29-1.08c3.55-0.44,5.84,0.53,5.65,4.72c-0.4,8.79-0.55,17.59-1.17,26.36
                c-0.08,1.13-2.18,2.55-3.62,3.08c-6.23,2.29-12.6,4.2-18.85,6.46c-1.71,0.62-3.16,1.98-4.73,3c0.19,0.54,0.37,1.07,0.56,1.61
                c1.68-0.39,3.42-0.61,5.02-1.2c5.76-2.11,11.46-4.4,17.23-6.5c3-1.1,5.07,0,5.11,3.3c0.14,11.29,0.32,22.59-0.05,33.87
                c-0.06,1.97-2.36,4.34-4.23,5.66c-3.69,2.6-4.62,5.33-4.28,10.05c0.7,9.57-0.02,19.25-0.16,28.89c-0.12,7.95-0.34,15.9-0.33,23.86
                c0,3.3-1.33,4.34-4.53,4.11c-4.64-0.33-9.3-0.25-13.95-0.42c-0.95-0.03-2.71-0.38-2.72-0.61c-0.14-6-4.78-3.46-7.67-4.11
                c-7.11-1.61-14.24-3.18-22.43-5c0.85-1.69,1.65-3.37,2.54-5c0.38-0.7,1.09-1.23,1.42-1.94c1.02-2.16,1.48-5.83,2.96-6.28
                c4.69-1.45,9.73-1.84,14.67-2.25c0.72-0.06,2.02,1.95,2.36,3.2c0.87,3.2,1.05,6.61,2.13,9.73c0.48,1.41,2.43,2.31,3.72,3.43
                c0.33-1.7,1.12-3.47,0.88-5.08c-0.42-2.91-1.66-5.69-2.16-8.59c-0.22-1.31,0.51-2.78,0.81-4.17c0.64,0,1.28-0.01,1.91-0.01
                c0.65,4.3,1.25,8.61,1.97,12.89c0.38,2.25,0.66,4.63,1.63,6.64c0.52,1.09,2.4,1.52,3.67,2.25c0.29-1.31,1-2.68,0.8-3.9
                c-0.87-5.18-2.02-10.32-3.13-15.81c3.46-0.85,6.27-1.53,9.08-2.22c-0.04-0.47-0.07-0.94-0.11-1.4c-1.68-0.31-3.46-0.37-5-1
                c-1.71-0.7-3.74-1.57-4.68-3c-2.52-3.83-5.44-4.36-7.71-0.32c-2.09,3.71-4.72,4.38-8.59,4.28c-3.74-0.1-7.51,0.87-12.24,1.49
                c7.27-31.98,21.58-62.15,17.83-95.07c7.11-2.42,12.87-4.39,18.63-6.35c-0.16-0.66-0.32-1.32-0.48-1.98
                c-3.75,0.52-7.51,1.04-11.26,1.56L616.16,442.08z M615.24,507.96c-3.69,1.13-7.39,2.26-11.08,3.38c5.13,2.65,27.08-2.1,29.71-5.69
                c-6.69,0.85-12.71,1.61-18.73,2.37c8.53-3.56,17.06-7.12,25.75-10.75c-4.02-1.84-28.31,5.43-33.08,10.69
                C611.09,507.96,613.17,507.96,615.24,507.96z M632.84,456c-7.59-2.35-19.68,3.48-19.2,8.85
                C619.92,461.96,626.19,459.07,632.84,456z M634.09,438.07c0.16,0.83,0.31,1.66,0.47,2.49c1.97-0.45,3.95-0.83,5.87-1.43
                c0.22-0.07,0.06-1.37,0.08-2.1C638.37,437.38,636.23,437.72,634.09,438.07z M612.77,470.31c-0.2,0.82-0.39,1.63-0.59,2.45
                c2.03,0.37,4.06,0.74,6.08,1.12c0.15-0.79,0.3-1.57,0.45-2.36C616.73,471.11,614.75,470.71,612.77,470.31z"/>
            <path onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="M469.38,822.4c-8.92,2.63-18.57-3.5-26.64,2.54c-0.03,0.54-0.05,1.09-0.08,1.63
                c6.62,1.73,13.24,3.47,19.87,5.17c0.8,0.2,1.68,0.34,2.48,0.23c11.46-1.7,18.1,4.67,23.54,13.59c2.93,4.8,6.39,9.3,9.97,13.65
                c1.39,1.69,4.17,2.22,5.56,3.91c5.02,6.11,9.73,12.49,13.9,19.5c-4.94-2.66-11.6-0.23-15.03-5.98c-0.51-0.85-2.04-1.64-3.01-1.55
                c-5.23,0.48-7.98-2.78-10.92-6.33c-3-3.62-7.29-4.73-11.8-3.5c-3.16,0.86-5.53,0.22-7.57-2.16c-4.56-5.3-10.57-5.92-17.14-6.32
                c-7.35-0.44-14.6-2.43-21.93-3.51c-3.02-0.44-6.3-0.96-9.14-0.21c-3.24,0.85-4.55,0.17-5.49-2.83c-3.08-9.84-6.3-19.64-9.47-29.46
                c-1.74-5.38-3.35-10.8-5.3-16.1c-1.78-4.87-0.74-6.83,4.41-6.05c8.3,1.24,16.61,2.73,24.72,4.84c5.69,1.48,11.11,3.37,17.08,1.66
                c1.3-0.37,3.34-0.31,4.27,0.47c5.84,4.88,11.46,10.02,17.12,15.11C469.09,820.98,469.11,821.59,469.38,822.4z"/>
            <path onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="M1344.66,902.95c-5.94-0.44-10.87-0.66-15.75-1.29c-0.94-0.12-1.64-1.82-2.51-2.74
                c-2.65-2.81-5.31-5.61-7.98-8.4c-1.47-1.54-3.53-2.81-4.35-4.64c-2.62-5.83-7.81-10.3-7.21-17.94c0.41-5.18-4.85-7.66-9.63-9.52
                c-2.53-0.98-5-2.89-6.73-5.01c-4.45-5.43-10.41-7.83-16.93-8.37c-4.58-0.38-6.11-1.91-5.92-6.39c0.35-8.04-1.91-8.95-9.8-8.51
                c-6.44,0.36-13.02-1.68-19.54-2.68c-1.8-0.28-3.94-1.44-5.32-0.83c-5.68,2.52-9.21-1.1-13.28-3.77
                c-6.46-4.23-11.27-3.77-17.41,1.25c-0.5,0.41-1.09,0.75-1.7,0.95c-3.62,1.17-7.25,2.29-11.62,3.65c-0.78-4.3-1.69-9.38-2.44-13.51
                c4.21,0.72,7.97,1.8,11.76,1.93c12.45,0.43,24.92,0.44,37.38,0.76c14.29,0.37,28.58,0.77,42.85,1.44
                c2.95,0.14,5.93,2.42,8.75,2.15c4.09-0.39,8.03-2.21,12.05-3.38c1.37-0.4,3.76-1.32,4.02-0.92c3.48,5.39,9.06,3.73,13.91,4.66
                c1.64,0.31,3.84,1.97,4.31,3.48c6.26,20,12.29,40.07,18.3,60.15C1341.54,890.99,1342.93,896.58,1344.66,902.95z"/>
            <path onClick={() => props.onFill(43)} fill={props.fillColors[43]} d="M720.94,447.58c1.19,0.25,2.4,0.79,3.58,0.71c3.86-0.24,6.48,1.27,9.66,3.69c3.57,2.72,8.9,3.16,13.49,4.5
                c3.58,1.05,7.82,1.15,8.37,6.68c-8.46-2.54-16.78-4.66-25.33-5.22c-0.16,0.72-0.33,1.43-0.49,2.15
                c9.39,2.62,18.79,5.24,28.81,8.04c1.56,5.07,3.23,10.45,4.86,15.83c1.35,4.45,2.5,8.97,4,13.37c1.26,3.71,1.28,6.07-3.71,6.02
                c-7.97-0.08-15.96,0.12-23.93,0.35c-2.01,0.06-4.01,0.66-6.02,1.02c0,0.58,0,1.15,0,1.73c2.85,0.3,5.71,0.6,8.56,0.91
                c0.17,0.48,0.35,0.96,0.52,1.44c-4.42,1.77-9.01,3.21-13.23,5.39c-4.51,2.33-8.66,5.36-14.2,8.85c-0.94-4.88-2.09-8.62-2.3-12.41
                c-0.33-6.25,6.95-1.94,8.95-5.93c-0.72-0.38-1.23-0.81-1.78-0.91c-6.77-1.14-8.14-3.09-6.28-10.04c1.42-5.29,3.44-10.42,4.93-15.7
                c2.45-8.69,2.28-17.12-0.82-25.9c-2.34-6.65-3.65-14.07-3.39-21.07c0.14-3.87,4.37-7.65,6.99-11.3c0.34-0.47,2.43-0.4,3.08,0.16
                c9.14,7.95,18.73,15.54,23.98,26.95c0.25,0.55,0.18,1.25,0.3,2.22c-5.14,0.16-9.76,3.16-14.77-1.83
                c-3.38-3.37-9.05-2.81-13.79-1.48C720.99,446.4,720.97,446.99,720.94,447.58z M731.05,494.59c-0.08,0.5-0.16,1-0.23,1.51
                c1.45,0.34,2.88,0.85,4.35,0.99c7.69,0.72,15.39,1.46,23.1,1.91c2.15,0.13,4.35-0.72,6.53-1.11c-0.09-0.68-0.18-1.36-0.27-2.05
                C753.34,496.11,742.35,491.42,731.05,494.59z M734.58,469.94c-0.16,0.78-0.33,1.56-0.49,2.35c5.07,0.91,10.15,1.83,15.22,2.74
                c0.13-0.66,0.26-1.32,0.39-1.99C744.97,470.55,739.97,469.34,734.58,469.94z"/>
            <path onClick={() => props.onFill(44)} fill={props.fillColors[44]} d="M1261.97,525.24c-2.54,5.69-4.81,10.79-7.11,15.96c-9.16,0.52-18.45,1.04-27.73,1.56
                c-0.02,0.75-0.04,1.51-0.07,2.26c1.43,0.35,2.89,1.1,4.29,0.99c13.38-1.04,24.86,5.4,36.96,9.33c0.85,0.28,1.82,1.54,1.94,2.45
                c0.51,4.08,0.74,8.2,1.18,13.75c-3.77-2.42-6.73-4.05-9.38-6.09c-2.78-2.15-4.94-5.25-7.94-6.89c-2.73-1.49-6.23-1.58-9.39-2.29
                c-1.59-0.36-3.23-0.59-4.75-1.14c-2.67-0.98-5.19-2.89-7.9-3.14c-14.56-1.36-29.14-2.4-43.73-3.37c-3.56-0.24-7.09-0.43-9.44,3.79
                c-0.84,1.51-4.77,2.51-6.68,1.89c-1.87-0.61-3.71-3.41-4.23-5.57c-0.89-3.65,1.74-4.88,5.07-4.94c6.31-0.1,12.63-0.01,18.91-0.49
                c9.44-0.72,18.83-2.17,28.28-2.76c9.04-0.56,18.12-0.4,27.18-0.57c1.83-0.04,4.31,0.96,3.78-2.72c-0.12-0.83,1.29-1.88,1.68-2.41
                c-4.9-1.6-9.68-3.52-14.64-4.58c-1.83-0.39-4.09,1.27-6.16,1.99c0.15,0.49,0.29,0.99,0.44,1.48c1.69,0.28,3.39,0.56,5.08,0.84
                c0,0.19,0,0.39,0,0.58c-6.26,0.48-12.52,1.19-18.79,1.23c-1.1,0.01-3.12-2.77-3.13-4.27c-0.02-3.27,1.25-6.54,1.44-9.84
                c0.09-1.49-1.14-3.06-1.78-4.6c-0.58,1.43-1.52,2.83-1.67,4.31c-0.49,4.75-0.68,9.54-0.98,14.27
                c-18.8,2.63-37.05,0.66-57.91-18.93c4.74,0,9.14-0.33,13.47,0.06c11.9,1.07,23.45-1.04,35.09-3c18-3.03,35.71-1.66,52.93,4.86
                c1.87,0.71,4.24,0.1,7.7,0.1c2.04,2.62,5.16,6.61,8.25,10.62c0.28,0.37,0.53,0.96,0.45,1.4c-1.01,5.93-2.08,11.86-3.13,17.78
                c-0.65,0.05-1.31,0.11-1.96,0.16c-0.51-3.01-1.36-6.01-1.45-9.03c-0.09-3.02,0.59-6.07,0.93-9.1c-0.39-0.2-0.78-0.4-1.16-0.61
                c-0.81,1.08-2.01,2.05-2.35,3.27c-0.53,1.89-0.27,4.01-0.75,5.92c-0.34,1.36-1.35,2.54-2.07,3.8c-0.35-1.46-1.3-3.08-0.93-4.33
                c0.81-2.82,2.45-5.39,3.41-8.18C1263.93,529.04,1265.73,526.37,1261.97,525.24z M1206.25,526.72c-0.76-3.26-1.41-6.39-2.28-9.45
                c-0.16-0.57-1.19-1.08-1.9-1.22c-0.37-0.07-1.1,0.65-1.33,1.17C1199.37,520.27,1202.37,525.68,1206.25,526.72z"/>
            <path onClick={() => props.onFill(45)} fill={props.fillColors[45]} d="M583.98,814.64c5.23-1.36,9.46-2.46,13.69-3.56c-0.02-0.51-0.03-1.02-0.05-1.54
                c-1.94-0.22-3.88-0.68-5.81-0.6c-4.47,0.18-8.97,0.4-13.39,1.05c-2.36,0.35-4.75,1.35-6.83,2.55c-3.15,1.82-4.78,0.55-5.38-2.38
                c-0.77-3.73-1.13-7.54-1.59-11.32c-0.47-3.88,1.02-5.94,5.21-5.84c3.06,0.07,6.12-0.28,9.19-0.45c0.08-0.55,0.15-1.09,0.23-1.64
                c-1.51-0.43-3-1.12-4.54-1.23c-2.81-0.2-5.7,0.23-8.47-0.19c-1.47-0.22-3.4-1.38-3.98-2.65c-1.5-3.26-2.36-6.82-3.54-10.46
                c7.03-1.41,14.16-3.25,21.41-4.2c12.68-1.66,25.59-1.96,38.07-4.49c5.83-1.18,8.48,0.26,12.06,4c2.93,3.06,2.97,5,0.34,7.9
                c-10.39,11.45-20.52,23.12-31.09,34.4C595.23,818.58,589.9,815.26,583.98,814.64z M609.2,790.74c-0.12-0.9-0.23-1.8-0.35-2.7
                c-3.92,0.49-7.84,0.98-11.75,1.47c0.08,0.76,0.16,1.52,0.24,2.28C601.29,791.44,605.25,791.09,609.2,790.74z"/>
            <path onClick={() => props.onFill(46)} fill={props.fillColors[46]} d="M1004.22,456.94c2.1-6.59,3.37-13.23,6.4-18.92c1.51-2.84,6.09-4.42,9.6-5.74
                c10.81-4.06,32.59-6.31,44.21-2.47c-1.24,5.97-3.35,11.85-3.42,17.75c-0.06,5.67-3.3,12,2.92,17.84c-9.32-3.44-15,3.25-21.93,5.22
                c-7.95,2.26-15.41,6.19-23.13,9.29c-1.34,0.54-3.56,1.3-4.22,0.7c-3.87-3.55-8.26-7-10.75-11.45c-1.62-2.9-1.69-7.72,4.08-9.29
                c3.42-0.93,6.61-3.16,9.53-5.31c6.32-4.65,13.25-3.41,20.17-2.63c3.37,0.38,3.61,2.75,3.41,5.53c-0.08,1.1,0.65,2.26,1.01,3.4
                c0.99-0.92,2.42-1.65,2.9-2.79c2.3-5.38,1.03-7.4-4.38-9.18c-11.21-3.7-20.8,0.24-30.13,5.87c-1.28,0.77-2.54,1.59-3.86,2.28
                C1006.29,457.21,1005.73,457.01,1004.22,456.94z M1049.37,459.1c5.42-0.56,8.27-6.11,5.83-10.11
                C1053.13,452.59,1051.25,455.85,1049.37,459.1z M1038.03,467.06c3.38,0,6.07,0.03,8.75-0.09c0.14-0.01,0.22-1.35,0.33-2.08
                c-2.64-0.04-5.28-0.09-7.93-0.07C1039.04,464.82,1038.9,465.35,1038.03,467.06z"/>
            <path onClick={() => props.onFill(47)} fill={props.fillColors[47]} d="M1190.18,862.33c-7.25-5.44-14.08-10.61-20.95-15.72c-11.59-8.63-23.1-17.37-34.86-25.76
                c-4.64-3.31-7.35-7.23-6.49-12.91c0.21-1.36,2.11-3.48,3.27-3.51c8.63-0.2,17.28-0.01,25.91,0.17c5.48,0.12,10.96,0.55,16.45,0.62
                c6.54,0.09,8.22,1.52,8.94,7.94c1.12,10.03,2.03,20.1,3.6,30.06c0.87,5.54,2.93,10.9,4.37,16.36
                C1190.61,860.28,1190.31,861.11,1190.18,862.33z"/>
            <path onClick={() => props.onFill(48)} fill={props.fillColors[48]} d="M1069.97,431.67c5.99-11.43,17.79-13.42,26.16-4.57c2.29,2.43,2.7,6.57,4.33,9.73
                c0.75,1.44,2.21,2.71,3.64,3.57c7.38,4.41,14.87,8.63,22.27,13.03c0.89,0.53,1.4,1.71,2.24,2.79c-3.31,2.35-6.58,4.25-9.35,6.73
                c-7.68,6.85-15.88,8.43-25.92,5.28c-4.68-1.47-10.4,0.36-15.64,0.7c-0.04-0.45-0.08-0.89-0.12-1.34
                c4.81-0.24,9.63-0.48,14.44-0.71c0.05-0.57,0.1-1.13,0.16-1.7c-1.53-0.37-3.07-1.07-4.59-1.04c-5.31,0.1-10.61,0.45-15.91,0.71
                c-3.84,0.18-5.47-1.09-4.49-5.35c0.38-1.65-1.66-3.72-1.68-5.61c-0.07-4.95,0.15-9.92,0.66-14.84c0.35-3.33,1.87-4.58,5.41-2.43
                c5.19,3.15,10.97,1.45,14.31-4.16c-3.04,0.72-5.61,1.9-8.11,1.77C1075.23,434.07,1072.77,432.64,1069.97,431.67z M1091.97,456.17
                c-2.12,0-3.49,0.29-4.66-0.05c-3.73-1.1-7.73-1.9-10.91-3.93c-1.41-0.9-1.04-4.5-1.6-6.84c-0.17-0.71-0.64-1.69-1.2-1.88
                c-0.56-0.2-1.97,0.34-2.04,0.72c-0.51,3.04-1.4,6.24-0.84,9.14c0.24,1.26,3.58,1.98,5.55,2.84c1.78,0.77,3.71,1.24,5.44,2.12
                C1085.9,460.43,1089.41,460.08,1091.97,456.17z M1103.69,449.49c-4.07-6.54-13.8-9.7-19.27-6.68
                C1090.76,445.01,1096.63,447.04,1103.69,449.49z M1117.55,456.63c-6.18,0.7-12.35,1.39-19.64,2.22
                C1102.81,462.24,1112.85,461.21,1117.55,456.63z"/>
            <path onClick={() => props.onFill(49)} fill={props.fillColors[49]} d="M506.93,568.38c0.18,1.3,0.36,2.58,0.53,3.86c1.28-0.38,2.54-0.88,3.85-1.12c1.33-0.25,2.81-0.64,4.04-0.3
                c6.02,1.63,12.06,3.26,17.93,5.35c2.27,0.81,4.85,2.44,5.86,4.43c0.63,1.25-0.87,4.64-2.37,5.68c-3.15,2.16-6.79,4.27-10.48,4.86
                c-10.62,1.7-21.38,2.51-32.05,3.92c-5.27,0.69-10.57,1.55-15.67,3c-6.07,1.72-10.72-0.96-15.32-3.98
                c-0.99-0.65-1.75-2.48-1.7-3.73c0.31-8.04,6.83-14.98,14.05-18.39C485.86,567.07,496.06,565.66,506.93,568.38z M462.51,590.46
                c4.33,3.17,20.43,0.73,23.37-3.39C478.53,588.14,471.18,589.2,462.51,590.46z M511.77,584.86c-4.16,1.17-8.33,2.34-13.66,3.84
                C502.97,591.39,508.36,589.88,511.77,584.86z M493.58,573.01c-0.12-0.86-0.23-1.72-0.35-2.58c-4.38,0.61-8.77,1.23-13.15,1.84
                c0.11,0.85,0.23,1.69,0.34,2.54C484.81,574.21,489.2,573.61,493.58,573.01z"/>
            <path onClick={() => props.onFill(50)} fill={props.fillColors[50]} d="M536.79,764.9c-8.63,0-17.27-0.38-25.87,0.14c-5.62,0.34-10.05-1.71-13.14-5.61
                c-1.54-1.95-1.55-5.96-0.84-8.67c2.64-10.04,16.41-16.92,26.08-13.27c8.53,3.21,16.97,6.65,25.4,10.11
                c1.33,0.55,2.53,1.63,3.55,2.7c2.43,2.52,4.91,4.96,3.54,9.14c-1.37,4.17-4.79,3.45-7.8,3.73c-3.63,0.33-7.27,0.48-10.9,0.7
                C536.81,764.2,536.8,764.55,536.79,764.9z"/>
            <path onClick={() => props.onFill(51)} fill={props.fillColors[51]} d="M646.92,580.43c0,4.24,0.46,8.39-0.24,12.34c-0.25,1.43-3.26,3.29-5.07,3.36
                c-10.91,0.37-21.87-0.28-32.73,0.54c-6.68,0.51-13.19,3.12-19.82,4.63c-7.22,1.65-8.48,0.44-7.02-6.8
                c0.62-3.08,1.52-6.16,2.76-9.04c3.08-7.21,12.92-10.42,19.38-5.99c2.58,1.77,3.95,5.22,6.3,7.47c1.42,1.36,3.65,1.87,5.52,2.76
                c0.74-1.73,1.82-3.39,2.17-5.2c1.39-7.26,1.64-7.63,9.09-7.21c5.01,0.28,10.24-0.25,14.46,3.68
                C642.39,581.58,644.41,580.74,646.92,580.43z"/>
            <path onClick={() => props.onFill(52)} fill={props.fillColors[52]} d="M1042.35,414.7c0.87-0.97,1.6-2.14,2.64-2.86c2.01-1.38,3.77-2.38,5.57,0.88
                c3.06,5.55,5.66,5.79,12.54,5.26c2.52-0.19,5.37,2.7,7.79,4.55c0.24,0.18-1.48,2.93-2.29,4.49c-0.43-0.21-0.87-0.42-1.3-0.63
                c0.2-1.16,0.39-2.32,0.74-4.33c-3.57,0-6.84-0.19-10.07,0.09c-1.43,0.12-2.73,1.54-4.17,1.75c-3.77,0.55-7.58,0.75-11.38,1.11
                c-1.52,0.15-3.03,0.37-4.54,0.55c-0.05-0.54-0.1-1.08-0.15-1.62c2.57-0.15,5.15-0.18,7.7-0.49c2.61-0.32,5.2-0.91,7.64-2.19
                c-13.11-1.84-25.67,0.76-38.47,4.56c0.11-4.43,1.11-7.17,5.92-6.94c1.35,0.06,2.72-1.12,4.14-1.53c2.3-0.66,4.46-1.87,6.72,0.86
                c0.79,0.95,3.69,0.15,6.4,0.15c-5.08-6.69-12.72-7.89-19.97-4.08c-3-8.13,3.13-18.75,12.61-19.73c7.97-0.82,16.16,0.27,24.23,0.9
                c1.73,0.14,3.75,1.62,4.89,3.07c3.07,3.91,5.81,8.09,8.51,12.27c0.55,0.85,0.86,2.65,0.38,3.16c-0.73,0.78-2.25,1.12-3.43,1.1
                c-2.11-0.04-4.22-0.49-6.73-0.81c0.37-1.43,0.71-2.74,0.88-3.4c-3.08-1.19-5.76-3.02-8.47-3.06c-3.27-0.06-6.64,1.22-9.79,2.39
                c-0.49,0.18-0.07,2.84-0.07,4.35C1041.33,414.59,1041.84,414.65,1042.35,414.7z M1038.26,408.23c3.06-7.18,9.5-7.28,15.82-7.99
                c-4.26-3.39-8.6-2.8-12.92-1.22C1036.81,400.61,1037.71,404.29,1038.26,408.23z"/>
            <path onClick={() => props.onFill(53)} fill={props.fillColors[53]} d="M444.75,794.65c-6.93,3.24-13.89,2.46-20.8,1.88c-8.07-0.68-16.13-1.43-24.17-2.32
                c-1.06-0.12-2.28-1.07-2.95-1.98c-0.75-1.03-0.94-2.46-1.43-3.7c-3.82-9.72-2.66-11.73,7.63-11.32
                c7.91,0.31,15.89,0.93,23.66,2.37C434.78,781.07,441.72,787.37,444.75,794.65z"/>
            <path onClick={() => props.onFill(54)} fill={props.fillColors[54]} d="M467.2,613.62c1.08-4.99,4.01-6.85,7.84-7.48c15.89-2.62,31.77-5.27,47.69-7.63
                c4.07-0.6,8.3-0.4,12.43-0.11c1.26,0.09,2.41,1.69,3.61,2.6c-1.17,0.65-2.3,1.41-3.53,1.94c-4.52,1.98-9.27,3.55-13.58,5.91
                c-9.38,5.11-19.71,5.59-29.91,6.68c-4.94,0.53-9.98,0.61-14.94,0.32C473.64,615.66,470.52,614.42,467.2,613.62z"/>
            <path onClick={() => props.onFill(55)} fill={props.fillColors[55]} d="M1332.58,685c-6.03-2.35-11.91-5.3-18.14-6.84c-4.47-1.1-9.49-0.85-14.12-0.2
                c-4.04,0.57-6.2-0.04-7.27-4.23c-1.19-4.65-3.01-9.13-4.44-13.72c-1.52-4.88-0.63-6.27,4.29-6.03c4.95,0.24,9.89,0.84,14.81,1.52
                c1.38,0.19,3.74,1.14,3.78,1.85c0.24,3.97,3.39,5.09,5.65,7.41c5.25,5.39,9.59,11.67,14.23,17.64c0.57,0.74,0.77,1.78,1.14,2.67
                C1332.5,685.07,1332.58,685,1332.58,685z"/>
            <path onClick={() => props.onFill(56)} fill={props.fillColors[56]} d="M1041.99,499.19c5.01-1.21,10.29-1.77,14.97-3.76c11.74-4.99,24.05-1.83,36-3.33
                c1.58-0.2,3.21-0.03,4.84-0.76c-7.11-2.91-14.61-0.94-21.98-2.37c4.69-0.78,9.38-1.55,14.06-2.33c-0.04-0.3-0.08-0.6-0.12-0.89
                c-9.85,0-19.7,0-29.69,0c0-0.74,0-2.01,0-3.37c5.74,3.02,10.6,0.64,15.62-0.55c-0.07-0.72-0.14-1.44-0.21-2.16
                c-3.43,0.33-6.85,0.65-10.69,1.02c-0.17-2.87-0.35-5.82-0.53-8.85c12.14,0.59,24.36,1.19,36.59,1.79c0.16,0.57,0.31,1.13,0.47,1.7
                c-2.85,3.19,1.85,9.23-5.5,10.85c1.48,1.23,2.84,1.85,3.31,2.88c0.7,1.52,1.33,3.5,0.89,4.94c-0.28,0.89-2.64,1.68-4.02,1.61
                c-16.26-0.85-32.5-1.69-48.33,3.52c-1.83,0.6-3.84,0.66-5.76,0.96C1041.95,499.78,1041.97,499.48,1041.99,499.19z"/>
            <path onClick={() => props.onFill(57)} fill={props.fillColors[57]} d="M587.82,605.35c9.12-0.79,17.2-5.57,26.98-4.75c8.62,0.73,17.45-0.96,26.17-1.75
                c6.31-0.57,12.6-1.4,18.9-2.14c0.65-0.08,1.42-0.07,1.89-0.43c6.56-4.93,13.66-1.73,20.78-1.75c0,0.99,0.08,1.64-0.01,2.28
                c-1,7.29-1.01,7.08-8.34,7.16c-3.57,0.04-7.12,1.02-11.06,1.64c-0.25-4.93-4.27-3.17-7.91-2.64c-11.97,1.72-23.96,3.36-35.94,5
                c-0.98,0.13-2.02,0.21-2.98,0.02c-9.03-1.73-18.07-2.24-27.18-0.69c-0.36,0.06-0.8-0.41-1.21-0.63
                C587.88,606.23,587.85,605.79,587.82,605.35z"/>
            <path onClick={() => props.onFill(58)} fill={props.fillColors[58]} d="M976.9,383.94c-3.71,4.35-7.02,8.12-10.19,12c-2.33,2.85-6.67,5.25-2.45,9.9c0.51,0.56-0.19,2.2-0.31,3.34
                c-1.34,13.53-2.63,27.06-4.04,40.59c-0.68,6.56-1.58,13.1-2.41,19.93c-3.74-0.45-6.79-0.81-10.52-1.25
                c0.55-3.33,1.13-6.48,1.6-9.65c2.71-18.58,5.12-37.31,11.58-54.99c2.44-6.68,7.87-12.29,12.03-18.31
                c0.52-0.74,1.63-1.12,2.52-1.54C975.08,383.8,975.6,383.94,976.9,383.94z"/>
            <path onClick={() => props.onFill(59)} fill={props.fillColors[59]} d="M1169.95,533.14c-9,1.34-16.2,4.84-24.5,2.7c-4.89-1.26-10.25-0.91-15.4-0.89
                c-2.92,0.01-4.11-0.99-4.66-3.83c-0.72-3.72-1.97-7.33-3.12-11.46c7.15-3.46,14.76-3.04,22.31-2.43c0.69,0.06,1.47,2.6,1.69,4.07
                c0.52,3.46,0.46,7.04,1.22,10.43c0.22,0.96,2.48,1.46,3.8,2.17c-0.09-1.39-0.09-2.8-0.29-4.18c-0.38-2.66-0.87-5.31-1.32-7.96
                c0.57-0.22,1.14-0.45,1.71-0.67C1157.23,524.88,1163.06,528.66,1169.95,533.14z"/>
            <path onClick={() => props.onFill(60)} fill={props.fillColors[60]} d="M1385.54,499.99c-10.06,0-19.08,0.23-28.08-0.1c-4.23-0.16-8.53-1.02-12.59-2.25
                c-2.77-0.84-5.73-2.6-4.73-6.49c0.95-3.7,2.6-7.43,7.15-6.91c7.21,0.81,14.36,2.22,21.5,3.57c5.61,1.05,11.05,2.64,14.46,7.81
                C1383.93,496.62,1384.4,497.78,1385.54,499.99z"/>
            <path onClick={() => props.onFill(61)} fill={props.fillColors[61]} d="M594.01,819.67c-6.8,11.68-12.93,22.22-19.34,33.22c-0.7-0.95-1.97-2.16-2.63-3.64
                c-4.02-8.92-6.1-18.24-5-28.06c0.11-1.02,1.3-2.77,1.95-2.75C577.21,818.69,585.41,819.2,594.01,819.67z"/>
            <path onClick={() => props.onFill(62)} fill={props.fillColors[62]} d="M485.08,787.4c-4.55,7.25-6.45,14.96-13.85,18.92c-4.35,2.33-8.4,2.28-11.56-1.44
                c-3.58-4.21-6.72-8.8-9.97-13.28c-0.65-0.89-0.93-2.05-1.87-4.2C460.37,787.4,472.22,787.4,485.08,787.4z"/>
            <path onClick={() => props.onFill(63)} fill={props.fillColors[63]} d="M971.52,474.55c-9.2-0.49-9.82-1.41-8.99-9.38c1.01-9.73,1.7-19.51,2.34-29.27
                c0.36-5.46,5.01-12.11,11.64-15.04c-2.97,5.88-5.56,11.01-8.65,17.14c2.79-1.59,4.9-2.79,8.33-4.74
                c-0.85,7.55-1.59,14.16-2.33,20.76C973.09,460.75,972.32,467.49,971.52,474.55z"/>
            <path onClick={() => props.onFill(64)} fill={props.fillColors[64]} d="M857.2,582.87c-1.7,11.76-3.24,23.54-5.26,35.24c-0.32,1.87-2.78,3.37-4.63,5.48
                c3.78,0.13,5.08,1.48,5.04,5.4c-0.02,2.37,2.72,5.73,5.09,6.97c6.82,3.55,12.06,10.89,11.25,18.41
                c-0.78,7.23,2.59,13.23,4.81,19.61c0.41,1.18,0.89,2.34,1.33,3.51c-0.46,0.25-0.93,0.5-1.39,0.75c-1.48-2.67-3.65-5.2-4.28-8.06
                c-1.24-5.6-1.55-11.39-2.4-17.08c-0.17-1.16-0.83-2.43-1.65-3.28c-4.23-4.4-8.58-8.7-12.92-12.99c-0.91-0.9-2.16-1.48-2.94-2.46
                c-1.46-1.82-3.52-3.7-3.84-5.79c-0.83-5.35-0.66-10.56,1.46-16.01c2.61-6.73,3.37-14.16,5.21-21.22c0.8-3.08,2.3-5.99,3.48-8.97
                C856.1,582.55,856.65,582.71,857.2,582.87z"/>
            <path onClick={() => props.onFill(65)} fill={props.fillColors[65]} d="M379.6,687.34c-6.25,3.77-13.15,6.13-17.2,10.9c-3.87,4.56-4.77,11.63-6.98,17.61
                c-5.4-1.85-11.8-9.93-11.52-15.4c0.06-1.25,1.24-2.68,2.28-3.59c7.33-6.43,16.21-8.85,25.69-9.5
                C374.19,687.21,376.52,687.34,379.6,687.34z"/>
            <path onClick={() => props.onFill(66)} fill={props.fillColors[66]} d="M843.24,510.65c-10.51,0-21.02,0.2-31.53-0.09c-4.71-0.13-5.46-2.02-3.21-6.11
                c1.5-2.72,3.06-5.4,4.43-7.79c10.56,4.29,20.55,8.35,30.53,12.41C843.4,509.6,843.32,510.12,843.24,510.65z"/>
            <path onClick={() => props.onFill(67)} fill={props.fillColors[67]} d="M1283.94,630.52c7.75,1.63,23.03,15.05,24.8,21.75c-6.76-0.67-13.28-1.23-19.76-2.06
                c-0.81-0.1-1.89-1.52-2.08-2.48C1285.8,642.13,1284.93,636.47,1283.94,630.52z"/>
            <path onClick={() => props.onFill(68)} fill={props.fillColors[68]} d="M1015.11,501.82c-0.36-1.87-0.65-3.38-0.77-4c5.46-2.07,10.29-3.95,15.16-5.73
                c2.01-0.73,4.15-1.86,6.13-1.69c2.65,0.23,5.88,3.21,5.88-2.5c0-0.11,0.21-0.31,0.34-0.33c3.93-0.41,7.9-1.29,11.76-0.93
                c1.45,0.13,2.64,3.14,4.63,5.71C1043.18,495.66,1029.38,498.69,1015.11,501.82z"/>
            <path onClick={() => props.onFill(69)} fill={props.fillColors[69]} d="M1040.66,504.13c-3.39,6.58-6.44,12.48-9.43,18.29c-5.9-2.91-12.42-6.12-18.94-9.34
                c-0.09-0.56-0.18-1.12-0.27-1.69C1021.72,509.77,1030.15,503.73,1040.66,504.13z"/>
            <path onClick={() => props.onFill(70)} fill={props.fillColors[70]} d="M685.38,579.33c0.6,9.37-1.93,11.8-10.45,10.9c-3.08-0.32-6.27,0.07-9.38,0.4
                c-2.31,0.25-4.15,0.07-4.07-2.76c0.07-2.36-1.57-5.67,2.79-6.15c1.15-0.13,2.33-0.51,3.45-0.37c4.67,0.57,9.29,1.68,13.47-1.92
                C682,578.72,683.96,579.33,685.38,579.33z"/>
            <path onClick={() => props.onFill(71)} fill={props.fillColors[71]} d="M1391.76,519.84c-15.49-5.65-31.47-8.03-48.2-7.08c5.7-7.55,13.46-5.73,20.67-5.7
                c2.46,0.01,4.93,0.64,7.37,1.15c1.1,0.23,2.97,0.74,3.04,1.31c0.79,6.3,5.78,4.35,9.47,5.22c2.85,0.67,5.53,2.08,8.28,3.16
                C1392.18,518.55,1391.97,519.19,1391.76,519.84z"/>
            <path onClick={() => props.onFill(72)} fill={props.fillColors[72]} d="M964.23,387.97c-2.46-9.48,4.23-21.01,12.46-22.41c2.3,8,0.77,12.28-5.65,16.69
                C968.75,383.82,966.76,385.82,964.23,387.97z"/>
            <path onClick={() => props.onFill(73)} fill={props.fillColors[73]} d="M976.76,393.5c0,6.78,0.06,13.56-0.06,20.34c-0.02,1-0.64,2.32-1.43,2.9c-2.77,2.05-5.72,3.84-9.88,5.36
                c2.94-9.73,5.88-19.46,8.82-29.19C975.06,393.11,975.91,393.3,976.76,393.5z"/>
            <path onClick={() => props.onFill(74)} fill={props.fillColors[74]} d="M1155.58,699.29c13.19-2.5,25.51-4.83,38.77-7.34c-1.19,1.1-1.85,2.1-2.77,2.5
                c-6.87,2.94-13.77,5.77-20.69,8.6c-2.39,0.97-4.78,2.6-7.21-0.2c-0.39-0.45-1.98-0.32-2.75,0.08
                C1157.09,704.92,1156.93,701.93,1155.58,699.29z"/>
            <path onClick={() => props.onFill(75)} fill={props.fillColors[75]} d="M692.4,415.6c6.05-0.46,11.8-1.14,17.55-1.18c1.52-0.01,3.05,1.94,3.96,2.57
                c-3.59,3.07-6.83,5.89-10.14,8.63c-0.58,0.48-1.46,0.96-2.15,0.9C698.49,426.24,692.63,419.42,692.4,415.6z"/>
            <path onClick={() => props.onFill(76)} fill={props.fillColors[76]} d="M1371.87,622.98c-14.18-0.69-28.36-1.38-42.53-2.07C1343.76,619.05,1358.05,618.98,1371.87,622.98z"/>
            <path onClick={() => props.onFill(77)} fill={props.fillColors[77]} d="M1033.6,481.65c0.97,4.43-1.07,6.79-5.62,7.14c-2.59,0.2-5.19,0.65-7.77,0.59
                c-1.03-0.02-2.29-0.92-2.92-1.8c-0.25-0.36,0.75-2.34,1.46-2.53C1023.66,483.77,1028.65,482.75,1033.6,481.65z"/>
            <path onClick={() => props.onFill(78)} fill={props.fillColors[78]} d="M579.66,674.44c-1.8-8.84-3.61-17.69-5.59-27.42c1.3,0.43,3.08,0.59,3.19,1.11
                c1.87,8.53,3.53,17.12,5.23,25.69C581.55,674.03,580.61,674.23,579.66,674.44z"/>
            <path onClick={() => props.onFill(79)} fill={props.fillColors[79]} d="M491.28,624.41c9.34-1.2,17.92-2.35,26.52-3.33c0.89-0.1,1.88,0.78,2.83,1.2
                c-0.6,0.83-1.16,2.34-1.81,2.39c-8.71,0.58-17.43,0.96-26.15,1.36C492.49,626.04,492.29,625.59,491.28,624.41z"/>
            <path onClick={() => props.onFill(80)} fill={props.fillColors[80]} d="M1149.42,539.39c-5.68,1.7-11.32,3.52-17.06,4.99c-1.32,0.34-2.96-0.57-4.45-0.9
                c1.06-1.56,2.02-4.37,3.19-4.46c6.09-0.45,12.24-0.21,18.36-0.21C1149.44,539,1149.43,539.19,1149.42,539.39z"/>
            <path onClick={() => props.onFill(81)} fill={props.fillColors[81]} d="M1123.97,798.04c13.24-1.77,26.33,0.53,36.76,1.86c-10.51,0-23.66,0-36.82,0
                C1123.92,799.29,1123.94,798.67,1123.97,798.04z"/>
            <path onClick={() => props.onFill(82)} fill={props.fillColors[82]} d="M657.55,586.81c0.43,3.08,0.32,5.74-3.78,5.97c-3.43,0.19-3.5-2.43-3.09-4.32
                c0.56-2.55-1.89-7.22,3.38-6.99c1.58,0.07,3.05,2.82,4.57,4.35C658.27,586.15,657.91,586.48,657.55,586.81z"/>
            <path onClick={() => props.onFill(83)} fill={props.fillColors[83]} d="M549.36,741.36c-3.57-1.1-6.98-2.47-7.1-8.32c-0.11-5.23-1.79-10.43-2.02-15.87
                C543.3,725.29,546.36,733.4,549.36,741.36z"/>
            <path onClick={() => props.onFill(84)} fill={props.fillColors[84]} d="M1293.23,620.92c8.16-5.62,17.34,0.42,26.12-1.44c0.02,0.48,0.05,0.96,0.07,1.44
                C1310.56,620.92,1301.71,620.92,1293.23,620.92z"/>
            <path onClick={() => props.onFill(85)} fill={props.fillColors[85]} d="M891.82,507.71c-2.83,4.84-12.27,6.44-23.22,3.84C876.08,510.31,883.56,509.07,891.82,507.71z"/>
            <path onClick={() => props.onFill(86)} fill={props.fillColors[86]} d="M608.65,764.79c-7.26,0.92-14.08,1.78-22.24,2.81C593.28,762.73,603.87,761.57,608.65,764.79z"/>
            <path onClick={() => props.onFill(87)} fill={props.fillColors[87]} d="M1141.37,545.3c4.09-0.4,8.17-0.95,12.26-1.08c1.03-0.03,2.11,1.3,3.17,2.01c-1.03,0.6-2.07,1.71-3.1,1.72
                c-4.15,0.02-8.31-0.3-12.46-0.5C1141.28,546.74,1141.33,546.02,1141.37,545.3z"/>
            <path onClick={() => props.onFill(88)} fill={props.fillColors[88]} d="M892.87,486.24c4.83-2.22,8.33-3.84,12.7-5.85c-1.68,3.28-2.43,6.21-4.27,8.01
                C897.4,492.21,896.48,486.75,892.87,486.24z"/>
            <path onClick={() => props.onFill(89)} fill={props.fillColors[89]} d="M669.15,744.12c5.52-2.9,10.29,0.2,15.23,2c-0.05,0.47-0.1,0.94-0.15,1.42
                c-5.05-0.53-10.09-1.05-15.14-1.58C669.12,745.34,669.14,744.73,669.15,744.12z"/>
            <path onClick={() => props.onFill(90)} fill={props.fillColors[90]} d="M1157.88,309.56c0-3.13,0.12-5.01-0.03-6.87c-0.16-1.93-0.59-3.84-1.07-6.77
                c2.58,0.46,4.57,0.82,7.14,1.28C1161.85,301.43,1160.17,304.86,1157.88,309.56z"/>
            <path onClick={() => props.onFill(91)} fill={props.fillColors[91]} d="M479.21,679c-1.58-0.48-3.21-0.84-4.72-1.5c-1-0.43-2.67-1.66-2.57-1.9c2.18-5.02-2.8-4.94-5.01-6.83
                c4.2-1.81,8.8,2.17,12.22,10.3C479.13,679.07,479.21,679,479.21,679z"/>
            <path onClick={() => props.onFill(92)} fill={props.fillColors[92]} d="M1142.62,552.61c-4.37,2.12-7.58,3.68-11.07,5.36C1130.64,552.55,1135.21,550.21,1142.62,552.61z"/>
            <path onClick={() => props.onFill(93)} fill={props.fillColors[93]} d="M1157.79,365.52c0.37,5.13,0.74,10.25,1.11,15.38c-0.65,0.06-1.3,0.11-1.95,0.17
                c-0.45-5.12-0.9-10.24-1.34-15.36C1156.33,365.64,1157.06,365.58,1157.79,365.52z"/>
            <path onClick={() => props.onFill(94)} fill={props.fillColors[94]} d="M1038.08,480.59c2.99-0.36,5.42-0.87,7.83-0.8c0.62,0.02,1.93,2.28,1.64,2.8
                C1046.24,484.94,1039.7,483.94,1038.08,480.59z"/>
            <path onClick={() => props.onFill(95)} fill={props.fillColors[95]} d="M1068.13,755.59c-4.73-0.86-9.46-1.73-14.19-2.59c0.12-0.67,0.24-1.35,0.36-2.02
                c4.71,0.78,9.42,1.55,14.14,2.33C1068.34,754.07,1068.23,754.83,1068.13,755.59z"/>
            <path onClick={() => props.onFill(96)} fill={props.fillColors[96]} d="M1038.91,475.75c4.69-1.8,9.38-3.59,14.72-5.64C1049.84,475.85,1044.81,476.83,1038.91,475.75z"/>
            <path onClick={() => props.onFill(97)} fill={props.fillColors[97]} d="M1309.52,644.07c5.48,2.36,8.32,6.46,7.51,12.93c-2.48-4.33-4.96-8.66-7.44-13L1309.52,644.07z"/>
            <path onClick={() => props.onFill(98)} fill={props.fillColors[98]} d="M1142.56,512.43c-7.33,0.59-14.66,1.18-21.98,1.76c-0.03-0.45-0.06-0.9-0.09-1.35
                c7.33-0.45,14.66-0.89,21.99-1.34C1142.51,511.81,1142.53,512.12,1142.56,512.43z"/>
            <path onClick={() => props.onFill(99)} fill={props.fillColors[99]} d="M616.12,577.04c-0.79,3.86-1.21,5.88-1.62,7.89c-0.5,0.15-1,0.3-1.51,0.46c-0.88-1.98-1.76-3.96-2.65-5.94
                C611.81,578.84,613.27,578.23,616.12,577.04z"/>
            <path onClick={() => props.onFill(100)} fill={props.fillColors[100]} d="M631.19,611.12c7-1.35,14.01-2.7,21.01-4.05c0.09,0.51,0.17,1.02,0.26,1.53
                c-7.11,0.82-14.22,1.64-21.33,2.46C631.13,611.06,631.19,611.12,631.19,611.12z"/>
            <path onClick={() => props.onFill(101)} fill={props.fillColors[101]} d="M534.41,618.82c1.1,1.49,2.2,2.98,3.31,4.47c-1.29,0.45-3.45,1.56-3.7,1.23c-0.96-1.26-1.29-3-1.86-4.56
                C532.9,619.58,533.66,619.2,534.41,618.82z"/>
            <path onClick={() => props.onFill(102)} fill={props.fillColors[102]} d="M523.74,612.86c2.56-1.5,5.13-3.01,7.69-4.51C530.04,611.76,529.54,616.43,523.74,612.86z"/>
            <path onClick={() => props.onFill(103)} fill={props.fillColors[103]} d="M1168.73,558.97c-3.14,0.31-6.28,0.61-9.41,0.92c1.86-2.77,6.89,0.52,6.43-5.72
                c1.38,1.31,2.26,2.13,3.13,2.96C1168.83,557.74,1168.78,558.35,1168.73,558.97z"/>
            <path onClick={() => props.onFill(104)} fill={props.fillColors[104]} d="M669.27,577.44c-4.05,0-8.09,0-12.14,0c-0.01-0.32-0.01-0.63-0.02-0.95c4.05,0,8.09,0,12.14,0
                C669.26,576.8,669.26,577.12,669.27,577.44z"/>
            <path onClick={() => props.onFill(105)} fill={props.fillColors[105]} d="M469.5,620.2c4.34,0,8.68,0,13.02,0c-0.02,0.59-0.04,1.19-0.06,1.78c-4.32-0.22-8.63-0.43-12.95-0.65
                C469.5,620.96,469.5,620.58,469.5,620.2z"/>
            <path onClick={() => props.onFill(106)} fill={props.fillColors[106]} d="M602,569.03c-3.91-0.29-7.82-0.57-11.73-0.86c0.15-0.81,0.3-1.62,0.44-2.44
                c3.74,1.12,7.48,2.24,11.22,3.36L602,569.03z"/>
            <path onClick={() => props.onFill(107)} fill={props.fillColors[107]} d="M999.93,322.61c-2.15,1.89-4.3,3.77-6.45,5.66c-0.41-0.47-0.82-0.94-1.23-1.41
                c2.17-1.88,4.34-3.77,6.51-5.65C999.16,321.68,999.54,322.15,999.93,322.61z"/>
            <path onClick={() => props.onFill(108)} fill={props.fillColors[108]} d="M1149.29,511.93c4.13,0,8.26-0.02,12.38,0.01c1.29,0.01,2.58,0.14,3.86,0.83c-5.42,0-10.84,0-16.25,0
                C1149.28,512.49,1149.28,512.21,1149.29,511.93z"/>
            <path onClick={() => props.onFill(109)} fill={props.fillColors[109]} d="M465.55,827.5c2.83,0,5.66,0,8.49,0c-0.01,0.26-0.03,0.52-0.04,0.78c-2.87,0-5.74,0-8.61,0
                C465.44,828.03,465.49,827.76,465.55,827.5z"/>
            <path onClick={() => props.onFill(110)} fill={props.fillColors[110]} d="M1220.13,553.89c2.13,0.13,4.27,0.26,6.4,0.39c-0.05,0.71-0.1,1.42-0.15,2.13
                c-2.13-0.15-4.26-0.29-6.39-0.44C1220.03,555.28,1220.08,554.59,1220.13,553.89z"/>
            <path onClick={() => props.onFill(111)} fill={props.fillColors[111]} d="M1055.01,482.74c-1.78-1.92-2.71-2.92-4.11-4.43c1.54-0.78,2.66-1.36,4.64-2.37
                C1055.35,478.43,1055.22,480.02,1055.01,482.74z"/>
            <path onClick={() => props.onFill(112)} fill={props.fillColors[112]} d="M447.19,801.17c-2.15,0.23-4.31,0.47-6.46,0.7c-0.08-0.48-0.16-0.97-0.24-1.45
                c2.11-0.44,4.21-0.87,6.32-1.31C446.93,799.8,447.06,800.48,447.19,801.17z"/>
            <path onClick={() => props.onFill(113)} fill={props.fillColors[113]} d="M1235.85,557.68c-1.59-0.36-3.17-0.72-4.76-1.07c0.11-0.61,0.22-1.23,0.33-1.84
                c1.59,0.21,3.18,0.43,4.77,0.64C1236.07,556.16,1235.96,556.92,1235.85,557.68z"/>
            <path onClick={() => props.onFill(114)} fill={props.fillColors[114]} d="M725.31,745.12c-1.82,0.21-3.63,0.42-5.45,0.63c-0.07-0.47-0.14-0.94-0.21-1.41
                c1.81-0.31,3.61-0.61,5.42-0.92C725.15,743.99,725.23,744.56,725.31,745.12z"/>
            <path onClick={() => props.onFill(115)} fill={props.fillColors[115]} d="M1309.59,644.01c-2.75-2.25-5.5-4.5-8.25-6.76c0.44-0.44,0.89-0.87,1.33-1.31
                c2.28,2.71,4.57,5.42,6.85,8.13C1309.52,644.07,1309.59,644.01,1309.59,644.01z"/>
            <path onClick={() => props.onFill(116)} fill={props.fillColors[116]} d="M1162.87,544.99c0,1.43,0,2.86,0,4.3c-0.34,0-0.68,0-1.02,0c0-1.43,0-2.86,0-4.3
                C1162.19,544.99,1162.53,544.99,1162.87,544.99z"/>
            <path onClick={() => props.onFill(117)} fill={props.fillColors[117]} d="M485.54,627.46c-1.46,0.1-2.92,0.19-4.38,0.29c-0.05-0.44-0.11-0.88-0.16-1.32
                c1.53-0.28,3.06-0.56,4.59-0.85C485.58,626.21,485.56,626.84,485.54,627.46z"/>
            <path onClick={() => props.onFill(118)} fill={props.fillColors[118]} d="M598.86,616.06c2.58-0.2,5.17-0.4,7.75-0.6c0.02,0.3,0.04,0.61,0.06,0.91c-2.59,0-5.18,0-7.77,0
                C598.89,616.27,598.87,616.17,598.86,616.06z"/>
            <path onClick={() => props.onFill(119)} fill={props.fillColors[119]} d="M526.23,623.67c-0.37-1.07-0.75-2.15-1.12-3.22c0.77-0.18,1.53-0.36,2.3-0.54
                c0.19,1.1,0.38,2.21,0.57,3.31C527.39,623.37,526.81,623.52,526.23,623.67z"/>
            <path onClick={() => props.onFill(120)} fill={props.fillColors[120]} d="M1026.98,426.85c-2.02,0.61-4.05,1.22-6.07,1.84c-0.1-0.27-0.2-0.55-0.31-0.82
                c2.01-0.69,4.02-1.38,6.03-2.08C1026.75,426.14,1026.86,426.5,1026.98,426.85z"/>
            <path onClick={() => props.onFill(121)} fill={props.fillColors[121]} d="M1190.65,556.68c-1.83,0.35-3.67,0.69-5.5,1.04c-0.07-0.39-0.15-0.77-0.22-1.16
                c1.83-0.18,3.66-0.35,5.49-0.53C1190.49,556.25,1190.57,556.47,1190.65,556.68z"/>
            <path onClick={() => props.onFill(122)} fill={props.fillColors[122]} d="M612.91,763.24c1.79-0.22,3.58-0.43,5.37-0.65c0.04,0.35,0.07,0.71,0.11,1.06
                c-1.79,0.11-3.59,0.22-5.38,0.32C612.97,763.74,612.94,763.49,612.91,763.24z"/>
            <path onClick={() => props.onFill(123)} fill={props.fillColors[123]} d="M1012.5,430.73c1.21-0.48,2.41-0.96,3.62-1.43c0.15,0.31,0.29,0.63,0.44,0.94
                c-1.18,0.55-2.37,1.1-3.55,1.65C1012.84,431.49,1012.67,431.11,1012.5,430.73z"/>
            <path onClick={() => props.onFill(124)} fill={props.fillColors[124]} d="M1167.67,300.87c0.92-0.44,1.84-0.88,2.76-1.31c0.21,0.42,0.43,0.83,0.64,1.25
                c-0.87,0.5-1.74,0.99-2.61,1.49C1168.2,301.82,1167.94,301.34,1167.67,300.87z"/>
            <path onClick={() => props.onFill(125)} fill={props.fillColors[125]} d="M631.13,611.06c-2.17,0.49-4.35,0.98-6.52,1.47c-0.04-0.47-0.09-0.94-0.13-1.41c2.24,0,4.47,0,6.71,0
                C631.19,611.12,631.13,611.06,631.13,611.06z"/>
            <path onClick={() => props.onFill(126)} fill={props.fillColors[126]} d="M616.1,442.01c-1.63,1-3.26,2-4.88,3c-0.2-0.42-0.4-0.85-0.6-1.27c1.85-0.55,3.69-1.11,5.54-1.66
                C616.16,442.08,616.1,442.01,616.1,442.01z"/>
            <path onClick={() => props.onFill(127)} fill={props.fillColors[127]} d="M1205.67,553.02c-1.35-0.12-2.71-0.24-4.06-0.35c0.01-0.17,0.02-0.34,0.03-0.51c1.35,0,2.71,0,4.06,0
                C1205.69,552.44,1205.68,552.73,1205.67,553.02z"/>
            <path onClick={() => props.onFill(128)} fill={props.fillColors[128]} d="M581,567.51c0.79,0.33,1.57,0.65,2.36,0.98c-0.11,0.27-0.22,0.55-0.33,0.82
                c-0.79-0.31-1.58-0.62-2.37-0.94C580.77,568.08,580.89,567.8,581,567.51z"/>
            <path onClick={() => props.onFill(129)} fill={props.fillColors[129]} d="M1184.55,513.65c-1.01,0.12-2.02,0.24-3.02,0.37c-0.04-0.22-0.07-0.44-0.11-0.66c1-0.18,2-0.35,3-0.53
                C1184.46,513.1,1184.51,513.37,1184.55,513.65z"/>
            <path onClick={() => props.onFill(130)} fill={props.fillColors[130]} d="M603.89,610.75c-0.96,0-1.93,0-2.89,0c0-0.11,0-0.23,0-0.34c0.99,0,1.98,0,2.98,0
                C603.95,610.52,603.92,610.63,603.89,610.75z"/>
            <path onClick={() => props.onFill(131)} fill={props.fillColors[131]} d="M1272.05,578.92c0.19,0.7,0.37,1.39,0.56,2.09c-0.19,0.09-0.38,0.18-0.57,0.27
                c-0.36-0.63-0.72-1.26-1.07-1.89C1271.33,579.24,1271.69,579.08,1272.05,578.92z"/>
            <path onClick={() => props.onFill(132)} fill={props.fillColors[132]} d="M1155.1,552.43c-0.8-0.16-1.6-0.33-2.4-0.49c0.03-0.27,0.05-0.53,0.08-0.8c0.85,0,1.69,0,2.54,0
                C1155.24,551.57,1155.17,552,1155.1,552.43z"/>
            <path onClick={() => props.onFill(133)} fill={props.fillColors[133]} d="M1366.37,588.29c0.83-0.07,1.67-0.13,2.5-0.2c0.01,0.21,0.03,0.43,0.04,0.64
                c-0.83,0.04-1.66,0.08-2.49,0.12C1366.4,588.67,1366.39,588.48,1366.37,588.29z"/>
            <ellipse onClick={() => props.onFill(134)} fill={props.fillColors[134]} cx="1172.77" cy="513.42" rx="1.56" ry="0.22"/>
            <path onClick={() => props.onFill(135)} fill={props.fillColors[135]} d="M712.19,423.99c-0.51,0.57-1.02,1.14-1.53,1.72c-0.2-0.18-0.41-0.36-0.61-0.54
                c0.51-0.57,1.02-1.14,1.53-1.71C711.78,423.63,711.99,423.81,712.19,423.99z"/>
            <path onClick={() => props.onFill(136)} fill={props.fillColors[136]} d="M97.13,786.99c0.14-0.27,0.28-0.53,0.42-0.8c0.23,0.14,0.64,0.27,0.66,0.43c0.03,0.27-0.21,0.56-0.33,0.84
                C97.64,787.31,97.39,787.15,97.13,786.99z"/>
            <path onClick={() => props.onFill(137)} fill={props.fillColors[137]} d="M761.63,743.08c0.57,0.04,1.13,0.09,1.7,0.13c0.01,0.17,0.02,0.34,0.03,0.51
                c-0.57,0.04-1.14,0.09-1.71,0.13C761.64,743.59,761.64,743.33,761.63,743.08z"/>
            <path onClick={() => props.onFill(138)} fill={props.fillColors[138]} d="M598.76,573.69C599.35,573.59,599.06,573.64,598.76,573.69C599.05,573.6,599.33,573.51,598.76,573.69z"/>
            <path onClick={() => props.onFill(139)} fill={props.fillColors[139]} d="M952.17,757.51c-0.08,0.06-0.16,0.13-0.25,0.19c-0.06-0.09-0.11-0.17-0.17-0.26
                C951.9,757.46,952.03,757.49,952.17,757.51z"/>
            <polygon onClick={() => props.onFill(140)} fill={props.fillColors[140]} points="1375.88,624.56 1375.98,624.26 1376.27,624.46 		"/>
            <path onClick={() => props.onFill(141)} fill={props.fillColors[141]} d="M750.12,452.77c0.01-0.11,0-0.31,0.05-0.32c0.12-0.04,0.27,0,0.4,0.01c-0.02,0.12,0,0.32-0.05,0.33
                C750.4,452.83,750.26,452.79,750.12,452.77z"/>
            <polygon onClick={() => props.onFill(142)} fill={props.fillColors[142]} points="1281.95,815.41 1282.29,815.51 1282.01,815.64 		"/>
            <path onClick={() => props.onFill(143)} fill={props.fillColors[143]} d="M1380.5,504.47c-0.03-0.11-0.12-0.25-0.08-0.31c0.05-0.08,0.2-0.1,0.31-0.15
                c0.03,0.11,0.12,0.25,0.08,0.31C1380.76,504.4,1380.61,504.42,1380.5,504.47z"/>
            <path onClick={() => props.onFill(144)} fill={props.fillColors[144]} d="M518.69,616.17c-0.1,0.11-0.19,0.22-0.29,0.32c-0.11-0.2-0.21-0.4-0.32-0.6c0.14-0.04,0.32-0.16,0.42-0.11
                C518.61,615.82,518.63,616.03,518.69,616.17z"/>
            <path onClick={() => props.onFill(145)} fill={props.fillColors[145]} d="M606.36,574.66c0.01-0.12,0.03-0.25,0.04-0.37c0.14,0.13,0.29,0.26,0.43,0.39
                C606.67,574.68,606.52,574.67,606.36,574.66z"/>
            <polygon onClick={() => props.onFill(146)} fill={props.fillColors[146]} points="977.45,779.48 977.36,779.76 977.1,779.57 		"/>
            <path onClick={() => props.onFill(147)} fill={props.fillColors[147]} d="M899.89,555c-0.2,0.12-0.41,0.24-0.38,0.22c-0.03,0.01,0.2-0.07,0.44-0.15
                C899.95,555.07,899.89,555,899.89,555z"/>
            <polygon onClick={() => props.onFill(148)} fill={props.fillColors[148]} points="1146.37,552.38 1146.7,552.26 1146.65,552.57 		"/>
            <polygon onClick={() => props.onFill(149)} fill={props.fillColors[149]} points="1277.11,478.16 1277.01,477.86 1277.29,477.91 		"/>
            <path onClick={() => props.onFill(150)} fill={props.fillColors[150]} d="M601.93,569.09c0.16,0.02,0.33,0.03,0.49,0.05c-0.14-0.04-0.28-0.08-0.43-0.11
                C602,569.03,601.93,569.09,601.93,569.09z"/>
            <path d="M1340.38,731.47c-10.68-1.08-19.93-2.02-29.67-3.01C1315.22,724.27,1331.5,725.86,1340.38,731.47z"/>
            <path d="M1370.76,712.79c8.86,0.62,17.71,1.25,26.57,1.87c-0.05,0.87-0.11,1.73-0.16,2.6c-8.89-0.58-17.77-1.15-26.66-1.73
                C1370.58,714.62,1370.67,713.7,1370.76,712.79z"/>
            <path d="M1349.02,680.95c7.33-3.06,14.94-2.41,22.55-1.78c0.05,0.62,0.11,1.25,0.16,1.87c-7.48,0.63-14.96,1.25-22.44,1.88
                C1349.2,682.26,1349.11,681.61,1349.02,680.95z"/>
            <path d="M1309.24,708.07c5.48-1.99,10.49,0,15.55,1.55c-0.08,0.69-0.15,1.38-0.23,2.07c-5.09-0.66-10.19-1.31-15.28-1.97
                C1309.26,709.17,1309.25,708.62,1309.24,708.07z"/>
            <path d="M1345.97,576.58c0.73-1.55,1.42-3.12,2.2-4.64c2.37-4.58,0.35-7.67-4.66-8.94c-5.8-1.47-9.84,0.28-13.34,4.73
                c-1.45,1.85-3.37,3.34-5.52,4.59c-0.37-5.88,3.27-9.38,7.77-11.04c4.38-1.6,9.38-1.48,14.42-2.16c0.28-3.17-1.59-4.8-5.42-5.26
                c-2.55-0.31-4.99-1.52-7.49-2.33c0.16-0.73,0.31-1.46,0.47-2.19c3.81,0.5,7.62,1.01,12.54,1.65c-2.88-5.32-6.89-4.03-10.4-4.07
                c-3.42-0.03-6.84-0.01-10.26-1.14c6.97-1.57,14.05-4.29,20.88-0.61c4.12,2.22,4.84,6.72,5.05,11.27c0.09,2,1.47,3.97,2.35,5.91
                c0.49,1.07,1.93,2.52,1.63,3c-2.6,4.26-5.49,8.34-8.3,12.47C1347.27,577.42,1346.62,577,1345.97,576.58z"/>
            <path d="M1358.91,560.12c5.81-3.8,15.19-1.31,20.1,4.88c1.01,1.28,0.82,3.51,0.31,5.79C1374.9,562.2,1366.4,562.18,1358.91,560.12
                z"/>
            <path d="M1361.12,524.64c-10.56,1.3-19.69,2.42-29.77,3.65C1335.59,523.65,1353.49,521.47,1361.12,524.64z"/>
            <path d="M1377.7,527.59c-2.14,0-4.28,0-6.42,0c-0.03-0.48-0.06-0.97-0.09-1.45c2.14-0.16,4.28-0.32,6.43-0.48
                C1377.65,526.3,1377.67,526.95,1377.7,527.59z"/>
            <path onClick={() => props.onFill(151)} fill={props.fillColors[151]} d="M875.4,280.88c-0.27-0.97-0.4-1.92-0.8-2.73c-1.21-2.48-2.61-5.4,0.35-7.08c2.47-1.41,6.16-2.78,8.5-1.94
                c9.57,3.45,19.37,4.62,29.39,4.71c3.47,0.03,6.94-0.31,8.71-0.4c2.31,2.39,3.4,4.27,5.03,5.05c4.16,1.99,8.56,3.5,13.2,6.37
                c-3.84,0.97-7.63,2.54-11.51,2.82c-17.05,1.24-34.13,1.98-51.19,3.15c-9.45,0.65-18.86,2.04-28.32,2.56
                c-4.24,0.23-8.55-0.9-12.84-1.3c-1.12-0.1-2.27,0.26-3.4,0.31c-2.48,0.09-5.07,0.61-7.41,0.07c-8.87-2.02-17.41-1.33-26.02,1.59
                c-13.03,4.42-26.64,3.21-40.06,3.19c-30.45-0.06-60.27-8.55-90.95-6.46c-16.58,1.13-33.27,0.7-49.9,1.2
                c-7.44,0.22-14.59,1.37-21.82,4.08c-7.62,2.86-16.14,4.17-24.33,4.51c-23.48,0.98-47.02,0.73-69.88-5.7
                c-23.19-6.52-46.42-12.9-68.84-22.03c-10.08-4.11-21.18-5.65-31.71-8.75c-5.85-1.72-11.45-4.26-16.98-7.27
                c9.78-0.85,19.11,1.25,28.35,3.98c0.35-0.36,0.7-0.71,1.05-1.07c-2.02-1.43-4.03-2.86-6.05-4.29c0.11-0.35,0.22-0.71,0.34-1.06
                c4.87-0.4,9.73-0.91,14.61-1.18c20.72-1.11,41.15,1.82,61.65,4.25c13.69,1.62,27.47,2.52,41.22,3.69
                c5.58,0.47,11.16,0.87,16.75,1.17c0.99,0.05,2.02-0.57,4.33-1.28c-4.52-2.62-8.1-4.44-11.42-6.66
                c-6.15-4.12-12.16-8.45-18.2-12.74c-0.51-0.36-0.85-1.03-1.17-1.61c-1.99-3.65-4.81-7.65-2.06-11.57
                c3.01-4.29,7.64-2.18,11.94-1.45c6,1.03,12.17,1.03,18.2,1.94c8.17,1.23,16.24,3.15,24.41,4.32c10.53,1.52,21.22,2.03,31.66,3.96
                c30.36,5.63,60.89,10.39,90.94,17.57c11.78,2.81,23.35,7.08,35.28,8.53c16.64,2.02,33.51,2.18,50.3,2.77
                c4.25,0.15,8.54-1.39,12.83-1.59c1.29-0.06,2.69,1.76,4.02,2.75c1.3,0.97,2.48,2.69,3.87,2.87c12.84,1.63,25.71,2.97,38.57,4.44
                c2.47,0.28,4.93,0.73,7.37,1.19c10.11,1.88,20.21,3.8,30.31,5.67C874.13,281.46,874.62,281.12,875.4,280.88z"/>
            <path onClick={() => props.onFill(152)} fill={props.fillColors[152]} d="M264.84,182.63c10.55-0.83,17.38,5.68,23.77,12.16c6.94,7.03,10.21,16.11,8.28,25.86
                c-1.23,6.22-3.98,12.45-7.39,17.81c-1.65,2.59-6.71,4.93-9.71,4.41c-15.02-2.59-29.96-5.79-44.68-9.71
                c-2.69-0.72-5.53-5.51-6.1-8.81c-2.89-16.52,2.66-30.41,17.51-38.24C251.78,183.35,258.69,183.71,264.84,182.63z M266.94,238.57
                c0.71-0.67,1.41-1.34,2.12-2.01c-2.19-3.1-4.44-6.16-6.55-9.31c-1.13-1.68-1.88-3.62-3.08-5.24c-0.89-1.2-2.23-2.06-3.36-3.07
                c-0.74,1.5-2.51,3.53-2.06,4.41c1.95,3.76,4.33,7.36,7,10.66C262.54,235.9,264.93,237.08,266.94,238.57z M245.83,205.64
                c-1.15-4.98-0.68-9.09,5.48-9.7c0.36-0.04,1.01-1.74,0.81-1.93c-0.74-0.72-2.06-1.8-2.65-1.55c-2.68,1.13-5.66,2.18-7.63,4.17
                C239.05,199.44,241.18,204.31,245.83,205.64z M258.04,214.48c0.16,0.6,0.32,1.2,0.48,1.79c5.62-1.26,11.24-2.49,16.84-3.82
                c0.73-0.17,1.7-0.8,1.9-1.43c0.3-0.95,0.32-2.23-0.12-3.07c-0.22-0.43-2.09-0.63-2.54-0.21c-3.16,2.92-6.68,4.16-11.05,4.05
                C261.73,211.75,259.87,213.53,258.04,214.48z M267.71,226.04c2.5,0.88,5.01,1.11,6.49,2.47c1.58,1.45,1.94,4.15,3.32,5.93
                c0.75,0.96,2.48,1.4,3.82,1.52c0.48,0.04,1.7-1.63,1.57-2.36C281.72,226.42,275.13,222.96,267.71,226.04z M232.61,215.46
                c0.33,0.28,0.65,0.55,0.98,0.83c2.13-1.04,4.66-1.68,6.25-3.27c0.96-0.96,0.46-3.38,0.61-5.15c-1.79,0.07-4.55-0.55-5.18,0.34
                C233.82,210.23,233.43,213,232.61,215.46z M284.14,209.71c-4.7,2.13-6.41,6.22-4.67,9.54c0.32,0.61,1.2,1.32,1.77,1.28
                c0.61-0.04,1.58-0.84,1.69-1.43C283.43,216.24,283.7,213.34,284.14,209.71z M268.22,210.56c1.23-3.3,2.22-5.21,2.53-7.23
                c0.11-0.7-1.52-1.65-2.35-2.49c-0.92,1.69-2.25,3.3-2.61,5.1C265.59,206.97,266.97,208.32,268.22,210.56z M260.87,186.42
                c-0.25-0.52-0.5-1.04-0.75-1.56c-2.44,1.27-4.88,2.54-7.87,4.1C257.28,191.21,258.44,190.77,260.87,186.42z M277.31,202.78
                c0.32,0.7,0.64,1.41,0.96,2.11c1.85-0.81,3.8-1.47,5.48-2.55c0.4-0.26-0.1-1.93-0.18-2.95
                C281.48,200.52,279.39,201.65,277.31,202.78z M241.63,224.15c0.36,0.49,0.72,0.99,1.08,1.48c1.69-1.35,3.44-2.64,5.01-4.12
                c0.21-0.2-0.48-1.37-0.75-2.09c-1.65,1.12-3.34,2.2-4.94,3.38C241.73,223.03,241.76,223.69,241.63,224.15z M245.73,216.4
                c0.78,0.24,1.57,0.48,2.35,0.72c0.55-1.44,1.33-2.85,1.5-4.34c0.05-0.42-1.68-1.05-2.59-1.58
                C246.57,212.93,246.15,214.66,245.73,216.4z M273.6,191.45c-0.4-0.62-0.8-1.23-1.2-1.85c-1.91,1.28-3.83,2.56-5.74,3.84
                c0.41,0.63,0.83,1.27,1.24,1.9C269.8,194.04,271.7,192.74,273.6,191.45z"/>
            <path onClick={() => props.onFill(153)} fill={props.fillColors[153]} d="M238.27,969.87c-0.64,1.71-1.28,3.41-1.91,5.12c-0.55-0.37-1.1-0.73-1.65-1.1
                c1.21-1.32,2.42-2.63,3.63-3.95C238.34,969.94,238.27,969.87,238.27,969.87z"/>
            <path d="M1014.05,713.78c-3.86,0.3-7.73,0.9-11.59,0.83c-10.44-0.18-20.88-0.65-31.33-0.97c-0.8-0.02-1.6,0.21-2.62,0.36
                c4.9,3.47,46.53,5.48,52.54,2.69c-1.43-1.35-2.87-2.7-4.3-4.05c1.44-0.52,3.39-1.89,4.23-1.39c2.22,1.32,4.39,3.2,5.63,5.39
                c0.41,0.73-1.9,3.24-3.26,4.64c-0.55,0.57-1.9,0.44-2.89,0.49c-18.28,0.9-36.51,0.75-54.54-3.02c-1.93-0.4-3.95-0.41-5.93-0.55
                c-16.05-1.14-32.1-2.43-48.16-3.29c-4.33-0.23-8.72,0.91-13.09,1.39c-1.57,0.17-3.16,0.25-5.43,0.43
                c3.45-5.19,12.69-9.33,17.08-8.43c5.84,1.2,11.69,3.16,17.56,3.27c12.93,0.24,25.88-0.64,38.83-0.73
                c12.43-0.09,24.86,0.25,37.28,0.39c3.24,0.04,6.47,0.01,9.71,0.01C1013.85,712.09,1013.95,712.93,1014.05,713.78z"/>
            <path d="M705.83,580.56c-0.66,10.55-1.31,21.1-1.93,31.11c21.81,1.31,43.44,2.61,65.08,3.91c0.01,0.96,0.02,1.91,0.02,2.87
                c-2.97,0.08-5.97,0.42-8.92,0.2c-14.39-1.06-28.76-2.82-43.16-3.25c-12.15-0.36-12.57,0.59-14.72,12.16
                c-0.42,2.27-0.33,4.63-0.7,6.91c-0.14,0.83-0.94,1.55-1.44,2.32c-0.71-0.74-1.99-1.46-2.03-2.24c-0.12-2.29,0.28-4.61,0.43-6.92
                c0.17-2.54,0.29-5.09,0.48-8.54c-3.74,1.61-6.45,2.78-9.16,3.95c-0.33-0.44-0.66-0.88-0.99-1.32c1.34-1.41,2.42-3.49,4.05-4.13
                c6.59-2.55,6.89-8.16,7.41-13.82c0.62-6.75,1.24-13.5,2.05-20.23c0.15-1.21,1.2-2.3,1.84-3.45
                C704.72,580.24,705.28,580.4,705.83,580.56z"/>
            <path d="M942.94,666.57c-6.7-0.49-13.41-0.98-20.11-1.48c0-0.57,0-1.14,0-1.71c1.48-0.26,2.99-0.83,4.45-0.71
                c5.29,0.43,10.55,1.19,15.84,1.62c5.09,0.42,10.2,0.69,15.3,0.84c9.77,0.27,19.55,0.27,29.31,0.72c1.85,0.09,3.63,1.73,5.16,3.14
                c-12.68,1.51-24.81-4.83-38.17-0.34c11.59,1.24,22.26,2.37,32.94,3.51c-0.05,0.65-0.1,1.31-0.14,1.96
                c-16.08-1.28-32.17-3.73-48.23-3.52c-15.99,0.21-31.94,3.1-48.75,4.9c5.22-3.47,10.62-4.51,16.28-4.9
                c12.02-0.81,24.04-1.68,36.07-2.53C942.91,667.58,942.93,667.08,942.94,666.57z"/>
            <path d="M844.08,708.09c-6.33,1.01-12.7,2.97-18.99,2.74c-6.15-0.22-12.23-2.66-18.71-3.13c3.6,1.67,7.2,3.33,10.8,5
                c-0.17,0.46-0.34,0.93-0.51,1.39c-7.36-1.6-14.72-3.21-22.07-4.81c0.01-0.34,0.01-0.67,0.02-1.01c1.45-0.53,2.9-1.06,4.24-1.55
                c-12.67-1.87-25.33-3.73-37.99-5.6c0.07-0.72,0.14-1.44,0.22-2.15c5.08,0.52,10.17,0.97,15.24,1.58
                c8.24,0.98,16.48,1.95,24.69,3.15c5.7,0.83,11.3,2.5,17.02,2.98c6.07,0.51,12.22,0.08,18.33-0.01c2.54-0.03,5.07-0.22,7.61-0.34
                C844,706.92,844.04,707.51,844.08,708.09z"/>
            <path d="M678.86,664.94c1.41-1.85,2.15-3.53,3.43-4.38c8.22-5.4,17.15-7.44,27-5.87c14.39,2.28,28.83,4.2,43.24,6.33
                c0.9,0.13,1.74,0.71,2.61,1.07c-0.02,0.48-0.04,0.96-0.06,1.43c-1.92,0-4.01,0.51-5.72-0.08c-13.74-4.75-28.23-3.65-42.31-5.67
                c-8.67-1.24-16.21,2.49-23.7,6.11c-0.73,0.35-1.36,0.93-2.08,1.3C681.03,665.31,680.64,665.14,678.86,664.94z"/>
            <path d="M955.86,645.94c-0.41,1.11-0.7,3.45-1.43,3.6c-5.45,1.12-3.94,5.77-5.09,9.09c-0.45,1.31-1.53,2.41-2.32,3.6
                c-0.64-1.36-1.83-2.73-1.8-4.08c0.05-2.28,0.56-4.67,1.41-6.8c3.86-9.63,3.68-19.25,0.67-29.03c-0.36-1.17,0.48-2.7,0.77-4.07
                c1.13,0.92,3.04,1.68,3.27,2.79C953.01,629.17,954.35,637.37,955.86,645.94z"/>
            <path d="M954.51,611.47c3.12-0.45,6.34-1.63,9.35-1.24c19.77,2.58,40.99-3.29,59.1,9.2c-0.11,0.65-0.22,1.31-0.32,1.96
                c-1.74-0.45-3.5-0.82-5.21-1.35c-4.51-1.39-9-2.84-13.49-4.28c-11.57-3.71-23.52-1.87-35.31-2.41c-3.28-0.15-6.59,0.35-9.88,0.51
                c-1.33,0.06-2.66,0.01-3.99,0.01C954.67,613.06,954.59,612.27,954.51,611.47z"/>
            <path d="M1041.89,714.93c2.89-0.69,5.76-1.88,8.66-1.94c5.26-0.11,10.64-0.06,15.75,1.01c2,0.42,3.39,3.76,5.06,5.76
                c-1.78,0.81-3.53,2.24-5.35,2.33c-8.39,0.45-16.19-2.08-23.96-4.56C1041.99,716.67,1041.94,715.8,1041.89,714.93z M1065.16,718.86
                c0.06-0.42,0.12-0.83,0.18-1.25c-1.58,0-3.15,0-4.73,0c-0.02,0.19-0.05,0.39-0.07,0.58
                C1062.08,718.42,1063.62,718.64,1065.16,718.86z"/>
            <path d="M887.85,712.25c-3.85,0.29-7.72,0.47-11.56,0.89c-7.21,0.79-14.47,1.37-21.58,2.69c-5.86,1.09-11.32,0.56-16.83-1.54
                c16.57-1.84,32.87-6.61,49.73-3.54C887.68,711.25,887.77,711.75,887.85,712.25z"/>
            <path d="M959.02,551.09c17.76-4.48,35.19,1.15,52.79,1.89c-0.02,0.62-0.03,1.24-0.05,1.87c-17.55-0.83-35.09-1.67-52.64-2.5
                C959.09,551.92,959.06,551.51,959.02,551.09z"/>
            <path d="M794.81,665.92c17.32,2.07,34.65,4.14,51.97,6.21c-8.08,1.38-16.04,3.43-24.31,1.07c-4.9-1.4-10.05-1.96-15.1-2.83
                c-4.29-0.74-8.6-1.38-12.9-2.07C794.58,667.51,794.7,666.71,794.81,665.92z"/>
            <path d="M747.22,692.7c-8.93,3.15-31.54-3.73-32.68-8.27C725.3,687.16,736.26,689.93,747.22,692.7z"/>
            <path d="M692.67,685.06c1.89-7.63,3.55-14.32,5.32-21.44C702.52,668.4,699.39,681.69,692.67,685.06z"/>
            <path d="M879.61,665.79c6.35,0,12.45,0,18.55,0c0.08,0.47,0.17,0.94,0.25,1.41c-2.69,0.67-5.36,1.39-8.06,1.99
                c-2.08,0.46-4.2,0.69-6.28,1.15C880.21,671.19,878.9,669.69,879.61,665.79z"/>
            <path d="M818.23,620.2c-4.51,5.39-10.32,5.84-13.7,1.57C809.43,621.21,813.83,620.7,818.23,620.2z"/>
            <path d="M787.65,666.6c-5.29,0-10.59,0-15.88,0c-0.01-0.52-0.03-1.05-0.04-1.57c5.32,0,10.63,0,15.95,0
                C787.67,665.56,787.66,666.08,787.65,666.6z"/>
            <path d="M776.51,616.61c3.51,0.2,7.02,0.4,10.54,0.6c-0.03,0.67-0.06,1.33-0.09,2c-3.52,0-7.05,0-10.57,0
                C776.42,618.34,776.46,617.48,776.51,616.61z"/>
            <path d="M1035.3,555.11c-3.56,0.69-6.82,3.64-10.75,1.62c-0.11-0.67-0.21-1.33-0.32-2c3.57-0.58,7.15-1.16,10.72-1.75
                C1035.07,553.69,1035.19,554.4,1035.3,555.11z"/>
            <path d="M795.93,620.55c-1.07,0-2.14,0-3.21,0c0-0.34,0.01-0.68,0.01-1.02c1.07,0,2.14,0,3.21,0
                C795.94,619.88,795.94,620.21,795.93,620.55z"/>
            <path d="M385.76,790.41c-16.12,4-32.65,4.46-49.43,4.94c6.78,3.25,10.6,3.53,16.81,2.14c2.32-0.52,4.93-0.31,7.31,0.1
                c2.08,0.36,3.97,1.7,6.05,2.17c1.88,0.43,3.9,0.26,5.86,0.35c0.11,0.46,0.22,0.93,0.33,1.39c-2.79,0.72-5.56,1.56-8.38,2.13
                c-2.76,0.56-5.62,0.7-8.37,1.31c-4.59,1.01-8.45,0.08-12.67-2.29c-8.19-4.59-17.47-5.01-26.57-3.9c-2.16,0.26-3.88,3.64-5.95,5.42
                c-1.41,1.21-3,2.87-4.65,3.05c-17.17,1.89-34.37,3.55-51.56,5.22c-4.79,0.46-9.59,0.98-14.39,1.05c-4.17,0.06-8.94,1.07-11.1-4.37
                c-0.26-0.66-1.2-1.35-1.94-1.49c-9.36-1.81-18.47-7.87-28.37-1.64c-1.18,0.75-3.18,0.1-4.76,0.34c-7.21,1.1-14.49,1.98-21.58,3.63
                c-2.36,0.55-4.25,3.16-7.19,5.49c2.72,0.95,4.14,1.44,6.83,2.38c-3.82,2.84-6.82,5.28-10.02,7.41c-6.96,4.63-9.26,12.55-5.8,20.34
                c0.68,1.52,1.09,3.16,1.04,5.03c-4.56-1.62-6.27-5.29-6.21-9.45c0.1-6.93-0.21-14.2,7.42-18.32c1.13-0.61,1.72-2.78,1.99-4.33
                c1.19-6.87,5.35-10.91,11.83-12.65c3.19-0.86,6.54-1.11,9.79-1.78c6.11-1.26,12.16-2.89,18.32-3.83c3.95-0.6,8.21-0.99,12.04-0.17
                c7.71,1.66,15.84,3.05,22.71,6.64c13.26,6.93,25.84,0.07,38.69-0.11c7.97-0.11,15.93-0.92,23.9-0.98
                c7.98-0.06,7.98,0.18,10.81-8.65c-2.28,0-4.34-0.18-6.36,0.03c-20.51,2.12-41.01,4.33-61.53,6.41c-2.99,0.3-6.03,0.04-9.04,0.04
                c-0.08-0.6-0.16-1.2-0.23-1.8c1.54-0.45,3.05-1.12,4.61-1.31c39.49-4.82,79.13-7.65,118.9-8.73c8.61-0.23,17.17-1.94,25.77-2.9
                c1.65-0.18,3.34-0.03,5.02-0.03C385.71,789.25,385.73,789.83,385.76,790.41z"/>
            <path d="M209.62,814.99c-5.6,0-11.19,0-16.79,0c-0.05,0.4-0.1,0.8-0.16,1.21c1.45,0.51,2.89,1.03,4.34,1.54
                c8.26,2.93,12.55,8.96,13.22,17.52c0.11,1.43-1.21,2.98-1.87,4.47c-0.83-1.29-2.23-2.51-2.39-3.88
                c-1.03-9.02-5.11-13.76-13.91-15.81c-2.25-0.52-4.5-1.16-6.65-2c-0.73-0.29-1.57-1.34-1.6-2.08c-0.04-0.79,0.61-1.91,1.3-2.38
                C188.51,811.27,205.8,811.49,209.62,814.99z"/>
            <path d="M133.53,839.71c0.1,1.22,0.25,2.43,0.3,3.65c0.22,5.29,0.48,10.58,0.59,15.87c0.03,1.61,0.11,3.53-0.67,4.78
                c-3.05,4.91-6.31,9.71-9.78,14.33c-0.78,1.04-2.71,1.2-4.12,1.77c0.16-1.45-0.06-3.12,0.58-4.32c1.08-2.03,2.52-3.95,4.13-5.6
                c5.59-5.7,5.87-12.64,5.06-19.93c-0.43-3.89-0.64-7.79,2.4-10.78C132.52,839.56,133.02,839.63,133.53,839.71z"/>
            <path d="M319.66,761c-9.55,3.29-18.67,6.15-27.98,0.71C298.05,755.29,314.92,754.64,319.66,761z"/>
            <path d="M141.6,803.95c7.07,6.3,14.24-0.58,20.88,1.6c-3.02,4.1-32.9,7.16-40.96,4.06C128.04,805.54,136.85,811.52,141.6,803.95z"
                />
            <path d="M152.95,759.06c11.63-1.24,23.26-2.47,34.89-3.71c0.16,0.82,0.31,1.64,0.47,2.45c-2.6,0.6-5.17,1.38-7.8,1.77
                c-3.53,0.52-7.13,0.59-10.65,1.13C164.06,761.59,158.37,761.92,152.95,759.06z"/>
            <path d="M290.28,704.84c1.31,0.09,2.68-0.03,3.89,0.36c0.76,0.25,1.74,1.24,1.77,1.94c0.02,0.63-1.02,1.71-1.75,1.88
                c-2.23,0.52-4.55,0.98-6.81,0.88c-1.32-0.06-2.59-1.24-3.88-1.92c1.28-1.07,2.44-2.33,3.87-3.12c0.72-0.4,1.92,0.06,2.9,0.13
                C290.27,704.93,290.27,704.88,290.28,704.84z"/>
            <path d="M218.74,755.27c-5.42,4.19-13.05,4.06-17.01,0C207.4,755.27,212.64,755.27,218.74,755.27z"/>
            <path d="M346.95,851.88c-2.4,4.91-7.13,7.87-12.73,6.48c4.53-3.11,8.09-5.56,11.66-8.01C346.24,850.86,346.6,851.37,346.95,851.88
                z"/>
            <path d="M340.95,841.03c3.57-5.78,9.4-6.3,12.72-1.69C349.58,839.88,345.71,840.39,340.95,841.03z"/>
            <path d="M223.53,687.72c2.72,0.37,5.45,0.74,8.17,1.12c-0.05,0.61-0.1,1.23-0.15,1.84c-2.74-0.19-5.48-0.38-8.22-0.58
                C223.39,689.3,223.46,688.51,223.53,687.72z"/>
            <path d="M310.6,709.17c-1.93,0-3.86,0-5.78,0c-0.03-0.67-0.06-1.34-0.09-2c1.92-0.12,3.85-0.24,5.77-0.35
                C310.53,707.6,310.56,708.38,310.6,709.17z"/>
            <path d="M149,756.12c-0.65-1.54-1.29-3.09-1.94-4.63c0.63-0.29,1.27-0.58,1.9-0.87c0.7,1.52,1.4,3.04,2.09,4.56
                C150.37,755.5,149.68,755.81,149,756.12z"/>
            <path d="M152.82,740.68c-1.03,0.62-1.83,1.42-2.2,1.26c-0.72-0.33-1.2-1.2-1.78-1.84c0.55-0.48,1.25-1.45,1.62-1.34
                C151.21,739,151.8,739.8,152.82,740.68z"/>
            <path d="M640.16,831.04c7.81,8.1,17.78,9.44,26.62,12.63c8.71,3.14,18.11,4.39,27.21,6.46c-0.07,0.52-0.13,1.05-0.2,1.57
                c-0.71,0.18-1.49,0.64-2.13,0.5c-10.47-2.38-21.12-4.2-31.32-7.47c-7.48-2.4-14.14-7.26-21.56-9.97
                c-5.12-1.87-10.89-1.96-16.38-2.78c-1.32-0.2-2.68-0.16-4.02-0.22c-0.12-0.64-0.23-1.28-0.35-1.92c2.6-0.73,5.46-2.55,7.74-2
                c12.3,2.97,23.49-3.26,35.32-3.7c1.32-0.05,2.65-0.51,3.96-0.44c20.22,1.04,40.49-1.77,60.72,1.38c8.12,1.27,16.57,0.36,24.85,0.8
                c2.56,0.14,5.06,1.43,7.59,2.19c-0.13,0.49-0.26,0.99-0.39,1.48c-18.93-0.94-37.87-1.87-56.8-2.81c-0.05,0.52-0.09,1.03-0.14,1.55
                c22.22,5.09,44.68,8.47,67.72,9.12c-5.36,2.41-10.96,3.14-16.56,2.55c-9.36-0.99-18.69-2.34-28-3.81
                c-5.41-0.85-10.71-2.49-16.13-3.27c-7.86-1.13-15.77-1.9-23.68-2.63c-3.55-0.33-7.44,0.77-9.76-3.26
                c-0.24-0.42-1.16-0.59-1.79-0.64C661.72,825.38,651.47,828.76,640.16,831.04z"/>
            <path d="M605.38,833.04c0.2-0.6,0.22-1.42,0.64-1.76c7.34-6.02,13.48-13.28,24.07-14.91c13.91-2.14,27.53-6.14,41.27-9.35
                c10.16-2.37,20.27-6.41,30.49-6.69c11.95-0.32,24.02,2.4,35.97,4.25c6.72,1.04,13.29,2.97,19.93,4.5
                c1.94,0.45,3.91,0.81,5.83,1.36c16.76,4.78,33.51,9.59,50.26,14.42c0.55,0.16,1,0.68,2.51,1.74c-2.13,0.69-3.4,1.51-4.59,1.41
                c-3.46-0.27-6.95-0.65-10.31-1.46c-7.6-1.82-15.14-3.87-22.68-5.94c-13.1-3.6-26.15-7.35-39.26-10.92
                c-18.13-4.94-36.09-7.8-54.82-1.45c-10.9,3.69-22.73,4.57-34.08,6.97c-8.78,1.86-17.54,3.89-26.17,6.33
                c-2.55,0.72-4.64,3.06-6.92,4.69c-3.69,2.65-7.37,5.34-11.05,8.01C606.1,833.85,605.74,833.44,605.38,833.04z"/>
            <path d="M1002.41,817.51c12.94,1.12,25.61,3.1,38.28,3.06c12.62-0.04,25.24-2.1,38.1-3.3
                C1073.64,825.36,1007.73,825.61,1002.41,817.51z"/>
            <path d="M959.73,810.28c-4.42-1.09-8.98-1.8-13.18-3.46c-1.54-0.61-3.14-3.47-3.08-5.25c0.13-4.21,9.68-8.92,13.43-7
                c3.38,1.72,6.88,3.26,10.04,5.32c1.45,0.94,3.44,3.6,3.05,4.32c-1.09,2.02-3.1,3.79-5.14,4.96c-1.25,0.71-3.23,0.13-4.88,0.13
                C959.89,809.62,959.81,809.95,959.73,810.28z M964.61,806.05c0.2-0.87,0.39-1.74,0.59-2.6c-3.67-1.79-7.2-4.13-11.07-5.1
                c-1.6-0.4-3.94,2.13-5.94,3.33c1.52,1.25,2.89,3.29,4.58,3.6C956.63,805.98,960.65,805.85,964.61,806.05z"/>
            <path d="M934.63,804.22c-1.89-1.49-3.45-2.72-5.72-4.51c0.72,2.72,1.09,4.09,1.45,5.46c-1.23,0.18-2.45,0.47-3.68,0.52
                c-1.98,0.08-4.26,0.63-5.87-0.14c-2.27-1.09-5.39-2.96-5.74-4.93c-0.56-3.16,2.16-5.75,5.64-5.45c4.4,0.38,8.86,1.15,13.07,2.48
                C937.23,798.74,937.33,801.4,934.63,804.22z M922.31,802.29c0.37-1.09,0.79-1.69,0.65-2.11c-0.17-0.51-0.8-1.18-1.23-1.19
                c-0.44-0.01-1.32,0.93-1.25,1.1C920.77,800.74,921.38,801.24,922.31,802.29z"/>
            <path d="M831.4,832.59c5.83-1.94,20.84,1.79,25.93,7.72c-8.77-2.04-17.55-4.07-26.32-6.11
                C831.13,833.66,831.26,833.12,831.4,832.59z"/>
            <path d="M1101.59,840.45c-11.05,0.75-22.1,1.5-34.08,2.31c3.46-3.6,26.04-5.91,34.03-4.3
                C1101.55,839.13,1101.57,839.79,1101.59,840.45z"/>
            <path d="M739.14,859.04c-8.38-0.84-16.77-1.68-26.28-2.63C717.59,852.08,735.24,853.86,739.14,859.04z"/>
            <path d="M983.2,795c2.71,2.35,4.38,3.8,6.05,5.24c-2.34,1.98-4.46,4.43-7.14,5.67c-0.92,0.42-3.24-2.2-4.92-3.44
                C978.97,800.27,980.74,798.06,983.2,795z"/>
            <path d="M1117.13,810.92c-6.28,1.45-12.45,2.91-18.64,4.24c-0.6,0.13-1.73-0.43-1.98-0.97c-0.24-0.51,0.13-1.62,0.6-2.1
                C1099.42,809.74,1113.62,808.93,1117.13,810.92z"/>
            <path d="M1131.64,836.86c-2.93,0.24-5.86,0.47-8.79,0.71c-0.08-0.64-0.16-1.27-0.24-1.91c2.97-0.39,5.93-0.79,8.9-1.18
                C1131.56,835.27,1131.6,836.06,1131.64,836.86z"/>
            <path d="M1229.59,672.85c-1.22-0.47-2.54-0.77-3.64-1.43c-12.87-7.72-27.46-8.3-41.74-10.19c-2.63-0.35-5.43-0.2-7.87-1.08
                c-8.17-2.94-16.2-6.25-24.2-10.6c1.13-0.19,2.39-0.78,3.37-0.49c3.47,1.04,7.1,1.95,10.23,3.7c6.11,3.42,12.48,4.8,19.44,5.46
                c10.21,0.97,20.4,2.81,30.39,5.18c4.71,1.12,8.93,4.31,13.32,6.65c0.59,0.31,0.87,1.2,1.29,1.82
                C1229.98,672.21,1229.79,672.53,1229.59,672.85z"/>
            <path d="M1279.98,731.37c0.13-1.74-0.4-4.05,0.51-5.11c1.78-2.06,4.36-2.71,6.37-0.73c-2.46,1.46-4.69,2.79-6.92,4.11
                C1279.96,730.22,1279.97,730.79,1279.98,731.37z"/>
            <path d="M1268.86,659.11c0.24-1.53-0.05-3.77,0.84-4.45c1.83-1.39,4.3-1.93,6.5-2.82c0.31,0.56,0.63,1.12,0.94,1.68
                c-2.37,2.01-4.74,4.01-7.12,6.02C1269.63,659.39,1269.24,659.25,1268.86,659.11z"/>
            <path d="M1301.4,786.17c2.9-2.32,6.17-2.35,9.6-1.44c0.09,0.56,0.18,1.12,0.28,1.68c-3.19,0.53-6.38,1.05-9.58,1.58
                C1301.6,787.38,1301.5,786.77,1301.4,786.17z"/>
            <path d="M1286.48,742.74c1.35-0.33,2.7-0.66,4.06-0.99c0.13,0.7,0.47,1.96,0.36,2c-1.27,0.46-2.6,0.74-3.91,1.06
                C1286.82,744.12,1286.65,743.43,1286.48,742.74z"/>
            <path d="M1275.58,730.98c0.15,1.73,0.3,3.47,0.46,5.2c-0.65,0.06-1.3,0.13-1.95,0.19c-0.19-1.76-0.38-3.51-0.56-5.27
                C1274.21,731.06,1274.89,731.02,1275.58,730.98z"/>
            <path d="M1267.14,611.24c-1.24-1.09-2.47-2.18-3.71-3.27c0.37-0.47,0.74-0.93,1.11-1.4c1.37,0.96,2.75,1.92,4.12,2.88
                C1268.15,610.04,1267.64,610.64,1267.14,611.24z"/>
            <path d="M1191.02,651.41c-0.62,0.5-1.17,1.05-1.26,0.98c-0.42-0.32-0.83-0.76-1-1.24c-0.06-0.17,0.65-0.89,0.83-0.83
                C1190.06,650.48,1190.43,650.93,1191.02,651.41z"/>
            <path d="M1064.39,585.58c5.96-4.17,51.8,0.48,60.32,6.04C1104.69,589.62,1084.54,587.6,1064.39,585.58z"/>
            <path d="M1081.78,541.5c6.09-3.56,12.92-2.58,18.42,0.17c7.08,3.53,13.11,9.16,19.59,13.88c-0.15,0.44-0.29,0.88-0.44,1.33
                c-1.26-0.12-2.77,0.17-3.74-0.42c-3.8-2.33-7.97-4.42-10.99-7.57c-4.39-4.58-9.38-5.86-15.31-5.44c-2.52,0.18-5.06,0.03-7.58,0.03
                C1081.74,542.82,1081.76,542.16,1081.78,541.5z"/>
            <path d="M1114.75,570.06c-12.57-1.3-25.35-2.61-38.57-3.97C1081.35,561.6,1109.09,564.97,1114.75,570.06z"/>
            <path d="M1092.87,548.85c-10.47-0.71-18.49,3.9-25.6,11.92c-1.52-5.39,1.02-7.34,3.74-9.35
                C1077.76,546.43,1085.01,545.64,1092.87,548.85z"/>
            <path d="M1074.48,597.15c7.2-8.62,23.77-8.03,29.07,0.32c-4.85-0.94-9.51-2.5-14.19-2.57
                C1084.74,594.81,1080.09,596.23,1074.48,597.15z"/>
            <path d="M1085.34,524.19c0.01-4.28,3.37-8.98,6.07-7.71c2.59,1.22,4.08,4.88,5.89,7.57c0.17,0.26-0.62,1.61-1.18,1.79
                c-0.54,0.18-1.86-0.38-1.98-0.84c-1.7-6.37-4.48-3.96-7.42-1.03C1086.46,524.23,1085.82,524.12,1085.34,524.19z"/>
            <path d="M1143.68,683.7c-6.5,2.17-12.14,1-15.84-4.09c5.89,0.99,10.97,1.84,16.05,2.69
                C1143.82,682.76,1143.75,683.23,1143.68,683.7z"/>
            <path d="M1090.63,692.8c4.3-0.2,8.59-0.41,12.89-0.61c0.05,0.88,0.11,1.77,0.16,2.65c-4.32,0.3-8.64,0.6-12.95,0.89
                C1090.7,694.76,1090.67,693.78,1090.63,692.8z"/>
            <path d="M1119.89,563.25c-4.41-2.06-8.82-4.11-13.24-6.17c4.12-1.95,10.42,0.92,13.97,5.11
                C1120.38,562.54,1120.13,562.9,1119.89,563.25z"/>
            <path d="M1116.77,390.81c1.66-11.48,3.58-22.94,4.88-34.46c0.67-5.89,0.47-11.91,0.25-17.86c-0.35-9.31-1.07-18.6-1.6-27.9
                c-0.04-0.65-0.02-1.42,0.29-1.95c0.9-1.52,1.96-2.95,2.96-4.42c0.51,1.7,1.72,3.53,1.42,5.07c-2.07,10.54-0.15,20.84,1.14,31.23
                c0.54,4.4,0.42,8.95,0.01,13.38c-1.3,13.87-2.88,27.72-4.36,41.58c-0.12,1.16-0.05,2.41-0.5,3.43c-0.33,0.74-1.37,1.17-2.1,1.74
                c-0.5-0.74-1.36-1.46-1.42-2.24c-0.18-2.48-0.07-4.98-0.07-7.48C1117.37,390.88,1117.07,390.85,1116.77,390.81z"/>
            <path d="M1129.24,426.21c-0.51-2.16-1.45-4.31-1.46-6.47c-0.05-7.82-0.02-15.65,0.41-23.45c0.57-10.29,1.57-20.56,2.31-30.85
                c0.13-1.82,0.01-3.66-0.16-5.48c-0.97-10.37-2.06-20.73-2.91-31.11c-0.14-1.7,0.73-3.47,1.13-5.21c0.57,0.01,1.14,0.01,1.71,0.02
                c0.36,1.6,0.96,3.18,1.04,4.79c0.19,3.98,0.01,7.98,0.23,11.96c0.68,12.06,2.06,24.12,2.06,36.18c0,10.75-1.63,21.49-2.25,32.24
                c-0.33,5.71-0.06,11.46-0.06,17.2C1130.59,426.1,1129.91,426.16,1129.24,426.21z"/>
            <path d="M1070.68,299.48c0.07,1.37,0.31,2.75,0.19,4.1c-1.38,15.1-2.34,30.27-4.4,45.28c-1.44,10.48-4.38,20.76-6.74,31.11
                c-0.13,0.59-1.36,1.5-1.71,1.35c-0.76-0.31-1.77-1.18-1.8-1.86c-0.07-1.62,0.17-3.33,0.64-4.89c4.1-13.59,6.27-27.33,6-41.67
                c-0.18-9.49,1.87-19.05,3.2-28.53c0.26-1.87,1.79-3.57,2.74-5.35C1069.42,299.18,1070.05,299.33,1070.68,299.48z"/>
            <path d="M1079.8,344.58c7.12,23.49,11.99,47.46,14.67,72.15c-3.4,0.72-4.65-1.36-5.02-4.25c-0.67-5.26-0.78-10.61-1.81-15.79
                c-2.8-14.1-6.01-28.11-8.91-42.19c-0.64-3.12-0.67-6.36-0.99-9.54C1078.43,344.84,1079.12,344.71,1079.8,344.58z"/>
            <path d="M1119.78,296.25c-2.19,0.35-4.55,1.41-6.53,0.92c-6.51-1.6-12.87-3.81-19.31-5.69c-2.55-0.75-5.17-1.25-8.25-1.97
                c1.21-1.15,2.2-2.09,3.37-3.2c-1.67-0.86-3.25-1.68-4.84-2.5c0.1-0.59,0.2-1.18,0.29-1.78c2.06,0.12,4.16,0.04,6.18,0.39
                c2.75,0.48,5.46,1.23,8.15,2c1.15,0.33,2.2,1.02,3.3,1.54c-1,0.81-1.96,1.66-3,2.41c-0.46,0.33-1.06,0.46-2.49,1.04
                c8.3,1.99,15.63,3.75,22.97,5.52C1119.68,295.37,1119.73,295.81,1119.78,296.25z"/>
            <path d="M1131.23,293.36c1.85-0.24,3.7-0.49,6.44-0.84c-8.37-3.92-15.89-1.5-23.62-0.11c3.89-4.33,9.11-5.31,14.28-5.41
                c3.88-0.07,8.07,0.58,11.61,2.1c3.13,1.34,5.56,4.33,8.29,6.58c-0.34,0.76-0.68,1.52-1.03,2.28c-5.38-0.67-10.76-1.35-16.13-2.02
                C1131.13,295.08,1131.18,294.22,1131.23,293.36z"/>
            <path d="M1134.68,271.82c-18.85-1.3-37.49-10.62-56.63-1.36c-0.13-0.55-0.25-1.1-0.38-1.65c0.65-0.78,1.18-2.06,1.97-2.25
                c5.25-1.22,10.66-3.46,15.81-3c12.32,1.09,24.53,3.48,36.78,5.39c0.89,0.14,1.72,0.61,2.59,0.92
                C1134.77,270.53,1134.72,271.17,1134.68,271.82z"/>
            <path d="M1051.67,288.17c-0.91,1.49-1.56,2.89-2.5,4.04c-1.81,2.22-3.41,5.08-5.8,6.24c-2.61,1.27-6.01,0.92-9.22,1.3
                C1034.92,291.61,1043.09,286.32,1051.67,288.17z"/>
            <path d="M1070.43,284.57c-3.6,0.91-7.21,1.81-10.82,2.72c1.62,6.23,5.7,2.45,8.97,2.93c-1.19,3.87-3.92,5.87-6.97,4.45
                c-2.66-1.24-4.41-4.44-6.57-6.77c2.44-2.14,4.58-5.02,7.42-6.17c2.18-0.88,5.24,0.43,7.91,0.76
                C1070.41,283.18,1070.42,283.88,1070.43,284.57z"/>
            <path d="M1058.65,301.34c0.49,5.71-9.52,7.46-4.36,15.77c-3.53-0.58-5.56-0.91-7.6-1.25c0.36-1.82,0.16-4.08,1.18-5.39
                c2.84-3.67,6.19-6.96,9.33-10.4C1057.68,300.5,1058.16,300.92,1058.65,301.34z"/>
            <path d="M1094.18,307.01c1.89-0.66,3.74-1.48,5.68-1.92c0.78-0.18,1.92,0.27,2.56,0.82c0.45,0.39,0.68,1.86,0.39,2.1
                c-0.67,0.55-1.73,0.92-2.6,0.87c-2.09-0.13-4.17-0.55-6.25-0.85C1094.04,307.68,1094.11,307.34,1094.18,307.01z"/>
            <path d="M1111.93,307.38c-0.28,2.2-0.55,4.41-0.83,6.61c-0.95-0.32-2.73-0.65-2.73-0.96c0.01-2.04,0.41-4.08,0.69-6.12
                C1110.01,307.06,1110.97,307.22,1111.93,307.38z"/>
            <path d="M1133.14,304.43c-1.26-1.48-2.56-2.94-3.69-4.51c-0.07-0.1,1.18-1.14,1.82-1.75c1.26,1.58,2.51,3.16,3.77,4.75
                C1134.4,303.42,1133.77,303.92,1133.14,304.43z"/>
            <path d="M1136.9,277.44c-2.52-0.14-5.05-0.27-7.57-0.41c0.03-0.56,0.05-1.11,0.08-1.67c2.51,0,5.02,0,7.52,0
                C1136.92,276.06,1136.91,276.75,1136.9,277.44z"/>
            <path d="M1088.67,251.05c-2.09,1.03-4.19,2.07-6.28,3.1c-0.27-0.52-0.55-1.04-0.82-1.56c2.05-1.12,4.11-2.23,6.16-3.35
                C1088.04,249.85,1088.35,250.45,1088.67,251.05z"/>
            <path d="M1078.45,284.88c-1.76,0.26-3.52,0.52-5.27,0.78c-0.1-0.69-0.2-1.38-0.31-2.06c1.76-0.25,3.51-0.51,5.27-0.76
                C1078.24,283.51,1078.35,284.2,1078.45,284.88z"/>
            <path d="M1072.01,330.28c1.85-0.43,3.71-0.86,5.56-1.28c0.15,0.74,0.3,1.48,0.45,2.22c-1.87,0.35-3.74,0.7-5.61,1.04
                C1072.28,331.6,1072.14,330.94,1072.01,330.28z"/>
            <path d="M1058.31,318.85c-1.01-1.19-1.89-1.9-2.24-2.81c-0.13-0.33,1.29-1.74,1.54-1.63c0.95,0.42,1.85,1.17,2.43,2.03
                C1060.2,316.67,1059.16,317.71,1058.31,318.85z"/>
            <path d="M1144.91,302.79c-1.33-0.14-2.67-0.28-4-0.41c0.09-0.73,0.18-1.45,0.27-2.18c1.33,0.26,2.66,0.52,3.99,0.77
                C1145.09,301.58,1145,302.19,1144.91,302.79z"/>
            <path d="M1084.99,304.14c-0.92,0.61-1.44,1.21-1.8,1.12c-0.47-0.11-0.86-0.73-1.16-1.2c-0.05-0.08,0.58-0.82,0.86-0.8
                C1083.42,303.29,1083.93,303.66,1084.99,304.14z"/>
            <path d="M1052.3,293.73c-0.12,0.26-0.18,0.64-0.37,0.72c-0.18,0.08-0.49-0.17-0.75-0.28c0.11-0.25,0.17-0.65,0.36-0.72
                C1051.73,293.38,1052.04,293.62,1052.3,293.73z"/>
            <path d="M632.04,663.04c6.27-3.39,12.53-6.77,18.8-10.16c0.07-0.43,0.13-0.87,0.2-1.3c-5.2-1.57-10.41-3.15-15.61-4.72
                c-0.25,0.55-0.51,1.11-0.76,1.66c1.92,1.23,3.85,2.45,6.11,3.9c-3.45,2.57-6.3,0.72-8.1-1.17c-3.28-3.45-6.86-4.78-11.47-4.25
                c-1.14,0.13-2.38-0.68-3.58-1.06c-0.05-0.45-0.1-0.91-0.15-1.36c1.86-0.51,3.72-1.47,5.58-1.47c9.28-0.02,18.73-0.61,27.31,3.86
                c2.07,1.08,4.31,3.35,4.75,5.43c0.25,1.2-2.75,3.45-4.64,4.66c-4.15,2.65-8.45,5.1-12.86,7.29c-1.48,0.73-3.47,0.43-5.23,0.59
                C632.26,664.3,632.15,663.67,632.04,663.04z"/>
            <path d="M600.19,731.64c-2.99-4.15-5.03-7.29-7.39-10.17c-3.53-4.31-4.25-9.09-3.63-14.47c0.33-2.84-0.58-5.8-0.51-8.7
                c0.07-2.98,0.57-5.96,1.11-8.9c0.05-0.26,1.84-0.37,2.75-0.2c0.36,0.07,0.61,1.1,0.77,1.73c2.56,10.25,5.33,20.45,7.49,30.78
                C601.44,724.89,600.43,728.42,600.19,731.64z"/>
            <path d="M601.71,677.98c0.88-2.01,1.59-3.64,2.27-5.21c11.69,1.39,23.11,2.76,35.16,4.19
                C626.36,687.33,614.24,680.77,601.71,677.98z M618.27,677.54c-0.08,0.44-0.16,0.88-0.24,1.32c1.82,0.26,3.64,0.52,5.46,0.77
                c0.05-0.38,0.11-0.77,0.16-1.15C621.86,678.17,620.06,677.86,618.27,677.54z"/>
            <path d="M516.63,654.05c6.72-0.15,8.85,3.01,7.8,10.15C519.44,662.62,519.63,657.76,516.63,654.05z"/>
            <path d="M542.1,691.96c4.18,0.62,8.35,1.23,12.81,1.89C551.65,698.69,545.83,698.24,542.1,691.96z"/>
            <path d="M566.59,720.64c-4.44-0.34-8.88-0.68-13.9-1.06c3.05-3.65,8.58-3.63,13.97-0.7C566.63,719.46,566.61,720.05,566.59,720.64
                z"/>
            <path d="M576.07,721.98c4.32-3.16,10.91-3.38,14.14,0C585.18,721.98,580.95,721.98,576.07,721.98z"/>
            <path d="M541.9,666.13c2.67-0.42,5.34-0.84,8.01-1.26c0.11,0.62,0.23,1.24,0.34,1.86c-2.62,0.9-4.9,3.49-8.2,1.14
                C542.01,667.28,541.96,666.7,541.9,666.13z"/>
            <path d="M534.16,666.29c-1.82,0.51-3.65,1.03-5.47,1.54c-0.2-0.67-0.4-1.34-0.61-2c1.79-0.62,3.57-1.23,5.36-1.85
                C533.68,664.75,533.92,665.52,534.16,666.29z"/>
            <path d="M716.11,546.52c-1.03-5.82,4.12-15.98,9.98-17.89c-3.08,6.65-5.83,12.58-8.57,18.52
                C717.05,546.94,716.58,546.73,716.11,546.52z"/>
            <path d="M784.67,516.38c-3.02-0.35-6.04-0.71-9.65-1.13C779.12,510.69,781.53,510.83,784.67,516.38z"/>
            <path d="M1226.45,388c-0.79-3.11-1.58-6.22-2.37-9.32c0.37-0.19,0.75-0.38,1.12-0.57c0.97,1.48,2.03,2.91,2.87,4.45
                c0.88,1.6,1.2,3.63,2.38,4.9c2.89,3.12,3.12,5.38-1.45,6.6c4.1,6.49,8.08,12.58,11.82,18.81c1.16,1.94,1.87,4.21,2.41,6.42
                c0.19,0.78-0.77,1.84-1.2,2.77c-0.91-0.54-2.51-0.96-2.64-1.65c-1.87-9.69-7.84-16.82-14.28-23.73c-0.98-1.05-1.52-2.52-2.5-4.19
                c-7.95,3.82-16.11,3.11-25.64,1.56C1206.39,385.99,1216.76,389.91,1226.45,388z"/>
            <path d="M1189.7,403.49c0.67-2.74,1.12-5.57,2.09-8.21c0.94-2.57,0.8-4.47-1.81-5.72c-10.82-5.19-12.2-13.97-11.28-25.02
                c0.62-7.41,0.53-14.83,2.78-21.98c0.41-1.31,1.84-2.3,2.8-3.44c0.27,1.45,0.86,2.91,0.77,4.33c-0.64,10.07-1.68,20.12-2.04,30.2
                c-0.19,5.34,3.63,8.54,7.94,11.21c7.55,4.69,7.59,13.32,0.25,19.03C1190.68,403.76,1190.19,403.62,1189.7,403.49z"/>
            <path d="M1186.24,453.26c-0.72-1.61-1.86-3.16-2.09-4.83c-1.42-10.45-4.95-20.71-3.21-31.44c0.58-3.55,1.67-7.05,2.9-10.44
                c0.27-0.74,2.11-0.92,3.23-1.36c0.21,0.88,0.84,1.94,0.56,2.61c-5.22,12.54-1.72,25.04,0.02,37.56c0.23,1.64,0.35,3.3,0.41,4.96
                c0.03,0.79-0.19,1.58-0.3,2.37C1187.25,452.88,1186.75,453.07,1186.24,453.26z"/>
            <path d="M1242.42,428.59c5.59,1.18,12.57-3.54,17.09,4.47c-5.25,0.29-10.05,0.82-14.82,0.63c-1.2-0.05-2.31-2.38-3.46-3.66
                C1241.62,429.54,1242.02,429.06,1242.42,428.59z"/>
            <path d="M1219.02,349.58c0-3.25,0.34-6.55-0.1-9.74c-0.42-3.13-1.62-6.16-2.49-9.25C1222.92,330.94,1224.07,337.03,1219.02,349.58
                z"/>
            <path d="M1243.23,440.71c1.31,2.78,0.1,10.31-2.1,11.47c-0.67,0.35-2.58-0.26-2.93-0.94
                C1237.11,449.15,1240.7,442.06,1243.23,440.71z"/>
            <path d="M1228.12,371.62c1.27-2.74,1.69-4.86,2.97-5.96c0.84-0.71,3.23-0.39,4.4,0.32c0.97,0.6,1.19,2.44,1.73,3.73
                c-1.24,0.33-2.47,0.71-3.72,0.98C1232.27,370.97,1231.02,371.13,1228.12,371.62z"/>
            <path d="M1237.77,311.8c-5.54,1.91-11.8,0.59-14.02-4.47c4.65,1.52,9.3,3.04,13.95,4.56
                C1237.71,311.89,1237.77,311.8,1237.77,311.8z"/>
            <path d="M1259.1,440.86c-4.57-1.42-8.09-2.52-11.94-3.72C1251.29,433.05,1256.21,434.35,1259.1,440.86z"/>
            <path d="M1266,447.33c-5.27,2.35-6.07,1.93-7.35-3.7C1261.22,444.92,1263.15,445.9,1266,447.33z"/>
            <path d="M1247.17,371.77c-2.29-0.21-4.58-0.42-6.87-0.63c0.02-0.57,0.05-1.14,0.07-1.71c2.31,0,4.61,0,6.92,0
                C1247.25,370.21,1247.21,370.99,1247.17,371.77z"/>
            <path d="M491.59,713.49c-21.3,1.48-42.35-2.7-63.59-2.53c0.04-0.9,0.07-1.8,0.11-2.69c21.23-0.89,42.31,2.59,63.51,2.44
                C491.61,711.64,491.6,712.56,491.59,713.49z"/>
            <path d="M405.17,742.36c7.43-1.63,14.82-1.83,22.16,0.02c-0.01,0.63-0.02,1.26-0.04,1.9c-7.41,0-14.83,0-22.24,0
                C405.1,743.64,405.14,743,405.17,742.36z"/>
            <path d="M395.55,706.01c-7.41,1.18-14.82,2.36-22.23,3.54C376.92,704.48,390.72,702.35,395.55,706.01z"/>
            <path d="M414.84,675.25c-5.2,0.99-10.39,1.98-16.77,3.2c3.84-5.27,11.98-7.13,16.56-4.89
                C414.7,674.12,414.77,674.69,414.84,675.25z"/>
            <path d="M439.69,744.68c2.92-0.77,5.85-1.53,9.6-2.51C446.91,747.24,444.36,747.95,439.69,744.68z"/>
            <path d="M979.05,844.75c4.94-1.37,7.85,0.67,9.3,5.18c0.99,3.09,2.91,4.86,6.28,5.5c3.86,0.74,7.31,2.54,8.34,6.87
                c0.19,0.79-0.14,2.2-0.7,2.52c-0.6,0.34-2.25,0.05-2.57-0.49c-5.35-9.04-13.09-4.31-20.11-3.59c-2.41,0.25-4.8,0.62-7.2,0.94
                c-0.16-0.79-0.31-1.58-0.47-2.38c4.44-1.17,8.88-2.33,13.56-3.56c-1.33-4.55-1.63-9.37-8.26-6.89c-0.75,0.28-3.15-1.3-3.21-2.13
                c-0.27-3.53-2.69-3.9-5.19-4.07c-5.63-0.4-11.78-2.16-15.02,4.93c-0.16,0.35-0.8,0.55-1.26,0.68c-0.27,0.08-0.62-0.11-0.93-0.19
                c-0.24-3.94,2.68-8.91,6.21-9.28c4.53-0.48,9.22-0.63,13.69,0.08c2.75,0.43,5.21,2.72,7.8,4.18
                C979.22,843.61,979.14,844.18,979.05,844.75z"/>
            <path d="M1006.37,896.23c-0.17-2-0.46-3.47-0.39-4.93c0.1-1.99,0.67-3.96,0.7-5.94c0.08-6.05-1.24-6.85-6.99-4.97
                c-1.38,0.45-3.1,0.06-4.64-0.16c-2.55-0.38-5.07-1.27-7.62-1.34c-4.08-0.1-8.22,0.02-12.26,0.61c-1.83,0.26-3.43,1.92-5.18,2.85
                c-1.25,0.67-2.58,1.18-3.87,1.77c0.01-1.62-0.58-4.26,0.12-4.66c2.76-1.59,5.85-3.07,8.96-3.48c5.89-0.78,11.89-0.93,17.84-1.06
                c4.4-0.1,8.82,0.11,13.2,0.5c1.33,0.12,2.94,0.99,3.77,2.04C1012.87,881.07,1010.92,892.54,1006.37,896.23z"/>
            <path d="M930.97,853.4c-1.53-0.17-3.4,0.18-4.53-0.59c-4.67-3.18-9.01-1.27-13.07,0.85c-1.07,0.56-1.73,3.79-1.16,5.22
                c1.45,3.62,0.93,6.37-1.74,9.05c-3.08,3.1-3.44,6.61-2.44,10.95c0.82,3.58,0.1,7.55-0.17,11.33c-0.04,0.62-1.28,1.15-1.97,1.73
                c-0.52-0.74-1.35-1.43-1.5-2.24c-0.73-3.94-1.98-7.96-1.69-11.85c0.3-3.91,1.65-8.01,3.57-11.46c1.54-2.77,3.06-4.64,1.28-8.04
                c-0.8-1.53-0.2-4.78,1-6.19C913.11,846.87,925.77,846.92,930.97,853.4z"/>
            <path d="M940.99,865.21c3.36,2.88,5.8,4.97,9.18,7.87C943.77,872.95,941.34,870.85,940.99,865.21z"/>
            <path d="M692.12,441.93c-10.87,0-21.75,0-32.62,0c0.03-0.83,0.05-1.66,0.08-2.5c10.94-1.15,21.79,0.83,32.68,1.2
                C692.21,441.06,692.17,441.5,692.12,441.93z"/>
            <path d="M659.43,494.99c6.41-0.81,12.83-1.63,19.24-2.44c0.12,0.86,0.24,1.72,0.36,2.57c-6.36,0.93-12.72,1.86-19.08,2.8
                C659.77,496.95,659.6,495.97,659.43,494.99z"/>
            <path d="M699.5,454.68c-8.47,0-15.62,0-22.97,0C680.04,451.44,693,451.09,699.5,454.68z"/>
            <path d="M653.21,409.34c3.44-0.47,6.88-0.93,11.2-1.51c-2.81,4.02-6.7,4.71-11.01,2.9C653.33,410.26,653.27,409.8,653.21,409.34z"
                />
            <path d="M703.35,446.21c-2.07-0.65-4.14-1.3-6.21-1.95c0.2-0.74,0.4-1.48,0.6-2.22c2.11,0.5,4.21,1,6.32,1.5
                C703.82,444.43,703.58,445.32,703.35,446.21z"/>
            <path d="M669.03,406.86c1.89-0.3,3.77-0.61,5.66-0.91c0.14,0.68,0.28,1.37,0.42,2.05c-1.9,0.41-3.81,0.82-5.71,1.23
                C669.28,408.44,669.16,407.65,669.03,406.86z"/>
            <path d="M681.64,419.57c-1.5,0.33-3,0.66-4.5,0.99c-0.15-0.64-0.29-1.28-0.44-1.93c1.49-0.35,2.99-0.7,4.48-1.05
                C681.34,418.24,681.49,418.9,681.64,419.57z"/>
            <path d="M464.9,891.12c2.96-2.55,5.91-5.1,9.32-8.03c-0.84-2.84-2.1-4.1-6.76-3.23c-5.11,0.95-10.74-1.69-16.04,1
                c-0.37,0.19-1.34-0.81-2.03-1.27c-2.78-1.87-5.08-1.74-7.28,1.15c-0.59,0.78-2.55,0.52-3.87,0.74c0.13-1.37-0.05-3.8,0.44-3.95
                c3.52-1.05,7.16-1.74,10.8-2.3c1.23-0.19,2.56,0.5,3.84,0.47c5.7-0.15,11.4-0.55,17.11-0.58c3.82-0.02,6.8,1.84,8.02,5.59
                c1.24,3.83,3.81,4.12,7.34,4.15c3.59,0.03,7.17,1.28,10.74,2.07c1.42,0.31,2.82,0.74,4.18,1.24c2.36,0.86,4.69,1.81,7.03,2.72
                c-0.02,0.54-0.03,1.08-0.05,1.61c-1.2,0.46-2.59,1.56-3.57,1.26c-3.86-1.16-7.79-2.39-11.32-4.29
                c-3.56-1.91-18.42-1.28-21.78,1.33c-1.39,1.08-3.18,1.65-4.78,2.45C465.8,892.56,465.35,891.84,464.9,891.12z"/>
            <path d="M395.97,899.78c-0.25,0.87-0.24,1.5-0.54,1.75c-2.51,2.13-12.66,2.77-15.36,0.78c-2.84-2.09-3.87-0.19-5.53,1.68
                c-1.17,1.31-3.24,1.83-4.9,2.7c0.42-1.87,0.29-4.23,1.4-5.48c1.53-1.71,4.01-3.61,6.08-3.61c5.9,0,11.81,0.91,17.71,1.51
                C395.26,899.15,395.66,899.59,395.97,899.78z"/>
            <path d="M441.63,893.03c-1.38,0.38-2.74,0.92-4.14,1.1c-2.39,0.31-4.82,0.64-7.21,0.51c-1.56-0.08-3.59-0.5-4.53-1.55
                c-3.11-3.51-3.88-3.54-6.4,0.14c-0.67,0.98-2.34,1.27-3.56,1.88c-0.2-1.47-1.05-3.28-0.48-4.34c2.2-4.13,5.94-4.83,10.22-3.67
                c4.52,1.22,9.07,2.38,13.58,3.63c0.86,0.24,1.63,0.76,2.45,1.16C441.59,892.27,441.61,892.65,441.63,893.03z"/>
            <path d="M323.35,902.27c-3.8,2.33-5.81,0.54-7.42-1.6c-1.96-2.61-3.56-3.17-5.83-0.29c-0.66,0.83-2.36,0.84-3.59,1.23
                c0.12-1.38-0.22-3.04,0.43-4.07c1.97-3.11,7.44-3.74,10.22-1.31C319.15,897.93,320.93,899.88,323.35,902.27z"/>
            <path d="M513.02,849.42c-1.28-1.19-2.56-1.9-2.48-2.39c0.84-5.1,0.69-10.88,3.17-15.07c3.74-6.31,7.88-5.75,13.15-0.45
                c3.46,3.48,7.03,6.73,6.94,12.1c-0.02,1.11-0.73,2.21-1.12,3.31c-0.93-0.92-2.73-1.93-2.64-2.74c0.52-4.72-2.2-7.43-5.53-9.79
                c-1.61-1.14-4.18-3.24-5.01-2.73c-2.2,1.33-4.5,3.58-5.26,5.94C513.16,840.95,513.43,844.74,513.02,849.42z"/>
            <path d="M537.06,849.99c3.04,3.57,7.15,6.7,8.9,10.82c3.23,7.62,9.81,9.77,16.34,12.49c1.83,0.76,3.68,1.5,5.52,2.25
                c-0.16,0.52-0.32,1.04-0.47,1.56c-1.64-0.28-3.36-0.32-4.89-0.88c-4.37-1.59-8.79-3.15-12.93-5.22c-1.94-0.97-4.48-2.93-4.72-4.72
                c-0.67-5.11-2.65-8.5-7.56-10.51c-1.13-0.46-1.31-3.24-1.92-4.95C535.9,850.56,536.48,850.27,537.06,849.99z"/>
            <path d="M538.35,788.92c-8.62,5.4-12.48,5.14-19.6,0.17c-1.72-1.2-4.54-0.82-7.38-2.18c3.51-0.99,7.16-3.11,10.51-2.71
                C527.25,784.83,532.43,787.13,538.35,788.92z"/>
            <path d="M517.91,809.77c-11.29,0.72-22.58,1.45-33.87,2.17c-0.04-0.42-0.08-0.84-0.12-1.27c10.97-4.38,22.51-2.66,33.87-2.87
                C517.83,808.46,517.87,809.11,517.91,809.77z"/>
            <path d="M528.76,807.49c7.84-2.07,15.29,0.18,22.78,1.91c-0.06,0.69-0.11,1.38-0.17,2.08c-7.58-0.81-15.16-1.62-22.74-2.44
                C528.67,808.53,528.71,808.01,528.76,807.49z"/>
            <path d="M813.52,887.36c4.55-3.96,8.24-7.17,12.54-10.91c-1.61-0.95-2.57-1.81-3.69-2.12c-4.07-1.13-8.16-2.22-12.29-3.04
                c-2.18-0.43-4.49-0.23-6.72-0.43c-2.42-0.21-4.89-0.33-7.24-0.88c-3.13-0.74-5,0.03-6.23,3.16c-0.52,1.32-2.15,2.28-3.4,3.22
                c-1.64,1.23-3.38,2.36-5.15,3.4c-0.75,0.44-1.71,0.52-2.58,0.76c-0.16-0.91-0.65-1.9-0.44-2.71c1.35-5.04,13.75-12.23,18.97-11.49
                c2.71,0.38,5.66-0.71,8.29-0.13c7.21,1.57,14.32,3.63,21.45,5.6c0.72,0.2,1.36,0.97,1.85,1.62c2.12,2.73,4.75,3.73,8.26,3.44
                c3.77-0.32,12.28,5.89,14.05,9.37c0.67,1.33,1.92,2.8,3.24,3.29c3.82,1.41,4.07,4.54,4.16,7.71c0.02,0.85-1.1,1.74-1.69,2.61
                c-0.94-0.73-2.7-1.48-2.68-2.19c0.08-3.38-1.71-4.71-4.62-5.4c-0.71-0.17-1.77-0.95-1.81-1.52c-0.4-5.12-5.27-5.96-8.16-8.62
                c-2.49-2.29-12.52-1.11-15.33,1.31C821.51,885.77,818.88,888.65,813.52,887.36z"/>
            <path d="M812.09,842.24c1.91,0.61,3.39,0.9,4.71,1.54c5.32,2.55,10.62,5.14,15.85,7.86c1.27,0.66,2.71,1.67,3.23,2.88
                c0.53,1.25,0.02,2.94-0.03,4.43c-1.26-0.45-2.54-0.85-3.77-1.37c-1.36-0.57-2.67-1.27-4-1.89c-1.28-0.6-2.56-1.21-3.86-1.77
                c-1.21-0.52-2.48-0.9-3.66-1.47c-1.7-0.82-3.97-1.33-4.9-2.71c-2.55-3.79-4.25-4.21-7.83-1.2c-1.7,1.42-2.91,4.86-5.82,2.15
                c-0.26-0.24,2.03-3.99,3.73-5.38C807.55,843.81,810.08,843.17,812.09,842.24z"/>
            <path d="M785.35,856.78c1.04-7.09,8.2-10.69,13.69-6.88C794.42,852.22,790.03,854.42,785.35,856.78z"/>
            <path d="M1263.84,869.02c6.19-5.13,10.71,0.72,15.53,0.51c0.96-0.04,2.37,3.92,3.08,6.19c0.56,1.81-0.11,4.07,0.64,5.72
                c0.81,1.77,2.39,3.58,4.1,4.47c4.8,2.51,9.99,4.28,14.75,6.86c3.69,2,5.19,8.04,3.25,11.43c-0.47,0.83-1.65,1.47-2.63,1.7
                c-0.35,0.08-1.42-1.22-1.52-1.97c-0.27-2.06,0.64-5.06-0.44-6.07c-2.57-2.41-5.81-4.53-9.16-5.59c-5.06-1.61-10.09-2.4-13.02-7.86
                c-0.78-1.45-4.36-1.59-6.71-1.94c-1.33-0.2-2.79,0.41-4.19,0.65c-0.19-0.55-0.38-1.1-0.58-1.65c2.34-0.9,4.62-2.3,7.03-2.58
                c2.82-0.33,4.76-1.41,4.33-4.1c-0.18-1.08-2.76-1.96-4.39-2.57C1271.06,871.14,1268.08,870.34,1263.84,869.02z"/>
            <path d="M1248.19,478.28c-1.19,4.3-2.62,7.88-3.11,11.58c-0.92,6.91-7.08,13.44-14.15,13.95c-1.02,0.07-2.14-1.37-3.22-2.12
                c0.86-0.71,1.63-1.84,2.61-2.06c8.8-1.97,11.68-5.52,11.45-14.45C1241.58,478.66,1244.1,476.54,1248.19,478.28z"/>
            <path d="M1149.71,501.89c6.51-1.03,13.2-2.32,19.95-3.07c5.32-0.59,7.77,1.55,7.83,6.66c-6.31-6.15-12.79-2.09-19.17-1.01
                C1153.46,505.29,1152.3,505.08,1149.71,501.89z"/>
            <path d="M1243.62,498.75c6.84-2.93,17.25-0.42,21.05,5.17c0.4,0.58,0.68,1.91,0.39,2.15c-0.65,0.55-1.77,0.98-2.56,0.81
                c-0.88-0.19-1.67-1.02-2.38-1.7c-3.34-3.23-7.26-4.35-11.88-3.83c-1.62,0.18-3.36-0.69-5.05-1.08
                C1243.34,499.77,1243.48,499.26,1243.62,498.75z"/>
            <path d="M1146.82,502.9c-3.73,1.05-7.43,2.64-11.22,2.92c-1.98,0.14-4.23-1.89-6.12-3.26c-0.41-0.3,0.16-1.97,0.28-3.01
                c0.84,0.26,2.11,0.26,2.45,0.82c2.26,3.77,5.35,2.36,8.38,1.6c1.91-0.48,3.82-0.9,5.74-1.35
                C1146.49,501.38,1146.65,502.14,1146.82,502.9z"/>
            <path d="M988.29,467.09c4.48,0.23,5.76,2.45,5.36,5.56c-0.08,0.62-1.35,1.09-2.07,1.63C990.48,471.88,989.39,469.49,988.29,467.09
                z"/>
            <path d="M991.62,477.21c-0.35,1.69-0.69,3.38-1.04,5.07c-0.84-0.31-2.39-0.63-2.39-0.92c0.02-1.6,0.43-3.19,0.71-4.79
                C989.81,476.79,990.71,477,991.62,477.21z"/>
            <path d="M991.11,387.98c-1.88,0.42-3.76,0.84-5.64,1.26c-0.17-0.64-0.35-1.29-0.52-1.93c1.87-0.52,3.74-1.05,5.62-1.57
                C990.75,386.48,990.93,387.23,991.11,387.98z"/>
            <path d="M615.24,507.96c-2.08,0-4.16,0-7.43,0c4.77-5.26,29.06-12.53,33.08-10.69c-8.69,3.63-17.22,7.19-25.75,10.75
                C615.14,508.02,615.24,507.96,615.24,507.96z"/>
            <path d="M615.14,508.02c6.02-0.76,12.04-1.52,18.73-2.37c-2.63,3.6-24.57,8.35-29.71,5.69c3.69-1.13,7.39-2.26,11.08-3.38
                C615.24,507.96,615.14,508.02,615.14,508.02z"/>
            <path d="M632.84,456c-6.65,3.07-12.92,5.96-19.2,8.85C613.16,459.49,625.25,453.65,632.84,456z"/>
            <path d="M634.09,438.07c2.14-0.35,4.28-0.69,6.42-1.04c-0.02,0.73,0.15,2.03-0.08,2.1c-1.92,0.6-3.9,0.98-5.87,1.43
                C634.4,439.73,634.25,438.9,634.09,438.07z"/>
            <path d="M612.77,470.31c1.98,0.4,3.96,0.8,5.94,1.21c-0.15,0.79-0.3,1.57-0.45,2.36c-2.03-0.37-4.06-0.74-6.08-1.12
                C612.37,471.94,612.57,471.12,612.77,470.31z"/>
            <path d="M731.05,494.59c11.3-3.17,22.29,1.52,33.48,1.24c0.09,0.68,0.18,1.36,0.27,2.05c-2.18,0.4-4.38,1.24-6.53,1.11
                c-7.71-0.45-15.41-1.2-23.1-1.91c-1.47-0.14-2.9-0.65-4.35-0.99C730.89,495.59,730.97,495.09,731.05,494.59z"/>
            <path d="M734.58,469.94c5.39-0.59,10.39,0.61,15.12,3.1c-0.13,0.66-0.26,1.32-0.39,1.99c-5.07-0.91-10.15-1.83-15.22-2.74
                C734.25,471.5,734.41,470.72,734.58,469.94z"/>
            <path d="M1206.25,526.72c-3.88-1.04-6.88-6.44-5.5-9.51c0.23-0.51,0.96-1.24,1.33-1.17c0.71,0.14,1.73,0.65,1.9,1.22
                C1204.84,520.33,1205.49,523.46,1206.25,526.72z"/>
            <path d="M609.2,790.74c-3.95,0.35-7.91,0.7-11.86,1.04c-0.08-0.76-0.16-1.52-0.24-2.28c3.92-0.49,7.84-0.98,11.75-1.47
                C608.97,788.94,609.08,789.84,609.2,790.74z"/>
            <path d="M1049.37,459.1c1.88-3.26,3.76-6.52,5.83-10.11C1057.64,452.99,1054.79,458.55,1049.37,459.1z"/>
            <path d="M1038.03,467.06c0.87-1.72,1.01-2.24,1.16-2.24c2.64-0.01,5.28,0.04,7.93,0.07c-0.11,0.72-0.19,2.07-0.33,2.08
                C1044.1,467.1,1041.41,467.06,1038.03,467.06z"/>
            <path d="M1091.97,456.17c-2.56,3.9-6.07,4.25-10.27,2.11c-1.72-0.88-3.65-1.35-5.44-2.12c-1.98-0.86-5.31-1.58-5.55-2.84
                c-0.56-2.9,0.34-6.09,0.84-9.14c0.06-0.38,1.48-0.92,2.04-0.72c0.56,0.2,1.03,1.17,1.2,1.88c0.56,2.34,0.2,5.94,1.6,6.84
                c3.18,2.03,7.18,2.83,10.91,3.93C1088.48,456.47,1089.85,456.17,1091.97,456.17z"/>
            <path d="M1103.69,449.49c-7.05-2.44-12.93-4.48-19.27-6.68C1089.89,439.79,1099.61,442.95,1103.69,449.49z"/>
            <path d="M1117.55,456.63c-4.71,4.59-14.74,5.62-19.64,2.22C1105.2,458.02,1111.38,457.32,1117.55,456.63z"/>
            <path d="M462.51,590.46c8.67-1.26,16.02-2.33,23.37-3.39C482.94,591.19,466.84,593.63,462.51,590.46z"/>
            <path d="M511.77,584.86c-3.42,5.02-8.8,6.53-13.66,3.84C503.45,587.2,507.61,586.03,511.77,584.86z"/>
            <path d="M493.58,573.01c-4.38,0.6-8.77,1.2-13.15,1.8c-0.11-0.85-0.23-1.69-0.34-2.54c4.38-0.61,8.77-1.23,13.15-1.84
                C493.35,571.29,493.47,572.15,493.58,573.01z"/>
            <path d="M1038.26,408.23c-0.55-3.94-1.45-7.62,2.9-9.21c4.32-1.58,8.66-2.17,12.92,1.22
                C1047.76,400.96,1041.32,401.05,1038.26,408.23z"/>
            <path d="M266.94,238.57c-2.01-1.5-4.4-2.68-5.93-4.56c-2.67-3.29-5.06-6.89-7-10.66c-0.45-0.88,1.31-2.91,2.06-4.41
                c1.14,1.01,2.48,1.87,3.36,3.07c1.2,1.62,1.96,3.56,3.08,5.24c2.11,3.15,4.36,6.21,6.55,9.31
                C268.35,237.24,267.64,237.91,266.94,238.57z"/>
            <path d="M245.83,205.64c-4.64-1.32-6.78-6.2-3.99-9.01c1.97-1.98,4.95-3.04,7.63-4.17c0.6-0.25,1.91,0.83,2.65,1.55
                c0.2,0.19-0.46,1.89-0.81,1.93C245.14,196.55,244.68,200.66,245.83,205.64z"/>
            <path d="M258.04,214.48c1.83-0.95,3.69-2.73,5.5-2.69c4.37,0.1,7.89-1.13,11.05-4.05c0.45-0.42,2.32-0.22,2.54,0.21
                c0.44,0.84,0.42,2.12,0.12,3.07c-0.2,0.63-1.17,1.25-1.9,1.43c-5.6,1.34-11.22,2.56-16.84,3.82
                C258.36,215.67,258.2,215.08,258.04,214.48z"/>
            <path d="M267.71,226.04c7.41-3.08,14.01,0.38,15.21,7.56c0.12,0.72-1.09,2.4-1.57,2.36c-1.34-0.12-3.08-0.56-3.82-1.52
                c-1.38-1.78-1.74-4.48-3.32-5.93C272.72,227.16,270.21,226.93,267.71,226.04z"/>
            <path d="M232.61,215.46c0.82-2.46,1.21-5.23,2.65-7.25c0.64-0.89,3.39-0.27,5.18-0.34c-0.15,1.77,0.35,4.19-0.61,5.15
                c-1.59,1.59-4.12,2.23-6.25,3.27C233.26,216.01,232.93,215.73,232.61,215.46z"/>
            <path d="M284.14,209.71c-0.44,3.63-0.71,6.53-1.22,9.39c-0.11,0.59-1.07,1.39-1.69,1.43c-0.57,0.04-1.45-0.68-1.77-1.28
                C277.74,215.93,279.45,211.85,284.14,209.71z"/>
            <path d="M268.22,210.56c-1.25-2.25-2.63-3.59-2.43-4.62c0.36-1.8,1.69-3.41,2.61-5.1c0.83,0.84,2.45,1.79,2.35,2.49
                C270.44,205.35,269.45,207.26,268.22,210.56z"/>
            <path d="M260.87,186.42c-2.43,4.35-3.59,4.79-8.61,2.54c2.99-1.56,5.43-2.83,7.87-4.1C260.37,185.38,260.62,185.9,260.87,186.42z"
                />
            <path d="M277.31,202.78c2.08-1.13,4.17-2.26,6.26-3.39c0.09,1.02,0.59,2.69,0.18,2.95c-1.68,1.08-3.63,1.74-5.48,2.55
                C277.95,204.19,277.63,203.48,277.31,202.78z"/>
            <path d="M241.63,224.15c0.13-0.46,0.1-1.12,0.4-1.34c1.6-1.19,3.28-2.27,4.94-3.38c0.27,0.72,0.96,1.88,0.75,2.09
                c-1.57,1.48-3.32,2.77-5.01,4.12C242.35,225.14,241.99,224.64,241.63,224.15z"/>
            <path d="M245.73,216.4c0.42-1.73,0.84-3.47,1.26-5.2c0.91,0.53,2.64,1.16,2.59,1.58c-0.17,1.48-0.95,2.9-1.5,4.34
                C247.3,216.88,246.51,216.64,245.73,216.4z"/>
            <path d="M273.6,191.45c-1.9,1.3-3.8,2.59-5.7,3.89c-0.41-0.63-0.83-1.27-1.24-1.9c1.91-1.28,3.83-2.56,5.74-3.84
                C272.8,190.21,273.2,190.83,273.6,191.45z"/>
            <path onClick={() => props.onFill(154)} fill={props.fillColors[154]} d="M1065.16,718.86c-1.54-0.22-3.08-0.44-4.62-0.66c0.02-0.19,0.05-0.39,0.07-0.58c1.58,0,3.15,0,4.73,0
                C1065.28,718.03,1065.22,718.44,1065.16,718.86z"/>
            <path onClick={() => props.onFill(155)} fill={props.fillColors[155]} d="M964.61,806.05c-3.96-0.21-7.97-0.07-11.84-0.77c-1.69-0.31-3.06-2.34-4.58-3.6c2-1.2,4.34-3.73,5.94-3.33
                c3.87,0.97,7.4,3.31,11.07,5.1C965,804.31,964.8,805.18,964.61,806.05z"/>
            <path onClick={() => props.onFill(156)} fill={props.fillColors[156]} d="M922.31,802.29c-0.92-1.05-1.53-1.55-1.83-2.2c-0.08-0.17,0.81-1.11,1.25-1.1
                c0.44,0.01,1.07,0.68,1.23,1.19C923.09,800.6,922.67,801.2,922.31,802.29z"/>
            <path onClick={() => props.onFill(157)} fill={props.fillColors[157]} d="M618.27,677.54c1.79,0.32,3.59,0.63,5.38,0.95c-0.05,0.38-0.11,0.77-0.16,1.15
                c-1.82-0.26-3.64-0.52-5.46-0.77C618.11,678.42,618.19,677.98,618.27,677.54z"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1,18.5c10.06,5.12,20.66,7.94,31.96,9.17c13.26,1.43,26.51,2.72,39.18,8.41c5.88,2.64,13.8,0.74,20.8,0.93
                c11.49,0.32,22.98,0.84,34.48,0.93c6.33,0.05,12.72-0.15,18.97-1.03c11.41-1.59,22.35,1.64,33.48,2.73
                c8.72,0.85,17.29,3.39,26.01,4.21c6.92,0.65,13.97-0.3,20.97-0.3c7.49,0,15,0.09,22.48,0.53c6.35,0.37,12.65,1.62,18.99,1.85
                c7.34,0.26,14.23,1.64,20.47,5.6c4.63,2.94,9.48,3.72,15.01,3.15c5.59-0.57,11.32,0.43,16.97,0.26
                c5.34-0.15,10.89-0.14,15.93-1.62c6.6-1.93,12.74-3.09,19.61-1.02c3.11,0.94,6.93-0.72,10.43-0.73
                c7.49-0.02,14.99,0.38,22.48,0.42c17.97,0.11,35.91-0.94,53.56,4.72c6.74,2.16,14.6,0.61,21.88,1.37
                c15.18,1.59,30.31,3.66,45.48,5.39c6.14,0.7,12.36,0.75,18.47,1.62c5.22,0.75,11.12,1.04,15.28,3.75
                c4.22,2.74,8.51,3.52,12.71,3.46c10.35-0.16,20.07,2.21,30.04,4.46c10.75,2.42,21.9,3.23,32.93,4.15
                c9.45,0.79,19.01,1.36,28.46,0.94c10.52-0.47,21.09-1.62,31.45-3.51c9.08-1.65,17.97-2.11,26.46,1.3
                c8.49,3.42,17.11,4.17,26.04,3.9c4.64-0.14,9.01,0.9,13.59,2.08c6.61,1.69,13.89,0.89,20.88,0.83c4.83-0.04,9.69-1.13,14.48-0.85
                c18.83,1.11,37.64,2.69,56.47,3.86c9.81,0.61,19.66,1.11,29.47,0.94c9.83-0.17,19.65-1.33,29.48-1.86
                c8.99-0.48,17.99-1.06,26.98-0.95c13.81,0.18,27.3,2.28,40.54,6.71c6.44,2.16,13.61,2.06,20.41,3.24
                c7.69,1.34,15.33,2.96,22.99,4.47c0.67,0.13,1.36,0.54,2,0.46c8.74-1.1,16.53,1.98,24.56,4.82c3.9,1.38,8.59,0.52,12.92,0.72
                c1.5,0.07,3,0.32,4.5,0.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1.5,48c13.01,4.39,26,3.88,39.52,1.83c11.58-1.76,23.64-1.32,35.42-0.66c14.03,0.78,27.96,3.41,41.99,4.19
                c19.29,1.07,38.63,1.12,57.95,1.68c1.34,0.04,2.73,0.48,3.99,0.99c9.17,3.69,19,2.61,28.48,4.05c5.06,0.76,9.98,2.37,15.01,3.4
                c4.8,0.98,9.63,2.25,14.49,2.44c22.98,0.88,45.96,1.51,68.95,2.05c9.99,0.24,20.08-0.79,29.95,0.3c7.8,0.86,15.38,3.97,23,6.28
                c4.23,1.28,8.52,2.6,12.47,4.53c8.6,4.21,17.82,4.25,27.01,4.99c8.85,0.71,17.64,2.46,26.49,2.82
                c16.43,0.68,31.05,7.82,46.51,11.93c6.04,1.6,12.62,1.05,18.94,1.71c13.67,1.41,27.3,3.17,40.98,4.41
                c10.8,0.98,21.77,0.61,32.44,2.29c10.36,1.63,19.72-1.51,29.55-3.02c10.41-1.6,20.91-2.06,31.53,0.99
                c5.5,1.58,11.95,0.39,17.88-0.32c5.56-0.66,10.45,0.02,15.57,2.49c7.45,3.6,15.64,4.46,23.9,5.31c10.9,1.12,21.63,3.79,32.51,5.21
                c6.25,0.81,12.67,0.17,18.97,0.68c9.02,0.74,18.15,1.19,26.95,3.1c15.78,3.42,31.29,8.07,47.03,11.7
                c5.13,1.18,10.63,0.79,15.96,1.14c5,0.32,10.01,0.51,14.98,1.05c4.02,0.43,8.01,1.9,12,1.85c22.57-0.28,44.25,4.87,65.96,10.06
                c20.94,5.01,41.85,10.25,62.98,14.37c17.16,3.34,34.56,5.58,51.94,7.55c16.59,1.88,33.3,2.74,49.95,4.14
                c6.13,0.52,11.89,1.66,17.64,4.7c5.83,3.09,12.87,3.87,19.33,5.79c9.85,2.93,19.54,6.47,29.51,8.86
                c12.54,3.01,25.28,5.18,37.95,7.57c2.09,0.39,4.53,0.63,6.45-0.05c5.98-2.13,12.03-2.98,18-0.81c7.95,2.89,15.77,1.69,23.49-0.17
                c9.71-2.34,19.3-0.94,29.01-0.54c9.8,0.41,19.65-0.47,29.47-0.3c10,0.17,20,0.79,29.98,1.47c9.49,0.64,18.79,3.13,28.55,0.72
                c4.28-1.06,9.25,0.89,13.93,1.21c8.99,0.61,17.99,0.99,26.98,1.56c2.01,0.13,4,0.64,6,0.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M471.16,552.5c-4.32,8.51-12.41,12.67-19.95,17.55c-5.7,3.68-12.16,4.54-18.51,5.98c-2.2,0.5-4.59,0.9-6.46,2.04
                c-8.3,5.11-18.01,6.62-26.72,11.05c-10.11,5.16-20.76,10.04-32.29,11.85c-6.14,0.96-12.34,1.6-18.48,2.57
                c-2.54,0.4-5.27,0.81-7.46,2.04c-12.15,6.79-25.2,11.16-38.51,14.96c-6.23,1.78-12.22,4.51-18.53,5.81
                c-6.52,1.35-13.28,1.79-19.96,2.07c-7.98,0.34-16,0.33-23.98,0.01c-9.63-0.39-18.6,1.68-26.43,7.15
                c-9.49,6.63-20.29,10.15-31.08,13.28c-37.53,10.87-75.03,21.98-112.96,31.33C47.31,685.74,23.98,688.17,1,692"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1346.04,130c-8.73-3.69-17.66-5-26.99-2.53c-3.12,0.83-6.32,1.38-9.49,2.02c-4.99,1.02-10.1,1.64-14.97,3.08
                c-10.33,3.05-20.16,0.67-30-2.09c-15.08-4.23-30.35-4.65-45.42-0.79c-6.92,1.77-12.67,7.09-20.54,6.95
                c-4.98-0.09-10.29,0.37-14.91,2.07c-5.25,1.93-8.75,0.72-12.63-2.64c-4.4-3.81-8.98-7.58-14.02-10.41
                c-3.87-2.16-8.57-2.82-12.9-4.17c-1.51-0.47-3.55-0.54-4.41-1.57c-4.56-5.51-10.22-7.17-17.1-6.02c-1.22,0.2-2.8-0.67-3.96-1.44
                c-7.77-5.17-16.89-6.63-25.5-9.48c-2.7-0.89-5.43-1.78-7.98-3.02c-7.14-3.48-14.57-5.46-22.45-6.77c-4.94-0.82-9.24-5-14.07-7.12
                c-9.33-4.09-16.49-11-23.99-17.52c-1.89-1.65-4.2-3.56-6.48-3.79c-2.05-0.21-6.27-3.47-6.78,2.72c-0.14,1.67-4.02,3.03-6.19,4.53"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M303.28,350c3.29,1.59,6.57,3.18,9.86,4.77c-0.06,0.63-0.12,1.27-0.18,1.9c-3.56,0.44-7.13,1.28-10.68,1.25
                c-7.7-0.08-14.78-2.73-22.08-5.14c-9.96-3.28-20.49-5.11-30.91-6.65c-11.5-1.7-21.02-7.98-31.46-12.13
                c-11.82-4.69-23.49-9.81-35.53-13.82c-6.65-2.21-14-2.2-20.9-3.78c-5.94-1.36-11.61-3.84-17.53-5.31c-4.04-1-8.3-1.19-12.47-1.57
                c-6.32-0.58-12.66-1.01-18.98-1.52c-10.75-0.87-18.45,5.26-25.92,11.58c-3.31,2.8-5.92,6.41-8.94,9.76
                c3.22,5.65,8.77,6.45,13.89,7.58c6.09,1.35,12.4,1.32,17.65,5.87c1.7,1.47,5.49,0.46,8.31,0.72c4.67,0.43,9.36,0.79,13.99,1.52
                c8.52,1.35,17.01,2.89,25.49,4.47c14,2.61,27.97,5.4,41.98,7.96c10.8,1.97,21.78,3.13,32.43,5.69c10.01,2.4,19.77,5.96,29.5,9.4
                c10.09,3.57,20.06,7.49,29.98,11.48c6.92,2.78,13.54,6.33,20.53,8.87c10.54,3.83,21.07,8.74,32,10.19
                c15.57,2.07,29.98,8.99,45.44,10.06c10.6,0.73,19.36,6.42,29.46,7.82c11.31,1.57,22.62,3.3,33.98,4.44
                c11.79,1.18,23.65,1.63,35.46,2.6c20.99,1.74,41.96,3.83,62.96,5.39c9.12,0.68,18.33,0.82,27.47,0.44
                c5.86-0.24,11.65-1.96,17.5-2.83c5.48-0.81,10.99-1.36,16.48-2.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M449.68,550c-7.99,3.83-15.99,7.67-23.98,11.5c-0.67,0.32-1.3,0.81-2,0.98c-14.32,3.36-28.64,6.69-42.96,10.02
                c-9.59,2.23-19.2,2.22-28.99,1.14c-7.75-0.86-15.68-1.04-23.46-0.47c-6.72,0.5-13.33,3.53-20,3.55
                c-7.49,0.03-15.17-1.4-22.43-3.41c-7.17-1.99-14.14-1.54-21.01-0.18c-11.96,2.37-23.55,1.47-35.55-0.26
                c-10.06-1.45-20.45-3.06-31.01,0.37c-9.78,3.18-20.22,4.34-30.41,6.16c-1.72,0.31-3.67-0.48-5.48-0.93
                c-8.8-2.19-17.5-2.27-26.51-0.64c-10.2,1.84-20.68,2.16-30.93,3.77c-6.59,1.04-13.02,3.16-19.5,4.89
                c-11,2.95-21.87,6.59-33.03,8.74c-5.72,1.1-12.46,0.96-17.83-1.05c-10.05-3.77-19.99-2.6-30.09-2.21C3.33,592.04,2.17,592,1,592"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M605.57,467c-16.89,5.12-34.31,2.26-51.46,3.52c-2.34,0.17-4.66,0.67-7,0.97c-5.33,0.68-10.64,1.57-15.99,1.95
                c-7.81,0.55-15.67,1.24-23.47,0.91c-7.69-0.33-15.33-1.88-22.99-2.84c-3.16-0.4-6.49-1.48-9.46-0.86
                c-9.24,1.93-18.48,4.12-27.47,6.99c-5.9,1.88-10.99,3.15-17.15,0.1c-5.08-2.51-11.22-2.84-16.86-4.26
                c-4.86-1.23-9.61-2.93-14.51-3.91c-7.11-1.43-14.29-2.56-21.48-3.52c-5.63-0.75-11.32-1.49-16.98-1.44
                c-7.66,0.07-15.32,0.89-22.98,1.39c-0.17,0.01-0.33,0-0.5,0c-10.49,0.17-21.04,1.04-31.46,0.3c-10.43-0.74-21.38-0.32-30.57-7.2
                c-1.63-1.22-4.24-1.15-6.42-1.59c-1.99-0.4-4.42-0.07-5.93-1.11c-11.63-8.06-25.64-8.13-38.53-11.92
                c-7.02-2.06-13.95-4.46-21-6.45c-9.35-2.64-18.74-2.94-28.5-1.77c-9.2,1.1-18.63,0.18-27.95,0.26
                c-5.33,0.05-10.68,0.11-15.98,0.53c-6.92,0.55-14.46-4.47-16.63-11.01c-0.38-1.15-2.45-2.23-3.88-2.45
                c-4.79-0.75-9.65-1.58-14.47-1.49c-10.16,0.17-20.32,0.89-30.48,1.4c-6.16,0.31-12.34,0.46-18.48,1.05
                c-8.35,0.8-16.64,2.38-24.99,2.81c-6.63,0.34-13.32-0.53-19.98-0.86"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M539.61,260c-2.66-2.17-5.06-4.86-8.05-6.4c-8.82-4.54-16.01-10.39-19.46-20.09c-2.17-6.12-1.14-7.76,6.05-8.81
                c3.49-0.51,7.27,1.43,10.97,1.73c6.81,0.56,13.73,0.2,20.46,1.2c8.58,1.28,16.94,4.62,25.52,5.13
                c15.67,0.93,30.78,4.25,45.99,7.59c5.63,1.24,11.74,0.22,17.43,1.28c8.76,1.64,17.33,4.27,26.01,6.34
                c11.47,2.74,23.18,4.7,34.42,8.17c17.01,5.24,34.39,7.13,52.01,7.84c5.99,0.24,12,0.61,17.98,0.44
                c6.17-0.17,12.32-1.12,18.49-1.33c2.35-0.08,6.06-1.7,6.83,1.93c0.89,4.17,4.06,4.01,6.67,4.37c8.12,1.1,16.34,1.54,24.46,2.66
                c8.86,1.23,17.65,3.03,26.49,4.4c5.47,0.85,10.98,1.55,16.49,1.9c1.27,0.08,3.62-1.34,3.83-2.4c1.58-7.79,6.19-11.3,13.09-8.34
                c9.71,4.17,19.84,4.48,30.04,4.81c2.32,0.08,4.66-0.84,6.99-0.85c1.33,0,3.79,0.84,3.83,1.42c0.49,7.68,8.11,5.25,11.74,8.4
                c1.02,0.89,2.92,0.75,4.62,1.13c-2.68,6.19-8.89,6.2-13.72,6.71c-11.91,1.25-23.98,0.98-35.94,1.82
                c-11.85,0.83-23.65,2.32-35.48,3.4c-6.82,0.63-13.65,1.09-20.49,1.43c-0.71,0.04-1.49-1.24-2.24-1.91"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M966.31,366c-4.33,0.17-8.94-0.57-12.94,0.68c-9.99,3.13-19.57,7.64-29.6,10.6c-4.44,1.31-9.61-0.02-14.42,0.28
                c-7.01,0.44-14,1.25-20.99,1.98c-0.69,0.07-1.46,1.05-1.98,0.9c-9.7-2.77-17.61,3.72-26.5,5.04c-4.15,0.62-8.35,0.88-12.48,1.55
                c-15.17,2.44-30.29,5.18-45.48,7.41c-11.44,1.68-22.95,3.31-34.48,3.85c-7.05,0.33-13.11,2.82-18.89,5.88
                c-6.87,3.63-13.08,8.5-19.57,12.83"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M363.74,724.5c-5.83-4.17-11.9-8.05-17.37-12.64c-2.14-1.8-3.54-5.05-4.23-7.89c-1.04-4.31-2.88-7.84-6.39-10.46
                c-14.08-10.48-30.21-15.2-47.46-15.32c-9.49-0.07-18.97,2.14-28.49,2.69c-5.79,0.33-11.65-0.61-17.48-0.86
                c-11.22-0.49-20.76,5.26-30.96,8.53c-0.6,0.19-1.01,0.96-1.51,1.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M194.36,695.25c-5.5,0.25-11.03,0.18-16.48,0.81c-16.01,1.83-31.99,3.86-47.96,6.04c-2.1,0.29-4.01,1.9-6.01,2.9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M115.92,708.5c-4.05,2.34-10.39,1.7-10.99,8.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M815.42,826c-7.13,1.53-13.46-1.68-19.97-3.55c-12.21-3.5-24.2-7.8-36.52-10.82c-14.01-3.43-28.3-5.69-42.43-8.67
                c-10.37-2.19-20.05,0.25-30.03,2.86c-13.63,3.56-27.61,5.82-41.42,8.7c-6.01,1.25-12.04,2.49-17.97,4.06
                c-2.78,0.74-5.66,1.83-7.95,3.52c-4.55,3.36-8.73,7.24-13.06,10.91"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M981.8,344.5c-7.33,0.33-14.68,1.2-21.98,0.86c-8.69-0.41-17.54-0.99-25.94-3.07c-11.25-2.79-22.51-4.19-34.03-4.3
                c-8.16-0.08-16.36-0.91-24.47-0.36c-15.85,1.07-31.67,2.74-47.47,4.42c-12.02,1.28-23.97,3.97-35.98,4.16
                c-11.63,0.18-22.4,1.71-33.11,6.48c-3.97,1.77-9.8,0.94-14.3-0.36c-16.08-4.64-32.51-5.93-49.01-5.15
                c-13.36,0.63-26.66,2.8-39.98,4.33c-8.17,0.94-16.3,2.24-24.49,2.88c-3.95,0.31-7.98-0.75-11.98-0.8
                c-1.83-0.02-4.04,0.42-5.43,1.5c-6.39,4.96-13.54,7.18-21.55,6.92"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M404.21,255c-7.5,0-15.01,0-22.51,0c-0.16,0.67-0.31,1.33-0.47,2c3.33,1.67,6.52,3.8,10.02,4.91c8.74,2.78,17.69,4.89,26.44,7.64
                c11.55,3.63,22.91,7.93,34.51,11.38c20.41,6.08,40.86,12.09,61.48,17.4c8.56,2.2,17.59,3.26,26.44,3.49
                c13.31,0.35,26.67-0.14,39.96-0.98c5.89-0.38,11.65-2.59,17.51-3.8c2.11-0.44,4.32-0.38,6.49-0.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M616.06,294.75c12.66-0.75,25.31-1.83,37.98-2.16c14.15-0.38,28.32-0.44,42.46,0.04c8.85,0.3,17.66,1.83,26.48,2.88
                c8,0.95,15.98,2.12,23.99,2.94c5.47,0.57,11.06,1.42,16.47,0.9c13.52-1.29,27.45,0.42,40.48-4.87c8.26-3.35,16.36-2.04,24.5,0.48
                c1.77,0.55,3.65,0.7,5.49,1.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1031.26,901c-3.83,1.33-7.58,3.06-11.51,3.9c-4.69,0.99-8.25,3.05-9.94,7.61c-1.19,3.23-3.47,3.92-6.53,3.5
                c-5.82-0.81-10.57,0.83-14.56,5.43c-3.58,4.13-8.17,6.41-13.93,4.59c-0.61-0.19-1.34-0.12-1.99-0.01c-5,0.81-10.2,2.91-14.94,2.14
                c-5.07-0.82-9.51-5.08-14.59-6.48c-7.92-2.19-16.09-3.99-24.45-1.72c-1.24,0.34-2.65,0.05-3.98,0.05c-6.83,0-13.7,0.43-20.47-0.18
                c-3.42-0.31-7.01-1.99-9.92-3.95c-3.37-2.27-6.02-5.61-9.12-8.32c-0.91-0.79-2.37-1.61-3.44-1.45c-5.53,0.8-11.47,0.9-16.38,3.18
                c-5.38,2.49-9.81,1.25-14.61-0.29c-5.13-1.64-9.83-1.8-13.5,3.01c-2.41,3.16-5.2,2.7-9.02,2.34c-7.2-0.67-14.69,0.31-19.96,6.66
                c-1.57,1.89-3.05,2.96-6.04,2.22c-2.4-0.59-5.26,0.87-7.94,1.25c-2.15,0.31-4.38,0.74-6.48,0.46c-5.86-0.79-11.68-1.86-17.49-2.96
                c-2.53-0.48-4.98-1.39-7.51-1.94c-2.14-0.47-4.93-1.71-6.38-0.84c-4.9,2.91-8.66,1.28-13.18-1.04
                c-6.36-3.26-13.25-5.48-19.91-8.15"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1342.79,261c6.92,6.24,15.47,7.18,24.23,7.53c2.84,0.12,5.69,0.48,8.49,1c4.03,0.75,5.34,2.61,6.64,9.15
                c-7.27,4.12-15.47,5.71-23.59,4.61c-11.27-1.52-22.35-4.43-33.5-6.82c-2.37-0.51-4.63-1.64-7.01-1.88
                c-2.46-0.25-5.74-0.71-7.36,0.57c-9.01,7.14-19.48,7.48-30.1,7.3c-7.17-0.12-14.33-0.51-21.48-0.99
                c-8.17-0.54-16.34-1.99-24.48-1.79c-9.51,0.23-19,1.75-28.48,2.89c-1.56,0.19-3.01,1.27-4.5,1.93"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1040.26,540.5c-1.5,0.33-2.99,0.92-4.5,0.96c-14.65,0.4-29.36,0.06-43.95,1.22c-14.56,1.16-28.95,4.26-43.5,5.7
                c-16.61,1.65-32.93,4.47-48.97,9.05c-1.19,0.34-2.65-0.27-3.98-0.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M426.7,358.5c-8.85,4.67-17.36,0.16-25.98-1.03c-3.52-0.49-6.98-1.74-10.5-1.88c-6.77-0.26-12.3-2.98-17.54-7.02
                c-2.69-2.07-5.76-4.12-8.97-4.93c-7.72-1.94-15.58-3.41-23.46-4.52c-3.73-0.53-7.64,0.24-11.48,0.37
                c-1.83,0.06-3.85,0.51-5.47-0.07c-8.23-2.98-16.53-5.87-24.43-9.59c-3.51-1.65-6.29-1.89-9.58-0.35
                c-3.64,1.71-7.33,3.29-12.11,5.42c7.6,8.89,15.73,13.85,25.61,16.1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M591.58,363c-5.66-0.5-11.32-1.19-16.99-1.45c-7.49-0.35-14.99-0.39-22.48-0.54c-8.99-0.18-18-0.14-26.98-0.56
                c-8.67-0.41-17.39-0.83-25.97-2.06c-10.56-1.52-21.06-3.64-31.47-6c-4.79-1.08-10.42-0.17-14.07-4.85
                c-0.25-0.32-1.4-0.28-1.93-0.01c-7.78,4.01-15.33,0.56-23.01-0.44c-4.59-0.6-9.39-0.53-13.26,3.72c2.06,2.68,4.16,5.43,6.27,8.18"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M335.76,731c-5.33,0.33-10.66,0.67-15.99,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M267.31,726.75c-8.37-2.04-16.79-3.65-25.48-2.75"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M241.33,724c-2.83-0.33-5.66-0.67-8.49-1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M210.35,724.25c-10.16,1.58-20.32,3.17-30.48,4.75"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M100.93,720.5c-0.67,0.67-1.32,1.34-2,2c-4.45,4.33-7.51,10.67-15.51,9.69c-1.5-0.18-3.31,2.14-4.97,3.31"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M871.38,679.5c-8.49,0.5-16.98,1.22-25.48,1.44c-9.49,0.25-18.99,0.11-28.48,0.03c-4-0.03-8.01-0.17-11.99-0.5
                c-14.99-1.27-29.97-2.77-44.98-3.88c-3.79-0.28-7.67,0.47-11.48,0.94c-4.51,0.55-9,1.31-13.49,1.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M545.61,261c-2.66,0.17-5.35,0.61-7.99,0.44c-4.18-0.27-8.32-1-12.5-1.43c-11.82-1.2-23.66-2.27-35.47-3.54
                c-9.84-1.05-19.63-2.88-29.49-3.34c-14.47-0.69-28.97-0.57-43.46-0.56c-3.66,0-7.33,0.93-10.99,1.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M231.83,233c16.59,6.38,33.92,9.45,51.47,11.46c3.53,0.4,6.56-1.12,7.94-4.48c3.17-7.76,7.61-15.47,8.53-23.54
                c0.67-5.86-2.36-13.01-5.78-18.24c-3.64-5.56-9.33-10.01-14.79-14.06c-5.19-3.86-11.79-2.46-17.88-2.66
                c-10.39-0.34-19.46,2.14-26.53,10.49c-10.01,11.82-12.63,30.95-3.95,40.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1399,387.5c-0.83-0.5-1.67-1.44-2.5-1.43c-7.43,0.04-14.27-1.06-21.17-4.73c-4.36-2.32-10.49-1.21-15.8-1.88
                c-5.35-0.67-10.64-2.05-16-2.34c-4.8-0.26-10.08-0.56-14.4,1.1c-10.4,4-20.89,3.62-31.56,2.79c-2.99-0.23-6.01-0.21-8.99-0.54
                c-6.67-0.74-13.33-1.64-19.99-2.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1150.68,288.5c0.83-1,1.66-2.01,2.5-3c4.65-5.46,4.16-8.39-1.99-12.52c-1.27-0.85-2.49-1.85-3.49-3
                c-8.32-9.62-20.15-11.31-31.53-13.31c-10.57-1.86-19.8-5.01-28.18-12.4c-7.2-6.35-16.07-7.39-25.92-2.62
                c-6.79,3.29-11.59,7.67-15.82,13.35"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1225.12,875c4.13,0.06,8.67-3.26,10.52,4.88c1.17,5.14,7.75,8.91,11.31,13.74c1.17,1.59,1.11,4.76,0.43,6.81
                c-0.42,1.25-3.06,1.84-4.79,2.53c-0.72,0.29-1.9-0.3-2.44,0.1c-8.16,5.92-14.89-0.19-22.01-2.58c-8.71-2.91-15.14-1.55-19.89,4.61
                c-2.04,2.65-4.33,3.15-6.54,1.81c-5.61-3.38-11.65-2.38-17.55-2.33c-7.17,0.06-13.89-0.96-20.02-5.03
                c-3.5-2.33-7.34-1.63-10.96-0.03c-12.46,5.52-25.54,7.08-38.97,5.91c-4.2-0.36-8.31-2.13-12.5-2.3
                c-6.73-0.28-13.35,0.82-20.1-1.82c-2.95-1.16-7.5,0.36-10.82,1.82c-10.14,4.44-19.5,2.75-28.47-2.73
                c-1.47-0.9-2.31-3.13-2.99-4.92c-0.66-1.71-0.98-3.63-1.03-5.47c-0.19-7.28-4.06-12.99-7.37-19.09
                c-1.79-3.31-2.12-7.54-2.58-11.42c-0.41-3.47-2.14-5.46-5.05-7.04c-2.95-1.61-6.25-3.12-8.4-5.56
                c-3.77-4.28-6.76-9.24-10.13-13.88c-0.9-1.24-1.88-2.45-3-3.49c-3.65-3.39-6.3-7.36-13.06-5.9c-5.59,1.21-11.9-1.39-17.91-1.47
                c-4.49-0.06-8.97,1.44-13.49,1.78c-4.3,0.33-8.66,0.07-12.99,0.07"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1356.53,255c-6.83-2.17-13.63-4.42-20.49-6.48c-9.97-2.99-19.93-2.06-29.99-0.1c-8.08,1.57-16.43,3.83-24.43,3.15
                c-6.82-0.58-12.55,0.14-18.59,2.79c-2.1,0.92-5.33,0.98-7.36,0c-9.54-4.56-19.47-3.91-29.55-3.48c-4.63,0.2-9.64,0-13.91-1.57
                c-7.5-2.76-14.39-1.49-21.57,0.64c-7.08,2.09-14.33,3.62-21.46,5.58c-8.69,2.39-17.33,4.97-25.99,7.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M282.8,763.5c-6.33,0.17-12.66,0.33-18.99,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M252.82,763.5c-7.99,0.5-15.99,1-23.98,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M209.35,767.25c-1.83,0.25-3.66,0.5-5.5,0.75"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M172.38,771.25c-8.49,1.58-16.99,3.17-25.48,4.75"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M950.82,423.5c-1.67,0.33-3.61,0.22-4.95,1.07c-11.18,7.08-23.56,10.05-36.51,11.47c-13.35,1.47-26.61,3.99-39.99,4.72
                c-9.76,0.53-19.74-0.46-29.44-1.95c-8.89-1.37-16.99-1.74-24.62,4.04c-1.75,1.32-4.94,1.31-7.37,1.06
                c-9.02-0.94-17.97-2.72-27-3.17c-3.42-0.17-6.89,2.8-10.51,3.65c-3.55,0.83-7.3,0.77-10.97,1.11"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M867.38,906c0.83-5.17,1.67-10.33,2.5-15.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M869.63,884c-0.75-1.5-1.11-3.7-2.32-4.38c-5.8-3.29-7.55-9.19-9.87-14.64c-4.49-10.51-12.06-18.33-21.09-24.91
                c-1.14-0.83-2.97-1.08-4.44-1.01c-6.87,0.35-13.37-0.88-19.49-4.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M804.43,833.5c-3.33,0.83-6.66,1.67-9.99,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M789.44,838.5c-3.46,3.45-7.8,4.51-12.49,4.49c-14.06-0.04-23.88,8.4-33.34,17.15c-1.78,1.64-2.01,5.14-2.58,7.87
                c-0.67,3.23-1.36,6.03-5.55,6.06c-1.17,0.01-2.99,0.62-3.39,1.48c-2.19,4.72-5.91,8-9.46,11.59c-1.5,1.51-1.97,4.52-2.04,6.87
                c-0.16,5.9-1.4,11.01-7.09,14"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M747.97,553.5c4.33,0.67,8.69,2.06,12.98,1.87c14.23-0.65,28.06,1.7,41.99,4.03c16.58,2.77,33.29,4.75,49.95,7.11
                c4.17,0.59,8.33,1.32,12.49,1.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M711.99,909c-4.16-0.67-8.61-0.63-12.43-2.16c-6.2-2.48-12.77-0.69-19.28-3.26c-5.15-2.03-11.44-2.33-16.73-5.14
                c-1.19-0.63-2.66-1.8-2.92-2.97c-1.7-7.68-9.39-10.54-17.1-11.38c-4.12-0.45-7.2-0.81-10.88-4.16c-3.84-3.5-10.41-5.57-16.58-4.38
                c-3.56,0.69-6.96,2.18-10.53,2.82c-1.37,0.25-3.11-0.65-4.45-1.42c-4.14-2.39-8.25-2.76-12.52-0.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M579.59,815c5.81,3.49,19.27,4.33,22.43,0.95c6.68-7.16,13.12-14.55,19.52-21.97c4.79-5.56,9.35-11.31,14.01-16.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M681.01,772.75c12.32,0.08,24.66,0.55,36.97,0.12c8.19-0.29,16.33-1.84,24.49-2.87c4.84-0.61,9.65-1.51,14.5-1.91
                c2.12-0.17,4.32,0.58,6.49,0.91"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M775.95,772.25c14.49,1.25,28.98,2.5,43.47,3.75"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M833.9,777.25c19.82,1.25,39.63,2.73,59.46,3.65c9.13,0.43,18.32-0.31,27.48-0.38c11.99-0.08,23.98-0.01,35.97-0.04
                c0.5,0,1-0.31,1.5-0.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M967.81,781.5c1.33,0.33,2.66,0.91,4,0.96c10.49,0.4,20.98,0.85,31.48,0.97c4.66,0.06,9.32-0.81,13.99-0.89
                c9.32-0.15,18.65-0.04,27.98-0.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1298.07,635c1.17,1.5,2.06,3.38,3.54,4.44c7.06,5.06,10.01,12.88,13.94,20.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1325.05,672.5c5.32,4.81,11.02,9.36,12.49,17"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1343.79,720c0.75,4.83,1.38,9.69,2.27,14.5c1.58,8.52,3.31,17,4.97,25.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M993.29,530c-21.48,4.17-43.12,7.69-64.39,12.76c-11.18,2.67-21.71,8.07-32.54,12.24"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1009.78,515c6.16,3,13.01,5.11,18.34,9.2c9.43,7.23,14.26,17.56,16.81,29.27c1.78,8.19,5.16,16.04,7.82,24.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1053.75,583c1.17,4,2.11,8.08,3.54,11.98c4.72,12.87,9.68,25.66,14.38,38.54c0.66,1.82,0.4,3.97,0.57,5.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1281.08,788c-27.14-0.68-53.69-6.15-80.44-10.02c-8.18-1.19-16.33-2.64-24.49-3.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1,776.5c0.33,0.5,0.62,1.39,1.01,1.44c14.28,1.86,28.43,3.61,43.01,1.35c9.25-1.43,18.94,0.26,28.43,0.1
                c4.34-0.07,8.66-1.23,12.99-1.9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1259.6,355.5c2.83,7.17,5.76,14.3,8.43,21.52c0.81,2.19,1.36,4.67,1.23,6.97c-0.53,9.6,4.46,17.7,6.74,26.53
                c3,11.58,4.81,23.49,8.28,34.92c2.49,8.21,2.29,16.34,2.15,24.56c-0.05,2.85-1.85,5.67-2.85,8.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M934.33,822.5c-1.87,4.68-6.7,2.96-9.52,3.17c-16.01,1.17-24.57,11.47-32.01,23.8c-1.76,2.91-4.24,5.4-6.44,8.03
                c-5.23,6.26-8.24,13.38-9.72,21.46c-0.51,2.77-3.76,5.04-5.76,7.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1334.05,514.5c-7.38,8.83-10.49,19.04-12.37,30.44c-1.91,11.6-5.61,23.21-14.55,32.13c-3.09,3.08-5.5,6.86-8,10.47
                c-0.86,1.25-1.06,2.96-1.56,4.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1166.17,742c1,2.17,2.34,4.24,2.94,6.52c2.15,8.13,4.18,16.29,6.01,24.5c0.95,4.27,1.41,8.65,2.04,12.99
                c0.59,4.05-0.06,8.53,4.45,11.09c0.55,0.32,0.39,1.91,0.55,2.91"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1233.62,815.25c3.83-0.08,7.66-0.17,11.49-0.25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M777.44,707.5c-3.66,0-7.39-0.44-10.98,0.08c-9.91,1.43-19.35-1.44-29-2.53c-6.96-0.79-13.98-1.36-20.98-1.48
                c-8.65-0.15-17.33,0.6-25.98,0.28c-4.57-0.17-6.76,1.34-7.52,5.64c-0.59,3.35-1.32,6.67-1.98,10"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M312.28,797c-5.5-0.5-11.03-1.64-16.48-1.35c-8.69,0.45-17.33,1.87-25.99,2.85c-11.99,1.35-23.98,2.68-35.98,3.97
                c-0.64,0.07-1.33-0.3-2-0.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1247.11,903c0.67,0.67,1.32,1.35,2,2c3.35,3.15,6.33,4.54,10.63,0.65c3.57-3.22,12.78-2.45,15.3,1.38
                c4.62,7.03,9.78,8.92,17.13,3.59c2.97-2.15,6.72-1.62,9.93,0.85c3.7,2.84,7.08,2.23,11.08-0.29c3.27-2.06,7.55-2.52,11.38-3.68"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1152.18,650c8.04,0.29,14.2,5.68,21.49,7.97c2.27,0.71,4.64,1.36,6.99,1.48c17.34,0.87,34.26,3.29,49.46,12.55"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M492.65,877.5c-1.5-2-3.11-3.93-4.47-6.01c-3.12-4.77-7.46-5.03-12.54-4.12c-1.85,0.33-4.74-0.2-5.84-1.48
                c-4.9-5.68-10.99-6.95-18.12-7.04c-6.51-0.09-13.15-0.88-19.45-2.5c-8.43-2.17-8.31-2.64-16.42,1.87
                c1.12,2.25,2.26,4.52,3.39,6.78"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M621.56,439c-4.16,1-8.38,1.85-12.45,3.13c-0.79,0.25-1.42,2.18-1.48,3.37c-1.04,19.2-0.61,38.43-7.33,57.08
                c-4.78,13.26-7.3,27.33-11.32,40.89c-1.88,6.35-4.89,12.36-7.39,18.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M587.08,564.75c7-0.88,13.48,0.57,19.49,4.25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1168.67,754c-9.49-1.83-18.95-3.88-28.49-5.44c-10.62-1.74-21.29-3.26-31.98-4.47c-5.61-0.64-11.32-0.42-16.98-0.58"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M681.76,617c-4.08,16.83-7.84,33.75-12.39,50.46c-1.99,7.33-5.89,13.09-13,18.04c-8.77,6.09-15.07,15.78-22.25,24.07
                c-1.04,1.2-1.07,3.27-1.57,4.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1399,807c-16.56-0.75-33.09-1.24-49.5-4.31c-9.13-1.71-18.62-1.52-27.94-2.19"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M755.46,662.5c-7.83-1.5-15.64-3.09-23.49-4.45c-2.78-0.48-5.66-0.29-8.48-0.57c-6.17-0.61-12.32-1.44-18.49-1.92
                c-2.47-0.19-5.23-0.33-7.44,0.56c-5.8,2.32-11.37,5.22-17.03,7.88"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1283.58,625c-4.38,6.39-0.76,13.09-0.25,19.48c0.59,7.42,2.67,14.81,4.83,21.99c4.38,14.59,9.75,28.89,13.77,43.56
                c4.57,16.67,8.4,33.57,12.03,50.48c2.63,12.23,4.66,24.61,6.48,37c0.92,6.25,0.77,12.65,1.1,18.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1306.57,816.5c-3.16-0.5-6.33-1-9.49-1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1285.58,817c-2.5-0.33-5-0.67-7.49-1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1398.75,658.5c-2.41-1.67-4.64-3.76-7.28-4.92c-7.27-3.19-14.87-2.02-22.45-1.13c-7.64,0.89-15.35,1.15-22.97,2.12
                c-6.37,0.81-12.67,2.26-19,3.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1144.68,648.5c1,4.5,2.83,9,2.84,13.5c0.03,11.86,2.52,23.21,5.67,34.49c0.89,3.2,1.98,6.34,2.98,9.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1158.92,712c2.42,9.5,4.83,19,7.5,29.5c-2.03,0-4.23,0.65-5.7-0.1c-11.53-5.92-24.14-5.65-36.52-6.98
                c-8.04-0.87-15.99-2.64-24-3.87c-2.63-0.4-5.32-0.37-7.99-0.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1.5,754.5c0.38,3.67,4.05,2.57,5.53,2.74c12.44,1.45,24.96,2.17,37.44,3.31c3.53,0.32,6.98,1.61,10.5,1.87
                c5.98,0.44,11.99,0.41,17.98,0.57"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M214.85,908c-3.16-5.17-6.33-10.33-9.49-15.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M201.36,885.75c-2.17-0.58-4.91-0.53-6.38-1.88c-3.21-2.95-6.46-3.33-10.62-3.1c-3.1,0.17-6.76-1.12-9.37-2.93
                c-3.47-2.4-5.95-2.34-8.6,0.66c-3.08,3.48-6.02,5.19-10.6,1.63c-3.74-2.91-8.2-1.5-10.24,2.45c-3.48,6.7-12.11,6.59-15.79,12.83
                c-0.31,0.53-3.08,0.18-4.29-0.5c-2.03-1.15-3.53-3.27-5.6-4.31c-2.46-1.24-5.24-2.22-7.96-2.5c-5.13-0.51-10.32-0.63-15.48-0.52
                c-2.84,0.06-5.82,0.51-8.46,1.52c-3.39,1.3-6.2,1.06-9.04-1.09c-5.32-4.02-10.97-3.34-15.52,1.45c-2.72,2.86-6.17,4.12-9.4,0.98
                c-3.64-3.54-7.62-4.36-11.47-1.34c-4.04,3.17-7.54,2.49-11.62,0.49c-1.54-0.76-3.64-0.68-5.45-0.55c-4.84,0.36-7.85-2.68-11-5.55"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M927.34,616.5c-7.33,1.67-14.59,3.78-22.01,4.85c-5.39,0.77-11,0.52-16.46,0.08c-13.94-1.14-27.85-2.77-41.48,2.07"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1398.5,342.5c-3.16,0.33-6.68,1.71-9.42,0.79c-6.55-2.19-12.45-0.82-18.58,1.13c-2.17,0.69-4.64,0.53-6.97,0.56
                c-13.16,0.2-26.31,0.43-39.47,0.47c-3.66,0.01-7.32-0.79-10.99-0.86c-1.49-0.03-3.31,0.53-4.44,1.47
                c-8.14,6.76-17,8.56-27.58,6.74c-7.08-1.22-14.84-1.59-21.93,1.7"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1124.95,806c-0.65,7.81,2.53,13.16,9.14,17.65c11.52,7.81,22.46,16.47,33.56,24.87c6.91,5.23,13.65,10.68,20.53,15.95
                c0.98,0.75,2.31,1.03,3.47,1.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1154.18,296.5c0.5,5,1,10,1.5,15"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1154.43,362c-2.52,8.49-1.38,16.48,3.25,24"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M372.73,801c-3.66,0-7.33,0-10.99,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M348.25,801.75c-5.66-1.92-11.22-4.28-17.02-5.6c-4.15-0.94-8.63-0.67-12.96-0.6c-5.5,0.09-7.46,4.41-10.24,8.28
                c-1.44,2-5.45,3.11-8.24,3.02c-16.22-0.53-32.08,1.87-48,4.49c-4.49,0.74-9.3-0.5-13.96-0.84"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M237.33,810.5c-2.66-1.33-5.26-2.85-8.01-3.95c-3.42-1.36-6.93-2.55-10.49-3.49c-2.42-0.64-4.97-0.9-7.48-1.02
                c-3.49-0.17-7.71-1.29-10.34,0.22c-5.56,3.18-11.44,2.31-17.15,3.17c-5.78,0.87-11.64,1.25-16.47,5.08
                c-0.55,0.44-1.4,0.96-1.45,1.5c-0.7,8.16-7.98,12.21-11.4,18.58c-3.38,6.3-2.43,12.96,1.84,18.92"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1385.01,503c3.55-3.17,2.98-6,0.09-9.57c-5.15-6.36-12.35-7.61-19.58-8.88c-5.96-1.05-11.97-1.88-17.99-2.49
                c-4.67-0.47-7.24,2.32-9.05,6.41c-1.97,4.45-0.44,7.43,3.05,10.05c0.75,0.56,1.34,1.32,2.01,1.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M758.46,829.5c-5.62-2.51-11.27-2.86-17.51-2.27c-7.05,0.67-14.3-0.77-21.46-1.22c-7.69-0.48-15.46-2.6-22.98,0.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M481.16,910c-2.83-0.33-5.66-0.75-8.5-0.96c-1.15-0.08-2.78-0.14-3.42,0.53c-5.6,5.82-13.45,3.79-20.14,5.64
                c-1.98,0.55-5.12-2.1-7.38-3.76c-8.07-5.97-16.47-7.06-25.54-2.5c-1.33,0.67-2.98,1.01-4.47,0.99
                c-5.87-0.08-11.57-0.43-16.65,3.86c-1.46,1.23-4.98,0.72-7.31,0.08c-5.66-1.56-10.73-1.72-15.04,3.09
                c-3.04,3.39-6.89,4.56-11.48,4.1c-3-0.3-5.85-0.43-8.64,2.26c-2.85,2.74-6.55,2.2-9.89-0.77c-5.63-5.01-11.54-9.81-19.93-8.56
                c-1,0.15-2.29-0.01-2.95,0.55c-6.86,5.83-14.3,3.01-21.51,1.36c-5.27-1.21-10.33-3.3-15.52-4.86c-1.07-0.32-2.48-0.42-3.46,0.02
                c-4.75,2.11-9.09,1.75-13.62-0.88c-0.96-0.56-3.51,0.81-4.84,1.89c-4.06,3.29-7.81,4.86-12.61,1.02
                c-3.29-2.63-7.27-4.41-10.92-6.59c-2.81-1.69-8.4-0.16-10.47,3.02c-1.13,1.75-2.02,3.65-3.02,5.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M218.84,906.5c6.33-4.5,12.55-9.17,19.06-13.39c1.87-1.21,4.6-1.55,6.92-1.53c6.7,0.08,12.64-2.24,18.5-5.07
                c0.94-0.45,2.02-1.04,2.99-0.96c6.84,0.56,13.69,1.1,20.48,2.03c2.62,0.36,4.02,0.24,5.16-2.53c0.68-1.67,2.65-3.13,4.38-4
                c11.83-5.91,17.52-15.35,16.96-28.57"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M650.54,975.5c-0.83-0.17-2.23-0.1-2.41-0.54c-2.79-6.62-5.87-13.16-7.97-20c-3.08-10.04-5.39-20.31-8.13-30.46
                c-0.82-3.05-1.87-6.05-3.06-8.97c-0.23-0.58-1.58-0.7-2.41-1.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M157.89,816.5c-9.33,0.67-18.66,1.31-27.98,2.01c-8.33,0.63-16.66,1.93-24.98,1.83c-9.8-0.11-19.29,0.61-28.5,4.12
                c-0.73,0.28-1.71,0.23-2.48,0.01c-6.76-1.96-9.91,2.8-13.53,7.01c-9.08,10.57-18.27,21.05-27.47,31.51
                c-3.82,4.34-8.69,1.33-12.98,1.34c-5.99,0.01-11.99-1.16-17.99-1.83"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1069.74,299.5c-0.83,4.83-1.88,9.64-2.45,14.51c-1,8.65-2.33,17.32-2.4,26c-0.11,13.17-3.47,25.58-7.1,38.01
                c-0.31,1.08-0.04,2.32-0.04,3.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1089.72,776.75c-7.16,0.08-14.32,0.17-21.48,0.25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1056.75,776.75c-1.5-0.25-2.99-0.67-4.5-0.72c-11.82-0.38-23.66-1.23-35.47-0.89c-14.01,0.41-27.99,1.79-41.97,2.93
                c-2.54,0.21-5,1.27-7.5,1.93"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1129.69,323.5c-0.17,4.83-1.01,9.76-0.38,14.48c2.6,19.49,3.17,38.92,1.06,58.54c-1.03,9.56-0.18,19.31-0.18,28.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M957.32,757c-2.64,3.15-5.66,4-9.54,2.09c-1.21-0.59-2.95-0.09-4.45-0.09"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M934.33,759.5c-7.49-0.33-14.99-0.67-22.48-1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M891.86,756.75c-11.82-1.58-23.65-3.17-35.47-4.75"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M855.89,752c-2.66-0.17-5.33-0.33-7.99-0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M815.92,747.75c-3.5-0.25-7-0.5-10.49-0.75"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M767.45,742.5c-3.33-0.17-6.66-0.33-9.99-0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M727.98,742c-5.01-1.31-9.47-0.16-13.49,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1156.67,361.5c3.65,3.23,4.23,7.47,4,12c-0.19,3.83-0.34,7.67-0.51,11.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1160.67,460.25c7.66-1.08,15.29-2.59,22.99-3.12c6.62-0.46,13.32,0.07,19.97,0.43c3.18,0.17,6.33,0.94,9.5,1.44"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1377.02,586.5c-5,0.17-9.99,0.33-14.99,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1347.54,587.25c-11.16,1.25-22.32,2.5-33.48,3.75"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1305.57,592.25c-12.28-1.8-16.04,7.77-20.99,15.75c-0.83,1.34-1.66,2.67-2.5,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M473.16,823.5c-2.17-3.17-4.33-6.33-6.5-9.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M447.68,794.5c-3.49-11.01-12.87-15-22.53-17.84c-9.89-2.91-20.24-2-30.43-1.12c-1.19,0.1-2.33,0.64-3.5,0.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1123.2,305.5c-1.6,12.25-0.5,24.32,1.21,36.54c0.95,6.77-1.04,13.96-1.71,20.96c-1.16,12.17-2.33,24.33-3.5,36.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M499.14,919c-3.33,10-7.15,19.87-9.84,30.04c-2.24,8.47-3.26,17.28-4.54,25.98c-0.23,1.56,0.9,3.32,1.4,4.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1043.25,266.5c-14.34,4.76-25.98,12.84-32.45,27.01c-2.99,6.56-6.55,12.4-11.74,17.76c-4.53,4.68-6.61,11.73-9.78,17.73"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M986.3,335.75c-3.38,8.3-6.39,16.73-6.91,25.75c-0.19,3.3,0.58,6.66,0.91,10"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M680.51,922.5c-2.5,8.33-5.04,16.65-7.48,25c-2.72,9.32-5.18,18.71-8.11,27.96c-0.81,2.55-2.89,4.7-4.39,7.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1247.61,849c-1.11-6.17-2.55-6.5-9.05-4.71c-2.81,0.77-6.29-0.71-9.43-1.31c-3.48-0.67-4.39,4.9-9.71,2.5
                c-6.06-2.73-13.77-2.15-20.77-2.31c-2.64-0.06-5.33,1.83-7.99,2.83"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M988.29,673c-11.16-0.83-22.63-0.33-33.4-2.84c-13.06-3.06-25.4,0.74-38.05,0.98c-8.67,0.16-17.41,1.06-25.48,4.87"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M988.79,508.5c-0.5,0.83-0.81,2.12-1.53,2.43c-5.1,2.19-18.54-1.15-22.45-5.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M956.32,498.5c-6.51-3.1-12.99-0.2-18.91,1.3c-12.08,3.07-24.19,4.74-36.55,5.2c-3.76,0.14-8.12-0.96-7.7,5.52
                c0.09,1.41-2.46,2.99-3.79,4.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M539.61,627.5c1.67-4.13,2.32-7.73-2.96-10.08c-4.43-1.97-4.2-5.17-1.04-8.42"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M540.61,604c1.24-5.63,0.64-6.41-7-9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M471.16,601c-1.33-0.67-2.65-1.36-4-2c-9.87-4.7-10.44-5.15-6.32-16.19c2.14-5.74,6.7-9.83,12.76-12.45
                c8.4-3.63,16.75-7.03,26.02-5.27c6.25,1.18,12.33,3.29,18.51,4.89c4.81,1.25,9.69,2.23,14.47,3.57c2.58,0.72,5.7,1.31,7.36,3.09
                c2.89,3.11,3.44,6.97-0.33,10.38c-2.44,2.21-4.68,4.64-7.02,6.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M461.17,825.75c4-0.58,7.98-1.41,12-1.61c1.29-0.06,3.05,1.23,3.93,2.41c7.77,10.42,15.18,21.11,23.12,31.4
                c4.75,6.15,10.67,11.42,15.23,17.69c2.2,3.03,5.63,6.63,3.18,11.37"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M991.79,392.5c-2.32,4.59-0.82,8.82,0.28,13.55c3.12,13.35,5.21,26.95,7.72,40.45c0.25,1.35,0.66,2.67,0.99,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M889.86,484.5c2.83,2.33,5.49,4.95,8.59,6.84c1.07,0.65,4.1,0.05,4.74-0.95c2.09-3.22,3.49-6.9,5.16-10.4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M902.36,478.5c-7.19,5.44-14.92,6.17-23.52,3.62c-6.99-2.08-14.25-3.38-21.47-4.48c-2.53-0.38-5.31,0.86-7.97,1.36"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M848.89,479c-18.91,1.21-39.16,10.21-45.47,30"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M365.74,727.5c5.89,4.54,10.15,10.1,12.07,17.48c1.03,3.96,2.92,7.69,4.41,11.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M389.72,775c1.83,6.83,3.66,13.67,5.5,20.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M397.47,802c5.41,16.83,10.79,33.68,16.3,50.48c0.54,1.65,1.94,3.02,2.94,4.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M60.46,743.5c-2.5-0.67-4.97-1.77-7.5-1.93c-13.31-0.82-26.64-1.51-39.97-2c-3.65-0.13-7.33,0.6-10.99,0.93"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M376.73,939.5c0,8.5-0.05,17,0.02,25.5c0.03,3.5,0.31,7,0.48,10.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M371.73,981.5c-1.33-3.33-2.66-6.67-4-10"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1084.23,928.5c-10.33,15.33-20.78,30.58-30.88,46.07c-2.2,3.38-3.1,7.6-4.6,11.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1287.08,858.5c4.16,1.17,8.4,2.11,12.47,3.56c4.63,1.64,6.64,4.79,5.13,9.98c-0.58,1.98-1.18,4.31,1.76,6.16
                c2.12,1.34,3.09,4.49,4.63,6.8c1.46,2.19,2.53,5.44,4.57,6.3c6.37,2.67,8.71,8.01,10.91,13.71"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1078.73,344.5c1.67,8.5,3.19,17.03,5.05,25.49c1.01,4.57,3.04,8.94,3.85,13.53c1.64,9.28,2.83,18.64,4.03,27.99
                c0.3,2.36,0.57,4.25,3.55,3.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M931.33,852c-1.33-0.17-2.9,0.06-3.96-0.57c-4.81-2.83-14.1-2.03-17.39,2.17c-0.89,1.14-0.61,3.73-0.03,5.36
                c1.01,2.86,1.49,5.06-1.03,7.61c-3.9,3.96-5.67,8.26-2.34,14.07c1.4,2.44-0.07,6.52-0.24,9.85"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1269.09,583.5c5.85,4.38,7.06,10.83,8.05,17.49c0.53,3.55,1.61,7.01,2.44,10.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1291.08,624.5c10.33-0.5,20.65-1,30.98-1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1331.55,623.75c7.33-0.08,14.67-0.52,21.98-0.15c6.69,0.34,13.32,1.63,20,2.36c2.14,0.23,4.33,0.04,6.49,0.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1014.28,748c-6.5,0.17-12.99,0.43-19.49,0.48c-10.22,0.07-19.49,3.98-28.98,7.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M955.82,755.75c-2.5,0.42-5,0.83-7.49,1.25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1167.67,802.25c-3.33,0.25-6.67,0.77-9.99,0.7c-9.66-0.19-19.32-0.68-28.98-0.88c-1.32-0.03-2.66,0.93-4,1.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1310.06,931c-1,3.17-2.3,6.27-2.94,9.51c-2.46,12.47-4.77,24.98-7.01,37.5c-0.56,3.13-0.7,6.33-1.03,9.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1135.19,270.5c-4.83-0.83-9.63-2.08-14.5-2.42c-11.16-0.79-22.12-4.73-33.46-2.51c-2.75,0.54-5.34,1.93-8.01,2.93"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M619.56,830.5c5.63-1.8,11.07-0.65,16.49,0.99c0.81,0.24,1.96,0.05,2.45,0.55c8.27,8.46,19.8,10.3,30.13,14.18
                c8.14,3.06,17.22,3.61,25.88,5.28"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M608.07,442c-1.18-6.03,2.99-9.96,6.11-13.91c5.34-6.74,12.47-10.83,21.37-11.09"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M648.54,436.5c-0.33,4.83-0.67,9.67-1,14.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M647.79,465c-0.25,10.67-0.53,21.33-0.73,32c-0.08,4.13-0.28,7.75-5.47,9.15c-2.87,0.77-4.21,3.3-3.17,6.89
                c0.71,2.46,0.1,5.29,0.14,7.96c0.15,9.17,0.33,18.33,0.49,27.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1339.04,513c1.5,0.67,3.12,2.1,4.48,1.88c12.73-2.07,25.09,0.55,37.48,2.69c2.92,0.5,5.62,2.16,8.53,2.83
                c1.54,0.36,3.31-0.24,4.97-0.41"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M379.23,870c-3.98-5.76-4.08-5.53-10.06-2.11c-3.14,1.8-7.11,2.09-10.38,3.71c-3.54,1.74-6.66,4.31-10.06,6.38
                c-1.9,1.16-3.88,2.52-6,2.94c-8.57,1.73-17.15,3.28-26,1.25c-2.07-0.47-4.89,0.71-6.91,1.92c-5.64,3.38-11.42,3.77-17.54,1.91"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M895.36,715.5c2.33-1,4.71-1.91,6.99-3.02c4.2-2.05,8.31-0.45,12.51-0.03c9.81,0.99,19.65,1.72,29.47,2.55"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M964.81,715.5c3.83,1.17,7.62,3.16,11.5,3.35c14.14,0.71,28.31,0.93,42.46,1.01c1.83,0.01,3.66-1.86,5.5-2.86
                c-1.83-1.33-3.66-2.67-5.5-4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1156.67,459.5c-3.66,3.17-6.95,6.99-11.07,9.36c-7.56,4.34-12.06,11.09-16.41,18.14c-1.65,2.68-3.6,5.21-4.92,8.04
                c-1.48,3.19-2.26,6.69-3.62,9.94c-0.89,2.12-2.28,4.02-3.45,6.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1399,128c-10.49-0.17-21-0.71-31.47-0.36c-6.02,0.2-12.01,1.77-17.97,2.97c-0.67,0.14-1.02,1.88-1.52,2.88"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M828.91,877.5c-1.79-4.2-5.06-5.41-9.48-5.66c-3.38-0.19-6.64-1.98-10.01-2.79c-1.93-0.46-3.98-0.39-5.98-0.55
                c-2.16-0.17-4.33-0.39-6.5-0.48c-2-0.08-4.69-0.85-5.87,0.13c-4.15,3.45-7.79,7.52-11.62,11.35"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M385.72,790c-5.5,0.5-10.99,1.09-16.49,1.48c-10.32,0.73-20.65,1.3-30.97,2.07c-1.86,0.14-3.67,0.95-5.5,1.45"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1281.08,634.5c-5.66-2.17-11.26-4.52-17-6.46c-9.01-3.04-9.8-11.24-11.92-18.56c-1.13-3.91-1.59-8.02-2.63-11.96
                c-0.3-1.14-1.6-2.9-2.41-2.89c-16.36,0.27-32.86-1.53-48.96,2.89c-1.21,0.33-2.34,0.98-3.5,1.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M847.39,672.5c-3.83,0-7.7,0.35-11.48-0.07c-8.52-0.94-16.99-2.29-25.49-3.42c-5.32-0.71-10.66-1.34-15.99-2.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1067.99,429c2.08-2.33,4.16-4.67,6.25-7"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1071.48,414c-2.62-8.02-7.31-15.03-13.96-19.68c-3.75-2.62-9.75-2.14-14.77-2.75c-2.11-0.26-4.38,0.67-6.48,0.36
                c-12.65-1.85-21.31,8.22-22.07,19.06c-0.38,5.38-1.52,10.7-2.35,16.24c6.77-0.72,14.72,0.29,20.68-2.69
                c7-3.5,14.04-1.44,20.71-3.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M273.8,707.5c-8.66-0.5-17.34-1.64-25.98-1.36c-16.5,0.53-33.02,1.49-49.46,3.01c-9.24,0.85-18.28,3.99-27.51,4.64
                c-10.65,0.75-21.49,1.28-31.98-2.22c-3.7-1.23-7.66-1.64-11.46-2.61c-1.57-0.4-3.01-1.29-4.51-1.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M713.99,412.75c-3.33-0.08-6.66-0.14-9.99-0.26c-3.5-0.13-7-0.32-10.49-0.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M685.76,405c-2.25-2.17-4.16-5.5-6.81-6.28c-7.22-2.12-14.48-4.74-22.02,0.63c-7.12,5.07-13.37,10.51-16.88,18.65"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1184.15,340.5c-2.16,7.34-4.33,14.61-3.12,22.52c0.55,3.56-0.47,7.33-0.31,10.98c0.18,4.09,1.77,7.4,5.36,10.09
                c9.3,6.98,11.24,4.59,5.06,18.41"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M644.54,908.5c1.67,14.5,3.4,28.99,4.93,43.51c0.26,2.47,3.03,5.48-0.94,7.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M860.39,578.5c-1.34-0.27-2.69-0.55-5.43-1.11c-2.28,7.15-4.78,14.6-7.02,22.12c-1.53,5.11-2.14,10.56-4.18,15.43
                c-2.31,5.51-1.18,10.84-0.1,16.01c0.55,2.64,3.63,4.83,5.77,7.03c4.76,4.9,9.64,9.69,14.47,14.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M797.43,548c-12.32,0.17-24.65,0.16-36.97,0.58c-4.83,0.17-9.58,0.21-13.77,4.38c-2.05,2.04-7.64,2.51-10.6,1.25
                c-7.19-3.05-13.77-1.29-20.61,0.26c-0.63,0.14-1.33,0.02-1.99,0.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M477.66,804.5c3.33-5.67,6.64-11.34,10-17c3.6-6.07,6.76-12.49,12.99-16.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M555.35,777c1.08,2.33,1.9,4.84,3.3,6.97c4.38,6.63,2.76,14.41,4.45,21.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M563.85,825c1.75,7.83,3.27,15.73,5.37,23.47c0.69,2.55,2.34,5.52,4.46,6.89c8.04,5.21,9.78,14.34,14.86,21.62
                c-1.62,1.99-3.05,3.74-3.68,4.52c-7.11,0-13.47-0.42-19.75,0.12c-5.63,0.48-7.48,2.96-7.45,8.38c0.01,2.7-0.9,3.81-3.44,5.19
                c-5.08,2.77-9.55,6.64-13.48,9.48c-4.74-0.89-9.04-1.7-13.34-2.51c-0.49,0.61-0.98,1.21-1.47,1.82c-1.26-3.16-2.53-6.33-3.79-9.49
                "/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M516.63,886.5c-2.6-2.71-5.66-4.14-9.49-3.5c-2.07,0.35-2.99-0.52-4.12-2.43c-2.77-4.7-5.89-5.1-10.37-2.07"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M31.98,925c-0.5,6.33-0.71,12.71-1.56,18.99c-1.43,10.53-3.27,21.01-4.93,31.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1014.28,712.5c-12.82-0.33-25.65-0.92-38.47-0.85c-4,0.02-8,1.85-11.99,2.85"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M476.66,922.5c-0.33,5-0.94,10-0.94,15c-0.01,11,0.56,22.01,0.32,33c-0.12,5.35-1.54,10.67-2.37,16"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M455.17,649.25c-12.69,0.36-25.42,0.39-37.47,5.26c-1.52,0.61-3.11,1.15-4.48,2.02c-9.19,5.86-18.02,12.21-21.91,23
                c-1.51,4.18-4.59,4.72-8.08,4.95c-10.37,0.69-20.96,0.27-30.46,5.55c-4.11,2.29-8.01,4.96-12.01,7.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M698.5,920.5c-2.66,5.33-5.4,10.63-7.98,16.01c-4.55,9.47-9.14,18.94-13.41,28.54c-0.98,2.21-0.76,4.96-1.1,7.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M320.27,928c3.33,10.67,6.62,21.35,10.02,31.99c0.82,2.56,2.41,4.93,2.86,7.53c0.6,3.42,0.44,6.98,0.61,10.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1259.1,626.5c0.5,9.87-0.47,19.99,5,29c2.08,3.42,1.98,6.83,0.56,10.53c-1.16,3.04-1.73,6.31-2.57,9.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1399,733.5c-9.16-1-18.32-2.74-27.48-2.76c-7.16-0.02-14.32,2.1-21.48,3.26"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1042.26,459.5c3.43-5.86,0.4-8.27-5.46-9.64c-10.47-2.44-18.57,2.51-27.06,7.05c-2.35,1.26-5.28,1.42-7.95,2.09"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1226.12,304c-11.49-2-22.97-4.09-34.48-5.96c-4.86-0.79-9.99-2.11-13.98,2.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1079.23,817.5c-6.99,1.33-13.97,3.63-20.99,3.79c-12.97,0.31-26,2.92-39-0.68c-5.47-1.52-11.29-1.78-16.96-2.61"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M49.96,836.5c-7.66,0.33-15.32,0.81-22.98,0.96c-8.32,0.17-16.65,0.04-24.98,0.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M716.49,465c-5.33-0.83-10.65-2.29-15.99-2.36c-9.65-0.14-19.32,0.55-28.98,0.84c-0.99,0.03-2-0.31-3-0.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M810.42,591c6.5,11.17,12.93,22.37,19.53,33.47c1.5,2.53,3.4,4.91,5.51,6.97c2.04,1.98,4.64,2.98,7.43,1.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M977.3,430.5c0,5.33,0.34,10.69-0.08,15.99c-0.73,9.19-1.9,18.34-2.95,27.5c-0.15,1.35-0.64,2.67-0.97,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M309.78,929.5c6.5,14.83,12.98,29.67,19.52,44.49c0.42,0.94,1.3,1.68,1.97,2.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M773.45,934.5c-0.73,13.91-0.55,27.73,4.55,40.98c0.63,1.64,1.95,3.02,2.95,4.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M513.13,933.5c-7.49,14.33-15,28.66-22.45,43.02c-0.44,0.86-0.37,1.98-0.54,2.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1171.16,786.5c-11.49-1.17-22.96-2.8-34.48-3.35c-8.79-0.42-17.65,0.52-26.47,0.85"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1021.27,620c-13.72-8.01-28.78-8.09-43.97-7.53c-1.99,0.07-4-0.51-5.99-0.43c-5.67,0.22-11.33,0.63-16.99,0.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M978.8,362c-12.85,2.01-15.97,11.86-17.9,22.52c-0.28,1.55,0.58,3.32,0.91,4.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M958.82,398c-1.83,7.33-3.74,14.65-5.47,22.01c-1.45,6.14-2.88,12.29-3.98,18.5c-1.82,10.31-3.37,20.66-5.04,30.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1255.1,834c-6.66-1.83-13.25-4.08-20.02-5.31c-2.97-0.54-6.32,0.98-8.02,1.29c-4.88-2.83-8.77-5.45-12.97-7.39
                c-3.68-1.69-7.13-0.92-9.5,2.88c-0.53,0.85-1.85,2.09-2.42,1.91c-5.31-1.63-7.6,2.68-11.11,4.98c-4.88,3.2-3.8,6.86-2.45,11.15
                c2.19,6.98,4.8,13.93,3.54,21.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1197.14,871.5c2.66,2.5,5.25,5.09,8.01,7.48c6.95,6.05,13.91,12.09,21.02,17.96c1.68,1.39,3.95,2.06,5.95,3.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M89.44,732.5c-2.17,4.17-4.84,8.16-6.33,12.56c-1.15,3.39-1.58,7.4-1,10.92c1.13,6.92,3.07,13.72,4.89,20.51
                c0.69,2.58,1.95,5.01,2.95,7.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M91.93,793c-4,5.67-7.99,11.33-11.99,17"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M75.95,814.5c-1.92,3.18-7.09,4-6.5,9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1199.14,690.5c-2.5-0.17-5.08-0.82-7.48-0.42c-10.86,1.81-21.67,3.89-32.49,5.94c-1.7,0.32-3.34,0.97-5,1.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1004.28,71.5c3.66,2.5,7.52,4.77,10.95,7.55c6.14,4.98,12.01,10.31,18.04,15.44c4.96,4.21,9.98,8.34,14.98,12.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M178.87,910.5c-2.33,7.5-4.78,14.97-6.96,22.51c-2.15,7.46-4.03,14.99-6.03,22.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M350.25,764.25c-4.5,0.58-8.99,1.17-13.49,1.75"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M598.57,936c-4,2-8.37,3.49-11.92,6.1c-8.39,6.18-16.48,12.8-24.49,19.47c-1.77,1.47-2.73,3.92-4.06,5.92"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M771.45,868.5c-1.33-0.33-2.68-0.61-3.99-1.01c-3.95-1.2-6.94-0.34-7.96,4.02c-0.67,2.88-1.86,4.61-4.99,5.64
                c-4.12,1.35-5.96,4.83-5.59,9.35c0.12,1.48,0.25,3.82-0.55,4.35c-3.2,2.11-2.02,5.51-3.14,8.04c-1.57,3.52-2.38,6.87-2.26,10.61
                c0.16,5.19-4.81,6.15-7.49,9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1050.25,927.5c-1.33,8.5-2.79,16.98-3.96,25.5c-1.31,9.48-2.22,19.02-3.62,28.48c-0.41,2.75-1.91,5.35-2.91,8.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M844.4,707c-6.33,0.83-12.64,1.83-18.99,2.41c-2.28,0.21-4.88-0.08-6.95-1c-10.91-4.86-22.77-2.58-34.03-4.83
                c-7.91-1.59-15.97-2.41-23.97-3.58"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1189.15,458.5c-2.88,10.96-4.4,21.88-0.6,33.04c1.17,3.44,1.1,7.3,1.6,10.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M41.97,844c-7.33,0.83-14.64,1.85-21.99,2.44c-5.64,0.46-11.32,0.44-16.98,0.49c-0.66,0.01-1.33-0.93-2-1.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M487.65,786c-4.5-0.17-8.99-0.43-13.49-0.48c-7.66-0.08-15.32-0.12-22.98,0.05c-1.84,0.04-3.67,0.93-5.5,1.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M684.51,704.5c-0.5-0.33-1.06-1.02-1.49-0.95c-13.26,2.34-27.36-1.57-40.01,5.39c-0.57,0.31-1.63-0.27-2.46-0.44"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M769.45,617.5c-14.99-1.33-29.96-2.91-44.98-3.88c-5.95-0.39-11.98,0.53-17.98,0.89c-1.5,0.09-3,0.32-4.5,0.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1181.16,599.5c-4.33,0.33-8.73,0.29-12.98,1.08c-9.54,1.76-19,3.92-28.49,5.92"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M879.37,973.75c-2.93-7.74,0.66-14.93,2.11-22.23c0.88-4.46,3.09-8.64,4.27-13.05c0.38-1.43,1.4-4.48-2.38-3.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1187.65,514.5c-3.16,0.17-6.33,0.33-9.49,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1130.19,564.5c2.83,13.33,5.9,26.62,8.37,40.02c0.93,5.03,0.43,10.31,0.64,15.48c0.07,1.67,0.13,3.38,0.52,4.99
                c1.78,7.47,3.67,14.91,5.98,24.19c-8.83-1.73-16.4-3.32-24-4.66c-7.47-1.31-14.98-2.37-22.48-3.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M858.39,579c-0.33,6.25-0.5,12.51-1.04,18.75c-0.66,7.6-1.62,15.17-2.46,22.75"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M619.56,612c6.65-5.29,14.95-3.88,22.51-5.37c4.36-0.86,8.97-0.46,13.47-0.63"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1159.67,553.5c-0.17-3.5-0.33-7-0.5-10.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1202.64,539.75c10.49-0.58,20.99-1.16,31.48-1.76c1.34-0.08,2.86,0.04,3.96-0.55c4.31-2.3,8.17-0.96,12.03,1.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M477.16,885.25c-0.76-5.95-4.04-9.79-10-7.76c-0.8,0.27-2.08,0.78-2.43,0.45c-3.77-3.6-7.36-1.21-11.07,0.01
                c-1.05,0.35-2.65,0.52-3.42-0.03c-4.13-2.92-7.39-1.29-10.55,1.58"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1270.09,621.5c-4.91-0.8-9.8-2.21-14.55,0.91c-0.93,0.61-2.96,0.62-3.88,0c-6.43-4.29-13.72-2.27-20.55-2.74
                c-8.44-0.58-16.98,0.18-25.47,0.3c-1.16,0.02-2.33-0.31-3.5-0.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M729.98,743.5c-1.6,1.57-3.21,3.14-5.04,4.94c-2.62-0.28-5.78-0.61-8.95-0.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M688.01,745c-7.7-3.34-15.39-6.91-23.48-1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M80.94,798.5c-5.33,0.5-10.66,1.02-15.99,1.5c-16.01,1.42-31.9,2.14-47.52-3.34c-4.81-1.69-10.27-1.49-15.43-2.16"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M253.82,938.5c-3.16,8.17-6.23,16.37-9.53,24.48c-1.6,3.93-3.76,7.62-5.38,11.55c-0.61,1.48-0.41,3.3-0.57,4.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1074.23,541c-4.47,0.92-7.24,2.8-8.73,7.93c-2.84,9.74-7.09,19.07-10.75,28.57"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1.5,911.5c-0.58,10.44,2,20.27,5.37,30.04c1.11,3.23,0.79,6.96,1.12,10.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M888.37,711c-3.83,0.17-7.66,0.53-11.49,0.46c-7.78-0.14-15.37,0.54-23.04,2.31c-5.22,1.21-10.94,0.23-16.44,0.23"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M722.48,505c-2.83,0-5.68-0.19-8.49,0.05c-3.02,0.27-6.01,1.4-9,1.36c-10-0.12-19.99-0.87-29.98-0.8
                c-5.5,0.04-10.99,1.26-16.49,1.85c-0.97,0.1-1.99-0.3-2.99-0.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1245.11,699c-7.66,0.17-15.32,0.32-22.98,0.51c-5.66,0.14-11.33,0.33-16.99,0.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M491.65,712c-9.66-0.33-19.32-0.66-28.98-1.02c-0.84-0.03-1.66-0.44-2.5-0.46c-10.82-0.36-21.65-0.68-32.48-1.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M676.52,826c-8.45-2.8-16.71-0.1-24.97,1.09c-4.68,0.67-9.99-0.03-13.5,4.41"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1097.72,426.5c1.76,7.55,7.29,11.81,13.49,15.5c5.01,2.98,10.02,5.96,14.98,9.01c6.11,3.75,6.25,5.03,0.48,8.95
                c-6.88,4.67-14.21,8.71-20.81,13.73c-2.1,1.6-3.16,5.4-3.49,8.32c-0.56,4.94-0.1,9.98-0.17,14.98c-0.04,3.08,1.13,5.14,3.98,6.57
                c2.33,1.17,4.25,3.15,6.55,4.39c4.49,2.4,4.21,7.47,6.56,10.98c3.51,5.26,3.52,11.31,4.95,17.05c0.19,0.74,0.96,1.34,1.46,2.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M991.29,669c-2-0.5-3.99-1.41-6-1.43c-10.99-0.12-22.05-4.07-32.98,0.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M89.94,923c-6.16,3.17-12.56,5.95-18.43,9.6c-7.09,4.42-13.72,9.57-20.55,14.4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1028.27,925.5c0.33,9.33,0.3,18.7,1.09,27.99c0.93,10.87,2.52,21.68,3.97,32.5c0.17,1.23,1.26,2.34,1.93,3.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M244.83,931.5c-3.04,12.58-6.08,25.16-9.48,39.24c-2.39-2.74-3.7-4.24-5.01-5.74"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M711.24,495c0.42-2,0.83-4,1.25-6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M712.99,435c-1.33-4.75,0.04-8.98,2.5-13"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M721.48,416.5c2.66,1.17,5.98,1.69,7.88,3.62c6.79,6.87,14.41,13.43,19.31,21.56c8.05,13.39,14.59,27.73,17.87,43.3
                c1.29,6.11,3.41,12.08,5.53,17.97c0.47,1.31,2.4,2.56,3.9,2.95c5.27,1.4,10.73,3.69,15.94,3.3c8.03-0.61,14.9,3.6,22.51,3.72
                c9.99,0.15,19.98-0.32,29.98-0.39c4.33-0.03,8.66,0.3,12.99,0.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M451.18,801.5c-4.77,1.82-9.51,4.66-14.49,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M429.19,799.75c-7.83-1.25-15.63-2.78-23.5-3.6c-2.73-0.28-5.65,1.18-8.48,1.85"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M648.54,551.5c6.68-1.96,13.27-1.15,19.45,1.58c8.02,3.54,16.38,5.2,25.02,5.92"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1188.15,406.5c-0.83,0.33-2.16,0.45-2.41,1.04c-1.19,2.75-3.13,5.73-2.87,8.44c0.63,6.67-1.53,13.41,1.57,20.12
                c1.74,3.76,1.12,8.58,1.8,12.89c0.17,1.08,1.26,2.01,1.92,3.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M572.09,643c-2.61,5.29,1.55,9.63,2.05,14.49c0.42,4.03,1.57,7.98,1.88,12.01c0.43,5.7,2.08,7.34,8.06,6.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1114.2,570.5c-4.66-1.33-9.25-3.33-14.01-3.85c-7.93-0.88-15.97-0.82-23.97-1.15"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M537.12,850.5c0.83,1.67,1.24,3.93,2.58,4.88c3.29,2.33,5.8,4.57,6.16,9.1c0.14,1.79,2.79,3.98,4.8,4.9
                c5.5,2.52,11.27,4.45,16.93,6.62"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1119.2,556c-4.66-3-9.7-5.56-13.9-9.11c-5.29-4.47-10.88-6.16-17.59-4.92c-2.12,0.39-4.32,0.37-6.49,0.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M930.34,511c-6.33,0.5-12.66,1.01-18.99,1.5c-4.66,0.36-9.32,0.79-13.99,0.94c-1.65,0.06-3.33-0.6-4.99-0.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M53.46,820c-6.5,0.17-12.99,0.55-19.49,0.45C23.31,820.3,12.66,819.83,2,819.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1142.68,912.5c-1.87,8.17-1.17,16.32-0.04,24.5c0.85,6.14,1.37,12.33,2.03,18.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1332.05,686c-3.83-1.33-7.89-2.23-11.45-4.09c-8.79-4.58-17.57-4.08-26.53-0.91"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1178.16,610.5c-3.83,0.33-7.67,1.01-11.49,0.93c-8.95-0.18-17.81,0.07-26.48,2.57"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1331.05,950.5c-2.5,5.17-5.23,10.23-7.44,15.52c-3.37,8.08-6.38,16.31-9.55,24.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M381.73,685c0,1.33,0,2.67,0,3.24c-6.39,3.89-13.09,6.86-18.22,11.55c-2.8,2.56-2.97,8.03-4.24,12.22c-0.75,2.47-1.35,5-2.01,7.5"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1059.24,469c1.83-0.17,3.66-0.33,5.5-0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1073.73,470c5.16,0.17,10.38-0.06,15.47,0.61c5.06,0.66,10.01,2.22,15,3.39"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1031.76,526.5c0.83-1.33,2.06-2.56,2.44-4.02c3.02-11.62,9.11-19.98,21.5-23.15c5.24-1.34,10.25-2.05,15.53-1.33"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1088.72,497.75c4.16-0.08,8.33-0.17,12.49-0.25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M596.57,819.5c-1.67,3-3.02,6.23-5.06,8.96c-5.84,7.82-10.49,16.21-13.47,25.53c-0.35,1.1-1.29,2.01-1.96,3.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M708.99,937.5c-9.16,10.83-18.32,21.67-27.48,32.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M909.35,477c4.33-1.33,8.62-2.82,13-3.96c5.12-1.34,10.28-2.64,15.5-3.42c2.39-0.36,4.99,0.5,7.47,0.9c1.35,0.22,2.68,0.62,4,0.98
                c7.16,1.99,14.35,3.9,21.46,6.07c1.5,0.46,2.78,1.82,4.01,2.94c4.7,4.29,9.44,8.55,13.98,13.01c4.45,4.37,8.61,9.05,13.06,13.43
                c1.76,1.74,3.96,3.04,5.96,4.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M166.38,902.5c-1.67,8.83-3.33,17.67-5,26.5c-0.66,3.5-1.33,7-2,10.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M115.92,934c-1.54,12.79-5.32,25.41-3,38.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1168.17,800c-4.16-1-8.33-1.98-12.49-3.01c-2.51-0.62-5-1.33-7.5-1.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1139.69,795.25c-6.85,0.31-13.7,0.62-22.33,1.01c-1.77-2.61-4.53-7.08-7.72-11.2c-1.55-2.01-4.04-3.27-5.89-5.08
                c-3.84-3.74-8.01-7.05-10.18-12.41c-1.69-4.16-4.83-7.73-7.33-11.57"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1070.74,751.5c-5.16-0.67-10.31-1.5-15.5-1.9c-1.62-0.13-3.32,0.9-4.99,1.4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M335.76,844.5c-2,2-3.86,4.16-6.02,5.97c-5.02,4.21-6.89,9.65-7.63,16.01c-0.38,3.28-2.48,6.36-3.84,9.52
                c-0.8,1.85-1.66,3.67-2.49,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1129.19,928.5c3.83,12.17,7.66,24.33,11.49,36.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1371.52,950c-3.03-2.09-8.35,0.64-9.88-4.04c-1.8-5.51-2.72-11.31-4.13-16.96c-0.71-2.86-1.64-5.67-2.48-8.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M508.64,665c-5.2,0.89-10.2,1.07-15.07-1.87c-1.66-1-4.41-1.06-6.38-0.51c-4.8,1.33-10.43-0.85-14.52,3.38"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1321.56,819c1.33,3.5,2.86,6.94,3.96,10.51c4.58,14.81,9.07,29.64,13.51,44.49c2.58,8.64,5.12,17.3,7.47,26.01
                c1.46,5.41,0.53,6.29-4.96,5.49c-3.5-0.51-6.98-1.2-10.5-1.45c-4.45-0.32-6.72,1.76-5.99,6.45"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M785.44,944.5c-4.27,0.78-3.51,4.22-3.45,7c0.19,8.67,0.61,17.33,0.95,26"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M782.94,978c-2.6,2.7-4.09,5.7-2.5,9.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M532.12,945c-10.16,8.5-20.36,16.96-30.42,25.57c-0.95,0.81-1.06,2.6-1.56,3.93"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1073.73,641.5c2.66,9.5,4.18,19.54,8.31,28.35c4.41,9.41,4.57,18.21,3.52,28.18c-1.14,10.84,0.3,21.97,0.75,32.96
                c0.02,0.53,1.25,1,1.92,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1116.2,937c2.17,3.67,4.57,7.22,6.45,11.02c3.83,7.75,7.38,15.64,11.04,23.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1311.56,617c7.83-0.38,15.69,0.36,23.48,0.48c13.3,0.2,27.16-2,39.98,4.02c2.16,1.01,4.33,2,6.49,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M187.87,757c-7.49,0.83-14.97,1.88-22.49,2.42c-4.13,0.3-8.32-0.25-12.49-0.42"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1169.66,561.25c-5.32,0.32-10.79,1.72-15.49-2.25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M814.92,493.5c6.62,6.69,15.82,8.03,23.99,11.47c3.41,1.44,8.39,1.05,7.48,7.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M518.63,808.5c-7.49,0.33-15,0.56-22.48,1.04c-4.18,0.27-8.33,0.96-12.49,1.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M415.7,860c-4.67,3.78-9.6,3.4-15.03,1.61c-4.59-1.51-9.08-1.29-12.56,3.31c-1.73,2.29-4.56,3.75-6.89,5.58"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1025.77,749.25c1.83,0.25,3.66,0.5,5.5,0.75"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1037.76,750.25c3.83,0.42,7.66,0.83,11.49,1.25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M581.08,564c-1.17,1.83-2.33,3.67-3.5,5.5c2.66,0.83,5.33,1.67,7.99,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M586.58,578.5c-5.93,7.35-8.67,15.54-6.5,25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M814.42,887c3.16-2,6.34-3.99,9.49-6.01c1.52-0.97,3.01-2.84,4.5-2.83c4,0.02,9.08-0.49,11.72,1.68c3.22,2.64,9.42,2.95,9.36,9.16
                c-0.01,0.66,1.25,1.91,1.91,1.91c5.73-0.06,4.15,4.55,5,7.59"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M697,828c5.33,1.5,10.58,3.52,16.01,4.39c10.6,1.71,21.3,2.76,31.96,4.12c3.34,0.43,6.65,1.29,10,1.41
                c4.15,0.15,8.32-0.26,12.49-0.42"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1194.15,930.5c-1,10.5-2.28,20.98-2.88,31.51c-0.32,5.46,0.54,10.99,0.89,16.49c0.13,2,0.33,4,0.49,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M958.32,552.5c10.33-0.17,20.66-0.63,30.98-0.4c7.67,0.17,15.32,1.24,22.99,1.9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1125.2,592c-18.38-4.56-36.98-7.18-55.96-6.49c-1.67,0.06-3.33,0.32-5,0.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1319.56,661.5c2.71-5.15,0.64-10.2-2.22-13.83c-3.96-5.02-9.42-8.86-14.3-13.14c-0.64-0.56-1.75-1.14-2.45-0.96
                c-6.82,1.77-9.7-2.82-12.51-7.57"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1265.1,869c2,0.17,4.14-0.07,5.96,0.59c2.9,1.05,7.92,7.97,8.54,10.91c1.25,5.93,5.74,7.62,10.94,9.17
                c3.91,1.17,7.65,3.47,10.96,5.94c3.17,2.36,3.23,5.74,0.58,8.89"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1040.26,268c1.26,5.58-3.39,8.98-5.11,13.46c-2.38,6.18-6.2,11.84-8.15,18.11c-1.34,4.3-0.56,9.26-0.72,13.93
                c-0.03,0.83,0.09,1.68-0.02,2.5c-0.69,4.87-0.78,9.13,1.58,14.2c2.45,5.26,3.28,12.74,1.5,18.16c-2.68,8.18-2.25,16.03-2.09,24.14
                c0.11,5.66,0,11.33,0.04,17c0,0.67,0.31,1.33,0.48,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M713.49,526c-0.67,1.17-1.05,2.75-2.04,3.44c-11.39,7.91-14.63,20.25-17.94,32.56c-1.21,4.49-2.34,9-3.5,13.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M688.76,582c-0.92,3.17-1.83,6.33-2.75,9.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M568.09,639c-8.49-3.33-16.99-6.67-25.48-10"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1102.21,838.5c-8.66,0.67-17.33,1.27-25.98,2.04c-2.52,0.23-5,0.96-7.5,1.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M714.99,683.5c1.73,3.95,4.99,4.59,8.97,5.16c7.9,1.13,15.68,3.18,23.51,4.84"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1073.23,256c-9.95,2.01-18.6,6.68-25.33,14.14c-2.6,2.88-3.99,3.74-6.65,0.86"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M207.85,838c0.94-9.01-4.63-17.03-13.49-19.51c-3.02-0.84-6-1.82-9-2.74"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M419.2,678.5c0.11,5.61-3.33,9.05-8.48,9.59c-7.39,0.77-13.55-2.55-19.99-5.09"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M85.94,939c-11.93,4.06-23.39,9.02-32.98,17.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M714.49,956.5c-3,1.83-6.16,3.45-8.96,5.54c-7.42,5.54-14.69,11.29-22.02,16.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M554.1,766.5c6.85-10.69,6.08-13.22-3.03-20.46c-6.56-5.21-14.64-7.12-22.46-9.52c-1.46-0.45-3.31-0.26-4.44-1.09
                c-6.39-4.69-11.81-0.57-17.55,1.54c-6.77,2.49-10.29,7.93-12.82,14.1c-0.43,1.05,1.18,2.94,1.84,4.44"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M640.54,497c-8.16,2.67-16.3,5.4-24.49,7.96c-2.42,0.76-4.98,1.04-7.48,1.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M110.92,809.5c-8.83,1.33-17.64,2.76-26.49,3.92c-1.76,0.23-3.65-0.59-5.49-0.92"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1399,853.5c-1.83,0.83-3.74,1.53-5.48,2.53c-2.24,1.3-3.91,1.08-6.61,0.33c-4.29-1.19-9.8-1.3-13.75,0.46
                c-4.97,2.22-9.25,2.01-13.6,0.1c-8.89-3.91-12.88-0.19-17.49,5.61c-0.21,0.27,0.13,0.97,0.21,1.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M691.01,441.5c-10.49-0.17-20.98-0.33-31.48-0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M853.39,622.5c0.33,0.67,1.07,1.43,0.94,1.98c-1.7,6.96,3.34,8.61,7.86,11.8c4.26,3.01,6.88,8.37,10.15,12.75
                c0.18,0.24-0.29,0.97-0.46,1.47c-2.98,8.84,1.38,16.29,4.45,24.02c0.81,2.04,1.05,4.31,1.55,6.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1080.73,966c-8.49,8-16.96,16.03-25.52,23.96c-1.05,0.97-2.63,1.37-3.96,2.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1203.14,462c3.9,0.65,7.75,0.97,10.99-2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1214.63,460c5-0.67,10.08-2.24,14.97-1.77c5.83,0.56,12.37-2.27,17.51,2.77"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M771.95,504.5c-6.16,0.83-12.32,1.67-18.49,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M731.98,516.25c-5.16,2.92-10.33,5.83-15.49,8.75"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M710.74,515c-0.25-0.83-0.5-1.67-0.75-2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1007.28,894.5c0.67-3.5,1.52-6.98,1.95-10.51c0.6-4.89-1.94-7.18-6.95-7.03c-8.99,0.28-18.01,0.07-26.97,0.71
                c-2.75,0.2-5.34,2.49-8.01,3.82"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M97.93,749.5c0.42,4.4-0.2,9.13,4.44,12.1c0.77,0.49,0.98,2.23,1.01,3.41c0.09,3.48,1.31,6.15,3.95,8.6
                c1.97,1.82,3.09,4.57,4.59,6.9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M193.36,711.5c1.33,0.83,2.64,2.34,4,2.39c7.15,0.24,14.36,0.56,21.47-0.03c5.77-0.47,11.02,0.79,16.55,1.87
                c4.91,0.96,10.27-0.4,15.44-0.73"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M523.13,900.5c-5.59,2.63-11.08,4.61-17.1,0.67c-1.05-0.69-4.28,0.23-5.26,1.43c-5.09,6.21-12.09,6.44-19.11,6.9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M589.08,616c-1.33,0.83-2.57,1.9-4.01,2.46c-9.3,3.62-10.86,8.24-8.48,19.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M578.34,645c1.92,9,3.78,18.01,5.78,26.99c0.64,2.88,1.64,5.67,2.47,8.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1165.17,304.5c2.17-0.5,4.42-0.76,6.47-1.56c2.85-1.1,5.11-1.46,8.59-0.84c10.13,1.8,20.58,1.73,30.91,2.4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1285.08,487.5c0.67,6.48-3.42,11.41-5.48,17c-1.72,4.65-3.34,9.33-5.01,14"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1274.84,528c-0.58,6.17-0.59,12.46-1.89,18.47c-1.72,7.97-0.33,15.46,1.64,23.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1274.84,577c0.25,1.5,0.5,3,0.75,4.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1180.16,962.5c2.66,8.67,5.3,17.34,8.06,25.98c0.16,0.5,1.27,0.69,1.93,1.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M263.31,952.5c-6.33,8.17-12.53,16.44-19.1,24.41c-1.22,1.48-3.89,1.76-5.88,2.59"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1255.1,835.5c1.69-3.94,5.36-4.08,8.41-3.2c1.19,0.34,1.99,4.04,1.98,6.2c-0.03,7.83,0.14,8.16,8.1,8.5
                c8.6,0.36,14.58,4.61,15.49,11"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M672.02,579c-6.92,0.27-14.1,3.2-20.49-2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M465.67,892c1.33-1,2.53-2.36,4.02-2.93c3.24-1.24,6.58-2.43,9.98-2.97c3.09-0.49,6.32-0.26,9.47-0.04c1.53,0.11,3,0.94,4.5,1.43
                c4.66,1.51,9.33,3,13.99,4.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M468.17,664c-5.25,6.47-12.22,4.86-18.98,3.98c-5.51-0.72-11-1.64-16.49-2.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M429.19,907.5c-1.67,8.33-3.28,16.68-5.08,24.98c-0.18,0.81-1.58,1.35-2.41,2.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M646.54,459.5c-10.72-0.77-19.54,4.9-28.98,8.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1207.14,947.5c-2.66,9.5-5.47,18.97-7.88,28.53c-0.47,1.88,0.57,4.14,1.79,6.29c3.62-5.78,7.47-10.25,14.08-11.32"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1178.16,504c-5.29-5.37-11.52-3.7-17.45-1.89c-4.01,1.23-7.7,1.44-11.53-0.11"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M889.36,560.5c-1-0.67-2.2-2.09-2.97-1.88c-8.87,2.32-17.68,4.88-26.51,7.38"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M892.86,507c-9.74,0.29-19.47,0.56-28.48,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M475.66,632.5c-6.93,4.98-13.85,9.97-20.43,14.7c12.36,14.24,23.94,27.48,35.33,40.87c1.11,1.31,1.56,3.61,1.5,5.43
                c-0.34,10.33,2.49,19.09,11.57,25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M952.32,646.5c-0.83-7.67-1.81-15.32-2.42-23.01c-0.21-2.59-0.92-3.82-3.58-3.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1226.12,392.5c1,2,1.8,4.13,3.03,5.98c3.73,5.57,7.74,10.95,11.38,16.57c0.98,1.52,1.07,3.62,1.57,5.45"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1038.26,620c9,2.28,18.46,3.42,25.48,10.5c1.75,1.76,3.56,3.27,5.99,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1344.04,965.5c-7.49,7.83-14.94,15.71-22.55,23.43c-0.82,0.84-2.6,0.73-3.93,1.07"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1202.14,765.5c-5.66-1-11.29-2.42-17-2.85c-3.77-0.28-7.65,0.85-11.48,1.35"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M160.64,951c0.25,1.17,0.5,2.33,0.75,3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1147.18,296c-1.67-0.67-3.94-0.86-4.89-2.08c-4.26-5.45-10.17-5.86-16.08-5.27c-4.07,0.4-8.01,2.19-12.01,3.35"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1398,715.5c-8.83-0.5-17.65-1.01-26.48-1.48c-0.49-0.03-1,0.31-1.5,0.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M132.41,842c-0.17,4-0.95,8.09-0.37,11.98c1.3,8.73-3.37,14.82-8.13,21.01c-0.93,1.22-1.99,2.34-2.99,3.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1360.03,524c-9.33,1-18.65,2-27.98,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1339.04,731c-9.14-2.75-18.37-4.63-27.98-3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1103.21,597c-9.11-6.49-18.53-4.02-27.98-1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1177.16,509.5c0.98,6.6,0.99,6.47-5.5,6.04c-5.32-0.36-10.66-0.53-15.98-0.46c-1.94,0.03-3.87,1-6.68,1.79
                c7.8,5.2,14.66,9.43,21.05,14.28c1.76,1.33,2.11,4.51,3.11,6.85"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M388.22,949.5c-2,8.17-3.95,16.34-6.05,24.49c-0.28,1.1-1.28,2.01-1.95,3.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M999.79,458c0.67,1.33,2.18,2.93,1.86,3.96c-2.52,8.27,2.61,13.13,7.65,18.02c3.17,3.08,5.77,6.38,5.98,11.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1022.77,491.75c5.16-1.75,10.22-3.99,15.52-5.12c5.87-1.25,11.96-1.47,17.96-2.13"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M636.55,774.75c-5.38-6.03-10.75-12.07-18.49-15.25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M607.07,760c-6.66,1.33-13.37,2.47-19.95,4.14c-1.73,0.44-3.03,2.53-4.53,3.86"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M633.55,507c-9.16,1.83-18.32,3.67-27.48,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1153.18,539c-8.37-2.57-16.91-3.41-25.46-1.42c-5.05,1.18-1.58,4.89-1.39,7.41c0.09,1.22,1.54,2.34,2.37,3.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1113.2,677c-7.49-0.5-14.99-1.07-22.49-1.44c-1.48-0.07-2.99,0.61-4.49,0.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M591.08,691c0.5,5,1,10,1.5,15"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M598.32,722c0.08,2.67,0.17,5.33,0.25,8"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M441.68,892.5c-4.32-0.2-8.64-0.4-13.51-0.62c-4.1-4.91-6.47-4.91-11.47,0.62"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1093.22,548.5c-7.09,0.29-14.7-1.73-20.48,4.5c-1.68,1.82-3.33,3.66-5,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M180.37,941.5c-3.16,8-6.19,16.06-9.61,23.95c-0.66,1.53-2.88,2.39-4.38,3.55"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M965.31,942.5c0.67,7,1.36,14,1.98,21c0.13,1.49,0.02,3,0.02,4.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M753.96,455c-6.96-1.76-14.33-1.24-20.71-6.21c-3.02-2.35-8.44-1.62-12.77-2.29"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1191.15,663.5c1.17,3.17,2.19,6.39,3.53,9.49c2.36,5.48,5.88,10.59,4.97,17.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1259.1,774.5c5.86-1.71,11.57-1.74,16.99,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1281.58,780c0.17,2,0.66,4.04,0.43,5.99c-0.4,3.42,1.23,5.4,4.08,6.47c4.78,1.8,9.62,3.46,14.48,5.01
                c4.1,1.31,7.77,3.09,9.98,7.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M952.32,848.5c1.5-6.36,3.81-8.24,9.99-7.99c2.83,0.11,5.73,0.79,8.48,0.39c4.8-0.71,5.16,2.29,5.47,5.6
                c8.8,0.2,8.8,0.2,12.03,9.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M865.38,652.5c-0.5,0.67-1.5,1.4-1.42,1.99c1.02,7.97,2.1,16.01,6.52,22.94c1.14,1.78,3.56,2.73,5.39,4.07"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M336.76,942.5c0.83,6.17,1.57,12.35,2.54,18.49c0.4,2.54,1.29,5.01,1.95,7.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1055.25,467.5c-6.95,2.7-13.9,5.4-20.77,8.07c1.28,4.26,2.28,7.6,3.28,10.93"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1347.04,905.5c2.5,0.33,5.13,1.33,7.47,0.86c4.85-0.96,8.91,0.58,13.03,2.62c1.55,0.76,3.21,1.29,4.89,1.96
                c2.34-10.3,1.94-12.05-12.39-13.93"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1196.15,611.5c2.17,4.61-1.95,7.62-3.78,10.31c-4.34,6.37-4.87,12.69-3.21,19.69"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1034.26,425c3.08,4.78,7.29,1.36,10.99,1.48c3.49,0.11,7,0.15,10.49-0.02c2.51-0.12,5-0.62,7.5-0.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1399,907c-4,1.33-7.99,2.69-11.99,3.99c-4.55,1.47-9.15,3.85-13.49-0.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1311.56,653.5c-8.49-0.5-16.99-1-25.48-1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M536.12,606.5c-2.5,0.17-5.31-0.34-7.43,0.64c-4.86,2.24-9.39,5.2-14.05,7.86"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1072.23,249c-4.33,1-8.82,1.57-12.95,3.12c-4.36,1.64-8.37,4.22-12.54,6.38"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1176.66,511c-12.82-0.5-25.65-1.12-38.47-1.45c-5.99-0.15-11.99,0.28-17.99,0.45"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M412.71,873.5c-0.5,0.17-1.18,0.63-1.47,0.45c-2.05-1.24-4.38-4.06-5.95-3.67c-3.88,0.97-8.31,2.57-10.84,5.42
                c-3.27,3.69-6.05,2.13-8.63,0.64c-2.23-1.28-3.68-3.91-5.53-6c-3,0.66-3.49,2.14-3.24,5.18c0.29,3.46,0.5,7.84-5.84,6.64
                c-3.44-0.66-8.46,4.8-9.21,8.85"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M526.12,677c1.33,5.33,2.49,10.72,4.06,15.98c0.98,3.27,2.6,6.35,3.93,9.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1349.54,562c-1.33,0-2.83,0.4-3.97-0.06c-8.17-3.3-13.85,0.12-18.52,6.56c-0.63,0.86-1.33,1.67-1.99,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1150.18,516.5c-8.49-0.17-16.99-0.42-25.48-0.43c-1.66,0-3.33,0.93-5,1.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M666.02,746.25c7.58,2.71,15.16,5.62,22.98,0.75"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M578.59,728c-7.33-0.5-14.65-1.16-21.99-1.43c-3.15-0.11-6.33,0.59-9.49,0.93"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1245.11,920.5c-0.83,1.83-1.85,3.61-2.45,5.52c-0.71,2.27-1.05,4.65-1.55,6.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1050.25,752c5.78,5.22,12.91,5.12,19.98,4.89c1.19-0.04,2.34-1.55,3.5-2.39"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M15.49,906c0.17,4.5,0.16,9.01,0.54,13.5c0.49,5.68,2.57,11.55,1.65,16.95c-1.32,7.83,1.17,16.19-3.19,23.55"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M525.12,961.5c-6.66,4-13.3,8.05-20.01,11.96c-1.34,0.78-2.97,1.04-4.47,1.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1242.86,316c2.67,10.89,6.43,21.34,12.22,31.02c1.34,2.23,2.36,4.65,3.52,6.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M967.81,803c-3.83-2.17-7.63-4.39-11.5-6.48c-2.67-1.45-9.75,1.54-10.39,4.5c-0.23,1.04,0.2,2.76,0.97,3.41
                c5.48,4.63,12.15,2.91,18.43,2.89c0.84,0,1.67-2.48,2.5-3.82"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M628.55,718.5c-6.03,3.77-11.53,8.08-14.99,14.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M864.38,513c2.83,0.5,5.65,1.2,8.5,1.44c2.98,0.25,5.99,0.07,8.99,0.05c7.99-0.04,7.99-0.04,7.99,8"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M979.3,373c-0.33,4.67,0.18,9.62-1.27,13.91c-1.12,3.3-4.74,5.74-7.2,8.61c-0.47,0.55-0.69,1.32-1.02,1.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M967.31,404.5c-3.94,16.77-5.1,33.83-5.63,51c-0.17,5.69-1.87,11.34-2.87,17"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1399,759.5c-6.5,0.67-13.3,0.38-19.38,2.33c-4.16,1.33-7.97,3.95-12.6,4.17"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M798.43,956.5c-3.66,8.5-7.33,17-10.99,25.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M946.32,667c-4.83-1-9.62-2.29-14.5-2.9c-3.1-0.39-6.32,0.23-9.48,0.4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1270.09,700.5c6.99,4,13.99,8.01,20.99,11.99c0.97,0.55,1.99,1.01,2.99,1.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1073.23,444.5c-0.33,1.67-0.96,3.34-0.91,5c0.04,1.35,0.79,3.76,1.42,3.83c5.71,0.61,10.26,5.79,16.47,4.17"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M739.47,858c-7.99-0.83-15.99-1.67-23.98-2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M730.48,495.5c9.49,0.67,18.98,1.38,28.48,1.96c1.82,0.11,3.66-0.29,5.49-0.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M832.91,833.5c7.83,2,15.66,4,23.48,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1227.12,544c6.99,0.33,13.99,0.67,20.98,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1267.59,552.25c1,0.08,2,0.17,3,0.25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M609.81,610.5c-2.95,2.91-5.92,3.52-12.24,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M673.52,576c-1-0.67-2.01-1.93-3-1.9c-6,0.14-11.99,0.57-17.99,0.9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1263.1,677c-0.05,7.65,0.39,15.16,5.46,21.53c0.5,0.63,0.89,1.72,0.73,2.46c-1.52,6.88,1.49,12.67,4.3,18.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1090.22,486c-6.33,1-12.66,2-18.99,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1062.24,489.5c-4.86,7.19-13.2,5.65-19.98,8c-1.34,0.47-2.67,1-4,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1311.06,615.5c-1.83,0.5-3.7,1.54-5.49,1.4c-6.63-0.5-12.68,1.17-18.49,4.1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1158.67,541c-7.75,1.05-16.03,0.11-22.48,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M486.65,588.5c-7.66,1-15.32,2-22.98,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M528.62,808c7.66,0.67,15.32,1.33,22.98,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1372.02,680c-7.66,0.67-15.32,1.33-22.98,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M66.45,917.5c-5.5,7.17-10.99,14.33-16.49,21.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1185.65,810.5c-1.72,8.11-0.18,15.72,3.5,23"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1288.33,943c-2.71,8.09,0.39,16,0.73,24c0.27,6.33,0.35,12.67,0.52,19"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M427.69,743.5c-7.66,0-15.32,0-22.98,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1202.14,286c7.66,3.67,15.32,7.33,22.98,11"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1381.01,510c2,0.83,3.93,2.03,6.01,2.43c5.47,1.06,11.92,7.89,11.98,13.07"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M633.55,663.5c4.16-2,8.32-4.01,12.49-5.99c1.48-0.7,3.08-1.2,4.47-2.05c0.98-0.6,2.08-1.49,2.41-2.49
                c0.26-0.8-0.32-2.66-0.91-2.85c-5.77-1.85-11.61-3.51-17.48-5.02c-0.66-0.17-1.64,0.91-2.47,1.41"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M532.12,845c0.17-5.26-2.6-8.67-6.38-12.13c-4.86-4.46-11.1-3.71-13,2.66c-0.94,3.15-0.91,6.63-1,9.97
                c-0.02,0.82,1.23,1.66,1.9,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1379.02,569.5c-1.23-5.65-5.87-7.55-10.53-8.87c-2.97-0.85-6.29-0.46-9.46-0.63"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M397.72,742c-4.5-1.67-8.91-3.66-13.52-4.87c-2.65-0.7-5.63-0.13-8.46-0.13"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M152.39,917.5c1.17,5.83,2.22,11.69,3.57,17.48c0.37,1.61,1.6,3.02,2.43,4.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M487.65,620c-1-0.67-1.97-1.86-3-1.91c-6.32-0.34-12.65-0.42-18.98-0.59"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1097.22,425.5c-6.89-3.6-13.64-7.75-21.98-5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M855.39,946.5c3.5,6.67,6.99,13.33,10.49,20"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1225.12,298.5c0.83-0.67,1.72-1.97,2.49-1.9c6.85,0.59,13.35,2.06,17.54,8.37c0.32,0.48,1.29,0.53,1.95,0.78"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M622.06,764c-0.83,0.67-1.62,1.84-2.51,1.92c-11.65,1.11-23.31,2.08-34.97,3.08"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1086.47,733c-0.75,7-1.5,14-2.25,21"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M162.88,806c-7,0.67-13.99,1.33-20.99,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1249.11,479c-5.37,0.45-6.15,4-6.04,8.5c0.17,7.05-5.14,13.39-11.96,14.38c-0.53,0.08-1.32-1.55-1.98-2.38"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1214.63,517.5c0.17,2.5,0.43,5,0.48,7.5c0.08,4.33,0.02,8.67,0.02,13"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M698.5,454c-6.83,0.17-13.66,0.33-20.49,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M599.57,900c0.33,6.83,0.67,13.67,1,20.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M610.06,544.5c-5,0.17-9.99,0.35-14.99,0.49c-1.83,0.05-3.66,0.01-5.5,0.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M632.55,456c-7.55,0.75-14.08,3.89-19.99,8.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1302.57,783c-6.83-1.17-13.66-2.33-20.49-3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M395.72,705c-7.66,1-15.32,2-22.98,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1117.2,811c-6.66,0.83-13.32,1.67-19.99,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1028.77,479.25c-4.83,1.58-9.66,3.17-14.49,4.75"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M679.51,494c-6.66,0.67-13.32,1.33-19.99,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M209.85,814c-6.66,0.33-13.32,0.67-19.99,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M977.8,389.5c0.33,2.33,0.92,4.66,0.95,7c0.12,8.5,0.07,17,0,25.5c-0.01,0.84-0.62,1.67-0.96,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M975.8,430.5c-2.33,2.33-4.66,4.67-6.99,7"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1242.11,675c7.83,0.5,15.66,0.9,23.48,1.58c1.71,0.15,4.12,0.78,4.86,2.01c2.87,4.75,8.31,5.68,12.13,8.92"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1216.13,331c4.42,2.32,5.46,5.77,4.09,10.53c-0.72,2.51-0.43,5.31-0.6,7.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M612.56,617c-5.33,0.67-10.66,1.36-15.99,1.98c-1.32,0.15-2.66,0.02-3.99,0.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M867.88,948c0.17,4.83,0.43,9.67,0.45,14.5c0.01,1.67-0.61,3.33-0.95,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1065.24,427c2.32,2.05,3.34,3.66,1.16,7.1c-4.77,7.54-4.84,15.96-2.16,24.4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M573.59,931c-4.5,6.5-8.99,13-13.49,19.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M493.15,690.5c6.79,0.89,10.31,4.84,11.98,13.5c0.39,1.99,0.51,4.04,1.04,5.99c1.27,4.64,4.48,6.14,8.97,5.62
                c5.44-0.63,10.98-0.44,16.48-0.61"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1362.03,844.5c7.66,0.17,15.32,0.31,22.98,0.51c4.66,0.12,9.33,0.32,13.99,0.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1092.22,466c-6.41-0.14-13.06-2.51-18.99,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M698.5,665.5c0.91,6.91-2.26,12.84-4.5,19"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M766.45,514c-4.66,0.17-9.33,0.42-13.99,0.47c-2.66,0.02-5.33-0.3-7.99-0.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M970.31,504c2,0.17,4.06,0.07,5.98,0.55c4.7,1.17,9.34,2.61,14,3.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1118.2,458c-6.33,0.67-12.66,1.33-18.99,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M520.13,786.5c-2.83,0.5-5.66,1-8.49,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M141.9,805.5c-0.42,3.27-2.54,4.04-5.5,4.04c-4.83,0.01-9.66,0.29-14.49,0.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M690.51,412.5c-2.18,4.62,0.41,8.48,2,12.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1128.44,552c-0.33,3.6-0.14,7.06,2.75,9.75"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M122.91,953.5c-1.17,5.17-2.37,10.32-3.48,15.5c-0.46,2.14-0.69,4.33-1.02,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1,940.5c0.91,4.13,0.02,9.16,5.5,11"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M16.74,971c1.75,3.83,3.5,7.67,5.25,11.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M266.81,236.5c-3.16-3.42-6.42-6.75-9.44-10.29c-1.13-1.33-1.71-3.13-2.55-4.71"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M228.34,230.5c-4.66-1.33-9.34-2.61-13.98-4.03c-1.56-0.48-3.01-1.3-4.51-1.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M685.51,592.5c-0.5,4-1,8-1.5,12"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1262.1,527.5c-1.67,4.67-3.36,9.32-4.98,14.01c-0.45,1.29-0.68,2.66-1.02,3.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M597.57,810c-4.33,0.67-8.68,1.22-12.98,2.05c-1.73,0.34-3.34,1.28-5.01,1.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M276.8,209.5c-6.33,2-12.66,4-18.99,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M462.67,969.5c1,5.17,1.97,10.34,3.08,15.48c0.06,0.3,1.25,0.35,1.92,0.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1266.1,530.5c-1.17,6-2.33,12-3.5,18"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M515.63,661c-4.33,0.17-8.67,0.21-12.99,0.56c-1.69,0.14-3.34,0.94-5,1.44"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1399,890.5c-0.17-1.5-0.41-4.32-0.49-4.31c-5.06,0.31-7.67-7.37-13.5-4.19"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M700.5,617c-0.17,4.17-0.35,8.33-0.49,12.5c-0.06,1.83-0.01,3.67-0.01,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1348.04,979.5c-1.67,0.83-3.62,1.35-4.94,2.56c-3.82,3.5-7.38,7.27-11.05,10.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1057.24,411.5c-2.93,1.98-3.29,4.23,0.52,4.86c4.57,0.76,9.3,0.47,13.97,0.64"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M210.35,692c4.16,1.17,8.32,2.37,12.5,3.48c1.64,0.44,3.33,0.68,4.99,1.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M579.59,962c-4.16,2.5-8.33,5-12.49,7.5c-2.5,1.5-5,3-7.49,4.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1068.24,433c1.83,0.83,3.62,1.8,5.51,2.47c4.3,1.54,8.19,1.23,10.98-2.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1070.74,489.5c1.83,0.67,3.66,1.89,5.5,1.9c7.33,0.07,14.66-0.24,21.98-0.4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M788.44,665.5c-5.66,0.17-11.33,0.33-16.99,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1243.11,499.5c7.76-0.68,15.15-0.05,20.98,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M819.41,851.5c-3.53-9.05-7.58-9.43-15.99-1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1390.01,935c-5.33,4.33-10.66,8.67-15.99,13"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1370.02,953.5c-1.17,3.67-2.33,7.33-3.5,11"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M656.53,940.5c-0.17,5.67-0.33,11.33-0.5,17"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M649.04,578c0.17,5.67,0.33,11.33,0.5,17"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M460.67,828c5.27,4.05,11.2,2.42,16.99,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M322.77,902c-1.83-1-3.98-1.67-5.44-3.06c-3.96-3.79-5.29-3.72-9.11,1.02c-0.41,0.51-1.61,0.38-2.44,0.55"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M281.8,234.5c-1.17-1-3.12-1.84-3.37-3.03c-1.34-6.42-6.23-5.36-10.62-5.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1103.21,448c-5.66-1.83-11.33-3.67-16.99-5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1131.19,501.5c1.02,4.05,4.41,3.26,6.98,2.87c3.08-0.46,6.01-1.87,9.01-2.87"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M972.31,860c4.33-0.67,8.81-0.83,12.96-2.11c7.74-2.39,10.2-1.81,15.57,5.07c0.48,0.61,1.62,0.7,2.45,1.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1024.77,307c-4.5,1.33-8.99,2.69-13.5,3.98c-0.97,0.28-1.99,0.35-2.99,0.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M978.3,381c-3.66,2.33-7.37,4.6-10.97,7.03c-1.46,0.98-2.69,2.3-4.02,3.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1054.25,400c-3.33,0-6.78-0.52-9.95,0.2c-1.91,0.43-3.38,2.8-5.04,4.3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M956.32,952.5c1.8,6.58,4.55,12.56,10.49,16.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M579.59,791.5c-5.5,0.33-10.99,0.67-16.49,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M102.93,956.5c1.17,4.33,2.46,8.64,3.45,13.01c0.71,3.14,2.37,3.67,5.04,2.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M330.26,979.25c-3.68,2.03-5.56-1.04-8.01-2.72c-3.08-2.12-6.31-4.03-9.47-6.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M535.62,968c-5.33,2.83-10.66,5.67-15.99,8.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M472.66,603c-2.9,3.45-8.09,5.03-8.49,10.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1056.25,718c3.16,0.83,6.3,1.82,9.51,2.4c0.88,0.16,1.98-0.9,2.98-1.4c-0.67-1-1.26-2.8-2.01-2.86
                c-3.47-0.31-6.98-0.14-10.48-0.14"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1325.05,710.5c-5.33-0.5-10.66-1-15.99-1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M898.36,666c-5.33,0.83-10.66,1.67-15.99,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M414.7,674c-5.33,1-10.66,2-15.99,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1011.28,428c-1.83,5.33-3.6,10.69-5.53,15.99c-0.81,2.23-1.97,4.34-2.97,6.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M486.65,678.5c4.55-0.74,8.7-1.08,12.19,3.35c1.28,1.63,4.79,1.55,7.3,2.14c3.82,0.89,7.66,1.68,11.49,2.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1230.62,391c-5.14,0.73-10.48-1.69-15.49,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M656.03,419.5c3,0.67,5.98,1.45,9,1.96c2.13,0.36,4.32,0.37,6.49,0.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1189.65,583.5c1.83,7.17,3.28,14.46,5.62,21.46c1.89,5.64,2.31,5.55-3.62,6.56c-1.19,0.2-2.33,0.65-3.5,0.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1193.65,287.25c-9.22,1.48-18.44,2.97-26.48,8.25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1218.63,558.25c6.99,0.42,13.99,0.83,20.99,1.25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1273.09,524.5c-2.66-2.33-5.54-4.47-7.93-7.06c-2.17-2.35-4.37-2.63-7.05-1.42c-0.06,0.03-0.07,0.35,0.01,0.47
                c0.12,0.2,0.32,0.34,0.49,0.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M723.98,530.5c-2.5,5-5,10-7.49,15"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M616.56,536.5c-0.33,1-0.36,2.52-1.05,2.92c-4.67,2.71-6.24,6.38-4.49,11.6c0.98,2.92,1.54,5.99,2.29,8.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M734.48,471c5,1,9.99,2,14.99,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1349.54,555.5c-0.25-1.17-0.65-2.32-0.72-3.5c-0.24-3.71-1.68-6.45-5.77-6.45c-5.66-0.01-11.33,0.29-16.99,0.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M929.34,804.5c-1.33-1.33-3.63-2.57-3.79-4.02c-0.28-2.68-1.04-4.53-3.66-3.82c-1.73,0.47-3.04,2.5-4.54,3.84
                c1.83,1,3.61,2.13,5.53,2.93c0.65,0.27,1.64-0.26,2.47-0.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1195.15,557c-4.76,3.83-9.91,1.95-14.99,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M922.34,933.5c0.67,5.08,1.33,10.17,2,15.25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1329.05,755c-5,0.33-9.99,0.67-14.99,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M436.69,917c-2,4.83-4,9.67-6,14.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M539.61,712.25c-3.28,2.88-2.85,2.81-2.1,7.27c1.07,6.43,1.44,12.98,2.1,19.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M526.12,683c-3.5-0.17-7-0.47-10.49-0.42c-1.17,0.02-2.33,0.92-3.5,1.42"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1223.13,309c4.83,1,9.66,2,14.49,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1003.28,320.5c-3.16,5.05-7.12,9.15-12.99,11"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1096.22,524.5c-1.35-2.25-2.71-4.51-4.67-7.78c-2.3,3.07-4.06,5.42-5.82,7.78"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1149.68,518.5c-3.21,7.41-2.98,9.55,1.5,14"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1259.1,433c-4.6-3.63-9.95-1.64-14.99-2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M540.11,713c2.17,5,4.33,10,6.5,15c1.66,3.83,3.44,7.62,4.92,11.53c0.36,0.97-0.25,2.3-0.42,3.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1015.77,492.5c-5.81,1.54-3.48,6.36-3.95,10.01c-0.14,1.13,0.28,2.33,0.45,3.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M730.48,459c6.83,1.5,13.63,3.15,20.5,4.42c2.62,0.49,4.83,4.59,7.98,1.08"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1284.08,792c-0.33,4.83-0.67,9.67-1,14.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M802.93,708.5c4.83,1.67,9.66,3.33,14.49,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M715.49,550c-1,1.67-1.83,3.47-3.03,4.98c-5.26,6.58-11.99,11.45-18.98,15.99c-0.35,0.23-0.98,0.03-1.48,0.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1348.04,553c-4.66-0.83-9.33-1.67-13.99-2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M617.56,645c3.16,0,6.36-0.28,9.48,0.11c1.74,0.22,4.22,1.14,4.85,2.47c1.84,3.92,5,4.66,8.66,4.92"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1054.75,413.5c-1.72-1.61-3.44-3.22-5.38-5.03c-3.04,1.01-7.42,0.73-7.61,6.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1282.08,969.5c-0.17,2.83-0.13,5.7-0.57,8.49c-0.3,1.89-1.26,3.68-1.93,5.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M717.49,970.5c-1.33,1-2.9,1.8-3.95,3.04c-2.17,2.58-3.91,5.64-8.04,4.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1104.21,693.5c-4.66,0.17-9.33,0.33-13.99,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1107.21,556c4.66,2,9.33,4,13.99,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M361.74,732.5c-1.67-4.02-5.23-2.27-8-2.39c-1.97-0.08-3.99,0.89-5.99,1.39"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M638.54,678c-0.67-0.17-1.43-0.64-1.98-0.45c-6.66,2.18-12.76-0.45-19.02-1.95c-0.83-0.2-1.98,0.9-2.97,1.4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M615.06,679c4.22,2.95,8.51,4.19,12.99,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M977.8,419c-3.67-0.68-5.72,1.75-8.05,3.94c-1.38,1.3-3.28,2.06-4.94,3.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1031.76,425.5c-0.25,0.5-0.39,1.32-0.77,1.45c-6.89,2.4-13.81,4.71-20.72,7.05"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M473.66,551c-5.65,1.23-11.05,0.82-15.99-2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1057.24,300.5c-0.83,1.5-1.6,3.04-2.51,4.49c-1.79,2.86-3.65,5.67-5.48,8.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1015.27,416.5c1.5,0,3.28,0.53,4.45-0.09c5.21-2.76,10.12-2.54,15.07,0.54c0.6,0.37,1.63,0.05,2.47,0.05"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M2,856.5c6.83,0.83,13.65,1.69,20.49,2.47c0.8,0.09,1.66-0.3,2.49-0.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M589.58,900.5c1,4.33,2,8.67,3,13"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M626.05,441.5c-6,1.67-11.99,3.33-17.99,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M566.6,927.5c-3.16,4.33-6.33,8.67-9.49,13"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1250.61,565c1.33,6.01,2.77,6.95,9.99,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M589.58,721c-4.33,0-8.66,0-12.99,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M5.5,874c4.66,0.5,9.5,1.99,13.94,1.22c4.31-0.75,7.29-4.64,8.54-9.22"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M951.32,647c-3.54,3.65-3.72,8.34-4,13"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1070.24,284c-5.03-0.48-9.86-0.26-13.47,4.04c2.86,4.61,6.23,7.37,11.47,2.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M554.6,695c-4.33-0.5-8.66-1-12.99-1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1257.6,565.75c-0.83-0.25-1.67-0.5-2.5-0.75"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1239.11,557.5c-0.98-3.22-3.53-4.48-6.49-4.43c-5.5,0.1-10.99,0.6-16.49,0.93"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M756.46,743c3.67,3.89,7.86,3.5,12.24,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M798.43,850c-5.08-0.53-9.15,1.15-11.99,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M932.33,938.5c-1,1.83-1.55,4.28-3.08,5.39c-6.26,4.56-6.47,10.9-5.92,17.61"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M988.79,956.5c-0.83,4-1.67,8-2.5,12"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M463.17,668.5c2.49,2.4,5.85,3.45,6.86,7.92c0.76,3.36,5.39,3.92,9.13,2.58"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1025.77,300.5c-4.16,1.33-8.33,2.67-12.49,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M801.93,977.25c-2.76,3.48-4.43,8.55-10.49,7.25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M41.47,961.5c-3.16,3.5-6.33,7-9.49,10.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M836.4,955c2.83,2,5.62,4.06,8.51,5.97c1.04,0.69,2.31,1.03,3.48,1.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M608.57,610c-4-3.11-7.99-2.51-11.99,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M512.63,586.5c-4,1-7.99,2-11.99,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M18.99,876c-1.5,2.33-2.71,4.95-4.62,6.88c-0.99,1-3.29,1.27-4.86,1.01c-2.57-0.43-5.01-1.56-7.51-2.39"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1279.09,881c-4,0.33-7.99,0.67-11.99,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1209.14,552c-4-2.14-7.99-2.53-11.99,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M746.47,507c-4-0.33-7.99-0.67-11.99-1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1057.74,473c-1.17,0.17-2.75-0.1-3.43,0.57c-5.12,4.97-11.74,4.64-18.05,5.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M659.53,581c0.33,4,0.67,8,1,12"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M714.99,421.5c-5.67-0.73-7.87,1.09-8.49,7"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M617.56,575.5c-0.67,4-1.33,8-2,12"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M930.84,960.5c0.97,5.94-3.51,7.79-7.49,10"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1379.02,873.5c-4.58-1.27-9.08-2.19-13.98-2.34c-4.27-0.13-5.94,1.8-8.01,4.34"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1363.03,255.5c-3.31-1.36-5.88-0.6-8.59,1.89c-2.37,2.17-5.57,3.44-8.4,5.11"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1084.73,980.5c-3.5,2.5-6.99,5-10.49,7.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M493.65,756c-1.53,3.27,1.48,4.33,3.03,5.96c2.19,2.31,4.63,4.37,6.96,6.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1074.73,479.5c-1.17,0.67-2.26,1.62-3.51,1.94c-2.28,0.58-4.66,0.79-7.41,1.21c-0.82-1.25-1.94-2.95-3.06-4.65"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1020.27,971.5c1.5,3.83,3,7.67,4.5,11.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M447.68,796c-3.83,1.5-7.66,3-11.49,4.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1270.09,596c-3.83,0.5-7.66,1-11.49,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1360.53,588.5c3.37,1.13,6.73,2.27,10.99,3.7c0-3.2,0-4.7,0-6.2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1236.12,928.5c-2.38,3.78-2.3,7.45,0.5,11"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1279.09,986c-0.67,2-1.13,4.1-2.07,5.96c-0.61,1.2-1.93,2.04-2.92,3.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1166.67,297c-0.33-0.83-0.6-2.35-1.01-2.39c-3.65-0.35-7.32-0.44-10.98-0.61"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M353.75,839c-3.66,0.17-7.33,0.33-10.99,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M493.65,572c-4.66,0.5-9.33,1-13.99,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M637.04,968.5c1.5,4,3,8,4.5,12"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M607.57,578.5c0.44,5.11,3.83,7.86,7.99,10"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1224.63,379c1.33,3.67,2.66,7.33,4,11"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1351.04,555.5c-4.11,0.3-1.81,3.78-1.35,4.39c5.06,6.61,1.19,11.57-2.65,16.61"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1373.02,936.5c-0.17,3.67-0.33,7.33-0.5,11"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M294.79,707.5c-3.66,0.17-7.33,0.33-10.99,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M704.5,581.5c-0.83,7.33-1.71,14.66-2.48,22c-0.4,3.81-0.59,7.65-0.89,11.62c-3.32,1.99-6.97,4.18-10.62,6.37"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1166.17,540c0,1,0.24,2.07-0.04,2.99c-1.81,5.96,0.69,10.3,5.04,14.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M629.55,543c-3.5,0-6.99,0-10.49,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1339.04,837.5c-3.5,0.33-6.99,0.67-10.49,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1390.01,681.5c1.83,0.67,3.63,1.67,5.51,1.86c1,0.1,2.15-1.2,3.23-1.86"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M394.72,900.5c-5.1-1.26-10.42,3.38-15.67-0.77c-0.85-0.67-4.18,1.7-6.3,2.79c-1.39,0.71-2.68,1.64-4.01,2.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1381.51,508c1.43-1.07,2.86-2.15,4.39-3.3c-4.5-4.6-7.6-3.57-10.39,0.3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1131.19,294c3.5,0,6.99,0,10.49,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1248.11,447.5c1.03,4.91-3.95,6.51-5.5,10"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1274.09,474c-0.59,4.04,0.25,7.38,4.5,9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1242.61,440.5c-0.83,3.67-1.67,7.33-2.5,11"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M787.44,618.5c-3.33,0-6.66,0-9.99,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1013.28,319c-6.33,0.33-12.66,0.67-18.99,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1311.06,785c-3.33,0.5-6.66,1-9.99,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1277.09,653.5c-3.38,0.77-7.66,0.51-6.99,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1187.65,513c-3.05-2.35-6.44-2.49-9.99-1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M299.79,245.5c-3.33-0.5-6.66-1-9.99-1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M775.45,514c2.17,0,4.36-0.18,6.48,0.11c0.6,0.08,1.02,1.55,1.51,2.39"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1202.14,517.5c1.42,2.79-0.42,7.12,4,8.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M976.8,969.5c0.2,3.75-0.56,6.85-5,7.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M533.37,615.5c-1.08,1.33-2.74,2.52-3.12,4.03c-0.64,2.55-0.46,5.3-0.63,7.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M587.58,969.5c-3.16,2.33-6.33,4.67-9.49,7"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M603.07,572.5c-3.3-2.44-6.46-2.2-9.49,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M934.33,804.5c0.96-3.84-0.08-5.98-4.5-5.55c-1.3,0.13-2.66-0.29-3.99-0.45"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M767.95,976.5c1.5,3,3,6,4.5,9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1094.22,307c3,0.17,6,0.33,8.99,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M512.13,717c0.5,2.33,1.12,4.65,1.41,7.01c0.07,0.6-0.91,1.32-1.41,1.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M593.58,574c4.58,1.17,9.13,2.5,13.77,3.36c0.9,0.17,2.14-1.52,3.22-2.36"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M550.61,666c-3,0.33-6,0.67-8.99,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M804.43,622.5c4.66-0.5,9.33-1,13.99-1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1084.23,283c2.83,1.17,5.66,2.33,8.49,3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M231.83,690c-3-0.33-6-0.67-8.99-1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1242.11,535.5c-1.42-4.31-4.68-3.74-7.99-3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1157.67,550c-2.83,0.17-5.66,0.33-8.49,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M67.95,958c-2.83,1.83-5.66,3.67-8.49,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M513.63,617c2.5,0.5,4.99,1.35,7.5,1.41c2.82,0.07,5.66-0.57,8.49-0.91"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M899.36,952.5c-1,2.83-2,5.67-3,8.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1035.26,554c-3.66,0.67-7.33,1.33-10.99,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M618.56,545.5c1.08,6.17,2.17,12.33,3.25,18.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1357.03,874c-3.76-3.14-7.57-4.3-12.05-1.08c-1.45,1.04-3.6,1.08-5.44,1.58"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M614.06,678c-3.45-1.18-6.87-3.02-10.49-0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M250.82,194.5c-7.9,2.02-9.48,4.13-6.94,9.47c0.25,0.53,1.28,0.69,1.94,1.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1066.24,458.5c-1.01,0.28-2.03,0.56-3.07,0.85c1.06,2.98,2.06,5.82,3.07,8.65"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M345.75,852.5c-2.66,1.83-5.33,3.67-7.99,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M989.79,466.5c0.67,2.67,1.33,5.33,2,8"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1263.1,603.5c-1-0.83-2.41-1.47-2.93-2.53c-2.11-4.38-5.78-5.2-10.07-4.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M794.43,708.5c2.71-1.18,6.74,3.27,8.49-2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1062.24,475.75c-6.03,3.75-6.71,8.94-2,15.25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1128.19,680.5c5.33,1,10.66,2,15.99,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M890.36,680.5c-2.66,0.5-5.33,1-7.99,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M520.63,613c1,4.5,2,9,3,13.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M413.7,260.5c-2.66-1.5-5.33-3-7.99-4.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M662.53,409.5c-2.66,0.33-5.33,0.67-7.99,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1137.19,276c-2.66,0-5.33,0-7.99,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M488.15,622c0.5,2.67,1,5.33,1.5,8"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M233.33,969.5c0,2.5-0.78,5.37,0.18,7.41c1.42,3,0.32,4.46-1.68,6.09"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1247.11,927.5c-1.83,2.17-3.66,4.33-5.5,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1343.04,864c1.83,2.17,3.66,4.33,5.5,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1275.59,777c-2.5,0.5-5,1-7.49,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1249.11,436.5c3.52-0.12,7.07-0.33,8.49,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M711.49,437.5c-5.52-0.71-3.59-5.35-4.17-9.37c-3.4,0.68-6.36,1.27-9.32,1.87"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1055.75,451c-1.5,2.5-3,5-4.5,7.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M668.52,407.5c2.33,0,4.66,0,6.99,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M201.86,756c4.66,0.17,9.33,0.33,13.99,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M942.33,868c1.83,1.83,3.66,3.67,5.5,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1025.27,317c-2.33,0.17-4.66,0.33-7,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M758.46,445c-2.17-0.17-4.33-0.33-6.5-0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1150.68,557c-1.24-2.5,0.79-7.58-4.48-6.87c-4.63,0.63-1.68,4.87-2.52,7.37"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M456.67,548c-2.17,0.33-4.33,0.67-6.5,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1087.72,250c-2.17,1.17-4.33,2.33-6.5,3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1287.08,726c-1.67,0.32-3.33,0.65-5.5,1.07c0,1.42,0,2.93,0,4.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M609.56,789.5c-4.33,0.33-8.66,0.67-12.99,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1078.23,330.5c-2,0.17-4,0.33-6,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1132.19,835.5c-2.83,0.33-5.66,0.67-8.49,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M272.81,191c-2,1-4,2-6,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M826.91,940.5c0.83,2,1.67,4,2.5,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M991.29,387c-2,0.33-4,0.67-6,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M618.56,473c-2-0.33-4-0.67-6-1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M990.29,476.5c-0.17,2-0.33,4-0.5,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1288.58,972c-3.44-1.68-7.87-2.29-7.99-7.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M387.72,981c-4.18,6.4-4.18,6.4-9.49,3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M564.6,719.5c-3.33-0.33-6.66-0.67-9.99-1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M752.46,449.5c-1.83,0.83-3.66,1.67-5.5,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1102.21,286c-2.83,0.33-5.66,0.67-8.49,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M148.89,750.5c0.67,1.5,1.33,3,2,4.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1378.02,527c-2.33,0-4.66,0-6.99,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1067.24,639c3.66,0.56,7.33,2.26,10.99,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M261.81,708c0.33,1.17,0.67,2.33,1.03,3.62c-1.66,0.95-3.35,1.91-5.03,2.88"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1356.03,761c-3-0.17-6-0.33-8.99-0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M7.99,871.5c-2.33,0.83-4.66,1.67-7,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M978.3,372.5c2.66,0,5.33,0,7.99,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1246.11,370.5c-2-0.17-4-0.33-6-0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M258.32,187.5c-1.83,0.67-3.66,1.33-5.5,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M310.78,708c-2,0-4,0-6,0"/>
        
            </g>
            </svg>
        </div>
        )
    }