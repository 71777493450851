import React from "react";
import "./Arches.css";
import "./Moses.css";

export default function Moses(props) {
  return (
    <div className={`arches-container ${props.size}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id={props.id}
        viewBox="0 0 490 724"
        style={{ enableBackground: "new 0 0 490 724" }}
      >
        <g className="arches-image">
          <rect x="2" y="2" class="st0" width="1000" height="auto" />
          <path
            onClick={() => props.onFill(0)}
            fill={props.fillColors[0]}
            d="M272.2,699.9c-2.7-8.4-4.6-15.9-5.3-20.1c-2.3,4.5-4.4,8.7-6.5,12.5c-1.2,2.2-2.3,4.3-3.5,6.3
		c-1.2,2-2.1,3.8-3,5.6c-1.7,3.6-3.2,6.7-4.3,9.3c-2.4,5.1-3.7,7.9-3.9,7.8c-0.2-0.1,0.7-3.1,2.6-8.4c1-2.6,2.2-5.9,3.8-9.6
		c0.8-1.8,1.7-3.8,2.8-5.9s2.1-4.1,3.2-6.4c2.2-4.5,4.6-9.5,7.2-14.8c1.3-2.7,2.6-5.4,4.1-8.2l4.6-8.6c3.1-5.9,6.5-12,9.9-18.4
		c1.7-3.2,3.5-6.4,5.3-9.7c1.8-3.3,3.3-6.6,4.8-10.2s2.9-7,4.2-10.5c0.7-1.7,1.3-3.5,2-5.2c0.6-1.7,1.4-3.5,2-5.1
		c2.7-6.7,5.3-13.2,7.7-19.3c0.6-1.5,1.2-3.1,1.7-4.6s0.9-3,1.4-4.5c0.8-3,1.7-6,2.5-8.8s1.7-5.6,2.6-8.2s1.8-5.1,2.6-7.5
		c3-9.5,5-17.5,6.5-22.9c0.9-3.4,1.6-5.9,2-7.2c-0.3-0.1-0.6-0.1-0.8-0.2c-2.4-0.6-5.2-1.4-8.3-2.4c-3.1-1-6.5-2.1-10-3.5
		c-3.3-1.4-6.5-3.1-9.5-5c-2.5-1.6-4.8-3.5-6.9-5.7c-0.9-0.9-1.5-2.1-1.8-3.3c-0.2-0.7-0.3-1.5-0.1-2.2c-0.4-0.2-0.9-0.3-1.3-0.3
		c-0.3,0-0.5,0-0.7,0.2l-0.2,0.1l-0.2,0.2l-0.5,0.4c-0.7,0.5-1.3,1.2-1.7,2c-0.1,0.4-0.2,0.9-0.1,1.3c0.1,0.7,0.2,1.4,0.3,2.1
		c0.2,1.5,0.4,3,0.5,4.7s0.4,3.4,0.5,5.2l0.1,1.4c0,0.5,0,0.9,0,1.4c0,0.9,0.1,1.8,0.2,2.8c0.1,1.9,0.3,3.8,0.4,5.5
		c0.1,0.9,0.1,1.8,0.2,2.7c0,0.4,0,0.9,0,1.4s-0.1,0.9-0.1,1.3c-0.3,3.3-0.6,6.3-0.8,8.7s-0.5,4.5-0.7,5.9c-0.1,0.5-0.2,1.1-0.3,1.6
		c0,0.4-0.1,0.7-0.2,0.7c-0.1,0-0.3-0.1-0.4-0.5c-0.2-0.5-0.3-1.1-0.4-1.6c-0.3-2-0.4-4-0.4-6c-0.1-2.5,0-5.5,0.1-8.8V526
		c0-0.3,0-0.7-0.1-1.2c-0.1-0.8-0.2-1.7-0.2-2.6c-0.2-1.8-0.3-3.7-0.5-5.6c-0.1-1-0.1-1.9-0.1-2.9c0-0.5,0-0.9,0-1.4l0-1.3
		c0-0.6-0.1-1.2-0.1-1.8c-0.4,0.8-0.9,1.6-1.5,2.6c-1,1.8-2.3,4-3.7,6.5c-0.7,1.3-1.4,2.7-1.9,4.1l-1.8,5c-2.6,7-5.7,15.2-9.5,24.3
		c-1.9,4.6-4.1,9.2-6.3,14c-1.1,2.4-2.2,4.9-3.2,7.4c-0.5,1.3-1,2.5-1.4,3.8c-0.2,0.6-0.4,1.3-0.5,1.8c-0.1,0.3-0.1,0.6-0.1,0.8
		l-0.1,1.1c-1.3,10.5-1.9,21-2.1,31.6c-0.2,9.8-0.5,18.6-0.9,26c-0.7,14.8-1.5,24-2,24c-0.5,0-0.6-9.2-0.6-24c0-3.8,0-7.9,0.1-12.4
		c-0.5,4.3-1.2,9.1-1.9,14.5c-1.7,12.6-4.2,25.1-7.4,37.5c-1.9,7-4,14.2-6.6,21.6c-2.6,7.7-5.6,15.2-9.1,22.5h57.9
		C280.3,720.8,276,711.6,272.2,699.9z"
          />
          <path
            onClick={() => props.onFill(1)}
            fill={props.fillColors[1]}
            class="st1"
            d="M475,704.6c0.6-2.7,1.1-5.7,1.5-8.9c0.5-3.3,0.6-6.7,0.3-10.1c-0.5-3.8-1.2-7.5-2.1-11.2
		c-0.9-3.9-2-7.9-3.2-11.9c-0.6-2-1.2-4-1.8-6s-1.4-3.8-2.2-5.6c-1.5-3.7-3.1-7.2-4.5-10.6c-3-6.8-5.7-12.8-7.9-18
		c-0.7-1.7-1.6-3.3-2.5-4.9c0.2,1.4,0.4,3.3,0.6,5.8c0.6,7.7,0.8,15.4,0.7,23.2c-0.1,4.9-0.2,10.2-0.4,16s-0.5,11.9-1.3,18.4
		c-0.5,3.3-1.2,6.5-1.9,9.8c-0.7,3.3-1.5,6.7-2.3,10.1c-1.6,6.8-3.4,13.9-5.3,21.1c0,0.1-0.1,0.1-0.1,0.1h27.9
		C469.9,721.8,472.7,715.5,475,704.6z"
          />
          <path
            onClick={() => props.onFill(2)}
            fill={props.fillColors[2]}
            class="st1"
            d="M438.8,720.8c1.8-7.2,3.6-14.2,5.3-21c0.8-3.4,1.6-6.7,2.4-10c0.8-3.3,1.5-6.5,2-9.5c0.9-6.2,1.3-12.3,1.7-18
		c0.4-5.7,0.6-11,0.9-15.9c0.6-9.7,0.5-17.5,0.6-23c0-3.3,0.1-5.8,0.2-7.1c-0.1-0.1-0.1-0.2-0.2-0.3c-1.2-1.9-2.1-3.5-3-4.8
		c-0.4-0.7-0.8-1.3-1.1-1.9c-0.6-0.3-1.3-0.7-1.9-1.1c-1.9-1.1-3.6-2.6-4.9-4.3c-0.3-0.5-0.6-0.9-0.7-1.3c-0.2-0.4-0.3-0.8-0.5-1.2
		c-0.2-0.8-0.4-1.5-0.5-2.2c0-0.1,0-0.1,0-0.2c-1.1-1.3-3.7-5-7.6-10.1c-1.3-1.6-2.7-3.3-4.3-5.1c-0.8-0.8-1.7-1.8-2.6-2.9
		c-0.9-1-1.7-2.1-2.5-3.3c-3.4-5-6.5-10.1-9.3-15.4l-4.5-8.1c-1.4-2.6-2.9-5-4.3-7.5c-2.3-4.3-4.3-8.8-6.1-13.4
		c-2.1-5.3-3.4-9.2-3.9-11.3c-4.4,1-8.8,1.5-13.3,1.4c-2.7-0.1-4.7-0.5-5.6-0.8c0,0.9-0.1,2-0.1,3.3c0,1,0.1,2,0.2,3
		c0.2,1.1,0.4,2.3,0.7,3.6c0.5,2.6,1.1,5.6,1.6,8.9s1,7,1.4,10.9c0.2,2,0.4,4,0.5,6.1c0,1.1,0.1,2.2,0.2,3.2c0.1,1.1,0,2.2,0.1,3.3
		c0,9-0.9,18.7-1.8,28.8c-0.1,1.3-0.2,2.5-0.2,3.7c0,0.6,0,1.2,0,1.7l0.1,1.9c0.1,2.5,0.1,5,0.2,7.4c0.1,4.8,0.3,9.4,0.7,13.7
		c0.4,4.1,1.1,8.1,2.2,12.1c1,3.7,2.1,7.2,3.2,10.3c1.1,3.1,2.3,5.9,3.2,8.4c0.9,2.5,1.6,4.7,2.1,6.6c0.4,1.4,0.6,2.9,0.7,4.3
		c0,0.1,0,0.2,0,0.3c0,0,0,0,0.1,0c0.2,0,0.4,0.1,0.5,0.1l0.1,0.1c-0.4-1.3-0.6-2-0.5-2c0.1-0.1,0.8,1.1,1.9,3.4c0,0,0,0,0,0.1
		l0.2,0.3c0.2,0.4,0.4,0.8,0.6,1.2c0.4,0.8,0.7,1.6,1,2.4c0.1,0.2,0.1,0.4,0.2,0.5c1,2.5,2.2,5.6,3.4,9.2c0.6,1.9,1.2,3.9,1.9,6.1
		c0.3,1.1,0.6,2.2,0.9,3.4c0.2,0.6,0.3,1.2,0.5,1.9c0.2,0.9,0.4,1.9,0.7,2.9l-8.2,1.8l-1.5,0.3l-0.8,0.2l-0.2,0c0,0,0,0,0,0.1
		c-0.9,1.2-2.1,2.1-3.5,2.7c-1.2,0.5-2.5,0.8-3.7,1.1l-7.4,1.9c-1.4,0.4-2.7,0.7-4.1,0.8c-0.4,0-0.8,0-1.2,0c-0.2,0-0.4,0-0.6-0.1
		c-0.1,0-0.1,0-0.2-0.1v0.1c0,0,0,0.1,0,0.1v0.2c0,0.2,0,0.5,0,0.7c-0.1,1.9-0.7,3.7-1.7,5.3c-0.6,0.9-1.6,1.6-2.6,2
		c-1.1,0.4-2.3,0.4-3.4,0c-0.3-0.1-0.5-0.2-0.7-0.3c0,0,0,0,0,0c-0.1,0.5-0.3,1.1-0.5,1.6c-0.2,0.5-0.2,1.3-0.9,1.1
		c-0.6,0-1.1,0-1.7,0l-9.1-0.2c0-1.9,0.1-3.8,0.1-5.7c0,0-0.1,0-0.1,0c-0.3,0.1-0.5,0.1-0.8,0.1c-0.6,0.1-1.2,0.1-1.8-0.1
		c-0.6-0.1-1.3-0.4-1.8-0.8c-0.5-0.4-0.9-0.8-1.2-1.3c-0.2-0.2-0.3-0.5-0.4-0.8l-0.1-0.3c0-0.1,0-0.2,0-0.1H343
		c-0.3,0-0.5,0-0.8-0.1c-0.7-0.2-1.3-0.5-1.8-1c-0.3-0.3-0.6-0.6-0.8-1l-0.1,0.5c-0.1,0.6-0.3,1.2-0.6,1.8l-0.2,0.4l-0.1,0.2h-0.2
		l-0.9,0.1c-0.6,0.1-1.2,0-1.8-0.2c-0.3-0.1-0.6-0.2-0.9-0.4c-0.6-0.3-1.2-0.8-1.6-1.3c-0.2-0.3-0.4-0.5-0.6-0.8
		c-0.1,0.2-0.2,0.5-0.3,0.7l-6.4-3.7c-0.1-0.8-0.2-1.7-0.3-2.5v-0.7h-0.9l-3.1,0.1l-0.2-2.6v0.1v0.2c0,0-0.1,0-0.1,0.1
		c-0.2,0.1-0.4,0.2-0.6,0.2h-0.1h-0.3c-0.2,0-0.3,0-0.5,0.1c-0.3,0-0.7,0-1,0c-0.2,0-0.3,0-0.5-0.1c-0.2,0-0.4-0.1-0.6-0.2
		c-0.4-0.1-0.8-0.3-1.2-0.5c-0.7-0.4-1.3-0.9-1.9-1.4c-0.3-0.1,0-0.3,0.1-0.4l0.3-0.4c0.2-0.3,0.4-0.5,0.7-0.8s0.6-0.6,0.8-0.9
		l0.3-0.3l0.3-0.3c0.7-0.7,1.3-1.4,1.9-2.1c2-2.4,3.8-5,5.4-7.7c1.2-2.1,2.1-3.9,2.7-5.1c0-0.2,0-0.4,0.1-0.6l0.1-0.5l0.2-0.6
		c0.1-0.4,0.3-0.8,0.5-1.1c0.2-0.3,0.5-0.6,0.9-0.7c0.1,0,0.2-0.1,0.2-0.1c-0.3-1-0.6-2.5-1-4.5c-1-5.7-1.4-11.4-1.3-17.1
		c0.1-3.7,0.4-7.6,0.8-11.8c0.4-4.2,0.8-8.7,1.4-13.5c1.1-9.5,2.5-19.9,4.2-30.7c1.8-10.8,2-21.1,2.4-30.6c0.2-4.8,0.3-9.3,0.5-13.5
		c0.2-4.2,0.3-8.1,0.2-11.7c-0.1-7.1-0.6-12.9-0.9-16.9c0-0.5-0.1-1-0.1-1.4c-1,0-3-0.4-5.8-1.1c-0.1,1.4-0.5,3.9-1.2,7.4
		c-1,5.5-2.6,13.5-5.4,23.3c-0.7,2.5-1.6,4.9-2.4,7.5s-1.6,5.3-2.4,8.2l-2.3,8.8c-0.4,1.5-0.8,3.1-1.3,4.7c-0.5,1.6-1.1,3.1-1.6,4.7
		c-2.3,6.3-4.9,12.8-7.5,19.5c-0.7,1.7-1.3,3.3-2,5.1l-2,5.2c-1.4,3.5-2.7,7-4.3,10.6c-1.4,3.6-3,7.1-4.8,10.5l-5.3,9.7L276.9,661
		l-4.7,8.5c-1.5,2.8-2.9,5.5-4.2,8.1c0,0,0,0,0,0.1c1.6,3.3,4.5,11.5,7.6,21.1c4,12.1,7.1,22,6.5,23.3h159
		C440,721.8,438.7,721.2,438.8,720.8z"
          />
          <path
            onClick={() => props.onFill(3)}
            fill={props.fillColors[3]}
            d="M198.6,716.2c-0.6-2.1-1.2-4.7-1.7-7.5s-1-5.9-1.6-9.1s-1.4-6.1-2.7-8.1c-1.3-1.9-3-3.5-5-4.7
		c-1.5-1-2.9-1.7-3.9-2.2c0,1.2-0.1,2.4,0,3.7c0.1,1.7,0.1,3.4,0.2,5.1l0.2,2.6c0.1,0.9,0.2,1.8,0.3,2.6l0.4,2.7l0.2,1.4
		c0.2,0.8,0.3,1.6,0.5,2.4c0.3,1.4,0.5,2.7,0.8,4l0.4,2l0.1,0.5c0,0,0,0.1,0,0.1l-0.1-0.1l-0.2-0.2l-0.9-0.6l-3.6-2.3l-1.8-1.2
		l-1.2-0.8c-2.3-1.6-4.6-3.4-6.8-5.3c-1.1-0.9-2.1-1.9-3-3c-0.9-1.1-1.6-2.1-2.4-3.1c-0.7-1-1.4-2.1-2.1-3.2
		c-0.7-1.1-1.2-2.4-1.5-3.7c0-0.2,0-0.4,0-0.6c0-0.2,0.1-0.5,0.1-0.7c0-0.2,0.1-0.4,0.2-0.5l0.2-0.4c0.3-0.5,0.6-1,0.9-1.5
		c0.6-0.9,1.1-1.8,1.7-2.6c1.1-1.6,2.2-3.1,3.1-4.4c1.9-2.7,3.4-4.7,4.4-6.2c1-1.4,2.5-3.6,4.1-6.3s3.5-6.3,5.6-10.2
		c2.1-4,4.4-8.5,6.7-13.3c1.2-2.4,2.3-4.9,3.5-7.6c1.1-2.6,2.2-5.3,3.3-8.1c8.6-22.6,17.1-41.3,19.6-47.7c2.2-6,4.1-12.1,5.7-18.4
		c1.3-4.8,2.2-9.6,2.9-14.5c0.1-1.3,0.3-2.7,0.4-4.1c0.2-1.4,0.3-2.8,0.5-4.3c0.4-2.9,0.8-5.8,1.2-8.8c0.9-6,1.7-11.7,2.6-17.1
		c-1.8-0.8-5-2.5-9.3-4.9l-5.2-3.1c-1-0.6-2-1.4-2.9-2.2c-0.5-0.5-1-0.9-1.6-1.4c-0.6,8.1-0.8,15.5-0.9,21c-0.1,6.1-0.1,9.8-0.4,9.8
		s-0.8-3.7-1.1-9.8c-0.3-7.6-0.2-15.2,0.4-22.8c-1.5-1.4-3.1-3-4.7-4.5l-2.9-2.8c-1-1-2-2-3-3c-0.2-0.2-0.4-0.4-0.6-0.6
		c-3.3,8.9-5.9,18-7.9,27.3c-1.6,7.5-2.1,12.3-2.5,12.2c-0.1,0-0.2-1.2,0-3.4c0.1-2.2,0.4-5.3,1-9.1c1.6-9.8,4.2-19.4,7.7-28.7
		c-3.1-3.3-5.9-6.5-8.5-9.7c-1.1-1.2-2.1-2.5-3-3.8c-1,2.6-1.8,5.3-2.5,8.1c-0.7,2.9-1.3,5.6-1.7,7.8c-0.9,4.5-1.3,7.3-1.6,7.3
		s-0.3-2.9,0.2-7.5c0.2-2.3,0.6-5,1.3-8c0.7-3.3,1.6-6.5,2.9-9.7c0,0,0,0,0,0c-1.1-1.6-2.2-3.3-3.2-5c-1.5-2.6-2.6-5.4-3.3-8.3
		c-0.1-0.5-0.2-0.9-0.3-1.3c-0.9,1.9-3,5.7-5,11.5c-1.6,4.7-2.9,9.6-3.9,14.5c-1.2,5.6-2.5,11.7-3.3,18.2c-0.7,4.9-0.8,9.9-0.4,14.9
		c0.1,1.2,0.2,2.3,0.4,3.4c0.2,1.2,0.3,2.3,0.5,3.4c0.3,2.2,0.6,4.3,0.9,6.3c1.1,8,1.9,14,2.4,17s1.9,8.9,3.7,16.7
		c0.9,3.9,2.1,8.2,3.2,12.9c1.2,5,2.1,10.1,2.7,15.3c0.6,5.2,0.7,10.3,0.6,15.5c-0.2,4.9-0.5,9.3-0.8,13.3c-0.3,4-0.6,7.5-0.9,10.4
		c-0.3,2.9-0.7,5.2-1,6.7s-0.5,3.8-1.2,6.7c-0.8,3.4-1.8,6.8-3,10.1c-1.3,3.8-3.1,8-5.1,12.4s-4.2,9-6.5,13.8
		c-5.4,12-10,23-13.2,31.1s-5.2,13-5.6,12.9c-0.5-0.1,0.6-5.4,3.3-13.7c3-9.1,6.4-18,10.2-26.8c-1.4,0.1-3.5,0.2-6.1,0.1
		c-3.1-0.1-6.1-0.4-9.1-1c-1.9-0.3-3.7-0.9-5.5-1.7c-1.6-0.9-3.3-1.7-5-2.5c-5.6-2.7-10.9-4.8-15.3-5.2c-1.1-0.1-2.3-0.1-3.3-0.2
		l-2.8-0.1c-1.8-0.1-3.1-0.1-4-0.1c-1.8,0-5.4,0.4-10.1,0.7c-2.4,0.2-5,0.2-7.9,0.2c-3.1,0-6.2-0.3-9.2-0.8
		c-3.1-0.6-6.1-1.8-8.9-3.4c-2.3-1.3-4.5-2.9-6.6-4.6c-3.7-3.1-6.2-5.6-7.6-6.7c-1.1-0.8-2.1-1.5-3.3-2.2c-1.5-0.9-3.3-1.9-5.3-3
		c-4.8-2.6-10-4.5-15.3-5.7c-1.8-0.4-3.7-0.7-5.5-1c-1.8-0.3-3.6-0.7-5.2-1c-3.3-0.6-6.3-1.3-8.8-2c-4.1-1.1-6.9-2.1-7.6-2.7V722
		h199c-0.2-0.2-0.3-0.5-0.6-0.9C199.7,719.6,199,717.9,198.6,716.2z M52.1,679.5c-1.4,0.1-2.8,0.3-4.1,0.7c-0.7,0.1-1.3,0.3-1.9,0.4
		l-0.9,0.2c-0.3,0.1-0.7,0.1-1,0.1c-0.8-0.1-1.6-0.2-2.4-0.4c-0.4-0.1-1.2,0-2.3-0.2c-0.5,0-1.1-0.2-1.7-0.2s-1.2,0-1.9,0
		c-1.3,0-2.5-0.1-3.7-0.4c-1.1-0.2-1.9-0.4-2.3-0.5c-0.8,0-1.6,0-2.4-0.1c-0.3-0.1-0.6-0.2-0.9-0.3l-0.8-0.4c-0.6-0.3-1.2-0.6-1.7-1
		c-2.9-2-4.9-3.9-4.6-4.4c0.2-0.3,1.1,0,2.5,0.7c0.1-0.8,0.8-2.4,1.9-4.7c0.2-0.4,0.5-0.8,0.7-1.2c0.3-0.4,0.6-0.8,1.1-1
		c0.2-0.1,0.4-0.1,0.5-0.1c1.1-0.1,2.3,0,3.4,0.3l1.6,0.3c0.5,0.1,1.1,0.1,1.6,0.1c1.2,0,2.4,0.1,3.7,0.2c1.3,0.1,2.5,0.3,3.8,0.6
		c1.2,0.3,2.4,0.7,3.5,1.3c1.8,1.1,3.2,2.6,4.3,4.4c1,1.7,1.6,3.2,1.8,4.1c0.7,0,1.5,0.1,2.2,0.2c1.2,0.3,1.8,0.7,1.8,1
		C53.8,679.4,53.1,679.5,52.1,679.5z M96.1,683.9c-1.6-0.4-3-0.8-4.1-1.3c-2.3-0.8-3.7-1.5-3.6-1.8c0.1-0.2,1-0.1,2.5,0.1
		c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.5,0-1,0.2-1.5c0.2-0.3,0.4-0.5,0.6-0.7l0.6-0.5c0.5-0.4,1.1-0.8,1.6-1.1c0.8-0.4,1.6-0.7,2.5-0.7
		c1.6,0,3.2,0.1,5,0.2l2.5,0.2c0.9,0,1.7,0.1,2.5,0.3c0.7,0.1,1.5,0.4,2.2,0.6c0.3,0.1,0.7,0.3,1,0.5c0.4,0.2,0.7,0.5,0.9,0.8
		c0.6,0.9,0.9,1.9,0.7,3c0,0,0,0,0,0.1c2.2,0.1,3.5,0.3,3.5,0.6c0,0.3-1.5,0.6-3.9,0.9l-4.2,0.4l-1.2,0.1c-0.2,0-0.4,0-0.5,0.1
		c-0.2,0-0.4,0.1-0.6,0.2c-0.9,0.2-1.9,0.4-2.8,0.5c-1,0.1-1.9,0.1-2.9,0C97.8,684.3,97,684.2,96.1,683.9z M128.7,711.1
		c-0.5,0.4-1.1,0.7-1.7,0.9c-0.7,0.2-1.5,0.3-2.3,0.3c-1.8,0-3.6-0.4-5.3-1c-0.3,0-0.5,0-0.8,0c-0.3,0-0.8,0-1.2,0
		c-1,0.1-1.9,0.3-2.8,0.6c-0.6,0.2-1.2,0.3-1.8,0.3c-0.3,0-0.7,0-1,0c-0.3-0.1-0.5-0.1-0.8-0.2c-0.9-0.3-1.5-0.7-1.9-0.8
		c-0.3-0.1-1.1-0.1-2-0.2c-1.1-0.1-2.3-0.4-3.3-0.9c-1.1-0.6-2.2-1.4-3.2-2.2c-0.8-0.6-1.2-1-1.2-1.3s0.8-0.3,1.8,0
		c0.3,0.1,0.6,0.2,0.8,0.3c0.6-0.5,1.7-1.3,3.3-2.1l0.5-0.2l0.4-0.2l1-0.6c0.4-0.3,0.9-0.5,1.5-0.6c0.1,0,0.1,0,0.2,0
		c0-0.1,0.1-0.1,0.1-0.2c0.1,0.1,0.3,0.1,0.4,0.2h0l0.6-0.1l2.8-0.5c0.5-0.1,1-0.2,1.6-0.2c0.6-0.1,1.2-0.1,1.8,0
		c2.3,0.2,4.1,0.7,6,0.9c1,0.1,1.9,0.3,2.8,0.7c0.5,0.2,0.9,0.6,1.2,1.1c0.2,0.4,0.4,0.8,0.5,1.3c0.2,1.1,0.3,2.3,0.3,3.4
		c0,0.1,0,0.1,0,0.2c0.3-0.1,0.6-0.2,0.9-0.2c0.4-0.1,0.8-0.2,1.3-0.2c0.4,0,0.5,0.2,0.5,0.3C129.8,710.1,129.5,710.4,128.7,711.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M483.1,268.6c-1.8,1.3-3.3,2.6-4.4,3.6s-1.8,1.6-2,1.4c-0.2-0.1,0-1.1,0.9-2.4c0.8-1.1,1.7-2.1,2.7-3
		c0.1-0.6,0.4-1.8,0.8-3.6c0.3-1.4,0.5-2.8,0.7-4.2c-1.5,2.6-3.3,5.1-5.5,7.2c-1.6,1.6-3,2.6-3.6,2.9c0,0.1,0.1,0.2,0.1,0.3
		c0.6,1.5,1.1,3.1,1.5,4.6c0.3,1,0.6,2.1,0.8,3.4c0.1,1.4,0,2.9-0.3,4.3c-0.1,0.6-0.1,1.3-0.1,1.9c0,0.1,0,0.2,0.1,0.4
		c0,0,0,0.1,0,0.1l0.1,0.2c0.2,0.5,0.3,1,0.3,1.5c0.1,0.9,0,1.8,0.1,2.4c0,0.2,0.1,0.4,0.2,0.6l0.1,0.2c0.1,0.2,0.2,0.4,0.3,0.5
		c0.1,0.4,0.2,0.7,0.2,1.1c-0.1,1-0.3,2-0.6,3c0,0.1-0.1,0.3-0.1,0.5c0,0,0,0.1,0,0.1c-0.1,0.2-0.1,0.4-0.1,0.6
		c0,0.2-0.1,0.4-0.2,0.6c7.7,7.7,13.3,14.4,12.6,15.1c-0.3,0.3-1.6-0.4-3.6-1.9c0,0,0,0,0,0c1,2.1,1.8,4.3,2.5,6.6
		c0.5,1.5,0.9,3.1,1.4,5c0,0.1,0,0.1,0,0.2v-56.8C486.8,266,485,267.2,483.1,268.6z"
          />
          <path
            onClick={() => props.onFill(5)}
            fill={props.fillColors[5]}
            class="st1"
            d="M458.4,407.4c4.1,1.5,8,3.6,11.6,6.1c5.6,3.9,10.4,8.9,14.3,14.5c0.7,1,1.3,2,1.9,3c0.5,0.9,0.9,1.7,1.2,2.4
		c0.5,0.9,0.7,1.6,0.7,1.9v-64.7c-0.5,1.8-7.1,9.5-14.9,19.1C466.5,398,460.9,405,458.4,407.4z"
          />
          <path
            onClick={() => props.onFill(6)}
            fill={props.fillColors[6]}
            class="st1"
            d="M479,338.6c-6.5,5.2-16.3,11-27.9,16c-4.5,1.9-9,3.6-13.7,5c-2.1,0.7-4.3,1.2-6.5,1.6c-2.1,0.4-4,0.7-5.9,1
		c-4.2,0.6-7.9,1.1-10.8,1.4c1.6,0.2,3.5,0.6,5.8,1.2c2.9,0.7,6.4,1.7,10.1,3c4.1,1.4,8.1,3.1,12,4.9c7.1,3.3,13.9,7.4,20.1,12.2
		c1.9,1.4,3.7,3,5.4,4.7c0.2,0.2,0.4,0.4,0.5,0.5c0.7-0.9,1.4-1.7,2.1-2.6c8.6-10.6,16.9-18,17.7-17.2v-41.1
		c-0.2,0.5-0.8,1.5-1.9,2.7C484,334.3,481.6,336.6,479,338.6z"
          />
          <path
            onClick={() => props.onFill(7)}
            fill={props.fillColors[7]}
            class="st1"
            d="M486.3,324.4c-0.5-0.1-1.1-0.3-1.6-0.5c-2.3-0.8-4.5-1.9-6.5-3.3c-0.5-0.4-1.1-0.7-1.6-1.1
		c-0.3-0.2-0.5-0.4-0.8-0.6l-0.4-0.3l-0.2-0.2l-0.1-0.1c0,0,0,0,0,0.1c0.2,0.3,0.3,0.7,0.4,1c0,0.1,0.1,0.3,0.1,0.4l0.1,0.2v0.1v0.2
		c0.2,0.6,0.3,1.2,0.4,1.8c0.2,1.2,0.4,2.5,0.5,3.8c0.1,1.3,0.2,2.6,0.3,4l0.1,2c0,0.5,0,0.9,0,1.4v1.6l-1.6-0.7
		c-0.3-0.1-0.5-0.2-0.8-0.4l-0.5-0.3c-0.7-0.3-1.3-0.7-2-1.1c-1.3-0.8-2.5-1.7-3.8-2.6c-0.6-0.5-1.2-0.9-1.8-1.4l-1.5-1.2l-0.5-0.5
		c0,0-0.1-0.1-0.1,0l0.1,0.2c0,0.1,0.1,0.2,0.1,0.3l0.1,0.3v0.1l0.1,0.5c0.2,0.7,0.3,1.4,0.4,2.1c0.2,1.4,0.3,2.8,0.4,4.3
		c0.1,1.5,0.1,2.9,0,4.4c0,0.7-0.1,1.5-0.2,2.2l-0.1,0.9l-0.1,1c0.1,1-0.8,0.3-1.3,0.2l-0.9-0.3l-0.6-0.3c-0.3-0.2-0.7-0.3-1-0.5
		c-0.7-0.4-1.4-0.8-2-1.2c-1.3-0.8-2.6-1.7-3.8-2.7c-1.2-1-2.4-2-3.6-3.1l-0.5-0.4l-0.1-0.1c0,0,0,0,0,0.1v0.2v0.3v0.1v0.1v0.5
		c0.1,0.7,0.1,1.4,0.1,2.2c0,1.5,0,2.9-0.2,4.4c-0.1,1.5-0.3,2.9-0.6,4.4c-0.1,0.7-0.3,1.5-0.5,2.2l-0.2,0.7
		c-0.1,0.3-0.2,0.6-0.4,0.9c-0.4,0.5-1.1,0.7-1.6,0.4l-1.4-0.6c-0.4-0.2-0.7-0.4-1.1-0.6c-0.7-0.4-1.4-0.8-2.1-1.3
		c-1.4-0.9-2.7-1.9-3.9-3c-0.6-0.6-1.2-1.1-1.8-1.8c-0.3-0.3-0.6-0.6-0.8-0.9l-0.8-0.9l-0.2-0.3c-0.1-0.1,0,0.1,0,0.1s0-0.2,0,0.9
		c0,0.8-0.1,1.5-0.2,2.3c-0.2,1.5-0.4,3-0.8,4.5c-0.4,1.5-0.9,3-1.5,4.4c-0.3,0.7-0.7,1.5-1.1,2.2c-0.2,0.3-0.4,0.6-0.6,0.9
		l-0.3,0.4l-0.1,0.1h-0.1l-0.2-0.1c-0.3-0.1-0.6-0.3-1-0.5l-0.7-0.3c-2.8-1.6-5.2-3.8-7.1-6.4c-0.5-0.6-0.9-1.3-1.4-2
		c-0.2-0.3-0.4-0.7-0.6-1l-0.4-0.7v0v0c-0.1,0.4-0.4,1.9-0.6,2.9c-0.4,1.4-0.8,2.8-1.4,4.2c-0.5,1.4-1.2,2.7-1.8,4.1
		c-0.3,0.7-0.7,1.3-1.1,2l-0.4,0.6l-0.3,0.5l-0.3,0.3c-0.2,0.2-0.5,0.4-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.3c-0.8-0.7-1-0.8-1.4-1.2l-1-1
		l-1-1.1l-0.9-1.1c-1.1-1.5-2.1-3.2-2.9-4.9c-0.4-0.9-0.7-1.8-1-2.7c-0.2-0.5-0.3-0.9-0.4-1.4l0,0.1l-0.1,0.2v0.1l-0.1,0.3
		c-1.6,3.5-3.7,6.7-6.1,9.7c-0.6,0.7-1.2,1.5-1.9,2.2l-1,1l-0.2,0.2l-0.3,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.4,0.2-0.8,0.2-1.2,0
		c-0.4-0.2-0.7-0.5-1-0.9l-0.5-0.6c-0.3-0.4-0.6-0.8-0.9-1.2c-0.3-0.4-0.5-0.8-0.8-1.3c-0.3-0.4-0.5-0.9-0.7-1.3
		c-1.2-2.4-2-4.9-2.4-7.6c-0.1-0.6-0.2-1.3-0.2-2V347l-0.3,0.5c-0.1,0.2-0.3,0.4-0.4,0.6c-0.6,0.8-1.1,1.5-1.8,2.2
		c-1.2,1.4-2.5,2.8-3.9,4c-0.3,0.3-0.6,0.5-0.9,0.8c0.6,0.5,1.1,1,1.6,1.5c1.5,1.8,2.3,4,2.4,6.3c3.7,0.3,7.2,0.6,10.3,0.8
		c3.2,0.2,6.4,0.3,9.7,0.2c2.7-0.2,4.8-0.4,6.2-0.6c2.8-0.4,8.4-1.6,15.7-3.3c1.8-0.4,3.7-0.9,5.7-1.4c2.1-0.5,4.1-1.1,6.1-1.9
		c4.2-1.5,8.6-3.3,13.2-5.3c9.7-4.1,19-9,27.8-14.7c3.3-2.3,5.9-4.2,7.6-5.7c1.8-1.4,2.7-2.3,2.9-2.1v-4.1l-0.7-0.2
		C486.9,324.5,486.5,324.4,486.3,324.4z"
          />
          <path
            onClick={() => props.onFill(8)}
            fill={props.fillColors[8]}
            class="st1"
            d="M10.1,638.5l8.9,1.3l5.2,0.8c1.7,0.2,3.7,0.5,5.7,1c3,0.7,6,1.7,8.8,3.1c2.4,1.2,4.7,2.5,6.9,4.1
		c2,1.4,3.6,2.7,4.9,3.8l3.1,2.5c1.4,1.1,4.4,3.3,8.3,5.7s9,5.3,14.8,6.4c3,0.6,6,0.9,8.7,1.2s5.4,0.5,7.7,0.7
		c4.6,0.4,8.2,0.5,10,0.5c0.9,0,2.2-0.1,3.9-0.3l2.8-0.3c1-0.1,2.1-0.3,3.4-0.3c5-0.2,11,1.5,16.8,4.3c1.8,0.8,3.5,1.8,5.1,2.7
		c1.5,0.7,3,1.3,4.6,1.6c3.2,0.8,6.1,1.3,8.6,1.7c2.9,0.4,5.1,0.8,6.5,1.1c0.5-1.2,1.1-2.4,1.6-3.6c2.3-4.9,4.7-9.5,6.9-13.8
		c2.2-4.3,4.2-8.2,5.8-11.8c1.1-2.4,2.1-4.9,3-7.5c-0.3,0.1-0.8,0.2-1.3,0.4c-0.9,0.2-1.9,0.3-2.8,0.4c-0.6,0-1.3,0-1.9-0.1
		c-0.5-0.1-0.7-0.2-1-0.3l-0.9-0.3c-1.3-0.4-2.7-0.7-4.1-1.1c-0.3-0.1-0.7-0.2-1-0.2H159h-0.1l-0.3,0.1l-2.5,0.7
		c-1.8,0.5-3.7,0.9-5.6,1.2c-1.1,0.2-2.2,0.2-3.3,0.2c-1.1-0.1-2.2-0.3-3.2-0.5c-1.7-0.4-3.3-0.8-4.8-1.2c-0.8-0.2-1.5-0.4-2.2-0.6
		c-0.3-0.1-0.7-0.2-1-0.2c-0.3,0-0.6,0-0.9,0c-2.9,0.1-5.7,0.1-8.2,0c-2.2-0.1-4.4-0.3-6.5-0.7c-1.4-0.3-2.7-0.8-4-1.4
		c-1.2-0.5-2.5-1-3.8-1.4c-0.4-0.1-0.8-0.1-1.2-0.1c-0.2,0-0.4,0-0.6,0.1l-0.8,0.1c-1.1,0.2-2.3,0.3-3.5,0.4
		c-2.8,0.3-5.6,0.3-8.4,0.2c-1.6-0.2-3.2-0.6-4.8-1.1c-1.6-0.5-3.1-1-4.6-1.5c-0.3-0.1-0.7-0.2-1-0.3c-0.3,0-0.7-0.1-1.1-0.1
		l-2.4-0.3c-1.7-0.1-3.3-0.5-4.9-1.1c-0.8-0.4-1.5-0.8-2.2-1.4c-0.6-0.5-1.1-1-1.6-1.5c-0.6-0.6-1.1-1.2-1.7-1.8
		c-0.2,0.1-0.4,0.1-0.6,0.2c-1.6,0.5-3.2,0.8-4.8,1c-2.1,0.1-4.4,0.2-7.1,0.2l-4.2,0.1c-1.4,0-3,0-4.4,0.1c-0.7,0-1.4,0.1-2.1,0.3
		c-0.3,0.1-0.7,0.1-1,0.2h-0.1l0.2,0.1l0.4,0.1c0.3,0.1,0.6,0.2,0.9,0.3c2.5,0.6,4.9,0.9,6.9,1.2c3.9,0.7,6.4,1,6.4,1.3
		c0,0.3-2.5,0.4-6.6,0.2c-2.4-0.1-4.8-0.4-7.2-0.8c-0.3-0.1-0.7-0.2-1-0.3s-0.3-0.1-0.6-0.2c-0.3-0.1-0.5-0.2-0.7-0.3
		c-0.6-0.2-1-0.6-1.3-1.2c-0.2-0.5-0.1-1.1,0.3-1.5c0.5-0.5,1.1-0.8,1.7-1c0.4-0.1,0.8-0.2,1.2-0.3c0.8-0.2,1.6-0.3,2.4-0.3
		c1.7-0.1,3.1,0,4.6,0l4.2,0.1c2.7,0,5.1,0.1,7.1,0.2c2,0.1,3.6-0.2,4.7-0.3c0.1,0,0.1,0,0.2,0c-0.1-0.2-0.3-0.3-0.4-0.4l-0.2-0.2
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0-0.1v-0.1c0,0.1,0.1-0.3-0.1,0.2l-0.1-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
		c-0.3-0.2-0.5-0.4-0.7-0.6c-0.4-0.4-0.9-0.8-1.3-1.2c-0.8-0.8-1.5-1.5-2.1-2.1c-1.3-1.3-2.3-2.3-2.9-2.9c-0.3-0.3-0.8-0.7-1.3-1.2
		c-0.6-0.6-1.1-1.2-1.6-1.9l-1.8-2.3c-0.3-0.4-0.6-0.8-1-1.1c-0.4-0.3-0.8-0.5-1.3-0.7c-2.5-1.2-5-2.5-7.3-4
		c-0.6-0.4-1.3-0.9-1.9-1.4c-0.6-0.5-1.2-1-1.8-1.5c-1.4-1.1-2.6-2.4-3.6-3.8c-0.7-1-1.3-2.1-1.9-3.2c-0.3-0.6-0.5-1.1-0.7-1.7
		c-0.1-0.3-0.2-0.5-0.3-0.8c-0.1,0,0,0-0.1,0l-0.8-0.4c-0.5-0.3-1-0.5-1.4-0.8c-0.9-0.6-1.7-1.1-2.6-1.8c-1.5-1.2-2.9-2.5-4.1-4
		c-1-1.3-1.8-2.7-2.5-4.1c-0.2-0.7-0.5-1.3-0.7-1.9l-0.2-0.9c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2l-0.1-0.2l-0.2-0.3l-0.4-0.5
		c-0.1-0.1-0.1-0.3-0.2-0.4c-7.3,14.8-13.6,28.2-18.3,37.9c-2.4,5-4.5,9.1-5.8,11.9c-0.5,1.1-1,2-1.4,2.7v3.2
		C2.8,637.6,5.7,637.9,10.1,638.5z M36,629.6l0.2-0.6c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.4,0.5-0.7,0.8-1c0.3-0.3,0.6-0.5,1-0.7
		c0.4-0.2,0.7-0.3,1.1-0.4c1.6-0.3,3.3-0.5,5-0.5c1.6,0,3.1,0,4.5,0.1c2.8,0.1,5.4,0.4,7.5,0.6c4.3,0.5,6.9,0.8,6.9,1.1
		s-2.7,0.5-7,0.4l-7.5-0.2c-1.4,0-2.9-0.1-4.4,0c-1.5,0-3,0.2-4.5,0.5c-0.9,0.1-1.7,0.8-1.9,1.7c-0.1,1,0.2,1.9,0.9,2.6
		c0.2,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.4,0.4,0.6,0.5c0.4,0.3,0.8,0.6,1.3,0.9l1.3,0.8l1.3,0.6c2.6,1.1,5.3,1.9,8,2.3
		c0.7,0.1,1.9,0.3,3.3,0.5l2.3,0.5c0.9,0.2,1.7,0.5,2.7,0.9c2.1,0.9,4,2,5.8,3.4c1.9,1.6,3.9,3,6,4.4c2.1,1.3,4.3,2.2,6.7,2.8
		c2.1,0.5,4.2,0.9,6.3,1.2c1,0.2,1.9,0.2,2.7,0.3s1.6,0.2,2.3,0.2c1.4,0,2.5,0.1,3.3,0s1.9-0.1,3.3-0.4c1.4-0.2,3.1-0.5,5-0.9
		c3.8-0.8,8.6-2.1,13.9-2.7c5.6-0.6,11.3-0.4,16.9,0.5c1.7,0.2,3.4,0.6,5,1.1c1.2,0.3,1.8,0.6,1.8,0.7c0,0.1-0.7,0.2-1.9,0.2
		s-2.9-0.3-5-0.4c-5.5-0.5-11-0.5-16.5,0.1c-5.2,0.6-9.9,1.5-13.9,1.9c-2,0.2-3.7,0.4-5.1,0.4s-2.6,0.1-3.3,0.1s-1.9,0.1-3.3,0.2
		c-0.7,0.1-1.5,0.1-2.4,0.1c-0.9,0-1.8,0.1-2.8,0c-2.2,0-4.4-0.3-6.6-0.7c-2.6-0.5-5.1-1.4-7.3-2.8c-2.1-1.4-4.2-3-6.1-4.7
		c-1.6-1.4-3.4-2.6-5.2-3.6c-0.9-0.4-1.7-0.8-2.5-1.1l-2.2-0.7c-1.4-0.3-2.4-0.6-3.2-0.7c-2.8-0.3-5.6-0.9-8.4-1.7l-1.5-0.5
		c-0.5-0.2-1-0.5-1.5-0.7c-0.5-0.3-1-0.6-1.5-0.9c-0.3-0.2-0.5-0.4-0.7-0.6c-0.3-0.2-0.5-0.4-0.7-0.7c-0.2-0.3-0.5-0.5-0.7-0.8
		l-0.5-1c-0.1-0.4-0.2-0.7-0.3-1.1C36,630.4,36,630,36,629.6z"
          />
          <path
            onClick={() => props.onFill(9)}
            fill={props.fillColors[9]}
            class="st1"
            d="M486,434.2c-0.5-0.6-1.1-1.2-1.7-2s-1.4-1.6-2.3-2.6c-4.2-5-9-9.4-14.3-13.2c-4.5-3.2-9.2-5.9-14.3-8.2
		c-3-1.4-6.2-2.4-9.4-3.1c-1.3-0.2-2.6-0.3-3.9-0.3h-1.3c-0.5,0-0.9,0.1-1.4,0.1c-1,0.1-2,0.4-3.2,0.6c-4.5,1.1-10,2.9-15.9,4.9
		l-4.3,1.5c-1.3,0.5-2.7,1-4,1.5l-7.3,2.8c-4.4,1.7-7.8,2.9-9.4,3.7c-1.6,0.8-4.8,2.5-8.9,4.8c-2.1,1.1-4.4,2.4-7,3.6
		c-2.6,1.3-5.3,2.6-8.3,3.8c-3.5,1.3-7.1,2.3-10.8,3.1c-3.4,0.7-6.4,1.1-9,1.4c-5.1,0.5-8.4,0.4-8.4-0.1s3-1.3,8-2.4
		c2.5-0.6,5.4-1.3,8.6-2.1c3.5-0.9,6.9-2,10.3-3.2c2.9-1.1,5.7-2.3,8.3-3.3c2.6-1,5.1-2,7.3-2.8c4.4-1.7,7.8-2.9,9.4-3.7
		c1.4-0.6,3.7-2,6.9-3.7c-2.6,0.2-5.3,0-7.8-0.5l-0.8-0.1c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.1-0.6-0.2-1-0.3l-0.8-0.4
		c-1.1-0.6-2.1-1.3-3-2.1c-1.9-1.6-3.8-3.3-5.8-5.2c-1-0.9-2-1.8-3-2.7c-1-0.9-2-1.6-3.2-2.2c-0.3-0.1-0.4-0.2-0.7-0.3
		c-0.3-0.1-0.6-0.2-0.9-0.2c-0.7-0.1-1.4-0.2-2-0.3c-1.5-0.1-2.9-0.1-4.4,0c-5.6,0.4-11.2,1.7-16.4,4c-1.2,0.5-2.3,1.1-3.5,1.6
		s-2.3,1.1-3.4,1.6c-2.2,1-4.3,1.9-6.3,2.6c-7.8,2.9-13,3.4-13,2.9c-0.1-0.4,4.6-1.8,11.7-5c-2.9-0.4-7.4-1.2-12.5-1.9
		c-4.3-0.6-8.2-0.9-11.1-1.2c-0.7,0-1.4-0.1-1.9-0.2s-1.1-0.1-1.5-0.2c-0.8-0.1-1.3-0.3-1.3-0.6c0-0.3,0.4-0.5,1.2-0.8
		c0.4-0.2,0.9-0.3,1.5-0.5c0.6-0.2,1.3-0.2,2-0.4c3.9-0.5,7.8-0.4,11.7,0.1c3.1,0.4,5.9,1.1,8.3,1.8c0.2-0.9,0.5-2.2,1.1-3.8
		c-0.7-1-1.6-2.7-2.8-4.9c-1-1.8-2.2-4-3.6-6.3c-0.7-1.2-1.5-2.3-2.4-3.3c-1-1.1-2-2.2-3.1-3.2c-3.9-3.7-8.1-6.9-12.7-9.7
		c-1.9-1.2-3.5-2.1-4.5-2.7c-0.6,0.1-1.2,0.3-1.8,0.4c-5.2,1.3-9,2.6-11.1,3.1c0.4,0.2,0.7,0.4,1.1,0.7c1,0.6,1.9,1.3,2.7,2
		c1.7,1.3,3.2,2.7,4.8,4.2c1.4,1.4,2.7,2.8,3.8,4.3c1.1,1.4,2,2.9,2.6,4.5c0.9,2.3,1.4,4.8,1.3,7.3c0,0.4,0,0.8-0.1,1.1
		c0.3-0.1,0.5-0.1,0.8-0.2c0.7-0.2,1.5-0.3,2.3-0.4c0.7,0,1.3,0,1.8,0c1,0,1.4,0.1,1.5,0.4c0,0.2-0.4,0.6-1.3,1
		c-0.4,0.2-0.9,0.4-1.5,0.6c-0.6,0.2-1.3,0.6-2,0.8c-3.8,1.6-7.3,3.7-10.5,6.3c-3.2,2.6-6.1,5.6-8.7,8.8c-0.5,0.6-1,1.2-1.4,1.7
		l1.4-0.3c1.8-0.4,2.8-0.5,2.9-0.3s-0.8,0.8-2.4,1.7l-1.3,0.7c-0.5,0.3-1,0.6-1.5,0.9l-1.8,1c-0.6,0.4-1.2,0.8-1.9,1.2
		c-3.1,1.9-5.8,4.3-8,7.1c-1.3,1.6-2.4,3.4-3.3,5.3c-0.6,1.3-1,2.7-1.3,4.1c4.4-0.6,8.9-0.9,13.3-0.9c6,0,12.1,0.7,18,1.8
		c4.9,0.9,8.6,1.7,10.5,2c1.9,0.3,5.6,0.5,10.6,0.8l2.7,0.2c0.2-0.6,0.6-1.2,1.1-1.6c0.6-0.5,1.4-0.8,2.2-0.8c0.4,0,0.6,0.2,0.8,0.3
		l0,0c1-0.2,1.7,0,1.8,0.1c0,0,0,0,0,0c0-0.1,0.5-0.2,1.5-0.2c0.4,0,0.9,0.1,1.3,0.3c0.2-0.3,0.4-0.5,0.6-0.7
		c0.6-0.6,1.4-0.8,2.2-0.8c0.4,0,0.6,0.1,0.8,0.3l0.1,0c1.1-0.2,1.7,0,1.8,0.1c0,0,0,0,0,0c0-0.1,0.5-0.2,1.6-0.2
		c1,0.1,2,0.4,2.9,0.9c0.2,0,0.3-0.1,0.5-0.1c0.3,0,0.6,0,0.9,0.2c0.1,0,0.1,0.1,0.1,0.1c0.8-0.1,1.3-0.1,1.6,0c0.1,0,0.1,0,0.2,0
		c0-0.1,0.5-0.2,1.5,0c1.3,0.1,2.5,0.6,3.6,1.3c0.2,0.2,0.5,0.3,0.7,0.5c0.1,0,0.1,0,0.2,0c1.3-0.2,2.6-0.5,3.8-0.8
		c3.4-0.8,6.8-1.8,10.1-3c1.8-0.7,5.1-2.5,9.4-4.9c1.1-0.6,2.3-1.2,3.5-1.9c0.6-0.3,1.3-0.6,2-0.9l1.1-0.4c0.4-0.1,0.9-0.3,1.3-0.3
		c1.6-0.2,3.3-0.3,4.9-0.3h4.9c3.8,0,7.6-0.1,11.4-0.5c1.7-0.2,3.4-0.5,4.9-0.7l4.3-0.7c4-0.7,6.9-1,8.1-1c-0.1-0.3-0.1-0.6,0-0.9
		c0.4-0.4,0.8-0.7,1.4-0.9l1.1-0.5c1.4-0.6,2.9-1.1,4.5-1.6l1.2-0.4l0.6-0.2l0.5-0.1c0.2-0.1,0.4-0.1,0.5-0.1c0.3-0.1,0.6,0,0.9,0.1
		c0.3,0.2,0.5,0.4,0.6,0.7c0.2,0.6,0.2,1.4,0,2c-0.2,0.4-0.4,0.8-0.6,1.2c0,0.1,0,0.2,0,0.4c0,1.5-0.3,2.9-0.9,4.3
		c-0.9,1.9-2.1,3.7-3.5,5.3c-0.8,1-1.7,1.9-2.7,2.7c-0.6,0.5-1.2,0.9-1.9,1.3c-0.2,0.1-0.5,0.2-0.7,0.3c-0.3,0.1-0.6,0.1-0.9,0.1
		c-0.5,0-0.7,0-1.1,0c-4.2-0.2-8.4,0.3-12.5,1.5c-1.6,0.5-2.8,1-3.7,1.4c1.8,1.3,4.2,3,7.2,4.8c8.5,5.3,17.7,9.4,27.3,12.1
		c-0.7-2.1-1.3-4.2-1.7-6.3c-0.3-1.8-0.3-2.8,0-2.8c0.3-0.1,0.7,0.8,1.4,2.4c0.7,1.6,1.6,3.9,3,6.7c0.7,1.5,1.6,2.8,2.5,4.1
		c1.1,1.5,2.3,3,3.5,4.6c1.3,1.6,2.6,3.3,4.1,4.9c0.7,0.8,1.5,1.6,2.3,2.5c0.4,0.4,0.8,0.8,1.2,1.2l1.3,1.2c3.6,3.4,6.4,7.1,9.2,9.8
		l0.5,0.5c0.2,0.2,0.4,0.4,0.6,0.6c0.4,0.4,0.7,0.8,1.1,1.2c0.7,0.8,1.2,1.6,1.8,2.4c1,1.5,1.9,3,2.7,4.5c2.9,5.7,3.6,9.6,3.2,9.8
		c-0.5,0.2-2-3.4-5.4-8.5c-0.8-1.3-1.8-2.7-2.9-4c-0.4-0.6-0.9-1.1-1.4-1.7c0.3,1.2,0.5,2.7,0.7,4.6c0.2,2.2,0.4,4.7,0.5,7.6
		c0.1,3.1,0,6.2-0.2,9.4c-0.5,4.8-1.6,9.5-3.1,14c-1.2,3.8-2.4,6.6-2.8,8.1s-0.9,4.4-2,8.3c-0.6,2.2-1.3,4.3-2.2,6.3
		c-0.9,2.2-2.1,4.5-3.3,6.9c-1.6,3-3.4,5.9-4.8,8c-0.4,0.5-0.7,1.1-1,1.5l-0.5,0.6l-0.1,0.2l0,0l-0.1,0.1l-0.8,0.8
		c0.8,0.3,2.9,1,4.1,1.4s1.8,0.7,2.3,0.8c1.4,0.4,4.4,1.1,8.2,2.4c2.2,0.7,4.2,1.8,6.2,3c1.2,0.8,2.2,1.8,3.1,3
		c0.5,0.7,0.8,1.5,1,2.3c0.2,1,0.2,2-0.1,2.9c-0.3,0.8-0.8,1.6-1.5,2.1c-0.6,0.4-1.2,0.8-2,0.9c-1.1,0.3-2.3,0.4-3.5,0.4
		c-1,0-2-0.1-3-0.3l-0.7-0.1l-0.6-0.1c0.2,1.8,0.4,3.6,0.6,5.5c0.3-0.3,0.6-0.6,1-0.8c1.5-0.9,3.2-1.5,5-1.8c2-0.4,4.1-0.5,6.1-0.4
		c3.7,0.1,7.4,1,10.8,2.6c1,0.5,2,1.2,2.8,2.1c0.5,0.7,0.7,1.1,0.5,1.3c-0.2,0.2-0.6,0.1-1.3-0.1s-1.7-0.5-2.9-0.9
		c-3.3-0.8-6.6-1.2-10-1.4c-4-0.1-7.6,0.1-9.8,0.9c-0.7,0.2-1.3,0.5-1.8,1c0,0.1,0,0.2,0,0.3c0.1,0.7,0.3,1.3,0.5,2
		c0.2,0.7,0.5,1.4,0.8,2c0.5,1.2,1.1,2.4,1.8,3.6c1.2,1.9,2.7,3.5,4.6,4.7c1.3,0.8,2.8,1.4,4.2,1.9c1,0.4,1.7,0.7,1.7,0.9
		c0,0.3-0.7,0.5-1.9,0.6c-0.3,0-0.6,0-0.9,0c-1.1,0.3-2.9,0.5-5.1,0.6c-2.3,0.1-4.7,0.1-7,0c-2.9-0.3-5.8-0.9-8.6-1.8
		c-0.8-0.3-1.6-0.6-2.4-0.9c0,0,0,0,0,0.1c1,1.2,2.2,2.7,3.5,4.5c1.6,2,2.9,4.2,4.1,6.5c2.5,5,5.5,11,8.6,17.7
		c1.6,3.3,3.2,6.9,4.8,10.6c0.8,1.9,1.6,3.8,2.3,5.8s1.3,4,1.9,6.1c1.2,4.1,2.3,8.2,3.2,12.1c0.9,3.8,1.6,7.7,2,11.7
		c0.2,1.9,0.2,3.8,0,5.6c-0.1,1.8-0.4,3.5-0.6,5.2c-0.5,3-1.2,6-2,9c-3,11.1-6.8,17.1-7.2,16.9H488V435.5c0,0.1,0,0.1-0.1,0.2
		C487.7,435.8,487.1,435.2,486,434.2z M478.6,536c-0.2,1.3-0.3,3.3-0.5,5.7c-0.3,4.8-0.4,11.5-0.6,19c-0.1,3.7-0.2,7.3-0.3,10.5
		c-0.1,2.9-0.3,5.8-0.8,8.7c0,0.3-0.1,0.7-0.2,1c-0.1,0.3-0.2,0.6-0.3,0.9c-0.2,0.5-0.4,1-0.7,1.5c-0.4,0.8-0.9,1.5-1.5,2.2
		c-1,1.1-1.8,1.5-1.9,1.3s0.2-0.8,0.8-2c0.3-0.7,0.6-1.5,0.8-2.2c0.1-0.4,0.3-0.9,0.4-1.4c0.1-0.2,0.1-0.5,0.1-0.7
		c0-0.3,0.1-0.5,0.1-0.8c0.1-2.3,0-5.3,0-8.5s-0.1-6.8,0-10.6c0-6.5,0.6-12.9,1.7-19.3c0.4-2,0.9-3.9,1.6-5.7c0.6-1.3,1-2,1.2-1.9
		S478.8,534.6,478.6,536z"
          />
          <path
            onClick={() => props.onFill(10)}
            fill={props.fillColors[10]}
            class="st1"
            d="M220.3,720.3c3.4-7.2,6.4-14.6,9-22.1c2.6-7.3,4.8-14.4,6.7-21.3c3.3-12.2,6.1-24.5,8.1-36.9
		c1.9-10.7,3.3-19.3,4.4-25.2c0.1-0.7,0.3-1.4,0.4-2.1c0-0.1,0-0.2,0-0.3c0-10.7,0.6-21.4,1.8-32l0.1-1c0.1-0.4,0.1-0.8,0.2-1.2
		c0.2-0.8,0.4-1.5,0.6-2.2c0.5-1.4,1-2.7,1.5-4c1-2.6,2.2-5.1,3.3-7.5c2.2-4.8,4.5-9.5,6.5-13.9c4-8.9,7.3-17,10.2-23.9l2-4.9
		c0.7-1.5,1.5-2.9,2.3-4.3c1.6-2.5,3.1-4.6,4.3-6.3c1.1-1.5,2-2.6,2.6-3.4c0-0.6-0.1-1.2-0.1-1.8c-0.1-1.6-0.1-3.2-0.2-4.7
		c0-0.7,0-1.5-0.1-2.1c-0.1-1.7,0.6-3.3,1.9-4.3c0.5-0.4,1-0.7,1.5-1l0.7-0.3l0.4-0.2c0.1-0.1,0.3-0.1,0.4-0.1
		c0.5-0.1,1.1-0.1,1.5,0.1c0.5,0.2,0.9,0.4,1.2,0.8c0.2-0.4,0.3-0.7,0.5-1c-0.2-0.4-0.3-0.7-0.5-1.1c-0.8-2-1.2-4.2-1-6.3
		c0-0.4,0.1-0.7,0.1-1c-0.7,0.1-1.5,0.2-2.2,0.3c-5.9,0.8-13.3,1.8-21.4,1c-7.2-0.8-14.1-2.7-20.7-5.7c-3.9-1.7-7.1-3.4-9.2-4.6
		c0.1,2-0.3,7.3-1.2,14.8c-1.1,9.7-2.9,23.1-5,37.8c-0.9,6-1.7,11.8-2.5,17.1c-0.9,5-2.2,9.9-3.9,14.7c-2.9,8.6-5.7,14.9-6.9,18.1
		c-2.5,6.4-8.6,26-17.2,48.6c-1,2.8-2.2,5.6-3.4,8.3c-1.2,2.7-2.5,5.2-3.8,7.6c-2.1,3.9-4.2,7.5-6.2,10.8c-0.4,2.6-1.3,7-2.1,12.8
		c-0.8,5.5-1.2,11-1.4,16.6c1.8,0.3,3.5,0.8,5.1,1.5c2.6,1.1,4.8,2.9,6.5,5.1c1,1.4,1.7,2.9,2.2,4.5c0.5,1.6,0.9,3.2,1.3,4.9
		c0.7,3.3,1,6.5,1.4,9.3s0.6,5.4,0.9,7.5c0.3,2.1,0.6,3.8,0.8,5c0.1,0.6,0.1,1,0.2,1.3h20.3C221.2,721.6,220.1,720.7,220.3,720.3z"
          />
          <path
            onClick={() => props.onFill(11)}
            fill={props.fillColors[12]}
            class="st1"
            d="M14.3,563c1.5-2.7,2.9-5.3,4.3-7.7c1.4-2.4,2.7-4.6,3.7-6.6c2.2-4,3.8-7.1,4.5-8.7c0.7-1.6,2-4.9,3.6-9.1
		l1.3-3.4c0.5-1.2,0.8-2.5,1.1-3.7c0.1-0.3,0.1-0.6,0.2-0.9c-0.2,0.3-0.4,0.7-0.7,1.1c-1.5,2.5-3.7,6.1-6.1,9.9c-0.7,1-1.3,2-1.9,3
		l1.6-2.1c0-0.1,0.1-0.2,0.1-0.2l0,0l0.1-0.1l1.6,1.1l-2.3,3.9c-0.8,1.3-1.7,2.7-2.6,4c-4.1,6.3-8.7,12.2-13.7,17.7
		c-3.9,4.2-6.6,6.5-6.8,6.3s2.1-2.9,5.7-7.3c2.8-3.5,6.4-8.1,10.1-13.5c-0.3,0.3-0.6,0.7-0.9,1c-2.7,2.9-4.4,4.6-4.6,4.4
		c-0.2-0.2,1.3-2,3.7-5.2l0.5-0.6c0.7-2.1,3.7-7.5,7.6-13.7c2.1-3.3,4.3-6.5,6.7-9.6c0.9-1.2,1.7-2.1,2.3-2.7
		c0.3-1.5,0.5-3.1,0.7-4.7c-0.7,1.3-1.7,2.9-3,4.9c-1.4,2.3-3.1,4.9-5.1,7.9s-3.9,6.2-6,9.7c-3.5,6-7.5,11.8-11.8,17.3
		c-1.3,1.7-2.8,3.2-4.3,4.7c-1.2,1-1.9,1.5-2,1.4v24.1c0.7-1.2,1.8-3.1,3.1-5.6C7.5,575.8,10.7,569.8,14.3,563z"
          />
          <path
            onClick={() => props.onFill(13)}
            fill={props.fillColors[13]}
            class="st1"
            d="M3.8,151.7c0.4,0.8,0.8,1.7,1.2,2.7c1.3,3.5,2.2,7.2,2.7,10.9c0.6-4.2,1-8.4,1.2-12.6
		c0.3-6.2,0.2-12.5-0.1-18.7c-0.1,0-0.3,0-0.4,0c-2.9,0-5.4-0.3-6.4-0.6v15.5c0.2,0.2,0.4,0.5,0.7,0.9C3,150.2,3.4,150.9,3.8,151.7z
		"
          />
          <path
            onClick={() => props.onFill(14)}
            fill={props.fillColors[14]}
            class="st1"
            d="M353.7,273.4c1.3,0,2.7-0.2,4-0.4c1.4-0.3,2.7-1,3.8-1.9c0.6-0.5,1.3-1.1,1.9-1.7c0.7-0.6,1.4-1.2,2.1-1.7
		l2.2-1.7c0.1-0.1,0.2-0.1,0.2-0.2c-0.2,0.1-0.4,0.1-0.6,0.2c-1.6,0.5-3.2,1-4.6,1.5c-1.5,0.5-2.9,0.9-4.2,1.2
		c-1.2,0.3-2.5,0.6-3.8,0.7c-3.8,0.5-6.4,0.3-7.2,0.1c0.3,0.2,0.7,0.7,1.4,1.3C349.9,271.8,351.4,273.3,353.7,273.4z"
          />
          <path
            onClick={() => props.onFill(15)}
            fill={props.fillColors[15]}
            class="st1"
            d="M398.8,261.8L398.8,261.8C398.9,261.7,399,261.7,398.8,261.8z"
          />
          <path
            onClick={() => props.onFill(16)}
            fill={props.fillColors[16]}
            class="st1"
            d="M347.3,269.3C347.3,269.3,347.3,269.3,347.3,269.3c0.4-0.2,3.1-0.4,7.3-1.2c1.2-0.2,2.4-0.5,3.6-0.9l3.9-1.3
		c-0.8,0.1-1.5,0.2-2.3,0.2c-0.6,0-1.3,0-2,0h-1c-0.3,0-0.5,0-0.8,0c-0.6,0.1-1.3,0.3-1.8,0.6c-0.3,0.2-0.7,0.4-1.1,0.6
		c-0.3,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0.1-1.6,0.1-2.4,0c-0.7,0-1.3-0.1-1.9-0.2c-0.4,0-0.8,0-1.2,0.1l-1.1,0.2
		c0,0-0.1,0-0.1,0c0.6,0.3,1.5,0.9,2.5,1.8c0.2,0.2,0.4,0.4,0.6,0.6C347.4,269.8,347.2,269.5,347.3,269.3
		C347.2,269.4,347.2,269.4,347.3,269.3C347.2,269.4,347.2,269.4,347.3,269.3C347.2,269.3,347.2,269.3,347.3,269.3z"
          />
          <path
            onClick={() => props.onFill(17)}
            fill={props.fillColors[17]}
            class="st1"
            d="M353.8,277.1l0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.2c0.3,0.1,0.5,0.1,0.8,0.1c1.1,0.1,2.2,0.2,3.3,0.2
		c1.5,0,3-0.1,4.5-0.4c1.4-0.5,2.6-1.3,3.6-2.3c1.5-1.5,2.5-3.4,2.7-5.5c0.1-0.9,0.3-1.8,0.4-2.6c0.1-0.2,0.1-0.5,0.2-0.7
		c-0.4,0.3-0.7,0.6-1.1,0.9l-2.3,1.7c-0.7,0.6-1.4,1.1-2.1,1.6c-0.6,0.5-1.3,1.1-2,1.6c-1.3,1.1-2.9,1.8-4.6,2.1
		c-1.4,0.2-2.8,0.3-4.3,0.2c-1-0.1-1.9-0.4-2.7-0.9c0.8,0.9,1.5,1.8,2.3,2.7L353.8,277.1z"
          />
          <path
            onClick={() => props.onFill(18)}
            fill={props.fillColors[18]}
            class="st1"
            d="M292.8,286c0.4-0.4,0.8-0.8,1.2-1.2c0.3-0.3,0.6-0.7,0.7-1.1c0,0,0-0.1,0-0.1v-0.6c0-0.2,0-0.3-0.1-0.5
		c0,0,0,0,0,0c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.3,0.1-0.6,0.2c-0.4,0.1-0.8,0.2-1.2,0.3c-0.8,0.2-1.6,0.4-2.4,0.5l-0.7,0.1
		c0,0,0,0,0,0.1c0.6,0.9,1.4,2.2,2.3,3.5C291.9,286.7,292.3,286.3,292.8,286z"
          />
          <path
            onClick={() => props.onFill(19)}
            fill={props.fillColors[19]}
            class="st1"
            d="M379.3,331.5c0.4-0.8,0.9-1.5,1.5-2.2c0.1-0.1,0.2-0.2,0.3-0.3c-1.7-0.2-3.4-0.4-5.2-0.7
		c0.5,0.6,0.9,1.2,1.2,1.9c0.5,1.1,0.7,2.4,0.6,3.6c-0.2,1.5-0.8,2.9-1.7,4c-0.7,0.7-1.4,1.3-2.3,1.8c1.8,0.5,3.2,0.9,4.2,1.1
		c0.7,0.1,1.8,0.3,3.1,0.4c-0.1-0.1-0.2-0.2-0.3-0.4c-1.4-1.8-2.1-4.1-2.1-6.3c0-0.5,0.1-1.1,0.1-1.6
		C378.9,332.4,379.1,331.9,379.3,331.5z"
          />
          <path
            onClick={() => props.onFill(20)}
            fill={props.fillColors[20]}
            class="st1"
            d="M397.5,342.1c-0.8-0.4-1.5-1-2.2-1.6l-0.6-0.6c-0.1-0.1-0.1-0.1-0.2-0.2c-0.4,0.9-1.1,1.6-1.9,2.2
		C394.1,342,395.8,342.1,397.5,342.1z"
          />
          <path
            onClick={() => props.onFill(21)}
            fill={props.fillColors[21]}
            class="st1"
            d="M310.9,206.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.2,0,0.4,0,0.6c0,0.2,0.1,0.3,0.2,0.4
		c0.2,0.2,0.5,0.4,0.8,0.5c0.3,0.1,0.5,0.2,0.8,0.2h0.2h0.5l1.1,0.1l0.5,0.9l0.1,0.2c0,0,0,0,0,0c0.1,0.1,0.2,0.1,0.4,0.1l0.4,0.1
		h0.2h0.3h0.3l1,0.5l1,0.5c0.1,0.2,0.2,0.3,0.3,0.5l0.1,0.1l0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0,0.3,0,0.5,0l0.3-0.1l0.2-0.1
		c0.2-0.1,0.3-0.1,0.5-0.2l1.2,0.2l1.2,0.3l0.3,0.4c0.1,0.1,0.1,0.1,0.2,0.2l0,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.2,0.5,0.3
		c0.3,0.1,0.7,0.2,1.1,0.2c0.4,0,0.8-0.1,1.2-0.3l0.2-0.1l0.3-0.1l0.5-0.3l0.5-0.3c0.1-0.1,0.3-0.1,0.5-0.2c1.2,1.3,1.3,1.1,1.9,1.3
		c0.5,0.1,0.9,0.1,1.4,0.1c0.2,0,0.3,0,0.5,0c0.3-0.8,0.5-1.4,0.8-1.9l-0.7-0.8l-0.6-0.8l-1.2-1.4l-2.1-2.6
		c-1.3-1.7-2.5-3.1-3.3-4.3l-0.7-1c-0.1-0.3-0.2-0.4-0.2-0.5l-0.2-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.5,0.1-1.4,0.2-2.6,0.3
		c-3,0.1-5.9-0.2-8.8-0.9c-1-0.2-2-0.6-2.9-1c0,0-0.1,0-0.1-0.1c0,0.1,0,0.1,0.1,0.2c0.1,0.2,0.2,0.4,0.3,0.5
		c0.2,0.2,0.2,0.3,0.6,0.7l0.3,0.8c0,0.3,0,0.5,0,0.9c0,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.2,0,0.3c0,0.2,0,0.4,0.1,0.6
		c0,0.2,0.1,0.4,0.2,0.7c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.2,0.5,0.3,0.7c-0.1,0.4-0.2,0.8-0.3,1.2l-0.2,0.5c0,0.1-0.1,0.2-0.1,0.3
		c0,0.2,0.1,0.2,0,0.2c-0.1,0-0.1,0.1-0.2,0.1h0.1v0.1L310.9,206.1z"
          />
          <path
            onClick={() => props.onFill(22)}
            fill={props.fillColors[22]}
            class="st1"
            d="M168.4,436.4c0.6,0.5,1.1,1.1,1.5,1.6l0.1-0.2c0.6-0.9,1.3-1.7,2.2-2.3c0.7-0.5,1.3-0.8,1.6-1
		c0.3-0.2,0.6-0.8,1.3-1.4c0.4-0.3,0.8-0.6,1.2-0.9c0.5-0.3,1.1-0.5,1.6-0.6c0.3-0.1,0.7-0.2,1-0.2h0.2c0,0,0,0.1,0.1-0.1
		c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.3,0.4-0.6,0.6-0.8c-0.6-2.2-0.9-3.9-1-5c-0.5-0.4-1-0.7-1.5-1.2c-2.7-2.4-5-5.2-6.9-8.3
		c-0.2,0.8-0.3,1.5-0.5,2.3c-1.1,5.1-2.7,10-4.8,14.8c-1.6,3.6-3.4,7.1-5.6,10.4c0.2-0.1,0.4-0.1,0.6-0.1l0.7-0.1
		c0.1-0.1,0.1-0.1,0.1-0.1l0.1-0.2c0.2-0.3,0.4-0.6,0.6-0.8c0.7-0.9,1.6-1.5,2.7-1.9c0.9-0.3,1.6-0.4,1.9-0.5
		c0.3-0.1,0.6-0.3,1.3-0.5c0,0,0.1,0,0.1,0c-0.3-0.2-0.6-0.4-1-0.7c-1.4-1.2-2.1-2.6-1.7-3C165.8,434.7,167,435.3,168.4,436.4z
		 M171.6,422.5c0.5-0.3,2.2,1,4.2,2.8c2,1.8,3.6,3.2,3.3,3.7c-0.3,0.5-2.6-0.2-4.7-2.2C172.3,424.9,171.2,422.8,171.6,422.5z"
          />
          <path
            onClick={() => props.onFill(23)}
            fill={props.fillColors[23]}
            class="st1"
            d="M335.1,218.8l0,0.2c0,0,0,0,0,0s0,0,0.1,0.1c0.2,0.1,0.4,0.2,0.5,0.3c0.7,0.3,1.4,0.4,2.1,0.4
		c0.7,0,1.3-0.1,2-0.3c0.1-0.1,0.3-0.2,0.4-0.3c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0-1.4-0.1-2.9-0.3-4.3
		c-0.1-1-0.2-1.9-0.2-2.6l-0.5-0.1l-0.6-0.1h-0.3c-0.5-0.1-0.9-0.1-1.3-0.1s-0.9,0-1.3,0h-0.3L335,212c-0.1,0-0.2,0-0.2,0
		c0,0.7,0,1.6,0,2.7C334.7,216.1,334.8,217.5,335.1,218.8z"
          />
          <g>
            <path
              onClick={() => props.onFill(24)}
              fill={props.fillColors[24]}
              class="st1"
              d="M346.6,197.2l-0.3-0.6c-0.7,1.5-1.7,3.5-2.9,5.7c-0.1,0.2-0.2,0.4-0.3,0.6l1.2-1.7l1.6-2.3l0.8-1.1l0.1-0.1
			v-0.1L346.6,197.2z"
            />
            <path
              onClick={() => props.onFill(25)}
              fill={props.fillColors[25]}
              class="st1"
              d="M343.1,197.2c-0.9,0.2-1.8,0.4-2.6,0.5c-3,0.5-5.9,0.7-8.9,0.6c-3.7-0.1-6.3-0.5-7.4-0.9
			c0.1,0.2,0.3,0.3,0.5,0.6l0.9,1.2l0.3,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.3l0.4,0.4c1.1,1,2.4,2.3,3.9,3.8
			l2.3,2.4c0.4,0.4,0.8,0.9,1.3,1.3l0.6,0.6l0.1,0.1c0,0,0,0,0.1-0.1h0.1l0.4-0.1c0.5-0.1,1.1-0.1,1.6-0.1c0.5,0,1.1,0,1.6,0h0.5
			c0,0,0,0,0-0.1l0.1-0.2c0.6-1.5,1.7-3.9,3-6.4c1.1-2.1,2.2-3.9,3-5.3C344.7,196.8,343.9,197,343.1,197.2z"
            />
          </g>
          <path
            onClick={() => props.onFill(26)}
            fill={props.fillColors[26]}
            class="st1"
            d="M391,340c1.1-0.4,1.9-0.9,2.4-1.3c-0.3-0.4-0.5-0.9-0.7-1.4c-0.2-0.8-0.3-1.6-0.3-2.3c0.1-0.4,0.1-0.8,0-1.2
		c0-0.1-0.1-0.2-0.6-0.7c-0.3-0.3-0.6-0.6-0.8-1c-0.3-0.5-0.4-1-0.5-1.5c0-0.3-0.1-0.6-0.2-0.9c-2.1-0.1-4.3-0.2-6.4-0.4
		c-0.5,0.5-0.9,1.1-1.3,1.7c-0.3,0.5-0.5,1-0.7,1.6c-0.1,0.6-0.2,1.2-0.1,1.9c0.1,1.5,0.6,3,1.6,4.2c0.9,1.1,2.2,1.8,3.6,1.9
		C388.5,340.6,389.8,340.4,391,340z"
          />
          <path
            onClick={() => props.onFill(27)}
            fill={props.fillColors[27]}
            class="st1"
            d="M338.8,325.8c-0.3,0.7-0.9,1.3-1.7,1.5c-0.1,0-0.1,0-0.2,0c1.3,0.5,2.6,1.1,3.9,1.6c-0.1-0.2-0.1-0.4-0.1-0.7
		c0-0.4,0-0.7,0-1.1c0.1-2.3,0.8-4.4,2.2-6.2c0.9-1.1,2.1-1.8,3.5-2.1c-2-1.1-3.3-2.1-3.9-2.7c-0.3,0.2-0.5,0.5-0.6,0.8
		c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c-0.1,0.5-0.3,1-0.6,1.4c-0.5,0.7-1.1,1.1-1.5,1.6c-0.3,0.3-0.6,0.8-0.7,1.2
		c0,0.6,0,1.3,0,1.9c0,0.3,0,0.7,0,1C339.1,325.1,339,325.5,338.8,325.8z"
          />
          <polygon class="st1" points="354.7,290.6 354.7,290.6 354.7,290.6 	" />
          <path
            onClick={() => props.onFill(28)}
            fill={props.fillColors[28]}
            class="st1"
            d="M391.8,310.1c0-0.9-0.1-1.9-0.4-2.8l0-0.2c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.2,0-0.5,0-0.7c0-0.1,0-0.2,0-0.2
		c-1,0.2-2.5,0.3-4.4,0.3c-4.9,0.1-9.8-0.4-14.6-1.4c-4.9-0.9-9.5-2.6-13.9-4.9c-1.9-1.1-3.3-2.2-4-2.9c0,0-0.1,0-0.1,0.1
		c-0.4,0.3-0.7,0.5-0.6,0.5c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2c0,0.4,0.1,0.8,0.2,1.2c0.2,0.7,0.2,1.4,0.1,2.1c0,0.1-0.1,0.2-0.1,0.3
		c3.1,0.7,10.3,3.2,19,5.2c5.7,1.4,10.9,2.3,14.6,2.9C389.3,309.7,390.7,310,391.8,310.1C391.8,310.1,391.8,310.1,391.8,310.1z"
          />
          <path
            onClick={() => props.onFill(29)}
            fill={props.fillColors[29]}
            class="st1"
            d="M389.6,324.9c0.1-0.5,0.2-1,0.3-1.6c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c-0.1-0.1-0.1-0.3-0.2-0.4
		c-0.2,1.2-0.5,2.2-0.7,3c0.2,0,0.4,0,0.6,0C389.6,325.4,389.6,325.1,389.6,324.9z"
          />
          <path
            onClick={() => props.onFill(30)}
            fill={props.fillColors[30]}
            class="st1"
            d="M328.3,321.5l0.2-0.5c0.2-0.6,0.4-1.2,0.5-1.6c0-0.2,0.1-0.3,0.1-0.5v-0.1c0-0.1,0-0.2,0-0.2
		c0-0.1,0-0.3,0-0.4c0-1,0-1.7-0.1-2.2c0-0.3-0.1-0.6-0.2-0.9c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.1-0.3-0.2-0.4
		c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.2-0.1-0.3-0.2l-0.1,0c0.1,0.4,0.2,0.9,0.3,1.5c0.2,1,0.2,2.1,0.2,3.1c-0.2,1.6-1.2,3.1-2.5,4
		c0,0,0,0,0,0c0.8,0.3,1.8,0.8,2.9,1.3l0,0c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.1-0.1-0.3,0-0.4c-0.1-0.1,0-0.3,0-0.4
		C328.2,322.1,328.2,321.8,328.3,321.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M329.2,357.6c2.7,0.1,5.3,0.4,7.8,0.6c4.9,0.5,9.3,1.2,13,1.9c2,0.4,3.8,0.7,5.3,1.1c-1.1-0.4-2.2-0.8-3.3-1.3
		c-2.1-0.8-4.1-1.7-6.1-2.6l-2.8-1.3l-2.6-1.4c-2.8-1.5-5.3-2.8-7.5-3.9c-1.3,0.3-4.4,0.4-8.8,0.7c-2.9,0.2-6.3,0.4-10.1,0.8
		c-1.9,0.2-3.8,0.5-5.9,0.9c-2,0.4-4.1,0.8-6.3,1.3c-4.3,1-8.4,2-12,3.1c-0.4,0.1-0.9,0.3-1.3,0.4l-0.3,0.1h0c0,0,0,0-0.1,0
		c0,0,0,0,0,0.1l-0.1,0.3l-0.3,0.6l-0.5,1.2c-0.7,1.6-1.4,3.1-2.1,4.4c-0.8,1.4-1.6,2.6-2.3,3.6c5-2.7,19.5-8.2,37.6-10.2
		C323.5,357.6,326.3,357.5,329.2,357.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M389.7,311.9c-0.8,0.1-1.7,0.1-2.5,0.1c-4.8-0.1-9.6-0.6-14.2-1.7c-0.1,1.6-0.4,4-1,6.6
		c-0.6,2.9-1.3,5.4-1.9,6.8c5.8,1,11.7,1.7,17.6,2c-0.1-1.5,0.1-4,0.5-6.7C388.6,315.9,389.2,313.3,389.7,311.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M273.2,291c0.3,0.4,0.5,0.8,0.8,1.2l1.5,2.5c2.1,3.5,4.5,7.7,7.3,12.2c0.7,1.1,1.5,2.1,2.3,3.3l2.6,3.4
		c1.8,2.3,3.6,4.7,5.6,7c1.6,1.9,3.1,3.7,4.8,5.3c1.7,1.6,3.5,3.1,5.2,4.6c3.4,2.9,6.6,5.6,9.6,7.8c1.5,1.1,2.9,2.2,4.2,3
		c1.3,0.8,2.7,1.5,3.9,2.1c2.4,1.3,4.3,2.3,5.6,2.9c2.6,1.2,7.9,3.4,14.8,6.3l2.7,1.1l2.9,1.1c1.9,0.7,4,1.5,6.1,2.2
		c2.8,1,5.6,1.8,8.5,2.6c0.6-1.9,1.7-3.5,3.3-4.7c1-0.7,2-1.3,3.1-1.6c-0.4-1.6-0.7-3.2-1-4.7c-0.3-1.8-0.5-3.5-0.7-5.3l-0.1-0.7
		v-0.1l-0.2,0.2c-0.3,0.4-0.6,0.8-0.9,1.1c-0.6,0.8-1.3,1.4-2,2.1c-1.4,1.3-2.8,2.5-4.3,3.6c-1.5,1.1-3,2.2-4.5,3.2
		c-0.8,0.5-1.5,1-2.3,1.4l-0.6,0.3l-1.1,0.6l-0.6,0.3l-0.3,0.2l-0.1-0.3l-0.6-1.2l-0.4-0.9l-0.3-0.6c-0.2-0.4-0.4-0.9-0.5-1.3
		c-0.3-0.9-0.5-1.8-0.8-2.7c-0.4-1.8-0.7-3.5-0.8-5.3c-0.1-1.8-0.2-3.6-0.1-5.3l0.1-1.3l0.1-0.7c0,0,0-0.1,0-0.1c0,0-0.1,0-0.1,0
		l-0.6,0.4l-1.1,0.8c-0.8,0.5-1.5,1-2.3,1.4c-1.5,0.9-3.1,1.8-4.6,2.6c-1.5,0.8-3.1,1.6-4.6,2.4l-2.3,1.1l-0.8,0.4
		c-0.3,0.1-0.7,0.4-1,0.4c-0.3,0-0.6,0.1-0.9,0c-0.3-0.2-0.3-0.2-0.3-0.3l-0.1-0.5l-0.2-1c0-0.5,0-0.6,0-0.8v-1.2c0-0.9,0-1.7,0-2.5
		c0-1.7,0.1-3.3,0.2-5c0.1-1.7,0.2-3.3,0.3-4.9c0-0.6,0.1-1.3,0.1-1.9c0-0.1,0-0.1,0-0.2c-0.1,0-0.1,0-0.2,0.1l-0.1,0.1l-0.5,0.3
		c-0.4,0.2-0.8,0.4-1.2,0.6c-0.8,0.4-1.6,0.8-2.3,1.1c-1.6,0.7-3.1,1.4-4.7,2s-3.1,1.2-4.7,1.7l-2.3,0.8l-1.3,0.4l-2,0.6h-0.1h-0.1
		c0,0,0,0,0-0.1v-0.3v-0.5l0.1-1l0.1-0.9v-0.6c0.1-0.8,0.1-1.6,0.2-2.3c0.2-1.5,0.4-3.1,0.6-4.6c0.2-1.5,0.5-3,0.8-4.4
		c0.1-0.5,0.2-0.9,0.3-1.4c0,0,0,0,0,0c-0.2-0.1-0.4-0.3-0.6-0.5c-0.1-0.1-0.2-0.2-0.2-0.3l-0.1,0l-1.1,0.2
		c-0.7,0.1-1.5,0.2-2.2,0.3c-1.5,0.2-2.9,0.3-4.3,0.4l-4.1,0.3l-2,0.1l-1.4,0.1h-0.8H293c0,0-0.2,0-0.2,0l0.1-0.2l0.5-1.5
		c0.1-0.2,0.2-0.5,0.2-0.7l0.2-0.5c0.2-0.6,0.5-1.2,0.8-1.8c0.5-1.1,1.1-2.2,1.7-3.3c0.6-1,1.3-2,2-3l0.5-0.7l0.3-0.4
		c0.1-0.1,0.1-0.1,0.2-0.2c-0.1-0.2-0.1-0.3-0.1-0.4H299h-0.5h-0.8c-1.1,0-2.2,0-3.2,0c-2-0.1-3.9-0.3-5.8-0.6
		c-0.9-0.1-1.8-0.3-2.6-0.5l-1.2-0.3c-0.5-0.2-1-0.3-1.4-0.5l-0.2-0.1c0,0,0-0.1,0.1-0.1l0.2-0.3c0.1-0.2,0.3-0.4,0.4-0.6l0.4-0.5
		c0.1-0.2,0.2-0.3,0.3-0.4c0.5-0.5,0.9-1,1.3-1.5c1.3-1.4,2.7-2.7,4.1-4c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1,0-0.1,0-0.2
		c0.1-0.3,0.3-0.6,0.5-0.9c0.3-0.4,0.6-0.8,0.8-1.1c0-0.1,0.1-0.1,0.1-0.2c-0.7,0.2-1.8,0.4-3.2,0.5c-1.2,0.1-2.7,0.2-4.3,0.2h-2.5
		c-0.5,0-0.7,0-1.8,0h-0.7h-0.2l-0.1-0.1l-0.2-0.1c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.1-0.3-0.1-0.5
		l0.2-0.5c0.1-0.3,0.3-0.7,0.5-1c0.4-0.5,0.8-0.9,1.2-1.3c0.6-0.6,1.3-1.2,2-1.8c1.1-0.9,2.3-1.7,3.6-2.5c1.2-0.7,2.1-1.1,2.7-1.4
		c-0.1-0.4-0.3-0.7-0.4-1.1c-0.2,0.4-0.5,0.9-0.9,1.2c-0.5,0.4-1,0.7-1.5,1c-0.4,0.2-0.9,0.4-1.4,0.5c-0.9,0.3-1.9,0.6-2.8,0.7
		c-1,0.2-2,0.2-3,0.2c-0.6,0-1.1-0.1-1.7-0.3c-0.7-0.2-1.4-0.6-1.9-1.2c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.2-0.2-0.4-0.3-0.6
		c-0.1,0.1-0.3,0.2-0.4,0.4c-0.3,0.3-0.6,0.6-0.8,1c-0.4,1.1-0.5,2.3-0.1,3.4C272.9,290.5,273.1,290.8,273.2,291z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M273.7,284.3c-0.1,0.2-0.2,0.3-0.3,0.5c0.1-0.1,0.3-0.1,0.4-0.2C273.8,284.5,273.8,284.4,273.7,284.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M369.7,337.3c0.5,0,1-0.1,1.4-0.2c1-0.3,1.8-0.8,2.5-1.5c0.6-0.6,1-1.3,1.2-2.1c0.2-0.8,0.1-1.6-0.1-2.3
		c-0.4-1.2-0.9-2.3-1.6-3.3c-0.6-0.1-1.3-0.2-1.9-0.3c-1.8-0.3-3.5-0.7-5.2-1.2c-0.9,0.6-1.7,1.3-2.3,2.2c-0.4,0.7-0.6,1.4-0.7,2.2
		c-0.1,0.9,0,1.9,0.3,2.7c0.3,0.9,0.9,1.7,1.6,2.2c0.9,0.7,1.9,1.1,3,1.3C368.6,337.3,369.1,337.3,369.7,337.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M361.8,327.1c0.5-0.6,1.1-1,1.7-1.4c-2.4-0.7-4.8-1.5-7.2-2.3c0.1,0.2,0.3,0.4,0.4,0.7
		c0.4,0.8,0.6,1.7,0.7,2.6c-0.1,2.9-1.7,5.3-3.9,6.7c2.2,0.7,4.3,1.4,6.4,2l0.8,0.3c-0.2-0.4-0.3-0.7-0.4-1.1
		c-0.3-1.4-0.4-2.8-0.1-4.1C360.4,329.2,360.9,328.1,361.8,327.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M353.1,302.8l-0.2,0.3c-0.1,0.2-0.3,0.4-0.3,0.6c-0.1,0.3-0.2,0.7-0.4,1.2c-0.2,0.5-0.4,0.9-0.7,1.3
		c-0.1,0.2-0.2,0.4-0.4,0.6c-0.2,0.2-0.3,0.4-0.5,0.6c-0.6,0.7-1.5,1.3-2.4,1.6c-0.2,0.1-0.4,0.1-0.5,0.2c-0.1,0-0.2,0.1-0.2,0.2
		c-0.1,0.1-0.1,0.6-0.2,0.9c-0.1,0.3-0.2,0.6-0.3,0.9c-0.2,0.5-0.5,1-0.8,1.5c-0.4,0.7-0.9,1.3-1.5,1.8c-0.3,0.3-0.8,0.7-1.4,1.2
		c1.2,0.4,3.9,1.5,7.8,2.9c0.1-1.3,1-4.3,2.3-7.7c1.1-2.9,2.3-5.4,3.2-6.7c-1.3-0.7-2.3-1.3-3-1.8
		C353.2,302.6,353.1,302.7,353.1,302.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M347.1,330.8c1.7,0.7,3.6,0.4,5.1-0.7c1.3-0.9,2.2-2.2,2.7-3.7c0.1-1.1,0-2.2-0.4-3.3
		c-0.1-0.2-0.1-0.4-0.2-0.6c-2.5-1-4.6-2-6.4-2.9c-0.2,0.1-0.4,0.2-0.6,0.4c-1,0.6-1.7,1.4-2.3,2.3c-0.7,1.5-1.1,3.1-1,4.8
		c0,0.2,0,0.4,0.1,0.6c0,0.2,0.1,0.3,0.2,0.5c0.1,0.4,0.4,0.7,0.6,1C345.5,330,346.2,330.5,347.1,330.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M370,316.4c0.6-2.7,1.2-5,1.8-6.5c-5.9-1.4-11-3.4-14.5-5.2c-0.3,1.5-1.1,4.1-2.2,7c-1.3,3.4-2.7,6.2-3.5,7.2
		c1.4,0.5,3,1,4.8,1.5c4.2,1.2,8.4,2.2,12.7,3C369,321.9,369.4,319.3,370,316.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M116.8,259.5c0,0.1,0.1,0.3,0.1,0.4c0,0.2,0,0.4-0.1,0.5c0.2-0.4,0.3-0.7,0.4-1
		C117.1,259.5,117,259.6,116.8,259.5C116.9,259.5,116.9,259.5,116.8,259.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M109.6,259.2c2.1,0,4.1,0.1,5.4,0.2c0,0,0,0,0,0c-0.5-0.1-0.9-0.1-1.4-0.2c-0.6-0.1-1.2-0.2-1.8-0.2
		c-0.6-0.1-1.3,0-2,0c-1.4,0-2.8,0.1-4,0.1c-0.4,0-0.8,0-1.2,0c0.7,0.2,1.4,0.2,2.1,0.2C107.5,259.2,108.5,259.2,109.6,259.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M120.5,242.3c-0.1-0.2-0.2-0.5-0.2-0.7c0-0.1,0-0.1,0-0.2c0-0.4,0.1-0.7,0.3-1c-0.5,0.1-1,0.3-1.4,0.6
		c-0.3,0.2-0.6,0.4-0.8,0.6c0.3,0.1,0.6,0.3,1,0.4C119.6,242.2,120.1,242.3,120.5,242.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M103.3,241.2c0.1,0,0.2-0.1,0.3-0.1c0.5-0.2,0.9-0.4,1.2-0.4c-0.3-0.2-0.6-0.4-1.1-0.7
		c-0.2-0.1-0.4-0.2-0.6-0.3c0.1,0.3,0.2,0.6,0.2,0.9C103.3,240.8,103.3,241,103.3,241.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M124.7,241.9c0.6-0.2,1.1-0.4,1.5-0.5c-0.1-0.1-0.3-0.1-0.4-0.2c-0.4-0.3-0.8-0.5-1.2-0.6
		c0.1,0.3,0.2,0.6,0.2,0.9C124.8,241.6,124.7,241.8,124.7,241.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M112,231.5c0.4,0.1,0.8,0,1.1-0.2c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.1,0.4-0.3c0.5-0.3,1-0.4,1.6-0.3
		c0.5,0.1,0.9,0.3,1.3,0.7l0.3,0.3l0.1,0.2h0.1c0.2,0.1,0.4,0.1,0.6,0.1c0,0,0.1,0,0.1,0c0.2-0.3,0.6-0.5,0.9-0.6
		c0.3-0.1,0.6-0.2,0.9-0.2c0.6-0.1,1.3,0,1.8,0.2c0.2,0.1,0.3,0.2,0.4,0.3l0.2,0.2l0.1,0.1c0.1,0.1,0.2,0.1,0.2,0.2
		c0.2,0.1,0.5,0.2,0.7,0.2c0.2,0,0.5,0,0.7-0.1l0.2-0.1l0.2-0.1c0.2-0.1,0.5-0.2,0.8-0.3c0.7,0,1.5,0.1,2.2,0.3l0.7,0.2
		c0.2,0,0.4,0,0.5,0c0.3,0,0.7-0.1,1-0.3c0.6-0.2,0.9-0.4,1-0.3c0.1,0.1,0,0.5-0.6,1c-0.2,0.2-0.4,0.3-0.7,0.5
		c0,0.4-0.2,1.3-0.4,2.5c-0.1,0.5-0.1,0.9,0,1.4c0.1,0.5,0.3,1.1,0.4,1.7c0.4,1.5,0.7,3,0.8,4.6c0,1.9-0.2,3.8-0.7,5.6
		c-0.5,2.1-1.2,4.1-2.1,6.1c-1,2.2-2.2,4.2-3.6,6.1c-0.8,1-1.6,1.9-2.4,2.9c-0.8,0.9-1.7,1.9-2.6,2.8c-0.9,1-2,2-3,2.9
		c0.2,0.8,0.4,2,0.6,3.5c0.2,1.4,0.4,3.2,0.8,4.9c0,0.1,0,0.1,0,0.1l0.3,0.2l1.4,0.6l2.9,1.4c0,0,0,0,0,0l0.2-0.4
		c0.2,0,0.5-0.1,0.7,0c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.6,0.1c0.5,0.1,0.9,0.3,1.3,0.6c0.1,0.1,0.2,0.2,0.4,0.3
		c0.2,0.1,0.3,0.3,0.4,0.4c0.3,0.4,0.7,0.8,1,1.3l-0.2,0.2c0.2,0.1,0.4,0.2,0.5,0.3c1.7,0.9,3.2,1.9,4.4,2.7c1.5,1,2.6,1.8,3.2,2.4
		c0-0.7,0-1.4,0.2-2c0.1-0.6,0.4-1.3,0.6-1.9c0.2-0.8,0.1-0.7-0.2-1.2c-0.5-0.7-1-1.5-1.4-2.4c-0.2-0.6-0.3-1.3-0.2-2
		c0.1-0.6,0.3-1.1,0.5-1.6c0.2-0.5,0.4-0.9,0.6-1.4c0.2-0.3,0.3-0.7,0.4-1l-0.2-0.4c-0.2-0.5-0.4-1.1-0.5-1.6
		c-0.2-0.6-0.3-1.2-0.3-1.9c0-0.4,0-0.8,0-1.1c0.1-0.5,0.3-1,0.5-1.4c0.1-0.1,0.1-0.2,0.2-0.3l0.1-0.1c0-0.1,0.1-0.1,0.2-0.2
		c0.1,0,0,0,0.1,0c0,0,0.1-0.1,0.1-0.2c0.1-0.3,0.2-0.6,0.2-0.8c0-0.7-0.1-1.5-0.3-2.2l-0.1-0.4c0-0.1-0.1-0.3-0.1-0.5
		c0-0.3,0-0.6,0-0.9c0-0.5,0.1-1,0.3-1.5c0.3-0.8,0.7-1.5,1.2-2.2l0.6-0.8c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.8,0.2-1.5,0-2.3
		c-0.1-0.3-0.2-0.7-0.3-1l-0.3-0.6c-0.1-0.3-0.2-0.5-0.3-0.8c-0.2-0.8-0.2-1.6,0-2.3c0.2-0.8,0.5-1.5,0.9-2.3l0.5-1
		c0.1-0.1,0.1-0.3,0.2-0.4c-0.2-0.2-0.4-0.5-0.5-0.7c-0.2-0.5-0.4-1-0.4-1.5c0-0.5-0.1-1-0.2-1.5c-0.1-0.4-0.4-0.8-0.7-1.1
		c-0.7-0.6-1.2-1.4-1.5-2.2c-0.1-0.9,0-1.9,0.4-2.7c0.2-0.5,0.2-1,0.1-1.5c-0.1-0.3-0.2-0.5-0.4-0.7c-0.1-0.1-0.2-0.3-0.3-0.4
		c-0.1-0.2-0.2-0.3-0.3-0.5c-0.4-0.6-0.7-1.3-0.9-2c-0.1-0.4-0.1-0.8-0.1-1.1c0-0.2,0.1-0.4,0.2-0.6c0.1-0.3,0.1-0.1,0.1-0.3
		c0.1-0.4,0.1-0.7,0.1-1.1c0-0.4-0.1-0.7-0.1-0.9c-0.1,0-0.3,0.1-0.4,0.1l-1.1,0.2l-0.7,0.1l-1.5,0.2c-0.9,0.1-1.8,0.2-2.7,0.2
		c-1.7,0.1-3.3,0.1-4.6,0.1c-2.7,0-4.7-0.1-5.7-0.1c-0.6,0-1.3,0-1.9,0.1c0,0,0,0.1-0.1,0.1c-0.6,1-1.6,1.7-2.8,2
		c-0.5,0.1-1,0.1-1.5,0c0.1,0.1,0.3,0.2,0.4,0.3C111.7,231.4,111.8,231.5,112,231.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M91.4,276.3c-0.3,0.8-0.8,1.6-1.3,2.3c-0.3,0.4-0.6,0.8-1,1.1c-0.4,0.3-0.9,0.5-1.4,0.7l-1.1,0.4
		c0.6,0.6,1.4,1.2,2.4,2c3.1,2.7,6.6,4.7,10.4,6.1c3.2,1,6.7,1.3,10,0.7c2-0.3,4-1,5.8-2.1c0.4-0.2,0.7-0.5,1.1-0.8l0.7-0.6l0.8-0.8
		c0.7-0.7,1.3-1.4,1.9-2.1c0.3-0.4,0.6-0.9,1-1.4l-2.6-1.3l-1.4-0.7l-1-0.5c-0.1,0-0.1-0.1-0.2-0.1l-0.1-0.2l-0.1-0.3
		c-0.1-0.2-0.1-0.4-0.2-0.6c-0.3-1.7-0.4-3.5-0.4-5.2c0-1.2,0-2.2,0.1-3c-0.6,0.5-1.3,1-2,1.5c-1.2,0.7-2.5,1.1-3.9,1
		c-1.3-0.1-2.5-0.4-3.6-1c-1.9-1-3.6-2.3-5.3-3.6c-1.6-1.2-3-2.4-4.2-3.5c-1.1-1-2.1-2-3-3.1c0,0.2,0.1,0.4,0.1,0.6
		c0.2,1.9,0.2,3.8,0.1,5.8C92.9,270.7,92.4,273.6,91.4,276.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M109.6,260.2c-1.1,0-2.1,0-3-0.1c-0.5,0-0.9,0-1.4-0.1c1.2,0.7,2.8,1.4,4.9,1.5c1,0.1,1.9,0.1,2.9-0.1
		c0.7-0.1,1.5-0.3,2.1-0.7c0.6-0.3,0.9-0.7,1.1-1c-0.3,0.1-0.7,0.3-1.1,0.4c-0.8,0.1-1.7,0.1-2.5,0.1
		C111.7,260.2,110.7,260.2,109.6,260.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M113.4,263.3c-1.1,0.3-2.3,0.4-3.5,0.3c-1.2-0.1-2.3-0.4-3.4-0.8c-0.9-0.4-1.7-1-2.3-1.6
		c-1-0.9-1.3-1.8-1.3-2.1c0,0,0,0,0,0c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.5-0.2c0,0,0,0,0,0c0.3,0.8,0.6,1.6,1,2.4
		c0.3,0.5,0.6,0.9,1,1.3c0.2,0.2,0.5,0.4,0.7,0.6c0.3,0.2,0.6,0.4,0.8,0.6c1.2,0.9,2.5,1.5,4,1.7c1.4,0.1,2.9-0.2,4.1-0.9
		c1-0.6,1.9-1.4,2.6-2.4c0.1-0.1,0.2-0.3,0.2-0.4c-0.1,0.1-0.2,0.2-0.2,0.3C115.3,262.6,114.4,263,113.4,263.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M187.5,228.9l0.9,0.1c0.5,0,1,0.1,1.3,0.1c0.5,0,0.9,0,1.4,0c0.3,0,0.8-0.1,1.5-0.2c0.4-0.1,0.8-0.2,1.4-0.3
		c0.3-0.1,0.6-0.2,0.9-0.3l0.5-0.2l0.3-0.1l0.1,0c-0.4-0.4-0.7-1.4-0.7-2.7c0-1.1,0.1-2.1,0.4-2.6c-1.3-0.3-3.8-0.6-9,0.7
		c0.3,0.5,0.6,1.4,0.6,2.3c0.1,1.3-0.2,2.5-0.7,3L187.5,228.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M202.6,221.9l-0.7-0.1c-0.4,0-0.8-0.1-1.2-0.1c-0.6,0-1.2,0-1.8,0.1c-0.6,0.1-1.3,0.2-1.9,0.4l-0.3,0.1
		c-0.1,0-0.3,0.1-0.4,0.2c0.4,0.4,0.8,1.4,0.8,2.6c0.1,1.2-0.2,2.2-0.6,2.7l0.3,0.1c0.5,0.1,1,0.1,1.5,0.1c0.8,0,1.5,0,2.3-0.2
		c0.4-0.1,0.9-0.2,1.4-0.3l0.7-0.2c0.1,0,0.3-0.1,0.4-0.1c-0.3-0.5-0.5-1.5-0.5-2.6c0-1.1,0.1-2.1,0.4-2.6L202.6,221.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M207.5,221.9c-0.2,0-0.3,0-0.5,0c-0.3,0-0.6,0-0.9,0c-0.5,0-1,0.1-1.3,0.1l-0.8,0.1c0.3,0.5,0.6,1.4,0.6,2.4
		c0,1-0.1,1.9-0.4,2.5c0.3,0,0.6,0,1,0c0.5,0,1-0.1,1.5-0.3c0.3-0.1,0.6-0.2,0.9-0.3l0.4-0.2c-0.2-0.5-0.4-1.3-0.5-2.2
		c-0.1-0.9-0.1-1.7,0.1-2.3H207.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M185.1,223.4c-0.1,0-0.3-0.1-0.5-0.1c-0.6-0.1-1.2-0.1-1.8-0.1c-0.9,0-1.7,0.1-2.6,0.2c-0.5,0.1-1,0.1-1.5,0.2
		l-0.8,0.2l-0.2,0c0.2,0.6,0.3,1.3,0.4,2.1c0,1-0.1,2-0.3,2.5l0.2,0c0.1,0,0.3,0.1,0.5,0.1c0.3,0.1,0.6,0.1,0.9,0.1
		c0.5,0.1,1.1,0.1,1.6,0.1c1,0,1.8,0,2.5,0l1.7-0.1l0.5-0.1c-0.4-0.4-0.6-1.5-0.7-2.8C184.9,224.9,185,224,185.1,223.4
		C185.1,223.4,185.1,223.4,185.1,223.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M213.5,224.9v-0.4c0-0.2,0-0.3,0-0.5c0,0.3-0.1,0.6-0.2,0.9C213.4,224.9,213.4,224.9,213.5,224.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M97.3,240.1c-0.4,0.2-0.8,0.4-1.2,0.5c0.7,0.3,1.6,0.7,2.7,0.9c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.1,0-0.1,0-0.2
		c0-0.5,0.1-0.9,0.4-1.2C98.4,239.6,97.9,239.8,97.3,240.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M109.6,231.8c-0.3,0.1-0.6,0.2-1,0.3c-0.3,0-0.7-0.1-0.9-0.3c-0.2-0.2-0.4-0.3-0.6-0.5
		c-0.2-0.2-0.5-0.4-0.6-0.4c-0.1,0-0.2,0.1-0.3,0.2l-0.1,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0.2-0.6,0.4-1,0.4
		c-0.4,0-0.7-0.1-1.1-0.2c-0.5-0.3-1-0.6-1.5-0.9c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2,0-0.4,0-0.5,0c-0.3,0-0.5,0.1-0.9,0.2
		c-0.4,0.1-0.9,0.2-1.3,0.2c-0.5,0-1-0.2-1.4-0.5c-0.2-0.1-0.4-0.2-0.5-0.3c0,0-0.1,0-0.4,0.1l-0.4,0.3l-0.3,0.2
		c-0.1,0.1-0.3,0.1-0.5,0.2c-0.3,0.1-0.7,0.1-1,0c-0.2-0.1-0.5-0.1-0.7-0.3c-0.2-0.1-0.5-0.2-0.7-0.3c-0.4-0.1-0.9,0-1.3,0.1
		c-0.4,0.1-0.9,0.4-1.2,0.6c-0.6,0.5-0.9,1.2-0.9,2c0,0.6,0.1,1.1,0.3,1.7c0.1,0.4,0.3,0.9,0.6,1.7c0.1,0.5,0.2,0.9,0.2,1.4
		c0,0.6-0.2,1.2-0.5,1.7c-0.6,0.9-1.3,2-1.1,2.6c0.2,0.5,0.4,0.9,0.8,1.3c0.5,0.6,0.9,1.3,1.2,2.1c0.2,0.5,0.3,1.1,0.3,1.6
		c0,0.6-0.2,1.3-0.5,1.8c-0.2,0.2-0.4,0.5-0.6,0.7l-0.4,0.3c-0.3,0.2-0.5,0.5-0.7,0.7c-0.3,0.3-0.6,0.8-0.7,1.2
		c-0.1,0.1,0.4,0.8,0.7,1.3c0.6,0.8,0.8,1.9,0.6,2.9c0.3,0.4,0.8,0.9,1.4,1.7c1.7,1.9,3.6,3.7,5.6,5.3c1.3,1,2.7,2.1,4.3,3.3
		c1.6,1.2,3.3,2.3,5.1,3.3c0.9,0.5,1.9,0.7,2.8,0.8c0.5,0,1-0.1,1.5-0.2c0.5-0.1,0.9-0.3,1.3-0.6c2-1.4,3.8-2.9,5.4-4.7
		c0.9-0.9,1.7-1.8,2.5-2.7s1.6-1.8,2.3-2.7c1.4-1.8,2.5-3.7,3.5-5.7c0.9-1.8,1.6-3.7,2.2-5.7c0.5-1.7,0.8-3.4,0.9-5.1
		c0-1.5-0.2-2.9-0.5-4.3c-0.1-0.7-0.2-1.3-0.3-1.9c0-0.6,0.1-1.1,0.2-1.7c0.3-1,0.6-1.7,0.8-2.2c0,0,0,0,0,0
		c-0.3,0.1-0.6,0.1-0.9,0.1c-0.3,0-0.6,0-0.9,0c-0.5-0.1-1.1-0.1-1.6-0.1c-0.1,0-0.1,0-0.2,0.1l-0.2,0.2l-0.3,0.2
		c-0.4,0.2-0.9,0.3-1.4,0.4c-0.5,0-1.1-0.1-1.6-0.3c-0.3-0.1-0.5-0.3-0.8-0.5l-0.1-0.1c0,0-0.1,0-0.1,0c-0.2-0.1-0.4-0.1-0.7,0
		c-0.1,0-0.3,0.1-0.4,0.1l-0.1,0l0,0l-0.1,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.3,0.2-0.5,0.3c-0.3,0.1-0.6,0.1-0.9,0.1
		c-0.5,0-1-0.1-1.4-0.3c-0.3-0.1-0.5-0.3-0.7-0.5l-0.1-0.2c0,0,0,0,0,0l-0.1-0.1c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1,0-0.2,0-0.2,0
		c0,0-0.2,0.2-0.4,0.2c-0.2,0.1-0.4,0.2-0.5,0.2c-0.7,0.2-1.5,0.2-2.2-0.1c-0.3-0.1-0.6-0.3-0.8-0.6c-0.1-0.1-0.2-0.3-0.4-0.4
		C110.2,231.8,109.9,231.8,109.6,231.8z M99.3,233.3c4.4-0.2,8.2,0.9,8.2,2.1s-1.3,1.5-3.3,0.7c-2.1-0.8-2.9-1.3-5.3-1.3
		c-2,0.1-4,0.5-5.9,1.2C92.9,235.9,94.8,233.5,99.3,233.3z M95.8,242c-0.4-0.4-0.7-0.8-0.8-1.1c-1.4,0.3-2.3-0.2-2.2-0.4
		c0-0.3,0.8-0.4,1.8-1c0.5-0.3,1.1-0.7,1.8-1.2c0.9-0.6,1.8-1,2.9-1.2c1.1-0.2,2.2-0.1,3.2,0.2c0.8,0.3,1.6,0.8,2.2,1.4
		c1,1.1,1.1,2,0.9,2.2c-0.1,0-0.1,0-0.2,0c0,0.4-0.3,0.9-1,1.4c-1.2,0.9-2.8,1.4-4.3,1.3C98.5,243.7,97,243.1,95.8,242z
		 M119.3,258.5c-0.2,0.3-0.5,0.5-0.9,0.7c-0.2,0.1-0.3,0.2-0.5,0.2c0.1,0.7,0.1,1.8-0.6,3.3c-0.7,1.4-1.7,2.5-3,3.3
		c-0.7,0.5-1.6,0.9-2.4,1.1c-0.9,0.3-1.9,0.4-2.9,0.3c-1.8-0.3-3.5-1.1-4.9-2.3c-0.3-0.2-0.6-0.5-0.9-0.8c-0.3-0.3-0.5-0.5-0.8-0.8
		c-0.4-0.6-0.8-1.2-1-1.9c-0.3-0.9-0.5-1.9-0.4-2.9c0-0.2,0-0.4,0.1-0.5c-0.3-0.2-0.6-0.5-0.8-0.8c-0.3-0.5-0.4-0.8-0.3-0.9
		c0.1-0.1,0.4,0.1,0.8,0.3c0.6,0.3,1.2,0.5,1.8,0.5c0.3,0,0.9-0.1,1.4-0.1l1.7-0.2c1.2-0.1,2.6-0.2,4-0.3c0.7,0,1.5,0,2.2,0
		c0.7,0.1,1.3,0.2,2,0.4c1.2,0.3,2.3,0.6,3.1,0.9l0.5,0.1c0.2,0,0.3,0,0.4-0.1c0.3-0.1,0.5-0.2,0.8-0.3c0.5-0.1,0.8-0.3,0.9-0.2
		C119.7,257.6,119.7,258,119.3,258.5z M122.8,234.7c1.9,0,4.2,2,4.2,2c-1.3-0.5-2.8-0.7-4.2-0.5c-1.1,0.2-3.7,0.9-3.8,0.1
		C118.8,235.4,120.8,234.7,122.8,234.7z M118.1,239.9c0.5-0.6,1.1-1,1.8-1.3c0.8-0.3,1.7-0.5,2.6-0.5c0.9,0,1.8,0.2,2.6,0.6
		c0.7,0.3,1.2,0.8,1.7,1.4c0.3,0.5,0.5,0.8,0.6,1c0.1,0.2,0.3,0.3,0.3,0.5c0,0.1-0.2,0.3-0.6,0.3c0,0-0.1,0-0.1-0.1
		c-0.1,0.3-0.3,0.7-0.8,1.2c-1.1,0.9-2.4,1.4-3.8,1.5c-1.5,0.2-2.9-0.3-4-1.3c-0.5-0.5-0.7-1-0.7-1.4c0,0-0.1,0-0.1,0
		C117.3,241.7,117.3,240.9,118.1,239.9z M114.1,241.4c0.3-1.2,1-2.3,1.8-3.2c0.7-0.7,1.3-0.9,1.5-0.7c0.4,0.4-0.6,2.1-1.3,4.5
		c-0.7,2.3-1,4.4-1.6,4.4c-0.3,0-0.6-0.5-0.7-1.5C113.6,243.8,113.7,242.5,114.1,241.4z M111,253.4c0.1,0.1,0.3,0.2,0.4,0.3
		c0.1,0,0.2,0.1,0.3,0.1c0.5,0.1,1.1,0.1,1.7,0c0.4,0,0.7-0.2,0.9-0.5c0.4-0.7,0.5-1.4,0.9-1.4c0.1,0,0.3,0.1,0.4,0.5
		c0.2,0.5,0.2,1,0.1,1.5c-0.1,0.4-0.3,0.8-0.5,1.1c-0.3,0.3-0.7,0.6-1.1,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.5,0.1-1,0-1.5-0.1
		c-0.3-0.1-0.6-0.2-0.8-0.3c-0.2-0.1-0.3-0.2-0.5-0.4c0,0,0,0,0-0.1s-0.4,0.3-0.8,0.4c-0.5,0.1-1,0.1-1.4-0.1
		c-0.6-0.2-0.9-0.8-1-1.4c0-0.4,0.1-0.6,0.2-0.6c0.1,0,0.3,0.1,0.5,0.3c0.2,0.2,0.5,0.4,0.7,0.3c0.1,0,0.2-0.1,0.3-0.1
		c0.2-0.2,0.5-0.5,0.8-0.7C109.7,252.9,110.4,253,111,253.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M213.2,221.6L213.2,221.6l-0.2,0.1c0.2,0.3,0.4,0.8,0.4,1.3c0,0,0-0.1,0-0.1L213.2,221.6L213.2,221.6
		C213.2,221.6,213.2,221.6,213.2,221.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M211.4,221.9c-0.1,0-0.2-0.1-0.4-0.1c-0.2,0-0.5,0-0.7,0c-0.3,0-0.7,0-1,0c-0.1,0-0.2,0-0.3,0
		c0.3,0.5,0.5,1.2,0.6,2c0.1,0.8,0,1.5-0.2,2.1c0.2,0,0.4,0,0.6,0c0.3,0,0.6-0.1,0.9-0.2c0.2-0.1,0.3-0.1,0.4-0.2
		c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.1,0.2-0.2c-0.2-0.4-0.4-1-0.5-1.7C211.3,222.9,211.4,222.3,211.4,221.9L211.4,221.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M161,309.4c0,0.1-0.1,0.1-0.1,0.2l0.1-0.1L161,309.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M247.1,299c-0.9,0.1-1.9,0.1-2.8,0.1l-1.3,0.1h-0.3h-0.1c0,0,0-0.1-0.1,0.2c-0.1,0.3-0.3,0.7-0.4,1
		c-0.2,0.7-0.4,1.4-0.5,2.1c-0.4,2.6-0.5,5.3-0.1,7.9c0.3,2.1,0.8,3.8,1.1,5c0.6-1.4,1.3-2.7,2-4c1.8-3.2,4.1-6.2,6.8-8.9
		c0.1-0.1,0.2-0.2,0.3-0.3c-0.4,0.1-0.8,0.2-1.3,0.3c-3.1,0.9-4.8,2-5.2,1.7c-0.4-0.3,1-2.4,4.3-4.1c4.4-2.1,9.2-2.7,14-1.9
		c4.7,0.7,9.1,2.4,13.1,4.9c0.2,0.1,0.3,0.2,0.5,0.3c-1.5-2.8-2.7-5.3-3.9-7.6l-1.3-2.6c-0.2-0.4-0.4-0.8-0.6-1.1
		c-0.3-0.5-0.5-1-0.6-1.5c-0.1-0.5-0.2-0.9-0.2-1.4c-0.3,0.1-0.6,0.2-0.9,0.3c-0.6,0.2-1.4,0.5-2.3,0.8c-0.4,0.2-0.9,0.3-1.3,0.5
		c-0.2,0.1-0.4,0.2-0.6,0.3l-0.8,0.5c-2.1,1.3-4.6,2.7-7.5,4.1c-1.5,0.8-3.1,1.5-4.7,2.1C250.9,298.6,249,298.9,247.1,299z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M213,227l-0.5,0c-0.1,0.1-0.2,0.1-0.4,0.2c-0.2,0.1-0.5,0.2-0.7,0.3c-0.4,0.1-0.9,0.2-1.4,0.3
		c-0.3,0-0.7,0-1,0h-0.2h-0.1l-0.3,0.1l-0.5,0.2c-0.4,0.1-0.7,0.2-1,0.3c-0.6,0.1-1.2,0.2-1.9,0.2c-0.4,0-0.9,0-1.3-0.1l-0.2,0
		l-0.7,0.2l-0.8,0.2c-0.5,0.1-1,0.2-1.5,0.2c-0.8,0.1-1.7,0.1-2.5,0c-0.6-0.1-1.1-0.2-1.6-0.3l-0.3-0.1l-0.1-0.1l-0.2,0.1l-0.3,0.1
		l-0.6,0.2c-0.4,0.1-0.7,0.2-1,0.3c-0.6,0.2-1.1,0.2-1.5,0.3c-0.8,0.2-1.3,0.2-1.6,0.2s-0.7,0-1.4,0c-0.4,0-0.8-0.1-1.4-0.1
		l-0.9-0.1l-1-0.2H186l-0.3,0.1l-0.6,0.1c-0.5,0.1-1,0.2-1.8,0.3c-0.9,0.1-1.8,0.2-2.6,0.3c-0.6,0-1.2,0-1.8,0c-0.3,0-0.7,0-1-0.1
		l-0.5-0.1l-0.3-0.1h-0.1c-0.3,0.2-0.6,0.3-1,0.3c-2.9,0.7-5.9,0.6-8.8-0.4c-0.3-0.1-0.5-0.2-0.8-0.3l-0.4-0.2l-0.1-0.1
		c-2.3,0-5.7-0.4-7.6-1.5h-0.4h-0.6c-0.4,0-0.8-0.1-1.2-0.1c-0.4-0.1-0.8-0.1-1.2-0.2l-0.6-0.2c-0.2-0.1-0.3-0.1-0.5-0.2
		c-1.2-0.1-2.3-0.6-3.2-1.4c-0.5-0.3-1-0.7-1.4-1.2c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1,0.1-0.2,0.3-0.3,0.5c-0.2,0.3-0.3,0.7-0.3,1.2
		c0,0.1,0,0.1,0,0.2v0.4c0,0.3-0.1,0.5-0.1,0.8c-0.2,0.5-0.4,1-0.8,1.5c-0.6,0.7-1.3,1.5-2,2.1c-0.4,0.6-0.6,1.3-0.6,2
		c-0.1,1.1-0.1,2.2,0,3.3c0,0.1,0,0.4,0,0.6c0,0.2,0,0.5-0.1,0.7c-0.1,0.5-0.2,0.9-0.5,1.3c-0.4,0.7-0.8,1.4-1.4,2l-1,1.1
		c-0.2,0.2-0.3,0.4-0.5,0.6c-0.2,0.7-0.2,1.5-0.1,2.3c0.1,1.1-0.1,2.2-0.4,3.2c-0.2,0.5-0.5,1-0.8,1.4c-0.3,0.4-0.5,0.7-0.8,1
		c-1,1.2-1.5,2.2-1.4,3c0,0.2,0.1,0.5,0.1,0.7l0.2,0.7l0.3,1.1c0.3,0.9,0.4,1.9,0.3,2.9c-0.1,0.4-0.2,0.8-0.3,1.1l-0.4,0.9
		c-0.3,0.6-0.5,1.2-0.6,1.8c0,0.3,0,0.6,0,0.8c0.1,0.3,0.2,0.5,0.3,0.8c0.5,1,0.8,2,0.9,3.1c0.1,0.7,0,1.3-0.2,2
		c-0.1,0.4-0.3,0.8-0.5,1.2c-0.1,0.2-0.3,0.4-0.4,0.5c0,0.1,0,0.3,0,0.4c0,0.4,0.1,0.8,0.2,1.2c0.1,0.4,0.3,0.9,0.4,1.3l0.1,0.3
		c0.1,0.2,0.1,0.5,0.2,0.7c0.1,0.5,0.1,1-0.1,1.5c-0.2,0.6-0.5,1.2-0.8,1.7l-0.7,1.2c-0.2,0.3-0.3,0.6-0.5,0.9
		c-0.1,0.2-0.1,0.4-0.1,0.6c0.2,0.7,0.5,1.4,0.8,2.1c0.2,0.5,0.3,1.1,0.3,1.6l-0.1,0.4c0,0.1,0,0.2-0.1,0.4
		c-0.1,0.2-0.2,0.4-0.3,0.5c-0.4,0.6-0.7,1.1-0.9,1.6c-0.4,0.7-0.5,1.5-0.5,2.3c0.1,0.8,0.5,1.5,1,2c0.5,0.5,0.9,1,1.3,1.6
		c0.2,0.5,0.3,1,0.3,1.5c0,0.4-0.1,0.9-0.2,1.3c-0.1,0.7-0.2,1.4-0.1,2.1c0,0.6,0.2,1.1,0.6,1.6c0.8,1,1.3,2.3,1.5,3.6
		c0.1,0.7,0,1.4-0.2,2.1c-0.1,0.3-0.2,0.7-0.4,1l-0.3,0.7c-0.1,0.3-0.2,0.6-0.1,0.9c0.1,0.4,0.2,0.8,0.4,1.2
		c0.1,0.2,0.2,0.5,0.4,0.7l0.2,0.4l0.3,0.5c0.4,0.7,0.6,1.5,0.7,2.4c0,1.2-0.2,2.5-0.7,3.6c-0.3,0.9-0.6,1.8-0.7,2.5
		c-0.3,1.1-0.2,2.2,0.2,3.2c0.1,0.2,0.2,0.3,0.2,0.5c1.6-1.5,3.1-2.4,4.2-2.8l-0.2-0.2l0.5-0.5c0.5-0.6,1-1.1,1.5-1.6
		c0.3-0.3,0.6-0.5,0.9-0.7l0.5-0.3l0.3-0.2c0.8-0.5,1.7-0.9,2.6-1.1c0.9-0.3,1.8-0.4,2.8-0.4c0.4,0,0.9,0,1.3,0.1
		c0.2,0,0.4,0.1,0.7,0.1l1,0.2l0.1,0.1c0.2-0.1,0.3-0.2,0.5-0.4c0.6-0.4,1.4-0.8,2.3-1.3l1.4-0.7l0.4-0.2l0.5-0.2l0.6-0.3
		c-0.3-0.7-0.6-1.3-0.9-2c-0.1-0.1-0.1-0.2-0.2-0.3l-0.1-0.1l0,0l-0.1-0.1l-0.1-0.1l-0.2-0.2l-0.3-0.4c-0.3-0.4-0.6-0.9-0.8-1.4
		c-0.3-0.7-0.4-1.5-0.4-2.3c0.1-0.5,0.2-0.8,0.4-0.8c0.3,0,0.4,1.1,1.3,2.4c0.2,0.4,0.5,0.7,0.8,1c0.1,0.1,0.3,0.3,0.4,0.4
		c0.3,0.2,0.6,0.5,0.8,0.9c0.7,1.1,1.4,2.3,1.8,3.6c0.1,0.1,0.3,0.2,0.5,0.3c0.1,0.2,0.1,0.3,0.2,0.5l0.1,0.3l0.1,0.1l0.3,0.4
		c0.2,0.3,0.4,0.6,0.6,0.8c0.4,0.5,0.8,1,1,1.6c0.3,0.8,0.2,1.7-0.1,2.4c-0.3,0.6-0.5,1-0.6,1.3c0,0.1-0.1,0.1-0.1,0.2
		c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.1,0.3,0.2,0.5,0.3,0.8c0.2,0.4,0.4,0.7,0.6,1.1c0.6,0.9,1.2,1.7,1.9,2.7c0.4,0.7,0.7,1.4,0.8,2.2
		c0.1,0.6,0.1,1.3,0.1,1.9c-0.1,1.1-0.3,2.2-0.6,3.3c-0.2,0.5-0.3,1-0.5,1.4c-0.1,0.3-0.2,0.6-0.2,0.9c-0.2,1.4,0,2.7,0.4,4.1
		c0.2,0.9,0.6,1.7,1.2,2.4c0.5,0.5,1.1,1,1.7,1.2c-0.3-0.3-0.5-0.7-0.6-1.1c-0.3-0.9-0.4-1.8-0.1-2.7c0.5-1.3,1.2-2.5,2.1-3.6
		l0.6-0.8c0.2-0.2,0.3-0.5,0.4-0.7c0.2-0.4,0.4-0.9,0.6-1.3c0.1-0.2,0.1-0.4,0.1-0.5v0l0,0c-0.2-0.2-0.3-0.5-0.4-0.8
		c-0.1-0.4-0.2-0.9-0.2-1.3c0.1-1.3,0.7-2.4,1.7-3.3c0.2-0.1,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.6-0.3l0.4-0.1c0.2,0,0.3,0,0.5,0
		c0.1,0,0.2,0,0.2,0l0.1,0.1c0.1,0,0.2,0.1,0.2,0.1c0.2,0.3,0.2,0.6,0.2,0.9c0,0.2,0,0.3,0,0.5v0.1h0.1l0.1-0.1
		c0.3-0.2,0.6-0.5,0.8-0.9c0.3-0.5,0.4-1.1,0.3-1.6c-0.3-0.8-0.8-1.6-1.4-2.2c-0.4-0.4-0.9-0.8-1.5-1.4c-0.3-0.3-0.7-0.6-0.9-1
		c-0.2-0.3-0.3-0.6-0.4-0.9c-0.1-0.3-0.1-0.7-0.1-1c0-0.9,0-1.7-0.1-2.6c0-0.4-0.1-0.7-0.3-1c-0.1-0.2-0.1-0.3-0.2-0.5l-0.4-0.6
		c-0.4-0.6-0.6-1.2-0.8-1.8c0-0.2-0.1-0.3-0.1-0.6c0-0.3-0.1-0.5-0.1-0.8c0-0.3,0-0.5,0.1-0.8c0.1-0.2,0.2-0.5,0.4-0.7l1-1.5
		c0.3-0.4,0.5-0.9,0.7-1.4c0.2-0.3,0.3-0.7,0.5-1l-0.4-0.5l0.4-1.4l0.2-0.8l0.4-1.4l0.7-2.2c0.1-0.3,0.2-0.6,0.3-0.9
		c0.1-1.8,0-3.6-0.2-5c-0.2-1.8-0.5-3.3-0.7-4.3c-0.1,0-0.1,0-0.2,0l-1.3-0.2l-0.4-0.1c-0.1,0-0.3,0-0.3-0.2l-0.5-0.7l-0.4-0.6
		l-0.2-0.4c-0.1-0.2-0.3-0.5-0.4-0.7l-0.9-1.4c-0.6-0.9-1.2-1.7-1.8-2.5c-1.6-2.2-3.4-4.4-5.3-6.4c-0.4-0.4-0.9-0.8-1.8-1.5
		l-1.7-1.4l-2.7-2.1l-0.5-0.9c-0.2-0.3-0.3-0.7-0.5-1.1c-0.5-1-0.9-2.1-1.3-3.2c-1-3-1.7-6.1-2.1-9.2c-0.8-5.5-1.2-11.1-1.1-16.7
		c0-2.7,0.2-4.7,0.5-5.9c-0.4,0.1-0.6,0-0.6-0.1c0-0.1,0.2-0.3,0.6-0.7c0.2-0.2,0.6-0.5,0.8-0.6c0.1-0.1,0.3-0.3,0.4-0.4l0.2-0.2
		c0.1-0.1,0.3-0.2,0.5-0.3c0.7-0.3,1.4-0.3,2.1,0c0.6,0.2,1.2,0.5,1.8,0.7c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.3-0.1,0.5-0.2
		c0.3-0.2,0.6-0.5,0.9-0.7c0.4-0.3,1-0.4,1.5-0.5c0.5,0,1,0.1,1.4,0.4c0.3,0.2,0.6,0.5,0.8,0.8c0.2,0.4,0.5,0.7,0.8,1
		c0.3,0.1,0.7,0.1,1,0c0.4-0.1,0.9-0.3,1.3-0.5c0.8-0.3,1.6-0.5,2.5-0.5c0.6,0,1.1,0.1,1.6,0.4c0.5,0.3,0.9,0.6,1.3,0.9
		c0.2,0.2,0.5,0.4,0.8,0.5c0.3,0,0.6-0.1,0.8-0.3c0.3-0.2,0.5-0.5,1-0.9c0.3-0.2,0.6-0.4,0.9-0.5c0.4-0.1,0.8-0.1,1.2,0
		c1.3,0.4,2.2,0.9,3.3,1.3c0.5,0.2,1,0.3,1.5,0.4c0.5,0.1,1,0.1,1.5,0c0.9-0.1,1.8-0.4,2.7-0.9c0.5-0.2,1-0.3,1.5-0.3
		c0.4,0,0.8,0.1,1.2,0.2c0.7,0.2,1.2,0.3,1.5,0.4c0.5,0.1,1,0.1,1.4,0.1c0.7-0.2,1.3-0.5,1.9-1.1c0.4-0.3,0.9-0.6,1.4-0.8
		c0.6-0.3,1.3-0.3,2-0.2c0.7,0.1,1.3,0.4,1.9,0.8c0.4,0.3,0.8,0.6,1.3,0.8c1.2,0.2,2.5,0.1,3.7-0.3c1.2-0.3,2.5-0.4,3.7-0.2
		c2.1,0.4,3.1,1.4,3,1.5c-0.1,0.2-0.7,0-1.6-0.1c0.8,2.2,1.3,11.7,0.3,23.1c-0.3,2.5-0.7,5-1.2,7.5c-0.5,2.2-1.1,4.4-2,6.5
		c-0.1,0.1-0.1,0.2-0.2,0.4c0.6,1.1,1.3,2.8,2.5,5c1.3,2.5,2.8,4.9,4.6,7.1v-0.6c0,0,0.1,0.1,0.1,0.1l3.5,3.2l1,0.9l0.4,0.3l0.3,0.3
		l1.2,1l1.3,1l0.7,0.5l0.9,0.7c0.4,0.4,1.1,0.6,0.9,1.3c0,0.2,0,0.3,0,0.5c3.5,2.1,6.9,3.5,9.8,4c2.1,0.4,3.8,0.5,5,0.6
		c0,0,0-0.1,0.1-0.1c0.2-0.4,0.5-0.7,0.8-0.9c0.1,0,0.2-0.1,0.3-0.1l0.3-0.1c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.5-0.1,0.6-0.1h0.4
		l1.4-0.1c0.9,0,1.8-0.1,2.7-0.1c1.6-0.1,3.2-0.4,4.8-0.8c0.4-0.1,0.8-0.3,1.1-0.4c0-0.3-0.1-0.6-0.2-0.9c-0.2-0.4-0.4-0.7-0.7-0.9
		c-0.3-0.3-0.9-0.6-1.6-1.1c-0.5-0.3-0.9-0.7-1.2-1.1c-0.4-0.6-0.6-1.2-0.7-1.9c-0.2-1.1-0.1-2-0.2-2.8c0-0.3-0.1-0.6-0.2-0.9
		c-0.1-0.2-0.2-0.3-0.4-0.5c-0.8-0.8-1.5-1.7-1.8-2.8c-0.2-0.9-0.4-1.7-0.4-2.6c0-0.4,0-0.8,0-1.1s0-0.7,0-0.8c0,0,0-0.1,0-0.1
		c0-0.1,0.1,0-0.1-0.2c-0.2-0.2-0.3-0.5-0.5-0.7c-0.7-0.8-1.4-1.7-2-2.7c-0.4-0.6-0.7-1.4-0.9-2.1c-0.1-0.3-0.1-0.7-0.2-1
		c0-0.4-0.1-0.7-0.2-1.1c-0.2-0.9-0.6-1.8-1.2-2.5c-0.3-0.4-0.7-0.7-1.1-0.9l-0.3-0.2l-0.2-0.1c-0.1,0-0.2-0.1-0.2-0.1
		c-0.5-0.3-1-0.7-1.4-1.2c-0.5-0.8-0.9-1.8-1-2.8c-0.1-0.7-0.2-1.3-0.4-2c-0.1-0.3-0.3-0.6-0.6-0.8c0,0-0.1-0.1-0.2-0.1l-0.3-0.2
		c-0.2-0.1-0.4-0.2-0.6-0.4c-0.4-0.3-0.8-0.6-1.2-0.9c-0.7-0.7-1.2-1.6-1.4-2.6c-0.1-0.7-0.1-1.4-0.1-2.1c0-0.9-0.3-1.7-0.8-2.5
		c-0.3-0.6-0.7-1.1-1.2-1.5c-0.5-0.4-1.1-0.6-1.7-0.8c-0.1,0-0.3-0.1-0.4-0.1c-0.2-0.1-0.3-0.1-0.5-0.2c-0.3-0.2-0.6-0.5-0.8-0.8
		c-0.4-0.6-0.7-1.2-0.9-1.8c-0.2-0.6-0.4-1.1-0.7-1.7c-0.1-0.2-0.2-0.4-0.4-0.6c-0.2-0.2-0.4-0.4-0.6-0.6c-0.5-0.4-1-0.8-1.7-1.4
		c-0.4-0.4-0.8-0.9-1.1-1.4c-0.2-0.4-0.3-0.7-0.4-1.1l-0.1-0.5c-0.1-0.6-0.3-1.1-0.7-1.5c-0.2-0.2-0.5-0.4-0.8-0.5l-0.7-0.3
		c-0.3-0.2-0.5-0.3-0.8-0.5c-0.5-0.4-0.9-0.9-1.2-1.5c-0.2-0.5-0.4-1-0.5-1.6c-0.1-0.8-0.1-1.5-0.1-2.3c0-0.4,0-0.8-0.2-1.1
		c-0.2-0.3-0.4-0.6-0.6-0.8c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2,0.1-0.4,0.1-0.7,0.1C213.6,227,213.3,227,213,227z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M84.9,337.6c0.5,0.2,1.1,0.3,1.8,0.5c0.7,0.2,1.5,0.5,2.2,0.8c0.5,0.2,0.9,0.5,1.2,0.9
		c0.3,0.4,0.6,0.8,0.8,1.2c0.4,0.8,0.8,1.6,1.2,2.3c0.2,0.4,0.4,0.8,0.6,1.1c0.3,0.1,0.6,0.2,0.9,0.3c0.3,0.1,0.5,0.1,0.8,0.1
		c0.2-0.1,0.4-0.3,0.3-0.5c0-0.4,0-0.8-0.2-1.2c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.1-0.3-0.4-0.5-0.7c-0.7-1-1.3-2.2-2-3.4
		c-0.3-0.6-0.6-1.1-1-1.7c-0.4-0.4-1-0.7-1.5-0.8l-2.3-0.8c-0.9-0.3-1.8-0.8-2.5-1.5c-0.4-0.4-0.7-0.7-0.9-0.9
		c-0.1-0.2-0.3-0.2-0.6-0.3h-0.2h-0.1c-0.1,0-0.2,0.1-0.4,0.2c-0.4,0.2-0.7,0.4-1,0.7c-1.3,1.1-2.5,2.3-3.8,3.5
		c-0.6,0.6-1.2,1.2-1.9,1.7c-0.3,0.3-0.7,0.6-1.1,0.8c-0.4,0.3-0.8,0.4-1.1,0.6c-1.2,0.5-2.1,1.4-2.6,2.6c-0.4,1.1-0.6,2.3-0.7,3.5
		c-0.1,1.7,0.3,3.5,1.2,5c0.2,0.4,0.4,0.7,0.6,1c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.4,0.5-0.7,0.9-1c0.5-0.5,1-0.9,1.6-1.2
		c0.3-0.1,0.5-0.3,0.7-0.4c-1.2-0.8-1.8-1.4-1.7-1.6c0.1-0.2,1.4,0.2,3.3,0.9l3.4,1.3c0.6,0.2,1.2,0.4,1.9,0.5h0.2l-0.3-0.4
		l-0.7-0.9l-1.3-1.7c-0.3-0.5-0.6-0.9-1.1-1.2c-0.4-0.3-1-0.6-1.4-0.9c-0.8-0.5-1.4-1.1-1.9-1.9c-0.3-0.5-0.4-0.9-0.3-1
		s0.4,0.1,0.9,0.4c0.6,0.5,1.3,0.8,2,1.1c0.5,0.2,1,0.4,1.6,0.7c0.7,0.4,1.2,0.9,1.7,1.5l1.4,1.6l0.7,0.9l0.4,0.5
		c0.3,0.3,0.4,0.6,0.5,1c0.1,0.2,0.1,0.4,0,0.6c0.1,0,0.1,0.1,0.2,0.1c0.7,0.4,1.4,0.7,2.1,0.9c0.4,0.1,0.9,0.1,1.3-0.1l0.2-0.1
		c0,0,0,0,0-0.1s0,0,0-0.1s0-0.1-0.1-0.2c-0.1-0.3-0.2-0.5-0.4-0.7c-0.1-0.1-0.2-0.2-0.3-0.4l-0.4-0.4c-0.5-0.7-1-1.4-1.4-2.2
		c-0.4-0.8-0.8-1.5-1.1-2.2l-0.5-1c-0.1-0.2-0.2-0.4-0.4-0.5c-0.5-0.4-1-0.7-1.6-1c-0.6-0.3-1.1-0.6-1.6-0.9
		c-1.9-1.3-2.8-2.3-2.6-2.5c0.2-0.2,1.4,0.4,3.3,1.2c0.5,0.2,1,0.4,1.6,0.7c0.7,0.3,1.4,0.6,2,1c0.4,0.3,0.8,0.7,1,1.1l0.5,1
		l1.1,2.1c0.3,0.7,0.7,1.3,1.1,1.9l0.4,0.4c0.1,0.2,0.3,0.4,0.4,0.6c0.2,0.4,0.4,0.8,0.5,1.3c0,0.1,0,0.1,0,0.2c0.1,0,0.1,0,0.1,0.1
		c0.3,0.1,0.7,0.2,1.1,0.3c0.4,0.1,0.8,0.1,1.2-0.1c0.4-0.2,0.5-0.3,0.5-0.7c-0.1-0.6-0.3-1.2-0.5-1.7c-0.3-0.7-0.7-1.3-1.1-1.9
		l-0.4-0.5c-0.2-0.2-0.3-0.4-0.4-0.6c-0.2-0.4-0.5-0.8-0.7-1.2l-1.1-2.3c-0.1-0.3-0.3-0.6-0.5-0.8c-0.2-0.2-0.4-0.4-0.7-0.5
		c-0.6-0.3-1.2-0.6-1.9-0.9c-0.6-0.3-1.2-0.5-1.8-0.8c-0.9-0.5-1.6-1.2-2.2-2c-0.4-0.6-0.4-1-0.3-1.1
		C81.9,335.8,82.9,336.9,84.9,337.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M277.7,284.2c0.3,0.1,0.6,0.1,0.9,0.1c0.8,0,1.5,0,2.2-0.2c0.8-0.1,1.5-0.3,2.3-0.5c0.4-0.1,0.7-0.3,1.1-0.4
		c0.2-0.1,0.5-0.2,0.7-0.4c0.1-0.1,0.2-0.2,0.3-0.4c0.2-0.8,0.8-1.4,1.5-1.7c0.4-0.1,0.7-0.2,1.1-0.3l0.7-0.1l1.2-0.1
		c0.8-0.1,1.5-0.2,2.3-0.3l1.1-0.2c0.5-0.1,1-0.2,1.5-0.2c0.6,0,1.2,0.2,1.8,0.5c0.5,0.4,0.8,0.9,0.9,1.5c0.1,0.5,0.2,1,0.2,1.6
		c0,0.2,0,0.5,0,0.6s-0.1,0.2-0.1,0.4c0,0.1,0,0.3-0.1,0.4c-0.3,0.8-0.8,1.5-1.5,2c-0.5,0.4-1,0.8-1.5,1.2c-1,0.7-1.9,1.2-2.5,1.8
		c-0.2,0.2-0.4,0.4-0.5,0.7c0,0.3,0,0.5,0.2,0.7c0.2,0.2,0.4,0.4,0.6,0.6l0.6,0.6c0.3,0.3,1.2,1,1.1,2c0,0.3-0.1,0.5-0.2,0.7
		c-0.1,0.2-0.2,0.5-0.3,0.7c-0.2,0.4-0.5,0.8-0.7,1.2c-0.1,0.1-0.1,0.3-0.2,0.5c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.3,0.2,0.4l0.2,0.1
		l0.1,0.1c0.5,0.3,0.8,0.8,1,1.3c0.1,0.2,0.1,0.4,0.1,0.7l0,0.5c0,0.5,0.2,1,0.5,1.4c0.2,0.4,0.6,0.7,1,0.8c0.2,0.1,0.4,0.1,0.6,0.1
		c0.3,0,0.6,0,1,0c0.8,0,1.6,0,2.5,0.1c0.5,0.1,1,0.2,1.5,0.5c0.8,0.4,1.5,1.1,1.8,1.9c0.1,0.2,0.1,0.3,0.2,0.5
		c0.1,0.3,0.1,0.5,0.1,0.7c0.1,0.5,0.1,1,0.1,1.4c0,0.5,0.1,1.1,0.4,1.5c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.2
		c0.2,0.1,0.4,0.2,0.6,0.3c0.8,0.2,1.6,0.3,2.4,0.3c0.7,0.1,1.5,0.3,2.1,0.6c0.4,0.2,0.8,0.5,1.1,0.8c0.2,0.3,0.5,0.5,0.7,0.8
		c0.8,1.1,1.3,2.2,1.6,2.8l0.1,0.1c0,0,0.2,0,0.4,0h0.7c1.1-0.1,2.2,0,3.3,0.2c0.6,0.1,1.2,0.3,1.8,0.6c0.5,0.3,1,0.6,1.5,1
		c0.7,0.6,1.3,1.3,1.9,2c0.2,0.4,0.5,0.8,0.9,1.1c0.2,0,0.3,0,0.5,0c0.3-0.1,0.5-0.1,0.7-0.3c0.9-0.5,1.6-1.2,1.9-2.2
		c0.2-0.9,0.3-1.8,0.4-2.7c0.1-0.9,0.2-1.6,0.3-2c-0.4-0.3-0.8-0.6-1.2-1c-0.6-0.6-1.1-1.2-1.5-2c-0.2-0.5-0.4-1-0.5-1.5
		c0-0.6,0.1-1.3,0.3-1.8c0.4-0.9,1-1.8,1.7-2.5c0.5-0.5,1-1,1.4-1.6c0.2-0.3,0.3-0.6,0.3-0.9c0.1-0.4,0.1-0.8,0.1-1.3
		c0-0.9,0-1.9,0.1-3c0.1-0.7,0.3-1.3,0.6-1.9c0.2-0.3,0.4-0.6,0.7-0.9c0.2-0.2,0.4-0.3,0.6-0.5c0.7-0.5,1.3-0.9,1.8-1.3
		c0.8-0.5,1.4-1.2,1.8-2c0.3-0.6,0.4-1.1,0.7-1.1c0.3,0,0.6,0.4,0.8,1.2c0,0.6-0.1,1.2-0.3,1.7c-0.3,0.7-0.7,1.3-1.2,1.9
		c-0.5,0.6-1.1,1.2-1.7,1.7c-0.1,0.1-0.3,0.3-0.4,0.4c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.7,0.1,1.7,0.1,2.8
		c0,0.6,0,1.2-0.1,1.8c-0.1,0.7-0.4,1.5-0.8,2.1c-0.6,0.8-1.2,1.6-2,2.2c-0.5,0.4-0.9,0.8-1.3,1.3l-0.1,0.2c-0.1,0-0.1,0.1-0.1,0.2
		c0,0.2,0,0.4,0,0.6c0.1,0.5,0.4,1,0.7,1.5c0.3,0.5,0.6,0.9,1,1.3l0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.2,0.5,0.5,0.6,0.8
		c0.1,0.1,0.1,0.3,0.1,0.5c0.1,0.1,0,0.1,0.1,0.4c0.1,0.2,0.1,0.4,0.1,0.6c0,0.4,0.1,0.7,0.1,1c0.1,0.7,0.3,1.4,0.5,2.1
		c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0.1,0.1,0.1l0.1,0.1c0.1,0.3,0.2,0.7,0.2,1.1c0,0.7,0,1.3-0.1,2v0.5c0,0.1,0,0.1,0,0.2
		c0,0.1,0.1,0.2,0.2,0.2h0.1c0.1,0,0.1,0,0.4,0.2c0.3,0.1,0.6,0.3,0.9,0.4c0.7,0.2,1.4,0.4,2.1,0.6c0.4,0.1,0.7,0.2,1.1,0.2h0.1
		c0,0,0,0.1,0-0.2c0-1,0.1-1.9,0.4-2.9c0.4-1,1-1.8,1.9-2.4c0.6-0.5,1.3-0.8,1.6-1c0.2-0.1,0.3-0.3,0.3-0.3c0,0,0.1-0.2,0.1-0.3
		c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.4,0.4-0.5c0.4-0.5,1-0.8,1.5-1.1c0.6-0.2,1.3-0.6,1.8-1c0.4-0.5,0.8-1.1,1-1.7
		c0.2-0.4,0.3-0.9,0.3-1.4c0-0.3,0.1-0.5,0.1-0.8c0-0.3,0-0.5,0-0.9c0-0.6,0.2-1.1,0.6-1.5c0.4-0.4,0.8-0.7,1.2-1
		c0.5-0.3,0.9-0.6,1.1-1.1l0.2-0.3c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.3,0.2-0.7,0.3-1.2
		c0.2-0.6,0.5-1.2,0.9-1.7l0.2-0.2l0.1-0.1l0.1-0.1c0,0,0-0.1,0-0.1c0.1-0.3,0.1-0.6,0-1c-0.1-0.6-0.1-1.2-0.1-1.8
		c0-0.2,0-0.4,0-0.5c0-0.2,0.1-0.5,0.2-0.7c0.1-0.4,0.4-0.8,0.7-1.1c0.5-0.5,1-0.8,1.6-1c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0
		l0.1-0.1l0.1-0.6c0.1-0.4,0.2-0.8,0.3-1.2c0.1-0.5,0.4-1,0.7-1.4c0.1-0.1,0.2-0.2,0.3-0.3l0.1,0.1c0.1,0,0.1-0.1,0.1-0.1
		c0.2-0.2,0.3-0.4,0.4-0.6c-0.2-0.1-0.5-0.3-0.7-0.5c-0.5-0.5-1-1.1-1.4-1.8c-0.5-1-1-2.1-1.3-3.2c-0.2-0.5-0.4-1-0.5-1.3
		c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.2-0.3-0.3-0.5l-3.1-4.5c-1.9-2.7-3.3-4.8-4.1-5.8c-1.7-1.7-3.4-3.3-5.2-4.8
		c-2.5-2.1-5.7-4.7-8.9-7.8c-1-1-2-1.9-2.9-2.9c-0.5-0.5-0.9-1-1.3-1.5l-0.3-0.4l-0.3-0.5c-0.1-0.1-0.1-0.1-0.2-0.3l-0.2-0.3
		c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1-0.7-0.2-1.4-0.2-2.1c0-0.6,0.1-1.2,0.1-1.7c0.1-1.1,0.2-2.1,0.4-3c0.3-2.2,0.7-3.8,1.1-4.8
		c-1.1-0.4-1.8-1-1.7-1.5c0.1-0.3,0.5-0.6,1.1-0.8c0-0.3,0-0.6,0-0.9c-0.6-0.2-1.6-1-2.9-2.2c-0.8-0.8-1.8-1.8-2.8-2.8
		c0-0.1-0.1-0.1-0.2-0.1h0l-0.1-0.1c-0.3-0.2-0.5-0.4-0.7-0.7c-0.3-0.4-0.6-0.9-0.9-1.3c-0.5-0.9-0.8-1.9-1.1-2.9
		c-0.2-1-0.3-2.1-0.2-3.2c0.2-1,0.3-1.7,0.5-2.5c0.4-1.5,0.8-2.8,1.2-4c0.4-1.1,0.6-1.9,0.9-2.5c-0.6-0.1-1.1-0.3-1.5-0.7
		c-0.2-0.2-0.5-0.4-0.7-0.6l-0.3-0.3h-0.1c-0.1,0-0.3,0-0.4,0c-0.3,0-0.6-0.1-0.9-0.1c-0.6-0.1-1.2-0.4-1.7-0.7
		c-0.1-0.1-0.3-0.2-0.4-0.3l-0.2-0.2l0-0.1l-0.1-0.1l-0.4-0.1c-0.5-0.1-0.9-0.2-1.4-0.4c-0.3-0.1-0.6-0.2-0.8-0.4
		c0.1,0.2,0.1,0.4,0.2,0.7c0.1,0.4,0.3,0.7,0.5,1.2c0.3,0.6,0.4,1.2,0.5,1.8c0.1,0.7,0.1,1.5,0,2.2c-0.2,0.9-0.6,1.7-1.3,2.4
		l-0.1,0.1c0,0.1,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.1,0.5-0.1,0.7c0.1,0.9,0.1,1.8,0,2.7c-0.1,0.7-0.4,1.3-0.8,1.9l-0.2,0.2
		l0,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.2-0.2,0.4c-0.2,0.5-0.3,1-0.4,1.5c0.2,0.1,0.5,0.3,0.7,0.6c0.6,0.6,1,1.2,1.4,2
		c0.2,0.5,0.4,1,0.5,1.5c0.1,0.3,0.1,0.7,0.1,0.8s0,0.4,0,0.4s0,0.1,0,0c0,0,0.1,0.1,0.1,0.3c0.1,0.2,0.2,0.4,0.3,0.6
		c0.2,0.4,0.3,0.9,0.4,1.3c0.2,1,0.2,2,0,3c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.2,0.5-0.2,0.5c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0.1
		l0.1,0.5c0,0.3,0.1,0.7,0.1,1c0,0.6,0,1.3-0.2,1.9c-0.1,0.3-0.2,0.5-0.3,0.7c-0.1,0.1-0.1,0.2-0.2,0.3l-0.1,0.1h0
		c-0.1,0.1-0.2,0.1-0.3,0.2l0.2,0.5c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.4,0.2,0.8,0.3,1.2c0.1,0.5,0.1,0.9,0,1.4c0,0.2,0,0.4-0.1,0.7
		c0,0.2-0.1,0.4-0.1,0.6c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.1-0.1,0.2-0.1,0.3c0.3,0.3,0.5,0.6,0.6,0.9c0.2,0.4,0.3,0.8,0.4,1.2
		c0.1,0.3,0.1,0.6,0.1,0.9c0,0.2,0,0.3,0,0.5c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1
		c0.1,0,0.1,0,0.1,0l0.3,0.3c0.5,0.6,0.8,1.4,0.8,2.2c0.5,0.1,1.2,0.4,1.9,0.9c1,0.7,1.8,1.8,2.2,3c0.3,0.9,0.2,1.9-0.1,2.8
		c0,0.1-0.1,0.1-0.1,0.2c0.5,0.3,0.9,0.7,1.3,1.2c0.3,0.3,0.5,0.7,0.6,1.1c0.1,0.2,0.2,0.5,0.2,0.7v0.2v0.1c0-0.1,0.2,0.3,0.3,0.6
		c0.1,0.3,0.3,0.7,0.4,1c0.2,0.7,0.3,1.4,0.3,2.1c0,0.6-0.2,1-0.3,1.1c-0.1,0.1-0.1,0-0.1,0.1c0,0.1,0,0.1,0,0.1
		c0,0,0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2,0.1,0.5,0.1,0.7c0,0.4,0,0.7,0,1.1c-0.1,0.4-0.2,0.8-0.3,1.2
		c0,0.1,0,0.2-0.1,0.4l-0.1,0.3c-0.1,0.1-0.1,0.2-0.1,0.3l0.2,0.2l0.1,0.1c0.1,0.1,0.1,0.1,0.1,0.2c0.2,0.4,0.4,0.9,0.5,1.4
		c0.1,0.6,0.1,1.3-0.1,1.9c0,0,0,0,0,0c0.8,0.5,1.4,1.2,1.8,2.1c0.3,0.6,0.4,1.2,0.5,1.8c0.1,0.8,0.1,1.6-0.1,2.4
		c-0.3,0.9-0.9,1.7-1.7,2.3c0.2,0.5,0.3,1,0.3,1.5c0,0.4,0,0.7-0.1,1c-0.1,0.4-0.2,0.8-0.4,1.1v0.2c0,0.1,0,0.3,0,0.4
		c0,0.3,0,0.5,0,0.8c-0.1,0.8-0.4,1.6-1,2.2c-0.2,0.2-0.4,0.4-0.6,0.6c-0.3,0.2-0.2,0.1-0.3,0.1c-0.3,0-0.2,0.2-0.3,0.2v0.3
		c0,0.2,0,0.3,0,0.5c0,0.2-0.1,0.5-0.1,0.7c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0.3-0.2,0.6-0.4,0.8c0,0,0,0.1-0.2,0.2
		c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.1,0.3,0.1,0.4c0.1,0.4,0.1,0.9,0,1.4c0,0.1-0.1,0.2-0.1,0.3
		c0.7,0.4,1.3,1.1,1.6,1.9c0.3,0.6,0.4,1.3,0.4,2c0.1,0.8,0,1.5-0.2,2.3c-0.3,1-0.8,1.8-1.5,2.5c-0.9,0.8-2,1.2-3.2,1.1
		c-1-0.1-2-0.5-2.8-1c-0.7-0.5-1.3-1.1-1.8-1.8c-0.5-0.7-0.8-1.6-0.9-2.5c0-0.2,0-0.5,0-0.7c0-0.2,0.1-0.4,0.1-0.6
		c0.1-0.3,0.2-0.6,0.3-0.8c0.6-1.2,1.3-1.9,1.8-2.2c-0.1-0.4-0.2-0.8-0.1-1.3c0.1-0.5,0.2-0.9,0.5-1.4l0.3-0.4l0.2-0.2
		c0.1-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.1-0.1-0.2l-0.1-0.3c-0.1-0.2-0.2-0.5-0.3-1c-0.1-0.3-0.1-0.7-0.1-1c0-0.2,0-0.5,0.1-0.7
		c0.1-0.2,0.1-0.4,0.2-0.7c0,0,0-0.1,0-0.2c-0.1,0-0.1-0.1-0.2-0.1h-0.1c0,0-0.1,0-0.1-0.1c-0.1-0.2-0.2-0.4-0.2-0.6
		c-0.1-0.7-0.1-1.4,0.2-2c0.1-0.2,0.2-0.4,0.4-0.6l0.2-0.2h0h0.1c0,0,0-0.2,0-0.2c0-0.2-0.1-0.4-0.1-0.5c0-0.4,0-0.7,0-1.1
		c0.1-0.6,0.3-1.1,0.7-1.6c-0.2-0.1-0.3-0.3-0.4-0.4c-0.7-0.7-1.1-1.6-1.2-2.5c0-0.5,0-0.9,0.1-1.4c0.1-0.3,0.2-0.6,0.4-0.8
		c0.8-1.4,1.6-2.1,2.2-2.3c-0.1-0.3-0.2-0.7-0.3-1c0-0.6,0.1-1.1,0.2-1.6c0.1-0.3,0.2-0.6,0.4-0.8c0-0.1,0.1-0.2,0.1-0.2
		c0-0.1-0.1-0.1-0.1-0.2c-0.3-0.4-0.5-0.8-0.7-1.3c-0.2-0.4-0.3-0.9-0.3-1.4c0-0.3,0-0.6,0-0.9c0-0.2,0-0.3,0.1-0.5
		c0-0.1,0-0.1,0-0.2c0,0,0.1,0,0,0c-0.1,0-0.1-0.1-0.1-0.1h-0.1c0,0,0,0.1-0.1,0c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.4-0.4-0.9-0.5-1.4
		c-0.1-0.6-0.1-1.3,0.1-1.9l0.1-0.2c0,0,0,0,0,0c0,0,0,0,0,0h0.1c0,0,0-0.1,0-0.1l-0.2-0.2c-0.1-0.1-0.3-0.2-0.4-0.4
		c-0.4-0.4-0.7-0.9-0.8-1.5c-0.2,0-0.4-0.1-0.6-0.2c-1.3-0.4-2.4-1.4-2.9-2.8c-0.3-0.8-0.3-1.8,0.2-2.6c0.1-0.1,0.1-0.2,0.2-0.2
		c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4-0.4-0.6-0.9-0.7-1.5c-0.1-0.7-0.2-1.4-0.1-2.1c0-0.4,0.1-0.8,0.2-1.1c0-0.1,0.1-0.1,0-0.2
		c-0.1-0.1-0.1-0.1-0.1-0.1c-0.3-0.4-0.6-0.9-0.7-1.4c-0.1-0.3-0.2-0.5-0.2-0.7c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.2-0.1-0.5-0.1-0.7
		c0-0.2,0-0.3,0-0.5c0.1-0.2,0.1-0.5,0.1-0.5c0,0,0,0,0-0.2c-0.1,0-0.1-0.1-0.1-0.2l-0.1,0l-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.3
		c-0.2-0.3-0.3-0.7-0.5-1.1c-0.1-0.5-0.2-1.1-0.2-1.7c0-0.4,0.1-0.7,0.1-1.1l0.1-0.5c0,0,0-0.1,0-0.1H304l-0.1-0.2
		c-0.1-0.1-0.1-0.3-0.2-0.4c-0.3-0.8-0.5-1.6-0.5-2.4c0-0.7,0-1.4,0.3-2c0.1-0.3,0.2-0.6,0.4-0.9l0.1-0.2c0,0,0,0.1,0-0.1
		c0-0.2-0.1-0.5-0.1-0.7c0-0.5-0.1-0.9,0-1.4c0,0,0-0.1,0-0.1c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,0.4-0.1,0.9-0.2,1.5
		c-0.1,1-0.3,2-0.6,3c-0.2,0.5-0.4,1-0.8,1.5l-0.2,0.3l-0.2,0.2l-0.3,0.5c-0.4,0.5-0.7,1.1-0.8,1.8c-0.2,0.8-0.4,1.7-0.7,2.5
		c-0.3,0.9-0.8,1.7-1.3,2.5c-0.5,0.7-1.2,1.3-1.8,1.8c-1.2,1-2.4,1.7-2.5,2.4c-0.4,1-0.4,2.3-0.7,3.5c-0.2,0.7-0.5,1.3-1,1.9
		c-0.2,0.2-0.5,0.4-0.7,0.6c-0.2,0.2-0.5,0.3-0.7,0.4c-0.7,0.4-1.4,0.8-2,1.3c-0.4,0.4-0.6,0.9-0.7,1.4c-0.1,0.7-0.3,1.3-0.5,2
		c-0.4,0.9-1,1.6-1.7,2.3c-0.4,0.3-0.8,0.7-1.2,1l-0.7,0.5l-0.3,0.3l-0.2,0.2c-0.5,0.5-0.4,1.5-0.5,3c0,0.4-0.1,0.9-0.3,1.3
		c-0.2,0.5-0.5,1-0.9,1.4c-0.2,0.2-0.4,0.4-0.7,0.6l-0.3,0.2c-0.2,0.1-0.3,0.3-0.4,0.5c-0.2,0.4-0.4,1-0.9,2c-0.3,0.5-0.7,1-1.1,1.4
		c-0.2,0.2-0.5,0.4-0.8,0.5c-0.1,0-0.1,0.1-0.2,0.1c0,0.1-0.1,0.1-0.1,0.1c-0.3,0.2-0.4,0.6-0.5,0.9c-0.1,0.3,0,0.7,0.3,0.9
		C277.4,284.2,277.4,284.2,277.7,284.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M270.9,287.7c-0.2,0.2-0.4,0.4-0.6,0.5c0.2,0,0.3,0,0.5,0C270.8,288.1,270.9,287.9,270.9,287.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M280.2,275.2c0-0.1,0.2-0.1,0.2-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.5,0.2-0.7c0.1-0.6,0.2-1.3,0.4-2
		c0.1-0.4,0.2-0.7,0.4-1c-0.8,1.6-1.7,3.3-2.6,4.9c0.2-0.2,0.4-0.3,0.6-0.4C279.7,275.4,280,275.3,280.2,275.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M73.6,337.8c0.3-0.2,0.6-0.4,0.8-0.7c0.6-0.5,1.2-1.1,1.8-1.7c1.2-1.2,2.4-2.5,3.9-3.7
		c0.4-0.3,0.8-0.6,1.3-0.9c0.3-0.1,0.6-0.3,0.9-0.3l0.6-0.1h0.4c0.7,0.1,1.3,0.4,1.8,0.8c0.4,0.4,0.6,0.7,0.9,1
		c0.5,0.5,1.1,0.9,1.8,1.1l2.2,0.9c0.2,0.1,0.4,0.2,0.6,0.3c0.2-0.3,0.5-0.6,1-1c0.7-0.5,1.4-1,2.3-1.3c0.5-0.2,1-0.4,1.5-0.5
		l0.8-0.2l0.4-0.1l0.3-0.4c0.2-0.3,0.4-0.5,0.6-0.8c1.4-1.6,3.1-2.7,5.1-3.3c1.2-0.3,2.3-0.5,3.5-0.5c0.2,0,0.5,0,0.7-0.1
		c1.4-2.6,3.7-4.5,6.5-5.4c0.8-0.2,1.5-0.4,2.3-0.4c0.7,0,1.3,0,2,0.1l0.8,0.2c0.2,0.1,0.5,0.1,0.7,0.2l0.3,0.1l0.1,0.1l0.2,0.1
		l0.3-0.3c0.2-0.1,0.2-0.1,0.4-0.3s0.5-0.3,0.7-0.5l0.8-0.5c0.9-0.7,1.8-1.2,2.8-1.7c0.5-0.2,1-0.4,1.5-0.5
		c-0.2-0.1-0.4-0.1-0.6-0.2c-2-0.5-4.2-0.8-6.3-0.7c-1.4,0.1-2.8,0.4-4.2,0.8c-1.5,0.5-3,1.2-4.7,1.9c-0.9,0.4-1.9,0.7-2.8,0.9
		c-0.5,0.1-1.1,0.2-1.6,0.2H105c-0.3,0-0.6-0.1-0.8-0.1c-2.1-0.5-4-1.3-5.9-2.3c-1.8-0.8-3.7-1.5-5.6-2c-1.8-0.4-3.6-0.7-5.4-0.7
		c-3.1,0.1-6.2,0.7-9.1,1.8c-3.2,1.2-5.5,2.4-6.9,3c-0.2,1.2-0.5,2.4-0.7,3.7c-0.4,2-0.6,4.1-0.5,6.2c0.1,1.7,0.4,3.4,0.8,5
		c0.4,1.6,0.8,2.9,1,3.7c0.2-0.2,0.5-0.3,0.8-0.5C73,338.2,73.3,338,73.6,337.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M132.8,312.8c-3.5-1.5-7.2-2.4-11-2.6c-1.7-0.1-3.4,0-5,0.3c-1.5,0.3-2.9,0.6-4.3,1.1c-1.2,0.4-2.4,0.7-3.6,1
		c-0.8,0.1-1.6,0.2-2.4,0.2c-0.8,0.1-1.6,0.1-2.4,0c-1.2-0.1-2.5-0.4-3.6-0.9c-2.7-1-5.7-2.6-9.1-3.2c-3.1-0.5-6.3-0.4-9.4,0.3
		c-1.9,0.4-3.8,1-5.5,1.8c-0.7,0.4-1.3,0.8-1.9,1.2c-0.4,0.3-0.8,0.6-1.3,1c-0.2,0.2-0.4,0.4-0.5,0.6c-0.1,0.1-0.1,0.2-0.1,0.2
		l-0.1,0.5c-0.3,1.5-0.7,3.2-1.1,5c1.3-0.9,3.3-2.2,6.1-3.5c3-1.4,6.3-2.2,9.7-2.4c2-0.1,4,0.1,5.9,0.6c2.1,0.5,4.1,1.2,6,2.1
		c1.8,0.9,3.6,1.6,5.5,2.2c0.2,0,0.4,0.1,0.6,0.1h0.6c0.4,0,0.9-0.1,1.3-0.2c0.9-0.2,1.7-0.4,2.5-0.7c1.6-0.6,3.3-1.3,4.9-1.8
		c1.5-0.4,3.1-0.7,4.7-0.6c2.3,0,4.5,0.5,6.6,1.4c0.5,0.2,0.9,0.5,1.2,0.7c0,0,0,0,0.1,0c0.5-0.1,1-0.1,1.5-0.1c0.3,0,0.5,0,0.8,0
		h0.2h0.1l0.1-0.1c1.8-1.5,4-2.6,6.3-3.1C135.4,313.8,134.3,313.3,132.8,312.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M84.6,351.5C84.6,351.5,84.5,351.5,84.6,351.5c-0.2,0.1-0.3,0.2-0.4,0.3C84.3,351.7,84.5,351.6,84.6,351.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M69,343.3c-0.2,0.2-0.4,0.5-0.7,0.9c-1.1,1.6-1.8,3.5-1.8,5.5c0,2,0.6,3.9,1.7,5.6c0.3,0.6,0.6,1,0.8,1.4
		c0.2-0.5,0.4-0.9,0.7-1.3c0.3-0.5,0.7-1,1.1-1.5c0.2-0.2,0.4-0.4,0.6-0.6l0.3-0.3l0.1-0.1c0-0.1,0-0.1,0.1-0.2
		c-0.3-0.2-0.6-0.6-1-1c-1.3-1.6-2-3.5-2-5.5c0-0.7,0-1.3,0.1-2C68.9,343.9,69,343.6,69,343.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M50,413.9c-0.1-0.2-0.2-0.4-0.4-0.4c-0.1,0.1-0.2,0.2-0.3,0.4c0,0.1,0,0.1-0.1,0.2l-0.1,0.1l0.3,0.1
		C49.6,414.1,49.8,414,50,413.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M49.7,161.9c-1.7,0.9-3,1.4-3.7,1.7c-0.5,0.2-0.9,0.5-1.3,0.8c0,0-0.1,0.1-0.1,0.1c0.9-0.3,2.1-0.7,3.5-1.2
		c2.1-0.8,3.6-1.8,4.3-2.1c-0.1-0.2-0.2-0.3-0.2-0.4c-0.4,0.1-0.8,0.3-1.2,0.5L49.7,161.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M50.9,176.4c0.6,0.5,1.3,0.8,2,0.9c0.7-0.1,1.4-0.4,2-0.8c-0.6,0.1-1.2,0.2-1.9,0.2
		C52.2,176.7,51.5,176.6,50.9,176.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M45.8,163.1c1.1-0.7,2.2-1.5,3.2-2.3c0.4-0.3,0.8-0.6,1.2-0.9c0.5-0.4,1-0.7,1.6-0.9c0.4-0.1,0.8-0.2,1.2-0.2
		c0.3,0,0.7,0.1,1,0.2c0.6,0.1,1.2,0.3,1.8,0.5c1.2,0.5,2.3,1.1,3.3,1.9l2.4,2c1.1,0.9,2,1.9,2.8,3.1c0.9,1.1,1.5,2.3,2,3.7
		c0.6,2.3,0.7,4.7,0.2,7c-0.5,2.7-1.7,5.3-3.3,7.5c-0.5,0.7-1.1,1.2-1.5,1.6c0.5,0.3,1.1,0.6,1.6,0.9c1,0.6,2,1.2,2.7,1.8
		c-0.1-0.7-0.1-1.7,0-3c0.1-1.5,0.3-2.9,0.5-4.4c0.2-0.8,0.3-1.7,0.5-2.5c0.2-0.8,0.3-1.7,0.4-2.6c0.3-2.7,0.4-5.3,0.3-8
		c-0.1-1.6-0.4-3.2-0.9-4.7c-0.7-1.4-1.7-2.7-2.8-3.9c-1.8-1.9-3.8-3.6-6.1-4.9c-1.1-0.7-2.4-1.1-3.7-1.3c-1.2-0.1-2.4,0-3.5,0.2
		c-1.6,0.3-3.1,0.9-4.4,1.7c-1.3,0.9-2.5,2.1-3.5,3.3c-0.8,0.9-1.6,1.9-2.3,2.9c-0.8,1.2-1.5,2.4-2.1,3.7c-0.8,1.5-1.5,3.1-2,4.7
		c-0.4,1.3-0.7,2.6-0.7,4c0,1,0,1.9,0,2.6c0.1,0.2,0.3,0.3,0.4,0.5c-0.2-0.9-0.3-1.8-0.3-2.7c0-0.8,0.1-1.6,0.3-2.3
		c0.2-0.8,0.6-1.6,1-2.3C39.2,166.8,42.2,164.4,45.8,163.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M44.4,187.4c-0.5,0.2-0.9,0.4-1.2,0.5c0.1,0.1,0.2,0.3,0.2,0.4c0.3,0.5,0.5,0.9,0.8,1.4
		c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.5-0.1-1,0.1-1.4C44.1,187.7,44.3,187.6,44.4,187.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M46.6,184.5c0-0.4,0-0.9,0-1.4c0-1.6,0.1-3,0.3-3.8c-0.7-0.6-1.3-1.2-1.8-1.8c-1.4-1.8-2.3-4-2.4-6.2
		c-0.1-1.5,0-2.9,0.3-4.4c0.2-1,0.5-1.5,0.6-1.5c0.2,0,0.2,0.6,0.2,1.6c0,1.4,0.1,2.7,0.3,4.1c0.3,1.9,1.1,3.7,2.4,5.2
		c0.8,0.8,1.6,1.6,2.5,2.2c1,0.6,2.2,0.8,3.3,0.8h1c0.3,0,0.5,0,0.8-0.1c0.5-0.1,1.1-0.3,1.6-0.6c1-0.5,1.9-1.2,2.7-2
		c1.4-1.4,2.4-3.1,2.9-4.9c0.3-1.3,0.4-2.7,0.3-4c0-0.4,0-0.7,0-0.9c-0.8-0.1-1.6-0.4-2.3-0.9c-0.8-0.7-1.4-1.6-1.6-2.7
		c0-0.1,0-0.2,0-0.3c-0.9-0.5-1.8-1-2.7-1.4c-0.5-0.2-1-0.4-1.6-0.6c-0.2-0.1-0.4-0.1-0.6-0.2c0,0,0.1,0.1,0.1,0.1
		c0.6,0.3,1.2,0.6,1.8,0.8c1.5,0.6,2.8,1,2.8,1.3c0,0.3-1.5,0.4-3.1-0.3c-0.7-0.2-1.3-0.6-1.8-1.2c-0.2,0.2-0.5,0.5-0.9,0.9
		c-1,0.8-2.1,1.4-3.3,1.9c-2,0.8-3.9,1-4.7,1c-0.3,0.2-0.5,0.5-0.8,0.7c-1.5,1.5-2.8,3.2-4,5c-0.7,1.1-1.1,2.3-1.2,3.6
		c-0.1,1.1-0.1,2.2,0,3.4c0,0.6,0.1,1.2,0.1,1.7c1.6,2.3,3.1,4.6,4.5,7c0.4-0.5,1-1,1.7-1.4C45,184.7,45.9,184.5,46.6,184.5z
		 M56.3,176.1c-0.1,0.2-0.2,0.3-0.4,0.5c-0.3,0.4-0.7,0.8-1.1,1.1c-0.5,0.4-1.2,0.6-1.9,0.7c-0.7,0-1.4-0.3-1.9-0.7
		c-0.4-0.3-0.7-0.7-1-1.1c-0.2-0.2-0.3-0.4-0.4-0.6c-0.7-0.3-1-0.6-0.9-0.9c0.2-0.6,2.1-0.5,4.3-0.5c2.3,0,4.2,0,4.3,0.5
		C57.5,175.4,57,175.8,56.3,176.1z M58.4,165.9c0.6,0.1,1.1,0.4,1.6,0.8c0.3,0.3,0.4,0.6,0.3,0.7c-0.2,0.2-0.6-0.1-1.4-0.3
		c0.1,0.1,0.1,0.3,0.1,0.4c0,0.5-0.5,1-1.1,1c-0.6,0-1.1-0.4-1.1-1c0-0.3,0.2-0.6,0.4-0.8c-0.8-0.1-1.3-0.2-1.3-0.4
		C55.9,166.1,57,165.6,58.4,165.9z M52.6,173c0.5,0,0.9-0.1,1.4-0.3c0.3-0.1,0.3-0.2,0.3-0.3c0-0.3,0-0.5-0.1-0.8
		c-0.3-0.6-0.4-1.3-0.5-2c-0.1-0.6,0-1.2,0.2-1.8c0.4-1,0.9-1.3,1-1.3c0.1,0.1-0.2,0.6-0.3,1.5c-0.1,0.5-0.1,1,0,1.5
		c0.2,0.6,0.4,1.2,0.6,1.8c0.1,0.4,0.2,0.8,0.1,1.3c0,0.3-0.2,0.5-0.4,0.7c-0.2,0.1-0.4,0.3-0.6,0.3c-0.6,0.2-1.2,0.2-1.8,0.1
		c-1-0.3-1.4-0.8-1.3-0.9C51.3,172.7,51.8,172.9,52.6,173z M46,166.6c0.5-0.4,1.1-0.6,1.7-0.7c1.5-0.3,2.6,0.2,2.5,0.4
		c0,0.2-0.6,0.3-1.3,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.5-0.5,1-1.1,1c-0.6,0-1-0.4-1.1-0.9c-0.7,0.2-1.2,0.3-1.3,0.2
		C45.5,167.2,45.7,166.9,46,166.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M60.8,164.6l-1.3-0.7c-0.2-0.1-0.5-0.3-0.7-0.4c0.3,0.6,0.7,1.1,1.3,1.5c1.2,0.9,2.6,1.2,3.2,1.4
		C62.5,165.6,61.6,165,60.8,164.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M57.6,186.4C57.6,186.4,57.6,186.4,57.6,186.4c-0.2,0.5-0.3,0.9-0.6,0.9c-0.6,0.1-1.3-1.8-1.2-4.2
		c0-0.9,0.2-1.8,0.4-2.4c-0.5,0.2-1,0.4-1.6,0.6c-0.4,0.1-0.8,0.1-1.2,0.2c-0.3,0-0.7,0-1.1,0c-0.7,0-1.5-0.1-2.2-0.3
		c-0.6-0.1-1.2-0.4-1.7-0.7c0.2,0.8,0.3,1.7,0.3,2.7c-0.1,2.6-1.1,4.6-1.6,4.5c-0.2-0.1-0.3-0.4-0.4-0.9c-0.1,0.2-0.3,0.3-0.5,0.5
		c-0.3,0.3-0.5,0.7-0.7,1.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.2,0.4,0.4,0.6c0.4,0.5,1,0.8,1.6,1.1c1.4,0.6,3,1,4.6,0.9
		c1.6,0,3.2-0.2,4.7-0.7c0.6-0.2,1.1-0.6,1.5-1c0.3-0.3,0.5-0.7,0.4-1.1C58.5,187.9,58.1,187.1,57.6,186.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M41.1,506.9l0.8-1.6c-0.4,0.5-0.7,1-1.1,1.5c0,0.2,0.1,0.4,0.1,0.6L41.1,506.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M64.4,176.8c0.5-2.1,0.6-4.2,0.5-6.3c-0.1-1.3-0.5-2.5-1.2-3.6c-0.1-0.1-0.2-0.2-0.3-0.3
		c-0.1,0.1-0.5,0.2-1.2,0.2c0,0-0.1,0-0.1,0c0.1,0.2,0.2,0.4,0.2,0.7c0.4,1.4,0.5,2.9,0.4,4.4c-0.4,2.3-1.4,4.3-2.9,6
		c-0.6,0.7-1.3,1.3-2.1,1.9c0.2,0.8,0.2,2,0.2,3.4c0,0.5-0.1,1.1-0.1,1.5c0.7,0.2,1.8,0.6,3,1.1c0.3-0.5,0.6-1.2,1.1-2
		C63.1,181.7,63.9,179.3,64.4,176.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M451.3,196.6L451.3,196.6c0.5,0.3,0.9,0.8,1.1,1.3c0.2,0.4,0.4,0.7,0.5,1c0.1,0.2,0.2,0.4,0.3,0.6l0.1,0.1
		l0.1,0.1c0.2,0.1,0.4,0.3,0.5,0.4c0.3,0.2,0.6,0.5,0.8,0.8c0.5,0.5,0.9,0.9,1.3,1.4l0.6,0.6c0.2,0.2,0.5,0.5,0.7,0.7
		c0.4,0.5,0.7,1.1,0.9,1.7c0.2,1,0.2,1.9,0,2.9c0,0.2,0,0.5,0.2,0.7c0.1,0.1,0.1,0.1,0.2,0.2c0-0.9,0.3-2.5,1-4.7
		c0.3-0.8,0.6-1.7,1-2.6c0.2-0.5,0.4-0.9,0.7-1.4c0.3-0.7,0.7-1.3,1.1-1.8c0.6-0.8,1.3-1.3,2.1-0.9c0.3,0.2,0.5,0.5,0.6,0.8
		c0.4-1.5,0.8-2.9,1.2-4.3c0.1-0.3,0.1-0.6,0.2-0.9v-0.2V193v0c0,0,0.1-0.2,0-0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.2,0.2
		l-0.3,0.2l-0.5,0.4c-1.7,1.4-2.9,2.5-3.7,3.2c-0.5,1.1-0.9,2.2-1.3,3.3c-0.2,0.9-0.5,1.8-0.9,2.7c-0.1,0-0.2-1,0.2-2.8
		c0.6-2.5,1.4-5,2.5-7.3c0.7-1.5,1.5-3.1,2.4-4.9c1-1.9,2.2-3.7,3.5-5.3c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.4-0.3,0.6-0.5
		c0.3-0.3,0.8-0.5,1.3-0.5c0.4,0.1,0.8,0.4,1,0.8l0.3,0.7c0,0.1,0.1,0.2,0.1,0.4l0.1,0.2c0.2,1.1,0.4,2.2,0.5,3.4
		c0.1-0.4,0.2-0.8,0.2-1.2c0.6-2.9,0.9-5.9,1-8.9c0-0.7,0-1.3-0.2-2c-0.1-0.6-0.4-1.1-0.5-1.1c-0.1,0-0.1,0-0.3,0
		c-0.2,0.1-0.5,0.2-0.7,0.3c-0.5,0.3-1,0.8-1.5,1.2c-1.8,1.7-3.6,3.6-5.2,5.6c-1.5,1.8-2.9,3.6-4.1,5.3c-4.8,6.6-7.4,11-7.7,10.9
		c-0.3-0.1,0.3-1.1,1.3-3c0.1-1.4,0.3-4,0.2-7.5c-0.1-3.4-0.4-6.8-0.9-10.1c0,2.4,0,4.9-0.1,7.3c-0.1,1.7-0.3,3.3-0.6,5
		c-0.2,0.8-0.3,1.6-0.5,2.4c-0.2,0.8-0.5,1.7-0.8,2.5l-1,2.3c0.1,0.2,0.2,0.5,0.3,0.7C451.2,196.4,451.3,196.5,451.3,196.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M401.6,161.7c-2,2.8-4.4,5.7-7,8.8c-0.7,0.8-1.3,1.6-2.1,2.3s-1.5,1.5-2.3,2.3c-1.6,1.5-3.2,2.9-5,4.4
		c-2.2,1.8-4.3,3.4-6.6,4.9c-0.6,0.4-1.1,0.7-1.7,1.1l-1.7,1c-1.1,0.6-2.2,1.2-3.3,1.8c-3.8,2-7.7,3.8-11.7,5.4
		c-4.5,1.8-8,2.7-9.9,3c0.2,0.4,0.4,0.7,0.6,1.1l-3.8,5.5l-2.9,4.2l-2.9,4.2c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0.1,0,0.1,0
		c0.4-0.1,0.8-0.2,1.2-0.4c0.6-0.3,1.1-0.7,1.4-1.2c0.3,0,0.6,0.1,1,0.1h0.8c0.5,0,1-0.1,1.6-0.2c0.5-0.1,1.1-0.3,1.6-0.5
		c0.7-0.4,1.3-0.8,1.8-1.4c0.4,0.1,0.9,0.2,1.3,0.2h0.8c0.5,0,1.1,0,1.6-0.1c0.5-0.1,1-0.2,1.5-0.4c0.2-0.1,0.4-0.2,0.6-0.3l0.1-0.1
		l0.1-0.1l0.2-0.2l0.6-0.6l0.9,0.2c0.1,0,0.3,0.1,0.5,0.1h0.3c0.3,0,0.6,0,1,0c0.6,0,1.3-0.1,1.9-0.2c0.6-0.1,1.2-0.3,1.7-0.6
		c0.3-0.1,0.5-0.3,0.7-0.5c0.1-0.1,0.2-0.2,0.3-0.3l0.2-0.2c0.1-0.2,0.3-0.3,0.4-0.5l1-0.4l1.1-0.3l0.4,0.1l0.3,0.1l0.3,0
		c0.2,0,0.4,0,0.6,0c0.4,0,0.8-0.1,1.2-0.2c0.4-0.1,0.7-0.3,1.1-0.6c0.2-0.1,0.3-0.3,0.5-0.4l1.1-1.3c0.6,0.1,1.3,0.2,1.7,0.2
		c0.3,0,0.5,0,0.8,0c0.5,0,1-0.1,1.5-0.2c0.4-0.1,0.8-0.3,1.2-0.6c0.2-0.1,0.3-0.3,0.4-0.4l0.2-0.3v-0.1c0,0,0,0,0.1-0.1v-0.1
		l0.2-0.4c0.1-0.3,0.3-0.6,0.4-0.9l1-0.1l0.5,0h0.3h0.1c0.1,0,0.3,0,0.4-0.1c0.5-0.1,1-0.3,1.5-0.6c0.4-0.2,0.8-0.5,1.1-0.9
		c0.2-0.2,0.3-0.4,0.4-0.6l0.2-0.4l0.1-0.2l0.2-0.5l0.8-0.7l0.8-0.7c0.2,0,0.4-0.1,0.6-0.1c0.1,0,0.1,0,0.2-0.1
		c0.1,0,0.2-0.1,0.4-0.1c0.2-0.1,0.4-0.2,0.6-0.3c0.1-0.1,0.3-0.3,0.4-0.4l0.1-0.1c0-0.1,0.1-0.3,0.1-0.4l0.1-0.5l0.1-0.6l0.8-0.3
		c0.3-0.1,0.6-0.2,0.8-0.3c0.3-0.1,0.7-0.1,1-0.2c0.2-0.1,0.4-0.1,0.6-0.2c0.4-0.2,0.8-0.4,1.1-0.6c0.3-0.2,0.6-0.5,0.9-0.8
		c0.1-0.2,0.2-0.3,0.3-0.5l0.2-0.3c0-0.1,0.1-0.2,0.1-0.3l0.1-0.3l0.6-0.6l0.6-0.6l0.2-0.1l0.2-0.1l0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.2
		c0.2-0.1,0.4-0.3,0.6-0.5c0.2-0.2,0.3-0.4,0.5-0.6l0.2-0.3l0.1-0.2c0.1-0.2,0.1-0.3,0.2-0.5l0.9-0.6l0.8-0.6l0.3-0.1
		c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.2,0.4-0.5,0.6-0.7c0.2-0.3,0.3-0.7,0.5-1c0.1-0.2,0.1-0.4,0.2-0.6
		c0.1-0.4,0.2-0.7,0.2-1.1l0.8-0.8l0.8-0.8l0.4-0.1l0.2-0.1l0.2-0.1c0.1-0.1,0.3-0.1,0.4-0.2c0.2-0.2,0.5-0.4,0.6-0.7
		c0.2-0.3,0.3-0.6,0.4-0.9c0.1-0.2,0.1-0.4,0.1-0.5l0-0.4v-0.7c0.3-0.3,0.5-0.6,0.7-0.9l0.8-0.8c0.2-0.1,0.4-0.1,0.6-0.2
		c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.3-0.2,0.5-0.3c0.3-0.2,0.5-0.6,0.6-0.9c0.1-0.4,0.2-0.8,0.2-1.3c0-0.2,0-0.5,0-0.7v-0.2l-0.1-0.3
		l-0.1-0.6l0.2-0.8l0.2-0.7l0.3-0.3l0.1-0.2l0.2-0.3c0.1-0.2,0.3-0.4,0.4-0.7c0.2-0.5,0.5-0.9,0.6-1.4c0.3-0.9,0.5-1.9,0.5-2.8
		c0-0.8-0.3-1.6-0.8-2.3c-0.2-0.3-0.5-0.6-0.8-0.9c-0.1-0.1-0.3-0.3-0.5-0.4c-0.2-0.1-0.4-0.3-0.6-0.4l-0.4-0.4
		c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.4-0.2-0.7-0.4-1.1c-0.1-0.3-0.3-0.5-0.4-0.8c-0.1-0.2-0.3-0.4-0.5-0.6c-0.1-0.1-0.2-0.1-0.3-0.2
		c0,0-0.1-0.1-0.1-0.1h-0.1v-0.1c-0.3-0.1-0.6-0.2-0.9-0.3c-0.2,0-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.2-0.3-0.4l-0.1-0.3l-0.1-0.2
		c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.3-0.3-0.4-0.5c-0.2-0.2-0.5-0.5-0.8-0.6c-0.1,0-0.1-0.1-0.2-0.1c0,0.9-0.1,1.9-0.2,2.8
		c-0.2,1.4-0.5,2.7-0.9,4C403.3,158.8,402.6,160.3,401.6,161.7z"
          />
          <polygon class="st1" points="440.8,187.2 440.6,187.4 440.9,187.2 	" />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M449.9,198.6C449.9,198.6,449.9,198.6,449.9,198.6C449.9,198.6,449.9,198.6,449.9,198.6
		C449.9,198.6,449.9,198.6,449.9,198.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M43.3,127.6c-0.2-1.9-0.6-3.9-1-5.8c-0.1-0.3-0.2-0.5-0.3-0.8c-0.1-0.2-0.1-0.1-0.1-0.1h-0.1
		c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.3,0.2-0.4,0.3c-1,1-2,2.1-2.8,3.3c-1.6,2.3-3.1,4.7-4.4,7.2
		c-0.1,0.3-0.3,0.5-0.4,0.8c1.8-0.1,5.6-0.3,11-0.4C43.8,131,43.5,129.3,43.3,127.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M450.9,197.1c0-0.1,0.1-0.2,0.1-0.2c0,0-0.1,0-0.1,0.1L450.9,197.1L450.9,197.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M448.5,191.5c0.5,0.6,1,1.3,1.4,2l0-0.8c0.1-1.8,0-3.6-0.3-5.4c-0.3-1.7-0.7-3.4-1.2-5.1c0,0,0-0.1,0-0.1
		c0.2,1.8,0.3,3.5,0.3,5.3C448.8,189.2,448.6,190.5,448.5,191.5L448.5,191.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M174.9,671.8c-1,1.4-2.4,3.6-4.1,6.3c-0.9,1.4-1.8,2.9-2.8,4.6c-0.5,0.9-1,1.7-1.5,2.6
		c-0.2,0.5-0.5,0.9-0.7,1.4l-0.1,0.3c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.2c0.3,1,0.7,1.9,1.3,2.7
		c0.6,0.9,1.3,1.9,2.1,2.8c0.8,0.9,1.6,1.9,2.4,2.8c0.9,0.9,1.8,1.8,2.8,2.5c2,1.7,4.3,3.3,6.7,4.8l0.5,0.3l0.2,0.1l-0.2-0.6l0-0.2
		l-0.2-1.3l-0.4-2.8c-0.2-0.9-0.2-1.8-0.3-2.8c-0.1-0.9-0.1-1.8-0.2-2.7c-0.2-1.8-0.1-3.6-0.2-5.3s0.1-3.4,0.1-5
		c0.3-5.8,1-11.5,2.2-17.2c0.7-3.2,1.3-5.9,2-8.1c-1.9,3-3.6,5.6-5.2,7.8C177.4,668.3,175.9,670.4,174.9,671.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M35.2,137l4.2-3.4c-3.2-0.1-5.4-0.2-6.6-0.3c-1,1.9-1.9,3.7-2.8,5.7c-1,2.2-1.7,4.3-2.4,6.2
		c-0.3,1-0.6,2-0.9,2.9c0-0.1,0.1-0.2,0.1-0.2C28.7,143.7,31.6,139.9,35.2,137z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M48.1,415.1c-0.4,0.4-0.7,0.8-1.1,1.2l-2.5,3l0.2-2.7l0.1-2.2v-0.5c0-0.1,0-0.1,0-0.2l-0.1-0.4
		c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.2-0.4-0.3-0.6c-0.4-0.7-1.1-1.3-1.8-1.6c-0.3-0.2-0.6-0.3-0.9-0.4c0.2,0.3,0.3,0.6,0.5,0.9
		c0.5,1.2,0.6,2,0.3,2.1c-0.3,0.1-0.8-0.5-1.6-1.3c-0.9-1.1-2-2-3.3-2.6c-0.3-0.1-0.7-0.2-1-0.2c-0.4,0-0.8,0-1.2,0
		c-0.4,0-0.9,0-1.3,0.1c0.3,0.2,0.6,0.4,0.9,0.6c1,0.8,1.2,1.6,0.9,1.8c-0.2,0.2-0.8-0.2-1.7-0.5c-1.1-0.5-2.3-0.6-3.4-0.5
		c-1,0.2-2,0.5-2.8,1c0.3,0,0.6,0.1,0.9,0.2c0.9,0.3,1.4,0.7,1.3,1c-0.1,0.6-2.3,0.5-4.5,1c-0.4,0.1-0.9,0.2-1.3,0.4
		c0.3,0.1,0.7,0.1,1,0.3c0.9,0.4,1.4,0.8,1.4,1c-0.1,0.6-2.5,0.3-4.8,0.9c-1,0.2-1.8,0.8-2.5,1.5l0,0c0.1,0,0.3,0,0.4,0
		c1.4,0,2.2,0.3,2.2,0.6c0,0.3-0.8,0.5-2,0.8c-1.5,0.3-2.9,1-4.1,1.9c-0.6,0.5-1.1,1.1-1.6,1.8c-0.4,0.6-0.7,1.2-1,1.8
		c-0.1,0.1-0.1,0.3-0.2,0.4c0.3-0.1,0.5-0.2,0.8-0.3l1.9-0.7l3.2-1.1c1.9-0.6,2.9-1,3.1-0.8c0.3,0.3-0.7,1.2-2.5,2.2
		c-1,0.6-2.1,1.1-3.2,1.5c-0.6,0.3-1.2,0.5-1.9,0.8c-0.7,0.3-1.4,0.5-2,0.8c-1.2,0.5-2.3,1.1-3.2,2c-0.7,0.7-1.2,1.5-1.5,2.5
		c-0.1,0.2-0.1,0.4-0.1,0.6c0.4-0.7,1-1.3,1.7-1.7c0.8-0.5,1.4-0.6,1.6-0.3c0.2,0.3-0.1,0.7-0.5,1.4c-0.5,0.8-1,1.7-1.4,2.5
		c-0.3,0.6-0.5,1.2-0.6,1.7c0.1-0.1,0.2-0.2,0.3-0.2c0.3-0.2,0.6-0.3,0.8-0.4c0.2-0.1,0.5-0.2,0.7-0.2c0.3-0.2,0.6-0.1,0.6,0
		c0.3,0.6-2.6,2.2-3.5,5.6c-0.1,0.4-0.2,0.8-0.3,1.2c0,0.1,0,0.2,0,0.3v0.2c0,0.1,0,0.1,0,0.1c0,0.1-0.1,0.2-0.1,0.3v0.2
		c0,0,0,0,0,0c0,0,0,0,0.1,0c0-0.1,0-0.2,0.1-0.2c0.4-1.4,1.1-2.7,2.2-3.6c0.8-0.7,1.5-0.9,1.7-0.7c0.4,0.5-1.2,2.3-1.9,4.9
		c-0.1,0.5-0.2,1.1-0.3,1.6c0.3-0.5,0.6-1,1-1.4c0.6-0.6,0.9-0.8,1-0.8c0.3,0.2-0.4,1.5-1.1,3.8c-0.4,1.3-0.6,2.6-0.6,4
		c0,0.8,0.1,1.6,0.3,2.3c0.1,0.4,0.2,0.8,0.3,1.2c0.1,0.3,0.1,0.2,0.1,0.2c0,0,0,0,0-0.1c0.3-0.8,0.6-1.5,0.9-2.1
		c0.5-1.1,1.2-2.2,2-3.2c0.8-1,1.5-1.6,2-1.9c0-0.6,0.1-1.2,0.3-1.8c0.4-1.2,1-2.2,1.9-3.1c0.7-0.6,1.3-0.8,1.5-0.6
		c0.5,0.4-0.7,2.2-1.4,4.4c-0.3,0.9-0.4,1.7-0.5,2.4c0.2-0.2,0.3-0.4,0.5-0.5c1.7-1.7,3.1-2.2,3.3-2c0.2,0.3-0.9,1.2-2.2,3
		c-0.8,1-1.4,2.1-1.8,3.3c-0.1,0.3-0.2,0.7-0.3,1c0,0.2-0.1,0.4-0.1,0.5v0.3v0c0.3-0.2,0.7-0.4,1-0.6l3.9-1.9l-2.5,3.4
		c-0.1,0.2-0.1,0.4-0.2,0.6c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0,0.2-0.1,0.3v0.3v0.3
		c0,0,0.1,0,0.1-0.1c0.1-0.1,0.2-0.2,0.4-0.3c0,0,0,0,0-0.1l0.1-0.2c0.3-1.1,0.7-2.2,1.4-3.2c1.1-1.6,2.1-2.3,2.3-2.1
		c0.2,0.2-0.3,1.2-1.2,2.6c-0.4,0.9-0.6,1.9-0.8,2.9c0,0.4,0,0.2,0,0.3l0.2-0.2c0.5-0.4,1.1-0.7,1.6-1c0.7-0.4,1.4-0.6,2.1-0.8
		l2.5-0.4l-2,1.3l0.1,0.2c-0.2,0.2-0.9,0.7-1.7,1.3c-0.2,0.2-0.5,0.4-0.7,0.6c0,0.1-0.1,0.1-0.1,0.2c-0.6,0.9-1.1,1.9-1.6,2.8h0.1
		h0.3l1.1-0.2l2.5-0.3c0,0.1-0.5,0.8-1,1.7c-0.1,0.2-0.3,0.5-0.4,0.7l-0.3,0.6l-0.1,0.2v0.1v0.2l-0.1,0.3c-0.1,0.4-0.3,0.9-0.4,1.3
		c-0.2,0.8-0.4,1.7-0.6,2.4l-0.1,0.5c-0.1,0.1,0,0.1-0.1,0.3s-0.1,0.3-0.2,0.5l-0.1,0.5l-0.1,0.3c0,0,0,0.1,0,0.1l0.5-0.4
		c0.3-0.2,0.5-0.5,0.7-0.7l1.6-1.5c1.5-1.4,3.1-2.7,4.8-3.8c1-0.7,1.8-1.5,2.5-2.4c0.7-0.9,1.3-1.8,1.9-2.5c1-1.5,1.7-2.4,2-2.2
		c0.3,0.1,0,1.3-0.7,3c-0.2,0.5-0.4,0.9-0.7,1.3c0.2,0.6,0.2,1.5,0.3,2.5c0,0.2,0.1,0.5,0.1,0.7c0.2-0.3,0.4-0.7,0.7-1
		c0.7-0.8,1.3-1.1,1.5-0.9c0.5,0.4-0.6,2.4-1.1,4.8c-0.2,0.8-0.2,1.6-0.1,2.4c0.4-0.9,0.9-1.7,1.6-2.4c0.7-0.6,1.2-0.8,1.4-0.7
		c0.5,0.4-0.5,2.1-1.2,4.2c-0.3,0.9-0.5,1.7-0.6,2.4l0.6-0.6c0.4-0.4,0.7-0.6,0.9-0.9c0.5-0.4,0.9-0.6,1-0.5c0.1,0.1,0,0.5-0.4,1.1
		c-0.2,0.3-0.4,0.7-0.7,1.1l-0.4,0.7l-0.2,0.3v0.1c0,0.1-0.1,0.2-0.1,0.3l0.2-0.1l0.1-0.1l0.3-0.2l0.1-0.1l0.5-0.3
		c0.4-0.2,0.8-0.4,1.1-0.7c2.6-1.7,4.6-4.1,5.9-6.9c1-2.2,1.4-3.7,1.7-3.6c0.3,0,0.5,1.7-0.3,4.2c-0.5,1.7-1.4,3.3-2.5,4.6
		c0,0.1,0,0.2,0,0.4c0,0.3,0,0.5-0.1,0.8c0.2,0,0.3-0.1,0.5-0.1c0.1-0.6,0.3-1.2,0.6-1.7c0.3-0.5,0.6-0.7,0.7-0.6
		c0.3,0.2-0.1,1.2,0.1,2.9c0,0.1,0,0.1,0,0.2v0.1l0.1,0.1c0,0,0.1-0.1,0.1-0.1l0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.2l0.3-0.3
		c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.2,0.4-0.4,0.6-0.7c0.2-0.2,0.4-0.5,0.6-0.7c1.4-1.8,2.5-3.8,3.4-5.9c0.7-1.6,1.2-2.7,1.5-2.6
		c0.3,0.1,0.3,1.3-0.1,3.1c-0.2,1.1-0.6,2.1-1,3.2c-0.1,0.2-0.2,0.3-0.2,0.5c0.2-0.2,0.5-0.4,0.7-0.6c1.6-1.4,2.3-2.9,2.9-2.9
		c0.2,0,0.4,0.5,0.4,1.1c0.2-0.2,0.3-0.5,0.5-0.8c0.2-0.3,0.3-0.8,0.5-1.2l0.7,0.1l0.3,0.1c0.3,0.2,0.5,0.4,0.8,0.5
		c-0.1,0.4-0.1,0.9,0,1.3c0.1,0.2,0.2,0.5,0.2,0.5c0.1,0.2,0.1,0.3,0.2,0.5c0,0,0,0,0,0c0-0.1,0-0.2,0-0.4c0.1-2.5,1-3.9,1.2-3.8
		c0.3,0.1,0,1.5,0.1,3.9c0,0.6,0.1,1.2,0.1,1.9c0,0.5,0.1,1,0.2,1.4c0,0,0,0,0.1,0l0.5-0.2c0.7-0.4,1.3-0.9,1.9-1.4
		c1.1-1,1.9-2.4,2.2-3.8c0.3-1.3,0.4-2.6,0.2-3.9c-0.2-2.3-0.7-3.7-0.4-3.8s1.2,1.1,1.8,3.6c0.3,1,0.4,2,0.3,3
		c0.4-0.8,0.7-1.7,0.8-2.6c0.5-2.7,0.4-5.2,1-5.3c0.3,0,0.6,0.4,0.8,1.3c0.2-0.8,0.4-1.7,0.4-2.5c0.2-2.5-0.1-4.7,0.5-4.8
		c0.3,0,0.7,0.4,1,1.3c0.3,0.7,0.4,1.4,0.5,2.2c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2,0-0.4,0-0.6
		c-0.1-0.6-0.3-1.2-0.6-1.8c-1.1-2.7-2.2-5-1.7-5.3c0.3-0.2,1.1,0.4,2,1.6c0-0.1,0-0.1-0.1-0.2c-0.5-1.5-1.4-2.9-2.5-4l-2.1-2.1
		l2.9,0.5c0.4,0,0.7,0.1,1.1,0.3l0.5,0.2c0.1,0,0.1,0.1,0.2,0.1l-0.1-0.2l-0.2-0.5c-0.1-0.3-0.2-0.6-0.4-0.9c-0.5-1-1.2-2-2-2.9
		c-1.3-1.5-2.4-2.3-2.3-2.6c0.1-0.3,1.5,0.1,3.3,1.5c0.3,0.2,0.5,0.4,0.7,0.6c-0.3-1.1-0.6-2.6-1.4-4c-1.6-3-4.2-4.5-4-5
		c0.1-0.3,0.9-0.2,2.1,0.3c-0.7-1-1.8-2.1-3.6-2.6c-1.7-0.5-3.4-0.8-5.1-0.6c-1.4,0-2.3,0.1-2.4-0.1c-0.1-0.3,0.7-0.9,2.1-1.3
		c0.9-0.3,1.8-0.4,2.7-0.4c1.1,0,2.3,0.2,3.4,0.5c1.1,0.4,2.2,1,3,1.8c0.1,0.1,0.1,0.1,0.2,0.2h0.1h0.3H70h0.1c0.1,0,0.2,0,0.3,0
		c0.1,0,0-0.2-0.1-0.3l-0.1-0.1l-0.1-0.1c-0.5-0.8-1.1-1.5-1.9-2c-0.9-0.5-1.9-0.9-3-1c-0.9-0.1-1.9-0.2-2.8-0.3
		c-1.6,0-2.7-0.1-2.8-0.4s0.9-0.8,2.7-1.1c0,0,0.1,0,0.1,0c-0.1,0-0.1,0-0.2,0c-1.9,0.1-3,0.3-3.1,0c0-0.2,1-0.7,3-1.1
		c1.2-0.2,2.4-0.4,3.6-0.3c0.8,0,1.5,0.1,2.2,0.2h0.1h0.2l-0.1-0.1l-0.1-0.1l-0.4-0.3c-0.3-0.2-0.5-0.3-0.8-0.4
		c-2-1-4.5-0.9-6.7-0.9c-1.9,0-3.8,0.3-5.7,0.8c-3.2,0.9-5,2-5.1,1.8s1.4-1.9,4.7-3.2c0.3-0.1,0.5-0.2,0.8-0.3
		c-0.8-0.2-1.7-0.5-2.8-0.4c-2.3,0.2-4.2,1.4-4.6,1c-0.2-0.2,0-0.8,0.8-1.5c0.3-0.3,0.7-0.6,1.1-0.8l-0.3-0.1l-0.4,0.4L48.1,415.1z
		 M16.5,431.2c-0.3-0.1-0.3-0.7-0.1-1.6c0.4-1.2,1-2.2,1.9-3c0.9-0.9,1.9-1.5,3.1-1.8c0.9-0.2,1.5-0.1,1.6,0.1
		c0.1,0.6-1.6,1.5-3.3,3.2C18.1,429.7,17.1,431.4,16.5,431.2z M28.8,433.2c-0.4,0-0.8,0.1-1.3,0.2c-0.2,0-0.5,0.1-0.7,0.1l-0.3,0.1
		c0,0,0,0,0,0c-0.1,0.1,0,0.1,0,0.1h0.1l0.3,0.2c0.3,0.1,0.5,0.3,0.7,0.5l1.3,1.2l-1.8,0.5c-0.9,0.3-1.8,0.7-2.6,1.2
		c-0.7,0.4-1.4,0.8-2,1.3c-1,0.8-1.7,1.3-1.8,1.2c-0.2-0.1,0.1-1,1-2.1c0.8-0.9,1.7-1.7,2.8-2.4c-0.7-0.2-1.4-0.4-2.1-0.6l-2.8-0.6
		l2.8-1.2c0.9-0.4,1.9-0.7,2.9-0.9c0.2-0.1,0.5-0.1,0.7-0.1h0.4c0.3,0,0.5,0,0.8,0c0.5,0,1,0,1.4,0.1c1.7,0.2,2.7,0.7,2.6,1
		C31.4,433.1,30.4,433.1,28.8,433.2z M39.2,417.9c-0.1,0.2-0.9,0.1-2-0.1c-0.5-0.1-1.2-0.3-1.9-0.3c-0.4,0-0.7-0.1-1.1-0.1h-0.1
		h-0.1c-0.1,0-0.2,0-0.2,0.1c0,0,0,0.1,0,0.2l0.1,0.1l0.3,0.2c0.7,0.5,1.2,0.7,1.1,1c0,0.2-0.6,0.4-1.6,0.2l-0.4-0.1l-0.6-0.2
		c-0.4-0.2-0.8-0.5-1.2-0.7c-0.8-0.6-1.6-1.3-2.2-2.1l1.3-0.3c0.4-0.1,0.9-0.2,1.3-0.3c0.4-0.1,0.8-0.1,1.2-0.1c0.4,0,0.9,0,1.2,0
		c0.4,0.1,0.9,0.2,1.3,0.3c0.7,0.2,1.4,0.5,2,0.8C38.9,417,39.4,417.7,39.2,417.9z M69.8,449.8c0,0.2,0,0.4,0,0.5v0.3
		c0,0.2,0,0.4,0,0.5l-0.1,1.1l-0.2,1c-0.1,0.4-0.2,0.8-0.3,1.3l-0.3,1.2c-0.7-0.9-1.3-1.7-1.8-2.5c-0.3-0.4-0.5-0.8-0.6-1.1
		c-0.1-0.3-0.3-0.6-0.4-0.9c-0.2-0.8,0-1.4,0.2-1.4c0.2,0,0.5,0.3,1,0.8c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0.1,0,0.1,0
		c0.1,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1v-0.2v-0.5l0.1-1.7c0-1,0.1-1.6,0.4-1.7c0.3-0.1,0.7,0.5,1.1,1.5
		C69.6,448.6,69.7,449.2,69.8,449.8z M64.2,434.4c0.4-0.4,2.6,0.8,3.9,3.5c0.6,1.2,0.8,2.4,0.8,3.7c-0.1,1-0.4,1.6-0.6,1.6
		c-0.6,0-0.9-2.1-2-4.4C65.1,436.6,63.8,434.9,64.2,434.4z M60,450.8c0.4-0.4,2.3,1,3.5,3.5c0.5,1.1,0.8,2.2,0.9,3.4
		c0,0.9-0.2,1.5-0.5,1.5c-0.6,0-1.2-1.8-2.3-4C60.5,453,59.6,451.2,60,450.8z M50.5,458.3c0.7-2,1-3.8,1.5-3.9
		c0.3-0.1,0.6,0.4,0.8,1.3c0.1,1.1,0,2.2-0.3,3.2c-0.8,2.4-2.4,3.8-2.9,3.5C49.2,462.1,49.9,460.3,50.5,458.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M420,346.5L420,346.5C420,346.4,420,346.4,420,346.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M465.8,108.4c2.1,0.1,3.9,0.2,5.7,0.2c-0.5-0.4-1.1-0.8-1.8-1.4c-1.9-1.6-4.6-3.9-8-6.8
		c-3.4-2.9-7.3-6.4-11.7-10.3c-3.2-2.9-6.7-6-10.4-9.3c3.7,5.6,10.2,16.1,17.5,28.3C460,108.7,462.9,108.4,465.8,108.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M18.2,133.6c0.3,2.8,0.6,5.3,0.7,7.6c0.2,3.6,0.3,6.4,0.3,8.1c0.4-1.4,1-3,1.6-4.7l1.1-3.1l1.3-3.4l1.4-3.6
		l0.3-0.8c-0.8,0.2-1.9,0.3-3.1,0.3C20.2,134,19,133.8,18.2,133.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M175.7,223.6c-0.6-0.1-1.3-0.2-1.9-0.3c-1.3-0.1-2.5-0.1-3.8,0c-0.6,0-1.2,0.1-1.8,0.1l-0.9,0.1l-0.3,0
		c0.1,0.5,0.2,1.1,0.2,1.8c0,0.8,0,1.5-0.2,2l0.6,0.3c0.2,0.1,0.4,0.2,0.7,0.3c1.8,0.6,3.7,0.9,5.6,0.8c0.7,0,1.4-0.1,2.1-0.2
		c0.2,0,0.4-0.1,0.6-0.1c-0.3-0.6-0.5-1.4-0.5-2.4c0-0.9,0.1-1.7,0.2-2.2C176.1,223.7,175.9,223.6,175.7,223.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M125.4,706.8c-0.3-0.7-0.4-0.9-1-1.2c-0.7-0.2-1.5-0.4-2.3-0.4c-1.9-0.1-4-0.6-5.9-0.8c-0.5,0-0.9,0-1.4,0
		c-0.5,0-1,0.1-1.5,0.2c-0.4,0.1-0.9,0.1-1.3,0.2c0.2,0.1,0.5,0.3,0.7,0.4c0.9,0.5,1.5,0.8,1.5,1.1s-0.8,0.5-1.9,0.3
		c-0.7-0.1-1.4-0.3-2-0.6c-0.4-0.2-0.7-0.4-1-0.6c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2,0.1-0.3,0.1-0.5,0.2c-0.4,0.2-0.7,0.4-1.1,0.5
		c-0.2,0.1-0.4,0.2-0.6,0.2l-0.5,0.1c-1.2,0.4-2.3,0.8-3,1.1c0.6,0.3,1.2,0.6,1.8,0.9c1,0.5,2,1,2.8,1.5c0.5,0.3,1.1,0.7,1.7,0.9
		c0.6,0.1,1.3,0.1,1.9,0l0.7-0.1c0.2,0,0.4-0.1,0.6-0.2c0.4-0.1,1-0.4,1.6-0.5c1.2-0.3,2.4-0.3,3.6,0.1c0.5,0.2,0.8,0.4,1.1,0.5
		c0.2,0.1,0.5,0.2,0.7,0.2c0.7,0.1,2.8-0.4,5.1-0.5c0.6,0,1.2-0.1,1.8-0.1c0-0.1,0-0.1-0.1-0.2C126.1,709.1,125.8,708,125.4,706.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M42.9,671.1c-1-0.5-2-0.8-3-1c-1.2-0.2-2.3-0.4-3.5-0.5c-1.2-0.1-2.4-0.2-3.6-0.2c-0.6,0-1.3-0.1-1.9-0.2
		c-0.3-0.1-0.7-0.1-1-0.2c1.8,1.1,3,1.8,2.9,2.2c-0.2,0.5-3.5,0-7-2.3c-0.2,0.3-0.5,0.6-0.7,0.9c-1.3,2-2.3,3.5-2.8,4.1
		c0.9,0.5,1.9,1.1,2.9,1.8c0.6,0.4,1.1,0.8,1.6,1.2l0.7,0.6c0.2,0.2,0.3,0.3,0.5,0.4c0.6,0.4,1.3,0.6,2,0.8c0.7,0,1.5-0.1,2.2-0.3
		c1.2-0.2,2.5-0.3,3.8-0.4c0.7,0,1.4,0.1,2.1,0.3c0.6,0.2,1.2,0.4,1.7,0.7c0.6,0.4,1.3,0.6,2,0.8c0.7,0,1.4-0.1,2.1-0.3
		c0.2-0.1,0.4-0.2,0.7-0.3l0.8-0.4c0.6-0.3,1.3-0.5,2-0.7c0.6-0.1,1.1-0.2,1.7-0.3c-0.5-0.8-1.2-1.9-2.2-3.3
		C45.8,673.2,44.5,672,42.9,671.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M101.5,682.4c0.8-0.1,1.7-0.2,2.5-0.4c0.2-0.1,0.5-0.1,0.7-0.2c0.2,0,0.5-0.1,0.7-0.1l1.3-0.1
		c1.4,0,2.7,0,3.9,0.1c0-0.1,0-0.1,0-0.2c-0.2-0.8-0.5-1.4-1.1-2c-0.2-0.2-0.2-0.2-0.5-0.3c-0.3-0.1-0.5-0.2-0.8-0.3
		c-0.7-0.2-1.3-0.3-2-0.4c-0.7-0.1-1.5-0.2-2.2-0.2l-2.6-0.1c-1.7-0.1-3.5-0.2-4.9-0.3c-1.2,0.1-2.3,0.5-3.2,1.1l-0.7,0.4
		c-0.2,0.1-0.3,0.2-0.4,0.3c-0.2,0.2-0.3,0.5-0.4,0.8c0,0.2,0,0.3,0,0.5c0.3,0,0.5,0.1,0.8,0.1l4.1,0.8
		C98.2,682.4,99.8,682.5,101.5,682.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M407.1,108.6c2.1-1,4.3-1.8,6.5-2.4c2.5-0.7,5.1-1,7.6-0.9c2.7,0.2,5.4,0.6,8.1,1.1c2.7,0.5,5.5,1,8.3,1.6
		c3.5,0.7,6.9,1.4,10.1,2c0.8,0.1,1.5,0.2,2.3,0.3c0.8,0,1.5-0.1,2.3-0.3l2.6-0.5c-8.3-13.9-15.1-25.8-17.5-30.6
		c-0.3-0.3-0.6-0.5-0.9-0.8l-0.7,0.7c-10.3,9.5-19.8,18.1-26.7,24.2c-3.5,3.1-6.3,5.5-8.3,7.2c-1.3,1.1-2.3,1.9-2.8,2.2
		c1.2-0.2,2.4-0.6,3.6-1.1C403.3,110.5,405.1,109.5,407.1,108.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M115.6,84.4c-0.3,0.1-0.7,0.2-1,0.4c0.3,0.1,0.6,0.1,0.9,0.2c0,0,0-0.1,0-0.1
		C115.6,84.8,115.6,84.6,115.6,84.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M121.3,78c-0.5,0-0.9,0.1-1.4,0.1c3.2,1.9,5.8,4.7,7.4,8.1c0.3,0.7,0.5,1.3,0.8,2c0.1,0.2,0,0.4,0.2,0.2
		c0.1,0,0.1-0.1,0.1-0.2c0.2-0.2,0.4-0.5,0.6-0.8c1-1.9,0.9-4.2-0.2-6c-0.9-1.3-2.1-2.3-3.6-2.8c-0.8-0.3-1.6-0.5-2.5-0.6
		c-0.2-0.1-0.4-0.1-0.6-0.1h-0.3h-0.2C121.5,78,121.4,78,121.3,78z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M117.4,85.7l0.6-0.3l0.3-0.1l0.2-0.1l0.3-0.1c0.2-0.1,0.4-0.1,0.6-0.2c-0.3-0.3-0.7-0.8-1.2-1.4
		c-1-1.1-2.4-2.7-4.2-2.7c0-0.7,0-1.4,0.1-2.1c1.4,0.1,2.6,0.6,3.6,1.6c0.7,0.7,1.3,1.5,1.7,2.4c0.4,0.8,0.6,1.5,0.7,2
		c0.3-0.1,0.6-0.1,0.8,0c0.3,0.1,0.5,0.3,0.7,0.6c0.2,0.3,0.2,0.6,0.1,0.9c-0.1,0.4-0.1,0.6-0.3,1.1c-0.2,0.5-0.2,0.6-0.3,0.8
		c-0.1,0.4-0.2,0.7-0.3,1.1c-0.1,0.7-0.2,1.4-0.3,2.1c0,0.6,0,1.2,0,1.8c0,0.3,0,0.7,0.1,0.8s0,0.3,0,0.4v0.2c0,0.1,0,0.1,0,0.1
		c0,0.2,0,0.4,0.1,0.6l0.4-0.5c0.4-0.5,0.8-1,1.1-1.5c0.7-0.9,1.2-1.8,1.7-2.8c0.8-1.5,1.1-3.2,1-4.9c0-0.2-0.1-0.4-0.1-0.6
		c-0.7-1.3-1.6-2.6-2.6-3.7c-1-1-2.1-2-3.3-2.7l0.1-0.1c0,0,0,0,0,0l0.1-0.2c-0.3,0-0.5,0-0.8,0.1c0.4-0.9,0.7-1.9,1.1-2.8
		c0.3-0.6,0.4-0.8,0.5-1c0.2-0.3,0.3-0.5,0.5-0.7c0.7-0.9,1.5-1.7,2.4-2.4c0.9-0.7,1.8-1.2,2.8-1.6l0.7-0.3l0.7-0.2h0.1
		c0,0,0-0.1,0-0.1c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.3-0.5-0.5-0.7-0.7c-0.6-0.4-1.3-0.7-2-0.8c-0.7-0.1-1.4-0.1-2.1,0
		c-0.7,0.1-1.3,0.2-1.9,0.5c-1.9,0.7-3.5,2-4.4,3.9c-0.1,0.2-0.1,0.3-0.2,0.5c0.9,0.7,1.6,1.6,2,2.7c0.5,1.4,0.3,2.1,0.1,2.1
		c-0.2,0-0.1-0.8-0.7-1.8c-0.4-0.7-1-1.4-1.7-1.9c-0.1,0.3-0.1,0.5-0.2,0.5c0,0-0.2-0.3-0.2-0.8c-0.6-0.4-1.3-0.7-2.1-0.9
		c-1.2-0.3-2.5-0.5-3.8-0.4c-0.7,0-1.4,0.1-2.2,0.1c-0.7,0-1.5,0.1-2.2,0.3c-0.6,0.2-1.1,0.7-1.3,1.3c-0.4,0.7-0.8,1.5-1.2,2.3
		c-0.2,0.4-0.4,0.8-0.5,1.2v0.1v0.1h0.1c1.6-0.6,3.2-0.8,4.9-0.6c0.4,0.1,0.8,0.1,1.3,0.3c0.1,0,0.3,0.1,0.5,0.1l0.5,0.2l1,0.5
		l0.9,0.6c0.2,0.2,0.4,0.4,0.6,0.6c0.2,0.2,0.4,0.4,0.6,0.7l-0.5,0.2h-0.1l0.3,0.1l0.2,0.1h0.1c0,0,0.1,0,0,0c0,0,0,0,0,0h-0.1h-0.3
		l0,0l0.3,0.3l0.2,0.2c-1.4-0.4-1.1-0.4-1-0.3c0,0,0,0,0,0v-0.1c0-0.1,0-0.1,0-0.1h0.5h0l-0.1-0.1c0,0-0.1-0.1-0.1-0.1h0l-0.1,0
		c0,0,0,0,0,0c0,0,0,0,0,0l0.2-0.1l0.3-0.1c0.2-0.1-0.8,0.3-0.9,0.3l-0.9,0.3c0.1,0.1-0.1,0.1-0.1,0.1l-0.2,0.1l-0.4,0.1l-0.8,0.2
		l-0.4,0.1l-0.2,0.1l-0.3,0.2c-0.5,0.2-0.9,0.5-1.3,0.7c-0.8,0.5-1.6,1-2.3,1.6c-0.7,0.5-1.3,1.1-1.9,1.7c-0.3,0.3-0.5,0.6-0.8,0.9
		l-0.5,0.6c-0.3,0.4-0.6,0.8-0.8,1.3l-0.1,0.2c0,0,0,0.1,0,0.1l0.1-0.1c0.1,0,0.1,0,0.1,0c0.4-0.3,0.7-0.5,1.1-0.7
		c0.6-0.3,1.2-0.6,1.8-0.8c1-0.4,2.1-0.8,3.2-1c1.2-0.2,2.4-0.4,3.6-0.4c0.4,0,0.8,0,1.7,0c0.4,0,0.7,0.1,1.1,0.1
		c0.9-0.4,1.9-0.8,2.9-1l0.3-0.1l0.1,0.2c0.3,0.5,0.3,1,0.1,1.5c-0.1,0.2-0.2,0.3-0.3,0.5l-0.1,0.2l-0.2-0.1
		c-0.7-0.2-1.4-0.4-2.2-0.5c-0.7,0.4-1.4,0.8-2,1.4c-1.1,0.9-2.1,2.1-2.8,3.3l-0.2,0.3v0.1l-0.1,0.2c-0.1,0.1-0.1,0.2-0.1,0.3
		c0,0.1,0,0.2,0,0.3l0.1-0.1l0.1-0.2l0.2-0.3l0.1-0.1l0.3-0.2c0.2-0.2,0.4-0.3,0.6-0.5c2-1.4,4.2-2.6,6.4-3.5L117.4,85.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M210.3,339.9c0.4,0,0.8-0.1,1.3-0.1c-0.1,0-0.2-0.1-0.3-0.1l-0.4-0.2L210.3,339.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M11.4,133.9c0.8,2.3,1.4,4.7,1.8,7.1c0.4,2.5,0.7,5,0.8,7.5c0,0.1,0,0.1,0,0.2c0.5-5.1,0.7-10.2,0.5-15.3
		C13.8,133.7,12.7,133.8,11.4,133.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M142.2,49.7c1.6,0.5,3.1,1,4.7,1.5l0.5,0.2l-3.9-4.6l5.5,3.8c0.5,0.3,2,1.5,4,3.1l2.7,2.2
		c0-0.1,0.2-0.4,0.2-0.6c0.2-0.7,0.2-1.3,0.1-2c-0.2-1.3-0.8-2.4-1.7-3.3c-0.9-0.8-1.9-1.4-3-1.8c-0.5-0.2-1-0.3-1.5-0.4
		c-0.2-0.1-0.4-0.1-0.7-0.1h-0.3l-0.6-0.1c-1.5-0.1-2.8-0.1-3.2-0.1l0.2-0.3l0.3-0.5l0.6-0.9c0.5-0.6,1.1-1.2,1.7-1.6
		c0.3-0.2,0.5-0.3,0.8-0.4c0.2-0.1,0.5-0.2,0.7-0.3c0.5-0.2,0.9-0.3,1.4-0.4c1.7-0.3,3.4-0.5,5.1-0.5l8.3-0.3l5.7-0.2h0.4h0.2
		l-0.1-0.2c-0.1-0.2-0.2-0.4-0.3-0.6c-0.3-0.4-0.6-0.8-1-1.1c-1-0.9-2-1.6-3.2-2.2c-1.7-1-3.5-1.9-5.4-2.6c-2.4-1.1-5.2-1.2-7.7-0.4
		c-1.5,0.6-2.9,1.5-4.1,2.6c-0.3,0.3-0.7,0.6-1,0.9l-0.7,0.7c-0.3,0.3-0.6,0.6-0.9,0.9l-1.9,2l-1,1l-0.2,0.2
		c0.1,0.1,0.2,0.2,0.1,0.2c0,0-0.2,0-0.3-0.1l-0.2,0.2c0-0.1,0-0.2,0.1-0.2c-0.3-0.1-0.7-0.2-1.3-0.3c-1.7-0.2-3.3-0.1-4.9,0.4
		c-2.5,0.7-4.8,1.9-6.8,3.6c-0.6,0.5-1.1,1-1.6,1.6c-0.2,0.3-0.5,0.6-0.7,0.9c-0.1,0.2-0.2,0.3-0.3,0.5l0,0.2c0,0.1-0.1,0.2-0.1,0.3
		c0,0,0,0.1,0,0.1l0.1-0.1l0.1-0.1l0.1,0c0.7-0.4,1.4-0.8,2.2-1.1c1.6-0.6,3.2-0.9,4.9-0.9c1.7,0,3.4,0.2,5,0.6l1.2,0.3l0.6,0.2
		L142.2,49.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M141.5,42.5c0.6,0.2,1,0.4,1.2,0.6c0.3-1,1-2.9,1.3-4.4c0-0.1,0.1-0.3,0.1-0.4v-0.3c0-0.1,0-0.2,0-0.3v-0.3
		c0-0.2,0-0.4-0.1-0.6c-0.1-0.5-0.2-0.9-0.4-1.4c-0.9-2.4-2.8-4.3-5.1-5.4c-3.3-1.5-7.1-1.4-10.6-1c-0.9,0.1-1.8,0.2-2.6,0.4
		c-0.8,0.1-1.6,0.3-2.3,0.6c-0.3,0.1-0.7,0.3-1,0.5c-0.3,0.3-0.3,0.2-0.3,0.3c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1h0h0.2h0.1h0.3
		c0.2,0,0.4,0,0.7,0c0.8,0,1.7,0,2.5,0.2c1.5,0.3,3,0.8,4.4,1.5c2.2,1.2,4.1,2.8,5.7,4.6c1,1.2,2,2.4,2.9,3.7
		c0.5,0.7,0.7,1.1,0.8,1.3C140,42.1,140.7,42.3,141.5,42.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M146.9,55.2c0.7,0.4,1.2,1,1.5,1.7c0.3,1,0.6,2,0.7,3c0.2,1.5,0.3,3.1,0.3,4.6l0,0.7l-0.1,0.7
		c0,0.4-0.1,0.8-0.2,1.2v0.1l0.3-0.2c0.2-0.2,0.5-0.4,0.7-0.6c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.2,0.3-0.5,0.5-0.7
		c0.4-0.6,0.6-1.2,0.8-1.9c0.5-1.7,0.4-3.6-0.2-5.3c-0.6-1.7-1.5-3.4-2.6-4.8c-0.6-0.5-1-0.9-1.2-1.1l-2.8,0.4l-0.5,0.1l-0.4,0.1
		l-0.9,0.2l-0.8,0.2l-0.4,0.1l-0.2,0.1h-0.1c-0.9,0.3-1.8,0.7-2.6,1.2c-1.5,0.9-2.8,2-3.9,3.3c-0.4,0.4-0.7,0.9-1,1.3
		c2.9-2.3,6.4-3.8,10.2-4.3C145.1,54.8,146.1,54.9,146.9,55.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M118.9,109c-0.1,4-0.1,7.7-0.1,10.7c0,1,0,2,0,2.9c0.5,0,1.4,0.1,2.4,0.3c1.8,0.3,4.3,0.7,7.1,0.9
		c2.3,0.2,4.7,0.1,7-0.3c0.9-0.1,1.7-0.2,2.2-0.3c0.8-5.1,1.1-10.2,1-15c-0.6,0.1-1.2,0.2-1.7,0.1c-1.2-0.1-2.4-0.3-3.5-0.8
		c-1.3-0.4-2.5-0.9-3.9-1.3c-5.2-1.4-9.8-0.6-10.3-1C119,106.5,119,107.8,118.9,109z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M200.7,243c-1-0.3-2-0.5-3.1-0.5c-1,0-2.1,0.1-3.1,0.3c-0.8,0.2-1.5,0.6-2.2,1c-0.5,0.4-0.9,0.7-1.3,0.9
		c0.4,0.2,0.9,0.4,1.6,0.7c0.7,0.3,1.5,0.5,2.3,0.6c0.3-1.7,1.8-3.1,3.7-3.1c1.4,0,2.7,0.8,3.3,2.1c0.1,0,0.1-0.1,0.2-0.1
		c0.5-0.3,0.8-0.6,1.1-0.8c-0.1,0-0.1,0-0.2-0.1C202.3,243.7,201.5,243.3,200.7,243z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M193.6,271.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.4,0.3-1.1,0.8-1.8,1.4c-0.9,0.8-2,1.5-3.1,2
		c-2.9,1.2-6.1,1.3-9.1,0.2c-1.2-0.5-2.3-1.2-3.2-2.1c-0.5-0.6-1-1.1-1.6-1.5c-0.2-0.1-0.3-0.3-0.5-0.4c-0.2,0-0.4,0-0.6,0
		c0.4,0.6,1,1.8,2,3.2c0.8,1.1,1.9,2,3.1,2.5c0.3,0.2,0.7,0.3,1.1,0.4c0.4,0.1,0.8,0.2,1.2,0.3c0.9,0.1,1.8,0.1,2.7,0.1
		c1.8,0,3.5-0.4,5.1-1c1.2-0.5,2.3-1.4,3.1-2.5l0.6-0.8l0.5-0.7c0.3-0.3,0.6-0.7,0.9-1c0.1-0.1,0.2-0.2,0.3-0.3c0,0-0.1,0-0.1,0
		C193.8,271.2,193.7,271.2,193.6,271.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M178.1,269.2c0.3-0.2,0.7-0.3,1-0.3c0.4,0,0.9,0,1.3,0.2c0.7,0.3,1.2,0.7,1.7,0.9c0.4,0.2,0.9,0.4,1.3,0.4
		c0.5-0.1,1-0.2,1.5-0.5c0.6-0.2,1.1-0.5,1.7-0.7c0.3-0.1,0.7-0.2,1-0.3c0.4-0.1,0.8-0.1,1.2,0c0.5,0.2,1,0.4,1.5,0.7
		c0.1,0,0.2,0.1,0.2,0.1c-0.7-0.6-1.4-1.1-2.2-1.6c-0.2-0.2-0.5-0.2-0.8-0.3c-0.4,0-0.8,0.1-1.1,0.2c-0.9,0.4-2,0.7-3,0.8
		c-0.6,0-1.2-0.1-1.7-0.3c-0.5-0.2-0.9-0.4-1.3-0.5c-0.3-0.1-0.7-0.2-1-0.1c-0.3,0.1-0.6,0.2-0.8,0.3c-0.7,0.6-1.5,1.1-2.3,1.6
		c0.2,0,0.3-0.1,0.5-0.1C177.3,269.6,177.7,269.4,178.1,269.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M167.1,246c0.3-1.8,1.8-3.2,3.7-3.2c1.5,0,2.9,0.9,3.4,2.3c0.4-0.2,0.6-0.3,0.9-0.4c-0.4-0.2-0.8-0.6-1.3-0.9
		c-0.6-0.5-1.4-0.9-2.1-1.1c-1-0.2-2-0.3-3.1-0.2c-1.1,0.1-2.1,0.3-3.1,0.6c-0.8,0.3-1.6,0.7-2.4,1.1c-0.1,0-0.1,0-0.2,0.1
		c0.3,0.2,0.6,0.5,1.1,0.8C165,245.5,166.1,245.8,167.1,246z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M160.7,269.7l1.1,1l1.1,1l1.6,1.5c0.8,0.8,1.3,1.4,1.7,1.7c1.1,1,3.5,2.8,6.4,5.6c0.7,0.7,1.5,1.5,2.2,2.3
		l1.1,1.3c0.2,0.2,0.4,0.5,0.5,0.7l0.3,0.4c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0,0.1,0h0l0.5,0l1.8,0.2c1.2,0.1,2.3,0.2,3.8,0.3
		c1.4,0.1,2.8,0.1,3.9,0.1h1.3c0.1,0,0.1,0,0.1,0h0.1h0c0,0,0.1-0.1,0.1-0.1l0.6-0.6l1.1-1.1c0.7-0.7,1.3-1.3,2-1.9
		c1.3-1.2,2.5-2.2,3.5-3.1c2.1-1.7,3.6-2.8,4.4-3.5c0.6-0.5,1.4-1.3,2.4-2.3c0.5-0.5,1.1-1,1.8-1.6l0.5-0.4l0.3-0.2l0.1-0.1
		c0.1-0.1,0-0.1,0.1-0.3s0.1-0.4,0.2-0.6c0.5-2,0.8-4.1,1-6.2c0.3-2.3,0.5-4.8,0.8-7.4c1.1-11.3,1.9-20.7,2.8-22.8
		c-0.3,0-0.5,0-0.8,0c-1.1,0-2.2,0.2-3.2,0.6c-1.5,0.6-3.1,0.7-4.7,0.4c-0.7-0.2-1.3-0.6-1.8-1.1c-0.3-0.3-0.8-0.5-1.2-0.7
		c-0.8-0.2-1.7,0-2.3,0.5c-0.4,0.2-0.8,0.4-1.2,0.6c-0.4,0.2-0.8,0.2-1.2,0.2c-0.5,0-1.1-0.1-1.6-0.3c-0.5-0.1-1-0.2-1.5-0.2
		c-0.3,0-0.7,0-1,0c-0.4,0.1-0.7,0.2-1,0.4c-0.9,0.7-1.9,1.1-2.9,1.4c-0.6,0.1-1.3,0.2-1.9,0.1c-0.7-0.1-1.3-0.2-1.9-0.5
		c-1.2-0.5-2.3-1.1-3.1-1.5c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2,0-0.3,0.1-0.5,0.2c-0.4,0.3-0.8,0.6-1.3,0.8c-0.5,0.3-1.1,0.3-1.7,0.2
		c-0.4-0.2-0.7-0.5-1-0.8c-0.3-0.4-0.7-0.7-1.1-1c-0.4-0.2-0.9-0.3-1.3-0.2c-0.7,0.2-1.4,0.5-2,0.9c-0.4,0.2-0.8,0.5-1.3,0.7
		c-0.6,0.3-1.4,0.4-2.1,0.2c-0.6-0.3-1.2-0.8-1.6-1.4c-0.1-0.2-0.3-0.3-0.5-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.4,0-0.8,0.3-1.5,0.8
		c-0.4,0.3-0.9,0.5-1.5,0.5c-0.5,0-0.9-0.2-1.3-0.4c-0.6-0.3-1.2-0.6-1.6-0.8c-0.3-0.2-0.7-0.3-1.1-0.3c-0.1,0-0.1,0.1-0.2,0.1
		l-0.3,0.2c-0.2,0.1-0.4,0.2-0.6,0.3c-0.1,0.1-0.2,0.1-0.3,0.1c0.3,1.1,0.7,3.2,1,6c0.5,4.2,1.2,10,2.1,16.3c0.5,3.4,1,6.5,1.4,9
		c0.3,1.2,0.4,2.3,0.6,3.2c0.1,0.4,0.2,0.8,0.2,1.1c0,0.1,0,0.2,0,0.4V269.7z M197.3,272.3c-0.5,0.1-1.1-0.7-1.6-1.6
		c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c0.1,0.2,0,0.6-0.1,1.2c-0.1,0.5-0.3,1-0.4,1.4l-0.3,0.9l-0.1,0.4l-0.2,0.5
		c-0.3,0.8-0.8,1.6-1.4,2.2c-0.7,0.8-1.5,1.4-2.4,1.9c-2,1-4.2,1.6-6.4,1.6c-1.2,0.1-2.3,0-3.5-0.2c-0.6-0.1-1.1-0.3-1.7-0.5
		c-0.5-0.2-1-0.4-1.5-0.7c-1.7-1-3-2.4-3.8-4.2c-0.5-1.1-0.8-2.3-0.8-3.5c0-0.5,0.1-0.9,0.2-1.1c-0.4-0.1-0.7-0.3-1-0.5
		c-0.1-0.1-0.1-0.1-0.2-0.2c-0.7,0.6-1.2,0.9-1.7,0.8c-0.3-0.1-0.4-0.5-0.4-0.9c0.1-0.6,0.4-1.1,0.9-1.6c1-1,2.1-1.1,2.4-0.6
		c0.3,0.5-0.1,1.2-0.8,1.9c0,0,0.1,0,0.1,0.1c0.8,0.4,1.7,0.3,2.4-0.1c0.5-0.4,1.1-0.8,1.5-1.2c0.6-0.6,1.2-1.1,1.9-1.6
		c0.5-0.3,1-0.6,1.6-0.7c0.6-0.1,1.2,0,1.7,0.2c0.8,0.4,1.6,0.6,2.5,0.7c0.9-0.1,1.7-0.3,2.5-0.7c0.5-0.2,1-0.3,1.5-0.3
		c0.6,0,1.2,0.2,1.7,0.5c0.7,0.5,1.4,1,2,1.6c0.5,0.5,1,1,1.5,1.3c0.2,0.2,0.4,0.3,0.7,0.4c0.1,0,0.2,0.1,0.3,0.1l0.3,0.1
		c0.4,0.1,0.8,0.1,1.1,0c0.1,0,0.2-0.1,0.3-0.1c-0.5-1-0.8-1.8-0.4-2.3c0.3-0.5,1.7,0.1,2.4,1.6S197.8,272.2,197.3,272.3z
		 M205.4,244.2c-0.3,0.2-0.6,0.3-1,0.3c-0.1,0-0.2,0-0.4,0c-0.1,0.4-0.4,1.1-1,1.7c-3.2,2.6-7.7,2.9-11.2,0.6
		c-0.8-0.7-1.2-1.3-1.4-1.7c-0.2,0.1-0.4,0.1-0.4,0.1c-0.2-0.2,0.2-1.2,1.3-2.4c0.7-0.8,1.6-1.4,2.6-1.7c1.2-0.4,2.4-0.6,3.7-0.5
		c1.3,0,2.5,0.3,3.7,0.8c0.9,0.4,1.8,1,2.5,1.7c0.2,0.2,0.4,0.3,0.7,0.4c0.2,0.1,0.4,0.1,0.6,0.1c0.4,0,0.7,0,0.8,0.1
		C206,243.7,205.8,244,205.4,244.2z M200.8,235.3c2.4,0,4.8,0.7,6.8,2.3c-2.1-0.5-4.3-0.8-6.5-0.8c-2.3,0.2-6.3,2.5-8.5,2.5
		c-1.4-0.1-2.7-0.8-3.4-2C191.3,237.3,196.4,235.2,200.8,235.3z M178.4,261.2l0.3-0.2c0.1-0.1,0.3-0.2,0.4-0.3
		c0.3-0.1,0.7-0.2,1-0.1c0.4,0.1,0.7,0.2,1,0.4l0.6,0.4c0.2,0.2,0.5,0.3,0.8,0.4c0.3,0.1,0.6,0.1,0.8,0.1c0.3,0,0.7,0,1-0.1
		c0.7-0.2,1.4-0.5,2-0.9c0.5-0.3,1.1-0.5,1.7-0.6c0.6,0,1.3,0.1,1.8,0.4c0.3,0.2,0.7,0.4,1,0.7c0.1,0.1,0.2,0.1,0.3,0.2
		c0,0,0.2,0,0.4-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.2-0.5,0.1-0.8c-0.1-0.3-0.2-0.6-0.4-0.9c-0.4-0.6-0.6-0.9-0.5-1.1
		c0.1-0.1,0.5-0.1,1.1,0.3c0.2,0.1,0.3,0.2,0.5,0.4c0.2,0.2,0.4,0.4,0.5,0.6c0.4,0.7,0.6,1.5,0.3,2.2c-0.2,0.4-0.4,0.8-0.8,1.1
		c-0.4,0.4-0.9,0.6-1.5,0.7c-0.3,0-0.6,0-0.9-0.1c-0.3-0.1-0.5-0.2-0.7-0.3c-0.4-0.2-0.7-0.4-0.9-0.5c-0.1-0.1-0.3-0.1-0.5-0.1
		c-0.2,0.1-0.4,0.2-0.6,0.3c-0.8,0.6-1.8,1-2.7,1.2c-0.5,0.1-1.1,0.2-1.6,0.1c-0.6,0-1.1-0.1-1.7-0.3c-0.7-0.3-1.3-0.6-1.8-1.1
		l-0.2,0.1c-0.5,0.3-1.1,0.4-1.7,0.3c-1.4-0.2-2.5-1.2-2.7-2.6c-0.1-0.3-0.1-0.7,0-1c0.1-0.5,0.3-0.9,0.6-1.3c0.4-0.6,0.8-0.8,1-0.7
		c0.2,0.1,0.1,0.5,0,1.1c0,0.3,0,0.7,0,1c0,0.3,0.2,0.6,0.4,0.9c0.2,0.2,0.5,0.4,0.8,0.4C178.1,261.4,178.3,261.3,178.4,261.2z
		 M158.4,237.3c2-1.3,4.2-2,6.6-2.1c3.8-0.1,12,2.7,13.1,2.5c0,0-1.3,1.6-4.6,1.5c-3.3-0.1-7-2.2-9.1-2.3
		C162.3,236.9,159.6,236.8,158.4,237.3z M160.2,243.6c0.1-0.1,0.3-0.1,0.8-0.1c0.2,0,0.4,0,0.6-0.1c0.3-0.1,0.5-0.2,0.7-0.4
		c0.7-0.7,1.6-1.3,2.5-1.7c1.2-0.5,2.4-0.8,3.6-0.8c1.3-0.1,2.5,0.1,3.8,0.5c1,0.4,1.9,1,2.6,1.9c1.1,1.3,1.5,2.3,1.3,2.5
		c-0.1,0.1-0.2,0-0.4-0.1c-0.1,0.4-0.5,1-1.4,1.7c-1.6,1.2-3.7,1.7-5.7,1.6c-2,0-4-0.8-5.5-2.1c-0.6-0.7-0.9-1.3-1-1.7
		c-0.1,0-0.2,0-0.4,0c-0.4,0-0.7-0.1-1-0.3C160.3,244,160.2,243.7,160.2,243.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M179.4,330l-0.3,0.3l0,0c3.3,1.5,6.2,2.9,8.3,4.1c0.9,0.5,1.7,0.9,2.3,1.3c2.3-2,4.6-4,6.7-5.7
		c1.4-1.2,2.8-2.3,4.2-3.4l1-0.8c0.4-0.3,0.9-0.6,1.4-0.8c0.8-0.2,1.6-0.4,2.5-0.6c1.5-0.3,2.9-0.6,4.1-0.9c1.2-0.3,2.4-0.8,3.4-1.2
		l2.8-1l1.1-0.4l1.2-0.4c0.4-0.1,0.7-0.2,1.1-0.2c0.4-0.1,0.8,0,1.2,0.1c0.3,0.4,0.4,0.9,0.3,1.4c-0.1,0.6-0.2,1.2-0.4,1.8
		c-0.2,0.6-0.5,1.2-0.9,1.7c-0.4,0.6-0.8,1.1-1.3,1.5c-0.6,0.4-1.2,0.8-1.9,1.1l-1.9,0.9l-4.1,2c-0.7,0.3-1.5,0.7-2.1,1.1
		c-0.2,0.1-0.3,0.2-0.4,0.3l-0.1,0c0.1,0,0.2-0.1,0.3-0.1c0.3-0.1,0.5-0.1,0.8,0c0.2,0,0.5,0,0.7,0.1c0.3,0.1,0.6,0.1,0.9,0.2
		l1.7,0.4l3.1,0.9l1.5,0.4c0.4,0.1,0.9,0.2,1.3,0.3c1.9,0.4,3.8,0.7,5.4,1.1l1.2,0.3l0.6,0.2l0.7,0.2c1,0.2,1.9,0.7,2.6,1.4
		c0.3,0.3,0.4,0.6,0.4,1c-0.1,0.4-0.3,0.7-0.5,1c-0.5,0.5-1.1,0.9-1.7,1.2c0,0-0.1,0-0.1,0.1c4.1,0.7,7.5,1.3,9.6,1.9
		c0.1-0.5,0.3-1,0.4-1.5c0.2-0.8,0.4-1.6,0.5-2.4c0.1-0.4,0.1-0.8,0.2-1.1l0.1-1.3c0.3-3.2,0.8-6.5,1.6-9.6c0.7-3.4,1.7-6.8,3.1-10
		c-0.2-0.2-0.3-0.4-0.5-0.6c-1.1-1.6-2-3.5-2.5-5.4c-0.8-2.9-1-5.9-0.7-8.9c0.1-0.9,0.3-1.8,0.5-2.6c0.1-0.2,0.1-0.5,0.2-0.7
		c-1.5,0.3-3.1,0.5-4.6,0.4c-2.8-0.2-5.5-0.8-8-1.9c-0.6-0.3-1.3-0.6-1.9-0.9c-0.5,2.3-1.3,4.5-2.6,6.5c-1.2,1.9-2.7,3.5-4.5,4.8
		c-1.4,1-2.9,1.8-4.4,2.4l-2.9,1c-0.4,0.2-0.7,0.3-1.1,0.5c0.1,0.4,0.2,0.8,0.2,1.2c0,0.9-0.3,1.4-0.5,1.4c0,0,0,0,0,0c0,0,0,0,0,0
		c0.3,0,0.5,0.5,0.5,1.4c-0.1,1.4-0.7,2.8-1.8,3.7c-1.7,1.5-4.1,2-6.2,1.3c-1.3-0.4-2.4-1.2-3.3-2.3c-0.7-1-1.2-2.1-1.3-3.2
		c-0.1,0-0.1,0-0.2,0c-2.5,0.2-5-0.1-7.5-0.7c-0.7-0.2-1.4-0.4-2.1-0.7c-0.7-0.3-1.3-0.6-2-0.9l-1.8-1.1c-0.6-0.4-1.1-0.8-1.6-1.2
		c-0.8-0.6-1.6-1.4-2.3-2.1c-0.2,0.4-0.4,0.8-0.7,1.2l-0.5,0.7l-0.3,0.4l-0.1,0.2c0,0,0,0,0,0c0,0,0,0.1,0,0.2
		c0.1,0.4,0.2,0.8,0.4,1.1l0.4,0.8c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.6,0.2,1.2,0.2,1.8c-0.1,1-0.2,1.9-0.4,2.9c-0.1,0.2-0.1,0.4,0,0.5
		c0.1,0.3,0.3,0.6,0.5,0.8c0.4,0.6,0.9,1.2,1.3,1.7c0.6,0.9,1.1,1.8,1.3,2.8c0.3,1.1,0.2,2.2-0.3,3.2
		C180.7,328.5,180.1,329.3,179.4,330z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M183.8,273.7c1.3,0,2.6-0.2,3.9-0.7c1-0.4,2-0.9,3-1.4c0.3-0.2,0.7-0.4,1.1-0.5c-0.7,0.1-1.3,0-2-0.3
		c-0.4-0.2-0.8-0.3-1.3-0.4c-0.2,0-0.2,0-0.4,0.1c-0.2,0.1-0.4,0.2-0.6,0.3c-0.5,0.2-1,0.5-1.6,0.8c-0.7,0.4-1.5,0.6-2.3,0.7
		c-0.9,0-1.7-0.2-2.4-0.7c-0.6-0.4-1.1-0.8-1.5-0.9s-0.4-0.2-0.9,0c-0.5,0.2-1,0.4-1.6,0.4c-0.4,0-0.7,0-1.1,0
		c0.4,0.2,0.7,0.4,1,0.7C179,273.2,181.4,273.9,183.8,273.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M177.9,329l0.2-0.6c-0.3,0.4-0.4,0.9-0.4,1.4c0,0,0,0,0,0c0,0,0,0,0,0C177.8,329.6,177.8,329.3,177.9,329z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M207.8,277.4c-0.6-1.8-1-3.4-1.2-4.6c-0.1,0-0.1,0.1-0.2,0.1l-0.3,0.1l-0.6,0.3c-0.8,0.4-1.4,0.8-2,1.2
		c-0.9,0.6-1.8,1.2-2.6,1.9c-1.4,1.3-2.6,2.6-3.8,4c-0.9,1-1.9,2.2-3,3.5l-4.1,5l-0.5,0.1l-0.9,0.1c-0.4,0.1-0.9,0.2-1.5,0.2
		c-1.4,0.1-2.8,0.1-4.2,0c-1.3-0.1-2.6-0.2-3.9-0.4c0.5,0.9,1,2.1,1.5,3.5c0.6,2.1,1,4.2,1,6.3c0.7,0.5,1.6,1.1,2.7,1.7
		c1.9,1,3.9,1.8,6,2.2c2.6,0.5,5.3,0.5,7.9,0.2c4.1-0.4,8-1.8,11.3-4.2c2.1-1.4,3.7-3.4,4.7-5.7c0.3-0.9,0.6-1.9,0.7-2.9l0.1-1.1
		l0-0.2c-1.2-1.3-2.3-2.7-3.3-4.2C209.9,282.4,208.7,280,207.8,277.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M134.9,363.5c1.5-0.6,3.1-1,4.7-1.2c0.8-0.2,2.4-0.9,4.6-1.3c2.6-0.6,5.4-0.6,8-0.1c1.7,0.4,3.4,0.9,5,1.7
		c1.3,0.6,2.5,1.5,3.6,2.4c0.9,0.8,1.6,1.6,1.9,2.2c0.2-0.3,0.4-0.5,0.6-0.8c0,0,0-0.1,0.1-0.1c-0.7-1-0.1-6.2,1.3-12.3l0.3-1.2
		l0.1-0.3l0-0.2c0-0.2,0.1-0.3,0.1-0.5c0.1-0.7,0.1-1.3,0.1-2c0-1.4-0.1-2.6-0.2-3.7c-0.1-0.9-0.1-1.6-0.1-2.2
		c-0.1-0.2-0.2-0.4-0.3-0.5c-0.5-1-1-2.1-1.7-3.2c-2.1-3.7-4.4-7.3-6.8-10.7c-0.3-0.4-0.5-0.7-0.8-1.1c-0.6-0.4-1.2-0.8-1.8-1.3
		c-1.3-0.9-2.6-1.9-3.9-3l-2-1.7l-1.5-1.3l-0.9-0.7c-0.8,0.9-2.3,2.3-3.9,4.3c-1.5,2-2.8,4.2-3.7,6.5c-1.2,2.8-2,5.8-2.5,8.8
		c-0.4,3.1-0.5,6.2-0.3,9.4c0.1,3,0.1,5.6,0.1,7.9C135.2,360.1,135.1,362.3,134.9,363.5C134.9,363.5,134.9,363.5,134.9,363.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M155.5,319l-0.7-0.7l-0.2-0.2l-0.1-0.1c0,0-0.3-0.1-0.4-0.1c-0.3,0-0.6,0-0.9,0c-0.6,0-1.2,0.1-1.7,0.3
		c-0.6,0.2-1.2,0.5-1.7,0.8l-0.2,0.1l0.5,0.4l1.9,1.7l3.7,3.1l6.3,5.2c1.9,1.7,3.5,3,4.4,3.9c0,0,0,0,0-0.1c0.1-0.4,0.2-0.8,0.3-1.2
		c0.1-0.9,0.2-1.8,0.1-2.6c0-0.7-0.2-1.4-0.6-2.1c-0.2-0.2-0.3-0.5-0.5-0.7c-0.6-0.3-1.3-0.7-2.1-1.2c-1.6-1-3.1-2.1-4.5-3.2
		c-0.8-0.7-1.7-1.4-2.5-2.2L155.5,319z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M162.5,315.1c-0.1,0-0.2,0-0.3,0l-0.3,0.1l-0.4,0.1l-1.5,0.4c-1,0.3-1.8,0.5-2.5,0.6c0,0-0.1,0-0.1,0l0.4,0.4
		l1.3,1.3c0.8,0.8,1.5,1.6,2.2,2.3c0.7,0.7,1.3,1.3,1.9,1.9c-0.1-0.3-0.2-0.5-0.2-0.8c0-0.2,0-0.4,0-0.7c0-0.2,0.1-0.5,0.1-0.7
		c0.1-0.3,0.2-0.7,0.4-1c0.3-0.5,0.6-1,0.6-1.1c0-0.1,0-0.1,0-0.1c-0.1-0.2-0.2-0.4-0.3-0.6c-0.3-0.4-0.5-0.8-0.8-1.2
		c-0.1-0.2-0.2-0.4-0.3-0.6l-0.1-0.1v-0.3C162.6,315.1,162.5,315.1,162.5,315.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M133,370.4c0,0.2,0,0.4,0.1,0.6c0,0.1,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4l0.1,0.4
		c0.2-0.2,0.3-0.3,0.5-0.5c0.2-0.3,0.5-0.6,0.8-0.8c0.2-0.2,0.5-0.4,0.8-0.6c2-1.3,4.2-2.5,6.4-3.6c2.6-0.9,5.4-1.1,8.1-0.7
		c3.3,0.4,6.4,2,8.7,4.4c0.6,0.6,1,1.2,1.3,1.8c0.6-1.1,1.2-2,1.7-2.9c0.1-0.2,0.3-0.5,0.4-0.7c-0.6-0.3-1.5-0.7-2.6-1.3
		c-1.1-0.5-2.3-1-3.5-1.5c-1.5-0.5-2.9-0.9-4.4-1.2c-2.6-0.5-5.1-0.9-7.1-1.1c-1.5-0.2-3-0.2-4.4,0.1c-1.4,0.5-2.6,1.2-3.7,2.2
		c-0.8,0.7-1.5,1.4-2.1,2.3C133.4,368.5,133.1,369.4,133,370.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M169.5,342.4c-0.7-0.8-1.3-1.6-1.7-2.5c-0.8-1.5-1.2-3-1.3-4.7c0-0.1,0-0.3,0-0.4c-1.2-0.4-3.3-1.4-5.9-3
		c-0.4-0.2-0.7-0.5-1.1-0.7c2,2.6,3.8,5.3,5.4,8.1c0.6,1.1,1.2,2.3,1.7,3.3c0.5,1.1,0.9,2.2,1.3,3.2c0.4,1,0.7,2.1,0.9,3
		s0.4,1.9,0.5,2.7c0.1,0.8,0.1,1.6,0.2,2.3c0,0.6,0,1.2-0.1,1.7c-0.1,0.9-0.3,1.4-0.4,1.4c-0.1,0-0.3-0.5-0.4-1.4
		c-0.1-0.4-0.1-1-0.2-1.6l-0.2,1c-0.8,3.2-1.6,6.1-2.5,8.2c1.5-2.2,3.6-5.4,6.3-9.3c0.9-1.3,1.9-2.6,3-3.9c0.5-0.7,1.1-1.4,1.8-2
		c0.3-0.3,0.7-0.7,1-1l1-1c0.8-0.7,1.6-1.5,2.4-2.3c-0.6-0.3-1.1-0.6-1.7-0.9c-0.3-0.2-0.6-0.3-0.8-0.5l-0.2,0.4
		c-0.7,1.4-2.1,2.4-3.7,2.5c-1,0-1.9-0.2-2.8-0.6C171,343.9,170.1,343.2,169.5,342.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M207,348.3c-1.2,1-2.4,2-3.6,3.1l-0.4,0.4l-0.2,0.2c0,0,0,0,0,0h0.3l1.1-0.1l2.2-0.2c1.4-0.1,2.8-0.2,4.2-0.3
		c0.7-0.1,1.3-0.1,1.9-0.3s1.3-0.3,2-0.4c0.1,0,0.3-0.1,0.4-0.1c-0.2-0.1-0.5-0.1-0.7-0.2C210.8,349.7,208.4,348.9,207,348.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M177,336.7l-0.6,0.9c0,0,0,0.1-0.1,0.1c0.4,0.1,0.9,0.2,1.4,0.4c1,0.3,2.1,0.8,3.3,1.3c1,0.4,2.1,0.8,3.2,1.2
		c1-0.9,2.1-1.9,3.2-2.8c0.1-0.1,0.3-0.3,0.4-0.4c-0.4-0.1-0.9-0.2-1.4-0.3c-2.8-0.8-5.5-1.7-8.2-2.8c-0.2,0.5-0.4,1-0.6,1.5
		C177.4,336.1,177.2,336.4,177,336.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M235.2,346.2c0.1-0.4,0.3-0.8,0.4-1.2c-2.7,0.3-7.1,0.3-12.1-0.5c-0.5,0.4-1.1,0.8-1.7,1.1
		c-0.4,0.2-0.7,0.3-1.1,0.4l-0.6,0.1c-0.2,0.1-0.4,0.1-0.5,0.1c-0.9,0.1-1.8,0.2-2.7,0.3l-5.3,0.4c-0.9,0.1-1.8,0.2-2.6,0.3
		c-0.2,0-0.3,0.1-0.5,0.1c1.5,0.1,3.5,0.3,5.9,0.6c6.4,0.8,12.9,1.4,19.4,1.6C234.4,348.3,234.8,347.3,235.2,346.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M174.7,340.2c-0.1,0.2-0.1,0.4-0.1,0.5c0,0.2,0.1,0.4,0.3,0.5c0.2,0.3,0.6,0.4,0.9,0.4
		c0.3-0.1,0.6-0.1,0.9-0.3l0.6-0.2c-0.3-0.2-0.6-0.4-0.9-0.6c-0.5-0.4-0.9-0.8-1.3-1.1C174.9,339.7,174.8,339.9,174.7,340.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M178.8,330c-0.1,0-0.1,0-0.2,0.1c0.1,0,0.2,0.1,0.3,0.1C178.9,330.1,178.8,330,178.8,330z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M150.9,452.7c1.8-1.8,2.7-2.9,3.4-3.8c3.9-5.2,7-11,9-17.2c1.6-4.8,2.9-9.6,3.8-14.6c1-5.4,1.9-11.3,2.9-17.4
		c0.9-5.6,1.9-10.9,2.9-15.2c0.5-2.1,1-3.9,1.4-5.4l0.5-1.8l0.3-1c0.1-0.3,0.2-0.6,0.3-0.8l1.3-0.5l0.8-0.3l1.4-0.5
		c0.9-0.4,1.8-0.8,2.6-1.1c1.6-0.8,3.1-1.6,4.5-2.4c2.3-1.4,4.4-3,6.4-4.8c3.4-3,5.5-5.1,7.1-6.3c0.2-0.2,0.4-0.3,0.6-0.4
		c0.3-0.2,0.5-0.3,0.8-0.4c0.6-0.2,1.1-0.3,1.8-0.4l4.4-0.9c1.6-0.4,3.4-0.7,5.4-1.2c1-0.2,2-0.5,3-0.8c0.5-0.2,1-0.3,1.5-0.5
		c0.5-0.2,0.9-0.4,1.3-0.7c0.2-0.1,0.4-0.3,0.6-0.4c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.3,0.2-0.5
		c0-0.1,0-0.3-0.3-0.3c-0.2,0-0.3,0-0.5,0.1l-0.7,0.1l-0.9,0.2l-1.9,0.5c-0.7,0.2-1.2,0.4-2,0.5c-0.8,0.2-1.5,0.3-2.2,0.4
		c-1.4,0.2-2.8,0.4-4.3,0.5l-2.2,0.2l-1.1,0.1l-1.9,0.2l-2.2,0.1c-0.3,0-0.6,0-0.9-0.1c-0.3,0-0.6-0.1-0.7-0.2
		c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.4,0.1-0.8,0.3-1.2
		c0.2-0.3,0.4-0.5,0.6-0.8c0.2-0.3,0.5-0.5,0.7-0.8c0.5-0.5,1.1-1.1,1.6-1.6l1.1-1c1.4-1.3,2.8-2.5,4.4-3.8l0.6-0.5
		c0.3-0.3,0.7-0.5,1-0.6c0.6-0.2,1.1-0.3,1.7-0.4c1-0.1,1.9-0.2,2.8-0.3l5.3-0.3c0.9,0,1.7-0.1,2.5-0.2h0.1h0.1h0.1
		c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.1-0.1-0.1l-0.3-0.1c-0.7,0-1.5,0-2.2,0h-4.2c-1.3,0-2.6,0.1-3.9,0.1s-2.4,0.1-3.5,0.2
		c-0.2,0-0.3,0-0.5,0.1c-0.1,0.1-0.4,0.2-0.6,0.3l-1.3,0.8c-1.5,0.9-3,1.7-4.6,2.4c-2.7,1.2-4.4,1-4.4,0.8c0-0.3,1.4-0.6,3.7-2.2
		c1.2-0.7,2.5-1.7,4.1-2.9l1.3-0.9c0.2-0.2,0.4-0.3,0.8-0.5c0.4-0.2,0.7-0.3,1.1-0.4c0.6-0.1,1.2-0.2,1.7-0.3
		c0.1-0.1,0.2-0.3,0.4-0.4c0.5-0.5,1-0.9,1.5-1.4l0.5-0.4l0.2-0.2c0.1-0.1,0.2-0.2,0.4-0.3c0.4-0.2,0.8-0.2,1.2-0.1
		c0.2,0,0.3,0.1,0.5,0.1l0.3,0.1c1.2,0.5,2.4,1.1,3.6,1.8l1.5-0.1c0.8-0.1,1.5-0.1,2.3-0.1c1.3-0.1,2.7,0.1,4,0.3
		c0.4,0.1,0.8,0.4,1.2,0.7c0,0.1,0.1,0.1,0.1,0.2l0.2-0.1l0.5-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.4-0.4,0.6-0.6
		c0.1-0.1,0.1-0.2,0.1-0.4c0-0.1-0.2-0.3-0.3-0.3c-0.3-0.1-0.6-0.2-0.9-0.2h-0.1l-0.3,0l-0.6-0.1l-1.2-0.2c-1.6-0.2-3.4-0.4-5.4-0.6
		c-0.5-0.1-1.1-0.1-1.6-0.3l-1.5-0.4l-3.1-0.7l-1.6-0.4l-0.8-0.1h-0.1l-2.6,1.5c-2.1,1.2-4.2,2.5-6.5,3.7c-0.4,0.2-0.8,0.4-1.2,0.6
		s-0.7,0.3-0.8,0.3l-8.6,3.3l6.7-6.3c3-2.8,5.8-5.3,8.5-7.6l1-0.9c0.2-0.1,0.3-0.3,0.6-0.5c0.3-0.2,0.5-0.3,0.7-0.4
		c0.9-0.4,1.6-0.7,2.4-1c1.5-0.6,3-1.1,4.3-1.6l1.9-0.7c0.5-0.2,1.1-0.4,1.6-0.7c0.4-0.3,0.8-0.6,1.1-1c0.3-0.3,0.5-0.7,0.8-1.1
		c0.2-0.3,0.3-0.6,0.4-0.9c0.1-0.3,0.2-0.6,0-0.9c-0.2-0.1-0.5-0.1-0.7,0c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0.1-0.4,0.1-0.7,0.3l-1.1,0.5
		c-0.8,0.4-1.7,0.8-2.7,1.3c-1,0.5-2.1,1.1-3.4,1.6c-1.3,0.5-2.7,1-4.2,1.4c-0.7,0.2-1.4,0.4-2.1,0.7c-0.3,0.1-0.5,0.3-0.7,0.5
		l-0.9,0.8l-3.9,3.6c-2.7,2.5-5.6,5.3-8.7,8s-6.1,5.3-8.9,7.8l-1,0.9l-1,0.9c-0.6,0.6-1.2,1.2-1.8,1.8c-1.2,1.2-2.3,2.4-3.3,3.5
		c-4.1,4.6-7,8.2-8.4,10.1c-0.3,0.5-0.8,1.1-1.2,1.8c-0.3,0.4-0.5,0.7-0.7,1.1c-0.2,0.4-0.5,0.9-0.7,1.4c-0.9,2-2,4.5-3.2,7.3
		c-2.5,5.6-5.5,12.6-9.2,20c-2.4,4.6-5,8.7-7.3,12.5c-2.3,3.7-4.2,7.1-5.7,10.1c-2.9,5.9-3.4,9.9-3.9,9.9c-0.1,0-0.2-0.3-0.3-0.9
		c-0.1-0.7-0.1-1.5,0-2.2c0.3-2.7,0.9-5.4,1.9-7.9c1.4-3.7,3.2-7.3,5.1-10.7c2.2-3.9,4.7-8,7-12.4c3.7-7.3,7.3-14.1,10.3-19.4
		c0.5-0.9,0.9-1.7,1.4-2.5c-0.5-0.3-1.2-0.8-2-1.3c-2.3-1.4-4.9-2.4-7.6-2.8c-2.3-0.4-4.7-0.6-7.1-0.6c-1.4,0-2.9,0.3-4.2,0.9
		c-0.6,0.4-1.1,0.9-1.6,1.5c-0.2,0.2-0.3,0.4-0.5,0.7c-0.2,0.3-0.3,0.5-0.5,0.9c-0.3,0.6-0.6,1.3-0.9,2c-0.3,0.7-0.7,1.4-0.9,2
		c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2-0.1-0.2-0.1c-0.3,2.8-0.9,7.1-1.8,12.6c-1.2,5.7-1.9,11.4-2.2,17.2c-0.1,3.2-0.1,6.7,0,10.2
		c0.1,3.6,0.4,7.3,0.8,10.9c0.5,3.9,1.4,7.8,2.6,11.5c0.6,1.7,1.3,3.3,2,4.9c0.7,1.6,1.5,3.1,2.2,4.5c1.5,2.8,2.8,5.2,3.9,7.2
		l1.4,2.6c0.2,0.2,0.1,0.2,0.2,0.3l0.1,0.3l0.2,0.5c0.3-0.1,0.6-0.3,0.9-0.5c0.5-0.3,1-0.7,1.4-1c0.9-0.7,1.7-1.3,2.4-1.9
		c0.7-0.6,1.3-1.2,1.9-1.7l0.7-0.7c0,0,0-0.1,0-0.1c0-0.1,0.1-0.1,0.2-0.1L150.9,452.7z"
          />
          <g>
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M315.6,304.1c-0.3,0.2-0.6,0.3-1,0.4c-0.3,0.1-0.7,0.1-1,0.1c-0.8,0-1.5-0.2-2.1-0.7
			c-0.1,0.5-0.2,1.1-0.3,1.7c0,0.2,0,0.4,0,0.6c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.2c0.1,0.3,0.3,0.6,0.5,0.8c0.3,0.3,0.7,0.6,1.1,0.9
			c0.3,0.2,0.7,0.4,1.1,0.4c0.3,0,0.5,0,0.8-0.2c0.3-0.2,0.5-0.5,0.6-0.8c0.2-0.4,0.3-0.8,0.4-1.3c0.1-0.4,0.1-0.8,0.1-1.2
			C315.8,304.8,315.7,304.4,315.6,304.1C315.6,304,315.6,304.1,315.6,304.1z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M312.4,262.5c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.2,0.2-0.4,0.1-0.6c-0.1-0.7-0.4-1.4-0.8-2
			c-0.2-0.3-0.3-0.5-0.5-0.7c0,0.1-0.1,0.2-0.1,0.3c-0.3,0.5-0.6,1.1-1,1.5c-0.2,0.3-0.5,0.5-0.8,0.7c-0.4,0.2-0.8,0.4-1.2,0.4
			c-0.2,0-0.3,0-0.5,0c0.2,0.4,0.3,0.8,0.7,1.1c0.4,0.4,1,0.7,1.6,0.7c0.2,0,0.3,0,0.3,0s0.2-0.1,0.4-0.1
			C311.7,262.9,312,262.7,312.4,262.5z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M318.4,281.5c-0.4,0.3-0.8,0.6-1.3,0.7c-1,0.4-2.1,0.2-3-0.3c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1,0.5-0.3,1.3-0.4,2.2c0,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.2,0.1,0.3c0.1,0.2,0.3,0.4,0.5,0.5c0.4,0.3,0.8,0.5,1.2,0.6
			c0.5,0.2,1,0.2,1.5,0.2c0.4,0,0.8-0.1,1.2-0.2c0.2-0.1,0.4-0.3,0.5-0.5c0.3-0.8,0.4-1.6,0.3-2.4
			C318.6,282.4,318.5,281.9,318.4,281.5z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M412.9,190.5c-0.3-0.3-0.6-0.6-0.9-0.8c-0.1,0.3-0.3,0.6-0.5,0.9c-0.5,0.7-1.3,1.2-2.2,1.4
			c0.2,0.3,0.4,0.6,0.7,0.9c0.5,0.6,1.2,1,1.9,1.3c0.4,0.1,0.8,0.2,0.8,0.1c0.3-0.1,0.5-0.2,0.8-0.4c0.5-0.4,0.7-1,0.6-1.5
			C413.9,191.6,413.4,191,412.9,190.5z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M427.4,258.4c-0.6,0.3-1.2,0.4-1.9,0.3c-1.1-0.1-2.1-0.7-2.8-1.7c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0
			c-0.4,0.6-0.7,1.2-0.8,1.9c-0.1,0.5-0.1,1-0.1,1.5c0,0.4,0.1,0.7,0.4,1c0.3,0.3,0.7,0.5,1.1,0.6c0.6,0.2,1.3,0.3,1.9,0.3
			c1.2,0,2.4-0.3,3.4-0.9c0.3-0.2,0.5-0.5,0.5-0.8c0.1-0.4,0.1-0.8,0-1.2c-0.1-0.5-0.4-1.1-0.7-1.5
			C428.1,258,427.8,258.2,427.4,258.4z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M426.9,281.5c-0.9,0.7-2.1,1-3.2,0.8c-1-0.1-1.8-0.6-2.5-1.3c-0.3,0.6-0.6,1.4-0.7,2.5
			c-0.1,0.5-0.1,1.1,0,1.7c0,0.4,0.2,0.8,0.5,1.2c0.4,0.3,0.8,0.6,1.3,0.7c0.4,0.1,0.7,0.2,1.1,0.2l0.6,0.1c0.2,0,0.3,0,0.5,0
			c0.6,0,1.3-0.1,1.9-0.3c0.5-0.2,0.8-0.5,1.1-0.9c0.3-0.4,0.4-0.9,0.4-1.4c0-0.5,0-0.9-0.2-1.4C427.6,282.6,427.3,282,426.9,281.5
			C426.9,281.4,426.9,281.4,426.9,281.5z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M427.6,304.2c-0.2,0.2-0.4,0.4-0.6,0.5c-0.8,0.5-1.8,0.8-2.8,0.8c-1.1,0-2.2-0.4-3.1-1c0,0-0.1-0.1-0.1-0.1
			c0,0.1-0.1,0.1-0.1,0.2c-0.3,0.9-0.5,1.8-0.6,2.7c-0.1,0.6-0.1,1.2-0.1,1.8c0.1,0.5,0.3,0.9,0.6,1.3c1.1,0.9,2.5,1.3,3.9,1.1
			c1.4-0.1,2.8-0.8,3.7-1.9c0.7-0.8,0.9-2,0.4-2.9C428.5,305.7,428.1,304.9,427.6,304.2z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M422.2,234.3c0,0.4,0,0.8,0.1,1.1c0,0.2,0.1,0.4,0.1,0.6c0.1,0.2,0.2,0.3,0.3,0.5c0.2,0.4,0.5,0.7,0.8,0.9
			c0.8,0.6,1.8,0.9,2.9,0.9c0.9-0.1,1.7-0.5,2.2-1.3c0.3-0.7,0.4-1.6,0.1-2.3c-0.2-0.7-0.5-1.3-0.8-1.9c-0.1,0.2-0.2,0.4-0.4,0.6
			c-0.6,0.8-1.6,1.2-2.5,1.2c-1,0-1.9-0.4-2.5-1.1C422.2,233.6,422.2,233.9,422.2,234.3z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M422.2,216.4c0.7-0.1,1.3-0.5,1.6-1.2c0.2-0.7,0-1.5-0.4-2.1c-0.3-0.6-0.7-1-0.9-1.5
			c-0.7,0.9-1.8,1.3-2.9,1.1c-0.5-0.1-0.9-0.3-1.3-0.5c-0.1-0.1-0.2-0.1-0.2-0.2c0.1,0.6,0.1,1.3,0.2,2.2c0,0.2,0.1,0.5,0.1,0.7
			c0,0.1,0.1,0.3,0.1,0.4c0.2,0.2,0.5,0.4,0.8,0.5c0.5,0.2,0.9,0.4,1.4,0.5C421.2,216.4,421.7,216.5,422.2,216.4z"
            />
          </g>
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M225.9,374.2c2.9-2.3,6.1-4.1,9.6-5.3c0.4-0.1,0.8-0.3,1.2-0.4l1.3-0.3c0.8-0.2,1.6-0.3,2.4-0.4
		c1.5-0.2,3-0.4,4.4-0.5c4-0.2,7.9-0.2,11.9,0.1c1.1,0,2.7,0,4.7,0.2c2.5,0.2,4.9,0.7,7.2,1.5c1.3,0.4,2.7,1,4.1,1.4
		c0.7,0.2,1.4,0.4,2.1,0.5c0.3,0.1,0.7,0.1,1,0.1l1.3,0.1c1,0.1,2,0.2,3,0.4c0-0.5,0.1-1,0.4-1.7c0.6-1.6,1.6-3.7,2.7-6.3
		c0.5-1.3,1.1-2.8,1.6-4.4l0.4-1.3l0.2-0.7l0.2-0.3c0-0.1,0.1-0.3,0.2-0.5c0,0-0.1,0-0.1,0c0.3-2.7,0.4-5.4,0.5-7.9
		c0.1-2.4,0.1-4.7,0.2-6.6c0.1-3.8,0.3-6.2,0.8-6.2c0.6-0.1,1.3,2.2,1.9,6c0.4,2.2,0.6,4.5,0.7,6.7c0.1,1.9,0.1,3.8,0,5.7
		c3.8-1.3,7.6-2.4,11.5-3.2c2.2-0.5,4.4-0.9,6.5-1.2c2.1-0.3,4.2-0.6,6.1-0.7c3.5-0.2,6.9-0.2,10.4,0c2.7,0.1,4.8,0.4,6.4,0.6
		c-1.8-1-3.3-1.8-4.4-2.3c-1.3-0.6-3.3-1.5-5.8-2.5c-1.3-0.5-2.7-1.1-4.2-1.8c-1.5-0.8-3-1.7-4.6-2.7c-3.2-2-6.7-4.5-10.3-7.3
		c-1.8-1.4-3.6-2.9-5.5-4.5c-1.8-1.7-3.6-3.6-5.2-5.6c-2-2.4-3.9-4.8-5.7-7.2c-0.9-1.2-1.8-2.4-2.6-3.5c-0.8-1.1-1.7-2.4-2.4-3.5
		c-0.3-0.6-0.7-1.2-1-1.7c-0.9-0.3-2.1-0.8-3.5-1.4c-4-1.7-8.2-2.9-12.5-3.6c-3.1-0.5-6.3-0.5-9.4,0c-0.3,0.4-0.7,1-1.2,1.6
		c-2.1,2.9-4,5.9-5.6,9.1c-2.2,4.8-3.9,9.8-4.8,15c-0.6,3.1-1,6.2-1.3,9.3l-0.1,1.2c0,0.5-0.1,0.9-0.2,1.4c-0.1,0.9-0.3,1.8-0.5,2.6
		c-0.4,1.8-0.9,3.5-1.6,5.2c-1.2,3.3-2.7,6.6-4.5,9.6c-1.9,2.9-3.8,5.4-5.5,8c-3.5,5-6.3,9.6-8.5,13.6c-0.4,0.7-0.8,1.4-1.2,2.1
		C221,378.3,223.3,376.1,225.9,374.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M408.4,341.9c-0.3-0.3-0.7-0.5-1-0.8c-0.3-0.3-0.6-0.6-0.9-0.8c-0.2-0.2-0.5-0.4-0.7-0.5
		c-0.2-0.1-0.3-0.1-0.5,0c-0.1,0-0.1,0.1-0.2,0.2l-0.3,0.4c-0.5,0.7-1.1,1.4-1.8,1.9c1,0,2.1,0,3.2-0.1
		C406.9,342,407.7,341.9,408.4,341.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M397.8,264.9c0.1-0.9,0.2-1.8,0.2-2.7c0-0.1,0-0.1,0-0.2c-0.2-0.3-0.3-0.7-0.4-1.1c0-0.1,0-0.1,0-0.2
		c-0.6,0.8-1.4,1.7-2.4,2.8c-2.3,2.7-5.5,6.4-8.9,10.7c-2.7,3.4-5.5,6.5-7.7,9c-1.1,1.2-2.2,2.3-3.1,3.2l-0.7,0.6
		c-0.2,0.2-0.5,0.4-0.7,0.5s-0.5,0.2-0.7,0.3l-0.5,0.1c-2.3,0.6-4.7,1.1-7.1,1.4c-1.7,0.2-3.6,0.5-5.6,0.6c-1,0.1-2,0.1-3.2,0.1
		h-0.3c-0.1,0.4-0.1,0.7-0.2,1.1c-0.1,0.2-0.2,0.4-0.3,0.6c0,0.1-0.1,0.1-0.2,0.2l-0.1,0.1l0,0c0,0.2,0,0.3,0,0.5c0,0.3,0,0.6,0,1
		v0.6c0,0.4-0.1,0.7-0.2,1.1c-0.1,0.3-0.2,0.5-0.4,0.7c1,0.3,2.3,0.9,4.1,1.5c4.4,1.5,9,2.8,13.6,3.7c8.3,1.8,15.6,2.5,18.5,3.2
		c0.1-0.3,0.2-0.6,0.2-0.9c0.3-0.8,0.4-1.7,0.5-2.6v-0.2c0-0.1,0-0.3,0-0.4c0-0.3,0-0.6,0-0.9c0-0.7,0.2-1.3,0.5-1.9
		c0.3-0.4,0.5-0.8,0.8-1.2c0.2-0.3,0.3-0.6,0.4-0.9c0.2-0.8,0.2-1.5,0.2-2.3c0.1-0.4,0-0.8,0.1-1.3c0.1-0.4,0.2-0.9,0.3-1.3
		c0.3-0.7,0.6-1.4,1-2.1c0.2-0.3,0.4-0.6,0.6-0.9c0.1-0.1,0.2-0.3,0.3-0.4s0.3-0.3,0.3-0.3c0.3-0.4,0.4-0.8,0.5-1.3
		c0.2-0.8,0.4-1.6,0.4-2.5c0-0.2,0-0.3,0-0.5c0-0.3,0-0.5,0-0.8c0.1-0.5,0.3-1,0.5-1.5l0.8-1.5c0.1-0.2,0.2-0.5,0.3-0.8
		c0.1-0.2,0.1-0.5,0.1-0.7c0-0.6-0.2-1.3-0.4-1.9c-0.1-0.3-0.2-0.7-0.4-1.1c-0.2-0.5-0.2-1.1,0-1.7c0.3-0.7,0.5-1.5,0.6-2.2
		c0-0.7-0.3-1.4-0.7-2c-0.3-0.4-0.5-0.9-0.7-1.4c-0.1-0.2-0.1-0.3-0.2-0.5c0-0.2-0.1-0.4-0.1-0.6c0-0.2,0-0.4,0-0.6L397.8,264.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M210.3,396.5c0.3-1,0.6-2.1,1.1-3.2c0.5-1.1,0.9-2.4,1.6-3.6c0.3-0.6,0.7-1.3,1-1.9c0.6-2.5,1.8-6.2,3.8-10.5
		c2.3-4.9,5-9.7,8-14.3c1.6-2.6,3.5-5.3,5.2-7.9c0.4-0.6,0.7-1.3,1.1-2c-3.3-0.2-6.5-0.5-9.7-1c0,0.1,0,0.2,0,0.2
		c-0.1,0.9-0.6,1.8-1.2,2.4c-0.2,0.2-0.3,0.3-0.5,0.5c-0.2,0.1-0.3,0.3-0.5,0.4c-0.3,0.2-0.6,0.4-0.8,0.5c-0.6,0.3-1.1,0.6-1.8,0.8
		c-0.6,0.2-1.1,0.3-1.7,0.5c-1.1,0.3-2.1,0.4-3.1,0.6c-2,0.3-3.8,0.6-5.5,0.8c-1.7,0.2-3.1,0.4-4.4,0.6c-0.6,0.1-1.1,0.2-1.7,0.3
		c-0.4,0.2-0.8,0.4-1.1,0.6c-1.5,1.1-3.4,3.3-6.7,6.6c-2,2-4.1,3.8-6.4,5.3c-1.5,1-3,1.9-4.5,2.7c-0.8,0.4-1.7,0.9-2.6,1.3l-1.4,0.6
		l-0.9,0.4v0.2c0,0.1,0,0.3,0,0.4l-0.3,1.9c-0.3,1.4-0.6,3.3-1,5.4c-0.8,4.2-1.7,9.5-2.6,15.1c-0.5,3.1-1,6.2-1.6,9.2
		c2.1,4.4,4.7,8.6,7.7,12.4c2.3,3,4.1,4.6,3.8,5.1c-0.2,0.3-1.1,0.1-2.4-0.7c0.5,0.9,1,2.1,1.6,3.4l1.4,3.1c0.3,0.5,0.5,1.1,0.8,1.6
		c0.1,0.3,0.3,0.5,0.4,0.8c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.1,0.3,0.3l0.4,0.3
		c0.2,0.2,0.5,0.4,0.8,0.6c0.5,0.4,1.1,0.8,1.7,1.3c1.2,0.9,2.4,1.8,3.7,2.6c4.4,3,9,5.8,13.7,8.3c1.8,0.9,3.3,1.7,4.3,2.2
		c0.1-2.2,0.4-4.2,0.6-6.1c0.2-2.3,0.4-4.5,0.6-6.5c0.3-4.1,0.2-7.9,0.2-11.1c-0.1-3.1-0.1-5.7-0.1-7.5c-1.2-2.2-2-4.6-2.5-7.1
		c-0.8-4-0.8-8.1,0-12C209.5,399.6,209.8,398,210.3,396.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M75.3,445.8l-1-1.1c0.1,0.4,0.2,0.9,0.2,1.3c0,0,0,0,0,0C74.7,445.9,75,445.8,75.3,445.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M394.4,495.7c-0.3,0-0.7,0.1-0.9,0.1l0.8,0.1c1.1,0.1,2.1,0.2,2.9,0.2c1,0.1,1.8,0.2,2.2,0.4
		c-0.1-0.1-0.1-0.1-0.2-0.2c-0.4-0.4-1-0.7-1.7-0.7C396.5,495.4,395.5,495.5,394.4,495.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M223.3,470.4l-1.1-0.3l-1.2-0.3c-0.6-0.2-1.1-0.6-1.5-1c-0.3-0.3-0.6-0.7-0.9-1.1c-0.5-0.7-1-1.5-1.4-2.3
		c-0.2-0.4-0.4-0.9-0.6-1.3l-0.3-0.7l-0.1-0.4l-0.1-0.2v-0.1c-0.3-0.7-0.6-1.4-1.1-2c-0.5-0.7-1-1.4-1.5-2.2l-1.8-2.3l-0.5-0.6
		c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.2-0.3-0.4-0.3-0.6c-0.1-0.2-0.2-0.5-0.2-0.7l-0.1-0.6v-0.6c-1.2-0.1-3-0.5-5.3-1.3
		c-5.2-1.9-10.1-4.5-14.7-7.7c-1.3-0.9-2.6-1.9-3.8-2.8c-0.6-0.5-1.2-1-1.8-1.5c-0.3-0.3-0.6-0.5-0.8-0.8c-0.3-0.3-0.6-0.6-0.9-0.9
		c-0.2-0.2-0.3-0.4-0.4-0.5c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.2-0.4-0.3-0.6l-0.1,0.5c-0.1,0.3-0.2,0.7-0.4,1l-0.3,0.5l-0.3,0.5
		c-0.2,0.3-0.4,0.6-0.7,0.9l0,0.2c0.1,0.9,0.2,1.7,0.4,2.6c0.1,0.5,0.3,0.9,0.5,1.3c0.2,0.4,0.6,0.9,0.9,1.4c0.7,1,1.4,2.1,2.3,3.3
		c1.9,2.6,4,5,6.3,7.3c0.3,0.3,0.6,0.6,1,0.9l1.1,0.9c0.8,0.6,1.5,1.3,2.2,2c1.5,1.4,3,2.8,4.5,4.3c3.1,3,6.3,6.2,10,9.2
		c0.4,0.4,0.9,0.7,1.4,1l1.5,1c1.1,0.7,2.1,1.5,3.2,2.2c2.1,1.5,4.1,3.1,6.2,4.7l6.3,4.9l3.2,2.5l1.6,1.2c0.1,0.1,0.2,0.1,0.2,0.2
		c0-0.2,0.1-0.3,0.1-0.5c1.4-7.8,2.5-13.1,3.2-14.8c-0.6-0.3-1.1-0.6-1.5-0.8C231.3,472.9,227.3,471.5,223.3,470.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M399.6,492c-0.6,0.2-1.1,0.3-1.7,0.3c-1.7,0-3.4-0.2-5.1-0.5c-0.3,0.3-0.6,0.5-0.7,0.9
		c-0.2,0.4-0.3,0.9-0.4,1.3c0.2-0.1,0.3-0.1,0.5-0.2c0.3-0.1,0.7-0.2,1-0.2c0.4,0,0.7-0.1,1.1-0.1c1.2-0.1,2.5,0,3.7,0.3
		c0.4,0.1,0.7,0.3,1,0.5c0-0.1,0-0.1,0.1-0.2c0.2-0.4,0.5-0.8,0.9-1.1C399.8,492.9,399.7,492.5,399.6,492z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M356.7,396c0,0-0.1-0.1-0.1-0.1l-0.2-0.1l-0.6-0.4c-1.7-1.4-3.5-2.7-5.4-3.9c-0.9-0.6-2-1-3-1.3
		c-0.5,0-0.9,0-1.4,0.2c-0.6,0.2-1.1,0.4-1.6,0.7c-2.2,1.4-4.3,3-6.2,4.9c-0.7,0.8-1.4,1.7-1.9,2.6c-0.6,0.9-1.1,1.8-1.6,2.7
		c-1,1.8-1.8,3.2-2.5,4.1c2.2,0.7,3.9,1.5,4.9,2.1c1.2-0.6,2.5-1.2,3.9-1.9c0.3-0.1,0.6-0.3,0.9-0.5c-0.4-0.3-0.7-0.5-1.1-0.7
		c-0.7-0.5-1.1-1-1-1.2c0.1-0.2,0.7-0.3,1.7-0.1c0.5,0.1,1.1,0.3,1.6,0.5c0.3,0.1,0.6,0.3,0.9,0.5c0.1-0.1,0.2-0.1,0.3-0.2
		c1-0.6,2.2-1.2,3.4-1.7c-0.7-0.5-1.4-1.1-1.9-1.5c-0.9-0.8-1.5-1.4-1.3-1.7c0.2-0.3,1-0.1,2.1,0.4c1.1,0.5,2.2,1.1,3.2,1.8
		c1.7-0.7,3.5-1.4,5.2-1.9l-2.4-1.8c-2.5-1.9-4.4-3.5-4.2-4c0.3-0.5,2.8,0.3,5.4,2.3c1.1,0.8,2.1,1.9,2.9,3c0.1,0,0.3-0.1,0.4-0.1
		c0-0.1,0-0.3,0-0.4c0.1-0.6,0-1.1-0.1-1.6C357,396.3,356.9,396.1,356.7,396z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M162,521.1l-0.7-3.3c-0.2-1.2-0.5-2.4-0.6-3.6c-0.5-4-0.6-8-0.4-12c-0.6,3.6-1.3,7.1-2.1,10.4
		c-1.3,5.3-2.5,10-3.5,14c-1.3,5.1-2.4,9-3.1,11c0.2,0.1,0.5,0.1,0.7,0.2c0.9,0.3,1.8,0.7,2.6,1.2c0.4,0.2,0.8,0.4,1.2,0.7l0.3,0.2
		v0.1c-0.3,0.1,1-0.2,1.7-0.3c0.8-0.1,1.5-0.2,2.3-0.3c1.3-0.1,2.6,0,3.8,0.3c0.8,0.2,1.5,0.5,2.1,0.9c-0.7-3.3-1.7-7.7-2.9-13.2
		C162.9,525.4,162.5,523.3,162,521.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M402.5,496.8c1.5,0.2,3,0.6,4.5,1.2c1.6,0.6,3.2,1.5,4.8,2.2c0.7,0.3,1.4,0.6,2.2,0.8c0.1,0,0.3,0.1,0.4,0.1
		c0.1,0,0.3,0,0.4,0c0.4,0,0.7-0.1,1.1-0.1c1.5-0.2,2.9-0.6,4.2-0.8c0.1,0,0.1,0,0.2,0c-1-0.5-1.9-0.9-2.8-1.3
		c-1-0.5-2.1-0.9-3.1-1.1c-3.2-0.8-5.5-1.3-6.4-1.8c-1,0.1-2,0.2-3.3,0.3c-0.6,0-1.3,0-2,0s-1.3-0.1-2-0.1l-0.6-0.1
		c0,0.3,0,0.5,0,0.7C400.7,496.5,401.6,496.6,402.5,496.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M472,299.4l-0.5,0.2l-0.3,0.1l-0.2,0c-0.4,0.3-0.8,0.8-1,1.3c-0.3,0.5-0.5,1-0.8,1.5l-0.2,0.4l-0.1,0.2l0,0.1
		c-0.1,0.2-0.1,0.3-0.2,0.5c-0.3,0.9-0.4,2-0.7,3.2c-0.2,0.7-0.4,1.4-0.8,2c-0.5,0.9-1.4,1.5-2.4,1.7c-0.8,0.1-1.7,0-2.5-0.4
		c-0.6-0.2-1.1-0.6-1.7-0.9c-0.9-0.6-1.8-1.4-2.5-2.3c-0.7-0.7-1.1-1.6-1.3-2.6c-0.2,0.1-0.4,0.3-0.6,0.3c-0.4,0.2-0.7,0.4-0.9,0.8
		c-0.1,0.2-0.3,0.4-0.4,0.7c-0.1,0.2-0.2,0.4-0.2,0.7c0,0.6,0.1,1.2,0.3,1.7c0.8,1.7,1.4,3.6,1.7,5.4c0.1,0.6,0.1,1.2,0.1,1.8
		c0,0.8-0.2,1.5-0.5,2.2c-0.2,0.4-0.5,0.7-0.8,1c-0.3,0.2-0.5,0.4-0.6,0.5c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0-0.1,0.1
		c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.5-0.2,1-0.3,1.6c0,0.3-0.1,0.6-0.1,0.9c0,0.4-0.1,0.8-0.3,1.3c-0.1,0.3-0.2,0.5-0.4,0.8
		c-0.1,0.3-0.3,0.5-0.6,0.7c-0.5,0.3-1,0.6-1.6,0.7c-0.7,0.1-1.3,0.2-1.7,0.3c0,0.1,0,0.1-0.1,0.2c-0.1,0.3-0.3,0.6-0.5,0.8
		c-0.7,0.7-1.5,1.2-2.4,1.5l-0.6,0.2l-0.1,0c0,0,0.1,0,0,0.1c-0.2,0.4-0.4,1.1-0.8,2c-0.4,0.6-0.9,1-1.5,1.3
		c-0.6,0.4-1.3,0.5-2.1,0.4c-0.4,0-0.6-0.1-0.8-0.1l-0.6-0.1l-1.1-0.2c-0.5-0.1-1-0.2-1.5-0.2c0,0-0.1,0-0.1,0
		c-0.1,0.1-0.2,0.2-0.2,0.4c-0.4,1.4-1.2,2.7-2.3,3.8c-0.8,0.6-1.7,1-2.7,1.1c-0.9,0.1-1.8-0.1-2.6-0.5c-0.7-0.3-1.4-0.8-1.9-1.4
		c-0.6-0.6-1.1-1.2-1.4-2c-0.4-1.4-0.5-2.9,0-4.3l0.1-0.4c0-0.1,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0.1,0.1-0.3-0.4-0.4-0.6l-0.4-0.7
		l-0.2-0.3v-0.1c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0.1-0.3,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3
		c0.1,0.9,0.3,1.8,0.5,2.7c0.2,0.9,0.4,1.9,0.5,2.8c0.1,0.9,0,1.9-0.2,2.8c-0.2,0.9-0.5,1.7-1,2.4c-0.4,0.7-1,1.3-1.8,1.7
		c-0.3,0.2-0.6,0.3-0.8,0.4c0.7-0.1,1.3-0.2,2-0.3l3.4-0.6c1.1-0.2,2-0.4,2.9-0.6s1.6-0.3,2.3-0.5s1.2-0.4,1.7-0.5
		c0.2-0.1,0.4-0.1,0.6-0.2c0.1-0.1,0.1-0.2,0.3-0.2c0.1,0,0.1,0,0.2,0c4.3-1.6,13.7-6.4,23.8-13c11.1-7.1,19.5-14.5,22.8-16.7
		C479,306,475.6,302.9,472,299.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M13.9,383c0.4,0,0.9,0,1.3-0.1c0.3,0,0.5-0.1,0.7-0.2c0.2-0.2,0.5-0.4,0.7-0.5c0.8-0.6,1.7-0.8,2.6-0.6
		c0.2,0,0.4,0.1,0.5,0.2l0.3,0.2c0.1,0.1,0.3,0.1,0.5,0.2c0.4,0,1.2-0.4,2.2-0.7c-0.3-0.4-0.6-1.2-1-2.1c-0.2-0.4-0.4-0.8-0.6-1.3
		c-0.2-0.4-0.4-0.5-0.6-0.6c-0.5-0.1-1-0.1-1.6,0c-0.4,0-0.8,0-1.2-0.1c0.5,0.2,0.9,0.4,0.9,0.7c0,0.3-0.6,0.6-1.6,0.6
		c-0.6,0-1.2-0.1-1.8-0.4c-0.3-0.1-0.6-0.2-0.8-0.4l-0.7,0.3l-0.5,0.2l-0.3,0.1c-0.1,0-0.2,0-0.3,0h0l-0.1,0.1
		c-0.2,0.2-0.3,0.4-0.5,0.6c-0.1,0.1-0.2,0.2-0.4,0.3l-0.3,0.2c-0.6,0.4-1.1,0.9-1.4,1.2c0.3,0.2,0.6,0.3,0.9,0.5
		C12.2,381.9,13.5,382.9,13.9,383z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M455.6,482.8c0,0.5-2.3,1.1-5.3,1.5c-2.1,0.3-4,0.3-4.9,0c-0.3,0.1-0.6,0.2-1,0.2c-0.4,0.1-0.9,0.2-1.3,0.2
		c-0.5,0-1,0-1.4-0.1c-0.7-0.2-1.3-0.5-1.8-0.9c-0.7,0.6-2,1.3-4,2.2l-1.1,0.5c-0.3,0.1-0.6,0.2-0.9,0.3c-0.2,0-0.4,0-0.5,0.1
		c0,0.1,0,0.3,0.1,0.4c0.1,0.8,0,1.6-0.3,2.3c-0.2,0.5-0.5,1-0.9,1.4c-0.2,0.2-0.5,0.4-0.8,0.6c-0.3,0.2-0.6,0.3-0.9,0.4
		c-2.3,0.7-4.6,1.1-7,1.2c-1.3,0.1-2.7,0.2-4.1,0.2c-1.5,0-3.1-0.1-4.6-0.3c-0.5-0.1-0.9-0.2-1.3-0.3c-0.4-0.1-0.8-0.3-1.1-0.4
		l-2-0.8c-1.3-0.5-2.5-1-3.6-1.5c-1.1-0.4-2.1-0.8-3.1-1.2c-0.1,0.2-0.2,0.3-0.3,0.5c-0.4,0.7-1,1.4-1.7,1.9c0,0.3,0,0.5,0,0.8
		c0,0.1,0,0.2,0,0.3l0,0c0.2-0.1,0.4-0.1,0.5-0.1c0.8-0.2,1.6-0.3,2.5-0.3c1.4,0,2.7,0.2,4,0.5c1,0.2,1.8,0.8,2.4,1.6
		c0.2,0.3,0.2,0.5,0.2,0.8c0,0.1-0.1,0.1-0.1,0.2c1,0,2.2,0.1,3.6,0.2c1.3,0.1,2.5,0.4,3.8,0.8c1.3,0.4,2.6,1,3.9,1.5l4.2,1.8
		c0.7,0.3,1.5,0.7,2.1,0.9l0.3-0.3l3.5-3.3c1.2-1.1,2.4-2.1,3.5-3c2-1.7,4.3-3.2,6.6-4.4c4-2.2,6.9-2.5,7-2.1
		c0.2,0.5-2.2,1.6-5.6,4.1c4-1.8,9.1-4,14.9-6.2c-1.1-1.2-2.3-2.4-3.5-3.6l-1.3-1.2c-0.5-0.4-0.9-0.9-1.3-1.3
		c-0.7-0.7-1.3-1.5-1.9-2.2c-0.5,0-1.1,0-1.6,0c0.3,0.3,0.5,0.6,0.7,1c0.2,0.6,0.3,1.3,0.1,1.9c-0.1,0.6-0.3,1.1-0.5,1.7
		c-0.2,0.4-0.5,0.7-0.8,1.1c0.4-0.1,0.8-0.1,1.2-0.2C453.1,481.8,455.6,482.2,455.6,482.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M12.8,377.1C12.8,377.2,12.8,377.2,12.8,377.1C12.8,377.2,12.8,377.2,12.8,377.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M52.7,540.9c0.8-0.1,1.7-0.1,2.5-0.1c0.4,0,0.9,0.1,1.3,0.1l0.6,0.1h0.1l0.1-0.1l0.3-0.2
		c0.3-0.2,0.6-0.4,1-0.6c0.7-0.4,1.4-0.8,2.2-1.2c1.6-0.8,3.2-1.5,4.9-2c3.6-1.2,7.5-1.5,11.3-0.9c0.1,0,0.1,0,0.2,0
		c-1.1-2.2-3.1-8.3-4.7-15.5c-1.7-7.4-2.6-13.8-2.6-16.2c-1.1,1.2-2.2,2.4-3.4,3.7c-3.7,4.3-7.2,8.8-10.4,13.5
		c-2.3,3.2-4.6,6.6-6.9,10.2c1.4-1.5,2.8-2.9,4.1-4.1c4.3-4,7.4-6.7,9.2-8.1c0.1-0.1,0.2-0.2,0.3-0.3l0.5,0.6c0,0,0,0,0,0l0,0
		c-2,1.8-3.8,3.8-5.5,5.9l-2.1,2.7c-3.1,4.5-6,9.1-8.6,13.8c0.5-0.2,1.1-0.5,1.8-0.7C50.1,541.3,51.4,541,52.7,540.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M12.6,377.6C12.7,377.7,12.7,377.7,12.6,377.6C12.7,377.5,12.7,377.4,12.6,377.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M446.6,325.3L446.6,325.3C446.8,325.4,446.7,325.3,446.6,325.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M448.6,325.8C448.6,325.8,448.6,325.7,448.6,325.8L448.6,325.8C448.5,325.7,448.5,325.7,448.6,325.8z"
          />
          <g>
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M309.6,255.4c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.2-0.1-0.4-0.1-0.7c0-0.2,0-0.4,0-0.7c0-0.1,0.1-0.3,0.1-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0.1-0.3c0-0.2,0-0.4,0-0.6c0-0.3,0-0.7-0.1-1c-0.1-0.5-0.2-0.9-0.3-1.4c0.1-0.2,0.3-0.4,0.3-0.5
			c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.3,0.2-0.5c0-0.2,0.1-0.3,0.1-0.5c0-0.4-0.1-0.8-0.2-1.2c-0.1-0.3-0.3-0.7-0.4-1
			c-0.1-0.2-0.2-0.4-0.4-0.6l-0.1-0.2l-0.2-0.3c-0.1-0.2-0.3-0.4-0.4-0.6c0.1-0.3,0.2-0.6,0.3-0.8c0-0.1,0-0.1,0.1-0.2V243
			c0-0.1,0-0.1,0-0.2c0-0.2,0-0.3,0-0.5c0-0.4,0-0.9-0.1-1.3c0-0.2-0.1-0.5-0.2-0.8l-0.1-0.4c-0.1-0.2-0.2-0.5-0.3-0.8
			c0-0.1,0.2-0.4,0.4-0.9c0.1-0.3,0.2-0.6,0.2-0.9c0.1-0.7,0.1-1.3,0-2c0-0.3-0.1-0.7-0.2-1c0-0.2-0.1-0.3-0.1-0.5
			c-0.1-0.3-0.2-0.5-0.3-0.7l0.1-0.7c0-0.1,0-0.1,0-0.3s0-0.4,0-0.6c0-0.3,0-0.7-0.1-1c-0.1-0.5-0.3-1-0.5-1.5
			c-0.1,0.3-0.2,0.6-0.3,0.8c-0.1,0.4-0.4,0.7-0.6,1l-0.1,0.1c0.1,0.1-0.1-0.1-0.1-0.1l0,0v0.1c-0.1,0.3-0.2,0.7-0.2,1
			c-0.1,0.4-0.1,1-0.2,1.5c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.3,0.3,0.5,0.4,0.7c0.1,0.3,0.1,0.4,0.2,0.8l-0.1,0.4c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.3,0,0.4c0,0.3,0,0.7,0,1c0,0.3,0.1,0.6,0.2,1c0,0.2,0.1,0.4,0.1,0.5l0.1,0.5l0.2,0.6l-0.4,0.6l-0.2,0.3
			c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.1,0.3-0.2,0.5c-0.1,0.4-0.1,0.7-0.1,1.1c0.1,1.1,0.3,2.1,0.8,3.1l-0.4,0.9c0,0.1-0.1,0.2-0.1,0.3
			l-0.1,0.2c-0.1,0.2-0.2,0.4-0.2,0.7c-0.1,0.4-0.1,0.8-0.1,1.1c0,0.3,0,0.6,0.1,0.8l0.1,0.3v0.1l0,0.2c0.1,0.3,0.1,0.4,0.1,0.4
			c0,0.1,0,0.3,0,0.4c-0.1,0.2-0.1,0.4-0.1,0.5c0,0.1,0,0.3,0,0.4c0,0.2,0,0.4,0,0.5c0,0.2,0,0.4,0.1,0.6c0,0.2,0.1,0.4,0.2,0.5
			c0.2,0.4,0.5,0.9,0.8,1.2c0-0.1,0.1,0.1,0.2,0.3c0-0.1,0.1,0.1,0.1,0.4c0.1,0.4,0.2,0.8,0.3,1.2c0.1,0.5,0.3,1.1,0.5,1.6
			c0.1,0.2,0.2,0.4,0.4,0.5c0,0,0.1,0,0.1,0c0-0.1,0-0.1,0-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0.2-0.3,0.4-0.6,0.5-0.9
			c0.1-0.2,0.1-0.3,0.2-0.5c0-0.1,0.1-0.2,0.1-0.4c0-0.2,0-0.4,0-0.7c0-0.2-0.1-0.4-0.2-0.6L309.6,255.4z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M317.9,273.5c-0.1-0.2-0.1-0.3-0.2-0.5l-0.1-0.3l-0.2-0.3c0,0.1-0.1-0.2-0.2-0.4c0,0.1-0.1-0.3-0.1-0.4
			c0-0.1-0.1-0.5-0.2-1c-0.1-0.5-0.2-1-0.4-1.5c-0.1-0.3-0.2-0.5-0.3-0.8c-0.1-0.3-0.3-0.7-0.5-1V267v-0.2v-0.1c0-0.1,0-0.2,0-0.3
			c0-0.2-0.1-0.4-0.2-0.7c-0.1-0.3-0.3-0.5-0.4-0.8c-0.2,0.2-0.3,0.3-0.5,0.4c-0.6,0.4-1.2,0.8-1.9,1l0.1,0.1l0.2,0.1
			c0.1,0.1,0.2,0.1,0.4,0.2c0.5,0.2,1.2,0.4,1.8,0.6l-0.5,1.1l-0.3,0.6c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0.1,0,0.1v0.1
			c0,0.3,0,0.5,0,0.8c0,0.2,0.1,0.4,0.2,0.6l0.1,0.3c0,0.1,0.1,0.3,0.1,0.3c0.1,0.2,0.1,0.5,0.1,0.8c0,0.2,0,0.5,0,0.7l-0.2,0.3
			c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.1,0.4-0.2,0.5c-0.1,0.3-0.1,0.7-0.1,1c0,0.4,0.1,0.8,0.3,1.2v0.1
			c0,0.1,0.1,0.2,0.2,0.3c0,0,0,0.2,0,0.4c0,0.3,0,0.6,0,0.9c0.1,0.4,0.2,0.7,0.3,1.1c0.1,0.3,0.3,0.5,0.6,0.6c0.3,0,0.6-0.1,1-0.2
			c0.3-0.1,0.5-0.2,0.7-0.4c0.2-0.3,0.4-0.6,0.4-1c0.1-0.4,0.1-0.7,0.1-1.1c0-0.1,0-0.2,0-0.2v-0.3v-0.4c0.1-0.2,0.3-0.4,0.4-0.6
			l0.2-0.3c0-0.1,0.1-0.2,0.1-0.3c0.1-0.3,0.1-0.6,0-0.9C318,274,318,273.7,317.9,273.5z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M376.8,181.5c2.1-1.4,4.2-3.1,6.3-4.8c1.8-1.4,3.4-2.8,5-4.2c0.8-0.7,1.6-1.4,2.3-2.1
			c0.7-0.6,1.4-1.4,2.1-2.1c2.8-2.9,5.4-5.5,7.5-8c0.9-1.1,1.7-2.4,2.2-3.8c0.5-1.2,0.9-2.4,1.2-3.7c0.6-2.3,0.7-4.1,0.3-5.2
			c-0.5-1.6-1.9-2.9-3.5-3.3c-0.4-0.1-0.8-0.4-1.1-0.7c-0.3-0.3-0.5-0.6-0.7-0.9c-0.3-0.5-0.6-1-1.1-1.3c-0.2-0.1-0.5-0.3-0.7-0.3
			c-0.3-0.1-0.6-0.2-1-0.2c-0.7-0.1-1.5-0.2-2.3-0.3c-0.9-0.1-1.8-0.3-2.7-0.5c-0.7-0.3-1.5-0.4-2.2-0.4c-0.4,0-0.7,0.1-1,0.2
			c-0.2,0-0.4,0.1-0.6,0.2l-0.7,0.3c-0.6,0.2-1.2,0.3-1.8,0.4c-0.7,0.1-1.3,0-2-0.2c-0.6-0.1-1-0.3-1.4-0.3s-0.9-0.1-1.4-0.2
			c-1-0.1-2.1-0.3-3.1-0.3c-0.5-0.1-1-0.1-1.5-0.1H374c-0.2,0-0.4,0-0.6,0c-1,0.2-2,0.4-3,0.7c-1,0.3-2.1,0.7-3.1,1l-1.5,0.5
			l-1.5,0.6c-1.3,0.5-2.7,0.5-4.1,0.2c-0.9-0.3-1.7-0.5-2.6-0.7c-0.9,0.1-1.7,0.2-2.5,0.5l-0.8,0.2l-0.4,0.1c-0.2,0-0.4,0-0.6,0
			c-0.7,0-1.3-0.2-1.9-0.5c-0.7-0.4-1.5-0.8-2.3-1.1c-0.3-0.1-0.7-0.2-1-0.2c-0.2,0-0.5,0-0.7,0.1c-0.6,0.3-1.1,0.7-1.5,1.2
			c-0.3,0.3-0.6,0.6-0.9,0.9c-0.4,0.3-0.8,0.6-1.3,0.7c-0.8,0.2-1.5,0.4-2,0.6c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0-0.1,0.1-0.2,0.2
			c-0.1,0.2-0.3,0.4-0.5,0.6c-0.5,0.4-1.1,0.6-1.7,0.8c-0.5,0.2-1,0.3-1.5,0.5c-0.9,0.3-1.7,0.5-2.3,0.7c-0.6,0.3-1.2,0.8-1.6,1.3
			c-0.6,0.7-1.3,1.5-2,2.5c-0.4,0.5-0.8,1.1-1.3,1.5c-0.1,0.1-0.3,0.3-0.4,0.4c-0.2,0.1-0.4,0.3-0.5,0.4l-0.9,0.6
			c-0.3,0.2-0.5,0.3-0.8,0.5c-0.2,0.2-0.5,0.5-0.7,0.8c-0.6,0.7-1.3,1.3-2.1,1.8c-0.8,0.5-1.7,0.9-2.5,1.2l-0.6,0.2
			c-0.1,0.1-0.2,0.1-0.4,0.2c-0.2,0.2-0.4,0.4-0.6,0.6c-0.4,0.7-0.7,1.4-0.9,2.2c-0.1,0.5-0.3,0.9-0.4,1.5c-0.2,0.6-0.4,1.1-0.8,1.6
			c-1.2,1.9-2.7,3.7-4.4,5.1l-0.3,0.3c-0.1,0.1-0.3,0.3-0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.3-0.3,0.5-0.4,0.8
			c-0.2,0.9-0.2,1.8-0.1,2.7l0.1,1c0,0.3,0,0.5,0,0.8c0,0.3-0.1,0.6-0.2,0.9c-0.3,0.9-0.8,1.7-1.5,2.3c-0.5,0.5-1,1-1.3,1.4
			c-0.2,0.2-0.3,0.4-0.5,0.6c-0.1,0.1-0.2,0.3-0.2,0.4c-0.1,0.5-0.2,0.9-0.2,1.4c0,0.8-0.1,1.5-0.2,2.3c-0.1,0.5-0.4,1-0.7,1.4
			l-0.1,0.2c0,0-0.1,0.3,0.1,0.7c0.2,0.5,0.4,0.9,0.6,1.4c0.2,0.3,0.3,0.6,0.5,1c0.2,0.4,0.3,0.9,0.3,1.3c0,0.6-0.1,1.2-0.2,1.8
			c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.2,0.2,0.4c0.5,0.6,1.1,1.2,1.8,1.5c0.8,0.4,1.5,0.8,2.4,1.1c2.6,0.9,5.4,1.5,8.1,2
			c1,0.2,1.7,0.3,2.3,0.4c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.7-0.2-1.1-0.1-1.3c0,0,0-0.1,0-0.1c0-0.4,3-0.5,7.9-1
			c2.8-0.3,5.7-0.8,8.5-1.4c0.8-0.2,1.6-0.4,2.4-0.6s1.7-0.5,2.5-0.7l2.2-0.7l0.7-0.2l0.2-0.1c0,0,0.1-0.1,0.1,0l0.2,0.3l0.7,1.2
			c0.4,0.7,0.8,1.4,1.2,2.1c1.6-0.9,5-2.3,9.5-4.5c3.2-1.6,7-3.6,11.1-6l3.1-1.9l1.6-1C375.8,182.2,376.3,181.9,376.8,181.5z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M313.8,289.2c-0.1,0-0.2-0.1-0.4-0.2c0,0.2,0,0.4,0.1,0.7c0,0.2,0.1,0.4,0.2,0.6c0,0.1,0.1,0.2,0.1,0.3
			c0,0.1,0,0.1,0.1,0.2s0.1,0.3,0.2,0.4l-0.2,0.9l-0.2,0.9l-0.3,0.3c-0.1,0.1-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.2
			c-0.1,0.2-0.1,0.4-0.2,0.7v0.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c-0.1,0.6-0.1,0.7-0.1,0.7c-0.1,0.2-0.2,0.4-0.3,0.6
			c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0.2-0.1,0.4-0.2,0.7c0,0.3,0,0.6,0,0.9l0.1,0.3l0.1,0.4c0,0,0,0.2,0,0.4
			v0.4v0.4c0,0.3,0.1,0.6,0.2,1c0,0.2,0.2,0.4,0.4,0.5c0.2,0.1,0.5,0.1,0.7,0.1c0.2,0,0.3,0,0.5,0h0.4c0.2,0,0.3-0.1,0.5-0.2
			c0.1-0.2,0.3-0.4,0.3-0.6l0.1-0.3l0.1-0.3c0.1-0.4,0.1-0.5,0.1-0.5c0.2-0.3,0.3-0.4,0.3-0.4c0.2-0.2,0.3-0.4,0.4-0.6
			c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2,0-0.4-0.1-0.5c-0.1-0.1-0.1-0.2-0.1-0.4l-0.1-0.6c0,0,0-0.2,0-0.3c0-0.1,0-0.3,0-0.4l0.2-0.5
			c0-0.1,0.2-0.3,0.2-0.5s0.1-0.3,0.2-0.5c0.1-0.4,0.3-0.8,0.3-1.3c0-0.2,0-0.3,0-0.5v-0.2v-0.3c0.1-0.4,0.2-0.8,0.3-1.1
			c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.1-0.6c-0.5,0.1-0.9,0.2-1.4,0.1
			C315.6,289.7,314.7,289.5,313.8,289.2z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M417.2,198c-0.1-0.3-0.2-0.5-0.3-0.8c-0.2-0.4-0.4-0.9-0.6-1.2c-0.1,0.2-0.3,0.3-0.5,0.5
			c-0.6,0.6-1.3,0.9-2.1,1.1c0.1,0.2,0.3,0.4,0.4,0.5l0.5,0.6l0.9,1.1v0.8c0,0.2,0,0.2,0,0.4c0,0.2,0.1,0.4,0.1,0.7
			c0.2,0.6,0.4,1.1,0.6,1.7l0.1,0.4l0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0.1,0.2,0.2,0.4,0.3,0.7c-0.1,0.3-0.2,0.8-0.2,0.9l-0.1,0.5
			c-0.1,0.3-0.1,0.6-0.1,0.8c0,0.3,0,0.6,0,0.9c0.1,0.3,0.2,0.5,0.4,0.7c0.3,0.3,0.6,0.5,1,0.8c0.4,0.3,0.9,0.5,1.4,0.6
			c0.3,0.1,0.6,0.1,0.9,0.1c0.2-0.2,0.4-0.5,0.5-0.8c0.2-0.4,0.3-0.9,0.3-1.4c0-0.7-0.1-1.5-0.5-2.1c-0.1-0.1-0.2-0.2-0.3-0.3
			l-0.3-0.3l-0.6-0.6c0-0.1-0.1-0.2-0.1-0.4c0-0.2,0-0.3-0.1-0.5c-0.1-0.3-0.2-0.7-0.3-1c-0.3-0.7-0.7-1.6-1.1-2.6l-0.2-0.5
			l-0.1-0.2l-0.2-0.5c0-0.2,0-0.5,0-0.7C417.3,198.3,417.3,198.2,417.2,198z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M405.4,186.3l1.4,0.5l0.2,0.6c0,0.2,0.1,0.3,0.2,0.5c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.3,0.4,0.6,0.6,0.8
			c0.1,0,0.2,0.1,0.2,0.1h0.2h0.2c0.3-0.1,0.5-0.2,0.7-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.2
			c-0.1-0.2-0.3-0.3-0.4-0.5c-0.3-0.3-0.7-0.6-1-0.9l-0.5-0.4l-1-0.7v-0.5c0-0.2,0-0.4,0-0.4c0-0.1,0-0.2-0.1-0.3
			c-0.1-0.3-0.2-0.5-0.3-0.8c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1,0.2-0.1,0.3-0.2,0.5c-0.3,0.6-0.8,1.1-1.3,1.6c-0.1,0.1-0.2,0.1-0.3,0.2
			c0.2,0.1,0.4,0.2,0.7,0.3L405.4,186.3z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M423.7,269.5c-0.1,0.1-0.2,0.3-0.3,0.4l0,0.1c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.6-0.3,1.2-0.4,1.8
			c-0.1,0.5-0.1,1-0.1,1.6c0,0.6,0,0.6,0,0.7c-0.1,0.2-0.2,0.4-0.3,0.6l-0.3,0.5l-0.1,0.2c-0.1,0.1-0.1,0.2-0.1,0.3
			c-0.1,0.7,0,1.4,0.4,1.9c0.3,0.2,0.5,0.4,0.9,0.5c0.4,0.2,0.7,0.3,1.1,0.3c0.7,0.1,1.2,0.2,1.8-0.4c0.5-0.5,0.7-1.2,0.8-1.9
			c0-0.2-0.1-0.4-0.2-0.6l-0.7-1.8c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.1-0.3,0.1-0.5c0-0.1,0-0.3,0-0.4c0-0.6-0.1-1.2-0.1-1.8
			c0-0.2-0.1-0.5-0.1-0.7l-0.1-0.4c-0.1-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.3-0.2-0.4l0.1-1c0-0.3,0.1-0.6,0.1-0.9l0.2-0.3l0.1-0.1
			c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0-0.1,0-0.1,0.1-0.2c-0.5,0.1-1,0.1-1.4,0.1c-0.7,0-1.5-0.1-2.2-0.3
			c0,0.1,0.1,0.3,0.1,0.4c0.1,0.2,0.1,0.4,0.2,0.6l0.4,0.8v1V269.5z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M427.3,243.3L427.3,243.3c0-0.1,0-0.1,0-0.1c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.3,0.1-0.4
			c0.1-0.3,0.1-0.5,0.2-0.8c0,0,0-0.1,0-0.1c-0.3,0.1-0.7,0.2-1,0.2c-0.6,0.1-1.1,0-1.7-0.1c-0.3,0-0.5-0.1-0.8-0.2
			c0,0.4,0.1,0.7,0.1,1c0,0.2,0.1,0.4,0.2,0.6l0.1,0.3l0.1,0.1l0.2,0.3l0.1,1.1l0.1,1.1l-0.2,0.3l-0.1,0.2v0.1c0,0.1,0,0.2-0.1,0.2
			c-0.1,0.1-0.1,0.3-0.1,0.4c-0.1,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.1,0.6-0.1,0.9v0.3c0,0.1,0,0.2,0,0.4c0.1,0.2,0.1,0.4,0.1,0.7
			c0,0.2-0.1,0.4-0.2,0.6c-0.4,0.7-0.4,0.7-0.4,0.9c-0.1,0.3-0.1,0.6-0.1,0.9c0,0.4,0.1,0.9,0.2,1c0.1,0.1,0.2,0.1,0.4,0.2
			c0.2,0.1,0.4,0.3,0.6,0.4c0.3,0.2,0.6,0.3,0.9,0.3c0.3,0,0.5,0,0.8-0.1c0.4-0.2,0.8-0.4,1.1-0.7c0.5-0.4,0.9-1.1,1-1.8
			c0-0.2-0.1-0.3-0.1-0.5l-0.2-0.4l-0.1-0.3l-0.2-0.4c0,0.1-0.1-0.2-0.2-0.6c0,0.1,0-0.2,0-0.6c-0.1-1-0.2-2-0.4-3
			c-0.1-0.4-0.1-0.7-0.2-1.1l-0.1-0.3c-0.1-0.2-0.1-0.3-0.2-0.5l-0.1-0.6l0.1-0.6C427.2,243.5,427.3,243.4,427.3,243.3z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M424.2,220.3c0-0.3,0-0.5,0-0.7c-0.5,0.2-1,0.4-1.6,0.4c-0.3,0-0.6,0-0.9,0c-0.3,0-0.5,0-0.7-0.1
			c-0.2,0-0.5-0.1-0.7-0.2c0.1,0.5,0.3,0.9,0.5,1.4c0.1,0.2,0.2,0.5,0.4,0.7l0.2,0.3l0.1,0.2l0.1,0.1l0.2,0.2l0.2,0.5l0.1,0.2
			c0.1,0.4,0.2,0.7,0.2,0.7l-0.2,0.8c0,0.2,0,0.3,0,0.5c0,0.3,0,0.5,0,0.8c0,0.4,0.1,0.7,0.2,1.1c0.1,0.2,0.2,0.3,0.2,0.5
			c0,0.2,0.1,0.3,0,0.5l-0.1,0.6c0,0.1,0,0.2,0,0.2c0.1,0.7,0.3,1.3,0.7,1.9c0.1,0.2,0.2,0.4,0.4,0.5c0.1,0.1,0.2,0.2,0.3,0.2
			c0.3,0.1,0.6,0.2,0.9,0.2c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.6-0.4,0.8-0.8c0.3-0.6,0.5-1.2,0.5-1.8c-0.1-0.3-0.2-0.5-0.3-0.7
			c-0.1-0.2-0.2-0.4-0.4-0.6c-0.1-0.1-0.2-0.2-0.3-0.3l-0.4-0.5c0,0.1-0.1-0.2-0.3-0.5c0,0.1-0.1-0.2-0.2-0.6c0-0.1,0-0.3-0.1-0.4
			c0-0.1,0-0.1-0.1-0.4s-0.2-0.7-0.3-1.1c-0.1-0.3-0.2-0.5-0.2-0.8l-0.1-0.3v-0.1l-0.1-0.2l-0.2-0.4c0-0.3,0.1-0.7,0.1-1V220.3z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M421.8,299.7c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0.1,0.6c0,0.2,0.1,0.4,0.2,0.6
			c0.2,0.1,0.5,0.1,0.7,0c0.4,0,1.1,0.1,1.7,0.1c0.8,0.1,1.6-0.1,2.3-0.4c0.3-0.2,0.6-0.4,0.7-0.8c0.1-0.2,0.2-0.5,0.2-0.8
			c0-0.2-0.1-0.4-0.2-0.6c0-0.1-0.1-0.3-0.3-0.5c-0.1-0.2-0.2-0.3-0.5-0.8c-0.1,0.2-0.2-0.4-0.3-0.9c0,0,0,0,0-0.2l0.1-0.8
			c0-0.1,0-0.3,0.1-0.4c0-0.1,0-0.1,0-0.3c0-0.2-0.1-0.5-0.1-0.9c-0.1-0.3-0.2-0.7-0.3-1c0-0.1,0-0.1-0.1-0.3
			c-0.1-0.2-0.2-0.3-0.3-0.5l-0.6-1.1c0.2-0.3,0.5-0.7,0.8-1c-0.4,0.1-0.8,0.1-1.2,0.1c-0.3,0-0.6,0-0.9-0.1c-0.3,0-0.5-0.1-0.8-0.1
			c-0.2,0-0.3-0.1-0.5-0.1c0,0,0,0,0,0c0.1,0.2,0.2,0.3,0.4,0.6l0.2,0.3c0.1,0.1,0.1,0.3,0.1,0.4v0.8v1.1c-0.1,0.1-0.2,0.3-0.3,0.4
			c0,0,0,0.1,0,0.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.5-0.2,0.9-0.2,1.4v0.8v0.2c0,0.1,0,0.2,0,0.3c0,0.7,0,0.7,0,0.8
			c-0.1,0.3-0.2,0.5-0.3,0.7l-0.2,0.4l-0.1,0.2L421.8,299.7z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M472.2,289.6c0-1,0.1-1.8,0.1-2.5c0-0.2,0-0.5-0.1-0.7v-0.1l-0.1-0.2c0-0.1-0.1-0.3-0.1-0.5
			c0-0.3,0-0.5,0-0.8c0.1-0.8,0.2-1.6,0.4-2.4c0.4-1.2,0.6-2.4,0.7-3.6c-0.1-1.1-0.2-2.2-0.3-3.3c-0.2-1.5-0.5-3.1-1.1-4.5
			c-0.2-0.5-0.5-1.1-0.8-1.9c-0.4-0.9-0.7-1.8-0.9-2.8c0-0.3,0-0.7,0-1c0.1-0.4,0.1-0.6,0.2-0.9c0.1-0.5,0.2-0.9,0.1-1.4
			c-0.2-0.9-0.9-2.2-1.4-3.5c-0.6-1.5-1-3.1-1.2-4.7c0-0.2,0-0.5,0-0.7v-0.6c0-0.4,0-0.7-0.1-1c-0.1-0.6-0.2-1.2-0.5-1.8
			c-0.1-0.1-0.2-0.2-0.2-0.3l-0.1-0.2l-0.2-0.2c-0.4-0.4-0.7-0.9-0.9-1.3c-0.6-0.9-1-1.9-1.2-3c-0.1-0.6-0.2-1.2-0.2-1.8v-0.7
			c0-0.2,0-0.3,0-0.5c-0.1-0.8-0.3-1.7-0.5-2.5c-0.1-0.5-0.2-1-0.3-1.6c-0.1-0.6-0.1-1.2,0-1.8c0.1-1.1,0.3-1.9,0.3-2.5
			c0.1-1.1-0.8-2.3-1.8-4c-0.1-0.3-0.3-0.5-0.4-0.8c-0.1-0.4-0.2-0.7-0.2-1.1c0-0.4,0.1-0.8,0.2-1.1c0.1-0.2,0.2-0.3,0.3-0.5
			l0.2-0.3c0.5-0.7,0.5-0.8,0.5-1.2c0-0.1,0-0.2-0.1-0.3l-0.1-0.2l-0.1-0.3c-0.1-0.4-0.3-0.8-0.4-1.3c-0.2-0.8-0.2-1.6-0.3-2.3
			c-0.1-0.7-0.1-1.4-0.2-1.9c0-0.2-0.1-0.4-0.2-0.7v-0.1l-0.1-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c-0.1-0.2-0.2-0.4-0.2-0.5
			c-0.3-1.2-0.4-2.4-0.4-3.6c0-0.5-0.1-0.9-0.2-1.4c-0.1-0.3-0.2-0.7-0.4-1c-0.4-0.5-0.8-0.9-1.3-1.3c-0.4-0.3-1.4-0.9-1.3-2
			c0.1-0.9,0-1.7-0.3-2.5c-0.2-0.4-0.4-0.8-0.7-1.1c-0.2-0.2-0.4-0.3-0.6-0.5l-0.8-0.6c-0.5-0.4-1-0.8-1.5-1.3
			c-0.2-0.2-0.5-0.4-0.7-0.5c-0.1-0.1-0.2-0.1-0.3-0.2h-0.1l-0.2-0.1c-0.1-0.1-0.2-0.1-0.4-0.2c-0.4-0.3-0.7-0.7-1-1.2
			c-0.2-0.3-0.4-0.7-0.6-0.9c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0h-0.1l-0.2-0.1
			c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.3-0.4-0.4c-0.2-0.2-0.3-0.4-0.5-0.7c-0.4-0.8-0.7-1.5-1.1-2.1c-0.3-0.6-0.7-1.1-1.2-1.5
			l-1-0.8c-0.3-0.3-0.7-0.6-1-0.9c-0.7-0.6-1.3-1.3-2-2c-0.3-0.4-0.6-0.7-0.9-1.1c-0.2-0.2-0.3-0.4-0.5-0.7
			c-0.1-0.1-0.2-0.3-0.3-0.5l-0.1-0.1l0,0l-0.1-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c-0.3-0.2-0.7-0.4-1.1-0.7l-0.8-0.5
			c-0.4-0.2-0.7-0.5-1-0.8c-0.5-0.5-0.9-0.9-1.3-1.4c-0.6-0.8-1.3-1.4-2.1-2.1c-0.2-0.1-0.2-0.1-0.7-0.4c-0.3-0.2-0.6-0.4-0.9-0.7
			c-0.4-0.4-0.9-0.9-1.2-1.4c-0.5-0.7-1.1-1.4-1.8-2c-0.1-0.1-0.3-0.2-0.4-0.3l-0.6-0.3c-0.5-0.3-1.1-0.6-1.5-1
			c-0.7-0.7-1.4-1.4-2.1-2.2c-0.3-0.3-0.6-0.7-0.8-0.8c-0.1-0.1-0.2-0.2-0.3-0.2l-0.1-0.1c-0.2-0.1-0.3-0.2-0.5-0.2
			c-0.6-0.4-1-0.9-1.3-1.5c-0.2-0.5-0.4-0.8-0.5-1.1c-0.2-0.5-0.5-1-0.9-1.4l-0.3-0.3l-0.4-0.4c-0.3-0.3-0.5-0.5-0.8-0.8
			c-0.9-1-1.4-1.9-1.9-2.5c-0.4-0.6-0.9-0.9-1.2-1.2c-0.1,0.5-0.2,1-0.3,1.5c-0.2,0.6-0.4,1.3-0.7,1.9c-0.1,0.3-0.3,0.6-0.5,0.9
			c-0.1,0.1-0.2,0.3-0.3,0.5l-0.1,0.2v0.1v0.1l0.1,0.3v0.1v0.3c0,0.4,0.1,0.7,0.1,1c0,0.8-0.1,1.5-0.3,2.3c-0.2,0.8-0.7,1.6-1.3,2.2
			c-0.3,0.3-0.6,0.6-1,0.8c-0.3,0.2-0.5,0.3-0.8,0.4h-0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1v0.2c0,0.3-0.1,0.6-0.2,0.9
			c-0.1,0.6-0.4,1.3-0.7,1.8c-0.4,0.6-0.8,1.1-1.4,1.6c-0.3,0.2-0.6,0.4-0.9,0.6c-0.2,0.1-0.3,0.2-0.5,0.2l-0.2,0.1h0
			c0,0.3-0.1,0.5-0.2,0.8c0.4,0.3,0.8,0.6,1.1,1c0.3,0.3,0.5,0.6,0.7,0.9c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0,0.1,0.1,0.2
			c0.1,0,0.2,0,0.2,0.1c0.2,0.1,0.4,0.3,0.7,0.4c0.5,0.3,0.9,0.6,1.3,1c0.3,0.2,0.5,0.4,0.7,0.7c0.1,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.5,0.3,0.8c0,0,0,0,0,0c0.5,0,1.2,0.2,2.1,0.5c0.5,0.2,1,0.5,1.5,0.9c0.6,0.5,1.1,1.1,1.5,1.9
			c0.2,0.4,0.3,0.9,0.3,1.4c0,0.5,0,1-0.1,1.5c0,0.2-0.1,0.3-0.2,0.5c0.1,0.1,0.2,0.1,0.3,0.2c0.5,0.4,1,0.9,1.4,1.4
			c0.3,0.3,0.5,0.7,0.7,1.1c0.1,0.2,0.2,0.5,0.3,0.8c0,0.2,0.1,0.3,0.1,0.5l0.1,0.3c0.1,0.2,0.2,0.4,0.3,0.5
			c0.5,1.1,0.7,2.2,0.7,3.4c0,0.4-0.1,0.8-0.2,1.3c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.1-0.1,0.2c0.1,0.1,0.1,0.2,0.2,0.3l0.2,0.4
			l0.2,0.4c0.6,0.7,1.1,1.4,1.5,2.2c0.4,0.8,0.5,1.7,0.3,2.6c0.9,0.3,1.6,0.7,2.3,1.3c0.5,0.5,0.9,1,1.3,1.7
			c0.4,0.8,0.6,1.8,0.4,2.7c-0.2,1-0.7,2-1.4,2.7c0,0,0.1,0.1,0.1,0.1c0.2,0.4,0.4,0.8,0.6,1.2c0.1,0.3,0.2,0.5,0.3,0.8
			c0.1,0.3,0.1,0.9,0.1,0.7l0.1,0.1l0.2,0.5c0.2,0.3,0.3,0.7,0.4,1.1c0.2,0.6,0.2,1.2,0.1,1.8c0,0.2-0.1,0.5-0.2,0.7
			c-0.1,0.2-0.1,0.1-0.2,0.2c0,0-0.1,0.1-0.1,0.1s0,0.1,0,0.1v0.1l0.1,0.1l0.1,0.3c0.1,0.2,0.1,0.4,0.2,0.5c0.1,0.3,0.1,0.5,0.2,0.8
			c0.1,0.5,0.1,0.7,0.1,0.9c0.2,0.8,0.3,1.6,0.4,2.4c0,0.1,0,0.2,0,0.2c0.2,0,0.3,0.1,0.5,0.2c0.9,0.4,1.6,1.1,2.2,1.8
			c1,1.4,1.3,3.1,0.8,4.8c-0.3,1-1,1.8-1.8,2.4c0.1,0.2,0.3,0.5,0.4,0.9c0.2,0.6,0.3,1.3,0.3,1.9c0,0.1,0,0.3,0,0.4v0.1
			c0,0,0,0,0.1,0s0.1,0.1,0.1,0.1l0.1,0.4c0.2,0.5,0.3,1,0.5,1.6c0.2,1,0.1,2-0.2,2.9c-0.1,0.4-0.3,0.7-0.5,1
			c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.1,0-0.2,0.1c-0.1,0.1-0.2,0.2-0.2,0.2c0,0.1,0,0.2,0,0.2v0.1v0.2c0,0.1,0,0.2,0,0.4
			c0,0.2,0,0.4,0,0.6v0.6c0.2,0.9,0.2,1.9,0,2.9c-0.1,0.4-0.2,0.7-0.4,1.1c0.8,0.4,1.5,1.1,2,1.9c0.4,0.7,0.6,1.6,0.6,2.4
			c0,1.1-0.4,2.2-1.2,3c-0.6,0.6-1.3,1.1-2,1.4c0.1,0.3,0.3,0.7,0.3,1c0,0.3,0,0.6,0,0.9c0,0.2,0,0.3-0.1,0.5l-0.1,0.3
			c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0.1h0.1c0,0,0,0,0.1,0.2c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.4,0.2,0.8,0.3,1.2c0.2,1.1,0,2.3-0.6,3.3
			c-0.1,0.2-0.2,0.4-0.4,0.5c-0.1,0.1-0.3,0.3-0.3,0.3l-0.4,0.1v0.3c0,0,0,0.1,0,0.1v0.2c0,0.3,0,0.5,0,0.7c0,0.2,0,0.5,0,0.7
			c0.3,0.9,0.4,1.8,0.3,2.8c-0.1,0.4-0.2,0.7-0.3,1c0.8,0.5,1.6,1.1,2.1,1.9c0.5,0.7,0.8,1.6,0.9,2.4c0.3,2-0.6,3.9-2.1,5
			c0.1,0.4,0.1,0.8,0,1.3c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0.1,0.1,0.1,0.1h0.1l0.1,0.1l0.2,0.3
			c0.3,0.6,0.5,1.1,0.6,1.8c0.2,0.8,0.1,1.7-0.2,2.5c-0.1,0.3-0.3,0.6-0.5,0.8c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.3,0.1-0.4,0.1
			c0,0.1-0.1,0.2-0.1,0.3s0.2,0.5,0.2,0.8c0,0.2,0.1,0.5,0.1,0.7c0,0.4,0,0.6,0,0.8c0.3,0.9,0.3,2,0,2.9c0,0.1-0.1,0.1-0.1,0.2
			c1,0.6,1.9,1.4,2.6,2.4c0.6,0.8,0.9,1.7,1,2.7c0.1,1.2-0.2,2.3-0.9,3.3c-1.4,2.1-3.6,3.4-6.1,3.6c-1.2,0.1-2.5,0-3.7-0.4
			c-1.2-0.4-2.3-1.1-3.1-2.1c-0.8-1-1.1-2.2-1.1-3.4c0-0.3,0-0.5,0.1-0.8l0.1-0.4l0.1-0.3c0.1-0.4,0.2-0.7,0.4-1.1
			c0.4-1.2,1.1-2.3,2-3c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0-0.1-0.1-0.1c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.1,0-0.2,0-0.3
			c-0.1-0.4-0.1-0.8,0-1.2c0-0.2,0.1-0.5,0.2-0.7c0.1-0.4,0.2-0.7,0.3-0.8c0-0.1,0-0.2,0.1-0.4c0-0.1,0.1-0.2,0.1-0.4l0.1-0.5
			c0-0.1,0-0.2,0-0.3c-0.1,0-0.2-0.1-0.3-0.2h-0.2h-0.1l-0.1-0.1c-0.2-0.3-0.5-0.6-0.6-0.9c-0.5-1-0.7-2.1-0.4-3.2
			c0.1-0.3,0.2-0.5,0.3-0.8c0-0.1,0.1-0.2,0.2-0.4c0,0,0,0,0-0.1c-0.1-0.4-0.2-0.7-0.2-1.1c-0.1-0.7,0-1.3,0.2-2c0,0,0,0,0,0
			c-0.4-0.3-0.8-0.7-1.1-1.1c-0.6-0.9-0.9-2-0.8-3.1c0.1-0.8,0.2-1.6,0.5-2.3c0.4-1.1,1.1-2.1,2.1-2.7c0.1-0.1,0.2-0.1,0.4-0.1
			c-0.3-0.5-0.5-1.1-0.5-1.7c0.1-1,0.4-1.9,0.9-2.7l0.1-0.3l0.1-0.3l0.1-0.3c0-0.1,0-0.2,0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.4,0-2-2.1-1.2-4.8c0.1-0.2,0.2-0.5,0.3-0.7l0.2-0.3c0-0.1,0.1,0,0-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.2-0.1-0.4-0.1-0.6
			c0-0.4,0-0.7,0-1.1c0-0.6,0.2-1.1,0.5-1.6c-0.4-0.3-0.8-0.7-1.1-1.2c-0.6-0.9-0.8-2-0.7-3.1c0.1-0.8,0.3-1.5,0.7-2.2
			c0.5-1.1,1.4-1.9,2.5-2.3c0.1,0,0.1,0,0.1,0c-0.2-0.4-0.3-0.8-0.3-1.3c0-0.4,0.1-0.8,0.3-1.2c0.1-0.2,0.3-0.5,0.4-0.7
			c0.2-0.3,0.4-0.6,0.5-0.9l0.2-0.7c0,0,0-0.1,0-0.3c-0.1-0.1-0.2-0.1-0.3-0.2l-0.2-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.3-0.3-0.5-0.7-0.7-1.1c-0.2-0.6-0.4-1.1-0.4-1.7c0-0.3,0-0.7,0.1-1l0.1-0.5l0.1-0.3v-0.1c0,0,0,0,0,0l-0.1-0.2
			c-0.1-0.1-0.1-0.3-0.1-0.4c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.5-0.1-0.9,0-1.4c0.1-0.7,0.4-1.4,0.8-2c0,0,0.1-0.1,0.1-0.1
			c-0.3-0.2-0.6-0.4-0.8-0.7c-1.3-1.2-1.8-2.9-1.6-4.6c0.2-1,0.6-1.9,1.3-2.6c0.2-0.2,0.4-0.4,0.6-0.5c0,0,0,0,0,0
			c-0.1-0.3-0.1-0.6-0.1-0.9c0-0.8,0.1-1.6,0.2-2.3v-0.7c0,0,0-0.1,0-0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.5-0.2-0.9-0.6-1.1-1
			c-0.2-0.4-0.4-0.8-0.5-1.3c-0.1-0.3-0.1-0.5-0.1-0.8v-0.5l-0.1-0.2l-0.2-0.3c-0.1-0.2-0.3-0.4-0.4-0.6c-0.2-0.4-0.3-0.8-0.4-1.3
			c-0.2-0.7-0.2-1.4,0-2.2c0.1-0.2,0.1-0.4,0.2-0.5c-0.4-0.2-0.9-0.5-1.3-0.8c-0.8-0.6-1.4-1.3-1.7-2.2c-0.1-0.4-0.2-0.9-0.1-1.3
			c0-0.3,0.1-0.6,0.1-0.9c0.4-1.5,0.9-2.5,1.3-2.9c-0.1-0.1-0.2-0.3-0.4-0.4c-0.3-0.5-0.6-1-0.8-1.5c-0.1-0.3-0.1-0.7-0.2-1.1
			c0-0.2-0.1-0.5-0.3-1c-0.1-0.3-0.1-0.6-0.1-0.9c0-0.2,0-0.4,0-0.6c0-0.1,0-0.2,0-0.3h-0.3c0,0-0.1,0-0.1-0.1l-0.2-0.1
			c-0.1-0.1-0.2-0.2-0.3-0.4c-0.5-0.7-0.9-1.4-1.2-2.3c-0.1-0.4-0.2-0.7-0.2-1.1c0-0.2,0-0.4,0-0.5c0,0,0,0,0-0.1l-0.3-0.5
			c-0.2-0.3-0.3-0.6-0.5-0.9c-0.2-0.5-0.4-1.1-0.5-1.7c-0.1,0-0.3,0-0.4-0.1c-0.4-0.1-0.8-0.3-1.1-0.5c-1.1-0.7-2-1.7-2.6-2.9
			c-0.3-0.7-0.4-1.4-0.3-2.2c-0.4-0.1-0.8-0.3-1.1-0.5c-0.6-0.5-1-1.1-1.3-1.9c-0.1-0.3-0.2-0.6-0.3-0.9c0-0.2,0-0.2,0-0.2v-0.1
			l-0.1-0.1l-0.1-0.1l-0.2-0.1c-0.3-0.2-0.5-0.4-0.8-0.6c-0.4-0.3-0.7-0.7-1-1c-0.1,0-0.2,0.1-0.2,0.1l-0.2,0.3
			c-0.1,0.2-0.2,0.4-0.4,0.6c-0.3,0.4-0.6,0.8-0.9,1.1c-0.5,0.5-1.1,0.9-1.8,1.3c-0.1,0.1-0.2,0.1-0.3,0.2l-0.1,0.1v0.1
			c0,0,0,0-0.1,0.1l-0.3,0.5c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.6-1,1.1-1.6,1.5c-0.9,0.6-1.8,1.1-2.8,1.4c-0.2,0.1-0.6,0.2-0.7,0.2
			v0.1c0,0,0,0-0.1,0.2l-0.2,0.4c-0.3,0.5-0.7,1-1.2,1.3c-0.4,0.4-0.9,0.6-1.4,0.9c-0.2,0.1-0.5,0.2-0.7,0.3l-0.4,0.1l-0.1,0.3
			l-0.3,0.6c-0.2,0.4-0.5,0.8-0.8,1.1c-0.6,0.7-1.3,1.3-2.1,1.7c-0.8,0.4-1.6,0.7-2.4,0.9l-0.6,0.1h-0.1v-0.1l0,0.1l-0.1,0.2
			c-0.1,0.2-0.3,0.4-0.4,0.6c-0.3,0.4-0.6,0.7-1,1c-0.7,0.5-1.5,0.9-2.4,1.1c-0.8,0.2-1.6,0.3-2.3,0.3c-0.2,0-0.5,0-0.7,0
			c0,0,0.1,0.1,0.1,0.1c0.5,0.6,1.1,1.3,1.7,1.8c0.3,0.4,0.8,0.6,1.3,0.8c0.8,0.2,1.5,0.6,2.2,1c0.5,0.3,0.9,0.6,1.2,1
			c0.2,0.3,0.4,0.6,0.6,0.9c0.1,0.2,0.1,0.3,0.1,0.5v0.3v0.2c0.1,0.9,0.3,1.8,0.5,2.6c0.2,0.9,0.5,1.8,0.9,2.6
			c0.1,0.1,0.1,0.2,0.2,0.3l0.6,0.4c0.6,0.4,1.1,0.8,1.6,1.2c0.7,0.6,1.1,1.5,1.3,2.4c0,0.2,0.1,0.5,0.1,0.7v0.5v1
			c0,0.5,0,1,0.1,1.5c0.1,0.3,0.2,0.5,0.5,0.7l0.1,0.1l0.3,0.3c0.2,0.2,0.4,0.4,0.6,0.7c0.3,0.4,0.5,0.9,0.7,1.5
			c0.2,0.8,0.3,1.6,0.3,2.4c0,0.3,0,0.5,0.1,0.8c0,0.1,0,0.1,0,0.1c0.6,0.8,1,1.8,1.1,2.9c0.1,0.6,0.2,1.2,0.4,1.8
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.3,0.2,0.4c0.2,0.3,0.4,0.6,0.6,1c0.3,0.5,0.5,0.9,0.7,1.5c0.1,0.5,0.2,1,0.3,1.5l0,0.5
			c0,0.1,0,0.2,0.1,0.4c0.1,0.2,0.1,0.5,0.3,0.7c0.2,0.4,0.5,0.9,0.8,1.3c0.2,0.2,0.4,0.5,0.6,0.8c0.2,0.2,0.3,0.5,0.4,0.8
			c0.1,0.2,0.1,0.4,0.2,0.7c0.1,0.7,0.3,1.3,0.3,2c0.1,0.6,0.1,1.2,0.2,1.7c0.1,0.4,0.2,0.9,0.3,1.3c0,0,0,0.1,0,0.1l0.1,0l0.1,0.1
			l0.2,0.2c0.2,0.1,0.3,0.3,0.4,0.4c0.9,1,1.6,2.2,2.1,3.4c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.2,0.1,0.4,0.1,0.5c0,0.2,0.1,0.3,0.1,0.5
			c0.1,0.6,0.1,1.2,0.2,1.7c0.1,0.5,0.1,1,0.2,1.5l0,0.3c0,0,0,0,0,0l0,0v0.1c0,0,0,0,0,0l0.1,0.1c0,0.1,0.1,0.2,0.1,0.3
			c0,0.1,0.1,0.2,0.1,0.4c0,0.2,0,0.4,0,0.6c0,1.1-0.2,2.2-0.5,3.3l0,0.2c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
			c0,0.1,0,0.1,0,0.2c0.1,0.4,0.3,0.8,0.5,1.2c0.2,0.5,0.4,1,0.5,1.5c0.2,0.6,0.2,1.2,0.1,1.7c-0.2,0.9-0.5,1.8-0.9,2.6
			c-0.1,0.2-0.1,0.4-0.1,0.7c0.1,0.3,0.2,0.7,0.3,1.1c0.2,0.8,0.3,1.6,0.3,2.3c0,0.4-0.1,0.8-0.3,1.2c-0.1,0.3-0.3,0.6-0.5,0.9
			c-0.3,0.5-0.6,1-0.9,1.4c-0.2,0.4-0.4,0.8-0.5,1.2c0,0.2,0,0.3,0,0.5c0,0.2,0,0.4,0,0.6c0,0.9-0.1,1.8-0.3,2.7
			c-0.1,0.6-0.3,1.3-0.7,1.8l-0.3,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c-0.2,0.3-0.3,0.5-0.5,0.8c-0.3,0.6-0.5,1.2-0.7,1.8
			c-0.1,0.7-0.2,1.4-0.1,2.1c0.1,0.9,0.1,1.8,0,2.7c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.5,0.8-0.6,1.1c-0.1,0.3-0.2,0.6-0.2,0.9
			c0,0.2,0,0.3,0.1,0.5l0.1,0.3c0,0.2,0.1,0.3,0.1,0.5c0.1,1.1,0,2.2-0.2,3.3l-0.3,1.5c0,0.2-0.1,0.4-0.1,0.6c0,0,0,0.1,0,0.1l0,0.2
			c0.4,1.2,0.6,2.4,0.6,3.7c0,0.5-0.1,0.9-0.2,1.4c-0.1,0.3-0.2,0.5-0.4,0.7c-0.1,0.2-0.3,0.4-0.4,0.5c-0.3,0.3-0.6,0.7-0.8,1.1
			c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.1,0,0.1,0,0.1l0,0.2l0.2,0.4c0.1,0.3,0.2,0.7,0.3,1c0.3,0.8,0.3,1.7,0.2,2.5
			c-0.1,0.6-0.3,1.1-0.6,1.5l-0.3,0.4c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1,0.1,0.5c0.1,0.2,0.2,0.4,0.3,0.7c0,0,0.1,0.2,0.1,0.4
			c0.1,0.1,0.1,0.3,0.1,0.4c0.1,0.3,0.1,0.6,0.1,0.8c0,0.7-0.1,1.4-0.3,2.1c0,0.2,0,0.4,0,0.6c0,0.1,0,0.2,0,0.2
			c0.1,0.1,0.1,0.2,0.1,0.3c0.1,0.5,0.3,1,0.5,1.6c0.2,0.7,0.4,1.4,0.4,2.2c0,0.2,0,0.1,0.3,0.5c0.2,0.3,0.5,0.5,0.7,0.8
			c0.3,0.4,0.5,0.9,0.6,1.4c0.1,0.8,0.1,1.5,0,2.3c-0.2,0.7,0,1.4,0.5,1.9c0.1,0.1,0.3,0.4,0.5,0.5l0.5,0.5c0.6,0.7,1.3,1.2,2.1,1.7
			c0.6,0.3,1.2,0.4,1.9,0.2c0.7-0.3,1.3-0.8,1.7-1.4c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.3-0.3,0.4-0.4c0.3-0.2,0.6-0.4,0.9-0.5
			c0.7-0.2,1.4-0.1,2,0.3c0.5,0.3,0.9,0.6,1.3,1c0.3,0.4,0.6,0.7,0.8,1c0.3,0.5,0.8,0.9,1.3,1.1c0.2,0,0.4,0,0.6-0.1
			c0.3-0.1,0.5-0.3,0.7-0.4c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.2,0.5-0.6,0.8-0.8c0.3-0.3,0.6-0.4,1-0.5c0.4,0,0.7,0.1,1,0.2
			c0.5,0.3,0.9,0.7,1.3,1.2c0.2,0.3,0.5,0.6,0.8,0.8c0.1,0.1,0.2,0.2,0.3,0.2c0.5,0,1-0.1,1.4-0.1c0,0,0.1,0,0.1,0
			c1.1-0.5,2.1-1,3-1.7c0.5-0.3,0.9-0.8,1.2-1.3c0.3-0.6,0.5-1.3,0.6-1.9c0.1-0.7,0.1-1.5,0-2.2c-0.2-0.8-0.4-1.7-0.7-2.5
			c-0.3-1-0.6-1.9-0.8-2.9c0-0.2-0.1-0.4-0.1-0.6v-0.6c0-0.4,0.1-0.8,0.2-1.2c0.2-0.8,0.8-1.4,1.6-1.6c0.8-0.2,1.7-0.2,2.5,0.2
			c0.4,0.2,0.8,0.5,1.1,0.8c0.2,0.2,0.3,0.4,0.5,0.5c0.1,0.1,0.2,0.3,0.3,0.4l0.5,0.7c0.2,0.3,0.4,0.5,0.6,0.7
			c0.3,0.4,0.4,0.9,0.4,1.5c0,0.1,0,0.3,0,0.4v0.3l-0.1,0.5c-0.2,0.8-0.2,1.6,0,2.4c0.4,0.6,1,1.2,1.6,1.5c0.3,0.1,0.6,0.2,0.9,0.2
			c0.2,0,0.5-0.1,0.7-0.3c0.5-0.4,0.7-1.1,1.4-2.6c0.2-0.6,0.6-1.1,1.1-1.5c0.5-0.5,1.2-0.8,1.9-0.9c1-0.1,2,0,2.9,0.2l1.1,0.3
			l0.5,0.1l0.3,0c0.2-0.4,0.4-1.1,0.8-2c0.1-0.3,0.3-0.6,0.5-0.8c0.2-0.3,0.5-0.5,0.8-0.7c0.2-0.1,0.3-0.2,0.5-0.2l0.4-0.1l0.4-0.2
			c0.4-0.1,0.8-0.3,1.1-0.6v0c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.3,0.3-0.5,0.6-0.7c0.2-0.1,0.4-0.2,0.6-0.3c0.1-0.1,0.3-0.1,0.5-0.2
			l0.4-0.1c0.9-0.2,1.5-0.2,2-0.3c0.1,0,0.2,0,0.2-0.1c0,0.1,0.1-0.3,0.1-0.6l0.1-0.9c0.1-0.7,0.3-1.3,0.5-2
			c0.1-0.4,0.3-0.8,0.5-1.1c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.3-0.3,0.5-0.4c0.3-0.2,0.5-0.4,0.7-0.5c0.1-0.1,0.2-0.2,0.3-0.3
			c0.2-0.3,0.3-0.6,0.3-0.9c0-0.4,0-0.9,0-1.4c-0.1-1.7-0.5-3.4-1-5.1c-0.1-0.4-0.2-0.9-0.2-1.3c0-0.5,0-0.9,0.2-1.4
			c0.1-0.4,0.3-0.8,0.6-1.1c0.2-0.3,0.4-0.5,0.7-0.8c0.4-0.4,1-0.7,1.6-0.8c0.3,0,0.5-0.1,0.7-0.1c0,0,0-0.1,0-0.1
			c0-0.5,0-1.1,0.1-1.6l0.1-0.6c0-0.1,0-0.3,0-0.4c-0.1-0.7-0.2-1.5-0.3-2.4c-0.1-0.8-0.1-1.7,0.1-2.5c0.2-0.7,0.3-1.3,0.4-1.9
			c0.2-1,0.3-2,0.3-3c0.1-1.7,0-2.6,0.2-2.7c0.2-0.1,0.8,0.8,1.2,2.6c0.2,1.1,0.3,2.2,0.3,3.3c0,0.6-0.1,1.4-0.1,2
			c0,0.6,0,1.2,0.2,1.8c0.2,0.7,0.4,1.4,0.6,2.4c0.1,0.3,0.1,0.6,0.1,0.9v0.4v0.3c0,0.3,0,0.7,0,1c0.1,0.6,0.4,1.2,0.8,1.6
			c0.6,0.6,1.3,1.2,2,1.6c0.3,0.2,0.7,0.4,1.1,0.5c0.2,0.1,0.4,0.1,0.6,0.1c0.1,0,0,0,0.1-0.1c0.2-0.3,0.3-0.7,0.3-1.1
			c0.2-0.9,0.3-2.1,0.7-3.4c0.1-0.4,0.2-0.7,0.4-1.1l0.1-0.3l0.1-0.2l0.2-0.3l0.8-1.6c0.3-0.5,0.6-1,1-1.5c0.5-0.6,1.1-1.1,1.8-1.4
			l0.3-0.1l0.2,0l0.3-0.1l0.6-0.2c0.3-0.1,0.6-0.2,0.8-0.4l0.1,0c0-0.2,0-0.4,0.1-0.5c0.1-0.3,0.1-0.6,0.2-0.9
			c0.3-0.7,0.5-1.4,0.6-2.1v-0.3c0,0,0,0,0,0l-0.1-0.2C472.4,290.9,472.2,290.2,472.2,289.6z"
            />
          </g>
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M414.5,339.9c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0.2-0.2,0.3-0.4,0.5
		c-0.2,0.3-0.5,0.5-0.7,0.8c-0.1,0.1-0.2,0.1-0.2,0.2c1.3-0.1,2.6-0.2,3.9-0.3C415.4,340.9,414.9,340.2,414.5,339.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M344.9,513.5l1.5-0.8c0.5-0.3,0.9-0.5,1.4-0.8c0.9-0.6,1.8-1.1,2.7-1.7l5.2-3.4c3.3-2.2,6.3-4.2,8.8-5.8
		c3.5-2.2,6.1-3.8,7.5-4.4c-0.2,0-0.4,0-0.6,0c-0.7,0.1-1.6,0.2-2.5,0.3l-2.9,0.4c-4.1,0.5-8.9,1.1-14.3,1.7l-8.5,0.9
		c-3.1,0.3-6.3,0.3-9.4,0c-2.1-0.2-4.1-0.5-6.2-0.9c0.7,1.8,1.4,3.5,2.3,5.2c1.5,3.1,3.3,5.9,5.5,8.5c1.2,1.4,2.6,2.5,4,2.6
		S342.9,514.6,344.9,513.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M331.6,478.8L331.6,478.8l0.2-0.1c-0.3,0-0.6,0.1-0.8,0.1c0,0.1,0.1,0.1,0.1,0.2l0.4-0.2
		C331.5,478.9,331.6,478.9,331.6,478.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M340.9,442.5c-0.5,0.3-1.3,0.4-2.2,0.5c-0.3,0.1-0.6,0.1-0.9,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.6,0,1.2,0,1.7,0
		c0.3,0,0.6,0,0.8,0c0.1-0.3,0.2-0.6,0.3-0.9C341,442.6,341,442.6,340.9,442.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M299.5,497.2c1.3,1,2.7,2,4.2,2.9c0.8,0.5,1.5,0.9,2.3,1.3c0.8,0.4,1.6,0.8,2.5,1.2c3.5,1.5,7,2.8,10.1,4
		l8.4,3.2c1.3,0.5,2.5,1,3.5,1.4c-1.1-1.9-2-3.8-2.8-5.9c-0.8-2.1-1.4-4.3-1.7-6.5c-3.2-0.6-6.4-1.3-9.6-2.2l-3.8-1
		c-1.3-0.3-2.5-0.7-3.7-1.1c-2.2-0.8-4.3-1.7-6.3-2.8c-2.9-1.6-5.6-3.4-8.2-5.4c-0.9-0.7-1.5-1.3-1.9-1.7c-0.3,0-0.5,0.1-0.8,0.1
		c0.1,0.3,0.2,0.7,0.2,1.1c0.4,1.9,1,3.7,2,5.3C295.4,493.4,297.3,495.5,299.5,497.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M344.6,472.6c0.7-1.3,1.4-2.6,1.8-4c0.5-2,0.8-4.1,0.8-6.2c0.1-3.4-0.3-6.9-1.2-10.2c-0.4-1.6-0.9-3.1-1.6-4.6
		c-0.1-0.2-0.2-0.5-0.3-0.7c-1.1,0.1-2.2,0.1-3.3,0.1c0.9,1.7,1.7,3.6,2.2,5.7c0.9,3.5,1.1,7.2,0.6,10.8c-0.3,2.1-0.8,4.2-1.4,6.2
		c-0.3,1.4-0.8,2.8-1.4,4.2c1-0.1,2-0.2,3-0.2C344.1,473.4,344.3,473,344.6,472.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M347.3,477.7c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2l0.1-0.1c0.2-0.1,0.4-0.3,0.6-0.5
		c-0.6,0-1.1,0.1-1.7,0.1c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1C347.1,477.3,347.2,477.5,347.3,477.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M352.2,472.5c0.7-1.2,1.3-2.5,1.7-3.8c0.5-2,0.8-4,0.8-6c0.1-3.3-0.3-6.6-1.1-9.9c-0.6-2.4-1.6-4.7-2.9-6.8
		l-1.1,0.2c-0.1,0-0.1,0-0.2,0c0.9,1.6,1.6,3.5,2.1,5.4c0.9,3.5,1.1,7.2,0.6,10.8c-0.3,2.1-0.8,4.2-1.4,6.2c-0.4,1.7-1,3.3-1.7,4.8
		c0.8,0,1.7,0,2.5,0C351.8,473.2,352,472.8,352.2,472.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M454.7,496.3c-0.7,0.5-1.3,1-2,1.6s-1.5,1.2-2.2,1.8c-3.1,2.4-6.4,4.5-9.9,6.4c0.2,0.2,0.4,0.4,0.5,0.7
		c0.3,0.5,0.6,0.9,0.9,1.4l0.7,1.4c0.8,1.8,1.4,3.7,1.8,5.6c0.7,3.1,1,6.3,1.1,9.5c0,2.1-0.1,4.3-0.4,6.4c-0.2,1.5-0.5,2.3-0.7,2.3
		s-0.5-0.8-0.8-2.3s-0.5-3.6-0.9-6.2c-0.1-0.5-0.2-1-0.2-1.5c-0.2,3.2-0.6,7-1.1,11c-1.2,9.6-3.3,17.6-3.5,20.4
		c-0.1,1.4-0.1,4.1,0,7.7c0.1,3.6,0.2,8,0.3,12.8c0.1,4.8,0.2,9.2,0.3,12.8c0,2.6,0.3,5.1,0.7,7.6c0.3,1,0.6,1.9,1,2.8
		c0.3,0.6,0.6,1.3,0.9,1.9c0.2,0.3,0.4,0.7,0.6,1c0.2,0.3,0.5,0.6,0.7,0.9c1.4,1.2,2.9,2.3,4.6,3c2,0.9,4.1,1.7,6.2,2.4
		c2.5,0.9,5.1,1.5,7.8,1.9c2,0.3,3.8,0.4,5.5,0.6c-1.6-1-3-2.4-4.1-4c-0.9-1.3-1.7-2.6-2.3-4.1c-0.3-0.7-0.6-1.5-0.9-2.3
		c-0.3-0.8-0.5-1.7-0.7-2.5c-0.5-3-0.8-6.1-0.9-9.2c0-0.7-0.1-1.4-0.1-1.9c0-0.2-0.1-0.7-0.1-1v-1c0-1.3,0-2.4,0-3.5
		c0.1-2.8,0.3-4.8,0.5-5.9c-1.5-0.7-2.9-1.3-4.4-1.9c-0.5-0.2-1.2-0.3-2.3-0.6l-2.1-0.5l-1.7-0.4l-2.6-0.5c0.3-0.9,0.7-1.8,1.2-2.6
		l0.2-0.5l0.1-0.3l0.1-0.2l0.3-0.7l0.8-1.7c1.1-2.4,2.6-5.3,4.3-8.3l3.6-6.6c1.2-2,2.1-4,2.9-5.8c1.2-2.5,2.3-5.1,3.2-7.8
		c0.6-2.7,1.2-5.5,1.5-8.3c0.6-3.9,1.3-8.7,1.8-13.8c0.3-3.1,0.4-6.2,0.5-9s0.2-5.3,0.3-7.5c0.1-2.9,0.3-5,0.5-6.1
		c-0.2-0.2-0.3-0.3-0.5-0.4l-0.1-0.1c-1.5,0.4-3.9,1-6.8,2.5C457.2,494.6,455.9,495.4,454.7,496.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M339.9,477.8l0.1-0.1h0l0.1-0.1c0,0,0,0,0,0c-0.3,0-0.7,0.1-1,0.1c0,0.1,0.1,0.2,0.1,0.2L339.9,477.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M346,441.8c0.1,0,0.2,0,0.2,0c-0.2-0.1-0.5-0.2-0.7-0.3C345.7,441.6,345.9,441.7,346,441.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M331.2,482H330c-0.1,0-0.2-0.1-0.3-0.1c0,0.6,0,1.1-0.1,1.1c-1-0.1-2-0.5-2.7-1.3c-0.5-0.5-0.9-1.2-0.9-2
		c0,0,0-0.1,0-0.1c-4.5,0.6-8.7,1.2-12.3,1.7c-5.9,0.7-10.4,1.1-12.6,1.5c-1.5,0.2-4,0.8-7.2,1.4c0.4,0.2,0.9,0.5,1.5,0.8
		c2,1.1,4.9,2.7,8.5,4.3c2,0.9,4,1.6,6.1,2.3c1.1,0.3,2.2,0.6,3.5,0.9l3.9,0.8c5.6,1.3,11.3,2.3,17,3c2.9,0.3,5.9,0.3,8.8,0.1
		l8.4-0.7c5.4-0.4,10.2-0.7,14.3-0.9l2.9-0.1c0.9,0,1.8-0.1,2.7-0.1c1.5,0.1,2.9,0.4,4.3,0.9c2.3,0.9,3.3,1.8,3.1,2
		c-0.1,0.2-1.3-0.3-3.5-0.7c-0.7-0.1-1.5-0.2-2.2-0.3c-0.4,0.8-3,3.2-7.1,6.5c-2.4,1.9-5.2,4.1-8.4,6.5c-1.6,1.2-3.3,2.4-5.1,3.6
		c-0.9,0.6-1.8,1.2-2.8,1.8c-0.5,0.3-1,0.6-1.6,0.9l-1.5,0.8c-1.1,0.6-2.2,1.1-3.3,1.5c-1.3,0.5-2.6,0.7-4,0.6
		c-1-0.1-1.9-0.4-2.8-0.9c0.1,0.6,0.2,1.1,0.4,1.8c1,5.6,1.7,11.3,2.1,17c0.3,3.6,0.3,7.6,0.2,11.8s-0.1,8.7-0.2,13.5
		c-0.2,9.5-0.4,20-2.2,31c-1.8,10.8-3.2,21.1-4.5,30.5c-0.6,4.7-1.2,9.2-1.7,13.4s-1,8.1-1.2,11.6c-0.3,5.6-0.3,11.2,0.1,16.8
		c0.1,1.9,0.2,3.5,0.3,4.5c0,0,0.1,0,0.1,0h0.6l0.6,0.1h0.3h0.2l0.3,0.1c1.7,0.3,3.1,0.7,4.6,1s2.7,0.5,4,0.7l3.6,0.4
		c2.9,0.3,5.1,0.6,6.2,1c0-0.2,0-0.5,0-0.7h1.1c-0.3-1-0.6-2.8-0.8-5.2c-0.1-2.5,0-5,0.2-7.5c0.3-2.9,0.8-6.2,1.5-9.8
		c0.3-1.8,0.7-3.7,1-5.6c0.3-1.9,0.5-3.9,0.6-6.1c0.3-4.3,0.7-8.8,1.2-13.6s0.9-9.8,1.3-15c0.5-5.2,0.9-10.6,1.1-16.1
		c0.5-11-0.1-21.5-0.5-31.2c-0.2-4.8-0.3-9.4-0.4-13.7c-0.2-4.2-0.6-8.2-1-11.7c-1-7.2-2.2-12.9-3-16.9s-1.4-6.2-1.1-6.3
		s1.2,2,2.5,5.9c1.8,5.5,3.2,11.1,4.2,16.8c0.7,4,1.2,8,1.5,12c0.2,2.2,0.3,4.4,0.4,6.7s0.3,4.6,0.4,7c0.5,9.6,1.3,20.3,0.8,31.5
		c-0.3,5.6-0.7,11-1.2,16.3c-0.5,5.3-1,10.3-1.5,15s-1,9.3-1.5,13.6c-0.2,2.1-0.5,4.3-0.8,6.3s-0.8,3.9-1.2,5.6
		c-0.8,3.5-1.5,6.7-2,9.5c-0.4,2.4-0.7,4.8-0.8,7.3c-0.1,2.4-0.1,4.1-0.1,5.1h1.8c0,0.1,0,0.1,0,0.2h0c0,0.1,0,0.3,0,0.4
		c1.9-0.5,6.5-1,12.9-2c2.1-0.3,4.5-0.7,6.9-1.2c2.5-0.6,5-1.4,7.4-2.4c1.3-0.5,2.6-1.1,4-1.7l2.1-0.9l0.3-0.1l0.4-0.1
		c0.3-0.1,0.7-0.2,1-0.3c0.1,0,0.2,0,0.3-0.1c0-0.1,0-0.1,0-0.2c-0.4-1.4-0.8-2.7-1.3-4c-0.7-1.7-1.5-3.8-2.6-6.2s-2.4-5.1-3.7-8.3
		c-1.3-3.1-2.6-6.6-3.7-10.4c-1.3-4.1-2.1-8.3-2.6-12.5c-0.5-4.5-0.8-9.2-0.9-14c0-0.5,0-1.1-0.1-1.7c-0.1,0.7-0.3,1.5-0.5,2.2
		c-0.5,2.3-1.1,4.6-1.7,7c-2.5,9.5-4.5,18-5.6,24.3c-0.3,1.6-0.4,3-0.6,4.3s-0.2,2.3-0.3,3.2c-0.2,1.8-0.3,2.8-0.4,2.8
		c-0.2,0-0.3-1-0.5-2.8c0-0.9,0-2,0-3.3s0.1-2.8,0.3-4.4c0.7-6.4,2.5-15.2,5-24.6c0.6-2.4,1.2-4.7,1.8-6.9c0.6-2.1,1-4.2,1.1-6.3
		c0.1-2.1,0.3-4.1,0.6-6.1c0.3-1.6,0.5-3,0.8-4.4c0.8-9.6,1.8-18.6,1.9-26.9c0-1.1,0-2.2,0-3.2s-0.1-2.1-0.1-3.1
		c-0.1-2.1-0.2-4.1-0.3-6c-0.3-3.9-0.6-7.5-1-10.8c-0.4-3.3-0.8-6.3-1.1-8.9c-0.2-1.3-0.3-2.5-0.4-3.7c-0.1-1.1,0-2.2,0.1-3.2
		c0.2-1.4,0.4-2.6,0.6-3.5c-1.5,0.5-2.9,0.9-4.4,1.2c-1.7,0.3-3.4,0.3-5.1,0c-2.8-0.6-5.3-1.9-7.4-3.8c-1.4-1.2-2.6-2.5-3.7-3.9
		c-0.7-1-1-1.7-0.8-1.8c0.4-0.4,2.5,1.4,6.1,3.7c1.9,1.3,4.1,2.2,6.4,2.6c2.5,0.3,5.3-0.4,8.4-1.5c0.8-0.3,1.5-0.5,2.2-0.8
		s1.4-0.6,2.1-0.9c1.4-0.7,2.7-1.3,4-2c2.5-1.4,4.7-2.7,6.6-3.8c1.5-0.9,2.8-1.6,3.9-2.2c-5-1-9.9-2.6-14.6-4.7
		c-4.6-2.1-7.2-3.9-7-4.4c0.2-0.4,3.2,0.5,7.9,2c6.1,2,12.3,3.4,18.7,4.3c6.3,0.8,12.8,0.9,19.1,0.5c4.9-0.2,7.9-0.8,8.1-0.3
		c0.1,0.4-2.8,1.9-7.8,2.8c-1,0.2-2,0.3-3.1,0.5c1.3,0.3,2.7,0.4,4.1,0.4c1.5,0,3-0.1,4.5-0.3c1.7-0.3,3.4-0.8,5-1.3
		c3.8-1.4,7.5-3,11.1-5c3.6-2,7.1-4.1,10.4-6.4c0.8-0.5,1.5-1.1,2.2-1.6c0.7-0.5,1.5-1.1,2.3-1.5c1.4-0.9,2.9-1.6,4.5-2.2
		c2.1-0.8,4.3-1.3,6.5-1.4c-0.8-0.7-1.5-1.5-2.2-2.2c-10.1,3.9-19,6.8-23.3,7.7c-0.1,0.1-0.2,0.2-0.3,0.2c-1,1-2.1,2-3.3,3.1
		s-2.2,2.2-3.5,3.4l-0.3,0.2l-0.1,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c-0.3,0.3-0.6,0.5-1,0.7c-0.4,0.1-0.8,0.2-1.2,0.1
		c-0.4-0.1-0.8-0.2-1.1-0.3c-0.9-0.4-1.6-0.7-2.4-1c-0.8-0.4-1.5-0.7-2.3-1.1c-0.6,0.3-1.3,0.7-2.1,1.1c-1.4,0.7-2.8,1.2-4.2,1.6
		c-0.5,0.1-0.9,0.2-1.4,0.3c-0.3,0.1-0.6,0.1-0.9,0.1c-0.3,0-0.6,0-0.9-0.1c-1-0.2-2-0.5-3-1c-1.8-0.8-3.4-1.8-4.8-2.5
		s-2.6-1.2-3.8-1.7c-1.8-0.8-3.1-1.4-3.4-1.9c-0.3,0.1-0.7,0.1-1.2,0.2c-0.8,0.1-1.8,0.2-3,0.2h-0.9h-1.7c-1.2,0-2.4-0.1-3.7-0.1
		l0.4-0.6c0.2-0.3,0.4-0.5,0.6-0.8c0.4-0.5,0.8-0.9,1.4-1.3c-0.1-0.1-0.1-0.2-0.2-0.3c-0.4-0.9-0.4-2,0.1-2.8
		c0.1-0.1,0.2-0.3,0.3-0.4c-0.5-0.1-0.9-0.2-1.4-0.4c-3.3-1-6.6-2.2-9.9-3.5l-1.3-0.5c-0.4-0.2-0.9-0.4-1.3-0.6
		c-0.9-0.4-1.6-0.8-2.4-1.3l-4.4-2.5c-2.7-1.6-5.2-2.9-7.3-4c-1.6-0.8-3-1.4-4.1-1.8c-1.2-0.1-2.5-0.1-3.9-0.1
		c-0.5,0.7-1.1,1.3-1.8,1.9c-0.3,0.3-0.7,0.6-1.1,0.8c-0.4,0.3-0.8,0.5-1.3,0.7c-0.2,0.1-0.5,0.2-0.7,0.3l-0.6,0.2
		c-0.2,0.1-0.4,0.1-0.7,0.2c-0.4,0-0.7,0-1.1,0h-1.1c-0.1,0-0.2-0.1-0.3-0.1c0,0.5,0,0.9-0.1,0.9c-0.5,0-1.1-0.2-1.5-0.4
		c-0.4-0.2-0.8-0.5-1.2-0.8c0,0,0-0.1-0.1-0.1c-0.1,0.1-0.3,0.1-0.4,0.2c-0.2,0.1-0.5,0.2-0.7,0.3l-0.5,0.1
		c-0.2,0.1-0.5,0.1-0.7,0.2c-0.4,0-0.8,0-1.2,0h-1.2c-0.1,0-0.3-0.1-0.4-0.2c0,0.6,0,1.2-0.1,1.1c-0.5,0-1.1-0.2-1.6-0.4
		c-0.4-0.2-0.7-0.4-1-0.7c-0.4,0.2-0.8,0.5-1.2,0.6c-0.2,0.1-0.5,0.2-0.7,0.3l-0.5,0.1c-0.2,0.1-0.5,0.1-0.7,0.2
		C332,482.1,331.6,482.1,331.2,482z"
          />
          <g>
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M110.4,547.9c-0.4,0.1-0.9,0.3-1.3,0.4c-0.9,0.2-1.7,0.4-2.5,0.5c-1.5,0.2-3.1,0.4-4.6,0.5
			c-2.5,0.1-5,0-7.5-0.3c-1.8-0.2-3.5-0.6-5.3-1c-0.7-0.2-1.3-0.3-1.9-0.5L86,547l-1,0.4c-0.2,0.1-0.5,0.2-0.7,0.3l-0.7,0.2
			c-2.2,0.7-4.6,1.2-6.9,1.5c-1.7,0.2-3.7,0.4-6.1,0.5c-1.2,0.1-2.5,0.1-3.9,0.1c-0.7,0-1.4,0-2.2,0h-2.1l-0.1,0.1l-0.2,0.2
			c-0.3,0.3-0.6,0.5-1,0.8c-1.1,0.7-2.2,1.3-3.5,1.7c-1.6,0.5-3.3,0.9-5,1.2c-0.9,0.1-1.9,0.2-2.9,0.3l-1.1,0.1h-0.2
			c0,0,0,0,0.1,0.1c0.2,0.2,0.4,0.5,0.6,0.8c0.1,0.1,0.2,0.2,0.2,0.3l0,0.1h0.7c0.5,0,0.9,0.1,1.4,0.1c0.8,0.1,1.7,0.2,2.5,0.4
			c1.4,0.3,2.8,0.8,4.1,1.5c0.9,0.5,1.8,1.1,2.6,1.7c0.3,0.3,0.6,0.5,0.8,0.7l0.1,0.2v0.1H62h0.6l1.2-0.1h2.4c1.4,0,2.8,0.2,4.3,0.4
			c2.2,0.3,4.5,0.9,6.6,1.7c1.5,0.5,2.9,1.2,4.3,2c0.6,0.3,1,0.6,1.5,0.9l0.6,0.4c0.1,0.1,0.3,0.2,0.4,0.3l2.8-0.5
			c2.5-0.4,5.1-0.7,7.7-0.8c2.2,0,4.5,0.1,6.7,0.3c1.4,0.2,2.8,0.5,4.2,0.8c0.8,0.2,1.5,0.4,2.3,0.7c0.4,0.1,0.8,0.3,1.2,0.4
			c0.2,0.1,0.4,0.2,0.6,0.3l0.3,0.1l0.1-0.1l0.5-0.2l1.5-0.6c1.5-0.5,3-0.9,4.5-1.1c4.3-0.7,8.7-0.5,12.9,0.7
			c1.2,0.3,2.3,0.7,3.5,1.2c0.6,0.3,1.1,0.5,1.7,0.8c0.3,0.1,0.5,0.3,0.8,0.4l0.4,0.2l1.9-0.6c0.8-0.3,1.6-0.5,2.3-0.7
			c1.5-0.4,3.1-0.7,4.6-0.9c2.9-0.4,5.8-0.4,8.7-0.2c2.5,0.2,4.9,0.7,7.3,1.4c1.1,0.3,2.1,0.6,3,1c0.5,0.2,0.9,0.4,1.3,0.6l0.6,0.3
			l0.3,0.2l0.4-0.3c0.2-0.1,0.5-0.3,0.7-0.4c0.4-0.2,0.9-0.4,1.3-0.6c0.7-0.3,1.4-0.4,2.2-0.5c0.3,0,0.5-0.1,0.7-0.1
			c-0.4-2.3-0.8-4.4-1.1-6.5c-0.3-2.3-0.6-4.4-0.9-6.4c-0.6,0.1-1.8,0.2-3.4,0.1c-1.6,0-3.2-0.2-4.7-0.5c-1-0.2-1.9-0.5-2.9-0.8
			c-0.5-0.2-1-0.4-1.5-0.6c-0.3-0.1-0.5-0.3-0.8-0.4l-0.5-0.3l-0.2,0.1l-0.5,0.2c-0.4,0.1-0.7,0.2-1.1,0.3c-0.8,0.1-1.6,0.3-2.4,0.4
			c-1.7,0.2-3.4,0.2-5.2,0.1c-1.9-0.2-3.7-0.5-5.5-1c-0.9-0.2-1.9-0.5-2.8-0.9c-0.5-0.2-0.9-0.3-1.4-0.5l-0.7-0.3l-0.3-0.2l-0.8,0.2
			c-0.4,0.1-0.8,0.2-1.2,0.3c-0.8,0.1-1.6,0.3-2.4,0.3s-1.6,0.1-2.5,0.1h-1.3l-1.3-0.1c-3.2-0.2-6.3-0.8-9.3-1.7
			c-1.3-0.4-2.3-0.7-3.2-1.1c-0.5,3.8-0.9,6.8-1.4,6.8c-0.5,0-1-3.2-0.6-7.3L110.4,547.9z M82.6,559.2c-2.6,0.7-5.4,1-8.1,1
			c-1.5,0-3-0.1-4.5-0.2c-0.7-0.1-1.3-0.2-2-0.3c-0.3-0.1-0.7-0.1-1-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.5-0.7-0.9-1.6-1-2.5
			c-0.1-0.6,0-1,0.1-1s0.3,0.2,0.6,0.7c0.4,0.6,0.8,1.2,1.3,1.7c0.1,0.1,0.2,0.2,0.4,0.2l0.8,0.1c0.6,0,1.2,0.1,1.9,0.1
			c1.3,0,2.8,0.1,4.3,0c3,0,5.6-0.2,7.6-0.4c0.4-4,0.5-7.3,1.1-7.3c0.6,0,1.2,3.1,1,7.1c0.9-0.1,1.4-0.1,1.5,0.1
			C85.7,557.9,84.6,558.6,82.6,559.2z M107,560.1c-2.6,0.5-5.3,0.7-8,0.5c-2.7-0.2-5.3-0.7-7.8-1.6c-1.9-0.7-3-1.5-2.9-1.7
			s1.3,0,3.3,0.3c2.5,0.4,5,0.8,7.6,0.9c5.9,0.4,10.8-0.3,10.9,0.4C110.2,559.1,109,559.7,107,560.1z M157.4,556.9
			c0.6,0,1,1.7,1.1,3.8c0.1,0.9,0.1,1.7,0,2.6c0,0.7-0.1,1.1-0.4,1.2c-0.3,0.1-0.7-0.2-1-0.9c-0.4-0.9-0.6-1.8-0.7-2.8
			C156.3,558.6,156.8,556.9,157.4,556.9z M149.6,554.6c2.5,0.1,4.5,0.3,4.5,0.9c0.1,0.6-2,1.3-4.6,1.2s-4.7-1.1-4.5-1.7
			S147.1,554.5,149.6,554.6z M134.3,558.2c0.2-2.5,0.6-4,0.8-4c0.3,0,0.4,1.5,0.6,4c0.1,0.6,0.1,1.3,0.3,1.9c0,0.3,0.2,0.7,0.2,0.6
			l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.2c1.6,0.6,3.2,1,4.8,1.3c3.1,0.5,6.2,0.9,9.3,0.9c2.4,0,4,0,4,0.3c0,0.3-1.4,0.8-3.9,1.2
			c-3.3,0.4-6.5,0.2-9.8-0.4c-1.8-0.3-3.6-0.8-5.3-1.6c-0.2-0.1-0.4-0.2-0.6-0.4c-0.3-0.2-0.5-0.4-0.7-0.6c-0.3-0.4-0.4-1-0.4-1.5
			C134.3,559.6,134.3,558.9,134.3,558.2z M113,558.5c0.3-0.5,4.4,1.5,9.9,2.1c5.5,0.6,10-0.2,10.1,0.5c0.1,0.3-1,0.8-2.9,1.3
			c-5,1.1-10.2,0.4-14.7-1.9C113.7,559.6,112.8,558.7,113,558.5z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M120.5,541c-0.1,0.6-3.8,0.8-8.7,1.2c0,0.1,0,0.3,0,0.4c0.1,1.4,0.1,2.7,0,3.5c0.5,0.2,0.9,0.3,1.6,0.5
			c0.8,0.3,1.9,0.6,3.1,0.9c2.9,0.8,5.9,1.3,8.9,1.5l1.2,0.1h1.2c0.8,0,1.5-0.1,2.3-0.1s1.5-0.2,2.2-0.3c0.4,0,0.7-0.2,1.1-0.2
			l0.2-0.1c-0.2-1.4-0.2-2.8,0-4.2c0.2-1.3,0.6-2.1,0.8-2.1c0.3,0,0.4,0.8,0.6,2.1l0.7,4.4l0.1,0l0.6,0.3c0.4,0.2,0.9,0.4,1.3,0.5
			c0.9,0.3,1.7,0.6,2.6,0.9c-0.2-1.9-0.1-3.8,0.1-5.7c0.3-1.8,0.8-2.9,1-2.8s0.3,1.2,0.5,2.9c0.1,1.6,0.2,3.8,0.5,6.2
			c1,0.2,2,0.4,3.1,0.5c1.6,0.2,3.2,0.2,4.8,0.1c0.8,0,1.5-0.2,2.2-0.3c0.4,0,0.7-0.2,1.1-0.2l0.2-0.1c0.1-2.6,0.9-4.7,1.4-4.6
			c0.6,0.1,0.7,2.2,0.7,4.9l0,0c0.2,0.1,0.5,0.3,0.7,0.4c0.5,0.2,0.9,0.5,1.4,0.7c0.9,0.4,1.8,0.7,2.7,1c1.5,0.4,3,0.7,4.5,0.9
			c1.6,0.2,2.8,0.2,3.4,0.3c-0.3-2.5-0.6-4.6-0.9-6.4c-0.6,0.3-1.8,0.4-3.2,0.2c-2-0.3-3.5-1.1-3.3-1.7c0.2-0.6,1.7-0.7,3.6-0.4
			c1.1,0.1,2.1,0.4,2.7,0.7c-0.2-1.1-0.3-2-0.4-2.8c-0.1-0.7-0.3-1.5-0.5-2.5c-0.1,0-0.3-0.1-0.4-0.1c-0.7-0.1-1.4-0.1-2.1-0.1
			c-1.1,0.1-2.2,0.2-3.3,0.5c-0.6,0.1-1.2,0.3-2,0.5s-1.1,0.4-2.9,1l-1.6-1c-0.2-0.1-0.4-0.2-0.6-0.3c-0.8,3.1-1.4,5.4-1.9,5.4
			c-0.5,0-0.7-2.7,0-6.2c-0.2-0.1-0.4-0.1-0.6-0.2c-1.6-0.4-3.3-0.7-5-0.8c-2.6-0.2-5.1-0.2-7.1-0.1l-2.6,0.1h-1c-0.5,0-0.8,0-1,0
			l-0.7-0.5l-0.5-0.4l-0.7-0.5c-0.4-0.3-0.9-0.6-1.4-0.8c-0.7-0.4-1.5-0.7-2.2-1c-0.5-0.2-1.1-0.4-1.6-0.5c-0.4-0.1-0.7-0.2-1.1-0.2
			c-1.8-0.2-3.6,0-5.3,0.3c-1.4,0.3-2.7,0.6-4,1.1c-0.8,0.3-1.6,0.6-2.4,0.9c-0.4,0.2-0.8,0.4-1.3,0.5l-0.7,0.3l-0.9,0.5
			c0,0.1,0,0.1,0,0.2C116.6,539.8,120.6,540.4,120.5,541z M129.5,537.8c2.7,0.6,4.6,2,4.4,2.5c-0.3,0.5-2.3,0.1-4.8-0.5
			c-2.5-0.6-4.6-1.2-4.5-1.8C124.5,537.6,126.8,537.2,129.5,537.8z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M44.4,552.6c0-0.1,0-0.2,0-0.2l1.6,0.1c-0.2-0.3-0.5-0.6-0.7-1c-0.4-0.7-0.5-1.5-0.5-2.3
			c0.1-0.7,0.3-1.1,0.6-1.1c0.6,0,1.1,1.1,1.8,2.4c0.4,0.7,0.7,1.5,0.8,2h0.6h0.7c1,0,1.9-0.1,2.8-0.2c1.6-0.2,3.2-0.5,4.7-0.9
			c1.1-0.3,2.2-0.8,3.2-1.3c0.3-0.2,0.6-0.3,0.8-0.6c-0.1-0.4-0.2-0.7-0.4-1.1l-0.5-1.2c-0.3-0.7-0.5-1.1-0.3-1.4
			c0.2-0.2,0.8-0.1,1.5,0.5c0.1,0.1,0.1,0.1,0.2,0.2c0.8-0.6,2.3-1.4,4.3-1.9c3-0.8,5.5-0.5,5.5,0.1c0,0.6-2.3,1.2-5,1.9
			c-1.6,0.4-3,0.8-4,0.9c0,0,0,0,0,0c0.3,0.5,0.5,1,0.7,1.5l0.2,0l1.2,0.1l2.2,0.1c1.4,0,2.7,0,3.9,0c2.4,0,4.4-0.1,6.1-0.3
			c2.3-0.2,4.6-0.6,6.8-1.2l0.7-0.2c0.2-0.1,0.5-0.2,0.7-0.3l0.7-0.3c-0.7-0.4-1.7-1.7-2.3-3.5c-0.2-0.6-0.4-1.2-0.4-1.8
			c-0.9,0.2-2.4,0.3-4.1,0.2c-2.6-0.1-4.8-0.5-4.8-1.1c0-0.6,2.2-1.1,4.9-1c1.6,0.1,3,0.3,3.9,0.7c0-0.4,0-0.8,0.1-1.1l-0.4-0.1
			l-0.3,0l-1.3-0.3c-1.2-0.1-2.5-0.2-3.7-0.2c-3.4,0.1-6.8,0.7-10,1.7c-1.5,0.5-3,1-4.5,1.7c-0.7,0.3-1.3,0.7-2,1l-1,0.5l-0.2,0.1
			l-0.1,0.1l-0.2,0.1l-0.9,0.5l-1.9-0.4c-0.4-0.1-0.8-0.2-1.1-0.2c-0.8-0.1-1.5-0.2-2.2-0.2c-1.2-0.1-2.4-0.1-3.6,0
			c-1.3,0-2.2,0.1-2.8,0.1c-0.7,1.3-1.4,2.5-2,3.8c-0.9,1.8-1.9,3.6-2.9,5.6c0.1,0,0.2-0.1,0.3-0.1
			C42.6,552.7,43.5,552.6,44.4,552.6z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M175.1,617.5c0-0.8,0-1.6,0-2.5c-1.7,0.1-3.4,0-5.1-0.3c-1.6-0.3-2.5-0.7-2.5-1s1-0.4,2.6-0.5
			c1.7,0,3.4-0.2,5-0.3c0-2.2,0-4.5-0.1-6.9c-0.5,0.2-1.2,0.3-2,0.5c-1.3,0.2-2.5,0.3-3.8,0.4c-0.7,0-1.5,0-2.3,0h-1.2l-1.1-0.1
			c-0.1,0.1-0.3,0.1-0.5,0.2l-0.7,0.2c-0.5,0.2-1,0.3-1.5,0.4c-1,0.3-2,0.4-3.1,0.6c-4.2,0.6-8.5,0.6-12.7,0c-3.2-0.4-5.2-1-5.1-1.3
			s2-0.2,5.2-0.2c4.1,0.1,8.2-0.1,12.3-0.6c1-0.2,2-0.3,2.9-0.6c0.5-0.1,0.9-0.2,1.4-0.3l1.6-0.5l0.8,0.1l0.6,0.1l1.2,0.1
			c0.8,0.1,1.5,0.1,2.2,0.2c1.4,0.1,2.6,0.1,3.7,0.1c0.9,0,1.6-0.1,2.2-0.1c0-0.3,0-0.6,0-1c-0.1-4.8-0.4-9.8-0.9-15.1
			c-0.1-0.9-0.2-1.7-0.3-2.6c-0.5,0.2-1.3,0.3-2.1,0.4c-1.3,0.2-2.7,0.3-4,0.2c-0.8,0-1.5,0-2.4-0.1c-0.2,0-0.5,0-0.7-0.1
			c0.8,2.4,1.1,5,1,7.6c-0.1,2.4-0.6,3.8-0.8,3.8s-0.4-1.5-0.6-3.7c-0.3-2.6-0.9-5.1-1.7-7.5c-0.3,0.1-0.5,0.2-0.8,0.3
			c-1.8,0.5-3.7,0.8-5.6,0.8c-1.5,0.1-3,0-4.5-0.1c-2.6-0.2-4.1-0.7-4.1-0.9c0-0.3,1.6-0.4,4.1-0.5c3.2-0.1,6.4-0.5,9.5-1.3
			c0.3-0.1,0.6-0.1,0.8-0.3l1.1-0.4l0.9,0.2l0.6,0.1l1.2,0.2c0.8,0.1,1.5,0.2,2.2,0.3c1.4,0.1,2.7,0.2,3.8,0.2c0.8,0,1.5,0,2.1,0
			c-0.5-4-1.2-7.8-1.8-11.5c-0.4-2.1-0.8-4.2-1.2-6.2c-0.2,0.1-0.5,0.1-0.7,0.2c-0.7,0.2-1.4,0.4-2,0.7c-0.4,0.2-0.8,0.4-1.1,0.7
			c-0.2,0.1-0.5,0.4-0.6,0.4c-0.1,0-0.3,0.2-0.4,0.3l-0.1,0.1c0.3,0.2,0.7,0.7,1,1.4c0.6,1.5,0.9,3.2,0.8,4.8
			c-0.1,1.6-0.5,3.2-1.2,4.7c-0.5,1.1-1.1,1.7-1.3,1.6c-0.2-0.1-0.2-0.6-0.1-1.5c-1.1,0.6-3.5,1.3-6.5,1.6c-4.1,0.4-7.5-0.3-7.4-0.8
			c0-0.6,3.3-0.8,7.2-1.3c3.1-0.3,5.7-0.5,6.8-0.4c0.1-1.1,0.4-2.5,0.4-4c0.1-1.4,0-2.9-0.1-4.3c-0.1-0.8-0.2-1.3-0.1-1.6l-0.7-0.3
			l-0.6-0.2c-0.4-0.2-0.9-0.3-1.3-0.5c-1-0.3-1.9-0.6-3-0.8c-2.3-0.6-4.7-0.9-7.1-1c-2.8-0.1-5.5,0-8.3,0.4c-1.5,0.3-2.9,0.6-4.4,1
			c-0.8,0.2-1.5,0.4-2.2,0.7l-2,0.7c0.3,0.7,0.5,2,0.6,3.4c0.2,2.3,0.1,4-0.4,4.2c-0.3,0.1-0.7-0.3-1-1.1c-0.4-1-0.6-2-0.7-3
			c-0.1-1.5,0.1-2.8,0.3-3.5c-0.1-0.1-0.2-0.1-0.3-0.2l-0.4-0.2l-0.7-0.4c-0.5-0.3-1-0.5-1.5-0.7c-1.1-0.4-2.1-0.8-3.2-1.1
			c-1.5-0.4-3-0.7-4.6-0.9c0,1.1-0.3,3.2-0.6,5.7c-0.4,3.5-0.5,6.5-1.1,6.5c-0.5,0.1-1.4-3-0.9-6.8c0.3-2.4,1-4.5,1.6-5.6
			c-2.1-0.2-4.3-0.1-6.4,0.2c-0.1,0-0.3,0.1-0.4,0.1c0,1.2-0.4,3.6-0.7,6.5c-0.3,3.9-0.4,7.2-1,7.2c-0.5,0.1-1.5-3.3-1.1-7.4
			c0.3-2.6,1-4.9,1.6-6.1c-0.9,0.2-1.8,0.4-2.7,0.6l-2.4,0.7c0,0.4,0,1.1-0.1,1.9c-0.1,1.5-0.1,3.4-0.1,5.6c0.1,2.9,0.4,5.5,0.4,6.9
			l0.5,0.3l0.4,0.2l0.6,0.3c0.4,0.2,0.9,0.4,1.3,0.5c0.9,0.3,1.7,0.6,2.6,0.9c1.7,0.5,3.3,0.9,5.1,1.1c2.9,0.4,5.8,0.6,8.7,0.4
			c4.9-0.3,7.9-1.2,8-0.8c0.1,0.2-2.8,1.6-7.9,2.3c-3,0.4-6.1,0.4-9.1,0c-1.8-0.2-3.6-0.6-5.4-1c-0.9-0.3-1.8-0.5-2.8-0.9
			c-0.3-0.1-0.6-0.2-0.9-0.3c-0.1,3.4,0,6.9,0.4,10.3c0.3,3,0.8,4.8,0.5,4.9c-0.3,0.1-1.2-1.6-2-4.7c-0.8-3.6-1.2-7.3-1-11l-0.3,0.1
			c-0.5,0.2-0.9,0.3-1.4,0.4c-2.1,0.5-4.2,0.9-6.3,1c-2,0.2-4,0.2-5.9,0.1c-2.5-0.1-4.9-0.4-7.3-0.8c0.3,0.9,0.6,2.4,0.9,4.2
			c0.4,2.7,0.6,4.9,0.1,5.1c-0.3,0.1-0.7-0.3-1.2-1.2c-0.5-1.1-0.9-2.3-1-3.6c-0.3-2.1-0.1-3.9,0.2-4.7c-0.5-0.1-1.1-0.2-1.6-0.4
			c-0.4-0.1-0.7-0.2-1-0.3c-0.4-0.1-0.8-0.3-1.1-0.5c-0.5-0.5-0.9-1.1-1.1-1.8c-0.3-1-0.4-1.9-0.5-2.9c-0.1-1.6,0.1-2.5,0.2-2.5
			s0.3,0.9,0.6,2.4c0.2,0.9,0.4,1.8,0.8,2.6c0.2,0.4,0.4,0.9,0.8,1.2c0.5,0.2,1.1,0.4,1.6,0.5c3.2,0.6,6.4,0.9,9.7,0.9
			c1.9,0,3.8,0,5.7-0.2c2-0.1,4-0.5,6-1c0.4-0.1,0.9-0.2,1.3-0.4l0.9-0.3c-0.6-1.3-1.3-3.9-1.5-7.1c-0.1-2,0.1-4,0.7-5.9
			c0.3-0.8,0.5-1.4,0.8-1.8l0,0l-0.4-0.2l-0.5-0.3c-0.4-0.1-0.7-0.3-1.1-0.4c-0.7-0.3-1.5-0.5-2.2-0.7c-1.3-0.4-2.7-0.7-4-0.9
			c-2.2-0.4-4.3-0.6-6.5-0.6c-2.5,0-5.1,0.1-7.6,0.5l-2.7,0.5c0.2,0.3,0.2,1.2,0.2,2.3v2.6v1.5v0.7h0.7l1.6,0.1
			c1,0.1,1.9,0.2,2.7,0.3c1.5,0.3,2.4,0.5,2.4,0.8c0,0.3-1,0.5-2.5,0.7c-0.8,0.1-1.7,0.1-2.7,0.2h-1.6h-0.9c-0.6,0-1.1,0-1.7-0.1
			c-0.1-0.6-0.1-1.1-0.1-1.7v-0.4V573c0-0.6,0.1-1.1,0.1-1.6c0.1-0.9,0.3-1.8,0.5-2.7c0.3-1.1,0.6-1.9,0.8-2.2
			c-0.2-0.1-0.3-0.2-0.5-0.4c-0.2-0.1-0.4-0.3-0.6-0.4c-0.5-0.3-0.9-0.5-1.5-0.8c-1.3-0.7-2.6-1.2-4-1.6c-0.1,0.9-1,3.1-1.8,5.8
			c-1.1,3.3-1.8,6.2-2.4,6.1c-0.5,0-0.8-3.1,0.4-6.7c0.9-2.7,2.3-4.7,3.1-5.4c-1.9-0.6-3.9-1-5.9-1.2c-1.4-0.1-2.7-0.2-4.1-0.2
			l-2.3,0.1l-2,0.2l0.1,0.2c-1.5,1.3-2.8,2.9-3.8,4.6c-0.8,1.3-1,2.3-1.4,2.2c-0.4,0-0.5-1.1,0-2.8c0.7-1.9,1.8-3.6,3.2-5l-0.5-0.4
			c-0.8-0.5-1.6-1-2.4-1.4c-1.2-0.5-2.4-1-3.7-1.2c-0.7-0.2-1.5-0.3-2.3-0.3c-0.4,0-0.8-0.1-1.2-0.1h-1.1c0,0.1,0,0.1,0,0.1
			c-1.5,0.4-2.8,0.9-4.1,1.8c-0.6,0.4-1.2,0.8-1.7,1.3l-0.8,0.7l-0.3,0.4l-0.2,0.2l-0.1,0.1l0.1,0.1c0.5,0.3,1.1,0.7,1.7,1
			c1.1,0.6,2.1,1.2,3.1,1.7c3.8,1.9,6.5,2.4,6.4,2.8c0,0.3-2.9,0.3-7-1.4c-1-0.4-2.2-0.9-3.3-1.5c-0.6-0.3-1.2-0.6-1.8-1
			c-0.6-0.4-1.1-0.7-1.7-1c-0.2-0.1,0-0.2,0-0.4l0.3-0.4l0.6-0.8c0.1-0.1,0.2-0.3,0.3-0.4l0.2-0.2l0.4-0.5l0.8-0.8
			c0.6-0.6,1.3-1.1,2-1.5c1.1-0.7,2.2-1.3,3.4-1.7c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.2-0.4-0.4-0.6-0.6l-0.4-0.3l-0.1-0.1l-0.2-0.1
			l-0.4-0.2c-1.8-0.3-3.4-0.3-4.2-0.5c-2.3,4.6-4.7,9.7-7.2,15l1-0.1c0.3,0,0.5,0,0.7,0c0.7-2.5,2.2-4.1,2.7-3.7
			c0.5,0.4-0.1,2.2-0.7,4.5c0.2,0.3,0.5,0.5,0.7,0.7c0.4,0.5,0.9,0.9,1.4,1.3c1.1,1,2.3,1.8,3.6,2.4c2.5,1.2,5.1,2,7.8,2.4
			c1.1,0.2,1.9,0.3,2.6,0.3s0.9,0.2,1,0.4c0,0.1-0.3,0.3-1,0.4c-0.9,0.2-1.8,0.3-2.7,0.3c-0.4,0-0.8,0-1.2,0
			c0.3,0.9,0.6,2.6,0.9,4.4c0.4,2.9,0.9,5.2,0.3,5.5c-0.5,0.2-1.9-2-2.4-5.1c-0.3-2.1-0.2-3.9,0.1-4.8c-2.2-0.2-4.4-0.9-6.4-1.9
			c-1.5-0.7-2.8-1.7-4-2.9c-0.5-0.5-1-1-1.4-1.6c-0.3-0.4-0.6-0.8-0.8-1.2c0,0-0.8-0.1-1.2-0.2c-0.4-0.1-0.8-0.2-1.2-0.3
			c-0.1,0-0.1,0-0.2-0.1c-1.6,3.4-3.3,7-5,10.6c-0.1,0.2-0.2,0.3-0.2,0.5l0.6,0.5l0.5,0.4l0.3,0.2l0.2,0.1c0-0.1,0.1-0.1,0.1-0.2
			c5,2.9,9.7,3.9,9.6,4.5c0,0.3-1.3,0.4-3.3,0c-1.7-0.3-3.4-0.9-5.1-1.6c0.1,0.2,0.3,0.5,0.4,0.7c0.7,1.2,1.6,2.3,2.6,3.3
			c1.2,1.2,2.5,2.3,3.9,3.3c0.7,0.5,1.5,1,2.4,1.5c0.4,0.2,0.8,0.5,1.3,0.7l0.7,0.3c0.4,0.2,0.9,0.4,1.4,0.6l0.4,1.1
			c1.4,0.8,2.9,1.3,4.5,1.6c1,0.2,1.9,0.3,2.9,0.4c0.5,0,1,0,1.4,0l1.9-0.1l0.4,0.5l0.2,0.2l0.1,0.1c0.1,0.1,0.3,0.3,0.4,0.4
			c0.3,0.3,0.6,0.5,0.9,0.8c0.6,0.5,1.2,0.9,1.9,1.3c1.2,0.8,2.5,1.4,3.8,2c2.2,0.9,4.5,1.6,6.9,1.9c4,0.6,6.4,0.5,6.5,0.8
			s-2.4,0.7-6.6,0.4c-2.5-0.2-5-0.7-7.3-1.6c-1.5-0.5-2.9-1.2-4.2-2c-0.7-0.4-1.4-0.9-2.1-1.4c-0.3-0.3-0.7-0.5-1-0.8l-0.5-0.4
			l-0.1-0.1c-0.1,0-0.7,0.1-1,0.1c-0.4,0-1,0-1.6,0c-1.1,0-2.2-0.2-3.2-0.4c-1-0.2-2-0.5-2.9-0.9c0.4,0.8,0.8,1.5,1.3,2.2
			c0.9,1.2,1.9,2.3,3,3.3l1.7,1.5c0.6,0.5,1.1,0.9,1.6,1.4c1.2,1,2.5,1.8,3.7,2.7c-0.3-1.1-0.4-2.2-0.5-3.4
			c-0.1-1.7,0.1-2.8,0.4-2.8c0.3,0,0.6,1,1.1,2.5c0.2,0.7,0.4,1.5,0.6,2.2c1.3,0.3,3.7,1.2,6.6,1.8c4.2,0.8,7.7,0.7,7.8,1.3
			c0,0.3-0.8,0.7-2.3,0.9c-1.9,0.3-3.9,0.3-5.8-0.1c-1.9-0.4-3.8-1.1-5.5-2.1c-0.1,0-0.1-0.1-0.2-0.1c0.2,0.6,0.5,1.1,0.7,1.7
			l0.8,0.5c0.2,0.1,0.3,0.2,0.4,0.3c0.2,0.1,0.3,0.3,0.5,0.4c0.2,0.3,0.4,0.6,0.6,0.9c0.7,0.2,1.5,0.5,2.6,0.7
			c1.6,0.3,3.1,0.5,4.7,0.6c1,0,1.9,0,2.9,0c0.5,0,1-0.1,1.5-0.1l1.9-0.2l0.6,0.3l0.4,0.2l0.8,0.3c0.5,0.2,1,0.5,1.6,0.7
			c4.1,1.7,8.3,2.8,12.7,3.3c3.4,0.3,5.5,0.2,5.5,0.5s-2.1,1-5.6,1c-4.6-0.1-9.2-1-13.4-2.8c-0.5-0.2-1-0.4-1.5-0.6
			c0.3,2.1,0.8,4.2,1.5,6.2c0.7,2,1.2,3.3,1,3.5s-1.2-0.9-2.3-2.9c-1.2-2.3-2-4.8-2.3-7.4l-0.6,0.1c-0.5,0-1.1,0-1.6,0.1
			c-1,0-2,0-3.1-0.1c-1.7-0.2-3.4-0.5-5-1.1c-0.5-0.2-1-0.4-1.4-0.6l1,1.7c0.4,0.7,0.8,1.3,1.3,1.9c0.4,0.4,0.8,0.8,1.2,1.2
			c0.7,0.6,1.8,1.5,3.2,2.6c0.7,0.5,1.5,1.1,2.4,1.7l1.3,0.9c0.2,0.1,0.5,0.3,0.7,0.4l0.1,0.1l0.2,0.1c0.2,0.1,0.4,0.2,0.5,0.3
			c1.1,0.8,2.1,1.7,3.1,2.5c0.5,0.4,1,0.8,1.6,1.2c0.5,0.3,1,0.6,1.5,0.8c1.3,0.3,2.7,0.5,4.1,0.6l1,0.1c-2.3-3.7-3.5-6.7-3.1-6.9
			c0.5-0.2,2.7,3,5.7,7.1c0.1,0,0.1,0,0.2,0c0.5,0.1,0.9,0.2,1.4,0.3c1.7,0.5,3.2,1.1,4.6,1.6c1.3,0.5,2.7,0.9,4.1,1.3
			c2.6,0.4,5.3,0.6,7.9,0.7c1,0,1.9,0,2.8,0c-0.4-0.9-0.7-1.9-1-2.8c-0.3-1.2-0.1-2,0.2-2c0.3,0,0.7,0.5,1.2,1.5
			c0.5,0.9,1.2,2,1.9,3.3c0.5,0.1,1,0.2,1.5,0.4c0.8,0.3,1.5,0.6,2.3,1c0.6,0.3,1.1,0.6,1.6,0.7c1.2,0.5,2.5,0.8,3.9,0.9
			c2.1,0.1,4.2,0.1,6.3-0.1c2.4-0.2,5.1-0.5,8.1-0.8c0.1-3.6,0.5-6.3,1-6.3c0.5,0.1,0.9,2.7,1,6.3c0.2,0,0.4,0.1,0.6,0.1
			c0.8,0.1,1.6,0.3,2.4,0.5l4.7,1.1c0.9,0.2,1.8,0.3,2.7,0.4c0.9,0,1.7,0,2.5-0.1c1.8-0.2,3.6-0.6,5.3-0.9l2.5-0.5l0.3-0.1
			c0.1,0,0.3,0,0.4-0.1c1.7-3.7,2.3-6.9,2.9-6.9c0.5,0,0.7,3.2-0.8,7.2c0.1,0,0.2,0.1,0.3,0.1c1.6,0.5,2.9,1,4.2,1.5l1.7,0.7
			c0.5,0.2,0.9,0.3,1.4,0.3c1,0.1,1.9,0.2,2.6,0.3c0.7,0.1,1.3,0.1,1.7,0.2c0.1-0.4,0.3-0.8,0.4-1.2c0.7-2.1,1.2-4.3,1.5-6.5
			c0.3-2.2,0.5-4.4,0.5-6.6C175.1,625,175.2,621.5,175.1,617.5z M132.1,590.2c2.5-0.3,4.6-0.5,5.7-0.4c-0.1-1.6-0.2-2.6,0.1-2.7
			c0.5-0.2,2.3,3.4,2.4,8.2c0.1,2.1-0.1,4.1-0.7,6.1c-0.4,1.5-1,2.4-1.2,2.3c-0.6-0.2,0-3.8-0.2-8.3c-0.1-1.7-0.2-3.3-0.3-4.6
			c-1,0.6-3.1,1.3-5.6,1.6c-3.6,0.4-6.6-0.2-6.5-0.8C125.8,590.9,128.6,590.6,132.1,590.2z M113,603.1c0.2-0.3,1.1,0.2,2.5,0.6
			c1.9,0.6,3.9,1.1,5.8,1.5c4.6,0.9,8.6,0.9,8.6,1.5c0.1,0.5-4,1.5-9,0.6c-2.1-0.4-4.2-1.1-6.1-2.2
			C113.5,604.2,112.8,603.4,113,603.1z M80.8,584.4c0.3,0,0.3,2.4,1.2,5.9c0.5,2.1,1.3,4,2.4,5.9c0.6,1,1.4,2,2.2,2.9
			c0.9,0.9,2,1.5,3.2,1.9c2.5,0.8,5,1.2,7.7,1.3c2.4,0.1,4.7,0,6.5,0c3.8-0.2,6.1-0.4,6.1-0.2c0.1,0.3-2.2,1-6,1.6
			c-2.2,0.3-4.5,0.5-6.7,0.5c-2.8,0-5.6-0.4-8.3-1.3c-1.5-0.5-2.8-1.3-3.9-2.4c-0.9-1-1.8-2.1-2.4-3.4c-1.1-2-1.8-4.2-2.2-6.5
			C80,586.7,80.5,584.3,80.8,584.4z M68.3,582.3c6.5,1.1,11.9,0.6,12,1.2c0,0.1-0.3,0.3-0.9,0.5c-0.8,0.3-1.7,0.5-2.6,0.6
			c-2.9,0.4-5.9,0.3-8.9-0.3c-2.9-0.5-5.8-1.4-8.4-2.8c-2-1-3.1-2-3-2.2C56.9,578.9,61.8,581.2,68.3,582.3z M54,594.4
			c-1.6-0.4-3.2-0.8-4.8-1.5c-0.9-0.4-1.8-0.8-2.7-1.3c-0.5-0.3-0.9-0.5-1.4-0.8c-0.5-0.3-1-0.6-1.4-1c-0.9-0.8-1.6-1.7-2.1-2.7
			c-0.5-0.9-0.9-1.8-1.3-2.7c-0.6-1.5-1.1-3.1-1.5-4.8c-0.7-2.8-0.7-4.5-0.5-4.6c0.3-0.1,0.9,1.6,1.9,4.1c0.5,1.3,1.1,2.8,1.9,4.4
			c0.4,0.8,0.8,1.7,1.3,2.4c0.4,0.8,1,1.5,1.7,2.2c0.4,0.3,0.8,0.6,1.2,0.8c0.4,0.3,0.8,0.5,1.3,0.8c0.8,0.5,1.7,0.9,2.5,1.3
			c1.6,0.8,3.1,1.3,4.4,1.8c2.6,0.9,4.3,1.4,4.2,1.7C58.6,594.9,56.8,595,54,594.4z M77.1,600.1c-2-0.1-3.9-0.3-5.9-0.7
			c-2.4-0.4-4.8-1.2-7-2.3c-0.6-0.3-1.2-0.7-1.7-1.1c-0.5-0.4-1-0.9-1.5-1.3c-0.8-0.9-1.5-1.9-2.1-3c-0.9-1.8-1.7-3.6-2.2-5.6
			c-0.9-3.3-0.9-5.4-0.6-5.4c0.3,0,0.8,1.9,2,5c0.7,1.8,1.5,3.5,2.4,5.1c0.6,0.9,1.2,1.8,2,2.5c0.4,0.4,0.8,0.8,1.2,1.1
			c0.5,0.3,1,0.6,1.5,0.9c2,1,4.2,1.8,6.5,2.3c2.1,0.5,4,0.8,5.6,1.1c3.2,0.5,5.3,0.8,5.2,1.1C82.5,600,80.4,600.3,77.1,600.1z
			 M96.9,612.2c-2.9-0.2-5.7-0.6-8.4-1.5c-4.7-1.4-7.2-3.1-7.1-3.3c0.1-0.2,2.8,0.9,7.4,1.9c5.9,1.4,12.1,1.3,18-0.2l2.2-0.7
			c0.9,0.5,1.1,0.6,1.5,0.8c0.4,0.2,0.7,0.4,1.1,0.5c0.7,0.3,1.5,0.7,2.2,1c1.5,0.6,2.9,1,4.3,1.5c2.4,0.7,4.9,1.2,7.4,1.5
			c4.3,0.4,7,0.1,7,0.4c0,0.2-2.6,1.1-7.1,1c-2.6-0.1-5.3-0.4-7.8-1.1c-1.5-0.4-3-0.9-4.5-1.4c-0.8-0.3-1.5-0.6-2.3-1
			c-0.4-0.2-0.8-0.4-1.2-0.5c-0.3-0.1-0.7-0.3-0.8-0.4l-0.5,0.1l-1,0.3C103.9,612,100.4,612.4,96.9,612.2z M115.2,624.1
			c-2.9,0.4-5.9,0.3-8.8-0.3c-1.6-0.3-3.2-0.7-4.7-1.1c-0.4-0.1-0.7-0.2-1-0.3c-0.4-0.1-0.7-0.3-1.1-0.5c-0.6-0.4-1.1-1-1.4-1.8
			c-0.4-0.9-0.6-1.8-0.6-2.7c0-0.7,0.1-1,0.2-1s0.3,0.3,0.6,0.8c0.3,0.8,0.7,1.5,1.1,2.2c0.2,0.4,0.5,0.7,0.9,0.9
			c0.1,0,0.5,0.1,0.8,0.2l1,0.2c1.4,0.3,2.9,0.6,4.5,0.9c2.8,0.5,5.5,0.8,8.3,0.9c2.1,0,3.5-0.1,3.5,0.2
			C118.7,623,117.4,623.8,115.2,624.1z M120.3,634.9c-0.6,0.1-1.6-2.3-1.8-5.5s0.3-5.8,0.8-5.7s1,2.5,1.3,5.6
			S120.8,634.7,120.3,634.9z M155.7,627c-5,0.7-10,0.4-14.9-0.8l-1.1-0.3l-0.5-0.1h-0.1c-0.6,0.1-1.4,0.3-2,0.4
			c-1.3,0.2-2.7,0.3-4,0.4c-2.3,0.1-4.5-0.1-6.7-0.5c-3.8-0.7-5.9-1.8-5.8-2.1c0.1-0.3,2.3,0.3,6,0.6c2.1,0.2,4.3,0.2,6.4,0.1
			c1.3-0.1,2.5-0.2,3.8-0.5c0.4-0.1,0.9-0.2,1.3-0.3c-0.3-3.9,0-7.1,0.6-7.1c0.6,0,1.1,3.1,1.5,7.1l0.1,0l1,0.2
			c4.7,1.1,9.5,1.5,14.3,1.3c3.7-0.2,5.9-1,6-0.6C161.6,625.1,159.5,626.4,155.7,627z M161.4,614.7c-0.8,0.4-1.7,0.8-2.6,1
			c-2.9,0.9-6,1.2-9.1,1.1c-3.1-0.1-6.1-0.6-9-1.7c-2.2-0.8-3.4-1.6-3.3-1.9c0.2-0.5,5.5,1.2,12.3,1.4c6.8,0.3,12.2-1.3,12.4-0.7
			C162.3,614.1,162,614.4,161.4,614.7z M171.6,632.9c-0.9,4.1-2.6,7.1-3.1,6.9c-0.5-0.2,0.2-3.4,1.1-7.3s1.6-7.1,2.2-7.1
			S172.5,628.8,171.6,632.9z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M86,546.6l0.5,0.2l0.8,0.2c0.5,0.1,1.2,0.3,1.9,0.4c1.7,0.3,3.5,0.5,5.2,0.7c2.4,0.2,4.9,0.2,7.3,0
			c1.5-0.1,3-0.3,4.5-0.6c0.8-0.2,1.6-0.4,2.4-0.6c0.4-0.1,0.8-0.3,1.2-0.4l0.8-0.3c-0.4-0.7-0.8-2-0.9-3.5c0-1.2,0.2-2.3,0.4-3.1
			c-0.5-0.2-1-0.5-1.2-0.6c-0.9-0.4-1.8-0.8-2.7-1.2c-2-0.7-4.1-1.2-6.3-1.4l-0.8-0.1h-0.8l-0.5,0c-0.2,4.3-0.2,7.6-0.7,7.6
			c-0.5,0.1-1.3-3.2-1.3-7.6c-0.6,0-1.1,0-1.7,0c-2.5,0.1-5,0.5-7.5,1.3c-0.3,0.1-0.5,0.2-1,0.4l-0.9,0.4l-0.6,0.3l-0.6,0.3
			c0.4,0.7,0.9,2,1.4,3.5C85.7,544.4,86.2,546,86,546.6z"
            />
          </g>
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M333,514.9c-0.4-0.5-0.7-1-1.1-1.6c-1.4-0.2-3.3-0.6-5.7-1.2c-2.5-0.6-5.4-1.4-8.7-2.5
		c-3.5-1.1-7-2.4-10.5-3.8c-0.9-0.4-1.8-0.8-2.7-1.3s-1.8-1-2.6-1.5c-1.5-1-3-2.1-4.4-3.3c-1.7-1.4-3.2-3.2-4.4-5.1
		c0,0.7,0.2,1.3,0.5,1.9c0.4,0.8,1,1.6,1.8,2.2c2.1,1.8,4.4,3.3,6.8,4.6c3,1.7,6,3.2,9.2,4.5c3.3,1.4,6.7,2.6,9.7,3.7s5.7,2.2,8,3.1
		c3,1.2,5.3,2.2,6.4,2.9c0-0.2,0-0.3,0-0.5C334.4,516.4,333.6,515.7,333,514.9z"
          />
          <g>
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M336.1,680.7c-1.6-0.3-3.2-0.6-4.8-1.1c-0.4-0.1-0.8-0.3-1.3-0.5c-0.2-0.1-0.4-0.2-0.6-0.3l-0.3-0.2l-0.5-0.3
			c-0.3-0.2-0.7-0.5-0.9-0.8l-0.2-0.2l0-0.1c0,0,0,0,0,0l-0.1-0.1c-0.1-0.2-0.2-0.4-0.2-0.6l-0.1-0.6c-0.4,1-0.9,2.2-1.6,3.5
			c-1.4,2.9-3.1,5.7-5.1,8.3c-0.6,0.8-1.2,1.5-1.9,2.3l-0.7,0.8l-0.2,0.3l-0.1,0.1c0,0-0.1,0.1,0,0.1c0.2,0.1,0.4,0.3,0.6,0.3
			c0.1,0,0.2,0.1,0.3,0.1h0.2c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0,0.7,0h0.3h0.2l0.3-0.1c1-0.5,2-1.1,2.9-1.8l0.1,0.2
			c0.1,0.1,0.1,0.2,0.1,0.3l0.1,0.9c0.1,0.5,0.1,1.1,0.2,1.7l0.1,0.5h0.3h0.5l3.2-0.2c0.1,1,0.2,2,0.4,3l0.1,0.9v0.3l0.2,0.1
			l0.4,0.2l1.1,0.6l0.5,0.3l0.2,0.1l0.3,0.1l0.2-0.5c0,0,0,0,0,0l0,0l0.2-0.6l1.9-4.8l0.7,5.2c0.1,1.1,0.6,2,1.3,2.8
			c0.2,0.2,0.4,0.3,0.5,0.5c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.1-0.1c0.6-2.3,1.2-4.6,1.9-7
			c0-0.1,0-0.2,0-0.3l0,0c0-0.1,0-0.2,0.1-0.2l3.5-12.9C340.1,681.2,338.1,681,336.1,680.7z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M342.4,681.3l-0.7,13.4c-0.2,1.5-0.1,3,0.2,4.5c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.2,0.2,0.4,0.4,0.5
			c0.1,0.1,0.3,0.2,0.4,0.2v-0.1v-0.1c0,0,0,0,0.1-0.1l0.1-0.1l0.4-0.6c1-1.5,2-2.9,3-4.3c-0.2,2.2-0.3,4.4-0.5,6.7
			c0,0.2,0,0.4,0.1,0.6c0,0.1,0,0.1,0,0.2l0,0.1c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.3,0.4,0.5,0.5c0.4,0.2,0.8,0.3,1.2,0.2l0.5-0.1
			l0.4-0.1c0,0,0.1,0,0.1,0c0.1-1.4,0.2-2.8,0.3-4.2c0.1-1.7,0.3-3.5,0.4-5.2c0-0.2,0-0.3,0-0.5c0.1-2.3,0.1-4.5,0.2-6.8
			c0.1-2.1,0.1-4.3,0.2-6.4c-1.3,0.3-3.3,0.5-5.9,0.5C343.6,681.3,343,681.3,342.4,681.3z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M398.3,691.1l-0.4-1.6c-0.3-1.2-0.5-2.3-0.8-3.3c-0.5-2.2-1.1-4.2-1.6-6.1c-0.7-2.4-1.4-4.6-2-6.6
			c-0.1,0.1-0.1,0.2-0.2,0.2l-0.4,0.4l-0.3,0.2c-0.2,0.2-0.4,0.3-0.6,0.4c-0.4,0.3-0.8,0.5-1.1,0.8c-6,3.7-12.6,5.1-18.4,5.8
			c-5.1,0.6-10.2,0.8-15.4,0.7c-1.1,0-2.1-0.1-3.1-0.2l-0.4,0c-0.1,2.5-0.1,4.9-0.3,7.3c-0.2,6.9-0.4,13.7-0.5,20.3l1.8,0l3.3,0.1
			c0,0,0-0.2,0-0.3s0-0.2,0-0.3c0.1-0.9,0-1.9-0.2-2.8l0.1,0c0,0,0,0,0,0l3.3-1c0.1,0.3,0.1,0.5,0.2,0.8c0.4,0.7,1,1.3,1.7,1.6
			c0.8,0.3,1.8,0,2.2-0.8c0.7-1,1.1-2.2,1.1-3.4c0-0.2,0-0.3,0-0.5c0-0.1,0-0.3,0-0.4l-0.1-0.6l-0.1-0.6l-0.3-0.7
			c-0.2-0.5-0.3-0.9-0.5-1.4c-0.4-1-0.9-2.1-1.3-3.1l-0.5-1.3l-0.4-1.1l3.5,3.1l0.8,0.7l0.7,0.6l0.6,0.5c0.1,0.1,0.2,0.2,0.3,0.3
			l0.2,0.1c0.3,0.2,0.7,0.3,1,0.3l0.4,0c0.3,0,0.5,0,0.8,0c1.2-0.1,2.4-0.3,3.6-0.7l7.4-1.8c1.1-0.2,2.2-0.5,3.3-0.9
			c0.9-0.3,1.6-0.9,2.2-1.6c0.4-0.7,0.7-1.4,0.8-2.2c-0.3-1.1-0.5-2.2-0.7-3.3l-0.7-4.6l3.1,3.5c1,1.2,1.4,2.7,1.3,4.2
			c0,0.3-0.1,0.6-0.1,0.9l0,0.2c0,0.1,0.1,0,0.2,0l0.4-0.1l3.6-0.7l1.7-0.3l0.6-0.1L398.3,691.1z"
            />
          </g>
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M460.9,581.7c0.1,0.7,0.2,1.5,0.2,2.3c0,0.4,0.1,0.8,0.1,1.2c0,0.1,0,0.1,0.1,0.2c0.1,0,0.1,0,0.2,0l1.1,0.1
		c0.9,0.1,1.7,0.1,2.6,0.1c0.8,0,1.6-0.1,2.4-0.3c0.3-0.1,0.6-0.2,0.8-0.4c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.3,0.1-0.7,0-1
		c-0.1-0.4-0.2-0.8-0.5-1.2c-0.6-0.9-1.2-1.6-2.1-2.3c-1.6-1.3-3.4-2.5-5.2-3.5c-0.2-0.1-0.5-0.3-0.7-0.4c0.1,0.5,0.1,1.1,0.2,1.8
		C460.6,578.9,460.7,580.2,460.9,581.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M434.7,575.5c-0.1-4.4,0.2-8.7,0.9-13c0.6-3.6,1.1-6.3,1.3-7.7c0.2-2.7-0.2-11,1-20.7
		c0.7-5.9,1.6-11.3,2.4-15.1c0.2-1.2,0.5-2.2,0.7-3.1c-0.5-1.6-1-3.2-1.7-4.7c-0.2-0.4-0.4-0.8-0.6-1.1c-0.2-0.4-0.4-0.7-0.7-1.1
		c-0.2-0.3-0.5-0.6-0.8-0.9c0,0-0.1-0.1-0.1-0.1c-2.9,1.4-5.9,2.6-9,3.6c-1.8,0.6-3.7,1-5.6,1.2c-1.7,0.1-3.3,0.1-5-0.1
		c-1.3-0.1-2.6-0.4-3.8-0.8c-0.2,0.2-0.4,0.4-0.7,0.7c-1.1,1.1-2.3,2-3.6,2.9c-4.3,2.8-9,4.9-13.9,6.1c-0.3,0.1-0.5,0.1-0.8,0.2
		c1.1,1.9,2.9,5.5,5.3,10.5c1.8,3.7,4,8.1,6.7,12.8c1.3,2.2,3,4.8,4.5,7.3s3,5.3,4.5,8.1c3.1,5.5,6,10.8,8.9,15.3
		c0.7,1.1,1.5,2.2,2.2,3.2s1.5,1.9,2.3,2.9c1.6,1.9,2.9,3.8,4.1,5.6c2.2,3.4,3.8,6.3,4.9,8.4c-0.4-1.4-1.2-3.9-1.9-7.1
		C435.4,584.2,434.8,579.9,434.7,575.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M459.1,575.5C459.1,575.5,459.1,575.5,459.1,575.5c-0.1,0,0,0.1,0,0.1C459.1,575.6,459.1,575.5,459.1,575.5z"
          />
          <g>
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M340.2,674.8c-1.5,0-2.9-0.2-4.4-0.4c-1.5-0.2-3.1-0.6-4.6-0.8H331h-0.1v0.1c0,0.3-0.1,0.7-0.1,1
			c0,0.2,0,0.4,0,0.5v0.1l0.1,0.1l0.2,0.1c0.2,0.1,0.3,0.2,0.5,0.2c0.4,0.1,0.7,0.3,1,0.4c1.4,0.5,2.8,0.9,4.3,1.2
			c2.8,0.6,5.4,1,7.6,1.3c2.5,0.4,4.4,0.7,5.7,1c0-0.5,0-1,0-1.5c0-0.1,0-0.1,0-0.2c-0.1-0.7-0.1-1.4,0-2.1c0-0.3,0-0.6,0.1-0.9
			c0-0.2,0-0.5,0-0.7c-1.2,0.3-3.4,0.5-6.3,0.6C342.8,674.8,341.6,674.8,340.2,674.8z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M390.4,670.8c-0.2-0.7-0.5-1.4-0.8-2c-0.1-0.3-0.3-0.6-0.5-0.9c0,0-0.1-0.1-0.1-0.2H389l-0.4,0.2l-2.1,0.8
			c-1.4,0.6-2.8,1.1-4.1,1.6c-2.6,1-5.3,1.8-8.1,2.3c-2.6,0.4-5,0.6-7.1,0.8c-6.6,0.5-11.3,0.5-13.2,0.2c0,0,0,0.1,0,0.1
			c0,1.9,0,3.7-0.1,5.6h0.3c1,0,2-0.1,3-0.1c4.3-0.2,9.4-0.6,15-1.4c2.9-0.4,5.7-1,8.5-1.8c2.9-0.9,5.7-2.1,8.4-3.7
			c0.3-0.2,0.6-0.4,0.9-0.6l0.4-0.3l0.2-0.2C390.5,671.3,390.5,671,390.4,670.8z"
            />
          </g>
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M412.3,511.3c0,0,0.1,0,0.1,0c-0.6-0.3-1.2-0.6-1.8-0.9c-0.2-0.1-0.3-0.2-0.4-0.3c-5.4,0.4-10.7,0.2-16-0.7
		c-0.8,0.9-2.3,2.3-4.3,3.9c-2,1.6-4.2,3.1-6.4,4.5c-1.3,0.8-2.6,1.6-4.1,2.3c-0.7,0.4-1.5,0.7-2.3,1.1c0,0-0.1,0-0.1,0
		c3.5-0.5,10.2-1.2,17.7-3.2c4.7-1.2,9.2-2.9,13.6-4.9C409.9,512.4,411.3,511.7,412.3,511.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M369.3,477.8c-0.1,0.2-0.6,0.2-1.7,0c1.3,0.6,2.6,1.2,3.8,1.8c1.4,0.7,2.9,1.5,4.5,2.3
		c0.8,0.4,1.5,0.8,2.3,1.1c0.4,0.2,0.8,0.3,1.1,0.5l1.3,0.5c3.4,1.4,6.6,2.7,9.5,3.7c2.5,1,5.1,1.6,7.7,2c1.6,0.1,3.2-0.4,4.5-1.5
		c0,0,0.1-0.1,0.1-0.1c-0.6-0.2-1.2-0.4-1.8-0.6c-3.6-1.1-5.9-1.4-5.9-1.9c0-0.5,2.5-1.1,6.5-0.6c2.3,0.3,4.6,0.8,6.8,1.5
		c1.3,0.4,2.5,0.8,3.9,1.3l2,0.8l0.9,0.3c0.3,0.1,0.5,0.2,0.8,0.2c1.3,0.2,2.7,0.3,4,0.3c1.3,0,2.6,0,3.9,0c2.2,0,4.3-0.1,6.5-0.3
		c0.6,0,1.1-0.3,1.4-0.8c0.3-0.4,0.5-0.9,0.6-1.4c0-0.1,0.1-0.2,0.1-0.3c-0.8-0.1-1.6-0.2-2.4-0.4c-2.1-0.6-4.2-1.3-6.5-2.2
		c-4.5-1.8-8.3-3.5-11.2-4.5c-2.9-1-4.7-1.3-4.7-1.8c0-0.2,0.5-0.5,1.4-0.7c1.3-0.2,2.6-0.2,3.9,0c4.1,0.7,8.1,1.9,11.9,3.6
		c2.2,0.8,4.3,1.7,6.2,2.4c0.8,0.3,1.6,0.5,2.4,0.7c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0l1.2-0.3c1.7-0.4,3.1-0.6,4-0.7
		c-0.3-0.4-0.4-0.8-0.5-1.3c-0.1-0.5,0-1,0.2-1.5c0.3-0.7,0.7-1,0.8-0.9c0.2,0.1,0,0.5,0,1.1c0,0.8,0.3,1.6,1,2.1
		c0.4,0.3,1,0.5,1.5,0.6c0.3,0,0.6,0,0.9,0c0.4-0.1,0.7-0.1,1.1-0.2c1.5-0.4,3.1-1,3.8-2.4c0.2-0.3,0.3-0.7,0.3-1.1
		c0.1-0.3,0.1-0.6,0-0.8c-0.3-0.5-0.8-1-1.3-1.2c-0.6-0.3-1.2-0.4-1.9-0.3c-0.6,0.1-1.3,0.2-1.9,0.4c-0.9,0.3-1.8,0.7-2.6,1.2
		c-0.6,0.3-0.9,0.6-1,0.5s0-0.5,0.5-1.1c0.3-0.3,0.6-0.6,0.9-0.8c-0.8,0.1-1.7,0-2.5-0.2c-0.9-0.3-1.8-0.6-2.7-1
		c-1.7-0.8-3.5-1.6-5.4-2.5c-3.8-1.8-7.1-3.4-9.6-4.3c-2.5-0.9-4.1-1.3-4.1-1.8c0-0.5,1.8-0.9,4.8-0.5c3.7,0.6,7.3,1.8,10.6,3.6
		c1.9,0.9,3.7,1.9,5.3,2.8c0.8,0.4,1.5,0.8,2.1,1.1c0.6,0.2,1.2,0.4,1.8,0.5c1.1,0.1,2,0.1,2.7,0.2c0.3-0.2,0.6-0.4,0.9-0.6
		c1.1-0.5,2.2-0.8,3.4-1.1c-0.5-0.7-1.1-1.4-1.6-2.1c-1.2-1.7-2.4-3.3-3.4-4.9c-0.3-0.4-0.5-0.8-0.7-1.2c-6.1-1.5-12-3.7-17.7-6.4
		c-4.6-2.2-9-4.9-13.2-8c-7-5.3-10.4-9.7-10-10c0.2-0.2,1.3,0.7,3.3,2.1c0.6-0.8,1.8-1.9,3.7-2.9c1.9-1,3.9-1.7,5.9-2.2
		c2.5-0.5,5.1-0.7,7.6-0.6h0.9l0.2-0.1c0.4-0.2,0.8-0.5,1.2-0.8c0.9-0.7,1.7-1.4,2.4-2.2c1.3-1.3,2.6-2.7,3.7-4.2
		c0.3-0.5,0.6-1,0.9-1.6c0,0-0.1,0.1-0.1,0.1c-1.9,1-3.2,1.2-3.5,1.1c0,0.1-0.1,0.1-0.3,0.2c-0.3,0.1-0.6,0.1-0.8,0
		c-0.9-0.2-1.8-0.6-2.5-1.2c-0.3-0.2-0.6-0.5-0.8-0.8c-0.9,0.6-3.7,1.7-7.9,2.9c-1.3,0.4-2.7,0.8-4.3,1.2c-1.6,0.4-3.3,0.8-5.1,1.1
		c-3.9,0.6-7.9,0.9-11.8,0.9c-1.7,0-3.4-0.1-4.9-0.1c-1.5-0.1-2.9-0.1-4.4,0l-0.9,0.1l-1,0.3c-0.6,0.2-1.3,0.5-1.9,0.7l-3.7,1.4
		c-4.6,1.8-8.2,3.1-10,3.8c-1.8,0.7-5.1,2.5-9.9,4.3c-0.8,0.3-1.6,0.6-2.4,0.8c1.6,2,2.9,4.7,3.6,7.7c0.8,3.4,1,6.9,0.6,10.4
		c-0.3,2-0.7,4-1.4,6c-0.4,1.7-1,3.4-1.8,5c1,0.1,2.1,0.2,3.1,0.4C365.9,475.3,369.5,477.4,369.3,477.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M408.7,395.4c-0.6,0.2-1.2,0.4-1.7,0.7c1.1-0.1,2.1-0.1,3.2-0.1c1,0,1.9,0.1,2.9,0.2c-0.6-0.6-1-1.1-1.3-1.5
		c-0.5,0.1-0.9,0.1-1.2,0.2C410.2,395,409.6,395.2,408.7,395.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M413.6,388.4l0.1,0.2c0.3,0.5,0.1,0.1-0.2-0.3C413.6,388.3,413.6,388.3,413.6,388.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M414.5,389.9L414.5,389.9C414.4,389.8,414.4,389.8,414.5,389.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M330,442.1c0.1,0,0.2,0,0.3,0c-0.4-0.2-0.8-0.4-1.2-0.6l-0.1,0c-0.1,0.1-0.3,0.3-0.4,0.4
		c-0.3,0.3-0.7,0.5-1.2,0.9c-0.1,0.1-0.1,0.1-0.2,0.2l0.8,0.1C328.6,442.5,329.3,442.2,330,442.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M129.4,216.6c0.1,0.1,0.1,0.2,0.2,0.4l0.1,0.3c0,0.1,0.1,0.2,0.1,0.3c0.2,0.3,0.4,0.5,0.6,0.8l0.2-2.6
		c0.3-4.5,0.2-9-0.5-13.4c-0.1-0.7-0.2-1.3-0.3-2c0,0.2,0,0.4,0,0.6c-0.1,3.2-0.4,6-0.7,8.3c-0.4,2.9-0.7,4.9-1,6
		c0.1,0.1,0.2,0.2,0.3,0.3C128.9,215.9,129.2,216.2,129.4,216.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M364.8,371.5c-1.9-1.4-3.2-3.5-3.6-5.9c-0.2-1.3,0-2,0.2-2.3c-0.9,0.3-5.3-0.1-11.8-0.7
		c-3.7-0.3-8.1-0.8-12.9-1.1c-2.4-0.2-5-0.3-7.6-0.4c-2.7,0-5.3,0.1-8,0.4c-5.5,0.5-10.8,1.5-15.6,2.4c-4.8,0.9-9,1.9-12.6,2.8
		c-5.5,1.3-9.2,2.5-10.9,2.8c-0.2,0.3-0.3,0.5-0.5,0.7c-0.3,0.5-0.6,0.9-0.8,1.3c0.5,0.1,1,0.2,1.5,0.3c1.9,0.3,3.8,0.9,5.5,1.6
		c0.4,0.2,0.7,0.4,1,0.5c1.6-1.1,5.6-3.1,11.4-5.2c2-0.7,4.2-1.3,6.7-1.9c2.6-0.6,5.2-1,7.8-1.3c6.1-0.7,12.2-0.7,18.3,0
		c0.1,0,0.2,0.4,0.1,1c0.3-0.2,0.5-0.4,0.8-0.6c1.6-1,3.3-1.7,5.1-2.2c0.5-0.1,1-0.2,1.5-0.3l1.6-0.1c0.5,0,1.1,0,1.6,0
		c0.5,0,1.1,0.1,1.6,0.1c2.3,0.3,4.4,1,6.4,2.2c3.4,2.2,6.1,5.4,7.7,9.1c2.5-0.3,9.6,0.4,17.7,2.5c4.3,1.1,8.5,2.6,12.6,4.5
		c0.8,0.4,1.5,0.7,2,1.1c0.5,0.3,0.8,0.5,1.2,0.8l0.3-0.2l1.1-0.7c0.8-0.4,1.5-0.8,2.2-1.2c1.4-0.7,2.9-1.4,4.4-2
		c0.4-0.2,0.8-0.3,1.1-0.4l0.4-0.1c0.2-0.1,0.3-0.1,0.5-0.1c0.3,0,0.6,0,1-0.1c1.2,0.1,2.2,1,2.5,2.2c0.1,0.6,0.2,1.1,0.1,1.7
		c-0.1,0.3-0.1,0.6-0.3,0.8c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.1-0.2,0.2-0.2,0.3c0.3-0.1,0.6-0.2,1-0.3c0.8-0.2,1.7-0.5,2.6-0.6
		c0.5-0.1,0.9-0.2,1.4-0.2c0.2,0,0.5,0,0.7,0h0.6c0.8,0,1.6,0.1,2.3,0.3c1,0.1,2,0.5,2.6,1.3c0.3,0.7,0.3,1.6-0.1,2.3
		c0,0.1-0.1,0.1-0.1,0.2c0.1,0,0.2,0.1,0.3,0.1c0.6,0.2,1.2,0.6,1.5,1.2c0.3,0.5,0.5,1.2,0.4,1.8c-0.1,0.6-0.3,1.2-0.6,1.7
		c-0.2,0.4-0.5,0.8-0.9,1.1c-0.3,0.3-0.7,0.5-1.1,0.7c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.3,0.1-0.5,0.1c-0.6,0.1-1.1,0.2-1.7,0.2
		c0.9,0.4,2,1,3.2,1.8c3.7,2.4,7.1,5.1,10.3,8.1c2.2-0.5,4.4-0.8,6.7-1.1c1.2-0.1,2.3-0.2,3.4-0.2c0.5,0.1,1.1,0.1,1.5,0.1l1.4,0.2
		c1.8,0.2,3.1,0.5,4,0.7c3.4,0.4,6.8,1.1,10,2c1,0.3,1.9,0.6,2.8,0.9c0.5-1.3,1.8-3.6,3.6-6.4c-1.7-1.2-4.8-3.8-9.4-6.9
		c-8.8-5.8-18.2-10.6-28.1-14.3c-5.8-2.1-11.4-3.9-16.6-5.3s-9.9-2.7-13.8-3.7c-5.2-1.3-9.1-2.3-11.1-3c-0.4,0.8-0.9,1.6-1.5,2.3
		c-1.4,1.7-3.2,2.9-5.3,3.5C371.5,374.4,367.7,373.7,364.8,371.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M253.7,443.3c-0.1-0.3,1.1-1,3.3-1.7c-0.8-0.3-1.6-0.7-2.3-1.2c-0.6-0.4-1.1-0.9-1.6-1.5
		c-0.6-0.7-1-1.4-1.2-2.3c0-0.2-0.1-0.3-0.1-0.5c-1.6,0.4-4.5,0.7-8.4,0.7c-3.6-0.1-7.2-0.5-10.7-1.4l-1.5-0.4l-1.5-0.5
		c-1-0.3-2-0.8-3.1-1.2c-2.1-0.9-4.2-2.1-6.1-3.4c-2.6-1.8-4.8-4-6.5-6.5c0.2,1.2,0.4,2.5,0.6,3.9c0.5,3.8,0.7,7.6,0.6,11.4
		c0,2.2-0.2,4.4-0.4,6.7s-0.5,4.7-0.6,7v0.6c0,0.1,0,0.1,0,0.1l0.2,0.2l0.4,0.5c0.6,0.8,1.1,1.5,1.7,2.3c0.6,0.8,1.1,1.6,1.6,2.5
		c0.6,0.9,1,1.9,1.3,3l0.1,0.3l0.1,0.3l0.2,0.5c0.1,0.4,0.3,0.7,0.4,1.1c0.3,0.7,0.6,1.4,1,2c0.2,0.3,0.3,0.6,0.6,0.9
		c0.2,0.3,0.2,0.3,0.3,0.4l1,0.4l1.1,0.4c3.9,1.6,7.8,3.4,11.5,5.5c10.1,4.6,20.9,7.6,32,8.8c6.9,0.6,13.9,0.6,20.8,0.2
		c5.9-0.3,10.3-0.7,12.6-1c2.2-0.3,6.5-1.4,12.3-2.7c5.8-1.3,12.9-3,20.8-4.2c0.5-0.1,1-0.1,1.6-0.2c0.2-0.3,0.4-0.5,0.5-0.8
		c0.7-1.3,1.4-2.6,1.8-4c0.5-2,0.8-4.1,0.9-6.2c0.1-3.4-0.3-6.9-1.2-10.2c-0.4-1.6-0.9-3.1-1.6-4.6c-0.3-0.6-0.6-1.3-1-1.9
		c-0.1,0-0.2,0-0.3,0c-1.6-0.2-3.2-0.3-4.7-0.5c-3-0.4-5.8-0.9-8.2-1.4c-4.9-1-8.6-1.8-10.5-2.1c-1.9-0.3-5.6-0.5-10.6-0.8
		c-5-0.3-11.1-0.6-17.6-0.5c-8.1,0.2-15.5,0.7-20.8,1.2C257.1,443.3,253.8,443.8,253.7,443.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M417.4,401.1c-0.4,0.8-1.1,1.3-2,1.5c-0.5,0.1-1,0.1-1.5,0c-0.8-0.1-1.5-0.1-1.9-0.1s-1,0.1-2,0.2
		c-0.5,0.1-1,0.1-1.7,0.2l-1.1,0.1h-0.1h-0.1h-0.1l-0.2,0l0,0.5c0,0,0,0.2,0,0.2l-0.2,0.4c-0.1,0.3-0.2,0.5-0.4,0.8
		c-0.7,1.4-1.5,2.7-2.6,3.9c-1.9,2.2-4.4,3.7-7.2,4.4c1.9,0.3,3.8,0.5,5.7,0.6c0.3,0,0.6,0.1,0.9,0.1c1.9-1,3.9-2.1,6.1-3.1
		c1.3-0.6,2.6-1.3,4.1-1.9c1.4-0.6,2.9-1.1,4.4-1.6c1.8-0.6,3.6-1.2,5.5-1.7c-1.9-1.8-3.7-3.5-5.3-4.9
		C417.5,400.8,417.4,400.9,417.4,401.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M165.3,223.3c-0.1-0.1-0.3-0.1-0.4-0.2c-0.3-0.1-0.6-0.2-0.9-0.3c-0.6-0.2-1.2-0.3-1.8-0.3
		c-0.6-0.1-1.2-0.1-1.8-0.1c-0.3,0-0.6,0-0.9,0h-0.2c0.2,0.5,0.3,1.1,0.4,1.8c0.1,0.7,0,1.4-0.2,1.9l0.3,0.1c0.1,0.1,0.1,0,0.4,0.2
		c0.3,0.1,0.5,0.2,0.8,0.3c0.6,0.1,1.2,0.3,1.8,0.4c0.6,0.1,1.2,0.2,1.9,0.2l0.9,0.1c-0.2-0.5-0.3-1.1-0.4-1.8
		C165.1,224.6,165.2,223.9,165.3,223.3L165.3,223.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M357.1,378.7c0-0.7-0.2-1.4-0.5-2c-1.5-3.3-3.8-6.1-6.9-8c-1.5-0.9-3.2-1.5-5-1.8c-1.7-0.3-3.4-0.3-5,0.1
		c-1.5,0.3-3,0.8-4.4,1.5c-1.3,0.6-2.4,1.4-3.6,2.2c-1.7,1.3-3.2,2.7-4.7,4.2c-1.1,1-1.7,1.6-1.9,1.5c-0.2-0.1,0-1,0.8-2.4
		c1.1-1.8,2.4-3.5,3.9-4.9c-2.1-0.2-4.2-0.3-6.3-0.3c-2.9,0-5.7,0.1-8.4,0.3c-2.7,0.2-5.2,0.5-7.5,0.9c-0.6,0.1-1.2,0.2-1.8,0.3
		c0.8,0.3,1.8,0.6,2.8,1.1c5.2,2.3,9.9,5.4,14,9.4c1.2,1.1,2.3,2.3,3.3,3.6c0.4,0.6,0.9,1.1,1.3,1.7c1.4-2.8,2.9-5.6,4.5-8.3
		c0.5-0.9,1.1-1.7,1.7-2.5c0.3-0.4,0.6-0.8,1-1.2c0.3-0.4,0.7-0.7,1-1c1.3-1.2,2.8-2.2,4.4-2.9c1.3-0.6,2.8-1,4.3-1.1
		c1.1-0.1,2.2,0,3.3,0.2c1.7,0.4,2.5,1,2.5,1.2c-0.1,0.2-0.7,0.2-1.7,0.2c0.2,0.1,0.4,0.3,0.6,0.5c0.9,0.8,1.6,1.9,2.1,3
		c0.3,0.7,0.5,1.4,0.6,2.2c0.1,0.7,0.2,1.4,0.3,2.2c0.1,4-0.4,7.4-1,9.1c0.5,0.3,1,0.6,1.5,0.9c1.5,1.1,3,2.4,4.3,3.7
		c0-0.2,0.1-0.4,0.1-0.7c0.3-2.1,0.5-4.2,0.6-6.3c0.1-1.3,0.1-2.8,0-4.4C357.3,380.3,357.2,379.5,357.1,378.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M333.6,397.2c0.3-0.5,0.5-1.1,0.9-1.7c0.4-0.7,0.8-1.3,1.3-1.8c2-2.2,4.3-4.2,6.8-5.7c1.6-1,3.4-1.4,5.3-1.2
		c0.4,0.1,0.7,0.2,1,0.3c-0.4-1.8-0.6-4.8-0.8-8.1c0-0.7-0.1-1.3-0.2-1.9c0-0.5-0.1-1-0.3-1.5c-0.2-0.9-0.6-1.8-1-2.6
		c-0.4-0.7-0.7-1.4-0.9-1.9c-1.6,0.3-3.2,0.8-4.6,1.7c-1.3,0.8-2.4,1.7-3.5,2.8c-0.3,0.3-0.5,0.6-0.8,0.9c-0.3,0.3-0.5,0.6-0.7,1
		c-0.5,0.7-0.9,1.5-1.4,2.3c-1.7,3.2-3.5,6.8-5.3,10.6c0.8,1.6,1.4,3.2,2,4.9c0.6,1.5,1,2.9,1.2,4
		C333,398.5,333.3,397.8,333.6,397.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M387,397.9c0.2-0.5,0.4-1.1,0.6-1.7c0.2-0.7,0.4-1.3,0.6-2c0.4-1.4,0.9-2.9,1.4-4.5c0.3-0.8,0.5-1.6,0.8-2.4
		c0.1-0.4,0.3-0.8,0.5-1.3c0.2-0.4,0.4-0.8,0.7-1.2c-0.2,0-0.4-0.1-0.6-0.1c-0.7-0.2-1.4-0.3-2.2-0.6l-12.6-3.4
		c-7.2-1.9-13.5-3.5-16.3-4.6c0.2,0.8,0.3,1.6,0.3,2.4c0,0.9,0,1.8,0,2.6c-0.1,1.5-0.2,3.1-0.5,4.6c-0.3,2.2-0.9,4.4-1.8,6.4
		c-0.2,0.4-0.4,0.8-0.6,1.1c0,0.1,0.1,0.1,0.1,0.2l0.5,0.5c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.3,0.3,0.4
		c0.3,0.5,0.5,1.1,0.5,1.7c0,0.6-0.1,1.3-0.4,1.9c2.2-0.5,4.4-0.8,6.7-0.9c1.6-0.1,3.2-0.1,4.8,0c0.8,0.1,1.6,0.2,2.4,0.3
		c0.4,0.1,0.8,0.2,1.2,0.3c0.5,0.1,0.9,0.3,1.3,0.5c1.4,0.8,2.7,1.7,3.8,2.8c0.4,0.4,0.8,0.7,1.1,1.1c0.1,0,0.2-0.1,0.2-0.1
		c1.6-0.6,3.1-1.3,4.5-2.3C385.7,399.4,386.4,398.7,387,397.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M60.5,373c0.5-0.8,1.1-1.5,1.9-2.1c0.3-0.2,0.6-0.4,0.8-0.5c0.1-0.1,0.3-0.2,0.5-0.2c0-0.5,0-0.8,0-0.9
		c-0.1-1.3,0-2.6,0.4-3.9c0.4-1,0.9-2,1.7-2.8c0.4-0.5,0.9-0.9,1.5-1.3c0.3-0.2,0.6-0.4,0.9-0.5l0.2-0.1c0,0,0-0.1,0-0.1
		c0-0.2,0-0.3,0-0.5c0-0.3,0-0.5,0-0.8c0.1-0.6,0.2-1.1,0.3-1.6c-0.4-0.2-1-0.7-1.7-1.4c-0.7-0.8-1.3-1.7-1.7-2.6
		c-0.6-1.2-0.9-2.5-0.8-3.9c0-1.3,0.3-2.6,0.9-3.9c0.5-1,1.1-1.9,1.9-2.6c0.5-0.5,1.2-0.9,1.9-1.1c0.1,0,0.2,0,0.3,0
		c0.3-0.8,0.7-1.5,1.2-2.1c0.2-0.2,0.5-0.4,0.7-0.6c-0.5-0.7-1.2-1.9-1.9-3.6c-0.7-1.7-1.1-3.5-1.4-5.3c-0.1-0.8-0.1-1.6-0.1-2.3
		l-0.8-0.4l-1.5-0.6c-1-0.4-2.1-0.9-3.3-1.3c-2-0.8-4.1-1.3-6.3-1.5c-1.3-0.1-2.6,0-3.9,0.3c-0.4,0.1-0.7,0.3-1.1,0.5
		c-0.5,0.3-1,0.6-1.4,1c-0.1,0.1-0.2,0.3-0.3,0.5c0,0.2-0.1,0.3-0.1,0.5c0,0.4,0,0.7-0.1,1.1c-0.1,0.5-0.2,1-0.3,1.5
		c0,0.1-0.1,0.4-0.2,0.6l-0.2,0.7l-0.5,1.5c0,0-0.1-0.1-0.2-0.1c-0.7,2.1-1.6,4.2-2.5,6.3c-0.2,0.4-0.3,0.9-0.6,1.5
		c-0.3,0.6-0.6,1.2-0.9,1.9c-0.7,1.8-1.3,3.6-1.8,5.4c-0.7,2.3-1.1,4.7-1.4,7.1c-0.2,1.3-0.2,2.6-0.1,3.9c0.1,1.4,0.3,2.8,0.6,4.1
		c0.4,1.7,0.9,3.4,1.4,5l0.9,2.4l0.8,2.3c0.5,1.4,1.1,2.7,1.8,4c0.3,0.5,0.6,1,1,1.5c0.1,0.1,0.2,0.2,0.3,0.2
		c0.1,0.1,0.2,0.1,0.2,0.2l0.2,0c0.1,0,0.2,0,0.3,0c0.9-0.3,1.8-0.7,2.6-1.3c0.7-0.5,1.4-0.9,2.1-1.3c1.2-0.6,2.1-0.8,2.1-0.6
		c0.1,0.2-0.6,0.6-1.6,1.3c0,0-0.1,0.1-0.1,0.1c2.1-0.8,4.3-1.5,6.5-1.9C59.9,374,60.2,373.5,60.5,373z M58.7,353.5
		c0-0.5,0-0.9,0-1.4c0-0.9,0.1-1.7,0.3-2.6c-1.6,0.1-3.1,0.3-4.6,0.8c-1.6,0.5-3,1.2-4.4,2c-2.3,1.6-3.4,3.1-3.7,2.9
		c-0.3-0.2,0.4-2,2.8-4.1c1.4-1.2,3-2.1,4.7-2.7c1.9-0.7,3.9-1,5.9-1c0.1-0.1,0.1-0.3,0.2-0.4c0.7-1.3,1.7-2.4,3-3.1
		c2.3-1.1,3.8-0.8,3.8-0.6c0.1,0.3-1.3,0.7-3,1.9c-0.9,0.7-1.7,1.6-2.1,2.7c-0.6,1.3-0.8,2.8-0.8,4.3c0,1.4,0.3,2.9,0.9,4.2
		c0.5,1.1,1.1,2.3,1.7,3.3c1.2,1.9,2,3,1.8,3.2c-0.2,0.2-1.4-0.6-3-2.3c-0.9-1-1.7-2.2-2.3-3.4c-0.4-0.7-0.7-1.5-0.9-2.3
		C58.8,354.4,58.7,354,58.7,353.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M55.4,318.3c1.3-0.2,2.5-0.2,3.8-0.1c2.8,0.2,5.5,1,8,2.3c0.7,0.4,1.3,0.8,1.7,1.2c0.6-2.6,1.3-5.2,2.3-7.7
		l0.2-0.5c0-0.1,0.1-0.2,0.2-0.4c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.3-0.2,0.4-0.3c-0.4-0.4-0.7-1.3-1-2.4c-0.4-1.4-0.6-2.7-0.1-3
		c0.5-0.3,1.7,0.7,2.1,2.5c0.2,0.8,0.2,1.6,0.1,2.2c0.3-0.2,0.5-0.3,0.8-0.5c0.9-0.5,1.6-0.8,2.1-1.1c1.7-1,3.6-1.9,5.5-2.6
		c1.5-0.6,3-0.9,4.6-1.2c1.9-0.3,3.7-0.2,5.6,0.1c3.9,0.7,6.9,2.8,9.3,4c1,0.5,2.1,1,3.2,1.3c0.7,0.2,1.5,0.3,2.2,0.2
		c0.8-0.1,1.5-0.2,2.2-0.4c1.1-0.4,2.2-0.8,3.3-1.4c1.4-0.7,2.9-1.3,4.4-1.7c1.8-0.5,3.7-0.6,5.5-0.6c4.1,0.2,8,1.3,11.5,3.3
		c1.9,1.1,3.2,2.1,3.7,2.7c0.3,0,0.6,0,0.9,0c0-0.4,0.1-0.8,0-1.3c0-0.3-0.1-0.6-0.3-0.9l-0.5-0.7c-0.2-0.3-0.4-0.6-0.5-0.9
		c-0.4-0.7-0.7-1.5-0.8-2.3c-0.1-1,0-2,0.4-2.9l0.4-0.7c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.3,0.1-0.6,0.1-0.9c0-0.6-0.2-1.3-0.5-1.8
		c-0.7-1-1-2.3-0.9-3.5c0.1-0.9,0.3-1.9,0.6-2.7c0.2-0.4,0.3-0.7,0.3-0.9c0-0.2,0-0.4,0-0.6c-0.2-0.5-0.4-1-0.8-1.5
		c-0.5-0.8-0.8-1.8-0.9-2.8c0,0,0-0.1,0-0.1c-0.7-0.2-2-0.9-3.9-1.8c-1.3-0.7-2.8-1.5-4.5-2.3c-0.3-0.2-0.6-0.3-1-0.5
		c-0.3,0.3-0.6,0.7-0.9,1c-2.6,2.6-5.6,4.7-9,6.1c-2,0.8-4.1,1.4-6.2,1.7c-0.4,0.1-0.8,0.2-1.2,0.2c0.1,0.2,0.3,0.4,0.4,0.6
		c0.4,0.7,0.6,1.4,0.7,2.1c0.2,0.9,0.2,1.7,0,2.6c-0.2,1.1-0.7,2-1.6,2.7c-0.9,0.7-2,1.1-3.2,1.1c-1.1,0-2.2-0.4-3.1-1.1
		c-0.8-0.7-1.4-1.6-1.6-2.7c-0.3-1.7,0.2-3.3,1.2-4.7c0.1-0.1,0.2-0.2,0.3-0.3c-0.2,0-0.5-0.1-0.7-0.1c-0.8-0.1-1.7-0.4-2.6-0.5
		c-1.9-0.5-3.7-1.3-5.4-2.2c-3.8-2-7.1-4.8-9.9-8.1c-0.1-0.1-0.2-0.2-0.2-0.3c-2.6,1-5.1,2-7.4,3.1c-2.2,1.2-4.4,2.2-6.8,3.1
		c-1,0.3-2.1,0.6-3.1,0.7c-0.9,0.1-1.7,0.3-2.5,0.6c-1.2,0.3-2.3,0.8-3.4,1.4c-1,0.6-1.9,1.4-2.6,2.4c-0.5,0.7-0.9,1.5-1.3,2.3
		c-0.4,0.9-0.9,1.8-1.3,2.8c-0.4,1-0.6,2.1-0.6,3.1c0,0.6,0.1,1.2,0.2,1.7c0.1,0.7,0.2,1.3,0.3,2c0.2,1.5,0.1,2.9-0.1,4.4
		c-0.1,0.7-0.3,1.5-0.6,2.2l-0.6,2.1c-0.5,1.7-1,3.4-1.5,5c0.3-0.1,0.7-0.3,1-0.4C53.2,318.7,54.3,318.4,55.4,318.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M115.7,225.2c1.4-0.1,3.4-0.2,5.9-0.4c1.7-0.1,3.4-0.3,5.4-0.5c1-0.1,2-0.3,3-0.5c0.2,0,0.5-0.1,0.7-0.2
		c0.3-0.1,0.7-0.2,1-0.3l0.1,0c0-0.3-0.1-0.6-0.2-0.9c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.3-0.3-0.4-0.4c-0.1-0.1-0.3-0.3-0.4-0.4
		l-0.5,1.1l0.1-1.4c-0.2-0.1-0.3-0.3-0.5-0.5c-0.6-0.5-1.1-1-1.5-1.6c-0.2-0.2-0.3-0.4-0.4-0.7c0-0.1-0.1-0.1-0.1-0.2l-0.1-0.1
		c-0.2-0.2-0.4-0.5-0.6-0.6c-0.6-0.5-1.2-0.9-1.8-1.4c-0.6-0.4-1.1-0.9-1.6-1.5l-1.2-1.4c-0.1-0.2-0.6-0.4-1.1-0.6
		c-0.5-0.2-1-0.3-1.5-0.5c-0.5-0.1-1-0.3-1.5-0.6c-0.2-0.2-0.4-0.4-0.6-0.6l-0.3-0.5c-0.2-0.5-0.5-0.9-0.9-1.2
		c-0.5-0.5-2-0.2-4.1-0.5c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.3-0.1-0.5-0.2l-0.4-0.3c-0.4-0.3-0.8-0.4-1.3-0.6
		c-0.5-0.1-1-0.2-1.5-0.1c-0.3,0-0.5,0-0.8,0.1c-0.3,0-0.7,0.1-1.1,0.1c-0.8,0-1.6-0.1-2.3-0.4c-0.4-0.2-0.9-0.4-1.3-0.4
		c-0.5,0.1-0.9,0.3-1.3,0.5c-0.5,0.2-1,0.5-1.6,0.6c-0.5,0.1-1.1,0.2-1.6,0.1c-0.5-0.1-0.9-0.1-1.2-0.2c-0.5-0.1-1.1-0.2-1.6-0.2
		c-0.5,0-1,0.1-1.4,0.4c-0.5,0.4-1,1.1-1.8,1.9c-1.7,2.1-5.8,0.4-5.9,2.1c-0.8,1.4-1.6,3.1-3.4,3.9c-1,0.3-2,0.9-2.6,1.8
		c-0.4,0.7-0.6,1.4-0.6,2.1c0,0.2,0,0.3,0,0.5l0.6,0.1l0.8,0.2l1.6,0.3l3.8,0.8c2.8,0.5,6,1.1,9.4,1.7c4.4,0.7,8.5,1.3,11.7,1.7
		c0.4,0,0.8,0.1,1.1,0.1c0.2-0.7,0.7-1.2,1.2-1.6c1.2-0.8,2.7-0.9,4-0.2C114.8,223.5,115.4,224.3,115.7,225.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M281.6,375.2c-1.7-0.4-3.3-0.7-4.9-0.9l-1.2-0.2c-0.5-0.1-0.9-0.1-1.4-0.3c-0.8-0.2-1.6-0.5-2.4-0.8
		c-1.5-0.6-2.8-1.3-4-1.8c-2.1-1-4.3-1.8-6.5-2.2c-1.5-0.3-3-0.5-4.5-0.5c-3.9,0-7.8,0.4-11.6,1.1c-1.3,0.3-2.7,0.5-4.2,0.9
		c-0.7,0.2-1.5,0.4-2.2,0.6l-1,0.4c-0.4,0.1-0.7,0.3-1.1,0.5c-3.1,1.4-5.9,3.1-8.6,5.2c-5.1,3.8-9.5,8.5-13,13.8
		c-0.8,1.1-1.5,2.2-2,3.2c-0.5,1-1,2-1.4,2.9c-0.6,1.4-1.1,2.8-1.3,4.3c-0.5,3.8-0.1,7.7,1.2,11.3c0.9,2.7,2.3,5.3,3.9,7.7
		c2,2.7,4.3,5,7,6.9c1.7,1.2,3.5,2.3,5.4,3.1c0.9,0.4,1.8,0.8,2.8,1.1l1.4,0.5l1.4,0.4c3.3,0.9,6.6,1.6,10,2
		c3.6,0.4,6.5,0.6,8.1,0.8c0-0.4,0-0.8,0.1-1.2c0.2-0.8,0.5-1.5,0.9-2.2c0.7-1,1.7-1.8,2.8-2.4c1.8-0.8,3.1-0.6,3.2-0.1
		c0.1,0.5-0.7,1.2-1.8,2.3c-0.6,0.6-1,1.2-1.4,1.9c-0.3,0.7-0.4,1.5-0.2,2.2c0.3,0.8,0.8,1.6,1.5,2.1c0.7,0.6,1.3,1.2,2.1,1.7
		c0.6,0.4,1.1,0.8,1.4,1.1c0.6-0.1,1.2-0.3,1.8-0.4c1.3-0.3,2.5-0.5,3.8-0.7c0.7-4.6,2.7-8.9,5.9-12.4c2.6-3,5.8-5.4,9.4-7.2
		c0.8-0.4,1.4-0.8,2.1-1l1.5-0.6c0.2-0.9,0.8-2.3,1.7-3.9c2.2-3.9,5.1-7.4,8.6-10.2c0.3-0.2,0.6-0.5,0.9-0.7c-0.2,0-0.4,0.1-0.7,0.1
		c-0.3,0-0.6-0.1-0.8-0.2c-0.3-0.1-0.5-0.2-0.8-0.4c-0.2-0.1-0.5-0.3-0.7-0.5c-0.2-0.1-0.3-0.3-0.4-0.4c-0.5-0.5-0.9-1.1-1.2-1.7
		c-0.6-1.1-1-2.2-1.3-3.4c-0.3-1.1-0.4-2.2-0.3-3.3c0.1-1,0.4-2,0.9-2.9c0.7-1.3,1.9-2.3,3.3-2.8c1-0.3,1.6,0,1.6,0.2
		c0,0.3-0.4,0.5-1,1c-0.7,0.7-1.3,1.6-1.5,2.5c-0.4,1.4-0.3,3,0.3,4.3c0.3,0.9,0.7,1.7,1.2,2.5c0.2,0.4,0.5,0.7,0.8,1.1l0.2,0.2
		l0.1-0.1c0.3-0.3,0.6-0.6,0.8-1c0.5-0.8,0.9-1.6,1.3-2.5c0.6-1.3,0.7-2.9,0.3-4.3c-0.3-1-0.8-1.9-1.5-2.7c-0.5-0.6-0.9-0.8-0.9-1
		c0-0.2,0.6-0.5,1.6-0.2c1.3,0.6,2.4,1.6,3.1,2.9c0.5,0.9,0.8,1.9,0.9,2.9c0.1,1.1-0.1,2.3-0.4,3.4c-0.3,1.2-0.8,2.3-1.4,3.3
		c-0.2,0.4-0.5,0.8-0.8,1.1c2.1-1.2,4.3-2.2,6.6-2.9c-0.1-0.4-0.2-0.8-0.3-1.2c-0.5-2.2-1.2-4.3-2.3-6.3c-0.7-1.3-1.6-2.6-2.6-3.7
		c-1.2-1.3-2.5-2.6-3.8-3.8c-1.4-1.3-3-2.6-4.6-3.8c-1.6-1.3-3.4-2.4-5.2-3.3C284.8,376,283.2,375.5,281.6,375.2z M271.7,373
		c0,0-0.4,2.4-0.5,3.2s1.3,5,1.1,6.2c-0.2,1.2-2.5,2.5-2.5,2.5c0.4-0.5,0.6-1.1,0.6-1.8c0.1-1.3-1.7-6.1-1.6-6.8
		S271.7,373,271.7,373z M266.5,433.5c-0.1,0.3-0.9,0.2-2,0.1c-0.6-0.1-1.3-0.1-1.9-0.2c-0.7,0-1.4,0.1-2,0.3
		c-0.3,0.1-0.5,0.3-0.7,0.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.5-0.2,1-0.1,1.5c0.1,1.1,0.3,1.8,0,2
		c-0.3,0.2-0.9-0.4-1.4-1.6c-0.4-0.8-0.5-1.7-0.3-2.5c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.2,0.3-0.5,0.5-0.7c0.4-0.5,0.8-0.8,1.4-1
		c1-0.4,2-0.5,3-0.3c0.7,0.1,1.5,0.3,2.1,0.7C266,432.7,266.6,433.3,266.5,433.5z M265.3,404.5c-0.4,1.4,0.2,6.1,0.2,6.1
		s-2.2-4.8-2.3-6.3c-0.1-1.6,2.5-5.9,3-6.6c0.6-1.1,1.1-2.2,1.5-3.4c0,0,1.5,2.2,1.2,3.3C268.5,398.6,265.7,403.1,265.3,404.5z
		 M273.2,399.1c-0.2,1.1-0.4,2.4-0.6,3.9c-0.4,3-0.4,5.8-1.4,6.1c-0.5,0.1-1.1-0.5-1.6-1.6c-0.6-1.5-0.8-3.2-0.6-4.9
		c0.2-1.7,0.9-3.3,1.9-4.6c0.9-1,1.8-1.3,2.1-1S273.3,398.1,273.2,399.1z M276,406.9c-0.3-0.1-0.2-1.8-0.3-3.9s0-3.8,0.3-3.9
		c0.3-0.1,0.8,1.7,0.8,3.9S276.3,407,276,406.9z M277.7,377.1c-0.1,0.7-0.2,1.6-0.3,2.6c-0.3,1.9-0.3,3.9-1.3,4.3
		c-0.5,0.1-1.1-0.2-1.6-1c-0.6-1.1-0.9-2.4-0.7-3.7c0.1-1.3,0.8-2.6,1.8-3.5c0.8-0.7,1.6-0.8,1.9-0.4
		C277.8,375.9,277.9,376.6,277.7,377.1z M280.9,379.7c-0.4,1.5-1,2.6-1.3,2.4c-0.2-0.1,0-1.2,0.3-2.6c0.3-1.4,0.7-2.5,1-2.5
		C281.2,377,281.2,378.3,280.9,379.7z M288.8,385.5c0.5,0.7,0.8,1.5,0.8,2.4c0,0.8-0.1,1.7-0.5,2.5c-0.3,0.7-0.8,1.4-1.3,2
		c-0.2,0.2-0.3,0.4-0.5,0.6c-0.1,0.2,0,0-0.1,0.1c-0.1,0.3-0.1,1-0.1,1.6v0.5c0,0.3-0.1,0.5-0.1,0.8c-0.1,0.5-0.3,0.9-0.6,1.3
		c-0.6,0.6-1.4,1-2.2,0.9c-0.3,0-0.7-0.1-1-0.2c-0.2-0.1-0.5-0.2-0.7-0.3c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.2-0.2-0.5-0.2-0.8
		c-0.1-0.2,0-0.3,0.1-0.3c0.3,0,0.5,0.1,0.9,0.1c0.2,0,0.4,0,0.6,0h0.4c0.1,0,0.2-0.1,0.2-0.1c0.1-0.1,0.2-0.2,0.2-0.3
		c0,0,0-0.1,0-0.2v-0.1v-0.3c-0.1-0.7-0.1-1.3-0.1-2c0-0.6,0.2-1.2,0.6-1.7c0.2-0.3,0.5-0.7,0.7-1c0.6-0.6,1.1-1.4,1.2-2.3
		c0.1-0.2,0.1-0.5,0-0.7c-0.1-0.2-0.2-0.5-0.4-0.6c-0.4-0.4-0.9-0.7-1.4-0.9c-0.4-0.2-0.7-0.4-0.7-0.6c-0.1-0.2,0.2-0.6,0.8-0.8
		c0.8-0.3,1.7-0.2,2.6,0.1C287.8,384.5,288.4,384.9,288.8,385.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M62.3,289.3c0.8-0.3,1.6-0.6,2.6-0.8c1-0.2,1.9-0.5,2.9-0.9c1.9-0.8,4-2.2,6.4-3.5c2.4-1.3,4.9-2.4,7.4-3.4
		l0.1-0.1c0.2-0.2,0.3-0.3,0.5-0.5c0.1-0.1,0.2-0.1,0.3-0.2l0.2-0.1c0.3-0.1,0.5-0.2,0.8-0.3c0.6-0.1,0.9-0.1,1.2,0l2.3-0.9
		c0.3-0.1,0.6-0.2,0.9-0.4c0.3-0.2,0.5-0.4,0.7-0.7c0.4-0.6,0.8-1.2,1.1-1.9c1-2.5,1.6-5.2,1.8-7.9c0.3-2.4,0.4-4.3,0.5-5.6
		c0-0.6,0.1-1,0.1-1.4c-0.6-0.7-1.1-1.4-1.6-2.1c-0.4-0.6-0.8-1.2-1-1.6c-0.2,0.4-0.4,0.5-0.5,0.5c-0.1-0.1-0.1-0.4-0.2-1
		c-0.1-0.8-0.5-1.5-1-2.1l-0.6-0.7c-0.3-0.3-0.6-0.8-0.6-1.3c0-1,0.3-1.9,1-2.7c0.3-0.4,0.6-0.7,0.9-1c0.2-0.1,0.3-0.3,0.5-0.4
		c0.1-0.1,0.2-0.1,0.2-0.2c0.2-0.5,0.2-1.1-0.1-1.5c-0.2-0.6-0.5-1.1-0.9-1.6c-0.5-0.6-0.9-1.4-1-2.3c0-0.8,0.2-1.6,0.7-2.3
		c0.3-0.5,0.7-0.9,1.1-1.4c0.5-0.6,0.7-1.4,0.6-2.2c-0.1-0.6-0.2-1.2-0.3-1.7c-0.1-0.4-0.3-1-0.5-1.8c-0.1-0.5-0.1-1,0-1.5
		c0.1-0.6,0.4-1.1,0.8-1.6c0.4-0.5,1-0.9,1.6-1.2c0.3-0.2,0.7-0.3,1-0.4c0.4-0.1,0.9-0.1,1.3-0.1c0.4,0.1,0.8,0.2,1.1,0.4
		c0.3,0.1,0.3,0.1,0.3,0.1L95,229l0.3-0.3c0.2-0.1,0.4-0.2,0.7-0.3c0.3-0.1,0.7-0.1,1-0.1c0.5,0.1,1,0.3,1.4,0.6
		c0.2,0.1,0.3,0.2,0.5,0.2c0.6,0,1.2-0.1,1.7-0.2c0.5-0.1,0.9,0,1.3,0.2c0.4,0.1,0.7,0.3,1,0.6c0.5,0.4,0.9,0.8,1.2,1
		c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3,0,0.4-0.1l0.2-0.2l0.4-0.3c0.2-0.2,0.5-0.3,0.7-0.3c0.4,0.2,0.7,0.5,1,0.8
		c0.1,0.2,0.3,0.3,0.5,0.5c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.5-0.3,0.9-0.4c0.3-0.2,0.7-0.2,1.1-0.2c-0.6-0.3-1-0.7-1.4-1.2
		c-0.1-0.2-0.2-0.4-0.3-0.6c-3.4-0.1-6.8-0.3-10.2-0.7c-10.1-1.3-18.2-3.9-19.2-4.7c0.1,0.2,0.1,0.5,0,1c0,0.2-0.1,0.4-0.2,0.6
		c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4,0.3-0.7,0.6-0.9,1c-0.2,0.4-0.1,0.7,0,1.4c0.2,0.8,0.3,1.7,0.2,2.5
		c-0.1,0.6-0.3,1.1-0.7,1.6l-0.3,0.3l-0.2,0.2l-0.5,0.5c-0.6,0.5-1.1,1.1-1.6,1.8c-0.1,0.2-0.2,0.3-0.2,0.5c0,0,0,0,0,0.1v0.2
		l0.1,0.5c0.3,0.8,0.4,1.7,0.3,2.5c-0.1,0.5-0.4,0.9-0.8,1.3c-0.2,0.1-0.3,0.3-0.5,0.4L73,241c-0.5,0.3-1,0.6-1.4,0.9
		c-0.4,0.2-0.7,0.6-0.9,0.9c-0.2,0.3-0.2,0.7-0.1,1c0.2,0.7,0.4,1.4,0.5,1.8c0.1,0.3,0.1,0.6,0,0.9c-0.1,0.4-0.3,0.8-0.6,1.1
		c-0.6,0.7-1.2,1.4-1.6,2.2c-0.2,0.4-0.3,0.8-0.2,1.2c0,0.1,0.1,0.2,0.1,0.3l0.2,0.4l0.5,0.8c0.2,0.4,0.4,0.7,0.5,1.2
		c0.1,0.6,0.1,1.1-0.1,1.7c-0.2,0.4-0.5,0.8-0.8,1.2c-0.3,0.3-0.5,0.5-0.8,0.8c-1.1,1-2.2,2-2.1,2.7c0,0.5,0.1,1.1,0.3,1.5
		c0.2,0.5,0.5,1,0.8,1.5c0.2,0.4,0.3,0.8,0.2,1.3c-0.1,0.3-0.2,0.6-0.4,0.9c-0.3,0.4-0.6,0.8-0.9,1.1c-0.3,0.3-0.6,0.5-0.9,0.8
		c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.1,0.2-0.2,0.3c-0.3,0.5-0.4,1.1-0.5,1.7c-0.1,0.4-0.1,0.8,0,1.2c0,0.2,0.1,0.3,0.2,0.5
		c0.2,0.2,0.4,0.3,0.5,0.5c0.5,0.6,0.8,1.2,0.9,1.9c0.2,0.9,0,1.8-0.5,2.5c-0.1,0.2-0.3,0.4-0.4,0.5l-0.4,0.4l-0.8,0.7
		c-0.2,0.2-0.4,0.4-0.6,0.6c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.3,0.1,0.5,0.3,0.7c0.2,0.3,0.5,0.7,0.8,1c0.3,0.4,0.5,0.7,0.8,1.1
		c0.4,0.5,0.5,1.2,0.5,1.8c-0.1,0.6-0.4,1.1-0.8,1.5c-0.3,0.3-0.6,0.5-0.8,0.8c-0.5,0.5-1,0.9-1.4,1.3c-0.6,0.5-1,1.3-1,2.1
		c0,0.2,0.1,0.4,0.1,0.6C61.7,289.5,62,289.4,62.3,289.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M399,408c0.6-0.5,1.2-1.1,1.8-1.7c1-1.1,1.8-2.2,2.5-3.1l0.5-0.6l0.2-0.3l0.1-0.2l0.2-0.2
		c0.2-0.4,0.5-0.8,0.8-1.2l2,0.2l1.1,0.1l1.7,0.2c0.6,0.1,1.3,0.1,1.9,0.1c0.6,0,1.2-0.1,1.8-0.3c0.4-0.1,0.8-0.2,1.1-0.4
		c0.2-0.1,0.3-0.3,0.3-0.5c0-0.2-0.1-0.5-0.3-0.5c-0.1,0-0.1-0.1-0.2,0c0,0,0,0-0.1,0h-0.4c-1.2,0-2.6,0-4.1,0
		c-1.5,0-2.9-0.1-4.3-0.2l-0.6,0c-0.5,0-0.9-0.2-1.3-0.5c-0.3-0.2-0.5-0.6-0.6-0.9c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0.1-0.5,0.2-0.8
		l0.1-0.3c0-0.1,0.1-0.3,0.2-0.4c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.2,0.4-0.3,0.6-0.5c0.1-0.1,0.2-0.1,0.3-0.2l0.3-0.1
		c1-0.4,2.2-0.5,3.3-0.5c0.6,0,1.2,0,1.8-0.1c1.5-0.5,3-1.1,4.4-1.8c0.2-0.1,0.4-0.2,0.5-0.3c0.1-0.1,0.2-0.2,0.3-0.4
		c0.1-0.1,0.2-0.3,0.2-0.5c0,0,0-0.1,0-0.1c-0.1,0-0.1,0-0.2,0h-0.3c-0.2,0-0.3,0-0.5,0c0.1,0.2,0.2,0.3,0,0h-0.1
		c-0.1,0.1-0.3,0.1-0.4,0.2l-0.2,0.1c-0.3,0.1-0.5,0.2-0.8,0.2c-0.5,0.1-0.9,0.2-1.4,0.2l-2.5,0.3c-1.6,0.2-3.1,0.3-4,0.4
		c-2,0.4-3,1.5-3.5,1.2c-0.2-0.1-0.3-0.6,0.1-1.3c0.6-1,1.4-1.8,2.4-2.3c0.3-0.2,0.7-0.3,1-0.4c0.4-0.1,0.7-0.2,1.1-0.3
		c0.7-0.2,1.5-0.4,2.3-0.6c0.8-0.2,1.6-0.3,2.5-0.5l1.3-0.2l0.5-0.1h0l0.1,0l0.2-0.1l0.3-0.1c0.3-0.1,0.7-0.1,1-0.1
		c0-0.2-0.1-0.4-0.3-0.6c-0.2,0-0.4,0-0.5,0.1c-0.5,0-1.2,0-1.9,0.1c-0.7,0-1.3,0.1-2,0.3c-0.7,0.2-1.5,0.4-2.3,0.7
		c-1.6,0.5-3.2,0.9-4.7,1.3c-0.7,0.2-1.5,0.3-2.2,0.5c-0.4,0.1-0.5,0.1-0.8,0.1c-0.2,0.1-0.4,0.1-0.6,0.2c-0.7,0.3-1.4,0.7-2,1.2
		c-0.5,0.4-0.9,0.5-1.1,0.5s-0.3-0.6-0.1-1.3c0.3-0.9,0.8-1.6,1.5-2.3c0-0.1,0-0.2,0-0.2c0-0.4,0.1-0.7,0.3-1
		c0.3-0.3,0.7-0.6,1.2-0.8c0.3-0.2,0.7-0.4,1.1-0.6c0.8-0.4,1.7-0.9,2.4-1.5c0.3-0.2,0.6-0.5,0.8-0.9c0-0.1,0.1-0.1,0.1-0.2
		c0,0,0-0.1,0-0.1c0-0.1,0-0.1-0.1-0.2c0-0.1-0.2-0.1-0.3-0.1h-0.1h-0.1c-0.3,0.1-0.6,0.2-0.9,0.4c-1.3,0.6-2.6,1.3-4,2
		c-0.7,0.4-1.4,0.8-2.1,1.2l-1,0.6l-0.5,0.3l-0.1,0.1l-0.1,0.2l-0.2,0.5c-0.1,0.3-0.3,0.7-0.5,1.1c-0.3,0.7-0.6,1.5-0.8,2.2
		c-0.5,1.5-1.1,3-1.6,4.4c-0.3,0.7-0.5,1.4-0.8,2.1c-0.3,0.7-0.6,1.4-1,2.1c-0.4,0.7-1,1.2-1.7,1.7c-0.5,0.4-1.1,0.7-1.7,1
		c-1.8,0.8-3.5,1.2-4.7,1.3c0.2,0.2,0.4,0.4,0.7,0.6c1.9,1.9,3.7,3.7,5.4,5.3c0.8,0.7,1.6,1.4,2.5,2l0.6,0.4
		c0.2,0.1,0.4,0.2,0.6,0.3c0,0,0.1,0,0.1,0c0.6-0.3,1.4-0.6,2.4-1c1.2-0.5,2.4-1.1,3.5-1.8C397.8,408.9,398.4,408.5,399,408z"
          />
          <g>
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M97.9,506.8l-0.2-0.3c-0.2-0.2-0.3-0.5-0.5-0.7l-0.1-0.2l-0.1-0.1c0,0-0.1-0.1-0.1-0.2
			c-0.2-0.5-0.4-1-0.6-1.5c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.3-0.2-0.5-0.3-0.8c-0.2-0.5-0.5-1.1-0.8-1.6c-0.7-1.2-1.5-2.2-2.5-3.1
			c-0.6-0.5-1.2-0.9-1.8-1.2c-0.7-0.4-1.5-0.8-2.2-1.2s-1.5-0.9-2.3-1.4c-0.4-0.3-0.8-0.5-1.2-0.8c-0.3-0.3-0.6-0.5-1-0.7
			c-1.3-0.9-3-0.9-4.4-0.1c-1.6,1.1-3.1,2.3-4.5,3.6c-3.1,2.7-6,5.6-8.8,8.6c-2.7,3-5,6-7.3,9c-4.5,5.9-8.8,11-12.5,15.3
			c-1.6,1.9-3,4-4.4,6l-3.2,4.7c-1.7,2.7-2.7,4.1-2.9,4c-0.2-0.1,0.6-1.7,2.2-4.4c0.8-1.4,1.7-3,2.9-5c1.3-2.2,2.7-4.3,4.2-6.4
			c1.1-1.3,2.2-2.8,3.4-4.3c0.6-1.2,1.7-3.1,3.1-5.5c3.3-5.4,7-10.7,10.9-15.7c1.9-2.3,3.9-4.4,6.1-6.3c2-1.8,4-3.4,5.8-4.8
			c3.5-2.8,6.3-4.8,7.7-5.8l2.9-2.4l2.2-1.8l0.8-0.6l0.3-0.3l0.2-0.1l0.1-0.1h-0.1l-2,0.8c-2.7,0.5-5.2,1.7-7.3,3.5
			c-0.3,0.3-0.4,0.5-0.7,0.8s-0.5,0.6-0.8,0.9c-0.6,0.6-1,1.1-1.6,1.6c-1,1-2,2-3,2.9c-2,1.9-3.8,3.5-5.4,5.1
			c-2.3,2.2-4.5,4.5-6.5,7c-2.1,2.7-7.6,11.3-14.7,21.1l-1.6,2.2c-0.5,0.7-1,1.4-1.5,2.2c-1,1.4-1.9,2.9-2.7,4.2l-4.5,7.3
			c-2.6,4.1-4.3,6.6-4.5,6.5c-0.3-0.1,1-2.9,3.2-7.2c0.1-0.1,0.1-0.3,0.2-0.4c-0.5,0.8-1,1.6-1.5,2.3c-0.2,0.3-0.5,0.7-0.8,1
			c-0.3,0.4-0.6,0.7-0.9,1c-0.5,0.4-1.1,0.9-1.5,1.4c-1.5,1.6-2.8,3.4-4,5.2c-0.9,1.3-1.2,2-1.4,2c-0.3-0.1,0.6-3.3,3.8-7.5
			c0.3-1.1,1.3-3.6,2.6-7.1c2.4-6,4.5-12.1,6.1-18.3c0.7-2.8,1.2-5.6,1.4-8.5c0.1-2.5-0.2-4.9-0.9-7.3c-0.5-1.9-1.2-3.7-2.2-5.5
			c-0.6-1.1-1.2-2.2-1.9-3.3l-0.2-0.3c0.7,2.1,1.3,4.2,1.8,6.3c0.5,2.3,0.8,4.6,0.9,7c0,1.3,0,2.7-0.1,4c-0.1,1.3-0.2,2.7-0.4,4.1
			c-0.4,3-0.9,5.9-1.7,8.8c-0.3,1.3-0.8,2.6-1.4,3.9c-0.5,1.2-1,2.3-1.5,3.3c-2,4.2-3.6,7.3-4.3,8.9s-2,4.9-3.8,9.1
			c-0.9,2.1-2.1,4.4-3.3,6.9s-2.6,5.1-4.1,7.8c-3.6,6.7-7.1,12.7-9.8,16.9c-1.9,3-3.5,5-4.3,5.9v22.7c2.1-3.4,3.9-6.8,5.6-9.9
			l4.6-8.9c2.6-5.1,4.2-8.2,4.5-8.1c0.3,0.1-0.9,3.5-3.2,8.7c-1.1,2.6-2.5,5.8-4.2,9.2c-1.8,3.7-3.7,7.4-5.9,10.9
			c-0.1,0.2-0.9-0.3-1.4-0.7v12.5c2.7-5.1,5.1-10.1,7.4-14.6c1.2-2.4,2.3-4.7,3.3-6.9c1-2.2,2-4.2,2.9-6.1
			c3.6-7.4,6.2-11.7,6.5-11.6s-1.8,4.7-5.1,12.2c-0.8,1.9-1.7,3.9-2.6,6.2c-0.9,2.2-2,4.6-3.1,7c-2.3,4.9-4.9,10.3-7.8,15.8
			c-0.1,0.2-0.8-0.1-1.3-0.4v8.4c0.2-0.5,0.4-1,0.6-1.6c1.2-2.9,3-7.1,5.2-12.2c4.5-10.2,10.9-24.4,18.4-39.7
			c6-12.5,11.7-24,16.8-33c3.6-6.4,7.6-12.6,12-18.6c0.3-0.4,0.6-0.8,1.1-1.4c-0.6,0.6-1.3,1.3-2,2c-2.2,2.2-4.6,4.8-7.2,7.8
			c-2.9,3.4-5.6,6.9-8.1,10.5l0,0c0,0,0,0,0,0l-1.7-1c0,0,0,0,0-0.1l0,0c0.4-0.7,3-4.9,5.9-9.2c3.8-5.8,7.6-11.3,11.3-16.3
			c3.3-4.7,7-9.1,10.9-13.3c3.6-3.8,6.8-6.9,9.6-9.5c1.4-1.3,2.7-2.4,3.8-3.3c0.6-0.5,1.1-0.9,1.6-1.2l0.4-0.3
			c0.2-0.1,0.3-0.2,0.5-0.2c0.3-0.1,0.7,0,0.9,0.2c0.8,0.7,1.6,1.4,2.4,2.1l3.4,3.3c1.4,1.3,3.1,3,5.1,4.8l1.6,1.5l0.9,0.8l0.5,0.4
			l0.4,0.3c0.3,0.3,0.7,0.5,1,0.7c0.2,0.1,0.3,0.2,0.5,0.3c0,0,0.1,0,0.1,0L97.9,506.8z M6.7,588.2c-2.6,3.1-4.5,4.8-4.7,4.6
			c-0.1-0.1,0.4-0.9,1.3-2.3c-0.4,0.5-0.7,0.7-0.8,0.6c-0.5-0.3,3.6-7.6,9.1-16.2c3.9-6.3,7.2-11.8,9-14.2c0.1-0.1,0.1-0.3,0.2-0.4
			c1.7-3.6,2.7-5.9,3-5.8c0.2,0.1-0.3,2.5-1.7,6.3c-1.8,4.9-3.9,9.7-6.4,14.4C13.1,579.9,10.1,584.2,6.7,588.2z M15.1,581.6
			c-0.3-0.2,0.7-2.1,2.3-5.2s3.7-7.4,6-12.2c4.9-9.5,9.4-16.9,9.9-16.6c0.5,0.3-3.2,8.1-8,17.5c-2,4.1-4.2,8.1-6.6,12
			C16.8,580.1,15.4,581.8,15.1,581.6z M26.6,570.1c-2.7,5.5-5.3,10.4-7.1,13.9s-3.1,5.7-3.3,5.6c-0.3-0.1,0.5-2.5,2-6.2
			c1.5-3.7,3.9-8.8,6.6-14.2c5.5-11,10.5-19.5,11-19.3C36.2,550.1,32.1,559.2,26.6,570.1z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M39.4,531.6c0.8-1.4,1.7-2.9,2.7-4.3c0.5-0.8,1-1.5,1.5-2.3l0.8-1.1c0.6-1.5,1.4-3.5,2.5-5.9
			c1.2-2.6,2.6-5.6,4.5-8.9l6.4-10.3c0.9-1.4,1.8-2.8,2.6-4.1c-0.1,0.2-0.3,0.4-0.4,0.5c-1.4,2-2.3,3.3-2.8,4.1
			c-0.3,0.4-0.6,0.9-1,1.6c-0.4,0.7-0.7,1.2-1.5,2.4l-0.6,0.8c-0.2,0.3-0.4,0.5-0.6,0.8c-0.3,0.4-0.7,0.6-1.1,0.8
			c-0.2,0-0.1-0.1-0.2-0.2s-0.1-0.2-0.1-0.2c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.4,0-0.8,0.1-1.3c0.1-0.5,0.1-0.9,0.2-1.5s0.1-0.8,0.1-1.2
			l0.1-1c0-0.7,0-1.3,0-2c0-1.4-0.2-2.9-0.6-4.3c-0.7-2.8-2.1-5.4-4-7.5c0.7,1.2,1.3,2.6,1.7,3.9c0.1,0.4,0.1,0.9,0.1,1.3v1.1v2v2.8
			c0.1,3.7-0.2,7.5-0.9,11.2c-0.6,2.9-1.4,5.7-2.6,8.4c-1.2,2.9-2.5,6-4,9.2c-1,2.2-2.1,4.3-3.1,6.3
			C39.2,531.9,39.3,531.7,39.4,531.6z"
            />
          </g>
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M74.1,436.4c0,0.5-0.1,1-0.2,1.3c0.2,0.3,0.4,0.6,0.6,0.9c0.2,0.3,0.4,0.7,0.6,1c0.1,0.2,0.2,0.4,0.3,0.6
		l0.3,0.9c0.2,0.7,0.3,1.4,0.3,2.1c0.1,0.7,0.1,1.4,0.2,2.1l0,0.4c0.5,0,1,0,1.6,0c1.3,0.1,2.6,0.4,3.8,1c1.5,0.7,2.8,1.9,3.6,3.3
		c0.1,0.1,0.2,0.3,0.3,0.5l0.1,0.2h0.2h0.9c0.6,0,1.1,0.1,1.6,0.1c0.6,0.1,1.2,0.2,1.8,0.3c-0.9-0.6-1.8-1.3-2.5-2.1
		c-2.8-2.7-4.4-5.4-4-5.8c0.4-0.4,2.7,1.7,5.4,4.3l3.4,3.3c0.8,0.8,1.3,1.3,1.3,1.6c0.2,0.1,0.5,0.2,0.7,0.4l0.4,0.3
		c0.3,0.2,0.5,0.4,0.8,0.6l0.4-0.2l0.8-0.2c0.7-0.2,1.4-0.4,2.1-0.5c2.1-0.4,4.2-0.3,6.2,0.3c0.8,0.2,1.5,0.6,2.2,1l0.2,0.1l0.1,0.1
		c0,0.1,0.1,0.1,0.1,0.2l0.2-0.1l0.7-0.4c0.4-0.2,0.9-0.4,1.3-0.6c0.7-0.3,1.4-0.5,2.2-0.6c0.9-0.1,1.8-0.2,2.7-0.2
		c0.3,0,0.6,0,0.9,0c0.3,0,0.6,0.1,0.9,0.1c0.3,0.1,0.7,0.2,1,0.3l0.2-0.2c0.1-0.1,0.3-0.3,0.5-0.4c0.5-0.3,1.1-0.6,1.7-0.9
		c1.7-0.7,3.5-1.1,5.3-0.9l1,0.1l0.5,0.1l0.1,0l0.5-0.3c0.3-0.2,0.6-0.3,0.9-0.5c0.6-0.3,1.2-0.5,1.8-0.7c1-0.3,2.1-0.4,3.2-0.4
		c0.3,0,0.6,0.1,0.9,0.1c-0.7-1.2-1.4-2.4-2.1-3.8c-0.9-1.7-1.7-3.5-2.4-5.3c0-0.1-0.1-0.2-0.1-0.2c-0.4,0-0.8-0.1-1.2-0.2
		c-2.3-0.5-4.4-1.4-6.2-2.8c-1.8-1.4-3.4-3.1-4.6-5c-0.9-1.5-1.2-2.6-1-2.7c0.5-0.3,2.9,3.1,6.9,6c1.7,1.3,3.5,2.3,5.4,3.1
		c0.1,0.1,0.2,0.1,0.3,0.1c-1.1-3.4-1.9-6.9-2.4-10.5c-0.2-1.9-0.4-3.7-0.6-5.6c-0.4,0.4-1,0.8-1.5,1.2c-1.8,1.4-3.4,2.4-3.9,2.1
		c-0.4-0.3,0.3-1.8,1.9-3.2c-0.1,0-0.2-0.1-0.4-0.1c-1-0.4-1.8-1.1-2.6-1.8c-1.7-1.7-2.6-3.5-2.2-3.9s2,0.8,3.6,2.4
		c0.8,0.8,1.5,1.6,2,2.1c0.2,0.2,0.3,0.4,0.4,0.6c0.9-0.6,1.7-1,2.4-1.3c0-0.8-0.1-1.6-0.1-2.4c-1-0.5-2.5-1.4-4.3-2.8
		c-1.9-1.5-3.6-3.2-5.1-5.1c-0.8-1.1-1.7-2.2-2.5-3.5c-0.4-0.6-0.8-1.3-1.2-2c-0.2-0.3-0.4-0.7-0.6-1l0,0c-0.7,0.5-1.9,1.1-3.2,1.7
		c-2.1,1.1-3.8,2.1-4.2,1.7c-0.2-0.2,0-0.8,0.5-1.5c0.7-0.9,1.7-1.6,2.7-2.1c1.1-0.6,2.3-0.9,3.1-1c-0.3-0.2-0.6-0.3-0.9-0.5
		c-0.6-0.4-1.2-0.8-1.7-1.2c-2.8-2.2-5.2-4.8-7.3-7.6c-1-1.3-1.8-2.6-2.6-3.8c-0.4-0.6-0.7-1.2-1-1.8l-0.5-0.9l-0.2-0.4
		c0,0-0.1-0.1-0.1-0.2l-0.2-0.1c-0.1-0.1-0.3-0.2-0.4-0.2c-0.3-0.2-0.5-0.3-0.7-0.5c-0.4-0.3-0.8-0.7-1.2-1
		c-0.7-0.7-1.2-1.3-1.7-1.8c-0.9-1-1.3-1.6-1.2-1.7c0.1-0.1,0.7,0.3,1.7,1.1c0.5,0.4,1.1,0.9,1.9,1.5c0.4,0.3,0.8,0.6,1.2,0.8
		c0.2,0.1,0.4,0.3,0.6,0.4l0.3,0.1l0.6,0.2l0.4,0.6l0.2,0.4l0.5,0.8c0.4,0.5,0.7,1.1,1.1,1.7c0.8,1.1,1.7,2.3,2.6,3.5
		c2,2.6,4.4,5,7,7.1c0.5,0.4,1.1,0.8,1.6,1.1l1.9,1.2l0.4,0.8l0.3,0.5l0.5,1c0.4,0.7,0.7,1.3,1.1,1.9c0.8,1.2,1.5,2.3,2.3,3.4
		c1.4,1.9,2.9,3.6,4.5,5.2c1.3,1.3,2.5,2.3,3.3,2.9c0-0.2,0-0.3,0-0.5c-0.1-3.6,0-7.1,0.3-10.5c0.1-0.8,0.1-1.6,0.2-2.4
		c-0.6-0.3-1.3-0.8-2.2-1.5c-2.3-1.9-4.3-4.2-5.8-6.8c-0.4-0.8-0.8-1.5-1.2-2.3c-0.2-0.4-0.3-0.8-0.4-1.2c-0.1-0.2-0.1-0.5-0.2-0.7
		c-0.1-0.3,0-0.7,0.1-1c0.1-0.2,0.2-0.3,0.3-0.5c0,0,0,0,0,0c-0.2,0-0.4-0.1-0.6-0.1c-1-0.3-1.9-0.7-2.8-1.2c-2.9-1.8-5.3-4.2-7-7.1
		c-1-1.6-1.8-3.3-2.3-5c-0.1-0.4-0.2-0.8-0.3-1.3c0-0.2-0.1-0.4-0.1-0.6c0-0.2,0-0.5,0-0.8c0-0.3,0.1-0.6,0.3-0.8
		c0.1-0.2,0.3-0.4,0.4-0.5c0.3-0.3,0.5-0.5,0.8-0.7c2.1-1.5,3.6-2,3.8-1.8c0.1,0.2-1.1,1.2-2.8,2.9c-0.2,0.2-0.4,0.4-0.5,0.6
		c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0.1,0,0.1l0,0c0,0,0,0.2,0.1,0.3c0.1,0.1,0.1,0.3,0.1,0.5c0.1,0.3,0.2,0.7,0.3,1
		c0.6,1.6,1.3,3.1,2.2,4.6c1.6,2.7,3.7,5,6.1,7c0.9,0.7,1.7,1.3,2.3,1.7c0.3,0.2,0.5,0.4,0.6,0.6c0.1-0.1,0.2-0.2,0.3-0.2
		c0.1-0.1,0.6-0.5,0.8-0.7c0.5-0.4,1-0.8,1.5-1.1c1.9-1.3,3.1-1.9,3.3-1.7c0.2,0.2-0.8,1.3-2.4,2.8c-0.4,0.4-0.8,0.8-1.3,1.3
		c-0.2,0.2-0.5,0.5-0.7,0.7l-0.3,0.4l-0.1,0.2c0,0.1,0.1,0.3,0.1,0.4c0.1,0.3,0.2,0.7,0.4,1c0.3,0.7,0.7,1.4,1.1,2.1
		c2.3,3.9,5,6.7,6.4,8.3c0.5-4.7,1.4-9.4,2.7-14.1c1.4-5.8,2.5-10.3,3.3-12.9c-0.1-0.1-0.3-0.2-0.5-0.4c-0.6-0.6-1.2-1.4-1.6-2.2
		c-0.1-0.2-0.2-0.5-0.3-0.8c-0.1-0.2-0.2-0.5-0.3-0.8c-0.1-0.4-0.2-0.8-0.2-1.2c0-1,0.2-2,0.7-2.8c0.4-0.7,0.9-1.4,1.4-2
		c0.7-0.7,1.5-1.3,2.3-1.8c-0.4-1.1-0.8-3.3-1.3-6.5c-0.3-2.2-0.7-4.9-0.9-7.9c-0.4-3.3-0.3-6.7,0.1-10c0.5-3.4,1.5-6.6,3-9.7
		c1.2-2.5,2.8-4.7,4.7-6.7c-0.2-0.1-0.3-0.2-0.5-0.4c0,0-0.1-0.1-0.1-0.1c-0.2,0.4-0.7,1-1.7,1.6c-0.8,0.4-1.7,0.7-2.6,0.8
		c-0.5,0.1-1.1,0.1-1.6,0.2c-0.4,0-1,0-1.1,0l-0.2,0.3c-1.4,1.4-3.2,2.2-5.2,2.3c-1.5,0.1-2.7,0.1-3.2,0.1l-0.6,0h-0.6h-0.1
		c0.5,0.8,1,2.4,1.8,4.3c1,2.6,2,4.8,1.5,5.1c-0.4,0.3-2.4-1.4-3.5-4.4c-0.6-1.6-0.9-3.2-0.8-4.2c-0.3,0.3-0.6,0.7-0.9,1
		c-1.1,0.9-2.3,1.7-3.7,2.1c-1.3,0.5-2.7,0.7-4.1,0.8c-0.8,0.1-1.5,0-2.3-0.1l-0.2,0.2l-0.2,0.3c-0.1,0.1-0.2,0.3-0.4,0.4l-0.4,0.4
		c-0.7,0.8-1.5,1.6-2.3,2.3c-1.5,1.1-3.2,1.8-5,2.1l-0.8,0.1h-0.2l-0.1,0.2c-0.2,0.2-0.3,0.5-0.5,0.7c-0.3,0.4-0.7,0.7-1.1,1.1
		c-0.7,0.5-1.4,0.9-2.2,1.2c-0.5,0.2-0.9,0.3-1.3,0.3c0,0.1,0.1,0.1,0.1,0.2c0.1,0.3,0.3,0.6,0.3,0.9c0.1,0.6,0.1,1.2,0,1.8
		c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.3-0.3,0.4c-0.2,0.2-0.5,0.4-0.8,0.4c-0.4,0-0.8-0.1-1.2-0.3c0.5,0.7,0.9,1.5,1.2,2.3
		c0.4,0.8,0.5,1.7,0.5,2.5c0,0.5-0.3,1-0.6,1.4c-0.3,0.3-0.8,0.5-1.2,0.6c-0.6,0.1-1.3,0-1.9-0.3c-0.3-0.1-0.6-0.3-0.8-0.5
		c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.3-0.4,0.5-0.7,0.6c-0.1,0.1-0.3,0.1-0.4,0.2l-0.3,0.1c-0.7,0.2-1.5,0.1-2.2-0.2
		c-0.7-0.3-1.3-0.7-1.8-1.1c-0.1,0.4-0.4,0.9-0.9,1.6c-0.5,0.7-1,1.3-1.5,1.9c-0.4,0.4-0.7,0.7-1.1,1c-0.2,0.2-0.4,0.3-0.7,0.5
		l-0.3,0.2l0,0c0,0,0,0,0,0.1c0,0.2-0.1,0.4-0.1,0.7c-0.1,0.4-0.2,0.9-0.4,1.3c-0.3,0.9-0.7,1.8-1.3,2.6c-0.4,0.6-0.9,1.1-1.5,1.6
		c-0.1,0.1-0.2,0.2-0.3,0.2l-0.1,0l-0.2,0c0,0,0,0.1-0.1,0.4c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.5-0.2,1.1-0.4,1.6
		c-0.3,0.9-0.7,1.7-1.2,2.5c-0.6,0.9-1.4,1.7-2.2,2.4l-0.3,0.3l-0.4,0.3l-0.4,0.3c0,0.1,0,0.2,0,0.5s0,0.6,0,0.9
		c0,0.8-0.1,1.7-0.3,2.5c-0.5,2.3-1.6,4.4-3.2,6.2l-0.6,0.5v0.5c0,0.3,0,0.7,0,1c0,0.6-0.1,1.3-0.1,1.8c-0.3,2.4-1,4.7-2.2,6.8
		c-0.1,0.2-0.2,0.5-0.4,0.7c-0.1,0.2-0.2,0.4-0.4,0.6l-0.3,0.4c-0.1,0.1-0.1,0.2-0.2,0.3l0.2,0.3c0.1,0.2,0.2,0.5,0.3,0.7
		c0.3,0.7,0.5,1.4,0.7,2.1c0.2,1,0.4,1.9,0.4,2.9c0,1.1-0.1,2.3-0.4,3.4c-0.2,0.8-0.5,1.5-0.8,2.2c-0.2,0.3-0.3,0.7-0.5,1l-0.1,0.2
		l-0.1,0.2l0.1,0.1l0.1,0.2c0.1,0.3,0.2,0.6,0.3,0.9c0.2,0.5,0.3,1.1,0.4,1.6c0.2,0.9,0.2,1.9,0.1,2.8c-0.1,0.4-0.1,0.8-0.2,1.1
		c1.2,0.1,2.4,0.4,3.5,1c0.3,0.2,0.7,0.4,1,0.6l0.5,0.3c0.1,0.1,0.2,0.1,0.3,0.2l0.4,0.3l0.7,0.7l0.6,0.7c0.4,0.4,0.7,0.8,1,1.3
		l1.7,2.5l0.1,0.2l0.1,0.1l0.1,0.2l0.3,0.5l0.6,1l-1.9-1.3l-1.2-0.8c-0.6-0.4-1.3-0.8-2-1.1l-0.4-0.1c1.1,0.7,1.9,1.7,2.5,2.8
		c0,0.1,0.1,0.1,0.1,0.2l0.1,0.3c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.4,0.2,0.8,0.2,1.2c0.1,0.5,0,1.1-0.2,1.6l-1.4-0.8
		c-0.2-0.1-0.3-0.2-0.5-0.2c0.1,0.2,0.2,0.5,0.3,0.7c0.2,0.6,0.3,1.1,0.3,1.6c0.8,0.7,1.5,1.5,2,2.5
		C73.8,432.8,74.2,434.6,74.1,436.4z M121.5,384.9c-0.1,0.2-0.8,0.2-1.6-0.2c-1.1-0.6-2-1.3-2.8-2.3c-1.9-2.1-2.7-4.2-2.2-4.5
		s2,1.2,3.8,3.1c0.9,1,1.6,1.8,2.2,2.5C121.3,384.1,121.6,384.6,121.5,384.9z M126.9,344c0.6,0,1.2,4.1,2.6,9
		c0.6,2.1,1.4,4.1,2.3,6.1c0.2,0.4,0.3,0.7,0.5,1c0.1,0.3,0.3,0.5,0.4,0.8c0.2,0.5,0.2,0.8,0.1,0.9c-0.1,0.1-0.4,0-0.8-0.3
		c-0.2-0.2-0.5-0.4-0.7-0.6c-0.2-0.3-0.5-0.6-0.8-0.9c-1.4-1.9-2.4-4-3-6.2C126,348.4,126.3,344,126.9,344z M127.2,361.9
		c0.2,0.2-0.5,1.2-1.7,2.7c-0.3,0.4-0.6,0.8-0.9,1.3c-0.1,0.2-0.2,0.4-0.3,0.7c0,0.1-0.1,0.2,0,0.2v0.2c0.3,1.3,0.7,2.6,1.1,3.8
		c0.9,2.3,2,4.5,3.3,6.6c1,1.7,1.8,2.6,1.6,2.8c-0.2,0.2-1.3-0.4-2.7-1.9c-1.8-2-3.2-4.3-4.1-6.8c-0.5-1.4-0.9-2.8-1.1-4.3
		c0-0.2,0-0.5,0-0.7c0-0.1,0.1-0.3,0.1-0.4l0.1-0.3c0.2-0.3,0.4-0.6,0.6-0.9c0.4-0.4,0.7-0.9,1.2-1.2
		C125.9,362.3,127,361.6,127.2,361.9z M111.7,335c0.2,0,0.7,0.9,1.6,2.6c0.4,0.8,0.9,1.8,1.5,2.9c0.3,0.5,0.7,1.1,1,1.6
		c0.2,0.3,0.4,0.5,0.6,0.8c0.1,0.1,0.2,0.3,0.3,0.4l0.7,0.7v0.6v1c0,0.4,0,0.8,0,1.2c0,0.8,0.1,1.6,0.2,2.5c0.4,3.2,1.2,6.4,2.5,9.4
		c0.3-0.7,0.8-1.7,1.6-2.6c1.4-1.7,2.9-2.6,3.3-2.2c0.4,0.4-0.4,2-1.8,3.6c-1.1,1.2-2.1,2.1-2.8,2.3c1.1,2.4,2,3.8,1.8,4
		c-0.2,0.2-1.6-1-3.1-3.5c-2-3.3-3.2-6.9-3.6-10.7c-0.1-0.9-0.2-1.8-0.2-2.6c0-0.4,0-0.8,0-1.3v-0.6c0-0.1,0-0.1,0-0.1l-0.1-0.1
		c-0.1-0.2-0.3-0.3-0.4-0.5c-0.2-0.3-0.5-0.7-0.7-1c-0.4-0.6-0.7-1.2-1-1.9c-0.5-1-0.9-2.1-1.2-3.2
		C111.6,336.2,111.5,335.1,111.7,335z M104.6,353.1c0.5-0.2,2.7,4,5.7,9c1.5,2.5,2.9,4.7,4,6.3c0.5,0.8,0.9,1.4,1.2,1.9
		c0.3,0.5,0.3,0.7,0.2,0.9c-0.1,0.1-0.4,0-0.9-0.3c-0.6-0.5-1.2-1-1.8-1.5c-1.8-1.9-3.3-3.9-4.6-6.1
		C105.5,358,104.1,353.3,104.6,353.1z M91.9,431.1c-0.1,0.3-0.7,0.2-1.5-0.1c-1-0.5-1.9-1.2-2.6-2c-1.8-1.9-2.6-3.8-2.1-4.1
		c0.5-0.3,2,1,3.6,2.7c0.8,0.9,1.5,1.6,2,2.3C91.7,430.4,92,430.9,91.9,431.1z M101.9,450.3c-0.3,0.5-2.3-0.2-4.2-1.8
		c-1.9-1.6-3-3.3-2.6-3.7c0.4-0.4,2.1,0.6,3.9,2.1C100.9,448.3,102.2,449.8,101.9,450.3z M112.9,432.8c0.2,0.2-1,1.3-2.9,2.9
		c-0.2,0.2-0.4,0.4-0.7,0.6c-0.2,0.2-0.2,0.2-0.3,0.4c-0.1,0-0.1,0.1-0.1,0.2v0c0,0.1,0,0.1,0,0.2c0.2,0.3,0.4,0.5,0.7,0.8
		c1.2,1.3,2.5,2.4,3.9,3.4c2.5,1.9,5.3,3.3,8.3,4.3c2.3,0.7,3.9,0.8,3.9,1.2c0,0.3-1.6,0.6-4.2,0.3c-3.4-0.6-6.5-2-9.2-4
		c-1.5-1.1-2.9-2.4-4.1-3.9c-0.3-0.3-0.5-0.7-0.8-1.1c-0.2-0.3-0.3-0.6-0.3-1c0-0.3,0.1-0.7,0.3-0.9c0.1-0.1,0.2-0.3,0.3-0.4
		c0.1-0.1,0.2-0.2,0.2-0.2c0.1-0.1,0.3-0.3,0.4-0.4c0.1-0.1,0.6-0.4,0.8-0.6C111.3,433.1,112.7,432.5,112.9,432.8z M103.7,413.3
		c0.6,0,1.2,2.9,3.1,5.8c1.9,3,4.2,4.9,3.8,5.4c-0.1,0.2-1,0-2.1-0.7c-1.5-0.9-2.7-2.2-3.6-3.6c-0.9-1.5-1.5-3.1-1.7-4.8
		C103.2,414.1,103.5,413.3,103.7,413.3z M94.2,402.7c1.4,1.9,3.1,3.6,4.9,5.2c0.7,0.6,1.3,1.1,1.7,1.4c0.4,0.4,0.6,0.6,0.5,0.7
		c-0.1,0.1-0.4,0.1-0.9,0c-0.8-0.2-1.5-0.5-2.2-0.9c-2.2-1.3-4.2-3-5.8-5.1c-1.5-2-2.7-4.3-3.6-6.8c-0.6-1.9-0.7-3-0.5-3.1
		C89.2,393.8,90.9,398.2,94.2,402.7z M97.7,414.2c-0.5,0.7-1.1,1.4-1.8,2c-0.5,0.5-0.9,0.8-1.2,0.7c-0.3-0.1-0.3-0.7-0.1-1.4
		c0.3-0.9,0.8-1.8,1.3-2.5c1.3-1.8,2.8-2.8,3.3-2.4S98.9,412.5,97.7,414.2z M98.2,378c-2.2,1.6-4.3,2.7-4.7,2.2s1.1-2.2,3.5-3.9
		c1-0.7,2.1-1.3,3.2-1.8c0.9-0.3,1.6-0.3,1.7-0.1C102.1,375,100.4,376.4,98.2,378z M91.4,355.8c0.5-0.2,2.7,3.5,5.6,8
		c0.9,1.4,1.8,2.7,2.6,3.9c0-0.1,0-0.1,0.1-0.2c0.4-0.8,0.9-1.5,1.5-2.1c1.4-1.4,2.9-2.1,3.3-1.6c0.4,0.4-0.4,1.8-1.8,3.1
		c-0.6,0.6-1.2,1.1-1.8,1.5c-0.2,0.1-0.3,0.2-0.5,0.3c0.2,0.2,0.3,0.5,0.5,0.7c1,1.4,1.6,2.4,1.4,2.6c-0.2,0.2-1.2-0.4-2.5-1.6
		c-1.7-1.7-3.1-3.5-4.4-5.5C92.2,360.3,90.8,356,91.4,355.8z M80.6,359.9c0.6-0.1,2.1,4.3,5,9.3s6.1,8.6,5.6,9
		c-0.2,0.2-1.3-0.5-2.7-1.8c-1.8-1.8-3.4-3.9-4.7-6.1C80.8,365,80,360,80.6,359.9z M86.9,392.4c-0.1,0.2-0.7,0.3-1.4-0.1
		c-0.9-0.5-1.7-1.1-2.4-1.9c-1.5-1.8-2.1-3.6-1.6-3.9c0.5-0.3,1.8,1,3.2,2.6c0.7,0.8,1.3,1.5,1.7,2.1
		C86.8,391.7,87,392.1,86.9,392.4z M79.7,402v-0.4c0-0.2,0.1-0.4,0.1-0.5c0.2-0.5,0.5-1,0.8-1.4c0.5-0.6,1-1.2,1.6-1.8
		c0.9-0.9,1.5-1.3,1.7-1.2c0.1,0.1-0.3,0.7-1,1.7c-0.4,0.5-0.8,1.2-1.3,1.9c-0.2,0.4-0.4,0.7-0.5,1.2c0,0.1,0,0.1,0,0.2v0.4
		c0,0.3,0,0.5,0,0.8c0.2,2.5,1,5,2.3,7.2c1.6,2.6,4,4.7,6.8,6c0.7,0.3,1.4,0.6,2.2,0.8l3,0.9l-0.1,1.1c0,0.2,0,0.4,0,0.5v0.3
		c0,0.3,0,0.7,0,1c0,0.6,0.1,1.3,0.2,1.9c0.2,1.2,0.5,2.4,1,3.5c0.8,1.9,2,3.5,3.6,4.8c0.6,0.5,1.3,0.9,2,1.3c0.5,0.3,1,0.6,1.6,0.9
		c0.9,0.5,1.4,0.8,1.3,0.9c0,0.1-0.6,0.1-1.6-0.2c-0.6-0.1-1.2-0.3-1.8-0.6c-0.8-0.3-1.5-0.7-2.2-1.2c-1.9-1.3-3.4-3.1-4.4-5.1
		c-0.6-1.2-1-2.5-1.3-3.9c-0.1-0.7-0.2-1.4-0.3-2.2c0-0.4,0-0.7,0-1.1v-0.3l-0.3-0.1l-1.2-0.3c-0.8-0.3-1.6-0.6-2.4-0.9
		c-3.2-1.4-5.8-3.9-7.6-6.9c-1.3-2.5-2.1-5.3-2.1-8.1C79.7,402.5,79.7,402.3,79.7,402z M80.9,419.7c0.2,0.2-0.9,1.3-2.5,3
		c-0.1,0.1-0.2,0.2-0.3,0.3l-0.1,0.2c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2c0.3,0.5,0.7,1.2,1.2,1.8c0.9,1.3,2,2.7,3.1,4.1
		c2.2,2.9,4.4,5.3,6,7.1c1.6,1.8,2.6,3,2.4,3.2c-0.2,0.2-1.5-0.5-3.4-2.1c-2.4-2.1-4.7-4.4-6.6-6.9c-1.1-1.4-2.1-2.8-3-4.3
		c-0.4-0.7-0.8-1.4-1.2-2.1c-0.1-0.3-0.2-0.6-0.2-0.9c0.1-0.3,0.2-0.5,0.4-0.8c0.1-0.1,0.2-0.2,0.3-0.3L77,422
		c0.1-0.1,0.3-0.2,0.4-0.3C79.4,420.1,80.7,419.5,80.9,419.7z M71.3,385c0.6-0.1,1.5,2.4,3.1,5.2c0.7,1.2,1.4,2.3,2.2,3.5
		c0.3,0.4,0.6,0.8,0.7,1.1c0.2,0.3,0.2,0.5,0.1,0.6c-0.1,0.1-0.3,0.1-0.6,0c-0.5-0.1-0.9-0.4-1.3-0.7c-1.2-0.9-2.3-2.1-3-3.5
		C70.7,388.1,70.7,385.1,71.3,385z M73.7,418.1c0.3,0.6,0.6,1.1,0.7,1.5c0.1,0.4,0.2,0.6,0.1,0.7c-0.1,0.1-0.3,0-0.7-0.2
		c-0.5-0.3-0.9-0.7-1.3-1.1c-1.3-1.4-2.3-3.1-2.9-4.8c-1.6-4.2-1.3-7.8-0.7-7.8c0.5,0,1.1,2.8,2.3,6.3c0.3-0.6,0.7-1.2,1.4-1.8
		c0.6-0.6,1.3-1.1,2.1-1.4c0.6-0.2,1.1-0.2,1.3,0.1c0.1,0.3-0.1,0.6-0.4,1.1c-0.5,0.6-1,1.2-1.5,1.7c-0.8,0.8-1.6,1.5-2.2,1.7
		C72.4,415.3,73,416.7,73.7,418.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M425.2,427c0.6,0.6,1.2,1.1,1.9,1.6c0.4,0.3,0.6,0.4,0.7,0.5c0-0.3,1.2-0.9,2.7-2.2c0.4-0.3,0.8-0.7,1.1-1.1
		c0.4-0.4,0.6-0.9,0.8-1.4c0-0.1,0-0.1-0.1,0h-0.1l-0.6,0.2l-1.2,0.4l-4.4,1.4l-0.9,0.3H425c-0.1,0,0,0.1,0,0.1
		C425.1,426.9,425.1,427,425.2,427z"
          />
          <g>
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M288.6,293.4c1.7,0.1,2.8,0.2,3.5,0.4c0-0.2,0-0.4-0.1-0.6c-0.1-0.1-0.1-0.2-0.2-0.3l-0.3-0.3
			c-0.2-0.2-0.4-0.3-0.6-0.6c-0.3-0.3-0.6-0.5-0.9-0.8c-0.4-0.4-0.5-0.9-0.5-1.5c0.1-0.5,0.3-0.9,0.6-1.3c-0.5-0.8-1-1.6-1.4-2.5
			c-0.5,0.5-1.3,1.1-2.2,1.9c-0.9,0.8-2,1.7-3.2,2.7c-0.6,0.5-1.2,1.1-1.7,1.7c-0.5,0.5-0.6,0.6-0.6,0.6c-0.8,0,2,0.1,3.4,0.1
			C286,293.2,287.4,293.3,288.6,293.4z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M359,338.5c-2.9-0.7-6.1-1.5-9.3-2.5c-0.6-0.2-1.2-0.4-1.9-0.6l-0.1,1.4l-0.1,1.3c-0.1,1.7,0,3.4,0.1,5.1
			c0.1,1.7,0.4,3.4,0.8,5.1c0.2,0.8,0.4,1.6,0.7,2.5c0.1,0.4,0.3,0.8,0.5,1.2l0.2,0.6l0.2,0.4c0,0,0,0,0,0l0.6-0.4
			c0.8-0.5,1.5-0.9,2.3-1.4c1.5-1,3-2,4.4-3.1c1.4-1.1,2.8-2.3,4.1-3.5c0.6-0.6,1.3-1.3,1.8-2c0.3-0.3,0.6-0.7,0.8-1l0.7-0.9
			l0.3-0.5c-0.2-0.1-0.5-0.2-0.7-0.4C362.8,339.3,360.9,338.9,359,338.5z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M322.4,323.2c-0.6-0.1-1.2-0.4-1.7-0.8c-0.4-0.3-0.8-0.6-1.1-1c-0.6-0.6-1.2-1.3-1.8-1.7
			c-0.2-0.2-0.5-0.4-0.8-0.5c-0.3-0.1-0.6-0.2-0.9-0.2c-0.9-0.1-1.7-0.1-2.6,0c-0.3,0-0.5,0-0.8,0h-0.5c-0.2,0-0.4,0-0.5-0.1l0,0.1
			l-0.2,1.1c-0.3,1.4-0.5,2.9-0.7,4.4c-0.2,1.5-0.4,3-0.5,4.5c-0.1,0.8-0.1,1.5-0.2,2.3l-0.1,0.8c0,0.1,0,0.2,0,0.3l1.3-0.4l2.3-0.8
			c1.5-0.5,3-1.1,4.6-1.7c1.5-0.6,3.1-1.3,4.6-2c0.8-0.4,1.5-0.7,2.3-1.1c0.4-0.2,0.7-0.4,1.1-0.6l0.6-0.4l0.3-0.2
			c-1.2-0.8-2.2-1.6-2.8-2.2C323.6,323.2,323,323.3,322.4,323.2z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M307.3,314.3c-0.1-0.1-0.1-0.1-0.2-0.1c-0.3-0.1-0.7-0.2-1-0.3c-1-0.1-1.9-0.2-2.8-0.5
			c-0.6-0.2-1.2-0.4-1.7-0.8c-0.5-0.4-1-0.9-1.3-1.4c-0.4-0.5-0.6-1.2-0.7-1.8c-0.1-0.3-0.1-0.6-0.1-0.9l-0.2,0.3
			c-0.7,0.9-1.3,1.9-1.9,2.9c-0.6,1-1.2,2.1-1.6,3.2c-0.3,0.6-0.5,1.1-0.7,1.7l-0.2,0.4c0,0.1-0.1,0.1-0.1,0.2l-0.1,0.4
			c-0.1,0.1,0.1,0,0.1,0h0.3h0.6l2-0.1c1.3-0.1,2.7-0.2,4.1-0.3s2.8-0.3,4.2-0.5c0.7-0.1,1.4-0.2,2.1-0.3l0.5-0.1
			c-0.4-0.6-0.6-1.2-1-1.6C307.5,314.6,307.4,314.5,307.3,314.3z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M295.2,305.4c-0.5-0.1-1-0.3-1.4-0.5c-0.8-0.5-1.5-1.2-1.8-2c-0.3-0.7-0.5-1.5-0.5-2.4v-0.4
			c0-0.1,0-0.2,0-0.2c0-0.1-0.1-0.3-0.2-0.4l-0.1-0.1l-0.2-0.2c-0.2-0.1-0.3-0.3-0.4-0.4c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0-0.3,0-0.5c0,0,0,0,0,0c-0.9,0.9-2.3,2.3-3.9,4.1c-0.4,0.5-0.8,1-1.3,1.5l-0.3,0.4l-0.2,0.3c-0.1,0.1-0.2,0.2-0.2,0.3
			l-0.1,0.2c0,0,0,0.1,0,0.1h0.1c0.3,0.1,0.5,0.2,0.8,0.2s0.8,0.2,1.1,0.3c0.8,0.2,1.7,0.3,2.5,0.5c1.9,0.3,3.8,0.4,5.8,0.4
			c0.4,0,0.9,0,1.3,0C295.6,305.5,295.4,305.4,295.2,305.4z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M329.3,326.7c0,0.2,0,0.4,0,0.5v0.6l-0.1,1.2c-0.1,1.6-0.2,3.2-0.3,4.9c-0.1,1.6-0.1,3.3-0.2,4.9
			c0,0.8,0,1.6,0,2.5c0,0.7,0,1.6,0,1.8c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0l0.5-0.3l2.3-1.2c1.5-0.8,3.1-1.6,4.6-2.4
			c1.5-0.8,3-1.7,4.6-2.6c0.8-0.4,1.5-0.9,2.2-1.4l1.1-0.7l0.6-0.4c0,0,0,0,0.1,0c-5.1-1.9-9.9-4.3-14.6-7
			C329.8,327.1,329.6,326.9,329.3,326.7z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M430.4,340.8c-0.8,0.3-1.8,0.6-2.8,1l-3.4,1c-1.1,0.3-2.2,0.6-3.3,0.9c0.2,0.3,0.3,0.6,0.5,1l0.3,0.5l0.2,0.4
			l0.3,0.5c0.2,0.3,0.4,0.6,0.6,1c0.4,0.6,0.8,1.3,1.3,1.9c1.7,2.4,3.9,4.4,6.4,5.9l0.4,0.2c0.4-0.6,0.7-1.2,1-1.9
			c0.6-1.3,1.1-2.7,1.4-4.1c0.4-1.4,0.6-2.8,0.8-4.3c0.1-0.7,0.1-1.4,0.2-2.1v-1.4c0-0.6,0-1.2,0-1.8c-0.4,0.1-0.9,0.3-1.4,0.5
			L430.4,340.8z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M487.5,323c-0.1-0.4-0.2-0.8-0.3-1.3c-0.4-1.8-0.8-3.5-1.2-5c-0.6-2.3-1.4-4.5-2.2-6.6
			c-0.1-0.2-0.1-0.3-0.2-0.4c-0.3-0.2-0.6-0.5-1-0.8c-0.8,1.2-2.5,3.2-5,5.7c-0.8,0.8-1.6,1.5-2.4,2.3c0.1,0,0.1,0.1,0.2,0.1
			l0.6,0.5c0.4,0.4,0.8,0.7,1.2,1c0.4,0.3,1,0.8,1.5,1.1c1.9,1.3,4,2.4,6.2,3.3c0.5,0.2,1,0.4,1.5,0.5l0.5,0.2l0.5,0.1h0.1h0.1
			c0,0,0,0,0-0.1v-0.2L487.5,323z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M449.7,335.6c0-0.1,0-0.2,0-0.4l-0.1-0.5l-0.1-0.8c-1.6,0.7-3.2,1.4-4.9,2c-3.4,1.3-6.3,2.1-8.3,2.7l0.1,0.1
			c0.5,0.8,0.9,1.3,1.3,1.9l0.2,0.3l0.2,0.2l0.4,0.5c0.3,0.3,0.5,0.6,0.8,0.9c0.5,0.6,1.1,1.1,1.6,1.7c1.1,1.1,2.3,2,3.6,2.9
			c0.6,0.4,1.3,0.8,2,1.2c0.3,0.2,0.7,0.4,1,0.5l0.8,0.3c0,0,0.1,0,0.1,0l0.1-0.5c0.2-0.7,0.3-1.4,0.5-2.1c0.3-1.4,0.5-2.8,0.6-4.2
			c0.1-1.4,0.2-2.8,0.2-4.2c0-0.7,0-1.4-0.1-2V335.6z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M475.4,325.9c-0.1-1.3-0.3-2.5-0.5-3.7c-0.1-0.6-0.2-1.2-0.3-1.8l0-0.2c0-0.1-0.1-0.2-0.1-0.3l-0.2-0.7
			c-0.1-0.3-0.2-0.7-0.3-1c-2.8,2.5-5.8,4.9-8.9,7.1c0.1,0.1,0.2,0.2,0.3,0.3l1.3,1.1l0.9,0.7c0.6,0.5,1.2,1,1.8,1.4
			c1.2,0.9,2.4,1.8,3.7,2.6c0.6,0.4,1.2,0.8,1.9,1.1l0.6,0.3l0.4,0.2v-1l-0.1-2C475.6,328.5,475.5,327.2,475.4,325.9z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M463.7,330.5c-0.1-0.7-0.2-1.3-0.3-2l-0.1-0.5c-0.1-0.3-0.1-0.6-0.3-0.9l-0.1-0.5c-0.7,0.5-1.4,0.9-2,1.3
			c-2.8,1.8-5.8,3.4-8.8,4.9l1.1,1c1.1,1.1,2.3,2.1,3.5,3c1.2,0.9,2.4,1.8,3.6,2.6c0.6,0.4,1.3,0.8,1.9,1.1c0.3,0.2,0.6,0.3,1,0.5
			l0.4,0.2h0.1c0.1,0,0.2,0.1,0.2,0V341c0.1-0.7,0.1-1.4,0.2-2.1c0.1-1.4,0.1-2.8,0.1-4.2C464,333.2,463.9,331.9,463.7,330.5z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M405.5,345.6c0.1,0.5,0.2,1,0.3,1.5c0.1,0.4,0.2,0.9,0.4,1.3c0.3,0.8,0.6,1.7,0.9,2.5
			c0.7,1.6,1.6,3.1,2.6,4.5l0.8,1l0.9,1l0.9,0.9c0.2,0.2,0.6,0.5,0.7,0.6l0.2-0.3c0.4-0.6,0.7-1.3,1-1.9c1.3-2.5,2.3-5.2,3.1-7.9
			c0.4-1.5,0.7-3,0.8-4.5c-3.9,0.8-7.9,1.2-11.8,1.4C406,345.6,405.8,345.6,405.5,345.6z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M377.8,341.8c-1.3-0.3-3.7-0.6-6.7-1c-0.4,0.1-0.9,0.1-1.3,0.2c-0.6,0-1.2-0.1-1.7-0.2c0,0.1,0,0.2,0,0.2
			l0.1,1.3l0.1,0.7c0.2,1.7,0.4,3.5,0.7,5.2c0.3,1.5,0.6,3.1,1,4.6c1.7-0.3,3.5-0.2,5.2,0.4c0.7,0.2,1.3,0.5,1.9,0.8
			c0.4-0.4,0.9-0.7,1.3-1.1c1.3-1.2,2.6-2.5,3.7-3.8c0.6-0.7,1.1-1.4,1.7-2.1c0.1-0.2,0.3-0.4,0.4-0.5l0.5-0.7
			c0.4-0.5,0.8-1.2,1.1-1.9c-0.4-0.1-0.7-0.2-1.1-0.3C381.4,342.7,379.1,342.1,377.8,341.8z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M388,344.1c0,0.1,0,0.2,0,0.2v1c0,0.3,0,0.7,0,1v0.7c0,0.6,0.1,1.2,0.2,1.8c0.4,2.4,1.1,4.8,2.2,7
			c0.2,0.4,0.4,0.8,0.6,1.2c0.2,0.4,0.5,0.8,0.7,1.2c0.3,0.4,0.5,0.8,0.8,1.1l0.4,0.5l0-0.1l1-1c0.6-0.7,1.2-1.4,1.8-2.1
			c2.3-2.8,4.3-5.9,5.8-9.2l0.2-0.3l0.2-0.6l0.4-1c-4.8-0.1-9.5-0.6-14.2-1.5C388.2,344.1,388.1,344.1,388,344.1z"
            />
          </g>
          <g>
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M109.6,328.7c0.6,1.1,0.8,2.2,0.7,2.8c0.5-0.1,1.1-0.3,1.8-0.6c1.1-0.3,2.4-0.7,3.7-1.2
			c1.2-0.4,2.3-0.9,3.5-1.5c0.5-0.2,0.9-0.5,1.2-0.7c0.2-0.1,0.4-0.3,0.6-0.4c-0.4-0.4-0.9-1.2-1.2-2.3c-0.4-1.1-0.5-2.1-0.4-2.6
			c0,0,0,0,0,0H119h-0.3h-0.6h-0.8c-0.5,0-1.1,0-1.7,0.2s-1.3,0.3-1.9,0.5c-1.5,0.5-3,1.2-4.3,2.1c-0.5,0.3-1,0.7-1.5,1.1
			c-0.2,0.1-0.4,0.3-0.6,0.5c0,0-0.1,0.1-0.1,0.1C107.9,326.7,108.9,327.4,109.6,328.7z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M121.8,324.1c0.4,1.2,0.4,2.3,0.1,2.8l0.4,0h0.5c0.3,0,0.4,0,0.6,0c1-0.1,2-0.4,2.9-0.8
			c1.5-0.5,2.8-1.3,3.9-2.4l0.5-0.6c-0.3-0.5-0.5-1.1-0.7-1.9c-0.2-0.8-0.3-1.5-0.3-2l-0.5-0.1c-0.2,0-0.5-0.1-0.7-0.1
			c-0.4,0-0.9-0.1-1.3-0.1c-0.8,0-1.6,0-2.3,0.2c-1,0.1-2,0.5-2.9,1c-0.2,0.2-0.5,0.3-0.7,0.5l-0.6,0.5l-0.4,0.4
			c-0.1,0.1-0.2,0.2-0.3,0.3C120.8,322.1,121.4,323,121.8,324.1z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M137.4,318.4c0-1,0.2-2,0.4-3c0-0.1,0-0.2,0.1-0.3c-0.3,0.1-0.7,0.2-1.1,0.4c-2,0.8-3.8,1.9-5.4,3.3l-0.1,0.1
			c0.4,0.4,0.8,1.1,1,1.9c0.2,0.8,0.2,1.6,0.1,2.2c0.3,0,0.7,0,1,0.1c0.5,0,1,0,1.4,0c0.8,0,1.5-0.1,2.3-0.3
			c0.8-0.2,1.3-0.4,1.7-0.5c-0.3-0.3-0.5-0.7-0.7-1.1C137.5,320.2,137.4,319.3,137.4,318.4z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M100.1,336.2l0.5-0.1l0.7-0.1c1.6-0.3,3.2-0.7,4.7-1.3c0.9-0.3,1.8-0.9,2.6-1.5l0.3-0.4
			c0.1-0.1,0.2-0.3,0.3-0.4l0.4-0.6c-0.5-0.3-1.1-1.2-1.7-2.2c-0.7-1.1-1.1-2.2-1.1-2.7l-0.8,0c-1.1,0.2-2.1,0.6-3,1.1
			c-1.5,0.9-2.9,2.1-4,3.4c-0.2,0.2-0.4,0.5-0.6,0.7l-0.3,0.3c-0.1,0.1-0.1,0.2-0.2,0.2c0.4,0.4,0.8,0.9,1.2,1.6
			C99.6,335,99.9,335.7,100.1,336.2z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M79,355.3c0.1,0.1,0.2,0.2,0.3,0.3l0.2-0.1l0.2-0.1c0.2-0.1,0.4-0.2,0.6-0.4c0.4-0.3,0.8-0.5,1.1-0.8
			c0.7-0.5,1.3-1.1,1.8-1.5c0.3-0.2,0.5-0.5,0.8-0.6c-0.3,0.2-0.6,0.3-0.9,0.3c-0.2,0-0.4,0-0.6,0l-0.4-0.1
			c-0.8-0.2-1.5-0.4-2.2-0.8c-1.1-0.5-2.2-1.1-3.3-1.7c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2,0.2-0.4,0.4-0.7,0.8c-0.4,0.4-0.8,0.9-1.2,1.4
			c-0.2,0.3-0.4,0.6-0.5,0.9c-0.1,0.1-0.1,0.3-0.2,0.5c0.8,0,1.9,0.2,3,0.7C77.5,354.1,78.3,354.6,79,355.3z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M92.2,335.7c0.4,0.6,0.8,1.3,1.1,2c0.6,1.2,1.1,2.3,1.6,3.2c0.3-0.2,0.7-0.4,1.1-0.7c0.6-0.4,1.1-0.9,1.6-1.4
			c0.3-0.3,0.5-0.6,0.8-0.9l0.4-0.5l0.2-0.3c-0.5-0.3-1.1-0.8-1.5-1.6c-0.5-0.7-0.7-1.5-0.8-2l-0.2,0l-0.7,0.1
			c-0.5,0.1-0.9,0.2-1.3,0.3c-0.7,0.2-1.5,0.5-2.2,0.8c-0.3,0.1-0.5,0.2-0.7,0.3C91.8,335.2,92,335.5,92.2,335.7z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M74.6,364.8c0-0.3,0-0.6,0-0.8c0-0.1,0-0.4,0-0.7c0,0-0.1,0-0.1,0c-0.7-0.1-1.4-0.2-2.1-0.4
			c-0.9-0.2-1.7-0.5-2.3-0.9l-0.6,0.2c-0.2,0-0.3,0.1-0.4,0.2l-0.2,0.1c-0.3,0.1-0.5,0.2-0.8,0.4c-0.5,0.2-0.9,0.5-1.3,0.9
			c-0.7,0.6-1.3,1.3-1.7,2.1c-0.6,1-0.9,2.2-0.9,3.4c0,0.1,0,0.2,0.1,0.9c0.5-0.4,2-0.5,3.6-0.2c0.9,0.2,1.8,0.5,2.6,1
			c0.4,0.3,0.6,0.5,0.7,0.8l0.6-0.4l0.3-0.3c0.7-0.7,1.3-1.5,1.7-2.5c0.3-0.7,0.6-1.5,0.7-2.3C74.5,365.8,74.6,365.3,74.6,364.8z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M69.9,372.3c-0.8-0.1-1.6-0.2-2.4-0.3c-1.6-0.3-2.9-0.8-3.3-1.3c-0.2,0.1-0.4,0.2-0.5,0.4
			c-0.2,0.2-0.4,0.4-0.6,0.6c-0.5,0.6-1,1.3-1.3,2c-0.9,2-1.3,4.2-1,6.3c0,0.5,0.1,0.9,0.2,1.4l0,0.1c0.6-0.3,1.4-0.5,2.4-0.6
			c0.8,0,1.7,0.1,2.4,0.5c0,0,0.1,0.1,0.1,0.1l0.1-0.1l0.6-0.6c1.4-1.5,2.5-3.3,3.3-5.2c0.3-0.7,0.5-1.4,0.7-2.2
			c0.1-0.3,0.2-0.6,0.2-0.8c0-0.1,0-0.3,0-0.4C70.5,372.3,70.2,372.3,69.9,372.3z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M72.9,361c0.8,0.2,1.6,0.5,2.2,1.1c0.1,0.1,0.1,0.1,0.2,0.2l0.2-0.1c0.1-0.1,0.2-0.2,0.3-0.2
			c0.4-0.4,0.8-0.8,1.1-1.2c0.4-0.6,0.8-1.3,1.1-2c0.1-0.4,0.3-0.7,0.4-1.1l0.1-0.5c0-0.1,0.1-0.3,0.1-0.4c-0.7-0.1-1.6-0.5-2.7-0.9
			c-1.2-0.5-2.2-1-2.8-1.5l-0.1,0.1l-0.2,0.3c-0.2,0.2-0.3,0.4-0.5,0.6c-0.3,0.4-0.6,0.8-0.8,1.3c-0.5,0.9-0.8,1.9-0.9,3
			c0,0.2,0,0.4,0,0.6c0,0.2,0,0.5,0,0.7c0,0,0,0.1,0,0.1C71.1,360.7,72,360.7,72.9,361z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M133.3,450.8c-0.9,0.2-1.9,0.4-2.8,0.8c-0.5,0.2-1,0.4-1.5,0.7c-0.3,0.1-0.5,0.3-0.8,0.4l-0.3,0.2
			c0.3,0.5,0.6,1.2,0.7,2c0.1,0.9,0.1,1.7-0.1,2.3c1.5,0.1,3.1,0,4.6-0.4c0.6-0.2,1.2-0.4,1.7-0.8c0.3-0.2,0.5-0.4,0.7-0.5l0.8-0.9
			l1,0.2l0.4,0.1c-0.9-1.4-1.9-2.9-2.9-4.6C134.3,450.5,133.9,450.6,133.3,450.8z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M126.4,453.3l-0.5-0.1l-0.8-0.1c-1.5-0.1-3.1,0.1-4.6,0.4c-0.7,0.2-1.2,0.3-1.7,0.5l-0.4,0.1
			c0.5,0.5,0.9,1.3,1,2.4c0.2,1.5-0.1,2.6-0.5,3.1c0.3,0.1,0.7,0.1,1,0.1c0.7,0,1.4,0,2.2-0.1c0.9-0.1,1.8-0.3,2.6-0.6
			c0.5-0.2,0.9-0.4,1.3-0.7c0.2-0.1,0.4-0.3,0.6-0.4l0.3-0.3l0.3-0.2c-0.2-0.6-0.4-1.4-0.6-2.2C126.4,454.5,126.4,453.8,126.4,453.3
			z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M106.4,456.1L106.4,456.1c-0.1,0-0.1-0.1-0.2-0.1c-0.6-0.3-1.1-0.5-1.8-0.7c-1.8-0.5-3.6-0.8-5.5-0.8
			c-0.8,0-1.5,0-2.1,0h-0.8l-0.6,0.1c0,0.5-0.2,1.3-0.4,2.1c-0.3,1-0.6,1.8-1,2.2c0.1,0.1,0.2,0.2,0.3,0.3l0.3,0.3
			c0.2,0.1,0.4,0.3,0.5,0.4c0.2,0.1,0.4,0.2,0.6,0.3c1.2,0.5,2.5,0.8,3.9,0.7c1,0,2-0.2,3-0.4c0.5-0.1,1.1-0.3,1.7-0.4
			c0.3-0.1,0.6-0.2,0.9-0.3l0.6-0.2c-0.1-0.5-0.1-1.1,0-1.7C105.8,457.1,106.1,456.6,106.4,456.1z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M93.1,456c0.3-1.1,1-1.8,1.5-2.1c-0.1-0.1-0.2-0.1-0.2-0.2l-0.4-0.3c-1-0.5-2.1-0.7-3.2-0.8
			c-0.8-0.1-1.7-0.1-2.7-0.1l-1.5,0.1l-0.8,0.1l-1.5,0.1c0,0,0,0,0,0c-0.3,0.3-0.6,0.7-0.9,1c-0.4,0.5-0.8,0.9-1.1,1.2c0,0,0,0,0,0
			c0,0,0,0,0.1,0.1l0.4,0.3c0.3,0.2,0.5,0.4,0.8,0.6c0.6,0.4,1.2,0.7,1.8,1c1.6,0.7,3.4,1.2,5.1,1.4c0.8,0.1,1.5,0.1,2,0.1h0.7
			c0,0,0,0,0,0C92.9,458.1,92.8,457.1,93.1,456z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M117.2,454.3c-0.4-0.2-0.7-0.3-0.9-0.4c-0.3-0.1-0.5-0.1-0.8-0.2c-0.3-0.1-0.6-0.1-0.9-0.1
			c-0.8,0-1.7,0.2-2.4,0.5c-0.6,0.3-1.3,0.5-1.9,0.9c-0.3,0.2-0.6,0.4-1,0.7l-0.5,0.4c-0.3,0.2-0.6,0.4-0.9,0.7c0,0.4,0,0.8-0.1,1.3
			c-0.1,0.6-0.3,1.1-0.5,1.5l0.1,0.1l0.4,0.2c0.3,0.1,0.6,0.2,0.9,0.3c0.6,0.2,1.1,0.3,1.7,0.4c1,0.2,2.1,0.3,3.1,0.3
			c1.4,0,2.7-0.2,4-0.8l0.6-0.3c-0.4-0.5-0.5-1.6-0.7-2.9C117.3,455.8,117.1,454.9,117.2,454.3z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M181.4,433.9c-0.2-0.5-0.3-1-0.5-1.4l-0.2,0.8l-1.4,0.2c-0.3,0-0.5,0.1-0.8,0.2c-1.5,0.2-2.9,0.7-4.3,1.4
			c-0.9,1.2-1.7,2.5-2.4,3.9l-0.2,0.3l-0.3,0.5c-0.1,0.2-0.2,0.4-0.4,0.6l-1.3-0.2c-0.2,0-0.4-0.1-0.6-0.1c-0.3-0.1-0.7-0.1-1-0.1
			c-0.4-0.1-0.8-0.1-1.3,0c-0.5,0.2-1,0.6-1.4,1c-0.6,0.6-1.2,1.3-1.8,2c-0.2,0.2-0.3,0.4-0.5,0.6l-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2
			c0.2,0.2,0.4,0.4,0.5,0.7c0.4,0.9,0,1.8-0.5,1.8c-0.6,0-1.2-0.4-1.4-0.9c-0.1-0.2-0.2-0.5-0.2-0.8l-0.2,0l-0.6-0.1
			c-0.3-0.1-0.7-0.1-1-0.2c-0.2,0-0.3-0.1-0.4-0.1c-1.3,1.9-2.6,3.7-4.1,5.4c-0.4,0.5-0.9,1.2-1.6,2c1.1-0.2,2.9-0.6,4.8-1.4
			c1-0.5,2-1,3-1.7c0.4-0.2,0.7-0.5,1-0.8c0.3-0.2,0.6-0.6,0.7-0.7h0.3c1,0,3.2-1,5.4-2.5c0.8-0.5,1.6-1.1,2.3-1.7
			c0.3-0.3,0.6-0.5,0.8-0.7c0.1-0.1,0.1-0.1,0.2-0.2c-0.3-0.1-0.6-0.3-0.7-0.6c-0.2-0.5,0.1-0.8,0.3-1.4c0.2-0.3,0.5-0.4,0.8-0.2
			c0.3,0.1,0.6,0.4,0.8,0.7c0.2,0.3,0.1,0.7,0,1c0.2,0,0.3,0,0.4,0h0.4c1.8-0.7,3.4-1.8,4.7-3.3c0.2-0.2,0.5-0.5,0.7-0.8l0.3-0.4
			l0.3-0.4c0.2-0.2,0.3-0.5,0.5-0.8l0.2-0.4v-0.1l0.1-0.2l0.2-0.3l0.4-0.2l0.2-0.1l0.1,0C181.5,434.4,181.4,434.1,181.4,433.9z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M62.8,411.4c-0.1-0.5-0.3-0.9-0.5-1.4c-0.1-0.2-0.2-0.5-0.3-0.7l-0.2-0.4c-0.5,0.3-1.3,0.5-2.3,0.5
			c-0.8,0-1.6-0.2-2.3-0.5c-0.1,0-0.1-0.1-0.2-0.1l-0.1,0.1c-0.2,0.3-0.3,0.6-0.5,0.8c-0.3,0.5-0.5,1.1-0.7,1.7
			c-0.3,1.2-0.5,2.1-0.6,2.8c0.3,0.2,0.5,0.3,0.7,0.5c0.3,0.3,0.5,0.5,0.6,0.7c1.2-0.3,2.4-0.5,3.7-0.6c1.1-0.1,2.1-0.1,3.2-0.1
			c0-0.3,0-0.6-0.1-1C63.2,412.9,63,412.1,62.8,411.4z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M63.3,396.5c-0.1-0.3-0.1-0.5-0.2-0.7l-0.1-0.4c-0.2,0.1-0.3,0.1-0.6,0.2c-0.7,0.2-1.3,0.3-2,0.3
			c-0.7,0.1-1.4,0.1-2.1,0c-0.2,0-0.5-0.1-0.7-0.2l-0.2,0.2c-0.1,0.1-0.2,0.3-0.2,0.3l-0.2,0.3c-0.3,0.5-0.5,1-0.8,1.5
			c-0.6,1.4-0.8,3-0.7,4.5c0.2,1.6,0.6,3.1,1.4,4.5c0.1,0.2,0.2,0.3,0.2,0.5c0.5-0.1,1.3-0.1,2.2-0.2c0.8,0,1.6,0,2.1,0.1l0.4-0.7
			c0.2-0.3,0.3-0.6,0.4-0.9c0.3-0.6,0.5-1.2,0.7-1.9c0.5-1.8,0.6-3.6,0.5-5.5C63.5,397.8,63.4,397.2,63.3,396.5z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M65.5,383.5l-0.1-0.5c0-0.1,0-0.1,0-0.2c-0.7,0.1-1.4,0.1-2,0.2c-0.9,0-1.8,0-2.4-0.1
			c-0.2,0.1-0.5,0.3-0.7,0.5l-0.3,0.2l-0.2,0.1c-0.2,0.2-0.4,0.3-0.6,0.5c-0.4,0.4-0.7,0.7-1,1.2c-0.6,0.7-1,1.5-1.3,2.3
			c-0.4,1.1-0.6,2.2-0.5,3.4c0.1,0.4,0.2,0.8,0.3,1.3l0.4,1.1c0.2,0.4,0.3,0.6,0.6,1.3c0.1-0.1,0.3-0.2,0.4-0.3
			c0.7-0.4,1.4-0.6,2.2-0.6c0.8-0.1,1.6,0,2.3,0.3c0.3,0.1,0.6,0.3,0.7,0.5c0-0.1,0.1-0.1,0.1-0.2l0.3-0.4c0.2-0.2,0.3-0.4,0.4-0.6
			c0.2-0.2,0.3-0.4,0.4-0.6c0.5-1.2,0.8-2.4,0.9-3.6c0.1-1,0.2-2,0.1-3c0-0.5-0.1-1.1-0.1-1.7L65.5,383.5z"
            />
            <path
              onClick={() => props.onFill(4)}
              fill={props.fillColors[4]}
              class="st1"
              d="M83.5,451.4c0-0.1-0.1-0.2-0.2-0.3c-1.4-2.4-3.9-3.5-5.9-4c-0.5-0.1-0.9-0.2-1.3-0.3l0,0.1l-0.1-0.1
			c-0.7-0.2-1.3-0.3-1.6-0.4c0,0.5-0.1,1-0.2,1.2c0.3,0.5,0.6,1,0.8,1.6c0.3,0.8,0.5,1.6,0.5,2.4c0,0.4-0.1,0.8-0.2,1.2
			c-0.1,0.2-0.2,0.4-0.3,0.5c0.3,0.1,0.7,0.2,1.2,0.4c0.7,0.2,1.4,0.4,2.2,0.6c0.4,0.1,0.9,0.1,1.3,0.2c0.2,0,0.4,0,0.7,0h0.2h0.2
			c0,0,0,0,0-0.1c0.1-0.8,0.5-1.5,1-2C82.4,451.9,83,451.6,83.5,451.4z"
            />
          </g>
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M157.7,222.1c-0.1-0.1-0.3-0.1-0.5-0.2c-0.3-0.1-0.7-0.2-1-0.2c-0.3-0.1-0.7-0.2-1-0.2l-0.3,0
		c0.2,0.5,0.3,1.2,0.3,1.9c0,0.8-0.1,1.5-0.3,2c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0,0.3,0.1,0.4,0.1c0.3,0.1,0.7,0.1,1,0.2
		c0.3,0.1,0.6,0.1,1,0.1h0.1c-0.2-0.4-0.3-1-0.4-1.6c0-0.9,0.1-1.6,0.4-2.1L157.7,222.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M67.2,487.1l-0.2,0.5c-0.6,1.3-3.8,6.7-7.4,12.4c-2.3,3.6-4.6,7.1-6.5,10.2c-1.9,3-3.4,5.9-4.7,8.3
		c5.9-7.7,11-13.7,12.8-16c1.1-1.3,3-4.2,6-7.6c0.4-0.5,0.9-1,1.3-1.5c-0.3-0.6-0.6-1.9-0.9-3.6C67.4,488.8,67.3,488,67.2,487.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M407.6,370.9c5.8,1.4,11.4,3.1,17,5.2c10.2,3.7,19.7,9,28.3,15.6c4.3,3.4,7.1,6.4,8.5,8.1
		c1.7-2.6,3.8-5.6,6.2-8.7c-0.2-0.1-0.4-0.2-0.7-0.4c-1.5-0.8-3.4-2.2-6-3.7c-6.5-4.1-13.2-7.7-20.2-11c-14.3-6.9-27.1-10.5-29.7-12
		c-0.7,0.1-1.3,0.2-1.8,0.2c-1.4,0.2-3.5,0.6-6.3,1c-3.3,0.4-6.6,0.7-9.9,0.7c-3.2,0.1-6.8,0-10.6-0.1c-0.1,0.2-0.1,0.4-0.2,0.6
		c2.2,0.1,6.1,0.6,11.3,1.4C397.5,368.6,402.3,369.6,407.6,370.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M338.7,441c-0.4-0.2-0.8-0.4-1.2-0.6l-0.1,0c-0.1,0.2-0.3,0.3-0.5,0.4c-0.3,0.3-0.7,0.5-1.2,0.9
		c0.1,0.1,0.3,0.2,0.4,0.3c0,0,0,0,0.1,0c0.5-0.6,1.3-1,2.1-1C338.5,441,338.6,441,338.7,441z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M152,224.5c0.4,0.4,1,0.6,1.5,0.8c-0.2-0.5-0.4-1.1-0.4-1.8c0-1,0.3-1.9,0.6-2.3c-0.1-0.1-0.3-0.3-0.4-0.4
		c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.4-0.1-0.6-0.2c0,0,0,0,0,0c0.2,0.5,0.3,1.3,0.3,2.1C152.4,223.2,152.2,224,152,224.5
		L152,224.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M78,536.2c1,0.2,2,0.5,3,0.8l1.3,0.6l0.3,0.1l0.1,0.1l0.3,0.1l0.6-0.2l0.7-0.3l1-0.4l1-0.4
		c2.4-1.1,4.9-1.9,7.5-2.6c0.9-0.3,1.9-0.4,2.9-0.6l1.6-0.1l0.8-0.1h0.9c2.5,0,5,0.4,7.3,1.2c1.1,0.4,2.2,0.9,3.2,1.5
		c1.1,0.7,0.7,0.5,0.8,0.6h0.1l0.7-0.3c0.5-0.2,1-0.3,1.5-0.5c0.9-0.3,1.8-0.5,2.7-0.7c1.5-0.3,3.1-0.4,4.7-0.5
		c1.9,0,3.8,0.2,5.7,0.5c0.3,0,0.7,0.1,1.2,0.2c1.5,0.2,3,0.6,4.4,1.1c0.5,0.2,1.1,0.4,1.6,0.7l0.8,0.5l0.2,0.1h0.1l0.2,0.1l0.3-0.1
		c0.3-0.1,0.6-0.2,0.9-0.3c0.7-0.2,1.7-0.4,2.7-0.6c2.5-0.4,5.1-0.5,7.7-0.4c1.5,0.1,3,0.4,4.5,0.7c0.3-2.1,1.1-6,2.3-11.2l3.1-14.1
		c1.2-5.3,2.2-11.1,3-17.3c0.8-6.2,1.5-12.1,1.9-17.5c0.3-4.8,0.4-9.5,0.3-14.3c-0.2-5.8-0.5-10-0.6-12c-0.5,0.4-1.1,0.8-1.7,1
		c-1.5,0.7-3.2,1-4.9,0.8c-0.7-0.1-1.2-0.2-1.5-0.4c-0.2,0.2-0.4,0.5-0.6,0.7c0,0,0.1,0,0.1,0c0.6,7.5,0.7,15.1,0.4,22.6
		c-0.3,5.8-0.8,9.3-1.1,9.3s-0.3-3.6-0.4-9.3c-0.1-5.3-0.3-12.6-0.9-20.6c-0.6,0.5-1.1,1.1-1.8,1.7c-0.7,0.7-1.5,1.4-2.4,2.1
		c-0.5,0.4-0.9,0.8-1.5,1.1c-0.8,0.6-1.5,1.1-2.3,1.5l-0.7-1l-0.9-1.3c-0.5-0.7-1-1.5-1.7-2.4c-0.2-0.3-0.4-0.7-0.7-1
		c-0.2,0-0.3,0-0.5,0h-0.5h-0.2c-0.1,0.2-0.2,0.3-0.3,0.5c-0.2,0.3-0.5,0.6-0.8,0.8c-0.6,0.5-1.3,1-2.1,1.3
		c-1.2,0.4-2.5,0.7-3.8,0.7c-0.1,6.6-0.5,13.2-1.3,19.8c-0.6,5.2-1.4,8.3-1.7,8.3s0-3.3,0.2-8.4c0.2-5,0.6-11.9,0.7-19.4l-0.1,0.1
		c-0.3,0.2-0.6,0.3-0.8,0.5c-1.5,0.8-3.2,1.1-4.9,1c-0.8-0.1-1.6-0.2-2.3-0.4c-0.4-0.1-0.9-0.3-1.3-0.5l-0.7,0.3
		c-1.3,0.7-2.7,1.3-4.1,1.6c-1.1,0.2-2.3,0.3-3.5,0.2c-0.7-0.1-1.3-0.2-2-0.3c-0.3-0.1-0.7-0.2-1-0.3l-0.5-0.2l-0.3-0.1h-0.1h-0.1
		l-0.5,0.2c-0.4,0.1-0.7,0.2-1,0.3c-0.6,0.1-1.3,0.2-1.9,0.3c-1.1,0.1-2.2,0.1-3.3-0.1c-0.5,9.3-0.5,17.7-0.5,23.9
		c0,6.2,0,10.1-0.3,10.1c-0.3,0-0.8-3.8-1.2-10.1c-0.4-8.1-0.4-16.3,0-24.4c-0.8-0.3-1.5-0.6-2.2-1l-0.6-0.3
		c-0.2-0.1-0.4-0.2-0.6-0.3l-0.4-0.3l-0.3-0.2c-0.1,0-0.2,0.1-0.3,0.1l-0.8,0.2c-0.7,0.2-1.4,0.3-2.1,0.3c-0.6,0-1.2,0-1.8,0
		c-0.2,3.8-0.4,7.3-0.5,9.9c-0.1,2.7-0.2,4.5-0.5,4.5c-0.3,0-0.8-1.7-1-4.4c-0.3-3.4-0.3-6.8,0-10.2c-0.8-0.2-1.5-0.4-2.2-0.7
		c-0.8-0.3-1.5-0.8-2.1-1.3c-0.3-0.2-0.6-0.5-0.9-0.8l-0.4-0.4l-0.1-0.1h-0.4c-0.3,0-0.6,0-0.9-0.1c-0.5-0.1-1-0.2-1.5-0.4
		c-0.8-0.3-1.5-0.6-2.2-1.1c-0.5-0.3-0.9-0.6-1.2-0.9c-0.3,0.2-0.6,0.3-0.9,0.3c-0.1,0-0.1,0-0.2,0c0,0.1,0,0.2,0,0.2
		c0,1.3-0.5,2.6-1.3,3.7c-0.2,0.2-0.3,0.3-0.5,0.5c-0.1,0.1-0.2,0.1-0.3,0.2c0.1,0.8,0,1.7-0.1,2.5c-0.1,0.8-0.4,1.6-0.8,2.3
		c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2,0.2-0.4,0.5-0.7,0.7c-0.2,0.1-0.4,0.2-0.6,0.4c0.3,1.8,0.3,7.3,0.3,13.8c0,4,0.1,7.5,0.1,10.2
		c0,1.3,0,2.4,0,3.1c1-1.1,2-2.2,3.2-3.4l2.9-3c0.5-0.5,1-1,1.5-1.6c0.2-0.3,0.5-0.5,0.7-0.8c0.2-0.3,0.5-0.6,0.8-0.9
		c1.3-1.2,2.7-2.1,4.4-2.8c0.7-0.3,1.5-0.5,2.2-0.7l1.1-0.2c0.4-0.1,0.6-0.1,1.1-0.2c2.2-0.4,4.4-1,5.9-1.5c1.5-0.4,2.4-0.7,2.8-0.7
		l-0.2,0.1l-0.3,0.2l-0.5,0.3l-1,0.7l-1.8,1.2c-1.1,0.8-2.1,1.5-2.8,2l-3.1,2.2c-1.5,1.1-2.4,1.7-3,2.2c-1.3,1.1-3.9,3.4-7.2,6.4
		c-1.6,1.5-3.5,3.2-5.4,5.1c-1.5,1.4-2.9,3-4.3,4.5c2.5-2.7,5.1-5.2,7.8-7.6c1.5-1.3,3.1-2.6,4.8-3.8c1-0.7,2.2-1,3.4-0.9
		c1.1,0.1,2.1,0.4,3.1,0.9c0.5,0.3,0.9,0.6,1.3,0.9c0.3,0.3,0.7,0.5,1,0.7c0.7,0.5,1.5,0.9,2.2,1.3l2.2,1.2c0.8,0.4,1.5,0.9,2.1,1.4
		c1.1,1.1,2,2.3,2.7,3.6c0.3,0.6,0.6,1.2,0.8,1.8c0.1,0.3,0.2,0.6,0.3,0.9s0.2,0.6,0.2,0.8c0.1,0.4,0.3,0.9,0.5,1.3
		c0,0.1,0.1,0.3,0.2,0.5c0.1,0.2,0.2,0.3,0.2,0.5c0.2,0.3,0.3,0.6,0.5,0.9c0.7,1.2,1.4,2.2,1.8,2.9c-0.1,0-0.2,0-0.3,0.1
		c0,0,0,0-0.1,0.1c0,0.1-0.1,0-0.1,0l-0.2-0.1c-0.6-0.2-1-0.4-1.4-0.7c-0.2-0.1-0.5-0.2-0.7-0.4l-0.7-0.5c-0.5-0.4-1-0.7-1.4-1.1
		l-0.3-0.3l-0.3-0.2l-0.5-0.4l-0.9-0.8l-1.6-1.5c-2-2-3.6-3.6-4.9-5l-3.3-3.4c-0.7-0.7-1.4-1.4-2.2-2c-0.1-0.1-0.3-0.2-0.5-0.1
		c-0.2,0.1-0.4,0.2-0.6,0.4c-0.5,0.4-1.1,0.8-1.5,1.2c-1.1,0.9-2.3,2.1-3.7,3.4c-1.6,1.6-3.4,3.4-5.3,5.5c0.8,1.8,2.4,8.3,4.2,16.2
		C76.3,527.6,77.8,534,78,536.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M408,494.4c1.4,0.1,2.3,0.2,2.8,0.4c-0.1-0.1-0.2-0.2-0.4-0.4c-0.6-0.4-1.4-0.6-2.1-0.6
		c-1.2-0.1-2.4-0.1-3.6,0c-0.7,0-1.3,0.1-2,0.3l2,0.1L408,494.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M373.9,356.2c-4.5-1.6-9.4,0.5-11.3,4.9c-0.5,1.2-0.7,2-1,2.1c0,0,0,0,0,0c0,0,0,0,0,0c0.3,0,0.4,0.9,1,2.1
		c1.9,4.4,6.9,6.5,11.3,4.9c1.4-0.5,2.7-1.4,3.6-2.6c2.1-2.5,2.1-6.2,0-8.7C376.6,357.6,375.4,356.7,373.9,356.2z M368.7,364.3
		c0.2,0.5,0.6,0.9,1.1,1.1c0.7,0.4,1.6,0.4,2.4,0c1.5-0.7,1.8-2.1,2.3-2.1c0.2,0,0.5,0.4,0.6,1.1c0,1.1-0.5,2.2-1.3,3
		c-1.4,1.3-3.3,1.7-5,1.1c-1.1-0.3-2-1-2.7-1.9c-1.5-2-1.5-4.7,0-6.7c0.7-0.9,1.6-1.5,2.7-1.9c1.8-0.6,3.7-0.1,5,1.1
		c0.9,0.8,1.3,1.9,1.3,3c-0.1,0.7-0.3,1.1-0.6,1.1c-0.5,0-0.9-1.4-2.3-2.1c-0.7-0.4-1.6-0.4-2.4,0
		C368.6,361.7,368.1,363.1,368.7,364.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M347.2,440.8c0,0.1-0.1,0.1-0.4,0.2c1.7,0.6,3.1,1.6,4.3,3c2.1,2.4,3.5,5.4,4.3,8.5c0.9,3.4,1,6.9,0.5,10.4
		c-0.3,2-0.8,4-1.5,6c-0.4,1.4-0.9,2.7-1.4,4c-0.5,1.1-1.1,2.2-1.8,3.1c-0.4,0.6-0.9,1.2-1.4,1.7c-0.3,0.3-0.6,0.6-0.9,0.8l-0.1,0.1
		h0.1h0.1c0.2-0.1,0.4-0.1,0.6-0.2c0.4-0.1,0.7-0.3,1.1-0.5c0.4-0.2,0.7-0.4,1-0.7c1.2-0.9,2.2-2,3.1-3.3c1.2-1.6,2.1-3.4,2.7-5.4
		c0.6-2,1.4-8.6-0.4-15.8c-1.1-4.4-3.3-8.1-5.6-9.9c-0.9-0.7-1.9-1.3-3-1.7c-0.8-0.2-1.3-0.4-1.3-0.5c-0.1,0-0.1,0-0.2,0
		C347.1,440.7,347.2,440.7,347.2,440.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M339.4,439.8c0,0.1-0.2,0.1-0.4,0.2c1.7,0.6,3.3,1.7,4.5,3.1c2.1,2.5,3.6,5.5,4.4,8.8
		c0.9,3.5,1.1,7.2,0.6,10.8c-0.3,2.1-0.8,4.2-1.5,6.2c-0.4,1.4-0.9,2.9-1.5,4.2c-0.5,1.1-1.2,2.2-1.9,3.2l-0.7,0.9
		c-0.2,0.3-0.5,0.6-0.8,0.9c-0.3,0.3-0.6,0.6-1,0.8l-0.1,0.1v0h0.1h0.1h0.1c0.2,0,0.4-0.1,0.6-0.2c0.4-0.1,0.8-0.3,1.1-0.6l0.5-0.3
		c0.2-0.1,0.3-0.3,0.5-0.4c1.2-0.9,2.3-2.1,3.2-3.4c1.2-1.7,2.1-3.6,2.7-5.5c0.7-2.1,1.5-8.9-0.4-16.4c-1.1-4.6-3.4-8.4-5.8-10.3
		c-0.9-0.7-2-1.3-3.1-1.7C339.9,440,339.4,439.9,339.4,439.8C339.4,439.7,339.4,439.7,339.4,439.8
		C339.4,439.7,339.4,439.7,339.4,439.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M337.6,479.4C337.6,479.4,337.6,479.4,337.6,479.4C337.6,479.4,337.6,479.4,337.6,479.4
		C337.6,479.4,337.6,479.4,337.6,479.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M331,440.9c0,0.1-0.2,0.1-0.4,0.2c1.7,0.6,3.3,1.7,4.4,3.1c2.1,2.5,3.6,5.5,4.4,8.7c0.9,3.5,1.1,7.2,0.6,10.8
		c-0.3,2.1-0.8,4.2-1.5,6.2c-0.4,1.4-0.9,2.9-1.5,4.2c-0.5,1.1-1.1,2.2-1.9,3.3l-0.7,0.9c-0.2,0.3-0.5,0.6-0.8,0.9
		c-0.3,0.3-0.6,0.6-1,0.8l-0.1,0.1c0.1,0,0.1,0,0.1,0h0.1h0.2c0.2,0,0.4-0.1,0.6-0.2c0.4-0.1,0.8-0.3,1.1-0.5l0.5-0.3
		c0.2-0.1,0.3-0.3,0.5-0.4c1.2-0.9,2.3-2.1,3.2-3.4c1.2-1.7,2.1-3.6,2.7-5.5c0.7-2.1,1.5-8.9-0.4-16.4c-1.2-4.6-3.4-8.4-5.8-10.3
		c-0.9-0.8-2-1.3-3.1-1.7C331.5,441.1,331,441,331,440.9C331,440.9,331,440.9,331,440.9C331,440.9,331,440.9,331,440.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M179.2,303.1l-0.1,0.8v0.2l0.2,0.1h0l0.1,0.1l0.3,0.3c0.4,0.3,0.7,0.7,1.1,1.1c0.8,0.7,1.7,1.4,2.6,2
		c0.5,0.3,1,0.7,1.5,1l1.6,0.9l1.7,0.7c0.6,0.3,1.2,0.4,1.8,0.6c2.3,0.6,4.7,0.9,7,1c0.2-0.6,0.6-1.3,1-1.8c0.8-1.1,2-1.9,3.3-2.3
		c2.2-0.7,4.5-0.2,6.2,1.3c0.4,0.4,0.8,0.8,1.1,1.3c0.4-0.1,0.8-0.2,1.2-0.4c0.9-0.4,1.8-0.8,2.7-1.4c1.3-0.8,2.5-1.7,3.5-2.8
		c2.8-2.9,4.6-6.7,5.3-10.7c0.1-0.6,0.2-1.1,0.2-1.7v-0.2l-0.1-0.1l-0.3-0.3l-0.7-0.6c-0.4-0.4-0.8-0.8-1.2-1.2l-1.1-1.2l-0.3-0.3
		c-0.1-0.1-0.2-0.2-0.2-0.2l-0.1-0.2c-0.1-0.2-0.3-0.5-0.6-1c-0.1,0-0.2,0.5-0.3,0.7c-0.1,0.1-0.1,0.3-0.2,0.5l-0.3,1
		c-0.5,1.3-0.8,2.3-1.1,3c-0.8,2.6-2.2,5-4.1,7.1c-3.3,3.4-7.7,5.6-12.4,6.1c-3,0.4-6.1,0.2-9.1-0.5c-2.4-0.6-4.7-1.8-6.7-3.3
		c-1.6-1.3-2.7-2.5-3.3-3.5c0,0,0,0.1,0,0.1c0,0.8,0,1.6,0,2.4C179.3,302.1,179.2,302.6,179.2,303.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M207.9,313c-0.5-0.8-1.2-1.5-2-2c-1.1-0.6-2.4-0.7-3.6-0.2c-1.8,0.8-2.7,2.9-1.9,4.7c0.4,0.9,1,1.5,1.9,1.9
		c1.2,0.5,2.5,0.4,3.6-0.2c0.8-0.5,1.5-1.2,2-2c0.4-0.6,0.6-1.1,0.8-1.1c0,0,0,0,0,0C208.5,314.1,208.3,313.6,207.9,313z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M106,292.1c0.5,0.1,1,0.3,1.4,0.7c0.6,0,1.3-0.1,1.9-0.2c2-0.5,4-1.3,5.8-2.3c3-1.7,5.7-3.9,8.1-6.3
		c0.4-0.4,0.9-0.9,1.2-1.3l0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.5-0.3-0.8-0.5l-0.3-0.1h-0.1
		c0,0-0.1,0-0.1-0.1l-0.1,0.1l-0.3,0.4c-0.2,0.3-0.4,0.6-0.6,0.8c-0.4,0.5-0.8,1-1.2,1.4c-0.7,0.7-1.4,1.4-2.2,2l-0.9,0.7l-0.8,0.5
		c-0.5,0.3-0.8,0.5-1.1,0.7c-1.8,1.2-3.7,2.2-5.8,2.9c-0.7,0.2-1.5,0.4-2.4,0.5c-0.9,0.2-1.8,0.2-2.7,0.3h-1.4l-1.5-0.2l-0.8-0.1
		l-0.7-0.2c-0.5-0.1-1-0.2-1.5-0.4c-2.3-0.8-4.4-1.9-6.4-3.3c-1.6-1.1-3.1-2.4-4.4-3.8c-2.2-2.4-3.2-4.2-3-4.4
		c0,0.2-0.5,0.1-1.2,0.4c-0.2,0.1-0.4,0.2-0.5,0.4c-0.1,0-0.1,0.1-0.1,0.1l-0.1,0.1c0,0-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1,0,0.2
		l0.2,0.1c0,0,0.4,0.3,0.6,0.6c2.9,2.8,6.2,5.3,9.8,7.2c1.6,0.9,3.3,1.6,5,2.3c0.8,0.3,1.6,0.6,2.4,0.7c0.8,0.2,1.5,0.4,2.2,0.5
		c0,0,0,0,0,0c0.2-0.1,0.3-0.1,0.5-0.2c0.8-0.2,1.2,0,1.2,0.1c0,0.1-0.3,0.3-0.8,0.6c-0.7,0.5-1.3,1.1-1.7,1.9
		c-0.3,0.5-0.5,1-0.6,1.6c-0.1,0.6-0.1,1.2,0,1.9c0.2,0.6,0.5,1.1,1,1.5c0.6,0.4,1.2,0.6,1.9,0.7c0.7,0,1.3-0.2,1.9-0.6
		c0.5-0.4,0.9-0.9,1-1.5c0.1-0.6,0.2-1.3,0.1-1.9c0-0.6-0.1-1.2-0.4-1.7c-0.3-0.8-0.8-1.4-1.5-1.8c-0.5-0.3-0.9-0.4-0.9-0.5
		C104.8,292.2,105.2,291.9,106,292.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M47.2,329.2C47.2,329.2,47.2,329.3,47.2,329.2L47.2,329.2c0.1,0.1,0.1,0.1,0.1,0c0.2-0.5,0.3-0.9,0.5-1.4
		c0.1-0.4,0.2-0.8,0.3-1.2c0.1-0.5,0.5-1,0.9-1.3c0.6-0.4,1.2-0.7,1.8-0.9c0.5-0.2,0.9-0.3,1.2-0.4c1.3-0.5,2.6-0.8,4-1.1
		c2.4-0.3,4.8,0,7,0.9c1.2,0.4,2.3,0.9,3.4,1.4c0.5,0.3,1,0.5,1.5,0.8l0.3,0.2c0,0,0.1-0.1,0.1-0.1l0.1-0.1l0.1-0.4
		c0.1-0.6,0.3-1,0.4-1.4c0.3-0.8,0.5-1.2,0.7-1.2c0,0,0,0,0,0c-0.4,0-1.4-0.5-3.1-1.1c-2.4-0.9-4.9-1.4-7.5-1.6
		c-4.8-0.5-9.1,0.4-10.3,1.1c-1.1,0.6-2.1,1.5-2.8,2.6c-0.5,0.7-0.7,1.6-0.6,2.4c0.2,1,0.8,1.8,1.5,2.5l0.2,0.2L47.2,329.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M117.3,228.2c1.1,0,3-0.1,5.7-0.4c1.3-0.1,2.9-0.3,4.5-0.5l2.6-0.4l1.4-0.3l0.7-0.1l0.4-0.1h0.1l-0.1-0.1
		c-0.1-0.1-0.2-0.2-0.3-0.3l-0.4-0.5l-0.6,0.2c-0.3,0.1-0.6,0.1-0.8,0.2c-1.1,0.2-2.1,0.3-3.2,0.3c-2,0.1-3.9,0-5.6,0
		c-2.4-0.1-4.4-0.2-5.8-0.2c0.1,0.7,0.1,1.5-0.1,2.2C116.4,228.1,116.9,228.2,117.3,228.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M98.5,226.2c3.5,0.5,6.8,0.9,9.7,1.2c0-0.1,0-0.2,0-0.3c0.1-0.6,0.3-0.9,0.4-0.8c0.2,0,0.2,0.3,0.4,0.8
		c0.2,0.6,0.5,1.1,1,1.6c0.6,0.6,1.4,0.8,2.2,0.5c1.4-0.5,2.1-2,1.6-3.3c-0.2-0.5-0.5-0.9-0.9-1.2c-0.7-0.5-1.5-0.5-2.2-0.2
		c-0.6,0.3-1,0.7-1.4,1.2c-0.3,0.4-0.5,0.7-0.6,0.6c-0.1,0-0.1-0.1-0.1-0.2c-0.3,0-0.7,0-1.1,0c-4,0-8-0.3-11.9-1
		c-3.5-0.6-6.7-1.3-9.5-2c-1.4-0.4-2.7-0.7-3.8-1.1l-1.6-0.5l-0.7-0.2l-0.3-0.1c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.2,0.3
		c-0.1,0.3-0.1,0.6-0.2,0.9c-0.1,0.4-0.1,0.7-0.2,0.8c0,0,0,0.1,0,0.1C79.2,222.9,87.8,224.8,98.5,226.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M1.4,624.2c0,0.1,0.2,0.3,0.6,0.5v-1.6C1.8,623.4,1.6,623.8,1.4,624.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M1.6,610.2c0,0.1,0.2,0.2,0.4,0.4v-1.1C1.9,609.7,1.7,609.9,1.6,610.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M442.4,722h-1.3C441.7,722.1,442.2,722.1,442.4,722z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M282,722.1c0,0,0.1-0.1,0.1-0.1h-0.4C281.8,722.1,281.9,722.2,282,722.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M1.8,638c0,0.1,0.1,0.2,0.2,0.3v-0.5C1.9,637.9,1.8,637.9,1.8,638z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M488.3,324.8l0.3,0h0.1c0,0,0-0.1,0-0.1l-0.1-0.5l-0.2-1c-0.1-0.5-0.2-1-0.3-1.4v2.9L488.3,324.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M488,328.8v0.3C488.1,329,488.1,328.9,488,328.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M1.5,148.3c-0.1,0.1,0.1,0.5,0.5,1.3v-0.8C1.7,148.5,1.5,148.3,1.5,148.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M488.3,107c0,0-0.1-0.1-0.3-0.1v0.2C488.2,107.1,488.3,107,488.3,107z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M489.5,263.5c-0.1-0.2-0.6-0.1-1.5,0.1v1.4C489,264.3,489.6,263.7,489.5,263.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M1.3,399.8c0,0.1,0.2,0.1,0.7,0.2v-0.5C1.6,399.7,1.3,399.7,1.3,399.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M1.3,587.3c0.1,0.1,0.3-0.1,0.7-0.5v-1.1C1.5,586.7,1.2,587.3,1.3,587.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M223.8,722h-1.5C222.9,722.3,223.6,722.4,223.8,722z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M488,370.3v0.4C488,370.4,488.1,370.3,488,370.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M1,636.2c0.1,0,0.4-0.5,1-1.6v-1.5C1.2,635,0.9,636.1,1,636.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M201.8,722.7c0.1-0.1,0.1-0.3,0.1-0.7H201C201.4,722.5,201.7,722.7,201.8,722.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M1.6,133c0,0.1,0.2,0.2,0.4,0.4v-0.7C1.7,132.7,1.6,132.9,1.6,133z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M488,435.3v0.1C488,435.4,488,435.4,488,435.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M1.7,100.3c0,0.1,0.1,0.1,0.3,0.2v-0.4C1.8,100.2,1.7,100.3,1.7,100.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M2,2v98.1c0.7-0.2,2.5-0.5,5.1-0.5c4.4,0.1,8.8,0.7,13,1.9c4.2,1.2,8.3,2.8,12.2,4.8c2.9,1.5,4.6,2.7,4.4,3
		c-0.1,0.3-2.1-0.5-5.1-1.6s-7.3-2.8-12.1-4.1c-4.1-1.1-8.2-2-12.5-2.5c-2.5-0.3-4.3-0.4-5-0.6v32.1c1-0.4,3.5-0.7,6.4-0.7
		c0.1,0,0.2,0,0.4,0c0,0,0,0,0,0l-0.3-5l2,4.5c0.1,0.2,0.2,0.4,0.3,0.6c1.6,0.1,2.9,0.3,3.7,0.5c-0.1-3.1-0.4-6.2-0.8-9.3
		c-0.3-2-0.6-4.1-1-6c0-0.1,0-0.2-0.1-0.2l0,0c0,0,0-0.1,0-0.1l2-0.6c1.4,4.5,2.5,9,3.2,13.7c0.1,0.8,0.2,1.6,0.4,2.4
		c0.8-0.3,2.1-0.5,3.6-0.5c1.5,0,2.9,0.2,3.6,0.5l0.7-1.7c2.3-5.6,4.9-11.1,7.8-16.4c0.7-1.1,1.3-2.2,2-3.3l1-1.6l1.1-1.5
		c0.3-0.5,0.7-1,1.1-1.5c0.4-0.5,0.8-1,1.1-1.4c0.4-0.4,0.8-0.9,1.2-1.4c0.4-0.5,0.9-0.9,1.5-1.3l4.1-3l-2.7,4.4
		c-1.5,2.5-2.9,5-4.2,7.5c0.5-0.1,1.1-0.1,2-0.1c2.8,0.1,5.5,0.6,8.1,1.5c3.3,1,7.1,2.5,11.4,3c2.3,0.2,4.6,0.2,6.8-0.1
		c2.3-0.3,4.8-1.2,7.7-1.3c1.4,0,2.8,0.2,4.1,0.7c1.2,0.5,2.4,1,3.6,1.6c2,1.2,4.1,2,6.3,2.6c2,0.2,4.1,0.1,6.1-0.4
		c1.9-0.3,3.8-0.4,5.7-0.3c2.8,0.1,5.5,0.8,8.1,1.8c0.8,0.4,1.5,0.7,1.9,1c0.3-1.2,0.6-2.6,0.9-4.1c0.8-4.2,1.3-8.4,1.4-12.6
		c-0.2,0.1-0.6,0.2-1,0.3c-1.3,0.1-2.6-0.1-3.7-0.7c-1.6-0.7-3.2-1.5-4.8-2.5c-0.9-0.5-1.8-0.9-2.7-1.2c-0.9-0.3-1.9-0.4-2.9-0.4
		c-1.6,0.1-3.4,0.9-5.4,0.7c-0.9-0.1-1.8-0.5-2.6-1c-0.7-0.4-1.3-0.9-1.8-1.5c-1-1-1.8-1.9-2.6-2.6c-0.5-0.5-1.1-1-1.8-1.4
		c-0.6-0.3-1.4-0.5-2.1-0.4c-1.1,0.2-2.2,0.6-3.2,1.2c-1.6,0.7-3.4,0.8-5.1,0.2c-1.8-0.7-3.4-1.9-4.7-3.3c-0.5-0.6-1-1.1-1.4-1.6
		c0,0,0-0.1,0-0.1h-0.1h-0.1l-0.3,0.2c-0.4,0.2-0.9,0.3-1.4,0.4c-0.8,0.1-1.6,0-2.4-0.1c-1.4-0.3-2.6-0.7-3.6-1
		c-2-0.6-3.5-1.1-4.3-1.3c-0.5-0.1-1.3-0.3-2.3-0.4c-1.2-0.2-2.5-0.4-3.7-0.8c-0.8-0.3-1.5-0.7-2.1-1.3c-0.6-0.7-1.1-1.5-1.4-2.4
		c-0.2-0.8-0.5-1.5-0.8-2.3c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.3-0.1-0.5,0c-0.8,0.5-1.6,1.2-2.3,1.9c-0.9,0.7-1.8,1.3-2.8,1.8
		c-1.7,0.9-3.5,1.4-5.3,1.6c-1.1,0.1-2.1,0.1-2.8,0.1c0.2,0.3,0.3,0.5,0.2,0.6C31.7,87,31,86.8,30,86.3c-0.6-0.3-1.2-0.7-1.7-1.1
		c-0.3-0.3-0.6-0.6-0.9-0.9l-0.8-0.9c-0.9-1-1.6-2-2.2-2.7c-0.1-0.2-0.3-0.3-0.4-0.4c-0.1-0.1-0.2-0.2-0.3-0.2
		c-0.2-0.1-0.4,0-0.5,0.1c-0.4,0.6-0.8,1.2-1.2,1.8c-0.4,0.5-0.8,1-1.3,1.4c-0.3,0.2-0.7,0.4-1.1,0.6c-0.2,0.1-0.4,0.2-0.6,0.2
		l-0.5,0.1c-1.3,0.2-2.5,0.2-3.5,0.3c-0.7,0-1.4,0.1-2,0.4c-0.5,0.5-0.9,1.1-1.3,1.7c-0.3,0.5-0.7,1-1.1,1.4
		c-0.3,0.3-0.6,0.5-0.9,0.7C9.3,88.9,8.9,89,8.4,89c-1.5,0.2-3,0.1-4.5-0.3c-1.1-0.3-1.7-0.6-1.7-0.9c0-0.3,0.7-0.5,1.8-0.7
		c1.1-0.1,2.6-0.2,4.1-0.3c0.2,0,0.4-0.1,0.7-0.1c0.2-0.1,0.4-0.2,0.6-0.3c0.5-0.3,0.9-0.6,1.3-0.9c0.8-0.6,1.5-1,1.9-1.2
		c1.6-1.2,3.6-2,5.6-2.3h0.5H19c0.2,0,0.4-0.1,0.6-0.2c0.4-0.2,0.9-0.5,1.3-0.8c0.8-0.6,1.4-1.1,1.8-1.4c0.3-0.3,0.6-0.5,1-0.5
		c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0.1,0.4,0.1c0.3,0.1,0.5,0.2,0.8,0.3c1.1,0.6,2.1,1.4,2.9,2.4c0.3,0.4,0.6,0.7,0.8,1
		c0.2,0.3,0.4,0.5,0.6,0.8l1.3,1.4c0,0,0,0,0,0c0.8-0.1,2-0.2,3.4-0.6c1.6-0.4,3.2-1,4.6-1.9c0.9-0.5,1.7-1.1,2.4-1.7
		c0.4-0.3,0.8-0.6,1.2-1.1c0.5-0.5,1.1-0.9,1.7-1.2c0.7-0.3,1.5-0.2,2.2,0.2c0.6,0.3,1,0.8,1.3,1.5c0.4,0.9,0.6,1.7,0.8,2.6
		c0.1,0.7,0.4,1.3,0.8,1.9c0.4,0.5,0.9,0.9,1.5,1.2c1.1,0.5,2.2,0.9,3.3,1.2c1,0.3,1.7,0.5,2.2,0.7c0.8,0.2,2.3,0.4,4.4,0.8
		c1,0.2,2.3,0.4,3.6,0.6c0.6,0.1,1.3,0.1,1.9-0.1c0.3-0.1,0.6-0.2,0.8-0.3c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1
		c0.1-0.1,0.1-0.1,0.2-0.2l0.9-1c0.1,0.1,0.7,0.8,1.4,1.6c0.4,0.5,0.9,1,1.4,1.5c1.1,1.2,2.4,2.2,3.9,3c1.2,0.6,2.6,0.7,3.9,0.3
		c1.2-0.5,2.4-0.7,3.7-0.8c0.8,0.1,1.7,0.3,2.4,0.7c0.7,0.4,1.4,0.8,2.1,1.3c0.9,0.6,1.8,1.4,2.9,2.2c1.1,0.9,2.3,1.4,3.7,1.6
		c1.4,0.1,3-0.7,5.1-0.9c1.3-0.1,2.6,0.1,3.8,0.5c1,0.4,2,0.9,2.9,1.5c1.7,1.1,3.2,2.1,4.4,2.8c1.9,1.1,3.4,1.2,4.1,1.4
		c0.1-4.6-0.3-9.3-1-13.9c-0.7,0.4-1.4,0.8-2.1,1.2l-0.6,0.4l-0.3,0.2c-0.2,0.1-0.3,0.3-0.5,0.4l-0.9,0.9l-0.4,0.4
		c-0.2,0.2-0.3,0.4-0.5,0.5l-2.4,2.8v-0.3v-0.7l0.1-0.6c0.1-0.9,0.3-1.5,0.4-2.2c0.1-0.3,0.1-0.7,0.3-1l0.4-1.1
		c0.2-0.4,0.3-0.7,0.5-1.1l0.3-0.5l0.2-0.3c0.9-1.4,2-2.6,3.3-3.5c0.3-0.2,0.6-0.4,0.9-0.6c-0.5,0-1.1-0.1-1.6,0
		c-1.2,0-2.4,0.2-3.5,0.5c-1,0.2-2.1,0.6-3.1,1c-0.7,0.3-1.5,0.7-2.1,1.1c-0.6,0.4-1.2,0.8-1.7,1.3l-0.5,0.4l-0.6,0.5l-1.2,1h-0.1
		h-0.1c0,0-0.1,0-0.1,0v-0.1l0.1-0.2l0.1-0.4c0.5-1.6,1.3-3.1,2.1-4.6l0.5-0.8l0.4-0.5c0.2-0.3,0.5-0.7,0.8-1c0.6-0.7,1.3-1.4,2-2
		c0.8-0.7,1.6-1.3,2.4-1.8c0.4-0.3,0.9-0.6,1.4-0.8l0.7-0.4c0,0,0,0-0.1,0h-0.1c-0.3-0.1-0.7-0.2-1-0.2c-0.7-0.1-1.3-0.1-2,0
		c-0.6,0.1-1.3,0.2-1.9,0.5l-0.4,0.2c-0.2,0.1-0.3,0.2-0.5,0.3l-0.3,0.1l-0.3,0.3l-0.6,0.6l-0.6,0.6l-0.3,0.3l-0.4,0.4l-1.6,1.5
		l0.6-2.1l0.7-2.4c0.1-0.4,0.3-0.8,0.4-1.2l0.2-0.6l0.2-0.6c0.2-0.4,0.4-0.9,0.6-1.3c0.4-0.8,0.8-1.6,1.3-2.4
		c0.3-0.4,0.6-0.8,0.9-1.2c0.4-0.4,0.9-0.7,1.5-0.8c0.9-0.2,1.7-0.2,2.6-0.2c0.8,0,1.5,0,2.3,0c1.4,0,2.8,0.3,4.1,0.7
		c0.6,0.2,1.2,0.5,1.8,0.9c0,0,0-0.1,0-0.1c0.7-2.2,2.4-4,4.6-4.9c0.7-0.3,1.4-0.6,2.2-0.7c0.9-0.2,1.7-0.2,2.6-0.1
		c1,0.1,1.9,0.5,2.7,1c0.5,0.3,0.9,0.7,1.2,1.1c0.4,0.5,0.7,1.2,0.7,1.9c0,0.4,0,0.8-0.1,1.2l-1.2,0.4h-0.1h-0.1h-0.1h-0.2l-0.3,0.1
		l-0.6,0.3c-0.8,0.4-1.6,0.8-2.3,1.4c-0.7,0.6-1.4,1.3-1.9,2c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.1-0.2,0.4-0.2,0.4h0.2
		c0.1,0,0.1-0.1,0.4,0s0.5,0.1,0.8,0.1c1,0.1,2,0.4,3,0.8c0.7,0.3,1.4,0.7,2.1,1.1c0-0.2,0-0.4,0-0.6h2.7h0.1c0,0,0,0,0-0.1
		l-0.3-0.5c-0.2-0.3-0.4-0.6-0.6-0.9c-0.4-0.5-0.8-1-1.3-1.4c-0.7-0.6-1.6-1-2.5-1.1c-0.7-0.1-1.1,0-1.1-0.2
		c-0.1-0.2,0.3-0.5,1.1-0.7c1.2-0.2,2.4,0,3.4,0.7c0.7,0.4,1.3,0.9,1.8,1.5c0.3,0.3,0.5,0.7,0.8,1l0.4,0.5c0.1,0.2,0.4,0.7,0.6,1
		l0.6,1.1l0.3,0.6l0.2,0.3c0,0,0,0.1,0,0.1h-0.2c-0.8,0-1.7,0.1-2.7,0.1h-1.5c0.3,0.3,0.6,0.7,0.8,1.1c1.5,2.5,1.5,5.6,0,8.1
		c-0.2,0.4-0.5,0.7-0.8,1.1c-0.5,0.5-1,0.9-1.5,1.2l-0.8,0.5c-0.1,0-0.2,0.1-0.2,0.1l-0.1-0.2l-0.1-0.4c-0.1-0.3-0.2-0.5-0.2-0.8
		c-0.1-0.3-0.1-0.7-0.2-0.9c-0.1-0.6-0.3-1.2-0.5-1.8c-0.1,1.3-0.3,2.7-0.9,3.9c-0.4,1-0.9,2-1.5,3c-0.3,0.5-0.7,1-1.1,1.6
		c-0.7,0.9-1.4,1.8-2.1,2.7c-0.1-0.3-0.2-0.5-0.3-0.8c-0.8,1.4-0.8,3.3-0.9,5.2c0,0.8-0.1,1.6-0.1,2.4c0.3-0.3,1.3-0.8,2.9-1.1
		c2.6-0.5,5.3-0.4,7.9,0.4c1.4,0.4,2.7,0.9,4,1.5c1,0.4,2,0.8,3.1,1.1c0.6,0.2,1.1,0.2,1.5,0.3c0-2.2-0.1-4.4-0.3-6.5
		c-0.5-8.3-1.4-15.9-1.7-22.2c-0.4-5-0.4-10.1,0-15.1c0.2-2.3,0.4-3.9,0.6-4.7c-2.1,1.3-3.9,2.9-5.4,4.9l-1.9,2.5l0.6-3.1
		c0.5-2.2,1.5-4.2,2.9-5.9c1.2-1.6,2.6-2.9,4.2-4c0.9-0.6,1.9-1,2.9-1.4h0h-0.1l-0.6-0.2l-1.2-0.3c-1.5-0.4-3-0.6-4.5-0.6
		c-1.4,0-2.9,0.2-4.2,0.7c-0.6,0.2-1.3,0.5-1.8,0.9c0,0-0.3,0.2-0.4,0.3c-0.1,0.1-0.3,0.2-0.4,0.4l-0.4,0.4l-0.2,0.2l-0.1,0.1
		l-0.1,0.2l-1.1,1.5l-1.4,1.9l0.1-1c0-0.1,0-0.1,0-0.2v-0.1l0.2-2c0-0.3,0.1-0.6,0.1-0.9l0.2-1c0.1-0.3,0.2-0.7,0.4-1
		c0.1-0.3,0.3-0.7,0.4-1l0.4-0.7c0.1-0.2,0.3-0.4,0.4-0.6c0.3-0.3,0.5-0.7,0.8-1c0.6-0.6,1.2-1.2,1.9-1.7c2.2-1.7,4.8-2.9,7.5-3.4
		c0.9-0.2,1.9-0.3,2.8-0.3c-0.2-0.2-0.6-0.5-1-0.9c-0.7-0.8-1.8-1.9-3.2-3.3c-0.7-0.7-1.4-1.3-2.2-1.9c-0.3,0.1-1.8,0-3.8,0.4
		c-1.4,0.2-2.6,0.9-3.7,1.8c-0.3,0.3-0.6,0.6-0.8,0.9c-0.1,0.2-0.2,0.3-0.3,0.5l-0.1,0.1c0,0.1,0,0.1,0,0.2l0.1-0.1l0.1-0.1
		c0.3-0.1,0.6-0.2,0.9-0.3c0.9-0.2,1.9-0.2,2.8,0c2,0.5,3.7,1.8,4.5,3.7c0.2,0.2-1.8,1-1.8,0.9c-0.6-1.3-1.8-2.2-3.2-2.6
		c-0.7-0.2-1.3-0.2-2-0.1c-0.5,0.1-0.9,0.2-1.4,0.5c-0.2,0.1-0.4,0.2-0.6,0.3l-0.6,0.5l-1.4,1.2c0-0.5,0-0.7,0-0.8l0.1-1.2
		c0-0.8,0.2-1.5,0.5-2.3c0.2-0.4,0.4-0.8,0.6-1.1c0.1-0.2,0.3-0.4,0.5-0.6c0.3-0.4,0.7-0.7,1.1-1c1.3-1,2.9-1.6,4.6-1.7
		c1-0.1,2,0,3,0.2c0.2,0,0.3,0.1,0.4,0.1c-0.9-0.6-1.9-1.2-2.9-1.7c-1.2-0.6-2.6-1-3.9-1.2c-0.7-0.1-1.5-0.1-2.2-0.1
		c-0.2,0.1-0.4,0.1-0.6,0.1c-0.1,0-0.1,0-0.3,0l-0.5,0.1l-0.5,0.1c-0.2,0-0.4,0.1-0.6,0.2l-1.3,0.4c0-0.2-0.1-0.4-0.1-0.6v-0.9
		c0-0.6,0.1-1.2,0.5-1.8c0.4-0.6,0.8-1,1.4-1.4c0.4-0.3,0.8-0.5,1.3-0.7c0.9-0.4,1.8-0.6,2.7-0.8c0.9-0.2,1.8-0.3,2.7-0.4
		c1.9-0.3,3.8-0.4,5.7-0.3c2.1,0.1,4.1,0.6,6,1.4c2.8,1.3,5,3.7,6.1,6.6c0.2,0.6,0.3,1.2,0.4,1.8c0,0.3,0.1,0.5,0.1,0.8
		c0,0,0,0.1,0,0.1l-0.1,0.3l0,0.1c0,0.1,0.1,0,0.1,0l0.2-0.1l0.1-0.1l0.1,0l0.3-0.2c0.4-0.3,0.7-0.6,1.1-0.9c1.4-1.1,2.9-2,4.6-2.7
		c1.5-0.5,3.1-0.7,4.7-0.4c1.3,0.2,2.6,0.6,3.9,1.2c1.9,0.9,3.7,1.9,5.4,3c1.2,0.7,2.2,1.5,3.2,2.4c0.5,0.4,0.9,0.9,1.3,1.4
		c0.3,0.4,0.5,0.8,0.7,1.2l0.2,0.3h-0.3l-0.7,0.1l-6.3,0.5l-8.3,0.7c-1.6,0.1-3.3,0.3-4.9,0.7c-0.4,0.1-0.8,0.2-1.2,0.4
		c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.4,0.2-0.4,0.2c-0.1,0.1-0.1,0.1-0.2,0.2l-0.1,0.1h0.1h0.3h0.3c0.2,0,0.5,0.1,0.8,0.1
		c0.6,0.1,1.2,0.2,1.7,0.4c1.4,0.4,2.7,1.2,3.8,2.2c0.2,0.2,0.4,0.5,0.6,0.7c1.2-0.7,2.7-0.9,4.1-0.7c1.3,0.2,2.5,0.7,3.6,1.5
		c0.9,0.7,1.8,1.6,2.5,2.5l0.3,0.3l0.1,0.2v0.1l0.1,0.2c0,0,0-0.1,0.1-0.1v-0.1c0-0.2,0-0.5,0.1-0.8c0.1-1,0-2-0.3-2.9
		c-0.3-0.8-0.7-1.5-1.3-2c-0.6-0.5-1.3-0.8-2-1.1c-1.1-0.4-2.1-0.8-3.2-1.3c-0.7-0.4-1-0.8-1-0.9s0.5,0.1,1.2,0.3
		c0.8,0.2,1.9,0.4,3.4,0.8c0.9,0.2,1.7,0.6,2.5,1.1c0.9,0.6,1.6,1.6,2,2.6c0.4,1.1,0.6,2.3,0.6,3.5c0,0.3,0,0.6,0,1
		s-0.1,1.1-0.2,1.6c-0.2,1.1-0.3,2.2-0.6,3.3l-1.7-2.5c-0.3-0.4-0.6-0.8-0.9-1.3l-0.2-0.3l-0.1-0.2l-0.2-0.3c-0.6-0.8-1.4-1.4-2.2-2
		c-1.5-1.1-3.8-1.6-5.3-0.7c0.3,0.6,0.5,1.3,0.6,2c0.2,1,0.1,2-0.1,2.9c-0.1,0.2-0.1,0.5-0.2,0.7l-0.2,0.4c-0.1,0.2-0.2,0.3-0.3,0.5
		c-0.2,0.3-0.4,0.6-0.7,0.9l-0.3,0.4c-0.1,0-0.3-0.2-0.4-0.3c-0.3-0.2-0.6-0.4-0.9-0.7l-0.7-0.6l-0.9-0.7l-1-0.9
		c0.3,0.7,0.5,1.3,0.7,2c0.6,2,0.6,4.1-0.1,6.1c-0.2,0.7-0.6,1.5-1,2.1c-0.2,0.3-0.4,0.6-0.6,0.8c-0.3,0.3-0.5,0.6-0.8,0.8
		c-0.5,0.4-1,0.7-1.5,0.9l-0.6,0.3c-0.2,0.1-0.2,0-0.2,0l0-0.1c0.2-0.9,0.4-1.8,0.5-2.6c0.1-0.4,0.1-0.7,0.1-0.9l0.1-0.7
		c0-0.7,0-1.5,0-2.2c0-0.8-0.1-1.5-0.2-2.3c-0.1-0.9-0.4-1.9-0.7-2.8c-0.2-0.6-0.6-1-1.2-1.3c-0.7-0.3-1.5-0.3-2.2-0.1
		c-1.1,0.2-2.2,0.5-3.2,0.8c-0.2,1-0.4,2.5-0.5,4.5c-0.2,3.7,0.3,9.1,1.8,15.7c0.7,3.3,1.6,6.9,2.4,10.8c0.4,2,0.6,4,0.8,6.2
		c0.2,2.2,0.2,4.4,0.1,6.6c-0.1,3.1-0.4,6.2-0.8,9.4c0.8,0,2,0,3.5,0.1c1.7,0.1,3.3,0.4,4.9,0.8c0.5,0.1,1,0.3,1.5,0.5
		c0.6,0.3,0.8,0.4,1.3,0.6c0.8,0.4,1.8,0.7,2.8,1c3.3,1,6.2,2,8.8,2.7c1.3,0.5,2.2,1.1,3.1,1.5s1.5,0.7,2,0.9c0.7,0.2,1.3,0.4,2,0.6
		c0.2,0,0.5,0.1,0.7,0.1c0.2,0,0.5,0,0.7,0c0.6,0,1.2-0.1,1.7-0.2c2.5-0.3,5.6-0.9,9-1.3c0.7-0.6,1.4-1.2,2.2-1.9
		c3.8-3.3,9.3-8,16.1-13.8c13.6-11.5,32.6-27.4,53.6-44.8l7.6-6.3l28.8,21.7l22.6,17.3c6.4,4.9,11.5,8.9,15,11.7
		c1.8,1.4,3.1,2.5,4,3.2s1.4,1.2,1.4,1.2l0.5,0.6c0.8,0.2,1.6,0.3,2.3,0.5c5.3,1.3,10.7,2.2,16.1,2.8l14.2,1.2
		c4.3,0.4,8,0.7,11.1,1.1c3.1,0.4,5.5,0.6,7.1,0.7c2.4,0.1,4.8,0.1,7.1-0.2c0.3,0,0.6-0.1,0.9-0.1c0.2-0.3,1.1-1.3,2.6-2.9
		c1.8-1.8,4.5-4.5,7.8-7.6c6.6-6.4,15.9-15.1,26.3-24.6l2.1-2l8,7.3l6.8,6.2c4.3,4,8.2,7.6,11.4,10.6c3.2,3,5.8,5.5,7.5,7.3
		c0.9,0.9,1.5,1.6,2,2.1c0.4,0,0.8,0,1.2,0c2,0.1,4.1-0.1,6.1-0.6c1.5-0.4,3.1-0.7,4.7-0.9c2-0.3,3.3-0.3,3.8-0.3V2H2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M336.7,104.9c-1-0.7-2.4-1.6-4.2-2.9c-3.7-2.6-8.9-6.4-15.4-11.2l-22.9-16.9l-24.9-18.6
		c14.6,31.8,25.8,57.2,25.3,57.4c-0.5,0.2-13.2-26.1-28.4-59.1l-5.9,4.9c-21,17.4-40.1,33-54,44.3c-6.9,5.6-12.6,10.1-16.5,13.2
		c-0.6,0.4-1.1,0.8-1.5,1.2c1.3-0.1,2.6-0.3,3.8-0.3c1.5-0.1,2.9-0.1,4.4,0.2c1.8,0.4,3.6,1,5.3,1.7c0.9,0.3,2.9,0.8,5.3,1.3
		c0.7,0.1,1.4,0.2,2,0.3c0.7,0.1,1.5,0.2,2.3,0.3c1.6,0.3,3.2,0.7,4.8,1c3.4,0.8,6.9,0.8,10.4,0.1c1.9-0.4,3.3-0.9,3.9-1
		c1.5-1.2,3-2.3,4.7-3.3c1.6-0.9,3.5-1.4,5.3-1.5c1.8,0,3.7,0.2,5.5,0.5c2.9,0.5,5.6,1.3,8.3,1.8c1.3,0.3,2.5,0.4,3.8,0.4
		c0.3,0,0.6,0,0.9-0.1c0.3-0.1,0.5-0.1,0.8-0.2l1.9-0.6c4.5-1.6,9.2-2.8,13.9-3.6c2-0.3,4-0.5,6.1-0.6l5.2-0.1
		c2.4,0,4.8-0.2,7.2-0.4c2.4-0.3,4.7-0.8,6.9-1.6c0.7-0.3,1.5-0.6,2.1-1l2.3-1.4c1.6-0.9,3.4-1.9,5.4-2.8c2.1-1,4.3-1.7,6.6-2.3
		c2.5-0.6,5.1-0.7,7.7-0.4c4.5,0.6,9.1,1.6,13.9,2.6c-0.1-0.1-0.4-0.5-0.5-0.6C337.7,105.6,337.2,105.2,336.7,104.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st3"
            d="M77,141.9c-14.1,0-25.5,0.5-25.5,1.1s11.4,1,25.5,1s25.5-0.5,25.5-1S91.1,141.9,77,141.9z"
          />
          <path
            // onClick={() => props.onFill(4)}
            // fill={props.fillColors[4]}
            fill="none"
            // class="st3"
            d="M483,114.7c-1.3,0.2-2.3,1.3-3.5,1.6c-0.7,0.1-1.3,0-1.9-0.3c-0.9-0.4-1.9-0.6-2.9-0.5
		c-1.1,0.1-2.1,0.3-3.1,0.7c-1,0.4-2,0.8-3,1.3c-1,0.5-2,0.9-3.1,1.3c-1,0.4-2.1,0.5-3.2,0.4c-0.5-0.1-1-0.2-1.5-0.4
		c-0.5-0.3-1-0.6-1.5-0.9c-1.1-0.9-2.5-1.6-3.9-2c-1.6-0.3-3.2-0.1-4.6,0.6c-1.4,0.5-2.7,1.2-3.9,1.6c-1.2,0.5-2.4,0.6-3.7,0.4
		c-0.2,0-0.4-0.1-0.6-0.2c-0.5-0.3-0.9-0.6-1.2-1l-1.5-1.5c0,1,0,2.4,0,3.4l0,0.4c0,0.1,0,0.3,0,0.3l-0.1,0.3
		c-0.1,0.2-0.1,0.4-0.2,0.6c-0.3,0.8-0.8,1.6-1.4,2.2l-0.2,0.2l-0.1,0c-0.4-0.2-0.8-0.3-1.3-0.4c-1-0.1-2,0-3,0.2
		c-1.8,0.5-3.4,1.1-4.9,1.5c-1.3,0.4-2.7,0.4-4-0.1c-1.2-0.5-2.2-1.5-3.5-1.9c-0.7-0.2-1.4-0.1-2,0.3c-0.5,0.3-0.9,0.7-1.2,1.2
		c-0.3,0.4-0.5,0.7-0.9,1.1l-0.4,0.5l-0.5,0.4c-0.3,0.2-0.6,0.4-0.9,0.6c-0.2,0.2-0.5,0.3-0.8,0.4c-1.2,0.4-2.4,0.6-3.6,0.5
		c-1.9-0.1-3.7-0.9-5.1-2.1c-0.9-0.8-1.5-1.8-1.9-2.9c-0.2-0.7-0.5-1.4-0.8-2.1c-0.4-0.8-1.1-1.4-2-1.8c-0.9-0.3-1.8-0.4-2.7-0.3
		c-0.9,0.1-1.8,0.4-2.6,0.7c-0.4,0.2-0.8,0.4-1.3,0.6l-0.2,0.1l-0.1-0.1l-1.2-0.9c-0.7-0.5-1.4-1-2.1-1.5l-0.3,1l-0.2,0.8
		c0,0.1-0.1,0.3-0.2,0.4l-0.1,0.2c-0.4,0.6-0.9,1.2-1.5,1.6c-0.9,0.6-1.8,1.2-2.8,1.6c-1.1,0.5-2.3,1-3.5,1.4
		c-0.7,0.2-1.4,0.3-2.1,0.2c-0.6-0.1-1.1-0.2-1.6-0.5c-0.3-0.2-0.6-0.5-0.9-0.8l0-0.1c0,0,0-0.1,0-0.1c-0.1-0.2-0.1-0.3-0.2-0.5
		l-0.2-0.6c-0.6,0-1.3,0-1.9,0.2c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0.1-0.4,0.2-0.5,0.3c-0.6,0.4-1.1,1-1.4,1.7
		c-0.1,0.3-0.2,0.6-0.3,0.8l0,0.4c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0l-0.6,0.2l-0.7,0.2l-0.3,0.1h-0.1l-0.2,0.1c-0.1,0,0,0,0,0v-0.2
		c0,0,0-0.1-0.1-0.4c-0.1-0.3-0.2-0.5-0.3-0.7c-0.1-0.2-0.3-0.4-0.4-0.6c-0.6-0.7-1.3-1.2-2.1-1.5c-1.4-0.6-2.9-0.8-4.4-0.6
		c-0.7,0.1-1.4,0.2-2,0.4c-0.6,0.2-1.1,0.4-1.7,0.7l-2.8,1.3c-1.4,0.7-2.9,1-4.4,1.1c-1,0-2-0.2-2.9-0.6c-1.7-0.9-3.6-1.6-5.5-2
		c-3.1-0.5-6.4,0.2-9.1,1.8c-0.7,0.5-1.4,0.8-2.2,1.1c-0.8,0.1-1.6-0.1-2.3-0.4l-5.7-2.1c-1-0.3-1.9-0.7-2.8-1.1
		c-0.3-0.2-0.6-0.4-0.8-0.7c-0.2-0.4-0.3-0.8-0.3-1.3c-0.1-0.7-0.3-1.4-0.6-2c-0.4-0.7-1-1.3-1.8-1.5c-1.4-0.5-2.9-0.4-4.2,0.3
		c-1.3,0.8-2.5,1.8-3.5,3c-0.8,0.9-1.9,1.7-3,2.3c-2.3,1-4.7,1.4-7.2,1.4l-13.7,0.1h-0.6v0.6v0.6v0.4v0.2c0,0.2,0,0.4-0.1,0.5
		c0,0.2-0.1,0.4-0.2,0.5c-0.3,0.7-0.7,1.3-1.2,1.8c-0.9,0.9-1.9,1.6-3,2.1c-1.8,1-3,1.4-2.9,1.6c0,0.1,1.2,0,3.2-0.8
		c1.2-0.5,2.4-1.1,3.4-2c0.7-0.6,1.2-1.3,1.6-2.1c0.1-0.2,0.2-0.5,0.3-0.7c0.1-0.2,0.1-0.5,0.2-0.8c0-0.1,0-0.2,0-0.3v-0.1h0.1h0.2
		h0.7h1.5l3.2,0.1c2.2,0,4.6,0.1,7.1,0.1c2.8,0.1,5.5-0.4,8.1-1.5c1.3-0.7,2.6-1.6,3.6-2.7c0.8-1,1.8-1.9,2.9-2.5
		c0.8-0.4,1.7-0.5,2.5-0.2c0.7,0.3,0.9,0.8,1.1,1.9c0.1,0.7,0.3,1.3,0.5,1.9c0.4,0.6,0.9,1.2,1.6,1.5c1,0.5,2.1,0.9,3.2,1.2l5.8,1.9
		c1,0.4,2.1,0.5,3.2,0.3c0.9-0.3,1.8-0.8,2.6-1.4c2.4-1.7,5.3-2.4,8.2-2.1c1.9,0.3,3.7,0.9,5.4,1.8c1,0.4,2,0.7,3.1,0.8
		c1.6,0,3.3-0.3,4.8-0.9l2.9-1.1c1.1-0.5,2.2-0.8,3.3-0.9c1.3-0.1,2.6,0.1,3.7,0.7c0.6,0.3,1.1,0.7,1.5,1.2c0.1,0.1,0.2,0.3,0.3,0.4
		c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.3,0.1,0.6,0.1,0.9v1.3c0.1,0,0.2,0,0.3,0l1.4-0.3l1.4-0.3l2.2-0.5c0-0.3,0-0.5,0-0.8
		c0-0.1,0-0.3,0-0.5v-0.2c0-0.1,0.1-0.3,0.2-0.4c0.2-0.4,0.5-0.7,0.8-0.9l0.3-0.2c0.1,0,0.3-0.1,0.2-0.1c0,0,0,0,0.1,0l0.2,0.3
		c0.4,0.6,0.9,1.1,1.5,1.5c0.8,0.5,1.6,0.8,2.5,0.9c1,0.1,1.9,0,2.8-0.3c1.3-0.4,2.6-0.9,3.8-1.5c1.1-0.5,2.2-1.2,3.2-1.9
		c0.8-0.6,1.5-1.4,2-2.3l0.1-0.3v-0.2c0,0,0.1,0.1,0.2,0.1l1.1,0.8c0.3,0.2,0.7,0.5,1,0.7l1-0.6c0.4-0.2,0.8-0.4,1.1-0.6
		c1.3-0.7,2.8-0.9,4.2-0.6c0.6,0.2,1,0.6,1.3,1.1c0.3,0.6,0.6,1.2,0.8,1.8c0.5,1.3,1.3,2.4,2.4,3.3c1.6,1.3,3.7,2,5.8,2.1
		c1.3,0.1,2.7-0.2,3.9-0.7c0.3-0.1,0.6-0.2,0.9-0.4c0.4-0.2,0.7-0.4,1-0.6l0.5-0.4l0.5-0.5c0.3-0.3,0.7-0.7,1-1.1
		c0.3-0.4,0.6-0.7,1-0.9c0.4-0.2,0.9-0.2,1.3-0.1c1,0.3,1.9,1.3,3.2,2c1.5,0.7,3.2,0.9,4.8,0.4c1.6-0.4,3.2-1,4.9-1.3
		c0.8-0.2,1.6-0.2,2.4-0.1c0.2,0,0.4,0.1,0.5,0.2c0.3,0.1,0.6,0.2,0.8,0.4l0.5,0.3c0.2,0,0.3-0.3,0.5-0.4c0.2-0.1,0.3-0.3,0.5-0.4
		c0.1-0.1,0.2-0.2,0.3-0.3c0.8-0.8,1.4-1.8,1.8-2.8c0.1-0.3,0.2-0.5,0.3-0.8l0.1-0.6c0,0,0,0,0,0c0.3,0.1,0.7,0.3,1,0.3
		c1.6,0.3,3.3,0.1,4.8-0.4c1.5-0.5,2.7-1.2,4-1.7c1.1-0.5,2.3-0.7,3.5-0.5c1.2,0.3,2.2,0.9,3.1,1.7c0.6,0.4,1.2,0.8,1.8,1.1
		c0.7,0.3,1.4,0.5,2.1,0.6c1.4,0.1,2.7-0.1,4-0.6c1.1-0.4,2.2-0.9,3.2-1.5c1-0.5,1.9-1,2.8-1.4c0.8-0.4,1.7-0.6,2.6-0.7
		c0.8-0.1,1.6,0,2.4,0.3c0.4,0.2,0.9,0.3,1.3,0.4c0.5,0,1,0,1.4-0.2c0.7-0.3,1.3-0.7,1.9-1.2c0.4-0.3,0.9-0.6,1.4-0.8
		c0.7-0.2,1.4-0.1,2,0.3c0.3,0.3,0.3,0.7,0.4,0.7s0.3-0.3,0-0.9C484.9,114.8,483.9,114.5,483,114.7z"
          />
          <ellipse class="st3" cx="193.8" cy="150.5" rx="32.3" ry="1" />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st3"
            d="M436.3,140.8c-11.2,0-20.2,0.5-20.2,1c0,0.5,9,1,20.2,1c11.2,0,20.2-0.4,20.2-1
		C456.5,141.3,447.5,140.8,436.3,140.8z"
          />
          <ellipse class="st3" cx="249" cy="163.7" rx="46.5" ry="1" />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M190.2,132.1c1.8-0.4,6.9,0,8.5-0.5c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0c0-0.1,1.3-0.7,3.7-1.7
		c1.2-0.5,2.7-1.1,4.5-1.6c1-0.3,2-0.5,3-0.6c1.2-0.1,2.3,0,3.5,0.1c2.5,0.4,4.9,0.4,7.4,0.2c1.3-0.1,2.5-0.5,3.6-1.2
		c0.3-0.2,0.5-0.4,0.8-0.6c0.2-0.2,0.5-0.5,0.8-0.8l1.6-1.6c0.3-0.4,0.7-0.7,1.1-1.1c-0.5,0.2-1,0.5-1.6,0.7c-1.6,0.6-3.3,0.9-5,1
		c-2.1,0.1-4.2-0.1-6.2-0.5c-1.7-0.3-3.3-0.8-4.8-1.2c-0.8-0.2-1.4-0.4-2.1-0.5c-0.7-0.2-1.4-0.4-2-0.6c-2.5-0.8-4.2-1.7-5.2-2
		c-1.7-0.5-3.5-0.9-5.3-1c-1.4-0.1-2.7,0-4.1,0.3l-4.9,0.6c-0.7,0.1-1.5,0.2-2.2,0.2c-0.5,0.4-1,0.7-1.5,1c0,0,0.3-0.4,0.9-0.9
		c-2.1,0.2-4.2,0.3-6.4,0.3c-0.6,0-1.2,0-1.9-0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.3-0.1-0.5-0.1-0.7-0.2c-0.9-0.3-1.6-0.7-2-0.8
		c-0.4-0.2-1.1-0.4-2-0.7c-0.9-0.3-2-0.7-3.1-0.9c-3.1-0.4-6.1-1.1-9-2.1c-1-0.3-2-0.7-3-1.1c-0.5-0.2-1.1-0.5-1.3-0.7
		c-0.4-0.2-0.8-0.3-1.2-0.4c-1.6-0.5-3.3-0.9-4.6-1.2c-1.5-0.3-2.6-0.6-3.4-0.8c-0.1,1.3-0.3,2.5-0.4,3.8c-0.3,3.2-0.7,6.4-1.1,9.7
		c0.9-0.1,2.4-0.1,4.2-0.2c1.8-0.1,3.6-0.4,5.4-0.9c2.3-0.6,4.6-0.9,7-1c1.3,0,2.5,0,3.7,0.1l1.7,0.2c0.6,0.1,1,0.1,1.7,0.2
		c0.3,0.1,0.7,0.2,1,0.3c0.3,0.2,0.6,0.4,0.9,0.7c0.3,0.4,0.6,0.7,0.9,1c0.6,0.5,1.2,1,2,1.3c1.1,0.4,2.3,0.8,3.5,1
		c0.8,0.2,1.2,0.4,1.4,0.5c1.8,0.1,5.9,0.6,7.2,1.9C185.2,130.8,188.4,132.5,190.2,132.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st3"
            d="M20.3,205.4c0.3,0.8,0.5,1.4,0.7,1.9c-0.2,3.6,0.1,6.1,0.5,6.1c0.6,0,1.2-4.1,1.9-9.1c0.7-5.1,1.3-9.1,0.7-9.3
		c-0.6-0.2-2,3.7-2.8,8.7c-0.2-0.9-0.4-1.8-0.7-2.7c0-3.2-0.6-10.7-0.6-19.5c0-11.6,1.2-21,0.5-21.1c-0.6-0.1-2.5,9.3-2.6,21.1
		c0,5.9,0.4,11.1,1,14.9c0,0,0,0,0,0c-2.8-6.5-6.4-11-6.9-10.6s2.3,5.2,5,11.5C18.5,200.4,19.6,203.3,20.3,205.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st3"
            d="M34.5,202c3.2,15.9,3.3,29.2,4,29.2c0.1,0,0.3-0.8,0.4-2.3c0.1-0.7,0.1-1.7,0.1-2.7v-1.7l-0.1-1.9
		c-0.2-7-1-14-2.3-20.9c-1.3-6.9-3.3-13.7-6-20.2c-2.1-5-4-7.8-4.2-7.7s1.1,3.2,2.8,8.2C31.4,188.5,33.1,195.2,34.5,202z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st3"
            d="M32.9,226.2c0.5,0,0.4-10.2-2-22.5c-2.4-12.3-6.2-21.7-6.8-21.5c-0.5,0.2,2.2,9.9,4.7,21.9
		C31.3,216.2,32.3,226.2,32.9,226.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st3"
            d="M40.2,197.8c0.9,2.6,1.9,6.2,2.9,10.3c2,8.1,2.8,14.8,3.4,14.8c0.6,0,0.7-7-1.3-15.3c-0.8-3.6-2-7-3.5-10.3
		c-0.5-1-1-2-1.6-2.9c-0.4-0.7-0.7-1-0.9-0.9c-0.1,0.1-0.1,0.5,0.1,1.3S39.8,196.5,40.2,197.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st3"
            d="M26.7,379.8c-0.3,0.1-0.6,0.2-0.8,0.4c-0.3,0.2-0.6,0.3-0.9,0.5c-0.3,0.2-0.6,0.2-1,0.3
		c-0.1,0-0.2,0.1-0.4,0.1c0-0.5,0-1.3-0.2-2.3c-0.1-0.5-0.2-1-0.4-1.5c-0.1-0.4-0.3-0.7-0.5-1c-0.3-0.4-0.7-0.6-1.1-0.8
		c-0.7-0.2-1.4-0.3-2.1-0.2l-2-0.2c-0.4,0-0.8,0-1.2,0c-0.2,0-0.5,0.1-0.7,0.1l-0.6,0.2c0,0-0.1,0-0.1,0c-0.1-0.1-0.3-0.1-0.4-0.2
		c0,0-0.1,0.2-0.2,0.5c-0.3,0.2-0.7,0.4-1,0.5l-0.3,0.2h0c0-0.2,0-0.2,0,0h0h-0.2c-0.4,0-0.7,0.2-0.9,0.5c-0.1,0.1-0.2,0.3-0.3,0.4
		L11,378l-0.2,0.1l-0.3,0.3c-0.5,0.6-0.9,1.2-1.2,1.9c0,0,0,0,0,0c-0.3-0.1-0.5-0.2-0.8-0.3c-0.8-0.2-1.3,0-1.4,0.3s0.2,0.6,0.7,1.1
		c0.6,0.6,1.3,1.1,2.1,1.6c0.4,0.2,0.8,0.4,1.3,0.5c0.4,0.1,0.7,0.1,1.1,0.1c0.7,0,1.2,0,1.4,0c0.2,0,0.7,0.4,1.3,0.6
		c0.4,0.2,0.9,0.3,1.4,0.2c0.5-0.1,1-0.3,1.4-0.6c0.5-0.4,1-0.7,1.5-1c0.1,0,0.2-0.1,0.3-0.1H20c0.2,0,0.4-0.1,0.6-0.1
		c1.2,0.4,2.5,0.6,3.8,0.3c0.6-0.1,1.2-0.4,1.6-0.8c0.3-0.3,0.6-0.7,0.9-1.1c0.2-0.4,0.3-0.8,0.3-1.2
		C27.2,379.8,27,379.7,26.7,379.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st3"
            d="M35.3,261.5c0.5,0.3,4.7-7.1,10-16c2.7-4.5,5.2-8.4,7-11.3c0.9-1.5,1.7-2.6,2.1-3.5c0.5-0.8,0.7-1.3,0.6-1.4
		c-0.1-0.1-0.5,0.3-1.3,0.9c-0.1,0.1-0.3,0.3-0.4,0.4c0.4-1.4,0.8-3.7,0.9-6.3c0.1-1.9,0-3.8-0.3-5.8c-0.3-1.5-0.6-2.3-0.9-2.3
		c-0.3,0-0.4,1-0.5,2.4s-0.2,3.4-0.3,5.6c-0.2,3.5-0.2,6.5,0.1,7.6c-0.4,0.5-0.8,1-1.2,1.5c-2.8,3.5-5.3,7.2-7.6,11.1
		C38.1,253.4,34.8,261.3,35.3,261.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st3"
            d="M8.8,335.5c1.1,0.4,2.5,0.8,4.1,1.2c0.8,0.2,1.7,0.4,2.6,0.5c1,0.1,1.9,0.1,2.9,0c0.9-0.1,1.9-0.2,2.8-0.5
		l0.6-0.2l0.5-0.1l1.2-0.1l4.2-0.4c2.4-0.2,3.9-0.6,3.9-0.9c0-0.3-1.5-0.5-3.9-0.6c-1.2-0.1-2.7-0.1-4.3-0.1c-0.4,0-0.8,0.1-1.3,0.1
		c-0.2,0-0.5,0-0.7,0.1c-0.3,0.1-0.5,0.1-0.7,0.1c-0.8,0.2-1.7,0.3-2.5,0.4c-1.6,0.1-3.3,0-4.9-0.3l-3.6-0.7
		c0.1-0.2,0.2-0.5,0.3-0.8c0.1-0.1,0.2-0.2,0.3-0.3l0.6-0.5c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.1,0.3-0.1,0.3-0.1
		c0.7,0.1,1.4,0.2,2.3,0.3c0.4,0,0.8,0,1.2,0c0.3,0,0.5,0,0.8,0.1c0.3,0.1,0.5,0.1,0.8,0.2l0.4,0.2c0.1,0.1,0.2,0.2,0.2,0.3
		c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.2,0.2,0.5,0.3,0.7c0.1,0.4,0.1,0.6,0.3,0.7c0.1,0.1,0.5-0.1,0.6-0.7c0.1-0.3,0.1-0.7,0.1-1
		c0-0.2,0-0.4-0.1-0.6c-0.1-0.3-0.1-0.5-0.3-0.8c-0.1-0.2-0.3-0.4-0.5-0.6c-0.2-0.1-0.3-0.2-0.5-0.3c-0.3-0.2-0.7-0.4-1.1-0.5
		c-0.4-0.1-0.9-0.2-1.3-0.2l-1-0.1l-2.2-0.2c-0.6,0-1.1,0.2-1.5,0.5c-0.3,0.2-0.5,0.5-0.8,0.8c-0.2,0.3-0.3,0.5-0.5,0.7
		c-0.2,0.3-0.3,0.6-0.4,0.9c0,0.5,0.1,0.8,0.2,1c-2.3-0.4-3.7-0.6-3.8-0.3C5.1,334,6.5,334.7,8.8,335.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st3"
            d="M29.6,241.5c-0.5-5.4-1.4-10.8-2.7-16.1c-0.4-1.6-0.9-3.2-1.6-4.7c-0.5-1.1-0.8-1.6-0.9-1.6
		c-0.1,0-0.1,0.7,0.2,1.8c0.2,1.1,0.5,2.8,0.9,4.8c0.7,4,1.5,9.7,2.1,15.9c1.1,12.5,1.4,22.7,2,22.8c0.3,0,0.5-2.1,0.7-5.6
		c1-1.7,3.6-8.3,7.4-15.8c2.2-4.4,4.4-8.3,6-11.1c0.8-1.4,1.5-2.5,1.9-3.4c0.4-0.8,0.6-1.3,0.5-1.4c-0.1-0.1-0.5,0.2-1.1,1
		c-0.9,0.9-1.7,1.9-2.5,3c-2.5,3.5-4.8,7.1-6.7,11c-2.8,5.7-4.7,10.9-5.5,14.1C30.3,252.2,30.1,247,29.6,241.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st3"
            d="M66,241.4c-1.9,3.3-3.8,6.6-5.5,10c0.5-1.9,1.1-3.8,1.7-5.8c0.6-2,1.2-4,1.8-6l2.1-7l-4,6.1
		c-2.8,4.4-5.4,9-7.7,13.8c-2.1,4.3-3.7,8.3-5,11.6c-2.6,6.7-3.7,10.8-3.5,10.9c0.3,0.1,1.8-3.9,4.7-10.4c2.6-6,5.6-11.9,8.8-17.6
		c-0.6,2-1.2,4.1-1.7,6c-0.7,2.6-1.4,5.1-1.8,7.7l-1.3,7.9l3.2-7.4c0-0.1,0.1-0.1,0.1-0.2l0,0c0,0,0,0,0-0.1c3.4-7.8,6.7-14.4,9-19
		c2.4-4.7,4-7.3,3.8-7.5C70.7,234.4,68.7,236.8,66,241.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st3"
            d="M62.3,226.8c-7.8,9.1-12.5,17.7-12,18c0.5,0.3,6-7.6,13.6-16.6c7.6-9,14.7-15.5,14.3-16
		C77.9,211.8,70.1,217.6,62.3,226.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st3"
            d="M16.2,245.7c-0.6,0.1,0.9,9.2,1.9,20.5c0.5,5.6,0.8,10.8,1,14.5c0.1,1.9,0.2,3.3,0.3,4.4
		c0.1,1.1,0.2,1.6,0.3,1.6c0.1,0,0.3-0.6,0.5-1.6c0.2-1.5,0.4-2.9,0.4-4.4c0.2-4.9,0.1-9.8-0.4-14.7
		C19.1,254.6,16.8,245.6,16.2,245.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st3"
            d="M26.9,268.2c0.3,0,0.4-4.3-0.3-11.1c-0.9-8.9-2.7-17.8-5.4-26.4c-3.1-10.1-7.5-18.8-11.5-24.4
		c-1.6-2.2-3.3-4.3-5.3-6.3c-1.4-1.3-2.3-2-2.3-1.9c-0.3,0.3,2.8,3.2,6.4,9c4.6,7.6,8.2,15.7,10.7,24.2c2.6,8.5,4.5,17.2,5.9,26
		C26.1,264,26.6,268.2,26.9,268.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st3"
            d="M46.3,253.2c-0.4-0.3-6.3,7.5-11.6,18.4c-2.1,4.4-4,8.8-5.5,13.4c0.4-4.1,0.9-8.4,1.4-12.9l1.2-10l-3.2,9.6
		l-0.4,1.1c-1.6,5.1-2.9,10.3-3.6,15.6c-0.8,4.9-1.2,9.2-1.5,12.9c-0.6,7.4-0.5,12-0.2,12s0.7-4.5,1.7-11.8c0.5-3.7,1.1-8,1.9-12.8
		c0.2-1.4,0.5-2.8,0.8-4.2c-0.4,5.2-0.7,10.3-0.8,15.5c0,2.8,0.1,5.7,0.4,8.5c0.2,2,0.6,3.1,0.7,3.1c0.2,0,0.1-1.1,0.1-3.1
		c0-2,0.1-4.9,0.3-8.4c0.2-2.9,0.4-6.3,0.8-10c0.4-0.9,0.9-2.2,1.6-3.6c1.5-3.6,3.7-8.5,6.3-13.8C41.8,261.8,46.8,253.6,46.3,253.2z
		"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            // class="st1"
            d="M133.3,151.9c-0.1,0-0.2,0.1-0.3,0.1l-0.4,0.3c-0.5,0.4-1,0.8-1.4,1.2c-0.9,0.9-1.7,1.8-2.4,2.6
		c-2.8,3.4-4.3,5.7-4.6,5.6c-0.2-0.1,0.5-1.9,2.2-4.6c0.2-1.2,0.6-2.9,1.1-4.9c0.3-1.1,0.6-2.3,0.9-3.6c-3.2,7.8-6,15.8-8.2,23.9
		c-1.8,6.7-2.4,11-2.7,11c-0.1,0-0.2-0.8-0.1-2.3c-0.2,0.7-0.4,1.3-0.5,1.9c-0.4,1.4-0.9,2.6-1.3,3.5c-0.8,1.9-1.3,2.9-1.5,2.8
		c-0.1-0.1,0.1-1.2,0.7-3.1c0.3-1,0.5-2.2,0.9-3.5c0.4-1.4,0.6-3,0.8-4.7c0.6-4.1,0.6-8.3,0.2-12.4c-0.1-1.2-0.3-2.4-0.5-3.5
		l-0.2-0.9l-0.1-0.4l-0.4,1.7c-1.3,5.4-2.7,10.4-4.2,15c-1.3,4-2.9,8-4.7,11.8c-1.2,2.6-2.6,5.1-4.1,7.5c-1.2,1.7-2,2.5-2.1,2.4
		c0.4-1,0.8-1.9,1.4-2.8c0.9-1.7,2-4,3.2-7c-0.2-0.8-0.5-2.5-1-4.8c-0.2-0.8-0.4-1.7-0.7-2.6c-0.1-0.2-0.1-0.2-0.2-0.2
		c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3v0.3c0,0.2-0.1,0.3-0.1,0.5l-0.5,1.9c-1.2,4.3-2.9,8.5-5,12.5c-1.6,3-2.9,4.8-3.2,4.6
		c-0.3-0.1,0.6-2.1,1.8-5.2c0.6-1.5,1.2-3,1.7-4.5c-0.8,0.8-2,2-3.7,3.7c-2.9,3-7.1,7.3-11.8,12.6l-2.8,3.1l1.2-4
		c1.1-3.3,2.6-6.5,4.6-9.4c1.5-2.4,3.1-4.8,4.9-7.2c-3.3,3.2-6.6,6.5-9.7,9.9c-4.3,4.8-6.5,8-6.7,7.9c-0.2-0.1,1.5-3.7,5.6-8.7
		c1.6-1.9,3.3-3.8,4.9-5.7c0.3-0.5,0.6-1,1-1.6c0.7-1.1,1.5-2.3,2.5-3.7c1-1.3,2-2.8,3.2-4.3c1.2-1.6,2.5-3.2,4-4.9
		c0.7-0.8,1.4-1.6,2.2-2.5c-1,0.9-2.1,1.7-3,2.7c-1.1,1.1-2.3,2.1-3.3,3.2c-1,1.1-2.1,2.2-3.1,3.3s-1.9,2.2-2.9,3.2l-2.6,3.2
		c-0.8,1-1.6,2.1-2.4,3.1c-1.5,2-2.9,3.9-4.1,5.7c-1.2,1.8-2.3,3.4-3.2,4.8c-0.9,1.4-1.7,2.7-2.3,3.7c-1.2,2.1-1.8,3.3-1.8,3.3
		s0.6-1.2,1.7-3.3c0.5-1.1,1.2-2.4,2.1-3.9s1.8-3.2,2.9-5s2.4-3.8,3.8-6c0.7-1,1.5-2.1,2.3-3.2l2.5-3.3l0.8-1
		c0.9-1.5,2.4-3.8,4.6-6.8c2.7-3.7,5.6-7.3,8.5-10.8c-1.5,1.4-3,2.9-4.5,4.4c-4.8,5.1-9.2,10.5-13.2,16.2
		c-3.7,5.2-6.9,10.1-9.4,14.2s-4.5,7.6-5.9,9.9s-2.2,3.7-2.3,3.6c-0.1-0.1,0.4-1.5,1.6-4c1.2-2.5,3-6,5.4-10.3
		c2.4-4.3,5.5-9.3,9.2-14.6c0.9-1.3,1.8-2.7,2.8-4l-1.9,1.3c-3.3,2.2-7.6,5.3-12.3,9c-8.1,6.4-14.5,12.3-16.4,13.5
		c0.3,1.8,0.4,3.6,0.3,5.5c-0.1,1.5-0.4,2.4-0.5,2.4c-0.1,0-0.1-0.9-0.3-2.3c-0.2-2.1-0.6-4.2-1.1-6.2c-1.8-6.8-4.5-13.3-8-19.3
		c-3.5-6.1-7.4-12-11.7-17.5c-2.2-2.8-3.8-4.9-4.6-6c-1.2,4.9-1.6,8-2,7.9c-0.3,0-0.3-3.3,0.5-8.4c1.1-6.7,3.2-13.3,6.1-19.4
		c0.2-0.5,0.5-0.9,0.7-1.4c-1,1.3-1.9,2.6-2.7,4c-1.7,2.8-2.3,4.6-2.6,4.6c0,0,0,0,0,0c-0.9,3.2-1.3,5.1-1.4,5
		c-0.1,0-0.1-1.3,0.3-3.6c0.5-3.2,1.2-6.4,2.2-9.6c0.6-2,1.3-4.2,2.2-6.4c0.9-2.4,2-4.8,3.2-7.1c1.3-2.6,2.8-5,4.4-7.4
		c0.9-1.3,1.9-2.6,3-3.7c0.2-0.2,0.3-0.3,0.5-0.4c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.5-0.3,0.7-0.4l0.4-0.1c0.2,0,0.4,0,0.5,0
		c0.7,0,1.4,0.4,1.8,1c0.3,0.4,0.5,0.9,0.6,1.4c0.1,0.3,0.1,0.6,0.2,0.9c-0.1-0.7-0.1-1.4-0.2-2.1c-0.1-0.9-0.4-1.7-0.8-2.4
		c-0.2-0.2-0.4-0.4-0.7-0.5c-0.3-0.1-0.6,0-0.9,0.1c-0.7,0.3-1.4,0.7-2,1.2c-0.6,0.5-1.2,1-1.7,1.5c-1.6,1.7-3.1,3.4-4.4,5.3
		c0,0-0.1,0.1-0.1,0.1c-1.9,4.4-3,7-3.2,6.9c-0.2-0.1,0.6-3.3,2.6-8.6c1-2.7,2.2-5.8,3.8-9.4c0.3-0.6,0.6-1.3,0.9-1.9
		c-0.6,1-1.2,2-1.8,3.1c-2.9,5.2-5.5,10.6-7.9,16.1l-1.6,3.7l-1.4,3.5l-1.4,3.3l-1.2,3.1c-1.5,3.9-2.7,7.1-3.6,9.3
		c-0.8,2.2-1.4,3.4-1.6,3.3c-0.1,0,0.2-1.3,0.8-3.6c0.3-0.9,0.6-2.1,1-3.3c-0.3-1.3-0.6-4.6-1.2-9.4c-0.3-2.2-0.6-4.6-1-7.3
		c0.1,6.2-0.4,12.4-1.4,18.5l-0.5,2.7c-0.2,0.8-0.4,1.6-0.6,2.4c-0.4,1.5-0.7,2.7-1.1,3.7c-0.7,2-1.2,3.1-1.3,3
		c-0.3-0.1,1-4.2,2-11.1c-0.1-1.1-0.3-2.6-0.6-4.6c-0.3-2.1-0.6-4.6-1.2-7.4c-0.2-1.1-0.4-2.1-0.7-3.1c0.2,4.9,0.2,9.8,0,14.6
		c-0.2,6.7-1.1,13.3-2.8,19.8l-1.8,6.7l-0.1-7c0-5.9-0.8-11.8-2.4-17.5c-0.7-2.4-1.5-4.1-2-5.1v170.2c0.3-0.1,0.6-0.1,1-0.2
		c0-0.3,0.7-0.9,2.1-1.5c0.9-0.4,1.8-0.6,2.7-0.7c0.6-0.1,1.2,0,1.8,0l0,0c0.7-9.7,0.6-19.4-0.2-29.1c-0.7-7.7-1.4-12.4-1.1-12.4
		c0.1,0,0.5,1.1,1,3.2c0.5,2.1,1.1,5.2,1.6,9c1.3,9.8,1.6,19.7,0.9,29.5c3.3,0.4,6.2,1,7.2,0.9c0.9-0.1,1.9-0.4,2.7-0.7l0.4-0.2
		c0.2-0.1,0.4-0.2,0.6-0.2c0.4-0.1,0.9-0.2,1.3-0.3c0.9-0.1,1.8-0.1,2.8,0c1.7,0.2,3.3,0.5,4.9,1c0.2,0.1,0.3,0.1,0.5,0.1
		c-0.1-1.9,1.3-9,4.1-17.2c1.3-4.1,3-8.1,5.1-11.9c1.7-2.9,3-4.5,3.2-4.4c0.3,0.2-0.6,2-1.9,5s-2.8,7.2-4.4,11.9
		c-2.8,8.2-4.8,15.1-5.7,16.7c0.6,0.2,1.2,0.3,1.9,0.4c0.9,0,1.9-0.1,2.8-0.5c1.3-0.4,2.7-1.2,4.5-1.8c0.6-0.2,1.2-0.3,1.8-0.4
		c0.3,0,0.7,0,1,0l0.8,0.1c1,0.2,1.9,0.3,2.6,0.5c0.7,0.2,1.3,0.3,1.7,0.5c0.3-1.2,0.6-2.4,1-3.6l0.6-2.1c0.2-0.7,0.4-1.3,0.5-2
		c0.2-1.3,0.3-2.6,0.2-3.9c0-0.6-0.1-1.3-0.1-1.9c-0.1-0.7-0.1-1.3-0.1-2c0.1-1.3,0.4-2.5,1-3.6c0.5-0.9,1-1.9,1.6-2.7
		c0.5-0.8,1-1.5,1.5-2.3c0.8-1,1.9-1.8,3-2.5c0.7-0.4,1.4-0.8,2.1-1.1c-0.2-0.2-0.3-0.3-0.4-0.5c-0.2-0.5-0.3-1-0.1-1.5
		c0.2-0.6,0.4-1.2,0.8-1.7c0.4-0.6,0.8-1.1,1.3-1.6c0.5-0.5,1-1.3,1-1.3c0,0,0-0.2-0.2-0.5c-0.2-0.3-0.4-0.6-0.7-0.9
		c-0.3-0.3-0.6-0.7-0.9-1.1c-0.4-0.5-0.6-1.2-0.7-1.8c0-0.6,0.2-1.2,0.6-1.7c0.3-0.4,0.6-0.7,0.9-0.9l0.8-0.7l0.4-0.3
		c0.1-0.1,0.2-0.2,0.2-0.2c0.5-0.7,0.5-1.8,0-2.5c-0.1-0.2-0.2-0.3-0.5-0.7c-0.1-0.3-0.2-0.7-0.2-1c0-0.5,0.1-1,0.2-1.5
		c0.2-0.6,0.5-1.3,0.8-1.8c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.4c0.2-0.2,0.5-0.5,0.7-0.8c0.2-0.3,0.4-0.7,0.6-1.1
		c0.1-0.2,0.1-0.4,0.1-0.6c0-0.1,0-0.2-0.1-0.3c-0.4-0.5-0.7-1-1-1.5c-0.4-0.7-0.6-1.4-0.7-2.2c0-0.2,0-0.5,0-0.7
		c0-0.3,0.1-0.5,0.2-0.8c0.2-0.4,0.4-0.8,0.7-1.1c0.5-0.6,1-1.2,1.6-1.7c0.5-0.4,0.9-0.8,1.3-1.3c0.1-0.1,0.1-0.3,0.1-0.4
		c-0.1-0.3-0.1-0.5-0.3-0.8l-0.4-0.9c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.1-0.4-0.1-0.6c-0.1-0.8,0.1-1.5,0.6-2.2
		c0.6-0.8,1.4-1.6,2.2-2.2c0.5-0.2,0.7-0.8,0.6-1.3c-0.2-0.6-0.4-1.2-0.7-1.7c-0.1-0.2-0.2-0.5-0.2-0.7c0-0.3,0-0.6,0.1-0.9
		c0.2-0.6,0.6-1.1,1-1.5c0.4-0.4,0.9-0.8,1.4-1.2c0.6-0.5,0.8-0.7,0.8-1c0-0.5-0.2-1.1-0.4-1.6c-0.1-0.2-0.1-0.4-0.2-0.6l-0.1-0.3
		c0-0.1,0-0.3,0-0.5c0-0.6,0.2-1.1,0.5-1.6c0.5-0.8,1.2-1.6,2-2.3l0.5-0.4l0.2-0.2l0.1-0.1c0.2-0.2,0.3-0.4,0.3-0.6
		c0.1-0.7,0.1-1.3,0-2c0-0.4,0-0.8,0-1.2c0.1-0.4,0.2-0.8,0.5-1.2c0.4-0.5,0.9-0.9,1.5-1.2l0.2-0.1c0.1,0,0.1-0.1,0.1-0.1
		c0.1-0.1,0.2-0.3,0.3-0.4c0.3-0.6,0.5-0.9,0.6-0.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0.1-0.1,0.1
		c-0.1,0-0.3-0.3-0.4-0.8c-0.1-0.4-0.1-0.7-0.1-1.1c0-0.6,0.2-1.1,0.6-1.5c-0.1-0.1-0.1-0.2-0.2-0.4c-0.3-0.9-0.3-1.9,0-2.9
		c0.3-0.7,0.7-1.3,1.2-1.7c0.6-0.5,1.2-0.9,1.9-1.2c0.9-0.5,1.6-1.7,2.4-3.2c0.2-0.4,0.4-0.7,0.7-1c0.4-0.4,0.8-0.6,1.3-0.7
		c0.7-0.1,1.4-0.1,2.1,0h0.8c0.2,0,0.5,0,0.7,0c0.4-0.1,0.8-0.2,1.2-0.5c0.8-0.5,1.4-1.1,2.1-1.5c0.6-0.3,1.2-0.4,1.8-0.4h1.7
		c0.8,0.1,1.6,0,2.3-0.3c0.4-0.2,0.8-0.5,1.2-0.8c0.5-0.4,1.2-0.7,1.8-0.9c0.8-0.1,1.6,0,2.3,0.3c0.5,0.2,1,0.2,1.5,0.2
		c0.3,0,0.5,0,0.9-0.1c0.4,0,0.7-0.1,1.1-0.1c0.7,0,1.5,0.1,2.1,0.4c0.6,0.2,1.2,0.6,1.8,1.1l0.2,0.2l0.1,0.1
		c0.1,0.1,0.1,0.1,0.2,0.1c0.2,0.1,0.4,0.2,0.6,0.3c0.8,0.3,1.6,0.5,2.4,0.6c0.2,0.1,0.4,0.1,0.7,0.1c0-0.9,0.4-3.4,1.3-6.8
		c1.6-5.6,3.7-11.1,6.3-16.3c0.3-0.7,0.6-1.3,1-2c-0.8,0.9-1.5,1.8-2.1,2.7c-1,1.4-1.8,2.8-2.6,4.3c-0.3,0.5-0.5,1.1-1,2.3
		c-0.2,0.4-0.3,0.8-0.4,1.2c-0.6,2-1,3.4-1.2,4.1l-0.1,0l-1.3,4.8l0.4-5.1c0.7-5.2,1.8-10.2,3.3-15.2c2.3-7.5,5.4-14.8,9.1-21.8
		l0.4-0.8c0.2-0.3,0.4-0.8,0.7-1.3c0.2-0.4,0.6-0.8,1-1.1c0.3-0.1,0.5,0.2,0.6,0.4c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.2,0.1,0.4,0.2,0.5
		c0.1,0.4,0.1,0.9,0.2,1.4l0.1,1.1c0,0.3,0.1,0.7,0.1,1.1c0.1-0.5,0.2-1,0.3-1.6c0.3-1.6,0.7-3.3,0.9-4.8c0.1-0.8,0.2-1.5,0.2-2.2
		c0-0.3,0-0.7,0-1L133.3,151.9C133.3,151.9,133.3,151.9,133.3,151.9z M51,233.3c0.4-0.5,0.8-1,1.2-1.5c-0.3-1.1-0.3-4.1-0.1-7.6
		c0.1-2.2,0.2-4.2,0.3-5.6s0.3-2.4,0.5-2.4c0.3,0,0.7,0.8,0.9,2.3c0.3,1.9,0.4,3.8,0.3,5.8c-0.1,2.6-0.5,4.9-0.9,6.3
		c0.1-0.1,0.3-0.3,0.4-0.4c0.8-0.7,1.2-1,1.3-0.9c0.1,0.1-0.1,0.6-0.6,1.4c-0.5,0.8-1.2,2-2.1,3.5c-1.8,2.9-4.3,6.9-7,11.3
		c-5.3,8.9-9.5,16.3-10,16s2.8-8.1,8.2-17.1C45.8,240.5,48.3,236.8,51,233.3z M35.8,242.1c1.9-3.9,4.1-7.5,6.7-11
		c0.8-1.1,1.6-2.1,2.5-3c0.6-0.7,1-1.1,1.1-1c0.1,0.1-0.1,0.6-0.5,1.4c-0.4,0.8-1.1,1.9-1.9,3.4c-1.6,2.8-3.8,6.7-6,11.1
		c-3.8,7.6-6.4,14.2-7.4,15.8c-0.1,3.5-0.4,5.6-0.7,5.6c-0.6,0-0.9-10.2-2-22.8c-0.6-6.3-1.4-11.9-2.1-15.9c-0.4-2-0.7-3.7-0.9-4.8
		c-0.2-1.1-0.3-1.8-0.2-1.8c0.1,0,0.4,0.5,0.9,1.6c0.6,1.5,1.1,3.1,1.6,4.7c1.3,5.3,2.3,10.6,2.7,16.1c0.5,5.6,0.7,10.7,0.6,14.7
		C31.1,253,32.9,247.8,35.8,242.1z M39.1,193.4c0.1-0.1,0.4,0.3,0.9,0.9c0.6,0.9,1.2,1.9,1.6,2.9c1.5,3.3,2.7,6.8,3.5,10.3
		c2,8.3,1.9,15.3,1.3,15.3c-0.6,0-1.4-6.7-3.4-14.8c-1-4-2-7.7-2.9-10.3c-0.4-1.3-0.8-2.4-1-3.1S39,193.5,39.1,193.4z M26.4,173.8
		c0.2-0.2,2.1,2.7,4.2,7.7c2.7,6.5,4.7,13.3,6,20.2c1.4,6.9,2.1,13.9,2.3,20.9l0.1,1.9v1.7c0,1.1,0,2-0.1,2.7
		c-0.1,1.5-0.2,2.3-0.4,2.3c-0.7,0-0.8-13.2-4-29.2c-1.3-6.8-3.1-13.5-5.3-20C27.5,177,26.2,174,26.4,173.8z M24.2,182.3
		c0.5-0.2,4.4,9.2,6.8,21.5c2.4,12.3,2.5,22.5,2,22.5c-0.6,0-1.5-10-4-22.1C26.4,192.1,23.6,182.4,24.2,182.3z M12.1,185.8
		c0.4-0.3,4.1,4.2,6.9,10.6c0,0,0,0,0,0c-0.6-3.8-1-9.1-1-14.9c0.1-11.8,2-21.2,2.6-21.1c0.7,0.1-0.6,9.5-0.5,21.1
		c0,8.8,0.6,16.3,0.6,19.5c0.3,0.9,0.5,1.8,0.7,2.7c0.8-5,2.2-8.9,2.8-8.7c0.6,0.2,0,4.3-0.7,9.3c-0.7,5.1-1.3,9.1-1.9,9.1
		c-0.4,0-0.7-2.6-0.5-6.1c-0.2-0.5-0.4-1.2-0.7-1.9c-0.8-2.1-1.8-5-3.2-8.1C14.4,191,11.7,186.2,12.1,185.8z M20.6,280.6
		c-0.1,1.5-0.2,3-0.4,4.4c-0.2,1-0.4,1.6-0.5,1.6c-0.1,0-0.3-0.6-0.3-1.6c-0.1-1-0.2-2.5-0.3-4.4c-0.2-3.7-0.5-8.8-1-14.5
		c-1-11.3-2.4-20.4-1.9-20.5c0.6-0.1,2.9,8.9,4,20.3C20.7,270.8,20.8,275.7,20.6,280.6z M19.2,231.3c-2.5-8.5-6.1-16.6-10.7-24.2
		c-3.6-5.8-6.6-8.8-6.4-9c0.1-0.1,0.9,0.6,2.3,1.9c1.9,1.9,3.7,4,5.3,6.3c4,5.6,8.4,14.3,11.5,24.4c2.6,8.6,4.4,17.4,5.4,26.4
		c0.7,6.9,0.6,11.1,0.3,11.1c-0.3,0-0.7-4.2-1.8-10.9C23.7,248.5,21.7,239.8,19.2,231.3z M36.6,272.5c-2.6,5.3-4.8,10.2-6.3,13.8
		c-0.6,1.5-1.1,2.7-1.6,3.6c-0.3,3.7-0.6,7.1-0.8,10c-0.2,3.6-0.3,6.4-0.3,8.4c0,2,0,3.1-0.1,3.1c-0.1,0-0.5-1.1-0.7-3.1
		c-0.3-2.8-0.4-5.7-0.4-8.5c0.1-5.2,0.4-10.3,0.8-15.5c-0.3,1.4-0.6,2.8-0.8,4.2c-0.8,4.8-1.4,9.1-1.9,12.8
		c-1,7.3-1.4,11.8-1.7,11.8s-0.3-4.6,0.2-12c0.3-3.7,0.8-8.1,1.5-12.9c0.8-5.3,2-10.5,3.6-15.6l0.4-1.1l3.2-9.6l-1.2,10
		c-0.5,4.5-1,8.9-1.4,12.9c1.6-4.6,3.4-9.1,5.5-13.4c5.3-10.8,11.1-18.7,11.6-18.4C46.8,253.6,41.8,261.8,36.6,272.5z M67,241.9
		c-2.4,4.6-5.6,11.2-9,19c0,0,0,0,0,0.1l0,0c0,0.1-0.1,0.1-0.1,0.2l-3.2,7.4l1.3-7.9c0.4-2.6,1.1-5.1,1.8-7.7c0.5-2,1.1-4,1.7-6
		c-3.3,5.7-6.2,11.6-8.8,17.6c-2.9,6.5-4.5,10.5-4.7,10.4c-0.3-0.1,0.9-4.3,3.5-10.9c1.3-3.3,2.9-7.3,5-11.6
		c2.3-4.7,4.8-9.3,7.7-13.8l4-6.1l-2.1,7c-0.6,2-1.2,4-1.8,6c-0.6,2-1.1,3.9-1.7,5.8c1.7-3.4,3.6-6.7,5.5-10c2.7-4.5,4.7-7,4.8-6.9
		C71,234.6,69.5,237.3,67,241.9z M63.9,228.2c-7.7,9-13.1,16.9-13.6,16.6c-0.5-0.3,4.2-8.9,12-18c7.8-9.1,15.6-15,15.9-14.6
		C78.6,212.7,71.5,219.2,63.9,228.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M2,399.6c1.2-0.3,3.8-0.6,7.5-1.2c2.5-0.4,5.5-0.9,8.8-1.6c3.5-0.7,6.9-1.8,10.1-3.4c1.7-0.8,3.4-1.7,5-2.5
		c1.6-0.9,3.4-1.5,5.2-1.9c1.6-0.2,3.3-0.4,4.9-0.4c1.5,0,2.9,0,4.2,0c4.2,0.1,7,0.4,8,0.7c0-0.6,0.1-1.1,0.1-1.7
		c0.2-1,0.6-2,1.1-2.9c0.3-0.5,0.7-1,1.1-1.5c0.2-0.2,0.4-0.5,0.7-0.7l0.2-0.2l0.1-0.1v-0.1c-0.1-0.5-0.2-1-0.2-1.6
		c-0.1-1.2-0.1-2.4,0.2-3.5c-2.2,0.4-4.2,0.9-5.7,1.2c-1,0.2-1.7,0.3-2.3,0.3c-0.2,0.2-0.5,0.4-0.8,0.6c-0.5,0.4-1.1,0.7-1.8,0.9
		c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0-0.5,0-0.7-0.1c-0.4-0.2-0.8-0.4-1.1-0.7c-0.6-0.5-1-1.1-1.4-1.8c-0.8-1.3-1.5-2.7-2.1-4.2l-1.8-4.7
		c-0.6-1.7-1.2-3.5-1.6-5.3c-0.3-1.5-0.5-3-0.6-4.5c-0.1-1.4,0-2.9,0.3-4.3c0.5-2.5,1.1-4.9,2-7.2c0.7-1.8,1.4-3.6,2.3-5.4
		c0.4-0.7,0.8-1.4,1.1-1.9c0.2-0.5,0.5-0.9,0.7-1.4c0.8-2.2,1.6-4.5,2.1-6.8c-0.3-0.2-0.6-0.4-0.9-0.7l-0.4-0.4l-0.3-0.3
		c-1.1-1-1.8-2.3-1.9-3.8c0-1.3,0.5-2.5,1.4-3.4c1-1,2.3-1.8,3.6-2.3c0.1-0.1,0.3-0.1,0.4-0.2c0.2-0.7,0.3-1.5,0.5-2.3
		c-0.4,0.1-1,0.1-1.6,0.1c-0.7,0-1.6,0-2.6-0.1l-0.8,0c-0.2,0-0.4,0-0.6,0c-0.5,0.1-1,0.2-1.4,0.3c-1.6,0.5-3.2,0.9-4.9,1.1
		c-1,0.1-2.1,0-3.1-0.2c-1-0.1-4.3,0.1-7.8-0.3c-0.9-0.1-1.7-0.2-2.5-0.2c-0.4,0-0.7,0-1.1,0c-0.2,0-0.3,0-0.5,0s-0.4,0.1-0.6,0.1
		c-1,0.1-2,0.1-3.1,0.1c-2.6,0.5-5.3,0.6-8,0.4c-0.6-0.1-1.1-0.1-1.7-0.3c-0.4-0.1-0.9-0.1-1.3-0.1c-0.8,0-1.6,0.1-2.4,0.2
		c-1.5,0.2-2.3,0.4-2.5,0.1c0,0,0,0,0-0.1c-0.3,0.1-0.7,0.1-1,0.2V399.6z M26.9,381.1c-0.2,0.4-0.5,0.8-0.9,1.1
		c-0.5,0.4-1.1,0.6-1.6,0.8c-1.3,0.2-2.6,0.1-3.8-0.3c-0.2,0-0.4,0-0.6,0.1h-0.4c-0.1,0-0.2,0.1-0.3,0.1c-0.5,0.3-1,0.6-1.5,1
		c-0.4,0.3-0.9,0.5-1.4,0.6c-0.5,0.1-0.9,0-1.4-0.2c-0.6-0.3-1.1-0.6-1.3-0.6c-0.2,0-0.8,0-1.4,0c-0.4,0-0.7-0.1-1.1-0.1
		c-0.4-0.1-0.9-0.3-1.3-0.5c-0.8-0.5-1.4-1-2.1-1.6c-0.5-0.5-0.7-0.8-0.7-1.1s0.6-0.4,1.4-0.3c0.3,0.1,0.5,0.2,0.8,0.3c0,0,0,0,0,0
		c0.3-0.7,0.7-1.3,1.2-1.9l0.3-0.3L11,378l0.4-0.6c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.3,0.5-0.4,0.9-0.5h0.2h0c0-0.2,0-0.2,0,0h0
		l0.3-0.2c0.3-0.2,0.6-0.3,1-0.5c0.1-0.3,0.2-0.5,0.2-0.5c0.1,0,0.3,0.1,0.4,0.2c0,0,0.1,0,0.1,0l0.6-0.2c0.2-0.1,0.5-0.1,0.7-0.1
		c0.4,0,0.8,0,1.2,0l2,0.2c0.7,0,1.4,0,2.1,0.2c0.4,0.2,0.8,0.4,1.1,0.8c0.2,0.3,0.4,0.6,0.5,1c0.2,0.5,0.3,1,0.4,1.5
		c0.1,1,0.2,1.7,0.2,2.3c0.1,0,0.2-0.1,0.4-0.1c0.4-0.1,0.7-0.1,1-0.3c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.5-0.3,0.8-0.4
		c0.3-0.1,0.5,0,0.5,0.2C27.2,380.4,27.1,380.8,26.9,381.1z M9,334.1c-0.1-0.2-0.2-0.6-0.2-1c0-0.3,0.2-0.7,0.4-0.9
		c0.1-0.2,0.3-0.4,0.5-0.7c0.2-0.3,0.5-0.6,0.8-0.8c0.4-0.4,1-0.5,1.5-0.5l2.2,0.2l1,0.1c0.4,0,0.9,0.1,1.3,0.2
		c0.4,0.1,0.7,0.3,1.1,0.5c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.2,0.4,0.3,0.5,0.6c0.1,0.2,0.2,0.5,0.3,0.8c0,0.2,0.1,0.4,0.1,0.6
		c0,0.4,0,0.7-0.1,1c-0.1,0.5-0.5,0.8-0.6,0.7c-0.1-0.1-0.1-0.4-0.3-0.7c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1-0.1-0.1-0.3-0.2-0.4
		c-0.1-0.1-0.1-0.2-0.2-0.3l-0.4-0.2c-0.3-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.5-0.1-0.8-0.1c-0.4,0-0.8,0-1.2,0c-0.8-0.1-1.6-0.2-2.3-0.3
		c0,0-0.1,0-0.3,0.1c-0.2,0.1-0.4,0.3-0.6,0.4l-0.6,0.5c-0.1,0.1-0.2,0.1-0.3,0.3c-0.2,0.3-0.3,0.6-0.3,0.8l3.6,0.7
		c1.6,0.3,3.2,0.5,4.9,0.3c0.8-0.1,1.7-0.2,2.5-0.4c0.2,0,0.4-0.1,0.7-0.1c0.2,0,0.5-0.1,0.7-0.1c0.5,0,0.9,0,1.3-0.1
		c1.6,0,3.1,0,4.3,0.1c2.4,0.1,3.9,0.3,3.9,0.6c0,0.3-1.5,0.6-3.9,0.9l-4.2,0.4l-1.2,0.1l-0.5,0.1l-0.6,0.2
		c-0.9,0.2-1.9,0.4-2.8,0.5c-1,0.1-1.9,0.1-2.9,0c-0.9-0.1-1.7-0.3-2.6-0.5c-1.6-0.4-3-0.8-4.1-1.2c-2.3-0.8-3.7-1.5-3.6-1.8
		C5.3,333.5,6.7,333.7,9,334.1z"
          />
          <ellipse class="st3" cx="193.8" cy="150.5" rx="32.3" ry="1" />
          <ellipse class="st3" cx="249" cy="163.7" rx="46.5" ry="1" />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st3"
            d="M483,114.7c-1.3,0.2-2.3,1.3-3.5,1.6c-0.7,0.1-1.3,0-1.9-0.3c-0.9-0.4-1.9-0.6-2.9-0.5
		c-1.1,0.1-2.1,0.3-3.1,0.7c-1,0.4-2,0.8-3,1.3c-1,0.5-2,0.9-3.1,1.3c-1,0.4-2.1,0.5-3.2,0.4c-0.5-0.1-1-0.2-1.5-0.4
		c-0.5-0.3-1-0.6-1.5-0.9c-1.1-0.9-2.5-1.6-3.9-2c-1.6-0.3-3.2-0.1-4.6,0.6c-1.4,0.5-2.7,1.2-3.9,1.6c-1.2,0.5-2.4,0.6-3.7,0.4
		c-0.2,0-0.4-0.1-0.6-0.2c-0.5-0.3-0.9-0.6-1.2-1l-1.5-1.5c0,1,0,2.4,0,3.4l0,0.4c0,0.1,0,0.3,0,0.3l-0.1,0.3
		c-0.1,0.2-0.1,0.4-0.2,0.6c-0.3,0.8-0.8,1.6-1.4,2.2l-0.2,0.2l-0.1,0c-0.4-0.2-0.8-0.3-1.3-0.4c-1-0.1-2,0-3,0.2
		c-1.8,0.5-3.4,1.1-4.9,1.5c-1.3,0.4-2.7,0.4-4-0.1c-1.2-0.5-2.2-1.5-3.5-1.9c-0.7-0.2-1.4-0.1-2,0.3c-0.5,0.3-0.9,0.7-1.2,1.2
		c-0.3,0.4-0.5,0.7-0.9,1.1l-0.4,0.5l-0.5,0.4c-0.3,0.2-0.6,0.4-0.9,0.6c-0.2,0.2-0.5,0.3-0.8,0.4c-1.2,0.4-2.4,0.6-3.6,0.5
		c-1.9-0.1-3.7-0.9-5.1-2.1c-0.9-0.8-1.5-1.8-1.9-2.9c-0.2-0.7-0.5-1.4-0.8-2.1c-0.4-0.8-1.1-1.4-2-1.8c-0.9-0.3-1.8-0.4-2.7-0.3
		c-0.9,0.1-1.8,0.4-2.6,0.7c-0.4,0.2-0.8,0.4-1.3,0.6l-0.2,0.1l-0.1-0.1l-1.2-0.9c-0.7-0.5-1.4-1-2.1-1.5l-0.3,1l-0.2,0.8
		c0,0.1-0.1,0.3-0.2,0.4l-0.1,0.2c-0.4,0.6-0.9,1.2-1.5,1.6c-0.9,0.6-1.8,1.2-2.8,1.6c-1.1,0.5-2.3,1-3.5,1.4
		c-0.7,0.2-1.4,0.3-2.1,0.2c-0.6-0.1-1.1-0.2-1.6-0.5c-0.3-0.2-0.6-0.5-0.9-0.8l0-0.1c0,0,0-0.1,0-0.1c-0.1-0.2-0.1-0.3-0.2-0.5
		l-0.2-0.6c-0.6,0-1.3,0-1.9,0.2c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0.1-0.4,0.2-0.5,0.3c-0.6,0.4-1.1,1-1.4,1.7
		c-0.1,0.3-0.2,0.6-0.3,0.8l0,0.4c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0l-0.6,0.2l-0.7,0.2l-0.3,0.1h-0.1l-0.2,0.1c-0.1,0,0,0,0,0v-0.2
		c0,0,0-0.1-0.1-0.4c-0.1-0.3-0.2-0.5-0.3-0.7c-0.1-0.2-0.3-0.4-0.4-0.6c-0.6-0.7-1.3-1.2-2.1-1.5c-1.4-0.6-2.9-0.8-4.4-0.6
		c-0.7,0.1-1.4,0.2-2,0.4c-0.6,0.2-1.1,0.4-1.7,0.7l-2.8,1.3c-1.4,0.7-2.9,1-4.4,1.1c-1,0-2-0.2-2.9-0.6c-1.7-0.9-3.6-1.6-5.5-2
		c-3.1-0.5-6.4,0.2-9.1,1.8c-0.7,0.5-1.4,0.8-2.2,1.1c-0.8,0.1-1.6-0.1-2.3-0.4l-5.7-2.1c-1-0.3-1.9-0.7-2.8-1.1
		c-0.3-0.2-0.6-0.4-0.8-0.7c-0.2-0.4-0.3-0.8-0.3-1.3c-0.1-0.7-0.3-1.4-0.6-2c-0.4-0.7-1-1.3-1.8-1.5c-1.4-0.5-2.9-0.4-4.2,0.3
		c-1.3,0.8-2.5,1.8-3.5,3c-0.8,0.9-1.9,1.7-3,2.3c-2.3,1-4.7,1.4-7.2,1.4l-13.7,0.1h-0.6v0.6v0.6v0.4v0.2c0,0.2,0,0.4-0.1,0.5
		c0,0.2-0.1,0.4-0.2,0.5c-0.3,0.7-0.7,1.3-1.2,1.8c-0.9,0.9-1.9,1.6-3,2.1c-1.8,1-3,1.4-2.9,1.6c0,0.1,1.2,0,3.2-0.8
		c1.2-0.5,2.4-1.1,3.4-2c0.7-0.6,1.2-1.3,1.6-2.1c0.1-0.2,0.2-0.5,0.3-0.7c0.1-0.2,0.1-0.5,0.2-0.8c0-0.1,0-0.2,0-0.3v-0.1h0.1h0.2
		h0.7h1.5l3.2,0.1c2.2,0,4.6,0.1,7.1,0.1c2.8,0.1,5.5-0.4,8.1-1.5c1.3-0.7,2.6-1.6,3.6-2.7c0.8-1,1.8-1.9,2.9-2.5
		c0.8-0.4,1.7-0.5,2.5-0.2c0.7,0.3,0.9,0.8,1.1,1.9c0.1,0.7,0.3,1.3,0.5,1.9c0.4,0.6,0.9,1.2,1.6,1.5c1,0.5,2.1,0.9,3.2,1.2l5.8,1.9
		c1,0.4,2.1,0.5,3.2,0.3c0.9-0.3,1.8-0.8,2.6-1.4c2.4-1.7,5.3-2.4,8.2-2.1c1.9,0.3,3.7,0.9,5.4,1.8c1,0.4,2,0.7,3.1,0.8
		c1.6,0,3.3-0.3,4.8-0.9l2.9-1.1c1.1-0.5,2.2-0.8,3.3-0.9c1.3-0.1,2.6,0.1,3.7,0.7c0.6,0.3,1.1,0.7,1.5,1.2c0.1,0.1,0.2,0.3,0.3,0.4
		c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.3,0.1,0.6,0.1,0.9v1.3c0.1,0,0.2,0,0.3,0l1.4-0.3l1.4-0.3l2.2-0.5c0-0.3,0-0.5,0-0.8
		c0-0.1,0-0.3,0-0.5v-0.2c0-0.1,0.1-0.3,0.2-0.4c0.2-0.4,0.5-0.7,0.8-0.9l0.3-0.2c0.1,0,0.3-0.1,0.2-0.1c0,0,0,0,0.1,0l0.2,0.3
		c0.4,0.6,0.9,1.1,1.5,1.5c0.8,0.5,1.6,0.8,2.5,0.9c1,0.1,1.9,0,2.8-0.3c1.3-0.4,2.6-0.9,3.8-1.5c1.1-0.5,2.2-1.2,3.2-1.9
		c0.8-0.6,1.5-1.4,2-2.3l0.1-0.3v-0.2c0,0,0.1,0.1,0.2,0.1l1.1,0.8c0.3,0.2,0.7,0.5,1,0.7l1-0.6c0.4-0.2,0.8-0.4,1.1-0.6
		c1.3-0.7,2.8-0.9,4.2-0.6c0.6,0.2,1,0.6,1.3,1.1c0.3,0.6,0.6,1.2,0.8,1.8c0.5,1.3,1.3,2.4,2.4,3.3c1.6,1.3,3.7,2,5.8,2.1
		c1.3,0.1,2.7-0.2,3.9-0.7c0.3-0.1,0.6-0.2,0.9-0.4c0.4-0.2,0.7-0.4,1-0.6l0.5-0.4l0.5-0.5c0.3-0.3,0.7-0.7,1-1.1
		c0.3-0.4,0.6-0.7,1-0.9c0.4-0.2,0.9-0.2,1.3-0.1c1,0.3,1.9,1.3,3.2,2c1.5,0.7,3.2,0.9,4.8,0.4c1.6-0.4,3.2-1,4.9-1.3
		c0.8-0.2,1.6-0.2,2.4-0.1c0.2,0,0.4,0.1,0.5,0.2c0.3,0.1,0.6,0.2,0.8,0.4l0.5,0.3c0.2,0,0.3-0.3,0.5-0.4c0.2-0.1,0.3-0.3,0.5-0.4
		c0.1-0.1,0.2-0.2,0.3-0.3c0.8-0.8,1.4-1.8,1.8-2.8c0.1-0.3,0.2-0.5,0.3-0.8l0.1-0.6c0,0,0,0,0,0c0.3,0.1,0.7,0.3,1,0.3
		c1.6,0.3,3.3,0.1,4.8-0.4c1.5-0.5,2.7-1.2,4-1.7c1.1-0.5,2.3-0.7,3.5-0.5c1.2,0.3,2.2,0.9,3.1,1.7c0.6,0.4,1.2,0.8,1.8,1.1
		c0.7,0.3,1.4,0.5,2.1,0.6c1.4,0.1,2.7-0.1,4-0.6c1.1-0.4,2.2-0.9,3.2-1.5c1-0.5,1.9-1,2.8-1.4c0.8-0.4,1.7-0.6,2.6-0.7
		c0.8-0.1,1.6,0,2.4,0.3c0.4,0.2,0.9,0.3,1.3,0.4c0.5,0,1,0,1.4-0.2c0.7-0.3,1.3-0.7,1.9-1.2c0.4-0.3,0.9-0.6,1.4-0.8
		c0.7-0.2,1.4-0.1,2,0.3c0.3,0.3,0.3,0.7,0.4,0.7s0.3-0.3,0-0.9C484.9,114.8,483.9,114.5,483,114.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st3"
            d="M77,141.9c-14.1,0-25.5,0.5-25.5,1.1s11.4,1,25.5,1s25.5-0.5,25.5-1S91.1,141.9,77,141.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st3"
            d="M436.3,140.8c-11.2,0-20.2,0.5-20.2,1c0,0.5,9,1,20.2,1c11.2,0,20.2-0.4,20.2-1
		C456.5,141.3,447.5,140.8,436.3,140.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M248.4,276.8c0,0.2,0.1,0.4,0.1,0.6l0.2,1c0.1,1.1,0.5,2.2,1.3,3c0.6,0.5,1.1,1.2,1.4,1.9
		c0.2,0.6,0.4,1.3,0.4,1.9c0.1,1.1-0.1,2,0,2.7c0.1,0.6-0.1,0.4,0.5,1.1c0.6,0.5,1.1,1.1,1.6,1.8c0.5,0.7,0.7,1.5,0.8,2.3
		c0,0.2,0,0.3-0.1,0.5c0.5-0.2,1.1-0.4,1.6-0.7c0.8-0.3,1.6-0.7,2.3-1c-0.5-6.2-0.6-12.4-0.2-18.6c0.4-5,1.2-8.1,1.5-8s0,3.1,0,8.1
		c0,4.6,0.2,10.7,0.8,17.6c1.2-0.5,2.3-1,3.3-1.5l0.8-0.4c0.3-0.1,0.6-0.3,1-0.4c0.5-0.2,1.1-0.3,1.6-0.4c0.8-0.1,1.7-0.2,2.5-0.2
		c0.1,0,0.1,0,0.1,0c0.2-0.3,0.5-0.8,0.9-1.4c0.8-1.2,1.9-2.9,3.1-5.1c2.1-3.8,4.7-8.9,7.5-14.6c-2,2.1-4.2,4.1-6.5,5.9
		c-3.4,2.6-5.8,3.7-5.9,3.5c-0.1-0.2,1.9-1.7,5-4.5c0.3-0.2,0.5-0.5,0.7-0.7c0.2-0.4,0.4-0.9,0.7-1.6c0.8-1.7,2-4.3,3.3-7.5
		c1.1-2.7,2.4-5.9,3.8-9.5c-2,3.5-4.2,6.9-6.5,10.2c-4.1,5.8-7.3,9-7.6,8.8c-0.2-0.2,1.4-2.2,3.8-5.7c0.2-0.7,0.4-1.6,0.7-2.7
		c0.4-1.4,1-3.1,1.7-5.1c0.7-2,1.6-4.2,2.6-6.7c3.8-8.9,8.2-17.6,13-26c-4.7,6.1-8.8,12.5-12.4,19.3c-3.4,6.4-6.3,13-8.7,19.8
		c-2.1,5.8-3.4,10.6-4.3,14c-0.8,3.3-1.2,5.2-1.4,5.2c-0.2,0,0-2,0.6-5.4c0.9-4.8,2.1-9.6,3.7-14.3c1.8-5.5,3.9-10.8,6.3-16
		c0.5-1.8,1.3-4.2,2.5-7.1c2.2-5.6,4.9-11,8.1-16.1c-3.4,3.6-6.4,7.5-9.1,11.7c-3.5,5.4-5.3,9-5.5,8.9c-0.3-0.1,1-4,4.3-9.6
		c0.9-1.7,2-3.3,3-4.8c0.8-1.7,2-3.9,3.5-6.5c1.9-3.5,4.4-7.7,7.4-12.4c-5.5,6.6-10.5,13.7-15,21c-2.2,3.6-4,6.6-5.1,8.5
		c-0.1,0.5-0.2,0.9-0.3,1.4c-1.1,4.7-2.7,9.3-4.9,13.7c-1.7,3.3-3,5.3-3.2,5.1c-0.6-0.3,3.5-8.5,6-19.3c1.1-4.6,1.6-9.3,1.6-14
		c-0.1-3.6-0.5-5.8-0.3-5.9c0.2-0.1,0.8,1.1,1.3,3.3c0.3-0.7,0.8-1.9,1.5-3.4c1.3-2.8,3.3-6.7,6-11.5c4-7.2,9.3-16.2,15.8-26.1
		c-5.8,6.8-11.1,13.9-16,21.4c-2.9,4.4-5.1,8-6.7,10.5c-1.5,2.5-2.4,3.9-2.5,3.8s0.5-1.6,1.8-4.2c0.2-0.5,0.5-1,0.8-1.5
		c0.1-0.6,0.2-1.5,0.3-2.6c0.1-0.6,0.2-1.3,0.3-2l0.2-2.4c0.1-0.8,0.1-1.8,0.2-2.7v-3.1c0.1-5-0.6-10-1.9-14.9
		c-0.2-0.7-0.4-1.4-0.6-2.1c-0.2-0.7-0.5-1.4-0.8-2c-0.3-0.6-0.5-1.4-0.9-2c-0.3-0.7-0.6-1.4-1-2c0,0,0,0,0-0.1l0.1,0.3
		c0.5,1.5,0.9,3,1.2,4.5l0.5,2.2c0.2,0.7,0.2,1.5,0.3,2.2c1.2,8.5,1,17.2-0.7,25.6c0,0.2-0.1,0.3-0.1,0.5c0.2,2.9,0.1,5.9-0.5,8.8
		c-0.3,1.4-0.6,2.8-1,4.1c-0.4,1.3-0.8,2.5-1.3,3.7c-0.5,1.1-1,2.2-1.4,3.2l-1.5,2.7l-0.7,1.1l-0.7,1c-0.4,0.6-0.8,1.1-1.2,1.5
		c-0.2,0.3-0.4,0.5-0.6,0.6c0.1,0.9,0.2,1.9,0.3,2.8c0.4,4.4,0.3,8.8-0.2,13.1c-0.8,7.3-2.3,11.6-2.5,11.5
		c-0.2-0.1,0.7-4.4,1.2-11.6c0.3-4.3,0.2-8.6-0.2-12.8c-0.2-2.1-0.6-4.2-1-6.3c0.1,2.1,0.1,4.2,0,6.3c0,0.5-0.1,1.1-0.1,1.6v0.4
		l-0.1,0.7l-0.2,1.5c-0.1,1-0.3,2-0.5,3c-0.2,1-0.3,1.8-0.5,2.8c-0.4-1.2-0.8-2.4-1.1-3.5c-0.3-0.9-0.6-1.9-1-2.8l-0.5-1l-0.3-0.6
		c-0.1-0.2-0.3-0.5-0.4-0.7c0,0.2,0.1,0.3,0.2,0.5c0.4,1.7,0.8,3.4,1,5.2c0.3,2.8,0.4,5.7,0.3,8.5c-0.1,1.8-0.2,3.6-0.5,5.5
		c0,0,0,0.1,0,0.1c0.1,0.2,0.2,0.4,0.2,0.7c1.1,3.9,1.5,6.4,1.2,6.4s-1-2.3-2.5-6c-0.7-1.8-1.6-4-2.7-6.3c0.8,3.1,1.3,6.3,1.6,9.6
		l0.3,6.1C248.7,276.3,248.6,276.4,248.4,276.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M484.4,108c-1.3,0.3-2.8,0.6-4.5,1.2c-2.1,0.6-4.2,1-6.4,1c-2.3,0.1-5,0-7.6,0c-2.9,0.1-5.7,0.4-8.5,1
		l-4.6,0.9c-1.7,0.4-3.5,0.5-5.2,0c-3.4-0.6-6.7-1.3-10.2-2l-8.3-1.7c-2.6-0.6-5.2-1-7.8-1.2c-4.6-0.2-9.3,0.8-13.5,2.7
		c-2,0.8-3.8,1.7-5.6,2.4c-1.7,0.6-3.4,1-5.1,1.1c-2.4,0.2-4.8,0.2-7.3,0c-1.7-0.1-4.1-0.2-7.2-0.5c-3.1-0.3-6.9-0.4-11.2-0.6
		l-14.3-0.7c-2.7-0.2-5.4-0.5-8.1-1c-2.7-0.5-5.5-1-8.3-1.6c-5.6-1.2-11.1-2.5-16.1-3.3c-4.9-0.8-9.6,0.4-13.5,2
		c-2,0.8-3.8,1.7-5.4,2.5l-2.4,1.3c-0.8,0.4-1.5,0.7-2.3,1c-2.3,0.7-4.7,1.2-7.1,1.4c-1.6,0.2-4.1,0.5-7.2,0.6
		c-3.7,0.1-7.4,0.5-11,1.1c-4.6,1-9.1,2.3-13.6,4l-1.8,0.7c-0.3,0.1-0.7,0.3-1.1,0.3c-0.4,0-0.7,0.1-1.1,0.1
		c-1.4,0.1-2.9-0.1-4.3-0.3c-2.8-0.5-5.5-1.2-8.3-1.7c-1.6-0.3-3.3-0.5-5-0.5c-1.6,0.1-3.1,0.5-4.5,1.2c-2.7,1.6-5.2,3.5-7.3,5.8
		c-0.5,0.5-1.1,1.1-1.6,1.6c-0.3,0.3-0.5,0.5-0.8,0.8c-0.3,0.3-0.6,0.5-1,0.8c-1.3,0.8-2.8,1.3-4.4,1.4c-2.6,0.2-5.2,0-7.8-0.4
		c-1-0.2-2.1-0.3-3.2-0.3c-0.9,0.1-1.9,0.2-2.8,0.5c-1.8,0.4-3.3,0.9-4.5,1.3c-2,0.6-3.3,0.9-3.8,0.9c0,0,0,0,0,0
		c-1.7,0.5-6.7,0.2-8.5,0.5c-1.8,0.4-5-1.3-6.4-2.6c-1.3-1.2-5.4-1.8-7.2-1.9c0,0,0.1,0.1,0.1,0.1c0,0.1-0.5,0.3-1.4,0.3
		c-1.3,0.1-2.7-0.1-3.9-0.4c-0.9-0.3-1.8-0.7-2.6-1.3c-0.4-0.3-0.8-0.7-1.1-1.1c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.1-0.5-0.1
		c-2-0.1-4.4-0.3-6.8-0.3c-2.2,0.1-4.5,0.3-6.7,0.8c-1.9,0.4-3.8,0.6-5.8,0.5c-1.3-0.1-2.6-0.3-3.8-0.5c-0.2,0-0.3-0.1-0.5-0.1
		c-0.2,1.5-0.3,3.1-0.5,4.6c-0.1,0.6-2.1,0.3-2.1-0.2c0.6-5.1,1.1-10.1,1.7-15s1.2-9.5,1.4-13.9c0.6-8.8-1.6-16.6-2.8-23.3
		c-1.2-6.7-1.7-12.3-1.2-16.1c0.1-0.8,0.2-1.7,0.4-2.5c0.1-0.4,0.1-0.7,0.2-1l0.3-0.8c-1.1,0.4-2.2,0.9-3.2,1.5
		c0,0.8-0.1,2.5-0.1,4.9c0,5,0.2,10,0.6,14.9c0.5,6.3,1.5,13.8,2.1,22.2s0.8,17.8-1.5,27.4c-0.1,0.6-2.1-0.1-2-0.5
		c0.3-1.1,0.5-2.2,0.7-3.3c-0.4,0.2-1,0.5-1.7,0.7c-2.4,0.8-5,1.1-7.5,0.9c-2.5-0.2-5-0.7-7.3-1.6c-0.9-0.3-1.5-0.6-2-0.9
		c0,3.9,0,6.2-0.2,6.2c-0.3,0-0.9-3.8-1.3-9.9c-0.2-3.1-0.3-6.7-0.3-10.8c0-2,0-4.2,0.1-6.4c0-1.2,0.1-2.3,0.3-3.5
		c0.2-1.3,0.7-2.6,1.6-3.6c0.1-0.1,0.3,0,0.5,0.2l-0.1-0.5l-0.1-0.5c-0.1-0.1,0-0.1-0.1-0.4c0-0.3-0.1-0.6-0.1-0.9
		c-0.1-0.7-0.1-1.4-0.1-2.1c0-0.8,0.1-1.6,0.3-2.4c0.1-0.7,0.5-2,0.4-1.7c0,0,0,0-0.1,0l-2.3,1c-0.7,0.3-1.4,0.6-2.1,1
		c1.7,9.4,1.4,19-0.9,28.3c-0.7,2.9-1.6,5.7-2.8,8.5c-0.8,1.9-1.4,2.9-1.5,2.9c-0.3-0.1,0.7-2.7,1.9-7.1c-0.5-0.1-1.2-0.4-2.1-0.6
		c-2.5-0.7-5.2-1.1-7.8-1.1c-1.8,0-3.6,0.2-5.4,0.6c-2.2,0.6-4.4,0.8-6.7,0.6c-2.4-0.5-4.7-1.4-6.9-2.6c-2.2-1.1-4.4-2.1-6.8-2.1
		c-2.3,0-4.8,0.9-7.4,1.2c-2.4,0.3-4.9,0.3-7.3,0c-4.7-0.6-8.5-2.3-11.7-3.5c-2.5-1-5-1.7-7.7-2.1c-0.9-0.1-1.7-0.2-2.2-0.2
		c-0.5,1-1,2.1-1.5,3.1c-1.1,2.3-2,4.4-2.9,6.3c0.5-0.7,1.1-1.5,1.8-2.3c0.5-0.6,1.1-1.2,1.7-1.7c0.7-0.6,1.5-1.2,2.4-1.5
		c0.6-0.2,1.2-0.3,1.8-0.2c0.7,0.1,1.2,0.5,1.6,1.1c0.6,0.9,1.1,2,1.3,3.1c0.4,2.2,0.6,4.4,0.5,6.6c0,2.1-0.1,4.2-0.3,6.4
		c14.2-0.3,37.6-0.4,64-0.4c35.1,0,64.8,0.7,74.8,0.7c10,0,39.7-0.7,74.8-0.7s64.8,0.7,74.8,0.7s39.7-0.7,74.8-0.7
		c43.3,0,78.4,0.5,78.4,1c0,0.6-35.1,1-78.4,1c-35.1,0-64.8-0.7-74.8-0.7s-39.7,0.7-74.8,0.7c-35.1,0-64.8-0.7-74.8-0.7
		c-10,0-39.7,0.7-74.8,0.7c-26.5,0-49.9-0.2-64.1-0.4l-1,8.6l-1-8.6c-1.6,0-3.1-0.1-4.5-0.1l-3,4.8c-1.8,2.7-3.4,5.5-4.8,8.4
		c-2.8,5.9-4.9,12.1-6.4,18.4c1,0.8,3.1,2.8,5.6,5.7c1.1,1.3,2.3,2.7,3.3,4c0-0.2-0.1-0.5-0.1-0.7c-0.2-1.5-0.1-3.1,0.3-4.6
		c0.5-1.8,1.1-3.5,2-5.2c0.7-1.4,1.5-2.6,2.5-3.8c0.9-1,1.8-1.9,2.8-2.8c1.3-1,2.7-2,4.1-2.8c1.4-1,2.8-1.8,4.4-2.5
		c1.3-0.5,2.6-0.7,4-0.8c1.6,0.1,3.2,0.5,4.6,1.4c2.5,1.5,4.6,3.5,6.2,5.9c0.9,1.4,1.7,2.9,2.3,4.5c0.7,1.5,1.3,3.1,1.7,4.8
		c0.6,2.8,0.8,5.6,0.4,8.4c-0.1,1-0.3,1.9-0.5,2.8s-0.4,1.7-0.6,2.5c-0.4,1.6-0.7,3-1,4.2c-0.3,1.5-0.6,2.7-0.8,3.3
		c1.2,1,1.9,1.9,1.7,2.1c-0.3,0.5-3-0.9-6.2-2.7c-0.9-0.5-1.7-1-2.4-1.5c0.1,0.2,0.3,0.5,0.3,0.7c0.2,0.4,0.2,0.9,0.1,1.3
		c-0.1,0.5-0.3,0.9-0.6,1.3c-0.6,0.7-1.3,1.3-2.1,1.7c-1.7,0.7-3.6,1.1-5.5,1.1c-1.9,0-3.8-0.4-5.5-1.3c-0.8-0.4-1.5-1-2-1.7
		c0-0.1-0.1-0.1-0.1-0.2c3.5,6.6,5.7,13.1,6.5,18c0,0.1,0,0.3,0.1,0.4c1.6-2.2,7.3-8.3,15.2-14.5c4-3.2,8.3-6.1,12.9-8.7l2.4-1.2
		c0.7-0.3,1.3-0.6,1.8-0.8c2.8-3.7,5.7-7.2,8.9-10.6c4.2-4.5,9-8.6,14.1-12l7.4-4.8l-6.2,6.3c-0.1,0.1-0.1,0.1-0.2,0.2l0,0
		c0,0,0,0,0,0c-6.4,6.6-11.7,12.8-15.6,17.5c0.5-0.5,1.1-1.1,1.6-1.6c1.1-1.1,2.4-2.2,3.7-3.3c0.4-0.3,0.7-0.6,1.1-0.9l1.1-0.8
		l2.2-1.6c0.4-0.3,0.8-0.5,1.1-0.7l1.1-0.7l2.3-1.4l1.1,1.6c-2,1.7-3.8,3.5-5.6,5.1c-1.8,1.7-3.4,3.3-4.9,4.9
		c-1.5,1.6-3,3.1-4.3,4.6s-2.5,2.8-3.5,4.1c-1,1.3-1.9,2.4-2.7,3.5c-0.1,0.2-0.3,0.4-0.4,0.5c3.9-4.2,8-8.2,12.4-12l1.4,1.4
		c-1.9,2.3-3.8,4.7-5.7,7.2c-2.3,3.1-4.5,6.1-6.4,9.2c-0.9,1.4-1.7,2.8-2.5,4.2c3.4-3.5,6.4-6.3,8.8-8.4c2.2-1.9,3.9-3.2,4.7-3.7
		c0.8-2.3,1.6-4.7,2.3-7.1l0.5-1.8c0.1-0.2,0.2-0.9,0.3-1.4l0.3-1.6c0.1-0.8,0.3-1.7,0.7-2.4l0.3-1v-0.1c0,0,0.1,0.1,0.1,0.1
		l0.2,0.3l0.3,0.5l0.5,1c0.2,0.3,0.4,0.7,0.5,1.1c0.3,0.9,0.6,1.8,0.8,2.7c0.2,0.9,0.4,1.9,0.5,2.8c0.1,1.5,0.2,2.7,0.2,3.6
		c1.2-3.2,2.6-6.9,3.9-11c1.4-4.5,2.7-9.6,4-14.9c0.2-0.8,0.4-1.5,0.5-2.3l0.5-2l0.2-1c0.1-0.3,0.2-0.7,0.2-0.9l0.9-2.8
		c0.2,0.4,0.4,1,0.5,1.5c0.2,0.5,0.3,1,0.5,1.5c0.2,0.5,0.3,1,0.5,1.6l0.4,1.8c0.1,0.3,0.2,0.6,0.2,0.9l0.1,0.5l0.2,0.9
		c0.2,1.3,0.3,2.5,0.4,3.7c0.2,2.1,0.2,4.2,0.1,6.3c2-8.8,4.8-17.4,8.3-25.7c0.9-2.1,1.8-4.1,2.7-6.1l2.7-5.7l1.8,0.7
		c-2.3,7.1-4.3,13.2-5.7,17.5c-0.3,0.8-0.5,1.6-0.7,2.3c0.6-0.9,1.3-1.8,2-2.6c0.5-0.5,1-1,1.6-1.5l0.5-0.3c0.2-0.1,0.5-0.3,0.7-0.4
		c0.3-0.2,0.7-0.2,1-0.2c0.3,0,0.6,0.2,0.9,0.4c0.2,0.2,0.4,0.5,0.5,0.9c0.1,0.2,0.1,0.3,0.1,0.5l0.1,0.4c0,0.4,0.1,0.9,0.1,1.3
		c0,0.8-0.1,1.6-0.2,2.5c-0.2,1.7-0.5,3.3-0.9,5c-0.6,2.8-1.3,5.5-2.3,8.2l-1.8,4l0.2-4.3c0-0.1,0-0.2,0-0.3l-0.1,0
		c0-0.1,0.1-0.4,0.1-0.7c0.1-1.7,0.1-3.2,0.2-4.6v-2.3v-1.5c0-0.2,0-0.4,0-0.5c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.1
		c-0.2,0.2-0.4,0.4-0.5,0.6c-0.1,0.2-0.3,0.4-0.4,0.6c-0.2,0.3-0.3,0.5-0.4,0.8c-1.5,2.8-4.5,11.6-7.7,22.1c-0.7,2.4-1.4,4.8-2,6.9
		c0.4-0.9,1.1-2.4,2.2-4.3c1.6-2.9,3.6-5.4,6-7.7c0,0,0,0,0,0l0,0c0.1-0.1,0.2-0.2,0.3-0.3l2.6-2.4l-1.3,3.5c-0.3,0.7-2,4-3.7,7.5
		c-2.5,5.2-4.8,10.5-6.8,15.9c-1.2,3.3-2,5.6-2.4,6.4c0,0,0,0,0,0c0.3,0.1,0.5,0.2,0.7,0.4c0.5,0.4,0.9,0.8,1.3,1.3
		c0.2,0.3,0.5,0.7,0.7,0.7c0.4,0.1,0.8,0.1,1.2,0.2c0.5,0,1,0.1,1.6,0.2c0.3,0.1,0.6,0.1,0.9,0.2c0.2,0.1,0.4,0.1,0.5,0.2
		c0.2,0.1,0.4,0.2,0.6,0.4c0.5,0.5,0.9,0.9,1.3,1.4c0.4,0.4,0.8,0.8,1.3,1.2c0.5,0.3,0.9,0.7,1.3,1c0.1-1.2,0.2-3.2,0.4-5.8
		c0.1-2.3,0.3-5,0.3-8.2c0-3.5-0.2-7.1-0.8-10.6l1.6-0.4c1.2,3.9,2,7.9,2.7,12c0.4,2.5,0.7,5.1,0.8,7.6c0.8-2.4,1.4-4.8,1.9-7.3
		c2-9.6,2.6-19.5,1.7-29.3l1.8-0.3c1.8,7.3,2.8,14.8,3,22.3c0.2-1.2,0.4-2.6,0.7-4.1c0.6-3,1.4-6.6,2.6-10.6
		c1.2-4.3,2.8-8.6,4.7-12.6l3.3-6.9l-1.4,7.5l-0.5,2.5c-1.8,9.9-3.3,18.9-4.5,25.5c-0.1,0.6-0.2,1.2-0.3,1.8
		c0.6-1.8,1.5-4.1,2.6-6.8c1.7-4.1,3.6-8.2,5.7-12.1c2.6-4.8,5.6-9.4,9-13.7c0,0,0,0,0,0l0,0c0.1-0.1,0.1-0.2,0.2-0.3l4.8-5.9
		l-3.1,7c-1,2.3-2,4.6-3,6.9c-5.4,12.5-10,24-13.1,32.4c-0.4,1-0.7,2-1.1,3c0.6-1.2,1.2-2.3,1.8-3.5c2.3-4.4,4.9-8.7,7.8-12.8
		c2.4-3.4,4.4-5.9,5.3-7.2c0.7-0.8,1.5-2.3,4.6-6c0,0,0-0.1,0.1-0.1l0,0c0,0,0.1-0.1,0.1-0.1l2-2.4l-1.2,3c-0.4,1-1.8,3.8-3.5,7.4
		c2-2.4,4.2-4.6,6.5-6.7c0.6-0.6,1.7-1.6,3.3-3.1c2.3-2,4.8-3.7,7.5-5.2l3.1-1.7l-2.2,2.9c-0.6,0.7-3.6,4-6.7,7.6
		c-3.8,4.4-7.2,9-10.3,14c-1.3,2.2-2.2,4-2.8,5.1c0.3,0,0.6,0.1,0.9,0.2c0.4,0.1,0.9,0.1,1.4,0.1c0.4-0.1,0.7-0.3,1-0.5
		c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.3,0.6-0.5,1-0.6c0.8-0.2,1.6-0.2,2.4-0.1c0.5,0,0.9-0.1,0.9-0.2c0,0,0.3-0.3,0.5-0.5
		c0.4-0.3,0.8-0.6,1.3-0.7c0.5-0.1,1.1-0.1,1.6,0.1c0.3,0.1,0.5,0.2,0.8,0.3c0.5,0,0.9,0,1.4-0.2c0.2-0.1,0.4-0.2,0.6-0.3h0.1
		l0.2-0.1l0.3-0.2c0.3-0.1,0.5-0.3,0.8-0.4c0.6-0.2,1.3-0.3,2-0.2c0.8,0.1,1.5,0.5,2.1,1c0.3,0,0.5,0,0.8,0l0.5-0.1l0.8-0.1
		c0.7-0.1,1.4,0,2,0.5c0.3,0.2,0.5,0.5,0.7,0.7c0.1,0.1,0.2,0.2,0.2,0.3l0.1,0.2c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0.1,0.1,0.1,0.1
		c0.1,0,0.3,0,0.4,0c0.3,0,0.6,0,0.9,0c0.7-0.1,1.3-0.1,2,0c0.7,0.1,1.4,0.4,1.8,1c0.7,0.9,0.9,1.5,1.2,1.8c0.2,0.2,0.4,0.3,0.6,0.4
		c0.3,0.1,0.6,0.1,0.9,0c0.5-0.1,1-0.2,1.6-0.3c0.8-0.1,1.5,0,2.3,0.2c0.5,0.2,0.9,0.6,1.2,1c0.2,0.3,0.5,0.7,0.7,1
		c0.1,0.1,0.2,0.3,0.2,0.3c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0,0.2,0h0.4l0.4,0.1c0.3,0.1,0.5,0.2,0.8,0.3c0.5,0.2,1,0.4,1.4,0.8
		c0.5,0.3,0.9,0.8,1.3,1.2c0.3,0.3,0.4,0.7,0.5,1.1l0.1,0.6v0.1l0.2,0.1l0.3,0.1c0.1,0,0.2,0.2,0.3,0.2c0.4,0.2,0.8,0.6,1,1
		c0.3,0.6,0.5,1.3,0.7,1.9c0.1,0.5,0.2,0.9,0.3,1.1c0.1,0.2,0.1,0.3,0.2,0.4c0.3,0.2,0.7,0.6,1,0.9c0.6,0.6,1.1,1.3,1.6,2
		c0.2,0.3,0.4,0.7,0.5,1c0.1,0.3,0.3,0.7,0.3,1c0.1,0.5,0.2,1,0.2,1.6c0,0.2,0,0.3,0,0.5c0.1,0,0.2,0,0.3,0c0.1,0.4,0.3,0.9,0.4,1.3
		l0.2,0.8l0.1,0.8c0.1,0.6,0.2,1.3,0.2,1.9v0.6c0,0.3-0.1,0.5-0.1,0.8c0,0,0,0,0,0c0.6,0.1,1.1,0.4,1.5,0.8c0.6,0.6,1,1.3,1.2,2.1
		c0.2,0.7,0.3,1.4,0.5,2c0.1,0.4,0.4,0.8,0.8,1c0.1,0.1,0.2,0.1,0.4,0.2c0.2,0.1,0.3,0.1,0.6,0.2c0.6,0.3,1.1,0.6,1.6,1
		c1.1,0.9,1.8,2.2,2,3.5c0,0.3,0.1,0.5,0.1,0.7c0.1,0.2,0.2,0.3,0.4,0.5c0.4,0.5,1,1,1.6,1.7c0.3,0.3,0.5,0.7,0.8,1.1
		c0.2,0.4,0.4,0.8,0.5,1.3c0.1,0.7,0.2,1.4,0.3,2.1c0,0.5,0.1,0.9,0.2,1.4c0.1,0.3,0.3,0.6,0.5,0.7c0.6,0.4,1.2,0.8,1.8,1.3
		c0.6,0.4,1.2,0.9,1.7,1.4c0.4,0.4,0.8,0.8,1.1,1.2c0.2,0.3,0.4,0.5,0.5,0.8c0.2,0.3,0.3,0.7,0.4,1c0.1,0.6,0.3,1.2,0.5,1.7
		c0.2,0.4,0.4,0.8,0.8,1c0.2,0.1,0.4,0.3,0.7,0.4c0.1,0.1,0.3,0.1,0.4,0.2l0.2,0.1c0.1,0,0.3,0.1,0.4,0.2c0.6,0.3,1.1,0.7,1.5,1.1
		c0.4,0.5,0.7,1,0.9,1.6c0.3,0.9,0.4,1.8,0.5,2.7c0,0.5,0.1,1,0.3,1.5c0,0.1,0.1,0.2,0.2,0.3c0,0,0.1,0.1,0.2,0.2l0.3,0.2
		c0.2,0.1,0.3,0.3,0.5,0.4c0.6,0.6,1.1,1.2,1.4,2c0.5,1.1,0.7,2.3,0.6,3.5c0,0.5-0.1,0.9-0.1,1.2c0,0.1,0,0.3,0,0.4
		c0,0.1,0,0.2,0,0.3c0.2,0.6,0.5,1.1,1,1.5c0.8,0.7,1.6,1.3,2.4,1.8c0.3,0.2,0.5,0.4,0.8,0.6c0.2,0.2,0.4,0.4,0.5,0.6
		c0.2,0.2,0.3,0.5,0.4,0.8c0.1,0.2,0.1,0.4,0.1,0.7c0.3-0.4,0.3-0.5,0.3-0.9l-2-5.7c-0.9-2.6-2-5.1-3.3-7.5
		c-0.9-1.8-1.7-2.7-1.5-2.8c0.1-0.1,1.1,0.5,2.4,2.2c0.5,0.7,1,1.4,1.4,2.1c-0.6-2.8-1.4-5.6-2.3-8.3c-0.4-1.2-0.8-2.4-1.2-3.5
		l-2.7-6.9l4.5,5.9c1.8,2.4,3.3,5.1,4.5,7.9c0.6,1.4,1.2,2.9,1.7,4.4c0-1.1,0-2.4,0-3.9c-0.1-2.8-0.3-5.5-0.7-8.3
		c-0.2-1.7-0.6-3.3-1.1-4.9c-0.2-0.9-0.5-1.7-0.9-2.5c-0.3-0.8-0.7-1.6-1.2-2.3l-2.7-3.7l3.8,2.3c1.6,1,2.9,2.3,3.8,3.8
		c0.3,0.4,0.5,0.8,0.7,1.2l0.4,0.8c0,0.1,0,0.1,0.1,0.1s0-0.1,0-0.1c0.1-0.7,0.1-1.3,0.2-2c0.1-2.2,0.1-4.4,0-6.5
		c-0.1-2.9-0.5-5.7-0.9-8.6c0,0,0-0.1,0-0.1l0,0c0-0.1,0-0.1,0-0.2l-1.5-8.2l3.4,7.5c1.7,3.9,2.9,7.9,3.6,12.1c0,0,0-0.1,0-0.1
		c0.2-0.5,0.5-1,0.8-1.7l0.5-1l0.5-1.2c0.4-0.8,0.8-1.7,1.2-2.7c0.4-1,0.8-2,1.2-3.2c0.4-1.1,0.7-2.3,1.1-3.6
		c0.4-1.2,0.6-2.6,0.9-3.9c0.8-4.6,0.9-9.3,0.2-13.9c-0.5-3.6-1.3-5.7-1-5.8c0.3-0.1,1.5,1.8,2.5,5.5c0.2,0.8,0.3,1.6,0.5,2.4
		c0.2-2.1,0.4-4.6,0.5-7.5c0.2-5-0.2-10.1-1-15.1c-0.1-0.7-0.2-1.4-0.4-2.1c-0.1-0.7-0.3-1.4-0.5-2.1c-0.3-1.5-0.7-2.9-1.2-4.4
		l-0.3-1.1l-0.4-1.1c-0.3-0.7-0.6-1.5-0.9-2.2c-0.3-0.7-0.6-1.5-1-2.1l-0.5-1.1l-0.2-0.4c-0.1-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0.1-0.1
		l0-0.1l-0.1-0.1v-0.1c0.7-0.6-1.6,1.3,1.6-1.3c1.2,1.2,2.3,2.5,3.2,3.9c0.5,0.7,0.9,1.4,1.4,2.1c0.5,0.7,0.8,1.4,1.2,2.1
		s0.7,1.4,1,2.2c0.3,0.7,0.6,1.4,0.9,2.2c0.3,0.7,0.5,1.4,0.8,2.2c0.2,0.7,0.4,1.4,0.6,2.1c1.3,5,1.8,10.2,1.5,15.4
		c-0.1,1.1-0.1,2.1-0.2,3.1c-0.1,1-0.3,1.9-0.4,2.8c-0.1,0.9-0.2,1.7-0.3,2.4l-0.5,2c-0.1,0.3-0.1,0.5-0.2,0.8
		c1.2-2.2,2.7-4.8,4.5-7.7c7.2-11.8,15.7-22.7,25.3-32.5l0-0.1l0,0c0,0,0.1-0.1,0.1-0.1l1.6,1.3L300,176
		c-9.5,13.7-17.5,26.5-23.1,35.8c-2.8,4.7-5,8.5-6.5,11.2c-1,1.7-1.7,2.9-2.1,3.6c0.1,0.6,0.2,1.2,0.3,1.9c0.3,3.4,0.3,6.9-0.2,10.3
		c0.9-1.8,2-3.9,3.4-6.4c5.3-9.5,11.6-18.3,18.8-26.4c1.4-1.6,3-3.1,4.4-4.6c0.1-0.1,0.2-0.3,0.3-0.4l0.1,0.1c0,0,0.1-0.1,0.1-0.1
		l1.6,1.3c-5.6,8.2-10.4,15.7-14.1,21.4c2.4-2.7,5-5.2,7.7-7.5l1.4,1.5c-0.3,0.4-0.7,0.8-1,1.2c-3.9,5.3-7.4,10.8-10.4,16.7
		c3-4.9,6.4-9.7,10.1-14.2c1-1.3,2.1-2.5,3.2-3.7l3.2-3.6l1.6,1.3c-7.6,11.7-14.3,24-20.3,36.6c-1.1,2.4-2,4.6-2.9,6.5
		c-0.9,1.9-1.5,3.6-2,5c-0.1,0.2-0.2,0.5-0.3,0.7c0.4-0.7,0.9-1.4,1.4-2.1c4.9-7.8,9.3-15.9,13.1-24.3l0.4-0.9l1.9,0.8
		c-3,8.6-6.4,17-10.3,25.2c-1.4,2.9-2.7,5.2-3.7,6.8c3.2-3.1,6.1-6.5,8.6-10.1l1.8,1c-0.6,1.4-1.3,2.8-2,4.3c-0.9,2-1.9,4.1-3,6.1
		c0.2-0.3,0.4-0.6,0.7-0.9l0.5-0.4l0.4-0.2l0.7-0.4c0.4-0.3,0.9-0.5,1.2-0.8c0.6-0.4,1.2-1,1.6-1.7c0.3-0.5,0.5-1.1,0.5-1.6
		c0.1-0.7,0.4-1.4,0.9-2c0.6-0.7,1.3-1.3,2.1-1.8c0.2-0.1,0.4-0.3,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.5c0.2-0.4,0.3-0.7,0.4-1.2
		c0-1.3,0.1-2.6,0.3-3.8c0.1-0.5,0.3-0.9,0.5-1.3c0.2-0.4,0.5-0.7,0.8-1c0.6-0.5,1.1-1.1,1.6-1.5c0.5-0.4,0.9-0.9,1.2-1.5
		c0.4-0.6,0.7-1.2,0.9-1.9c0.2-0.7,0.3-1.3,0.5-2.3c0.2-1,0.6-2,1.1-2.9c0.1-0.2,0.3-0.4,0.4-0.6l0.2-0.3l0.1-0.2
		c0.2-0.3,0.3-0.5,0.4-0.8c0.2-0.7,0.3-1.4,0.4-2.2c0.1-0.5,0.1-1.1,0.2-1.8c0.1-0.8,0.5-1.6,1-2.3c0.2-0.2,0.3-0.4,0.5-0.5l0.1-0.1
		c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.3,0.3-0.7,0.3-1c0.1-0.5,0.2-0.9,0.3-1.5c0.1-0.6,0.3-1.3,0.5-1.9c0.1-0.2,0.2-0.4,0.3-0.6l0.2-0.3
		l0.1-0.1c0.1-0.1,0.1-0.1,0.1-0.2c0.1-0.3,0.3-0.7,0.3-1.1c0.2-1,0.5-2,0.9-3c0.1-0.3,0.3-0.6,0.5-0.8c0.1-0.1,0.2-0.3,0.3-0.4
		l0.2-0.2c0.1-0.1,0.2-0.3,0.2-0.5c0.1-0.7,0.2-1.5,0.2-2.2c0-0.5,0.2-1,0.3-1.5c0.1-0.3,0.3-0.6,0.5-0.8c0.1-0.1,0.2-0.2,0.3-0.3
		l0.2-0.1h0.1c0.7-0.7,1.1-1.6,1-2.6c0-0.5,0-0.9-0.1-1.4c-0.1-0.5-0.2-0.9-0.3-1.4c-0.1-0.5-0.1-1.1,0.1-1.6
		c-0.3-0.3-0.5-0.6-0.7-1c-0.2-0.5-0.4-1-0.5-1.6c-0.1-0.5-0.1-1,0-1.4c0.1-0.5,0.2-0.9,0.4-1.3c0.1-0.2,0.2-0.4,0.3-0.6
		c0-0.1,0.1-0.2,0.2-0.3l0.1-0.1l0-0.1l0.1-0.2l0.1-0.1c0.1,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0-0.2-0.1-0.3-0.1-0.5
		c-0.1-0.3-0.1-0.7-0.1-1c0-0.3,0-0.7,0-1c0-0.2,0-0.3,0.1-0.5c0-0.1,0.1-0.4,0.1-0.4c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.1-0.1-0.2
		s-0.2-0.5-0.3-0.8c-0.1-0.3-0.2-0.6-0.2-0.9c-0.1-0.5-0.1-1,0-1.4c0,0,0,0,0,0c-0.5-0.4-0.9-0.8-1.3-1.3c-0.2-0.3-0.4-0.6-0.6-1
		c-0.2-0.5-0.3-0.9-0.3-1.4c0.1-0.5,0.1-1,0.1-1.5c0.1-0.2-0.8-1.3-1.3-2.8c-0.4-0.9-0.4-1.9-0.1-2.9c0.1-0.3,0.2-0.5,0.4-0.8
		c0.1-0.1,0.2-0.2,0.3-0.3l0.1-0.2c0,0,0.1-0.1,0.1-0.2c0.1-0.5,0.1-1,0.1-1.5c0-0.8,0.1-1.6,0.3-2.4c0.1-0.5,0.3-0.9,0.6-1.3
		c0.2-0.4,0.5-0.7,0.7-1c0.5-0.6,1-1.1,1.4-1.5c0.3-0.3,0.5-0.6,0.7-1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.2l-0.1-1
		c-0.2-1.3-0.1-2.6,0.2-3.9c0.2-0.8,0.5-1.5,1-2.1c0.3-0.3,0.5-0.6,0.9-0.9l0.6-0.5c1.5-1.2,2.8-2.6,3.9-4.1
		c0.9-1.2,1.1-3.4,2.6-5.3c0.4-0.5,0.9-0.9,1.5-1.2c0.3-0.1,0.6-0.3,0.9-0.4l0.6-0.2c0.8-0.2,1.5-0.5,2.2-0.8
		c0.6-0.3,1.2-0.7,1.7-1.2c0.3-0.3,0.7-0.6,1-0.8c0.3-0.2,0.7-0.5,1.1-0.6l1-0.5l0.4-0.2c0.1-0.1,0.2-0.2,0.3-0.3
		c0.5-0.4,0.9-0.8,1.3-1.3c1.6-1.8,2.8-3.3,4.3-3.9c0.6-0.2,1.4-0.5,2.3-0.8c0.4-0.2,0.9-0.3,1.4-0.5c0.5-0.2,0.9-0.4,1.3-0.8
		c0,0,0,0,0.1-0.1l0,0V144v-0.1l0.1-0.2c0.1-0.1,0.1-0.2,0.2-0.3c0.3-0.3,0.7-0.6,1.1-0.8c0.7-0.3,1.3-0.5,2-0.8
		c0.2-0.1,0.4-0.2,0.6-0.4c0.2-0.2,0.4-0.5,0.6-0.8c0.5-0.8,1.2-1.5,2.1-2c0.5-0.3,1.1-0.4,1.8-0.4c0.5,0,1.1,0.1,1.6,0.2
		c0.9,0.3,1.8,0.6,2.7,1c0.3,0.1,0.5,0.2,0.8,0.2h0.1l0.3-0.1l0.6-0.2c1-0.4,2.1-0.6,3.3-0.8c0.4,0,0.7,0,1.1,0.1
		c0.4,0.1,0.6,0.1,0.9,0.2c0.5,0.1,1,0.3,1.4,0.4c0.7,0.2,1.5,0.1,2.2-0.2l1.4-0.6l1.6-0.6c1.1-0.4,2.1-0.7,3.3-1.1
		c1.2-0.4,2.3-0.6,3.5-0.8c0.4-0.1,0.7-0.1,1.1,0h0.9c0.6,0,1.1,0.1,1.7,0.1c1.1,0.1,2.2,0.3,3.2,0.5c0.5,0.1,1.1,0.2,1.6,0.3
		c0.5,0.1,1.1,0.3,1.5,0.5c0.3,0.1,0.7,0.2,1,0.2c0.4,0,0.7-0.1,1.1-0.2l0.7-0.2c0.3-0.1,0.5-0.1,0.8-0.2c0.6-0.1,1.2-0.2,1.7-0.1
		c1,0.1,2.1,0.4,3,0.8c0.7,0.3,1.5,0.5,2.3,0.7c0.8,0.2,1.6,0.3,2.5,0.5c0.4,0.1,0.8,0.3,1.2,0.4c0.4,0.2,0.9,0.4,1.2,0.7
		c0.6,0.6,1.1,1.2,1.4,2c0.1,0.2,0.2,0.5,0.4,0.7c0.1,0.2,0.2,0.3,0.4,0.3c1.1,0.4,2.1,1.2,2.8,2.1c0.5,0.7,0.9,1.5,1.2,2.3
		c0.2,0.8,0.4,1.7,0.4,2.5c0.1,0,0.2,0,0.4,0.1c0.4,0.1,0.8,0.3,1.2,0.5c0.2,0.2,0.4,0.3,0.6,0.5c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.3
		c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.2,0.2,0.1c0.2,0,0.3,0,0.5,0l0.2,0h0.1c0.1,0,0.2,0.1,0.3,0.1c0.2,0.1,0.4,0.2,0.5,0.3
		c0.3,0.2,0.6,0.5,0.9,0.8c0.3,0.3,0.5,0.6,0.7,1c0.2,0.3,0.4,0.8,0.5,0.9c0,0.1,0.1,0.1,0.1,0.2l0.2,0.1c0.2,0.1,0.4,0.2,0.5,0.3
		c0.2,0.1,0.4,0.3,0.6,0.5c0.4,0.3,0.8,0.7,1.2,1.2c0.8,1,1.3,2.1,1.5,3.4c0.1,0.6,0.1,1.2,0,1.8c0.1,0,0.1,0,0.2,0.1
		c0.7,0.1,1.4,0.4,2,0.9c0.8,0.6,1.5,1.4,2.3,2.1c0.2,0.2,0.4,0.3,0.7,0.5l0.4,0.2l0.5,0.3c0.7,0.5,1.3,1.2,1.7,2
		c0.2,0.4,0.4,0.8,0.5,1s0.3,0.3,0.4,0.3c0,0,0.1,0,0.1,0l0.5,0.2c0.3,0.1,0.5,0.3,0.8,0.5c0.4,0.3,0.8,0.6,1.1,1
		c0.6,0.6,1.2,1.2,1.8,1.8c0.3,0.2,0.6,0.4,0.9,0.5l0.7,0.3c0.3,0.1,0.6,0.3,0.9,0.5c1,0.7,1.8,1.6,2.5,2.5c0.3,0.4,0.6,0.7,0.9,1
		c0.4,0.3,0.8,0.5,1.2,0.8c1,0.7,2,1.6,2.8,2.6c0.3,0.4,0.7,0.8,1.1,1.1c0.1,0.1,0.2,0.2,0.4,0.3l0.7,0.4c0.5,0.3,0.9,0.5,1.3,0.8
		c0.6,0.4,1.1,0.9,1.5,1.5c0.3,0.6,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.3,0.3,0.5c0.2,0.3,0.5,0.7,0.8,1c0.5,0.7,1.1,1.3,1.7,1.9
		c0.3,0.3,0.6,0.6,0.9,0.9l0.4,0.3c0-0.9,0-2,0-3.4c-0.4-4.7-1.4-9.4-3-13.8c0-0.1-0.1-0.1-0.1-0.2l0,0c0,0,0-0.1-0.1-0.1l1.5-0.7
		c1.7,2.8,3.1,5.9,4.1,9c0.5,1.3,0.8,2.7,1.1,4.1c0.1-0.9,0.2-1.7,0.2-2.6c0.3-6.4,0.2-12.8-0.4-19.1l1.9-0.4c0,0.1,0,0.1,0.1,0.2
		l0,0c0,0,0,0.1,0,0.1c2.1,7.2,3,14.7,2.7,22.2c-0.1,2.1-0.3,4.1-0.7,6.2c1.4-2.6,2.9-5.1,4.5-7.5c1.2-1.7,2.5-3.6,4-5.5
		c1.6-2.1,3.3-4,5.2-5.9c0.5-0.5,1.1-1,1.7-1.5c0.4-0.2,0.8-0.4,1.2-0.6c0.6-0.2,1.2-0.2,1.8,0.1c0.5,0.3,0.9,0.7,1.2,1.3
		c0.2,0.4,0.3,0.8,0.4,1.3c0.1,0.8,0.2,1.6,0.2,2.4c-0.1,3.1-0.4,6.2-1,9.3c-0.6,3.1-1.3,6.1-2,9l-2.1,8l0.1-8.3
		c0-2.6,0-5.1-0.2-7.5c-0.1-1.1-0.2-2.3-0.4-3.4l-0.1-0.3c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1,0h-0.1l-0.3,0.3
		c-1.3,1.5-2.5,3.2-3.5,4.9c-1,1.6-1.8,3.2-2.6,4.6c-0.2,0.3-0.3,0.7-0.5,1c0.4-0.5,1-1.1,1.5-1.7l0.5-0.5l0.3-0.3
		c0.1-0.1,0.2-0.1,0.4-0.3c0.6-0.6,1.3-0.9,2.1-1c0.2,0,0.3,0.1,0.5,0.2c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.2,0.1,0.4,0.2,0.6
		c0,0.2,0,0.5,0,0.7c-0.1,0.8-0.2,1.5-0.3,2.3c-0.5,2.5-1.2,5.1-2,7.8c-0.2,0.7-0.4,1.3-0.6,2L463,213l0.6-9c0-0.1,0-0.1,0-0.2l0,0
		l0,0c0.1-1.1,0.1-2.3,0-3.4c0,0,0-0.1,0-0.1c0-0.1-0.1,0-0.1,0c-0.2,0.3-0.4,0.6-0.6,0.9c-0.3,0.4-0.5,0.9-0.7,1.3
		c-0.5,0.8-0.8,1.6-1.2,2.4c-1.1,2.3-1.7,3.9-2,4.6c0.6,0.4,1.1,0.8,1.6,1.4c0.3,0.4,0.6,0.9,0.7,1.4c0.1,0.5,0.3,1,0.3,1.5
		c0.1,1.1,0.4,2.1,0.8,3.1c0,0.1,0.1,0.2,0.2,0.3l0.1,0.1l0.1,0.2c0.2,0.4,0.4,0.7,0.5,1.2c0.2,0.7,0.4,1.4,0.5,2.1
		c0.1,0.7,0.3,1.3,0.5,2c0.1,0.3,0.2,0.6,0.3,0.9l0.3,0.6c0.1,0.2,0.2,0.5,0.3,0.8c0.1,0.6,0.1,1.2,0,1.8c-0.1,0.5-0.3,1-0.6,1.5
		l-0.1,0.2v0c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0.1,0.2,0.2,0.3c0.4,0.6,0.9,1.3,1.4,2.2c0.3,0.5,0.5,1,0.7,1.5
		c0.2,0.6,0.3,1.2,0.3,1.8c0,1-0.1,2-0.3,2.9c0,0.3,0,0.7,0,1c0,0.4,0.1,0.8,0.3,1.2c0.3,1,0.5,2,0.6,3.1c0,0.3,0,0.6,0,0.9
		c0,0.2,0,0.3,0,0.5v0.2c0,0.3,0,0.6,0,0.9c0.1,0.7,0.4,1.4,0.7,2c0.2,0.3,0.3,0.6,0.5,0.9l0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.4
		c0.2,0.2,0.3,0.5,0.4,0.8c0.4,1,0.7,2,0.7,3c0,0.5,0,0.9,0,1.4l0,0.5c0,0.2,0,0.3,0,0.5c0,1.4,0.2,2.8,0.6,4.1
		c0.2,0.7,0.3,1.3,0.5,1.9c0.2,0.7,0.4,1.4,0.5,2.1c0,0.7-0.1,1.4-0.3,2.1c-0.2,0.4-0.3,0.9-0.3,1.3c0,0.8,0.2,1.7,0.5,2.5
		c0.1,0.4,0.3,0.8,0.4,1.1c0.4-0.6,1.4-1.8,2.6-3.4c2.4-3,4.4-6.2,6-9.7c0.4-0.8,0.6-1.6,0.9-2.3l2-5.2v5.5c0,3.4-0.6,6.8-1.7,10
		c-0.4,1-0.7,1.8-1,2.4c0.2-0.1,0.3-0.3,0.5-0.4c2.3-1.8,4.7-2.9,6.1-3.3V107.2C487.5,107.3,486.2,107.6,484.4,108z M485.4,116.5
		c-0.1,0,0-0.3-0.4-0.7c-0.6-0.4-1.3-0.6-2-0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.6,0.5-1.2,0.9-1.9,1.2c-0.4,0.2-0.9,0.2-1.4,0.2
		c-0.5-0.1-0.9-0.2-1.3-0.4c-0.8-0.3-1.6-0.4-2.4-0.3c-0.9,0.1-1.8,0.3-2.6,0.7c-0.9,0.4-1.8,0.9-2.8,1.4c-1,0.6-2.1,1.1-3.2,1.5
		c-1.3,0.5-2.6,0.7-4,0.6c-0.7-0.1-1.5-0.3-2.1-0.6c-0.6-0.3-1.2-0.7-1.8-1.1c-0.9-0.8-2-1.3-3.1-1.7c-1.2-0.2-2.4,0-3.5,0.5
		c-1.3,0.5-2.5,1.1-4,1.7c-1.5,0.6-3.2,0.7-4.8,0.4c-0.3-0.1-0.7-0.2-1-0.3c0,0,0,0,0,0l-0.1,0.6c-0.1,0.3-0.2,0.6-0.3,0.8
		c-0.4,1-1,2-1.8,2.8c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.3,0.4-0.5,0.4l-0.5-0.3
		c-0.3-0.2-0.5-0.3-0.8-0.4c-0.2-0.1-0.4-0.1-0.5-0.2c-0.8-0.1-1.6-0.1-2.4,0.1c-1.6,0.3-3.3,1-4.9,1.3c-1.6,0.4-3.3,0.3-4.8-0.4
		c-1.3-0.8-2.2-1.7-3.2-2c-0.4-0.1-0.9-0.1-1.3,0.1c-0.4,0.2-0.8,0.5-1,0.9c-0.3,0.4-0.6,0.7-1,1.1l-0.5,0.5l-0.5,0.4
		c-0.3,0.2-0.6,0.4-1,0.6c-0.3,0.2-0.6,0.3-0.9,0.4c-1.2,0.5-2.6,0.7-3.9,0.7c-2.1-0.1-4.1-0.8-5.8-2.1c-1.1-0.9-1.9-2-2.4-3.3
		c-0.2-0.6-0.5-1.2-0.8-1.8c-0.3-0.5-0.8-0.9-1.3-1.1c-1.4-0.3-2.9-0.1-4.2,0.6c-0.4,0.2-0.8,0.4-1.1,0.6l-1,0.6
		c-0.3-0.2-0.7-0.4-1-0.7l-1.1-0.8c-0.1,0-0.2-0.1-0.2-0.1v0.2l-0.1,0.3c-0.5,0.9-1.2,1.7-2,2.3c-1,0.8-2,1.4-3.2,1.9
		c-1.2,0.6-2.5,1.1-3.8,1.5c-0.9,0.3-1.9,0.4-2.8,0.3c-0.9-0.1-1.8-0.4-2.5-0.9c-0.6-0.4-1.1-0.9-1.5-1.5l-0.2-0.3c0,0-0.1,0-0.1,0
		c0,0-0.1,0-0.2,0.1l-0.3,0.2c-0.3,0.2-0.6,0.6-0.8,0.9c-0.1,0.1-0.1,0.3-0.2,0.4v0.2c0,0.2,0,0.3,0,0.5c0,0.3,0,0.5,0,0.8l-2.2,0.5
		l-1.4,0.3l-1.4,0.3c-0.1,0-0.2,0-0.3,0v-1.3c0-0.3,0-0.6-0.1-0.9c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.3-0.3-0.4
		c-0.4-0.5-0.9-0.9-1.5-1.2c-1.2-0.5-2.5-0.8-3.7-0.7c-1.1,0.1-2.3,0.4-3.3,0.9l-2.9,1.1c-1.5,0.6-3.1,1-4.8,0.9
		c-1.1-0.1-2.1-0.3-3.1-0.8c-1.7-0.9-3.5-1.5-5.4-1.8c-2.9-0.3-5.8,0.4-8.2,2.1c-0.8,0.6-1.6,1.1-2.6,1.4c-1.1,0.2-2.2,0.1-3.2-0.3
		l-5.8-1.9c-1.1-0.3-2.1-0.7-3.2-1.2c-0.7-0.3-1.2-0.9-1.6-1.5c-0.3-0.6-0.5-1.2-0.5-1.9c-0.2-1.1-0.4-1.6-1.1-1.9
		c-0.8-0.3-1.7-0.2-2.5,0.2c-1.1,0.7-2.1,1.5-2.9,2.5c-1,1.1-2.2,2-3.6,2.7c-2.5,1.1-5.3,1.6-8.1,1.5c-2.5,0-4.9-0.1-7.1-0.1
		l-3.2-0.1h-1.5H288h-0.2h-0.1v0.1c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.5-0.2,0.8c-0.1,0.2-0.2,0.5-0.3,0.7c-0.4,0.8-0.9,1.5-1.6,2.1
		c-1,0.9-2.2,1.5-3.4,2c-2,0.8-3.2,0.9-3.2,0.8c-0.1-0.2,1.1-0.6,2.9-1.6c1.1-0.6,2.1-1.3,3-2.1c0.5-0.5,0.9-1.1,1.2-1.8
		c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.2,0.1-0.4,0.1-0.5V125v-0.4v-0.6v-0.6h0.6l13.7-0.1c2.5,0,4.9-0.5,7.2-1.4c1.1-0.6,2.2-1.3,3-2.3
		c1-1.2,2.2-2.2,3.5-3c1.3-0.7,2.8-0.7,4.2-0.3c0.8,0.3,1.4,0.8,1.8,1.5c0.3,0.6,0.5,1.3,0.6,2c0,0.4,0.1,0.9,0.3,1.3
		c0.2,0.3,0.4,0.6,0.8,0.7c0.9,0.4,1.8,0.8,2.8,1.1l5.7,2.1c0.7,0.3,1.5,0.5,2.3,0.4c0.8-0.2,1.5-0.6,2.2-1.1
		c2.7-1.7,5.9-2.3,9.1-1.8c1.9,0.4,3.8,1.1,5.5,2c0.9,0.4,1.9,0.6,2.9,0.6c1.5,0,3-0.4,4.4-1.1l2.8-1.3c0.5-0.2,1.1-0.5,1.7-0.7
		c0.6-0.2,1.3-0.4,2-0.4c1.5-0.2,3,0.1,4.4,0.6c0.8,0.3,1.6,0.9,2.1,1.5c0.1,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.2,0.4,0.3,0.7
		c0.1,0.3,0.1,0.4,0.1,0.4v0.2c0,0,0,0,0,0l0.2-0.1h0.1l0.3-0.1l0.7-0.2l0.6-0.2c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1l0-0.4
		c0.1-0.3,0.1-0.6,0.3-0.8c0.3-0.7,0.8-1.2,1.4-1.7c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.4-0.2,0.7-0.3c0.6-0.1,1.2-0.2,1.9-0.2
		l0.2,0.6c0.1,0.2,0.1,0.3,0.2,0.5c0,0,0,0.1,0,0.1l0,0.1c0.2,0.3,0.5,0.6,0.9,0.8c0.5,0.3,1.1,0.5,1.6,0.5c0.7,0,1.4,0,2.1-0.2
		c1.2-0.4,2.3-0.8,3.5-1.4c1-0.4,1.9-1,2.8-1.6c0.6-0.4,1.1-1,1.5-1.6l0.1-0.2c0.1-0.1,0.1-0.3,0.2-0.4l0.2-0.8l0.3-1
		c0.7,0.5,1.5,1,2.1,1.5l1.2,0.9l0.1,0.1l0.2-0.1c0.4-0.2,0.8-0.4,1.3-0.6c0.8-0.3,1.7-0.6,2.6-0.7c0.9-0.1,1.9,0,2.7,0.3
		c0.9,0.3,1.6,1,2,1.8c0.3,0.7,0.6,1.4,0.8,2.1c0.4,1.1,1,2.1,1.9,2.9c1.4,1.2,3.2,2,5.1,2.1c1.2,0.1,2.5,0,3.6-0.5
		c0.3-0.1,0.5-0.2,0.8-0.4c0.3-0.2,0.6-0.3,0.9-0.6l0.5-0.4l0.4-0.5c0.3-0.3,0.6-0.7,0.9-1.1c0.3-0.5,0.8-0.9,1.2-1.2
		c0.6-0.3,1.3-0.4,2-0.3c1.4,0.4,2.4,1.4,3.5,1.9c1.3,0.5,2.7,0.5,4,0.1c1.5-0.4,3-1.1,4.9-1.5c1-0.3,2-0.3,3-0.2
		c0.4,0.1,0.9,0.2,1.3,0.4l0.1,0l0.2-0.2c0.6-0.6,1.1-1.4,1.4-2.2c0.1-0.2,0.2-0.4,0.2-0.6l0.1-0.3c0,0,0-0.2,0-0.3l0-0.4
		c0-1,0-2.4,0-3.4l1.5,1.5c0.4,0.4,0.8,0.7,1.2,1c0.2,0.1,0.4,0.2,0.6,0.2c1.2,0.2,2.5,0.1,3.7-0.4c1.2-0.4,2.5-1.1,3.9-1.6
		c1.5-0.6,3.1-0.8,4.6-0.6c1.4,0.4,2.7,1.1,3.9,2c0.5,0.4,1,0.7,1.5,0.9c0.5,0.2,1,0.4,1.5,0.4c1.1,0.1,2.2-0.1,3.2-0.4
		c1-0.4,2.1-0.8,3.1-1.3c1-0.5,2-0.9,3-1.3c1-0.4,2.1-0.6,3.1-0.7c1,0,2,0.2,2.9,0.5c0.6,0.3,1.3,0.4,1.9,0.3
		c1.2-0.4,2.2-1.4,3.5-1.6c0.9-0.2,1.9,0.2,2.5,0.9C485.7,116.2,485.5,116.5,485.4,116.5z M77,144c-14.1,0-25.5-0.5-25.5-1
		s11.4-1.1,25.5-1.1s25.5,0.5,25.5,1.1S91.1,144,77,144z M193.8,151.5c-17.8,0-32.3-0.5-32.3-1s14.4-1,32.3-1
		c17.8,0,32.3,0.5,32.3,1S211.6,151.5,193.8,151.5z M249,164.7c-25.7,0-46.5-0.5-46.5-1s20.8-1,46.5-1c25.7,0,46.5,0.5,46.5,1
		S274.7,164.7,249,164.7z M436.3,142.8c-11.2,0-20.2-0.5-20.2-1c0-0.5,9-1,20.2-1c11.1,0,20.2,0.4,20.2,1
		C456.5,142.4,447.5,142.8,436.3,142.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M397.1,257.8l-0.1-0.3l-0.1-0.3c-0.1-0.2-0.2-0.4-0.3-0.6c-0.4-0.9-0.9-1.7-1.6-2.4c-0.1-0.1-0.1-0.1-0.2-0.2
		l-0.1-0.1l-0.1,0c0,0,0,0,0,0c-0.4-0.3-0.8-0.8-1-1.3c-0.3-0.7-0.5-1.4-0.7-2.1c-0.1-0.7-0.2-1.3-0.3-1.9c-0.1-0.5-0.2-1.1-0.3-1.6
		c0-0.1,0-0.1-0.1-0.2c-0.1-0.2-0.3-0.4-0.5-0.7c-0.5-0.6-0.9-1.2-1.2-1.9c-0.2-0.5-0.4-1-0.5-1.5c-0.1-0.3-0.1-0.5-0.1-0.8l0-0.6
		c0-0.5-0.2-1-0.4-1.4c-0.1-0.2-0.4-0.5-0.6-0.9c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.3-0.5-0.4-0.8c-0.2-0.8-0.4-1.6-0.5-2.5
		c0-0.4-0.1-0.8-0.3-1.2c-0.2-0.3-0.4-0.6-0.5-0.9c-0.1-0.3-0.2-0.6-0.2-0.9c-0.1-0.4-0.1-0.9-0.1-1.3c0-0.6,0-1.1-0.1-1.7
		c0-0.1-0.1-0.3-0.1-0.4c-0.1,0-0.1-0.1-0.1-0.1l-0.3-0.2c-0.8-0.6-1.4-1.5-1.6-2.6c-0.1-0.8-0.2-1.6-0.1-2.4l0.1-0.9v-0.4v-0.2
		c0-0.3-0.2-0.6-0.4-0.8c-0.4-0.4-0.7-0.7-1.1-1c-0.2-0.2-0.5-0.4-0.8-0.6c-0.3-0.3-0.6-0.7-0.8-1.1c-0.5-1.1-0.8-2.2-0.9-3.4
		c-0.2-1-0.2-2-0.3-3c0-0.2,0-0.4-0.1-0.6c-0.2-0.3-0.4-0.5-0.7-0.7c-0.6-0.4-1.1-0.7-1.8-1c-0.2-0.1-0.4-0.2-0.6-0.3
		c-0.2-0.1-0.3-0.2-0.5-0.4c-0.2-0.2-0.5-0.5-0.7-0.8c-0.5-0.7-1-1.6-1.3-2.4c-0.1-0.2-0.1-0.3-0.1-0.5l-0.1,0.1
		c-0.3,0.3-0.6,0.5-0.9,0.7c-0.6,0.4-1.3,0.8-2,1c-0.7,0.2-1.5,0.3-2.2,0.3c-0.4,0-0.7,0-1-0.1c-0.2,0-0.3-0.1-0.5-0.1h-0.1h-0.1
		l-0.1,0.1l-0.3,0.3c-0.2,0.2-0.4,0.3-0.6,0.5c-0.4,0.3-0.8,0.6-1.2,0.8c-0.8,0.4-1.7,0.7-2.6,0.8c-0.8,0.1-1.7,0.2-2.5,0.1
		c-0.4,0-0.8-0.1-1.2-0.1h-0.3h-0.1h-0.1l-0.1,0.1l-0.1,0.1l-0.3,0.2c-0.4,0.2-0.7,0.4-1.1,0.5c-0.7,0.2-1.4,0.4-2.2,0.4
		c-0.7,0-1.3,0-2-0.1l-0.9-0.1l-0.4-0.1c-0.5,0.4-1.1,0.7-1.7,0.9c-0.7,0.3-1.4,0.4-2.1,0.5c-0.6,0.1-1.2,0.1-1.9,0
		c-0.3,0-0.5-0.1-0.8-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.5,0.4-1,0.6-1.6,0.8c-0.4,0.1-0.8,0.1-1.2,0.1c0.2,0.4,0.3,0.8,0.5,1.2
		c0.6,1.5,0.9,3.1,1.1,4.7c0,0.2,0,0.3,0,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.4-0.3,0.8-0.5,1.1c-0.4,0.6-1,1.2-1.7,1.5
		c-1.1,0.6-2.4,0.9-3.6,0.8c-1.2,0-2.4-0.3-3.5-0.8c-0.6-0.3-1.2-0.7-1.6-1.3c-0.3-0.3-0.5-0.6-0.6-1c-0.1-0.2-0.1-0.4-0.2-0.6
		l-0.1-0.4c-0.1-1.5,0-3.1,0.4-4.6c-0.5,0.2-1,0.4-1.6,0.4c-0.4,0-0.9,0-1.3-0.1l-0.5-0.2c-0.1,0-0.1,0-0.2-0.1c0,0-0.1,0-0.1,0
		l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.2,0.1c-0.6,0.4-1.3,0.6-2,0.8c-0.8,0.2-1.7,0.1-2.5-0.1c-0.4-0.1-0.9-0.3-1.2-0.6
		c-0.2-0.1-0.4-0.2-0.6-0.4l-0.1-0.1h0l0,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0.1-0.5,0.2-0.8,0.2c-0.2,0-0.3,0-0.5,0
		c0.1,0.7,0.2,1.7,0.1,2.8c0,1.2-0.2,2.6-0.4,4.1c-0.1,0.7-0.2,1.6-0.3,2.3c0,0.7,0,1.3,0.2,2c0.1,0.2,0.1,0.5,0.2,0.7
		c1-0.6,2.6-1.4,3.8-1.4c1.7,0,4.8,2.9,8.3,3.4c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.6,0.5,0.4,0.8c-1.2,0.6-2.5,0.9-3.9,0.9
		c-3-0.1-5.7-2.9-8.9-3.2c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.3,0.3,0.5,0.4,0.8l0.1,0.2l0.1,0.1l0.2,0.1c0.1,0.1,0.2,0.2,0.3,0.3
		c0.9,1.1,1.8,2.3,2.5,3.5c0.5,0.8,0.8,1.5,1.1,2c0.1-0.4,0.2-0.8,0.3-1.2c0.1-0.1,0.1-0.3,0.3-0.4c0.1-0.1,0.2-0.2,0.3-0.2
		c0.2-0.1,0.3-0.2,0.5-0.3c1.4-0.6,2.3-0.7,2.3-0.6c0,0.1-0.8,0.5-2,1.2c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.1,0.1-0.1,0.1
		s0,0.1-0.1,0.2c-0.1,0.4-0.2,0.8-0.2,1.2c0,0.7,0,1.4,0,2.1c0.6-0.1,1.3-0.1,1.9-0.2h1.2h1.2c1.8,0,3.6-0.5,4.7-0.6
		c-0.2-1.1-0.4-2.4-0.6-3.7c-0.1-1-0.5-1.9-0.8-2.7c0,0,0,0,0,0c-0.1-0.3-0.2-0.4-0.4-0.8s-0.2-0.6-0.4-0.8c-0.6-1-1-1.7-0.8-2.1
		c0.2-0.5,1.3-0.5,2.7,0.4c0.8,0.5,1.5,1.2,2,2c0.7,1,1.1,2.1,1.3,3.3c0.7,4.5-0.6,8-1.6,7.8c-0.4-0.1-0.6-0.6-0.9-1.3
		c-0.1,0.1-0.1,0.1-0.2,0.2c-1.4,1.2-3.2,1.9-5,1.9c-0.5,0-1,0-1.4-0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.3-0.1-0.6-0.1-1-0.2
		c0.1,1,0.2,2.6,0.3,4.5c0,0.9,0.1,1.9,0.1,2.9c0,0.5,0,1,0.1,1.6c0,0.4,0.1,0.9,0.2,1.3v0H327l-0.2,0.1h0.1l0.2-0.1c0,0,0,0,0,0
		v0.1c0,0,0.1,0.1,0.1,0.1l0.2,0.3l0.3,0.3c0.4,0.4,0.8,0.9,1.2,1.3c0.9,0.9,1.8,1.8,2.8,2.8c2.9,2.8,5.6,5.7,8.1,8.8
		c2.1,2.6,3.5,4.8,4.4,5.7c0.4,0.4,0.9,1,1.6,1.6c-0.2-0.3-0.4-0.6-0.6-1c-1.2-2-1.9-3.2-1.6-3.6c0,0,0,0,0,0c0,0,0,0-0.1,0
		c-0.1-0.2,0.1-0.6,0.6-1.1c0.3-0.3,0.6-0.6,1-0.8c0.5-0.4,1.1-0.6,1.8-0.7c0.6-0.1,1.3-0.2,1.9-0.3c0.7-0.1,1.3-0.2,1.9-0.3
		c0.5,0,2.3-1.7,4.6-2c0.5,0,0.9,0,1.4,0c0.4,0,0.7,0.1,1.1,0.1l2,0.3c1.2,0.2,2.4,0.4,3.4,0.5c1.5,0.2,3,0.2,4.5,0
		c0.4-0.1,0.9-0.3,1.3-0.5l0.7-0.3l1-0.5c0.7-0.3,1.6-0.8,2.7-1.4c0.1,0,0.1,0.1,0.1,0.4c0,0.2,0,0.5,0,0.8c0,0.7,0,1.7-0.1,2.9
		l-0.1,0.9v0.5c0,0.4-0.1,0.8-0.1,1.2c-0.1,0.8-0.1,1.7-0.3,2.5c0,0.3-0.1,0.8-0.2,1.3c-0.1,0.4-0.2,0.8-0.4,1.2
		c-0.3,0.7-0.7,1.4-1.2,2.1c-0.8,1.1-1.9,2-3.1,2.6c-1.5,0.7-3.1,1.2-4.7,1.4c-0.8,0.2-2.3,1-4.4,1.6c-1.2,0.4-2.5,0.6-3.8,0.7
		c-0.4,0-0.8,0-1.2,0c0,0,0,0-0.1,0c0.1,0.1,0.1,0.2,0.2,0.3c0.3,0.5,0.5,1,0.7,1.5c0.4,1,0.8,2,1.3,2.8c0.2,0.3,0.4,0.6,0.6,0.9
		c0,0.1,0,0.1,0,0.1h0.3l3,0.2l5.5,0.3c2.3,0.2,4.7,0.2,7-0.1l0.5-0.1c0.2-0.1,0.3-0.2,0.5-0.2c0.1-0.1,0.3-0.3,0.4-0.4l0.5-0.7
		c0.7-1,1.4-2.2,2.3-3.6c2.1-3.4,4.3-6.7,6.8-9.8c2.9-3.8,6.3-7.2,9.9-10.2c1.6-1.3,3-2.1,4-2.6
		C397.2,258.6,397.2,258.2,397.1,257.8z M356.6,258.7c-0.1,0.3-0.3,0.6-0.6,0.8c-0.2,0.2-0.4,0.4-0.7,0.5c-0.5,0.3-1.1,0.5-1.6,0.5
		c-0.9,0.1-1.9,0.1-2.9,0.1h-0.7c-0.2,0-0.4,0-0.6,0c-0.3,0-0.6,0.1-0.9,0.3c-0.7,0.4-1.4,0.9-2,1.5l-0.5,0.5
		c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.2-0.6,0.3c-0.4,0.1-0.8,0.2-1.2,0.2c-0.3,0-0.6-0.1-0.8-0.2c-0.9-0.3-1.7-0.7-2.5-1.2
		c-0.4-0.2-0.7-0.5-1-0.7c-0.3-0.3-0.6-0.6-0.8-1c-0.6-0.9-1.1-1.9-1.4-2.9c-0.2-0.7-0.2-1.2-0.1-1.3c0.2-0.1,0.5,0.2,1,0.7
		s1.1,1.4,2,2.2c0.2,0.2,0.4,0.4,0.6,0.5c0.2,0.1,0.5,0.3,0.8,0.5c0.6,0.3,1.3,0.5,2,0.7c0.1,0,0.3,0.1,0.4,0.1c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0l0.2-0.2l0.5-0.5c0.7-0.8,1.6-1.5,2.6-2c0.6-0.3,1.2-0.5,1.8-0.6c0.3,0,0.6,0,0.8,0h0.8
		c1,0,1.9,0.1,2.5,0.1c0.3,0,0.5-0.1,0.8-0.2c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0,0,0.1-0.1s0,0,0,0c0.2-0.4,0.3-0.9,0.2-1.4
		c-0.1-0.9-0.4-1.7-0.8-2.5c-0.4-0.5-0.7-0.7-0.7-0.9c0-0.2,0.6-0.3,1.3,0.2c1,0.7,1.7,1.7,2,2.9c0.1,0.4,0.2,0.8,0.2,1.2
		C356.8,257.7,356.8,258.2,356.6,258.7z M373.7,230.7c-2,1-4.6,2.4-7.8,4.1c-1.6,0.9-3.2,1.6-5,2l-0.7,0.1h-0.8
		c-0.4,0-0.8,0-1.2-0.1c-0.7-0.1-1.3-0.3-2-0.5c-2.4-0.8-3.5-2.1-3.3-2.5c0.2-0.3,1.1-0.2,2.6-0.1c-0.1-0.9-0.1-1.8,0-2.7
		c0-0.4,0.2-0.7,0.3-1c0.1-0.2,0.3-0.3,0.5-0.5l0.4-0.2c0.5-0.3,1.1-0.5,1.6-0.7c1.8-0.7,3.7-1.4,5.5-1.9c3.2-0.9,5.3-1.1,5.3-1
		s-2,0.6-5.1,1.7c-1.6,0.5-3.4,1.2-5.4,2.1c-0.5,0.2-1,0.5-1.5,0.7c-0.2,0.1-0.5,0.3-0.5,0.3c-0.1,0.2-0.2,0.4-0.2,0.7
		c-0.1,0.9,0,1.7,0,2.6c0.1,0,0.1,0,0.2,0c0.5,0,1.1,0,1.7,0c0.3,0,0.6,0,0.9-0.1l0.3-0.1l0.4-0.1c1.4-0.6,2.8-1.2,4.1-2
		c2.7-1.6,5.7-2.7,8.7-3.3c2.4-0.4,4,0,4,0.5C377,229.2,375.7,229.7,373.7,230.7z M372.3,220.3c-4,1.1-14.3,8.2-17.9,8.5
		c-2.3,0.1-4.5-0.8-6-2.6c2.5,0,5-0.3,7.5-0.8c4.5-0.9,12.3-6.8,15.3-7.3c2.9-0.4,7.4,1.8,7.4,1.8
		C376.4,219.7,374.3,219.8,372.3,220.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M150.6,218.4c-0.3-0.1-0.4-0.6-0.3-1.4c0.1-0.5,0.2-1,0.4-1.5c0.1-0.3,0.3-0.6,0.5-0.9
		c0.1-0.2,0.2-0.3,0.4-0.5l0.1-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.4c0-0.2-0.1-0.5-0.1-1c-0.1-0.2-0.1-0.4-0.1-0.7
		c0-0.1,0-0.3,0-0.4c0-0.2,0-0.4,0.1-0.6c0.1-0.4,0.2-0.8,0.5-1.1c0.1-0.2,0.3-0.3,0.4-0.5l0.3-0.3c0.5-0.6,1-1.2,1.4-1.9l0.7-1.3
		c0.3-0.6,0.7-1.1,1.1-1.6c0.3-0.3,0.6-0.5,0.9-0.7c0.3-0.2,0.6-0.3,0.9-0.4l0.2-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.3,0.3-0.4
		c0.2-0.4,0.5-0.8,0.7-1.1c0.2-0.2,0.4-0.5,0.6-0.7c0.2-0.2,0.5-0.4,0.8-0.6c0.3-0.1,0.6-0.2,0.9-0.3c0.2,0,0.5,0,0.7,0
		c0.2,0,0.4,0,0.5,0c0.3-1.2,0.9-3.2,2.1-5.7c2.7-5.3,6.1-10.2,10-14.7c0.9-1.1,1.9-2.2,3-3.3c-0.9,0.5-1.7,1.1-2.5,1.7
		c-1.2,0.9-2.4,1.8-3.4,2.8c-1.7,1.7-3.3,3.5-4.7,5.5c-2.4,3.2-4.6,6.7-6.4,10.3c-0.1,0.3-0.2,0.5-0.2,0.5l0,0c0,0,0,0,0,0.1
		l-1.8-0.8c0.1-0.2,0.6-1.7,1.3-3.2c1.3-2.9,2.7-5.7,4.3-8.5c-0.3,0.4-0.6,0.7-0.8,1c-0.9,1.3-2.6,4-4.6,7.5c-2,3.6-4.4,8.1-7,13
		c-6.4,12.1-11.2,22.2-11.7,21.9c-0.4-0.2,1.4-5.3,4.7-12.6c0.3-1.2,0.7-2.7,1.1-4.4c0.5-1.7,1.1-3.7,1.8-5.8
		c2.6-8.2,6.7-19.1,11.8-31c-1.3,1.9-2.5,3.9-3.6,5.9c-2.5,4.4-4.5,8.4-6,11.8c-2.2,4.7-3.7,8.1-4.4,9.8c-0.6,3.3-1,5.2-1.2,5.2
		c-0.3,0-0.1-4.1,0.7-10.7c0.6-5,1.5-11.4,2.6-18.5c-0.4,1.1-0.8,2.2-1.1,3.4c-1.2,3.9-2.2,7.4-3,10.4c-1,3.7-1.7,6.5-2.1,8.1
		c0,1.4-0.1,2.6-0.2,3.8c-0.1,1.3-0.3,2.3-0.4,3.2c-0.1,0.9-0.3,1.8-0.7,2.6c-0.3,0,0.4-3.7,0.2-10c-0.2-4.8-0.6-9.6-1.4-14.3
		c-0.1,6.1-0.8,12.2-2,18.3c-0.9,4.6-2.3,9.1-4.1,13.5l-1.4,3.1c0.3,0.3,0.6,0.7,0.8,1c0.2,0.3,0.4,0.5,0.5,0.8
		c0.1,0.3,0.1,0.6,0.2,0.8c0,0.4,0,0.8-0.1,1.2c0,0,0,0,0,0c0.1,0.1,0.3,0.2,0.4,0.4l0.4,0.4l0.3,0.3l0.4,0.4l0.3,0.3
		c0.1,0.1,0.2,0.3,0.3,0.4c0.3,0.4,0.4,1,0.3,1.5c-0.1,0.2-0.2,0.4-0.3,0.5c0,0,0,0.1,0.1,0.1c0.2,0.3,0.4,0.7,0.5,1
		c0.2,0.5,0.3,1.1,0.2,1.6c0,0.1-0.1,0.5-0.1,0.5c0,0.1,0,0.1,0,0.2c0,0.2,0.1,0.4,0.1,0.6c0.2,0.5,0.5,1,0.8,1.4l0.3,0.4
		c0.1,0.1,0.3,0.3,0.4,0.5c0.3,0.4,0.5,0.7,0.7,1.2c0.2,0.5,0.3,1,0.3,1.5c0,0.5-0.1,0.9-0.3,1.4c-0.6,1.7-0.6,1.9,0.4,3
		c0.2,0.2,0.3,0.4,0.5,0.6c0-0.1,0-0.3,0.1-0.4c0.2-0.7,0.6-1.4,1.1-1.9c0.4-0.5,0.8-0.8,1.1-1.2c0.3-0.3,0.6-0.7,0.8-1.1
		c0.1-0.1,0.1-0.3,0.2-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.3c0-1.3,0.1-2.5,0.3-3.7c0.1-0.7,0.3-1.3,0.6-1.9
		c0.3-0.7,0.8-1.3,1.3-1.8c0.9-0.7,1.6-1.1,2.1-1.6c0.2-0.2,0.4-0.4,0.5-0.6c0-0.1,0.1-0.2,0.1-0.3v-0.1l0.1-0.4
		c0.2-0.8,0.6-1.4,1.3-1.9c0.4-0.2,0.7-0.4,0.9-0.5v-0.4c0-0.8,0.2-1.6,0.3-2.4c0.1-0.5,0.3-1,0.6-1.4c0.3-0.3,0.5-0.3,0.5-0.3
		C150.5,218.5,150.6,218.4,150.6,218.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M213.2,219.6c-0.2-0.3-0.4-0.7-0.7-1.1c-0.1-0.2-0.3-0.4-0.4-0.6c-0.2-0.2-0.4-0.4-0.6-0.6
		c-0.5-0.5-1-0.9-1.5-1.2l-1-0.7c-0.6-0.4-1-1-1.3-1.7c-0.2-0.5-0.4-1-0.5-1.5c-0.1-0.3-0.2-0.5-0.3-0.8c-0.2-0.1-0.4-0.1-0.5-0.2
		c-0.4-0.2-0.8-0.6-1.1-1c-0.3-0.4-0.5-0.8-0.7-1.3c-0.1-0.3-0.1-0.7-0.1-0.6c-0.1-0.2-0.3-0.3-0.5-0.5c-0.4-0.3-0.8-0.6-1.3-0.7
		c-0.7-0.1-1.3-0.4-1.8-0.9c-0.2-0.2-0.4-0.5-0.6-0.8c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.3-0.2-0.6-0.3-0.8c0-0.1-0.1-0.2-0.2-0.3
		c-0.4-0.2-0.8-0.4-1.2-0.4c-0.5-0.1-1-0.1-1.5-0.1c-0.3,0-0.5,0-0.8-0.1c-0.2-0.1-0.5-0.1-0.7-0.3c-0.3-0.2-0.6-0.4-0.8-0.7
		c-0.5-0.5-1-1-1.5-1.5c-0.3-0.2-0.6-0.2-0.9-0.2c-0.5,0.1-1,0.3-1.5,0.4c-0.3,0.1-0.7,0.2-1.1,0.3c-0.2,0-0.4,0.1-0.6,0.1
		c-0.3,0-0.6,0-0.9-0.1c-0.6-0.2-1.1-0.6-1.5-1.1l-0.2-0.2c-0.1-0.1-0.1-0.1-0.1-0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0l-0.3,0.1l-0.6,0.2c-0.5,0.1-1.1,0.2-1.7,0.2c-0.4,0-0.8-0.1-1.2-0.2c-0.2-0.1-0.4-0.2-0.5-0.3
		c-0.1-0.1-0.2-0.1-0.3-0.2l-0.3-0.3c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.1,0-0.2,0.1l-0.1,0
		l-0.2,0.1c-0.5,0.3-1,0.5-1.5,0.5c-1,0.2-2,0.1-2.9-0.2c-0.4-0.1-0.7-0.3-1-0.6c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3,0-0.4,0.1
		l-0.4,0.2l-0.2,0.1l-0.3,0.1c-0.4,0.2-0.8,0.2-1.2,0.2c-0.3,0-0.6-0.1-0.9-0.2c-0.5-0.3-1.1-0.4-1.7-0.3c-0.2,0.1-0.3,0.2-0.5,0.4
		c-0.2,0.2-0.4,0.6-0.7,1c-0.4,0.5-0.8,1-1.4,1.3c-0.7,0.3-1.4,0.5-2.1,0.5c-0.6,0-1.2,0.1-1.8,0.2l-0.3,0.1c0,0-0.1,0.1-0.1,0.1
		c0,0,0,0,0,0.1l-0.1,0.2c-0.1,0.2-0.2,0.5-0.4,0.9c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.3-0.3,0.5-0.5,0.8c-0.2,0.3-0.5,0.6-0.9,0.8
		c-0.2,0.1-0.4,0.2-0.5,0.3l-0.4,0.1l-0.3,0.1c0,0-0.1,0-0.1,0.1c-0.2,0.3-0.4,0.6-0.6,0.9c-0.3,0.4-0.5,0.9-0.8,1.3
		c-0.6,1-1.3,1.8-2.2,2.5c-0.3,0.2-0.2,0.1-0.3,0.2c0,0-0.1,0-0.1,0c0,0,0,0,0,0.2s0,0.3,0,0.5c0,0.5,0,0.9-0.1,1.4
		c-0.1,0.6-0.3,1.1-0.7,1.6c-0.1,0.1-0.2,0.2-0.3,0.3l-0.2,0.1l-0.1,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
		c-0.3,0.4-0.6,0.7-0.8,1c-0.4,0.6-0.7,0.9-1,0.9c0,0,0,0,0,0c-0.1,0.1-0.1,0.1-0.2,0.2c0,0,0,0,0,0c0,0.1-0.1,0.2-0.1,0.6
		c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.7,0,1.5,0,2.2v0.2c0,0,0,0.1,0.1,0.1l0.1,0.1c0-0.3-0.1-0.6-0.1-1c0-1.2,0.5-2.2,0.9-2.4
		c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.2-0.3-0.3-0.2-0.4s0.2,0,0.5,0.1
		c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0
		c0.3,0,0.5,0.1,0.8,0.1c0.3,0.1,0.6,0.2,0.9,0.3c0.2,0.1,0.3,0.2,0.5,0.3l0.3,0.2c1.4-0.1,2.8,0.1,4.1,0.6h0.2l0.5-0.1
		c0.3-0.1,0.7-0.1,1-0.1c0.7,0,1.3,0,2,0c0.7,0,1.4,0.1,2.2,0.3c0.4,0.1,0.7,0.2,1.1,0.3c0.2,0.1,0.4,0.1,0.5,0.2l0.2,0.1h0.1
		l0.5-0.1l0.9-0.1c0.6-0.1,1.3-0.1,1.9-0.2c1.4-0.1,2.7-0.1,4.1,0c0.8,0,1.5,0.2,2.2,0.3c0.3,0.1,0.6,0.1,0.8,0.3c0,0,0.1,0,0.1,0
		l0.6-0.1l0.8-0.1c0.5-0.1,1.1-0.2,1.6-0.2c0.9-0.1,1.9-0.1,2.8,0c0.7,0,1.4,0.2,2.1,0.4c0.2,0.1,0.4,0.1,0.6,0.2l0.1,0.1l0.9-0.2
		l1.1-0.2c0.7-0.1,1.3-0.2,1.9-0.2c1-0.1,2-0.1,3.1,0c0.7,0,1.4,0.1,2.1,0.3c0.2,0,0.5,0.1,0.7,0.2l0.3,0.1c0.2-0.1,0.5-0.2,0.7-0.3
		l0.3-0.1c0.7-0.2,1.3-0.4,2-0.5c0.6-0.1,1.3-0.2,1.9-0.2c0.4,0,0.9,0,1.3,0l0.7,0.1l0.7,0.1l0.1-0.1l0.3-0.1
		c0.2-0.1,0.4-0.1,0.8-0.2c0.4-0.1,0.8-0.2,1.4-0.2c0.3,0,0.7-0.1,1-0.1c0.2,0,0.4,0,0.6,0h0.3h0.2c0.3-0.1,0.7-0.2,1-0.3
		c0.4-0.1,0.8-0.1,1.2-0.1c0.3,0,0.6,0,0.9,0c0.2,0,0.4,0,0.5,0.1h0.2c0,0,0,0,0.2-0.1l0.3-0.2c0.2-0.1,0.4-0.2,0.6-0.2
		c0,0,0.1,0,0.1,0C213.2,219.7,213.2,219.6,213.2,219.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st3"
            d="M12.2,407.5c4.8-0.9,9.5-2,14.1-3.5c1.3-0.5,2.7-1.1,3.9-1.6c1.2-0.5,2.4-0.9,3.6-1.2c2.1-0.5,4.2-0.8,6.3-0.8
		c3.7,0,5.9,0.7,5.9,0.5c-0.5-0.3-1-0.5-1.5-0.6c-1.4-0.4-2.9-0.6-4.4-0.7c-2.2-0.1-4.4,0.1-6.6,0.6c-1.3,0.3-2.5,0.7-3.8,1.2
		c-1.3,0.5-2.5,1.1-3.8,1.5c-5.3,1.8-10.3,3-13.9,3.8s-5.9,1.4-5.8,1.5S8.6,408.2,12.2,407.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M7.9,528.3c1.8-4,4.1-8.8,6.5-14.1c1.1-2.4,2.3-5,3.4-7.6c-1.6,2.9-3.1,5.4-4.4,7.7c-3.5,6.2-5.5,10-5.8,9.9
		c-0.2-0.1,0.9-3,3.2-7.7c0.3-1.6,1.3-4.9,2.9-8.7c1.7-4.2,3.8-7.4,4.2-8.6c0.5-1.2,1.2-5,2.8-9.2s3.6-7.5,4.1-8.7
		c0.4-1.2,1.1-5,2.6-9.2c1.1-3.1,2.2-5.8,3-7.4c-0.1,0.1-0.2,0.2-0.3,0.3l-2.4,1.4c-0.2,1-0.7,2.6-1.3,4.7c-1,3.5-2.5,8.3-4.3,13.5
		c-0.7,2.1-1.5,4.1-2.2,6c-0.3,1-0.7,1.9-1,2.7c-0.3,0.8-0.7,1.6-1.1,2.4c-1.5,3-2.7,5.3-3.2,6.4l-0.9,2c0,0.1-0.1,0.2-0.1,0.3l0,0
		l-1.1,2.5l0.4-2.7c0.2-1.4,0.7-4.9,1.4-9.3c-0.6,2.8-1.4,6-2.4,9.4c-1.5,4.6-3.4,8.9-5.9,13.1c-1.8,3.2-3.2,5-3.4,4.8
		c-0.2,0.3-0.5,0.5-0.8,0.8v18.8c0.1-0.1,0.2-0.3,0.3-0.4C2.5,540.4,4.6,535.6,7.9,528.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M67.6,467.3c-0.5,1.1-1.2,2.1-2.1,2.9c-0.7,0.7-1.5,1.2-2.4,1.7l-1.8,0.8l-1,0.4h-0.1c0,0,0,0,0-0.1v-0.1v-0.3
		l-0.1-0.5c-0.1-0.6-0.2-1.2-0.3-1.9c0-0.4,0-0.8,0-1.1c0-0.3,0-0.6,0-0.8c-0.1,0-0.3,0-0.5-0.1c-0.3-0.1-0.5-0.2-0.7-0.5
		c-0.3-0.3-0.3-0.2-0.3-0.2s-0.1,0.1-0.1,0.2v0.1l-0.1,0.1l-0.2,0.2l-0.4,0.3c-0.5,0.3-1,0.6-1.6,0.7c-0.1,0-0.2,0-0.4,0
		c-0.2,0.2-0.4,0.4-0.6,0.6c-0.8,0.8-1.9,1.3-3,1.5c-0.2,0-0.4,0-0.6,0c-0.1,0.2-0.3,0.4-0.4,0.6c-0.2,0.3-0.5,0.6-0.7,0.8
		c-0.2,0.3-0.5,0.5-0.8,0.8c-0.3,0.2-0.5,0.5-0.8,0.7l-1,0.6c-0.5,0.2-0.9,0.4-1.4,0.6l-0.7,0.3l-0.2,0.1c0,0-0.1,0.1-0.1,0
		l-0.1-0.3c-0.1-0.5-0.2-0.9-0.2-1.3c0,0,0-0.1,0-0.1c-0.5,0.5-1.1,0.8-1.8,1l-2,0.6l0.6-1.5c-0.3,0.2-0.6,0.5-1,0.7
		c-0.4,0.3-0.9,0.5-1.3,0.8l-0.6,0.4l-1.1,0.4l-2,0.8l-2.1,0.6l-1,0.3l-0.5,0.1l-0.2,0.1c-0.1,0,0,0,0-0.1l0.1-0.1l1.3-1.5
		c0.4-0.5,0.8-1,1.2-1.4l0.8-0.9c-0.1-0.2-0.3-0.5-0.4-0.9c-0.1-0.7-0.2-1.3-0.1-2c-0.2-0.2-0.5-0.4-0.7-0.7c-0.8-1.2-1.1-2.6-0.8-4
		c0-0.1,0.1-0.2,0.1-0.4c-0.1-0.1-0.1-0.1-0.2-0.2c-0.6-0.7-0.9-1.5-1-2.4c0-0.1,0-0.1,0-0.2c-0.2,0.2-0.3,0.4-0.5,0.6
		c0,1.4-0.8,5.1-2.3,9.3c-1.2,3-2.5,5.9-4,8.7c-0.4,1.2-1.1,5-2.7,9.2s-3.7,7.5-4.2,8.6c-0.3,0.7-0.7,2.4-1.3,4.6
		c2.5-4.7,5.4-10,8.6-15.6l1.8,0.9c0,0,0,0.1-0.1,0.1l0,0c0,0,0,0,0,0c-1.2,2.6-2.3,5.3-3.6,8.1c-2.8,6.2-5.3,12.1-7.9,17.4
		s-4.9,10-6.9,14c-2,4-3.7,7.1-4.9,9.3s-1.9,3.3-2.1,3.3c0,0,0-0.1,0-0.2c-0.1,0.1-0.2,0.3-0.3,0.4v19.8c-0.1-0.1,0.4-0.8,1.3-1.9
		s2.2-2.9,3.8-5c3.1-4.3,7-10.5,11.2-17.5c2.1-3.5,4.1-6.9,6.2-9.7c2.1-2.9,3.9-5.5,5.4-7.7c1.9-2.7,3.5-4.7,4.4-5.8
		c0.1-1,0.2-1.9,0.3-2.9c0.1-1.3,0.3-2.6,0.3-3.8c0-2.3-0.1-4.5-0.5-6.7c-0.4-2.9-1-5.7-1.8-8.5l0,0l0,0c0,0,0,0,0,0l-0.5-1.9
		l1.1,1.6c1.1,1.5,1.4,1.9,1.6,2.2c0.5,0.8,1.3,1.8,2.2,3.2c1.1,1.7,2,3.5,2.7,5.4c0.2,0.5,0.3,1,0.5,1.5c1-1.2,2.1-2.4,3.3-3.5
		c0.8-0.8,1.8-1.5,2.8-2l0,0l0,0c0.1,0,0.2-0.1,0.2-0.1l1.8-0.8l-1.1,1.6l-1,1.5c-0.3,0.5-1.1,1.9-2,3.8c-0.2,0.5-0.5,1-0.7,1.5
		s-0.4,1.2-0.7,1.8c-0.5,1.2-1.1,2.5-1.8,3.9c0,0-0.1,0-0.1,0c-0.1,2.7-0.5,5.3-1.1,7.9c-1.6,6.4-3.9,12.5-6.9,18.4
		c-0.9,1.9-1.7,3.5-2.4,4.6c0.1-0.1,0.3-0.3,0.4-0.4c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.3,0.4-0.6,0.6-1
		c0.9-1.4,1.7-2.9,2.5-4.5c1.7-3.3,3.5-6.9,5.4-10.8c1.5-3.1,2.9-6.2,4.2-9c1.2-2.6,2.2-5.3,3-8c1-3.5,1.5-7.2,1.6-10.9
		c0-0.7,0-1.6-0.1-2.8l-0.2-2l-0.1-1.1c0-0.4-0.1-0.8-0.2-1.1c-0.9-3.1-3.9-6.6-8.1-10.3l-2.9-2.6l3.9,1.1c0.1,0,0.1,0.1,0.2,0.1
		l0,0c0,0,0.1,0.1,0.1,0.1c2.7,1.3,5.1,3.1,7.2,5.3c2.4,2.5,4.1,5.7,4.8,9.2c0.3,1.5,0.4,3.1,0.4,4.7c0,0.8-0.1,1.5-0.1,2.2
		c0,0.3-0.1,0.7-0.1,1c0,0.3-0.1,0.8-0.1,0.8l-0.2,0.8l-0.1,0.5v0.2c0,0.1-0.1,0.2,0,0.2c0.4-0.3,0.7-0.6,1-1
		c0.3-0.4,0.9-1.2,1.3-1.8s0.7-1.1,1-1.5c0.6-0.9,1.3-2.2,2.6-4.3c2-2.8,4.2-5.5,6.6-8l1.2-1.3c-0.2-2-0.3-4-0.3-6
		C66.8,474.3,67.2,469.8,67.6,467.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M2.8,522.2C2.8,522.2,2.8,522.3,2.8,522.2c-0.3-0.1,0.6-2.2,2.1-5.5c2-4.2,3.7-8.6,5.2-13
		c2.5-8.6,5-15.8,5.6-18.3c0.3-1.1,0.6-3.2,1.7-8.2l0.6-3l0.7,3.2c0.2,1.1,0,5.8-0.9,10.8c-0.6,3.5-1.4,6.9-2.3,10.3
		c0.3-1,0.7-2,1.2-3.2c0.3-0.8,0.6-1.6,0.8-2.5c0.3-0.9,0.5-1.8,0.8-2.8c0.6-1.9,1.2-4,1.9-6.1c1.8-5.3,3.5-10,4.9-13.3
		c0.6-1.5,1.1-2.6,1.6-3.5l-1.5,1l-1.9,1.1c-0.5,0.3-1,0.7-1.5,1l-1.1,0.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.2v-0.3v-0.8
		c0-0.3,0-0.8,0-1.3v-1.3c0-0.9,0.1-1.7,0.2-2.6c0.1-0.5,0.2-1,0.3-1.5l0.2-0.8c0,0,0,0,0,0H21c-0.4,0-0.7,0-1.1,0l-0.8-0.9
		c-0.1,0.2-0.2,0.3-0.3,0.5v-0.2l-0.2-1.3c-0.1-0.5-0.2-0.9-0.2-1.4l-0.2,0.8l-0.5,2.5l-0.3-0.7c-0.1-0.2-0.1-0.2-0.2-0.3
		c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0.1c0,0.1,0-0.1-0.1-0.3l-0.1-0.5l-0.3-1.2c-0.2-0.9-0.3-1.9-0.4-2.8l0,0.1
		c-0.1,0.2-0.2,0.4-0.2,0.6l-0.7,2.2l-0.5-1.6l-0.1-0.2l-0.1-0.5c-0.1-0.3-0.2-0.6-0.3-0.9l-0.1-0.5l-0.1-0.6c0-0.4,0-0.8,0-1.2
		v-0.6V453c0-0.2,0.1-0.5,0.1-0.7c0.1-0.4,0.2-0.8,0.4-1.2c0.2-0.5,0.5-1,0.8-1.5c-0.2-0.2-0.3-0.5-0.4-0.9
		c-0.1-0.3-0.1-0.5-0.1-0.8c-0.3,0.4-0.6,1-1,1.6c-0.6,1-1.1,2.1-1.5,3.2c-0.2,0.6-0.5,1.3-0.7,2c0,0-0.1,0.3-0.1,0.5l-0.1,0.6
		l-0.3,1.2c-0.2,0.8-0.4,1.6-0.5,2.5l-0.9-1.2l-0.5-0.6c-0.2-0.2-0.3-0.5-0.5-0.8c-0.2-0.4-0.4-0.8-0.6-1.2
		c-0.2-0.3-0.3-0.7-0.4-1.1c-0.2-0.5-0.3-0.9-0.4-1.4c-0.2-0.9-0.2-1.8-0.2-2.8c0-0.9,0.2-1.7,0.4-2.6c-0.1-0.2-0.3-0.6-0.4-1
		c-0.1-0.5-0.2-1.1-0.3-1.6L6,446.5c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1,0-0.1-0.1l-0.2-0.7c-0.2-0.6-0.3-1.2-0.4-1.8
		c0-0.4-0.1-0.8-0.1-1.2V442c0-0.1,0-0.3,0-0.4c0.1-0.5,0.2-1,0.3-1.5c0.3-0.9,0.7-1.8,1.3-2.6c-0.2-0.8-0.2-2,0.2-3.5
		c0,0,0-0.1-0.1-0.1c-0.4-0.8-0.5-1.7-0.5-2.5c0.1-1.3,0.6-2.6,1.5-3.6c1.1-1.1,2.4-2,3.8-2.6c0,0,0,0,0,0c0-0.3,0-0.8,0-1.3
		c0.2-1.9,1.2-3.6,2.7-4.8c0.4-0.3,0.9-0.6,1.4-0.9c0.4-0.2,0.9-0.4,1.4-0.5c0.2,0,0.4-0.1,0.5-0.1c0-0.3,0.1-0.6,0.2-0.9
		c0.6-1.4,1.8-2.4,3.2-2.7c0.1,0,0.2,0,0.3-0.1c0.1-0.2,0.2-0.4,0.3-0.5c0.8-1,2-1.7,3.2-2c0.2-0.1,0.5-0.1,0.7-0.1
		c0.1-0.2,0.3-0.5,0.5-0.8c1-1.2,2.4-2,4-2.3c0.5-0.1,1-0.1,1.5,0c1-0.8,3.3-1.8,6.4-1.1c1,0.4,1.8,1,2.5,1.8
		c0.5-0.1,1.3-0.1,2.2,0.1c1.2,0.3,2.2,1,2.9,2c0.2,0.3,0.4,0.6,0.5,0.9c0.2,0.3,0.3,0.7,0.4,1l0.1,0.5v0.3l2.3,0.5l0,0l-2.2-0.5
		l0.5-0.5c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.1,0.7-0.5,1-0.7c0.5-0.3,1.1-0.5,1.7-0.7c0.2,0.8,0.3,1.6,0.1,2.4c0.4-0.1,0.8-0.2,1.2-0.2
		c0.8-0.1,1.6,0.1,2.3,0.4c-0.1-0.7-0.1-1.6,0-2.7c0.1-0.7,0.3-1.3,0.5-2c0.1-0.4,0.3-0.7,0.5-1.1c0.1-0.1,0-0.1,0-0.1
		c-1-1.5-1.5-3.3-1.7-5.1c-0.2-1.9,0.3-3.8,1.3-5.5c0.3-0.6,0.7-1.1,1.2-1.6l0.3-0.3c0-0.1,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.1
		c0-0.2-0.3-0.9-0.5-1.4l-0.3-1.2c-0.2-0.7-0.1-1.1-0.2-1.4c-0.1-0.6-0.1-1.1-0.1-1.7c-1,0.2-3.8,0.3-7.9,0.5
		c-1.3,0.1-2.7,0.1-4.1,0.3c-1.6,0.1-3.1,0.3-4.6,0.6c-3,0.6-6.1,2.8-9.7,4.5c-3.4,1.6-7,2.8-10.7,3.4c-3.4,0.6-6.4,1-9,1.2
		c-3.8,0.3-6.5,0.3-7.7,0.2v123C2.3,522.8,2.5,522.5,2.8,522.2z M12.1,406.8c3.6-0.9,8.6-2,13.9-3.8c1.3-0.5,2.5-1,3.8-1.5
		c1.2-0.5,2.5-0.9,3.8-1.2c2.2-0.5,4.4-0.7,6.6-0.6c1.5,0.1,3,0.3,4.4,0.7c0.6,0.1,1.1,0.3,1.5,0.6c0,0.2-2.3-0.5-5.9-0.5
		c-2.1,0-4.3,0.2-6.3,0.8c-1.2,0.3-2.4,0.7-3.6,1.2c-1.2,0.5-2.5,1.1-3.9,1.6c-4.6,1.5-9.3,2.7-14.1,3.5c-3.7,0.7-6,1-6,0.8
		S8.5,407.7,12.1,406.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M230.8,493.7l-1.7-1.3l-3.3-2.5l-6.3-5c-2.1-1.6-4.1-3.2-6.1-4.7c-1-0.8-2-1.5-3-2.1l-1.6-1.1
		c-0.5-0.4-1-0.8-1.5-1.2c-3.8-3.3-7-6.6-10-9.7l-4.3-4.4c-0.7-0.7-1.4-1.4-2.1-2l-1.1-1c-0.4-0.3-0.7-0.7-1-1
		c-2.3-2.5-4.3-5.2-6.1-8c-0.8-1.2-1.5-2.4-2.1-3.5c-0.3-0.5-0.6-1.1-0.8-1.7c0,0,0,0,0-0.1c-0.1,0.2-0.1,0.5-0.2,0.7
		c0.9,0.4,0.9,2.5,0.2,4.9c-0.6,2-1.7,3.5-2.6,3.8c0.2,0.5,0.5,1.1,0.9,1.9c1.2,2.6,2.6,5.1,4.2,7.5c2.5,3.5,5.1,6.8,8,9.9
		c3.2,3.6,6.8,7.6,10.7,11.6l11.1,11.3c0.9,0.9,1.7,1.7,2.5,2.5c0.7,0.7,1.5,1.4,2.4,2l5,3.4c3.7,2.6,6.5,4.7,8.1,6
		c0.9-5.4,1.7-10.4,2.5-14.8C231.9,494.5,231.3,494.1,230.8,493.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M179.4,442.6c-0.1-0.7-0.1-1.3-0.1-2c-0.8,0.6-1.7,1-2.6,1.2c-0.8,0.2-1.6,0.3-2.5,0.3l-0.4,0.1
		c-0.2,0-0.6,0.1-0.8,0.1c-0.1,0-0.1,0.1-0.1,0.2c0,0.1,0,0.2-0.1,0.3c-0.2,0.4-0.3,0.7-0.6,1c-0.6,0.9-1.4,1.8-2.3,2.4
		c-1.9,1.1-4.1,1.6-6.3,1.3h-0.2c-0.3,0.7-0.7,1.3-1.1,1.9c-0.3,0.4-0.7,0.8-1,1.1c0.4,1.8,1.2,6.1,1.7,12.3
		c0.4,4.8,0.5,9.7,0.2,14.5c-0.2,4-0.6,8.4-1.1,12.9c0.7-3.5,1.4-6.9,2.3-10.3c1.2-5,2.7-10,4.8-14.7c1.4-3.2,3.1-6.3,5.1-9.1
		c0.9-1.3,1.7-2.1,2.2-2.5c0-0.1,0-0.1,0-0.2c-0.7-0.6-0.8-2.6-0.1-4.8c0.8-2.4,2.3-4.1,3.2-3.8l0.2-0.7
		C179.6,443.7,179.5,443.2,179.4,442.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M179.8,444.2l-0.2,0.7c0,0,0,0,0,0c0,0,0,0,0,0C179.7,444.7,179.8,444.5,179.8,444.2
		C179.8,444.3,179.8,444.3,179.8,444.2z"
          />
        </g>
        <g id="bd42d6f1-3bde-4ea5-9d38-b1c288fd2017">
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M230.8,120.9c-0.2-0.3-1.8,0.4-4.4,1c-3.4,0.7-7,0.7-10.4-0.1c-1.6-0.3-3.3-0.7-4.8-1
		c-0.8-0.1-1.6-0.2-2.3-0.3s-1.3-0.2-2-0.3c-2.5-0.5-4.4-1-5.3-1.3c-1.7-0.7-3.5-1.3-5.3-1.7c-1.4-0.2-2.9-0.3-4.4-0.2
		c-1.6,0.1-3.2,0.2-4.9,0.4c-3.4,0.4-6.6,1-9.1,1.3c-0.6,0.1-1.2,0.1-1.7,0.2c-0.2,0-0.5,0-0.7,0c-0.2,0-0.5-0.1-0.7-0.1
		c-0.7-0.1-1.3-0.3-2-0.6c-0.5-0.2-1.1-0.5-2-0.9s-1.8-1-3.1-1.5c-2.5-0.7-5.5-1.7-8.8-2.7c-1-0.3-2-0.6-2.8-1
		c-0.5-0.2-0.7-0.3-1.3-0.6c-0.5-0.2-1-0.4-1.5-0.5c-1.6-0.4-3.3-0.6-4.9-0.8c-2.8-0.2-4.6-0.1-4.6,0.1s1.6,0.7,4.4,1.3
		c1.3,0.3,3,0.7,4.6,1.2c0.4,0.1,0.8,0.3,1.2,0.4c0.3,0.1,0.9,0.5,1.3,0.7c1,0.4,2,0.8,3,1.1c2.9,0.9,5.9,1.6,9,2.1
		c1.2,0.3,2.3,0.7,3.1,0.9s1.6,0.5,2,0.7s1.1,0.5,2,0.8c0.2,0.1,0.5,0.2,0.7,0.2c0.3,0.1,0.6,0.1,0.9,0.1c0.6,0.1,1.2,0.1,1.9,0.1
		c3.1,0,6.2-0.2,9.2-0.6l4.9-0.6c1.4-0.2,2.7-0.3,4.1-0.3c1.8,0.1,3.6,0.5,5.3,1c0.9,0.3,2.7,1.1,5.2,2c0.6,0.2,1.3,0.4,2,0.6
		s1.4,0.3,2.1,0.5c1.5,0.4,3.1,0.9,4.8,1.2c2,0.4,4.1,0.6,6.2,0.5c1.7-0.1,3.4-0.4,5-1C229.6,122.3,230.9,121.1,230.8,120.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M353.3,252.9c0-0.2,0.6-0.3,1.3,0.2c1,0.7,1.7,1.7,2,2.9c0.1,0.4,0.2,0.8,0.2,1.2c0,0.5,0,1.1-0.3,1.6
		c-0.1,0.3-0.3,0.6-0.6,0.8c-0.2,0.2-0.4,0.4-0.7,0.5c-0.5,0.3-1.1,0.5-1.6,0.5c-0.9,0.1-1.9,0.1-2.9,0.1h-0.7c-0.2,0-0.4,0-0.6,0
		c-0.3,0-0.6,0.1-0.9,0.3c-0.7,0.4-1.4,0.9-2,1.5l-0.5,0.5c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.2-0.6,0.3
		c-0.4,0.1-0.8,0.2-1.2,0.2c-0.3,0-0.6-0.1-0.9-0.2c-0.9-0.3-1.7-0.7-2.5-1.2c-0.4-0.2-0.7-0.5-1-0.7c-0.3-0.3-0.6-0.6-0.8-1
		c-0.6-0.9-1.1-1.9-1.4-2.9c-0.2-0.7-0.2-1.2-0.1-1.3s0.5,0.2,1,0.7s1.1,1.4,2,2.2c0.2,0.2,0.4,0.4,0.6,0.5c0.2,0.1,0.5,0.3,0.8,0.5
		c0.6,0.3,1.3,0.5,2,0.7c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0,0,0.1,0s0,0,0,0l0.2-0.1l0.5-0.5c0.7-0.8,1.6-1.5,2.6-2
		c0.6-0.3,1.2-0.5,1.8-0.6c0.4,0,0.6,0,0.9,0h0.8c1,0,1.9,0.1,2.5,0.1c0.3,0,0.5-0.1,0.8-0.1c0.1,0,0.2-0.1,0.3-0.1
		c0.1,0,0,0,0.1-0.1s0,0,0,0c0.2-0.4,0.3-0.9,0.2-1.4c-0.1-0.9-0.4-1.7-0.8-2.5C353.5,253.3,353.2,253.1,353.3,252.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M336.1,240.9c-0.9-0.2-1.3-3.4-2-7.2c-0.5-3.3-2.9-5.4-2.4-6.3c0.2-0.5,1.3-0.5,2.7,0.4c0.8,0.5,1.5,1.2,2,2
		c0.7,1,1.1,2.1,1.3,3.3C338.5,237.5,337.1,241.1,336.1,240.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M318.8,228c0,0,2.9-2,4.8-2s5.7,3.7,9.7,3.5c0.1,0,0.3,0.1,0.3,0.2c0,0.1,0,0.2-0.1,0.3
		c-1.4,0.9-3,1.4-4.7,1.3C325.4,231.2,322.4,227.6,318.8,228z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M378.5,219.9c0,0-4.5-2.3-7.4-1.8s-10.8,6.3-15.3,7.3c-2.5,0.5-5,0.8-7.5,0.8c1.5,1.7,3.7,2.7,6,2.6
		c3.7-0.3,13.9-7.4,17.9-8.5C374.3,219.8,376.4,219.7,378.5,219.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M377,228.7c0,0.5-1.3,1-3.3,2s-4.6,2.4-7.8,4.1c-1.6,0.9-3.2,1.6-5,2l-0.7,0.1h-0.8c-0.4,0-0.8,0-1.2-0.1
		c-0.7-0.1-1.3-0.3-2-0.5c-2.4-0.8-3.5-2.1-3.3-2.5s1.6-0.1,3.8,0c0.5,0,1.1,0,1.7,0c0.3,0,0.6,0,0.9-0.1l0.4-0.1l0.4-0.1
		c1.4-0.6,2.8-1.2,4.1-2c2.7-1.6,5.7-2.7,8.7-3.3C375.4,227.8,377,228.2,377,228.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M355.6,234.9c-0.2-1.3-0.2-2.5-0.1-3.8c0-0.4,0.2-0.7,0.3-1c0.1-0.2,0.3-0.3,0.5-0.5l0.4-0.2
		c0.5-0.3,1.1-0.5,1.6-0.7c1.8-0.7,3.7-1.4,5.5-1.9c3.2-0.9,5.3-1.1,5.3-1s-2,0.6-5.1,1.7c-1.6,0.5-3.4,1.2-5.4,2.1
		c-0.5,0.2-1,0.5-1.5,0.7c-0.2,0.1-0.5,0.3-0.5,0.4c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,1.2-0.1,2.4,0.1,3.5
		C356.7,234.9,355.6,235,355.6,234.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M323.6,239.2c0.1-0.5,0.9-0.8,2-1c0.7-0.1,1.4-0.2,2-0.2h1.2h1.2c2.8,0.1,5.3-1.2,5.9-0.5
		c0.3,0.4,0.1,1.3-0.9,2.2c-1.4,1.2-3.2,1.9-5,1.9c-0.5,0-1,0-1.4-0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.7-0.1-1.4-0.3-2.1-0.6
		C324.1,240.2,323.5,239.7,323.6,239.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M324.7,239.1c-0.1-1,0-2.1,0.1-3.1c0-0.5,0.2-1,0.4-1.4c0.1-0.1,0.1-0.3,0.3-0.4c0.1-0.1,0.2-0.2,0.3-0.2
		c0.2-0.1,0.3-0.2,0.5-0.3c1.4-0.6,2.3-0.7,2.3-0.6s-0.8,0.5-2,1.2c-0.1,0.1-0.3,0.2-0.4,0.3s-0.1,0.1-0.1,0.1s0,0.1-0.1,0.1
		c-0.1,0.4-0.2,0.8-0.2,1.2c-0.1,1-0.1,2,0,3C325.4,239.1,325.1,239.1,324.7,239.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M398.7,258.8c-0.3-0.4-2.4,0.6-5.3,2.9c-3.7,3.1-7,6.5-9.9,10.2c-2.5,3.1-4.7,6.4-6.8,9.8
		c-0.9,1.4-1.6,2.6-2.3,3.6l-0.5,0.6c-0.1,0.2-0.3,0.3-0.4,0.4c-0.2,0.1-0.3,0.2-0.5,0.2l-0.5,0.1c-2.3,0.3-4.7,0.4-7,0.1l-5.5-0.3
		l-3-0.2h-0.3l0,0c0,0,0,0,0-0.1c-0.2-0.3-0.4-0.5-0.6-0.9c-0.4-0.8-0.8-1.8-1.3-2.8c-0.2-0.5-0.5-1-0.7-1.5
		c-0.1-0.3-0.3-0.5-0.5-0.8c-0.2-0.3-0.4-0.5-0.6-0.8c-1.4-1.5-2.7-2.8-3.9-4c-2.4-2.4-4.3-4-5.1-5s-2.3-3.1-4.4-5.7
		c-2.5-3.1-5.2-6-8.1-8.8c-1-1-1.9-1.9-2.8-2.8c-0.4-0.4-0.9-0.9-1.2-1.3l-0.3-0.3l-0.2-0.3c0,0-0.1-0.1-0.1-0.1v-0.1l0,0
		c0,0,0,0,0,0l-0.2,0.1h-0.1h0h0l0.2-0.1h0.1v0c-0.1-0.4-0.2-0.9-0.2-1.3c0-0.5-0.1-1-0.1-1.6c0-1,0-2-0.1-2.9
		c-0.1-3.6-0.3-5.8-0.8-5.9s-1.2,2.1-1.8,5.7c-0.1,0.9-0.3,1.9-0.4,3c0,0.5-0.1,1.1-0.1,1.7c0,0.7,0,1.4,0.2,2.1
		c0,0.2,0.1,0.5,0.3,0.7l0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3l0.3,0.5l0.3,0.4c0.4,0.5,0.9,1,1.3,1.5c0.9,1,1.9,2,2.9,2.9
		c3.2,3.1,6.4,5.7,8.9,7.8c1.8,1.5,3.6,3.1,5.2,4.8c0.8,1,2.2,3,4.1,5.8l3.1,4.5c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.2,0.2,0.3,0.3,0.6
		c0.2,0.4,0.4,0.9,0.5,1.4c0.4,1.1,0.8,2.2,1.4,3.2c0.3,0.7,0.8,1.3,1.4,1.8c0.4,0.3,0.8,0.6,1.3,0.8l0.8,0.2h0.5
		c1.1,0,2.2,0,3.2-0.1c2-0.1,3.9-0.4,5.6-0.6c2.4-0.3,4.8-0.8,7.1-1.4l0.5-0.1c0.2-0.1,0.5-0.2,0.7-0.3s0.5-0.3,0.7-0.5l0.7-0.6
		c0.9-0.9,1.9-1.9,3.1-3.2c2.3-2.5,5-5.6,7.7-9c3.4-4.2,6.5-8,8.9-10.7S399,259.3,398.7,258.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M343.6,267.8c-0.3,0.4,0.4,1.6,1.6,3.6c1.6,2.6,3.4,5,5.3,7.4c0.2,0.3,0.5,0.6,0.8,0.9s0.3,0.3,0.5,0.5
		s0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.2,0.5,0.3c0.5,0.2,1,0.3,1.5,0.4c0.4,0,0.8,0,1.2,0c1.3-0.1,2.6-0.3,3.8-0.7
		c2.1-0.7,3.6-1.4,4.4-1.6c1.6-0.3,3.2-0.7,4.7-1.4c1.2-0.6,2.3-1.5,3.1-2.6c0.5-0.6,0.9-1.3,1.2-2.1c0.2-0.4,0.3-0.8,0.4-1.2
		c0.1-0.4,0.1-1,0.2-1.3c0.1-0.9,0.2-1.7,0.3-2.5c0-0.4,0-0.8,0.1-1.2v-0.5l0.1-0.9c0.1-1.2,0.1-2.2,0.1-2.9c0-0.4,0-0.6,0-0.8
		s0-0.4-0.1-0.4c-1.1,0.6-2,1.1-2.7,1.4l-1,0.5L369,263c-0.4,0.2-0.9,0.4-1.3,0.5c-1.5,0.2-3,0.2-4.5,0c-1-0.1-2.2-0.3-3.4-0.5
		l-2-0.3c-0.4-0.1-0.7-0.1-1.1-0.1c-0.5,0-0.9,0-1.4,0c-2.3,0.3-4.1,1.9-4.6,2c-0.6,0.1-1.3,0.2-1.9,0.3c-0.7,0.1-1.3,0.2-1.9,0.3
		c-0.6,0.1-1.3,0.4-1.8,0.7c-0.4,0.2-0.7,0.5-1,0.8C343.6,267.2,343.4,267.6,343.6,267.8c0.1,0.2,0.5,0.2,1.2,0.1l1.1-0.2
		c0.4-0.1,0.8-0.1,1.2-0.1c0.5,0,1.2,0.1,1.9,0.2c0.8,0.1,1.6,0.1,2.4,0c0.3,0,0.5-0.1,0.8-0.1c0.3-0.1,0.5-0.2,0.8-0.3
		c0.5-0.2,0.8-0.4,1.1-0.6c0.6-0.3,1.2-0.5,1.8-0.6c0.3,0,0.5-0.1,0.8,0h1c0.7,0,1.4,0,2,0c1.2,0,2.5-0.2,3.7-0.4
		c1.5-0.3,3.1-0.8,4.6-1.3c0.3-0.1,0.7-0.1,1.4-0.3l0.3-0.1h0.2l0.4-0.1c0.3,0,0.5-0.1,0.8-0.2c0,0-0.1,0.2-0.1,0.3l-0.2,0.4
		c-0.1,0.2-0.2,0.4-0.4,0.6l0,0c0,0,0,0,0,0.1l-0.1,0.6c-0.1,0.4-0.2,0.8-0.3,1.2c-0.2,0.8-0.3,1.7-0.4,2.6
		c-0.2,2.1-1.1,4.1-2.7,5.5c-1,1-2.3,1.8-3.6,2.3c-1.5,0.3-3,0.4-4.5,0.4c-1.1,0-2.2,0-3.3-0.2c-0.3,0-0.5-0.1-0.8-0.1
		c-0.2,0-0.3-0.1-0.5-0.2l0,0l-0.3-0.3l-0.6-0.7c-1.9-2.4-3.9-4.6-6.1-6.7C345.3,268.1,343.9,267.5,343.6,267.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M347.3,269.3c0.1-0.1,0.6,0.5,1.6,1.5s2.5,2.5,4.8,2.5c1.3,0,2.7-0.2,4-0.4c1.4-0.3,2.7-1,3.8-1.9
		c0.6-0.5,1.3-1.1,1.9-1.6s1.4-1.2,2.1-1.7l2.2-1.7c0.7-0.5,1.4-1.1,2-1.8l0.9-0.8l0.4-0.4l0.1-0.2l0,0l0.2,0.1
		c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.2,0,0.1,0l0.5,0.7c-0.8,0.5-1.7,0.9-2.6,1.2c-0.8,0.3-1.6,0.6-2.4,0.9c-1.6,0.5-3.2,1-4.6,1.5
		c-1.5,0.5-2.9,0.9-4.2,1.2c-1.2,0.3-2.5,0.6-3.8,0.7C350.1,269.9,347.2,269.6,347.3,269.3c0-0.2,2.8-0.3,7.3-1.2
		c1.2-0.2,2.4-0.5,3.6-0.9l4.1-1.4c1.5-0.5,3-1,4.5-1.6c0.8-0.3,1.6-0.6,2.4-0.9c0.8-0.3,1.5-0.7,2.3-1.1l0.3-0.2l0.4,0.1
		c0.4,0.1,0.7,0.4,0.7,0.8c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.2-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2
		c-0.1,0.1-0.1,0.2-0.2,0.3l-0.4,0.5l-0.4,0.4l-0.2,0.2l-0.1,0.1h0l0,0l0,0l-0.3-0.3c-0.7-0.6,0.5,0.4,0.4,0.3l0,0l0,0v0l-0.1,0.1
		l-0.1,0.2l-0.3,0.3c-0.2,0.2-0.4,0.3-0.6,0.5c-0.4,0.3-0.8,0.6-1.1,0.9l-2.3,1.7c-0.7,0.6-1.4,1.1-2.1,1.6s-1.3,1.1-2,1.6
		c-1.3,1.1-2.9,1.8-4.6,2.1c-1.4,0.2-2.8,0.3-4.3,0.2c-1.2-0.1-2.4-0.6-3.4-1.4c-0.7-0.6-1.3-1.2-1.8-1.9
		C347.5,270.1,347.1,269.4,347.3,269.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M319.3,214.3c0.5,0,0.9,1.6,0.8,4c0,1.2-0.2,2.6-0.4,4.1c-0.1,0.7-0.2,1.6-0.3,2.3c0,0.7,0,1.3,0.2,2
		c0.1,0.7,0.4,1.4,0.7,2c0.1,0.3,0.3,0.5,0.4,0.8l0.1,0.2l0.1,0.1l0.2,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.9,1.1,1.8,2.3,2.5,3.5
		c1.2,2.1,1.7,3.5,1.3,3.8s-1.6-0.6-3.3-2.2c-0.8-0.8-1.8-1.9-2.8-2.8c0-0.1-0.1-0.1-0.2-0.1h0l-0.1-0.1c-0.3-0.2-0.5-0.4-0.7-0.7
		c-0.3-0.4-0.6-0.9-0.9-1.3c-0.5-0.9-0.8-1.9-1.1-2.9c-0.2-1-0.3-2.1-0.2-3.2c0.1-1,0.3-1.7,0.5-2.5c0.4-1.5,0.8-2.8,1.2-3.9
		C318.3,215.6,318.8,214.3,319.3,214.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M457.9,303.6c-0.1-0.1-0.4,0-0.9,0.1c-0.3,0.1-0.6,0.1-1,0.2c-0.6,0.1-1.2,0.4-1.6,0.8
		c-0.2,0.2-0.5,0.5-0.7,0.8c-0.3,0.3-0.5,0.7-0.6,1.1c-0.1,0.4-0.2,0.9-0.2,1.4c0,0.4,0.1,0.9,0.2,1.3c0.5,1.6,0.9,3.4,1,5.1
		c0,0.4,0,0.9,0,1.4c0,0.3-0.1,0.6-0.3,0.9c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.4,0.3-0.7,0.5c-0.2,0.1-0.3,0.3-0.5,0.4
		c-0.2,0.2-0.3,0.4-0.4,0.6c-0.2,0.4-0.4,0.7-0.5,1.1c-0.2,0.7-0.4,1.3-0.5,2l-0.1,0.9c0,0.3-0.1,0.6-0.1,0.6c0,0.1-0.1,0.1-0.2,0.1
		c-0.5,0.1-1.1,0.1-2,0.3l-0.4,0.1c-0.2,0-0.3,0.1-0.5,0.2c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.2-0.4,0.4-0.6,0.7
		c-0.1,0.2-0.1,0.3-0.2,0.5v0.1c-0.1,0,0.6,0.1,0.3,0.1l0,0l-0.1-0.1c0,0-0.1,0-0.1,0l0,0l0,0c-0.3,0.3-0.7,0.5-1.1,0.6l-0.4,0.2
		l-0.4,0.1c-0.2,0.1-0.3,0.2-0.5,0.2c-0.3,0.2-0.6,0.4-0.8,0.7c-0.2,0.3-0.4,0.5-0.5,0.8c-0.4,0.9-0.6,1.6-0.8,2l0,0l-0.3,0
		l-0.5-0.1l-1.1-0.3c-1-0.3-2-0.3-2.9-0.2c-0.7,0.1-1.4,0.4-1.9,0.9c-0.5,0.4-0.8,0.9-1.1,1.5c-0.6,1.5-0.9,2.2-1.4,2.6
		c-0.2,0.2-0.4,0.3-0.6,0.3c-0.3,0-0.6,0-0.9-0.2c-0.7-0.3-1.3-0.8-1.6-1.5c-0.2-0.8-0.2-1.6,0-2.4l0.1-0.5v-0.3c0-0.1,0-0.3,0-0.4
		c0-0.5-0.1-1-0.4-1.5c-0.2-0.2-0.4-0.5-0.6-0.7l-0.5-0.7c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.3-0.4-0.5-0.5
		c-0.3-0.3-0.7-0.6-1.1-0.8c-0.8-0.4-1.7-0.4-2.5-0.2c-0.8,0.2-1.4,0.8-1.6,1.6c-0.1,0.4-0.2,0.8-0.2,1.2v0.6c0,0.2,0,0.4,0.1,0.6
		c0.2,1,0.5,2,0.8,2.9c0.3,0.8,0.5,1.6,0.6,2.5c0.1,0.7,0.1,1.5,0,2.2c-0.1,0.7-0.3,1.3-0.6,1.9c-0.3,0.5-0.7,1-1.2,1.4
		c-0.9,0.7-1.9,1.2-3,1.7c-0.5,0.3-0.7,0.4-1.1,0.3c-0.3,0-0.6-0.2-0.8-0.4c-0.3-0.2-0.5-0.5-0.8-0.8c-0.4-0.5-0.8-0.9-1.3-1.2
		c-0.3-0.2-0.6-0.3-1-0.2c-0.4,0.1-0.7,0.2-1,0.5c-0.3,0.2-0.6,0.6-0.8,0.8c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.2-0.4,0.3-0.7,0.4
		c-0.2,0.1-0.4,0.1-0.6,0.1c-0.5-0.3-1-0.7-1.3-1.1c-0.3-0.3-0.5-0.6-0.8-1c-0.4-0.4-0.8-0.7-1.3-1c-0.6-0.3-1.3-0.4-2-0.3
		c-0.3,0.1-0.7,0.3-0.9,0.5c-0.1,0.1-0.3,0.2-0.4,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4,0.6-1,1.1-1.7,1.4c-0.6,0.2-1.3,0.1-1.9-0.2
		c-0.8-0.4-1.5-1-2.1-1.7l-0.5-0.5c-0.2-0.2-0.4-0.4-0.5-0.5c-0.5-0.5-0.7-1.2-0.5-1.9c0.1-0.8,0.1-1.5,0-2.3
		c-0.1-0.5-0.3-1-0.6-1.4c-0.2-0.3-0.4-0.6-0.7-0.8c-0.4-0.4-0.3-0.4-0.3-0.5c-0.1-0.7-0.2-1.5-0.4-2.1c-0.2-0.6-0.4-1.1-0.5-1.6
		c0-0.1,0-0.2-0.1-0.4c0-0.1,0-0.2,0-0.2c0-0.2,0-0.4,0-0.6c0.1-0.7,0.2-1.4,0.3-2.1c0-0.3,0-0.6-0.1-0.8c0-0.2-0.1-0.3-0.1-0.4
		c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.3-0.2-0.5-0.3-0.7c-0.1-0.4-0.1-0.4-0.1-0.5c0,0,0-0.1,0.1-0.1l0.3-0.4c0.3-0.5,0.5-1,0.6-1.5
		c0.1-0.9,0-1.7-0.2-2.5c-0.1-0.4-0.2-0.7-0.3-1l-0.2-0.4l0-0.1l0,0c0,0,0,0,0-0.1c0-0.1,0.1-0.3,0.2-0.4c0.2-0.4,0.5-0.7,0.8-1.1
		c0.2-0.2,0.3-0.3,0.4-0.5c0.2-0.2,0.3-0.5,0.4-0.7c0.2-0.4,0.2-0.9,0.2-1.4c0-1.3-0.2-2.5-0.6-3.7l0-0.2l0,0c0,0,0-0.1,0-0.1
		c0-0.2,0-0.4,0.1-0.6l0.3-1.5c0.2-1.1,0.3-2.2,0.2-3.3c0-0.2,0-0.3-0.1-0.5l-0.1-0.3c0-0.2-0.1-0.3-0.1-0.5c0-0.3,0-0.6,0.2-0.9
		c0.1-0.3,0.4-0.7,0.6-1.1c0.3-0.5,0.5-1,0.6-1.6c0.1-0.9,0.1-1.8,0-2.7c-0.1-0.7-0.1-1.4,0.1-2.1c0.1-0.6,0.4-1.2,0.7-1.8
		c0.1-0.3,0.3-0.5,0.5-0.8c0.1-0.1,0.2-0.2,0.2-0.4l0.3-0.4c0.3-0.6,0.6-1.2,0.7-1.8c0.2-0.9,0.3-1.8,0.3-2.7c0-0.2,0-0.4,0-0.6
		c0-0.2,0-0.3,0-0.5c0.1-0.4,0.3-0.8,0.5-1.2c0.2-0.4,0.5-0.9,0.9-1.4c0.2-0.3,0.3-0.6,0.5-0.9c0.1-0.4,0.2-0.8,0.3-1.2
		c0-0.8-0.1-1.6-0.3-2.3c-0.1-0.4-0.2-0.7-0.3-1.1c0-0.2,0-0.5,0.1-0.7c0.4-0.8,0.7-1.7,0.9-2.6c0.1-0.6,0-1.2-0.1-1.7
		c-0.1-0.5-0.3-1-0.5-1.5c-0.2-0.4-0.3-0.8-0.5-1.2c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1l0-0.2
		c0.3-1.1,0.4-2.2,0.5-3.3c0-0.2,0-0.4,0-0.6c0-0.1,0-0.2-0.1-0.4c0-0.1-0.1-0.2-0.1-0.3l-0.1-0.1v-0.1c-0.3,0.2,0.7-0.4-0.7,0.4h0
		c0.2-0.1,0.3-0.2,0.5-0.2c0.2-0.1,0.2-0.1,0.2-0.1v-0.1l0-0.3c-0.1-0.4-0.1-1-0.2-1.5s-0.1-1.1-0.2-1.7c0-0.1,0-0.3-0.1-0.5
		s-0.1-0.4-0.1-0.5c-0.1-0.3-0.2-0.6-0.3-0.9c-0.5-1.2-1.2-2.4-2.1-3.4c-0.1-0.1-0.3-0.3-0.4-0.4l-0.2-0.2l-0.1-0.1l-0.1,0l0,0l0,0
		l0,0c0,0,0-0.1,0-0.1c-0.2-0.4-0.3-0.8-0.3-1.3c-0.1-0.5-0.2-1.1-0.2-1.7c-0.1-0.7-0.2-1.3-0.3-2c0-0.2-0.1-0.4-0.2-0.7
		c-0.1-0.3-0.3-0.5-0.4-0.8c-0.2-0.3-0.4-0.6-0.6-0.8c-0.3-0.4-0.6-0.8-0.8-1.3c-0.1-0.2-0.2-0.5-0.3-0.7c0-0.1-0.1-0.2-0.1-0.4
		l0-0.5c0-0.5-0.1-1-0.3-1.5c-0.2-0.5-0.4-1-0.6-1.5c-0.3-0.4-0.5-0.7-0.6-1c-0.1-0.1-0.2-0.2-0.2-0.4c-0.1-0.1-0.1-0.2-0.1-0.3
		c-0.2-0.6-0.3-1.2-0.4-1.8c-0.1-1-0.5-2-1.1-2.9c0,0,0,0.1,0-0.1c0-0.3-0.1-0.5-0.1-0.8c0-0.8-0.1-1.6-0.3-2.4
		c-0.1-0.5-0.4-1-0.7-1.5c-0.2-0.2-0.4-0.5-0.6-0.6l-0.3-0.3l-0.1-0.1c-0.2-0.2-0.4-0.4-0.5-0.7c-0.1-0.5-0.1-1-0.1-1.5v-1v-0.5
		c0-0.2,0-0.5-0.1-0.7c-0.2-0.9-0.6-1.7-1.3-2.4c-0.5-0.5-1-0.9-1.6-1.2l-0.6-0.4c-0.1-0.1-0.2-0.2-0.2-0.3
		c-0.4-0.8-0.7-1.7-0.9-2.6c-0.2-0.9-0.4-1.8-0.5-2.6v-0.2v-0.3c0-0.2-0.1-0.4-0.1-0.5c-0.1-0.3-0.3-0.6-0.6-0.9
		c-0.4-0.4-0.8-0.7-1.2-1c-0.7-0.4-1.5-0.7-2.2-0.9c-0.5-0.1-0.9-0.4-1.3-0.8c-0.6-0.6-1.2-1.2-1.7-1.9c-0.4-0.4-0.6-0.7-0.7-0.6
		s-0.1,0.4,0.1,1c0.3,0.9,0.7,1.7,1.3,2.4c0.2,0.3,0.4,0.5,0.7,0.8c0.1,0.1,0.3,0.3,0.5,0.4c0.2,0.1,0.4,0.2,0.6,0.3
		c0.6,0.3,1.2,0.6,1.8,1c0.3,0.2,0.5,0.4,0.7,0.6c0.1,0.2,0.2,0.4,0.1,0.6c0,1,0.1,2,0.3,3c0.1,1.2,0.5,2.3,0.9,3.4
		c0.2,0.4,0.4,0.8,0.8,1.1c0.3,0.3,0.5,0.4,0.8,0.6c0.4,0.3,0.8,0.6,1.1,1c0.2,0.2,0.3,0.5,0.4,0.8v0.2v0.4l-0.1,0.9
		c-0.1,0.8-0.1,1.6,0.1,2.4c0.2,1,0.8,1.9,1.6,2.6l0.3,0.2l0,0c0,0,0,0.1,0.1,0.1c0.1,0.1,0.1,0.2,0.1,0.4c0.1,0.6,0.1,1.1,0.1,1.7
		c0,0.4,0,0.9,0.1,1.3c0,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.3,0.6,0.5,0.9c0.2,0.4,0.3,0.8,0.3,1.2c0.1,0.8,0.3,1.7,0.5,2.5
		c0.1,0.3,0.2,0.5,0.4,0.8c0.1,0.2,0.2,0.4,0.3,0.6c0.2,0.4,0.5,0.7,0.6,0.9c0.2,0.4,0.4,0.9,0.4,1.4l0,0.6c0,0.3,0.1,0.5,0.1,0.8
		c0.1,0.5,0.3,1,0.5,1.5c0.3,0.7,0.7,1.3,1.2,1.9c0.2,0.3,0.4,0.5,0.5,0.7l0,0c0,0.1,0,0.1,0.1,0.2c0.1,0.5,0.2,1.1,0.3,1.6
		c0.1,0.6,0.2,1.2,0.3,1.9c0.1,0.7,0.3,1.5,0.7,2.1c0.2,0.5,0.6,0.9,1,1.3c0,0,0,0,0,0l0.1,0l0,0l0,0l0.1,0.1
		c0.1,0.1,0.1,0.1,0.2,0.2c0.7,0.7,1.2,1.5,1.6,2.4c0.1,0.2,0.2,0.4,0.3,0.6l0.1,0.3l0.1,0.3c0.1,0.5,0.2,1,0.3,1.5s0.2,1.1,0.3,1.7
		c0.1,0.4,0.2,0.8,0.4,1.2l0,0c1.6-0.9,0.4-0.2,0.8-0.4h-0.1c0,0-0.1,0.1-0.1,0.1l-0.3,0.1c-0.4,0.1-0.2,0-0.3,0.1
		c0,0.1,0,0.1,0,0.2c0,0.9,0,1.8-0.2,2.7l-0.1,0.4c0,0.2,0,0.4,0,0.6c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0.1,0.3,0.2,0.5
		c0.2,0.5,0.4,0.9,0.7,1.4c0.4,0.6,0.6,1.3,0.7,2c-0.1,0.8-0.3,1.5-0.6,2.2c-0.2,0.5-0.2,1.1,0,1.7c0.1,0.4,0.3,0.8,0.4,1.1
		c0.2,0.6,0.4,1.2,0.4,1.9c0,0.2,0,0.5-0.1,0.7c-0.1,0.3-0.2,0.5-0.3,0.8l-0.8,1.5c-0.2,0.5-0.4,1-0.5,1.5c0,0.3,0,0.5,0,0.8
		c0,0.2,0,0.4,0,0.5c0,0.8-0.2,1.7-0.4,2.5c-0.1,0.5-0.3,0.9-0.5,1.3c0,0-0.2,0.2-0.3,0.3s-0.2,0.3-0.3,0.4
		c-0.2,0.3-0.4,0.6-0.6,0.9c-0.4,0.7-0.7,1.4-1,2.1c-0.1,0.4-0.2,0.8-0.3,1.3c0,0.5,0,0.8-0.1,1.3c0,0.8-0.1,1.5-0.2,2.3
		c-0.1,0.3-0.2,0.6-0.4,0.9c-0.3,0.4-0.6,0.8-0.8,1.2c-0.3,0.6-0.5,1.2-0.5,1.9c0,0.3,0,0.6,0,0.9c0,0.1,0,0.3,0,0.4v0.2
		c0,0.9-0.2,1.8-0.5,2.6c-0.1,0.5-0.3,1-0.4,1.5c-0.1,0.3-0.1,0.6-0.2,1c0,0.2,0,0.5,0,0.7c0,0.1,0,0.3,0.1,0.4l0,0.2
		c0.3,0.9,0.4,1.8,0.4,2.8c0,0.1,0,0.2-0.1,0.3l0,0l-0.1,0.1c-0.5,0.5-1,1-1.3,1.6c-0.2,0.4-0.4,0.8-0.6,1.2
		c-0.2,0.5-0.2,1.1-0.1,1.6l0.1,0.4l0.1,0.3l0.1,0.4c0.1,0.3,0.2,0.5,0.2,0.7c0.1,0.3,0.1,0.7,0.1,1c0,0,0,0.1-0.1,0.1l-0.3,0.4
		c-0.1,0.2-0.3,0.4-0.4,0.7c-0.2,0.5-0.4,1.1-0.4,1.7c0,0.5,0.1,1,0.3,1.5c0.1,0.4,0.3,0.7,0.4,1c0,0.1,0,0.1,0,0.1l0,0
		c0,0,0,0.1,0,0.1c-0.1,0.5-0.1,1.1-0.3,1.6c-0.1,0.4-0.1,0.8-0.1,1.3c0,0.2,0,0.5,0.1,0.7c0,0.2,0.1,0.4,0.1,0.5
		c0.2,0.7,0.4,1.4,0.6,1.9c0.2,0.4,0.3,0.9,0.3,1.4c0,0.5,0.2,1.1,0.5,1.5c0.2,0.4,0.5,0.7,0.8,1c0.5,0.5,0.5,0.6,0.6,0.7
		c0.1,0.4,0.1,0.8,0,1.1c-0.1,0.8,0,1.6,0.3,2.3c0.3,0.7,0.7,1.4,1.2,2c0.3,0.3,0.4,0.4,0.6,0.6l0.6,0.6c0.9,0.8,1.9,1.5,3,2
		c0.6,0.3,1.3,0.4,2,0.5c0.7,0,1.4-0.1,2-0.4c1-0.5,1.9-1.3,2.5-2.3l0.3-0.4c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.1,0.3-0.1,0.5,0
		c0.3,0.1,0.5,0.3,0.7,0.5c0.3,0.3,0.6,0.5,0.9,0.8c0.3,0.3,0.6,0.6,1,0.9c0.4,0.3,0.9,0.5,1.4,0.6c0.5,0.1,1,0,1.5-0.3
		c0.4-0.2,0.7-0.4,1-0.7c0.3-0.3,0.5-0.5,0.7-0.8c0.2-0.3,0.3-0.4,0.4-0.5c0.1-0.2,0.3-0.3,0.5-0.3c0.2,0,0.4,0,0.5,0.1
		c0.5,0.3,1.1,1.1,1.7,1.6c0.4,0.3,0.8,0.6,1.3,0.7c0.6,0,1.2-0.1,1.7-0.4c1.2-0.4,2.3-0.9,3.4-1.6c0.7-0.4,1.3-1,1.8-1.7
		c0.5-0.7,0.8-1.6,1-2.4c0.2-0.9,0.3-1.9,0.2-2.8c-0.1-1-0.2-1.9-0.5-2.8c-0.2-0.9-0.4-1.8-0.5-2.7c0-0.1,0-0.2,0-0.3
		c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.2,0.3-0.2c0.1,0,0.2,0,0.3,0.1c0,0,0.1,0.1,0.1,0.1v0.1l0.2,0.3l0.4,0.7c0.1,0.2,0.5,0.7,0.4,0.6
		c0,0,0,0.1,0,0.1l0,0c0,0,0,0,0,0.1l-0.1,0.4c-0.4,1.4-0.4,2.9,0,4.3c0.3,0.8,0.8,1.4,1.4,2c0.6,0.6,1.2,1,1.9,1.4
		c0.8,0.4,1.7,0.6,2.6,0.5c1-0.1,1.9-0.5,2.7-1.1c1.1-1,1.9-2.3,2.3-3.8c0.1-0.1,0.1-0.3,0.2-0.4c0,0,0.1,0,0.1,0c0.5,0,1,0,1.5,0.2
		l1.1,0.2l0.6,0.1c0.2,0,0.4,0.1,0.8,0.1c0.7,0.1,1.4-0.1,2.1-0.4c0.6-0.3,1.1-0.7,1.5-1.3c0.4-0.9,0.6-1.6,0.8-2c0.1-0.1,0,0,0-0.1
		l0,0l0,0l0.1,0l0.6-0.2c0.9-0.3,1.7-0.9,2.4-1.5c0.2-0.2,0.4-0.5,0.5-0.8c0.1-0.2,0.2-0.3,0.2-0.5v-0.1V326c1,0.2-1.3-0.3-1.1-0.3
		l0,0l0,0l0,0h0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.2,0.2,0.3,0.3,0.5l0.1,0.2l0,0h0.1c0.4-0.1,1.1-0.1,1.9-0.3c0.6-0.1,1.1-0.3,1.6-0.7
		c0.2-0.2,0.4-0.4,0.6-0.7c0.2-0.2,0.3-0.5,0.4-0.8c0.1-0.4,0.2-0.8,0.3-1.3c0-0.3,0.1-0.6,0.1-0.9c0.1-0.5,0.1-1,0.3-1.6
		c0-0.2,0.1-0.3,0.2-0.5c0-0.1,0-0.1,0.1-0.1c0-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.4-0.3,0.6-0.5c0.3-0.3,0.6-0.6,0.8-1
		c0.4-0.7,0.6-1.4,0.5-2.2c0-0.6,0-1.2-0.1-1.8c-0.3-1.9-0.9-3.7-1.7-5.4c-0.3-0.5-0.4-1.1-0.3-1.7c0-0.2,0.1-0.5,0.2-0.7
		c0.1-0.2,0.3-0.5,0.4-0.7c0.2-0.3,0.5-0.6,0.9-0.8c0.4-0.1,0.7-0.3,1-0.5C457.8,304,457.9,303.7,457.9,303.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M415,162.4c-0.1,0.3,0.9,0.6,1.8,1.8c0.5,0.6,1,1.5,1.9,2.5c0.2,0.3,0.5,0.5,0.8,0.8l0.4,0.4l0.3,0.3
		c0.4,0.4,0.7,0.9,0.9,1.4c0.1,0.3,0.3,0.7,0.5,1.1c0.3,0.6,0.7,1.1,1.3,1.4c0.2,0.1,0.3,0.2,0.5,0.2l0.1,0.1
		c0.1,0.1,0.2,0.1,0.3,0.2s0.5,0.5,0.8,0.8c0.6,0.8,1.3,1.5,2.1,2.2c0.5,0.4,1,0.7,1.5,1l0.6,0.3c0.1,0.1,0.3,0.2,0.4,0.3
		c0.7,0.6,1.3,1.2,1.8,1.9c0.4,0.5,0.8,1,1.2,1.4c0.3,0.3,0.6,0.5,0.9,0.7c0.4,0.3,0.5,0.3,0.6,0.4c0.8,0.6,1.5,1.3,2.1,2.1
		c0.4,0.4,0.8,0.9,1.3,1.4c0.3,0.3,0.6,0.5,1,0.8l0.8,0.4c0.4,0.2,0.8,0.5,1.1,0.7c0.1,0.1,0.3,0.2,0.4,0.3l0.1,0.1l0,0l0,0l0.1-0.1
		l0.3-0.2h0.1l-0.5,0.2l0,0l0,0l0.1,0.1c0.1,0.2,0.2,0.3,0.3,0.4c0.2,0.3,0.3,0.5,0.5,0.7c0.3,0.4,0.6,0.8,0.9,1.1
		c0.6,0.7,1.3,1.4,2,2.1c0.3,0.3,0.7,0.6,1,0.9l1,0.8c0.5,0.4,0.9,0.9,1.2,1.5c0.4,0.6,0.6,1.3,1.1,2.1c0.1,0.2,0.3,0.5,0.5,0.7
		c0.1,0.1,0.2,0.3,0.4,0.4c0.1,0.1,0.2,0.1,0.3,0.2l0.1,0.1h0.1c0.1-0.3,0-0.1,0.1-0.1l0,0l0,0c-0.1,0.1-0.1,0.1,0,0.1s0,0,0.1,0
		c0.1,0.1,0.2,0.2,0.2,0.3c0.2,0.2,0.4,0.6,0.6,0.9c0.3,0.4,0.6,0.8,1,1.1c0.1,0.1,0.2,0.2,0.4,0.2l0.2,0.1h0.1
		c0.1,0,0.2,0.1,0.3,0.2c0.2,0.2,0.5,0.4,0.7,0.6c0.5,0.4,1,0.9,1.5,1.3l0.8,0.6c0.2,0.1,0.4,0.3,0.6,0.5c0.3,0.3,0.6,0.7,0.7,1.1
		c0.2,0.8,0.3,1.7,0.3,2.5c-0.1,1.1,0.9,1.7,1.3,2c0.5,0.3,1,0.8,1.3,1.3c0.2,0.3,0.3,0.6,0.4,0.9c0.1,0.5,0.1,0.9,0.2,1.4
		c0,1.2,0.2,2.4,0.4,3.6c0.1,0.2,0.1,0.4,0.2,0.6c0,0.1,0.1,0.2,0.1,0.3l0.1,0.1v0.1c0.1,0.2,0.2,0.4,0.2,0.7
		c0.1,0.6,0.2,1.2,0.2,1.9s0.1,1.5,0.3,2.3c0.1,0.4,0.2,0.9,0.4,1.3l0.1,0.3l0.1,0.2c0,0.1,0.1,0.2,0.1,0.4c0,0.5,0,0.6-0.5,1.2
		l-0.2,0.3c-0.1,0.2-0.2,0.3-0.3,0.5c-0.2,0.4-0.2,0.7-0.2,1.1c0,0.4,0.1,0.8,0.2,1.1c0.1,0.3,0.2,0.5,0.4,0.8
		c0.9,1.7,1.9,2.9,1.8,4c0,0.6-0.2,1.5-0.3,2.5c-0.1,0.6-0.1,1.2,0,1.8c0.1,0.5,0.2,1.1,0.3,1.6c0.2,0.8,0.4,1.6,0.5,2.5
		c0,0.2,0,0.3,0,0.5v0.7c0,0.6,0,1.2,0.2,1.8c0.2,1.1,0.6,2.1,1.2,3c0.3,0.5,0.6,0.9,0.9,1.3l0.2,0.2l0.1,0.1
		c0.1,0.1,0.2,0.2,0.2,0.3c0.3,0.6,0.5,1.2,0.5,1.8c0,0.3,0.1,0.7,0.1,1v0.6c0,0.2,0,0.5,0,0.7c0.2,1.6,0.6,3.2,1.2,4.6
		c0.5,1.4,1.2,2.6,1.4,3.5c0.1,0.5,0.1,1-0.1,1.4c0,0.3-0.1,0.5-0.2,0.9c0,0.3,0,0.7,0,1c0.2,1,0.5,1.9,0.9,2.8
		c0.3,0.8,0.6,1.4,0.8,1.9c0.5,1.5,0.9,3,1.1,4.5c0.2,1.1,0.3,2.2,0.3,3.3c-0.1,1.2-0.3,2.4-0.7,3.6c-0.2,0.8-0.4,1.6-0.4,2.4
		c0,0.3,0,0.5,0,0.8c0,0.2,0,0.3,0.1,0.5l0.1,0.2v0.1c0.1,0.2,0.1,0.5,0.1,0.7c0,0.6-0.1,1.4-0.1,2.5c0,0.6,0.2,1.3,0.5,1.8l0.1,0.2
		l0,0c0,0,0,0,0,0v0.3c-0.2,0.7-0.4,1.4-0.6,2.1c-0.1,0.3-0.2,0.6-0.2,0.9c0,0.2-0.1,0.4-0.1,0.5l0,0l-0.1,0
		c-0.3,0.2-0.5,0.3-0.8,0.4l-0.6,0.2l-0.3,0.1l-0.2,0l-0.3,0.1c-0.7,0.3-1.3,0.8-1.8,1.4c-0.4,0.5-0.7,1-1,1.5l-0.8,1.6l-0.2,0.4
		l-0.1,0.2l-0.1,0.3c-0.2,0.3-0.3,0.7-0.4,1.1c-0.4,1.3-0.5,2.5-0.7,3.4c-0.1,0.4-0.2,0.7-0.3,1.1c0,0.1,0,0.1-0.1,0.1
		c-0.2,0-0.4,0-0.6-0.1c-0.4-0.1-0.7-0.3-1.1-0.5c-0.7-0.5-1.4-1-2-1.6c-0.4-0.4-0.7-1-0.8-1.6c0-0.3,0-0.7,0-1v-0.3v-0.4
		c0-0.3,0-0.6-0.1-0.9c-0.2-1-0.4-1.7-0.6-2.4c-0.1-0.6-0.2-1.2-0.2-1.8c0-0.6,0.1-1.4,0.1-2c0-1.1-0.1-2.2-0.3-3.3
		c-0.4-1.8-1-2.6-1.2-2.6s-0.1,1-0.2,2.7c-0.1,1-0.2,2-0.3,3c-0.1,0.6-0.2,1.1-0.4,1.9c-0.1,0.8-0.2,1.7-0.1,2.5
		c0.1,0.8,0.3,1.7,0.3,2.4c0,0.1,0,0.3,0,0.4l-0.1,0.6c-0.1,0.5-0.1,1.1-0.1,1.6c0.1,1.3,0.6,2.5,1.5,3.4c0.7,0.8,1.6,1.6,2.5,2.3
		c0.5,0.4,1.1,0.7,1.7,0.9c0.8,0.4,1.6,0.5,2.5,0.4c1-0.2,1.9-0.8,2.4-1.7c0.3-0.6,0.6-1.3,0.8-2c0.3-1.2,0.4-2.3,0.7-3.2
		c0.1-0.2,0.1-0.4,0.2-0.5l0-0.1l0.1-0.2l0.2-0.4c0.3-0.5,0.5-1,0.8-1.5c0.2-0.5,0.6-0.9,1-1.3l0,0l0.2,0l0.3-0.1l0.7-0.3
		c0.6-0.2,1.1-0.5,1.6-0.9c0.7-0.5,1.2-1.3,1.4-2.1c0-0.2,0-0.5,0.1-0.6c0,0,0-0.1,0-0.1c0-0.2,0.1-0.3,0.1-0.5c0.3-1,0.5-2,0.6-3
		c0-0.4-0.1-0.8-0.2-1.1c-0.1-0.2-0.2-0.4-0.3-0.5l-0.1-0.2l0,0c-0.1-0.2-0.2-0.4-0.2-0.6c-0.1-0.6,0-1.4-0.1-2.4
		c0-0.5-0.2-1-0.3-1.5l-0.1-0.2c0,0,0,0,0-0.1c0-0.1,0-0.2-0.1-0.4c0-0.6,0-1.3,0.1-1.9c0.3-1.4,0.4-2.8,0.3-4.3
		c-0.2-1.3-0.5-2.4-0.8-3.4c-0.4-1.6-0.9-3.1-1.5-4.6c-0.2-0.5-0.5-1.1-0.7-1.9c-0.3-0.8-0.5-1.6-0.5-2.5c0-0.5,0.1-0.9,0.3-1.3
		c0.3-0.7,0.4-1.4,0.3-2.1c-0.1-0.7-0.2-1.4-0.5-2.1c-0.2-0.6-0.4-1.3-0.5-1.9c-0.4-1.3-0.6-2.7-0.6-4.1c0-0.2,0-0.3,0-0.5l0-0.5
		c0-0.5,0-0.9,0-1.4c0-1.1-0.3-2.1-0.7-3c-0.1-0.3-0.3-0.5-0.4-0.8c-0.1-0.1-0.2-0.3-0.3-0.4l-0.2-0.3c-0.2-0.3-0.4-0.6-0.5-0.9
		c-0.3-0.6-0.6-1.3-0.7-2c0-0.3,0-0.6,0-0.9v-0.2c0-0.2,0-0.3,0-0.5c0-0.3,0-0.6,0-0.9c-0.1-1-0.3-2.1-0.6-3.1
		c-0.1-0.4-0.2-0.8-0.3-1.2c0-0.3,0-0.7,0-1c0.1-1,0.2-1.9,0.3-2.9c0-0.6-0.1-1.2-0.3-1.8c-0.2-0.5-0.4-1-0.7-1.5
		c-0.5-0.9-1.1-1.6-1.4-2.2c-0.1-0.1-0.2-0.4-0.2-0.3c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1v0l0.1-0.2c0.3-0.5,0.5-1,0.6-1.5
		c0.1-0.6,0.1-1.2,0-1.8c-0.1-0.3-0.1-0.5-0.3-0.8l-0.3-0.6c-0.1-0.3-0.3-0.6-0.3-0.9c-0.2-0.7-0.4-1.3-0.5-2
		c-0.1-0.7-0.3-1.4-0.5-2.1c-0.1-0.4-0.3-0.8-0.5-1.1l-0.1-0.2l-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c-0.4-1-0.6-2.1-0.8-3.1
		c-0.1-0.5-0.2-1-0.3-1.5c-0.1-0.5-0.4-1-0.7-1.4c-0.5-0.5-1-1-1.7-1.4c-0.2-0.1-0.4-0.3-0.6-0.5c-0.2-0.2-0.2-0.5-0.2-0.7
		c0.2-1,0.2-1.9,0-2.9c-0.2-0.6-0.5-1.2-0.9-1.7c-0.2-0.3-0.4-0.5-0.7-0.7l-0.6-0.6c-0.4-0.4-0.8-0.9-1.3-1.4
		c-0.3-0.3-0.5-0.6-0.8-0.8c-0.2-0.1-0.4-0.3-0.5-0.4l-0.1-0.1l0,0l-0.1-0.1c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.3-0.3-0.6-0.5-1
		c-0.3-0.6-0.7-1-1.2-1.3l0,0l0,0c-0.1,0.2,0.3-0.6-0.3,0.5v-0.1l0.1-0.1c0-0.1,0.1-0.1,0.2-0.2l0.1-0.1l0,0l0,0
		c0-0.1-0.1-0.2-0.2-0.3c-0.5-1.8-1.4-3.4-2.7-4.8l-0.9-0.9c-0.3-0.3-0.6-0.6-0.9-0.9c-0.6-0.6-1.1-1.3-1.7-1.9
		c-0.3-0.3-0.5-0.7-0.8-1c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.2,0,0-0.3-0.6c-0.4-0.6-0.9-1.1-1.5-1.5c-0.4-0.3-0.9-0.6-1.3-0.8
		l-0.7-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.4-0.4-0.7-0.7-1.1-1.1c-0.8-1-1.7-1.8-2.8-2.6c-0.4-0.2-0.8-0.5-1.2-0.8
		c-0.3-0.3-0.6-0.6-0.9-1c-0.7-1-1.6-1.8-2.5-2.5c-0.3-0.2-0.6-0.4-0.9-0.5l-0.7-0.3c-0.3-0.1-0.6-0.3-0.9-0.6
		c-0.7-0.5-1.3-1.1-1.8-1.8c-0.4-0.4-0.7-0.7-1.1-1c-0.2-0.2-0.5-0.4-0.8-0.5l-0.5-0.2c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.4-0.3
		s-0.3-0.6-0.5-1c-0.4-0.8-1-1.5-1.7-2l-0.5-0.3l-0.4-0.2c-0.2-0.1-0.5-0.3-0.7-0.5c-0.8-0.7-1.5-1.6-2.3-2.1
		c-0.6-0.5-1.3-0.8-2-0.9C415.3,162.2,415,162.3,415,162.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M333.8,290c-0.3,0-0.4,0.5-0.7,1.1c-0.4,0.8-1.1,1.5-1.8,2c-0.5,0.4-1.1,0.7-1.8,1.3c-0.2,0.1-0.4,0.3-0.6,0.5
		c-0.3,0.3-0.5,0.6-0.7,0.9c-0.3,0.6-0.5,1.2-0.6,1.9c-0.1,1.1-0.1,2.1-0.1,3c0,0.4,0,0.8-0.1,1.3c0,0.3-0.2,0.6-0.3,0.9
		c-0.4,0.6-0.9,1.1-1.4,1.6c-0.7,0.7-1.3,1.5-1.7,2.5c-0.2,0.6-0.3,1.2-0.3,1.8c0.1,0.5,0.2,1.1,0.5,1.5c0.4,0.8,0.9,1.4,1.5,2
		c0.5,0.5,1,0.9,1.6,1.3l0.4,0.2c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4c0.1,0.2,0.1,0.4,0.2,0.5
		c0.1,0.3,0.2,0.6,0.2,0.9c0.1,0.5,0.1,1.2,0.1,2.2c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2,0,0.2v0.1l0,0c0,0.2,0,0.3-0.1,0.5
		c-0.1,0.4-0.3,1-0.5,1.6l-0.2,0.5c-0.1,0.3-0.1,0.5-0.2,0.8c0,0.1-0.1,0.3,0,0.4c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.3,0.2,0.4l0.3,0.4
		c0.2,0.3,0.4,0.5,0.7,0.7c0.2,0.2,0.4,0.3,0.6,0.4c0.4,0.3,0.7,0.5,1.1,0.7c0.8,0.4,1.6,0.8,2.4,1.1c0.4,0.1,0.9,0.3,1.4,0.4
		c0.1,0,0.4,0.1,0.6,0.1s0.4,0.1,0.6,0.1h0.6c0.2,0,0.4-0.1,0.6-0.1c0.8-0.2,1.4-0.8,1.7-1.5c0.2-0.4,0.2-0.8,0.3-1.2
		c0-0.3,0-0.7,0-1c0-0.6,0-1.3,0-1.9c0.1-0.5,0.3-0.9,0.7-1.2c0.4-0.5,1-0.9,1.5-1.6c0.3-0.4,0.6-0.9,0.6-1.4c0-0.1,0-0.1,0-0.2
		c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0.2-0.4,0.5-0.7,0.8-0.9c0.7-0.6,1.4-1.1,1.7-1.5c0.6-0.5,1.1-1.2,1.5-1.8
		c0.3-0.5,0.6-1,0.8-1.5c0.1-0.3,0.2-0.6,0.4-0.9s0.2-0.8,0.2-0.9c0-0.1,0.1-0.2,0.2-0.2c0.2-0.1,0.4-0.1,0.5-0.2
		c0.9-0.3,1.8-0.9,2.4-1.6c0.2-0.2,0.3-0.4,0.5-0.6s0.3-0.4,0.4-0.6c0.3-0.4,0.5-0.9,0.7-1.3c0.2-0.5,0.3-1,0.4-1.2
		c0.1-0.2,0.2-0.4,0.3-0.6l0.2-0.3c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.4,0.4-0.9,0.5-1.3c0.1-0.7,0.1-1.4-0.1-2.1
		c-0.1-0.4-0.1-0.8-0.2-1.2c0-0.1,0-0.1,0-0.2l0,0c0,0,0,0,0-0.1c-0.1,0,0.2-0.2,0.6-0.5c0.2-0.1,0.3-0.2,0.5-0.3
		c0.2-0.1,0.3-0.2,0.5-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.3,0.2-0.7,0.2-1.1v-0.6c0-0.4,0-0.7,0-1c0-0.2,0-0.3,0-0.5l-0.1-0.1l0,0
		c0.1,0.2,0,0,0.1,0.1l0,0l0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.5,0.3-1,0.3-1.6c0-0.9-0.1-1.3-0.4-1.4
		s-0.6,0.3-1,1c-0.2,0.3-0.5,0.7-0.8,1.1c0,0.1-0.1,0.1-0.2,0.1l0,0l0.1,0.1c0,0,0.1,0.1,0.1,0.2l0,0l-0.3-0.4l0,0l0,0l0,0
		c-0.1,0.1-0.2,0.2-0.4,0.3c-0.3,0.4-0.5,0.9-0.7,1.4c-0.1,0.4-0.2,0.8-0.3,1.2l-0.1,0.6l-0.1,0.1c0,0-0.1,0-0.1,0h0.1
		c0,0-0.1,0-0.1,0c-0.6,0.2-1.2,0.6-1.6,1c-0.3,0.3-0.6,0.7-0.7,1.1c-0.1,0.2-0.1,0.4-0.2,0.7c0,0.2,0,0.4,0,0.5
		c0,0.6,0,1.2,0.1,1.8c0.1,0.3,0.1,0.6,0,1c0,0,0,0.1,0,0.1l-0.1,0.1l0,0l-0.1,0.1l-0.2,0.2c-0.4,0.5-0.7,1.1-0.9,1.7
		c-0.2,0.6-0.2,0.9-0.3,1.2c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.1-0.1,0.3-0.2,0.5l-0.2,0.4c-0.3,0.5-0.7,0.8-1.1,1.1
		c-0.5,0.3-0.9,0.6-1.2,1c-0.4,0.4-0.6,1-0.6,1.5c0,0.5,0,0.6,0,0.9c0,0.3,0,0.5-0.1,0.8c-0.1,0.5-0.2,0.9-0.3,1.4
		c-0.2,0.6-0.5,1.2-1,1.7c-0.5,0.4-1.2,0.8-1.8,1c-0.6,0.3-1.1,0.6-1.5,1.1c-0.1,0.2-0.3,0.3-0.4,0.5c-0.1,0.1-0.1,0.2-0.1,0.3
		c0,0.1-0.1,0.3-0.1,0.3s-0.1,0.1-0.3,0.3c-0.3,0.3-1,0.6-1.6,1c-0.9,0.6-1.5,1.4-1.9,2.4c-0.3,0.9-0.4,1.9-0.4,2.9
		c0,0.3,0,0.2,0,0.2h-0.1c-0.4-0.1-0.7-0.1-1.1-0.2c-0.7-0.2-1.4-0.4-2.1-0.6c-0.3-0.1-0.6-0.2-0.9-0.4s-0.3-0.1-0.4-0.2H331
		c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.1,0-0.2v-0.5c0.1-0.7,0.1-1.3,0.1-2c0-0.4-0.1-0.7-0.2-1.1l-0.1-0.1l0,0c0,0,0-0.1-0.1-0.1
		c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.7-0.4-1.4-0.5-2.1c0-0.3-0.1-0.6-0.1-1c0-0.2,0-0.4-0.1-0.6s0-0.2-0.1-0.4c0-0.2-0.1-0.3-0.1-0.5
		c-0.1-0.3-0.3-0.6-0.6-0.8c-0.1-0.1-0.2-0.2-0.3-0.3l-0.3-0.3c-0.4-0.4-0.7-0.8-1-1.3c-0.3-0.5-0.5-1-0.7-1.5
		c-0.1-0.2-0.1-0.4,0-0.6c0-0.1,0-0.1,0.1-0.2l0.1-0.2c0.4-0.5,0.8-1,1.3-1.3c0.8-0.6,1.4-1.4,2-2.2c0.4-0.6,0.6-1.4,0.8-2.1
		c0.1-0.6,0.1-1.2,0.1-1.8c0-1.1-0.1-2.1-0.1-2.8c0-0.2,0-0.5,0.1-0.7c0-0.1,0-0.1,0-0.1s0.2-0.2,0.4-0.4c0.5-0.5,1.2-1.1,1.7-1.7
		c0.5-0.6,0.9-1.2,1.2-1.9c0.2-0.5,0.3-1.1,0.3-1.7C334.4,290.4,334,290,333.8,290z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M309.5,209.6c0-0.1-0.4-0.1-0.7,0.4c-0.3,0.7-0.4,1.4-0.3,2.1c0.1,0.5,0.1,0.9,0.3,1.4
		c0.1,0.4,0.1,0.9,0.1,1.4c0.1,1-0.3,1.9-1,2.6h-0.1l-0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.4,0.5-0.5,0.8
		c-0.2,0.5-0.3,1-0.4,1.5c0,0.7-0.1,1.5-0.2,2.2c0,0.2-0.1,0.3-0.2,0.5l0,0l-0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.4
		c-0.2,0.3-0.4,0.5-0.5,0.8c-0.4,1-0.7,2-0.9,3c-0.1,0.4-0.2,0.7-0.3,1.1c0,0.1-0.1,0.2-0.1,0.2l0,0l-0.1,0.1l-0.2,0.3
		c-0.1,0.2-0.2,0.4-0.3,0.6c-0.2,0.6-0.4,1.2-0.5,1.9c-0.1,0.5-0.2,1-0.3,1.5c-0.1,0.3-0.2,0.7-0.3,1c0,0.1-0.1,0.2-0.2,0.2l0,0l0,0
		l-0.1,0.1c-0.2,0.2-0.4,0.3-0.5,0.5c-0.5,0.7-0.9,1.5-1,2.3c-0.1,0.7-0.1,1.2-0.2,1.8c0,0.7-0.2,1.5-0.4,2.2
		c-0.1,0.3-0.2,0.6-0.4,0.8l-0.1,0.2l-0.2,0.3c-0.1,0.2-0.3,0.4-0.4,0.6c-0.5,0.9-0.9,1.8-1.1,2.9c-0.2,0.9-0.3,1.6-0.5,2.3
		c-0.2,0.7-0.5,1.3-0.9,1.9c-0.3,0.5-0.8,1-1.2,1.5c-0.5,0.5-1,1-1.6,1.5c-0.3,0.3-0.6,0.6-0.8,1c-0.3,0.4-0.4,0.8-0.5,1.3
		c-0.2,1.3-0.3,2.6-0.3,3.8c0,0.4-0.2,0.8-0.4,1.2c-0.1,0.2-0.3,0.3-0.4,0.5c-0.2,0.1-0.3,0.3-0.5,0.4c-0.8,0.5-1.5,1.1-2.1,1.8
		c-0.5,0.6-0.8,1.3-0.9,2c-0.1,0.6-0.3,1.1-0.5,1.6c-0.4,0.7-0.9,1.2-1.6,1.7c-0.4,0.3-0.8,0.5-1.2,0.8l-0.7,0.4l-0.4,0.2l-0.5,0.4
		c-0.6,0.5-1,1.3-1.2,2.1c-0.2,0.7-0.2,1.4-0.4,2c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.1-0.1,0.2-0.3,0.3c0,0-0.1,0-0.2,0.1
		c-0.2,0.1-0.5,0.2-0.7,0.3c-0.5,0.3-0.9,0.6-1.2,1.1c-0.6,0.8-0.9,1.7-1.2,2.1c-0.1,0.2-0.2,0.3-0.4,0.4c-0.1,0-0.1,0.1-0.2,0.1
		l-0.3,0.2c-0.1,0.1-0.3,0.1-0.4,0.2c-2.2,1.4-2.8,4.3-1.3,6.5c0.1,0.1,0.2,0.3,0.3,0.4c0.5,0.6,1.1,1,1.9,1.2
		c0.6,0.2,1.1,0.3,1.7,0.3c1,0,2,0,3-0.2c1-0.2,1.9-0.4,2.8-0.7c0.5-0.2,0.9-0.3,1.4-0.5c0.6-0.3,1.1-0.6,1.5-1
		c0.5-0.4,0.9-1,1.2-1.6c0-0.1,0-0.1,0-0.1l0,0l0.5-0.1l1.2-0.2c0.8-0.1,1.6-0.3,2.4-0.5c0.4-0.1,0.8-0.2,1.2-0.3s0.6-0.2,0.6-0.2
		c0.2-0.1,0.4,0,0.5,0.2c0,0,0,0,0,0c0.1,0.2,0.1,0.3,0.1,0.5v0.6c0,0,0,0.1,0,0.1c-0.1,0.4-0.4,0.8-0.7,1.1
		c-0.4,0.4-0.8,0.8-1.2,1.2c-0.9,0.8-1.7,1.4-2.4,2.2c-0.5,0.4-0.8,1-0.9,1.6c0,0.5,0.2,1.1,0.5,1.5c0.3,0.3,0.5,0.6,0.9,0.9
		c0.3,0.2,0.5,0.4,0.6,0.6l0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.2,0.4,0.1,0.7c0,0.1-0.1,0.2-0.1,0.4
		c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.3-0.5,0.7-0.8,1.1c-0.2,0.3-0.3,0.5-0.5,0.9c-0.1,0.3-0.2,0.7-0.2,1.1c0,0.4,0.2,0.8,0.4,1.1
		c0.1,0.2,0.3,0.3,0.4,0.4l0.2,0.2l0.1,0.1c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0,0.2,0,0.2v0.4c0,0.8,0.1,1.6,0.5,2.4
		c0.4,0.9,1,1.6,1.9,2c0.4,0.2,0.9,0.4,1.4,0.5c0.5,0.1,0.8,0.1,1.2,0.2c0.7,0.1,1.4,0.1,2.1,0.3c0.2,0.1,0.5,0.1,0.7,0.3
		c0.1,0.1,0.1,0.1,0.1,0.2s0,0,0,0c0,0,0,0.1,0,0.1c0,0.1,0,0.3,0.1,0.5c0,0.3,0,0.7,0,1.1c0,0.5,0,1,0.1,1.5
		c0.1,0.6,0.3,1.2,0.7,1.8c0.4,0.5,0.8,1,1.3,1.4c0.5,0.4,1.1,0.6,1.7,0.8c0.9,0.2,1.9,0.4,2.8,0.5c0.3,0,0.7,0.1,1,0.3
		c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.3,0.4,0.4c0.5,0.6,0.9,1.5,1.6,2.6c0.1,0.2,0.2,0.3,0.4,0.5c0.2,0.2,0.4,0.4,0.6,0.5
		c0.4,0.3,0.9,0.5,1.4,0.6c0.2,0,0.4,0.1,0.6,0.1h0.5c0.3,0,0.6,0,0.8,0c0.9-0.1,1.7-0.1,2.6,0c0.3,0,0.6,0.1,0.9,0.2
		c0.3,0.1,0.6,0.3,0.8,0.5c0.6,0.5,1.1,1.1,1.8,1.7c0.3,0.4,0.7,0.7,1.1,1c0.5,0.4,1.1,0.6,1.7,0.8c0.6,0.1,1.2,0,1.8-0.2
		c0.5-0.2,0.9-0.4,1.3-0.7c1.4-0.9,2.3-2.4,2.5-4c0.1-1,0-2.1-0.1-3.1c-0.3-1.6-0.6-2.5-0.8-2.5s-0.4,0.9-0.6,2.5
		c-0.1,0.9-0.2,1.8-0.4,2.7c-0.3,0.9-1,1.7-1.9,2.2c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0-0.3,0-0.5,0c-0.3-0.3-0.6-0.7-0.9-1.1
		c-0.6-0.7-1.2-1.4-1.9-2c-0.4-0.4-0.9-0.7-1.5-1c-0.6-0.3-1.2-0.5-1.8-0.6c-1.1-0.2-2.2-0.2-3.3-0.2h-0.7c-0.2,0-0.4,0-0.4,0
		s0,0,0,0l0,0l-0.1-0.1c-0.4-0.6-0.9-1.7-1.6-2.8c-0.2-0.3-0.4-0.5-0.7-0.8c-0.3-0.3-0.7-0.6-1.1-0.8c-0.7-0.3-1.4-0.5-2.1-0.6
		c-0.8-0.1-1.6-0.2-2.4-0.3c-0.2,0-0.4-0.1-0.6-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.3-0.5-0.4-1-0.4-1.5
		c0-0.4-0.1-0.9-0.1-1.4c0-0.3-0.1-0.5-0.1-0.7c0-0.2-0.1-0.3-0.1-0.5c-0.3-0.9-0.9-1.6-1.8-1.9c-0.5-0.2-1-0.4-1.5-0.5
		c-0.8-0.1-1.6-0.1-2.5-0.1c-0.4,0-0.7,0-1,0c-0.2,0-0.4-0.1-0.6-0.1c-0.4-0.2-0.8-0.5-1-0.8c-0.2-0.4-0.4-0.9-0.5-1.4l0-0.5
		c0-0.2-0.1-0.4-0.1-0.7c-0.2-0.5-0.5-1-1-1.3l-0.1-0.1l-0.2-0.1c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0-0.3,0-0.4c0-0.2,0.1-0.3,0.2-0.5
		c0.2-0.4,0.5-0.8,0.7-1.2c0.1-0.2,0.2-0.5,0.4-0.7c0.1-0.2,0.2-0.5,0.2-0.7c0.1-1-0.8-1.7-1.1-2l-0.6-0.6c-0.2-0.2-0.4-0.4-0.6-0.6
		c-0.2-0.2-0.2-0.5-0.2-0.7c0.1-0.3,0.3-0.5,0.5-0.7c0.7-0.5,1.6-1.1,2.5-1.8c0.5-0.4,1-0.7,1.5-1.2c0.7-0.5,1.2-1.2,1.5-2
		c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.3,0.1-0.4s0-0.4,0-0.6c0-0.5,0-1.1-0.1-1.6c-0.1-0.6-0.5-1.1-0.9-1.5c-0.5-0.3-1.1-0.5-1.8-0.5
		c-0.5,0-1,0.1-1.5,0.2l-1.1,0.2c-0.7,0.1-1.5,0.2-2.3,0.3l-1.2,0.1l-0.7,0.1c-0.4,0-0.8,0.1-1.1,0.3c-0.7,0.4-1.2,1-1.5,1.7
		c-0.1,0.2-0.2,0.3-0.3,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.7,0.3-1.1,0.4c-0.8,0.2-1.5,0.4-2.3,0.5c-0.7,0.1-1.5,0.2-2.2,0.2
		c-0.3,0-0.6,0-0.9-0.1c-0.3-0.1-0.2-0.1-0.3-0.1c-0.2-0.3-0.3-0.6-0.3-0.9c0-0.4,0.2-0.7,0.5-0.9c0,0,0.1,0,0.1-0.1
		c0.1,0,0.1-0.1,0.2-0.1c0.3-0.1,0.6-0.3,0.8-0.5c0.5-0.4,0.9-0.8,1.1-1.4c0.5-0.9,0.6-1.6,0.9-2c0.1-0.2,0.2-0.3,0.4-0.5l0.3-0.2
		c0.3-0.2,0.5-0.4,0.7-0.6c0.4-0.4,0.7-0.9,0.9-1.4c0.1-0.4,0.2-0.8,0.3-1.3c0.1-1.5,0-2.5,0.5-3l0.2-0.2l0.3-0.3l0.7-0.5
		c0.4-0.3,0.8-0.7,1.2-1c0.7-0.6,1.3-1.4,1.7-2.3c0.3-0.6,0.5-1.3,0.5-2c0.1-0.5,0.3-1,0.7-1.4c0.6-0.5,1.3-1,2-1.3
		c0.2-0.1,0.4-0.3,0.7-0.4c0.3-0.2,0.5-0.4,0.7-0.6c0.5-0.5,0.8-1.2,1-1.9c0.3-1.3,0.4-2.6,0.7-3.5c0.2-0.7,1.3-1.4,2.5-2.4
		c0.7-0.5,1.3-1.2,1.8-1.9c0.6-0.8,1-1.6,1.3-2.5c0.3-0.8,0.5-1.6,0.7-2.5c0.2-0.6,0.4-1.2,0.8-1.8l0.3-0.5l0.2-0.2l0.2-0.4
		c0.3-0.5,0.6-1,0.8-1.5c0.3-1,0.5-2,0.6-3c0-0.6,0.1-1.1,0.2-1.5c0-0.2,0.1-0.5,0.2-0.7l0,0l0,0l0.2-0.3l0.3-0.2
		c0.3-0.3,0.6-0.7,0.8-1c0.3-0.6,0.5-1.3,0.6-2c0.1-0.6,0.2-1.1,0.2-1.6c0-0.3,0.1-0.7,0.2-1V231l0,0l0,0l0,0l0,0
		c0,0,0.2,0.2,0.1,0.1l0.1-0.1c0.2-0.3,0.5-0.6,0.6-1c0.2-0.6,0.4-1.1,0.5-1.7c0.1-0.8,0.3-1.6,0.5-2.4c0.1-0.1,0.1-0.2,0.2-0.4
		c0,0,0.1-0.1,0.1-0.1l0-0.1l0.2-0.2c0.4-0.5,0.7-1.2,0.8-1.9c0.1-0.9,0.1-1.8,0-2.7c0-0.2,0-0.5,0.1-0.7c0-0.1,0-0.1,0-0.2
		c0,0,0,0,0-0.1s0,0,0,0l0.1-0.1c0.6-0.7,1.1-1.5,1.3-2.4c0.1-0.7,0.2-1.5,0-2.2c-0.1-0.6-0.3-1.2-0.5-1.8c-0.2-0.4-0.4-0.8-0.5-1.2
		c-0.2-0.5-0.4-1-0.4-1.5C309.5,209.9,309.6,209.7,309.5,209.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M348.9,196.8c0.1,0.4,4.6-0.4,11.5-3.1c4-1.6,7.9-3.4,11.7-5.4c1.1-0.6,2.1-1.2,3.3-1.8l1.7-1
		c0.6-0.3,1.2-0.7,1.7-1.1c2.2-1.5,4.4-3.2,6.6-4.9c1.8-1.4,3.4-2.9,5-4.4c0.8-0.8,1.6-1.5,2.3-2.3s1.4-1.6,2.1-2.4
		c2.6-3.1,5-5.9,7-8.8c0.9-1.4,1.6-2.9,2.1-4.5c0.4-1.3,0.7-2.6,0.9-4c0.2-1.1,0.3-2.2,0.3-3.3c0-0.9-0.1-1.7-0.4-2.5
		c-0.3-0.8-0.7-1.6-1.2-2.3c-0.7-0.9-1.7-1.7-2.8-2.1c-0.2-0.1-0.3-0.2-0.4-0.4c-0.2-0.2-0.3-0.5-0.4-0.7c-0.3-0.8-0.8-1.4-1.4-2
		c-0.4-0.3-0.8-0.5-1.2-0.7c-0.4-0.1-0.8-0.3-1.2-0.4c-0.8-0.2-1.7-0.4-2.5-0.6c-0.8-0.1-1.5-0.4-2.3-0.7c-0.9-0.4-2-0.7-3-0.8
		c-0.6,0-1.2,0-1.7,0.1c-0.3,0.1-0.5,0.1-0.8,0.2l-0.7,0.2c-0.4,0.1-0.7,0.2-1.1,0.2c-0.3,0-0.7-0.1-1-0.2c-0.4-0.1-1-0.3-1.5-0.4
		s-1.1-0.2-1.6-0.3c-1-0.2-2.1-0.4-3.2-0.5c-0.5-0.1-1.1-0.1-1.7-0.1h-0.9c-0.4,0-0.7,0-1.1,0.1c-1.2,0.2-2.4,0.4-3.5,0.8
		c-1.1,0.3-2.2,0.7-3.3,1.1l-1.6,0.6L363,139c-0.7,0.3-1.4,0.4-2.2,0.2c-0.4-0.1-0.9-0.2-1.4-0.4c-0.3-0.1-0.5-0.1-0.9-0.2
		c-0.4-0.1-0.7-0.1-1.1-0.1c-1.1,0.1-2.2,0.4-3.3,0.8l-0.6,0.2l-0.3,0.1h-0.1c-0.3,0-0.6-0.1-0.8-0.2c-0.9-0.4-1.8-0.7-2.7-1
		c-0.5-0.1-1.1-0.2-1.6-0.2c-0.6,0-1.2,0.2-1.8,0.4c-0.8,0.5-1.5,1.2-2.1,2c-0.2,0.3-0.4,0.5-0.6,0.8c-0.2,0.2-0.4,0.3-0.6,0.4
		c-0.6,0.3-1.3,0.5-2,0.8c-0.4,0.2-0.8,0.4-1.1,0.8c-0.1,0.1-0.1,0.2-0.2,0.3l-0.1,0.2v0.1v0.1l0.2,0.1l0,0c-0.1,0-0.2-0.1-0.2-0.1
		s0,0,0,0s0,0-0.1,0.1c-0.4,0.3-0.8,0.6-1.3,0.8c-0.5,0.2-0.9,0.4-1.4,0.6c-0.9,0.3-1.6,0.6-2.3,0.8c-1.5,0.6-2.7,2.2-4.3,3.9
		c-0.4,0.4-0.8,0.9-1.3,1.3c-0.1,0.1-0.2,0.2-0.3,0.3l-0.4,0.2l-1,0.5c-0.4,0.2-0.8,0.4-1.1,0.6c-0.3,0.3-0.7,0.5-1,0.8
		c-0.5,0.5-1.1,0.9-1.7,1.2c-0.7,0.3-1.5,0.6-2.2,0.8l-0.6,0.2c-0.3,0.1-0.6,0.2-0.9,0.4c-0.6,0.3-1.1,0.7-1.5,1.2
		c-1.5,1.9-1.7,4.1-2.6,5.3c-1.1,1.6-2.4,2.9-3.9,4.1l-0.6,0.5c-0.3,0.3-0.6,0.5-0.9,0.9c-0.5,0.6-0.8,1.3-1,2.1
		c-0.3,1.3-0.4,2.6-0.2,3.9l0.1,1c0,0.2,0,0.2,0,0.2s0,0.1,0,0.1c-0.2,0.4-0.4,0.7-0.7,1c-0.4,0.4-0.9,0.9-1.4,1.5
		c-0.3,0.3-0.5,0.6-0.7,1c-0.3,0.4-0.5,0.9-0.6,1.4c-0.2,0.8-0.3,1.6-0.3,2.4c0,0.5,0,1-0.1,1.5c0,0.1,0,0.2-0.1,0.2l0,0l-0.1,0.2
		c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.3-0.3,0.5-0.4,0.8c-0.3,0.9-0.2,2,0.1,2.9c0.5,1.4,1.4,2.5,1.3,2.8c0,0.5,0,1-0.1,1.5
		c0,0.5,0.1,1,0.3,1.4c0.2,0.4,0.4,0.7,0.6,1c0.8,1,1.8,1.7,3,2.2c0.9,0.4,1.9,0.8,2.9,1c2.9,0.7,5.8,1,8.8,0.9
		c2.1-0.1,3.2-0.3,3.2-0.6s-1.1-0.5-3.1-0.8c-2.8-0.5-5.5-1.1-8.1-2c-0.8-0.3-1.6-0.6-2.4-1.1c-0.7-0.4-1.4-0.9-1.9-1.5
		c-0.1-0.1-0.1-0.2-0.2-0.4c0-0.1,0-0.1,0-0.2c0.1-0.6,0.2-1.2,0.2-1.8c0-0.5-0.1-0.9-0.3-1.3c-0.1-0.3-0.3-0.6-0.5-0.9
		c-0.2-0.4-0.4-0.9-0.6-1.4c-0.1-0.4-0.1-0.7-0.1-0.7s0,0,0,0l0,0l0,0l0.1-0.2c0.3-0.4,0.6-0.9,0.7-1.4c0.2-0.8,0.2-1.5,0.2-2.3
		c0-0.5,0.1-1,0.2-1.4c0-0.2,0.1-0.3,0.2-0.4c0.1-0.2,0.3-0.4,0.5-0.6c0.4-0.4,0.8-0.9,1.3-1.4c0.7-0.7,1.2-1.5,1.5-2.4
		c0.1-0.3,0.2-0.6,0.2-0.9c0-0.3,0-0.5,0-0.8l-0.1-1c-0.1-0.9-0.1-1.8,0.1-2.7c0.1-0.3,0.2-0.6,0.4-0.9c0.1-0.1,0.2-0.2,0.3-0.3
		c0,0,0.2-0.2,0.3-0.3l0.3-0.3c1.7-1.5,3.2-3.2,4.4-5.1c0.3-0.5,0.6-1.1,0.8-1.6c0.2-0.5,0.3-1,0.4-1.4c0.2-0.8,0.5-1.5,0.9-2.2
		c0.2-0.2,0.4-0.4,0.6-0.6c0.1-0.1,0.2-0.1,0.4-0.2l0.6-0.2c0.9-0.3,1.7-0.7,2.5-1.2c0.8-0.5,1.5-1.1,2.1-1.8
		c0.3-0.3,0.5-0.6,0.7-0.8c0.2-0.2,0.5-0.4,0.8-0.5l0.9-0.6c0.1-0.1,0.4-0.3,0.5-0.4s0.3-0.3,0.4-0.4c0.5-0.5,0.9-1,1.3-1.5
		c0.8-1,1.4-1.9,2-2.5c0.4-0.6,1-1,1.6-1.3c0.6-0.2,1.4-0.4,2.3-0.7c0.5-0.1,1-0.3,1.5-0.5c0.6-0.2,1.2-0.5,1.7-0.8
		c0.2-0.2,0.4-0.4,0.5-0.6c0.1-0.1,0.1-0.1,0.1-0.2c0.2-0.1,0.3-0.2,0.5-0.3c0.5-0.2,1.2-0.3,2-0.6c0.5-0.1,0.9-0.4,1.3-0.7
		c0.3-0.3,0.6-0.6,0.9-0.9c0.4-0.5,0.9-0.9,1.5-1.2c0.2-0.1,0.5-0.1,0.7-0.1c0.3,0,0.7,0.1,1,0.2c0.8,0.3,1.6,0.7,2.3,1.1
		c0.6,0.3,1.2,0.5,1.9,0.5c0.2,0,0.4,0,0.6,0l0.4-0.1l0.8-0.2c0.8-0.2,1.7-0.4,2.5-0.5c0.9,0.2,1.8,0.4,2.6,0.7
		c1.3,0.3,2.8,0.3,4.1-0.2l1.5-0.6l1.5-0.5c1-0.3,2-0.7,3.1-1c1-0.3,2-0.5,3-0.7c0.2,0,0.4,0,0.6,0h0.7c0.5,0,1,0,1.5,0.1
		c1,0.1,2,0.2,3.1,0.3c0.5,0.1,1,0.1,1.4,0.2s0.9,0.2,1.4,0.3c0.7,0.2,1.3,0.2,2,0.2c0.6-0.1,1.2-0.2,1.8-0.4l0.7-0.3
		c0.2-0.1,0.4-0.1,0.6-0.2c0.3-0.1,0.7-0.2,1-0.2c0.8,0,1.5,0.2,2.2,0.4c0.9,0.3,1.8,0.4,2.7,0.5c0.9,0.1,1.6,0.2,2.3,0.3
		c0.4,0.1,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.3c0.5,0.3,0.8,0.8,1.1,1.3c0.2,0.3,0.4,0.6,0.7,0.9c0.3,0.3,0.7,0.6,1.1,0.7
		c1.7,0.5,3,1.7,3.5,3.3c0.4,1.1,0.3,2.9-0.3,5.2c-0.3,1.3-0.7,2.5-1.2,3.7c-0.5,1.4-1.3,2.6-2.2,3.8c-2.1,2.5-4.7,5.1-7.5,8
		c-0.7,0.7-1.4,1.5-2.1,2.1s-1.5,1.4-2.3,2.1c-1.6,1.4-3.3,2.8-5,4.2c-2.1,1.7-4.2,3.4-6.3,4.8c-0.5,0.4-1,0.7-1.5,1l-1.6,1
		l-3.1,1.9c-4.1,2.4-7.9,4.4-11.1,6C352.9,194.6,348.7,196.2,348.9,196.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M323.7,197c0.2-0.1,0.5,0.3,0.9,0.9l0.9,1.2l0.3,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.3l0.4,0.4
		c1.1,1.1,2.4,2.3,3.9,3.9l2.3,2.4c0.4,0.4,0.8,0.9,1.3,1.3l0.6,0.6l0.1,0.1c0,0,0,0,0.1-0.1h0.1l0.4-0.1c0.5-0.1,1.1-0.1,1.6-0.1
		c0.5,0,1.1,0,1.6,0h0.5l0,0c0,0,0,0,0-0.1l0.7-1l1.4-2.1l2.9-4.3l1.6-2.3l0.8-1.1l0.1-0.1v-0.1l0,0l-0.2-0.3l-0.3-0.6l-0.1-0.1l0,0
		c0,0,0-0.1,0-0.1l0,0h-0.1l-0.3,0.1c-0.9,0.3-1.8,0.5-2.7,0.7s-1.8,0.4-2.6,0.5c-3,0.5-5.9,0.7-8.9,0.6
		C326.6,198.2,323.6,197.4,323.7,197c-0.1-0.5,2.9-0.5,7.8-1c2.8-0.3,5.7-0.8,8.5-1.4c0.8-0.2,1.6-0.4,2.4-0.6s1.6-0.5,2.5-0.7
		l2.2-0.7l0.7-0.2l0.2-0.1c0,0,0.1-0.1,0.1,0l0.2,0.3l0.7,1.2c0.7,1.3,1.5,2.7,2.2,4l-3.8,5.5l-2.9,4.2l-3.5,4.9l-1.5-0.3l-0.6-0.1
		h-0.3c-0.5-0.1-0.9-0.1-1.3-0.1s-0.9,0-1.3,0h-0.3L335,212c-0.4,0.1-0.8,0.2-1.3,0.2l-1.2-1.4l-0.6-0.8l-1.1-1.4l-2.1-2.6
		c-1.3-1.6-2.5-3.1-3.4-4.3l-0.7-0.9c-0.1-0.3-0.2-0.4-0.2-0.5l-0.2-0.4c-0.2-0.5-0.4-1-0.5-1.5C323.5,197.5,323.5,197.1,323.7,197z
		"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M339,210c-0.3-0.1,1.2-3.8,3.5-8.2s4.5-7.7,4.7-7.5s-1.5,3.7-3.8,8S339.2,210.1,339,210z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M334.3,210.5c0.5,0.1,0.5,1.8,0.5,4.3c-0.1,1.4,0,2.7,0.3,4.1l0,0.2c0,0.1,0,0,0,0.1s0,0,0.1,0.1
		c0.2,0.1,0.4,0.2,0.5,0.3c0.7,0.3,1.4,0.4,2.1,0.4c0.7,0,1.3-0.1,2-0.3c0.1-0.1,0.3-0.2,0.4-0.3c0,0,0,0,0-0.1c0,0,0,0,0-0.1
		c0,0,0,0,0-0.1c0-1.4-0.1-2.9-0.3-4.3c-0.3-2.5-0.4-4.2,0.1-4.3s1.4,1.2,2.4,3.8c0.6,1.5,0.9,3.1,1.1,4.7c0,0.2,0,0.3,0,0.5
		c0,0.2,0,0.4-0.1,0.6c-0.1,0.4-0.3,0.8-0.5,1.1c-0.4,0.6-1,1.2-1.7,1.5c-1.1,0.6-2.4,0.9-3.6,0.8c-1.2,0-2.4-0.3-3.5-0.8
		c-0.6-0.3-1.2-0.7-1.6-1.3c-0.3-0.3-0.5-0.6-0.6-1c-0.1-0.2-0.1-0.4-0.2-0.6l-0.1-0.4c-0.2-1.7,0-3.4,0.5-5
		C333,211.8,333.9,210.4,334.3,210.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M308.9,195.8c0.2,0.1,0.3,0.4,0.4,1c0.1,0.3,0.2,0.7,0.4,1c0.1,0.2,0.2,0.4,0.3,0.5c0.2,0.2,0.2,0.3,0.6,0.7
		l0.3,0.8c0,0.3,0,0.5,0,0.9c0,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.2,0,0.3c0,0.2,0,0.4,0.1,0.6c0,0.2,0.1,0.4,0.2,0.6
		c0,0.1,0.1,0.2,0.1,0.4s0.2,0.5,0.3,0.7c-0.1,0.4-0.2,0.8-0.3,1.1l-0.2,0.5c0,0.1-0.1,0.2-0.1,0.3s0.1,0.2,0,0.2s-0.1,0.1-0.2,0.1
		h0.1l0,0v0.1l-0.1,0.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.2,0,0.4,0,0.6c0,0.2,0.1,0.3,0.1,0.4
		c0.2,0.2,0.5,0.4,0.8,0.5c0.3,0.1,0.5,0.2,0.8,0.2h0.2h0.5l1.1,0.1l0.5,0.9l0.1,0.2l0,0c0,0,0,0,0,0c0.1,0.1,0.2,0.1,0.4,0.1
		l0.4,0.1h0.2h0.3h0.3l1,0.5l1,0.5c0.1,0.2,0.2,0.3,0.3,0.5l0.1,0.1l0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0,0.3,0,0.5,0l0.3-0.1
		l0.2-0.1c0.2-0.1,0.3-0.1,0.5-0.2l1.2,0.2l1.2,0.3l0.3,0.4c0.1,0.1,0.1,0.1,0.2,0.2l0,0.1c0.1,0.1,0.1,0.1,0.2,0.2
		c0.1,0.1,0.3,0.2,0.5,0.3c0.3,0.1,0.7,0.2,1.1,0.2c0.4,0,0.8-0.1,1.2-0.3l0.2-0.1l0.3-0.1l0.5-0.3l0.5-0.3c0.1-0.1,0.3-0.1,0.5-0.2
		c1.2,1.3,1.3,1.1,1.9,1.3c0.5,0.1,0.9,0.1,1.4,0.1c0.8,0,1.3,0,1.4,0.2s-0.3,0.6-1.1,1.1c-0.6,0.3-1.2,0.4-1.8,0.5
		c-0.4,0-0.9,0-1.3-0.1l-0.5-0.2c-0.1,0-0.1,0-0.2-0.1c0,0-0.1,0-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.2,0.1
		c-0.6,0.4-1.3,0.6-2,0.8c-0.8,0.2-1.7,0.1-2.5-0.1c-0.4-0.1-0.9-0.3-1.2-0.6c-0.2-0.1-0.4-0.2-0.6-0.4l-0.1-0.1l0,0h0l0,0.1l0,0
		c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0.1-0.5,0.2-0.8,0.2c-0.6,0.1-1.2,0.1-1.7,0c-0.6-0.1-1.2-0.4-1.8-0.7c-0.2-0.2-0.5-0.4-0.7-0.6
		l-0.3-0.3c0-0.1,0,0,0,0h-0.1c-0.1,0-0.3,0-0.4,0c-0.3,0-0.6-0.1-0.9-0.1c-0.6-0.1-1.2-0.4-1.7-0.7c-0.1-0.1-0.3-0.2-0.4-0.3
		l-0.2-0.2l0-0.1l-0.1-0.1l0,0l-0.4-0.1c-0.5-0.1-0.9-0.2-1.4-0.4c-1.1-0.4-2-1.1-2.6-2.1c-0.2-0.5-0.4-1-0.5-1.6
		c-0.1-0.5-0.1-1,0-1.4c0.1-0.5,0.2-0.9,0.4-1.3c0.1-0.2,0.2-0.4,0.3-0.6c0-0.1,0.1-0.2,0.2-0.3l0.1-0.1l0-0.1l0.1-0.2l0.1-0.1
		c0.1,0,0-0.1,0-0.1s0,0,0-0.1c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.3-0.1-0.7-0.1-1c0-0.3,0-0.7,0-1c0-0.2,0-0.3,0.1-0.5
		c0-0.1,0.1-0.4,0.1-0.4c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.1-0.1-0.2s-0.2-0.5-0.3-0.8c-0.1-0.3-0.2-0.6-0.2-0.9
		c-0.1-0.5-0.1-1,0-1.4C308.4,196,308.7,195.7,308.9,195.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M393,305.1c0,0.5-2.3,0.9-6.1,1c-4.9,0.1-9.8-0.4-14.6-1.4c-4.9-0.9-9.5-2.6-13.9-4.9c-3.3-1.9-5-3.7-4.7-4.1
		s2.3,0.6,5.7,1.8c4.4,1.5,9,2.8,13.6,3.7C383.9,303.5,393,304,393,305.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M393.6,311c0,0.3-0.6,0.5-1.7,0.7c-1.5,0.2-3.1,0.4-4.7,0.3c-5.1-0.1-10.3-0.7-15.2-2
		c-11.8-2.8-20.3-7.9-19.8-8.8s9.3,2.6,20.7,5.3c5.7,1.4,10.9,2.3,14.6,2.9c1.9,0.3,3.4,0.6,4.5,0.8S393.6,310.8,393.6,311z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M391.3,329.7c-6.7-0.2-13.4-0.9-20-2.1c-5.4-1-10.7-2.5-15.8-4.5c-8.8-3.3-13.5-6.9-13.2-7.3s5.3,2.2,14.1,4.7
		c11.4,3.3,23.2,5.1,35.1,5.3C391.8,325.8,391.6,329.7,391.3,329.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M394.6,338.1c0.2,0.1,0.3,0.6-0.1,1.4c-0.5,1.1-1.3,2-2.3,2.7c-1.5,1-3.3,1.6-5.2,1.5c-1.1,0-2.3-0.2-3.3-0.7
		c-1.2-0.5-2.2-1.3-3-2.3c-1.4-1.8-2.1-4.1-2.1-6.4c0-0.5,0.1-1.1,0.1-1.6c0.1-0.5,0.3-1,0.5-1.5c0.4-0.8,0.9-1.5,1.5-2.1
		c0.8-0.8,1.9-1.4,3-1.8c0.8-0.2,1.3-0.1,1.4,0.1s-0.2,0.6-0.7,1.1c-0.7,0.7-1.3,1.4-1.8,2.2c-0.3,0.5-0.5,1-0.7,1.6
		c-0.1,0.6-0.2,1.2-0.1,1.9c0.1,1.5,0.6,3,1.6,4.2c0.9,1.1,2.2,1.8,3.6,1.9c1.3,0.2,2.6,0,3.8-0.4
		C393.1,339.2,394.2,337.9,394.6,338.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M367.9,325.1c0,0.3-0.5,0.5-1.4,1c-1.1,0.6-2.1,1.4-2.8,2.5c-0.4,0.7-0.6,1.4-0.7,2.2c-0.1,0.9,0,1.9,0.3,2.7
		c0.3,0.9,0.9,1.7,1.6,2.2c0.9,0.7,1.9,1.1,3,1.3c0.5,0.1,1.1,0.2,1.7,0.2c0.5,0,1-0.1,1.4-0.2c1-0.3,1.8-0.8,2.5-1.5
		c0.6-0.6,1-1.3,1.2-2.1c0.2-0.8,0.1-1.6-0.1-2.3c-0.4-1.3-1-2.4-1.8-3.5c-0.6-0.8-0.9-1.3-0.8-1.5s0.8-0.1,1.7,0.5
		c1.4,0.9,2.5,2.1,3.2,3.6c0.5,1.1,0.7,2.4,0.6,3.6c-0.2,1.5-0.8,2.9-1.7,4c-1,1.2-2.4,2.1-3.9,2.6c-0.8,0.3-1.7,0.5-2.5,0.5
		c-0.8,0-1.7-0.1-2.5-0.3c-1.6-0.3-3.1-1.1-4.4-2.1c-1.3-1-2.1-2.4-2.6-4c-0.3-1.4-0.4-2.8-0.1-4.1c0.2-1.2,0.8-2.4,1.6-3.3
		c0.6-0.7,1.5-1.3,2.4-1.7c0.6-0.3,1.3-0.5,2-0.6C367.2,324.6,367.9,324.9,367.9,325.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M348.5,319.1c0,0.3-0.5,0.5-1.2,1c-1,0.6-1.7,1.4-2.3,2.3c-0.7,1.5-1.1,3.1-1,4.8c0,0.2,0,0.4,0.1,0.6
		c0,0.2,0.1,0.3,0.1,0.5c0.1,0.4,0.4,0.7,0.6,1c0.6,0.7,1.4,1.2,2.3,1.5c1.7,0.7,3.6,0.4,5.1-0.7c1.3-0.9,2.2-2.2,2.7-3.6
		c0.1-1.1,0-2.2-0.4-3.3c-0.3-0.9-0.4-1.4-0.2-1.5s0.7,0.1,1.4,0.8c0.4,0.5,0.8,1,1.1,1.5c0.4,0.8,0.6,1.7,0.7,2.6
		c-0.2,4.7-4.2,8.3-8.9,8.1c-0.9,0-1.8-0.2-2.6-0.5c-1.5-0.5-2.7-1.5-3.7-2.6c-0.5-0.6-0.9-1.3-1.1-2.1c-0.1-0.4-0.2-0.8-0.3-1.2
		c0-0.4,0-0.7,0-1.1c0.1-2.3,0.8-4.4,2.2-6.2c1-1.2,2.4-2,3.9-2.2c0.4-0.1,0.8,0,1.2,0C348.3,318.9,348.5,319,348.5,319.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M483.1,307.6c-0.8-0.8-10.4,8.5-24.2,17.3c-11.1,7.1-21.3,12.2-24.9,13.3c-0.5,0.1-1,0.3-1.7,0.5
		s-1.5,0.3-2.3,0.5s-1.9,0.4-2.9,0.6l-3.4,0.6c-5.8,0.9-11.6,1.4-17.5,1.5c-13.1,0.4-24.5-0.6-28.2-1.2c-1.9-0.4-5.5-1.4-10.3-2.9
		l-7.9-2.5c-2.9-0.9-5.9-1.8-9-2.8c-6.6-2.2-13.1-4.7-19.5-7.6c-4.9-2.2-7.9-3.6-8.2-3.2s2.3,2.7,7,5.5c6.2,3.6,12.7,6.6,19.5,8.8
		c3.2,1.1,6.4,1.9,9.4,2.5s5.7,1.2,8.2,1.6c4.9,0.9,8.7,1.3,10.6,1.7s5.5,1.4,10.5,2.3c5.9,1.1,12,1.6,18,1.5
		c6.1-0.2,12.1-1.2,17.9-2.9l3.4-1c1-0.4,2-0.7,2.8-1l2.3-0.8c0.7-0.3,1.2-0.5,1.7-0.6c1.8-0.6,5.5-1.5,10.3-3.3
		c5.7-2.1,11.1-4.8,16.2-8c6-3.8,11.6-8.3,16.7-13.4C481.5,310.7,483.5,308,483.1,307.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M488,328.8c-0.2-0.1-1.1,0.7-2.9,2.1s-4.3,3.4-7.6,5.7c-8.8,5.7-18.1,10.6-27.8,14.7c-4.6,2-9,3.8-13.2,5.3
		c-2,0.7-4,1.4-6.1,1.9c-2,0.5-3.9,1-5.7,1.4c-7.3,1.7-12.9,2.9-15.7,3.3c-1.4,0.2-3.5,0.4-6.2,0.6c-3.2,0.1-6.5,0.1-9.7-0.1
		c-3.7-0.2-7.9-0.6-12.4-1c-2.2-0.2-4.5-0.5-6.9-0.8l-3.6-0.5c-1.2-0.2-2.4-0.4-3.5-0.6c-4.6-1-9.2-2.3-13.6-3.8
		c-2.1-0.7-4.1-1.5-6.1-2.2l-2.9-1.1l-2.7-1.1c-6.9-2.9-12.2-5.1-14.8-6.3c-1.3-0.6-3.2-1.6-5.6-2.9c-1.2-0.6-2.6-1.3-3.9-2.1
		s-2.8-1.9-4.2-3c-3-2.2-6.2-4.9-9.6-7.8c-1.7-1.5-3.5-3-5.1-4.6s-3.2-3.4-4.8-5.3c-2-2.4-3.9-4.7-5.6-7l-2.6-3.4
		c-0.9-1.1-1.6-2.1-2.3-3.3c-2.9-4.5-5.2-8.7-7.3-12.2l-1.5-2.5c-0.2-0.4-0.5-0.8-0.8-1.2c-0.2-0.3-0.3-0.5-0.4-0.8
		c-0.4-1.1-0.4-2.3,0.1-3.4c0.2-0.4,0.5-0.7,0.8-1c0.3-0.3,0.6-0.5,0.8-0.7c0.5-0.4,0.7-0.6,0.7-0.7s-0.4-0.1-1,0
		c-0.4,0.1-0.7,0.3-1.1,0.5c-0.5,0.3-1,0.6-1.4,1.1c-0.9,1.4-1.2,3.1-0.9,4.7c0.1,0.5,0.3,1,0.6,1.5c0.2,0.4,0.4,0.7,0.6,1.1
		l1.3,2.6c1.8,3.7,4,8,6.8,12.7c0.7,1.2,1.5,2.4,2.4,3.5s1.7,2.3,2.6,3.5c1.8,2.4,3.7,4.8,5.7,7.2c1.6,2,3.3,3.8,5.2,5.6
		c1.9,1.6,3.7,3.1,5.5,4.5c3.6,2.8,7.1,5.2,10.3,7.3c1.6,1,3.1,1.9,4.6,2.7s2.9,1.3,4.2,1.8c2.5,1.1,4.5,1.9,5.8,2.5
		c2.6,1.2,7.6,4,14.3,7.5l2.6,1.4l2.8,1.3c1.9,0.9,3.9,1.8,6.1,2.6c4.5,1.9,9.2,3.3,14,4.4c1.3,0.3,2.6,0.5,3.8,0.6s2.5,0.3,3.7,0.4
		c2.4,0.2,4.8,0.4,7,0.5c4.5,0.2,8.8,0.3,12.6,0.2c3.3-0.1,6.6-0.3,9.9-0.7c2.7-0.4,4.8-0.8,6.3-1c2.8-0.4,8.5-1,16-2.1
		c1.9-0.3,3.8-0.6,5.9-1c2.2-0.4,4.4-0.9,6.5-1.6c4.6-1.4,9.2-3.1,13.7-5c11.5-5,21.4-10.8,27.9-16c2.6-2,5-4.3,7.1-6.7
		C487.6,330.2,488.2,329,488,328.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M474.1,295.9c8.2,8.1,14.3,15.3,13.5,16.1s-8-5.2-16.3-13.3C472.1,297.7,473.1,296.8,474.1,295.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M388.1,327.2c-0.6-0.1-0.5-3.8,0.1-8.3s1.6-8.1,2.2-8s0.5,3.8-0.1,8.3S388.7,327.3,388.1,327.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M369.3,324.9c-0.6-0.1-0.3-3.9,0.7-8.5s2.2-8.2,2.8-8.1s0.3,3.9-0.7,8.5S369.9,325.1,369.3,324.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M351,319.4c-0.5-0.2,0.4-4,2.1-8.4s3.5-7.9,4.1-7.7s-0.4,4-2.1,8.4S351.5,319.6,351,319.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M292.5,294c0,0.3-1.5,0.7-3.9,0.9c-1.2,0.1-2.7,0.2-4.3,0.2h-2.5c-0.5,0-0.7,0-1.8,0h-0.7h-0.2l-0.1-0.1
		l-0.2-0.1c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.1-0.3-0.1-0.5l0.2-0.5c0.1-0.3,0.3-0.7,0.5-1
		c0.4-0.5,0.8-0.9,1.2-1.4c0.6-0.6,1.3-1.2,2-1.8c1.1-0.9,2.3-1.7,3.6-2.5c2.1-1.3,3.6-1.8,3.7-1.5s-1,1.2-2.9,2.8
		c-0.9,0.8-2,1.7-3.2,2.7c-0.6,0.5-1.2,1.1-1.7,1.7c-0.8,0.9-0.5,0.6-0.6,0.6c-0.8,0,2,0.1,3.4,0.1c1.6,0.1,3,0.2,4.3,0.3
		C291,293.6,292.5,293.7,292.5,294z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M290.3,288.9c-1-1.4-1.8-3-2.4-4.6c-0.4-1.2-0.5-2.1-0.3-2.2s0.9,0.5,1.6,1.5s1.7,2.6,2.7,4.1
		C291.4,288.1,290.9,288.5,290.3,288.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M482.8,308c0,0,0.1,0.2,0.4,0.5c0.3,0.5,0.6,1,0.9,1.6c1,2.1,1.8,4.3,2.5,6.6c0.5,1.5,0.9,3.1,1.4,5
		c0.1,0.5,0.2,1,0.4,1.6l0.2,1l0.1,0.5c0,0,0,0.1,0,0.1h-0.1l-0.3,0l-1-0.2c-0.3-0.1-0.8-0.2-1-0.2c-0.5-0.1-1.1-0.3-1.6-0.5
		c-2.3-0.8-4.5-1.9-6.5-3.3c-0.5-0.4-1.1-0.7-1.6-1.1c-0.3-0.2-0.5-0.4-0.8-0.6l-0.4-0.3l-0.2-0.2l-0.1-0.1l0,0c0,0,0,0,0,0.1
		c0.2,0.3,0.3,0.7,0.4,1c0,0.1,0.1,0.3,0.1,0.4l0.1,0.2v0.1v0.2c0.2,0.6,0.3,1.2,0.4,1.8c0.2,1.2,0.4,2.5,0.5,3.8s0.2,2.6,0.3,4
		l0.1,2c0,0.5,0,0.9,0,1.4v1.6l-1.6-0.7c-0.3-0.1-0.5-0.2-0.8-0.4l-0.5-0.3c-0.7-0.4-1.3-0.7-2-1.1c-1.3-0.8-2.5-1.7-3.8-2.6
		c-0.6-0.5-1.2-0.9-1.8-1.4l-1.5-1.2l-0.5-0.5c0,0-0.1-0.1-0.1,0l0.1,0.2c0,0.1,0.1,0.2,0.1,0.4l0.1,0.3v0.1l0.1,0.5
		c0.2,0.7,0.3,1.4,0.4,2.1c0.2,1.4,0.3,2.8,0.4,4.3c0.1,1.5,0.1,2.9,0,4.4c0,0.7-0.1,1.5-0.2,2.2l-0.1,0.9l-0.1,1
		c0.1,1-0.8,0.3-1.3,0.2l-0.9-0.3l-0.6-0.3c-0.3-0.2-0.7-0.3-1-0.5c-0.7-0.4-1.4-0.8-2-1.1c-1.3-0.8-2.6-1.7-3.8-2.7
		c-1.2-1-2.4-2-3.6-3.1l-0.5-0.4l-0.1-0.1c0,0,0,0,0,0.1v0.2v0.3v0.1v0.1v0.5c0.1,0.7,0.1,1.4,0.1,2.2c0,1.5,0,2.9-0.2,4.4
		c-0.1,1.5-0.3,2.9-0.6,4.4c-0.1,0.7-0.3,1.5-0.5,2.2l-0.2,0.7c-0.1,0.3-0.2,0.6-0.4,0.9c-0.4,0.5-1.1,0.7-1.6,0.4l-1.4-0.6
		c-0.4-0.2-0.7-0.4-1.1-0.6c-0.7-0.4-1.4-0.8-2.1-1.3c-1.4-0.9-2.7-1.9-3.9-3c-0.6-0.6-1.2-1.1-1.8-1.8c-0.3-0.3-0.6-0.6-0.8-0.9
		l-0.8-0.9l-0.2-0.3c-0.1-0.1,0,0.1,0,0.1s0-0.2,0,0.9c0,0.8-0.1,1.5-0.2,2.3c-0.2,1.5-0.4,3-0.8,4.5c-0.4,1.5-0.9,3-1.5,4.4
		c-0.3,0.7-0.7,1.5-1.1,2.2c-0.2,0.3-0.4,0.6-0.6,0.9l-0.3,0.4l-0.1,0.1h-0.1l-0.2-0.1c-0.3-0.1-0.6-0.3-1-0.5l-0.7-0.3
		c-2.8-1.6-5.2-3.8-7.1-6.4c-0.5-0.6-0.9-1.3-1.4-2c-0.2-0.3-0.4-0.7-0.6-1l-0.4-0.7v-0.1c0,0,0,0,0,0.1c0.1-0.6-0.3,1.6-0.6,2.9
		c-0.4,1.4-0.8,2.8-1.4,4.2c-0.5,1.4-1.1,2.7-1.8,4.1c-0.3,0.7-0.7,1.3-1.1,2l-0.4,0.6l-0.3,0.5l-0.3,0.3c-0.2,0.2-0.5,0.4-0.8,0.3
		c-0.3,0-0.6-0.1-0.9-0.3c-0.8-0.7-1-0.8-1.4-1.2l-1-1l-1-1.1l-0.9-1.1c-1.1-1.5-2.1-3.2-2.9-4.9c-0.4-0.9-0.7-1.8-1-2.6
		c-0.2-0.5-0.3-0.9-0.4-1.4l0,0l0,0.1l-0.1,0.2v0.1l-0.1,0.3c-1.6,3.5-3.7,6.7-6.1,9.7c-0.6,0.7-1.2,1.5-1.9,2.2l-1,1l-0.2,0.2
		l-0.3,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.4,0.2-0.8,0.2-1.2,0c-0.4-0.2-0.7-0.5-1-0.9l-0.5-0.6c-0.3-0.4-0.6-0.8-0.9-1.2
		s-0.5-0.8-0.8-1.3s-0.5-0.9-0.7-1.3c-1.2-2.4-2-4.9-2.4-7.6c-0.1-0.6-0.2-1.3-0.2-2V347l0,0l-0.3,0.5c-0.1,0.2-0.3,0.4-0.4,0.6
		c-0.6,0.8-1.1,1.5-1.8,2.2c-1.2,1.4-2.5,2.8-3.9,4c-1.4,1.2-2.8,2.4-4.3,3.5c-0.8,0.5-1.5,1.1-2.3,1.6l-0.7,0.4l-1.1,0.7l-0.6,0.3
		l-0.3,0.2c-0.1,0.1-0.1,0-0.1-0.1l-0.1-0.1c-0.1-0.4-0.3-0.8-0.5-1.2c-0.4-1-0.5-1.2-0.6-1.7l-0.4-1.3c-0.3-0.9-0.5-1.8-0.7-2.6
		c-0.5-1.8-0.8-3.5-1.1-5.3s-0.5-3.5-0.7-5.3l-0.1-0.7v-0.1l0,0l-0.2,0.2c-0.3,0.4-0.6,0.8-0.9,1.1c-0.6,0.8-1.3,1.4-2,2.1
		c-1.4,1.3-2.8,2.5-4.3,3.6c-1.5,1.1-3,2.2-4.5,3.2c-0.8,0.5-1.5,1-2.3,1.4l-0.6,0.4l-1.1,0.6l-0.6,0.3l-0.3,0.1l-0.1-0.3l-0.6-1.2
		l-0.4-0.9l-0.3-0.6c-0.2-0.4-0.4-0.9-0.5-1.3c-0.3-0.9-0.5-1.8-0.8-2.6c-0.4-1.8-0.7-3.5-0.8-5.4c-0.1-1.8-0.2-3.6-0.1-5.3l0.1-1.3
		l0.1-0.7c0,0,0-0.1,0-0.1s-0.1,0-0.1,0l-0.6,0.4l-1.1,0.8c-0.8,0.5-1.5,1-2.3,1.4c-1.5,0.9-3.1,1.8-4.6,2.6
		c-1.5,0.8-3.1,1.6-4.6,2.4l-2.3,1.1l-0.8,0.4c-0.3,0.1-0.7,0.4-1,0.4s-0.6,0.1-0.9,0s-0.3-0.2-0.3-0.3l-0.1-0.5l-0.2-1
		c0-0.5,0-0.6,0-0.8v-1.2c0-0.9,0-1.7,0-2.5c0-1.7,0.1-3.3,0.2-5s0.2-3.3,0.3-4.9c0-0.6,0.1-1.3,0.1-1.9c0-0.1,0-0.1,0-0.2
		c-0.1,0-0.1,0-0.2,0.1l-0.1,0.1l-0.5,0.3c-0.4,0.2-0.8,0.4-1.2,0.6c-0.8,0.4-1.6,0.8-2.3,1.1c-1.6,0.7-3.1,1.4-4.7,2
		s-3.1,1.2-4.7,1.7l-2.3,0.8l-1.3,0.4l-2,0.6h-0.1h-0.1c0,0,0,0,0-0.1v-0.3v-0.5l0.1-1l0.1-0.9v-0.6c0.1-0.8,0.1-1.6,0.2-2.3
		c0.2-1.5,0.4-3.1,0.6-4.6s0.5-3,0.8-4.4c0.1-0.7,0.3-1.3,0.4-2c0-0.1,0.1-0.2,0-0.4s-0.2,0-0.3,0l-0.3,0.1l-0.6,0.1l-1.1,0.2
		c-0.7,0.1-1.5,0.2-2.2,0.3c-1.5,0.2-2.9,0.3-4.3,0.4l-4.1,0.3l-2,0.1l-1.4,0.1h-0.8H293c0,0-0.2,0-0.1,0l0.1-0.2l0.5-1.5
		c0.1-0.2,0.2-0.5,0.2-0.7l0.2-0.5c0.2-0.6,0.5-1.2,0.8-1.8c0.5-1.1,1.1-2.2,1.7-3.3c0.6-1,1.3-2,2-3l0.5-0.7l0.3-0.4
		c0.2-0.2,0.4-0.4,0.6-0.6h0h-0.4h-0.4h-0.5h-0.8c-1.1,0-2.2,0-3.2,0c-2-0.1-3.9-0.3-5.9-0.6c-0.9-0.1-1.8-0.3-2.6-0.5l-1.2-0.3
		c-0.5-0.1-1-0.3-1.4-0.5l-0.2-0.1c0,0,0-0.1,0.1-0.1l0.2-0.3c0.1-0.2,0.3-0.4,0.4-0.6l0.4-0.5c0.1-0.2,0.2-0.3,0.3-0.4
		c0.5-0.5,0.9-1,1.3-1.5c1.3-1.4,2.7-2.7,4.1-4c0.5-0.5,1-0.9,1.5-1.2c-0.4,0.5-0.9,1-1.4,1.4c-0.9,0.9-2.3,2.3-3.9,4.1
		c-0.4,0.5-0.8,1-1.3,1.5l-0.3,0.4l-0.2,0.3c-0.1,0.1-0.2,0.2-0.2,0.3l-0.1,0.2c0,0,0,0.1,0,0.1h0.1c0.3,0.1,0.5,0.2,0.8,0.2
		s0.8,0.2,1.1,0.3c0.8,0.2,1.6,0.3,2.5,0.5c1.9,0.3,3.8,0.4,5.8,0.4c1,0,2.1,0,3.2,0h0.8c0.4,0,0.8,0,1.2-0.1l1.3-0.1l0.6,0h0.3h0.2
		h0.1c-0.6,0.6-1.2,1.3-1.8,1.9l-0.7,0.9l-0.5,0.7c-0.7,0.9-1.3,1.9-1.9,2.9c-0.6,1-1.2,2.1-1.6,3.2c-0.3,0.6-0.5,1.1-0.7,1.7
		l-0.2,0.4c0,0.1-0.1,0.1-0.1,0.2l-0.1,0.4c-0.1,0.1,0.1,0,0.1,0h0.3h0.6l2-0.1c1.4-0.1,2.7-0.2,4.1-0.3s2.8-0.3,4.2-0.5
		c0.7-0.1,1.4-0.2,2.1-0.3l1.1-0.2l0.5-0.1l0.8-0.2h0.8c0.2,0.1,0.5,0.2,0.6,0.4c0.1,0.2,0.2,0.4,0.2,0.7c0,0.1,0,0.3,0,0.4
		l-0.1,0.4l-0.3,1.2l-0.2,1.1c-0.3,1.4-0.5,2.9-0.7,4.4s-0.4,3-0.5,4.5c-0.1,0.8-0.1,1.5-0.2,2.3l-0.1,0.8c0,0.1,0,0.2,0,0.3
		l1.3-0.4l2.3-0.8c1.5-0.5,3-1.1,4.6-1.7s3.1-1.3,4.6-2c0.8-0.4,1.5-0.7,2.3-1.1c0.4-0.2,0.7-0.4,1.1-0.6l0.6-0.4l1-0.6l0.7-0.2
		c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0.1,0.4,0.2c0.2,0.1,0.2,0.4,0.3,0.6s0,0.2,0,0.4v0.5c0,0.3,0,0.7,0,1.1v0.6l-0.1,1.2
		c-0.1,1.6-0.2,3.2-0.3,4.9s-0.1,3.3-0.2,4.9c0,0.8,0,1.6,0,2.5s0,2,0,1.8c0,0,0,0.1,0,0.1s0,0,0.1,0l0.5-0.3l2.3-1.1
		c1.5-0.8,3.1-1.6,4.6-2.4c1.5-0.8,3-1.7,4.6-2.6c0.8-0.4,1.5-0.9,2.2-1.4l1.1-0.7l0.6-0.4c0.4-0.2,0.7-0.5,1-0.8l0.8-0.3
		c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0,0.1,0.1,0.2,0.1,0.3l0.1,0.3c0,0.2,0,0.4,0,0.6l-0.1,1.9
		l-0.1,1.3c-0.1,1.7,0,3.4,0.1,5.1c0.1,1.7,0.4,3.4,0.8,5.1c0.2,0.8,0.4,1.6,0.7,2.5c0.1,0.4,0.3,0.8,0.5,1.2l0.2,0.6l0.2,0.4
		c0,0,0,0,0,0l0.6-0.4c0.8-0.5,1.5-0.9,2.3-1.4c1.5-1,3-2,4.4-3.1c1.4-1.1,2.8-2.3,4.1-3.5c0.6-0.6,1.3-1.3,1.8-2
		c0.3-0.3,0.6-0.7,0.8-1l0.7-0.9l0.8-1.3l1-0.6l0.1-0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0.1l0.2,0.1c0.1,0.1,0.1,0.1,0.2,0.2
		l0.2,0.2c0,0.2,0.1,0.4,0.1,0.6c0,0.4,0.1,0.8,0.1,1.2l0.1,1.3l0.1,0.7c0.2,1.7,0.4,3.5,0.7,5.2s0.7,3.4,1.1,5.1
		c0.2,0.9,0.5,1.7,0.7,2.5l0.4,1.3c0.1,0.4,0.4,1,0.3,0.8l0,0l0.5-0.3c0.7-0.5,1.5-1,2.2-1.5c1.4-1,2.8-2.2,4.1-3.4
		c1.3-1.2,2.6-2.5,3.7-3.9c0.6-0.7,1.1-1.4,1.7-2.1c0.1-0.2,0.3-0.4,0.4-0.5l0.5-0.7c0.5-0.7,1-1.7,1.5-2.5c0.4-0.2,0.8-0.4,1.3-0.6
		c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0.1,0.2,0.1l0.1,0.1c0,0,0.1,0,0.1,0.2l0.2,0.5c0.1,0.3,0.1,0.6,0,0.9v1c0,0.3,0,0.7,0,1v0.7
		c0,0.6,0.1,1.2,0.2,1.8c0.4,2.4,1.1,4.8,2.2,7c0.2,0.4,0.4,0.8,0.6,1.2c0.2,0.4,0.5,0.8,0.7,1.1c0.3,0.4,0.5,0.8,0.8,1.1l0.4,0.5
		l0,0l0-0.1l1-1c0.6-0.7,1.2-1.4,1.8-2.1c2.3-2.8,4.3-5.9,5.8-9.2l0.1-0.3l0.2-0.6l0.5-1.2l0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.5l0.5-0.5
		l0.5-0.5c0.1-0.1,0.1-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0.1,0.4,0.2l0.2,0.2l0.1,0v0.2l0.1,0.3c0.2,0.8,0.4,1.8,0.6,2.7
		c0.1,0.4,0.2,0.9,0.4,1.3c0.3,0.8,0.6,1.7,0.9,2.5c0.7,1.6,1.6,3.1,2.6,4.5l0.8,1l0.9,1l0.9,0.9c0.3,0.3,0.8,0.7,0.6,0.6l0,0
		l0.2-0.3c0.4-0.6,0.7-1.3,1-1.9c1.3-2.5,2.3-5.2,3.1-7.9c0.5-1.8,0.8-3.7,0.9-5.6l0.1-0.3c0-0.1,0-0.1,0.1-0.1l0.1-0.1l0.3-0.3
		c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0,0.3,0,0.4,0c0.4-0.2,1.2,1.1,1.9,2.6l0.3,0.5l0.2,0.4l0.3,0.5c0.2,0.3,0.4,0.6,0.6,1
		c0.4,0.6,0.8,1.3,1.3,1.9c1.7,2.4,3.9,4.4,6.4,5.9l0.4,0.2l0,0c0.4-0.6,0.7-1.2,1-1.9c0.6-1.3,1.1-2.7,1.4-4.1
		c0.4-1.4,0.6-2.8,0.8-4.3c0.1-0.7,0.1-1.4,0.2-2.1v-1.4c0-0.7,0-1.5,0-2.2l0.5-0.9c0.1-0.1,0.1-0.3,0.4-0.3c0.1,0,0.2,0,0.3,0
		c0.1,0,0.1,0,0.2,0l0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.2l0.4,0.5c0.5,0.8,0.9,1.3,1.3,1.9l0.2,0.3l0.2,0.2l0.4,0.5
		c0.3,0.3,0.5,0.6,0.8,0.9c0.5,0.6,1.1,1.1,1.6,1.7c1.1,1.1,2.3,2,3.6,2.9c0.6,0.4,1.3,0.8,2,1.2c0.3,0.2,0.7,0.4,1,0.5l0.8,0.4
		c0,0,0.1,0,0.1,0l0.1-0.5c0.2-0.7,0.3-1.4,0.5-2.1c0.3-1.4,0.5-2.8,0.6-4.2c0.1-1.4,0.2-2.8,0.2-4.2c0-0.7,0-1.4-0.1-2v-0.5
		c0-0.1,0-0.2,0-0.4l-0.1-0.5l-0.3-1.8l0.3-1c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0.1,0.5,0.2
		l0.8,0.7l1.4,1.4c1.1,1.1,2.3,2.1,3.5,3c1.2,0.9,2.4,1.8,3.6,2.6c0.6,0.4,1.3,0.8,1.9,1.1c0.3,0.2,0.6,0.3,1,0.5l0.4,0.2h0.1
		c0.1,0,0.2,0.1,0.2,0V341c0.1-0.7,0.1-1.4,0.2-2.1c0.1-1.4,0.1-2.8,0.1-4.2c0-1.4-0.1-2.7-0.3-4.1c-0.1-0.7-0.2-1.3-0.3-2l-0.1-0.5
		c-0.1-0.3-0.1-0.6-0.3-0.9l-0.5-1.8c0-0.1-0.1-0.3-0.1-0.4v-0.2l0.1-0.4l0.1-0.4c0,0,0-0.1,0.1-0.1s0.1,0,0.1,0h0.1l0.4,0.1
		l0.5,0.2c0.5,0.3,1,0.9,1.5,1.3l1.3,1.1l0.9,0.7c0.6,0.5,1.2,1,1.8,1.4c1.2,0.9,2.4,1.8,3.7,2.6c0.6,0.4,1.2,0.8,1.9,1.1l0.6,0.3
		l0.4,0.2v-1l-0.1-2c-0.1-1.3-0.1-2.6-0.3-3.9s-0.3-2.5-0.5-3.7c-0.1-0.6-0.2-1.2-0.3-1.8l0-0.2c0-0.1-0.1-0.2-0.1-0.3l-0.2-0.7
		c-0.2-0.5-0.3-0.9-0.5-1.4c0-0.4-0.1-0.7-0.1-1.1l-0.1-0.6c0,0,0,0,0-0.1s0.1,0,0.1,0l0.1,0.1l0.3,0.1l0.3,0.2
		c0.1,0,0.1,0.1,0.2,0.1l0.3,0.2c0.2,0.1,0.3,0.3,0.5,0.4l0.6,0.5c0.4,0.4,0.8,0.7,1.2,1s1,0.8,1.5,1.1c1.9,1.4,4,2.4,6.2,3.3
		c0.5,0.2,1,0.4,1.5,0.5l0.5,0.1l0.5,0.1h0.1h0.1c0,0,0,0,0-0.1v-0.2l-0.1-0.5c-0.1-0.4-0.2-0.8-0.3-1.3c-0.4-1.8-0.8-3.5-1.2-5
		c-0.6-2.3-1.4-4.5-2.2-6.6C483.2,308.8,482.8,308,482.8,308z"
          />
          <ellipse class="st1" cx="371.3" cy="363.2" rx="9.7" ry="9" />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M361.6,363.2c0.3,0,0.4,0.8,1,2.1c1.9,4.4,6.9,6.5,11.3,4.9c1.4-0.5,2.7-1.4,3.6-2.6c2.1-2.5,2.1-6.2,0-8.7
		c-0.9-1.2-2.2-2.1-3.6-2.6c-4.5-1.6-9.4,0.5-11.3,4.9C362,362.4,361.9,363.2,361.6,363.2c-0.3,0-0.6-0.9-0.4-2.5
		c0.4-2.3,1.7-4.4,3.6-5.9c2.9-2.2,6.7-2.9,10.2-1.8c2.1,0.6,3.9,1.9,5.3,3.5c3.2,3.8,3.2,9.4,0,13.1c-1.4,1.7-3.2,2.9-5.3,3.5
		c-3.5,1.1-7.3,0.4-10.2-1.8c-1.9-1.4-3.2-3.5-3.6-5.9C361,364,361.4,363.2,361.6,363.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M374.5,363.3c-0.5,0-0.9-1.4-2.3-2.1c-0.7-0.4-1.6-0.4-2.4,0c-1.2,0.5-1.7,1.9-1.1,3.1
		c0.2,0.5,0.6,0.9,1.1,1.1c0.7,0.4,1.6,0.4,2.4,0C373.6,364.7,374,363.3,374.5,363.3c0.2,0,0.5,0.4,0.6,1.2c0,1.1-0.5,2.2-1.3,3
		c-1.4,1.3-3.3,1.7-5,1.1c-1.1-0.3-2-1-2.7-1.9c-1.5-2-1.5-4.7,0-6.7c0.7-0.9,1.6-1.5,2.7-1.9c1.8-0.6,3.7-0.1,5,1.1
		c0.9,0.8,1.3,1.9,1.3,3C375,362.9,374.7,363.3,374.5,363.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M457,408.3c-0.8-0.5,4.7-10.2,13.3-20.8s16.9-18,17.7-17.2s-6.4,9-14.9,19.5S457.9,408.8,457,408.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M469.3,391.7c-0.2,0.2-1-0.2-2.5-1s-3.4-2.2-6-3.7c-6.5-4.1-13.2-7.7-20.2-11c-16.2-7.8-30.4-11.5-30.2-12.4
		c0.1-0.5,3.9-0.2,9.7,1.2c2.9,0.7,6.4,1.7,10.1,3c4.1,1.4,8.1,3.1,12,4.9c7.1,3.3,13.9,7.4,20.1,12.2c1.9,1.4,3.7,3,5.4,4.7
		C468.9,390.7,469.5,391.5,469.3,391.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M462.2,401.3c-0.4,0.4-4-3.1-10.8-7.6c-8.8-5.8-18.2-10.6-28.1-14.3c-5.8-2.1-11.4-3.9-16.6-5.3
		s-9.9-2.7-13.8-3.7c-7.9-2-12.8-3.3-12.7-3.8s5.2-0.1,13.3,1.3c4.1,0.7,8.9,1.7,14.1,3c5.8,1.4,11.4,3.1,17,5.1
		c10.2,3.7,19.7,9,28.3,15.6C459.4,396.8,462.5,401,462.2,401.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M281.2,369.5c-0.5-1,16.6-9.1,39.5-11.6c2.8-0.3,5.7-0.4,8.5-0.3c2.7,0.1,5.3,0.4,7.8,0.6
		c4.9,0.5,9.3,1.2,13,1.9c7.3,1.4,11.8,2.6,11.7,3.1s-4.7,0.1-12-0.6c-3.7-0.3-8.1-0.8-12.9-1.1c-2.4-0.2-5-0.3-7.6-0.4
		c-2.7,0-5.3,0.1-8,0.4c-5.5,0.5-10.8,1.5-15.6,2.4s-9,1.9-12.6,2.8C285.7,368.4,281.4,370,281.2,369.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M280.2,372.3c-0.3,0-0.4-1.1,0.2-2.7s1.6-3.7,2.7-6.3c0.5-1.3,1.1-2.8,1.6-4.4l0.4-1.3l0.2-0.6l0.1-0.4
		c0-0.1,0.2-0.4,0.3-0.5c0.1-0.2,0.2-0.4,0.4-0.5c0.1-0.2,0.3-0.3,0.5-0.4l0.6-0.3c0.2-0.1,0.3-0.1,0.4-0.2c0.5-0.2,0.9-0.4,1.4-0.5
		c4-1.4,8.1-2.6,12.3-3.5c2.2-0.5,4.4-0.9,6.5-1.2s4.2-0.6,6.1-0.7c3.5-0.2,6.9-0.2,10.4,0c5.9,0.3,9.5,1,9.4,1.5s-3.6,0.7-9.4,1
		c-2.9,0.2-6.3,0.4-10.1,0.8c-1.9,0.2-3.8,0.5-5.9,0.9s-4.1,0.8-6.3,1.3c-4.3,1-8.4,2-12,3.1c-0.4,0.1-0.9,0.3-1.3,0.4l-0.3,0.1h0
		c0,0,0,0-0.1,0c0,0,0,0,0,0.1l-0.1,0.3l-0.3,0.6l-0.5,1.2c-0.7,1.6-1.4,3.1-2.1,4.4c-1.5,2.6-2.9,4.5-3.8,5.9
		S280.4,372.3,280.2,372.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M285.6,356.2c0.3-2.7,0.4-5.4,0.5-7.9s0.1-4.7,0.2-6.6c0.1-3.8,0.3-6.1,0.9-6.2s1.3,2.2,1.9,6
		c0.4,2.2,0.6,4.5,0.7,6.7c0.1,2.8,0.1,5.6-0.3,8.4c-0.7,0.1-1.3,0-2-0.1C286.9,356.4,286.3,356.3,285.6,356.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M245.3,304.3c-0.4-0.3,1-2.4,4.3-4.1c4.4-2.1,9.2-2.7,14-1.9c4.7,0.7,9.1,2.4,13.1,4.9c3,1.9,4.5,3.6,4.3,4
		s-2.3-0.5-5.4-1.7c-4-1.7-8.2-2.9-12.5-3.6c-4.2-0.7-8.5-0.4-12.6,0.7C247.4,303.5,245.7,304.7,245.3,304.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M243.3,317.9c-0.2,0.1-0.8-0.4-1.7-1.5c-1.1-1.6-2-3.5-2.5-5.4c-0.8-2.9-1-5.9-0.7-8.9
		c0.1-0.9,0.3-1.8,0.5-2.6c0.1-0.5,0.3-0.9,0.5-1.4c0.1-0.3,0.3-0.7,0.5-1c0.2-0.4,0.5-0.7,0.8-0.9c0.1,0,0.2-0.1,0.3-0.1l0.3-0.1
		c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.5-0.1,0.6-0.1h0.4l1.4-0.1c0.9,0,1.8-0.1,2.6-0.1c1.6-0.1,3.2-0.4,4.8-0.8c1.5-0.5,3-1.2,4.5-1.8
		c2.9-1.2,5.5-2.4,7.6-3.4l0.8-0.4c0.3-0.1,0.6-0.3,1-0.4c0.5-0.2,1.1-0.3,1.6-0.4c0.8-0.1,1.7-0.2,2.5-0.2c1.4,0,2.1,0.1,2.2,0.4
		s-0.7,0.6-1.9,1c-0.6,0.2-1.4,0.5-2.3,0.8c-0.4,0.2-0.9,0.3-1.3,0.5c-0.2,0.1-0.4,0.2-0.6,0.3l-0.8,0.5c-2.1,1.3-4.6,2.7-7.5,4.1
		c-1.5,0.8-3.1,1.5-4.7,2.1c-1.8,0.6-3.8,0.9-5.7,1c-0.9,0.1-1.9,0.1-2.8,0.1l-1.3,0.1h-0.3h-0.1c0,0,0-0.1-0.1,0.1
		c-0.1,0.3-0.3,0.7-0.4,1c-0.2,0.7-0.4,1.4-0.5,2.1c-0.4,2.6-0.5,5.3-0.1,7.9C242.2,315,243.7,317.6,243.3,317.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M213.5,391.8c-0.4-0.1,0.4-5.9,4.4-14.4c2.3-4.9,5-9.7,8-14.3c1.6-2.6,3.5-5.3,5.2-7.9c1.7-2.9,3.1-5.9,4.1-9
		c0.6-1.6,1.1-3.2,1.5-4.8c0.2-0.8,0.4-1.6,0.5-2.4c0.1-0.4,0.1-0.8,0.1-1.1l0.1-1.3c0.3-3.2,0.8-6.5,1.6-9.6
		c1.1-5.4,3.1-10.7,5.8-15.5c1.8-3.2,4.1-6.2,6.8-8.9c1.9-1.8,3.1-2.6,3.3-2.4s-0.7,1.3-2.2,3.4c-2.1,2.9-4,5.9-5.6,9.1
		c-2.2,4.8-3.9,9.8-4.8,15c-0.6,3.1-1,6.2-1.2,9.3l-0.1,1.2c0,0.5-0.1,0.9-0.2,1.4c-0.1,0.9-0.3,1.8-0.5,2.6
		c-0.4,1.8-0.9,3.5-1.6,5.2c-1.2,3.3-2.7,6.6-4.5,9.6c-1.9,2.9-3.8,5.4-5.5,8c-3.5,5-6.3,9.6-8.5,13.6
		C215.7,386.5,214.1,392,213.5,391.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M253,435.7c0-0.5-3.7-0.6-9.5-1.3c-3.4-0.4-6.7-1.1-10-2l-1.4-0.4l-1.4-0.5c-0.9-0.3-1.8-0.8-2.8-1.1
		c-1.9-0.9-3.7-1.9-5.4-3.1c-2.7-1.9-5.1-4.2-7-6.9c-1.7-2.4-3-4.9-3.9-7.7c-1.2-3.6-1.7-7.5-1.2-11.3c0.3-1.5,0.7-2.9,1.3-4.3
		c0.4-0.9,0.9-1.9,1.4-2.9s1.2-2.1,2-3.2c3.5-5.3,7.9-10,13-13.8c2.6-2.1,5.5-3.8,8.6-5.2c0.4-0.1,0.7-0.3,1.1-0.5l1-0.4
		c0.7-0.2,1.5-0.4,2.2-0.6c1.4-0.4,2.8-0.6,4.2-0.9c3.8-0.8,7.7-1.1,11.6-1.1c1.5,0.1,3,0.3,4.5,0.5c2.3,0.4,4.5,1.2,6.5,2.2
		c1.2,0.6,2.5,1.2,4,1.8c0.8,0.3,1.6,0.6,2.4,0.8c0.4,0.1,0.9,0.2,1.4,0.3l1.2,0.2c1.6,0.3,3.2,0.5,4.9,0.9c1.6,0.4,3.3,0.9,4.8,1.5
		c1.8,0.9,3.6,2,5.2,3.3c1.7,1.3,3.2,2.6,4.6,3.8c1.3,1.2,2.6,2.5,3.8,3.8c1,1.1,1.9,2.4,2.6,3.7c1,2,1.8,4.1,2.3,6.3
		c0.4,1.6,0.6,2.5,0.8,2.5s0.5-0.9,0.6-2.7c0-2.5-0.4-4.9-1.3-7.3c-0.6-1.6-1.5-3.1-2.6-4.5c-1.2-1.5-2.5-3-3.8-4.3
		c-1.5-1.5-3.1-2.9-4.8-4.2c-0.9-0.7-1.8-1.4-2.7-2c-1-0.7-2.1-1.3-3.2-1.8c-1.8-0.7-3.6-1.3-5.5-1.6c-1.7-0.3-3.5-0.6-5.3-0.8
		l-1.3-0.1c-0.3,0-0.7-0.1-1-0.1c-0.7-0.1-1.4-0.3-2.1-0.5c-1.4-0.4-2.8-1-4.1-1.4c-2.3-0.8-4.8-1.3-7.2-1.5c-2-0.1-3.6-0.1-4.7-0.2
		c-4-0.3-7.9-0.3-11.9-0.1c-1.4,0.1-2.9,0.2-4.4,0.5c-0.8,0.1-1.5,0.3-2.4,0.4l-1.3,0.3c-0.4,0.1-0.8,0.3-1.2,0.4
		c-3.5,1.3-6.7,3.1-9.6,5.3c-5.4,4.1-9.8,9.5-12.9,15.5c-0.7,1.2-1.1,2.5-1.6,3.6s-0.8,2.2-1.1,3.2c-0.4,1.5-0.8,3.1-1,4.6
		c-0.8,4-0.8,8.1,0,12c1.4,6.9,5.4,12.9,11.2,17c1.9,1.3,3.9,2.5,6.1,3.4c1,0.4,2.1,0.9,3.1,1.2l1.5,0.5l1.5,0.4
		c3.5,0.9,7.1,1.3,10.7,1.4C249.4,437,253,436.1,253,435.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M369.3,477.8c0.2-0.4-3.4-2.5-9.9-3.6c-3.8-0.6-7.6-0.8-11.5-0.7c-4.7,0.1-9.3,0.6-13.9,1.3
		c-7.9,1.2-15,2.9-20.8,4.2s-10.1,2.4-12.3,2.7s-6.7,0.7-12.6,1c-6.9,0.4-13.9,0.3-20.8-0.2c-11.1-1.2-21.9-4.2-32-8.8
		c-3.7-2-7.6-3.9-11.5-5.5l-1.1-0.4l-1-0.4c-0.1-0.1-0.1-0.1-0.3-0.4s-0.4-0.6-0.6-0.9c-0.4-0.6-0.7-1.3-1-2
		c-0.2-0.4-0.3-0.7-0.4-1.1l-0.2-0.5l-0.1-0.3l-0.1-0.3c-0.3-1-0.8-2-1.3-3c-0.5-0.9-1-1.7-1.6-2.5s-1.1-1.6-1.7-2.3l-0.4-0.5
		l-0.1-0.2l0,0c0,0,0,0,0-0.1v-0.6c0.1-2.3,0.4-4.7,0.6-7s0.3-4.6,0.4-6.7c0.1-3.8-0.1-7.6-0.6-11.4c-0.8-6.4-1.8-10.3-2.3-10.2
		s-0.4,4-0.2,10.4c0,3.2,0.1,7-0.2,11.1c-0.1,2.1-0.3,4.3-0.6,6.5s-0.5,4.6-0.6,7.2v0.9l0.1,0.6c0,0.2,0.1,0.5,0.2,0.7
		c0,0.3,0.2,0.4,0.3,0.6s0.2,0.3,0.3,0.4l0.5,0.6l1.8,2.3c0.6,0.7,1.1,1.5,1.5,2.2c0.4,0.6,0.8,1.3,1.1,2v0.1l0.1,0.2l0.1,0.4
		l0.3,0.7c0.2,0.4,0.4,0.9,0.6,1.3c0.4,0.8,0.9,1.6,1.4,2.3c0.3,0.4,0.6,0.7,0.9,1.1c0.4,0.5,0.9,0.8,1.5,1l1.2,0.3l1.1,0.3
		c4.1,1,8,2.5,11.8,4.2c2,1,5.9,3.3,11.4,5.8c6.6,3,13.6,4.9,20.7,5.7c8.2,0.8,15.5-0.2,21.4-1s10.3-1.9,12.5-2.3s6.7-0.8,12.6-1.5
		s13.1-1.8,20.9-2.9c9.6-1.5,18.3-2.1,24.5-1.6S369.1,478.4,369.3,477.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M423.7,427c-0.1-0.4-3.5-0.1-8.8,0.8l-4.4,0.7c-1.5,0.3-3.2,0.5-4.9,0.7c-3.8,0.4-7.6,0.6-11.4,0.5h-4.9
		c-1.6,0-3.3,0.1-4.9,0.3c-0.4,0.1-0.9,0.2-1.3,0.3l-1.1,0.4c-0.7,0.3-1.4,0.6-2,0.9c-1.3,0.6-2.4,1.3-3.5,1.9
		c-4.4,2.4-7.7,4.2-9.4,4.9c-3.3,1.2-6.7,2.2-10.1,3c-1.2,0.3-2.5,0.6-3.8,0.8c-0.7,0.1-1.3,0.3-2,0.4l-2.1,0.3
		c-3.1,0.4-6.1,0.5-9.2,0.5c-1.6,0-3.2-0.1-4.8-0.1s-3.1-0.1-4.6-0.2l-8.2-0.5c-5-0.3-8.7-0.6-10.6-0.8s-5.6-1.1-10.5-2
		c-5.9-1.2-11.9-1.8-18-1.8c-7.1,0.1-14.2,0.8-21.2,2.3c-5.4,1.2-8.5,2.6-8.3,3.1s3.4-0.1,8.7-0.5s12.7-1,20.8-1.1
		c6.5-0.1,12.6,0.3,17.6,0.5s8.7,0.5,10.6,0.8s5.6,1.1,10.5,2.1c2.5,0.5,5.2,1,8.2,1.4c1.5,0.2,3.1,0.4,4.7,0.5s3.3,0.3,5,0.2
		c3.3,0.1,6.6-0.2,9.8-0.9l2.2-0.5c0.7-0.2,1.4-0.4,2.1-0.6c1.4-0.4,2.6-0.9,3.8-1.3c4.7-1.8,8.1-3.6,9.9-4.3s5.4-2,10-3.8l3.7-1.4
		c0.6-0.2,1.3-0.5,1.9-0.7l1-0.3l0.9-0.1c1.5-0.1,2.9-0.1,4.4,0c1.6,0.1,3.2,0.1,4.9,0.1c4,0.1,7.9-0.2,11.8-0.9
		c1.8-0.3,3.5-0.7,5.1-1.1s3-0.8,4.3-1.2C420.6,428.8,423.8,427.5,423.7,427z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M427.8,429.1c-0.1-0.3,1.1-0.9,2.7-2.2c0.4-0.3,0.8-0.7,1.1-1.1c0.4-0.4,0.6-0.9,0.8-1.4c0-0.1,0-0.1-0.1,0
		l0,0h-0.1l-0.6,0.2l-1.2,0.4l-4.4,1.4l-0.9,0.3H425c-0.1,0,0,0.1,0,0.1c0,0.1,0.1,0.2,0.1,0.2c0.6,0.6,1.2,1.1,1.9,1.6
		C427.5,428.9,427.7,429,427.8,429.1c0.1,0.1,0,0.2-0.2,0.2c-0.3,0.1-0.6,0.1-0.8,0c-0.9-0.2-1.8-0.6-2.5-1.2
		c-0.6-0.5-1.7-1.4-1.2-2.2c0.4-0.4,0.8-0.7,1.4-0.9l1.1-0.5c1.4-0.6,2.9-1.1,4.5-1.6l1.2-0.4l0.6-0.2l0.5-0.1
		c0.2-0.1,0.4-0.1,0.5-0.1c0.3-0.1,0.6,0,0.9,0.1c0.3,0.2,0.5,0.4,0.6,0.7c0.2,0.6,0.2,1.4,0,2c-0.3,0.8-0.8,1.5-1.4,2
		c-0.5,0.4-1,0.8-1.6,1.2C429.3,429.3,427.8,429.4,427.8,429.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M404.5,443.6c-0.3-0.3,1-2.3,4.2-4c1.9-1,3.9-1.7,5.9-2.1c2.5-0.5,5.1-0.7,7.6-0.6h0.9l0,0l0.2-0.1
		c0.4-0.2,0.8-0.5,1.2-0.8c0.9-0.7,1.7-1.4,2.4-2.2c1.3-1.3,2.6-2.7,3.7-4.2c0.7-1,1.3-2.2,1.7-3.3c0.4-0.9,0.6-1.4,0.9-1.3
		s0.5,0.6,0.5,1.6c0,1.5-0.3,2.9-0.9,4.3c-0.9,1.9-2.1,3.7-3.5,5.3c-0.8,1-1.7,1.9-2.7,2.7c-0.6,0.5-1.2,0.9-1.9,1.3
		c-0.2,0.1-0.5,0.2-0.7,0.3c-0.3,0.1-0.6,0.1-0.9,0.1c-0.5,0-0.7,0-1.1,0c-4.2-0.2-8.4,0.3-12.5,1.5
		C406.6,442.9,404.9,444,404.5,443.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M442.5,464.9c-6.1-1.5-12.1-3.7-17.8-6.5c-4.6-2.2-9-4.9-13.2-8c-7-5.3-10.4-9.7-10-10s4.3,3.2,11.5,7.8
		c9.4,5.9,19.6,10.3,30.3,12.9C443.8,461.2,442.8,465,442.5,464.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M477,507.5c-0.5,0.2-2-3.4-5.4-8.5c-0.8-1.3-1.8-2.7-2.9-4c-0.5-0.7-1.1-1.4-1.8-2.1c-0.3-0.3-0.6-0.7-1-1
		c-0.2-0.2-0.3-0.3-0.5-0.4l-0.6-0.5c-3.2-2.9-6-6.5-9.4-9.6l-1.3-1.2c-0.5-0.4-0.9-0.9-1.3-1.3c-0.8-0.9-1.6-1.8-2.3-2.6
		c-1.5-1.8-2.8-3.5-4-5.2s-2.4-3.3-3.4-4.9c-1-1.5-1.8-3.1-2.4-4.8c-0.9-2.4-1.6-4.8-2-7.3c-0.3-1.8-0.3-2.8,0-2.8s0.7,0.8,1.4,2.4
		s1.6,3.9,3,6.6c0.7,1.5,1.6,2.8,2.5,4.1c1.1,1.5,2.3,3,3.5,4.6s2.6,3.3,4.1,4.9c0.7,0.8,1.5,1.6,2.3,2.5c0.4,0.4,0.8,0.8,1.2,1.2
		l1.3,1.2c3.6,3.4,6.4,7.1,9.2,9.8l0.5,0.5c0.2,0.2,0.4,0.4,0.6,0.6c0.4,0.4,0.7,0.8,1.1,1.2c0.7,0.8,1.2,1.6,1.8,2.4
		c1,1.5,1.9,3,2.7,4.5C476.7,503.4,477.4,507.4,477,507.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M439.5,479c0.1,0.1,0,0.5,0,1.1c0,0.8,0.3,1.6,1,2.1c0.4,0.3,1,0.5,1.5,0.6c0.3,0,0.6,0,0.9,0
		c0.4-0.1,0.7-0.1,1.1-0.2c1.5-0.4,3.1-1,3.8-2.4c0.2-0.3,0.3-0.7,0.4-1.1c0.1-0.3,0.1-0.6,0-0.8c-0.3-0.5-0.8-1-1.3-1.2
		c-0.6-0.3-1.2-0.4-1.9-0.4c-0.6,0.1-1.3,0.2-1.9,0.4c-0.9,0.3-1.8,0.7-2.6,1.2C439.9,478.8,439.6,479.1,439.5,479
		c-0.1-0.1,0-0.5,0.5-1.1c0.7-0.8,1.6-1.5,2.6-1.9c0.7-0.3,1.4-0.6,2.1-0.8c1-0.2,1.9-0.1,2.8,0.3c1.1,0.3,2.1,1.1,2.6,2.2
		c0.2,0.6,0.3,1.3,0.1,1.9c-0.1,0.6-0.3,1.1-0.5,1.7c-0.6,1-1.4,1.9-2.5,2.4c-0.9,0.4-1.8,0.7-2.8,0.9c-0.4,0.1-0.9,0.2-1.3,0.2
		c-0.5,0-1,0-1.4-0.1c-0.8-0.2-1.6-0.6-2.2-1.3c-0.5-0.5-0.8-1.1-1-1.8c-0.1-0.5,0-1,0.1-1.5C439,479.2,439.4,478.9,439.5,479z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M451.6,476.6c-2.5,0.1-4.6,0.1-6.1,0.2l-1.7,0.1h-0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4-0.1
		c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1-0.1-0.1,0-0.3c0-0.1,0.1-0.3,0.2-0.4c0.5-0.7,1.1-1.2,1.9-1.6c2.1-1,4.5-1.5,6.8-1.5
		c0.1,0.7,0.1,1.3,0.1,2c0,0.5,0,1,0,1.3S451.7,476.6,451.6,476.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M455.6,482.8c0,0.5-2.3,1.1-5.3,1.5s-5.5,0.2-5.5-0.3s2.3-1.4,5.3-1.7C453.1,481.8,455.6,482.2,455.6,482.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M416.5,467.1c0-0.5,1.8-0.9,4.8-0.5c3.7,0.6,7.3,1.8,10.6,3.6c1.9,0.9,3.7,1.9,5.3,2.8
		c0.8,0.4,1.5,0.8,2.1,1.1c0.6,0.2,1.2,0.4,1.8,0.5c2.5,0.3,4.3,0,4.4,0.5c0.1,0.2-0.3,0.6-1,1c-1.1,0.6-2.2,0.9-3.4,1
		c-0.9,0.1-1.8,0.1-2.7-0.1c-0.9-0.3-1.8-0.6-2.7-1c-1.7-0.8-3.5-1.6-5.4-2.5c-3.8-1.8-7.1-3.4-9.6-4.3S416.5,467.6,416.5,467.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M407.3,477.8c0-0.2,0.5-0.5,1.4-0.7c1.3-0.2,2.6-0.2,3.9,0c4.1,0.7,8.1,1.9,11.9,3.6c2.2,0.8,4.4,1.7,6.2,2.4
		c0.8,0.3,1.6,0.5,2.4,0.7c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0l1.2-0.3c3-0.7,5-0.9,5.2-0.5s-1.5,1.5-4.4,2.9l-1.1,0.5
		c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.6,0.1-0.9,0.1c-1.1,0-2.2-0.2-3.3-0.5c-2.1-0.6-4.2-1.3-6.5-2.2c-4.5-1.8-8.3-3.5-11.2-4.5
		S407.3,478.3,407.3,477.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M394.8,485.8c0-0.5,2.5-1.1,6.5-0.6c2.3,0.3,4.6,0.8,6.8,1.5c1.3,0.4,2.5,0.8,3.9,1.3l2,0.8l0.9,0.3
		c0.3,0.1,0.5,0.2,0.8,0.2c1.3,0.2,2.7,0.3,4,0.3c1.3,0,2.6,0,3.9,0c2.2,0,4.3-0.1,6.5-0.3c0.6,0,1.1-0.3,1.4-0.8
		c0.3-0.4,0.5-0.9,0.6-1.4c0.3-1,0.4-1.6,0.6-1.6s0.6,0.5,0.8,1.7c0.1,0.8,0,1.6-0.3,2.3c-0.2,0.5-0.5,1-0.9,1.4
		c-0.2,0.2-0.5,0.4-0.8,0.6c-0.3,0.2-0.6,0.3-0.9,0.4c-2.3,0.7-4.6,1.1-7,1.2c-1.3,0.1-2.7,0.2-4.1,0.2c-1.5,0-3.1-0.1-4.6-0.3
		c-0.5-0.1-0.9-0.2-1.3-0.3c-0.4-0.1-0.8-0.3-1.1-0.4l-2-0.8c-1.3-0.5-2.5-1-3.6-1.5c-2.3-0.9-4.4-1.6-6.2-2.1
		C397.1,486.6,394.8,486.3,394.8,485.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M411,495c-0.2,0-0.2-0.3-0.6-0.6c-0.6-0.4-1.4-0.6-2.1-0.6c-1.2-0.1-2.4-0.1-3.6,0c-0.7,0-1.3,0.1-2,0.3
		c-0.1,0,0,0-0.1,0l0,0c0,0,0,0,0.1,0l2,0.1l3.3,0.2C409.8,494.5,411,494.7,411,495c0.1,0.2-1.1,0.6-3,0.8c-1,0.1-2.1,0.2-3.4,0.3
		c-0.6,0-1.3,0-2,0s-1.3-0.1-2-0.1l-2.1-0.2l0.2-0.7c0.1-0.3,0.2-0.6,0.3-0.8c0.3-0.5,0.7-1,1.2-1.3c0.3-0.2,0.6-0.4,1-0.5l0.5-0.2
		c0.2-0.1,0.4-0.1,0.5-0.1c0.8-0.2,1.6-0.3,2.5-0.3c1.4,0,2.7,0.2,4,0.5c1,0.2,1.8,0.8,2.4,1.6c0.2,0.3,0.2,0.5,0.2,0.8
		C411.1,494.9,411.1,495,411,495z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M399.9,496.9c-0.2,0-0.3-0.3-0.6-0.6c-0.4-0.4-1-0.7-1.7-0.7c-1.1-0.1-2.1,0-3.2,0.1c-0.3,0-0.7,0.1-0.9,0.1
		l0,0l0,0l0,0l0,0l0.8,0.1c1.1,0.1,2.1,0.2,2.9,0.2c1.6,0.2,2.7,0.4,2.7,0.7s-1,0.5-2.7,0.7c-0.8,0.1-1.8,0.2-3,0.2h-0.9h-1.7
		c-1.2,0-2.4-0.1-3.7-0.1l0.4-0.6c0.2-0.3,0.4-0.5,0.6-0.8c0.6-0.8,1.4-1.5,2.4-1.9c0.3-0.1,0.6-0.2,0.9-0.3s0.7-0.2,1-0.2
		c0.4,0,0.7-0.1,1.1-0.1c1.2-0.1,2.5,0,3.7,0.3c0.9,0.2,1.7,0.9,2.1,1.8C400.2,496.5,400,496.9,399.9,496.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M424.3,500.1c0,0.5-1.2,1.3-3.5,2.4c-1.4,0.7-2.8,1.2-4.2,1.6c-0.5,0.1-0.9,0.2-1.4,0.3
		c-0.3,0.1-0.6,0.1-0.9,0.1c-0.3,0-0.6,0-0.9-0.1c-1-0.2-2-0.5-3-1c-1.9-0.8-3.4-1.8-4.8-2.5s-2.6-1.2-3.8-1.7
		c-2.3-1-3.6-1.7-3.5-2.2s1.7-0.6,4.3-0.2c1.5,0.2,3,0.6,4.5,1.2c1.6,0.6,3.2,1.5,4.8,2.2c0.7,0.3,1.4,0.6,2.2,0.8
		c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4,0c0.4,0,0.7-0.1,1.1-0.1c1.5-0.2,2.9-0.6,4.2-0.8c1-0.2,2-0.3,3-0.4
		C423.7,499.7,424.2,499.9,424.3,500.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M449.8,487c0.2,0.5-2.3,1.6-5.7,4.2c-2,1.5-3.9,3.1-5.8,4.8c-1,1-2.1,2-3.3,3.1s-2.2,2.2-3.5,3.4l-0.3,0.2
		l-0.1,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c-0.3,0.3-0.6,0.5-1,0.7c-0.4,0.1-0.8,0.2-1.1,0.1c-0.4-0.1-0.8-0.2-1.1-0.3
		c-0.9-0.4-1.6-0.7-2.4-1c-1.5-0.7-2.8-1.4-4.1-2s-2.5-1.3-3.6-1.8c-1-0.5-2.1-0.9-3.1-1.1c-4.2-1-6.9-1.6-6.8-2.1s2.8-0.8,7.3-0.4
		c1.3,0.1,2.5,0.4,3.8,0.8c1.3,0.4,2.6,1,3.9,1.5l4.2,1.8c0.7,0.3,1.5,0.7,2.1,0.9l0,0l0,0l0.3-0.3l3.5-3.3c1.2-1.1,2.4-2.1,3.5-3
		c2-1.7,4.3-3.2,6.6-4.4C446.7,486.8,449.6,486.5,449.8,487z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M462.4,487.8c-14.3,5.5-26.3,9.1-26.7,8.1s10.9-6.3,25.3-11.8C461.7,485.2,462.2,486.5,462.4,487.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M355.6,475.3c0-0.2,0.8-0.4,2.2-0.3c2,0.2,3.9,0.6,5.7,1.3c2.7,0.9,5.4,2.1,7.9,3.4c1.4,0.7,2.9,1.5,4.5,2.3
		c0.8,0.4,1.5,0.8,2.3,1.1c0.4,0.2,0.8,0.3,1.1,0.5l1.3,0.5c3.4,1.4,6.6,2.7,9.5,3.7c2.5,1,5.1,1.6,7.7,2c1.6,0.1,3.2-0.4,4.5-1.5
		c0.9-0.8,1.4-1.4,1.6-1.3s0.2,0.9-0.5,2.2c-0.5,0.8-1.1,1.5-1.9,2c-1.1,0.7-2.3,1.1-3.6,1.2c-3,0-5.9-0.5-8.8-1.4
		c-3.3-1-6.6-2.2-9.9-3.5l-1.3-0.5c-0.4-0.2-0.9-0.4-1.3-0.6c-0.9-0.4-1.6-0.8-2.4-1.3l-4.4-2.5c-2.7-1.6-5.2-2.9-7.3-4
		C358.4,476.5,355.6,475.8,355.6,475.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M401.5,490c0.2,0.3,0.3,0.6,0.2,0.9c0,0.3,0,0.7,0,1c-0.1,0.7-0.3,1.6-0.8,1.8s-1.4-0.6-1.3-2
		c0.1-0.6,0.4-1.2,0.9-1.6C400.9,489.7,401.3,489.8,401.5,490z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M393.9,490.6c0.1,0.6-1.3,1.2-1.8,2.1s-0.5,2.4-1,2.6c-0.3,0.1-0.7-0.2-1-0.8c-0.4-0.9-0.4-2,0.1-2.8
		c0.5-0.9,1.4-1.5,2.4-1.6C393.4,490.1,393.8,490.3,393.9,490.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M329.3,441.1c0,0.3-0.3,0.5-0.6,0.8s-0.7,0.5-1.2,0.9c-0.9,0.7-1.6,1.6-2.2,1.4c-0.3-0.1-0.4-0.5-0.3-1.1
		c0.2-0.8,0.6-1.4,1.2-1.9c0.6-0.5,1.4-0.8,2.2-0.8C328.9,440.5,329.3,440.8,329.3,441.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M333,443.2c-0.1,0.6-1.4,0.8-2.6,1c-0.8,0.2-1.6,0.3-2.5,0.3c-0.3-0.1-0.4-0.6,0-1.2c0.5-0.6,1.3-1.1,2.2-1.1
		C331.9,441.9,333.1,442.6,333,443.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M329.6,483c-1-0.1-2-0.5-2.7-1.3c-0.5-0.5-0.9-1.2-0.9-2c-0.1-1.2,0.5-1.8,1-1.8s1.1,0.4,1.6,0.8
		c0.4,0.3,0.8,0.4,1.3,0.4C329.7,479.1,329.8,483,329.6,483z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M331.5,480.1c0,0.3-0.3,0.5-0.8,0.5c-0.7,0-1.4-0.4-1.7-1.1c-0.3-0.5-0.4-1.2-0.3-1.8c0.1-0.2,0.2-0.4,0.3-0.5
		c0.1-0.1,0.3-0.1,0.4-0.2c0.6,0,1,0.9,1.4,1.4c0.2,0.3,0.3,0.5,0.4,0.7C331.4,479.5,331.5,479.8,331.5,480.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M337.6,440c0,0.3-0.3,0.5-0.6,0.8s-0.8,0.5-1.2,0.9c-0.9,0.7-1.6,1.6-2.2,1.4c-0.3-0.1-0.4-0.5-0.3-1.1
		c0.2-0.8,0.6-1.5,1.2-2c0.6-0.6,1.4-0.8,2.2-0.8C337.3,439.4,337.6,439.7,337.6,440z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M341.4,442.1c-0.1,0.6-1.4,0.8-2.7,1c-0.8,0.2-1.6,0.3-2.5,0.3c-0.3,0-0.4-0.6,0-1.2c0.5-0.7,1.3-1.1,2.2-1.1
		C340.2,440.8,341.5,441.5,341.4,442.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M338,481.9c-0.5,0-1.1-0.2-1.6-0.4c-0.4-0.2-0.8-0.5-1.2-0.9c-0.5-0.5-0.9-1.2-1-2c-0.1-1.2,0.5-1.8,1-1.8
		s1.1,0.4,1.6,0.8c0.4,0.3,0.8,0.4,1.3,0.4C338.1,478,338.2,481.9,338,481.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M339.9,479c0,0.3-0.3,0.5-0.8,0.5c-0.7,0-1.3-0.4-1.7-1c-0.3-0.5-0.4-1.2-0.3-1.8c0.1-0.2,0.2-0.4,0.3-0.5
		c0.1-0.1,0.3-0.1,0.4-0.2c0.6,0,1,0.9,1.4,1.4c0.2,0.3,0.3,0.5,0.5,0.7C339.8,478.4,339.9,478.7,339.9,479z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M347.2,440.6c-0.6,0-1.1,0.1-1.7,0.2c5.6,1.4,10,9.5,10,19.3s-5,18.4-10,18.8c0.6,0.1,0.4,0.8,1.8,0.7
		c6.4-0.3,11.7-8.8,11.7-19.6S353.6,440.6,347.2,440.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M347.2,440.6c0,0.1,0.5,0.3,1.3,0.5c1.1,0.4,2.1,0.9,3,1.7c2.3,1.8,4.5,5.5,5.6,9.9c1.9,7.2,1,13.8,0.4,15.8
		c-0.6,1.9-1.5,3.7-2.7,5.4c-0.8,1.2-1.9,2.3-3.1,3.3c-0.3,0.3-0.6,0.5-1,0.7c-0.4,0.2-0.7,0.4-1.1,0.5c-0.2,0.1-0.4,0.2-0.6,0.2
		h-0.1h-0.1l0,0l0,0l0,0l0,0l0.1-0.1c0.3-0.2,0.6-0.5,0.9-0.8c0.5-0.5,1-1.1,1.4-1.7c0.7-1,1.3-2,1.8-3.1c0.6-1.3,1-2.6,1.4-4
		c0.7-2,1.1-4,1.5-6c0.5-3.5,0.3-7-0.5-10.4c-0.7-3.1-2.2-6-4.3-8.5c-1.4-1.7-3.4-2.9-5.5-3.3v0.8c1-0.4,1.6-0.5,1.6-0.6
		s-0.6-0.3-1.8-0.1l-1.5,0.2l1.5,0.5c1.8,0.7,3.4,2,4.6,3.5c1.7,2.4,3,5.1,3.7,7.9c0.8,3.2,1.2,6.5,1.1,9.9c0,2-0.3,4-0.8,6
		c-0.4,1.3-1,2.6-1.7,3.8c-0.6,1-1.2,1.9-1.9,2.8c-0.4,0.5-0.9,1-1.4,1.4c-0.3,0.2-0.5,0.4-0.8,0.6l-0.1,0.1
		c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0.1-0.4,0.2-0.7,0.3l-1.4,0.5c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.2l0,0
		l0,0c0,0,0,0,0,0.3v0.4l-0.1,0.1c0,0,0,0.1,0.1,0.1l0.2,0.2c0.2,0.2,0.5,0.4,0.8,0.4h1.1c0.4,0,0.7,0,1.1,0c0.2,0,0.4-0.1,0.7-0.2
		l0.6-0.2c0.2-0.1,0.5-0.2,0.7-0.3c0.5-0.2,0.9-0.4,1.3-0.7c0.4-0.2,0.8-0.5,1.1-0.8c1.3-1.1,2.3-2.4,3.1-3.9c1-1.8,1.7-3.8,2.2-5.8
		c0.6-2,1.1-4,1.4-6c0.5-3.5,0.3-7-0.6-10.4c-1.2-4.7-3.7-8.7-6.6-10.5c-1.1-0.7-2.3-1.2-3.6-1.3
		C347.7,440.4,347.2,440.5,347.2,440.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M345.5,440.8c0,0.3-0.3,0.5-0.7,0.9c-0.5,0.4-0.9,0.8-1.4,1.2c-1,1.1-1.9,2.2-2.5,2s-0.5-1.9,1-3.4
		c0.6-0.7,1.5-1.1,2.5-1.2c0.3,0,0.6,0,0.9,0.2C345.4,440.6,345.5,440.7,345.5,440.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M349.1,442.9c-0.1,0.6-1.3,0.8-2.6,1c-0.8,0.2-1.6,0.2-2.4,0.2c-0.3,0-0.4-0.6,0-1.2c0.5-0.6,1.3-1,2.1-1.1
		C348,441.6,349.2,442.3,349.1,442.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M345.9,481.5c-0.5,0-1.1-0.2-1.5-0.4c-0.4-0.2-0.8-0.5-1.1-0.8c-0.5-0.5-0.8-1.2-0.9-2c-0.1-1.2,0.5-1.8,1-1.8
		s1.1,0.4,1.6,0.8c0.4,0.2,0.8,0.4,1.2,0.4C345.9,477.5,346,481.5,345.9,481.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M347.7,478.6c0,0.3-0.3,0.5-0.8,0.5c-0.7,0-1.3-0.4-1.7-1c-0.3-0.5-0.4-1.2-0.3-1.8c0.1-0.2,0.2-0.3,0.3-0.5
		c0.1-0.1,0.3-0.1,0.4-0.2c0.6,0,1,0.9,1.4,1.4c0.2,0.2,0.2,0.5,0.4,0.7C347.6,478,347.7,478.3,347.7,478.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M339.4,439.7c-0.6,0-1.2,0.1-1.8,0.2c5.8,1.4,10.3,9.8,10.3,20s-5.2,19-10.4,19.5c0.6,0.1,0.4,0.8,1.8,0.8
		c6.7-0.3,12.1-9.1,12.1-20.2S346.1,439.7,339.4,439.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M339.4,439.7c0,0.1,0.5,0.3,1.3,0.5c1.1,0.4,2.2,1,3.1,1.7c2.4,1.9,4.6,5.7,5.8,10.3
		c1.9,7.5,1.1,14.3,0.4,16.4c-0.6,2-1.5,3.8-2.7,5.5c-0.9,1.3-2,2.4-3.2,3.4c-0.2,0.1-0.3,0.3-0.5,0.4l-0.5,0.3
		c-0.4,0.2-0.7,0.4-1.1,0.6c-0.2,0.1-0.4,0.2-0.6,0.2h-0.1H341h-0.1l0,0v0l0,0l0,0l0,0l0,0l0,0l0.1-0.1c0.3-0.3,0.7-0.5,1-0.8
		c0.3-0.3,0.6-0.6,0.8-0.9l0.7-0.9c0.7-1,1.4-2.1,1.9-3.2c0.6-1.4,1.1-2.8,1.5-4.2c0.7-2,1.2-4.1,1.5-6.2c0.5-3.6,0.3-7.2-0.6-10.8
		c-0.7-3.2-2.2-6.2-4.4-8.8c-1.5-1.8-3.5-3-5.7-3.4v0.8C338.7,440,339.4,439.9,339.4,439.7c0-0.1-0.7-0.3-1.9-0.1l-1.5,0.2l1.5,0.5
		c1.9,0.7,3.5,2,4.7,3.6c0.9,1.1,1.6,2.3,2.2,3.6c0.7,1.5,1.2,3,1.6,4.6c0.8,3.3,1.2,6.8,1.2,10.2c0,2.1-0.3,4.1-0.9,6.2
		c-0.5,1.4-1.1,2.7-1.8,4c-0.6,1-1.2,2-2,2.9l-0.7,0.7c-0.2,0.3-0.5,0.5-0.8,0.7c-0.3,0.2-0.6,0.5-0.9,0.7l-0.1,0.1l0,0h0l0,0
		l-0.1,0.1l-0.7,0.3l-1.4,0.5c0,0,0,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.2-0.1,0.3v0.1l0,0l0,0c0,0.1,0-0.1,0,0.4
		c0,0.1,0,0.2-0.1,0.4s0,0.1,0,0.1l0.1,0.1l0.1,0.2c0.2,0.2,0.5,0.3,0.8,0.4h1.1c0.4,0,0.8,0,1.2,0c0.2,0,0.5-0.1,0.7-0.2l0.5-0.1
		c0.3-0.1,0.5-0.2,0.7-0.3c0.5-0.2,0.9-0.4,1.3-0.7l0.6-0.4c0.2-0.2,0.4-0.3,0.5-0.5c1.3-1.1,2.4-2.5,3.2-4c1-1.9,1.8-3.9,2.3-6
		c0.7-2,1.1-4.1,1.4-6.2c0.5-3.6,0.3-7.2-0.6-10.8c-1.2-4.9-3.9-9-6.8-10.9c-1.1-0.8-2.4-1.2-3.7-1.4
		C339.9,439.5,339.4,439.6,339.4,439.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M331,440.9c-0.6,0-1.2,0.1-1.8,0.2c5.8,1.4,10.3,9.8,10.3,20s-5.2,19-10.4,19.5c0.6,0.2,0.4,0.8,1.8,0.8
		c6.6-0.3,12-9.1,12-20.2S337.7,440.9,331,440.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M331,440.9c0,0.1,0.5,0.3,1.3,0.5c1.1,0.4,2.2,1,3.1,1.7c2.4,1.9,4.6,5.7,5.8,10.3c1.9,7.5,1.1,14.3,0.4,16.4
		c-0.6,2-1.5,3.9-2.7,5.5c-0.9,1.3-2,2.4-3.2,3.4c-0.2,0.1-0.3,0.3-0.5,0.4l-0.5,0.3c-0.4,0.2-0.7,0.4-1.1,0.5
		c-0.2,0.1-0.4,0.2-0.6,0.2h-0.2h-0.1l0,0c0,0-0.1,0-0.1,0s0,0,0,0l0,0l0,0l0,0l0,0l0,0l0,0l0.1-0.1c0.4-0.3,0.7-0.5,1-0.8
		c0.3-0.3,0.6-0.6,0.8-0.9l0.7-0.9c0.7-1,1.3-2.1,1.9-3.3c0.6-1.4,1.1-2.8,1.5-4.2c0.7-2,1.2-4.1,1.5-6.2c0.5-3.6,0.3-7.2-0.6-10.8
		c-0.7-3.2-2.2-6.2-4.4-8.7c-1.4-1.8-3.5-3-5.7-3.4v0.8C330.3,441.2,331,441.1,331,440.9c0-0.1-0.7-0.3-1.9-0.1l-1.5,0.2l1.5,0.5
		c1.9,0.8,3.5,2,4.7,3.6c0.9,1.1,1.6,2.4,2.2,3.6c0.7,1.5,1.2,3,1.6,4.6c0.9,3.3,1.2,6.8,1.2,10.2c0,2.1-0.3,4.1-0.9,6.2
		c-0.5,1.4-1.1,2.7-1.8,4c-0.6,1-1.2,2-2,2.9l-0.7,0.7c-0.2,0.3-0.5,0.5-0.8,0.7c-0.3,0.2-0.6,0.5-0.9,0.7l-0.1,0.1l0,0h-0.1l0,0
		c0,0-0.1,0.1-0.1,0.1l-0.7,0.3l-1.4,0.5c0,0.1,0,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.2-0.1,0.3v0.1l0,0l0,0
		c0,0,0-0.1,0,0.4c0,0.1,0,0.3-0.1,0.4c0,0,0,0.1,0,0.1l0.1,0.1c0,0.1,0.1,0.1,0.2,0.2c0.2,0.2,0.5,0.3,0.8,0.4h1.1
		c0.4,0,0.8,0,1.2,0c0.2,0,0.5-0.1,0.7-0.2l0.5-0.1c0.3-0.1,0.5-0.2,0.7-0.3c0.5-0.2,0.9-0.4,1.3-0.7l0.6-0.4
		c0.2-0.1,0.4-0.3,0.5-0.5c1.3-1.1,2.4-2.5,3.2-4c1-1.9,1.8-3.9,2.3-6c0.7-2,1.1-4.1,1.4-6.2c0.5-3.6,0.3-7.2-0.6-10.8
		c-1.2-4.9-3.9-9-6.8-10.8c-1.1-0.8-2.4-1.2-3.7-1.4C331.5,440.6,331,440.7,331,440.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M265.6,440.8c0.5-5.1,2.6-9.8,6.1-13.6c2.6-3,5.8-5.4,9.4-7.2c0.8-0.4,1.4-0.8,2.1-1l2-0.8
		c0.6-0.2,1.2-0.4,1.7-0.6l1.5-0.4c1.8-0.4,2.8-0.5,2.9-0.3s-0.8,0.9-2.4,1.7l-1.3,0.7c-0.5,0.3-1,0.6-1.5,0.9l-1.8,1
		c-0.6,0.4-1.2,0.8-1.9,1.2c-3.1,1.9-5.8,4.3-8,7.1c-1.3,1.6-2.4,3.4-3.3,5.3c-0.9,2-1.5,4.1-1.7,6.2
		C269.5,441.1,265.6,441.2,265.6,440.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M312.5,398.5c0,0.2-0.4,0.6-1.3,1c-0.4,0.2-0.9,0.4-1.5,0.6s-1.3,0.6-2,0.9c-3.8,1.6-7.3,3.7-10.5,6.3
		c-3.2,2.6-6.1,5.6-8.7,8.8c-2.1,2.5-3.3,4.1-3.8,3.9s0-2.3,1.6-5.3c2.2-3.9,5.1-7.4,8.6-10.2c3.5-2.9,7.6-4.9,12-5.9
		c0.7-0.2,1.5-0.3,2.3-0.4c0.7,0,1.3,0,1.8,0C312,398.1,312.5,398.2,312.5,398.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M405.4,414.1c0,0.2-1.2,0.8-3.5,1.1c-3.3,0.4-6.7,0.3-10-0.3l-0.8-0.1c-0.3,0-0.6-0.1-0.9-0.2
		c-0.3-0.1-0.6-0.2-1-0.3l-0.9-0.4c-1.1-0.6-2.1-1.3-3-2.1c-1.9-1.6-3.8-3.3-5.8-5.2c-1-0.9-2-1.8-3-2.7c-1-0.9-2-1.6-3.1-2.2
		c-0.3-0.1-0.4-0.2-0.7-0.3c-0.3-0.1-0.6-0.2-0.9-0.2c-0.7-0.1-1.4-0.2-2-0.3c-1.5-0.1-2.9-0.1-4.4,0c-5.6,0.4-11.2,1.7-16.4,4
		c-1.2,0.5-2.3,1.1-3.5,1.6s-2.3,1.1-3.4,1.6c-2.2,1-4.3,1.9-6.3,2.6c-7.8,2.9-13,3.4-13,2.9c-0.1-0.4,4.7-1.8,12.1-5.2
		c1.8-0.8,3.8-1.8,5.9-2.9c1-0.5,2.1-1.1,3.2-1.7s2.3-1.3,3.6-1.8c5.5-2.6,11.4-4.2,17.5-4.6c1.6-0.1,3.2-0.1,4.9,0
		c0.8,0.1,1.6,0.2,2.4,0.3c0.4,0.1,0.8,0.2,1.2,0.3c0.5,0.1,0.9,0.3,1.3,0.5c1.4,0.8,2.7,1.7,3.9,2.8c1.1,1,2.1,2,3.1,2.9
		c1.9,1.9,3.7,3.7,5.4,5.3c0.8,0.7,1.6,1.4,2.5,2l0.6,0.4c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.2,0.7,0.2l0.7,0.2
		c3,0.8,6.2,1.2,9.3,1.3C404.1,414,405.4,413.9,405.4,414.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M487.9,435.6c0.2-0.1,0-0.9-0.7-2.2c-0.3-0.7-0.7-1.5-1.2-2.4c-0.6-1-1.2-2-1.9-3c-3.8-5.7-8.7-10.6-14.3-14.5
		c-4.7-3.3-10-5.9-15.5-7.4c-3.3-0.9-6.7-1.6-10-2c-0.9-0.2-2.2-0.4-4-0.6l-1.4-0.2c-0.5,0-1,0-1.5-0.1c-1.1,0-2.2,0.1-3.4,0.2
		c-5.7,0.7-11.3,1.9-16.7,3.8c-1.5,0.5-3,1.1-4.4,1.6s-2.8,1.2-4.1,1.9c-2.6,1.3-4.9,2.5-7,3.6c-4.1,2.3-7.3,4.1-8.9,4.8
		s-5,2-9.4,3.6c-2.2,0.8-4.7,1.8-7.3,2.8s-5.5,2.2-8.3,3.3c-3.4,1.2-6.8,2.3-10.3,3.2c-3.2,0.9-6.2,1.5-8.6,2.1c-5,1.2-8,1.9-8,2.4
		s3.3,0.6,8.4,0.1c2.6-0.3,5.6-0.7,9-1.4c3.7-0.7,7.3-1.8,10.8-3.1c3-1.2,5.8-2.5,8.3-3.8s4.9-2.5,7-3.6c4.1-2.3,7.3-4,8.9-4.8
		s5-2,9.4-3.7l7.3-2.8c1.3-0.5,2.6-1,4-1.5l4.3-1.5c5.9-2,11.4-3.8,15.9-4.9c1.1-0.3,2.2-0.5,3.1-0.6c0.5,0,1-0.1,1.4-0.1h1.3
		c1.3,0,2.6,0.1,3.9,0.3c3.3,0.7,6.4,1.7,9.4,3.1c5,2.2,9.8,5,14.3,8.2c5.3,3.8,10.1,8.2,14.3,13.1c0.8,0.9,1.6,1.8,2.3,2.6
		s1.2,1.4,1.7,2C487.1,435.2,487.7,435.8,487.9,435.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M378.8,497.5c-0.1,0.2-1.3-0.3-3.5-0.7c-1.3-0.2-2.6-0.3-3.9-0.2c-0.7,0.1-1.6,0.2-2.5,0.3l-2.9,0.4
		c-4.1,0.5-8.9,1.1-14.3,1.7l-8.5,0.9c-3.1,0.3-6.3,0.3-9.4,0c-5.9-0.7-11.8-1.8-17.5-3.4l-3.8-1c-1.3-0.3-2.5-0.7-3.7-1.1
		c-2.2-0.8-4.3-1.7-6.3-2.8c-2.9-1.6-5.6-3.4-8.2-5.4c-1.8-1.5-2.6-2.4-2.5-2.6s1.3,0.4,3.3,1.5s4.9,2.7,8.5,4.3
		c2,0.9,4,1.6,6.1,2.3c1.1,0.3,2.2,0.6,3.5,0.9l3.9,0.8c5.6,1.3,11.3,2.3,17,3c2.9,0.3,5.9,0.3,8.8,0.1l8.4-0.7
		c5.4-0.4,10.2-0.7,14.3-0.9l2.9-0.1c0.9,0,1.8-0.1,2.7-0.1c1.5,0.1,2.9,0.4,4.3,0.9C378,496.4,379,497.3,378.8,497.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M424.4,506.4c0.1,0.4-2.8,1.9-7.8,2.8c-13.2,2.4-26.8,0.6-39-5c-4.6-2.1-7.2-3.9-7-4.4s3.2,0.5,7.9,2
		c6.1,2,12.3,3.4,18.7,4.3c6.3,0.8,12.8,0.9,19.1,0.5C421.2,506.4,424.2,505.9,424.4,506.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M467.1,490.8c0,0.5-3.6,0.6-8.6,3.2c-1.3,0.7-2.6,1.5-3.8,2.4c-0.7,0.5-1.3,1-2,1.6s-1.5,1.2-2.2,1.8
		c-3.4,2.6-7,4.9-10.7,6.9c-3.7,2.1-7.7,3.7-11.8,5c-1.8,0.6-3.7,1-5.6,1.2c-1.7,0.1-3.3,0.1-5-0.1c-2.4-0.2-4.8-1-6.9-2.3
		c-1.5-1-2-1.8-1.8-2s0.9,0.3,2.4,0.7c2.1,0.7,4.2,1,6.4,1c1.5,0,3-0.1,4.5-0.3c1.7-0.3,3.4-0.8,5-1.3c3.8-1.4,7.5-3,11.1-5
		c3.6-2,7.1-4.1,10.4-6.4c0.8-0.5,1.5-1.1,2.2-1.6c0.7-0.5,1.5-1.1,2.3-1.5c1.4-0.9,2.9-1.6,4.5-2.2c2.2-0.9,4.6-1.4,7-1.4
		C466.2,490.2,467.2,490.5,467.1,490.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M306.3,404.7c0-0.3,0.4-0.5,1.2-0.8c0.4-0.1,0.9-0.3,1.5-0.5s1.3-0.2,2-0.4c3.9-0.5,7.8-0.4,11.7,0.1
		c9.1,1.2,15.8,4.4,15.5,5.4s-7.3-0.6-16-1.8c-4.3-0.6-8.2-0.9-11.1-1.2c-0.7,0-1.4-0.1-1.9-0.2s-1.1-0.1-1.5-0.1
		C306.9,405.1,306.4,404.9,306.3,404.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M357.1,400c-0.2-0.1-0.1-0.8,0-1.8c0.1-0.6,0-1.1-0.1-1.6c-0.1-0.2-0.2-0.4-0.4-0.6c0,0-0.1-0.1-0.1-0.1
		l-0.2-0.1l-0.6-0.4c-1.7-1.4-3.5-2.7-5.4-3.9c-0.9-0.6-2-1-3-1.3c-0.5,0-0.9,0-1.4,0.2c-0.6,0.2-1.1,0.4-1.6,0.7
		c-2.2,1.4-4.3,3-6.2,4.9c-0.7,0.8-1.4,1.7-1.9,2.6c-0.6,0.9-1.1,1.9-1.6,2.7c-1.9,3.4-3.2,5.5-3.7,5.3s0-2.6,1.4-6.4
		c0.4-0.9,0.8-2,1.3-3c0.3-0.5,0.5-1.1,0.9-1.7c0.4-0.7,0.8-1.3,1.3-1.8c2-2.2,4.3-4.2,6.8-5.7c1.6-1,3.4-1.4,5.3-1.2
		c1.6,0.3,3.1,1,4.4,2c1.9,1.4,3.7,3,5.3,4.8l0.5,0.5c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.1,0.2,0.3,0.3,0.4c0.3,0.5,0.5,1.1,0.5,1.7
		c0,0.8-0.2,1.6-0.6,2.3C357.9,399.7,357.3,400.1,357.1,400z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M357.1,400.6c-0.3,0.1-0.8-0.3-1.5-0.9l-3.1-2.4c-2.5-1.9-4.4-3.5-4.1-4s2.8,0.4,5.4,2.3
		c1.2,0.8,2.2,1.9,2.9,3.1C357.3,399.8,357.4,400.5,357.1,400.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M349.4,403.5c-1.3-1.1-2.8-2.1-3.7-3s-1.5-1.4-1.3-1.7s1-0.1,2.1,0.4c1.6,0.7,3,1.6,4.3,2.7
		c-0.2,0.3-0.4,0.6-0.7,0.8l-0.5,0.5L349.4,403.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M343.3,406.2c-0.4-0.4-0.8-0.7-1.3-1c-0.5-0.3-0.9-0.6-1.3-0.9c-0.7-0.5-1.1-1-1-1.2s0.7-0.3,1.6-0.1
		c0.5,0.1,1.1,0.3,1.6,0.5c0.7,0.3,1.4,0.7,1.9,1.3L343.3,406.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M349.9,388.9c-1,0-1.5-4.6-1.8-10c0-0.7-0.1-1.3-0.1-1.9c0-0.5-0.1-1-0.3-1.5c-0.2-0.9-0.6-1.8-1-2.6
		c-0.8-1.5-1.4-2.5-1-2.9s1.6,0,3.1,1.4c0.9,0.8,1.6,1.9,2.1,3c0.3,0.7,0.5,1.4,0.6,2.2c0.1,0.7,0.2,1.4,0.3,2.2
		C352,384.4,350.8,388.9,349.9,388.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M301.8,369.9c0.1-0.4,2.9-0.2,6.9,1.5c5.2,2.3,9.9,5.4,14,9.4c1.2,1.1,2.3,2.3,3.3,3.6c1,1.2,1.8,2.5,2.5,3.9
		c1.2,2.2,2.2,4.5,3.1,6.9c1.4,4,1.9,6.7,1.4,6.8s-1.8-2.1-3.7-5.8c-1-1.8-2.2-4-3.6-6.3c-0.7-1.2-1.5-2.3-2.4-3.3
		c-1-1.1-2-2.2-3.1-3.2c-3.9-3.7-8.1-6.9-12.7-9.7C303.9,371.5,301.6,370.4,301.8,369.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M325.7,389.3c1.8-3.9,3.8-7.8,6-11.5c0.5-0.9,1.1-1.7,1.7-2.5c0.3-0.4,0.6-0.8,1-1.2c0.3-0.4,0.7-0.7,1-1
		c1.3-1.2,2.8-2.2,4.4-2.9c1.3-0.6,2.8-1,4.3-1.1c1.1-0.1,2.2,0,3.3,0.2c1.7,0.4,2.5,1,2.5,1.2s-1,0.2-2.6,0.3
		c-2.2,0.1-4.3,0.7-6.1,1.8c-1.3,0.8-2.4,1.7-3.5,2.8c-0.3,0.3-0.5,0.6-0.8,0.9s-0.5,0.6-0.7,1c-0.5,0.7-0.9,1.5-1.4,2.3
		c-1.8,3.3-3.7,7.2-5.6,11.3C329.1,391.1,325.6,389.5,325.7,389.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M356.4,394.1c-0.2-0.1,0-0.9,0.2-2.4c0.3-2.1,0.5-4.2,0.6-6.3c0.1-1.3,0.1-2.8,0-4.4c0-0.8-0.1-1.6-0.1-2.4
		c0-0.7-0.2-1.4-0.5-2c-1.5-3.3-3.8-6.1-6.9-8c-1.5-0.9-3.2-1.5-5-1.8c-1.7-0.3-3.4-0.3-5,0.1c-1.5,0.3-3,0.8-4.4,1.5
		c-1.3,0.6-2.4,1.4-3.6,2.2c-1.7,1.3-3.2,2.7-4.7,4.2c-1.1,1-1.7,1.6-1.9,1.5s0-1,0.8-2.4c1.1-1.9,2.6-3.7,4.2-5.2
		c1.1-1.1,2.4-2.1,3.8-2.9c1.6-1,3.3-1.7,5.1-2.2c0.5-0.1,1-0.2,1.5-0.3l1.6-0.1c0.5,0,1.1,0,1.6,0c0.5,0,1.1,0.1,1.6,0.1
		c2.3,0.3,4.4,1,6.4,2.2c3.6,2.3,6.4,5.7,8,9.8c0.4,1,0.6,2.1,0.6,3.1c0,0.9,0,1.8,0,2.6c-0.1,1.5-0.2,3.1-0.5,4.6
		c-0.3,2.2-0.9,4.4-1.8,6.4C357.3,393.6,356.6,394.3,356.4,394.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M332.5,369.4c-3-0.4-5.9-0.6-8.9-0.6c-2.9,0-5.7,0.1-8.4,0.3s-5.2,0.5-7.5,0.9s-4.5,0.9-6.5,1.4
		c-7.9,2-12.7,3.9-13,3.3s4.2-3.3,12.2-6c2-0.7,4.2-1.3,6.6-1.9c2.6-0.6,5.2-1,7.8-1.3c6.1-0.7,12.2-0.7,18.3,0
		C333.5,365.6,332.7,369.4,332.5,369.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M398.6,388.9c-0.1,0-0.3-0.1-0.5-0.5c-0.1-0.2-0.2-0.5-0.2-0.8c0-0.4,0.1-0.7,0.3-1c0.3-0.3,0.7-0.6,1.1-0.8
		c0.4-0.2,0.7-0.4,1.1-0.6c0.8-0.4,1.7-0.9,2.4-1.5c0.3-0.2,0.6-0.5,0.8-0.9c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.1
		c0-0.1,0-0.1-0.1-0.2c0-0.1-0.2-0.1-0.3-0.1h-0.1l0,0h-0.1c-0.3,0.1-0.6,0.2-0.9,0.4c-1.3,0.6-2.6,1.3-4,2
		c-0.7,0.4-1.4,0.8-2.1,1.2l-1,0.6l-0.5,0.3l-0.1,0.1l-0.1,0.2l-0.2,0.5c-0.1,0.4-0.3,0.7-0.5,1.1c-0.3,0.7-0.6,1.5-0.9,2.2
		c-0.5,1.5-1.1,3-1.6,4.4c-0.3,0.7-0.5,1.4-0.8,2.1c-0.3,0.7-0.6,1.4-1,2.1c-0.4,0.7-1,1.2-1.7,1.7c-0.5,0.4-1.1,0.7-1.7,1
		c-4.5,2-7.6,1.4-7.5,0.9c0-0.3,0.7-0.5,1.8-0.9c1.6-0.6,3.1-1.3,4.5-2.3c0.8-0.5,1.6-1.2,2.1-2.1c0.2-0.5,0.4-1.1,0.6-1.7
		c0.2-0.6,0.4-1.3,0.6-2c0.4-1.4,0.9-2.9,1.4-4.5c0.3-0.8,0.5-1.6,0.8-2.4c0.1-0.4,0.3-0.8,0.5-1.3c0.2-0.5,0.5-1,0.9-1.5l0.4-0.5
		l0.4-0.3l0.6-0.4l1.1-0.7c0.8-0.4,1.5-0.8,2.2-1.2c1.4-0.7,2.9-1.4,4.4-2c0.4-0.1,0.8-0.3,1.1-0.4l0.4-0.1c0.2-0.1,0.3-0.1,0.5-0.1
		c0.3,0,0.6,0,1-0.1c1.2,0.1,2.2,1,2.5,2.2c0.1,0.6,0.2,1.1,0.1,1.7c-0.1,0.3-0.1,0.6-0.3,0.8c-0.1,0.2-0.2,0.4-0.3,0.6
		c-0.5,0.7-1.1,1.2-1.8,1.5c-1,0.5-2,0.9-3,1.2l-1.2,0.3c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.1-0.1,0.3-0.1,0.4
		C398.8,388.7,398.7,388.9,398.6,388.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M414.6,388.7c-0.1-0.2,0.2-0.6,0.3-1s0.1-0.9-0.3-1.1c-0.2,0-0.4,0-0.5,0.1c-0.5,0-1.2,0-1.9,0.1
		c-0.7,0-1.3,0.1-2,0.3c-0.7,0.2-1.5,0.4-2.3,0.6c-1.6,0.5-3.2,0.9-4.7,1.3c-0.7,0.2-1.5,0.3-2.1,0.5c-0.4,0.1-0.5,0.1-0.8,0.1
		c-0.2,0.1-0.4,0.1-0.6,0.2c-0.7,0.3-1.4,0.7-2,1.2c-0.5,0.4-0.9,0.5-1.1,0.5s-0.3-0.6-0.1-1.3c0.4-1,1-1.9,1.8-2.5
		c0.3-0.2,0.6-0.4,1-0.6c0.3-0.2,0.7-0.3,1-0.5l2-0.8c1.4-0.5,3-1.1,4.7-1.6c0.9-0.2,1.7-0.5,2.6-0.6c0.5-0.1,0.9-0.2,1.4-0.2
		c0.2,0,0.5,0,0.7,0h0.6c0.8,0,1.6,0.1,2.4,0.3c1,0.1,2,0.5,2.6,1.3c0.3,0.7,0.3,1.6-0.1,2.3c-0.2,0.5-0.6,1-1.1,1.3
		C415.1,389.1,414.6,388.9,414.6,388.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M390.2,412.9c0,0.5,1.6,1,4.2,0.6c3.5-0.5,6.7-2.2,9-4.8c1-1.2,1.9-2.5,2.6-3.9c0.1-0.3,0.3-0.6,0.4-0.8
		l0.1-0.4c0,0,0-0.1,0-0.2l0,0l0-0.5l0.2,0h0.1h0.1h0.1l1.1-0.1c0.7-0.1,1.3-0.1,1.7-0.2c1-0.1,1.5-0.2,2-0.2s1.1,0,1.9,0.1
		c0.5,0,1,0,1.5,0c0.9-0.1,1.6-0.7,2-1.5c0.4-0.9,0.4-2-0.1-2.9c-0.3-0.5-0.7-0.9-1.2-1.2c-0.3-0.1-0.5-0.3-0.8-0.3
		c-0.2-0.1-0.4-0.1-0.6-0.2c-1.5-0.3-3-0.5-4.5-0.5c-1.5,0-2.9,0-4.4,0.2h-0.2h-0.1c0-0.1,0,0.2,0,0.3s-0.1,0.3-0.1,0.4
		c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1,0.1-0.1,0.6,0s0.3,0,0.4,0c0,0,0-0.1,0-0.1V397v-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1v-0.1
		l0.1-0.1c0.7-0.5,1.5-0.9,2.3-1.1c0.9-0.2,1.5-0.4,1.9-0.5c0.9-0.2,2.6-0.2,4.9-0.6c0.2,0,0.3-0.1,0.5-0.1c0.2,0,0.4-0.1,0.6-0.2
		c0.4-0.2,0.8-0.4,1.1-0.7c0.4-0.3,0.7-0.7,0.9-1.1c0.3-0.5,0.5-1.1,0.6-1.7c0.1-0.6-0.1-1.3-0.4-1.8c-0.4-0.6-0.9-1-1.5-1.2
		c-0.3-0.1-0.7-0.2-1-0.3c-0.3,0-0.5-0.1-0.8-0.1c-0.5,0-1.1,0-1.6,0.1l-0.3,0.1l-0.2,0.1l-0.1,0l0,0c-0.1-0.2,1,2.1,0.5,1.1
		l-0.1-0.2c-0.1-0.2-0.2-0.5-0.3-0.8l-0.1-0.2l0,0l0,0h-0.1l-0.5,0.1l-1.3,0.2c-0.8,0.2-1.7,0.3-2.5,0.5s-1.6,0.4-2.3,0.6
		c-0.4,0.1-0.7,0.2-1.1,0.3c-0.3,0.1-0.7,0.3-1,0.4c-1,0.5-1.9,1.3-2.4,2.3c-0.4,0.7-0.3,1.2-0.1,1.3c0.4,0.3,1.5-0.8,3.5-1.2
		c0.9-0.2,2.4-0.3,4-0.4l2.5-0.3c0.4,0,0.9-0.1,1.4-0.2c0.3,0,0.5-0.1,0.8-0.2l0.2-0.1c0.1,0,0.3-0.1,0.4-0.1h0.1
		c0.4,0.8-0.6-1.1-0.5-0.9l0,0l0,0l0.1,0.2c0.1,0.1,0.1,0.2,0.1,0.4c0,0.1,0.1,0.3,0.1,0.4l0,0c0.2,0,0.4-0.1,0.6,0h0.3l0,0
		c0.1,0,0.1,0,0.2,0c0,0,0,0.1,0,0.1c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.1-0.3,0.3-0.5,0.3
		c-1.4,0.7-2.9,1.3-4.4,1.8c-0.6,0.1-1.2,0.1-1.8,0.1c-1.1,0-2.2,0.1-3.3,0.5l-0.3,0.1c-0.1,0.1-0.2,0.1-0.3,0.2
		c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.1-0.1,0.2-0.1,0.4l-0.1,0.3c-0.1,0.2-0.2,0.5-0.2,0.8
		c0,0.3,0,0.5,0.1,0.8c0.1,0.4,0.3,0.7,0.6,0.9c0.4,0.3,0.8,0.5,1.3,0.5l0.6,0c1.4,0.1,2.8,0.2,4.3,0.2s2.9,0,4.1,0h0.4
		c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0.4,0.3,0.5c0,0.2-0.1,0.4-0.3,0.5c-0.4,0.2-0.7,0.3-1.1,0.4
		c-0.6,0.2-1.2,0.3-1.8,0.3c-0.6,0-1.3,0-1.9-0.1l-1.7-0.2l-1.1-0.1l-2-0.2c-0.3,0.4-0.6,0.8-0.9,1.2l-0.2,0.2l-0.1,0.1l-0.2,0.3
		l-0.5,0.6c-0.7,0.9-1.6,2-2.5,3.1c-0.6,0.6-1.2,1.1-1.8,1.7c-0.6,0.5-1.2,0.9-1.8,1.3c-1.1,0.7-2.3,1.3-3.5,1.8
		C391.6,412,390.1,412.4,390.2,412.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M394.4,385c-0.1,0.2-0.7,0.2-1.6,0.1c-0.5-0.1-1-0.2-1.7-0.4s-1.4-0.3-2.2-0.6l-12.6-3.4
		c-9.9-2.6-18-4.6-17.8-5.6s8.6-0.6,18.7,2.1c4.3,1.1,8.5,2.6,12.6,4.5c0.8,0.4,1.5,0.7,2,1.1s1.1,0.7,1.5,1
		C394.1,384.4,394.5,384.8,394.4,385z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M425.3,407.9c-3.8-3.7-7.4-6.9-9.9-9.4s-4-4.1-3.7-4.5s2.4,0.5,5.4,2.4c4,2.5,7.6,5.5,11,8.8
		C427.2,406.2,426.3,407.1,425.3,407.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M295.5,388.3c0,0.3-0.4,0.5-1,1c-0.7,0.7-1.3,1.6-1.5,2.5c-0.4,1.4-0.3,3,0.3,4.4c0.3,0.9,0.7,1.7,1.2,2.5
		c0.2,0.4,0.5,0.7,0.8,1.1l0.2,0.2l0,0l0,0l0.1-0.1c0.3-0.3,0.6-0.6,0.8-1c0.5-0.8,0.9-1.6,1.3-2.5c0.6-1.3,0.7-2.9,0.3-4.3
		c-0.3-1-0.8-1.9-1.5-2.7C295.9,388.8,295.6,388.6,295.5,388.3c0-0.2,0.6-0.5,1.6-0.1c1.3,0.6,2.4,1.6,3.1,2.9
		c0.5,0.9,0.8,1.9,0.9,2.9c0.1,1.1-0.1,2.3-0.4,3.4c-0.3,1.2-0.8,2.3-1.4,3.3c-0.4,0.6-0.8,1.2-1.3,1.7c-0.4,0.4-0.8,0.7-1.2,0.9
		c-0.5,0.3-1.1,0.4-1.6,0.4c-0.3,0-0.6-0.1-0.8-0.2c-0.3-0.1-0.5-0.2-0.8-0.4c-0.2-0.1-0.5-0.3-0.7-0.5c-0.2-0.1-0.3-0.3-0.4-0.4
		c-0.5-0.5-0.9-1.1-1.2-1.7c-0.6-1.1-1-2.2-1.3-3.4c-0.3-1.1-0.4-2.2-0.3-3.4c0.1-1,0.4-2,0.9-2.9c0.7-1.3,1.9-2.3,3.3-2.8
		C295,387.7,295.6,388.1,295.5,388.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M281.6,395.6c0.3,0,0.5,0.1,0.9,0.1c0.2,0,0.4,0,0.6,0h0.4c0.1,0,0.2-0.1,0.2-0.1c0.1-0.1,0.2-0.2,0.2-0.3
		c0,0,0-0.1,0-0.1v-0.1v-0.3c-0.1-0.7-0.1-1.3-0.1-2c0-0.6,0.2-1.2,0.6-1.7c0.2-0.3,0.5-0.7,0.7-1c0.6-0.6,1.1-1.4,1.2-2.3
		c0.1-0.2,0.1-0.5,0-0.7c-0.1-0.2-0.2-0.5-0.4-0.6c-0.4-0.4-0.9-0.7-1.4-0.9c-0.4-0.2-0.7-0.4-0.7-0.6s0.2-0.6,0.8-0.8
		c0.8-0.3,1.7-0.2,2.6,0.1c0.7,0.2,1.3,0.7,1.7,1.2c0.5,0.7,0.8,1.5,0.9,2.4c0,0.8-0.1,1.7-0.5,2.5c-0.3,0.7-0.8,1.4-1.3,2
		c-0.2,0.2-0.3,0.4-0.5,0.6c-0.1,0.1,0,0-0.1,0.1c-0.1,0.3-0.1,1-0.1,1.6v0.5c0,0.3-0.1,0.5-0.1,0.8c-0.1,0.5-0.3,0.9-0.6,1.3
		c-0.6,0.6-1.4,1-2.2,0.9c-0.3,0-0.7-0.1-1-0.2c-0.2-0.1-0.5-0.2-0.7-0.3c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.2-0.2-0.5-0.2-0.8
		C281.4,395.7,281.5,395.6,281.6,395.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M276.1,384c-0.5,0.1-1.1-0.2-1.6-1c-0.6-1.1-0.9-2.4-0.7-3.7c0.1-1.3,0.8-2.6,1.8-3.5c0.8-0.7,1.6-0.8,1.9-0.4
		c0.3,0.5,0.4,1.1,0.2,1.7c-0.1,0.7-0.2,1.6-0.3,2.6C277.1,381.6,277.1,383.7,276.1,384z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M271.1,409.1c-0.5,0.1-1.1-0.5-1.6-1.6c-0.6-1.5-0.8-3.2-0.6-4.9c0.2-1.7,0.9-3.3,1.9-4.6c0.9-1,1.8-1.3,2.1-1
		s0.3,1.1,0.1,2.1s-0.4,2.4-0.6,3.9C272.1,406.1,272.1,408.9,271.1,409.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M276,406.9c-0.3-0.1-0.2-1.8-0.3-3.9s0-3.8,0.3-3.9s0.8,1.7,0.8,3.9S276.3,407,276,406.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M279.6,382.1c-0.2-0.1,0-1.2,0.3-2.6s0.7-2.5,1-2.5s0.4,1.3,0,2.7S279.9,382.3,279.6,382.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M265.4,410.6c0,0-0.6-4.7-0.2-6.1s3.3-5.9,3.6-7s-1.2-3.3-1.2-3.3c-0.4,1.2-0.9,2.3-1.5,3.4
		c-0.5,0.7-3.1,5-3,6.6S265.4,410.6,265.4,410.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M271.7,373c0,0-2.8,2.7-2.9,3.3s1.7,5.5,1.6,6.8c0,0.6-0.2,1.2-0.6,1.8c0,0,2.3-1.3,2.5-2.5s-1.2-5.4-1.1-6.2
		S271.7,373,271.7,373z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M260.8,441.7c-0.1,0.5-1.3,0.7-3.1,0.2c-1.1-0.3-2.1-0.8-3.1-1.4c-0.6-0.4-1.1-0.9-1.6-1.5
		c-0.6-0.7-1-1.4-1.2-2.3c-0.3-0.9-0.3-1.8-0.1-2.7c0.2-0.8,0.5-1.5,0.9-2.2c0.7-1,1.7-1.8,2.8-2.4c1.8-0.8,3.1-0.6,3.2-0.1
		s-0.7,1.2-1.8,2.3c-0.6,0.6-1,1.2-1.4,1.9c-0.3,0.7-0.4,1.5-0.2,2.2c0.3,0.8,0.8,1.6,1.5,2.1c0.7,0.6,1.3,1.2,2.1,1.7
		C260.1,440.5,261,441.2,260.8,441.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M266.5,433.5c-0.1,0.3-0.9,0.2-2,0.1c-0.6-0.1-1.3-0.1-1.9-0.2c-0.7,0-1.4,0.1-2,0.3c-0.3,0.1-0.5,0.3-0.7,0.5
		c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.5-0.2,1-0.1,1.5c0.1,1.1,0.3,1.8,0,2s-0.9-0.4-1.4-1.6
		c-0.4-0.8-0.5-1.7-0.3-2.5c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.2,0.3-0.5,0.5-0.7c0.4-0.5,0.8-0.8,1.4-1c1-0.4,2-0.5,3-0.3
		c0.7,0.1,1.5,0.3,2.1,0.7C266,432.7,266.6,433.3,266.5,433.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M459.8,574.5c-0.2-0.1-0.4,0.2-0.6,0.8c-0.3,0.8-0.4,1.7-0.5,2.5c-0.1,1.1-0.2,2.4-0.3,3.9
		c0,0.7-0.1,1.5-0.1,2.4c0,0.4,0,0.9,0,1.3c0,0.8,0,1.6,0.1,2.5c0.7,0.2,1.5,0.4,2.3,0.5l0.8,0.2l0.7,0.1c1,0.2,2,0.2,3,0.3
		c1.2,0,2.3-0.1,3.5-0.4c0.7-0.2,1.4-0.5,2-0.9c0.7-0.5,1.2-1.3,1.5-2.1c0.3-0.9,0.3-2,0.1-2.9c-0.2-0.8-0.5-1.6-1-2.3
		c-0.8-1.2-1.9-2.2-3.1-3c-1.9-1.2-4-2.3-6.2-3c-3.8-1.4-6.8-2-8.2-2.4c-0.5-0.2-1.2-0.4-2.3-0.8s-3.3-1.1-4.1-1.4l0.8-0.8l0.1-0.1
		l0,0l0.1-0.2l0.5-0.6c0.3-0.5,0.7-1,1-1.5c1.5-2.2,3.2-5,4.8-8c1.3-2.3,2.4-4.7,3.3-6.9c0.9-2.1,1.6-4.2,2.2-6.3
		c1.1-3.9,1.6-6.8,2-8.3s1.5-4.3,2.8-8.1c1.5-4.5,2.6-9.2,3.1-14c0.3-3.1,0.3-6.2,0.2-9.4c-0.1-2.9-0.3-5.4-0.5-7.6
		c-0.5-4.3-1.2-6.9-1.6-6.9s-0.7,2.7-0.9,7c-0.1,2.1-0.2,4.7-0.3,7.5s-0.2,5.9-0.5,9c-0.5,5.1-1.2,9.9-1.8,13.8
		c-0.4,2.8-0.9,5.5-1.5,8.3c-0.9,2.7-2,5.3-3.2,7.8c-0.8,1.8-1.8,3.7-2.9,5.8l-3.6,6.6c-1.6,3-3.1,5.9-4.3,8.3l-0.8,1.7l-0.3,0.7
		l-0.1,0.2l-0.1,0.3l-0.2,0.5c-0.5,0.9-0.9,1.7-1.2,2.6l2.6,0.5l1.7,0.4l2.1,0.5c1.2,0.3,1.8,0.5,2.3,0.6c2.6,1,5.1,2.1,7.5,3.5
		c1.8,1,3.6,2.2,5.2,3.5c0.8,0.6,1.5,1.4,2.1,2.3c0.2,0.4,0.4,0.8,0.5,1.2c0.1,0.3,0.1,0.7,0,1c0,0.2-0.1,0.3-0.3,0.4
		c-0.2,0.2-0.5,0.3-0.8,0.4c-0.8,0.2-1.6,0.3-2.4,0.3c-0.9,0-1.7,0-2.6-0.1l-1.1-0.1c-0.1,0-0.1,0-0.2,0c0-0.1-0.1-0.1-0.1-0.2
		c0-0.4-0.1-0.8-0.1-1.2c-0.1-0.8-0.2-1.6-0.2-2.3c-0.2-1.5-0.3-2.7-0.4-3.8C460.2,575.8,460.1,574.6,459.8,574.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M478.5,533.8c0.2,0.1,0.3,0.9,0.1,2.2s-0.3,3.3-0.5,5.7c-0.3,4.8-0.4,11.5-0.6,19c-0.1,3.7-0.2,7.3-0.3,10.5
		c-0.1,2.9-0.3,5.8-0.8,8.7c0,0.3-0.1,0.7-0.2,1c-0.1,0.3-0.2,0.6-0.3,0.9c-0.2,0.5-0.4,1-0.7,1.5c-0.4,0.8-0.9,1.5-1.5,2.2
		c-1,1.1-1.8,1.5-1.9,1.3s0.2-0.8,0.8-2c0.3-0.7,0.6-1.5,0.8-2.2c0.1-0.4,0.3-0.9,0.4-1.4c0.1-0.2,0.1-0.5,0.1-0.7
		c0-0.3,0.1-0.5,0.1-0.8c0.1-2.3,0-5.3,0-8.5s-0.1-6.8,0-10.6c0-6.5,0.6-12.9,1.7-19.3c0.4-2,0.9-3.9,1.6-5.7
		C477.8,534.3,478.3,533.7,478.5,533.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M475.7,611.8c0,0.3-0.7,0.5-1.9,0.6c-1.8,0-3.6-0.3-5.2-1c-2.5-1.1-4.6-2.9-6.2-5.1c-0.9-1.3-1.7-2.6-2.3-4.1
		c-0.3-0.7-0.6-1.5-0.9-2.3c-0.3-0.8-0.5-1.7-0.7-2.5c-0.5-3-0.8-6.1-0.9-9.2c0-0.7-0.1-1.4-0.1-1.9c0-0.2-0.1-0.7-0.1-1v-1
		c0-1.3,0-2.4,0-3.5c0.1-4.2,0.5-6.8,1-6.8s1,2.6,1.6,6.7c0.1,1,0.3,2.2,0.4,3.4c0,0.3,0.1,0.6,0.1,0.9s0.1,0.5,0.2,0.9
		c0.1,0.7,0.2,1.4,0.3,2.1c0.3,2.8,0.5,5.8,1.1,8.8c0.1,0.7,0.3,1.3,0.5,2c0.2,0.7,0.5,1.4,0.8,2c0.5,1.2,1.1,2.4,1.8,3.6
		c1.2,1.9,2.7,3.5,4.6,4.7c1.3,0.8,2.8,1.4,4.2,1.9C475,611.3,475.7,611.6,475.7,611.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M487.8,597c-0.2,0.2-0.6,0.1-1.3-0.1s-1.7-0.5-2.9-0.9c-3.3-0.8-6.6-1.2-10-1.4c-4-0.1-7.6,0.1-9.8,0.9
		c-0.9,0.3-1.6,0.7-2.3,1.4c-0.5,0.5-0.8,0.8-1,0.8s-0.5-0.5-0.2-1.4c0.4-1.3,1.2-2.3,2.3-3c1.5-0.9,3.2-1.5,5-1.8
		c2-0.4,4.1-0.5,6.1-0.4c3.7,0.1,7.4,1,10.8,2.6c1,0.5,2,1.2,2.8,2.1C487.8,596.4,488,596.8,487.8,597z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M442.2,512.8c-0.5,0-1.1,2.3-1.9,6.2s-1.6,9.2-2.4,15.1c-1.2,9.6-0.8,18-1,20.7c-0.1,1.4-0.7,4.1-1.3,7.7
		c-0.7,4.3-1,8.6-0.9,13c0.1,4.4,0.7,8.7,1.6,13c0.8,3.6,1.6,6.2,2,7.6c0.2,0.7,0.3,1.7,0.6,3c0.1,0.7,0.3,1.4,0.5,2.2
		c0.1,0.4,0.3,0.8,0.5,1.2s0.5,0.9,0.7,1.3c1.3,1.8,3,3.2,4.9,4.3c2.1,1.2,4.3,2.2,6.6,3c2.8,1,5.7,1.6,8.6,1.8c2.3,0.1,4.7,0.1,7,0
		c3.9-0.2,6.4-0.7,6.4-1.2s-2.4-0.9-6.3-1.4c-1.9-0.2-4.3-0.4-6.8-0.8c-2.6-0.4-5.2-1.1-7.8-1.9c-2.1-0.7-4.2-1.5-6.2-2.4
		c-1.7-0.7-3.2-1.8-4.6-3c-0.2-0.3-0.5-0.5-0.7-0.9s-0.4-0.7-0.6-1c-0.3-0.6-0.6-1.3-0.9-1.9c-0.4-0.9-0.7-1.9-1-2.8
		c-0.4-2.5-0.7-5.1-0.7-7.6c-0.1-3.6-0.2-8-0.3-12.8s-0.2-9.2-0.3-12.8s-0.1-6.3,0-7.7c0.3-2.8,2.4-10.8,3.5-20.4
		c0.7-5.9,1.2-11.3,1.3-15.3S442.7,512.9,442.2,512.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M438.5,504.1c0.6,0.4,1.1,0.8,1.6,1.3c0.4,0.4,0.8,0.9,1.1,1.4c0.3,0.5,0.6,0.9,0.9,1.4l0.7,1.4
		c0.8,1.8,1.4,3.7,1.8,5.6c0.7,3.1,1,6.3,1.1,9.5c0,2.1-0.1,4.3-0.4,6.4c-0.2,1.5-0.5,2.3-0.7,2.3s-0.5-0.8-0.8-2.3
		s-0.5-3.6-0.9-6.2c-0.4-3-1-6-1.8-8.9c-0.5-1.7-1.1-3.3-1.8-4.8c-0.2-0.4-0.4-0.8-0.6-1.1s-0.4-0.7-0.7-1.1
		c-0.2-0.3-0.5-0.6-0.8-0.9c-0.2-0.2-0.5-0.4-0.7-0.6c0,0,0.1-0.2,0.3-0.5s0.4-0.8,0.6-1.2c0.2-0.4,0.4-0.8,0.7-1.2
		C438.2,504.2,438.4,504,438.5,504.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M414,511c0.1,0.2-0.2,0.7-1,1.5c-1.1,1.1-2.3,2-3.6,2.9c-4.3,2.8-9,4.9-13.9,6.1c-4.9,1.3-9.9,1.9-15,1.8
		c-3.9-0.1-6.3-0.8-6.2-1.3c0.1-1,9.4-1,20.3-4c4.7-1.2,9.2-2.9,13.6-4.9c1.7-0.7,3.1-1.4,4.1-1.8S413.9,510.8,414,511z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M352.5,513.8c0.4-0.4,2.5,1.4,6.1,3.7c1.9,1.3,4.1,2.2,6.4,2.6c2.5,0.3,5.3-0.4,8.4-1.5
		c0.8-0.3,1.5-0.5,2.2-0.8s1.4-0.6,2.1-0.9c1.4-0.7,2.7-1.3,4-2c2.5-1.4,4.7-2.7,6.6-3.8c3.8-2.2,6.2-3.5,6.5-3.1s-1.6,2.4-5.1,5.2
		c-2,1.6-4.2,3.1-6.4,4.5c-1.3,0.8-2.6,1.6-4.1,2.3c-0.7,0.4-1.5,0.7-2.3,1.1s-1.6,0.6-2.4,0.9c-1.6,0.6-3.3,1-5,1.4
		c-1.7,0.3-3.4,0.3-5.1,0c-2.8-0.6-5.3-1.9-7.4-3.8c-1.4-1.2-2.6-2.5-3.7-3.9C352.6,514.7,352.3,514,352.5,513.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M326.4,495.4c0.3,0,0.4,0.9,0.9,2.5c0.8,2.2,1.7,4.3,2.7,6.4c1.5,3.1,3.3,5.9,5.5,8.5c1.2,1.4,2.6,2.5,4,2.6
		s3.3-0.8,5.3-1.8l1.5-0.8c0.5-0.3,0.9-0.5,1.4-0.8c0.9-0.6,1.8-1.1,2.7-1.7l5.2-3.4c3.3-2.2,6.3-4.2,8.8-5.8
		c5.1-3.3,8.3-5.1,8.6-4.7s-2.4,3-7.1,6.8c-2.4,1.9-5.2,4.1-8.4,6.5c-1.6,1.2-3.3,2.4-5.1,3.6c-0.9,0.6-1.9,1.2-2.8,1.8
		c-0.5,0.3-1,0.6-1.6,0.9l-1.5,0.8c-1.1,0.6-2.2,1.1-3.3,1.5c-1.3,0.5-2.6,0.7-4,0.6c-1.4-0.2-2.7-0.7-3.8-1.5
		c-0.9-0.7-1.7-1.5-2.5-2.4c-2.2-2.9-4-6.2-5.3-9.7c-0.9-2.3-1.5-4.7-1.8-7.1C325.8,496.3,326.2,495.3,326.4,495.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M291.3,483.5c0.3,0,0.4,0.8,0.7,2.2c0.4,1.9,1,3.7,2,5.3c1.4,2.4,3.3,4.5,5.5,6.2c1.3,1,2.7,2,4.2,2.9
		c0.8,0.5,1.5,0.9,2.3,1.3s1.6,0.8,2.5,1.2c3.5,1.5,7,2.8,10.1,4l8.4,3.2c4.8,1.8,7.7,3.2,7.5,3.6s-3.3,0-8.3-1.2
		c-2.5-0.6-5.4-1.4-8.7-2.5c-3.5-1.1-7-2.4-10.5-3.8c-0.9-0.4-1.8-0.8-2.7-1.3s-1.8-1-2.6-1.5c-1.5-1-3-2.1-4.4-3.3
		c-2.5-2.1-4.4-4.7-5.7-7.7c-0.8-2-1.2-4.2-1-6.3C290.8,484.3,291.1,483.5,291.3,483.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M336,518.3c-0.1,0.5-3.2,0-7.9-1.2c-2.4-0.6-5.2-1.4-8.3-2.4s-6.5-2.1-10-3.5c-3.3-1.4-6.5-3.1-9.5-5
		c-2.5-1.6-4.8-3.5-6.9-5.7c-0.9-0.9-1.5-2.1-1.8-3.3c-0.3-0.9-0.3-2,0-2.9c0.5-1.5,1.4-2,1.5-1.8s-0.1,0.9-0.1,2.1
		c0,0.7,0.2,1.4,0.5,2c0.4,0.8,1,1.6,1.8,2.2c2.1,1.8,4.4,3.3,6.8,4.6c3,1.7,6,3.2,9.2,4.5c3.3,1.4,6.7,2.6,9.7,3.7s5.7,2.2,8,3.1
		C333.4,516.5,336.1,517.9,336,518.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M329.8,671.8c-0.2,0-0.9-2.1-1.6-6.1c-1-5.7-1.4-11.4-1.3-17.1c0.1-3.7,0.4-7.6,0.8-11.8s0.8-8.7,1.4-13.5
		c1.1-9.5,2.5-19.9,4.2-30.7s2-21.1,2.4-30.6c0.2-4.8,0.3-9.3,0.5-13.5s0.3-8.1,0.2-11.7c-0.1-7.1-0.6-12.9-0.9-16.9
		s-0.5-6.2-0.2-6.3s0.9,2.1,1.6,6.1c1,5.6,1.7,11.3,2.1,17c0.3,3.6,0.3,7.6,0.2,11.8s-0.1,8.7-0.2,13.5c-0.2,9.5-0.4,20-2.1,31
		c-1.8,10.8-3.2,21.1-4.5,30.5c-0.6,4.7-1.2,9.2-1.7,13.4s-1,8.1-1.2,11.6c-0.3,5.6-0.3,11.2,0.1,16.8
		C329.8,669.5,330,671.7,329.8,671.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M351.9,673.6c-0.2,0-1-2.1-1.3-6.3c-0.1-2.5,0-5,0.2-7.5c0.3-2.9,0.9-6.2,1.5-9.8c0.3-1.8,0.7-3.7,1-5.6
		s0.5-3.9,0.6-6.1c0.3-4.3,0.7-8.8,1.2-13.6s0.9-9.8,1.3-15s0.9-10.6,1.1-16.1c0.5-11-0.1-21.5-0.5-31.2c-0.2-4.8-0.3-9.4-0.4-13.7
		s-0.6-8.2-1-11.7c-1-7.2-2.2-12.9-3-16.9s-1.4-6.2-1.1-6.3s1.2,2,2.5,5.9c1.8,5.5,3.2,11.1,4.2,16.8c0.7,4,1.2,8,1.5,12
		c0.2,2.2,0.3,4.4,0.4,6.7s0.3,4.6,0.4,7c0.5,9.6,1.3,20.3,0.8,31.5c-0.3,5.6-0.7,11-1.2,16.3s-1,10.3-1.5,15s-1,9.3-1.5,13.6
		c-0.2,2.1-0.5,4.3-0.9,6.3s-0.8,3.9-1.2,5.6c-0.8,3.5-1.5,6.7-2,9.5c-0.4,2.4-0.7,4.8-0.8,7.3C351.9,671.3,352.2,673.5,351.9,673.6
		z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M389.6,665.4c-0.1,0-0.2-0.5-0.4-1.5c-0.4-1.4-0.8-2.7-1.3-4c-0.6-1.7-1.5-3.8-2.6-6.2s-2.4-5.1-3.7-8.3
		s-2.6-6.6-3.7-10.4c-1.3-4.1-2.1-8.3-2.6-12.5c-0.5-4.5-0.8-9.2-0.9-14c-0.1-2.4-0.2-4.8-0.2-7.3l-0.1-1.8c0-0.7,0-1.4,0-2
		c0-1.3,0.1-2.6,0.2-3.9c0.9-10.2,1.9-19.8,2-28.6c0-1.1,0-2.2,0-3.2s-0.1-2.1-0.1-3.1c-0.1-2.1-0.2-4.1-0.3-6
		c-0.3-3.9-0.6-7.5-1-10.8s-0.8-6.3-1.1-8.9c-0.2-1.3-0.3-2.5-0.4-3.7c-0.1-1.1,0-2.2,0.1-3.2c0.4-3.8,1.3-5.8,1.5-5.7
		s-0.2,2.1-0.1,5.8c0,1,0.1,2,0.2,3c0.2,1.1,0.4,2.3,0.7,3.6c0.5,2.6,1.1,5.6,1.6,8.9s1,7,1.4,10.9c0.2,2,0.4,4,0.5,6.1
		c0,1.1,0.1,2.2,0.2,3.2s0,2.2,0.1,3.3c0,9-0.9,18.7-1.8,28.8c-0.1,1.3-0.2,2.5-0.2,3.7c0,0.6,0,1.2,0,1.7l0.1,1.9
		c0.1,2.5,0.1,5,0.2,7.4c0.1,4.8,0.3,9.4,0.7,13.7c0.4,4.1,1.1,8.1,2.2,12.1c1,3.7,2.1,7.2,3.2,10.3s2.3,5.9,3.1,8.4
		s1.6,4.7,2.1,6.6c0.4,1.4,0.6,2.9,0.7,4.3C389.9,664.8,389.7,665.4,389.6,665.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M365.2,650.5c-0.1,0-0.3-1-0.5-2.8c0-0.9,0-2,0-3.3s0.1-2.8,0.3-4.4c0.7-6.4,2.5-15.2,5-24.6
		c0.6-2.4,1.2-4.7,1.8-6.9c0.6-2.1,1-4.2,1.1-6.3c0.1-2.1,0.3-4.1,0.6-6.1c0.3-1.9,0.6-3.6,1-5.2c1.3-6.3,2.4-10.1,2.7-10.1
		s-0.3,4-1.2,10.3c-0.2,1.6-0.5,3.3-0.7,5.2c-0.3,1.9-0.4,3.9-0.4,5.9c0,0.5,0,1.2-0.1,1.7l-0.3,1.7c-0.2,1.1-0.4,2.2-0.7,3.4
		c-0.5,2.3-1.1,4.6-1.7,7c-2.5,9.5-4.5,18-5.6,24.3c-0.3,1.6-0.4,3-0.6,4.3s-0.2,2.3-0.3,3.2C365.4,649.5,365.3,650.5,365.2,650.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M353,673.4c0,0.1-0.4,0.2-0.8,0.4c-0.3,0.1-0.5,0.3-0.6,0.6c-0.1,0.4-0.1,0.8,0,1.3c0.1,0.6,0.2,1.3,0.3,1.9
		c0.1,0.3,0.2,0.7,0.3,1c0,0.2,0.1,0.3,0.2,0.5c0,0.1,0.1,0.2,0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0,0.3,0h1.3
		c1,0,2-0.1,3-0.1c4.3-0.2,9.4-0.6,15-1.4c2.9-0.4,5.7-1,8.5-1.8c2.9-0.9,5.7-2.1,8.4-3.7c0.3-0.2,0.6-0.4,0.9-0.6l0.4-0.3l0.2-0.2
		l0,0l0,0l0,0c0,0-0.1-0.3-0.1-0.5c-0.2-0.7-0.5-1.4-0.8-2c-0.1-0.3-0.3-0.6-0.5-0.9c0,0-0.1-0.1-0.1-0.2l0,0l0,0H389l-0.4,0.2
		l-2.1,0.8c-1.4,0.6-2.8,1.1-4.1,1.6c-2.6,1-5.3,1.8-8.1,2.3c-2.6,0.4-5,0.6-7.1,0.8C358.4,674.2,353,674,353,673.4
		c0-0.5,5.3-1.1,13.8-2.4c2.1-0.3,4.5-0.7,6.9-1.2c2.5-0.6,5-1.4,7.4-2.4c1.3-0.5,2.6-1.1,4-1.7l2.1-0.9l0.3-0.1l0.4-0.1
		c0.3-0.1,0.7-0.2,1-0.3c0.3-0.1,0.7-0.1,1,0c0.2,0,0.4,0.1,0.5,0.1l0.2,0.1l0.2,0.2c0.3,0.2,0.6,0.5,0.8,0.8
		c0.1,0.1,0.2,0.3,0.4,0.5l0.2,0.3c0.2,0.4,0.4,0.8,0.6,1.2c0.4,0.8,0.7,1.6,1,2.4c0.1,0.2,0.1,0.4,0.2,0.7c0.1,0.4,0.2,0.7,0.2,1.1
		c0,0.2,0,0.4,0,0.6l-0.2,0.5c-0.2,0.4-0.4,0.7-0.6,1l-0.4,0.4l-0.3,0.2c-0.2,0.2-0.4,0.3-0.6,0.4c-0.4,0.3-0.8,0.5-1.1,0.8
		c-6,3.7-12.6,5.1-18.4,5.8c-5.1,0.6-10.2,0.8-15.4,0.7c-1.1,0-2.1-0.1-3.1-0.2l-0.7-0.1c-0.3,0-0.4,0-0.8-0.1c-0.4,0-0.7-0.1-1-0.3
		c-0.3-0.2-0.5-0.4-0.7-0.7c-0.2-0.3-0.3-0.6-0.4-0.9c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.4-0.1-0.8-0.2-1.2c-0.1-0.7-0.1-1.4,0-2.1
		c0-0.7,0.2-1.3,0.5-1.9c0.3-0.4,0.8-0.6,1.3-0.7C352.8,673.1,353,673.3,353,673.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M351.6,680.2c0,0.5-2.8,1.1-7.4,1.1c-2.7,0-5.4-0.2-8.1-0.6c-1.6-0.3-3.2-0.6-4.8-1.1
		c-0.4-0.1-0.8-0.3-1.3-0.5c-0.2-0.1-0.4-0.2-0.6-0.3l-0.3-0.2l-0.5-0.3c-0.3-0.2-0.7-0.5-0.9-0.8l-0.2-0.2l0-0.1c0,0,0,0,0,0l0,0
		l-0.1-0.1c-0.1-0.2-0.2-0.4-0.2-0.6l-0.1-0.6c0-0.1,0-0.2,0-0.3v-0.2c0-0.3,0-0.5,0-0.8c0-0.5,0.1-1,0.1-1.5l0.1-0.5l0.2-0.6
		c0.1-0.4,0.3-0.8,0.5-1.1c0.2-0.3,0.5-0.6,0.9-0.7c0.3-0.2,0.7-0.3,1.1-0.3h0.6l0.6,0.1h0.3h0.2l0.3,0.1c1.7,0.3,3.1,0.7,4.6,1
		s2.7,0.5,4,0.7l3.6,0.4c4.5,0.5,7.3,1,7.2,1.5s-2.8,0.9-7.3,1.1c-1.1,0-2.4,0-3.7,0c-1.5,0-2.9-0.2-4.4-0.4
		c-1.5-0.2-3.1-0.6-4.6-0.8H331h-0.1l0,0l0,0l0,0v0.1c0,0.3-0.1,0.7-0.1,1c0,0.2,0,0.4,0,0.5v0.1l0,0l0.1,0.1l0.2,0.1
		c0.2,0.1,0.3,0.2,0.5,0.2c0.4,0.1,0.7,0.3,1,0.4c1.4,0.5,2.8,0.9,4.3,1.2c2.8,0.6,5.4,1,7.6,1.3
		C348.8,679.3,351.6,679.6,351.6,680.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M439.5,599.3c-0.5,0.3-3.2-4.1-8.3-10.5c-1.3-1.6-2.7-3.3-4.3-5.1c-0.8-0.8-1.7-1.8-2.6-2.9s-1.7-2.1-2.5-3.3
		c-3.4-5-6.5-10.1-9.3-15.4l-4.5-8.1c-1.4-2.6-2.9-5-4.3-7.5c-2.3-4.3-4.3-8.8-6.1-13.4c-3.1-7.8-4.5-12.7-4.1-12.9
		s2.7,4.4,6.4,11.8c1.8,3.7,4,8.1,6.7,12.8c1.3,2.2,3,4.8,4.5,7.3s3,5.3,4.5,8.1c3.1,5.5,6,10.8,8.9,15.3c0.7,1.1,1.5,2.2,2.2,3.2
		s1.5,1.9,2.3,2.9c1.6,1.9,2.9,3.8,4.1,5.6C437.9,594.3,439.9,599.1,439.5,599.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M470.3,722c-0.5-0.2,2.4-6.5,4.7-17.4c0.6-2.7,1.1-5.7,1.5-8.9c0.5-3.3,0.6-6.7,0.3-10.1
		c-0.5-3.8-1.2-7.5-2.1-11.2c-0.9-3.9-2-7.9-3.2-11.9c-0.6-2-1.2-4-1.8-6s-1.4-3.8-2.2-5.6c-1.5-3.7-3.1-7.2-4.5-10.6
		c-3-6.8-5.7-12.8-7.9-18c-1-2.2-2.2-4.4-3.5-6.5c-1.2-1.9-2.1-3.5-3-4.8c-1.6-2.7-2.5-4.2-2.3-4.3s1.4,1.1,3.4,3.5
		c1,1.2,2.2,2.7,3.5,4.5c1.6,2,2.9,4.2,4.1,6.5c2.5,5,5.5,11,8.6,17.7c1.6,3.3,3.2,6.9,4.8,10.6c0.8,1.9,1.6,3.8,2.3,5.8
		s1.3,4,1.9,6.1c1.2,4.1,2.3,8.2,3.2,12.1c0.9,3.8,1.6,7.7,2,11.7c0.2,1.9,0.2,3.8,0,5.6c-0.1,1.8-0.4,3.5-0.6,5.2c-0.5,3-1.2,6-2,9
		C474.5,716.2,470.7,722.2,470.3,722z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M438.8,720.8c1.8-7.2,3.6-14.2,5.3-21c0.8-3.4,1.6-6.7,2.4-10c0.8-3.3,1.5-6.5,2-9.5c0.9-6.2,1.3-12.3,1.7-18
		s0.6-11,0.9-15.9c0.6-9.7,0.5-17.5,0.6-23s0.1-8.5,0.4-8.5s0.7,3,1.1,8.4c0.6,7.7,0.8,15.4,0.7,23.2c-0.1,4.9-0.2,10.2-0.4,16
		s-0.5,11.9-1.3,18.4c-0.5,3.3-1.2,6.5-1.9,9.8s-1.5,6.7-2.4,10.1c-1.6,6.8-3.4,13.9-5.3,21.1C442.3,722.6,438.6,721.4,438.8,720.8z
		"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M245.7,721.2c-0.2-0.1,0.7-3.1,2.6-8.4c1-2.6,2.2-5.9,3.8-9.6c0.8-1.8,1.7-3.8,2.8-5.9s2.1-4.1,3.2-6.4
		c2.2-4.5,4.6-9.5,7.2-14.8c1.3-2.7,2.6-5.4,4.1-8.2l4.6-8.6c3.1-5.9,6.5-12,9.9-18.4c1.7-3.2,3.5-6.4,5.3-9.7s3.3-6.6,4.8-10.2
		s2.9-7,4.2-10.5c0.7-1.7,1.3-3.5,2-5.2c0.6-1.7,1.4-3.5,2-5.1c2.7-6.7,5.3-13.2,7.7-19.3c0.6-1.5,1.2-3.1,1.7-4.6s0.9-3,1.4-4.5
		c0.9-3,1.7-6,2.5-8.8s1.7-5.6,2.6-8.2s1.8-5.1,2.6-7.5c3-9.5,5-17.5,6.5-22.9s2.3-8.4,2.6-8.4s-0.1,3.2-1.2,8.7s-2.6,13.5-5.4,23.3
		c-0.7,2.5-1.6,4.9-2.4,7.5s-1.6,5.3-2.4,8.2l-2.3,8.8c-0.4,1.5-0.8,3.1-1.4,4.7s-1.1,3.1-1.6,4.7c-2.3,6.3-4.9,12.8-7.5,19.5
		c-0.7,1.7-1.3,3.3-2,5.1l-2,5.2c-1.4,3.5-2.7,7-4.3,10.6c-1.4,3.6-3,7.1-4.8,10.5l-5.3,9.7L276.9,661l-4.7,8.5
		c-1.5,2.8-2.9,5.5-4.2,8.1c-2.7,5.3-5.2,10.2-7.6,14.6c-1.2,2.2-2.3,4.3-3.5,6.3s-2.1,3.8-3,5.6c-1.7,3.6-3.2,6.7-4.3,9.3
		C247.3,718.4,245.9,721.3,245.7,721.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M286.7,544.2c-0.1,0-0.3-0.1-0.4-0.5c-0.2-0.5-0.3-1.1-0.4-1.6c-0.3-2-0.4-4-0.4-6c-0.1-2.5,0-5.5,0.1-8.8V526
		c0-0.3,0-0.7-0.1-1.2c-0.1-0.8-0.2-1.7-0.2-2.6c-0.2-1.8-0.3-3.7-0.5-5.6c-0.1-1-0.1-1.9-0.1-2.9c0-0.5,0-0.9,0-1.4l0-1.3
		c-0.1-1.8-0.2-3.5-0.3-5.1s-0.1-3.2-0.2-4.7c0-0.7,0-1.5-0.1-2.1c-0.1-1.7,0.6-3.3,1.9-4.3c0.5-0.4,1-0.7,1.5-1l0.7-0.3l0.4-0.2
		c0.1-0.1,0.3-0.1,0.4-0.1c0.5-0.1,1.1-0.1,1.5,0.1c0.6,0.2,1.1,0.6,1.5,1.1c0.3,0.3,0.3,0.6,0.2,0.7s-0.3,0-0.6-0.1
		c-0.4-0.2-0.9-0.3-1.4-0.3c-0.3,0-0.5,0-0.7,0.2l-0.2,0.1l-0.2,0.2l-0.5,0.4c-0.7,0.5-1.3,1.2-1.7,2c-0.1,0.4-0.2,0.9-0.1,1.3
		c0.1,0.7,0.2,1.4,0.3,2.1c0.2,1.5,0.4,3,0.5,4.7s0.4,3.4,0.5,5.2l0.1,1.4c0,0.5,0,0.9,0,1.4c0,0.9,0.1,1.8,0.1,2.8
		c0.1,1.9,0.3,3.8,0.4,5.5c0.1,0.9,0.1,1.8,0.2,2.7c0,0.4,0,0.9,0,1.4s-0.1,0.9-0.1,1.3c-0.3,3.3-0.6,6.3-0.8,8.7s-0.5,4.5-0.7,5.9
		c-0.1,0.5-0.2,1.1-0.3,1.6C286.9,543.9,286.8,544.1,286.7,544.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M249.3,662.3c-0.5,0-0.6-9.2-0.6-24c0-7.4,0.1-16.2,0.1-26c0-10.7,0.6-21.4,1.8-32l0.1-1
		c0.1-0.4,0.1-0.8,0.2-1.2c0.2-0.8,0.4-1.5,0.6-2.2c0.5-1.4,1-2.7,1.5-4c1-2.6,2.2-5.1,3.3-7.5c2.2-4.8,4.5-9.5,6.5-13.9
		c4-8.9,7.3-17,10.2-23.9l2-4.9c0.7-1.5,1.5-2.9,2.3-4.3c1.6-2.5,3.1-4.6,4.3-6.3c2.5-3.3,4-5.1,4.2-4.9s-0.9,2.1-3,5.7
		c-1,1.8-2.3,4-3.7,6.5c-0.7,1.3-1.4,2.7-1.9,4.1l-1.8,5c-2.6,7-5.7,15.2-9.5,24.3c-1.9,4.6-4.1,9.2-6.3,14
		c-1.1,2.4-2.2,4.9-3.2,7.4c-0.5,1.3-1,2.5-1.4,3.8c-0.2,0.6-0.4,1.3-0.5,1.8c-0.1,0.3-0.1,0.6-0.1,0.8l-0.1,1.1
		c-1.3,10.5-1.9,21-2.1,31.6c-0.2,9.8-0.5,18.6-0.9,26C250.6,653.2,249.8,662.3,249.3,662.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M220.3,720.3c3.4-7.2,6.4-14.6,9-22.1c2.6-7.3,4.8-14.4,6.7-21.3c3.3-12.2,6.1-24.5,8.1-36.9
		c1.9-10.7,3.3-19.3,4.4-25.2s1.7-9.3,2-9.2s0.1,3.4-0.5,9.4s-1.6,14.7-3.2,25.5c-1.7,12.6-4.2,25.1-7.4,37.5
		c-1.9,7-4,14.2-6.6,21.6c-2.6,7.7-5.6,15.2-9.1,22.5C223.3,723,220,720.9,220.3,720.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M201.8,722.7c-0.2,0.1-0.7-0.4-1.3-1.5c-0.8-1.6-1.5-3.2-1.9-4.9c-0.6-2.1-1.2-4.7-1.7-7.5s-1-5.9-1.6-9.1
		s-1.4-6.1-2.7-8.1c-1.3-1.9-3-3.5-5-4.7c-3.5-2.2-6.1-3-5.9-3.6c0-0.3,0.8-0.4,2-0.2c1.8,0.3,3.5,0.8,5.1,1.5
		c2.6,1.1,4.8,2.9,6.5,5.1c1,1.4,1.7,2.9,2.2,4.5c0.5,1.6,0.9,3.2,1.3,4.9c0.6,3.3,1,6.5,1.4,9.3s0.6,5.4,0.9,7.5s0.6,3.8,0.8,5
		S202.1,722.6,201.8,722.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M237,474.8c-0.5-0.1-1.8,5.9-3.6,15.5s-3.9,22.9-6,37.7c-0.4,3-0.8,5.9-1.2,8.8c-0.2,1.4-0.3,2.9-0.5,4.3
		s-0.3,2.7-0.4,4.1c-0.7,4.9-1.7,9.7-2.9,14.5c-1.6,6.2-3.5,12.3-5.7,18.4c-2.5,6.4-10.9,25.1-19.6,47.7c-1.1,2.9-2.1,5.5-3.3,8.1
		s-2.3,5.1-3.5,7.6c-2.3,4.9-4.6,9.3-6.7,13.3s-4,7.4-5.6,10.2s-3.1,4.9-4.1,6.3s-2.5,3.5-4.4,6.2c-0.9,1.3-2,2.8-3.1,4.4
		c-0.6,0.8-1.1,1.7-1.7,2.6c-0.3,0.5-0.6,0.9-0.9,1.5l-0.2,0.4c-0.1,0.2-0.2,0.3-0.2,0.5c-0.1,0.2-0.1,0.4-0.1,0.7
		c0,0.2,0,0.4,0,0.6c0.2,1.3,0.7,2.5,1.4,3.7c0.6,1.1,1.3,2.1,2.1,3.2s1.5,2,2.4,3.1c0.9,1.1,1.9,2.1,3,3c2.2,1.9,4.4,3.7,6.8,5.3
		l1.2,0.8l1.8,1.2l3.6,2.3l0.9,0.6l0.2,0.2l0.1,0.1c0,0,0-0.1,0-0.1l-0.1-0.5l-0.4-2c-0.3-1.3-0.5-2.6-0.8-4
		c-0.2-0.8-0.3-1.6-0.5-2.4l-0.2-1.4l-0.4-2.7c-0.1-0.9-0.2-1.7-0.3-2.6l-0.2-2.6c-0.1-1.7-0.1-3.4-0.2-5.1s0-3.3,0-4.9
		c0.1-5.7,0.6-11.3,1.4-16.9c1.3-9.6,2.8-15.5,2.3-15.6s-2.8,5.5-4.8,15.2c-1.2,5.7-1.9,11.4-2.2,17.2c0,1.6-0.1,3.3-0.1,5
		s0,3.5,0.2,5.3c0.1,0.9,0.1,1.8,0.2,2.7c0.1,0.9,0.1,1.8,0.3,2.8l0.4,2.8l0.2,1.3l0.1,0.2l0.1,0.6l0,0l-0.2-0.1l-0.4-0.3
		c-2.4-1.6-4.6-3.2-6.6-4.8c-1-0.8-1.9-1.6-2.8-2.5c-0.8-0.9-1.7-1.9-2.4-2.8s-1.5-1.9-2.1-2.8c-0.6-0.8-1-1.7-1.3-2.7
		c0-0.1,0-0.2,0-0.2c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0.1-0.3l0.1-0.3c0.2-0.5,0.4-0.9,0.7-1.4c0.5-0.9,1-1.8,1.5-2.6
		c1-1.7,1.9-3.2,2.8-4.6c1.7-2.8,3.1-4.9,4.1-6.3s2.5-3.5,4.4-6.1s4.1-6,6.4-9.9s5-8.2,7.5-13c1.3-2.4,2.6-4.9,3.8-7.6
		s2.4-5.5,3.4-8.3c8.6-22.6,14.7-42.2,17.2-48.6c1.3-3.2,4-9.5,6.9-18.1c1.6-4.8,2.9-9.7,3.9-14.7c0.8-5.4,1.6-11.1,2.5-17.1
		c2.1-14.8,3.9-28.1,5-37.8S237.5,474.9,237,474.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M191,258.5c0.1-0.1,0.5-0.1,1.1,0.3c0.2,0.1,0.3,0.2,0.5,0.4c0.2,0.2,0.4,0.4,0.5,0.6c0.4,0.7,0.6,1.5,0.3,2.2
		c-0.2,0.4-0.4,0.8-0.8,1.1c-0.4,0.4-0.9,0.6-1.4,0.7c-0.3,0-0.6,0-0.9-0.1c-0.3-0.1-0.5-0.2-0.7-0.3c-0.4-0.2-0.7-0.4-0.9-0.5
		c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0.1-0.4,0.2-0.6,0.3c-0.8,0.6-1.8,1-2.7,1.2c-0.5,0.1-1.1,0.2-1.6,0.1c-0.6,0-1.1-0.1-1.7-0.3
		c-0.7-0.3-1.3-0.6-1.8-1.1l0,0l0,0l-0.2,0.1c-0.5,0.3-1.1,0.4-1.7,0.3c-1.4-0.2-2.5-1.2-2.7-2.6c-0.1-0.3-0.1-0.7,0-1
		c0.1-0.5,0.3-0.9,0.6-1.3c0.4-0.6,0.8-0.8,1-0.7s0.1,0.5,0,1.1c0,0.3,0,0.7,0,1c0,0.3,0.2,0.6,0.4,0.9c0.2,0.2,0.5,0.4,0.9,0.4
		c0.1,0,0.3-0.1,0.4-0.2l0.3-0.2c0.1-0.1,0.3-0.2,0.4-0.3c0.3-0.1,0.7-0.2,1-0.1c0.4,0.1,0.7,0.2,1,0.4l0.6,0.4
		c0.2,0.2,0.5,0.3,0.8,0.4c0.3,0.1,0.6,0.1,0.9,0.1c0.3,0,0.7,0,1-0.1c0.7-0.2,1.4-0.5,2-0.9c0.5-0.3,1.1-0.5,1.7-0.6
		c0.6,0,1.3,0.1,1.8,0.4c0.3,0.2,0.7,0.4,1,0.7c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0.2,0,0.4-0.1c0.1-0.1,0.2-0.2,0.3-0.3
		c0.2-0.2,0.2-0.5,0.1-0.8c-0.1-0.3-0.2-0.6-0.4-0.9C191.1,259,190.9,258.7,191,258.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M196.2,269.9c0.1,0.1-0.1,0.5-0.8,0.9c-0.4,0.2-0.9,0.4-1.4,0.4c-0.2,0-0.3,0-0.5,0c-0.2,0-0.4-0.1-0.5-0.1
		c-0.3-0.1-0.6-0.3-0.9-0.5c-1.2-0.7-2.3-1.8-3.6-2.6c-0.2-0.2-0.5-0.2-0.8-0.3c-0.4,0-0.8,0.1-1.1,0.2c-0.9,0.4-2,0.7-3,0.8
		c-0.6,0-1.2-0.1-1.7-0.3c-0.5-0.2-0.9-0.4-1.3-0.5c-0.3-0.1-0.7-0.2-1-0.1c-0.3,0.1-0.6,0.2-0.8,0.4c-1.2,1-2.5,1.8-3.8,2.6
		c-0.6,0.3-1.3,0.4-2,0.3c-0.5-0.1-1-0.3-1.3-0.7c-0.6-0.5-0.7-1-0.6-1s0.4,0.2,1,0.5c0.8,0.4,1.7,0.3,2.4-0.1
		c0.5-0.4,1.1-0.8,1.5-1.2c0.6-0.6,1.2-1.1,1.9-1.6c0.5-0.3,1-0.6,1.6-0.7c0.6-0.1,1.2,0,1.7,0.2c0.8,0.4,1.6,0.6,2.4,0.7
		c0.9-0.1,1.7-0.3,2.5-0.7c0.5-0.2,1-0.3,1.6-0.3c0.6,0,1.2,0.2,1.7,0.5c0.7,0.5,1.4,1,2,1.6c0.6,0.5,1.1,1,1.5,1.4
		c0.2,0.2,0.4,0.3,0.7,0.4c0.1,0,0.2,0.1,0.3,0.1l0.3,0.1c0.4,0.1,0.8,0.1,1.1,0C195.7,270,196.1,269.8,196.2,269.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M194.9,270.8c0.2,0.1,0.2,0.6,0,1.4c-0.1,0.5-0.3,1-0.4,1.4l-0.3,0.9l-0.1,0.4l-0.2,0.5
		c-0.3,0.8-0.8,1.6-1.4,2.2c-0.7,0.8-1.5,1.4-2.4,1.9c-2,1-4.2,1.6-6.4,1.6c-1.2,0.1-2.3,0-3.5-0.2c-0.6-0.1-1.1-0.3-1.7-0.5
		c-0.5-0.2-1-0.4-1.5-0.7c-1.7-1-3-2.4-3.8-4.2c-0.5-1.1-0.8-2.3-0.8-3.5c0-0.9,0.2-1.3,0.5-1.3c0.5-0.1,1,1.6,2.5,3.6
		c0.8,1.1,1.9,2,3.1,2.5c0.3,0.2,0.7,0.3,1.1,0.4c0.4,0.1,0.8,0.2,1.2,0.3c0.9,0.1,1.8,0.1,2.7,0.1c1.8,0,3.5-0.4,5.1-1
		c1.2-0.5,2.3-1.4,3.1-2.5l0.6-0.9l0.5-0.7c0.3-0.3,0.6-0.7,0.9-1C194.3,270.9,194.7,270.6,194.9,270.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M193.9,270.8c0,0.1-0.2,0.3-0.7,0.6s-1.1,0.8-1.8,1.4c-0.9,0.8-2,1.5-3.1,2c-2.9,1.2-6.1,1.3-9.1,0.2
		c-1.2-0.5-2.3-1.2-3.2-2.1c-0.5-0.6-1-1.1-1.6-1.5c-0.4-0.3-0.7-0.5-0.6-0.7s0.4-0.2,1-0.1c0.9,0.2,1.6,0.6,2.3,1.1
		c2,1.4,4.3,2,6.7,1.9c1.3,0,2.6-0.2,3.9-0.7c1-0.4,2-0.9,3-1.4c0.7-0.4,1.5-0.7,2.3-0.9C193.5,270.6,193.9,270.7,193.9,270.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M192.8,270.6c0,0.1-0.2,0.4-0.8,0.5c-0.8,0.1-1.6,0.1-2.3-0.2c-0.4-0.2-0.8-0.3-1.3-0.4c-0.2,0-0.2,0-0.4,0.1
		c-0.2,0.1-0.4,0.2-0.6,0.3c-0.5,0.2-1,0.5-1.6,0.8c-0.7,0.4-1.5,0.6-2.3,0.7c-0.9,0-1.7-0.2-2.4-0.7c-0.6-0.4-1.1-0.8-1.5-0.9
		s-0.4-0.2-0.9,0c-0.5,0.2-1,0.4-1.6,0.4c-0.8,0.1-1.5,0-2.3-0.3c-0.5-0.2-0.8-0.3-0.7-0.5s0.3-0.2,0.8-0.3c0.7-0.1,1.3-0.2,1.9-0.4
		c0.4-0.2,0.8-0.3,1.2-0.6c0.3-0.2,0.7-0.3,1.1-0.3c0.4,0,0.9,0,1.3,0.2c0.7,0.3,1.2,0.7,1.7,0.9c0.4,0.2,0.9,0.4,1.3,0.4
		c0.5-0.1,1-0.2,1.5-0.5c0.6-0.2,1.1-0.5,1.7-0.7c0.3-0.1,0.7-0.2,1-0.3c0.4-0.1,0.8-0.1,1.2,0c0.5,0.2,1,0.4,1.4,0.7
		c0.5,0.3,1.1,0.6,1.7,0.8C192.5,270.4,192.8,270.4,192.8,270.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M210.3,233.5c-1.1,0-1.9,10.5-3.1,23.2c-0.3,2.6-0.5,5.1-0.8,7.4c-0.2,2.1-0.5,4.1-1,6.2
		c-0.1,0.2-0.1,0.4-0.2,0.6s0,0.2-0.1,0.3l-0.1,0.1l-0.3,0.2l-0.5,0.4c-0.7,0.6-1.3,1.1-1.8,1.6c-1.1,1-1.9,1.8-2.4,2.3
		c-0.8,0.7-2.3,1.8-4.4,3.5c-1,0.9-2.2,1.9-3.5,3.1c-0.6,0.6-1.3,1.2-2,1.9l-1.1,1.1l-0.6,0.6c0,0-0.1,0.1-0.1,0.1h-0.1h-0.1l0,0
		c0,0,0,0-0.1,0h-1.3c-1.1,0-2.4,0-3.9-0.1s-2.5-0.2-3.8-0.3l-1.8-0.2l-0.4,0h-0.1c0,0-0.1,0-0.1,0l0,0c0,0-0.1-0.1-0.1-0.1
		l-0.3-0.4c-0.2-0.2-0.4-0.5-0.6-0.7l-1.1-1.3c-0.8-0.8-1.5-1.6-2.2-2.3c-2.9-2.8-5.3-4.6-6.4-5.6c-0.4-0.4-0.9-0.9-1.7-1.7
		l-1.6-1.5l-1.1-1l-1.1-1v-0.2c0-0.1,0-0.2,0-0.4c-0.1-0.3-0.1-0.7-0.2-1.1c-0.2-0.9-0.4-2-0.6-3.2c-0.4-2.5-0.9-5.6-1.4-9
		c-0.9-6.3-1.5-12.1-2.1-16.3s-1-6.8-1.5-6.8s-0.9,2.6-1,6.9c-0.1,5.6,0.2,11.2,1.1,16.7c0.5,3.1,1.2,6.2,2.1,9.2
		c0.4,1.1,0.8,2.2,1.3,3.2c0.2,0.4,0.4,0.8,0.5,1.1l0.5,0.9l2.7,2.1l1.7,1.4c0.9,0.7,1.5,1.2,1.8,1.5c1.9,2,3.7,4.1,5.3,6.4
		c0.6,0.8,1.2,1.6,1.8,2.5l0.9,1.4c0.1,0.2,0.3,0.5,0.4,0.7l0.2,0.4l0.4,0.6l0.5,0.7c0,0.2,0.2,0.2,0.3,0.2l0.4,0.1l1.3,0.2
		c0.6,0.1,1.2,0.2,1.9,0.3c1.3,0.2,2.6,0.3,3.9,0.4c1.4,0.1,2.8,0.1,4.2,0c0.6,0,1.1-0.1,1.5-0.2l0.9-0.1l0.5-0.1l4.1-5
		c1.1-1.3,2.1-2.5,3-3.5c1.2-1.4,2.5-2.7,3.8-4c0.8-0.7,1.7-1.3,2.6-1.9c0.6-0.4,1.3-0.8,2-1.2l0.6-0.3l0.3-0.1
		c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.5,0.4-0.7c0.9-2.1,1.5-4.2,2-6.5
		c0.5-2.5,0.9-5,1.2-7.5C212,244.1,211.3,233.5,210.3,233.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M241.8,297.6c0,0.2-0.6,0.6-1.8,1c-1.8,0.5-3.6,0.7-5.5,0.5c-2.8-0.2-5.5-0.8-8-1.9c-3.1-1.3-5.9-3.1-8.5-5.2
		c-2.6-2.1-4.8-4.6-6.6-7.4c-1.5-2.3-2.7-4.7-3.6-7.2c-1.5-4.3-1.5-7.2-1.1-7.3s1.4,2.5,3.4,6.3c2.5,4.9,5.9,9.3,10.1,12.9
		c5,4.1,10.4,6.6,14.5,7.4S241.6,297.1,241.8,297.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M155.2,316.4c0.1,0.3,0.9,0.2,2.3-0.1c0.7-0.1,1.5-0.4,2.5-0.6l1.5-0.4l0.4-0.1l0.3-0.1c0.1,0,0.2,0,0.3,0
		c0.1,0,0.1,0,0.1,0v0.3l0.1,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0.2,0.4,0.5,0.8,0.8,1.2c0.1,0.2,0.2,0.4,0.3,0.6l0,0c0,0,0,0-0.1,0.1
		s-0.3,0.5-0.6,1.1c-0.2,0.3-0.3,0.7-0.4,1c0,0.2-0.1,0.5-0.1,0.7c0,0.2,0,0.4,0,0.7c0.1,0.6,0.3,1.3,0.6,1.9c0.2,0.5,0.5,1,0.8,1.4
		c0.7,1,1.4,2,2,2.8c0.4,0.6,0.6,1.3,0.6,2.1c0,0.9,0,1.8-0.1,2.6c-0.1,0.4-0.1,0.8-0.3,1.2c-0.1,0.6-0.2,1.1-0.1,1.7
		c0.1,1.6,0.6,3.2,1.3,4.7c0.5,0.9,1,1.8,1.7,2.5c0.7,0.8,1.5,1.5,2.4,2c0.9,0.4,1.8,0.6,2.8,0.6c1.6-0.1,3-1,3.7-2.5l0.9-1.9
		l-2.7,0.7c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.7-0.1-0.9-0.4c-0.1-0.2-0.2-0.3-0.3-0.5c0-0.2,0-0.4,0.1-0.5c0.5-0.9,1-1.8,1.7-2.6
		l0.6-0.9c0.2-0.3,0.4-0.7,0.6-1c0.4-0.7,0.7-1.5,0.8-2.3c0.1-0.4,0.1-0.9,0.1-1.3c0-0.6-0.1-1.1-0.4-1.6c-0.1-0.2-0.2-0.3-0.3-0.5
		l-0.1-0.1l0,0l0,0l0,0l-0.5,0.5l0,0l0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.2l0.1-0.1l0,0c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.5,0.1-1.1,0.4-1.5
		c0.1-0.1,0.2-0.2,0.2-0.3s0.1-0.1,0.1-0.2s-0.1-0.1-0.1-0.3c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1v-0.1l0,0c0.1-0.6-0.1,0.8-0.1,0.6l0,0
		l-0.3,1.2c-0.2,0.7-0.4,1.3-0.5,2c0.3-0.3,0.7-0.6,1.1-0.9c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.3l0,0
		l0.1-0.1l0.2-0.2l0.3-0.4c0.7-0.7,1.2-1.5,1.7-2.4c0.5-1,0.6-2.1,0.3-3.2c-0.3-1-0.7-2-1.4-2.8c-0.4-0.5-0.8-1.1-1.3-1.7
		c-0.2-0.3-0.4-0.5-0.5-0.8c-0.1-0.2-0.1-0.4,0-0.5c0.2-1,0.4-1.9,0.4-2.9c0-0.6,0-1.2-0.2-1.8c-0.1-0.3-0.2-0.6-0.3-0.9l-0.4-0.8
		c-0.2-0.4-0.3-0.7-0.4-1.1c0-0.1,0-0.2,0-0.2l0,0l0,0c0,0,0,0.1,0,0l0.1-0.2l0.3-0.4l0.5-0.7c0.3-0.5,0.7-1.1,0.9-1.6
		c1.3-2.6,2-5.5,2.1-8.4c0-2.3-0.3-4.6-1-6.9c-1.2-3.8-2.7-5.7-3.2-5.5s0.2,2.4,0.7,6s0.5,8.5-1.9,13.1c-0.3,0.5-0.4,0.9-0.7,1.4
		l-1,1.5c-0.1,0.2-0.3,0.5-0.4,0.7c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.3,0,0.5,0.1,0.8c0,0.2,0.1,0.4,0.1,0.6c0.2,0.6,0.5,1.2,0.8,1.8
		l0.4,0.6c0.1,0.1,0.2,0.3,0.2,0.5c0.1,0.3,0.2,0.7,0.3,1c0.1,0.9,0.1,1.7,0.1,2.6c0,0.3,0,0.7,0.1,1c0.1,0.3,0.2,0.6,0.4,0.9
		c0.3,0.4,0.6,0.7,0.9,1c0.6,0.5,1.1,1,1.5,1.4c0.7,0.6,1.2,1.3,1.4,2.2c0.1,0.6,0,1.1-0.3,1.6c-0.2,0.3-0.5,0.6-0.8,0.9l-0.1,0.1
		h-0.1l0,0v-0.1c0-0.2,0-0.3,0-0.5c0-0.3,0-0.6-0.2-0.9c0,0-0.1-0.1-0.2-0.1l-0.1-0.1c-0.1,0-0.2,0-0.2,0c-0.2,0-0.3,0-0.5,0
		l-0.4,0.1c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.3,0.2-0.5,0.4c-1,0.8-1.6,2-1.7,3.3c0,0.5,0,0.9,0.2,1.4c0.1,0.3,0.2,0.6,0.4,0.8
		l0.2,0.2l0,0l0.6-0.6l0,0l0,0c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.3,0.1-0.4,0.1h-0.1l0,0v0.2c0,0.2-0.1,0.4-0.1,0.5
		c-0.1,0.5-0.3,0.9-0.6,1.3c-0.1,0.2-0.3,0.5-0.4,0.7l-0.6,0.8c-0.9,1.1-1.6,2.3-2.1,3.6c-0.3,0.9-0.3,1.9,0.1,2.7
		c0.3,0.8,0.8,1.4,1.5,1.9c0.6,0.4,1.3,0.7,2,0.7c0.6,0,1.2-0.1,1.7-0.3c0.6-0.3,1.1-0.7,1.5-1.2l-1.8-1.2c-0.1,0.2-0.2,0.4-0.3,0.5
		c-0.1,0.2-0.3,0.3-0.4,0.5c-0.3,0.2-0.7,0.4-1.1,0.5c-0.7,0.1-1.5,0-2.1-0.2c-0.8-0.2-1.6-0.7-2.1-1.4c-0.6-0.7-1-1.5-1.2-2.4
		c-0.4-1.3-0.5-2.7-0.4-4.1c0-0.3,0.1-0.6,0.2-0.9c0.2-0.5,0.4-1,0.5-1.4c0.3-1.1,0.5-2.2,0.6-3.3c0-0.6,0-1.3-0.1-1.9
		c-0.1-0.8-0.4-1.5-0.8-2.2c-0.6-1-1.3-1.8-1.9-2.7c-0.2-0.3-0.4-0.7-0.6-1.1c-0.1-0.3-0.2-0.5-0.3-0.8c0-0.1,0,0,0,0
		c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0.1-0.2c0.1-0.3,0.4-0.6,0.6-1.3c0.3-0.8,0.4-1.6,0.1-2.4c-0.2-0.6-0.6-1.2-1-1.6
		c-0.2-0.3-0.4-0.5-0.6-0.8l-0.3-0.4l-0.1-0.1l-0.1-0.3c-0.1-0.2-0.1-0.3-0.2-0.5c-0.5-0.3-0.9-0.6-1.1-0.8
		c-0.4-0.2-0.8-0.4-1.1-0.5l-1.4,0.6l-0.5,0.2l-0.4,0.2l-1.4,0.7c-0.9,0.5-1.6,0.9-2.3,1.3C155.7,315.5,155.1,316.1,155.2,316.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M178.6,297.1c3.8,5,9.7,7.9,16,7.8c10.9,0,21.1-6.1,21.1-19.4c2.4,2.4,4.9,4.7,7.5,6.9c0,0,0.1,12.1-10.8,17
		s-25.1,7.5-34.9-5C177.6,304.4,178.9,299.1,178.6,297.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M178.6,297.1c-0.3,0-0.4,0.7-0.8,1.9l-0.6,2.2l-0.4,1.4l-0.2,0.8l-0.4,1.4l1.4,1.7c0.4,0.4,0.7,0.9,1.1,1.3
		c0.8,0.9,1.8,1.8,2.8,2.6c0.5,0.4,1.1,0.8,1.6,1.2l1.8,1.1c0.6,0.3,1.3,0.6,2,0.9c0.7,0.3,1.4,0.5,2.1,0.7c2.4,0.7,4.9,0.9,7.5,0.7
		c2.1-0.2,4.2-0.5,6.3-1.2c2.5-0.8,5-1.8,7.5-2.9l2.9-1c1.6-0.6,3.1-1.4,4.4-2.4c1.8-1.3,3.3-2.9,4.5-4.8c1.3-2.2,2.3-4.6,2.7-7.1
		c0.1-0.7,0.2-1.3,0.3-2V293c0-0.3,0-0.6,0-0.9c0.2-0.7-0.5-0.9-0.9-1.3l-0.9-0.7l-0.6-0.5l-1.3-1l-1.2-1l-0.3-0.3l-0.4-0.3l-1-0.9
		l-3.5-3.2c0,0-0.1-0.1-0.1-0.1v1.1v1.1v2.1v1l-0.1,0.8l-0.1,1.1c-0.1,1-0.3,1.9-0.7,2.9c-1,2.3-2.6,4.3-4.7,5.7
		c-3.3,2.4-7.2,3.8-11.3,4.2c-2.6,0.4-5.3,0.3-7.9-0.2c-2.1-0.4-4.1-1.2-6-2.2C180.7,298.7,179,296.9,178.6,297.1
		c-0.3,0.4,0.7,2.9,4,5.5c2,1.6,4.3,2.7,6.7,3.3c3,0.8,6.1,0.9,9.1,0.5c4.7-0.5,9.1-2.7,12.4-6.1c1.9-2,3.3-4.4,4.1-7.1
		c0.3-0.7,0.6-1.7,1.1-3l0.3-1c0.1-0.2,0.1-0.4,0.2-0.5s0.1-0.6,0.3-0.7c0.3,0.4,0.5,0.7,0.6,1l0.1,0.2c0,0,0.1,0.1,0.2,0.2l0.3,0.3
		l1.1,1.2c0.4,0.4,0.8,0.8,1.2,1.2l0.7,0.6l0.4,0.3l0.1,0.1l0,0l0,0l0,0v0.2c-0.1,0.5-0.1,1.1-0.2,1.6c-0.6,4-2.5,7.7-5.3,10.7
		c-1.1,1.1-2.2,2-3.5,2.8c-0.9,0.5-1.8,1-2.7,1.4c-2.4,0.8-5,1.4-7.5,1.6c-4.1,0.5-8.3,0.3-12.3-0.8c-0.6-0.2-1.3-0.3-1.8-0.6
		l-1.7-0.7l-1.6-0.9c-0.5-0.3-1-0.6-1.5-1c-0.9-0.6-1.8-1.3-2.6-2c-0.4-0.3-0.7-0.7-1.1-1.1l-0.3-0.3l-0.1-0.1h-0.1l-0.2-0.1v-0.2
		l0.1-0.8c0.1-0.5,0.1-1,0.1-1.5c0-0.8,0-1.6,0-2.4C179.2,297.8,178.9,297.1,178.6,297.1z"
          />
          <circle class="st1" cx="203.6" cy="314.1" r="5.2" />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M208.8,314.1c-0.3,0-0.5-0.5-0.8-1.1c-0.5-0.8-1.2-1.5-2-2c-1.1-0.6-2.4-0.7-3.6-0.2c-1.8,0.8-2.7,2.9-1.9,4.7
		c0.4,0.9,1,1.5,1.9,1.9c1.2,0.5,2.5,0.4,3.6-0.2c0.8-0.5,1.5-1.2,2-2C208.3,314.5,208.5,314.1,208.8,314.1c0.3,0,0.5,0.5,0.5,1.5
		c-0.1,1.4-0.7,2.8-1.8,3.7c-1.7,1.5-4.1,2-6.2,1.3c-1.3-0.4-2.4-1.2-3.3-2.3c-1.9-2.4-1.9-5.8,0-8.2c0.8-1.1,2-1.9,3.3-2.3
		c2.2-0.7,4.5-0.2,6.2,1.3c1.1,0.9,1.7,2.3,1.8,3.7C209.3,313.6,209,314.1,208.8,314.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M134.4,364.6c-0.5,0-1.1-2.7-1.7-7.2c-0.3-2.2-0.7-4.9-0.9-7.9c-0.4-3.3-0.3-6.7,0.1-10c0.5-3.4,1.5-6.6,3-9.7
		c1.2-2.5,2.8-4.8,4.8-6.8c3.4-3.3,6.4-4.2,6.6-3.8s-2,2-4.7,5.5c-1.5,2-2.8,4.2-3.7,6.5c-1.2,2.8-2,5.8-2.5,8.8
		c-0.4,3.1-0.5,6.2-0.3,9.4c0.1,3,0.1,5.6,0.1,7.9C135.2,361.8,134.9,364.6,134.4,364.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M167.5,334.9c-0.2,0.4-3-0.7-7-3.2c-2-1.2-4.4-2.8-6.9-4.6c-1.3-0.9-2.6-1.9-3.9-3l-2-1.6l-1.5-1.3l-2-1.7
		l0.4-0.5c0.5-0.6,1-1.1,1.5-1.6c0.3-0.3,0.6-0.5,0.9-0.7l0.4-0.3l0.3-0.2c0.8-0.5,1.7-0.9,2.6-1.1c0.9-0.3,1.8-0.4,2.8-0.4
		c0.4,0,0.9,0,1.3,0.1c0.2,0,0.4,0.1,0.7,0.1l1,0.2l1.5,1.8l1.3,1.3c0.8,0.8,1.5,1.6,2.2,2.3c1.4,1.5,2.7,2.7,3.8,3.7
		c2.2,2,3.6,3.1,3.4,3.4s-2-0.1-4.6-1.8c-1.6-1-3.1-2.1-4.5-3.2c-0.8-0.7-1.6-1.4-2.5-2.2l-1.3-1.2l-0.7-0.7l-0.2-0.2l-0.1-0.1l0,0
		l0,0l0,0c-0.1,0-0.3-0.1-0.4-0.1c-0.3,0-0.6,0-0.9,0c-0.6,0-1.2,0.1-1.7,0.3c-0.6,0.2-1.2,0.5-1.7,0.8l-0.2,0.1l0,0l0,0l0,0l0,0
		l0.5,0.4l1.9,1.7l3.7,3.1l6.3,5.2C165.6,332.6,167.8,334.5,167.5,334.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M169,356.8c-0.1,0-0.3-0.5-0.4-1.4c-0.1-0.5-0.1-1-0.2-1.6s-0.3-1.3-0.4-2.1l-0.8-2.5
		c-0.3-0.9-0.7-1.8-1.1-2.8s-0.9-2-1.4-3s-1-2.1-1.7-3.2c-2.1-3.7-4.4-7.3-6.8-10.7c-1.9-2.7-3.1-4.4-2.9-4.5s1.8,1.1,4,3.6
		c2.9,3.3,5.4,6.8,7.5,10.6c0.6,1.1,1.2,2.3,1.7,3.3s0.9,2.2,1.3,3.2s0.7,2.1,0.9,3s0.4,1.9,0.5,2.7s0.1,1.6,0.2,2.3
		c0,0.6,0,1.2-0.1,1.7C169.3,356.3,169.1,356.8,169,356.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M163.5,366.5c-1-0.2-0.5-5.8,1.1-12.5l0.3-1.2l0.1-0.3l0.1-0.2c0-0.2,0.1-0.3,0.1-0.5c0.1-0.7,0.1-1.3,0.1-2
		c0-1.4-0.1-2.6-0.2-3.7c-0.1-2.2-0.2-3.6,0.3-3.8s1.4,1,2.2,3.2c0.5,1.3,0.8,2.7,1,4c0.1,0.9,0.1,1.8,0.1,2.6c0,0.5-0.1,1-0.2,1.4
		l-0.3,1.2C166.5,361.5,164.5,366.7,163.5,366.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M160.1,373.9c0.4-0.2,0.1-1.9-1.6-3.8c-2.3-2.4-5.4-3.9-8.7-4.4c-2.7-0.4-5.5-0.2-8.1,0.7
		c-2.2,1-4.3,2.2-6.4,3.6c-0.3,0.2-0.5,0.4-0.8,0.6c-0.3,0.3-0.5,0.5-0.8,0.8c-0.2,0.2-0.4,0.3-0.5,0.5l-0.1-0.4
		c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2l0,0c0,0,0,0,0-0.1c0-0.2-0.1-0.4-0.1-0.6c0.1-0.9,0.4-1.8,1-2.6c0.6-0.9,1.3-1.6,2.1-2.3
		c1.1-1,2.4-1.7,3.7-2.2c1.5-0.2,3-0.2,4.4-0.1c2.1,0.2,4.6,0.6,7.1,1.1c1.5,0.3,3,0.7,4.4,1.2c1.2,0.4,2.3,0.9,3.5,1.5
		c2,1,3.1,1.7,3.6,1.4s-0.3-1.8-2.1-3.5c-1.1-1-2.3-1.8-3.6-2.4c-1.6-0.8-3.3-1.3-5-1.7c-2.6-0.5-5.4-0.4-8,0.1
		c-2.2,0.5-3.8,1.1-4.6,1.3c-1.6,0.2-3.2,0.7-4.7,1.2c-1.2,0.5-2.4,1.3-3.3,2.2c-0.6,0.6-1.1,1.3-1.4,2c-0.5,0.9-0.7,1.8-0.7,2.8
		c0,0.4,0.1,0.8,0.2,1.2c0.1,0.3,0.1,0.5,0.3,0.8c0.1,0.3,0.2,0.5,0.3,0.8c0.4,0.8,0.9,1.6,1.6,2.2c0.4,0.4,0.8,0.8,1.3,1.1
		c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0,0.1-0.1,0.1-0.1c0.3-0.6,0.6-1.3,0.9-2c0.2-0.7,0.5-1.4,0.9-2c0.2-0.4,0.3-0.6,0.5-0.9
		c0.1-0.2,0.3-0.5,0.5-0.7c0.4-0.6,1-1.1,1.6-1.5c1.3-0.6,2.7-0.9,4.2-0.9c2.4,0,4.7,0.2,7.1,0.6c2.7,0.4,5.3,1.3,7.6,2.8
		C158.7,373.2,159.7,374.1,160.1,373.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M194.4,347.4c0,0.2,1.7,0.3,4.4-0.8c1.6-0.7,3.1-1.5,4.6-2.4l1.3-0.8c0.2-0.1,0.5-0.3,0.6-0.3
		c0.1,0,0.3-0.1,0.5-0.1c1.1-0.1,2.3-0.2,3.5-0.2s2.5-0.1,3.9-0.1h4.2c0.7,0,1.5,0,2.2,0l0.3,0.1c0.1,0,0.2,0,0.1,0.1
		c-0.1,0.1-0.1,0.1-0.2,0.1h-0.1h-0.1h-0.1c-0.8,0.1-1.6,0.1-2.5,0.2l-5.3,0.3c-0.9,0.1-1.8,0.1-2.8,0.3c-0.6,0.1-1.2,0.2-1.7,0.4
		c-0.4,0.1-0.7,0.3-1,0.6l-0.6,0.5c-1.5,1.2-2.9,2.5-4.4,3.8l-1.1,1c-0.5,0.5-1.1,1.1-1.6,1.6c-0.3,0.3-0.5,0.5-0.7,0.8
		c-0.2,0.2-0.4,0.5-0.6,0.8c-0.2,0.4-0.3,0.8-0.3,1.2c0,0.2,0,0.3,0.1,0.4c0.1,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3
		c0.1,0.1,0.3,0.2,0.4,0.3s0.5,0.1,0.7,0.2c0.3,0,0.6,0.1,0.9,0.1l2.2-0.1l1.9-0.1l1.1-0.1l2.2-0.2c1.4-0.1,2.9-0.3,4.3-0.5
		c0.7-0.1,1.4-0.2,2.2-0.4s1.3-0.4,2-0.5l1.9-0.5l0.9-0.2l0.7-0.1c0.2,0,0.3-0.1,0.5-0.1c0.2,0,0.3,0.2,0.3,0.3
		c0,0.2-0.1,0.3-0.2,0.5c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2,0.2-0.4,0.3-0.6,0.4c-0.4,0.3-0.8,0.5-1.3,0.7
		c-0.5,0.2-1,0.3-1.5,0.5c-1,0.3-2,0.6-3,0.8c-1.9,0.5-3.7,0.9-5.4,1.2l-4.4,0.9c-0.6,0.1-1.2,0.3-1.8,0.4c-0.3,0.1-0.6,0.2-0.8,0.4
		c-0.2,0.1-0.4,0.3-0.6,0.4c-1.6,1.2-3.7,3.3-7.1,6.3c-2,1.8-4.1,3.4-6.4,4.8c-1.3,0.8-2.8,1.6-4.5,2.4c-0.8,0.4-1.7,0.8-2.6,1.1
		l-1.4,0.5l-0.8,0.3l-1.3,0.5c-0.1,0.2-0.2,0.5-0.3,0.8l-0.3,1l-0.6,1.8c-0.4,1.4-0.9,3.3-1.4,5.4c-1,4.2-2,9.6-2.9,15.2
		c-1,6.1-1.8,12-2.9,17.4c-0.9,5-2.2,9.8-3.8,14.6c-2,6.2-5.1,12-9,17.1c-0.7,0.9-1.6,2-3.4,3.8l-1.5,1.5c-0.6,0.5-1.2,1.1-1.9,1.7
		s-1.5,1.3-2.4,1.9c-0.5,0.3-0.9,0.7-1.4,1c-0.3,0.2-0.6,0.4-0.9,0.5l-0.2-0.5l-0.1-0.3c-0.1-0.1,0-0.1-0.2-0.3l-1.4-2.6
		c-1.1-2-2.4-4.4-3.9-7.2c-0.7-1.4-1.5-2.9-2.2-4.5c-0.8-1.6-1.5-3.2-2.1-4.9c-1.2-3.8-2.1-7.6-2.6-11.5c-0.5-3.6-0.8-7.2-0.8-10.9
		c-0.1-3.5-0.1-7,0-10.2c0.2-5.8,1-11.5,2.2-17.2c1.7-9.9,2.3-16.1,1.9-16.2s-2.1,5.9-4.4,15.6c-1.6,5.7-2.6,11.6-3,17.5
		c-0.2,3.4-0.3,6.9-0.3,10.5c0,3.8,0.3,7.6,0.8,11.3c0.6,4.1,1.5,8.2,2.9,12.1c0.7,1.8,1.5,3.6,2.4,5.3c0.9,1.6,1.7,3.1,2.5,4.5
		c1.6,2.8,3.2,5.1,4.4,7c0.6,0.9,1.2,1.7,1.7,2.4l0.9,1.3l0.7,1c0.8-0.5,1.6-1,2.4-1.5c0.5-0.4,1-0.8,1.5-1.1
		c0.9-0.7,1.7-1.4,2.4-2.1s1.3-1.3,1.9-1.8l1.5-1.6c1.7-1.9,2.6-3.1,3.4-4c4.3-5.1,7.8-10.9,10.4-17c2-4.8,3.6-9.7,4.8-14.8
		c1.3-5.5,2.3-11.4,3.3-17.5c0.9-5.5,1.8-10.9,2.6-15.1c0.4-2.1,0.8-3.9,1-5.4l0.3-1.9c0-0.1,0-0.3,0-0.4v-0.2l0.9-0.4l1.4-0.6
		c0.9-0.4,1.8-0.9,2.6-1.3c1.6-0.8,3.1-1.7,4.5-2.7c2.3-1.6,4.5-3.3,6.4-5.3c3.3-3.3,5.2-5.5,6.7-6.6c0.3-0.3,0.7-0.5,1.1-0.6
		c0.6-0.1,1.1-0.2,1.7-0.3c1.3-0.2,2.8-0.4,4.4-0.6s3.5-0.5,5.5-0.8c1-0.2,2-0.4,3.1-0.6c0.5-0.1,1.1-0.3,1.7-0.5
		c0.6-0.2,1.2-0.5,1.8-0.8c0.3-0.2,0.6-0.3,0.8-0.5c0.2-0.1,0.3-0.2,0.5-0.4c0.2-0.2,0.4-0.3,0.5-0.5c0.7-0.7,1.1-1.5,1.2-2.4
		c0.1-0.4,0-0.9-0.1-1.3c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1-0.1-0.3-0.3-0.5-0.3c-0.9-0.4-1.9-0.5-2.9-0.3c-0.7,0.1-0.9,0.1-1.2,0.2
		l-1,0.2c-0.7,0.1-1.3,0.3-2,0.4s-1.4,0.3-2,0.4s-1.2,0.2-1.9,0.3c-1.3,0.1-2.7,0.3-4.2,0.4l-2.1,0.1l-1.1,0.1h-0.3l0,0c0,0,0,0,0,0
		l0,0l0.2-0.2l0.4-0.4c1.4-1.3,2.8-2.5,4.2-3.6l0.5-0.4l0.1-0.1c0.3-0.1,0.6-0.1,1-0.2c0.8-0.1,1.7-0.2,2.6-0.3l5.3-0.4
		c0.9-0.1,1.7-0.1,2.6-0.3c0.1,0,0.3,0,0.5-0.1l0.6-0.1c0.4-0.1,0.7-0.3,1.1-0.4c0.6-0.3,1.3-0.7,1.8-1.2c0.9-0.6,1.5-1.6,1.5-2.7
		c0-0.1,0-0.3-0.1-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.3-0.3-0.4-0.5c-0.3-0.3-0.7-0.6-1.2-0.7c-1.3-0.3-2.7-0.4-4-0.4
		c-0.8,0-1.5,0-2.3,0.1l-4.3,0.2c-1.4,0.1-2.7,0.2-4,0.4s-2.4,0.3-3.6,0.5c-0.4,0.1-0.8,0.2-1.1,0.4c-0.3,0.2-0.5,0.4-0.8,0.5
		l-1.3,0.9c-1.6,1.2-2.9,2.1-4.1,2.9C195.8,346.8,194.3,347.1,194.4,347.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M217.5,340.9c0,0.3-0.8,0.4-2,0.2c-1.5-0.3-2.9-0.8-4.2-1.4l-0.4-0.1l0,0l-0.9,0.5c-0.6,0.4-1.2,0.7-1.7,1
		c-1,0.5-1.7,0.7-1.9,0.5s0.2-0.9,1.1-1.7c0.5-0.5,1-0.9,1.5-1.4l0.5-0.4l0.2-0.2c0.1-0.1,0.2-0.2,0.4-0.3c0.4-0.2,0.8-0.2,1.2-0.1
		c0.2,0,0.3,0.1,0.5,0.1l0.4,0.1c1.4,0.6,2.7,1.2,4,2C217,340.2,217.6,340.6,217.5,340.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M133.5,429.5c0.6,0,1-4,3.9-9.9c1.4-2.9,3.4-6.3,5.6-10.1s4.9-7.8,7.3-12.5c3.8-7.4,6.8-14.4,9.2-20
		c1.2-2.8,2.3-5.3,3.2-7.3c0.2-0.5,0.5-1,0.7-1.4s0.4-0.8,0.7-1.1c0.5-0.7,0.9-1.3,1.2-1.8c1.4-1.9,4.4-5.5,8.4-10.1
		c1-1.1,2.1-2.3,3.3-3.5c0.6-0.6,1.2-1.2,1.8-1.8l1-0.9l1-0.9c2.8-2.4,5.8-5,8.9-7.8s6-5.5,8.7-8l3.9-3.6l0.9-0.8
		c0.2-0.2,0.4-0.4,0.7-0.5c0.7-0.3,1.4-0.5,2.1-0.7c1.5-0.4,2.9-0.9,4.2-1.4s2.4-1.1,3.4-1.6s1.9-0.9,2.7-1.3l1.1-0.5
		c0.3-0.1,0.5-0.2,0.7-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2-0.1,0.5-0.1,0.7,0c0.2,0.2,0.1,0.6,0,0.9c-0.1,0.3-0.2,0.6-0.4,0.9
		c-0.2,0.4-0.5,0.7-0.8,1.1c-0.3,0.4-0.7,0.7-1.1,1c-0.5,0.3-1.1,0.5-1.6,0.7l-1.9,0.7c-1.4,0.5-2.8,1-4.3,1.6
		c-0.8,0.3-1.5,0.6-2.4,1c-0.2,0.1-0.5,0.2-0.7,0.4s-0.4,0.3-0.6,0.5l-1,0.9c-2.7,2.3-5.5,4.9-8.5,7.6l-6.7,6.3l8.6-3.3
		c0.1,0,0.4-0.1,0.8-0.3s0.8-0.4,1.2-0.6c2.3-1.2,4.5-2.5,6.5-3.7l2.6-1.5l0,0h0.1l0.8,0.1l1.6,0.4l3.1,0.7l1.5,0.4
		c0.5,0.1,1.1,0.2,1.6,0.3c2,0.2,3.8,0.4,5.4,0.6l1.2,0.2l0.6,0.1l0.3,0h0.1c0.3,0,0.6,0.1,0.9,0.2c0.1,0.1,0.4,0.2,0.3,0.3
		c0,0.1-0.1,0.3-0.1,0.4c-0.2,0.2-0.3,0.4-0.6,0.6c-0.1,0.1-0.2,0.2-0.3,0.3l-0.5,0.3l-0.8,0.5c-0.4,0.3-0.6,0.5-0.6,0.6
		s0.3,0.2,0.8,0.1c0.3,0,0.6-0.1,0.9-0.2c0.2,0,0.4-0.1,0.6-0.2c0.3-0.1,0.6-0.2,0.8-0.3c0.7-0.3,1.2-0.7,1.7-1.2
		c0.2-0.3,0.4-0.6,0.5-1c0-0.4-0.1-0.7-0.4-1c-0.7-0.7-1.6-1.2-2.6-1.4l-0.7-0.2l-0.6-0.2l-1.2-0.3c-1.6-0.4-3.5-0.7-5.4-1.1
		c-0.4-0.1-0.9-0.2-1.3-0.3l-1.5-0.4l-3.1-0.9l-1.7-0.4c-0.3-0.1-0.6-0.1-0.9-0.2c-0.2-0.1-0.5-0.1-0.7-0.1c-0.3-0.1-0.5-0.1-0.8,0
		c-0.3,0.1-0.5,0.2-0.8,0.3l-0.2,0.1l-0.1,0l-0.2,0.1l-0.8,0.4l-1.6,0.9c-2.1,1.2-4.2,2.5-6.4,3.6l-1,0.5l-0.5,0.2l1.9,3l8.1-7.9
		l0.9-0.9l0.4-0.4c0.1-0.1,0.2-0.1,0.4-0.3c0.6-0.4,1.4-0.7,2.1-1.1l4.1-2l1.9-0.9c0.6-0.3,1.3-0.7,1.9-1.1c0.5-0.4,1-1,1.4-1.5
		c0.4-0.5,0.6-1.1,0.9-1.7c0.2-0.6,0.4-1.2,0.4-1.8c0.1-0.5,0-1-0.4-1.4c-0.4-0.2-0.8-0.2-1.2-0.1c-0.4,0-0.8,0.1-1.1,0.2L217,321
		l-1.1,0.4l-2.8,1c-1,0.4-2.2,0.8-3.4,1.2s-2.6,0.6-4.1,0.9c-0.8,0.1-1.6,0.3-2.5,0.6c-0.5,0.2-1,0.5-1.4,0.8l-1,0.8
		c-1.4,1.1-2.8,2.2-4.2,3.4c-2.9,2.4-5.9,5-9,7.7s-6,5.5-8.6,8.1l-1,1c-0.3,0.3-0.7,0.7-1,1c-0.6,0.7-1.2,1.4-1.8,2
		c-1.1,1.4-2.1,2.7-3,3.9c-3.6,5-6.1,9-7.5,10.9c-0.3,0.5-0.8,1-1.3,1.7c-0.3,0.4-0.6,0.8-0.9,1.2c-0.3,0.4-0.6,0.9-0.8,1.4
		c-1.2,1.9-2.5,4.3-4,7c-3,5.4-6.6,12.1-10.3,19.4c-2.3,4.4-4.9,8.5-7,12.4c-2,3.4-3.7,7-5.1,10.7c-1,2.5-1.7,5.2-1.9,7.9
		c-0.1,0.7-0.1,1.5,0,2.2C133.3,429.2,133.4,429.5,133.5,429.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M177.7,329.7c3.8,1.7,7.3,3.4,9.7,4.7s3.9,2.3,3.7,2.8s-2,0.5-4.7-0.2c-3.5-1-6.9-2.2-10.3-3.7
		C176.6,332.1,177.1,330.9,177.7,329.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M183.7,344.6c-1.5-0.5-2.9-1.2-4.3-2c-1.1-0.6-2.1-1.3-3.1-2c-1.7-1.3-2.5-2.4-2.3-2.8s1.6-0.3,3.6,0.4
		c1,0.3,2.1,0.8,3.3,1.3s2.6,1,3.9,1.5C184.6,342.1,184.2,343.4,183.7,344.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M223.9,340.6c7.9,1.3,14.4,2.3,14.4,3.4s-6.8,1.8-15,0.4c0-0.7,0.1-1.3,0.3-1.9l0.3-1.3
		C223.8,341,223.8,340.8,223.9,340.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M235.2,353.3c-7.1-0.1-14.2-1.1-21.2-2.8c-5.3-1.3-8.4-2.6-8.3-3.1s3.5-0.2,8.8,0.4c6.9,0.9,13.8,1.4,20.7,1.6
		C235.5,349.4,235.4,353.3,235.2,353.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M172,409.5c2.1,4.4,4.7,8.6,7.7,12.4c2.3,3,4.1,4.6,3.8,5.1s-2.7-0.5-5.8-3.2c-3.9-3.6-7.1-7.9-9.3-12.8
		C168.3,410.9,172,409.4,172,409.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M212.1,452.6c-0.1,0.5-2.8,0.4-6.9-1c-5.2-1.9-10.1-4.5-14.7-7.7c-1.3-0.9-2.6-1.9-3.8-2.8
		c-0.6-0.5-1.2-1-1.8-1.5c-0.3-0.3-0.6-0.5-0.8-0.8c-0.3-0.3-0.6-0.6-0.9-0.9c-0.2-0.2-0.3-0.4-0.4-0.5c-0.1-0.2-0.2-0.4-0.3-0.6
		c-0.2-0.4-0.3-0.7-0.5-1c-0.3-0.6-0.5-1.3-0.7-1.9c-0.4-1.2-0.8-2.3-1-3.3c-1.1-4.1-1.4-6.7-1-6.8s1.7,2.2,3.4,6l1.4,3.1
		c0.3,0.5,0.5,1.1,0.8,1.6c0.1,0.3,0.3,0.5,0.4,0.8c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.1,0.3,0.3
		l0.4,0.3c0.2,0.2,0.5,0.4,0.8,0.6c0.5,0.4,1.1,0.9,1.7,1.3c1.2,0.9,2.4,1.8,3.7,2.6c4.4,3,9,5.8,13.7,8.4
		C209.9,451.2,212.2,452.1,212.1,452.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M232.6,495c-0.8-0.5-1.3-0.9-1.9-1.3l-1.7-1.3l-3.3-2.5l-6.3-5c-2.1-1.6-4.1-3.2-6.1-4.7c-1-0.8-2-1.5-3-2.1
		l-1.6-1.1c-0.5-0.4-1-0.8-1.5-1.2c-3.8-3.3-7-6.6-10-9.7l-4.3-4.4c-0.7-0.7-1.4-1.4-2.1-2l-1.1-1c-0.4-0.3-0.7-0.7-1-1
		c-2.3-2.5-4.3-5.2-6.1-8c-0.8-1.2-1.5-2.4-2.1-3.5c-0.3-0.5-0.6-1.1-0.8-1.7c-0.2-0.6-0.4-1.1-0.5-1.7c-0.1-0.9-0.2-1.9-0.1-2.9
		c0-0.4,0.1-0.8,0.1-1.1c0-0.4,0.1-0.7,0.4-1c0.3-0.4,0.6-0.7,1.1-0.9c0.3-0.1,0.4-0.1,0.5-0.1s-0.1,0.2-0.2,0.4
		c-0.2,0.3-0.4,0.6-0.6,0.9c-0.1,0.2-0.1,0.5-0.1,0.7l0.1,1.1c0.1,0.9,0.2,1.7,0.4,2.6c0.1,0.5,0.3,0.9,0.5,1.4
		c0.2,0.4,0.6,0.9,0.9,1.4c0.7,1,1.4,2.1,2.3,3.3c1.9,2.6,4,5,6.3,7.3c0.3,0.3,0.6,0.6,1,0.9l1.1,0.9c0.8,0.6,1.5,1.3,2.2,2
		c1.5,1.4,3,2.8,4.5,4.3c3.1,3,6.4,6.2,10,9.2c0.4,0.4,0.9,0.7,1.4,1l1.5,1c1.1,0.7,2.1,1.5,3.2,2.2c2.1,1.5,4.1,3.1,6.2,4.7
		l6.3,4.9l3.2,2.5l1.6,1.2c0.5,0.4,1.1,0.8,1.5,1.1c0.1,0.1-0.2,1-0.6,1.9S233,495.2,232.6,495z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M231.5,511.3c-0.3,0.4-4.4-1.7-10.8-5.3l-5.2-3.1c-1-0.6-2-1.4-2.9-2.2c-0.9-0.8-1.8-1.6-2.7-2.5
		c-1.8-1.7-3.6-3.5-5.5-5.3l-2.9-2.8c-1-1-2-2-3-3c-3.9-4.1-7.5-8.2-10.7-12c-2.9-3.3-5.4-6.9-7.7-10.8c-1.5-2.6-2.6-5.4-3.3-8.3
		c-0.5-2.1-0.5-3.3-0.3-3.3s0.7,1,1.6,2.8c1.2,2.6,2.6,5.1,4.2,7.5c2.5,3.5,5.1,6.8,8,9.9c3.2,3.6,6.8,7.6,10.7,11.6l11.1,11.3
		c0.9,0.9,1.7,1.7,2.5,2.5c0.7,0.7,1.5,1.4,2.4,2l5,3.4C228.1,508.1,231.8,510.9,231.5,511.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M140.9,721.6c0.5,0.2,2.4-4.9,5.6-12.9s7.8-19.1,13.2-31.1c2.3-4.8,4.5-9.5,6.5-13.8s3.8-8.6,5.1-12.4
		c1.2-3.3,2.2-6.7,3-10.1c0.7-2.9,1-5.2,1.2-6.7s0.6-3.8,0.9-6.7s0.7-6.4,0.9-10.4s0.6-8.4,0.8-13.3c0.2-5.2,0-10.4-0.6-15.5
		c-0.6-5.1-1.5-10.2-2.7-15.3c-1.1-4.7-2.3-9-3.2-12.9c-1.8-7.8-3.1-13.7-3.7-16.7s-1.3-9-2.4-17c-0.3-2-0.6-4.1-0.9-6.3
		c-0.2-1.1-0.3-2.2-0.5-3.4s-0.3-2.3-0.4-3.4c-0.4-5-0.3-10,0.4-14.9c0.8-6.5,2.1-12.6,3.3-18.2c1-4.9,2.3-9.8,3.9-14.5
		c2.8-8.1,5.8-12.5,5.5-12.8c-0.2-0.2-1.3,0.7-2.7,2.7c-2,2.8-3.7,5.9-5.1,9.1c-2,4.8-3.6,9.7-4.8,14.7c-1.6,6.1-2.8,12.3-3.7,18.5
		c-0.7,5.2-0.7,10.5,0,15.7c0.1,1.2,0.4,2.4,0.6,3.6l0.7,3.3c0.5,2.2,0.9,4.2,1.4,6.2c1.7,7.8,3.1,13.7,3.7,16.7s1.3,9,2.4,17
		c0.6,4,1.5,8.4,2.4,13.1s1.7,9.7,2.2,15s0.9,10.3,0.9,15.1s0.1,9.3,0.1,13.3s0,7.5-0.1,10.4c-0.1,2.2-0.2,4.4-0.5,6.6
		c-0.4,2.2-0.9,4.4-1.5,6.5c-1.1,3.3-2.3,6.5-3.8,9.6c-1.6,3.6-3.6,7.6-5.8,11.8s-4.6,8.8-6.9,13.8c-4.7,10.3-8.8,20.9-12.4,31.7
		C141.5,716.2,140.4,721.5,140.9,721.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M197.3,272.3c-0.6,0.1-1.2-0.9-1.7-1.9s-0.9-2-0.5-2.5s1.7,0.1,2.4,1.6S197.8,272.2,197.3,272.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M169.7,271c-0.3-0.1-0.4-0.5-0.4-0.9c0.1-0.6,0.4-1.1,0.9-1.6c1-1,2.1-1.1,2.4-0.6s-0.2,1.3-1,2.1
		S170.2,271.1,169.7,271z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M207.6,237.5c-1.9-1.5-4.3-2.3-6.8-2.3c-4.4-0.1-9.5,2-11.7,1.9c0.7,1.2,2,1.9,3.4,2c2.3,0,6.2-2.3,8.5-2.5
		C203.3,236.7,205.5,237,207.6,237.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M158.4,237.3c2-1.3,4.2-2,6.6-2.1c3.8-0.1,12,2.7,13.1,2.5c0,0-1.3,1.6-4.6,1.5s-7-2.2-9.1-2.3
		S159.6,236.8,158.4,237.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M205.9,243.6c0,0.1-0.1,0.4-0.5,0.6c-0.3,0.2-0.6,0.3-1,0.3c-0.5,0-0.9-0.1-1.3-0.3c-0.7-0.5-1.5-0.8-2.4-1.1
		c-1-0.3-2-0.5-3.1-0.5c-1,0-2.1,0.1-3.1,0.3c-0.8,0.2-1.5,0.6-2.2,1c-1.3,0.9-2.1,1.5-2.3,1.3s0.2-1.2,1.3-2.4
		c0.7-0.8,1.6-1.4,2.6-1.7c1.2-0.4,2.4-0.6,3.7-0.5c1.3,0,2.5,0.3,3.6,0.8c0.9,0.4,1.8,1,2.5,1.7c0.2,0.2,0.4,0.3,0.7,0.4
		c0.2,0.1,0.4,0.1,0.6,0.1C205.6,243.5,205.9,243.4,205.9,243.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M203.9,243.8c0.3,0.1,0.2,1.2-0.9,2.4c-3.2,2.6-7.7,2.9-11.2,0.6c-1.2-1-1.6-1.9-1.3-2.1s0.9,0.3,2.1,0.8
		c3.1,1.2,6.5,1,9.5-0.5C203.2,244.4,203.6,243.8,203.9,243.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M194.8,246.7c0-2,1.7-3.7,3.7-3.7c1.7,0,3.2,1.2,3.6,2.8C202.2,246.1,194.8,249,194.8,246.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M167.1,246.5c0-2,1.7-3.7,3.7-3.7c1.7,0,3.2,1.2,3.6,2.8C174.5,245.9,167.1,248.9,167.1,246.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M160.2,243.6c0.1-0.1,0.4-0.1,0.8-0.1c0.2,0,0.4,0,0.6-0.1c0.3-0.1,0.5-0.2,0.7-0.4c0.7-0.7,1.6-1.3,2.5-1.7
		c1.2-0.5,2.4-0.8,3.6-0.9c1.3-0.1,2.5,0.1,3.8,0.5c1,0.4,1.9,1,2.6,1.9c1.1,1.3,1.5,2.3,1.3,2.5s-1-0.5-2.3-1.4
		c-0.6-0.5-1.4-0.9-2.1-1.1c-1-0.2-2-0.3-3.1-0.2c-1.1,0.1-2.1,0.3-3.1,0.6c-0.8,0.3-1.6,0.7-2.4,1.1c-0.4,0.2-0.9,0.3-1.3,0.3
		c-0.4,0-0.7-0.1-1-0.3C160.3,244,160.1,243.7,160.2,243.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M162.2,243.8c0.3-0.1,0.8,0.6,1.8,1.2c3,1.4,6.4,1.6,9.5,0.5c1.2-0.5,1.9-1,2.1-0.8s-0.1,1.1-1.3,2.1
		c-1.6,1.2-3.7,1.7-5.7,1.6c-2,0-4-0.8-5.5-2.1C162,245,161.9,243.9,162.2,243.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M212.3,234.1c0.1-0.2-0.9-1.1-3-1.5c-1.2-0.2-2.5-0.1-3.7,0.2c-1.2,0.4-2.5,0.5-3.7,0.3
		c-0.5-0.2-0.9-0.4-1.3-0.8c-0.6-0.4-1.2-0.7-1.9-0.8c-0.7-0.1-1.4-0.1-2,0.2c-0.5,0.2-1,0.5-1.4,0.8c-0.5,0.5-1.1,0.9-1.9,1.1
		c-0.5,0.1-1,0-1.4-0.1c-0.3-0.1-0.8-0.2-1.5-0.4c-0.4-0.1-0.8-0.2-1.2-0.2c-0.5,0-1,0.1-1.5,0.3c-0.8,0.4-1.7,0.7-2.7,0.9
		c-0.5,0.1-1,0.1-1.5,0c-0.5-0.1-1-0.2-1.5-0.4c-1.1-0.4-2-0.9-3.3-1.3c-0.4-0.1-0.8-0.1-1.2,0c-0.3,0.1-0.6,0.3-0.9,0.5
		c-0.4,0.4-0.7,0.7-1,0.9c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3-0.1-0.6-0.3-0.8-0.5c-0.4-0.4-0.8-0.7-1.3-0.9c-0.5-0.3-1.1-0.4-1.6-0.4
		c-0.9,0-1.7,0.2-2.5,0.5c-0.4,0.2-0.9,0.3-1.3,0.5c-0.3,0.1-0.7,0.1-1,0c-0.3-0.3-0.6-0.6-0.8-1c-0.2-0.3-0.5-0.6-0.8-0.8
		c-0.4-0.3-0.9-0.4-1.4-0.4c-0.5,0-1,0.2-1.5,0.5c-0.3,0.2-0.6,0.4-0.9,0.7c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.4-0.1-0.6-0.2
		c-0.6-0.2-1.1-0.5-1.8-0.7c-0.7-0.3-1.4-0.3-2.1,0c-0.2,0.1-0.3,0.2-0.5,0.3l-0.2,0.2c-0.1,0.1-0.3,0.3-0.4,0.4s-0.6,0.4-0.8,0.6
		c-0.4,0.3-0.7,0.5-0.6,0.7s0.4,0.1,0.9,0c0.3-0.1,0.7-0.2,1-0.4c0.2-0.1,0.4-0.2,0.6-0.3l0.3-0.2c0.1,0,0.1-0.1,0.1-0.1
		c0.4,0,0.7,0.1,1.1,0.3c0.5,0.2,1,0.5,1.6,0.8c0.4,0.2,0.8,0.3,1.3,0.4c0.5,0,1-0.2,1.5-0.5c0.7-0.5,1.1-0.8,1.5-0.8
		c0.1,0,0.2,0,0.3,0.1c0.2,0.1,0.3,0.3,0.4,0.4c0.4,0.6,0.9,1.1,1.6,1.4c0.7,0.2,1.4,0.1,2.1-0.2c0.5-0.2,0.9-0.5,1.3-0.7
		c0.6-0.4,1.3-0.7,2-0.9c0.4-0.1,0.9,0,1.3,0.2c0.4,0.3,0.8,0.6,1.1,1c0.3,0.3,0.6,0.6,1,0.8c0.6,0.2,1.2,0.1,1.7-0.1
		c0.4-0.2,0.9-0.5,1.3-0.8c0.1-0.1,0.3-0.2,0.5-0.2c0.1,0,0.3,0,0.4,0.1c0.8,0.3,1.9,1,3.1,1.5c0.6,0.2,1.3,0.4,1.9,0.5
		c0.6,0.1,1.3,0,1.9-0.1c1.1-0.3,2.1-0.7,2.9-1.4c0.3-0.2,0.6-0.3,1-0.4c0.3,0,0.7,0,1,0c0.5,0,1,0.1,1.5,0.2
		c0.5,0.1,1.1,0.2,1.6,0.3c0.4,0,0.8-0.1,1.2-0.2c0.4-0.2,0.8-0.4,1.2-0.6c0.7-0.5,1.5-0.7,2.4-0.5c0.4,0.1,0.8,0.4,1.2,0.7
		c0.5,0.5,1.1,0.8,1.8,1.1c1.6,0.4,3.2,0.2,4.7-0.4c1-0.3,2.1-0.5,3.2-0.6C211,233.8,212.2,234.3,212.3,234.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M253.2,295.7c0.2,0.1,0.6-0.2,1-0.9c0.3-0.5,0.4-1.1,0.4-1.6c0-0.8-0.3-1.6-0.8-2.3c-0.5-0.6-1-1.2-1.6-1.8
		c-0.6-0.7-0.4-0.4-0.5-1.1s0.1-1.5,0-2.7c0-0.7-0.2-1.3-0.4-1.9c-0.3-0.7-0.8-1.4-1.4-1.9c-0.7-0.8-1.2-1.9-1.3-3l-0.2-1
		c-0.1-0.4-0.2-0.9-0.3-1.3c-0.1-0.3-0.2-0.6-0.4-0.8c-0.1-0.2-0.3-0.4-0.5-0.6c-0.3-0.3-0.6-0.4-0.8-0.6c-0.9-0.6-1.7-1.2-2.4-1.8
		c-0.5-0.4-0.8-0.9-1-1.5c0-0.1,0-0.2,0-0.3c0-0.1,0-0.3,0-0.4c0-0.4,0.1-0.8,0.1-1.2c0.1-1.2-0.1-2.4-0.6-3.5
		c-0.3-0.8-0.8-1.4-1.4-2c-0.2-0.1-0.3-0.3-0.5-0.4l-0.3-0.2c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.2-0.3
		c-0.2-0.5-0.2-1-0.3-1.5c-0.1-0.9-0.2-1.8-0.5-2.7c-0.2-0.6-0.5-1.1-0.9-1.6c-0.4-0.5-0.9-0.9-1.5-1.1c-0.1-0.1-0.2-0.1-0.4-0.2
		l-0.2-0.1c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.1-0.5-0.2-0.7-0.4c-0.4-0.2-0.6-0.6-0.8-1c-0.2-0.6-0.4-1.1-0.5-1.7
		c-0.1-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.3-0.6-0.5-0.8c-0.3-0.4-0.7-0.8-1.1-1.2c-0.5-0.5-1.1-0.9-1.7-1.4c-0.6-0.5-1.2-0.9-1.8-1.3
		c-0.3-0.1-0.5-0.4-0.5-0.7c-0.1-0.5-0.2-0.9-0.2-1.4c0-0.7-0.1-1.4-0.3-2.1c-0.1-0.5-0.3-0.9-0.5-1.3c-0.2-0.4-0.5-0.8-0.8-1.1
		c-0.5-0.7-1.1-1.2-1.6-1.7c-0.1-0.1-0.3-0.3-0.4-0.5l0,0c0-0.2-0.1-0.5-0.1-0.7c-0.2-1.4-0.9-2.6-2-3.5c-0.5-0.4-1-0.7-1.6-1
		c-0.3-0.1-0.4-0.2-0.6-0.2c-0.1-0.1-0.2-0.1-0.4-0.2c-0.4-0.2-0.7-0.6-0.8-1c-0.2-0.6-0.3-1.3-0.5-2c-0.2-0.8-0.6-1.5-1.2-2.1
		c-0.4-0.4-1-0.6-1.5-0.8c-0.9-0.2-1.4,0-1.4,0.3s0.3,0.5,0.8,1c0.3,0.2,0.5,0.5,0.6,0.8c0.2,0.4,0.2,0.7,0.2,1.1
		c0,0.8,0,1.5,0.1,2.3c0.1,0.6,0.2,1.1,0.5,1.6c0.3,0.6,0.7,1.1,1.2,1.5c0.2,0.2,0.5,0.4,0.8,0.5l0.7,0.3c0.3,0.1,0.5,0.3,0.8,0.5
		c0.4,0.4,0.7,1,0.7,1.5l0.1,0.5c0.1,0.4,0.2,0.8,0.4,1.1c0.3,0.5,0.6,1,1.1,1.4c0.7,0.6,1.3,1,1.7,1.4c0.2,0.2,0.4,0.4,0.6,0.6
		c0.2,0.2,0.3,0.4,0.4,0.6c0.3,0.5,0.5,1.1,0.7,1.7c0.2,0.6,0.5,1.3,0.9,1.8c0.2,0.3,0.5,0.6,0.8,0.8c0.1,0.1,0.3,0.2,0.5,0.2
		c0.1,0.1,0.3,0.1,0.4,0.1c0.6,0.1,1.2,0.4,1.7,0.8c0.5,0.4,0.9,0.9,1.2,1.5c0.5,0.7,0.8,1.6,0.8,2.5c0,0.7,0,1.4,0.1,2.1
		c0.2,1,0.7,1.9,1.4,2.6c0.4,0.3,0.7,0.6,1.2,0.9c0.2,0.1,0.4,0.2,0.6,0.4l0.3,0.2c0.1,0,0.1,0.1,0.1,0.1c0.3,0.2,0.5,0.5,0.6,0.8
		c0.2,0.7,0.3,1.3,0.4,2c0.1,1,0.5,1.9,1,2.8c0.4,0.5,0.8,0.9,1.4,1.2c0.1,0,0.2,0.1,0.2,0.1l0.2,0.1l0.3,0.2
		c0.4,0.2,0.8,0.5,1.1,0.9c0.6,0.7,1,1.6,1.2,2.5c0.1,0.4,0.2,0.7,0.2,1.1c0,0.3,0.1,0.7,0.2,1c0.2,0.7,0.5,1.5,0.9,2.1
		c0.6,0.9,1.3,1.8,2,2.6c0.2,0.2,0.4,0.5,0.5,0.7s0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1c0,0,0,0.5,0,0.8s0,0.7,0,1.1
		c0,0.9,0.1,1.8,0.4,2.6c0.3,1.1,0.9,2.1,1.8,2.8c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0.1,0.8,0,1.7,0.2,2.8
		c0.1,0.7,0.3,1.3,0.7,1.9c0.3,0.4,0.8,0.8,1.2,1.1c0.7,0.5,1.2,0.8,1.6,1.1c0.3,0.2,0.5,0.5,0.7,0.9c0.1,0.3,0.2,0.7,0.2,1
		C252.9,295.1,252.9,295.6,253.2,295.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M140.6,323.4c0.1-0.2-0.2-0.7-0.6-1.4c-0.4-1-0.5-2.2-0.2-3.2c0.1-0.7,0.4-1.5,0.7-2.5
		c0.4-1.2,0.7-2.4,0.7-3.6c0-0.8-0.3-1.6-0.7-2.4l-0.3-0.5l-0.2-0.4c-0.1-0.2-0.3-0.5-0.4-0.7c-0.2-0.4-0.3-0.8-0.4-1.2
		c0-0.3,0-0.6,0.1-0.9l0.3-0.6c0.1-0.3,0.3-0.7,0.4-1c0.2-0.7,0.3-1.4,0.2-2.1c-0.1-1.3-0.6-2.6-1.4-3.6c-0.4-0.4-0.6-1-0.6-1.6
		c-0.1-0.7,0-1.4,0.1-2.1c0.1-0.4,0.1-0.9,0.2-1.3c0-0.5-0.1-1-0.3-1.5c-0.4-0.6-0.8-1.2-1.3-1.6c-0.5-0.5-0.9-1.2-1-2
		c-0.1-0.8,0.1-1.6,0.5-2.3c0.2-0.5,0.5-1,0.9-1.6c0.1-0.1,0.2-0.3,0.3-0.5s0.1-0.3,0.1-0.4l0.1-0.4c0-0.6-0.1-1.1-0.3-1.6
		c-0.3-0.7-0.6-1.4-0.8-2.1c0-0.2,0-0.4,0.1-0.6c0.1-0.3,0.3-0.6,0.5-0.9l0.7-1.2c0.3-0.5,0.6-1.1,0.8-1.7c0.1-0.5,0.2-1,0.1-1.5
		c-0.1-0.2-0.1-0.5-0.2-0.7l-0.1-0.3c-0.1-0.5-0.3-0.9-0.4-1.3c-0.1-0.4-0.2-0.8-0.2-1.2c0-0.1,0-0.3,0-0.4l0,0l0,0c0,0,0,0,0,0
		c0.2-0.2,0.3-0.3,0.4-0.5c0.2-0.4,0.4-0.7,0.5-1.1c0.2-0.7,0.2-1.3,0.2-2c-0.1-1.1-0.4-2.1-0.9-3.1c-0.1-0.2-0.2-0.5-0.3-0.8
		c0-0.3,0-0.6,0-0.8c0.1-0.6,0.3-1.2,0.6-1.8l0.4-0.9c0.2-0.3,0.3-0.7,0.4-1.1c0.1-1-0.1-2-0.4-2.9l-0.3-1.1l-0.2-0.7
		c-0.1-0.2-0.1-0.5-0.1-0.7c-0.1-0.8,0.5-1.8,1.4-3c0.2-0.3,0.5-0.6,0.8-1c0.3-0.4,0.6-0.9,0.8-1.4c0.3-1,0.5-2.1,0.4-3.2
		c-0.1-0.8,0-1.5,0.1-2.3c0.1-0.2,0.3-0.4,0.5-0.6l1-1.1c0.6-0.6,1-1.3,1.4-2c0.2-0.4,0.4-0.8,0.4-1.3c0.1-0.2,0.1-0.5,0.1-0.7
		c0-0.2,0-0.5,0-0.6c-0.1-1.1-0.1-2.2,0-3.3c0-0.7,0.2-1.4,0.6-2c0.7-0.7,1.4-1.4,2-2.1c0.3-0.4,0.6-0.9,0.8-1.5
		c0.1-0.3,0.1-0.5,0.1-0.8v-0.4c0-0.1,0-0.1,0-0.2c0-0.4,0.1-0.8,0.3-1.1c0.4-0.7,0.8-1.2,0.6-1.4s-0.7-0.2-1.7,0.4
		c-0.6,0.5-1.1,1.1-1.3,1.9l-0.1,0.4v0.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.3,0.4-0.5,0.6c-0.5,0.4-1.2,0.9-2.1,1.6
		c-0.6,0.5-1,1.1-1.3,1.8c-0.3,0.6-0.5,1.3-0.6,1.9c-0.2,1.2-0.3,2.5-0.3,3.7c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.2
		c0,0.2-0.1,0.3-0.2,0.5c-0.2,0.4-0.5,0.8-0.9,1.1c-0.3,0.4-0.7,0.7-1.1,1.2c-0.5,0.6-0.9,1.2-1.1,1.9c-0.2,1.2-0.2,2.4,0,3.5
		c0.1,0.7,0.2,1.4,0.1,2.1c-0.1,0.3-0.2,0.6-0.3,0.9l-0.5,1c-0.4,0.7-0.7,1.5-0.9,2.3c-0.2,0.8-0.2,1.6,0,2.3
		c0.1,0.3,0.2,0.5,0.3,0.8l0.3,0.6c0.1,0.3,0.3,0.7,0.3,1c0.2,0.8,0.2,1.5,0,2.3c-0.1,0.2-0.2,0.4-0.3,0.5l-0.6,0.8
		c-0.5,0.7-0.9,1.4-1.2,2.1c-0.2,0.5-0.3,1-0.3,1.5c0,0.3,0,0.6,0,0.9c0,0.2,0.1,0.3,0.1,0.5l0.1,0.4c0.2,0.7,0.3,1.5,0.3,2.2
		c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.1-0.1,0.1-0.1,0.2s0,0-0.1,0c-0.1,0.1-0.1,0.1-0.2,0.2l-0.1,0.1c-0.1,0.1-0.2,0.2-0.2,0.3
		c-0.3,0.4-0.4,0.9-0.5,1.4c0,0.4,0,0.8,0,1.1c0,0.6,0.2,1.3,0.3,1.9c0.1,0.6,0.3,1.1,0.6,1.6l0.1,0.4l0,0l0,0
		c-0.1,0.3-0.2,0.7-0.4,1c-0.2,0.4-0.4,0.9-0.6,1.4c-0.2,0.5-0.4,1.1-0.5,1.6c-0.1,0.7-0.1,1.4,0.1,2c0.4,0.8,0.8,1.6,1.4,2.4
		c0.3,0.5,0.4,0.5,0.2,1.2c-0.2,0.6-0.4,1.3-0.6,1.9c-0.2,0.9-0.3,1.9-0.1,2.9c0.1,1,0.4,1.9,0.9,2.8c0.3,0.5,0.6,0.9,0.8,1.5
		c0,0.2,0,0.4,0,0.6c-0.1,0.2-0.2,0.5-0.3,0.9c-0.3,0.9-0.6,1.8-0.6,2.7c-0.1,1.3,0.2,2.5,0.9,3.5c0.3,0.5,0.5,1.2,0.5,1.8
		c0,0.3,0,0.6-0.1,0.9c0,0.2-0.1,0.3-0.2,0.4l-0.4,0.7c-0.4,0.9-0.5,1.9-0.4,2.9c0.1,0.8,0.4,1.6,0.8,2.3c0.2,0.3,0.4,0.6,0.5,0.9
		l0.5,0.7c0.2,0.3,0.3,0.6,0.3,0.9c0,0.9,0,1.8-0.3,2.6c-0.2,1-0.3,2-0.4,3c0,0.9,0.1,1.8,0.4,2.7c0.3,0.7,0.7,1.2,1.2,1.7
		C139.9,323.6,140.5,323.6,140.6,323.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M214.1,220.5c0.3,0,0.4-0.5,0.5-1.3c0-0.5-0.1-1.1-0.2-1.6c-0.1-0.3-0.2-0.7-0.4-1c-0.2-0.3-0.3-0.7-0.5-1
		c-0.4-0.7-1-1.4-1.6-2c-0.3-0.3-0.7-0.6-1-0.9c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.3-0.2-0.7-0.3-1.1c-0.1-0.7-0.3-1.3-0.7-1.9
		c-0.2-0.4-0.6-0.8-1-1c-0.1,0-0.2-0.1-0.3-0.2l-0.3-0.1l-0.1-0.1c-0.1,0,0,0,0,0v-0.1l-0.1-0.6c-0.1-0.4-0.3-0.8-0.5-1.1
		c-0.4-0.5-0.8-0.9-1.3-1.2c-0.4-0.3-0.9-0.6-1.4-0.8c-0.3-0.1-0.5-0.2-0.8-0.3l-0.4-0.1h-0.4c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1
		c0-0.1-0.1-0.2-0.2-0.3c-0.2-0.3-0.4-0.7-0.7-1c-0.3-0.4-0.8-0.8-1.2-1c-0.7-0.3-1.5-0.3-2.3-0.2c-0.6,0.1-1.1,0.2-1.6,0.3
		c-0.3,0.1-0.6,0.1-0.9,0c-0.2-0.1-0.4-0.2-0.6-0.4c-0.3-0.3-0.6-0.9-1.2-1.8c-0.5-0.6-1.1-0.9-1.8-1c-0.7-0.1-1.3-0.1-2,0
		c-0.3,0-0.6,0-0.9,0c-0.1,0-0.3,0-0.4,0c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.3l-0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.3
		c-0.2-0.3-0.5-0.5-0.7-0.7c-0.6-0.4-1.3-0.6-2-0.4l-0.8,0.1l-0.5,0.1c-0.3,0-0.5,0-0.8,0c-0.1,0,0,0,0,0l0,0l0,0
		c-0.6-0.5-1.3-0.9-2.1-1c-0.7-0.1-1.4,0-2,0.2c-0.3,0.1-0.5,0.2-0.8,0.4l-0.4,0.2l-0.2,0.1h-0.1c-0.2,0.1-0.4,0.2-0.6,0.3
		c-0.4,0.2-0.9,0.2-1.4,0.2c-0.3,0-0.6-0.1-0.8-0.3c-0.5-0.2-1.1-0.3-1.6-0.1c-0.5,0.1-0.9,0.4-1.3,0.7c-0.3,0.2-0.5,0.5-0.5,0.5
		s-0.4,0.2-0.9,0.2c-0.8-0.2-1.6-0.1-2.4,0.1c-0.4,0.1-0.7,0.4-1,0.6c-0.3,0.3-0.6,0.6-0.9,0.8c-0.3,0.2-0.6,0.4-1,0.5
		c-0.5,0.1-0.9,0-1.4-0.1c-0.7-0.1-1.4-0.2-2.1-0.3c-0.2,0-0.5,0-0.7,0.1c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.6,0.3-0.8,0.6
		c-0.2,0.2-0.4,0.4-0.6,0.6c-0.3,0.4-0.5,0.7-0.7,1.1c-0.1,0.2-0.2,0.3-0.3,0.4s-0.2,0.2-0.2,0.2l0,0l0,0l0,0l-0.2,0.1
		c-0.3,0.1-0.6,0.2-0.9,0.4c-0.3,0.2-0.7,0.4-0.9,0.7c-0.4,0.5-0.8,1-1.1,1.6l-0.7,1.3c-0.4,0.7-0.8,1.3-1.4,1.9l-0.3,0.3
		c-0.1,0.2-0.3,0.3-0.4,0.5c-0.2,0.3-0.4,0.7-0.5,1.1c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.3,0,0.4c0,0.3,0,0.5,0.1,0.7
		c0.1,0.4,0.1,0.7,0.1,0.9s0,0.3,0,0.4c0,0,0,0.1,0,0.1l0,0l-0.1,0.1c-0.1,0.1-0.3,0.3-0.4,0.5c-0.2,0.3-0.4,0.6-0.5,0.9
		c-0.2,0.5-0.4,1-0.4,1.5c-0.1,0.8,0,1.3,0.3,1.4s0.6-0.3,1-0.9c0.2-0.3,0.5-0.6,0.8-1c0.2-0.2,0.3-0.3,0.5-0.5
		c0.1-0.1,0.2-0.1,0.3-0.2l0.1-0.1l0.2-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.4-0.5,0.6-1,0.7-1.6c0.1-0.5,0.1-0.9,0.1-1.4
		c0-0.2,0-0.4,0-0.5s0-0.1,0-0.2l0,0c0,0,0.1,0,0.1-0.1s-0.1,0.1,0.3-0.2c0.9-0.7,1.6-1.6,2.2-2.5c0.3-0.4,0.6-0.9,0.8-1.3
		c0.2-0.3,0.4-0.6,0.6-0.9c0,0,0-0.1,0.1-0.1l0.3-0.1l0.4-0.1c0.2-0.1,0.4-0.2,0.5-0.3c0.3-0.2,0.6-0.5,0.9-0.8
		c0.2-0.2,0.4-0.5,0.5-0.8c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.4,0.3-0.7,0.4-0.9l0.1-0.2c0-0.1,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1l0.3-0.1
		c0.6-0.1,1.2-0.2,1.8-0.2c0.7,0,1.5-0.2,2.1-0.5c0.6-0.3,1-0.7,1.4-1.3c0.3-0.4,0.5-0.8,0.7-1c0.1-0.2,0.3-0.3,0.5-0.4
		c0.6-0.1,1.2,0.1,1.7,0.3c0.3,0.1,0.6,0.2,0.9,0.2c0.4,0.1,0.8,0,1.2-0.2l0.3-0.1l0.2-0.1l0.4-0.2c0.1-0.1,0.2-0.1,0.4-0.1
		c0.1,0,0.2,0.1,0.3,0.2c0.3,0.2,0.6,0.4,1,0.6c0.9,0.3,2,0.4,2.9,0.2c0.5-0.1,1.1-0.3,1.6-0.6l0.2-0.1l0.1-0.1
		c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.4,0c0,0,0.1,0.1,0.1,0.1l0.3,0.3c0.1,0.1,0.2,0.1,0.3,0.2
		c0.2,0.1,0.4,0.2,0.6,0.3c0.4,0.1,0.8,0.2,1.2,0.2c0.6,0,1.1-0.1,1.7-0.2l0.6-0.2l0.3-0.1c0.1,0,0,0,0.1,0c0,0,0.1-0.1,0.1-0.1
		c0,0,0,0,0.1,0c0,0,0.1,0.1,0.1,0.1l0.2,0.2c0.4,0.5,0.9,0.8,1.5,1.1c0.3,0.1,0.6,0.1,0.9,0.1c0.2,0,0.4,0,0.6-0.1
		c0.4-0.1,0.7-0.1,1.1-0.3c0.5-0.2,1-0.3,1.5-0.4c0.3-0.1,0.6,0,0.9,0.2c0.5,0.5,1,0.9,1.5,1.5c0.2,0.3,0.5,0.5,0.8,0.7
		c0.2,0.1,0.5,0.2,0.7,0.3c0.3,0.1,0.5,0.1,0.8,0.1c0.5,0,1,0.1,1.5,0.1c0.4,0,0.8,0.2,1.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3
		c0.1,0.2,0.2,0.5,0.3,0.8c0.1,0.2,0.2,0.4,0.3,0.6c0.2,0.3,0.3,0.6,0.6,0.8c0.5,0.5,1.1,0.8,1.8,0.9c0.5,0.1,0.9,0.4,1.3,0.7
		c0.2,0.1,0.3,0.3,0.5,0.5l0,0c0-0.1,0,0.3,0.1,0.6c0.1,0.5,0.3,0.9,0.6,1.3c0.3,0.4,0.7,0.8,1.1,1c0.2,0.1,0.3,0.2,0.5,0.2l0,0l0,0
		c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.5,0.3,1,0.5,1.5c0.3,0.7,0.7,1.3,1.3,1.7l1,0.7c0.5,0.4,1,0.8,1.5,1.2c0.2,0.2,0.4,0.4,0.6,0.6
		c0.2,0.2,0.3,0.4,0.4,0.6c0.3,0.4,0.5,0.8,0.7,1.1C213.6,220.2,213.8,220.6,214.1,220.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M150.4,218.6c0-0.1-0.3,0-0.5,0.3c-0.3,0.4-0.5,0.9-0.6,1.4c-0.2,0.8-0.3,1.6-0.3,2.4v0.7
		c0,0.1,0.1,0.2,0.1,0.2c0.3,0.4,0.4,0.5,0.6,0.7c0.4,0.5,0.9,0.9,1.4,1.2l0,0l0,0c0.9,0.8,2,1.3,3.2,1.4l0,0l0,0
		c0.2,0.1,0.3,0.2,0.5,0.2l0.6,0.2c0.4,0.1,0.8,0.2,1.1,0.2c0.4,0.1,0.8,0.1,1.2,0.1h0.6h0.4c1.9,1.1,5.3,1.4,7.6,1.5l0,0l0.1,0.1
		l0.4,0.2c0.3,0.1,0.5,0.2,0.8,0.3c2.8,1,5.9,1.1,8.8,0.4c0.4-0.1,0.7-0.2,1-0.4h0.1l0.3,0.1l0.5,0.1c0.4,0.1,0.7,0.1,1,0.1
		c0.6,0,1.2,0,1.8,0c0.9,0,1.8-0.1,2.6-0.3c0.7-0.1,1.3-0.2,1.8-0.3l0.6-0.1l0.3-0.1h0.1l1,0.2l0.9,0.1c0.6,0,1,0.1,1.4,0.1
		c0.7,0,1.1,0,1.4,0s0.8,0,1.6-0.1c0.4-0.1,0.9-0.1,1.5-0.3c0.3-0.1,0.6-0.2,1-0.3l0.6-0.2l0.3-0.1l0.2-0.1l0.1,0.1l0.3,0.1
		c0.5,0.2,1.1,0.3,1.6,0.3c0.8,0.1,1.7,0.1,2.5,0c0.5-0.1,1-0.1,1.5-0.2l0.8-0.2l0.7-0.2l0,0l0,0l0.2,0c0.4,0.1,0.9,0.1,1.3,0.1
		c0.6,0,1.3-0.1,1.9-0.2c0.3-0.1,0.7-0.2,1-0.3l0.5-0.2l0.3-0.1h0.1h0.1c0.3,0,0.7,0,1,0c0.5,0,0.9-0.1,1.4-0.3
		c0.3-0.1,0.5-0.2,0.7-0.3c0.1-0.1,0.3-0.1,0.4-0.2l0.5,0l0,0c0.3,0,0.6,0,0.9,0c0.3,0,0.7-0.1,1-0.3l0.2-0.1c0.1,0,0.2,0,0.2-0.1
		l0.1-0.5c0.1-0.3,0.1-0.7,0.1-1v-0.6c0-0.6-0.1-1.3-0.2-1.9l-0.1-0.8l-0.2-0.8c-0.1-0.4-0.2-0.9-0.4-1.3c-0.5-0.1-1.1-0.1-1.6,0
		c-0.2,0.1-0.4,0.1-0.6,0.2l-0.3,0.1c-0.2,0.1-0.2,0.1-0.2,0.1l0,0h-0.2c-0.2,0-0.4-0.1-0.5-0.1c-0.3,0-0.6,0-0.9,0
		c-0.4,0-0.8,0.1-1.2,0.1c-0.3,0-0.7,0.1-1,0.3H208h-0.3c-0.2,0-0.4,0-0.6,0c-0.3,0-0.7,0-1,0.1c-0.6,0.1-1,0.1-1.4,0.2
		s-0.6,0.1-0.8,0.2l-0.3,0.1l-0.1,0.1l-0.7-0.1L202,221c-0.4,0-0.9,0-1.3,0c-0.6,0-1.3,0.1-1.9,0.2c-0.7,0.1-1.3,0.3-2,0.5l-0.3,0.1
		c-0.1,0.1-0.5,0.2-0.7,0.3l-0.3-0.1c-0.2-0.1-0.5-0.1-0.7-0.2c-0.7-0.2-1.4-0.3-2.1-0.3c-1-0.1-2-0.1-3.1,0
		c-0.6,0.1-1.3,0.1-1.9,0.2l-1.1,0.2l-0.9,0.2l-0.1-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.7-0.2-1.4-0.3-2.1-0.4c-0.9-0.1-1.9-0.1-2.8,0
		c-0.5,0-1.1,0.1-1.6,0.2l-0.8,0.1l-0.6,0.1l0,0c0,0,0.1,0-0.1,0c-0.3-0.1-0.5-0.2-0.8-0.3c-0.7-0.2-1.5-0.3-2.2-0.3
		c-1.4-0.1-2.7-0.1-4.1,0c-0.7,0.1-1.3,0.1-1.9,0.2l-0.9,0.1l-0.5,0.1h-0.1l0,0l-0.2-0.1c-0.2-0.1-0.4-0.1-0.6-0.2
		c-0.4-0.1-0.7-0.2-1.1-0.3c-0.7-0.2-1.4-0.3-2.1-0.3c-0.7,0-1.3,0-2,0c-0.3,0-0.7,0-1,0.1l-0.5,0.1h-0.1c-1.3-0.5-2.7-0.7-4.1-0.6
		l-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.3c-0.3-0.1-0.6-0.2-0.9-0.3c-0.3-0.1-0.5-0.1-0.8-0.1c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0
		c0-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.2-0.5-0.3C150.6,218.6,150.5,218.6,150.4,218.6
		c0,0.1,0.1,0.2,0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.2,0.1,0.3,0.2,0.5c0.3,0.1,0.5,0.1,0.8,0.2
		c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.4,0.2,0.6,0.3c0.3,0.2,0.5,0.4,0.7,0.6c0.2,0.1,0.5,0.1,0.7,0.1l0.5,0.1c0.3,0,0.7,0.1,1,0.1
		c0.3,0.1,0.7,0.1,1,0.2c0.2,0,0.3,0.1,0.5,0.2l0.2,0.1l0.5,0.3l0.7-0.1h0.4c0.3,0,0.6,0,0.9,0c0.6,0,1.2,0,1.8,0.1
		c0.6,0.1,1.2,0.2,1.8,0.3c0.3,0.1,0.6,0.2,0.9,0.3c0.1,0.1,0.3,0.1,0.4,0.2l0.7,0.4l0.7-0.1l0.4,0l0.9-0.1c0.6,0,1.2-0.1,1.9-0.1
		c1.3-0.1,2.5-0.1,3.8,0c0.6,0,1.3,0.1,1.9,0.3c0.2,0,0.4,0.1,0.6,0.2l0.6,0.2l1.1-0.2l0.8-0.1c0.5-0.1,1-0.2,1.5-0.2
		c0.9-0.1,1.7-0.2,2.6-0.2c0.6,0,1.2,0,1.8,0.1c0.2,0,0.4,0.1,0.5,0.1c0.2,0.1,0.3,0.1,0.5,0.1c6.4-1.7,9-1.1,10.2-0.8
		c0.3-0.1,0.6-0.3,0.8-0.4l0.3-0.1c0.6-0.2,1.3-0.3,1.9-0.4c0.6-0.1,1.2-0.1,1.8-0.1c0.4,0,0.8,0,1.2,0.1l0.7,0.1l0.8,0.1h0.3h0.3
		l0.8-0.1c0.4,0,0.8-0.1,1.3-0.1c0.3,0,0.6,0,0.9,0c0.2,0,0.3,0,0.5,0h0.2l0.4,0.1c0.4-0.1,0.8-0.1,1.2-0.2c0.3,0,0.7,0,1,0
		c0.2,0,0.5,0,0.7,0c0.1,0,0.2,0,0.4,0.1h0.1l0.2,0.1l0.4,0.1l0.3-0.2c0.1-0.1,0.3-0.2,0.5-0.2l0.2-0.1h0c0,0,0.1,0,0.1,0v0.1
		l0.1,1.1c0.1,0.5,0.1,1.1,0.1,1.6v0.4l0,0c-0.2,0-0.4,0-0.5,0l-0.5,0.2l-0.5,0.2c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0.1-0.2,0.1
		c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3,0.1-0.6,0.2-0.9,0.2c-0.2,0-0.5,0-0.7,0h-0.6l-0.4,0.2l-0.2,0.1l-0.4,0.2c-0.3,0.1-0.6,0.2-0.9,0.3
		c-0.5,0.1-1,0.2-1.5,0.3c-0.3,0-0.7,0-1,0h-0.4c-0.1,0-0.2,0-0.2,0c-0.3,0.1-0.6,0.2-0.9,0.3l-0.7,0.2c-0.5,0.1-0.9,0.2-1.4,0.3
		c-0.8,0.1-1.5,0.2-2.3,0.2c-0.5,0-1-0.1-1.5-0.1l-0.5-0.1c-0.1,0-0.2,0-0.2,0l-0.4,0.2l-0.3,0.1l-0.5,0.2c-0.3,0.1-0.6,0.2-0.9,0.3
		c-0.6,0.2-1,0.3-1.4,0.3c-0.8,0.1-1.2,0.2-1.5,0.2c-0.5,0-0.9,0-1.4,0c-0.4,0-0.8-0.1-1.3-0.1l-0.9-0.1l-1.2-0.3H186h-0.3l-0.6,0.1
		l-1.7,0.1c-0.7,0-1.6,0.1-2.6,0c-0.5,0-1.1,0-1.6-0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.2,0-0.3-0.1-0.4-0.1l-0.7-0.2
		c-0.4,0.1-0.9,0.2-1.4,0.2c-0.7,0.1-1.4,0.2-2.1,0.2c-1.9,0.1-3.8-0.2-5.6-0.8c-0.2-0.1-0.4-0.2-0.6-0.3l-0.9-0.4H166h-0.5l-1-0.1
		c-0.7-0.1-1.3-0.1-1.9-0.2c-0.6-0.1-1.2-0.2-1.8-0.4c-0.3-0.1-0.6-0.1-0.8-0.3s-0.3-0.1-0.4-0.1l-0.6-0.3h-1.3c-0.4,0-0.7,0-1-0.1
		c-0.3,0-0.7-0.1-1-0.1c-0.2,0-0.3-0.1-0.4-0.1c-0.2-0.1-0.3-0.1-0.4-0.2l-0.3-0.1h-0.3c-0.9,0-1.7-0.3-2.4-0.9l-0.1-0.1l-0.1-0.1
		c-0.4-0.2-0.8-0.5-1.1-0.8l-0.4-0.4c0,0-0.1-0.1-0.1-0.1v-0.2c0-0.7,0-1.5,0-2.2c0-0.5,0.1-0.9,0.2-1.3
		C150.3,218.8,150.4,218.7,150.4,218.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M212.5,225.8c-0.6,0-1-1-1.1-2.3s0.1-2.3,0.7-2.4s1.3,0.9,1.4,2.2C213.6,224.7,213.1,225.9,212.5,225.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M208.7,226.9c-0.6-0.1-0.9-1.3-1.1-2.8s0-2.8,0.6-3s1.3,1.1,1.5,2.8S209.2,227,208.7,226.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M203.7,227.7c-0.6,0-1-1.4-1.1-3s0.2-3,0.8-3.1s1.2,1.3,1.3,3S204.2,227.7,203.7,227.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M196.1,228.1c-0.6,0-0.9-1.3-1-2.8s0.2-2.8,0.8-2.9s1.2,1.2,1.3,2.8S196.7,228.1,196.1,228.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M186,228.8c-0.6-0.1-0.9-1.4-1-3s0.1-3,0.7-3.1s1.3,1.2,1.4,3S186.6,228.9,186,228.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M177.3,229.1c-0.6,0-1.1-1.4-1.2-3.1s0.3-3.1,0.9-3.2s1.1,1.4,1.2,3.1S177.8,229.1,177.3,229.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M166.4,228.3c-0.6,0.1-1.3-1.2-1.3-2.8s0.5-2.9,1.1-2.9s1,1.3,1,2.8S167,228.1,166.4,228.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M158.8,226.8c-0.6,0.1-1.3-1-1.3-2.5s0.4-2.6,1-2.6s1,1.2,1.1,2.5S159.4,226.7,158.8,226.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M154.4,226.1c-0.6,0.1-1.2-1.1-1.2-2.6s0.6-2.7,1.1-2.6s0.9,1.2,0.9,2.6S155,226,154.4,226.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M151.4,224.9c-0.6,0.1-1.2-1.1-1.1-2.6s0.7-2.6,1.3-2.5s0.8,1.3,0.8,2.6S152,224.8,151.4,224.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M162.5,313.4c-0.4-1.1-0.9-2.2-1.5-3.3c-0.1-0.1-0.1-0.2-0.2-0.3l-0.1-0.1l0,0l0,0l0.2-0.2l0.1-0.1
		c0.2-0.3-0.3,0.4-0.2,0.3l0,0l-0.1-0.1l-0.1-0.1l-0.2-0.2l-0.3-0.4c-0.3-0.4-0.6-0.9-0.8-1.4c-0.3-0.7-0.4-1.5-0.4-2.3
		c0.1-0.5,0.2-0.8,0.4-0.8c0.3,0,0.4,1.1,1.3,2.4c0.2,0.4,0.5,0.7,0.8,1c0.1,0.1,0.3,0.3,0.4,0.4c0.3,0.2,0.6,0.5,0.8,0.9
		c0.7,1.1,1.4,2.3,1.8,3.6C163.9,313.1,163.2,313.3,162.5,313.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M282,722.1c-0.9,0.4-5.7-9.5-9.8-22.3s-6.4-23.5-5.3-23.8c0.9-0.2,4.5,10,8.7,22.6S282.9,721.8,282,722.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M115.1,251.8c0.1,0,0.3,0.1,0.4,0.5c0.2,0.5,0.2,1,0.1,1.5c-0.1,0.4-0.3,0.8-0.5,1.1c-0.3,0.3-0.7,0.6-1.1,0.8
		c-0.4,0.2-0.9,0.3-1.3,0.3c-0.5,0.1-1,0-1.5-0.1c-0.3-0.1-0.6-0.2-0.8-0.4c-0.2-0.1-0.3-0.2-0.5-0.4c0,0,0,0-0.1-0.1
		s-0.4,0.3-0.8,0.4c-0.5,0.1-1,0.1-1.4-0.1c-0.6-0.2-0.9-0.8-1-1.4c0-0.4,0.1-0.6,0.2-0.6s0.3,0.1,0.5,0.3s0.5,0.4,0.7,0.3
		c0.1,0,0.2-0.1,0.3-0.1c0.2-0.2,0.5-0.5,0.8-0.7c0.6-0.3,1.4-0.2,1.9,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0,0.2,0.1,0.3,0.1
		c0.5,0.1,1.1,0.1,1.6,0c0.4,0,0.7-0.2,0.9-0.5C114.6,252.6,114.8,251.8,115.1,251.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M119.6,257.6c0.1,0.1,0.1,0.5-0.3,0.9c-0.2,0.3-0.5,0.5-0.9,0.7c-0.2,0.1-0.4,0.2-0.7,0.3
		c-0.3,0.1-0.6,0.1-0.9,0c-1,0-2.1-0.3-3.3-0.4c-0.6-0.1-1.2-0.2-1.8-0.2s-1.3,0-2,0c-1.4,0-2.8,0.1-4,0.1c-0.6,0-1.2,0-1.8,0
		c-0.6,0-1.1,0-1.7-0.1c-0.8-0.2-1.6-0.7-2.1-1.4c-0.3-0.5-0.4-0.8-0.3-0.9s0.4,0.1,0.8,0.3c0.6,0.3,1.2,0.5,1.8,0.5
		c0.3,0,0.9-0.1,1.4-0.1l1.7-0.2c1.2-0.1,2.6-0.2,4-0.3c0.7,0,1.5,0,2.2,0c0.7,0.1,1.3,0.2,2,0.4c1.2,0.3,2.3,0.6,3.1,0.9l0.5,0.1
		c0.2,0,0.3,0,0.4-0.1c0.3-0.1,0.5-0.2,0.8-0.3C119.2,257.7,119.4,257.5,119.6,257.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M117.7,258.8c0.2,0,0.7,1.6-0.3,3.9c-0.7,1.4-1.7,2.5-3,3.3c-0.7,0.5-1.6,0.9-2.4,1.1
		c-0.9,0.3-1.9,0.4-2.9,0.3c-1.8-0.3-3.5-1.1-4.9-2.3c-0.3-0.2-0.6-0.5-0.9-0.8c-0.3-0.3-0.5-0.5-0.8-0.8c-0.4-0.6-0.8-1.2-1-1.9
		c-0.3-0.9-0.5-1.9-0.4-2.9c0-0.7,0.2-1,0.3-1s0.3,0.4,0.5,1c0.3,0.8,0.6,1.6,1,2.4c0.3,0.5,0.6,0.9,1,1.3c0.2,0.2,0.4,0.4,0.7,0.6
		s0.6,0.4,0.8,0.6c1.2,0.9,2.5,1.5,4,1.7c1.4,0.1,2.9-0.2,4.1-0.9c1-0.6,1.9-1.4,2.6-2.4C117.2,260.3,117.3,258.8,117.7,258.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M117.1,258.9c0.1,0.1-0.5,0.9-2,1.2c-0.8,0.1-1.7,0.1-2.5,0.1c-0.9,0-1.9,0-3,0s-2.1,0-3-0.1
		c-0.9,0-1.7-0.1-2.5-0.5c-1.4-0.6-1.9-1.4-1.8-1.5s0.8,0.5,2,0.8c0.7,0.2,1.5,0.3,2.3,0.2c0.9,0,1.9,0,3,0c2.1,0,4.1,0.1,5.4,0.2
		C115.7,259.2,116.4,259.1,117.1,258.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M116.6,259.3c0.1,0,0.3,0.2,0.4,0.7c0,0.7-0.4,1.4-0.9,1.9c-0.7,0.7-1.6,1.2-2.6,1.4c-1.1,0.3-2.3,0.4-3.4,0.3
		c-1.2-0.1-2.3-0.4-3.4-0.8c-0.9-0.4-1.7-1-2.3-1.6c-1.1-1.1-1.5-2.1-1.2-2.3c0.5-0.4,2.9,2.5,7.1,2.6c1,0.1,1.9,0.1,2.9-0.1
		c0.7-0.1,1.5-0.3,2.1-0.7C116.2,260.2,116.3,259.3,116.6,259.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M89.1,255.9c0.1-0.1,0.7,0.8,1.9,2.1c1.7,1.9,3.6,3.7,5.6,5.4c1.3,1,2.7,2.1,4.3,3.3c1.6,1.2,3.3,2.3,5.1,3.3
		c0.9,0.5,1.9,0.7,2.9,0.8c0.5,0,1-0.1,1.5-0.2c0.5-0.1,0.9-0.3,1.3-0.6c2-1.4,3.8-2.9,5.4-4.7c0.9-0.9,1.7-1.8,2.5-2.7
		s1.6-1.8,2.3-2.7c1.4-1.8,2.5-3.7,3.5-5.7c0.9-1.8,1.6-3.7,2.2-5.7c0.5-1.7,0.8-3.4,0.9-5.1c0-1.5-0.2-2.9-0.5-4.3
		c-0.1-0.7-0.2-1.3-0.3-1.9c0-0.6,0.1-1.1,0.2-1.7c0.5-1.8,1-2.8,1.2-2.7s-0.1,1.1-0.4,2.9c-0.1,0.5-0.1,0.9,0,1.4
		c0.1,0.5,0.3,1.1,0.4,1.7c0.4,1.5,0.7,3,0.8,4.6c0,1.9-0.2,3.8-0.7,5.6c-0.5,2.1-1.2,4.1-2.1,6.1c-1,2.2-2.2,4.2-3.6,6.1
		c-0.8,1-1.6,1.9-2.4,2.9s-1.7,1.9-2.6,2.8c-1.7,1.9-3.7,3.6-5.8,5c-1.2,0.7-2.5,1.1-3.9,1c-1.3-0.1-2.5-0.4-3.6-1
		c-1.9-1-3.6-2.3-5.3-3.6c-1.6-1.2-3-2.4-4.2-3.5c-2-1.8-3.8-3.8-5.3-6C89.3,257,89,256,89.1,255.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M114.5,246.4c-0.3,0-0.6-0.5-0.7-1.5c-0.2-1.2,0-2.4,0.3-3.6c0.3-1.2,1-2.3,1.8-3.2c0.7-0.7,1.3-0.9,1.5-0.7
		c0.4,0.4-0.6,2.1-1.3,4.5S115.1,246.4,114.5,246.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M126.9,236.7c0,0-2.3-2-4.2-2s-3.9,0.8-3.8,1.6s2.8,0.1,3.8-0.1C124.2,236,125.6,236.1,126.9,236.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M92.9,235.9c0,0,1.9-2.4,6.3-2.6s8.2,0.9,8.2,2.1s-1.3,1.5-3.3,0.7s-2.9-1.3-5.3-1.3
		C96.8,234.8,94.8,235.2,92.9,235.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M88.9,257.4c0.1,0.1,0.5-0.2,0.7-0.9c0.2-1,0-2.1-0.6-3c-0.3-0.5-0.8-1.2-0.7-1.3c0.1-0.5,0.4-0.9,0.7-1.2
		c0.2-0.3,0.5-0.5,0.7-0.7l0.4-0.3c0.2-0.2,0.5-0.4,0.6-0.7c0.4-0.5,0.5-1.2,0.5-1.8c0-0.6-0.1-1.1-0.3-1.6
		c-0.3-0.7-0.7-1.4-1.2-2.1c-0.3-0.4-0.6-0.8-0.8-1.3c-0.2-0.7,0.5-1.7,1.1-2.6c0.3-0.5,0.5-1.1,0.5-1.7c0-0.5-0.1-0.9-0.2-1.4
		c-0.2-0.8-0.5-1.3-0.6-1.7c-0.1-0.6-0.2-1.1-0.3-1.7c0-0.8,0.3-1.5,0.9-2c0.4-0.3,0.8-0.5,1.2-0.6c0.4-0.2,0.8-0.2,1.3-0.1
		c0.2,0.1,0.5,0.2,0.7,0.3c0.2,0.1,0.4,0.2,0.7,0.3c0.3,0.1,0.7,0.1,1,0c0.2,0,0.3-0.1,0.4-0.2l0.3-0.2l0.4-0.3
		c0.3-0.1,0.3-0.1,0.4-0.1c0.2,0,0.4,0.1,0.6,0.3c0.4,0.3,0.9,0.4,1.4,0.5c0.4,0,0.9,0,1.3-0.2c0.3-0.1,0.6-0.2,0.9-0.2
		c0.2,0,0.4,0,0.5,0c0.2,0.1,0.4,0.1,0.6,0.3c0.5,0.3,1,0.6,1.5,0.9c0.3,0.2,0.7,0.2,1.1,0.2c0.4,0,0.7-0.2,0.9-0.4
		c0.1-0.1,0.2-0.2,0.3-0.3l0.1-0.1c0.1-0.1,0.1-0.2,0.3-0.2s0.3,0.2,0.6,0.4c0.2,0.2,0.4,0.3,0.6,0.5c0.3,0.2,0.6,0.3,0.9,0.3
		c0.4-0.1,0.7-0.2,1-0.3c0.3-0.1,0.6-0.1,0.9,0.1c0.1,0.1,0.3,0.2,0.4,0.4c0.2,0.3,0.5,0.5,0.8,0.6c0.7,0.3,1.5,0.3,2.2,0.1
		c0.2-0.1,0.4-0.1,0.5-0.2c0.2-0.1,0.4-0.3,0.4-0.2s0.1,0,0.2,0c0.2,0.1,0.3,0.2,0.4,0.3l0.1,0.1c0,0,0,0,0,0l0.1,0.2
		c0.2,0.2,0.4,0.4,0.7,0.5c0.4,0.2,0.9,0.3,1.4,0.3c0.3,0,0.6,0,0.9-0.1c0.2-0.1,0.4-0.2,0.5-0.3c0.1-0.1,0.2-0.2,0.3-0.3l0.1-0.1
		l0,0l-0.3-0.2l0,0l0,0l0.1,0.1c0,0,0.1,0.1,0.1,0.1l0,0l0,0l0.1-0.1c0.1-0.1,0.2-0.1,0.4-0.1c0.2-0.1,0.4-0.1,0.7,0
		c0,0,0.1,0,0.1,0l0,0l0,0l0.1,0.1c0.2,0.2,0.5,0.4,0.8,0.5c0.5,0.2,1,0.3,1.6,0.3c0.5,0,1-0.1,1.4-0.4l0.3-0.2l0.2-0.2
		c0,0,0.1-0.1,0.2-0.1c0.5-0.1,1.1,0,1.6,0.1c0.3,0,0.6,0,0.9,0c0.3,0,0.6,0,0.9-0.1c0.4-0.2,0.8-0.4,1.2-0.7c0.5-0.5,0.7-0.9,0.6-1
		s-0.4,0.1-1,0.3c-0.3,0.1-0.7,0.2-1,0.3c-0.2,0-0.4,0-0.6,0l-0.7-0.2c-0.7-0.2-1.4-0.4-2.1-0.3c-0.3,0-0.5,0.1-0.8,0.3l-0.2,0.1
		l-0.1,0.1c-0.2,0.1-0.5,0.1-0.7,0.1c-0.2,0-0.5-0.1-0.7-0.2c-0.1,0-0.2-0.1-0.2-0.2l-0.1-0.1l-0.2-0.2c-0.1-0.1-0.3-0.2-0.4-0.3
		c-0.6-0.3-1.2-0.3-1.8-0.2c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.7,0.3-0.9,0.6l-0.1,0.1l0,0l0,0l0.4,0.3l0,0l-0.1-0.1
		c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0-0.6-0.1H117l0,0l-0.1-0.2l-0.3-0.3c-0.4-0.3-0.8-0.6-1.3-0.7
		c-0.5-0.1-1.1,0-1.6,0.3c-0.3,0.2-0.2,0.2-0.4,0.3c-0.1,0.1-0.2,0.1-0.3,0.2c-0.3,0.2-0.7,0.3-1.1,0.2c-0.2,0-0.3-0.1-0.5-0.2
		c-0.2-0.2-0.5-0.4-0.8-0.5c-0.5-0.2-1.1-0.2-1.6,0.1c-0.4,0.1-0.8,0.4-0.9,0.4c-0.2,0-0.4,0-0.5-0.1c-0.2-0.1-0.4-0.3-0.5-0.5
		c-0.3-0.4-0.6-0.6-1-0.8c-0.3,0-0.5,0.1-0.7,0.3l-0.4,0.3l-0.2,0.2c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.3-0.1-0.4-0.2
		c-0.4-0.2-0.7-0.6-1.2-1c-0.3-0.2-0.6-0.4-1-0.6c-0.4-0.2-0.9-0.2-1.3-0.2c-0.6,0.1-1.2,0.2-1.7,0.2c-0.2,0-0.4-0.1-0.5-0.2
		c-0.4-0.3-0.9-0.5-1.4-0.6c-0.3,0-0.7,0-1,0.1c-0.2,0.1-0.5,0.2-0.7,0.3L95,229l-0.2,0.1l0,0c0,0,0,0.1-0.3-0.1
		c-0.4-0.2-0.7-0.3-1.1-0.4c-0.4-0.1-0.9-0.1-1.3,0.1c-0.3,0.1-0.7,0.2-1,0.4c-0.6,0.3-1.1,0.7-1.6,1.2c-0.4,0.5-0.7,1-0.8,1.6
		c-0.1,0.5-0.1,1,0,1.5c0.2,0.8,0.4,1.4,0.5,1.8c0.2,0.6,0.3,1.1,0.3,1.7c0.1,0.8-0.1,1.6-0.6,2.2c-0.4,0.4-0.8,0.9-1.1,1.4
		c-0.5,0.7-0.7,1.5-0.7,2.3c0.1,0.8,0.5,1.6,1,2.3c0.4,0.5,0.6,1,0.9,1.6c0.2,0.5,0.3,1,0.1,1.5c-0.1,0.1-0.1,0.2-0.2,0.2
		c-0.2,0.1-0.3,0.3-0.5,0.4c-0.3,0.3-0.6,0.6-0.9,1c-0.7,0.7-1,1.7-1,2.6c0.1,0.5,0.3,0.9,0.6,1.3l0.6,0.7c0.5,0.6,0.9,1.3,1,2.1
		C88.9,257,88.8,257.4,88.9,257.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M135.4,289.1c-0.2,0.3-1.8-0.5-4.4-1.9c-1.3-0.7-2.8-1.5-4.5-2.3s-3.7-1.7-5.6-2.7l-2.9-1.4l-1.4-0.7l-1-0.5
		c-0.1,0-0.1-0.1-0.2-0.1l-0.1-0.2l-0.1-0.3c-0.1-0.2-0.1-0.4-0.1-0.6c-0.3-1.7-0.4-3.5-0.4-5.2c0-2.9,0.1-4.8,0.4-4.8
		s0.6,1.8,1,4.6c0.2,1.4,0.4,3.2,0.8,4.9c0,0.1,0,0.1,0,0.1l0,0l0.4,0.2l1.4,0.6l2.9,1.4c2,1,3.9,1.9,5.6,2.9s3.2,1.9,4.4,2.7
		C134.2,287.6,135.6,288.8,135.4,289.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M54.9,375c0-0.1-0.8,0-2.1,0.6c-0.7,0.4-1.4,0.8-2.1,1.3c-0.8,0.6-1.6,1-2.6,1.3c-0.1,0-0.2,0-0.3,0l-0.2,0
		c-0.1-0.1-0.2-0.1-0.2-0.1c-0.1-0.1-0.2-0.2-0.3-0.2c-0.4-0.5-0.7-0.9-1-1.5c-0.7-1.3-1.3-2.6-1.8-4l-0.8-2.3l-0.9-2.4
		c-0.6-1.6-1.1-3.3-1.4-5c-0.3-1.4-0.5-2.8-0.6-4.1c-0.1-1.3,0-2.6,0.1-3.9c0.3-2.4,0.8-4.8,1.4-7.1c0.5-1.8,1.1-3.6,1.8-5.4
		c0.3-0.7,0.6-1.3,0.9-1.9s0.5-1.1,0.6-1.5c1.3-2.9,2.4-5.9,3.4-8.9c0.4-1.2,0.6-2.4,0.8-3.6c0.2-1.2,0.4-2.4,0.7-3.6
		c0.7-2.5,1.4-5.3,2.3-8.1l0.6-2.1c0.2-0.7,0.4-1.5,0.6-2.2c0.2-1.4,0.3-2.9,0.1-4.4c-0.1-0.7-0.2-1.4-0.3-2
		c-0.1-0.6-0.2-1.2-0.2-1.7c0-1.1,0.2-2.1,0.6-3.1c0.4-1,0.9-1.9,1.3-2.8c0.4-0.8,0.8-1.5,1.3-2.3c0.7-1,1.6-1.8,2.6-2.4
		c1-0.6,2.2-1.1,3.3-1.4c0.8-0.2,1.7-0.4,2.6-0.6c1.1-0.2,2.1-0.4,3.1-0.7c2.3-0.9,4.6-2,6.8-3.1c2.4-1.1,5-2.2,7.7-3.2l5-1.9
		c0.5-0.2,1-0.4,1.4-0.7c0.4-0.3,0.7-0.7,1-1.1c0.5-0.7,0.9-1.5,1.3-2.3c1-2.7,1.5-5.6,1.5-8.5c0.1-1.9,0-3.8-0.1-5.8
		c-0.2-1.4-0.4-2.1-0.6-2.1s-0.1,0.8-0.2,2.1s-0.2,3.3-0.5,5.6c-0.2,2.7-0.8,5.4-1.8,7.9c-0.3,0.7-0.7,1.3-1.1,1.9
		c-0.2,0.3-0.4,0.5-0.7,0.7c-0.3,0.2-0.6,0.3-0.9,0.4l-5,1.9c-2.7,1-5.3,2.2-7.8,3.5c-2.4,1.3-4.4,2.6-6.4,3.5
		c-0.9,0.4-1.9,0.7-2.9,0.9c-0.9,0.3-1.8,0.5-2.6,0.8c-1.2,0.4-2.3,1-3.4,1.7c-1.1,0.6-2.2,1.5-3,2.5c-0.5,0.7-1.1,1.5-1.5,2.3
		c-0.6,0.9-1.1,1.8-1.6,2.7c-0.6,1.1-0.9,2.4-1,3.6c0,0.7,0,1.3,0.1,2c0.1,0.6,0.1,1.3,0.1,1.9c0.1,1.3,0,2.6-0.2,3.9
		c-0.1,0.7-0.3,1.3-0.6,2l-0.7,2.1c-0.8,2.9-1.6,5.6-2.1,8.3c-0.3,1.3-0.5,2.7-0.5,4c0,1.1-0.2,2.2-0.4,3.3c-0.6,3.1-1.5,6.1-2.6,9
		c-0.2,0.5-0.4,0.9-0.7,1.4c-0.3,0.5-0.7,1.1-1.1,1.9c-0.9,1.7-1.6,3.5-2.3,5.4c-0.9,2.3-1.5,4.8-2,7.2c-0.3,1.4-0.3,2.9-0.3,4.3
		c0.1,1.5,0.3,3,0.6,4.5c0.4,1.8,0.9,3.5,1.6,5.3l1.8,4.7c0.6,1.4,1.3,2.8,2.1,4.2c0.4,0.7,0.9,1.2,1.4,1.8c0.3,0.3,0.7,0.5,1.1,0.7
		c0.2,0.1,0.5,0.1,0.7,0.1c0.2,0,0.4,0,0.6-0.1c0.6-0.2,1.2-0.5,1.8-0.9c0.5-0.3,0.9-0.7,1.2-1c0.7-0.6,1.3-1.1,1.9-1.5
		C54.3,375.6,54.9,375.2,54.9,375z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M85,279.7c0,0,8.7,11.7,20.4,11.3s16.9-10.3,16.9-10.3c1.4,0.1,2.6,0.8,3.3,1.9c0,0-8.3,10.9-20.8,10.7
		S83.1,282.5,82,281C82.7,280.1,83.8,279.6,85,279.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M85,279.7c0-0.1-0.5-0.5-1.5-0.3c-0.3,0.1-0.5,0.2-0.8,0.3l-0.2,0.1c-0.1,0.1-0.2,0.1-0.3,0.2
		c-0.2,0.1-0.4,0.3-0.5,0.5l-0.3,0.3c-0.1,0.1-0.3,0.2-0.1,0.3c0.1,0.2,0.3,0.5,0.4,0.7c0.4,0.5,0.6,0.8,0.9,1.1
		c2.8,3.3,6.1,6,9.9,8.1c1.7,0.9,3.5,1.7,5.4,2.2c0.9,0.2,1.8,0.4,2.6,0.5s1.6,0.2,2.4,0.2c2.2,0.1,4.3-0.2,6.4-0.6
		c2.1-0.3,4.2-0.9,6.2-1.7c3.4-1.4,6.4-3.5,9-6.1c0.5-0.5,0.9-1,1.3-1.4l0.4-0.4l0.3-0.4l0.4-0.5c-0.3-0.5-0.7-0.9-1-1.3
		c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.4-0.3-0.9-0.5-1.3-0.6c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.1
		c-0.2,0-0.5,0-0.7,0l-0.4,0.8l-0.3,0.5c-0.2,0.3-0.3,0.5-0.5,0.8c-0.3,0.5-0.7,1-1,1.4c-0.6,0.7-1.2,1.4-1.9,2.1l-0.8,0.8l-0.7,0.6
		c-0.3,0.3-0.7,0.5-1.1,0.8c-1.8,1-3.7,1.7-5.8,2.1c-3.3,0.6-6.8,0.3-10-0.7c-3.8-1.4-7.4-3.5-10.4-6.1
		C86.6,280.9,85.2,279.5,85,279.7s0.8,2,3,4.4c1.3,1.4,2.8,2.7,4.4,3.8c2,1.4,4.1,2.5,6.4,3.3c0.5,0.2,1,0.3,1.5,0.4l0.7,0.2
		l0.8,0.1l1.5,0.2h1.4c0.9,0,1.8-0.1,2.7-0.3c0.8-0.1,1.6-0.4,2.4-0.5c2.1-0.7,4-1.6,5.8-2.9c0.3-0.2,0.7-0.4,1.1-0.7l0.8-0.5
		l0.9-0.7c0.8-0.6,1.5-1.3,2.2-2c0.4-0.4,0.8-0.9,1.2-1.4c0.2-0.3,0.4-0.5,0.6-0.8l0.3-0.4l0.1-0.1c0,0,0.1,0,0.1,0.1h0.1l0.3,0.1
		c0.3,0.1,0.6,0.3,0.8,0.5c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0,0.1,0.1,0.1,0.1l0,0l0,0l-0.1,0.1c-0.3,0.4-0.7,0.8-1.2,1.3
		c-2.4,2.5-5.1,4.6-8.1,6.3c-1.8,1-3.7,1.8-5.8,2.3c-2.1,0.3-4.1,0.2-6.2-0.1c-0.7-0.1-1.4-0.3-2.2-0.5s-1.6-0.5-2.4-0.7
		c-1.7-0.6-3.4-1.4-5-2.3c-3.6-2-6.9-4.4-9.8-7.2c-0.3-0.3-0.6-0.6-0.6-0.6l-0.2-0.1c-0.1-0.1,0-0.1,0-0.2c0-0.1,0.1-0.1,0.1-0.2
		l0.1-0.1c0,0,0.1-0.1,0.1-0.1c0.2-0.1,0.3-0.3,0.5-0.4C84.5,279.8,85,279.8,85,279.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M60.2,376.5c-2.7,0.5-5.2,1.1-7,1.4s-3,0.4-3.1,0.2s0.9-0.9,2.7-1.6c2.3-0.9,4.6-1.6,7-2.1
		C60,375.1,60.1,375.8,60.2,376.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M104.8,292.3c1.5-0.1,3.5,1.1,3.7,4.7s-2.5,4.5-3.9,4.5c-2.2,0-3.9-1.7-3.9-3.9c0,0,0-0.1,0-0.1
		C100.8,294.4,103.1,292.5,104.8,292.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M104.8,292.3c0,0.1-0.3,0.3-0.9,0.6c-0.7,0.5-1.3,1.1-1.7,1.9c-0.3,0.5-0.5,1-0.6,1.6c-0.1,0.6-0.1,1.2,0,1.9
		c0.2,0.6,0.5,1.1,1,1.5c0.6,0.4,1.2,0.6,1.9,0.7c0.7,0,1.3-0.2,1.9-0.6c0.5-0.4,0.9-0.9,1-1.5c0.1-0.6,0.2-1.3,0.1-1.9
		c0-0.6-0.1-1.2-0.4-1.7c-0.3-0.8-0.8-1.4-1.5-1.9C105.2,292.5,104.8,292.5,104.8,292.3c0-0.1,0.4-0.4,1.2-0.3c1.1,0.2,2.1,1,2.6,2
		c0.4,0.7,0.6,1.4,0.7,2.1c0.2,0.9,0.2,1.7,0,2.6c-0.2,1.1-0.7,2-1.6,2.7c-0.9,0.7-2,1.1-3.2,1.1c-1.1,0-2.2-0.4-3.1-1.1
		c-0.8-0.7-1.4-1.6-1.6-2.7c-0.3-1.7,0.2-3.3,1.2-4.7c0.6-0.9,1.6-1.5,2.6-1.8C104.4,292,104.8,292.2,104.8,292.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M137.3,314.4c0.2-0.2-1.2-1.6-3.9-3.1c-3.5-2-7.5-3.1-11.6-3.3c-1.9-0.1-3.7,0.1-5.5,0.6c-1.5,0.4-3,1-4.4,1.7
		c-1,0.5-2.1,1-3.3,1.4c-0.7,0.2-1.5,0.4-2.2,0.4c-0.7,0-1.5,0-2.2-0.2c-1.1-0.3-2.2-0.7-3.2-1.3c-2.4-1.2-5.4-3.3-9.3-4
		c-1.8-0.3-3.7-0.4-5.6-0.1c-1.6,0.2-3.1,0.6-4.6,1.2c-1.9,0.7-3.7,1.6-5.4,2.6c-0.5,0.3-1.2,0.6-2.1,1.1c-0.4,0.3-0.9,0.5-1.5,0.9
		c-0.3,0.2-0.6,0.4-0.8,0.7c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.4l-0.2,0.5c-1.2,3.1-2.1,6.4-2.7,9.6
		c-0.4,2.2-0.6,4.5-0.4,6.7c0.2,1.8,0.7,3.6,1.4,5.3c1.1,2.9,2.3,4.4,2.5,4.3s-0.4-1.9-1.1-4.7c-0.4-1.6-0.7-3.3-0.8-5
		c-0.1-2.1,0.1-4.1,0.5-6.2c0.6-3.6,1.4-6.9,1.9-9.6l0.1-0.5c0,0,0-0.1,0.1-0.2c0.2-0.2,0.3-0.4,0.5-0.6c0.4-0.4,0.9-0.7,1.3-1
		c0.6-0.5,1.3-0.9,1.9-1.2c1.8-0.8,3.6-1.4,5.5-1.8c3.1-0.8,6.3-0.9,9.4-0.3c3.4,0.7,6.4,2.3,9.1,3.2c1.2,0.4,2.4,0.7,3.6,0.9
		c0.8,0.1,1.6,0.1,2.4,0c0.8,0,1.6-0.1,2.4-0.2c1.2-0.2,2.4-0.5,3.6-1c1.4-0.5,2.8-0.9,4.3-1.1c1.7-0.3,3.4-0.4,5.1-0.3
		c3.8,0.2,7.5,1.1,11,2.6C135.5,313.8,137.2,314.7,137.3,314.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M127.7,318.2c-0.1,0.1-0.8-0.4-2.2-0.8c-2-0.5-4.2-0.8-6.3-0.7c-1.4,0.1-2.8,0.4-4.2,0.8
		c-1.5,0.5-3,1.2-4.7,1.9c-0.9,0.4-1.9,0.7-2.8,0.9c-0.5,0.1-1.1,0.2-1.6,0.2H105c-0.3,0-0.6-0.1-0.8-0.1c-2.1-0.5-4-1.3-5.9-2.3
		c-1.8-0.8-3.7-1.5-5.6-2c-1.8-0.4-3.6-0.7-5.4-0.7c-3.1,0.1-6.2,0.7-9.1,1.8c-5.1,1.9-8,3.8-8.1,3.5s2.4-2.6,7.6-4.9
		c3-1.4,6.3-2.2,9.6-2.4c2-0.1,4,0.1,5.9,0.6c2.1,0.5,4.1,1.2,6,2.1c1.8,0.9,3.6,1.6,5.5,2.2c0.2,0,0.4,0.1,0.6,0.1h0.6
		c0.4,0,0.9-0.1,1.3-0.1c0.9-0.2,1.7-0.4,2.5-0.7c1.6-0.6,3.3-1.3,4.9-1.8c1.5-0.4,3.1-0.7,4.7-0.6c2.3,0,4.5,0.5,6.6,1.4
		C127.2,317.3,127.8,318.1,127.7,318.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M71,307.1c0.5-0.3,1.7,0.7,2.1,2.5s0,3.2-0.6,3.2s-1-1.2-1.4-2.6S70.5,307.5,71,307.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M69.6,323c0,0-4.5-3.8-12.8-3.8s-17.6,5.9-9.2,11.8c0.5-1.4,0.9-2.8,1.1-4.3c0.1-1.3,3.7-3.1,8.2-3.1
		s10.4,3.1,12,4C68.9,327.6,69.4,324,69.6,323z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M69.6,323c-0.2,0-0.4,0.4-0.7,1.2c-0.1,0.4-0.3,0.9-0.4,1.4l-0.1,0.4l-0.1,0.1c0,0.1-0.1,0.1-0.1,0.1L68,326
		c-0.5-0.3-1-0.5-1.5-0.8c-1.1-0.5-2.3-1-3.4-1.4c-2.2-0.8-4.6-1.1-7-0.9c-1.4,0.2-2.7,0.6-4,1.1c-0.3,0.1-0.7,0.2-1.2,0.4
		c-0.7,0.2-1.3,0.5-1.8,0.9c-0.5,0.3-0.8,0.8-0.9,1.3c-0.1,0.4-0.2,0.8-0.3,1.2c-0.1,0.4-0.3,0.9-0.5,1.4c-0.1,0.2,0,0.1-0.1,0.1
		l0,0h-0.1c0,0,0-0.1,0-0.1l0,0l0,0l-0.1-0.1l-0.2-0.2c-0.7-0.6-1.3-1.5-1.5-2.5c-0.1-0.8,0.1-1.7,0.6-2.4c0.7-1.1,1.7-2,2.8-2.6
		c1.3-0.7,5.5-1.6,10.3-1.1c2.6,0.2,5.1,0.7,7.5,1.6c1.9,0.7,3,1.2,3.2,1s-0.7-1.3-2.5-2.4c-2.5-1.4-5.2-2.2-8-2.4
		c-1.3-0.1-2.5-0.1-3.8,0.1c-1.1,0.2-2.2,0.4-3.2,0.8c-1.3,0.5-2.6,1.1-3.8,1.8c-1.3,0.5-2.5,1.3-3.6,2.3c-0.9,0.9-1.4,2.1-1.4,3.4
		c0.1,1.5,0.8,2.8,1.9,3.8l0.3,0.3l0.4,0.4c0.4,0.3,0.7,0.5,1,0.8c0.6,0.4,1,0.7,1.1,0.8l0.5-1.5l0.2-0.7c0.1-0.2,0.2-0.5,0.2-0.6
		c0.1-0.5,0.2-1,0.3-1.5c0.1-0.5,0.1-0.9,0.1-1.4c0-0.2,0.1-0.5,0.3-0.6c0.4-0.4,0.9-0.7,1.4-1c0.3-0.2,0.7-0.4,1.1-0.5
		c1.3-0.3,2.6-0.4,3.9-0.3c2.2,0.2,4.3,0.7,6.3,1.5c1.2,0.4,2.3,0.8,3.3,1.3l1.5,0.6l2.4,1.2l0.2-1.8l0.1-0.8v-0.5
		c0-0.6,0-1.1,0-1.5C69.9,323.5,69.8,323,69.6,323z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M66.8,343.4c0.1,0.3-1.3,0.7-3,1.9c-0.9,0.7-1.7,1.6-2.1,2.7c-0.6,1.3-0.8,2.8-0.8,4.3c0,1.4,0.3,2.9,0.8,4.2
		c0.5,1.1,1.1,2.3,1.7,3.3c1.2,1.9,2,3,1.8,3.2s-1.4-0.6-3-2.3c-0.9-1-1.7-2.2-2.3-3.4c-0.4-0.7-0.7-1.5-0.9-2.3
		c-0.1-0.4-0.2-0.9-0.3-1.3c0-0.5,0-0.9,0-1.4c0-1.8,0.4-3.5,1.2-5c0.7-1.3,1.7-2.4,3-3.1C65.2,342.9,66.7,343.1,66.8,343.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M70,342.2c0,0.3-0.8,0.7-1.6,2c-1.1,1.6-1.8,3.5-1.8,5.5c0,2,0.6,3.9,1.7,5.6c0.8,1.4,1.4,2.2,1.2,2.5
		s-1.2-0.3-2.4-1.6c-0.7-0.8-1.3-1.7-1.7-2.6c-0.6-1.2-0.9-2.5-0.8-3.9c0-1.3,0.3-2.6,0.9-3.9c0.5-1,1.1-1.9,1.9-2.6
		c0.5-0.5,1.2-0.9,1.9-1.1C69.6,341.9,70,342,70,342.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M91.7,344.3c0.1-0.1,0.7,0.3,1.9,0.7c0.3,0.1,0.5,0.1,0.8,0.1c0.2-0.1,0.4-0.3,0.3-0.5c0-0.4,0-0.8-0.2-1.2
		c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.1-0.3-0.4-0.5-0.7c-0.7-1-1.3-2.2-2-3.4c-0.3-0.6-0.6-1.1-1-1.7c-0.4-0.4-1-0.7-1.6-0.8l-2.3-0.8
		c-0.9-0.3-1.8-0.8-2.5-1.5c-0.4-0.4-0.7-0.7-0.9-0.9c-0.1-0.2-0.3-0.2-0.6-0.3h-0.2h-0.1c-0.1,0-0.2,0.1-0.4,0.2
		c-0.4,0.2-0.7,0.4-1,0.7c-1.3,1.1-2.5,2.3-3.8,3.5c-0.6,0.6-1.2,1.2-1.9,1.7c-0.3,0.3-0.7,0.6-1.1,0.8c-0.4,0.3-0.8,0.4-1.1,0.6
		c-1.2,0.5-2.1,1.4-2.6,2.6c-0.4,1.1-0.6,2.3-0.7,3.5c-0.1,1.7,0.3,3.5,1.2,5c0.6,1.1,1.1,1.7,1,1.8s-0.8-0.3-1.7-1.3
		c-1.3-1.6-2-3.5-2-5.5c0-0.7,0-1.3,0.1-2c0.1-0.7,0.2-1.4,0.5-2.1c0.3-0.8,0.7-1.5,1.3-2.2c0.6-0.6,1.3-1.1,2-1.5
		c0.4-0.2,0.7-0.4,1-0.6c0.3-0.2,0.6-0.4,0.9-0.7c0.6-0.5,1.2-1.1,1.8-1.7c1.2-1.2,2.4-2.5,3.9-3.7c0.4-0.3,0.8-0.6,1.3-0.9
		c0.3-0.1,0.6-0.3,0.9-0.4l0.6-0.1h0.4c0.7,0.1,1.3,0.4,1.8,0.8c0.4,0.4,0.6,0.7,0.9,1c0.5,0.5,1.1,0.9,1.8,1.1l2.2,0.9
		c0.4,0.1,0.8,0.3,1.2,0.5c0.5,0.2,0.8,0.6,1.1,1c0.4,0.6,0.8,1.3,1.1,2c0.6,1.3,1.2,2.4,1.7,3.4c0.1,0.3,0.2,0.4,0.4,0.8
		c0.1,0.3,0.3,0.6,0.3,0.9c0.1,0.6,0.1,1.2,0,1.8c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.3-0.3,0.4c-0.2,0.2-0.5,0.4-0.8,0.4
		c-0.5,0-0.9-0.1-1.3-0.3C92,345,91.6,344.4,91.7,344.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M89.6,350.6c0.1-0.1,0.5,0,1,0.2c0.3,0.1,0.7,0.2,1.1,0.3c0.4,0.1,0.8,0.1,1.2-0.1c0.4-0.1,0.5-0.3,0.5-0.6
		c-0.1-0.6-0.3-1.2-0.5-1.7c-0.3-0.7-0.7-1.3-1.1-1.9l-0.4-0.5c-0.2-0.2-0.3-0.4-0.4-0.6c-0.2-0.4-0.5-0.8-0.7-1.2l-1.1-2.3
		c-0.1-0.3-0.3-0.6-0.5-0.9c-0.2-0.2-0.4-0.4-0.7-0.5c-0.6-0.3-1.2-0.6-1.9-0.9c-0.6-0.3-1.2-0.5-1.8-0.9c-0.9-0.5-1.6-1.2-2.2-2
		c-0.4-0.6-0.4-1-0.3-1.1s1.1,1,3.1,1.8c0.5,0.2,1.1,0.3,1.8,0.5c0.7,0.2,1.5,0.5,2.2,0.8c0.5,0.2,0.9,0.5,1.2,0.9
		c0.3,0.4,0.6,0.8,0.8,1.2c0.4,0.8,0.8,1.6,1.2,2.3c0.2,0.4,0.4,0.8,0.6,1.1c0.1,0.2,0.2,0.3,0.3,0.5s0.3,0.4,0.4,0.5
		c0.5,0.7,0.9,1.5,1.2,2.3c0.4,0.8,0.5,1.7,0.5,2.5c0,0.5-0.3,1-0.6,1.4c-0.3,0.3-0.8,0.5-1.2,0.6c-0.6,0.1-1.3,0-1.9-0.3
		c-0.4-0.2-0.8-0.4-1.1-0.7C89.7,351,89.5,350.7,89.6,350.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M84,350.4c0.1-0.1,0.4,0,1,0.3c0.7,0.4,1.4,0.7,2.1,0.9c0.4,0.1,0.9,0.1,1.3-0.1l0.2-0.1l0,0c0,0,0,0,0-0.1
		s0,0,0-0.1s0-0.1-0.1-0.2c-0.1-0.3-0.2-0.5-0.4-0.7c-0.1-0.1-0.2-0.2-0.3-0.4l-0.4-0.4c-0.5-0.7-1-1.4-1.4-2.2
		c-0.4-0.8-0.8-1.5-1.1-2.2l-0.5-1c-0.1-0.2-0.2-0.4-0.4-0.5c-0.5-0.4-1-0.7-1.6-1c-0.6-0.3-1.1-0.6-1.6-0.9
		c-1.9-1.3-2.8-2.3-2.6-2.5s1.4,0.4,3.3,1.2c0.5,0.2,1,0.4,1.6,0.7c0.7,0.3,1.4,0.6,2,1c0.4,0.3,0.8,0.7,1.1,1.1l0.5,1l1.1,2.1
		c0.3,0.7,0.7,1.3,1.1,1.9l0.4,0.4c0.1,0.2,0.3,0.4,0.4,0.6c0.2,0.4,0.4,0.8,0.5,1.3c0.1,0.3,0.1,0.6,0.1,0.8c0,0.3-0.1,0.6-0.3,0.9
		c-0.2,0.3-0.4,0.5-0.7,0.6c-0.1,0.1-0.3,0.1-0.4,0.2l-0.3,0.1c-0.7,0.2-1.5,0.1-2.2-0.2c-0.9-0.3-1.7-0.9-2.3-1.7
		C84,350.9,83.9,350.5,84,350.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M73.8,347.6c0.1-0.2,1.4,0.2,3.3,0.9l3.4,1.3c0.6,0.2,1.2,0.4,1.9,0.5h0.2l0,0l0,0l-0.3-0.4l-0.7-0.9l-1.3-1.7
		c-0.3-0.5-0.6-0.9-1.1-1.2c-0.4-0.3-0.9-0.6-1.4-0.9c-0.8-0.5-1.4-1.1-1.9-1.9c-0.3-0.5-0.4-0.9-0.3-1s0.4,0.1,0.9,0.4
		c0.6,0.5,1.3,0.8,2,1.1c0.5,0.2,1,0.4,1.6,0.7c0.7,0.4,1.2,0.9,1.7,1.5l1.4,1.6l0.7,0.9l0.4,0.5c0.3,0.3,0.4,0.6,0.5,1
		c0.2,0.8-0.1,1.6-0.8,2c-0.3,0.2-0.6,0.3-1,0.4c-0.2,0-0.4,0-0.6,0l-0.4-0.1c-0.8-0.2-1.5-0.4-2.2-0.8c-1.1-0.5-2.2-1.1-3.3-1.7
		C74.7,348.7,73.7,347.8,73.8,347.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M60.2,349.5c-2,0-4,0.2-5.8,0.8c-1.6,0.5-3,1.2-4.4,2c-2.3,1.6-3.4,3.1-3.7,2.9s0.4-2,2.8-4.1
		c1.4-1.2,3-2.1,4.7-2.7c2.1-0.7,4.3-1.1,6.6-1C60.4,348.2,60.4,348.9,60.2,349.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st1"
            d="M79,223.4c0,0-0.4-2.2,0.4-3c0,0,17.3,5,32,5.1s18.6-0.4,20.8-1.3c0,0,3.2,2.7,1.4,3c-2,0.3-8.8,1.7-22,1.2
		C100.6,227.9,89.7,226.2,79,223.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M79,223.4c-0.2,0.5,8.4,3.4,19.3,4.9c6.3,0.8,12.7,0.9,19,0.6c1.1,0,3,0.1,5.7,0.1c1.4,0,2.9,0,4.6-0.1
		c0.9,0,1.8-0.1,2.7-0.2l1.4-0.2l0.7-0.1l1.1-0.2c0.2-0.1,0.4-0.1,0.6-0.2c0.3-0.2,0.6-0.5,0.7-0.9c0.1-0.5,0-1.1-0.3-1.5
		c-0.1-0.1-0.2-0.3-0.3-0.4l-0.3-0.3l-0.4-0.4l-0.3-0.3l-0.4-0.4c-0.2-0.2-0.4-0.4-0.6-0.5c0,0-0.1-0.1-0.2-0.1l-0.2,0.1l-0.4,0.1
		c-0.3,0.1-0.7,0.2-1,0.3c-0.2,0.1-0.5,0.1-0.7,0.2c-1,0.2-2,0.3-3,0.5c-2,0.2-3.8,0.4-5.4,0.5c-3.3,0.3-5.8,0.4-7.1,0.4
		s-3.8-0.1-7-0.5s-7.3-1-11.7-1.7c-3.4-0.6-6.6-1.2-9.4-1.7l-3.8-0.8l-1.6-0.3l-0.8-0.2l-1.1-0.2c-0.5,0.5-0.7,1.1-0.8,1.7
		c0,0.4,0,0.7,0.1,1.1C78.7,223.2,78.8,223.4,79,223.4c0,0,0.1-0.3,0.1-0.9c0-0.3,0.1-0.6,0.2-0.9c0-0.1,0.1-0.2,0.1-0.3
		c0.1-0.1,0.1-0.2,0.2-0.2l0.3,0.1l0.7,0.2l1.6,0.5c1.1,0.4,2.4,0.7,3.8,1.1c2.8,0.7,6,1.4,9.4,2c3.9,0.7,7.9,1,11.9,1
		c3.4,0,5.9-0.2,7.1-0.2s3.8,0.2,7.1,0.3c1.7,0,3.5,0.1,5.6,0c1.1,0,2.1-0.2,3.2-0.3c0.3,0,0.6-0.1,0.8-0.2l0.6-0.2l0,0l0.4,0.5
		c0.1,0.1,0.2,0.2,0.3,0.3l0.1,0.1l0,0l0,0h-0.1l-0.4,0.1l-0.7,0.1l-1.4,0.3l-2.6,0.4c-1.7,0.2-3.2,0.4-4.5,0.5
		c-2.7,0.3-4.6,0.4-5.7,0.4c-2.5,0-10-0.8-18.8-2C87.8,224.8,79.1,222.9,79,223.4z"
          />

          <ellipse
            transform="matrix(0.1597 -0.9872 0.9872 0.1597 -129.946 300.864)"
            class="st1"
            cx="111.8"
            cy="226.8"
            rx="3.4"
            ry="3.2"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M108.6,226.2c0.1,0,0.2,0.3,0.4,0.8c0.2,0.6,0.5,1.1,1,1.6c0.6,0.6,1.4,0.8,2.2,0.5c1.4-0.5,2.1-2,1.6-3.3
		c-0.2-0.5-0.5-0.9-0.9-1.2c-0.7-0.5-1.5-0.5-2.2-0.2c-0.6,0.3-1,0.7-1.4,1.2C109,226,108.8,226.3,108.6,226.2
		c-0.1,0-0.2-0.3-0.1-0.9c0.2-0.9,0.7-1.6,1.4-2.1c1.2-0.8,2.7-0.9,4-0.2c2.1,1.3,2.7,4,1.5,6.1c-0.6,1-1.6,1.7-2.8,2
		c-0.8,0.2-1.6,0.1-2.3-0.3c-0.6-0.3-1.1-0.7-1.5-1.2c-0.5-0.7-0.8-1.6-0.6-2.5C108.3,226.5,108.5,226.2,108.6,226.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M134,227.2c0.1-0.1,0.4,0.1,0.8,0.6c0.2,0.3,0.4,0.7,0.5,1c0.2,0.5,0.3,1.1,0.2,1.6c0,0.1-0.1,0.5-0.1,0.5
		c0,0.1,0,0.1,0,0.2c0,0.2,0.1,0.4,0.1,0.6c0.2,0.5,0.5,1,0.8,1.4l0.3,0.4c0.1,0.1,0.3,0.3,0.4,0.5c0.3,0.4,0.5,0.7,0.7,1.2
		c0.2,0.5,0.3,1,0.3,1.5c0,0.5-0.1,0.9-0.3,1.4c-0.6,1.7-0.6,1.9,0.4,3c0.6,0.6,1,1.3,1.1,2.2c0.1,0.6,0.1,1.2,0,1.8
		c-0.1,0.4-0.1,0.8,0,1.2c0,0.3,0.1,0.6,0.3,0.8c0.2,0.5,0.4,0.6,0.4,0.8s-0.5,0.2-1-0.2c-0.3-0.3-0.6-0.6-0.8-1
		c-0.2-0.5-0.4-1-0.4-1.5c0-0.5-0.1-1-0.2-1.5c-0.1-0.4-0.4-0.8-0.7-1.1c-0.7-0.6-1.2-1.4-1.5-2.2c-0.1-0.9,0-1.9,0.4-2.7
		c0.2-0.5,0.2-1,0.1-1.5c-0.1-0.3-0.2-0.5-0.4-0.7c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.2-0.3-0.3-0.5c-0.4-0.6-0.7-1.3-0.9-2
		c-0.1-0.4-0.1-0.8-0.1-1.1c0-0.2,0.1-0.4,0.1-0.6c0.1-0.3,0.1-0.1,0.1-0.3c0.1-0.4,0.1-0.7,0.1-1.1c0-0.4-0.1-0.7-0.1-1
		C133.9,227.6,133.9,227.3,134,227.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M79.4,220.4c0.1,0,0.2-0.4,0.2-1c0-0.7,0.2-1.5,0.6-2.1c0.6-0.9,1.5-1.5,2.6-1.8c1.8-0.8,2.6-2.5,3.4-3.9
		c0.1-1.8,4.1,0,5.9-2.1c0.8-0.8,1.3-1.5,1.8-1.9c0.4-0.3,0.9-0.5,1.4-0.4c0.5,0,1.1,0.1,1.6,0.2c0.4,0.1,0.8,0.1,1.2,0.2
		c0.5,0.1,1.1,0.1,1.6-0.1c0.5-0.2,1.1-0.4,1.6-0.6c0.4-0.2,0.9-0.4,1.3-0.5c0.5,0.1,0.9,0.2,1.3,0.4c0.7,0.3,1.5,0.4,2.3,0.4
		c0.4,0,0.8-0.1,1.1-0.1c0.3-0.1,0.5-0.1,0.8-0.1c0.5,0,1,0,1.5,0.1c0.5,0.1,0.9,0.3,1.3,0.6l0.4,0.3c0.2,0.1,0.3,0.2,0.5,0.2
		c0.3,0.1,0.6,0.2,0.9,0.2c2.1,0.3,3.7,0,4.1,0.5c0.4,0.3,0.7,0.8,0.9,1.2l0.3,0.5c0.1,0.3,0.3,0.5,0.6,0.6c0.5,0.2,1,0.4,1.5,0.6
		c0.5,0.1,1,0.3,1.5,0.5s1,0.4,1.1,0.6l1.2,1.4c0.5,0.6,1,1.1,1.6,1.5c0.6,0.4,1.2,0.9,1.8,1.4c0.2,0.2,0.4,0.4,0.6,0.6l0.1,0.1
		c0,0.1,0.1,0.1,0.1,0.2c0.1,0.2,0.2,0.5,0.4,0.7c0.5,0.6,1,1.1,1.5,1.6c0.5,0.4,0.9,0.8,1.3,1.1c0.1,0.1,0.3,0.3,0.4,0.4
		c0.1,0.1,0.2,0.3,0.2,0.5c0.1,0.3,0.2,0.7,0.2,1c0.1,0.6,0.1,0.9,0.3,1s0.4-0.3,0.5-0.9c0.1-0.4,0.1-0.8,0.1-1.2
		c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.3-0.3-0.6-0.5-0.8c-0.4-0.5-0.8-0.9-1.2-1.4c-0.4-0.5-0.8-0.9-1.1-1.4c-0.1-0.1-0.1-0.2-0.1-0.3
		l-0.1-0.3c-0.1-0.1-0.1-0.3-0.2-0.4c-0.2-0.4-0.5-0.8-0.9-1.1c-0.6-0.6-1.3-1.1-2-1.6c-0.5-0.3-0.9-0.7-1.3-1.2
		c-0.4-0.4-0.9-0.9-1.3-1.4c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.4-0.2-0.5-0.2c-0.3-0.1-0.6-0.2-0.9-0.2c-0.6-0.1-1.1-0.1-1.6-0.2
		c-0.4,0-0.8-0.1-1.2-0.2c-0.2,0-0.4-0.4-0.7-0.7c-0.3-0.5-0.8-0.9-1.3-1.3c-0.2-0.2-0.5-0.3-0.7-0.4c-0.3-0.1-0.6-0.2-1-0.2
		c-0.8-0.2-1.6-0.4-2.4-0.6c-0.2-0.1-0.4-0.2-0.6-0.3c-0.1,0-0.2-0.1-0.2-0.1l-0.1-0.1l-0.2-0.2c-0.5-0.5-1.1-0.8-1.8-1.1
		c-0.7-0.2-1.4-0.4-2.1-0.4c-0.4,0-0.7,0-1.1,0.1c-0.4,0.1-0.6,0.1-0.9,0.1c-0.5,0-1,0-1.5-0.2c-0.7-0.3-1.5-0.4-2.3-0.3
		c-0.7,0.2-1.3,0.5-1.8,0.9c-0.4,0.3-0.8,0.5-1.2,0.8c-0.7,0.3-1.5,0.4-2.3,0.3h-1.7c-0.6,0-1.3,0.1-1.8,0.4c-0.7,0.4-1.4,1-2.1,1.5
		c-0.4,0.2-0.7,0.4-1.2,0.4c-0.2,0-0.5,0-0.7,0h-0.8c-0.7-0.1-1.4-0.1-2.1,0c-0.5,0.1-1,0.3-1.3,0.7c-0.3,0.3-0.5,0.7-0.7,1.1
		c-0.8,1.5-1.5,2.7-2.4,3.2c-0.7,0.3-1.3,0.7-1.9,1.2c-0.5,0.5-1,1.1-1.2,1.7c-0.3,0.9-0.3,1.9,0,2.9
		C79,220.2,79.3,220.4,79.4,220.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M61.5,290c0.1-0.1-0.1-0.5-0.2-1c0-0.8,0.4-1.5,1-2.1c0.4-0.4,0.8-0.9,1.4-1.3c0.3-0.3,0.5-0.5,0.8-0.8
		c0.4-0.4,0.7-0.9,0.8-1.5c0.1-0.6-0.1-1.3-0.4-1.8c-0.3-0.4-0.6-0.8-0.8-1.1s-0.5-0.7-0.8-1c-0.2-0.2-0.3-0.4-0.3-0.7
		c0-0.1,0-0.3,0.1-0.4c0.2-0.2,0.4-0.4,0.6-0.6l0.8-0.7l0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.5c0.5-0.7,0.7-1.6,0.5-2.5
		c-0.1-0.7-0.5-1.4-0.9-1.9c-0.2-0.2-0.4-0.4-0.6-0.5c-0.1-0.1-0.2-0.3-0.2-0.5c-0.1-0.4-0.1-0.8,0-1.2c0.1-0.6,0.2-1.2,0.5-1.7
		c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.3-0.2,0.6-0.5,0.9-0.8c0.3-0.3,0.7-0.7,0.9-1.1c0.2-0.3,0.3-0.6,0.4-0.9
		c0.1-0.4,0-0.9-0.2-1.3c-0.3-0.5-0.5-1-0.8-1.5c-0.2-0.5-0.3-1-0.3-1.5c-0.1-0.6,1-1.6,2.1-2.7c0.3-0.3,0.6-0.5,0.8-0.8
		c0.4-0.3,0.6-0.7,0.9-1.2c0.2-0.5,0.3-1.1,0.1-1.7c-0.1-0.4-0.3-0.8-0.5-1.1l-0.5-0.9l-0.2-0.4c0-0.1-0.1-0.2-0.1-0.3
		c-0.1-0.4,0-0.8,0.2-1.2c0.4-0.8,1-1.6,1.6-2.2c0.3-0.3,0.5-0.7,0.6-1.1c0.1-0.3,0.1-0.6,0-0.9c-0.1-0.5-0.3-1.1-0.5-1.9
		c-0.1-0.3-0.1-0.7,0.1-1c0.2-0.4,0.6-0.7,0.9-0.9c0.4-0.3,0.9-0.6,1.4-0.9l0.4-0.3c0.2-0.1,0.3-0.2,0.5-0.4
		c0.4-0.3,0.6-0.8,0.8-1.3c0.1-0.8,0-1.7-0.3-2.5l-0.1-0.5v-0.2c0,0,0,0,0-0.1c0-0.2,0.1-0.4,0.2-0.5c0.4-0.7,1-1.3,1.6-1.8l0.5-0.5
		l0.2-0.2l0.3-0.3c0.4-0.5,0.6-1,0.7-1.6c0.1-0.8,0-1.7-0.2-2.5c-0.1-0.7-0.2-1,0-1.4c0.2-0.4,0.5-0.7,0.9-1
		c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.7,0-1.1-0.2-1.1s-0.3,0.3-0.6,0.9
		c-0.1,0.1-0.2,0.3-0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.2,0.1c-0.6,0.3-1.1,0.7-1.5,1.2c-0.3,0.4-0.4,0.8-0.5,1.2c0,0.4,0,0.8,0,1.2
		c0.1,0.7,0.1,1.3,0,2c0,0.2-0.1,0.5-0.3,0.6l-0.1,0.1l-0.2,0.2l-0.5,0.4c-0.8,0.6-1.5,1.4-2,2.3c-0.3,0.5-0.5,1-0.5,1.6
		c0,0.2,0,0.3,0.1,0.5l0.1,0.3c0.1,0.2,0.1,0.4,0.2,0.6c0.2,0.5,0.3,1,0.4,1.6c0,0.3-0.2,0.5-0.8,1c-0.5,0.4-0.9,0.8-1.4,1.2
		c-0.4,0.4-0.8,0.9-1,1.5c-0.1,0.3-0.1,0.6-0.1,0.9c0,0.3,0.1,0.5,0.2,0.7c0.3,0.5,0.5,1.1,0.7,1.7c0.1,0.5-0.1,1-0.6,1.3
		c-0.8,0.6-1.6,1.3-2.2,2.2c-0.5,0.6-0.7,1.4-0.6,2.2c0,0.2,0.1,0.4,0.1,0.6c0.1,0.2,0.1,0.3,0.2,0.5l0.4,0.9
		c0.1,0.2,0.2,0.5,0.3,0.8c0,0.1,0,0.3-0.1,0.4c-0.4,0.5-0.8,0.9-1.3,1.3c-0.6,0.5-1.1,1.1-1.6,1.7c-0.3,0.3-0.5,0.7-0.7,1.1
		c-0.1,0.2-0.2,0.5-0.2,0.8c0,0.2,0,0.5,0,0.7c0.1,0.8,0.3,1.5,0.7,2.2c0.3,0.6,0.6,1.1,1,1.5c0.1,0.1,0.1,0.2,0.1,0.3
		c0,0.2-0.1,0.4-0.1,0.6c-0.2,0.4-0.4,0.7-0.6,1.1c-0.2,0.3-0.4,0.5-0.7,0.8c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.1-0.2,0.2-0.2,0.3
		c-0.3,0.6-0.6,1.2-0.8,1.8c-0.2,0.5-0.2,1-0.2,1.5c0,0.3,0.1,0.7,0.2,1c0.3,0.4,0.4,0.5,0.5,0.7c0.5,0.7,0.5,1.8,0,2.5
		c-0.1,0.1-0.2,0.2-0.2,0.2l-0.4,0.3l-0.8,0.7c-0.3,0.3-0.7,0.6-0.9,0.9c-0.4,0.5-0.6,1.1-0.6,1.7c0.1,0.7,0.3,1.3,0.7,1.8
		c0.3,0.4,0.6,0.8,0.9,1.1s0.6,0.6,0.7,0.9s0.2,0.4,0.2,0.5s-0.6,0.8-1,1.3c-0.5,0.5-0.9,1.1-1.3,1.6c-0.4,0.5-0.6,1.1-0.8,1.7
		c-0.1,0.5-0.1,1,0.1,1.5c0.1,0.3,0.3,0.5,0.6,0.7C61.3,290,61.4,290,61.5,290z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M127.7,241.6c0,0.1-0.2,0.3-0.6,0.3c-0.5-0.1-0.9-0.4-1.3-0.7c-1-0.7-2.1-1-3.3-1c-1.2-0.1-2.4,0.2-3.4,0.8
		c-0.8,0.5-1.3,1-1.5,0.9s-0.3-1,0.5-2c0.5-0.6,1.1-1,1.8-1.3c0.8-0.3,1.7-0.5,2.6-0.5c0.9,0,1.8,0.2,2.6,0.6
		c0.7,0.3,1.2,0.8,1.7,1.4c0.3,0.5,0.5,0.8,0.6,1S127.7,241.4,127.7,241.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M126.9,241.4c0.2,0.2,0,0.9-0.8,1.6c-1.1,0.9-2.4,1.4-3.8,1.5c-1.5,0.2-2.9-0.3-4-1.3
		c-0.8-0.8-0.9-1.6-0.6-1.7s0.7,0.2,1.4,0.5c1,0.4,2,0.5,3,0.4C124.7,242.3,126.5,241,126.9,241.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M92.8,240.5c0-0.3,0.8-0.4,1.8-1c0.5-0.3,1.1-0.7,1.8-1.2c0.9-0.6,1.8-1,2.9-1.2c1.1-0.2,2.2-0.1,3.2,0.2
		c0.8,0.3,1.6,0.8,2.2,1.4c1,1.1,1.1,2,0.9,2.2s-0.8-0.4-1.8-1c-1.2-0.8-2.7-1-4.1-0.7c-0.8,0.2-1.6,0.4-2.3,0.8
		c-0.7,0.4-1.5,0.7-2.2,0.9C93.6,241.3,92.7,240.8,92.8,240.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M105.2,240.6c0.3,0.1,0.1,1-0.9,1.8c-1.2,0.9-2.8,1.4-4.3,1.3c-1.6,0-3-0.6-4.2-1.6c-0.9-0.8-1.1-1.5-0.9-1.7
		c0.4-0.4,2.4,1.2,5.1,1.3c1.2,0,2.4-0.1,3.5-0.5C104.4,240.8,104.9,240.5,105.2,240.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M124.8,241.4c0,1.1-0.9,2-2,2c-0.1,0-0.1,0-0.2,0c-1.1,0.1-2.2-0.7-2.3-1.8c0-0.1,0-0.1,0-0.2
		c0-1.2,1-2.3,2.3-2.3S124.8,240.2,124.8,241.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M103.3,240.6c0,1.1-0.9,2.1-2,2.1c-0.1,0-0.2,0-0.3,0c-1.2,0.1-2.2-0.7-2.4-1.9c0-0.1,0-0.1,0-0.2
		c0-1.3,1-2.3,2.3-2.3S103.3,239.3,103.3,240.6L103.3,240.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M139.3,314.5c0.1-0.3-1.1-0.7-3-0.4c-2.4,0.5-4.6,1.5-6.4,3.1l-0.1,0.1h-0.1h-0.2c-0.3,0-0.5,0-0.8,0
		c-0.5,0-1,0-1.5,0.1c-0.9,0.1-1.7,0.4-2.6,0.7c-1,0.5-2,1-2.9,1.7l-0.8,0.5c-0.2,0.1-0.4,0.3-0.7,0.5s-0.3,0.2-0.4,0.3l-0.3,0.3
		l-0.2-0.1l-0.1-0.1L119,321c-0.2-0.1-0.4-0.2-0.7-0.2l-0.8-0.2c-0.7-0.1-1.3-0.2-2-0.1c-0.8,0.1-1.6,0.2-2.3,0.4
		c-2.8,1-5.1,2.9-6.5,5.4c-0.2,0-0.5,0.1-0.7,0.1c-1.2,0-2.4,0.2-3.5,0.5c-2,0.6-3.7,1.7-5.1,3.3c-0.2,0.3-0.4,0.5-0.6,0.8l-0.3,0.4
		c0,0,0,0,0,0l0,0l-0.4,0.1l-0.8,0.2c-0.5,0.1-1,0.3-1.5,0.5c-0.8,0.3-1.6,0.8-2.3,1.3c-1.2,0.9-1.6,1.8-1.4,2s0.9-0.3,2.1-0.8
		c0.7-0.3,1.4-0.6,2.2-0.8c0.4-0.1,0.9-0.2,1.3-0.3l0.7-0.1l1.2-0.2c0.1-0.3,0.3-0.5,0.5-0.8l0.3-0.3c0.2-0.2,0.4-0.5,0.6-0.7
		c1.2-1.4,2.5-2.5,4.1-3.4c0.9-0.6,1.9-1,3-1.1l1-0.1c0.1-0.1,0.3-0.3,0.4-0.5c0.2-0.2,0.4-0.3,0.6-0.5c0.5-0.4,1-0.7,1.5-1.1
		c1.3-0.9,2.8-1.6,4.3-2.1c0.6-0.2,1.3-0.3,1.9-0.5s1.2-0.2,1.7-0.2h0.8h0.6h0.3h0.4c0.2,0,0.3,0,0.5,0c0.2-0.3,0.4-0.5,0.6-0.6
		l0.4-0.4l0.6-0.5c0.2-0.2,0.4-0.3,0.7-0.5c0.9-0.5,1.9-0.8,2.9-1c0.8-0.1,1.5-0.2,2.3-0.2c0.4,0,0.9,0,1.3,0.1
		c0.2,0,0.5,0.1,0.7,0.1l1.2,0.2l0.8-0.6c1.6-1.4,3.5-2.5,5.4-3.3C138.2,314.9,139.2,314.8,139.3,314.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M139,322.2c-0.2-0.2-0.9,0.3-2.2,0.5c-0.7,0.2-1.5,0.3-2.3,0.3c-0.4,0-0.9,0-1.4,0c-0.7,0-1.4-0.1-2.1-0.2
		l-0.8,1.1c-1.1,1.1-2.5,1.9-3.9,2.4c-0.9,0.4-1.9,0.6-2.9,0.8c-0.1,0-0.3,0-0.6,0h-0.5l-1-0.1c-0.2,0.1-0.6,0.5-0.9,0.6
		s-0.7,0.5-1.2,0.7c-1.1,0.6-2.3,1.1-3.5,1.5c-1.4,0.5-2.7,0.9-3.7,1.2s-1.8,0.6-2.4,0.7l-0.5,0.8c-0.1,0.1-0.2,0.3-0.3,0.4
		l-0.3,0.4c-0.8,0.7-1.6,1.2-2.6,1.5c-1.5,0.6-3.1,1-4.7,1.3l-0.7,0.1l-0.5,0.1l-0.7,0.1l-0.6,1.1l-0.4,0.5
		c-0.2,0.3-0.5,0.6-0.8,0.9c-0.5,0.5-1,1-1.6,1.4c-1,0.7-1.7,1-1.6,1.3s0.9,0.4,2.3-0.1c0.8-0.3,1.5-0.7,2.2-1.2
		c0.4-0.3,0.8-0.7,1.1-1.1c0.2-0.2,0.3-0.4,0.5-0.7l0.1-0.2l0,0l0,0h0.2l0.8-0.1c1.8-0.3,3.5-1.1,5-2.1c0.9-0.7,1.6-1.4,2.3-2.3
		l0.4-0.4c0.1-0.1,0.3-0.3,0.4-0.4l0.2-0.3l0.2-0.2c0.7,0.1,1.5,0.2,2.3,0.1c1.4-0.1,2.8-0.4,4.1-0.8c1.4-0.5,2.6-1.2,3.7-2.1
		c0.4-0.3,0.8-0.7,1.1-1.1c0.1-0.2,0.3-0.4,0.4-0.6h0.6h0.6l0.6,0c0.6,0,1.7,0.1,3.2-0.1c1.9-0.1,3.8-0.9,5.2-2.3l0.2-0.3
		c-0.3,0,0.7,0,1.1,0c0.5,0,1.1-0.1,1.6-0.1c0.9-0.1,1.8-0.4,2.6-0.8C138.8,323.3,139.2,322.3,139,322.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M54.8,415c0.3,0,0.4-1.5,1-3.6c0.2-0.6,0.4-1.1,0.7-1.7c0.1-0.3,0.3-0.6,0.5-0.9l0.6-0.9
		c-0.1-0.3-0.2-0.7-0.4-1c-0.7-1.4-1.2-2.9-1.4-4.5c-0.1-1.5,0.1-3.1,0.7-4.5c0.2-0.5,0.5-1,0.8-1.5l0.2-0.3c0-0.1,0.2-0.2,0.2-0.3
		l0.4-0.5c-0.4-1.1-0.6-1.4-0.8-1.9l-0.4-1.1c-0.1-0.4-0.2-0.8-0.3-1.3c-0.1-1.1,0.1-2.3,0.5-3.4c0.3-0.8,0.7-1.6,1.3-2.3
		c0.3-0.4,0.6-0.8,1-1.2c0.2-0.2,0.4-0.4,0.6-0.5l0.2-0.1l0.3-0.2c0.3-0.2,0.5-0.4,0.8-0.5c0-0.2,0-0.4-0.1-0.5l-0.1-0.9
		c-0.1-0.5-0.1-0.9-0.2-1.4c-0.3-2.2,0.1-4.4,1-6.3c0.4-0.7,0.8-1.4,1.3-2c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.2,0.5-0.3,0.7-0.5
		c-0.1-1.1-0.1-1.1-0.1-1.3c0-1.2,0.3-2.4,0.9-3.4c0.4-0.8,1-1.5,1.7-2.1c0.4-0.3,0.8-0.6,1.3-0.9c0.2-0.1,0.5-0.3,0.8-0.4l0.2-0.1
		c0.1-0.1,0.3-0.1,0.4-0.2l1-0.3c0-0.1-0.1-0.6-0.1-1.3c0-0.2,0-0.5,0-0.7c0-0.2,0-0.4,0-0.6c0.1-1.1,0.4-2.1,0.9-3
		c0.2-0.4,0.5-0.9,0.8-1.3c0.1-0.2,0.3-0.4,0.5-0.6l0.2-0.3l0.2-0.2l0.3-0.2c0-0.4,0.1-0.9,0.3-1.3c0.1-0.3,0.3-0.6,0.5-0.9
		c0.4-0.5,0.8-0.9,1.2-1.4c0.7-0.8,1.2-1.2,1.1-1.4s-0.7,0-1.7,0.5c-0.6,0.3-1.1,0.7-1.6,1.2c-0.3,0.3-0.6,0.6-0.9,1
		c-0.2,0.4-0.4,0.8-0.5,1.2l0,0l-0.1,0.1l-0.3,0.3c-0.2,0.2-0.4,0.4-0.6,0.6c-0.4,0.5-0.8,1-1.1,1.5c-0.7,1.2-1.2,2.5-1.3,3.9
		c0,0.3,0,0.5,0,0.8c0,0.2,0,0.3,0,0.5c0,0,0,0.1,0,0.1l0,0l0,0l-0.2,0.1c-0.3,0.2-0.6,0.4-0.9,0.5c-0.5,0.4-1,0.8-1.5,1.3
		c-0.8,0.8-1.3,1.7-1.7,2.8c-0.4,1.3-0.5,2.6-0.4,3.9c0,0.1,0,0.4,0,0.9c-0.2,0.1-0.3,0.1-0.5,0.2c-0.3,0.2-0.6,0.4-0.8,0.5
		c-0.7,0.6-1.4,1.3-1.9,2.1c-1.5,2.1-2.1,4.7-1.8,7.3c0,0.5,0.1,1.1,0.2,1.6v0.1l0,0l0,0l0,0l0,0l-0.1,0.1l-0.2,0.2
		c-0.3,0.2-0.5,0.5-0.7,0.7c-0.4,0.5-0.8,1-1.1,1.5c-0.5,0.9-0.9,1.9-1.1,2.9c-0.2,1.2-0.2,2.5,0,3.7c0.1,0.3,0,0.7,0.2,1.4l0.3,1.2
		c0.1,0.5,0.4,1.2,0.4,1.4c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.1l-0.3,0.3c-0.5,0.5-0.9,1-1.2,1.6c-1,1.7-1.4,3.6-1.3,5.5
		c0.2,1.8,0.8,3.6,1.7,5.1c0,0,0.1,0,0,0.1c-0.2,0.4-0.3,0.7-0.5,1.1c-0.2,0.7-0.4,1.3-0.5,2C54,413.5,54.5,415,54.8,415z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M63.5,416c0.2,0.1,0.8-0.7,1-2.3c0.1-0.9,0.1-1.9-0.1-2.8c-0.1-0.6-0.3-1.1-0.4-1.6c-0.1-0.3-0.2-0.6-0.3-0.9
		l-0.1-0.2l-0.1-0.1l0,0l0,0l0.1-0.1l0.1-0.2c0.2-0.3,0.4-0.7,0.5-1c0.3-0.7,0.6-1.4,0.8-2.2c0.3-1.1,0.4-2.2,0.4-3.4
		c0-1-0.1-1.9-0.4-2.9c-0.2-0.7-0.4-1.4-0.7-2.1c-0.1-0.2-0.2-0.5-0.3-0.7l-0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3l0.3-0.4
		c0.2-0.3,0.3-0.5,0.4-0.6c0.1-0.2,0.3-0.5,0.4-0.7c1.2-2.1,2-4.4,2.2-6.8c0.1-0.6,0.1-1.2,0.1-1.9c0-0.3,0-0.6,0-1v-0.5l0,0l0,0
		l0.6-0.5c1.6-1.7,2.7-3.9,3.2-6.2c0.2-0.8,0.3-1.6,0.3-2.5c0-0.3,0-0.6,0-0.9s0-0.4-0.1-0.5l0.4-0.3l0.4-0.3l0.3-0.3
		c0.9-0.7,1.6-1.5,2.2-2.4c0.5-0.8,0.9-1.6,1.2-2.5c0.2-0.5,0.3-1,0.4-1.6c0.1-0.3,0.1-0.6,0.1-0.9s0.1-0.4,0.1-0.4l0.2,0l0.1,0
		c0.1-0.1,0.2-0.1,0.3-0.2c0.6-0.4,1.1-1,1.5-1.6c0.6-0.8,1-1.7,1.3-2.6c0.1-0.4,0.3-0.9,0.4-1.3c0-0.2,0.1-0.4,0.1-0.6s0-0.1,0-0.1
		l0,0l0.1-0.1l0.3-0.2c0.2-0.2,0.4-0.4,0.7-0.5c0.4-0.3,0.8-0.7,1.1-1c0.6-0.6,1.1-1.2,1.5-1.9c0.8-1.1,1.1-1.9,0.9-2
		s-0.8,0.4-1.8,1.2c-0.5,0.4-1.1,1-1.8,1.5c-0.3,0.3-0.7,0.5-1.1,0.8c-0.2,0.1-0.4,0.3-0.6,0.4l-0.1,0.1l-0.3,0.1l-0.6,0.4l-0.1,0.6
		c0,0.1,0,0.3-0.1,0.4l-0.1,0.5c-0.1,0.4-0.2,0.7-0.4,1.1c-0.3,0.7-0.6,1.4-1.1,2c-0.3,0.5-0.7,0.9-1.1,1.2
		c-0.1,0.1-0.2,0.2-0.3,0.2l-0.4,0.3c-0.2,0.2-0.4,0.3-0.5,0.4c0,0.6,0,1.1,0,1.3s0,0.5,0,0.8c0,0.5-0.1,1-0.2,1.4
		c-0.2,0.8-0.4,1.6-0.7,2.3c-0.4,0.9-0.9,1.8-1.7,2.5l-0.3,0.3l-1,0.7c0,0.3,0,0.5-0.1,0.8c0,0.2-0.1,0.5-0.2,0.8
		c-0.2,0.7-0.4,1.5-0.7,2.2c-0.8,1.9-1.9,3.7-3.3,5.2l-0.6,0.6l-0.6,0.6c0,0.3,0.1,0.7,0.1,1l0.1,0.5l0.1,0.9c0,0.6,0.1,1.2,0.1,1.7
		c0,1,0,2-0.2,3c-0.1,1.3-0.4,2.5-0.9,3.6c-0.1,0.2-0.2,0.4-0.4,0.6c-0.1,0.1-0.2,0.3-0.4,0.6l-0.3,0.4c-0.2,0.2-0.3,0.4-0.5,0.6
		l0.2,0.7c0.1,0.2,0.1,0.5,0.2,0.7c0.1,0.7,0.2,1.3,0.3,2c0.1,1.8,0,3.7-0.5,5.5c-0.2,0.6-0.4,1.3-0.7,1.9c-0.1,0.3-0.2,0.6-0.4,0.9
		l-0.7,1.4l0.4,0.8l0.2,0.4c0.1,0.2,0.2,0.5,0.3,0.7c0.2,0.5,0.4,0.9,0.5,1.4c0.3,0.8,0.4,1.6,0.5,2.4
		C63.4,415.1,63.3,415.9,63.5,416z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M135.9,450.1c0.1-0.2-0.9-0.8-2.8-0.9c-1.1,0-2.2,0.1-3.2,0.4c-0.6,0.2-1.2,0.4-1.8,0.6
		c-0.3,0.1-0.6,0.3-0.9,0.5l-0.5,0.3l-0.1,0l0,0l-0.5-0.1l-1-0.1c-1.8-0.1-3.6,0.2-5.3,0.9c-0.6,0.3-1.1,0.6-1.7,0.9
		c-0.2,0.1-0.4,0.3-0.5,0.4l-0.2,0.2c-0.3-0.1-0.7-0.2-1-0.3c-0.3-0.1-0.6-0.1-0.9-0.1c-0.3,0-0.6,0-0.9,0c-0.9,0-1.8,0-2.7,0.2
		c-0.7,0.1-1.5,0.3-2.2,0.6c-0.4,0.2-0.8,0.4-1.3,0.6l-0.7,0.4l-0.1,0.1c0-0.1-0.1-0.1-0.1-0.2l-0.1-0.1l0,0l-0.2-0.1
		c-0.7-0.4-1.4-0.8-2.2-1c-2-0.6-4.1-0.7-6.2-0.3c-0.7,0.1-1.4,0.3-2.1,0.5l-0.8,0.2l-0.4,0.1c-0.2-0.2-0.5-0.4-0.8-0.6l-0.4-0.3
		c-1-0.6-2.1-1.1-3.3-1.4c-0.9-0.3-1.9-0.4-2.8-0.5c-0.5-0.1-1.1-0.1-1.6-0.1h-0.9h-0.2l0,0l0,0l0,0l-0.1-0.2
		c-0.1-0.2-0.2-0.3-0.3-0.5c-0.9-1.4-2.1-2.6-3.6-3.3c-1.2-0.6-2.5-0.9-3.8-1c-2.2-0.2-3.5,0.3-3.5,0.5s1.3,0.4,3.2,0.9
		s4.5,1.6,5.9,4c0.1,0.1,0.1,0.2,0.2,0.4l0.3,0.6c0.2,0.4,0.4,0.8,0.4,0.8l1.5-0.1l0.8-0.1l1.6-0.1c1,0,1.9,0,2.7,0.1
		c1.1,0,2.2,0.3,3.2,0.8l0.4,0.3c0.4,0.2,0.7,0.5,1,0.8l0.7-0.1h0.8c0.6,0,1.3,0,2.1,0c1.8,0,3.7,0.3,5.5,0.8
		c0.6,0.2,1.2,0.4,1.8,0.7c0.1,0,0.1,0.1,0.2,0.1l0.3,0.2l0.5,0.3c0.2,0.1,0.4,0.2,0.6,0.4c0.4-0.3,0.8-0.6,1.1-0.9l0.6-0.4
		c0.4-0.3,0.7-0.5,1-0.7c0.6-0.3,1.2-0.6,1.9-0.9c0.8-0.3,1.6-0.5,2.4-0.5c0.3,0,0.6,0,0.9,0.1c0.3,0,0.5,0.1,0.8,0.2
		c0.3,0.1,0.6,0.2,1.3,0.5l0.6-0.2l0.6-0.2c0.4-0.1,1-0.3,1.7-0.5c1.5-0.4,3-0.5,4.6-0.4l0.8,0.1l1.3,0.3l0.4-0.3l0.3-0.2l0.4-0.2
		c0.3-0.2,0.5-0.3,0.8-0.4c0.5-0.3,1.1-0.5,1.6-0.7c0.9-0.3,1.8-0.6,2.8-0.8C134.8,450.3,135.9,450.4,135.9,450.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M139.3,455.7c0-0.1-0.3-0.2-0.7-0.4l-0.8-0.2l-0.5-0.1l-0.9-0.2l-0.8,0.9c-0.2,0.1-0.4,0.4-0.7,0.5
		c-0.5,0.3-1.1,0.6-1.7,0.8c-1.5,0.4-3.1,0.5-4.7,0.4c-0.3,0-0.8-0.1-0.9-0.1l-0.7,0.6l-0.3,0.3c-0.2,0.1-0.4,0.3-0.6,0.4
		c-0.4,0.3-0.8,0.5-1.3,0.7c-0.8,0.3-1.7,0.5-2.6,0.6c-0.7,0.1-1.4,0.1-2.1,0.1c-0.5,0-1-0.1-1.5-0.2l-0.9,0.5
		c-1.3,0.6-2.6,0.8-4,0.8c-1,0-2.1-0.1-3.1-0.3c-0.6-0.1-1.1-0.3-1.7-0.4c-0.3-0.1-0.6-0.2-0.9-0.3l-0.4-0.2l-0.2-0.1l-0.1,0
		l-0.2-0.1l-0.4-0.2l-1.3,0.4c-0.3,0.1-0.6,0.2-0.9,0.3c-0.6,0.1-1.1,0.3-1.7,0.4c-1,0.2-2,0.4-3,0.4c-1.3,0.1-2.7-0.2-3.9-0.7
		c-0.2-0.1-0.4-0.2-0.6-0.3c-0.1-0.1-0.3-0.2-0.5-0.4l-0.3-0.3c-0.2-0.2-0.3-0.3-0.5-0.5c-0.2,0-0.4,0-0.7,0h-0.7
		c-0.6,0-1.2,0-2-0.1c-1.8-0.2-3.5-0.7-5.1-1.4c-0.6-0.3-1.3-0.6-1.8-1c-0.3-0.2-0.6-0.4-0.8-0.6l-0.4-0.3c-0.1-0.1-0.2-0.2-0.3-0.3
		l-0.4-0.4l-0.7,0.1h-0.3h-0.2c-0.2,0-0.4,0-0.7,0c-0.4,0-0.9-0.1-1.3-0.2c-0.7-0.1-1.5-0.3-2.2-0.6c-1.2-0.4-2-0.6-2.1-0.5
		s0.4,0.8,1.6,1.5c0.7,0.4,1.5,0.8,2.2,1.1c0.5,0.2,1,0.3,1.5,0.4c0.3,0,0.6,0.1,0.9,0.1h0.4l0,0l0.1,0.1l0.4,0.4
		c0.3,0.3,0.6,0.5,0.9,0.8c0.7,0.5,1.4,0.9,2.1,1.3c1.9,0.9,3.9,1.2,6,1.1c0.7,0,1.4-0.2,2.1-0.3l0.8-0.2c0.1,0,0.2-0.1,0.3-0.1
		l0.3,0.2l0.4,0.3c0.2,0.1,0.4,0.2,0.6,0.4l0.6,0.3c2.3,1.3,4.9,1.9,7.5,1.6c0.6-0.1,1.3-0.2,1.9-0.3c0.3-0.1,0.6-0.2,1-0.3l0.5-0.1
		h0.1l0,0h0.1l0.3,0.1l0.5,0.2c0.3,0.1,0.7,0.2,1,0.3c0.7,0.2,1.3,0.3,2,0.3c1.2,0.1,2.3,0.1,3.5-0.2c1.5-0.3,2.8-0.8,4.1-1.6
		l0.7-0.4c0.4,0.2,0.8,0.3,1.3,0.5c0.8,0.2,1.5,0.3,2.3,0.4c1.7,0.1,3.4-0.2,4.9-1c0.3-0.2,0.6-0.3,0.8-0.5l0.4-0.3l0,0l0,0
		c1.9,0.2,3.8,0,5.5-0.7c0.8-0.3,1.5-0.7,2.1-1.3c0.3-0.2,0.5-0.5,0.8-0.8c0.1-0.2,0.2-0.3,0.3-0.5h0.2h0.6c0.3,0,0.6,0,0.9-0.1
		C139,455.9,139.3,455.8,139.3,455.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M181.3,429.5c-0.1-0.2-0.6,0-1,0.4c-0.3,0.3-0.5,0.5-0.7,0.9c-0.1,0.2-0.2,0.4-0.3,0.6s-0.1,0-0.1,0.1l0,0l0,0
		h-0.2c-0.3,0-0.7,0.1-1,0.2c-0.6,0.1-1.1,0.4-1.6,0.6c-0.4,0.3-0.8,0.6-1.2,0.9c-0.7,0.6-1.1,1.2-1.3,1.4s-0.9,0.5-1.6,1
		c-0.9,0.6-1.6,1.4-2.2,2.3l-0.2,0.3v0.1c0,0,0,0,0,0.1s0,0.1-0.1,0.1c-0.2,0-0.5,0-0.7,0.1c-0.4,0.1-0.8,0.2-1.1,0.3
		c-0.6,0.2-1,0.5-1.3,0.5s-1,0.2-1.9,0.5c-1.1,0.4-2,1-2.7,1.9c-0.2,0.3-0.4,0.5-0.6,0.8l-0.1,0.2l0,0l0,0c0,0,0,0-0.1,0.1l-0.6,0.1
		c-0.3,0.1-0.7,0.1-1,0.2c-0.6,0.2-0.9,0.4-0.9,0.6s0.4,0.4,0.9,0.6c0.3,0.1,0.7,0.2,1,0.2l0.6,0.1l1.3,0.1l0.4-0.7
		c0.1-0.1,0.1-0.2,0.2-0.3l0.1-0.1c0.1-0.2,0.3-0.4,0.5-0.6c0.5-0.7,1.1-1.4,1.8-2c0.4-0.4,0.8-0.8,1.4-1c0.4-0.1,0.8-0.1,1.3,0
		c0.3,0,0.7,0.1,1,0.1c0.2,0,0.4,0.1,0.6,0.1l1.3,0.2c0.1-0.2,0.3-0.4,0.4-0.6l0.3-0.5l0.2-0.3c0.7-1.4,1.5-2.7,2.4-3.9
		c1.3-0.7,2.8-1.1,4.3-1.4c0.3-0.1,0.5-0.1,0.8-0.2l1.4-0.2l0.4-1.2l0.1-0.6c0.1-0.4,0.2-0.7,0.2-0.9
		C181.4,430,181.4,429.7,181.3,429.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M183.3,433.8c-0.1-0.1-0.4,0.1-0.7,0.3l-0.6,0.4l-0.4,0.2l-0.2,0.1l-0.4,0.2l-0.2,0.4l-0.1,0.2v0.1l-0.2,0.4
		c-0.1,0.3-0.3,0.5-0.5,0.8l-0.3,0.4l-0.3,0.4c-0.2,0.3-0.4,0.5-0.6,0.8c-1.3,1.5-2.9,2.6-4.7,3.3h-0.4c-0.2,0-0.4,0-1.1,0
		c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.2,0.2-0.4,0.4c-0.2,0.2-0.5,0.4-0.8,0.7c-0.7,0.6-1.5,1.2-2.3,1.7c-2.2,1.5-4.4,2.5-5.4,2.5
		h-0.3c-0.2,0.2-0.5,0.5-0.7,0.7c-0.3,0.3-0.7,0.6-1.1,0.8c-1,0.6-2,1.2-3,1.6c-3,1.4-5.9,1.3-6,1.9c-0.1,0.3,0.7,0.7,1.9,0.8
		c1.7,0.2,3.4-0.1,4.9-0.8c1.3-0.6,2.3-1.5,3.1-2.6c0.4-0.6,0.8-1.2,1.1-1.9h0.2c2.2,0.3,4.4-0.2,6.3-1.3c0.9-0.6,1.7-1.4,2.3-2.4
		c0.2-0.3,0.4-0.7,0.6-1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.1,0.1-0.2c0.2,0,0.6-0.1,0.8-0.1l0.4-0.1c0.8-0.1,1.6-0.2,2.4-0.3
		c1.4-0.3,2.7-1.1,3.7-2.1c0.3-0.3,0.6-0.6,0.8-1l0.3-0.5l0.3-0.5c0.2-0.3,0.3-0.7,0.4-1l0.1-0.5V436l0,0c0,0,0,0,0,0v-0.1l0.2-0.2
		c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.2,0.3-0.5,0.4-0.8C183.4,434.1,183.4,433.8,183.3,433.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M132.8,361.8c-0.1,0.1-0.4,0-0.8-0.3c-0.2-0.2-0.5-0.4-0.7-0.6c-0.2-0.3-0.5-0.6-0.8-0.9c-1.4-1.9-2.4-4-3-6.2
		c-1.5-5.2-1.2-9.6-0.6-9.6s1.2,4.1,2.6,9c0.6,2.1,1.4,4.1,2.3,6.1c0.2,0.4,0.3,0.7,0.5,1s0.3,0.5,0.4,0.8
		C132.8,361.4,132.9,361.7,132.8,361.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M122.4,363.6c-0.2,0.2-1.6-1-3.1-3.5c-2-3.3-3.2-6.9-3.6-10.7c-0.1-0.9-0.2-1.8-0.2-2.6c0-0.4,0-0.9,0-1.3
		v-0.6c0-0.1,0-0.1,0-0.1l0,0l0,0l-0.1-0.1c-0.1-0.2-0.3-0.3-0.4-0.5c-0.2-0.3-0.5-0.7-0.7-1c-0.4-0.6-0.7-1.2-1-1.9
		c-0.5-1-0.9-2.1-1.2-3.2c-0.5-1.9-0.6-3-0.4-3s0.7,0.9,1.6,2.6c0.4,0.8,0.9,1.8,1.5,2.9c0.3,0.5,0.6,1.1,1,1.6
		c0.2,0.3,0.4,0.5,0.6,0.8c0.1,0.1,0.2,0.3,0.3,0.4l0.7,0.7v0.6v1c0,0.4,0,0.8,0,1.2c0,0.8,0.1,1.6,0.2,2.5c0.4,3.5,1.4,7,2.8,10.3
		C121.7,362,122.6,363.4,122.4,363.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M130.4,380.1c-0.2,0.2-1.3-0.4-2.7-1.9c-1.8-2-3.2-4.3-4.1-6.8c-0.5-1.4-0.9-2.8-1.1-4.3c0-0.2,0-0.5,0-0.7
		c0-0.1,0.1-0.3,0.1-0.4l0.1-0.3c0.2-0.3,0.4-0.6,0.6-0.9c0.4-0.4,0.7-0.9,1.2-1.2c1.5-1.4,2.6-2,2.8-1.8s-0.6,1.2-1.7,2.7
		c-0.3,0.4-0.6,0.8-0.9,1.3c-0.1,0.2-0.2,0.4-0.3,0.7c0,0.1-0.1,0.2,0,0.2l0,0l0,0v0.2c0.3,1.3,0.7,2.6,1.1,3.8
		c0.9,2.3,2,4.5,3.3,6.6C129.8,378.9,130.6,379.9,130.4,380.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M115.8,371.1c-0.1,0.1-0.4,0-0.9-0.3c-0.6-0.5-1.2-1-1.8-1.5c-1.8-1.9-3.3-3.9-4.6-6.1
		c-3.1-5.1-4.4-9.8-3.9-10s2.7,4,5.7,9c1.5,2.5,2.9,4.7,4,6.3c0.5,0.8,0.9,1.4,1.2,1.9S115.9,371,115.8,371.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M102.1,372c-0.2,0.2-1.2-0.4-2.5-1.6c-1.7-1.7-3.1-3.5-4.4-5.5c-3-4.6-4.4-8.9-3.9-9.1s2.7,3.5,5.6,8
		c1.4,2.2,2.8,4.2,3.8,5.7S102.4,371.8,102.1,372z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M91.3,378.3c-0.2,0.2-1.3-0.5-2.7-1.8c-1.8-1.8-3.4-3.9-4.7-6.1c-3.1-5.3-3.9-10.3-3.2-10.4s2.1,4.3,5,9.3
		S91.7,377.8,91.3,378.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M117.4,389.8c-0.1,0.1-0.5,0.1-1.1-0.1c-1-0.3-1.9-0.7-2.8-1.2c-2.9-1.8-5.3-4.2-7-7.1c-1-1.6-1.8-3.3-2.3-5
		c-0.1-0.4-0.2-0.8-0.3-1.3c0-0.2-0.1-0.4-0.1-0.6c0-0.2,0-0.5,0-0.8c0-0.3,0.1-0.6,0.3-0.8c0.1-0.2,0.3-0.4,0.4-0.5
		c0.3-0.3,0.5-0.5,0.8-0.7c2.1-1.5,3.6-2,3.8-1.8s-1.1,1.2-2.8,2.9c-0.2,0.2-0.4,0.4-0.6,0.6c-0.1,0.1-0.1,0.2-0.2,0.3
		c0,0,0,0.1,0,0.1l0,0l0,0l-0.3-0.1l0,0h0.1l0.2,0.1c0.1,0,0,0,0,0s0,0.2,0.1,0.4s0.1,0.3,0.1,0.5c0.1,0.3,0.2,0.7,0.3,1
		c0.6,1.6,1.3,3.1,2.2,4.6c1.6,2.7,3.7,5,6.1,7c0.9,0.7,1.7,1.3,2.3,1.7S117.4,389.6,117.4,389.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M127.3,404.3c-0.2,0.2-1.4-0.4-3.3-1.9c-2.3-1.9-4.3-4.2-5.8-6.9c-0.4-0.8-0.8-1.5-1.2-2.3
		c-0.2-0.4-0.3-0.8-0.4-1.2c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.3,0-0.7,0.1-1c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.1-0.2,0.2-0.3
		c0.1-0.2,0.3-0.3,0.4-0.4s0.6-0.5,0.8-0.7c0.5-0.4,1-0.8,1.5-1.1c1.9-1.3,3.1-1.9,3.3-1.7s-0.8,1.3-2.4,2.8
		c-0.4,0.4-0.8,0.8-1.3,1.3c-0.2,0.2-0.5,0.5-0.7,0.7l-0.3,0.4l-0.1,0.2l0,0l0,0c0,0.1,0.1,0.3,0.1,0.4c0.1,0.3,0.2,0.7,0.4,1
		c0.3,0.7,0.7,1.4,1.1,2.1C123.3,400.2,127.7,403.8,127.3,404.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M127.1,418.6c-0.1,0.2-2.4-0.7-5.6-3.3c-1.9-1.5-3.6-3.2-5.1-5.1c-0.9-1.1-1.7-2.2-2.6-3.5
		c-0.4-0.6-0.8-1.3-1.2-2c-0.2-0.3-0.4-0.7-0.6-1l-0.3-0.5l-0.1-0.3l0,0l-0.6-0.4c-0.3-0.2-0.6-0.4-0.9-0.5
		c-0.6-0.4-1.2-0.8-1.7-1.2c-2.8-2.2-5.2-4.8-7.3-7.6c-1-1.3-1.8-2.6-2.6-3.8c-0.4-0.6-0.7-1.2-1-1.8l-0.5-0.9l-0.2-0.4
		c0,0-0.1-0.1-0.1-0.2l-0.2-0.1c-0.1-0.1-0.3-0.1-0.4-0.2c-0.3-0.2-0.5-0.3-0.7-0.5c-0.4-0.3-0.8-0.7-1.2-1
		c-0.7-0.7-1.2-1.3-1.7-1.8c-0.9-1-1.3-1.6-1.2-1.7s0.7,0.3,1.7,1.1c0.5,0.4,1.1,0.9,1.9,1.5c0.4,0.3,0.8,0.6,1.2,0.8
		c0.2,0.1,0.4,0.3,0.6,0.4l0.3,0.1l0.6,0.2l0.4,0.6l0.2,0.4l0.5,0.8c0.4,0.5,0.7,1.1,1.1,1.7c0.8,1.1,1.7,2.3,2.6,3.5
		c2,2.6,4.4,5,7,7.1c0.5,0.4,1.1,0.8,1.6,1.1l1.9,1.2l0.4,0.8l0.3,0.5l0.5,1c0.4,0.7,0.7,1.3,1.1,1.9c0.8,1.2,1.6,2.4,2.3,3.4
		c1.4,1.9,2.9,3.6,4.5,5.2C125.3,417,127.3,418.4,127.1,418.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M101.4,410c-0.1,0.1-0.4,0.1-0.9,0c-0.8-0.2-1.5-0.5-2.1-0.9c-2.2-1.3-4.2-3-5.8-5.1c-1.5-2-2.7-4.3-3.6-6.8
		c-0.6-1.9-0.7-3-0.5-3.1c0.7-0.3,2.4,4.2,5.7,8.6c1.4,1.9,3.1,3.6,4.9,5.2c0.7,0.6,1.3,1.1,1.7,1.4S101.4,409.9,101.4,410z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M110.6,424.5c-0.1,0.2-1,0-2.1-0.6c-1.5-0.9-2.7-2.2-3.6-3.6c-0.9-1.5-1.5-3.1-1.7-4.8
		c-0.1-1.3,0.1-2.1,0.4-2.1c0.6,0,1.2,2.9,3.1,5.9S111,424,110.6,424.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M77.4,395.4c-0.1,0.1-0.3,0.1-0.6,0c-0.5-0.1-0.9-0.4-1.3-0.7c-1.2-0.9-2.3-2.1-3-3.5
		c-1.8-3.2-1.8-6.2-1.2-6.3s1.5,2.4,3.1,5.2c0.7,1.2,1.4,2.3,2.2,3.5c0.3,0.4,0.6,0.8,0.7,1.1S77.5,395.3,77.4,395.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M104.9,433.8c0,0.1-0.6,0.1-1.6-0.2c-0.6-0.1-1.2-0.3-1.8-0.6c-0.8-0.3-1.5-0.7-2.2-1.2
		c-1.9-1.3-3.4-3.1-4.4-5.1c-0.6-1.2-1-2.5-1.3-3.9c-0.1-0.7-0.2-1.4-0.3-2.2c0-0.4,0-0.7-0.1-1.1v-0.3l0,0l0,0l0,0l-0.3-0.1
		l-1.2-0.3c-0.8-0.3-1.6-0.6-2.4-0.9c-3.2-1.4-5.8-3.9-7.6-6.9c-1.3-2.5-2.1-5.3-2.1-8.1c0-0.3,0-0.6,0-0.9v-0.4
		c0-0.2,0.1-0.4,0.1-0.5c0.2-0.5,0.5-1,0.8-1.4c0.5-0.6,1-1.2,1.6-1.8c0.9-0.9,1.6-1.3,1.6-1.2s-0.3,0.7-1,1.7
		c-0.4,0.5-0.8,1.1-1.3,1.9c-0.2,0.4-0.4,0.7-0.5,1.1c0,0.1,0,0.1,0,0.2v0.4c0,0.3,0,0.5,0.1,0.8c0.2,2.5,1,5,2.3,7.2
		c1.6,2.6,4,4.7,6.8,6c0.7,0.3,1.4,0.6,2.2,0.9l3,0.9l-0.1,1.1c0,0.2,0,0.4,0,0.5v0.3c0,0.3,0,0.7,0,1c0,0.6,0.1,1.3,0.2,1.9
		c0.2,1.2,0.5,2.4,1,3.5c0.8,1.9,2,3.5,3.6,4.8c0.6,0.5,1.3,0.9,1.9,1.3c0.5,0.3,1,0.6,1.6,0.9C104.4,433.3,104.9,433.6,104.9,433.8
		z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M131.1,439.8c0,0.1-0.3,0.2-0.8,0.3c-0.7,0-1.4,0-2.1-0.2c-2.3-0.5-4.4-1.4-6.2-2.8c-1.8-1.4-3.4-3.1-4.6-5
		c-0.9-1.5-1.2-2.6-1-2.7c0.5-0.3,2.9,3.1,6.9,6c1.7,1.3,3.5,2.3,5.4,3.1c0.7,0.4,1.4,0.6,1.8,0.8S131.1,439.6,131.1,439.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M125.6,446.8c0,0.3-1.6,0.6-4.2,0.3c-3.4-0.6-6.5-2-9.2-4c-1.5-1.1-2.9-2.4-4.1-3.9c-0.3-0.3-0.5-0.7-0.8-1.1
		c-0.2-0.3-0.3-0.6-0.4-1c0-0.3,0.1-0.7,0.3-0.9c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.2,0.2-0.2s0.3-0.3,0.4-0.4s0.6-0.4,0.8-0.6
		c2.1-1.4,3.6-2,3.8-1.8s-1,1.3-2.9,2.9c-0.2,0.2-0.4,0.4-0.7,0.6s-0.2,0.2-0.3,0.4c-0.1,0-0.1,0.1-0.1,0.2v0c0,0.1,0,0.1,0,0.1
		c0.2,0.3,0.4,0.5,0.7,0.8c1.2,1.3,2.5,2.4,3.9,3.4c2.5,1.9,5.3,3.3,8.3,4.3C124.1,446.3,125.6,446.4,125.6,446.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M90.6,439.6c-0.2,0.2-1.5-0.5-3.4-2.1c-2.4-2.1-4.7-4.4-6.6-6.9c-1.1-1.4-2.1-2.8-3-4.3
		c-0.4-0.7-0.8-1.4-1.1-2.1c-0.1-0.3-0.2-0.6-0.2-0.9c0.1-0.3,0.2-0.5,0.4-0.8c0.1-0.1,0.2-0.2,0.3-0.3L77,422
		c0.1-0.1,0.3-0.2,0.4-0.3c2-1.6,3.4-2.2,3.5-2s-0.9,1.3-2.5,3c-0.1,0.1-0.2,0.2-0.3,0.3l-0.1,0.2c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2
		c0.3,0.5,0.7,1.1,1.2,1.8c0.9,1.3,2,2.7,3.1,4.1c2.2,2.9,4.4,5.3,6,7.1S90.8,439.4,90.6,439.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M93.6,452.5c-0.1,0.3-1,0.1-2.1-0.5c-1.5-0.8-2.8-1.8-4-3c-2.8-2.7-4.4-5.4-4-5.8s2.7,1.7,5.4,4.3l3.4,3.3
		C93.2,451.7,93.8,452.2,93.6,452.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M74.5,420.3c-0.1,0.1-0.4,0-0.7-0.2c-0.5-0.3-0.9-0.7-1.3-1.1c-1.3-1.4-2.3-3.1-2.9-4.8
		c-1.6-4.2-1.3-7.8-0.7-7.8s1.2,3.3,2.6,7.1c0.6,1.6,1.3,3.2,2.2,4.7c0.3,0.6,0.6,1.1,0.7,1.5S74.6,420.1,74.5,420.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M86.9,392.4c-0.1,0.2-0.7,0.3-1.4-0.1c-0.9-0.5-1.7-1.1-2.4-1.9c-1.5-1.8-2.1-3.6-1.6-3.9s1.8,1,3.2,2.6
		c0.7,0.8,1.3,1.5,1.7,2.1S87,392.1,86.9,392.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M125.4,337.3c-0.4,0.3-2.4-1.4-3.5-4.4s-1-5.5-0.4-5.6s1.3,2.2,2.4,4.8S125.9,336.9,125.4,337.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M121.5,384.9c-0.1,0.2-0.8,0.2-1.6-0.2c-1.1-0.6-2-1.3-2.8-2.3c-1.9-2.1-2.7-4.2-2.2-4.5s2,1.2,3.8,3.1
		c0.9,1,1.6,1.8,2.2,2.5S121.6,384.6,121.5,384.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M91.9,431.1c-0.1,0.3-0.7,0.2-1.5-0.1c-1-0.5-1.9-1.2-2.6-2c-1.8-1.9-2.6-3.8-2.1-4.1s2,1,3.6,2.7
		c0.8,0.9,1.5,1.6,2,2.3S92,430.9,91.9,431.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M123.6,422.5c-0.1,0.3-0.7,0.3-1.5,0c-1-0.4-1.8-1.1-2.6-1.8c-1.7-1.7-2.6-3.5-2.2-3.9s2,0.8,3.6,2.4
		c0.8,0.8,1.5,1.6,2,2.1S123.7,422.2,123.6,422.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M120.1,359.6c-0.5-0.3,0.2-2,1.6-3.7s2.9-2.6,3.4-2.2s-0.4,2-1.8,3.6S120.6,360,120.1,359.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M99.6,368.8c-0.3-0.1-0.3-0.7,0-1.3c0.4-0.8,0.9-1.5,1.5-2.1c1.4-1.4,2.9-2.1,3.3-1.6s-0.4,1.8-1.8,3.1
		c-0.6,0.6-1.2,1.1-1.8,1.5C100.3,368.7,99.9,368.9,99.6,368.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M101.9,374.5c0.2,0.5-1.5,1.9-3.7,3.5s-4.3,2.7-4.7,2.2s1.1-2.2,3.4-3.9c1-0.7,2.1-1.3,3.2-1.8
		C101.1,374.3,101.8,374.2,101.9,374.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M94.8,416.9c-0.3-0.1-0.3-0.6-0.1-1.4c0.3-0.9,0.8-1.8,1.3-2.5c1.3-1.8,2.8-2.8,3.3-2.4s-0.3,2-1.6,3.7
		c-0.5,0.7-1.1,1.4-1.8,2C95.4,416.7,95,417,94.8,416.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M101.9,450.3c-0.3,0.5-2.3-0.2-4.2-1.8s-3-3.3-2.6-3.7s2.1,0.6,3.9,2.1S102.2,449.8,101.9,450.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M104.8,407.3c-0.2-0.2,0-0.8,0.5-1.5c0.7-0.9,1.7-1.6,2.7-2.1c2.4-1.3,4.7-1.2,4.8-0.6s-1.7,1.4-3.8,2.5
		S105.2,407.6,104.8,407.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M120.6,425.8c-0.4-0.4,0.6-2.3,2.6-3.7s4-2,4.3-1.5s-1.2,1.8-3,3.2S121.1,426.1,120.6,425.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M76,409.4c0.1,0.3-0.1,0.6-0.4,1.1c-0.5,0.6-1,1.2-1.5,1.7c-1.3,1.3-2.6,2.1-3,1.8s0.1-1.9,1.6-3.3
		c0.6-0.6,1.3-1.1,2.1-1.4C75.4,409,75.9,409.1,76,409.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M179.1,429c-0.3,0.5-2.6-0.2-4.7-2.2s-3.3-4-2.8-4.3s2.2,1,4.2,2.8S179.4,428.5,179.1,429z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M165.4,435c0.4-0.3,1.7,0.3,3,1.4s2.2,2.4,1.9,2.8s-1.8,0-3.2-1.2S164.9,435.4,165.4,435z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M171.8,440c0.2-0.3,0.5-0.4,0.8-0.2c0.3,0.1,0.6,0.4,0.8,0.7c0.3,0.5,0.1,1.1-0.4,1.4c0,0-0.1,0-0.1,0.1
		c-0.5,0.2-1.1-0.1-1.4-0.6C171.2,440.8,171.5,440.5,171.8,440z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M163,446.3c-0.6,0-1.2-0.4-1.4-0.9c-0.3-0.5-0.2-1.2,0.2-1.6c0.4-0.4,1.3-0.1,1.7,0.8S163.6,446.3,163,446.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M127.8,458.3c-0.6,0-1.1-1.4-1.3-3s-0.3-2.9,0.2-3.2s1.6,1,1.9,2.9S128.4,458.3,127.8,458.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M118.4,459.9c-0.6-0.1-0.8-1.5-1-3.1s-0.4-2.9,0-3.2s1.8,0.9,2,2.9S119,460,118.4,459.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M106.4,460.3c-0.6,0-1-1.2-0.7-2.6c0.1-0.6,0.4-1.2,0.8-1.7c0.3-0.4,0.8-0.6,1-0.4c0.4,0.3,0.5,1.3,0.3,2.5
		S107,460.4,106.4,460.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M93.8,459c-0.6,0.1-1.1-1.3-0.6-3s1.8-2.5,2.2-2.1s0.2,1.5-0.2,2.7S94.3,459,93.8,459z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M81.3,455.5c-0.3-0.1-0.5-0.4-0.4-1c0.1-0.8,0.5-1.5,1-2c1.3-1.3,2.8-1.4,3-0.9s-0.6,1.4-1.4,2.3
		c-0.4,0.5-0.8,0.9-1.1,1.2S81.5,455.6,81.3,455.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M68.8,493.9c-0.3,0-0.8-1.5-1.2-4.2c-0.5-3.4-0.7-6.8-0.7-10.3c0-8,1-14.5,1.5-14.5s0.5,6.5,0.6,14.4
		c0,4,0.1,7.5,0.1,10.2S69.1,493.8,68.8,493.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M150.9,539.3c-0.3-0.1,0.7-5.1,2.4-13.1l3.1-14.1c1.2-5.3,2.2-11.1,3-17.3s1.5-12.1,1.9-17.5
		c0.3-4.8,0.4-9.5,0.3-14.3c-0.3-8.2-0.8-13.2-0.5-13.3s1.3,5,2,13.2c0.4,4.8,0.5,9.7,0.2,14.5c-0.3,5.4-0.9,11.4-1.7,17.6
		s-1.9,12.1-3.2,17.4s-2.5,10-3.5,14C152.6,534.5,151.1,539.3,150.9,539.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M77.9,537c-0.6,0.2-3.3-7.1-5.4-16.5s-3-17.1-2.5-17.2s2.4,7.4,4.5,16.7S78.4,536.8,77.9,537z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M62.4,408c0.1,0.5-1.2,1.4-3,1.4c-0.8,0-1.6-0.2-2.3-0.5c-0.6-0.3-0.8-0.7-0.7-1c0.3-0.5,1.4-0.5,3-0.6
		S62.3,407.4,62.4,408z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M63.4,395c-0.1,0.3-0.5,0.4-1,0.6c-0.7,0.2-1.3,0.3-2,0.3c-0.7,0.1-1.4,0.1-2.1,0c-0.3,0-0.5-0.1-0.7-0.2
		c-0.2-0.1-0.4-0.3-0.3-0.4s0-0.3,0.2-0.4c0.2-0.2,0.4-0.3,0.6-0.4c0.7-0.4,1.4-0.6,2.2-0.6c0.8-0.1,1.6,0,2.3,0.3
		C63.2,394.4,63.5,394.7,63.4,395z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M66.4,382.4c-0.1,0.3-0.5,0.4-1,0.5c-0.7,0.1-1.4,0.1-2.1,0.2c-1.6,0-3.1-0.1-3.2-0.6s1.2-1.4,3.2-1.5
		c0.8,0,1.7,0.1,2.4,0.5C66.3,381.8,66.5,382.2,66.4,382.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M71.2,372.1c-0.2,0.3-0.6,0.3-1.2,0.2c-0.8-0.1-1.6-0.2-2.4-0.4c-1.9-0.4-3.4-1-3.4-1.5s1.8-0.9,3.8-0.5
		c0.9,0.2,1.8,0.5,2.6,1C71.1,371.4,71.3,371.9,71.2,372.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M75.7,363.2c-0.2,0.2-0.6,0.3-1.1,0.2c-0.7-0.1-1.4-0.2-2.1-0.4c-1.7-0.4-3-1.1-2.9-1.7s1.6-0.8,3.4-0.4
		c0.8,0.2,1.6,0.5,2.2,1.1C75.7,362.5,75.9,363,75.7,363.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M79.6,356.5c-0.4,0.4-1.9-0.1-3.8-0.9s-3.4-1.7-3.2-2.2s2-0.6,4,0.3c0.9,0.4,1.7,0.9,2.4,1.5
		C79.6,355.8,79.8,356.3,79.6,356.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M100,337.3c-0.4,0.4-1.7-0.3-2.6-1.8s-1-2.9-0.4-3.1s1.4,0.7,2.2,2S100.4,336.9,100,337.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M109.9,332c-0.6,0.1-1.3-1-2.1-2.3s-1.4-2.6-0.9-3s1.8,0.4,2.7,2S110.5,331.9,109.9,332z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M121.5,327.3c-0.6,0-1.2-1-1.7-2.5s-0.6-2.8-0.1-3.1s1.5,0.8,2.1,2.4S122.1,327.3,121.5,327.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M131.6,323.9c-0.6,0-1.1-1.1-1.5-2.6s-0.4-2.7,0-3s1.6,0.8,2,2.4S132.1,323.9,131.6,323.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M36.2,543.7c0.1,0.1,1.1-1.3,2.8-4l3.2-4.7c1.4-2.1,2.8-4.1,4.4-6c3.7-4.2,7.9-9.4,12.5-15.3
		c2.3-3,4.5-6,7.3-9c2.8-3,5.7-5.9,8.8-8.6c1.5-1.3,3-2.5,4.6-3.6c1.4-0.8,3-0.8,4.4,0.1c0.4,0.2,0.7,0.4,1,0.7
		c0.4,0.3,0.8,0.6,1.2,0.8c0.8,0.5,1.6,1,2.3,1.4s1.5,0.8,2.2,1.2c0.7,0.3,1.3,0.7,1.8,1.2c1,0.9,1.8,1.9,2.5,3.1
		c0.3,0.5,0.6,1,0.8,1.6c0.1,0.3,0.2,0.5,0.3,0.8s0.1,0.5,0.2,0.8c0.2,0.5,0.4,1,0.6,1.5c0,0.1,0,0.1,0.1,0.2l0.1,0.1l0.1,0.2
		c0.2,0.3,0.4,0.5,0.5,0.7l0.2,0.3L98,507c0,0-0.1,0-0.1,0c-0.2-0.1-0.4-0.1-0.5-0.3c-0.3-0.2-0.7-0.5-1-0.7l-0.4-0.3l-0.4-0.4
		l-0.9-0.8l-1.6-1.5c-2-1.9-3.7-3.5-5.1-4.8l-3.4-3.3c-0.7-0.8-1.5-1.5-2.4-2.1c-0.2-0.2-0.6-0.3-0.9-0.2c-0.2,0.1-0.3,0.1-0.5,0.2
		l-0.4,0.3c-0.5,0.3-1.1,0.8-1.6,1.2c-1.1,0.9-2.4,2-3.8,3.3c-2.8,2.5-6.1,5.7-9.6,9.5c-3.9,4.2-7.6,8.6-10.9,13.3
		c-3.7,5-7.5,10.5-11.3,16.3c-2.8,4.3-5.5,8.4-5.9,9.2L39,547c2.5-3.7,5.2-7.2,8.1-10.5c2.6-3,5-5.6,7.2-7.8c4.3-4.4,7.4-7.3,9-8.7
		l-0.5-0.6c-2,1.9-3.8,3.9-5.5,6c-1,1.4-1.6,2.1-2.1,2.7c-4.4,6-8.4,12.2-12,18.6c-5.1,9-10.9,20.5-16.8,33
		c-7.5,15.4-13.9,29.5-18.4,39.7c-2.3,5.1-4,9.3-5.2,12.2s-1.8,4.5-1.6,4.6s1-1.5,2.4-4.3S6.8,625,9.2,620
		c4.9-10.1,11.4-24.1,19-39.4c6-12.5,11.3-24.1,16.1-33.2c3.3-6.6,7.1-12.9,11.3-18.9l2.1-2.7c1.7-2.1,3.6-4,5.6-5.9l-0.5-0.6
		c-1.7,1.4-5,4.1-9.5,8.3c-2.2,2.1-4.8,4.7-7.5,7.7c-3,3.3-5.8,6.9-8.4,10.6l1.7,1c0.4-0.7,3.2-4.8,6-9c3.8-5.8,7.5-11.3,11.1-16.4
		c3.2-4.7,6.7-9.2,10.4-13.5c3.4-3.9,6.5-7.1,9.2-9.8c1.3-1.3,2.6-2.4,3.7-3.4c0.5-0.4,1-0.8,1.6-1.2c0.2-0.1,0.4-0.3,0.6-0.4
		c0.2-0.1,0.4,0,0.5,0.1c0.8,0.6,1.5,1.3,2.2,2l3.3,3.4c1.3,1.4,2.9,3,4.9,5l1.6,1.5l0.9,0.8l0.5,0.4l0.3,0.2l0.3,0.3
		c0.4,0.4,0.9,0.7,1.4,1.1l0.7,0.5c0.2,0.1,0.5,0.2,0.7,0.4c0.4,0.2,0.9,0.4,1.4,0.7l0.2,0.1c0.1,0,0.2,0.1,0.1,0c0,0,0-0.1,0.1-0.1
		c0.1-0.1,0.2-0.1,0.3-0.1c-0.4-0.7-1.1-1.7-1.8-2.9c-0.2-0.3-0.3-0.6-0.5-0.9c-0.1-0.2-0.1-0.3-0.2-0.5s-0.2-0.4-0.2-0.5
		c-0.2-0.4-0.3-0.9-0.4-1.3c0-0.2-0.1-0.5-0.2-0.8s-0.2-0.6-0.3-0.9c-0.2-0.6-0.5-1.2-0.8-1.8c-0.7-1.4-1.6-2.6-2.7-3.6
		c-0.6-0.6-1.4-1.1-2.1-1.4l-2.2-1.2c-0.7-0.4-1.5-0.9-2.2-1.3c-0.4-0.2-0.7-0.5-1-0.7c-0.4-0.3-0.8-0.6-1.3-0.9
		c-1-0.5-2-0.9-3.1-0.9c-1.2-0.1-2.4,0.2-3.4,0.9c-1.7,1.2-3.3,2.4-4.8,3.8c-3.2,2.8-6.2,5.7-9,8.8c-2.8,3-5,6.2-7.3,9.2
		c-4.4,5.9-8.6,11.1-12.1,15.5c-1.5,2-3,4.2-4.2,6.4c-1.2,1.9-2.1,3.6-2.9,5C36.8,542,36,543.6,36.2,543.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M32,546.3c0.3,0.1,1.9-2.4,4.5-6.5l4.5-7.3c0.9-1.4,1.8-2.8,2.7-4.2c0.5-0.7,1-1.4,1.5-2.2l1.6-2.2
		c7.1-9.8,12.6-18.4,14.7-21.1c2-2.5,4.1-4.8,6.5-7c1.6-1.5,3.5-3.2,5.4-5.1c1-0.9,2-1.9,3-2.9c0.5-0.5,1-1,1.6-1.6
		c0.3-0.3,0.5-0.6,0.8-0.9s0.4-0.5,0.7-0.8c2.1-1.8,4.6-3,7.3-3.5l2-0.8h0.1l0,0l-0.1,0.1l-0.2,0.1l-0.3,0.3l-0.8,0.6l-2.2,1.8
		l-2.9,2.4c-1.3,1.1-4.1,3.1-7.7,5.8c-1.8,1.4-3.8,3-5.8,4.8c-2.2,1.9-4.3,4-6.1,6.3c-3.9,5-7.6,10.3-10.9,15.7
		c-2.6,4.2-3.9,6.9-3.7,7s2.1-2.3,4.9-6.2s6.7-9.5,11.2-15.3c1.8-2.2,3.7-4.3,5.8-6.2c1.9-1.9,3.8-3.6,5.4-5.1
		c3.3-3,5.9-5.3,7.2-6.4c0.6-0.5,1.5-1.2,3-2.2l3.1-2.2c0.7-0.5,1.7-1.2,2.8-2l1.8-1.2l1-0.7l0.5-0.3l0.3-0.2l0.1-0.1
		c-0.3,0-1.2,0.3-2.8,0.7c-1.5,0.4-3.7,1.1-5.9,1.5c-0.5,0.1-0.7,0.1-1.1,0.2l-1.1,0.2c-0.7,0.2-1.5,0.4-2.2,0.7
		c-1.6,0.6-3.1,1.5-4.4,2.8c-0.3,0.3-0.6,0.7-0.8,0.9s-0.4,0.5-0.7,0.8c-0.5,0.5-1,1.1-1.5,1.6l-2.9,3c-1.9,2-3.6,3.8-5.1,5.5
		c-3,3.4-4.9,6.3-6,7.6c-2.1,2.7-8.8,10.5-15.9,20.2l-1.6,2.3c-0.5,0.8-1,1.5-1.5,2.3c-1,1.5-1.8,3-2.7,4.3c-1.6,2.8-3,5.3-4.2,7.5
		C33,543.4,31.8,546.2,32,546.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M25.3,551.7c0.2,0,0.5-0.7,1.4-2c1.2-1.8,2.5-3.6,4-5.2c0.5-0.5,1-1,1.5-1.4c0.3-0.3,0.7-0.6,0.9-1
		c0.3-0.3,0.5-0.7,0.8-1c1-1.4,1.8-2.9,2.8-4.6c1.8-3.3,3.7-6.9,5.6-10.8c1.5-3.2,2.8-6.3,4-9.2c1.1-2.7,2-5.5,2.6-8.4
		c0.7-3.7,1-7.4,0.9-11.2v-2.8v-2V491c0-0.4-0.1-0.9-0.2-1.3c-0.5-2-1.5-3.9-2.7-5.5c-1.5-2.1-3.3-4.1-5.2-5.8l-1,1.5
		c2.5,1.4,4.7,3.2,6.7,5.3c2.2,2.3,3.8,5.1,4.5,8.2c0.3,1.4,0.5,2.8,0.6,4.3c0,0.7,0,1.4,0,2l-0.1,1c0,0.3,0,0.6-0.1,1.2
		s-0.2,1-0.2,1.5c-0.1,0.4-0.1,0.8-0.1,1.3c0,0.2,0.1,0.5,0.1,0.7c0,0.1,0,0.2,0.1,0.2s0,0.2,0.2,0.2c0.4-0.2,0.8-0.4,1.1-0.8
		c0.2-0.3,0.4-0.5,0.6-0.8l0.6-0.8c0.8-1.2,1.1-1.7,1.5-2.4s0.7-1.1,1-1.6c0.6-0.8,1.4-2.1,2.8-4.1c2.1-2.7,4.4-5.2,6.9-7.5
		l-1.1-0.8c-0.9,1.1-4.4,6.3-8,12l-6.4,10.3c-1.9,3.3-3.3,6.3-4.5,8.9c-2.3,5.1-3.6,8.4-3.3,8.5s2-2.9,4.7-7.9
		c1.3-2.5,2.9-5.4,4.8-8.5s4.2-6.6,6.5-10.2c3.6-5.7,6.8-11.1,7.4-12.4l1.9-4.2l-3,3.3c-2.4,2.5-4.6,5.2-6.6,8
		c-1.3,2.1-2.1,3.4-2.6,4.3c-0.3,0.4-0.6,0.9-1,1.5s-1,1.4-1.3,1.8c-0.3,0.4-0.6,0.7-1,1c-0.1,0,0-0.1,0-0.2v-0.2l0.1-0.5l0.2-0.8
		c0,0,0.1-0.5,0.1-0.8s0.1-0.7,0.1-1c0.1-0.7,0.1-1.4,0.1-2.2c0-1.6-0.1-3.1-0.4-4.7c-0.7-3.4-2.4-6.6-4.8-9.2
		c-2.1-2.3-4.6-4.2-7.5-5.5l-3.9-1.1l2.9,2.6c4.1,3.7,7.1,7.2,8.1,10.3c0.1,0.4,0.2,0.7,0.2,1.1l0.1,1.1l0.2,2
		c0.1,1.2,0.1,2.1,0.1,2.8c-0.1,3.7-0.6,7.3-1.6,10.9c-0.8,2.7-1.8,5.4-3,8c-1.3,2.8-2.7,5.9-4.2,9c-1.8,3.9-3.7,7.5-5.4,10.8
		c-0.9,1.6-1.7,3.2-2.6,4.5c-0.2,0.4-0.4,0.7-0.6,1c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.3-0.3,0.4c-0.6,0.5-1.1,1.1-1.6,1.7
		C26,548,25,551.6,25.3,551.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M1.3,587.3c0.2,0.2,2.3-2.2,5-6.4s6.2-10.2,9.8-16.9c1.5-2.7,2.8-5.4,4.1-7.8s2.4-4.8,3.3-6.9
		c1.8-4.2,3.1-7.5,3.8-9.1s2.3-4.7,4.3-8.9c0.5-1,1-2.2,1.5-3.3c0.6-1.2,1-2.5,1.4-3.9c0.8-2.9,1.3-5.8,1.7-8.8
		c0.2-1.4,0.3-2.8,0.4-4.1s0.2-2.7,0.2-4c-0.1-2.4-0.4-4.7-0.9-7c-0.7-2.9-1.5-5.7-2.6-8.5l-0.6,0.3l1.6,2.2
		c0.7,1.1,1.3,2.2,1.9,3.3c0.9,1.7,1.6,3.6,2.2,5.5c0.7,2.4,1,4.8,0.9,7.3c-0.2,2.9-0.6,5.7-1.3,8.5c-1.6,6.2-3.6,12.4-6.1,18.3
		c-1.8,4.6-2.9,7.5-2.7,7.6s1.9-2.5,4-7c2.9-5.9,5.2-12,6.9-18.4c0.8-2.9,1.2-6,1.2-9c0-2.6-0.5-5.3-1.4-7.7
		c-0.7-1.9-1.6-3.7-2.7-5.4c-0.9-1.5-1.7-2.5-2.2-3.2c-0.2-0.3-0.5-0.7-1.6-2.2l-1.1-1.6l0.5,1.9c0.8,2.8,1.4,5.6,1.8,8.5
		c0.3,2.2,0.5,4.5,0.5,6.7c0,1.2-0.2,2.5-0.3,3.8s-0.3,2.6-0.5,4c-0.4,3-0.9,5.9-1.4,8.6c-0.3,1.3-0.6,2.5-1.1,3.7l-1.3,3.4
		c-1.6,4.3-2.9,7.5-3.6,9.1s-2.3,4.7-4.5,8.7c-1.1,2-2.3,4.2-3.7,6.6s-2.8,5-4.3,7.7c-3.6,6.7-6.8,12.8-9.2,17.1
		S1.1,587.2,1.3,587.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M23.8,554.6c0.2,0.1-0.3,2.5-1.7,6.3c-1.8,4.9-3.9,9.7-6.4,14.4c-2.6,4.6-5.6,8.9-9,12.9
		c-2.6,3.1-4.5,4.8-4.7,4.6s1.2-2.2,3.5-5.5c3-4.2,5.8-8.5,8.4-13c2.8-5.3,5.1-10.3,6.8-13.9S23.5,554.5,23.8,554.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M2.5,591.1c-0.5-0.3,3.6-7.6,9.1-16.2c5.3-8.7,9.7-15.8,10.2-15.5s-3.1,7.8-8.4,16.6
		C7.8,584.7,3,591.4,2.5,591.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M15.1,581.6c-0.3-0.2,0.7-2.1,2.3-5.2s3.7-7.4,6-12.2c4.9-9.5,9.4-16.9,9.9-16.6s-3.2,8.1-8,17.5
		c-2,4.1-4.2,8.1-6.6,12C16.8,580.1,15.4,581.8,15.1,581.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M16.1,589.6c-0.3-0.1,0.5-2.5,2-6.2s3.9-8.8,6.6-14.2c5.5-11,10.5-19.5,11-19.3s-3.7,9.3-9.1,20.2
		c-2.7,5.5-5.3,10.4-7.1,13.9S16.4,589.7,16.1,589.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M1.4,624.2c2.9-5.5,5.5-10.8,7.9-15.7c1.2-2.4,2.3-4.7,3.3-6.9s2-4.2,2.9-6.1c3.6-7.4,6.2-11.7,6.5-11.6
		s-1.8,4.7-5.1,12.2c-0.8,1.9-1.7,3.9-2.6,6.2c-0.9,2.2-2,4.6-3.1,7c-2.3,4.9-4.9,10.3-7.8,15.8C3.1,625.5,1.3,624.4,1.4,624.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M1.6,610.2c2.3-3.6,4.2-7.3,6-10.6l4.6-8.9c2.6-5.1,4.2-8.2,4.5-8.1s-0.9,3.5-3.2,8.7
		c-1.1,2.6-2.5,5.8-4.2,9.2c-1.8,3.7-3.7,7.4-5.9,10.9C3.2,611.5,1.5,610.3,1.6,610.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M12.6,552.1c-0.2-0.2,1.3-2,3.7-5.2l9.8-12.6l1.7,1.1l-2.3,3.9c-0.8,1.3-1.7,2.7-2.6,4
		c-4.1,6.3-8.7,12.2-13.7,17.7c-3.9,4.2-6.6,6.5-6.8,6.3s2.1-2.9,5.7-7.3s8.3-10.7,13.1-17.9c0.9-1.3,1.7-2.7,2.6-4s1.6-2.6,2.3-3.8
		l1.7,1.1c-4.1,5-7.7,9.2-10.4,12.2S12.8,552.3,12.6,552.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M34.5,519.5c0.2,0.2-0.7,1.8-2.2,4.3s-3.7,6.1-6.1,9.9c-4.9,7.7-9,13.8-9.5,13.5s2.9-6.9,7.8-14.6
		c2.1-3.3,4.3-6.5,6.7-9.6C32.9,520.7,34.3,519.3,34.5,519.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M2,561.6c-0.1-0.1,0.4-0.8,1.3-1.9s2.2-2.9,3.8-5c3.1-4.3,7-10.5,11.2-17.5c2.1-3.5,4.1-6.9,6.2-9.7
		s3.9-5.5,5.4-7.7c3.2-4.3,5.2-6.9,5.5-6.8s-1.4,3-4.2,7.6c-1.4,2.3-3.1,4.9-5.1,7.9s-3.9,6.2-6,9.7c-3.5,6-7.5,11.8-11.8,17.3
		c-1.3,1.7-2.8,3.2-4.3,4.7C2.8,561.3,2.1,561.7,2,561.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M41.2,511.5c0.7-1.3,1.3-2.6,1.8-3.9c0.3-0.6,0.5-1.3,0.7-1.8s0.4-1,0.7-1.5c0.9-1.9,1.7-3.3,2-3.8l1-1.5
		l1.1-1.6l-1.8,0.8c-1.1,0.6-2.2,1.3-3.1,2.2c-1.7,1.5-3.2,3.2-4.6,5.1c0.5,0.5,1.1,0.9,1.7,1.2c1.4-2,2.9-3.8,4-5.1
		c0.8-0.9,1.6-1.7,2.5-2.4l-0.8-0.8l-1,1.5c-0.3,0.5-1.3,1.8-2.5,3.6c-0.3,0.5-0.6,1-0.9,1.5l-0.8,1.7l-1.9,3.7
		C39.8,511,40.5,511.3,41.2,511.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M7.8,524.1c-0.2-0.1,1.4-4.1,4.6-10.6s7.9-15.3,13.5-25.2l1.8,0.9c-1.2,2.7-2.4,5.4-3.6,8.2
		c-2.8,6.2-5.3,12.1-7.9,17.4s-4.9,10-6.9,14s-3.7,7.1-4.9,9.3s-1.9,3.3-2.1,3.3s2-5.2,5.7-13.2c1.8-4,4.1-8.8,6.5-14.1
		s5-11.3,7.7-17.5l3.7-8.2l1.8,0.9C22,499.3,17,508,13.5,514.2S8,524.2,7.8,524.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M2.8,522.3c0.3,0.1,1.6-1.7,3.4-4.8c2.5-4.1,4.4-8.5,5.9-13.1c2.6-8.7,3.6-16.3,4.2-18.7
		c0.3-1.1,0.9-3.1,2.3-8.1l-1.2-0.2c-0.2,1.1-0.9,5.5-1.8,10.5c-1.3,7.7-2.3,14.4-2.6,16.5l-0.4,2.7l1.1-2.5l1-2.3
		c0.5-1.2,1.7-3.4,3.2-6.4c0.4-0.8,0.8-1.6,1.1-2.4c0.3-0.9,0.7-1.8,1-2.7c0.7-1.9,1.4-3.9,2.2-6c1.8-5.3,3.3-10,4.3-13.5
		s1.6-5.6,1.4-5.8s-1.4,1.9-2.8,5.3s-3.2,8.1-4.9,13.3c-0.7,2.1-1.3,4.2-1.9,6.1c-0.3,1-0.6,1.9-0.8,2.8c-0.2,0.8-0.5,1.7-0.8,2.5
		c-1.2,3.1-2,5.5-2.5,6.7c-0.1,0.3-0.3,0.7-1,2.2l0.7,0.2c1.7-5.3,3-10.8,4-16.3c0.9-5,1.2-9.7,0.9-10.8l-0.7-3.2l-0.6,3
		c-1.1,5.1-1.4,7.2-1.7,8.2c-0.6,2.5-3.1,9.7-5.6,18.3c-1.5,4.4-3.2,8.8-5.2,13C3.4,520,2.5,522.1,2.8,522.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M31.7,463c-0.6-0.2-2.3,4-4.2,9.1c-1.5,4.2-2.1,8-2.6,9.2s-2.5,4.5-4.1,8.7s-2.3,8-2.8,9.2s-2.6,4.4-4.2,8.6
		c-2.1,5.1-3.3,9.4-2.8,9.7s2.7-3.8,4.7-8.9c1.7-4.2,2.4-8,2.9-9.1s2.6-4.4,4.2-8.6s2.3-8,2.7-9.2c1.5-2.8,2.8-5.7,4-8.7
		C31.3,467.6,32.2,463.2,31.7,463z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M156.5,680.5c0-0.5-3.1-1-8.1-1.8c-2.5-0.4-5.5-0.8-8.6-1.7c-1.6-0.4-3.1-0.9-4.6-1.6
		c-1.6-0.9-3.3-1.8-5.1-2.7c-5.8-2.8-11.8-4.5-16.8-4.3c-1.3,0.1-2.3,0.2-3.4,0.3l-2.8,0.3c-1.7,0.2-3,0.3-3.9,0.3
		c-1.8,0-5.4-0.2-10-0.5c-2.3-0.2-5-0.4-7.7-0.7s-5.8-0.6-8.7-1.2c-5.8-1.1-10.8-3.9-14.8-6.4s-6.8-4.6-8.3-5.7l-3.1-2.5
		c-1.3-1.1-2.9-2.4-4.9-3.8c-2.2-1.6-4.5-3-6.9-4.1c-2.8-1.4-5.8-2.4-8.8-3.1c-2-0.5-4-0.8-5.7-1l-5.2-0.8l-8.9-1.3
		c-5.1-0.7-8.2-1-8.3-0.5s2.9,1.6,7.8,3c2.5,0.7,5.5,1.4,8.8,2c1.7,0.3,3.4,0.7,5.2,1s3.6,0.6,5.5,1c5.3,1.2,10.5,3.1,15.3,5.7
		c2,1.1,3.8,2.1,5.3,3c1.1,0.7,2.2,1.4,3.3,2.2c1.4,1.1,3.9,3.7,7.6,6.7c2.1,1.7,4.2,3.3,6.6,4.6c2.8,1.6,5.8,2.7,8.9,3.4
		c3,0.5,6.1,0.8,9.2,0.8c2.9,0,5.5,0,7.9-0.2c4.7-0.3,8.3-0.7,10.1-0.7c0.9,0,2.3,0,4,0.1l2.8,0.1c1,0,2.2,0,3.3,0.2
		c4.4,0.4,9.8,2.5,15.3,5.2c1.7,0.8,3.4,1.7,5,2.5c1.7,0.8,3.6,1.4,5.5,1.7c3,0.6,6.1,0.9,9.1,1C153.3,681.4,156.5,681,156.5,680.5z
		"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M27,580.5c-0.2,0-0.1,0.5,0.2,1c0.2,0.3,0.4,0.6,0.6,0.9l0.4,0.5l0.2,0.3l0.1,0.2c0,0.1,0,0.1,0,0.2
		c0,0,0.1,0.2,0.1,0.4l0.2,0.9c0.2,0.6,0.4,1.2,0.7,1.9c0.7,1.5,1.5,2.9,2.5,4.1c1.2,1.5,2.6,2.8,4.1,4c0.8,0.6,1.7,1.2,2.6,1.8
		c0.5,0.3,0.9,0.5,1.4,0.8l0.8,0.4c0.1,0,0,0,0.1,0l0,0c0.1,0.3,0.2,0.5,0.3,0.8c0.2,0.5,0.4,1.1,0.7,1.7c0.5,1.1,1.1,2.2,1.9,3.2
		c1,1.4,2.2,2.7,3.6,3.8c0.6,0.5,1.3,1,1.8,1.5s1.3,1,1.9,1.4c2.4,1.5,4.8,2.8,7.3,4c0.5,0.2,0.9,0.4,1.3,0.7c0.4,0.4,0.7,0.7,1,1.1
		l1.8,2.3c0.5,0.6,1,1.3,1.6,1.9c0.5,0.5,1,0.9,1.3,1.2c0.7,0.7,1.7,1.7,2.9,2.9c0.6,0.6,1.3,1.3,2.1,2.1c0.4,0.4,0.8,0.8,1.3,1.2
		c0.2,0.2,0.5,0.4,0.7,0.6c0.1,0.1,0.2,0.1,0.3,0.2l0.1,0.1l0,0c0.3-0.5,0.1-0.1,0.1-0.2v0.1l-0.1,0.1c0,0.1,0,0,0,0.1l0.2,0.2
		c0.8,0.8,1.7,1.8,2.6,2.8c0.5,0.5,1,1,1.6,1.5c0.7,0.6,1.4,1.1,2.2,1.4c1.6,0.6,3.2,0.9,4.9,1.1l2.4,0.3c0.4,0,0.8,0.1,1.1,0.1
		c0.3,0.1,0.7,0.1,1,0.3c1.5,0.4,3,1,4.6,1.5c1.5,0.5,3.1,0.9,4.8,1.1c2.8,0.2,5.6,0.1,8.4-0.2c1.3-0.1,2.4-0.3,3.5-0.4l0.8-0.1
		c0.2,0,0.4-0.1,0.6-0.1c0.4,0,0.8,0,1.2,0.1c1.3,0.4,2.6,0.8,3.8,1.4c1.3,0.6,2.6,1.1,4,1.4c2.1,0.4,4.3,0.7,6.5,0.7
		c2.5,0.1,5.3,0.1,8.2,0c0.3,0,0.6,0,0.9,0c0.3,0,0.6,0.1,0.9,0.2c0.7,0.2,1.4,0.4,2.2,0.6c1.5,0.4,3.1,0.8,4.8,1.2
		c1.1,0.2,2.1,0.4,3.2,0.5c1.1,0.1,2.2,0,3.3-0.2c1.9-0.3,3.7-0.7,5.6-1.2l2.5-0.7l0.3-0.1h0.1l0,0h0.2c0.3,0,0.7,0.1,1,0.2
		c1.5,0.3,2.9,0.7,4.1,1.1l0.9,0.3c0.3,0.1,0.6,0.2,1,0.3c0.6,0.1,1.3,0.1,1.9,0.1c0.9-0.1,1.9-0.2,2.8-0.4c1.5-0.3,2.3-0.7,2.3-0.9
		s-0.9-0.4-2.4-0.5c-0.7-0.1-1.6-0.1-2.6-0.3c-0.5,0-1-0.1-1.4-0.3l-1.7-0.7c-1.3-0.5-2.6-1.1-4.2-1.5c-0.4-0.1-0.9-0.3-1.3-0.3
		c-0.1,0-0.2,0-0.4-0.1c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.5,0.1l-0.3,0.1l-2.5,0.5c-1.7,0.4-3.5,0.7-5.3,0.9
		c-0.8,0.1-1.7,0.2-2.6,0.1c-0.9-0.1-1.8-0.2-2.7-0.4l-4.7-1.1c-0.8-0.2-1.6-0.3-2.4-0.5c-0.4-0.1-0.8-0.1-1.3-0.2
		c-0.4,0-0.9,0-1.3,0c-3,0.3-5.7,0.6-8.1,0.8c-2.1,0.2-4.2,0.3-6.3,0.1c-1.3-0.1-2.6-0.4-3.9-0.9c-0.4-0.2-0.9-0.4-1.6-0.7
		c-0.7-0.4-1.5-0.7-2.3-1c-0.5-0.2-1-0.3-1.6-0.4c-0.3,0-0.6,0-0.9,0h-0.8c-1.1,0-2.2,0-3.4,0c-2.6-0.1-5.3-0.3-7.9-0.7
		c-1.4-0.3-2.8-0.7-4.1-1.3c-1.5-0.5-2.9-1.1-4.6-1.6c-0.5-0.1-0.9-0.2-1.4-0.3c-0.5-0.1-0.9-0.1-1.3-0.2l-2.4-0.2
		c-1.4,0-2.8-0.2-4.1-0.6c-0.5-0.2-1-0.5-1.5-0.8c-0.5-0.4-1.1-0.8-1.6-1.2c-1-0.8-1.9-1.7-3.1-2.5c-0.2-0.1-0.3-0.2-0.5-0.3
		l-0.2-0.1l-0.1-0.1c-0.2-0.1-0.5-0.3-0.7-0.4l-1.3-0.9c-0.9-0.6-1.6-1.2-2.4-1.7c-1.4-1.1-2.5-2-3.2-2.6c-0.4-0.4-0.8-0.8-1.2-1.2
		c-0.5-0.6-0.9-1.2-1.3-1.9L62,615c-0.3-0.5-0.6-1-1-1.5c-0.1-0.2-0.3-0.3-0.4-0.4c-0.1-0.1-0.3-0.2-0.4-0.3l-0.8-0.5
		c-2.1-1.3-4.4-2.8-6.6-4.5c-0.6-0.5-1-0.9-1.6-1.4l-1.7-1.5c-1.1-1-2.1-2.1-3-3.3c-0.5-0.8-1-1.6-1.4-2.5c-0.2-0.5-0.4-0.9-0.6-1.4
		c-0.1-0.2-0.2-0.5-0.3-0.7l-0.5-1.5c-0.5-0.2-1-0.4-1.4-0.6l-0.7-0.3c-0.4-0.2-0.9-0.4-1.3-0.7c-0.8-0.5-1.6-1-2.4-1.5
		c-1.4-1-2.7-2.1-3.9-3.3c-1-1-1.8-2.1-2.6-3.3c-0.3-0.5-0.6-1.1-0.8-1.6l-0.3-0.7l-0.2-0.4l-0.2-0.5l-0.4-0.4l-0.3-0.2l-0.5-0.4
		l-0.8-0.6C27.4,580.6,27.2,580.5,27,580.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M167,541.5c0.1-0.1,0-0.4-0.5-0.9c-0.7-0.5-1.5-0.9-2.3-1.1c-1.2-0.3-2.5-0.3-3.8-0.3c-0.8,0-1.5,0.1-2.3,0.3
		c-0.7,0.1-2,0.4-1.7,0.3v-0.1l0,0l-0.3-0.2c-0.4-0.3-0.8-0.5-1.2-0.7c-0.8-0.5-1.7-0.8-2.6-1.2c-1.9-0.6-3.8-0.9-5.8-1.1
		c-2.6-0.2-5.1,0-7.7,0.4c-1,0.2-2,0.4-2.7,0.6c-0.3,0.1-0.6,0.2-0.9,0.3L135,538l-0.2-0.1h-0.1l-0.2-0.1l-0.9-0.5
		c-0.5-0.3-1.1-0.5-1.6-0.7c-1.4-0.6-2.9-0.9-4.4-1.1c-0.5-0.1-0.9-0.2-1.2-0.2c-1.9-0.3-3.8-0.5-5.6-0.5c-1.6,0-3.1,0.2-4.7,0.5
		c-0.9,0.2-1.8,0.4-2.7,0.7c-0.5,0.1-1,0.3-1.5,0.5l-0.7,0.3h-0.1c-0.1-0.1,0.3,0.1-0.8-0.6c-1-0.6-2.1-1.1-3.2-1.5
		c-2.4-0.8-4.8-1.2-7.3-1.2H99l-0.8,0.1l-1.6,0.1c-1,0.2-2,0.3-2.9,0.6c-2.6,0.7-5.1,1.5-7.5,2.6l-1,0.4l-1,0.4l-0.7,0.3l-0.6,0.2
		l-0.3-0.1l-0.1-0.1l-0.3-0.1L81,537c-1.3-0.5-2.6-0.8-4-1c-3.8-0.6-7.6-0.2-11.3,0.9c-1.7,0.5-3.3,1.2-4.9,2
		c-0.8,0.4-1.5,0.8-2.2,1.2c-0.3,0.2-0.7,0.4-1,0.6l-0.3,0.2l-0.1,0.1l0,0l0,0h-0.1l-0.6-0.1c-0.5,0-0.9-0.1-1.3-0.1
		c-0.8,0-1.7,0-2.5,0.1c-1.3,0.1-2.6,0.4-3.9,0.8c-2.1,0.7-3.2,1.5-3.1,1.8s1.4,0.2,3.4,0.1c1.2-0.1,2.4-0.1,3.6,0
		c0.7,0,1.4,0.1,2.2,0.2c0.4,0,0.8,0.1,1.2,0.2l1.9,0.4l0.9-0.5l0.2-0.1l0.1-0.1l0.2-0.1l1-0.5c0.7-0.3,1.3-0.7,2-1
		c1.5-0.7,3-1.2,4.5-1.7c3.2-1.1,6.6-1.6,10-1.7c1.2,0,2.5,0.1,3.7,0.2l1.3,0.3l0.3,0l0.4,0.1c0.2,0,0.4,0.1,0.6,0.1l1-0.5l0.6-0.3
		l0.9-0.4c0.5-0.2,0.7-0.3,1-0.4c2.4-0.8,4.9-1.2,7.5-1.3c0.9-0.1,1.8,0,2.8-0.1l1.5,0.1h0.8l0.8,0.1c2.1,0.2,4.3,0.7,6.3,1.4
		c0.9,0.3,1.8,0.7,2.7,1.2c0.5,0.2,2.1,1.1,2.3,1.1l1.3-0.7l0.7-0.3c0.4-0.2,0.9-0.4,1.3-0.5c0.8-0.3,1.6-0.7,2.4-0.9
		c1.3-0.4,2.6-0.8,4-1.1c1.7-0.4,3.5-0.5,5.3-0.3c0.4,0,0.7,0.1,1.1,0.2c0.5,0.1,1.1,0.3,1.6,0.5c0.8,0.3,1.5,0.6,2.2,1
		c0.5,0.2,0.9,0.5,1.4,0.8l0.7,0.5l0.5,0.4l0.7,0.5c0.2,0,0.5,0,1,0h1l2.6-0.1c2,0,4.5-0.1,7.1,0.1c1.7,0.1,3.4,0.4,5,0.8
		c0.7,0.2,1.4,0.5,2.1,0.8c0.3,0.1,0.6,0.3,0.9,0.5l1.6,1c1.8-0.6,2.1-0.7,2.9-1s1.4-0.4,2-0.5c1.1-0.3,2.2-0.4,3.3-0.5
		c0.7,0,1.4,0,2.1,0.1C166.6,541.6,166.9,541.6,167,541.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M169.1,554.5c0-0.2-1.4-0.2-4-0.4c-1.5-0.2-3-0.5-4.5-0.9c-0.9-0.3-1.8-0.6-2.7-1c-0.4-0.2-0.9-0.4-1.4-0.7
		c-0.2-0.1-0.4-0.3-0.7-0.4l-0.3-0.2l-0.6-0.4l-1.4,0.3c-0.4,0.1-0.7,0.2-1.1,0.2c-0.7,0.1-1.5,0.3-2.2,0.3
		c-1.6,0.1-3.2,0.1-4.8-0.1c-1.7-0.2-3.5-0.5-5.1-1c-0.9-0.2-1.7-0.5-2.6-0.9c-0.4-0.2-0.9-0.3-1.3-0.5l-0.6-0.3l-0.3-0.2l-0.6-0.3
		l-1.5,0.3c-0.4,0.1-0.7,0.2-1.1,0.2c-0.7,0.1-1.5,0.3-2.2,0.3s-1.5,0.1-2.3,0.1h-1.2l-1.2-0.1c-3-0.2-6-0.7-8.9-1.5
		c-1.2-0.3-2.3-0.7-3.1-0.9s-1.2-0.4-2-0.7l-1.6,0.5c-0.4,0.1-0.8,0.3-1.2,0.4c-0.8,0.2-1.6,0.4-2.4,0.6c-1.5,0.3-3,0.5-4.4,0.6
		c-2.4,0.2-4.9,0.2-7.3,0c-1.7-0.1-3.5-0.4-5.2-0.7c-0.7-0.1-1.3-0.3-1.9-0.4l-0.8-0.2l-0.8-0.2l-1.2,0.5c-0.2,0.1-0.4,0.2-0.7,0.3
		l-0.7,0.2c-2.2,0.7-4.5,1.1-6.8,1.2c-1.7,0.2-3.7,0.3-6.1,0.3c-1.2,0-2.5,0-3.9,0l-2.2-0.1l-1.2-0.1l-1.4-0.1l-0.7,0.5
		c-0.3,0.2-0.6,0.4-0.9,0.6c-1,0.6-2.1,1-3.2,1.3c-1.6,0.4-3.1,0.8-4.7,0.9c-0.9,0.1-1.8,0.2-2.8,0.2h-0.7h-1.2c-0.5,0-0.8,0-1.4,0
		l-1.6-0.1c0,0.1-0.1,0.3-0.1,0.7l-0.1,0.3c0,0.1,0,0,0,0l-0.2,1c0.2,0,0.5,0.2,1,0.4l0.4,0.2l0.2,0.1l0.1,0.1l0.4,0.3
		c0.2,0.2,0.4,0.4,0.6,0.6c0.2,0.2,0.3,0.4,0.5,0.6l0.2,0.3l0,0l0.1,0.1l0.1,0.3l0.3,0.6h2c0.4,0,0.8,0.1,1.2,0.1
		c0.8,0.1,1.5,0.2,2.3,0.3c1.3,0.3,2.5,0.7,3.7,1.2c0.8,0.4,1.6,0.9,2.4,1.4l0.7,0.6l0.1,0.1l0.2,0.2l0.3,0.3l2.8-0.3l2.3-0.1
		c1.4,0,2.7,0.1,4.1,0.2c2.2,0.2,4.3,0.7,6.4,1.3c1.5,0.5,2.9,1,4.3,1.7c0.6,0.3,1,0.5,1.4,0.8c0.2,0.1,0.4,0.3,0.6,0.4
		c0.2,0.1,0.4,0.3,0.6,0.4l3.1-0.6c2.5-0.4,5-0.6,7.6-0.5c2.2,0,4.4,0.2,6.5,0.6c1.3,0.2,2.7,0.5,4,0.9c0.7,0.2,1.4,0.4,2.2,0.7
		c0.4,0.1,0.7,0.3,1.1,0.4l0.6,0.3l0.4,0.2l0.4,0.2l2.7-0.8c1.4-0.4,2.8-0.7,4.3-0.9c4-0.6,8.1-0.3,12.1,0.8
		c1.1,0.3,2.2,0.7,3.2,1.1c0.5,0.2,1,0.4,1.5,0.7l0.7,0.4l0.4,0.2c0.2,0.1,0.5,0.3,0.7,0.4l2.7-1c0.7-0.3,1.5-0.5,2.2-0.7
		c1.4-0.4,2.9-0.7,4.4-1c2.7-0.4,5.5-0.5,8.3-0.4c2.4,0.1,4.7,0.4,7.1,1c1.1,0.3,2,0.5,3,0.8c0.4,0.2,0.9,0.3,1.3,0.5l0.6,0.2
		l0.9,0.4l0.5-0.4c0.1-0.1,0.3-0.3,0.4-0.3s0.4-0.3,0.6-0.4c0.4-0.2,0.7-0.5,1.1-0.7c0.6-0.3,1.3-0.5,2-0.7c0.6-0.1,1.2-0.3,1.7-0.5
		c0-0.1-0.6-0.2-1.9-0.1c-0.7,0.1-1.5,0.3-2.2,0.5c-0.5,0.2-0.9,0.4-1.3,0.6c-0.2,0.1-0.4,0.3-0.7,0.4l-0.4,0.3l-0.3-0.2l-0.6-0.3
		c-0.4-0.2-0.9-0.4-1.3-0.6c-0.9-0.4-1.9-0.7-3-1c-2.4-0.7-4.8-1.2-7.3-1.4c-2.9-0.2-5.8-0.2-8.7,0.2c-1.6,0.2-3.1,0.5-4.6,0.9
		c-0.8,0.2-1.6,0.4-2.3,0.7l-1.9,0.6l0,0l-0.4-0.2c-0.3-0.2-0.5-0.3-0.8-0.4c-0.5-0.3-1.1-0.5-1.6-0.8c-1.1-0.5-2.3-0.9-3.5-1.2
		c-4.2-1.2-8.6-1.4-12.9-0.7c-1.5,0.3-3,0.6-4.5,1.1l-1.5,0.6l-0.5,0.2l-0.1,0.1l-0.3-0.1c-0.2-0.1-0.4-0.2-0.6-0.3
		c-0.4-0.2-0.8-0.3-1.2-0.4c-0.8-0.3-1.5-0.5-2.3-0.7c-1.4-0.3-2.8-0.6-4.2-0.8c-2.2-0.3-4.5-0.4-6.7-0.3c-2.6,0.1-5.1,0.4-7.7,0.8
		l-2.8,0.5c-0.1-0.1-0.2-0.2-0.4-0.3l-0.6-0.4c-0.4-0.3-0.9-0.6-1.5-0.9c-1.4-0.8-2.8-1.5-4.3-2c-2.1-0.8-4.3-1.3-6.6-1.7
		c-1.4-0.2-2.8-0.3-4.3-0.4h-2.4l-1.2,0.1H62h-0.2v-0.1l0,0l-0.1-0.2c-0.2-0.2-0.5-0.5-0.8-0.7c-0.8-0.7-1.7-1.2-2.6-1.7
		c-1.3-0.7-2.7-1.2-4.1-1.5c-0.8-0.2-1.7-0.3-2.5-0.4c-0.4,0-0.9-0.1-1.3-0.1h-0.7l-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.3
		c-0.2-0.3-0.4-0.5-0.6-0.8c-0.2-0.2-0.1-0.1-0.1-0.1l0,0l0,0h0.2l1.1-0.1c1-0.1,2-0.2,2.9-0.3c1.7-0.2,3.4-0.6,5-1.2
		c1.2-0.4,2.4-1,3.5-1.7c0.4-0.2,0.7-0.5,1-0.8l0.2-0.2l0,0l0.1-0.1h2.1c0.8,0,1.5,0,2.2,0c1.4,0,2.7,0,3.9-0.1
		c2.4-0.1,4.4-0.3,6.1-0.5c2.3-0.3,4.7-0.8,6.9-1.5l0.7-0.2c0.2-0.1,0.5-0.2,0.7-0.3l1-0.4l1.3,0.4c0.6,0.2,1.2,0.3,1.9,0.5
		c1.7,0.4,3.5,0.8,5.3,1c2.5,0.3,5,0.4,7.5,0.3c1.6-0.1,3.1-0.2,4.6-0.5c0.8-0.1,1.7-0.3,2.5-0.5c0.4-0.1,0.9-0.3,1.3-0.4l0.7-0.2
		l0.3-0.1l0,0c0.5,0.3,0.9,0.5,1.4,0.6c0.8,0.3,1.9,0.7,3.2,1.1c3,0.9,6.2,1.5,9.3,1.7l1.3,0.1h1.3c0.8,0,1.6-0.1,2.5-0.1
		s1.6-0.2,2.4-0.3c0.4-0.1,0.8-0.2,1.2-0.3l0.8-0.2l0,0l0.4,0.2l0.7,0.3c0.5,0.2,0.9,0.4,1.4,0.5c0.9,0.3,1.9,0.6,2.8,0.9
		c1.8,0.5,3.6,0.8,5.5,1c1.7,0.1,3.4,0.1,5.2-0.1c0.8,0,1.6-0.3,2.4-0.4c0.4-0.1,0.8-0.2,1.1-0.3l0.6-0.2l0.2-0.1l0.5,0.3
		c0.3,0.2,0.5,0.3,0.8,0.4c0.5,0.2,1,0.5,1.5,0.6c0.9,0.3,1.9,0.6,2.9,0.8c1.6,0.3,3.1,0.5,4.7,0.5
		C167.7,555,169.1,554.6,169.1,554.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M54.4,577.5c0,0.1-0.3,0.3-0.9,0.4c-0.9,0.2-1.8,0.3-2.7,0.3c-3,0.1-6-0.6-8.7-2c-1.5-0.7-2.8-1.7-4-2.9
		c-0.5-0.5-1-1-1.4-1.6c-0.3-0.4-0.6-0.8-0.8-1.2c0,0-0.8-0.1-1.2-0.2c-0.4-0.1-0.8-0.2-1.2-0.3c-0.6-0.2-1-0.4-1-0.5s0.4-0.2,1-0.3
		l1.2-0.1c0.5,0,0.7,0,1.9-0.2c0.4,0.6,0.9,1.2,1.5,1.6c0.4,0.5,0.9,0.9,1.4,1.3c1.1,1,2.3,1.8,3.6,2.4c2.5,1.2,5.1,2,7.8,2.4
		c1.1,0.2,1.9,0.3,2.6,0.3S54.4,577.4,54.4,577.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M80.4,583.5c0,0.1-0.3,0.3-0.9,0.5c-0.8,0.3-1.7,0.5-2.6,0.6c-2.9,0.4-5.9,0.3-8.9-0.3
		c-2.9-0.5-5.8-1.4-8.4-2.8c-2-1-3.1-2-3-2.2c0.3-0.5,5.2,1.8,11.7,2.9C74.9,583.4,80.3,582.9,80.4,583.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M137,586c0.1,0.2-2.8,1.6-7.9,2.3c-3,0.4-6.1,0.4-9.1,0c-1.8-0.2-3.6-0.6-5.4-1c-0.9-0.3-1.8-0.5-2.8-0.9
		c-0.5-0.2-0.9-0.4-1.4-0.6l-0.7-0.3l-0.3-0.1l0,0l-0.9,0.3c-0.5,0.2-0.9,0.3-1.4,0.4c-2.1,0.5-4.2,0.9-6.4,1c-2,0.2-4,0.2-5.9,0.1
		c-3.3-0.1-6.7-0.6-9.9-1.4c-0.4-0.1-0.7-0.2-1-0.3c-0.4-0.1-0.8-0.3-1.1-0.5c-0.5-0.5-0.9-1.1-1.1-1.8c-0.3-1-0.4-1.9-0.5-2.9
		c-0.1-1.6,0.1-2.5,0.2-2.5s0.3,0.9,0.6,2.4c0.2,0.9,0.4,1.8,0.8,2.6c0.2,0.4,0.4,0.9,0.8,1.2c0.5,0.2,1.1,0.4,1.6,0.5
		c3.2,0.6,6.4,0.9,9.7,0.9c1.9,0,3.8,0,5.7-0.2c2-0.1,4-0.5,6-1c0.4-0.1,0.9-0.2,1.3-0.4l1.8-0.6l0.6,0.3l0.4,0.2l0.6,0.3
		c0.4,0.2,0.9,0.4,1.3,0.5c0.9,0.3,1.7,0.6,2.6,0.9c1.7,0.5,3.3,0.9,5.1,1.1c2.9,0.4,5.8,0.6,8.7,0.4
		C133.9,586.5,136.9,585.7,137,586z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M175.1,585.9c0,0.2-1.2,0.8-3.4,1.1c-1.3,0.2-2.7,0.3-4,0.2c-0.8,0-1.5,0-2.4-0.1c-0.4,0-0.8-0.1-1.3-0.1
		l-0.6-0.1h-0.3l0,0c0,0,0.1,0-0.1,0.1l-0.5,0.2c-0.3,0.1-0.6,0.2-0.9,0.3c-1.8,0.5-3.7,0.8-5.6,0.8c-1.5,0.1-3,0-4.5-0.1
		c-2.6-0.2-4.1-0.7-4.1-0.9s1.6-0.4,4.1-0.5c3.2-0.1,6.4-0.5,9.5-1.3c0.3-0.1,0.6-0.1,0.8-0.3l1.1-0.4l0.9,0.2l0.6,0.1l1.2,0.2
		c0.8,0.1,1.5,0.2,2.2,0.3c1.4,0.1,2.7,0.2,3.8,0.2C173.7,585.6,175.1,585.6,175.1,585.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M165.1,583c-0.6-0.2,0.2-3,0.4-6.4c0.1-1.4,0-2.9-0.1-4.3c-0.1-1.1-0.2-1.8,0-2s0.9,0.4,1.4,1.6
		c0.6,1.5,0.9,3.2,0.8,4.8c-0.1,1.6-0.5,3.2-1.1,4.7C165.9,582.5,165.4,583.1,165.1,583z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M135.9,578.1c-0.3,0.1-0.7-0.3-1-1.1c-0.4-1-0.6-2-0.7-3c-0.2-2.4,0.4-4.3,0.9-4.3s1,1.9,1.2,4.1
		S136.4,577.9,135.9,578.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M109.5,584.3c-0.5,0.2-2-3.4-2.2-8c-0.1-2,0.1-4,0.7-5.9c0.5-1.5,1.1-2.3,1.4-2.2s0.2,1,0.1,2.5
		s-0.1,3.4-0.1,5.6C109.6,580.5,110.1,584.1,109.5,584.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M91.4,574.9c0,0.3-0.9,0.5-2.5,0.7c-0.8,0.1-1.7,0.1-2.7,0.2h-1.6h-0.9c-0.6,0-1.1,0-1.7-0.1
		c-0.1-0.6-0.1-1.1-0.1-1.7v-0.4V573c0-0.6,0.1-1.1,0.1-1.6c0.1-0.9,0.3-1.8,0.5-2.7c0.4-1.5,0.8-2.4,1.1-2.3s0.4,1,0.4,2.5v2.6v1.5
		v0.7l0,0h0.7l1.6,0.1c1,0.1,1.9,0.2,2.7,0.3C90.4,574.3,91.4,574.6,91.4,574.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M62,562.2c-1.5,1.3-2.8,2.9-3.8,4.6c-0.8,1.3-1,2.3-1.4,2.2s-0.6-1.1,0-2.8c0.8-2.1,2.1-4.1,3.8-5.5
		c0.3,0.2,0.5,0.5,0.7,0.8l0.5,0.5L62,562.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M48.8,557.9c-1.5,0.4-2.8,0.9-4.1,1.8c-0.6,0.4-1.2,0.8-1.7,1.3l-0.8,0.7l-0.3,0.4l-0.2,0.2l0,0l-0.1,0.1
		l0.1,0.1c0.5,0.3,1.1,0.7,1.7,1c1.1,0.6,2.1,1.2,3.1,1.7c3.8,1.9,6.5,2.4,6.4,2.8s-2.9,0.3-7-1.4c-1-0.4-2.2-0.9-3.3-1.5
		c-0.6-0.3-1.2-0.6-1.8-1s-1.1-0.7-1.7-1c-0.2-0.1,0-0.2,0-0.4l0.3-0.4l0.6-0.8c0.1-0.1,0.2-0.3,0.3-0.4l0.2-0.2l0.4-0.5l0.8-0.8
		c0.6-0.6,1.3-1.1,1.9-1.5c1.5-1,3.1-1.7,4.8-2.1C48.7,555.8,48.9,557.9,48.8,557.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M35.2,569.5c0.7-2.7,2.3-4.5,2.8-4.1s-0.2,2.2-0.7,4.6c-0.1,0-0.2,0-0.3-0.1l-0.7-0.2l-0.7-0.2L35.2,569.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M45.4,554.8c-2.8-0.4-5-0.3-5.2-0.9c-0.1-0.3,0.5-0.7,1.5-1c1.3-0.3,2.7-0.4,4-0.2c0,0.3-0.1,0.7-0.1,1
		l-0.1,0.7C45.5,554.5,45.5,554.7,45.4,554.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M61,549.7c-0.1-0.5-0.3-0.9-0.5-1.3l-0.5-1.2c-0.3-0.7-0.4-1.1-0.3-1.4s0.8-0.1,1.5,0.5c0.4,0.3,0.7,0.7,1,1.2
		c0.4,0.6,0.6,1.2,0.8,1.8C63,549.3,61,549.7,61,549.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M85.9,546.9c-0.5,0.3-2-1.2-2.9-3.7s-0.6-4.5,0-4.6s1.3,1.8,2,3.9S86.4,546.5,85.9,546.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M111.4,546.9c-0.5,0.2-1.6-1.7-1.7-4.2s0.9-4.5,1.4-4.3s0.6,2,0.7,4.2S112,546.7,111.4,546.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M133.7,549.4c-0.3-1.7-0.4-3.5-0.2-5.2c0.2-1.3,0.6-2.1,0.9-2.1s0.4,0.8,0.6,2.1l0.8,4.8
		C135.1,549.2,134.4,549.3,133.7,549.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M153.8,551.3c0-2.9,0.8-5.2,1.4-5.1s0.7,2.3,0.7,5.1c-0.1,0-0.2,0-0.3,0h-1.4L153.8,551.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M164,585.4c1.1,2.9,1.7,6,1.6,9.2c-0.1,2.4-0.6,3.8-0.9,3.8s-0.4-1.5-0.6-3.7c-0.3-2.9-1-5.7-2.1-8.5
		C162.7,585.9,163.3,585.6,164,585.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M138.4,603.6c-0.6-0.2,0-3.8-0.2-8.3s-0.9-8.1-0.3-8.3s2.3,3.4,2.4,8.2c0.1,2.1-0.1,4.1-0.7,6.1
		C139.2,602.8,138.6,603.7,138.4,603.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M111,584.7c-0.2,3.9-0.1,7.8,0.3,11.7c0.3,3,0.8,4.8,0.5,4.9s-1.2-1.6-2-4.7c-0.9-4-1.3-8-1-12.1
		C109.6,584.4,110.3,584.5,111,584.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M110.1,602.1c0.1,0.3-2.2,1-6,1.6c-2.2,0.3-4.5,0.5-6.7,0.5c-2.8,0-5.6-0.4-8.3-1.3c-1.5-0.5-2.8-1.3-3.9-2.4
		c-0.9-1-1.8-2.1-2.4-3.4c-1.1-2-1.8-4.2-2.2-6.5c-0.5-3.9,0-6.3,0.3-6.2s0.3,2.4,1.1,5.9c0.5,2.1,1.3,4,2.4,5.9
		c0.6,1,1.4,2,2.2,2.9c0.9,0.9,2,1.5,3.2,1.9c2.5,0.8,5,1.2,7.6,1.3c2.4,0.1,4.7,0,6.5,0C107.7,602.1,110.1,601.8,110.1,602.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M82.5,599.8c0,0.3-2.1,0.5-5.4,0.4c-2-0.1-3.9-0.3-5.9-0.7c-2.4-0.4-4.8-1.2-7-2.3c-0.6-0.3-1.2-0.7-1.7-1.1
		c-0.5-0.4-1-0.9-1.5-1.3c-0.8-0.9-1.5-1.9-2.1-3c-0.9-1.8-1.7-3.6-2.2-5.6c-0.9-3.3-0.9-5.4-0.6-5.4s0.8,1.9,2,5
		c0.7,1.8,1.5,3.5,2.4,5.1c0.6,0.9,1.2,1.8,2,2.5c0.4,0.4,0.8,0.8,1.2,1.1c0.5,0.3,1,0.6,1.5,0.9c2,1,4.2,1.8,6.4,2.3
		c2.1,0.5,4,0.8,5.6,1.1C80.5,599.2,82.5,599.5,82.5,599.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M58.6,594.6c0,0.3-1.8,0.4-4.6-0.2c-1.6-0.4-3.2-0.8-4.8-1.5c-0.9-0.4-1.8-0.8-2.7-1.3
		c-0.5-0.3-0.9-0.5-1.4-0.8c-0.5-0.3-1-0.6-1.4-1c-0.9-0.8-1.6-1.7-2.1-2.7c-0.5-0.9-0.9-1.8-1.3-2.7c-0.6-1.5-1.1-3.1-1.5-4.8
		c-0.7-2.8-0.7-4.5-0.5-4.6s0.9,1.6,1.9,4.1c0.5,1.3,1.1,2.8,1.8,4.4c0.4,0.8,0.8,1.7,1.3,2.4c0.4,0.8,1,1.5,1.7,2.2
		c0.4,0.3,0.8,0.6,1.2,0.8c0.4,0.3,0.8,0.5,1.3,0.8c0.8,0.5,1.7,0.9,2.5,1.3c1.6,0.8,3.1,1.3,4.4,1.8
		C57,593.9,58.7,594.3,58.6,594.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M29.7,582.1c5,2.9,9.7,3.9,9.6,4.5c0,0.3-1.3,0.4-3.3,0c-2.6-0.5-5.1-1.4-7.4-2.7
		C29,583.3,29.3,582.7,29.7,582.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M42.9,595.9c1.7,1.1,3.6,1.8,5.5,2.3c1,0.2,1.9,0.3,2.9,0.4c0.5,0,1,0,1.4,0l1.9-0.1l0.4,0.5l0.2,0.2l0.1,0.1
		c0.1,0.1,0.3,0.3,0.4,0.4c0.3,0.3,0.6,0.5,0.9,0.8c0.6,0.5,1.2,0.9,1.9,1.3c1.2,0.8,2.5,1.4,3.8,2c2.2,0.9,4.5,1.6,6.9,1.9
		c4,0.6,6.4,0.5,6.4,0.8s-2.4,0.7-6.6,0.4c-2.5-0.2-5-0.7-7.3-1.6c-1.5-0.5-2.9-1.2-4.2-2c-0.7-0.4-1.4-0.9-2.1-1.4
		c-0.3-0.3-0.7-0.5-1-0.8l-0.5-0.4l-0.2-0.1l0,0c0.2,0-0.6,0.1-1,0.1s-1,0-1.6,0c-1.1,0-2.2-0.2-3.2-0.4c-2.2-0.4-4.4-1.3-6.3-2.5
		C41.4,597.4,42.8,595.8,42.9,595.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M132.5,614c0.1,0.2-2.6,1.1-7.1,1c-2.6-0.1-5.3-0.4-7.8-1.1c-1.5-0.4-3-0.9-4.5-1.4c-0.8-0.3-1.5-0.6-2.3-1
		c-0.4-0.2-0.8-0.4-1.2-0.5s-1-0.5-0.8-0.4l0,0c0,0,0.1,0,0,0l-0.5,0.1l-0.9,0.3c-3.4,1-6.9,1.3-10.5,1.1c-2.9-0.2-5.7-0.6-8.4-1.5
		c-4.7-1.4-7.2-3.1-7.1-3.3s2.8,0.9,7.4,1.9c5.9,1.4,12.1,1.3,17.9-0.2l2.2-0.7c0.9,0.5,1.1,0.6,1.5,0.8s0.7,0.4,1.1,0.5
		c0.7,0.3,1.5,0.7,2.2,1c1.4,0.6,2.9,1,4.3,1.5c2.4,0.7,4.9,1.2,7.4,1.5C129.8,614,132.4,613.7,132.5,614z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M162.3,614c0.1,0.1-0.3,0.4-0.8,0.7c-0.8,0.4-1.7,0.8-2.6,1c-2.9,0.9-6,1.2-9.1,1.1c-3.1-0.1-6.1-0.6-9-1.7
		c-2.2-0.8-3.4-1.6-3.3-1.9c0.2-0.5,5.6,1.2,12.4,1.4C156.6,615,162,613.4,162.3,614z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M130,606.8c0.1,0.5-4,1.5-9,0.6c-2.1-0.4-4.2-1.1-6.1-2.2c-1.4-0.9-2.1-1.8-1.9-2s1.1,0.2,2.6,0.6
		c1.9,0.6,3.9,1.1,5.8,1.5C126,606.1,130,606.1,130,606.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M176.3,605.4c0.1,0.2-1.1,0.7-3.2,1.1c-1.3,0.2-2.5,0.3-3.8,0.4c-0.7,0-1.5,0-2.3,0h-1.2l-1.1-0.1
		c-0.1,0.1-0.3,0.1-0.4,0.2l-0.7,0.2c-0.5,0.2-1,0.3-1.5,0.4c-1,0.3-2,0.4-3.1,0.6c-4.2,0.6-8.5,0.6-12.7,0c-3.2-0.4-5.2-1-5.1-1.3
		s2-0.2,5.2-0.2c4.1,0.1,8.2-0.1,12.3-0.6c1-0.2,2-0.3,2.9-0.6c0.5-0.1,0.9-0.2,1.4-0.3l1.6-0.5l0.8,0.1l0.6,0.1l1.2,0.1
		c0.8,0.1,1.5,0.1,2.2,0.2c1.4,0.1,2.6,0.1,3.7,0.1C175,605.3,176.2,605.1,176.3,605.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M176.4,614.9c-2.1,0.3-4.3,0.3-6.4-0.2c-1.6-0.3-2.5-0.7-2.5-1s1-0.4,2.6-0.5c2,0,4-0.2,5.9-0.4
		C176.2,613.5,176.3,614.2,176.4,614.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M60,614c0.1-0.2,1.6,0.5,4.3,1.1c1.6,0.3,3.1,0.5,4.7,0.6c1,0,1.9,0,2.9,0c0.5,0,1-0.1,1.5-0.1l1.9-0.2
		l0.6,0.3l0.4,0.2l0.8,0.3c0.5,0.2,1,0.5,1.6,0.7c4.1,1.7,8.3,2.8,12.7,3.3c3.4,0.3,5.5,0.2,5.5,0.5s-2.1,1-5.6,1
		c-4.6-0.1-9.2-1-13.4-2.8c-0.6-0.2-1.1-0.5-1.6-0.7l-0.8-0.4l-0.4-0.2h-0.1l0,0h-0.1h-0.4l-0.8,0.1c-0.6,0-1.1,0-1.6,0.1
		c-1,0-2,0-3.1-0.1c-1.7-0.2-3.4-0.5-5-1.1C61.2,615.4,59.8,614.2,60,614z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M118.6,622.8c0.1,0.3-1.2,1-3.5,1.3c-2.9,0.4-5.9,0.3-8.8-0.3c-1.6-0.3-3.2-0.7-4.7-1.1
		c-0.4-0.1-0.7-0.2-1-0.3c-0.4-0.1-0.7-0.3-1.1-0.5c-0.6-0.4-1.1-1-1.4-1.8c-0.4-0.9-0.6-1.8-0.6-2.7c0-0.7,0.1-1,0.2-1
		s0.3,0.3,0.6,0.8c0.3,0.8,0.7,1.5,1.1,2.2c0.2,0.4,0.5,0.7,0.9,0.9c0.1,0,0.5,0.1,0.8,0.2l1,0.2c1.4,0.3,2.9,0.6,4.6,0.9
		c2.8,0.5,5.5,0.8,8.3,0.9C117.2,622.6,118.5,622.5,118.6,622.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M161.5,624.9c0.1,0.2-2,1.5-5.8,2.1c-5,0.7-10,0.4-14.9-0.8l-1.1-0.3l-0.5-0.1h-0.1l0,0
		c-0.6,0.1-1.4,0.3-2,0.4c-1.3,0.2-2.7,0.3-4,0.4c-2.3,0.1-4.5-0.1-6.7-0.5c-3.8-0.7-5.9-1.8-5.8-2.1s2.3,0.3,6,0.6
		c2.1,0.2,4.3,0.2,6.4,0.1c1.3-0.1,2.5-0.2,3.8-0.5c0.7-0.1,1.2-0.3,2-0.4c0.2,0,0.4-0.1,0.5-0.1l0.4,0.1l0.5,0.1l1,0.2
		c4.7,1.1,9.5,1.5,14.3,1.3C159.2,625.3,161.4,624.5,161.5,624.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M87.5,634.9c-3.6-4.8-5.5-9.4-5-9.7s3.2,3.7,6.6,8.4C88.6,634.1,88,634.6,87.5,634.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M135,640.2c0-4.5,0.5-8.1,1.1-8s1.1,3.6,1.1,8C136.4,640.2,135.7,640.2,135,640.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M157.9,640c2.1-4.1,2.8-8,3.4-8s0.8,4.3-1.6,9c-0.3-0.1-0.6-0.3-0.9-0.5l-0.6-0.4
		C158.1,640.1,158,640.1,157.9,640z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M120.3,634.9c-0.6,0.1-1.6-2.3-1.8-5.5s0.3-5.8,0.8-5.7s1,2.5,1.3,5.6S120.8,634.7,120.3,634.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M109.9,637.8c-0.8-1.3-1.3-2.8-1.7-4.3c-0.3-1.2-0.1-2,0.2-2s0.7,0.5,1.2,1.5s1.3,2.3,2.1,3.8
		C111.1,637.2,110.5,637.5,109.9,637.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M76.2,616.4c0.3,2.7,0.9,5.4,1.8,8c0.7,2,1.2,3.3,0.9,3.5s-1.2-0.9-2.3-2.9c-1.4-2.6-2.2-5.5-2.5-8.5
		c0.3-0.1,0.7-0.1,1-0.1C75.4,616.3,75.8,616.3,76.2,616.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M57.7,613.6c-1-2.1-1.6-4.3-1.7-6.6c-0.1-1.7,0.1-2.8,0.4-2.8s0.6,1,1.1,2.5c0.5,2,1.2,3.9,2.1,5.8
		C59,613,58.3,613.3,57.7,613.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M72.6,575.3c-0.5,0-0.8-3.1,0.4-6.7s3.3-6,3.7-5.6s-0.7,2.9-1.8,6.3S73.2,575.3,72.6,575.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M114.1,580.9c-0.5,0.1-1.5-3.3-1.1-7.4s2-7.3,2.6-7.1s-0.1,3.3-0.5,7.3S114.7,580.8,114.1,580.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M151.1,582.3c0-0.6,3.3-0.8,7.2-1.3s7.2-0.7,7.3-0.1s-3,1.8-7.1,2.2S151.1,582.8,151.1,582.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M125.8,591.5c0-0.6,2.9-0.9,6.3-1.3s6.3-0.7,6.4-0.2s-2.6,1.8-6.2,2.3S125.7,592.1,125.8,591.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M122,579.3c-0.5,0.1-1.4-3-0.9-6.8s1.9-6.6,2.4-6.4s0,3.1-0.4,6.6S122.6,579.2,122,579.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M88.4,595.6c-0.3,0.1-0.7-0.3-1.2-1.2c-0.5-1.1-0.9-2.3-1-3.6c-0.4-2.9,0.1-5.2,0.7-5.2s1,2.2,1.4,4.9
		S88.9,595.4,88.4,595.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M50.8,588.1c-0.5,0.2-1.9-2-2.4-5.1s0.1-5.7,0.6-5.6s1,2.4,1.4,5.3S51.3,587.9,50.8,588.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M72.5,612c0,0.3-0.8,0.7-2.3,0.9c-1.9,0.3-3.9,0.3-5.8-0.1c-1.9-0.4-3.8-1.1-5.5-2.1c-1.3-0.8-1.9-1.6-1.7-1.8
		c0.3-0.5,3.4,1.1,7.6,1.8C68.9,611.5,72.4,611.4,72.5,612z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M138.1,624.8c-0.4-4.3-0.1-7.9,0.5-7.9s1.1,3.4,1.5,7.7C139.5,624.7,138.8,624.8,138.1,624.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M168.5,639.8c-0.5-0.2,0.2-3.4,1.1-7.3s1.6-7.1,2.2-7.1s0.7,3.5-0.1,7.5S169,640,168.5,639.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M85.6,557.6c0.1,0.3-1,1-3,1.6c-2.6,0.7-5.4,1-8.1,1c-1.5,0-3-0.1-4.5-0.2c-0.7-0.1-1.3-0.2-2-0.3
		c-0.3-0.1-0.7-0.1-1-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.5-0.7-0.9-1.6-1-2.5c-0.1-0.6,0-1,0.1-1s0.3,0.2,0.7,0.7
		c0.4,0.6,0.8,1.2,1.3,1.7c0.1,0.1,0.2,0.2,0.4,0.2l0.8,0.1c0.6,0,1.2,0.1,1.9,0.1c1.3,0,2.8,0.1,4.3,0c3.1,0,5.8-0.2,7.8-0.4
		S85.5,557.3,85.6,557.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M110.1,558.9c0.1,0.3-1.1,0.8-3.1,1.2c-2.6,0.5-5.3,0.7-7.9,0.5c-2.7-0.2-5.3-0.7-7.8-1.6
		c-1.9-0.7-3-1.5-2.9-1.7s1.3,0,3.3,0.3c2.5,0.4,5,0.8,7.6,0.9C105.1,558.9,110,558.2,110.1,558.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M133,561.1c0.1,0.3-1,0.8-2.9,1.3c-5,1.1-10.2,0.4-14.7-1.9c-1.7-0.9-2.6-1.8-2.4-2c0.3-0.5,4.4,1.5,9.9,2.1
		S132.9,560.5,133,561.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M155,563.6c0.1,0.3-1.4,0.8-3.9,1.2c-3.3,0.4-6.5,0.2-9.8-0.4c-1.8-0.3-3.6-0.8-5.3-1.6
		c-0.2-0.1-0.4-0.2-0.6-0.4c-0.3-0.2-0.5-0.4-0.7-0.6c-0.3-0.4-0.4-1-0.4-1.5c0-0.7,0-1.4,0-2.1c0.2-2.5,0.6-4,0.8-4s0.4,1.5,0.6,4
		c0.1,0.6,0.1,1.3,0.3,1.9c0,0.3,0.2,0.7,0.2,0.6l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.2c1.6,0.6,3.2,1,4.8,1.3c3.1,0.5,6.2,0.9,9.3,0.9
		C153.4,563.4,155,563.3,155,563.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M158.1,564.5c-0.3,0.1-0.7-0.2-1-0.9c-0.4-0.9-0.6-1.8-0.7-2.8c-0.2-2.2,0.4-3.9,0.9-3.9s1,1.7,1.1,3.8
		c0.1,0.9,0.1,1.7,0,2.6C158.5,563.9,158.4,564.4,158.1,564.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M113,547.3c-0.5,4.3-0.9,7.8-1.5,7.8s-1.1-3.6-0.5-8.1c0.3,0,0.7,0,1,0.1l0.7,0.1L113,547.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M82,558.2c0.4-4.3,0.6-7.8,1.2-7.8s1.3,3.5,0.9,8C83.4,558.4,82.7,558.3,82,558.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M154.1,555.5c0.1,0.6-2,1.3-4.6,1.2s-4.7-1.1-4.5-1.7s2.1-0.5,4.6-0.4S154.1,554.9,154.1,555.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M71.3,544.6c0,0.6-2.3,1.2-5,1.9s-5.1,1.2-5.3,0.7s1.9-1.9,4.8-2.7C68.7,543.7,71.3,544,71.3,544.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M98,534.7c-0.2,5.1-0.2,9.2-0.8,9.3s-1.5-4.1-1.3-9.4C96.6,534.6,97.3,534.6,98,534.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M110.6,540.2c5.5-0.5,10,0.2,9.9,0.8s-4.4,0.8-9.7,1.3C110.6,541.6,110.6,540.9,110.6,540.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M140.5,551.7c-0.3-2.4-0.3-4.8,0-7.1c0.3-1.8,0.8-2.9,1-2.8s0.3,1.2,0.4,2.9s0.3,4.2,0.6,6.8
		C141.8,551.6,141.1,551.7,140.5,551.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M154.1,540.2c-1,4-1.8,7.3-2.4,7.2s-0.7-3.6,0.4-7.7c0.3,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.1,0.7,0.2
		C153.9,540.1,154,540.1,154.1,540.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M161.1,546.5c0.1-0.6,1.7-0.7,3.6-0.4s3.4,0.8,3.4,1.4s-1.7,0.9-3.7,0.7S161,547.1,161.1,546.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M45.5,548c0.6,0,1.1,1.1,1.8,2.4s1.2,2.5,0.7,3s-1.8-0.4-2.6-2c-0.4-0.7-0.5-1.5-0.5-2.3
		C45,548.4,45.2,548,45.5,548z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M124.5,538.1c0-0.5,2.3-0.9,5-0.3s4.6,2,4.4,2.5s-2.3,0.1-4.8-0.5S124.5,538.7,124.5,538.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M73.8,540.6c0-0.6,2.2-1.1,4.9-1s4.8,0.8,4.8,1.4s-2.2,0.8-4.9,0.7S73.8,541.2,73.8,540.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M22.3,450.5c-0.2-0.2-1.2,0.5-2.3,2.1c-0.6,1-1.1,2-1.3,3.1l-0.1,0.2c0,0.1-0.1,0.3-0.1,0.4l-0.1,0.9
		c0,0.6,0,1.1,0,1.7c0,0.5,0.1,1,0.2,1.5l0.2,1.3v0.2c0.7-1,1.4-2.2,1.9-2.8c0.2-0.3,0.5-0.6,0.8-0.8l0.4-0.3l0.2-0.1
		c0.5-0.4,0.9-0.8,1.3-1.1c0.8-0.6,1.5-1.1,1.7-1.3l-0.5-0.9c-0.6,0.6-1.2,1.2-1.7,1.9l-0.6,0.9c-1.1,1.3-2.2,2.6-3.1,4l0.9,1
		c0.4,0,0.7,0,1.1,0h0.4l0,0c0,0,0,0,0,0l0,0l-0.2,0.8c-0.1,0.5-0.2,1-0.3,1.5c-0.1,0.9-0.2,1.7-0.2,2.6v1.3c0,0.5,0,0.9,0,1.3v0.9
		v0.3c0,0.1,0,0.2,0,0.2l1.1-0.9c0.5-0.4,1-0.7,1.5-1l1.9-1.1l5-3.1c1.2-0.8,2.2-1.8,3-3.1c0.6-0.9,1.2-1.9,1.6-2.9
		c0.8-1.7,1-2.9,0.7-3s-1,0.8-2,2.2c-0.5,0.7-1.2,1.6-1.9,2.5c-0.7,0.9-1.6,1.7-2.5,2.4c-1.7,1.1-3.4,2.4-4.8,3.8l-1.6,1.5
		c-0.2,0.3-0.5,0.5-0.7,0.7l-0.5,0.4c0,0,0-0.1,0-0.1l0.1-0.3l0.1-0.5c0.1-0.2,0.1-0.4,0.2-0.5s0-0.1,0.1-0.3l0.1-0.5
		c0.2-0.7,0.4-1.5,0.6-2.4c0.1-0.4,0.2-0.9,0.4-1.3l0.1-0.3v-0.2v-0.1l0.1-0.2l0.3-0.6c0.1-0.2,0.2-0.5,0.4-0.7c0.5-0.9,1-1.6,1-1.7
		l-2.5,0.3l-1.1,0.2h-0.3h-0.1l-0.3,0.1c-0.5,0.1-0.9,0.2-1.4,0.5l0.9,1c0.7-1.5,1.5-3,2.4-4.4c0.2-0.3,0.4-0.6,0.7-0.8
		c0.5-0.6,1.1-1.1,1.7-1.5l2-1.3l-2.5,0.4c-0.7,0.2-1.5,0.4-2.1,0.8c-0.6,0.3-1.1,0.6-1.6,1l-0.2,0.1l0,0l0,0l0,0l0,0l0,0
		c0,0,0,0.1,0-0.3c0.2-1,0.4-1.9,0.8-2.9C21.9,451.7,22.5,450.7,22.3,450.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M20.3,445.5c0.2,0.3-0.9,1.2-2.2,3c-0.8,1-1.4,2.1-1.8,3.3c-0.1,0.3-0.2,0.7-0.3,1c0,0.2-0.1,0.4-0.1,0.5v0.3
		v0c0,0.1,0,0,0,0l0,0l0,0c0.3-0.2,0.7-0.4,1-0.6l3.9-1.9l-2.5,3.4c-0.1,0.2-0.1,0.4-0.2,0.6c0,0.1,0,0.3,0,0.4s0,0,0,0.1
		c0,0.1,0,0.2-0.1,0.3v0.3v0.3c0,0,0.1,0,0.1-0.1c0.3-0.2,0.5-0.4,0.7-0.6c0.4-0.4,0.7-0.5,0.8-0.4s0,0.4-0.2,0.8
		c-0.1,0.2-0.1,0.3-0.2,0.5l-0.3,0.7c-0.1,0.1-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.5-0.2,0.7l-0.3,1.3l-0.5,2.5l-0.3-0.7
		c-0.1-0.1-0.1-0.2-0.2-0.3c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0.1s0-0.1-0.1-0.3l-0.1-0.5l-0.3-1.2c-0.2-1-0.4-2-0.4-3
		c0-0.3,0-0.6,0-0.9c0-0.2,0-0.4,0-0.6c0-0.5,0.2-0.9,0.4-1.4l1.3,1.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.3,0.2-0.6,0.5-0.8,0.8l-0.2,0.5
		c-0.1,0.2-0.2,0.4-0.2,0.6l-0.7,2.2l-0.6-1.6l0,0l-0.1-0.2l-0.2-0.5c-0.1-0.3-0.2-0.6-0.3-0.9l-0.1-0.5l-0.1-0.6c0-0.4,0-0.8,0-1.1
		v-0.6V453c0-0.2,0.1-0.5,0.1-0.7c0.1-0.4,0.2-0.8,0.4-1.2c0.5-1.4,1.4-2.6,2.4-3.6C18.7,445.8,20.1,445.3,20.3,445.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M18.4,441.3c0.4,0.4-0.7,2.2-1.4,4.4s-0.6,4.2-1.1,4.4c-0.3,0.1-0.7-0.4-1-1.3c-0.3-1.2-0.3-2.5,0.1-3.7
		c0.4-1.2,1-2.2,1.9-3.1C17.6,441.3,18.2,441.1,18.4,441.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M10.8,442.6c0.3,0.2-0.4,1.5-1.1,3.8c-0.4,1.3-0.6,2.6-0.6,4c0,0.8,0.1,1.6,0.3,2.3c0.1,0.4,0.2,0.8,0.3,1.2
		c0.1,0.3,0.1,0.2,0.1,0.2l0,0l0,0l0,0l0,0c0,0,0,0,0-0.1c0.3-0.8,0.6-1.5,0.9-2.1c0.5-1.1,1.2-2.2,2-3.2c1.4-1.7,2.6-2.3,2.7-2.1
		s-0.6,1.1-1.6,2.9c-0.6,1-1.1,2.1-1.5,3.2c-0.2,0.6-0.5,1.3-0.7,2c0,0-0.1,0.3-0.1,0.5l-0.1,0.6l-0.3,1.2c-0.2,0.8-0.3,1.6-0.5,2.5
		l-0.9-1.2l-0.5-0.6c-0.2-0.2-0.3-0.5-0.5-0.8c-0.2-0.4-0.4-0.8-0.6-1.2c-0.2-0.3-0.3-0.7-0.4-1.1c-0.2-0.5-0.3-0.9-0.4-1.4
		c-0.2-0.9-0.2-1.8-0.2-2.8c0.1-1.5,0.4-3.1,1.1-4.5c0.4-0.9,0.9-1.8,1.6-2.6C10.3,442.8,10.6,442.5,10.8,442.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M11,438.3c0.4,0.5-1.2,2.3-1.9,4.9s-0.4,5-1,5.1c-0.3,0.1-0.7-0.5-1-1.5c-0.4-1.4-0.4-2.8,0-4.2
		c0.4-1.4,1.1-2.7,2.2-3.6C10.2,438.3,10.8,438.1,11,438.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M10.8,435c0.3,0.6-2.6,2.2-3.5,5.6c-0.1,0.4-0.2,0.8-0.3,1.2c0,0.1,0,0.2,0,0.3v0.1c0,0.1,0,0.1,0,0.1l0,0
		c0,0.1-0.1,0.2-0.1,0.3v0.2c0,0,0,0,0,0c0.2,0,0.3,0,0.5,0c0.3-0.1,0.6-0.2,0.7,0s0.1,0.3-0.2,0.8l-0.7,1L6,446.5
		c-0.1-0.1-0.1-0.1-0.1-0.2s-0.1,0-0.1-0.1l-0.2-0.7c-0.2-0.6-0.3-1.2-0.4-1.8c0-0.4-0.1-0.8-0.1-1.2V442c0-0.1,0-0.3,0-0.4
		c0.1-0.5,0.2-1,0.3-1.5c0.3-1,0.8-2,1.4-2.8c0.5-0.7,1.2-1.2,1.9-1.7c0.3-0.2,0.6-0.3,0.8-0.4c0.2-0.1,0.5-0.2,0.7-0.2
		C10.5,434.9,10.8,434.9,10.8,435z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M10.9,430.3c0.2,0.3-0.1,0.7-0.5,1.4c-0.5,0.8-1,1.7-1.4,2.5c-0.9,2.1-1.2,4-1.8,4.1S6,436,7.1,433.4
		c0.5-1.1,1.2-2.1,2.2-2.8C10.2,430.1,10.8,430,10.9,430.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M22.1,421.8c0.3,0.3-0.7,1.2-2.5,2.2c-1,0.6-2.1,1.1-3.2,1.5c-0.6,0.3-1.2,0.5-1.9,0.8s-1.4,0.5-2,0.8
		c-1.2,0.5-2.3,1.1-3.2,2c-0.7,0.7-1.2,1.5-1.5,2.5c-0.4,1.7-0.1,2.9-0.3,3c-0.1,0.1-0.4-0.2-0.7-0.7c-0.4-0.8-0.5-1.7-0.5-2.5
		c0.1-1.3,0.6-2.6,1.5-3.6c1.1-1.1,2.4-2,3.8-2.6c0.8-0.3,1.4-0.6,2.1-0.8l1.9-0.7l3.2-1.1C20.9,422.1,21.9,421.7,22.1,421.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M21.8,418c0,0.3-0.8,0.5-2,0.8c-1.5,0.3-2.9,1-4.1,1.9c-0.6,0.5-1.1,1.1-1.6,1.8c-0.4,0.6-0.7,1.2-1,1.8
		c-0.5,1.1-0.7,1.9-1,1.9s-0.6-0.9-0.4-2.3c0.2-1.9,1.2-3.6,2.7-4.8c0.4-0.3,0.9-0.6,1.4-0.9c0.4-0.2,0.9-0.4,1.4-0.5
		c0.4-0.1,0.9-0.2,1.3-0.3c0.4,0,0.8,0,1.1,0C21,417.4,21.8,417.7,21.8,418z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M26.5,415.1c-0.1,0.6-2.5,0.3-4.8,0.9c-1,0.2-1.8,0.8-2.5,1.5c-0.6,0.6-0.9,1.1-1.2,1s-0.5-0.8-0.1-1.8
		c0.6-1.4,1.8-2.4,3.2-2.7c1.3-0.4,2.7-0.3,4,0.1C26.1,414.4,26.6,414.8,26.5,415.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M29.9,412.4c-0.1,0.6-2.3,0.5-4.5,1c-0.9,0.2-1.9,0.5-2.7,1c-0.7,0.4-1.1,0.6-1.4,0.5s-0.2-0.8,0.4-1.6
		c0.8-1,2-1.7,3.2-2c1.2-0.3,2.5-0.2,3.7,0.1C29.5,411.8,30,412.1,29.9,412.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M35.7,411.3c-0.2,0.2-0.8-0.2-1.7-0.5c-1.1-0.5-2.3-0.6-3.4-0.5c-1.2,0.2-2.3,0.7-3.4,1.3
		c-0.9,0.5-1.4,0.9-1.7,0.7s0-0.9,0.7-1.8c1-1.2,2.4-2,4-2.3c0.9-0.1,1.8-0.1,2.7,0.2c0.7,0.2,1.3,0.6,1.9,1.1
		C35.7,410.3,35.9,411.1,35.7,411.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M42.3,412.9c-0.3,0.1-0.8-0.5-1.6-1.4c-0.9-1.1-2-2-3.3-2.6c-0.3-0.1-0.7-0.2-1-0.2c-0.4,0-0.8,0-1.2,0
		c-0.7,0-1.3,0-2,0.1c-1.1,0.1-1.8,0.4-2,0.1c-0.5-0.4,2.4-3.1,6.9-2c1,0.4,1.8,1,2.5,1.8c0.5,0.6,1,1.3,1.3,2
		C42.5,412,42.5,412.8,42.3,412.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M50.3,414.4c-0.2-0.1-0.3-0.9-0.7-1c-0.1,0.1-0.2,0.2-0.3,0.4c0,0.1,0,0.1-0.1,0.2l-0.5,0.5l-0.6,0.6
		c-0.4,0.4-0.7,0.8-1.1,1.2l-2.5,3l0.2-2.7l0.1-2.2v-0.5c0-0.1,0-0.1,0-0.2l-0.1-0.4c0-0.2-0.1-0.5-0.2-0.7
		c-0.1-0.2-0.2-0.4-0.3-0.6c-0.4-0.7-1.1-1.3-1.8-1.6c-1.4-0.7-2.5-0.8-2.5-1s1.1-0.8,3-0.3c1.2,0.3,2.2,1,2.9,2
		c0.2,0.3,0.4,0.6,0.5,0.9c0.2,0.3,0.3,0.7,0.4,1l0.1,0.5v0.3l0,0l0,0l0,0 M46.8,413.6l0.6-0.5c0.2-0.2,0.4-0.3,0.6-0.5
		s0.7-0.5,1-0.7c0.5-0.3,1.1-0.5,1.7-0.7c0.2,0.8,0.3,1.6,0.1,2.4c-0.1,0.6-0.3,0.8-0.5,0.8L46.8,413.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M56.7,416.2c-0.4,0.5-2.3-0.9-4.6-0.7s-4.2,1.4-4.6,1c-0.2-0.2,0-0.8,0.8-1.5c1-0.9,2.3-1.5,3.7-1.6
		c1.4-0.1,2.8,0.3,3.9,1.3C56.6,415.4,56.8,416,56.7,416.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M59.3,419.8c0-0.2,1-0.7,3-1.1c1.2-0.2,2.4-0.4,3.6-0.3c0.8,0,1.5,0.1,2.2,0.2h0.1h0.2l0,0l-0.1-0.1l0,0l0,0
		l-0.1-0.1l-0.4-0.3c-0.3-0.2-0.5-0.3-0.8-0.4c-2-1-4.5-0.9-6.7-0.9c-1.9,0-3.8,0.3-5.7,0.9c-3.2,0.9-5,2-5.1,1.8s1.4-1.9,4.7-3.2
		c1.9-0.8,3.9-1.2,6-1.4c1.2-0.1,2.4-0.1,3.7-0.1c1.4,0.1,2.7,0.4,4,1c0.3,0.2,0.7,0.4,1,0.6l0.5,0.3c0.1,0.1,0.2,0.1,0.3,0.2
		l0.4,0.3l0.7,0.6l0.6,0.7c0.4,0.4,0.7,0.8,1,1.3l1.7,2.5l0.1,0.2c0,0.1,0,0,0,0l0,0l0.1,0.1l0.1,0.2l0.3,0.5l0.6,1l-1.9-1.3
		l-1.2-0.8c-0.6-0.4-1.3-0.8-2-1.1l-1.2-0.5l-0.5-0.1l-0.4-0.1c-0.7-0.2-1.3-0.3-2-0.4c-1.1-0.1-2.3-0.2-3.4-0.1
		C60.4,419.8,59.3,420,59.3,419.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M66.1,420.8c-0.6,0.2-1.3,0.2-1.8-0.2c-0.7-0.4-1.3-1-1.2-1.6s1.1-0.8,2.1-0.3c0.5,0.2,0.8,0.6,1.1,1.1
		C66.4,420.3,66.3,420.6,66.1,420.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M68.1,425.3c0-0.1,0.3-0.3,1-0.3h0.5h0.3H70h0.1c0.1,0,0.2,0,0.3,0c0.1,0,0-0.2-0.1-0.3l-0.1-0.1l0,0l0,0
		l-0.1-0.1c-0.5-0.8-1.1-1.5-1.9-2c-0.9-0.5-1.9-0.9-3-1c-0.9-0.1-1.9-0.2-2.8-0.3c-1.6,0-2.7-0.1-2.8-0.4s0.9-0.8,2.7-1.1
		c1-0.2,2.1-0.3,3.1-0.2c1.4,0.1,2.7,0.5,3.9,1.2c1.1,0.7,2,1.7,2.5,2.9c0,0.1,0.1,0.1,0.1,0.2l0.1,0.3c0.1,0.2,0.1,0.4,0.2,0.6
		c0.1,0.4,0.2,0.8,0.2,1.2c0.1,0.5,0,1.1-0.2,1.6l-1.4-0.8c-0.3-0.2-0.7-0.4-1-0.5l-1-0.4C68.4,425.6,68.1,425.5,68.1,425.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M70.8,429.8c-0.7,0.2-1.2-3.8-5.1-4.9c-1.7-0.5-3.4-0.8-5.1-0.6c-1.4,0-2.3,0.1-2.4-0.1s0.7-0.9,2.2-1.3
		c0.9-0.3,1.8-0.4,2.7-0.4c1.1,0,2.3,0.2,3.4,0.5c1.1,0.4,2.2,1,3,1.8c0.7,0.7,1.2,1.6,1.5,2.5C71.2,428.8,71,429.8,70.8,429.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M73.3,438.4c-0.7,0-0.6-3.1-2.2-6.1s-4.2-4.5-4-5c0.1-0.3,1-0.2,2.2,0.4c1.6,0.8,2.8,2.1,3.7,3.6
		c0.8,1.5,1.2,3.3,1.1,5.1C74,437.7,73.6,438.4,73.3,438.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M73.9,448.1c-0.2-0.1-0.1-0.8-0.5-1.9c-0.5-1.5-1.4-2.9-2.5-4l-2.1-2.1l2.9,0.5c0.4,0,0.7,0.1,1.1,0.3l0.5,0.2
		c0.1,0,0.1,0.1,0.2,0.1l0,0l0,0l0,0l-0.1-0.2l-0.2-0.5c-0.1-0.3-0.2-0.6-0.4-0.9c-0.5-1-1.2-2-2-2.9c-1.4-1.5-2.4-2.4-2.3-2.6
		s1.5,0.1,3.3,1.5c1,0.8,1.9,1.9,2.7,3c0.2,0.3,0.4,0.7,0.6,1c0.1,0.2,0.2,0.4,0.3,0.6l0.3,0.9c0.2,0.7,0.3,1.4,0.3,2.1
		c0.1,0.7,0.1,1.4,0.2,2.1l0.1,1.6l-1.9-2.3l-0.4-0.5l-0.4-0.4c-0.2-0.2-0.5-0.4-0.7-0.6c-0.1-0.1-0.2-0.2-0.4-0.3
		c-0.1,0-0.1-0.1-0.2-0.1l-0.3-0.2c-0.2-0.1-0.4-0.2-0.5-0.2l0.8-1.6c1.3,1.4,2.2,3.2,2.3,5.2C74.6,447.4,74.1,448.1,73.9,448.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M72.7,453.8c0.1-0.1,0.2-0.2,0.5-0.3c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2,0-0.4,0-0.6
		c-0.1-0.6-0.3-1.2-0.6-1.8c-1.1-2.7-2.2-5-1.7-5.3s2.3,1.5,3.6,4.5c0.3,0.8,0.5,1.6,0.5,2.4c0,0.4-0.1,0.8-0.2,1.2
		c-0.1,0.4-0.4,0.7-0.7,1c-0.3,0.2-0.6,0.4-1,0.4c-0.2,0-0.5,0-0.7-0.1C72.7,454.1,72.6,453.9,72.7,453.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M70.8,459c-0.1-0.1-0.1-0.3-0.1-0.6c0-0.1,0.1-0.3,0.1-0.4c0-0.2,0.1-0.4,0.1-0.5c0.3-1,0.5-2,0.5-3
		c0.1-2.5-0.1-4.7,0.5-4.9c0.3,0,0.7,0.4,1,1.3c0.5,1.2,0.6,2.4,0.6,3.7c0,1.3-0.5,2.6-1.3,3.6c-0.2,0.2-0.3,0.3-0.5,0.5
		c-0.1,0.1-0.3,0.2-0.5,0.3C71,459.1,70.9,459.1,70.8,459z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M67.3,465.7c-0.1-0.1,0-0.3,0.2-0.6s0.2-0.2,0.3-0.4s0.2-0.3,0.3-0.5c0.6-1,1-2.1,1.1-3.2
		c0.5-2.7,0.4-5.2,1-5.3c0.3,0,0.7,0.5,0.9,1.5c0.3,1.4,0.4,2.8,0.1,4.1c-0.1,0.8-0.4,1.6-0.8,2.3c-0.2,0.3-0.4,0.6-0.6,0.9
		c-0.2,0.2-0.4,0.5-0.7,0.6c-0.2,0.2-0.5,0.3-0.7,0.4c-0.2,0.1-0.4,0.1-0.6,0.1C67.6,465.9,67.4,465.8,67.3,465.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M61.2,463.1c0.3,0.1,0,1.5,0.1,3.9c0,0.6,0.1,1.2,0.2,1.9c0,0.5,0.1,1,0.2,1.4c0,0,0,0,0.1,0l0,0l0.5-0.2
		c0.7-0.4,1.3-0.9,1.9-1.4c1.1-1,1.9-2.4,2.2-3.9c0.3-1.3,0.4-2.6,0.2-3.9c-0.2-2.3-0.7-3.7-0.4-3.8s1.2,1.1,1.8,3.6
		c0.4,1.5,0.4,3,0.2,4.5c-0.3,1.9-1.3,3.7-2.7,5c-0.7,0.7-1.5,1.2-2.4,1.7l-1.8,0.8l-1,0.4h-0.1c0,0,0,0,0-0.1v-0.1v-0.3l-0.1-0.5
		c-0.1-0.6-0.2-1.2-0.3-1.9c0-0.4,0-0.8,0-1.1c0-0.7,0-1.4,0.1-2C60.1,464.4,60.9,463,61.2,463.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M54.4,468.3c0-0.3,0.5-0.5,1.1-0.8c0.3-0.2,0.6-0.5,0.8-0.7l0.2-0.3c0,0,0.1-0.1,0.1-0.1v-0.1l0.1-0.1
		c0.2-0.3,0.3-0.5,0.5-0.8s0.3-0.8,0.5-1.2l0.7,0.1l0.3,0.1c0.3,0.2,0.5,0.4,0.8,0.5c-0.1,0.4-0.1,0.9,0,1.3
		c0.1,0.2,0.2,0.5,0.2,0.5c0.1,0.2,0.1,0.3,0.2,0.5c0.2,0.3,0.3,0.5,0.2,0.7s-0.3,0.3-0.8,0.1c-0.3-0.1-0.5-0.2-0.7-0.5
		c-0.3-0.3-0.3-0.2-0.3-0.2s-0.1,0.1-0.1,0.1v0.1l0,0l0,0l0,0l-0.1,0.1l-0.2,0.2l-0.4,0.3c-0.5,0.3-1,0.6-1.6,0.6
		C54.9,468.9,54.4,468.6,54.4,468.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M50.4,470.7c-0.2-0.6,1.6-1.4,3.1-2.8s2.3-2.9,2.9-2.9c0.3,0,0.5,0.6,0.3,1.5c-0.3,1.1-0.9,2.2-1.8,2.9
		c-0.8,0.8-1.9,1.3-3,1.5C51,471.1,50.5,470.9,50.4,470.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M46.3,469.7c0.3,0.2-0.1,1.2,0.1,2.9c0,0.1,0,0.1,0,0.2v0.1l0.1,0.1c0,0,0.1-0.1,0.1-0.1l0.1-0.1
		c0.1-0.1,0.2-0.1,0.3-0.2l0.3-0.3c0.2-0.2,0.4-0.4,0.7-0.6c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.2,0.4-0.5,0.6-0.7
		c1.4-1.8,2.5-3.8,3.4-5.9c0.7-1.6,1.2-2.7,1.5-2.6s0.3,1.3-0.1,3.1c-0.2,1.1-0.6,2.1-1,3.2c-0.5,1.3-1.2,2.5-2.1,3.6
		c-0.2,0.3-0.5,0.6-0.7,0.8c-0.2,0.3-0.5,0.5-0.8,0.8c-0.3,0.2-0.5,0.5-0.8,0.7l-0.9,0.6c-0.5,0.2-0.9,0.4-1.4,0.6l-0.7,0.3
		l-0.2,0.1c-0.1,0-0.1,0.1-0.1,0l-0.1-0.4c-0.1-0.5-0.2-0.9-0.2-1.3c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0-0.5c0-0.8,0.3-1.6,0.7-2.3
		C45.9,469.8,46.2,469.6,46.3,469.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M44.1,470.2c0.3,0,0.6,0.5,0.6,1.1c0,0.8-0.2,1.7-0.6,2.4l-0.2,0.3l-1.2-1.5c0.5-0.1,1.2-0.4,1.7-0.5
		s0.9-0.1,1.1,0.1s0.1,0.6-0.3,1.1c-0.5,0.7-1.2,1.1-2.1,1.3l-2,0.6l0.9-2l0.1-0.3c0.3-0.7,0.6-1.4,1-2
		C43.5,470.5,43.8,470.2,44.1,470.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M39.1,470.4c0.1,0.1,0,0.5-0.4,1.1c-0.2,0.3-0.4,0.7-0.7,1.1l-0.4,0.7l-0.2,0.3v0.1c0,0.1-0.1,0.2-0.1,0.3l0,0
		l0.2-0.1l0.1-0.1l0.3-0.2l0.1-0.1l0.6-0.3c0.4-0.2,0.8-0.4,1.1-0.7c2.6-1.7,4.6-4.1,5.9-6.9c1-2.1,1.4-3.7,1.7-3.6s0.5,1.7-0.3,4.2
		c-1.1,3.3-3.2,6.2-6.2,8.1c-0.4,0.3-0.9,0.5-1.3,0.8l-0.7,0.4l-1.1,0.4l-2,0.8l-2.1,0.6l-1,0.3l-0.5,0.1l-0.2,0.1c-0.1,0,0,0,0-0.1
		l0.1-0.1l1.3-1.5c0.4-0.5,0.8-1,1.2-1.4l1.2-1.3l0.5-0.6l0.3-0.3l0.6-0.6c0.4-0.4,0.7-0.6,0.9-0.9C38.6,470.5,39,470.3,39.1,470.4z
		"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M38.4,465.8c0.5,0.4-0.5,2.1-1.2,4.2s-0.8,3.9-1.3,4.1c-0.3,0.1-0.7-0.4-0.9-1.3c-0.2-1.1-0.2-2.3,0.2-3.4
		c0.3-1.1,0.9-2.1,1.7-2.9C37.7,465.9,38.2,465.7,38.4,465.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M36.7,461.7c0.5,0.4-0.6,2.4-1.1,4.8c-0.2,1-0.2,2,0,3c0.2,0.8,0.4,1.3,0.2,1.5s-0.9,0-1.6-0.8
		c-0.8-1.2-1.1-2.6-0.8-4c0.3-1.3,0.9-2.5,1.7-3.5C35.9,461.8,36.4,461.5,36.7,461.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M33.7,459.8c0.6,0.2,0.5,1.6,0.7,3.1s0.6,2.8,0.1,3.2c-0.2,0.2-0.7,0-1.2-0.5c-0.6-0.7-0.9-1.5-1-2.4
		c-0.1-0.9,0-1.8,0.4-2.6C33,459.9,33.4,459.7,33.7,459.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M20.8,439.8c-0.2-0.1,0.1-1,1-2.1c1.3-1.5,3-2.7,4.9-3.4l-0.4,1.7c-0.1-0.1-0.2-0.1-0.3-0.2l-0.2-0.1l-0.2-0.1
		l-0.3-0.1c-0.9-0.3-1.8-0.6-2.7-0.8l-2.8-0.6l2.8-1.2c0.9-0.4,1.9-0.7,2.9-0.9c0.2-0.1,0.5-0.1,0.7-0.1h0.4c0.3,0,0.5,0,0.8,0
		c0.5,0,1,0,1.4,0.1c1.7,0.2,2.7,0.7,2.6,1s-1.1,0.3-2.6,0.4c-0.4,0-0.8,0.1-1.3,0.2c-0.2,0-0.5,0.1-0.7,0.1l-0.3,0.1c0,0,0,0-0.1,0
		c-0.1,0.1,0,0.1,0.1,0.1l0,0h0.1l0.3,0.2c0.3,0.1,0.5,0.3,0.7,0.5l1.3,1.2l-1.8,0.5c-0.9,0.3-1.8,0.7-2.6,1.1
		c-0.7,0.4-1.4,0.8-2,1.3C21.7,439.4,21,439.9,20.8,439.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M68.2,443.3c-0.6,0-0.9-2.1-2-4.4s-2.3-4-2-4.4s2.6,0.8,3.9,3.5c0.6,1.2,0.8,2.4,0.8,3.7
		C68.7,442.7,68.4,443.3,68.2,443.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M49.7,462.4c-0.5-0.4,0.2-2.1,0.9-4.1s1-3.8,1.5-3.9c0.3-0.1,0.6,0.4,0.8,1.3c0.1,1.1,0,2.2-0.3,3.2
		C51.7,461.4,50.1,462.7,49.7,462.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M63.9,459.2c-0.6,0-1.2-1.9-2.3-4s-2-4-1.6-4.4s2.3,1,3.5,3.5c0.5,1.1,0.8,2.2,0.9,3.4
		C64.4,458.6,64.2,459.1,63.9,459.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M66.4,449.9c0.2,0,0.5,0.3,1,0.8c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0
		c0,0,0-0.1,0-0.1l0,0l0,0v-0.2v-0.5l0.1-1.7c0-1,0.1-1.6,0.4-1.7s0.7,0.5,1.1,1.5c0.2,0.6,0.3,1.2,0.4,1.9c0,0.2,0,0.4,0,0.5v0.3
		c0,0.2,0,0.4,0,0.5l-0.1,1.1l-0.2,1c-0.1,0.4-0.2,0.9-0.3,1.3l-0.3,1.2c-0.7-0.9-1.3-1.7-1.8-2.5c-0.3-0.4-0.5-0.8-0.6-1.1
		c-0.1-0.3-0.3-0.6-0.4-0.9C66,450.5,66.2,449.9,66.4,449.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M16.5,431.2c-0.3-0.1-0.3-0.7-0.1-1.6c0.4-1.2,1-2.2,1.9-3c0.9-0.9,1.9-1.5,3.1-1.8c0.9-0.2,1.5-0.1,1.6,0.1
		c0.2,0.6-1.6,1.5-3.3,3.2S17.1,431.4,16.5,431.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M35.4,418.8c0,0.2-0.6,0.4-1.6,0.2l-0.4-0.1l-0.6-0.2c-0.4-0.2-0.8-0.5-1.2-0.7c-0.8-0.6-1.6-1.3-2.2-2.1
		l1.3-0.3c0.4-0.1,0.9-0.2,1.3-0.3c0.4-0.1,0.8-0.1,1.2-0.1c0.4,0,0.9,0,1.2,0c0.4,0.1,0.9,0.2,1.3,0.3c0.7,0.2,1.4,0.5,2,0.9
		c1.1,0.6,1.6,1.3,1.4,1.5s-0.9,0.1-2-0.1c-0.6-0.1-1.2-0.3-1.9-0.4c-0.3,0-0.7-0.1-1.1-0.1h-0.1h-0.1c-0.1,0-0.2,0-0.2,0.1
		c0,0,0,0.1,0,0.1l0.1,0.1l0.3,0.2C35,418.3,35.5,418.6,35.4,418.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M56,389.3c0,0.3-3.2,0.4-8.3,0.6c-1.3,0.1-2.7,0.1-4.1,0.3c-1.6,0.1-3.1,0.3-4.6,0.6c-3,0.6-6.1,2.8-9.7,4.5
		c-3.4,1.6-7,2.8-10.7,3.4c-3.4,0.6-6.4,1-8.9,1.2c-5.1,0.4-8.3,0.3-8.3,0s3.1-0.7,8.1-1.5c2.5-0.4,5.5-0.9,8.8-1.6
		c3.5-0.7,6.9-1.8,10.2-3.4c1.7-0.8,3.4-1.7,5-2.5c1.6-0.9,3.4-1.5,5.2-1.9c1.6-0.2,3.3-0.4,4.9-0.4c1.5,0,2.9,0,4.2,0
		C52.9,388.6,56,389,56,389.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M50.2,318c0-0.3-0.8-0.6-2.3-1c-0.7-0.2-1.6-0.4-2.6-0.5l-0.8-0.1c-0.3,0-0.7,0-1,0c-0.6,0.1-1.2,0.2-1.8,0.4
		c-1.8,0.6-3.3,1.4-4.5,1.8c-0.9,0.4-1.8,0.5-2.8,0.5c-1-0.2-1.9-0.4-2.8-0.7c-1.6-0.5-3.2-0.8-4.9-1c-0.9-0.1-1.8-0.1-2.8,0
		c-0.4,0.1-0.9,0.1-1.3,0.3c-0.2,0.1-0.4,0.2-0.6,0.2l-0.4,0.2c-0.9,0.4-1.8,0.6-2.7,0.7c-1,0.1-4.1-0.6-7.7-1l-1.6-0.2
		c-0.6-0.1-1.2-0.1-1.8,0c-0.9,0.1-1.9,0.3-2.7,0.7c-1.4,0.6-2.2,1.3-2.1,1.5c0.1,0.3,1,0.1,2.5-0.1c0.8-0.1,1.6-0.2,2.4-0.2
		c0.4,0,0.9,0.1,1.3,0.1c0.6,0.1,1.1,0.2,1.7,0.3c2.7,0.2,5.4,0.1,8-0.4c1,0,2,0,3.1-0.1c0.2,0,0.4-0.1,0.6-0.1s0.3,0,0.4,0
		c0.4,0,0.7,0,1.1,0c0.8,0,1.6,0.1,2.5,0.2c3.6,0.4,6.8,0.2,7.8,0.3c1,0.2,2.1,0.3,3.1,0.2c1.7-0.2,3.3-0.6,4.9-1.1
		c0.5-0.2,0.9-0.3,1.4-0.3c0.2,0,0.4,0,0.6,0l0.8,0c1,0.1,1.8,0.1,2.6,0.1C49.2,318.4,50.1,318.3,50.2,318z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M24.6,164.8c0.2-0.2,2.8,2,6.4,6.2c4.7,5.4,8.8,11.2,12.3,17.4c4.1,7.3,6.7,14.6,7.6,20
		c0.4,2.1,0.6,4.3,0.5,6.5c-0.1,1.6-0.4,2.4-0.6,2.4s-0.1-0.9-0.3-2.4c-0.2-2.1-0.6-4.2-1.1-6.2c-1.8-6.8-4.5-13.3-8-19.3
		c-3.5-6.1-7.4-12-11.7-17.5C26.6,167.5,24.4,165,24.6,164.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M85,183.2c0.1,0.1-0.4,0.5-1.3,1.2c-0.4,0.3-1,0.7-1.7,1.1l-2.2,1.5c-3.3,2.2-7.6,5.3-12.3,9
		c-9.4,7.4-16.4,14.1-16.9,13.7s6.1-7.8,15.6-15.3c4-3.2,8.3-6.1,12.9-8.6l2.4-1.2c0.7-0.3,1.4-0.6,1.9-0.8
		C84.3,183.2,84.9,183,85,183.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M84.1,189.4c-0.2-0.1,1.7-3.4,5.4-8.5c5.1-6.9,10.6-13.5,16.5-19.8l1.2,1.5c-5,3.4-9.6,7.4-13.7,11.8
		c-4.8,5.1-9.2,10.5-13.2,16.2c-3.7,5.2-6.9,10.1-9.4,14.2s-4.5,7.6-5.9,9.9s-2.2,3.7-2.3,3.6s0.4-1.5,1.6-4s3-6,5.4-10.3
		s5.5-9.3,9.2-14.6c4-5.9,8.4-11.4,13.2-16.6c4.2-4.5,9-8.6,14.1-12l7.4-4.8l-6.2,6.3c-7.2,7.4-13,14.3-17,19.1
		S84.3,189.6,84.1,189.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M340.8,212.6c0-0.1,0.4-0.2,1-0.4c0.4-0.1,0.8-0.2,1.2-0.4c0.6-0.3,1.1-0.7,1.4-1.2c0.3,0,0.6,0.1,1,0.1h0.8
		c0.5,0,1-0.1,1.6-0.2c0.5-0.1,1.1-0.3,1.6-0.5c0.7-0.4,1.3-0.8,1.8-1.4c0.4,0.1,0.9,0.2,1.3,0.2h0.8c0.5,0,1.1,0,1.6-0.1
		c0.5-0.1,1-0.2,1.5-0.4c0.2-0.1,0.4-0.2,0.6-0.3l0.1-0.1l0,0l0.1-0.1l0.2-0.2l0.6-0.6l0.9,0.2c0.1,0,0.3,0.1,0.5,0.1h0.3
		c0.3,0,0.6,0,1,0c0.6,0,1.3-0.1,1.9-0.2c0.6-0.1,1.2-0.3,1.7-0.6c0.3-0.1,0.5-0.3,0.7-0.5c0.1-0.1,0.2-0.2,0.3-0.3l0.2-0.2
		c0.1-0.2,0.3-0.3,0.4-0.5l1-0.4l1.1-0.3l0.4,0.1l0.3,0.1l0.3,0.1c0.2,0,0.4,0,0.6,0c0.4,0,0.8-0.1,1.2-0.2c0.4-0.1,0.7-0.3,1.1-0.6
		c0.2-0.1,0.3-0.3,0.5-0.4l1.1-1.3c0.6,0.1,1.3,0.2,1.7,0.2c0.3,0,0.5,0,0.8,0c0.5,0,1-0.1,1.5-0.2c0.4-0.1,0.8-0.3,1.2-0.6
		c0.2-0.1,0.3-0.3,0.4-0.4l0.2-0.3v-0.1l0,0c0,0,0,0,0.1-0.1v-0.1l0.2-0.4c0.1-0.3,0.3-0.6,0.4-0.9l1-0.1l0.5-0.1h0.3h0.1
		c0.1,0,0.3-0.1,0.4-0.1c0.5-0.1,1-0.3,1.5-0.6c0.4-0.2,0.8-0.5,1.1-0.9c0.2-0.2,0.3-0.4,0.4-0.6l0.2-0.4l0.1-0.2l0.2-0.5l0.8-0.7
		l0.8-0.7c0.2,0,0.4-0.1,0.6-0.1c0.1,0,0.1,0,0.2-0.1c0.1,0,0.2-0.1,0.4-0.1c0.2-0.1,0.4-0.2,0.6-0.4c0.1-0.1,0.3-0.3,0.4-0.4
		l0.1-0.1c0-0.1,0.1-0.3,0.1-0.4l0.1-0.5l0.1-0.6l0.8-0.3c0.3-0.1,0.6-0.2,0.8-0.3c0.3-0.1,0.7-0.1,1-0.2c0.2-0.1,0.4-0.1,0.6-0.2
		c0.4-0.2,0.8-0.4,1.1-0.6c0.3-0.2,0.6-0.5,0.9-0.8c0.1-0.2,0.2-0.3,0.3-0.5l0.1-0.3c0-0.1,0.1-0.2,0.1-0.3l0.1-0.3l0.6-0.6l0.6-0.6
		l0.2-0.1l0.2-0.1l0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.4-0.3,0.6-0.5c0.2-0.2,0.3-0.4,0.5-0.6l0.2-0.3l0.1-0.2
		c0.1-0.2,0.1-0.3,0.2-0.5l0.9-0.6l0.9-0.6l0.3-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.2,0.4-0.5,0.6-0.7
		c0.2-0.3,0.3-0.7,0.5-1c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.4,0.2-0.7,0.2-1.1l0.8-0.8l0.8-0.8l0.4-0.1l0.1-0.1l0.2-0.1
		c0.1-0.1,0.3-0.1,0.4-0.2c0.2-0.2,0.5-0.4,0.6-0.6c0.2-0.3,0.3-0.6,0.4-0.9c0.1-0.2,0.1-0.4,0.1-0.5l0-0.4v-0.7
		c0.3-0.3,0.5-0.6,0.7-0.9l0.8-0.8c0.2-0.1,0.4-0.1,0.6-0.2c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.3-0.2,0.5-0.3c0.3-0.2,0.5-0.6,0.6-0.9
		c0.1-0.4,0.2-0.8,0.2-1.3c0-0.2,0-0.5,0-0.7v-0.2l-0.1-0.3l-0.1-0.6l0.2-0.8l0.2-0.7l0.3-0.3l0.1-0.2l0.2-0.3
		c0.1-0.2,0.3-0.4,0.4-0.7c0.2-0.5,0.5-0.9,0.6-1.4c0.3-0.9,0.5-1.9,0.5-2.8c0-0.8-0.3-1.6-0.8-2.3c-0.2-0.3-0.5-0.6-0.8-0.9
		c-0.1-0.1-0.3-0.3-0.5-0.4c-0.2-0.1-0.4-0.3-0.6-0.4l-0.4-0.4c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.4-0.2-0.7-0.4-1.1
		c-0.1-0.3-0.3-0.5-0.4-0.8c-0.1-0.2-0.3-0.4-0.5-0.6c-0.1-0.1-0.2-0.1-0.3-0.2c0,0-0.1-0.1-0.1-0.1h-0.1v-0.1
		c-0.3-0.1-0.6-0.2-0.9-0.3c-0.2,0-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.2-0.3-0.4l-0.1-0.3l-0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.3
		c-0.1-0.2-0.3-0.3-0.4-0.5c-0.2-0.2-0.5-0.5-0.8-0.6c-0.5-0.3-0.8-0.4-0.8-0.5s0.4-0.2,1,0c0.4,0.1,0.8,0.3,1.2,0.5
		c0.2,0.2,0.4,0.3,0.6,0.5c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.3c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0.2,0.1c0.2,0,0.3,0,0.5,0l0.2,0.1
		h0.1c0.1,0,0.2,0.1,0.3,0.1c0.2,0.1,0.4,0.2,0.5,0.3c0.3,0.2,0.6,0.5,0.9,0.8c0.3,0.3,0.5,0.6,0.7,1c0.2,0.3,0.4,0.8,0.5,0.9
		c0,0.1,0.1,0.1,0.1,0.1l0.2,0.1c0.2,0.1,0.4,0.2,0.5,0.3c0.2,0.1,0.4,0.3,0.6,0.5c0.4,0.3,0.8,0.7,1.2,1.2c0.8,1,1.3,2.1,1.5,3.4
		c0.1,1.3,0,2.6-0.4,3.8c-0.2,0.6-0.4,1.3-0.7,1.9c-0.1,0.3-0.3,0.6-0.5,0.9c-0.1,0.1-0.2,0.3-0.3,0.4l-0.1,0.2l0,0v0.1v0.1l0.1,0.3
		v0.1v0.3c0,0.4,0.1,0.7,0.1,1c0,0.8-0.1,1.5-0.3,2.3c-0.2,0.8-0.7,1.6-1.3,2.2c-0.3,0.3-0.6,0.6-1,0.8c-0.3,0.2-0.5,0.3-0.8,0.4
		h-0.1c0,0,0,0.1,0,0.1l0,0c0-0.1,0,0,0,0.1v0.2c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.6-0.4,1.3-0.7,1.8c-0.4,0.6-0.8,1.1-1.4,1.6
		c-0.3,0.2-0.6,0.4-0.9,0.6c-0.2,0.1-0.3,0.2-0.5,0.2l-0.2,0.1h0l0,0c-0.1,0.4-0.2,0.8-0.3,1.2c-0.2,0.6-0.4,1.2-0.7,1.7
		c-0.3,0.6-0.8,1.1-1.3,1.6c-0.3,0.2-0.6,0.5-0.9,0.7c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.1-0.3,0.1-0.5,0.2l0,0l-0.2,0.3
		c-0.1,0.2-0.2,0.4-0.4,0.6c-0.3,0.4-0.6,0.8-0.9,1.1c-0.5,0.5-1.1,0.9-1.8,1.3c-0.1,0.1-0.2,0.1-0.3,0.2l-0.1,0.1l0,0v0.1
		c0,0,0,0-0.1,0.1l-0.3,0.5c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.6-1,1.1-1.6,1.5c-0.9,0.6-1.8,1.1-2.8,1.4c-0.3,0.1-0.8,0.2-0.7,0.2
		l0,0v0.1c0,0,0,0-0.1,0.1l-0.2,0.4c-0.3,0.5-0.7,1-1.2,1.4c-0.4,0.4-0.9,0.6-1.4,0.9c-0.2,0.1-0.5,0.2-0.7,0.3l-0.4,0.1l0,0
		l-0.1,0.3l-0.3,0.6c-0.2,0.4-0.5,0.8-0.8,1.1c-0.6,0.7-1.3,1.3-2.1,1.7c-0.8,0.4-1.6,0.7-2.4,0.9l-0.6,0.1h-0.1l0,0l0,0l0,0l0,0
		v-0.1l0,0.1l-0.1,0.2c-0.1,0.2-0.3,0.4-0.4,0.6c-0.3,0.4-0.6,0.7-1,1c-0.7,0.5-1.5,0.9-2.4,1.1c-0.8,0.2-1.6,0.3-2.3,0.3
		c-0.4,0-0.8,0-1.1,0h-0.2l-0.1,0.1c-0.3,0.3-0.6,0.5-0.9,0.7c-0.6,0.4-1.3,0.8-2,1c-0.7,0.2-1.5,0.3-2.2,0.3c-0.4,0-0.7,0-1-0.1
		c-0.2,0-0.3-0.1-0.5-0.1h-0.1l0,0h-0.1l-0.1,0.1c0,0,0,0.1,0,0l-0.3,0.3c-0.2,0.2-0.4,0.3-0.6,0.5c-0.4,0.3-0.8,0.6-1.2,0.8
		c-0.8,0.4-1.7,0.7-2.6,0.8c-0.8,0.1-1.7,0.2-2.5,0.1c-0.4,0-0.8-0.1-1.2-0.1h-0.3h-0.1h-0.1l-0.1,0.1l0,0l-0.1,0.1l-0.3,0.2
		c-0.4,0.2-0.7,0.4-1.1,0.5c-0.7,0.2-1.4,0.4-2.2,0.4c-0.7,0-1.3,0-2-0.1l-0.9-0.1l-0.4-0.1c-0.5,0.4-1.1,0.7-1.7,0.9
		c-0.7,0.3-1.4,0.4-2.1,0.5c-0.6,0.1-1.2,0.1-1.9,0c-0.3,0-0.5-0.1-0.8-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.5,0.4-1,0.6-1.6,0.8
		c-0.5,0.1-0.9,0.1-1.4,0.1C341.1,212.9,340.7,212.7,340.8,212.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M139.3,650.5c0-0.1-0.6-0.4-1.8-0.7c-1.6-0.5-3.3-0.8-5-1.1c-5.6-0.9-11.2-1.1-16.9-0.5
		c-5.3,0.6-10.1,1.9-13.9,2.7c-1.9,0.4-3.6,0.7-5,0.9s-2.5,0.3-3.3,0.4s-1.9,0-3.3,0c-0.7,0-1.5-0.1-2.3-0.2s-1.8-0.1-2.7-0.3
		c-2.1-0.2-4.2-0.6-6.3-1.2c-2.3-0.6-4.6-1.5-6.7-2.8c-2.1-1.4-4.1-2.8-6-4.4c-1.8-1.4-3.7-2.5-5.8-3.4c-1-0.3-1.8-0.7-2.7-0.9
		l-2.3-0.5c-1.4-0.2-2.6-0.4-3.3-0.5c-2.7-0.5-5.4-1.3-8-2.3l-1.3-0.6l-1.3-0.8c-0.4-0.3-0.8-0.6-1.3-0.9c-0.2-0.2-0.4-0.4-0.6-0.5
		c-0.2-0.2-0.4-0.4-0.5-0.6c-0.7-0.7-1-1.6-0.9-2.6c0.2-0.9,1-1.6,1.9-1.7c1.5-0.3,3-0.5,4.5-0.5c1.5-0.1,3,0,4.4,0l7.5,0.2
		c4.3,0.1,6.9-0.1,7-0.4s-2.6-0.7-6.9-1.1c-2.1-0.2-4.7-0.5-7.5-0.6c-1.4-0.1-2.9-0.1-4.5-0.1c-1.7,0-3.4,0.2-5,0.5
		c-0.4,0.1-0.8,0.2-1.1,0.4c-0.4,0.2-0.7,0.4-1,0.7c-0.3,0.3-0.6,0.6-0.8,1c-0.1,0.2-0.2,0.4-0.3,0.6l-0.2,0.6
		c-0.1,0.4-0.1,0.8,0,1.3c0.1,0.4,0.2,0.8,0.3,1.1l0.5,1c0.2,0.3,0.4,0.5,0.7,0.8c0.2,0.2,0.5,0.5,0.7,0.7c0.2,0.2,0.5,0.4,0.7,0.6
		c0.5,0.3,1,0.7,1.5,0.9s1,0.5,1.5,0.7l1.5,0.5c2.7,0.8,5.5,1.4,8.4,1.7c0.8,0.1,1.8,0.4,3.2,0.7l2.2,0.7c0.8,0.3,1.6,0.7,2.5,1.1
		c1.9,1,3.6,2.2,5.2,3.6c1.9,1.7,3.9,3.3,6.1,4.7c2.3,1.4,4.7,2.3,7.3,2.8c2.2,0.4,4.4,0.7,6.6,0.7c1,0.1,1.9,0,2.8,0s1.7,0,2.4-0.1
		c1.4-0.1,2.6-0.2,3.3-0.2s1.9-0.1,3.3-0.1s3.2-0.2,5.1-0.4c4-0.4,8.8-1.3,13.9-1.9c5.5-0.5,11-0.6,16.5-0.1c2.1,0.1,3.8,0.3,5,0.4
		S139.2,650.6,139.3,650.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M64.5,635c0,0.3-2.5,0.4-6.6,0.2c-2.4-0.1-4.8-0.4-7.2-0.8c-0.3-0.1-0.7-0.2-1-0.3s-0.3-0.1-0.6-0.2
		c-0.3-0.1-0.5-0.2-0.7-0.3c-0.6-0.2-1-0.6-1.3-1.2c-0.2-0.5-0.1-1.1,0.3-1.5c0.5-0.5,1.1-0.8,1.7-1c0.4-0.1,0.8-0.2,1.2-0.3
		c0.8-0.2,1.6-0.3,2.4-0.3c1.7-0.1,3.1,0,4.6,0l4.2,0.1c2.7,0,5.1,0.1,7.1,0.2s3.6-0.2,4.7-0.3s1.8-0.1,1.8,0s-0.6,0.4-1.6,0.8
		c-1.6,0.5-3.2,0.8-4.8,1c-2.1,0.1-4.4,0.2-7.1,0.2l-4.2,0.1c-1.4,0-3,0-4.4,0.1c-0.7,0-1.4,0.1-2.1,0.3c-0.3,0.1-0.7,0.1-1,0.2
		h-0.1l0,0l0,0l0.2,0.1l0.4,0.1c0.3,0.1,0.6,0.2,0.9,0.3c2.5,0.6,4.9,0.9,6.9,1.2C62,634.3,64.5,634.7,64.5,635z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M46.1,401c0,0.2-2.3-0.5-5.9-0.5c-2.1,0-4.3,0.2-6.3,0.8c-1.2,0.3-2.4,0.7-3.6,1.2c-1.2,0.5-2.5,1.1-3.9,1.6
		c-4.6,1.5-9.3,2.7-14.1,3.5c-3.7,0.7-5.9,1-6,0.8s2.2-0.7,5.8-1.5s8.6-2,13.9-3.8c1.3-0.5,2.5-1,3.8-1.5c1.2-0.5,2.5-0.9,3.8-1.2
		c2.2-0.5,4.4-0.7,6.6-0.6c1.5,0.1,3,0.3,4.4,0.7C45.1,400.5,45.6,400.7,46.1,401z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M185.3,470.2c-1.2,3-2.2,6.1-2.9,9.2c-0.7,2.9-1.3,5.6-1.7,7.8c-0.9,4.5-1.4,7.3-1.6,7.3s-0.3-2.9,0.2-7.5
		c0.2-2.3,0.6-5,1.3-8c0.7-3.3,1.6-6.5,2.9-9.7C183.5,469,185.3,470.1,185.3,470.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M211.2,496.6c-0.7,9.2-1,17.6-1.1,23.7s-0.1,9.8-0.4,9.8s-0.8-3.7-1.1-9.8c-0.3-7.9-0.2-15.9,0.5-23.8
		C209.1,496.2,211.2,496.5,211.2,496.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M198.4,485c-3.5,9.4-6.3,19-8.4,28.7c-1.6,7.5-2.1,12.3-2.4,12.2c-0.1,0-0.1-1.2-0.1-3.4s0.4-5.3,1.1-9.1
		c1.7-10,4.3-19.8,7.9-29.2C196.6,483.9,198.5,484.8,198.4,485z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M99.7,461.6c-0.5,9.5-0.5,18.1-0.6,24.3s0,10.1-0.3,10.1s-0.8-3.8-1.2-10.1c-0.4-8.2-0.4-16.3,0-24.5
		C97.6,461.2,99.7,461.4,99.7,461.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M130,458.7c-0.1,6.8-0.5,13.6-1.3,20.3c-0.6,5.2-1.4,8.3-1.7,8.3s0-3.3,0.2-8.4s0.6-12.4,0.7-20.3
		C128,458.4,130.1,458.5,130,458.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M152.2,453.3c0.6,7.5,0.7,15.1,0.4,22.6c-0.3,5.8-0.8,9.3-1.1,9.3s-0.3-3.6-0.4-9.3s-0.3-13.7-1-22.4
		C150.1,453.2,152.2,453,152.2,453.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M88.7,459.1c-0.3,4.2-0.4,8-0.6,10.7s-0.2,4.5-0.5,4.5s-0.8-1.6-1-4.4c-0.3-3.6-0.3-7.3,0-10.9
		C87.3,459,88,459,88.7,459.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M55,229.3c0.1,0.1-0.1,0.6-0.6,1.4s-1.2,2-2.1,3.5c-1.8,2.9-4.3,6.9-7,11.3c-5.3,8.9-9.4,16.3-10,16
		s2.8-8.1,8.2-17.1c2.3-3.9,4.8-7.6,7.6-11.1c0.8-1.1,1.7-2.1,2.7-3.1C54.5,229.5,54.9,229.2,55,229.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M53,216.3c0.3,0,0.7,0.8,0.9,2.3c0.3,1.9,0.4,3.8,0.3,5.8c-0.2,4.5-1.1,8-1.7,7.9s-0.6-3.7-0.4-8
		c0.1-2.2,0.2-4.1,0.3-5.6S52.7,216.3,53,216.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M82.7,197.9l1.4-2.4c0.4-0.8,1.1-1.7,1.7-2.8s1.5-2.3,2.5-3.6s2-2.8,3.2-4.3s2.5-3.2,4-4.9s3-3.4,4.7-5.2
		s3.5-3.6,5.5-5.4l1.1,1.6l-2.2,1.4l-1.1,0.7c-0.4,0.2-0.7,0.5-1.1,0.7l-2.1,1.6l-1.1,0.8c-0.4,0.3-0.7,0.6-1.1,0.9
		c-1.2,1.1-2.5,2.1-3.6,3.1s-2.3,2.1-3.3,3.2s-2.1,2.2-3.1,3.3s-1.9,2.2-2.9,3.2l-2.6,3.2c-0.8,1-1.6,2.1-2.4,3.1
		c-1.5,2-2.9,3.9-4.1,5.7s-2.3,3.4-3.2,4.8s-1.7,2.7-2.3,3.7c-1.2,2.1-1.8,3.3-1.8,3.3s0.6-1.2,1.7-3.3c0.6-1.1,1.2-2.4,2.1-3.9
		s1.8-3.2,2.9-5s2.4-3.8,3.8-6c0.7-1,1.4-2.1,2.3-3.2l2.5-3.4l2.8-3.4c1-1.1,2-2.3,3.1-3.4s2.2-2.2,3.4-3.4s2.4-2.2,3.7-3.3
		c0.4-0.3,0.7-0.6,1.1-0.9l1.1-0.8l2.2-1.6c0.4-0.3,0.8-0.5,1.1-0.7l1.1-0.7l2.3-1.4l1.1,1.6c-1.9,1.7-3.8,3.4-5.6,5.1
		s-3.4,3.3-4.9,4.9s-3,3.1-4.3,4.6s-2.5,2.8-3.5,4.1s-1.9,2.4-2.7,3.5s-1.4,1.9-1.9,2.7L82.7,197.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M99.1,191.1c0.1,0.2-1.6,1.8-4.6,4.8s-7.1,7.3-11.8,12.6l-2.8,3.1l1.2-4c1.1-3.3,2.6-6.5,4.6-9.4
		c1.9-3.1,4.1-6.2,6.5-9.4c1.9-2.5,3.8-5,5.8-7.3l1.4,1.4c-6.5,5.6-12.7,11.5-18.5,17.9c-4.3,4.8-6.5,8-6.7,7.9s1.6-3.7,5.6-8.7
		c5.5-6.7,11.6-12.9,18.2-18.5l1.4,1.4c-1.9,2.3-3.8,4.7-5.7,7.2c-2.3,3.1-4.5,6.1-6.4,9.2c-1.8,2.8-3.4,5.7-4.5,8.9l-1.6-0.9
		c4.9-5.2,9.2-9.4,12.4-12.1S99,191,99.1,191.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M105.6,190c-0.3,0-0.6-2.1-1.3-5.4c-0.2-0.8-0.4-1.7-0.7-2.6c-0.1-0.2-0.1-0.2-0.2-0.2s0,0.1,0,0.1
		c0,0.1,0,0.2,0,0.3v0.3c0,0.2-0.1,0.3-0.1,0.5l-0.5,1.9c-1.2,4.3-2.9,8.5-5,12.5c-1.6,3-2.9,4.8-3.2,4.6s0.6-2.1,1.8-5.2
		c1.7-4.1,3.1-8.2,4.3-12.4l0.5-1.8c0.1-0.2,0.2-0.9,0.3-1.4l0.3-1.6c0.1-0.8,0.3-1.7,0.6-2.4l0.3-1v-0.1c0,0,0.1,0.1,0.1,0.1
		l0.2,0.3l0.3,0.5l0.6,1c0.2,0.3,0.4,0.7,0.5,1.1c0.3,0.9,0.6,1.8,0.8,2.6c0.2,0.9,0.4,1.9,0.5,2.9C106.1,187.9,105.8,190,105.6,190
		z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M114.3,189.4c-0.1-0.1,0.1-1.1,0.7-3.1c0.3-1,0.5-2.2,0.9-3.5s0.6-3,0.8-4.7c0.6-4.1,0.6-8.3,0.2-12.4
		c-0.1-1.2-0.3-2.4-0.5-3.6l-0.2-0.9l-0.1-0.4l0,0l0,0l0,0l0,0l-0.4,1.7c-1.3,5.4-2.7,10.4-4.2,15c-1.3,4-2.9,8-4.6,11.8
		c-1.2,2.6-2.6,5.1-4.1,7.5c-1.1,1.7-2,2.5-2.1,2.4c0.4-1,0.8-1.9,1.4-2.8c0.9-1.8,2.2-4.4,3.5-7.7s2.8-7.3,4.2-11.8s2.7-9.6,4-14.9
		c0.2-0.8,0.4-1.5,0.5-2.3l0.4-2l0.2-1c0.1-0.3,0.1-0.7,0.2-0.9l0.9-2.8c0.2,0.4,0.4,1,0.5,1.5s0.3,1,0.5,1.5s0.3,1,0.5,1.6l0.4,1.8
		c0.1,0.3,0.1,0.6,0.2,0.9l0.1,0.5l0.2,0.9c0.2,1.3,0.3,2.5,0.4,3.7c0.3,4.3,0.1,8.6-0.7,12.8c-0.3,1.8-0.8,3.4-1.1,4.8
		s-0.9,2.6-1.3,3.5C114.9,188.4,114.4,189.4,114.3,189.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M126.3,159.4c-0.2-0.1,0.2-2.7,1.3-7.1s2.8-10.6,5-17.8l1.8,0.7l-2.6,5.7c-0.9,2-1.8,4-2.6,6.1
		c-3.5,8.3-6.5,16.9-8.9,25.6c-1.8,6.7-2.4,11-2.7,10.9s-0.3-4.4,1.2-11.3c2-8.9,4.8-17.7,8.4-26.1c0.9-2.1,1.8-4.1,2.7-6.1l2.7-5.7
		l1.8,0.7c-2.3,7.1-4.3,13.2-5.7,17.5S126.5,159.4,126.3,159.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M116.2,208.2c0.3,0.1,1.1-2.6,2.6-6.7c2-5.4,4.2-10.7,6.8-15.9c1.7-3.5,3.4-6.8,3.7-7.5l1.4-3.5L128,177
		c-2.5,2.3-4.6,5-6.3,8c-1.2,2.2-1.9,3.7-2.4,4.6c-0.3,0.5-0.6,1.1-1.1,2.2c-0.8,1.7-1.5,3.4-2.1,5.2l1,0.2
		c0.5-1.6,2.2-7.9,4.3-14.8c3.1-10.5,6.2-19.3,7.7-22.1c0.1-0.2,0.3-0.5,0.4-0.8c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.3-0.4,0.5-0.6
		c0.1-0.1,0.1,0,0.2,0.1c0,0.1,0.1,0.2,0.1,0.3c0,0.2,0,0.4,0,0.5v1.5v2.3c-0.1,1.6-0.1,3.5-0.2,5.6l-0.2,4.3l1.9-4
		c0.9-2.7,1.7-5.4,2.3-8.2c0.4-1.7,0.7-3.3,0.9-5c0.1-0.8,0.2-1.6,0.2-2.5c0-0.4,0-0.9-0.1-1.3l-0.1-0.4c0-0.2-0.1-0.3-0.1-0.5
		c-0.1-0.3-0.3-0.6-0.5-0.9c-0.2-0.2-0.5-0.4-0.9-0.4c-0.3,0-0.7,0-1,0.2c-0.2,0.1-0.5,0.2-0.7,0.4l-0.5,0.4c-0.6,0.5-1.1,1-1.6,1.5
		c-0.8,1-1.6,2-2.3,3c-2.5,3.8-3.6,6.3-3.4,6.5s1.8-2.1,4.6-5.6c0.7-0.8,1.5-1.8,2.4-2.6c0.4-0.4,0.9-0.8,1.4-1.2l0.4-0.3
		c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0l0,0l0,0v0.2c0,0.3,0,0.7,0,1c0,0.7-0.1,1.5-0.2,2.2c-0.2,1.5-0.5,3.1-0.9,4.8
		c-0.8,3.9-1.7,7.5-1.8,8.2l1.6,0.3c0-2.1,0-4-0.1-5.7c0-0.8-0.1-1.6-0.1-2.3l-0.1-1.1c-0.1-0.5-0.1-1-0.2-1.4
		c0-0.2-0.1-0.4-0.1-0.5c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.2-0.3-0.5-0.6-0.4c-0.4,0.3-0.8,0.7-1,1.1c-0.3,0.5-0.5,1-0.7,1.3
		l-0.4,0.8c-3.7,6.9-6.8,14.2-9.1,21.8c-1.6,5-2.7,10-3.3,15.2l-0.4,5.1l1.3-4.8c0.5-1.8,1.1-3.5,1.8-5.2c0.5-1.1,0.7-1.8,1-2.3
		c0.8-1.5,1.7-2.9,2.6-4.3c1.9-2.7,4.1-5.1,6.6-7.1l-1.3-1.1c-1.5,2.4-2.9,4.8-4.1,7.4c-2.6,5.2-4.7,10.7-6.3,16.3
		C116.2,205.3,115.9,208.1,116.2,208.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M140,208.9c-0.3,0,0.4-3.7,0.2-10c-0.3-8.7-1.6-17.2-3.8-25.6l1.8-0.3c1.1,10,0.6,20.1-1.5,29.9
		c-0.9,4.6-2.3,9.1-4.1,13.5l-2.5,5.8l0.6-6.4c0.3-4.5,0.2-9-0.5-13.4c-0.6-4-1.4-7.9-2.4-11.8l1.6-0.4c0.5,3.6,0.6,7.2,0.4,10.9
		c-0.1,3.2-0.4,6-0.7,8.3c-0.6,4.5-1.2,7-1.3,7s0.1-2.6,0.4-7.1c0.1-2.3,0.3-5,0.3-8.2c0-3.5-0.2-7.1-0.8-10.6l1.6-0.4
		c1.2,3.9,2,7.9,2.7,12c0.8,4.6,1,9.3,0.7,13.9l-2-0.5c1.8-4.2,3.1-8.6,4-13.1c2-9.6,2.6-19.5,1.7-29.3l1.8-0.3
		c2.1,8.5,3.1,17.3,3.1,26.1c0,1.6-0.1,3-0.3,4.3s-0.3,2.3-0.4,3.1C140.6,207.2,140.3,208,140,208.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M144.3,206.8c-0.3,0-0.1-4.1,0.6-10.7s2.1-15.7,3.9-25.6l0.4-2.5l2,0.7c-1.9,4-3.5,8.1-4.8,12.3
		c-1.2,3.9-2.2,7.4-3,10.4c-1.5,5.9-2.4,9.6-2.6,9.5s0.1-3.8,1.3-9.9c0.6-3,1.4-6.6,2.6-10.6c1.2-4.3,2.8-8.6,4.7-12.6l3.3-6.9
		l-1.4,7.5l-0.5,2.5c-1.8,9.9-3.3,18.9-4.5,25.4S144.6,206.8,144.3,206.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M144.1,219.3c-0.1,0,0.1-1.4,0.6-3.9c0.3-1.2,0.7-2.7,1.1-4.4s1.1-3.7,1.8-5.8c2.7-8.6,7.1-20.2,12.6-32.7
		c1-2.4,2.1-4.7,3.1-7l1.7,1.1c-3.4,4.2-6.5,8.8-9.2,13.5c-2.5,4.4-4.5,8.4-6,11.8c-3.2,6.8-4.8,11-5.1,10.9s1-4.5,3.8-11.5
		c1.7-4.1,3.6-8.2,5.7-12.1c2.6-4.9,5.7-9.6,9.2-14l4.8-5.9l-3.1,7c-1,2.3-2,4.6-3.1,6.9c-5.4,12.5-10,24-13.1,32.4
		S144.4,219.3,144.1,219.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M162.6,201c0.3,0.1,1.3-2.5,3.6-6.3c3-5,6.4-9.6,10.3-14c3.1-3.6,6.1-6.9,6.7-7.6l2.2-2.9l-3.1,1.7
		c-2.7,1.5-5.2,3.2-7.5,5.2c-1.6,1.4-2.7,2.4-3.3,3.1c-5.2,4.5-9.5,10-12.9,16l1.9,0.8c0.1-0.2,0.7-1.6,1.3-3.1
		c3-6.8,6-12.8,6.7-14.4l1.2-3l-2,2.4c-3.3,3.9-4.1,5.4-4.8,6.2c-0.9,1.3-2.9,3.8-5.3,7.2c-2.8,4.1-5.4,8.4-7.8,12.8
		c-6.4,12.2-10.4,22.7-9.8,22.9s5.3-9.8,11.7-21.9c2.6-4.9,5-9.4,7-13s3.7-6.3,4.6-7.5c0.7-0.8,1.6-2.2,5-5.9l-0.8-0.6
		c-3,4.4-5.6,9.1-7.7,14c-0.6,1.5-1.2,3-1.3,3.2l1.9,0.8c1.9-3.8,4.2-7.5,6.7-10.9c1.4-2,3-3.8,4.7-5.5c1.1-1,2.2-2,3.4-2.8
		c2.4-1.7,5-3.3,7.6-4.6l-0.8-1.2c-2.6,2.2-5,4.7-7.2,7.4c-3.9,4.5-7.3,9.4-10,14.7C162.9,198.1,162.4,200.9,162.6,201z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M256.5,241.7c-0.1-0.1,0.1-0.6,0.5-1.6c0.2-0.5,0.5-1,0.8-1.7l0.5-1l0.5-1.2c0.4-0.8,0.8-1.7,1.2-2.7
		s0.8-2,1.2-3.2s0.7-2.3,1.1-3.6s0.6-2.6,0.9-3.9c0.8-4.6,0.9-9.3,0.2-13.9c-0.5-3.6-1.3-5.7-1-5.8s1.5,1.8,2.5,5.5
		c1.2,4.8,1.3,9.8,0.4,14.6c-0.3,1.4-0.6,2.8-1,4.1s-0.8,2.5-1.3,3.7s-1,2.2-1.4,3.2l-1.5,2.7l-0.7,1.1l-0.7,1
		c-0.4,0.6-0.8,1.1-1.1,1.5C257,241.3,256.6,241.8,256.5,241.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M268.8,216.2c-0.1,0,0-1.4,0.3-3.8c0.1-0.6,0.2-1.3,0.3-2l0.2-2.4c0.1-0.9,0.1-1.8,0.2-2.7v-3.1
		c0.1-5-0.6-10-1.9-14.9c-0.2-0.7-0.4-1.4-0.6-2.1s-0.5-1.4-0.8-2s-0.5-1.4-0.9-2.1s-0.6-1.4-1-2s-0.7-1.4-1.1-2s-0.8-1.3-1.3-1.9
		c-0.9-1.3-1.9-2.5-3-3.6c3.2-2.6,0.9-0.7,1.6-1.3l0,0l0,0v0.1l0.1,0.1l0.1,0.3l0.3,0.6l0.6,1.1c0.4,0.7,0.8,1.5,1.1,2.3
		c0.3,0.8,0.6,1.5,0.9,2.3l0.5,1.1l0.3,1.1c0.5,1.5,0.9,3,1.2,4.5l0.5,2.2c0.1,0.7,0.2,1.5,0.3,2.2c1.2,8.5,1,17.2-0.7,25.6
		c-0.5,2.4-1,3.7-1.1,3.6c-0.3-0.1,0.8-5.4,1.1-14c0.2-5-0.2-10.1-1-15.1c-0.1-0.7-0.2-1.4-0.4-2.1s-0.3-1.4-0.5-2.1
		c-0.3-1.5-0.7-2.9-1.2-4.4l-0.3-1.1l-0.4-1.1c-0.3-0.7-0.6-1.4-0.9-2.2c-0.3-0.7-0.6-1.5-1-2.1l-0.5-1.1l-0.3-0.5l-0.1-0.3
		l-0.1-0.1v-0.1l0,0l0,0c0.7-0.6-1.6,1.3,1.6-1.3c1.2,1.2,2.3,2.5,3.2,3.9c0.5,0.7,0.9,1.4,1.4,2.1s0.8,1.4,1.2,2.1s0.7,1.4,1,2.2
		c0.3,0.7,0.6,1.4,0.9,2.2c0.3,0.7,0.5,1.4,0.8,2.1s0.4,1.4,0.6,2.1c1.3,5,1.8,10.2,1.5,15.4c-0.1,1.1-0.1,2.1-0.2,3.1
		s-0.3,1.9-0.4,2.8s-0.2,1.7-0.4,2.4l-0.5,2C269.4,214.9,269,216.2,268.8,216.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M267.8,227c-0.1-0.1,0.5-1.6,1.8-4.4s3.3-6.7,6-11.5c5.3-9.6,13.1-22.5,22.7-36.3l1.2-1.7l1.6,1.4
		c-9.6,9.8-18.2,20.5-25.7,32c-2.9,4.4-5.1,8-6.7,10.5s-2.4,3.9-2.5,3.8s0.5-1.6,1.8-4.2s3.4-6.4,6.1-10.9
		c7.2-11.8,15.8-22.8,25.5-32.7l1.6,1.4L300,176c-9.5,13.7-17.5,26.5-23.1,35.8c-2.8,4.7-5,8.5-6.5,11.2S268,227.1,267.8,227z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M259.5,261.8c-0.6-0.3,3.5-8.5,6-19.3c1.1-4.6,1.6-9.3,1.6-14c-0.1-3.6-0.5-5.8-0.3-5.9s1.3,2,1.8,5.8
		c0.5,4.9,0.1,9.8-1,14.6c-1.1,4.7-2.7,9.3-4.9,13.7C261,260,259.7,262,259.5,261.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M250.2,267.2c-0.1,0-0.1-0.7-0.1-2s0-3.1,0-5.4c-0.1-2.8-0.3-5.5-0.7-8.3c-0.2-1.7-0.6-3.3-1.1-4.9
		c-0.2-0.9-0.5-1.7-0.9-2.5c-0.3-0.8-0.7-1.6-1.2-2.3l-2.7-3.6l3.9,2.3c1.6,1,2.9,2.3,3.8,3.8c0.3,0.4,0.5,0.8,0.7,1.2l0.4,0.8
		c0,0.1,0,0.1,0.1,0.1s0-0.1,0-0.1c0.1-0.7,0.1-1.3,0.1-2c0.1-2.2,0.1-4.4,0-6.5c-0.1-3-0.5-5.9-1-8.9l-1.5-8.2l3.4,7.5
		c2.2,5.1,3.6,10.5,4,16c0.4,4.4,0.3,8.8-0.2,13.1c-0.9,7.4-2.3,11.6-2.5,11.5s0.7-4.4,1.2-11.6c0.3-4.3,0.2-8.6-0.2-12.8
		c-0.5-5.3-1.9-10.4-4.1-15.3l1.9-0.6c0.6,3,0.9,6.1,1.1,9.2c0.1,2.3,0.1,4.5,0,6.8c0,0.5-0.1,1.1-0.1,1.6v0.4l-0.1,0.7l-0.2,1.5
		c-0.1,1-0.3,2-0.5,3s-0.3,1.8-0.5,2.8c-0.4-1.2-0.8-2.4-1.1-3.5c-0.3-0.9-0.6-1.9-1-2.8l-0.5-1l-0.3-0.6c-0.2-0.4-0.4-0.7-0.6-1.1
		c-0.8-1.3-1.9-2.5-3.1-3.3l1.2-1.4c0.6,0.9,1.1,1.8,1.4,2.8c0.3,0.9,0.6,1.8,0.9,2.7c0.4,1.7,0.8,3.4,1,5.2
		c0.3,2.8,0.4,5.7,0.3,8.5c-0.1,1.8-0.2,3.6-0.5,5.5C250.4,266.5,250.3,267.2,250.2,267.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M241.8,259.8c0.1-0.1,1.1,0.5,2.4,2.2c1.7,2.3,3.1,4.8,4.1,7.5l-1.7,0.4c-0.6-4.8-1.8-9.5-3.4-14
		c-0.4-1.2-0.8-2.4-1.2-3.5l-2.7-6.9l4.5,5.9c1.8,2.4,3.3,5.1,4.5,7.9c1,2.2,1.8,4.5,2.5,6.8c1.1,3.9,1.5,6.4,1.2,6.4s-1-2.3-2.5-6
		c-0.7-1.9-1.6-4.1-2.8-6.5c-1.2-2.6-2.8-5.1-4.5-7.4l1.8-1c0.5,1.2,0.9,2.4,1.3,3.6c1.7,4.7,2.7,9.6,3.1,14.6l0.3,6.1l-2-5.7
		c-0.9-2.6-2-5.1-3.3-7.5C242.4,260.8,241.7,260,241.8,259.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M275.7,234.5c-0.2-0.1,1.6-3.9,5-10s8.4-14.4,14.6-23.5l1.6,1.3c-1.6,1.6-3.2,3.2-4.8,5
		c-7.1,8-13.5,16.6-19.1,25.8c-4.4,7-6.7,11.5-7,11.4s1.7-4.9,5.7-12.1c5.3-9.5,11.6-18.3,18.8-26.4c1.6-1.8,3.3-3.4,4.9-5l1.6,1.3
		c-6.2,9-11.4,17.1-15.1,23C278.1,231.2,275.9,234.6,275.7,234.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M273.2,251.7c-0.2-0.1,0.7-4.2,3.2-10.6c3.3-8.4,7.6-16.2,13-23.4c0.4-0.4,0.7-0.9,1.1-1.3l1.4,1.5
		c-6.5,5.4-12.1,11.7-16.6,18.8c-3.5,5.4-5.3,9-5.5,8.9s1-4,4.3-9.6c4.3-7.5,9.9-14.2,16.5-19.7l1.4,1.5c-0.3,0.4-0.7,0.8-1,1.2
		c-5.3,7.1-9.7,14.7-13.3,22.7C274.9,247.8,273.5,251.8,273.2,251.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M271.8,267.7c-0.1,0,0.2-1.6,1-4.4c0.4-1.4,1-3.1,1.7-5.1s1.6-4.2,2.6-6.7c5.6-12.9,12.2-25.4,19.8-37.2
		l1.6,1.3l-3.1,3.5c-1,1.2-2.1,2.4-3.1,3.7c-5.6,6.9-10.5,14.2-14.6,22.1c-3.4,6.4-6.3,13-8.7,19.8c-2.1,5.8-3.4,10.6-4.3,14
		s-1.2,5.2-1.4,5.2s0-2,0.6-5.4c0.9-4.8,2.1-9.6,3.7-14.3c2.2-7,5.1-13.7,8.4-20.2c4.1-8,9-15.5,14.7-22.5c1-1.3,2.1-2.5,3.2-3.7
		l3.2-3.6l1.6,1.3c-7.6,11.7-14.3,24-20.3,36.6c-1.1,2.4-2,4.6-2.9,6.5s-1.5,3.6-2,5C272.6,266.2,272,267.7,271.8,267.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M274.2,272.5c-0.1-0.1,0.3-1.1,1.1-2.8s2-4.3,3.3-7.5c2.7-6.3,6.1-15.3,9.6-25.2l1.9,0.8l-0.4,0.9
		c-3.9,8.5-8.5,16.6-13.8,24.2c-4.1,5.8-7.3,9-7.6,8.8s2.5-3.6,6.3-9.6c4.9-7.8,9.3-15.9,13.1-24.3l0.4-0.9l1.9,0.8
		c-3,8.6-6.4,17-10.3,25.2c-1.5,3.1-2.9,5.6-3.9,7.2S274.3,272.6,274.2,272.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M269.5,288.8c-0.1-0.1,0.3-0.8,1.1-1.9s1.9-2.9,3.1-5.1c2.5-4.4,5.7-10.7,9-17.8c0.7-1.5,1.3-2.9,2-4.2l1.8,1
		c-3.3,4.6-7.3,8.8-11.8,12.3c-3.4,2.6-5.8,3.7-5.9,3.5s1.9-1.7,5-4.5c4.1-3.7,7.8-7.9,11-12.4l1.8,1c-0.6,1.4-1.3,2.8-2,4.3
		c-2.8,6.1-6,12-9.6,17.6c-1.1,1.7-2.4,3.3-3.7,4.9C270.3,288.5,269.6,288.9,269.5,288.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M258.5,292.9c-0.6-6.5-0.7-13-0.3-19.5c0.4-5,1.2-8.1,1.5-8s0,3.1,0,8.1s0.3,11.8,0.9,19.4
		C260.5,293.1,258.5,293,258.5,292.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M454.6,194.9c-0.3,0,0.2-3.2,0.2-8.7c-0.2-7.4-1.3-14.8-3.5-21.9l1.9-0.4c0.7,6.5,0.9,13,0.6,19.4
		c-0.1,1.7-0.3,3.3-0.6,5c-0.2,0.8-0.3,1.6-0.5,2.4c-0.2,0.8-0.5,1.7-0.8,2.4l-2.2,5l0.2-5.5c0.1-1.8,0-3.6-0.3-5.4
		c-0.3-1.7-0.7-3.4-1.2-5.1c-0.9-3-2.2-5.9-3.7-8.7l1.5-0.7c1.6,4.7,2.5,9.7,2.5,14.7c0,3.6-0.8,5.6-0.9,5.5s0.2-2,0-5.5
		c-0.4-4.8-1.4-9.6-3.1-14.1l1.5-0.7c1.7,2.8,3.1,5.9,4.1,9c1.2,3.6,1.8,7.3,1.7,11.1l-2-0.5c0.3-0.7,0.5-1.4,0.6-2.1
		c0.2-0.7,0.3-1.5,0.5-2.3c0.2-1.6,0.4-3.1,0.5-4.8c0.3-6.4,0.2-12.8-0.4-19.1l1.9-0.4c2.1,7.3,3.1,14.9,2.8,22.5
		c-0.1,2.2-0.3,4.3-0.7,6.4C455.2,193.4,455,194.2,454.6,194.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M459.4,202.8c-0.1,0-0.2-1,0.2-2.8c0.6-2.5,1.4-5,2.5-7.3c0.7-1.5,1.5-3.1,2.4-4.9c1-1.9,2.2-3.7,3.5-5.3
		c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.4-0.3,0.6-0.5c0.3-0.3,0.8-0.5,1.3-0.5c0.4,0.1,0.8,0.4,1,0.8l0.3,0.7c0,0.1,0.1,0.2,0.1,0.4
		l0.1,0.2c0.3,1.2,0.4,2.4,0.5,3.7c0.2,2.5,0.3,5,0.3,7.6l-2-0.3c0.7-2.8,1.4-5.8,1.9-8.8c0.6-2.9,0.9-5.9,1-8.9c0-0.7,0-1.3-0.2-2
		c-0.1-0.6-0.4-1.1-0.5-1.1s-0.1,0-0.3,0c-0.2,0.1-0.5,0.2-0.7,0.3c-0.5,0.3-1,0.8-1.5,1.2c-1.8,1.7-3.6,3.6-5.2,5.6
		c-1.5,1.8-2.9,3.6-4.1,5.3c-4.8,6.6-7.4,11-7.7,10.9s0.3-1.3,1.4-3.3c1.5-2.9,3.2-5.7,5.1-8.4c1.2-1.7,2.5-3.6,4-5.5
		c1.6-2.1,3.3-4,5.2-5.9c0.5-0.5,1.1-1,1.7-1.5c0.4-0.2,0.8-0.4,1.2-0.6c0.6-0.2,1.2-0.2,1.8,0.1c0.5,0.3,0.9,0.7,1.2,1.3
		c0.2,0.4,0.3,0.8,0.4,1.3c0.1,0.8,0.2,1.6,0.2,2.4c-0.1,3.1-0.4,6.2-1,9.3c-0.6,3.1-1.3,6.1-2,9l-2.1,8l0.1-8.3
		c0-2.6,0-5.1-0.2-7.4c-0.1-1.1-0.2-2.3-0.4-3.4l-0.1-0.3c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1,0h-0.1l-0.3,0.3
		c-1.3,1.5-2.5,3.2-3.5,4.9c-1,1.6-1.8,3.2-2.6,4.6c-1.2,2.2-2.2,4.6-3,6.9C460,201,459.7,201.9,459.4,202.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M458.9,209.8c-0.3,0-0.3-2.2,0.9-5.4c0.3-0.8,0.6-1.7,1-2.6c0.2-0.5,0.4-0.9,0.7-1.4c0.3-0.7,0.7-1.3,1.1-1.9
		c0.6-0.8,1.3-1.3,2.1-0.9c0.3,0.2,0.6,0.6,0.7,1l0.1,0.4c0,0.1,0,0.3,0.1,0.4c0,0.3,0.1,0.6,0.1,0.9c0.1,1.3,0.1,2.6,0,3.9l-2-0.4
		l0.6-2c0.8-2.7,1.6-5.3,2.1-7.6c0.1-0.3,0.1-0.6,0.2-0.9v-0.2V193l0,0V193c0,0,0.1-0.2,0-0.3c-0.1,0-0.2,0.1-0.3,0.1
		c-0.1,0-0.2,0.1-0.2,0.2l-0.3,0.2l-0.5,0.4c-2.8,2.4-4.5,4-4.7,3.7s1.1-2.1,3.7-4.8l0.5-0.5l0.3-0.3c0.1-0.1,0.2-0.1,0.4-0.3
		c0.6-0.6,1.3-0.9,2.1-1c0.2,0,0.3,0.1,0.5,0.2c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.2,0.1,0.4,0.2,0.6c0,0.2,0,0.5,0,0.7
		c-0.1,0.8-0.2,1.5-0.3,2.3c-0.5,2.5-1.2,5.1-2,7.8c-0.2,0.7-0.4,1.3-0.6,2L463,213l0.6-9c0.1-1.2,0.1-2.4,0.1-3.6c0,0,0-0.1,0-0.1
		c0-0.1-0.1,0-0.1,0c-0.2,0.3-0.4,0.6-0.6,0.9c-0.3,0.4-0.5,0.9-0.7,1.3c-0.5,0.9-0.8,1.6-1.2,2.4
		C459.6,207.9,459.1,209.8,458.9,209.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M480.4,268.6c-0.3-0.1,0.2-1.6,0.7-4c0.7-3.2,1-6.4,1-9.6l2,0.3c-0.3,0.8-0.6,1.7-1,2.5c-1.7,3.7-4,7-6.8,9.8
		c-2.2,2.2-3.8,3.2-4,3s1-1.6,2.8-3.9c2.4-3,4.4-6.2,6-9.7c0.4-0.8,0.6-1.6,0.9-2.3l2-5.2v5.5c0,3.4-0.6,6.8-1.7,10
		C481.5,267.4,480.6,268.7,480.4,268.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M489.5,263.5c0.3,0.6-2.8,2.5-6.4,5.1c-1.8,1.3-3.3,2.6-4.4,3.6s-1.8,1.6-2,1.4s0-1.1,0.9-2.4
		c1.2-1.6,2.6-3.1,4.2-4.3C485.6,264.1,489.3,263,489.5,263.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M2.1,198.1c0.1-0.1,0.9,0.6,2.3,1.9c1.9,1.9,3.7,4,5.3,6.3c4,5.6,8.4,14.3,11.5,24.4
		c2.6,8.6,4.4,17.4,5.4,26.4c0.7,6.9,0.6,11.1,0.3,11.1s-0.7-4.2-1.8-10.9c-1.4-8.8-3.3-17.5-5.9-26c-2.5-8.5-6.1-16.6-10.7-24.1
		C4.9,201.3,1.8,198.4,2.1,198.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M24.4,219.1c0.1,0,0.4,0.5,0.9,1.6c0.6,1.5,1.1,3.1,1.6,4.7c1.3,5.3,2.3,10.6,2.7,16.1c1.2,12.7,0.5,23-0.1,23
		s-0.9-10.2-2-22.8c-0.6-6.3-1.4-11.9-2.1-15.9c-0.4-2-0.7-3.7-0.9-4.8S24.3,219.1,24.4,219.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M46.1,227.1c0.1,0.1-0.1,0.6-0.5,1.4s-1.1,1.9-1.9,3.4c-1.6,2.8-3.8,6.7-6,11.1c-4.5,8.9-7.3,16.4-7.8,16.2
		s1.4-8.1,5.9-17.2c1.9-3.9,4.1-7.5,6.7-11c0.8-1.1,1.6-2.1,2.5-3C45.5,227.4,45.9,227,46.1,227.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M27.5,311.5c-0.1,0-0.4-1.1-0.7-3.1c-0.3-2.8-0.4-5.7-0.4-8.5c0.2-9.4,0.8-18.8,2-28.1l2,0.5l-0.4,1.1
		c-1.6,5-2.8,10.1-3.7,15.3c-0.8,4.8-1.4,9.1-1.9,12.8c-1,7.3-1.4,11.8-1.7,11.8s-0.3-4.6,0.2-12c0.3-3.7,0.8-8.1,1.5-12.9
		c0.8-5.3,2-10.5,3.6-15.6l0.4-1.1l3.2-9.6l-1.2,10c-1.3,10.9-2.2,20.8-2.6,27.9c-0.2,3.6-0.3,6.4-0.3,8.4S27.7,311.5,27.5,311.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M46.3,253.2c0.5,0.4-4.5,8.6-9.7,19.3c-2.6,5.3-4.8,10.2-6.3,13.8s-2.5,5.8-2.7,5.7s0.1-2.5,1.3-6.3
		c1.6-4.9,3.5-9.6,5.8-14.2C40,260.8,45.8,252.9,46.3,253.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M44.5,284.9c0.3,0.2-0.6,2-1.9,5s-2.8,7.2-4.4,11.9c-3.2,9.4-5.4,17.2-6,17.1s0.8-8.2,4-17.7
		c1.3-4.1,3-8.1,5.1-11.9C42.9,286.4,44.3,284.8,44.5,284.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M70.8,234.5c0.2,0.1-1.4,2.8-3.8,7.5s-5.7,11.3-9.2,19.3l-3.2,7.4l1.3-7.9c0.4-2.6,1.1-5.1,1.8-7.7
		s1.5-5.3,2.3-8c0.6-2.1,1.2-4.1,1.8-6l1.8,0.9c-5,7.9-9.4,16.2-13.1,24.7c-2.9,6.5-4.5,10.5-4.7,10.4s0.9-4.3,3.4-10.9
		c1.3-3.3,2.9-7.3,5-11.6c2.3-4.7,4.8-9.3,7.7-13.8l4-6.1l-2.1,7c-0.6,2-1.2,4-1.8,6c-0.8,2.7-1.6,5.4-2.3,8s-1.5,5.1-1.9,7.5
		l-1.9-0.5c2.9-6.6,6.2-12.9,9.9-19.1C68.7,236.8,70.7,234.4,70.8,234.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M78.2,212.2c0.4,0.5-6.7,7-14.3,16S50.8,245,50.3,244.8s4.2-8.9,12-18S77.9,211.8,78.2,212.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M39.1,193.4c0.1-0.1,0.4,0.3,0.9,0.9c0.6,0.9,1.2,1.9,1.6,2.9c1.5,3.3,2.7,6.8,3.5,10.3
		c2,8.3,1.9,15.3,1.3,15.3s-1.4-6.7-3.4-14.8c-1-4.1-2-7.7-2.9-10.3c-0.4-1.3-0.8-2.4-1-3.1S39,193.5,39.1,193.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M38.5,231.1c-0.7,0-0.8-13.2-4-29.2c-1.3-6.8-3.1-13.5-5.3-20c-1.7-5-3-8-2.8-8.2s2.1,2.7,4.2,7.6
		c2.7,6.5,4.7,13.3,6,20.2c1.4,6.9,2.1,13.9,2.3,20.9l0.1,1.9v1.7c0,1.1,0,2-0.1,2.7C38.7,230.3,38.6,231.1,38.5,231.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M32.9,226.2c-0.6,0-1.5-10-4-22.1s-5.2-21.7-4.7-21.9s4.4,9.2,6.8,21.5S33.4,226.2,32.9,226.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M25.3,153c-0.2,0,0.1-2,1.5-5.1c2-4.2,4.8-7.9,8.4-10.9l4.7-3.9l-3.3,5.2c-1.8,2.7-3.4,5.5-4.8,8.4
		c-2.8,6-5,12.3-6.6,18.8c-1.2,5-1.7,8.1-2,8.1s-0.3-3.3,0.5-8.4c1.1-6.7,3.2-13.3,6.1-19.4c1.5-3,3.2-5.9,5.1-8.6l1.4,1.4
		c-1.9,1.5-3.7,3.2-5.2,5.1c-1.2,1.5-2.3,3.1-3.3,4.7C26.2,151.3,25.5,153.1,25.3,153z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M32.2,127.2c-0.3-0.1,1-3.6,5-8.3c0.5-0.6,1.1-1.2,1.7-1.7c0.7-0.6,1.5-1.2,2.4-1.5c0.6-0.2,1.2-0.3,1.8-0.2
		c0.7,0.1,1.2,0.5,1.6,1.1c0.6,0.9,1.1,2,1.3,3.1c0.4,2.2,0.6,4.4,0.5,6.6c0,2.3-0.2,4.6-0.4,7.1l-1,9l-1-9
		c-0.2-1.9-0.5-3.8-0.7-5.7c-0.2-1.9-0.6-3.9-1.1-5.8c-0.1-0.3-0.2-0.5-0.3-0.8c-0.1-0.2-0.1-0.1-0.1-0.1l0,0h-0.1
		c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.3,0.2-0.4,0.3c-1,1-2,2.1-2.8,3.3c-1.6,2.3-3.1,4.7-4.4,7.2
		c-1.3,2.2-2.4,4.5-3.4,6.8c-1,2.2-1.7,4.3-2.4,6.2c-2.6,7.8-3.5,12.8-3.8,12.8c-0.1,0-0.1-1.3,0.3-3.6c0.5-3.2,1.2-6.4,2.2-9.6
		c0.6-2,1.3-4.2,2.2-6.4c0.9-2.4,2-4.8,3.2-7.1c1.3-2.6,2.8-5,4.4-7.4c0.9-1.3,1.9-2.6,3-3.7c0.2-0.2,0.3-0.3,0.5-0.4
		c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.5-0.3,0.7-0.4l0.4-0.1c0.2,0,0.4,0,0.5,0c0.7,0,1.4,0.4,1.8,1c0.3,0.4,0.5,0.9,0.6,1.4
		c0.5,2,0.9,4,1.1,6.1c0.3,2,0.5,3.9,0.7,5.8h-2c0.3-2.4,0.4-4.7,0.5-6.9c0.1-2.1,0-4.2-0.3-6.2c-0.1-0.9-0.4-1.7-0.8-2.4
		c-0.2-0.2-0.4-0.4-0.7-0.5c-0.3-0.1-0.6,0-0.9,0.1c-0.7,0.3-1.4,0.7-2,1.2c-0.6,0.5-1.2,1-1.7,1.5c-1.6,1.7-3.1,3.4-4.4,5.3
		C33.2,125.9,32.7,126.5,32.2,127.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M17,157.7c-0.1,0,0.2-1.3,0.8-3.6s1.6-5.5,3-9.5l1.1-3.1l1.3-3.4l1.3-3.6l1.6-3.8c2.3-5.6,4.9-11.1,7.8-16.4
		c0.7-1.1,1.3-2.2,2-3.3l1-1.6l1.1-1.5c0.3-0.5,0.7-1,1.1-1.5s0.8-1,1.2-1.4s0.8-0.9,1.2-1.4c0.4-0.5,0.9-0.9,1.5-1.3l4.1-3
		l-2.7,4.4c-2.2,3.6-4.2,7.3-6,11.1c-1.7,3.5-3,6.6-4.1,9.1c-2.2,5.2-3.5,8.3-3.7,8.2s0.7-3.3,2.6-8.6c1-2.7,2.2-5.8,3.8-9.4
		c1.7-3.9,3.7-7.8,5.8-11.5l1.4,1.4c-0.4,0.3-0.8,0.6-1.1,1c-0.4,0.4-0.8,0.8-1.1,1.3s-0.7,0.9-1.1,1.4s-0.7,0.9-1,1.4l-1,1.5
		l-1,1.6c-0.7,1-1.3,2.1-1.9,3.2c-2.9,5.2-5.5,10.6-7.9,16.1l-1.6,3.7l-1.4,3.5l-1.4,3.3l-1.2,3.1c-1.5,3.9-2.7,7.1-3.6,9.3
		S17.1,157.7,17,157.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M11.5,164.3c-0.3-0.1,1.1-4.6,2.1-12c1.3-9.7,1.3-19.5,0-29.1c-0.3-2.2-0.6-4.3-1.1-6.3l2-0.6
		c1.4,4.5,2.5,9,3.2,13.7c0.7,4.2,1,8,1.3,11.2c0.4,6.3,0.3,10.2,0,10.2s-0.6-3.8-1.4-10c-0.4-3.1-0.9-6.8-1.7-11
		c-0.8-4.5-1.9-9-3.3-13.3l2-0.6c0.5,2.1,0.8,4.3,1.1,6.5c1.4,9.8,1.2,19.8-0.6,29.6l-0.5,2.7c-0.2,0.8-0.4,1.6-0.6,2.4
		c-0.4,1.5-0.7,2.7-1.1,3.7C12.1,163.3,11.6,164.4,11.5,164.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M1.5,148.3c0.1-0.1,0.5,0.4,1.2,1.4c0.3,0.5,0.7,1.1,1.1,1.9s0.8,1.7,1.2,2.7c2,5.8,3.1,11.8,3.2,17.9
		l-1.8-0.2c1.4-6.4,2.3-12.9,2.5-19.4c0.3-6.9,0.3-13.9-0.2-20.8l-0.3-5l2,4.5c1.4,3.1,2.3,6.3,2.8,9.6c0.4,2.5,0.7,5,0.8,7.6
		c0.2,4.2,0,6.6-0.2,6.6s-0.4-2.4-0.9-6.6c-0.3-2.1-0.6-4.6-1.2-7.4c-0.6-3.1-1.5-6.1-2.9-9l1.8-0.5c0.6,7,0.7,14,0.4,21
		c-0.2,6.7-1.1,13.3-2.8,19.8l-1.8,6.7l-0.1-7c0-5.9-0.8-11.8-2.4-17.5C2.8,150.6,1.3,148.5,1.5,148.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M21.5,213.5c-0.6,0-0.9-4.3-0.1-9.5s2.2-9.2,2.8-9s0,4.3-0.7,9.3S22.1,213.5,21.5,213.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M21.8,208.8c-0.1,0-0.3-0.3-0.6-0.9s-0.5-1.4-0.9-2.5c-0.8-2.1-1.8-5-3.2-8.1c-2.7-6.3-5.4-11.1-5-11.5
		s4.1,4.2,6.9,10.6c1.2,2.8,2.1,5.6,2.7,8.6c0.2,0.9,0.3,1.8,0.3,2.7C22,208.4,22,208.8,21.8,208.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M20.5,202.7c-0.5,0.1-2.6-9.3-2.5-21.1s2-21.2,2.7-21.1s-0.6,9.5-0.6,21.1S21.1,202.6,20.5,202.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M19.7,286.7c-0.2,0-0.3-0.6-0.3-1.6s-0.2-2.5-0.3-4.4c-0.2-3.7-0.5-8.8-1-14.5c-1-11.3-2.4-20.4-1.9-20.5
		s2.9,8.9,4,20.3c0.5,4.9,0.6,9.8,0.4,14.6c-0.1,1.5-0.2,3-0.4,4.4C20,286.1,19.8,286.7,19.7,286.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M9.5,318.3c0.8-10,0.7-20-0.2-29.9c-0.7-7.7-1.4-12.4-1.1-12.4c0.1,0,0.5,1.1,1,3.2s1.1,5.2,1.6,9
		c1.3,10,1.6,20.2,0.8,30.3C11.5,318.9,9.5,318.6,9.5,318.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M57,187.4c-0.6,0.1-1.3-1.8-1.2-4.2s0.9-4.2,1.4-4.1s0.7,2,0.7,4.2S57.6,187.3,57,187.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M47.1,187.7c-0.6-0.1-0.5-2.1-0.5-4.5s0.2-4.3,0.7-4.5s1.5,1.9,1.4,4.5S47.6,187.8,47.1,187.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M47.7,185.6c0,0.1-0.2,0.4-0.7,0.9c-0.2,0.2-0.5,0.5-0.8,0.9c-0.3,0.3-0.5,0.7-0.7,1.1c0,0.1,0,0.3,0.1,0.4
		c0.1,0.2,0.2,0.4,0.4,0.6c0.4,0.5,1,0.8,1.6,1.1c1.4,0.6,3,1,4.6,0.9c1.6,0,3.2-0.2,4.7-0.7c0.6-0.2,1.1-0.6,1.5-1
		c0.3-0.3,0.5-0.7,0.4-1.1c-0.3-0.8-0.7-1.6-1.2-2.2c-0.4-0.5-0.6-0.9-0.5-1s0.5,0,1.1,0.4c0.4,0.2,0.7,0.5,1,0.8
		c0.5,0.5,0.8,1,1,1.6c0.2,0.4,0.2,0.9,0.1,1.3c-0.1,0.5-0.3,0.9-0.6,1.3c-0.6,0.7-1.3,1.3-2.1,1.7c-1.7,0.7-3.6,1.1-5.5,1.1
		c-1.9,0-3.8-0.4-5.5-1.3c-0.8-0.4-1.5-1-2-1.7c-0.3-0.4-0.5-0.8-0.6-1.2c-0.1-0.5-0.1-1,0.1-1.4c0.3-0.6,0.7-1.1,1.3-1.4
		c0.3-0.3,0.7-0.5,1.1-0.7C47.2,185.5,47.6,185.4,47.7,185.6z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M68.4,191.8c-0.4,0.5-3-0.9-6.2-2.7s-5.6-3.7-5.4-4.2s3.2,0.5,6.4,2.4S68.8,191.3,68.4,191.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M41.9,187.9c-0.4-0.4,0.5-1.8,2.2-2.7s3.3-0.9,3.4-0.3s-1,1.4-2.4,2.2S42.3,188.4,41.9,187.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M57.5,162.9c0,0.3-1.5,0.4-3.1-0.3c-0.7-0.3-1.4-0.7-1.9-1.3c-0.4-0.5-0.4-0.9-0.3-1s0.3,0.2,0.8,0.4
		c0.6,0.3,1.2,0.6,1.8,0.8C56.2,162.3,57.6,162.6,57.5,162.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M63.5,166.5c0,0.1-0.5,0.3-1.2,0.3c-1.1,0-2.1-0.4-2.9-1c-0.8-0.7-1.4-1.6-1.6-2.7c-0.1-0.8,0-1.3,0.2-1.3
		s0.3,0.4,0.5,1.1c0.3,0.8,0.8,1.5,1.5,2C61.6,166.2,63.5,166.2,63.5,166.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M43.2,165.1c0-0.3,2.2-0.8,4.8-1.8s4.4-2.4,4.6-2.2s-0.2,0.6-1,1.3c-1,0.8-2.1,1.4-3.3,1.9
		C45.7,165.4,43.3,165.4,43.2,165.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M43.6,165.4c0.2,0,0.2,0.6,0.2,1.6c0,1.4,0.1,2.7,0.3,4.1c0.3,1.9,1.1,3.7,2.4,5.2c0.8,0.8,1.6,1.6,2.5,2.2
		c1,0.6,2.2,0.8,3.3,0.8h1c0.3,0,0.5,0,0.8-0.1c0.5-0.1,1.1-0.3,1.6-0.6c1-0.5,1.9-1.2,2.7-2c1.4-1.4,2.4-3.1,2.9-4.9
		c0.3-1.3,0.4-2.7,0.3-4.1c0-1,0-1.6,0.1-1.6s0.4,0.5,0.7,1.5c0.4,1.4,0.5,2.9,0.4,4.4c-0.4,2.3-1.4,4.3-2.9,6c-0.9,1-2,1.9-3.1,2.5
		c-0.7,0.4-1.4,0.6-2.1,0.8c-0.4,0.1-0.8,0.1-1.2,0.1c-0.3,0-0.7,0-1.1,0c-0.7,0-1.5-0.1-2.2-0.3c-0.8-0.2-1.5-0.5-2.1-0.9
		c-1.1-0.8-2.1-1.7-2.9-2.7c-1.4-1.8-2.3-4-2.4-6.2c-0.1-1.5,0-2.9,0.3-4.4C43.2,165.9,43.4,165.4,43.6,165.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M37.7,181.3c0.3-0.1,0.1-1.3,0-3.3c-0.1-1.1-0.1-2.2,0-3.4c0.1-1.3,0.5-2.5,1.2-3.6c1.2-1.8,2.5-3.5,4-5
		c0.5-0.6,1.1-1.1,1.8-1.6c0.4-0.3,0.9-0.6,1.3-0.8c0.7-0.3,2-0.9,3.7-1.7l1.3-0.6c0.4-0.2,0.9-0.4,1.3-0.5c0.2,0,0.3,0,0.5,0
		c0.2,0,0.5,0.1,0.7,0.2c0.5,0.2,1.1,0.4,1.6,0.6c1,0.4,2,1,3,1.5c0.4,0.3,1,0.6,1.4,0.8l1.3,0.7c1.1,0.6,2.2,1.4,3,2.3
		c0.7,1.1,1.1,2.3,1.2,3.6c0.1,2.1,0,4.2-0.5,6.3c-0.5,2.5-1.4,4.9-2.5,7.1c-0.9,1.8-1.6,2.8-1.4,3s1.3-0.5,2.6-2.2
		c1.7-2.2,2.8-4.8,3.3-7.5c0.5-2.3,0.4-4.7-0.2-7c-0.5-1.3-1.1-2.6-2-3.7c-0.8-1.2-1.7-2.2-2.8-3.1l-2.4-2c-1-0.8-2.1-1.4-3.3-1.9
		c-0.6-0.2-1.2-0.4-1.8-0.5c-0.3-0.1-0.7-0.1-1-0.2c-0.4,0-0.8,0-1.2,0.2c-0.6,0.2-1.1,0.5-1.6,0.9c-0.4,0.3-0.8,0.6-1.2,0.9
		c-1,0.9-2.1,1.6-3.2,2.3c-3.5,1.3-6.5,3.7-8.6,6.8c-0.4,0.7-0.8,1.5-1,2.3c-0.2,0.8-0.3,1.5-0.3,2.3c0,1.3,0.2,2.5,0.5,3.7
		C36.8,180.3,37.5,181.4,37.7,181.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M35.6,178.2c0.3-0.1,0.1-1.6,0.1-4c0-1.4,0.2-2.7,0.7-4c0.5-1.6,1.2-3.2,2-4.7c0.6-1.3,1.4-2.5,2.1-3.6
		c0.7-1,1.5-2,2.3-2.9c1-1.2,2.2-2.3,3.5-3.3c1.4-0.8,2.9-1.4,4.4-1.7c1.2-0.2,2.3-0.3,3.5-0.2c1.3,0.2,2.6,0.6,3.7,1.3
		c2.3,1.3,4.3,3,6.1,4.9c1.1,1.1,2.1,2.5,2.8,3.9c0.5,1.5,0.8,3.1,0.9,4.7c0.1,2.7,0.1,5.3-0.3,8c-0.1,0.9-0.3,1.8-0.4,2.6
		s-0.4,1.7-0.5,2.5c-0.3,1.5-0.5,2.9-0.5,4.4c-0.2,2.5,0,4.1,0.3,4.1s0.6-1.5,1.1-3.9c0.3-1.2,0.6-2.6,1-4.2
		c0.2-0.8,0.4-1.6,0.6-2.5s0.4-1.8,0.5-2.8c0.3-2.8,0.2-5.7-0.4-8.4c-0.4-1.6-1-3.2-1.7-4.8c-0.6-1.6-1.4-3.1-2.3-4.5
		c-1.6-2.4-3.7-4.5-6.2-5.9c-1.4-0.8-3-1.3-4.6-1.4c-1.4,0-2.7,0.3-4,0.8c-1.6,0.6-3,1.5-4.4,2.5c-1.4,0.8-2.8,1.8-4.1,2.8
		c-1,0.8-2,1.7-2.8,2.8c-1,1.2-1.8,2.4-2.5,3.8c-0.9,1.6-1.5,3.4-2,5.2c-0.4,1.5-0.5,3.1-0.3,4.6C34.6,176.8,35.3,178.2,35.6,178.2z
		"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M51.2,172.8c0.1-0.1,0.6,0.1,1.4,0.2c0.5,0,0.9-0.1,1.4-0.3c0.3-0.1,0.3-0.2,0.3-0.3c0-0.3,0-0.5-0.1-0.8
		c-0.3-0.6-0.4-1.3-0.6-2c-0.1-0.6,0-1.2,0.2-1.8c0.4-1,0.9-1.4,1-1.3s-0.2,0.6-0.3,1.4c-0.1,0.5-0.1,1,0,1.4
		c0.2,0.6,0.4,1.2,0.6,1.8c0.1,0.4,0.2,0.8,0.2,1.3c0,0.3-0.2,0.5-0.4,0.7c-0.2,0.1-0.4,0.3-0.6,0.3c-0.6,0.2-1.2,0.2-1.8,0.1
		C51.5,173.4,51.1,172.9,51.2,172.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M57.4,175.1c0.2,0.5-1.8,1.6-4.4,1.6s-4.5-1-4.3-1.6s2.1-0.5,4.3-0.5S57.2,174.6,57.4,175.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M56.5,175.4c0.1,0.1-0.1,0.6-0.6,1.3c-0.3,0.4-0.7,0.8-1.1,1.1c-0.5,0.4-1.2,0.6-1.9,0.7
		c-0.7,0-1.4-0.3-1.9-0.7c-0.4-0.3-0.7-0.7-1-1.1c-0.5-0.7-0.7-1.2-0.5-1.3s0.5,0.3,1.1,0.8c0.6,0.7,1.5,1.1,2.4,1.2
		c0.9-0.2,1.8-0.6,2.5-1.2C56,175.6,56.4,175.3,56.5,175.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M60.3,167.4c-0.2,0.2-1-0.3-2.2-0.5s-2.1-0.3-2.2-0.6s1-0.7,2.4-0.4c0.6,0.1,1.1,0.4,1.6,0.8
		C60.3,167.1,60.4,167.3,60.3,167.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M45.6,167.3c-0.1-0.1,0.1-0.4,0.4-0.7c0.5-0.4,1.1-0.6,1.7-0.7c1.4-0.3,2.6,0.2,2.5,0.4s-1.1,0.4-2.3,0.6
		S45.8,167.5,45.6,167.3z"
          />
          <ellipse class="st2" cx="57.9" cy="167.6" rx="1.1" ry="1" />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M487.7,133.1c0-0.6-35.1-1.1-78.4-1.1c-35.1,0-64.8,0.7-74.8,0.7s-39.7-0.7-74.8-0.7s-64.8,0.7-74.8,0.7
		s-39.7-0.7-74.8-0.7c-43.3,0-78.4,0.5-78.4,1.1s35.1,1,78.4,1c35.1,0,64.8-0.7,74.8-0.7s39.7,0.7,74.8,0.7s64.8-0.7,74.8-0.7
		s39.7,0.7,74.8,0.7C452.6,134.2,487.7,133.7,487.7,133.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M17.4,133c0-0.6,1.9-1.1,4.3-1.1s4.3,0.5,4.3,1.1s-1.9,1-4.3,1S17.4,133.6,17.4,133z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M1.6,133c0-0.6,3.1-1.1,6.8-1.1s6.8,0.5,6.8,1.1s-3.1,1-6.8,1S1.6,133.6,1.6,133z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M488.3,107c0-0.1-1.5-0.1-4.1,0.2c-1.6,0.2-3.2,0.5-4.7,0.9c-2,0.5-4,0.7-6.1,0.6c-2.3,0-4.8-0.2-7.6-0.3
		c-3,0-5.9,0.3-8.8,0.8l-4.7,0.8c-0.7,0.1-1.5,0.2-2.3,0.3c-0.8-0.1-1.5-0.1-2.3-0.3c-3.3-0.6-6.7-1.3-10.1-2
		c-2.8-0.6-5.6-1.1-8.3-1.6c-2.7-0.5-5.4-0.9-8.1-1.1c-2.6-0.1-5.2,0.2-7.6,0.9c-2.2,0.6-4.4,1.4-6.5,2.4c-2,0.9-3.8,1.9-5.4,2.6
		c-1.5,0.6-3.2,1.1-4.8,1.3c-2.4,0.3-4.8,0.4-7.1,0.2c-1.6-0.1-4.1-0.3-7.1-0.7s-6.9-0.7-11.1-1.1l-14.2-1.2
		c-5.4-0.6-10.8-1.6-16.1-2.8c-5.6-1.2-11-2.4-16.3-3.1c-2.6-0.3-5.2-0.2-7.7,0.4c-2.3,0.5-4.5,1.3-6.6,2.3c-2,0.9-3.8,1.9-5.4,2.8
		l-2.3,1.4c-0.7,0.4-1.4,0.7-2.1,1c-2.2,0.8-4.5,1.4-6.9,1.6c-2.4,0.3-4.8,0.4-7.2,0.4l-5.1,0.1c-2,0-4.1,0.2-6.1,0.6
		c-4.7,0.8-9.4,2-13.9,3.6l-1.9,0.6c-0.3,0.1-0.5,0.1-0.8,0.2s-0.6,0.1-0.9,0.1c-1.3,0-2.6-0.1-3.8-0.4c-2.6-0.5-5.4-1.2-8.3-1.8
		c-1.8-0.4-3.6-0.6-5.5-0.5c-1.9,0.1-3.7,0.6-5.3,1.5c-2.9,1.7-5.5,3.8-7.8,6.2l-1.6,1.6c-0.3,0.3-0.5,0.6-0.8,0.8
		c-0.2,0.2-0.5,0.4-0.8,0.6c-1.1,0.7-2.3,1.1-3.6,1.2c-2.5,0.2-4.9,0.2-7.4-0.2c-1.1-0.2-2.3-0.2-3.5-0.1c-1,0.1-2,0.4-3,0.6
		c-1.8,0.6-3.3,1.1-4.5,1.6c-2.4,1-3.7,1.5-3.7,1.7s1.5-0.1,4-0.9c1.3-0.4,2.8-0.8,4.5-1.3c0.9-0.2,1.9-0.4,2.8-0.5
		c1.1,0,2.1,0.1,3.2,0.3c2.6,0.5,5.2,0.6,7.8,0.4c1.5-0.1,3-0.6,4.4-1.4c0.4-0.2,0.7-0.5,1-0.8c0.3-0.3,0.6-0.5,0.9-0.8
		c0.5-0.5,1.1-1.1,1.6-1.6c2.1-2.3,4.6-4.2,7.3-5.8c1.4-0.7,2.9-1.2,4.5-1.2c1.7,0,3.4,0.2,5,0.5c2.8,0.5,5.5,1.3,8.3,1.7
		c1.4,0.3,2.8,0.4,4.3,0.3c0.4,0,0.7-0.1,1.1-0.1s0.8-0.2,1.1-0.3l1.8-0.7c4.4-1.7,9-3,13.6-4c3.6-0.7,7.3-1,11-1.1
		c3.1-0.2,5.6-0.4,7.2-0.6c2.4-0.2,4.8-0.7,7.1-1.4c0.8-0.3,1.6-0.6,2.3-1l2.4-1.3c1.6-0.9,3.5-1.7,5.4-2.5c3.9-1.6,8.6-2.8,13.5-2
		s10.4,2.1,16.1,3.3c2.8,0.6,5.6,1.2,8.3,1.6c2.7,0.5,5.4,0.8,8.1,1l14.3,0.7c4.3,0.2,8,0.3,11.1,0.6s5.5,0.4,7.2,0.5
		c2.4,0.2,4.8,0.2,7.3,0c1.8-0.1,3.5-0.5,5.1-1.1c1.8-0.7,3.6-1.6,5.6-2.4c4.2-2,8.8-2.9,13.5-2.7c2.6,0.3,5.2,0.7,7.8,1.2l8.3,1.7
		c3.5,0.7,6.9,1.4,10.2,2c1.7,0.4,3.5,0.4,5.2-0.1l4.6-0.9c2.8-0.6,5.7-0.9,8.5-1c2.7,0,5.3,0.1,7.6,0c2.2,0,4.3-0.3,6.4-0.9
		c1.8-0.6,3.3-0.9,4.5-1.2C486.8,107.5,488.3,107.2,488.3,107z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M472.8,109.5c-0.1,0.1-1.2-0.7-3.1-2.3s-4.6-3.9-8-6.8s-7.3-6.4-11.7-10.3c-4.2-3.7-8.7-7.8-13.5-12.1l0,0
		l-0.7,0.7c-10.3,9.5-19.8,18.1-26.7,24.2c-3.5,3.1-6.3,5.5-8.3,7.2s-3.1,2.5-3.2,2.4s0.9-1.2,2.7-3s4.5-4.4,7.8-7.6
		c6.6-6.4,15.9-15.1,26.3-24.6l2.1-2l8,7.3l6.8,6.2c4.3,4,8.2,7.6,11.4,10.6s5.8,5.5,7.5,7.3S472.9,109.4,472.8,109.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M455.5,110.5c-11-18.4-19.5-33.6-19-33.9s9.8,14.4,20.8,32.8C457.5,109.7,455.6,110.7,455.5,110.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M338.3,105.8c-0.6-0.2-1.1-0.5-1.5-0.9c-1-0.6-2.4-1.6-4.2-2.9c-3.7-2.6-8.9-6.4-15.4-11.2l-22.9-16.9
		l-27.6-20.5l-6.3,5.3c-21,17.4-40.1,33-54,44.3c-6.9,5.6-12.6,10.1-16.5,13.2c-2,1.5-3.5,2.7-4.6,3.5c-0.5,0.4-1.1,0.8-1.7,1.1
		c-0.1-0.1,0.4-0.6,1.4-1.4s2.4-2.2,4.3-3.8c3.8-3.3,9.3-8,16.1-13.8c13.6-11.5,32.6-27.4,53.6-44.8l7.6-6.3l28.8,21.7l22.6,17.3
		c6.4,4.9,11.5,8.9,15,11.7c1.8,1.4,3.1,2.5,4,3.2S338.3,105.7,338.3,105.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M267.6,51.9c15.4,33.4,27.4,60.6,26.9,60.9s-13.4-26.6-28.8-60C265.5,52.3,267.4,51.5,267.6,51.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M2.3,87.9c0,0.3,0.6,0.6,1.7,0.9c1.5,0.3,3,0.4,4.5,0.3c0.4-0.1,0.9-0.2,1.2-0.4c0.3-0.2,0.7-0.4,0.9-0.7
		c0.4-0.4,0.8-0.9,1.1-1.4c0.3-0.6,0.8-1.2,1.3-1.7c0.6-0.3,1.3-0.4,2-0.4c1-0.1,2.2-0.1,3.5-0.3l0.5-0.1c0.2,0,0.4-0.1,0.7-0.2
		c0.4-0.1,0.7-0.3,1.1-0.6c0.5-0.4,1-0.9,1.3-1.4c0.3-0.6,0.7-1.2,1.2-1.8c0.1-0.1,0.4-0.2,0.6-0.1c0.1,0.1,0.2,0.1,0.3,0.2
		c0.2,0.1,0.3,0.3,0.4,0.4c0.6,0.7,1.3,1.7,2.2,2.7l0.8,0.9c0.3,0.3,0.6,0.6,0.9,0.9c0.5,0.4,1.1,0.8,1.7,1.1c1,0.5,1.7,0.7,1.9,0.4
		s-0.3-0.8-1-1.6l-1.3-1.3c-0.2-0.3-0.4-0.5-0.6-0.8s-0.5-0.6-0.8-1c-0.8-1-1.8-1.8-2.9-2.4c-0.2-0.1-0.5-0.2-0.8-0.3
		c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.3,0-0.4,0c-0.4,0-0.7,0.2-1,0.5c-0.3,0.3-1,0.8-1.8,1.4c-0.4,0.3-0.8,0.6-1.3,0.8
		C19.5,81.9,19.3,82,19,82h-0.3h-0.5c-2,0.2-4,1-5.6,2.3c-0.4,0.2-1.1,0.7-1.9,1.2c-0.4,0.3-0.9,0.6-1.3,0.9
		c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2,0.1-0.4,0.1-0.7,0.1c-1.5,0.2-3,0.2-4.1,0.3S2.3,87.6,2.3,87.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M53.9,679.1c0.1-0.3-0.6-0.8-1.8-1c-1.6-0.3-3.2-0.3-4.7,0.1c-0.7,0.2-1.4,0.4-2,0.7l-0.8,0.4
		c-0.2,0.1-0.4,0.2-0.7,0.3c-0.7,0.2-1.4,0.3-2.1,0.3c-0.7-0.2-1.4-0.5-2-0.8c-0.5-0.3-1.1-0.5-1.7-0.7c-0.7-0.2-1.4-0.3-2.2-0.3
		c-1.3,0-2.5,0.1-3.8,0.4c-0.7,0.2-1.5,0.3-2.2,0.3c-0.7-0.1-1.4-0.4-2-0.8c-0.2-0.1-0.4-0.3-0.5-0.4l-0.7-0.6
		c-0.5-0.4-1-0.8-1.6-1.2c-2.9-1.9-5.5-3.1-5.8-2.7s1.7,2.4,4.6,4.4c0.6,0.4,1.1,0.7,1.7,1l0.8,0.4c0.3,0.1,0.6,0.3,0.9,0.3
		c0.8,0.1,1.6,0.1,2.4,0.1c0.4,0,1.2,0.3,2.3,0.5c1.2,0.2,2.5,0.3,3.7,0.4c0.7,0,1.3,0,1.9,0s1.1,0.2,1.7,0.2
		c1.1,0.1,1.9,0.1,2.3,0.2c0.8,0.2,1.6,0.4,2.4,0.4c0.3,0,0.7,0,1-0.1l0.9-0.2c0.6-0.1,1.3-0.2,1.9-0.4c1.4-0.3,2.7-0.5,4.1-0.7
		C53.1,679.5,53.8,679.4,53.9,679.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M50,679c-0.3,0.1-1.1-1.8-3.1-4.3c-1.1-1.5-2.4-2.7-4-3.6c-1-0.5-2-0.8-3-1c-1.2-0.2-2.3-0.4-3.5-0.5
		c-1.2-0.1-2.4-0.2-3.6-0.2c-0.6,0-1.3-0.1-1.9-0.2c-0.6-0.1-1.1-0.2-1.7-0.4c-0.9-0.2-1.9-0.4-2.8-0.4c-0.2,0-0.1,0-0.2,0
		c-0.1,0.1-0.3,0.2-0.4,0.4c-0.3,0.3-0.5,0.7-0.8,1c-1.8,2.7-2.9,4.5-3.1,4.3s0.3-2.1,1.9-5.1c0.2-0.4,0.5-0.8,0.7-1.2
		c0.3-0.4,0.6-0.8,1.1-1c0.2-0.1,0.4-0.1,0.6-0.1c1.1-0.1,2.3,0,3.4,0.3l1.6,0.3c0.5,0.1,1.1,0.1,1.6,0.1c1.2,0,2.4,0.1,3.7,0.2
		c1.3,0.1,2.5,0.3,3.8,0.6c1.2,0.3,2.4,0.7,3.5,1.3c1.8,1.1,3.2,2.6,4.3,4.4C49.9,676.8,50.3,678.9,50,679z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M26.5,666.8c3.3,2.3,6.4,3.8,6.2,4.3s-3.8,0-7.4-2.6c0.1-0.3,0.3-0.6,0.6-0.9l0.4-0.6
		C26.4,667,26.4,666.9,26.5,666.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M129.9,709.9c0-0.1-0.2-0.3-0.6-0.3c-0.4,0-0.8,0.1-1.3,0.2c-0.5,0.1-1,0.3-1.6,0.3c-0.6,0.1-1.3,0.1-1.9,0.1
		c-2.3,0.1-4.4,0.6-5.1,0.5c-0.2-0.1-0.5-0.1-0.7-0.2c-0.3-0.1-0.6-0.3-1.1-0.5c-1.2-0.4-2.4-0.4-3.6-0.1c-0.6,0.2-1.2,0.4-1.6,0.5
		c-0.2,0.1-0.4,0.1-0.6,0.2l-0.6,0.1c-0.6,0.1-1.3,0.1-1.9,0c-0.6-0.2-1.2-0.6-1.7-0.9c-0.8-0.5-1.8-1-2.8-1.5
		c-1.1-0.7-2.3-1.2-3.5-1.6c-1-0.3-1.7-0.2-1.8,0s0.4,0.7,1.1,1.3c1,0.8,2.1,1.5,3.2,2.2c1,0.5,2.1,0.8,3.3,0.9
		c0.9,0.1,1.7,0.1,2,0.2s1,0.5,1.9,0.8c0.3,0.1,0.5,0.2,0.8,0.2c0.3,0.1,0.7,0.1,1,0c0.6-0.1,1.2-0.2,1.8-0.3
		c0.9-0.3,1.9-0.5,2.8-0.6c0.4,0,0.9,0,1.2,0c0.3,0,0.5,0,0.8,0c1.7,0.6,3.5,1,5.3,1c0.8,0,1.5-0.1,2.3-0.3c0.6-0.2,1.2-0.5,1.7-0.9
		C129.6,710.4,129.8,710.1,129.9,709.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M126.9,711c-0.1,0-0.3-0.4-0.6-1.2s-0.5-1.9-0.9-3.1c-0.3-0.7-0.4-0.9-1-1.2c-0.7-0.2-1.5-0.4-2.3-0.4
		c-1.9-0.1-3.9-0.6-5.9-0.8c-0.5,0-0.9,0-1.4,0c-0.5,0-1,0.1-1.4,0.2c-1,0.1-1.9,0.3-2.9,0.4c-0.2,0-0.5,0-0.8,0.1
		c-0.2,0-0.3,0-0.5,0h-0.1c0.1-0.4,0-0.1,0-0.2l0,0c0,0,0,0.1,0,0.1c0,0.1,0,0,0,0c-0.3,0.1-0.6,0.2-0.9,0.3
		c-0.4,0.2-0.7,0.4-1.1,0.5c-0.2,0.1-0.4,0.2-0.6,0.2l-0.5,0.1c-1.3,0.4-2.3,0.8-3.1,1.1s-1.1,0.4-1.2,0.3c-0.2-0.3,1.2-1.5,3.7-2.8
		l0.5-0.2l0.4-0.2l1-0.6c0.4-0.3,0.9-0.5,1.5-0.6c0.1,0,0.2,0,0.3,0h0.2c0,0.1,0-0.2,0,0.2v-0.1v-0.2h0.2l0.6-0.1l2.8-0.5
		c0.5-0.1,1-0.2,1.6-0.2c0.6-0.1,1.2-0.1,1.8,0c2.3,0.2,4.1,0.7,6,0.9c1,0.1,1.9,0.3,2.8,0.7c0.5,0.2,0.9,0.6,1.2,1.1
		c0.2,0.4,0.4,0.8,0.5,1.3c0.2,1.1,0.3,2.3,0.3,3.4C127.1,710.5,127,711,126.9,711z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M109.2,703c0.4,0.2,0.8,0.4,1.2,0.7l0.9,0.6c0.5,0.3,1,0.6,1.5,0.9c0.9,0.5,1.5,0.8,1.5,1.1s-0.8,0.5-1.9,0.3
		c-0.7-0.1-1.4-0.3-2-0.6c-0.4-0.2-0.7-0.4-1.1-0.6c-0.3-0.2-0.6-0.4-0.9-0.5c0,0,0.2-0.5,0.4-1C108.9,703.6,109,703.3,109.2,703z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M114.8,682.3c0,0.3-1.5,0.6-3.9,0.9l-4.2,0.4l-1.2,0.1c-0.2,0-0.4,0-0.5,0.1c-0.2,0-0.4,0.1-0.6,0.2
		c-0.9,0.2-1.9,0.4-2.8,0.5c-1,0.1-1.9,0.1-2.9,0c-0.9-0.1-1.7-0.3-2.6-0.5c-1.6-0.4-3-0.8-4.1-1.3c-2.3-0.8-3.7-1.5-3.6-1.8
		s1.6-0.1,4,0.4l4.1,0.8c1.6,0.3,3.3,0.5,4.9,0.3c0.8-0.1,1.7-0.2,2.5-0.4c0.2-0.1,0.5-0.1,0.7-0.2c0.2,0,0.5-0.1,0.7-0.1l1.3-0.1
		c1.6,0,3.1,0,4.3,0.1C113.3,681.9,114.8,682.1,114.8,682.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M110.4,682.5c-0.1-0.1,0.1-0.4,0.1-0.9c-0.2-0.8-0.5-1.4-1.1-2c-0.2-0.2-0.2-0.2-0.5-0.3
		c-0.3-0.1-0.5-0.2-0.8-0.3c-0.7-0.2-1.3-0.3-2-0.4c-0.7-0.1-1.5-0.2-2.2-0.2l-2.6-0.1c-1.7-0.1-3.5-0.2-4.9-0.3
		c-1.2,0.1-2.3,0.5-3.2,1.1l-0.7,0.4c-0.2,0.1-0.3,0.2-0.4,0.3c-0.2,0.2-0.3,0.5-0.4,0.8c-0.1,0.5,0,0.9-0.1,1s-0.5-0.2-0.7-0.9
		c-0.1-0.5,0-1,0.2-1.5c0.2-0.3,0.4-0.5,0.6-0.7l0.6-0.5c0.5-0.4,1.1-0.8,1.6-1.1c0.8-0.4,1.6-0.7,2.5-0.7c1.6,0,3.2,0.1,4.9,0.2
		l2.5,0.2c0.9,0,1.7,0.1,2.5,0.3c0.7,0.1,1.5,0.4,2.2,0.6c0.3,0.1,0.7,0.3,1,0.5c0.4,0.2,0.7,0.5,0.9,0.8c0.6,0.9,0.9,1.9,0.7,3
		c-0.1,0.3-0.3,0.6-0.5,0.8C110.6,682.5,110.5,682.5,110.4,682.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M456.5,141.8c0,0.6-9,1-20.2,1s-20.1-0.5-20.1-1s9-1,20.1-1S456.5,141.3,456.5,141.8z"
          />
          <ellipse class="st2" cx="193.8" cy="150.5" rx="32.3" ry="1.1" />
          <ellipse class="st2" cx="77" cy="142.9" rx="25.5" ry="1.1" />
          <ellipse class="st2" cx="249" cy="163.7" rx="46.5" ry="1.1" />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M402.3,185c0.2-0.1,0.5,0.1,1.2,0.5c0.3,0.2,0.7,0.4,1.2,0.6l0.8,0.3l1.4,0.5l0.2,0.6c0,0.2,0.1,0.3,0.2,0.5
		c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.3,0.4,0.6,0.6,0.8c0.1,0,0.2,0.1,0.2,0.1h0.2h0.2c0.3-0.1,0.5-0.2,0.7-0.4c0.1-0.1,0.1-0.2,0.2-0.3
		c0,0,0-0.1,0-0.1l0,0l0,0c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.3-0.3-0.4-0.5c-0.3-0.3-0.7-0.6-1-0.9l-0.5-0.4l-1-0.7v-0.5
		c0-0.2,0-0.4,0-0.4c0-0.1,0-0.2-0.1-0.4c-0.1-0.3-0.2-0.5-0.3-0.8c-0.3-0.5-0.5-0.9-0.8-1.3c-0.5-0.7-0.7-1.2-0.5-1.4
		s0.7,0,1.5,0.5c0.5,0.3,0.9,0.7,1.3,1.1c0.3,0.3,0.5,0.6,0.7,0.9c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0,0.1,0.1,0.2l0,0
		c0.1,0,0.2,0,0.2,0.1c0.2,0.1,0.4,0.3,0.6,0.4c0.5,0.3,0.9,0.6,1.4,1c0.3,0.2,0.5,0.4,0.7,0.7c0.1,0.2,0.3,0.3,0.4,0.5
		c0.1,0.2,0.2,0.5,0.3,0.8c0.1,0.3,0.1,0.6,0.1,0.8c0,0.2,0,0.5-0.1,0.7c-0.1,0.4-0.3,0.8-0.6,1.2c-0.6,0.8-1.5,1.3-2.4,1.4
		c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0-0.5,0-0.7-0.1c-0.5-0.1-1-0.3-1.4-0.6c-0.6-0.5-1-1.1-1.3-1.9c-0.1-0.3-0.2-0.6-0.3-0.9
		c0-0.2,0-0.2,0-0.2v-0.1l-0.1-0.1l0,0l-0.1-0.1l-0.2-0.1c-0.3-0.2-0.5-0.4-0.8-0.6c-0.4-0.3-0.8-0.7-1.1-1.1
		C402.1,185.7,402.1,185.1,402.3,185z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M408.3,190.8c0.5,0,0.8,1.1,1.8,2.1c0.5,0.6,1.2,1,1.9,1.3c0.4,0.1,0.8,0.2,0.8,0.1c0.3-0.1,0.5-0.2,0.8-0.4
		c0.5-0.4,0.7-1,0.6-1.5c-0.2-0.7-0.6-1.3-1.2-1.7c-1.2-1.1-2.1-1.8-1.9-2.3s1.4-0.6,3.3,0.1c0.5,0.2,1,0.5,1.5,0.9
		c0.6,0.5,1.1,1.1,1.5,1.9c0.2,0.4,0.3,0.9,0.3,1.4c0,0.5,0,1-0.1,1.4c-0.3,1-0.8,1.8-1.5,2.5c-0.7,0.7-1.7,1.1-2.7,1.3
		c-0.5,0.1-1.1,0-1.6-0.2c-0.4-0.1-0.8-0.3-1.1-0.5c-1.1-0.7-2-1.7-2.6-2.9c-0.4-0.8-0.4-1.7-0.3-2.5
		C407.8,191,408,190.7,408.3,190.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M412.5,196c-0.3-0.1-0.7,0.6-0.6,1.6c0.1,0.6,0.2,1.2,0.5,1.7c0.1,0.3,0.3,0.6,0.5,0.9l0.3,0.5
		c0,0.1,0,0.1,0,0.1l0,0c0,0.1,0,0.4,0,0.5c0,0.4,0.1,0.8,0.2,1.1c0.2,0.8,0.6,1.6,1.2,2.3c0.1,0.1,0.2,0.2,0.3,0.4l0.2,0.1
		c0,0.1,0.1,0.1,0.1,0.1h0.3c0,0.1,0,0.2,0,0.3c0,0.2,0,0.4,0,0.6c0,0.3,0.1,0.6,0.1,0.9c0.1,0.5,0.2,0.8,0.3,1
		c0,0.4,0.1,0.7,0.2,1.1c0.2,0.5,0.4,1.1,0.8,1.5c0.4,0.6,1,1.1,1.6,1.5c0.4,0.3,0.9,0.4,1.3,0.5c1.3,0.3,2.6-0.3,3.2-1.5
		c0.5-1.2,0.5-2.5,0-3.6c-0.4-0.8-0.9-1.5-1.5-2.2l-0.2-0.4l-0.2-0.4c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1,0.1-0.1,0.1-0.2
		c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.4,0.2-0.8,0.2-1.3c0-1.2-0.2-2.3-0.7-3.4c-0.1-0.2-0.2-0.4-0.3-0.5l-0.1-0.3l0,0
		c0-0.2,0-0.3-0.1-0.5c-0.1-0.3-0.2-0.5-0.3-0.8c-0.2-0.4-0.4-0.8-0.7-1.1c-0.4-0.5-0.9-1-1.4-1.4c-0.9-0.6-1.5-0.8-1.8-0.6
		s0,0.9,0.4,1.7c0.2,0.4,0.5,0.9,0.7,1.5c0.1,0.3,0.2,0.5,0.3,0.8c0,0.1,0.1,0.2,0.1,0.3c0,0.2,0,0.5,0,0.7l0.2,0.5l0.1,0.2l0.2,0.4
		c0.4,1,0.8,1.9,1.1,2.6c0.1,0.3,0.2,0.7,0.3,1c0.1,0.2,0.1,0.3,0.1,0.5c0,0.1,0,0.2,0.1,0.4l0.6,0.6l0.3,0.3
		c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.7,0.5,1.4,0.5,2.1c0,0.5-0.1,1-0.3,1.4c-0.1,0.3-0.3,0.6-0.5,0.8c-0.3,0.1-0.6,0.1-0.9-0.1
		c-0.5-0.2-1-0.4-1.4-0.6c-0.4-0.2-0.7-0.5-1-0.8c-0.2-0.2-0.3-0.5-0.4-0.7c0-0.3,0-0.6,0-0.9c0-0.2,0.1-0.5,0.1-0.8l0.1-0.5
		c0-0.2,0.1-0.6,0.2-0.9c-0.1-0.2-0.2-0.5-0.3-0.7c0-0.1-0.1-0.1-0.1-0.2l-0.1-0.2l-0.1-0.4c-0.2-0.6-0.4-1.1-0.6-1.7
		c-0.1-0.2-0.1-0.4-0.1-0.7c0-0.1,0-0.2,0-0.4v-0.8l-0.9-1.1l-0.5-0.6c-0.3-0.4-0.6-0.8-0.9-1.1C413,196.5,412.8,196,412.5,196z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M417.5,210.9c0.5,0.1,0.6,1.4,0.7,3.3c0,0.2,0.1,0.4,0.1,0.6c0,0.1,0.1,0.3,0.1,0.4c0.2,0.2,0.5,0.4,0.8,0.6
		c0.5,0.2,0.9,0.4,1.4,0.6c0.5,0.1,1,0.2,1.5,0.1c0.7-0.1,1.3-0.5,1.6-1.2c0.2-0.7,0-1.5-0.4-2.1c-0.8-1.5-1.8-2.4-1.5-2.9
		c0.1-0.2,0.5-0.3,1.1-0.1c0.9,0.3,1.7,0.7,2.4,1.4c0.5,0.5,0.9,1,1.3,1.7c0.4,0.8,0.6,1.8,0.4,2.7c-0.4,2.2-2.2,3.9-4.4,4.2
		c-0.3,0-0.6,0-0.9,0c-0.3,0-0.5,0-0.7-0.1c-0.5-0.1-0.9-0.2-1.3-0.3c-0.8-0.3-1.6-0.7-2.3-1.2c-0.8-0.6-1.4-1.3-1.7-2.2
		c-0.1-0.4-0.2-0.9-0.1-1.3c0-0.3,0.1-0.6,0.1-0.9C416.2,211.8,417,210.8,417.5,210.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M419.6,218.1c-0.3-0.1-0.9,0.4-1.2,1.6c-0.2,0.7-0.2,1.4,0,2.1c0.1,0.4,0.2,0.9,0.4,1.3
		c0.1,0.2,0.2,0.4,0.4,0.6l0.2,0.3l0.1,0.1l0,0l0,0v0.5c0,0.3,0.1,0.5,0.1,0.8c0.1,0.4,0.3,0.9,0.5,1.3c0.2,0.5,0.6,0.8,1.1,1
		c0.1,0.1,0.2,0.1,0.3,0.1c0,0.2,0,0.2,0,0.3v0.7c-0.1,0.8-0.2,1.5-0.2,2.3c0,0.3,0.1,0.6,0.1,0.9c0.1,0.4,0.4,0.8,0.7,1.2
		c0.7,0.7,1.6,1.1,2.6,1.1c1,0,1.9-0.4,2.5-1.2c0.5-0.6,0.8-1.3,0.8-2.1c0-0.8-0.1-1.6-0.4-2.4c0-0.2,0-0.5-0.1-0.9
		c0-0.3-0.1-0.5-0.2-0.8c-0.1-0.2-0.1-0.3-0.2-0.5l-0.1-0.3l-0.1-0.1V226c0,0,0-0.1,0-0.1s0.1-0.1,0.1-0.1s0.1,0,0.2-0.2
		c0.1-0.2,0.1-0.4,0.2-0.7c0.1-0.6,0-1.2-0.1-1.8c-0.1-0.4-0.2-0.7-0.4-1.1l-0.2-0.5l-0.1-0.1l0,0c0,0.1-0.1-0.4-0.1-0.7
		c-0.1-0.3-0.2-0.6-0.3-0.8c-0.2-0.4-0.3-0.8-0.6-1.2c-0.4-0.7-0.8-1-1.1-0.9s-0.4,0.6-0.5,1.3c0,0.4,0,0.8,0,1.3v0.7
		c0,0.3,0,0.7-0.1,1l0.2,0.4l0.1,0.2v0.1l0.1,0.4c0.1,0.3,0.2,0.5,0.2,0.8c0.1,0.4,0.2,0.8,0.3,1.1s0.1,0.3,0.1,0.4
		c0,0.1,0,0.3,0.1,0.4c0.1,0.3,0.1,0.6,0.2,0.6c0.1,0.3,0.3,0.6,0.3,0.5l0.4,0.5c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.2,0.3,0.4,0.4,0.6
		c0.1,0.2,0.2,0.5,0.3,0.7c0,0.7-0.2,1.3-0.5,1.9c-0.2,0.3-0.4,0.6-0.8,0.8c-0.3,0.1-0.6,0.2-0.9,0.2c-0.3,0-0.6-0.1-0.9-0.2
		c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.2-0.3-0.4-0.4-0.5c-0.4-0.6-0.6-1.2-0.7-1.9c0,0,0-0.1,0-0.2l0.1-0.6c0-0.2,0-0.3,0-0.5
		c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.3-0.2-0.7-0.2-1.1c0-0.3,0-0.5,0-0.8c0-0.1,0-0.3,0-0.5l0.2-0.8c0,0-0.1-0.3-0.2-0.7l-0.1-0.2
		l-0.2-0.5l-0.2-0.2l-0.1-0.1l-0.1-0.2l-0.2-0.3c-0.1-0.2-0.3-0.5-0.4-0.7c-0.2-0.5-0.4-0.9-0.5-1.4
		C420.1,218.9,420,218.2,419.6,218.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M422,232.1c0.2,0.1,0.3,0.5,0.2,1.1c0,0.3,0,0.6-0.1,1c0,0.4,0,0.8,0.1,1.1c0,0.2,0.1,0.4,0.1,0.6
		c0.1,0.2,0.2,0.3,0.3,0.5c0.2,0.4,0.5,0.7,0.8,0.9c0.8,0.6,1.8,0.9,2.9,0.9c0.9-0.1,1.7-0.5,2.1-1.3c0.3-0.7,0.4-1.6,0.1-2.3
		c-0.3-0.7-0.6-1.4-0.9-2c-0.3-0.5-0.4-0.9-0.2-1.1s0.6-0.2,1.2,0.1c0.9,0.4,1.6,1.1,2.2,1.8c1,1.4,1.3,3.1,0.8,4.8
		c-0.4,1-1,1.9-1.9,2.5c-0.9,0.6-1.9,1-3,1.2c-0.6,0.1-1.1,0-1.7-0.1c-1.4-0.3-2.7-0.9-3.8-1.9c-1.3-1.2-1.8-2.9-1.6-4.6
		c0.2-1,0.6-1.9,1.3-2.6C421.4,232.2,421.7,232,422,232.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M423.7,239.7c-0.3-0.2-1,0-1.7,1c-0.4,0.6-0.7,1.3-0.8,2c-0.1,0.5-0.1,0.9,0,1.4c0,0.3,0.1,0.5,0.2,0.7
		c0,0.1,0.1,0.3,0.1,0.4l0.1,0.2c0,0.1,0,0,0,0l0,0l0,0l0,0v0.1l-0.1,0.3l-0.1,0.5c-0.1,0.3-0.1,0.7-0.1,1c0,0.6,0.2,1.2,0.4,1.7
		c0.2,0.4,0.4,0.8,0.7,1.1c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.1c0.1,0.1,0.2,0.2,0.3,0.2c0,0.2,0,0.3,0,0.3l-0.2,0.7
		c-0.1,0.3-0.3,0.6-0.5,0.9c-0.1,0.2-0.3,0.4-0.4,0.7c-0.2,0.4-0.3,0.8-0.3,1.2c0,0.5,0.1,0.9,0.3,1.4c0.2,0.4,0.4,0.8,0.6,1.1
		c0.7,0.9,1.7,1.5,2.8,1.6c0.6,0.1,1.3,0,1.9-0.3c0.5-0.2,0.9-0.5,1.3-0.9c0.6-0.6,1-1.3,1.2-2.2c0.2-1,0.2-1.9,0-2.9v-0.6
		c0-0.1,0-0.3,0-0.6s0-0.2,0-0.4v-0.2v-0.1c0,0,0-0.1,0-0.2c0,0,0.1-0.1,0.2-0.2s0.1,0,0.2-0.1c0.1-0.1,0.2-0.2,0.2-0.3
		c0.2-0.3,0.4-0.6,0.5-1c0.3-0.9,0.4-1.9,0.2-2.9c-0.1-0.5-0.3-1.1-0.5-1.6l-0.1-0.4c0,0,0-0.1-0.1-0.1s0,0-0.1,0l0,0l0,0l0,0v-0.1
		c0-0.1,0-0.3,0-0.4c0-0.6-0.1-1.3-0.3-1.9c-0.5-1.4-1.3-2-1.7-1.8s-0.6,1-0.8,2.1c0,0.3-0.1,0.5-0.2,0.8c0,0.1-0.1,0.3-0.1,0.4
		c0,0.1,0,0.1-0.1,0.2l0,0c0,0,0,0,0,0.1v0.1c0,0.1-0.1,0.2-0.1,0.3l-0.1,0.6l0.1,0.6c0,0.2,0.1,0.3,0.2,0.5l0.1,0.3
		c0.1,0.3,0.2,0.7,0.2,1.1c0.2,1,0.3,2,0.4,3c0,0.4,0,0.8,0,0.6c0.1,0.4,0.1,0.7,0.2,0.6l0.2,0.4l0.1,0.3l0.2,0.4
		c0.1,0.2,0.1,0.3,0.1,0.5c-0.1,0.7-0.5,1.3-1,1.8c-0.3,0.3-0.7,0.5-1.1,0.7c-0.2,0.1-0.5,0.2-0.8,0.1c-0.3,0-0.6-0.2-0.9-0.3
		c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.3-0.1-0.4-0.2s-0.2-0.6-0.2-1c0-0.3,0-0.6,0.1-0.9c0-0.1,0.1-0.1,0.4-0.9
		c0.1-0.2,0.1-0.4,0.2-0.6c0-0.2,0-0.5-0.1-0.7c0-0.1,0-0.2,0-0.4v-0.4c0-0.3,0-0.5,0.1-0.9c0-0.3,0.1-0.6,0.2-0.9
		c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.2v-0.1l0.1-0.2l0.2-0.3l-0.1-1.1L425,244l-0.2-0.3l-0.1-0.1l-0.1-0.3
		c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.4-0.1-0.8-0.1-1.3C424.1,240.6,424.1,240,423.7,239.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M423.2,256c0,0.2-0.3,0.5-0.6,0.9c-0.4,0.6-0.7,1.2-0.8,1.9c-0.1,0.5-0.1,1-0.1,1.5c0,0.4,0.1,0.7,0.4,1
		c0.3,0.3,0.7,0.5,1.1,0.6c0.6,0.2,1.3,0.3,1.9,0.3c1.2,0,2.4-0.3,3.4-0.9c0.3-0.2,0.5-0.5,0.5-0.8c0.1-0.4,0.1-0.8,0-1.2
		c-0.2-0.7-0.5-1.4-0.9-2c-0.3-0.5-0.5-0.9-0.4-1.1s0.6-0.3,1.3,0c1,0.4,1.9,1.2,2.4,2.1c0.4,0.7,0.6,1.6,0.6,2.4
		c0,1.1-0.4,2.2-1.2,3c-0.7,0.8-1.7,1.3-2.7,1.7c-1,0.3-2,0.5-3,0.5c-1,0-2.1-0.2-3.1-0.5c-1.1-0.4-2-1.1-2.7-2
		c-0.6-0.9-0.8-2-0.7-3.1c0.1-0.8,0.3-1.5,0.7-2.2c0.5-1.1,1.4-1.9,2.5-2.3C422.7,255.5,423.2,255.7,423.2,256z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M422,263.4c-0.3-0.2-0.9,0.1-1.4,1c-0.3,0.5-0.5,1.1-0.5,1.7c0,0.4,0,0.7,0,1.1c0,0.2,0,0.4,0.1,0.6
		c0,0.1,0,0.2,0.1,0.3s0,0,0,0.1l0,0l0,0l0,0l-0.2,0.3c-0.1,0.2-0.2,0.4-0.3,0.7c-0.8,2.8,0.9,4.9,1.2,4.8c0.1,0.1,0.2,0.2,0.3,0.3
		c0,0.1-0.1,0.2-0.1,0.3l-0.1,0.4l-0.1,0.3l-0.1,0.3c-0.5,0.8-0.8,1.8-0.9,2.7c0.2,2,1.7,3.7,3.7,4c1.1,0.2,2.3-0.1,3.2-0.8
		c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.2,0.3-0.4,0.4-0.6c0.2-0.4,0.3-0.8,0.4-1.2c0-0.9-0.1-1.9-0.4-2.8c0-0.2,0-0.5,0-0.7
		c0-0.2,0-0.4,0-0.7v-0.2c0,0,0-0.1,0-0.1v-0.3l0.4-0.1c0,0,0.2-0.2,0.3-0.3c0.1-0.2,0.3-0.3,0.4-0.5c0.6-1,0.8-2.2,0.6-3.3
		c-0.1-0.4-0.2-0.8-0.3-1.2c-0.1-0.2-0.1-0.4-0.2-0.6s-0.1-0.2-0.1-0.2l0,0H429c0,0,0,0,0-0.1c0,0,0,0,0-0.1l0.1-0.3
		c0-0.2,0.1-0.3,0.1-0.5c0-0.3,0-0.6,0-0.9c-0.1-0.5-0.2-0.9-0.4-1.3c-0.4-0.7-0.8-0.9-1.1-0.8s-0.5,0.5-0.7,1.1
		c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.2-0.1,0.3-0.2,0.4c0,0.1-0.1,0.1-0.1,0.2l-0.1,0.1l-0.2,0.3c0,0.3-0.1,0.6-0.1,0.9l-0.1,1
		c0.1,0.1,0.1,0.3,0.2,0.4s0,0.1,0.1,0.2l0.1,0.4c0.1,0.2,0.1,0.5,0.1,0.7c0.1,0.6,0.1,1.2,0.1,1.8c0,0.1,0,0.3,0,0.4
		c0,0.2,0,0.2-0.1,0.5c-0.1,0.3-0.2,0.5-0.2,0.8l0.7,1.8c0.1,0.2,0.1,0.4,0.2,0.6c0,0.7-0.3,1.4-0.8,1.9c-0.6,0.6-1.1,0.5-1.8,0.4
		c-0.4-0.1-0.8-0.2-1.1-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.4-0.6-0.5-1.3-0.4-1.9c0-0.1,0.1-0.1,0.1-0.3l0.1-0.2l0.3-0.5
		c0.1-0.2,0.2-0.4,0.3-0.6c0-0.1,0-0.1,0-0.7c0-0.5,0.1-1,0.1-1.6c0.1-0.6,0.2-1.2,0.4-1.8c0-0.1,0.1-0.2,0.1-0.3l0-0.1
		c0.1-0.1,0.2-0.3,0.3-0.4v-1v-1l-0.4-0.8c-0.1-0.2-0.2-0.4-0.2-0.6c-0.1-0.4-0.2-0.8-0.3-1.1C422.5,264.1,422.3,263.6,422,263.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M421.5,279.9c0.3,0.5-0.8,1.5-1,3.5c-0.1,0.5-0.1,1.1,0,1.6c0,0.4,0.2,0.8,0.5,1.2c0.4,0.3,0.8,0.6,1.3,0.7
		c0.4,0.1,0.7,0.2,1.1,0.2l0.6,0.1c0.2,0,0.3,0,0.5,0c0.6,0,1.3-0.1,1.9-0.3c0.5-0.2,0.8-0.5,1.1-0.9c0.3-0.4,0.4-0.9,0.4-1.4
		c0-0.5,0-0.9-0.2-1.4c-0.3-0.8-0.7-1.6-1.2-2.3c-0.3-0.6-0.5-1-0.4-1.2s0.6-0.2,1.3,0.1c1,0.5,1.9,1.2,2.5,2.2
		c0.5,0.7,0.8,1.6,0.9,2.4c0.3,2.3-0.9,4.6-3,5.6c-1.1,0.5-2.3,0.8-3.5,0.8c-0.3,0-0.6,0-0.9-0.1c-0.3,0-0.5-0.1-0.8-0.1
		c-0.5-0.1-1-0.2-1.5-0.4c-1.2-0.3-2.2-1-2.9-2c-0.6-0.9-0.9-2-0.9-3.1c0.1-0.8,0.2-1.6,0.5-2.3c0.4-1.1,1.1-2.1,2.1-2.7
		C421,279.7,421.4,279.7,421.5,279.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M421,288.2c-0.3-0.2-1,0.1-1.5,1.2c-0.2,0.6-0.3,1.3-0.2,2c0,0.4,0.1,0.8,0.2,1.1c0,0.2,0,0.1,0,0.1l0,0l0,0
		c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0.3-0.2,0.5-0.3,0.8c-0.3,1.1-0.1,2.2,0.4,3.2c0.2,0.3,0.4,0.6,0.6,0.9l0.1,0.1h0.1h0.2
		c0.1,0.1,0.2,0.2,0.3,0.2c0,0.1,0,0.2,0,0.3l-0.1,0.5c0,0.2-0.1,0.3-0.1,0.4c0,0.2-0.1,0.3-0.1,0.4s-0.1,0.4-0.3,0.8
		c-0.1,0.2-0.1,0.5-0.2,0.7c-0.1,0.4-0.1,0.8,0,1.2c0,0.1,0,0.2,0,0.4c0,0.2,0,0.3,0.1,0.5c0.2,0.5,0.6,0.9,1,1.2c0.9,0.7,2,1,3.1,1
		c1,0,1.9-0.3,2.8-0.8c0.7-0.5,1.3-1.2,1.6-2c0.3-0.9,0.3-2,0-2.9c0-0.2,0-0.4,0-0.8c0-0.2,0-0.5-0.1-0.7c0-0.3-0.2-0.7-0.2-0.8
		s0.1-0.2,0.1-0.3c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.3-0.1c0.2-0.2,0.3-0.5,0.5-0.8c0.3-0.8,0.4-1.6,0.2-2.5
		c-0.1-0.6-0.4-1.2-0.6-1.8l-0.2-0.3l-0.1-0.1h-0.1c0,0-0.1,0-0.1-0.1c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.3,0.1-0.4
		c0.1-0.5,0.1-0.9,0-1.4c-0.1-0.8-0.5-1.2-0.8-1.2s-0.7,0.3-1.1,0.8l-0.6,0.9l-0.2,0.2c-0.1,0.1-0.2,0.2-0.2,0.3
		c-0.2,0.3-0.6,0.8-0.9,1.2l0.6,1.1c0.1,0.2,0.2,0.4,0.3,0.5s0.1,0.2,0.1,0.3c0.1,0.3,0.2,0.7,0.3,1c0.1,0.4,0.1,0.7,0.1,0.9
		s0,0.2,0,0.3c-0.1,0.1-0.1,0.3-0.1,0.4l-0.1,0.8c0,0.2,0,0.3,0,0.2c0.1,0.5,0.2,1.1,0.3,0.9c0.3,0.5,0.4,0.6,0.5,0.8
		s0.2,0.4,0.3,0.5c0.1,0.2,0.2,0.4,0.2,0.6c0,0.3-0.1,0.5-0.2,0.8c-0.2,0.3-0.4,0.6-0.7,0.8c-0.7,0.4-1.5,0.5-2.3,0.4
		c-0.6,0-1.3-0.1-1.7-0.1c-0.2,0.1-0.5,0.1-0.7,0c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.2-0.1-0.4-0.1-0.6c0-0.2,0-0.4,0-0.6
		c0-0.1,0.1-0.2,0.1-0.3l0.1-0.3l0.1-0.2l0.2-0.4c0.1-0.2,0.2-0.5,0.3-0.7c0-0.1,0-0.1,0-0.8c0-0.1,0-0.2,0-0.3v-0.2V296
		c0-0.5,0.1-0.9,0.2-1.4c0-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0.1-0.1,0.2-0.3,0.3-0.4v-1.1v-0.8c0-0.1,0-0.3-0.1-0.4l-0.2-0.3
		c-0.1-0.2-0.2-0.4-0.4-0.6c-0.2-0.3-0.3-0.7-0.5-1C421.5,288.9,421.4,288.4,421,288.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M421.4,303.3c0.1,0.2-0.2,0.6-0.5,1.2c-0.3,0.9-0.5,1.8-0.6,2.7c-0.1,0.6-0.1,1.2-0.1,1.9
		c0.1,0.5,0.3,0.9,0.6,1.3c1.1,0.9,2.5,1.3,3.9,1.1c1.4-0.1,2.8-0.8,3.7-1.9c0.7-0.8,0.9-2,0.4-2.9c-0.4-0.9-0.8-1.8-1.4-2.6
		c-0.4-0.6-0.5-1.1-0.4-1.3s0.6-0.1,1.4,0.3c1,0.6,1.9,1.4,2.6,2.4c0.6,0.8,0.9,1.7,1,2.7c0.1,1.2-0.2,2.3-0.9,3.3
		c-1.4,2.1-3.6,3.4-6.1,3.6c-1.2,0.1-2.5,0-3.7-0.4c-1.2-0.4-2.3-1.1-3.1-2.1c-0.8-1-1.1-2.2-1.1-3.4c0-0.3,0-0.5,0.1-0.8l0.1-0.4
		l0.1-0.3c0.1-0.4,0.2-0.7,0.4-1.1c0.4-1.2,1.1-2.3,2-3C420.8,303,421.3,303.1,421.4,303.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M304.2,232.1c-0.1,0-0.4,0.2-0.5,0.7c-0.1,0.3,0,0.7,0.1,1c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.3,0.5,0.3,0.5
		c0,0.1,0,0.1,0.1,0.2c0,0.1-0.1,0.1-0.1,0.2l0,0.1l-0.1,0.3c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.4-0.1,0.9-0.1,1.3c0,0.5,0,0.9,0,1.4
		c0,0.2,0.1,0.5,0.1,0.7s0,0.1,0,0.1l0,0l0,0l0,0l-0.1,0.2c-0.2,0.3-0.3,0.6-0.4,0.9c-0.2,0.6-0.3,1.3-0.3,2c0,0.8,0.2,1.6,0.5,2.4
		c0.1,0.1,0.1,0.3,0.2,0.4l0.1,0.2l0,0h0.1c0,0,0,0.1,0,0.1l-0.1,0.5c-0.1,0.3-0.1,0.7-0.1,1.1c0,0.6,0,1.1,0.2,1.7
		c0.1,0.4,0.3,0.7,0.5,1.1c0.1,0.1,0.1,0.2,0.2,0.3l0.1,0.1l0.1,0c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.2s0,0.3-0.1,0.5
		c0,0.2,0,0.3,0,0.5c0,0.2,0,0.5,0.1,0.7c0,0.2,0.1,0.5,0.1,0.7c0,0.1,0.1,0.3,0.2,0.7c0.2,0.5,0.4,1,0.7,1.4c0,0,0,0.1,0.1,0.1
		s0,0,0,0.2c-0.1,0.4-0.2,0.7-0.2,1.1c-0.1,0.7,0,1.4,0.1,2.1c0.1,0.5,0.3,1.1,0.7,1.5c0.5,0.6,1.3,1,2.1,0.9c0.4,0,0.8-0.2,1.2-0.4
		c0.3-0.2,0.6-0.4,0.8-0.7c0.4-0.4,0.7-1,1-1.5c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.3,0.2-0.6,0.2-0.9c0.1-1-0.2-2-0.8-2.8l-0.3-0.3
		c0,0,0,0-0.1,0s-0.1-0.1-0.1-0.1s0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3c0-0.2,0-0.3,0-0.5c0-0.3,0-0.6-0.1-0.9
		c-0.1-0.4-0.2-0.8-0.4-1.2c-0.1-0.3-0.3-0.7-0.6-0.9c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.3,0.1-0.4c0.1-0.2,0.1-0.4,0.1-0.6
		c0-0.2,0.1-0.4,0.1-0.7c0-0.5,0-0.9,0-1.4c-0.1-0.4-0.2-0.8-0.3-1.2c-0.1-0.2-0.2-0.5-0.3-0.8l-0.2-0.5c0.1,0,0.2-0.1,0.3-0.2h0
		l0.1-0.1c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.2-0.5,0.3-0.7c0.2-0.6,0.2-1.2,0.2-1.9c0-0.3,0-0.7-0.1-1l-0.1-0.5c0-0.1,0-0.1,0-0.1
		c0,0,0.1-0.1,0.1-0.2c0,0,0.1-0.3,0.2-0.5c0.1-0.2,0.1-0.4,0.2-0.7c0.2-1,0.2-2,0-3c-0.1-0.5-0.2-0.9-0.4-1.3
		c-0.1-0.2-0.2-0.4-0.3-0.6s-0.1-0.3-0.1-0.3s0,0,0,0s0-0.3,0-0.4s0-0.5-0.1-0.8c-0.1-0.5-0.2-1-0.5-1.5c-0.3-0.7-0.8-1.4-1.4-2
		c-1-0.9-1.8-1.1-1.9-0.9s0.2,0.8,0.7,1.8c0.3,0.5,0.5,1.1,0.6,1.7c0.1,0.3,0.1,0.7,0.1,1c0,0.2,0,0.4,0,0.6s0,0.2,0,0.3l-0.1,0.7
		c0.1,0.2,0.2,0.5,0.3,0.7c0,0.2,0.1,0.3,0.1,0.5c0.1,0.3,0.2,0.7,0.2,1c0.1,0.7,0.1,1.3,0,2c-0.1,0.3-0.1,0.6-0.2,0.9
		c-0.2,0.5-0.3,0.8-0.4,0.9c0.1,0.3,0.2,0.6,0.3,0.9l0.1,0.4c0.1,0.3,0.1,0.5,0.2,0.8c0.1,0.4,0.1,0.9,0.1,1.3c0,0.2,0,0.3,0,0.5
		c0,0.1,0,0.1,0,0.2v0.1c0,0.1,0,0.1-0.1,0.2c-0.1,0.3-0.3,0.5-0.3,0.8c0.2,0.2,0.3,0.4,0.4,0.6l0.2,0.3l0.1,0.2
		c0.1,0.2,0.3,0.4,0.4,0.6c0.2,0.3,0.3,0.7,0.4,1c0.1,0.4,0.2,0.8,0.2,1.2c0,0.2-0.1,0.3-0.1,0.5c0,0.2-0.1,0.3-0.2,0.5
		c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0.2-0.2,0.3-0.4,0.5c0.2,0.4,0.3,0.9,0.4,1.4c0.1,0.3,0.1,0.7,0.1,1c0,0.2,0,0.4,0,0.6
		c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.4,0,0.7c0,0.2,0.1,0.5,0.1,0.7c0,0.1,0.1,0.2,0.2,0.3
		l0.1,0.2c0.1,0.2,0.1,0.4,0.2,0.6c0,0.2,0,0.4,0,0.7c0,0.1-0.1,0.2-0.1,0.4c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.3-0.3,0.6-0.5,0.9
		c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0.1c0,0-0.1,0-0.1,0c-0.2-0.1-0.3-0.3-0.4-0.5c-0.2-0.5-0.4-1-0.5-1.6
		c-0.1-0.4-0.2-0.8-0.3-1.2c0-0.3-0.1-0.5-0.1-0.4c-0.1-0.2-0.3-0.4-0.2-0.3c-0.3-0.4-0.6-0.8-0.8-1.2c-0.1-0.2-0.2-0.4-0.2-0.5
		c0-0.2-0.1-0.4-0.1-0.6c0-0.2,0-0.4,0-0.5c0-0.1,0-0.3,0-0.4c0-0.2,0.1-0.4,0.1-0.5c0-0.1,0-0.3,0-0.4c0,0,0-0.1-0.1-0.4l0-0.2V250
		l-0.1-0.3c0-0.3-0.1-0.6-0.1-0.8c0-0.4,0-0.8,0.1-1.1c0.1-0.2,0.1-0.5,0.2-0.7l0.1-0.2c0-0.1,0.1-0.2,0.1-0.3l0.4-0.9
		c-0.4-1-0.7-2-0.8-3.1c0-0.4,0-0.7,0.1-1.1c0-0.2,0.1-0.3,0.2-0.5c0,0,0-0.1,0.1-0.1l0.2-0.3l0.4-0.6l-0.2-0.6l-0.1-0.5
		c0-0.2-0.1-0.4-0.1-0.5c-0.1-0.4-0.1-0.7-0.2-1c0-0.3,0-0.7,0-1c0-0.1,0-0.3,0-0.4c0-0.1,0-0.1,0-0.2l0.1-0.4
		c-0.1-0.4-0.1-0.6-0.2-0.8s-0.2-0.4-0.4-0.7c-0.2-0.1-0.3-0.3-0.5-0.4c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.2-0.3-0.4-0.4-0.5
		C304.5,232.4,304.4,232.1,304.2,232.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M307.8,260.5c0.5,0.1,0.5,1.4,1.3,2c0.4,0.4,1,0.7,1.6,0.7c0.2,0,0.3,0,0.3,0s0.2-0.1,0.4-0.1
		c0.4-0.2,0.8-0.4,1.1-0.6c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.2,0.2-0.4,0.1-0.6c-0.1-0.7-0.4-1.4-0.8-2c-0.8-1.2-1.5-2-1.3-2.5
		s1.5-0.5,3.1,0.7c1,0.7,1.8,1.8,2.2,3c0.3,0.9,0.2,1.9-0.1,2.8c-0.4,0.9-0.9,1.6-1.7,2.1c-0.6,0.5-1.4,0.9-2.2,1.1
		c-0.4,0.1-0.9,0.2-1.3,0.2c-0.5,0-0.9-0.1-1.4-0.2c-1.3-0.4-2.4-1.4-2.9-2.8c-0.3-0.8-0.3-1.8,0.1-2.6
		C307.2,260.6,307.5,260.4,307.8,260.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M310.9,265c-0.1,0-0.3,0-0.5,0.3c-0.2,0.4-0.3,0.8-0.2,1.2c0.1,0.6,0.4,1.2,0.9,1.6c0.1,0.1,0.3,0.2,0.4,0.4
		l0.2,0.2l0,0c0,0,0,0.1,0,0.1h-0.1l0,0l0,0c0,0,0-0.1,0,0l-0.1,0.2c-0.2,0.6-0.2,1.3-0.1,1.9c0.1,0.5,0.2,0.9,0.5,1.4
		c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0,0,0.1,0h0.1c0,0,0,0.1,0.1,0.1s0,0,0,0c0,0.1,0,0.1,0,0.2c0,0.2-0.1,0.3-0.1,0.5
		c0,0.3,0,0.6,0,0.9c0,0.5,0.1,0.9,0.3,1.4c0.2,0.5,0.4,0.9,0.7,1.3l0,0c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
		c-0.2,0.3-0.3,0.5-0.4,0.8c-0.2,0.5-0.3,1.1-0.2,1.6c0,0.9,0.5,1.7,1.3,2.3c0.9,0.5,2,0.7,3,0.3c0.9-0.3,1.7-0.9,2.1-1.8
		c0.3-0.7,0.3-1.4,0.2-2.2c-0.1-0.5-0.3-0.9-0.5-1.4c0-0.1-0.1-0.2-0.1-0.2l-0.1-0.1l-0.2-0.2c0-0.1,0.1-0.2,0.1-0.3l0.1-0.3
		c0.1-0.2,0.1-0.3,0.1-0.4c0.1-0.4,0.2-0.8,0.3-1.2c0-0.4,0-0.7,0-1.1c0-0.2-0.1-0.5-0.1-0.7c0-0.1-0.1-0.2-0.1-0.4
		s-0.1-0.3-0.1-0.3s0,0,0-0.1s0,0,0.1-0.1s0.3-0.5,0.3-1.1c0-0.7-0.1-1.4-0.3-2.1c-0.1-0.3-0.2-0.7-0.4-1c-0.1-0.3-0.4-0.7-0.3-0.6
		v-0.1v-0.2c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.4-0.3-0.8-0.6-1.1c-0.4-0.5-0.9-0.9-1.4-1.3c-0.9-0.5-1.5-0.6-1.7-0.3s0.1,0.8,0.5,1.5
		c0.2,0.4,0.5,0.8,0.6,1.2c0.1,0.2,0.1,0.4,0.2,0.7c0,0.1,0,0.2,0,0.3v0.1v0.2v0.4c0.2,0.3,0.3,0.7,0.5,1c0.1,0.3,0.2,0.5,0.3,0.8
		c0.2,0.5,0.3,1,0.4,1.5c0.1,0.5,0.2,0.8,0.2,1s0.1,0.5,0.1,0.4c0.1,0.2,0.1,0.5,0.1,0.4l0.2,0.3l0.1,0.3c0.1,0.2,0.2,0.3,0.2,0.5
		c0.1,0.3,0.2,0.5,0.2,0.8c0.1,0.3,0.1,0.6,0,0.9c0,0.1-0.1,0.2-0.1,0.3l-0.2,0.3c-0.1,0.1-0.2,0.3-0.4,0.6v0.4v0.3
		c0,0.1,0,0.2,0,0.2c0,0.4,0,0.7-0.1,1.1c-0.1,0.3-0.2,0.7-0.4,1c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.6,0.2-1,0.2
		c-0.3-0.1-0.5-0.3-0.6-0.6c-0.2-0.3-0.3-0.7-0.3-1.1c0-0.3,0-0.6,0-0.9c0-0.3,0-0.5,0-0.4c-0.1-0.1-0.1-0.2-0.2-0.3v-0.1
		c-0.2-0.4-0.3-0.8-0.3-1.2c0-0.3,0.1-0.7,0.1-1c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.1,0.1-0.2l0.2-0.3
		c0-0.2,0-0.5,0-0.7c0-0.3,0-0.5-0.1-0.8c0,0-0.1-0.2-0.1-0.3l-0.1-0.3c-0.1-0.2-0.1-0.4-0.2-0.6c0-0.3,0-0.5,0-0.8v-0.1
		c0-0.1,0-0.1,0-0.1c0-0.1,0-0.1,0.1-0.2l0.3-0.6l0.5-1.1c-0.6-0.2-1.3-0.4-1.8-0.6c-0.1,0-0.3-0.1-0.4-0.2l-0.2-0.1l-0.3-0.1
		c-0.3-0.1-0.5-0.3-0.8-0.5C311.3,265.6,311.2,265.1,310.9,265z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M313.6,280.8c0.4,0.3-0.1,1.5-0.3,3.1c0,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.2,0.1,0.3c0.1,0.2,0.3,0.4,0.5,0.5
		c0.4,0.3,0.8,0.5,1.2,0.6c0.5,0.2,1,0.2,1.5,0.2c0.4,0,0.8-0.1,1.2-0.2c0.2-0.1,0.4-0.3,0.5-0.5c0.3-0.8,0.4-1.6,0.3-2.4
		c-0.1-1.6-0.7-2.5-0.5-2.9c0.1-0.2,0.6-0.3,1.2,0.1c0.8,0.5,1.4,1.2,1.8,2.1c0.3,0.6,0.4,1.2,0.5,1.8c0.1,0.8,0.1,1.6-0.1,2.4
		c-0.3,1.1-1,1.9-2,2.5c-0.9,0.5-1.9,0.7-2.9,0.7c-0.9,0-1.8-0.2-2.7-0.5c-0.8-0.3-1.6-0.8-2.2-1.5c-0.7-0.7-1.1-1.6-1.2-2.5
		c0-0.5,0-0.9,0.1-1.4c0.1-0.3,0.2-0.6,0.4-0.8C312,281,313.3,280.4,313.6,280.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M313.5,287.5c-0.2-0.2-0.8-0.1-1.4,0.6c-0.4,0.5-0.6,1-0.7,1.6c0,0.4,0,0.7,0,1.1c0,0.2,0,0.4,0.1,0.5
		c0,0.1,0,0.2,0,0.2l0,0h-0.1h0l-0.2,0.2c-0.1,0.2-0.3,0.4-0.4,0.6c-0.3,0.6-0.3,1.3-0.2,2c0,0.2,0.1,0.4,0.2,0.6
		c0,0.1,0.1,0.1,0.1,0.1h0.1c0,0,0.1,0.1,0.2,0.1c0,0,0,0.1,0,0.2c-0.1,0.2-0.2,0.4-0.2,0.7c-0.1,0.2-0.1,0.4-0.1,0.7
		c0,0.3,0,0.7,0.1,1c0.1,0.5,0.3,0.8,0.3,1l0.1,0.3c0,0.1,0,0.1,0.1,0.2c0,0,0,0.1-0.1,0.2l-0.2,0.2l-0.3,0.4
		c-0.2,0.4-0.4,0.9-0.5,1.4c-0.1,0.8,0.1,1.6,0.7,2.2c0.7,0.6,1.6,1,2.5,1c0.3,0,0.7-0.1,1-0.1c0.4-0.1,0.7-0.2,1-0.4
		c0.6-0.4,1.1-1,1.2-1.6c0.1-0.4,0.1-0.9,0-1.4c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.3
		c0.1-0.1,0.1-0.2,0.1-0.2c0.2-0.2,0.3-0.5,0.4-0.8c0.1-0.2,0.2-0.5,0.3-0.7c0.1-0.2,0.1-0.5,0.1-0.7c0-0.1,0-0.3,0-0.5V297
		c0.1,0,0-0.2,0.3-0.2c0.1,0,0.1,0.1,0.3-0.1c0.2-0.2,0.5-0.4,0.6-0.6c0.5-0.6,0.8-1.4,1-2.2c0-0.3,0-0.5,0-0.8c0-0.1,0-0.3,0-0.4
		v-0.2l0,0l0,0c0.2-0.4,0.3-0.7,0.4-1.1c0.1-0.3,0.1-0.7,0.1-1c0-0.5-0.1-1.1-0.3-1.6c-0.3-0.8-0.7-1.3-1-1.2s-0.5,0.6-0.6,1.3
		c-0.1,0.4-0.2,0.8-0.3,1.2c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.2,0.4-0.4,0.6c-0.1,0.4-0.2,0.7-0.3,1.1v0.3v0.2c0,0.1,0,0.3,0,0.5
		c-0.1,0.4-0.2,0.9-0.3,1.3c-0.1,0.2-0.1,0.3-0.2,0.5s-0.2,0.4-0.2,0.5l-0.2,0.5c0,0.1,0,0.3,0,0.4c0,0.3,0,0.4,0,0.3l0.1,0.6
		c0,0.1,0,0.3,0.1,0.4c0,0.2,0.1,0.4,0.1,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.4-0.4,0.6c0,0-0.1,0.1-0.3,0.4
		c0,0.1,0,0.1-0.1,0.5l-0.1,0.3l-0.1,0.3c-0.1,0.2-0.2,0.4-0.4,0.6c-0.1,0.1-0.3,0.2-0.5,0.2H314c-0.2,0-0.3,0-0.5,0
		c-0.2,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.3-0.4-0.5c-0.1-0.3-0.2-0.6-0.2-1V300v-0.4c0-0.3,0-0.5,0-0.4l-0.1-0.4l-0.1-0.3
		c0-0.3,0-0.6,0-0.9c0-0.2,0.1-0.5,0.2-0.7c0-0.1,0.1-0.2,0.2-0.4c0.1-0.2,0.2-0.3,0.4-0.4c0.1-0.2,0.2-0.4,0.3-0.6
		c0-0.1,0-0.1,0.1-0.7c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2v-0.3c0-0.2,0.1-0.5,0.2-0.7c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0.1-0.1
		l0.3-0.3l0.2-0.9l0.2-0.9c-0.1-0.1-0.1-0.3-0.2-0.4s-0.1-0.1-0.1-0.2s-0.1-0.2-0.1-0.3c-0.1-0.2-0.1-0.4-0.2-0.6
		c-0.1-0.3-0.1-0.7,0-1C313.5,288.2,313.7,287.7,313.5,287.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M311.4,302.5c0.4,0.3,0,1.5-0.2,3.1c0,0.2,0,0.4,0,0.6c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.2
		c0.1,0.3,0.3,0.6,0.5,0.8c0.3,0.3,0.7,0.6,1.1,0.9c0.3,0.2,0.7,0.4,1.1,0.4c0.3,0,0.5,0,0.8-0.2c0.3-0.2,0.5-0.5,0.6-0.8
		c0.2-0.4,0.3-0.8,0.4-1.3c0.1-0.4,0.1-0.8,0.1-1.2c0-1.4-0.8-2.3-0.6-2.8c0.1-0.2,0.5-0.3,1.1,0c0.9,0.4,1.6,1.2,1.9,2.1
		c0.3,0.6,0.4,1.3,0.4,2c0.1,0.8,0,1.5-0.2,2.3c-0.3,1-0.8,1.8-1.5,2.5c-0.9,0.8-2,1.2-3.2,1.1c-1-0.1-2-0.5-2.9-1
		c-0.7-0.5-1.3-1.1-1.8-1.8c-0.5-0.7-0.8-1.6-0.9-2.5c0-0.2,0-0.5,0-0.7c0-0.2,0.1-0.4,0.1-0.6c0.1-0.3,0.2-0.6,0.3-0.8
		C309.8,302.9,311,302.2,311.4,302.5z"
          />
          <rect x="2" y="2" class="st0" width="486" height="720" />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M115.2,73.5c0.2,0,0.1-0.9,0.6-2.2c0.9-1.8,2.5-3.2,4.4-3.9c0.6-0.2,1.3-0.4,1.9-0.5c0.7-0.1,1.4-0.1,2.1,0
		c0.7,0.1,1.4,0.4,2,0.8c0.3,0.2,0.5,0.4,0.7,0.7c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0.1,0,0.1h-0.1l-0.7,0.2l-0.7,0.3
		c-1,0.4-1.9,0.9-2.8,1.6c-0.9,0.7-1.7,1.5-2.4,2.4c-0.2,0.2-0.3,0.5-0.5,0.7s-0.3,0.4-0.6,1c-0.4,0.9-0.8,1.9-1.1,2.8
		c0.9-0.1,1.9-0.2,2.8-0.2c0.1,0,0.3,0,0.3,0h0.2h0.3c0.2,0,0.4,0,0.6,0.1c0.8,0.1,1.7,0.3,2.5,0.6c1.5,0.5,2.7,1.5,3.6,2.8
		c1.1,1.8,1.2,4.1,0.2,6c-0.2,0.3-0.3,0.5-0.6,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.2-0.1,0-0.2-0.2c-0.2-0.7-0.5-1.3-0.8-2
		c-1.6-3.6-4.4-6.6-7.9-8.4l-0.3,0.6c1.1,0.6,2.2,1.3,3.2,2.1c0.6,0.5,1.1,1.1,1.6,1.7c0.7,1,1.1,2.1,1.2,3.3c0.1,1.7-0.2,3.4-1,4.9
		c-0.5,1-1,1.9-1.7,2.8c-0.3,0.5-0.7,1-1.1,1.5l-0.4,0.5c0-0.2-0.1-0.4-0.1-0.6c0,0,0,0,0-0.1v-0.2c0-0.1,0-0.2-0.1-0.4
		s-0.1-0.5-0.1-0.8c0-0.6,0-1.2,0-1.8c0.1-0.7,0.1-1.4,0.3-2.1c0.1-0.4,0.2-0.7,0.3-1.1c0.1-0.2,0.1-0.3,0.3-0.8s0.2-0.7,0.3-1.1
		c0.1-0.3,0-0.6-0.1-0.8c-0.2-0.3-0.4-0.5-0.7-0.6c-0.5-0.3-1.4,0.2-2.1,0.4l-0.3,0.1l-0.2,0.1l-0.3,0.1l-0.6,0.3l-1.3,0.5
		c-2.3,0.9-4.4,2.1-6.4,3.5c-0.2,0.2-0.4,0.3-0.6,0.5l-0.3,0.2l0,0l-0.1,0.1l-0.2,0.3l-0.1,0.2l-0.1,0.1l0,0c0-0.1,0-0.2,0-0.3
		c0-0.1,0-0.2,0.1-0.3l0.1-0.2v-0.1l0.2-0.3c0.7-1.3,1.7-2.4,2.8-3.3c1.3-1.1,2.8-1.9,4.4-2.4l-0.4-0.2c0.2,0.2,0.2,0.5,0.1,0.8
		c0,0.1-0.1,0.2-0.1,0.3l0.4-0.1c-1.3-0.4-2.6-0.6-4-0.7c-0.9,0-1.3,0-1.7,0c-1.2,0-2.4,0.2-3.6,0.4c-1.1,0.2-2.2,0.6-3.2,1
		c-0.6,0.2-1.2,0.5-1.8,0.8c-0.4,0.2-0.8,0.5-1.1,0.7c0,0,0,0-0.1,0l-0.1,0.1c0,0,0-0.1,0.1-0.1l0.1-0.2c0.2-0.4,0.5-0.9,0.8-1.3
		l0.5-0.6c0.2-0.3,0.5-0.6,0.8-0.9c0.6-0.6,1.2-1.2,1.9-1.7c0.7-0.6,1.5-1.1,2.3-1.6c0.4-0.2,0.8-0.5,1.3-0.7l0.3-0.2l0.2-0.1
		l0.4-0.1l0.8-0.2l0.4-0.1l0.2-0.1c0.1,0,0.2,0,0.1-0.1l0.9-0.3c0.1,0,1.1-0.3,0.9-0.3l-0.4,0.1l-0.2,0.1l0,0c0,0-0.1,0,0,0l0.4,0.2
		l0.2,0.1h0.1c0,0,0.1,0,0,0h-0.4h-0.5c0,0,0,0,0,0.1v0.1c0.4,0.1-1-0.3,1,0.3l0,0l0,0l-0.2-0.2l-0.3-0.3l-0.2-0.2
		c0,0-0.1-0.1-0.1-0.1h0.1l0.5-0.2c-0.2-0.2-0.4-0.5-0.6-0.7c-0.2-0.2-0.4-0.4-0.6-0.6l-0.9-0.6l-1-0.4l-0.5-0.2
		c-0.1-0.1-0.4-0.1-0.5-0.1c-0.4-0.1-0.8-0.2-1.3-0.3c-1.7-0.2-3.4,0-4.9,0.6h-0.1l0,0l0,0c-0.1,0.1,0,0,0,0v-0.1v-0.1
		c0.2-0.4,0.3-0.8,0.5-1.2c0.4-0.8,0.7-1.5,1.2-2.3c0.3-0.6,0.7-1,1.3-1.3c0.7-0.2,1.4-0.2,2.2-0.3c0.8,0,1.5-0.1,2.2-0.1
		c1.3,0,2.5,0.1,3.8,0.4c1.7,0.4,3.2,1.5,4.1,3.1c0.6,1.1,0.6,1.8,0.7,1.8s0.4-0.8-0.1-2.1c-0.8-1.9-2.4-3.4-4.4-4
		c-1.3-0.5-2.7-0.7-4.1-0.7c-0.7,0-1.5,0-2.3,0c-0.9,0-1.7,0.1-2.6,0.2c-0.5,0.1-1,0.4-1.4,0.8c-0.4,0.4-0.7,0.7-0.9,1.2
		c-0.5,0.8-0.9,1.6-1.3,2.4c-0.2,0.4-0.4,0.8-0.6,1.3l-0.2,0.6L99.5,77c-0.1,0.4-0.3,0.8-0.4,1.2l-0.7,2.4l-0.6,2.1l1.6-1.5l0.4-0.4
		l0.3-0.3l0.6-0.6l0.6-0.6l0.3-0.3l0.3-0.2c0.2-0.1,0.3-0.2,0.5-0.3l0.4-0.2c0.6-0.2,1.2-0.4,1.9-0.5c0.7-0.1,1.3-0.1,2,0
		c0.3,0,0.7,0.1,1,0.2h0.1l0,0c0.1,0,0.1,0,0.1,0l0,0l-0.7,0.4c-0.5,0.2-0.9,0.5-1.4,0.8c-0.9,0.5-1.7,1.2-2.4,1.8
		c-0.7,0.6-1.3,1.3-1.9,2c-0.3,0.3-0.6,0.7-0.8,1l-0.4,0.5l-0.5,0.8c-0.9,1.4-1.6,3-2.1,4.6l-0.1,0.4l-0.1,0.2v0.1l0,0
		c0,0,0.1,0,0.1,0h0.1h0.1l0,0l1.2-1l0.6-0.5l0.5-0.4c0.5-0.5,1.1-0.9,1.7-1.3c0.7-0.4,1.4-0.8,2.1-1.1c1-0.4,2-0.8,3.1-1
		c1.1-0.3,2.3-0.4,3.5-0.5c0.5,0,1.1,0,1.6,0.1c1.3,0.1,2.6,0.3,3.9,0.7l0.2,0.1l0.1-0.2c0.1-0.1,0.3-0.3,0.3-0.5
		c0.2-0.5,0.2-1.1-0.1-1.5l-0.1-0.2l-0.3,0.1c-1.8,0.3-3.5,1.1-4.9,2.1c-1.3,1-2.5,2.2-3.3,3.5l-0.2,0.3l-0.3,0.5
		c-0.2,0.4-0.4,0.7-0.5,1.1l-0.4,1.1c-0.1,0.3-0.2,0.7-0.3,1c-0.2,0.7-0.3,1.4-0.4,2.2l-0.1,0.6v0.7V97l2.4-2.8
		c0.1-0.2,0.3-0.4,0.5-0.5l0.4-0.4l0.9-0.9c0.2-0.1,0.3-0.3,0.5-0.4l0.3-0.2l0.6-0.4c1.9-1.3,4-2.4,6.1-3.2l2.3-1c0.1,0,0.1,0,0.1,0
		c0.1-0.3-0.3,0.9-0.4,1.7c-0.1,0.8-0.2,1.6-0.3,2.4c0,0.7,0,1.4,0.1,2.1c0,0.3,0.1,0.6,0.1,0.9c0.1,0.3,0,0.3,0.1,0.4l0.1,0.5
		l0.3,1.1l0.3,0.9c0.1,0.4,0.3,0.7,0.5,1.1c0.7-0.9,1.4-1.8,2.1-2.7c0.4-0.5,0.8-1.1,1.1-1.6c0.6-0.9,1.1-1.9,1.5-3
		c0.8-1.7,1.1-3.6,0.9-5.5c-0.2-1.3-0.7-2.6-1.5-3.6c-0.5-0.7-1.1-1.3-1.8-1.8c-1-0.8-2.1-1.6-3.3-2.2l-0.3,0.6
		c1.2,0.8,2.3,1.7,3.3,2.7c1.5,1.6,2.7,3.6,3.5,5.7c0.2,0.7,0.4,1.3,0.6,2c0.1,0.3,0.1,0.6,0.2,0.9s0.2,0.6,0.2,0.8l0.1,0.4l0.1,0.2
		c0,0,0.1-0.1,0.2-0.1l0.8-0.5c0.6-0.3,1.1-0.7,1.6-1.2c0.3-0.3,0.6-0.7,0.8-1.1c1.5-2.5,1.5-5.6,0-8.1c-1.1-1.7-2.7-2.9-4.6-3.6
		c-1-0.4-2-0.6-3-0.8c-0.3,0-0.5,0-0.8-0.1s-0.3,0-0.4,0h-0.2l0,0c0,0.1,0.1-0.2,0.2-0.4s0.3-0.4,0.4-0.6c0.5-0.7,1.2-1.4,1.9-2
		c0.7-0.6,1.5-1,2.3-1.4l0.6-0.3l0.3-0.1h0.2h0.1h0.1h0.1l1.2-0.4c0.1-0.4,0.1-0.8,0.1-1.2c0-0.7-0.3-1.4-0.7-1.9
		c-0.3-0.4-0.7-0.8-1.2-1.1c-0.8-0.5-1.7-0.9-2.7-1c-0.9-0.1-1.7-0.1-2.6,0.1c-0.8,0.1-1.5,0.4-2.2,0.7c-2.2,0.9-3.8,2.7-4.6,4.9
		C114.7,72.7,115.1,73.6,115.2,73.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M114.9,88.1c1.9,9.7,1.7,19.7-0.7,29.3c-0.7,2.9-1.6,5.7-2.8,8.5c-0.8,1.9-1.4,2.9-1.5,2.9
		c-0.3-0.1,1.4-4.4,2.9-11.7c1.9-9.4,1.9-19.2,0.1-28.6C112.8,88.2,114.8,87.9,114.9,88.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M120.6,96.7c-1.3,1.4-1.3,3.7-1.4,5.9s-0.2,4.3-0.3,6.3c-0.1,4-0.1,7.7-0.1,10.7c0,6.1,0.1,9.9-0.2,9.9
		s-0.9-3.8-1.3-9.9c-0.2-3.1-0.3-6.7-0.3-10.8c0-2,0.1-4.2,0.1-6.4c0-1.2,0.1-2.3,0.3-3.5c0.2-1.3,0.7-2.6,1.6-3.6
		C119.2,95.1,120.7,96.6,120.6,96.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M139.2,42.2c0.1-0.1-0.2-0.6-0.8-1.4c-0.9-1.3-1.8-2.5-2.9-3.7c-1.6-1.9-3.5-3.4-5.7-4.6
		c-1.4-0.7-2.8-1.2-4.4-1.5c-0.8-0.1-1.7-0.2-2.5-0.2c-0.2,0-0.4,0-0.7,0H122h-0.1h-0.2h-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1
		c0.1-0.1,0,0,0.4-0.3c0.3-0.2,0.7-0.4,1-0.5c0.7-0.3,1.5-0.5,2.3-0.6c0.9-0.1,1.7-0.3,2.6-0.4c3.5-0.4,7.3-0.5,10.6,1
		c2.3,1.1,4.1,3,5.1,5.4c0.2,0.4,0.3,0.9,0.4,1.4c0,0.2,0.1,0.4,0.1,0.6v0.3c0,0.1,0,0.2,0,0.3v0.3c0,0.1-0.1,0.3-0.1,0.4
		c-0.4,1.8-1.3,4.3-1.5,5l0.6-0.6l1-1l1.9-2c0.3-0.3,0.6-0.6,0.9-0.9l0.7-0.7c0.3-0.3,0.6-0.6,1-0.9c1.2-1.1,2.6-2,4.1-2.6
		c2.5-0.9,5.3-0.7,7.7,0.4c1.9,0.8,3.7,1.6,5.4,2.7c1.1,0.6,2.2,1.4,3.2,2.2c0.4,0.3,0.7,0.7,1,1.1c0.1,0.2,0.2,0.4,0.3,0.6l0.1,0.2
		h-0.2h-0.4l-5.7,0.2l-8.3,0.3c-1.7,0-3.4,0.2-5.1,0.5c-0.5,0.1-0.9,0.2-1.4,0.4c-0.2,0.1-0.5,0.2-0.7,0.3c-0.3,0.1-0.6,0.3-0.8,0.4
		c-0.7,0.4-1.2,1-1.7,1.6l-0.6,0.9l-0.3,0.5l-0.2,0.3c0.5,0,1.7,0,3.2,0.1l0.6,0.1h0.3c0.2,0,0.4,0.1,0.7,0.1c0.5,0.1,1,0.2,1.5,0.4
		c1.1,0.4,2.1,1,3,1.8c0.9,0.9,1.5,2.1,1.7,3.3c0.1,0.7,0.1,1.3-0.1,2c-0.1,0.3-0.3,0.7-0.2,0.6s0,0,0,0l-2.7-2.2
		c-2-1.6-3.5-2.8-4-3.1l-5.5-3.8l4.3,5c1.7,1.8,3,4,3.8,6.3c0.6,1.7,0.6,3.6,0.2,5.3c-0.2,0.7-0.4,1.3-0.8,1.9
		c-0.1,0.3-0.4,0.5-0.5,0.7c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.2-0.4,0.4-0.7,0.6l-0.3,0.2c-0.1,0.1-0.1,0,0,0v-0.1
		c0.1-0.4,0.1-0.8,0.2-1.2l0.1-0.7l0.1-0.7c0.1-1.5,0-3.1-0.3-4.6c-0.1-1-0.4-2-0.7-3c-0.3-0.7-0.8-1.3-1.4-1.7
		c-0.8-0.4-1.8-0.5-2.7-0.3c-5.3,0.7-10.1,3.3-13.5,7.5l1.3,0.6c0.7-1.9,1.7-3.6,3-5.2c1.1-1.3,2.4-2.4,3.9-3.3
		c0.8-0.5,1.7-0.9,2.6-1.2h0.1l0.2-0.1l0.4-0.1l0.9-0.2l0.9-0.2l0.4-0.1l0.6-0.1l4.3-0.6l-2.3-0.8c-1.6-0.5-3.1-1-4.7-1.5l-1-0.3
		l-0.6-0.2l-1.2-0.3c-1.6-0.4-3.3-0.6-5-0.6c-1.7,0-3.3,0.3-4.9,0.9c-0.8,0.3-1.5,0.6-2.2,1.1l-0.1,0.1l0,0l0,0l-0.1,0.1l-0.1,0.2
		c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0.1-0.3l0-0.2c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.3,0.4-0.6,0.7-0.9c0.5-0.6,1-1.1,1.6-1.6
		c2-1.6,4.3-2.8,6.8-3.6c1.6-0.5,3.3-0.7,4.9-0.4c1.1,0.2,1.7,0.6,1.8,0.4s-0.4-0.6-1.6-1c-1.7-0.5-3.6-0.6-5.4-0.1
		c-2.7,0.6-5.3,1.8-7.5,3.4c-0.7,0.5-1.3,1.1-1.9,1.7c-0.3,0.3-0.6,0.7-0.8,1c-0.1,0.2-0.3,0.4-0.4,0.6l-0.4,0.7
		c-0.2,0.3-0.3,0.7-0.4,1c-0.2,0.3-0.3,0.7-0.4,1l-0.2,1c-0.1,0.3-0.1,0.6-0.1,0.9l-0.2,2v0.1c0,0.1,0,0.1,0,0.2l-0.1,1l1.4-1.9
		l1.1-1.5l0.1-0.2l0.1-0.1l0.2-0.2l0.4-0.4c0.1-0.1,0.3-0.3,0.4-0.4s0.4-0.2,0.4-0.3c0.6-0.3,1.2-0.6,1.8-0.9
		c1.4-0.5,2.8-0.7,4.2-0.7c1.5,0,3.1,0.2,4.6,0.6l1.2,0.3l0.6,0.2h0.1h0.1l0,0c0,0,0.1,0-0.1,0c-1,0.4-2,0.9-2.9,1.4
		c-1.6,1.1-3.1,2.4-4.2,4c-1.4,1.7-2.4,3.8-2.9,5.9l-0.6,3.1l1.9-2.5c3-4,7.5-6.7,12.4-7.5c0.7-0.2,1.5-0.2,2.2,0.1
		c0.5,0.3,0.9,0.8,1.2,1.3c0.4,0.9,0.6,1.8,0.7,2.8c0.1,0.8,0.2,1.5,0.2,2.3c0,0.7,0,1.5,0,2.2l-0.1,0.7c0,0.2,0,0.5-0.1,0.9
		c-0.1,0.7-0.3,1.6-0.5,2.6l0.1,0.1c0,0,0,0.1,0.1,0l0.6-0.3c0.5-0.3,1-0.6,1.5-0.9c0.3-0.2,0.6-0.5,0.9-0.8
		c0.2-0.3,0.4-0.5,0.6-0.8c0.4-0.6,0.8-1.4,1-2.1c0.7-2,0.7-4.1,0.1-6.1c-0.8-2.6-2.1-5-3.9-7l-1.2,1.2c0.4,0.4,1.9,1.7,3.8,3.4
		l1.6,1.3l0.9,0.7l0.7,0.6c0.3,0.2,0.6,0.5,0.9,0.7c0.1,0.1,0.3,0.3,0.4,0.3l0.4-0.4c0.2-0.3,0.4-0.6,0.7-0.9
		c0.1-0.2,0.2-0.3,0.3-0.5l0.2-0.4c0.1-0.2,0.2-0.5,0.2-0.7c0.3-0.9,0.3-1.9,0.1-2.9c-0.3-1.7-1.1-3.3-2.4-4.5
		c-1.1-1-2.4-1.7-3.8-2.2c-0.6-0.2-1.2-0.3-1.7-0.4c-0.3,0-0.5-0.1-0.8-0.1h-0.4l0,0h-0.3h-0.1l0,0l0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2
		c0,0,0.3-0.2,0.4-0.2c0.2-0.1,0.4-0.2,0.6-0.3c0.4-0.2,0.8-0.3,1.2-0.4c1.6-0.4,3.3-0.6,4.9-0.7l8.3-0.7l6.4-0.5l0.7-0.1h0.3
		l-0.2-0.3c-0.2-0.4-0.4-0.8-0.7-1.2c-0.4-0.5-0.8-1-1.3-1.4c-1-0.9-2.1-1.7-3.2-2.4c-1.7-1.1-3.5-2.1-5.4-3c-1.2-0.6-2.5-1-3.9-1.2
		c-1.6-0.2-3.2-0.1-4.7,0.4c-1.7,0.6-3.2,1.5-4.6,2.7c-0.4,0.3-0.7,0.6-1.1,0.9l-0.3,0.2l-0.1,0.1l-0.1,0.1l-0.2,0.2
		c0,0-0.1,0.1-0.1,0l0.1-0.1l0.1-0.3c0,0,0-0.1,0-0.1c0-0.3,0-0.5-0.1-0.8c-0.1-0.6-0.2-1.2-0.4-1.8c-1.1-2.9-3.3-5.3-6.1-6.6
		c-1.9-0.8-3.9-1.3-6-1.4c-1.9-0.1-3.8,0-5.7,0.3c-0.9,0.1-1.8,0.3-2.7,0.4c-0.9,0.1-1.9,0.4-2.7,0.8c-0.4,0.2-0.9,0.4-1.3,0.7
		c-0.6,0.4-1,0.9-1.4,1.4c-0.3,0.5-0.5,1.2-0.4,1.8V33c0,0.2,0.1,0.4,0.1,0.6l1.3-0.4c0.2-0.1,0.4-0.2,0.6-0.2l0.5-0.1l0.5-0.1
		c0.2,0,0.2,0,0.3,0s0.4,0,0.6-0.1c0.7-0.1,1.5,0,2.2,0.1c1.4,0.2,2.7,0.6,3.9,1.2c2.1,1,4,2.4,5.6,4c1.4,1.3,2.5,2.5,3.2,3.3
		S139.2,42.3,139.2,42.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M136.6,127.5c2.2-9.3,2.1-18.5,1.6-26.8s-1.4-15.8-1.7-22.2c-0.4-5-0.4-10.1,0-15.1c0.3-3.6,0.7-5.5,0.8-5.5
		s0,2,0,5.5c0,5,0.2,10,0.6,14.9c0.5,6.3,1.5,13.8,2.1,22.2s0.8,17.8-1.5,27.4C138.5,128.6,136.6,127.9,136.6,127.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M140.7,129.5c0.6-5.1,1.1-10.1,1.7-15s1.2-9.5,1.4-13.9c0.6-8.8-1.6-16.6-2.8-23.3s-1.7-12.3-1.2-16.1
		c0.1-0.8,0.2-1.7,0.4-2.5c0.1-0.4,0.1-0.7,0.2-1l0.3-0.8c0.3-1,0.5-1.4,0.6-1.4s-0.5,2.1-0.7,5.8s0.3,9.1,1.8,15.7
		c0.7,3.3,1.6,6.9,2.4,10.8c0.4,2,0.6,4,0.8,6.2c0.2,2.2,0.2,4.4,0.1,6.6c-0.2,4.5-0.8,9.2-1.3,14s-1.1,9.8-1.6,15
		C142.7,130.4,140.6,130,140.7,129.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M140,107.4c0,0.1-0.2,0.4-0.8,0.6c-0.8,0.3-1.6,0.4-2.5,0.3c-1.2-0.1-2.4-0.3-3.5-0.8
		c-1.3-0.4-2.6-0.9-3.9-1.3c-5.5-1.5-10.2-0.5-10.4-1.1c-0.1-0.3,1-0.9,3-1.3c2.6-0.5,5.3-0.4,7.9,0.4c1.4,0.4,2.7,0.9,4,1.5
		c1,0.4,2,0.8,3.1,1.1C138.7,107.4,139.9,107.1,140,107.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M130.5,44.1c-0.6-1.3-1.8-2.2-3.1-2.6c-0.7-0.2-1.3-0.2-2-0.1c-0.5,0.1-0.9,0.2-1.4,0.5
		c-0.2,0.1-0.4,0.2-0.6,0.3l-0.6,0.5l-1.4,1.2c0-0.5,0-0.7,0-0.8l0.1-1.2c0-0.8,0.2-1.5,0.5-2.3c0.2-0.4,0.4-0.8,0.6-1.1
		c0.1-0.2,0.3-0.4,0.5-0.6c0.3-0.4,0.7-0.7,1.1-1c1.3-1,2.9-1.6,4.6-1.7c1-0.1,2,0,3,0.2c0.7,0.2,1,0.3,1,0.4c0,0.3-1.5,0-3.9,0.5
		c-1.4,0.2-2.6,0.9-3.7,1.8c-0.3,0.3-0.6,0.6-0.8,0.9c-0.1,0.2-0.2,0.3-0.3,0.5l-0.1,0.1c0,0.1,0,0.1,0,0.2l0,0l0.1-0.1l0.1-0.1
		c0.3-0.1,0.6-0.2,0.9-0.3c0.9-0.2,1.9-0.2,2.8,0.1c2,0.5,3.7,1.8,4.6,3.7C132.5,43.4,130.5,44.2,130.5,44.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M156,49.5c1.3-0.7,2.7-1,4.1-0.7c1.3,0.2,2.5,0.7,3.6,1.5c0.9,0.7,1.8,1.6,2.5,2.5l0.3,0.3l0.1,0.2l0,0v0.1
		l0.1,0.2c0,0,0-0.1,0.1-0.1v-0.1c0-0.2,0-0.5,0.1-0.8c0.1-1,0-2-0.3-2.9c-0.3-0.8-0.7-1.5-1.4-2c-0.6-0.5-1.3-0.8-2-1.1
		c-1.1-0.4-2.1-0.8-3.2-1.3c-0.7-0.4-1-0.8-1-0.8s0.5,0.1,1.2,0.3s1.9,0.4,3.4,0.8c0.9,0.2,1.7,0.6,2.4,1.1c0.9,0.6,1.6,1.6,2,2.6
		c0.4,1.1,0.6,2.3,0.6,3.5c0,0.3,0,0.6,0,1s-0.1,1.1-0.2,1.6c-0.2,1.1-0.3,2.2-0.6,3.3l-1.7-2.5c-0.3-0.4-0.6-0.8-0.9-1.3l-0.2-0.3
		l-0.1-0.2l-0.2-0.3c-0.6-0.8-1.4-1.4-2.2-2c-1.6-1.1-3.9-1.6-5.4-0.7C156.9,51.4,155.8,49.6,156,49.5z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M114.1,78.8c1.4,0.1,2.6,0.6,3.6,1.6c0.7,0.7,1.3,1.5,1.7,2.4c0.7,1.5,0.9,2.5,0.6,2.6s-0.9-0.6-1.9-1.8
		s-2.4-2.7-4.2-2.7C113.9,80.2,114,79.5,114.1,78.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M128.1,77.3h2.7h0.1l0,0c0,0,0,0,0-0.1l-0.3-0.5c-0.2-0.3-0.4-0.6-0.6-0.9c-0.4-0.5-0.8-1-1.3-1.4
		c-0.7-0.6-1.6-1-2.5-1.1c-0.7-0.1-1.1,0-1.1-0.2s0.3-0.5,1.1-0.7c1.2-0.2,2.4,0,3.4,0.7c0.7,0.4,1.3,0.9,1.8,1.5
		c0.3,0.3,0.5,0.7,0.8,1l0.4,0.6c0.1,0.2,0.4,0.7,0.6,1l0.6,1.1l0.3,0.6l0.2,0.3c0,0,0,0.1,0,0.1h-0.2c-0.8,0-1.7,0.1-2.7,0.1h-3.1
		C128,78.7,128,78,128.1,77.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M114.4,104.3c0-0.3-1.9-0.1-4.4-1.5c-1.3-0.7-2.7-1.7-4.4-2.8c-0.9-0.6-1.9-1.1-2.9-1.5
		c-1.2-0.4-2.5-0.6-3.8-0.5c-2.1,0.3-3.7,1-5.1,0.9c-1.4-0.2-2.7-0.7-3.7-1.6c-1.1-0.8-2-1.6-2.9-2.2c-0.6-0.5-1.3-0.9-2.1-1.3
		c-0.7-0.4-1.5-0.7-2.4-0.7c-1.3,0-2.5,0.3-3.7,0.8c-1.3,0.4-2.7,0.3-3.9-0.3c-1.5-0.8-2.8-1.8-3.9-3c-0.5-0.5-1-1-1.4-1.5
		c-0.8-0.8-1.3-1.5-1.4-1.6l-0.9,1c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.1-0.2,0.1
		c-0.3,0.1-0.5,0.3-0.8,0.3c-0.6,0.1-1.3,0.1-1.9,0.1c-1.3-0.1-2.5-0.4-3.6-0.6c-2.1-0.4-3.6-0.6-4.4-0.8c-0.5-0.1-1.3-0.3-2.2-0.7
		c-1.1-0.3-2.2-0.7-3.3-1.2c-0.6-0.3-1.1-0.7-1.5-1.2c-0.4-0.6-0.7-1.2-0.8-1.9c-0.2-0.9-0.5-1.8-0.8-2.6c-0.3-0.6-0.7-1.1-1.3-1.5
		c-0.7-0.4-1.5-0.4-2.2-0.2c-0.7,0.3-1.2,0.7-1.7,1.2c-0.4,0.4-0.8,0.7-1.2,1.1c-0.7,0.6-1.6,1.2-2.4,1.7c-1.4,0.8-3,1.5-4.6,1.9
		c-2.8,0.7-4.7,0.5-4.7,0.8s1.8,1,5,0.6c1.8-0.2,3.6-0.8,5.3-1.6c1-0.5,1.9-1.1,2.8-1.8c0.7-0.7,1.5-1.3,2.3-1.9
		c0.2-0.1,0.3-0.1,0.5,0c0.2,0.1,0.4,0.3,0.5,0.5c0.3,0.7,0.6,1.5,0.8,2.3c0.3,0.9,0.7,1.7,1.4,2.4c0.6,0.6,1.3,1,2.1,1.3
		c1.2,0.4,2.4,0.7,3.7,0.8c1,0.2,1.8,0.3,2.3,0.4c0.8,0.2,2.3,0.7,4.3,1.3c1,0.3,2.2,0.7,3.6,1c0.8,0.2,1.6,0.2,2.4,0.1
		c0.5-0.1,1-0.2,1.4-0.4l0.3-0.2h0.1h0.1c0,0,0,0.1,0,0.1c0.4,0.5,0.9,1,1.4,1.6c1.3,1.4,2.9,2.5,4.7,3.3c1.7,0.6,3.5,0.6,5.1-0.2
		c1-0.6,2.1-1,3.2-1.2c0.7-0.1,1.4,0.1,2.1,0.4c0.7,0.4,1.3,0.8,1.8,1.4c0.7,0.7,1.5,1.6,2.6,2.6c0.6,0.5,1.2,1,1.8,1.5
		c0.8,0.5,1.7,0.9,2.6,1c2,0.2,3.8-0.6,5.4-0.7c1-0.1,1.9,0.1,2.9,0.4c0.9,0.3,1.8,0.7,2.7,1.2c1.5,0.9,3.1,1.7,4.8,2.5
		c1.2,0.5,2.4,0.8,3.7,0.7C114,104.6,114.5,104.4,114.4,104.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M36.7,109.3c-0.1,0.3-2.1-0.5-5.1-1.6s-7.3-2.8-12.1-4.1c-4.1-1.1-8.2-2-12.5-2.4c-3.2-0.4-5.3-0.5-5.3-0.8
		s2-0.7,5.4-0.7c4.4,0.1,8.8,0.7,13,1.9c4.2,1.2,8.3,2.8,12.2,4.8C35.1,107.9,36.8,109.1,36.7,109.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M112.5,121.8c-0.1,0.1-1.1-0.3-2.9-0.8c-2.5-0.7-5.2-1.1-7.8-1.1c-1.8,0-3.6,0.2-5.4,0.6
		c-2.2,0.6-4.4,0.8-6.7,0.6c-2.4-0.5-4.7-1.4-6.9-2.6c-2.2-1.1-4.4-2.1-6.8-2.1s-4.8,0.9-7.4,1.2c-2.4,0.3-4.9,0.3-7.3,0
		c-4.7-0.6-8.5-2.4-11.7-3.5c-2.5-1-5-1.7-7.7-2.1c-1.9-0.3-2.9-0.2-2.9-0.4s1-0.4,3-0.4c2.8,0.1,5.5,0.6,8.1,1.5
		c3.3,1,7.1,2.5,11.4,3c2.3,0.2,4.6,0.2,6.8-0.1c2.3-0.3,4.8-1.2,7.6-1.3c1.4,0,2.8,0.2,4.1,0.7c1.2,0.5,2.4,1,3.6,1.6
		c2,1.2,4.1,2,6.3,2.6c2,0.2,4.1,0.1,6.1-0.4c1.9-0.3,3.8-0.4,5.7-0.4c2.8,0.1,5.5,0.8,8.1,1.8C111.7,121,112.6,121.7,112.5,121.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M138.3,123.4c0.1,0.3-0.9,0.9-2.7,1.6c-2.4,0.8-5,1.1-7.5,0.9c-2.5-0.2-5-0.7-7.3-1.6
		c-1.8-0.7-2.8-1.3-2.7-1.6s1.3-0.2,3.1,0.1s4.3,0.7,7.1,0.9c2.3,0.2,4.7,0.1,7-0.3C137.1,123.2,138.2,123.1,138.3,123.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M176.6,127.7c0,0.1-0.5,0.3-1.4,0.3c-1.3,0.1-2.7-0.1-3.9-0.4c-0.9-0.3-1.8-0.7-2.6-1.3
		c-0.4-0.3-0.8-0.7-1.1-1.1c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.1-0.5-0.1c-2-0.1-4.4-0.3-6.8-0.3c-2.2,0.1-4.5,0.3-6.7,0.8
		c-1.9,0.4-3.8,0.6-5.8,0.5c-1.3-0.1-2.6-0.3-3.9-0.5c-0.9-0.2-1.4-0.4-1.4-0.6c0-0.3,2.1-0.2,5.2-0.4c1.8-0.1,3.6-0.4,5.4-0.9
		c2.3-0.6,4.6-0.9,7-1c1.3,0,2.5,0,3.7,0.1l1.7,0.1c0.6,0.1,1,0.1,1.7,0.2c0.3,0.1,0.7,0.2,1,0.3c0.3,0.2,0.6,0.4,0.9,0.7
		c0.3,0.4,0.6,0.7,0.9,1c0.6,0.5,1.2,1,1.9,1.3c1.1,0.4,2.3,0.8,3.5,1C176.2,127.4,176.7,127.6,176.6,127.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M278.9,131.8c0,0.1,1.2,0,3.2-0.8c1.2-0.5,2.4-1.1,3.4-2c0.7-0.6,1.2-1.3,1.6-2.1c0.1-0.2,0.2-0.5,0.3-0.7
		c0.1-0.2,0.1-0.5,0.2-0.8c0-0.1,0-0.2,0-0.3v-0.1h0.1h0.2h0.7h1.5l3.2,0.1c2.2,0.1,4.6,0.1,7.1,0.1c2.8,0.1,5.5-0.4,8.1-1.5
		c1.3-0.7,2.6-1.6,3.6-2.7c0.8-1,1.8-1.9,2.9-2.5c0.8-0.4,1.7-0.5,2.5-0.2c0.7,0.3,0.9,0.8,1.1,1.9c0.1,0.7,0.3,1.3,0.5,1.9
		c0.4,0.6,0.9,1.2,1.6,1.5c1,0.5,2.1,0.9,3.2,1.2l5.8,1.9c1,0.4,2.1,0.5,3.2,0.3c0.9-0.3,1.8-0.8,2.6-1.4c2.4-1.7,5.3-2.4,8.2-2.1
		c1.9,0.3,3.7,0.9,5.4,1.8c1,0.4,2,0.7,3.1,0.8c1.6,0,3.3-0.3,4.8-0.9l2.9-1.1c1.1-0.5,2.2-0.8,3.3-0.9c1.3-0.1,2.6,0.1,3.7,0.7
		c0.6,0.3,1.1,0.7,1.5,1.2c0.1,0.1,0.2,0.3,0.3,0.4s0.1,0.3,0.2,0.4c0.1,0.3,0.1,0.6,0.1,0.9v1.3l0,0c0.1,0,0.2,0,0.3,0l1.4-0.3
		l1.4-0.3l2.2-0.5c0-0.3,0-0.5,0-0.8c0-0.1,0-0.3,0-0.5v-0.2c0-0.1,0.1-0.3,0.2-0.4c0.2-0.4,0.5-0.7,0.8-0.9l0.3-0.2
		c0.1-0.1,0.3-0.1,0.2-0.1s0,0,0.1,0l0,0l0,0l0.1,0.3c0.4,0.6,0.9,1.1,1.5,1.5c0.8,0.5,1.6,0.8,2.5,0.9c1,0.1,1.9,0,2.8-0.3
		c1.3-0.4,2.6-0.9,3.8-1.5c1.1-0.5,2.2-1.2,3.1-1.9c0.8-0.6,1.5-1.4,2-2.3l0.1-0.3v-0.2c0,0,0.1,0.1,0.2,0.1l1.1,0.8
		c0.3,0.2,0.7,0.5,1,0.7l1-0.6c0.4-0.2,0.8-0.4,1.1-0.6c1.3-0.7,2.8-0.9,4.2-0.6c0.6,0.2,1,0.6,1.3,1.1c0.3,0.6,0.6,1.2,0.8,1.8
		c0.5,1.3,1.3,2.4,2.4,3.3c1.6,1.3,3.7,2,5.8,2.1c1.3,0.1,2.7-0.2,3.9-0.7c0.3-0.1,0.6-0.2,0.9-0.4c0.4-0.2,0.7-0.4,1-0.6l0.5-0.4
		l0.5-0.5c0.3-0.3,0.7-0.7,1-1.1c0.3-0.4,0.6-0.7,1-0.9c0.4-0.2,0.9-0.2,1.3-0.1c1,0.3,1.9,1.3,3.2,2c1.5,0.7,3.2,0.9,4.8,0.4
		c1.6-0.4,3.2-1,4.9-1.3c0.8-0.2,1.6-0.2,2.4-0.1c0.2,0,0.4,0.1,0.5,0.1c0.3,0.1,0.6,0.2,0.8,0.4l0.5,0.3c0.2,0,0.3-0.3,0.5-0.4
		c0.2-0.1,0.3-0.3,0.5-0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.8-0.8,1.4-1.8,1.8-2.8c0.1-0.3,0.2-0.5,0.3-0.8l0.1-0.6c0-0.1,0-0.1,0-0.1v0.1
		l0,0c0.3,0.1,0.7,0.3,1,0.3c1.6,0.3,3.3,0.1,4.8-0.4c1.5-0.5,2.7-1.2,4-1.7c1.1-0.5,2.3-0.7,3.5-0.5c1.2,0.3,2.2,0.9,3.1,1.7
		c0.6,0.4,1.2,0.8,1.8,1.1c0.7,0.3,1.4,0.5,2.1,0.6c1.4,0.1,2.7-0.1,4-0.6c1.1-0.4,2.2-0.9,3.2-1.5c1-0.5,1.9-1,2.8-1.4
		c0.8-0.4,1.7-0.6,2.6-0.7c0.8-0.1,1.6,0,2.4,0.3c0.4,0.2,0.9,0.3,1.3,0.4c0.5,0,1,0,1.4-0.2c0.7-0.3,1.3-0.7,1.9-1.2
		c0.4-0.3,0.9-0.6,1.4-0.8c0.7-0.2,1.4-0.1,2,0.4c0.3,0.3,0.3,0.7,0.4,0.7s0.3-0.3,0-0.9c-0.6-0.7-1.5-1.1-2.5-0.9
		c-1.3,0.2-2.3,1.3-3.5,1.6c-0.7,0.1-1.3,0-1.9-0.3c-0.9-0.4-1.9-0.6-2.9-0.5c-1.1,0.1-2.1,0.3-3.1,0.7c-1,0.4-2,0.8-3,1.3
		c-1,0.5-2,0.9-3.1,1.3c-1,0.4-2.1,0.5-3.2,0.4c-0.5-0.1-1-0.2-1.5-0.4c-0.5-0.3-1-0.6-1.5-0.9c-1.1-0.9-2.5-1.6-3.9-2
		c-1.6-0.3-3.2-0.1-4.6,0.6c-1.4,0.5-2.7,1.2-3.9,1.6c-1.2,0.5-2.4,0.6-3.7,0.4c-0.2,0-0.4-0.1-0.6-0.2c-0.5-0.3-0.9-0.6-1.2-1
		l-1.5-1.5c0,1,0,2.4,0,3.4l0,0.4c0,0.1,0,0.3,0,0.3l-0.1,0.3c-0.1,0.2-0.1,0.4-0.2,0.6c-0.3,0.8-0.8,1.6-1.4,2.2l-0.2,0.2l0,0l0,0
		l-0.1-0.1c-0.4-0.2-0.8-0.3-1.3-0.4c-1-0.1-2,0-3,0.2c-1.8,0.5-3.4,1.1-4.9,1.5c-1.3,0.4-2.7,0.4-4-0.1c-1.2-0.5-2.2-1.5-3.5-1.9
		c-0.7-0.2-1.4-0.1-2,0.3c-0.5,0.3-0.9,0.7-1.2,1.2c-0.3,0.4-0.5,0.7-0.9,1.1l-0.4,0.4l-0.5,0.4c-0.3,0.2-0.6,0.4-0.9,0.6
		c-0.2,0.2-0.5,0.3-0.8,0.4c-1.2,0.4-2.4,0.6-3.6,0.5c-1.9-0.1-3.7-0.9-5.1-2.1c-0.9-0.8-1.5-1.8-1.9-2.9c-0.2-0.7-0.5-1.4-0.8-2.1
		c-0.4-0.8-1.1-1.4-2-1.8c-0.9-0.3-1.8-0.4-2.7-0.3c-0.9,0.1-1.8,0.4-2.6,0.7c-0.4,0.2-0.9,0.4-1.3,0.6l-0.2,0.1l-0.1-0.1l-1.2-0.9
		c-0.7-0.5-1.4-1-2.1-1.5l-0.3,1l-0.2,0.8c0,0.1-0.1,0.3-0.2,0.4l-0.1,0.2c-0.4,0.6-0.9,1.2-1.5,1.6c-0.9,0.6-1.8,1.2-2.8,1.6
		c-1.1,0.5-2.3,1-3.5,1.4c-0.7,0.2-1.4,0.3-2.1,0.2c-0.6-0.1-1.1-0.2-1.6-0.5c-0.3-0.2-0.6-0.5-0.9-0.8l0-0.1l0,0c0,0,0-0.1,0-0.1
		c-0.1-0.2-0.1-0.4-0.2-0.5l-0.2-0.6c-0.6,0-1.3,0-1.9,0.2c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0.1-0.4,0.2-0.5,0.3
		c-0.6,0.4-1.1,1-1.4,1.7c-0.1,0.3-0.2,0.6-0.3,0.8l0,0.4l0,0c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0l-0.6,0.2l-0.6,0.2l-0.3,0.1h-0.1
		l-0.2,0.1c-0.1,0,0,0,0,0v-0.1c0,0,0-0.1-0.1-0.4s-0.2-0.5-0.3-0.7s-0.3-0.4-0.4-0.6c-0.6-0.7-1.3-1.2-2.1-1.5
		c-1.4-0.6-2.9-0.8-4.4-0.6c-0.7,0.1-1.4,0.2-2,0.4c-0.6,0.2-1.1,0.4-1.6,0.7l-2.8,1.3c-1.4,0.7-2.9,1-4.4,1.1c-1,0-2-0.2-2.9-0.6
		c-1.7-0.9-3.6-1.6-5.5-2c-3.1-0.5-6.4,0.2-9.1,1.8c-0.7,0.5-1.4,0.8-2.2,1.1c-0.8,0.1-1.6-0.1-2.3-0.4l-5.7-2.1
		c-1-0.3-1.9-0.7-2.8-1.1c-0.3-0.2-0.6-0.4-0.8-0.7c-0.2-0.4-0.3-0.8-0.3-1.3c-0.1-0.7-0.3-1.4-0.6-2c-0.4-0.7-1-1.3-1.8-1.6
		c-1.4-0.5-2.9-0.4-4.2,0.3c-1.3,0.8-2.5,1.8-3.5,3c-0.8,0.9-1.9,1.7-3,2.3c-2.3,1-4.7,1.4-7.2,1.4l-13.7,0.1h-0.6v0.6v0.6v0.4v0.2
		c0,0.2,0,0.4-0.1,0.6c0,0.2-0.1,0.4-0.2,0.5c-0.3,0.7-0.7,1.3-1.2,1.8c-0.9,0.9-1.9,1.6-3,2.1C280,131.2,278.9,131.6,278.9,131.8z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M18.2,335.1c-0.1-0.1-0.1-0.4-0.3-0.7c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1-0.1-0.1-0.3-0.2-0.4
		c-0.1-0.1-0.1-0.2-0.2-0.4l-0.4-0.2c-0.3-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.5-0.1-0.8-0.1c-0.4,0-0.8,0-1.2,0c-0.8-0.1-1.6-0.2-2.3-0.3
		c0,0-0.1,0-0.3,0.1c-0.2,0.1-0.4,0.3-0.6,0.4l-0.6,0.5c-0.1,0.1-0.2,0.1-0.3,0.3c-0.3,0.5-0.3,1.1-0.5,1.1s-0.7-0.4-0.7-1.5
		c0-0.3,0.2-0.7,0.4-0.9c0.1-0.2,0.3-0.4,0.5-0.7c0.2-0.3,0.5-0.6,0.8-0.8c0.4-0.4,1-0.5,1.6-0.5l2.2,0.2l1,0.1
		c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.7,0.3,1.1,0.5c0.2,0.1,0.3,0.2,0.5,0.4c0.2,0.2,0.4,0.3,0.5,0.6c0.1,0.2,0.2,0.5,0.3,0.8
		c0,0.2,0.1,0.4,0.1,0.6c0,0.4,0,0.7-0.1,1C18.7,335,18.3,335.3,18.2,335.1z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M31.6,335.2c0,0.3-1.5,0.6-3.9,0.9l-4.2,0.4l-1.2,0.1l-0.5,0.1l-0.6,0.2c-0.9,0.2-1.9,0.4-2.8,0.5
		c-1,0.1-1.9,0.1-2.9,0c-0.9-0.1-1.7-0.3-2.6-0.5c-1.6-0.4-3-0.8-4.1-1.2c-2.3-0.8-3.7-1.5-3.6-1.8s1.6-0.1,4,0.4l4.1,0.8
		c1.6,0.3,3.2,0.5,4.9,0.3c0.8-0.1,1.7-0.2,2.5-0.4c0.2,0,0.4-0.1,0.7-0.1c0.2,0,0.5-0.1,0.7-0.1c0.4,0,0.9,0,1.3-0.1
		c1.6,0,3.1,0,4.3,0.1C30.1,334.7,31.5,334.9,31.6,335.2z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M27.2,379.9c0-0.1-0.2-0.3-0.5-0.2c-0.3,0.1-0.6,0.2-0.8,0.4c-0.3,0.2-0.6,0.4-0.9,0.5s-0.6,0.2-1,0.3
		c-1.4,0.4-2.8,1.1-3.3,1.1c-0.2,0-0.3-0.1-0.5-0.2l-0.3-0.2c-0.2-0.1-0.4-0.1-0.5-0.2c-0.9-0.2-1.9,0.1-2.6,0.6
		c-0.3,0.2-0.5,0.3-0.7,0.5c-0.2,0.1-0.5,0.2-0.7,0.2c-0.4,0.1-0.9,0.2-1.3,0.1c-0.4-0.1-1.7-1.1-2.9-1.9c-0.8-0.5-1.6-0.9-2.5-1.1
		c-0.8-0.2-1.3,0-1.3,0.3s0.2,0.6,0.7,1.1c0.6,0.6,1.3,1.1,2.1,1.6c0.4,0.2,0.8,0.4,1.3,0.5c0.4,0.1,0.7,0.1,1.1,0.1
		c0.7,0,1.2,0,1.4,0s0.7,0.4,1.3,0.6c0.4,0.2,0.9,0.3,1.4,0.2c0.5-0.1,1-0.3,1.4-0.6c0.5-0.4,1-0.7,1.5-1c0.1,0,0.2-0.1,0.3-0.1H20
		c0.2,0,0.4-0.1,0.6-0.1c1.2,0.4,2.5,0.6,3.8,0.3c0.6-0.1,1.2-0.4,1.7-0.8c0.3-0.3,0.6-0.7,0.9-1.1
		C27.1,380.8,27.2,380.4,27.2,379.9z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M23.3,381.7c-0.3,0.1-0.8-1-1.5-2.6c-0.2-0.4-0.4-0.9-0.6-1.3s-0.3-0.5-0.6-0.6c-0.5-0.1-1-0.1-1.6,0
		c-0.7,0-1.4-0.1-2-0.1c-0.3,0-0.6,0-0.8,0c-0.1,0-0.2,0-0.3,0l-0.4,0.1l-1.7,0.7l-0.5,0.2l-0.3,0.1c-0.1,0-0.2,0-0.3,0h-0.2
		c0,0.1,0.1-1.2,0-0.6l0,0l0,0c0,0.1,0.1,0.3,0.2,0.4c0,0.1,0.1,0.1,0.1,0.2l0,0l0,0l-0.2,0.2c-0.2,0.2-0.3,0.4-0.5,0.6
		c-0.1,0.1-0.2,0.2-0.4,0.3l-0.3,0.2c-0.7,0.5-1.2,1-1.6,1.3s-0.6,0.5-0.8,0.4s-0.1-0.4,0.1-0.9c0.3-0.7,0.7-1.3,1.2-1.9l0.3-0.3
		L11,378l0.4-0.6c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.3,0.5-0.4,0.9-0.5h0.2c0-0.6-0.1,0.8-0.1,0.7l0,0c0-0.1,0-0.2,0-0.3
		c0-0.1,0-0.1,0-0.3s0-0.1,0-0.1h0.1l0.3-0.2c0.5-0.3,1-0.6,1.7-0.9l0.6-0.2c0.2-0.1,0.5-0.1,0.7-0.1c0.4,0,0.8,0,1.2,0l2,0.2
		c0.7,0,1.4,0,2.1,0.2c0.4,0.2,0.8,0.4,1.1,0.8c0.2,0.3,0.4,0.6,0.5,1c0.2,0.5,0.3,1,0.4,1.5C23.6,380.5,23.6,381.7,23.3,381.7z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M14.3,375.4c0.4,0.1,0.8,0.3,1.1,0.5l0.8,0.5c0.5,0.3,0.9,0.5,1.3,0.7c0.8,0.4,1.3,0.5,1.3,0.9
		s-0.6,0.6-1.6,0.6c-0.6,0-1.2-0.1-1.8-0.4c-0.3-0.1-0.6-0.3-0.9-0.4s-0.6-0.3-0.7-0.3S14.3,375.3,14.3,375.4z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M390,662.3c0.2-0.1,1.7,2.5,3.9,7.7c1.1,2.5,2.3,5.7,3.6,9.5c0.6,1.9,1.2,3.9,1.9,6.1c0.3,1.1,0.6,2.2,0.9,3.4
		c0.2,0.6,0.3,1.2,0.5,1.9c0.2,0.9,0.4,1.9,0.7,2.9l-8.2,1.8l-1.5,0.3l-0.8,0.2l-0.4,0.1c-0.1,0-0.1,0-0.2,0l-0.1-0.2l-0.6-1.5
		l-0.3-0.8l-0.2-0.5c-0.4-1.3-0.7-2.6-0.9-4l-0.7-4.6l3.1,3.5c1,1.2,1.4,2.7,1.3,4.2c-0.1,1.4-0.5,2.8-1.3,4
		c-0.9,1.2-2.1,2.1-3.5,2.7c-1.2,0.5-2.5,0.8-3.7,1.1l-7.4,1.9c-1.4,0.4-2.7,0.7-4.1,0.8c-0.4,0-0.8,0-1.2,0c-0.2,0-0.4,0-0.6-0.1
		l0,0c-0.1,0-0.1,0-0.2-0.1l0,0l0,0v0.1c0,0,0,0.1,0,0.1v0.2c0,0.2,0,0.5,0,0.7c-0.1,1.9-0.7,3.7-1.7,5.3c-0.6,0.9-1.6,1.6-2.6,2
		c-1.1,0.4-2.3,0.4-3.4,0c-2-0.8-3.6-2.4-4.2-4.5l3.3-1c0.4,1.3,0.5,2.7,0.3,4.1c0,0.2,0,0.3-0.1,0.5c0,0.2,0,0.3-0.1,0.6
		c-0.1,0.5-0.3,1.1-0.5,1.6c-0.2,0.5-0.2,1.3-0.9,1.1c-0.6,0-1.1,0-1.7,0l-9.1-0.2c0.2-8.3,0.4-16.9,0.6-25.6
		c0.1-5,0.3-9.9,0.4-14.7h3.6c0,7.6-0.2,14.9-0.7,22c-0.1,1.8-0.3,3.5-0.4,5.3l-0.2,2.6l-0.1,1.3l-0.1,1l-0.1,1.2
		c0.1,0.5-0.5,0.5-0.8,0.7s-0.8,0.3-1.2,0.4c-0.3,0.1-0.6,0.2-0.9,0.2c-0.3,0.1-0.5,0.1-0.8,0.1c-0.6,0.1-1.2,0.1-1.8-0.1
		c-0.6-0.1-1.3-0.4-1.8-0.8c-0.5-0.4-0.9-0.8-1.2-1.3c-0.2-0.2-0.3-0.5-0.4-0.8l-0.1-0.3l0,0c0-0.1,0-0.2,0-0.1H343
		c-0.3,0-0.5,0-0.8-0.1c-0.7-0.2-1.3-0.5-1.8-1c-0.5-0.4-0.8-0.9-1.1-1.5c-0.2-0.5-0.4-1-0.5-1.5c-0.3-1.8-0.4-3.6-0.2-5.4l3,0.5
		l-1.2,4.2l-0.6,2.1l-0.3,1.1c-0.1,0.6-0.3,1.2-0.6,1.8l-0.2,0.4l-0.1,0.2h-0.2l-0.9,0.1c-0.6,0.1-1.2,0-1.8-0.2
		c-0.3-0.1-0.6-0.2-0.9-0.4c-0.6-0.3-1.2-0.8-1.6-1.3c-1.1-1.2-1.8-2.8-2-4.4l2.8,0.5c-0.6,1.3-1.1,2.6-1.7,3.8l-6.4-3.7
		c-0.1-0.8-0.2-1.7-0.3-2.5v-0.7h-0.9l-3.1,0.1l-0.2-2.6v-0.2c0-0.1,0,0,0,0v0.1v0.2v0.2c0,0-0.1,0-0.1,0.1
		c-0.2,0.1-0.4,0.2-0.6,0.2h-0.1h-0.3c-0.2,0-0.3,0-0.5,0.1c-0.3,0-0.7,0-1,0c-0.2,0-0.3,0-0.5-0.1c-0.2,0-0.4-0.1-0.6-0.2
		c-0.4-0.1-0.8-0.3-1.2-0.5c-0.7-0.4-1.3-0.9-1.9-1.4c-0.3-0.1,0-0.3,0.1-0.4l0.4-0.4c0.2-0.3,0.4-0.5,0.7-0.8s0.6-0.6,0.8-0.9
		l0.3-0.3l0.3-0.3c0.7-0.7,1.3-1.4,1.9-2.1c2-2.4,3.8-5,5.4-7.7c2.6-4.5,3.6-7.2,3.9-7.1s-0.5,2.9-2.8,7.6c-1.4,2.9-3.1,5.7-5.1,8.3
		c-0.6,0.8-1.2,1.5-1.9,2.3l-0.7,0.8l-0.2,0.3l-0.1,0.1c0,0-0.1,0.1,0,0.1c0.2,0.1,0.4,0.3,0.6,0.3c0.1,0,0.2,0.1,0.3,0.1h0.2
		c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0,0.7,0h0.3h0.2l0.3-0.1c1-0.5,2-1.1,2.9-1.8l0.1,0.2c0.1,0.1,0.1,0.2,0.1,0.3l0.1,0.9
		c0.1,0.5,0.1,1.1,0.2,1.7l0.1,0.5h0.3h0.5l3.2-0.2c0.1,1,0.2,2,0.4,3l0.1,0.9v0.3l0.2,0.1l0.4,0.2l1.1,0.6l0.5,0.3l0.1,0.1l0.3,0.1
		l0.4-1.1l1.9-4.8l0.7,5.2c0.1,1.1,0.6,2,1.3,2.8c0.2,0.2,0.4,0.3,0.5,0.5c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0.1,0,0.1,0
		c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.1-0.1c0.7-2.4,1.3-4.9,2-7.5l3.8-14.2l-0.8,14.7c-0.2,1.5-0.1,3,0.2,4.5c0.1,0.3,0.2,0.6,0.3,0.9
		c0.1,0.2,0.2,0.4,0.4,0.5c0.1,0.1,0.3,0.2,0.4,0.2l0,0v-0.1v-0.1c0,0,0,0,0.1-0.1l0.1-0.1l0.4-0.6c1-1.5,2-2.9,3-4.3
		c-0.2,2.2-0.3,4.4-0.5,6.7c0,0.2,0,0.4,0.1,0.6c0,0.1,0,0.1,0,0.2l0,0.1c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.3,0.4,0.5,0.5
		c0.4,0.2,0.8,0.3,1.2,0.2l0.5-0.1l0.4-0.1c0,0,0.1,0,0.1,0c0.1-1.4,0.2-2.8,0.4-4.2c0.1-1.7,0.3-3.5,0.4-5.2
		c0.4-7,0.6-14.3,0.6-21.8h3.6c-0.1,4.8-0.2,9.7-0.4,14.7c-0.2,7.5-0.4,14.9-0.6,22.1l0,0l1.8,0l3.3,0.1l0,0l0,0c0,0,0-0.2,0-0.3
		s0-0.2,0-0.3c0.1-0.9,0-1.9-0.2-2.8l3.3-1c0.3,1,1.1,1.9,2.1,2.3c0.8,0.3,1.8,0,2.2-0.8c0.7-1,1.1-2.2,1.1-3.4c0-0.2,0-0.3,0-0.5
		c0-0.1,0-0.3,0-0.4l-0.1-0.6l-0.1-0.6l-0.3-0.7c-0.2-0.5-0.3-0.9-0.5-1.4c-0.4-1-0.9-2.1-1.3-3.1l-0.5-1.3l-0.4-1.1l3.5,3.1
		l0.8,0.7l0.7,0.6l0.6,0.5c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.1c0.3,0.2,0.7,0.3,1,0.3l0.4,0c0.3,0,0.5,0,0.8,0c1.2-0.1,2.4-0.3,3.6-0.7
		l7.4-1.8c1.1-0.2,2.2-0.5,3.3-0.9c0.9-0.3,1.6-0.9,2.2-1.6c0.5-0.8,0.8-1.6,0.8-2.5c0.1-0.8-0.1-1.5-0.5-2.2l2.4-1.1
		c0.2,1.2,0.4,2.5,0.7,3.7l0.2,0.8c0,0.1,0.1,0,0.2,0l0.4-0.1l3.6-0.7l1.7-0.3l0.6-0.1l-0.1-0.8l-0.4-1.6c-0.3-1.2-0.5-2.3-0.8-3.3
		c-0.5-2.2-1.1-4.2-1.6-6.1c-1.1-3.8-2.1-7-3-9.6C390.9,665.2,389.8,662.4,390,662.3z"
          />
          <path
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            class="st2"
            d="M176.8,453.7c-1-0.3-1.2-2.5-0.4-5s2.3-4.1,3.2-3.8s0.9,2.5,0.2,4.9S177.7,453.9,176.8,453.7z"
          />
          <ellipse class="st2" cx="48" cy="167" rx="1.1" ry="1" />
        </g>
      </svg>
    </div>
  );
}
//{/* <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
//viewBox="0 0 490 724" style="enable-background:new 0 0 490 724;" xml:space="preserve">
//{/* <style type="text/css">
// .st0{fill:none;stroke:#231F20;stroke-width:4;stroke-miterlimit:10;}
// .st1{fill:#ffffff;}
// .st2{fill:#231F20;}
// .st3{fill:none;}
//</style> */}
//<g id="Layer_2">

//</g>
//</svg> */}
