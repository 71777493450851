import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import swal from "sweetalert";
import ColoredInImage from "../Images/ColoredInImage";
import {saveSvgAsPng} from 'save-svg-as-png';
import { API_URL } from "../../GlobalConfig";
import LoadingSpeaner from "../LoadingSpeaner";

function FeaturedImage() {
  const history = useHistory();
  let location = useLocation();

  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history.push("/login");
    }
  }, [])

  // console.log("image id", location.state.image_id)

  const deleteImage = () => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this image!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        setLoading(true)
        const formData = new FormData()
        formData.append('image_id', location.state.image_id)
    
        fetch(`${API_URL}/delete_image`, {
          method: 'POST',
          headers: {'Content-Type': 'multipart/form-data'},
          headers: {'Authorization': `Bearer ${token}`},
          body: formData
        })
        .then((response) =>
           response.json()
        )
        .then((res) => {
          if(res.length >= 0) {
            setLoading(false)
            swal("Your image has been deleted!", {
              icon: "success",
            });
            history.push('/mygallery')
          }
          else{
            return (
              <h1>No image for delete</h1>
            )
          }
        })
      }
    })
  }

  if(loading === true) {
    return(
      <LoadingSpeaner />
    )
  }

  const backToGallery = () => {
    history.push('/mygallery')
  }
  
    const saveImage = () => {
      saveSvgAsPng(document.getElementById("svg-image"), "myimage.png", {scale: 12});
      swal("Download Complete", "This image has been saved to your Downloads", "success");
    }

  return (
    <div className="container m-auto">
      <div className="row">
        <div className="col-md-8 col-sm-12 mt-3 mb-3">
          <ColoredInImage
            size="large"
            component={location.state.component}
            onFill={() => {}}
            fillColors={JSON.parse(location.state.fill_colors)}
          />
        </div>
        <div className="col-md-4 col-sm-12 mt-5">
          <button onClick = {saveImage} className="btn btn-outline-primary col-12 m-1">Download</button>
              <Link to={{
                pathname: `/colorpage/${location.state.id}/${location.state.component}`,
                state: location.state
                }}>
                <button className="btn btn-outline-primary col-12 m-1">Edit</button>
              </Link>
          <button onClick = {backToGallery} className="btn btn-outline-primary col-12 m-1">Back to My images</button>
          <button onClick = {deleteImage} className="btn btn-outline-primary col-12 m-1">Delete</button>
        </div>
      </div>
    </div>
  );
}

export default FeaturedImage;
