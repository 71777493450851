import React from 'react';
import './Saguaro.css';

export default function Saguaro(props) {
    
    return (
		<div className={`saguaro-container ${props.size}`}>
			<svg  xmlns="http://www.w3.org/2000/svg" id={props.id}>
			<g className="saguaro-image">
	
			<path d="M1162.5,1422.54c0,25.65,0,51.31,0,76.96c-387.19,0-774.38,0-1161.56,0c0-499.75,0-999.5,0-1499.25
				c387.19,0,774.38,0,1161.56,0c0,467.43,0,934.87,0,1402.3c-0.37-2.34-1.05-4.68-1.06-7.02c-0.09-17.62-0.05-35.25-0.05-52.88
				c-5.13,0.1-9.01,1.36-12.39,3.91c-2.59-1.73-8.48-1.45-11.49,0.55c-4.7,3.12-11.09,1.29-13.34-3.79
				c-0.29-0.65-0.72-1.24-0.47-0.83c-3.03-0.16-4.99-0.5-6.87-0.27c-1.12,0.14-2.44,0.93-3.16,1.84c-3.87,4.85-6.43,11.67-14.87,8.99
				c-0.71-0.23-1.65-0.1-2.38,0.16c-4.41,1.54-8.9,2.92-13.16,4.81c-5.16,2.28-6.86,2.08-11.16-2.36c-0.52-0.53-1.63-1.19-2.09-0.97
				c-3.9,1.81-6-1.5-8.88-2.75c-1.72-0.74-5.08-1.2-5.7-0.33c-2.32,3.21-4.87,6.89-5.36,10.64c-0.73,5.59-0.65,6.18-6.85,5.14
				c-0.97,5.77-2,6.37-8.6,4.4c0.48,1.64,0.53,3.21,1.31,4.19c2.95,3.74,2.05,6.76-2.65,7.88c-1.52,0.36-3.13,0.35-5.98,0.63
				c1.51,1.78,2.21,3.14,3.32,3.78c1.79,1.04,2.66,1.57,2.66,4.3c0,1.94,0.5,6.44,5.05,6.91c2.26,0.24,4.37,1.94,6.55,2.98
				c-1.41,2.36-2.83,4.73-4.24,7.09c0.63-2.31,1.26-4.62,1.89-6.93c-3.03-0.98-6.68-1.23-8.9-3.15c-2.12-1.83-2.75-5.39-4.44-9.09
				c-0.01-0.01-1.49-0.31-2.28-1.22c-4.47-5.1-10.01-4.49-12.97,1.56c-1.14,2.33-6.31,3.69-7.65,1.76c-1.81-2.6-6.21-3.26-5.71-7.7
				c0.07-0.62-1.61-1.45-2.55-2.23c-1.42,3.2-2.64,5.66-3.64,8.22c-2.08,5.33,4.4,2.53,5.4,6.53c-2.02,1.11-5.48,4.07-6.51,3.38
				c-3.39-2.27-5.9-5.85-8.72-8.92c-5.96,6.7-5.96,6.7,0.67,10.36c-0.73,2.22-1.5,4.55-2.27,6.89c-1.61-0.78-3.21-1.56-4.62-2.25
				c-0.76,1.63-1.53,3.61-2.56,5.44c-1.32,2.35-2.73,4.42,1.38,5.53c0.79,0.21,1.88,2.37,1.57,3.05c-0.96,2.13-2.53,3.98-4.25,6.5
				c0.14,0.34,1.19,1.71,1.12,3.03c-0.06,1.11-1.42,2.15-2.2,3.22c-2.6-2.31-8.32,0.21-8.69-5.65c-0.02-0.38-1.03-0.7-0.53-0.38
				c-2.53,1.22-4.35,2.09-6.57,3.16c0.04,0.25-0.03,2.26,0.4,2.37c6.25,1.63,4.39,7.64,6.49,11.46c2,3.63,2.49,6.34-1.25,9.17
				c-1.2,0.9-1.7,3.17-1.82,4.85c-0.04,0.59,1.98,1.52,3.19,1.94c3.36,1.17,6.78,2.15,10.1,3.18c2.39-4.12,8.85-5.4,11.18-3.05
				c1.81-1.19,3.47-2.38,5.23-3.39c1.28-0.74,3.64-0.99,3.9-1.91c1.87-6.65,4.92-3.01,8.2-0.89c-1.49-5.24,2.59-11.7-6.87-15.05
				c5.15-2.88,8.51-4.77,12.1-6.78c-0.41-0.52-1-1.08-1.37-1.76c-0.79-1.47-2.47-3.71-2-4.36c1.12-1.58,3.19-3.17,5.02-3.37
				c2.01-0.22,4.2,1.09,6.31,1.76c1.46,0.47,2.92,1.46,4.35,1.4c5.19-0.24,9.4-4.6,11.07-7.7c2.3-4.26,6.82-5.21,8.82-8.98
				c-0.79,1.75-1.22,3.83-2.44,5.19c-3.3,3.7-6.9,7.12-10.45,10.58c-0.64,0.62-1.56,1.04-2.42,1.33c-4.28,1.41-8.62,3.07-12.4-1.17
				c-0.03-0.04-0.69,0.48-1.08,0.75c5.98,5.97,5.98,5.97,1.97,11.03c0.41,4.41,0.55,7.38,0.99,10.3c0.63,4.18-1.16,5.86-5.25,4.97
				c-1.19-0.26-3.52-0.05-3.69,0.47c-1.87,5.73-4.55,3.92-6.9,1.31c-1.62,1.32-3.07,2.34-4.31,3.57c-0.82,0.81-1.68,1.88-1.84,2.94
				c-0.09,0.57,1.13,1.65,1.97,1.99c2.11,0.85,4.35,1.42,6.87,2.2c-0.5,0.99-1.08,2.12-1.66,3.26c0.2,0.44,0.4,0.88,0.6,1.32
				c1.72-0.64,3.86-0.85,5.06-2.02c2.62-2.58,4.19-1.02,5.63,0.97c0.69,0.96,0.73,2.38,1.07,3.59c0.29-0.3,0.59-0.59,0.88-0.89
				c2.3,1.69,4.61,3.37,6.91,5.06c-0.43,0.59-0.86,1.17-1.3,1.76c-2.18-4.75-10.59-0.93-10.23-7.74c-3.07,0.26-6.8,1.76-8.67,0.5
				c-4.1-2.76-7.26-6.92-10.68-10.39c-2.09,4.75-2.47,4.87-5.82,1.99c-1.5,0.64-2.82,1.04-3.97,1.73c-1.65,0.99-4.39,2.05-4.52,3.31
				c-0.51,4.76-3.09,3.14-5.39,2.37c-2.94,1.78-3.38,10.32-9.26,2.44c-0.81,2.56-1.43,4.53-1.91,6.04
				c6.14,6.33,11.99,12.47,18.01,18.45c0.93,0.93,2.62,1.55,3.95,1.56c24.13,0.09,48.25,0.07,72.35,0.07
				c0.93-8.18,4.65-13.69,9.79-14.91c4.83-1.15,9.84-1.88,14.39-3.74c7.2-2.94,14.97-4.79,19.63-12.52
				c1.61-2.67,6.82-3.36,10.53-4.48c2-0.61,4.44,0.23,6.41-0.43c1.55-0.52,3.47-2.1,3.87-3.57c1.96-7.19,1.8-7.21,9.32-7.08
				c2.75,0.05,6.13-0.08,5.79-3.72c-0.61-6.43,4-7.02,8.13-8.43c-1.5,1.16-3.09,2.22-4.47,3.51c-0.88,0.82-1.96,1.94-2.05,2.99
				c-0.48,5.98-2.49,8.18-8.17,7.26c-4.7-0.76-6.52,1.11-6.79,5.4c-0.21,3.45-2.34,5-5.45,5.41c-2.13,0.28-4.3,0.35-6.45,0.36
				c-5.19,0.03-9.39,1.5-11.67,6.74c-0.6,1.38-2.09,2.72-3.49,3.36c-6.18,2.79-12.37,5.63-18.77,7.82
				c-5.24,1.79-10.42,3.53-14.01,7.89c-1.43,1.74-1.87,4.29-2.76,6.47c1.95,0.55,3.91,1.58,5.86,1.59
				c31.25,0.1,62.49,0.08,93.74,0.06c1.61,0,3.22-0.17,5.06-0.28c0-23.39,0-46.41,0-69.4c-5.79,1.04-11.38,2.05-16.5,2.97
				c-1.28,2.45-2.71,5.17-4.13,7.89c0.31-2.41,0.63-4.81,0.94-7.22c0.37-2.9,1.24-4.6,4.86-3.46c1.8,0.57,4.13-0.06,6.09-0.63
				c2.04-0.59,3.92-1.77,7.05-3.25C1159.71,1424.8,1161.1,1423.67,1162.5,1422.54z M1123.55,935.83c-6.68,4.8-15.5,6.87-19.75,14.76
				c0.4,0.49,0.79,0.98,1.19,1.46c6.16-5.44,12.31-10.87,18.47-16.31c4.42-3.71,8.84-7.42,13.26-11.13c-0.23-0.27-0.46-0.53-0.69-0.8
				C1131.88,927.82,1127.72,931.83,1123.55,935.83z M1009.34,1435.23c-2.71,1.2-5.42,2.41-8.12,3.61c0.11,0.42,0.22,0.85,0.33,1.27
				c6.24,0.99,3.48,5.5,3.37,8.84c2.64,0.48,4.95,0.91,6.88,1.26c0.13-2.74,0.26-5.22,0.38-7.71c-0.27,0.09-0.54,0.19-0.81,0.28
				c-0.69-2.53-1.37-5.07-2.06-7.6c1.74-0.58,3.47-1.16,5.64-1.89c-2.67-3.04-4.58-5.21-6.42-7.3c-0.83,0.63-1.71,1.3-2.5,1.9
				C1007.66,1430.47,1013.18,1430.79,1009.34,1435.23z M174.92,1254.72c0.64,2.83,1.73,5.64,1.84,8.48c0.26,7.24,0.09,14.5,0.09,22
				c3.1,0,5.54,0,7.64,0c-1.77-11.45-3.54-22.94-5.5-35.67c-1.85,2.5-2.89,3.9-3.94,5.31c-2.56-0.21-5.12-0.42-7.35-0.61
				c0.65,10.88,1.31,22,1.97,33.12c0.83-0.06,1.66-0.12,2.49-0.18c-0.69-10.23-1.37-20.45-2.06-30.68c0.61-0.05,1.21-0.11,1.82-0.16
				c0.87,8.9,1.73,17.8,2.6,26.71c0.13,0,0.26,0.01,0.39,0.01C174.92,1273.6,174.92,1264.16,174.92,1254.72z M616.24,1138.71
				c-1.04-1.35-2.08-2.71-3.11-4.06c3.41,1.85,6.91,3.55,10.2,5.58c2.77,1.72,5.31,3.82,7.94,5.75c0.22-1.3-0.08-1.89-0.47-2.4
				c-0.71-0.92-1.37-1.92-2.23-2.68c-13.29-11.75-22.7-26.38-31.53-41.54c-2.35-4.03-5.71-8.65-9.71-10.28
				c-7.44-3.03-10.6-8.82-14.28-15.01c-3.61-6.07-5.11-14.49-14.4-15.49c-1.66-0.18-3.26-0.93-4.89-1.42
				c-0.17,1.64-0.75,3.37-0.44,4.92c1.79,8.79,4.19,17.44,2.38,26.6c-1.81,9.14-4.74,12.15-14.1,13.16
				c-7.35,0.79-14.71,1.51-22.07,2.26c-0.27,0.48-0.53,0.97-0.8,1.45c2.28,2.15,4.34,4.62,6.9,6.38c3.27,2.25,7.08,3.7,10.4,5.88
				c5.41,3.55,9.93,7.95,11.49,14.57c-1.65-1.57-2.85-3.49-4.27-5.23c-1.33-1.65-2.89-3.12-4.34-4.67
				c5.41,14.81,6.46,29.32,2.86,44.45c-2.83,11.9-4.93,24.05-2.27,35.09c1.39,0.81,2.35,1.04,2.46,1.49
				c0.91,3.67,1.74,7.36,2.44,11.07c0.77,4.07,0.9,8.32,2.14,12.23c3.3,10.38,8.87,19.44,15.71,28.01
				c11.37,14.25,20.62,30.35,35.9,39.4c0.31,2.69,0.23,4.42,0.75,5.94c1.34,3.92,3.57,7.64,4.33,11.65
				c2.54,13.54,5.63,27.11,6.57,40.8c1.22,17.76,3.8,35.99-5.31,52.86c-0.1,0.19,0.18,0.58,0.58,1.79c2.16-1.29,5.3-2.04,5.95-3.74
				c3.15-8.3,7.96-16.88,7.88-25.32c-0.16-17.22-2.67-34.46-4.99-51.59c-2.09-15.45-5.58-30.59-19.01-40.94
				c-0.35-0.27-0.33-1.03-0.59-1.92c9.35,4.73,16.99,15.63,18.88,27.78c2.6,16.73,4.52,33.57,7.07,50.31
				c2.02,13.32,1.2,26.03-4.94,38.34c-2.34,4.69-5.01,8.04-10.23,9.16c-2.09,0.45-4.26,1.06-6.06,2.15c-2.78,1.68-5.2,3.88-4.51,8.82
				c12.7-5.44,24.84-10.63,36.49-15.62c1.84-5.86,3.82-11.49,5.33-17.23c1.2-4.57,2.87-9.37,2.52-13.92
				c-1.29-16.92,0.52-33.55,3.08-50.19c1.42-9.21,4.84-18.49-0.35-27.66c-0.07-0.12,0.95-0.85,1.46-1.29c0.75,1.5,1.49,3,2.24,4.5
				c-0.02,3.94,1.88,6.02,5.86,6.45c6.1,0.65,12.35,2.79,18.24,2c20.71-2.77,41.3-6.48,61.89-10.03c1.09-0.19,2.18-2.08,2.71-3.4
				c1-2.51,1.59-5.18,2.35-7.78c0.53,0.16,1.06,0.31,1.58,0.47c-0.16,1.08-0.25,2.17-0.5,3.23c-1.94,8.19-1.43,16.04,2.24,23.81
				c8.05,17.08,19.2,31.36,35.11,41.91c6.34,4.2,12.3,9.14,17.82,14.38c6.31,5.99,9.69,13.7,8.25,22.56
				c-1.55,9.58-4.68,18.68-10.59,26.02c0.82,1.95,1.58,3.74,1.95,4.64c-2.38,1.17-4.21,2.06-6.04,2.96c1.8,1.34,3.44,3.14,5.47,3.87
				c1.49,0.54,3.99,0.28,5.17-0.68c3.43-2.8,7.6-5.59,9.38-9.37c6.07-12.91,11.61-26.1,16.6-39.47c2.92-7.81,1.12-10.62-5.22-15.94
				c-4.67-3.92-9.15-8.23-12.99-12.96c-4.52-5.57-6.16-12.44-3.68-19.3c2.21-6.13,5.27-12.03,8.56-17.68
				c2.6-4.47,4.27-9.32,7.84-13.53c3.11-3.67,3.79-9.86,4.39-15.08c0.6-5.21,0.13-10.64-0.56-15.88c-0.62-4.79-2.16-9.46-3.29-14.18
				c5.74,9.22,7.78,19.17,6.86,29.84c-1.07,12.33-7.69,22.1-14.65,31.54c-9.94,13.48-9.27,27.88,2.68,39.46
				c2.26,2.19,4.83,4.07,7.36,5.95c5.81,4.33,8.47,9.84,6.08,16.97c-2.33,6.92-4.63,13.9-7.62,20.54c-2.47,5.47-3,10.66-0.81,16.56
				c0.42-1.64,0.75-2.92,1.46-5.65c0.67,2.63,0.97,3.8,1.49,5.82c0.69-1.89,1.17-3.23,1.66-4.57c0.51,0.43,1.03,0.86,1.54,1.29
				c-0.16,1.57-0.33,3.14-0.49,4.71c0.45,0.12,0.9,0.23,1.35,0.35c2.48-6.47,4.95-12.94,7.4-19.34c1.29-1.41,2.84-2.91,4.18-4.59
				c6.99-8.73,4.66-20.31,9.04-30.01c2.2-4.88,2.05-11.07,2.95-16.58c2.01-12.28,1.97-24.98,1.85-37.49
				c-0.07-6.91-3.17-13.86-2.79-20.68c0.48-8.62-2.1-15.91-5.62-23.26c-0.08-0.18,0.22-0.54,0.51-1.19c1.06,0.99,2.01,1.88,2.96,2.77
				c0.32-0.25,0.64-0.49,0.96-0.74c-3.7-5.5-7.4-11-11.42-16.99c1.61,0.46,2.61,0.75,3.6,1.04c-3.27-4.37-7.45-7.56-11.12-11.26
				c-11.93-12.04-26.63-18.46-42.91-21.89c-16.89-3.56-33.87-6.79-50.62-10.91c-13.68-3.36-27.1-7.75-40.6-11.79
				c-3.17-0.95-6.2-2.37-9.32-3.49c-7.18-2.58-14.26-5.54-21.59-7.54c-8.24-2.25-16.32-4.58-23.22-9.86
				c-11.59-8.87-22.51-18.61-36.31-24.36c-5.99-2.5-10.74-7.4-12.12-14.36c-0.71-3.57-0.89-7.2,2.74-9.64
				c-2.06,11.16-0.16,16.3,9.81,21.91c6.3,3.55,13.2,6.26,20.13,8.4c4.38,1.36,9.38,1.8,14.65-1.12c-1.91-1.78-3.3-3.31-4.91-4.54
				C627.42,1147.01,621.82,1142.88,616.24,1138.71z M1160.68,1022.44c-0.03-0.91-0.05-1.82-0.08-2.73c0.26-1.78,0.74-3.57,0.75-5.35
				c0.08-8.99,0.04-17.99,0.04-26.98c0-5.24,0-10.48,0-16.74c-3.92,3.26-6.75,6.06-10,8.23c-11.74,7.83-23.47,15.72-35.52,23.05
				c-7.21,4.39-14.77,8.47-22.65,11.41c-5.38,2.01-8.29,4.85-9.24,10.75c15.3-2.05,28.95-8.65,43.01-14.04
				c-13.57,9.07-26.25,19.98-44.05,19.48c9.22-2.31,18.52-4.42,27.49-10.39c-8.9,2.46-16.66,4.67-24.46,6.73
				c-3.09,0.82-9.73,8.81-8.34,10.97c2.66,4.13,6.75,1.64,10.29,1.06c1.12-0.18,2.18-0.77,3.28-1.15c5.47-1.91,10.82-4.37,16.44-5.61
				c10.76-2.36,20.34-7.03,29.37-13.13c3.44-2.33,7.07-4.38,10.61-6.56c-18.43,18.18-43.59,22.97-65.92,33.73
				c3.16,0.68,6.08,0.69,8.95,0.28c2.45-0.35,4.84-1.12,7.23-1.8c10-2.83,20.26-4.99,29.91-8.74c11.25-4.36,21.96-10.11,32.9-15.26
				c-2.03,1.7-3.88,3.7-6.11,5.06c-16.75,10.18-35.25,15.91-54.02,20.81c-4.94,1.29-10.08,1.94-15.17,2.4
				c-1.89,0.17-3.91-1.05-5.87-1.64c0.71-1.73,1.42-3.45,2.06-5.01c-1.71-0.54-3.84-1.21-5.53-1.75c-1.95,1.76-3.63,3.65-5.67,5.03
				c-3.02,2.04-4.07,4.75-3.5,8.15c0.6,3.6,2.66,5.83,6.28,6.93c7.82,2.39,15.73,2.99,23.58,0.84c9.76-2.68,19.37-5.91,29.04-8.9
				c-0.14-0.44-0.29-0.89-0.43-1.33c-0.31-0.06-0.65-0.24-0.92-0.17c-11.83,3.17-23.62,6.44-35.49,9.44
				c-1.46,0.37-4.03-0.22-4.72-1.27c-0.66-1,0.08-3.43,0.93-4.76c0.82-1.28,2.45-2.33,3.94-2.84c4.54-1.54,9.13-3.08,13.81-4.08
				c17.29-3.66,34.06-8.61,49.11-18.26c2.49-1.59,5.04-3.08,7.57-4.61c-1.62,1.63-3.12,3.42-4.89,4.88
				c-9.44,7.79-20.86,11.53-32.24,15.1c-10.27,3.22-20.84,5.45-31.19,8.41c-2.19,0.63-4,2.55-5.99,3.88
				c0.26,0.54,0.52,1.09,0.78,1.63c1.36-0.2,2.75-0.29,4.09-0.62c7.25-1.76,14.43-3.89,21.74-5.32
				c18.17-3.56,33.28-12.81,46.95-24.79C1159.92,1025.94,1160.09,1023.94,1160.68,1022.44z M1159.72,953.39
				c-7.21,9.65-13.47,20.21-25.47,25.06c-2.56,1.03-5.11,2.65-7.04,4.61c-8.29,8.42-19.07,12.84-29.52,17.13
				c-6.5,2.66-8.76,6.28-9.59,12.62c1.21-0.3,2.19-0.42,3.07-0.78c23.31-9.71,43.93-23.93,64.16-38.73
				c6.74-4.93,7.99-11.77,5.13-20.49c0.38-2.17,0.75-4.34,1.16-6.69c-0.84,0.1-1.09,0.06-1.15,0.15c-0.64,0.96-1.24,1.94-1.86,2.91
				c-4.84,7.58-9.68,15.26-17.83,19.76c-14.51,8.01-29.1,15.87-43.76,23.6c-3.12,1.65-5.94,2.98-4.76,8.23
				c10.91-5.28,21.88-9.64,31.36-17.04c3.89-3.04,8.33-5.36,12.25-8.36c4.67-3.58,9.17-7.4,13.51-11.38
				C1153,960.63,1156.28,956.93,1159.72,953.39z M31.15,1324.38c-1.45-3.77-2.89-7.55-4.34-11.32c-0.34,0.15-0.68,0.29-1.02,0.44
				c1.66,3.73,3.32,7.46,4.98,11.19c1.9,8.96,6.43,17.87,0.24,28.22c-2.61-3.82-4.74-6.94-7.24-10.6c0.55,3.4,0.99,6.06,1.42,8.73
				c-0.35,0.06-0.7,0.11-1.05,0.17c-1.16-1.83-2.31-3.67-3.87-6.14c0.44,3.25,0.77,5.63,1.09,8.01c-0.39,0.11-0.77,0.23-1.16,0.34
				c-2.79-3.25-5.58-6.5-8.95-10.44c1.54,6.19,5.02,11.13,2.08,17.56c-2.93-5.51-5.54-10.4-8.14-15.3c-0.13,0.13-0.26,0.25-0.39,0.38
				c1.49,8.09,2.98,16.18,4.44,24.12c2.28-0.88,5.48-3.16,8.64-3.11c8.76,0.13,16.31-4.2,24.59-5.74c3.99-0.75,7.61-3.52,11.36-5.45
				c3.28-1.68,5.78-3.67,4.14-8.09c-0.63-1.69-0.26-3.74-0.37-6.29c-1.54,2.54-2.69,4.43-4.37,7.19c-0.45-6.29-0.82-11.46-1.19-16.64
				c-0.28,0.01-0.56,0.01-0.84,0.02c-0.24,4.53-0.48,9.07-0.72,13.6c-0.44-0.03-0.87-0.07-1.31-0.1c-0.78-5.5-1.56-11-2.34-16.5
				c-1.02,7.32,2.74,15-3.16,21.95c-1.73-6.71-3.34-12.99-4.95-19.27c-0.16,0.04-0.32,0.07-0.48,0.11
				c0.76,6.36,1.52,12.72,2.27,19.08c-0.42,0.06-0.84,0.13-1.26,0.19c-0.2-0.55-0.44-1.09-0.61-1.65
				C36.15,1340.83,33.65,1332.6,31.15,1324.38z M609.32,989.91c4.96-0.14,9.94-0.02,14.87-0.48c7.56-0.7,13.7,1.35,17.9,7.98
				c2.13,3.36,4.27,6.72,6.36,10.11c2.77,4.49,6.77,6.1,11.89,5.34c2.64-0.39,5.31-0.63,8.91-1.05c-2.09-2.81-3.74-5.39-5.75-7.65
				c-2.59-2.9-6.23-5.12-8.06-8.39c-2.71-4.87-4.27-10.39-6.25-15.65c-4.09-10.88-1.58-19.25,7.79-26.21
				c2.53-1.88,2.33-9.55-0.29-11.02c-5.21-2.92-12.86,0.88-13.42,6.82c-0.79,8.28-1.46,16.57-1.99,24.87
				c-0.41,6.48-1.91,8.24-8.53,9.48c-1.93,0.36-4.08,0.71-5.62,1.79c-4.07,2.85-8.42,3.65-13.24,3.22c-1.55-0.14-3.17,0.41-4.76,0.65
				c-2.43,1.55-5.62,2.58-7.14,4.76c-4.88,7.02-11.22,8.17-18.85,6.2c-1.74-0.45-3.57-0.57-5.36-0.83c-0.13,0.49-0.25,0.98-0.38,1.48
				c1.04,0.45,2.09,0.9,3.13,1.37c9.1,4.06,18.54,7.67,21.94,18.56c2.89,9.26,9.83,12.16,19.38,11.44c-1.26-2.63-2.16-5.31-3.68-7.57
				c-6.42-9.53-7.94-20.52-9.68-31.45C608.3,992.49,609.02,991.17,609.32,989.91z M535.33,1193.81c-4.65,0.81-7.91-1.47-11.34-4.32
				c-2.62-2.18-6.2-3.25-9.41-4.68c-1.82-0.81-4.12-0.9-5.56-2.09c-2.84-2.38-5.18-3.54-8.81-1.17c-2.46,1.61-7.45,3.65-8.31,2.71
				c-5.71-6.17-9.51-0.3-11.48,2.55c-3.09,4.48-4.62,3.45-6.65,0.37c-3.54,2.7-6.79,5.18-11.32,8.64c5.79,1.9,10.23,3.36,14.27,4.69
				c-1.02,2.02-1.61,3.31-2.31,4.54c-0.78,1.37-1.34,3.34-2.53,3.92c-4.29,2.07-3.61,4.36-0.48,7.08c-4.22,3.81-5.54,7.94-2.2,12.77
				c0.24,0.35,0.14,0.96,0.14,1.46c-0.06,5.12,2.95,7.55,7.65,9.39c5.52,2.16,10.45,5.73,16.75,2.84c0.33-0.15,1.07,0.65,1.65,0.96
				c8.84,4.79,18.12,2.38,27.11,1.01c2.53-0.39,4.66-4.16,6.72-6.59c0.87-1.03,1.71-2.74,1.48-3.93c-0.93-4.62-1.65-9.45-3.68-13.61
				c-1.76-3.6-4.84-8.64-7.93-9.11c-9.22-1.39-16.75-9.26-26.97-6.69c-1.58,0.4-4.06-1.86-5.73-3.34c-0.43-0.38,0.32-3.29,1.11-3.62
				c2.59-1.09,5.5-2.19,8.21-2.02c3.08,0.19,6.08,1.7,9.09,2.71c2.71,0.91,5.38,1.94,8.08,2.87c0.87,0.3,1.77,0.51,2.67,0.68
				c6.97,1.26,14.22,1.64,19.58,7.34c0.86,0.91,2.43,1.15,4.42,2.04c-1.59-6.4-2.97-11.96-4.36-17.53c0.43-0.64,1.15-1.24,1.24-1.93
				c1.59-11.32,3.19-22.64,4.61-33.99c1.58-12.66-0.32-24.76-6.29-36.13c-1.92-3.65-4.97-5.46-10.01-5.99
				c-0.41,21.37-0.83,42.39-1.19,63.42c-0.03,1.81,0.27,3.64,0.6,5.43c0.83,4.4,3.48,6.71,8.02,7.15
				C533.28,1191.75,534.28,1193.06,535.33,1193.81z M157.5,627.72c-6.26,8.71-11.67,18.17-22.43,22.51c-2.33,0.94-4.23,3.23-6,5.19
				c-16.17,17.89-32.27,35.84-48.4,53.78c-1.31,1.46-2.63,2.9-3.91,4.31c-8.82-6.26-8.44-3.31-14.1,2.1
				c-0.6,0.57-1.17,1.21-1.61,1.91c-3.93,6.23-9.26,9.3-16.78,7.7c-3.51-0.75-6.01,0.72-8.06,3.58c-1.11,1.56-2.81,3.87-4.2,3.83
				c-4.79-0.12-6.84,3.2-8.9,6.27c-2.62,3.9-5.27,4.01-8.59,1.18c-1.9-1.61-3.74-3.34-5.83-4.68c-1.81-1.16-3.92-1.85-6.37-2.96
				c0,17.12,0,33.18,0,50.3c3.67-2.72,6.68-4.96,9.69-7.19c8.79-6.52,17.06-13.95,26.49-19.33c11.64-6.65,22.7-13.85,32.53-22.91
				c7.58-6.99,14.36-15.1,22.75-20.91c11.48-7.94,19.2-18.56,26.75-29.8c8.8-13.1,18.37-25.67,27.41-38.61
				c3.58-5.13,6.64-10.62,9.94-15.95c4.04-5.55,8.07-11.1,12.11-16.66c-0.42-0.29-0.84-0.57-1.26-0.86
				C164.98,616.26,161.24,621.99,157.5,627.72z M955.72,868.74c0.35-6.05,0.98-12.11,0.89-18.16c-0.03-1.83-1.6-4.31-3.21-5.34
				c-15.98-10.29-28.82-23.51-37.92-40.23c-4.06-7.46-7.74-15.12-11.61-22.72c-0.68,1.54-1.37,3.11-2.65,6.01
				c-2.82-6.79-5.96-12.21-7.34-18.04c-6.23-26.31-12.01-52.72-17.8-79.13c-0.48-2.17,0.13-4.57,0.23-6.87
				c0.6-0.07,1.21-0.14,1.81-0.22c3.31,10.12,6.62,20.24,9.93,30.36c0.49-0.94,0.45-1.74,0.26-2.51
				c-4.78-19.49-9.89-38.9-14.26-58.48c-3.83-17.15-6.71-34.52-10.32-51.72c-0.74-3.52-2.86-6.75-4.47-10.39
				c-2.61,1.67-4.23,2.7-5.88,3.76c-1.24-1.14-2.26-2.09-3.93-3.64c0.56,5.41,1.05,9.86,1.47,14.31
				c2.89,30.04,4.76,60.17,10.93,89.84c2.9,13.95,4.29,28.21,6.5,42.31c2.47,15.72,4.77,31.47,7.78,47.09
				c2,10.38,5.11,20.55,7.73,30.82c-0.09,1.57-0.58,3.24-0.21,4.68c4.11,16.05,11.49,30.75,19.69,44.96
				c3.15,5.46,8.01,10.05,12.54,14.58c7.13,7.13,14.66,13.86,21.97,20.82c1.06,1.01,1.73,2.43,2.68,3.58c4,4.88,8.04,9.73,12.15,14.7
				C953.72,901.86,954.72,885.3,955.72,868.74z M31.58,1249.55c8.57,4.1,12.16,12.32,16.28,20.01c2.83,5.29,5.24,10.81,7.83,16.22
				c1.45-0.46,2.18-0.52,2.67-0.88c6.12-4.56,12.04-9.44,19.91-10.83c0.59-0.1,0.95-1.48,1.44-2.24c1.18-1.86,2.37-3.71,4.05-6.34
				c-3.6-5.24-7.97-11.59-12.33-17.94c0.36-0.25,0.72-0.5,1.08-0.75c9.7,8.12,19.4,16.25,29.1,24.37c-3.99-1.05-7.7-2.66-10.73-5.12
				c-3.45-2.8-6.28-6.35-9.39-9.58c0.83,3.23,2.4,5.72,4.12,8.12c1.66,2.31,1.41,3.77-1.38,4.96c-1.08,0.46-1.7,1.99-2.53,3.04
				c0.48,0.54,0.95,1.08,1.43,1.62c3.23-1.13,6.47-2.25,8.56-2.98c1.58,1.05,2.52,2.15,3.62,2.34c4.58,0.79,9.58,0.4,13.72,2.12
				c6.4,2.67,13.04-2.77,19.15,1.4c0.6,0.41,2.24-0.69,3.55-1.14c-0.45-0.95-0.84-1.52-0.98-2.14c-1.03-4.42-7.49-7.74-2.71-13.42
				c0.37-0.45-0.24-1.95-0.68-2.82c-3.71-7.42-7.41-14.84-11.3-22.16c-0.58-1.1-2.38-2.6-3.17-2.37c-4.11,1.19-4.93-1.42-6.46-4.26
				c-5.29-9.85-11.47-18.97-23.98-20.3c-5.23-0.56-10.77-2-13.95,4.64c-0.71,1.47-3.52,2.21-5.51,2.73
				c-5.77,1.5-11.62,2.72-17.29,4.02c6.76,6.13,12.77,11.59,18.77,17.05c-0.46,0.58-0.92,1.16-1.38,1.74
				c-7.18-4.8-14.37-9.6-22.4-14.97c-0.68,2.92-1.12,5.07-1.68,7.19c-0.44,1.67-0.72,3.51-1.62,4.92c-3.36,5.23-2.84,6.66,1.81,10.76
				c8.39,7.39,16.39,15.23,24.55,22.88c-0.37,0.46-0.74,0.93-1.1,1.39c-2.49-1.92-5.88-3.32-7.31-5.84
				c-4.11-7.24-10.31-12.06-16.81-16.72c-1.21-0.87-2.78-1.67-3.35-2.89c-0.85-1.84-1.57-4.12-1.26-6.04
				c0.63-3.96,1.85-7.85,3.08-11.68c0.79-2.45,1.49-6.28,3.16-6.86c7.08-2.5,13.1-8.04,21.35-7.46c1.89,0.14,4.18-1.41,5.83-2.74
				c1.85-1.49,3.2-3.61,4.47-5.11c-5.56,0-12.23,0.01-18.91,0c-3.91-0.01-6.8,1.1-8.8,5.03c-0.86,1.69-3.96,2.71-6.22,3.13
				c-10.09,1.89-20.25,3.43-30.37,5.17c-3.76,0.65-5.7,2.61-5.51,6.85c0.31,6.81,0.08,13.65,0.08,20.48c0,9.38,0.28,9.72,9.73,9.01
				c1.67-0.13,3.26-1.27,4.9-1.93c1.64-0.66,3.24-1.5,4.95-1.89c3.19-0.73,6.44-1.19,9.66-1.76c-2.39,1.22-4.72,2.57-7.18,3.61
				c-1.92,0.81-4.02,1.15-6,1.83c-2.25,0.78-4.39,2.12-6.68,2.46c-2.88,0.43-5.88,0.1-8.99,0.1c0,4.32,0,8.02,0,11.78
				c1.99,0.37,3.73,1.22,5.23,0.89c5.7-1.25,6.76,2.4,7.55,6.57c0.25,1.3,0.76,2.58,0.83,3.89c0.22,4.02,3.19,4.08,6,4.28
				c2.07,0.14,4.15,0.03,6.3,0.03c-2.06-5.58-4.48-12.15-7.15-19.4c6.39,0.97,9,3.36,9.03,8.83c0.02,2.74,1.55,5.58,2.84,8.16
				c1.73,3.46,3.88,6.72,5.85,10.06c-0.33,0.2-0.67,0.41-1,0.61c-2.33-3.32-4.66-6.64-7.77-11.07c0.5,3.57,0.78,5.6,1.06,7.63
				c-0.29,0.47-0.59,0.94-0.88,1.41c-3.8-5.36-8.72-5.01-14.27-3.74c-0.78-2.67-1.7-5.13-2.21-7.67c-1.52-7.53-1.46-7.54-8.98-7.86
				c-0.78-0.03-1.57,0-2.81,0c0,2.14,0,3.95,0,5.75c0,16.16,0.71,32.36-0.25,48.45c-0.62,10.4,3.3,18.01,10.09,25.07
				c-4.32-11.02-7.97-22.18-7.22-34.29c5.05,10.05,3.33,23.11,14.21,31.32c-1.43-5.09-2.68-9.52-3.92-13.94
				c0.34-0.14,0.68-0.28,1.03-0.42c1.11,1.41,2.22,2.81,3.8,4.82c-0.97-4.53-1.86-8.22-2.53-11.95c-0.64-3.55-1.04-7.14-1.54-10.71
				c0.46-0.1,0.91-0.19,1.37-0.29c3.62,11.12,3.93,23.68,13.72,32.56c-0.34-1.61-0.68-2.88-0.86-4.16c-0.39-2.79-2-6.58-0.78-8.18
				c2.71-3.55,0.9-6.24-0.17-9.06c-2.97-7.83-6.25-15.54-9.82-24.29c10.4,9.01,13.78,20.64,18.03,32.07
				c-1.52-11.34-3.03-22.67-4.55-34.01c0.2-0.07,0.4-0.13,0.6-0.2c0.81,1.75,1.63,3.5,2.87,6.16c2.14-10.79,10.49-15.1,17.85-20.94
				c-2.48-3.96-4.94-7.85-7.36-11.76C41.58,1265.82,36.59,1257.68,31.58,1249.55z M328.39,1477.59c2.11,0.16,4.21,0.31,4.9,0.37
				c2.38-2.64,3.74-5.37,5.86-6.21c3.18-1.26,6.52-0.63,8.06,3.48c0.22,0.59,2.41,1,3.34,0.63c2.13-0.85,4.64-1.79,5.9-3.5
				c1.9-2.58,6.45-4.84,3.66-8.9c-1.71-2.49-1.65-4.19-0.76-6.77c1.12-3.23,1.93-6.63-2.9-8.32c-1.22-0.43-2.46-3.35-2.21-4.88
				c0.57-3.48-0.92-4.7-3.79-5.84c-4.78-1.9-9.4-4.23-14.55-6.6c-0.23,0.12-1.09,0.62-1.97,1.06c-4.41,2.21-8.81,5.56-13.2-0.19
				c-0.38-0.49-1.85-0.26-2.8-0.19c-3.55,0.25-7.09,0.56-10.84,0.87c-1.79,5.32-3.83,6.25-10.3,4.12c-2.07,2.45-3.32,7.75-8.24,3.07
				c-0.32-0.31-2.63,0.55-2.71,1.06c-0.97,6.38-4.44,4.9-6.67,3.59c-5.43,0.84-9.44,1.59-13.48,1.96c-0.62,0.06-2.15-1.84-1.97-2.4
				c0.98-2.96-0.99-4.43-2.21-6.66c-3.57-6.49-6.17-7.47-12.13-3.25c-4.08,2.89-10.18,2.59-13.66-0.66
				c-3.42-3.2-8.61-2.45-11.48,1.25c-0.7,0.91-2.82,1.19-4.19,1.01c-2.76-0.37-5.42-1.39-8.17-1.91c-4.06-0.76-5.61,0.85-5.68,4.91
				c-0.02,1.42-0.8,3.19-1.83,4.16c-3.98,3.76-6.95,3.34-12.64-1.18c-1.15,1.44-2.43,2.74-3.35,4.25c-2.45,4.01-8.63,6.08-5.85,12.94
				c0.86,2.12-2.32,3.85-4.33,1.6c-2.39-2.67-4.06-6-6.35-9.52c-1.28,0-3.64,0-4.76,0c-0.83,3.26-1.68,5.74-2.05,8.28
				c-0.83,5.71-1.85,6.37-7.78,5.7c-1.37-0.15-3.68,0.97-4.26,2.16c-1.57,3.15-3.69,3.9-6.89,3.22c-4.84-1.02-8.29,1.58-9.19,6.31
				c-0.34,1.78-1.57,3.38-2.35,5.08c-0.33,0.72-0.6,1.48-0.84,2.24c-0.51,1.63-0.97,3.26-1.46,4.9c-0.55,1.39,0.38,3.36-0.17,4.71
				c-0.65,1.58-2.59,2.64-4.16,4.1c7.78,1.62,10.11-0.31,11.44-6.8c0.74-3.64,3.37-6.94,5.42-10.22c0.41-0.65,2.39-1.04,3.13-0.63
				c3.65,2.02,5.31-1.32,7.5-2.72c1.82-1.16,3.26-3.04,4.6-4.8c2.26-2.97,4.74-3.7,8.2-1.96c2.55,1.28,5.4,1.95,8.06,3.04
				c0.41,0.17,0.35,1.29,0.75,1.75c0.77,0.87,1.72,2.19,2.61,2.2c0.92,0.01,1.76-1.52,2.8-2c2.49-1.14,5.02-2.6,7.66-2.94
				c3.17-0.41,4.08,1.97,4.08,4.91c0,0.92,1.09,1.85,1.74,2.86c4.72-5.53,10.5-5.59,15.31-0.32c3.03-3.3,5.18-7.83,10.75-7.63
				c0.56,0.02,1.36-1.24,1.73-2.06c1.18-2.62,2.57-3.5,5.43-1.86c0.99,0.57,3.44-0.06,4.36-1c2.9-2.96,9.13-2.33,11.44,1.38
				c1.4,2.24,1.01,3.52-1.4,4.66c-1.86,0.88-3.48,2.27-5.96,3.95c8.58,4.3,10.05-5.28,15.37-5.82c1.87,5.34,4.23,5.97,8.79,2.83
				c1.43-0.99,3.36-2.23,4.85-2c6.99,1.07,7.75,2.17,8.27,9.4c0.05,0.73,0.48,1.44,0.67,1.98c2.94-1.55,6.35-4.8,8.22-4.07
				c5.36,2.08,8.19-0.62,11.52-3.59c3-2.67,7.3-2.25,7.22,0.57c-0.17,5.4,2.78,6.03,7.16,5.53c0.3,1.72,0.67,3.15,0.77,4.6
				c0.23,3.38,1.97,4.41,5.27,4.4c3.75-0.01,3.66-2.09,3.84-4.84c0.09-1.44,1.68-4.02,2.43-3.96c3.94,0.29,5.11-2.42,6.55-5.03
				L328.39,1477.59z M222.4,1179.51c2.75-3.84,1.45-7.59-0.55-11.08c-7.08-12.4-14.18-24.78-21.57-36.99
				c-1.38-2.28-3.88-4.74-6.3-5.33c-3.58-0.87-7.55-0.15-11.35-0.1c-1.43,7.18-5.17,3.26-8.16,1.56
				c-10.94-6.22-21.84-12.53-32.67-18.94c-2.41-1.42-5.06-2.97-6.65-5.15c-3.6-4.96-7.84-5.09-13.18-3.61
				c-4.29,1.18-8.89,1.98-13.3,1.78c-4.9-0.22-9.72-1.89-14.62-2.71c-4.21-0.7-7.61,0.37-10.32,4.23c-2.72,3.88-6.2,5.26-11.61,3.62
				c-12.65-3.85-25.39-8.39-38.99-6.32c-2.45,0.37-5.41,1.88-6.84,3.82c-6.39,8.69-12.32,17.71-21.75,23.58
				c-0.59,0.37-0.9,1.19-1.65,2.22c4.83,0.93,8.58,1.44,10.43-4.1c0.47-1.4,2.96-2.74,4.66-2.95c2.92-0.36,5.99-0.02,8.93,0.43
				c2.96,0.45,4.46,1.91,2.68,5.22c-1.25,2.32-2.42,4.86-2.77,7.42c-0.27,2,0.2,4.63,1.39,6.18c1.92,2.49,13.66,1.58,15.82-0.78
				c5.63-6.18,17.08-7.99,24.53-4.05c1.39,0.73,3.13,0.97,4.74,1.07c6.11,0.38,7.86,3.44,4.87,8.86c-0.88,1.6-2.23,2.96-2.95,4.61
				c-1.72,3.9-3.19,7.9-4.88,12.19c3.34-0.15,5.34-0.52,7.27-0.28c6.42,0.78,12.8,1.88,19.22,2.58c1.24,0.14,3.42-0.66,3.81-1.6
				c2.57-6.09,5.22-12.21,6.98-18.57c2.33-8.4,1.99-8.49,10.92-8.74c0.83-0.02,1.66-0.04,2.5-0.04c3.28,0.02,4.76,1.21,3.17,4.64
				c-2.62,5.68-5.11,11.41-7.75,17.07c-0.4,0.85-1.26,1.53-2.02,2.17c-6.3,5.31-6.29,7.86,0.83,11.53c3.99,2.05,5.12,4.95,5.76,9.04
				c0.65,4.16,2.45,8.15,3.87,12.61c3.33-2.75,5.15-8.24,8.46-4.61c3.79,4.15,8.03,4.7,12.71,5.25c5.35,0.63,10.72,1.04,16.51,1.58
				c-0.17-3.43-0.15-5.91-0.43-8.37c-1.28-11.01-1.1-11.29,9.88-12.9c6.16-0.9,7.92,0.38,8.85,5.78c8.74,2,17.22,3.9,25.67,5.9
				c2.73,0.65,3.71-0.7,3.87-3.06c0.17-2.49-0.24-5.13,0.48-7.43c0.39-1.27,3.16-3.12,3.84-2.76c2.82,1.48,5.25,3.7,7.83,5.65
				c-2.89-1.37-5.79-2.73-9.72-4.59c0,3.63,0.12,6.22-0.02,8.81c-0.32,5.83-3.37,7.44-8.76,5.17c-2.8-1.18-5.87-1.92-8.88-2.39
				c-5.99-0.93-12.02-1.53-18.04-2.27c1.01-6.72,0.21-7.4-6.16-6.05c-0.97,0.21-1.96,0.51-2.94,0.53c-3.69,0.06-4.35,2.21-3.96,5.33
				c0.39,3.13,0.74,6.28,0.85,9.43c0.2,6.1-0.17,6.31-6.06,5.49c-2.13-0.3-4.41,0.13-6.45-0.4c-7.04-1.85-15.73,1.26-20.87-7.83
				c-1.9,2.86-3.38,5.07-5.05,7.58c-5.03-3.26-6.88-8.46-7.41-13.56c-0.58-5.53-2.59-8.89-7.44-11.68c-5.35-3.08-5.55-7.99-0.8-11.72
				c3.01-2.36,4.54-4.96,5.15-8.61c0.29-1.69,1.68-3.19,2.53-4.8c1.36-2.55,2.69-5.12,4.17-7.95c-3.06,0-5.52-0.48-7.65,0.16
				c-1.64,0.49-3.51,2.12-4.15,3.7c-2.78,6.9-5.21,13.95-7.63,20.99c-1.23,3.58-3.07,4.76-7.11,4c-7.3-1.38-14.75-2.53-22.15-2.63
				c-5.22-0.07-6.75-0.78-4.78-5.85c1.79-4.61,4.19-9.01,6.53-13.39c2.32-4.35,1.58-5.9-3.26-6.24c-1.3-0.09-2.78-0.12-3.84-0.74
				c-9.08-5.36-16.58-2.08-23.77,3.94c-1.17,0.98-2.96,1.52-4.52,1.65c-2.97,0.26-5.99,0.18-8.98,0.07
				c-5.79-0.2-8.89-5.41-6.64-10.88c1.17-2.83,2.17-5.73,3.27-8.68c-9.28-2.04-9.81-1.73-14.74,4.26c-1.09,1.32-3.3,1.97-5.12,2.36
				c-1.83,0.39-3.81,0.09-5.78,0.09c0,16.14,0,31.67,0,47.32c3.75-2.38,6.99-5.11,10.73-6.63c3.92-1.59,8.44-1.66,12.46-3.08
				c2.62-0.93,6.25-2.64,6.91-4.78c2.14-6.96,7.74-8.13,13.36-9.55c0.83-0.21,2.8,0.87,2.91,1.56c0.76,4.83,1.18,9.71,1.65,14.06
				c7.58,1.77,14.18,3.32,20.77,4.86c0.08,0.65,0.16,1.29,0.24,1.94c-3.77,4.64-7.54,9.28-11.44,14.07
				c5.68,1.21,11.71,2.55,17.77,3.75c3.12,0.62,3.96-1.7,3.64-3.93c-1.05-7.31,3.83-11.69,7.78-16.51c1.74-2.12,4.7-3.36,6.22-0.41
				c2.4,4.64,6.55,5.67,10.78,7.17c7,2.48,8.93,6.16,7.17,13.51c-1.02,4.27,0.42,6.99,4.31,8.86c7.89,3.8,8.68,4.51,4.3,12.4
				c-4.01,7.24-2.43,14.21-0.68,20.85c1.7,6.44,5.68,12.3,8.85,18.31c4.53,8.6,11.06,16.23,12.38,26.4c0.13,1.01,2.15,2.47,3.36,2.54
				c5.09,0.28,10.21,0.12,15.94,0.12c-2.75-10.65-5.46-20.88-8.03-31.14c-1.1-4.39-5.26-7.73-9.01-5.85
				c-5.06,2.53-8.1,0.21-11.55-2.66c-1.19-0.99-2.83-1.76-4.35-1.97c-4.44-0.61-4.46-3.55-3.31-6.51c1.38-3.53,2.19-6.44-1.67-9
				c-0.79-0.52-1.24-3.09-0.68-3.84c2.16-2.9,4.45-5.93,7.35-8c3.73-2.67,8.5-3.72,11.06-8.29c0.53-0.94,4.11-0.72,6.02-0.15
				c1.29,0.38,2.11,2.32,3.48,3.96c1.59-1.96,2.63-3.43,3.87-4.68c0.85-0.86,1.98-1.98,3.02-2.02c8.28-0.32,16.57-0.38,24.86-0.6
				c0.69-0.02,1.58-0.5,2.02-1.05c2.51-3.17,5.48-3.25,8.93-1.79c0.94,0.39,2.38,0.52,3.21,0.07c3.69-1.97,6.63-0.38,9.79,1.35
				c1.27,0.7,3.09,0.56,4.65,0.53c3.85-0.06,7.87-1,11.49-0.16c2.09,0.48,3.53,3.74,6.03,6.64c-0.53-3.93-0.8-7.25-1.46-10.5
				c-1.11-5.51-2.44-10.99-3.74-16.46C223.45,1182.25,222.87,1180.89,222.4,1179.51z M136.63,1325.55c0.99-0.35,1.98-0.71,2.7-0.96
				c-0.51-2.86-0.96-5.42-1.41-7.97c0.42-0.13,0.83-0.26,1.25-0.38c2,4.85,2.11,10.97,8.18,13.34c-0.29-1.86-0.53-3.44-0.8-5.15
				c1.19,0.06,2.03,0.1,3.61,0.18c-1.27-2.96-2.16-5.39-3.33-7.68c-1.24-2.44-2.71-4.78-4.14-7.12c-0.84-1.37-2.23-3.94-2.63-3.8
				c-5.77,1.99-7.92-3.85-11.99-5.36c-1.01-0.37-1.87-1.72-2.35-2.82c-1.67-3.78-3.15-7.64-4.68-11.47
				c-0.42-1.06-0.76-2.15-1.14-3.23c0.57-0.22,1.13-0.45,1.7-0.67c2.29,5.22,4.03,10.79,7.06,15.53c1.77,2.76,5.99,6.25,8.45,5.8
				c4.98-0.91,5.61,2.18,7.22,4.82c2.47,4.03,4.83,8.13,7.23,12.2c0.06-5.72-0.57-11-1.94-16.09c-2.11-7.82-4.87-15.71-13.26-18.85
				c-17.34-6.49-35.02-11.66-54.08-9.84c-0.67,1.33-1.38,2.75-2.1,4.16c-0.23-0.39-0.46-0.78-0.69-1.17
				c-3.62,1.45-7.24,2.91-11.27,4.53c6.06,4.56,11.36,8.55,16.88,12.7c-1.02,1.41-1.86,2.56-2.64,3.64c0.55,1.12,0.96,2.03,1.44,2.9
				c0.48,0.86,1.1,1.65,1.53,2.53c1.21,2.46-0.44,4.36-3.16,3.1c-2.69-1.25-4.85-3.64-7-5.34c-2.84-1.22-5.56-2.52-8.38-3.54
				c-2.35-0.86-4.82-1.39-7.23-2.07c-0.09,0.42-0.18,0.83-0.27,1.25c1.29,1.79,2.59,3.58,4.57,6.34c-6.95,3.27-13.1,6.15-20.57,9.66
				c1.12,3.49,2.58,8.04,4.04,12.58c0.4-0.12,0.81-0.24,1.21-0.36c-0.16-1.72-0.33-3.43-0.62-6.57c5.78,5.56,5.07,11.96,6.62,18.5
				c2.29-3.73,4.17-6.79,6.05-9.86c0.96,1.47,1.27,2.67,0.91,3.6c-1.76,4.55-0.02,6.84,4.49,7.76c2.23,0.45,4.38,1.26,6.57,1.91
				c-0.14,0.47-0.28,0.94-0.42,1.41c-3.92-0.99-7.83-1.98-12.04-3.05c0,3.68,0,7.12,0,11.55c4.36-0.55,8.48-1.06,12.6-1.58
				c-1.6,1.55-3.43,2.5-5.38,2.91c-2.97,0.62-6.3,0.24-8.99,1.4c-5.05,2.19-9.64,5.4-14.61,7.8c-5.49,2.65-11.69,2.88-17.48,5.17
				c-7.36,2.91-15.85,2.97-23.87,4.27c0,12,0,24.03,0,36.93c8.91-2.26,17.21-4.44,25.55-6.46c4.29-1.04,8.61-2.17,12.98-2.57
				c7.4-0.68,14.93-0.29,22.26-1.33c7.14-1.02,14.05-3.6,21.17-4.89c8.1-1.47,16.31-2.25,24.48-3.35c0.65-0.09,1.28-0.29,1.91-0.49
				c6.84-2.1,13.56-4.86,20.55-6.15c9.1-1.67,18.45-1.9,27.63-3.26c10.06-1.49,20.52-0.57,29.77-6.91c4.95-3.39,11.58-4.22,17.24-6.7
				c3.68-1.61,6.72-4.93,10.48-6.04c12.27-3.63,24.73-6.59,38.31-10.12c-7.56-0.81-14.22,0.91-20.29-2.43
				c1.69-1.17,3.43-1.53,5.07-2.15c1.2-0.45,2.55-1.01,3.33-1.94c1.8-2.14,3.3-4.53,4.85-6.73c-7.1-0.47-15.09-0.99-23.08-1.51
				c0-0.59,0-1.17-0.01-1.76c7.97-2.7,15.95-5.4,23.92-8.09c-19.23,1.33-38.07,3.68-56.95,5.72c-4.71,0.51-10-1.29-14.2,0.23
				c-12.23,4.42-25.87,2.77-37.59,9.47c-3.67,2.1-8.59,2.05-12.96,2.87c-8.05,1.51-16.14,2.82-24.17,4.42
				c-6.12,1.22-12.16,2.79-18.25,4.13c-4.72,1.04-9.47,1.95-14.2,2.92c-0.11-0.35-0.23-0.69-0.34-1.04c2.11-1.03,4.22-2.05,5.61-2.73
				c0-5.92,0-11.26,0-16.59c0.23-0.01,0.45-0.01,0.68-0.02c1.13,3.06,2.26,6.11,3.39,9.17c0.58-0.26,1.16-0.51,1.75-0.77
				c-2.37-4.97-4.74-9.93-7.6-15.92c4.84,3.33,9.08,6.24,14.32,9.84c0.37-2.63,0.73-5.17,1.14-8.04c0.68,0.09,1.63,0.22,2.6,0.35
				c0.66-3.41,1.27-6.59,1.88-9.77c0.24,0.07,0.49,0.14,0.73,0.21c0.56,6.13,1.12,12.25,1.68,18.38c0.4-0.03,0.81-0.05,1.21-0.08
				c0.52-3.39,1.04-6.78,1.56-10.17c0.42,0,0.85-0.01,1.27-0.01c0.64,2,1.29,3.99,1.93,5.99c0.48-0.05,0.95-0.1,1.43-0.16
				c0-7.58,0-15.15,0-22.73c0.82-0.03,1.65-0.07,2.47-0.1c0.49,5.18,0.97,10.36,1.54,16.38c3.37-3.76,5.92-8.5,12.15-5.05
				c-0.35-4.89,0.54-8.31,5.36-7.81c4.48,0.47,2.71,3.68,1.95,5.94c3.39,0.47,6.38,0.89,9.76,1.37c0,0.74,0,1.89,0,3.04
				c-2.07-0.84-4.47-2.83-6.16-2.33c-6.77,1.98-5.6-2.01-5.31-6.51c-1.71,1.04-2.54,2.4-3.56,3.61c-1.22,1.44-2.53,3.85-3.91,3.93
				c-5.21,0.29-10.81,4.74-11.89,9.89c-0.85,4.03-2.17,7.97-3.26,11.86c5.05,0.57,6.07-0.19,6.98-5.4c0.76-4.37,1.69-8.71,2.55-13.07
				c0.52,0.1,1.05,0.19,1.57,0.29c-0.26,2.72-0.53,5.44-0.93,9.52c2.94-1.77,5.03-3.04,8.06-4.86c-0.87,4.4-1.6,8.06-2.4,12.08
				c3.46-1.01,6.42-2.09,9.48-2.72c2.96-0.61,6.02-0.78,8.89-1.12c-1.83-4.26-3.43-7.99-5.31-12.36c1.69,0.39,2.76,0.61,3.81,0.89
				c1.15,0.3,2.28,0.66,4.05,1.17C138.25,1328.42,137.44,1326.98,136.63,1325.55z M219.41,1158.91c2.92,5.59,5.84,11.19,8.77,16.81
				c0.88-1.65,1.8-4.29,3.49-6.26c1.13-1.31,3.54-2.58,5.06-2.27c3.45,0.71,4.77-0.02,5.27-3.6c0.58-4.08,2.79-6.12,7.43-4.49
				c1.7,0.59,3.94-0.36,4.95-0.48c1.55-6.65,2.87-12.31,4.19-17.96c0.49,5.13-0.18,9.97-0.78,14.82c-0.33,2.65,0.72,4.22,3.6,3.99
				c3.67-0.29,4.43,1.72,4.53,4.93c0.04,1.21,1.63,2.36,2.59,3.65c1.78-4.98,3.07-8.79,4.56-12.52c0.21-0.52,1.36-0.79,2.12-0.93
				c3.62-0.67,7.31-1.02,10.87-1.92c9.63-2.43,9.6-2.52,14.11,4.44c7.26,0.44,15.45-5.64,19.34,5.36c6.01-4.12,7.49-3.47,7.95,3.11
				c0.3,4.34,3.54,4.04,6.1,3.54c5.27-1.04,9.65,2.52,9.38,8.57c1.68-0.36,3.43-1.26,4.83-0.89c1.79,0.48,4.76,2.1,4.7,3.06
				c-0.32,4.86,0.24,8.37,6.07,9.2c2.22,0.31,4.24,1.95,6.43,3.02c-1.97-4.97-2.87-9.94-5.59-13.52
				c-5.49-7.23-11.25-14.59-18.21-20.3c-11.36-9.32-23.68-17.5-35.82-25.83c-2.04-1.4-5.9-2.36-7.77-1.36
				c-4.63,2.47-8.72,1.45-12.86-0.21c-4.1-1.65-7.81-0.57-10.5,2.06c-2.44,2.38-4.71,3.24-7.9,2.97c-1.21-0.1-2.56,0.5-3.73,1.03
				c-8.53,3.86-16.9,8.1-25.59,11.56c-3.88,1.54-8.37,1.54-12.35,2.2c-1.68,2.64-3.49,5.48-5.3,8.32c0.66-2.4,1.85-4.79,1.87-7.2
				c0.04-5.56,3.25-5.43,7.33-5.13c2.86,0.21,5.79-0.49,8.22-0.73c0-17.2,0.04-33.16-0.07-49.12c-0.01-1.65-0.44-3.77-1.5-4.87
				c-7.18-7.38-14.92-14.24-21.81-21.86c-4.59-5.08-8.7-10.82-11.9-16.86c-2.96-5.58-3.37-12.66-6.76-17.86
				c-16.73-25.61-34.04-50.84-51.27-76.12c-1.23-1.8-3.45-4.07-5.26-4.12c-7.07-0.18-11.96-4.37-17.38-7.88
				c-3.09-2-6.65-3.67-11.29-1.23c4.99,5.66,1.5,10.9-0.17,16.38c-1.69,5.55-2.8,11.28-4.26,16.91c-1.15,4.45-2.13,8.98-3.76,13.26
				c-2.34,6.15-2.48,5.95-8.25,2.22c-1.92-1.24-4.34-2.38-6.53-2.36c-3.57,0.03-7.13,1.04-10.03,1.52c-0.55,1.76-0.59,5.2-2.06,6.02
				c-5.26,2.89-10.78,6.23-17.13,1.89c-1.83-1.25-4.33-1.7-6.6-2.08c-5.8-0.98-15.99,3.74-18.66,9.01c2.92,0.42,5.62,0.69,8.25,1.23
				c2.2,0.45,4.96,0.62,6.29,2.03c1.1,1.16,1.02,4.11,0.51,6c-1.03,3.82-3,7.39-4,11.22c-0.43,1.64-0.01,4.22,1.08,5.42
				c4.32,4.77,3.76,12.2-0.73,16.98c-3.16,3.36-6.57,7.53-7.4,11.81c-1.83,9.41-2.24,19.11-2.89,28.72c-0.2,3.01,1.16,4.92,5.05,5.2
				c7.38,0.52,14.71,1.83,22.02,3.06c4.39,0.74,8.7,1.98,12.54,2.87c1.1-5.04,0.9-10.71,3.5-14.4c3.87-5.5,4.19-9.88,0.52-15.07
				c-0.47-0.66-0.7-1.5-1.02-2.27c-3.06-7.2-2.29-9.44,4.62-13.64c2.03,1.52,4.05,3.02,6.36,4.75c5.31-18.08,7.5-35.85,7.73-54.09
				c1.79,4.69,3.35,9.45,3.36,14.2c0.04,13.31,0.74,26.82-1.3,39.87c-1.74,11.1,0.88,20.65,4.34,30.52
				c1.29,3.68,8.22,6.64,12.07,5.59c4.43-1.2,8.97-2.21,13.53-2.68c3.66-0.38,6.76,0.78,9.63,3.92c3.15,3.44,7.55,5.81,11.61,8.32
				c4.48,2.78,9.27,5.07,13.78,7.8c5.78,3.49,11.43,7.2,16.45,10.38c2.02-1.74,3.14-3.42,4.63-3.87c8.24-2.45,16.64,1.21,20.89,8.66
				C208.73,1140.54,214.09,1149.72,219.41,1158.91z M163.97,441.65c-2.81,2.65-5.3,5.87-8.58,7.68c-1.3,0.72-4.3-1.74-6.58-2.61
				c-1.05-0.4-2.23-0.49-3.35-0.68c-4.42-0.72-8.84-1.43-13.55-2.19c5.04-6.65,9.56-12.62,14.42-19.03
				c-11.56-2.62-21.16,0.16-30.91,3.46c0.72-2.21,1.51-4.07,2.86-5.34c4.94-4.67,7.52-10.31,8.55-17c0.46-2.99,2.07-5.91,3.62-8.59
				c3.2-5.56,6.71-10.95,10.35-16.83c-9.05,0.89-11.78-2.2-8.88-9.04c0.26-0.61,0.64-1.17,0.89-1.79c1.26-3.09,2.49-6.18,3.73-9.28
				c-0.31-0.13-0.62-0.26-0.92-0.4c-6.65,12.16-13.3,24.32-20.61,37.67c4.86-2.59,8.75-4.66,12.64-6.73c0.3,0.37,0.6,0.73,0.91,1.1
				c-15.79,12.84-26.29,31.04-44.59,41.33c0.22,0.37,0.43,0.74,0.65,1.11c5.67-0.98,11.34-1.96,18.09-3.13
				c-3.1,3.38-5.02,6.63-7.86,8.31c-5.47,3.23-11.27,7.06-17.29,7.81c-5.13,0.64-9.23,1.48-12.77,5.1
				c-6.48,6.62-13.7,12.03-22.89,14.42c-2.2,0.57-4.25,1.87-6.23,3.06c-7.65,4.6-15.03,9.71-22.93,13.82
				c-7.58,3.95-12.42,8.48-10.86,18.05c0.98,6,0.18,12.29,0.18,18.45c0,2.23,0,4.46,0,8.24c9.05-10.31,17.56-18.67,29.96-21.4
				c-2.38,3.48-5.24,6.39-8.03,9.37c-2.6,2.77-5.37,5.47-6.14,9.37c11.13-8.01,18.3-20.28,30.88-27.07c-0.46,4.57-0.82,8.07-1,9.84
				c5.62,1.6,10.69,1.67,13.53,4.23c2.97,2.68,4.95,7.59,1.4,11.8c3.45,3,5.67,1.88,8.81,0.02c7.8-4.62,16.05-8.47,24.24-12.39
				c1.85-0.88,4.61-0.13,6.2-1.22c6.8-4.7,13.67-9.41,19.87-14.85c8.97-7.88,17.34-16.44,26.05-24.62c1.43-1.34,3.12-2.73,4.93-3.26
				c1.93-0.56,4.32-0.62,6.21,0.01c3.27,1.08,6.32,2.86,9.71,4.46c-7.37,6.2-14.96,12.59-23.01,19.36c2.6,1.62,3.92,2.43,5.6,3.48
				c-3.79,3.99-7.31,7.71-10.84,11.42c0.27,0.43,0.55,0.87,0.82,1.3c3.65-0.9,7.54-1.27,10.9-2.81
				c13.47-6.16,25.23-15.34,39.01-21.26c8.29-3.56,15.86-9.09,24.98-10.67c11.25-1.95,20.9-6.72,30.08-13.5
				c4.87-3.6,11.25-5.96,17.27-6.95c4.84-0.8,9.39-1.22,13.36-4.3c2.39-1.86,4.87-2.51,7.75-0.72c0.94,0.59,3.13,0.87,3.53,0.35
				c2.47-3.22,6.13-6.49,3.89-11.03c-1.87-3.81-0.32-6.03,1.89-8.76c2.9-3.57,5.35-7.5,8-11.27c-0.43-0.36-0.86-0.72-1.29-1.09
				c-7.31,4.24-14.54,8.64-21.98,12.65c-4.66,2.51-9.51,4.81-14.51,6.49c-5.49,1.85-7.52,0.53-10.18-4.67
				c-0.62-1.2-1.88-2.89-2.91-2.94c-6.07-0.28-7.87-3.17-5.27-9.59c-2.43,1.55-3.92,2.53-5.45,3.47
				c-10.61,6.52-21.09,13.28-31.89,19.47c-7.55,4.32-15.38,7.61-24.6,3.8c-3.48-1.44-7.87-0.54-11.79-1.12
				c-2.76-0.41-5.42-1.52-8.12-2.31c0.79-0.92,1.58-1.83,3.46-4c-1.15,5.6,1.32,5.37,4.11,5.01c1.73-0.22,3.55-0.25,5.28,0
				c4.36,0.65,8.65,1.76,13.02,2.22c7.13,0.74,13.36-2.17,19.23-5.77c10.9-6.69,21.7-13.54,32.55-20.32
				c1.77-1.11,3.58-2.17,6.23-3.77c-0.26,2.35-0.22,3.66-0.57,4.85c-1.02,3.49-1.42,6.12,3.74,6.21c1.51,0.03,3.77,2.3,4.3,3.98
				c1.35,4.33,4.33,5.13,7.71,3.82c6.15-2.39,12.2-5.14,18.03-8.23c7.43-3.94,14.61-8.37,22.6-13c0.3,7.29-3.66,11.66-7.31,16.04
				c-2.47,2.95-4.42,5.5-2.38,9.71c0.72,1.49-0.3,4.08-1.04,5.97c-0.8,2.06-2.19,3.89-3.11,5.47c7.92,5.71,15.35,11.17,22.88,16.49
				c9.91,7,19.92,13.85,29.82,20.87c1.46,1.03,2.45,2.69,3.8,3.9c2.56,2.3,4.91,5.07,7.89,6.61c17.54,9.12,35.02,18.4,52.94,26.72
				c11.72,5.44,24.12,9.44,36.31,13.8c1.72,0.62,4.57,0.37,5.95-0.7c9.27-7.22,18.27-14.77,27.21-22.08
				c-4.6-2.62-8.83-1.42-12.96,2.13c-1.14,0.98-3.25,1.06-4.93,1.16c-2.89,0.18-5.81-0.08-8.7,0.12c-2.38,0.17-4.73,0.74-8.69,1.4
				c1.74-4.75,2.14-9,4.44-11.53c8.3-9.16,5.63-17.52-0.59-26.08c-0.77-1.07-1.05-2.58-1.98-3.43c-1.43-1.3-3.25-3.15-4.84-3.1
				c-3.94,0.13-6.15-1.7-8.12-4.67c-4.94-7.45-10.16-9.03-18.97-7.87c-3.32,0.44-7.26-1.48-10.45-3.19c-2.53-1.36-4.2-4.25-6.55-6.07
				c-1.46-1.13-3.56-2.32-5.22-2.12c-3.9,0.47-7.87,1.35-11.51,2.81c-4.32,1.73-8.25,1.4-11.85-1.06
				c-6.83-4.67-13.44-9.65-20.17-14.52c2.4-5.84,3.93-11.44,1.13-17.47c-0.7-1.49,0.03-3.61-0.11-5.42
				c-0.19-2.54,0.21-5.53-0.99-7.51c-7.77-12.76-5.72-25.51-0.33-38.21c0.68-1.6,3.33-3.12,5.2-3.3c3.08-0.29,4.9,1.88,4.55,5.11
				c-0.56,5.27-0.94,10.6-2.09,15.75c-1.73,7.75-1.65,15.34,0.51,23.09c1.34-3.37,2.81-6.8,3.02-10.3c0.64-10.8,8.45-18.6,11.38-28.4
				c0.23-0.76,1.48-1.7,2.27-1.72c3.99-0.08,8.79-1.22,11.79,0.56c4.1,2.43,6.45,6.87,5.45,12.82c-1.48,8.82-3.34,17.9,0.95,27.2
				c0.46-2.29,0.8-4.07,1.17-5.84c1.64-7.8,3.23-15.6,4.95-23.38c0.77-3.48,2.73-4.54,6.56-4.15c6.29,0.64,10.1,3.5,11.17,10.15
				c0.45,0.08,0.9,0.16,1.35,0.25c1.15-2.01,2.31-4.02,3.51-6.12c3.02,2.51,4.85,4.51,7.09,5.79c3.5,1.99,5.17,4.8,4.5,8.62
				c-0.91,5.21-2.02,10.42-3.51,15.49c-0.8,2.72-0.46,4.81,1.92,5.85c1.69,0.74,4.03,0.94,5.77,0.39c2.13-0.67,3.93-2.37,5.84-3.6
				c4.24,2.61,8.09,1.03,14.42-6.55c3.78-4.52,7.06-9.46,10.79-14.52c0,3.68-1.25,8.03,0.36,10.55c1.49,2.34,6.04,2.73,10.31,4.4
				c-2.18,5.53-4.33,11.19-6.63,16.77c-1.38,3.35-3.28,6.58-0.77,9.67c2.75-2.33,5.28-6.24,8.1-6.47c5.01-0.4,7.85-3.38,11.34-5.86
				c1.7-1.21,3.51-2.28,4.52-2.92c3.32,3.48,6.14,6.42,9.18,9.6c2.2-3,3.69-5.21,5.36-7.27c4.95-6.14,6.5-6.21,12.15-0.81
				c0.6,0.57,1.33,1.01,1.92,1.59c3.27,3.22,6.81,6.23,9.69,9.77c1.99,2.45,3.06,5.65,5.23,9.85c-1.54,2.18-4.18,5.92-6.64,9.41
				c1.49,3.25,2.89,6.32,4.2,9.17c4.1-3.42,7.91-6.86,12.04-9.85c1.16-0.83,3.92-1.01,4.81-0.22c0.96,0.87,1.08,3.32,0.73,4.89
				c-1.48,6.54-3.22,13.03-9.02,17.22c3.65,4,6.6,4.16,9.67,0.03c1.67-2.25,3.79-4.23,5.98-6c1.16-0.94,3.5-2.08,4.28-1.56
				c4.21,2.79,6.38-0.59,9.49-2.17c3.74-1.9,8-3.11,12.16-3.71c1.79-0.26,4.03,1.6,5.87,2.79c2.52,1.64,4.73,3.78,7.3,5.3
				c4.3,2.54,8.78,4.77,14.2,7.67c3.33-2.62,7.57-5.96,11.93-9.39c-3.49-3.47-7.15-3.74-11.34-3.16c-2.83,0.39-6.1,0.32-8.67-0.75
				c-5.02-2.1-10.23-4.38-14.38-7.78c-8.36-6.86-18.66-7.09-28.31-9.32c-3.55-0.82-6.31-1.8-8.12-4.81c-1.36-2.27-2.33-4.77-3.6-7.1
				c-3.74-6.86-10.79-11.06-17.28-11.13c-5.64-0.06-7.89-2.8-11.72-4.94c-4.79-2.68-10.64-4.62-16.07-4.7
				c-5.33-0.08-12.21-0.93-14.8-5.83c-2.37-4.47-6-5.7-10.31-6.77c-1.87-0.47-3.66-1.79-5.16-3.09
				c-6.73-5.82-12.02-13.93-22.47-13.66c-0.76,0.02-1.7-0.37-2.29-0.88c-6.05-5.17-12.78-3.14-19.49-2.06
				c-1.62,0.26-3.54,0.22-5-0.42c-8.24-3.56-16.22-7.77-24.6-10.92c-9.39-3.53-18.73-7.9-29.41-5.13c-3.96,1.03-8.25,0.95-12.4,1.06
				c-7.06,0.19-12.91-2.12-16.94-8.41c-2.92-4.57-7.51-6.71-12.12-7.6c-3.19,4.96-5.9,9.48-8.97,13.76c-0.8,1.12-3.31,2.43-4,2
				c-6.54-4.06-14.73-5.59-19.38-12.62c-2.47-3.74-5.74-7-7.92-10.88c-5.07-9.05-13.44-8.06-22.25-8.33
				c0.56,5.39,4.13,8.08,7.41,10.59c4.95,3.79,8.19,8.25,10.04,14.35c0.83,2.72,4.32,5.77,7.16,6.48c4.7,1.18,7.47,3.98,5.67,8.49
				c-1.1,2.76-2.76,5.29-4.04,7.98c-0.5,1.06-0.91,2.28-0.93,3.44c-0.06,3.25,0.16,6.49,0.18,9.74c0.04,5.2,0.01,10.4,0.01,16.34
				c-1.29,1.06-3.17,2.69-5.14,4.18c-3.57,2.7-6.03,1.87-9.3-1.41c-4.64-4.65-6.96-8.91-4.18-15.46c0.81-1.91-0.2-4.95-1.15-7.15
				c-0.72-1.67-2.58-3.89-4.06-3.99c-5.9-0.4-10.63-2.18-12.79-8.21c-5.54,3.28-6.94,8.41-7.42,13.5
				c-0.97,10.11-7.73,15.8-15.01,21.21c-0.77,0.57-2.75,0.54-3.43-0.05c-0.95-0.83-1.69-2.49-1.61-3.73c0.1-1.57,1.18-3.07,1.75-4.62
				c2.46-6.68,4.88-13.37,7.32-20.06c-0.78-0.36-1.56-0.71-2.35-1.07c-1.88,3.49-4.12,6.85-5.55,10.52
				c-1.24,3.21-1.3,6.87-2.41,10.15c-0.64,1.89-1.95,4.03-3.59,5c-3.36,1.97-7.13,3.23-10.79,4.8c-2.71-3.67-3.13-6.63,1.29-9.1
				c3.15-1.76,4.74-3.99,4.59-8.33c-0.13-3.78,2.07-7.95,4.12-11.43c2.25-3.83,5.59-7.01,8.28-10.6c0.91-1.22,2.1-3.11,1.74-4.25
				c-1.29-4.04-2.83-8.09-4.96-11.73c-1.88-3.21-6.03-5.92-3.08-9.98c2.65-3.65,6.59-1.22,9.59,0.11c10.25,4.56,16.1-1.78,21.07-8.09
				c1.75-2.23,1.43-3.93-1.55-4.77c-13.5-3.78-25.69-11.76-40.08-12.58c-1.59-0.09-3.76,0.69-4.79,1.85
				c-3.56,3.99-0.41,6.37,1.35,7.92c2.79-2.21,5.08-4.51,7.82-5.98c1.32-0.71,3.85-0.39,5.18,0.47c1.67,1.08,4.39,2.67,1.81,5.51
				c-2.73-6.08-5.51-6.5-9.47-1.4c-1.28,1.65-2.5,3.34-4.18,5.58c-2.09-2.64-4.46-4.47-5.11-6.78c-0.64-2.25,0.49-5,0.8-7.29
				c-0.65,0.4-1.75,1.68-2.83,1.67c-6.11-0.05-6.57,4.18-7.05,8.63c-1,9.36-1.91,18.74-3.16,28.07c-1.18,8.79-1.36,8.77,5.5,12.51
				c-1.08,7.27-3.2,13.88-9.12,19.12c-5.03,4.45-9.29,9.76-13.89,14.69c2.63,0.27,4.47-1.02,6.03-0.73
				c7.02,1.28,12.35-3.03,18.27-5.19c5.22-1.9,11.64-12.44,11.26-16.53c-0.3-3.27,0.09-6.67,0.65-9.94
				c1.37-7.98,4.27-15.92,4.19-23.85c-0.08-8.06,3.65-13.3,8.24-18.71c-1.78,3.53-3.62,7.04-5.33,10.61
				c-0.62,1.28-1.18,2.67-1.37,4.07c-1.19,8.48-2.04,17.02-3.47,25.46c-0.73,4.35-4.79,8.25-1.26,13.09
				c0.39,0.54-0.23,1.91-0.54,2.84c-3.35,9.91-16,19.68-26.47,19.73c-4.88,0.02-8.66,1.7-12.25,4.57c-0.75,0.6-1.69,0.97-2.54,1.45
				c-0.36-0.37-0.72-0.74-1.08-1.12c5.4-6.82,9.99-14.58,16.44-20.2c6.12-5.33,9.48-11.02,10.27-19.17
				c-5.32,0.08-7.04-2.87-6.45-7.44c1.59-12.28,3.22-24.56,4.95-36.82c0.47-3.32,1.11-6.68,5.88-6.38c1.21,0.07,2.5-1.07,4.81-2.16
				c-3.84-1.5-6.54-2.56-9.59-3.75c-1.63,3.69-3.22,6.94-4.51,10.3c-1.55,4.04-3.8,5.24-8.06,3.45c-1.81-0.76-5.59-0.59-6.27,0.51
				c-1.5,2.43-1.76,5.67,1.57,7.81c2.74,1.76,4.03,4.25,3.14,7.7c-0.26,1.01,0.47,2.25,0.41,3.36c-0.17,3.17,0.37,6.82-1,9.4
				c-2.22,4.17-5.72,7.66-8.74,11.39c-1.69,2.09-3.51,4.07-6.18,7.14c3.81,0,6.36,0.68,8.26-0.14c4.22-1.83,6.53-0.38,8.55,4
				c-2.92,2.78-6.02,5.73-9.1,8.69c-2.88,2.76-7.06,5.03-8.35,8.4c-3.47,9.11-11.16,13.28-18.39,18.2c-1.79,1.22-3.63,2.37-6.55,4.27
				c3.6,0.49,5.64,0.76,9.16,1.24c-4.87,6.92-9.19,13.06-13.78,19.59c2.4,0.23,4.7,0.28,6.92,0.71c2.88,0.55,6.55,0.52,8.37,2.29
				c3.81,3.69,6.45,1.91,9.42-0.49C161.12,444.37,162.52,442.96,163.97,441.65z M349.52,948.75c0.22-0.61,0.77-1.31,0.62-1.82
				c-2.78-9.71-4.96-19.71-13.57-26.49c-2.47-1.94-5.15-4.07-6.61-6.73c-2.32-4.25-5.3-9.11-5.07-13.55
				c0.29-5.46-0.92-10.03-2.71-14.84c-2.48-6.68-0.49-11.94,6.09-15.69c3,4.07,6.25,8.06,9.01,12.36c3.33,5.19,6.15,10.7,12.11,13.52
				c0.83,0.39,1.87,1.29,1.99,2.09c0.76,5.03,4.51,7.32,8.11,10.12c2.64,2.05,4.61,5.18,6.37,8.13c2.88,4.85,5.25,10,8.04,14.91
				c0.96,1.68,2.55,3.01,3.85,4.5c-1.74-5.45-4.9-9.97-6.99-14.95c-3.75-8.95-1.44-11.95,4.53-14.91c5.76-2.86,9.77-7.24,12.09-13.33
				c1.01-2.65,2.73-5.04,4.32-7.88c4.37,6.22,3.9,8.99-1.07,12.76c-1.51,1.15-2.77,2.78-3.77,4.43c-2.13,3.52-3.93,7.25-6.03,10.8
				c-2.08,3.51-2.97,6.52,1.23,9.34c2.14,1.44,3.8,3.68,5.49,5.71c6.78,8.17,13.1,16.78,20.39,24.46c3.33,3.51,8.52,5.26,12.88,7.79
				c1.37,0.8,3.51,1.19,4.05,2.35c3.86,8.4,7.48,16.91,10.96,25.47c0.49,1.2-0.29,2.87-0.29,4.33c0,3.02-0.32,6.14,0.31,9.04
				c0.8,3.7,2.54,7.19,3.6,10.85c0.57,1.97,0.57,4.1,0.83,6.16c-1.98-0.6-2.87-1.36-3.44-2.31c-4.9-8.06-10.72-15.58-12.89-25.14
				c-0.86-3.78-2.66-7.71-5.09-10.7c-8.15-10.05-16.42-20.04-25.32-29.42c-9.22-9.72-9.7-9.4-20.86-2.01
				c-1.52,1.01-3.15,1.85-4.7,2.81c-2.88,1.79-5.75,3.62-8.45,5.33c4.02,5.08,8.07,9.17,10.89,13.99
				c4.13,7.06,9.31,12.89,15.78,17.77c1.98,1.49,3.8,3.27,5.4,5.16c9.86,11.61,17.6,24.42,22.79,38.86
				c3.02,8.41,7.24,16.38,10.74,24.62c3.88,9.14,7.59,18.35,11.32,27.55c2.08,5.13,4.06,10.29,6.08,15.44
				c-0.65,0.37-1.29,0.74-1.94,1.11c-3.53-5.02-7.19-9.96-10.56-15.09c-3.91-5.96-6.65-12.97-11.49-18
				c-14.59-15.16-27.69-31.46-40.03-48.44c-1.36-1.87-3.07-3.47-4.62-5.2c4.63,5.22,2.66,9.43-1.39,13.57
				c-1.62,1.66-2.87,3.72-4.59,5.24c-1.13,1-2.85,1.98-4.25,1.9c-6.71-0.37-10.79,2.9-13.28,8.77c-1.94,4.58-5.62,6.34-10.37,6.22
				c-1.71-0.04-3.41-0.22-5.12-0.34c-0.33,0.39-0.65,0.78-0.98,1.17c5.82,8.16,11.64,16.33,17.78,24.94
				c-4.85,3.32-9.25,8.06-16.39,6.78c-6.52-1.17-13.04-2.31-20.32-3.6c10.76,15.26,10.76,15.26,8.71,21.32
				c2.19,1.9,4.58,3.71,6.67,5.84c4.06,4.14,8.05,8.37,11.89,12.72c8.32,9.43,16.05,19.45,24.94,28.31
				c7.74,7.71,17.9,13.39,22.6,23.61c5.94,12.9,16.3,21.29,27.18,30.1c0.06-4.55,2.42-4.51,6.02-3.84c3.99,0.74,7.69,1.1,9.85,5.24
				c0.44,0.84,2.32,1.24,3.57,1.31c4.85,0.25,7.27,2.63,7.41,7.53c0.04,1.51,0.65,3.01,1.24,5.56c1.27-13.52,9.86-16.1,20.92-15.76
				c-5.49-5.82-5.35-6.83,0.34-10.56c1.35-0.88,2.98-1.65,3.84-2.91c2.41-3.55,4.69-2.64,7.02-0.04c4.25-6.51,8.31-8.31,15.72-6.47
				c0-13.19-0.03-26.15,0.01-39.11c0.06-17.82,0.03-35.64,0.34-53.46c0.1-5.98,0.5-11.52-7.52-12.39c-1.05-0.11-2.08-1.02-2.99-1.73
				c-10.19-7.88-17.82-17.43-19.45-30.71c-0.59-4.78-0.89-9.6-1.12-14.41c-1.43-29.92-2.77-59.84-4.21-89.76
				c-1.02-21.28-1.4-42.62-3.43-63.8c-2.1-21.98-2-43.85-0.61-65.79c0.21-3.4-3.24-7.48-6.07-6.18c-6.51,2.98-10.5-0.97-14.69-4.35
				c-3.84-3.1-7.31-2.65-10.77,0.2c-1.65,1.36-3.54,2.88-4.35,4.75c-4.24,9.82-13.29,15.15-20.51,22.09
				c-4.13,3.97-9.48,6.65-14.28,9.91c-0.38-0.51-0.75-1.02-1.13-1.54c12.7-11.47,28.86-19.51,37.14-37.2
				c-4.86-1.1-9.54-2.14-14.19-3.24c-1.43-0.34-3.26-0.47-4.14-1.41c-3.78-4.05-7.72-2.84-11.77-0.83
				c-12.32,6.11-24.57,12.25-37.46,17.25c-8.35,3.23-15.85,8.22-25.38,7.19c-3.4-0.37-4.59,1.65-4.68,5.27
				c-0.27,9.97-0.5,20.01-1.84,29.87c-2.68,19.74-6.11,39.38-9.15,59.07c-0.18,1.15-0.05,2.78,0.64,3.54
				c3.25,3.56,6.49,7.22,10.27,10.16c1.81,1.41,4.89,1.08,7.25,1.93c3.75,1.35,8.66,2.03,10.79,4.77c4.18,5.4,6.79,12.03,10.05,18.15
				c-1.24-1.24-2.78-2.29-3.66-3.75c-2.31-3.84-4.39-7.82-6.46-11.8c-2.08-4-5.72-6.11-9.74-5.51c-5.63,0.83-9.27-1.51-12.55-5.35
				c-2.18-2.55-4.27-5.19-6.4-7.79c-0.52,0.38-1.04,0.77-1.56,1.15c-1.3,5.13-1.98,10.53-4.07,15.32c-2.24,5.13-0.03,7.54,3.32,10.87
				c10.94,10.86,21.48,22.12,32.11,33.28c4.49,4.71,8.83,9.55,13.46,14.57c3.79-8.36,3.95-16.19,1.97-23.83
				c-1.71-6.61,1.02-10.53,6.05-13.75c5.18-3.3,10.4-6.54,15.64-9.75c3.63-2.22,7.13-2.43,10.48,0.7
				c6.3,5.91,13.41,11.14,18.82,17.77c7.6,9.3,15.93,18.24,19.66,30.31c2.16,6.98,5.87,13.49,8.88,20.21
				c0.51-0.22,1.01-0.43,1.52-0.65c-1.33-3.49-2.97-6.9-3.89-10.5c-0.66-2.6-0.37-5.45-0.49-8.19c-0.12-2.71,0.13-5.49-0.41-8.11
				c-0.67-3.22-1.62-6.51-3.13-9.41c-2.31-4.44-4.4-11.23-8-12.31c-9.41-2.83-15.24-9.04-20.95-16c-6.4-7.81-12.5-15.9-19.28-23.38
				c-4.29-4.74-4.91-6.88-1.14-12.42c1.91-2.8,3.46-5.85,5.82-9.88c-4.37,2.93-7.36,5.27-10.65,7.06c-3.96,2.15-5.19,5.22-3.42,9.1
				c2.33,5.11,5.23,9.96,7.7,15.01c0.83,1.7,1.08,3.68,1.6,5.53c-0.55,0.34-1.11,0.68-1.66,1.02c-1.99-1.79-4.38-3.3-5.88-5.43
				c-2.37-3.38-5-6.95-6.01-10.84c-2.86-11.02-14.98-14.84-18.67-25.03c-0.04-0.12-0.31-0.13-0.42-0.25
				c-4.03-4.52-8.2-8.92-12.02-13.61c-2.99-3.66-5.54-7.68-8.45-11.76c-4.95,4.43-6.11,8.88-3.7,14.33c1.26,2.86,3.46,6.2,2.9,8.82
				c-2.3,10.79,2.67,18.14,10.4,24.17c11.06,8.63,12.5,21.59,16.4,33.48c-0.38,0.25-0.76,0.51-1.14,0.76
				C351.58,951.61,350.55,950.18,349.52,948.75z M996.45,520.64l0.12,0.13c1.56,0.39,3.91,0.27,4.55,1.26
				c3.77,5.78,8.11,11.46,10.47,17.84c5.94,16.05,7.73,33.03,8,50.02c0.74,46.12,1.23,92.24,1.41,138.36c0.08,19.3,0,38.69-1.57,57.9
				c-2.56,31.23-4.16,62.45-3.46,93.77c0.57,25.45,1.4,50.89,2.05,76.34c0.14,5.23,0.02,10.47,0.02,15.71
				c0.54,0.06,1.09,0.12,1.63,0.19c1.29-4.21,3.07-8.34,3.78-12.65c3.58-21.48,6.58-43.05,10.36-64.49
				c3.42-19.45,4.34-39.09,3.98-58.68c-0.36-19.38-2.47-38.72-4.02-58.07c-1.89-23.7-4.07-47.38-5.94-71.08
				c-0.59-7.46-0.62-14.97-0.82-22.45c-0.38-14.65-0.66-29.3-1.05-43.94c-0.32-12.1-0.54-24.21-1.14-36.3
				c-0.35-6.97-2.14-13.92-1.95-20.84c0.52-19.92-5.85-38.34-11.33-56.98c-1.44-4.92-4.91-8.5-10.18-9.21
				c-8.96-1.21-17.97-2.15-26.98-2.82c-1.94-0.14-4.2,1.01-5.96,2.12c-6.9,4.33-10.69,13.79-9.53,24.66
				c2.47-5.05,4.32-9.25,6.55-13.26c2.18-3.92,3.15-9.18,9.17-9.77c-2.1,4.65-4.99,8.8-5.83,13.33c-1.85,9.92-3.47,20.01-3.73,30.06
				c-0.38,15.3,0.45,30.63,0.81,45.95c0.31,13.11,0.57,26.21,1.05,39.31c0.55,15.26,1.55,30.51,1.88,45.77
				c0.15,7.12-1.08,14.26-1.24,21.4c-0.3,13.32-0.03,26.65-0.44,39.97c-1.03,33.27-2.15,66.54-3.53,99.8
				c-0.92,22.1-1.87,44.22-3.75,66.25c-1.3,15.2-4,30.29-6.57,45.35c-0.69,4.06-0.52,10.07-7.72,9.38c-0.48-0.05-1.08,0.69-1.55,1.15
				c-6.22,5.96-13.3,5.22-19.75,1.31c-5.02-3.04-9.09-7.67-13.57-11.6c-2.86-2.51-5.33-6.15-8.66-7.31
				c-8.4-2.93-13.25-9.03-17.48-16.2c-3.27-5.54-7.57-9.91-13.79-12.45c-3.44-1.41-6.28-4.25-9.5-6.27
				c-4.32-2.71-8.75-5.24-13.13-7.84c12.59,34.4,30.51,64.23,60.2,85.77c-3.82-1.6-7.41-3.43-10.19-6.09
				c-8.04-7.7-15.74-15.74-23.59-23.63c-14.05-14.12-23.67-30.7-27.98-50.25c-0.95-4.32-2.17-8.28-5.72-11.53
				c-2.33-2.14-3.92-5.4-5.08-8.45c-2.89-7.59-5.62-15.26-7.92-23.05c-2.7-9.17-4.88-18.5-7.29-27.75c-0.39,0.75-0.49,1.42-0.37,2.06
				c3.96,20.88,7.36,41.88,12.18,62.56c2.3,9.89,7.96,18.96,10.88,28.77c2.67,8.96,5.24,17.77,10.42,25.67
				c1.6,2.44,2.81,5.31,3.51,8.15c3.58,14.43,11.16,26.24,22.82,35.34c2.97,2.32,6.98,3.38,9.72,5.88
				c9.27,8.48,20.01,12.07,32.4,11.06c3.33-0.27,5.31,0.98,6.11,4.39c2.33,9.92,7.66,19.15,6.8,29.81c-0.05,0.64-0.03,1.51,0.35,1.93
				c6.06,6.76,6.16,14.43,5.33,23.11c-0.83,8.76,1.28,17.75,1.35,26.65c0.09,11.56-0.58,23.12-0.88,34.68
				c-0.03,1.32,0.2,2.65,0.28,3.97c0.48,7.13,1.45,14.28,1.31,21.4c-0.27,13.84-2.54,27.67,0.25,41.5c0.32,1.58,0.03,3.32-0.14,4.96
				c-0.57,5.44-1.42,10.85-1.8,16.3c-0.57,8.28-0.84,16.58-1.24,24.88c-0.48,10.12-1.5,20.25-1.3,30.35
				c0.32,16.12,1.42,32.23,2.25,48.34c0.6,11.6,1.36,23.18,1.93,34.78c0.19,3.82,0.27,7.68-0.11,11.47
				c-1.08,10.84-2.88,21.63-3.53,32.5c-0.81,13.76-0.81,27.58-0.99,41.38c-0.02,1.16,0.97,2.82,1.98,3.43
				c5.46,3.3,11.08,6.34,16.84,9.59c1.19-2.47,1.75-7.82,6.38-3.88c3.39-2.2,6.4-4.14,8.63-5.59c-2.62-1.15-5.12-3-7.77-3.24
				c-6.01-0.54-6.08-4.01-4.26-7.97c2-4.35,4.98-8.25,0.59-12.89c-0.56-0.6,0.22-2.47,0.39-3.84c-5.53-1.08-5.96-5.51-5.94-9.64
				c0.01-1.07,2.91-2.13,5.34-3.78c0.21-1.15,0.9-3.41,1-5.7c1.09-24.26,2.52-48.52,2.97-72.79c0.27-14.92-1.27-29.86-1.52-44.8
				c-0.25-15.14,0.14-30.28,0.18-45.42c0.02-6.47,0-12.94-0.27-19.39c-0.15-3.44-0.91-6.86-1.32-10.29c-0.56-4.58-2.22-9.4-1.34-13.7
				c2.49-12.23-0.1-24.22-0.48-36.3c-0.7-22.35-3.29-44.77,3.64-66.7c0.54-1.7,0.56-3.64,0.44-5.44c-0.54-7.95-1.39-15.89-1.75-23.84
				c-0.08-1.85,1.28-3.76,1.97-5.64c1.64,1.12,3.53,2.02,4.84,3.44c0.93,1.01,1.29,2.69,1.56,4.13c0.54,2.92,0.83,5.89,1.24,8.84
				c1.07,7.69,3.12,15.39,3.02,23.06c-0.28,21.62-1.64,43.23-2.18,64.85c-0.4,16.31-0.25,32.65-0.21,48.97
				c0.04,14.47,0.33,28.95,0.41,43.42c0.15,26.94,0.62,53.89,0.23,80.83c-0.34,24.27-1.69,48.52-2.44,72.79
				c-0.06,1.83,1.15,3.7,1.78,5.55c0.37-0.15,0.75-0.29,1.12-0.44c-1.52-3.86-1.52-3.86,2.8-8.6c-1.26-2.76-3.32-5.2-3.21-7.54
				c0.12-2.75,1.64-5.83,3.47-7.99c1.29-1.51,4.12-1.71,6.26-2.5c-4.44-0.64-5.88-3.62-4.49-6.93c1.21-2.87,3.65-5.94,6.34-7.16
				c1.41-0.64,4.53,2.52,6.79,3.92c0.6-2.37,1.04-4.41,1.65-6.41c0.67-2.19,2.18-4.33,2.15-6.49c-0.27-21.97-0.63-43.94-1.26-65.9
				c-0.45-15.62-1.52-31.22-2.01-46.83c-0.9-28.11-1.63-56.22-2.32-84.33c-0.34-14.13-0.47-28.26-0.63-42.39
				c-0.1-8.45-0.15-16.9-0.07-25.35c0.04-4.88,0.51-9.73-4.71-12.73c-0.85-0.49-1.13-2.25-1.36-3.49
				c-1.05-5.64-1.99-11.31-2.96-16.97c-0.67,1.73-0.76,3.38-0.65,5.02c0.52,8.27,1.49,16.54,1.58,24.81
				c0.34,29.11-0.93,58.31,0.92,87.32c2.09,32.78,2.83,65.51,2.47,98.31c-0.03,2.65-0.16,5.36-0.71,7.94
				c-2.97,14.13-2.76,28.4-2.45,42.72c0.28,13.05,0.06,26.11,0.06,39.16c-1.03-3.35-1.82-6.85-1.86-10.36
				c-0.3-25.08-0.04-50.04,1.94-75.19c2.36-29.91,0.14-60.2-0.48-90.32c-0.61-29.6-1.71-59.2-2.61-88.8
				c-0.28-9.25-0.74-18.5-0.87-27.75c-0.08-5.94,0.29-11.88,0.46-17.82c0.81-0.08,1.62-0.16,2.43-0.24c0.7,2.19,1.54,4.35,2.08,6.59
				c1.77,7.37,0.49,15.64,7.17,21.56c1.41,1.25,1.7,4.29,1.71,6.52c0.02,4.47-0.9,8.96-0.72,13.41c0.4,9.95,1.62,19.88,1.8,29.83
				c0.28,15.46-0.13,30.94-0.05,46.4c0.08,15.48,0.07,30.98,0.71,46.44c0.57,13.94,2.44,27.83,2.87,41.77
				c0.92,29.95,1.29,59.92,1.87,89.03c3.48,3.69,6.55,6.95,9.38,9.95c0.94-12.28,2.28-24.17,2.65-36.09
				c0.46-14.61,0.28-29.25,0.07-43.87c-0.36-25.46-1.2-50.91-1.39-76.36c-0.16-22.31,0.09-44.63,0.55-66.94
				c0.29-14.13,1.59-28.24,1.91-42.38c0.24-10.3-0.3-20.63-0.64-30.94c-0.16-4.98-0.46-9.98-1.12-14.92
				c-0.2-1.51-1.81-2.83-2.78-4.23c-1.01,0.95-2.7,1.75-2.93,2.86c-1.24,5.92-2.89,11.91-2.96,17.9c-0.21,17.65,0.33,35.3,0.41,52.95
				c0.05,10.48-0.36,20.95-0.3,31.43c0.1,17.27,0.22,34.55,0.71,51.81c0.31,10.9,1.63,21.78,1.88,32.68
				c0.17,7.43-1.07,14.89-1.09,22.34c-0.06,19.65,0.21,39.3,0.34,58.94c0.04,5.76-0.06,11.53,0.13,17.29
				c0.19,5.55,0.67,11.09,1.02,16.63c-0.43,0.03-0.85,0.07-1.28,0.1c-1.07-8.99-2.84-17.96-3.04-26.96
				c-0.28-12.61,0.81-25.25,0.96-37.88c0.22-18.94-2.1-37.94,1.55-56.8c0.37-1.9-0.09-3.97-0.27-5.96
				c-1.4-15.89-3.68-31.76-4.01-47.67c-0.39-19.1,1.18-38.23,1.37-57.35c0.16-16.47-0.63-32.95-0.57-49.42
				c0.03-7.33,1.1-14.65,1.71-22.02c-3.91-0.48-4.22-0.53-4.92,3.94c-0.85,5.37-0.97,10.86-1.72,16.26
				c-0.28,2.05-1.44,3.98-2.19,5.97c-1.4-1.64-3.48-3.08-4.06-4.97c-1.38-4.58-1.86-9.42-3.11-14.05c-0.53-1.96-2.04-5.17-3.18-5.2
				c-7.52-0.21-13.16-4.53-19.04-8.13c-5.15-3.15-8.35-8.06-9.45-14.17c1.47,1.04,2.34,2.47,3.26,3.87
				c6.48,9.8,15.45,15.45,27.32,15.32c5.94-0.06,11.85-1.53,17.81-1.85c10.65-0.58,20.66-3.31,30.01-8.35
				c5.08-2.74,10.24-4.67,16.13-4.22c4.31,0.33,7.5-1.69,10.42-4.83c3.26-3.5,7.1-6.45,10.62-9.7c2.06-1.9,4.66-3.6,5.88-5.98
				c4.36-8.56,9.76-17,11.98-26.16c4.65-19.18,8.26-38.68,10.99-58.23c4.43-31.71,4.93-63.67,4.43-95.67
				c-0.31-20.47,0.45-40.96,0.94-61.43c0.7-29.11,2.86-58.26,2.05-87.32c-0.85-30.93,2.84-61.5,4-92.24
				c0.88-23.4,3.17-46.76,3.58-70.17c0.46-26.3-0.08-52.63-0.95-78.92c-0.67-20.25-1.74-40.56-4.11-60.66
				c-2.25-19.08-5.39-38.11-12.85-56.2c-4.09-9.92-18.85-16.44-28.69-13.52c-4.7,1.4-7.33,4.74-8.8,9.7c1.9-1.43,3.35-2.52,5.67-4.27
				c-0.19,3.46-0.31,5.62-0.43,7.77c0.22,0.01,0.45,0.03,0.67,0.04c0.56-2.62,1.11-5.24,1.67-7.86c0.4,0.08,0.8,0.15,1.2,0.23
				c0,2.2,0,4.39,0,5.37c1.36-2.34,3.15-5.41,4.94-8.48c-0.01,0.82-0.03,1.64-0.04,2.46c-3.87,6.77-2.83,14.15-2.43,21.37
				c0.73,13.29,1.96,26.55,2.55,39.85c0.65,14.47,0.77,28.96,1.21,43.44c0.1,3.33,0.51,6.65,0.78,9.97c-0.65,0.04-1.3,0.09-1.95,0.13
				c-2.23-32.48-4.46-64.95-6.69-97.43c-0.53,6.08-1.29,12.21-1.11,18.31c0.74,26.12,1.99,52.22,2.6,78.34
				c0.51,21.79,0.52,43.6,0.5,65.4c-0.03,36.31-0.24,72.62-0.43,108.93c-0.01,1.74-0.48,3.47-0.74,5.21
				c-1.11-1.14-1.21-2.11-1.21-3.09c-0.02-18.16,0.05-36.31-0.07-54.47c-0.3-45.26,2.61-90.5-0.71-135.81
				c-2.15-29.35-1.6-58.9-2.25-88.36c-0.05-2.2-0.01-4.39-0.01-6.59c-0.56-0.07-1.11-0.14-1.67-0.21c-1.12,3.56-2.8,7.05-3.27,10.7
				c-3.99,31.43-4.84,63.03-4.08,94.66c0.53,22.12,2.03,44.22,2.43,66.34c0.26,14.45-0.74,28.92-1.08,43.38
				c-0.41,17.29-0.74,34.59-1.08,51.88c-0.33,16.78-0.74,33.55-0.9,50.33c-0.18,18.48-0.05,36.96-0.16,55.45
				c-0.1,16.13-0.22,32.25-0.55,48.38c-0.31,15.14-1.23,30.28-1.21,45.42c0.01,6.55,0.48,13.5,6.36,19.83
				c1.55-7.08,2.86-13.07,4.17-19.06c3.35,21.43-2.44,41.88-4.98,62.57c-2.52,20.5-6.81,40.79-10.33,61.17
				c0.91-0.55,1.34-1.23,1.6-1.98c1.06-2.97,2.15-5.93,3.07-8.94c10.68-34.86,15.53-70.75,17.43-106.98
				c2.02-38.4,3.14-76.85,4.18-115.29c0.76-27.8,0.82-55.61,1.07-83.42c0.14-15.92,0.03-31.85,0.03-47.77
				c1.15,4.98,1.16,9.95,1.15,14.93c-0.09,37.64,0.36,75.28-0.43,112.9c-0.8,38.1-2.74,76.18-4.35,114.27
				c-0.99,23.44-1.35,46.96-3.71,70.27c-3.47,34.38-8.82,68.53-17.97,101.98c-1.15,4.21-0.92,8.8-1.32,13.22
				c0.52,0.09,1.05,0.18,1.57,0.26c0.41-0.47,0.92-0.88,1.2-1.42c0.99-1.92,2.01-3.83,2.81-5.83c7.88-19.69,12.52-40.13,15.46-61.14
				c4.34-31,8.45-61.97,8.65-93.33c0.01-1.6,0.41-3.2,0.63-4.8c0.51,0.06,1.02,0.11,1.53,0.17c0,1.6,0.07,3.21-0.01,4.81
				c-0.67,13.63-1.21,27.27-2.1,40.89c-1.03,15.77-1.47,31.65-3.8,47.24c-2.62,17.53-7.19,34.77-10.65,52.18
				c-2.04,10.24-4.59,20.54-4.95,30.88c-0.22,6.42-0.83,12.42-2.82,18.42c-1.19,3.61-2.09,7.31-3.12,10.97
				c0.59,0.18,1.18,0.35,1.77,0.53c3.46-10.51,8.06-20.8,10.17-31.58c5.64-28.84,10.21-57.89,15.22-86.86
				c0.62-3.58,1.31-7.15,1.96-10.73c0.6,0.1,1.2,0.21,1.8,0.31c-1.82,14.93-3.56,29.87-5.5,44.78c-1.92,14.82-3.35,29.75-6.29,44.38
				c-2.92,14.54-7.46,28.75-11.28,43.1c2.24-1.72,4.22-3.69,5.07-6.05c2.95-8.27,6.59-16.49,8.02-25.06
				c5.2-31.11,10.6-62.24,14.02-93.58c2.63-24.09,2.91-48.48,3.27-72.76c0.24-16.11-1.24-32.25-1.89-48.38
				c-0.08-1.88,0.22-3.77,0.34-5.65c0.46,0.03,0.91,0.05,1.37,0.08c0.44,5.41,1.1,10.82,1.29,16.24c0.62,17.46,1.52,34.93,1.46,52.39
				c-0.09,23.46-0.25,46.96-1.72,70.36c-1.38,22.03-4.5,43.95-6.86,65.92c-0.3,2.84-0.62,5.67-1.06,9.63
				c1.13-1.38,1.58-1.7,1.71-2.13c0.47-1.59,0.83-3.21,1.25-4.82c8.27-31.1,9.92-62.99,11.03-94.91c0.46-13.12-0.81-26.3-0.32-39.42
				c1.1-30.09,3.15-60.15,4.15-90.24c1.11-33.44,1.79-66.91,2.05-100.37c0.22-27.64-0.56-55.28-0.69-82.93
				c-0.23-47.63-0.31-95.25-0.43-142.88c-0.04-18.55-0.01-37.09-0.01-55.64c-0.65,0-1.3,0-1.95,0c0,18.58,0,37.16,0,55.73
				c-0.48,0.02-0.97,0.03-1.45,0.05c-0.73-10.9-1.46-21.8-2.2-32.7c0.91,37.15,2.37,74.26,1.82,111.34
				c-0.55,36.89,0.41,73.88-3.97,110.68c-0.73-1.56-0.8-3.14-0.72-4.7c0.54-10.47,1.52-20.93,1.62-31.41
				c0.51-52.44,2.96-104.87,0.11-157.32c-1.48-27.23-1.94-54.54-7.77-81.35c-1.43-6.57-3.13-13.08-4.7-19.62
				c11.01,23.42,10.16,49.41,16.03,74.61c1.29-23.12-2.18-45.01-7.72-66.75c2.92,6.19,5.33,12.49,7.3,18.93
				c6.15,20.03,6.06,40.67,5.9,61.33c-0.26,33.97-0.59,67.95-0.53,101.92c0.09,50.95,1.19,101.9,0.53,152.84
				c-0.52,40.76-0.39,81.53-3.51,122.28c-2.11,27.65-0.7,55.55-1.4,83.32c-0.51,20.29-1,40.65-3.19,60.8
				c-1.87,17.27-6.07,34.29-9.44,51.38c-0.42,2.13-2.08,4.02-3.16,6.03c-0.72-0.21-1.44-0.42-2.15-0.62
				c1.13-19.58,1.56-39.19,4.67-58.68c-0.82,1.83-1.33,3.68-1.64,5.57c-2.68,16.39-4.8,32.9-8.17,49.15
				c-2.24,10.82-5.99,21.36-9.6,31.84c-1.42,4.12-4.05,7.99-10.6,9.41c8.62-25.21,15.33-49.95,17.88-75.58
				c-8.2,40.95-16.44,68.37-23.03,75.28c-1.87-6.1-0.19-11.65,1.7-17.01c2.46-6.97,4.37-13.82,3.22-21.4
				c-0.51-3.35,0.33-6.96,0.93-10.4c0.94-5.44,2.19-10.83,3.31-16.23c-1.99,3.71-3.18,7.69-4.88,11.43
				c-1.64,3.62-3.8,6.99-5.73,10.48c-0.79-0.13-1.59-0.27-2.38-0.4c-0.42-3.42-1.64-6.98-1.08-10.24c1.29-7.5,3.86-14.78,5.18-22.27
				c4.83-27.48,9.54-54.98,13.9-82.53c1.44-9.14,1.67-18.46,2.45-27.7c-0.83,0.34-1.05,0.76-1.08,1.19
				c-1.4,22.38-7.55,43.8-13.18,65.32c-1.04,3.97-2.92,7.76-4.69,11.49c-0.66,1.39-2.13,2.4-3.23,3.58c-0.26-0.2-0.52-0.41-0.78-0.61
				c5.88-36.98,11.75-73.96,17.63-110.94c-0.41-0.11-0.82-0.23-1.23-0.34c-0.96,1.16-1.92,2.32-3.98,4.81
				c-2.21-4.31-5.16-7.67-5.57-11.32c-1.16-10.19-1.76-20.51-1.66-30.77c0.13-13.12,1.16-26.24,1.79-39.35
				c-0.95,5.04-1.15,10.11-1.64,15.16c-1.7,17.7-4.69,35.37-4.99,53.09c-0.52,30.68-2.37,61.11-7.34,91.44
				c-3.84,23.47-6.45,47.08-15.07,69.53c-4.16,10.85-10.42,15.44-21.95,14.19c-2.31-0.25-4.65-0.48-6.9-1.04
				c-6.9-1.71-13.18-1.1-18.33,4.39c-2.15,2.3-3.96,5.13-6.52,6.79c-6.59,4.26-7.61,10.4-7.08,17.41c0.2,2.56,0.03,5.15,0.03,7.72
				c-0.84-0.01-1.67-0.02-2.51-0.03c0-5.16-0.34-10.35,0.14-15.46c0.25-2.65,1.24-6.03,3.13-7.58c8.41-6.93,13.67-18.99,26.57-17.16
				c0.75-10.48,1.84-20.36,2.11-30.26c1.22-45.11,2.37-90.21,3.22-135.33c0.55-29.14,0.21-58.29,0.87-87.43
				c0.41-18.13,2.44-36.21,2.86-54.34c0.84-36.31-0.53-72.56-4.01-108.74c-1.22-12.65-1.49-25.4-2.2-38.1
				c-0.94,5.6-1.14,11.21-0.91,16.81c1.09,26.76,3.4,53.53,3.2,80.28c-0.3,40.77-2.4,81.53-3.57,122.3
				c-1.07,37.12-2.27,74.24-2.74,111.36c-0.34,26.3,0.62,52.62,0.59,78.93c-0.01,8.27-1.1,16.57-2.2,24.79
				c-0.41,3.08-2.23,5.97-3.41,8.94c-0.71,0.04-1.42,0.08-2.14,0.12c-1.51-3.87-4.24-7.73-4.31-11.62
				c-0.32-17.92,0.06-35.86,0.29-53.79c0.17-13.11,1.04-26.24,0.64-39.34c-0.38-12.28-2.95-24.53-2.86-36.78
				c0.13-17.61,1.64-35.22,2.85-52.81c1.34-19.41,3.53-38.78,4.36-58.21c0.75-17.44,0.16-34.94,0.16-52.42c-0.4,0-0.8,0.01-1.2,0.01
				c0,2.48,0.03,4.95-0.01,7.43c-0.17,12.62,0.08,25.27-0.65,37.86c-1.14,19.59-3.17,39.12-4.43,58.7
				c-1.06,16.61-1.63,33.25-2.29,49.88c-0.96,24.44-1.83,48.88-2.67,73.32c-0.76,22.11-1.31,44.24-2.24,66.34
				c-0.22,5.34-1.58,10.8-6.42,13.99c-1.44,0.95-4.25,0.28-6.23-0.31c-0.75-0.22-1.69-2.9-1.24-3.5c4.45-6.07,3.56-13.37,4.98-20.12
				c0.72-3.4,1.55-6.77,2.34-10.16c0.62,0.07,1.23,0.14,1.85,0.21c3.01,9.33,0,19,1.31,28.53c1.55-8.76,3.04-17.59,3.24-26.44
				c0.46-20.13-0.28-40.3,0.47-60.42c1.45-38.57,3.4-77.13,5.55-115.67c1.27-22.92,3.54-45.78,4.88-68.69
				c0.53-9.1,0.3-18.33-0.55-27.41c-1.25-13.22-4.34-26.32-4.92-39.54c-1.27-29.1-1.75-58.24-1.93-87.36
				c-0.06-9.56,0.99-19.39,6.73-27.72c1.83-2.66,4.4-4.81,6.63-7.2c-0.91,5.03-2.98,9.3-3.44,13.74c-1.65,15.69-3.49,31.41-3.9,47.16
				c-0.67,26.28-0.8,52.6,2.71,78.79c1.68,12.51,3.55,25.14,3.47,37.7c-0.13,21.77-1.53,43.54-2.74,65.29
				c-0.85,15.28-2.31,30.52-3.45,45.79c-1.42,18.95-1.71,37.83,0.42,56.82c1.49,13.32,1.65,26.86,1.48,40.29
				c-0.25,19.78-1.46,39.54-1.97,59.32c-0.14,5.39,0.82,10.78,4.98,15.37c1.7-5.91,3.36-11.84,3.59-17.82
				c0.52-13.3,0.28-26.62,0.44-39.93c0.55-44.95,0.84-89.9,1.82-134.84c0.87-39.77,2.5-79.53,3.85-119.29
				c1.13-32.98-0.1-65.87-2.74-98.77c-1.22-15.23-1.62-30.63,2.69-45.63c0.28-0.96,0.87-1.83,1.91-3.95c0,8.71-0.35,16.01,0.06,23.26
				c1.67,29.74,4.04,59.44,5.22,89.19c0.83,21.11,1.29,42.35-0.08,63.4c-2.5,38.43-3.11,76.85-2.93,115.32
				c0.06,12.16,0.06,24.32-0.33,36.47c-1.45,45.41-3.15,90.82-4.59,136.23c-0.36,11.39,0.32,11.41,11.5,12.2
				c11.51,0.82,17.41-8.96,16.89-17.53c-0.38-6.14-0.27-12.32-0.4-18.47c-0.45-20.95,0.25-41.9-3.22-62.76
				c-2.47-14.87-4.65-30.03-4.55-45.04c0.26-38.27,2.04-76.53,2.89-114.8c0.83-37.46,1.28-74.93,1.88-112.39
				c0.37-23.14,0.45-46.25-3.29-69.21C1007.54,543.04,1004.8,530.82,996.45,520.64z M126.21,641.59c-3.98-2.06-7.55-2.28-11.23,1.11
				c-3.09,2.83-6.31,5.4-11.44,2.67c-2.17-1.15-6.12,0.29-8.97,1.35c-3.07,1.15-5.64,3.62-8.7,4.81c-3.6,1.4-7.46,2.36-11.29,2.9
				c-2.65,0.38-5.83-1.19-8.12-0.27c-8,3.23-17.44-1.78-24.8,4.57c-0.43,0.37-1.59-0.06-2.4-0.18c-3.28-0.48-6.56-1-9.85-1.48
				c-10.65-1.57-11.68-1.11-17.26,8.22c-1.53,2.56-2.78,5.29-4.23,7.89c-0.64,1.14-1.31,2.88-2.27,3.11
				c-4.86,1.16-3.83,4.89-3.5,7.95c0.63,5.87,5.68,9.11,11.09,7.34c14.2-4.62,28.29-9.6,42.61-13.8c4.73-1.39,10.17-1.73,15.01-0.92
				c3.96,0.66,7.28,0.63,10.32-1.58c5.5-4,10.63-8.54,16.2-12.43c3.03-2.12,6.62-4.59,10.04-4.72c4.71-0.19,7.2-2.29,9.7-5.62
				c2.83-3.78,6.04-7.28,9.09-10.9c-3.67,5.51-7.2,11.12-11.12,16.45c-0.87,1.18-3.13,1.99-4.68,1.88
				c-5.28-0.36-9.13,1.92-12.95,5.25c-5,4.35-10.23,8.55-15.84,12.06c-2.52,1.58-6.46,2.41-9.26,1.68
				c-6.14-1.58-11.74-0.91-17.55,1.08c-12.87,4.4-25.8,8.64-38.73,12.83c-4.9,1.59-10.16,4.4-13.86-2.38c0,13.27,0,26.54,0,38.7
				c4.67,3.24,8.8,5.91,12.7,8.88c2.71,2.06,4.28,2.12,7.01-0.58c5.13-5.07,11.01-9.39,16.74-13.82c1.02-0.79,2.91-0.98,4.26-0.72
				c7.88,1.52,13.5-1.54,17.52-8.18c0.51-0.84,1.27-1.54,1.95-2.27c5.55-5.9,7.19-6.13,14.04-1.91c0.58-0.54,1.22-1.07,1.77-1.68
				c14.9-16.57,29.55-33.38,44.82-49.61c5.48-5.83,12.46-10.26,18.75-15.33c1.02-0.82,2.27-1.49,2.99-2.53
				c4.45-6.42,8.39-13.23,13.23-19.32c6.76-8.5,14.16-16.48,21.28-24.68c0.47,0.34,0.93,0.69,1.4,1.03c-0.25,0.87-0.3,1.88-0.78,2.6
				c-9.28,13.77-18.47,27.6-27.97,41.21c-10.46,14.99-21.01,29.92-31.9,44.59c-5.81,7.84-12.01,15.48-18.77,22.49
				c-5.61,5.81-12.38,10.49-18.51,15.82c-7.53,6.55-14.35,14.09-22.49,19.73c-18.13,12.55-37.8,22.85-54.19,37.98
				c-2.72,2.51-4.13,4.45-4.12,8.16c0.12,41.64,0.08,83.28,0.08,124.92c0,1.76,0,3.52,0,6.22c3.71-2.01,6.56-3.59,9.45-5.11
				c12.01-6.31,24.43-10.26,38.34-7.95c2.12,0.35,5.41-1.57,6.99-3.44c10.62-12.58,21.08-25.3,31.28-38.22
				c7.62-9.65,16.83-16.43,29.1-18.71c2.6-0.48,5.53-0.83,7.59-2.27c15.47-10.72,35.06-11.78,50.78-21.9
				c7.08-4.56,14.68-8.3,21.8-12.8c1.53-0.96,2.8-3.57,2.84-5.45c0.32-14.13-0.1-28.28,0.58-42.39c0.3-6.36,2.22-12.73,4.07-18.9
				c1.51-5.02,7.04-6.35,10.79-2.66c1.97,1.95,3.62,4.95,3.99,7.66c0.88,6.4,1.12,12.91,1.24,19.39c0.18,9.63,0.05,19.26,0.05,28.97
				c6.5,1.26,11.67,2.26,17.4,3.37c0-1.77,0.21-3.26-0.03-4.67c-3.69-21.44-0.19-42.83,0.44-64.24c0.09-3.1,0.91-6.3,2.01-9.22
				c2.35-6.26,6.41-6.97,11.51-2.68c5.54,4.66,5.66,11.05,5.87,17.35c0.39,11.79,0.29,23.59,0.83,35.37
				c0.47,10.27,1.54,20.5,2.36,30.93c4.54,1.05,9.04,2.09,14.21,3.29c0-4.63-0.28-8.77,0.06-12.86c0.64-7.75,1.47-15.49,2.55-23.19
				c0.47-3.38,1.13-7.8,5.48-7.78c4.3,0.02,5.35,4.36,6.56,7.83c0.22,0.63,0.42,1.27,0.51,1.92c1.45,11.02,3.17,22.01,4.15,33.07
				c0.35,3.9,1.92,5.19,5.83,5.83c0-3.38,0.06-6.51-0.01-9.64c-0.35-14.78-0.97-29.55-1.03-44.33c-0.06-13.76,0.24-27.53,0.84-41.28
				c0.19-4.38,1.94-8.58,7.59-8.97c0.75-0.05,1.54-0.62,2.17-1.12c11.23-8.84,22.11-18.18,33.74-26.46
				c13.5-9.61,27.18-19.16,41.66-27.13c15.09-8.31,27.51-19.66,40.25-30.78c1.96-1.71,3.13-4.29,4.81-6.35
				c1.6-1.96,3.39-3.76,5.1-5.64c-0.71,7.79-7.48,13.12-7.11,20.62c29.13-9.68,46.83-31.94,62.67-55.54
				c-1.2,0.36-2.83,0.84-5.94,1.76c1-4.23,1.51-7.24,2.42-10.12c3.66-11.55,13.59-16.38,23.43-21.42c0.25,1.01,0.51,2.08,0.89,3.59
				c6.56-5.38,12.18-10.53,14.52-18.57c1.56-5.35,5.38-8.49,11.35-8.92c2.06-0.15,5.18-1.33,5.79-2.86
				c2.15-5.34,3.41-11.04,5.07-16.84c-12.19-0.9-19.92,1.05-24.33,9.73c-2.28,4.49-5.95,8.44-9.58,12.03
				c-2.25,2.22-5.61,3.3-9.13,5.27c-0.49-3.3-0.77-5.15-1.12-7.49c-3.32,1.98-6.21,3.71-9.69,5.79c0-2.97,0-5.13,0-7.41
				c-1.9,0.91-3.49,1.67-5.07,2.42c-0.47-0.29-0.95-0.58-1.42-0.87c2-5.08,4-10.16,6.48-16.44c-13.27,9.87-25.55,18.95-37.78,28.1
				c-5.85,4.38-11.43,9.15-17.46,13.27c-11.5,7.86-23.01,15.76-34.97,22.89c-6.46,3.85-13.75,6.38-20.83,9.06
				c-4.08,1.54-8.49,2.2-11.57,2.96c0.83,3.03,2.58,5.84,2,8.03c-1.38,5.22-3.73,10.2-5.92,15.17c-2.26,5.13-5.19,10-7.12,15.24
				c-2.85,7.72-2.56,7.62-10.83,6.88c-2.27-0.2-5.89,0.43-6.83,1.95c-4.15,6.71-8.93,13.52-10.91,20.97
				c-3.17,11.99-9.95,22.01-14.86,33.02c-5.35,11.98-16.75,17.13-26.54,23.94c-4.59,3.19-10.52,4.43-16.46,6.8
				c-0.7-7.09,3.07-11.69,5.84-17.07c-6.34,2.77-12.11,6.19-17.92,9.54c-1.41,0.81-2.93,1.46-4.44,2.09
				c-6.53,2.75-13.13,5.34-19.59,8.24c-4.54,2.03-8.92,4.43-13.33,6.74c-2.83,1.48-5.17,1.24-6.52-1.86
				c-1.34-3.07-3.61-3.37-6.08-2.05c-3.78,2.03-7.55,4.17-11,6.7c-8.74,6.42-17.3,13.09-26.68,20.23c0.9-1.48,1.2-2.43,1.84-2.95
				c10.14-8.06,20.27-16.14,30.56-24.01c1.78-1.36,4.18-2.2,6.41-2.56c1.58-0.25,4.44,0.26,4.86,1.23c2.26,5.28,5.52,3.45,8.86,1.74
				c3.54-1.82,6.97-3.89,10.56-5.61c5.52-2.64,11.16-5.05,16.72-7.62c3.09-1.43,6.4-2.58,9.13-4.53c7.13-5.08,15.3-6.94,23.7-8.77
				c-4.44,5.19-9.79,9.52-10.82,17.38c3.8-1.67,7.22-2.87,10.35-4.61c10.42-5.8,20.87-11.61,27.07-22.52
				c2.87-5.05,6.25-9.87,8.68-15.12c5.26-11.34,10.08-22.89,15.04-34.37c4.16-9.63,4.86-9.97,15.23-9.02
				c1.49,0.14,3.93-0.97,4.57-2.21c3.79-7.32,7.38-14.77,10.62-22.35c1.68-3.92,3.34-8.19,3.36-12.31c0.01-1.92-3.91-3.86-6.02-5.82
				c-0.19-0.18-0.14-0.61-0.22-1.05c0.34-0.25,0.68-0.65,1.09-0.77c1.27-0.38,2.57-0.66,3.87-0.95c13.49-3.02,26.93-5.9,37.92-15.38
				c4.47-3.85,9.91-6.59,14.98-9.73c6.7-4.15,13.46-8.19,20.5-12.46c-9-3.58-17.18-6.67-25.23-10.08
				c-8.1-3.43-16.19-6.93-24.07-10.84c-14.73-7.31-29.33-14.89-43.92-22.47c-1.72-0.89-3.11-2.45-4.59-3.77
				c-4.05-3.62-7.77-7.7-12.17-10.82c-10.03-7.1-20.39-13.73-30.61-20.56c-0.96-0.64-1.83-1.43-2.73-2.16
				c-3.66-2.93-7.31-5.85-11.16-8.93c-0.25,2.96-0.42,4.89-0.57,6.66c6.07,3.71,7.02,11.02,11.04,16.04
				c4.02,5.01,9.47,8.88,14.32,13.27c-2.35,1.33-5.13,2.12-6.7,3.95c-5.53,6.47-13.01,8.14-20.76,9.65
				c-2.18,0.42-4.84,1.53-6.04,3.21c-3.94,5.49-9.2,7.88-15.54,9.84c-14.38,4.43-29.05,8.33-41.9,16.8
				c-3.39,2.23-7.46,3.7-11.43,4.71c-11.73,2.98-23.63,5.27-35.31,8.4c-5.54,1.48-9.8,0.62-14.35-2.78
				c-6.9-5.15-13.76-10.91-21.57-14.15c-12.88-5.33-26.51-8.85-41.41-13.63c0.42,3.05,0.07,5.6,1.14,7.17
				c1.64,2.4,4.47,3.96,6.43,6.2c2.01,2.29,4.39,4.74,5.14,7.52c1.11,4.13,3.4,6.3,7.2,7.3c3.58,0.95,7.21,1.75,11.79,2.85
				c-1.94,2.25-3.18,3.71-4.45,5.17c-4.38,5.02-3.43,8.85,2.59,10.94c3.12,1.08,6.16,2.41,9.24,3.63c-0.11,0.46-0.22,0.93-0.33,1.39
				c-4.21,0.93-8.41,1.87-12.62,2.8c0.01,0.37,0.01,0.73,0.02,1.1c8.35,4.06,16.71,8.12,25.06,12.18c-0.08,0.56-0.15,1.11-0.23,1.67
				c-27.08-0.43-54.06,0.06-80.3,8.25c18.44-3.6,35.33,1.7,53.42,8.8c-3.53,0-5.73,0.25-7.85-0.04c-9.59-1.33-18.81,0.08-27.97,2.96
				c-4.32,1.36-8.87,2.11-13.37,2.77c-2.31,0.34-4.74-0.13-8.3-0.29c6.78,6.37,12.55,11.8,18.32,17.23c-0.25,0.46-0.5,0.92-0.75,1.39
				c-24.78-15.93-51.56-24.11-81.15-22.16c0,24,0,47.7,0,71.39c0.39,0.21,0.77,0.42,1.16,0.63c1.05-1.4,2.16-2.76,3.14-4.21
				c3.35-4.92,6.46-10.02,10.06-14.75c2.16-2.85,5.22-3.29,8.96-2.37c7.43,1.84,15.06,4.53,22.44-0.5c0.76-0.52,1.9-0.61,2.88-0.69
				c5.36-0.43,10.73-0.79,16.1-1.16c1.65-0.11,3.53-0.71,4.93-0.14c5.54,2.23,9.96-0.45,14.49-2.81c2.95-1.53,5.66-3.75,8.77-4.67
				c3.03-0.89,6.67-1.36,9.59-0.49c3.17,0.94,5.19,0.34,7.64-1.38c4.48-3.16,8.73-8.17,15.11-2.77
				C127.26,640.08,126.73,640.83,126.21,641.59z M293.73,346.47c2.48,6.72,8.55,10.16,15.09,13.39c7.02,3.47,7.68,3.52,11.7-3.47
				c0.66-1.15,1.24-2.35,1.76-3.57c3-7.05,4.36-7.68,11.18-3.57c3.02,1.82,5.35,4.76,8.05,7.14c1.97,1.73,3.8,4.29,6.08,4.82
				c4.41,1.03,9.09,1.14,13.66,1.18c8.05,0.06,15.88-2.34,24.23-0.27c7.41,1.83,15.38,1.47,23.12,1.84c1.28,0.06,2.62-1.06,3.93-1.65
				c3.28-1.47,6.42-3.46,9.85-4.33c10.2-2.57,20.05,0.22,29.99,2.34c5.21,1.11,10.91,2.3,15.95,1.28c7.47-1.51,14.63-3.77,22.36-1.89
				c0.85,0.21,2.53-1.28,3.22-2.37c3.91-6.11,8.82-8.31,16.03-7.12c1.28,0.21,3.15,0.2,3.94-0.56c7.83-7.46,17.22-10.94,27.86-11.56
				c1.32-0.08,2.96,0.05,3.87-0.66c5.61-4.37,11.72-3.21,17.6-1.4c3.56,1.09,5.45,0.03,7.51-2.57c6.57-8.28,15.05-11.14,25.61-9.91
				c9.88,1.15,18.63,4.41,26.72,10.04c2.01,1.4,4.66,2.39,7.09,2.58c3.68,0.28,7.43-0.57,11.14-0.46c7.45,0.23,15.1,2.09,22.26,0.85
				c7.18-1.24,12.76-0.27,17.89,4.37c1.88,1.7,3.28,1.89,5.62,0.67c6.61-3.48,13.67-3.51,20.09,0.08c5.18,2.89,10.1,4.72,16.22,4.38
				c4.52-0.26,9.15,1.99,13.71,1.91c6.3-0.11,11.53,1.68,15.64,6.23c3.36,3.72,6.63,5.13,11.8,3.34c6.73-2.33,13.19,0.65,17.16,6.89
				c-0.67,0.42-1.32,1.17-2.01,1.21c-58.69,3.31-117.38,6.57-176.4,9.85c0.51,2.3,0.92,4.19,1.27,5.77c3.27-0.69,6.52-2.36,9.29-1.78
				c8.63,1.81,17.34,3.88,23.82,10.82c1.33-0.96,2.41-1.71,3.45-2.49c10.77-8.05,18.06-7.6,27.73,1.68c0.54,0.52,1.48,1.33,1.92,1.17
				c4.57-1.71,8.08-0.3,11.03,3.32c0.28,0.34,1.29,0.39,1.81,0.19c9.25-3.57,16.07,3.37,23.86,5.8c2.08,0.65,4.56,4.68,4.32,6.87
				c-0.28,2.5-2.97,5.82-5.38,6.74c-3.89,1.49-8.41,1.44-12.69,1.8c-6.25,0.52-12.52,0.87-18.78,1.22
				c-8.13,0.45-16.26,0.91-24.4,1.22c-12.96,0.49-25.92,1.06-38.89,1.16c-3.89,0.03-7.8-1.3-11.67-2.18
				c-4.95-1.12-9.85-3.33-14.8-3.45c-11.13-0.26-22.28,0.6-33.42,0.78c-10.74,0.17-21.49,0.04-32.52,0.04
				c3.27,6.17,3.62,6.33,9.94,5.56c0.78-0.1,1.71,0,2.39,0.35c7.79,3.99,16.26,7.09,19.27,16.79c0.71,2.28,2.82,5.09,4.91,5.79
				c5.59,1.87,11.56,2.58,17.3,4.05c3.76,0.97,7.54,2.14,11.01,3.83c2.95,1.44,5.27,4.19,8.24,5.58c4.75,2.23,9.87,5.48,14.69,5.25
				c6.11-0.29,11.69-0.99,16.48,3.36c1.18-1.1,2.03-1.69,2.62-2.47c5.97-7.81,13.33-12.25,23.64-10.56c1.35,0.22,3.12-1.42,4.54-2.44
				c3.49-2.51,6.91-5.11,10.3-7.74c3.05-2.36,6.53-4.41,8.96-7.31c4.7-5.62,8.59-12.14,16.22-14.14c4.18-1.1,8.62-1.24,12.92-1.92
				c6.82-1.09,13.62-3.11,20.45-3.18c7.1-0.07,11.76-2.31,15.68-8.29c2.39-3.64,6.3-7.7,10.21-8.61c6.65-1.54,13.9-1.38,20.79-0.77
				c12.7,1.13,25.31,3.12,37.97,4.69c1.11,0.14,2.51,0.16,3.39-0.38c4.23-2.58,8.64-2.94,13.42-2.08c6.01,1.08,12.07,1.83,18.12,2.67
				c1.96,0.27,3.98,0.7,5.9,0.46c6.75-0.86,13.44-2.19,20.2-2.93c5.81-0.64,11.53-0.39,16.63,3.34c6.93,5.06,13.88,10.15,21.16,14.68
				c4.02,2.5,6.5,5.48,8.52,9.82c2.08,4.47,6.03,8.07,9.16,12.05c0.45-0.31,0.9-0.62,1.35-0.93c0-5.32-0.04-10.64,0.01-15.97
				c0.37-40.27,0.93-80.55,1.11-120.82c0.17-38.13-0.06-76.25-0.13-114.38c0-1.61-0.15-3.23-0.27-5.77c-5.08,0-9.86,0.07-14.65-0.01
				c-19.62-0.35-39.26-1.52-58.84-0.95c-32.09,0.94-64.15,2.88-96.21,4.67c-13.44,0.75-26.86,2.01-40.26,3.37
				c-19.83,2.02-39.52,5.01-59.65,3.77c-16.71-1.02-33.53-0.25-50.3-0.28c-5.29-0.01-10.62,0.36-15.84-0.26
				c-3.66-0.43-8.33-1.42-8.82-5.97c-0.49-4.62,2.68-8.12,6.61-10.38c1.82-1.04,4.08-1.88,6.13-1.86
				c12.72,0.15,24.03-5.87,36.23-7.97c4.66-0.8,8.26-3.03,11.16-7.42c2.15-3.26,6.35-5.78,10.16-7.17c4.43-1.61,9.47-1.52,14.2-2.4
				c2.21-0.41,5.18-0.71,6.33-2.2c4.43-5.77,10.48-6.45,16.93-6.72c5.15-0.22,10.89,0.62,15.28-1.39c6.73-3.09,12.78-2.26,19.35-0.82
				c1.64,0.36,4.2-0.09,5.29-1.2c6.7-6.84,14.73-9.2,23.71-6.69c6.97,1.96,13.64,5.03,20.37,7.8c2.67,1.1,4.42,1.82,6.54-1.43
				c4.27-6.55,11.07-9.61,18.63-10.12c6.11-0.42,12.29,0.25,18.43,0.4c4.15,0.1,8.3,0.31,12.44,0.19c5.25-0.15,10.5-0.6,15.75-0.9
				c2.63-0.15,5.82,0.65,7.8-0.55c5.88-3.55,11.82-2.54,17.9-1.57c3.12,0.49,6.23,1.4,9.34,1.4c2.03,0,4.11-1.2,6.08-2.06
				c3.73-1.63,7.38-4.78,11.09-4.82c7.87-0.08,15.75,1.42,23.63,2.31c3.77,0.43,7.89,2.14,11.24,1.17c9.47-2.73,18.63-6.53,27.9-9.95
				c0.59-0.22,1.12-0.74,1.54-1.23c7.84-9.24,17.37-9.92,28.04-5.87c1.34,0.51,3.68,0.18,4.77-0.72
				c17.66-14.45,37.73-18.01,59.59-13.32c6.33,1.36,12.49,3.51,18.82,4.84c2.39,0.51,5.27,0.32,7.54-0.57
				c3.02-1.18,5.71-3.27,8.43-5.12c1.75-1.19,3.73-2.38,4.89-4.06c3.33-4.86,7.95-7.48,13.51-8.65c5.36-1.13,10.78-1.99,16.16-3.06
				c3.12-0.62,5.05-2.25,6.25-5.57c5.41-15.02,15.75-21.53,31.14-20.13c0-15.55,0-31.1,0-46.51c-386.49,0-772.55,0-1158.62,0
				c0,102.48,0,204.53,0,306.86c13.51,0,26.72-0.06,39.93,0.02c7.42,0.04,14.93-0.36,22.22,0.7c5.24,0.76,9.11,0.05,12.42-3.95
				c3.72-4.5,8.73-6.83,14.32-7.21c5.61-0.37,11.37-0.4,16.89,0.47c9.81,1.55,19.37,4.24,29.41,0.66c2.58-0.92,6.09,0.85,9.19,1.3
				c3.58,0.53,7.24,1.64,10.76,1.33c10.35-0.92,12.93,0.54,16.11,11.56c5.67-0.91,11.6-1.38,17.25-2.9
				c7.17-1.93,13.91-2.26,20.9,0.78c2.91,1.26,6.4,1.54,9.64,1.6c9.46,0.15,18.92-0.01,28.38-0.13c1.61-0.02,3.28-0.25,4.81-0.72
				c8.74-2.67,17.17-3.72,26.24-0.16c5.63,2.21,12.06,3.1,18.51,1c7.2-2.34,14.47-2.13,22.07-0.37c9.64,2.23,19.63,3.97,29.48,4.02
				c10.12,0.06,19.33,2.32,28.5,6c4.2,1.69,4.32,4.65,1.48,6.64c-3.35,2.34-7.72,3.18-11.62,4.76c-1.73,0.7-4.01,1.19-4.95,2.54
				c-5.5,7.84-8.6,9.27-18,6.98c-5.44-1.32-10.7-2.05-16.08,0.36c-1.92,0.86-4.19,1.17-6.32,1.27
				C313.84,345.73,303.78,346.07,293.73,346.47z M535.46,745.78c14.17-6.71,27-12.86,39.9-18.85c13-6.03,22.65-17.73,37.82-22.64
				c-2.41,3.94-4.15,6.78-5.89,9.62c0.35,0.42,0.7,0.84,1.05,1.26c10.08-5.49,21.08-9.81,29.99-16.79
				c9.04-7.09,16.02-16.82,24.65-26.19c0.16,2.07,0.35,2.85,0.26,3.6c-0.75,5.68-2.94,11.42,0.89,16.9c0.25,0.36-0.02,1.39-0.39,1.84
				c-3.43,4.2-6.33,9.16-10.55,12.33c-8.03,6.04-12.7,14.51-19.72,22.72c1.83-0.35,2.35-0.31,2.71-0.54
				c11.8-7.56,25.14-12.95,33.15-25.64c3.47-5.5,8.66-9.63,15.47-11.17c-1.5,4.96-3.65,9.37-5.5,13.9
				c-1.91,4.67-3.52,9.47-5.27,14.21c7.8-4.94,15.17-9.49,19.37-17.89c2.75-5.49,7.46-10,11.29-14.95
				c-0.54,13.07-7.38,27.49-15.67,35.94c-2.56,2.61-5.31,5.05-7.85,7.68c-3.93,4.09-6.69,8.8-7.72,15.38
				c3.31-1.33,5.97-2.2,8.44-3.43c5.01-2.52,10.31-3.42,15.43-1.29c5.78,2.41,9.28,0.11,12.46-4.28c2.05-2.83,3.96-5.86,6.47-8.23
				c5.13-4.87,10.42-9.62,16-13.95c7.58-5.88,15.75-11,23.18-17.04c6.39-5.19,8.81-5.59,15.38-0.04c-1.61,3.61-3.22,7.23-5.22,11.74
				c9.67-2.67,17.18-6.14,22.41-13.49c3.56-5.01,7.47-9.78,11.12-14.74c0.64-0.87,1.21-2.15,1.12-3.17
				c-0.89-9.91-1.93-19.8-2.93-29.7c-0.81-8.06-1.76-16.12-2.39-24.2c-0.75-9.59-0.9-19.23-1.92-28.79
				c-0.87-8.23-2.83-16.34-3.84-24.56c-0.62-5.06-0.14-10.25-0.51-15.36c-0.69-9.42-1.67-18.82-2.48-28.23
				c-0.12-1.42-0.02-2.87-0.02-5.3c-2.29,1.35-4.25,1.88-5.17,3.16c-2.43,3.38-4.4,7.09-7.07,11.53c2.36,2.35,5.7,5.11,8.28,8.45
				c1.65,2.14,3.18,5.22,2.99,7.74c-0.54,7.09-1.89,14.13-3.18,21.15c-0.4,2.14-1.65,4.12-2.71,6.63c-4.2-3.73-5.87-7.16-4.19-12.27
				c1.49-4.53,2.39-9.38,2.64-14.13c0.14-2.65-1.02-5.76-2.55-8.02c-2.7-3.97-6.3-7.31-9.21-11.15c-4.55-6.01-3.22-7.97,3.3-11.55
				c5.39-2.96,9.65-7.97,14.38-12.11c0.32-0.28,0.52-0.91,0.49-1.36c-0.49-7.63,0.24-15.49-4.45-23.66
				c-6.47,5.88-12.67,11.3-18.58,17.01c-2.95,2.85-6.08,3.96-9.94,2.77c-3.8-1.17-7.57-2.45-11.32-3.79
				c-2.69-0.96-6.6-1.04-6.06-5.24c0.53-4.14,0.76-8.65,5.74-10.74c4.58-1.92,9.09-4.11,13.35-6.64c2.76-1.64,5.19-2.12,7.83-0.32
				c4.43,3.03,8.1,1.52,11.53-1.75c2.05-1.95,4.15-3.85,6.12-5.88c4.14-4.25,5.04-11.89,1.96-18.01c-2.36,2.18-4.58,4.36-6.93,6.4
				c-8.65,7.5-16.98,15.12-29.42,16.5c-4.84,0.54-9.43,4.46-13.83,7.31c-3.18,2.06-5.64,5.26-8.84,7.28
				c-2.68,1.68-5.97,3.3-8.99,3.31c-6.81,0.02-13.1,1.49-19.19,4.31c-8.51,3.94-18.12,2.98-26.92,6.4c-6.3,2.45-13.64,2.23-20.46,3.2
				c3.26,2.93,6.25,5.61,8.49,7.63c-6.76-2.97-14.14-6.07-21.38-9.49c-2.65-1.25-5.55-2.8-7.28-5.03c-3.45-4.44-7.05-4.73-11.68-2.32
				c-2.49,1.3-5.29,1.98-7.88,3.09c-3.79,1.62-7.35,1.24-11.02-0.5c-4.2-1.99-7.85-5.73-13.3-4.27c-0.9,0.24-2.33-1.08-3.36-1.87
				c-1.97-1.53-3.79-3.25-5.74-4.81c-3.06-2.43-6.09-4.87-10.09-1.37c-0.65,0.57-2.52,0.59-3.26,0.07c-3.53-2.49-6.35-0.6-9.12,1.23
				c-3.55,2.35-7.07,2.37-10.87,0.69c-4.75-2.1-9.47-4.41-14.44-5.82c-2.57-0.73-6.43-0.78-8.31,0.67
				c-5.76,4.43-11.52,9.2-15.99,14.86c-3.92,4.96-6.11,11.29-9.07,17.02c0.39,0.25,0.78,0.5,1.17,0.75
				c7.94-9.24,14.55-19.95,26.78-24.54c-4.78,5.9-9.97,11.39-15.51,16.51c-3.64,3.36-6.66,6.74-6.25,12.27
				c3.3-1.54,6.31-2.94,9.88-4.6c0,3,0,4.93,0,7.97c2.53-1.63,4.79-2.48,6.18-4.09c3.68-4.26,7.2-8.7,10.38-13.34
				c4.25-6.2,14.64-11.12,21.99-9.52c1.65,0.36,4.32,2.64,4.16,3.64c-0.93,5.52-2.07,11.15-4.26,16.25
				c-0.92,2.14-4.47,4.14-7.02,4.41c-6.01,0.64-9.04,3.55-10.96,9.11c-3.14,9.05-9.98,15.15-18.03,19.9
				c-0.56,0.33-2.04-0.89-3.53-1.6c-10.71,2.81-15.66,11.5-18.74,22.93c3.79-1.63,6.65-2.86,9.51-4.09c0.3,0.41,0.61,0.83,0.91,1.24
				c-10.95,10.5-17.62,24.15-28.07,35.3c-12.18,12.99-27.51,19.96-43.44,29.26c0.19-4.37,0.34-7.85,0.51-11.65
				c-8.14,7.13-15.48,14.8-24.01,20.74c-11.15,7.76-23.05,14.5-34.97,21.07c-19.1,10.52-34.42,26.03-51.93,38.63
				c-1.6,1.15-2.91,2.69-4.42,4.11c5.36,7.21,6.36,15.57,6.88,23.83c1.01,15.9,1.39,31.85,2.06,47.78
				c0.46,10.94,0.96,21.88,1.48,33.62c6.34-1.05,12.96-0.93,18.53-3.3c14.77-6.27,29.1-13.59,43.59-20.53
				c6.49-3.11,12.93-7.2,19.14,0.18c4.46-3.64,8.73-6.62,12.35-10.25c3.23-3.24,5.8-7.15,8.55-10.85c1.34-1.8,2.25-3.95,3.69-5.65
				c3.04-3.56,6.3-6.93,9.76-10.69c-6.43-2.31-11.04-1.52-15.98,2.14c-7.75,5.75-15.41,11.62-23.31,17.15
				c-2.41,1.68-5.47,2.42-8.24,3.59c-0.28-0.45-0.57-0.9-0.85-1.35c1.03-1.19,1.99-2.46,3.11-3.56c7.91-7.85,15.73-15.8,23.85-23.43
				c3.98-3.74,8.36-7.15,12.94-10.12c1.54-1,4.26-0.16,6.44-0.16c0.13,0.43,0.26,0.85,0.39,1.28c4.03-2.9,7.82-6.22,12.13-8.61
				c9.93-5.51,20.11-10.57,30.19-15.81c0.29-0.15,0.64-0.25,0.96-0.25c8.57,0.1,15.37-4.84,22.82-7.94c2.63-1.09,3.62-2.62,3.77-5.59
				c0.42-8.43,1.33-16.84,2.08-25.26c0.31-3.52,0.7-7.04,1.21-12.09c-4.77,5.11-8.56,9.01-12.15,13.08c-2.07,2.35-3.96,3.13-7.3,2.12
				c-2.77-0.84-6.63-0.93-9.03,0.41c-8.06,4.51-15.65,9.86-23.49,14.75c-2.05,1.28-4.3,2.47-6.63,3.03
				c-7.4,1.78-14.87,3.26-22.32,4.83c-1.33,0.28-2.69,0.44-5.2,0.83c4-7.61,9.71-11.8,15.39-16.06c7.84-5.89,15.6-11.89,23.35-17.88
				c0.74-0.58,1.6-1.42,1.76-2.27c0.83-4.4,1.45-8.85,2.17-13.46c-3.49-1.46-5.42-1.55-9.26,1.76c-5.15,4.43-9.83,9.43-14.73,14.16
				c-3.28,3.17-6.59,6.29-9.94,9.5c-5.18-6.87-5.16-11.69,1.48-16.57c15.32-11.27,29.46-24.26,47.88-31.08
				c8.2-3.04,16.03-8.06,22.84-13.64c6.21-5.08,10.62-12.31,16.18-18.26c4.18-4.47,8.33-9.46,13.52-12.38
				c8.52-4.78,17.88-8.05,25.91-11.54c-2.05,7.15-4.5,15.69-6.95,24.24c0.56,0.34,1.11,0.68,1.67,1.01
				c4.83-4.46,9.76-8.81,14.42-13.44c1.46-1.45,2.7-3.53,3.14-5.53c1.33-6.14,4.45-11.15,9.09-15.12c3.18-2.72,3.64-5.68,2.43-9.34
				c-1.3-3.94-2.6-7.89-3.72-11.88c-0.39-1.38-0.84-3.27-0.22-4.32c4.44-7.5,4.23-15.13,1.26-22.98c-0.04-0.1,0.43-0.4,0.98-0.89
				c1.47,1.75,3.23,3.33,4.34,5.27c8.21,14.41,16.3,28.89,24.36,43.39c0.54,0.97,0.64,2.22,0.78,3.36c0.62,4.95,1.42,9.89,1.6,14.86
				c0.05,1.44-1.43,3.56-2.78,4.23c-0.97,0.48-3.57-0.5-4.23-1.57c-1.49-2.43-2.24-5.33-3.65-8.98
				c-1.87,11.54-11.42,8.11-18.22,10.41c1.68-3.72,3.36-7.44,5.41-11.97c-5.51,2.48-11.12,8.87-9.9,11.35
				c1.73,3.49-0.29,5.22-1.98,7.41c-1.91,2.48-3.76,5-5.64,7.51c0.31,0.37,0.63,0.74,0.94,1.1c2.95-0.36,5.89-0.73,9.69-1.2
				c-1.41,2.09-2.03,3.86-3.26,4.7c-11.52,7.84-19.25,20.11-31.97,26.7c-5.13,2.66-9.23,7.47-13.49,11.62
				c-2.94,2.86-5.35,6.26-8,9.43c10.57,10.32,4.75,24.47,8.22,36.63c0.08,0.01,0.42,0.1,0.72,0.03c0.48-0.11,0.93-0.34,1.4-0.5
				c7.16-2.55,13.97-7.27,22.17-3.62c0.49,0.22,1.36-0.07,1.92-0.37c14.35-7.55,30.72-6.39,45.82-10.66
				c0.15-0.04,0.42,0.37,1.45,1.32c-8.87,5.35-17.46,10.55-26.11,15.67c-0.64,0.38-1.63,0.23-2.45,0.23
				c-15.44,0.02-28.73,6.07-40.98,14.86c-1.24,0.89-2.4,2.86-2.43,4.35C535.37,723.31,535.46,734.44,535.46,745.78z M337.71,1474.17
				c0.1,4.54-1.34,7.36-6.54,5.65c-0.55-0.18-1.49,0.47-2.12,0.92c-2.25,1.63-4.45,3.33-7.26,5.45c0.13,3.16,0.36,7.21-5.77,7.14
				c-5.56-0.06-6.86-1.07-8.22-8.56c-5.5,0.4-7.24-3.26-7.77-9.12c-1.96,1.65-3.27,2.67-4.49,3.79c-2.65,2.44-5.56,5.23-9.27,3.19
				c-3.79-2.08-5.72,0.07-7.59,2.32c-4.91,5.88-5.63,6.09-11.58,1.48c0,2.69,0,4.99,0,8.73c-3.26-1.87-5.54-3.18-8.01-4.6
				c-2.87,2.24-5.84,4.57-8.82,6.9c0.18,0.31,0.37,0.62,0.55,0.93c1.99,0,3.97,0,5.96,0c45.61,0,91.21-0.21,136.82,0.19
				c8.26,0.07,14.42-2.12,20.46-7.65c15.8-14.48,33.48-25.39,55.39-28.61c8.38-1.23,16.92-3.97,24.43-7.87
				c11.21-5.82,22.58-10.42,35.08-12.23c11.41-1.66,22.37-4.72,32.73-10.04c3.89-2,8.53-2.46,12.72-3.93
				c4.81-1.69,9.53-3.65,14.23-5.64c0.96-0.41,2.25-1.44,2.34-2.3c0.62-6.12,3.75-10.13,9.45-12.29c0.55-0.21,1.14-0.84,1.33-1.4
				c2.17-6.6,5.78-13.15,6.08-19.85c0.63-14.08,0.54-28.35-1.11-42.33c-2.62-22.2-5.08-44.59-24.46-60.61
				c-7.27-6.01-12.33-14.78-18.06-22.54c-1.97-2.68-3.89-3.1-7.03-3.04c-21.95,0.42-43.9,0.54-65.85,0.72
				c-7.58,0.06-15.16,0.01-22.75,0.01c0.01,0.24,0.02,0.49,0.03,0.73c1.82,0.69,3.65,1.38,5.47,2.07c-0.02,0.44-0.05,0.89-0.07,1.33
				c-5.97,1.9-11.94,3.8-17.91,5.7c0.14,0.53,0.28,1.06,0.42,1.59c1.86,0.53,3.72,1.05,5.58,1.58c-2.1,1.59-4.37,2.51-6.7,2.78
				c-7.24,0.83-14.64,0.78-21.74,2.23c-9.5,1.95-19.37,1.63-28.56,6.21c-4.39,2.19-10.5,0.93-15.83,1.23
				c0.56-1.1,1.25-1.74,1.96-1.76c9.39-0.21,18.03-3.85,27.04-5.67c10.64-2.14,21.66-2.43,32.52-3.52c1.34-0.13,2.69-0.24,4.04-0.37
				c-0.01-0.26-0.03-0.51-0.04-0.77c-1.4-0.43-2.79-0.85-4.19-1.28c-0.09-0.47-0.17-0.95-0.26-1.43c6.24-2.5,12.48-5,18.72-7.51
				c-11.16-0.24-22.31,0.29-33.36,1.65c-3.3,0.41-6.19,4.29-9.47,4.64c-13.83,1.47-25.48,10.88-39.71,10.62
				c-0.73-0.01-1.47,0.61-2.21,0.93c0.47,0.15,0.95,0.3,1.42,0.45c-0.7,5.05-2.16,10.15-1.88,15.15c0.27,4.96-2.1,4.85-5.57,5.24
				c-4.51,0.51-9.18,1.14-13.35,2.8c-5.69,2.26-11.03,3.39-17.32,2.51c-4.84-0.68-10.06,0.93-15.04,1.9
				c-6.03,1.17-11.99,2.74-17.98,4.1c-3.28,0.74-6.59,1.32-9.84,2.14c-2.27,0.57-4.42,1.79-6.71,2.09c-3.07,0.4-6.33-0.31-9.31,0.35
				c-9.81,2.17-19.22,6.13-29.55,6.05c-2.18-0.02-4.45,1.28-6.54,2.26c-6.86,3.23-13.65,6.6-20.66,10.01c10.16,0,20.38,0,30.6,0
				c-1.5,1.92-3.18,2.91-4.99,3.41c-5.45,1.5-11.94,1.06-14.14,8.22c-0.17,0.55-1.05,0.89-1.69,1.4c5.87,1.47,12.06-1.48,17.93,2.61
				c-1.49,0.96-2.23,1.71-3.12,1.96c-6.36,1.79-12.72,3.66-19.15,5.17c-11.98,2.82-24.04,4.92-34.19,13.03
				c-3.45,2.75-9.43,2.14-13.32,4.56c-5.55,3.46-10.96,6.05-17.59,5.46c-0.47-0.04-0.96,0.28-1.45,0.34
				c-11.97,1.53-23.95,3.04-35.92,4.56c-1.78,0.23-3.61,0.27-5.31,0.75c-7.05,1.98-14.06,4.11-21.1,6.14
				c-0.62,0.18-1.32,0.05-1.99,0.05c-11.7,0.1-22.89,2.82-33.86,6.67c-1.56,0.55-3.19,1.09-4.82,1.18
				c-9.21,0.5-18.44,0.73-27.65,1.34c-3.41,0.23-6.81,1.08-10.13,1.96c-4.14,1.1-8.15,2.69-12.29,3.78c-4.33,1.14-8.8,1.77-13.1,2.99
				c-1.2,0.34-2.02,2-3.01,3.05c0.34,0.34,0.68,0.68,1.03,1.02c6.29-1.39,12.79-4.51,18.83-3.79c9.79,1.17,19.07-0.11,28.53-1.51
				c2.09-0.31,4.26-0.05,6.4-0.05c-0.06,0.18-0.12,0.35-0.18,0.53c-9.94,2.77-19.88,5.54-29.64,8.26c4.18,0,8.54,0,12.89,0
				c0.06,0.25,0.12,0.5,0.19,0.74c-12.52,3.05-25.05,6.1-37.43,9.11c0,21.43,0,42.21,0,63.65c12.1-2.94,23.83-5.78,35.55-8.62
				c0.14,0.43,0.29,0.86,0.43,1.29c-6.74,2.3-13.41,4.82-20.24,6.81c-4.24,1.24-8.76,1.47-13.05,2.58c-1.34,0.34-2.33,2.05-3.48,3.14
				c0.33,0.5,0.66,1.01,1,1.51c9.62,0,19.24,0.03,28.86-0.06c0.8-0.01,1.75-0.74,2.35-1.4c2.84-3.09,5.58-6.28,8.18-9.23
				c-0.7-4.79-1.55-9.85-2.11-14.95c-0.1-0.9,0.8-2.4,1.61-2.76c0.8-0.35,2.28,0.14,3.11,0.74c2,1.46,3.8,3.2,5.03,4.26
				c-1.08-3.23-2.24-6.69-3.4-10.16c2.34-0.55,4.67-1.1,7.26-1.7c1.21-5.76,1.8-6.06,8.51-3.42c0-2.45-0.86-5.38,0.24-6.81
				c1.15-1.5,4.17-1.57,6.79-2.4c-1.15-1.26-2.2-2.28-3.09-3.43c-0.91-1.18-1.67-2.48-2.29-3.42c2.42-1.75,4.82-2.67,5.87-4.41
				c2.1-3.48,6.25-5.46,10.33-4.55c2.47,0.56,5.11,0.33,7.68,0.46c0.5-0.91,1.01-1.82,1.51-2.73c2.31,3.6,4.62,7.2,6.92,10.8
				c0.29-0.62,0.58-1.24,0.86-1.86c1.6-1.91,3.2-3.81,4.81-5.72c1.62,1.88,3.87,3.52,4.73,5.71c1.36,3.45,3.72,4.51,6.73,3.7
				c1.46-0.39,2.46-2.45,3.85-3.94c2.84,1.94,5.49,3.9,8.28,5.62c2.67,1.65,5.5,3.05,8.25,4.56c-2.29-3.37-5.32-5.61-8.05-8.18
				c-1.66-1.57-3.14-3.48-4.2-5.5c-1.29-2.47,1.2-7.64,3.51-7.52c4.15,0.23,8.28,0.82,12.79,1.31c-1.48-3.09-2.71-5.67-4.18-8.74
				c1.87,0.41,3,0.66,4.93,1.08c-1.66-4.8-3.13-8.82-4.42-12.89c-0.43-1.36-1.12-3.37-0.5-4.21c1.73-2.36,1-4.09-0.07-6.33
				c-0.55-1.17-0.31-3.89,0.42-4.27c1.72-0.89,5.08-1.74,5.78-0.92c2.48,2.94,5.71,2.16,8.38,2.1c7.89-0.18,9.46,1.14,11.07,9.05
				c0.86,4.21,2.02,9.22,4.87,11.94c3.03,2.89,6.19,5.15,8.78,8.87c4.23,6.06,10.29,10.85,15.61,16.21
				c3.21-11.35,6.37-23.02,20.08-26.51c0.1-1.91-0.29-3.73,0.35-5.05c1.32-2.71,10.56-6.13,13.84-5.06
				c4.73,1.54,8.67,0.21,12.79-2.05c2.33-1.27,5.22-1.81,7.91-2.04c3.98-0.33,8.02-0.09,11.87-0.09c1.63-7.35,2.14-7.58,9.02-5.22
				c0.99,0.34,2.87,0.45,3.22-0.07c1.67-2.54,3.8-5.23,4.16-8.07c0.4-3.19,0.58-5.68,4.31-5.94c3.94-0.28,4.32,2.55,4.92,5.53
				c0.19,0.91,2.8,2.34,3.31,2.01c4.82-3.13,7.06,0.39,9.92,3.21c1.77,1.75,4.93,4.41,6.25,3.85c3.98-1.7,7.38-4.79,11.84-7.93
				c3.31,0.31,8.23,0.8,13.15,1.2c0.38,0.03,0.8-0.76,1.22-0.79c1.73-0.12,3.62-0.56,5.17-0.05c6.55,2.14,7.8,8.81,11.18,13.67
				c0.08,0.12-0.03,0.36,0.04,0.49c1.09,2.04,2.15,4.11,3.33,6.1c0.57,0.97,2.05,1.85,1.98,2.67c-0.47,5.57-0.33,11.45-2.22,16.55
				c-1.53,4.13,0.48,4.81,2.87,5.69c7.71,2.85,7.75,2.75,7.36,10.89c4.13,0.51,6.5,2.79,5.82,7.22c-0.62,4.05-2.24,8.14,1.66,11.57
				c0.31,0.28,0.07,1.86-0.41,2.27c-5.48,4.77-1.68,10.37-1.83,15.52c5.57-0.42,9.91,6.57,15.45,1.23c0.76,1.32,1.47,1.99,1.52,2.71
				c0.24,3.5,1.18,5.33,5.28,3.71c3.4-1.34,7.09-1.95,10.66-2.87c3.16-0.82,5.93-1.16,6.63,3.32c0.19,1.21,1.68,2.22,2.48,3.21
				c-3.53,0.56-3.28,0.47-4.36-2.59c-0.39-1.12-2.47-2.61-3.43-2.39c-2.63,0.6-5.07,2.05-7.61,3.09c-5.94,2.45-9.69,0.96-11.64-3.19
				c-4.75-1.55-8.23-3.31-11.86-3.68c-3.61-0.36-5.53-1.02-5.3-5.11c0.09-1.62-0.54-4.05-1.66-4.72c-0.97-0.58-3.32,0.52-4.69,1.43
				c-2.48,1.64-5.04,2.92-6.79-0.27C344.03,1473.28,341.52,1473.23,337.71,1474.17z M972.68,213.6c0.72,13.64,2.17,27.09,1.97,40.51
				c-0.43,29.45-2.22,58.89-2.47,88.33c-0.29,35.12,0.44,70.25,0.76,105.37c0.01,1.09,0.37,2.18,0.66,3.73
				c3.17-0.83,5.98-1.86,8.88-2.24c3.88-0.51,8-1.36,11.7-0.58c9.91,2.07,19.56,5.38,29.49,7.28c5.36,1.03,11.19,0.9,16.59,0.01
				c7.98-1.31,7.83-1.79,8.04-9.58c0.75-27.77,1.34-55.55,2.47-83.31c0.26-6.35,2.02-12.76,3.93-18.89c1.69-5.43,6-8.59,11.8-9.46
				c11.56-1.72,25.23,5.62,29.27,16.69c9.97,27.33,13.37,55.76,15.84,84.44c0.17,1.97,1.02,4.69,2.46,5.56
				c2.59,1.56,5.54,2.22,8.64-0.5c3.56-3.12,7.6-6.49,11.99-7.69c6.36-1.73,10.92-5.17,15.05-9.87c2.52-2.87,5.17-5.7,8.11-8.11
				c2.72-2.23,3.58-4.7,3.57-8.16c-0.09-62.78-0.07-125.55-0.08-188.33c0-1.76-0.16-3.52-0.29-6.19
				c-15.46,0.72-30.38,1.35-45.28,2.14c-6.3,0.33-12.61,1.53-18.89,1.39c-30.43-0.68-60.85-1.76-91.28-2.51
				C994.55,213.37,983.44,213.6,972.68,213.6z M567.37,1059.32c8.39-4.94,16.41-9.56,24.34-14.35c5.94-3.59,12.13-6.77,19.21-5.2
				c4.44,0.99,8.73,3.07,12.78,5.23c7.53,4.02,15.11,8.3,23.78,8.21c12.26-0.12,22.92,4.89,33.88,9.06c2.22,0.84,4.03,2.87,5.88,4.53
				c10.64,9.56,21.52,18.88,31.76,28.85c13.27,12.92,30.58,20.78,42.32,35.83c9.37,12.02,20.64,22.6,31.4,33.48
				c2.78,2.81,6.58,5.01,10.32,6.38c16.78,6.16,34.56,9.79,49.61,20.25c4.78,3.32,9.78,6.31,14.76,9.33c1.68,1.02,3.6,1.63,5.4,2.46
				c2.65,1.22,5.65,2.02,7.87,3.8c8.55,6.88,16.74,14.21,25.33,21.03c1.8,1.43,4.78,2.14,7.12,1.96c6.78-0.51,13.51-1.71,20.28-2.5
				c6.24-0.73,7.07-1.38,7.19-7.8c0.05-2.44-0.74-4.89-1.1-7.35c-0.29-1.94-0.8-3.91-0.72-5.84c0.48-10.94,1.86-21.89,1.47-32.79
				c-0.41-11.61-2.42-23.15,0.28-34.73c1.06-4.53-1.05-7.53-5.08-9.65c-5.12-2.69-10.83-4.89-14.92-8.75
				c-8.29-7.83-15.55-16.74-23.48-24.97c-3.28-3.41-6.95-6.58-10.89-9.18c-8.86-5.84-18.26-10.9-26.96-16.95
				c-9.19-6.39-18.17-12.82-29.46-15.11c-2.64-0.53-5.42-2.08-7.36-3.96c-6.97-6.77-15.83-9.53-24.72-12.1
				c-2.51-0.73-5.8-0.55-8.16,0.5c-3.13,1.39-5.29,0.75-7.78-0.95c-7.13-4.88-14.18-9.9-21.53-14.43
				c-8.88-5.48-18.01-10.58-27.09-15.72c-4.1-2.32-8.3-3.15-13.29-2.69c-8.21,0.76-15.48-1.6-20.71-9.14
				c-4.33-6.24-9.82-11.65-14.75-17.47c-1.97-2.33-4.89-4.38-2.7-8.17c0.23-0.4-0.03-1.39-0.4-1.79
				c-6.53-7.09-13.12-14.14-20.6-22.17c0.73,6.53,0.29,7.38-4.13,9.98c-4.09,2.41-9.02,14.08-7.6,18.18
				c1.73,5.02,3.02,10.29,5.48,14.93c2.4,4.54,5.25,9.61,9.32,12.3c5.12,3.38,5.89,7.92,7.69,12.57c-3.43,0-6.25-0.43-8.9,0.08
				c-7.67,1.47-11.94-0.16-16.04-6.77c-1.22-1.98-1.99-4.34-3.52-6.01c-3.2-3.48-6.34-8.61-10.29-9.47
				c-7.05-1.54-14.68-0.43-21.13-0.43c-2.14,16.28,6.78,28.67,12.77,41.96c-8.74,2.63-16.61-0.04-20.66-6.66
				c-1.65-2.69-3.1-5.52-4.38-8.4c-4.14-9.35-13.04-11.27-21.31-14.7c-3.09,17.28-3,34.71-14.43,48.78
				C564.67,1054.74,565.75,1056.57,567.37,1059.32z M582.86,937.03c0.59,0.2,1.17,0.4,1.76,0.6c4.25-4.05,9.41-7.62,10.39-13.98
				c1.04-6.74,6.11-10.45,10.07-15c0.78-0.9,2.72-0.94,4.15-1.09c4.58-0.45,9.23-0.48,13.74-1.26c1.71-0.3,3.14-2.22,4.69-3.4
				c11.31-0.57,23.8-4.79,34.39,4.2c0.58,0.49,1.56,0.49,2.35,0.74c12.67,3.98,20.35,12.17,21.58,24.04
				c8.57,3.34,16.42,6.4,24.28,9.46c0.3-0.32,0.61-0.63,0.91-0.95c-5.36-5.52-10.6-11.17-16.12-16.53
				c-5.95-5.78-12.84-10.73-18.08-17.07c-5.75-6.97-11.11-13.97-19.61-17.81c-1.3-0.59-2.55-1.75-3.29-2.96
				c-0.76-1.25-1.64-3.37-1.09-4.25c0.73-1.17,2.83-2.19,4.26-2.1c5.13,0.33,10.22,1.57,15.34,1.69c4.67,0.11,6.8,2.5,9.27,6.08
				c7.89,11.43,16.35,22.46,25.08,34.32c2.43-4.14,3.46-8.17,6.02-9.83c6.35-4.12,13.46-6.13,21.23-3.21
				c6.11,2.3,10.11,0.94,14.16-4.07c4.5-5.57,8.56-6.08,14.19-2.03c3.29,2.37,3.1,4.77,0.54,7.45c-5.13,5.38-4.64,10.25,2.43,12.81
				c5.79,2.1,12.28,2.31,18.47,3.25c0.48,0.07,1.1-0.65,1.63-1.05c5.13-3.85,7.47-3.68,12.73,1.39c-1.86,1.62-3.86,3.04-5.45,4.83
				c-1.91,2.17-3.45,4.66-5.18,6.99c-2.39,3.23-4.37,6.93-7.33,9.5c-3.07,2.66-4.42,5.35-2.08,8.51c2.92,3.96,6.75,7.23,9.86,11.07
				c1.98,2.43,4.09,5.14,4.9,8.07c3.08,11.15,6.42,21.8,19.63,24.92c1.4,0.33,2.7,1.28,3.94,2.11
				c15.24,10.19,29.82,21.02,41.81,35.24c7.69,9.11,16.06,18.01,25.52,25.15c10.73,8.11,20.85,16.46,28.75,27.37
				c8.5,11.76,17.02,23.5,25.54,35.25c1.43-7.52,3.53-15.03,1.87-21.6c-3.16-12.5,1.91-26.59-7.32-37.81
				c-0.27-0.33-0.25-0.97-0.22-1.46c0.63-9.61-4.41-17.91-6.16-26.97c-0.75-3.88-2.81-5.05-7.05-4.66c-4.31,0.4-8.8-0.8-13.17-1.57
				c-11.49-2.03-19.38-10.61-28.43-16.73c-10.27-6.95-17.2-17.27-21.22-29.43c-2.37-7.17-6.15-13.87-9.13-20.86
				c-1.88-4.41-3.49-8.95-5.15-13.45c-2.29-6.2-4.6-12.4-6.76-18.65c-2.37-6.86-5.21-13.65-6.72-20.71
				c-2.19-10.23-3.33-20.69-4.97-31.04c-0.75-4.74-4.85-4.86-8.31-5.77c-3.02-0.79-5.8-1.18-7.6,2.47
				c-3.31,6.74-8.51,9.31-15.84,6.44c-0.92-0.36-2.12-0.89-2.88-0.58c-1.8,0.74-4.39,1.58-4.92,3.02c-1.89,5.11-4.27,8.04-9.97,4.86
				c-0.26-0.15-0.67-0.14-0.98-0.08c-5.46,0.96-6.65-2.08-6.33-6.66c0.26-3.75-0.23-7.04-3.6-9.72c-7.02-5.59-13.4-12.02-20.67-17.23
				c-4.56-3.27-10.28-4.91-15.43-7.37c-2.76-1.32-5.47-2.75-8.11-4.3c-3.04-1.78-5.77-4.3-9.01-5.51
				c-7.29-2.73-14.96-4.47-22.16-7.39c-3.92-1.59-7.19-4.77-11.26-7.58c2.69-2.28,4.29-3.63,6.05-5.13
				c-9.49-6.9-20.27-5.85-29.65-6.18c-2.41,2.6-4.21,4.54-6.67,7.19c12.26,0.52,19.07,8.11,24.06,16.53
				c-2.28,4.33-3.89,8.6-6.54,12.08c-2.42,3.18-1.58,5.55,0.88,7.15c4.53,2.95,9.42,5.39,14.29,7.78c2.89,1.42,6.04,2.32,9,3.43
				c-2.2,7.83-2.97,8.38-9.27,6.47c-6.19-1.88-12.23-4.39-18.53-5.79c-5.45-1.21-11.24-4.47-16.66,0.99
				c-1.12,1.13-4.18,1.03-6.09,0.51c-4.31-1.17-8.47-2.91-12.66-4.49c-9.64-3.63-9.42-3.66-10.17-13.84
				c-0.18-2.44-1.7-5.62-3.63-6.91c-3.83-2.56-6.22-5.15-4.19-10.4c-1.92,0-3.32-0.36-4.43,0.06c-9.31,3.51-17.08-0.5-24.12-5.59
				c-8.21-5.93-16.15-12.37-23.51-19.33c-5.85-5.53-12.8-7.08-20.91-8.74c0,38.28,0,76.12,0,113.95c1.57-4.57,2.82-9.33,3.14-14.15
				c0.83-12.45,1.2-24.92,1.85-37.38c0.18-3.46,0.99-6.92,5.08-7.6c4.04-0.67,7.37,1.34,9.08,4.84c1.58,3.22,2.76,6.84,3.17,10.39
				c3.02,25.75,0.89,50.89-10.59,74.52c-2.54,5.22-6.9,9.55-10.34,14.34c-1.13,1.57-2.82,3.33-2.83,5.02
				c-0.14,16.78-0.09,33.57,0.14,50.35c0.11,7.75,0.73,15.5,1.19,24.57c3.39-1.73,6.33-2.77,8.72-4.54
				c5.74-4.26,8.71-10.47,10.31-17.21c5.57-23.41,7.78-47.24,8.21-71.23c0.56-30.97,0.72-61.94,1.13-92.91
				c0.07-5.14,0.11-10.36,1.01-15.39c0.43-2.42,2.36-5.46,4.46-6.51c5.38-2.69,14.4,2.92,15.13,8.99c0.52,4.29,0.89,8.61,0.97,12.93
				c0.31,15.28,0.74,30.56,0.6,45.83C584.29,912.21,583.41,924.62,582.86,937.03z M817.01,430.26c2.03-5.86,3.9-11.32,5.81-16.77
				c1.11-3.16,1.43-5.82-1.7-8.44c-2.98-2.5-5.4-3.27-8.71-0.85c-1.08,0.8-2.84,1.15-4.22,1c-6.91-0.74-13.78-1.86-20.69-2.56
				c-11.18-1.14-22.08-4.49-33.62-3c-5.06,0.65-8.87,2.04-11.76,6.13c-7.37,10.42-14.77,20.81-22.27,31.37
				c0.89,0.67,2.28,1.72,3.71,2.79c-0.38,0.71-0.5,1.26-0.85,1.53c-9.08,7.22-18.15,14.45-27.35,21.52
				c-0.92,0.71-3.09,0.91-3.93,0.31c-1.04-0.74-1.49-2.54-1.82-3.97c-0.24-1.02,0.12-2.19,0.33-4.6
				c-11.91,6.53-22.94,12.58-34.91,19.15c4.66-11,10.34-19.79,20.17-25.05c4.81-2.58,7.88-6.19,10.09-11.15
				c4.74-10.65,9.73-13.3,22.29-12.5c-0.57,1.82-1.11,3.54-1.4,4.46c7.88-3.99,16.02-8.11,24.16-12.24
				c-12.21,0.59-24.51,1.84-36.82,3.12c-0.46,0.05-0.87,0.5-1.34,0.64c-8.07,2.37-12.76,8.56-17.36,15
				c-1.69,2.37-3.88,4.51-6.22,6.24c-4.33,3.22-8.89,6.16-13.47,9.02c-1.84,1.15-4.1,2.7-6.01,2.5c-7.87-0.83-13.85,1.32-19.4,7.44
				c-5.53,6.08-12.33,11.15-19.1,15.92c-6.45,4.54-13.57,8.12-20.41,12.08c-0.71,0.41-1.58,0.53-2.38,0.79
				c-0.16-0.3-0.32-0.59-0.49-0.89c5.8-4.22,11.61-8.44,18.47-13.43c-4.39-2.23-8.04-3.94-11.54-5.91c-2.38-1.34-5.31-2.55-6.67-4.66
				c-2.12-3.3-4.7-4.48-7.93-3.21c-5.44,2.16-10.65,4.9-16.41,7.61c-2.37-4.22-5.1-1.93-7.82,1.15c-1.21,1.37-2.53,2.65-3.79,3.97
				c-5.55,5.81-7.54,5.68-13.41-1.2c6.77-5.32,9.16-12.74,9.88-20.87c-0.46-0.43-0.92-0.87-1.38-1.3
				c-5.02,4.34-10.04,8.68-15.27,13.21c-1.92-4.11-3.83-8.21-5.82-12.48c2.46-3.28,5.02-6.68,6.61-8.8
				c-1.81-3.33-3.07-5.55-4.22-7.81c-0.51-1-0.5-2.41-1.23-3.11c-4.67-4.43-9.48-8.72-15.11-13.84c-3.63,4.3-7.3,8.64-11.34,13.42
				c-3.36-2.94-6.76-5.92-10.43-9.13c-1.68,1.23-3.7,2.67-5.67,4.18c-1.56,1.2-3.42,3.82-4.52,3.52c-6.38-1.75-8.16,2.69-10.44,6.81
				c-0.57,1.02-1.55,1.81-2.6,3.01c-2.87-5.65-3.14-10.47-0.37-15.72c2.44-4.62,4.05-9.69,6.33-15.3c-5.76-0.31-11.23,0.2-11.16-6.09
				c-5.59,4.88-11.19,9.87-16.93,14.7c-0.91,0.77-2.94,1.5-3.62,1.03c-2.78-1.91-4.51-0.05-6.54,1.19c-3.2,1.95-6.32,3.05-9.23-0.66
				c-0.76-0.97-2.11-1.47-3.39-2.33c1.21-4.46,2.54-8.9,3.6-13.4c2.42-10.32,1.46-12.27-8.24-17.52c-1.8,1.99-3.62,3.99-5.86,6.45
				c-0.99-3.18-1.65-5.53-2.46-7.82c-1.68-4.76-6.27-3.87-9.77-4.32c-0.96-0.13-3.01,3.33-3.52,5.39c-2.68,10.96-5.01,22-7.53,33
				c-0.24,1.05-0.95,1.99-1.44,2.97c-0.44-0.11-0.89-0.22-1.33-0.33c-0.31-10.66-0.7-21.32-0.87-31.99
				c-0.04-2.63,0.78-5.26,0.91-7.91c0.19-3.81-3.48-8.91-6.58-9.47c-5.74-1.03-9.41,0.98-11.2,5.98c-0.72,2.02-1.77,3.92-2.56,5.92
				c-2.18,5.46-4.51,10.88-6.4,16.44c-1.96,5.76-3.47,11.67-5.17,17.52c-0.47-0.11-0.95-0.22-1.42-0.34
				c-2.18-9.84-4.02-19.61-1.06-29.79c1.33-4.58,1.81-9.47,2.15-14.25c0.1-1.35-1.44-3.09-2.67-4.13c-0.44-0.38-2.92,0.49-3.19,1.25
				c-3.31,9.21-7.08,18.33-3.81,28.52c2.21,6.88,7.96,13.04,4.67,21.16c-0.05,0.12,0.06,0.33,0.13,0.47
				c2.96,6.1,1.8,12.19-0.21,17.89c5.49,4.14,10.46,8.36,15.92,11.8c2.92,1.84,6.68,3.19,10.06,3.2c3.84,0.01,7.88-1.26,11.47-2.81
				c4.69-2.02,7.91-0.46,11.06,2.79c2.08,2.14,4.04,4.71,6.61,5.99c2.61,1.3,6.08,2.18,8.85,1.63c7.17-1.41,15.06,1.36,18.9,7.63
				c1.8,2.95,3.67,5.23,7.82,5c1.85-0.1,4.47,1.49,5.6,3.11c2.45,3.51,3.86,7.73,6.23,11.31c3.97,6,3.87,11.24-0.75,17.01
				c-2.61,3.26-3.9,7.58-6.18,12.23c5.3-0.76,9.3-2.39,12.68-1.53c4.04,1.02,6.71,0.14,9.45-2.27c2.46-2.17,4.86-2.67,8.16-1.36
				c4.3,1.71,8.48,1.33,12.26-2.26c7.6-7.21,15.54-14.05,23.28-21.1c8.77-7.98,18.37-8.41,27.82-1.15c3.91,3,7.89,3.92,12.39,1
				c2.85-1.85,5.81-4.07,9.6-1.5c0.78,0.53,2.78,0.22,3.6-0.45c2.8-2.3,5.39-1.36,7.78,0.26c2.87,1.96,5.37,4.49,8.26,6.42
				c1.52,1.02,3.51,1.39,5.34,1.88c1.95,0.52,4.42,0.23,5.87,1.33c6.97,5.31,14,5.13,21.39,1.19c1.31-0.7,2.83-1.01,4.14-1.71
				c4.04-2.15,7.24-1.23,10.05,2.25c4.69,5.81,11.05,7.76,18.21,7.14c5.41-0.47,10.79-2.18,16.15-2.04
				c9.76,0.25,18.01-6.92,27.97-5.16c0.78,0.14,1.72-0.92,2.63-1.29c5.96-2.45,11.75-5.55,18.59-4.48c5.38,0.84,9.7-1.3,13.41-5.09
				c1.74-1.78,3.78-3.26,5.58-4.99c5.62-5.39,12.37-7.94,20-9.24c4.26-0.73,8.75-2.55,12.17-5.15c6.07-4.61,11.52-10.07,16.96-15.44
				c1.33-1.31,1.77-3.62,2.37-5.55c2.45-7.88,4.12-16.08,7.4-23.59c2.98-6.81,14.02-8.42,19.66-3.6
				C810.26,423.29,813.41,426.73,817.01,430.26z M659.6,678.28c-7.96,6.79-12.27,18.48-23.42,23.77
				c-10.48,4.97-20.39,11.13-30.55,16.77c-0.45-0.5-0.91-0.99-1.36-1.49c1.17-2.92,2.33-5.84,3.5-8.76
				c-0.28-0.33-0.55-0.66-0.83-0.98c-7.54,5.87-15.6,11.19-22.48,17.74c-9.85,9.37-17.68,20.79-29.33,28.39
				c-2.46,1.61-3.92,4.74-6.73,8.3c3.96,0.19,6.55,0.62,9.06,0.34c2.48-0.28,4.88-1.29,8.32-2.27c-6.6,16.12-20.44,25.13-29.91,38.05
				c2.25,0.68,3.79,1.28,5.38,1.59c20.37,4.03,40.76,7.95,61.11,12.05c13.21,2.66,26.32,3.76,39.42-0.66
				c1.97-0.66,4.29-0.42,6.44-0.39c21.45,0.27,42.9,0.82,64.35,0.76c6.91-0.02,14.01-0.86,20.69-2.62
				c8.18-2.16,15.76-1.98,23.92,0.32c17.6,4.95,35.51,6.21,52.83,1.7c-1.82-23.91-3.61-47.49-5.52-72.52
				c-2.46,4.74-4.66,3.52-7.78,2.09c-7.9-3.65-15.6-3.68-22.47,3.05c-3.91,3.83-8.42,7.04-12.7,10.48c-0.87,0.7-2.35,1.9-2.85,1.62
				c-4.65-2.61-8,0.52-11.17,2.72c-5.15,3.58-9.81,7.87-14.77,11.73c-5.22,4.06-10.55,7.96-15.83,11.94
				c-0.47-0.55-0.95-1.09-1.42-1.64c7.65-12.36,17.54-22.99,27.9-33.93c-3.35-1.59-6.09-2.88-9.24-4.37
				c3.8-6.64,7.87-11.65,15.94-12.31c5.9-0.48,12.85-0.47,17.33-3.58c12.11-8.41,23.8-17.65,32.46-29.99
				c2.93-4.17,1.76-8.15,0.52-12.56c-5.25,6.54-9.86,12.86-15.09,18.62c-5.43,5.97-12.97,8.1-22.26,10.54
				c2.04-5.68,3.58-9.99,5.07-14.13c-4.75-3.7-8.12-1.73-12.17,1.71c-6.79,5.77-14.45,10.5-21.5,15.98
				c-5.84,4.54-11.58,9.27-16.91,14.39c-3.93,3.78-7.05,8.39-10.67,12.51c-1.61,1.84-3.33,2.43-6.11,1.32
				c-6.2-2.47-12.57-2.67-18.81,0.54c-2.99,1.53-6.25,2.53-9.39,3.77c-0.46-0.36-0.92-0.72-1.39-1.09c1.14-3.5,1.48-7.58,3.57-10.37
				c4.75-6.34,10.23-12.15,15.63-17.97c5.34-5.75,10.6-16.94,11.61-26.74c-8.06,12.8-15.17,24.89-28.94,31.34
				c-1.25-10.9,5.8-19.07,8.13-28.9c-4.16,3.19-8.45,6.39-11.32,10.57c-4.34,6.34-9.42,11.5-16.07,15.29
				c-4.76,2.71-9.21,5.99-13.99,8.67c-2.8,1.57-5.98,2.47-8.99,3.67c-0.32-0.51-0.64-1.03-0.96-1.54
				c7.1-9.26,12.93-19.41,22.58-26.82c4.01-3.08,7.65-8.03,9-12.81C662.57,687.95,660.3,682.79,659.6,678.28z M465.33,1031.6
				c-0.51,8.85,0.34,17.89,5.15,25.54c4.82,7.67,10.61,14.98,20.14,17.58c4.25,1.16,5.7,4.1,5.63,8.26
				c-0.3,17.14-0.62,34.28-0.77,51.43c-0.13,15.11-0.03,30.23-0.03,45.69c0.57,0.04,2.03,0.63,2.73,0.14
				c5.06-3.57,9.26-2.51,13.11,1.89c0.38,0.43,1.16,0.56,1.79,0.73c2.71,0.72,5.43,1.4,8.74,2.24c0-7.64-0.12-16.02,0.03-24.4
				c0.22-12.61,0.51-25.21,0.98-37.81c0.18-4.81,0.75-9.2-3.41-13.21c-4.07-3.92-3.43-6.01,0.8-9.99c1.05-0.98,2.53-2.1,2.7-3.31
				c0.65-4.6,1.15-9.26,1.15-13.9c-0.01-7.97,0.04-7.9,7.61-10.13c7.7-2.27,14.78-5.4,18.59-13.57c1.42-3.06,4.77-6.83,7.65-7.23
				c4.33-0.59,6.07-2.93,7.38-5.93c2.23-5.11,4.8-10.34,5.6-15.76c3.06-20.88,6.2-41.79,7.94-62.81c2.12-25.5,3.47-51.11,3.99-76.69
				c0.33-16.26-1.39-32.56-2.29-48.84c-0.28-5.04-4.94-8.94-10.36-8.52c-4.67,0.36-4.9,4.16-5.14,7.75c-0.12,1.82-0.5,3.64-0.52,5.46
				c-0.35,32.64-0.6,65.28-0.99,97.91c-0.3,25.01-2.51,49.83-8.26,74.24c-1.71,7.27-5,13.81-10.93,18.59
				c-4.14,3.34-8.7,5.83-14.3,4.25c0.41-2.04,1.03-3.6,0.98-5.15c-0.19-6.98-0.77-13.94-0.82-20.92
				c-0.13-17.62-0.17-35.24-0.03-52.86c0.17-20.64,0.72-41.28,0.83-61.93c0.1-18.48-0.59-36.98-0.22-55.45
				c0.69-33.62,2.63-67.22,2.66-100.83c0.02-24.24-2.14-48.49-3.49-72.73c-0.19-3.45-0.87-6.99-2.03-10.24
				c-1.86-5.18-7.2-5.5-11.52-6.64c-0.8-0.21-3.52,3.32-3.83,5.36c-1.66,10.83-3.62,21.71-4.02,32.63
				c-0.95,25.94-1.04,51.91-1.57,77.87c-0.56,27.76-1.21,55.52-1.85,83.28c-0.56,24.63-1.41,49.26-1.68,73.89
				c-0.34,32.14-0.15,64.29-0.42,96.44c-0.04,4.23-0.95,8.63-2.37,12.62c-1.48,4.17-4.37,4.63-7.03,1.23
				c-2.39-3.04-4.72-6.59-5.57-10.27c-1.28-5.58-1.5-11.45-1.74-17.22c-0.67-16.1-1.47-32.2-1.55-48.31
				c-0.07-14.64,1.78-29.35,0.91-43.92c-1.52-25.51-1.67-51.24-7.68-76.31c-2.83-11.79-0.45-24.77-8.1-35.45
				c-0.27-0.37-0.51-0.99-0.39-1.38c1.41-4.6-2-4.01-4.48-4.03c-3.36-0.03-8.56,5.19-8.66,8.86c-0.42,15.47-1.45,30.97-0.83,46.41
				c1.02,25.42,3.39,50.79,4.75,76.2c1.09,20.44,1.74,40.9,2.19,61.36c0.32,14.62,1.18,29.02,8.38,42.21
				c2.03,3.72,4.06,7.43,6.09,11.15c-0.43-4.47-2.16-8.38-2.46-12.4c-1.3-17.19-2.27-34.4-3.18-51.61
				c-1.12-21.22-2.2-42.44-3.05-63.67c-0.69-17.11-0.93-34.23-1.54-51.34c-0.28-7.94-0.93-15.87-1.47-23.8
				c-0.42-6.1-0.92-12.2-1.38-18.3c0.42-0.03,0.83-0.06,1.25-0.09c1.16,10.87,2.79,21.72,3.38,32.62
				c1.21,22.37,1.79,44.77,2.75,67.15c0.93,21.72,2,43.44,3.06,65.16c0.67,13.76,1.27,27.52,2.19,41.26
				c0.89,13.29,8.6,21.45,21.66,22.91c0,3.58,0,7.24,0,10.91c0.46,0.04,0.92,0.09,1.38,0.13c0.93-4.66,2.14-9.29,2.71-13.99
				c0.63-5.26,0.63-10.59,0.99-15.88c1.27-18.68,2.84-37.34,3.8-56.04c0.73-14.27,0.77-28.57,0.98-42.86
				c0.29-20.14,0.29-40.28,0.71-60.41c0.68-32.73,1.56-65.45,2.42-98.17c0.18-6.8,0.78-13.59,0.92-20.4
				c0.32-15.44,0.51-30.89,0.7-46.34c0.07-5.73,0.01-11.47,0.01-17.21c1.61,15.06,2.8,30.16,2.37,45.22
				c-0.62,21.36-2.7,42.68-3.65,64.04c-0.73,16.44-0.7,32.91-0.97,49.36c-0.21,13.31-0.4,26.62-0.52,39.93
				c-0.27,28.96-0.5,57.92-0.74,86.89c-0.01,0.83,0,1.67-0.05,2.5c-1.28,20.35-2.25,40.73-4.02,61.04
				c-0.68,7.79-2.62,15.61-5.07,23.07c-1.65,5.02-6.37,7.02-9.72,5.53c-8.64-3.85-15.83-9.55-19.1-18.66
				C468.78,1046.48,467.29,1038.96,465.33,1031.6z M1161.39,127.06c0-1.14,0-2.95,0-4.77c0-21.49,0-42.97,0-64.46
				c0-7.15-1.27-8.56-8.35-7.73c-3.01,0.36-6.68,1.24-8.61,3.27c-4.61,4.85-9.08,10.13-12.21,15.99c-1.94,3.63-3.88,6.06-7.65,6.88
				c-5.52,1.19-11.28,1.57-16.59,3.33c-4.06,1.35-7.7,4.15-11.32,6.6c-2.82,1.91-5.26,4.38-7.99,6.42c-1.71,1.28-3.6,2.37-5.53,3.28
				c-2.1,0.99-4.49,2.64-6.5,2.34c-6.21-0.94-12.33-2.53-18.42-4.14c-22.98-6.06-44.12-3.26-62.81,12.44
				c-1.01,0.84-3.27,0.93-4.64,0.46c-6.56-2.25-13.59-4.89-19.71-0.84c-6.86,4.53-13.57,8.72-21.29,11.89
				c-9.67,3.97-18.6,7.76-29.5,4.86c-7.26-1.93-15.04-2.2-22.61-2.41c-2.85-0.08-6.93,1.31-8.42,3.44c-3.37,4.82-7.42,4.1-11.85,3.29
				c-2.61-0.48-5.19-1.39-7.81-1.55c-4.11-0.26-8.93-1.38-12.22,0.28c-5.09,2.58-9.85,2.21-14.97,1.86c-2.96-0.2-5.95-0.01-8.92,0.13
				c-5.26,0.25-10.51,0.82-15.76,0.86c-6.15,0.05-12.32-0.73-18.44-0.42c-7.27,0.36-13.84,3.18-17.7,9.62
				c-2.39,4-4.64,3.59-8.12,2.12c-7.01-2.97-14-6.14-21.29-8.2c-7.25-2.05-14.66-0.56-19.77,5.18c-3.2,3.59-6.09,3.62-10.01,2.88
				c-3.72-0.7-8.19-2.43-11.15-1.1c-7.83,3.54-15.91,2.76-23.94,3.65c-3.91,0.44-8.37,1.76-11.23,4.24c-2.99,2.6-5.84,4.1-9.56,4.49
				c-3.31,0.35-6.63,0.61-9.9,1.17c-5.12,0.88-10.57,1.75-13.03,7.06c-2.58,5.55-7.26,7.45-12.53,8.74
				c-4.19,1.02-8.81,1.1-12.54,2.99c-6.03,3.05-12.3,3.51-18.74,4.31c-4.29,0.53-9.05,1.65-12.42,4.11
				c-5.29,3.85-4.12,7.92,2.14,9.69c2.42,0.69,5.02,0.78,7.79,1.17c-0.69-7.06,2.66-11.14,8.01-12.48
				c8.94-2.23,18.09-4.05,27.26-4.87c9.49-0.85,18.62-1.8,27.63-5.61c5.17-2.19,11.84-2.62,17.44-1.61
				c8.89,1.6,16.95,1.13,25.23-2.51c9.88-4.35,20.05-3.58,30.19-0.24c2.25,0.74,4.85,0.69,7.27,0.57c10.07-0.53,20.13-1.64,30.2-1.76
				c9.76-0.12,19.53,1.16,29.29,0.97c5.95-0.12,11.28-2.63,14.02-8.8c0.46-1.03,2.28-1.98,3.52-2.05c18.58-0.97,37.17-1.8,55.75-2.65
				c7.43-0.34,14.86-0.73,22.29-0.91c12.42-0.3,24.61,0.43,35.11,8.43c1.48,1.13,3.65,1.88,5.5,1.88c8.47,0.02,16.96-0.1,25.42-0.5
				c15.47-0.74,30.05-9.29,46.09-5.34c0.15,0.04,0.33-0.01,0.49-0.05c9.89-2.57,19.04-0.34,27.99,3.99c7.73,3.74,6.15,3.7,10.84-3.06
				c3.92-5.67,8.9-8.26,15.6-7.87c3.15,0.18,6.3,0.43,9.43,0.76c3.96,0.42,7.96,1.61,11.86,1.31c5.37-0.41,10.63-2.64,15.97-2.78
				c8.2-0.22,14.28-3.41,19.1-9.86c4.31-5.76,10.27-9.28,17.43-9.42C1144.37,125.87,1152.58,126.65,1161.39,127.06z M1162.28,140.98
				c-1.01-3.89-0.93-8.92-2.78-9.78c-10.8-5.01-30.09-6.26-39.48,6.04c-2.19,2.87-4.78,6.49-7.88,7.39
				c-9.12,2.67-18.51,4.69-27.94,5.87c-4.77,0.6-9.85-1.15-14.78-1.86c-9.28-1.32-17.78-0.59-22.57,9.1
				c-2.34,4.73-5.36,4.33-9.74,2.58c-6.39-2.57-13.08-5.02-19.84-5.86c-7.97-0.99-16.15-0.3-24.24-0.17
				c-2.29,0.04-4.71,0.27-6.83,1.06c-16.27,6-33.13,6.71-50.17,5.86c-2.07-0.1-4.57-0.52-6.04-1.78c-6.71-5.73-14.71-7.83-23.09-8.14
				c-7.23-0.27-14.54,1.29-21.73,0.82c-19.99-1.31-39.9-0.56-59.8,1.25c-4.87,0.44-9.92,0.16-12.43,5.93
				c-0.63,1.45-2.66,2.89-4.27,3.22c-6.58,1.34-13.31,3.45-19.87,3.09c-16.56-0.91-33.05-4.13-49.56,0.53
				c-1.8,0.51-4.11,0.36-5.86-0.32c-10.94-4.26-21.73-4.12-32.44,0.65c-6.76,3.01-13.56,3.34-20.92,2.08
				c-5.73-0.99-12.61-1.81-17.54,0.45c-11.82,5.42-24.04,5.59-36.47,6.64c-7.76,0.65-15.44,3.42-22.87,6.05
				c-1.77,0.63-2.39,4.5-3.54,6.88c2.08,0.48,4.25,1.62,6.22,1.33c14.11-2.05,28.16-1.12,42.31-0.34
				c13.43,0.75,26.98,0.91,40.39-0.02c24.86-1.71,49.62-4.89,74.49-6.56c31.87-2.14,63.77-3.94,95.69-4.82
				c20.75-0.57,41.56,0.68,62.34,1.32c3.87,0.12,5.71-0.66,6.95-4.85c3.31-11.13,12.33-14.39,22.71-9.19
				c11.7,5.86,15.83,16.43,18.11,28.23c1.1,5.66,2.08,11.35,3.17,17.29c2.26,0,4.08-0.04,5.89,0.01c25.62,0.64,51.23,1.47,76.85,1.9
				c18.28,0.31,36.57,0.35,54.85,0.03c14.77-0.26,29.53-1.26,44.3-1.9c2.41-0.1,4.83-0.01,7.33-0.01c0-8.62,0-16.41,0-24.32
				c-8.16-2.15-16.18-4.13-24.11-6.41c-4.11-1.18-8.95-1.93-11.9-4.6c-5.25-4.75-11-5.72-17.46-5.45
				c-5.98,0.25-11.96,0.53-17.94,0.77c-1.48,0.06-2.97,0.01-4.45,0.01c-0.1-0.5-0.19-1-0.29-1.5c1.69-0.83,3.33-1.76,5.07-2.45
				c2.78-1.11,5.98-1.54,8.35-3.19c6.44-4.48,13.51-5.97,21.15-6.22c6.75-0.22,11.83-3.1,15.1-9.57c2.74-5.42,8.07-7.84,14.1-7.94
				C1152.74,140.05,1156.7,140.6,1162.28,140.98z M842,1251.3c1.81,5.04,3.74,10.22,5.53,15.45c0.91,2.66,2.03,5.37,2.27,8.12
				c0.67,8.03,0.41,16.16,1.49,24.12c2.62,19.43,3.51,38.69-1.98,57.84c-0.5,1.74-0.06,4.92,1.13,5.76
				c6.19,4.4,6.32,10.47,5.69,16.98c-1.36,14.06-2.58,28.14-4.11,42.18c-0.35,3.17-1.76,6.23-2.72,9.48
				c3.56,1.85,7.23,4.28,11.26,5.73c7.61,2.75,15.27,5.97,23.18,7.08c9.52,1.33,18.59,3.18,27.1,7.73
				c6.74,3.6,13.64,6.92,20.49,10.31c1.08,0.54,2.31,0.78,4.35,1.44c0-8.02-0.14-15.29,0.04-22.55c0.19-7.56-0.38-15.35,1.27-22.62
				c3.21-14.14,3.2-28.3,2.74-42.57c-0.24-7.45-1.02-14.89-1.33-22.34c-0.71-16.78-1.44-33.56-1.85-50.34
				c-0.29-11.79-0.2-23.59,0-35.38c0.13-7.55,0.75-15.1,1.16-22.76c-1.43-0.08-2.11-0.26-2.72-0.13c-9.2,1.95-18.29,2.1-27.57-0.06
				c-5.1-1.18-10.67-1.64-15.85-0.97C875,1245.94,858.53,1248.75,842,1251.3z M943.54,974.05c-4.3-3.98-8.95-7.21-12.15-11.52
				c-3.3-4.44-5.07-10-7.72-14.96c-11.81-22.15-23.66-44.22-29.38-69.02c-2.49-10.82-6.99-21.21-9.18-32.07
				c-4.85-24.08-8.89-48.32-13.13-72.51c-2.72-15.52-5.32-31.06-7.79-46.62c-1.01-6.4-1.35-12.9-2.32-19.3
				c-2.35-15.38-7.57-30.14-7.67-46.11c-0.09-14.2-3.02-28.38-4.71-42.57c-2.44-20.47-4.72-40.97-7.41-61.41
				c-1.26-9.54-3.71-18.92-4.94-28.46c-2.25-17.44-3.27-35.08-6.22-52.39c-1.88-11.03-6.29-21.64-9.65-32.41
				c-2.82-9.04-8.63-16.06-15.59-22.18c-5.01-4.41-14.25-3.02-17.02,3.01c-2.58,5.62-4.06,11.77-5.74,17.77
				c-0.61,2.17-1.09,4.79-0.43,6.82c2.96,9.16,2.26,18.29,1.23,27.64c-0.53,4.83,0.53,9.88,1.19,14.78
				c1.24,9.17,3.43,18.26,3.91,27.46c0.7,13.26-0.08,26.5,2.77,39.73c1.8,8.33,0.92,17.2,1.78,25.78c1.03,10.2,2.99,20.3,3.87,30.5
				c0.64,7.43-0.44,15.03,0.36,22.43c0.99,9.22,3.35,18.29,4.69,27.49c0.77,5.24,0.6,10.62,1.02,15.92
				c1.54,19.89,3.3,39.77,4.67,59.67c0.85,12.44,0.7,24.96,1.76,37.38c2.16,25.22,6.79,50.05,13.58,74.44
				c4.35,15.65,8.89,31.27,13.82,46.75c2.23,6.99,6.81,12.35,13.84,15.57c4.56,2.08,8.69,5.11,12.98,7.78
				c1.54,0.96,2.81,2.56,4.46,3.12c9.63,3.27,15.29,10.58,20.48,18.76c2.22,3.5,5.71,6.32,9,8.98c4.71,3.81,9.89,7.03,14.63,10.8
				c4.02,3.19,7.38,7.28,11.6,10.14C931.01,979.9,937.48,979.25,943.54,974.05z M400.46,366.21c0.02,0.26,0.04,0.53,0.06,0.79
				c7.94,3.78,15.85,7.63,23.86,11.26c1.17,0.53,2.84-0.13,4.27-0.09c5.37,0.15,11.24-0.94,15.99,0.88
				c5.87,2.25,11.17,3.59,17.16,1.62c1.24-0.41,2.6-0.68,3.9-0.67c7.75,0.05,15.5,0.19,23.25,0.31c2.59,0.04,4.52-0.21,5.67-3.41
				c2.13-5.9,7.33-8.58,13.25-6.47c4.56,1.62,8.58,4.75,12.86,7.17c1.18,0.66,2.46,1.77,3.64,1.69c4.79-0.3,9.65-0.6,14.32-1.65
				c3.47-0.78,7.68-1.87,9.78-4.34c3.6-4.23,7.83-5.31,12.73-5.47c5.09-0.16,10.18-0.04,15.13-0.04
				c3.42-13.32,9.57-17.58,23.03-16.06c7.36,0.84,14.66,2.88,22-0.37c1.21-0.54,2.96-0.07,4.41,0.19c5.49,0.97,10.89,2.93,16.45-0.21
				c1.33-0.76,3.6-0.18,5.35,0.19c3.41,0.72,6.81,1.55,10.12,2.65c2.89,0.97,4.85,0.5,6.93-1.94c3.69-4.33,8.73-5.87,15.17-6.08
				c-4.86,2.57-10.04,3.75-12.58,7c-3.85,4.92-7.62,4.05-12.26,2.83c-3.98-1.05-8.63-3.36-11.98-2.19
				c-6.03,2.09-11.29,1.26-16.96-0.18c-1.86-0.47-4.19-0.66-5.89,0.05c-5.55,2.3-11,1.74-16.63,0.61c-3.07-0.62-6.27-0.98-9.4-0.89
				c-8.34,0.24-13.19,4.1-14.81,12.01c-0.79,3.86-2.5,5.49-6.42,4.75c-1.46-0.27-2.98-0.33-4.47-0.36
				c-6.87-0.12-14.1-1.44-18.86,5.67c-0.48,0.71-1.68,1.1-2.62,1.32c-8.3,1.9-16.62,3.73-22.25,4.99
				c-6.95-3.87-11.55-7.11-16.64-9.07c-5.65-2.17-9.31,0.34-11.73,5.9c-0.71,1.63-2.85,3.64-4.43,3.72
				c-7.39,0.39-14.82,0.13-22.24,0.2c-3.13,0.03-6.26,0.31-9.38,0.48c3.68,4.55,8.06,7.21,11.16,10.94c2.47,2.97,5.08,4.1,8.54,5.07
				c2.79,0.79,5.87,2.59,7.54,4.88c4.34,5.94,8.1,7.84,15.15,6.31c1.1-0.24,2.44-0.4,3.42,0.01c6.03,2.52,12.28,2.13,18.59,1.94
				c16.96-0.49,33.93-1,50.89-1.01c7.24-0.01,14.51,1,21.71,1.96c2.69,0.36,5.21,2.68,7.83,2.75c12.93,0.3,25.89,0.51,38.81,0.04
				c18.56-0.66,37.1-1.91,55.64-2.97c1.64-0.09,3.54-0.14,4.82-0.98c2.05-1.34,5.24-3.45,5.12-5.02c-0.16-2.17-2.6-4.68-4.71-6.04
				c-2.43-1.57-5.87-1.56-8.32-3.1c-4-2.52-7.81-3.15-12.3-1.66c-1.27,0.42-3.61-0.25-4.49-1.27c-2.33-2.69-4.82-2.92-8.05-2.25
				c-1.33,0.28-3.52-0.44-4.35-1.49c-5.99-7.46-14.56-8.89-22.46-3.41c-2.56,1.77-5.05,3.65-7.17,5.19
				c-10.8-8.22-21.52-13.82-35.17-7.66c-0.14-2.09-0.13-4.06-0.42-5.97c-0.99-6.45-7.43-9.53-13.1-7.23
				c-6.91,2.81-14.22,4.65-21.33,6.99c-2.86,0.94-5.65,2.1-8.51,3.17c0.07-4.37,4.18-4.23,7.49-5.21c7.18-2.14,14.4-4.19,21.45-6.7
				c3.89-1.38,7.25-1.7,10.86,0.67c1.65,1.08,3.87,1.89,5.81,1.83c18.62-0.54,37.23-1.16,55.84-2.02
				c34.21-1.58,68.42-3.31,102.62-5.08c4.99-0.26,9.94-1.04,16.21-1.72c-5.47-4.7-10.22-6.03-15.47-4.03
				c-4.81,1.83-8.13,0.15-11.6-3.14c-2.57-2.44-5.72-5.07-9.01-5.79c-7.39-1.62-15.04-2.84-22.57-2.77
				c-4.29,0.05-7.53-0.81-10.97-2.96c-7.6-4.77-15.42-5.68-23.53-0.93c-1,0.59-3.3,0.26-4.22-0.54c-5.57-4.79-11.27-8.32-18.9-4.61
				c-0.81,0.39-1.98,0.15-2.97,0.05c-6.42-0.63-12.83-1.38-19.25-1.92c-2.8-0.23-5.95-0.86-8.37,0.12c-3.55,1.43-6.04,0.33-8.81-1.34
				c-3.98-2.4-7.8-5.24-12.07-6.95c-14-5.6-31.38-7.75-41.47,7.35c-0.9,1.34-4.27,1.37-6.49,1.39c-4.86,0.05-9.75-0.69-14.57-0.34
				c-3.31,0.24-6.47,2.15-9.79,2.62c-10.1,1.45-19.13,4.72-25.8,13.3c-0.95-0.31-2.19-0.83-3.47-1.12
				c-5.75-1.26-11.58,0.52-13.44,4.96c-1.84,4.39-4.67,4.78-8.72,4.33c-3.86-0.43-8.24-1.25-11.63,0.08
				c-7.29,2.85-14.28,3.65-21.8,1.48c-5.25-1.51-10.6-3.06-16.01-3.56c-9.05-0.83-18.35-1.88-25.92,5.33
				c-0.94,0.89-2.75,1.08-4.18,1.18C405.7,366.34,403.07,366.21,400.46,366.21z M157.18,836.47c-0.47-0.37-0.93-0.75-1.4-1.12
				c-4.07,1.64-8.18,3.2-12.21,4.92c-8.83,3.78-17.34,8.61-26.5,11.2c-10.39,2.94-18.89,7.81-25.62,16.13
				c-10.35,12.79-20.88,25.44-31.15,38.3c-3.64,4.56-7.47,7.39-13.65,5.31c-1.97-0.66-4.29-0.39-6.44-0.37
				c-12.83,0.13-23.57,6.2-34.45,12.01c-2.94,1.57-3.8,3.43-3.78,6.65c0.13,27.14,0.07,54.28,0.09,81.42c0,1.39,0.22,2.77,0.28,3.41
				c7.73-3.75,15.31-7.35,22.82-11.11c4.24-2.12,8.5-4.26,12.48-6.82c7.66-4.94,15.13-10.17,22.65-15.31
				c1.49-1.02,3.11-1.96,4.3-3.28c4.23-4.7,8.41-9.45,12.39-14.36c4.9-6.03,10.22-11.31,17.98-13.62c2.14-0.64,4.12-2.15,5.89-3.61
				c5.55-4.6,11.56-5.51,18.15-2.73c3.21,1.36,6.43,2.71,9.56,4.24c5.19,2.53,9.81,2.49,13.65-2.46c2.37-3.07,6.33-5.77,6.96-9.13
				c1.2-6.43,0.33-13.22,0.74-19.83c0.23-3.7,0.2-8.14,2.18-10.88c5.37-7.47,11.75-14.2,17.69-21.26c0.5-0.6,1.09-1.53,0.96-2.18
				c-1.46-7.47,3.37-11.95,7.55-16.97c5.47-6.54,5.27-8.38,0.59-15.3c-5.09,2.81-10,5.83-15.2,8.23c-2.45,1.13-5.63,1.73-7.56-1.5
				c-1.91-3.21-3.21-6.28-1.25-10.27C156.29,843.27,156.45,839.73,157.18,836.47z M927.29,799.63c8.77-3.03,17.1-5.98,25.5-8.76
				c3.4-1.12,5.03-2.81,4.97-6.78c-0.14-8.29,0.27-16.6,0.64-24.89c0.2-4.46,1.06-8.89,1.14-13.35c0.14-7.3-0.3-14.61-0.17-21.91
				c0.23-12.48,0.84-24.96,1.07-37.44c0.15-7.91,0.51-15.89-0.29-23.73c-0.86-8.4-3.75-16.61-4.37-25.01
				c-1.21-16.23-1.44-32.54-2.11-48.82c-0.11-2.73-0.34-5.45-0.56-8.81c-4.83,3.45-7.65,8.51-14.15,8.92c1.58-4,2.87-7.28,4.17-10.56
				c-3.64,3.2-6.66,6.53-9.32,10.13c-1.33,1.8-2.06,4.07-2.98,6.16c-1.16,2.62-2.08,5.36-3.38,7.9c-1.13,2.2-3.61,4.09-3.86,6.29
				c-1.19,10.69-9.02,19.1-9.88,29.84c-0.06,0.76-1.07,1.44-1.65,2.17c-4.21-2.35-5.09-4.96-3.65-9.6
				c5.46-17.54,12.19-34.89,10.26-53.9c-0.28-2.73,0.4-5.62,0.97-8.37c0.66-3.16,2.2-5.05,6.03-3.74c1.49,0.51,4.65,0.26,4.88-0.4
				c0.63-1.8,0.48-4.12-0.09-6.01c-0.5-1.66-2.26-2.91-2.97-4.56c-1.17-2.73-1.9-5.66-2.9-8.47c-1-2.81-2.12-5.57-3.18-8.36
				c-0.53,0.17-1.07,0.33-1.6,0.5c3.27,4.63,1.25,8.61-1.06,12.57c-0.59,1-1.48,2.01-1.59,3.07c-0.55,5.55-4.24,5.83-8.29,4.82
				c-4.97-1.25-9.8-3.04-14.73-4.44c-1.27-0.36-2.71-0.11-4.08-0.15c0.04,1.49-0.25,3.08,0.19,4.44c0.76,2.35,1.93,4.58,3,6.82
				c1.63,3.4-0.34,4.92-3.17,4.97c-1.94,0.03-4.55-0.74-5.73-2.12c-6.24-7.29-14.69-10.53-23.28-13.68
				c-5.15-1.88-10.66,0.92-12.79,7.38c11.79-0.36,15.85,8.88,20.78,16.9c5.49,8.92,10.48,18.01,11.88,28.78
				c1.13,8.71,3.38,17.27,5.09,25.91c6.65,33.58,13.23,67.16,19.96,100.72c2.05,10.24,3.62,20.69,6.92,30.53
				C916.97,776.81,922.63,788.5,927.29,799.63z M633.24,1405.74c11.59-1.46,21.25-2.84,30.94-3.83c6.9-0.7,14.06-2.12,20.76-1.06
				c29.68,4.72,59.11,11.15,88.88,15.05c15.58,2.04,14.73,4.37,20.97-11.65c0.72-1.86,1.39-3.74,1.97-5.65
				c3.18-10.46,1.36-19.81-6.66-27.47c-3.96-3.78-8.02-7.61-12.6-10.54c-22.77-14.58-38.59-34.19-45.05-60.79
				c-28.39,4.66-56.19,16.05-85.67,6.95c-0.72,4.74-1.38,8.99-2.02,13.25c-2.52,16.77-5.38,33.51-3.56,50.58
				c0.7,6.56-0.2,12.75-3.05,18.89C635.97,1394.15,635.08,1399.41,633.24,1405.74z M1057.48,1246.76c0.51,0.28,1.02,0.56,1.53,0.84
				c3.82-4.51,7.55-9.11,11.51-13.5c1.95-2.17,2.75-3.94,0.44-6.38c-0.77-0.82-1.42-2.55-1.08-3.5c1.64-4.57,7.99-6.18,12.16-3.54
				c8.82,5.59,17.67,11.15,26.68,16.44c2.85,1.67,6.15,2.79,8.52-1.43c0.53-0.94,2.58-1.05,3.95-1.48c1.99-0.63,4.08-0.99,5.97-1.83
				c6.03-2.7,11.96-4.69,18.22-0.53c5.06,3.36,10.25,6.51,16.02,10.16c0-2.55,0-4.34,0-6.12c0-44.96-0.32-89.92,0.25-134.87
				c0.13-10.1-2.06-18.4-9.38-24.86c-3.29-2.9-7.77-4.83-12.03-6.13c-3.13-0.96-6.79-0.19-10.84-0.19
				c3.91,6.65,7.76,13.19,11.6,19.75c2.25,3.84-2.07,12.17-6.34,12.06c-7.99-0.21-14.99-2.58-19.74-9.6
				c-0.8-1.19-1.99-2.93-3.08-2.98c-9.6-0.5-15.45-6.97-21.67-13c-4.41-4.28-6.55-4.31-12.55-0.19c5.67,6.95,11.32,13.85,17.1,20.93
				c-1.77,2.92-6.88,4.15-4.83,8.84c4.02,9.17,7.14,18.71,17.97,23.03c12.03,4.8,19.61,15.42,28.64,23.95
				c-2,3.99-4.83,3.95-7.64,2.05c-4.92-3.32-10.22-4.62-16.1-5.52c-5.12-0.78-10.32-3.55-14.59-6.68c-4.59-3.37-8.65-6.99-14.64-7.82
				c-2.26-0.31-4.69-1.94-6.35-3.65c-5.2-5.36-10.06-11.04-15.05-16.6c-0.78-0.87-1.55-1.74-2.33-2.61
				c-0.61,0.12-1.23,0.25-1.84,0.37c0,10.78,0,21.57,0,32.35c0,0.67-0.17,1.38,0.02,1.99c0.37,1.2,0.61,2.75,1.48,3.42
				c7.18,5.62,14.46,11.12,21.79,16.54c0.82,0.61,2.59,1.13,3.14,0.7c4.89-3.79,8.26-0.07,12.09,2.05
				c7.08,3.93,14.24,7.72,21.43,11.46c7.66,3.99,15.39,7.86,23.41,11.95c-8.33,9.11-10.63,9.66-17.73,5.43
				c-12.49-7.44-25.03-14.82-37.55-22.22c-0.57-0.34-1.14-0.68-1.71-1.02c-8.64-5.07-17.29-10.14-26.87-15.76
				C1057.48,1188.85,1057.48,1217.81,1057.48,1246.76z M749.71,1428.71c-0.22,0.69-0.45,1.38-0.67,2.07c-2.73-0.93-5.5-1.76-8.18-2.8
				c-12.97-5.06-25.52-11.27-39.62-13.04c-3.87-0.49-7.7-2-11.35-3.53c-21.53-9.03-43.65-6.91-65.07-1.3
				c-13.59,3.56-26.04,11.4-39.09,17.13c-3.46,1.52-7.24,2.3-10.88,3.41c-4.26,1.29-8.54,2.51-12.77,3.86
				c-9.61,3.07-19.03,6.94-28.83,9.12c-11.72,2.6-23.63,4.5-34,10.83c-8.84,5.4-18.18,9.03-28.37,10.22
				c-24.06,2.81-42.51,16.04-59.7,31.71c-0.4,0.37-0.56,1-0.92,1.67c0.87,0.16,1.35,0.32,1.82,0.32c24.3,0.02,48.6,0.03,72.9-0.01
				c1.26,0,2.75-0.18,3.74-0.86c6.79-4.65,14.65-7.75,20-14.53c2.33-2.96,5.6-5.19,8.56-7.63c1.13-0.93,2.56-1.5,3.85-2.23
				c0.24,0.3,0.47,0.6,0.71,0.9c-1.38,2.35-2.76,4.71-4.69,8c3.48-1.35,6.33-1.94,8.59-3.43c8.26-5.42,17.27-7.82,27.05-8.93
				c7.49-0.85,13.41-4.96,16.73-12.56c1.32-3.03,4.48-5.67,7.42-7.5c8.22-5.11,17.57-3.75,26.69-4.08
				c13.47-0.49,20.4-1.28,24.72-14.5c0.61-1.88,1.42-3.76,2.49-5.42c0.96-1.48,2.17-3.3,3.68-3.85c6.02-2.2,12.18-4.03,18.33-5.85
				c4.9-1.45,9.81-2.92,14.81-3.97c3.31-0.69,7.09-1.67,10.12-0.75c15.18,4.61,30.75,8.3,44.57,16.62c1.95,1.18,4.36,1.57,6.46,2.54
				c2.98,1.36,5.98,2.74,8.76,4.44c3.82,2.33,7.19,5.54,11.21,7.37c10.1,4.61,20.29,9.11,30.78,12.67c3.68,1.25,8.33-0.37,12.53-0.68
				c-0.01-0.62-0.01-1.23-0.02-1.85c-3.39-1.11-6.78-2.23-10.16-3.34c0.14-0.48,0.27-0.96,0.41-1.44c2,0.4,4.16,0.44,5.97,1.25
				c4.74,2.12,9.12,5.86,14.02,6.65c11.77,1.9,22.69,7.5,34.93,7.32c7.63-0.11,15.32,0.34,22.89,1.3
				c14.43,1.84,28.43,6.57,43.26,6.03c4.55-0.17,9.29,0.91,13.71,2.21c11.03,3.24,22.12,5.05,34.26,3.09
				c-2.11-1.53-3.79-3.15-5.78-4.13c-12.82-6.31-25.61-12.69-38.62-18.58c-5.23-2.37-10.91-4.67-16.52-5.05
				c-14-0.94-26.46-5.82-38.62-12.26c-2.82-1.49-6.29-1.93-9.52-2.45c-10.96-1.76-21.99-3.1-32.9-5.13
				c-3.25-0.61-5.76-0.73-8.03,1.73c-3.52,3.82-7.94,4.07-12.4,2.61c-4.42-1.44-8.54-3.79-12.95-5.25c-4.84-1.61-5.71-3.03-3.28-8.78
				c-24.24-4.11-48.26-8.17-72.27-12.24c-0.16,0.39-0.32,0.78-0.48,1.18C716.58,1414.25,733.15,1421.48,749.71,1428.71z
				M1024.81,1381.94c0.27-6.04,0.64-12.01,0.77-17.99c0.21-9.99,0.23-19.98,0.39-29.96c0.27-17.13,0.83-34.25,0.81-51.38
				c-0.02-12.26-0.26-24.58-1.39-36.78c-0.86-9.28,1.45-17.9,2.52-26.85c2.17-18.26,1.97-36.8,2.64-55.23
				c0.47-12.81,0.74-25.62,1.14-38.43c0.04-1.27,0.37-2.53,0.57-3.79c0.6,0.06,1.19,0.12,1.79,0.19c0,14.11,0.12,28.21-0.03,42.32
				c-0.2,17.97-0.57,35.95-0.97,53.92c-0.1,4.28-0.44,8.57-0.99,12.82c-0.81,6.22-2.56,12.38-2.76,18.6
				c-0.32,9.61,0.33,19.25,0.58,28.88c0.66,26.45,1.26,52.9,2.07,79.35c0.09,3.08,0.87,6.36,2.24,9.09c0.94,1.86,3.48,2.91,5.3,4.32
				c0.4-0.15,0.79-0.3,1.19-0.46c-3.58-11.63,5.73-2.66,7.09-5.88c0.05-2.1,0.49-4,1.1-5.85c0.87-2.62,2.56-5.15,2.7-7.79
				c0.85-16.22,1.6-32.45,1.9-48.68c0.18-9.62-1.43-19.34-0.63-28.88c1.37-16.39,2.89-32.63,1.85-49.18
				c-1.03-16.37-0.29-32.87,0.06-49.31c0.41-19.29,0.77-38.61,2.03-57.85c0.97-14.93,5.6-29.56,4.02-44.75
				c-0.06-0.62,0.39-1.28,0.46-1.93c0.61-5.95,1.19-11.9,1.78-17.85c-0.55-0.28-1.1-0.55-1.65-0.83c-4.09,4.89-7.92,10.21-15.14,9.12
				c0.34,3.28,0.6,5.83,0.98,9.47c1.56-3.05,2.62-5.13,3.38-6.62c-1.77,10.75-3.62,21.97-5.48,33.18c-0.62-0.06-1.24-0.12-1.87-0.17
				c0-3.06-0.13-6.13,0.02-9.18c0.36-7.29,0.93-14.58,1.2-21.87c0.04-1.2-1.06-2.44-1.64-3.66c-0.9,1.04-2.32,1.96-2.6,3.14
				c-1.36,5.62-2.57,11.29-3.52,16.99c-0.86,5.12-1.31,10.3-2.06,16.49c-3.92-4.27-2.97-8.26-2.77-12.1c0.29-5.8,0.65-11.6,0.83-17.4
				c0.08-2.41-0.88-3.91-3.7-2.91c-6.35,2.26-12.71,4.5-18.82,6.66c0.63,6.87,1.78,13.62,1.75,20.37
				c-0.07,19.94-0.79,39.88-0.93,59.82c-0.21,29.28-0.03,58.57-0.23,87.85c-0.3,44.05,2.44,88.14-1.58,132.16
				c-0.5,5.43,0.22,10.96,0.38,16.45c4.67-3.19,8.08-5.92,7.88-11.44c-0.55-15.63-0.74-31.28-0.82-46.93
				c-0.15-29.28-0.75-58.57,2.73-87.74c0.74-6.2,2.6-12.67-2.74-18.18c-1.04-1.08-1.01-3.46-1.02-5.24
				c-0.05-17.46-0.38-34.94,0.1-52.39c0.7-25.26,1.97-50.51,3.09-75.76c0.34-7.62,2.03-14.94,5-20.15
				c-1.25,11.82-3.49,25.04-3.89,38.32c-1.13,36.77-1.55,73.57-2.03,110.35c-0.03,2.56,1.84,5.11,2.68,7.72
				c0.78,2.41,2.12,4.94,1.93,7.32c-0.99,12.4-3.3,24.75-3.46,37.14c-0.48,36.61-0.17,73.23-0.17,109.95
				C1020.94,1382.33,1022.68,1382.15,1024.81,1381.94z M904.62,1231.94c0.21-0.67,0.41-1.35,0.62-2.02
				c-29.09-25.3-61.84-44.04-98.94-55.01c-0.47,0.53-0.95,1.06-1.42,1.59c11.46,12.6,22.91,25.19,34.37,37.79
				c-0.29,0.36-0.57,0.73-0.86,1.09c-4.3-2.92-8.82-5.56-12.84-8.84c-6.02-4.91-12.51-6.13-19.92-4.3c-2,0.49-4.32,0.33-6.37-0.12
				c-13.14-2.9-25.07-8.66-36.35-15.85c-0.98-0.63-1.71-2.37-1.77-3.62c-0.47-10.43-4.05-15.66-13.99-19.27
				c-5.75-2.09-9.14-5.62-11.02-11.71c-1.57-5.07-4.59-9.98-7.98-14.12c-4.51-5.51-10.41-9.87-14.98-15.33
				c-5.19-6.2-10.81-8.84-18.5-5.3c-0.42,0.19-0.82,0.42-2.39,1.23c3.69,4.04,6.95,7.87,10.49,11.43
				c6.21,6.26,12.65,12.29,18.84,18.57c2.9,2.95,2.79,7,0.01,9.32c-3.33,2.77-2.64,5.06-0.78,7.59c5.11,6.96,10.44,13.76,15.68,20.63
				c1.32,1.74,2.62,3.49,3.93,5.24c-0.26,0.43-0.52,0.86-0.78,1.29c-6.61-1.31-13.24-2.54-19.82-4.01c-1.17-0.26-2.35-1.36-3.11-2.39
				c-4.52-6.11-8.95-12.29-13.32-18.51c-0.53-0.75-0.49-1.9-0.67-2.88c-1.69-8.98-5.88-16.49-12.77-22.56
				c-3.36-2.97-7.09-5.87-9.44-9.57c-3.66-5.79-7.25-11.96-9.06-18.48c-1.45-5.22-3.31-9.41-7.16-13.18
				c-6.88-6.73-13.34-13.88-20.06-20.77c-1.18-1.21-2.78-2.03-4.19-3.02c-0.34,0.39-0.69,0.78-1.03,1.17
				c7.38,13.23,21.08,22.48,25.53,38.12c-2.85,0.59-5.05,0.7-6.98,1.49c-7.45,3.06-13.88,1.44-18.85-4.54
				c-4.56-5.48-8.49-11.49-12.64-17.3c-2.4-3.37-4.7-6.82-7.04-10.23c-0.61,0.38-1.23,0.77-1.84,1.15
				c5.67,9.58,11.33,19.17,17.34,29.34c-8.61,3.89-16.84-3.76-25.88-0.13c5.86,6.77,11.54,13.04,16.89,19.58
				c4.29,5.24,8.51,10.61,12.14,16.31c2.96,4.63,1.33,7.33-4.21,7.91c-2.96,0.31-5.99,0.06-9.53,0.06
				c12.35,11.56,25.26,19.85,41.47,23.26c10.6,2.23,20.49,7.67,30.94,10.89c13.96,4.3,28.12,8,42.27,11.64
				c11.39,2.93,22.85,5.69,34.38,7.98c17.74,3.51,35.12,7.63,49.54,19.55c1.29,1.06,3.73,1.34,5.51,1.08c8.54-1.22,17-3.02,25.56-4.1
				c12.33-1.56,24.65-3.56,37.05,0.3C895.08,1231.7,899.97,1231.45,904.62,1231.94z M737.17,1189.71c-2.03-2.43-3.44-3.98-4.69-5.65
				c-4.58-6.12-9.28-12.16-13.56-18.48c-2.03-2.99-3.77-6.31,0.97-9.33c1.09-0.69,0.88-4.94-0.05-6.93
				c-1.14-2.44-3.59-4.31-5.61-6.28c-6.44-6.26-13.27-12.16-19.34-18.76c-6.06-6.59-5.22-7.79,3.1-10.35
				c5.33-1.64,8.99-0.64,12.73,3.37c5.1,5.47,11.22,10.03,16.02,15.73c4.42,5.25,8.74,11.08,11.13,17.41
				c2.17,5.73,4.75,9.65,10.65,11.12c8.31,2.07,13.33,8.32,13.98,15.83c0.6,6.95,4.57,9.04,9.17,11.7c0.72,0.42,1.38,0.99,2.14,1.28
				c8.66,3.31,17.21,7.02,26.08,9.65c3.34,0.99,7.56-1.32,11.13-0.7c5.81,1.01,11.42,3.2,16.88,4.82
				c-12.58-12.86-25.67-26.1-38.6-39.51c-11.2-11.62-22.55-23.14-33.19-35.26c-5.76-6.56-11.07-13.24-19.66-16.17
				c-0.45-0.15-0.84-0.54-1.21-0.87c-15.2-13.2-31.2-25.61-45.32-39.88c-10.47-10.58-23.28-14.52-36.49-16.19
				c-11.55-1.46-22.2-3.85-31.85-10.05c-5.72-3.68-10.6-2.95-15.49,1.1c-9.59,7.94-19.18,15.89-28.75,23.86
				c-0.75,0.62-1.35,1.42-2.09,2.21c2.53,5.59,5.04,10.49,11.5,12.97c3.87,1.48,7.16,5.34,9.86,8.8c3.35,4.3,5.69,9.37,8.74,13.92
				c0.98,1.47,2.67,3.07,4.28,3.4c6.16,1.27,12.42,2.09,18.65,2.97c1.14,0.16,2.36-0.18,3.55-0.29
				c-5.05-11.42-15.79-19.72-14.96-33.45c2.02,1,3.48,2.36,4.61,3.96c5.95,8.42,11.47,17.16,17.81,25.27
				c5.26,6.72,10.15,7.49,18.09,4.59c1.38-0.5,2.83-0.81,4.8-1.37c-1.3-2.53-2.18-5.07-3.73-7.11c-5.22-6.89-10.67-13.6-16.02-20.4
				c-2.6-3.31-5.25-6.61-4.99-12.67c3.02,1.64,5.61,2.4,7.31,4.07c7.69,7.57,15.38,15.17,22.54,23.23c2.71,3.06,5.01,7.21,5.71,11.18
				c1.24,7,3.83,13.06,8.52,18.2c3.7,4.05,7.68,7.86,11.65,11.65c5.98,5.7,9.7,12.56,11.18,20.69c0.29,1.59,0.84,3.25,1.73,4.58
				c3.12,4.66,6.28,9.31,9.75,13.72c1.66,2.12,3.76,4.51,6.15,5.31C726.45,1188.12,731.3,1188.58,737.17,1189.71z M936.83,1127.73
				c-4.01-5.51-7.26-10.02-10.57-14.49c-9.98-13.47-18.34-28.29-31.63-39.04c-6.58-5.32-13.92-9.76-20.11-15.46
				c-7.45-6.86-14.24-14.46-20.98-22.05c-13.54-15.24-29.43-27.2-47.68-36.27c-6.02-2.99-11.62-7.08-16.86-11.35
				c-9.93-8.08-19.36-16.77-29.23-24.93c-2.02-1.68-4.95-2.41-7.58-3.16c-3.96-1.14-8.27-1.35-12.02-2.93
				c-14.24-6-28.29-12.43-42.45-18.62c-2.55-1.11-5.4-2.83-7.91-2.54c-9.92,1.14-19.77,2.97-30.78,4.73
				c2.04,2.14,3.03,3.19,4.04,4.22c6.38,6.55,12.83,13.03,19.1,19.69c1.12,1.19,2.5,3.6,2.01,4.57c-1.97,3.92,0.64,5.66,2.73,8.04
				c4.49,5.11,9.08,10.19,12.99,15.73c4.58,6.48,12.67,10.28,20.45,9.56c3.57-0.33,7.78,0,10.84,1.64
				c12.42,6.66,24.53,13.89,36.69,21.03c3.14,1.85,6.1,4.03,9.04,6.21c4.56,3.4,8.76,7.99,15.19,3.71c0.37-0.24,0.98-0.22,1.47-0.17
				c9.81,1.01,18.32,5.43,26.7,10.21c4.52,2.58,9.02,5.22,13.65,7.62c5.7,2.95,11.75,5.29,17.23,8.59
				c12.52,7.52,24.83,15.38,37.11,23.27c3.17,2.04,6.28,4.41,8.8,7.19c5.35,5.88,10.52,11.95,15.37,18.26
				C918.5,1118.9,926.93,1123.13,936.83,1127.73z M370.25,320.8c-5.52-0.66-10.41-1.22-15.3-1.84c-11.8-1.48-23.59-3.09-35.41-4.42
				c-4.72-0.53-9.78-1.59-14.21-0.48c-10.53,2.64-20.48,2.34-30.61-1.63c-2.87-1.12-6.52-0.79-9.72-0.36
				c-5.04,0.67-9.95,2.48-14.99,2.89c-5.91,0.48-11.9,0.09-17.85-0.13c-6.73-0.25-13.8,0.45-20.09-1.41
				c-7.36-2.18-14.06-2.68-21.39-0.58c-4.85,1.39-10.06,1.55-15.11,2.18c-2.98,0.37-4.47-0.89-5.08-4.02
				c-1.08-5.5-4.63-7.4-10.07-7.15c-4.53,0.21-9.11-0.7-13.68-0.94c-4.86-0.26-9.73-0.42-14.6-0.47c-4.58-0.05-9.18,0.44-13.73,0.1
				c-7.76-0.59-15.46-1.92-23.22-2.42c-6.96-0.44-13,1.92-17.69,7.47c-3.41,4.04-7.75,5.01-13.05,3.8
				c-4.98-1.13-10.19-1.79-15.29-1.7c-14.08,0.22-28.15,0.94-42.23,1.46c-1.45,0.05-2.9,0.15-4.61,0.23c0,10.9,0,21.48,0,33.23
				c6.96,0.3,13.84,0.91,20.72,0.81c6.1-0.09,12.19-1.03,18.29-1.47c2.82-0.2,5.79,0.44,8.46-0.22c8.9-2.22,16.98,0.15,25.29,3.03
				c4.55,1.57,9.68,1.77,14.58,1.99c4.1,0.19,8.24-0.73,12.36-0.74c8.56-0.02,16.14-2.47,21.97-8.88c4.3-4.73,9.33-5.8,15.35-4.84
				c2.89,0.47,6.44,1.15,8.78-0.05c7.1-3.62,13.39-2.45,19.86,1.94c4.41-6.73,9.44-11.58,17.95-5.93c2.02-4.36,3.85-8.24,5.62-12.14
				c1.34-2.93,3.17-3.68,6.37-2.47c5.74,2.16,11.55,4.62,17.55,5.52c9.72,1.46,18.25,5.61,27.22,9.07c6.26,2.42,13.48,7.99,20.6,0.87
				c0.29-0.29,0.96-0.34,1.44-0.29c6.91,0.71,14.46-1.34,20.14,4.81c0.63,0.68,2.11,0.83,3.18,0.79c18.39-0.69,37.12,4,55.05-4.29
				c8.37-3.87,17.32-6.46,26.01-9.64C369.23,322.43,369.29,322.21,370.25,320.8z M270,972.3c0,7.01,0.55,15.85-0.11,24.6
				c-1.39,18.23,0.09,36.22,3.43,54.09c0.38,2.01,1.89,4.08,3.42,5.56c5.48,5.31,12.49,9.53,16.54,15.73
				c7.34,11.23,19.2,16.59,28.85,25.1c2.17-9.45-8.42-12.88-10.12-20.72c6.73,0.79,13.47,0.49,19.39,2.55
				c7.59,2.64,12.58-0.45,18.11-4.48c-7.34-8.26-13.95-16.49-17.67-26.52c1.32-0.71,1.47-0.85,1.61-0.86
				c0.66-0.02,1.34-0.08,1.98,0.03c6.92,1.16,11.93-0.12,15.03-7.7c1.31-3.19,6.26-6.19,9.94-6.76c3.92-0.61,6.8-1.35,9.05-4.59
				c1.7-2.45,3.61-4.74,6.07-7.93c-20.96-29.46-45.78-55.62-71.58-79.95c-4.82,6.21-8.79,12.82-14.2,17.91
				C284.2,963.57,277.26,967.28,270,972.3z M184.2,1015.54c7.64-4.45,14.85-8.91,22.33-12.86c3.12-1.65,6.87-3.15,10.26-3
				c4.57,0.2,7.59-1.2,10.21-4.64c1.61-2.11,3.67-3.87,5.29-5.98c0.82-1.07,1.56-2.58,1.53-3.87c-0.12-4.65-0.76-9.28-0.86-13.93
				c-0.07-3.09-1.26-4.84-4.19-5.78c-13.01-4.18-18.67-14.3-20.82-26.8c-1.8-10.47-3.65-21.02-4.24-31.6
				c-1.14-20.42-1.39-40.89-2.05-61.33c-0.34-10.55-0.76-21.11-1.12-31.03c-13.72,7.4-27.24,14.7-40.55,21.88
				c-1.04,3.59-2.09,7.67-3.42,11.65c-1.42,4.26,1.7,8.72,5.87,7.36c4.13-1.34,7.86-4,11.65-6.27c2-1.2,3.76-2.81,5.75-4.33
				c6.38,13.61,6.17,14.99-3.45,25.51c-0.56,0.61-1.16,1.21-1.63,1.89c-0.56,0.81-1.5,1.78-1.39,2.57c0.89,6.74-2.34,11.15-7.1,15.53
				c-3.84,3.53-5.98,8.87-9.7,12.57c-4.33,4.32-4.7,8.98-4.23,14.57c0.77,9.01,0.75,18.07-3.52,27.19c2.46-1.63,4.59-2.69,6.28-4.22
				c5.54-5.02,12.11-6.53,19.34-6.49c3.69,0.02,6.19,0.77,7.44,4.88c1.39,4.58,11.41,7.33,15.26,4.64c5.41-3.78,5.41-3.78,9.42,1.53
				c-4.77,9.78-4.77,9.79-14.72,5.55c-0.92-0.39-1.82-0.82-2.73-1.22c-9.82-4.2-18.24,1.73-27.16,3.88
				c-1.19,0.29-2.12,2.05-2.95,3.28c-1.59,2.33-2.92,4.85-4.56,7.14c-1.8,2.51-1.66,4.56,0.19,7.14c3.77,5.27,7.09,10.86,10.67,16.26
				C171.51,996.61,177.8,1005.96,184.2,1015.54z M288.8,935.54c-2.29,2.12-3.56,3.82-5.26,4.65c-0.87,0.42-3.62-0.71-3.63-1.17
				c-0.1-5.78-4.49-7.58-8.38-10.26c-2.24-1.54-4.73-4.4-4.97-6.87c-1.15-11.87-1.54-23.82-2.27-35.73
				c-0.41-6.8-0.88-13.6-1.45-20.39c-1.14-13.56-2.56-27.1-3.51-40.68c-0.85-12.28-1.29-24.58-1.79-36.88
				c-0.65-16.1-1.15-32.22-1.81-48.32c-0.15-3.64-0.52-7.3-1.14-10.88c-0.74-4.31-3.46-7.87-7.7-8c-4.51-0.14-4.81,4.6-5.11,7.87
				c-1.12,12.25-1.94,24.52-2.67,36.8c-0.28,4.64-0.26,9.33,0.02,13.97c0.68,11.62,1.91,23.21,2.32,34.84
				c0.68,19.29,0.76,38.6,1.34,57.89c0.52,17.46,1.57,34.92,1.98,52.38c0.23,9.98-0.35,19.97-0.57,29.96
				c0.25,0.27,0.5,0.54,0.75,0.81c1.93-2.7,3.85-5.4,5.78-8.1c0.5,5.88,0.03,11.42-0.69,16.93c-0.12,0.95-1.49,1.67-2.05,2.63
				c-1.13,1.95-2.99,3.99-2.99,6c-0.02,9.81,0.77,19.62,0.73,29.43c-0.08,18.8-1.06,37.6-0.68,56.39
				c0.53,25.44,1.97,50.86,3.08,76.29c0.08,1.86,0.64,3.7,0.82,4.68c9.69-3.96,19.02-7.78,28.14-11.51
				c-0.27-3.03-0.47-5.18-0.66-7.32c-1.95-22.2-3.77-44.42-5.91-66.61c-1.11-11.57-3.44-23.05-4.02-34.64
				c-0.57-11.26,0.88-22.62,0.48-33.91c-0.2-5.63,2.08-12.46-5.18-16.66c6.5-1.71,12.35-3.25,18.3-4.82
				C275.98,952.07,286.4,945.89,288.8,935.54z M1114.09,639.6c0.34,0.18,0.68,0.36,1.02,0.55c2.75-3.27,5.67-6.42,8.21-9.86
				c2.06-2.79,3.42-5.91-0.97-8.37c3.94-4.91,5.63-11.65,12.53-12.1c-0.69,7.33-1.57,14.14-1.91,20.97
				c-0.28,5.72-0.37,11.25-3.55,16.46c-1.32,2.16-1.5,5.46-1.15,8.11c0.41,3.15,1.95,6.15,3.06,9.41c4.5-8.59,9.33-17.64,13.97-26.78
				c1.41-2.77,2.73-5.69-0.38-8.55c-1.98-1.81-3.5-4.11-5.29-6.27c5.97-7.16,12.33-14.4,18.19-22.02c1.87-2.43,3.42-5.88,3.43-8.87
				c0.2-57.29,0.15-114.58,0.13-171.87c0-1.35-0.22-2.71-0.32-3.94c-9.94,9.2-19.6,18.13-28.76,26.61c1.47,2.17,2.51,3.75,3.58,5.3
				c1.22,1.76,2.17,3.89,3.78,5.16c5.99,4.69,6.08,4.87,2.4,11.73c-1.22,2.28-3.27,5.01-2.83,7.09c0.96,4.58-0.63,8.06-2.52,11.82
				c-2.68,5.33-5.07,10.8-7.58,16.22c-3.83,8.3,0.36,13.34,6.79,16.41c3.7,1.76,4.25,4.16,4.29,7.45c0.05,4.06,0.21,8.13,0.16,12.19
				c-0.02,1.63-0.47,3.26-0.68,4.89c-0.36,2.75-0.94,5.51-0.98,8.27c-0.1,6.99-4.36,11.49-8.1,16.33c-0.41-0.19-0.82-0.37-1.22-0.56
				c0.98-7.85,1.95-15.7,2.98-23.91c-5.26-0.62-6.52-4.28-5.88-9.18c0.41-3.14-1.51-4.49-4.18-5.19c-4.43-1.16-8.21,2.26-8.61,8.27
				c-0.81,12.26-1.55,24.53-2.03,36.8c-0.51,13.14-0.54,26.29-1.04,39.43c-0.32,8.39-1.09,16.75-1.68,25.29
				c2.04-0.34,3.44-0.57,5.12-0.84C1114.09,637.47,1114.09,638.54,1114.09,639.6z M809.87,1472.95c-6.97-3.83-12.53-7.59-18.61-10.08
				c-11.9-4.87-23.84-9.54-36.27-13.29c-12.66-3.82-24.88-6.2-37.98-6.04c-5.63,0.07-11.28-1.83-16.94-2.77
				c-1.54-0.26-3.32-0.81-4.65-0.31c-21.77,8.22-44.12,8.68-66.86,5.62c-4.32-0.58-8.86,0.19-13.27,0.63
				c-3.74,0.38-7.41,1.51-11.14,1.74c-4.96,0.31-9.98-0.2-14.94,0.15c-9.73,0.7-16.82,4.8-19.97,14.93
				c-2.15,6.9-5.73,13.35-8.44,20.09c-1.84,4.56-3.29,9.27-5.2,14.76c8.37,0,15.66-0.21,22.92,0.09c3.48,0.14,4.91-1.06,5.87-4.32
				c3.33-11.31,11.13-18.07,22.27-21.53c3.85-1.19,7.43-3.27,11.07-5.1c3.76-1.89,7.43-3.96,11.14-5.95
				c-4.06,7.01-11.97,9.36-14.22,18.27c-1.35,5.34-7.81,9.39-12.62,14.73c0-2.67,0-4.47,0-6.27c-0.39-0.17-0.78-0.33-1.17-0.5
				c-2.54,3.2-5.07,6.4-8.25,10.41c9.64,0,17.77,0,25.59,0c1.36-2.73,2.07-5.72,3.93-7.58c7.65-7.65,18.44-9.14,28.48-4.89
				c3.17,1.34,6.79,1.63,11.84,2.77c1.56,2.21,4.38,6.2,7.19,10.19c-0.63-4.06-2.7-7.46-2.56-10.76c0.21-4.86-1.39-7.26-5.93-7.95
				c-3.52-0.53-5.59-2.02-3.92-6.87c-10.59,0.64-20.47,1.24-31.62,1.92c5.72-5.92,11.58-6.73,17.88-6.67
				c11.09,0.11,22.26,0.77,33.24-0.33c7.92-0.79,15.46-0.75,22.24,2.98c7.71,4.24,15.42,7.42,24.35,6.87
				c0.16-0.01,0.32,0.08,0.49,0.11c9.44,1.71,18.71,1.73,28.05-1.25c3.43-1.09,7.7-0.98,11.23-0.06c13.8,3.62,27.32,1.42,40.88-1.06
				C805.77,1475.32,807.41,1474.11,809.87,1472.95z M1132.02,1344.73c0.09,0.42,0.18,0.84,0.26,1.26c5.69-1.35,11.38-2.7,18.2-4.32
				c2.14-3.68,5.18-4.56,10.18-1.06c0.27-2.21,0.69-4.05,0.69-5.88c0.04-25.47-0.63-50.97,0.34-76.41
				c0.41-10.84-2.9-17.26-12.38-21.88c-5.56-2.7-10.35-6.52-17.08-3.93c-4.18,1.61-8.59,2.62-12.88,3.95
				c-0.43,0.13-0.87,0.57-1.09,0.98c-2.54,4.82-6.43,3.63-9.82,1.71c-8.5-4.83-16.92-9.83-25.1-15.19c-6.36-4.18-6.68-4.4-11.76,2.15
				c6,3.29,2.95,6.79-0.25,9.82c-4.36,4.13-6.06,10.69-12.33,13.17c-1.09,0.43-1.69,3-1.88,4.66c-0.61,5.44-1.01,10.9-1.33,16.36
				c-0.33,5.62-0.6,11.24-0.69,16.87c-0.12,7.93-0.06,15.87-0.07,23.8c-0.01,5.55,0,11.11,0,17.06c8-6.04,14.81-5.24,25.11,0.52
				c8.99,5.03,12.07,13.07,13.44,22.23c2.07-0.18,3.51-0.3,3.8-0.32c-0.47-2.96-1.76-6.04-1.16-8.68c0.64-2.82,3.06-5.24,4.61-7.68
				c-4.57-1.74-9.16-3.16-13.46-5.21c-11.68-5.57-19.44-19.78-18.04-32.48c0.9-8.21,6.01-11.19,13.02-7.46
				c5.8,3.08,11.5,6.34,17.22,9.52c0.01-0.04,0.11-0.22,0.06-0.31c-0.23-0.44-0.51-0.85-0.77-1.27c-8.89-14.5-8.05-29.74-3.03-45.11
				c1.16-3.56,3.77-7.76,8.07-6.88c3.29,0.67,7.04,3.35,8.8,6.23c2.52,4.13,3.91,9.15,4.92,13.97c2,9.6,3.4,19.32,5.1,29.3
				c1.2-1.75,2.12-3.31,3.25-4.7c2.74-3.36,5.77-6.01,10.67-4.34c5.11,1.74,7.92,5.24,8.11,10.7c0.39,10.94-5.5,18.59-13.7,24.59
				c-2.07,1.51-5.73,1.73-8.44,1.25c-3.28-0.57-3.93,0.73-4.46,3.06c4.85-0.45,9.44-1.11,14.05-1.23c4.58-0.12,8.39,1.79,10.96,5.78
				c3.29,5.1,2.01,9.78-3.45,12.29C1137.21,1342.74,1134.59,1343.69,1132.02,1344.73z M550.16,605.5c-0.27-0.31-0.54-0.62-0.82-0.93
				c1.66-7.85,3.31-15.69,5-23.66c-14.48,3.94-26.92,9.96-35.74,22.79c-10.09,14.67-23.51,25.63-41,31
				c-4.38,1.35-8.71,3.47-12.48,6.07c-9.31,6.41-18.34,13.25-27.36,20.06c-2.22,1.68-4.3,3.71-5.94,5.95
				c-1.86,2.54-2.12,5.4,1.72,9.49c1.88-2.62,3.29-5.15,5.23-7.18c4.94-5.16,9.85-10.42,15.32-14.98c2.62-2.19,6.4-3.39,9.84-4.01
				c1.34-0.24,4.66,2.5,4.54,3.61c-0.51,4.66-1.9,9.24-3.16,13.8c-0.23,0.83-1.26,1.5-2.04,2.11c-7.99,6.24-16.05,12.39-23.99,18.69
				c-4.19,3.32-8.19,6.86-13.06,10.96c9.69-1.67,17.99-3.03,26.25-4.6c1.36-0.26,2.57-1.38,3.8-2.17c7.79-5,15.4-10.32,23.45-14.84
				c2.55-1.43,6.27-0.8,9.47-1.05c1.69-0.13,4.21,0.58,4.95-0.29c5.87-6.85,14.18-11.7,17.31-20.84c0.96-2.81,3.11-4.97,6.08-3.53
				c5.68,2.75,8.39-0.75,11.68-4.28c4.96-5.32,9.68-11.27,15.68-15.11c6.92-4.42,13.2-9.23,18.94-15.05
				c4.38-4.44,9.49-8.17,13.89-11.89c-2.53-0.85-5.01-1.68-7.49-2.51c1.21-2.43,2.36-4.89,3.67-7.26c0.47-0.85,1.41-1.44,1.93-2.28
				c0.85-1.37,2.21-2.85,2.18-4.26c-0.1-5.53,3.82-12.99,8.64-15.09c0.97-0.42,2.75-0.27,3.48,0.38c0.65,0.58,0.76,2.36,0.41,3.36
				c-0.81,2.3-2.06,4.44-2.97,6.32c9.89,2.93,11.83-5.57,16.1-12.71c0.86,4,1.05,6.35,1.89,8.43c0.85,2.11,2.35,3.95,3.56,5.92
				c0.31-2.13,1.04-4.3,0.85-6.39c-0.46-5.1-0.24-10.65-2.27-15.13c-4.99-11.03-11.04-21.59-16.79-32.27
				c-1.32-2.46-3.13-4.66-4.82-7.11c-0.79,4.09-0.67,7.92-2.28,10.78c-2.78,4.95-2.09,9.46-0.31,14.24c0.75,2.02,1.51,4.05,2.21,6.09
				c1.5,4.34,0.6,7.75-2.78,11.24c-3.25,3.36-6.44,7.45-7.87,11.8C570.79,592.19,559.34,597.61,550.16,605.5z M287.15,455.86
				c-2.07-2.31-3.91-4.34-5.98-6.65c-5.55,4.84-11.45,6.17-18.38,6.83c-6.3,0.61-12.77,4.54-18.13,8.44
				c-7.27,5.28-14.51,9.59-23.59,10.94c-8.71,1.3-17.03,3.99-24.6,9.01c-4.88,3.23-10.48,5.48-15.93,7.74
				c-4.14,1.71-4.47,1.69-4.71,6.51c1.65,0.19,3.3,0.39,5.8,0.68c-6.36,6.71-15.31,10.23-15.82,20.34c3.11,0.38,5.49,0.66,7.88,0.95
				c0.21,0.51,0.43,1.01,0.64,1.52c-5.67,4.07-11.35,8.13-17.21,12.33c1.47,1.18,2.99,2.19,4.24,3.47c5.13,5.26,10.76,5.82,17.7,3.5
				c8.32-2.78,16.93-5.27,25.6-6.26c11.96-1.37,21.29-7.16,29.8-14.78c7.8-6.98,15.46-14.17,22.69-21.74
				c7.58-7.94,17.05-11.84,27.3-14.36c1.83-0.45,3.92,0.17,6.2,0.31c-0.89,2.49-1.43,4.02-1.96,5.5c8.63,5.14,12.91,4.54,21.13-3.2
				c-7.68-3.63-12.56-9.62-15.57-17.34c-1.59-4.08-4.07-7.58-8.77-8.72c-0.34-0.08-0.53-0.77-0.89-1.34
				C285.32,458.47,286.06,457.41,287.15,455.86z M420.55,1198.66c-1.15,1.59-2.03,3.95-3.13,4.05c-1.39,0.13-2.99-1.52-4.43-2.51
				c-0.52-0.36-0.79-1.05-1.2-1.58c-3.04-3.99-4.91-3.83-8.92-0.74c-4.03,3.1-7.09,7.79-13.65,7.13c-2.4-0.24-5.87,2.93-7.63,5.41
				c-2.38,3.37-3.66,2.49-5.45-0.05c-1.9-2.69-10.58-4.28-12.57-1.78c-1.33,1.67-4.58,2.82-2.94,6.44c1.32,2.89-1.47,3.55-3.36,3.12
				c-2.13-0.48-4.03-1.95-6.24-3.1c-2.75,3.62-5.34,7.64-8.57,11.05c-2.31,2.45-1.6,4.23,0.25,5.86c4.44,3.92,8.95,7.78,13.67,11.33
				c8.62,6.48,19.06,10.95,23.43,21.98c0.48,1.2,1.67,2.11,2.22,3.29c1.24,2.64,2.29,5.38,3.96,9.4c6.59-1.95,14.12-4.16,21.64-6.43
				c4.12-1.25,8.18-2.7,12.3-3.92c7.16-2.12,14.58-3.16,18.21-11.57c1.9-4.41,6.11-7.82,9.37-11.79c-3.54-2.84-3.22-5.13,0.57-6.14
				c3.36-0.9,4.32-3.86,2.94-5.48c-3.63-4.23-1.3-7.8-0.13-11.72c0.28-0.95-0.31-2.16-0.5-3.22c-6.52,0.61-7.58-0.21-8.31-6.8
				c-0.39-3.53-2-5.36-5.61-5.74c-1.71-0.18-4.2-0.66-4.86-1.83C428.91,1198.54,424.34,1199.23,420.55,1198.66z M629.71,903.57
				c-1.22,1.52-1.92,3.29-3,3.57c-4.15,1.06-8.41,2.2-12.64,2.25c-3.79,0.04-7.56-1.47-10.26,2.68c-2.06,3.17-5.58,5.89-6.36,9.29
				c-1.64,7.2-6.14,11.91-11,16.77c-1.63,1.63-2.78,4.28-3.08,6.6c-1.17,9.06-1.93,18.16-2.8,27.26c-0.78,8.05-1.5,16.1-2.24,23.98
				c12.29,4.47,14.59,4,22.7-4.92c3.31-3.64,7.28-4.75,12.05-4.76c3.38-0.01,6.83-0.72,10.12-1.62c3.3-0.9,6.35-2.81,9.67-3.54
				c3.71-0.82,5.19-2.47,5.43-6.36c0.58-9.28,1.49-18.56,2.63-27.79c0.61-4.97,3.75-7.26,8.99-7.37c4.77-0.11,9.52-1.11,14.27-1.82
				c3.89-0.58,7.75-1.68,11.65-1.91c7.66-0.46,10.2-4.74,6.28-11.52c-4.1-7.11-9.42-12.78-17.87-14.82c-2.67-0.65-5.24-2.09-7.6-3.58
				c-4.12-2.6-8.41-3.65-13.27-3.13C638.77,903.29,634.1,903.34,629.71,903.57z M163.29,1331.71c7.67-0.86,13.7-1.6,19.75-2.21
				c5.05-0.51,10.11-0.84,15.16-1.34c1.64-0.16,3.23-0.69,4.87-0.87c13.97-1.61,27.94-3.13,41.9-4.8c7.23-0.87,14.45-1.93,21.65-3.03
				c3.56-0.55,7.24-0.95,10.58-2.18c7.1-2.62,14.13-4.74,21.84-3.26c1.21,0.23,2.68-0.74,3.99-1.26c2.43-0.96,4.76-2.42,7.28-2.9
				c5.33-1.03,10.76-1.56,16.14-2.29c0.18-0.63,0.35-1.26,0.53-1.89c-5.81-4.99-11.01-11.09-17.58-14.7
				c-8.02-4.41-17.47-4.91-26.69-3.97c-2.7,0.27-5.33,1.94-7.99,1.91c-8.74-0.1-17.5-1.31-26.2-0.93
				c-11.2,0.48-22.35,2.08-32.21,3.06c1.79,0.86,5.09,2.46,8.39,4.06c-0.22,0.6-0.43,1.19-0.65,1.79c-12.6-8.39-27.25-5.8-40.94-8.33
				c-4.51-0.83-9.48,0.81-14.24,1.32c-0.01,0.62-0.01,1.23-0.02,1.85c6.25,1.67,12.79,2.67,18.68,5.19
				c5.95,2.55,11.26,6.62,16.85,10.02c-1.1,1.2-1.46,1.31-1.68,1.18c-1-0.58-2-1.19-2.95-1.86c-8.39-5.88-17.04-11.01-27.87-10.64
				c-1.28,0.04-2.8-0.97-3.87-1.89c-6.75-5.76-14.36-7.03-23.36-4.64c10.12,10.13,6.51,24.58,13.05,36.25
				c6.88-1.46,8.85-9.29,14.82-13.84C169.29,1318.59,166.48,1324.72,163.29,1331.71z M1.89,470.48c0.38,0.5,0.76,1,1.14,1.5
				c19.57-13.7,39.14-27.4,58.72-41.1c0.31,0.43,0.63,0.86,0.94,1.29c-2.13,3.73-3.91,7.73-6.47,11.13
				c-3.37,4.47-6.72,9.39-11.2,12.52c-13.15,9.18-26.89,17.49-40.26,26.36c-1.55,1.03-2.21,3.39-3.28,5.13
				c0.52,0.4,1.03,0.81,1.55,1.21c10.65-6.68,21.3-13.36,31.96-20.02c1.41-0.88,2.81-1.98,4.37-2.36
				c10.61-2.58,18.57-9.23,26.35-16.39c2.04-1.87,5.35-3.7,7.87-3.45c7.52,0.73,12.69-4.25,18.91-6.6c2.11-0.8,3.93-2.35,5.89-3.55
				c-6.85-3.15-12.47,2.93-18.79-2.87c18.22-7.31,26.68-24.28,40.19-36.08c-2.69,0.94-5.39,1.87-8.48,2.94
				c6.48-11.53,12.67-22.54,19.07-33.93c-4.01,3.42-7.03,7.54-11.03,9.09c-8.4,3.25-17.5,4.26-25.13,10.01
				c-4.75,3.58-10.96,5.12-16.15,8.21c-4.17,2.49-7.89,5.31-13.18,4.9c-2.02-0.16-4.28,0.72-6.19,1.64
				c-4.68,2.25-9.37,2.11-14.01,0.32c-13.26-5.12-26.95-9.28-38.76-17.61c-1.02-0.72-2.2-1.2-3.48-1.88c0,23.26,0,46.09,0,69.21
				c4.27-4.55,8-8.99,12.21-12.9c4.59-4.25,9.82-7,11.28-14.62c1.78-9.31,11.86-8.83,20.23-12.58c-1.88,7.87-0.04,12.48,8.61,12.51
				c-4.27,6.19-8,12.24-13.15,16.59c-11.67,9.87-24.05,18.9-36.13,28.3C4.24,468.37,3.09,469.45,1.89,470.48z M1142.31,623.26
				c5.66,3.6,7.64,8.87,4.65,14.87c-4.66,9.36-9.8,18.48-14.84,27.64c-0.86,1.56-2.27,2.81-3.42,4.21c-0.39-0.22-0.77-0.45-1.16-0.67
				c0.44-2.4,0.95-4.79,1.29-7.21c0.15-1.07,0.41-2.66-0.15-3.2c-4.47-4.31-2.4-8.85-0.68-13.35c0.94-2.47,2.93-4.8,3.09-7.27
				c0.55-8.57,0.51-17.18,0.69-25.67c-2.02,3.43-8.31,5.2-5.08,11.85c0.44,0.9,0.23,2.42-0.22,3.41
				c-2.79,6.13-7.11,10.95-13.04,15.23c-0.57-2.03-0.95-3.38-1.33-4.72c-1.95,1.26-3.35,2.9-3.53,4.66
				c-1.03,10.25-2.29,20.51-2.5,30.79c-0.45,22.62-0.32,45.25-0.41,67.87c0,1.21,0.15,2.42,0.25,3.95
				c8.18-6.88,15.89-13.4,23.66-19.85c1.39-1.16,2.89-2.45,4.56-2.98c9.68-3.08,16.85-9.85,24.08-16.42c1.68-1.53,3.01-4.4,3.03-6.67
				c0.19-31.62,0.14-63.24,0.11-94.85c0-1.15-0.25-2.3-0.44-3.91C1154.42,608.76,1148.36,616.01,1142.31,623.26z M955.86,389.61
				c-0.64,0.02-1.28,0.05-1.91,0.07c-0.17-1.53-0.42-3.06-0.49-4.6c-0.68-14.63-0.64-29.33-2.14-43.87
				c-3.12-30.27-1.15-60.54-1.14-90.81c0-6.64,0.97-13.27,1.12-19.92c0.27-11.94,0.73-23.92,0.19-35.84
				c-0.33-7.11-2.52-14.1-7.37-20.02c0.15,22.31,1.21,44.52,1.69,66.74c0.48,22.08,1.6,44.19,0.05,66.26
				c-0.49,0.03-0.99,0.06-1.48,0.09c-0.62-6.17-1.24-12.34-1.86-18.52c-0.13,0-0.26-0.01-0.4-0.01c-0.39,2.86-0.79,5.72-1.18,8.58
				c-1.49-1.06-2.19-2.08-2.25-3.14c-0.57-10.42-1.25-20.83-1.48-31.26c-0.28-12.96,0.14-25.94-0.31-38.9
				c-0.63-18.23-1.78-36.45-2.71-54.67c-0.07,1.69-1.12,3.62-1.24,5.61c-0.62,10.11-1.68,20.26-1.31,30.35
				c0.67,18.61,2.9,37.17,3.24,55.77c0.52,28.11,1.12,56.3-0.45,84.34c-1.64,29.32-1.85,58.52-0.23,87.79
				c0.53,9.61,1.24,19.21,1.87,28.82c1.32-12.46,1.87-24.91,1.99-37.36c0.31-33.29,0.37-66.58,0.72-99.86c0.04-4,1.31-7.99,2-11.99
				c0.44,0.08,0.89,0.15,1.33,0.23c0.06,1.29,0.22,2.58,0.16,3.86c-0.77,15.62-2.11,31.23-2.22,46.86
				c-0.15,21.63,0.76,43.27,0.96,64.91c0.16,17.32-0.31,34.64-0.03,51.95c0.12,7.52,1.45,15.01,7.63,22.15
				c-1.9-16.96-4.06-32.51-5.27-48.12c-1.21-15.64,0.12-31.28,1.59-46.89c0.96,1.62,0.88,3.22,0.97,4.81
				c1.28,21.08,2.34,42.19,3.98,63.24c0.83,10.73,2.52,21.41,4.36,32.02c0.25,1.42,3.6,3.05,5.62,3.2c1.86,0.13,3.92-1.49,5.76-2.55
				c2.67-1.53,5.24-3.26,8.1-5.06c-7.21-6.84-10.13-16.24-11.83-25.91c-1.35-7.66-1.99-15.54-2.09-23.33
				c-0.46-38.96-0.57-77.92-0.96-116.88c-0.12-11.93-0.73-23.85-1.11-35.78c-0.63,0.01-1.26,0.02-1.89,0.02
				C955.86,331.2,955.86,360.41,955.86,389.61z M404.29,1193.7c-3-3.42-5.73-6.31-8.2-9.41c-2.05-2.57-3.73-5.43-5.69-8.08
				c-4.5-6.1-8.23-13.07-13.75-18.04c-14.09-12.7-28.42-25.01-38.39-41.49c-0.84-1.39-2.14-2.56-3.39-3.63
				c-9.62-8.17-19.39-16.16-28.9-24.46c-4.22-3.69-7.97-7.92-11.9-11.94c-6.3-6.46-12.56-12.95-19.67-20.29
				c1.95,24.54,3.76,47.34,5.57,70.12c1.02,0.19,1.89,0.2,2.62,0.52c4.97,2.2,9.78,3.65,15.23,0.9c1.66-0.84,4.8-0.16,6.6,0.93
				c8.39,5.05,17.05,9.86,24.65,15.95c9.39,7.52,18.05,16,26.56,24.53c3.54,3.55,5.99,8.25,8.72,12.56c1.77,2.8,2.92,6.01,4.76,8.75
				c2.81,4.18,6.15,8,8.95,12.18c2.09,3.13,4.31,3.78,7.39,1.65c1.06-0.73,2.2-2.01,3.26-1.97
				C395.48,1202.72,399.28,1197.97,404.29,1193.7z M54.71,594.33c4.32-1.23,7.64-2.12,10.93-3.11c13.23-4,26.46-7.76,40.56-6.69
				c2.57,0.19,5.22-0.8,7.85-1.17c3.74-0.52,7.49-1.36,11.23-1.37c6.19-0.01,12.38,0.54,18.58,0.85c0.06-0.28,0.13-0.57,0.19-0.85
				c-7.34-3.64-14.68-7.29-22.02-10.93c-0.07-0.49-0.14-0.98-0.21-1.47c3.68-1.43,7.37-2.86,10.5-4.07
				c-3.22-1.83-9.13-3.25-10.09-6.25c-1.09-3.42,2.23-8.26,3.69-12.67c-0.94-0.17-2.7-0.12-4.1-0.81c-3.63-1.78-9.4-3.25-10.17-6
				c-1.85-6.61-6.36-10.19-10.86-14.27c-3.08-2.79-5.94-5.81-2.65-10.72c-0.58-0.27-1.17-0.55-1.75-0.82c-2.3,2.24-4.6,4.47-6.5,6.32
				c3.1,3.37,5.85,5.65,7.71,8.51c3.65,5.62,6.53,11.75,10.29,17.28c1.79,2.62,2.19,4.65,0.62,7.2c-2.7,4.39-6.03,8.51-7.98,13.2
				c-2.21,5.31-5.42,7.9-11.08,8.85c-4.17,0.71-8.07,3.03-12.09,4.63c-0.19-0.34-0.39-0.69-0.58-1.03c4.2-3.64,8.39-7.28,13.55-11.75
				c-3.6-0.25-6-0.42-8.5-0.6c-1.03-7.39,7.43-7.62,9.88-13.41c-2.82,1.24-4.92,2.16-6.9,3.03c0-5.01,0-9.63,0-14.38
				c-1.78,1.32-3.65,2.7-5.53,4.08c-0.24-0.2-0.49-0.4-0.73-0.61c1.16-2.89,2.32-5.78,3.9-9.72c-2.7,1.75-4.3,2.79-5.89,3.82
				c-0.44-0.28-0.89-0.55-1.33-0.83c1.44-2.78,2.74-5.64,4.35-8.31c1.73-2.86,5.17-5.04,3.12-9.17c-9,3.31-15.28,9.66-20.11,21.38
				c-2.12,5.14-5.16,9.03-9.75,12.15c-7.47,5.06-14.09,11.05-18.84,18.96c-4.92,8.19-11.83,14.31-20.58,18.27
				c-2.88,1.3-5.89,2.38-8.57,3.99c-1.36,0.82-2.12,2.62-3.15,3.97c0.29,0.54,0.58,1.07,0.86,1.61c24.29-2.03,46.79,4.3,68.54,14.08
				c0.39-0.44,0.77-0.87,1.16-1.31c-3.49-2.81-6.98-5.62-10.46-8.43c2.59-0.95,4.53-0.94,6.48-0.95c4.79-0.03,9.75,0.72,14.33-0.29
				c7.85-1.74,15.45-4.58,22.81-6.85c-5.85-2.59-12.47-3.79-19.64-3.45C75.85,594.69,65.89,594.33,54.71,594.33z M440.16,766.75
				c-0.27-0.44-0.54-0.88-0.81-1.32c1.8-1.61,3.43-3.47,5.42-4.78c14.44-9.51,25.31-23.55,40.7-31.88c4.95-2.68,9.43-6.23,14.42-8.81
				c6.64-3.44,7.59-5.19,5.04-12.55c-2.82,1.43-5.56,3.07-8.48,4.24c-4.11,1.65-8.35,2.94-12.53,4.39
				c-4.14,1.44-8.41,2.61-12.37,4.44c-4.17,1.93-8.04,4.53-12.05,6.81c-4.4,2.5-8.85,4.91-13.21,7.47
				c-3.72,2.18-7.32,4.58-11.05,6.76c-2.47,1.45-3.94,0.77-3.63-2.73c-0.41-0.2-0.82-0.39-1.23-0.59
				c-11.39,10.85-22.78,21.69-34.16,32.54c0.28,0.34,0.56,0.68,0.84,1.02c7.54-5.64,15.16-11.16,22.58-16.95
				c6.1-4.75,12.58-6,21-3.04c-2.76,3.65-5.09,6.95-7.65,10.06c-1.9,2.29-5.2,3.99-5.97,6.54c-2.24,7.38-7.58,11.92-13.02,16.57
				c-1.95,1.66-3.92,3.31-5.26,4.44c3.53,2.01,6.7,3.92,9.99,5.62c1.14,0.59,2.9,1.31,3.79,0.84c5.01-2.62,9.13-1.58,13.22,2.01
				c3.26,2.86,6.78,5.68,11.55,2.42c0.55-0.38,2.08,0.02,2.74,0.56c5.28,4.32,8.43,1.98,11.89-2.82c3.44-4.78,8.39-4.7,12.41-0.73
				c1.04,1.02,1.85,2.27,2.39,2.94c8.11-1.6,15.77-2.98,23.35-4.72c1.55-0.36,3.98-2.03,4.01-3.15c0.3-11.06,0.18-22.13,0.18-33.46
				c-27.36,11.34-32.74,12.41-38.87,7.75c5.29-4.29,10.56-8.56,15.83-12.84c-0.41-0.72-0.82-1.43-1.23-2.15
				C466.7,756.7,453.43,761.72,440.16,766.75z M1043.39,765.09c0.16-0.97,0.43-1.91,0.46-2.86c1.03-27.28,2.23-54.55,2.95-81.83
				c0.34-12.62-0.67-25.28-0.31-37.9c0.5-17.95,1.85-35.87,2.53-53.82c0.49-12.79,0.43-25.61,0.7-38.41
				c0.07-3.32,0.45-6.64,0.73-10.56c-8.03,5.8-8.53,5.8-12.8,0.28c1.25-1.63,2.55-3.31,3.78-4.92c-0.19-0.47-0.25-0.82-0.44-1.06
				c-0.42-0.52-0.82-1.1-1.37-1.44c-5.63-3.58-7.39-8.21-5.35-14.86c0.76-2.47-0.89-5.69-1.45-8.57c-2.84,7.86-4.88,15.51-6.88,23.16
				c-0.11,0.43,0.23,0.97,0.19,1.44c-0.22,2.64-0.21,5.33-0.81,7.88c-0.93,3.95-3.61,7.92-3.25,11.66c1.97,20.81,5.5,41.5,6.8,62.34
				c1.3,20.72,0.82,41.56,0.7,62.34c-0.15,26.18,2.8,52.1,5.42,78.07C1035.59,761.79,1037.97,764.27,1043.39,765.09z
				M1161.22,1027.56c-1.96,2.47-2.91,4.03-4.2,5.23c-4.7,4.38-9.14,9.19-14.4,12.77c-4.88,3.33-10.62,5.44-16.09,7.85
				c-19.67,8.7-39.63,15.49-61.07,8.23c-0.81,4.07-1.98,8.05-2.3,12.1c-0.34,4.27,0.57,8.64,0.13,12.89
				c-0.64,6.17-2.12,12.25-2.95,18.42c-0.21,1.57,0.23,3.66,1.19,4.87c4.83,6.12,9.66,12.29,15.07,17.88
				c2.39,2.47,6.13,3.65,9.29,5.36c3.43,1.85,7.41,3.05,10.26,5.54c5.83,5.1,12.03,8.5,19.93,9.12c3.57,0.28,7.05,2.13,10.51,3.41
				c2.19,0.81,4.29,1.87,6.43,2.82c0.44-0.68,0.87-1.35,1.31-2.03c-8.47-6.93-16.2-15.22-25.64-20.41
				c-9.25-5.09-15.12-11.76-19.09-20.99c-3.23-7.51-3.15-8.6,2.42-13.96c-5.71-7.25-11.41-14.49-17.18-21.82
				c10-6.05,11.37-4.85,17.24,0.55c2.69,2.48,5.19,5.28,8.22,7.22c2.84,1.82,6.33,2.62,9.45,4.05c2.06,0.95,4.52,1.79,5.86,3.45
				c4.26,5.26,8.95,9.17,16.18,9.4c3.2,0.1,5.12-0.64,6.93-3.39c1.95-2.98,1.07-5.06-0.52-7.63c-3.23-5.23-6.26-10.58-9.26-15.95
				c-1.07-1.92-1.84-4.01-2.92-6.42c4.29,0.6,7.69,1.59,11.02,1.43c7.7-0.38,13.48,2.55,17.91,8.69c1.76,2.45,3.73,4.75,6.24,7.92
				C1161.22,1064.91,1161.22,1046.97,1161.22,1027.56z M341.18,1432.43c0.6-0.08,1.19-0.17,1.79-0.25
				c1.03-6.02,2.11-12.03,3.03-18.06c0.09-0.58-0.81-1.28-1.18-1.96c-1.49-2.75-2.94-5.53-4.45-8.28c-0.59-1.08-1.26-2.11-1.94-3.14
				c-0.97-1.45-2.31-2.74-2.9-4.33c-1.46-3.91-5.11-5.66-8.98-3.98c-0.87,0.37-2.03,1.2-2.62,0.91c-6.82-3.31-12.52,0.16-17.91,3.3
				c-7.82,4.57-8.05,4.72-14.35-2c-1.24-1.33-3.61-2.74-5.15-2.45c-6.55,1.22-6.47,1.44-8.29-4.93c-0.23-0.82-0.99-1.89-1.7-2.07
				c-0.75-0.18-2.52,0.62-2.5,0.89c0.27,3.73-2.3,5.96-3.85,8.88c-2.9,5.44-2.56,5.73-8.49,3.49c-0.99-0.38-3.68,0.77-3.77,1.44
				c-0.63,5.04-3.79,4.65-7.63,4.66c-4.76,0-9.89,0.19-14.19,1.94c-4.78,1.94-9.03,3.59-14.28,2.23c-5.21-1.35-9.98,2.18-11.34,7.33
				c-0.33,1.23-1.63,2.61-2.83,3.14c-4.63,2.02-9.12,4.12-11.18,9.18c-1.12,2.74-2.43,5.5-2.91,8.38c-0.26,1.58,0.53,4.12,1.73,4.95
				c1.45,1,4.05,1.25,5.75,0.67c1.32-0.45,2.82-2.66,2.86-4.14c0.13-4.42,3.14-7.45,7.63-6.66c3.39,0.6,6.73,1.55,10.12,2.35
				c3.62-6.6,9.47-6.44,17.63,0.1c7.42-1.7,14.33-3.27,21.24-4.85c0.07,0.39,0.13,0.78,0.2,1.16c1-1.18,2-2.36,3.12-3.69
				c1.49,1.32,2.56,2.26,3.62,3.2c-0.69-0.83-1.38-1.66-2.07-2.49c1.72-0.84,3.44-1.68,5.65-2.77c-1.38-4.14-0.86-4.9,4.54-4.82
				c0.33,1.64,0.66,3.32,1.11,5.58c1.56-1.29,2.59-2.15,3.58-2.96c1.28,1.05,2.27,1.92,3.32,2.7c1.02,0.76,2.11,1.43,2.59,1.74
				c2.33-1.47,4.83-2.3,6.11-4.04c1.61-2.19,3.36-4.82,5.66-2.91c2.01,1.67,4.17,1.74,6.09,2.8c0.97,0.54,1.06,2.48,1.95,3.4
				c1.33,1.37,3.02,2.4,5.65,4.41c1.13-0.15,3.72-0.58,6.34-0.8c1.24-0.1,3.19-0.25,3.66,0.43c3.42,4.99,7.17,2.99,10.72,0.59
				C335.85,1428.37,338.78,1427.77,341.18,1432.43z M893.6,455.48c1.39,2.21,2.31,3.92,3.47,5.45c2.1,2.79,4.72,5.26,6.38,8.27
				c0.91,1.65,0.75,4.28,0.15,6.17c-0.19,0.6-3.38,0.46-5.16,0.28c-1.22-0.13-2.38-0.92-3.56-1.42c-0.33,0.22-0.66,0.44-0.99,0.65
				c2.39,5.1,4.78,10.19,7.15,15.25c-4.03,3.2-7.64,2.32-11.33,0.11c-4.04-2.42-10.32-1.75-13.56,1.49
				c-0.87,0.87-2.08,2.45-1.81,3.25c2.82,8.26-3.13,11.64-7.96,16.28c-3.52,3.39-6.93,8.11-7.82,12.74c-1.35,7-0.61,14.43-0.47,21.67
				c0.06,3.34,0.91,6.17,5,7.46c8.08,2.55,15.79,5.92,21.5,12.75c1.71,2.04,4.17,4.03,7.26,0.95c-1.12-2.53-2.17-4.95-3.25-7.35
				c-0.98-2.16-2.89-4.01-0.38-6.66c2.52-2.66,4.62-1.41,7.11-0.34c5.91,2.54,11.89,4.92,18.35,4.16c0.96-2.26,1.43-4.3,2.56-5.85
				c3.44-4.7,3.64-9.4,0.55-14.24c-7.64-11.97-4.1-27.02-10.84-39.23c-0.13-0.24,0.04-0.64,0.07-0.97c0.18-2.72,0.35-5.44,0.55-8.51
				c7.02-5.01,15.34-14.11,18.88-20.8c4.25-8.02,0.66-17.66-7.32-21.34c-3.97-1.83-7.5-4.75-10.93-7.54
				c-4.29-3.49-8.27-7.35-12.39-11.05c-0.48,0.45-0.97,0.9-1.45,1.34c1.68,2.02,3.33,4.07,5.05,6.06c2.05,2.37,4.53,4.46,6.14,7.09
				c2.67,4.38,5.02,9,7.05,13.71c0.8,1.84,0.38,4.21,0.5,6.08C904.52,461.83,901.2,453.99,893.6,455.48z M1103.11,895.34
				c3.86-3.08,6.85-5.19,9.52-7.66c17.9-16.58,26.76-38.52,35.36-60.53c1.98-5.07,2.86-10.56,4.36-15.83
				c2.96-10.38,7.23-20.57,8.67-31.15c1.51-11.12,0.44-22.61,0.26-33.93c-0.02-1.38-1.56-2.73-2.95-5.01
				c-0.63,2.83-0.6,4.3-1.26,5.35c-2.36,3.78-4.9,7.45-7.49,11.08c-0.65,0.9-1.68,1.94-2.67,2.11c-5.73,0.96-9.12,5.09-12.7,8.98
				c-1.88,2.04-4.9,4.01-5.18,6.29c-0.32,2.6,1.8,5.52,2.91,8.28c0.31,0.77,0.73,1.5,1.14,2.22c1.72,2.99,0.52,4.31-2.57,4.6
				c-3.31,0.31-6.93-0.11-9.84,1.13c-4.15,1.76-7.38,0.64-10.81-1.24c-1.54-0.84-3.09-1.69-5.13-2.81c-0.36,5.34-0.49,10-1.04,14.61
				c-0.43,3.58,1.27,5.08,4.16,6.81c5.48,3.27,10.72,7.03,15.67,11.07c2.86,2.33,4.77,5.78,7.45,8.39c4.36,4.24,4.4,5.26,1.17,10.65
				c-2.92,4.87-5.91,1.82-8.83,0.48c-2.85-1.31-5.38-3.61-8.34-4.34c-3.8-0.94-7.89-0.76-11.83-1.07
				C1103.11,853.18,1103.11,873.59,1103.11,895.34z M282.84,1192.63c2.35,2.06,3.98,3.25,5.29,4.72c0.75,0.84,1.43,2.2,1.34,3.25
				c-0.52,6.12-0.01,6.83,5.92,7.51c0.99-2.06,1.91-3.98,3.28-6.84c3.03,6.94,5.38,8.02,9.76,5.41c-1.43-2.58-3.03-5.1-4.2-7.8
				c-0.42-0.96-0.16-2.64,0.46-3.49c0.52-0.71,2.26-1.2,3.05-0.86c0.91,0.4,1.51,1.76,2.01,2.82c0.8,1.71,1.42,3.51,2.57,6.44
				c3.83-6.11,6.33-4.67,8.94-0.68c1.03,1.58,2.99,2.56,4.69,3.94c2.54-5.26,2.53-5.22,7.31-4.55c2.95,0.42,5.29-0.13,7.23-2.98
				c3.3-4.85,4.42-4.77,8.98-1c0.58,0.48,1.36,0.71,2.03,1.06c1.14-2.96,2.17-5.63,2.76-7.17c-1.49-3.13-3.83-5.74-3.71-8.25
				c0.22-4.71-0.62-6.09-5.46-4.75c-1.5,0.42-4.33-1.05-5.34-2.5c-1.26-1.82-1.28-4.51-1.83-6.82c-0.51,0.48-1.01,0.96-1.52,1.44
				c-2.94-0.35-6.54,0.21-8.62-1.31c-2.01-1.48-2.45-5.1-3.58-7.76c-4.9,2.67-4.81,2.59-9.06-2.41c-0.84-0.99-2.76-1.49-4.17-1.48
				c-2.39,0.01-4.77,0.6-7.31,0.97c-0.64,1.27-1.39,2.76-2.88,5.74c-1.67-3.84-2.59-6.24-3.74-8.53c-1.47-2.92-3.4-4.78-7.16-3.3
				c-4.51,1.78-9.18,3.18-14.83,5.1c-0.63,2.44-1.3,5.95-2.52,9.27c-0.38,1.04-2.17,2.04-3.42,2.2c-4.27,0.57-5.63-1.27-6.19-7.8
				c-0.45-0.09-0.93-0.27-1.41-0.27c-4.12-0.04-8.42,0.69-12.29-0.28c-3.52-0.88-4.76-0.26-4.77,3.02c-0.02,4.33-2.5,5.41-6.19,5.15
				c-1.28-0.09-2.55-0.27-3.52-0.37c-2.5,4.63-4.99,8.83-7.07,13.22c-1.46,3.08,0.78,6.72,3.98,7.5c1.17,0.28,2.29,1.06,3.27,1.83
				c2.25,1.76,4.39,3.65,6.58,5.49c-0.37-0.5-0.74-1.01-1.12-1.51c1.82-1.07,3.53-2.43,5.48-3.14c1.86-0.68,3.93-0.98,4.16,2.26
				c0.34,4.69,2.27,5.52,7.37,3.31c-1.98-4.35-0.48-6.29,4.27-6.94c2.19-0.3,3.99-2.77,6.21-3.61c2-0.75,4.34-0.62,6.53-0.87
				c-0.17,1.47-0.27,2.96-0.54,4.41c-0.21,1.12-0.63,2.2-1.03,3.57c1.63,0.95,3.15,1.82,3.86,2.24
				C279.37,1195.23,281.45,1193.67,282.84,1192.63z M2.05,354.99c0,6.41,0.21,11.87-0.07,17.31c-0.19,3.6,1.14,5.71,4.07,7.73
				c12.29,8.48,26.21,13.29,40.09,18.15c2.41,0.84,5.43,1.36,7.79,0.68c10.01-2.89,20.83-3.66,28.98-11.63
				c2.3-2.25,6.24-2.81,9.44-4.13c1.2-0.5,2.66-0.67,3.58-1.47c4.85-4.22,10.61-5.99,16.82-6.81c4.46-0.59,7.99-2.66,10.74-6.46
				c5.57-7.73,12.39-13.82,22.4-15.09c0.79-0.1,1.51-0.82,2.26-1.26c-0.11-0.39-0.23-0.78-0.34-1.18c-5.96,1.12-11.9,2.3-17.87,3.33
				c-4.7,0.81-9.42,2.03-14.14,2.05c-9.96,0.04-19.92-0.76-29.89-0.87c-6.29-0.07-12.81,1.66-18.85,0.54
				C45.61,351.92,24.26,357,2.05,354.99z M706.63,924.32c-9.29-12.56-18.05-24.04-26.31-35.86c-2.09-2.99-4.04-4.85-7.66-5.23
				c-5.11-0.54-10.18-1.46-15.28-2.08c-0.82-0.1-2.13,0.29-2.49,0.89c-0.35,0.57-0.07,2.07,0.48,2.54c2.1,1.85,4.26,3.74,6.69,5.09
				c5.02,2.77,9.04,6.22,12.41,11.1c3.83,5.53,8.77,10.44,13.84,14.93c7.96,7.06,16.46,13.45,22.59,22.39
				c1.93,2.82,4.4,5.97,7.36,7.27c11.51,5.05,22.57,11.45,35.44,12.86c2.03,0.22,4.27,1.11,5.86,2.38
				c9.7,7.83,19.25,15.84,28.82,23.82c2.59,2.16,5.09,4.42,8.24,7.17c-1.86-5.66-3.72-10.27-4.82-15.04
				c-0.88-3.82-2.92-6.33-5.76-8.85c-3.43-3.04-6.73-6.48-9.18-10.32c-2.99-4.67-1.79-7.73,2.75-10.98c1.72-1.23,4.14-2.56,4.62-4.29
				c1.86-6.73,5.64-11.86,11.85-15.52c-3.63-2.8-6.41-3.62-9.51-0.08c-0.94,1.07-3.24,1.35-4.89,1.3c-3.3-0.09-6.71-0.24-9.85-1.13
				c-3.73-1.06-7.77-2.25-10.73-4.58c-5.47-4.31-5.21-7.85-0.12-12.53c1.78-1.63,3.07-3.21,0.7-5.34c-3.66-3.28-8.48-2.9-11.45,0.91
				c-5.05,6.49-9.34,8.15-16.93,5.27c-6.81-2.59-12.71-0.66-18.3,2.67c-1.98,1.18-2.96,4.05-4.43,6.11
				C709.6,920.49,708.59,921.75,706.63,924.32z M902.78,475.17c-0.66-9.16-10.13-12.46-10.64-20.45c3.29-2.33,6.12-2.56,9.41,0.27
				c2.4,2.06,5.55,3.23,9.06,5.18c-0.13-1.81,0.1-2.78-0.28-3.34c-5.12-7.63-7.17-17.23-15.27-23c-3.06-2.18-5.06-5.5-1.66-9.62
				c11.41,6.39,18.16,19.51,33.55,22.54c-1.92-2.44-3.06-4-4.31-5.48c-3.59-4.26-7.97-8.08-10.59-12.87
				c-1.94-3.54-4.03-6.04-7.36-8.19c-7.06-4.57-13.78-9.68-20.7-14.47c-1.59-1.1-3.46-2.56-5.19-2.54c-8.3,0.07-16.6,0.59-26.03,1
				c8.87,13.42,10.93,26.23,3.95,39.9c-5.12-7.12-9.68-14.39-13.94-21.83c-3.13-5.47-4.51-11.03,1.74-15.56
				c-7.19-5.55-15.21-4.92-24.67-5.09c5.87,3.46,7.01,7.4,5.13,12.43c-2.03,5.42-3.82,10.94-5.59,16.45
				c-0.37,1.14-0.62,2.63-0.19,3.65c1.67,3.89,3.63,7.65,5.55,11.59c7.41-10.29,1.89-22.28,4.14-33.16
				c3.04,12.38,5.67,24.66,8.07,36.99c1.17,6.01,1.7,12.15,2.94,18.14c0.77,3.77,3.26,5.23,7.1,3.38c4.6-2.22,9.28-4.28,13.87-6.53
				c0.79-0.39,1.61-1.27,1.88-2.1c1.77-5.34,6.48-9.96,4.06-16.36c-0.2-0.54-0.1-1.54,0.28-1.91c3.74-3.65,2.36-8.38,2.78-12.71
				c0.45-4.6,0.86-9.2,1.28-13.8c0.48-0.03,0.95-0.05,1.43-0.08c0.64,1.8,1.56,3.55,1.87,5.4c1.25,7.43,3.29,14.4,7.79,20.74
				c2.05,2.9,2.39,7.01,3.72,11.27c-2.49-0.25-4.14-0.41-6.14-0.61c0.7,3.07,1.26,5.82,1.95,8.54c1.28,5.04,2.18,9.84-0.63,14.93
				c-1.36,2.46-0.94,5.91-1.15,7.48c4.51,1.43,7.87,2.59,11.29,3.55c2.23,0.63,4.55,0.98,7.86,1.67c-7.55-15.1-7.55-15.1-6.23-19.4
				C895.88,472.36,898.84,473.57,902.78,475.17z M46.46,1159.18c-0.45-0.5-0.9-1-1.35-1.5c-3.18,2.31-8.1,3.99-9.19,7.05
				c-2.45,6.86-7.44,8.33-13.25,8.89c-7.29,0.71-13.31,3.86-18.71,8.5c-0.82,0.7-1.77,1.86-1.78,2.81
				c-0.12,10.87-0.08,21.75-0.08,32.07c9.19-1.5,17.9-2.57,26.44-4.42c5.91-1.28,13.35,0.36,16.56-7.48
				c0.41-1.01,2.84-1.39,4.41-1.69c12.52-2.38,24.61-0.55,36.69,3.17c5.84,1.8,10.7,4.62,14.38,9.25c3.08,3.87,5.46,8.29,8.32,12.35
				c0.7,0.99,1.98,1.82,3.15,2.18c1.74,0.53,3.63,0.57,5.33,0.8c0-2.79-0.46-5.9,0.14-8.79c0.56-2.67,2.18-5.14,3.46-7.64
				c1.78-3.49,0.59-5.42-2.85-6.96c-7.25-3.24-8.03-4.99-6.49-13.23c0.82-4.41-0.59-7.54-4.86-9.08
				c-5.42-1.95-11.23-3.23-13.86-10.28c-4.56,5.63-9.7,9.21-9.37,16.67c0.29,6.53-1.39,7.71-7.77,6.79
				c-5.37-0.77-10.69-2.03-15.96-3.38c-1.2-0.31-2.54-1.73-2.94-2.93c-0.23-0.68,1.15-2.17,2.11-2.91c3.79-2.94,8.09-5.45,7.9-11.99
				c-6.49-0.94-13.18-1.92-20.32-2.95C53.07,1169.48,42.65,1164.63,46.46,1159.18z M957.06,845.23c-0.62-5,0.06-9.28-1.75-11.73
				c-2.52-3.42-7.4-5.01-10.37-8.21c-6.94-7.51-14.82-14.72-19.62-23.53c-6.78-12.44-11.18-26.19-16.48-39.42
				c-0.98-2.45-1.6-5.08-2.04-7.69c-2.29-13.59-4.06-27.29-6.77-40.79c-7.49-37.29-15.43-74.49-23.02-111.76
				c-2.23-10.96-9.44-19.28-14.21-28.88c-2.96-5.97-8.09-7.97-13.51-9.98c-2.9-1.07-3.49,0.7-3.18,3.16
				c0.62,4.95,1.6,9.89,1.71,14.85c0.11,4.91,2.74,7.86,5.9,10.73c5.86-3.31,6.84-3.28,9.53,2.79c2.13,4.81,3.68,9.98,4.71,15.15
				c4.16,20.84,7.66,41.81,12.09,62.59c3.25,15.24,7.66,30.24,11.33,45.4c1.15,4.76,1.61,9.68,2.39,14.53
				c-0.58,0.12-1.16,0.24-1.75,0.35c-4.48-13.75-8.96-27.51-13.57-41.65c-0.21,0.79-0.41,1.13-0.35,1.42
				c4.97,23.03,9.76,46.11,15.06,69.07c1.66,7.19,4.69,14.06,7.1,21.08c0.55-0.18,1.1-0.36,1.65-0.55
				c-0.02-14.42-8.92-26.88-9.67-41.21c1.57,1.64,2.92,3.38,3.58,5.34c2.49,7.37,5.26,14.7,7,22.26
				c6.64,28.81,21.22,52.45,44.73,70.46C950.15,840.96,953,842.57,957.06,845.23z M66.31,529.15c-2.78-1.44-7.13-1.56-4.67-7.02
				c2.53-5.64-1.17-10.39-7.5-10.48c-7.62-0.11-7.62-0.11-8.57-7.7c-0.02-0.19-0.49-0.33-0.89-0.58
				c-8.66,8.37-17.34,16.76-26.16,25.28c-3.81-1.83-3.88-4.36-0.99-7.53c3.08-3.37,6.04-6.85,9.7-11.01
				c-9.33,4.63-16.05,10.8-22.1,17.94c-2.19,2.59-3.19,5.02-3.15,8.41c0.17,14.8,0.07,29.61,0.07,44.41c0,4.08,0,8.17,0,13.02
				c10.91-4.6,21.21-8.55,27.39-18.48c6.45-10.37,15.14-18.4,25.03-25.34c1.55-1.09,2.9-2.75,3.79-4.44
				C61.01,540.37,63.5,534.95,66.31,529.15z M382.54,1298.98c-0.42-7.83-0.77-14.22-1.11-20.62c-0.02-0.33-0.13-0.73,0.01-0.99
				c1.96-3.64-0.15-6.19-2.14-8.95c-1.73-2.41-2.91-5.22-4.58-7.7c-1.28-1.89-2.71-3.81-4.47-5.22c-2.95-2.37-6.27-4.27-9.33-6.51
				c-4.64-3.4-9.1-7.08-13.84-10.34c-2.03-1.4-4.85-1.67-6.83-3.1c-3.74-2.71-6.89-6.26-10.73-8.8c-4.76-3.15-9.96-5.63-15.5-8.69
				c-0.91,3.38-0.95,6.29-2.4,7.95c-1.08,1.24-4,0.89-6.1,1.24c-1.2,0.16-2.26,1.53-2.63,3.07c-0.99,4.11-1.51,8.34-2.55,12.43
				c-0.85,3.36,0.38,4.68,3.56,4.76c2.88-5.34,2.87-5.22,8.71-5.1c3.72,0.08,6.85,0.47,7.85,4.97c0.28,1.26,1.77,2.34,2.87,3.31
				c2.15,1.89,4.42,3.66,6.65,5.46c0.89,0.72,1.71,1.78,2.72,2.03c7.49,1.88,11.76,7.72,16.58,13c3.08,3.37,6.58,6.34,9.87,9.52
				c1.16,1.12,2.83,2.14,3.25,3.51c1.29,4.29,2.1,8.72,3.16,13.41c-0.67-0.03-2.27,0.35-2.73-0.2c-3.56-4.29-11.63-1.91-13.3-9.05
				c-0.13-0.55-2.07-0.63-3.15-0.99c-3.61-1.19-8.83-1.34-10.49-3.85c-3.42-5.17-7.47-4.18-11.96-3.79c-1.49,0.13-2.99,0-4.49,0.02
				c-3.51,0.04-6.08-0.92-6.87-4.96c-0.23-1.19-1.99-2.81-3.17-2.91c-1.68-0.15-3.65,0.61-5.16,1.54c-1.14,0.7-1.75,2.25-2.59,3.43
				c2.63,1.96,5.5,3.5,7.57,5.77c6.16,6.73,11.94,13.82,18.06,20.58c1.08,1.2,3.16,2.18,4.74,2.12c6.03-0.23,12.03-0.97,18.05-1.42
				c2.77-0.2,5.87,0.61,8.28-0.36C365.59,1300.61,372.46,1296.72,382.54,1298.98z M786.68,841.81c-0.08,0.43-0.15,0.85-0.23,1.28
				c3.69,1.19,7.55,2.03,10.98,3.73c1.72,0.85,2.65,3.28,3.93,5c-1.65,1.01-3.19,2.48-4.98,2.91c-1.66,0.39-3.59-0.31-5.39-0.57
				c-5.33-0.79-10.26-0.97-15.28,2.35c-2.38,1.57-7.02,1.64-9.74,0.41c-6.76-3.06-13.11-7.09-19.44-11.03
				c-6.83-4.25-13.01-9.86-21.98-9.45c6.09-4.98,13.52-2.71,20.05-3.66c1.04-4-0.76-4.74-3.84-4.94c-12.93-0.83-25.84-2-38.77-2.75
				c-4.34-0.25-6.47-2.65-8.38-6.16c1.55-1.47,2.98-2.83,5.44-5.17c-7.38,0.43-13.23,0.78-20,1.18c2.88,1.86,4.89,3.15,7.4,4.77
				c-2.37,2.03-4.2,3.6-6.62,5.67c3.12,1.94,5.7,4.15,8.68,5.27c6.98,2.62,14.14,4.74,21.24,7.03c4.25,1.37,8.45,2.5,11.13,6.82
				c1.04,1.67,3.76,2.89,5.87,3.17c7.63,1.02,13.71,4.66,19.17,9.82c4.35,4.11,8.51,8.64,13.5,11.81c6.99,4.46,11.08,9.8,9.87,18.36
				c-0.03,0.24,0.33,0.54,0.47,0.74c3.43,0.65,6.8,1.29,10.12,1.92c5.72-9.74,6.25-10.51,14.02-8.23c5.8,1.7,9.4,0.13,11.83-4.74
				c2.58-5.18,6.61-5.32,11.3-4.1c1.39,0.36,2.76,0.79,4.51,1.29c-0.05-1.04-0.02-1.68-0.13-2.28c-2.58-14.38-4.97-28.8-7.99-43.08
				c-0.48-2.27-3.54-4.62-5.95-5.62c-1.61-0.67-4.18,0.98-6.94,1.78c1.53,1.83,2.65,2.59,2.91,3.58c0.86,3.28,2.27,6.81,1.68,9.9
				c-0.27,1.41-4.66,2.51-7.31,2.86C794.17,842.17,790.4,841.81,786.68,841.81z M231.81,1232.35c0-3.59,0.58-6.92-0.17-9.92
				c-0.73-2.91-2.82-5.48-4.31-8.19c-0.92-2.04-1.77-3.99-2.86-5.8c-0.61-1.01-1.45-2.13-2.46-2.6c-5.01-2.32-9.34-0.52-13.18,2.85
				c-3.6-3.25-6.61-7.57-11.96-3.48c-0.36,0.28-1.31,0.1-1.83-0.17c-3.54-1.85-6.75-2.61-9.68,1.3c-0.57,0.76-2.72,0.48-4.13,0.43
				c-1.47-0.05-2.99-0.75-4.4-0.55c-6.41,0.89-12.79,1.99-19.19,3.01c-0.43-0.97-0.66-0.74-0.86-0.48c-4.4,5.88-4.39,5.87-9.96,1.51
				c-1.99-1.56-3.38-1.4-4.61,1.18c-0.67,1.4-2.35,2.4-3.71,3.38c-4.88,3.51-11.39,5.16-13.81,12.21c6.46,1.86,3.98,6.4,3.37,10.47
				c-0.2,1.3-0.12,2.64-0.17,3.96c1.5-0.05,3.01-0.03,4.51-0.18c0.87-0.09,1.71-0.44,1.8-0.46c0.87,2.02,1.13,4.28,2.22,4.81
				c1.98,0.96,4.44,1.02,6.72,1.18c1.04,0.07,2.4-1.09,3.14-0.72c4.01,1.98,9.42-0.86,12.71,3.72c0.24,0.33,2.06-0.67,3.15-0.72
				c2-0.09,4.9-0.76,5.87,0.27c3.43,3.67,5.34,4.27,8.1,0.35c2.87-4.07,5.19-3.96,8.07-0.32c0.47,0.59,1.3,0.9,2.24,1.54
				c2.12-2.27,3.96-4.61,6.17-6.51c1.89-1.63,4.02-3.22,5.48,0.95c0.93,2.66,2.87,3.63,4.7,0.21c2.08-3.88,4.13-3.8,7.08-0.39
				c3.48,4.02,6.87,2.55,9.36-1.97c2.22-4.03,5.98-7.23,9.19-10.68C228.82,1232.12,230,1232.43,231.81,1232.35z M254.36,376.15
				c1.65,7.38,7.26,8.35,13.3,8.93c1.36,0.13,3.34,1.44,3.74,2.64c1.61,4.84,3.43,9.84,0.79,14.92c-1.34,2.58-0.52,4.33,1.58,5.97
				c1.17,0.91,1.94,2.37,3.17,3.14c1.57,0.98,3.92,2.56,5.06,2.03c2.45-1.15,6.03-1.41,5.78-6.09c-0.31-5.85,0.72-11.76,0.85-17.65
				c0.05-1.98-0.87-3.99-1.5-6.61c1.64-2.89,3.91-6.54,5.81-10.38c1.5-3.02,0.55-5.99-2.73-6.7c-6.11-1.33-8.85-3.86-10.88-9.36
				c-1.3-3.54-3.73-6.84-6.26-9.72c-3.65-4.16-7.84-7.84-12.52-12.44c-1.8,1.96-4.4,4.3-6.39,7.08c-6.56,9.15-13.13,11.47-23.91,8.08
				c-1.26-0.4-2.55-0.84-3.85-0.93c-3.22-0.22-4.42,1.21-2.89,4.25c0.59,1.17,1.43,2.24,2.27,3.25c7.95,9.51,7.78,14.24,0.4,23.72
				c-3.39,4.35-7.21,9.38-7.94,14.51c-0.8,5.59-1.78,9.77-6.85,12.62c-0.86,0.48-0.83,2.54-1.14,3.61
				c10.7-0.67,13.92-7.1,14.53-15.74c0.08-1.14,0.47-2.28,0.87-3.37c2.32-6.4,7.52-10.18,12.19-14.46c-1.24,6.23-2.37,12-3.53,17.77
				c-1.17,5.78-6.66,10.07-6.45,17.31c1.75-0.8,2.7-1.05,3.43-1.6c6.87-5.09,12.24-11.06,13.21-20.17
				C245.15,384.63,247.49,379.22,254.36,376.15z M908.61,490.63c-0.9,4.29,0.19,9.16,3.06,13.77c0.76,1.22,0.25,3.24,0.29,4.9
				c0.17,8.58,1.07,16.82,7.2,23.72c2.78,3.14,6.13,7,6.48,10.78c0.47,5.22,2.48,9.01,5.45,12.81c0.8,1.02,1.47,2.26,1.8,3.5
				c1.61,5.93-0.76,8.65-6.65,7.31c-3.1-0.71-4.5,0.36-4.8,3.16c-0.35,3.13-0.45,6.29-0.56,9.44c-0.2,5.97,0.64,12.17-0.7,17.87
				c-2.75,11.76-6.69,23.24-9.98,34.88c-0.46,1.64-0.06,3.52-0.06,6.35c2.33-5.08,4.08-8.64,5.61-12.3
				c1.41-3.35,2.44-6.85,3.89-10.18c2.35-5.39,4.89-10.69,7.37-16.03c0.54-1.17,1.18-2.3,1.69-3.49c1.61-3.71,2.69-7.76,4.89-11.07
				c3.02-4.54,6.85-8.54,10.37-12.75c0.85-1.01,1.81-1.93,2.71-2.9c0.34,0.22,0.69,0.43,1.03,0.65c-1.58,4.74-3.16,9.48-4.84,14.55
				c7.49-3.72,9.82-7.44,9.86-14.7c0.01-2.64,0.39-5.29,0.72-7.91c0.72-5.75,1.48-11.5,2.29-17.25c0.51-3.62-0.86-5.68-4.32-7.21
				c-9.76-4.3-15.8-11.86-18.06-22.34c-0.94-4.38-2.1-8.74-2.66-13.17c-1.36-10.78-2.43-21.59-3.58-32.08
				C921.24,477.17,915.29,483.52,908.61,490.63z M1047.55,462.19c-3.51,0.7-6.62,0.51-8.68,1.9c-5.46,3.67-10.46,8.03-15.65,12.09
				c-1.41,1.1-2.73,2.53-4.34,3.11c-13.18,4.71-21.39,14.89-29.94,26.15c4.06,3.3,7.22,7.81,11.2,8.72
				c9.22,2.12,13.27,7.94,15.48,16.19c1.48,5.53,3.15,11.01,4.74,16.51c1.72-4.6,7.02-7.99,3.92-13.64
				c-0.36-0.65,0.31-1.88,0.54-2.83c1.34-5.63,2.56-11.3,4.07-16.89c0.72-2.67,1.99-5.19,3.01-7.78c0.67,0.09,1.34,0.18,2.01,0.27
				c0.75,3.79,3.03,8.1,1.95,11.26c-2.78,8.14,2.05,11.94,7.03,16.15c0.34,0.29,0.47,0.82,0.78,1.41c-1.28,1.69-2.56,3.37-4.53,5.97
				c2.26,0.34,4.34,1.33,5.28,0.67c2.67-1.86,6.27-3.02,6.05-7.63c-0.76-16.1-1.21-32.21-1.82-48.32
				C1048.36,478.22,1047.97,470.94,1047.55,462.19z M886.56,837.93c0.4,3.14,0.43,5.63,1.07,7.94c2.7,9.76,5.84,19.4,8.35,29.21
				c4.52,17.63,10.6,34.63,19.07,50.75c4.49,8.53,9.66,16.79,13.18,25.7c2.73,6.91,6.96,12.02,12.62,16.2
				c1.73,1.28,5.23,2.65,6.27,1.85c1.85-1.42,3.23-4.42,3.42-6.87c0.69-8.94-0.05-18.07,1.36-26.86c1.55-9.66-1.87-16.78-8.05-23.34
				c-7.8-8.29-15.09-17.16-23.57-24.68c-10.87-9.64-20.23-20.05-25.75-33.68C892.39,848.84,889.49,843.83,886.56,837.93z
				M695.5,820.78c3.23,1.04,5.95,2.45,8.78,2.71c7.1,0.67,14.26,0.76,21.38,1.21c5.8,0.37,11.6,0.8,17.37,1.43
				c3.68,0.4,4.66,3.36,3.88,6.24c-0.33,1.22-2.97,2.3-4.69,2.54c-2.97,0.41-6.04,0.11-9.17,0.11c6.72,4.4,12.97,8.76,19.48,12.68
				c4.95,2.98,10.1,5.75,15.45,7.88c2.05,0.81,5.46,0.35,7.31-0.91c4.85-3.3,9.78-3.43,15.22-2.5c2.91,0.5,5.98,0.09,8.97,0.09
				c0.16-0.51,0.32-1.02,0.48-1.53c-1.54-0.92-2.99-2.05-4.63-2.7c-2.76-1.1-5.72-1.72-8.45-2.89c-1.32-0.56-2.3-1.92-3.43-2.91
				c1.45-0.84,2.82-2.14,4.36-2.4c2.43-0.41,4.98,0.02,7.46-0.15c2.81-0.19,5.61-0.64,9.37-1.1c-2.01-5.17-3.58-9.19-5.27-13.54
				c3.13-4.24,7.3-6,13.08-1.13c-0.48-4.05-0.82-6.93-1.23-10.33c-4.75,0.84-8.93,1.81-13.17,2.29c-15.25,1.71-30.2-0.14-44.83-4.54
				c-5.44-1.64-10.58-1.28-16.14-0.31c-9.75,1.71-19.67,2.76-29.56,3.25C702.86,814.51,699.08,815.12,695.5,820.78z M872.38,427.58
				c-0.87,5.86-1.6,12.44-2.7,18.76c-1.08,6.15-3.36,11.58-5.19,17.28c-0.43,1.35-2.53,2.36-4.06,3.14
				c-3.66,1.87-7.58,3.27-11.13,5.32c-5.56,3.22-9.65,2.02-11.39-4.19c-1.11-3.97-1.4-8.17-2.08-12.26
				c-1.19-7.09-2.39-14.18-3.59-21.27c-1.92,2.98-2.27,6.09-3.76,8.51c-3.07,4.97-2.41,9.7-0.45,14.69c1.33,3.4,2.88,6.79,3.61,10.34
				c1.55,7.56,2.63,15.21,4.18,24.49c5.14-5.5,9.62-10.16,13.95-14.95c2.92-3.23,5.85-5.56,10.5-1.67
				c-3.66,8.12-7.4,16.22-10.95,24.39c-1.24,2.85-3.18,6.04-2.82,8.82c0.78,5.95-1.38,11.01-4.2,15.42
				c-2.21,3.47-2.9,6.63-2.24,10.23c1.54,8.43,3.37,16.81,5.01,24.85c3.27-2.32,6.35-4.39,9.28-6.64c0.98-0.75,2.24-1.94,2.29-2.97
				c0.2-4.92,0.41-9.89-0.1-14.77c-1.18-11.33,1.2-20.86,11.38-27.54c3.82-2.51,6.14-6.17,4.77-11.38c-0.4-1.54,0.36-4.11,1.53-5.2
				c2.67-2.48,3.83-5.16,3.73-8.76c-0.05-1.93,0.94-3.86,1.17-5.82c0.41-3.49,1.08-7.05,0.78-10.51c-0.47-5.44-1.66-10.82-2.55-16.23
				c0.42-0.1,0.85-0.2,1.27-0.3c1.27,1.02,2.53,2.05,3.8,3.07c0.27-0.19,0.54-0.38,0.81-0.57
				C879.57,443.71,875.94,435.57,872.38,427.58z M233.7,938.85c0.09-0.16,0.18-0.33,0.27-0.49c-1.84-3.93-4.03-7.75-5.46-11.82
				c-6.1-17.42-6.02-35.71-6.71-53.78c-1.42-37.42-2.18-74.86-3.36-112.29c-0.13-4.28-0.68-8.71-2.05-12.71
				c-0.68-1.98-3.51-3.76-5.73-4.4c-0.84-0.25-3.09,2.74-3.97,4.6c-1.26,2.68-2.49,5.63-2.64,8.53c-0.77,15.11-1.55,30.24-1.61,45.37
				c-0.05,11.97,0.6,23.97,1.49,35.91c2.45,32.54-1.03,65.72,9.98,97.34c2.17,6.22,5.61,11.99,8.46,17.97
				c0.43-0.21,0.86-0.42,1.29-0.62c-3.08-5.78-6.16-11.56-9.24-17.33c0.6-0.38,1.21-0.76,1.81-1.14c3.09,7.42,6.38,14.67,14.14,18.91
				c-12.57-19.21-19.27-39.78-20.92-62.06c-0.86-11.61-1.19-23.28-2.54-34.84c-3.27-28.01-3.88-56.09-3.18-84.23
				c0.12-4.87,0.9-9.73,1.37-14.6c0.48,0.02,0.97,0.04,1.45,0.06c-3.48,52.71,2.61,104.84,8.06,157.02c0-17.41-0.76-34.77-1.53-52.13
				c-0.78-17.44-1.88-34.88-2.28-52.34c-0.39-17.2-0.09-34.42-0.09-51.63c0.68,1.77,0.97,3.56,1.01,5.35
				c0.22,11.81,0.18,23.62,0.61,35.42c1.03,28.75,2.27,57.49,3.42,86.23c0.46,11.43-0.04,22.99,1.6,34.24
				c1.45,10.02,5.89,19.41,13.18,26.93c1.35,1.39,3.39,2.11,5.11,3.14c0.5-0.47,0.99-0.94,1.49-1.41
				c-5.02-7.44-10.03-14.88-15.05-22.32c0.39-0.27,0.78-0.54,1.17-0.81C226.73,929.56,230.22,934.21,233.7,938.85z M745.19,1442.31
				c-5.19-3.17-10.38-6.63-15.84-9.58c-5.5-2.97-11.13-5.82-16.97-8.04c-9.92-3.77-19.97-7.27-30.13-10.33
				c-3.82-1.15-8.23-1.56-12.14-0.92c-7.63,1.25-15.23,3.07-22.59,5.43c-5.07,1.63-10.89,3.52-14.25,7.23
				c-3.9,4.31-5.48,10.73-8.11,16.32c0.58,0.31,0.97,0.68,1.41,0.74c20.26,2.69,40.49,3.66,60.27-2.78
				c7.03-2.29,13.55-3.14,20.55,0.24c2.63,1.27,6.19,0.7,9.33,0.81C726.14,1441.77,735.54,1442.02,745.19,1442.31z M260.3,1237.46
				c-6.72,4.59-9.21,1.27-12.82-3.98c-0.95-1.38-1.75-3.96-1.08-5.06c0.74-1.22,3.23-1.37,5.47-2.16c0.53-0.68,1.59-2.06,2.65-3.43
				c1.55,1.73,3.11,3.46,5.18,5.76c2.07-1.08,5.04-2.62,8-4.17c0.28,0.56,0.56,1.12,0.84,1.68c-3.21,1.6-6.3,3.81-9.69,4.48
				c-1.15,0.23-3.07-3.42-4.53-5.18c-0.85,3.91-0.85,3.91-6.79,4.07c2.93,7.83,5.77,8.84,13.47,5.37c2.92,1.21,5.85,1.66,7.55,3.35
				c1.73,1.71,2.24,4.64,3.07,6.55c2.25,0.51,5.06,0.38,6.4,1.71c0.91,0.9,0.49,4.83-0.65,5.71c-1.73,1.35-4.6,1.23-7.34,1.81
				c-0.72,4.01-3.28,6.61-8.43,2.62c-1.88,2.23-3.73,4.44-5.95,7.08c0.9,1.22,1.83,2.5,2.66,3.62c3.95-2.65,6.99-6.42,9.11-0.1
				c1.31-0.5,3.73-0.98,3.74-1.51c0.07-3.44,1.84-4.38,4.83-4.09c3.47,0.34,2.76,2.82,2.94,5.17c0.06,0.84,2.07,1.53,3.19,2.29
				c2.83-4.62,6.49-2.39,10.85-0.45c0.19-3.8,1.68-8.14,0.2-9.77c-2.72-3-2.65-4.73-0.08-6.75c-1.18-3.45-2.56-5.54-6.44-5.5
				c-3.17,0.03-4.09-2.02-2.42-5.19c0.56-1.07-0.2-3.09-0.8-4.48c-0.92-2.14-2.23-4.11-3.38-6.15c0.46-0.33,0.92-0.66,1.38-0.99
				c-2.24-1.47-4.45-3-6.74-4.38c-3.68-2.23-8.45-2.47-10.41-7.76c-0.71-1.9-4.33-4.63-8.43-2.24c-1.71,1-5.5,1.87-7.2-1.96
				c-0.27-0.6-3.14-0.82-4.14-0.18c-6.5,4.2-9.71,3.91-13.05-1.52c-0.27,3.04-2.18,5.96,1.54,8.9c4.44,3.51,0.51,8.04,0.21,12.03
				c-0.09,1.23-3.09,2.23-4.85,3.41c-0.77,1.55-1.96,3.34-2.54,5.31c-0.44,1.49-0.45,3.31-0.01,4.8c0.68,2.3,1.9,4.45,3.19,7.32
				c5.17-0.79,4.11,3.56,3.99,7.64c1.24-1.26,1.75-2.73,2.69-3.82c0.81-0.94,2.12-1.44,3.21-2.14c0.53,1.34,1.44,2.66,1.5,4.03
				c0.07,1.6-0.59,3.23-0.93,4.85c0.47,0.4,0.94,0.8,1.41,1.19c2.74-1.65,5.42-3.4,8.23-4.91c1.34-0.72,3.48-1.84,4.28-1.31
				c3.19,2.12,4.3,0.03,5.02-2.21c1.16-3.58,2.86-3.58,5.43-1.47c0.68,0.56,1.66,0.76,2.5,1.12c1.07-2.27,1.56-5.3,3.05-5.91
				c1.75-0.72,4.36,0.65,6.89,1.18c-0.12-2.18-0.19-3.42-0.26-4.7c-6.15,1.48-6.55,1.09-7.15-4.86c-0.11-1.08-1.43-2.39-2.52-2.93
				C264.7,1238.42,262.74,1238.14,260.3,1237.46z M1160.96,740.71c0-10.85,0-21.3,0-30.46c-8.83,5.18-18.28,9.94-26.83,15.97
				c-8.9,6.28-16.91,13.82-25.36,20.74c-3.06,2.51-4.16,5.47-3.97,9.5c0.34,7.47,0.1,14.96,0.04,22.44c-0.05,5.8,6.29,12,11.99,11.21
				c4.69-0.65,9.29-1.9,14.22-2.94c-1.4-3.37-3.07-6.78-4.22-10.36c-0.79-2.45-0.71-4.5,1.68-7c7.38-7.7,18.97-10.6,23.8-21.07
				c1.42-3.08,2.8-6.18,4.3-9.5C1157.76,739.64,1159.26,740.14,1160.96,740.71z M984.42,504.63c-3.15-7.74-6.97-15-9.02-22.73
				c-2.37-8.95-4.28-18.29-4.46-27.49c-0.75-40.1-1.52-80.22-0.88-120.32c0.59-36.92,4.17-73.84,0.39-110.73
				c-1.38-13.49-4.26-26.87-7.12-40.16c-1.24-5.75-5.04-10.43-9.62-13.34c2.03,5.8,4.78,11.7,6.14,17.91
				c4.28,19.62,4.38,39.56,4.21,59.57c-0.57,64.92-0.9,129.84-0.99,194.77c-0.02,16.44,0.63,33,6.04,48.79
				c1.56,4.57,4.19,9.08,7.43,12.62C978.04,505.16,982.18,504.38,984.42,504.63z M488.46,931.22c3.82-0.75,6.98-1.11,9.94-2.1
				c1.09-0.37,2.37-2.22,2.39-3.42c0.52-27.62,0.83-55.24,1.3-82.87c0.12-7.14,0.57-14.28,0.95-21.42c0.42-7.75,0.95-15.5,1.48-24.03
				c-9.39,2-17.87,3.81-26.89,5.74c7.37,21.37,7.42,42.72,8.41,63.92C487.03,888.29,487.66,909.55,488.46,931.22z M131.92,1485.64
				c-1.36-7.67-6.18-8.63-11.02-7.07c-4.36,1.4-7.32,1.27-10.87-2.81c-1.04,1.85-1.86,3.3-2.24,3.96c-6.18-1.99-11.47-3.7-16.76-5.41
				c-2.73,3.25-8.74,3.61-11.23-0.06c-5.74-8.46-15.6-9.65-23.98-15.28c0.76,7.38-3.11,7.5-7.82,6.12c-1.1,4.3,2.24,5.75,4.05,8.08
				c0.66,0.84,0.55,2.27,0.79,3.44c-1.2,0.11-2.66,0.71-3.54,0.23c-1.85-1.01-3.36-2.64-5.09-3.89c-0.74-0.54-1.69-0.79-2.84-1.3
				c0.52,2.75,0.93,4.75,1.26,6.76c0.35,2.2,0.07,4.71,1.05,6.54c1.69,3.13,1,4.64-1.9,6.39c-2.43,1.46-4.35,3.78-6.5,5.72
				c0.32,0.44,0.65,0.89,0.97,1.33c1.82,0,3.64,0,5.46,0c24.46,0,48.92-0.12,73.38,0.08c6.09,0.05,11.8,0.15,15.41-6.59
				C130.29,1490.07,125.92,1486.91,131.92,1485.64z M34.58,1028.81c-3.81-0.25-7.36-0.61-10.92-0.69
				c-5.74-0.14-11.49-0.25-17.22-0.07c-3.35,0.11-4.51,2.04-4.49,5.61c0.16,29.77,0.09,59.54,0.11,89.31c0,1.16,0.27,2.32,0.42,3.48
				c0.53,0.25,1.07,0.49,1.6,0.74c6.82-8.65,13.71-17.24,20.38-26c0.75-0.99,0.96-3.37,0.29-4.31c-3.05-4.3-2.46-8.7-1.31-13.34
				c1.19-4.83,2.39-9.69,3.03-14.6c0.27-2.06-0.87-4.31-1.55-7.3c2.04-4.42,5.24-9.71,6.91-15.45
				C33.58,1040.21,33.85,1033.81,34.58,1028.81z M344.59,1198.16c-3.29,5.06-7.4,8.99-14.29,5.97c-1.17,3.36-3.06,6.04-6.56,4.12
				c-2.62-1.44-4.59-4.07-6.39-5.74c-2.31,1.03-4.37,1.96-6.68,2.99c-0.68,5.89-2.97,6.6-11.58,3.09c-2.58,4.15-6.6,2.48-8.63,0.63
				c-2.46-2.24-3.39-6.16-4.98-9.36c0.32-0.09,0.64-0.19,0.96-0.28c-1.67-1.04-3.35-2.09-3.99-2.49c-2.67,0.9-4.88,2.38-6.82,2.08
				c-1.81-0.28-4.12-2.15-4.76-3.86c-0.65-1.74,0.47-4.15,0.68-5.48c-3.96,1.92-8.08,3.91-12.41,6c1.53,2.58,1.38,5.08-2.75,6.16
				c-4.85,1.26-8.15-0.95-9.79-6.75c-3.99,6.09-3.99,5.99-10.36,0.87c-2.37-1.9-5.24-3.16-7.89-4.72c-0.32,0.3-0.65,0.6-0.97,0.9
				c2.84,7.17,5.68,14.35,8.73,22.05c2.89-1.1,5.32-1.34,6.69-2.68c2.92-2.84,4.91-2.22,7.57,0.31c1.23,1.18,3.89,2.26,5.2,1.72
				c5.79-2.41,8.69,1.6,12.1,4.7c1.43,1.3,2.71,2.9,4.37,3.78c3.05,1.61,6.33,2.78,9.84,4.27c3.44-2.6,7.25-5.84,11.43-8.51
				c3.23-2.06,7.2-5.19,10.35-4.69c6.23,0.98,12.37,3.65,18.1,6.52c5.71,2.86,10.86,6.81,15.65,9.89c2.45-3.76,4.34-7.86,7.32-10.89
				c3.08-3.13,6.17-9.39,12.05-3.18c0.11,0.11,0.6-0.15,0.62-0.15c1.42-9.72,8.11-11.14,16.48-10.47c-2.18-2.45-5.5-3.85-6.09-6
				c-1.53-5.61-6.09-5.43-9.98-7.05c-0.82,1.2-1.65,2.07-2.09,3.11C352.33,1203.05,352.35,1203.06,344.59,1198.16z M794.26,1454.26
				c-2,0.89-5.17,2.31-7.68,3.43c5.77,3.12,11.62,6.96,17.98,9.53c6.01,2.42,12.07,6.84,19.18,3.1c6.54-3.44,12.94-1.29,19.37,0.31
				c0.81,0.2,1.6,0.57,2.41,0.61c5.25,0.2,9.22,1.98,10.65,7.65c0.34,1.33,1.49,2.86,2.69,3.49c4.99,2.63,6.25,7.36,7.67,12.18
				c0.41,1.4,1.76,3.57,2.72,3.59c10.18,0.26,20.38,0.16,30.79,0.16c-0.66-2.82-1.36-4.88-1.6-7c-0.67-5.77,0.65-7.07,6.44-6.98
				c2.82,0.04,5.85,0.5,8.43-0.34c4.37-1.43,8.37-1.02,12.63,0.21c6.9,2,13.73,1.41,20.61-0.75c1.95-0.61,4.96-0.09,6.63,1.11
				c5.13,3.68,9.77,8.02,14.75,11.9c1.39,1.08,3.32,1.47,5,2.17c0.23-0.43,0.46-0.86,0.69-1.29c-5.33-4.99-10.63-10.01-15.98-14.97
				c-4.31-3.99-9.31-5.42-15.19-4.42c-2.73,0.46-5.69,0.47-8.41-0.04c-8.8-1.63-17.47-4.03-26.32-5.31
				c-12.59-1.83-25.53-0.51-37.91-4.86c-4.74-1.66-10.12-1.69-15.24-2.04c-13.03-0.89-26.27,0.03-38.79-5.05
				c-2.73-1.11-5.85-1.19-8.71-2.05C803.15,1457.45,799.31,1456,794.26,1454.26z M359.43,958.63c-0.61,0.25-1.22,0.5-1.83,0.76
				c0.19,2.24,0.55,4.47,0.53,6.71c-0.06,6.41,0.33,12.9-0.63,19.18c-0.71,4.65-0.44,8.14,2.87,11.72
				c8.34,9.01,16.54,18.16,24.43,27.56c7.91,9.42,14.66,19.92,23.22,28.66c9.4,9.6,17.93,19.52,23.8,31.66
				c1.48,3.06,3.67,5.77,5.53,8.64c-5.89-14.95-12.12-29.52-18.37-44.09c-4.89-11.41-9.37-23.03-14.85-34.15
				c-5.47-11.09-12.9-20.79-22.79-28.66c-4.43-3.52-7.66-8.62-11.2-13.19C366.43,968.61,362.99,963.57,359.43,958.63z M968.53,1427
				c0-6.39-0.1-12.29,0.02-18.18c0.31-15.64,0.55-31.28,1.13-46.91c0.45-12.28,2.08-24.56,1.93-36.82
				c-0.34-27.45-2.24-54.9-2.1-82.34c0.19-37.11,1.5-74.21,2.72-111.3c0.54-16.36-0.96-32.46-3.98-48.46
				c-0.41-2.16-1.87-4.13-2.85-6.18c-0.5,0.16-1,0.33-1.51,0.49c0.09,1.73,0.13,3.46,0.29,5.18c0.54,5.79,1.31,11.56,1.64,17.36
				c0.33,5.8-0.77,11.28-2.44,17.03c-2.18,7.51-2.55,15.78-2.36,23.69c0.49,19.78,2.62,39.55,2.33,59.29
				c-0.17,11.41-0.16,22.49,1.23,33.92c1.8,14.89,0.26,30.17,0.55,45.28c0.29,15.44,1.8,30.88,1.46,46.3
				c-0.69,31.59-2.15,63.16-3.59,94.72C962.79,1424.22,964.63,1425.54,968.53,1427z M635.19,820.38c-2.28-1.55-3.33-2.89-4.4-2.91
				c-25.98-0.37-50.95-7.65-77.51-11.21c7.1,5.74,13.67,10.75,19.9,16.16c8.77,7.62,17.66,14.05,30.36,10.25
				c2.79-0.83,6.09-0.15,5.03,4.78c-0.31,1.43,1.64,3.41,2.69,5.05c1.8,2.8,5.36,5.57,5.27,8.27c-0.27,8.21,3.64,11.93,10.91,13.76
				c3.52,0.89,6.81,2.64,10.32,3.59c1.62,0.44,4.53,0.55,5.08-0.32c2.65-4.17,6.4-3.15,10.68-3.19c-2.54-4.34-5.64-8.03-8.43-11.95
				c-1.03-1.45-2.32-3.94-1.75-5.08c3.06-6.06-0.66-8.54-5.07-10.78c-4.37-2.21-8.82-4.27-14.84-7.17
				C627.81,826.2,631.27,823.46,635.19,820.38z M281.78,938.1c0.43,0.24,0.86,0.47,1.29,0.71c1.55-2.24,3.22-4.4,4.62-6.73
				c1.95-3.27,3.55-6.77,5.6-9.98c1.12-1.76,3.68-2.89,4.18-4.7c2.61-9.42,5.59-18.85,6.88-28.49c2.09-15.63,2.62-31.47,4.4-47.15
				c3.77-33.23,1.08-66.5,1.04-99.75c-0.01-5.24-0.68-10.48-1.05-15.72c1.07,1.72,1.71,3.48,1.78,5.27c0.45,11.47,1,22.93,1.09,34.4
				c0.17,21.28,0.43,42.58-0.11,63.85c-0.35,13.94-1.83,27.86-3.24,41.75c-0.99,9.74-2.49,19.44-4.18,29.08
				c-0.89,5.04-2.42,10.02-4.15,14.85c-1.78,4.99-2.87,11.68-6.58,14.17c-5.27,3.55-0.99,8.71-4.46,12.04
				c-2.43,2.33-3.72,5.85-5.51,8.85c-0.54,0.91-1.02,1.86-1.53,2.79c1.9-0.38,3.15-1.33,4.27-2.41c7.9-7.62,12.55-17.2,15.22-27.58
				c3.77-14.62,6.92-29.3,7.48-44.61c0.57-15.57,3.53-31.04,4.49-46.61c1.23-20.08,1.64-40.23,2.14-60.35
				c0.17-6.78-0.48-13.58-0.72-20.38c-0.01-0.17,0.3-0.34,0.49-0.54c0.23,0.95,0.44,1.81,0.64,2.66c0.91-12.36,1.08-24.64-3.12-36.39
				c-0.82-2.29-3.35-3.97-5.1-5.92c-1.61,2.25-3.26,4.46-4.79,6.77c-0.33,0.49-0.26,1.3-0.26,1.96c0.02,13.45-0.06,26.9,0.14,40.35
				c0.09,5.81,1.24,11.64,1.06,17.43c-0.95,30.43-1.91,60.87-3.42,91.28c-0.77,15.46-5.67,29.94-12.88,43.67
				c-3.15,5.99-5.68,12.3-8.47,18.47c-0.11,0.24,0.1,0.62,0.17,1c1.13,0.28,2.28,0.56,3.5,0.86
				C282.36,934.95,282.07,936.52,281.78,938.1z M955.19,537.66c1.13-4.62,2.15-8.99,3.25-13.33c2.01-7.93,12.32-13.55,20.35-11.96
				c4.4,0.87,9.12,0.16,13.91,0.16c-0.31-0.74-0.33-0.95-0.45-1.04c-3.64-2.99-7.23-4.18-12.49-4.08
				c-6.18,0.12-10.77,1.72-15.26,5.32c-0.86,0.69-2.12,0.99-3.24,1.21c-5.82,1.16-9.61-2.01-9.57-7.91c0.01-2.51,0-5.02,0-7.52
				c-0.16,0.01-0.31,0.02-0.47,0.03c-0.33,2.48-0.66,4.95-1.06,7.92c-5.3-2.75-7.5-6.88-8.68-11.38c-2.5-9.53-5.34-19.08-6.65-28.8
				c-1.68-12.5-2.4-25.17-2.78-37.79c-0.64-20.78-2.01-41.67-0.68-62.36c2.08-32.43,3.15-64.82,1.87-97.26
				c-0.81-20.6-2.65-41.15-3.6-61.74c-0.51-11.02-0.09-22.08-0.09-31.91c-0.44,6.42-1.49,14.13-1.42,21.83
				c0.34,38.27,1.32,76.54,1.4,114.82c0.06,31.61-1.24,63.22-1.16,94.83c0.06,25.26,0.94,50.54,2.17,75.77
				c0.52,10.56,2.68,21.08,4.49,31.54C937.06,525.56,943.62,533.57,955.19,537.66z M242.03,936.51c0-11.58,0.27-22.88-0.06-34.15
				c-0.59-19.94-1.6-39.86-2.32-59.8c-0.41-11.3-0.51-22.61-0.97-33.9c-0.06-1.48-1.19-3.87-2.29-4.18
				c-4.24-1.19-8.68-1.7-13.31-2.52c-0.1,1.63-0.25,2.77-0.22,3.9c0.63,22.61,1.07,45.23,2,67.83c0.76,18.24,1.12,36.57,6.96,54.21
				C233.8,933.93,235.69,935.92,242.03,936.51z M135.06,1475.41c2.16-5.66,6.08-7.71,11.5-7.28c1.52,0.12,4.05-1.41,4.63-2.8
				c1.2-2.9,3.01-3.2,5.52-2.76c6.37,1.14,6.91,0.66,6.2-5.8c-0.64,0.14-1.57,0.6-1.85,0.35c-3.59-3.27-10.25-2.28-11.77-8.54
				c-0.21-0.87-1.67-1.49-2.62-2.11c-4.46-2.91-8.95-5.78-13.42-8.68c-0.27-0.17-0.41-0.53-0.65-0.75c-2.27-1.97-4.55-3.93-6.85-5.91
				c-4,5.19-3.19,7.5,1.79,11.28c3.93,2.99,7.39,6.59,11.05,9.92c-0.38,0.5-0.76,0.99-1.14,1.49c-7.81-0.51-13.42-5.86-19.67-9.78
				c-3.81,5.71-9.33,5.34-12.4-0.81c-0.59-1.18-0.81-2.58-1.54-3.64c-0.57-0.82-1.68-1.26-2.54-1.87c-0.48,0.8-1.14,1.54-1.4,2.4
				c-1.24,4.1-3.4,4.81-6.51,1.84c-2-1.91-3.7-4.13-5.78-6.48c-1.88,4.68-4.38,2.28-7.06,1.71c-2.69-0.57-6.39-0.93-8.27,0.47
				c-2.04,1.52-1.44,4.56-0.01,7.79c2.69,6.05,8.11,7.98,12.71,11.23c3.57,2.52,7.33,4.22,11.24-0.7c2.22,4.63,5.23,5.78,9.5,3.55
				c1.02-0.53,3.78-0.01,4.06,0.69c1.73,4.44,5.7,5.25,9.05,7.54c4.64,3.17,8.61,6.23,14.34,6.44
				C133.71,1474.22,134.24,1474.87,135.06,1475.41z M953.42,946.58c0.63,0.05,1.26,0.1,1.88,0.15c1.78-20.16,4.23-40.29,5.17-60.49
				c1.09-23.43,0.53-46.94,1.26-70.39c0.9-28.76,2.42-57.51,3.64-86.26c0.57-13.29,1.15-26.58,1.57-39.87
				c0.21-6.8,0.45-13.64-0.01-20.42c-1.37-20.2-4.86-40.22-3.54-60.66c0.79-12.25-0.65-24.62-0.55-36.93
				c0.11-12.37,0.89-24.73,1.32-35.65c-1.18,1.82-4.52,4.79-5.08,8.22c-1.8,11.1-3.01,22.34-3.61,33.57
				c-0.48,8.78,0.26,17.62,0.49,26.44c0.16,6.15-0.13,12.36,0.66,18.42c1.8,13.82,4.65,27.52,6.08,41.37
				c0.91,8.82,0.15,17.82-0.02,26.74c-0.24,12.65-0.71,25.29-0.9,37.93c-0.13,8.46,0.31,16.94,0.03,25.39
				c-0.32,9.93-1.43,19.83-1.67,29.76c-0.51,20.62-0.67,41.25-0.97,61.87c-0.11,7.95,0.03,15.91-0.38,23.84
				c-0.28,5.46-1.65,10.85-1.95,16.31c-0.85,15.43-1.36,30.89-2.12,46.33C954.49,937.06,953.87,941.82,953.42,946.58z
				M1161.35,908.27c-0.31-0.08-0.61-0.16-0.92-0.24c-0.88,1.31-1.73,2.64-2.65,3.93c-6.48,9.01-12.81,18.14-19.55,26.94
				c-2.28,2.98-5.6,5.16-8.48,7.67c-4.72,4.12-9.34,8.37-14.24,12.26c-4.41,3.5-9.07,6.71-13.74,9.86
				c-7.97,5.37-6.46,13.92-8.69,22.6c4.06-1.78,6.87-2.79,9.46-4.19c8.92-4.81,17.52-10.28,26.69-14.53
				c13.42-6.22,22.94-16.2,30.38-28.58c0.67-1.11,1.64-2.33,1.65-3.51C1161.41,929.75,1161.35,919.01,1161.35,908.27z
				M138.01,1498.38c32.76,0,64.18,0.02,95.59-0.09c1.13,0,2.25-1.4,3.38-2.14c-0.97-1.02-1.81-2.73-2.94-2.94
				c-7.04-1.35-12.91-4.1-15.64-12.33c-3.29,3.44-6.3,3.74-9.28,0.46c-3.44-3.78-6.32-3.84-10.21-0.55
				c-2.59,2.19-5.48,1.31-6.44-2.03c-0.35-1.22-0.44-2.52-0.79-4.7c-2.7,0.72-6.4,0.67-7.16,2.14c-1.43,2.77-3.16,4.14-5.69,3.37
				c-1.8-0.55-3.14-2.63-4.18-3.57c-2.4-1.1-4.41-1.73-6.07-2.86c-2.68-1.82-4.65-2.4-7.16,0.63c-2.92,3.54-6.57,6.47-9.89,9.65
				c-2.94-0.73-7.3-4.66-6.37,2.87c0.05,0.39-0.33,1.13-0.67,1.24C139.46,1489.2,141.29,1494.43,138.01,1498.38z M170.61,522.72
				c-7.39-0.59-8.97-3.09-5.5-8.88c2.67-4.46,6.57-8.17,9.98-12.29c-0.59-0.22-1.64-0.6-2.79-1.02c0.24-0.98,0.42-1.61,0.56-2.25
				c0.14-0.66,0.26-1.32,0.66-3.42c-10.95,10.31-22.67,15.47-38.03,15.52c4.76-5.2,8.61-9.41,12.77-13.97
				c-5.55-1.58-3.84-4.72-1.54-7.48c1.78-2.13,4.12-3.81,6.28-5.61c3.65-3.03,7.35-5.99,11.28-9.18c-1.61-0.84-2.65-1.29-3.59-1.9
				c-5.03-3.27-7.31-3.09-11.67,1.11c-1.08,1.04-2.05,2.18-3.04,3.3c-10.09,11.33-21.42,21.2-34.2,29.41
				c-2.83,1.81-5.31,4.17-9.07,7.17c4.77,1.57,8.1,3.38,11.57,3.69c14.32,1.28,26.82,7.07,38.76,14.57c1.01,0.63,2.95,0.96,3.84,0.41
				C161.26,529.23,165.43,526.23,170.61,522.72z M306.17,1252.2c-9.5-4.97-9.52-5.09-7.87-11.92c0.8-3.33,1.42-6.71,1.95-10.1
				c0.93-5.9,3.89-7.83,9.53-5.85c0.56-1.87,1.11-3.73,1.75-5.87c-2.88-1.25-5.56-2.42-7.71-3.36c-5.36,3.5-10.03,7.24-15.28,9.76
				c-4.24,2.03-5.48,4.96-5.75,9.2c4.38,2.28,6.1,5.47,2.85,10c8.06,1.17,11.26,6.16,8.61,9.89c0.58,3.75,1.45,6.25,1.19,8.62
				c-0.3,2.8,0.12,4.65,3.01,5.22c3.02,0.6,6.1,0.93,9.14,1.43c2.97,0.49,6.89-0.23,7.43,4.09c0.45,3.59,2.34,4.39,5.41,4.15
				c2.98-0.24,5.97-0.61,8.94-0.56c1.7,0.03,4.35,0.27,4.93,1.32c3.04,5.55,8.59,5.58,13.59,6.99c1.34,0.38,3.42,0.93,3.68,1.84
				c1.48,5.03,5.75,4.64,8.94,5.44c1.31-6.71-2.62-10.33-7.8-13.45c-1.88-1.13-2.93-3.55-4.61-5.12c-4.67-4.38-9.31-8.8-14.25-12.86
				c-4.57-3.76-10.44-6.32-14.03-10.79c-3.51-4.35-6.54-6.39-12.03-4.5C307.31,1247.69,306.79,1249.73,306.17,1252.2z M942.6,170.49
				c0.58-0.1,1.15-0.2,1.73-0.31c1.21,1.9,2.61,3.71,3.6,5.72c6.23,12.63,7.25,26.12,6.34,39.89c-0.75,11.29-1.98,22.55-2.28,33.85
				c-0.51,19.3-0.6,38.62-0.58,57.93c0.01,9.25,0.68,18.5,1.05,27.74c1.16-20.14,1.24-40.25,1.12-60.35
				c-0.04-6.64-0.94-13.29-0.74-19.91c0.17-5.4,1.39-10.77,2.14-16.15c0.77,0.06,1.55,0.12,2.32,0.18
				c1.59,57.59,3.19,115.18,4.49,162.29c0-25.92,0.15-62.34-0.05-98.77c-0.18-32.26-0.38-64.54-1.34-96.78
				c-0.36-12.23-2.36-24.47-9.55-35.16c-2.45-3.64-5.77-7.06-9.4-5.56c-2.41,0.99-4.18,6.14-4.33,9.52
				c-0.41,9.29-0.1,18.64,0.43,27.94c1.2,21.08,3.59,42.1,1.49,63.29c-0.67,6.81-0.11,13.74-0.11,20.61c0.14,0,0.28,0,0.42-0.01
				c0.36-3.05,0.73-6.09,1.09-9.14c0.41,0.01,0.83,0.03,1.24,0.04c0.61,2.46,1.21,4.91,1.77,7.2c0-16.33,0.26-32.72-0.07-49.09
				c-0.38-18.79-1.36-37.58-1.92-56.37C941.42,176.25,942.21,173.36,942.6,170.49z M802.02,698.6c-7.77,7.16-15.63,13.59-22.52,20.94
				c-8.27,8.82-18.52,11.93-29.92,12.43c-6.42,0.28-9.71,4.03-12.33,8.7c2.9,1.98,5.51,3.77,8.17,5.58
				c-8.5,9.93-16.43,19.21-24.37,28.48c0.53,0.5,1.06,1,1.59,1.5c4.5-4.1,8.68-8.66,13.6-12.18c5.99-4.28,12.25-8.79,19.1-11.02
				c3.77-1.23,7.23-2.28,10.26-4.75c2.58-2.1,5.31-4.06,7.66-6.39c7.28-7.21,15.48-7.24,24.28-3.51c3.79,1.61,7.56-0.4,7.31-4.41
				C804.09,722.06,802.97,710.16,802.02,698.6z M134.39,1397.18c0.94,3.3,2.87,6.5,2,7.75c-2.13,3.06-0.66,5.22,0.12,7.85
				c1.13,3.77,3.8,8.41,2.55,11.22c-1.69,3.81-0.18,5.76,1.11,8.39c0.74,1.51,1.63,2.95,2.45,4.42c-0.35,0.3-0.7,0.6-1.04,0.9
				c-2.65-1.83-5.31-3.66-7.96-5.49c-0.38,0.48-0.76,0.96-1.13,1.44c1.37,1.24,2.63,3.39,4.13,3.57c6.65,0.81,8.3,8.52,14.35,9.98
				c0.37,0.09,0.47,1.16,0.76,1.74c1.56,3.16,9.32,6.27,12.71,5.07c-0.37-1.33-0.73-2.65-1.24-4.48c2.51-0.29,4.64-0.37,6.69-0.82
				c3.87-0.85,5.32,0.67,5.85,4.52c0.31,2.25,2.12,4.3,3.26,6.44c0.54-0.26,1.07-0.53,1.61-0.79c-0.59-1.73-1.19-3.45-1.78-5.16
				c0.72-0.78,1.47-1.44,2.04-2.23c0.57-0.8,0.84-1.83,1.46-2.57c3.19-3.77,2.78-6.86-1.14-9.69c-2.1-1.51-4.3-3.08-5.89-5.07
				c-4.05-5.09-6.61-11.52-13.27-14.17c-0.42-0.17-0.86-0.65-0.99-1.07c-1.54-4.77-3.27-9.5-4.44-14.37
				c-1.02-4.26-3.12-6.03-7.23-5.69c-3.69,0.3-7.78,2.65-10.91-1.78C138.04,1396.47,135.87,1397.11,134.39,1397.18z M97.81,995.12
				c1.47-4.43,3.02-8.37,4.08-12.44c2.14-8.19,3.46-16.63,6.23-24.59c1.7-4.89,0.79-8.4-1.59-12.2c-4.29,2.48-8.4,4.61-12.25,7.14
				c-4.64,3.06-9.5,5.99-13.45,9.83c-5.11,4.97-9.33,10.84-14.12,16.16c-1.51,1.67-3.52,2.94-5.44,4.19
				c-8.53,5.55-17.12,11.03-25.68,16.53c0.26,0.53,0.53,1.07,0.79,1.6c6.9-3.6,14.09-6.21,21.27-1.83
				c6.35,3.87,11.21,0.87,16.58-1.94c-0.78-2.76-1.47-5.21-2.48-8.82c2.71,0.95,4.3,1.79,5.97,2.02c2.19,0.3,4.86,0.88,6.59-0.04
				c3.22-1.72,5.56-1.23,8.14,0.87C93.86,992.73,95.47,993.59,97.81,995.12z M1013.01,894.38c0.46-0.01,0.92-0.03,1.38-0.04
				c0-1.82-0.01-3.65,0-5.47c0.15-23.62-0.19-47.26,0.57-70.87c1.02-31.74,3.6-63.45,4.2-95.19c0.55-29.11-0.46-58.25-1.14-87.37
				c-0.54-23.27-1.15-46.56-2.67-69.78c-0.82-12.54-4.15-24.8-9.29-34.5c1.72,7.99,4.26,17.55,5.75,27.27
				c1.53,10,2.82,20.15,2.87,30.24c0.15,31.29-0.02,62.59-0.66,93.87c-0.71,34.61-2.21,69.19-3.11,103.8
				c-0.6,22.96-1.16,45.94-0.91,68.9C1010.15,868.29,1011.96,881.33,1013.01,894.38z M235.72,991.14c-0.55-0.09-1.09-0.18-1.64-0.27
				c-2.55,3.03-4.92,6.26-7.74,9.01c-1.4,1.36-3.85,2.88-5.48,2.56c-10.41-2.03-16.61,5.76-24.88,9.36
				c8.75,9.91,16.84,19.38,20.98,31.51c0.55,1.63,1.77,3.39,3.18,4.3c5.53,3.55,11.27,6.79,18.11,10.85
				C237.39,1035.41,236.56,1013.28,235.72,991.14z M276.23,924.04c0.93-0.86,2.15-1.6,2.81-2.68c2.51-4.11,4.89-8.3,7.22-12.51
				c3.85-6.95,5.78-14.18,5.34-22.38c-0.61-11.44-0.51-22.97,0.07-34.42c0.97-19.11,2.07-38.22-0.12-57.29
				c-0.94-8.21-2.82-16.33-4.6-24.42c-0.31-1.41-2.28-2.46-3.49-3.67c-0.87,1.1-2.25,2.09-2.53,3.33c-1.18,5.17-2.78,10.4-2.89,15.63
				c-0.67,34.27-0.74,68.55-1.6,102.81c-0.19,7.62-2.88,15.16-4.16,22.78c-0.35,2.1-0.1,4.45,0.49,6.51
				C273.32,919.63,274.68,921.3,276.23,924.04z M213.96,1260.62c-2.32,7.73-4.72,15.27-6.72,22.93c-0.25,0.97,1.55,3.55,2.5,3.61
				c8.42,0.51,16.87,0.93,25.3,0.71c4.74-0.12,9.44-2.12,14.16-2.14c8.73-0.03,17.46,0.72,26.18,1.24c5.91,0.35,11.35-3.8,10.14-8.42
				c-0.67-2.54-2.94-4.69-4.63-6.91c-1.78-2.34-3.73-4.55-6.11-7.42c-2.37,2.57-5.07,11.53-11.06,1.66
				c-3.58,6.15-6.49,1.81-8.84-0.06c-2.03,1.67-3.66,4.12-5.52,4.31c-3.72,0.39-7.76-1.36-11.31-0.59
				c-5.61,1.23-8.32-1.25-10.41-5.95C221.46,1270.79,219.24,1261.83,213.96,1260.62z M752.87,1479.98
				c-8.01,0.19-16.68,0.51-25.35,0.53c-3.63,0.01-7.33-0.3-10.89-1.03c-4.1-0.85-8.4-1.7-12.03-3.65c-8.9-4.77-17.84-7.91-28.11-5.39
				c-2.69,0.66-5.6,0.52-8.41,0.51c-9.97-0.05-19.94-0.23-29.9-0.26c-1.4,0-2.81,0.59-4.22,0.91c2.02,0.7,3.92,0.46,5.82,0.38
				c5.31-0.23,10.62-0.58,15.92-0.44c1.25,0.03,3.54,1.88,3.47,2.74c-0.26,3.22,1.82,3.45,3.91,4.02c5.14,1.4,6.93,4.12,6.21,9.31
				c-0.6,4.31,3.57,10.63,7.64,10.69c14.11,0.2,28.22,0.08,41.39,0.08c0.98-3.93,2.76-7.3,2.38-10.41c-0.7-5.71,2.7-5.52,6.25-5.52
				c2.81,0,5.63,0.25,8.42,0.6C741.94,1483.86,748.27,1483.77,752.87,1479.98z M139.47,418.89c0.3,0.19,0.59,0.37,0.89,0.56
				c3.63-2.32,7.45-4.4,10.84-7.02c3.37-2.62,6.54-5.59,9.4-8.76c4.04-4.48,7.61-9.38,11.61-13.9c2.28-2.58,5.01-4.77,7.54-7.14
				c-0.05-0.58-0.09-1.16-0.14-1.74c-1.85-0.19-3.85-0.92-5.52-0.46c-3.82,1.04-7.15,0.91-10.51-2.23c1.35-1.71,2.62-3.4,3.98-5.01
				c3.52-4.18,7.2-8.24,10.61-12.52c4.36-5.48-0.39-11.39,0.49-17.08c0.06-0.41-0.88-0.93-1.26-1.46c-1.74-2.42-3.44-4.86-5.61-7.94
				c-0.95,3.08-3.16,6.46-2.72,9.44c1.59,10.89-8.05,14.55-11.85,21.89c-0.62,0.17-1.24,0.34-1.86,0.51
				c-0.97-1.57-1.94-3.14-2.83-4.58c-0.85,3.44-1.53,6.47-2.38,9.47c-0.67,2.38-2.18,4.7-2.21,7.06c-0.11,8.01-1.96,15.35-6.28,22.15
				c-2.02,3.18-2.88,6.54,2.34,8.07C142.37,411.99,140.92,415.44,139.47,418.89z M1095.18,168.33c-0.07-0.03,0.19,0.2,0.44,0.18
				c11.2-0.56,22.71-2.8,32.04,6.34c0.99,0.97,2.65,1.38,4.08,1.78c7.33,2.08,14.67,4.15,22.04,6.07c2.32,0.61,4.77,0.76,7.6,1.19
				c0-7.12,0-13.76,0-20.4c0-6.6,0-13.21,0-19.6c-14.79-3.79-19.04-1.95-27.51,9.08c-2.35,3.06-6.91,5.74-10.71,6.13
				C1112.98,160.17,1103.39,162.28,1095.18,168.33z M826.46,1236.58c4.84,4.32,8.94,8.05,13.15,11.66c0.52,0.44,1.61,0.41,2.4,0.31
				c9.03-1.08,18.28-1.32,27.04-3.52c14.8-3.72,29.45-4.64,44.3-1.3c8.56,1.93,16.61-0.47,24.88-1.59c0.35-4.43,0.66-8.3,0.99-12.4
				c-1.26,0-2.08-0.05-2.88,0.01c-1.65,0.14-3.3,0.38-4.95,0.5c-16.17,1.2-31.96,5.91-48.68,0.93c-8.24-2.46-18.06-0.21-27.08,0.73
				C846.13,1232.9,836.76,1234.88,826.46,1236.58z M500.56,931.85c-1.66,0-2.98-0.14-4.27,0.02c-6.89,0.85-8.01,1.87-8.53,8.73
				c-0.35,4.64-0.37,9.3-0.36,13.96c0.03,11.6,0.06,23.2,0.29,34.8c0.19,9.27,0.4,18.55,1.1,27.79c0.56,7.42,0.36,15.36,8.84,21.51
				c1.04-4.24,2.52-7.58,2.56-10.93c0.37-31.09,0.49-62.18,0.67-93.27C500.87,933.81,500.72,933.16,500.56,931.85z M1101.98,917.45
				c0.37,0.51,0.74,1.01,1.11,1.52c5.04-3.57,11.37-6.19,14.88-10.91c9.3-12.48,18.8-25.19,25.53-39.1
				c6.65-13.73,9.6-29.2,14.95-43.61c3.85-10.38,2.62-20.85,2.6-31.42c-0.68,0.95-1.06,1.98-1.38,3.02
				c-3.76,12.55-7.2,25.2-11.39,37.6c-2.05,6.08-5.77,11.57-8.26,17.53c-7.53,18.01-18.29,33.43-34.52,44.7
				c-1.18,0.82-2.38,2.45-2.51,3.81C1102.43,906.18,1102.28,911.83,1101.98,917.45z M1112.02,444.13
				c0.52,26.97,1.03,53.55,1.54,80.13c0.41,0.07,0.81,0.14,1.22,0.21c1.24-3.01,2.69-5.95,3.67-9.05c0.83-2.64,0.47-5.83,1.78-8.12
				c4.89-8.56,10.32-16.81,15.39-25.27c0.98-1.64,2.11-3.83,1.79-5.5c-0.96-5.05,0.96-9.01,3.48-13.03c2.1-3.36,3.29-6.69-2.1-8.47
				c-1.01-0.34-1.59-1.95-2.41-2.94c-2.11-2.56-4.23-5.1-6.78-8.17c1.91-2.33,4.26-5.2,6.61-8.06c-0.28-0.36-0.56-0.71-0.84-1.07
				c-3.76,2.73-7.37,5.72-11.35,8.07c-2.2,1.3-5.01,1.73-7.6,2.14C1115.09,445.2,1113.61,444.47,1112.02,444.13z M209.96,931.01
				c-0.32,18.01,11.41,33.95,24.33,32.37c1.78,15.85,4.79,31.69,5.1,47.57c0.67,34.77-0.04,69.57-0.17,104.36
				c-0.03,9.38-0.01,18.77-0.01,28.61c4.11-0.67,7.46,0.26,7.26-4.33c-0.42-9.48-0.81-18.96-1.2-28.44
				c-0.79-19.28-1.81-38.55-2.26-57.83c-0.22-9.62,0.95-19.28,0.87-28.91c-0.14-16.98-0.83-33.95-1.08-50.93
				c-0.03-2.33,0.5-6.09,1.92-6.76c5.34-2.53,3.53-6.76,3.78-10.91c-4.42,0.79-8.52,1.52-12.61,2.25c3.77-3.99,6.99-11.85,5.22-14.42
				c-1.25-0.15-2.5-0.29-3.39-0.4c-0.12,2.57-0.23,4.95-0.35,7.32c-2.19-0.23-4.37-0.47-5.78-0.62c0.18,1.35,0.42,3.13,0.66,4.92
				c-1.28-0.21-2.57-0.4-3.85-0.63c-0.32-0.06-0.78-0.41-0.91-0.3c-5.33,4.37-6.66-1.34-8.12-3.85
				C215.69,943.71,212.8,936.89,209.96,931.01z M1161.21,898.63c-9.32,10.54-18.2,21.52-28.1,31.48
				c-7.94,7.98-17.06,14.81-25.84,21.91c-1.2,0.97-3.5,0.55-5.29,0.78c0.41-1.64,0.29-3.88,1.34-4.8c3.89-3.46,8.01-6.73,12.33-9.64
				c19.36-13.02,33.22-30.79,44.18-51.02c1.38-2.54,1.3-5.87,1.9-8.83c-0.52-0.17-1.04-0.33-1.55-0.5
				c-16.38,24.77-34.96,47.44-60.18,64.68c-0.98,7.78-2.08,16.48-3.22,25.48C1125.21,950.99,1148.19,929.39,1161.21,898.63z
				M449.18,760.49c11.07-3.63,22.95-7.51,35.92-11.76c-3.32,8.83-9.99,12.69-15.54,17.65c12.09,0.53,22.73-3.15,32.66-9.17
				c0.94-0.57,1.83-2.04,1.88-3.13c0.59-11.24,1.01-22.49,1.51-34.44C483,729.08,466.62,746.22,449.18,760.49z M565.29,1468.17
				c-6.02,1.61-11.62,3.67-17.4,4.49c-6.02,0.85-11.47,2.33-16.76,5.54c-4.94,3-10.58,4.83-16.43,7.4c0-2.31,0-3.37,0-4.53
				c-7.27,5.81-14.36,11.48-21.61,17.29c19.35,0,38.86,0.03,58.37-0.11c0.86-0.01,2.06-1.39,2.5-2.4c2.83-6.48,5.52-13.03,8.2-19.58
				C563.34,1473.39,564.4,1470.47,565.29,1468.17z M164.78,335.06c-4.43,0-8.37-0.2-12.28,0.05c-4.36,0.28-8.75,1.64-13.04,1.32
				c-5.63-0.41-10.4-0.02-14.47,4.5c-4.48,4.97-10.28,7.94-17,8.57c-4.75,0.44-9.67-0.21-14.29,0.75
				c-7.67,1.59-14.76,0.84-21.85-2.33c-6.32-2.82-12.92-3.83-19.91-2.67c-4.22,0.7-8.58,0.48-12.88,0.66
				c-3.32,0.14-6.63,0.25-9.95,0.38c-6.92,0.26-13.85,0.6-20.77,0.75c-1.9,0.04-3.8-0.5-6.03-0.83c0,2.47,0,4.4,0,7.13
				c5.61,0,11.03,0.04,16.45-0.01c13.95-0.11,27.89-0.35,41.84-0.35c6.79,0,13.58,0.57,20.37,0.65c7.96,0.09,15.93,0.02,23.9-0.18
				c7.74-0.2,15.5-0.42,23.21-1.09c4.23-0.37,8.4-1.59,12.55-2.63c4.38-1.1,9.17-1.67,12.91-3.91
				C157.48,343.46,160.4,339.39,164.78,335.06z M185.61,1018.3c0.87,1.25,1.34,1.93,1.81,2.6c7.08,10.18,15.48,19.68,16.5,32.94
				c0.09,1.15,1.26,2.32,2.16,3.27c9.08,9.64,18.23,19.22,27.29,28.88c0.95,1.02,1.47,2.44,2.2,3.68c0.65-0.36,1.3-0.72,1.95-1.08
				c0-3.78-0.08-7.56,0.01-11.33c0.42-16.6,0.57-16.84-14.23-24.83c-5.4-2.91-7.99-7.24-10.84-12.28
				c-5.39-9.56-11.83-18.53-17.91-27.87C190.87,1014.76,188.41,1016.42,185.61,1018.3z M627.15,829.32c5.21,2.62,9.72,4.7,14.04,7.12
				c4.16,2.33,6.6,5.53,4.18,10.67c-0.45,0.96-0.39,2.68,0.19,3.54c2.81,4.19,5.75,8.3,8.91,12.22c1.07,1.33,2.78,2.41,4.42,2.98
				c7.04,2.45,14.2,4.53,21.22,7.03c3.66,1.3,5.22,0.14,5.64-3.81c-3.16-1.1-6.48-1.8-9.34-3.34c-4.75-2.57-9.43-5.39-13.66-8.72
				c-1.37-1.08-2.28-4.47-1.63-6.08c1.85-4.56,4.62-8.74,6.88-12.8c-3.5-11.96-15.53-13.28-25.81-18.13c2.7-1.76,4.26-2.77,5.96-3.88
				c-4.75-2.98-5.98-2.93-14.2,0.34c0.82,1.34,1.63,2.68,2.43,4C633.27,823.44,630.41,826.19,627.15,829.32z M751.43,1496.67
				c0.26,0.46,0.52,0.92,0.78,1.38c26.92,0,53.83,0,80.93,0c-0.22-0.83-0.23-1.37-0.48-1.74c-2.65-3.81-5.28-7.65-8.05-11.38
				c-4.25-5.74-8.14-6.92-15.09-5.03c-1.89,0.51-3.93,0.44-5.86,0.85c-4.3,0.92-8.64,1.75-12.85,3.01
				c-6.31,1.88-12.44,4.38-18.79,6.06c-4.59,1.21-9.52,1.12-14.1,2.36C755.52,1492.83,753.58,1495.12,751.43,1496.67z M260.92,807.72
				c2.5,35.11,4.95,69.55,7.47,104.98c4.51-6.9,5.47-13.73,5.62-20.72c0.21-10.15,0.14-20.31,0.35-30.46
				c0.32-15.64,0.83-31.28,1.07-46.92c0.02-1.47-0.92-3.96-1.95-4.28C269.6,809.11,265.49,808.6,260.92,807.72z M728.06,423.09
				c-11.43,0.26-18.12,9.38-27.44,13.06c-0.31-0.32-0.61-0.65-0.92-0.97c1.37-2.31,2.75-4.61,4.26-7.14
				c-8.17-0.6-13.97,1.89-16.48,9.9c-2,6.39-6.35,10.54-12.29,13.62c-7.41,3.86-12.5,10.15-15.96,17.58
				c10.58-5.61,21.21-11.24,33.05-17.52c-0.31,4.58-0.49,7.25-0.77,11.34c10.46-8.02,19.96-15.31,29.89-22.93
				c-2.4-0.98-3.86-1.57-5.68-2.32C720.01,432.62,724.03,427.85,728.06,423.09z M1004.22,475.95c-3.2,5.86-5.95,10.92-8.71,15.98
				c0.36,0.23,0.72,0.46,1.07,0.7c1.21-1.09,2.36-2.24,3.63-3.26c4.53-3.62,8.63-8.35,13.79-10.45c5.2-2.12,8.92-5.33,12.71-8.98
				c5.32-5.12,10.88-9.78,19-9.4c0.64,0.03,1.36-1.54,2.04-2.37c-0.24-0.36-0.48-0.73-0.73-1.09c-10.43,4.87-20.67,3.43-31.07,0.13
				c-8.02-2.54-16.29-4.27-23.47-6.11c-2.64,3.08-4.86,5.68-7.04,8.22c0.55,3.1,1.16,6.48,1.68,9.39c2.05,1.01,4.89,1.45,5.71,2.98
				C995.18,476.08,998.64,476.75,1004.22,475.95z M542.72,904.68c0.65,0.07,1.31,0.13,1.96,0.2c-0.94,6.3-1.88,12.59-2.82,18.89
				c0.21,0.09,0.42,0.18,0.63,0.27c1.73-3.31,3.47-6.62,5.2-9.93c0.41,0.2,0.82,0.4,1.23,0.59c-3.76,11.2-7.38,22.53-14.43,32.27
				c-2.35,3.25-2.12,5.8-0.47,10.17c2.4-2.54,4.88-4.18,5.95-6.48c3.64-7.81,7.14-15.73,10.04-23.84
				c6.49-18.21,4.66-37.12,3.75-55.88c-0.16-3.19-1.41-6.63-3.15-9.32c-1.12-1.74-3.97-2.75-6.21-3.13c-0.7-0.12-2.77,2.88-2.79,4.47
				c-0.21,15.9-0.13,31.81-0.13,47.72c0.1,0.01,0.19,0.03,0.29,0.04C542.09,908.71,542.41,906.7,542.72,904.68z M441.52,1256.61
				c39.32,0,78.78,0,118.73,0c-5.31-10.5-5.9-10.85-15.9-10.15c-1.16,0.08-2.33,0.07-3.49,0.15c-15.57,0.99-31.14,1.95-46.71,2.99
				c-15.25,1.02-30.49,2.34-45.75,3.01C444.53,1252.77,442.17,1253.41,441.52,1256.61z M151.29,356.87c2.35,2.76,3.86,4.54,5.62,6.62
				c5.44-7.72,13.69-13.4,9.26-25.16c-2.98,2.43-5.87,4.47-8.38,6.9c-4.74,4.6-7.67,11.59-15.92,11.36
				c-0.92-0.03-2.36,0.97-2.73,1.84c-2.26,5.33-4.55,10.68-6.26,16.2c-1,3.23,0.54,5.14,4.32,4.11c1.68-0.46,3.41-0.74,6.28-1.35
				c-1.32,3.13-2.06,5.25-3.08,7.22c-5.02,9.63-12.17,18.18-13.84,29.53c-0.43,2.94-3.47,5.52-5.39,8.2
				c-0.87,1.22-1.95,2.3-3.08,3.61c6.17-1.39,11.79-2.62,17.38-3.97c0.67-0.16,1.44-0.89,1.71-1.54c1.52-3.63,2.89-7.32,4.29-10.91
				c-3.65-3.12-3.56-5.45-1.32-10.6c2.35-5.4,4.32-11.03,5.78-16.74C147.97,374.18,149.39,365.99,151.29,356.87z M2.86,467.64
				c13.96-11.04,27.66-21.55,40.93-32.56c3.43-2.85,5.54-7.29,8.96-11.96c-8.26,0.41-9.77-4.03-8.8-10.48
				c-4.17,1.43-8.12,1.91-10.87,3.99c-2.83,2.14-6.21,5.81-6.27,8.87c-0.1,4.97-3.02,6.79-5.89,9.34
				c-4.09,3.63-8.07,7.39-12.06,11.13C2.85,451.61,0.32,458.37,2.86,467.64z M536.01,794.36c8.06-9.73,19.04-18.14,25.64-29.84
				c-5-0.24-9.75-0.47-15.01-0.73c2.12-7.42,6.48-11.18,11.14-15.09c7.33-6.14,14.04-13.03,21.01-19.6
				c-12.96,4.9-25.06,10.67-37.18,16.39c-3.56,1.68-5.74,3.92-5.67,8.33C536.14,767.41,536.01,781.01,536.01,794.36z M496.4,1247.14
				c0.07-0.34,0.15-0.67,0.22-1.01c-1.83-0.33-3.74-1.17-5.47-0.88c-4.15,0.71-8.16,1.87-11.08-2.59c-0.37-0.57-1.39-0.72-2.12-1.03
				c-2.64-1.11-5.25-2.33-7.95-3.28c-4.31-1.52-2.97-4.92-2.85-7.96c0.02-0.49,0.03-1.1-0.23-1.45c-4.53-6.13,1.94-12.03,0.43-18.14
				c-0.19-0.77,1.13-1.91,1.76-2.87c1.32-2.03,2.65-4.05,4.32-6.61c-6.03,0.62-10.51,0.99-14.95,1.62c-1.12,0.16-2.12,1.19-3.18,1.8
				c-1.38,0.79-2.99,1.32-4.09,2.39c-1.36,1.32-2.58,2.97-3.27,4.71c-0.33,0.84,0.47,3.01,1.16,3.22c5.42,1.63,3.58,5.4,2.92,8.89
				c-0.28,1.48-1.13,3.4-0.53,4.4c3.66,6.09,2.85,9.49-1.66,11.43c-1.34,4.36-2.28,7.43-3.39,11.07
				C463.45,1249.59,479.93,1248.37,496.4,1247.14z M213.38,1479.47c0.28,0.67,0.57,1.33,0.85,2c4.28-3.19,5.78-1.17,8.08,2.45
				c1.92,3.03,5.86,5.79,9.36,6.55c4.8,1.05,8.08,2.28,7.9,7.91c9.15,0.75,13.25-7.5,20.21-10.57c1.29,1.04,2.64,2.13,4.14,3.34
				c1.4-7.04,1.72-7.17,7.51-4.04c0.51,0.28,1.59,0.03,2.09-0.37c0.34-0.27,0.21-1.24,0.14-1.89c-0.21-2.07-0.48-4.13-0.75-6.18
				c-0.09-0.74-0.24-1.46-0.36-2.2c-3.32,1.39-6.35-3.72-10.08-0.04c-3.69,3.63-7.77,2.81-11.35-1.32c-1.9,1.72-3.56,4.32-5.77,4.92
				c-2.5,0.68-5.49-0.44-8.26-0.77c1.07-2.21,1.94-4.56,3.3-6.57c0.61-0.9,2.19-1.13,4.41-2.18c-3.42-1.43-5.89-2.47-8.37-3.51
				c-3.46,5.46-4.16,5.66-9.14,3.31c-2.07,1.68-3.74,3.29-5.65,4.51C218.99,1476.51,216.15,1477.93,213.38,1479.47z M848.63,1365.45
				c-2.99,9.4-5.37,18.7-8.95,27.51c-2.29,5.64-4.25,10.99-3.75,17.17c0.3,3.68-0.02,7.45-4.66,8.54c2.17,0.42,4.11,0.79,6.04,1.16
				c-0.05,0.45-0.1,0.91-0.14,1.36c-1.22,0.12-2.6-0.07-3.63,0.44c-1.35,0.67-3.34,1.85-3.4,2.91c-0.09,1.47,0.95,3.72,2.2,4.44
				c7.97,4.62,16.72-0.29,17.25-9.51c0.44-7.63,0.55-15.28,1.25-22.89c0.6-6.44,2-12.8,2.73-19.23
				C854.11,1372.62,853.78,1367.97,848.63,1365.45z M1118.99,1286.87c-1.71-9.01-3.56-19.25-5.67-29.44c-0.46-2.2-1.5-4.5-2.92-6.22
				c-1.35-1.62-3.52-3.34-5.4-3.43c-1.07-0.05-2.95,2.95-3.32,4.81c-1.07,5.34-1.86,10.78-2.21,16.22
				c-0.83,12.85,3.67,23.92,12.22,33.34c2.19,2.41,4.38,1.32,5.04-1.64C1117.63,1296.48,1118.11,1292.37,1118.99,1286.87z
				M965.29,1498.38c0.15-0.35,0.3-0.71,0.44-1.06c-4.39-3.76-8.85-7.45-13.13-11.33c-2-1.81-3.62-1.45-6.15-0.91
				c-5.56,1.19-11.37,1.63-17.08,1.65c-3.73,0.01-7.49-1.94-11.19-1.78c-5.55,0.24-11.05,1.57-16.93,2.49
				c-1.75,4.44,0.33,10.87,4.22,10.89C925.42,1498.44,945.35,1498.38,965.29,1498.38z M821.06,1473.73
				c6.47,7.56,12.9,15.15,19.47,22.62c0.87,0.99,2.43,1.9,3.7,1.94c6.79,0.19,13.59,0.09,21.61,0.09c-1.96-4.38-3.25-8.25-5.33-11.62
				c-2.22-3.58-5.18-6.71-7.84-10.01c-0.59-0.74-1.19-1.89-1.93-2.02C840.83,1472.97,831.03,1467.83,821.06,1473.73z M106.53,1477.42
				c1.54-0.83,4.4-2.78,4.69-2.46c4.24,4.8,8.98,0.01,13.38,1.06c-0.61-0.94-1.4-1.48-2.05-2.15c-1.57-1.62-3.33-4.89-4.56-4.69
				c-5.25,0.83-6.78-2.92-9.18-5.93c-0.64-0.81-1.95-1.42-3.01-1.5c-5.92-0.49-12.36,0.52-17.67-1.49
				c-6.87-2.6-14.09-5.98-17.4-13.79c-0.57-1.35-2.39-2.18-3.63-3.25c5.25,9.3,5.25,9.3-3.21,10.97c-0.52,4.33,1.3,6.9,5.28,8.91
				c3.94,1.99,7.34,5.08,10.95,7.71c2.35,1.72,4.66,3.49,6.1,4.58c2.44-1.9,4.04-3.97,4.93-3.69
				C96.28,1473.26,101.25,1475.38,106.53,1477.42z M84.2,574.78c0.12,0.27,0.23,0.55,0.35,0.82c3.93-1.73,8.95-2.49,11.55-5.41
				c4.22-4.74,6.79-10.93,10.17-16.44c1.7-2.78,1.52-4.91-0.65-7.56c-3.23-3.95-5.84-8.41-8.71-12.66c-1.87-2.76-3.81-5.47-5.57-8.3
				c-1.38-2.23-2.53-4.61-4.23-7.76c-0.59,1.48-1.38,3.41-2.14,5.36c-0.8,2.05-1.41,4.2-2.42,6.14c-0.93,1.79-2.28,3.36-3.53,5.14
				c1.87,0.22,4.02,0.2,4.08,0.53c0.32,1.94,0.16,3.96,0.16,5.81c1.37-0.54,2.9-1.14,4.18-1.63c-0.39,5.09-0.74,9.56-1.07,13.87
				c2.77,0,5,0,7.22,0c0.18,0.46,0.36,0.92,0.55,1.38c-3.59,3.28-7.18,6.56-10.83,9.9c3.41,1.01,5.98,1.77,9.67,2.86
				C89.44,570.02,86.82,572.4,84.2,574.78z M95.83,1030.1c-1.74,8.8-3.44,18.23-5.51,27.57c-1.99,9-2.16,8.96-9.6,3.22
				c-0.22-0.17-0.61-0.11-0.87-0.14c-4.03,2.25-4.47,5.4-2.56,9.27c1.39,2.83,2.52,5.8,4.01,8.57c1.46,2.71,1.3,4.9-0.89,7.18
				c-5.14,5.35-4.97,11.82-3.77,19.91c6.65-6.66,12.59-13.33,23.18-8.37c-1.76-5.44-3.51-9.42-4.27-13.58
				c-0.99-5.44-1.75-11.05-1.61-16.55C94.27,1054.58,95.19,1042,95.83,1030.1z M781.42,472.24c-2.81,2.55-5.39,4.81-7.87,7.18
				c-4.14,3.96-8.47,5.52-13.81,2.05c-1.13-0.73-3.27-0.85-4.51-0.29c-5.43,2.44-10.75,5.16-16.03,7.95
				c-3.1,1.64-4.82,9.61-2.33,10.55c5.25,2,10.6,3.91,16.08,4.96c2.07,0.4,4.95-1.23,6.87-2.72c6.24-4.84,12.27-9.97,18.24-15.14
				c1.24-1.07,2.44-2.72,2.7-4.26C781.32,479.33,781.22,476.03,781.42,472.24z M287.81,485.64c-9.01,2.37-17.54,3.77-24.1,9.94
				c-8.88,8.35-17.61,16.86-27.13,26.01c3.09-0.99,5.29-1.66,7.45-2.39c7.36-2.5,14.82-4.74,22.02-7.64
				c4.15-1.67,8.18-4.03,11.69-6.81c3.12-2.47,5.59-5.22,10.12-5.42c3.58-0.16,7.1-1.8,10.65-2.78
				C289,495.19,285.96,492.63,287.81,485.64z M723.23,1484.83c0.08,1.49,0.32,2.5,0.13,3.42c-0.23,1.11-0.81,2.14-1.25,3.2
				c-1.79,4.3-0.3,6.86,4.2,6.91c6.48,0.08,12.96,0.1,19.44-0.06c1.27-0.03,3.21-0.68,3.68-1.62c4.16-8.48,12.53-8.4,19.77-8.86
				c8.66-0.55,15.74-4.38,21.89-6.75c-8.21-0.65-17.62-1.45-27.05-2.12c-1.6-0.11-3.58-0.23-4.8,0.56
				c-9.64,6.28-20.1,6.95-31.04,5.12C726.76,1484.41,725.23,1484.74,723.23,1484.83z M989.07,452.1c-0.44-0.47-0.88-0.94-1.31-1.41
				c-3.52,0.86-7.02,1.83-10.57,2.55c-2.8,0.57-4.03,2-3.42,4.84c2.35,10.99,4.35,22.08,7.25,32.93c1.15,4.29,4.29,8.04,6.53,12.04
				c0.53,0,1.07,0,1.6,0c3.38-7.67,6.75-15.33,9.79-22.22c-2.48-2.35-4.82-4.61-7.21-6.81c-0.95-0.88-2.16-2.42-3.03-2.28
				c-3.86,0.64-4.06-1.66-4.22-4.39c-0.12-1.95-0.24-3.99-0.89-5.8c-1.04-2.91-0.19-4.65,2.39-6.14
				C987.22,454.68,988.05,453.22,989.07,452.1z M41.65,1327.15c0.69-0.25,1.38-0.5,2.07-0.74c-1.1-5.11-2.2-10.22-3.32-15.39
				c2.98-1.2,6.94,1.14,10.32-2.39c2.46-2.56,6.54-3.55,9.76-5.17c-1.57-2.33-2.78-4.14-4.16-6.18c6.29-3.03,10.51-0.26,14.6,3.04
				c6.83-3.93,6.84,6.03,11.67,6.19c-0.61-3.62-1.18-6.94-1.74-10.26c-1.22-1.55-3.82-2.59-6.04-4.16c-1.83-1.29-3.2-3.23-4.82-4.83
				c-1.01-1-2.12-1.89-3.19-2.83c0.93-1.04,1.76-2.19,2.8-3.1c1.25-1.1,2.69-1.99,4.04-2.97c-1.09-0.26-1.82,0.05-2.49,0.43
				c-5.7,3.24-11.2,6.91-17.12,9.66c-12.33,5.71-18.48,17.04-15.45,29.96C39.28,1321.4,40.61,1324.24,41.65,1327.15z M954.7,831.02
				c0.6-0.4,1.2-0.8,1.81-1.21c0.5-12.34,1.01-24.68,1.52-37.21c-10.67,2.36-20.8,4.05-29.5,12.56
				C937.29,813.81,946,822.41,954.7,831.02z M46.33,1013.97c-1.72-0.65-3.4-1.42-5.16-1.91c-3.67-1.03-7.39-1.88-11.06-2.89
				c-0.34-0.09-0.53-0.76-0.84-1.25c0.62-1.17,1.22-2.31,1.95-3.69c-9.53,1.11-17.12,5.64-24.55,10.79
				c-4.31,2.99-5.73,6.48-3.89,11.77c8.39-2.69,16.68-2.35,25.02,0.06c1.27,0.37,3.28-0.38,4.44-1.27
				C36.78,1022.08,41.12,1018.3,46.33,1013.97z M1090.84,1352.36c0.2-0.67,0.4-1.34,0.61-2.01c-0.67-1.84-1.55-3.62-1.98-5.51
				c-2.07-9.21-12.94-18.47-22.53-19.15c-4.05-0.29-7.64,0.14-9.5,4.5c-1.88,4.39,0.28,7.78,3.39,9.97
				c5.66,3.98,11.58,7.76,17.86,10.58C1082.26,1352.34,1086.76,1351.89,1090.84,1352.36z M1127.6,1344.08
				c3.98-1.68,7.65-3.22,11.31-4.78c4.02-1.71,4.65-4.84,2.43-8.06c-1.39-2.01-3.74-3.9-6.05-4.63c-10.07-3.19-30.41,4.61-35.91,13.7
				c-0.96,1.59-1.53,4.5-0.7,5.81c1.06,1.67,4.05,3.57,5.6,3.14c2.49-0.69,5.05-2.8,6.58-5.01
				C1115.41,1337.67,1123.72,1337.35,1127.6,1344.08z M380.45,325.98c-4.31-2.94-7.85-3.76-12.5-0.87c-5.19,3.24-11.2,5.2-16.93,7.53
				c-1.65,0.67-3.57,0.66-5.35,1.06c-1.6,0.36-3.16,0.9-4.76,1.27c-5.44,1.24-10.83,2.91-16.34,3.53
				c-5.42,0.61-10.96,0.14-16.45,0.14c-6.69,0-13.38,0-20.07,0c1.12,2.17,2.6,3.95,4.19,4.06c9.07,0.6,18.3,1.91,27.22,0.85
				c10.93-1.31,21.53-4.6,32.75-1.5c3.84,1.06,7.63-0.78,9.39-4.88c1.56-3.65,4.16-4.98,8.07-5.46
				C373.65,331.22,378.62,331.61,380.45,325.98z M1142.31,1299.35c0.17-6.44-1.23-10-5.89-11.69c-4.97-1.81-7.29,1.53-9.69,4.79
				c-1.86,2.53-3.99,4.91-5.49,7.64c-1.73,3.12-3.23,6.46-4.22,9.88c-1.16,3.97,1.38,7.13,4.46,8.56c2.29,1.06,6.27,1.11,8.23-0.24
				C1136.88,1313.35,1141.7,1306.55,1142.31,1299.35z M317.4,776.11c-0.79,18.48-1.32,36.72-2.43,54.92
				c-0.66,10.76-2.21,21.47-3.37,32.21c-1.66,15.33-2.62,30.79-5.21,45.96c-3.01,17.65-8.16,34.69-23.21,46.59
				c-1.11,0.88-1.4,2.8-2.22,4.57c1.29,0,1.68,0.12,1.93-0.02c0.87-0.47,1.7-1.02,2.53-1.56c7.78-5.05,12.58-12.44,14.99-21.09
				c3.51-12.61,6.88-25.32,9.25-38.19c3.39-18.44,7.08-36.97,8.35-55.62c1.42-21.02,0.31-42.21,0.24-63.33
				C318.24,779.12,317.72,777.69,317.4,776.11z M1058.15,1152.6c-0.26,4.19,3.04,7.58,9.8,10.95c10.02,5.01,20,10.14,29.67,15.77
				c8.72,5.07,16.99,10.9,25.5,16.34c7.62,4.86,8.14,4.76,15.53-2.97c-7.62-3.56-14.97-6.7-22.05-10.36
				c-7.65-3.95-15.1-8.3-22.56-12.62c-2.89-1.67-5.41-3.8-8.92-0.98c-0.81,0.65-3.21,0.13-4.37-0.63
				C1073.19,1163.09,1065.76,1157.86,1058.15,1152.6z M1161.99,854.02c-0.35-0.12-0.7-0.24-1.05-0.36c-0.34,0.32-0.82,0.57-1,0.96
				c-5.87,12.67-11.98,25.2-21.1,35.94c-10.61,12.5-19.18,27.44-37.1,30.89c-0.42,5.4-0.81,10.26-1.19,15.13
				c0.43,0.24,0.86,0.48,1.3,0.72c7.95-7.4,16.74-14.1,23.63-22.38c9.08-10.9,16.88-22.91,24.81-34.73
				c3.58-5.34,6.32-11.29,9.08-17.13C1160.68,860.26,1161.14,857.05,1161.99,854.02z M548.2,1067.89
				c-6.72,2.73-13.11,5.21-19.39,7.96c-1.06,0.46-2.22,2.06-2.28,3.19c-0.27,5.13-0.55,10.31-0.02,15.39
				c0.22,2.11,2.06,5.14,3.87,5.78c5.51,1.97,14.54-3.34,15.51-9.23C547.17,1083.22,547.5,1075.3,548.2,1067.89z M538.8,1244.41
				c1.07-3.09,2.97-6,2.87-8.83c-0.18-5.01-1.9-9.94-2.39-14.95c-0.71-7.22-9.69-15.4-17-15.58c-3.53-0.09-7.08-1.23-10.55-2.14
				c-2.41-0.64-4.7-1.7-7.04-2.6c-3.16-1.22-7.25,1.08-9.89-2.77c-0.35-0.52-3.2,0.44-4.74,1.07c-0.7,0.29-1.03,1.46-1.53,2.22
				c1.22,0.55,2.43,1.55,3.65,1.56c2.71,0.02,5.69-1.31,8.08-0.56c6.8,2.13,12.73,6.82,20.28,6.92c0.42,0.01,1,0.38,1.23,0.75
				c3.32,5.27,7.9,10.22,9.44,15.98c1.07,4.01,3.69,9.23,0.22,13.81c-1.19,1.56-2.42,3.09-4,5.12
				C531.33,1244.41,534.85,1244.41,538.8,1244.41z M273.74,1426.35c-1.23,1.4-2.72,3.83-4.87,5.22c-1.03,0.66-3.34-0.66-4.88-1.04
				c-2.17,4.05-0.48,6.76,3.65,8.31c-0.77,1.65-1.43,3.07-2.1,4.51c2.83,0.27,6.21,1.64,7.61,0.53c3.37-2.68,6.26-2.24,9.46-0.38
				c2.13-6.42,3.54-7.03,10.13-4.25c1.57-4.33,4.14-6.96,8.77-3.01c1.88-2.68,3.57-5.06,5-7.11c-2.75-3.35-9.68-0.68-9.87-7.8
				c-2.75,2.39-5.19,4.26-7.34,6.44c-1.85,1.87-3.19,2.54-5.27,0.19c-0.99-1.12-2.86-1.76-4.43-2
				C277.89,1425.71,276.06,1426.17,273.74,1426.35z M1077.02,1289.91c-0.21,0.91-0.64,1.85-0.6,2.77
				c0.36,8.58,6.53,14.48,10.16,21.55c0.43,0.83,1.94,1.22,3.02,1.57c5.7,1.86,11.42,3.64,17.1,5.44
				C1108.56,1308.32,1093.96,1292.63,1077.02,1289.91z M1128.62,839.88c5.12-6.34,5.06-7.07-0.38-11.68
				c-1.63-1.39-3.84-2.54-4.69-4.33c-3.99-8.41-11.87-11.34-19.88-15.15c-0.28,6.09-0.61,11.69-0.77,17.29
				c-0.11,3.82,5.06,8.91,8.42,7.5c3.91-1.64,6.07,0.41,8.82,1.94C1122.86,836.97,1125.66,838.35,1128.62,839.88z M30.7,1097.04
				c0.41-8.22,0.51-16.15,1.28-24.02c0.86-8.83,2.6-17.25,10.21-23.5c4.33-3.56,3.72-9.6-0.39-13.56c-2.51-2.42-3.14-4.65-1.14-7.81
				c1.69-2.67,2.71-5.77,4.33-9.36c-5.25,1.68-7.56,4.48-8.08,8.94c-0.53,4.44-1.31,8.86-2.02,13.28
				c-0.97,6.06-1.52,12.26-6.26,16.96c-0.57,0.57-0.85,1.7-0.81,2.55c0.14,2.75,1.07,5.57,0.66,8.2c-1.03,6.71-2.76,13.32-3.92,20.02
				C23.56,1094.57,26.83,1098.08,30.7,1097.04z M535.73,706.11c0.61-0.26,1.26-0.42,1.78-0.78c12.75-8.61,26.37-14.68,42.12-14.73
				c0.93,0,1.95-0.43,2.78-0.92c4.81-2.85,9.57-5.79,14.34-8.69c-0.11-0.41-0.22-0.83-0.33-1.24c-8.49,1.76-17.04,3.3-25.45,5.36
				c-4.81,1.18-9.4,3.26-14.13,4.81c-2.31,0.76-4.45,0.84-3.15-3.25c-3.58,0.94-6.49,1.57-9.31,2.47
				C533.31,692.67,532.34,694.59,535.73,706.11z M1131.75,558.85c0.65,0.12,1.31,0.24,1.96,0.36c0.54-0.81,1.41-1.57,1.55-2.45
				c1.13-7.01,2.27-14.02,3.07-21.07c0.35-3.03-0.35-6.17-0.11-9.22c0.28-3.58,0.71-6.31-3.73-8.37c-3.94-1.83-8.19-4.83-8.37-10.24
				c-0.09-2.81,0.37-5.64,0.58-8.46c-0.29-0.12-0.58-0.24-0.86-0.36c-3.05,8.76-6.1,17.53-9.14,26.25
				c7.78-1.44,11.49,1.42,11.75,9.31c0.08,2.33,0.32,3.91,3.14,4.75c1.31,0.39,2.82,3.2,2.68,4.78
				C1133.83,549.08,1132.65,553.95,1131.75,558.85z M57.51,435.68c-2.3,1.53-4.64,3.02-6.9,4.6c-5.15,3.61-10.24,7.3-15.39,10.91
				c-2.84,1.99-5.8,3.82-8.63,5.82c-6.75,4.76-13.34,9.76-20.23,14.31c-4.89,3.23-5.26,3.48-3.31,9.68c2.13-1.43,4.16-2.81,6.21-4.16
				c9.92-6.55,19.46-13.82,29.89-19.42C48.27,452.52,53.6,445.1,57.51,435.68z M181.98,944.91c-4.65-8.56-4.66-8.73-12.4-7.95
				c-10.2,1.02-16.87,8.5-24.58,14.02c-0.97,0.7-1.5,2.02-2.13,2.92c2.67,3.79,5.12,7.27,7.76,11.03c2.51-3.81,4.38-7.59,7.13-10.54
				c2.24-2.4,5.32-4.44,8.42-5.54C171.12,947.09,176.38,946.25,181.98,944.91z M661.04,1489.61c-0.24,0.45-0.48,0.9-0.71,1.34
				c-3.13-0.65-6.53-0.72-9.33-2.07c-6.36-3.06-12.73-3.38-19.34-1.54c-5.26,1.46-9.27,6.24-9.11,10.78c14.15,0,28.3,0,43.22,0
				C664,1494.94,662.52,1492.28,661.04,1489.61z M840.17,524.03c0.3,0.1,0.6,0.21,0.9,0.31c1.22-4.51,2.47-9.01,3.66-13.53
				c0.99-3.77,1.48-7.73,2.94-11.29c3.16-7.76,6.8-15.32,10.25-22.96c-0.45-0.48-0.91-0.96-1.36-1.44c-1.86,1.29-4.19,2.22-5.49,3.94
				c-3.19,4.21-5.74,8.75-11.14,10.94c-1.85,0.75-4.12,3.88-3.93,5.68C836.99,505.17,838.7,514.58,840.17,524.03z M1073.07,1292.63
				c-2.35,4.8-1.94,11.24,1.8,19.8c5.08,11.63,15.05,16.37,26.7,18.25c1.73,0.28,3.85-1.77,5.78-2.75c-0.97-1.58-1.64-3.5-2.98-4.65
				c-1.59-1.37-3.68-2.73-5.68-2.96c-9.75-1.1-16.66-5.3-19.71-15.23C1077.62,1300.68,1075.02,1296.66,1073.07,1292.63z
				M186.48,1253.17c0.74,2.73,1.81,4.96,1.88,7.22c0.21,6.98,0.58,14.03-0.15,20.95c-0.58,5.53,2.99,6.12,6.38,6.75
				c4.55,0.85,6.4-1.18,6.89-6.09c1.05-10.45-2.31-19.91-6.58-29.84c0.96,11.43,1.86,22.23,2.76,33.04
				c-0.56,0.06-1.13,0.12-1.69,0.18c-1.18-11.82-2.36-23.64-3.62-36.34C189.67,1250.92,187.77,1252.26,186.48,1253.17z
				M573.97,1071.53c11.99-10.12,23.23-19.6,34.46-29.08c-2.88-0.5-5.66-0.52-7.82,0.63c-10.65,5.68-21.14,11.66-31.68,17.56
				c-0.22,0.12-0.25,0.58-0.48,1.17C570.07,1064.65,571.77,1067.65,573.97,1071.53z M1160.91,824.94
				c-4.29,13.53-7.38,27.42-13.09,40.13c-5.76,12.81-14.11,24.44-21.33,36.59c11.17-11.71,20.14-24.82,27.7-39.21
				C1160.49,850.45,1162.81,838.35,1160.91,824.94z M855.69,440.73c0.67-0.2,1.35-0.39,2.02-0.59c1.1-10.02,2.25-19.97-3.35-29.52
				c-3.44-5.87-3.19-6.01-8.01-1.92c-0.25,0.21-0.58,0.32-0.87,0.48c-3.35,1.9-4.12,4.81-2.45,8.03
				C847.12,425.13,851.45,432.9,855.69,440.73z M467.77,1034.37c0.55,2.73,1.22,5.45,1.63,8.2c1.42,9.48,5.59,17.47,13.43,23
				c3.2,2.25,7.32,3.18,11.03,4.72c0.81-3.6,1.67-7.2,2.4-10.82c0.15-0.74-0.16-1.58-0.2-1.99
				C482.66,1054.17,471.67,1048.67,467.77,1034.37z M785.21,513.63c-0.91,0.8-1.44,1.42-2.09,1.8c-5.58,3.27-7.73,10.7-15.14,11.97
				c-3.06,0.52-3.32,3.27-1.25,5.75c3.19,3.83,6.14,7.89,9.61,11.43c3.52,3.59,4.52,7.57,3.48,12.28c-1.03,4.69-2.33,9.33-3.07,14.05
				c-0.32,2.08,0.46,4.34,0.74,6.52c0.83,0.01,1.67,0.01,2.5,0.02c1.26-8.94,2.64-17.86,3.63-26.82c0.13-1.19-1.58-2.73-2.67-3.89
				c-2.49-2.66-5.16-5.15-7.69-7.78c-2.15-2.23-1.9-4.06,0.48-6.28c2.06-1.92,3.35-4.66,5.09-6.96c0.95-1.25,1.93-2.95,3.24-3.34
				C786.99,520.9,786.86,517.91,785.21,513.63z M293.19,887.46c0.54,0.05,1.07,0.11,1.61,0.16c0.9-6.33,2.18-12.62,2.62-18.98
				c1.12-16.25,1.8-32.52,2.8-48.77c0.21-3.37-0.86-4.92-4.6-4.7C294.8,839.38,293.99,863.42,293.19,887.46z M607.21,1487.12
				c2.47-4.68,4.81-9.11,7.33-13.89c-15.66,1.99-25.58,9.51-28.73,24.73c3.19,1.08,5.31,0.42,6.84-2.85
				c1.09-2.35,2.85-4.55,4.77-6.31C599.85,1486.57,602.46,1483.75,607.21,1487.12z M972.23,650.91c-0.96-41.31-3-82.44,4.54-124.23
				c-5.5,8.57-6.73,17.36-6.81,26.15c-0.19,20.63,0,41.27,0.42,61.89C970.62,626.79,971.58,638.84,972.23,650.91z M309.93,1287.32
				c0.22-0.34,0.44-0.67,0.66-1.01c-3.98-3.14-7.96-6.28-12.96-10.23c2.28-1.29,3.98-2.25,5.69-3.21c-4.4-1.14-7.53-6.14-11.81-2.04
				c-0.16,0.15-1.28-1.05-1.96-1.05c-1.67-0.01-3.35,0.3-5.03,0.49c0.48,1.46,0.91,2.95,1.46,4.38c0.6,1.55,1.22,3.12,2.02,4.57
				c0.9,1.63,1.77,4.19,3.1,4.52C297.29,1285.29,303.63,1286.19,309.93,1287.32z M222.8,1264.4c3.03-0.39,4.61-6.23,7.22-1.1
				c1.42,2.79,2.65,5.3,6.01,4.45c3.98-1.01,0.59-4.24,1.34-6.32c0.05-0.14-0.33-0.42-0.25-0.32c-1.56,0.7-2.99,1.34-4.42,1.98
				c-0.89-2.14-1.78-4.27-2.79-6.7c-0.99-0.58-3.47-1.18-4.14-2.62c-1.62-3.52-2.39-7.43-3.86-12.37c-2,7.41-2.42,7.8-10.58,10.43
				c6.32-0.22,3.79,3.51,3.6,6.07C217.69,1260.16,220.34,1262.36,222.8,1264.4z M1109.06,1309.47c1.23-0.8,3.51-2.27,5.79-3.74
				c-10.94-3.97-22.44-25.01-15.53-56.09c-1.79,4.29-3.47,8.71-4,13.26c-1.23,10.47-1.35,20.97,4.03,30.58
				C1102.43,1298.98,1105.89,1304.28,1109.06,1309.47z M34.08,1414.46c-0.08-0.57-0.17-1.15-0.25-1.72
				c-8.23,0.86-16.49,1.54-24.68,2.64c-7.97,1.07-8.28,1.78-6.31,10.1c6.34-1.29,12.67-2.59,19.59-4c-1.59-1.68-2.37-2.5-3.83-4.05
				C24.35,1416.32,29.22,1415.39,34.08,1414.46z M533.21,945.37c9.36-12.84,7.65-16.96,5.2-35.91c-1.87,6.24-4.21,11.71-5.01,17.39
				C532.56,932.78,533.21,938.92,533.21,945.37z M1037.81,801.15c0.46-0.03,0.92-0.07,1.38-0.1c1.18-8.97,2.36-17.94,3.53-26.91
				c0.85-6.46,0.24-7.2-7.21-8.2C1036.29,777.75,1037.05,789.45,1037.81,801.15z M1035.55,1380.64c-6.89-16.3-6.15-32.2-6.32-47.96
				c-0.47,0.02-0.94,0.03-1.42,0.05c0,16.42,0,32.83,0,49.52C1030.14,1381.77,1032.98,1381.18,1035.55,1380.64z M465.91,797.82
				c3.46,1.49,7.84,1.72,6.1,7.59c-0.24,0.81,1.07,2,1.42,3.09c1.4,4.34,3.23,8.62,3.94,13.07c1.94,12.27,3.4,24.63,5.05,36.95
				c0.11-11.01-0.1-22-1.69-32.79c-1.17-7.96-3.96-15.71-6.35-23.46C473.13,798.16,470.13,796.4,465.91,797.82z M1054.32,1347.09
				c9.22,3.7,17.52,7.03,25.91,10.4c0.38-0.73,1.01-1.91,0.94-1.79c-9.1-5.72-17.75-11.16-26.26-16.51
				C1054.74,1341.42,1054.49,1344.81,1054.32,1347.09z M708.22,1414.97c-10.96-10.13-23.59-14.25-37.94-11.85
				C682.92,1407.07,695.57,1411.02,708.22,1414.97z M1132.52,911.1c-0.13,0.14,0.46-0.4,0.95-1.04c8.64-11.31,17.47-22.5,25.75-34.07
				c1.88-2.63,1.87-6.62,3.05-11.26C1151.59,881.38,1141.81,896.63,1132.52,911.1z M826.72,1428.87c-0.78-2.68-1.38-4.76-1.96-6.73
				c-3.74,4.06-5.87,3.83-8.52-1.23c-1.16-2.22-2.08-4.57-3.23-7.11c-2.02,3.78-3.94,7.36-6.17,11.53
				C813.53,1426.51,819.8,1427.63,826.72,1428.87z M984.79,1075.9c1.44,7.21,2.78,13.98,4.13,20.76c0.35-0.08,0.7-0.16,1.04-0.25
				c0.48-7.13,0.96-14.25,1.47-21.7C989.3,1075.1,987.24,1075.47,984.79,1075.9z M1026.09,1237.25c0.75,0.15,1.5,0.3,2.25,0.45
				c0.99-5.61,2.56-11.19,2.85-16.84c0.52-10.24,0.29-20.53,0.36-30.79c0.02-3.41,0-6.83,0-10.24c-0.52,0-1.04,0.01-1.56,0.01
				c0,12.25,0.07,24.51-0.05,36.76c-0.03,2.76-0.56,5.55-1.09,8.28C1028.04,1229.03,1027.02,1233.13,1026.09,1237.25z
				M552.25,1068.47c-1.21,9.65-2.47,19.78-3.65,29.16C554.43,1094,556.52,1079.79,552.25,1068.47z M540.39,1244.68
				c4.58-0.27,7.72-0.46,11.27-0.67c-3.38-5.92-6.26-10.96-9.14-16c-0.39,0.15-0.79,0.31-1.18,0.46
				C544.08,1233.67,544.84,1238.88,540.39,1244.68z M959.89,957.79c-0.32-0.05-0.64-0.09-0.95-0.14c-1.8,8.57-3.6,17.13-5.51,26.24
				c0.06,0.02,1.33,0.48,3.6,1.29C958.04,975.54,958.96,966.67,959.89,957.79z M166.72,1287.01c0-8.38,0-16.37,0-24.36
				c-0.5-0.01-1-0.02-1.5-0.02c0,4.72,0,9.43,0,14.15c-0.38,0.04-0.76,0.08-1.13,0.12c-0.94-1.48-1.87-2.96-3.28-5.18
				c0.82,3.8,1.48,6.67,2.06,9.57C163.98,1286.87,163.96,1286.87,166.72,1287.01z M1112.67,1326.5c5.53-5.99,2.64-11.71,1.1-17.66
				c-0.89,0.48-2.46,0.97-2.44,1.37C1111.63,1315.44,1112.15,1320.65,1112.67,1326.5z M134.85,1277.92c-2.46,0.79-4.06,1.3-5.66,1.82
				c2.02,0.72,4.53,0.97,5.42-0.11c2.61-3.18,2.37-7.01,0.13-10.39c-1.4-2.12-3.38-3.85-5.1-5.76c-0.35,0.37-0.71,0.75-1.06,1.12
				C130.63,1268.96,132.68,1273.31,134.85,1277.92z M162.43,1268.53c1.25-16.54-0.27-15.44-7.56-17.57c0.46,1.44,0.89,2.79,1.36,4.25
				c0.43-0.8,0.78-1.44,1.65-3.02C159.56,1258.23,161,1263.38,162.43,1268.53z M1161.69,890.8c-0.39-0.14-0.78-0.28-1.17-0.41
				c-4.27,6.58-8.53,13.15-12.8,19.73c0.34,0.22,0.68,0.44,1.02,0.66C1152.4,903.69,1159.72,898.97,1161.69,890.8z M204.08,945.66
				c-0.12-0.63-0.24-1.25-0.36-1.88c-4.02,1.35-8.05,2.71-11.82,3.97C200.16,951.93,200.16,951.93,204.08,945.66z M241.31,1267.43
				c2.76,0,5.81,0,9.5,0c0.66-1.75,1.54-4.09,2.45-6.51C248.61,1263.46,244.85,1265.5,241.31,1267.43z M1033.84,1063.61
				c0.5,4.3,0.92,7.88,1.35,11.58c1.26-4.43,2.49-8.75,3.96-13.93C1036.75,1062.32,1035.17,1063.02,1033.84,1063.61z M208.74,1269.61
				c-0.51-0.11-1.02-0.23-1.53-0.34c-1.6,5.47-3.21,10.94-4.81,16.41c0.58,0.17,1.17,0.34,1.75,0.51
				C205.67,1280.67,207.2,1275.14,208.74,1269.61z M5.48,1364.02c0.6-0.18,1.2-0.37,1.8-0.55c-1.4-4.73-2.79-9.46-4.19-14.2
				c-0.65,0.2-1.3,0.4-1.96,0.6C2.58,1354.59,4.03,1359.31,5.48,1364.02z M275.58,927.01c-2.23-3.52-4.38-6.9-7.16-11.29
				C268.57,925.05,269.31,926.05,275.58,927.01z M208.68,1247.72c-0.63-0.02-1.27-0.03-1.9-0.05c-0.32,5.43-0.64,10.86-0.96,16.3
				c0.35,0.02,0.7,0.04,1.05,0.06C207.48,1258.6,208.08,1253.16,208.68,1247.72z M184.9,1280.21c0.45,0.02,0.9,0.04,1.35,0.06
				c0-6.51,0-13.01,0-19.96C185.78,1267.25,185.34,1273.73,184.9,1280.21z M1086.9,917.12c-0.31-0.03-0.61-0.06-0.92-0.09
				c-0.79,8.51-1.59,17.01-2.38,25.52c0.27,0.02,0.53,0.05,0.8,0.07C1085.23,934.13,1086.06,925.63,1086.9,917.12z M783.27,1420.25
				c-0.17-0.52-0.34-1.04-0.52-1.56c-3,0.96-5.99,1.92-10.03,3.21C778.99,1427.1,780.2,1420.99,783.27,1420.25z M805.12,1478.48
				c3.81-0.58,7.62-1.15,11.41-1.73C812.01,1470.06,808.79,1477.28,805.12,1478.48z M92.46,1341.24c0.89,2.19-1.12,7.03,4.93,5.78
				c-0.98-3.06-1.85-5.75-2.72-8.44c-0.2,0.06-0.4,0.13-0.6,0.19c0.44,2.2,0.88,4.39,1.33,6.59c-0.38,0.09-0.76,0.18-1.14,0.27
				C93.66,1344.16,93.06,1342.7,92.46,1341.24z M88.56,1348.28c0.6-0.28,1.21-0.57,1.81-0.85c-2.3-3.73-4.59-7.45-6.89-11.18
				c-0.36,0.22-0.71,0.43-1.07,0.65C84.47,1340.69,86.51,1344.49,88.56,1348.28z M203.62,1271.15c0.45-0.09,0.9-0.19,1.35-0.28
				c-1.57-4.63-3.14-9.26-4.71-13.88c-0.33,0.11-0.67,0.22-1,0.34C200.71,1261.94,202.17,1266.54,203.62,1271.15z M828.35,1417.53
				c3.71-1.2,6.82-2.74,4.6-8.07C831.27,1412.41,829.82,1414.96,828.35,1417.53z M201.6,1256.87c1.19-3.07,1.99-5.13,2.97-7.64
				c-1.78,0.48-3.22,0.86-5.07,1.36C200.2,1252.69,200.79,1254.48,201.6,1256.87z M393.18,889.1c-0.62-0.32-1.25-0.63-1.87-0.95
				c-1.12,2.4-2.25,4.79-3.37,7.19c0.53,0.27,1.06,0.55,1.59,0.82C390.74,893.81,391.96,891.45,393.18,889.1z M129.12,1475.29
				c-0.32,0.69-0.65,1.37-0.97,2.06c1.93,0.78,3.85,1.57,5.78,2.35c0.15-0.57,0.54-1.57,0.4-1.66
				C132.64,1477.05,130.87,1476.18,129.12,1475.29z M373.19,1201.27c0.35-0.32,0.71-0.64,1.06-0.96c-1.72-1.79-3.45-3.58-5.17-5.37
				c-0.34,0.32-0.67,0.64-1.01,0.96C369.78,1197.7,371.48,1199.49,373.19,1201.27z M955.4,255.61c-0.11,0-0.22,0.01-0.32,0.01
				c0,5.05,0,10.09,0,15.14c0.11,0,0.22-0.01,0.32-0.01C955.4,265.7,955.4,260.65,955.4,255.61z M853.65,938.51
				c0.37-0.1,0.75-0.2,1.12-0.3c-1.59-3.92-3.18-7.85-4.76-11.77c-0.29,0.12-0.59,0.24-0.88,0.36
				C850.63,930.7,852.14,934.61,853.65,938.51z M81.97,1351.33c-1.74-2.53-3.05-4.46-4.37-6.38
				C77.97,1347.54,76.17,1351.49,81.97,1351.33z M2.26,1368.92c0.69-0.02,1.38-0.04,2.07-0.06c-0.3-2.28-0.61-4.57-0.91-6.85
				c-0.38,0.05-0.77,0.1-1.15,0.14C2.26,1364.41,2.26,1366.67,2.26,1368.92z"/>
			<path onClick={() => props.onFill(0)} fill={props.fillColors[0]} d="M1162.5,1422.54c-1.4,1.13-2.79,2.26-3.06,2.47c-3.13,1.48-5.01,2.65-7.05,3.25
				c-1.95,0.57-4.29,1.2-6.09,0.63c-3.62-1.14-4.49,0.56-4.86,3.46c-0.31,2.41-0.63,4.81-0.94,7.22c0,0,0.05-0.05,0.05-0.05
				c-4.13,1.41-8.74,2-8.13,8.43c0.34,3.64-3.04,3.77-5.79,3.72c-7.52-0.13-7.36-0.11-9.32,7.08c-0.4,1.48-2.31,3.06-3.87,3.57
				c-1.97,0.65-4.41-0.18-6.41,0.43c-3.71,1.12-8.92,1.81-10.53,4.48c-4.66,7.73-12.43,9.58-19.63,12.52
				c-4.55,1.86-9.57,2.59-14.39,3.74c-5.14,1.22-8.85,6.73-9.79,14.91c-24.09,0-48.22,0.03-72.35-0.07
				c-1.34-0.01-3.02-0.63-3.95-1.56c-6.02-5.98-11.87-12.12-18.01-18.45c0.48-1.51,1.1-3.48,1.91-6.04c5.88,7.88,6.32-0.65,9.26-2.44
				c2.3,0.77,4.88,2.39,5.39-2.37c0.13-1.26,2.88-2.33,4.52-3.31c1.15-0.69,2.47-1.09,3.97-1.73c3.35,2.88,3.74,2.76,5.82-1.99
				c3.42,3.47,6.58,7.63,10.68,10.39c1.87,1.26,5.61-0.24,8.67-0.5c-0.36,6.81,8.05,2.99,10.23,7.74c0.43-0.59,0.86-1.17,1.3-1.76
				c-2.3-1.69-4.61-3.37-6.91-5.06c-0.29,0.3-0.59,0.59-0.88,0.89c-0.34-1.21-0.38-2.64-1.07-3.59c-1.43-1.99-3.01-3.54-5.63-0.97
				c-1.19,1.17-3.34,1.38-5.06,2.02c-0.2-0.44-0.4-0.88-0.6-1.32c0.58-1.14,1.16-2.27,1.66-3.26c-2.52-0.78-4.75-1.35-6.87-2.2
				c-0.84-0.34-2.06-1.42-1.97-1.99c0.17-1.06,1.02-2.12,1.84-2.94c1.24-1.23,2.7-2.25,4.31-3.57c2.36,2.61,5.04,4.42,6.9-1.31
				c0.17-0.53,2.5-0.73,3.69-0.47c4.09,0.89,5.88-0.79,5.25-4.97c-0.44-2.92-0.58-5.89-0.99-10.3c4.01-5.07,4.01-5.07-1.97-11.03
				c0.39-0.27,1.04-0.79,1.08-0.75c3.78,4.25,8.12,2.58,12.4,1.17c0.87-0.28,1.79-0.71,2.42-1.33c3.55-3.46,7.15-6.89,10.45-10.58
				c1.22-1.37,1.65-3.44,2.44-5.19l-0.01,0.02c1.41-2.36,2.83-4.73,4.24-7.09c-2.18-1.04-4.29-2.74-6.55-2.98
				c-4.55-0.48-5.05-4.97-5.05-6.91c-0.01-2.73-0.87-3.26-2.66-4.3c-1.11-0.65-1.81-2.01-3.32-3.78c2.85-0.29,4.46-0.27,5.98-0.63
				c4.7-1.12,5.59-4.15,2.65-7.88c-0.78-0.99-0.83-2.55-1.31-4.19c6.6,1.97,7.63,1.38,8.6-4.4c6.2,1.04,6.12,0.45,6.85-5.14
				c0.49-3.76,3.04-7.43,5.36-10.64c0.62-0.86,3.99-0.41,5.7,0.33c2.88,1.25,4.98,4.56,8.88,2.75c0.46-0.21,1.57,0.44,2.09,0.97
				c4.3,4.44,6,4.64,11.16,2.36c4.26-1.89,8.75-3.27,13.16-4.81c0.73-0.25,1.67-0.38,2.38-0.16c8.43,2.68,11-4.14,14.87-8.99
				c0.72-0.9,2.03-1.7,3.16-1.84c1.89-0.23,3.85,0.11,6.87,0.27c-0.24-0.41,0.19,0.18,0.47,0.83c2.25,5.08,8.64,6.91,13.34,3.79
				c3.01-2,8.9-2.28,11.49-0.55c3.38-2.55,7.26-3.81,12.39-3.91c0,17.63-0.04,35.25,0.05,52.88c0.01,2.34,0.69,4.68,1.06,7.02
				C1162.5,1409.21,1162.5,1415.88,1162.5,1422.54z M1099.16,1387.97c0.08,5.89,9.25,17.05,14.18,17.01
				c-0.45,7.42,7.66,6.58,10.75,10.91c0.18,0.25,1.69-0.04,2.19-0.5c2.29-2.16,4.52-4.4,6.59-6.77c0.47-0.54,0.61-2.2,0.23-2.5
				c-0.74-0.57-1.95-0.76-2.94-0.7c-0.74,0.04-1.43,0.74-2.32,1.24c-0.76-9.08-1.09-9.52-6.02-8.11c-0.14-1.44-0.28-2.84-0.41-4.18
				c-8.13-0.72-8.3-0.86-7.66-6.61c-6.73-0.83-9.04-2.97-8.2-8.86c-4.06,0.31-7.88,0.5-11.66,0.99c-0.58,0.08-1.27,1.51-1.4,2.4
				C1091.58,1388.02,1092.92,1389.2,1099.16,1387.97z"/>
			<path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M293.73,346.47c10.06-0.4,20.11-0.74,30.17-1.24c2.13-0.11,4.4-0.41,6.32-1.27
				c5.38-2.41,10.64-1.68,16.08-0.36c9.41,2.29,12.51,0.86,18-6.98c0.94-1.35,3.22-1.84,4.95-2.54c3.89-1.58,8.27-2.42,11.62-4.76
				c2.84-1.99,2.72-4.95-1.48-6.64c-9.17-3.69-18.38-5.94-28.5-6c-9.85-0.06-19.84-1.8-29.48-4.02c-7.6-1.76-14.87-1.97-22.07,0.37
				c-6.45,2.1-12.88,1.21-18.51-1c-9.07-3.57-17.5-2.52-26.24,0.16c-1.54,0.47-3.2,0.7-4.81,0.72c-9.46,0.12-18.92,0.28-28.38,0.13
				c-3.24-0.05-6.74-0.33-9.64-1.6c-6.98-3.04-13.73-2.71-20.9-0.78c-5.65,1.52-11.58,1.99-17.25,2.9
				c-3.18-11.02-5.76-12.49-16.11-11.56c-3.52,0.31-7.18-0.8-10.76-1.33c-3.1-0.46-6.61-2.22-9.19-1.3
				c-10.04,3.57-19.6,0.88-29.41-0.66c-5.53-0.87-11.29-0.84-16.89-0.47c-5.6,0.37-10.61,2.7-14.32,7.21c-3.3,4-7.18,4.71-12.42,3.95
				c-7.29-1.06-14.8-0.66-22.22-0.7c-13.21-0.08-26.42-0.02-39.93-0.02c0-102.33,0-204.38,0-306.86c386.06,0,772.12,0,1158.62,0
				c0,15.41,0,30.96,0,46.51c-15.38-1.4-25.72,5.1-31.14,20.13c-1.2,3.32-3.12,4.95-6.25,5.57c-5.38,1.07-10.8,1.93-16.16,3.06
				c-5.55,1.17-10.17,3.79-13.51,8.65c-1.16,1.68-3.13,2.87-4.89,4.06c-2.72,1.85-5.41,3.94-8.43,5.12
				c-2.26,0.88-5.14,1.07-7.54,0.57c-6.33-1.34-12.49-3.49-18.82-4.84c-21.86-4.69-41.92-1.13-59.59,13.32
				c-1.1,0.9-3.43,1.23-4.77,0.72c-10.67-4.05-20.21-3.38-28.04,5.87c-0.42,0.5-0.95,1.01-1.54,1.23c-9.27,3.42-18.43,7.22-27.9,9.95
				c-3.36,0.97-7.47-0.74-11.24-1.17c-7.87-0.89-15.76-2.39-23.63-2.31c-3.71,0.04-7.36,3.19-11.09,4.82
				c-1.97,0.86-4.05,2.06-6.08,2.06c-3.11,0.01-6.23-0.91-9.34-1.4c-6.08-0.96-12.02-1.97-17.9,1.57c-1.99,1.2-5.17,0.4-7.8,0.55
				c-5.25,0.3-10.5,0.75-15.75,0.9c-4.14,0.12-8.29-0.09-12.44-0.19c-6.15-0.15-12.33-0.82-18.43-0.4
				c-7.57,0.52-14.36,3.57-18.63,10.12c-2.12,3.25-3.87,2.53-6.54,1.43c-6.73-2.77-13.4-5.84-20.37-7.8
				c-8.98-2.52-17.01-0.15-23.71,6.69c-1.09,1.11-3.66,1.56-5.29,1.2c-6.56-1.44-12.62-2.27-19.35,0.82
				c-4.39,2.02-10.13,1.17-15.28,1.39c-6.45,0.28-12.5,0.95-16.93,6.72c-1.14,1.49-4.12,1.79-6.33,2.2c-4.73,0.88-9.77,0.78-14.2,2.4
				c-3.82,1.39-8.01,3.91-10.16,7.17c-2.9,4.38-6.5,6.61-11.16,7.42c-12.2,2.1-23.51,8.12-36.23,7.97c-2.05-0.02-4.31,0.81-6.13,1.86
				c-3.93,2.26-7.11,5.76-6.61,10.38c0.49,4.55,5.16,5.54,8.82,5.97c5.22,0.61,10.56,0.25,15.84,0.26
				c16.77,0.04,33.59-0.74,50.3,0.28c20.13,1.23,39.81-1.75,59.65-3.77c13.39-1.36,26.82-2.62,40.26-3.37
				c32.06-1.79,64.12-3.72,96.21-4.67c19.59-0.58,39.23,0.6,58.84,0.95c4.78,0.08,9.57,0.01,14.65,0.01
				c0.12,2.54,0.27,4.16,0.27,5.77c0.07,38.13,0.29,76.25,0.13,114.38c-0.18,40.27-0.73,80.55-1.11,120.82
				c-0.05,5.32-0.01,10.64-0.01,15.97c-0.45,0.31-0.9,0.62-1.35,0.93c-3.13-3.98-7.08-7.58-9.16-12.05
				c-2.02-4.34-4.49-7.32-8.52-9.82c-7.28-4.53-14.23-9.62-21.16-14.68c-5.1-3.72-10.82-3.97-16.63-3.34
				c-6.76,0.74-13.45,2.07-20.2,2.93c-1.92,0.24-3.94-0.19-5.9-0.46c-6.05-0.83-12.12-1.59-18.12-2.67
				c-4.78-0.86-9.19-0.5-13.42,2.08c-0.89,0.54-2.28,0.52-3.39,0.38c-12.66-1.57-25.28-3.56-37.97-4.69
				c-6.89-0.61-14.14-0.77-20.79,0.77c-3.91,0.9-7.82,4.97-10.21,8.61c-3.92,5.98-8.58,8.21-15.68,8.29
				c-6.83,0.07-13.63,2.09-20.45,3.18c-4.3,0.69-8.73,0.82-12.92,1.92c-7.63,2-11.51,8.52-16.22,14.14c-2.43,2.9-5.9,4.95-8.96,7.31
				c-3.4,2.63-6.82,5.24-10.3,7.74c-1.41,1.01-3.19,2.66-4.54,2.44c-10.31-1.69-17.67,2.75-23.64,10.56
				c-0.6,0.78-1.44,1.37-2.62,2.47c-4.78-4.34-10.36-3.65-16.48-3.36c-4.82,0.23-9.95-3.02-14.69-5.25
				c-2.96-1.39-5.28-4.14-8.24-5.58c-3.47-1.69-7.25-2.86-11.01-3.83c-5.74-1.48-11.71-2.18-17.3-4.05c-2.09-0.7-4.2-3.51-4.91-5.79
				c-3.01-9.71-11.48-12.8-19.27-16.79c-0.68-0.35-1.61-0.45-2.39-0.35c-6.33,0.78-6.68,0.62-9.94-5.56
				c11.03,0,21.77,0.13,32.52-0.04c11.14-0.17,22.29-1.04,33.42-0.78c4.96,0.12,9.85,2.33,14.8,3.45c3.86,0.88,7.78,2.21,11.67,2.18
				c12.96-0.1,25.93-0.67,38.89-1.16c8.14-0.31,16.27-0.77,24.4-1.22c6.26-0.35,12.53-0.7,18.78-1.22c4.27-0.36,8.79-0.31,12.69-1.8
				c2.42-0.93,5.11-4.24,5.38-6.74c0.24-2.2-2.24-6.23-4.32-6.87c-7.79-2.43-14.61-9.37-23.86-5.8c-0.52,0.2-1.53,0.15-1.81-0.19
				c-2.95-3.62-6.45-5.03-11.03-3.32c-0.44,0.16-1.37-0.64-1.92-1.17c-9.67-9.29-16.96-9.74-27.73-1.68
				c-1.05,0.78-2.12,1.53-3.45,2.49c-6.49-6.94-15.2-9.01-23.82-10.82c-2.77-0.58-6.01,1.09-9.29,1.78
				c-0.35-1.58-0.76-3.46-1.27-5.77c59.02-3.28,117.71-6.54,176.4-9.85c0.69-0.04,1.34-0.79,2.01-1.21
				c-3.97-6.24-10.43-9.22-17.16-6.89c-5.16,1.79-8.44,0.38-11.8-3.34c-4.11-4.55-9.34-6.34-15.64-6.23
				c-4.56,0.08-9.19-2.17-13.71-1.91c-6.11,0.35-11.04-1.48-16.22-4.38c-6.42-3.59-13.47-3.55-20.09-0.08
				c-2.34,1.23-3.74,1.03-5.62-0.67c-5.14-4.64-10.71-5.62-17.89-4.37c-7.15,1.24-14.81-0.62-22.26-0.85
				c-3.71-0.12-7.46,0.74-11.14,0.46c-2.43-0.19-5.08-1.18-7.09-2.58c-8.09-5.63-16.84-8.89-26.72-10.04
				c-10.56-1.23-19.05,1.63-25.61,9.91c-2.06,2.6-3.94,3.66-7.51,2.57c-5.88-1.81-11.99-2.96-17.6,1.4
				c-0.91,0.71-2.55,0.58-3.87,0.66c-10.63,0.61-20.02,4.09-27.86,11.56c-0.79,0.75-2.66,0.77-3.94,0.56
				c-7.21-1.19-12.12,1.01-16.03,7.12c-0.69,1.09-2.37,2.58-3.22,2.37c-7.74-1.88-14.89,0.39-22.36,1.89
				c-5.04,1.02-10.74-0.17-15.95-1.28c-9.94-2.12-19.79-4.91-29.99-2.34c-3.43,0.86-6.57,2.85-9.85,4.33
				c-1.31,0.59-2.65,1.71-3.93,1.65c-7.74-0.37-15.71-0.01-23.12-1.84c-8.35-2.06-16.18,0.33-24.23,0.27
				c-4.57-0.03-9.25-0.15-13.66-1.18c-2.28-0.53-4.12-3.09-6.08-4.82c-2.7-2.37-5.03-5.31-8.05-7.14c-6.82-4.11-8.18-3.48-11.18,3.57
				c-0.52,1.22-1.1,2.42-1.76,3.57c-4.03,7-4.68,6.94-11.7,3.47C302.28,356.64,296.21,353.19,293.73,346.47z"/>
			<path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M126.21,641.59c0.53-0.76,1.05-1.51,1.44-2.08c-6.39-5.4-10.63-0.39-15.11,2.77
				c-2.45,1.72-4.46,2.32-7.64,1.38c-2.92-0.86-6.57-0.4-9.59,0.49c-3.11,0.92-5.83,3.13-8.77,4.67c-4.53,2.36-8.95,5.05-14.49,2.81
				c-1.4-0.56-3.28,0.03-4.93,0.14c-5.37,0.37-10.74,0.74-16.1,1.16c-0.98,0.08-2.11,0.16-2.88,0.69c-7.38,5.04-15.01,2.34-22.44,0.5
				c-3.74-0.93-6.8-0.49-8.96,2.37c-3.59,4.73-6.71,9.83-10.06,14.75c-0.98,1.45-2.09,2.81-3.14,4.21c-0.39-0.21-0.77-0.42-1.16-0.63
				c0-23.7,0-47.39,0-71.39c29.59-1.94,56.37,6.23,81.15,22.16c0.25-0.46,0.5-0.92,0.75-1.39c-5.77-5.43-11.55-10.86-18.32-17.23
				c3.56,0.16,5.99,0.63,8.3,0.29c4.5-0.66,9.05-1.41,13.37-2.77c9.16-2.88,18.38-4.29,27.97-2.96c2.12,0.29,4.31,0.04,7.85,0.04
				c-18.09-7.1-34.97-12.4-53.42-8.8c26.24-8.19,53.22-8.68,80.3-8.25c0.08-0.56,0.15-1.11,0.23-1.67
				c-8.35-4.06-16.71-8.12-25.06-12.18c-0.01-0.37-0.01-0.73-0.02-1.1c4.21-0.93,8.41-1.87,12.62-2.8c0.11-0.46,0.22-0.93,0.33-1.39
				c-3.08-1.22-6.11-2.55-9.24-3.63c-6.03-2.08-6.97-5.92-2.59-10.94c1.27-1.45,2.51-2.92,4.45-5.17c-4.58-1.1-8.2-1.9-11.79-2.85
				c-3.8-1-6.09-3.18-7.2-7.3c-0.75-2.78-3.13-5.23-5.14-7.52c-1.96-2.24-4.78-3.8-6.43-6.2c-1.08-1.57-0.73-4.12-1.14-7.17
				c14.89,4.79,28.52,8.3,41.41,13.63c7.81,3.23,14.67,9,21.57,14.15c4.55,3.4,8.81,4.26,14.35,2.78c11.68-3.13,23.58-5.43,35.31-8.4
				c3.97-1.01,8.04-2.48,11.43-4.71c12.85-8.47,27.52-12.37,41.9-16.8c6.34-1.95,11.6-4.34,15.54-9.84c1.2-1.68,3.87-2.78,6.04-3.21
				c7.75-1.51,15.23-3.18,20.76-9.65c1.56-1.83,4.35-2.61,6.7-3.95c-4.85-4.39-10.3-8.26-14.32-13.27
				c-4.03-5.02-4.97-12.33-11.04-16.04c0.15-1.77,0.32-3.7,0.57-6.66c3.85,3.08,7.5,6.01,11.16,8.93c0.91,0.73,1.77,1.51,2.73,2.16
				c10.21,6.84,20.58,13.46,30.61,20.56c4.4,3.12,8.11,7.2,12.17,10.82c1.48,1.32,2.87,2.88,4.59,3.77
				c14.59,7.58,29.19,15.16,43.92,22.47c7.88,3.91,15.97,7.41,24.07,10.84c8.05,3.41,16.23,6.5,25.23,10.08
				c-7.04,4.27-13.8,8.32-20.5,12.46c-5.07,3.14-10.51,5.87-14.98,9.73c-10.99,9.48-24.44,12.35-37.92,15.38
				c-1.3,0.29-2.59,0.58-3.87,0.95c-0.42,0.12-0.75,0.52-1.09,0.77c0.08,0.44,0.03,0.88,0.22,1.05c2.11,1.96,6.03,3.9,6.02,5.82
				c-0.02,4.12-1.68,8.39-3.36,12.31c-3.25,7.58-6.83,15.03-10.62,22.35c-0.64,1.24-3.08,2.34-4.57,2.21
				c-10.36-0.95-11.07-0.61-15.23,9.02c-4.95,11.48-9.78,23.03-15.04,34.37c-2.44,5.25-5.81,10.06-8.68,15.12
				c-6.2,10.91-16.65,16.73-27.07,22.52c-3.12,1.74-6.55,2.94-10.35,4.61c1.03-7.85,6.38-12.18,10.82-17.38
				c-8.41,1.83-16.58,3.69-23.7,8.77c-2.73,1.95-6.04,3.1-9.13,4.53c-5.56,2.57-11.19,4.97-16.72,7.62
				c-3.59,1.72-7.01,3.79-10.56,5.61c-3.33,1.71-6.6,3.54-8.86-1.74c-0.42-0.97-3.28-1.48-4.86-1.23c-2.23,0.35-4.64,1.2-6.41,2.56
				c-10.28,7.88-20.42,15.95-30.56,24.01c-0.65,0.51-0.94,1.47-1.84,2.95c9.38-7.14,17.94-13.81,26.68-20.23
				c3.45-2.54,7.22-4.68,11-6.7c2.48-1.33,4.75-1.02,6.08,2.05c1.35,3.1,3.69,3.35,6.52,1.86c4.41-2.31,8.79-4.7,13.33-6.74
				c6.46-2.9,13.06-5.49,19.59-8.24c1.51-0.64,3.03-1.28,4.44-2.09c5.81-3.35,11.58-6.76,17.92-9.54c-2.77,5.37-6.54,9.98-5.84,17.07
				c5.93-2.37,11.87-3.61,16.46-6.8c9.79-6.81,21.19-11.96,26.54-23.94c4.91-11.01,11.69-21.04,14.86-33.02
				c1.97-7.46,6.76-14.26,10.91-20.97c0.94-1.52,4.56-2.15,6.83-1.95c8.27,0.74,7.98,0.84,10.83-6.88
				c1.93-5.24,4.86-10.11,7.12-15.24c2.19-4.97,4.55-9.95,5.92-15.17c0.58-2.19-1.17-5-2-8.03c3.09-0.76,7.5-1.42,11.57-2.96
				c7.08-2.68,14.38-5.21,20.83-9.06c11.95-7.13,23.46-15.03,34.97-22.89c6.03-4.12,11.6-8.89,17.46-13.27
				c12.23-9.15,24.5-18.23,37.78-28.1c-2.48,6.28-4.48,11.36-6.48,16.44c0.47,0.29,0.95,0.58,1.42,0.87
				c1.58-0.76,3.17-1.51,5.07-2.42c0,2.28,0,4.44,0,7.41c3.48-2.08,6.37-3.81,9.69-5.79c0.35,2.34,0.62,4.19,1.12,7.49
				c3.52-1.96,6.89-3.05,9.13-5.27c3.63-3.59,7.3-7.54,9.58-12.03c4.41-8.68,12.14-10.63,24.33-9.73c-1.67,5.8-2.93,11.5-5.07,16.84
				c-0.61,1.52-3.73,2.71-5.79,2.86c-5.98,0.43-9.79,3.57-11.35,8.92c-2.34,8.04-7.96,13.19-14.52,18.57
				c-0.37-1.52-0.64-2.58-0.89-3.59c-9.84,5.04-19.77,9.87-23.43,21.42c-0.91,2.88-1.42,5.88-2.42,10.12
				c3.11-0.92,4.74-1.41,5.94-1.76c-15.84,23.6-33.54,45.87-62.67,55.54c-0.37-7.5,6.41-12.84,7.11-20.62
				c-1.71,1.87-3.5,3.68-5.1,5.64c-1.68,2.06-2.85,4.64-4.81,6.35c-12.74,11.12-25.16,22.47-40.25,30.78
				c-14.48,7.97-28.16,17.52-41.66,27.13c-11.63,8.28-22.51,17.61-33.74,26.46c-0.63,0.5-1.42,1.07-2.17,1.12
				c-5.65,0.39-7.4,4.59-7.59,8.97c-0.6,13.75-0.9,27.52-0.84,41.28c0.07,14.78,0.69,29.55,1.03,44.33c0.07,3.13,0.01,6.26,0.01,9.64
				c-3.91-0.64-5.49-1.93-5.83-5.83c-0.98-11.06-2.7-22.06-4.15-33.07c-0.09-0.65-0.29-1.3-0.51-1.92c-1.21-3.47-2.25-7.82-6.56-7.83
				c-4.35-0.02-5.01,4.4-5.48,7.78c-1.08,7.7-1.91,15.44-2.55,23.19c-0.34,4.09-0.06,8.23-0.06,12.86c-5.17-1.2-9.66-2.24-14.21-3.29
				c-0.82-10.43-1.89-20.66-2.36-30.93c-0.54-11.78-0.44-23.58-0.83-35.37c-0.21-6.3-0.32-12.68-5.87-17.35
				c-5.1-4.29-9.16-3.58-11.51,2.68c-1.1,2.92-1.92,6.12-2.01,9.22c-0.63,21.41-4.13,42.8-0.44,64.24c0.24,1.41,0.03,2.89,0.03,4.67
				c-5.73-1.11-10.9-2.11-17.4-3.37c0-9.71,0.14-19.34-0.05-28.97c-0.12-6.47-0.36-12.99-1.24-19.39c-0.37-2.72-2.02-5.71-3.99-7.66
				c-3.74-3.7-9.28-2.36-10.79,2.66c-1.86,6.17-3.77,12.54-4.07,18.9c-0.68,14.1-0.26,28.26-0.58,42.39
				c-0.04,1.88-1.31,4.49-2.84,5.45c-7.12,4.5-14.72,8.24-21.8,12.8c-15.73,10.13-35.31,11.18-50.78,21.9
				c-2.07,1.43-5,1.78-7.59,2.27c-12.27,2.28-21.48,9.06-29.1,18.71c-10.2,12.92-20.66,25.64-31.28,38.22
				c-1.57,1.86-4.86,3.79-6.99,3.44c-13.91-2.31-26.33,1.65-38.34,7.95c-2.89,1.51-5.74,3.1-9.45,5.11c0-2.7,0-4.46,0-6.22
				c0-41.64,0.05-83.28-0.08-124.92c-0.01-3.71,1.4-5.66,4.12-8.16c16.39-15.12,36.05-25.43,54.19-37.98
				c8.14-5.64,14.96-13.18,22.49-19.73c6.13-5.33,12.9-10.01,18.51-15.82c6.77-7.01,12.96-14.66,18.77-22.49
				c10.88-14.68,21.44-29.6,31.9-44.59c9.5-13.62,18.69-27.45,27.97-41.21c0.48-0.72,0.53-1.73,0.78-2.6
				c-0.47-0.34-0.93-0.69-1.4-1.03c-7.12,8.2-14.53,16.18-21.28,24.68c-4.84,6.09-8.78,12.9-13.23,19.32
				c-0.72,1.04-1.97,1.71-2.99,2.53c-6.29,5.07-13.26,9.5-18.75,15.33c-15.27,16.23-29.92,33.04-44.82,49.61
				c-0.55,0.61-1.19,1.13-1.77,1.68c-6.85-4.22-8.49-3.99-14.04,1.91c-0.68,0.73-1.44,1.43-1.95,2.27c-4.03,6.65-9.64,9.7-17.52,8.18
				c-1.35-0.26-3.24-0.07-4.26,0.72c-5.73,4.42-11.61,8.75-16.74,13.82c-2.73,2.7-4.31,2.64-7.01,0.58c-3.9-2.97-8.02-5.64-12.7-8.88
				c0-12.16,0-25.43,0-38.7c3.7,6.78,8.96,3.97,13.86,2.38c12.94-4.19,25.86-8.43,38.73-12.83c5.81-1.99,11.41-2.66,17.55-1.08
				c2.81,0.72,6.75-0.11,9.26-1.68c5.6-3.51,10.83-7.71,15.84-12.06c3.82-3.33,7.67-5.61,12.95-5.25c1.55,0.11,3.81-0.7,4.68-1.88
				c3.92-5.33,7.45-10.94,11.12-16.45C126.23,641.6,126.21,641.59,126.21,641.59z M477.48,534.25c-0.24-0.33-0.48-0.66-0.73-0.99
				c-1.33,0.85-2.56,1.98-4.01,2.48c-3.69,1.28-7.47,2.28-11.22,3.4c-2.11,0.63-4.71,0.67-6.26,1.96
				c-10.2,8.5-20.35,17.08-30.11,26.08c-2.92,2.69-6.19,6.71-6.38,10.27c-0.29,5.44-3.17,8.74-5.86,12.61
				c-2.75,3.95-5.28,8.04-7.83,12.13c-0.49,0.79-0.59,1.84-1.28,4.17C432.31,584.83,453.6,558.22,477.48,534.25z M349.64,666.5
				c0.33,0.39,0.65,0.78,0.98,1.17c19.35-18.56,43.45-31.59,60.52-52.9c-4.57,0.73-10.2,0.63-12.78,3.39
				c-6.24,6.67-12.93,12.18-21.38,15.56c-0.14,0.06-0.26,0.21-0.37,0.33C367.62,644.87,358.63,655.68,349.64,666.5z"/>
			<path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M1069.54,339.89c-1.79,3.07-3.58,6.14-4.94,8.48c0-0.98,0-3.18,0-5.37c-0.4-0.08-0.8-0.15-1.2-0.23
				c-0.56,2.62-1.11,5.24-1.67,7.86c-0.22-0.01-0.45-0.03-0.67-0.04c0.12-2.16,0.24-4.31,0.43-7.77c-2.32,1.75-3.77,2.83-5.67,4.27
				c1.47-4.96,4.1-8.31,8.8-9.7c9.83-2.92,24.59,3.6,28.69,13.52c7.45,18.08,10.59,37.11,12.85,56.2
				c2.38,20.11,3.44,40.41,4.11,60.66c0.88,26.29,1.41,52.62,0.95,78.92c-0.41,23.4-2.69,46.76-3.58,70.17
				c-1.16,30.75-4.85,61.32-4,92.24c0.8,29.07-1.36,58.21-2.05,87.32c-0.49,20.47-1.25,40.96-0.94,61.43
				c0.49,32.01-0.01,63.97-4.43,95.67c-2.73,19.55-6.33,39.06-10.99,58.23c-2.22,9.16-7.62,17.6-11.98,26.16
				c-1.21,2.38-3.81,4.08-5.88,5.98c-3.52,3.26-7.37,6.2-10.62,9.7c-2.93,3.14-6.11,5.16-10.42,4.83
				c-5.89-0.45-11.05,1.48-16.13,4.22c-9.35,5.04-19.35,7.77-30.01,8.35c-5.95,0.32-11.87,1.79-17.81,1.85
				c-11.87,0.13-20.84-5.52-27.32-15.32c-0.92-1.4-1.79-2.84-3.26-3.87c1.1,6.11,4.3,11.02,9.45,14.17
				c5.88,3.59,11.52,7.92,19.04,8.13c1.14,0.03,2.65,3.24,3.18,5.2c1.25,4.63,1.72,9.47,3.11,14.05c0.57,1.89,2.66,3.33,4.06,4.97
				c0.76-1.98,1.91-3.92,2.19-5.97c0.75-5.4,0.86-10.88,1.72-16.26c0.71-4.47,1.01-4.42,4.92-3.94c-0.6,7.37-1.68,14.69-1.71,22.02
				c-0.06,16.47,0.74,32.95,0.57,49.42c-0.19,19.12-1.75,38.25-1.37,57.35c0.32,15.91,2.61,31.78,4.01,47.67
				c0.17,1.98,0.63,4.06,0.27,5.96c-3.64,18.86-1.32,37.86-1.55,56.8c-0.15,12.63-1.24,25.27-0.96,37.88
				c0.2,9.01,1.97,17.98,3.04,26.96c0.43-0.03,0.85-0.06,1.28-0.1c-0.35-5.54-0.83-11.08-1.02-16.63
				c-0.19-5.76-0.09-11.52-0.13-17.29c-0.13-19.65-0.4-39.3-0.34-58.94c0.02-7.45,1.26-14.91,1.09-22.34
				c-0.25-10.9-1.57-21.78-1.88-32.68c-0.49-17.26-0.61-34.54-0.71-51.81c-0.06-10.48,0.35-20.95,0.3-31.43
				c-0.08-17.65-0.63-35.31-0.41-52.95c0.07-5.98,1.72-11.97,2.96-17.9c0.23-1.11,1.92-1.92,2.93-2.86c0.97,1.41,2.58,2.72,2.78,4.23
				c0.66,4.94,0.95,9.94,1.12,14.92c0.34,10.31,0.87,20.63,0.64,30.94c-0.33,14.13-1.62,28.25-1.91,42.38
				c-0.46,22.31-0.71,44.63-0.55,66.94c0.19,25.46,1.03,50.91,1.39,76.36c0.21,14.62,0.39,29.26-0.07,43.87
				c-0.37,11.92-1.71,23.81-2.65,36.09c-2.83-3-5.9-6.25-9.38-9.95c-0.58-29.11-0.95-59.08-1.87-89.03
				c-0.43-13.94-2.3-27.83-2.87-41.77c-0.63-15.46-0.63-30.96-0.71-46.44c-0.08-15.47,0.33-30.94,0.05-46.4
				c-0.18-9.95-1.4-19.88-1.8-29.83c-0.18-4.45,0.74-8.94,0.72-13.41c-0.01-2.22-0.3-5.26-1.71-6.52c-6.68-5.91-5.4-14.19-7.17-21.56
				c-0.54-2.23-1.38-4.39-2.08-6.59c-0.81,0.08-1.62,0.16-2.43,0.24c-0.17,5.94-0.55,11.88-0.46,17.82
				c0.13,9.25,0.59,18.5,0.87,27.75c0.9,29.6,2,59.19,2.61,88.8c0.62,30.12,2.84,60.41,0.48,90.32c-1.98,25.14-2.25,50.1-1.94,75.19
				c0.04,3.51,0.83,7.01,1.86,10.36c0-13.05,0.22-26.11-0.06-39.16c-0.31-14.32-0.52-28.59,2.45-42.72c0.54-2.58,0.68-5.29,0.71-7.94
				c0.36-32.8-0.39-65.53-2.47-98.31c-1.85-29.01-0.57-58.21-0.92-87.32c-0.1-8.28-1.06-16.54-1.58-24.81
				c-0.1-1.64-0.02-3.3,0.65-5.02c0.98,5.66,1.91,11.32,2.96,16.97c0.23,1.23,0.51,3,1.36,3.49c5.22,3,4.75,7.85,4.71,12.73
				c-0.07,8.45-0.02,16.9,0.07,25.35c0.16,14.13,0.29,28.27,0.63,42.39c0.69,28.11,1.42,56.22,2.32,84.33
				c0.5,15.62,1.56,31.22,2.01,46.83c0.63,21.96,0.99,43.93,1.26,65.9c0.03,2.15-1.48,4.3-2.15,6.49c-0.61,1.99-1.05,4.04-1.65,6.41
				c-2.26-1.39-5.38-4.55-6.79-3.92c-2.69,1.21-5.14,4.29-6.34,7.16c-1.39,3.31,0.05,6.3,4.49,6.93c-2.14,0.79-4.97,0.99-6.26,2.5
				c-1.83,2.15-3.34,5.23-3.47,7.99c-0.11,2.34,1.95,4.78,3.21,7.54c-4.31,4.74-4.31,4.74-2.8,8.6c-0.37,0.15-0.75,0.29-1.12,0.44
				c-0.62-1.85-1.83-3.72-1.78-5.55c0.75-24.27,2.1-48.52,2.44-72.79c0.38-26.94-0.08-53.88-0.23-80.83
				c-0.08-14.47-0.37-28.95-0.41-43.42c-0.04-16.32-0.19-32.65,0.21-48.97c0.53-21.62,1.9-43.23,2.18-64.85
				c0.1-7.67-1.96-15.37-3.02-23.06c-0.41-2.95-0.7-5.92-1.24-8.84c-0.27-1.44-0.63-3.12-1.56-4.13c-1.32-1.42-3.2-2.32-4.84-3.44
				c-0.7,1.88-2.06,3.79-1.97,5.64c0.37,7.96,1.21,15.89,1.75,23.84c0.12,1.8,0.1,3.75-0.44,5.44c-6.93,21.94-4.34,44.35-3.64,66.7
				c0.38,12.07,2.96,24.07,0.48,36.3c-0.87,4.3,0.79,9.13,1.34,13.7c0.42,3.44,1.18,6.85,1.32,10.29c0.27,6.46,0.29,12.93,0.27,19.39
				c-0.04,15.14-0.43,30.29-0.18,45.42c0.24,14.94,1.79,29.88,1.52,44.8c-0.44,24.27-1.87,48.53-2.97,72.79
				c-0.1,2.29-0.79,4.55-1,5.7c-2.43,1.65-5.34,2.71-5.34,3.78c-0.02,4.13,0.4,8.57,5.94,9.64c-0.17,1.37-0.95,3.24-0.39,3.84
				c4.38,4.64,1.41,8.54-0.59,12.89c-1.83,3.96-1.75,7.43,4.26,7.97c2.65,0.24,5.15,2.09,7.77,3.24c-2.23,1.45-5.23,3.39-8.63,5.59
				c-4.63-3.95-5.19,1.41-6.38,3.88c-5.76-3.24-11.38-6.29-16.84-9.59c-1.01-0.61-2-2.27-1.98-3.43c0.18-13.8,0.18-27.61,0.99-41.38
				c0.64-10.86,2.45-21.65,3.53-32.5c0.38-3.79,0.29-7.66,0.11-11.47c-0.56-11.6-1.33-23.19-1.93-34.78
				c-0.83-16.11-1.93-32.22-2.25-48.34c-0.2-10.11,0.82-20.23,1.3-30.35c0.39-8.29,0.66-16.6,1.24-24.88
				c0.38-5.45,1.23-10.86,1.8-16.3c0.17-1.65,0.46-3.38,0.14-4.96c-2.78-13.83-0.52-27.66-0.25-41.5c0.14-7.12-0.83-14.27-1.31-21.4
				c-0.09-1.33-0.32-2.65-0.28-3.97c0.3-11.56,0.97-23.12,0.88-34.68c-0.07-8.9-2.18-17.89-1.35-26.65
				c0.82-8.69,0.72-16.36-5.33-23.11c-0.37-0.42-0.4-1.29-0.35-1.93c0.86-10.66-4.47-19.89-6.8-29.81c-0.8-3.41-2.78-4.66-6.11-4.39
				c-12.39,1.01-23.13-2.58-32.4-11.06c-2.74-2.51-6.75-3.56-9.72-5.88c-11.66-9.11-19.24-20.92-22.82-35.34
				c-0.7-2.84-1.91-5.71-3.51-8.15c-5.18-7.91-7.75-16.71-10.42-25.67c-2.92-9.81-8.58-18.88-10.88-28.77
				c-4.82-20.68-8.22-41.68-12.18-62.56c-0.12-0.63-0.02-1.31,0.37-2.06c2.41,9.26,4.58,18.58,7.29,27.75
				c2.3,7.79,5.03,15.46,7.92,23.05c1.16,3.05,2.75,6.32,5.08,8.45c3.55,3.25,4.77,7.21,5.72,11.53
				c4.3,19.55,13.92,36.13,27.98,50.25c7.85,7.89,15.55,15.93,23.59,23.63c2.78,2.66,6.37,4.49,10.19,6.09
				c-29.69-21.54-47.6-51.37-60.2-85.77c4.38,2.61,8.81,5.14,13.13,7.84c3.22,2.02,6.06,4.86,9.5,6.27
				c6.23,2.54,10.52,6.91,13.79,12.45c4.23,7.17,9.07,13.26,17.48,16.2c3.33,1.16,5.79,4.8,8.66,7.31c4.49,3.93,8.55,8.56,13.57,11.6
				c6.45,3.91,13.54,4.66,19.75-1.31c0.47-0.45,1.07-1.19,1.55-1.15c7.2,0.69,7.03-5.32,7.72-9.38c2.58-15.05,5.27-30.15,6.57-45.35
				c1.89-22.03,2.83-44.15,3.75-66.25c1.39-33.26,2.51-66.53,3.53-99.8c0.41-13.31,0.14-26.65,0.44-39.97
				c0.16-7.14,1.39-14.28,1.24-21.4c-0.33-15.26-1.33-30.51-1.88-45.77c-0.48-13.1-0.74-26.21-1.05-39.31
				c-0.36-15.32-1.19-30.65-0.81-45.95c0.25-10.06,1.87-20.15,3.73-30.06c0.85-4.54,3.73-8.69,5.83-13.33
				c-6.01,0.59-6.99,5.86-9.17,9.77c-2.22,4-4.08,8.21-6.55,13.26c-1.16-10.87,2.63-20.33,9.53-24.66c1.77-1.11,4.03-2.26,5.96-2.12
				c9.01,0.67,18.02,1.61,26.98,2.82c5.27,0.71,8.74,4.3,10.18,9.21c5.47,18.64,11.85,37.07,11.33,56.98
				c-0.18,6.92,1.61,13.88,1.95,20.84c0.6,12.09,0.83,24.2,1.14,36.3c0.38,14.65,0.67,29.3,1.05,43.94
				c0.19,7.49,0.23,14.99,0.82,22.45c1.87,23.7,4.05,47.38,5.94,71.08c1.54,19.34,3.65,38.69,4.02,58.07
				c0.37,19.59-0.55,39.23-3.98,58.68c-3.77,21.44-6.78,43.01-10.36,64.49c-0.72,4.31-2.49,8.44-3.78,12.65
				c-0.54-0.06-1.09-0.12-1.63-0.19c0-5.24,0.12-10.48-0.02-15.71c-0.66-25.45-1.49-50.89-2.05-76.34
				c-0.7-31.32,0.9-62.54,3.46-93.77c1.58-19.22,1.65-38.6,1.57-57.9c-0.18-46.12-0.67-92.25-1.41-138.36
				c-0.27-16.99-2.06-33.97-8-50.02c-2.36-6.38-6.69-12.06-10.47-17.84c-0.65-0.99-2.99-0.87-4.54-1.25
				c-0.59,1.38-1.72,2.75-1.71,4.12c0.13,10.65,0.2,21.31,0.82,31.93c1.05,18.09,2.39,36.17,3.79,54.23
				c0.87,11.25,3.17,22.51,2.91,33.72c-0.64,27.93-2.68,55.83-3.6,83.76c-1.07,32.43-1.84,64.87-2.17,97.31
				c-0.18,17.29-0.21,34.7,1.56,51.87c3.17,30.73,4.75,61.33,0.67,92.06c-0.6,4.53-0.54,9.14-0.78,13.72
				c0.41,0.03,0.82,0.06,1.24,0.09c0.18-0.72,0.48-1.43,0.53-2.15c1.22-16.57,3.13-33.14,3.45-49.73
				c0.56-28.95,0.07-57.92,0.16-86.88c0.07-22.31,0.02-44.62,0.64-66.91c0.55-19.45,2.97-38.88,2.79-58.3
				c-0.4-43.75-1.73-87.5-3.02-131.23c-0.67-22.74-1.11-45.57-7.4-67.73c8.34,10.17,11.09,22.39,13.13,34.98
				c3.74,22.96,3.66,46.07,3.29,69.21c-0.6,37.46-1.04,74.93-1.88,112.39c-0.85,38.27-2.63,76.53-2.89,114.8
				c-0.1,15.01,2.08,30.17,4.55,45.04c3.46,20.86,2.77,41.81,3.22,62.76c0.13,6.16,0.02,12.33,0.4,18.47
				c0.53,8.57-5.37,18.35-16.89,17.53c-11.19-0.79-11.86-0.82-11.5-12.2c1.43-45.41,3.13-90.82,4.59-136.23
				c0.39-12.15,0.39-24.31,0.33-36.47c-0.18-38.47,0.43-76.89,2.93-115.32c1.37-21.05,0.91-42.29,0.08-63.4
				c-1.18-29.75-3.55-59.46-5.22-89.19c-0.41-7.25-0.06-14.54-0.06-23.26c-1.04,2.12-1.64,2.99-1.91,3.95
				c-4.31,15-3.91,30.4-2.69,45.63c2.64,32.89,3.86,65.78,2.74,98.77c-1.36,39.76-2.98,79.52-3.85,119.29
				c-0.98,44.94-1.27,89.89-1.82,134.84c-0.16,13.31,0.08,26.64-0.44,39.93c-0.23,5.98-1.89,11.91-3.59,17.82
				c-4.16-4.59-5.13-9.98-4.98-15.37c0.52-19.78,1.72-39.54,1.97-59.32c0.17-13.43,0.01-26.97-1.48-40.29
				c-2.13-18.99-1.84-37.87-0.42-56.82c1.15-15.26,2.6-30.51,3.45-45.79c1.22-21.75,2.61-43.52,2.74-65.29
				c0.08-12.56-1.8-25.19-3.47-37.7c-3.51-26.19-3.38-52.5-2.71-78.79c0.4-15.75,2.25-31.47,3.9-47.16
				c0.47-4.44,2.53-8.71,3.44-13.74c-2.23,2.39-4.79,4.54-6.63,7.2c-5.74,8.32-6.79,18.15-6.73,27.72
				c0.17,29.13,0.66,58.27,1.93,87.36c0.58,13.23,3.68,26.32,4.92,39.54c0.86,9.08,1.08,18.31,0.55,27.41
				c-1.34,22.91-3.61,45.78-4.88,68.69c-2.14,38.54-4.09,77.1-5.55,115.67c-0.76,20.12-0.02,40.28-0.47,60.42
				c-0.2,8.85-1.69,17.68-3.24,26.44c-1.32-9.54,1.7-19.2-1.31-28.53c-0.62-0.07-1.23-0.14-1.85-0.21
				c-0.78,3.39-1.62,6.76-2.34,10.16c-1.42,6.75-0.53,14.05-4.98,20.12c-0.44,0.61,0.5,3.28,1.24,3.5c1.98,0.59,4.79,1.26,6.23,0.31
				c4.84-3.19,6.2-8.65,6.42-13.99c0.92-22.11,1.47-44.23,2.24-66.34c0.84-24.44,1.71-48.88,2.67-73.32
				c0.65-16.63,1.22-33.27,2.29-49.88c1.25-19.58,3.28-39.12,4.43-58.7c0.73-12.59,0.48-25.24,0.65-37.86
				c0.03-2.48,0.01-4.95,0.01-7.43c0.4,0,0.8-0.01,1.2-0.01c0,17.47,0.59,34.97-0.16,52.42c-0.83,19.43-3.02,38.8-4.36,58.21
				c-1.21,17.59-2.72,35.2-2.85,52.81c-0.09,12.25,2.49,24.5,2.86,36.78c0.4,13.09-0.46,26.22-0.64,39.34
				c-0.24,17.93-0.61,35.87-0.29,53.79c0.07,3.9,2.8,7.75,4.31,11.62c0.71-0.04,1.42-0.08,2.14-0.12c1.18-2.98,3-5.87,3.41-8.94
				c1.09-8.22,2.19-16.52,2.2-24.79c0.03-26.31-0.93-52.63-0.59-78.93c0.48-37.13,1.68-74.25,2.74-111.36
				c1.17-40.77,3.27-81.53,3.57-122.3c0.2-26.75-2.11-53.52-3.2-80.28c-0.23-5.6-0.03-11.21,0.91-16.81
				c0.71,12.7,0.98,25.45,2.2,38.1c3.48,36.18,4.86,72.43,4.01,108.74c-0.42,18.13-2.45,36.21-2.86,54.34
				c-0.66,29.13-0.33,58.29-0.87,87.43c-0.85,45.11-2,90.22-3.22,135.33c-0.27,9.9-1.35,19.78-2.11,30.26
				c-12.9-1.83-18.15,10.23-26.57,17.16c-1.89,1.55-2.88,4.93-3.13,7.58c-0.48,5.11-0.14,10.31-0.14,15.46
				c0.84,0.01,1.67,0.02,2.51,0.03c0-2.57,0.16-5.16-0.03-7.72c-0.53-7.01,0.49-13.15,7.08-17.41c2.56-1.66,4.37-4.5,6.52-6.79
				c5.15-5.5,11.43-6.1,18.33-4.39c2.25,0.56,4.59,0.79,6.9,1.04c11.54,1.25,17.79-3.35,21.95-14.19
				c8.62-22.45,11.22-46.06,15.07-69.53c4.97-30.33,6.81-60.76,7.34-91.44c0.3-17.72,3.29-35.39,4.99-53.09
				c0.48-5.05,0.69-10.13,1.64-15.16c-0.64,13.12-1.67,26.23-1.79,39.35c-0.1,10.26,0.5,20.58,1.66,30.77
				c0.41,3.65,3.36,7.02,5.57,11.32c2.06-2.49,3.02-3.65,3.98-4.81c0.41,0.11,0.82,0.23,1.23,0.34
				c-5.88,36.98-11.75,73.96-17.63,110.94c0.26,0.2,0.52,0.41,0.78,0.61c1.1-1.18,2.57-2.19,3.23-3.58
				c1.77-3.74,3.65-7.52,4.69-11.49c5.63-21.52,11.78-42.94,13.18-65.32c0.03-0.43,0.25-0.85,1.08-1.19
				c-0.78,9.24-1.01,18.57-2.45,27.7c-4.35,27.56-9.07,55.06-13.9,82.53c-1.32,7.5-3.89,14.77-5.18,22.27
				c-0.56,3.25,0.67,6.82,1.08,10.24c0.79,0.13,1.59,0.27,2.38,0.4c1.93-3.48,4.09-6.86,5.73-10.48c1.7-3.74,2.89-7.71,4.88-11.43
				c-1.12,5.41-2.37,10.79-3.31,16.23c-0.59,3.43-1.44,7.05-0.93,10.4c1.16,7.58-0.76,14.43-3.22,21.4
				c-1.89,5.35-3.57,10.9-1.7,17.01c6.59-6.91,14.83-34.33,23.03-75.28c-2.55,25.64-9.26,50.37-17.88,75.58
				c6.55-1.42,9.18-5.29,10.6-9.41c3.61-10.48,7.36-21.02,9.6-31.84c3.37-16.25,5.49-32.76,8.17-49.15c0.31-1.89,0.82-3.75,1.64-5.57
				c-3.11,19.49-3.55,39.11-4.67,58.68c0.72,0.21,1.44,0.42,2.15,0.62c1.08-2,2.74-3.89,3.16-6.03c3.37-17.09,7.57-34.11,9.44-51.38
				c2.19-20.15,2.67-40.51,3.19-60.8c0.7-27.77-0.71-55.68,1.4-83.32c3.11-40.75,2.98-81.53,3.51-122.28
				c0.65-50.94-0.45-101.89-0.53-152.84c-0.06-33.97,0.27-67.95,0.53-101.92c0.16-20.66,0.25-41.31-5.9-61.33
				c-1.98-6.44-4.38-12.74-7.3-18.93c5.54,21.74,9.01,43.62,7.72,66.75c-5.87-25.2-5.02-51.2-16.03-74.61
				c1.57,6.54,3.27,13.05,4.7,19.62c5.83,26.8,6.29,54.11,7.77,81.35c2.85,52.45,0.39,104.88-0.11,157.32
				c-0.1,10.47-1.08,20.94-1.62,31.41c-0.08,1.56-0.01,3.14,0.72,4.7c4.38-36.8,3.41-73.79,3.97-110.68
				c0.56-37.08-0.91-74.19-1.82-111.34c0.73,10.9,1.46,21.8,2.2,32.7c0.48-0.02,0.97-0.03,1.45-0.05c0-18.58,0-37.16,0-55.73
				c0.65,0,1.3,0,1.95,0c0,18.55-0.04,37.09,0.01,55.64c0.12,47.63,0.2,95.25,0.43,142.88c0.13,27.64,0.91,55.29,0.69,82.93
				c-0.26,33.46-0.95,66.93-2.05,100.37c-1,30.09-3.05,60.15-4.15,90.24c-0.48,13.12,0.78,26.3,0.32,39.42
				c-1.11,31.91-2.76,63.81-11.03,94.91c-0.43,1.6-0.78,3.23-1.25,4.82c-0.13,0.42-0.58,0.75-1.71,2.13
				c0.43-3.96,0.75-6.79,1.06-9.63c2.36-21.97,5.47-43.89,6.86-65.92c1.47-23.4,1.64-46.9,1.72-70.36
				c0.06-17.46-0.84-34.93-1.46-52.39c-0.19-5.42-0.85-10.83-1.29-16.24c-0.46-0.03-0.91-0.05-1.37-0.08
				c-0.12,1.89-0.42,3.78-0.34,5.65c0.65,16.13,2.13,32.27,1.89,48.38c-0.36,24.27-0.64,48.67-3.27,72.76
				c-3.42,31.33-8.81,62.47-14.02,93.58c-1.43,8.57-5.07,16.79-8.02,25.06c-0.84,2.37-2.82,4.33-5.07,6.05
				c3.83-14.35,8.36-28.56,11.28-43.1c2.94-14.62,4.36-29.56,6.29-44.38c1.94-14.91,3.67-29.85,5.5-44.78c-0.6-0.1-1.2-0.21-1.8-0.31
				c-0.66,3.57-1.34,7.14-1.96,10.73c-5.01,28.97-9.58,58.02-15.22,86.86c-2.11,10.78-6.71,21.07-10.17,31.58
				c-0.59-0.18-1.18-0.35-1.77-0.53c1.03-3.66,1.92-7.37,3.12-10.97c1.99-6.01,2.6-12,2.82-18.42c0.36-10.34,2.91-20.64,4.95-30.88
				c3.46-17.42,8.03-34.65,10.65-52.18c2.33-15.59,2.77-31.47,3.8-47.24c0.89-13.62,1.43-27.26,2.1-40.89
				c0.08-1.6,0.01-3.2,0.01-4.81c-0.51-0.06-1.02-0.11-1.53-0.17c-0.22,1.6-0.62,3.2-0.63,4.8c-0.2,31.36-4.31,62.34-8.65,93.33
				c-2.94,21.01-7.58,41.45-15.46,61.14c-0.8,2-1.82,3.91-2.81,5.83c-0.28,0.54-0.79,0.95-1.2,1.42c-0.52-0.09-1.05-0.18-1.57-0.26
				c0.41-4.42,0.17-9.01,1.32-13.22c9.14-33.45,14.5-67.6,17.97-101.98c2.35-23.31,2.71-46.83,3.71-70.27
				c1.61-38.08,3.55-76.16,4.35-114.27c0.79-37.62,0.34-75.27,0.43-112.9c0.01-4.98,0-9.96-1.15-14.93c0,15.92,0.12,31.85-0.03,47.77
				c-0.25,27.81-0.31,55.63-1.07,83.42c-1.05,38.44-2.16,76.89-4.18,115.29c-1.91,36.23-6.76,72.12-17.43,106.98
				c-0.92,3.01-2.01,5.97-3.07,8.94c-0.27,0.75-0.7,1.43-1.6,1.98c3.52-20.38,7.81-40.66,10.33-61.17
				c2.54-20.69,8.33-41.14,4.98-62.57c-1.31,5.99-2.62,11.98-4.17,19.06c-5.88-6.33-6.36-13.28-6.36-19.83
				c-0.02-15.14,0.91-30.28,1.21-45.42c0.33-16.12,0.46-32.25,0.55-48.38c0.11-18.48-0.02-36.97,0.16-55.45
				c0.16-16.78,0.57-33.55,0.9-50.33c0.34-17.29,0.67-34.59,1.08-51.88c0.34-14.46,1.34-28.93,1.08-43.38
				c-0.4-22.12-1.9-44.22-2.43-66.34c-0.75-31.63,0.09-63.23,4.08-94.66c0.46-3.64,2.15-7.13,3.27-10.7
				c0.56,0.07,1.11,0.14,1.67,0.21c0,2.2-0.04,4.4,0.01,6.59c0.65,29.46,0.1,59.01,2.25,88.36c3.32,45.31,0.41,90.55,0.71,135.81
				c0.12,18.16,0.05,36.31,0.07,54.47c0,0.97,0.1,1.95,1.21,3.09c0.26-1.74,0.73-3.47,0.74-5.21c0.18-36.31,0.4-72.62,0.43-108.93
				c0.02-21.8,0.01-43.61-0.5-65.4c-0.61-26.12-1.86-52.23-2.6-78.34c-0.17-6.1,0.58-12.23,1.11-18.31
				c2.23,32.48,4.46,64.95,6.69,97.43c0.65-0.04,1.3-0.09,1.95-0.13c-0.27-3.32-0.68-6.64-0.78-9.97
				c-0.43-14.48-0.56-28.97-1.21-43.44c-0.59-13.29-1.81-26.56-2.55-39.85c-0.4-7.23-1.44-14.6,2.43-21.37
				c0.71,5.59,1.72,11.16,2.08,16.77c0.72,11.1,0.95,22.23,1.63,33.34c1.23,20.08,3.1,40.12,3.85,60.22
				c0.71,18.95,0.13,37.94,0.45,56.9c0.3,18.11,1.01,36.22,1.59,54.33c0.02,0.63,0.53,1.25,0.82,1.88c1.13-4.24,1.73-8.35,1.6-12.43
				c-0.45-14.29-1.42-28.57-1.69-42.86c-0.51-27.78-0.37-55.57-1.06-83.34c-0.6-24.25-1.84-48.5-3.13-72.72
				c-0.24-4.54-2.01-9.02-3.32-13.46C1072.14,340.38,1070.5,340.24,1069.54,339.89z M909,1012.33c-16.42-9.66-28.28-23.82-38.7-39.35
				c-10.2-15.21-20.24-30.54-30.73-46.39c1.02,2.81,1.81,5.11,2.67,7.38c8.81,23.18,23.72,42.56,39.1,61.49
				C888.31,1004.02,898.13,1009.2,909,1012.33z M1045.34,1027.13c-0.41-0.26-0.82-0.52-1.23-0.78c-0.5,0.38-1.11,0.67-1.5,1.14
				c-6.49,8.01-14.73,13.58-24.7,15.76c-13.36,2.93-26.87,4.02-40.37,0.05c-3.77-1.11-7.82-1.38-11.76-1.67
				c-1.25-0.09-3.39,0.75-3.66,1.63c-0.33,1.04,0.55,3.06,1.55,3.8c2.08,1.54,4.45,2.94,6.91,3.67c13.47,4.02,27.12,3.98,40.85,1.45
				c11.07-2.04,21.1-6.03,28.15-15.32C1041.84,1033.88,1043.44,1030.39,1045.34,1027.13z M1041.89,1053.19
				c-0.22-0.37-0.45-0.75-0.67-1.12c-0.58,0.13-1.2,0.18-1.74,0.41c-24.85,10.48-49.79,13.8-75.04,1.19
				c-1.51-0.76-3.5-0.57-5.27-0.82c-0.31,0.55-0.61,1.09-0.92,1.64c2.31,2.42,4.19,5.86,7.01,7.06c6.31,2.68,12.98,5.4,19.7,6.03
				c17.08,1.61,33.12-4.16,49.13-9.09C1036.97,1057.61,1039.31,1055,1041.89,1053.19z M1035.81,1011.77
				c-1.45,0.87-1.91,1.05-2.25,1.37c-16.52,15.75-35.9,20.23-57.82,14.7c-3.04-0.77-6.06-1.7-9.15-2.16
				c-1.06-0.16-2.85,0.64-3.31,1.52c-0.42,0.81,0.12,2.77,0.9,3.43c1.98,1.67,4.17,3.42,6.58,4.2c15.79,5.13,31.17,3.01,45.95-3.76
				C1024.95,1027.29,1031.82,1021.68,1035.81,1011.77z M921.37,1020.84c-12.43,0.93-22.93-4.19-32.02-11.41
				c-9.1-7.22-16.96-15.99-25.36-24.07c0.08,0.35,0.11,0.98,0.37,1.49c8.11,15.52,20.22,27.11,35.66,35.04
				C906.96,1025.45,914.37,1025.29,921.37,1020.84z M880.85,940.65c8.62,24.63,28.51,48.94,42.82,52.01
				C906.43,976.87,891,960.92,880.85,940.65z M886.53,967.89c-8.15-15.18-16.27-30.41-28.87-42.53
				C862.32,942.93,874.66,955.24,886.53,967.89z M940.65,1037.55c0-1.25,0.16-1.79-0.02-2.16c-4.45-9.37-6.47-18.72-0.23-28.2
				c0.48-0.73,0.38-1.94,0.33-2.91c-0.28-5.47-0.63-10.94-0.95-16.4c-1.26,4.58-1.34,9.26-1.85,13.9c-0.27,2.47-0.77,5.14-2.01,7.22
				C931.58,1016.3,933.63,1031.24,940.65,1037.55z"/>
			<path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M535.46,745.78c0-11.34-0.09-22.47,0.11-33.6c0.03-1.49,1.18-3.45,2.43-4.35
				c12.24-8.78,25.53-14.84,40.98-14.86c0.83,0,1.81,0.15,2.45-0.23c8.64-5.12,17.23-10.33,26.11-15.67
				c-1.03-0.95-1.3-1.37-1.45-1.32c-15.11,4.27-31.48,3.1-45.82,10.66c-0.56,0.3-1.43,0.59-1.92,0.37c-8.2-3.65-15,1.07-22.17,3.62
				c-0.47,0.17-0.92,0.39-1.4,0.5c-0.3,0.07-0.64-0.03-0.72-0.03c-3.47-12.17,2.34-26.31-8.22-36.63c2.66-3.17,5.07-6.57,8-9.43
				c4.26-4.15,8.36-8.96,13.49-11.62c12.72-6.59,20.45-18.86,31.97-26.7c1.24-0.84,1.85-2.61,3.26-4.7c-3.8,0.47-6.75,0.83-9.69,1.2
				c-0.31-0.37-0.63-0.74-0.94-1.1c1.88-2.5,3.73-5.03,5.64-7.51c1.69-2.19,3.71-3.92,1.98-7.41c-1.23-2.48,4.39-8.87,9.9-11.35
				c-2.05,4.53-3.73,8.25-5.41,11.97c6.8-2.3,16.35,1.14,18.22-10.41c1.41,3.65,2.16,6.54,3.65,8.98c0.66,1.07,3.26,2.06,4.23,1.57
				c1.35-0.67,2.83-2.79,2.78-4.23c-0.18-4.97-0.98-9.91-1.6-14.86c-0.14-1.14-0.24-2.4-0.78-3.36
				c-8.06-14.5-16.16-28.98-24.36-43.39c-1.11-1.95-2.88-3.52-4.34-5.27c-0.55,0.49-1.02,0.79-0.98,0.89
				c2.97,7.85,3.18,15.48-1.26,22.98c-0.62,1.04-0.16,2.94,0.22,4.32c1.12,3.99,2.43,7.94,3.72,11.88c1.2,3.66,0.74,6.62-2.43,9.34
				c-4.64,3.97-7.76,8.98-9.09,15.12c-0.43,1.99-1.68,4.08-3.14,5.53c-4.66,4.63-9.59,8.98-14.42,13.44
				c-0.56-0.34-1.11-0.68-1.67-1.01c2.45-8.54,4.9-17.09,6.95-24.24c-8.03,3.49-17.39,6.77-25.91,11.54
				c-5.2,2.91-9.35,7.9-13.52,12.38c-5.56,5.95-9.97,13.18-16.18,18.26c-6.81,5.58-14.63,10.6-22.84,13.64
				c-18.41,6.82-32.56,19.81-47.88,31.08c-6.64,4.89-6.65,9.71-1.48,16.57c3.36-3.2,6.67-6.33,9.94-9.5
				c4.9-4.73,9.57-9.73,14.73-14.16c3.84-3.3,5.77-3.22,9.26-1.76c-0.72,4.61-1.34,9.05-2.17,13.46c-0.16,0.84-1.02,1.69-1.76,2.27
				c-7.76,6-15.51,12-23.35,17.88c-5.68,4.26-11.38,8.46-15.39,16.06c2.51-0.4,3.87-0.55,5.2-0.83c7.45-1.57,14.92-3.05,22.32-4.83
				c2.32-0.56,4.58-1.75,6.63-3.03c7.85-4.9,15.43-10.25,23.49-14.75c2.4-1.34,6.26-1.24,9.03-0.41c3.33,1.01,5.22,0.23,7.3-2.12
				c3.59-4.07,7.38-7.97,12.15-13.08c-0.51,5.05-0.9,8.56-1.21,12.09c-0.75,8.42-1.66,16.83-2.08,25.26
				c-0.15,2.98-1.14,4.5-3.77,5.59c-7.45,3.1-14.25,8.04-22.82,7.94c-0.32,0-0.67,0.1-0.96,0.25c-10.08,5.23-20.26,10.29-30.19,15.81
				c-4.31,2.39-8.1,5.71-12.13,8.61c-0.13-0.43-0.26-0.85-0.39-1.28c-2.18,0-4.9-0.84-6.44,0.16c-4.58,2.97-8.96,6.38-12.94,10.12
				c-8.12,7.63-15.93,15.58-23.85,23.43c-1.12,1.11-2.08,2.37-3.11,3.56c0.28,0.45,0.57,0.9,0.85,1.35c2.76-1.17,5.83-1.91,8.24-3.59
				c7.9-5.53,15.56-11.41,23.31-17.15c4.93-3.66,9.55-4.45,15.98-2.14c-3.46,3.76-6.73,7.13-9.76,10.69
				c-1.45,1.7-2.35,3.85-3.69,5.65c-2.75,3.69-5.32,7.61-8.55,10.85c-3.62,3.63-7.9,6.61-12.35,10.25
				c-6.21-7.38-12.65-3.29-19.14-0.18c-14.49,6.94-28.82,14.26-43.59,20.53c-5.57,2.36-12.19,2.25-18.53,3.3
				c-0.52-11.74-1.02-22.68-1.48-33.62c-0.67-15.93-1.05-31.87-2.06-47.78c-0.52-8.26-1.52-16.62-6.88-23.83
				c1.51-1.42,2.83-2.96,4.42-4.11c17.51-12.6,32.83-28.11,51.93-38.63c11.92-6.57,23.82-13.31,34.97-21.07
				c8.53-5.94,15.87-13.61,24.01-20.74c-0.17,3.8-0.32,7.28-0.51,11.65c15.93-9.3,31.26-16.27,43.44-29.26
				c10.45-11.15,17.12-24.8,28.07-35.3c-0.3-0.41-0.61-0.83-0.91-1.24c-2.86,1.23-5.72,2.46-9.51,4.09
				c3.08-11.43,8.04-20.12,18.74-22.93c1.49,0.71,2.97,1.93,3.53,1.6c8.05-4.75,14.9-10.85,18.03-19.9
				c1.93-5.56,4.95-8.48,10.96-9.11c2.55-0.27,6.1-2.26,7.02-4.41c2.19-5.11,3.33-10.73,4.26-16.25c0.17-1-2.51-3.28-4.16-3.64
				c-7.35-1.6-17.74,3.32-21.99,9.52c-3.18,4.64-6.7,9.08-10.38,13.34c-1.39,1.61-3.65,2.46-6.18,4.09c0-3.03,0-4.97,0-7.97
				c-3.57,1.66-6.58,3.06-9.88,4.6c-0.41-5.53,2.6-8.9,6.25-12.27c5.54-5.11,10.73-10.61,15.51-16.51
				c-12.24,4.59-18.84,15.29-26.78,24.54c-0.39-0.25-0.78-0.5-1.17-0.75c2.96-5.73,5.15-12.06,9.07-17.02
				c4.47-5.66,10.23-10.43,15.99-14.86c1.89-1.45,5.75-1.41,8.31-0.67c4.96,1.42,9.69,3.72,14.44,5.82c3.8,1.68,7.32,1.66,10.87-0.69
				c2.77-1.83,5.59-3.72,9.12-1.23c0.74,0.52,2.61,0.5,3.26-0.07c4-3.49,7.03-1.06,10.09,1.37c1.95,1.55,3.77,3.28,5.74,4.81
				c1.03,0.8,2.46,2.11,3.36,1.87c5.44-1.46,9.09,2.28,13.3,4.27c3.68,1.74,7.24,2.13,11.02,0.5c2.59-1.11,5.39-1.79,7.88-3.09
				c4.63-2.41,8.23-2.13,11.68,2.32c1.73,2.23,4.63,3.78,7.28,5.03c7.23,3.42,14.62,6.51,21.38,9.49c-2.25-2.02-5.23-4.7-8.49-7.63
				c6.82-0.96,14.15-0.75,20.46-3.2c8.8-3.42,18.41-2.46,26.92-6.4c6.09-2.82,12.38-4.29,19.19-4.31c3.03-0.01,6.32-1.63,8.99-3.31
				c3.21-2.01,5.66-5.21,8.84-7.28c4.4-2.85,9-6.78,13.83-7.31c12.44-1.38,20.76-9,29.42-16.5c2.35-2.04,4.58-4.22,6.93-6.4
				c3.08,6.13,2.19,13.76-1.96,18.01c-1.97,2.02-4.07,3.92-6.12,5.88c-3.43,3.28-7.1,4.78-11.53,1.75c-2.64-1.8-5.07-1.32-7.83,0.32
				c-4.27,2.53-8.78,4.72-13.35,6.64c-4.98,2.09-5.2,6.6-5.74,10.74c-0.54,4.2,3.38,4.28,6.06,5.24c3.75,1.34,7.52,2.62,11.32,3.79
				c3.86,1.19,6.99,0.09,9.94-2.77c5.92-5.71,12.12-11.12,18.58-17.01c4.69,8.16,3.96,16.02,4.45,23.66
				c0.03,0.45-0.17,1.08-0.49,1.36c-4.73,4.14-8.99,9.15-14.38,12.11c-6.52,3.58-7.85,5.54-3.3,11.55c2.91,3.84,6.51,7.18,9.21,11.15
				c1.53,2.25,2.69,5.37,2.55,8.02c-0.26,4.76-1.16,9.61-2.64,14.13c-1.68,5.11-0.01,8.55,4.19,12.27c1.05-2.51,2.31-4.49,2.71-6.63
				c1.3-7.01,2.64-14.06,3.18-21.15c0.19-2.52-1.35-5.61-2.99-7.74c-2.58-3.34-5.91-6.1-8.28-8.45c2.67-4.44,4.65-8.14,7.07-11.53
				c0.92-1.28,2.87-1.81,5.17-3.16c0,2.44-0.11,3.88,0.02,5.3c0.82,9.41,1.79,18.81,2.48,28.23c0.38,5.11-0.11,10.3,0.51,15.36
				c1,8.22,2.97,16.33,3.84,24.56c1.01,9.56,1.17,19.2,1.92,28.79c0.63,8.08,1.58,16.13,2.39,24.2c1,9.9,2.04,19.79,2.93,29.7
				c0.09,1.03-0.48,2.3-1.12,3.17c-3.65,4.95-7.55,9.73-11.12,14.74c-5.23,7.36-12.74,10.82-22.41,13.49c2-4.5,3.62-8.13,5.22-11.74
				c-6.57-5.55-8.98-5.16-15.38,0.04c-7.43,6.04-15.61,11.16-23.18,17.04c-5.59,4.33-10.87,9.08-16,13.95
				c-2.5,2.38-4.42,5.4-6.47,8.23c-3.17,4.38-6.68,6.68-12.46,4.28c-5.12-2.13-10.42-1.22-15.43,1.29c-2.47,1.24-5.13,2.1-8.44,3.43
				c1.03-6.58,3.79-11.29,7.72-15.38c2.53-2.64,5.28-5.07,7.85-7.68c8.29-8.45,15.13-22.87,15.67-35.94
				c-3.83,4.95-8.54,9.46-11.29,14.95c-4.2,8.4-11.56,12.95-19.37,17.89c1.74-4.74,3.36-9.53,5.27-14.21c1.85-4.53,4-8.94,5.5-13.9
				c-6.81,1.54-12,5.67-15.47,11.17c-8.01,12.69-21.35,18.08-33.15,25.64c-0.35,0.23-0.88,0.19-2.71,0.54
				c7.02-8.21,11.69-16.68,19.72-22.72c4.22-3.18,7.12-8.13,10.55-12.33c0.36-0.45,0.64-1.48,0.39-1.84
				c-3.83-5.47-1.63-11.21-0.89-16.9c0.1-0.75-0.1-1.53-0.26-3.6c-8.62,9.38-15.6,19.1-24.65,26.19c-8.91,6.99-19.9,11.3-29.99,16.79
				c-0.35-0.42-0.7-0.84-1.05-1.26c1.74-2.84,3.48-5.68,5.89-9.62c-15.16,4.92-24.82,16.61-37.82,22.64
				C562.46,732.92,549.63,739.07,535.46,745.78z M760.42,650.14c0.32,0.03,0.63,0.06,0.95,0.09c0.8-7.01,2.69-14.11,2.14-21.01
				c-0.97-12.31-5.97-23.33-13.91-33.02c-2.52-3.07-4.62-6.85-5.73-10.65c-2.88-9.85-9.53-16.88-16.56-25.37
				c0,1.96-0.09,2.46,0.01,2.9c2.61,11.09,6.77,22.12,0.87,33.47c-0.33,0.63,0.09,2.09,0.66,2.67c6.9,7.12,13.96,14.1,20.83,21.25
				c1.74,1.82,3.6,3.93,4.32,6.25C756.42,634.45,758.32,642.32,760.42,650.14z M467.89,615.5c0.29,0.37,0.58,0.75,0.88,1.12
				c10.07-3.55,20.18-6.99,30.17-10.74c1.88-0.71,3.37-2.58,4.89-4.07c8.05-7.9,15.97-15.95,24.11-23.75c3.74-3.59,6.27-13,4.62-17.2
				c-4.4,3.3-8.93,6.75-13.52,10.12c-4.82,3.54-9.27,8.06-14.65,10.26c-11.51,4.71-18.09,14.75-26.67,22.59
				C473.99,607.22,471.14,611.58,467.89,615.5z M789.54,602.31c-2.55-3.42-4.69-6.89-7.41-9.83c-6.18-6.68-11.03-14.08-14-22.73
				c-1.94-5.65-8.24-11.98-13.31-13.35c1,2.42,1.85,4.53,2.74,6.62c2.16,5.05,3.9,9.89,2.63,15.83c-0.62,2.9,1.18,7.88,3.55,9.59
				c7.57,5.44,15.92,9.79,23.99,14.53C787.92,603.07,788.33,602.77,789.54,602.31z"/>
			<path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M337.71,1474.17c3.81-0.94,6.32-0.89,8.19,2.52c1.75,3.19,4.3,1.91,6.79,0.27c1.37-0.91,3.73-2,4.69-1.43
				c1.13,0.67,1.76,3.11,1.66,4.72c-0.24,4.09,1.69,4.74,5.3,5.11c3.63,0.37,7.11,2.14,11.86,3.68c1.94,4.15,5.69,5.64,11.64,3.19
				c2.54-1.05,4.97-2.5,7.61-3.09c0.96-0.22,3.04,1.27,3.43,2.39c1.08,3.07,0.83,3.16,4.36,2.59c-0.8-0.99-2.29-2-2.48-3.21
				c-0.7-4.48-3.47-4.14-6.63-3.32c-3.57,0.92-7.26,1.53-10.66,2.87c-4.1,1.62-5.04-0.21-5.28-3.71c-0.05-0.72-0.76-1.4-1.52-2.71
				c-5.54,5.34-9.87-1.65-15.45-1.23c0.16-5.15-3.64-10.74,1.83-15.52c0.47-0.41,0.72-1.99,0.41-2.27c-3.9-3.43-2.28-7.53-1.66-11.57
				c0.68-4.43-1.69-6.7-5.82-7.22c0.39-8.14,0.36-8.04-7.36-10.89c-2.38-0.88-4.4-1.55-2.87-5.69c1.89-5.1,1.75-10.98,2.22-16.55
				c0.07-0.82-1.41-1.71-1.98-2.67c-1.18-1.99-2.24-4.06-3.33-6.1c-0.07-0.13,0.04-0.37-0.04-0.49c-3.38-4.85-4.64-11.52-11.18-13.67
				c-1.56-0.51-3.45-0.07-5.17,0.05c-0.43,0.03-0.84,0.82-1.22,0.79c-4.93-0.4-9.85-0.89-13.15-1.2c-4.46,3.14-7.85,6.23-11.84,7.93
				c-1.33,0.57-4.49-2.1-6.25-3.85c-2.86-2.82-5.09-6.34-9.92-3.21c-0.51,0.33-3.12-1.1-3.31-2.01c-0.61-2.97-0.98-5.8-4.92-5.53
				c-3.73,0.26-3.91,2.74-4.31,5.94c-0.36,2.84-2.49,5.53-4.16,8.07c-0.34,0.52-2.22,0.41-3.22,0.07c-6.88-2.35-7.4-2.12-9.02,5.22
				c-3.86,0-7.89-0.24-11.87,0.09c-2.69,0.22-5.58,0.76-7.91,2.04c-4.13,2.26-8.06,3.59-12.79,2.05c-3.28-1.07-12.53,2.35-13.84,5.06
				c-0.64,1.32-0.26,3.14-0.35,5.05c-13.71,3.48-16.87,15.15-20.08,26.51c-5.32-5.36-11.38-10.14-15.61-16.21
				c-2.6-3.72-5.76-5.99-8.78-8.87c-2.86-2.72-4.01-7.73-4.87-11.94c-1.62-7.91-3.18-9.23-11.07-9.05c-2.66,0.06-5.9,0.85-8.38-2.1
				c-0.69-0.82-4.05,0.03-5.78,0.92c-0.73,0.38-0.98,3.1-0.42,4.27c1.07,2.25,1.8,3.98,0.07,6.33c-0.62,0.84,0.07,2.84,0.5,4.21
				c1.3,4.08,2.76,8.1,4.42,12.89c-1.93-0.42-3.06-0.67-4.93-1.08c1.46,3.06,2.7,5.65,4.18,8.74c-4.51-0.48-8.64-1.08-12.79-1.31
				c-2.31-0.13-4.8,5.05-3.51,7.52c1.06,2.02,2.54,3.93,4.2,5.5c2.73,2.57,5.75,4.82,8.05,8.18c-2.76-1.51-5.58-2.91-8.25-4.56
				c-2.79-1.72-5.44-3.68-8.28-5.62c-1.38,1.49-2.39,3.55-3.85,3.94c-3.01,0.81-5.37-0.25-6.73-3.7c-0.86-2.19-3.11-3.83-4.73-5.71
				c-1.6,1.91-3.2,3.81-4.81,5.72c-0.29,0.62-0.58,1.24-0.86,1.86c-2.31-3.6-4.62-7.2-6.92-10.8c-0.5,0.91-1.01,1.82-1.51,2.73
				c-2.57-0.13-5.21,0.1-7.68-0.46c-4.08-0.92-8.23,1.06-10.33,4.55c-1.05,1.74-3.45,2.66-5.87,4.41c0.62,0.94,1.38,2.24,2.29,3.42
				c0.89,1.14,1.94,2.16,3.09,3.43c-2.62,0.83-5.64,0.9-6.79,2.4c-1.1,1.44-0.24,4.36-0.24,6.81c-6.71-2.64-7.3-2.34-8.51,3.42
				c-2.59,0.61-4.92,1.15-7.26,1.7c1.16,3.47,2.32,6.93,3.4,10.16c-1.23-1.07-3.03-2.81-5.03-4.26c-0.83-0.6-2.31-1.09-3.11-0.74
				c-0.81,0.35-1.71,1.86-1.61,2.76c0.56,5.1,1.4,10.16,2.11,14.95c-2.61,2.96-5.35,6.14-8.18,9.23c-0.6,0.65-1.55,1.39-2.35,1.4
				c-9.62,0.1-19.24,0.06-28.86,0.06c-0.33-0.5-0.66-1.01-1-1.51c1.15-1.08,2.14-2.79,3.48-3.14c4.29-1.1,8.81-1.34,13.05-2.58
				c6.83-1.99,13.5-4.51,20.24-6.81c-0.14-0.43-0.29-0.86-0.43-1.29c-11.72,2.84-23.45,5.69-35.55,8.62c0-21.45,0-42.23,0-63.65
				c12.38-3.01,24.9-6.06,37.43-9.11c-0.06-0.25-0.12-0.5-0.19-0.74c-4.35,0-8.7,0-12.89,0c9.76-2.72,19.7-5.49,29.64-8.26
				c0.06-0.18,0.12-0.35,0.18-0.53c-2.13,0-4.31-0.26-6.4,0.05c-9.46,1.4-18.74,2.68-28.53,1.51c-6.04-0.72-12.54,2.39-18.83,3.79
				c-0.34-0.34-0.68-0.68-1.03-1.02c0.99-1.05,1.82-2.71,3.01-3.05c4.3-1.22,8.77-1.85,13.1-2.99c4.14-1.09,8.15-2.68,12.29-3.78
				c3.32-0.89,6.72-1.74,10.13-1.96c9.21-0.61,18.44-0.84,27.65-1.34c1.62-0.09,3.26-0.63,4.82-1.18
				c10.97-3.85,22.15-6.57,33.86-6.67c0.67-0.01,1.37,0.13,1.99-0.05c7.04-2.03,14.05-4.16,21.1-6.14c1.71-0.48,3.54-0.52,5.31-0.75
				c11.97-1.52,23.95-3.03,35.92-4.56c0.49-0.06,0.98-0.38,1.45-0.34c6.63,0.59,12.04-2.01,17.59-5.46
				c3.89-2.42,9.87-1.81,13.32-4.56c10.15-8.11,22.22-10.21,34.19-13.03c6.43-1.51,12.79-3.38,19.15-5.17c0.88-0.25,1.63-1,3.12-1.96
				c-5.87-4.09-12.06-1.14-17.93-2.61c0.64-0.51,1.52-0.84,1.69-1.4c2.19-7.16,8.69-6.71,14.14-8.22c1.81-0.5,3.49-1.49,4.99-3.41
				c-10.22,0-20.44,0-30.6,0c7.01-3.41,13.8-6.78,20.66-10.01c2.09-0.98,4.36-2.28,6.54-2.26c10.32,0.09,19.74-3.88,29.55-6.05
				c2.98-0.66,6.24,0.05,9.31-0.35c2.29-0.3,4.44-1.52,6.71-2.09c3.25-0.82,6.57-1.4,9.84-2.14c6-1.36,11.95-2.94,17.98-4.1
				c4.98-0.96,10.2-2.57,15.04-1.9c6.28,0.88,11.63-0.24,17.32-2.51c4.17-1.66,8.84-2.29,13.35-2.8c3.47-0.39,5.84-0.28,5.57-5.24
				c-0.28-5,1.18-10.1,1.88-15.15c-0.47-0.15-0.95-0.3-1.42-0.45c0.74-0.32,1.48-0.94,2.21-0.93c14.23,0.26,25.87-9.15,39.71-10.62
				c3.28-0.35,6.17-4.23,9.47-4.64c11.05-1.36,22.2-1.89,33.36-1.65c-6.24,2.5-12.48,5-18.72,7.51c0.09,0.48,0.17,0.95,0.26,1.43
				c1.4,0.43,2.79,0.85,4.19,1.28c0.01,0.26,0.03,0.51,0.04,0.77c-1.35,0.12-2.69,0.23-4.04,0.37c-10.86,1.09-21.87,1.37-32.52,3.52
				c-9.01,1.82-17.65,5.46-27.04,5.67c-0.71,0.02-1.4,0.66-1.96,1.76c5.33-0.3,11.44,0.96,15.83-1.23
				c9.18-4.58,19.06-4.26,28.56-6.21c7.1-1.46,14.5-1.41,21.74-2.23c2.34-0.27,4.6-1.18,6.7-2.78c-1.86-0.53-3.72-1.05-5.58-1.58
				c-0.14-0.53-0.28-1.06-0.42-1.59c5.97-1.9,11.94-3.8,17.91-5.7c0.02-0.44,0.05-0.89,0.07-1.33c-1.82-0.69-3.65-1.38-5.47-2.07
				c-0.01-0.24-0.02-0.49-0.03-0.73c7.58,0,15.17,0.05,22.75-0.01c21.95-0.18,43.9-0.3,65.85-0.72c3.15-0.06,5.06,0.36,7.03,3.04
				c5.73,7.76,10.79,16.52,18.06,22.54c19.37,16.02,21.84,38.41,24.46,60.61c1.65,13.97,1.74,28.25,1.11,42.33
				c-0.3,6.7-3.9,13.25-6.08,19.85c-0.18,0.56-0.78,1.19-1.33,1.4c-5.7,2.15-8.83,6.16-9.45,12.29c-0.09,0.85-1.38,1.89-2.34,2.3
				c-4.69,2-9.42,3.96-14.23,5.64c-4.19,1.47-8.84,1.94-12.72,3.93c-10.36,5.32-21.31,8.38-32.73,10.04
				c-12.5,1.81-23.87,6.41-35.08,12.23c-7.51,3.9-16.05,6.64-24.43,7.87c-21.91,3.21-39.59,14.13-55.39,28.61
				c-6.04,5.54-12.2,7.73-20.46,7.65c-45.6-0.39-91.21-0.19-136.82-0.19c-1.99,0-3.97,0-5.96,0c-0.18-0.31-0.37-0.62-0.55-0.93
				c2.98-2.33,5.95-4.66,8.82-6.9c2.47,1.42,4.75,2.73,8.01,4.6c0-3.74,0-6.04,0-8.73c5.94,4.62,6.66,4.4,11.58-1.48
				c1.87-2.24,3.81-4.4,7.59-2.32c3.71,2.04,6.62-0.76,9.27-3.19c1.22-1.12,2.53-2.14,4.49-3.79c0.53,5.86,2.27,9.52,7.77,9.12
				c1.36,7.49,2.66,8.49,8.22,8.56c6.13,0.07,5.9-3.98,5.77-7.14c2.81-2.12,5-3.83,7.26-5.45c0.63-0.45,1.57-1.1,2.12-0.92
				C336.37,1481.52,337.82,1478.71,337.71,1474.17z"/>
			<path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M593.27,1126.59c-3.63,2.43-3.45,6.07-2.74,9.64c1.38,6.96,6.13,11.86,12.12,14.36
				c13.8,5.75,24.72,15.5,36.31,24.36c6.91,5.28,14.98,7.61,23.22,9.86c7.34,2.01,14.41,4.96,21.59,7.54
				c3.12,1.12,6.15,2.54,9.32,3.49c13.5,4.04,26.93,8.44,40.6,11.79c16.76,4.11,33.73,7.34,50.62,10.91
				c16.28,3.43,30.98,9.86,42.91,21.89c3.67,3.7,7.85,6.9,11.12,11.26c-0.99-0.29-1.98-0.57-3.6-1.04
				c4.03,5.99,7.72,11.49,11.42,16.99c-0.32,0.25-0.64,0.49-0.96,0.74c-0.95-0.89-1.91-1.79-2.96-2.77c-0.29,0.65-0.6,1.01-0.51,1.19
				c3.52,7.35,6.1,14.64,5.62,23.26c-0.38,6.82,2.72,13.77,2.79,20.68c0.12,12.52,0.16,25.21-1.85,37.49
				c-0.9,5.51-0.75,11.7-2.95,16.58c-4.38,9.71-2.05,21.28-9.04,30.01c-1.34,1.67-2.89,3.18-4.18,4.59
				c-2.45,6.4-4.93,12.87-7.4,19.34c-0.45-0.12-0.9-0.23-1.35-0.35c0.16-1.57,0.33-3.14,0.49-4.71c-0.51-0.43-1.03-0.86-1.54-1.29
				c-0.49,1.34-0.97,2.68-1.66,4.57c-0.52-2.02-0.82-3.19-1.49-5.82c-0.7,2.73-1.04,4.01-1.46,5.65c-2.2-5.91-1.66-11.09,0.81-16.56
				c2.99-6.64,5.29-13.62,7.62-20.54c2.4-7.13-0.26-12.64-6.08-16.97c-2.53-1.88-5.1-3.76-7.36-5.95
				c-11.95-11.59-12.62-25.98-2.68-39.46c6.96-9.44,13.58-19.21,14.65-31.54c0.93-10.67-1.11-20.62-6.86-29.84
				c1.13,4.72,2.67,9.39,3.29,14.18c0.68,5.24,1.15,10.67,0.56,15.88c-0.6,5.21-1.28,11.41-4.39,15.08
				c-3.57,4.22-5.25,9.06-7.84,13.53c-3.28,5.65-6.34,11.55-8.56,17.68c-2.47,6.86-0.84,13.73,3.68,19.3
				c3.84,4.73,8.31,9.04,12.99,12.96c6.34,5.32,8.14,8.13,5.22,15.94c-4.99,13.37-10.53,26.56-16.6,39.47
				c-1.78,3.78-5.95,6.57-9.38,9.37c-1.18,0.97-3.68,1.22-5.17,0.68c-2.03-0.73-3.66-2.53-5.47-3.87c1.83-0.9,3.66-1.8,6.04-2.96
				c-0.38-0.9-1.13-2.69-1.95-4.64c5.92-7.34,9.04-16.44,10.59-26.02c1.44-8.86-1.94-16.57-8.25-22.56
				c-5.52-5.24-11.48-10.18-17.82-14.38c-15.91-10.54-27.05-24.82-35.11-41.91c-3.67-7.78-4.18-15.62-2.24-23.81
				c0.25-1.06,0.33-2.15,0.5-3.23c-0.53-0.16-1.06-0.31-1.58-0.47c-0.76,2.6-1.35,5.27-2.35,7.78c-0.53,1.32-1.62,3.22-2.71,3.4
				c-20.6,3.55-41.19,7.26-61.89,10.03c-5.88,0.79-12.14-1.35-18.24-2c-3.98-0.42-5.87-2.51-5.86-6.45c0.42-0.23,1.21-0.46,1.21-0.69
				c-0.03-6.41-2.71-11.42-8.41-14.71c0.99,1.37,1.99,2.74,2.29,3.16c-0.44,1.61-0.99,2.51-0.74,2.93c1,1.68,2.26,3.22,3.42,4.81
				c-0.51,0.44-1.52,1.18-1.46,1.29c5.19,9.17,1.77,18.45,0.35,27.66c-2.56,16.64-4.37,33.27-3.08,50.19
				c0.35,4.56-1.32,9.36-2.52,13.92c-1.51,5.75-3.49,11.37-5.33,17.23c-11.65,4.99-23.78,10.18-36.49,15.62
				c-0.7-4.95,1.72-7.14,4.51-8.82c1.8-1.09,3.97-1.7,6.06-2.15c5.22-1.12,7.89-4.47,10.23-9.16c6.14-12.31,6.97-25.02,4.94-38.34
				c-2.54-16.74-4.47-33.58-7.07-50.31c-1.89-12.15-9.53-23.05-18.88-27.78c0.26,0.89,0.23,1.65,0.59,1.92
				c13.43,10.35,16.92,25.49,19.01,40.94c2.32,17.13,4.83,34.37,4.99,51.59c0.08,8.44-4.72,17.01-7.88,25.32
				c-0.65,1.7-3.79,2.45-5.95,3.74c-0.41-1.21-0.68-1.6-0.58-1.79c9.11-16.86,6.54-35.09,5.31-52.86
				c-0.94-13.69-4.03-27.26-6.57-40.8c-0.75-4.01-2.99-7.72-4.33-11.65c-0.52-1.52-0.43-3.26-0.75-5.94
				c-15.28-9.05-24.53-25.15-35.9-39.4c-6.84-8.57-12.41-17.63-15.71-28.01c-1.24-3.91-1.37-8.16-2.14-12.23
				c-0.7-3.71-1.53-7.41-2.44-11.07c-0.11-0.45-1.08-0.68-2.46-1.49c-2.66-11.04-0.56-23.19,2.27-35.09
				c3.59-15.13,2.55-29.64-2.86-44.45c1.46,1.55,3.01,3.02,4.34,4.67c1.41,1.75,2.62,3.66,4.27,5.23
				c-1.56-6.63-6.08-11.02-11.49-14.57c-3.32-2.18-7.13-3.64-10.4-5.88c-2.55-1.75-4.62-4.22-6.9-6.38c0.27-0.48,0.53-0.97,0.8-1.45
				c7.36-0.75,14.72-1.46,22.07-2.26c9.36-1.01,12.3-4.02,14.1-13.16c1.81-9.17-0.59-17.82-2.38-26.6c-0.31-1.55,0.27-3.27,0.44-4.92
				c1.63,0.49,3.23,1.24,4.89,1.42c9.28,1,10.79,9.42,14.4,15.49c3.68,6.2,6.84,11.98,14.28,15.01c4,1.63,7.36,6.25,9.71,10.28
				c8.83,15.17,18.25,29.8,31.53,41.54c0.86,0.76,1.52,1.76,2.23,2.68c0.4,0.51,0.7,1.1,0.47,2.4c-2.64-1.93-5.17-4.04-7.94-5.75
				c-3.29-2.04-6.79-3.74-10.2-5.58c-5.63-3.27-11.22-6.62-16.96-9.7c-0.54-0.29-1.99,1.15-3.02,1.79L593.27,1126.59z
				M566.38,1147.54c-4.67,4.9-9.09,10.09-14.11,14.59c-3.4,3.04-5.49,8.75-3.7,12.51c2.31-4.36,4.73-8.98,7.23-13.57
				c0.46-0.85,1.11-1.98,1.9-2.22C563.45,1157.17,564.31,1151.81,566.38,1147.54z M813.4,1322.11c-5,8.85-5.63,25.8-1.67,30.37
				C812.26,1342.74,812.83,1332.36,813.4,1322.11z M796.66,1349.93c-0.16,0.12-0.33,0.24-0.49,0.36c3.09,3.6,5.97,7.41,9.32,10.75
				c4.32,4.31,11.66,5.94,11.4,14.22c1.53-4.94,1.11-6.95-1.96-9.64C808.89,1360.33,802.76,1355.16,796.66,1349.93z M770.65,1345.63
				c0.33-0.27,0.66-0.54,0.99-0.81c0.03-0.32,0.19-0.85,0.06-0.92c-4.85-3.06-4.15-10.42-10.21-12.86c-1.6-0.65-2.06-4.15-3.04-6.34
				C758.58,1334.2,765.99,1339.02,770.65,1345.63z M832.93,1277.27c0.39-0.19,0.78-0.39,1.17-0.58c-2-5.79-2-12.64-8.81-16.21
				C827.92,1266.25,830.42,1271.76,832.93,1277.27z M751.18,1315.41c-0.23,7.53,2.74,14.59,7.25,17.51
				C756.07,1327.21,753.58,1321.21,751.18,1315.41z M548.04,1180.73c-0.44,0-0.87,0-1.31,0c0,4.98,0,9.97,0,14.95
				c0.44,0,0.87,0,1.31,0C548.04,1190.7,548.04,1185.71,548.04,1180.73z M592,1107.34c0.55-0.1,1.09-0.19,1.64-0.29
				c-1.15-3.79-2.3-7.58-3.45-11.38c-0.73,0.24-1.45,0.48-2.18,0.72C589.34,1100.03,590.67,1103.69,592,1107.34z M788.76,1257.6
				c-0.45-0.01-0.9-0.01-1.35-0.02c-0.62,4.12-1.25,8.24-1.87,12.36c0.53,0.07,1.06,0.14,1.58,0.21
				C787.67,1265.98,788.21,1261.79,788.76,1257.6z M586.09,1095.4c-1.88-2.65-3.77-5.29-5.65-7.94c-0.46,0.32-0.92,0.65-1.38,0.98
				C579.46,1092.14,582.04,1094.35,586.09,1095.4z M565.52,1067.1c-0.62,0.22-1.24,0.44-1.86,0.66c0.88,2.38,1.75,4.76,2.63,7.14
				c0.58-0.2,1.16-0.39,1.74-0.59C567.19,1071.9,566.36,1069.5,565.52,1067.1z M643.17,1259.52c0.41-0.41,0.81-0.82,1.22-1.22
				c-3.24-2.76-6.49-5.52-9.73-8.28c-0.29,0.34-0.59,0.68-0.88,1.02C636.91,1253.86,640.04,1256.69,643.17,1259.52z M640.66,1251.73
				c0.32-0.47,0.64-0.94,0.96-1.41c-3.36-2.14-6.73-4.27-10.09-6.41c-0.24,0.38-0.48,0.76-0.73,1.15
				C634.09,1247.28,637.38,1249.51,640.66,1251.73z M595.59,1118.73c0.5,0.27,0.99,0.55,1.49,0.82c0.91-2.52,1.83-5.05,2.74-7.57
				c-0.55-0.2-1.11-0.41-1.66-0.61C597.3,1113.82,596.45,1116.27,595.59,1118.73z"/>
			<path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M349.63,948.85c-3.26-6.12-5.87-12.75-10.05-18.15c-2.12-2.74-7.04-3.42-10.79-4.77
				c-2.36-0.85-5.44-0.52-7.25-1.93c-3.78-2.94-7.02-6.6-10.27-10.16c-0.69-0.76-0.82-2.4-0.64-3.54
				c3.04-19.69,6.47-39.33,9.15-59.07c1.34-9.86,1.58-19.9,1.84-29.87c0.1-3.62,1.28-5.64,4.68-5.27c9.54,1.03,17.04-3.96,25.38-7.19
				c12.9-4.99,25.15-11.13,37.46-17.25c4.06-2.01,7.99-3.22,11.77,0.83c0.88,0.94,2.71,1.07,4.14,1.41
				c4.66,1.11,9.34,2.14,14.19,3.24c-8.29,17.69-24.44,25.73-37.14,37.2c0.38,0.51,0.75,1.02,1.13,1.54
				c4.8-3.26,10.16-5.94,14.28-9.91c7.22-6.94,16.27-12.27,20.51-22.09c0.81-1.87,2.7-3.39,4.35-4.75c3.46-2.84,6.93-3.29,10.77-0.2
				c4.19,3.38,8.18,7.33,14.69,4.35c2.83-1.3,6.28,2.79,6.07,6.18c-1.39,21.94-1.49,43.81,0.61,65.79
				c2.03,21.18,2.41,42.53,3.43,63.8c1.44,29.92,2.78,59.84,4.21,89.76c0.23,4.81,0.54,9.63,1.12,14.41
				c1.63,13.28,9.26,22.83,19.45,30.71c0.91,0.71,1.94,1.61,2.99,1.73c8.01,0.87,7.62,6.41,7.52,12.39
				c-0.3,17.82-0.28,35.64-0.34,53.46c-0.04,12.96-0.01,25.92-0.01,39.11c-7.41-1.84-11.47-0.03-15.72,6.47
				c-2.33-2.6-4.61-3.51-7.02,0.04c-0.85,1.26-2.49,2.02-3.84,2.91c-5.69,3.73-5.83,4.75-0.34,10.56
				c-11.05-0.34-19.65,2.24-20.92,15.76c-0.59-2.55-1.2-4.05-1.24-5.56c-0.14-4.9-2.56-7.27-7.41-7.53
				c-1.25-0.06-3.13-0.46-3.57-1.31c-2.16-4.14-5.86-4.5-9.85-5.24c-3.6-0.67-5.95-0.72-6.02,3.84c-10.88-8.8-21.24-17.2-27.18-30.1
				c-4.71-10.22-14.86-15.9-22.6-23.61c-8.89-8.86-16.61-18.88-24.94-28.31c-3.84-4.35-7.83-8.58-11.89-12.72
				c-2.08-2.12-4.48-3.94-6.67-5.84c2.04-6.06,2.04-6.06-8.71-21.32c7.28,1.29,13.8,2.43,20.32,3.6c7.14,1.28,11.54-3.46,16.39-6.78
				c-6.14-8.61-11.96-16.77-17.78-24.94c0.33-0.39,0.65-0.78,0.98-1.17c1.71,0.12,3.41,0.3,5.12,0.34c4.75,0.12,8.43-1.64,10.37-6.22
				c2.49-5.87,6.57-9.14,13.28-8.77c1.4,0.08,3.12-0.9,4.25-1.9c1.73-1.52,2.97-3.58,4.59-5.24c4.04-4.14,6.02-8.35,1.39-13.57
				c1.55,1.73,3.26,3.33,4.62,5.2c12.34,16.98,25.44,33.28,40.03,48.44c4.84,5.03,7.58,12.03,11.49,18
				c3.36,5.13,7.03,10.07,10.56,15.09c0.65-0.37,1.29-0.74,1.94-1.11c-2.02-5.15-4-10.31-6.08-15.44
				c-3.73-9.2-7.44-18.41-11.32-27.55c-3.5-8.24-7.72-16.22-10.74-24.62c-5.19-14.44-12.93-27.25-22.79-38.86
				c-1.61-1.89-3.42-3.67-5.4-5.16c-6.47-4.87-11.66-10.71-15.78-17.77c-2.82-4.82-6.87-8.91-10.89-13.99
				c2.7-1.71,5.57-3.53,8.45-5.33c1.55-0.97,3.18-1.81,4.7-2.81c11.15-7.39,11.63-7.71,20.86,2.01c8.9,9.38,17.17,19.37,25.32,29.42
				c2.43,3,4.23,6.92,5.09,10.7c2.17,9.56,8,17.08,12.89,25.14c0.58,0.95,1.46,1.71,3.44,2.31c-0.26-2.06-0.26-4.2-0.83-6.16
				c-1.06-3.66-2.8-7.15-3.6-10.85c-0.63-2.9-0.31-6.02-0.31-9.04c0-1.45,0.78-3.13,0.29-4.33c-3.48-8.56-7.09-17.08-10.96-25.47
				c-0.53-1.16-2.68-1.56-4.05-2.35c-4.36-2.53-9.54-4.28-12.88-7.79c-7.29-7.68-13.61-16.28-20.39-24.46
				c-1.69-2.03-3.35-4.27-5.49-5.71c-4.2-2.83-3.31-5.84-1.23-9.34c2.1-3.54,3.9-7.27,6.03-10.8c0.99-1.64,2.25-3.28,3.77-4.43
				c4.98-3.77,5.44-6.54,1.07-12.76c-1.58,2.84-3.31,5.22-4.32,7.88c-2.32,6.08-6.34,10.47-12.09,13.33
				c-5.96,2.96-8.28,5.96-4.53,14.91c2.09,4.97,5.25,9.5,6.99,14.95c-1.3-1.49-2.89-2.82-3.85-4.5c-2.79-4.9-5.16-10.06-8.04-14.91
				c-1.76-2.95-3.73-6.08-6.37-8.13c-3.61-2.8-7.35-5.09-8.11-10.12c-0.12-0.79-1.16-1.69-1.99-2.09
				c-5.96-2.82-8.78-8.34-12.11-13.52c-2.76-4.3-6.01-8.29-9.01-12.36c-6.57,3.75-8.56,9.01-6.09,15.69c1.78,4.8,3,9.37,2.71,14.84
				c-0.24,4.45,2.74,9.3,5.07,13.55c1.45,2.66,4.14,4.79,6.61,6.73c8.61,6.78,10.79,16.78,13.57,26.49c0.14,0.51-0.4,1.21-0.62,1.82
				L349.63,948.85z M436.1,854.52c-2.17,6.83-2.77,6.83-9.75,5.4c-8.16-1.68-14.46-6.47-20.91-11.15c-2.34-1.7-4.43-2.77-7.72-1.62
				c-4.81,1.67-9.09,0.61-12.89-3.7c-2.7-3.06-6.78-5.02-10.49-7.03c-2.05-1.11-4.76-0.96-6.91-1.95
				c-7.97-3.63-15.84-7.51-23.76-11.25c-0.51-0.24-1.19-0.11-1.78-0.15c-0.3,0.4-0.6,0.81-0.9,1.21c3.77,3.21,7.05,7.57,11.4,9.42
				c11.5,4.89,20.74,12.29,28.63,21.74c0.9,1.08,2.51,1.52,3.64,2.45c2.23,1.83,5.33,3.37,6.35,5.77
				c4.34,10.21,11.9,16.96,21.27,22.28c2.13,1.21,4.22,3.49,5.08,5.75c2.05,5.39,3.41,11.06,4.96,16.63
				c1.2,4.29,3.38,7.77,8.66,10.79c-0.27-3.99-0.07-7.02-0.74-9.85c-1.15-4.83-3.31-9.44-4.16-14.3c-0.84-4.83-1.98-10.32-0.52-14.68
				c3.26-9.69,9.81-12.52,20.03-10.55c0.43,0.08,0.93-0.19,1.36-0.28C447.57,863.01,443.55,857.41,436.1,854.52z"/>
			<path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M972.68,213.6c10.76,0,21.87-0.23,32.96,0.04c30.43,0.75,60.85,1.84,91.28,2.51
				c6.28,0.14,12.58-1.06,18.89-1.39c14.91-0.78,29.82-1.42,45.28-2.14c0.13,2.67,0.29,4.43,0.29,6.19
				c0.01,62.78-0.01,125.55,0.08,188.33c0.01,3.46-0.85,5.93-3.57,8.16c-2.94,2.41-5.59,5.24-8.11,8.11
				c-4.13,4.7-8.69,8.14-15.05,9.87c-4.39,1.2-8.43,4.56-11.99,7.69c-3.1,2.72-6.05,2.06-8.64,0.5c-1.44-0.87-2.29-3.59-2.46-5.56
				c-2.47-28.68-5.87-57.11-15.84-84.44c-4.04-11.06-17.7-18.41-29.27-16.69c-5.81,0.86-10.12,4.03-11.8,9.46
				c-1.9,6.12-3.67,12.54-3.93,18.89c-1.14,27.76-1.73,55.54-2.47,83.31c-0.21,7.78-0.07,8.27-8.04,9.58
				c-5.41,0.89-11.24,1.02-16.59-0.01c-9.93-1.9-19.58-5.21-29.49-7.28c-3.71-0.77-7.83,0.07-11.7,0.58
				c-2.9,0.38-5.71,1.42-8.88,2.24c-0.28-1.55-0.65-2.64-0.66-3.73c-0.32-35.12-1.06-70.25-0.76-105.37
				c0.25-29.45,2.04-58.88,2.47-88.33C974.85,240.69,973.4,227.25,972.68,213.6z"/>
			<path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M567.37,1059.32c-1.62-2.75-2.7-4.58-3.86-6.55c11.42-14.08,11.34-31.51,14.43-48.78
				c8.27,3.43,17.17,5.35,21.31,14.7c1.28,2.88,2.73,5.71,4.38,8.4c4.05,6.62,11.92,9.29,20.66,6.66
				c-5.99-13.3-14.91-25.68-12.77-41.96c6.45,0,14.08-1.11,21.13,0.43c3.95,0.86,7.09,5.99,10.29,9.47c1.53,1.67,2.29,4.03,3.52,6.01
				c4.1,6.62,8.36,8.24,16.04,6.77c2.65-0.51,5.47-0.08,8.9-0.08c-1.8-4.65-2.57-9.19-7.69-12.57c-4.07-2.69-6.92-7.76-9.32-12.3
				c-2.46-4.64-3.74-9.91-5.48-14.93c-1.42-4.1,3.51-15.77,7.6-18.18c4.42-2.6,4.86-3.45,4.13-9.98c7.48,8.03,14.07,15.08,20.6,22.17
				c0.37,0.4,0.63,1.39,0.4,1.79c-2.19,3.79,0.73,5.84,2.7,8.17c4.93,5.82,10.43,11.24,14.75,17.47c5.23,7.54,12.5,9.9,20.71,9.14
				c5-0.46,9.2,0.37,13.29,2.69c9.08,5.15,18.21,10.24,27.09,15.72c7.35,4.53,14.4,9.55,21.53,14.43c2.49,1.7,4.65,2.34,7.78,0.95
				c2.36-1.05,5.66-1.23,8.16-0.5c8.89,2.58,17.75,5.33,24.72,12.1c1.94,1.88,4.73,3.43,7.36,3.96c11.3,2.29,20.27,8.73,29.46,15.11
				c8.71,6.05,18.1,11.11,26.96,16.95c3.94,2.6,7.61,5.78,10.89,9.18c7.93,8.23,15.18,17.14,23.48,24.97
				c4.09,3.86,9.81,6.06,14.92,8.75c4.03,2.12,6.13,5.12,5.08,9.65c-2.7,11.59-0.69,23.12-0.28,34.73c0.39,10.9-1,21.86-1.47,32.79
				c-0.08,1.93,0.43,3.9,0.72,5.84c0.36,2.45,1.15,4.91,1.1,7.35c-0.13,6.42-0.96,7.07-7.19,7.8c-6.77,0.79-13.5,1.99-20.28,2.5
				c-2.34,0.18-5.33-0.53-7.12-1.96c-8.59-6.83-16.78-14.16-25.33-21.03c-2.21-1.78-5.21-2.57-7.87-3.8c-1.8-0.83-3.72-1.44-5.4-2.46
				c-4.98-3.02-9.98-6.01-14.76-9.33c-15.05-10.46-32.83-14.09-49.61-20.25c-3.74-1.37-7.54-3.57-10.32-6.38
				c-10.76-10.88-22.03-21.46-31.4-33.48c-11.73-15.04-29.05-22.9-42.32-35.83c-10.24-9.97-21.12-19.29-31.76-28.85
				c-1.84-1.66-3.66-3.69-5.88-4.53c-10.96-4.17-21.63-9.18-33.88-9.06c-8.67,0.09-16.25-4.19-23.78-8.21
				c-4.05-2.16-8.35-4.25-12.78-5.23c-7.07-1.57-13.26,1.61-19.21,5.2C583.78,1049.75,575.76,1054.38,567.37,1059.32z"/>
			<path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M582.86,937.03c0.55-12.41,1.43-24.82,1.55-37.24c0.15-15.27-0.29-30.55-0.6-45.83
				c-0.09-4.32-0.46-8.64-0.97-12.93c-0.73-6.07-9.75-11.68-15.13-8.99c-2.1,1.05-4.03,4.09-4.46,6.51
				c-0.9,5.03-0.94,10.25-1.01,15.39c-0.41,30.97-0.57,61.94-1.13,92.91c-0.43,23.99-2.64,47.83-8.21,71.23
				c-1.6,6.74-4.57,12.95-10.31,17.21c-2.39,1.77-5.33,2.81-8.72,4.54c-0.46-9.07-1.08-16.81-1.19-24.57
				c-0.23-16.78-0.28-33.57-0.14-50.35c0.01-1.69,1.71-3.44,2.83-5.02c3.44-4.8,7.81-9.12,10.34-14.34
				c11.48-23.63,13.61-48.78,10.59-74.52c-0.42-3.55-1.6-7.17-3.17-10.39c-1.71-3.5-5.03-5.51-9.08-4.84
				c-4.09,0.68-4.9,4.14-5.08,7.6c-0.65,12.46-1.02,24.93-1.85,37.38c-0.32,4.82-1.57,9.58-3.14,14.15c0-37.83,0-75.67,0-113.95
				c8.12,1.66,15.06,3.21,20.91,8.74c7.36,6.96,15.29,13.39,23.51,19.33c7.04,5.09,14.81,9.1,24.12,5.59
				c1.11-0.42,2.51-0.06,4.43-0.06c-2.04,5.25,0.36,7.83,4.19,10.4c1.93,1.29,3.45,4.47,3.63,6.91c0.75,10.18,0.53,10.21,10.17,13.84
				c4.19,1.58,8.35,3.32,12.66,4.49c1.9,0.52,4.97,0.61,6.09-0.51c5.42-5.46,11.22-2.2,16.66-0.99c6.29,1.4,12.33,3.91,18.53,5.79
				c6.31,1.92,7.07,1.36,9.27-6.47c-2.96-1.11-6.1-2.02-9-3.43c-4.87-2.39-9.76-4.83-14.29-7.78c-2.47-1.61-3.3-3.98-0.88-7.15
				c2.65-3.48,4.26-7.75,6.54-12.08c-4.99-8.43-11.8-16.01-24.06-16.53c2.46-2.65,4.26-4.59,6.67-7.19
				c9.38,0.33,20.17-0.72,29.65,6.18c-1.77,1.5-3.36,2.85-6.05,5.13c4.07,2.81,7.34,5.99,11.26,7.58c7.2,2.92,14.88,4.66,22.16,7.39
				c3.23,1.21,5.96,3.73,9.01,5.51c2.64,1.54,5.36,2.98,8.11,4.3c5.15,2.47,10.87,4.1,15.43,7.37c7.27,5.21,13.65,11.64,20.67,17.23
				c3.37,2.69,3.86,5.97,3.6,9.72c-0.32,4.58,0.87,7.62,6.33,6.66c0.32-0.06,0.72-0.06,0.98,0.08c5.7,3.18,8.08,0.25,9.97-4.86
				c0.53-1.44,3.12-2.28,4.92-3.02c0.76-0.31,1.96,0.22,2.88,0.58c7.33,2.87,12.53,0.3,15.84-6.44c1.8-3.65,4.57-3.27,7.6-2.47
				c3.46,0.91,7.56,1.03,8.31,5.77c1.64,10.35,2.79,20.81,4.97,31.04c1.51,7.06,4.35,13.84,6.72,20.71
				c2.16,6.25,4.47,12.45,6.76,18.65c1.66,4.5,3.27,9.04,5.15,13.45c2.98,6.99,6.76,13.68,9.13,20.86
				c4.02,12.17,10.95,22.49,21.22,29.43c9.04,6.12,16.94,14.69,28.43,16.73c4.37,0.77,8.86,1.97,13.17,1.57
				c4.25-0.4,6.3,0.77,7.05,4.66c1.75,9.06,6.79,17.37,6.16,26.97c-0.03,0.49-0.05,1.13,0.22,1.46c9.23,11.22,4.16,25.3,7.32,37.81
				c1.66,6.56-0.44,14.08-1.87,21.6c-8.51-11.75-17.03-23.5-25.54-35.25c-7.89-10.92-18.02-19.27-28.75-27.37
				c-9.46-7.15-17.83-16.04-25.52-25.15c-11.99-14.22-26.57-25.05-41.81-35.24c-1.24-0.83-2.54-1.78-3.94-2.11
				c-13.2-3.11-16.54-13.77-19.63-24.92c-0.81-2.93-2.92-5.64-4.9-8.07c-3.12-3.83-6.94-7.11-9.86-11.07
				c-2.33-3.16-0.98-5.84,2.08-8.51c2.96-2.57,4.94-6.28,7.33-9.5c1.73-2.33,3.27-4.83,5.18-6.99c1.58-1.79,3.59-3.21,5.45-4.83
				c-5.26-5.07-7.6-5.25-12.73-1.39c-0.53,0.4-1.15,1.12-1.63,1.05c-6.19-0.94-12.68-1.15-18.47-3.25
				c-7.07-2.56-7.56-7.43-2.43-12.81c2.56-2.68,2.75-5.08-0.54-7.45c-5.64-4.06-9.7-3.54-14.19,2.03c-4.04,5-8.05,6.37-14.16,4.07
				c-7.77-2.92-14.87-0.91-21.23,3.21c-2.56,1.66-3.59,5.68-6.02,9.83c-8.73-11.86-17.19-22.89-25.08-34.32
				c-2.47-3.58-4.6-5.97-9.27-6.08c-5.12-0.12-10.21-1.35-15.34-1.69c-1.44-0.09-3.54,0.92-4.26,2.1c-0.55,0.88,0.33,3,1.09,4.25
				c0.75,1.22,2,2.38,3.29,2.96c8.49,3.84,13.85,10.84,19.61,17.81c5.24,6.34,12.13,11.29,18.08,17.07
				c5.52,5.36,10.76,11.01,16.12,16.53c-0.3,0.32-0.61,0.63-0.91,0.95c-7.85-3.06-15.7-6.12-24.28-9.46
				c-1.23-11.87-8.92-20.07-21.58-24.04c-0.79-0.25-1.78-0.25-2.35-0.74c-10.59-8.99-23.08-4.77-34.39-4.2
				c-1.56,1.18-2.98,3.1-4.69,3.4c-4.52,0.79-9.16,0.81-13.74,1.26c-1.43,0.14-3.37,0.19-4.15,1.09c-3.96,4.55-9.04,8.26-10.07,15
				c-0.98,6.36-6.14,9.92-10.39,13.98C584.03,937.43,583.45,937.23,582.86,937.03z"/>
			<path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M222.6,333.14c2.58-2.84-0.14-4.43-1.81-5.51c-1.33-0.86-3.86-1.18-5.18-0.47
				c-2.74,1.46-5.03,3.77-7.82,5.98c-1.75-1.55-4.91-3.93-1.35-7.92c1.03-1.16,3.2-1.94,4.79-1.85c14.39,0.83,26.58,8.8,40.08,12.58
				c2.98,0.84,3.3,2.54,1.55,4.77c-4.96,6.3-10.82,12.65-21.07,8.09c-3-1.33-6.94-3.76-9.59-0.11c-2.95,4.06,1.2,6.77,3.08,9.98
				c2.13,3.65,3.68,7.7,4.96,11.73c0.36,1.13-0.83,3.03-1.74,4.25c-2.69,3.6-6.03,6.77-8.28,10.6c-2.05,3.48-4.25,7.64-4.12,11.43
				c0.15,4.34-1.44,6.57-4.59,8.33c-4.42,2.47-4,5.43-1.29,9.1c3.65-1.57,7.43-2.83,10.79-4.8c1.64-0.96,2.95-3.11,3.59-5
				c1.1-3.28,1.16-6.94,2.41-10.15c1.42-3.67,3.66-7.03,5.55-10.52c0.78,0.36,1.56,0.71,2.35,1.07c-2.44,6.69-4.86,13.38-7.32,20.06
				c-0.57,1.56-1.64,3.05-1.75,4.62c-0.08,1.24,0.67,2.9,1.61,3.73c0.68,0.59,2.66,0.62,3.43,0.05c7.28-5.41,14.04-11.11,15.01-21.21
				c0.49-5.09,1.88-10.22,7.42-13.5c2.16,6.03,6.89,7.81,12.79,8.21c1.48,0.1,3.34,2.33,4.06,3.99c0.95,2.19,1.96,5.23,1.15,7.15
				c-2.78,6.55-0.46,10.81,4.18,15.46c3.28,3.28,5.74,4.12,9.3,1.41c1.98-1.5,3.85-3.13,5.14-4.18c0-5.94,0.03-11.14-0.01-16.34
				c-0.02-3.25-0.24-6.5-0.18-9.74c0.02-1.16,0.42-2.38,0.93-3.44c1.28-2.69,2.94-5.22,4.04-7.98c1.8-4.51-0.97-7.31-5.67-8.49
				c-2.83-0.71-6.33-3.76-7.16-6.48c-1.86-6.09-5.09-10.56-10.04-14.35c-3.27-2.5-6.85-5.2-7.41-10.59
				c8.8,0.27,17.18-0.71,22.25,8.33c2.18,3.89,5.44,7.14,7.92,10.88c4.65,7.03,12.84,8.56,19.38,12.62c0.7,0.43,3.2-0.88,4-2
				c3.07-4.27,5.79-8.8,8.97-13.76c4.62,0.89,9.2,3.03,12.12,7.6c4.03,6.29,9.87,8.59,16.94,8.41c4.15-0.11,8.44-0.03,12.4-1.06
				c10.68-2.78,20.02,1.6,29.41,5.13c8.38,3.15,16.36,7.36,24.6,10.92c1.47,0.63,3.39,0.68,5,0.42c6.7-1.08,13.44-3.11,19.49,2.06
				c0.59,0.51,1.53,0.9,2.29,0.88c10.45-0.27,15.74,7.83,22.47,13.66c1.5,1.3,3.29,2.62,5.16,3.09c4.3,1.07,7.94,2.3,10.31,6.77
				c2.59,4.9,9.47,5.76,14.8,5.83c5.43,0.08,11.27,2.02,16.07,4.7c3.83,2.14,6.09,4.88,11.72,4.94c6.49,0.07,13.54,4.27,17.28,11.13
				c1.27,2.33,2.24,4.83,3.6,7.1c1.81,3.01,4.57,3.99,8.12,4.81c9.66,2.23,19.95,2.46,28.31,9.32c4.15,3.4,9.36,5.68,14.38,7.78
				c2.57,1.07,5.84,1.15,8.67,0.75c4.19-0.58,7.85-0.32,11.34,3.16c-4.36,3.43-8.6,6.77-11.93,9.39c-5.42-2.9-9.9-5.13-14.2-7.67
				c-2.58-1.52-4.78-3.67-7.3-5.3c-1.84-1.19-4.07-3.05-5.87-2.79c-4.17,0.6-8.42,1.81-12.16,3.71c-3.11,1.58-5.28,4.96-9.49,2.17
				c-0.78-0.51-3.11,0.62-4.28,1.56c-2.19,1.77-4.31,3.75-5.98,6c-3.06,4.12-6.02,3.96-9.67-0.03c5.81-4.18,7.55-10.68,9.02-17.22
				c0.36-1.57,0.24-4.03-0.73-4.89c-0.88-0.79-3.65-0.61-4.81,0.22c-4.13,2.99-7.94,6.43-12.04,9.85c-1.31-2.85-2.71-5.91-4.2-9.17
				c2.46-3.49,5.1-7.23,6.64-9.41c-2.17-4.2-3.24-7.4-5.23-9.85c-2.88-3.54-6.41-6.55-9.69-9.77c-0.59-0.58-1.32-1.02-1.92-1.59
				c-5.66-5.4-7.2-5.32-12.15,0.81c-1.67,2.06-3.16,4.27-5.36,7.27c-3.03-3.17-5.85-6.12-9.18-9.6c-1.01,0.65-2.82,1.71-4.52,2.92
				c-3.48,2.48-6.33,5.46-11.34,5.86c-2.82,0.22-5.35,4.14-8.1,6.47c-2.52-3.09-0.61-6.32,0.77-9.67c2.31-5.59,4.46-11.24,6.63-16.77
				c-4.28-1.67-8.82-2.06-10.31-4.4c-1.61-2.53-0.36-6.88-0.36-10.55c-3.73,5.06-7.02,9.99-10.79,14.52
				c-6.33,7.58-10.17,9.17-14.42,6.55c-1.91,1.23-3.71,2.93-5.84,3.6c-1.74,0.55-4.07,0.35-5.77-0.39c-2.39-1.04-2.72-3.13-1.92-5.85
				c1.49-5.07,2.59-10.28,3.51-15.49c0.67-3.82-1.01-6.63-4.5-8.62c-2.24-1.28-4.08-3.28-7.09-5.79c-1.21,2.1-2.36,4.11-3.51,6.12
				c-0.45-0.08-0.9-0.16-1.35-0.25c-1.07-6.65-4.87-9.51-11.17-10.15c-3.83-0.39-5.8,0.67-6.56,4.15
				c-1.72,7.78-3.32,15.58-4.95,23.38c-0.37,1.77-0.71,3.55-1.17,5.84c-4.3-9.3-2.44-18.38-0.95-27.2c1-5.95-1.36-10.39-5.45-12.82
				c-3.01-1.78-7.8-0.64-11.79-0.56c-0.79,0.01-2.04,0.96-2.27,1.72c-2.94,9.8-10.74,17.61-11.38,28.4c-0.21,3.5-1.68,6.94-3.02,10.3
				c-2.16-7.75-2.24-15.34-0.51-23.09c1.15-5.15,1.53-10.48,2.09-15.75c0.34-3.23-1.48-5.4-4.55-5.11c-1.87,0.17-4.52,1.7-5.2,3.3
				c-5.38,12.7-7.44,25.45,0.33,38.21c1.2,1.98,0.81,4.97,0.99,7.51c0.13,1.81-0.59,3.93,0.11,5.42c2.81,6.02,1.27,11.63-1.13,17.47
				c6.73,4.87,13.34,9.85,20.17,14.52c3.6,2.46,7.54,2.79,11.85,1.06c3.64-1.46,7.61-2.34,11.51-2.81c1.65-0.2,3.76,0.99,5.22,2.12
				c2.35,1.82,4.02,4.71,6.55,6.07c3.19,1.71,7.13,3.63,10.45,3.19c8.81-1.16,14.03,0.43,18.97,7.87c1.97,2.97,4.18,4.8,8.12,4.67
				c1.6-0.05,3.42,1.79,4.84,3.1c0.93,0.85,1.2,2.36,1.98,3.43c6.22,8.56,8.89,16.92,0.59,26.08c-2.29,2.53-2.69,6.77-4.44,11.53
				c3.96-0.66,6.31-1.24,8.69-1.4c2.89-0.2,5.81,0.06,8.7-0.12c1.69-0.11,3.79-0.18,4.93-1.16c4.12-3.55,8.36-4.76,12.96-2.13
				c-8.93,7.31-17.94,14.86-27.21,22.08c-1.38,1.07-4.23,1.32-5.95,0.7c-12.19-4.36-24.59-8.36-36.31-13.8
				c-17.92-8.32-35.41-17.6-52.94-26.72c-2.97-1.55-5.33-4.32-7.89-6.61c-1.35-1.21-2.35-2.87-3.8-3.9
				c-9.9-7.01-19.91-13.87-29.82-20.87c-7.53-5.32-14.96-10.77-22.88-16.49c0.93-1.58,2.32-3.41,3.11-5.47
				c0.73-1.89,1.76-4.48,1.04-5.97c-2.05-4.22-0.09-6.76,2.38-9.71c3.65-4.37,7.61-8.74,7.31-16.04c-8,4.63-15.17,9.06-22.6,13
				c-5.83,3.09-11.88,5.84-18.03,8.23c-3.37,1.31-6.36,0.51-7.71-3.82c-0.52-1.68-2.79-3.95-4.3-3.98c-5.17-0.09-4.77-2.73-3.74-6.21
				c0.35-1.19,0.31-2.5,0.57-4.85c-2.66,1.61-4.46,2.66-6.23,3.77c-10.85,6.78-21.65,13.63-32.55,20.32
				c-5.87,3.6-12.1,6.51-19.23,5.77c-4.37-0.45-8.67-1.57-13.02-2.22c-1.73-0.26-3.54-0.23-5.28,0c-2.79,0.36-5.26,0.59-4.11-5.01
				c-1.87,2.17-2.66,3.09-3.46,4l0.1-0.12c-1.46,1.33-2.86,2.73-4.39,3.97c-2.97,2.39-5.62,4.18-9.42,0.49
				c-1.83-1.77-5.49-1.74-8.37-2.29c-2.22-0.42-4.52-0.48-6.92-0.71c4.59-6.53,8.92-12.67,13.78-19.59
				c-3.52-0.48-5.56-0.75-9.16-1.24c2.92-1.9,4.76-3.05,6.55-4.27c7.23-4.92,14.92-9.09,18.39-18.2c1.28-3.37,5.47-5.64,8.35-8.4
				c3.09-2.96,6.19-5.91,9.1-8.69c-2.03-4.38-4.34-5.83-8.55-4c-1.9,0.82-4.45,0.14-8.26,0.14c2.67-3.07,4.49-5.05,6.18-7.14
				c3.02-3.73,6.52-7.22,8.74-11.39c1.38-2.58,0.83-6.22,1-9.4c0.06-1.11-0.68-2.35-0.41-3.36c0.89-3.44-0.39-5.94-3.14-7.7
				c-3.33-2.14-3.07-5.38-1.57-7.81c0.68-1.11,4.46-1.27,6.27-0.51c4.26,1.79,6.5,0.58,8.06-3.45c1.3-3.36,2.88-6.61,4.51-10.3
				c3.05,1.19,5.75,2.25,9.59,3.75c-2.31,1.08-3.6,2.23-4.81,2.16c-4.77-0.29-5.41,3.06-5.88,6.38c-1.73,12.26-3.36,24.54-4.95,36.82
				c-0.59,4.57,1.12,7.52,6.45,7.44c-0.79,8.16-4.15,13.84-10.27,19.17c-6.45,5.62-11.04,13.38-16.44,20.2
				c0.36,0.37,0.72,0.74,1.08,1.12c0.85-0.48,1.78-0.85,2.54-1.45c3.59-2.86,7.37-4.54,12.25-4.57c10.48-0.05,23.13-9.82,26.47-19.73
				c0.31-0.93,0.93-2.31,0.54-2.84c-3.53-4.84,0.53-8.75,1.26-13.09c1.42-8.44,2.28-16.98,3.47-25.46c0.2-1.4,0.75-2.79,1.37-4.07
				c1.71-3.57,3.54-7.07,5.33-10.61L222.6,333.14z"/>
			<path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M817.01,430.26c-3.61-3.53-6.75-6.97-10.27-9.97c-5.64-4.81-16.68-3.21-19.66,3.6
				c-3.28,7.5-4.95,15.71-7.4,23.59c-0.6,1.93-1.05,4.24-2.37,5.55c-5.44,5.37-10.89,10.83-16.96,15.44
				c-3.42,2.6-7.91,4.42-12.17,5.15c-7.63,1.31-14.39,3.85-20,9.24c-1.8,1.73-3.84,3.21-5.58,4.99c-3.71,3.79-8.03,5.93-13.41,5.09
				c-6.85-1.07-12.63,2.04-18.59,4.48c-0.92,0.38-1.86,1.43-2.63,1.29c-9.96-1.76-18.21,5.41-27.97,5.16
				c-5.36-0.14-10.74,1.58-16.15,2.04c-7.15,0.62-13.52-1.33-18.21-7.14c-2.81-3.48-6.02-4.4-10.05-2.25
				c-1.31,0.7-2.83,1.01-4.14,1.71c-7.38,3.94-14.42,4.12-21.39-1.19c-1.45-1.1-3.92-0.81-5.87-1.33c-1.83-0.49-3.82-0.86-5.34-1.88
				c-2.89-1.93-5.38-4.46-8.26-6.42c-2.39-1.63-4.98-2.56-7.78-0.26c-0.82,0.67-2.82,0.98-3.6,0.45c-3.8-2.57-6.75-0.34-9.6,1.5
				c-4.51,2.92-8.49,2-12.39-1c-9.45-7.26-19.05-6.83-27.82,1.15c-7.74,7.05-15.68,13.89-23.28,21.1c-3.78,3.59-7.97,3.97-12.26,2.26
				c-3.3-1.31-5.7-0.81-8.16,1.36c-2.73,2.41-5.41,3.29-9.45,2.27c-3.38-0.85-7.38,0.78-12.68,1.53c2.28-4.65,3.56-8.97,6.18-12.23
				c4.62-5.76,4.72-11.01,0.75-17.01c-2.37-3.58-3.79-7.8-6.23-11.31c-1.13-1.62-3.75-3.21-5.6-3.11c-4.15,0.23-6.01-2.04-7.82-5
				c-3.83-6.27-11.72-9.04-18.9-7.63c-2.78,0.54-6.24-0.33-8.85-1.63c-2.56-1.28-4.53-3.84-6.61-5.99
				c-3.15-3.24-6.37-4.81-11.06-2.79c-3.59,1.55-7.63,2.83-11.47,2.81c-3.39-0.01-7.15-1.37-10.06-3.2
				c-5.46-3.44-10.43-7.66-15.92-11.8c2.01-5.7,3.17-11.8,0.21-17.89c-0.07-0.15-0.18-0.35-0.13-0.47c3.3-8.12-2.46-14.28-4.67-21.16
				c-3.27-10.18,0.5-19.31,3.81-28.52c0.27-0.76,2.75-1.62,3.19-1.25c1.23,1.04,2.77,2.78,2.67,4.13c-0.34,4.78-0.82,9.67-2.15,14.25
				c-2.96,10.17-1.13,19.95,1.06,29.79c0.47,0.11,0.95,0.22,1.42,0.34c1.71-5.84,3.21-11.76,5.17-17.52
				c1.89-5.56,4.22-10.98,6.4-16.44c0.8-2,1.84-3.9,2.56-5.92c1.79-5,5.46-7.01,11.2-5.98c3.1,0.55,6.77,5.66,6.58,9.47
				c-0.14,2.65-0.96,5.28-0.91,7.91c0.17,10.66,0.56,21.33,0.87,31.99c0.44,0.11,0.89,0.22,1.33,0.33c0.49-0.99,1.2-1.93,1.44-2.97
				c2.52-11,4.84-22.04,7.53-33c0.51-2.07,2.56-5.52,3.52-5.39c3.49,0.46,8.08-0.43,9.77,4.32c0.81,2.29,1.46,4.63,2.46,7.82
				c2.24-2.47,4.05-4.47,5.86-6.45c9.7,5.25,10.66,7.2,8.24,17.52c-1.06,4.5-2.39,8.94-3.6,13.4c1.28,0.85,2.63,1.36,3.39,2.33
				c2.91,3.71,6.03,2.61,9.23,0.66c2.03-1.24,3.76-3.09,6.54-1.19c0.68,0.47,2.71-0.26,3.62-1.03c5.74-4.82,11.34-9.81,16.93-14.7
				c-0.08,6.29,5.4,5.77,11.16,6.09c-2.28,5.61-3.88,10.67-6.33,15.3c-2.78,5.25-2.51,10.07,0.37,15.72c1.05-1.19,2.04-1.99,2.6-3.01
				c2.28-4.12,4.07-8.55,10.44-6.81c1.1,0.3,2.96-2.32,4.52-3.52c1.97-1.51,3.99-2.95,5.67-4.18c3.67,3.21,7.07,6.19,10.43,9.13
				c4.04-4.78,7.71-9.12,11.34-13.42c5.63,5.12,10.44,9.41,15.11,13.84c0.73,0.69,0.73,2.11,1.23,3.11c1.15,2.27,2.41,4.48,4.22,7.81
				c-1.6,2.12-4.15,5.53-6.61,8.8c1.99,4.27,3.91,8.37,5.82,12.48c5.24-4.53,10.25-8.87,15.27-13.21c0.46,0.43,0.92,0.87,1.38,1.3
				c-0.72,8.13-3.11,15.55-9.88,20.87c5.87,6.88,7.87,7.01,13.41,1.2c1.26-1.33,2.58-2.6,3.79-3.97c2.73-3.07,5.46-5.37,7.82-1.15
				c5.77-2.71,10.97-5.46,16.41-7.61c3.23-1.28,5.81-0.09,7.93,3.21c1.36,2.12,4.29,3.33,6.67,4.66c3.5,1.96,7.14,3.68,11.54,5.91
				c-6.87,4.99-12.67,9.21-18.47,13.43c0.16,0.3,0.32,0.59,0.49,0.89c0.8-0.26,1.67-0.38,2.38-0.79c6.85-3.96,13.97-7.54,20.41-12.08
				c6.77-4.77,13.57-9.84,19.1-15.92c5.56-6.11,11.53-8.27,19.4-7.44c1.91,0.2,4.17-1.35,6.01-2.5c4.58-2.86,9.14-5.8,13.47-9.02
				c2.34-1.74,4.53-3.88,6.22-6.24c4.6-6.44,9.29-12.63,17.36-15c0.47-0.14,0.88-0.59,1.34-0.64c12.3-1.28,24.61-2.52,36.82-3.12
				c-8.14,4.12-16.29,8.25-24.16,12.24c0.29-0.91,0.83-2.64,1.4-4.46c-12.57-0.8-17.56,1.85-22.29,12.5
				c-2.2,4.96-5.27,8.57-10.09,11.15c-9.82,5.26-15.5,14.05-20.17,25.05c11.97-6.57,23-12.61,34.91-19.15
				c-0.21,2.42-0.57,3.58-0.33,4.6c0.34,1.43,0.78,3.22,1.82,3.97c0.84,0.61,3.01,0.4,3.93-0.31c9.2-7.06,18.27-14.3,27.35-21.52
				c0.34-0.27,0.47-0.82,0.85-1.53c-1.43-1.08-2.82-2.12-3.71-2.79c7.5-10.56,14.9-20.95,22.27-31.37c2.89-4.09,6.71-5.48,11.76-6.13
				c11.54-1.49,22.44,1.86,33.62,3c6.91,0.71,13.78,1.82,20.69,2.56c1.37,0.15,3.13-0.2,4.22-1c3.3-2.43,5.72-1.65,8.71,0.85
				c3.13,2.62,2.81,5.28,1.7,8.44C820.91,418.94,819.04,424.4,817.01,430.26z M474.66,443.2c-1.55,2.34-2.58,3.59-3.29,5
				c-3.15,6.2-7.36,10.59-15.08,9.93c-0.93-0.08-2.45,1.03-2.89,1.97c-1.48,3.14-3.25,6.36-3.68,9.71
				c-0.59,4.57-2.73,9.93,2.97,13.55c2.57-3.82,5.01-7.45,7.45-11.08c0.24,0.2,0.48,0.4,0.72,0.59c-0.36,6.43-0.72,12.87-1.1,19.62
				c5.23-4.67,11.47-7.77,13.63-12.68c4.44-10.1,6.7-21.15,10.25-33.18c-2.87,2.68-4.59,4.29-6.41,5.98
				C476.37,449.49,475.69,446.99,474.66,443.2z M765.67,445.76c2.38-2.96,5.15-6.41,8.09-10.07c-4.07-2.29-6.74-2.03-9.31,0.77
				c-9.29,10.15-18.69,20.19-28.02,30.3c-0.74,0.8-1.2,1.87-1.79,2.82c1.61,0.2,2.52-0.15,3.3-0.68c8.8-5.96,17.7-11.63,28.18-14.41
				c1.67-0.44,3.52-2.97,3.8-4.77C770.09,448.68,767.28,447.18,765.67,445.76z M485.57,502.79c0.47,0.33,0.95,0.65,1.42,0.98
				c13.01-7.67,21.64-18.72,26.22-33.5c-3.39,1.25-7.53,1.45-9,3.54C497.58,483.17,491.71,493.08,485.57,502.79z M490.34,458.55
				c-0.47-0.07-0.95-0.14-1.42-0.21c-4.47,9.3-8.94,18.59-13.68,28.46c5.34-1.99,9.28-3.42,13.17-4.95c0.72-0.28,1.57-0.82,1.87-1.47
				c1.59-3.47,3.1-6.98,4.46-10.55c0.29-0.75,0.05-2.44-0.38-2.61C490.32,465.6,490.61,461.96,490.34,458.55z M526.12,462.83
				c-8.49,2.78-11.04,8.77-9.19,17.07C519.99,474.21,523.06,468.52,526.12,462.83z"/>
			<path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M659.6,678.28c0.71,4.51,2.98,9.67,1.8,13.87c-1.35,4.79-4.98,9.73-9,12.81
				c-9.65,7.41-15.48,17.56-22.58,26.82c0.32,0.51,0.64,1.03,0.96,1.54c3.01-1.2,6.19-2.1,8.99-3.67c4.78-2.68,9.23-5.96,13.99-8.67
				c6.65-3.79,11.74-8.96,16.07-15.29c2.86-4.18,7.15-7.38,11.32-10.57c-2.33,9.83-9.38,17.99-8.13,28.9
				c13.77-6.46,20.88-18.54,28.94-31.34c-1.01,9.8-6.28,20.99-11.61,26.74c-5.4,5.82-10.88,11.63-15.63,17.97
				c-2.08,2.79-2.43,6.87-3.57,10.37c0.46,0.36,0.92,0.72,1.39,1.09c3.14-1.24,6.4-2.24,9.39-3.77c6.25-3.21,12.62-3.01,18.81-0.54
				c2.78,1.11,4.5,0.52,6.11-1.32c3.61-4.13,6.74-8.73,10.67-12.51c5.33-5.12,11.07-9.85,16.91-14.39
				c7.05-5.48,14.71-10.21,21.5-15.98c4.05-3.44,7.42-5.41,12.17-1.71c-1.48,4.14-3.03,8.45-5.07,14.13
				c9.3-2.44,16.84-4.57,22.26-10.54c5.23-5.76,9.84-12.09,15.09-18.62c1.23,4.41,2.41,8.39-0.52,12.56
				c-8.66,12.34-20.36,21.58-32.46,29.99c-4.47,3.11-11.43,3.1-17.33,3.58c-8.08,0.66-12.14,5.67-15.94,12.31
				c3.14,1.49,5.88,2.78,9.24,4.37c-10.36,10.95-20.25,21.58-27.9,33.93c0.47,0.55,0.95,1.09,1.42,1.64
				c5.28-3.97,10.62-7.88,15.83-11.94c4.97-3.86,9.62-8.15,14.77-11.73c3.17-2.2,6.52-5.33,11.17-2.72c0.5,0.28,1.98-0.92,2.85-1.62
				c4.28-3.44,8.8-6.65,12.7-10.48c6.86-6.73,14.57-6.69,22.47-3.05c3.11,1.44,5.31,2.66,7.78-2.09c1.91,25.04,3.7,48.61,5.52,72.52
				c-17.32,4.51-35.23,3.25-52.83-1.7c-8.16-2.29-15.74-2.48-23.92-0.32c-6.67,1.76-13.77,2.6-20.69,2.62
				c-21.45,0.06-42.9-0.49-64.35-0.76c-2.15-0.03-4.47-0.27-6.44,0.39c-13.1,4.42-26.2,3.33-39.42,0.66
				c-20.35-4.1-40.74-8.02-61.11-12.05c-1.59-0.32-3.13-0.92-5.38-1.59c9.47-12.92,23.31-21.93,29.91-38.05
				c-3.44,0.98-5.84,1.99-8.32,2.27c-2.51,0.29-5.1-0.15-9.06-0.34c2.81-3.55,4.27-6.69,6.73-8.3c11.65-7.61,19.48-19.02,29.33-28.39
				c6.89-6.55,14.95-11.87,22.48-17.74c0.28,0.33,0.55,0.66,0.83,0.98c-1.17,2.92-2.33,5.84-3.5,8.76c0.45,0.5,0.91,0.99,1.36,1.49
				c10.16-5.64,20.08-11.79,30.55-16.77C647.32,696.76,651.63,685.07,659.6,678.28z"/>
			<path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M465.33,1031.6c1.96,7.35,3.45,14.88,6.01,22.01c3.27,9.12,10.46,14.81,19.1,18.66
				c3.35,1.49,8.07-0.51,9.72-5.53c2.45-7.45,4.39-15.27,5.07-23.07c1.77-20.31,2.74-40.69,4.02-61.04c0.05-0.83,0.04-1.66,0.05-2.5
				c0.25-28.96,0.47-57.92,0.74-86.89c0.12-13.31,0.31-26.62,0.52-39.93c0.26-16.46,0.24-32.93,0.97-49.36
				c0.95-21.36,3.04-42.68,3.65-64.04c0.43-15.05-0.76-30.16-2.37-45.22c0,5.74,0.06,11.47-0.01,17.21
				c-0.19,15.45-0.38,30.89-0.7,46.34c-0.14,6.8-0.74,13.59-0.92,20.4c-0.86,32.72-1.75,65.44-2.42,98.17
				c-0.42,20.13-0.42,40.27-0.71,60.41c-0.21,14.29-0.25,28.6-0.98,42.86c-0.95,18.69-2.52,37.36-3.8,56.04
				c-0.36,5.29-0.36,10.63-0.99,15.88c-0.57,4.71-1.78,9.33-2.71,13.99c-0.46-0.04-0.92-0.09-1.38-0.13c0-3.66,0-7.33,0-10.91
				c-13.06-1.46-20.78-9.62-21.66-22.91c-0.92-13.74-1.52-27.51-2.19-41.26c-1.06-21.72-2.12-43.44-3.06-65.16
				c-0.96-22.38-1.54-44.79-2.75-67.15c-0.59-10.9-2.22-21.75-3.38-32.62c-0.42,0.03-0.83,0.06-1.25,0.09
				c0.46,6.1,0.96,12.2,1.38,18.3c0.54,7.93,1.19,15.86,1.47,23.8c0.61,17.11,0.85,34.23,1.54,51.34
				c0.85,21.23,1.93,42.45,3.05,63.67c0.91,17.21,1.88,34.43,3.18,51.61c0.3,4.02,2.04,7.94,2.46,12.4
				c-2.03-3.72-4.06-7.43-6.09-11.15c-7.2-13.2-8.06-27.6-8.38-42.21c-0.45-20.46-1.1-40.93-2.19-61.36
				c-1.36-25.41-3.73-50.78-4.75-76.2c-0.62-15.43,0.41-30.94,0.83-46.41c0.1-3.67,5.3-8.88,8.66-8.86c2.49,0.02,5.9-0.57,4.48,4.03
				c-0.12,0.39,0.13,1.01,0.39,1.38c7.66,10.68,5.28,23.66,8.1,35.45c6.01,25.06,6.15,50.79,7.68,76.31
				c0.87,14.57-0.98,29.28-0.91,43.92c0.08,16.11,0.88,32.21,1.55,48.31c0.24,5.77,0.46,11.64,1.74,17.22
				c0.84,3.67,3.18,7.23,5.57,10.27c2.67,3.4,5.55,2.94,7.03-1.23c1.42-3.99,2.33-8.39,2.37-12.62c0.27-32.14,0.07-64.29,0.42-96.44
				c0.26-24.63,1.11-49.26,1.68-73.89c0.63-27.76,1.28-55.52,1.85-83.28c0.53-25.96,0.62-51.93,1.57-77.87
				c0.4-10.92,2.36-21.8,4.02-32.63c0.31-2.04,3.03-5.57,3.83-5.36c4.33,1.15,9.67,1.47,11.52,6.64c1.16,3.25,1.84,6.79,2.03,10.24
				c1.35,24.24,3.51,48.49,3.49,72.73c-0.03,33.61-1.98,67.21-2.66,100.83c-0.38,18.47,0.31,36.97,0.22,55.45
				c-0.11,20.64-0.66,41.28-0.83,61.93c-0.14,17.62-0.1,35.24,0.03,52.86c0.05,6.97,0.64,13.94,0.82,20.92
				c0.04,1.54-0.57,3.11-0.98,5.15c5.61,1.58,10.16-0.91,14.3-4.25c5.93-4.78,9.22-11.31,10.93-18.59
				c5.75-24.4,7.96-49.23,8.26-74.24c0.39-32.64,0.64-65.28,0.99-97.91c0.02-1.82,0.39-3.64,0.52-5.46c0.24-3.59,0.47-7.39,5.14-7.75
				c5.42-0.42,10.08,3.48,10.36,8.52c0.91,16.28,2.63,32.58,2.29,48.84c-0.53,25.58-1.87,51.19-3.99,76.69
				c-1.75,21.01-4.88,41.93-7.94,62.81c-0.79,5.42-3.37,10.65-5.6,15.76c-1.31,3-3.06,5.34-7.38,5.93c-2.88,0.39-6.23,4.17-7.65,7.23
				c-3.81,8.18-10.89,11.31-18.59,13.57c-7.57,2.23-7.62,2.16-7.61,10.13c0,4.64-0.49,9.3-1.15,13.9c-0.17,1.21-1.66,2.33-2.7,3.31
				c-4.23,3.98-4.87,6.06-0.8,9.99c4.16,4.01,3.59,8.4,3.41,13.21c-0.47,12.6-0.77,25.2-0.98,37.81c-0.14,8.38-0.03,16.76-0.03,24.4
				c-3.31-0.84-6.03-1.52-8.74-2.24c-0.62-0.17-1.41-0.29-1.79-0.73c-3.85-4.4-8.05-5.47-13.11-1.89c-0.7,0.49-2.16-0.09-2.73-0.14
				c0-15.46-0.1-30.58,0.03-45.69c0.14-17.14,0.47-34.29,0.77-51.43c0.07-4.15-1.38-7.1-5.63-8.26c-9.53-2.59-15.32-9.9-20.14-17.58
				C465.67,1049.49,464.82,1040.45,465.33,1031.6z M578.39,847.59c-0.28,0.02-0.55,0.05-0.83,0.07c-0.04,0.75-0.13,1.51-0.1,2.26
				c0.57,15.08,1.63,30.16,1.59,45.24c-0.04,13.75-1.23,27.49-1.99,41.23c-0.48,8.59-0.89,17.2-1.71,25.77
				c-0.78,8.06-1.95,16.09-3.14,24.1c-2.15,14.55-4.17,29.14-6.82,43.6c-0.98,5.32-3.61,10.34-5.49,15.5c0.32,0.33,0.64,0.67,0.97,1
				c0.24-0.74,0.31-1.6,0.73-2.22c6.34-9.36,7.96-20.26,9.63-31.01c1.75-11.27,2.73-22.66,3.9-34.01c1.17-11.36,2.27-22.72,3.17-34.1
				c0.59-7.43,0.71-14.9,1.11-22.35c0.62-11.4,1.67-22.8,1.86-34.21c0.17-9.94-0.45-19.9-1.01-29.83
				C580.04,854.94,579.03,851.28,578.39,847.59z M574.44,857.06c-0.39,0.05-0.79,0.1-1.18,0.16c0,4.16,0.01,8.31,0,12.47
				c-0.06,20.07,0.45,40.16-0.36,60.2c-0.8,19.68-3.08,39.3-4.47,58.97c-1.33,18.77-7.51,35.8-16.73,51.63
				c3.17-1.17,5.68-3.9,7.03-7.78c1.8-5.16,3.9-10.26,5.24-15.54c2.39-9.43,4.85-18.89,6.26-28.49c1.19-8.16,0.69-16.56,1.37-24.82
				c1.01-12.21,2.94-24.37,3.51-36.59c0.77-16.44,0.8-32.92,0.78-49.39C575.88,870.93,574.95,864,574.44,857.06z M515.46,918.25
				c-0.54-0.02-1.08-0.04-1.62-0.06c-0.09,1.45-0.24,2.9-0.25,4.35c-0.12,15.41,0.08,30.84-0.38,46.24
				c-0.78,25.68-1.98,51.35-3.03,77.02c-0.05,1.32-0.14,2.66-0.44,3.94c-1.91,7.94-3.89,15.86-5.84,23.78
				c0.56-0.66,1.65-1.37,1.88-2.3c2.28-9.09,4.99-18.13,6.41-27.36c1.05-6.83,0.07-13.95,0.48-20.91
				c0.72-12.06,1.97-24.08,2.68-36.14c0.38-6.47,0.11-12.97,0.11-19.46C515.46,950.99,515.46,934.62,515.46,918.25z M460.76,828.74
				c-0.43-0.01-0.85-0.02-1.28-0.03c0.08,1.46,0.15,2.92,0.24,4.38c0.83,13.56,1.85,27.1,2.4,40.67c0.23,5.76-0.94,11.58-0.71,17.34
				c0.41,10.74,1.62,21.46,2.05,32.2c0.53,13.27,0.45,26.57,0.95,39.85c0.18,4.85,1.26,9.67,1.93,14.5c0.5-0.07,1-0.14,1.5-0.21
				c-0.67-6.71-1.56-13.42-1.95-20.15c-0.38-6.46-0.12-12.95-0.37-19.42c-0.61-15.74-1.56-31.47-1.99-47.21
				c-0.33-11.92,0.25-23.87-0.2-35.78C463,846.14,461.65,837.45,460.76,828.74z M472.48,828.71c-0.37,0.06-0.75,0.13-1.12,0.19
				c0.59,16.06,1.26,32.13,1.76,48.19c0.51,16.28,0.93,32.56,1.25,48.84c0.32,15.85,1.66,31.57,4.97,47.08
				c0.67-0.52,0.92-1.01,0.85-1.46c-4.02-26.79-4.34-53.82-5.11-80.81c-0.39-13.62-0.43-27.25-0.91-40.87
				C473.93,842.8,473.07,835.76,472.48,828.71z M521.86,784.79c-0.72,0.06-1.43,0.12-2.15,0.18c1.09,26.96-3.62,54.03,2.15,80.88
				C521.86,838.83,521.86,811.81,521.86,784.79z M479.66,1031.47c-0.38,0.21-0.76,0.42-1.13,0.63c2.36,7.24,5.52,14.1,11.54,19.21
				c1.12,0.95,3.45,1.13,4.97,0.72c0.95-0.26,1.59-2.22,2.01-3.53c0.1-0.3-1.44-1.68-1.95-1.54c-6.28,1.7-8.53-2.96-11.03-6.99
				C482.38,1037.26,481.11,1034.31,479.66,1031.47z M481.53,1017.42c-1.27,12,3.61,22.58,9.82,23.82
				C488.27,1033.79,485.03,1025.93,481.53,1017.42z"/>
			<path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M1161.39,127.06c-8.81-0.4-17.02-1.19-25.21-1.03c-7.16,0.14-13.12,3.67-17.43,9.42
				c-4.82,6.44-10.9,9.64-19.1,9.86c-5.34,0.14-10.6,2.37-15.97,2.78c-3.9,0.3-7.9-0.89-11.86-1.31c-3.14-0.34-6.29-0.58-9.43-0.76
				c-6.7-0.39-11.68,2.2-15.6,7.87c-4.68,6.77-3.11,6.8-10.84,3.06c-8.95-4.33-18.1-6.56-27.99-3.99c-0.16,0.04-0.34,0.09-0.49,0.05
				c-16.03-3.95-30.61,4.61-46.09,5.34c-8.46,0.4-16.95,0.53-25.42,0.5c-1.85-0.01-4.02-0.76-5.5-1.88c-10.5-8-22.68-8.73-35.11-8.43
				c-7.43,0.18-14.86,0.57-22.29,0.91c-18.59,0.84-37.17,1.67-55.75,2.65c-1.24,0.07-3.07,1.02-3.52,2.05
				c-2.74,6.17-8.07,8.68-14.02,8.8c-9.75,0.19-19.53-1.08-29.29-0.97c-10.07,0.12-20.13,1.22-30.2,1.76
				c-2.42,0.13-5.02,0.18-7.27-0.57c-10.14-3.34-20.31-4.11-30.19,0.24c-8.28,3.64-16.34,4.11-25.23,2.51
				c-5.6-1.01-12.26-0.57-17.44,1.61c-9.01,3.81-18.14,4.76-27.63,5.61c-9.17,0.82-18.32,2.64-27.26,4.87
				c-5.35,1.34-8.7,5.42-8.01,12.48c-2.77-0.4-5.36-0.49-7.79-1.17c-6.27-1.77-7.43-5.84-2.14-9.69c3.38-2.46,8.14-3.58,12.42-4.11
				c6.43-0.8,12.71-1.26,18.74-4.31c3.73-1.89,8.35-1.97,12.54-2.99c5.28-1.28,9.96-3.18,12.53-8.74c2.46-5.31,7.92-6.18,13.03-7.06
				c3.27-0.56,6.6-0.82,9.9-1.17c3.72-0.4,6.57-1.9,9.56-4.49c2.86-2.48,7.32-3.8,11.23-4.24c8.02-0.9,16.11-0.11,23.94-3.65
				c2.96-1.34,7.44,0.39,11.15,1.1c3.92,0.74,6.81,0.71,10.01-2.88c5.11-5.74,12.52-7.22,19.77-5.18c7.29,2.06,14.29,5.23,21.29,8.2
				c3.48,1.47,5.73,1.88,8.12-2.12c3.85-6.44,10.43-9.26,17.7-9.62c6.12-0.31,12.29,0.47,18.44,0.42c5.26-0.04,10.5-0.61,15.76-0.86
				c2.97-0.14,5.96-0.33,8.92-0.13c5.12,0.34,9.87,0.71,14.97-1.86c3.29-1.67,8.11-0.54,12.22-0.28c2.63,0.16,5.2,1.08,7.81,1.55
				c4.44,0.81,8.48,1.53,11.85-3.29c1.49-2.14,5.57-3.52,8.42-3.44c7.57,0.21,15.36,0.48,22.61,2.41c10.89,2.9,19.82-0.89,29.5-4.86
				c7.73-3.17,14.43-7.36,21.29-11.89c6.12-4.05,13.15-1.41,19.71,0.84c1.37,0.47,3.64,0.38,4.64-0.46
				c18.69-15.7,39.83-18.51,62.81-12.44c6.09,1.61,12.21,3.2,18.42,4.14c2.01,0.31,4.4-1.34,6.5-2.34c1.93-0.91,3.82-2,5.53-3.28
				c2.74-2.04,5.18-4.51,7.99-6.42c3.62-2.45,7.26-5.25,11.32-6.6c5.31-1.76,11.07-2.14,16.59-3.33c3.77-0.82,5.72-3.25,7.65-6.88
				c3.13-5.86,7.59-11.14,12.21-15.99c1.93-2.02,5.6-2.91,8.61-3.27c7.08-0.84,8.35,0.58,8.35,7.73c0,21.49,0,42.97,0,64.46
				C1161.39,124.1,1161.39,125.92,1161.39,127.06z"/>
			<path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M219.4,1158.92c-5.31-9.2-10.67-18.37-15.93-27.6c-4.25-7.45-12.65-11.11-20.89-8.66
				c-1.49,0.44-2.61,2.13-4.63,3.87c-5.02-3.18-10.67-6.89-16.45-10.38c-4.52-2.73-9.3-5.02-13.78-7.8
				c-4.06-2.51-8.46-4.88-11.61-8.32c-2.88-3.14-5.98-4.3-9.63-3.92c-4.56,0.47-9.1,1.48-13.53,2.68
				c-3.85,1.04-10.78-1.91-12.07-5.59c-3.46-9.88-6.08-19.42-4.34-30.52c2.04-13.04,1.34-26.56,1.3-39.87
				c-0.01-4.76-1.57-9.51-3.36-14.2c-0.23,18.24-2.42,36.02-7.73,54.09c-2.31-1.73-4.33-3.24-6.36-4.75
				c-6.92,4.2-7.68,6.44-4.62,13.64c0.33,0.76,0.55,1.6,1.02,2.27c3.67,5.19,3.35,9.57-0.52,15.07c-2.6,3.69-2.39,9.36-3.5,14.4
				c-3.83-0.89-8.15-2.12-12.54-2.87c-7.31-1.24-14.64-2.54-22.02-3.06c-3.89-0.28-5.25-2.19-5.05-5.2
				c0.65-9.61,1.07-19.31,2.89-28.72c0.83-4.29,4.24-8.45,7.4-11.81c4.49-4.78,5.04-12.21,0.73-16.98c-1.09-1.2-1.51-3.78-1.08-5.42
				c1-3.83,2.97-7.4,4-11.22c0.51-1.89,0.59-4.84-0.51-6c-1.33-1.41-4.09-1.58-6.29-2.03c-2.64-0.54-5.33-0.81-8.25-1.23
				c2.68-5.27,12.86-9.98,18.66-9.01c2.27,0.38,4.77,0.83,6.6,2.08c6.35,4.34,11.87,1,17.13-1.89c1.48-0.81,1.51-4.25,2.06-6.02
				c2.9-0.48,6.46-1.49,10.03-1.52c2.18-0.02,4.61,1.12,6.53,2.36c5.77,3.73,5.92,3.93,8.25-2.22c1.63-4.28,2.61-8.81,3.76-13.26
				c1.46-5.63,2.56-11.36,4.26-16.91c1.67-5.48,5.16-10.72,0.17-16.38c4.64-2.44,8.2-0.77,11.29,1.23c5.42,3.51,10.32,7.7,17.38,7.88
				c1.81,0.05,4.03,2.32,5.26,4.12c17.23,25.28,34.55,50.51,51.27,76.12c3.39,5.2,3.8,12.28,6.76,17.86
				c3.21,6.04,7.31,11.79,11.9,16.86c6.9,7.63,14.63,14.49,21.81,21.86c1.07,1.09,1.49,3.22,1.5,4.87
				c0.11,15.96,0.07,31.92,0.07,49.12c-2.43,0.24-5.36,0.94-8.22,0.73c-4.08-0.31-7.3-0.44-7.33,5.13c-0.02,2.4-1.21,4.8-1.87,7.2
				L219.4,1158.92z M116.02,963.38c-2.13,7.34-3.55,13.78-5.9,19.85c-2.19,5.64-2.4,10.65,1.6,15.36c3.86,4.55,5.88,9.52,4.76,15.61
				c-0.22,1.17,0.33,2.6,0.86,3.76c2.33,5.14,4.74,10.24,7.21,15.32c2.98,6.12,6.66,12.2,13.58,13.74
				c7.55,1.68,11.8,6.77,16.39,11.99c2.19,2.49,4.6,4.83,7.18,6.89c1.51,1.2,3.56,1.72,6.4,3.02c-0.72-2.89-0.92-4.54-1.54-6
				c-5.1-12-0.83-22.77,4.16-33.5c0.46-0.99,0.47-2.55,0.03-3.54c-1.78-3.93-3.72-7.79-5.8-11.58c-2.51-4.58-5.22-9.05-7.85-13.57
				c-0.76,2.02-0.23,3.54-0.16,5.08c0.26,5.79,0.81,11.64-3.73,16.43c-2.38,2.51-4.72,5.17-6.49,8.12c-1.48,2.47-3.15,3.03-5.9,3.41
				c-5.73,0.79-9.07-1.3-11.17-6.49c-1.05-2.59-2.78-4.9-4.17-7.36c-0.21-0.37-0.2-0.86-0.33-1.44c1.23,0,2.28,0,4.25,0
				c-3.29-5.49-6.17-10.47-9.21-15.33c-6.55-10.46-6.35-16.35,0.99-26.51c0.41-0.57,0.74-1.65,0.48-2.19
				C119.95,970.85,118.06,967.33,116.02,963.38z"/>
			<path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M1162.28,140.98c-5.57-0.39-9.53-0.93-13.48-0.87c-6.03,0.1-11.36,2.53-14.1,7.94
				c-3.27,6.47-8.35,9.35-15.1,9.57c-7.64,0.25-14.71,1.74-21.15,6.22c-2.37,1.65-5.58,2.08-8.35,3.19c-1.74,0.7-3.38,1.63-5.07,2.45
				c0.1,0.5,0.19,1,0.29,1.5c1.48,0,2.97,0.05,4.45-0.01c5.98-0.24,11.96-0.52,17.94-0.77c6.46-0.27,12.2,0.7,17.46,5.45
				c2.95,2.67,7.79,3.41,11.9,4.6c7.93,2.28,15.96,4.26,24.11,6.41c0,7.91,0,15.7,0,24.32c-2.5,0-4.92-0.09-7.33,0.01
				c-14.77,0.64-29.53,1.64-44.3,1.9c-18.28,0.32-36.57,0.28-54.85-0.03c-25.62-0.43-51.23-1.25-76.85-1.9
				c-1.81-0.05-3.62-0.01-5.89-0.01c-1.08-5.94-2.07-11.63-3.17-17.29c-2.28-11.81-6.41-22.37-18.11-28.23
				c-10.38-5.2-19.4-1.94-22.71,9.19c-1.24,4.18-3.08,4.97-6.95,4.85c-20.78-0.64-41.58-1.89-62.34-1.32
				c-31.92,0.88-63.83,2.68-95.69,4.82c-24.86,1.67-49.62,4.85-74.49,6.56c-13.41,0.92-26.96,0.76-40.39,0.02
				c-14.14-0.78-28.2-1.71-42.31,0.34c-1.97,0.29-4.14-0.85-6.22-1.33c1.15-2.38,1.77-6.26,3.54-6.88c7.43-2.63,15.11-5.4,22.87-6.05
				c12.43-1.05,24.65-1.22,36.47-6.64c4.93-2.26,11.81-1.44,17.54-0.45c7.36,1.27,14.16,0.94,20.92-2.08
				c10.71-4.77,21.49-4.91,32.44-0.65c1.75,0.68,4.06,0.82,5.86,0.32c16.51-4.65,33-1.44,49.56-0.53c6.56,0.36,13.29-1.75,19.87-3.09
				c1.61-0.33,3.64-1.77,4.27-3.22c2.51-5.77,7.56-5.49,12.43-5.93c19.9-1.8,39.81-2.56,59.8-1.25c7.19,0.47,14.51-1.09,21.73-0.82
				c8.39,0.31,16.38,2.42,23.09,8.14c1.47,1.25,3.98,1.67,6.04,1.78c17.04,0.85,33.9,0.14,50.17-5.86c2.12-0.78,4.54-1.02,6.83-1.06
				c8.09-0.13,16.28-0.83,24.24,0.17c6.77,0.84,13.45,3.3,19.84,5.86c4.38,1.76,7.4,2.15,9.74-2.58c4.8-9.69,13.29-10.42,22.57-9.1
				c4.94,0.7,10.01,2.45,14.78,1.86c9.42-1.18,18.82-3.2,27.94-5.87c3.1-0.91,5.69-4.53,7.88-7.39c9.39-12.3,28.68-11.05,39.48-6.04
				C1161.35,132.06,1161.26,137.09,1162.28,140.98z"/>
			<path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M842,1251.3c16.54-2.55,33-5.36,49.56-7.48c5.17-0.67,10.74-0.21,15.85,0.97
				c9.28,2.15,18.37,2.01,27.57,0.06c0.61-0.13,1.29,0.05,2.72,0.13c-0.41,7.66-1.03,15.21-1.16,22.76c-0.2,11.79-0.29,23.6,0,35.38
				c0.41,16.79,1.14,33.57,1.85,50.34c0.31,7.45,1.09,14.89,1.33,22.34c0.46,14.26,0.47,28.43-2.74,42.57
				c-1.65,7.26-1.08,15.06-1.27,22.62c-0.18,7.26-0.04,14.53-0.04,22.55c-2.04-0.67-3.27-0.91-4.35-1.44
				c-6.85-3.39-13.75-6.71-20.49-10.31c-8.51-4.54-17.59-6.39-27.1-7.73c-7.91-1.11-15.57-4.33-23.18-7.08
				c-4.02-1.45-7.7-3.89-11.26-5.73c0.96-3.25,2.37-6.31,2.72-9.48c1.53-14.04,2.75-28.12,4.11-42.18c0.63-6.51,0.5-12.58-5.69-16.98
				c-1.19-0.84-1.62-4.03-1.13-5.76c5.49-19.14,4.6-38.4,1.98-57.84c-1.08-7.96-0.81-16.09-1.49-24.12
				c-0.23-2.75-1.35-5.46-2.27-8.12C845.73,1261.52,843.81,1256.34,842,1251.3z"/>
			<path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M943.54,974.05c-6.06,5.2-12.53,5.84-19.41,1.18c-4.22-2.86-7.58-6.94-11.6-10.14
				c-4.74-3.77-9.92-6.99-14.63-10.8c-3.29-2.66-6.78-5.48-9-8.98c-5.2-8.18-10.86-15.49-20.48-18.76c-1.65-0.56-2.92-2.16-4.46-3.12
				c-4.28-2.67-8.42-5.7-12.98-7.78c-7.04-3.22-11.62-8.58-13.84-15.57c-4.93-15.48-9.47-31.09-13.82-46.75
				c-6.78-24.39-11.42-49.22-13.58-74.44c-1.06-12.42-0.91-24.94-1.76-37.38c-1.36-19.9-3.13-39.78-4.67-59.67
				c-0.41-5.3-0.25-10.68-1.02-15.92c-1.34-9.2-3.7-18.27-4.69-27.49c-0.8-7.4,0.28-15-0.36-22.43c-0.88-10.2-2.84-20.31-3.87-30.5
				c-0.86-8.57,0.02-17.45-1.78-25.78c-2.85-13.22-2.07-26.47-2.77-39.73c-0.48-9.2-2.67-18.29-3.91-27.46
				c-0.66-4.9-1.72-9.95-1.19-14.78c1.03-9.34,1.73-18.48-1.23-27.64c-0.66-2.03-0.18-4.65,0.43-6.82c1.68-6,3.16-12.15,5.74-17.77
				c2.77-6.04,12-7.42,17.02-3.01c6.96,6.12,12.77,13.13,15.59,22.18c3.36,10.77,7.76,21.38,9.65,32.41
				c2.96,17.31,3.97,34.95,6.22,52.39c1.23,9.54,3.69,18.92,4.94,28.46c2.69,20.44,4.97,40.94,7.41,61.41
				c1.69,14.19,4.62,28.37,4.71,42.57c0.1,15.97,5.33,30.72,7.67,46.11c0.98,6.4,1.31,12.91,2.32,19.3
				c2.47,15.56,5.06,31.1,7.79,46.62c4.25,24.2,8.28,48.44,13.13,72.51c2.19,10.86,6.69,21.25,9.18,32.07
				c5.72,24.8,17.57,46.87,29.38,69.02c2.64,4.96,4.42,10.52,7.72,14.96C934.59,966.84,939.24,970.08,943.54,974.05z M853.93,871.93
				c0.45-0.06,0.89-0.13,1.34-0.19c-0.25-1.85-0.43-3.72-0.76-5.56c-0.82-4.57-1.96-9.11-2.53-13.71
				c-3.5-28.37-6.8-56.76-10.31-85.13c-3.82-30.83-7.62-61.67-11.74-92.46c-3.24-24.2-6.97-48.34-10.48-72.51
				c-2.22-15.28-4.82-30.52-6.53-45.86c-1.47-13.21-1.96-26.54-2.82-39.82c-0.42-6.5-0.68-13-1.02-19.51
				c2.48,14.11,4.17,28.28,6.08,42.43c3.19,23.71,6.78,47.38,9.71,71.12c3.01,24.42,4.95,48.97,8.25,73.35
				c3.9,28.8,8.12,57.59,13.39,86.16c5.16,27.97,10.17,55.88,11.44,84.37c0.82,18.34,3.94,36.34,10.94,53.47
				c0.8,1.96,1.75,3.86,2.63,5.78c0.95-1.22,0.89-2,0.76-2.78c-2.59-15.88-5.44-31.71-7.7-47.64c-2.49-17.6-4.39-35.29-6.6-52.93
				c-2.52-20.12-5.08-40.23-7.68-60.33c-2.38-18.45-4.95-36.89-7.24-55.35c-2.98-24.08-5.56-48.21-8.66-72.27
				c-3.19-24.7-7.61-49.26-10.04-74.03c-3.18-32.42-5.33-64.93-12.41-96.84c-1.47-6.62-2.86-13.26-4.29-19.89
				c-0.46,0.07-0.91,0.14-1.37,0.2c-4.05,12.53,0.03,25.12,0.34,37.7c-3.53-13.72-4.45-28.12-11.21-40.93
				c-0.23,1.99,0.33,3.81,0.48,5.67c1.71,22.06,3.04,44.15,5.12,66.18c1.68,17.86,4.02,35.67,6.45,53.45
				c3.95,28.96,8.25,57.88,12.31,86.82c3.97,28.29,7.82,56.6,11.71,84.9c2.53,18.4,5.27,36.78,7.51,55.22
				c1.22,10.06,0.83,20.36,2.52,30.31c3.04,17.82,7.1,35.47,10.75,53.19C852.78,867,853.37,869.46,853.93,871.93z M847.26,906.05
				c0.16-1.82,0.27-2.15,0.2-2.42c-8.41-30.69-12.5-62.16-16.42-93.63c-2.71-21.77-5.15-43.57-7.33-65.4
				c-2.76-27.62-4.91-55.31-7.76-82.93c-1.77-17.16-3.62-34.38-6.78-51.32c-3.9-20.9-8.4-41.64-9.32-63
				c-0.59-13.62-2.02-27.2-3.22-40.78c-1.93-21.86-4-43.7-5.96-65.55c-0.13-1.47,0.16-2.98,0.2-4.48c0.04-1.45,0.01-2.91,0.01-4.36
				c-1.21,0.58-1.55,1.14-1.71,1.74c-4.18,15.69-4.03,31.57-2.08,47.52c4.31,35.29,8.86,70.55,12.94,105.86
				c3.28,28.4,5.97,56.87,9,85.29c3.54,33.21,6.64,66.48,10.89,99.59c4.38,34.09,9.83,68.04,14.98,102.02
				C836.59,885.27,840.99,895.37,847.26,906.05z M811.38,434.31c-0.51,0.17-1.02,0.35-1.53,0.52c1.35,5.24,3.28,10.41,3.94,15.74
				c4.85,39.05,16.13,77.02,18.49,116.53c1.35,22.69,5.1,45.23,7.49,67.86c2.82,26.6,4.18,53.42,8.29,79.82
				c4.87,31.28,11.82,62.25,18.23,93.28c2.41,11.67,6.3,23.04,8.64,34.72c2.5,12.51,3.68,25.28,6.03,37.83
				c0.98,5.26,3.47,10.24,5.27,15.35c0.43-0.16,0.87-0.31,1.3-0.47c-2.31-16.41-4.03-32.92-7.06-49.2
				c-6.35-34.09-13.65-68-20.04-102.08c-3.7-19.73-6.63-39.62-9.47-59.49c-1.91-13.31-2.97-26.74-4.57-40.1
				c-2.27-18.96-4.77-37.89-6.99-56.85c-1.2-10.23-1.72-20.54-3.09-30.74c-2.58-19.26-5.58-38.45-8.37-57.68
				c-2.4-16.59-4.01-33.34-9.88-49.21C816.07,444.78,813.62,439.59,811.38,434.31z M873.97,879.84c-0.43,0.15-0.86,0.31-1.29,0.46
				c-1.81,17.5,12.39,55.57,22.86,60.29c-0.37-1.02-0.57-1.78-0.91-2.47c-7.7-15.55-14-31.57-17.22-48.73
				C876.78,886.11,875.14,883.02,873.97,879.84z M900.85,941.02c-4.69-14.39-9.17-28.11-13.65-41.83c-0.43,0.16-0.87,0.31-1.3,0.47
				C887.64,914.38,890.64,928.66,900.85,941.02z M909.68,941.96c-2.2-5.46-4.62-10.85-6.54-16.41c-1.86-5.41-3.21-11.01-4.78-16.52
				c-0.66,0.22-1.31,0.43-1.97,0.65C896.08,922.32,901.87,932.66,909.68,941.96z"/>
			<path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M400.46,366.21c2.62,0,5.25,0.13,7.85-0.05c1.43-0.1,3.25-0.29,4.18-1.18c7.57-7.21,16.87-6.16,25.92-5.33
				c5.4,0.5,10.76,2.05,16.01,3.56c7.52,2.17,14.51,1.37,21.8-1.48c3.38-1.32,7.77-0.51,11.63-0.08c4.04,0.45,6.88,0.06,8.72-4.33
				c1.86-4.43,7.69-6.22,13.44-4.96c1.29,0.28,2.52,0.81,3.47,1.12c6.68-8.58,15.7-11.86,25.8-13.3c3.32-0.48,6.48-2.39,9.79-2.62
				c4.82-0.34,9.72,0.39,14.57,0.34c2.23-0.02,5.6-0.06,6.49-1.39c10.1-15.1,27.47-12.95,41.47-7.35c4.27,1.71,8.09,4.55,12.07,6.95
				c2.77,1.68,5.27,2.78,8.81,1.34c2.42-0.98,5.57-0.35,8.37-0.12c6.43,0.54,12.84,1.29,19.25,1.92c0.99,0.1,2.16,0.34,2.97-0.05
				c7.63-3.71,13.33-0.19,18.9,4.61c0.93,0.8,3.22,1.13,4.22,0.54c8.11-4.75,15.93-3.84,23.53,0.93c3.44,2.16,6.68,3.01,10.97,2.96
				c7.53-0.08,15.18,1.15,22.57,2.77c3.29,0.72,6.44,3.35,9.01,5.79c3.46,3.3,6.79,4.97,11.6,3.14c5.25-2,10.01-0.67,15.47,4.03
				c-6.27,0.68-11.22,1.47-16.21,1.72c-34.2,1.76-68.41,3.49-102.62,5.08c-18.6,0.86-37.22,1.48-55.84,2.02
				c-1.93,0.06-4.16-0.75-5.81-1.83c-3.61-2.37-6.98-2.05-10.86-0.67c-7.05,2.5-14.27,4.56-21.45,6.7c-3.3,0.99-7.42,0.84-7.49,5.21
				c2.86-1.07,5.65-2.22,8.51-3.17c7.11-2.34,14.41-4.18,21.33-6.99c5.67-2.3,12.11,0.78,13.1,7.23c0.29,1.92,0.28,3.88,0.42,5.97
				c13.65-6.16,24.36-0.56,35.17,7.66c2.12-1.54,4.61-3.42,7.17-5.19c7.9-5.48,16.47-4.05,22.46,3.41c0.84,1.04,3.03,1.76,4.35,1.49
				c3.23-0.67,5.72-0.44,8.05,2.25c0.88,1.01,3.23,1.69,4.49,1.27c4.49-1.49,8.3-0.86,12.3,1.66c2.45,1.55,5.89,1.53,8.32,3.1
				c2.11,1.36,4.55,3.88,4.71,6.04c0.12,1.57-3.07,3.67-5.12,5.02c-1.28,0.84-3.18,0.89-4.82,0.98c-18.54,1.05-37.08,2.3-55.64,2.97
				c-12.92,0.46-25.88,0.25-38.81-0.04c-2.63-0.06-5.14-2.39-7.83-2.75c-7.2-0.96-14.47-1.97-21.71-1.96
				c-16.97,0.01-33.93,0.52-50.89,1.01c-6.32,0.18-12.57,0.58-18.59-1.94c-0.98-0.41-2.32-0.25-3.42-0.01
				c-7.05,1.53-10.81-0.36-15.15-6.31c-1.67-2.29-4.75-4.09-7.54-4.88c-3.46-0.98-6.07-2.11-8.54-5.07
				c-3.1-3.73-7.48-6.4-11.16-10.94c3.13-0.17,6.25-0.45,9.38-0.48c7.41-0.07,14.84,0.19,22.24-0.2c1.58-0.08,3.72-2.09,4.43-3.72
				c2.42-5.56,6.08-8.07,11.73-5.9c5.09,1.96,9.69,5.21,16.64,9.07c5.63-1.25,13.95-3.08,22.25-4.99c0.94-0.22,2.15-0.6,2.62-1.32
				c4.76-7.11,11.99-5.79,18.86-5.67c1.49,0.03,3.01,0.08,4.47,0.36c3.91,0.73,5.62-0.89,6.42-4.75c1.63-7.91,6.47-11.78,14.81-12.01
				c3.13-0.09,6.33,0.28,9.4,0.89c5.63,1.13,11.08,1.69,16.63-0.61c1.69-0.7,4.02-0.52,5.89-0.05c5.67,1.44,10.94,2.27,16.96,0.18
				c3.34-1.16,7.99,1.15,11.98,2.19c4.64,1.22,8.4,2.1,12.26-2.83c2.54-3.25,7.72-4.43,12.58-7c-6.44,0.2-11.48,1.75-15.17,6.08
				c-2.08,2.44-4.04,2.91-6.93,1.94c-3.3-1.1-6.71-1.94-10.12-2.65c-1.75-0.37-4.02-0.94-5.35-0.19c-5.56,3.15-10.96,1.18-16.45,0.21
				c-1.45-0.26-3.2-0.72-4.41-0.19c-7.33,3.24-14.64,1.2-22,0.37c-13.45-1.53-19.61,2.74-23.03,16.06c-4.95,0-10.05-0.12-15.13,0.04
				c-4.89,0.15-9.12,1.24-12.73,5.47c-2.1,2.47-6.31,3.56-9.78,4.34c-4.66,1.05-9.52,1.35-14.32,1.65c-1.17,0.07-2.46-1.03-3.64-1.69
				c-4.28-2.42-8.3-5.54-12.86-7.17c-5.92-2.11-11.12,0.57-13.25,6.47c-1.16,3.2-3.08,3.45-5.67,3.41
				c-7.75-0.12-15.5-0.26-23.25-0.31c-1.3-0.01-2.66,0.26-3.9,0.67c-5.99,1.97-11.29,0.64-17.16-1.62
				c-4.74-1.82-10.61-0.73-15.99-0.88c-1.43-0.04-3.1,0.62-4.27,0.09c-8.01-3.62-15.92-7.48-23.86-11.26
				C400.5,366.74,400.48,366.48,400.46,366.21z"/>
			<path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M163.88,441.75c2.7,0.79,5.36,1.9,8.12,2.31c3.91,0.58,8.31-0.32,11.79,1.12
				c9.21,3.81,17.05,0.52,24.6-3.8c10.8-6.19,21.28-12.95,31.89-19.47c1.52-0.94,3.02-1.92,5.45-3.47c-2.59,6.42-0.8,9.3,5.27,9.59
				c1.03,0.05,2.29,1.73,2.91,2.94c2.66,5.2,4.7,6.52,10.18,4.67c5-1.69,9.85-3.98,14.51-6.49c7.44-4.01,14.66-8.41,21.98-12.65
				c0.43,0.36,0.86,0.72,1.29,1.09c-2.64,3.77-5.1,7.7-8,11.27c-2.21,2.73-3.76,4.95-1.89,8.76c2.24,4.55-1.42,7.82-3.89,11.03
				c-0.4,0.52-2.59,0.24-3.53-0.35c-2.88-1.79-5.35-1.14-7.75,0.72c-3.97,3.08-8.52,3.5-13.36,4.3c-6.02,0.99-12.39,3.35-17.27,6.95
				c-9.18,6.78-18.83,11.55-30.08,13.5c-9.12,1.58-16.69,7.11-24.98,10.67c-13.78,5.91-25.54,15.1-39.01,21.26
				c-3.36,1.54-7.25,1.9-10.9,2.81c-0.27-0.43-0.55-0.87-0.82-1.3c3.53-3.72,7.05-7.43,10.84-11.42c-1.68-1.05-3-1.86-5.6-3.48
				c8.05-6.77,15.64-13.16,23.01-19.36c-3.39-1.6-6.43-3.38-9.71-4.46c-1.89-0.63-4.28-0.57-6.21-0.01c-1.81,0.52-3.5,1.91-4.93,3.26
				c-8.7,8.19-17.08,16.74-26.05,24.62c-6.2,5.45-13.06,10.16-19.87,14.85c-1.59,1.09-4.35,0.34-6.2,1.22
				c-8.19,3.92-16.44,7.78-24.24,12.39c-3.14,1.86-5.36,2.98-8.81-0.02c3.55-4.21,1.58-9.12-1.4-11.8
				c-2.84-2.56-7.91-2.63-13.53-4.23c0.18-1.77,0.54-5.27,1-9.84c-12.58,6.79-19.75,19.07-30.88,27.07c0.77-3.9,3.54-6.6,6.14-9.37
				c2.79-2.98,5.65-5.88,8.03-9.37c-12.39,2.73-20.91,11.09-29.96,21.4c0-3.78,0-6.01,0-8.24c0-6.16,0.79-12.45-0.18-18.45
				c-1.56-9.57,3.28-14.1,10.86-18.05c7.9-4.12,15.28-9.22,22.93-13.82c1.98-1.19,4.03-2.48,6.23-3.06
				c9.19-2.39,16.41-7.8,22.89-14.42c3.55-3.62,7.65-4.46,12.77-5.1c6.02-0.75,11.82-4.59,17.29-7.81c2.84-1.68,4.76-4.93,7.86-8.31
				c-6.75,1.17-12.42,2.15-18.09,3.13c-0.22-0.37-0.43-0.74-0.65-1.11c18.3-10.3,28.8-28.49,44.59-41.33c-0.3-0.37-0.6-0.73-0.91-1.1
				c-3.89,2.07-7.78,4.14-12.64,6.73c7.31-13.35,13.96-25.51,20.61-37.67c0.31,0.13,0.62,0.26,0.92,0.4
				c-1.24,3.09-2.47,6.19-3.73,9.28c-0.25,0.61-0.63,1.18-0.89,1.79c-2.9,6.84-0.16,9.94,8.88,9.04
				c-3.64,5.88-7.15,11.27-10.35,16.83c-1.55,2.69-3.16,5.61-3.62,8.59c-1.04,6.69-3.62,12.34-8.55,17
				c-1.34,1.27-2.13,3.13-2.86,5.34c9.76-3.29,19.35-6.08,30.91-3.46c-4.86,6.41-9.38,12.38-14.42,19.03
				c4.71,0.76,9.13,1.46,13.55,2.19c1.13,0.18,2.3,0.28,3.35,0.68c2.28,0.87,5.27,3.33,6.58,2.61c3.27-1.81,5.77-5.03,8.58-7.69
				C163.98,441.64,163.88,441.75,163.88,441.75z"/>
			<path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M157.18,836.47c-0.73,3.26-0.88,6.8-2.31,9.71c-1.96,3.98-0.66,7.06,1.25,10.27
				c1.92,3.22,5.11,2.63,7.56,1.5c5.2-2.4,10.1-5.43,15.2-8.23c4.68,6.92,4.87,8.76-0.59,15.3c-4.19,5.01-9.01,9.49-7.55,16.97
				c0.13,0.65-0.46,1.59-0.96,2.18c-5.94,7.06-12.33,13.8-17.69,21.26c-1.97,2.75-1.95,7.18-2.18,10.88
				c-0.4,6.61,0.47,13.41-0.74,19.83c-0.63,3.36-4.59,6.07-6.96,9.13c-3.83,4.95-8.45,4.99-13.65,2.46
				c-3.13-1.52-6.35-2.88-9.56-4.24c-6.59-2.78-12.6-1.87-18.15,2.73c-1.76,1.46-3.75,2.97-5.89,3.61
				c-7.75,2.31-13.08,7.58-17.98,13.62c-3.98,4.91-8.16,9.66-12.39,14.36c-1.19,1.32-2.81,2.26-4.3,3.28
				c-7.53,5.14-15,10.37-22.65,15.31c-3.97,2.56-8.24,4.7-12.48,6.82c-7.5,3.76-15.09,7.36-22.82,11.11
				c-0.06-0.64-0.28-2.03-0.28-3.41c-0.02-27.14,0.04-54.28-0.09-81.42c-0.01-3.22,0.84-5.08,3.78-6.65
				c10.88-5.8,21.62-11.87,34.45-12.01c2.16-0.02,4.48-0.29,6.44,0.37c6.18,2.08,10.01-0.75,13.65-5.31
				c10.27-12.86,20.8-25.51,31.15-38.3c6.73-8.32,15.23-13.19,25.62-16.13c9.15-2.59,17.67-7.42,26.5-11.2
				c4.03-1.73,8.14-3.29,12.21-4.92C156.24,835.73,156.71,836.1,157.18,836.47z"/>
			<path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M927.29,799.63c-4.66-11.13-10.33-22.81-14.42-35.02c-3.3-9.84-4.87-20.29-6.92-30.53
				c-6.72-33.56-13.3-67.15-19.96-100.72c-1.71-8.64-3.97-17.2-5.09-25.91c-1.39-10.77-6.39-19.86-11.88-28.78
				c-4.93-8.02-8.99-17.26-20.78-16.9c2.13-6.47,7.65-9.27,12.79-7.38c8.59,3.14,17.05,6.38,23.28,13.68
				c1.18,1.38,3.8,2.15,5.73,2.12c2.84-0.04,4.8-1.56,3.17-4.97c-1.07-2.24-2.24-4.47-3-6.82c-0.44-1.36-0.15-2.95-0.19-4.44
				c1.36,0.03,2.81-0.21,4.08,0.15c4.93,1.4,9.76,3.19,14.73,4.44c4.05,1.02,7.74,0.74,8.29-4.82c0.11-1.07,1.01-2.07,1.59-3.07
				c2.31-3.95,4.33-7.93,1.06-12.57c0.53-0.17,1.07-0.33,1.6-0.5c1.06,2.78,2.18,5.55,3.18,8.36c1,2.81,1.73,5.74,2.9,8.47
				c0.71,1.65,2.47,2.89,2.97,4.56c0.57,1.89,0.72,4.21,0.09,6.01c-0.23,0.66-3.38,0.92-4.88,0.4c-3.83-1.32-5.37,0.57-6.03,3.74
				c-0.57,2.75-1.24,5.64-0.97,8.37c1.93,19.01-4.81,36.36-10.26,53.9c-1.44,4.64-0.56,7.25,3.65,9.6c0.58-0.73,1.59-1.41,1.65-2.17
				c0.86-10.74,8.69-19.16,9.88-29.84c0.25-2.21,2.73-4.09,3.86-6.29c1.31-2.54,2.23-5.28,3.38-7.9c0.92-2.09,1.65-4.35,2.98-6.16
				c2.66-3.6,5.68-6.93,9.32-10.13c-1.29,3.28-2.59,6.56-4.17,10.56c6.51-0.41,9.32-5.47,14.15-8.92c0.22,3.37,0.45,6.09,0.56,8.81
				c0.66,16.28,0.9,32.58,2.11,48.82c0.63,8.4,3.51,16.61,4.37,25.01c0.8,7.84,0.45,15.82,0.29,23.73
				c-0.24,12.48-0.84,24.96-1.07,37.44c-0.14,7.3,0.3,14.61,0.17,21.91c-0.08,4.46-0.94,8.89-1.14,13.35
				c-0.37,8.29-0.79,16.6-0.64,24.89c0.07,3.97-1.57,5.66-4.97,6.78C944.4,793.65,936.06,796.6,927.29,799.63z"/>
			<path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M633.24,1405.74c1.85-6.32,2.74-11.59,4.91-16.26c2.85-6.14,3.75-12.33,3.05-18.89
				c-1.82-17.08,1.04-33.82,3.56-50.58c0.64-4.26,1.3-8.51,2.02-13.25c29.48,9.09,57.29-2.3,85.67-6.96
				c6.46,26.61,22.27,46.22,45.05,60.8c4.58,2.93,8.64,6.77,12.6,10.54c8.02,7.66,9.84,17.01,6.66,27.47
				c-0.58,1.91-1.25,3.79-1.97,5.65c-6.24,16.02-5.39,13.69-20.97,11.65c-29.77-3.91-59.2-10.34-88.88-15.05
				c-6.7-1.06-13.85,0.36-20.76,1.06C654.48,1402.89,644.82,1404.28,633.24,1405.74z"/>
			<path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M136.74,1325.64c0-1.15,0-2.29,0-3.04c-3.37-0.47-6.37-0.89-9.76-1.37c0.75-2.25,2.53-5.46-1.95-5.94
				c-4.81-0.51-5.7,2.92-5.36,7.81c-6.23-3.45-8.78,1.29-12.15,5.05c-0.57-6.02-1.05-11.2-1.54-16.38c-0.82,0.03-1.65,0.07-2.47,0.1
				c0,7.58,0,15.15,0,22.73c-0.48,0.05-0.95,0.1-1.43,0.16c-0.64-2-1.29-3.99-1.93-5.99c-0.42,0-0.85,0.01-1.27,0.01
				c-0.52,3.39-1.04,6.78-1.56,10.17c-0.4,0.03-0.81,0.05-1.21,0.08c-0.56-6.13-1.12-12.25-1.68-18.38
				c-0.24-0.07-0.49-0.14-0.73-0.21c-0.61,3.18-1.22,6.36-1.88,9.77c-0.97-0.13-1.91-0.26-2.6-0.35c-0.41,2.88-0.77,5.42-1.14,8.04
				c-5.24-3.6-9.48-6.52-14.32-9.84c2.86,5.98,5.23,10.95,7.6,15.92c-0.58,0.26-1.16,0.51-1.75,0.77c-1.13-3.06-2.26-6.11-3.39-9.17
				c-0.23,0.01-0.45,0.01-0.68,0.02c0,5.34,0,10.68,0,16.59c-1.39,0.67-3.5,1.7-5.61,2.73c0.11,0.35,0.23,0.69,0.34,1.04
				c4.73-0.97,9.48-1.88,14.2-2.92c6.09-1.34,12.14-2.91,18.25-4.13c8.03-1.6,16.12-2.91,24.17-4.42c4.37-0.82,9.29-0.77,12.96-2.87
				c11.72-6.71,25.35-5.05,37.59-9.47c4.2-1.52,9.49,0.28,14.2-0.23c18.88-2.04,37.72-4.38,56.95-5.72
				c-7.97,2.7-15.95,5.4-23.92,8.09c0,0.59,0,1.17,0.01,1.76c7.99,0.52,15.98,1.05,23.08,1.51c-1.56,2.2-3.05,4.59-4.85,6.73
				c-0.78,0.93-2.13,1.49-3.33,1.94c-1.64,0.62-3.38,0.98-5.07,2.15c6.07,3.35,12.73,1.62,20.29,2.43
				c-13.57,3.53-26.04,6.49-38.31,10.12c-3.76,1.11-6.8,4.43-10.48,6.04c-5.66,2.48-12.29,3.31-17.24,6.7
				c-9.26,6.34-19.71,5.42-29.77,6.91c-9.17,1.36-18.53,1.58-27.63,3.26c-6.99,1.29-13.71,4.05-20.55,6.15
				c-0.63,0.19-1.26,0.4-1.91,0.49c-8.16,1.1-16.38,1.89-24.48,3.35c-7.12,1.29-14.03,3.87-21.17,4.89
				c-7.33,1.04-14.86,0.65-22.26,1.33c-4.37,0.4-8.69,1.53-12.98,2.57c-8.34,2.02-16.64,4.2-25.55,6.46c0-12.9,0-24.92,0-36.93
				c8.03-1.29,16.51-1.36,23.87-4.27c5.79-2.29,11.99-2.52,17.48-5.17c4.97-2.4,9.56-5.61,14.61-7.8c2.69-1.17,6.01-0.78,8.99-1.4
				c1.95-0.41,3.78-1.35,5.38-2.91c-4.12,0.52-8.24,1.03-12.6,1.58c0-4.43,0-7.87,0-11.55c4.21,1.06,8.12,2.06,12.04,3.05
				c0.14-0.47,0.28-0.94,0.42-1.41c-2.19-0.65-4.34-1.46-6.57-1.91c-4.52-0.91-6.25-3.2-4.49-7.76c0.36-0.94,0.05-2.13-0.91-3.6
				c-1.88,3.06-3.76,6.13-6.05,9.86c-1.56-6.54-0.84-12.94-6.62-18.5c0.3,3.14,0.46,4.86,0.62,6.57c-0.4,0.12-0.81,0.24-1.21,0.36
				c-1.46-4.55-2.92-9.09-4.04-12.58c7.48-3.51,13.62-6.4,20.57-9.66c-1.99-2.75-3.28-4.54-4.57-6.34c0.09-0.42,0.18-0.83,0.27-1.25
				c2.41,0.68,4.88,1.21,7.23,2.07c2.82,1.03,5.54,2.33,8.38,3.54c2.15,1.7,4.31,4.09,7,5.34c2.72,1.27,4.37-0.63,3.16-3.1
				c-0.43-0.88-1.06-1.67-1.53-2.53c-0.48-0.87-0.89-1.78-1.44-2.9c0.78-1.08,1.62-2.23,2.64-3.64c-5.52-4.15-10.82-8.14-16.88-12.7
				c4.03-1.62,7.65-3.07,11.27-4.53c0.23,0.39,0.46,0.78,0.69,1.17c0.71-1.41,1.42-2.82,2.1-4.16c19.06-1.82,36.74,3.35,54.08,9.84
				c8.39,3.14,11.15,11.03,13.26,18.85c1.37,5.09,2.01,10.37,1.94,16.09c-2.4-4.07-4.76-8.17-7.23-12.2
				c-1.61-2.64-2.24-5.73-7.22-4.82c-2.46,0.45-6.68-3.03-8.45-5.8c-3.04-4.74-4.78-10.31-7.06-15.53c-0.57,0.22-1.13,0.45-1.7,0.67
				c0.38,1.08,0.72,2.17,1.14,3.23c1.53,3.84,3.01,7.7,4.68,11.47c0.48,1.1,1.35,2.44,2.35,2.82c4.07,1.51,6.23,7.35,11.99,5.36
				c0.4-0.14,1.79,2.43,2.63,3.8c1.43,2.34,2.9,4.68,4.14,7.12c1.17,2.29,2.06,4.72,3.33,7.68c-1.59-0.08-2.42-0.12-3.61-0.18
				c0.27,1.71,0.51,3.29,0.8,5.15c-6.08-2.37-6.18-8.5-8.18-13.34c-0.42,0.13-0.83,0.25-1.25,0.38c0.45,2.55,0.9,5.11,1.41,7.97
				c-0.71,0.25-1.71,0.61-2.7,0.96L136.74,1325.64z M44.64,1373.88c0.12,0.58,0.24,1.17,0.36,1.75c9.75-0.43,19.5-0.85,29.25-1.28
				c0.08,0.6,0.16,1.2,0.25,1.79c-6.33,1.86-12.66,3.72-18.98,5.58c0.77,0.84,1.41,1.16,1.96,1.04c14.9-3.11,29.77-6.31,44.68-9.4
				c6.44-1.34,12.93-2.42,19.39-3.7c4.82-0.96,9.69-1.81,14.4-3.17c4.39-1.27,8.49-3.83,12.93-4.61c8.47-1.49,17.09-2.08,25.63-3.13
				c0.97-0.12,1.87-0.74,2.81-1.13c-8.37-0.9-16.56-1.12-24.74-0.84c-7.44,0.26-14.93,0.71-21.41,5.27c-0.94,0.66-2.46,0.6-3.72,0.66
				c-9.91,0.48-19.86,0.51-29.72,1.47c-9.39,0.92-18.68,2.8-28.02,4.23c-6.88,1.06-13.79,1.97-20.64,3.18
				C47.5,1371.87,46.11,1373.09,44.64,1373.88z"/>
			<path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M1057.48,1246.76c0-28.96,0-57.91,0-87.71c9.58,5.62,18.23,10.69,26.87,15.76
				c0.57,0.34,1.14,0.68,1.71,1.02c12.52,7.4,25.05,14.77,37.55,22.22c7.1,4.23,9.4,3.68,17.73-5.43
				c-8.02-4.09-15.75-7.95-23.41-11.95c-7.18-3.74-14.34-7.53-21.43-11.46c-3.83-2.12-7.2-5.84-12.09-2.05
				c-0.56,0.43-2.32-0.09-3.14-0.7c-7.33-5.43-14.61-10.92-21.79-16.54c-0.86-0.68-1.11-2.23-1.48-3.42
				c-0.19-0.61-0.02-1.32-0.02-1.99c0-10.78,0-21.57,0-32.35c0.61-0.12,1.23-0.25,1.84-0.37c0.78,0.87,1.55,1.74,2.33,2.61
				c4.99,5.56,9.85,11.24,15.05,16.6c1.65,1.71,4.08,3.33,6.35,3.65c5.98,0.83,10.04,4.46,14.64,7.82c4.26,3.12,9.47,5.9,14.59,6.68
				c5.88,0.9,11.18,2.2,16.1,5.52c2.81,1.9,5.64,1.94,7.64-2.05c-9.03-8.53-16.6-19.15-28.64-23.95
				c-10.83-4.32-13.95-13.86-17.97-23.03c-2.06-4.69,3.05-5.92,4.83-8.84c-5.79-7.08-11.43-13.99-17.1-20.93
				c6-4.12,8.14-4.08,12.55,0.19c6.21,6.03,12.07,12.5,21.67,13c1.09,0.06,2.28,1.8,3.08,2.98c4.75,7.03,11.74,9.39,19.74,9.6
				c4.27,0.11,8.59-8.22,6.34-12.06c-3.84-6.55-7.69-13.1-11.6-19.75c4.05,0,7.71-0.77,10.84,0.19c4.26,1.31,8.74,3.23,12.03,6.13
				c7.32,6.47,9.51,14.77,9.38,24.86c-0.57,44.95-0.25,89.91-0.25,134.87c0,1.79,0,3.58,0,6.12c-5.77-3.65-10.96-6.8-16.02-10.16
				c-6.26-4.16-12.19-2.16-18.22,0.53c-1.88,0.84-3.98,1.21-5.97,1.83c-1.37,0.43-3.42,0.54-3.95,1.48
				c-2.38,4.22-5.68,3.11-8.52,1.43c-9.01-5.29-17.86-10.85-26.68-16.44c-4.17-2.64-10.52-1.04-12.16,3.54
				c-0.34,0.94,0.3,2.68,1.08,3.5c2.31,2.44,1.5,4.22-0.44,6.38c-3.95,4.39-7.68,8.99-11.51,13.5
				C1058.5,1247.32,1057.99,1247.04,1057.48,1246.76z"/>
			<path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M749.71,1428.71c-16.57-7.23-33.14-14.46-49.7-21.69c0.16-0.39,0.32-0.78,0.48-1.18
				c24.01,4.07,48.03,8.13,72.27,12.24c-2.44,5.76-1.56,7.18,3.28,8.78c4.41,1.46,8.53,3.81,12.95,5.25c4.46,1.46,8.88,1.2,12.4-2.61
				c2.27-2.46,4.78-2.34,8.03-1.73c10.91,2.03,21.94,3.37,32.9,5.13c3.24,0.52,6.7,0.96,9.52,2.45
				c12.16,6.44,24.62,11.31,38.62,12.26c5.61,0.38,11.3,2.68,16.52,5.05c13.01,5.89,25.8,12.27,38.62,18.58
				c1.99,0.98,3.67,2.6,5.78,4.13c-12.14,1.96-23.23,0.15-34.26-3.09c-4.42-1.3-9.16-2.37-13.71-2.21
				c-14.83,0.54-28.83-4.19-43.26-6.03c-7.57-0.97-15.26-1.42-22.89-1.3c-12.24,0.18-23.16-5.42-34.93-7.32
				c-4.9-0.79-9.28-4.53-14.02-6.65c-1.81-0.81-3.97-0.86-5.97-1.25c-0.14,0.48-0.27,0.96-0.41,1.44c3.39,1.11,6.78,2.23,10.16,3.34
				c0.01,0.62,0.01,1.23,0.02,1.85c-4.21,0.31-8.86,1.93-12.53,0.68c-10.49-3.56-20.69-8.07-30.78-12.67
				c-4.02-1.83-7.39-5.04-11.21-7.37c-2.79-1.7-5.79-3.07-8.76-4.44c-2.1-0.96-4.51-1.36-6.46-2.54
				c-13.83-8.32-29.39-12.02-44.57-16.62c-3.03-0.92-6.81,0.05-10.12,0.75c-4.99,1.04-9.91,2.51-14.81,3.97
				c-6.15,1.83-12.31,3.66-18.33,5.85c-1.51,0.55-2.72,2.36-3.68,3.85c-1.07,1.65-1.87,3.53-2.49,5.42
				c-4.32,13.22-11.25,14.01-24.72,14.5c-9.12,0.33-18.47-1.03-26.69,4.08c-2.94,1.83-6.1,4.47-7.42,7.5
				c-3.32,7.6-9.23,11.71-16.73,12.56c-9.78,1.11-18.79,3.51-27.05,8.93c-2.26,1.48-5.11,2.07-8.59,3.43c1.93-3.3,3.31-5.65,4.69-8
				c-0.24-0.3-0.47-0.6-0.71-0.9c-1.29,0.73-2.72,1.3-3.85,2.23c-2.95,2.43-6.22,4.67-8.56,7.63c-5.35,6.78-13.21,9.88-20,14.53
				c-0.99,0.68-2.48,0.86-3.74,0.86c-24.3,0.04-48.6,0.03-72.9,0.01c-0.48,0-0.95-0.16-1.82-0.32c0.36-0.67,0.51-1.3,0.92-1.67
				c17.19-15.67,35.63-28.91,59.7-31.71c10.19-1.19,19.53-4.82,28.37-10.22c10.37-6.33,22.28-8.23,34-10.83
				c9.8-2.18,19.22-6.05,28.83-9.12c4.24-1.35,8.52-2.57,12.77-3.86c3.64-1.1,7.42-1.89,10.88-3.41
				c13.05-5.72,25.5-13.57,39.09-17.13c21.42-5.61,43.54-7.72,65.07,1.3c3.65,1.53,7.47,3.04,11.35,3.53
				c14.09,1.77,26.65,7.98,39.62,13.04c2.68,1.05,5.45,1.88,8.18,2.8C749.27,1430.09,749.49,1429.4,749.71,1428.71z"/>
			<path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M1024.81,1381.94c-2.13,0.21-3.87,0.39-5.98,0.6c0-36.72-0.31-73.34,0.17-109.95
				c0.16-12.39,2.48-24.74,3.46-37.14c0.19-2.38-1.15-4.91-1.93-7.32c-0.84-2.6-2.71-5.16-2.68-7.72
				c0.48-36.79,0.9-73.58,2.03-110.35c0.41-13.28,2.64-26.5,3.89-38.32c-2.98,5.21-4.66,12.52-5,20.15
				c-1.12,25.25-2.39,50.5-3.09,75.76c-0.49,17.45-0.15,34.92-0.1,52.39c0.01,1.78-0.03,4.16,1.02,5.24
				c5.34,5.51,3.48,11.98,2.74,18.18c-3.48,29.17-2.88,58.46-2.73,87.74c0.08,15.64,0.27,31.3,0.82,46.93
				c0.19,5.52-3.21,8.25-7.88,11.44c-0.16-5.49-0.88-11.02-0.38-16.45c4.02-44.02,1.28-88.11,1.58-132.16
				c0.2-29.28,0.02-58.57,0.23-87.85c0.14-19.94,0.86-39.88,0.93-59.82c0.03-6.75-1.13-13.5-1.75-20.37
				c6.11-2.16,12.47-4.39,18.82-6.66c2.81-1,3.77,0.5,3.7,2.91c-0.19,5.8-0.54,11.6-0.83,17.4c-0.2,3.84-1.15,7.84,2.77,12.1
				c0.76-6.18,1.21-11.37,2.06-16.49c0.95-5.7,2.16-11.37,3.52-16.99c0.29-1.19,1.71-2.1,2.6-3.14c0.58,1.22,1.68,2.46,1.64,3.66
				c-0.26,7.3-0.84,14.58-1.2,21.87c-0.15,3.05-0.02,6.12-0.02,9.18c0.62,0.06,1.24,0.12,1.87,0.17c1.85-11.22,3.7-22.43,5.48-33.18
				c-0.76,1.48-1.82,3.56-3.38,6.62c-0.38-3.64-0.64-6.19-0.98-9.47c7.22,1.1,11.06-4.23,15.14-9.12c0.55,0.28,1.1,0.55,1.65,0.83
				c-0.59,5.95-1.17,11.9-1.78,17.85c-0.07,0.65-0.52,1.31-0.46,1.93c1.58,15.19-3.04,29.82-4.02,44.75
				c-1.25,19.25-1.62,38.56-2.03,57.85c-0.35,16.44-1.09,32.94-0.06,49.31c1.04,16.55-0.48,32.79-1.85,49.18
				c-0.8,9.54,0.81,19.26,0.63,28.88c-0.3,16.23-1.05,32.46-1.9,48.68c-0.14,2.63-1.83,5.17-2.7,7.79c-0.61,1.85-1.06,3.76-1.1,5.85
				c-1.35,3.21-10.66-5.75-7.09,5.88c-0.4,0.15-0.79,0.3-1.19,0.46c-1.82-1.41-4.36-2.46-5.3-4.32c-1.38-2.73-2.15-6.01-2.24-9.09
				c-0.81-26.45-1.41-52.9-2.07-79.35c-0.24-9.63-0.89-19.27-0.58-28.88c0.2-6.22,1.95-12.38,2.76-18.6
				c0.56-4.24,0.9-8.54,0.99-12.82c0.4-17.97,0.77-35.94,0.97-53.92c0.15-14.1,0.03-28.21,0.03-42.32c-0.6-0.06-1.19-0.12-1.79-0.19
				c-0.2,1.26-0.53,2.52-0.57,3.79c-0.4,12.81-0.68,25.62-1.14,38.43c-0.67,18.43-0.47,36.97-2.64,55.23
				c-1.07,8.95-3.38,17.57-2.52,26.85c1.13,12.2,1.37,24.51,1.39,36.78c0.02,17.13-0.53,34.25-0.81,51.38
				c-0.16,9.99-0.18,19.98-0.39,29.96C1025.45,1369.92,1025.07,1375.89,1024.81,1381.94z M1040.79,1277.67
				c-0.44,0-0.88,0.01-1.32,0.01c0,8.61,0,17.22,0,25.83c0.44-0.01,0.88-0.01,1.32-0.02
				C1040.79,1294.89,1040.79,1286.28,1040.79,1277.67z M1049.79,1134.78c-0.58,0-1.16,0-1.75,0.01c-0.75,8.33-1.5,16.67-2.24,25
				c0.53,0.04,1.05,0.08,1.58,0.11c0.59-4.21,1.32-8.4,1.73-12.63C1049.5,1143.13,1049.57,1138.95,1049.79,1134.78z M1047.53,1206.25
				c-0.34,0-0.68,0-1.02,0c0,5.02,0,10.05,0,15.07c0.34,0,0.68,0,1.02,0C1047.53,1216.3,1047.53,1211.27,1047.53,1206.25z
				M1046.59,1226.88c-0.39-0.04-0.78-0.09-1.16-0.13c-0.85,4.8-1.69,9.6-2.54,14.41c0.59,0.08,1.17,0.16,1.76,0.24
				C1045.3,1236.56,1045.95,1231.72,1046.59,1226.88z M1048.33,1184.89c-0.29,0.01-0.58,0.02-0.86,0.04c0,3.8,0,7.6,0,11.4
				c0.29-0.02,0.58-0.03,0.86-0.04C1048.33,1192.49,1048.33,1188.69,1048.33,1184.89z M1040.37,1268.21
				c0.36,0.01,0.72,0.02,1.08,0.03c0-4.08,0-8.16,0-12.24c-0.36,0.01-0.72,0.01-1.08,0.02
				C1040.37,1260.07,1040.37,1264.14,1040.37,1268.21z M1050.35,1118.41c-0.39,0.01-0.77,0.02-1.16,0.03c0,3.45,0,6.9,0,10.35
				c0.39,0.01,0.77,0.02,1.16,0.03C1050.35,1125.35,1050.35,1121.88,1050.35,1118.41z M1041.07,1344.74
				c-0.36,0.01-0.72,0.02-1.07,0.03c0,2.68,0,5.36,0,8.03c0.48-0.01,0.95-0.03,1.43-0.04
				C1041.31,1350.09,1041.19,1347.41,1041.07,1344.74z"/>
			<path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M904.62,1231.94c-4.66-0.49-9.54-0.24-13.93-1.6c-12.41-3.86-24.73-1.86-37.05-0.3
				c-8.56,1.08-17.02,2.88-25.56,4.1c-1.78,0.25-4.22-0.02-5.51-1.08c-14.42-11.92-31.8-16.04-49.54-19.55
				c-11.54-2.28-22.99-5.05-34.38-7.98c-14.15-3.64-28.31-7.34-42.27-11.64c-10.45-3.22-20.34-8.66-30.94-10.89
				c-16.21-3.4-29.12-11.7-41.47-23.26c3.54,0,6.56,0.26,9.53-0.06c5.54-0.58,7.16-3.28,4.21-7.91c-3.64-5.7-7.85-11.06-12.14-16.31
				c-5.35-6.54-11.03-12.81-16.89-19.58c9.04-3.62,17.26,4.02,25.88,0.13c-6.01-10.17-11.68-19.75-17.34-29.34
				c0.61-0.38,1.23-0.77,1.84-1.15c2.34,3.41,4.63,6.86,7.04,10.23c4.15,5.81,8.08,11.82,12.64,17.3c4.97,5.98,11.41,7.6,18.85,4.54
				c1.92-0.79,4.13-0.91,6.98-1.49c-4.44-15.64-18.15-24.89-25.53-38.12c0.34-0.39,0.69-0.78,1.03-1.17c1.41,1,3.01,1.81,4.19,3.02
				c6.72,6.89,13.18,14.04,20.06,20.77c3.85,3.76,5.71,7.96,7.16,13.18c1.81,6.52,5.4,12.69,9.06,18.48c2.35,3.7,6.07,6.6,9.44,9.57
				c6.88,6.08,11.08,13.59,12.77,22.56c0.18,0.97,0.15,2.13,0.67,2.88c4.37,6.22,8.8,12.4,13.32,18.51c0.76,1.02,1.94,2.12,3.11,2.39
				c6.58,1.47,13.21,2.7,19.82,4.01c0.26-0.43,0.52-0.86,0.78-1.29c-1.31-1.75-2.61-3.5-3.93-5.24
				c-5.24-6.87-10.57-13.67-15.68-20.63c-1.86-2.53-2.54-4.82,0.78-7.59c2.79-2.32,2.9-6.38-0.01-9.32
				c-6.19-6.28-12.63-12.3-18.84-18.57c-3.53-3.57-6.8-7.4-10.49-11.43c1.57-0.81,1.98-1.04,2.39-1.23c7.69-3.54,13.31-0.9,18.5,5.3
				c4.57,5.46,10.47,9.83,14.98,15.33c3.39,4.14,6.41,9.05,7.98,14.12c1.89,6.09,5.27,9.62,11.02,11.71
				c9.94,3.61,13.52,8.84,13.99,19.27c0.06,1.26,0.78,3,1.77,3.62c11.28,7.18,23.21,12.95,36.35,15.85c2.05,0.45,4.37,0.61,6.37,0.12
				c7.41-1.83,13.9-0.61,19.92,4.3c4.01,3.27,8.54,5.91,12.84,8.84c0.29-0.36,0.57-0.72,0.86-1.09
				c-11.46-12.6-22.91-25.19-34.37-37.79c0.47-0.53,0.95-1.06,1.42-1.59c37.1,10.97,69.85,29.71,98.94,55.01
				C905.03,1230.59,904.83,1231.26,904.62,1231.94z"/>
			<path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M222.55,1179.64c-2.57-1.95-5-4.17-7.82-5.65c-0.68-0.36-3.44,1.49-3.84,2.76
				c-0.71,2.3-0.3,4.94-0.48,7.43c-0.16,2.36-1.14,3.71-3.87,3.06c-8.45-2-16.92-3.9-25.67-5.9c-0.93-5.41-2.69-6.69-8.85-5.78
				c-10.98,1.61-11.16,1.89-9.88,12.9c0.29,2.45,0.26,4.94,0.43,8.37c-5.78-0.54-11.16-0.95-16.51-1.58
				c-4.67-0.55-8.91-1.09-12.71-5.25c-3.31-3.63-5.13,1.87-8.46,4.61c-1.42-4.46-3.21-8.44-3.87-12.61
				c-0.64-4.09-1.77-6.98-5.76-9.04c-7.12-3.67-7.13-6.22-0.83-11.53c0.75-0.64,1.62-1.32,2.02-2.17c2.64-5.66,5.13-11.4,7.75-17.07
				c1.58-3.43,0.1-4.62-3.17-4.64c-0.83-0.01-1.66,0.01-2.5,0.04c-8.94,0.24-8.59,0.34-10.92,8.74c-1.76,6.35-4.41,12.48-6.98,18.57
				c-0.4,0.94-2.57,1.73-3.81,1.6c-6.42-0.7-12.8-1.8-19.22-2.58c-1.93-0.23-3.93,0.13-7.27,0.28c1.7-4.29,3.17-8.29,4.88-12.19
				c0.73-1.65,2.07-3.02,2.95-4.61c2.99-5.41,1.24-8.48-4.87-8.86c-1.6-0.1-3.35-0.33-4.74-1.07c-7.45-3.94-18.9-2.13-24.53,4.05
				c-2.15,2.36-13.9,3.27-15.82,0.78c-1.19-1.55-1.66-4.18-1.39-6.18c0.34-2.56,1.52-5.1,2.77-7.42c1.78-3.3,0.28-4.77-2.68-5.22
				c-2.94-0.45-6.01-0.79-8.93-0.43c-1.7,0.21-4.19,1.56-4.66,2.95c-1.85,5.53-5.6,5.03-10.43,4.1c0.75-1.04,1.06-1.86,1.65-2.22
				c9.42-5.87,15.36-14.89,21.75-23.58c1.42-1.94,4.38-3.44,6.84-3.82c13.6-2.07,26.34,2.47,38.99,6.32
				c5.41,1.65,8.89,0.26,11.61-3.62c2.71-3.86,6.1-4.93,10.32-4.23c4.89,0.81,9.72,2.48,14.62,2.71c4.41,0.2,9.01-0.6,13.3-1.78
				c5.34-1.47,9.58-1.35,13.18,3.61c1.58,2.18,4.24,3.73,6.65,5.15c10.84,6.4,21.73,12.71,32.67,18.94c2.99,1.7,6.73,5.62,8.16-1.56
				c3.8-0.04,7.77-0.77,11.35,0.1c2.42,0.59,4.92,3.05,6.3,5.33c7.39,12.21,14.49,24.59,21.57,36.99c2,3.5,3.3,7.25,0.55,11.08
				L222.55,1179.64z"/>
			<path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M737.17,1189.71c-5.87-1.13-10.72-1.59-15.22-3.1c-2.39-0.81-4.48-3.2-6.15-5.31
				c-3.46-4.41-6.63-9.06-9.75-13.72c-0.89-1.32-1.44-2.99-1.73-4.58c-1.48-8.13-5.2-14.99-11.18-20.69
				c-3.97-3.79-7.95-7.6-11.65-11.65c-4.69-5.14-7.29-11.2-8.52-18.2c-0.7-3.97-2.99-8.12-5.71-11.18
				c-7.16-8.06-14.84-15.66-22.54-23.23c-1.7-1.67-4.29-2.43-7.31-4.07c-0.26,6.06,2.39,9.36,4.99,12.67
				c5.35,6.79,10.81,13.5,16.02,20.4c1.54,2.04,2.43,4.58,3.73,7.11c-1.96,0.55-3.42,0.86-4.8,1.37c-7.94,2.9-12.84,2.13-18.09-4.59
				c-6.34-8.1-11.86-16.85-17.81-25.27c-1.13-1.6-2.58-2.96-4.61-3.96c-0.83,13.73,9.91,22.03,14.96,33.45
				c-1.19,0.11-2.41,0.45-3.55,0.29c-6.24-0.88-12.49-1.7-18.65-2.97c-1.61-0.33-3.3-1.94-4.28-3.4c-3.05-4.56-5.39-9.63-8.74-13.92
				c-2.7-3.46-5.99-7.31-9.86-8.8c-6.46-2.48-8.96-7.37-11.5-12.97c0.74-0.79,1.34-1.59,2.09-2.21c9.57-7.97,19.16-15.92,28.75-23.86
				c4.89-4.05,9.77-4.77,15.49-1.1c9.65,6.21,20.3,8.6,31.85,10.05c13.21,1.67,26.02,5.61,36.49,16.19
				c14.12,14.27,30.12,26.68,45.32,39.88c0.38,0.33,0.76,0.71,1.21,0.87c8.59,2.94,13.9,9.61,19.66,16.17
				c10.64,12.12,21.98,23.64,33.19,35.26c12.93,13.41,26.02,26.65,38.6,39.51c-5.46-1.62-11.07-3.81-16.88-4.82
				c-3.57-0.62-7.79,1.69-11.13,0.7c-8.87-2.64-17.42-6.34-26.08-9.65c-0.76-0.29-1.42-0.86-2.14-1.28c-4.6-2.66-8.57-4.75-9.17-11.7
				c-0.65-7.51-5.67-13.76-13.98-15.83c-5.9-1.47-8.48-5.39-10.65-11.12c-2.39-6.32-6.71-12.16-11.13-17.41
				c-4.8-5.7-10.91-10.26-16.02-15.73c-3.74-4.01-7.4-5-12.73-3.37c-8.32,2.55-9.16,3.75-3.1,10.35c6.07,6.6,12.9,12.5,19.34,18.76
				c2.03,1.97,4.47,3.84,5.61,6.28c0.93,1.99,1.14,6.23,0.05,6.93c-4.74,3.01-3,6.33-0.97,9.33c4.29,6.32,8.99,12.36,13.56,18.48
				C733.73,1185.73,735.15,1187.28,737.17,1189.71z"/>
			<path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M936.83,1127.73c-9.89-4.6-18.33-8.83-24.41-16.75c-4.84-6.3-10.01-12.38-15.37-18.26
				c-2.53-2.77-5.63-5.15-8.8-7.19c-12.28-7.9-24.6-15.76-37.11-23.27c-5.48-3.29-11.53-5.63-17.23-8.59
				c-4.62-2.4-9.12-5.04-13.65-7.62c-8.38-4.78-16.89-9.2-26.7-10.21c-0.48-0.05-1.1-0.08-1.47,0.17
				c-6.43,4.28-10.63-0.31-15.19-3.71c-2.93-2.18-5.89-4.37-9.04-6.21c-12.16-7.14-24.27-14.37-36.69-21.03
				c-3.06-1.64-7.27-1.97-10.84-1.64c-7.78,0.71-15.87-3.08-20.45-9.56c-3.92-5.54-8.51-10.62-12.99-15.73
				c-2.09-2.38-4.7-4.11-2.73-8.04c0.49-0.97-0.89-3.38-2.01-4.57c-6.26-6.66-12.71-13.14-19.1-19.69c-1.01-1.03-1.99-2.08-4.04-4.22
				c11.01-1.76,20.85-3.59,30.78-4.73c2.51-0.29,5.36,1.42,7.91,2.54c14.16,6.18,28.22,12.61,42.45,18.62
				c3.75,1.58,8.05,1.8,12.02,2.93c2.63,0.75,5.56,1.49,7.58,3.16c9.86,8.17,19.3,16.85,29.23,24.93
				c5.24,4.27,10.84,8.36,16.86,11.35c18.26,9.07,34.15,21.03,47.68,36.27c6.74,7.59,13.53,15.19,20.98,22.05
				c6.2,5.7,13.54,10.14,20.11,15.46c13.29,10.75,21.65,25.57,31.63,39.04C929.57,1117.71,932.81,1122.23,936.83,1127.73z"/>
			<path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M370.25,320.8c-0.96,1.42-1.02,1.63-1.15,1.68c-8.68,3.18-17.64,5.78-26.01,9.64
				c-17.93,8.29-36.66,3.6-55.05,4.29c-1.07,0.04-2.56-0.11-3.18-0.79c-5.67-6.15-13.23-4.09-20.14-4.81c-0.47-0.05-1.15,0-1.44,0.29
				c-7.12,7.13-14.34,1.55-20.6-0.87c-8.97-3.46-17.51-7.62-27.22-9.07c-6-0.9-11.81-3.36-17.55-5.52c-3.21-1.2-5.04-0.46-6.37,2.47
				c-1.77,3.9-3.6,7.78-5.62,12.14c-8.51-5.65-13.54-0.8-17.95,5.93c-6.47-4.39-12.76-5.56-19.86-1.94
				c-2.34,1.19-5.89,0.51-8.78,0.05c-6.02-0.97-11.05,0.11-15.35,4.84c-5.83,6.41-13.41,8.86-21.97,8.88
				c-4.12,0.01-8.26,0.92-12.36,0.74c-4.9-0.22-10.02-0.41-14.58-1.99c-8.31-2.87-16.39-5.25-25.29-3.03
				c-2.67,0.67-5.64,0.02-8.46,0.22c-6.1,0.44-12.19,1.38-18.29,1.47c-6.87,0.1-13.76-0.51-20.72-0.81c0-11.75,0-22.33,0-33.23
				c1.72-0.09,3.17-0.18,4.61-0.23c14.08-0.53,28.15-1.24,42.23-1.46c5.1-0.08,10.31,0.57,15.29,1.7c5.31,1.21,9.64,0.24,13.05-3.8
				c4.69-5.55,10.72-7.91,17.69-7.47c7.76,0.49,15.46,1.83,23.22,2.42c4.55,0.34,9.15-0.14,13.73-0.1c4.87,0.05,9.74,0.2,14.6,0.47
				c4.57,0.25,9.14,1.15,13.68,0.94c5.45-0.25,9,1.64,10.07,7.15c0.61,3.13,2.1,4.39,5.08,4.02c5.06-0.63,10.27-0.79,15.11-2.18
				c7.33-2.1,14.03-1.6,21.39,0.58c6.29,1.86,13.36,1.16,20.09,1.41c5.95,0.22,11.94,0.61,17.85,0.13c5.04-0.41,9.95-2.22,14.99-2.89
				c3.19-0.42,6.85-0.76,9.72,0.36c10.12,3.96,20.08,4.27,30.61,1.63c4.43-1.11,9.49-0.06,14.21,0.48
				c11.82,1.33,23.6,2.94,35.41,4.42C359.84,319.57,364.73,320.13,370.25,320.8z"/>
			<path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M270,972.3c7.26-5.01,14.2-8.73,19.73-13.94c5.41-5.09,9.38-11.7,14.2-17.91
				c25.8,24.33,50.62,50.49,71.58,79.95c-2.46,3.19-4.37,5.49-6.07,7.93c-2.24,3.23-5.13,3.98-9.05,4.59
				c-3.68,0.57-8.64,3.57-9.94,6.76c-3.1,7.58-8.12,8.86-15.03,7.7c-0.64-0.11-1.32-0.04-1.98-0.03c-0.15,0-0.29,0.15-1.61,0.86
				c3.72,10.04,10.33,18.26,17.67,26.52c-5.53,4.03-10.53,7.12-18.11,4.48c-5.92-2.06-12.67-1.76-19.39-2.55
				c1.7,7.84,12.29,11.27,10.12,20.72c-9.65-8.51-21.51-13.88-28.85-25.1c-4.05-6.19-11.06-10.42-16.54-15.73
				c-1.53-1.48-3.04-3.55-3.42-5.56c-3.34-17.88-4.81-35.86-3.43-54.09C270.55,988.15,270,979.31,270,972.3z M299.84,974.32l0.07,0
				c-0.7-1.12-1.41-2.25-2.18-3.49c-1.16,2.65-1.9,4.73-2.96,6.65c-1.13,2.06-2.56,3.95-3.86,5.92c0.32,0.41,0.65,0.81,0.97,1.22
				c-2.33-0.51-4.65-1.02-7.56-1.66c5.32,8.89,22.59,25.29,25.88,25.48c-2.72-6.1-5.56-12.47-8.4-18.84c0.29-0.45,0.59-0.9,0.88-1.36
				c3.94,1.28,7.88,2.55,13.25,4.29C310.43,985.28,306.68,978.49,299.84,974.32z M274.26,995.85c-3.32,3.11-2.46,5.62,0.36,8.43
				c4.7,4.69,9.25,9.56,13.58,14.6c3.59,4.18,2,13.46-3.05,16.3c8.44,9.12,16.76,18.12,25.08,27.11c0.49-0.4,0.97-0.8,1.46-1.2
				c-3.2-4.69-6.6-9.25-9.53-14.1c-2.54-4.21-6.51-8.33-2.67-13.98c0.47-0.68-0.42-2.44-0.92-3.6c-0.81-1.88-1.81-3.69-2.73-5.52
				c0.31-0.26,0.62-0.53,0.94-0.79c4.15,3.37,8.31,6.75,12.46,10.12C298.51,1019.83,290.13,1004.42,274.26,995.85z"/>
			<path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M184.2,1015.54c-6.4-9.57-12.69-18.93-18.92-28.32c-3.58-5.4-6.9-10.99-10.67-16.26
				c-1.85-2.58-1.99-4.62-0.19-7.14c1.64-2.29,2.97-4.81,4.56-7.14c0.84-1.23,1.77-2.99,2.95-3.28c8.92-2.15,17.34-8.08,27.16-3.88
				c0.92,0.39,1.81,0.83,2.73,1.22c9.95,4.24,9.95,4.24,14.72-5.55c-4.01-5.3-4.01-5.3-9.42-1.53c-3.85,2.69-13.87-0.05-15.26-4.64
				c-1.25-4.11-3.75-4.86-7.44-4.88c-7.23-0.04-13.8,1.48-19.34,6.49c-1.69,1.53-3.81,2.59-6.28,4.22
				c4.27-9.12,4.29-18.18,3.52-27.19c-0.48-5.59-0.1-10.25,4.23-14.57c3.72-3.71,5.86-9.04,9.7-12.57c4.76-4.38,8-8.79,7.1-15.53
				c-0.1-0.78,0.84-1.75,1.39-2.57c0.47-0.68,1.07-1.27,1.63-1.89c9.61-10.52,9.83-11.9,3.45-25.51c-1.99,1.51-3.75,3.13-5.75,4.33
				c-3.79,2.27-7.52,4.93-11.65,6.27c-4.17,1.35-7.29-3.11-5.87-7.36c1.33-3.98,2.38-8.07,3.42-11.65
				c13.3-7.18,26.83-14.48,40.55-21.88c0.36,9.92,0.78,20.47,1.12,31.03c0.66,20.45,0.91,40.91,2.05,61.33
				c0.59,10.58,2.44,21.13,4.24,31.6c2.15,12.5,7.82,22.62,20.82,26.8c2.93,0.94,4.12,2.69,4.19,5.78c0.1,4.65,0.74,9.28,0.86,13.93
				c0.03,1.29-0.71,2.8-1.53,3.87c-1.61,2.11-3.68,3.87-5.29,5.98c-2.62,3.44-5.64,4.84-10.21,4.64c-3.39-0.15-7.13,1.36-10.26,3
				C199.05,1006.63,191.84,1011.09,184.2,1015.54z"/>
			<path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M288.8,935.54c-2.4,10.35-12.82,16.53-8.7,28.76c-5.95,1.57-11.8,3.11-18.3,4.82
				c7.26,4.19,4.98,11.03,5.18,16.66c0.4,11.28-1.05,22.64-0.48,33.91c0.58,11.58,2.91,23.07,4.02,34.64
				c2.14,22.19,3.96,44.4,5.91,66.61c0.19,2.15,0.38,4.29,0.66,7.32c-9.13,3.73-18.45,7.55-28.14,11.51
				c-0.18-0.98-0.74-2.82-0.82-4.68c-1.11-25.43-2.56-50.85-3.08-76.29c-0.39-18.78,0.6-37.59,0.68-56.39
				c0.04-9.81-0.74-19.62-0.73-29.43c0-2.01,1.86-4.05,2.99-6c0.56-0.96,1.93-1.68,2.05-2.63c0.73-5.5,1.19-11.04,0.69-16.93
				c-1.93,2.7-3.85,5.4-5.78,8.1c-0.25-0.27-0.5-0.54-0.75-0.81c0.22-9.99,0.8-19.98,0.57-29.96c-0.41-17.47-1.46-34.92-1.98-52.38
				c-0.58-19.29-0.66-38.6-1.34-57.89c-0.41-11.62-1.64-23.22-2.32-34.84c-0.27-4.64-0.3-9.33-0.02-13.97
				c0.74-12.28,1.56-24.55,2.67-36.8c0.3-3.27,0.6-8.01,5.11-7.87c4.23,0.14,6.95,3.7,7.7,8c0.62,3.59,0.99,7.24,1.14,10.88
				c0.66,16.1,1.16,32.22,1.81,48.32c0.5,12.3,0.93,24.61,1.79,36.88c0.94,13.57,2.37,27.11,3.51,40.68
				c0.57,6.79,1.03,13.59,1.45,20.39c0.73,11.91,1.12,23.86,2.27,35.73c0.24,2.47,2.73,5.33,4.97,6.87
				c3.89,2.68,8.29,4.48,8.38,10.26c0.01,0.46,2.75,1.59,3.63,1.17C285.24,939.37,286.51,937.66,288.8,935.54z M259.56,1126.6
				c0.58-0.06,1.15-0.11,1.73-0.17c0.15-0.58,0.46-1.16,0.44-1.73c-0.44-10.8-0.6-21.61-1.48-32.38c-1.2-14.73-3.76-29.39-4.26-44.13
				c-0.8-23.61-0.37-47.25-0.49-70.88c-0.01-1.17-0.27-2.34-0.42-3.51c-1.06,1.09-1.45,2.15-1.5,3.22
				c-0.73,15.27-1.53,30.54-2.04,45.81c-0.25,7.31-0.22,14.66,0.2,21.96c1.1,19.21,2.24,38.43,3.86,57.61
				C256.27,1110.52,258.2,1118.54,259.56,1126.6z M261.67,983.24c-0.77,48.57,1.49,96.96,9.75,144.97
				C271.37,1079.69,263.46,1031.66,261.67,983.24z M261.38,935.23c0.5,0.02,1,0.04,1.5,0.06c-2.78-33.42-6.24-66.79-8.15-100.26
				c-1.91-33.44-2.27-66.98-3.31-100.47C248.86,801.77,255.68,868.47,261.38,935.23z M249.85,916.59c0.42,0.02,0.83,0.04,1.25,0.07
				c0-0.76,0.04-1.53-0.01-2.29c-0.69-11.12-1.48-22.23-2.07-33.35c-1.19-22.26-1.45-44.6-3.62-66.76
				c-2.4-24.61-2.89-49.11-0.98-73.72c0.28-3.58,0.42-7.17,0.62-10.76c-0.92,0.58-1.17,1.19-1.27,1.82
				c-3.5,21.16-2.89,42.49-1.9,63.74c1.38,29.55,3.73,59.06,5.7,88.58C248.29,894.81,249.08,905.7,249.85,916.59z M253.91,903.5
				c0.44,0,0.88,0,1.31,0c-0.68-16.23-1.04-32.49-2.11-48.7c-1.11-16.73-3.59-33.38-4.24-50.11c-0.87-22.42-0.55-44.88-0.77-67.32
				c-0.01-1.37-0.3-2.73-0.46-4.09C243.08,790.32,253.01,846.73,253.91,903.5z"/>
			<path onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M1114.09,639.6c0-1.06,0-2.12,0-3.54c-1.69,0.28-3.09,0.51-5.12,0.84c0.58-8.54,1.36-16.91,1.68-25.29
				c0.5-13.14,0.53-26.29,1.04-39.43c0.47-12.28,1.22-24.54,2.03-36.8c0.4-6.01,4.18-9.43,8.61-8.27c2.67,0.7,4.58,2.05,4.18,5.19
				c-0.63,4.9,0.62,8.55,5.88,9.18c-1.02,8.21-2,16.06-2.98,23.91c0.41,0.19,0.82,0.37,1.22,0.56c3.74-4.85,7.99-9.34,8.1-16.33
				c0.04-2.76,0.62-5.51,0.98-8.27c0.21-1.63,0.67-3.26,0.68-4.89c0.04-4.06-0.12-8.12-0.16-12.19c-0.04-3.29-0.59-5.68-4.29-7.45
				c-6.44-3.07-10.62-8.11-6.79-16.41c2.5-5.42,4.89-10.9,7.58-16.22c1.89-3.76,3.48-7.24,2.52-11.82c-0.44-2.08,1.61-4.82,2.83-7.09
				c3.68-6.86,3.58-7.04-2.4-11.73c-1.61-1.27-2.56-3.4-3.78-5.16c-1.08-1.55-2.12-3.12-3.58-5.3c9.17-8.48,18.82-17.41,28.76-26.61
				c0.1,1.24,0.32,2.59,0.32,3.94c0.02,57.29,0.07,114.58-0.13,171.87c-0.01,2.99-1.56,6.44-3.43,8.87
				c-5.86,7.62-12.22,14.87-18.19,22.02c1.79,2.15,3.32,4.45,5.29,6.27c3.12,2.86,1.79,5.78,0.38,8.55
				c-4.65,9.14-9.47,18.19-13.97,26.78c-1.12-3.26-2.65-6.26-3.06-9.41c-0.35-2.64-0.17-5.94,1.15-8.11
				c3.18-5.2,3.27-10.73,3.55-16.46c0.33-6.83,1.22-13.64,1.91-20.97c-6.9,0.46-8.59,7.2-12.53,12.1c4.39,2.46,3.03,5.58,0.97,8.37
				c-2.54,3.43-5.46,6.58-8.21,9.86C1114.77,639.96,1114.43,639.78,1114.09,639.6z"/>
			<path onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M809.87,1472.95c-2.46,1.16-4.1,2.37-5.9,2.7c-13.55,2.49-27.08,4.68-40.88,1.06
				c-3.53-0.93-7.8-1.04-11.23,0.06c-9.34,2.97-18.61,2.96-28.05,1.25c-0.16-0.03-0.33-0.12-0.49-0.11
				c-8.93,0.55-16.64-2.63-24.35-6.87c-6.79-3.74-14.33-3.77-22.24-2.98c-10.98,1.1-22.15,0.44-33.24,0.33
				c-6.3-0.06-12.16,0.75-17.88,6.67c11.15-0.68,21.03-1.28,31.62-1.92c-1.67,4.85,0.41,6.34,3.92,6.87
				c4.54,0.68,6.14,3.09,5.93,7.95c-0.14,3.3,1.93,6.7,2.56,10.76c-2.81-3.99-5.63-7.98-7.19-10.19c-5.05-1.14-8.67-1.43-11.84-2.77
				c-10.04-4.25-20.83-2.76-28.48,4.89c-1.85,1.85-2.56,4.85-3.93,7.58c-7.83,0-15.95,0-25.59,0c3.18-4.01,5.72-7.21,8.25-10.41
				c0.39,0.17,0.78,0.33,1.17,0.5c0,1.8,0,3.6,0,6.27c4.8-5.34,11.27-9.39,12.62-14.73c2.25-8.91,10.15-11.26,14.22-18.27
				c-3.71,1.99-7.38,4.06-11.14,5.95c-3.63,1.82-7.22,3.91-11.07,5.1c-11.14,3.45-18.94,10.22-22.27,21.53
				c-0.96,3.26-2.39,4.46-5.87,4.32c-7.26-0.3-14.54-0.09-22.92-0.09c1.92-5.49,3.36-10.2,5.2-14.76c2.72-6.74,6.3-13.19,8.44-20.09
				c3.15-10.13,10.24-14.23,19.97-14.93c4.96-0.36,9.97,0.16,14.94-0.15c3.74-0.24,7.41-1.36,11.14-1.74
				c4.41-0.45,8.95-1.22,13.27-0.63c22.74,3.06,45.08,2.6,66.86-5.62c1.33-0.5,3.11,0.05,4.65,0.31c5.65,0.94,11.31,2.85,16.94,2.77
				c13.1-0.17,25.32,2.21,37.98,6.04c12.43,3.76,24.36,8.42,36.27,13.29C797.34,1465.36,802.9,1469.12,809.87,1472.95z
				M759.66,1457.44c0.15-0.51,0.29-1.02,0.44-1.52c-8.74-4.37-17.51-8.74-27.77-8.27c-1.02,0.05-2.68,1.57-2.82,2.58
				c-0.82,5.58-4.88,7.56-9.45,7.98c-4.71,0.43-9.61,0.11-14.29-0.67c-7.34-1.24-14.54-3.23-21.84-4.7c-1.68-0.34-3.52,0.18-5.29,0.3
				c-0.04,0.49-0.07,0.98-0.11,1.47c11.5,3.38,22.86,7.34,34.53,9.92c7.46,1.65,14.48-1.59,19.92-7c3.05-3.03,6.24-4.07,10.29-3.27
				C748.72,1455.35,754.19,1456.38,759.66,1457.44z"/>
			<path onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M328.53,1477.45c-1.43,2.62-2.61,5.32-6.55,5.03c-0.75-0.06-2.34,2.52-2.43,3.96
				c-0.17,2.75-0.09,4.83-3.84,4.84c-3.29,0.01-5.04-1.02-5.27-4.4c-0.1-1.45-0.47-2.88-0.77-4.6c-4.38,0.5-7.33-0.13-7.16-5.53
				c0.09-2.81-4.21-3.24-7.22-0.57c-3.33,2.97-6.16,5.67-11.52,3.59c-1.87-0.73-5.28,2.52-8.22,4.07c-0.19-0.54-0.62-1.25-0.67-1.98
				c-0.53-7.23-1.28-8.33-8.27-9.4c-1.49-0.23-3.42,1.01-4.85,2c-4.56,3.14-6.92,2.51-8.79-2.83c-5.32,0.54-6.79,10.12-15.37,5.82
				c2.48-1.67,4.1-3.07,5.96-3.95c2.41-1.14,2.8-2.42,1.4-4.66c-2.32-3.7-8.54-4.34-11.44-1.38c-0.92,0.93-3.37,1.56-4.36,1
				c-2.86-1.64-4.25-0.76-5.43,1.86c-0.37,0.81-1.17,2.08-1.73,2.06c-5.57-0.2-7.72,4.33-10.75,7.63c-4.81-5.27-10.59-5.2-15.31,0.32
				c-0.65-1.02-1.74-1.94-1.74-2.86c0-2.94-0.91-5.32-4.08-4.91c-2.64,0.35-5.17,1.8-7.66,2.94c-1.05,0.48-1.89,2.01-2.8,2
				c-0.89-0.01-1.83-1.33-2.61-2.2c-0.41-0.46-0.34-1.58-0.75-1.75c-2.65-1.09-5.51-1.76-8.06-3.04c-3.45-1.74-5.94-1.01-8.2,1.96
				c-1.34,1.76-2.78,3.64-4.6,4.8c-2.2,1.4-3.85,4.74-7.5,2.72c-0.74-0.41-2.72-0.02-3.13,0.63c-2.06,3.28-4.68,6.58-5.42,10.22
				c-1.33,6.49-3.65,8.41-11.44,6.8c1.57-1.46,3.51-2.52,4.16-4.1c0.55-1.35-0.37-3.32,0.17-4.71c0.48-1.63,0.95-3.27,1.46-4.9
				c0.24-0.76,0.5-1.52,0.84-2.24c0.78-1.7,2.01-3.31,2.35-5.08c0.9-4.73,4.35-7.33,9.19-6.31c3.19,0.67,5.32-0.07,6.89-3.22
				c0.59-1.18,2.89-2.31,4.26-2.16c5.93,0.67,6.95,0.01,7.78-5.7c0.37-2.54,1.22-5.01,2.05-8.28c1.11,0,3.47,0,4.76,0
				c2.29,3.52,3.96,6.84,6.35,9.52c2.01,2.25,5.19,0.52,4.33-1.6c-2.78-6.86,3.4-8.93,5.85-12.94c0.93-1.52,2.21-2.82,3.35-4.25
				c5.69,4.52,8.66,4.94,12.64,1.18c1.03-0.98,1.81-2.74,1.83-4.16c0.06-4.06,1.62-5.67,5.68-4.91c2.75,0.51,5.41,1.53,8.17,1.91
				c1.37,0.18,3.49-0.1,4.19-1.01c2.87-3.7,8.06-4.45,11.48-1.25c3.48,3.25,9.58,3.55,13.66,0.66c5.96-4.21,8.56-3.24,12.13,3.25
				c1.22,2.23,3.19,3.7,2.21,6.66c-0.19,0.56,1.35,2.46,1.97,2.4c4.04-0.38,8.06-1.12,13.48-1.96c2.22,1.31,5.69,2.79,6.67-3.59
				c0.08-0.51,2.39-1.37,2.71-1.06c4.91,4.68,6.17-0.62,8.24-3.07c6.47,2.13,8.51,1.2,10.3-4.12c3.75-0.31,7.29-0.62,10.84-0.87
				c0.95-0.07,2.42-0.3,2.8,0.19c4.39,5.75,8.79,2.4,13.2,0.19c0.88-0.44,1.74-0.93,1.97-1.06c5.15,2.37,9.77,4.7,14.55,6.6
				c2.87,1.14,4.35,2.36,3.79,5.84c-0.25,1.53,0.99,4.45,2.21,4.88c4.83,1.69,4.02,5.09,2.9,8.32c-0.9,2.58-0.95,4.28,0.76,6.77
				c2.79,4.06-1.76,6.32-3.66,8.9c-1.26,1.71-3.77,2.66-5.9,3.5c-0.93,0.37-3.12-0.05-3.34-0.63c-1.54-4.11-4.88-4.75-8.06-3.48
				c-2.11,0.84-3.48,3.57-5.86,6.21c-0.69-0.05-2.8-0.21-4.9-0.37c1.12-0.94,2.1-2.41,3.37-2.71c3.11-0.74,3.26-2.58,2.16-4.8
				c-0.69-1.4-1.98-2.51-3.01-3.74c0.36-0.18,0.72-0.36,1.08-0.55c-0.89-1.97-1.54-5.38-2.71-5.57c-1.97-0.32-4.27,1.41-6.43,2.28
				c0.12,0.15,0.24,0.31,0.36,0.46c-1.82,0.19-3.65,0.37-5.16,0.52c-1.4-1.25-2.84-2.49-4.22-3.79c-0.88-0.82-1.95-1.58-2.44-2.61
				c-0.56-1.16-0.58-2.59-0.83-3.9c-1.52,0.81-3.23,1.4-4.49,2.5c-1.18,1.03-1.89,2.61-3.03,4.28c-1.97-1.69-3.71-3.73-5.92-4.9
				c-1.3-0.68-3.57-0.46-4.96,0.24c-1.51,0.76-2.71,2.42-3.66,3.93c-1.3,2.08-2.2,3.94-5.33,2.44c-0.87-0.42-3.15,0.95-4.03,2.08
				c-0.72,0.93-0.95,3.21-0.3,4.05c2.5,3.27,5.45,6.21,8.44,9.51c4.02-2.8,7.98-5.58,12.03-8.4c2.41,2.24,5.93,3.95,6.14,6
				c0.68,6.57,7.83,4.54,10.14,8.56c0.09,0.16,1.74-0.47,2.59-0.89C321.64,1480.92,325.08,1479.17,328.53,1477.45z"/>
			<path onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="M1132.02,1344.73c2.57-1.04,5.18-1.98,7.7-3.14c5.46-2.51,6.74-7.18,3.45-12.29
				c-2.57-3.98-6.38-5.9-10.96-5.78c-4.61,0.12-9.2,0.78-14.05,1.23c0.53-2.33,1.17-3.63,4.46-3.06c2.71,0.47,6.37,0.26,8.44-1.25
				c8.2-6,14.08-13.65,13.7-24.59c-0.19-5.46-3-8.97-8.11-10.7c-4.9-1.67-7.92,0.98-10.67,4.34c-1.13,1.39-2.06,2.95-3.25,4.7
				c-1.7-9.98-3.11-19.71-5.1-29.3c-1-4.82-2.39-9.84-4.92-13.97c-1.76-2.88-5.51-5.55-8.8-6.23c-4.31-0.88-6.91,3.32-8.07,6.88
				c-5.02,15.36-5.86,30.61,3.03,45.11c0.26,0.42,0.53,0.83,0.77,1.27c0.05,0.09-0.05,0.27-0.06,0.31
				c-5.72-3.17-11.42-6.43-17.22-9.52c-7.01-3.73-12.11-0.75-13.02,7.46c-1.4,12.7,6.36,26.92,18.04,32.48
				c4.3,2.05,8.9,3.47,13.46,5.21c-1.55,2.45-3.98,4.86-4.61,7.68c-0.6,2.65,0.69,5.72,1.16,8.68c-0.29,0.02-1.73,0.15-3.8,0.32
				c-1.37-9.16-4.45-17.2-13.44-22.23c-10.3-5.76-17.11-6.56-25.11-0.52c0-5.95,0-11.51,0-17.06c0.01-7.93-0.06-15.87,0.07-23.8
				c0.09-5.62,0.35-11.25,0.69-16.87c0.32-5.46,0.72-10.93,1.33-16.36c0.19-1.65,0.79-4.23,1.88-4.66
				c6.28-2.48,7.97-9.04,12.33-13.17c3.2-3.03,6.25-6.53,0.25-9.82c5.08-6.54,5.4-6.32,11.76-2.15c8.17,5.36,16.6,10.36,25.1,15.19
				c3.39,1.92,7.28,3.12,9.82-1.71c0.22-0.41,0.66-0.85,1.09-0.98c4.29-1.33,8.7-2.34,12.88-3.95c6.73-2.59,11.53,1.23,17.08,3.93
				c9.48,4.61,12.8,11.03,12.38,21.88c-0.97,25.44-0.3,50.93-0.34,76.41c0,1.84-0.42,3.67-0.69,5.88c-5-3.5-8.04-2.62-10.18,1.06
				c-6.82,1.62-12.51,2.97-18.2,4.32C1132.2,1345.57,1132.11,1345.15,1132.02,1344.73z"/>
			<path onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="M550.16,605.5c9.18-7.89,20.63-13.31,24.91-26.37c1.43-4.35,4.61-8.44,7.87-11.8
				c3.38-3.49,4.28-6.9,2.78-11.24c-0.71-2.04-1.46-4.06-2.21-6.09c-1.77-4.78-2.47-9.29,0.31-14.24c1.61-2.86,1.49-6.69,2.28-10.78
				c1.68,2.46,3.49,4.65,4.82,7.11c5.75,10.68,11.79,21.24,16.79,32.27c2.03,4.48,1.81,10.03,2.27,15.13
				c0.19,2.09-0.54,4.26-0.85,6.39c-1.21-1.96-2.71-3.81-3.56-5.92c-0.84-2.08-1.04-4.43-1.89-8.43c-4.27,7.14-6.21,15.64-16.1,12.71
				c0.91-1.88,2.15-4.02,2.97-6.32c0.35-1,0.24-2.77-0.41-3.36c-0.72-0.65-2.5-0.8-3.48-0.38c-4.82,2.09-8.74,9.55-8.64,15.09
				c0.02,1.41-1.33,2.89-2.18,4.26c-0.52,0.84-1.46,1.42-1.93,2.28c-1.3,2.38-2.45,4.84-3.67,7.26c2.48,0.83,4.96,1.67,7.49,2.51
				c-4.4,3.72-9.51,7.45-13.89,11.89c-5.74,5.82-12.02,10.63-18.94,15.05c-6,3.84-10.72,9.79-15.68,15.11
				c-3.29,3.53-6,7.03-11.68,4.28c-2.97-1.44-5.12,0.73-6.08,3.53c-3.13,9.15-11.44,13.99-17.31,20.84
				c-0.75,0.87-3.26,0.16-4.95,0.29c-3.19,0.25-6.92-0.38-9.47,1.05c-8.05,4.53-15.66,9.85-23.45,14.84
				c-1.24,0.79-2.45,1.92-3.8,2.17c-8.26,1.57-16.56,2.93-26.25,4.6c4.87-4.1,8.88-7.64,13.06-10.96c7.95-6.29,16-12.45,23.99-18.69
				c0.77-0.6,1.81-1.28,2.04-2.11c1.26-4.56,2.65-9.14,3.16-13.8c0.12-1.11-3.21-3.85-4.54-3.61c-3.44,0.62-7.21,1.82-9.84,4.01
				c-5.47,4.56-10.38,9.82-15.32,14.98c-1.94,2.02-3.35,4.55-5.23,7.18c-3.84-4.09-3.57-6.95-1.72-9.49
				c1.64-2.24,3.72-4.27,5.94-5.95c9.03-6.81,18.05-13.65,27.36-20.06c3.78-2.6,8.1-4.73,12.48-6.07c17.49-5.37,30.9-16.33,41-31
				c8.83-12.83,21.27-18.86,35.74-22.79c-1.68,7.97-3.34,15.81-5,23.66C549.62,604.88,549.89,605.19,550.16,605.5z"/>
			<path onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="M222.4,1179.51c0.47,1.38,1.04,2.74,1.38,4.16c1.3,5.48,2.62,10.95,3.74,16.46
				c0.65,3.25,0.93,6.57,1.46,10.5c-2.5-2.9-3.94-6.15-6.03-6.64c-3.62-0.84-7.64,0.1-11.49,0.16c-1.56,0.03-3.38,0.16-4.65-0.53
				c-3.17-1.73-6.11-3.32-9.79-1.35c-0.84,0.45-2.28,0.32-3.21-0.07c-3.45-1.45-6.43-1.38-8.93,1.79c-0.44,0.55-1.33,1.03-2.02,1.05
				c-8.29,0.23-16.58,0.29-24.86,0.6c-1.04,0.04-2.17,1.16-3.02,2.02c-1.25,1.26-2.28,2.73-3.87,4.68c-1.36-1.64-2.19-3.58-3.48-3.96
				c-1.91-0.57-5.5-0.8-6.02,0.15c-2.56,4.57-7.33,5.62-11.06,8.29c-2.89,2.07-5.18,5.1-7.35,8c-0.56,0.75-0.11,3.31,0.68,3.84
				c3.85,2.56,3.05,5.47,1.67,9c-1.16,2.96-1.13,5.91,3.31,6.51c1.52,0.21,3.16,0.98,4.35,1.97c3.45,2.87,6.49,5.19,11.55,2.66
				c3.75-1.88,7.91,1.47,9.01,5.85c2.57,10.26,5.27,20.49,8.03,31.14c-5.73,0-10.85,0.16-15.94-0.12c-1.21-0.07-3.23-1.52-3.36-2.54
				c-1.32-10.17-7.85-17.8-12.38-26.4c-3.17-6.01-7.16-11.87-8.85-18.31c-1.75-6.64-3.33-13.61,0.68-20.85
				c4.38-7.89,3.59-8.6-4.3-12.4c-3.89-1.88-5.33-4.6-4.31-8.86c1.76-7.35-0.17-11.03-7.17-13.51c-4.23-1.5-8.38-2.53-10.78-7.17
				c-1.53-2.95-4.48-1.72-6.22,0.41c-3.94,4.81-8.83,9.2-7.78,16.51c0.32,2.23-0.52,4.55-3.64,3.93c-6.06-1.2-12.09-2.54-17.77-3.75
				c3.9-4.8,7.67-9.44,11.44-14.07c-0.08-0.65-0.16-1.29-0.24-1.94c-6.6-1.54-13.19-3.09-20.77-4.86c-0.47-4.36-0.88-9.24-1.65-14.06
				c-0.11-0.69-2.08-1.77-2.91-1.56c-5.62,1.41-11.22,2.58-13.36,9.55c-0.66,2.14-4.29,3.85-6.91,4.78
				c-4.02,1.42-8.54,1.49-12.46,3.08c-3.74,1.52-6.98,4.25-10.73,6.63c0-15.65,0-31.18,0-47.32c1.97,0,3.95,0.31,5.78-0.09
				c1.82-0.39,4.03-1.04,5.12-2.36c4.93-5.99,5.46-6.3,14.74-4.26c-1.1,2.95-2.11,5.85-3.27,8.68c-2.25,5.47,0.86,10.67,6.64,10.88
				c2.99,0.11,6.01,0.19,8.98-0.07c1.56-0.14,3.35-0.68,4.52-1.65c7.19-6.02,14.69-9.31,23.77-3.94c1.06,0.63,2.54,0.65,3.84,0.74
				c4.84,0.34,5.58,1.89,3.26,6.24c-2.34,4.38-4.73,8.78-6.53,13.39c-1.97,5.07-0.43,5.78,4.78,5.85c7.4,0.1,14.85,1.25,22.15,2.63
				c4.04,0.76,5.88-0.42,7.11-4c2.42-7.04,4.85-14.08,7.63-20.99c0.64-1.58,2.51-3.21,4.15-3.7c2.12-0.64,4.58-0.16,7.65-0.16
				c-1.48,2.82-2.81,5.39-4.17,7.95c-0.86,1.61-2.25,3.1-2.53,4.8c-0.62,3.65-2.15,6.24-5.15,8.61c-4.75,3.74-4.55,8.65,0.8,11.72
				c4.85,2.79,6.86,6.15,7.44,11.68c0.53,5.1,2.39,10.3,7.41,13.56c1.67-2.51,3.15-4.72,5.05-7.58c5.14,9.08,13.84,5.98,20.87,7.83
				c2.04,0.54,4.32,0.1,6.45,0.4c5.88,0.83,6.25,0.62,6.06-5.49c-0.1-3.15-0.45-6.3-0.85-9.43c-0.39-3.13,0.27-5.27,3.96-5.33
				c0.98-0.02,1.96-0.32,2.94-0.53c6.38-1.35,7.18-0.66,6.16,6.05c6.02,0.74,12.05,1.34,18.04,2.27c3.02,0.47,6.08,1.2,8.88,2.39
				c5.39,2.28,8.43,0.66,8.76-5.17c0.14-2.58,0.02-5.18,0.02-8.81c3.93,1.86,6.83,3.22,9.72,4.59
				C222.55,1179.64,222.4,1179.51,222.4,1179.51z"/>
			<path onClick={() => props.onFill(43)} fill={props.fillColors[43]} d="M31.29,1249.54c-3.22,0.57-6.48,1.03-9.66,1.76c-1.7,0.39-3.31,1.23-4.95,1.89
				c-1.64,0.66-3.23,1.8-4.9,1.93c-9.45,0.71-9.73,0.37-9.73-9.01c0-6.83,0.22-13.66-0.08-20.48c-0.19-4.24,1.75-6.2,5.51-6.85
				c10.12-1.74,20.28-3.27,30.37-5.17c2.25-0.42,5.36-1.44,6.22-3.13c2-3.93,4.9-5.04,8.8-5.03c6.68,0.02,13.35,0,18.91,0
				c-1.27,1.5-2.62,3.62-4.47,5.11c-1.66,1.33-3.94,2.88-5.83,2.74c-8.25-0.59-14.27,4.96-21.35,7.46c-1.67,0.59-2.37,4.41-3.16,6.86
				c-1.23,3.83-2.45,7.72-3.08,11.68c-0.31,1.92,0.41,4.2,1.26,6.04c0.57,1.22,2.14,2.02,3.35,2.89c6.5,4.67,12.7,9.49,16.81,16.72
				c1.43,2.52,4.82,3.93,7.31,5.84c0.37-0.46,0.74-0.93,1.1-1.39c-8.16-7.65-16.16-15.49-24.55-22.88c-4.65-4.1-5.18-5.53-1.81-10.76
				c0.9-1.41,1.18-3.25,1.62-4.92c0.56-2.12,1.01-4.27,1.68-7.19c8.03,5.37,15.21,10.17,22.4,14.97c0.46-0.58,0.92-1.16,1.38-1.74
				c-6.01-5.46-12.02-10.91-18.77-17.05c5.67-1.3,11.52-2.51,17.29-4.02c1.99-0.52,4.8-1.26,5.51-2.73
				c3.19-6.64,8.72-5.19,13.95-4.64c12.51,1.33,18.69,10.44,23.98,20.3c1.53,2.85,2.35,5.46,6.46,4.26c0.79-0.23,2.58,1.27,3.17,2.37
				c3.89,7.32,7.6,14.75,11.3,22.16c0.44,0.87,1.05,2.37,0.68,2.82c-4.78,5.69,1.68,9,2.71,13.42c0.14,0.62,0.54,1.19,0.98,2.14
				c-1.31,0.45-2.94,1.55-3.55,1.14c-6.11-4.17-12.75,1.27-19.15-1.4c-4.14-1.73-9.14-1.34-13.72-2.12c-1.1-0.19-2.04-1.28-3.62-2.34
				c-2.09,0.73-5.32,1.85-8.56,2.98c-0.48-0.54-0.95-1.08-1.43-1.62c0.83-1.04,1.45-2.58,2.53-3.04c2.79-1.19,3.04-2.64,1.38-4.96
				c-1.72-2.39-3.29-4.89-4.12-8.12c3.1,3.23,5.94,6.78,9.39,9.58c3.03,2.46,6.74,4.07,10.73,5.12c-9.7-8.12-19.4-16.25-29.1-24.37
				c-0.36,0.25-0.72,0.5-1.08,0.75c4.36,6.35,8.73,12.7,12.33,17.94c-1.68,2.63-2.87,4.48-4.05,6.34c-0.49,0.77-0.84,2.14-1.44,2.24
				c-7.87,1.39-13.79,6.27-19.91,10.83c-0.49,0.37-1.22,0.42-2.67,0.88c-2.59-5.4-5-10.93-7.83-16.22
				c-4.12-7.7-7.71-15.91-16.28-20.01C31.58,1249.55,31.29,1249.54,31.29,1249.54z"/>
			<path onClick={() => props.onFill(44)} fill={props.fillColors[44]} d="M883.87,815.79c-2.62-10.26-5.73-20.43-7.73-30.82c-3.01-15.62-5.31-31.37-7.78-47.09
				c-2.22-14.1-3.6-28.36-6.5-42.31c-6.17-29.67-8.05-59.8-10.93-89.84c-0.43-4.45-0.91-8.9-1.47-14.31c1.68,1.55,2.7,2.49,3.93,3.64
				c1.66-1.06,3.28-2.09,5.88-3.76c1.61,3.65,3.73,6.87,4.47,10.39c3.61,17.21,6.48,34.57,10.32,51.72
				c4.37,19.58,9.48,38.99,14.26,58.48c0.19,0.77,0.23,1.57-0.26,2.51c-3.31-10.12-6.62-20.24-9.93-30.36
				c-0.6,0.07-1.21,0.14-1.81,0.22c-0.1,2.29-0.71,4.7-0.23,6.87c5.8,26.41,11.57,52.82,17.8,79.13c1.38,5.83,4.52,11.25,7.34,18.04
				c1.28-2.9,1.97-4.48,2.65-6.01c3.87,7.6,7.55,15.27,11.61,22.72c9.1,16.72,21.93,29.94,37.92,40.23c1.6,1.03,3.18,3.51,3.21,5.34
				c0.09,6.05-0.54,12.11-0.89,18.16c0,0,0.13,0.15,0.13,0.15c-1.89-1.85-3.68-3.8-5.68-5.52c-22.83-19.75-43.63-41.06-54.6-70.07
				c-0.48-1.27-1.33-2.4-2-3.6c-0.38,1.89,0.16,3.35,0.74,4.8c11.33,27.74,29.49,50.77,49.75,72.36c2.08,2.22,3.97,4.62,6.59,7.69
				c-7.19,0.57-11.53-2.54-15.55-6.01c-16.3-14.09-29.83-30.27-38.31-50.4c-2.74-6.49-6.61-12.5-9.96-18.73
				c-1.44-3.68-2.82-7.4-4.35-11.05c-1.45-3.45-3.07-6.83-4.62-10.24c-0.6,1.99-0.35,3.61,0.25,5.08
				c6.06,14.88,12.1,29.77,18.31,44.59c6.94,16.56,18.16,30.01,31.17,42.1c0.91,0.84,2.16,1.32,3.25,1.97l-0.09-0.08
				c4.34,4.45,8.69,8.9,13.03,13.35c-0.29,0.49-0.58,0.99-0.86,1.48c-3.98-1.76-8.31-3.01-11.85-5.42
				c-3.93-2.68-7.45-6.14-10.62-9.73c-14.85-16.8-26.33-35.87-36.62-55.69L883.87,815.79z M871.09,693.89
				c-1.31,18.84,12.73,72.74,19.71,79.35C884.18,746.6,877.63,720.25,871.09,693.89z"/>
			<path onClick={() => props.onFill(45)} fill={props.fillColors[45]} d="M287.15,455.86c-1.08,1.55-1.82,2.61-2.56,3.68c0.36,0.57,0.55,1.26,0.89,1.34
				c4.7,1.14,7.18,4.63,8.77,8.72c3.01,7.72,7.89,13.71,15.57,17.34c-8.22,7.73-12.5,8.34-21.13,3.2c0.53-1.48,1.08-3.01,1.96-5.5
				c-2.29-0.15-4.38-0.76-6.2-0.31c-10.25,2.53-19.72,6.42-27.3,14.36c-7.23,7.57-14.88,14.76-22.69,21.74
				c-8.51,7.62-17.84,13.41-29.8,14.78c-8.67,0.99-17.29,3.47-25.6,6.26c-6.94,2.32-12.57,1.76-17.7-3.5
				c-1.25-1.28-2.77-2.28-4.24-3.47c5.86-4.2,11.53-8.27,17.21-12.33c-0.21-0.51-0.43-1.01-0.64-1.52c-2.38-0.29-4.77-0.58-7.88-0.95
				c0.51-10.1,9.46-13.63,15.82-20.34c-2.5-0.29-4.14-0.48-5.8-0.68c0.23-4.82,0.57-4.8,4.71-6.51c5.45-2.26,11.05-4.5,15.93-7.74
				c7.57-5.02,15.88-7.71,24.6-9.01c9.08-1.35,16.33-5.66,23.59-10.94c5.36-3.89,11.83-7.83,18.13-8.44c6.93-0.67,12.84-2,18.38-6.83
				C283.24,451.52,285.07,453.55,287.15,455.86z"/>
			<path onClick={() => props.onFill(46)} fill={props.fillColors[46]} d="M420.55,1198.66c3.79,0.57,8.36-0.12,11.08,4.69c0.67,1.18,3.15,1.65,4.86,1.83
				c3.61,0.39,5.22,2.22,5.61,5.74c0.73,6.59,1.79,7.41,8.31,6.8c0.19,1.06,0.78,2.27,0.5,3.22c-1.18,3.93-3.5,7.49,0.13,11.72
				c1.39,1.62,0.42,4.58-2.94,5.48c-3.79,1.01-4.11,3.3-0.57,6.14c-3.26,3.97-7.46,7.38-9.37,11.79
				c-3.63,8.41-11.05,9.44-18.21,11.57c-4.13,1.22-8.19,2.67-12.3,3.92c-7.52,2.28-15.05,4.48-21.64,6.43
				c-1.68-4.02-2.72-6.75-3.96-9.4c-0.56-1.19-1.74-2.09-2.22-3.29c-4.38-11.03-14.81-15.5-23.43-21.98
				c-4.73-3.55-9.24-7.42-13.67-11.33c-1.85-1.63-2.56-3.42-0.25-5.86c3.23-3.41,5.81-7.43,8.57-11.05c2.21,1.15,4.11,2.62,6.24,3.1
				c1.9,0.43,4.68-0.23,3.36-3.12c-1.65-3.62,1.61-4.77,2.94-6.44c1.99-2.5,10.67-0.91,12.57,1.78c1.79,2.53,3.07,3.42,5.45,0.05
				c1.76-2.48,5.23-5.65,7.63-5.41c6.56,0.66,9.62-4.03,13.65-7.13c4.01-3.09,5.88-3.24,8.92,0.74c0.4,0.53,0.68,1.22,1.2,1.58
				c1.43,0.99,3.03,2.64,4.43,2.51C418.51,1202.61,419.4,1200.25,420.55,1198.66z"/>
			<path onClick={() => props.onFill(47)} fill={props.fillColors[47]} d="M629.71,903.57c4.4-0.23,9.06-0.28,13.68-0.78c4.86-0.52,9.15,0.53,13.27,3.13
				c2.36,1.49,4.93,2.93,7.6,3.58c8.45,2.05,13.77,7.72,17.87,14.82c3.91,6.78,1.37,11.06-6.28,11.52
				c-3.91,0.23-7.76,1.33-11.65,1.91c-4.74,0.71-9.5,1.72-14.27,1.82c-5.24,0.12-8.38,2.4-8.99,7.37
				c-1.14,9.23-2.06,18.51-2.63,27.79c-0.24,3.89-1.73,5.54-5.43,6.36c-3.32,0.73-6.37,2.64-9.67,3.54
				c-3.29,0.89-6.74,1.6-10.12,1.62c-4.77,0.01-8.74,1.12-12.05,4.76c-8.12,8.93-10.41,9.39-22.7,4.92
				c0.73-7.88,1.46-15.93,2.24-23.98c0.88-9.09,1.64-18.2,2.8-27.26c0.3-2.32,1.45-4.97,3.08-6.6c4.86-4.86,9.36-9.57,11-16.77
				c0.78-3.4,4.3-6.12,6.36-9.29c2.7-4.15,6.47-2.63,10.26-2.68c4.23-0.05,8.5-1.18,12.64-2.25
				C627.79,906.86,628.48,905.09,629.71,903.57z"/>
			<path onClick={() => props.onFill(48)} fill={props.fillColors[48]} d="M163.29,1331.71c3.2-6.99,6-13.12,9.23-20.2c-5.97,4.55-7.94,12.38-14.82,13.84
				c-6.54-11.67-2.93-26.12-13.05-36.25c9-2.4,16.61-1.12,23.36,4.64c1.07,0.92,2.59,1.93,3.87,1.89
				c10.82-0.37,19.47,4.76,27.87,10.64c0.95,0.67,1.94,1.28,2.95,1.86c0.22,0.13,0.58,0.02,1.68-1.18
				c-5.59-3.4-10.89-7.47-16.85-10.02c-5.89-2.53-12.42-3.52-18.68-5.19c0.01-0.62,0.01-1.23,0.02-1.85
				c4.76-0.51,9.73-2.16,14.24-1.32c13.69,2.53,28.34-0.07,40.94,8.33c0.22-0.6,0.43-1.19,0.65-1.79c-3.3-1.6-6.6-3.19-8.39-4.06
				c9.86-0.99,21.01-2.58,32.21-3.06c8.7-0.37,17.46,0.83,26.2,0.93c2.65,0.03,5.28-1.64,7.99-1.91c9.21-0.94,18.66-0.44,26.69,3.97
				c6.57,3.61,11.77,9.7,17.58,14.7c-0.18,0.63-0.35,1.26-0.53,1.89c-5.39,0.73-10.81,1.27-16.14,2.29c-2.52,0.48-4.84,1.94-7.28,2.9
				c-1.31,0.52-2.78,1.49-3.99,1.26c-7.71-1.48-14.74,0.64-21.84,3.26c-3.34,1.24-7.02,1.64-10.58,2.18
				c-7.2,1.1-14.41,2.17-21.65,3.03c-13.96,1.67-27.94,3.18-41.9,4.8c-1.63,0.19-3.23,0.71-4.87,0.87
				c-5.05,0.5-10.12,0.83-15.16,1.34C176.99,1330.11,170.96,1330.85,163.29,1331.71z M158.51,1293.02c-0.32,0.27-0.63,0.54-0.95,0.81
				c2.39,3.6,4.79,7.2,7.18,10.79C166.7,1297.88,160.69,1296.81,158.51,1293.02z"/>
			<path onClick={() => props.onFill(49)} fill={props.fillColors[49]} d="M1.89,470.48c1.19-1.03,2.35-2.11,3.59-3.07c12.08-9.39,24.46-18.42,36.13-28.3
				c5.15-4.36,8.88-10.4,13.15-16.59c-8.65-0.03-10.49-4.64-8.61-12.51c-8.37,3.75-18.45,3.28-20.23,12.58
				c-1.46,7.62-6.69,10.37-11.28,14.62c-4.22,3.91-7.94,8.34-12.21,12.9c0-23.12,0-45.95,0-69.21c1.28,0.68,2.46,1.16,3.48,1.88
				c11.81,8.33,25.5,12.49,38.76,17.61c4.64,1.79,9.32,1.93,14.01-0.32c1.91-0.92,4.17-1.8,6.19-1.64c5.29,0.41,9.01-2.42,13.18-4.9
				c5.18-3.09,11.4-4.64,16.15-8.21c7.63-5.75,16.73-6.76,25.13-10.01c4.01-1.55,7.02-5.67,11.03-9.09
				c-6.4,11.38-12.59,22.4-19.07,33.93c3.09-1.07,5.78-2.01,8.48-2.94c-13.5,11.8-21.97,28.78-40.19,36.08
				c6.33,5.8,11.95-0.28,18.79,2.87c-1.95,1.21-3.78,2.76-5.89,3.55c-6.21,2.35-11.39,7.33-18.91,6.6c-2.52-0.25-5.84,1.58-7.87,3.45
				c-7.78,7.16-15.73,13.81-26.35,16.39c-1.56,0.38-2.96,1.48-4.37,2.36c-10.66,6.66-21.31,13.34-31.96,20.02
				c-0.52-0.4-1.03-0.81-1.55-1.21c1.07-1.74,1.73-4.11,3.28-5.13c13.37-8.87,27.11-17.18,40.26-26.36
				c4.48-3.13,7.83-8.04,11.2-12.52c2.56-3.4,4.34-7.4,6.47-11.13c-0.31-0.43-0.63-0.86-0.94-1.29c-19.57,13.7-39.14,27.4-58.72,41.1
				C2.65,471.47,2.27,470.98,1.89,470.48z M102.91,398.94c-0.17-0.14-0.35-0.27-0.52-0.41c-5.03,3.1-10.15,6.06-15.03,9.39
				c-1.52,1.04-3.58,3.28-3.33,4.57c0.98,5.02-2.54,5.82-5.67,7.01c-5.93,2.26-9.36,6.45-10.21,12.88
				c2.07-11.03,14.24-10.67,19.7-17.85c-2.6-3.8-2.15-4.43,4.89-7.29c-2.96,14.7-17.39,19.73-26.25,29.88
				c6.19-4.04,12.6-7.97,17.56-13.26c5.77-6.15,10.27-13.5,15.33-20.32C100.53,401.98,101.73,400.47,102.91,398.94z"/>
			<path onClick={() => props.onFill(50)} fill={props.fillColors[50]} d="M1142.31,623.26c6.05-7.25,12.11-14.5,18.62-22.3c0.19,1.61,0.44,2.76,0.44,3.91
				c0.02,31.62,0.08,63.24-0.11,94.85c-0.01,2.26-1.34,5.14-3.03,6.67c-7.23,6.57-14.4,13.34-24.08,16.42
				c-1.67,0.53-3.17,1.82-4.56,2.98c-7.77,6.45-15.48,12.98-23.66,19.85c-0.1-1.53-0.25-2.74-0.25-3.95
				c0.09-22.62-0.04-45.25,0.41-67.87c0.2-10.28,1.47-20.55,2.5-30.79c0.18-1.76,1.57-3.4,3.53-4.66c0.38,1.34,0.76,2.69,1.33,4.72
				c5.92-4.28,10.24-9.1,13.04-15.23c0.45-0.99,0.66-2.51,0.22-3.41c-3.23-6.66,3.06-8.43,5.08-11.85
				c-0.18,8.49-0.14,17.1-0.69,25.67c-0.16,2.48-2.15,4.8-3.09,7.27c-1.72,4.5-3.79,9.04,0.68,13.35c0.56,0.54,0.3,2.13,0.15,3.2
				c-0.34,2.42-0.85,4.81-1.29,7.21c0.39,0.22,0.77,0.45,1.16,0.67c1.15-1.4,2.56-2.65,3.42-4.21c5.05-9.16,10.18-18.28,14.84-27.64
				C1149.95,632.13,1147.98,626.86,1142.31,623.26z"/>
			<path onClick={() => props.onFill(51)} fill={props.fillColors[51]} d="M955.86,389.61c0-29.21,0-58.42,0-87.63c0.63-0.01,1.26-0.02,1.89-0.02c0.38,11.93,0.99,23.85,1.11,35.78
				c0.39,38.96,0.5,77.92,0.96,116.88c0.09,7.79,0.74,15.66,2.09,23.33c1.7,9.68,4.62,19.08,11.83,25.91
				c-2.86,1.8-5.43,3.53-8.1,5.06c-1.85,1.06-3.91,2.68-5.76,2.55c-2.02-0.14-5.37-1.78-5.62-3.2c-1.84-10.61-3.53-21.29-4.36-32.02
				c-1.64-21.06-2.7-42.16-3.98-63.24c-0.1-1.59-0.01-3.19-0.97-4.81c-1.47,15.61-2.8,31.25-1.59,46.89
				c1.21,15.62,3.37,31.16,5.27,48.12c-6.18-7.14-7.51-14.62-7.63-22.15c-0.28-17.31,0.18-34.64,0.03-51.95
				c-0.2-21.64-1.11-43.28-0.96-64.91c0.11-15.62,1.46-31.24,2.22-46.86c0.06-1.28-0.1-2.57-0.16-3.86
				c-0.44-0.08-0.89-0.15-1.33-0.23c-0.7,4-1.96,7.99-2,11.99c-0.36,33.29-0.41,66.58-0.72,99.86c-0.12,12.45-0.67,24.9-1.99,37.36
				c-0.63-9.61-1.34-19.21-1.87-28.82c-1.62-29.27-1.41-58.47,0.23-87.79c1.57-28.04,0.96-56.23,0.45-84.34
				c-0.34-18.6-2.58-37.17-3.24-55.77c-0.36-10.09,0.69-20.24,1.31-30.35c0.12-1.99,1.17-3.92,1.24-5.61
				c0.93,18.22,2.08,36.43,2.71,54.67c0.45,12.95,0.02,25.93,0.31,38.9c0.23,10.43,0.91,20.84,1.48,31.26
				c0.06,1.06,0.76,2.08,2.25,3.14c0.39-2.86,0.79-5.72,1.18-8.58c0.13,0,0.26,0.01,0.4,0.01c0.62,6.17,1.24,12.34,1.86,18.52
				c0.49-0.03,0.99-0.06,1.48-0.09c1.55-22.07,0.42-44.18-0.05-66.26c-0.48-22.22-1.53-44.43-1.69-66.74
				c4.85,5.91,7.04,12.91,7.37,20.02c0.54,11.92,0.08,23.89-0.19,35.84c-0.15,6.64-1.12,13.28-1.12,19.92
				c-0.01,30.27-1.98,60.55,1.14,90.81c1.5,14.54,1.46,29.24,2.14,43.87c0.07,1.54,0.32,3.06,0.49,4.6
				C954.58,389.66,955.22,389.64,955.86,389.61z"/>
			<path onClick={() => props.onFill(52)} fill={props.fillColors[52]} d="M404.29,1193.7c-5,4.27-8.81,9.02-15.57,8.77c-1.06-0.04-2.2,1.24-3.26,1.97
				c-3.08,2.14-5.3,1.48-7.39-1.65c-2.8-4.18-6.14-8-8.95-12.18c-1.85-2.74-2.99-5.95-4.76-8.75c-2.73-4.31-5.17-9.01-8.72-12.56
				c-8.51-8.53-17.17-17.01-26.56-24.53c-7.6-6.09-16.26-10.9-24.65-15.95c-1.8-1.08-4.93-1.76-6.6-0.93
				c-5.46,2.75-10.27,1.31-15.23-0.9c-0.73-0.32-1.6-0.33-2.62-0.52c-1.81-22.77-3.62-45.58-5.57-70.12
				c7.11,7.34,13.37,13.83,19.67,20.29c3.92,4.03,7.67,8.26,11.9,11.94c9.51,8.3,19.28,16.3,28.9,24.46
				c1.26,1.07,2.55,2.24,3.39,3.63c9.96,16.48,24.3,28.79,38.39,41.49c5.52,4.98,9.26,11.94,13.75,18.04
				c1.95,2.65,3.64,5.51,5.69,8.08C398.56,1187.39,401.29,1190.28,404.29,1193.7z"/>
			<path onClick={() => props.onFill(53)} fill={props.fillColors[53]} d="M54.71,594.33c11.18,0,21.14,0.36,31.07-0.12c7.17-0.34,13.79,0.85,19.64,3.45
				c-7.36,2.27-14.96,5.11-22.81,6.85c-4.57,1.01-9.54,0.26-14.33,0.29c-1.95,0.01-3.89,0-6.48,0.95c3.49,2.81,6.98,5.62,10.46,8.43
				c-0.39,0.44-0.77,0.87-1.16,1.31c-21.75-9.78-44.24-16.11-68.54-14.08c-0.29-0.54-0.58-1.07-0.86-1.61
				c1.03-1.36,1.8-3.16,3.15-3.97c2.69-1.62,5.7-2.69,8.57-3.99c8.75-3.96,15.67-10.09,20.58-18.27
				c4.75-7.91,11.37-13.89,18.84-18.96c4.6-3.12,7.63-7.01,9.75-12.15c4.83-11.72,11.11-18.07,20.11-21.38
				c2.05,4.13-1.39,6.32-3.12,9.17c-1.62,2.67-2.92,5.53-4.35,8.31c0.44,0.28,0.89,0.55,1.33,0.83c1.59-1.03,3.19-2.07,5.89-3.82
				c-1.58,3.94-2.74,6.83-3.9,9.72c0.24,0.2,0.49,0.4,0.73,0.61c1.87-1.38,3.74-2.76,5.53-4.08c0,4.75,0,9.37,0,14.38
				c1.98-0.87,4.08-1.79,6.9-3.03c-2.45,5.79-10.92,6.02-9.88,13.41c2.5,0.18,4.9,0.34,8.5,0.6c-5.16,4.48-9.36,8.12-13.55,11.75
				c0.19,0.34,0.39,0.69,0.58,1.03c4.02-1.6,7.92-3.93,12.09-4.63c5.66-0.96,8.87-3.55,11.08-8.85c1.95-4.7,5.28-8.81,7.98-13.2
				c1.57-2.55,1.17-4.58-0.62-7.2c-3.77-5.53-6.64-11.66-10.29-17.28c-1.86-2.86-4.61-5.15-7.71-8.51c1.9-1.85,4.2-4.08,6.5-6.32
				c0.58,0.27,1.17,0.55,1.75,0.82c-3.29,4.91-0.43,7.93,2.65,10.72c4.5,4.08,9.01,7.66,10.86,14.27c0.77,2.75,6.54,4.22,10.17,6
				c1.4,0.69,3.16,0.64,4.1,0.81c-1.46,4.41-4.78,9.25-3.69,12.67c0.96,3,6.87,4.42,10.09,6.25c-3.13,1.21-6.82,2.64-10.5,4.07
				c0.07,0.49,0.14,0.98,0.21,1.47c7.34,3.64,14.68,7.29,22.02,10.93c-0.06,0.28-0.13,0.57-0.19,0.85
				c-6.19-0.31-12.39-0.86-18.58-0.85c-3.75,0.01-7.49,0.85-11.23,1.37c-2.63,0.37-5.28,1.37-7.85,1.17
				c-14.09-1.07-27.33,2.7-40.56,6.69C62.35,592.22,59.03,593.11,54.71,594.33z"/>
			<path onClick={() => props.onFill(54)} fill={props.fillColors[54]} d="M440.16,766.75c13.27-5.03,26.54-10.05,39.81-15.08c0.41,0.72,0.82,1.43,1.23,2.15
				c-5.27,4.27-10.54,8.55-15.83,12.84c6.13,4.66,11.51,3.59,38.87-7.75c0,11.34,0.12,22.41-0.18,33.46
				c-0.03,1.12-2.45,2.79-4.01,3.15c-7.58,1.74-15.24,3.12-23.35,4.72c-0.54-0.68-1.36-1.92-2.39-2.94
				c-4.02-3.97-8.97-4.04-12.41,0.73c-3.46,4.79-6.61,7.13-11.89,2.82c-0.66-0.54-2.19-0.94-2.74-0.56
				c-4.76,3.25-8.29,0.44-11.55-2.42c-4.09-3.59-8.21-4.63-13.22-2.01c-0.89,0.47-2.65-0.25-3.79-0.84c-3.28-1.7-6.46-3.61-9.99-5.62
				c1.35-1.13,3.31-2.78,5.26-4.44c5.44-4.64,10.78-9.19,13.02-16.57c0.77-2.54,4.07-4.24,5.97-6.54c2.57-3.1,4.89-6.4,7.65-10.06
				c-8.43-2.96-14.91-1.72-21,3.04c-7.42,5.79-15.04,11.31-22.58,16.95c-0.28-0.34-0.56-0.68-0.84-1.02
				c11.39-10.85,22.78-21.69,34.16-32.54c0.41,0.2,0.82,0.39,1.23,0.59c-0.31,3.5,1.16,4.17,3.63,2.73
				c3.73-2.18,7.32-4.58,11.05-6.76c4.37-2.56,8.82-4.97,13.21-7.47c4.01-2.28,7.88-4.88,12.05-6.81c3.96-1.83,8.23-3,12.37-4.44
				c4.18-1.46,8.43-2.75,12.53-4.39c2.92-1.17,5.65-2.81,8.48-4.24c2.55,7.36,1.6,9.11-5.04,12.55c-4.99,2.58-9.47,6.13-14.42,8.81
				c-15.38,8.32-26.26,22.36-40.7,31.88c-1.99,1.31-3.62,3.17-5.42,4.78C439.62,765.87,439.89,766.31,440.16,766.75z"/>
			<path onClick={() => props.onFill(55)} fill={props.fillColors[55]} d="M349.52,948.75c1.03,1.43,2.06,2.87,3.09,4.3c0.38-0.25,0.76-0.51,1.14-0.76
				c-3.9-11.89-5.34-24.85-16.4-33.48c-7.73-6.03-12.7-13.38-10.4-24.17c0.56-2.62-1.64-5.96-2.9-8.82c-2.4-5.45-1.25-9.9,3.7-14.33
				c2.9,4.08,5.46,8.1,8.45,11.76c3.82,4.69,8,9.09,12.02,13.61c0.1,0.12,0.38,0.13,0.42,0.25c3.69,10.19,15.8,14.01,18.67,25.03
				c1.01,3.88,3.64,7.46,6.01,10.84c1.5,2.13,3.89,3.64,5.88,5.43c0.55-0.34,1.11-0.68,1.66-1.02c-0.52-1.85-0.77-3.83-1.6-5.53
				c-2.47-5.05-5.37-9.9-7.7-15.01c-1.77-3.88-0.54-6.95,3.42-9.1c3.29-1.79,6.28-4.13,10.65-7.06c-2.36,4.03-3.91,7.08-5.82,9.88
				c-3.77,5.54-3.15,7.68,1.14,12.42c6.77,7.48,12.87,15.56,19.28,23.38c5.71,6.97,11.55,13.17,20.95,16c3.6,1.08,5.69,7.88,8,12.31
				c1.51,2.9,2.46,6.19,3.13,9.41c0.54,2.62,0.29,5.4,0.41,8.11c0.12,2.74-0.17,5.59,0.49,8.19c0.92,3.6,2.56,7.01,3.89,10.5
				c-0.51,0.22-1.01,0.43-1.52,0.65c-3.01-6.72-6.72-13.23-8.88-20.21c-3.74-12.07-12.06-21.01-19.66-30.31
				c-5.42-6.62-12.52-11.86-18.82-17.77c-3.34-3.13-6.85-2.93-10.48-0.7c-5.24,3.21-10.46,6.44-15.64,9.75
				c-5.03,3.21-7.76,7.14-6.05,13.75c1.97,7.64,1.82,15.47-1.97,23.83c-4.63-5.02-8.97-9.86-13.46-14.57
				c-10.63-11.16-21.17-22.43-32.11-33.28c-3.35-3.33-5.56-5.74-3.32-10.87c2.09-4.79,2.77-10.19,4.07-15.32
				c0.52-0.38,1.04-0.77,1.56-1.15c2.13,2.6,4.22,5.23,6.4,7.79c3.28,3.84,6.92,6.18,12.55,5.35c4.03-0.6,7.67,1.51,9.74,5.51
				c2.07,3.98,4.15,7.96,6.46,11.8c0.88,1.46,2.42,2.51,3.66,3.75C349.63,948.85,349.52,948.75,349.52,948.75z"/>
			<path onClick={() => props.onFill(56)} fill={props.fillColors[56]} d="M1043.39,765.09c-5.42-0.82-7.8-3.3-8.38-9.03c-2.63-25.97-5.58-51.89-5.42-78.07
				c0.12-20.78,0.59-41.63-0.7-62.34c-1.3-20.84-4.83-41.53-6.8-62.34c-0.35-3.74,2.32-7.72,3.25-11.66c0.6-2.55,0.59-5.25,0.81-7.88
				c0.04-0.47-0.31-1.01-0.19-1.44c2-7.66,4.04-15.3,6.88-23.16c0.55,2.88,2.21,6.1,1.45,8.57c-2.04,6.65-0.28,11.28,5.35,14.86
				c0.54,0.35,0.95,0.93,1.37,1.44c0.19,0.24,0.25,0.58,0.44,1.06c-1.24,1.61-2.53,3.29-3.78,4.92c4.27,5.52,4.76,5.52,12.8-0.28
				c-0.28,3.92-0.66,7.24-0.73,10.56c-0.27,12.8-0.21,25.62-0.7,38.41c-0.68,17.95-2.03,35.87-2.53,53.82
				c-0.35,12.62,0.65,25.28,0.31,37.9c-0.73,27.29-1.93,54.56-2.95,81.83C1043.81,763.18,1043.55,764.12,1043.39,765.09z"/>
			<path onClick={() => props.onFill(57)} fill={props.fillColors[57]} d="M1161.22,1027.56c0,19.41,0,37.35,0,56.6c-2.51-3.18-4.48-5.48-6.24-7.92
				c-4.43-6.14-10.21-9.07-17.91-8.69c-3.34,0.16-6.73-0.83-11.02-1.43c1.09,2.41,1.85,4.5,2.92,6.42c3,5.36,6.03,10.72,9.26,15.95
				c1.59,2.57,2.47,4.65,0.52,7.63c-1.81,2.76-3.73,3.49-6.93,3.39c-7.23-0.23-11.92-4.14-16.18-9.4c-1.34-1.65-3.79-2.5-5.86-3.45
				c-3.12-1.43-6.6-2.23-9.45-4.05c-3.04-1.95-5.53-4.75-8.22-7.22c-5.87-5.4-7.24-6.6-17.24-0.55c5.77,7.33,11.47,14.57,17.18,21.82
				c-5.57,5.36-5.65,6.45-2.42,13.96c3.97,9.23,9.84,15.91,19.09,20.99c9.43,5.19,17.16,13.48,25.64,20.41
				c-0.44,0.68-0.87,1.35-1.31,2.03c-2.14-0.95-4.24-2.02-6.43-2.82c-3.47-1.28-6.94-3.13-10.51-3.41c-7.9-0.62-14.1-4.02-19.93-9.12
				c-2.85-2.5-6.83-3.7-10.26-5.54c-3.16-1.7-6.9-2.88-9.29-5.36c-5.41-5.59-10.24-11.76-15.07-17.88c-0.96-1.21-1.4-3.3-1.19-4.87
				c0.82-6.16,2.31-12.24,2.95-18.42c0.44-4.25-0.47-8.62-0.13-12.89c0.33-4.05,1.49-8.03,2.3-12.1c21.44,7.25,41.39,0.47,61.07-8.23
				c5.46-2.42,11.21-4.53,16.09-7.85c5.26-3.59,9.7-8.4,14.4-12.77C1158.31,1031.6,1159.26,1030.04,1161.22,1027.56z"/>
			<path onClick={() => props.onFill(58)} fill={props.fillColors[58]} d="M341.18,1432.43c-2.4-4.66-5.33-4.06-8.84-1.7c-3.55,2.4-7.3,4.4-10.72-0.59
				c-0.46-0.68-2.42-0.53-3.66-0.43c-2.61,0.21-5.21,0.65-6.34,0.8c-2.63-2.01-4.31-3.04-5.65-4.41c-0.89-0.92-0.98-2.87-1.95-3.4
				c-1.92-1.06-4.08-1.13-6.09-2.8c-2.3-1.91-4.05,0.72-5.66,2.91c-1.27,1.74-3.77,2.57-6.11,4.04c-0.48-0.32-1.57-0.99-2.59-1.74
				c-1.05-0.78-2.04-1.65-3.32-2.7c-0.98,0.81-2.01,1.67-3.58,2.96c-0.45-2.27-0.78-3.94-1.11-5.58c-5.4-0.08-5.92,0.68-4.54,4.82
				c-2.21,1.08-3.93,1.92-5.65,2.77c0.69,0.83,1.38,1.66,2.07,2.49c-1.07-0.94-2.13-1.88-3.62-3.2c-1.12,1.32-2.12,2.51-3.12,3.69
				c-0.07-0.39-0.13-0.78-0.2-1.16c-6.91,1.58-13.81,3.15-21.24,4.85c-8.17-6.53-14.02-6.7-17.63-0.1c-3.39-0.8-6.72-1.75-10.12-2.35
				c-4.49-0.79-7.5,2.24-7.63,6.66c-0.04,1.47-1.54,3.68-2.86,4.14c-1.7,0.58-4.3,0.33-5.75-0.67c-1.2-0.82-1.99-3.37-1.73-4.95
				c0.48-2.88,1.8-5.63,2.91-8.38c2.06-5.06,6.54-7.16,11.18-9.18c1.2-0.52,2.51-1.9,2.83-3.14c1.36-5.14,6.13-8.68,11.34-7.33
				c5.25,1.36,9.5-0.29,14.28-2.23c4.3-1.75,9.43-1.94,14.19-1.94c3.84,0,7,0.39,7.63-4.66c0.08-0.67,2.77-1.82,3.77-1.44
				c5.93,2.24,5.59,1.95,8.49-3.49c1.55-2.91,4.12-5.15,3.85-8.88c-0.02-0.27,1.75-1.07,2.5-0.89c0.71,0.17,1.46,1.25,1.7,2.07
				c1.82,6.37,1.74,6.16,8.29,4.93c1.54-0.29,3.9,1.12,5.15,2.45c6.29,6.72,6.53,6.56,14.35,2c5.38-3.15,11.09-6.62,17.91-3.3
				c0.59,0.29,1.75-0.54,2.62-0.91c3.87-1.68,7.52,0.07,8.98,3.98c0.59,1.58,1.94,2.88,2.9,4.33c0.68,1.02,1.35,2.06,1.94,3.14
				c1.51,2.75,2.95,5.52,4.45,8.28c0.37,0.68,1.27,1.38,1.18,1.96c-0.92,6.03-2,12.04-3.03,18.06
				C342.37,1432.26,341.77,1432.35,341.18,1432.43z"/>
			<path onClick={() => props.onFill(59)} fill={props.fillColors[59]} d="M893.6,455.48c7.6-1.48,10.92,6.36,18.5,5.91c-0.12-1.87,0.3-4.24-0.5-6.08
				c-2.03-4.71-4.38-9.33-7.05-13.71c-1.61-2.63-4.09-4.72-6.14-7.09c-1.72-1.99-3.37-4.04-5.05-6.06c0.48-0.45,0.97-0.9,1.45-1.34
				c4.11,3.7,8.1,7.56,12.39,11.05c3.43,2.79,6.97,5.71,10.93,7.54c7.98,3.68,11.56,13.32,7.32,21.34
				c-3.54,6.69-11.87,15.79-18.88,20.8c-0.2,3.07-0.37,5.79-0.55,8.51c-0.02,0.33-0.2,0.73-0.07,0.97
				c6.74,12.22,3.2,27.27,10.84,39.23c3.09,4.84,2.89,9.54-0.55,14.24c-1.13,1.55-1.6,3.59-2.56,5.85
				c-6.46,0.76-12.44-1.62-18.35-4.16c-2.49-1.07-4.59-2.32-7.11,0.34c-2.51,2.66-0.6,4.5,0.38,6.66c1.08,2.41,2.13,4.83,3.25,7.35
				c-3.09,3.07-5.55,1.09-7.26-0.95c-5.7-6.82-13.42-10.2-21.5-12.75c-4.1-1.29-4.94-4.12-5-7.46c-0.14-7.24-0.88-14.68,0.47-21.67
				c0.89-4.63,4.3-9.35,7.82-12.74c4.82-4.64,10.77-8.02,7.96-16.28c-0.27-0.8,0.94-2.38,1.81-3.25c3.24-3.24,9.52-3.91,13.56-1.49
				c3.69,2.21,7.3,3.09,11.33-0.11c-2.37-5.06-4.76-10.16-7.15-15.25c0.33-0.22,0.66-0.44,0.99-0.65c1.18,0.5,2.34,1.29,3.56,1.42
				c1.78,0.19,4.97,0.33,5.16-0.28c0.6-1.89,0.77-4.52-0.15-6.17c-1.66-3.01-4.28-5.49-6.38-8.27
				C895.91,459.4,894.99,457.69,893.6,455.48z"/>
			<path onClick={() => props.onFill(60)} fill={props.fillColors[60]} d="M1103.11,895.34c0-21.76,0-42.16,0-61.53c3.94,0.3,8.02,0.12,11.83,1.07c2.95,0.73,5.49,3.03,8.34,4.34
				c2.92,1.34,5.92,4.39,8.83-0.48c3.23-5.39,3.19-6.41-1.17-10.65c-2.67-2.6-4.59-6.06-7.45-8.39c-4.95-4.03-10.2-7.79-15.67-11.07
				c-2.89-1.73-4.58-3.23-4.16-6.81c0.55-4.61,0.68-9.27,1.04-14.61c2.04,1.12,3.59,1.96,5.13,2.81c3.43,1.87,6.66,3,10.81,1.24
				c2.92-1.24,6.53-0.82,9.84-1.13c3.09-0.29,4.29-1.61,2.57-4.6c-0.41-0.72-0.83-1.45-1.14-2.22c-1.1-2.76-3.23-5.68-2.91-8.28
				c0.28-2.28,3.3-4.25,5.18-6.29c3.58-3.89,6.97-8.02,12.7-8.98c0.99-0.17,2.03-1.21,2.67-2.11c2.6-3.63,5.13-7.3,7.49-11.08
				c0.65-1.04,0.62-2.51,1.26-5.35c1.39,2.28,2.93,3.63,2.95,5.01c0.19,11.32,1.26,22.81-0.26,33.93
				c-1.44,10.58-5.71,20.77-8.67,31.15c-1.5,5.26-2.38,10.76-4.36,15.83c-8.6,22.02-17.46,43.95-35.36,60.53
				C1109.97,890.15,1106.97,892.26,1103.11,895.34z"/>
			<path onClick={() => props.onFill(61)} fill={props.fillColors[61]} d="M282.84,1192.63c-1.39,1.04-3.47,2.6-6.18,4.63c-0.71-0.41-2.22-1.29-3.86-2.24
				c0.41-1.37,0.83-2.45,1.03-3.57c0.27-1.45,0.36-2.94,0.54-4.41c-2.19,0.26-4.53,0.12-6.53,0.87c-2.22,0.84-4.03,3.31-6.21,3.61
				c-4.75,0.65-6.25,2.59-4.27,6.94c-5.1,2.21-7.03,1.38-7.37-3.31c-0.23-3.25-2.3-2.94-4.16-2.26c-1.95,0.71-3.66,2.07-5.48,3.14
				c0.37,0.5,0.74,1.01,1.12,1.51c-2.19-1.84-4.33-3.73-6.58-5.49c-0.98-0.77-2.1-1.55-3.27-1.83c-3.2-0.77-5.43-4.42-3.98-7.5
				c2.08-4.39,4.57-8.59,7.07-13.22c0.97,0.1,2.24,0.28,3.52,0.37c3.69,0.26,6.17-0.82,6.19-5.15c0.01-3.29,1.25-3.91,4.77-3.02
				c3.88,0.97,8.18,0.24,12.29,0.28c0.48,0,0.95,0.18,1.41,0.27c0.57,6.53,1.93,8.36,6.19,7.8c1.26-0.17,3.04-1.16,3.42-2.2
				c1.22-3.31,1.89-6.83,2.52-9.27c5.66-1.92,10.32-3.32,14.83-5.1c3.76-1.48,5.68,0.38,7.16,3.3c1.15,2.29,2.07,4.69,3.74,8.53
				c1.49-2.98,2.24-4.47,2.88-5.74c2.54-0.37,4.93-0.96,7.31-0.97c1.42,0,3.33,0.49,4.17,1.48c4.25,5,4.15,5.08,9.06,2.41
				c1.13,2.66,1.57,6.29,3.58,7.76c2.08,1.52,5.68,0.96,8.62,1.31c0.51-0.48,1.01-0.96,1.52-1.44c0.55,2.31,0.57,5,1.83,6.82
				c1.01,1.45,3.83,2.91,5.34,2.5c4.84-1.34,5.68,0.04,5.46,4.75c-0.12,2.5,2.22,5.12,3.71,8.25c-0.59,1.53-1.62,4.21-2.76,7.17
				c-0.68-0.34-1.46-0.58-2.03-1.06c-4.55-3.77-5.67-3.85-8.98,1c-1.94,2.85-4.28,3.4-7.23,2.98c-4.78-0.68-4.78-0.72-7.31,4.55
				c-1.7-1.39-3.66-2.36-4.69-3.94c-2.6-3.99-5.1-5.43-8.94,0.68c-1.15-2.93-1.76-4.74-2.57-6.44c-0.5-1.06-1.1-2.42-2.01-2.82
				c-0.79-0.35-2.53,0.15-3.05,0.86c-0.63,0.85-0.88,2.53-0.46,3.49c1.17,2.7,2.77,5.22,4.2,7.8c-4.39,2.61-6.73,1.53-9.76-5.41
				c-1.37,2.85-2.29,4.78-3.28,6.84c-5.93-0.68-6.44-1.38-5.92-7.51c0.09-1.05-0.59-2.41-1.34-3.25
				C286.82,1195.88,285.19,1194.69,282.84,1192.63z"/>
			<path onClick={() => props.onFill(62)} fill={props.fillColors[62]} d="M2.05,354.99c22.22,2.01,43.57-3.08,65,0.89c6.04,1.12,12.56-0.61,18.85-0.54
				c9.97,0.11,19.93,0.91,29.89,0.87c4.72-0.02,9.44-1.23,14.14-2.05c5.97-1.04,11.92-2.21,17.87-3.33c0.11,0.39,0.23,0.78,0.34,1.18
				c-0.75,0.43-1.47,1.16-2.26,1.26c-10.01,1.27-16.83,7.36-22.4,15.09c-2.74,3.81-6.27,5.88-10.74,6.46
				c-6.22,0.82-11.97,2.59-16.82,6.81c-0.92,0.8-2.38,0.98-3.58,1.47c-3.19,1.32-7.14,1.88-9.44,4.13
				c-8.15,7.98-18.97,8.75-28.98,11.63c-2.35,0.68-5.38,0.17-7.79-0.68c-13.88-4.87-27.8-9.67-40.09-18.15
				c-2.93-2.02-4.26-4.13-4.07-7.73C2.26,366.87,2.05,361.4,2.05,354.99z"/>
			<path onClick={() => props.onFill(63)} fill={props.fillColors[63]} d="M706.63,924.32c1.96-2.57,2.97-3.83,3.9-5.14c1.47-2.07,2.45-4.93,4.43-6.11
				c5.59-3.33,11.49-5.26,18.3-2.67c7.58,2.88,11.88,1.21,16.93-5.27c2.97-3.81,7.79-4.19,11.45-0.91c2.37,2.13,1.08,3.71-0.7,5.34
				c-5.08,4.68-5.35,8.22,0.12,12.53c2.96,2.33,6.99,3.53,10.73,4.58c3.15,0.89,6.55,1.04,9.85,1.13c1.66,0.05,3.96-0.23,4.89-1.3
				c3.11-3.54,5.89-2.72,9.51,0.08c-6.21,3.67-9.98,8.79-11.85,15.52c-0.48,1.73-2.89,3.06-4.62,4.29
				c-4.54,3.25-5.74,6.31-2.75,10.98c2.46,3.84,5.75,7.28,9.18,10.32c2.84,2.52,4.88,5.02,5.76,8.85c1.1,4.78,2.96,9.38,4.82,15.04
				c-3.15-2.75-5.65-5.01-8.24-7.17c-9.57-7.98-19.12-16-28.82-23.82c-1.58-1.28-3.83-2.16-5.86-2.38
				c-12.87-1.41-23.93-7.81-35.44-12.86c-2.96-1.3-5.43-4.45-7.36-7.27c-6.13-8.94-14.63-15.34-22.59-22.39
				c-5.07-4.49-10.01-9.4-13.84-14.93c-3.37-4.88-7.39-8.33-12.41-11.1c-2.43-1.34-4.59-3.24-6.69-5.09
				c-0.54-0.48-0.82-1.97-0.48-2.54c0.37-0.6,1.67-0.99,2.49-0.89c5.1,0.62,10.17,1.54,15.28,2.08c3.62,0.38,5.57,2.24,7.66,5.23
				C688.58,900.28,697.34,911.75,706.63,924.32z"/>
			<path onClick={() => props.onFill(64)} fill={props.fillColors[64]} d="M1140.5,1439.56c1.42-2.72,2.85-5.44,4.13-7.89c5.12-0.92,10.71-1.92,16.5-2.97c0,22.99,0,46.01,0,69.4
				c-1.84,0.11-3.45,0.28-5.06,0.28c-31.25,0.02-62.49,0.04-93.74-0.06c-1.96-0.01-3.91-1.03-5.86-1.59
				c0.89-2.18,1.33-4.74,2.76-6.47c3.59-4.36,8.77-6.1,14.01-7.89c6.4-2.18,12.59-5.03,18.77-7.82c1.41-0.63,2.89-1.97,3.49-3.36
				c2.28-5.24,6.48-6.71,11.67-6.74c2.15-0.01,4.32-0.08,6.45-0.36c3.11-0.41,5.24-1.96,5.45-5.41c0.26-4.28,2.08-6.16,6.79-5.4
				c5.68,0.92,7.69-1.29,8.17-7.26c0.08-1.06,1.17-2.17,2.05-2.99c1.38-1.29,2.97-2.35,4.47-3.51
				C1140.55,1439.51,1140.5,1439.56,1140.5,1439.56z"/>
			<path onClick={() => props.onFill(65)} fill={props.fillColors[65]} d="M902.78,475.17c-3.94-1.6-6.9-2.8-9.87-4.01c-1.31,4.3-1.31,4.3,6.23,19.4c-3.31-0.69-5.63-1.04-7.86-1.67
				c-3.42-0.96-6.78-2.12-11.29-3.55c0.2-1.58-0.21-5.02,1.15-7.48c2.81-5.09,1.9-9.89,0.63-14.93c-0.69-2.72-1.25-5.47-1.95-8.54
				c1.99,0.2,3.65,0.36,6.14,0.61c-1.33-4.26-1.66-8.37-3.72-11.27c-4.49-6.33-6.54-13.31-7.79-20.74c-0.31-1.85-1.23-3.6-1.87-5.4
				c-0.48,0.03-0.95,0.05-1.43,0.08c-0.42,4.6-0.83,9.2-1.28,13.8c-0.43,4.33,0.96,9.06-2.78,12.71c-0.37,0.36-0.48,1.37-0.28,1.91
				c2.42,6.4-2.29,11.03-4.06,16.36c-0.27,0.83-1.09,1.71-1.88,2.1c-4.59,2.25-9.27,4.31-13.87,6.53c-3.84,1.85-6.32,0.38-7.1-3.38
				c-1.23-5.99-1.76-12.13-2.94-18.14c-2.4-12.33-5.03-24.62-8.07-36.99c-2.25,10.88,3.27,22.87-4.14,33.16
				c-1.92-3.94-3.89-7.7-5.55-11.59c-0.43-1.01-0.18-2.51,0.19-3.65c1.77-5.51,3.56-11.03,5.59-16.45c1.88-5.04,0.74-8.98-5.13-12.43
				c9.46,0.17,17.48-0.47,24.67,5.09c-6.25,4.53-4.87,10.09-1.74,15.56c4.26,7.44,8.82,14.71,13.94,21.83
				c6.98-13.67,4.93-26.48-3.95-39.9c9.43-0.41,17.73-0.93,26.03-1c1.73-0.01,3.6,1.45,5.19,2.54c6.93,4.79,13.64,9.9,20.7,14.47
				c3.33,2.15,5.42,4.65,7.36,8.19c2.63,4.79,7,8.61,10.59,12.87c1.25,1.48,2.39,3.04,4.31,5.48c-15.4-3.03-22.15-16.15-33.55-22.54
				c-3.41,4.13-1.41,7.44,1.66,9.62c8.1,5.77,10.15,15.37,15.27,23c0.38,0.56,0.15,1.53,0.28,3.34c-3.5-1.95-6.66-3.12-9.06-5.18
				c-3.29-2.83-6.12-2.59-9.41-0.27C892.65,462.71,902.12,466.01,902.78,475.17z"/>
			<path onClick={() => props.onFill(66)} fill={props.fillColors[66]} d="M46.46,1159.18c-3.81,5.46,6.61,10.3,0.12,15.28c7.14,1.04,13.83,2.01,20.32,2.95
				c0.2,6.54-4.1,9.05-7.9,11.99c-0.96,0.74-2.34,2.24-2.11,2.91c0.4,1.2,1.73,2.62,2.94,2.93c5.27,1.35,10.58,2.61,15.96,3.38
				c6.38,0.92,8.06-0.26,7.77-6.79c-0.33-7.46,4.8-11.04,9.37-16.67c2.63,7.05,8.44,8.32,13.86,10.28c4.27,1.54,5.68,4.67,4.86,9.08
				c-1.54,8.24-0.76,9.98,6.49,13.23c3.44,1.54,4.62,3.47,2.85,6.96c-1.27,2.5-2.9,4.97-3.46,7.64c-0.6,2.89-0.14,6-0.14,8.79
				c-1.7-0.23-3.59-0.27-5.33-0.8c-1.18-0.36-2.45-1.19-3.15-2.18c-2.86-4.06-5.24-8.48-8.32-12.35c-3.68-4.63-8.54-7.45-14.38-9.25
				c-12.08-3.72-24.17-5.55-36.69-3.17c-1.57,0.3-4,0.68-4.41,1.69c-3.22,7.84-10.66,6.2-16.56,7.48
				c-8.54,1.85-17.25,2.93-26.44,4.42c0-10.31-0.05-21.19,0.08-32.07c0.01-0.95,0.96-2.1,1.78-2.81c5.39-4.65,11.41-7.8,18.71-8.5
				c5.81-0.56,10.81-2.04,13.25-8.89c1.09-3.05,6.01-4.74,9.19-7.05C45.56,1158.18,46.01,1158.68,46.46,1159.18z"/>
			<path onClick={() => props.onFill(67)} fill={props.fillColors[67]} d="M957.06,845.23c-4.07-2.66-6.92-4.28-9.49-6.25c-23.51-18-38.09-41.64-44.73-70.46
				c-1.74-7.56-4.52-14.89-7-22.26c-0.66-1.96-2.01-3.69-3.58-5.34c0.76,14.33,9.65,26.79,9.67,41.21c-0.55,0.18-1.1,0.36-1.65,0.55
				c-2.4-7.02-5.44-13.89-7.1-21.08c-5.3-22.96-10.09-46.03-15.06-69.07c-0.06-0.28,0.15-0.62,0.35-1.42
				c4.61,14.15,9.09,27.9,13.57,41.65c0.58-0.12,1.16-0.24,1.75-0.35c-0.78-4.85-1.24-9.77-2.39-14.53
				c-3.68-15.16-8.08-30.15-11.33-45.4c-4.43-20.78-7.93-41.75-12.09-62.59c-1.03-5.17-2.58-10.34-4.71-15.15
				c-2.69-6.07-3.67-6.1-9.53-2.79c-3.16-2.86-5.79-5.82-5.9-10.73c-0.11-4.96-1.09-9.9-1.71-14.85c-0.31-2.47,0.28-4.24,3.18-3.16
				c5.42,2.01,10.55,4.01,13.51,9.98c4.77,9.61,11.98,17.93,14.21,28.88c7.59,37.27,15.53,74.47,23.02,111.76
				c2.71,13.5,4.48,27.2,6.77,40.79c0.44,2.61,1.06,5.24,2.04,7.69c5.3,13.24,9.7,26.99,16.48,39.42
				c4.8,8.81,12.68,16.02,19.62,23.53c2.97,3.21,7.85,4.79,10.37,8.21C957.12,835.96,956.44,840.24,957.06,845.23z"/>
			<path onClick={() => props.onFill(68)} fill={props.fillColors[68]} d="M157.88,628.04c-3.3,5.33-6.35,10.82-9.94,15.95c-9.04,12.94-18.61,25.51-27.41,38.61
				c-7.55,11.24-15.28,21.86-26.75,29.8c-8.39,5.8-15.17,13.92-22.75,20.91c-9.83,9.07-20.89,16.27-32.53,22.91
				c-9.43,5.39-17.7,12.81-26.49,19.33c-3.01,2.23-6.02,4.47-9.69,7.19c0-17.11,0-33.18,0-50.3c2.44,1.11,4.56,1.79,6.37,2.96
				c2.09,1.34,3.93,3.07,5.83,4.68c3.33,2.82,5.97,2.72,8.59-1.18c2.06-3.07,4.12-6.38,8.9-6.27c1.39,0.03,3.09-2.28,4.2-3.83
				c2.05-2.87,4.55-4.33,8.06-3.58c7.52,1.6,12.86-1.47,16.78-7.7c0.44-0.7,1.01-1.33,1.61-1.91c5.65-5.41,5.28-8.36,14.1-2.1
				c1.28-1.41,2.6-2.86,3.91-4.31c16.12-17.93,32.22-35.89,48.4-53.78c1.77-1.96,3.68-4.25,6-5.19c10.76-4.34,16.18-13.8,22.43-22.51
				C157.5,627.72,157.88,628.04,157.88,628.04z"/>
			<path onClick={() => props.onFill(69)} fill={props.fillColors[69]} d="M66.31,529.15c-2.81,5.8-5.3,11.21-8.06,16.48c-0.88,1.69-2.24,3.35-3.79,4.44
				c-9.89,6.94-18.57,14.97-25.03,25.34c-6.18,9.93-16.48,13.88-27.39,18.48c0-4.85,0-8.94,0-13.02c0-14.8,0.1-29.61-0.07-44.41
				c-0.04-3.39,0.96-5.82,3.15-8.41c6.04-7.15,12.77-13.31,22.1-17.94c-3.66,4.16-6.62,7.64-9.7,11.01c-2.9,3.17-2.82,5.7,0.99,7.53
				c8.82-8.52,17.5-16.91,26.16-25.28c0.4,0.25,0.87,0.39,0.89,0.58c0.95,7.58,0.95,7.58,8.57,7.7c6.34,0.09,10.04,4.85,7.5,10.48
				C59.18,527.59,63.52,527.72,66.31,529.15z"/>
			<path onClick={() => props.onFill(70)} fill={props.fillColors[70]} d="M382.54,1298.98c-10.08-2.26-16.95,1.63-24.16,4.55c-2.41,0.98-5.51,0.16-8.28,0.36
				c-6.02,0.44-12.02,1.19-18.05,1.42c-1.58,0.06-3.66-0.92-4.74-2.12c-6.13-6.76-11.9-13.85-18.06-20.58
				c-2.07-2.26-4.94-3.8-7.57-5.77c0.84-1.17,1.45-2.73,2.59-3.43c1.51-0.92,3.48-1.69,5.16-1.54c1.18,0.11,2.94,1.72,3.17,2.91
				c0.79,4.04,3.36,5,6.87,4.96c1.5-0.02,3,0.11,4.49-0.02c4.49-0.39,8.54-1.37,11.96,3.79c1.66,2.5,6.87,2.65,10.49,3.85
				c1.08,0.36,3.03,0.45,3.15,0.99c1.67,7.14,9.74,4.76,13.3,9.05c0.46,0.56,2.06,0.17,2.73,0.2c-1.07-4.69-1.87-9.13-3.16-13.41
				c-0.41-1.37-2.09-2.39-3.25-3.51c-3.29-3.18-6.79-6.15-9.87-9.52c-4.82-5.27-9.08-11.12-16.58-13c-1.01-0.25-1.83-1.32-2.72-2.03
				c-2.23-1.8-4.5-3.57-6.65-5.46c-1.1-0.97-2.59-2.05-2.87-3.31c-1-4.49-4.13-4.89-7.85-4.97c-5.84-0.12-5.83-0.23-8.71,5.1
				c-3.18-0.09-4.41-1.41-3.56-4.76c1.04-4.1,1.56-8.32,2.55-12.43c0.37-1.54,1.43-2.91,2.63-3.07c2.1-0.36,5.01,0,6.1-1.24
				c1.45-1.66,1.48-4.56,2.4-7.95c5.54,3.06,10.74,5.54,15.5,8.69c3.84,2.54,6.99,6.09,10.73,8.8c1.98,1.44,4.8,1.71,6.83,3.1
				c4.74,3.26,9.19,6.93,13.84,10.34c3.06,2.24,6.38,4.14,9.33,6.51c1.76,1.41,3.19,3.33,4.47,5.22c1.67,2.47,2.84,5.28,4.58,7.7
				c1.99,2.76,4.09,5.31,2.14,8.95c-0.14,0.26-0.02,0.66-0.01,0.99C381.77,1284.75,382.11,1291.15,382.54,1298.98z"/>
			<path onClick={() => props.onFill(71)} fill={props.fillColors[71]} d="M535.19,1193.7c1.38,5.56,2.77,11.13,4.36,17.52c-1.99-0.88-3.57-1.13-4.42-2.04
				c-5.36-5.7-12.61-6.08-19.58-7.34c-0.9-0.16-1.8-0.38-2.67-0.68c-2.7-0.93-5.37-1.96-8.08-2.87c-3.01-1.01-6.02-2.52-9.09-2.71
				c-2.71-0.17-5.62,0.94-8.21,2.02c-0.8,0.33-1.54,3.24-1.11,3.62c1.67,1.48,4.15,3.73,5.73,3.34c10.22-2.57,17.75,5.31,26.97,6.69
				c3.09,0.46,6.17,5.51,7.93,9.11c2.03,4.15,2.76,8.99,3.68,13.61c0.24,1.18-0.61,2.9-1.48,3.93c-2.06,2.44-4.18,6.21-6.72,6.59
				c-8.99,1.37-18.27,3.78-27.11-1.01c-0.58-0.31-1.32-1.12-1.65-0.96c-6.29,2.88-11.22-0.68-16.75-2.84
				c-4.71-1.84-7.71-4.27-7.65-9.39c0.01-0.49,0.1-1.11-0.14-1.46c-3.34-4.83-2.02-8.97,2.2-12.77c-3.13-2.72-3.8-5.01,0.48-7.08
				c1.2-0.58,1.76-2.55,2.53-3.92c0.7-1.23,1.29-2.52,2.31-4.54c-4.04-1.33-8.48-2.79-14.27-4.69c4.53-3.46,7.78-5.94,11.32-8.64
				c2.02,3.09,3.55,4.11,6.65-0.37c1.97-2.85,5.77-8.72,11.48-2.55c0.86,0.93,5.85-1.1,8.31-2.71c3.63-2.37,5.96-1.21,8.81,1.17
				c1.43,1.2,3.74,1.29,5.56,2.09c3.21,1.43,6.78,2.5,9.41,4.68c3.43,2.85,6.69,5.14,11.34,4.32L535.19,1193.7z"/>
			<path onClick={() => props.onFill(72)} fill={props.fillColors[72]} d="M1040.66,1409.54c-2,3.77-6.52,4.72-8.82,8.98c-1.68,3.1-5.88,7.46-11.07,7.7
				c-1.42,0.07-2.89-0.93-4.35-1.4c-2.1-0.67-4.29-1.99-6.31-1.76c-1.84,0.2-3.9,1.79-5.02,3.37c-0.47,0.66,1.21,2.89,2,4.36
				c0.37,0.68,0.96,1.25,1.37,1.76c-3.58,2.01-6.95,3.89-12.1,6.78c9.46,3.36,5.38,9.81,6.87,15.05c-3.29-2.12-6.34-5.75-8.2,0.89
				c-0.26,0.92-2.62,1.17-3.9,1.91c-1.76,1.01-3.41,2.2-5.23,3.39c-2.33-2.35-8.79-1.07-11.18,3.05c-3.32-1.03-6.74-2.01-10.1-3.18
				c-1.21-0.42-3.23-1.36-3.19-1.94c0.11-1.69,0.62-3.95,1.82-4.85c3.75-2.82,3.25-5.54,1.25-9.17c-2.1-3.82-0.24-9.83-6.49-11.46
				c-0.43-0.11-0.36-2.12-0.4-2.37c2.22-1.07,4.04-1.94,6.57-3.16c-0.5-0.32,0.51,0,0.53,0.38c0.37,5.86,6.1,3.34,8.69,5.65
				c0.78-1.07,2.14-2.11,2.2-3.22c0.07-1.32-0.98-2.69-1.12-3.03c1.72-2.52,3.29-4.38,4.25-6.5c0.31-0.69-0.78-2.84-1.57-3.05
				c-4.11-1.11-2.7-3.18-1.38-5.53c1.03-1.83,1.8-3.81,2.56-5.44c1.41,0.68,3.01,1.46,4.62,2.25c0.77-2.33,1.54-4.67,2.27-6.89
				c-6.63-3.66-6.63-3.66-0.67-10.36c2.83,3.06,5.34,6.65,8.72,8.92c1.03,0.69,4.49-2.28,6.51-3.38c-0.99-4-7.47-1.2-5.4-6.53
				c1-2.56,2.21-5.03,3.64-8.22c0.94,0.79,2.62,1.61,2.55,2.23c-0.5,4.45,3.9,5.1,5.71,7.7c1.34,1.93,6.51,0.57,7.65-1.76
				c2.96-6.05,8.5-6.66,12.97-1.56c0.79,0.91,2.27,1.21,2.28,1.22c1.7,3.7,2.32,7.26,4.44,9.09c2.22,1.92,5.87,2.17,8.9,3.15
				c-0.63,2.31-1.26,4.62-1.89,6.93L1040.66,1409.54z"/>
			<path onClick={() => props.onFill(73)} fill={props.fillColors[73]} d="M786.68,841.81c3.72,0,7.49,0.36,11.16-0.12c2.64-0.35,7.04-1.45,7.31-2.86c0.59-3.09-0.82-6.62-1.68-9.9
				c-0.26-0.99-1.38-1.76-2.91-3.58c2.76-0.79,5.33-2.45,6.94-1.78c2.41,1,5.47,3.36,5.95,5.62c3.02,14.28,5.41,28.7,7.99,43.08
				c0.11,0.61,0.07,1.24,0.13,2.28c-1.76-0.5-3.13-0.93-4.51-1.29c-4.69-1.22-8.73-1.07-11.3,4.1c-2.43,4.88-6.03,6.45-11.83,4.74
				c-7.77-2.28-8.29-1.51-14.02,8.23c-3.32-0.63-6.69-1.27-10.12-1.92c-0.13-0.2-0.5-0.5-0.47-0.74c1.21-8.56-2.88-13.9-9.87-18.36
				c-4.99-3.18-9.15-7.7-13.5-11.81c-5.46-5.16-11.55-8.8-19.17-9.82c-2.11-0.28-4.83-1.5-5.87-3.17c-2.68-4.31-6.88-5.44-11.13-6.82
				c-7.1-2.29-14.27-4.41-21.24-7.03c-2.98-1.12-5.56-3.33-8.68-5.27c2.42-2.07,4.26-3.65,6.62-5.67c-2.51-1.62-4.52-2.91-7.4-4.77
				c6.77-0.4,12.63-0.74,20-1.18c-2.46,2.34-3.89,3.7-5.44,5.17c1.91,3.52,4.04,5.91,8.38,6.16c12.93,0.74,25.84,1.91,38.77,2.75
				c3.08,0.2,4.88,0.93,3.84,4.94c-6.53,0.95-13.96-1.33-20.05,3.66c8.97-0.41,15.15,5.2,21.98,9.45
				c6.33,3.94,12.68,7.97,19.44,11.03c2.72,1.23,7.36,1.16,9.74-0.41c5.02-3.32,9.95-3.14,15.28-2.35c1.8,0.27,3.73,0.97,5.39,0.57
				c1.79-0.42,3.33-1.89,4.98-2.91c-1.29-1.72-2.22-4.15-3.93-5c-3.43-1.7-7.3-2.54-10.98-3.73
				C786.52,842.67,786.6,842.24,786.68,841.81z"/>
			<path onClick={() => props.onFill(74)} fill={props.fillColors[74]} d="M231.81,1232.35c-1.8,0.08-2.99-0.22-3.38,0.21c-3.21,3.45-6.97,6.65-9.19,10.68
				c-2.5,4.52-5.89,5.99-9.36,1.97c-2.95-3.41-5.01-3.49-7.08,0.39c-1.83,3.42-3.77,2.45-4.7-0.21c-1.46-4.17-3.59-2.58-5.48-0.95
				c-2.21,1.9-4.06,4.24-6.17,6.51c-0.95-0.63-1.77-0.94-2.24-1.54c-2.88-3.64-5.19-3.75-8.07,0.32c-2.76,3.92-4.68,3.32-8.1-0.35
				c-0.96-1.03-3.86-0.36-5.87-0.27c-1.1,0.05-2.92,1.05-3.15,0.72c-3.3-4.58-8.7-1.74-12.71-3.72c-0.74-0.37-2.1,0.79-3.14,0.72
				c-2.28-0.16-4.75-0.22-6.72-1.18c-1.09-0.53-1.35-2.79-2.22-4.81c-0.08,0.02-0.93,0.38-1.8,0.46c-1.49,0.15-3.01,0.13-4.51,0.18
				c0.05-1.32-0.02-2.66,0.17-3.96c0.61-4.07,3.09-8.61-3.37-10.47c2.42-7.05,8.93-8.7,13.81-12.21c1.36-0.98,3.04-1.98,3.71-3.38
				c1.23-2.58,2.62-2.74,4.61-1.18c5.57,4.36,5.56,4.37,9.96-1.51c0.2-0.26,0.43-0.49,0.86,0.48c6.39-1.03,12.77-2.13,19.19-3.01
				c1.41-0.2,2.92,0.5,4.4,0.55c1.41,0.05,3.56,0.33,4.13-0.43c2.92-3.91,6.13-3.15,9.68-1.3c0.52,0.27,1.46,0.45,1.83,0.17
				c5.35-4.09,8.36,0.24,11.96,3.48c3.84-3.37,8.17-5.17,13.18-2.85c1.01,0.47,1.85,1.59,2.46,2.6c1.09,1.8,1.93,3.75,2.86,5.8
				c1.49,2.72,3.59,5.29,4.31,8.19C232.39,1225.42,231.81,1228.76,231.81,1232.35z M155.55,1223.15c-2.43,1.04-4.59,1.39-5.82,2.65
				c-1.24,1.27-1.73,3.4-2.23,5.25c-0.4,1.46-0.91,4.06-0.35,4.39c2.05,1.18,4.5,1.98,6.85,2.16c0.83,0.06,1.86-2.04,2.73-3.21
				c1.6-2.15,2.95-5.95,4.76-6.17c3.84-0.46,7.9,0.93,10.07,1.27c2.01,3.81,3.05,6.99,5.18,9.03c0.62,0.6,4.35-2.04,7.34-3.55
				c3.18,4,8.51,4.47,12.62,0.53c3.33-3.19,4.87-8.24,7.39-12.81c1.21,0.98,2.54,2.72,4.25,3.26c1.49,0.47,4.92-0.58,4.91-0.66
				c-0.81-7.06,4.48-4.11,7.58-4.62c-3.42-5.01-5.38-5.56-10.51-3.4c-1.4,0.59-3.32,1.13-4.58,0.63c-5.68-2.25-8.96-1.21-12.25,3.71
				c-1,1.49-3.91,3.43-4.76,2.97c-4.56-2.45-7.46,0.6-10.53,2.64C167.21,1218.38,158.56,1215.88,155.55,1223.15z"/>
			<path onClick={() => props.onFill(75)} fill={props.fillColors[75]} d="M254.36,376.15c-6.87,3.08-9.21,8.48-9.86,14.59c-0.97,9.11-6.34,15.08-13.21,20.17
				c-0.73,0.54-1.68,0.8-3.43,1.6c-0.21-7.24,5.28-11.53,6.45-17.31c1.16-5.76,2.29-11.54,3.53-17.77
				c-4.67,4.28-9.86,8.06-12.19,14.46c-0.39,1.09-0.78,2.23-0.87,3.37c-0.61,8.64-3.83,15.07-14.53,15.74
				c0.31-1.07,0.28-3.12,1.14-3.61c5.07-2.86,6.04-7.03,6.85-12.62c0.74-5.13,4.56-10.16,7.94-14.51c7.38-9.48,7.54-14.21-0.4-23.72
				c-0.85-1.01-1.68-2.08-2.27-3.25c-1.53-3.03-0.34-4.46,2.89-4.25c1.3,0.09,2.59,0.53,3.85,0.93c10.78,3.39,17.35,1.08,23.91-8.08
				c1.99-2.78,4.59-5.11,6.39-7.08c4.68,4.6,8.88,8.28,12.52,12.44c2.53,2.88,4.96,6.18,6.26,9.72c2.03,5.51,4.77,8.04,10.88,9.36
				c3.28,0.71,4.22,3.68,2.73,6.7c-1.9,3.84-4.18,7.49-5.81,10.38c0.63,2.62,1.54,4.63,1.5,6.61c-0.14,5.89-1.17,11.8-0.85,17.65
				c0.25,4.68-3.33,4.95-5.78,6.09c-1.14,0.54-3.49-1.05-5.06-2.03c-1.23-0.77-2-2.23-3.17-3.14c-2.11-1.64-2.93-3.39-1.58-5.97
				c2.64-5.08,0.82-10.08-0.79-14.92c-0.4-1.2-2.38-2.51-3.74-2.64C261.62,384.5,256.01,383.53,254.36,376.15z"/>
			<path onClick={() => props.onFill(76)} fill={props.fillColors[76]} d="M31.29,1249.54c0,0,0.29,0.01,0.29,0.01c5,8.13,10,16.27,15.02,24.4c2.42,3.91,4.88,7.79,7.36,11.76
				c-7.36,5.84-15.71,10.15-17.85,20.94c-1.24-2.66-2.05-4.41-2.87-6.16c-0.2,0.07-0.4,0.13-0.6,0.2
				c1.52,11.34,3.03,22.67,4.55,34.01c-4.24-11.43-7.63-23.06-18.03-32.07c3.57,8.76,6.85,16.47,9.82,24.29
				c1.07,2.82,2.88,5.51,0.17,9.06c-1.22,1.6,0.39,5.39,0.78,8.18c0.18,1.29,0.52,2.55,0.86,4.16c-9.8-8.88-10.1-21.44-13.72-32.56
				c-0.46,0.1-0.91,0.19-1.37,0.29c0.5,3.57,0.91,7.16,1.54,10.71c0.67,3.73,1.56,7.43,2.53,11.95c-1.59-2.01-2.69-3.42-3.8-4.82
				c-0.34,0.14-0.68,0.28-1.03,0.42c1.25,4.42,2.49,8.85,3.92,13.94c-10.89-8.21-9.16-21.26-14.21-31.32
				c-0.74,12.12,2.91,23.27,7.22,34.29c-6.79-7.07-10.71-14.67-10.09-25.07c0.96-16.1,0.25-32.3,0.25-48.45c0-1.81,0-3.61,0-5.75
				c1.25,0,2.03-0.03,2.81,0c7.52,0.31,7.46,0.33,8.98,7.86c0.51,2.54,1.43,5,2.21,7.67c5.54-1.28,10.47-1.63,14.27,3.74
				c0.29-0.47,0.59-0.94,0.88-1.41c-0.28-2.03-0.57-4.06-1.06-7.63c3.11,4.43,5.44,7.75,7.77,11.07c0.33-0.2,0.67-0.41,1-0.61
				c-1.97-3.34-4.12-6.6-5.85-10.06c-1.29-2.58-2.82-5.43-2.84-8.16c-0.03-5.47-2.64-7.85-9.03-8.83c2.67,7.24,5.09,13.82,7.15,19.4
				c-2.16,0-4.24,0.11-6.3-0.03c-2.81-0.19-5.79-0.26-6-4.28c-0.07-1.31-0.58-2.59-0.83-3.89c-0.79-4.18-1.85-7.82-7.55-6.57
				c-1.49,0.33-3.24-0.52-5.23-0.89c0-3.75,0-7.45,0-11.78c3.12,0,6.11,0.33,8.99-0.1c2.3-0.35,4.43-1.69,6.68-2.46
				c1.98-0.68,4.08-1.02,6-1.83C26.57,1252.11,28.9,1250.76,31.29,1249.54z"/>
			<path onClick={() => props.onFill(77)} fill={props.fillColors[77]} d="M1160.7,1019.65c-10.94,5.15-21.66,10.9-32.9,15.26c-9.65,3.74-19.91,5.9-29.91,8.74
				c-2.39,0.68-4.79,1.45-7.23,1.8c-2.86,0.41-5.79,0.4-8.95-0.28c22.33-10.76,47.49-15.54,65.92-33.73
				c-3.54,2.18-7.17,4.23-10.61,6.56c-9.03,6.11-18.61,10.77-29.37,13.13c-5.62,1.23-10.97,3.69-16.44,5.61
				c-1.09,0.38-2.15,0.97-3.28,1.15c-3.55,0.58-7.63,3.07-10.29-1.06c-1.39-2.16,5.25-10.15,8.34-10.97
				c7.8-2.06,15.55-4.27,24.46-6.73c-8.97,5.97-18.27,8.07-27.49,10.39c17.8,0.49,30.49-10.41,44.05-19.48
				c-14.06,5.39-27.71,11.99-43.01,14.04c0.94-5.89,3.85-8.74,9.24-10.75c7.88-2.94,15.44-7.02,22.65-11.41
				c12.05-7.34,23.78-15.22,35.52-23.05c3.25-2.17,6.08-4.97,10-8.23c0,6.25,0,11.49,0,16.74c0,8.99,0.04,17.99-0.04,26.98
				c-0.02,1.79-0.49,3.57-0.76,5.35C1160.6,1019.71,1160.7,1019.65,1160.7,1019.65z"/>
			<path onClick={() => props.onFill(78)} fill={props.fillColors[78]} d="M908.61,490.63c6.68-7.11,12.63-13.46,18.5-19.71c1.15,10.48,2.22,21.3,3.58,32.08
				c0.56,4.43,1.72,8.79,2.66,13.17c2.26,10.48,8.31,18.04,18.06,22.34c3.47,1.53,4.83,3.59,4.32,7.21
				c-0.81,5.74-1.57,11.49-2.29,17.25c-0.33,2.63-0.71,5.27-0.72,7.91c-0.03,7.26-2.37,10.98-9.86,14.7
				c1.69-5.06,3.26-9.8,4.84-14.55c-0.34-0.22-0.69-0.43-1.03-0.65c-0.91,0.96-1.87,1.88-2.71,2.9c-3.51,4.21-7.35,8.21-10.37,12.75
				c-2.2,3.31-3.28,7.36-4.89,11.07c-0.51,1.18-1.15,2.31-1.69,3.49c-2.47,5.34-5.02,10.64-7.37,16.03
				c-1.45,3.33-2.49,6.83-3.89,10.18c-1.53,3.65-3.28,7.21-5.61,12.3c0-2.83-0.4-4.71,0.06-6.35c3.3-11.64,7.24-23.12,9.98-34.88
				c1.33-5.7,0.49-11.9,0.7-17.87c0.11-3.15,0.21-6.31,0.56-9.44c0.31-2.8,1.7-3.86,4.8-3.16c5.89,1.34,8.26-1.38,6.65-7.31
				c-0.34-1.24-1-2.48-1.8-3.5c-2.97-3.79-4.98-7.59-5.45-12.81c-0.34-3.78-3.69-7.64-6.48-10.78c-6.13-6.91-7.03-15.14-7.2-23.72
				c-0.03-1.65,0.47-3.68-0.29-4.9C908.8,499.79,907.71,494.92,908.61,490.63z"/>
			<path onClick={() => props.onFill(79)} fill={props.fillColors[79]} d="M1047.55,462.19c0.41,8.75,0.81,16.03,1.09,23.32c0.62,16.11,1.06,32.22,1.82,48.32
				c0.22,4.61-3.38,5.77-6.05,7.63c-0.94,0.66-3.02-0.33-5.28-0.67c1.97-2.6,3.25-4.28,4.53-5.97c-0.31-0.59-0.44-1.12-0.78-1.41
				c-4.98-4.21-9.81-8.01-7.03-16.15c1.08-3.16-1.2-7.47-1.95-11.26c-0.67-0.09-1.34-0.18-2.01-0.27c-1.02,2.59-2.29,5.11-3.01,7.78
				c-1.51,5.59-2.74,11.25-4.07,16.89c-0.23,0.95-0.89,2.18-0.54,2.83c3.11,5.65-2.2,9.03-3.92,13.64
				c-1.59-5.5-3.26-10.98-4.74-16.51c-2.21-8.25-6.25-14.07-15.48-16.19c-3.98-0.91-7.14-5.42-11.2-8.72
				c8.55-11.26,16.76-21.44,29.94-26.15c1.61-0.58,2.94-2.01,4.34-3.11c5.2-4.06,10.19-8.42,15.65-12.09
				C1040.93,462.71,1044.04,462.9,1047.55,462.19z"/>
			<path onClick={() => props.onFill(80)} fill={props.fillColors[80]} d="M886.56,837.93c2.93,5.89,5.83,10.9,7.98,16.21c5.52,13.64,14.88,24.05,25.75,33.68
				c8.48,7.52,15.77,16.39,23.57,24.68c6.18,6.56,9.6,13.68,8.05,23.34c-1.41,8.8-0.68,17.92-1.36,26.86
				c-0.19,2.45-1.57,5.45-3.42,6.87c-1.05,0.8-4.54-0.57-6.27-1.85c-5.66-4.18-9.89-9.28-12.62-16.2
				c-3.52-8.91-8.69-17.17-13.18-25.7c-8.48-16.12-14.56-33.12-19.07-50.75c-2.51-9.8-5.65-19.45-8.35-29.21
				C886.99,843.56,886.96,841.07,886.56,837.93z"/>
			<path onClick={() => props.onFill(81)} fill={props.fillColors[81]} d="M219.34,1158.97c1.81-2.84,3.61-5.68,5.3-8.32c3.98-0.66,8.47-0.66,12.35-2.2
				c8.69-3.45,17.06-7.69,25.59-11.56c1.17-0.53,2.52-1.14,3.73-1.03c3.19,0.27,5.46-0.58,7.9-2.97c2.69-2.63,6.39-3.71,10.5-2.06
				c4.14,1.66,8.24,2.69,12.86,0.21c1.87-1,5.73-0.04,7.77,1.36c12.14,8.33,24.46,16.51,35.82,25.83
				c6.96,5.71,12.72,13.07,18.21,20.3c2.72,3.58,3.61,8.55,5.59,13.52c-2.19-1.07-4.22-2.7-6.43-3.02c-5.83-0.83-6.39-4.33-6.07-9.2
				c0.06-0.96-2.91-2.57-4.7-3.06c-1.4-0.38-3.14,0.53-4.83,0.89c0.27-6.05-4.11-9.6-9.38-8.57c-2.55,0.5-5.79,0.81-6.1-3.54
				c-0.46-6.58-1.94-7.23-7.95-3.11c-3.9-11-12.08-4.92-19.34-5.36c-4.51-6.96-4.48-6.87-14.11-4.44c-3.56,0.9-7.25,1.25-10.87,1.92
				c-0.76,0.14-1.92,0.41-2.12,0.93c-1.49,3.73-2.78,7.54-4.56,12.52c-0.96-1.29-2.56-2.45-2.59-3.65c-0.1-3.21-0.85-5.22-4.53-4.93
				c-2.88,0.23-3.93-1.34-3.6-3.99c0.6-4.85,1.26-9.69,0.78-14.82c-1.32,5.66-2.64,11.31-4.19,17.96c-1.01,0.12-3.25,1.07-4.95,0.48
				c-4.64-1.62-6.85,0.41-7.43,4.49c-0.51,3.59-1.82,4.31-5.27,3.6c-1.51-0.31-3.93,0.96-5.06,2.27c-1.69,1.97-2.62,4.61-3.49,6.26
				c-2.93-5.62-5.85-11.21-8.77-16.8C219.4,1158.92,219.34,1158.97,219.34,1158.97z"/>
			<path onClick={() => props.onFill(82)} fill={props.fillColors[82]} d="M695.5,820.78c3.58-5.67,7.36-6.27,12.02-6.5c9.89-0.49,19.81-1.54,29.56-3.25
				c5.55-0.97,10.69-1.33,16.14,0.31c14.63,4.4,29.57,6.25,44.83,4.54c4.24-0.48,8.42-1.45,13.17-2.29c0.4,3.4,0.75,6.27,1.23,10.33
				c-5.77-4.87-9.94-3.11-13.08,1.13c1.69,4.34,3.26,8.37,5.27,13.54c-3.76,0.45-6.55,0.91-9.37,1.1c-2.48,0.17-5.04-0.26-7.46,0.15
				c-1.54,0.26-2.91,1.57-4.36,2.4c1.13,1,2.11,2.35,3.43,2.91c2.73,1.17,5.69,1.79,8.45,2.89c1.64,0.65,3.09,1.78,4.63,2.7
				c-0.16,0.51-0.32,1.02-0.48,1.53c-3,0-6.06,0.41-8.97-0.09c-5.44-0.93-10.36-0.81-15.22,2.5c-1.85,1.26-5.26,1.72-7.31,0.91
				c-5.35-2.12-10.5-4.9-15.45-7.88c-6.51-3.92-12.76-8.27-19.48-12.68c3.14,0,6.2,0.3,9.17-0.11c1.72-0.24,4.36-1.32,4.69-2.54
				c0.78-2.88-0.19-5.84-3.88-6.24c-5.77-0.63-11.57-1.07-17.37-1.43c-7.13-0.45-14.28-0.54-21.38-1.21
				C701.45,823.23,698.73,821.82,695.5,820.78z"/>
			<path onClick={() => props.onFill(83)} fill={props.fillColors[83]} d="M126.23,641.6c-3.05,3.62-6.25,7.12-9.09,10.9c-2.5,3.34-4.99,5.44-9.7,5.62
				c-3.42,0.13-7.01,2.6-10.04,4.72c-5.57,3.89-10.7,8.43-16.2,12.43c-3.04,2.21-6.37,2.24-10.32,1.58
				c-4.84-0.81-10.28-0.47-15.01,0.92c-14.32,4.21-28.41,9.18-42.61,13.8c-5.41,1.76-10.47-1.47-11.09-7.34
				c-0.33-3.07-1.37-6.79,3.5-7.95c0.95-0.23,1.63-1.97,2.27-3.11c1.46-2.6,2.71-5.33,4.23-7.89c5.58-9.33,6.61-9.79,17.26-8.22
				c3.28,0.48,6.56,1,9.85,1.48c0.81,0.12,1.97,0.55,2.4,0.18c7.36-6.35,16.8-1.34,24.8-4.57c2.29-0.92,5.46,0.65,8.12,0.27
				c3.83-0.55,7.68-1.5,11.29-2.9c3.06-1.19,5.63-3.66,8.7-4.81c2.84-1.06,6.79-2.5,8.97-1.35c5.13,2.73,8.36,0.16,11.44-2.67
				c3.68-3.38,7.25-3.17,11.24-1.11C126.21,641.59,126.23,641.6,126.23,641.6z"/>
			<path onClick={() => props.onFill(84)} fill={props.fillColors[84]} d="M872.38,427.58c3.56,7.99,7.19,16.13,10.82,24.27c-0.27,0.19-0.54,0.38-0.81,0.57
				c-1.27-1.02-2.53-2.05-3.8-3.07c-0.42,0.1-0.85,0.2-1.27,0.3c0.89,5.41,2.08,10.79,2.55,16.23c0.3,3.46-0.37,7.01-0.78,10.51
				c-0.23,1.96-1.22,3.9-1.17,5.82c0.1,3.61-1.06,6.28-3.73,8.76c-1.17,1.09-1.93,3.66-1.53,5.2c1.37,5.22-0.95,8.88-4.77,11.38
				c-10.18,6.68-12.56,16.21-11.38,27.54c0.51,4.88,0.3,9.85,0.1,14.77c-0.04,1.03-1.31,2.22-2.29,2.97
				c-2.94,2.25-6.01,4.32-9.28,6.64c-1.64-8.05-3.47-16.42-5.01-24.85c-0.66-3.6,0.03-6.75,2.24-10.23c2.81-4.41,4.98-9.47,4.2-15.42
				c-0.36-2.78,1.58-5.97,2.82-8.82c3.56-8.17,7.29-16.27,10.95-24.39c-4.65-3.9-7.58-1.56-10.5,1.67
				c-4.33,4.79-8.8,9.45-13.95,14.95c-1.55-9.28-2.63-16.93-4.18-24.49c-0.73-3.55-2.27-6.94-3.61-10.34
				c-1.96-4.99-2.61-9.72,0.45-14.69c1.49-2.41,1.85-5.52,3.76-8.51c1.2,7.09,2.41,14.18,3.59,21.27c0.68,4.09,0.97,8.29,2.08,12.26
				c1.74,6.22,5.84,7.41,11.39,4.19c3.55-2.05,7.47-3.44,11.13-5.32c1.53-0.78,3.62-1.79,4.06-3.14c1.83-5.7,4.12-11.13,5.19-17.28
				C870.78,440.02,871.51,433.44,872.38,427.58z"/>
			<path onClick={() => props.onFill(85)} fill={props.fillColors[85]} d="M233.7,938.85c-3.48-4.65-6.96-9.29-10.45-13.94c-0.39,0.27-0.78,0.54-1.17,0.81
				c5.02,7.44,10.03,14.88,15.05,22.32c-0.5,0.47-0.99,0.94-1.49,1.41c-1.72-1.03-3.76-1.75-5.11-3.14
				c-7.29-7.52-11.72-16.91-13.18-26.93c-1.63-11.25-1.14-22.82-1.6-34.24c-1.15-28.74-2.39-57.48-3.42-86.23
				c-0.42-11.8-0.39-23.61-0.61-35.42c-0.03-1.8-0.33-3.59-1.01-5.35c0,17.21-0.31,34.43,0.09,51.63c0.4,17.45,1.5,34.89,2.28,52.34
				c0.78,17.36,1.53,34.73,1.53,52.13c-5.45-52.18-11.54-104.32-8.06-157.02c-0.48-0.02-0.97-0.04-1.45-0.06
				c-0.47,4.87-1.25,9.72-1.37,14.6c-0.69,28.14-0.09,56.22,3.18,84.23c1.35,11.55,1.68,23.22,2.54,34.84
				c1.65,22.28,8.36,42.85,20.92,62.06c-7.76-4.24-11.06-11.49-14.14-18.91c-0.6,0.38-1.21,0.76-1.81,1.14
				c3.08,5.78,6.16,11.56,9.24,17.33c-0.43,0.21-0.86,0.42-1.29,0.62c-2.85-5.98-6.3-11.75-8.46-17.97
				c-11.01-31.62-7.54-64.8-9.98-97.34c-0.9-11.94-1.55-23.94-1.49-35.91c0.07-15.13,0.85-30.25,1.61-45.37
				c0.15-2.89,1.37-5.85,2.64-8.53c0.88-1.86,3.13-4.85,3.97-4.6c2.22,0.65,5.05,2.42,5.73,4.4c1.37,4.01,1.91,8.44,2.05,12.71
				c1.18,37.43,1.93,74.87,3.36,112.29c0.69,18.07,0.61,36.36,6.71,53.78c1.43,4.08,3.62,7.89,5.46,11.82
				C233.88,938.53,233.79,938.69,233.7,938.85z M219.61,900.77c0.58-0.03,1.17-0.05,1.75-0.08c-1.72-47.72-3.43-95.43-5.15-143.15
				c-0.69,0.03-1.38,0.06-2.06,0.09C215.97,805.35,217.79,853.06,219.61,900.77z"/>
			<path onClick={() => props.onFill(86)} fill={props.fillColors[86]} d="M745.19,1442.31c-9.65-0.29-19.05-0.54-28.45-0.87c-3.14-0.11-6.7,0.46-9.33-0.81
				c-6.99-3.38-13.51-2.53-20.55-0.24c-19.78,6.44-40.02,5.47-60.27,2.78c-0.44-0.06-0.84-0.43-1.41-0.74
				c2.63-5.59,4.21-12.01,8.11-16.32c3.35-3.71,9.17-5.6,14.25-7.23c7.36-2.37,14.96-4.19,22.59-5.43c3.91-0.64,8.32-0.23,12.14,0.92
				c10.16,3.06,20.21,6.56,30.13,10.33c5.83,2.22,11.47,5.06,16.97,8.04C734.82,1435.68,740,1439.15,745.19,1442.31z"/>
			<path onClick={() => props.onFill(87)} fill={props.fillColors[87]} d="M260.3,1237.46c2.43,0.68,4.39,0.96,6.06,1.8c1.09,0.55,2.41,1.86,2.52,2.93c0.59,5.95,1,6.34,7.15,4.86
				c0.07,1.28,0.14,2.52,0.26,4.7c-2.53-0.53-5.14-1.9-6.89-1.18c-1.48,0.61-1.97,3.64-3.05,5.91c-0.84-0.36-1.82-0.56-2.5-1.12
				c-2.57-2.1-4.27-2.1-5.43,1.47c-0.72,2.23-1.83,4.32-5.02,2.21c-0.8-0.53-2.94,0.59-4.28,1.31c-2.81,1.51-5.49,3.26-8.23,4.91
				c-0.47-0.4-0.94-0.8-1.41-1.19c0.34-1.62,1-3.25,0.93-4.85c-0.06-1.36-0.97-2.69-1.5-4.03c-1.09,0.7-2.4,1.2-3.21,2.14
				c-0.94,1.09-1.45,2.55-2.69,3.82c0.11-4.09,1.17-8.44-3.99-7.64c-1.29-2.88-2.51-5.02-3.19-7.32c-0.44-1.49-0.42-3.31,0.01-4.8
				c0.58-1.97,1.77-3.76,2.54-5.31c1.76-1.17,4.76-2.18,4.85-3.41c0.3-3.99,4.22-8.52-0.21-12.03c-3.72-2.94-1.81-5.86-1.54-8.9
				c3.34,5.43,6.55,5.72,13.05,1.52c1-0.64,3.87-0.42,4.14,0.18c1.7,3.83,5.49,2.95,7.2,1.96c4.1-2.4,7.73,0.33,8.43,2.24
				c1.96,5.29,6.73,5.53,10.41,7.76c2.29,1.39,4.5,2.92,6.74,4.38c-0.46,0.33-0.92,0.66-1.38,0.99c1.15,2.04,2.45,4.01,3.38,6.15
				c0.6,1.39,1.37,3.4,0.8,4.48c-1.67,3.18-0.75,5.22,2.42,5.19c3.88-0.04,5.26,2.05,6.44,5.5c-2.57,2.02-2.63,3.74,0.08,6.75
				c1.48,1.64-0.01,5.97-0.2,9.77c-4.36-1.95-8.01-4.17-10.85,0.45c-1.12-0.76-3.13-1.45-3.19-2.29c-0.18-2.35,0.54-4.83-2.94-5.17
				c-2.98-0.3-4.76,0.65-4.83,4.09c-0.01,0.53-2.44,1.01-3.74,1.51c-2.13-6.32-5.16-2.55-9.11,0.1c-0.82-1.12-1.76-2.4-2.66-3.62
				c2.22-2.63,4.07-4.84,5.95-7.08c5.15,3.99,7.71,1.39,8.43-2.62c2.74-0.58,5.61-0.46,7.34-1.81c1.14-0.88,1.55-4.81,0.65-5.71
				c-1.35-1.34-4.15-1.21-6.4-1.71c-0.83-1.91-1.35-4.84-3.07-6.55c-1.7-1.69-4.63-2.13-7.55-3.35c-7.7,3.47-10.53,2.46-13.47-5.37
				c5.94-0.16,5.94-0.16,6.79-4.07c1.46,1.75,3.38,5.4,4.53,5.18c3.39-0.67,6.48-2.88,9.69-4.48c-0.28-0.56-0.56-1.12-0.84-1.68
				c-2.97,1.54-5.93,3.09-8,4.17c-2.07-2.3-3.63-4.03-5.18-5.76c-1.06,1.37-2.12,2.74-2.65,3.43c-2.25,0.8-4.74,0.95-5.47,2.16
				c-0.67,1.1,0.13,3.68,1.08,5.06C251.09,1238.73,253.58,1242.05,260.3,1237.46z"/>
			<path onClick={() => props.onFill(88)} fill={props.fillColors[88]} d="M1160.96,740.71c-1.7-0.56-3.2-1.07-4.36-1.45c-1.5,3.32-2.88,6.42-4.3,9.5
				c-4.83,10.47-16.42,13.37-23.8,21.07c-2.39,2.49-2.46,4.55-1.68,7c1.15,3.58,2.82,6.99,4.22,10.36
				c-4.92,1.04-9.53,2.29-14.22,2.94c-5.7,0.79-12.04-5.41-11.99-11.21c0.07-7.48,0.3-14.98-0.04-22.44c-0.18-4.03,0.91-7,3.97-9.5
				c8.45-6.92,16.46-14.46,25.36-20.74c8.55-6.03,18-10.79,26.83-15.97C1160.96,719.41,1160.96,729.86,1160.96,740.71z"/>
			<path onClick={() => props.onFill(89)} fill={props.fillColors[89]} d="M984.42,504.63c-2.24-0.24-6.38,0.53-7.88-1.1c-3.24-3.53-5.86-8.05-7.43-12.62
				c-5.41-15.78-6.06-32.34-6.04-48.79c0.09-64.92,0.41-129.85,0.99-194.77c0.18-20.01,0.07-39.95-4.21-59.57
				c-1.36-6.21-4.11-12.12-6.14-17.91c4.59,2.9,8.39,7.59,9.62,13.34c2.86,13.29,5.74,26.67,7.12,40.16
				c3.78,36.89,0.2,73.81-0.39,110.73c-0.64,40.09,0.13,80.22,0.88,120.32c0.17,9.21,2.09,18.55,4.46,27.49
				C977.45,489.63,981.27,496.89,984.42,504.63z"/>
			<path onClick={() => props.onFill(90)} fill={props.fillColors[90]} d="M488.46,931.22c-0.8-21.67-1.43-42.93-2.42-64.17c-0.99-21.21-1.04-42.55-8.41-63.92
				c9.02-1.92,17.49-3.73,26.89-5.74c-0.53,8.53-1.06,16.28-1.48,24.03c-0.39,7.14-0.83,14.27-0.95,21.42
				c-0.46,27.62-0.78,55.25-1.3,82.87c-0.02,1.2-1.3,3.05-2.39,3.42C495.45,930.11,492.28,930.46,488.46,931.22z"/>
			<path onClick={() => props.onFill(91)} fill={props.fillColors[91]} d="M131.92,1485.64c-6,1.27-1.63,4.43-1.41,6.24c-3.61,6.74-9.32,6.64-15.41,6.59
				c-24.46-0.2-48.92-0.08-73.38-0.08c-1.82,0-3.64,0-5.46,0c-0.32-0.44-0.65-0.89-0.97-1.33c2.15-1.94,4.06-4.26,6.5-5.72
				c2.9-1.74,3.58-3.26,1.9-6.39c-0.99-1.84-0.7-4.35-1.05-6.54c-0.33-2.02-0.74-4.02-1.26-6.76c1.14,0.51,2.09,0.76,2.84,1.3
				c1.73,1.26,3.24,2.89,5.09,3.89c0.88,0.48,2.34-0.12,3.54-0.23c-0.24-1.16-0.14-2.6-0.79-3.44c-1.82-2.33-5.15-3.78-4.05-8.08
				c4.71,1.38,8.59,1.26,7.82-6.12c8.39,5.63,18.24,6.82,23.98,15.28c2.49,3.67,8.51,3.31,11.24,0.06
				c5.29,1.71,10.58,3.41,16.76,5.41c0.37-0.66,1.19-2.11,2.24-3.96c3.55,4.07,6.51,4.21,10.87,2.81
				C125.74,1477.01,130.56,1477.97,131.92,1485.64z"/>
			<path onClick={() => props.onFill(92)} fill={props.fillColors[92]} d="M34.58,1028.81c-0.74,5-1,11.39-2.75,17.36c-1.68,5.74-4.88,11.03-6.91,15.45
				c0.69,2.98,1.82,5.23,1.55,7.3c-0.65,4.92-1.84,9.78-3.03,14.6c-1.15,4.64-1.74,9.04,1.31,13.34c0.67,0.94,0.46,3.32-0.29,4.31
				c-6.67,8.76-13.56,17.36-20.38,26c-0.53-0.25-1.07-0.49-1.6-0.74c-0.15-1.16-0.42-2.32-0.42-3.48
				c-0.02-29.77,0.05-59.54-0.11-89.31c-0.02-3.57,1.14-5.5,4.49-5.61c5.73-0.18,11.48-0.06,17.22,0.07
				C27.22,1028.2,30.77,1028.56,34.58,1028.81z"/>
			<path onClick={() => props.onFill(93)} fill={props.fillColors[93]} d="M344.59,1198.16c7.76,4.9,7.74,4.89,11.13-3.14c0.44-1.03,1.27-1.9,2.09-3.11
				c3.9,1.62,8.45,1.44,9.98,7.05c0.59,2.15,3.91,3.55,6.09,6c-8.37-0.67-15.07,0.75-16.48,10.47c-0.02,0.01-0.51,0.26-0.62,0.15
				c-5.88-6.2-8.97,0.05-12.05,3.18c-2.98,3.03-4.87,7.13-7.32,10.89c-4.78-3.08-9.94-7.03-15.65-9.89
				c-5.73-2.87-11.86-5.54-18.1-6.52c-3.15-0.49-7.12,2.63-10.35,4.69c-4.18,2.67-7.99,5.91-11.43,8.51
				c-3.52-1.49-6.79-2.66-9.84-4.27c-1.66-0.88-2.94-2.48-4.37-3.78c-3.42-3.1-6.31-7.11-12.1-4.7c-1.31,0.55-3.97-0.54-5.2-1.72
				c-2.65-2.53-4.65-3.15-7.57-0.31c-1.37,1.34-3.81,1.58-6.69,2.68c-3.05-7.71-5.89-14.88-8.73-22.05c0.32-0.3,0.65-0.6,0.97-0.9
				c2.64,1.55,5.52,2.82,7.89,4.72c6.37,5.12,6.37,5.22,10.36-0.87c1.65,5.8,4.94,8.02,9.79,6.75c4.13-1.08,4.28-3.58,2.75-6.16
				c4.33-2.09,8.45-4.08,12.41-6c-0.22,1.33-1.34,3.74-0.68,5.48c0.64,1.71,2.95,3.59,4.76,3.86c1.94,0.3,4.15-1.18,6.82-2.08
				c0.64,0.4,2.31,1.44,3.99,2.49c-0.32,0.09-0.64,0.19-0.96,0.28c1.59,3.2,2.52,7.12,4.98,9.36c2.03,1.85,6.05,3.52,8.63-0.63
				c8.6,3.51,10.9,2.8,11.58-3.09c2.31-1.04,4.37-1.96,6.68-2.99c1.79,1.67,3.76,4.3,6.39,5.74c3.5,1.92,5.38-0.76,6.56-4.12
				C337.19,1207.15,341.3,1203.22,344.59,1198.16z"/>
			<path onClick={() => props.onFill(94)} fill={props.fillColors[94]} d="M794.26,1454.26c5.05,1.73,8.89,3.19,12.82,4.36c2.85,0.85,5.98,0.94,8.71,2.05
				c12.52,5.08,25.75,4.16,38.79,5.05c5.12,0.35,10.5,0.38,15.24,2.04c12.38,4.35,25.32,3.03,37.91,4.86
				c8.84,1.28,17.52,3.68,26.32,5.31c2.72,0.5,5.68,0.5,8.41,0.04c5.88-1,10.88,0.43,15.19,4.42c5.36,4.96,10.66,9.98,15.98,14.97
				c-0.23,0.43-0.46,0.86-0.69,1.29c-1.68-0.71-3.61-1.09-5-2.17c-4.99-3.88-9.63-8.23-14.75-11.9c-1.67-1.2-4.68-1.72-6.63-1.11
				c-6.88,2.16-13.7,2.75-20.61,0.75c-4.25-1.23-8.25-1.64-12.63-0.21c-2.57,0.84-5.6,0.38-8.43,0.34c-5.8-0.08-7.11,1.21-6.44,6.98
				c0.25,2.12,0.94,4.18,1.6,7c-10.41,0-20.61,0.1-30.79-0.16c-0.96-0.02-2.31-2.2-2.72-3.59c-1.42-4.82-2.68-9.55-7.67-12.18
				c-1.2-0.63-2.35-2.16-2.69-3.49c-1.43-5.66-5.4-7.45-10.65-7.65c-0.81-0.03-1.61-0.41-2.41-0.61c-6.43-1.59-12.84-3.74-19.37-0.31
				c-7.11,3.74-13.17-0.68-19.18-3.1c-6.36-2.56-12.21-6.41-17.98-9.53C789.09,1456.58,792.26,1455.16,794.26,1454.26z"/>
			<path onClick={() => props.onFill(95)} fill={props.fillColors[95]} d="M359.43,958.63c3.56,4.94,7,9.98,10.72,14.79c3.54,4.57,6.77,9.67,11.2,13.19
				c9.89,7.86,17.33,17.56,22.79,28.66c5.48,11.12,9.96,22.74,14.85,34.15c6.25,14.57,12.47,29.14,18.37,44.09
				c-1.86-2.87-4.05-5.58-5.53-8.64c-5.87-12.14-14.4-22.06-23.8-31.66c-8.56-8.74-15.32-19.24-23.22-28.66
				c-7.89-9.4-16.1-18.55-24.43-27.56c-3.32-3.58-3.59-7.06-2.87-11.72c0.96-6.28,0.57-12.78,0.63-19.18
				c0.02-2.23-0.34-4.47-0.53-6.71C358.21,959.13,358.82,958.88,359.43,958.63z"/>
			<path onClick={() => props.onFill(96)} fill={props.fillColors[96]} d="M968.53,1427c-3.89-1.46-5.73-2.78-5.54-6.95c1.44-31.56,2.9-63.14,3.59-94.72
				c0.34-15.41-1.17-30.86-1.46-46.3c-0.29-15.1,1.26-30.38-0.55-45.28c-1.38-11.43-1.4-22.51-1.23-33.92
				c0.29-19.75-1.84-39.52-2.33-59.29c-0.19-7.91,0.18-16.18,2.36-23.69c1.67-5.74,2.77-11.22,2.44-17.03
				c-0.33-5.8-1.1-11.57-1.64-17.36c-0.16-1.72-0.2-3.45-0.29-5.18c0.5-0.16,1-0.33,1.51-0.49c0.98,2.06,2.44,4.02,2.85,6.18
				c3.02,16.01,4.51,32.11,3.98,48.46c-1.21,37.09-2.53,74.2-2.72,111.3c-0.14,27.44,1.75,54.89,2.1,82.34
				c0.15,12.26-1.48,24.54-1.93,36.82c-0.58,15.63-0.82,31.27-1.13,46.91C968.43,1414.71,968.53,1420.61,968.53,1427z"/>
			<path onClick={() => props.onFill(97)} fill={props.fillColors[97]} d="M635.19,820.38c-3.91,3.09-7.38,5.82-11.75,9.27c6.02,2.9,10.47,4.95,14.84,7.17
				c4.4,2.23,8.13,4.72,5.07,10.78c-0.57,1.14,0.72,3.63,1.75,5.08c2.78,3.92,5.89,7.61,8.43,11.95c-4.28,0.04-8.03-0.98-10.68,3.19
				c-0.55,0.87-3.47,0.76-5.08,0.32c-3.51-0.95-6.8-2.7-10.32-3.59c-7.27-1.83-11.18-5.56-10.91-13.76c0.09-2.69-3.47-5.47-5.27-8.27
				c-1.05-1.64-3-3.62-2.69-5.05c1.06-4.93-2.24-5.62-5.03-4.78c-12.71,3.79-21.6-2.64-30.36-10.25c-6.23-5.41-12.8-10.42-19.9-16.16
				c26.56,3.56,51.53,10.83,77.51,11.21C631.86,817.48,632.91,818.82,635.19,820.38z"/>
			<path onClick={() => props.onFill(98)} fill={props.fillColors[98]} d="M281.78,938.1c0.29-1.58,0.58-3.15,0.94-5.11c-1.23-0.3-2.37-0.59-3.5-0.86c-0.07-0.38-0.27-0.76-0.17-1
				c2.78-6.18,5.32-12.48,8.47-18.47c7.21-13.73,12.11-28.21,12.88-43.67c1.51-30.41,2.46-60.85,3.42-91.28
				c0.18-5.79-0.97-11.61-1.06-17.43c-0.2-13.45-0.12-26.9-0.14-40.35c0-0.66-0.07-1.47,0.26-1.96c1.53-2.3,3.18-4.52,4.79-6.77
				c1.74,1.96,4.28,3.63,5.1,5.92c4.2,11.75,4.03,24.03,3.12,36.39c-0.21-0.86-0.41-1.71-0.64-2.66c-0.19,0.2-0.49,0.38-0.49,0.54
				c0.25,6.79,0.89,13.59,0.72,20.38c-0.5,20.12-0.91,40.26-2.14,60.35c-0.96,15.57-3.92,31.04-4.49,46.61
				c-0.56,15.3-3.71,29.99-7.48,44.61c-2.68,10.38-7.33,19.95-15.22,27.58c-1.12,1.08-2.37,2.03-4.27,2.41
				c0.51-0.93,0.99-1.88,1.53-2.79c1.78-3,3.08-6.51,5.51-8.85c3.46-3.33-0.82-8.49,4.46-12.04c3.71-2.49,4.79-9.19,6.58-14.17
				c1.73-4.83,3.27-9.81,4.15-14.85c1.7-9.64,3.2-19.35,4.18-29.08c1.41-13.89,2.88-27.81,3.24-41.75
				c0.54-21.27,0.27-42.57,0.11-63.85c-0.09-11.47-0.64-22.94-1.09-34.4c-0.07-1.79-0.71-3.55-1.78-5.27
				c0.37,5.24,1.05,10.48,1.05,15.72c0.04,33.25,2.73,66.52-1.04,99.75c-1.78,15.68-2.32,31.52-4.4,47.15
				c-1.29,9.64-4.27,19.07-6.88,28.49c-0.5,1.81-3.06,2.94-4.18,4.7c-2.05,3.21-3.64,6.71-5.6,9.98c-1.39,2.33-3.07,4.5-4.62,6.73
				C282.64,938.57,282.21,938.33,281.78,938.1z M305.6,719.78c-0.54-0.01-1.07-0.03-1.61-0.04c-0.22,4.2-0.76,8.42-0.6,12.61
				c0.91,23.92,2.69,47.84,2.81,71.76c0.1,19.92-2,39.83-2.52,59.76c-0.46,17.57-4,34.24-11.81,49.99c-0.48,0.97-0.65,2.1-0.97,3.15
				c0.63,0.19,1.25,0.38,1.88,0.57c3.32-9.47,7.5-18.74,9.71-28.46c2.15-9.48,2.63-19.4,3.24-29.17c1.16-18.58,2.62-37.19,2.6-55.78
				c-0.02-18.09-1.93-36.17-2.65-54.27C305.27,739.88,305.6,729.82,305.6,719.78z"/>
			<path onClick={() => props.onFill(99)} fill={props.fillColors[99]} d="M955.19,537.66c-11.57-4.09-18.13-12.1-20.13-23.66c-1.82-10.47-3.98-20.98-4.49-31.54
				c-1.24-25.23-2.11-50.51-2.17-75.77c-0.08-31.61,1.22-63.22,1.16-94.83c-0.08-38.27-1.06-76.54-1.4-114.82
				c-0.07-7.7,0.98-15.42,1.42-21.83c0,9.83-0.42,20.89,0.09,31.91c0.94,20.59,2.78,41.14,3.6,61.74
				c1.28,32.44,0.22,64.83-1.87,97.26c-1.33,20.69,0.04,41.58,0.68,62.36c0.39,12.62,1.11,25.29,2.78,37.79
				c1.3,9.73,4.15,19.27,6.65,28.8c1.18,4.5,3.37,8.63,8.68,11.38c0.4-2.97,0.73-5.45,1.06-7.92c0.16-0.01,0.31-0.02,0.47-0.03
				c0,2.51,0.01,5.02,0,7.52c-0.03,5.9,3.75,9.07,9.57,7.91c1.12-0.22,2.38-0.52,3.24-1.21c4.49-3.6,9.08-5.2,15.26-5.32
				c5.25-0.1,8.84,1.1,12.49,4.08c0.11,0.09,0.14,0.3,0.45,1.04c-4.8,0-9.51,0.71-13.91-0.16c-8.03-1.59-18.34,4.03-20.35,11.96
				C957.34,528.68,956.32,533.04,955.19,537.66z"/>
			<path onClick={() => props.onFill(100)} fill={props.fillColors[100]} d="M242.03,936.51c-6.34-0.59-8.22-2.58-10.22-8.61c-5.84-17.64-6.21-35.97-6.96-54.21
				c-0.94-22.6-1.37-45.22-2-67.83c-0.03-1.13,0.12-2.27,0.22-3.9c4.64,0.81,9.07,1.33,13.31,2.52c1.1,0.31,2.23,2.7,2.29,4.18
				c0.46,11.29,0.56,22.6,0.97,33.9c0.72,19.93,1.74,39.86,2.32,59.8C242.3,913.63,242.03,924.93,242.03,936.51z"/>
			<path onClick={() => props.onFill(101)} fill={props.fillColors[101]} d="M135.06,1475.41c-0.82-0.54-1.35-1.19-1.9-1.21c-5.74-0.21-9.7-3.27-14.34-6.44
				c-3.36-2.29-7.32-3.11-9.05-7.54c-0.27-0.7-3.03-1.22-4.06-0.69c-4.27,2.22-7.28,1.08-9.5-3.55c-3.91,4.92-7.68,3.23-11.24,0.7
				c-4.6-3.26-10.03-5.18-12.71-11.23c-1.43-3.22-2.03-6.26,0.01-7.79c1.88-1.4,5.58-1.04,8.27-0.47c2.68,0.57,5.18,2.97,7.06-1.71
				c2.08,2.36,3.78,4.58,5.78,6.48c3.11,2.97,5.27,2.26,6.51-1.84c0.26-0.86,0.92-1.61,1.4-2.4c0.87,0.61,1.98,1.05,2.54,1.87
				c0.73,1.06,0.95,2.46,1.54,3.64c3.08,6.15,8.59,6.52,12.4,0.81c6.25,3.92,11.86,9.28,19.67,9.78c0.38-0.5,0.76-0.99,1.14-1.49
				c-3.67-3.33-7.12-6.94-11.05-9.92c-4.98-3.78-5.79-6.09-1.79-11.28c2.3,1.98,4.58,3.94,6.85,5.91c0.25,0.21,0.39,0.57,0.65,0.75
				c4.47,2.9,8.96,5.77,13.42,8.68c0.95,0.62,2.41,1.24,2.62,2.11c1.51,6.26,8.18,5.27,11.77,8.54c0.28,0.25,1.2-0.21,1.85-0.35
				c0.71,6.46,0.17,6.93-6.2,5.8c-2.51-0.45-4.32-0.15-5.52,2.76c-0.57,1.39-3.11,2.92-4.63,2.8
				C141.14,1467.69,137.22,1469.75,135.06,1475.41z"/>
			<path onClick={() => props.onFill(102)} fill={props.fillColors[102]} d="M953.42,946.58c0.44-4.77,1.06-9.53,1.3-14.31c0.76-15.44,1.26-30.89,2.12-46.33
				c0.3-5.46,1.67-10.85,1.95-16.31c0.41-7.93,0.27-15.9,0.38-23.84c0.3-20.63,0.46-41.25,0.97-61.87
				c0.24-9.93,1.35-19.83,1.67-29.76c0.27-8.45-0.16-16.93-0.03-25.39c0.19-12.65,0.67-25.29,0.9-37.93
				c0.17-8.92,0.93-17.92,0.02-26.74c-1.43-13.85-4.28-27.55-6.08-41.37c-0.79-6.07-0.5-12.28-0.66-18.42
				c-0.23-8.81-0.96-17.66-0.49-26.44c0.61-11.23,1.81-22.47,3.61-33.57c0.56-3.43,3.9-6.41,5.08-8.22
				c-0.43,10.92-1.21,23.28-1.32,35.65c-0.11,12.31,1.34,24.68,0.55,36.93c-1.32,20.44,2.17,40.46,3.54,60.66
				c0.46,6.78,0.22,13.62,0.01,20.42c-0.41,13.29-1,26.58-1.57,39.87c-1.22,28.75-2.74,57.5-3.64,86.26
				c-0.74,23.46-0.18,46.96-1.26,70.39c-0.94,20.2-3.39,40.33-5.17,60.49C954.68,946.68,954.05,946.63,953.42,946.58z"/>
			<path onClick={() => props.onFill(103)} fill={props.fillColors[103]} d="M1161.35,908.27c0,10.74,0.05,21.47-0.08,32.21c-0.01,1.18-0.98,2.4-1.65,3.51
				c-7.44,12.37-16.96,22.36-30.38,28.58c-9.17,4.25-17.77,9.72-26.69,14.53c-2.6,1.4-5.41,2.41-9.46,4.19
				c2.22-8.67,0.72-17.23,8.69-22.6c4.67-3.15,9.33-6.36,13.74-9.86c4.9-3.89,9.52-8.14,14.24-12.26c2.88-2.51,6.2-4.69,8.48-7.67
				c6.74-8.81,13.08-17.93,19.55-26.94c0.92-1.28,1.77-2.62,2.65-3.93C1160.74,908.11,1161.05,908.19,1161.35,908.27z"/>
			<path onClick={() => props.onFill(104)} fill={props.fillColors[104]} d="M138.01,1498.38c3.27-3.94,1.45-9.18,6.47-10.84c0.34-0.11,0.72-0.85,0.67-1.24
				c-0.94-7.54,3.43-3.6,6.37-2.87c3.32-3.18,6.97-6.11,9.89-9.65c2.51-3.04,4.48-2.45,7.16-0.63c1.66,1.13,3.67,1.75,6.07,2.86
				c1.04,0.94,2.38,3.02,4.18,3.57c2.53,0.77,4.27-0.6,5.69-3.37c0.76-1.47,4.46-1.42,7.16-2.14c0.35,2.18,0.44,3.48,0.79,4.7
				c0.96,3.34,3.86,4.22,6.44,2.03c3.89-3.29,6.77-3.23,10.21,0.55c2.98,3.28,5.99,2.98,9.28-0.46c2.73,8.23,8.6,10.99,15.64,12.33
				c1.13,0.22,1.97,1.92,2.94,2.94c-1.13,0.74-2.25,2.14-3.38,2.14C202.19,1498.4,170.77,1498.38,138.01,1498.38z"/>
			<path onClick={() => props.onFill(105)} fill={props.fillColors[105]} d="M170.61,522.72c-5.18,3.51-9.35,6.51-13.72,9.16c-0.9,0.54-2.83,0.22-3.84-0.41
				c-11.94-7.49-24.44-13.28-38.76-14.57c-3.47-0.31-6.8-2.12-11.57-3.69c3.77-3,6.25-5.36,9.07-7.17
				c12.78-8.21,24.11-18.08,34.2-29.41c0.99-1.12,1.97-2.26,3.04-3.3c4.36-4.19,6.64-4.38,11.67-1.11c0.94,0.61,1.98,1.06,3.59,1.9
				c-3.93,3.19-7.64,6.15-11.28,9.18c-2.16,1.79-4.5,3.48-6.28,5.61c-2.31,2.76-4.01,5.9,1.54,7.48c-4.17,4.55-8.02,8.77-12.77,13.97
				c15.36-0.05,27.08-5.21,38.03-15.52c-0.4,2.1-0.51,2.76-0.66,3.42c-0.14,0.64-0.31,1.27-0.56,2.25c1.14,0.42,2.2,0.81,2.79,1.02
				c-3.41,4.12-7.31,7.84-9.98,12.29C161.64,519.63,163.22,522.13,170.61,522.72z"/>
			<path onClick={() => props.onFill(106)} fill={props.fillColors[106]} d="M306.17,1252.2c0.62-2.47,1.14-4.51,1.63-6.43c5.49-1.89,8.52,0.15,12.03,4.5
				c3.6,4.46,9.46,7.03,14.03,10.79c4.94,4.06,9.58,8.48,14.25,12.86c1.67,1.57,2.73,3.99,4.61,5.12c5.18,3.12,9.11,6.74,7.8,13.45
				c-3.19-0.79-7.46-0.4-8.94-5.44c-0.27-0.91-2.35-1.46-3.68-1.84c-4.99-1.41-10.54-1.44-13.59-6.99c-0.58-1.05-3.23-1.29-4.93-1.32
				c-2.98-0.05-5.96,0.33-8.94,0.56c-3.06,0.24-4.96-0.56-5.41-4.15c-0.54-4.33-4.47-3.6-7.43-4.09c-3.04-0.51-6.12-0.84-9.14-1.43
				c-2.88-0.57-3.31-2.42-3.01-5.22c0.26-2.37-0.62-4.87-1.19-8.62c2.65-3.72-0.55-8.72-8.61-9.89c3.26-4.53,1.53-7.72-2.85-10
				c0.27-4.24,1.51-7.17,5.75-9.2c5.25-2.52,9.92-6.26,15.28-9.76c2.15,0.94,4.83,2.11,7.71,3.36c-0.64,2.14-1.19,4-1.75,5.87
				c-5.64-1.98-8.6-0.05-9.53,5.85c-0.53,3.39-1.14,6.77-1.95,10.1C296.64,1247.11,296.66,1247.23,306.17,1252.2z"/>
			<path onClick={() => props.onFill(107)} fill={props.fillColors[107]} d="M942.6,170.49c-0.39,2.87-1.18,5.76-1.1,8.62c0.56,18.79,1.53,37.57,1.92,56.37
				c0.33,16.37,0.07,32.76,0.07,49.09c-0.56-2.29-1.17-4.74-1.77-7.2c-0.41-0.01-0.83-0.03-1.24-0.04c-0.36,3.05-0.73,6.09-1.09,9.14
				c-0.14,0-0.28,0-0.42,0.01c0-6.87-0.57-13.81,0.11-20.61c2.1-21.19-0.3-42.21-1.49-63.29c-0.53-9.3-0.84-18.65-0.43-27.94
				c0.15-3.37,1.92-8.52,4.33-9.52c3.64-1.5,6.96,1.92,9.4,5.56c7.19,10.7,9.19,22.94,9.55,35.16c0.95,32.25,1.16,64.52,1.34,96.78
				c0.2,36.42,0.05,72.84,0.05,98.77c-1.3-47.11-2.9-104.7-4.49-162.29c-0.77-0.06-1.55-0.12-2.32-0.18
				c-0.75,5.38-1.98,10.75-2.14,16.15c-0.2,6.62,0.7,13.27,0.74,19.91c0.11,20.1,0.04,40.2-1.12,60.35
				c-0.37-9.25-1.04-18.5-1.05-27.74c-0.02-19.31,0.07-38.63,0.58-57.93c0.3-11.3,1.53-22.56,2.28-33.85
				c0.91-13.77-0.11-27.26-6.34-39.89c-0.99-2.01-2.39-3.82-3.6-5.72C943.75,170.28,943.18,170.38,942.6,170.49z"/>
			<path onClick={() => props.onFill(108)} fill={props.fillColors[108]} d="M802.02,698.6c0.95,11.55,2.07,23.45,2.83,35.38c0.25,4-3.52,6.01-7.31,4.41c-8.79-3.73-17-3.7-24.28,3.51
				c-2.35,2.33-5.08,4.29-7.66,6.39c-3.03,2.47-6.49,3.52-10.26,4.75c-6.85,2.23-13.11,6.74-19.1,11.02
				c-4.92,3.52-9.09,8.08-13.6,12.18c-0.53-0.5-1.06-1-1.59-1.5c7.94-9.28,15.87-18.55,24.37-28.48c-2.66-1.82-5.27-3.6-8.17-5.58
				c2.63-4.66,5.92-8.42,12.33-8.7c11.4-0.49,21.65-3.6,29.92-12.43C786.39,712.2,794.25,705.76,802.02,698.6z"/>
			<path onClick={() => props.onFill(109)} fill={props.fillColors[109]} d="M222.72,332.99c-4.59,5.41-8.32,10.64-8.24,18.71c0.08,7.93-2.82,15.88-4.19,23.85
				c-0.56,3.26-0.96,6.67-0.65,9.94c0.38,4.09-6.04,14.63-11.26,16.53c-5.93,2.16-11.25,6.47-18.27,5.19c-1.56-0.28-3.4,1-6.03,0.73
				c4.6-4.93,8.86-10.24,13.89-14.69c5.93-5.24,8.04-11.85,9.12-19.12c-6.86-3.74-6.68-3.72-5.5-12.51
				c1.25-9.33,2.17-18.7,3.16-28.07c0.47-4.45,0.94-8.69,7.05-8.63c1.09,0.01,2.19-1.27,2.83-1.67c-0.32,2.29-1.44,5.04-0.8,7.29
				c0.66,2.32,3.02,4.15,5.11,6.78c1.67-2.24,2.9-3.93,4.18-5.58c3.96-5.1,6.74-4.68,9.47,1.4
				C222.6,333.14,222.72,332.99,222.72,332.99z"/>
			<path onClick={() => props.onFill(110)} fill={props.fillColors[110]} d="M134.39,1397.18c1.48-0.07,3.64-0.71,4.06-0.12c3.12,4.43,7.22,2.08,10.91,1.78
				c4.11-0.33,6.22,1.43,7.23,5.69c1.16,4.87,2.9,9.6,4.44,14.37c0.14,0.43,0.58,0.91,0.99,1.07c6.66,2.66,9.22,9.08,13.27,14.17
				c1.59,1.99,3.79,3.56,5.89,5.07c3.92,2.83,4.33,5.92,1.14,9.69c-0.62,0.74-0.89,1.77-1.46,2.57c-0.57,0.79-1.31,1.45-2.04,2.23
				c0.59,1.71,1.18,3.44,1.78,5.16c-0.54,0.26-1.07,0.53-1.61,0.79c-1.14-2.14-2.94-4.18-3.26-6.44c-0.54-3.84-1.98-5.36-5.85-4.52
				c-2.05,0.45-4.19,0.53-6.69,0.82c0.5,1.82,0.87,3.15,1.24,4.48c-3.39,1.2-11.14-1.91-12.71-5.07c-0.29-0.58-0.39-1.65-0.76-1.74
				c-6.05-1.46-7.7-9.17-14.35-9.98c-1.5-0.18-2.76-2.33-4.13-3.57c0.38-0.48,0.76-0.96,1.13-1.44c2.65,1.83,5.31,3.66,7.96,5.49
				c0.35-0.3,0.7-0.6,1.04-0.9c-0.82-1.47-1.71-2.91-2.45-4.42c-1.29-2.62-2.8-4.57-1.11-8.39c1.25-2.81-1.42-7.45-2.55-11.22
				c-0.78-2.62-2.25-4.79-0.12-7.85C137.26,1403.68,135.33,1400.49,134.39,1397.18z"/>
			<path onClick={() => props.onFill(111)} fill={props.fillColors[111]} d="M97.81,995.12c-2.35-1.53-3.96-2.4-5.35-3.53c-2.57-2.09-4.92-2.59-8.14-0.87
				c-1.73,0.92-4.4,0.34-6.59,0.04c-1.67-0.23-3.27-1.07-5.97-2.02c1.01,3.61,1.7,6.06,2.48,8.82c-5.37,2.81-10.23,5.81-16.58,1.94
				c-7.18-4.38-14.36-1.77-21.27,1.83c-0.26-0.53-0.53-1.07-0.79-1.6c8.56-5.5,17.15-10.98,25.68-16.53
				c1.92-1.25,3.93-2.51,5.44-4.19c4.79-5.32,9.01-11.19,14.12-16.16c3.95-3.83,8.81-6.77,13.45-9.83c3.84-2.53,7.95-4.66,12.25-7.14
				c2.38,3.8,3.29,7.31,1.59,12.2c-2.76,7.96-4.08,16.41-6.23,24.59C100.83,986.75,99.28,990.69,97.81,995.12z"/>
			<path onClick={() => props.onFill(112)} fill={props.fillColors[112]} d="M1013.01,894.38c-1.06-13.05-2.86-26.09-3.01-39.15c-0.25-22.96,0.31-45.94,0.91-68.9
				c0.9-34.6,2.4-69.19,3.11-103.8c0.64-31.28,0.81-62.58,0.66-93.87c-0.05-10.09-1.33-20.24-2.87-30.24
				c-1.49-9.72-4.03-19.28-5.75-27.27c5.14,9.71,8.47,21.96,9.29,34.5c1.52,23.22,2.12,46.51,2.67,69.78
				c0.68,29.12,1.69,58.26,1.14,87.37c-0.6,31.75-3.17,63.45-4.2,95.19c-0.76,23.6-0.42,47.24-0.57,70.87c-0.01,1.82,0,3.65,0,5.47
				C1013.94,894.36,1013.48,894.37,1013.01,894.38z"/>
			<path onClick={() => props.onFill(113)} fill={props.fillColors[113]} d="M235.72,991.14c0.83,22.14,1.67,44.28,2.54,67.32c-6.84-4.06-12.58-7.29-18.11-10.85
				c-1.42-0.91-2.63-2.67-3.18-4.3c-4.14-12.13-12.23-21.6-20.98-31.51c8.28-3.61,14.48-11.4,24.88-9.36
				c1.63,0.32,4.08-1.2,5.48-2.56c2.82-2.75,5.19-5.98,7.74-9.01C234.63,990.95,235.18,991.05,235.72,991.14z"/>
			<path onClick={() => props.onFill(114)} fill={props.fillColors[114]} d="M955.72,868.74c-1,16.56-1.99,33.12-3.03,50.38c-4.11-4.97-8.15-9.82-12.15-14.7
				c-0.95-1.15-1.61-2.57-2.68-3.58c-7.3-6.96-14.84-13.69-21.97-20.82c-4.53-4.53-9.38-9.12-12.54-14.58
				c-8.21-14.21-15.59-28.91-19.69-44.96c-0.37-1.45,0.12-3.12,0.21-4.68c0,0-0.06-0.03-0.06-0.03c0.91,2.48,1.58,5.08,2.76,7.41
				c8.49,16.77,18.25,32.79,30.66,46.93c5.06,5.76,11.29,10.62,17.52,15.17c3.11,2.28,7.37,3.75,12.61,0.12
				c-5.85-4.79-11.23-9.2-16.61-13.61l0.09,0.08c-0.99-1.25-1.82-2.68-3-3.72c-19.81-17.41-31.2-40.04-39.99-64.3
				c2.57,4.98,5.53,9.79,7.63,14.96c8.38,20.53,22.19,36.88,38.78,51.1c2.6,2.23,5.64,4.26,8.83,5.42c2.6,0.94,5.72,0.44,8.61,0.58
				c0.22-0.63,0.45-1.26,0.67-1.89c-8.42-9.55-16.83-19.1-25.25-28.64c2.63,1.12,4.26,3.19,6.22,4.86
				c7.44,6.29,14.99,12.46,22.51,18.67L955.72,868.74z"/>
			<path onClick={() => props.onFill(115)} fill={props.fillColors[115]} d="M276.23,924.04c-1.55-2.75-2.91-4.41-3.46-6.31c-0.59-2.06-0.84-4.41-0.49-6.51
				c1.28-7.62,3.97-15.16,4.16-22.78c0.86-34.26,0.94-68.54,1.6-102.81c0.1-5.23,1.71-10.46,2.89-15.63
				c0.28-1.24,1.66-2.23,2.53-3.33c1.21,1.21,3.18,2.26,3.49,3.67c1.78,8.09,3.66,16.21,4.6,24.42c2.19,19.07,1.09,38.17,0.12,57.29
				c-0.58,11.45-0.68,22.98-0.07,34.42c0.44,8.2-1.49,15.43-5.34,22.38c-2.33,4.21-4.71,8.4-7.22,12.51
				C278.39,922.44,277.16,923.18,276.23,924.04z M275.18,914.86c0.42,0.2,0.85,0.39,1.27,0.59c0.87-1.71,2.15-3.33,2.55-5.15
				c2.34-10.66,6.05-21.31,6.36-32.05c0.8-27.96,0.13-55.96-0.09-83.94c-0.04-4.46-0.77-8.92-1.19-13.38
				c-0.46,0.05-0.92,0.1-1.38,0.15c0.27,4.43,0.72,8.85,0.78,13.28c0.23,16.49,0.56,32.97,0.45,49.46
				C283.76,867.8,286.55,892.17,275.18,914.86z M280.89,795.07c-1.43,35.79,3.06,71.73-2.66,107.4
				C285.95,866.84,282.01,830.92,280.89,795.07z M285.53,904.42c4.5-9.18,4.88-116.68,0.6-125.5c-0.15,0.6-0.36,1.07-0.36,1.53
				c0.27,20.93,1.12,41.88,0.7,62.79C286.07,863.61,290.12,884.06,285.53,904.42z"/>
			<path onClick={() => props.onFill(116)} fill={props.fillColors[116]} d="M213.96,1260.62c5.28,1.22,7.51,10.17,13.67,2.97c2.09,4.7,4.8,7.18,10.41,5.95
				c3.55-0.78,7.59,0.98,11.31,0.59c1.87-0.2,3.5-2.65,5.52-4.31c2.35,1.87,5.26,6.2,8.84,0.06c5.99,9.87,8.69,0.91,11.06-1.66
				c2.38,2.87,4.33,5.08,6.11,7.42c1.69,2.22,3.96,4.37,4.63,6.91c1.22,4.61-4.22,8.77-10.14,8.42c-8.72-0.52-17.46-1.27-26.18-1.24
				c-4.72,0.02-9.42,2.02-14.16,2.14c-8.43,0.21-16.88-0.21-25.3-0.71c-0.96-0.06-2.76-2.63-2.5-3.61
				C209.23,1275.89,211.64,1268.35,213.96,1260.62z"/>
			<path onClick={() => props.onFill(117)} fill={props.fillColors[117]} d="M752.87,1479.98c-4.6,3.8-10.93,3.88-17.49,3.06c-2.79-0.35-5.62-0.6-8.42-0.6
				c-3.55,0-6.95-0.19-6.25,5.52c0.38,3.11-1.41,6.48-2.38,10.41c-13.17,0-27.29,0.12-41.39-0.08c-4.07-0.06-8.24-6.38-7.64-10.69
				c0.73-5.19-1.07-7.92-6.21-9.31c-2.09-0.57-4.17-0.79-3.91-4.02c0.07-0.86-2.23-2.71-3.47-2.74c-5.3-0.14-10.62,0.21-15.92,0.44
				c-1.9,0.08-3.79,0.32-5.82-0.38c1.41-0.32,2.81-0.91,4.22-0.91c9.97,0.03,19.94,0.21,29.9,0.26c2.81,0.01,5.73,0.15,8.41-0.51
				c10.26-2.53,19.21,0.62,28.11,5.39c3.63,1.95,7.93,2.8,12.03,3.65c3.55,0.73,7.25,1.04,10.89,1.03
				C736.18,1480.48,744.85,1480.16,752.87,1479.98z"/>
			<path onClick={() => props.onFill(118)} fill={props.fillColors[118]} d="M139.47,418.89c1.45-3.45,2.9-6.89,4.51-10.69c-5.21-1.54-4.36-4.9-2.34-8.07
				c4.33-6.8,6.17-14.15,6.28-22.15c0.03-2.36,1.54-4.68,2.21-7.06c0.84-2.99,1.52-6.03,2.38-9.47c0.89,1.43,1.86,3.01,2.83,4.58
				c0.62-0.17,1.24-0.34,1.86-0.51c3.79-7.33,13.44-11,11.85-21.89c-0.44-2.99,1.76-6.36,2.72-9.44c2.17,3.08,3.88,5.52,5.61,7.94
				c0.38,0.53,1.33,1.05,1.26,1.46c-0.87,5.69,3.88,11.6-0.49,17.08c-3.41,4.28-7.08,8.34-10.61,12.52c-1.36,1.61-2.63,3.3-3.98,5.01
				c3.36,3.15,6.7,3.28,10.51,2.23c1.67-0.46,3.67,0.27,5.52,0.46c0.05,0.58,0.09,1.16,0.14,1.74c-2.53,2.37-5.25,4.56-7.54,7.14
				c-4,4.52-7.57,9.43-11.61,13.9c-2.86,3.17-6.03,6.14-9.4,8.76c-3.39,2.63-7.21,4.71-10.84,7.02
				C140.06,419.26,139.76,419.07,139.47,418.89z"/>
			<path onClick={() => props.onFill(119)} fill={props.fillColors[119]} d="M1095.18,168.33c8.21-6.04,17.8-8.16,27.99-9.21c3.8-0.39,8.35-3.07,10.71-6.13
				c8.47-11.03,12.72-12.87,27.51-9.08c0,6.39,0,12.99,0,19.6c0,6.64,0,13.28,0,20.4c-2.83-0.43-5.27-0.59-7.6-1.19
				c-7.37-1.93-14.71-3.99-22.04-6.07c-1.43-0.41-3.09-0.81-4.08-1.78c-9.33-9.14-20.84-6.9-32.04-6.34
				C1095.38,168.52,1095.12,168.3,1095.18,168.33z"/>
			<path onClick={() => props.onFill(120)} fill={props.fillColors[120]} d="M826.46,1236.58c10.29-1.7,19.67-3.68,29.15-4.67c9.02-0.95,18.84-3.19,27.08-0.73
				c16.73,4.99,32.51,0.27,48.68-0.93c1.65-0.12,3.3-0.37,4.95-0.5c0.8-0.07,1.62-0.01,2.88-0.01c-0.33,4.1-0.63,7.97-0.99,12.4
				c-8.26,1.12-16.31,3.52-24.88,1.59c-14.84-3.34-29.5-2.42-44.3,1.3c-8.75,2.2-18,2.44-27.04,3.52c-0.79,0.09-1.88,0.13-2.4-0.31
				C835.4,1244.63,831.3,1240.9,826.46,1236.58z"/>
			<path onClick={() => props.onFill(121)} fill={props.fillColors[121]} d="M500.56,931.85c0.16,1.31,0.31,1.96,0.31,2.61c-0.18,31.09-0.3,62.18-0.67,93.27
				c-0.04,3.35-1.53,6.69-2.56,10.93c-8.48-6.15-8.28-14.09-8.84-21.51c-0.7-9.24-0.91-18.52-1.1-27.79
				c-0.23-11.6-0.26-23.2-0.29-34.8c-0.01-4.65,0.01-9.32,0.36-13.96c0.52-6.85,1.64-7.88,8.53-8.73
				C497.57,931.72,498.9,931.85,500.56,931.85z"/>
			<path onClick={() => props.onFill(122)} fill={props.fillColors[122]} d="M1101.98,917.45c0.3-5.63,0.45-11.27,1.01-16.87c0.13-1.36,1.33-2.99,2.51-3.81
				c16.23-11.27,26.99-26.69,34.52-44.7c2.49-5.96,6.2-11.45,8.26-17.53c4.19-12.4,7.63-25.06,11.39-37.6
				c0.31-1.04,0.7-2.07,1.38-3.02c0.02,10.57,1.25,21.04-2.6,31.42c-5.35,14.42-8.3,29.89-14.95,43.61
				c-6.74,13.92-16.24,26.62-25.53,39.1c-3.51,4.72-9.83,7.33-14.88,10.91C1102.72,918.47,1102.35,917.96,1101.98,917.45z"/>
			<path onClick={() => props.onFill(123)} fill={props.fillColors[123]} d="M1112.02,444.13c1.59,0.34,3.07,1.07,4.4,0.86c2.6-0.4,5.4-0.84,7.6-2.14c3.98-2.35,7.59-5.35,11.35-8.07
				c0.28,0.36,0.56,0.71,0.84,1.07c-2.35,2.86-4.69,5.73-6.61,8.06c2.55,3.07,4.68,5.61,6.78,8.17c0.82,0.99,1.39,2.6,2.41,2.94
				c5.38,1.78,4.2,5.12,2.1,8.47c-2.52,4.02-4.44,7.98-3.48,13.03c0.32,1.68-0.81,3.86-1.79,5.5c-5.08,8.46-10.5,16.71-15.39,25.27
				c-1.31,2.29-0.94,5.48-1.78,8.12c-0.98,3.09-2.43,6.04-3.67,9.05c-0.41-0.07-0.81-0.14-1.22-0.21
				C1113.06,497.68,1112.54,471.1,1112.02,444.13z M1133.73,468.72c-0.37-0.24-0.74-0.48-1.11-0.72
				c-5.33,6.24-11.01,12.22-15.87,18.81c-3.42,4.63-2.29,10.15,2.7,16.46C1124.39,491.32,1129.06,480.02,1133.73,468.72z"/>
			<path onClick={() => props.onFill(124)} fill={props.fillColors[124]} d="M209.96,931.01c2.85,5.88,5.73,12.71,9.42,19.06c1.46,2.52,2.79,8.23,8.12,3.85
				c0.13-0.11,0.59,0.25,0.91,0.3c1.28,0.23,2.57,0.42,3.85,0.63c-0.24-1.78-0.48-3.56-0.66-4.92c1.41,0.15,3.6,0.38,5.78,0.62
				c0.11-2.37,0.22-4.74,0.35-7.32c0.89,0.1,2.14,0.25,3.39,0.4c1.76,2.57-1.45,10.43-5.22,14.42c4.09-0.73,8.18-1.46,12.61-2.25
				c-0.26,4.15,1.55,8.38-3.78,10.91c-1.42,0.67-1.95,4.43-1.92,6.76c0.25,16.98,0.94,33.95,1.08,50.93
				c0.08,9.63-1.1,19.29-0.87,28.91c0.45,19.28,1.47,38.56,2.26,57.83c0.39,9.48,0.77,18.96,1.2,28.44c0.2,4.59-3.15,3.67-7.26,4.33
				c0-9.85-0.03-19.23,0.01-28.61c0.13-34.79,0.84-69.58,0.17-104.36c-0.31-15.88-3.31-31.72-5.1-47.57
				C221.37,964.95,209.63,949.02,209.96,931.01z"/>
			<path onClick={() => props.onFill(125)} fill={props.fillColors[125]} d="M1161.21,898.63c-13.01,30.76-36,52.35-64.45,69.53c1.14-9,2.24-17.7,3.22-25.48
				c25.22-17.24,43.8-39.91,60.18-64.68c0.52,0.17,1.04,0.33,1.55,0.5c-0.6,2.96-0.52,6.29-1.9,8.83
				c-10.96,20.23-24.82,38-44.18,51.02c-4.32,2.91-8.44,6.18-12.33,9.64c-1.04,0.93-0.92,3.17-1.34,4.8c1.79-0.23,4.1,0.19,5.29-0.78
				c8.79-7.1,17.9-13.93,25.84-21.91C1143.01,920.15,1151.88,909.17,1161.21,898.63z"/>
			<path onClick={() => props.onFill(126)} fill={props.fillColors[126]} d="M449.18,760.49c17.44-14.27,33.82-31.41,56.43-40.84c-0.5,11.95-0.91,23.2-1.51,34.44
				c-0.06,1.1-0.94,2.56-1.88,3.13c-9.93,6.02-20.57,9.7-32.66,9.17c5.56-4.97,12.23-8.83,15.54-17.65
				C472.13,752.98,460.26,756.87,449.18,760.49z"/>
			<path onClick={() => props.onFill(127)} fill={props.fillColors[127]} d="M565.29,1468.17c-0.89,2.3-1.95,5.22-3.12,8.09c-2.68,6.55-5.37,13.1-8.2,19.58
				c-0.44,1.01-1.64,2.39-2.5,2.4c-19.51,0.14-39.02,0.11-58.37,0.11c7.26-5.8,14.35-11.48,21.61-17.29c0,1.16,0,2.23,0,4.53
				c5.84-2.57,11.49-4.4,16.43-7.4c5.28-3.21,10.73-4.69,16.76-5.54C553.67,1471.84,559.27,1469.77,565.29,1468.17z"/>
			<path onClick={() => props.onFill(128)} fill={props.fillColors[128]} d="M164.78,335.06c-4.38,4.33-7.29,8.4-11.23,10.76c-3.75,2.24-8.53,2.81-12.91,3.91
				c-4.15,1.04-8.32,2.26-12.55,2.63c-7.71,0.68-15.47,0.9-23.21,1.09c-7.96,0.2-15.93,0.27-23.9,0.18
				c-6.79-0.07-13.58-0.65-20.37-0.65c-13.95-0.01-27.89,0.24-41.84,0.35c-5.42,0.04-10.84,0.01-16.45,0.01c0-2.73,0-4.65,0-7.13
				c2.22,0.32,4.13,0.87,6.03,0.83c6.93-0.14,13.85-0.48,20.77-0.75c3.32-0.13,6.63-0.24,9.95-0.38c4.3-0.18,8.67,0.04,12.88-0.66
				c6.99-1.16,13.6-0.15,19.91,2.67c7.09,3.17,14.19,3.92,21.85,2.33c4.61-0.95,9.54-0.31,14.29-0.75c6.72-0.63,12.51-3.6,17-8.57
				c4.07-4.51,8.85-4.91,14.47-4.5c4.29,0.32,8.67-1.04,13.04-1.32C156.41,334.86,160.35,335.06,164.78,335.06z"/>
			<path onClick={() => props.onFill(129)} fill={props.fillColors[129]} d="M185.61,1018.3c2.8-1.88,5.26-3.54,8.94-6.02c6.09,9.34,12.52,18.31,17.91,27.87
				c2.84,5.04,5.44,9.37,10.84,12.28c14.8,7.99,14.65,8.23,14.23,24.83c-0.1,3.78-0.01,7.56-0.01,11.33
				c-0.65,0.36-1.3,0.72-1.95,1.08c-0.72-1.24-1.24-2.66-2.2-3.68c-9.06-9.66-18.21-19.24-27.29-28.88
				c-0.89-0.95-2.07-2.13-2.16-3.27c-1.02-13.26-9.42-22.76-16.5-32.94C186.96,1020.23,186.49,1019.56,185.61,1018.3z"/>
			<path onClick={() => props.onFill(130)} fill={props.fillColors[130]} d="M535.33,1193.81c-1.05-0.75-2.05-2.06-3.17-2.17c-4.53-0.44-7.18-2.75-8.02-7.15
				c-0.34-1.78-0.63-3.62-0.6-5.43c0.36-21.02,0.78-42.05,1.19-63.42c5.04,0.54,8.09,2.34,10.01,5.99
				c5.97,11.37,7.87,23.47,6.29,36.13c-1.42,11.34-3.02,22.67-4.61,33.99c-0.1,0.69-0.82,1.29-1.24,1.93
				C535.19,1193.7,535.33,1193.81,535.33,1193.81z"/>
			<path onClick={() => props.onFill(131)} fill={props.fillColors[131]} d="M627.15,829.32c3.26-3.13,6.12-5.88,9.24-8.88c-0.8-1.32-1.61-2.66-2.43-4c8.22-3.26,9.45-3.31,14.2-0.34
				c-1.7,1.1-3.26,2.12-5.96,3.88c10.28,4.86,22.31,6.17,25.81,18.13c-2.26,4.06-5.03,8.24-6.88,12.8c-0.65,1.61,0.25,5.01,1.63,6.08
				c4.23,3.32,8.91,6.15,13.66,8.72c2.86,1.55,6.18,2.25,9.34,3.34c-0.42,3.95-1.98,5.11-5.64,3.81c-7.02-2.49-14.18-4.58-21.22-7.03
				c-1.64-0.57-3.35-1.65-4.42-2.98c-3.16-3.93-6.1-8.04-8.91-12.22c-0.58-0.86-0.64-2.57-0.19-3.54c2.42-5.14-0.02-8.34-4.18-10.67
				C636.88,834.01,632.36,831.94,627.15,829.32z"/>
			<path onClick={() => props.onFill(132)} fill={props.fillColors[132]} d="M751.43,1496.67c2.15-1.55,4.09-3.84,6.48-4.49c4.57-1.25,9.51-1.15,14.1-2.36
				c6.35-1.67,12.49-4.18,18.79-6.06c4.21-1.25,8.55-2.09,12.85-3.01c1.93-0.41,3.97-0.33,5.86-0.85c6.95-1.89,10.84-0.71,15.09,5.03
				c2.77,3.73,5.39,7.56,8.05,11.38c0.26,0.37,0.26,0.91,0.48,1.74c-27.1,0-54.01,0-80.93,0
				C751.95,1497.59,751.69,1497.13,751.43,1496.67z"/>
			<path onClick={() => props.onFill(133)} fill={props.fillColors[133]} d="M260.92,807.72c4.57,0.87,8.68,1.38,12.56,2.59c1.02,0.32,1.97,2.8,1.95,4.28
				c-0.25,15.64-0.75,31.28-1.07,46.92c-0.21,10.15-0.14,20.31-0.35,30.46c-0.15,6.99-1.11,13.82-5.62,20.72
				C265.87,877.27,263.42,842.84,260.92,807.72z"/>
			<path onClick={() => props.onFill(134)} fill={props.fillColors[134]} d="M728.06,423.09c-4.02,4.76-8.05,9.53-12.35,14.62c1.82,0.74,3.28,1.34,5.68,2.32
				c-9.93,7.61-19.43,14.91-29.89,22.93c0.28-4.09,0.46-6.76,0.77-11.34c-11.84,6.28-22.46,11.91-33.05,17.52
				c3.47-7.43,8.55-13.72,15.96-17.58c5.93-3.09,10.28-7.23,12.29-13.62c2.51-8.01,8.3-10.49,16.48-9.9
				c-1.51,2.53-2.88,4.84-4.26,7.14c0.31,0.32,0.61,0.65,0.92,0.97C709.94,432.46,716.63,423.34,728.06,423.09z"/>
			<path onClick={() => props.onFill(135)} fill={props.fillColors[135]} d="M1004.22,475.95c-5.58,0.8-9.03,0.13-11.39-4.25c-0.82-1.53-3.66-1.97-5.71-2.98
				c-0.52-2.92-1.13-6.29-1.68-9.39c2.18-2.55,4.4-5.14,7.04-8.22c7.17,1.84,15.45,3.57,23.47,6.11c10.4,3.3,20.64,4.74,31.07-0.13
				c0.24,0.36,0.48,0.73,0.73,1.09c-0.68,0.83-1.4,2.4-2.04,2.37c-8.12-0.38-13.68,4.29-19,9.4c-3.79,3.65-7.51,6.86-12.71,8.98
				c-5.16,2.1-9.26,6.83-13.79,10.45c-1.27,1.01-2.42,2.17-3.63,3.26c-0.36-0.23-0.72-0.46-1.07-0.7
				C998.27,486.87,1001.02,481.81,1004.22,475.95z"/>
			<path onClick={() => props.onFill(136)} fill={props.fillColors[136]} d="M542.72,904.68c-0.31,2.01-0.63,4.03-0.94,6.04c-0.1-0.01-0.19-0.03-0.29-0.04
				c0-15.91-0.08-31.81,0.13-47.72c0.02-1.59,2.09-4.59,2.79-4.47c2.24,0.38,5.09,1.39,6.21,3.13c1.74,2.69,2.99,6.13,3.15,9.32
				c0.91,18.76,2.74,37.67-3.75,55.88c-2.89,8.11-6.4,16.03-10.04,23.84c-1.07,2.3-3.55,3.94-5.95,6.48
				c-1.65-4.37-1.88-6.92,0.47-10.17c7.05-9.74,10.67-21.07,14.43-32.27c-0.41-0.2-0.82-0.4-1.23-0.59c-1.73,3.31-3.47,6.62-5.2,9.93
				c-0.21-0.09-0.42-0.18-0.63-0.27c0.94-6.3,1.88-12.59,2.82-18.89C544.03,904.81,543.37,904.75,542.72,904.68z"/>
			<path onClick={() => props.onFill(137)} fill={props.fillColors[137]} d="M441.52,1256.61c0.65-3.2,3.01-3.84,6.89-4.01c15.26-0.66,30.5-1.99,45.75-3.01
				c15.57-1.04,31.14-2,46.71-2.99c1.16-0.07,2.33-0.06,3.49-0.15c10-0.7,10.59-0.36,15.9,10.15
				C520.3,1256.61,480.84,1256.61,441.52,1256.61z"/>
			<path onClick={() => props.onFill(138)} fill={props.fillColors[138]} d="M151.29,356.87c-1.9,9.12-3.32,17.3-5.37,25.33c-1.46,5.7-3.43,11.33-5.78,16.74
				c-2.24,5.15-2.33,7.48,1.32,10.6c-1.4,3.59-2.77,7.28-4.29,10.91c-0.27,0.65-1.04,1.38-1.71,1.54
				c-5.59,1.35-11.21,2.58-17.38,3.97c1.13-1.32,2.21-2.39,3.08-3.61c1.92-2.69,4.96-5.26,5.39-8.2c1.67-11.35,8.81-19.9,13.84-29.53
				c1.03-1.97,1.76-4.09,3.08-7.22c-2.88,0.61-4.6,0.89-6.28,1.35c-3.78,1.03-5.33-0.88-4.32-4.11c1.71-5.52,4.01-10.87,6.26-16.2
				c0.37-0.87,1.81-1.87,2.73-1.84c8.25,0.23,11.18-6.76,15.92-11.36c2.51-2.43,5.4-4.47,8.38-6.9c4.43,11.76-3.81,17.44-9.26,25.16
				C155.15,361.41,153.64,359.63,151.29,356.87z"/>
			<path onClick={() => props.onFill(139)} fill={props.fillColors[139]} d="M2.86,467.64c-2.54-9.27-0.01-16.04,6-21.67c3.99-3.74,7.98-7.49,12.06-11.13
				c2.87-2.55,5.79-4.38,5.89-9.34c0.06-3.06,3.44-6.73,6.27-8.87c2.75-2.08,6.7-2.55,10.87-3.99c-0.97,6.45,0.54,10.89,8.8,10.48
				c-3.42,4.67-5.53,9.11-8.96,11.96C30.52,446.09,16.83,456.61,2.86,467.64z"/>
			<path onClick={() => props.onFill(140)} fill={props.fillColors[140]} d="M536.01,794.36c0-13.35,0.13-26.95-0.07-40.54c-0.07-4.41,2.12-6.65,5.67-8.33
				c12.12-5.73,24.22-11.5,37.18-16.39c-6.97,6.57-13.68,13.46-21.01,19.6c-4.66,3.91-9.02,7.67-11.14,15.09
				c5.25,0.25,10.01,0.49,15.01,0.73C555.05,776.22,544.06,784.63,536.01,794.36z"/>
			<path onClick={() => props.onFill(141)} fill={props.fillColors[141]} d="M496.4,1247.14c-16.47,1.23-32.95,2.45-49.97,3.72c1.12-3.64,2.06-6.71,3.39-11.07
				c4.51-1.95,5.32-5.35,1.66-11.43c-0.6-1,0.25-2.92,0.53-4.4c0.66-3.49,2.5-7.26-2.92-8.89c-0.69-0.21-1.49-2.38-1.16-3.22
				c0.69-1.74,1.91-3.38,3.27-4.71c1.1-1.07,2.72-1.6,4.09-2.39c1.07-0.61,2.06-1.64,3.18-1.8c4.44-0.63,8.92-0.99,14.95-1.62
				c-1.67,2.56-3,4.58-4.32,6.61c-0.63,0.96-1.95,2.1-1.76,2.87c1.5,6.11-4.96,12.01-0.43,18.14c0.26,0.35,0.25,0.96,0.23,1.45
				c-0.12,3.04-1.46,6.43,2.85,7.96c2.7,0.95,5.3,2.17,7.95,3.28c0.73,0.31,1.75,0.47,2.12,1.03c2.92,4.46,6.93,3.3,11.08,2.59
				c1.72-0.29,3.64,0.55,5.47,0.88C496.55,1246.47,496.47,1246.8,496.4,1247.14z"/>
			<path onClick={() => props.onFill(142)} fill={props.fillColors[142]} d="M609.14,989.7c1.59-0.24,3.2-0.79,4.76-0.65c4.82,0.43,9.17-0.37,13.24-3.22
				c1.54-1.08,3.69-1.43,5.62-1.79c6.62-1.24,8.12-3,8.53-9.48c0.52-8.3,1.2-16.59,1.99-24.87c0.56-5.94,8.21-9.74,13.42-6.82
				c2.62,1.47,2.82,9.13,0.29,11.02c-9.38,6.96-11.88,15.33-7.79,26.21c1.98,5.27,3.54,10.79,6.25,15.65
				c1.83,3.27,5.47,5.49,8.06,8.39c2.02,2.26,3.67,4.84,5.75,7.65c-3.6,0.42-6.27,0.66-8.91,1.05c-5.13,0.76-9.13-0.84-11.89-5.34
				c-2.09-3.39-4.23-6.75-6.36-10.11c-4.2-6.62-10.34-8.67-17.9-7.98c-4.93,0.46-9.91,0.34-14.87,0.48L609.14,989.7z"/>
			<path onClick={() => props.onFill(143)} fill={props.fillColors[143]} d="M213.38,1479.47c2.77-1.54,5.61-2.95,8.27-4.66c1.91-1.22,3.58-2.84,5.65-4.51
				c4.98,2.35,5.68,2.15,9.14-3.31c2.49,1.04,4.95,2.08,8.37,3.51c-2.22,1.04-3.81,1.28-4.41,2.18c-1.36,2.02-2.23,4.36-3.3,6.57
				c2.78,0.34,5.76,1.45,8.26,0.77c2.2-0.6,3.87-3.2,5.77-4.92c3.59,4.13,7.66,4.95,11.35,1.32c3.74-3.68,6.76,1.43,10.08,0.04
				c0.12,0.73,0.26,1.46,0.36,2.2c0.27,2.06,0.54,4.12,0.75,6.18c0.07,0.64,0.2,1.62-0.14,1.89c-0.5,0.4-1.58,0.65-2.09,0.37
				c-5.78-3.13-6.11-3-7.51,4.04c-1.5-1.21-2.85-2.3-4.14-3.34c-6.96,3.07-11.06,11.32-20.21,10.57c0.18-5.63-3.1-6.86-7.9-7.91
				c-3.5-0.77-7.45-3.52-9.36-6.55c-2.29-3.62-3.8-5.64-8.08-2.45C213.95,1480.8,213.66,1480.13,213.38,1479.47z"/>
			<path onClick={() => props.onFill(144)} fill={props.fillColors[144]} d="M31.15,1324.38c2.5,8.22,5,16.45,7.5,24.67c0.17,0.56,0.41,1.1,0.61,1.65c0.42-0.06,0.84-0.13,1.26-0.19
				c-0.76-6.36-1.52-12.72-2.27-19.08c0.16-0.04,0.32-0.07,0.48-0.11c1.61,6.28,3.23,12.56,4.95,19.27
				c5.9-6.96,2.14-14.63,3.16-21.95c0.78,5.5,1.56,11,2.34,16.5c0.44,0.03,0.87,0.07,1.31,0.1c0.24-4.53,0.48-9.07,0.72-13.6
				c0.28-0.01,0.56-0.01,0.84-0.02c0.37,5.17,0.74,10.34,1.19,16.64c1.68-2.76,2.82-4.65,4.37-7.19c0.11,2.54-0.25,4.6,0.37,6.29
				c1.64,4.42-0.86,6.41-4.14,8.09c-3.75,1.92-7.37,4.7-11.36,5.45c-8.28,1.55-15.83,5.88-24.59,5.74c-3.16-0.05-6.36,2.24-8.64,3.11
				c-1.46-7.94-2.95-16.03-4.44-24.12c0.13-0.13,0.26-0.25,0.39-0.38c2.61,4.9,5.21,9.79,8.14,15.3c2.94-6.43-0.54-11.37-2.08-17.56
				c3.37,3.93,6.16,7.19,8.95,10.44c0.39-0.11,0.77-0.23,1.16-0.34c-0.32-2.38-0.65-4.76-1.09-8.01c1.56,2.47,2.71,4.3,3.87,6.14
				c0.35-0.06,0.7-0.11,1.05-0.17c-0.43-2.66-0.87-5.32-1.42-8.73c2.5,3.66,4.63,6.78,7.24,10.6c6.19-10.35,1.66-19.26-0.24-28.22
				C30.89,1324.59,31.02,1324.48,31.15,1324.38z"/>
			<path onClick={() => props.onFill(145)} fill={props.fillColors[145]} d="M848.63,1365.45c5.15,2.52,5.48,7.17,4.94,11.91c-0.73,6.43-2.13,12.79-2.73,19.23
				c-0.7,7.6-0.81,15.26-1.25,22.89c-0.53,9.22-9.28,14.13-17.25,9.51c-1.25-0.72-2.29-2.98-2.2-4.44c0.07-1.06,2.05-2.23,3.4-2.91
				c1.03-0.51,2.41-0.32,3.63-0.44c0.05-0.45,0.1-0.91,0.14-1.36c-1.94-0.37-3.88-0.74-6.04-1.16c4.64-1.09,4.96-4.86,4.66-8.54
				c-0.5-6.18,1.47-11.53,3.75-17.17C843.26,1384.15,845.64,1374.85,848.63,1365.45z"/>
			<path onClick={() => props.onFill(146)} fill={props.fillColors[146]} d="M1118.99,1286.87c-0.89,5.5-1.37,9.61-2.26,13.63c-0.66,2.96-2.85,4.05-5.04,1.64
				c-8.55-9.42-13.05-20.49-12.22-33.34c0.35-5.44,1.14-10.87,2.21-16.22c0.37-1.86,2.25-4.86,3.32-4.81
				c1.88,0.09,4.05,1.81,5.4,3.43c1.42,1.71,2.47,4.02,2.92,6.22C1115.43,1267.62,1117.28,1277.86,1118.99,1286.87z"/>
			<path onClick={() => props.onFill(147)} fill={props.fillColors[147]} d="M965.29,1498.38c-19.93,0-39.87,0.06-59.8-0.05c-3.89-0.02-5.97-6.45-4.22-10.89
				c5.89-0.92,11.38-2.24,16.93-2.49c3.69-0.16,7.46,1.79,11.19,1.78c5.71-0.02,11.51-0.45,17.08-1.65c2.53-0.54,4.15-0.91,6.15,0.91
				c4.28,3.88,8.74,7.56,13.13,11.33C965.58,1497.67,965.44,1498.02,965.29,1498.38z"/>
			<path onClick={() => props.onFill(148)} fill={props.fillColors[148]} d="M1160.45,952.8c2.86,8.72,1.61,15.55-5.13,20.49c-20.22,14.8-40.85,29.02-64.16,38.73
				c-0.88,0.36-1.86,0.48-3.07,0.78c0.83-6.34,3.09-9.96,9.59-12.62c10.45-4.28,21.23-8.7,29.52-17.13c1.93-1.96,4.48-3.57,7.04-4.61
				c12-4.85,18.26-15.41,25.47-25.06C1159.96,953.19,1160.21,952.99,1160.45,952.8z"/>
			<path onClick={() => props.onFill(149)} fill={props.fillColors[149]} d="M821.06,1473.73c9.97-5.91,19.77-0.76,29.67,0.99c0.74,0.13,1.33,1.29,1.93,2.02
				c2.66,3.31,5.63,6.43,7.84,10.01c2.08,3.37,3.37,7.24,5.33,11.62c-8.01,0-14.81,0.1-21.61-0.09c-1.27-0.04-2.83-0.95-3.7-1.94
				C833.96,1488.88,827.54,1481.29,821.06,1473.73z"/>
			<path onClick={() => props.onFill(150)} fill={props.fillColors[150]} d="M106.53,1477.42c-5.28-2.04-10.24-4.16-15.38-5.74c-0.89-0.27-2.49,1.8-4.93,3.69
				c-1.44-1.08-3.75-2.86-6.1-4.58c-3.61-2.64-7.01-5.72-10.95-7.71c-3.98-2.01-5.8-4.58-5.28-8.91c8.45-1.67,8.45-1.67,3.21-10.96
				c1.24,1.07,3.05,1.9,3.63,3.25c3.31,7.81,10.53,11.19,17.4,13.79c5.31,2.01,11.75,1,17.67,1.49c1.05,0.09,2.37,0.7,3.01,1.5
				c2.4,3.02,3.93,6.77,9.18,5.93c1.24-0.2,2.99,3.07,4.56,4.69c0.65,0.67,1.44,1.21,2.05,2.15c-4.4-1.04-9.14,3.74-13.38-1.06
				C110.93,1474.64,108.07,1476.59,106.53,1477.42z"/>
			<path onClick={() => props.onFill(151)} fill={props.fillColors[151]} d="M84.2,574.78c2.62-2.38,5.24-4.76,8.76-7.96c-3.68-1.09-6.26-1.85-9.67-2.86
				c3.66-3.34,7.25-6.62,10.83-9.9c-0.18-0.46-0.36-0.92-0.55-1.38c-2.22,0-4.45,0-7.22,0c0.33-4.3,0.68-8.78,1.07-13.87
				c-1.27,0.5-2.81,1.1-4.18,1.63c0-1.84,0.16-3.87-0.16-5.81c-0.05-0.33-2.21-0.31-4.08-0.53c1.25-1.79,2.6-3.36,3.53-5.14
				c1.01-1.94,1.62-4.09,2.42-6.14c0.76-1.95,1.55-3.88,2.14-5.36c1.7,3.15,2.85,5.53,4.23,7.76c1.75,2.83,3.7,5.54,5.57,8.3
				c2.87,4.24,5.48,8.7,8.71,12.66c2.17,2.66,2.36,4.79,0.65,7.56c-3.38,5.51-5.95,11.7-10.17,16.44c-2.6,2.92-7.62,3.68-11.55,5.41
				C84.43,575.33,84.32,575.05,84.2,574.78z"/>
			<path onClick={() => props.onFill(152)} fill={props.fillColors[152]} d="M95.83,1030.1c-0.64,11.9-1.56,24.48-1.88,37.08c-0.14,5.51,0.62,11.11,1.61,16.55
				c0.76,4.16,2.51,8.14,4.27,13.58c-10.59-4.96-16.53,1.71-23.18,8.37c-1.2-8.09-1.37-14.57,3.77-19.91
				c2.19-2.28,2.35-4.47,0.89-7.18c-1.49-2.77-2.62-5.74-4.01-8.57c-1.9-3.86-1.47-7.02,2.56-9.27c0.26,0.04,0.65-0.02,0.87,0.14
				c7.44,5.73,7.61,5.77,9.6-3.22C92.39,1048.33,94.09,1038.9,95.83,1030.1z"/>
			<path onClick={() => props.onFill(153)} fill={props.fillColors[153]} d="M781.42,472.24c-0.2,3.79-0.1,7.09-0.65,10.28c-0.26,1.54-1.47,3.19-2.7,4.26
				c-5.97,5.17-12,10.3-18.24,15.14c-1.91,1.48-4.8,3.11-6.87,2.72c-5.48-1.05-10.84-2.97-16.08-4.96
				c-2.49-0.95-0.77-8.91,2.33-10.55c5.27-2.79,10.59-5.5,16.03-7.95c1.24-0.56,3.39-0.44,4.51,0.29c5.33,3.48,9.67,1.91,13.81-2.05
				C776.03,477.05,778.61,474.8,781.42,472.24z"/>
			<path onClick={() => props.onFill(154)} fill={props.fillColors[154]} d="M287.81,485.64c-1.85,7,1.19,9.55,10.7,10.91c-3.55,0.98-7.07,2.62-10.65,2.78
				c-4.53,0.2-6.99,2.96-10.12,5.42c-3.51,2.77-7.54,5.14-11.69,6.81c-7.2,2.9-14.66,5.14-22.02,7.64c-2.17,0.73-4.36,1.4-7.45,2.39
				c9.52-9.14,18.25-17.65,27.13-26.01C270.27,489.4,278.8,488,287.81,485.64z"/>
			<path onClick={() => props.onFill(155)} fill={props.fillColors[155]} d="M723.23,1484.83c2-0.09,3.53-0.43,4.97-0.18c10.94,1.83,21.4,1.16,31.04-5.12c1.21-0.79,3.2-0.67,4.8-0.56
				c9.42,0.67,18.84,1.46,27.05,2.12c-6.14,2.37-13.22,6.2-21.89,6.75c-7.24,0.46-15.6,0.38-19.77,8.86
				c-0.46,0.94-2.4,1.59-3.68,1.62c-6.48,0.16-12.96,0.13-19.44,0.06c-4.5-0.05-6-2.6-4.2-6.91c0.44-1.06,1.02-2.1,1.25-3.2
				C723.55,1487.33,723.31,1486.32,723.23,1484.83z"/>
			<path onClick={() => props.onFill(156)} fill={props.fillColors[156]} d="M989.07,452.1c-1.02,1.12-1.85,2.58-3.09,3.3c-2.58,1.49-3.42,3.22-2.39,6.14
				c0.65,1.81,0.77,3.85,0.89,5.8c0.16,2.74,0.36,5.03,4.22,4.39c0.87-0.14,2.08,1.4,3.03,2.28c2.39,2.2,4.73,4.46,7.21,6.81
				c-3.04,6.89-6.41,14.56-9.79,22.22c-0.53,0-1.07,0-1.6,0c-2.24-4-5.38-7.75-6.53-12.04c-2.9-10.85-4.9-21.93-7.25-32.93
				c-0.61-2.84,0.62-4.28,3.42-4.84c3.55-0.72,7.05-1.69,10.57-2.55C988.19,451.16,988.63,451.63,989.07,452.1z"/>
			<path onClick={() => props.onFill(157)} fill={props.fillColors[157]} d="M41.65,1327.15c-1.04-2.91-2.37-5.75-3.07-8.74c-3.03-12.93,3.12-24.25,15.45-29.96
				c5.93-2.75,11.42-6.42,17.12-9.66c0.68-0.39,1.4-0.69,2.49-0.43c-1.35,0.98-2.79,1.87-4.04,2.97c-1.04,0.91-1.88,2.06-2.8,3.1
				c1.07,0.94,2.18,1.83,3.19,2.83c1.63,1.6,2.99,3.54,4.82,4.83c2.23,1.57,4.82,2.6,6.04,4.16c0.56,3.32,1.12,6.64,1.74,10.26
				c-4.83-0.17-4.83-10.12-11.67-6.19c-4.09-3.3-8.31-6.07-14.6-3.04c1.37,2.04,2.59,3.85,4.16,6.18c-3.22,1.62-7.3,2.61-9.76,5.17
				c-3.39,3.53-7.35,1.18-10.32,2.39c1.11,5.17,2.22,10.28,3.32,15.39C43.03,1326.66,42.34,1326.9,41.65,1327.15z"/>
			<path onClick={() => props.onFill(158)} fill={props.fillColors[158]} d="M954.7,831.02c-8.71-8.6-17.42-17.21-26.17-25.86c8.7-8.51,18.83-10.2,29.5-12.56
				c-0.51,12.54-1.02,24.87-1.52,37.21C955.91,830.21,955.31,830.61,954.7,831.02z"/>
			<path onClick={() => props.onFill(159)} fill={props.fillColors[159]} d="M609.32,989.91c-0.3,1.26-1.03,2.58-0.84,3.76c1.73,10.93,3.26,21.92,9.68,31.45
				c1.52,2.26,2.43,4.94,3.68,7.57c-9.54,0.71-16.48-2.18-19.38-11.44c-3.4-10.89-12.84-14.5-21.94-18.56
				c-1.04-0.46-2.08-0.91-3.13-1.37c0.13-0.49,0.25-0.99,0.38-1.48c1.79,0.27,3.62,0.38,5.36,0.83c7.63,1.97,13.98,0.82,18.85-6.2
				c1.52-2.19,4.71-3.21,7.14-4.76C609.14,989.7,609.32,989.91,609.32,989.91z"/>
			<path d="M1069.54,339.89c0.96,0.35,2.6,0.49,2.77,1.07c1.31,4.44,3.07,8.92,3.32,13.46c1.3,24.23,2.53,48.47,3.13,72.72
				c0.69,27.77,0.56,55.57,1.06,83.34c0.26,14.29,1.24,28.57,1.69,42.86c0.13,4.09-0.46,8.19-1.6,12.43
				c-0.28-0.63-0.8-1.24-0.82-1.88c-0.58-18.11-1.29-36.21-1.59-54.33c-0.31-18.97,0.26-37.96-0.45-56.9
				c-0.75-20.09-2.62-40.14-3.85-60.22c-0.68-11.1-0.91-22.24-1.63-33.34c-0.36-5.61-1.37-11.18-2.08-16.77
				C1069.51,341.53,1069.53,340.71,1069.54,339.89z M1075.93,405.19c0.34,0.02,0.68,0.03,1.02,0.05
				c-0.96-17.71-1.92-35.41-2.88-53.12c-0.4,0.02-0.8,0.05-1.2,0.07C1073.89,369.85,1074.91,387.52,1075.93,405.19z"/>
			<path onClick={() => props.onFill(160)} fill={props.fillColors[160]} d="M46.33,1013.97c-5.21,4.34-9.55,8.11-14.09,11.62c-1.16,0.89-3.16,1.64-4.44,1.27
				c-8.33-2.41-16.63-2.75-25.02-0.06c-1.84-5.29-0.43-8.77,3.89-11.77c7.43-5.15,15.02-9.68,24.55-10.79
				c-0.73,1.38-1.33,2.52-1.95,3.69c0.32,0.49,0.5,1.16,0.84,1.25c3.68,1.01,7.39,1.86,11.06,2.89
				C42.93,1012.55,44.61,1013.32,46.33,1013.97z"/>
			<path onClick={() => props.onFill(161)} fill={props.fillColors[161]} d="M1090.84,1352.36c-4.08-0.47-8.58-0.02-12.15-1.62c-6.28-2.82-12.21-6.61-17.86-10.58
				c-3.11-2.19-5.27-5.58-3.39-9.97c1.87-4.36,5.46-4.78,9.5-4.5c9.59,0.68,20.46,9.94,22.53,19.15c0.42,1.89,1.31,3.68,1.98,5.51
				C1091.24,1351.02,1091.04,1351.69,1090.84,1352.36z"/>
			<path onClick={() => props.onFill(162)} fill={props.fillColors[162]} d="M1159.59,1023.7c-2.52,1.53-5.08,3.02-7.57,4.61c-15.05,9.65-31.82,14.6-49.11,18.26
				c-4.68,0.99-9.27,2.53-13.81,4.08c-1.49,0.51-3.12,1.56-3.94,2.84c-0.85,1.33-1.59,3.76-0.93,4.76c0.69,1.05,3.26,1.64,4.72,1.27
				c11.87-3,23.67-6.27,35.49-9.44c0.27-0.07,0.61,0.11,0.92,0.17c0.14,0.44,0.29,0.89,0.43,1.33c-9.67,2.99-19.28,6.22-29.04,8.9
				c-7.85,2.15-15.76,1.55-23.58-0.84c-3.62-1.1-5.68-3.33-6.28-6.93c-0.57-3.4,0.48-6.11,3.5-8.15c2.04-1.38,3.72-3.27,5.67-5.03
				c1.69,0.53,3.82,1.21,5.53,1.75c-0.64,1.55-1.35,3.28-2.06,5.01c1.96,0.59,3.98,1.81,5.87,1.64c5.09-0.46,10.23-1.11,15.17-2.4
				c18.78-4.9,37.27-10.64,54.02-20.81c2.24-1.36,4.08-3.36,6.11-5.06c0,0-0.1,0.06-0.1,0.06c0.03,0.91,0.05,1.82,0.08,2.73
				C1160.31,1022.86,1159.95,1023.28,1159.59,1023.7z"/>
			<path onClick={() => props.onFill(163)} fill={props.fillColors[163]} d="M616.24,1138.71c5.58,4.17,11.19,8.3,16.72,12.53c1.61,1.23,3,2.76,4.91,4.54
				c-5.28,2.92-10.27,2.48-14.65,1.12c-6.92-2.14-13.82-4.86-20.13-8.4c-9.98-5.62-11.87-10.76-9.81-21.91l-0.13,0.15
				c3.35,2.04,6.72,4.06,10.06,6.14c3.82,2.38,7.59,4.83,11.45,7.14C614.85,1140.13,615.69,1139.17,616.24,1138.71z"/>
			<path onClick={() => props.onFill(164)} fill={props.fillColors[164]} d="M1127.6,1344.08c-3.88-6.73-12.19-6.41-16.74,0.16c-1.53,2.21-4.08,4.32-6.58,5.01
				c-1.55,0.43-4.54-1.47-5.6-3.14c-0.83-1.3-0.26-4.22,0.7-5.81c5.5-9.09,25.84-16.89,35.91-13.7c2.31,0.73,4.66,2.62,6.05,4.63
				c2.22,3.22,1.58,6.34-2.43,8.06C1135.25,1340.86,1131.58,1342.4,1127.6,1344.08z"/>
			<path onClick={() => props.onFill(165)} fill={props.fillColors[165]} d="M380.45,325.98c-1.84,5.63-6.8,5.24-10.78,5.73c-3.92,0.48-6.51,1.82-8.07,5.46
				c-1.76,4.11-5.55,5.95-9.39,4.88c-11.21-3.1-21.81,0.19-32.75,1.5c-8.92,1.07-18.15-0.25-27.22-0.85
				c-1.59-0.11-3.08-1.89-4.19-4.06c6.69,0,13.38,0,20.07,0c5.49,0,11.03,0.47,16.45-0.14c5.51-0.62,10.9-2.29,16.34-3.53
				c1.6-0.37,3.16-0.91,4.76-1.27c1.78-0.4,3.7-0.39,5.35-1.06c5.73-2.33,11.73-4.29,16.93-7.53
				C372.61,322.21,376.14,323.04,380.45,325.98z"/>
			<path onClick={() => props.onFill(166)} fill={props.fillColors[166]} d="M1142.31,1299.35c-0.61,7.2-5.43,14-12.61,18.94c-1.96,1.35-5.94,1.3-8.23,0.24
				c-3.08-1.43-5.61-4.59-4.46-8.56c1-3.42,2.5-6.75,4.22-9.88c1.51-2.73,3.63-5.11,5.49-7.64c2.4-3.26,4.72-6.6,9.69-4.79
				C1141.08,1289.35,1142.48,1292.91,1142.31,1299.35z"/>
			<path onClick={() => props.onFill(167)} fill={props.fillColors[167]} d="M317.4,776.11c0.32,1.59,0.84,3.01,0.84,4.44c0.07,21.12,1.19,42.31-0.24,63.33
				c-1.26,18.65-4.95,37.18-8.35,55.62c-2.37,12.86-5.74,25.57-9.25,38.19c-2.41,8.66-7.21,16.05-14.99,21.09
				c-0.83,0.54-1.66,1.09-2.53,1.56c-0.25,0.14-0.64,0.02-1.93,0.02c0.82-1.77,1.11-3.69,2.22-4.57
				c15.05-11.91,20.19-28.94,23.21-46.59c2.59-15.17,3.54-30.63,5.21-45.96c1.17-10.73,2.72-21.44,3.37-32.21
				C316.08,812.82,316.61,794.58,317.4,776.11z"/>
			<path onClick={() => props.onFill(168)} fill={props.fillColors[168]} d="M1058.15,1152.6c7.61,5.26,15.04,10.49,22.6,15.5c1.15,0.76,3.56,1.28,4.37,0.63
				c3.51-2.82,6.03-0.7,8.92,0.98c7.46,4.32,14.91,8.67,22.56,12.62c7.08,3.65,14.42,6.8,22.05,10.36c-7.4,7.73-7.92,7.84-15.53,2.97
				c-8.51-5.43-16.78-11.27-25.5-16.34c-9.68-5.63-19.66-10.76-29.67-15.77C1061.19,1160.18,1057.89,1156.79,1058.15,1152.6z"/>
			<path onClick={() => props.onFill(169)} fill={props.fillColors[169]} d="M1161.99,854.02c-0.85,3.03-1.31,6.24-2.63,9.05c-2.75,5.85-5.49,11.79-9.08,17.13
				c-7.93,11.82-15.73,23.82-24.81,34.73c-6.89,8.28-15.68,14.98-23.63,22.38c-0.43-0.24-0.86-0.48-1.3-0.72
				c0.38-4.86,0.76-9.73,1.19-15.13c17.92-3.46,26.49-18.39,37.1-30.89c9.12-10.75,15.23-23.28,21.1-35.94
				c0.18-0.39,0.66-0.64,1-0.96C1161.28,853.78,1161.64,853.9,1161.99,854.02z"/>
			<path onClick={() => props.onFill(170)} fill={props.fillColors[170]} d="M548.2,1067.89c-0.7,7.41-1.03,15.33-2.3,23.09c-0.96,5.89-10,11.2-15.51,9.23
				c-1.81-0.65-3.65-3.67-3.87-5.78c-0.53-5.08-0.26-10.26,0.02-15.39c0.06-1.13,1.22-2.73,2.28-3.19
				C535.08,1073.1,541.47,1070.62,548.2,1067.89z"/>
			<path onClick={() => props.onFill(171)} fill={props.fillColors[171]} d="M538.8,1244.41c-3.95,0-7.47,0-11.37,0c1.59-2.02,2.82-3.55,4-5.12c3.47-4.58,0.85-9.8-0.22-13.81
				c-1.54-5.76-6.11-10.71-9.44-15.98c-0.23-0.37-0.81-0.75-1.23-0.75c-7.54-0.09-13.48-4.78-20.28-6.92
				c-2.39-0.75-5.37,0.58-8.08,0.56c-1.22-0.01-2.43-1.01-3.65-1.56c0.5-0.77,0.84-1.94,1.53-2.22c1.54-0.63,4.38-1.59,4.74-1.07
				c2.63,3.86,6.73,1.55,9.89,2.77c2.33,0.9,4.63,1.96,7.04,2.6c3.47,0.92,7.01,2.06,10.55,2.14c7.3,0.18,16.29,8.36,17,15.58
				c0.49,5.02,2.21,9.95,2.39,14.95C541.77,1238.42,539.87,1241.32,538.8,1244.41z"/>
			<path onClick={() => props.onFill(172)} fill={props.fillColors[172]} d="M273.74,1426.35c2.32-0.19,4.15-0.65,5.86-0.39c1.57,0.24,3.44,0.87,4.43,2c2.08,2.36,3.43,1.68,5.27-0.19
				c2.14-2.17,4.59-4.05,7.34-6.44c0.19,7.12,7.12,4.45,9.87,7.8c-1.44,2.04-3.12,4.43-5,7.11c-4.63-3.95-7.2-1.32-8.77,3.01
				c-6.59-2.78-8-2.18-10.13,4.25c-3.2-1.86-6.09-2.3-9.46,0.38c-1.4,1.11-4.77-0.25-7.61-0.53c0.67-1.44,1.33-2.86,2.1-4.51
				c-4.13-1.54-5.82-4.25-3.65-8.31c1.54,0.38,3.84,1.7,4.88,1.04C271.03,1430.19,272.51,1427.75,273.74,1426.35z"/>
			<path onClick={() => props.onFill(173)} fill={props.fillColors[173]} d="M1077.02,1289.91c16.93,2.73,31.54,18.41,29.68,31.33c-5.68-1.8-11.41-3.58-17.1-5.44
				c-1.09-0.35-2.59-0.74-3.02-1.57c-3.63-7.07-9.79-12.97-10.16-21.55C1076.38,1291.76,1076.81,1290.82,1077.02,1289.91z"/>
			<path onClick={() => props.onFill(174)} fill={props.fillColors[174]} d="M136.63,1325.55c0.81,1.43,1.62,2.87,2.8,4.96c-1.78-0.52-2.91-0.87-4.05-1.17
				c-1.05-0.28-2.12-0.5-3.81-0.89c1.88,4.37,3.48,8.1,5.31,12.36c-2.87,0.34-5.93,0.51-8.89,1.12c-3.05,0.63-6.02,1.7-9.48,2.72
				c0.8-4.03,1.53-7.68,2.4-12.08c-3.03,1.83-5.12,3.09-8.06,4.86c0.4-4.08,0.66-6.8,0.93-9.52c-0.52-0.1-1.05-0.19-1.57-0.29
				c-0.86,4.35-1.79,8.7-2.55,13.07c-0.91,5.21-1.93,5.97-6.98,5.4c1.09-3.89,2.41-7.82,3.26-11.86c1.08-5.15,6.67-9.6,11.89-9.89
				c1.38-0.08,2.69-2.48,3.91-3.93c1.02-1.21,1.84-2.57,3.56-3.61c-0.29,4.5-1.46,8.48,5.31,6.51c1.68-0.49,4.08,1.49,6.15,2.33
				C136.74,1325.64,136.63,1325.55,136.63,1325.55z"/>
			<path onClick={() => props.onFill(175)} fill={props.fillColors[175]} d="M1128.62,839.88c-2.96-1.54-5.76-2.92-8.49-4.43c-2.75-1.53-4.91-3.58-8.82-1.94
				c-3.36,1.41-8.53-3.68-8.42-7.5c0.16-5.6,0.49-11.2,0.77-17.29c8.01,3.81,15.89,6.74,19.88,15.15c0.85,1.78,3.06,2.94,4.69,4.33
				C1133.67,832.82,1133.74,833.55,1128.62,839.88z"/>
			<path onClick={() => props.onFill(176)} fill={props.fillColors[176]} d="M30.7,1097.04c-3.87,1.05-7.14-2.46-6.13-8.3c1.16-6.7,2.9-13.3,3.92-20.02c0.4-2.63-0.52-5.46-0.66-8.2
				c-0.04-0.85,0.24-1.98,0.81-2.55c4.74-4.7,5.28-10.91,6.26-16.96c0.71-4.42,1.5-8.84,2.02-13.28c0.53-4.46,2.83-7.26,8.08-8.94
				c-1.62,3.59-2.64,6.68-4.33,9.36c-2,3.17-1.37,5.39,1.14,7.81c4.11,3.96,4.72,10,0.39,13.56c-7.61,6.25-9.35,14.67-10.21,23.5
				C31.21,1080.89,31.11,1088.82,30.7,1097.04z"/>
			<path onClick={() => props.onFill(177)} fill={props.fillColors[177]} d="M535.73,706.11c-3.39-11.52-2.42-13.44,8.64-16.97c2.82-0.9,5.73-1.53,9.31-2.47
				c-1.3,4.1,0.84,4.01,3.15,3.25c4.73-1.55,9.32-3.63,14.13-4.81c8.42-2.06,16.96-3.6,25.45-5.36c0.11,0.41,0.22,0.83,0.33,1.24
				c-4.78,2.91-9.54,5.84-14.34,8.69c-0.83,0.49-1.85,0.92-2.78,0.92c-15.75,0.05-29.37,6.12-42.12,14.73
				C536.99,705.69,536.34,705.85,535.73,706.11z"/>
			<path onClick={() => props.onFill(178)} fill={props.fillColors[178]} d="M1131.75,558.85c0.89-4.9,2.08-9.78,2.51-14.72c0.14-1.58-1.37-4.39-2.68-4.78
				c-2.82-0.84-3.06-2.42-3.14-4.75c-0.25-7.89-3.97-10.75-11.75-9.31c3.04-8.73,6.09-17.49,9.14-26.25
				c0.29,0.12,0.58,0.24,0.86,0.36c-0.21,2.82-0.68,5.65-0.58,8.46c0.18,5.41,4.43,8.41,8.37,10.24c4.44,2.06,4.01,4.79,3.73,8.37
				c-0.24,3.05,0.46,6.19,0.11,9.22c-0.8,7.05-1.95,14.06-3.07,21.07c-0.14,0.88-1.02,1.64-1.55,2.45
				C1133.06,559.09,1132.41,558.97,1131.75,558.85z"/>
			<path onClick={() => props.onFill(179)} fill={props.fillColors[179]} d="M57.51,435.68c-3.91,9.42-9.23,16.84-18.35,21.73c-10.43,5.6-19.97,12.87-29.89,19.42
				c-2.05,1.36-4.09,2.74-6.21,4.16c-1.95-6.2-1.59-6.45,3.31-9.68c6.89-4.55,13.48-9.55,20.23-14.31c2.83-2,5.79-3.83,8.63-5.82
				c5.15-3.61,10.24-7.29,15.39-10.91C52.87,438.7,55.21,437.21,57.51,435.68z"/>
			<path onClick={() => props.onFill(180)} fill={props.fillColors[180]} d="M181.98,944.91c-5.61,1.34-10.86,2.18-15.79,3.93c-3.1,1.1-6.18,3.13-8.42,5.54
				c-2.75,2.95-4.62,6.72-7.13,10.54c-2.64-3.75-5.09-7.23-7.76-11.03c0.63-0.89,1.16-2.22,2.13-2.92c7.71-5.51,14.38-13,24.58-14.02
				C177.32,936.18,177.34,936.35,181.98,944.91z"/>
			<path onClick={() => props.onFill(181)} fill={props.fillColors[181]} d="M661.04,1489.61c1.48,2.66,2.96,5.33,4.73,8.51c-14.92,0-29.07,0-43.22,0c-0.16-4.54,3.85-9.32,9.11-10.78
				c6.6-1.83,12.98-1.51,19.34,1.54c2.8,1.34,6.2,1.42,9.33,2.07C660.56,1490.51,660.8,1490.06,661.04,1489.61z"/>
			<path onClick={() => props.onFill(182)} fill={props.fillColors[182]} d="M840.17,524.03c-1.46-9.44-3.18-18.86-4.17-28.35c-0.19-1.81,2.08-4.93,3.93-5.68
				c5.39-2.19,7.94-6.72,11.14-10.94c1.3-1.72,3.63-2.65,5.49-3.94c0.45,0.48,0.91,0.96,1.36,1.44c-3.45,7.64-7.09,15.2-10.25,22.96
				c-1.46,3.57-1.95,7.53-2.94,11.29c-1.19,4.52-2.44,9.02-3.66,13.53C840.77,524.23,840.47,524.13,840.17,524.03z"/>
			<path onClick={() => props.onFill(183)} fill={props.fillColors[183]} d="M1073.07,1292.63c1.95,4.02,4.55,8.05,5.91,12.46c3.05,9.93,9.96,14.13,19.71,15.23
				c2,0.23,4.09,1.59,5.68,2.96c1.34,1.15,2.01,3.07,2.98,4.65c-1.94,0.97-4.05,3.03-5.78,2.75c-11.65-1.88-21.62-6.62-26.7-18.25
				C1071.13,1303.88,1070.72,1297.43,1073.07,1292.63z"/>
			<path onClick={() => props.onFill(184)} fill={props.fillColors[184]} d="M186.48,1253.17c1.29-0.91,3.19-2.24,5.88-4.13c1.27,12.71,2.44,24.53,3.62,36.34
				c0.56-0.06,1.13-0.12,1.69-0.18c-0.9-10.8-1.81-21.61-2.76-33.04c4.27,9.93,7.63,19.39,6.58,29.84c-0.49,4.9-2.34,6.93-6.89,6.09
				c-3.39-0.63-6.96-1.23-6.38-6.75c0.72-6.92,0.36-13.96,0.15-20.95C188.29,1258.13,187.22,1255.89,186.48,1253.17z"/>
			<path onClick={() => props.onFill(185)} fill={props.fillColors[185]} d="M573.97,1071.53c-2.2-3.88-3.9-6.88-5.51-9.72c0.23-0.59,0.26-1.05,0.48-1.17
				c10.53-5.9,21.02-11.89,31.68-17.56c2.16-1.15,4.94-1.13,7.82-0.63C597.2,1051.93,585.97,1061.41,573.97,1071.53z"/>
			<path onClick={() => props.onFill(186)} fill={props.fillColors[186]} d="M1160.91,824.94c1.89,13.4-0.42,25.51-6.73,37.51c-7.56,14.4-16.53,27.51-27.7,39.21
				c7.22-12.15,15.58-23.79,21.33-36.59C1153.53,852.36,1156.62,838.47,1160.91,824.94z"/>
			<path onClick={() => props.onFill(187)} fill={props.fillColors[187]} d="M855.69,440.73c-4.24-7.83-8.58-15.61-12.66-23.51c-1.66-3.21-0.89-6.13,2.45-8.03
				c0.29-0.16,0.62-0.27,0.87-0.48c4.82-4.1,4.57-3.95,8.01,1.92c5.61,9.55,4.45,19.5,3.35,29.52
				C857.04,440.34,856.37,440.54,855.69,440.73z"/>
			<path onClick={() => props.onFill(188)} fill={props.fillColors[188]} d="M467.77,1034.37c3.9,14.3,14.89,19.8,28.29,23.11c0.05,0.41,0.36,1.25,0.2,1.99
				c-0.73,3.62-1.59,7.22-2.4,10.82c-3.7-1.53-7.83-2.46-11.03-4.72c-7.84-5.53-12.01-13.52-13.43-23
				C469,1039.82,468.33,1037.1,467.77,1034.37z"/>
			<path onClick={() => props.onFill(189)} fill={props.fillColors[189]} d="M1160.45,952.8c-0.25,0.19-0.49,0.39-0.74,0.59c-3.43,3.54-6.71,7.25-10.34,10.58
				c-4.33,3.98-8.84,7.8-13.51,11.38c-3.92,3-8.36,5.33-12.25,8.36c-9.48,7.4-20.45,11.76-31.36,17.04
				c-1.19-5.25,1.63-6.58,4.76-8.23c14.66-7.73,29.25-15.59,43.76-23.6c8.15-4.5,12.98-12.17,17.83-19.76
				c0.62-0.97,1.22-1.95,1.86-2.91c0.06-0.09,0.31-0.05,1.15-0.15C1161.21,948.46,1160.83,950.63,1160.45,952.8z"/>
			<path onClick={() => props.onFill(190)} fill={props.fillColors[190]} d="M1159.59,1023.7c0.36-0.42,0.73-0.84,1.09-1.27c-0.59,1.51-0.76,3.5-1.84,4.44
				c-13.67,11.97-28.77,21.23-46.95,24.79c-7.31,1.43-14.49,3.56-21.74,5.32c-1.33,0.32-2.72,0.42-4.09,0.62
				c-0.26-0.54-0.52-1.09-0.78-1.63c1.99-1.33,3.8-3.26,5.99-3.88c10.35-2.96,20.93-5.19,31.19-8.41c11.37-3.56,22.8-7.31,32.24-15.1
				C1156.47,1027.12,1157.97,1025.34,1159.59,1023.7z"/>
			<path onClick={() => props.onFill(191)} fill={props.fillColors[191]} d="M785.21,513.63c1.65,4.28,1.78,7.26-3.15,8.75c-1.3,0.39-2.29,2.1-3.24,3.34
				c-1.74,2.29-3.02,5.03-5.09,6.96c-2.38,2.22-2.63,4.05-0.48,6.28c2.53,2.63,5.2,5.12,7.69,7.78c1.09,1.16,2.8,2.7,2.67,3.89
				c-0.99,8.97-2.37,17.89-3.63,26.82c-0.83-0.01-1.67-0.01-2.5-0.02c-0.28-2.18-1.06-4.43-0.74-6.52c0.74-4.73,2.04-9.37,3.07-14.05
				c1.04-4.7,0.05-8.69-3.48-12.28c-3.48-3.54-6.43-7.6-9.61-11.43c-2.07-2.49-1.81-5.23,1.25-5.75c7.41-1.27,9.56-8.7,15.14-11.97
				C783.78,515.05,784.3,514.43,785.21,513.63z"/>
			<path onClick={() => props.onFill(192)} fill={props.fillColors[192]} d="M293.19,887.46c0.81-24.04,1.62-48.09,2.43-72.29c3.74-0.22,4.8,1.33,4.6,4.7
				c-1,16.25-1.68,32.53-2.8,48.77c-0.44,6.36-1.72,12.66-2.62,18.98C294.26,887.57,293.72,887.52,293.19,887.46z"/>
			<path onClick={() => props.onFill(193)} fill={props.fillColors[193]} d="M607.21,1487.12c-4.74-3.37-7.36-0.55-9.79,1.68c-1.92,1.76-3.68,3.96-4.77,6.31
				c-1.53,3.27-3.65,3.93-6.84,2.85c3.15-15.22,13.07-22.74,28.73-24.73C612.02,1478,609.68,1482.44,607.21,1487.12z"/>
			<path onClick={() => props.onFill(194)} fill={props.fillColors[194]} d="M972.23,650.91c-0.64-12.06-1.61-24.12-1.85-36.19c-0.42-20.63-0.61-41.26-0.42-61.89
				c0.08-8.79,1.31-17.58,6.81-26.15C969.22,568.46,971.27,609.59,972.23,650.91z"/>
			<path onClick={() => props.onFill(195)} fill={props.fillColors[195]} d="M309.93,1287.32c-6.29-1.13-12.64-2.03-18.83-3.56c-1.33-0.33-2.2-2.89-3.1-4.52
				c-0.81-1.45-1.42-3.02-2.02-4.57c-0.55-1.43-0.98-2.92-1.46-4.38c1.68-0.18,3.35-0.5,5.03-0.49c0.68,0,1.8,1.2,1.96,1.05
				c4.28-4.1,7.41,0.9,11.81,2.04c-1.7,0.96-3.41,1.92-5.69,3.21c5.01,3.95,8.99,7.09,12.96,10.23
				C310.36,1286.65,310.15,1286.98,309.93,1287.32z"/>
			<path onClick={() => props.onFill(196)} fill={props.fillColors[196]} d="M222.8,1264.4c-2.46-2.04-5.11-4.23-7.87-6.51c0.19-2.56,2.71-6.29-3.6-6.07
				c8.17-2.63,8.59-3.02,10.58-10.43c1.47,4.95,2.24,8.86,3.86,12.37c0.66,1.44,3.15,2.04,4.14,2.62c1.01,2.43,1.9,4.57,2.79,6.7
				c1.43-0.64,2.86-1.28,4.42-1.98c-0.08-0.1,0.3,0.19,0.25,0.32c-0.75,2.08,2.64,5.31-1.34,6.32c-3.36,0.85-4.59-1.66-6.01-4.45
				C227.42,1258.17,225.83,1264.01,222.8,1264.4z"/>
			<path onClick={() => props.onFill(197)} fill={props.fillColors[197]} d="M1109.06,1309.47c-3.17-5.19-6.63-10.49-9.72-16c-5.37-9.61-5.25-20.11-4.03-30.58
				c0.53-4.55,2.21-8.96,4-13.26c-6.91,31.09,4.59,52.13,15.53,56.09C1112.57,1307.2,1110.29,1308.67,1109.06,1309.47z"/>
			<path onClick={() => props.onFill(198)} fill={props.fillColors[198]} d="M34.08,1414.46c-4.87,0.93-9.73,1.87-15.48,2.97c1.46,1.55,2.24,2.37,3.83,4.05
				c-6.93,1.41-13.26,2.71-19.59,4c-1.97-8.31-1.66-9.02,6.31-10.1c8.2-1.1,16.45-1.78,24.68-2.64
				C33.91,1413.31,34,1413.88,34.08,1414.46z"/>
			<path onClick={() => props.onFill(199)} fill={props.fillColors[199]} d="M175.05,1254.84c1.04-1.41,2.08-2.81,3.93-5.31c1.96,12.73,3.74,24.22,5.5,35.67c-2.11,0-4.54,0-7.64,0
				c0-7.49,0.18-14.75-0.09-22c-0.1-2.85-1.2-5.66-1.84-8.48C174.92,1254.72,175.05,1254.84,175.05,1254.84z"/>
			<path onClick={() => props.onFill(200)} fill={props.fillColors[200]} d="M533.21,945.37c0-6.45-0.65-12.59,0.19-18.52c0.8-5.68,3.14-11.15,5.01-17.39
				C540.86,928.41,542.57,932.53,533.21,945.37z"/>
			<path onClick={() => props.onFill(201)} fill={props.fillColors[201]} d="M1037.81,801.15c-0.76-11.7-1.52-23.4-2.29-35.22c7.45,1,8.06,1.74,7.21,8.2
				c-1.18,8.97-2.36,17.94-3.53,26.91C1038.73,801.08,1038.27,801.12,1037.81,801.15z"/>
			<path onClick={() => props.onFill(202)} fill={props.fillColors[202]} d="M1035.55,1380.64c-2.57,0.54-5.4,1.13-7.74,1.62c0-16.69,0-33.11,0-49.52c0.47-0.02,0.94-0.03,1.42-0.05
				C1029.4,1348.44,1028.66,1364.34,1035.55,1380.64z"/>
			<path onClick={() => props.onFill(203)} fill={props.fillColors[203]} d="M465.91,797.82c4.21-1.42,7.21,0.35,8.47,4.45c2.38,7.75,5.18,15.5,6.35,23.46
				c1.58,10.79,1.79,21.78,1.69,32.79c-1.65-12.32-3.11-24.67-5.05-36.95c-0.71-4.46-2.54-8.74-3.94-13.07
				c-0.35-1.09-1.66-2.28-1.42-3.09C473.75,799.54,469.37,799.31,465.91,797.82z"/>
			<path onClick={() => props.onFill(204)} fill={props.fillColors[204]} d="M1054.32,1347.09c0.17-2.28,0.42-5.67,0.59-7.91c8.51,5.35,17.16,10.79,26.26,16.51
				c0.06-0.12-0.56,1.07-0.94,1.79C1071.84,1354.13,1063.54,1350.79,1054.32,1347.09z"/>
			<path onClick={() => props.onFill(205)} fill={props.fillColors[205]} d="M708.22,1414.97c-12.65-3.95-25.3-7.91-37.94-11.85C684.63,1400.72,697.26,1404.84,708.22,1414.97z"/>
			<path onClick={() => props.onFill(206)} fill={props.fillColors[206]} d="M1132.52,911.1c9.28-14.47,19.07-29.72,29.75-46.37c-1.18,4.64-1.17,8.63-3.05,11.26
				c-8.28,11.57-17.11,22.76-25.75,34.07C1132.99,910.7,1132.39,911.25,1132.52,911.1z"/>
			<path onClick={() => props.onFill(207)} fill={props.fillColors[207]} d="M174.92,1254.72c0,9.44,0,18.88,0,28.32c-0.13,0-0.26-0.01-0.39-0.01c-0.87-8.9-1.73-17.8-2.6-26.71
				c-0.61,0.05-1.21,0.11-1.82,0.16c0.69,10.23,1.37,20.45,2.06,30.68c-0.83,0.06-1.66,0.12-2.49,0.18
				c-0.66-11.12-1.32-22.24-1.97-33.12c2.23,0.18,4.79,0.4,7.35,0.61C175.05,1254.84,174.92,1254.72,174.92,1254.72z"/>
			<path onClick={() => props.onFill(208)} fill={props.fillColors[208]} d="M826.72,1428.87c-6.92-1.24-13.19-2.36-19.87-3.55c2.23-4.17,4.14-7.74,6.17-11.53
				c1.14,2.54,2.06,4.89,3.23,7.11c2.65,5.06,4.77,5.29,8.52,1.23C825.34,1424.11,825.94,1426.19,826.72,1428.87z"/>
			<path onClick={() => props.onFill(209)} fill={props.fillColors[209]} d="M1009.3,1435.19c0.69,2.53,1.37,5.07,2.06,7.6c0.27-0.09,0.54-0.19,0.81-0.28
				c-0.12,2.49-0.24,4.97-0.38,7.71c-1.92-0.35-4.24-0.78-6.88-1.26c0.12-3.34,2.87-7.85-3.37-8.84c-0.11-0.42-0.22-0.85-0.33-1.27
				c2.71-1.2,5.42-2.41,8.12-3.61L1009.3,1435.19z"/>
			<path onClick={() => props.onFill(210)} fill={props.fillColors[210]} d="M984.79,1075.9c2.46-0.44,4.51-0.81,6.64-1.19c-0.5,7.45-0.99,14.57-1.47,21.7
				c-0.35,0.08-0.7,0.16-1.04,0.25C987.57,1089.89,986.22,1083.11,984.79,1075.9z"/>
			<path onClick={() => props.onFill(211)} fill={props.fillColors[211]} d="M1026.09,1237.25c0.93-4.12,1.94-8.23,2.75-12.37c0.53-2.73,1.07-5.51,1.09-8.28
				c0.12-12.25,0.05-24.51,0.05-36.76c0.52,0,1.04-0.01,1.56-0.01c0,3.41,0.02,6.83,0,10.24c-0.07,10.27,0.17,20.55-0.36,30.79
				c-0.29,5.65-1.86,11.23-2.85,16.84C1027.59,1237.55,1026.84,1237.4,1026.09,1237.25z"/>
			<path onClick={() => props.onFill(212)} fill={props.fillColors[212]} d="M552.25,1068.47c4.27,11.31,2.18,25.52-3.65,29.16C549.77,1088.25,551.04,1078.12,552.25,1068.47z"/>
			<path d="M616.24,1138.71c-0.54,0.46-1.39,1.42-1.59,1.31c-3.86-2.31-7.63-4.76-11.45-7.14c-3.33-2.08-6.7-4.09-10.06-6.14
				c1.03-0.64,2.49-2.07,3.02-1.79c5.74,3.08,11.33,6.42,16.96,9.7C614.16,1136,615.2,1137.36,616.24,1138.71z"/>
			<path onClick={() => props.onFill(213)} fill={props.fillColors[213]} d="M540.39,1244.68c4.45-5.79,3.69-11,0.95-16.21c0.39-0.15,0.79-0.31,1.18-0.46
				c2.88,5.04,5.76,10.08,9.14,16C548.1,1244.22,544.97,1244.4,540.39,1244.68z"/>
			<path onClick={() => props.onFill(214)} fill={props.fillColors[214]} d="M959.89,957.79c-0.93,8.88-1.85,17.75-2.86,27.4c-2.27-0.82-3.54-1.27-3.6-1.29
				c1.91-9.11,3.71-17.67,5.51-26.24C959.26,957.7,959.57,957.74,959.89,957.79z"/>
			<path onClick={() => props.onFill(215)} fill={props.fillColors[215]} d="M166.72,1287.01c-2.76-0.13-2.74-0.14-3.85-5.74c-0.57-2.89-1.24-5.77-2.06-9.57
				c1.4,2.22,2.34,3.7,3.28,5.18c0.38-0.04,0.76-0.08,1.13-0.12c0-4.71,0-9.43,0-14.15c0.5,0.01,1,0.02,1.5,0.02
				C166.72,1270.64,166.72,1278.63,166.72,1287.01z"/>
			<path onClick={() => props.onFill(216)} fill={props.fillColors[216]} d="M1112.67,1326.5c-0.52-5.85-1.04-11.06-1.34-16.28c-0.02-0.4,1.55-0.89,2.44-1.37
				C1115.31,1314.78,1118.2,1320.5,1112.67,1326.5z"/>
			<path onClick={() => props.onFill(217)} fill={props.fillColors[217]} d="M134.85,1277.92c-2.17-4.6-4.22-8.96-6.27-13.32c0.35-0.37,0.71-0.75,1.06-1.12
				c1.72,1.91,3.7,3.64,5.1,5.76c2.24,3.38,2.49,7.2-0.13,10.39c-0.89,1.08-3.4,0.83-5.42,0.11
				C130.79,1279.22,132.39,1278.71,134.85,1277.92z"/>
			<path onClick={() => props.onFill(218)} fill={props.fillColors[218]} d="M162.43,1268.53c-1.44-5.15-2.87-10.3-4.56-16.34c-0.87,1.59-1.21,2.23-1.65,3.02
				c-0.47-1.46-0.9-2.81-1.36-4.25C162.17,1253.09,163.69,1251.99,162.43,1268.53z"/>
			<path onClick={() => props.onFill(219)} fill={props.fillColors[219]} d="M1123.47,935.74c-6.16,5.44-12.31,10.87-18.47,16.31c-0.4-0.49-0.79-0.98-1.19-1.46
				c4.25-7.89,13.07-9.96,19.75-14.76C1123.55,935.83,1123.47,935.74,1123.47,935.74z"/>
			<path onClick={() => props.onFill(220)} fill={props.fillColors[220]} d="M1161.69,890.8c-1.97,8.17-9.29,12.89-12.95,19.97c-0.34-0.22-0.68-0.44-1.02-0.66
				c4.27-6.58,8.53-13.15,12.8-19.73C1160.91,890.52,1161.3,890.66,1161.69,890.8z"/>
			<path onClick={() => props.onFill(221)} fill={props.fillColors[221]} d="M204.08,945.66c-3.92,6.27-3.92,6.27-12.17,2.1c3.77-1.27,7.79-2.62,11.82-3.97
				C203.84,944.41,203.96,945.03,204.08,945.66z"/>
			<path d="M643.04,1294.41c-1.16-1.59-2.41-3.13-3.42-4.81c-0.25-0.42,0.3-1.31,0.74-2.93c-0.3-0.42-1.3-1.79-2.29-3.16
				c5.7,3.29,8.38,8.3,8.41,14.71c0,0.23-0.79,0.46-1.21,0.69C644.53,1297.41,643.79,1295.91,643.04,1294.41z"/>
			<path onClick={() => props.onFill(222)} fill={props.fillColors[222]} d="M241.31,1267.43c3.54-1.93,7.29-3.97,11.96-6.51c-0.91,2.42-1.79,4.76-2.45,6.51
				C247.12,1267.43,244.08,1267.43,241.31,1267.43z"/>
			<path onClick={() => props.onFill(223)} fill={props.fillColors[223]} d="M1033.84,1063.61c1.33-0.59,2.92-1.29,5.31-2.35c-1.47,5.18-2.7,9.49-3.96,13.93
				C1034.76,1071.48,1034.34,1067.91,1033.84,1063.61z"/>
			<path onClick={() => props.onFill(224)} fill={props.fillColors[224]} d="M208.74,1269.61c-1.53,5.53-3.06,11.06-4.6,16.59c-0.58-0.17-1.17-0.34-1.75-0.51
				c1.6-5.47,3.21-10.94,4.81-16.41C207.72,1269.38,208.23,1269.5,208.74,1269.61z"/>
			<path onClick={() => props.onFill(225)} fill={props.fillColors[225]} d="M5.48,1364.02c-1.45-4.71-2.9-9.43-4.35-14.14c0.65-0.2,1.3-0.4,1.96-0.6c1.4,4.73,2.79,9.46,4.19,14.2
				C6.68,1363.65,6.08,1363.84,5.48,1364.02z"/>
			<path onClick={() => props.onFill(226)} fill={props.fillColors[226]} d="M275.58,927.01c-6.27-0.95-7.01-1.96-7.16-11.29C271.2,920.11,273.35,923.49,275.58,927.01z"/>
			<path onClick={() => props.onFill(227)} fill={props.fillColors[227]} d="M208.68,1247.72c-0.6,5.44-1.21,10.88-1.81,16.32c-0.35-0.02-0.7-0.04-1.05-0.06
				c0.32-5.43,0.64-10.86,0.96-16.3C207.42,1247.69,208.05,1247.71,208.68,1247.72z"/>
			<path onClick={() => props.onFill(228)} fill={props.fillColors[228]} d="M184.9,1280.21c0.44-6.48,0.88-12.96,1.35-19.9c0,6.95,0,13.45,0,19.96
				C185.8,1280.25,185.35,1280.23,184.9,1280.21z"/>
			<path onClick={() => props.onFill(229)} fill={props.fillColors[229]} d="M1086.9,917.12c-0.83,8.5-1.66,17-2.5,25.51c-0.27-0.02-0.53-0.05-0.8-0.07
				c0.79-8.51,1.59-17.01,2.38-25.52C1086.28,917.07,1086.59,917.1,1086.9,917.12z"/>
			<path onClick={() => props.onFill(230)} fill={props.fillColors[230]} d="M783.27,1420.25c-3.07,0.74-4.28,6.85-10.54,1.65c4.04-1.29,7.03-2.25,10.03-3.21
				C782.93,1419.21,783.1,1419.73,783.27,1420.25z"/>
			<path onClick={() => props.onFill(231)} fill={props.fillColors[231]} d="M157.5,627.72c3.74-5.73,7.49-11.46,11.23-17.19c0.42,0.29,0.84,0.57,1.26,0.86
				c-4.04,5.55-8.07,11.1-12.11,16.66C157.88,628.04,157.5,627.72,157.5,627.72z"/>
			<path onClick={() => props.onFill(232)} fill={props.fillColors[232]} d="M1009.34,1435.23c3.84-4.45-1.68-4.76-3.31-7.33c0.79-0.6,1.66-1.27,2.5-1.9c1.84,2.1,3.75,4.26,6.42,7.3
				c-2.17,0.72-3.9,1.31-5.64,1.89C1009.3,1435.19,1009.34,1435.23,1009.34,1435.23z"/>
			<path onClick={() => props.onFill(233)} fill={props.fillColors[233]} d="M805.12,1478.48c3.67-1.2,6.89-8.42,11.41-1.73C812.74,1477.33,808.93,1477.91,805.12,1478.48z"/>
			<path onClick={() => props.onFill(234)} fill={props.fillColors[234]} d="M92.46,1341.24c0.6,1.46,1.2,2.92,1.8,4.38c0.38-0.09,0.76-0.18,1.14-0.27c-0.44-2.2-0.88-4.39-1.33-6.59
				c0.2-0.06,0.4-0.13,0.6-0.19c0.87,2.69,1.73,5.38,2.72,8.44C91.34,1348.27,93.35,1343.42,92.46,1341.24z"/>
			<path onClick={() => props.onFill(235)} fill={props.fillColors[235]} d="M88.56,1348.28c-2.05-3.79-4.1-7.59-6.14-11.38c0.36-0.22,0.71-0.43,1.07-0.65
				c2.3,3.73,4.59,7.45,6.89,11.18C89.77,1347.71,89.17,1348,88.56,1348.28z"/>
			<path onClick={() => props.onFill(236)} fill={props.fillColors[236]} d="M1123.55,935.83c4.16-4,8.33-8.01,12.49-12.01c0.23,0.27,0.46,0.53,0.69,0.8
				c-4.42,3.71-8.84,7.42-13.26,11.13C1123.47,935.74,1123.55,935.83,1123.55,935.83z"/>
			<path onClick={() => props.onFill(237)} fill={props.fillColors[237]} d="M203.62,1271.15c-1.45-4.61-2.91-9.22-4.36-13.83c0.33-0.11,0.67-0.22,1-0.34
				c1.57,4.63,3.14,9.26,4.71,13.88C204.52,1270.97,204.07,1271.06,203.62,1271.15z"/>
			<path onClick={() => props.onFill(238)} fill={props.fillColors[238]} d="M828.35,1417.53c1.47-2.58,2.92-5.12,4.6-8.07C835.17,1414.8,832.06,1416.33,828.35,1417.53z"/>
			<path onClick={() => props.onFill(239)} fill={props.fillColors[239]} d="M201.6,1256.87c-0.8-2.4-1.4-4.18-2.1-6.28c1.85-0.5,3.29-0.88,5.07-1.36
				C203.59,1251.75,202.79,1253.8,201.6,1256.87z"/>
			<path onClick={() => props.onFill(240)} fill={props.fillColors[240]} d="M393.18,889.1c-1.22,2.35-2.43,4.71-3.65,7.06c-0.53-0.27-1.06-0.55-1.59-0.82
				c1.12-2.4,2.25-4.79,3.37-7.19C391.93,888.47,392.55,888.78,393.18,889.1z"/>
			<path onClick={() => props.onFill(241)} fill={props.fillColors[241]} d="M129.12,1475.29c1.75,0.9,3.52,1.76,5.2,2.76c0.14,0.08-0.25,1.08-0.4,1.66
				c-1.93-0.78-3.85-1.57-5.78-2.35C128.47,1476.66,128.8,1475.97,129.12,1475.29z"/>
			<path onClick={() => props.onFill(242)} fill={props.fillColors[242]} d="M373.19,1201.27c-1.71-1.79-3.42-3.58-5.13-5.37c0.34-0.32,0.67-0.64,1.01-0.96
				c1.72,1.79,3.45,3.58,5.17,5.37C373.9,1200.64,373.55,1200.95,373.19,1201.27z"/>
			<ellipse onClick={() => props.onFill(243)} fill={props.fillColors[243]} cx="955.24" cy="263.18" rx="0.23" ry="10.71"/>
			<path onClick={() => props.onFill(244)} fill={props.fillColors[244]} d="M853.65,938.51c-1.51-3.91-3.02-7.81-4.52-11.72c0.29-0.12,0.59-0.24,0.88-0.36
				c1.59,3.92,3.18,7.85,4.76,11.77C854.39,938.31,854.02,938.41,853.65,938.51z"/>
			<path onClick={() => props.onFill(245)} fill={props.fillColors[245]} d="M81.97,1351.33c-5.8,0.16-4-3.79-4.37-6.38C78.92,1346.87,80.24,1348.79,81.97,1351.33z"/>
			<path onClick={() => props.onFill(246)} fill={props.fillColors[246]} d="M2.26,1368.92c0-2.26,0-4.51,0-6.77c0.38-0.05,0.77-0.1,1.15-0.14c0.3,2.28,0.61,4.57,0.91,6.85
				C3.64,1368.88,2.95,1368.9,2.26,1368.92z"/>
			<path onClick={() => props.onFill(247)} fill={props.fillColors[247]} d="M30.77,1324.69c-1.66-3.73-3.32-7.46-4.98-11.19c0.34-0.15,0.68-0.29,1.02-0.44
				c1.45,3.77,2.89,7.55,4.34,11.32C31.02,1324.48,30.89,1324.59,30.77,1324.69z"/>
			<path d="M1099.16,1387.97c-6.24,1.23-7.57,0.06-6.69-5.69c0.14-0.88,0.83-2.32,1.4-2.4c3.78-0.49,7.61-0.68,11.66-0.99
				c-0.84,5.89,1.48,8.03,8.2,8.86c-0.63,5.75-0.47,5.89,7.66,6.61c0.13,1.34,0.27,2.75,0.41,4.18c4.93-1.41,5.26-0.97,6.02,8.11
				c0.89-0.5,1.59-1.19,2.32-1.24c0.99-0.06,2.21,0.13,2.94,0.7c0.37,0.29,0.24,1.95-0.23,2.5c-2.07,2.37-4.3,4.61-6.59,6.77
				c-0.49,0.46-2.01,0.75-2.19,0.5c-3.09-4.33-11.21-3.49-10.75-10.91C1108.41,1405.01,1099.24,1393.85,1099.16,1387.97z
				M1119.12,1397.3c-2.89-1.61-6.27-2.34-7.09-4.19c-1.95-4.44-5.51-5.7-9.74-6.97c0.14-1.75,0.29-3.54,0.39-4.72
				c-2.74,0.42-5.23,0.8-8.43,1.3c1.43,1.04,2.65,1.85,3.78,2.76c1.2,0.97,2.56,1.88,3.44,3.1c3.73,5.16,5.01,12.31,12.31,14.58
				c0.96,0.3,1.31,2.41,2.06,3.62c0.84,1.36,2.42,3.87,2.64,3.76c4.03-2,4.25,2.44,5.85,3.58c1.93-1.71,3.6-3.19,5.61-4.97
				c-6.75-0.61-3.64-5.75-4.89-9.2C1120.41,1403.23,1119.4,1400.12,1119.12,1397.3z"/>
			<path d="M477.48,534.25c-23.89,23.97-45.18,50.58-73.67,72.1c0.7-2.33,0.79-3.38,1.28-4.17c2.54-4.08,5.08-8.18,7.83-12.13
				c2.69-3.86,5.57-7.17,5.86-12.61c0.19-3.56,3.46-7.58,6.38-10.27c9.76-9,19.91-17.58,30.11-26.08c1.55-1.29,4.15-1.34,6.26-1.96
				c3.75-1.11,7.53-2.11,11.22-3.4c1.45-0.5,2.68-1.64,4.01-2.48C477,533.59,477.24,533.92,477.48,534.25z M410.16,597.23
				c0.48,0.4,0.95,0.81,1.43,1.21c18.78-18.98,37.57-37.97,56.35-56.95c-4.16-0.22-8.7-0.64-11.29,1.42
				c-10.78,8.55-21.09,17.72-31.29,26.98c-2.19,1.99-4.59,5.33-4.49,7.95c0.2,5.17-2.46,8.3-5.26,11.77
				C413.66,592.04,411.97,594.69,410.16,597.23z"/>
			<path d="M349.64,666.5c8.99-10.82,17.98-21.64,26.97-32.45c0.11-0.13,0.22-0.28,0.37-0.33c8.45-3.38,15.14-8.89,21.38-15.56
				c2.58-2.76,8.21-2.66,12.78-3.39c-17.06,21.31-41.17,34.34-60.52,52.9C350.29,667.28,349.97,666.89,349.64,666.5z M362.92,653.23
				c0.27,0.28,0.54,0.56,0.8,0.84c14.08-11.54,28.16-23.08,42.24-34.63c-0.32-0.46-0.64-0.93-0.97-1.39
				c-2.27,0.79-5.37,0.9-6.67,2.49c-5.27,6.45-11.17,11.73-19.4,13.96c-0.58,0.16-1.12,0.73-1.53,1.23
				C372.55,641.55,367.74,647.4,362.92,653.23z"/>
			<path d="M996.45,520.64c6.29,22.16,6.73,44.99,7.4,67.73c1.3,43.74,2.62,87.48,3.02,131.23c0.18,19.42-2.24,38.85-2.79,58.3
				c-0.63,22.29-0.57,44.61-0.64,66.91c-0.09,28.96,0.4,57.93-0.16,86.88c-0.32,16.59-2.23,33.16-3.45,49.73
				c-0.05,0.73-0.35,1.44-0.53,2.15c-0.41-0.03-0.82-0.06-1.24-0.09c0.24-4.57,0.18-9.19,0.78-13.72c4.08-30.73,2.5-61.33-0.67-92.06
				c-1.77-17.16-1.74-34.58-1.56-51.87c0.33-32.44,1.1-64.89,2.17-97.31c0.92-27.93,2.96-55.83,3.6-83.76
				c0.26-11.21-2.04-22.46-2.91-33.72c-1.4-18.07-2.74-36.14-3.79-54.23c-0.62-10.63-0.69-21.29-0.82-31.93
				c-0.02-1.36,1.11-2.74,1.71-4.12C996.57,520.77,996.45,520.64,996.45,520.64z M1001.59,890.46c0-0.82,0-1.63,0-2.45
				c0.1-50.25-0.12-100.47,2.84-150.71c1.81-30.64,0.33-61.48,0.33-92.23C1001.87,726.82,992.95,808.47,1001.59,890.46z
				M1002.01,625.84c0.44-0.03,0.88-0.06,1.32-0.09c1.52-14.71-3.5-86.1-7.35-92.32C997.99,564.23,1000,595.03,1002.01,625.84z"/>
			<path d="M909,1012.33c-10.87-3.13-20.69-8.31-27.65-16.87c-15.38-18.93-30.3-38.31-39.1-61.49c-0.86-2.27-1.65-4.57-2.67-7.38
				c10.49,15.85,20.53,31.18,30.73,46.39C880.72,988.51,892.58,1002.67,909,1012.33z M875.59,984.99c3.83,6.42,8.36,12.17,16.1,16.84
				c-5.84-6.49-10.71-11.9-15.57-17.32c-4.99-7.01-9.97-14.01-14.96-21.02c-0.45,0.34-0.9,0.67-1.35,1.01
				C865.07,971.34,870.33,978.17,875.59,984.99z"/>
			<path d="M1045.34,1027.13c-1.9,3.26-3.5,6.75-5.77,9.74c-7.05,9.28-17.08,13.28-28.15,15.32c-13.73,2.53-27.38,2.57-40.85-1.45
				c-2.46-0.73-4.83-2.13-6.91-3.67c-0.99-0.74-1.87-2.76-1.55-3.8c0.28-0.88,2.42-1.72,3.66-1.63c3.95,0.29,8,0.56,11.76,1.67
				c13.5,3.96,27.01,2.88,40.37-0.05c9.97-2.19,18.21-7.76,24.7-15.76c0.38-0.47,0.99-0.76,1.5-1.14
				C1044.52,1026.61,1044.93,1026.87,1045.34,1027.13z M1036.44,1036.77c-5.02,2.47-9.95,5.84-15.41,7.41
				c-14.21,4.08-28.64,5.23-43.2,1.06c-4.41-1.26-9.08-1.59-14.7-2.53C973.04,1058.38,1025.6,1052.76,1036.44,1036.77z"/>
			<path d="M1041.89,1053.19c-2.58,1.81-4.92,4.42-7.79,5.31c-16.02,4.93-32.06,10.7-49.13,9.09c-6.72-0.63-13.39-3.35-19.7-6.03
				c-2.83-1.2-4.71-4.64-7.01-7.06c0.31-0.55,0.61-1.09,0.92-1.64c1.77,0.25,3.75,0.06,5.27,0.82c25.24,12.61,50.18,9.3,75.04-1.19
				c0.54-0.23,1.16-0.28,1.74-0.41C1041.44,1052.44,1041.66,1052.81,1041.89,1053.19z M961.01,1053.91
				c7.15,13.46,33.03,13.93,48.45,8.85C992.64,1065.73,976.82,1062.58,961.01,1053.91z"/>
			<path d="M1035.81,1011.77c-3.99,9.91-10.85,15.53-19.09,19.3c-14.78,6.77-30.16,8.89-45.95,3.76c-2.41-0.78-4.59-2.53-6.58-4.2
				c-0.78-0.66-1.33-2.62-0.9-3.43c0.46-0.88,2.24-1.67,3.31-1.52c3.09,0.45,6.11,1.39,9.15,2.16c21.92,5.53,41.3,1.05,57.82-14.7
				C1033.89,1012.82,1034.36,1012.64,1035.81,1011.77z M1025.32,1023.02c-18.62,8.72-36.31,12.44-55.01,5.15
				c-1.6-0.62-3.51-0.42-5.99-0.67C972.84,1040.15,1008.98,1037.33,1025.32,1023.02z"/>
			<path d="M921.37,1020.84c-7.01,4.45-14.41,4.61-21.34,1.05c-15.44-7.93-27.55-19.51-35.66-35.04c-0.27-0.51-0.29-1.14-0.37-1.49
				c8.4,8.08,16.27,16.85,25.36,24.07C898.45,1016.65,908.95,1021.77,921.37,1020.84z M886.91,1011.51
				c8.44,6.07,15.77,11.78,24.72,11.1C904.31,1019.32,896.12,1015.65,886.91,1011.51z"/>
			<path d="M880.85,940.65c10.14,20.27,25.57,36.21,42.82,52.01C909.36,989.59,889.47,965.28,880.85,940.65z"/>
			<path d="M886.53,967.89c-11.86-12.65-24.21-24.96-28.87-42.53C870.26,937.48,878.37,952.71,886.53,967.89z M876.2,955.43
				c0.54-0.38,1.09-0.76,1.63-1.15c-4.57-6.62-9.14-13.24-13.72-19.86c-0.56,0.39-1.12,0.79-1.69,1.18
				C867.02,942.21,871.61,948.82,876.2,955.43z"/>
			<path d="M940.65,1037.55c-7.02-6.3-9.07-21.25-4.73-28.56c1.24-2.08,1.74-4.76,2.01-7.22c0.5-4.64,0.59-9.33,1.85-13.9
				c0.32,5.47,0.67,10.93,0.95,16.4c0.05,0.98,0.15,2.18-0.33,2.91c-6.25,9.48-4.22,18.84,0.23,28.2
				C940.81,1035.76,940.65,1036.3,940.65,1037.55z"/>
			<path d="M760.42,650.14c-2.1-7.82-4-15.69-6.41-23.42c-0.72-2.32-2.58-4.43-4.32-6.25c-6.87-7.15-13.93-14.13-20.83-21.25
				c-0.57-0.59-0.99-2.04-0.66-2.67c5.9-11.35,1.74-22.38-0.87-33.47c-0.11-0.45-0.01-0.94-0.01-2.9
				c7.02,8.49,13.67,15.53,16.56,25.37c1.11,3.8,3.21,7.58,5.73,10.65c7.94,9.69,12.94,20.71,13.91,33.02
				c0.55,6.9-1.35,14-2.14,21.01C761.05,650.2,760.74,650.17,760.42,650.14z M732.25,567.46c-0.72,0.26-1.44,0.52-2.17,0.78
				c4.43,9.15,4.46,18.28,0.61,27.49c-0.35,0.83-0.04,2.44,0.58,3.1c6.49,6.81,13.18,13.42,19.64,20.25
				c1.97,2.08,3.92,4.47,4.94,7.08c1.7,4.32,2.64,8.95,4.21,14.55c3.3-15.47-2.32-32.89-13.28-44.56c-2.15-2.29-3.46-5.45-4.88-8.34
				C738.61,581.07,735.46,574.25,732.25,567.46z"/>
			<path d="M467.89,615.5c3.25-3.92,6.11-8.27,9.83-11.67c8.58-7.84,15.17-17.88,26.67-22.59c5.38-2.2,9.83-6.72,14.65-10.26
				c4.59-3.37,9.12-6.82,13.52-10.12c1.64,4.2-0.88,13.61-4.62,17.2c-8.14,7.81-16.06,15.85-24.11,23.75
				c-1.52,1.49-3.01,3.37-4.89,4.07c-9.99,3.76-20.1,7.19-30.17,10.74C468.47,616.24,468.18,615.87,467.89,615.5z M530.99,567.38
				c-0.44-0.25-0.88-0.51-1.33-0.76c-20.1,13.48-41.77,25.02-57.1,46.51c9.38-3.3,17.62-6.09,25.76-9.17
				c1.57-0.59,2.79-2.23,4.06-3.5c7.99-7.96,16.11-15.79,23.8-24.03C528.44,574.01,529.42,570.42,530.99,567.38z"/>
			<path d="M789.54,602.31c-1.21,0.46-1.62,0.76-1.79,0.66c-8.07-4.74-16.42-9.09-23.99-14.53c-2.38-1.71-4.17-6.68-3.55-9.59
				c1.27-5.94-0.47-10.78-2.63-15.83c-0.89-2.09-1.74-4.2-2.74-6.62c5.06,1.36,11.36,7.7,13.31,13.35c2.97,8.65,7.82,16.05,14,22.73
				C784.85,595.42,786.99,598.89,789.54,602.31z M760.82,563.08c0.33,4.96,0.49,11.09,1.25,17.14c0.3,2.44,1.26,5.62,3.04,6.9
				c6.16,4.44,12.82,8.2,17.98,11.4C775.78,586.88,767.87,574.3,760.82,563.08z"/>
			<path d="M566.38,1147.54c-2.07,4.27-2.93,9.64-8.68,11.32c-0.79,0.23-1.44,1.36-1.9,2.22c-2.5,4.58-4.92,9.21-7.23,13.57
				c-1.79-3.76,0.3-9.47,3.7-12.51C557.29,1157.63,561.7,1152.44,566.38,1147.54z"/>
			<path d="M813.4,1322.11c-0.56,10.25-1.13,20.62-1.67,30.37C807.77,1347.91,808.4,1330.97,813.4,1322.11z"/>
			<path d="M796.66,1349.93c6.09,5.22,12.23,10.4,18.26,15.69c3.07,2.69,3.49,4.7,1.96,9.64c0.26-8.28-7.08-9.91-11.4-14.22
				c-3.35-3.34-6.23-7.15-9.32-10.75C796.34,1350.17,796.5,1350.05,796.66,1349.93z"/>
			<path d="M770.65,1345.63c-4.66-6.61-12.07-11.43-12.2-20.93c0.98,2.19,1.44,5.69,3.04,6.34c6.06,2.44,5.36,9.8,10.21,12.86
				c0.12,0.08-0.04,0.61-0.06,0.92C771.31,1345.09,770.98,1345.36,770.65,1345.63z"/>
			<path d="M832.93,1277.27c-2.51-5.51-5.01-11.01-7.64-16.79c6.81,3.57,6.81,10.42,8.81,16.21
				C833.71,1276.88,833.32,1277.07,832.93,1277.27z"/>
			<path d="M751.18,1315.41c2.4,5.8,4.89,11.8,7.25,17.51C753.93,1330,750.95,1322.94,751.18,1315.41z"/>
			<path d="M548.04,1180.73c0,4.98,0,9.97,0,14.95c-0.44,0-0.87,0-1.31,0c0-4.98,0-9.97,0-14.95
				C547.16,1180.73,547.6,1180.73,548.04,1180.73z"/>
			<path d="M592,1107.34c-1.33-3.65-2.66-7.3-3.99-10.95c0.73-0.24,1.45-0.48,2.18-0.72c1.15,3.79,2.3,7.58,3.45,11.38
				C593.09,1107.14,592.55,1107.24,592,1107.34z"/>
			<path d="M788.76,1257.6c-0.54,4.19-1.09,8.37-1.63,12.56c-0.53-0.07-1.06-0.14-1.58-0.21c0.62-4.12,1.25-8.24,1.87-12.36
				C787.86,1257.59,788.31,1257.6,788.76,1257.6z"/>
			<path d="M586.09,1095.4c-4.05-1.06-6.63-3.26-7.02-6.97c0.46-0.33,0.92-0.65,1.38-0.98
				C582.32,1090.11,584.21,1092.76,586.09,1095.4z"/>
			<path d="M565.52,1067.1c0.84,2.4,1.67,4.8,2.51,7.2c-0.58,0.2-1.16,0.39-1.74,0.59c-0.88-2.38-1.75-4.76-2.63-7.14
				C564.28,1067.53,564.9,1067.32,565.52,1067.1z"/>
			<path d="M643.17,1259.52c-3.13-2.83-6.26-5.65-9.4-8.48c0.29-0.34,0.59-0.68,0.88-1.02c3.24,2.76,6.49,5.52,9.73,8.28
				C643.99,1258.7,643.58,1259.11,643.17,1259.52z"/>
			<path d="M640.66,1251.73c-3.29-2.22-6.57-4.45-9.86-6.67c0.24-0.38,0.48-0.76,0.73-1.15c3.36,2.14,6.73,4.27,10.09,6.41
				C641.3,1250.79,640.98,1251.26,640.66,1251.73z"/>
			<path d="M595.59,1118.73c0.85-2.46,1.71-4.91,2.56-7.37c0.55,0.2,1.11,0.41,1.66,0.61c-0.91,2.52-1.83,5.05-2.74,7.57
				C596.58,1119.27,596.09,1119,595.59,1118.73z"/>
			<path d="M436.1,854.52c7.44,2.89,11.47,8.5,10.85,14.93c-0.43,0.1-0.93,0.37-1.36,0.28c-10.22-1.97-16.77,0.86-20.03,10.55
				c-1.47,4.37-0.32,9.86,0.52,14.68c0.85,4.86,3.01,9.47,4.16,14.3c0.67,2.83,0.48,5.86,0.74,9.85c-5.28-3.02-7.46-6.5-8.66-10.79
				c-1.55-5.58-2.91-11.24-4.96-16.63c-0.86-2.26-2.95-4.54-5.08-5.75c-9.37-5.32-16.93-12.07-21.27-22.28
				c-1.02-2.4-4.12-3.94-6.35-5.77c-1.13-0.93-2.74-1.37-3.64-2.45c-7.89-9.45-17.13-16.85-28.63-21.74
				c-4.35-1.85-7.63-6.21-11.4-9.42c0.3-0.4,0.6-0.81,0.9-1.21c0.6,0.04,1.28-0.09,1.78,0.15c7.92,3.74,15.79,7.62,23.76,11.25
				c2.15,0.98,4.86,0.84,6.91,1.95c3.71,2,7.79,3.97,10.49,7.03c3.8,4.3,8.08,5.37,12.89,3.7c3.29-1.15,5.38-0.07,7.72,1.62
				c6.45,4.68,12.74,9.47,20.91,11.15C433.33,861.34,433.93,861.35,436.1,854.52z M427.95,915.24c0.49-0.19,0.97-0.38,1.46-0.57
				c-0.45-1.97-0.81-3.96-1.35-5.9c-1.43-5.1-3.54-10.1-4.23-15.3c-0.66-4.95-1.46-10.89,0.62-14.96
				c3.95-7.74,9.66-14.89,20.88-10.99c0.16-0.47,0.32-0.93,0.48-1.4c-2.49-3.1-4.97-6.2-7.86-9.8c-2.82,6.5-4.53,7.1-10.2,6.06
				c-8.82-1.63-15.88-6.43-22.94-11.51c-1.64-1.18-4.35-2.07-6.18-1.6c-6.14,1.58-10.84,0.39-15.14-4.52
				c-2.4-2.74-5.92-4.7-9.25-6.38c-2.98-1.51-6.48-1.96-9.59-3.25c-4.63-1.93-9.12-4.18-13.67-6.3c0.11,1.49,0.68,2.13,1.39,2.41
				c12.24,4.76,21.99,12.8,30.23,22.84c0.41,0.5,1,1.02,1.59,1.15c5.75,1.29,8.28,5.75,10.44,10.54c2.76,6.1,6.74,11.36,12.7,14.28
				c9.05,4.45,13.55,11.78,15.5,21.21c0.5,2.43,0.9,4.93,1.75,7.24C425.45,910.85,426.81,913.01,427.95,915.24z"/>
			<path d="M474.66,443.2c1.03,3.79,1.71,6.29,2.57,9.43c1.82-1.7,3.53-3.3,6.41-5.98c-3.56,12.03-5.81,23.08-10.25,33.18
				c-2.16,4.91-8.4,8.01-13.63,12.68c0.38-6.76,0.74-13.19,1.1-19.62c-0.24-0.2-0.48-0.4-0.72-0.59c-2.44,3.63-4.88,7.25-7.45,11.08
				c-5.7-3.63-3.56-8.98-2.97-13.55c0.43-3.35,2.2-6.57,3.68-9.71c0.44-0.94,1.96-2.05,2.89-1.97c7.72,0.65,11.93-3.74,15.08-9.93
				C472.08,446.79,473.1,445.54,474.66,443.2z M461.26,489.45c4.17-3.97,8.86-6.54,10.53-10.41c3.5-8.13,5.42-16.94,8.04-25.65
				c-1.64,0.59-3.06,1.1-4.58,1.64c-0.36-2.58-0.66-4.75-0.97-6.93c-3.86,5.63-7.38,11.27-14.2,11.53c-5.18,0.2-6.51,3.05-7.54,6.76
				c-1.28,4.58-2.99,9.26-0.82,14.24c3.49-4.3,6.66-8.21,9.83-12.12c0.59,0.33,1.18,0.67,1.77,1
				C462.65,475.85,462,482.18,461.26,489.45z"/>
			<path d="M765.67,445.76c1.61,1.42,4.42,2.92,4.26,3.96c-0.28,1.8-2.13,4.32-3.8,4.77c-10.48,2.78-19.38,8.45-28.18,14.41
				c-0.78,0.53-1.69,0.87-3.3,0.68c0.59-0.95,1.04-2.01,1.79-2.82c9.32-10.12,18.73-20.16,28.02-30.3c2.57-2.8,5.24-3.06,9.31-0.77
				C770.82,439.34,768.04,442.8,765.67,445.76z M745.27,460.72c7.63-3.26,15.26-6.52,22.89-9.79c-0.13-0.39-0.26-0.77-0.38-1.16
				c-2.25-0.46-4.51-0.93-6.76-1.39c-0.39-0.65-0.77-1.3-1.16-1.94c3.84-2.91,7.68-5.82,11.51-8.73c-0.76-0.76-1.52-1.52-2.27-2.28
				C759.67,442.44,754.14,453.53,745.27,460.72z"/>
			<path d="M485.57,502.79c6.14-9.71,12.02-19.61,18.64-28.98c1.48-2.09,5.61-2.29,9-3.54c-4.59,14.77-13.21,25.82-26.22,33.5
				C486.51,503.44,486.04,503.11,485.57,502.79z M491.22,497.34c10.31-4.56,14.14-14.32,18.84-23.38
				C499.03,477.96,496.72,488.92,491.22,497.34z"/>
			<path d="M490.34,458.55c0.27,3.41-0.02,7.05,4.01,8.67c0.43,0.17,0.67,1.86,0.38,2.61c-1.35,3.57-2.87,7.08-4.46,10.55
				c-0.3,0.65-1.15,1.19-1.87,1.47c-3.9,1.53-7.84,2.96-13.17,4.95c4.74-9.86,9.21-19.16,13.68-28.46
				C489.39,458.41,489.86,458.48,490.34,458.55z M488.55,463c-3.51,7.05-6.54,13.16-10.01,20.13c2.64-1.22,4.22-2.53,5.86-2.6
				c4.46-0.2,5.04-3.79,6.95-6.57c2.69-3.93,1.89-6.33-2.01-8.39C489,465.39,489.01,464.56,488.55,463z"/>
			<path d="M526.12,462.83c-3.06,5.69-6.12,11.38-9.19,17.07C515.08,471.6,517.63,465.61,526.12,462.83z"/>
			<path d="M578.39,847.59c0.64,3.68,1.65,7.34,1.86,11.05c0.56,9.93,1.18,19.89,1.01,29.83c-0.19,11.41-1.24,22.8-1.86,34.21
				c-0.41,7.45-0.53,14.92-1.11,22.35c-0.9,11.38-2,22.75-3.17,34.1c-1.17,11.35-2.14,22.75-3.9,34.01
				c-1.67,10.74-3.3,21.64-9.63,31.01c-0.42,0.62-0.5,1.47-0.73,2.22c-0.32-0.33-0.64-0.67-0.97-1c1.88-5.16,4.51-10.18,5.49-15.5
				c2.66-14.46,4.67-29.05,6.82-43.6c1.19-8.01,2.36-16.04,3.14-24.1c0.82-8.56,1.24-17.17,1.71-25.77
				c0.76-13.74,1.95-27.48,1.99-41.23c0.04-15.08-1.02-30.16-1.59-45.24c-0.03-0.75,0.07-1.51,0.1-2.26
				C577.84,847.64,578.11,847.62,578.39,847.59z"/>
			<path d="M574.44,857.06c0.51,6.94,1.44,13.87,1.45,20.81c0.02,16.46-0.01,32.95-0.78,49.39c-0.57,12.22-2.5,24.38-3.51,36.59
				c-0.68,8.26-0.18,16.66-1.37,24.82c-1.4,9.6-3.87,19.06-6.26,28.49c-1.34,5.28-3.44,10.38-5.24,15.54
				c-1.35,3.88-3.86,6.61-7.03,7.78c9.22-15.82,15.39-32.85,16.73-51.63c1.4-19.66,3.67-39.28,4.47-58.97
				c0.81-20.04,0.3-40.13,0.36-60.2c0.01-4.16,0-8.31,0-12.47C573.65,857.17,574.05,857.11,574.44,857.06z"/>
			<path d="M515.46,918.25c0,16.37,0,32.74,0,49.11c0,6.49,0.27,12.99-0.11,19.46c-0.71,12.06-1.96,24.08-2.68,36.14
				c-0.41,6.96,0.56,14.08-0.48,20.91c-1.41,9.23-4.13,18.27-6.41,27.36c-0.23,0.93-1.32,1.64-1.88,2.3
				c1.95-7.92,3.93-15.84,5.84-23.78c0.31-1.28,0.39-2.62,0.44-3.94c1.04-25.67,2.24-51.34,3.03-77.02
				c0.47-15.4,0.27-30.83,0.38-46.24c0.01-1.45,0.16-2.9,0.25-4.35C514.38,918.21,514.92,918.23,515.46,918.25z"/>
			<path d="M460.76,828.74c0.89,8.71,2.24,17.41,2.57,26.14c0.45,11.91-0.13,23.86,0.2,35.78c0.43,15.74,1.38,31.47,1.99,47.21
				c0.25,6.47-0.01,12.96,0.37,19.42c0.4,6.73,1.28,13.43,1.95,20.15c-0.5,0.07-1,0.14-1.5,0.21c-0.67-4.83-1.75-9.65-1.93-14.5
				c-0.5-13.27-0.42-26.57-0.95-39.85c-0.43-10.74-1.64-21.46-2.05-32.2c-0.22-5.76,0.95-11.58,0.71-17.34
				c-0.55-13.57-1.57-27.12-2.4-40.67c-0.09-1.46-0.16-2.92-0.24-4.38C459.91,828.72,460.34,828.73,460.76,828.74z"/>
			<path d="M472.48,828.71c0.59,7.05,1.46,14.09,1.71,21.16c0.48,13.62,0.52,27.25,0.91,40.87c0.77,26.99,1.09,54.02,5.11,80.81
				c0.07,0.45-0.19,0.94-0.85,1.46c-3.32-15.51-4.66-31.23-4.97-47.08c-0.32-16.28-0.74-32.56-1.25-48.84
				c-0.5-16.07-1.17-32.13-1.76-48.19C471.73,828.84,472.1,828.77,472.48,828.71z"/>
			<path d="M521.86,784.79c0,27.02,0,54.04,0,81.06c-5.77-26.85-1.06-53.92-2.15-80.88C520.42,784.91,521.14,784.85,521.86,784.79z
				M520.49,847.83c0.16-0.05,0.31-0.1,0.47-0.14c0-6.66,0-13.33,0-19.99c-0.16,0-0.31,0-0.47,0
				C520.49,834.4,520.49,841.11,520.49,847.83z"/>
			<path d="M479.66,1031.47c1.45,2.84,2.72,5.79,4.4,8.49c2.5,4.03,4.75,8.69,11.03,6.99c0.51-0.14,2.05,1.24,1.95,1.54
				c-0.42,1.31-1.06,3.28-2.01,3.53c-1.52,0.41-3.85,0.23-4.97-0.72c-6.02-5.1-9.17-11.97-11.54-19.21
				C478.9,1031.89,479.28,1031.68,479.66,1031.47z"/>
			<path d="M481.53,1017.42c3.51,8.5,6.75,16.36,9.82,23.82C485.13,1040,480.26,1029.43,481.53,1017.42z"/>
			<path d="M116.02,963.38c2.04,3.95,3.93,7.47,5.65,11.07c0.26,0.54-0.07,1.62-0.48,2.19c-7.34,10.16-7.54,16.05-0.99,26.51
				c3.05,4.86,5.92,9.84,9.21,15.33c-1.98,0-3.02,0-4.25,0c0.12,0.58,0.12,1.07,0.33,1.44c1.39,2.46,3.12,4.77,4.17,7.36
				c2.1,5.2,5.44,7.28,11.17,6.49c2.75-0.38,4.42-0.94,5.9-3.41c1.77-2.95,4.1-5.61,6.49-8.12c4.55-4.79,4-10.63,3.73-16.43
				c-0.07-1.54-0.6-3.06,0.16-5.08c2.62,4.52,5.33,8.99,7.85,13.57c2.08,3.78,4.02,7.65,5.8,11.58c0.45,0.99,0.43,2.55-0.03,3.54
				c-4.99,10.74-9.26,21.5-4.16,33.5c0.62,1.46,0.81,3.1,1.54,6c-2.84-1.3-4.9-1.81-6.4-3.02c-2.59-2.06-5-4.4-7.18-6.89
				c-4.59-5.22-8.84-10.31-16.39-11.99c-6.93-1.54-10.61-7.62-13.58-13.74c-2.47-5.08-4.88-10.18-7.21-15.32
				c-0.53-1.16-1.07-2.59-0.86-3.76c1.12-6.08-0.9-11.06-4.76-15.61c-4-4.71-3.79-9.73-1.6-15.36
				C112.47,977.16,113.89,970.72,116.02,963.38z M116.47,1002.23c-4.43-8.73-3.01-17.33,1.47-25.36c2.02-3.63,0.71-5.48-1.22-8.16
				c-0.97,3.8-1.15,7.52-2.81,10.39c-4.91,8.52-3.81,16.01,2.58,23.08c0.8,2.79,1.73,5.56,2.36,8.39c0.56,2.53,0.35,5.3,1.25,7.67
				c1.71,4.49,3.94,8.77,6.01,13.12c2.75,5.81,5.91,11.76,12.55,13.36c7.91,1.91,12.7,7.23,17.54,12.96
				c2.23,2.63,4.94,4.85,7.44,7.25c0.33-2.67-0.63-4.53-0.69-6.41c-0.14-4.86-0.56-9.88,0.4-14.57c1.1-5.36,3.59-10.43,5.41-15.65
				c0.19-0.54,0.06-1.35-0.21-1.88c-2.83-5.53-5.72-11.03-8.19-15.77c-1.97,4.26-3.7,8.75-6.07,12.86c-1.8,3.11-4.6,5.62-6.63,8.62
				c-2.33,3.44-12.78,5.87-15.17,2.61c-4.3-5.86-7.64-12.43-11.7-19.25c2.32-0.23,3.17-0.32,5.16-0.52
				C122.46,1010.28,119.46,1006.25,116.47,1002.23z"/>
			<path d="M853.93,871.93c-0.55-2.47-1.15-4.93-1.66-7.41c-3.65-17.72-7.71-35.37-10.75-53.19c-1.7-9.96-1.3-20.25-2.52-30.31
				c-2.24-18.44-4.97-36.82-7.51-55.22c-3.9-28.3-7.75-56.61-11.71-84.9c-4.06-28.95-8.37-57.86-12.31-86.82
				c-2.42-17.78-4.77-35.59-6.45-53.45c-2.07-22.02-3.41-44.12-5.12-66.18c-0.14-1.86-0.71-3.69-0.48-5.67
				c6.76,12.8,7.68,27.21,11.21,40.93c-0.31-12.58-4.39-25.16-0.34-37.7c0.46-0.07,0.91-0.14,1.37-0.2
				c1.43,6.63,2.82,13.27,4.29,19.89c7.08,31.91,9.22,64.42,12.41,96.84c2.43,24.77,6.85,49.33,10.04,74.03
				c3.11,24.06,5.69,48.19,8.66,72.27c2.28,18.47,4.85,36.9,7.24,55.35c2.6,20.11,5.15,40.22,7.68,60.33
				c2.21,17.64,4.11,35.33,6.6,52.93c2.25,15.92,5.11,31.76,7.7,47.64c0.13,0.78,0.19,1.56-0.76,2.78c-0.88-1.93-1.83-3.82-2.63-5.78
				c-7-17.13-10.12-35.12-10.94-53.47c-1.27-28.49-6.27-56.4-11.44-84.37c-5.27-28.57-9.49-57.36-13.39-86.16
				c-3.3-24.37-5.24-48.93-8.25-73.35c-2.93-23.75-6.52-47.41-9.71-71.12c-1.9-14.14-3.6-28.31-6.08-42.43
				c0.33,6.5,0.6,13.01,1.02,19.51c0.86,13.28,1.35,26.6,2.82,39.82c1.71,15.34,4.31,30.58,6.53,45.86
				c3.51,24.17,7.24,48.31,10.48,72.51c4.13,30.79,7.93,61.63,11.74,92.46c3.51,28.37,6.81,56.76,10.31,85.13
				c0.57,4.6,1.71,9.13,2.53,13.71c0.33,1.84,0.51,3.71,0.76,5.56C854.82,871.8,854.37,871.87,853.93,871.93z M853.83,801.11
				c0.37-0.06,0.75-0.12,1.12-0.18c-2.23-17.43-4.43-34.86-6.68-52.29c-2.45-18.93-5.04-37.85-7.37-56.8
				c-2.88-23.41-4.25-47.1-8.64-70.21c-6.34-33.4-8.81-67.15-12.45-100.82c-3.01-27.8-7.3-55.46-11.02-83.19
				c-0.83,0.05-1.67,0.1-2.5,0.16c0,3.71-0.35,7.46,0.06,11.13c2.8,24.92,5.65,49.84,8.7,74.74c2.77,22.58,5.81,45.13,8.71,67.7
				c2.46,19.1,5.02,38.2,7.33,57.32c2.03,16.83,3.4,33.74,5.72,50.52c2.74,19.89,6.04,39.71,9.34,59.53
				C848.49,772.88,851.25,786.98,853.83,801.11z M798.35,441.34c0.12,16.18,8.78,114.44,13.61,125.16
				C807.18,524.51,810.08,482.41,798.35,441.34z M832.39,725.97c0.68-0.09,1.35-0.17,2.03-0.26c-5.18-38.95-10.35-77.89-15.53-116.84
				c-0.54,0.07-1.08,0.14-1.61,0.21C822.31,648.04,827.35,687.01,832.39,725.97z M858.51,829.85c1.68,21.54,0.91,43.4,7.62,64.3
				C863.59,872.71,861.05,851.28,858.51,829.85z M841.59,782.12c-0.43,0.05-0.86,0.1-1.3,0.15c1.96,17.24,3.91,34.47,5.87,51.71
				c0.47-0.05,0.93-0.11,1.4-0.16C845.58,816.59,843.58,799.36,841.59,782.12z"/>
			<path d="M847.26,906.05c-6.27-10.69-10.68-20.78-12.35-31.83c-5.15-33.98-10.61-67.94-14.98-102.02
				c-4.25-33.12-7.35-66.38-10.89-99.59c-3.03-28.43-5.72-56.89-9-85.29c-4.08-35.32-8.63-70.58-12.94-105.86
				c-1.95-15.95-2.1-31.84,2.08-47.52c0.16-0.6,0.5-1.15,1.71-1.74c0,1.45,0.03,2.91-0.01,4.36c-0.04,1.49-0.33,3-0.2,4.48
				c1.96,21.85,4.03,43.7,5.96,65.55c1.2,13.58,2.63,27.17,3.22,40.78c0.92,21.36,5.42,42.09,9.32,63c3.16,16.94,5,34.16,6.78,51.32
				c2.85,27.61,5,55.3,7.76,82.93c2.18,21.83,4.62,43.63,7.33,65.4c3.92,31.47,8.01,62.94,16.42,93.63
				C847.54,903.9,847.42,904.23,847.26,906.05z M803.82,587.69c-0.55,0.17-1.11,0.33-1.66,0.5c1.68,15.35,3.47,30.69,5.02,46.05
				c2.67,26.3,5.05,52.64,7.81,78.93c2.57,24.46,5.05,48.93,8.33,73.3c3.79,28.14,8.28,56.19,12.77,84.23
				c1.19,7.41,3.48,14.64,5.27,21.95c-3.81-26.86-8.33-53.51-11.95-80.27c-3.12-23.06-5.44-46.23-7.74-69.39
				c-3.1-31.27-5.44-62.62-8.89-93.85C810.52,628.57,806.86,608.16,803.82,587.69z M797.3,534.79c-2.91-29.26-5.83-58.53-8.74-87.79
				C785.74,476.79,792.91,505.67,797.3,534.79z"/>
			<path d="M811.38,434.31c2.24,5.28,4.69,10.47,6.67,15.84c5.87,15.87,7.48,32.63,9.88,49.21c2.79,19.23,5.79,38.43,8.37,57.68
				c1.37,10.2,1.89,20.51,3.09,30.74c2.22,18.96,4.72,37.89,6.99,56.85c1.6,13.36,2.66,26.79,4.57,40.1
				c2.85,19.88,5.77,39.76,9.47,59.49c6.39,34.08,13.7,67.99,20.04,102.08c3.03,16.27,4.75,32.79,7.06,49.2
				c-0.43,0.16-0.87,0.31-1.3,0.47c-1.8-5.11-4.29-10.09-5.27-15.35c-2.34-12.55-3.52-25.32-6.03-37.83
				c-2.34-11.68-6.23-23.05-8.64-34.72c-6.41-31.03-13.36-62-18.23-93.28c-4.11-26.39-5.47-53.21-8.29-79.82
				c-2.4-22.64-6.14-45.17-7.49-67.86c-2.35-39.51-13.64-77.49-18.49-116.53c-0.66-5.33-2.6-10.5-3.94-15.74
				C810.36,434.66,810.87,434.49,811.38,434.31z M882.78,882.2c0.31-0.06,0.63-0.11,0.94-0.17c-1.41-10.5-2.34-21.09-4.32-31.48
				c-6.9-36.18-14.42-72.24-21.17-108.44c-3.71-19.89-6.58-39.95-9.43-59.99c-1.9-13.32-3.09-26.73-4.63-40.1
				c-2.13-18.46-4.35-36.9-6.43-55.36c-1.65-14.62-3.15-29.26-4.71-43.88c-0.31,9.69,0.3,19.28,1.35,28.83
				c2.41,21.95,5.19,43.86,7.51,65.81c2.83,26.76,4.16,53.75,8.32,80.3c4.82,30.78,11.64,61.26,18.34,91.71
				c3.52,16,9.08,31.48,9.71,48.17C878.55,865.84,881.19,874,882.78,882.2z M828.58,525.31c0.45-0.08,0.9-0.16,1.35-0.24
				c-4.82-24.33-5.05-49.47-14.28-73.01C819.96,476.47,824.27,500.89,828.58,525.31z"/>
			<path d="M873.97,879.84c1.17,3.18,2.81,6.28,3.43,9.56c3.22,17.15,9.52,33.18,17.22,48.73c0.34,0.69,0.54,1.45,0.91,2.47
				c-10.47-4.72-24.67-42.79-22.86-60.29C873.11,880.14,873.54,879.99,873.97,879.84z M889.37,932.85
				c-4.97-14.95-9.61-28.94-14.25-42.93C878.01,904.51,881.12,919.02,889.37,932.85z"/>
			<path d="M900.85,941.02c-10.22-12.35-13.21-26.64-14.95-41.36c0.43-0.16,0.87-0.31,1.3-0.47
				C891.68,912.91,896.16,926.63,900.85,941.02z"/>
			<path d="M909.68,941.96c-7.81-9.3-13.6-19.64-13.29-32.28c0.66-0.22,1.31-0.43,1.97-0.65c1.57,5.51,2.92,11.11,4.78,16.52
				C905.06,931.11,907.48,936.5,909.68,941.96z"/>
			<path d="M44.64,1373.88c1.47-0.78,2.86-2,4.42-2.28c6.86-1.21,13.76-2.12,20.64-3.18c9.34-1.43,18.63-3.32,28.02-4.23
				c9.86-0.96,19.81-0.99,29.72-1.47c1.26-0.06,2.78,0,3.72-0.66c6.48-4.57,13.96-5.02,21.41-5.27c8.17-0.28,16.36-0.06,24.74,0.84
				c-0.93,0.39-1.84,1.01-2.81,1.13c-8.55,1.04-17.17,1.64-25.63,3.13c-4.44,0.78-8.54,3.34-12.93,4.61
				c-4.71,1.36-9.58,2.21-14.4,3.17c-6.45,1.28-12.94,2.37-19.39,3.7c-14.9,3.09-29.78,6.28-44.68,9.4c-0.55,0.12-1.19-0.2-1.96-1.04
				c6.33-1.86,12.66-3.72,18.98-5.58c-0.08-0.6-0.16-1.2-0.25-1.79c-9.75,0.43-19.5,0.85-29.25,1.28
				C44.88,1375.04,44.76,1374.46,44.64,1373.88z M143.1,1360.57c-0.14-0.45-0.28-0.89-0.41-1.34c-2.09,0.82-4.26,1.48-6.25,2.51
				c-3.86,2-7.64,3.04-12.22,3.02c-9.55-0.04-19.13,0.83-28.64,1.83c-10.54,1.11-21.03,2.72-31.52,4.23
				c-4.72,0.68-9.4,1.64-13.77,2.42c10.17,0,20.52,0,31.68,0c-2.03,1.51-3.43,2.56-4.85,3.61c6.31-1.39,12.41-2.81,18.54-4.08
				c8.86-1.84,17.67-4.38,26.63-5.11C130.11,1367.01,136.56,1364,143.1,1360.57z"/>
			<path d="M1040.79,1277.67c0,8.61,0,17.22,0,25.83c-0.44,0.01-0.88,0.01-1.32,0.02c0-8.61,0-17.22,0-25.83
				C1039.91,1277.68,1040.35,1277.68,1040.79,1277.67z"/>
			<path d="M1049.79,1134.78c-0.21,4.17-0.29,8.35-0.68,12.5c-0.41,4.23-1.14,8.42-1.73,12.63c-0.53-0.04-1.05-0.08-1.58-0.11
				c0.75-8.33,1.5-16.67,2.24-25C1048.62,1134.78,1049.2,1134.78,1049.79,1134.78z"/>
			<path d="M1047.53,1206.25c0,5.02,0,10.04,0,15.07c-0.34,0-0.68,0-1.02,0c0-5.02,0-10.04,0-15.07
				C1046.85,1206.25,1047.19,1206.25,1047.53,1206.25z"/>
			<path d="M1046.59,1226.88c-0.65,4.84-1.29,9.68-1.94,14.51c-0.59-0.08-1.17-0.16-1.76-0.24c0.85-4.8,1.69-9.6,2.54-14.41
				C1045.82,1226.79,1046.21,1226.84,1046.59,1226.88z"/>
			<path d="M1048.33,1184.89c0,3.8,0,7.59,0,11.39c-0.29,0.01-0.58,0.03-0.86,0.04c0-3.8,0-7.6,0-11.4
				C1047.75,1184.92,1048.04,1184.91,1048.33,1184.89z"/>
			<path d="M1040.37,1268.21c0-4.07,0-8.13,0-12.2c0.36-0.01,0.72-0.01,1.08-0.02c0,4.08,0,8.16,0,12.24
				C1041.09,1268.22,1040.73,1268.21,1040.37,1268.21z"/>
			<path d="M1050.35,1118.41c0,3.47,0,6.94,0,10.4c-0.39-0.01-0.77-0.02-1.16-0.03c0-3.45,0-6.9,0-10.35
				C1049.58,1118.43,1049.96,1118.42,1050.35,1118.41z"/>
			<path d="M1041.07,1344.74c0.12,2.67,0.24,5.35,0.36,8.02c-0.48,0.01-0.95,0.03-1.43,0.04c0-2.68,0-5.36,0-8.03
				C1040.35,1344.76,1040.71,1344.75,1041.07,1344.74z"/>
			<path d="M274.26,995.85c15.88,8.58,24.25,23.98,34.99,37.37c-4.15-3.37-8.31-6.75-12.46-10.12c-0.31,0.26-0.62,0.53-0.94,0.79
				c0.92,1.84,1.92,3.64,2.73,5.52c0.5,1.16,1.38,2.91,0.92,3.6c-3.85,5.65,0.12,9.76,2.67,13.98c2.93,4.85,6.33,9.41,9.53,14.1
				c-0.49,0.4-0.97,0.8-1.46,1.2c-8.32-8.99-16.64-17.99-25.08-27.11c5.05-2.83,6.64-12.12,3.05-16.3
				c-4.33-5.04-8.87-9.91-13.58-14.6C271.79,1001.46,270.93,998.96,274.26,995.85z M276.1,997.88c-0.62,0.3-1.23,0.6-1.85,0.9
				c0.37,1.13,0.46,2.49,1.17,3.34c2.24,2.67,4.67,5.19,7.09,7.71c5.52,5.76,12.29,10.91,9.28,20.56c-0.67,2.15-1.03,4.4-1.67,7.23
				c2.39,2.67,5.32,5.95,8.36,9.35c-0.95-4.14-3.18-8.27-2.27-11.52c0.88-3.15,0.56-5.13-0.73-7.66c-0.93-1.84-1.49-3.87-2.38-6.25
				c1.97-0.24,3.23-0.4,6.09-0.75C290.84,1012.51,283.47,1005.19,276.1,997.88z"/>
			<path d="M299.84,974.32c6.84,4.17,10.59,10.96,16.09,18.21c-5.37-1.74-9.31-3.01-13.25-4.29c-0.29,0.45-0.59,0.9-0.88,1.36
				c2.84,6.37,5.68,12.74,8.4,18.84c-3.29-0.2-20.56-16.59-25.88-25.48c2.91,0.64,5.24,1.15,7.56,1.66
				c-0.32-0.41-0.65-0.81-0.97-1.22c1.3-1.97,2.73-3.86,3.86-5.92c1.06-1.91,1.8-4,2.96-6.65c0.77,1.24,1.48,2.36,2.18,3.49
				c-3.11,4.5-6.21,9-9.8,14.21c4.51,3.88,9.26,7.96,13.38,11.5c-1.13-3.47-2.62-8.05-4.32-13.26c3.51,0,6.5,0,10,0
				C305.76,982.22,302.8,978.27,299.84,974.32z"/>
			<path onClick={() => props.onFill(248)} fill={props.fillColors[248]} d="M299.84,974.32c2.96,3.95,5.92,7.9,9.33,12.45c-3.5,0-6.49,0-10,0c1.7,5.21,3.19,9.79,4.32,13.26
				c-4.12-3.54-8.87-7.62-13.38-11.5c3.59-5.2,6.69-9.71,9.8-14.21C299.91,974.32,299.84,974.32,299.84,974.32z"/>
			<path d="M259.56,1126.6c-1.36-8.06-3.29-16.08-3.97-24.2c-1.62-19.18-2.76-38.39-3.86-57.61c-0.42-7.3-0.44-14.65-0.2-21.96
				c0.51-15.28,1.32-30.55,2.04-45.81c0.05-1.08,0.45-2.13,1.5-3.22c0.15,1.17,0.41,2.34,0.42,3.51c0.12,23.63-0.3,47.28,0.49,70.88
				c0.5,14.74,3.07,29.4,4.26,44.13c0.87,10.76,1.04,21.58,1.48,32.38c0.02,0.57-0.28,1.15-0.44,1.73
				C260.71,1126.49,260.14,1126.55,259.56,1126.6z M258.32,1091.55c-0.19,0.01-0.37,0.02-0.56,0.03c0,6.02,0,12.04,0,18.06
				c0.19,0,0.37-0.01,0.56-0.01C258.32,1103.61,258.32,1097.58,258.32,1091.55z"/>
			<path d="M261.67,983.24c1.79,48.42,9.7,96.45,9.75,144.97C263.16,1080.2,260.91,1031.81,261.67,983.24z M265.76,1070.34
				c0.35-0.04,0.71-0.08,1.06-0.11c-0.51-6.48-1.02-12.97-1.53-19.45c-0.42,0.04-0.85,0.07-1.27,0.11
				C264.6,1057.37,265.18,1063.85,265.76,1070.34z"/>
			<path d="M261.38,935.23c-5.7-66.76-12.52-133.46-9.95-200.67c1.04,33.49,1.4,67.02,3.31,100.47c1.91,33.47,5.37,66.84,8.15,100.26
				C262.38,935.27,261.88,935.25,261.38,935.23z"/>
			<path d="M249.85,916.59c-0.76-10.89-1.56-21.78-2.29-32.68c-1.96-29.52-4.32-59.03-5.7-88.58c-0.99-21.24-1.6-42.58,1.9-63.74
				c0.1-0.63,0.35-1.24,1.27-1.82c-0.2,3.59-0.35,7.18-0.62,10.76c-1.9,24.6-1.42,49.11,0.98,73.72c2.16,22.16,2.43,44.5,3.62,66.76
				c0.59,11.12,1.38,22.24,2.07,33.35c0.05,0.76,0.01,1.53,0.01,2.29C250.68,916.63,250.26,916.61,249.85,916.59z"/>
			<path d="M253.91,903.5c-0.9-56.77-10.84-113.17-6.28-170.23c0.16,1.36,0.45,2.73,0.46,4.09c0.22,22.44-0.1,44.91,0.77,67.32
				c0.65,16.73,3.13,33.38,4.24,50.11c1.07,16.21,1.44,32.46,2.11,48.7C254.79,903.5,254.35,903.5,253.91,903.5z"/>
			<path d="M759.66,1457.44c-5.47-1.06-10.94-2.09-16.4-3.18c-4.05-0.8-7.23,0.24-10.29,3.27c-5.43,5.41-12.46,8.64-19.92,7
				c-11.67-2.58-23.04-6.55-34.53-9.92c0.04-0.49,0.07-0.98,0.11-1.47c1.77-0.12,3.62-0.63,5.29-0.3c7.3,1.47,14.51,3.46,21.84,4.7
				c4.68,0.79,9.58,1.11,14.29,0.67c4.57-0.42,8.63-2.4,9.45-7.98c0.15-1.02,1.81-2.54,2.82-2.58c10.26-0.47,19.03,3.89,27.77,8.27
				C759.95,1456.42,759.8,1456.93,759.66,1457.44z M702.8,1459.75c9.53,4.32,19.31,3.97,26.37-1.81c4.81-3.94,9.19-7.48,15.87-6.74
				c-4.2-0.59-8.39-1.17-12.44-1.74C728.12,1460.61,722.37,1462.6,702.8,1459.75z"/>
			<path d="M328.53,1477.45c-3.45,1.72-6.89,3.47-10.36,5.16c-0.85,0.41-2.5,1.04-2.59,0.89c-2.31-4.02-9.47-1.99-10.14-8.56
				c-0.21-2.05-3.73-3.76-6.14-6c-4.05,2.83-8.02,5.6-12.03,8.4c-2.99-3.3-5.94-6.24-8.44-9.51c-0.65-0.84-0.42-3.13,0.3-4.05
				c0.88-1.13,3.15-2.5,4.03-2.08c3.14,1.5,4.03-0.36,5.33-2.44c0.95-1.51,2.15-3.17,3.66-3.93c1.39-0.7,3.66-0.92,4.96-0.24
				c2.21,1.17,3.96,3.2,5.92,4.9c1.14-1.67,1.85-3.24,3.03-4.28c1.26-1.1,2.97-1.69,4.49-2.5c0.25,1.31,0.27,2.74,0.83,3.9
				c0.49,1.02,1.57,1.79,2.44,2.61c1.39,1.29,2.82,2.53,4.22,3.79c1.51-0.15,3.33-0.34,5.16-0.52c-0.12-0.15-0.24-0.31-0.36-0.46
				c2.16-0.87,4.47-2.6,6.43-2.28c1.18,0.19,1.83,3.61,2.71,5.57c-0.36,0.18-0.72,0.36-1.08,0.55c1.02,1.24,2.32,2.34,3.01,3.74
				c1.1,2.23,0.95,4.07-2.16,4.8c-1.27,0.3-2.25,1.77-3.37,2.71C328.39,1477.59,328.53,1477.45,328.53,1477.45z M330.24,1462.91
				c-2.93-2.38-4.15-2.04-4.68,1.26c-0.19,1.21-1.56,2.22-2.39,3.33c-0.86-0.81-1.72-1.63-2.32-2.19c-1.41,0.5-3.35,1.73-3.87,1.29
				c-3.29-2.8-6.26-5.97-9.74-9.42c-2.95,0.16-0.72,7.72-5.67,5.83c-2.7-1.03-4.62-4.11-7.47-6.81c-2.52,2.85-5.28,5.97-7.25,8.2
				c-2.87,0.18-4.83,0.31-7.55,0.48c3.54,3.48,6.06,5.96,8.94,8.79c0.23-0.11,1.66-0.77,3.03-1.54c2.85-1.6,6.27-5.15,8.36-4.48
				c3.42,1.1,8.28,2.63,8.39,8.2c0.02,0.82,1.85,1.85,3.02,2.33c1.72,0.71,3.61,1.01,6.24,1.7c4.11-2.32,9.52-5.35,15.44-8.69
				c-1.87-1.66-3.59-3.19-5.49-4.88C328.29,1465.1,329.13,1464.16,330.24,1462.91z"/>
			<path d="M955.84,868.89c-7.51-6.21-15.06-12.37-22.51-18.67c-1.97-1.67-3.59-3.74-6.22-4.86c8.42,9.55,16.83,19.1,25.25,28.64
				c-0.22,0.63-0.45,1.26-0.67,1.89c-2.89-0.14-6.01,0.37-8.61-0.58c-3.19-1.16-6.22-3.19-8.83-5.42
				c-16.59-14.22-30.4-30.57-38.78-51.1c-2.11-5.16-5.07-9.98-7.63-14.96c-0.33-1.48-0.67-2.96-1-4.44
				c3.35,6.23,7.22,12.24,9.96,18.73c8.48,20.13,22.01,36.31,38.31,50.4c4.02,3.47,8.36,6.59,15.55,6.01
				c-2.62-3.07-4.51-5.47-6.59-7.69c-20.26-21.59-38.42-44.62-49.75-72.36c-0.59-1.44-1.13-2.91-0.74-4.8c0.68,1.2,1.52,2.33,2,3.6
				c10.97,29,31.78,50.32,54.6,70.07C952.16,865.09,953.96,867.04,955.84,868.89z"/>
			<path d="M871.09,693.89c6.55,26.35,13.09,52.71,19.71,79.35C883.82,766.63,869.78,712.74,871.09,693.89z"/>
			<path d="M886.83,799.4c0.33,1.48,0.67,2.96,1,4.44c8.8,24.26,20.19,46.89,39.99,64.3c1.18,1.04,2.01,2.47,3,3.72
				c-1.09-0.65-2.34-1.12-3.25-1.97c-13.01-12.09-24.23-25.54-31.17-42.1c-6.21-14.82-12.25-29.71-18.31-44.59
				c-0.6-1.48-0.84-3.1-0.25-5.08c1.55,3.41,3.17,6.79,4.62,10.24C884.02,792,885.39,795.71,886.83,799.4z"/>
			<path d="M930.74,871.77c5.38,4.41,10.77,8.82,16.61,13.61c-5.24,3.64-9.49,2.16-12.61-0.12c-6.22-4.55-12.46-9.41-17.52-15.17
				c-12.41-14.13-22.17-30.16-30.66-46.93c-1.18-2.34-1.85-4.94-2.76-7.41c10.3,19.82,21.77,38.89,36.62,55.69
				c3.17,3.59,6.69,7.05,10.62,9.73c3.54,2.41,7.87,3.66,11.85,5.42c0.29-0.49,0.58-0.99,0.86-1.48
				C939.43,880.67,935.09,876.22,930.74,871.77z"/>
			<path d="M158.51,1293.02c2.18,3.79,8.19,4.87,6.24,11.6c-2.39-3.6-4.79-7.2-7.18-10.79
				C157.88,1293.55,158.19,1293.28,158.51,1293.02z"/>
			<path d="M102.91,398.94c-1.18,1.53-2.38,3.04-3.53,4.59c-5.06,6.82-9.56,14.17-15.33,20.32c-4.96,5.29-11.37,9.22-17.56,13.26
				c8.86-10.14,23.3-15.18,26.25-29.88c-7.04,2.86-7.49,3.49-4.89,7.29c-5.46,7.18-17.63,6.82-19.7,17.85
				c0.84-6.43,4.27-10.62,10.21-12.88c3.12-1.19,6.65-2,5.67-7.01c-0.25-1.29,1.81-3.53,3.33-4.57c4.88-3.32,10-6.29,15.03-9.39
				C102.57,398.67,102.74,398.8,102.91,398.94z"/>
			<path d="M155.55,1223.15c3.01-7.27,11.66-4.77,22.63,4.08c3.08-2.04,5.97-5.09,10.53-2.64c0.85,0.46,3.77-1.49,4.76-2.97
				c3.3-4.92,6.58-5.96,12.25-3.71c1.26,0.5,3.18-0.04,4.58-0.63c5.13-2.16,7.09-1.61,10.51,3.4c-3.11,0.51-8.39-2.44-7.58,4.62
				c0.01,0.08-3.42,1.13-4.91,0.66c-1.71-0.54-3.04-2.28-4.25-3.26c-2.52,4.57-4.06,9.62-7.39,12.81c-4.11,3.94-9.44,3.47-12.62-0.53
				c-2.99,1.5-6.72,4.14-7.34,3.55c-2.13-2.04-3.17-5.22-5.18-9.03c-2.17-0.34-6.23-1.73-10.07-1.27c-1.8,0.22-3.16,4.02-4.76,6.17
				c-0.87,1.17-1.9,3.27-2.73,3.21c-2.36-0.18-4.8-0.97-6.85-2.16c-0.56-0.32-0.04-2.93,0.35-4.39c0.5-1.84,0.99-3.97,2.23-5.25
				C150.95,1224.54,153.12,1224.19,155.55,1223.15z M195.07,1226.4c-5.76,0.72-12.04-1.75-17.31,3.14c-2.61,2.42-3.01,4.58,0.47,6.91
				c2.15-2.31,3.26-7.23,8.12-2.75c3.56,3.29,7.03,2.15,9.82-2.49c2.45-4.09,4.73-8.28,7.1-12.44
				C198.14,1218.2,195.85,1220.28,195.07,1226.4z M172.48,1226.98c0.17-0.57,0.35-1.13,0.52-1.7c-3.62-1.49-7.15-3.44-10.93-4.2
				c-1.27-0.25-3.14,2.55-4.75,3.94c-0.49,0.42-1.18,1.22-1.55,1.1c-3.66-1.11-4.02,2.1-5.31,3.99c-2.67,3.93-2.1,4.83,3.6,5.32
				c0.1-0.72-0.03-1.64,0.35-2.18c2.19-3.07,2.97-7.81,8.22-7.4C165.92,1226.13,169.2,1226.6,172.48,1226.98z M206.52,1221.4
				c1.64,1.38,2.78,2.35,3.7,3.12c1.54-2.5,2.76-4.49,4.29-6.97C211.76,1218.88,209.47,1219.99,206.52,1221.4z"/>
			<path d="M219.61,900.77c-1.82-47.71-3.64-95.43-5.46-143.14c0.69-0.03,1.38-0.06,2.06-0.09c1.72,47.72,3.43,95.43,5.15,143.15
				C220.78,900.72,220.2,900.75,219.61,900.77z"/>
			<path d="M305.6,719.78c0,10.05-0.33,20.11,0.07,30.14c0.72,18.1,2.63,36.18,2.65,54.27c0.02,18.59-1.44,37.2-2.6,55.78
				c-0.61,9.77-1.08,19.69-3.24,29.17c-2.21,9.72-6.39,19-9.71,28.46c-0.63-0.19-1.25-0.38-1.88-0.57c0.32-1.05,0.49-2.18,0.97-3.15
				c7.81-15.75,11.35-32.42,11.81-49.99c0.52-19.93,2.62-39.85,2.52-59.76c-0.12-23.93-1.89-47.84-2.81-71.76
				c-0.16-4.19,0.39-8.41,0.6-12.61C304.53,719.75,305.07,719.76,305.6,719.78z"/>
			<path d="M275.18,914.86c11.37-22.69,8.58-47.06,8.74-71.04c0.11-16.48-0.21-32.97-0.45-49.46c-0.06-4.43-0.51-8.85-0.78-13.28
				c0.46-0.05,0.92-0.1,1.38-0.15c0.41,4.46,1.15,8.92,1.19,13.38c0.22,27.98,0.89,55.98,0.09,83.94
				c-0.31,10.74-4.02,21.4-6.36,32.05c-0.4,1.81-1.68,3.44-2.55,5.15C276.03,915.25,275.6,915.05,275.18,914.86z"/>
			<path d="M280.89,795.07c1.12,35.85,5.06,71.78-2.66,107.4C283.95,866.79,279.46,830.85,280.89,795.07z"/>
			<path d="M285.53,904.42c4.59-20.36,0.54-40.82,0.95-61.18c0.42-20.92-0.43-41.86-0.7-62.79c-0.01-0.46,0.21-0.92,0.36-1.53
				C290.41,787.74,290.03,895.24,285.53,904.42z"/>
			<path d="M1133.73,468.72c-4.67,11.3-9.34,22.6-14.28,34.55c-4.99-6.31-6.12-11.83-2.7-16.46c4.86-6.59,10.54-12.57,15.87-18.81
				C1132.99,468.25,1133.36,468.49,1133.73,468.72z M1127.45,478.44c-11.16,9.71-12.23,12.3-8.43,20.39
				C1121.98,491.66,1124.72,485.05,1127.45,478.44z"/>
			<path onClick={() => props.onFill(249)} fill={props.fillColors[249]} d="M1075.93,405.19c-1.02-17.67-2.04-35.34-3.06-53.01c0.4-0.02,0.8-0.05,1.2-0.07
				c0.96,17.71,1.92,35.41,2.88,53.12C1076.61,405.22,1076.27,405.21,1075.93,405.19z"/>
			<path onClick={() => props.onFill(250)} fill={props.fillColors[250]} d="M1119.12,1397.3c0.28,2.82,1.28,5.93,5.93,2.65c1.25,3.46-1.86,8.59,4.89,9.2
				c-2.01,1.78-3.68,3.26-5.61,4.97c-1.61-1.13-1.82-5.57-5.85-3.58c-0.21,0.11-1.79-2.41-2.64-3.76c-0.75-1.2-1.09-3.32-2.06-3.62
				c-7.3-2.28-8.58-9.43-12.31-14.58c-0.89-1.23-2.24-2.13-3.44-3.1c-1.13-0.91-2.35-1.72-3.78-2.76c3.21-0.49,5.7-0.88,8.43-1.3
				c-0.1,1.17-0.24,2.97-0.39,4.72c4.23,1.27,7.79,2.53,9.74,6.97C1112.85,1394.97,1116.23,1395.69,1119.12,1397.3z"/>
			<path onClick={() => props.onFill(251)} fill={props.fillColors[251]} d="M410.16,597.23c1.81-2.55,3.5-5.19,5.45-7.62c2.79-3.47,5.46-6.6,5.26-11.77
				c-0.1-2.62,2.29-5.96,4.49-7.95c10.2-9.25,20.51-18.42,31.29-26.98c2.59-2.05,7.13-1.63,11.29-1.42
				c-18.78,18.98-37.57,37.97-56.35,56.95C411.11,598.04,410.63,597.64,410.16,597.23z"/>
			<path onClick={() => props.onFill(252)} fill={props.fillColors[252]} d="M362.92,653.23c4.82-5.84,9.63-11.68,14.47-17.5c0.42-0.5,0.95-1.07,1.53-1.23
				c8.23-2.23,14.13-7.51,19.4-13.96c1.3-1.59,4.4-1.7,6.67-2.49c0.32,0.46,0.64,0.93,0.97,1.39
				c-14.08,11.54-28.16,23.08-42.24,34.63C363.45,653.79,363.19,653.51,362.92,653.23z"/>
			<path onClick={() => props.onFill(253)} fill={props.fillColors[253]} d="M1001.59,890.46c-8.64-81.99,0.28-163.64,3.18-245.39c0,30.75,1.48,61.59-0.33,92.23
				c-2.97,50.24-2.74,100.46-2.84,150.71C1001.59,888.83,1001.59,889.64,1001.59,890.46z"/>
			<path onClick={() => props.onFill(254)} fill={props.fillColors[254]} d="M1002.01,625.84c-2.01-30.8-4.02-61.6-6.03-92.4c3.85,6.22,8.87,77.6,7.35,92.32
				C1002.89,625.78,1002.45,625.81,1002.01,625.84z"/>
			<path onClick={() => props.onFill(255)} fill={props.fillColors[255]} d="M875.59,985c-5.26-6.83-10.53-13.66-15.79-20.49c0.45-0.34,0.9-0.67,1.35-1.01
				c4.99,7.01,9.97,14.01,14.96,21.02C875.94,984.68,875.77,984.84,875.59,985z"/>
			<path onClick={() => props.onFill(256)} fill={props.fillColors[256]} d="M876.12,984.52c4.87,5.41,9.73,10.82,15.57,17.32c-7.74-4.67-12.27-10.42-16.1-16.84
				C875.77,984.84,875.94,984.68,876.12,984.52z"/>
			<path onClick={() => props.onFill(257)} fill={props.fillColors[257]} d="M1036.44,1036.77c-10.84,15.99-63.4,21.61-73.31,5.93c5.62,0.94,10.29,1.27,14.7,2.53
				c14.55,4.17,28.99,3.02,43.2-1.06C1026.49,1042.61,1031.43,1039.24,1036.44,1036.77z"/>
			<path onClick={() => props.onFill(258)} fill={props.fillColors[258]} d="M961.01,1053.91c15.82,8.67,31.64,11.82,48.45,8.85C994.04,1067.85,968.16,1067.37,961.01,1053.91z"/>
			<path onClick={() => props.onFill(259)} fill={props.fillColors[259]} d="M1025.32,1023.02c-16.34,14.32-52.49,17.13-61,4.48c2.48,0.25,4.4,0.04,5.99,0.67
				C989.01,1035.46,1006.71,1031.73,1025.32,1023.02z"/>
			<path onClick={() => props.onFill(260)} fill={props.fillColors[260]} d="M886.91,1011.51c9.22,4.14,17.4,7.81,24.72,11.1C902.67,1023.29,895.35,1017.58,886.91,1011.51z"/>
			<path onClick={() => props.onFill(261)} fill={props.fillColors[261]} d="M876.2,955.43c-4.59-6.61-9.18-13.21-13.77-19.82c0.56-0.39,1.12-0.79,1.69-1.18
				c4.57,6.62,9.14,13.24,13.72,19.86C877.29,954.66,876.74,955.04,876.2,955.43z"/>
			<path onClick={() => props.onFill(262)} fill={props.fillColors[262]} d="M732.25,567.46c3.21,6.79,6.36,13.61,9.67,20.35c1.42,2.89,2.73,6.05,4.88,8.34
				c10.96,11.67,16.58,29.1,13.28,44.56c-1.57-5.61-2.51-10.23-4.21-14.55c-1.03-2.61-2.98-5-4.94-7.08
				c-6.46-6.83-13.15-13.45-19.64-20.25c-0.62-0.65-0.93-2.26-0.58-3.1c3.85-9.21,3.82-18.34-0.61-27.49
				C730.81,567.98,731.53,567.72,732.25,567.46z"/>
			<path onClick={() => props.onFill(263)} fill={props.fillColors[263]} d="M530.99,567.38c-1.57,3.05-2.55,6.64-4.8,9.05c-7.69,8.24-15.81,16.07-23.8,24.03
				c-1.27,1.27-2.49,2.91-4.06,3.5c-8.13,3.07-16.38,5.86-25.76,9.17c15.33-21.49,37-33.03,57.1-46.51
				C530.11,566.87,530.55,567.12,530.99,567.38z"/>
			<path onClick={() => props.onFill(264)} fill={props.fillColors[264]} d="M760.82,563.08c7.05,11.22,14.96,23.8,22.27,35.44c-5.16-3.2-11.82-6.96-17.98-11.4
				c-1.78-1.28-2.74-4.46-3.04-6.9C761.31,574.17,761.15,568.04,760.82,563.08z"/>
			<path onClick={() => props.onFill(265)} fill={props.fillColors[265]} d="M427.95,915.24c-1.14-2.24-2.5-4.4-3.36-6.74c-0.85-2.32-1.25-4.81-1.75-7.24
				c-1.94-9.43-6.44-16.76-15.5-21.21c-5.96-2.93-9.94-8.19-12.7-14.28c-2.16-4.79-4.69-9.25-10.44-10.54
				c-0.59-0.13-1.18-0.66-1.59-1.15c-8.25-10.04-17.99-18.08-30.23-22.84c-0.71-0.28-1.28-0.92-1.39-2.41
				c4.55,2.12,9.04,4.37,13.67,6.3c3.11,1.29,6.61,1.74,9.59,3.25c3.33,1.69,6.84,3.64,9.25,6.38c4.3,4.91,9,6.1,15.14,4.52
				c1.83-0.47,4.54,0.41,6.18,1.6c7.05,5.07,14.12,9.88,22.94,11.51c5.67,1.05,7.38,0.44,10.2-6.06c2.89,3.6,5.37,6.7,7.86,9.8
				c-0.16,0.47-0.32,0.93-0.48,1.4c-11.21-3.9-16.93,3.25-20.88,10.99c-2.08,4.07-1.28,10.02-0.62,14.96
				c0.69,5.19,2.8,10.19,4.23,15.3c0.54,1.94,0.91,3.93,1.35,5.9C428.92,914.87,428.44,915.06,427.95,915.24z"/>
			<path onClick={() => props.onFill(266)} fill={props.fillColors[266]} d="M461.26,489.45c0.75-7.26,1.4-13.6,2.05-19.94c-0.59-0.33-1.18-0.67-1.77-1
				c-3.17,3.91-6.34,7.81-9.83,12.12c-2.17-4.98-0.46-9.66,0.82-14.24c1.03-3.7,2.37-6.56,7.54-6.76c6.82-0.26,10.34-5.9,14.2-11.53
				c0.3,2.17,0.61,4.35,0.97,6.93c1.52-0.55,2.95-1.06,4.58-1.64c-2.62,8.71-4.53,17.52-8.04,25.65
				C470.11,482.91,465.42,485.48,461.26,489.45z"/>
			<path onClick={() => props.onFill(267)} fill={props.fillColors[267]} d="M745.27,460.72c8.87-7.19,14.4-18.28,23.84-25.29c0.76,0.76,1.52,1.52,2.27,2.28
				c-3.84,2.91-7.68,5.82-11.51,8.73c0.39,0.65,0.77,1.3,1.16,1.94c2.25,0.46,4.51,0.93,6.76,1.39c0.13,0.39,0.26,0.77,0.38,1.16
				C760.53,454.2,752.9,457.46,745.27,460.72z"/>
			<path onClick={() => props.onFill(268)} fill={props.fillColors[268]} d="M491.22,497.34c5.49-8.42,7.81-19.39,18.84-23.38C505.37,483.02,501.53,492.78,491.22,497.34z"/>
			<path onClick={() => props.onFill(269)} fill={props.fillColors[269]} d="M488.55,463c0.45,1.56,0.45,2.39,0.79,2.57c3.9,2.06,4.69,4.46,2.01,8.39c-1.9,2.78-2.49,6.37-6.95,6.57
				c-1.64,0.07-3.22,1.38-5.86,2.6C482.01,476.16,485.05,470.06,488.55,463z"/>
			<path onClick={() => props.onFill(270)} fill={props.fillColors[270]} d="M520.49,847.83c0-6.71,0-13.42,0-20.13c0.16,0,0.31,0,0.47,0c0,6.66,0,13.33,0,19.99
				C520.8,847.73,520.65,847.78,520.49,847.83z"/>
			<path onClick={() => props.onFill(271)} fill={props.fillColors[271]} d="M116.47,1002.23c3,4.02,5.99,8.04,9.48,12.73c-1.99,0.2-2.84,0.29-5.16,0.52
				c4.07,6.82,7.4,13.39,11.7,19.25c2.39,3.26,12.84,0.83,15.17-2.61c2.03-3,4.83-5.51,6.63-8.62c2.37-4.11,4.1-8.6,6.07-12.86
				c2.47,4.74,5.36,10.24,8.19,15.77c0.27,0.53,0.4,1.33,0.21,1.88c-1.81,5.22-4.31,10.29-5.41,15.65c-0.96,4.69-0.54,9.71-0.4,14.57
				c0.05,1.89,1.02,3.75,0.69,6.41c-2.49-2.4-5.21-4.62-7.44-7.25c-4.84-5.73-9.63-11.04-17.54-12.96
				c-6.63-1.61-9.79-7.55-12.55-13.36c-2.06-4.35-4.3-8.64-6.01-13.12c-0.9-2.37-0.69-5.14-1.25-7.67c-0.63-2.83-1.56-5.6-2.36-8.39
				C116.5,1002.18,116.47,1002.23,116.47,1002.23z"/>
			<path onClick={() => props.onFill(272)} fill={props.fillColors[272]} d="M116.5,1002.18c-6.39-7.07-7.5-14.56-2.58-23.08c1.66-2.87,1.84-6.59,2.81-10.39
				c1.93,2.68,3.24,4.53,1.22,8.16c-4.48,8.02-5.9,16.62-1.47,25.36C116.47,1002.23,116.5,1002.18,116.5,1002.18z"/>
			<path onClick={() => props.onFill(273)} fill={props.fillColors[273]} d="M853.83,801.11c-2.58-14.13-5.33-28.23-7.69-42.4c-3.3-19.81-6.61-39.63-9.34-59.53
				c-2.31-16.78-3.69-33.7-5.72-50.52c-2.3-19.12-4.87-38.21-7.33-57.32c-2.91-22.57-5.95-45.12-8.71-67.7
				c-3.05-24.9-5.9-49.81-8.7-74.74c-0.41-3.66-0.06-7.42-0.06-11.13c0.83-0.05,1.67-0.1,2.5-0.16c3.72,27.72,8.02,55.39,11.02,83.19
				c3.64,33.67,6.12,67.42,12.45,100.82c4.38,23.12,5.76,46.8,8.64,70.21c2.33,18.95,4.93,37.87,7.37,56.8
				c2.25,17.43,4.46,34.86,6.68,52.29C854.57,800.99,854.2,801.05,853.83,801.11z"/>
			<path onClick={() => props.onFill(274)} fill={props.fillColors[274]} d="M798.35,441.34c11.72,41.07,8.83,83.17,13.61,125.16C807.14,555.79,798.47,457.52,798.35,441.34z"/>
			<path onClick={() => props.onFill(275)} fill={props.fillColors[275]} d="M832.39,725.97c-5.04-38.96-10.08-77.92-15.12-116.88c0.54-0.07,1.08-0.14,1.61-0.21
				c5.18,38.95,10.35,77.89,15.53,116.84C833.74,725.8,833.07,725.88,832.39,725.97z"/>
			<path onClick={() => props.onFill(276)} fill={props.fillColors[276]} d="M858.51,829.85c2.54,21.43,5.08,42.86,7.62,64.3C859.42,873.25,860.18,851.39,858.51,829.85z"/>
			<path onClick={() => props.onFill(277)} fill={props.fillColors[277]} d="M841.59,782.12c1.99,17.23,3.98,34.47,5.98,51.7c-0.47,0.05-0.93,0.11-1.4,0.16
				c-1.96-17.24-3.91-34.47-5.87-51.71C840.73,782.23,841.16,782.18,841.59,782.12z"/>
			<path onClick={() => props.onFill(278)} fill={props.fillColors[278]} d="M803.82,587.69c3.04,20.47,6.69,40.88,8.97,61.44c3.45,31.23,5.79,62.58,8.89,93.85
				c2.3,23.16,4.61,46.33,7.74,69.39c3.63,26.77,8.15,53.41,11.95,80.27c-1.79-7.31-4.08-14.54-5.27-21.95
				c-4.49-28.04-8.98-56.09-12.77-84.23c-3.28-24.36-5.76-48.84-8.33-73.3c-2.76-26.29-5.15-52.63-7.81-78.93
				c-1.56-15.36-3.34-30.7-5.02-46.05C802.71,588.02,803.27,587.85,803.82,587.69z"/>
			<path onClick={() => props.onFill(279)} fill={props.fillColors[279]} d="M797.3,534.79c-4.39-29.13-11.56-58-8.74-87.79C791.47,476.27,794.38,505.53,797.3,534.79z"/>
			<path onClick={() => props.onFill(280)} fill={props.fillColors[280]} d="M882.78,882.2c-1.59-8.2-4.23-16.36-4.54-24.61c-0.63-16.69-6.19-32.16-9.71-48.17
				c-6.7-30.45-13.51-60.94-18.34-91.71c-4.16-26.55-5.49-53.53-8.32-80.3c-2.32-21.96-5.11-43.87-7.51-65.81
				c-1.05-9.55-1.66-19.15-1.35-28.83c1.57,14.63,3.06,29.27,4.71,43.88c2.08,18.46,4.3,36.91,6.43,55.36
				c1.54,13.37,2.74,26.79,4.63,40.1c2.86,20.04,5.72,40.1,9.43,59.99c6.76,36.2,14.28,72.26,21.17,108.44
				c1.98,10.39,2.91,20.98,4.32,31.48C883.41,882.09,883.09,882.15,882.78,882.2z"/>
			<path onClick={() => props.onFill(281)} fill={props.fillColors[281]} d="M828.58,525.31c-4.31-24.42-8.62-48.83-12.93-73.25c9.22,23.54,9.45,48.69,14.28,73.01
				C829.48,525.15,829.03,525.23,828.58,525.31z"/>
			<path onClick={() => props.onFill(282)} fill={props.fillColors[282]} d="M889.37,932.85c-8.24-13.83-11.36-28.34-14.25-42.93C879.76,903.9,884.4,917.89,889.37,932.85z"/>
			<path onClick={() => props.onFill(283)} fill={props.fillColors[283]} d="M143.1,1360.57c-6.55,3.43-12.99,6.44-20.81,7.09c-8.96,0.74-17.77,3.28-26.63,5.11
				c-6.13,1.27-12.23,2.69-18.54,4.08c1.41-1.05,2.81-2.1,4.85-3.61c-11.16,0-21.5,0-31.68,0c4.37-0.78,9.05-1.74,13.77-2.42
				c10.49-1.51,20.98-3.12,31.52-4.23c9.51-1,19.09-1.87,28.64-1.83c4.58,0.02,8.37-1.02,12.22-3.02c1.98-1.03,4.16-1.68,6.25-2.51
				C142.83,1359.68,142.97,1360.12,143.1,1360.57z"/>
			<path onClick={() => props.onFill(284)} fill={props.fillColors[284]} d="M276.1,997.88c7.37,7.31,14.74,14.63,23.09,22.91c-2.86,0.35-4.12,0.51-6.09,0.75
				c0.89,2.38,1.45,4.41,2.38,6.25c1.28,2.53,1.61,4.5,0.73,7.66c-0.91,3.25,1.32,7.37,2.27,11.52c-3.04-3.4-5.98-6.68-8.36-9.35
				c0.64-2.83,1-5.08,1.67-7.23c3.01-9.65-3.75-14.8-9.28-20.56c-2.42-2.52-4.85-5.03-7.09-7.71c-0.71-0.85-0.8-2.21-1.17-3.34
				C274.87,998.48,275.49,998.18,276.1,997.88z"/>
			<path onClick={() => props.onFill(285)} fill={props.fillColors[285]} d="M258.32,1091.55c0,6.03,0,12.05,0,18.08c-0.19,0-0.37,0.01-0.56,0.01c0-6.02,0-12.04,0-18.06
				C257.95,1091.57,258.13,1091.56,258.32,1091.55z"/>
			<path onClick={() => props.onFill(286)} fill={props.fillColors[286]} d="M265.76,1070.34c-0.58-6.49-1.16-12.97-1.74-19.46c0.42-0.04,0.85-0.07,1.27-0.11
				c0.51,6.48,1.02,12.97,1.53,19.45C266.46,1070.26,266.11,1070.3,265.76,1070.34z"/>
			<path onClick={() => props.onFill(287)} fill={props.fillColors[287]} d="M702.8,1459.75c19.57,2.85,25.32,0.85,29.79-10.3c4.05,0.57,8.25,1.15,12.44,1.74
				c-6.67-0.73-11.05,2.8-15.87,6.74C722.1,1463.72,712.33,1464.07,702.8,1459.75z"/>
			<path onClick={() => props.onFill(288)} fill={props.fillColors[288]} d="M330.24,1462.91c-1.11,1.25-1.95,2.19-3.01,3.38c1.9,1.69,3.62,3.22,5.49,4.88
				c-5.93,3.33-11.33,6.37-15.44,8.69c-2.63-0.69-4.52-0.99-6.24-1.7c-1.17-0.48-3.01-1.51-3.02-2.33c-0.11-5.57-4.97-7.1-8.39-8.2
				c-2.09-0.67-5.51,2.88-8.36,4.48c-1.37,0.77-2.8,1.43-3.03,1.54c-2.88-2.83-5.4-5.31-8.94-8.79c2.71-0.17,4.68-0.3,7.55-0.48
				c1.97-2.23,4.72-5.35,7.25-8.2c2.85,2.7,4.77,5.77,7.47,6.81c4.95,1.89,2.72-5.67,5.67-5.83c3.48,3.45,6.46,6.62,9.74,9.42
				c0.52,0.44,2.46-0.79,3.87-1.29c0.6,0.57,1.46,1.38,2.32,2.19c0.83-1.1,2.2-2.12,2.39-3.33
				C326.09,1460.87,327.31,1460.53,330.24,1462.91z"/>
			<path onClick={() => props.onFill(289)} fill={props.fillColors[289]} d="M195.07,1226.4c0.78-6.11,3.07-8.2,8.2-7.62c-2.37,4.16-4.65,8.35-7.1,12.44
				c-2.78,4.64-6.25,5.78-9.82,2.49c-4.86-4.49-5.96,0.43-8.12,2.75c-3.48-2.33-3.08-4.49-0.47-6.91
				C183.03,1224.65,189.31,1227.12,195.07,1226.4z"/>
			<path onClick={() => props.onFill(290)} fill={props.fillColors[290]} d="M172.48,1226.98c-3.28-0.38-6.56-0.85-9.85-1.11c-5.25-0.42-6.03,4.33-8.22,7.4
				c-0.39,0.54-0.25,1.46-0.35,2.18c-5.69-0.5-6.26-1.4-3.6-5.32c1.29-1.9,1.65-5.1,5.31-3.99c0.37,0.11,1.05-0.68,1.55-1.1
				c1.61-1.39,3.48-4.19,4.75-3.94c3.77,0.76,7.3,2.71,10.93,4.2C172.83,1225.85,172.65,1226.41,172.48,1226.98z"/>
			<path onClick={() => props.onFill(291)} fill={props.fillColors[291]} d="M206.52,1221.4c2.94-1.42,5.23-2.52,7.98-3.85c-1.52,2.48-2.75,4.47-4.29,6.97
				C209.3,1223.75,208.16,1222.79,206.52,1221.4z"/>
			<path onClick={() => props.onFill(292)} fill={props.fillColors[292]} d="M1127.45,478.44c-2.73,6.61-5.47,13.22-8.43,20.39C1115.22,490.74,1116.29,488.15,1127.45,478.44z"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1162,1402.05c0-466.93,0-933.87,0-1401.05C775.31,1,388.62,1,1.44,1c0,1.57,0,3.41,0,5.25c0,99.45,0,198.9,0,298.35
				c0,22.99-0.09,45.98,0.13,68.96c0.02,2.34,1.22,5.73,2.98,6.81c6.53,3.98,13.46,7.34,20.38,10.64c5.72,2.73,11.57,5.21,17.47,7.49
				c5.4,2.09,10.96,3.06,16.47,0.06c0.78-0.43,1.69-1.03,2.49-0.96c9.06,0.76,14.83-5.92,22.13-9.22
				c10.37-4.69,19.91-11.85,31.81-12.84c4.85-0.4,7.18-4.24,10.04-7.44c1.56-1.74,3.61-3.04,5.44-4.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M968.74,1428.54c0.33-6.16,0.88-12.32,0.95-18.49c0.15-12.66-0.34-25.34,0.15-37.98c0.5-12.68,2.46-25.31,2.74-37.99
				c0.32-14.48-0.52-28.98-0.85-43.47c-0.01-0.5,0-1,0-1.5c-0.33-39.65-0.98-79.29-0.86-118.94c0.06-19.49,1.85-38.98,2.19-58.47
				c0.11-6.64-1.83-13.32-2.82-19.99c-0.52-3.5-0.68-7.09-1.62-10.46c-0.61-2.18-2.34-4.05-3.57-6.06c-0.61,0.13-1.22,0.26-1.82,0.39
				c-0.16,2.38-0.62,4.78-0.42,7.13c0.49,5.85,1.53,11.65,1.87,17.5c0.32,5.49-0.67,10.67-2.55,16.12
				c-2.23,6.45-2.07,13.85-2.28,20.86c-0.33,10.65-0.41,21.33,0.01,31.98c0.4,10.18,1.73,20.32,2.32,30.49
				c0.21,3.63-0.81,7.32-0.86,10.99c-0.06,4.83-0.7,9.96,0.69,14.42c1.79,5.77,1.9,11.26,1.34,17.07c-0.3,3.12,0.37,6.32,0.39,9.49
				c0.06,9.16-0.19,18.33,0.07,27.48c0.51,18,2.01,35.99,1.78,53.97c-0.34,26.5-1.76,52.98-2.88,79.46
				c-0.23,5.37-1.21,10.71-1.88,16.37c-1.9-0.67-3.19-1.12-4.52-1.59c-0.78,6.08,0.57,8.16,6.67,10.29
				c-1.55,3.09-2.03,5.85,1.12,8.62c0.8,0.7-0.28,3.87-0.89,5.78c-0.51,1.6-1.5,3.09-2.45,4.51c-2.17,3.24-0.69,4.49,2.51,5.04
				c1.03,0.17,2.32,0.35,2.94,1.03c1.66,1.81,3.04,2.98,5.18,0.56c0.18-0.21,2.2,1.22,3.72,2.09c0.78-0.96,1.59-1.97,2.6-3.22
				c0.75,0.45,1.83,1.09,3.21,1.91c0.59-1.69,1.07-3.09,1.85-5.34c1.32,2.04,2.23,3.44,2.86,4.42c4.13-3.16,8.39-6.42,13.29-10.16
				c-0.04-0.02,0.97,0.49,1.79,1.22c2.35,2.09,3.55,2.11,3.88-0.53c0.57-4.53,2.53-3.16,5.15-2.04c3.27,1.41,4.25-0.35,4-3.44
				c-0.15-1.82-0.03-3.66-0.03-6.17c-8.21-3.95,1.09-5.72,2.12-8.82c-1.73-2.53-3.42-5.01-5.11-7.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1097.58,391.55c0.67,6.5,1.92,13,1.9,19.49c-0.13,35.32-0.79,70.63-0.83,105.95c-0.03,26.65,0.6,53.31,0.93,79.96
				c0.34,27.65,1.07,55.31,0.89,82.96c-0.21,32.49-0.24,65.03-2.19,97.43c-1.69,27.99-3.53,55.94-3.69,83.98
				c-0.05,8.66,0.11,17.33-0.03,25.99c-0.24,14.66-0.3,29.34-1.05,43.97c-1.17,22.62-3.58,45.09-9.87,66.98
				c-1.12,3.88-1.78,7.89-2.65,11.84c-0.7-0.1-1.4-0.19-2.1-0.29c-0.09-2.02-0.36-4.05-0.24-6.06c0.6-9.67,1.19-19.33,2.01-28.98
				c0.4-4.69,1.61-9.31,1.88-14c0.44-7.81,0.15-15.67,0.65-23.48c0.49-7.68,1.59-15.33,2.42-22.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,130.18c-9.96-3.66-20.07-5.36-30.44-1.95c-2.45,0.81-4.75,2.15-6.98,3.5c-1.17,0.71-2.07,1.91-3.02,2.95
				c-5.91,6.56-12.52,10.15-21.82,12.22c-10.69,2.39-20.36,1.79-30.64,0.35c-9.14-1.28-17.56,0.05-22.49,9.39
				c-0.77,1.45-1.96,2.68-2.97,4.01c-0.14,0.19-0.33,0.34-0.6,0.61c-9.02-4.06-17.78-8.99-28.37-8.18
				c-9.13,0.71-18.77-0.94-27.36,1.44c-16.69,4.63-33.44,6.14-50.53,5.56c-2.18-0.07-4.75-0.71-6.43-2
				c-11.77-9.05-25.22-9.22-39.02-7.91c-3.83,0.36-7.73,1.31-11.47,0.89c-15.73-1.76-31.34-1.07-46.96,1.06
				c-2.78,0.38-5.68-0.18-8.48,0.11c-4.28,0.43-8.76,0.21-11.17,5.36c-0.93,2-3.66,3.41-5.85,4.52c-8.82,4.49-18.34,3.06-27.41,1.85
				c-12.76-1.69-25.44-2.6-37.92,0.44c-4.44,1.08-7.7-0.29-11.57-1.13c-9.59-2.08-19.16-2.65-28.47,1.84
				c-7.15,3.45-14.79,4.28-22.43,1.98c-7.55-2.27-14.92-1.11-21.38,2.28c-7.45,3.9-15.27,4.34-23.09,4.64
				c-10.12,0.39-20.07,0.83-29.47,5.12c-6.54,2.99-7.91,5.55-5.98,12.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M978.23,525.49c-7.58,33.5-5.23,67.55-5.39,101.45c-0.03,6.33,1.38,12.65,1.84,18.99c0.29,3.98-0.2,8.01,0.12,11.98
				c0.39,4.85,1.79,9.66,1.83,14.5c0.12,13.16,0.14,26.34-0.49,39.47c-0.76,15.84-2.34,31.65-3.38,47.48
				c-1.43,21.82-2.73,43.64-4.01,65.47c-0.9,15.32-2.08,30.64-2.41,45.98c-0.42,19.48,0.21,38.99-0.2,58.47
				c-0.31,14.67-1.37,29.34-2.54,43.97c-0.28,3.47,0,8.02-4.85,9.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M988.72,536.48c0.33,1.17,0.92,2.32,0.96,3.5c0.4,10.66,0.37,21.35,1.12,31.98c0.99,14.02,2.77,27.98,3.84,41.99
				c0.69,8.97,1.05,17.99,1,26.98c-0.11,18.16-0.44,36.32-0.99,54.47c-0.33,10.67-1.71,21.32-1.86,31.99
				c-0.53,39.98-0.58,79.96-1.13,119.94c-0.37,26.66-1.26,53.31-1.95,79.96c-0.3,11.66-0.5,23.33-1.05,34.98
				c-0.37,7.84-1.39,15.65-1.88,23.49c-0.19,2.98,0.28,5.99,0.44,8.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M997.7,530.48c1.33,9.33,3.27,18.62,3.87,27.99c1.39,21.63,2.5,43.29,3.03,64.96c0.83,34.14,1.58,68.3,1.39,102.45
				c-0.1,19.33-2.14,38.65-3.29,57.97c-0.08,1.33-0.01,2.66-0.01,4c0,32.82,0.06,65.63-0.05,98.45c-0.02,6.83-0.72,13.66-0.89,20.49
				c-0.05,2.16,0.94,4.34,0.88,6.49c-0.4,14.83-0.86,29.66-1.49,44.48c-0.2,4.84-0.97,9.66-1.45,14.5c-0.36,3.66-0.67,7.33-1,10.99"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M789.96,431.53c-0.91,19.15,1.54,38.05,3.91,56.98c1.77,14.11,2.96,28.29,4.01,42.47c0.84,11.3,0.93,22.66,1.62,33.97
				c0.34,5.52,1.37,10.99,1.92,16.5c2.53,25.15,5.16,50.29,7.46,75.46c2.2,23.97,3.68,48.01,6.15,71.95
				c1.93,18.72,4.86,37.33,7.38,55.98c2.14,15.83,4.07,31.69,6.56,47.47c2.69,17.04,5.38,34.12,9.16,50.93
				c1.72,7.65,6.08,14.71,9.24,22.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M810.44,434.53c6.27,15.18,12.25,30.39,14.07,46.97c1.59,14.54,4.37,28.95,5.79,43.5c1.67,17.11,2.1,34.34,3.71,51.45
				c1.74,18.53,4.34,36.98,6.36,55.49c1.36,12.47,2.17,25.01,3.58,37.47c2.51,22.34,4.46,44.79,8.17,66.93
				c4,23.82,9.63,47.38,14.83,70.99c1.89,8.59,5.08,16.91,6.79,25.53c1.9,9.55,2.45,19.36,4.23,28.94
				c1.48,7.93,3.89,15.68,5.89,23.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M990.21,520.49c-4.6,19.66-4.37,39.5-2.58,59.48c1.11,12.45,1.91,24.98,1.98,37.47c0.16,27.98,0.12,55.98-0.48,83.96
				c-0.83,38.49-2.2,76.96-3.43,115.44c-0.74,23.16-2.07,46.31-2.35,69.47c-0.26,21.32,0.8,42.64,0.74,63.97
				c-0.02,8-1.38,16.01-2.45,23.98c-0.36,2.66-1.7,5.2-2.89,8.64c-4.27-6.37-5.95-12.27-6-18.63c-0.07-8-0.37-16.01,0.06-23.98
				c0.69-12.68,2.41-25.31,2.76-37.99c0.28-10.14-0.38-20.37-1.44-30.47c-1.81-17.14-3.3-34.24-1.9-51.49
				c0.89-10.99,1.64-21.99,2.52-32.98c1.3-16.16,3.16-32.3,3.85-48.48c0.76-17.97,0.55-35.98,0.56-53.97c0-3.16-0.93-6.33-1.43-9.5"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M966.24,531.98c-0.5,5.5-1.18,10.99-1.46,16.49c-0.48,9.32-0.93,18.65-0.97,27.98c-0.03,7.16,0.88,14.33,0.85,21.49
				c-0.03,8.66-1.12,17.35-0.78,25.98c0.36,9.19,1.86,18.33,2.85,27.49c0.36,3.33,0.68,6.66,1,9.99c1.24,12.87,0.95,25.61-0.73,38.52
				c-1.32,10.16-0.14,20.62-0.3,30.95c-0.22,14.83,0.45,29.77-1.19,44.45c-3.49,31.3-2.14,62.65-2.93,93.97
				c-0.43,17.01-2.31,34.01-3.91,50.97c-0.92,9.7-2.59,19.33-3.92,28.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M512.8,692.4c0.5,11.66,1.53,23.33,1.38,34.98c-0.26,20.99-0.01,42.11-2.24,62.93c-1.99,18.56-1.24,37.02-1.58,55.51
				c-0.56,30.48-0.67,60.97-1.08,91.45c-0.2,14.83-0.4,29.66-1.04,44.48c-0.72,16.67-1.78,33.33-2.99,49.97
				c-0.67,9.19-1.6,18.39-3.12,27.46c-0.56,3.35-3.15,6.36-4.82,9.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M370.98,321.09c-4.49-1-8.98-2.78-13.49-2.83c-8.34-0.08-16.39-1.11-24.5-3.02c-7.16-1.69-14.59-2.37-21.95-3.04
				c-3.27-0.29-6.97-0.2-9.92,1.04c-8.02,3.39-15.53,1.66-23.02-1.19c-7.3-2.78-14.37-1.55-21.49,0.53
				c-1.82,0.53-3.66,1.41-5.49,1.44c-10.98,0.14-21.97,0.15-32.96,0c-2.17-0.03-4.51-0.56-6.46-1.5c-6.59-3.16-13.12-2.88-20.04-1.04
				c-6.28,1.66-12.8,2.4-19.25,3.55c-1.46-10.82-5.54-12.68-13.69-12.08c-4.1,0.3-8.3-1.01-12.48-1.32
				c-4.65-0.34-9.68-1.54-13.88-0.23c-4.81,1.49-9.01,0.78-13.59,0.25c-8.96-1.04-17.96-2.11-26.96-2.41
				c-5.35-0.18-10.4,2.18-13.91,6.41c-3.87,4.65-7.99,6.26-14.09,4.59c-7.15-1.96-14.5-1.91-21.98-0.35
				c-3.92,0.82-8.28-0.78-12.44-0.73c-8.99,0.12-17.98,0.6-26.97,0.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1003.2,527.49c2,6.5,4.78,12.87,5.83,19.52c2.06,13.07,4.17,26.27,4.46,39.46c0.65,29.8,0.46,59.64,0.13,89.45
				c-0.32,28.82-1.15,57.64-1.98,86.46c-0.35,12.17-1.55,24.32-1.88,36.48c-0.56,20.15-1.39,40.33-0.82,60.46
				c0.36,12.7,3.09,25.33,4.75,37.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M807.44,435.03c2.16,10.16,4.99,20.24,6.36,30.5c3.09,23.1,5.44,46.29,8.13,69.45c3.16,27.22,4.57,54.61,10.7,81.52
				c3.56,15.64,4.35,31.91,6.26,47.91c1.76,14.81,3.16,29.67,5.06,44.47c3.17,24.67,6.81,49.29,9.86,73.98
				c1.48,11.93,2.18,23.97,3.06,35.97c1.42,19.48,2.3,39.02,4.23,58.45c0.66,6.63,3.78,13.01,5.78,19.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M832.91,540.48c1.83,15.16,3.61,30.33,5.51,45.48c2.78,22.16,5.8,44.29,8.41,66.48c1.37,11.62,1.55,23.39,3.18,34.96
				c2.55,18.05,5.58,36.05,8.95,53.98c4.01,21.38,8.79,42.62,12.85,63.99c3.63,19.1,6.77,38.29,10.02,57.46
				c1.01,5.97,1.62,12,2.55,17.98c0.72,4.68,1.64,9.33,2.47,14"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M171.73,608.95c-0.33,1.17-0.41,2.5-1.04,3.47c-4.75,7.38-9.44,14.81-14.49,21.98c-4.24,6.01-9.16,11.54-13.38,17.56
				c-7.5,10.7-14.58,21.7-22.06,32.43c-7.2,10.33-14.53,20.15-25.19,27.85c-13.01,9.4-24.02,21.55-36.3,32.03
				c-4.9,4.19-10.62,7.66-16.44,10.49c-12.25,5.97-22.24,14.86-32.4,23.61c-0.36,0.31-0.61,0.83-1.01,0.97
				c-8.45,2.96-8.1,9.8-8.07,17.02c0.18,40.31,0.08,80.63,0.11,120.94c0,1.67,0.31,3.33,0.48,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M658.12,941.78c3.56,6.87,2.68,10.65-3.5,14.99c-4.66,3.28-8.19,14.93-5.98,19.99c3.13,7.18,6.28,14.35,9.51,21.48
				c0.58,1.28,1.43,3.11,2.49,3.39c5.87,1.53,7.09,6.77,10.02,12.12c-5.7,0-10.96,0.57-16-0.25c-2.4-0.39-4.66-3.17-6.46-5.31
				c-2.15-2.56-3.96-5.48-5.52-8.46c-3.87-7.37-10.22-9.78-18.02-9.46c-5,0.21-10,0.57-15.05,0.87c0.32,15.47,5.95,29.1,13.91,41.99
				c-7.82,2.25-16.86-1.4-20.21-8.95c-4.23-9.53-10.53-16.59-20.63-19.31c-5.69-1.53-6.4-3.97-5.69-9.65
				c2.29-18.25,3.75-36.61,5.13-54.95c0.56-7.45-0.13-14.99,0.14-22.48c0.5-13.67,1.84-27.32,1.78-40.98
				c-0.05-12.16-1.42-24.33-2.37-36.48c-0.39-5.01-4.24-6.46-8.44-7.55c-3.16-0.82-6.5-1.02-7.83,2.12
				c-1.42,3.34-2.04,7.25-2.08,10.92c-0.47,36.48-0.24,72.98-1.27,109.44c-0.63,22.2-2.73,44.42-8.84,65.96
				c-2.96,10.43-11.52,20.65-23.43,19"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M952.26,1054.72c3.98,9.24,11.45,14.44,20.46,18.02c7.39,2.94,14.65,1.16,21.99,0.01c3.63-0.57,7.44-1.37,10.93-0.75
				c1.64,0.29,3.74,3.64,3.88,5.72c0.7,10.97,0.79,21.98,1.19,32.97c0.23,6.17,1.07,12.34,0.9,18.49
				c-0.41,14.83-1.46,29.65-1.86,44.48c-0.54,20.15-0.93,40.31-0.98,60.47c-0.03,14.49,0.54,28.99,0.96,43.48
				c0.15,5.34,0.96,10.67,0.91,15.99c-0.16,15.66-0.65,31.32-0.92,46.98c-0.12,7.16,0.04,14.33-0.04,21.49
				c-0.1,9.33-0.3,18.66-0.53,27.99c-0.02,0.67-0.63,1.33-1.2,2.45c-1.75-3.26-6.55-3.71-6.34-8.45c0.03-0.64-1.21-1.88-1.91-1.91
				c-5.26-0.24-3.95-4.04-3.98-7.08c-0.34-30.65-0.33-61.31-1.17-91.95c-0.34-12.52-2.7-24.98-3.75-37.49
				c-0.5-5.96-0.09-11.99-0.09-17.98c0-11.16,0-22.32,0-33.48c0-13.49,0.25-26.99-0.08-40.48c-0.25-10.01-1.42-19.99-1.83-29.99
				c-0.17-4.14,0.86-8.33,0.8-12.49c-0.03-2.17-0.55-5.37-2-6.32c-5.81-3.79-4.36-9.99-5.86-15.18c-1.09-3.8-2.02-7.66-3.01-11.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M802.95,585.46c3.33,18.16,7.31,36.23,9.81,54.5c2.76,20.22,4.26,40.61,6.13,60.95c1.18,12.81,1.84,25.67,3.08,38.47
				c1.25,12.85,2.92,25.66,4.45,38.49c1.81,15.16,3.28,30.38,5.58,45.46c3.49,22.87,7.57,45.66,11.38,68.48c0.16,0.97,0.02,2,0.02,3"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M978.73,1080.21c-0.17,6.16-0.46,12.33-0.46,18.49c-0.01,5.66,0.3,11.33,0.46,16.99c0.03,1.17,0,2.33,0,3.5
				c0,17.99-0.37,35.99,0.12,53.97c0.36,13.01,1.87,25.99,2.87,38.98c0.01,0.17,0,0.33,0,0.5c0.33,30.32,2.67,60.79,0.33,90.9
				c-1.71,22.07-1.76,44.01-2.23,66.02c-0.14,6.48,0.9,12.99,1.4,19.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M809.94,494.5c3,22.99,5.92,45.99,9.01,68.96c2.2,16.34,4.84,32.63,6.91,48.99c2.25,17.79,3.86,35.67,6.1,53.46
				c2.44,19.35,5.04,38.69,8,57.97c3.39,22.03,7.22,43.99,10.96,65.97c0.77,4.54,1.96,9,2.96,13.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1002.7,1073.21c-4.47,8.35-4.1,17.46-3.96,26.49c0.2,12.66,0.64,25.32,0.96,37.98c0.06,2.33,0.04,4.66,0,7
				c-0.32,17.66-0.37,35.33-1.08,52.97c-0.63,15.81,0.96,31.35,2.82,47.01c1.11,9.32-0.64,18.96-0.68,28.45
				c-0.06,13.66,0.53,27.32,0.37,40.98c-0.14,12-1.01,23.99-1.36,35.98c-0.09,2.99,0.68,5.99,0.92,8.99
				c0.55,6.99,1.01,13.99,1.51,20.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1079.6,346.08c2.5,10.33,5.5,20.57,7.35,31.01c1.95,11.05,2.98,22.28,4.06,33.47c0.58,5.96,0.46,11.99,0.56,17.98
				c0.68,38.48,1.56,76.96,1.92,115.44c0.18,19.82-0.41,39.65-0.97,59.47c-0.35,12.34-1.27,24.66-1.94,36.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M830.41,1207.65c-2-1-4.21-1.72-5.95-3.05c-5.65-4.3-11.69-4.73-18.55-3.67c-4.31,0.67-9.37,0.21-13.37-1.48
				c-9.58-4.05-18.61-9.41-28.13-13.61c-2.55-1.13-2.38-2.37-2.41-4.18c-0.16-9.23-4.98-15.99-13.49-19
				c-5.38-1.91-9.92-4.18-11.17-10.95c-1.28-6.96-5.81-12.55-10.82-17.51c-5.44-5.39-11.3-10.39-16.35-16.1
				c-3.43-3.88-6.96-4.87-11.6-3.41c-1.31,0.41-2.64,0.77-3.99,0.99c-2.61,0.42-3.39,1.96-1.94,3.98c2.28,3.16,4.7,6.28,7.48,8.99
				c7.49,7.33,15.2,14.43,22.7,21.5c-1.15,3.55,0.16,7.79-4.76,8.53c0.33,1.83,0.11,4.06,1.09,5.43
				c5.47,7.63,11.22,15.06,16.89,22.55c0.72,0.95,1.5,1.86,1.86,3.92c-1.95-0.31-3.9-0.79-5.86-0.89
				c-9.68-0.54-17.38-3.88-20.49-14.02c-0.22-0.7-0.48-1.55-1.01-1.97c-5.35-4.23-6.87-10.18-8.79-16.43
				c-1.58-5.15-5.65-9.73-9.25-14.02c-6-7.16-14.29-12.4-17.89-21.56c-0.85-2.16-2.49-4.38-2.36-6.49
				c0.55-8.59-5.13-13.03-10.55-18.08c-5.68-5.29-10.66-11.31-16.1-16.87c-1.86-1.91-4.16-3.39-7.04-4.29
				c5.41,14.88,19.82,23.73,24.74,39.15c-4.27,1.04-8.45,2.81-12.67,2.91c-6.88,0.16-11.29-4.68-14.94-9.85
				c-5.86-8.3-11.45-16.8-17.55-25.78c-2.61,7.18,2.19,11.51,4.66,16.24c3,5.75,7.01,10.97,11.34,17.56c-2.96,0-4.99,0.16-6.98-0.03
				c-6.33-0.59-12.65-1.3-18.98-1.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M820.42,847.33c-0.67,1.67-2.17,3.51-1.86,4.97c2.6,12.21,5.81,24.29,8.27,36.53c1.95,9.74,2.84,19.69,4.7,29.45
				c0.82,4.29,2.85,8.36,4.39,12.51c2.47,6.67,4.97,13.33,7.49,19.99c1.64,4.34,3.14,8.75,5.03,12.98c3.01,6.73,7.02,13.1,9.3,20.06
				c5.04,15.42,13.9,27.24,28.59,34.48c1.33,0.66,2.43,1.88,3.51,2.97c6.61,6.71,15.45,8.53,24,10.92c1.54,0.43,3.33,0.23,4.97,0.03
				c7.2-0.86,7.95-0.98,9.32,6.06c1.76,9.02,6.84,17.31,5.77,26.97c-0.16,1.41,0.98,3.16,1.93,4.45c3.68,5.02,5.19,10.13,3.7,16.58
				c-0.85,3.68,1.09,7.93,1.19,11.94c0.22,8.99,0.1,17.99,0.02,26.98c-0.01,1-0.62,2-0.96,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M996.71,520.99c3.16,3,6.97,5.56,9.36,9.08c6.07,8.94,7.7,19.53,9.59,29.9c4.82,26.5,2.45,53.28,3.04,79.95
				c0.59,26.65,1.59,53.3,1.88,79.96c0.19,17.15-0.29,34.33-0.99,51.47c-0.57,13.84-1.84,27.66-2.92,41.48
				c-2.01,25.8-2.66,51.59-1.11,77.47c1,16.78,0.81,33.64,1.12,50.47c0.2,10.66-0.46,21.42,0.83,31.94
				c0.66,5.42,0.64,10.33-1.44,14.98c-3.66,8.21-13.73,12.13-23.33,8.95c-10.49-3.47-18.68-0.7-25.53,7.56
				c-1.64,1.99-3.55,3.79-5.48,5.5c-4.6,4.06-5.85,9.11-5.02,15.02c0.44,3.13,0.38,6.32,0.55,9.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M660.12,945.28c8.21,7.61,16.42,15.22,24.48,22.7c-3.34,4.29-2.54,6.6-0.09,9.36c5.9,6.62,11.09,13.88,17.2,20.29
				c2.66,2.79,6.65,4.83,10.41,5.92c3.38,0.98,7.32-0.18,10.91,0.32c3.57,0.5,7.35,1.28,10.44,3c11.47,6.39,22.79,13.07,33.98,19.95
				c5.06,3.11,9.64,7,14.55,10.4c2.11,1.46,3.86,2.45,7.13,0.86c3.09-1.51,7.96-1.45,11.24-0.1c7.9,3.25,16.6,5.14,22.71,12.15
				c1.73,1.98,5.08,2.96,7.87,3.54c12.12,2.5,21.15,10.41,30.99,17.01c7.94,5.33,16.05,10.47,24.51,14.9
				c4.9,2.57,8.48,5.99,11.99,10.07c7.53,8.75,15.05,17.63,23.59,25.33c4.27,3.85,10.77,5.23,16.27,7.73"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M926.79,180.66c-17.31-0.67-34.62-1.7-51.94-1.88c-12.81-0.13-25.64,0.79-38.45,1.4c-11.49,0.55-22.99,1.13-34.46,2.01
				c-29.45,2.25-59.06,1.45-88.42,5.76c-15.81,2.32-31.92,3.09-47.92,3.52c-11.29,0.3-22.63-1.61-33.95-1.67
				c-9.32-0.05-18.64,1.63-27.97,1.69c-5.82,0.04-11.82-0.91-17.42-2.53c-4.22-1.23-4.48-4.31-2.08-7.82
				c3.28-4.79,8.42-6.84,13.5-6.75c8.95,0.16,16.83-3.04,24.99-5.66c2.71-0.87,5.69-0.86,8.46-1.6c4.47-1.2,8.93-2.53,11.02-7.44
				c2.6-6.12,8.43-7.25,13.98-8.43c3.57-0.76,7.36-0.46,10.95-1.18c2.43-0.48,5.57-1.26,6.83-3.03c3.14-4.4,7.62-5.22,12.17-5.78
				c5.62-0.69,11.31-0.76,16.97-1.08c0.66-0.04,1.54,0.27,1.97-0.05c7.74-5.87,15.66-1.09,23.5-0.45
				c8.61-11.87,20.91-11.31,34.44-5.96c4.28,1.7,8.35,3.94,12.5,5.95c2,0.97,3.25,0.79,4.57-1.46c4.53-7.68,11.78-10.93,20.4-10.97
				c6.32-0.03,12.66,0.94,18.97,0.82c4.84-0.1,9.64-1.62,14.49-1.78c4.98-0.16,9.98,0.57,14.98,0.88c1.33,0.08,3.14,0.58,3.92-0.08
				c6.97-5.84,14.78-2.64,22.01-1.65c5.5,0.76,9.78,1.96,14.2-3.1c3.74-4.28,10.04-3.83,15.29-3.48c6.54,0.43,13.15,1.62,19.45,3.45
				c9.15,2.65,16.59-1.85,24.5-4.66c2.73-0.97,5.36-2.25,7.99-3.48c2.36-1.1,5.41-1.73,6.87-3.58c7.09-8.95,18.96-9.26,29.4-3.22
				c9.45-8.91,20.84-14.33,33.66-16.63c10.76-1.93,21.43-0.87,31.95,1.95c4.99,1.34,9.98,2.71,14.98,3.99
				c3.61,0.92,6.65,0.66,10.11-1.81c5.64-4.02,10.83-8.51,16.19-12.74c4.89-3.86,12.63-4.15,19.14-5.9c2.45-0.66,5.23-0.55,7.41-1.67
				c1.86-0.96,3.65-2.94,4.46-4.9c2.61-6.3,6.37-11.48,11.5-16.05c6.05-5.39,12.48-5.56,19.57-3.39"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M931.79,170.66c-3.98,2.21-4.22,6.65-4.29,10c-0.54,24.31-0.55,48.64-0.69,72.96c-0.05,9.33-0.17,18.66,0.02,27.99
				c0.58,27.99,1.49,55.97,1.88,83.96c0.17,12.49-0.55,24.99-0.92,37.48c-0.46,15.33-1.11,30.65-1.4,45.98
				c-0.07,3.82,0.57,7.75,1.5,11.47c1.15,4.61-0.67,7.86-3.57,11.03c-5.55,6.07-11,12.24-17,18.93c0,0.88-0.11,3.22,0.04,5.54
				c0.09,1.35,0.48,2.73,0.99,3.99c0.89,2.21,3.3,4.82,2.73,6.43c-2.25,6.32,1.67,11.69,1.88,17.57c0.08,2.2,1.92,4.31,2.83,6.51
				c1.72,4.14,3.63,8.24,4.84,12.53c0.39,1.37-1.16,3.29-1.84,4.95c-0.14,0.34-0.32,0.67-0.5,1c-1.66,3-2.96,8.18-5.07,8.54
				c-4.23,0.71-8.96-1.02-13.39-2.11c-2.43-0.6-4.61-2.18-7.04-2.82c-1.16-0.31-2.92,0.21-3.87,1.01c-0.67,0.56-0.89,2.39-0.52,3.35
				c1.44,3.68,3.19,7.24,5.55,12.42c-3.55-0.39-6.78,0.24-7.97-1.04c-7.37-7.89-16.4-12.57-26.61-15.38
				c-0.39-0.11-0.67-0.64-1.01-0.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M200.69,811.34c-8,5.39-15.1,12.34-24.97,14.49c-7.57,9.09-19.63,6.81-28.99,11.43c-9.35,4.61-18.58,10.26-28.53,12.67
				c-12.42,3.01-21.98,8.81-29.39,18.87c-2.39,3.24-4.93,6.38-7.48,9.5c-7.8,9.52-15.79,18.88-23.39,28.55
				c-3.46,4.4-7.29,3.73-12.07,3.11c-12.39-1.61-23.38,3.28-34.52,8.13c-8.66,3.78-10.33,9.44-10.12,18.19
				c0.61,24.97,0.21,49.97,0.24,74.96c0,1.67,0.31,3.33,0.48,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M235.15,1348.58c7.34,0.52,14.68,1.04,22.02,1.56c0.04,0.47,0.08,0.94,0.12,1.42c-2.55,0.34-5.17,0.42-7.64,1.07
				c-11.01,2.89-22.05,5.74-32.93,9.08c-3.25,1-5.92,3.76-9.06,5.31c-3.54,1.75-7.32,3-10.95,4.57c-3.69,1.6-7.28,3.43-11.01,4.94
				c-2.92,1.19-5.91,2.48-8.99,2.95c-8.12,1.25-16.3,2.05-24.45,3.1c-4.51,0.58-8.97,1.53-13.49,1.92
				c-4.13,0.36-8.34-0.57-12.08,2.44c-1.2,0.96-3.57,0.41-5.39,0.64c-2.34,0.29-5.47-0.18-6.83,1.15c-3.38,3.3-7.32,2.24-11.13,2.94
				c-10.21,1.86-20.33,4.22-30.48,6.4c-3.83,0.82-7.62,2.09-11.49,2.4c-6.46,0.52-12.98,0.35-19.47,0.63
				c-3.67,0.16-7.98-0.49-10.86,1.19c-7.12,4.14-15.13,4.03-22.61,6.24c-1.93,0.57-3.98,0.7-5.98,1.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1003.7,641.43c-0.33,8.33-0.58,16.66-1.01,24.99c-1.29,24.66-3.14,49.3-3.86,73.97c-0.98,33.47-1.58,66.97-1.43,100.45
				c0.08,17.5,2.14,34.98,3.3,52.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M847.39,561.47c2.16,0.83,4.3,1.77,6.5,2.48c4.03,1.3,7.03,3.43,9.1,7.46c3.04,5.9,7.24,11.2,10.25,17.11
				c2.4,4.71,4.36,9.81,5.5,14.96c3.62,16.26,6.81,32.62,10.08,48.95c4.54,22.65,9.09,45.29,13.46,67.97
				c2.37,12.29,4.32,24.65,6.57,36.97c0.4,2.21,1.13,4.41,1.99,6.49c4.94,12.02,9.82,24.06,15,35.97
				c4.18,9.62,11.07,17.27,18.98,23.95c3.73,3.15,7.94,5.72,11.94,8.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M935.78,465.52c-0.67-6-1.59-11.98-1.94-17.99c-0.55-9.65-0.79-19.32-1.04-28.98c-0.39-15.32-1.17-30.66-0.86-45.97
				c0.26-12.68,1.85-25.32,2.84-37.98c0.04-0.5,0.01-1,0.01-1.5c0-20.82,0.22-41.65-0.09-62.47c-0.16-11-1.39-21.98-1.88-32.99
				c-0.79-17.65-1.51-35.31-1.94-52.97c-0.12-4.82-0.14-10.13,1.75-14.33c1.41-3.12,5.75-6.11,9.22-6.73
				c2.62-0.47,6.96,2.17,8.74,4.7c8.56,12.17,10.4,26.4,11.19,40.85c0.53,9.67,1.9,19.33,1.87,28.99
				c-0.14,57.97-0.56,115.94-0.94,173.91c-0.01,1.5-0.63,3-0.96,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1108.07,638.93c0.5-8.33,0.97-16.66,1.51-24.99c0.26-4,0.89-7.99,0.94-12c0.13-11.33-0.34-22.67,0.15-33.98
				c0.55-12.68,2.27-25.31,2.76-37.99c0.34-8.64-0.48-17.32-0.89-25.98c-0.2-4.17-0.86-8.33-0.94-12.5
				c-0.41-20.65-0.07-41.35-1.27-61.95c-0.73-12.57-4.14-24.96-5.65-37.51c-1.47-12.15-4.25-23.88-7.99-35.5
				c-2.89-8.97-8.11-15.43-16.57-19.48c-7.59-3.64-19.14-1.69-23.28,5.15c-2.31,3.81-3.05,8.81-3.59,13.38
				c-0.92,7.78-1.15,15.64-1.61,23.48c-0.86,14.66-1.77,29.31-2.45,43.98c-0.21,4.48,0.39,8.99,0.42,13.49
				c0.07,12.16-0.27,24.33,0.09,36.48c0.58,19.33,2.26,38.65,2.26,57.97c0,25.49-1.06,50.98-1.89,76.46
				c-0.35,10.67-1.68,21.32-1.86,31.99c-0.31,18.99-0.09,37.98-0.09,56.97c0,10.99,0.12,21.99-0.04,32.98
				c-0.1,6.67-0.62,13.33-0.96,19.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M951.26,167.67c7.12,2.87,11.13,8.64,13.42,15.52c4.74,14.24,6.57,29.09,7.43,43.96c0.91,15.63,1.33,31.32,1.01,46.97
				c-0.58,27.66-2.39,55.3-2.73,82.96c-0.33,27.14,0.3,54.31,0.94,81.46c0.35,14.96,1.35,29.93,5.42,44.48
				c2.24,8,5.29,15.59,11.48,21.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M838.4,1214.14c-11.32-12.33-22.58-24.71-34-36.94c-2.89-3.09-6.52-5.48-9.41-8.57c-14.57-15.58-28.98-31.3-43.52-46.91
				c-1.76-1.89-3.72-3.88-6-4.94c-11.63-5.38-20.24-14.72-29.83-22.69c-11.05-9.18-20.78-20.05-32.29-28.55
				c-6.68-4.94-15.6-7.13-23.79-9.64c-4.86-1.49-10.52-0.36-15.37-1.86c-8.03-2.49-15.67-6.22-23.54-9.28
				c-3.92-1.52-7.9-3.04-11.99-3.95c-5.35-1.19-9.99,1.45-14.45,3.94c-9.04,5.06-17.99,10.3-26.98,15.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M786.96,521.49c-3.81,1.28-7.38,2.21-8.26,7.45c-0.46,2.77-3.68,5.09-5.65,7.61c-1.12,0.41-2.24,0.82-3.35,1.22
				c4.26,3.07,8.47,6.19,12.79,9.18c2.47,1.71,2.61,3.76,2.06,6.53c-1.39,6.95-2.34,13.99-3.62,20.97c-0.33,1.79-1.24,3.48-2,5.52
				c-3.84-4.15-4.86-7.69-2.15-12.62c1.77-3.23,1.77-7.53,2.14-11.39c0.37-3.88,0.08-7.36-3.28-10.64
				c-4.65-4.55-8.29-10.13-13.1-16.2c3.68-1.7,6.32-2.91,8.96-4.14c0.67-0.31,1.48-0.52,1.98-1.02c4.04-4.12,8.17-8.18,11.89-12.57
				c0.82-0.97,0.29-3.25,0.09-4.89c-1.11-9.17-2.77-18.3-3.32-27.5c-0.42-7.12,0.77-14.32,0.76-21.48
				c-0.01-4.67-1.85-9.47-1.18-13.96c0.91-6.13,2.69-12.4,5.44-17.94c4.2-8.46,13.78-9.06,20.86-2.65
				c9.78,8.86,14.93,20.01,17.99,32.53c1.21,4.94,3.87,9.56,4.8,14.53c2.36,12.59,5.1,25.24,5.89,37.97
				c1.24,20.08,5.27,39.67,8.03,59.48c3.14,22.54,4.76,45.29,7.24,67.93c0.6,5.54,2.29,10.97,2.85,16.51
				c0.7,6.96,0.2,14.07,1.22,20.96c1.49,10.07,4.06,19.97,5.8,30.01c1.61,9.28,2.71,18.65,4.06,27.97c0.29,2.01,0.68,4,0.99,6
				c2.67,16.99,5.13,34.02,8.08,50.96c1.29,7.42,3.87,14.62,5.33,22.02c1.96,9.94,3.02,20.06,5.17,29.95
				c2.38,10.93,5.52,21.69,8.39,32.5c4.94,18.63,10.81,36.89,19.92,54c5.42,10.19,9.7,21.04,15.78,30.8
				c3.64,5.84,9.51,10.41,14.85,15.02c2.03,1.75,5.18,1,5.82-2.38c1.2-6.29,2.43-12.62,2.95-18.99c0.6-7.29,0.69-14.66,0.48-21.98
				c-0.09-3.02-0.71-6.7-2.52-8.87c-6.87-8.22-13.75-16.4-21.32-24.11c-9.34-9.51-21.36-16.88-27.49-29.48
				c-4.4-9.04-8.94-18.01-13.49-26.98c-0.81-1.6-1.97-3.01-2.97-4.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M157.24,834.83c-0.17,3.5,0.73,7.56-0.74,10.37c-2.42,4.62-1.99,9,1.48,11.26c2.23,1.45,7.13,0.14,10.16-1.33
				c3.88-1.87,7.08-5.15,10.58-7.81c5.63,6.85,5.93,11.52,0.98,17.98c-1.5,1.96-3.38,3.62-4.94,5.54c-0.98,1.21-2.43,2.65-2.41,3.97
				c0.22,13.12-11.35,19.08-17.37,28.3c-3.21,4.91-4.33,9.08-3.88,14.69c0.54,6.61,1.06,13.66-0.7,19.87
				c-1.58,5.58-5.32,11.61-11.24,13.77c-3.16,1.16-7.7-0.54-11.33-1.79c-4.98-1.71-9.55-4.71-14.57-6.22
				c-3.79-1.14-5.84,1.42-3.78,4.73c2.56,4.11,0.66,7.46-0.19,11.1c-1.93,8.18-3.79,16.38-6.04,24.47
				c-1.21,4.36-3.16,8.52-4.94,13.19c-2.92-2.02-5.14-3.7-7.5-5.13c-1-0.61-2.79-1.38-3.39-0.94c-4.51,3.32-9.02,1.61-14.16-0.45
				c0.97,2.94,1.63,4.95,2.53,7.66c-5.42,2.04-10.13,7.27-17.49,2.81c-5.61-3.39-11.68-2.95-17.97-0.3
				c-5.14,2.17-11.26,2.07-16.33,4.35c-7.31,3.29-14.13,7.7-20.98,11.93c-1.03,0.64-1.51,2.88-1.51,4.38
				c-0.08,34.65-0.07,69.3-0.03,103.95c0,2.02,0.43,4.05,0.72,6.62c4.74-0.77,10.34,2.3,11.91-5.58c0.52-2.58,3.72-2.49,6.32-2.08
				c2.82,0.45,5.71,0.52,9.32,0.82c-1.5,4-2.57,7.14-3.85,10.21c-2.11,5.06,0.59,9.42,6.01,9.5c2.83,0.04,5.66,0.1,8.49-0.04
				c1.18-0.06,2.94-0.25,3.4-1.01c3.74-6.14,10.43-5.79,16.13-7.24c2.19-0.56,4.94,1.15,7.44,1.8c1.99,0.52,3.98,1.34,5.99,1.44
				c5.15,0.26,6.41,2.36,4,7.07c-2.21,4.31-4.44,8.61-6.45,13.01c-0.76,1.65-0.93,3.57-1.26,4.88c10.42,1.23,20.08,2.37,31.33,3.69
				c2.56-7.09,6-14.95,8.13-23.15c1.36-5.21,3.91-6.77,8.69-6.47c2.01,0.13,4.04,0.02,6.99,0.02c-1.73,3.46-2.89,5.72-3.99,8
				c-1.52,3.15-2.87,6.4-4.55,9.47c-1.08,1.97-2.34,4.01-4,5.46c-4.84,4.23-4.95,7.7,0.6,10.97c4.6,2.71,7.04,5.76,7.24,11.57
				c0.14,4.2,3.02,8.31,4.87,12.93c2.71-3.03,4.59-5.13,6.88-7.7c5.15,9.47,14.44,6.27,21.93,8.18c2.85,0.73,5.97,0.37,10.07,0.56
				c-0.2-3.82-0.09-7.69-0.67-11.45c-1.18-7.65-1.22-7.79,6.06-9.01c0.67-0.11,1.33-0.34,2-0.49c7.06-1.62,8.13-0.76,7.46,6.3
				c5.83,0.57,11.69,0.96,17.5,1.78c2.56,0.36,5.05,1.48,7.49,2.44c4.75,1.86,8.17,1.85,8-4.52c-0.01-0.33-0.04-0.67,0-1
				c0.32-2.5,0.66-5,0.99-7.5c2.5,1,5.01,1.97,7.49,3.01c1.85,0.78,3.67,1.66,5.5,2.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M455.87,805.85c-0.5,4.16-1.37,8.32-1.43,12.49c-0.18,13.66-0.45,27.33,0.03,40.98c0.6,17.34,2.05,34.65,2.86,51.98
				c0.84,17.98,1.24,35.99,2.08,53.97c0.82,17.67,1.84,35.33,3.07,52.97c0.22,3.14,1.89,6.17,2.89,9.25"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M945.27,307.6c-0.17-26.15-0.13-52.31-0.57-78.46c-0.29-17.33-1.27-34.65-1.92-51.98c-0.06-1.66-0.01-3.33,0.73-5.1
				c6.04,6.87,7.94,15.12,9.24,23.6c2.78,18.12-1.5,35.97-1.99,53.97c-0.04,1.5-0.01,3-0.01,4.5c0,17.32-0.32,34.66,0.1,51.97
				c0.38,15.67,1.62,31.32,2.38,46.98c0.59,12.16,1.02,24.32,1.52,36.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1073.61,349.58c1,6.66,2.48,13.3,2.89,20c0.72,11.64,0.94,23.31,1.07,34.98c0.42,36.65,0.56,73.3,1.11,109.94
				c0.18,12.17,1.42,24.32,1.84,36.49c0.17,4.81-0.58,9.66-0.92,14.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M464.86,823.34c0.83,10.99,1.68,21.99,2.48,32.98c0.13,1.82-0.01,3.66,0.02,5.5c0.32,16.16,0.42,32.33,1.06,48.47
				c0.73,18.5,2,36.98,2.91,55.48c0.76,15.32,1.14,30.66,2.1,45.97c0.55,8.86,1.61,17.71,3,26.48c1.3,8.16,6.39,13.78,13.93,16.91
				c1.88,0.78,4.27,0.34,7.05,0.49c-0.19,4.12-0.05,8.66-0.72,13.08c-0.44,2.94-3.49,3.96-5.83,2.91c-11.13-5-18.66-13.34-21.46-25.4
				c-1.06-4.57-1.4-9.31-2.06-13.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M532.53,958.77c-0.42-4.5-1.22-9-1.19-13.49c0.21-28.99,0.69-57.97,0.89-86.96c0.09-12.33-0.61-24.66-0.34-36.98
				c0.17-7.34,1.8-14.64,2.29-22c0.4-5.97,0.01-11.99,0.09-17.99c0.3-19.99,1.16-39.99,0.81-59.97c-0.24-13.68-2.14-27.32-3.33-40.98
				c-0.45-5.17-0.86-10.35-1.52-15.49c-0.93-7.2-7.33-12.73-14.41-11.74c-1.74,0.25-3.84,3.55-4.37,5.77
				c-1.19,5.04-1.81,10.27-2.1,15.46c-0.98,17.15-1.7,34.31-2.56,51.47c-0.45,8.83-1.33,17.66-1.41,26.49
				c-0.13,14.16,0.65,28.33,0.32,42.48c-0.37,15.84-1.87,31.65-2.29,47.48c-0.33,12.31,0.57,24.66,0.32,36.98
				c-0.34,17-1.7,33.98-1.82,50.98c-0.2,28.48,0.35,56.97,0.3,85.46c-0.01,6.16-1.07,12.35-2,18.48c-0.27,1.75-1.77,3.31-2.87,5.25
				c-8.74-5.66-9.23-14.15-9.9-22.23c-0.79-9.46-0.85-18.98-1.08-28.48c-0.26-10.33-0.54-20.66-0.46-30.98
				c0.06-8.66,1.07-17.34,0.85-25.99c-0.57-22.5-1.51-44.98-2.43-67.47c-0.52-12.5-0.87-25.03-2.15-37.46
				c-0.68-6.6-3.13-13.02-4.83-19.51c-0.76-2.92-1.51-5.31,2.99-6.11c8.22-1.46,16.33-3.56,24.49-5.39"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M988.72,506.5c2.16,2.33,4.15,4.87,6.56,6.92c1.48,1.25,3.53,2.21,5.44,2.49c6.44,0.96,10.66,4.93,12.34,10.62
				c5.73,19.4,11.98,38.68,11.32,59.44c-0.26,8.12,2.25,16.3,2.67,24.49c0.5,9.8,0.05,19.65,0.14,29.48
				c0.09,10.5,0.19,20.99,0.52,31.48c0.34,10.83,0.76,21.67,1.51,32.48c0.95,13.51,2.4,26.98,3.41,40.49
				c0.99,13.31,1.46,26.67,2.63,39.97c0.59,6.71,2.23,13.34,3.4,20"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M995.21,513.99c-5.83-0.17-11.71,0.12-17.46-0.61c-7.25-0.92-16.33,5.14-18.62,12.07c-2.84,8.59-1.14,17.41-2.38,26.03
				c-0.41,2.84-1.29,5.65-1.42,8.5c-0.34,7.49-0.48,14.99-0.54,22.49c-0.08,9.83-0.58,19.7,0.12,29.48c0.94,13.03,2.81,26,4.37,38.99
				c1.08,9.01,3.39,18.01,3.26,26.99c-0.27,19.17-1.84,38.31-2.71,57.48c-0.14,3.14,0.98,6.34,0.87,9.49
				c-0.52,14.5-1.42,28.98-1.89,43.48c-0.51,15.82-0.78,31.65-1,47.48c-0.08,5.83,0.7,11.68,0.38,17.49
				c-0.74,13.34-2.05,26.65-2.86,39.99c-0.46,7.48-0.33,14.99-0.59,22.48c-0.06,1.84-0.63,3.67-0.96,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1046.64,750.37c-0.5,4-0.99,8-1.5,11.99c-1.33,10.33-2.99,20.63-3.91,30.99c-1.04,11.79-1.6,23.64-2.04,35.48
				c-0.69,18.68,1.17,37.4-2.85,56.01c-2.66,12.28-3.5,24.95-5.19,37.44c-2.28,16.87-3.92,33.87-10.02,49.96
				c-0.55,1.44-1.63,2.68-2.46,4.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M621.67,1159.17c12.28,12.17,25.99,21.69,43.42,25.12c5.83,1.15,11.28,4.08,17.02,5.82c10.44,3.16,21,5.9,31.44,9.08
				c13.2,4.02,26.19,8.77,39.5,12.3c9.43,2.5,19.31,3.27,28.91,5.21c15.63,3.16,30.35,8.51,42.45,19.45
				c4.89,4.42,9.76,8.89,14.41,13.56c0.91,0.92,0.75,2.92,1.08,4.41"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M251.13,732.38c0.33,25.65,0.2,51.33,1.16,76.96c0.64,17.19,2.98,34.31,4.29,51.48c1.2,15.64,1.9,31.33,3.09,46.97
				c0.71,9.35,1.94,18.66,2.94,27.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M309.06,724.39c0.5,5.33,1.23,10.65,1.45,15.99c0.36,8.99,0.44,17.99,0.54,26.98c0.2,18.16,0.82,36.33,0.35,54.47
				c-0.34,13.02-2.36,25.98-3.29,38.99c-1.21,16.87-2.33,33.74-7.61,49.95c-1.52,4.65-4.02,8.98-5.88,13.53
				c-1.05,2.58-1.7,5.31-2.54,7.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M305.06,718.39c-0.17,13.49-0.79,27-0.39,40.48c0.57,19.17,2.56,38.31,2.67,57.48c0.09,14.82-1.65,29.66-2.83,44.47
				c-0.75,9.51-1,19.23-3.21,28.43c-2.32,9.61-6.73,18.72-10.22,28.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1025.17,1069.22c-4.2,10.94-5.52,22.22-5.69,33.98c-0.34,23.83-2.02,47.64-2.72,71.47c-0.43,14.81,0.02,29.65-0.15,44.47
				c-0.04,3.91,0.96,7.02,3.43,10.1c1.25,1.56,1.66,4.26,1.53,6.38c-0.37,5.85-1.28,11.66-1.89,17.5
				c-4.09,39.42-1.12,78.96-1.97,118.44c-0.1,4.49,0.3,8.99,0.46,13.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M577.72,846.33c0.5,10.99,1.41,21.99,1.41,32.98c-0.01,19.66-0.04,39.34-1.08,58.96c-0.77,14.54-3.24,28.99-4.78,43.49
				c-0.86,8.14-1.05,16.36-2.11,24.47c-1.74,13.34-3.32,26.79-10.91,38.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M291.08,344.58c2.83,0.17,5.67,0.24,8.49,0.52c11.18,1.09,22.11,0.11,33.01-2.85c3.88-1.05,8.4-0.8,12.41-0.03
				c8,1.53,14.96,2.34,19.33-6.98c0.95-2.04,5.99-2.2,9.17-3.12c0.77-0.22,1.96,0.32,2.43-0.09c1.96-1.69,3.71-3.6,5.55-5.44
				c-1.66-1.17-3.3-2.38-5-3.48c-1.12-0.72-2.35-1.29-2.61-1.43c-4.53,1.61-8.5,2.87-12.34,4.46c-2.6,1.08-5.02,2.61-7.51,3.95
				c-0.85,0.46-1.62,1.33-2.51,1.44c-10.65,1.42-20.24,7.51-31.46,6.64c-9.79-0.76-19.63-0.82-29.46-1.02
				c-1.49-0.03-2.99,0.93-4.49,1.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M245.13,727.39c-1,16.99-2.71,33.98-2.78,50.98c-0.06,14.32,1.89,28.64,2.75,42.98c0.96,16.15,1.62,32.32,2.55,48.47
				c0.91,15.83,1.98,31.65,2.98,47.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M249.13,954.27c0.08,4.77,3.22,10.31-3.92,13.19c-1.06,0.43-1.53,3.47-1.51,5.3c0.23,23.82,0.73,47.64,0.87,71.47
				c0.06,9.83-1.14,19.68-0.8,29.48c0.44,12.51,2,24.98,2.8,37.49c0.37,5.81-0.13,11.67,0.12,17.48c0.2,4.68,0.94,9.33,1.44,14"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,212.14c-4.49,0-9-0.18-13.48,0.03c-15.48,0.75-30.96,1.89-46.45,2.38c-12.47,0.4-24.97,0.26-37.45,0.02
				c-8.66-0.17-17.31-1.2-25.97-1.39c-22.14-0.49-44.28-0.72-66.42-1.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M573.73,854.32c0.5,17.49,1.49,34.99,1.33,52.47c-0.11,12-1.96,23.98-2.79,35.99c-0.83,11.98-1.07,24.01-2.13,35.97
				c-0.89,10.03-1.92,20.14-4.1,29.95c-1.87,8.38-5.12,16.51-8.47,24.45c-1.19,2.82-4.48,4.74-6.81,7.07"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M749.51,1430.03c-11.65-4.66-23.16-9.74-35.01-13.83c-6.86-2.37-14.32-2.91-21.4-4.72c-8.72-2.23-17.26-5.23-26.02-7.28
				c-3.28-0.77-6.97,0.05-10.45,0.37c-9.83,0.93-19.81,1.19-29.41,3.25c-6.47,1.39-12.31,5.54-18.55,8.19
				c-10.1,4.29-20.02,9.34-30.53,12.23c-9.17,2.52-17.64,6.43-26.47,9.57c-8.96,3.19-18.52,4.78-27.9,6.66
				c-9.93,1.99-19.5,4.93-27.91,10.62c-8.53,5.77-18.38,7.78-28.04,9.2c-16.69,2.45-30.99,9.39-43.88,19.77
				c-5.69,4.58-11.02,9.62-16.51,14.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M316.55,773.36c-0.33,7.33-0.91,14.66-0.95,21.99c-0.14,22.44-1.96,44.73-4.91,66.98c-1.54,11.57-1.01,23.43-2.82,34.94
				c-2.33,14.81-4.58,29.94-12.91,42.93c-3.61,5.63-8.4,10.53-12.93,15.51c-1.96,2.16-3.58,3.78-0.44,6.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1071.11,571.46c0.17,18.49,0.34,36.98,0.5,55.47c0.34,40.7-1.01,81.34-3.89,121.95c-0.98,13.78-0.66,27.65-1.13,41.47
				c-0.74,21.99-1.33,44-2.57,65.96c-1.03,18.18-2.4,36.38-4.56,54.46c-1.72,14.42-4.6,28.72-7.4,42.98
				c-2.69,13.72-6.07,27.3-8.8,41.02c-0.57,2.86,0.38,6.03,0.73,10.16c7.09-7.32,8-16.17,11.64-23.68"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1162,1425.54c0,24.49,0,48.98,0,73.46c-386.69,0-773.38,0-1160.57,0c0-3.98,0-7.99,0-11.99c0-36.98-0.02-73.96,0.02-110.94
				c0.01-5.33,0.31-10.66,0.48-15.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M513.3,352.07c7.28-8.72,17.08-12.04,27.96-13c1.34-0.12,3.01,0.13,3.94-0.57c7.3-5.51,14.65-2.59,23.21-0.09
				c0.38-0.6,1.06-1.74,1.82-2.83c8.35-12.03,20.07-13.72,32.91-9.84c6.93,2.1,13.47,5.63,19.98,8.93c3.01,1.53,5.01,3.23,9.16,1.77
				c3.9-1.37,8.86,0.49,13.36,0.59c6.99,0.16,14.05,0.63,20.95-0.16c4.9-0.56,8.67,1.02,12.47,3.25c1.81,1.06,3.27,2.72,5.05,4.25
				c9.37-6.48,18.54-5.18,27.41,0.74c2.54,1.69,4.89,2.15,8.02,2.16c7.66,0.03,15.45,0.48,22.92,2.06c3.82,0.81,7.17,4.24,10.51,6.78
				c2.89,2.2,5.15,4.84,9.56,3.05c6.82-2.77,12.91-0.94,18.83,5.35c-6.44,0.92-12.13,2.15-17.88,2.45
				c-7.8,0.41-15.66-0.24-23.46,0.17c-22.32,1.15-44.61,2.74-66.92,3.88c-12.64,0.64-25.31,0.51-37.95,1.12
				c-11.17,0.53-22.31,1.6-33.46,2.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M247.13,731.38c0,15.66-0.55,31.34,0.13,46.97c1,23.01,2.87,45.98,4.36,68.97c0.51,7.83,1.15,15.65,1.46,23.49
				c0.47,11.82,0.7,23.65,1.03,35.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1063.62,354.57c-0.83,1.33-2.39,2.67-2.39,4c0.02,25.49-0.1,50.98,0.48,76.46c0.57,25,2.6,49.97,2.75,74.97
				c0.25,40.98-0.51,81.96-0.86,122.94c-0.01,1.17-0.31,2.33-0.48,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M559.75,383.56c-0.48-4.54,3.02-5,6-5.44c8.19-1.18,16.15-2.92,23.48-7.02c5.77-3.23,12.82,1.14,13.96,8.46
				c0.21,1.32,0.31,2.67,0.42,3.59c3.69-0.87,7.84-3.25,10.98-2.3c7.79,2.37,16.93,2.65,22.31,11.07c3.08-2.13,6.07-4.4,9.26-6.34
				c7.07-4.31,16.24-2.36,21.98,4.45c0.67,0.8,2.04,2.1,2.42,1.9c5.55-2.92,8.62,1.06,11.62,4.21c5.03-2.82,9.41-2.71,13.97,0.31
				c2,1.33,4.56,1.91,6.95,2.55c4.28,1.14,7.88,3.02,7.9,8.03c0.02,3.48-4.36,6.67-9.41,6.96c-13.81,0.78-27.63,1.39-41.45,2.03
				c-14.81,0.69-29.63,1.41-44.45,1.97c-6.45,0.24-12.38-1.76-18.6-3.53c-8.49-2.41-17.85-1.84-26.85-2.37
				c-1.81-0.11-3.66,0.88-5.49,0.89c-14.15,0.08-28.3,0.01-42.45,0.09c-1.67,0.01-3.33,0.62-4.99,0.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M152.25,359.07c1.38,1.76,2.76,3.52,4.45,5.67c3.9-4.83,8.36-8.82,10.73-13.8c1.71-3.6,1.59-8.77,0.52-12.78
				c-1-3.77-11.74-6.52-15.19-4.54c-4.66,2.68-9.26,2.92-14.55,1.66c-4.1-0.97-8.48-0.52-12,3.24c-7.79,8.32-17.37,11.9-28.92,10.19
				c-2.7-0.4-5.62,1.06-8.47,1.32c-5.41,0.5-10.44-0.68-15.53-2.83c-7.44-3.15-15.23-4.7-23.45-2.26c-2.75,0.82-5.96,0.01-8.95,0.15
				c-5.16,0.24-10.32,0.94-15.48,0.9c-7.66-0.06-15.31-0.6-22.97-0.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M273.1,1055.72c6.16,5.33,13.08,10.02,18.28,16.16c6.35,7.5,13.37,13.87,20.98,20.06c11.86,9.64,23.74,19.2,32.5,32.52
				c8.04,12.23,19.34,22.13,31,31.35c4.83,3.82,9.48,8.63,12.34,13.99c3.97,7.45,8.63,14.17,14.23,20.36
				c1.93,2.13,3.16,5.08,7.01,4.66c0.88-0.1,2.03,1.8,2.97,2.85c1.41,1.57,2.75,3.21,4.67,5.46c0.87-2.59,1.45-4.33,1.83-5.47
				c3,0,5.49,0,7.99,0c0.33,0,0.9-0.12,0.97,0.02c2.7,5.36,7.63,6.86,12.95,8.23c1.11,0.28,1.33,3.77,2.08,5.74
				c0.84,2.22-0.31,6.3,4.48,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M206.18,755.37c-0.5,6.33-1.45,12.66-1.41,18.99c0.11,19.99,0.18,40,1.06,59.96c0.59,13.53,2.57,26.99,3.84,40.49
				c1.08,11.49,1.87,23,3.12,34.47c0.3,2.74,1.89,5.34,2.89,8.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M210.18,756.37c0.5,5.5,1.38,10.99,1.43,16.49c0.18,18.82-0.39,37.66,0.19,56.47c0.6,19.34,2.35,38.64,3.31,57.98
				c0.51,10.31-0.9,20.89,0.95,30.91c1.61,8.68,6.49,16.78,10.15,25.04c1.63,3.69,3.82,7.13,6.36,11.78
				c-9.45-5.08-11.49-7.65-16.89-20.25"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M785.97,512.49c0.67,3.83,2.14,7.73,1.85,11.48c-0.91,11.68-0.01,23.07,2.45,34.54c1.16,5.41-0.37,11.36,0.34,16.93
				c0.93,7.4,3.45,14.62,4.21,22.02c0.95,9.26,1.15,18.65,1.05,27.97c-0.09,8.9,1.48,17.38,3.33,26.04
				c1.98,9.25,1.99,18.93,2.74,28.43c1.06,13.32,2,26.65,3.02,39.98c0.81,10.66,2.26,21.32,2.35,31.99
				c0.14,16.63,1.15,33.1,4.07,49.49c2.63,14.77,4.72,29.64,7.09,44.46c0.06,0.38,0.63,0.67,0.96,1.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M953.26,1477.01c-14.14,1.02-27.91-0.79-41.48-4.9c-3.8-1.15-8.01-1.77-11.95-1.47c-10.84,0.84-21.08-2.01-31.48-4.1
				c-4.13-0.83-8.3-1.7-12.48-1.96c-8.97-0.56-18.01-0.35-26.95-1.22c-4.91-0.48-9.66-2.57-14.5-3.83c-2.97-0.77-6.06-1.13-8.96-2.08
				c-2.6-0.85-5.27-1.91-7.46-3.51c-4.69-3.42-10.19-4.11-15.53-5.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M288.58,451.52c8.64,2.44,13.6,10.16,20.89,14.63c9.99,6.12,19.39,13.19,29.05,19.85c0.49,0.34,1.1,0.58,1.48,1.01
				c13.37,15.31,31.95,22.46,49.55,30.76c17.53,8.27,35.82,14.95,53.89,22.06c1.76,0.69,5.02,0.26,6.32-0.98
				c14.08-13.43,30.79-23.25,46.45-34.52c5.36-3.86,9.29-9.65,14.13-14.3c4.76-4.56,9.42-7.81,17.12-4.47
				c3.68,1.6,7.77,2.41,11.37,4.82c3.43,2.29,7.16,2.37,10.99-0.28c2.66-1.84,5.82-4.39,9.46-1.17c0.47,0.41,1.91,0.41,2.4,0
				c5.46-4.59,9.17-0.17,12.99,2.61c3.51,2.55,5.83,7.49,11.6,4.58c0.66-0.33,2.42,1.02,3.4,1.89c7.26,6.42,14.79,5.12,22.51,1
				c1.57-0.84,3.31-1.87,4.98-1.9c2.16-0.03,5.05,0.16,6.36,1.5c4.41,4.5,8.93,8.33,15.61,8.36c4.49,0.02,9.02,0.33,13.48-0.08
				c5.03-0.46,10.71-0.32,14.84-2.68c7.97-4.56,17.22-2.28,25.26-6.47c4.93-2.57,11.16-3.39,16.85-3.53
				c5.7-0.14,9.93-2.01,14.04-5.66c5.01-4.44,9.43-9.88,16.42-11.56c3.49-0.84,6.96-1.83,10.49-2.47
				c9.06-1.64,14.28-8.91,20.95-14.05c3.27-2.52,6.34-5.31,9.51-7.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M807.44,431.53c-4.57,12.33-0.33,24.65-0.01,36.98c0.24,9.33,0.27,18.66,0.54,27.98c0.2,7,0.48,14.01,1.01,20.99
				c1.23,16.33,2.54,32.66,4,48.97c0.8,9.01,2.01,17.99,2.95,26.99c1.21,11.66,2.1,23.35,3.56,34.98
				c3.34,26.67,6.92,53.32,10.46,79.96c0.89,6.68,1.98,13.33,2.98,19.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M577.72,998.25c2.5,0.33,5.17,0.2,7.46,1.09c6.22,2.41,11.02,0.27,14.42-4.65c4.06-5.87,9.4-7.45,16.08-7.04
				c2.62,0.16,5.39-0.65,7.97-1.45c3.57-1.1,6.93-2.89,10.53-3.86c3.57-0.97,5.31-2.8,5.52-6.57c0.51-8.84,1.2-17.67,1.98-26.49
				c0.5-5.68,3.29-7.61,8.95-8.11c5.7-0.5,11.29-2.34,17-2.8c11.7-0.94,22.89-5.56,35.19,1.49c13.61,7.81,28.62,14.26,43.83,17.89
				c15.55,3.71,25.18,14.5,36.14,23.73c9.29,7.83,18.62,14.89,29.52,20.53c14.9,7.72,28.15,18.17,39.62,30.68
				c8.51,9.29,16.99,18.65,26.06,27.39c5.01,4.83,11.35,8.27,16.83,12.65c10.53,8.41,18.02,19.42,25.56,30.41
				c4.84,7.05,10.35,13.64,15.39,20.55c4.45,6.09,7.23,12.25,5.32,20.54c-1.49,6.49-0.3,13.59-0.3,20.42
				c0,7.33,0.13,14.66-0.04,21.99c-0.16,7-0.71,13.99-0.9,20.99c-0.05,1.82,0.69,3.65,0.92,5.5c0.4,3.16,1.15,6.36,0.93,9.49
				c-0.79,11.67-2.09,23.32-2.86,34.99c-0.74,11.15-1.19,22.31-1.49,33.48c-0.16,5.99,0.17,12,0.47,17.99
				c0.39,7.83,1.19,15.65,1.42,23.49c0.36,11.99-0.52,24.09,0.84,35.95c1.06,9.25,1.62,18.33,0.66,27.52
				c-0.78,7.52-2.31,14.97-2.86,22.5c-0.59,8.13-0.36,16.32-0.62,24.48c-0.23,7.33-0.64,14.66-0.98,21.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M532.78,1039.73c-0.5-8.33-1.16-16.65-1.46-24.99c-0.47-13.16-0.81-26.32-0.97-39.48c-0.05-4.49,0.53-9,0.99-13.49
				c0.11-1.04,0.71-2.62,1.48-2.91c8.83-3.36,10.77-11.78,13.87-19.12c5.84-13.84,9.74-28.17,9.1-43.45
				c-0.34-8.16,0.23-16.4-0.76-24.46c-0.57-4.65-2.51-9.81-5.51-13.32c-3.46-4.06-8.54-1.68-9.19,3.8
				c-0.6,5.13-0.86,10.32-1.03,15.49c-0.28,8.49,0.33,17.08-0.79,25.45c-0.69,5.15-0.37,11.38-5.74,15.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M514.8,916.29c0.17,4.16,0.45,8.33,0.48,12.49c0.07,11.16,0.37,22.33-0.05,33.48c-0.73,19.33-1.94,38.65-2.91,57.97
				c-0.4,7.99-0.05,16.08-1.17,23.96c-1.29,9.1-3.78,18.03-5.91,26.99c-0.19,0.82-1.58,1.35-2.41,2.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M941.77,313.59c-0.83,9.33-2.15,18.64-2.4,27.99c-0.5,19.15-0.59,38.31-0.54,57.47c0.03,11.99,0.95,23.99,0.84,35.98
				c-0.1,10.67-1.99,21.37-1.41,31.97c0.72,13.09,0.71,26.78,9.87,37.68c0.66-0.08,1.33-0.16,1.99-0.24
				c-0.45-7.14-0.72-14.3-1.4-21.42c-0.99-10.34-2.83-20.63-3.32-30.99c-0.69-14.47-0.46-28.98-0.63-43.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M368.98,582.96c1.64,1.36,3.28,2.72,5.85,4.85c-3,6.47-6.25,13.27-9.32,20.15c-2.13,4.77-4.42,9.53-5.9,14.52
				c-1.23,4.13-3.05,5.17-7.13,4.04c-6.77-1.89-8.9-0.83-11.94,5.43c-5.23,10.75-9.9,21.72-14.34,32.84
				c-3.86,9.65-9.76,18.86-16.4,26.9c-5.98,7.24-14.16,12.67-23.2,16.23c-2.56,1.01-4.98,2.37-7.51,3.44
				c-0.91,0.38-1.98,0.37-2.98,0.53c0-1-0.37-2.2,0.06-2.96c2.37-4.24,4.93-8.36,7.43-12.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1085.6,793.35c0.83,17.16,2.09,34.31,2.38,51.48c0.33,19.15,0.05,38.32-0.47,57.47c-0.39,14.35,0.75,28.84-2.91,42.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M459.87,826.34c1.17,12.66,2.72,25.3,3.36,37.99c0.41,8.13-0.94,16.33-0.78,24.48c0.43,21.83,1.06,43.66,2.02,65.46
				c0.34,7.69,1.89,15.33,2.89,22.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M166.73,337.58c-5.13,1.73-9.05,4.8-12.07,9.44c-3.29,5.07-9.3,2.47-13.93,3.96c-3.51,1.13-7.42,1.04-10.93,2.17
				c-8.79,2.82-17.54,1.92-26.51,1.12c-7.24-0.64-14.64,0.8-21.95,0.71c-7.83-0.1-15.64-1.08-23.47-1.36
				c-6.98-0.25-13.98-0.1-20.97-0.03c-3.5,0.04-6.99,0.42-10.49,0.45c-7.99,0.07-15.98,0.02-23.97,0.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M471.85,826.34c2.16,31.12,2.36,62.27,2.76,93.45c0.23,17.84,3.07,35.65,4.73,53.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M215.17,755.37c0.83,24.32,1.72,48.64,2.48,72.96c0.44,14.16,0.46,28.33,1.1,42.47c0.43,9.68,1.57,19.33,2.46,28.99
				c0.05,0.53,0.63,1,0.96,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1074.61,835.33c-1.14,48.71-4.23,97.18-17.48,144.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M232.65,965.77c-12.2-3.64-19.93-11.69-22.88-24.01c-1.05-4.37-1.12-8.97-1.58-13.47c-0.39-3.83-0.59-7.67-1.03-11.49
				c-0.94-8.17-2.5-16.3-2.88-24.49c-1.25-26.81-2.52-53.63-2.94-80.46c-0.28-18.15,0.66-36.32,1.49-54.46
				c0.19-4.21,1.81-8.5,3.54-12.42c0.67-1.51,4.19-3.37,5.17-2.83c2.57,1.42,5.49,3.72,6.39,6.33c1.4,4.02,1.5,8.57,1.69,12.91
				c1.25,28.32,2.38,56.64,3.53,84.96c0.08,2-0.03,4,0.02,6c0.32,14.33,0.34,28.67,1.09,42.97c0.64,12.09,2.92,24.04,7.08,35.42
				c1.26,3.45,4.67,6.2,7.41,8.96c0.58,0.58,2.94,0.22,3.78-0.51c0.94-0.82,1.58-2.56,1.53-3.88c-1.07-30.49-2.26-60.97-3.43-91.46
				c-0.5-13.16-0.99-26.32-1.5-39.48c-0.49-12.83-1.58-25.67-1.33-38.48c0.25-12.67,1.77-25.33,2.9-37.98
				c0.17-1.88,1.04-3.8,1.98-5.48c1.94-3.46,4.85-3.85,7.96-1.54c6.01,4.45,6.13,11.1,6.39,17.51c0.23,5.81-0.62,11.68-0.34,17.48
				c0.57,11.67,1.6,23.32,2.41,34.99c0.36,5.16,0.8,10.32,0.97,15.49c0.28,8.16-0.03,16.36,0.62,24.48c0.98,12.35,2.89,24.64,3.81,37
				c0.8,10.79,0.61,21.66,1.17,32.47c0.22,4.35,1.26,8.67,1.92,13"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M358.99,957.77c4.33,5.66,8.53,11.43,13.03,16.96c3.27,4.01,6.61,8.08,10.5,11.46c14.28,12.4,24.25,27.46,30,45.51
				c2.03,6.37,5.29,12.33,7.87,18.53c1.78,4.28,3.14,8.75,5.06,12.96c3.84,8.4,8.06,16.63,11.86,25.04c1.02,2.26,1.08,4.96,1.58,7.46
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M555.75,578.46c-6.49,2.5-13.53,4.12-19.33,7.74c-6,3.74-11,9.22-16.08,14.3c-7.5,7.48-14.32,15.7-22.18,22.76
				c-3.91,3.52-9.3,5.7-14.36,7.56c-10.3,3.79-19.81,8.79-28.45,15.57c-6.36,5-13.01,9.63-19.44,14.54
				c-7.44,5.68-8.28,9.16-3.23,16.62c6.19-6.67,12.08-13.27,18.26-19.58c2.3-2.35,5.07-4.44,7.99-5.95c3.07-1.59,6.59-2.52,8.38,0.05
				c-0.89,5.04-1.32,9.55-2.6,13.79c-0.58,1.94-2.65,3.62-4.37,5.01c-10.42,8.41-20.99,16.63-31.38,25.09
				c-1.7,1.38-2.71,3.6-4.05,5.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M254.62,973.26c-0.33,19.32-1.3,38.66-0.81,57.97c0.43,17.18,2.76,34.31,3.68,51.49c0.42,7.79-0.62,15.65-0.81,23.48
				c-0.05,1.99,0.61,4,0.94,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M283.09,779.36c0.5,6.16,1.39,12.32,1.43,18.49c0.16,26.15,0.09,52.31,0.06,78.46c-0.02,12.27-2.04,24.18-7.02,35.47
				c-0.57,1.28-1.63,2.35-2.46,3.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M747.51,1444.53c0-0.5,0.19-1.33-0.03-1.45c-7.31-4.19-13.02-10.81-21.89-12.79c-5.13-1.14-9.52-5.27-14.58-7.14
				c-10.86-4.01-21.89-7.58-32.95-10.99c-2.43-0.75-5.41-0.62-7.93-0.01c-6.4,1.53-12.62,4.74-19.02,5.06
				c-6.15,0.31-10.6,3.79-15.91,5.49c-1.64,0.52-3.04,2.67-3.98,4.38c-2.99,5.41-5.72,10.96-8.55,16.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M439.39,1017.24c-1.66-2.33-3.69-4.49-4.93-7.03c-3.85-7.91-6.83-16.29-11.2-23.87c-5.02-8.7-10.64-17.18-17-24.94
				c-5.18-6.33-11.75-11.54-17.84-17.09c-3.2-2.91-6.54-3.45-10.52-0.62c-5.23,3.7-10.94,6.73-16.41,10.09
				c-4.57,2.81-5.98,6.85-4.51,11.99c2.38,8.33,1.31,16.46-1.49,24.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1032.16,1120.19c0.17,1.67,0.48,3.33,0.48,5c0.03,23.32,0.12,46.64-0.02,69.96c-0.07,10.66-0.5,21.33-1.03,31.98
				c-0.14,2.86-0.84,5.85-1.99,8.47c-3.98,8.99-1.88,18.33-1.88,27.52c0,14.49,0.27,28.99,0.47,43.48c0.04,3,0.54,6,0.45,8.99
				c-0.6,19.66-1.27,39.32-1.99,58.97c-0.11,3.01-0.63,6-0.97,9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1078.1,913.79c-1.66,12.99-3.31,25.99-5.01,38.98c-0.41,3.18-1.4,6.33-1.42,9.5c-0.07,18.07-3.83,35.57-8.14,52.95
				c-1.85,7.45-4.63,14.66-6.88,22.02c-0.93,3.05-1.57,6.19-2.34,9.28c0.54,0.28,1.08,0.56,1.62,0.84c2.06-2.71,4.58-5.19,6.11-8.17
				c5.84-11.44,8.36-23.91,10.55-36.44c3.05-17.48,6.01-34.98,9-52.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.94,783.36c-0.17-16.49-0.38-32.98-0.48-49.48c-0.08-12.49-0.02-24.99-0.02-37.48c0-29.65,0-59.3,0-88.96
				c0-2.67-0.44-5.43,0.14-7.96c0.46-2,1.77-4.41,3.44-5.39c3.9-2.28,8.85-3.05,12.26-5.81c5.82-4.72,11.72-9.86,15.89-15.98
				c5.79-8.48,13.73-14.31,21.16-20.89c2.28-2.02,5.01-4.3,5.88-7c5.61-17.4,19.19-25.49,35.15-30.83c2.25-0.75,5.73-1.13,7.32,0.08
				c6.36,4.83,13.89,4.82,21.1,6.37c12.24,2.64,23.56,7.9,32.8,16.13c8.29,7.38,15.96,9.68,26.75,5.6
				c9.11-3.44,19.23-4.17,28.83-6.37c2.94-0.67,5.92-1.87,8.47-3.49c15.25-9.7,32.23-14.82,49.43-19.61
				c3.19-0.89,6.14-3.77,8.43-6.41c2.68-3.09,5.37-5.16,9.6-5.46c7.68-0.55,14.17-3.79,19.52-9.4c1.15-1.21,2.77-1.97,4.57-3.21
				c-0.7-0.49-1.65-1.17-2.61-1.83c-6.48-4.46-11.61-9.87-14.66-17.42c-1.42-3.52-4.78-6.25-7.74-9.95
				c-0.01-0.49-0.15-2.32-0.02-4.12c0.08-1.02,0.49-2.07,0.98-2.99c1.43-2.7,3.25-5.23,4.37-8.05c0.71-1.78,1.23-4.37,0.44-5.87
				c-1.81-3.46-0.61-5.58,1.6-8.13c3.51-4.05,7.2-8.13,8.35-15.3c-9.37,5.4-17.55,10.15-25.77,14.84c-5.44,3.1-10.84,6.41-17.47,5.97
				c-1.18-0.08-3.24-0.44-3.36-0.99c-1.3-6.11-6.09-7.64-11.27-8.33c0.66-3.11,1.19-5.58,1.95-9.18c-3.46,2.31-5.83,3.99-8.31,5.53
				c-9.97,6.19-19.87,12.51-29.99,18.44c-5.92,3.47-12.36,6.2-19.43,5.47c-4.71-0.49-9.32-1.98-14-2.87
				c-3.12-0.6-6.66,2.49-9.48-1.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M2.43,676.91c1.66-1.67,3.63-3.13,4.92-5.05c1.79-2.64,2.85-5.76,4.61-8.42c5.83-8.88,8.17-9.86,18.43-7.48
				c5.83,1.35,11.5,2.82,17.03-0.99c1.06-0.73,2.84-1.33,3.92-0.96c6.31,2.2,12.02-1.13,18.02-1.54c2.97-0.2,6.31,1.18,8.92,0.25
				c5.87-2.08,11.29-5.4,17.09-7.7c2.56-1.01,6.31-2,8.31-0.87c4.71,2.68,7.46,0.72,10.68-2.18c1.86-1.68,4.25-2.76,6.42-4.12
				c1.82,0.9,3.61,1.79,5.75,2.86c-3.53,5.01-7.1,10.12-10.74,15.19c-1.7,2.36-3.91,3.4-6.99,3.07c-4.94-0.53-8.47,2.36-12.02,5.38
				c-5.15,4.37-10.48,8.56-15.99,12.47c-2.9,2.06-6.3,2.9-9.95,1.11c-6.84-3.36-13.21,0.16-19.42,2.09
				c-13.77,4.27-27.3,9.32-41.04,13.7c-1.42,0.45-3.76-1.27-5.39-2.4c-1.11-0.77-1.72-2.26-2.55-3.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M794.46,428.54c1,2.83,2.65,5.61,2.89,8.5c2.33,27.64,4.06,55.33,6.73,82.94c1.57,16.23,4.52,32.33,6.85,48.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M595.2,1284.61c7.62,7.12,14.26,14.68,16.59,25.46c3.24,15.04,5.92,30.08,6.45,45.5c0.29,8.34,2.57,16.82,1.57,24.94
				c-1.01,8.2-4.15,16.47-8.03,23.82c-1.56,2.95-7.52,3.74-11.6,5.16c-5.06,1.76-8.3,6.72-7.47,11.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M981.72,520.99c-11.5,8.38-12.64,21.13-12.79,33.49c-0.31,25.98,0.28,51.98,0.93,77.96c0.17,7.02,1.87,14,2.87,20.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1026.67,1021.74c-6.16,2.33-12.2,5.07-18.5,6.91c-12.89,3.76-25.76,3.15-38.44-1.39c-3.27-1.17-3.32-1.03-6.15,1.1
				c2.26,5.04,7.29,6.5,11.68,7.18c16.8,2.6,33.24,1.31,47.3-9.45c4.87-3.73,8.44-9.18,12.6-13.84"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M737.53,1286.11c-5.2,12.1-1.87,22.84,3.79,34.09c9.38,18.63,24.55,31.02,41.1,42.46c6.6,4.56,12.8,9.93,15.44,17.94
				c1.09,3.3,1.86,7.06,1.45,10.45c-1.27,10.49-3.76,20.65-12.34,28"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M286.08,777.36c1.63,19.13,3.07,38.21,1.69,57.49c-0.87,12.09,0.34,24.31,0.25,36.47c-0.08,11.2,1.36,22.54-2.44,33.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M848.89,589.46c1.64,1.37,3.27,2.73,4.88,4.07c1.7-1.03,3.28-2,5.05-3.08c5.52,5.06,6.36,11.81,7.95,18.03
				c2.69,10.57,4.98,21.26,7.02,31.97c1.67,8.76,2.21,17.76,4.18,26.45c3.41,15.09,8.21,29.88,11.06,45.06
				c1.27,6.78,2.62,13.61,3.8,20.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M820.42,1478.01c-8.99,0.17-17.98,0.27-26.97,0.54c-2.34,0.07-4.68,1.02-6.99,0.89c-9-0.5-17.98-1.64-26.97-1.79
				c-5.48-0.09-10.97,1.54-16.48,1.77c-5.98,0.25-11.98-0.24-17.97-0.41c-0.17,0-0.34-0.03-0.5,0c-12.89,2.44-22.28-7.79-33.98-9.36
				c-5.89-0.79-11.97-0.14-17.96-0.14c-1.66,0-3.33-0.04-4.99,0.01c-11.15,0.32-22.32,0.46-33.45,1.14c-2.56,0.16-5,2.18-7.5,3.35"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1160.5,1426.54c-5.68,0.67-10.6,6.55-17.47,1.83c-0.21,1.96-0.5,3.32-0.48,4.66c0.07,3.8-0.92,6.58-4.97,8.12
				c-2.72,1.03-4.97,2.61-4.3,6.91c0.63,4.07-3.51,5.08-6.71,4.34c-5.56-1.3-7.96,1.23-8.44,6.12c-0.46,4.72-3.53,5.03-7.07,4.99
				c-5.32-0.07-11.22-0.77-14.37,4.57c-5.07,8.6-14.02,9.82-22.07,12.96c-5.69,2.22-12.22,2.75-16.5,7.96
				c-2.31,2.81-5.06,5.44-2.49,9.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M837.4,1498.5c-7.92-12.11-15.82-23.81-30.87-28.75c-8.15-2.68-15.25-8.39-23.11-12.12c-5.73-2.72-11.89-4.56-17.94-6.57
				c-6.44-2.15-13-3.94-19.46-6.05c-10.3-3.36-20.94-2.09-31.46-2.58c-4.36-0.21-8.64-1.68-13-2.35c-2.63-0.41-5.65-1.26-7.93-0.41
				c-20.86,7.84-42.31,7.99-63.96,5.29c-6.25-0.78-11.96-1.04-18.13,1.74c-3.87,1.75-9.18,0.34-13.84,0.32
				c-6.87-0.02-13.97-0.7-19.9,3.6c-2.9,2.1-5.89,4.83-7.41,7.97c-4.38,9.05-12.22,11.98-21.14,12.8c-6.11,0.56-11.7,1.79-17.03,5.02
				c-4.69,2.84-9.89,4.85-15.18,7.37c-2.21-5.32,0.89-7.73,4.75-9.77"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M818.93,606.45c2,13.49,4.03,26.98,5.98,40.48c1.88,12.99,3.81,25.97,5.47,38.98c1.67,13.14,3.78,26.28,4.33,39.48
				c0.59,14.07,4.02,27.63,5.57,41.48c0.72,6.42-0.14,12.99,0.17,19.47c0.8,16.82,1.85,33.64,6.44,49.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M464.36,1028.24c-2.61,7.95-0.26,15.66,2.18,22.93c3.93,11.72,11.42,20.63,23.77,24.6c2.87,0.92,4.66,2.79,4.59,6.44
				c-0.48,23.65-0.78,47.31-1.06,70.96c-0.11,9.66-0.02,19.32-0.02,28.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M936.28,167.17c-0.17,2.67-0.52,5.33-0.47,8c0.26,12.5,0.52,24.99,1,37.48c0.53,14,1.58,27.98,1.87,41.98
				c0.19,9.32-0.65,18.65-0.84,27.99c-0.05,2.32,0.61,4.66,0.94,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M386.46,897.8c1.58-4.1,3.17-8.2,4.98-12.89c3.19,5.59,2.79,8.22-1.52,10.84c-2.6,1.58-3.37,4.05-4.53,6.52
				c-1.55,3.3-3.81,6.26-5.35,9.56c-1.22,2.63-3.57,5.17-0.63,8.51c7.5,8.51,14.64,17.33,22.06,25.91c3.15,3.65,6.57,7.08,10,10.48
				c0.92,0.91,2.23,1.77,3.47,1.97c11.4,1.88,14.01,10.87,16.5,20.04c0.43,1.56,1.4,2.97,1.94,4.52c0.62,1.79,1.99,4.11,1.37,5.41
				c-3.72,7.87,1.34,14.18,3.11,21.08c0.32,1.25,0.05,2.65,0.05,3.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M493.33,573.96c-1.86,0.78-3.72,1.56-6.54,2.75c2.06-13.53,8.17-23.08,21.27-26.54c2.08-0.23,4.81,0.3,6.14-0.83
				c5.74-4.85,11.38-9.66,13.36-17.8c1.03-4.23,4.24-8.31,10.23-7.63c4.3,0.49,7.08-2.37,8.37-5.97c1.49-4.16,2.21-8.6,3.07-12.96
				c0.52-2.6-0.33-4.15-3.46-4.03c-9.31,0.37-17.73,2-22.55,11.5c-3.48,6.86-8.39,12.63-16.53,15.95c-0.22-2.56-0.41-4.75-0.67-7.83
				c-3.38,1.89-6.26,3.5-9.18,5.13c-2.43-7.73,2.22-12.22,6.97-16.72c4.87-4.62,9.66-9.32,14.49-13.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1159,1024.24c-15.45,12.67-33.77,18.51-52.92,22.55c-5.91,1.25-11.69,3.19-17.47,5c-1.15,0.36-2.25,1.44-2.95,2.48
				c-0.65,0.95-0.73,2.29-1.07,3.45c1.33,0.33,2.78,1.18,3.97,0.91c5.7-1.3,11.36-2.85,16.99-4.42c6.34-1.77,12.63-3.73,19-5.41
				c1.2-0.32,2.64,0.26,3.98,0.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M514.8,353.57c-8.03-4.93-16.67-3.11-20.28,5.08c-1.55,3.52-3.93,2.72-5.65,2.26c-5.9-1.56-11.48-1.57-17.02,1.14
				c-6.55,3.2-12.99,1.36-19.48-0.46c-11.18-3.14-22.43-5.52-33.96-1.54c-0.45,0.15-1.27-0.19-1.45,0.05
				c-4.73,6.36-11.59,4.86-18.02,5.01c-0.67,0.02-1.33,0.62-2,0.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M435.4,612.94c-13.79,18.23-31.41,31.67-51.36,42.61c-17.44,9.57-34.11,20.44-49.07,33.79c-8.02,7.16-16.59,13.72-24.92,20.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M957.75,298.6c0.67,6.83,1.86,13.66,1.91,20.49c0.21,30.65,0.07,61.3,0.13,91.95c0,1.83,0.62,3.66,0.96,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M168.23,337.58c0.33-0.33,0.7-0.64,0.99-1c6.86-8.68,6.86-8.68,17.56-4.78c1.96-4.2,4.18-8.36,5.82-12.74c1.01-2.7,2.29-2.8,4.6-2
				c5.12,1.77,10.21,4.32,15.49,4.85c14.91,1.5,27.33,10.05,41.45,13.59c1.68,0.42,3.96,0.31,5.42-0.51
				c9.87-5.56,20.09-3.75,27.01,5.09c2.71,3.46,5.08,7.23,8.07,10.42c2.81,2.99,6.07,5.67,9.47,7.99c3.24,2.21,6.91,3.79,10.78,5.86
				c4.1-3.19,7.3-7.04,8.64-12.28c1.03-4.06,3.36-5.35,6.97-2.96c3.27,2.17,7.13,4.05,9.36,7.07c5.93,8.03,12.94,9.02,21.11,7.33
				c11.95-2.48,23.69-1.22,34.9,2.74c8.65,3.06,16.91,7.46,24.95,11.95c4.16,2.32,7.74,1.07,11.56,0.32
				c5.05-0.99,9.63-0.5,14.06,2.43c1.54,1.02,3.92,0.78,5.92,1.11c1,0.17,2.24,0.01,2.96,0.55c5.4,4.07,11.51,7.6,15.73,12.69
				c2.7,3.25,5.67,3.7,8.81,4.68c3.24,1.01,5.83,2.06,7.6,5.5c2.86,5.55,8.16,6.3,14.86,5.82c6.55-0.47,13.41,1.01,17.89,6.8
				c2.83,3.65,5.51,5.49,9.32,2.18c2.14,0.95,4.22,1.79,6.21,2.78c6.43,3.21,11.98,7.44,14.43,14.5c1.64,4.74,4.36,6.71,9.55,6.67
				c3.49-0.03,6.94,2.34,10.5,2.69c8.05,0.8,14.12,5.15,20.58,9.43c6.25,4.15,12.85,8.57,21.4,5.78c3.88-1.27,7.42,0.82,10.45,3.39"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M633.16,730.88c0-0.83-0.36-1.97,0.05-2.45c6.25-7.39,12.56-14.74,18.94-22.02c3.41-3.9,7-7.65,10.44-11.54
				c0.4-0.46,0.73-1.54,0.48-1.94c-3.21-5.03-2-10.27-0.99-15.54c0.11-0.59-0.37-1.3-1.04-3.46c-13.01,21.08-31.83,33.67-53.83,42.01
				c-0.97-3.45,1.02-7.24,5.48-10.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M676.6,814.34c2.67,1.99,5.34,3.98,7.99,5.95c-1.88,1.32-3.73,2.61-5.98,4.19c5.09,6.89,12.73,8.82,19.98,11.31
				c3.73,1.28,7.7,1.87,11.45,3.1c3.61,1.18,7.18,2.11,9.72,5.8c1.48,2.15,4.99,3.53,7.8,4.01c7.42,1.27,13.13,5.14,18.5,10.09
				c5.13,4.72,10.45,9.26,15.99,13.47c4.3,3.27,6.95,6.9,5.97,12.57c-0.58,3.34,0.27,5.41,4.47,5.12c2.31-0.16,4.71,0.96,7.76,1.67
				c0.88-1.59,2.53-3.57,3.11-5.82c1.01-3.91,3.53-3.97,6.6-3.47c1.16,0.19,2.44,0.09,3.48,0.54c6.05,2.63,10.33,0.75,12.97-5.05
				c2.44-5.36,6.57-4.7,11.02-3.51c1.79,0.48,3.66,0.69,5.49,1.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M286.58,452.02c-1.68-1.32-3.35-2.64-5.29-4.15c-0.66,0.5-1.37,1.2-2.21,1.64c-4.85,2.53-9.23,5.04-15.47,5.01
				c-5.65-0.02-12.33,2.97-16.77,6.75c-9.5,8.09-20.32,12-32.16,14.28c-7.7,1.48-14.47,4.99-21.09,9.29
				c-5.63,3.65-12.34,5.59-18.32,8.75c-4.22,2.23-7.99,5.32-12.04,7.9c-7.3,4.63-14.89,8.38-23.96,7.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M732.53,415.54c-6.99,0.5-14,0.87-20.97,1.57c-2.54,0.25-5.01,1.24-7.49,1.95c-0.86,0.24-1.79,1.11-2.48,0.92
				c-11.25-3.07-18.22,3.72-24.36,11.17c-4.21,5.12-7.49,10.66-14.56,12.57c-2.48,0.67-4.79,3.17-6.45,5.38
				c-2.68,3.57-6.05,4.47-10.08,3.91c-7.12-0.99-11.8,2.11-16.7,7.31c-6.64,7.04-14.82,12.62-20.99,17.73
				c-4.56-3.4-7.38-6.32-10.82-7.87c-4-1.8-8.43-2.07-10.58-7.09c-1.44-3.35-5.58-2.91-8.27-1.91c-5,1.85-9.65,4.63-14.97,7.27
				c-2.39-2.23-5.55-2.92-8.59,1.05c-0.99,1.3-2.36,2.3-3.46,3.53c-5.67,6.32-5.66,6.33-12.84,0.72c7.69-5.49,9.38-13.51,10.51-22.63
				c-9,1.21-12.07,8.54-17.61,12.45c-1.61-3.68-3.19-7.3-5.04-11.55c2.14-2.85,4.93-6.57,8.33-11.09c-8.52,1.03-4.59-7.39-9.15-10.18
				c-5.18-3.17-9-8.54-14.13-13.67c-3.95,4.62-7.63,8.92-13.09,15.3c-3.05-3.04-6.22-6.2-9.39-9.36c0.56-0.78,1.12-1.56,1.68-2.34
				c-3.9,3.12-7.81,6.24-11.71,9.36c-4.98-1.81-7.35-0.16-11.47,8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1059.13,814.34c-0.17,5.5-0.16,11.01-0.54,16.49c-0.48,6.84-1.08,13.69-2.01,20.49c-1.1,8.03-3.28,15.96-3.79,24.01
				c-0.88,13.75-3.64,27.1-6.6,40.48c-1.86,8.39-3.32,16.87-4.96,25.31c0.67,0.22,1.34,0.43,2.02,0.65
				c2.13-5.16,4.83-10.16,6.28-15.51c3.05-11.22,5.79-22.56,8.01-33.97c2.12-10.91,3.43-21.97,5.09-32.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M542.52,1221.64c2.08,5,3.41,10.5,6.38,14.9c9.28,13.75,18.92,27.27,28.95,40.48c4.09,5.39,9.75,9.56,14.31,14.63
				c5.98,6.65,8.91,15.09,10.83,23.49c2.51,10.97,4.13,22.23,5.04,33.45c1.02,12.6,1.22,25.32,0.96,37.96
				c-0.1,4.86-2.44,9.67-3.8,14.49c-0.61,2.18-1.32,4.33-1.99,6.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M280.09,793.35c0.5,6.5,1.35,12.99,1.43,19.49c0.2,16.82-0.08,33.65,0.11,50.47c0.16,13.87-0.21,27.63-4.54,40.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M180.22,598.45c-4.83,5-10.13,9.62-14.37,15.08c-6.74,8.68-12.72,17.94-19.13,26.87c-1.22,1.7-2.69,3.7-4.5,4.41
				c-7.21,2.85-12.42,8.15-17.39,13.65c-15.43,17.06-30.67,34.28-45.99,51.43c-0.78,0.88-1.66,1.67-2.49,2.5
				c-6.59-5.16-6.88-5.2-12.46,0.52c-2.47,2.53-4.72,5.28-7.01,7.98c-2.41,2.84-5.12,3.82-9.02,3.12c-5.33-0.96-10.36-0.16-13.57,5.3
				c-0.75,1.27-2.98,1.6-4.36,2.59c-2.41,1.73-4.94,3.39-6.96,5.52c-4.26,4.48-4.5,4.51-9.57,0.49c-3.28-2.61-6.08-6.27-10.96-6.03"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,789.86c-0.67,1.17-1.58,2.26-1.96,3.51c-3.89,12.81-6.67,26.06-11.75,38.38c-9.32,22.62-18.6,45.59-39.25,61.03
				c-1.84,1.38-3.97,2.36-5.97,3.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M679.6,346.58c-5.84,1.59-12.19,2.06-15.64,8.41c-0.52,0.96-3.22,1.18-4.82,0.99c-2.38-0.28-4.77-1.01-6.98-1.95
				c-4.89-2.07-9.42-2.18-14.6-0.22c-2.95,1.12-6.89-0.51-10.39-0.71c-2.82-0.16-5.97-0.83-8.43,0.12
				c-6.91,2.68-13.45,0.22-20.02-0.77c-9.15-1.39-19.6,2.91-20.44,13.11c-0.26,3.2-1.77,3.67-4.55,3.56
				c-5.15-0.22-10.35-0.38-15.47,0.07c-2.57,0.23-5.82,1.18-7.35,2.99c-4.5,5.32-10.24,6.54-16.63,6.92
				c-3.87,0.23-7.7,0.97-10.29,1.32c-5.66-3.2-10.47-6.5-15.73-8.74c-5.72-2.44-11.06-0.04-12.72,5.93c-1.02,3.67-3.4,3.56-5.71,3.85
				c-3.79,0.48-7.65,0.59-11.47,0.53c-2.83-0.05-5.65-0.8-8.49-0.9c-4.07-0.14-8.36-0.74-11.48,2.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M288.08,694.4c-9.21,2.35-18.63,4.18-26,10.96c-0.96,0.89-2.24,1.77-3.48,1.96c-9.57,1.5-17.42,6.68-25.5,11.5
				c-3.43,2.05-7.28,3.41-10.87,5.04c-0.38-0.35-0.79-0.62-1.06-0.99c-4.26-5.77-7.01-6.96-12.39-2.85
				c-11.09,8.46-23.79,15.01-32.56,26.35"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M264.11,969.27c5.11,1.1,10.04-0.64,13.86-3.18c10.01-6.66,19.55-14.31,23.49-26.34c2.89-8.81,5.3-17.86,6.99-26.97
				c3.53-19.09,6.56-38.27,9.56-57.46c2.93-18.72,3.23-37.57,2.05-56.47c-0.25-3.99-0.21-8.01-0.54-11.99
				c-0.74-8.84-2.85-17.75-2.18-26.47c0.96-12.48,0.9-24.75-1.39-36.99c-0.71-3.78-2.11-7.61-4.01-10.95
				c-2.35-4.11-7.28-3.59-8.74,0.97c-1.43,4.47-2.53,9.29-2.49,13.95c0.13,14.66,1.05,29.32,1.31,43.98
				c0.25,14.32,0.3,28.66-0.03,42.98c-0.24,10.34-1.09,20.67-1.95,30.98c-1.17,14.01-2.58,27.99-4.02,41.98
				c-0.16,1.55-1.26,3.01-1.93,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1128.54,669.42c0.5-2.67,1.09-5.32,1.45-8c0.13-0.96,0.08-2.39-0.51-2.93c-4.49-4.1-2.96-8.66-0.31-12.47
				c3.41-4.9,2.13-10.46,3.14-15.63c1.19-6.15,0.65-12.63,0.87-19.37c-5.19,1.08-5.99,7.24-9.77,10.52c4.46,2.46,2.88,6.15,1.01,8.81
				c-3.03,4.31-6.91,8.02-11.04,12.67c-0.14-2.58-0.23-4.08-0.32-5.83c-6.64,0.34-6.12,5.91-6.33,9.75
				c-1,18.47-1.67,36.97-2.09,55.47c-0.31,13.82,0.07,27.65-0.1,41.48c-0.18,15.66-0.65,31.32-0.96,46.98
				c-0.68,33.65-1.12,67.31-2.09,100.95c-0.47,16.34-1.5,32.69-3.02,48.97c-1.46,15.71-3.89,31.33-5.85,46.99
				c-0.18,1.48-0.02,3-0.02,4.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M811.93,812.34c-7.49,1-14.97,2.69-22.48,2.85c-13.4,0.28-26.61-2.23-39.39-6.05c-6.06-1.81-11.19,0.14-16.52,1.33
				c-10.61,2.37-21.06,3.45-32.02,2.12c-7.67-0.93-15.62,0.47-23.44,0.73c-3.82,0.13-7.7,0.39-11.48-0.05
				c-10.57-1.24-20.98-1.64-31.49,0.97c-9.57,2.37-19.28,1.36-28.95-0.35c-17.95-3.17-35.99-5.8-53.9-9.18
				c-5.99-1.13-11.68-3.86-17.5-5.86"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M797.45,993.25c-0.17-1.5-0.09-3.08-0.55-4.48c-1-3.05-2.86-5.93-3.33-9.03c-1.02-6.74-4.45-11.42-10.04-15.08
				c-2.08-1.36-3.57-3.78-5.03-5.92c-3.82-5.63-3.16-7.97,2.42-12.04c1.96-1.43,4.57-3.45,4.83-5.46
				c0.94-7.24,6.02-10.78,12.39-14.88c-3.2-1.56-5.61-2.73-8.38-4.09c-3.18,5.62-7.62,5.27-12.82,3.62
				c-3.97-1.26-8.62-0.67-12.38-2.28c-7.3-3.14-8.05-8.27-2.53-13.79c2.77-2.77,2.44-4.92-0.54-7.03c-4.56-3.23-8.13-2.4-12.07,1.92
				c-2.2,2.42-5.17,4.19-7.95,6.01c-0.89,0.58-2.55,0.92-3.41,0.48c-8.15-4.1-16.13-3.57-23.98,0.64c-1.06,0.57-1.87,1.84-2.49,2.96
				c-1.35,2.41-2.51,4.92-4.55,8.99c-9.94-13.88-18.72-26.61-28.15-38.83c-1.67-2.16-6.06-2.42-9.29-3.1
				c-3.77-0.79-7.63-1.28-11.48-1.47c-1.73-0.08-3.51,0.88-5.27,1.38c-0.24,0.67-0.47,1.35-0.71,2.02c2.49,2,4.7,4.76,7.54,5.85
				c6.74,2.58,10.68,7.71,15.11,12.99c6.09,7.28,13.56,13.39,20.26,20.17c5.63,5.69,11.05,11.6,16.45,17.51
				c0.57,0.63,0.39,1.95,0.55,2.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M348.01,828.34c1.66,1.5,3.08,3.63,5.03,4.39c11.55,4.53,20.52,12.34,28.42,21.6c0.39,0.45,0.62,1.38,1.01,1.44
				c7.95,1.07,9.25,8.41,13.08,13.47c3.32,4.38,6.84,9.65,11.52,11.76c11.58,5.23,13.06,15.85,16.38,25.79
				c1.19,3.57,2.84,7,4.27,10.49c0.77-0.19,1.53-0.37,2.3-0.56c-0.21-2.31,0.17-4.88-0.72-6.88c-3.88-8.63-4.87-17.86-5.8-27.04
				c-0.85-8.32,12.52-16.85,21.4-14.02c0.25,0.08,0.66-0.3,1.52-0.73c-1.03-5.49-3.67-9.78-9.02-12.23
				c-3.86,7.01-8.86,8.07-18.42,2.9c-6.32-3.41-12.14-7.74-18.34-11.75c-6.87,3.37-10.79,2.7-16.19-2.63
				c-3.59-3.54-6.62-8.14-12.99-6.66c-1.27,0.3-3.22-0.89-4.42-1.91c-5.39-4.56-11.89-6.28-18.49-8.06c-2.2-0.59-4.03-2.55-6.02-3.88
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M818.43,1256.12c3.9,9.98,6.17,20.17,4.51,30.99c-1.61,10.5-6.51,19.55-12.56,27.94c-14.76,20.5-10.28,33.7,5.54,46.52
				c2.2,1.79,4.41,3.57,6.48,5.51c5.01,4.68,3.22,10.25,1.43,15.45c-2.68,7.77-5.98,15.33-8.88,23.03
				c-1.77,4.71,0.24,11.66,3.97,14.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M648.64,813.34c0.5,1,1,2,1.57,3.15c-1.71,1.07-3.44,2.14-6.56,4.08c11.98,2.5,21.52,7.02,25.82,17.39
				c-2.85,4.75-5.7,9.49-8.61,14.34c5.19,11.39,17.03,11.86,26.64,16.7c-1.07,1.99-2.13,3.95-3.29,6.11
				c-8.5-2.64-16.75-5.37-25.11-7.73c-3.37-0.95-6.96-1.3-10.47-1.46c-1.3-0.06-3.02,0.9-3.92,1.94c-2.1,2.42-4.47,2.38-7.06,1.42
				c-5.68-2.11-11.27-4.47-17-6.42c-3.59-1.22-5.01-3.4-4.48-7.05c0.89-6.16-1.37-10.46-6.86-13.71c-1.59-0.94-1.61-4.52-2.36-6.89
				c0.42-0.46,0.84-0.92,1.25-1.38c-1.84,0-3.79-0.41-5.5,0.07c-9.36,2.63-17.29-0.48-24.46-6.1c-8.29-6.5-16.36-13.27-24.47-19.98
				c-0.71-0.58-1.03-1.63-1.53-2.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M907.32,1231.63c-9.99-8.66-19.34-18.28-30.23-25.58c-5.42-3.63-12.48-5.4-18.26-9.32c-6.42-4.35-12.97-8.73-20-11.9
				c-7.64-3.44-15.86-5.65-23.91-8.11c-3.89-1.19-7.97-1.73-11.97-2.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M301.07,814.84c-5.79-1.15-7.44-4.35-7.79-10.48c-0.63-11.22-2.82-22.38-4.88-33.47c-0.41-2.23-3.15-4.03-4.81-6.03
				c-1.33,2-3.09,3.83-3.92,6.02c-4.88,12.82-2.64,26.25-3.05,39.45c-0.57,18.49-1.09,36.98-1.48,55.47
				c-0.19,8.82,0.46,17.69-0.2,26.47c-0.45,6.06-2.24,12.08-3.94,17.97c-0.81,2.8-1.49,5.02,0.56,7.57c2.15,2.68,1.78,7.4,6.55,7.95"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M847.39,914.79c10.99,2.9,18.22,12.18,28.33,16.79c4.14,1.89,7.37,6.67,9.99,10.79c4.09,6.43,8.07,12.23,16.04,14.65
				c3.44,1.04,6.14,4.67,9.05,7.27c4.56,4.09,8.59,8.92,13.61,12.31c5.69,3.85,15.82,4.08,20.37-2.34"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1064.12,342.58c-0.28,4.71-1.83,9.27,0.79,14.11c1.36,2.52,0.57,6.22,0.7,9.38c0.35,8.33,0.51,16.67,1.04,24.99
				c1.41,22.16,2.96,44.31,4.46,66.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M871.86,424.54c-0.33,3.66-0.51,7.35-1.05,10.99c-0.4,2.7-1.38,5.32-1.91,8.01c-0.35,1.8-0.73,3.71-0.46,5.48
				c0.48,3.17-4.44,14.7-7.06,16.02c-4.86,2.44-9.58,5.17-14.52,7.42c-3.48,1.58-6.33,0.31-7.41-3.45
				c-1.08-3.76-1.76-7.64-2.54-11.48c-1.69-8.32-3.34-16.66-5-24.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M367.98,1192.15c-3.56-3.61-3.87-9.05-6.32-12.78c-6.74-10.26-15.84-18.52-25.41-26.48c-9.15-7.62-18.9-14.12-28.65-20.78
				c-4.29-2.93-8.03-4.28-13.14-1.64c-2.17,1.12-6.16,0.85-8.26-0.47c-5.54-3.5-9.33-0.37-13.64,2.13c-1.79,1.03-3.99,1.31-5.95,2.08
				c-4.52,1.78-9.44,2.99-13.37,5.66c-3.98,2.7-7.67,5.25-12.61,5.34c-0.34,0.01-0.71,0.26-0.99,0.49
				c-4.86,3.83-10.41,3.25-15.89,2.25c-1.43,3.74-2.76,7.24-4.09,10.74"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M637.65,1283.11c5.49,3.52,6.2,9.27,7,14.99c2.17,15.53-3.56,30.27-4.42,45.48c-0.47,8.47-0.44,17.01,0.01,25.48
				c0.44,8.31-1.17,15.94-4.44,23.56c-1.7,3.96-1.82,8.6-2.65,12.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M76.34,1206.15c-2-0.83-4-2.38-5.99-2.37c-7.32,0.01-14.66,0.41-21.96,1c-1.24,0.1-2.84,1.65-3.41,2.91
				c-1.73,3.86-4.94,4.45-8.57,5.01c-9.68,1.5-19.31,3.3-28.98,4.93c-4.3,0.72-6.23,2.95-6.06,7.51c0.38,9.99,0.4,19.99,0.56,29.98"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M995.71,521.49c0.33,11.66,0.26,23.35,1.08,34.98c1.71,24.01,3.91,47.98,5.91,71.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M938.28,1244.13c-2.83,0.17-5.73-0.01-8.48,0.57c-7.46,1.56-14.63,0.43-22.01-0.94c-13.46-2.5-26.7-0.74-39.98,2.66
				c-8.87,2.27-18.26,2.54-27.41,3.71"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M876.85,684.41c1.31,21.66,8.4,42.23,12.12,63.44c2.35,13.38,5.69,26.63,12.85,38.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M100.81,432.03c-4.86,7.68-13.08,10.78-20.48,14.98c-0.45,0.25-1.09,0.62-1.48,0.47c-10.09-3.79-13.98,5.69-20.49,9.53
				c-4.82,2.84-9.36,6.56-14.56,8.27c-6.48,2.13-10.99,6.76-16.46,10.14c-7.29,4.5-14.88,8.51-22.45,12.53
				c-3.03,1.61-3.61,3.91-3.52,7.05c0.3,11.16,0.38,22.32,0.55,33.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M386.46,421.54c0.85-5.52-0.74-10.68-1.58-16.06c-1.06-6.72,0.71-13.96,1.66-20.91c0.54-3.96-1.44-10.68-5.67-12.25
				c-3.37-1.25-7.56-0.29-11.39-0.19c-0.35,0.01-0.81,0.89-0.98,1.44c-2.91,9.63-10.79,17.17-11.2,27.97c-0.15,4.04-2.47,8-3.8,12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M560.74,1256.62c-0.67,0.33-1.33,0.95-2,0.96c-21.31,0.38-42.61,0.75-63.92,1.02c-9.65,0.12-19.31,0.02-28.96,0.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M787.96,444.53c-2.77,13.53-1.21,27.03,0.55,40.47c2.29,17.52,4.94,34.99,7.44,52.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M327.53,1307.6c-2,0.83-3.93,2.12-6.01,2.41c-4.62,0.63-9.31,0.74-13.97,1.11c-0.67,0.05-1.7,0.1-1.94,0.52
				c-2.74,4.67-6.87,2.96-11.02,3.24c-6.23,0.42-12.34,2.69-18.49,4.24c-1.37,0.35-2.68,1.44-4,1.41
				c-12.66-0.32-24.63,3.87-36.97,5.46c-10.59,1.36-21.3,1.68-31.94,2.67c-3.03,0.28-5.98,1.35-9,1.93
				c-8.74,1.67-17.83-1.12-26.54,2.33c-2.19,0.87-5.26-0.49-7.92-0.82"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M150.25,1333.08c-3.33,1-6.66,2-9.99,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M28.4,509.5c-4.83,2.67-10.03,4.83-14.37,8.14C9.86,520.8,6.49,525.06,3,529.05c-0.96,1.1-1.5,2.94-1.5,4.44
				c-0.1,19.82-0.07,39.65-0.04,59.47c0,0.5,0.31,1,0.48,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M261.61,982.26c1,20.66,2.04,41.31,2.94,61.97c0.11,2.48-1.02,5.02-0.86,7.49c0.45,7.18,1.25,14.33,1.92,21.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1064.62,1059.22c-0.83,7.5-1.8,14.98-2.46,22.49c-0.69,7.75-0.5,15.27-2.98,23.16c-2.86,9.08-1.71,19.48-2.01,29.31
				c-0.35,11.32-0.4,22.65-0.53,33.98c-0.06,5.5,0.1,11-0.04,16.49c-0.24,9.5-0.88,18.99-0.9,28.49c-0.02,7.99,1.14,16.01,0.8,23.98
				c-0.51,12.01-2.15,23.98-2.75,35.99c-0.32,6.3,0.86,12.66,0.81,18.98c-0.08,10.66-0.65,21.32-0.88,31.99
				c-0.04,1.66,0.61,3.33,0.95,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M841.4,1266.12c7.08,5.97,7.15,14.19,7.58,22.48c0.38,7.19,2.18,14.32,2.27,21.5c0.15,12.65-0.33,25.33-0.99,37.97
				c-0.19,3.71-2,7.31-2.8,11.02c-0.27,1.24-0.17,3.52,0.5,3.85c10.19,5.12,6.74,14.21,6.31,22.11c-0.53,9.85-2.02,19.65-2.83,29.5
				c-0.49,5.92-0.42,11.89-5.05,16.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M274.6,1423.04c0.77,1.21,1.53,2.42,2.55,4.02c1.02-1.33,1.82-2.36,2.79-3.63c2.07,1.65,4.32,3.44,6.86,5.47
				c2.63-2.84,4.75-6.4,7.89-8.14c3.3-1.83,4.23,1.42,5.12,3.26c0.92,0.67,1.78,1.44,2.77,1.99c1.1,0.61,2.29,1.11,3.49,1.49
				c0.6,0.19,1.64-0.25,1.94,0.08c4.25,4.78,4.98,4.96,12.03,2.46c5.77,5.51,5.77,5.51,16.06-0.01c5.01,2.34,9.44,4.47,13.91,6.49
				c3.29,1.48,7.26,2.17,4.76,7.64c-0.4,0.87,2.8,3.39,4.34,5.15c0.66-0.21,1.33-0.41,1.99-0.62c-0.53,3.28-1.1,6.56-1.58,9.85
				c-0.14,0.98-0.34,2.15,0.05,2.96c0.77,1.61,3.12,3.62,2.72,4.42c-1.46,2.93-3.62,5.59-5.85,8.04c-1.09,1.2-2.93,1.72-4.42,2.56
				c-2.27,1.28-4.86,1.86-5.88-1.04c-1.42-4.03-4.52-3.2-7.07-2.74c-1.07,0.19-1.71,2.78-2.53,4.28c-0.36,0.65-0.94,1.92-0.96,1.91
				c-5.84-2.69-8.08,4.85-13.02,4.6c-0.5-0.02-1.02,0.21-1.8,0.38c-0.91,3.33,1.85,8.79-5.19,8.7c-6.38-0.08-6.12-4.39-5.94-9.23
				c-5.37,0.79-8.9-0.38-8.13-6.38c0.05-0.41-1.49-1.56-1.86-1.39c-1.76,0.78-4.17,1.52-4.87,2.98c-1.48,3.09-2.55,2.47-4.16,0.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M806.44,1425.54c7.32,1.5,14.7,2.79,21.96,4.55c8.41,2.03,17.66-0.68,25.47,4.46c11.69,7.69,24.71,10.77,38.45,12.14
				c4.76,0.48,9.54,2.4,13.96,4.42c13.42,6.11,26.83,12.27,39.89,19.09c4.11,2.15,7.08,6.48,10.58,9.81
				c6.49,6.17,12.98,12.33,19.48,18.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M94.32,1008.25c0.83,9.66,2.37,19.33,2.32,28.98c-0.07,11.83-1.68,23.65-2.19,35.49c-0.15,3.46,1,7.05,1.92,10.48
				c1.31,4.88,2.95,9.67,4.45,14.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M925.79,441.53c-5.81-4.42-11.09-8.98-13.79-16.38c-1.16-3.17-5.56-5.28-8.71-7.57c-5.9-4.29-12.18-8.09-17.89-12.61
				c-5.21-4.13-10.94-4.69-17.04-3.88c-7.51,1-14.99,2.29-22.48,3.45c-0.33,0.05-0.73,0.13-0.99-0.01c-6.71-3.78-14.2-3.41-21.49-3.9
				c-2.63-0.18-5.57,0.01-7.93,1.04c-4.2,1.83-8.08,1.86-12.57,1.04c-7.72-1.42-15.61-1.96-23.44-2.62
				c-7.98-0.67-15.99-0.85-23.96-1.59c-7.04-0.66-11.85,2.4-15.5,8.05c-2.61,4.03-5.25,8.05-8.02,11.97c-0.72,1.03-2,2.46-2.95,2.41
				c-8.01-0.41-13.35,4.94-19.5,8.59c-1.72,1.02-3.65,1.69-5.48,2.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M734.03,1298.1c-12.82,2.67-25.6,5.53-38.46,7.95c-15.97,3-32,4.91-47.94-0.43c-0.45-0.15-0.99-0.02-1.49-0.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M728.04,561.47c2.86,11.92,7.6,23.8,0.54,36.06c8.4,7.63,13.14,18.45,23.83,23.61c1.52,0.73,1.9,4.09,2.57,6.31
				c2.07,6.81,4.05,13.64,5.97,20.5c0.2,0.73-0.28,1.65-0.45,2.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M371.98,1011.74c2.83,2.67,6.19,4.95,8.4,8.06c11.41,16.07,23.97,31.11,37.35,45.59c5.96,6.45,9.75,14.88,14.71,22.29
				c2.78,4.14,5.95,8.02,8.95,12.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M470.86,1261.62c-9.65-3.81-19.64-1.53-29.46-1.32c-3.52,0.07-8.11,1.15-10.25,3.54c-4.22,4.69-9.74,4.13-14.71,5.8
				c-7.18,2.42-14.25,5.22-21.51,7.37c-3.66,1.08-7.63,1.1-11.46,1.6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,1019.24c-22.05,15.54-68.82,30.76-82.23,26.21c1.86-1.44,3.34-2.58,4.82-3.72"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M955.76,252.62c0.33,4,0.9,7.99,0.95,11.99c0.14,9.66,0.4,19.34-0.04,28.98c-0.69,15.01-1.91,29.99-2.91,44.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M405.94,791.35c1.83-1.83,3.66-3.67,5.49-5.5c5.43-5.4,12.81-9.07,14.23-17.95c0.34-2.1,3.92-3.56,5.71-5.57
				c2.61-2.93,4.95-6.1,7.56-9.37c-6.87-3.92-12.69-1.7-17.96,1.98c-7.2,5.04-13.96,10.69-21.08,15.84
				c-1.63,1.18-3.94,1.41-5.94,2.07"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M267.61,970.76c0.33,8,0.9,15.99,0.94,23.99c0.06,10.83-0.68,21.67-0.3,32.48c0.26,7.36,1.86,14.66,2.85,21.99
				c1.01,7.49,2.28,14.97,2.94,22.49c1.67,18.98,3.05,37.98,4.55,56.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M420.92,798.35c-4.81-3.89-9.75-7.3-16.49-6.07c-0.91,0.17-2.39-0.29-2.92-0.98c-4.22-5.5-9.14-2.88-13.5-0.84
				c-7.34,3.44-14.25,7.8-21.55,11.32c-8.19,3.95-16.54,7.57-24.98,10.97c-3.1,1.25-6.6,1.55-9.96,2.06c-3.3,0.5-6.65,0.7-9.98,1.04"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M887.34,803.35c1.17,3.33,2.19,6.72,3.52,9.99c5.45,13.4,10.86,26.97,20.58,37.88c9.07,10.18,19.17,19.45,28.85,29.08
				c1.84,1.83,3.84,3.48,5.9,5.33c-5.42,2.87-9.42-0.18-13.37-2.37c-9.74-5.39-16.07-14.3-22.45-22.98
				c-10.03-13.64-19.27-27.81-26.09-43.41c-0.32-0.73-1.6-1.03-2.43-1.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1047.64,750.37c0.17,7.5,0.35,14.99,0.48,22.49c0.01,0.66-0.44,1.33-0.46,2c-0.36,13.16-1.19,26.33-0.82,39.47
				c0.2,7.13,0.57,14.92,7.37,20.37c0.89-1.98,1.66-3.68,2.42-5.37"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M47.88,1174.66c7.48-2.43,14.01,1.82,21.08,2.24c0.34,0.56,0.67,1.12,1.01,1.68c-3.76,4.58-7.52,9.16-11.64,14.18
				c0.03,0.03,0.65,1.59,1.56,1.79c5.62,1.2,11.3,2.08,16.96,3.11c4.47,0.82,6.36-1.72,5.89-5.49c-1.08-8.58,5.19-12.57,10.4-18.48
				c2.82,6.41,7.87,8.75,13.64,10.61c5.44,1.75,7.1,5.58,6.04,10.86c-1.45,7.17-1,8.11,5.49,11.49c5.22,2.73,6.67,4.68,3.26,9.32
				c-4.82,6.55-3.07,13.71-2.76,20.14c0.28,5.78,4.61,11.46,7.54,16.99c3.43,6.46,7.27,12.69,10.93,19.02
				c0.49,0.84,1.62,1.9,1.4,2.46c-2.82,7.36,2.41,11.76,6.15,16.48c5.53,6.97,6.61,15.31,7.81,23.56c0.76,5.24,0.44,10.65,0.61,15.98
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1057.63,1157.67c12.48,7,25.07,13.82,37.42,21.05c7.69,4.5,15.02,9.6,22.51,14.43c2.33,1.5,4.7,2.94,6.98,4.51
				c4.14,2.84,7.94,3.08,11.53-0.98c0.92-1.05,2.29-1.7,4.03-2.94c-8.11-4.43-15.01-9.99-23.94-12.91
				c-7.85-2.57-14.85-7.84-22.04-12.24c-3.05-1.87-5.65-3.12-9.09-1.02c-0.97,0.59-2.98,0.63-3.88,0
				c-6.27-4.33-12.39-8.89-18.53-13.42c-1.72-1.26-3.34-2.65-5-3.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M580.72,516.49c4.95,5.79,5.27,13.07,2.13,18.79c-3.1,5.63-1.88,10.13-0.58,15.18c0.53,2.08,1.82,3.95,2.4,6.02
				c1.01,3.56,1.08,6.65-2.35,9.6c-4.62,3.98-8.06,8.93-9.26,15.34c-0.47,2.52-2.4,5.2-4.41,6.94c-6.44,5.58-13.24,10.75-19.9,16.08"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1160.5,822.34c-3.16,10.49-6.54,20.93-9.43,31.5c-3.72,13.57-11.6,24.88-19.04,36.46c-2.27,3.54-4.74,6.95-6.92,10.54
				c-0.56,0.92-0.39,2.3-0.56,3.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M919.3,535.48c4.27,2.2,6.18,6.02,5.86,10.49c-0.25,3.44,1.07,5.54,2.99,8.11c1.99,2.68,3.38,6.12,3.98,9.42
				c0.57,3.12-2.06,3.87-4.83,3.47c-5.83-0.85-7.31,0.4-7.47,6.5c-0.09,3.5-0.41,7.05,0.06,10.48c1.47,10.74-2.78,20.45-5.16,30.47
				c-1.49,6.28-3.88,12.36-5.9,18.51c-1.16,3.52,0.34,5.67,2.68,6.84c2.98-7.56,6.48-14.78,8.63-22.38
				c1.7-6.02,3.36-11.77,7.68-16.44c0.16-0.17,0.46-0.32,0.48-0.5c1.65-12.55,11.84-19.64,18.49-28.99c0.08-0.11,0.01-0.33,0.01-0.49
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M961.25,417.79c0,1.25-0.01,2.5,0,3.75c0.14,20.9-0.85,41.86,3.96,62.48c1.87,8.01,5.23,15.2,10.52,21.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M877.35,778.36c2.5,6.66,4.79,13.41,7.58,19.95c1.3,3.06,3.77,5.6,5.37,8.55c5.75,10.61,10.95,21.54,17.11,31.91
				c7.96,13.39,18.9,24.26,31.38,33.51c3.52,2.61,7.43,4.27,12.7,2.32c-2.19-2.74-4.11-5.36-6.25-7.77
				c-6.27-7.05-12.65-13.99-18.91-21.04c-0.49-0.55-0.38-1.63-0.54-2.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M968.24,1498.5c-5.33-4.66-10.57-9.43-16.05-13.9c-0.98-0.8-3.13-0.98-4.39-0.5c-8.49,3.23-16.93,2.87-25.53,0.49
				c-2.01-0.56-4.31-0.2-6.47-0.06c-3.67,0.24-7.32,0.82-10.99,0.93c-5.46,0.17-6.31,1.1-4.97,6.53c0.54,2.2,1.31,4.34,1.98,6.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M734.53,469.52c9.49-6.82,19.39-12.78,30.93-15.63c1.41-0.35,2.36-2.53,3.53-3.86c-1.5-0.67-2.99-1.35-4.5-1.99
				c-0.63-0.26-1.3-0.42-2.69-0.86c3.69-3.49,7.2-6.81,10.71-10.13c-0.21-0.64-0.42-1.28-0.62-1.92c-1.96,0.3-4.69-0.12-5.77,1.01
				c-8.05,8.45-15.77,17.21-23.57,25.89c-0.17,0.18-0.02,0.65-0.02,0.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1058.63,1109.2c1,1,2.05,1.96,2.99,3.01c5.68,6.32,11.2,12.77,17.08,18.89c1.37,1.43,3.86,1.9,5.91,2.56
				c4.66,1.49,9.03,3.11,12.56,6.95c5.52,6,12.79,7.91,20.88,8.3c3.39,0.16,6.68,2.75,9.99,4.29c2.52,1.17,5,3,7.49,0
				c-10.17-10.45-20.15-21.1-33.94-27.02c-6.07-2.61-6.97-9.09-9.97-13.99c-0.54-0.88-0.62-2.02-1.04-2.98
				c-3.28-7.46-3.29-7.46,3.08-12.05c-5.86-7.33-11.73-14.66-17.7-22.12c9.62-4.72,9.73-4.68,16.64,2.17
				c5.32,5.28,10.72,10.3,18.96,10.68c1.56,0.07,3.4,2.23,4.43,3.86c4.14,6.5,10.44,8.71,17.55,8.77c1.82,0.02,3.99-1.85,5.41-3.4
				c1.68-1.82,3.31-4.07,1.59-6.93c-2.38-3.97-4.77-7.94-6.98-12c-2.13-3.93-4.02-7.98-6.02-11.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M265.61,1048.23c0.33,8.66,0.58,17.33,1.03,25.99c0.37,7.17,0.88,14.33,1.49,21.49c0.92,10.83,1.98,21.66,2.98,32.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M870.36,693.4c5.29,27,10.33,54.06,19.98,79.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M150.25,582.96c-5.66,0-11.32,0.06-16.98-0.01c-8.26-0.1-16.39,0.19-24.52,2.31c-4.36,1.14-9.39-0.48-13.9,0.34
				c-9.24,1.69-18.34,4.19-27.49,6.35"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M108.8,944.28c-2.5,1-5.08,1.83-7.46,3.06c-1.66,0.86-2.87,2.92-4.55,3.33c-9.16,2.23-15.14,8.41-20.46,15.59
				c-6.41,8.66-14.35,15.33-23.83,20.73c-7,3.98-12.78,10.1-19.11,15.26"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M294.57,812.84c-0.33,6.16-0.83,12.32-0.97,18.49c-0.42,18.66-1.53,37.35-0.7,55.96c0.45,10.14-2.92,17.98-7.75,26.04
				c-2.56,4.26-4.48,8.91-6.53,13.46c-1.58,3.52-2.69,7.07,2.65,7.49c-0.26,2.08-0.46,3.73-0.66,5.37c0.64,0.3,1.28,0.6,1.92,0.9
				c2.02-2.59,4.04-5.18,6.06-7.77c1.17-1.5,2.33-3,3.5-4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M445.89,540.98c-5.33,4-10.24,8.78-16.07,11.82c-9.61,5-18.4,10.93-27.02,17.48c-8.87,6.73-20.28,7.83-30.82,10.66
				c-1.95,0.52-3.99,0.69-5.99,1.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M299.07,1204.15c-1.33,2.46-0.64,7.11-6.01,5.05c-4.55-1.74-6.03-4.66-4.34-10.51c-1.21-0.35-2.62-0.42-3.58-1.11
				c-1.04-0.75-1.67-2.08-2.99-3.85c-1.11,1.54-2.09,4.29-3.61,4.64c-1.72,0.39-5.06-0.91-5.66-2.33c-0.88-2.08,0.05-4.93,0.23-7.77
				c-1.23-0.05-4-2.91-4.78,2.34c-0.17,1.13-4.04,2.42-6.2,2.41c-5.86-0.03-5,2.63-2.98,6.12c-6.23,3.54-8.79,2.55-10.55-3.98
				c-0.16-0.59-1.23-1.53-1.41-1.43c-1.25,0.68-2.73,1.35-3.47,2.46c-1.8,2.71-3.13,3.16-5.09,1.48c-3.41-2.93-7.07-5.58-10.42-8.58
				c-1.3-1.16-3.1-3.12-2.88-4.42c1.95-11.76-3.69-20.61-10.03-29.6c-4.62-6.55-8.27-13.82-12.07-20.92
				c-4.78-8.93-10.05-12.04-19.49-10.33c-1.53,0.28-2.7,2.51-4.98,4.78c-5.94-3.78-12.65-8.23-19.55-12.37
				c-3.82-2.29-8.14-3.76-11.91-6.12c-4.53-2.85-9.18-5.78-12.89-9.56c-3.31-3.37-6.63-3.85-10.62-2.83
				c-4.03,1.03-7.98,2.38-12.01,3.4c-0.7,0.18-1.62-0.76-2.48-0.9c-6.32-1.05-12.62-2.26-18.99-2.87c-1.73-0.17-4.56,1.02-5.33,2.43
				c-4.26,7.81-10.79,6.63-17.09,4.31c-12.19-4.49-24.61-6.21-37.49-4.91c-1.8,0.18-2.96,0.01-4.22,2.39
				c-2.76,5.22-6.41,10.1-10.36,14.53c-4.06,4.56-8.89,8.42-13.39,12.58"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M474.35,1201.15c-1,2.5-1.36,6.44-3.12,7.22c-5.09,2.28-3.76,4.69-1.51,7.89c-3.94,3.31-4.75,7.27-1.88,11.89
				c0.34,0.56,0.5,1.32,0.5,1.98c-0.02,2.7-2.74,6.03,2.01,7.52c2.71,0.85,5.34,1.97,8,2.97c0.84,0.32,2.27,0.51,2.4,1.02
				c1.18,4.66,5.21,3.45,7.51,2.74c3.53-1.09,6.19-0.41,9.07,1.22c7.8,4.42,15.97,0.06,23.98,0.82c6.57,0.62,13.3-0.42,19.96-0.82
				c3.83-0.23,7.66-0.65,11.49-0.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1044.15,1026.24c-4.96,12.46-13.86,20.16-26.94,23.58c-14.66,3.83-29.28,4.2-43.95,0.81c-4.26-0.98-9.23-1.8-11.12-8.64
				c5.51,0.64,10.53,0.46,15.05,1.88c15.94,5,31.53,3.34,46.95-1.74c3.75-1.24,7.01-3.91,10.53-5.87c1.1-0.61,2.32-1.02,3.49-1.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M815.43,449.03c0.5,5.33,0.64,10.73,1.56,15.98c3.33,18.85,6.91,37.66,10.44,56.48c0.38,2.02,0.98,4,1.48,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,1243.63c-5.99-3.83-12.04-7.57-17.96-11.52c-5.2-3.47-10.15-0.71-14.95,0.7c-2.66,0.78-4.59,3.73-8.08,2.44
				c-0.72-0.27-2.49,0.63-2.87,1.43c-2.11,4.41-5.58,3.5-8.53,1.87c-7.96-4.41-15.92-8.91-23.46-14c-8.11-5.47-7.91-5.78-15.47,0.44
				c0.8,1.04,1.91,1.99,2.34,3.18c0.5,1.38,1.12,3.76,0.47,4.35c-5.79,5.22-8.21,13.78-16.37,16.62"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M700.07,813.34c-1.64,2.02-3.29,4.03-5.07,6.22c1.75,5.1,6.64,4.81,10.58,5.15c11.62,1.01,23.29,1.52,34.95,2.09
				c5.43,0.27,5.79,0.58,4.99,6.53c-4.99,0.5-9.99,1-14.98,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M782.47,470.51c-3.5,3-7.13,5.85-10.45,9.04c-3.59,3.45-7.52,3.87-11.47,1.37c-2.95-1.87-4.86-1.46-7.64,0.46
				c-3.45,2.38-7.42,4.3-11.43,5.5c-5.36,1.61-6.06,5.9-6.8,10.15c-0.2,1.15,1.2,3.28,2.4,3.85c3.5,1.67,7.21,2.98,10.94,4.07
				c5.08,1.49,9.38,1.69,13.67-3.28c5.39-6.22,11.61-11.98,19.27-15.67"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M302.56,938.28c14.35,11.99,27.48,25.11,39.08,39.88c7.63,9.71,16.74,18.24,24.74,27.67c3.95,4.65,6.88,10.17,10.15,15.11
				c-2.8,3.41-5.29,6.74-8.13,9.73c-1.29,1.36-3.26,2.9-4.93,2.91c-6.71,0.03-11.01,2.86-13.56,9.11c-1.81,4.44-5.4,6.67-10.41,6.04
				c-1.82-0.23-3.66-0.37-6.37-0.65c3.51,10.75,10.64,18.56,17.7,26.86c-5.87,4.38-11.12,8.47-19.41,5.49
				c-5.54-2-11.86-1.82-18.81-2.74c4.04,5.05,7.27,8.99,10.39,13.02c1.98,2.56,2.31,5.16-0.48,7.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M469.86,539.48c-8.62-0.91-15.78,2.42-21.94,8.03c-8.27,7.53-16.43,15.2-24.45,23.01c-2.81,2.73-4.12,6.2-4.27,10.44
				c-0.09,2.54-2.41,5.02-3.77,7.5c-2.23,4.06-7.01,6.63-6.99,12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,895.8c-6.16,7.16-11.64,15.1-18.64,21.31c-10.37,9.2-21.61,17.45-32.91,25.52c-3.85,2.75-6.25,5.49-6.66,10.09
				c13.14-9.08,26.14-18.06,35.74-30.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M730.04,565.47c6.27,6.75,11.81,13.86,13.09,23.47c0.24,1.77,1.59,3.65,2.93,4.96c9.85,9.67,14.43,21.94,16.72,35.07
				c0.68,3.93-1.12,8.29-1.79,12.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,185.16c-10.32-2.67-20.75-4.98-30.91-8.16c-4.3-1.35-7.81-5.74-12.09-6.55c-7.13-1.36-14.59-1.11-21.92-1.21
				c-3.82-0.05-7.66,0.6-11.48,0.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M928.29,468.02c0.5,6.16,0.65,12.38,1.57,18.48c1.74,11.53,3.07,23.24,6.18,34.42c2.62,9.43,10.21,15.23,19.71,18.06"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M774.48,1418.54c-9.65-2.33-19.23-5.11-28.98-6.89c-14.91-2.73-29.94-4.78-44.93-7.07c-0.63-0.1-1.33,0.3-1.99,0.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,904.8c-3.66,5.33-7.76,10.42-10.88,16.05c-4.72,8.52-12.06,14.56-18.28,21.69c-9.63,11.05-22.86,17.01-33.28,26.7
				c-0.79,0.73-1.97,1.03-2.97,1.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M311.55,1289.11c-6.82-1.33-13.6-3.11-20.49-3.82c-3.56-0.37-7.29,1.31-10.97,1.77c-3.13,0.4-6.32,0.45-9.48,0.53
				c-3.33,0.08-6.66,0.15-9.99-0.02c-2.84-0.14-5.69-1.05-8.49-0.87c-5.51,0.36-10.98,1.43-16.49,1.85
				c-4.14,0.31-8.32,0.06-12.48,0.06c-8.32,0-16.65,0-24.97,0c-2.16,0-4.35,0.22-6.49-0.04c-5.8-0.7-11.57-1.61-17.98-2.52
				c-1.08-9.23-2.29-19.57-3.5-29.92"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,969.77c-14.81,10.16-29.34,20.79-44.55,30.31c-9.23,5.78-19.53,9.84-29.37,14.63c-0.2,0.1-0.65-0.3-0.98-0.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1069.62,970.26c-3,14.16-5.36,28.49-9.19,42.42c-2.97,10.78-7.79,21.05-11.78,31.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M536.27,1120.69c1.66,5.83,3.24,11.69,5.01,17.48c2.88,9.41,1.37,18.7-0.52,27.99c-2.7,13.25-5.12,26.5-1.49,39.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,943.78c-2.66,3.66-5.42,7.26-7.97,11.01c-4.98,7.32-11.65,12.53-19.48,16.51c-13.54,6.88-27.03,13.88-40.4,21.08
				c-1.47,0.79-2.43,3.1-3,4.9c-2.48,7.95-4.06,16.25-7.23,23.9c-3.55,8.58-6.85,17.88-14.92,23.4c-5.17,3.53-5,8.07-3.06,12
				c1.32,2.66,5.47,4.85,8.7,5.49c18.52,3.68,35.79-1.77,51.76-10.11c12.93-6.75,26.17-13.78,35.6-25.7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M506.31,705.9c-4.49,2.17-8.89,4.57-13.52,6.41c-3.16,1.26-6.69,1.56-9.94,2.63c-3.9,1.29-7.76,2.75-11.48,4.5
				c-4.92,2.3-9.8,4.74-14.48,7.5c-7.6,4.47-15.03,9.25-22.69,14c-0.55-1.02-1.12-2.07-2.42-4.45c-6.67,5.82-13.23,11.14-19.31,16.95
				c-7.06,6.75-13.69,13.95-20.56,20.9c-0.41,0.42-1.3,0.37-1.96,0.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M497.82,571.46c-1.17,1.33-2.34,2.66-3.49,4c-14.16,16.44-25.3,35.78-44.5,47.39c-6.92,4.19-14.44,7.38-22.55,11.47
				c0.55-3.82,1.09-7.6,1.63-11.38"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.94,379.56c-0.17,5.33-0.46,10.66-0.48,15.99c-0.06,16.99-0.04,33.98,0,50.97c0,1.67,0.31,3.33,0.48,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M352.5,952.27c-1.66-5.33-4.13-10.57-4.78-16.02c-0.7-5.91-4.21-9.54-7.72-13.46c-3.78-4.21-8.22-7.96-11.27-12.64
				c-2.05-3.14-3.33-7.66-2.91-11.33c0.52-4.53-0.56-8.15-2.24-12.05c-3.15-7.29-2.31-10.26,4.43-16.41
				c4.37,6.19,7.85,13.21,13.22,18.2c6.63,6.16,10.63,14.4,18.04,20.01c4.23,3.2,6.39,9.27,9.16,14.22
				c2.95,5.27,5.08,11.11,10.59,14.31c0.45-0.25,0.89-0.5,1.34-0.74c-1.8-3.53-3.55-7.09-5.4-10.59c-1.26-2.37-2.87-4.58-3.93-7.02
				c-2.3-5.28-1.22-8.19,3.43-11.5c3.77-2.68,7.35-5.63,11.01-8.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,142.18c-3.83-0.33-7.65-0.73-11.49-0.99c-6.31-0.42-11.32,2.21-14.39,7.53c-3.56,6.17-8.32,10.16-15.57,9.87
				c-9.81-0.38-17.84,4.12-25.98,8.55c-0.78,0.43-1.66,0.68-2.49,1.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.94,1257.12c-0.17,6.33-0.46,12.66-0.48,18.99c-0.06,19.32,0,38.65-0.04,57.97c-0.01,4.34,0.43,8.06,4.38,11.16
				c2.69,2.12,4.13,5.83,6.13,8.83"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M729.54,421.54c-3.74,5.07-7.47,10.13-11.43,15.5c1.35,0.93,2.99,2.06,4.7,3.25c-8.93,6.89-17.55,13.6-26.26,20.2
				c-1.46,1.1-3.3,1.7-4.96,2.54c-0.17-1.83-0.36-3.66-0.49-5.5c-0.07-1-0.01-2.01-0.01-4.72c-11.72,6.65-22.34,12.68-32.96,18.71"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,598.95c-6.98,8.18-13.95,16.35-20.36,23.87c2.37,3.13,5.49,5.85,6.57,9.21c0.68,2.12-1.37,5.4-2.74,7.86
				c-5.02,9.07-10.27,18.02-15.43,27.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1159,954.27c-2.5,2.83-5.27,5.47-7.43,8.54c-3.64,5.17-7.93,9.34-13.48,12.56c-6.1,3.53-11.51,8.24-17.59,11.81
				c-9.52,5.59-18.71,12.03-29.92,14.07"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M904.82,780.36c10.15,27.51,25.74,50.7,51.94,65.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M73.35,616.94c-4-1.67-7.91-3.56-12.01-4.93c-3.89-1.3-7.97-2.05-11.96-3.07c-1.84-0.47-3.89-0.62-5.46-1.56
				c-6.85-4.11-14.26-4.68-22.01-4.53c-6.48,0.13-12.98-0.57-19.47-0.9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M233.65,963.27c0.83,9,1.66,17.99,2.5,26.99c0.82,8.83,2.16,17.65,2.39,26.49c0.5,19.32,0.68,38.65,0.53,57.97
				c-0.12,15.33-1.03,30.65-1.37,45.98c-0.14,6.49,0.19,13,0.51,19.49c0.08,1.69,0.94,3.33,1.44,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M145.26,1360.57c-8.7,5.49-17.88,9.07-28.47,8.09c-2.77-0.26-5.86,0.03-8.45,1c-17.14,6.41-35.15,8.98-52.97,12.41"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1092.59,1351.57c-1.06-12.34-7.24-20.48-18.93-25.1c-13.43-5.3-18.76,0.56-19.97,12.11c-0.45,4.3-0.21,8.67-0.63,12.98
				c-0.17,1.71-1.25,3.34-1.92,5.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M801.95,695.4c-8.05,11.33-19.1,19.53-29.36,28.61c-4.39,3.89-9.3,5.05-14.59,5.84c-3.3,0.5-6.67,0.58-9.98,1.05
				c-6.28,0.9-9.54,5.53-12.49,10.47c2.96,1.58,5.93,3.16,8.74,4.66c-7.75,9.18-15.02,17.72-22.17,26.36
				c-1.01,1.22-1.38,2.96-2.04,4.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M99.81,513.99c-2.99,4.33-2.23,7.86,1.97,11.03c4.93,3.72,8.79,7.97,10.97,14.3c0.95,2.77,6.81,3.95,10.56,5.6
				c1.74,0.76,3.74,0.91,4.99,1.19c-1.85,3.64-3.49,6.72-5,9.87c-1.52,3.19-0.39,5.05,2.97,6.03c3.38,0.98,6.67,2.29,10,3.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M352.5,413.54c-3.42-9.89-2.26-19.63,0.36-29.52c1.04-3.93,0.8-8.32,0.45-12.44c-0.11-1.27-2.33-2.91-3.86-3.3
				c-0.86-0.22-2.85,1.55-3.34,2.82c-2.86,7.36-6.08,14.84-4.47,22.93c0.99,4.97,3.4,9.63,4.79,14.54c0.77,2.71,0.82,5.64,1.05,8.48
				c0.15,1.82-0.63,4.02,0.14,5.43c3.23,5.88,0.55,11.14-0.85,16.87c5.53,4.07,11.36,8.39,17.22,12.68
				c4.52,3.31,9.14,4.07,14.54,1.63c3.35-1.52,7.3-2.54,10.92-2.36c2.4,0.12,4.78,2.59,6.98,4.27c2.62,2.01,4.77,4.76,7.6,6.3
				c2.07,1.13,5.04,1.49,7.38,1.03c8.74-1.71,13.94,0.2,19.01,7.61c2.02,2.95,4.24,5.31,8.5,4.67c1.34-0.2,3.63,1.13,4.36,2.4
				c3.26,5.68,5.98,11.66,9.15,17.4c1.67,3.01-0.59,4.87-1.61,7c-2.13,4.43-4.68,8.65-6.86,13.06c-0.46,0.94-0.52,2.75,0.06,3.34
				c0.58,0.58,2.2,0.16,3.37,0.12c4-0.15,7.99-0.26,11.98-0.53c1.18-0.08,2.46-0.41,3.47-1c2.46-1.46,4.79-3.14,6.9-4.55
				c3.03,0.87,6.07,1.73,9.1,2.6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M288.08,384.06c0.5,1.33,1.48,2.69,1.42,3.99c-0.32,6.34-0.96,12.66-1.36,19c-0.05,0.71,0.68,1.47,1.27,2.65
				c-0.98,0.86-2.39,2.12-3.82,3.34c-3.5,2.98-6.58,3.93-9.59-0.93c-1.51-2.44-4.07-4.23-6.02-6.19c1.84-5.1,3.6-9.47,1.42-14.97
				c-1.4-3.54-2.51-5.41-6.28-5.4c-5.88,0.02-9.93-2.57-11.28-8.59c-6.53,3.79-8.35,9.96-9.17,16.6
				c-1.15,9.28-8.04,14.22-14.55,19.42c-0.43,0.34-2.1-0.01-2.35-0.48c-0.62-1.18-1.3-2.76-0.98-3.91c0.59-2.11,1.55-4.31,2.94-5.98
				c5.03-6.06,3.64-13.7,5.43-20.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1087.09,352.57c3.16,11.99,7.57,23.83,9.12,36.03c1.41,11.11-0.3,22.61-0.63,33.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M939.78,1228.14c-9.82,1.17-19.64,2.33-29.46,3.5c-2.67,0.32-5.37,0.45-7.98,1.04c-7.25,1.63-13.57-2.78-20.49-2.99
				c-6.98-0.21-14.05-0.66-20.96,0.1c-10.21,1.11-20.33,3.16-30.47,4.89c-1.54,0.26-3,0.97-4.5,1.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M858.88,827.34c1.66,13.49,3.27,26.99,5.02,40.48c1.04,8.01,2.13,16.03,3.53,23.98c1.24,7.04,2.94,14,4.44,21"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M684.59,935.28c1.51-12.79-6.52-19.74-16.04-25.39c-4.64-2.76-9.88-4.56-14.94-6.54c-2.19-0.86-4.65-1.52-6.97-1.47
				c-6.32,0.14-12.64,0.71-19.35,1.13c0.27,0.88,0.58,1.85,1.18,3.79c-6.24,0.51-12.27,0.99-18.29,1.5c-1.17,0.1-2.66-0.08-3.44,0.56
				c-4.52,3.65-9.17,7.34-10.51,13.43c-1.68,7.65-6.43,12.76-13.01,16.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M532.28,560.47c-0.33,2-0.92,3.99-0.95,6c-0.07,4.88-1.84,8.65-5.47,12.07c-7.88,7.42-15.34,15.29-23.08,22.88
				c-1.6,1.57-3.45,3.15-5.49,3.95c-7.75,3.03-15.61,5.77-23.46,8.53c-0.54,0.19-1.32-0.29-1.98-0.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M456.87,1273.61c-2.66,0.33-5.33,0.97-7.99,0.94c-7.17-0.08-14.1,0.53-21.04,2.87c-4.84,1.63-10.72,0.44-15.32,2.42
				c-8.01,3.46-16.52,2.19-24.57,4.26"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M45.38,1018.24c-1.17,3.5-1.64,7.49-3.69,10.36c-2.15,3.01-0.45,4.66,0.75,6.6c1.23,2,4.33,3.85,4.15,5.5
				c-0.38,3.44-1.44,7.9-3.88,9.8c-7.34,5.7-8.38,13.76-9.7,21.73c-0.9,5.4-1.08,10.98-0.99,16.47c0.05,3.34,1.22,6.66,1.88,10"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M603.19,575.96c1.32,3.89,2.64,7.78,3.92,11.55c4.53-0.43,6.14-1.83,4.2-6.11c-1.06-2.34-0.82-5.27-1.12-7.94
				c-0.36-3.16,0.22-6.8-1.15-9.42c-7-13.33-14.48-26.41-21.87-39.53c-0.49-0.86-1.62-1.36-2.45-2.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M177.22,1357.57c-9.32,0.67-18.79,2.64-27.92,1.59c-7.44-0.85-13.04,1.46-19.03,4.4c-0.47,0.23-0.99,0.48-1.49,0.49
				c-8.99,0.19-17.99,0.15-26.96,0.59c-5.51,0.27-11,1.16-16.48,1.95c-8.67,1.25-17.35,2.44-25.96,4.05c-4.74,0.89-9.34,2.59-14,3.92
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1138.53,434.53c-2.74,3.27-5.47,6.55-7.87,9.41c1.62,1.89,2.76,3.82,4.43,5c2.54,1.8,2.31,6.62,6.95,6.32
				c0.38-0.02,1.57,3.51,1.32,5.23c-0.26,1.77-1.95,3.3-2.81,5.03c-1.47,2.97-2.17,5.93-2.4,9.46c-0.28,4.43-3.36,8.66-5.1,13.02
				c-2.24,5.63-5.12,11.16-6.3,17.03c-1.32,6.51,4.13,10.22,8.9,12.72c3.67,1.92,3.94,3.73,3.51,7.24c-0.44,3.59,1.02,7.51,0.2,10.94
				c-1.75,7.29,0.16,15.2-3.85,22.02c-1.52,2.58-3.31,5.01-4.97,7.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1070.11,338.58c3.39,9.07,1.83,18.68,2.8,28.01c1.42,13.58,1.52,27.3,2.21,40.96c0.02,0.34,0.31,0.67,0.48,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M820.92,848.33c3.83,13.99,7.37,28.07,11.59,41.95c2.66,8.73,5.67,17.34,13.17,23.77c2.21,1.89,2.23,6.71,2.63,10.26
				c0.73,6.4,2.72,12.15,7.07,16.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M509.81,662.92c-3.33,3.17-6.68,6.31-9.98,9.5c-2.2,2.13-4.33,4.32-6.39,6.38c-5.17-1.76-9.95-3.2-15.16,0.51
				c-6.01,4.28-12.77,7.49-18.83,11.7c-3.61,2.51-6.64,4.78-11.54,5.17c-7.24,0.59-14.34,3.05-21.5,4.72"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M90.83,516.49c-0.65,1.51-1.29,3.01-2.14,4.99c10.22,5.91,10.39,19.43,19.49,26.65c0.79,0.63,0.35,3.82-0.47,5.28
				c-3.43,6.13-7.23,12.05-10.89,18.04c-0.31,0.51-0.57,1.33-1.01,1.45c-4.64,1.25-9.31,2.38-13.97,3.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M134.77,1423.54c1,0.5,2,1,3,1.5c0.17-1.17,0.69-2.42,0.43-3.48c-0.82-3.38-1.65-6.8-3.02-9.97c-1.03-2.37-1.6-3.99-0.18-6.68
				c0.86-1.63-0.93-4.67-1.64-7.5c1.75-1.93,3.71-3.38,7.1-0.05c1.52,1.5,5.22,1.44,7.78,1.08c6.02-0.85,8.82,1.15,9.59,7.1
				c0.31,2.4,1.54,4.69,2.43,7c0.4,1.04,1.54,2.1,1.38,2.99c-0.6,3.46,1.65,4.66,4.11,5.45c2.38,0.77,3.33,1.74,4.31,4.44
				c1.28,3.5,4.72,6.43,7.72,9.04c4.17,3.64,8.39,7.03,8.77,11.98c-2.64,3.06-4.78,5.54-6.49,7.53c0.7,2.31,1.29,4.25,1.88,6.18
				c-0.5,0.19-1.01,0.39-1.51,0.58c-3.3-1.68-6.22-3.64-5.72-8.21c0.28-2.62-1.26-3.13-3.49-3.02c-2.49,0.12-4.99,0.03-8.39,0.03
				c1.67,2.21,2.54,3.35,2.8,3.69c-0.56,2.53-1.14,4.4-1.37,6.31c-0.53,4.41-2.25,5.67-6.52,4.49c-2.94-0.81-5.69-1.46-6.01,3.01
				c-0.25,3.53-3.09,2.71-4.97,2.42c-6.18-0.98-9.01,0.97-10.6,7.05c-0.6,2.3-2.39,4.3-3.9,6.9c0.08,0.05,1.06,0.57,2.74,1.46
				c-2.36,1.33-3.72,2.1-5.43,3.06c0.65,1.37,1.42,2.99,2.1,4.42c-1.66,2.1-3.27,4.14-4.89,6.18"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M140.26,952.27c6.99,10.33,13.99,20.65,20.97,30.99c11.49,16.99,23.3,33.78,34.27,51.09c3.53,5.57,4.34,12.79,7.33,18.79
				c2.59,5.2,6.03,10.17,9.94,14.47c7.12,7.83,14.86,15.1,22.4,22.55c0.59,0.58,1.63,0.7,2.46,1.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M599.7,679.91c-8.44,5.63-16.08,11.69-27.44,12.44c-12.73,0.84-24.63,6.52-35,14.53c-0.58,0.45-1.32,0.68-1.99,1.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M476.85,533.48c-7.82,8.5-15.09,17.6-23.59,25.35c-15.25,13.89-27.81,30.67-45.29,42.16c-1.23,0.81-2.03,2.29-3.03,3.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1102.08,1301.1c-0.5-1.33-0.72-2.87-1.54-3.97c-7.72-10.3-7.64-22.06-6.42-34.01c0.29-2.85,0.76-5.72,1.51-8.49
				c1.48-5.44,5.26-9.46,9.34-8.18c3.34,1.05,7.22,4.5,8.34,7.75c4.66,13.58,7.25,27.7,6.57,42.13c-0.17,3.64-2.67,7.12-3.78,10.77
				c-1.6,5.26,0.1,9.69,4.45,12.48c3.98,2.55,8.45,2.07,12.43-2.05c5.39-5.58,10.69-11.32,10.56-19.94
				c-0.1-6.94-1.92-9.78-8.02-11.47c-5.3-1.46-7.18,3.06-10,5.95c-1.56,1.6-3.31,3.02-4.98,4.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M343.51,1432.53c1.33-6.33,2.66-12.66,4.09-19.42c-3.38-2.53-7.37-5.04-6.19-10.58c0.14-0.66-1.27-1.61-1.88-2.48
				c-1.03-1.47-2.35-2.87-2.94-4.52c-1.3-3.61-6.56-6.13-10.05-4.46c-0.87,0.42-2.31,1.52-2.41,1.41c-2.76-3.3-5.64-1.19-8.61-0.52
				c-1.2,0.27-2.64-0.55-3.08-0.66c-4.46,2.98-8.25,5.96-12.49,8.01c-1.03,0.5-3.75-1.86-5.32-3.31c-1.99-1.83-3.59-4.07-4.97-5.68
				c-2.58,0.57-4.15,0.72-5.56,1.28c-3.1,1.21-4.11,0.15-4.66-3.01c-0.31-1.81-2.13-4.68-3.35-4.73c-2.07-0.08-4.24,1.49-3.6,4.72
				c0.23,1.19-0.51,3.25-1.46,3.84c-1.65,1.03-2.21,1.07-1.82,3.69c0.39,2.59-3.47,3.32-6.06,1.89c-3.79-2.1-6.56-3.04-6.58,3.05
				c-0.01,3.53-2.97,2.33-4.94,2.47c-1.66,0.12-3.4,0.34-4.98-0.03c-6.24-1.49-11.06,2.47-16.49,4.05c-1.16,0.34-2.52,1.23-3.47,0.91
				c-6.59-2.19-12.23-0.31-17.63,4.4c0.78,2.33,1.53,4.36-3.25,6.03c-6.58,2.3-11.58,7.16-13.44,15.07
				c-0.91,3.85-0.92,9.17-5.66,11.57"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M282.09,1228.64c0.19,3.23-1.09,6.97,3.42,8.7c0.41,0.16-0.42,3.14-0.37,4.79c0.04,1.18,0.3,3.1,0.99,3.38
				c5.98,2.44,6.02,2.35,8.44,6.62c-1,1-1.99,2-3.03,3.04c0.17,0.31,0.27,0.76,0.54,0.94c2.25,1.43,3.94,2.57,2.64,6.08
				c-1.63,4.4,1.56,7.72,7.34,8.4c2.13,0.25,4.33,0.13,6.49,0.02c2.99-0.16,5.2,0.56,5.49,4.02c0.26,3.09,2.08,4.25,4.99,3.99
				c3.5-0.31,6.99-0.78,10.49-0.93c1.32-0.06,3.46,0.2,3.87,1c3.09,6.13,9.18,6.36,14.61,7.93c0.66,0.19,1.76,0.14,1.93,0.53
				c3.13,6.97,10.64,6.43,16.04,9.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M165.23,473.01c-1-0.33-2.06-0.54-2.99-1.02c-8.88-4.54-9.74-4.1-16.55,3.46c-9.76,10.85-20.53,20.62-32.85,28.62
				c-3.83,2.48-7.04,5.92-10.54,8.92"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M530.78,564.47c-12.15,7.83-24.98,14.81-36.24,23.76c-8.75,6.96-15.55,16.37-23.17,24.73c-0.96,1.05-1.68,2.32-2.51,3.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1056.13,980.26c0.5,1.33,1.61,2.77,1.4,3.98c-1.3,7.36-2.99,14.66-4.34,22.02c-0.38,2.1,0,4.32-0.07,6.49
				c-0.12,3.67,0.54,7.64-0.66,10.93c-2.9,7.99-5.29,15.95-4.82,24.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M848.89,923.79c2.16,4.5,5.47,8.81,6.26,13.53c1.66,9.99,7.63,17.46,13.37,24.83c11.48,14.76,23.16,29.52,39.8,39.09"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M59.86,435.03c-4.7,11.92-13.66,19.8-24.46,26c-6.54,3.75-13.08,7.51-19.46,11.53c-4.64,2.93-9.02,6.28-13.51,9.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1030.66,1177.16c-0.33,0.83-0.94,1.66-0.96,2.5c-0.23,10.16-0.57,20.32-0.47,30.48c0.06,6.72-0.74,13.12-2.86,19.56
				c-1.02,3.11,0.13,6.93,0.29,10.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1148.02,1012.24c-6.49,4-12.67,8.67-19.55,11.83c-10.04,4.62-20.57,8.14-30.88,12.17c-2.36,0.92-4.58,2.35-7.01,2.91
				c-4.98,1.15-10.17,2.64-14.46-1.92"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M506.31,718.39c-6.99,4-13.78,8.41-21.02,11.91c-14.59,7.04-23.79,20.88-37.38,29.13c-0.93,0.56-1.37,1.94-2.03,2.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M115.79,967.27c-1.83,6.16-3.33,12.45-5.59,18.45c-1.94,5.14-0.26,9.41,2.72,12.93c3.95,4.68,5.5,9.69,4.88,15.59
				c-0.05,0.49-0.19,1.1,0.02,1.48c5.04,9.23,7.03,20.18,15.47,27.49c0.9,0.78,1.92,1.78,2.99,1.95
				c10.98,1.67,16.41,10.69,23.47,17.55c1.53,1.48,3.17,2.84,5.94,5.3c-1.12-7.67-3.31-14.12-2.61-20.24
				c0.78-6.8,4.21-13.3,6.95-21.28c-3.84-7.15-8.56-15.94-13.28-24.74"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M800.95,681.91c-3.83,4.66-8.09,9.04-11.39,14.06c-5.69,8.65-14.2,12.36-24.01,15.14c-0.88-5.23,2.55-8.72,4.59-12.62
				c-8.02-4.65-8.26-4.17-14.73,1.3c-6.72,5.68-13.79,11.25-21.49,15.4c-8.91,4.8-14.95,12.01-20.9,19.67
				c-2.81,3.62-6.06,6.89-9.03,10.23c-10.06-4.97-16.27-4.36-31.15,2.92c-0.72-7.46,3.42-12.66,7.81-17.58
				c3.92-4.38,8.61-8.1,12.32-12.63c2.6-3.18,4.51-7.08,6.02-10.94c2.35-6.03,4.09-12.29,6.09-18.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M580.22,603.95c-8.49,8-16.7,16.32-25.57,23.87c-5.62,4.79-12.64,7.95-18.25,12.76c-4.79,4.1-8.45,9.51-12.61,14.34"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M866.87,1498.5c-2.1-6.24-6.09-11.33-9.44-16.83c-4.66-7.66-11.04-8.49-17.95-10.88c-7.14-2.47-13.15,0.13-19.54,1.78
				c-0.79,0.2-1.35,1.27-2.01,1.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M323.04,1466.02c0.67-0.5,1.53-0.87,1.96-1.53c0.81-1.24,1.19-3.57,2.08-3.75c1.28-0.26,2.86,1.02,4.78,1.83
				c-1.58,1.69-2.55,2.72-3.27,3.49c2.02,2.03,3.85,3.86,5.81,5.83c-6.79,3.79-12.65,7.06-18.89,10.55
				c-0.23-0.46-0.63-1.18-0.95-1.94c-0.29-0.68-0.49-1.39-0.8-2.28c-4.57,1.96-7.42,1.51-6.77-4.22c0.05-0.44-0.87-1.07-1.44-1.48
				c-2.02-1.47-4.08-2.87-6.7-4.7c-3.7,2.57-7.79,5.41-11.89,8.25c-2.05-2.89-3.27-5.87-5.49-7.38c-2.26-1.54-3.31-2.74-1.28-4.57
				c1.16-1.04,3.32-0.96,5.21-0.75c4.08-1.53,4.43-8.76,10.11-7.56c2.88,0.61,5.1,4.32,7.79,6.79c0.75-1.68,1.44-3.42,2.28-5.07
				c0.6-1.18,1.41-2.25,1.86-2.96c3.86,4.36,6.99,7.89,10.48,11.82c1.34-0.45,2.09-5.35,4.62-1.37"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M701.07,435.03c1.64-2.58,3.28-5.15,5-7.87c-8.17-2.01-15.53,1-18.4,7.91c-2.99,7.19-7.3,12.37-14.44,16.19
				c-8.5,4.55-12.99,13.23-17.1,21.75"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M362.99,1294.6c-1.82-5.33-0.83-11.37-7.3-15.31c-5.42-3.3-8.97-9.57-13.76-14.09c-2.5-2.36-5.91-3.75-8.9-5.59
				c-0.51-0.31-1.04-0.62-1.49-1c-4.17-3.49-9.79-6.23-12.11-10.69c-2.68-5.15-6.31-4.68-9.82-4.02c-2.05,0.39-3.57,3.6-5.94,6.21
				c1.06,0,0.65,0.13,0.4-0.02c-6.45-3.99-6.68-5-3.57-12c0.73-1.65,0.63-3.66,1.08-5.47c0.74-2.95,1.63-5.86,2.65-9.45
				c2.38,1.7,3.76,2.69,5.83,4.17c0.93-3.25,1.71-5.98,2.49-8.72"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M841.9,779.36c2.33,18.16,5.66,36.26,6.65,54.49c0.7,12.89,4,25.02,6.33,37.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1048.14,455.52c-13.07,5.93-25.75,2.42-38.46-1.47c-3.93-1.2-8.15-1.6-11.94-3.12c-8.9-3.57-16.86-0.02-25,2.59"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1101.58,1248.13c-1.33,8.16-3.51,16.31-3.69,24.49c-0.12,5.3,2.16,10.88,4.34,15.92c2.14,4.96,5.29,9.56,8.49,13.95
				c0.87,1.2,3.52,1.11,5.35,1.61"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,705.9c-4.49,3.66-8.85,7.53-13.53,10.93c-3.24,2.35-6.93,4.09-10.44,6.05c-1.62,0.9-3.56,1.39-4.95,2.56
				c-8.4,7.07-16.68,14.29-25.02,21.43c-0.73,0.62-1.65,1.02-2.48,1.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M301.07,495.5c-0.83-0.5-1.61-1.27-2.51-1.45c-3.71-0.75-7.46-1.33-11.91-2.09c1.34-2.75,2.12-4.36,3.52-7.25
				c-2.86,0.52-4.97,0.93-7.08,1.29c-12.74,2.18-22.23,9.41-30.55,18.91c-4.48,5.11-9.93,9.37-14.86,14.1
				c-1.19,1.14-2.03,2.64-3.02,3.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M62.36,431.53c-9.82,6.66-19.64,13.33-29.47,19.98c-0.8,0.54-1.74,0.91-2.48,1.52c-8.35,6.84-16.39,14.08-26.4,18.64
				c-1.38,0.63-2.35,3.47-2.45,5.34c-0.22,4.31,0.21,8.66,0.38,12.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M158.24,1007.75c1.91,11.58-8.13,17.42-12.48,25.99c-4.66-0.17-9.34-0.15-13.96-0.68c-0.81-0.09-1.29-2.52-2.03-3.82
				c-2.13-3.76-4.31-7.48-6.6-11.44c1.59-0.79,2.65-1.33,3.36-1.68c-3.98-7.58-8.01-14.9-11.67-22.4c-2.96-6.05,1.9-10.98,3.86-15.8
				c1.71-4.19,1.12-6.57-0.9-9.68c-0.81-1.24-1.35-2.65-2.02-3.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1107.57,1312.59c-4.18-14.61-17.48-18.27-29.01-23.89c-3.16-1.54-7.5,1.85-7.9,5.91c-1.15,11.88,4.1,27.07,16.46,32.42
				c4.33,1.88,8.9,3.32,13.47,4.5c6.26,1.61,9.18-0.95,8.96-7.43c-0.09-2.67-0.07-5.38-0.54-7.99c-0.86-4.78,0.8-7.16,6.05-8.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M204.68,321.09c-1.83,1.33-4.05,4.1-5.42,3.73c-4.04-1.08-4.18,1.78-4.48,3.78c-1.56,10.63-5.26,20.99-4.12,31.97
				c0.08,0.81-0.28,1.67-0.47,2.49c-1.67,7.32-0.79,8.93,7.01,11.3c-2.19,5.12-3.02,11.76-6.77,14.86
				c-8.58,7.1-14.37,16.09-20.71,24.81"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M463.37,799.35c1.5,0.17,3.14,0.01,4.45,0.59c1.34,0.59,3.41,1.95,3.37,2.9c-0.28,7.09,6.07,12,5.47,19.52
				c-0.51,6.38,1.69,12.97,2.69,19.47c1,6.5,2,12.99,3,19.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M194.7,1011.74c5.83,8.16,12.64,15.81,17.19,24.64c4.33,8.39,9.44,14.83,18.22,18.44c2.92,1.2,5.37,3.57,8.03,5.4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,850.32c-3.33,6.5-6.4,13.14-10.04,19.46c-7.58,13.16-15.77,25.89-27.88,35.55c-3.03,2.41-5.38,5.78-8.6,7.84
				c-4.18,2.66-8.91,4.45-13.41,6.62"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M131.28,1432.53c-2.04-0.48-4.09-0.95-6.46-1.51c-4.48,4.74-4.28,7.08,1.46,11.5c1.5,1.16,3.08,2.24,4.48,3.52
				c1.83,1.67,3.52,3.48,4.89,5.89c-7.17-1.07-12.68-5.4-17.2-8.8c-3.25,1.55-5.79,3.76-8.11,3.55c-1.82-0.16-3.4-3.3-5.01-5.17
				c-1.39-1.62-2.68-3.32-4.01-4.99c-1.17,1.88-2.35,3.76-4.24,6.79c-2.94-2.64-6.22-5.57-9.75-8.74c-1.31,2.84-3.05,3.56-6.61,1.69
				c-2.49-1.3-6.21-0.25-8.94-0.25c-1.14,2.55-2.03,4.53-2.91,6.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M339.02,1231.63c-1.5-0.67-3.35-0.98-4.44-2.06c-6.64-6.57-14.8-10.23-23.52-12.95c-2.56-0.8-5.06-1.82-8.04-2.91
				c-7.05,4.61-14.5,9.5-21.53,14.1c-3.28-1.45-6.52-2.51-9.32-4.27c-2.14-1.34-3.98-3.38-5.49-5.45c-1.93-2.63-7.27-4.22-10.58-3.61
				c-2,0.37-4.78-0.23-6.35-1.48c-2.26-1.81-3.48-3.93-6.25-0.47c-1.11,1.38-3.81,1.65-5.86,2.07c-3.5,0.73-3.69-1.34-2.99-3.95
				c-1.5,0-3.25-0.5-4.44,0.11c-2.1,1.08-3.71,2.26-4.55-1.11c-1.22-4.9-6.59-7.5-11.47-5.47c-1.78,0.74-3.44,1.78-5.26,2.74
				c-3.99-3.59-3.98-3.57-12.24-2.32c-0.44,0.07-0.95-0.73-1.5-0.93c-4.34-1.57-8.54-2.52-10.12,1.76
				c-6.26,0.45-12.5-0.75-16.55,1.69c-3.53,2.13-6.02-3.03-8.28,0.52c-3.18-3.04-3.96,0.5-5.5,2c-1.34,1.29-2.7,2.56-3.8,3.59
				c-2.37-2.26-4.33-4.13-5.65-5.39c-6.73,5.06-13.01,9.5-18.94,14.38c-2.4,1.97-4.99,4.63,0.31,7.17c1.18,0.56,1.55,4.64,0.92,6.67
				c-0.7,2.25-3.33,4.69-0.67,6.36c2.31,1.45,5.78,1.64,8.36-0.8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.94,469.02c-3.59-13.85,3.63-23,13.49-30.98c1.48-1.19,3.12-2.21,4.47-3.53c2.69-2.63,5.9-4.2,5.96-9.46
				c0.05-4.49,3.39-9.24,9.53-10.13c2.93-0.42,5.67-2.23,9.48-3.83c0.45,5.21-2.27,13.17,8.69,10.55
				c-3.44,7.19-6.65,12.76-12.13,16.92c-12.39,9.4-24.69,18.92-36.94,28.5c-1.78,1.39-4.25,2.89-2.53,5.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M834.91,493c5.73-4.75,12.24-8.57,16.1-15.42c1.58-2.8,4.5-4.81,8.04-1.47c-3.38,7.78-6.49,15.72-10.27,23.33
				c-1.95,3.92-3.03,7.49-3.22,12.04c-0.22,5.37-1.18,11.48-6.66,15.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M880.35,940.28c7.95,17.82,19.15,33.24,33.98,45.95c2.52,2.16,5.31,4.02,7.97,6.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,742.38c-1.32-0.53-2.64-1.06-3.42-1.37c-3.25,5.57-6.41,10.97-9.57,16.36c-0.12,0.2-0.3,0.45-0.5,0.49
				c-7.62,1.67-11.55,8.57-17.62,12.27c-3.74,2.29-3.3,4.46-2.29,7.21c1.31,3.56,3.1,6.95,4.88,10.84
				c-5.06,0.94-9.81,1.58-14.43,2.73c-5.92,1.47-9.4-3.2-14-5.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M376.47,1206.15c-2.16,0.17-4.34,0.56-6.49,0.46c-7.44-0.36-10.96,2.79-10.99,11.38c-2.89-1.61-5.24-2.93-7.95-4.44
				c-3.58,4.41-7.79,8.71-10.81,13.72c-1.39,2.31-0.57,5.76,2.72,7.95c7.34,4.89,13.83,11.39,21.67,15.11
				c9.3,4.41,11.85,13.62,17.82,20.3c0.48,0.54,1.15,1.53,0.95,1.95c-3.26,6.9,2,13.55-0.2,21.23c-2.08,7.29-4.94,4.98-9.19,5.53
				c-4.8,0.62-9.29,3.3-14.04,4.68c-3.05,0.89-6.45,1.92-9.44,1.4c-6.6-1.16-12.85-1.58-19.07,1.54c-0.86,0.43-2.94-0.52-3.87-1.43
				c-4.46-4.36-8.84-8.83-12.99-13.48c-1.98-2.22-3.07-5.26-5.12-7.37c-2.87-2.95-6.28-5.37-9.33-7.92c1.33-1.75,2.62-3.45,3.91-5.15
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M893.33,789.36c6.54,17.34,14.61,33.97,27.6,47.34c10.99,11.31,23.17,21.46,34.82,32.12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1040.65,1052.72c-7.34,5.75-16.41,8.17-25.01,9.1c-12.86,1.38-26.18,2.63-38.9-2.12c-4.2-1.57-8.32-3.34-12.5-4.97
				c-1.21-0.47-2.49-0.73-3.91-0.52c4.5,8.73,12.62,11.44,20.92,12.28c10.34,1.05,20.82,0.33,30.94-2.78"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M421.92,417.04c3.82-7.19,5.68-14.86,5.43-22.99c-0.03-1.01-0.69-2.3-1.48-2.95c-2.69-2.18-5.56-4.14-8.66-6.39
				c-1.58,1.9-3.09,3.71-4.86,5.84c-0.75-1.58-1.42-2.75-1.9-4.01c-1.95-5.14-6.82-8.45-11.96-7.31c-1.43,0.32-3.01,2.66-3.42,4.34
				c-1.76,7.1-3.09,14.3-4.61,21.46c-0.61,2.84-1.32,5.67-1.99,8.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M498.82,1247.63c-0.83,0.33-1.65,0.91-2.5,0.96c-16.81,1.05-33.62,2.04-50.44,3.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1048.14,462.02c-8.55-2.06-14.42,2.76-19.93,8.04c-4.44,4.26-9.42,7.27-14.8,10.35c-10.77,6.17-20.01,14.84-25.44,25.22
				c-7.89,1.45-15.74-2.22-21.82,4.79c-1.2,1.38-3.5,2.03-5.42,2.52c-4.37,1.11-7.83-1.9-8.01-6.44c-0.15-3.67-0.63-7.33-0.96-11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M609.18,706.9c-7.66,5-15.73,9.48-22.84,15.16c-5.65,4.51-10,10.61-15.15,15.79c-4.15,4.18-8.69,7.96-12.93,12.05
				c-3.74,3.6-7.3,7.38-11.13,11.27c6.01,4.02,11.05,2.7,16.29,0.83c-6.46,14.35-18.95,23.26-28.15,34.86"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M759.5,1456.52c-4.42-1.79-8.97-2.89-13.19-5.69c-3.31-2.2-8.44-1.75-12.79-2.22c-0.93-0.1-2.47,0.65-2.91,1.45
				c-5.18,9.39-7.98,10.87-18.55,9.45c-2.5-0.33-4.99-0.79-7.49-0.95c-1.48-0.09-2.99,0.28-4.49,0.47c-0.02,0-0.06,0.35,0.01,0.47
				c0.12,0.2,0.32,0.34,0.49,0.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,862.32c-3.33,6-6.96,11.85-9.92,18.02c-5.36,11.19-13.94,20.03-20.99,29.99c-0.74,1.04-0.72,2.63-1.05,3.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M117.79,1234.63c-1.97-1.35-2.91-5.04-6.59-2.65c-0.44,0.29-2.63-1.27-3.32-2.39c-2.2-3.54-3.69-7.56-6.14-10.89
				c-5.61-7.63-13.38-12.07-22.89-11.84c-3.94,0.09-9.5-1.3-11.21,5.23c-0.32,1.23-3.51,2.55-5.27,2.42
				c-8.52-0.64-14.75,4.9-21.91,7.82c-1.35,0.55-2,3.41-2.52,5.33c-1.3,4.83-2.35,9.73-3.76,15.72c0.86,0.67,2.58,1.9,4.2,3.26
				c6.33,5.32,13.39,9.81,17.64,17.39c1.34,2.39,4.51,3.75,6.84,5.58"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M406.93,617.44c-4.23-0.23-8.25,0.23-10.97,4.01c-4.06,5.62-9.17,9.55-15.94,11.67c-2.19,0.68-3.82,3.39-5.53,5.34
				c-4.06,4.61-8.07,9.26-11.95,14.02c-0.7,0.86-0.72,2.29-1.05,3.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M703.07,691.9c-2.16,2.33-4.97,4.33-6.38,7.06c-5.27,10.2-13.23,17.68-22.92,23.91c-0.38-10.8,6.79-19.16,9.76-29.44
				c-6.87,1.89-10.85,6.65-14.35,11.53c-9.73,13.56-23.65,20.87-38.53,26.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M940.28,986.26c-0.33,5.83-0.68,11.66-0.99,17.49c-0.06,1.16,0.47,2.68-0.08,3.44c-6.43,8.79-4.95,17.61-0.45,26.55
				c0.53,1.06,0.69,2.32,1.03,3.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.94,1132.18c0,16.82,0,33.65,0,50.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M152.25,357.57c-2.16,9.99-4.11,20.04-6.6,29.96c-1.04,4.15-3.23,8.02-4.89,12.02c-1.51,3.62-3.27,7.3,1.96,9.26
				c-1.81,4.59-3.55,8.99-5.25,13.31c-6.46,1.57-13.57,3.3-20.68,5.03c0-0.45-0.15-0.94,0.02-1.1c6.42-5.99,9.7-13.38,11.75-21.94
				c1.32-5.53,5.32-10.43,8.23-15.55c1.62-2.85,3.46-5.58,5.52-8.89c-1.67-0.26-2.85-0.54-4.05-0.6c-1.99-0.09-5.5,0.5-5.72-0.12
				c-0.73-2.08-0.78-4.71-0.17-6.87c1.33-4.76,3.23-9.36,4.89-14.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M756,556.97c2.76,6.91,7.23,13.3,5.07,21.51c-1.61,6.12,2.78,9.95,7,12.84c5.45,3.72,11.56,6.47,17.39,9.62
				c0.13,0.07,0.35,0.06,0.49,0c0.2-0.1,0.37-0.29,1.17-0.95c-2.05-2.51-3.92-5.2-6.19-7.5c-6.11-6.18-10.05-13.59-13.64-21.44
				c-1.91-4.16-5.8-7.42-8.8-11.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M576.72,1003.25c-1.83,10.66-3.58,21.34-5.52,31.98c-1.2,6.56-4.75,11.98-8.96,17"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M49.38,1173.16c-0.5-2.67-1.1-5.32-1.47-8c-0.37-2.64-0.51-5.31-0.81-8.58c-5.23,2.78-12.52,2.07-13.63,11.03
				c-0.23,1.86-5.8,3.32-9.08,4.49c-4.91,1.74-10.21,2.53-14.9,4.7c-5.79,2.68-8.86,7.19-8.22,14.37c0.83,9.27,0.5,18.64,0.66,27.97"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M872.86,880.31c0.33,3.5,0.11,7.16,1.1,10.47c3.92,13.07,7.83,26.17,12.62,38.92c1.52,4.05,5.76,7.08,8.76,10.58"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1085.6,1355.32c4.83-1.42,9.6-3.09,14.5-4.19c7.58-1.69,7.52-1.59,12.57-8.48c0.76-1.04,2.87-1.22,4.41-1.5
				c0.58-0.11,1.69,1.05,1.93,0.86c5.78-4.67,6.32,0.85,8.33,4.05c3.41-1.59,7.13-3.15,10.68-5.03c2.46-1.3,5.89-1.6,5.62-5.96
				c-0.25-3.92-1.6-6.49-5.04-8.58c-5.65-3.44-11.32-1.49-17.03-0.89c-1.85,0.19-3.66,0.64-5.5,0.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M309.56,913.79c3.66,3.5,7.05,7.37,11.09,10.35c2.23,1.65,5.51,2.31,8.39,2.53c4.75,0.36,8.58,1.52,11.03,6.08
				c2.93,5.45,6.27,10.69,9.43,16.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M524.79,1114.19c-1.66,15.79-3.78,31.5-1.7,47.5c0.87,6.67-0.43,13.64-0.83,20.46c-0.11,1.9,3.56,4.9-1.92,5.01
				c-3.55-3.86-3.55-3.86-7.54-2.51c-2-1.67-3.89-3.49-6.04-4.93c-1.07-0.72-2.84-1.35-3.91-0.95c-3.56,1.3-6.94,3.08-10.28,4.63
				c-4.98-4.76-8.76-3.38-15.51,5.62c-1.01-1.19-2.01-2.37-3.2-3.79c-1.98,1.77-3.65,3.46-5.54,4.88c-1.91,1.44-4.02,2.61-6.44,4.15
				c1.75,2.07,3.36,3.97,4.96,5.88"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M748.51,1453.02c-1.83,0.17-4,1.04-5.43,0.36c-3.96-1.87-7.03-0.16-9.49,2.2c-9.61,9.24-20.28,10.24-32.03,4.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M78.34,1255.12c2.53,3.98,5.06,7.96,7.94,12.49c-7.29-0.32-4.4,6.67-7.13,9.72c-0.21-0.43-0.42-0.87-0.63-1.3
				c-3.23,0.86-6.78,1.13-9.61,2.7c-7.19,3.98-14.04,8.56-21.04,12.87c-6.22,3.84-10.46,9.57-11.24,16.52
				c-0.85,7.61-1.55,15.88,5.25,21.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M875.86,887.31c3.83,12.33,7.63,24.66,11.5,36.98c1.06,3.37,2.31,6.67,3.48,10"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M633.16,1148.18c-1.33-2.67-2.05-5.98-4.09-7.89c-12.57-11.74-22.14-25.6-30.44-40.54c-3.56-6.4-8.03-12.05-15.84-14.25
				c-2.22-0.62-4.12-3.55-5.47-5.83c-3.56-6.03-6.71-12.29-10.07-18.44c-2.63-4.82-9.43-7.7-12.96-5.48c-1.09,0.69-3,1.91-2.86,2.44
				c1.92,7.22-5.06,7.67-8.32,10.31c-3.33,2.69-8.05,3.87-12.33,5.13c-3.81,1.12-5.78,2.67-5.58,7.1c0.29,6.48-0.22,12.99-0.38,19.49
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M207.18,321.09c0,0.67,0.25,1.48-0.04,1.98c-4.24,7.15-4.25,7.14,1.74,12.27c2.86-2.99,5.7-5.97,9.27-9.7
				c1.84,2.77,3.11,4.67,4.68,7.03c-0.19,0.33-0.85,1.67-1.68,2.91c-3.74,5.61-5.96,11.17-5.91,18.5c0.05,7.98-3.21,15.99-5.09,23.98
				c-0.36,1.53-1.86,3.77-1.34,4.38c3.68,4.29,0.73,7.71-1.2,11.08c-2.74,4.81-6.01,9.11-11.92,10.58
				c-5.33,1.32-9.93,5.04-16.02,4.13c-1.99-0.3-4.3,1.49-6.47,2.32"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M410.43,614.94c-13.17,15.66-30.53,26.44-45.93,39.5c-4.94,4.19-9.67,8.64-14.5,12.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M174.22,332.08c-0.42,4.19,0.22,7.75,4.4,10.16c0.97,0.56,0.84,3.16,1.05,4.84c0.08,0.63-0.65,1.53-0.41,1.96
				c5.47,9.66-2.88,14.7-7.13,20.96c-2.02,2.98-4.98,5.33-7.57,8.02c4.03,3.43,8.08,2.12,11.46,0.94c1.48,1.25,2.85,2.41,4.66,3.94
				c-3.36,2.96-7.32,6.14-10.91,9.71c-3.05,3.03-6.58,5.56-7.78,10.38c-0.66,2.66-3.4,5.23-5.83,6.95
				c-5.6,3.98-11.58,7.43-17.41,11.1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M473.85,446.03c-1,1.33-2.31,2.53-2.94,4.02c-2.67,6.25-7.23,9.27-14.04,9.02c-0.99-0.04-2.67,0.37-2.89,0.99
				c-2.23,6.27-5.48,12.42-3.56,19.44c0.27,0.98,1.05,1.83,1.77,3.03c2.92-4.35,5.56-8.28,8.2-12.22c0.58,0.25,1.16,0.49,1.74,0.74
				c-0.6,6.63-1.19,13.25-1.79,19.88c0.42,0.11,0.85,0.23,1.27,0.34c3.41-3.42,6.89-6.77,10.2-10.3c0.82-0.87,1.16-2.25,1.53-3.46
				c3.19-10.32,6.34-20.65,9.51-30.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1075.11,1289.61c-1.27,9.18,5.68,15.33,8.75,22.88c0.96,2.36,4.87,4.16,7.77,4.95c5.16,1.4,10.33,2.34,14.49,6.1
				c0.63,0.57,1.95,0.39,2.95,0.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M94.82,1025.74c-0.67,5-1.12,10.03-2.05,14.98c-1.46,7.78-3.23,15.51-5.06,24.14c-3.24-2.56-5.38-4.25-7.55-5.96
				c-6.79,6.66-6.71,4.63-2.32,14.32c0.68,1.49,1.36,2.99,1.99,4.5c0.64,1.51,1.22,3.05,2.05,5.13c-7.65,5.37-6.97,14.07-7.54,22.36"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M631.66,815.84c1.22,1.28,2.43,2.57,3.89,4.1c-3.3,3.3-6.47,6.48-9.68,9.68c4.8,2.51,9.23,4.98,13.79,7.17
				c4.69,2.25,7.4,5.07,4.55,10.56c-0.48,0.92-0.52,3.02,0,3.35c5.69,3.6,7.88,9.65,10.92,15.1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M858.88,961.77c2.83,5,5.11,10.43,8.61,14.9c6.4,8.16,12.83,16.55,20.58,23.33c6.04,5.28,14.09,8.25,21.25,12.25"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M580.22,726.39c-3.33,1.5-6.67,2.97-9.99,4.5c-9.33,4.32-18.67,8.61-27.95,13.02c-2.28,1.09-4.35,2.63-6.51,3.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M301.07,1160.67c-1.17-1.67-2.47-3.25-3.47-5.01c-2.35-4.16-5.06-4.98-9.05-3.04c-2.68,1.31-5.83,3.25-8.42,2.82
				c-6.7-1.11-5.92,5.36-8.91,7.87c-0.68,0.57,0.08,2.87,0.18,4.44c-5.57,2.16-6.46,1.38-7.28-6.58c-7.57-1.02-9.21-3.68-6.98-11.49
				c0.91-3.19,1.98-6.33,2.98-9.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M525.79,1244.63c1.5-1.83,3.64-3.44,4.37-5.54c1.97-5.65-0.15-10.91-1.29-16.57c-1.15-5.7-7.04-6.82-7.64-11.87
				c-0.05-0.39-0.95-0.98-1.44-0.96c-7.34,0.36-12.76-4.39-19.06-6.84c-2.34-0.91-5.58,0.52-8.41,0.78
				c-2.33,0.21-4.78,0.14-4.88-2.98c-0.03-0.98,1.03-2.57,1.93-2.88c2.04-0.7,4.38-1.33,6.43-0.99c3.08,0.51,5.96,2.43,9.02,2.74
				c5.18,0.53,5.21,0.2,8.21,3.8c1.74-0.25,3.39-0.97,4.74-0.6c7.42,2.03,14.78,4.29,20.53,9.88c0.59,0.58,1.63,0.7,2.46,1.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M606.69,675.41c-3.14,4.33-8.22,4.4-12.48,4.3c-11.92-0.29-22.59,3.93-33.48,7.65c-1.54,0.53-3.31,0.38-4.98,0.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M121.79,396.55c0,0.17,0.07,0.42-0.01,0.49c-13.53,11.92-22.75,28.58-40.22,36.19c0.12,0.6,0.24,1.2,0.36,1.8
				c5.47-0.5,10.93-1,16.4-1.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1132.04,560.97c0.08-5.54-2.04-11.15,1.39-16.56c1.43-2.25,0.31-4.34-2.89-4.44c-2.56-0.08-3.02-1.59-3.07-3.98
				c-0.21-10.16-3.61-12.27-12.42-8.01c-0.27,0.13-0.66,0.02-0.99,0.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M592.21,1127.19c-2.86,8.79,0.36,15.68,7.56,20.39c9.77,6.38,19.91,12.43,32.39,11.05c2.31-0.26,4.54-1.2,7.57-2.03
				c-6.62-9.7-15.47-14.43-24.08-19.34c-5.81-3.32-12.01-5.94-17.89-9.16c-1.07-0.59-1.39-2.56-2.06-3.89"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M307.06,1430.53c-1.59,2.38-3.17,4.76-4.86,7.29c-1.81-0.93-3.4-1.75-5.03-2.59c-1.79,1.93-3.4,3.65-5,5.38
				c-3.29-1.37-7.43-5.7-7.87,2.4c-0.02,0.35-4.28,0.46-6.59,0.55c-4.03,0.68-8.05,1.36-13.54,2.28c0.8-2.63,1.39-4.58,1.86-6.1
				c-1.21-1.31-2.79-2.61-3.88-4.24c-3.82-5.73-7.41-6.36-13.08-2.56c-1.53,1.03-3.79,0.98-6.07,0.49c-0.78,1.04-1.56,2.08-2.39,3.19
				c-3.54-1.96-5.03-6.94-10.48-6.24c-3.72,0.48-7.45-0.23-6.98,5.2c-4.14-1.1-7.79-2.17-11.48-3.02c-3.59-0.83-7.89,2.06-6.8,4.9
				c1.67,4.34-1.45,4.53-3.63,6.14c-2.93,2.16-4.96,0.44-7.01-1.12c-1.2-0.91-2.03-2.29-3.03-3.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M375.47,1203.15c-0.33-1.83-0.18-3.98-1.11-5.42c-1.38-2.12-3.55-3.74-5.38-5.57c-1,0.5-2.17,1.61-2.97,1.4
				c-4.06-1.11-8.1-2.36-11.99-3.96c-3.07-1.26-2.62-4.29-2.51-6.93c0.18-4.17-0.64-6.53-5.56-4.11c-1.5,0.74-3.68,0.11-5.54,0.11
				c-0.29-0.66-0.58-1.32-0.87-1.98c0.99-0.5,1.99-1.01,3.14-1.59c-1.91-2.17-3.78-4.29-5.61-6.38c-1.53,0.65-3.03,1.84-4.54,1.84
				c-3.35-0.01-6.67-0.45-6.09-5.4c0.17-1.47-0.79-3.08-1.43-5.31c-2.09,1.68-3.49,2.8-4.78,3.84c-2.97-2.4-5.83-4.7-8.68-7.01
				c-2.58,3.58-10.05-2.17-10.49,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M46.38,502.5c0.22,2.84,0.43,5.68,0.68,8.88c2.65-0.14,4.98-0.37,7.31-0.36c7.11,0.03,10.36,4.73,7.99,11.47
				c-2.19,6.2,3.4,4.6,6,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M184.71,945.28c-1.66-2.5-3.17-5.13-5.06-7.44c-0.94-1.15-2.57-2.39-3.93-2.44c-7.11-0.27-13.77,0.86-19.57,5.77
				c-2.76,2.34-6.56,3.45-9.89,5.11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1103.57,807.35c5.33,3.17,11.1,5.78,15.86,9.65c4.89,3.98,9.18,8.87,12.99,13.93c3.48,4.62-1.57,6.97-3.64,10.33
				c-4.9-2.8-9.44-5.39-13.97-7.98c0.05,0.33,0.09,0.66,0.14,0.98c-2.63-0.14-5.33,0.05-7.85-0.53c-1.64-0.38-3.02-1.88-4.52-2.88"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M63.86,1461.52c-0.53-2.8-1.05-5.61-1.62-8.64c2.92-0.37,5.27-0.66,8.68-1.09c-2.49-2.98-4.41-5.27-6.35-7.59
				c3.95-2.38,5.43-2.39,7.13,1.89c2.95,7.43,10.13,9.41,15.76,13.23c1.91,1.3,5.45,0.18,9.63,0.17c1.24,1.82,4.03,3.36,8.29,1.2
				c1.2-0.61,3.69-1.28,4.72,2.25c0.75,2.57,2.94,6.66,7.73,4.7c0.48-0.2,1.78,1.09,2.42,1.91c1.98,2.53,3.83,5.16,5.35,7.23
				c-3.82-0.95-9.52,5.46-14.71-1.56c-1.54,1.54-2.87,2.87-3.63,3.63c-5.67-2.05-11.1-4.01-16.83-6.08
				c-0.54,1.05-1.25,2.44-2.63,5.14c-5.07-3.98-9.5-8.12-14.55-11.25c-5.16-3.2-10.92-5.43-17.19-8.45
				c-0.61,2.17-1.19,4.25-1.72,6.14c-2.68,0.4-5.33,0.79-7.99,1.18c1.97,3.44,3.93,6.89,6.64,11.62c-2.87-1.46-5.04-2.47-7.12-3.65
				c-1.56-0.88-3.01-1.98-4.51-2.98c0.17,1.83,0.37,3.66,0.49,5.5c0.27,3.99,0.51,7.99,0.78,12.31c-2.33,2.87-5.3,6.53-8.26,10.18"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M476.85,802.35c-1-1.33-1.92-2.73-3.01-3.99c-3.13-3.59-7.94-3.29-11,0.47c-1.93,2.37-4.1,4.55-6.32,6.99
				c-2.26-1.27-4.16-2.46-6.16-3.43c-1.07-0.52-2.93-1.36-3.38-0.93c-4.33,4.06-7.3,0.31-10.58-1.61c-2.19-1.28-4.19-3.06-6.53-3.9
				c-4.37-1.56-8.41,0.78-10.29,4.01c-8.55,14.69-22.03,24.04-35.11,33.91c-0.45,0.34-0.68,0.98-1.01,1.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M805.44,737.88c-0.17,0-0.38-0.07-0.49,0.01c-1.53,1.12-3.05,2.26-4.62,3.44c-12.91-5.79-17.03-7.01-27.36,2.04
				c-2.58,2.26-5.22,4.48-8.01,6.47c-2.65,1.9-4.91,3.54-8.88,4.1c-5.96,0.84-11.97,4.71-16.92,8.61
				c-6.8,5.37-11.89,12.88-20.58,15.91c-0.9,0.31-1.36,1.91-2.03,2.91"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M892.83,1002.25c-4-4.66-8.18-9.18-11.94-14.03c-5.99-7.7-10.87-16.46-17.71-23.27c-11.1-11.05-16.76-24.86-22.78-38.67"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M108.3,597.95c-7.76-3.78-15.58-6.27-24.51-4.16c-4.1,0.97-8.62,0.1-12.94,0.18c-5.49,0.1-10.99,0.32-16.48,0.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M730.53,835.83c7.32,4.33,14.54,8.86,22.01,12.93c5.48,2.99,11.19,5.62,17,7.91c1.62,0.64,4.39,0.1,5.85-0.96
				c5.05-3.7,10.46-3.16,16.07-2.43c2.95,0.39,5.96,0.36,9.82,0.57c-3.05-9.78-12.62-6.39-17.94-12.23
				c7.51-0.65,14.58-1.27,22.37-1.94c-1.79-5.4-3.25-9.78-4.92-14.8c3.17-5.79,7.95-2.24,12.63-0.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M234.65,988.76c-3.33,3.5-6.58,7.08-10.04,10.44c-1.02,0.99-2.82,2.19-3.91,1.92c-6.35-1.63-11.45,1.16-16.5,4.16
				c-6.56,3.89-13,7.97-19.49,11.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M616.18,1471.51c-16.21,2.41-28.25,9.76-31.96,26.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M886.84,899.3c3.05,14.05,5.5,28.31,13.98,40.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,875.81c-5.66,7.5-11.34,14.98-16.97,22.49c-7.42,9.9-16.3,18.31-25.93,26.04c-5.41,4.35-10.31,9.33-15.59,13.86
				c-0.67,0.58-2.26,0.08-3.43,0.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M552.26,1064.72c4.03,8.92,3.81,18.28,1.79,27.44c-0.94,4.28-3.67,8.06-9.81,7.25c-3.51-0.46-7.27,1.4-10.97,1.71
				c-2.96,0.25-6.04-0.66-8.96-0.27c-2.09,0.28-4.36,1.53-5.82,3.04c-0.51,0.52,0.74,3.54,1.9,4.73c2.2,2.28,4.69,4.49,7.45,6
				c8.25,4.53,16.46,8.96,19.93,18.57"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M667.11,1498.5c-1.87-3.4-3.74-6.81-5.88-10.7c-3.99,2.87-6.98,0.9-10.69-0.56c-9.08-3.58-18.33-4.19-26.42,3.23
				c-2.47,2.26-3.64,4.78-3.46,8.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1029.66,1330.08c0.33,7,0.83,13.99,0.95,20.99c0.11,6.02,0.03,11.99,3,17.52c0.35,0.65,0.11,1.65,0.04,2.47
				c-0.31,3.39-0.04,6.45,4.15,7.17c-2.56,6.69-8.69,4.8-13.17,5.58c-4.92,0.85-9.17,1.71-12.03,6.19c-0.37,0.57-1.91,0.39-2.91,0.56
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M24.41,1311.09c2.33,6.5,5.54,12.84,6.74,19.54c1.16,6.46,0.25,13.3,0.25,19.89c-10.91-9.09-12.71-21.86-14.98-34.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M256.62,1159.17c-2.83,0.33-5.86,1.46-8.44,0.81c-3.85-0.97-5.06,1.54-4.86,3.67c0.53,5.89-3.16,5.01-6.67,5.06
				c-1.34,0.02-3.82,0.61-3.82,0.95c-0.01,4.68-4.12,6.69-6.17,9.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M813.43,1410.54c-1.66,2.83-3.87,5.48-4.88,8.53c-1.93,5.82-7.11,8.3-11.17,11.88c-2.1,1.85-12.07-0.15-13.88-2.44
				c-1.62-2.05-1.85-4.19,0.98-5.44c2.26-1,4.65-1.7,6.98-2.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M520.79,784.36c0,12.99,0,25.99,0,38.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M143.26,1287.11c4.99,0,10.02-0.36,14.97,0.13c3.16,0.31,6.41,0.86,8.63,4.29c2.74,4.22,7.79,2.31,11.83,3.22
				c9.6,2.16,17.51,7.91,25.5,13.36"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M891.34,741.38c3,8.33,5.96,16.67,9,24.98c2.15,5.88,4.83,11.68,1.49,17.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M851.39,404.05c2.66,5,5.45,9.94,7.9,15.03c0.59,1.22,0.15,2.96,0.07,4.45c-0.1,1.84-1.07,4.07-0.35,5.43
				c2.69,5.08-0.53,8.88-1.63,13.14c-0.56,0.2-1.12,0.4-1.68,0.6c-4.93-9.05-10.09-18-14.56-27.28c-0.63-1.3,1.72-4.55,3.36-6.27
				c1.38-1.45,2.75-2.22,0.89-4.1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M603.19,573.46c-1,2.67-1.78,5.44-3.09,7.95c-0.59,1.13-2.12,2.04-3.42,2.49c-3.4,1.18-6.92,2.04-11.27,3.28
				c1.95-4.3,3.5-7.7,5.32-11.72c-1.86,0-3.88-0.56-4.41,0.09c-3.32,4.1-9.77,6.45-7.39,13.99c0.49,1.54-2.83,4.17-4.17,6.43
				c-1.33,2.24-2.37,4.64-3.53,6.98c2.83,0.17,5.66,0.41,8.49,0.43c0.5,0,1-0.93,1.5-1.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M255.12,336.58c-1.73,9.7-12.5,16.44-21.48,13.51c-2.45-0.8-4.96-1.72-7.49-1.94c-4.09-0.35-5.41,1.81-3.46,5.4
				c2.56,4.72,5.55,9.22,7.86,14.06c1.16,2.42,0.95,4.84-1.23,7.58c-4.64,5.84-11.68,10.71-11.94,18.87
				c-0.22,6.93-2.68,11.53-9.1,13.9c0.91,2.16,1.56,3.67,2.15,5.09c0.41,0,0.59,0.04,0.74-0.01c9.81-2.78,14.25-9.51,15.17-19.47
				c0.26-2.78,2.54-5.55,4.37-7.96c2.2-2.89,4.92-5.38,7.42-8.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M183.21,1252.62c3.44-1.63,6.87-3.25,10.12-4.79c1.31,12.98,2.59,25.63,3.86,38.28"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M925.79,449.03c-12.24-5.48-21.66-14.74-31.26-23.66c-3.13,2.33-1.78,5.34-0.11,6.52c8.41,5.93,11.5,15.38,16.3,23.67
				c0.85,1.46,0.7,3.51,1.01,5.29c-0.42,0.23-0.84,0.46-1.26,0.69c-3.22-2.01-6.3-4.3-9.7-5.93c-2.32-1.11-5.07-1.3-7.63-1.91
				c-0.35,0.63-0.71,1.26-1.06,1.89c3.24,4.14,6.71,8.12,9.64,12.47c1.57,2.32,4.21,5.27,0.07,7.86c-2.73-1.02-5.49-2.06-8.26-3.1
				c-0.4,0.37-0.8,0.75-1.2,1.12c2.45,5.21,4.9,10.43,7.45,15.86c-2.66,2.15-5.32,1.97-8.49,0.25c-7.77-4.2-12.25-3.11-18.44,3.93
				c2.19,7.35,0.87,10.78-5.99,15.49c-7.69,5.29-11.3,15.48-9.06,25c0.33,1.38-0.37,2.98-0.41,4.48c-0.08,3,0.08,6-0.07,8.99
				c-0.08,1.51-0.12,3.51-1.04,4.41c-3.61,3.55-7.57,6.75-11.4,10.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M847.39,1363.57c-4.06,10.81-4.19,22.88-10.49,32.98c-0.95,1.53-1.99,3-2.99,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M504.31,757.37c-8.32,3.33-16.56,6.92-25.01,9.88c-4.02,1.41-8.42,1.2-11.79,0.06c5.05-5.25,9.94-10.34,14.83-15.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M611.18,1042.73c-2.66,1.83-5.47,3.49-7.97,5.53c-9.72,7.92-19.33,15.96-28.99,23.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M32.4,1250.12c6.53,6.4,11.98,13.29,14.72,22.42c1.48,4.91,5.42,9.07,8.25,13.57"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M871.36,417.54c1.17,4.5,2.06,9.09,3.58,13.46c1.26,3.64,3.32,6.99,4.89,10.53c1.77,4,3.38,8.07,5.06,12.1
				c-0.61,0.37-1.21,0.74-1.82,1.11c-1.57-1.74-3.15-3.49-4.72-5.23"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M188.7,947.78c3.78,1.62,7.56,3.24,11.74,5.04c1.88-2.64,3.6-5.07,5.07-7.12c-1.12-1.64-2.14-3.12-2.68-3.92
				c-1.47,0-1.98-0.14-2.11,0.02c-2.79,3.37-5.58,4.51-10.56,3.89c-5.43-0.67-11.23,2.63-16.94,2.93
				c-11.52,0.61-16.89,8.21-21.47,17.14"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1092.59,414.54c3.35,11.8,2.68,23.93,3,35.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M38.39,1483.01c-4,1.17-7.98,2.37-11.99,3.47c-0.77,0.21-2.01-0.33-2.43,0.09c-5.8,5.71-14.1,2.58-20.54,5.92
				c-0.26,0.14-0.66,0.02-0.99,0.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M35.39,1025.74c-1.54,10.94,0.22,22.68-7.92,32.05c-0.88,1.02-1.37,2.25-0.51,4.59c1.31,3.56-0.02,8.2-0.64,12.32
				c-0.48,3.22-1.76,6.31-2.39,9.51c-0.99,5.03-1.1,9.98,2.96,13.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M101.81,399.05c-4.45,4.79-8.58,9.6-11.26,15.87c-1.69,3.96-5.86,6.99-9.26,10.06c-4.82,4.36-9.94,8.38-14.94,12.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M756.5,1478.01c-5.56,6.78-13.2,6.91-20.97,6.48c-3.67-0.21-7.32-0.72-10.99-0.92c-0.81-0.05-2.41,0.86-2.39,0.91
				c1.58,4.77-4.78,9.21-0.61,14.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M263.11,333.58c1.23,6.48,6.66,9.71,11.12,13.33c3.2,2.59,4.83,5.46,5.89,9.15c1.49,5.22,3.84,9.14,10.45,9.27
				c2.43,0.05,5.25,3.28,3.42,6.68c-2.33,4.34-5.25,8.37-7.91,12.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M956.26,238.63c-1.11,11.63-4.39,23.18-1.5,34.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M138.27,1433.53c-1.88-0.92-3.75-1.85-5.63-2.77c-0.37,0.34-0.74,0.68-1.11,1.02c0.75,1.75,1.29,4.87,2.28,5.02
				c5.83,0.89,8.14,6.57,12.9,8.83c1.67,0.79,2.52,3.15,4.1,4.32c1.96,1.45,4.62,2.03,6.35,3.66c2.04,1.91,3.78,3.31,6.58,1.9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,1085.71c-2.16-2.67-4.44-5.25-6.47-8.01c-4.61-6.28-10.48-9.7-18.5-9.04c-2.47,0.2-4.99-0.28-7.49-0.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M73.35,1350.07c-3.5,2.35-7.18,3.81-11.49,2.52c-0.61-0.18-1.45-0.26-1.97,0.01c-4.69,2.44-9.3,5.03-14.01,7.43
				c-3.43,1.75-6.37,5.33-11,3.09c-0.61-0.29-1.68,0.16-2.46,0.46c-3.35,1.29-6.62,3.57-10.01,3.75c-4.62,0.24-8.75,0.8-12.54,3.63
				c-0.67,0.5-2.26-0.24-3.43-0.41"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M225.16,1334.58c12.32,0.34,24.54,4.17,36.95,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M150.25,350.57c-1.33,1.33-2.52,3.61-4.02,3.84c-8.14,1.23-14.01,4.77-16.5,13.14c-3.15,10.54-9.69,19.32-15.41,28.52
				c-0.22,0.36-0.03,0.98-0.03,1.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M798.95,439.53c2.33,10.99,4.66,21.99,6.99,32.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M226.16,951.77c-0.41,1.03-0.83,2.06-1.46,3.64c-6.12-6.64-10.57-13.48-12.14-22.11c-0.45-2.46-2.22-4.68-3.39-7.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M549.26,1065.72c-0.35,11.24,2.19,22.96-5.49,32.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M566.74,1465.52c-1.17,3.83-1.66,8.06-3.64,11.41c-4.01,6.79-6.93,13.85-8.35,21.57"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M897.33,909.3c1.88,11.76,5.62,22.85,11.99,32.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M33.4,1003.25c-1,1.5-2,3-3.22,4.84c3.99,2.49,7.84,4.9,13.05,1.96c1.54,1.66,3.15,3.38,4.67,5.02
				c-5.73,4.27-11.26,8.63-17.08,12.54c-1.26,0.85-3.82,0.69-5.37,0.03c-7.75-3.3-15.36-1.58-23.01,0.1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M167.23,439.53c-4.42,3.62-8.84,7.24-13.74,11.25c-5.3-8.98-14.29-4.34-20.35-7.54c4.75-6.17,9.55-12.4,14.73-19.13
				c-3.61-0.44-6.11-0.75-8.61-1.06"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1031.66,1064.72c0.67,1.5,1.9,3,1.9,4.5c0.03,8-0.27,15.99-0.38,23.99c-0.02,1.33,0.31,2.66,0.47,4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M818.43,401.05c6.7,4.52,8.1,9.81,3.65,17.08c-2.65,4.33-3.81,9.57-5.64,14.4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M174.72,1254.62c0.33,10.66,0.67,21.32,1,31.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M122.29,600.95c-5.83-0.33-11.74-1.46-17.45-0.79c-6.76,0.79-13.32,3.23-20.01,4.75c-3.27,0.75-6.63,1.22-9.98,1.49
				c-3.14,0.25-6.32,0.05-9.48,0.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M958.75,952.27c-0.67,3.33-1.25,6.68-2.01,9.99c-1.63,7.1-0.19,15.08-6.21,20.84c6.3,4.27,7.29,3.9,8.19-3.35
				c0.82-6.64,1.45-13.32,1.96-19.99c0.11-1.46-0.6-2.99-0.93-4.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1128.04,496.5c-3.72,4.76-6.99,9.43-7.77,15.96c-0.6,5-3.72,9.7-5.71,14.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M673.61,1498.5c-3.06-3.79-7-6.98-5.3-13.08c0.4-1.42-2.67-4.95-4.77-5.68c-3.61-1.25-7.01-1.7-4.84-7.75
				c-7.59,0.56-14.07,1.14-20.55,1.47c-2.48,0.13-4.99-0.29-7.49-0.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M309.06,1032.23c-4.36-3.76-7.45-9.29-14.77-10.45c0.55,1.9,0.71,3.29,1.33,4.43c1.68,3.08,4.11,5.48,1.3,9.74
				c-1.07,1.63,1.71,5.76,2.64,8.78c0.45,1.46,0.69,2.99,1.02,4.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M711.56,1414.54c-12.27-9.86-26.04-14.91-41.94-12.96c-0.7,0.09-1.34,0.63-2,0.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1127.04,1010.24c-5.49,3.33-10.77,7.11-16.54,9.88c-6.92,3.32-14.23,5.86-21.44,8.56c-2.21,0.83-4.64,1.05-6.97,1.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M511.31,472.51c-2.55,9.39-8,16.96-15.03,23.43c-1.83,1.69-4.28,2.72-6.44,4.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M81.34,1274.61c12.03-0.5,23.92,0.14,35.46,4c6.12-3.07,9.71,4.88,15.49,3.55c1.58-0.36,3.32-0.06,4.98-0.06"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M212.18,1481.51c2.54-3.92,4.16-8.78,10.51-8.24c1.01,0.09,2.3-3.09,3.47-4.75c5.86,3.03,5.86,3.03,10.1-3.15
				c3.15,1.75,6.28,3.48,9.03,5.01c-3.24,2.75-5.85,4.96-9,7.64c8.11,2.99,10.23,2.13,14.68-4.75c1.88,1.42,3.69,3.85,5.67,3.99
				c2.25,0.16,4.57-2.26,6.99-2.6c2.92-0.41,6.08-0.22,8.94,0.48c2.69,0.66,0.9,3.14,1.15,4.86c0.28,1.91,1.54,3.68,2.37,5.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M81.34,1373.56c-3.33,0-6.66-0.1-9.99,0.02c-7.99,0.27-15.98,0.65-23.97,0.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M75.34,1278.61c-2.66,1.67-5.33,3.33-8.46,5.29c5.69,4.23,11.22,8.35,16.74,12.46c-2.82,3.48-2.8,3.7,1.49,10.7
				c-4.24,0.94-7.01,0.25-7.85-4.45c-0.14-0.76-1.65-2.03-1.86-1.91c-4.26,2.44-6.24-0.93-9.18-2.9c-2.1-1.41-5.44-0.95-8.23-1.32
				c-0.19,0.49-0.39,0.99-0.58,1.48c1.57,1.98,3.13,3.97,4.84,6.12c-5,2.69-9.65,5.2-14.86,8.01c-0.66,0-2.38,0.24-3.98-0.08
				c-1.09-0.22-2.01-1.25-3.01-1.91"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M167.73,471.51c-4.66,4.16-9.3,8.35-13.99,12.48c-1.77,1.56-3.84,2.84-5.45,4.54c-3.83,4.06-3.6,4.9,1.32,7.39
				c-3.93,4.67-7.89,9.37-11.84,14.07"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M957.75,790.35c-3.5,1-7.14,1.65-10.46,3.07c-6.43,2.75-12.68,5.92-19.01,8.92"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M463.86,394.55c-3.32,3.21-1.8,6.97-0.94,10.48c1.39,5.7,7.17,1.26,10.42,4.53c-1.92,5.12-3.31,10.91-6.16,15.87
				c-2.95,5.12-2.25,9.43,0.18,14.1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M864.37,985.26c4.49,5.83,8.36,12.32,13.62,17.35c10.42,9.98,23.14,16.14,37.32,19.13"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1111.56,1018.24c-3,0.67-6.01,1.28-8.99,2.01c-6.67,1.63-13.32,3.32-19.98,4.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M211.18,1249.63c-0.33,1.83-0.84,3.65-0.96,5.5c-0.16,2.66-0.23,5.34-0.01,7.99c0.75,8.76-4.79,16.34-4.53,24.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M310.55,1060.72c-7.75-8.24-15.5-16.48-22.28-23.69c1.02-3,1.77-4.87,2.29-6.8c2.44-9.08-2.95-14.61-8.93-20.03
				c-3.31-3-6.18-6.49-9-9.49c0.7-1.58,1.23-2.79,2.27-5.16c7.53,7.54,14.67,14.55,21.62,21.76c1.4,1.45,2.05,3.61,3.05,5.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M877.85,688.41c2,5.33,4.05,10.64,5.97,16c1.42,3.96,2.68,7.99,4.01,11.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M237.64,1498.5c1.71-3.35,0.15-5.44-3.01-5.92c-7.61-1.15-12.72-5.31-15.99-12.06c-0.03-0.07-0.32-0.01-0.95-0.01
				c-2.84,2.49-5.59,2.84-8.62-0.92c-3.22-4-8.2-2.98-12.74,2.34c-1.09-1.35-2.65-2.5-3.02-3.94c-0.56-2.22,0.07-4.91-3.62-4.47
				c-2.64,0.31-5.9-1.15-6.75,3.46c-0.19,1.04-3.59,1.48-5.95,2.37c-0.51-2.27-0.85-3.81-1.22-5.47c-0.54-0.12-1.05-0.31-1.56-0.33
				c-1.33-0.05-3.01,0.51-3.94-0.1c-5.45-3.62-8.74-3.35-13.01,1.61c-0.86,1-1.14,2.54-2.08,3.42c-1.29,1.22-2.94,2.06-4.43,3.05
				c-1.18,0-3.46-0.39-5.46,0.18c-0.88,0.25-1.21,2.47-2.31,4.99c-5.18,0.91-3.15,7.52-5.24,11.82"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M19.41,1303.1c8.99,9.63,13.87,21.26,16.51,33.98c0.08,0.39,0.63,0.67,0.97,1.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1025.67,1384.56c2.06,2.21,4.12,4.42,6.98,7.48c-0.01,0-1,0.51-1.63,0.83c0.89,1.74,1.38,3.91,2.74,5.07
				c1.57,1.34,3.84,2.31,5.9,2.51c4.71,0.45,5.26,1.09,2.98,5.1c-2.19,3.87-3.18,8.77-8.49,10c0.93,4.51-4.54,4.25-5.24,6.57
				c-1.5,4.92-6.02,3.42-8.84,5.26c-0.83,0.54-3.18-0.48-4.34-1.41c-3.43-2.73-7.62-2.03-10.73,2.33c1.63,1.3,3.29,2.6,5.65,4.48
				c-4.23,2.24-7.84,4.16-11.85,6.28c2.63,3.07,8.92,2.56,4.89,7.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M166.73,1260.12c0.33,3.66,0.74,7.33,0.98,11c0.39,5.83,0.68,11.66,1.01,17.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M796.45,1349.08c5.49,5.33,10.86,10.8,16.53,15.94c3.11,2.82,4.63,5.91,3.95,10.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1055.63,1335.58c6.77,9.64,16.67,14.51,27.47,17.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M30.4,1250.12c-2.83,0.83-5.64,1.73-8.5,2.46c-0.94,0.24-2.6-0.36-2.89,0.1c-3.67,5.62-9.07,3.64-14.08,3.44
				c-0.83-0.03-1.66,0-2.5,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M47.88,501c-2.33,1.5-4.93,2.7-6.94,4.55c-7.72,7.09-15.26,14.38-23.13,21.86c-0.15-0.15-1.15-1.15-2.84-2.84
				c5.99-5.86,11.71-11.46,17.43-17.07"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1040.15,1276.11c0.17,7.5,0.37,14.99,0.47,22.49c0.02,1.83-0.3,3.66-0.47,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M357,1190.65c-1.65,3.5-3.31,7.01-5.23,11.07c-2.34-1.76-4.55-3.42-7.09-5.32c-4.01,4.36-7.35,11.07-15.05,6
				c-2.82,6.68-4.36,8.13-12.31-2.05c-2.3,2.49-4.92,4.18-8.26,1.79"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M166.73,1251.62c3.78,2.54,7.29,3.69,10.07-1.46c0.43-0.8,2.57-1.68,2.81-1.44c1.23,1.27,2.77,2.81,2.98,4.41
				c0.66,5.12,0.76,10.31,1.13,15.48c0.29,4.17,0.56,8.34,1.07,12.48c0.11,0.9,1.25,1.68,1.92,2.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1049.14,1133.18c-0.83,9-1.66,17.99-2.5,26.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M885.84,1010.24c7.78,9.21,21.66,17.49,34.96,10.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M461.37,400.05c-5.49,5.66-10.83,11.49-16.6,16.86c-1.31,1.22-4.49,3.14-5.51-0.71c-3.44,1.69-6.53,3.86-9.91,4.56
				c-1.5,0.31-3.61-2.39-5.44-3.72"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M515.8,1479.01c-0.33,0-0.8-0.15-0.98,0.02c-7.73,7.26-15.87,13.99-24.99,19.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M183.71,1256.62c2.56-0.24,4.19,0.35,4.03,3.5c-0.44,8.82-0.7,17.66-1.04,26.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1051.14,537.98c-2.68,1.97-5.37,3.95-8.06,5.93c-1.44-1.08-2.96-2.21-4.85-3.62c1.58-1.79,3.07-3.49,5.25-5.96
				c-1.84-1.5-3.39-3.31-5.36-4.27c-4.45-2.16-3.95-5.54-3.61-9.59c0.39-4.48-0.38-9.05-0.64-13.58c-0.77-0.06-1.54-0.12-2.32-0.19
				c-1.3,5.59-2.59,11.19-3.89,16.78c-0.5,2.17-0.52,4.62-1.63,6.42c-1.23,2.01-2,3.33,0.08,5.13c0.51,0.44,0.78,1.8,0.49,2.41
				c-1.87,3.89-3.93,7.69-5.93,11.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M489.83,458.52c-3.5,7-7.01,13.98-10.47,21c-0.83,1.68-1.49,3.45-2.29,5.33c3.85-1.44,7.98-1.79,10.05-4.03
				c3.15-3.41,5.39-7.96,6.88-12.4c0.43-1.29-3.01-3.89-4.67-5.89"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M813.43,1320.09c-1,5.33-2.42,10.63-2.83,16c-0.24,3.1,1.17,6.32,1.83,9.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M36.89,1413.04c-11.69-1.4-23.26-1.3-34.46,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,887.81c-4.83,7.33-9.7,14.63-14.43,22.02c-0.58,0.9-0.39,2.3-0.56,3.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M520.29,824.34c0,8.66,0,17.32,0,25.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1128.04,476.01c-3.83,4.33-8.47,8.22-11.18,13.16c-1.62,2.95-0.22,7.55-0.18,11.42c0.73,0.11,1.46,0.22,2.19,0.33
				c1.56-3.31,3.23-6.56,4.65-9.93c3.07-7.3,6.02-14.65,9.01-21.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.94,1347.08c-2.24,6.96,0.8,13.04,3.61,18.94c1.97,4.14,0.21,4.6-3.11,4.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M504.81,474.01c-5.83,9.66-11.65,19.32-17.48,28.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M258.62,1088.21c0.33,6.16,0.67,12.33,1,18.49c0.34,6.5,0.67,12.99,1,19.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M857.38,925.29c11.83,12.86,20.18,28.07,28.96,42.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M862.37,932.78c1,3,1.35,6.47,3.13,8.9c3.9,5.31,8.52,10.09,12.85,15.09"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M314.05,992.25c-3.06-5.44-8.13-4.37-14.1-4.51c4.78,6.87,3.15,15.62,10.1,20.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M478.85,1031.23c3.33,5.66,6.4,11.51,10.18,16.86c0.88,1.24,4.14,0.8,6.3,1.13"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M521.29,824.34c0,8.66,0,17.32,0,25.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M138.27,565.97c-5.09,1.04-10.18,2.08-15.27,3.12c0.1,0.46,0.2,0.92,0.29,1.38c7.99,4.17,15.98,8.33,23.97,12.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M267.61,1224.64c-1.5,1.33-2.79,3.18-4.54,3.88c-2.74,1.08-6.94,4-6.54-2.88c0.04-0.63-1.24-1.85-1.9-1.85
				c-0.67,0-2.02,1.43-1.89,1.82c1.35,3.93-0.81,2.96-3.12,2.64c-1.2-0.17-2.55,0.75-3.97,1.22c7.76,10.69,8.41,10.94,14.75,6.33
				c2.17,0.82,4.32,1.35,6.17,2.41c1.38,0.79,3.53,2.48,3.36,3.39c-1.48,7.83,3.38,4.53,7.64,3.84c-0.35,2.41-0.01,5.83-1.12,6.39
				c-1.78,0.9-4.51-0.09-7.19-0.31c-0.49,1.26-0.77,4.52-2.3,5.29c-1.51,0.75-4.27-1.02-6.19-1.6c-3.35,4.51-6.91,9.31-10.69,14.4
				c-2.1-0.77-4.72-3.56-7.56-0.11c-0.14,0.18-2.2-1.22-3.36-1.89"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M983.72,1075.21c1.33,6.66,2.59,13.34,4.05,19.98c0.33,1.48,1.38,2.8,3.27,4.12c0.22-1.54,0.54-3.07,0.66-4.61
				c0.53-6.99,1.01-13.99,1.51-20.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M794.46,1480.51c-8.39,4.11-16.6,7.85-26.44,8.41c-6.53,0.38-15.32,0.29-18,9.58"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M4.93,1317.09c1.5,7.33,2.21,14.95,4.79,21.88c1.58,4.25,6.06,7.42,9.18,11.12c0.77,0.91,1.35,1.99,2.01,2.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M251.13,1324.09c-5.08,4.76-11.38,6.91-17.97,8.53c-1.72,0.42-3.34,1.3-5,1.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M282.59,1270.61c-4.89-0.77-5.73-2.08-4.32-7.69c-1.8-0.12-3.46-0.23-5.13-0.34c0.23,0.44,0.46,0.87,0.69,1.31
				c-1.57,1.58-2.92,3.9-4.8,4.46c-1.27,0.38-3.28-1.74-5.86-3.28c0.53-0.28-0.38,0-1.02,0.58c-0.77,0.71-1.31,1.67-4.01,4.45
				c-1-1.99-2.01-3.99-3.01-5.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M481.84,1017.24c0.77,8.8,2.16,17.34,9.49,23.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M156.24,533.98c5.41-3.88,10.82-7.76,17.27-12.39c-3.57-0.34-5.92-0.57-8.73-0.84c-0.56-9.9,7.39-14.2,14.07-19.94
				c-1.93-0.5-3.33-0.86-4.63-1.2c0-1.78,0-3.44,0-5.11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,413.54c-5.49,5.5-10.83,11.17-16.57,16.4c-1.95,1.77-4.91,2.42-7.4,3.6c-3.5,1.66-7.92,2.5-10.29,5.17
				c-4.66,5.25-9.47,6.55-15.68,3.82"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M95.32,405.05c-2.66,1.33-5.4,2.54-7.95,4.06c-1.05,0.63-2.61,2.25-2.39,2.89c2.2,6.48-2.99,6.6-6.63,8.04
				c-6.2,2.44-9.15,7.51-10.98,13.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1044.15,1060.72c0.67,5.5,1.82,10.99,1.87,16.49c0.06,6.33-0.87,12.66-1.37,18.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1063.12,1049.73c-1.5,1-3.63,1.66-4.39,3.06c-3.69,6.85-10.14,6.97-16.58,7.49c-2.36,0.19-4.76,0.68-6.98,1.5
				c-9.2,3.39-18.33,6.95-27.48,10.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M554.75,688.91c1.74-3.41,0.07-3.38-2.5-2.5c-6.32,2.17-12.65,4.33-18.98,6.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M136.27,1321.09c-0.4,1.2-0.8,2.4-1.28,3.85c-1.95-1.25-3.58-2.31-5.18-3.34c-0.86,0.66-1.7,1.31-3.81,2.93
				c0.4-2.92,0.65-4.74,1-7.35c-0.58-0.25-2.78-1.89-3.57-1.38c-1.35,0.87-1.66,3.21-2.68,4.75c-0.76,1.15-2.57,3.05-2.86,2.88
				c-4.11-2.48-5.45,0.99-7.65,3.08c-1.29,1.23-3.26,1.74-4.93,2.57"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M138.77,1340.58c-6.66,1.67-13.29,3.44-20,4.89c-1.17,0.25-2.64-0.89-3.97-1.39"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M229.15,1212.14c1.12,3.66-0.66,8.39,4.36,10.77c0.63,0.3-0.71,4.38-0.82,6.72c-0.12,2.73,0.17,5.54-4.05,4.01
				c-0.29-0.11-0.66-0.01-0.99-0.01c1.14,6.53-8.04,5.94-8.14,11.99c-0.06,3.22-5.56,4.62-8.34,2.5c-1.51-1.15-2.99-2.34-4.52-3.53
				c-0.32,0.18-0.94,0.37-0.93,0.53c0.37,7.51-3.41,14.89-0.59,22.51c0.84,2.27,1.98,5.07-1.95,5.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M72.85,1247.63c3,2.83,5.65,6.2,9.06,8.38c6.46,4.13,10.04,11.85,17.88,14.19c0.03,0.01-0.31,1.25-0.48,1.92"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M466.36,1028.74c2.66,5.16,5.36,10.31,7.97,15.5c1.19,2.35,2.22,4.77,5.51,4.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M539.27,905.8c0.67,5.48,3.9,10.67,1.62,16.54c-0.38,0.99,0.87,2.62,1.37,3.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M560.25,573.96c-7.21,7.84-6.63,17.94-7.99,27.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M42.88,1222.14c6.82,5.33,13.65,10.66,20.47,15.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M86.83,540.98c-0.63,4.65-1.26,9.3-1.92,14.09c2.74-1.06,5.09-1.98,7.45-2.89c0.27,0.55,0.55,1.1,0.82,1.65
				c-3.8,3.54-7.61,7.08-12.27,11.43c4.58,0.51,7.27,0.81,11.4,1.27c-5.54,4.76-10,8.6-14.47,12.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M547.76,913.29c-1.33,3.66-2.59,7.36-4.01,10.99c-2.43,6.19-4.84,12.39-7.56,18.46c-0.78,1.75-2.58,3.04-3.91,4.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M483.84,749.38c-3.83,1.5-7.55,3.76-11.51,4.3c-4.69,0.63-8.78,2.22-13,4.1c-4.65,2.07-9.65,3.32-14.42,5.15
				c-1.64,0.63-3.02,1.94-4.52,2.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M473.85,1262.12c-5.33,1.5-10.72,2.81-15.95,4.58c-2.5,0.85-4.69,2.58-7.02,3.91"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M23.41,1420.54c-4.02,4.58-10.09,3.01-15.01,4.93c-1.85,0.72-3.97,0.73-5.97,1.06"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M699.57,1458.52c-6.99-1.5-13.98-3-20.97-4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1083.6,1355.57c-1.05,1.29-2.1,2.58-3.7,4.54c-2.47-1.91-4.95-4.29-7.86-5.91c-1.4-0.78-3.58-0.13-6.06-0.13
				c-3.24-2.31-6.65-6.9-12.34-3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M164.23,1249.13c0,9.33,0,18.66,0,27.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M269.11,914.29c-3.38,4.42-2.03,10.76,3.02,13.45c1.51,0.8,3.3,1.05,4.97,1.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M226.66,1189.66c0,1.17-0.22,2.38,0.04,3.49c1.25,5.52,2.63,11,3.96,16.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M751.51,1313.09c-0.2,7.55,2.45,14.1,6.99,19.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M38.89,1418.54c-5.53,1.14-11.04,2.44-16.52-0.43c-0.72-0.37-1.96,0.26-2.96,0.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M879.35,452.52c0.67,4.66,1.54,9.31,1.92,14c0.24,2.97-0.1,6.01-0.46,8.98c-0.51,4.18-1.29,8.33-1.96,12.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M288.58,986.26c5.66,5.83,11.32,11.66,16.98,17.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M42.39,1331.33c0.5,6.25,1,12.49,1.5,18.74"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M171.73,1312.59c-4,6-7.99,11.99-11.99,17.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M2.93,1347.08c1.66,5,3.46,9.96,4.9,15.02c0.33,1.14-0.57,2.64-0.9,3.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M198.19,1254.62c0.17,1.67-0.04,3.48,0.56,4.97c3.47,8.59,5.32,17.29,2.98,26.52c-0.15,0.59,0.29,1.32,0.46,1.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M288.58,934.78c0.17,1.33,0.89,2.94,0.4,3.95c-1.92,3.95-4.35,7.65-6.34,11.57c-0.84,1.66-1.06,3.64-1.56,5.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M828.91,412.54c0.17,3-0.12,6.11,0.58,8.98c2.29,9.41,0.27,17.93-4.58,26.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M525.79,462.52c-5.22,4.51-8.22,10.27-9.49,16.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M161.24,1269.12c-0.33,1.83-1.08,3.7-0.92,5.49c0.41,4.36,1.25,8.67,1.91,13"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M605.19,478.01c-5.83,4.16-11.65,8.33-17.48,12.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1041.15,1060.72c-1.83,5.83-3.66,11.66-5.49,17.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M104.81,1312.09c-0.17,7.66-0.27,15.33-0.56,22.99c-0.04,1.18-0.94,2.33-1.43,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1047.14,1204.15c-0.17,5.66-0.33,11.33-0.5,16.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M56.37,1410.04c-5.66,0.67-12.38-0.33-16.71,2.42c-5.31,3.38-10.96,3.13-16.25,5.07"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M83.83,518.99c0.02,3.79,0.55,7.34-2.82,10.68c-2.42,2.4-3.18,6.48-4.67,9.81"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1103.07,1333.08c-1.33,1.67-2.97,3.17-3.94,5.03c-2.85,5.49-4.55,6.42,0.94,11.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M222.16,800.35c5.49,1,10.99,2,16.48,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,1340.58c-1.66-0.06-3.32-0.12-4.98-0.18c-0.18-0.62-0.35-1.24-0.53-1.86c-2.15,2.02-4.29,4.05-5.83,5.5
				c-2.56,0.19-4.92,0.07-7.13,0.61c-2.9,0.71-5.62,2.22-8.53,2.78c-1.16,0.22-2.64-1.19-3.97-1.85"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M22.91,1266.12c3.71,4.12,4.02,9.7,6.22,14.39c2.11,4.49,6.11,8.09,9.23,12.13c0.24,0.31,0.03,0.97,0.03,1.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1104.57,1380.56c-1.33-0.33-3.77-1.1-3.83-0.94c-1.58,3.99-4.11,0.91-7.71,0.58c1.08,2.68,1.32,5.07,2.65,6.25
				c4.21,3.78,8.23,8.57,11.98,15.05c0.58,1,2.31,1.29,3.4,2.07c1.24,0.9,2.4,1.92,3.5,2.99c1.88,1.81,1.93,6.58,6.91,4.35
				c0.65,1.27,1.53,3,2.33,4.57c2.83-2.54,5.55-4.99,8.27-7.43c-0.21-0.32-0.42-0.65-0.63-0.97c-1.29,0.33-2.57,0.65-4.38,1.11
				c-0.38-3.29-0.75-6.52-1.21-10.51c-2.04,1.41-3.15,2.17-4.73,3.26c-0.32-2.18-0.59-4.06-0.78-5.39c-2.66-0.73-5.16-1.01-7.16-2.15
				c-0.89-0.5-0.8-2.74-1.14-4.14c-2.24-1.11-5.03-2.15-7.32-3.85c-0.72-0.53-0.11-2.85-0.11-4.35"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M223.16,925.29c3.83,5,7.96,9.8,11.36,15.07c1.78,2.76,2.25,6.35,3.32,9.57c-0.52,0.33-1.05,0.66-1.57,0.99
				c-2.37-1.38-4.74-2.77-7.11-4.15"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1001.2,478.51c-1.33,2.5-2.94,4.89-3.92,7.52c-1.01,2.7-1.41,5.64-2.07,8.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M205.68,1220.14c-1.5-1.17-3.77-3.63-4.36-3.28c-3.04,1.82-7.88,2.4-6.86,7.66c-5.19,2.29-11.54-1.92-15.84,3.53
				c-0.22,0.28-1.9-0.59-2.9-0.92"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M496.32,505c-1.66,3.83-3.39,7.64-4.96,11.51c-0.56,1.37-0.77,2.88-1.41,5.38c2.66-1.53,4.27-2.46,5.88-3.39"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1045.65,1225.14c-0.67,5.33-1.33,10.66-2,15.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M541.27,1227.14c0.17,2.17-0.26,4.62,0.64,6.43c1.29,2.59,2.11,4.41-0.54,6.68c-0.93,0.8-0.76,2.89-1.09,4.39"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M998.7,1467.02c2.41,4.81,5.02,4.95,10.15-0.16c-0.01,6.97,6.93,5.95,9.83,9.65"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M604.69,1488.51c-0.92-1.97-2.18-3.17-3.97-0.98c-2.93,3.58-5.69,7.31-8.52,10.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M973.73,1466.02c-0.21,1.19-0.42,2.38-0.64,3.65c-4.08-1.74-5.94-0.57-8.5,5.19c-1.29-1.23-2.48-2.37-4.1-3.93
				c-1.37,2.42-2.55,4.5-3.73,6.59"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M2.43,1270.61c2.83,0,6.85-1.16,8.22,0.26c2.31,2.39,3.37,6.3,4.16,9.76c1.17,5.14,4.42,6.7,9.94,3.79
				c1.25,1.43,2.56,2.93,4.64,5.31c0-3.45,0-5.79,0-8.12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M520.79,851.32c0.33,5,0.67,9.99,1,14.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M305.06,1197.15c1.91,1.24,5.53,9.27,4.4,10.4c-1.08,1.08-3.03,2.14-4.37,1.92c-1.94-0.33-3.69-1.83-5.52-2.83"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M275.6,808.85c-4.99-0.83-9.99-1.67-14.98-2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M119.79,1333.08c-0.94,5.39-5.17,8.15-8.99,11.24"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M989.71,450.02c1.81,5.54-4.37,5.75-5.6,7.89c0.83,4.89,1.48,8.76,2.21,13.07c3-0.65,5.64-1.49,6.87,3.41
				c0.53,2.1,5.69,5.24,10.01,2.62"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M48.88,1322.09c1.71,2.91,1.21,5.76,0.72,9.03c-0.69,4.53,0.12,9.3,0.28,13.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M120.29,1282.11c0.33,1.17,0.3,2.72,1.06,3.43c3.67,3.44,3.8,8.38,5.8,12.37c1.7,3.4,7.1,7.68,10.01,7.12
				c4.8-0.93,5.97,1.89,6.36,4.1c0.65,3.8,3.04,5.89,5.17,8.51c1.38,1.69,2.07,3.94,3.07,5.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M290.58,1481.01c-1.33,0.33-3.25,1.4-3.9,0.88c-3.54-2.84-6.24-1.09-7.96,1.7c-1.67,2.7-2.27,6.06-3.35,9.14
				c-1.1-1.07-2.2-2.13-3.3-3.2c-1.28-1.38-2.56-2.76-5.01-5.4c-0.86,3.48-1.46,5.92-2.21,8.94c-1.85-1.38-3.58-2.68-5.75-4.3
				c-3.54,3.14-7.26,6.44-10.98,9.73"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M242.14,940.28c2.34,6.53,0.56,13.26-4.49,16.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M833.91,1408.05c1.65,4.2,2.66,8.13-3.48,9.57c-0.62,0.15-1.02,1.26-1.51,1.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1041.15,1254.12c-0.17,4.66-0.33,9.33-0.5,13.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M94.32,1320.09c-0.67,5-1.11,10.04-2.11,14.97c-0.4,1.97-2.02,3.68-3.01,5.39c-5.63-3.98-10.49-7.42-15.35-10.87"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M99.81,1329.09c0.33,3.33,0.71,6.66,0.96,10c0.06,0.82-0.24,1.68-0.48,2.49c-0.52,1.77-1.11,3.53-2.19,6.93
				c4.88-1.22,8.28-2.08,11.69-2.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M174.72,1227.64c-4.13-0.73-8.24,1.88-12.62-0.77c-2.24-1.35-6.5,4.62-6.93,10.22c-2.82-0.75-5.47-1.46-8.25-2.2
				c1.76-3.7,3.17-6.65,4.86-10.2c2.37,1.06,4.63,0.88,6.07-2.5c1.15-2.69,3.42-3.3,6.43-1.64c3.56,1.96,7.49,3.23,10.83,4.63
				c0.45,5.06-3.48,10.05,2.93,13.16c1.51-1.89,3.06-3.81,4.93-6.15c8.63,10.21,12.96,1.16,18.19-4.13
				c-0.95-3.56-0.93-6.34,3.99-7.05c2.45-0.35,4.92-1.88,6.95-3.44c2.44-1.87,3.64-0.49,5.06,1.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M404.94,1194.65c-3.83,3-7.39,7.92-11.55,8.51c-5.13,0.72-8.87,4.51-13.91,4.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M60.86,1329.09c-2.41,7.57-2.96,15.23-1,22.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M787.46,1421.04c-3.44-5.26-11.74-4.15-15.67,3.31c3.72,0.43,6.95,0.81,10.18,1.18"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M501.32,930.28c-4.49,0.83-8.99,1.67-13.48,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M301.07,975.26c3.33,4.5,6.66,9,9.99,13.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M787.46,1256.12c-0.5,4.5-1,9-1.5,13.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M476.35,1201.65c-2.7-2.97-5.88-2.15-8.98-0.97c-2.49,0.95-4.79,3.6-7.5,0.47c-1.27,2.43-6.13-0.61-6.06,4
				c0.01,0.49-0.84,1.23-1.44,1.46c-5.53,2.14-6.68,4.53-4.48,9.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M59.86,1338.58c4.49,1.5,8.99,3,13.48,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1048.14,1183.16c-0.17,4.33-0.33,8.66-0.5,12.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M588.71,1095.2c1.33,4.33,2.66,8.66,4,12.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1070.11,341.08c-1.17,1.83-2.62,3.55-3.41,5.53c-0.73,1.84-0.75,3.96-1.08,5.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M211.68,1258.62c3.92,2.17,7.83,4.35,12.27,6.81c0.83-0.67,2.23-1.79,4.08-3.27c0.6,0.91,1.53,1.67,1.56,2.46
				c0.13,3.77,3.09,4.27,5.57,4.31c1.48,0.02,4.04-1.62,4.27-2.85c0.44-2.35-0.41-4.96-0.81-7.45c-0.06-0.38-0.62-0.68-0.98-0.98
				c-0.01-0.01-0.31,0.31-0.48,0.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M629.16,1462.52c-4.99,4.16-12.08,7.43-14.37,12.76c-2.1,4.9-5.02,8.4-8.07,12.26c-1.56,1.98-2.7,4.3-4.04,6.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M224.16,1296.1c-4-1.83-7.99-3.66-11.99-5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M973.73,518.99c-4,6.33-7.93,12.7-12.02,18.97c-1.08,1.66-2.63,3.02-3.96,4.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M642.64,508.5c4,3.33,7.99,6.66,11.99,10"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M357.5,1474.01c0.83,2.17,2.01,4.27,2.4,6.51c0.4,2.29,0.23,4,3.61,3.67c1.9-0.19,3.91,1.83,5.99,2.22
				c2.25,0.42,4.64,0.09,7.59,0.09c0.02,0.12,0.12,1.33,0.4,2.5c0.78,3.35,5.59,4.22,9.99,2.04c2.74-1.35,5.57-2.79,8.51-3.31
				c1.09-0.19,3.88,2.31,3.74,3.24c-0.64,4.14,1.53,3.12,3.71,2.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M10.43,1341.58c1,6.16,2,12.33,3,18.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M80.84,1335.08c1,4.16,1.73,8.42,3.08,12.47c1.1,3.3-0.02,4.36-3.06,5.1c-3.57,0.86-7.01,2.26-10.5,3.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1050.14,1117.19c0,4,0,8,0,11.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M157.24,1293.1c2.83,3.5,5.66,7,8.49,10.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M112.8,1328.09c-0.33,2.83-0.8,5.66-0.93,8.5c-0.06,1.15,0.6,2.33,0.93,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M474.35,444.53c0.59,2.97,1.18,5.93,1.85,9.32c1.42-0.71,3.03-1.51,4.64-2.32"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M547.76,1179.16c0,5.66,0,11.33,0,16.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M129.78,1262.12c-1.03,0.8-2.05,1.6-2.94,2.29c2.18,4.08,4.28,8.03,6.67,12.52c-3.31,0.83-6.02,1.51-8.72,2.18"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M940.77,277.61c-0.17,6.66-0.33,13.33-0.5,19.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M953.76,578.46c-2.16,1.83-4.27,3.73-6.51,5.47c-1.64,1.27-3.44,2.33-5.85,3.94c1.11-4.61,1.99-8.26,2.87-11.91"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M139.27,1316.09c0.5,3.83,1,7.66,1.5,11.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M831.91,1408.05c-2,3.5-3.68,7.24-6.12,10.39c-1.23,1.58-3.84,2.09-6.09,3.22c-0.32-3.63-0.55-6.12-0.77-8.61"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M37.89,1328.09c-1.23,7.34-0.18,14.47,2,21.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M835.9,1251.62c6.8,2.93,7.52,10.2,10.87,15.56c0.63,1-0.22,2.93-0.39,4.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M51.87,1329.09c0.67,3.66,1.33,7.33,2,10.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1049.14,1356.57c0.5,0.5,1.47,1.39,1.43,1.44c-1.67,2.01-2.99,4.02-0.93,6.55c-0.83,0.67-1.66,1.87-2.5,1.89
				c-1.83,0.03-3.66-0.56-5.53-0.9c-0.32,2-0.64,4-0.96,6c-2-0.5-3.99-1-5.99-1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M366.98,1194.15c1.83,2.5,3.19,5.7,5.6,7.33c3.66,2.48,5.91,5.34,6.38,9.66"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M297.57,971.26c-0.06,3.02,0.93,6.44-3.47,7.59c-0.67,0.17-1.35,1.53-1.47,2.41c-0.72,5.52-1.62,5.87-7.05,2.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M758,1324.59c1.6,8.56,9.25,13.28,12.98,20.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M633.65,1250.12c3.16,2.83,6.33,5.66,9.49,8.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M793.96,1452.52c-3.33,1.5-6.66,3-9.99,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M566.24,1147.68c-4.66,5.5-9.01,11.3-14.09,16.38c-3.36,3.36-3.4,7.06-3.39,11.1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M276.1,1421.04c-1.17,0.5-2.94,0.69-3.38,1.56c-1.41,2.78-2.32,5.82-3.76,9.68c-2.23-1.95-3.67-3.21-5.18-4.54
				c-0.79,1.57-1.72,3.43-2.66,5.3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M62.36,605.95c3.33,2.83,6.65,5.68,9.99,8.49c3.98,3.35,7.99,6.67,11.98,10"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M57.87,1337.58c-1.33,3.17-2.66,6.33-4,9.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M824.42,1260.12c6.22,3.52,5.63,10.86,9,15.99c0.25,0.39,0.66,0.67,0.99,1.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M630.66,1244.13c3.16,2.17,6.33,4.33,9.49,6.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1040.15,1343.08c0.17,3.33,0.33,6.66,0.5,9.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M255.12,1261.62c-2.02-1.95-3.38-4.61-5.84,0.33c-1.1,2.2-5.98,2.51-9.14,3.67"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M132.77,1243.63c3.18,3.95,6.16,6.55,12.14,3.84c2.93-1.33,8.06,0.05,11.18,1.91c3.36,2.01,6.11,2.88,9.64,1.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M76.34,1335.08c-0.7,5.93-1.13,11.84,1.5,17.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M157.24,1253.12c1,5.16,2,10.33,3,15.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M294.57,1267.12c-1.14,1.02-2.29,2.05-3.43,3.07c0.05-0.44,0.1-0.89,0.15-1.33c-2.07-0.41-4.18-1.24-6.19-1.04
				c-0.79,0.08-2.15,2.69-1.85,3.74c0.59,2.04,2.17,3.79,3.82,6.45c0,1.28,0,3.94,0,6.6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M810.94,1472.51c-2.83,1.67-5.66,3.33-8.49,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M543.27,904.3c-0.33,3-0.67,6-1,9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1051.14,1062.22c-1.17,3-2.33,6-3.5,9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1035.16,763.87c3,1,5.99,2,8.99,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M15.92,1334.08c1.33,2.67,2.66,5.33,4,8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M580.22,1088.21c1.83,2.67,3.66,5.33,5.49,8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M598.2,1111.19c-0.67,2.67-1.33,5.33-2,8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M132.27,1329.59c4.36,1.73,7.71,4.32,7.99,9.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M837.4,1420.54c-1.83,0-3.67,0.13-5.49-0.04c-1.85-0.17-3.67-0.63-5.5-0.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M220.67,1220.64c-5.78-2.69-8.55-1.22-8.47,6.07c-2.64-1.85-4.57-3.21-6.51-4.57"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M315.05,748.38c0.17,2.67,0.33,5.33,0.5,8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1055.63,349.08c1.29-1.38,2.57-2.75,4.41-4.72c0.22,3.53,0.4,6.37,0.59,9.22"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M32.4,1300.1c0.83,2.67,1.66,5.33,2.5,8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M135.77,1425.54c2,3.66,4,7.33,5.99,10.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M223.66,1241.63c0.93,2.8,1.86,5.59,2.79,8.39c0.27-0.15,0.54-0.29,0.81-0.44c-0.22,1.81-0.44,3.62-0.68,5.59
				c2.55-0.13,4.04-0.21,5.57-0.28c0,3.06,0,5.37,0,9.13c2.26-2.46,3.38-3.67,4.49-4.89"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M454.38,1270.61c2.27,1.62,4.55,3.21,7.49,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M153.75,1249.13c0.67,2.67,1.33,5.33,2,8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M76.84,1342.58c2,1.5,4,3,5.99,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M21.41,1340.58c-3.41,2.59-1.66,6.26-2,9.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M135.27,1477.51c-2.11-2.14-4.06-4.84-7.49-2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M89.33,1340.58c0.67,2.5,1.33,5,2,7.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M78.84,535.98c1.3-0.33,2.61-0.66,4.7-1.18c-0.89,3.1-1.54,5.39-2.2,7.68"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M523.29,1187.16c0.67,0.67,1.43,1.26,1.98,2.01c2.28,3.09,5.47,3.72,9.01,3.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M251.13,1338.58c-2.74,2.07-8.12,1.17-7.7,7.01c0.04,0.56-2.79,1.32-4.29,1.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M94.82,1336.08c1.33,2,2.66,4,4,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M250.13,947.28c0.18,8.03-1.69,9.69-8.49,7.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M98.82,1347.58c-4.49,0.5-8.99,1-13.48,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M991.71,1459.52c-0.26,5.9,3.86,5.95,7.99,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M996.71,1382.56c-1.31,4.46-5.37,8.21-3.5,13.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M540.77,1202.15c0.67,5.83,1.38,11.66,1.9,17.5c0.04,0.44-1.24,0.99-1.9,1.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M20.91,1343.08c1.17,2.33,2.33,4.66,3.5,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1118.55,1319.59c-0.83,2-1.53,4.07-2.53,5.98c-1.12,2.14-2.53,4.09-5.46,2.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M147.26,1324.09c1.33,1.5,2.66,3,4,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M195.7,1243.13c-1.98,4.48,2.14,7.29,3.17,10.95c0.54,1.93,2.83,3.38,4.33,5.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M92.82,1339.08c0.83,2,1.66,4,2.5,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M127.28,390.55c-1.59,3.4-4.05,5.15-7.97,5.65c-2.77,0.35-5.35,2.17-8.01,3.34"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M810.94,1346.08c0.33,2.17,0.67,4.33,1,6.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M203.69,1247.63c-2,0.5-4,1-5.99,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M512.8,470.51c-2.29,1.45-4.09,4.71-7.49,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M158.74,1325.09c0,2,0,4,0,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M822.92,1414.04c-0.67,1.83-1.33,3.66-2,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M126.78,1477.51c1.66,1.17,3.33,2.33,4.99,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M42.88,1320.59c3.21,3.17,2.95,6.33,0,9.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M89.83,1330.08c-0.27,3.26,1.99,4.24,4.49,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M42.88,1313.09c0,1.83,0.21,3.7-0.11,5.48c-0.12,0.63-1.55,1.02-2.38,1.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M564.74,1066.22c0.67,3,1.33,6,2,9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M999.2,1396.55c-3.88,1.34-7.21-0.17-10.49-2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M45.88,1325.59c0.67,1.17,1.33,2.33,2,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M173.22,1287.61c-2.16,0.67-4.33,1.33-6.49,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M88.33,539.48c-2.83,2-5.66,4-8.49,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M551.76,1165.67c0,1.17,0,2.33,0,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M536.77,1194.65c-0.5-0.33-1-0.67-1.5-1"/>
		
			</g>
			</svg>
		</div>
    )
}