import React from 'react';
import './Sequoia.css';

export default function Sequoia(props) {
   
    return (
		<div className={`sequoia-container ${props.size}`} >
			<svg  xmlns="http://www.w3.org/2000/svg" id={props.id}>
			<g className="sequoia-image">
				
			<path onClick={() => props.onFill(0)} fill={props.fillColors[0]} d="M-39.601-19.425c253.949,0,507.898,0,761.847,0c0,253.949,0,507.898,0,761.847
				c-253.948,0-507.898,0-761.847,0C-39.601,488.474-39.601,234.524-39.601-19.425z"/>
			<path fill-rule="evenodd" d="M353.544,20.987c1.056-1.452,2.183-1.941,3.643-0.881c1.303,0.946,1.734,1.804,0.959,3.267
				c-0.826,1.558-0.761,2.796,0.48,4.014c1.113,1.092,1.082,2.689,1.723,4.375c1.936-1.564,4.072-2.204,6.087-2.599
				c0.708-0.794,0.35-1.501,0.328-2.167c-0.051-1.565,0.871-2.371,2.21-2.772c1.419-0.425,2.251,0.31,2.743,1.583
				c0.249,0.643,0.472,1.32,1.253,1.574c0.563,0.088,0.822-0.445,1.246-0.646c2.887-1.372,5.681,0.046,6.154,3.147
				c0.299,1.958,0.515,3.931,0.737,5.9c0.112,0.988,0.193,2.003-0.28,2.923c-1.815,3.525-1.988,7.677-4.363,10.885
				c0.65,1.024,1.134,1.069,1.989,0.41c2.276-1.756,3.846-1.447,5.225,1.041c0.868,1.566,2.763,2.189,3.27,4.42
				c2.15-2.406,4.255-1.584,6.43-0.727c2.986,1.177,3.13,1.331,3.028,5.189c0.228,0.132,0.516,0.442,0.72,0.395
				c1.995-0.469,3.89-0.994,4.111,2.138c0.053,0.744,1.12,1.048,1.952,1.087c0.892,0.042,1.784,0.047,2.677,0.048
				c59.316,0.081,118.633,0.159,177.949,0.237c1.077,0.001,2.155,0,3.414,0c-0.418,1.701-1.769,1.417-2.639,1.638
				c-14.504,3.672-29.024,7.279-43.543,10.896c-7.498,1.868-15,3.725-22.499,5.593c-0.649,0.162-1.285,0.376-1.881,0.963
				c51.955,0,103.909,0,155.867,0c0,179.75,0,359.229,0,539.092c-36.499,0-72.632,0-109.186,0c1.716,3.357,3.167,6.256,4.679,9.122
				c0.457,0.865,1.453,0.961,2.284,1.238c4.136,1.379,8.247,2.84,12.421,4.09c7.543,2.26,12.352,7.064,14.66,14.634
				c1.239,4.062,2.727,8.074,4.585,11.924c1.177,2.438,2.655,4.61,4.697,6.437c3.212,2.874,5.506,6.498,7.782,10.104
				c4.663,7.383,8.797,15.065,12.738,22.853c0.354,0.7,0.815,1.373,0.661,2.196c-0.342,0.608-0.856,0.822-1.502,0.815
				c-0.794-0.008-1.587,0.008-2.381,0.008c-188.563,0-377.127,0.001-565.691-0.021c-1.325,0-2.773,0.448-4.044-0.604
				c-0.261-1.344,0.642-2.237,1.27-3.179c2.22-3.332,5.109-6.062,8.108-8.665c1.247-1.082,2.041-2.302,2.483-3.915
				c0.81-2.955,1.926-5.813,2.485-8.856c0.508-2.762,2.078-5.13,3.475-7.524c9.188-15.759,18.252-31.596,29.295-46.179
				c0.835-1.104,1.555-2.296,2.304-3.463c0.084-0.131,0.011-0.364,0.011-0.92c-30.75,0-61.515,0-92.695,0
				c0-179.846,0-359.323,0-539.242c53.152,0.02,106.408,0.166,159.722-0.368c-11.05-3.024-22.11-6.015-33.148-9.082
				c-11.002-3.057-22.066-5.902-33.514-9.29c1.366-1.095,2.378-0.795,3.285-0.795c29.757,0.003,59.515,0.025,89.272,0.046
				c14.978,0.011,29.957-0.06,44.933,0.122c2.684,0.032,4.525-0.905,6.182-2.685c1.008-1.083,1.912-2.261,2.82-3.344
				c-1.11-3.073-0.966-2.976,0.979-5.664c1.793-2.479,4.311-3.785,6.942-4.89c2.336-0.98,4.717-0.266,6.88,0.8
				c1.48,0.729,2.885,0.929,4.384,0.598c0.294-1.289-0.715-1.467-1.232-1.976c-0.863-0.85-1.104-1.852-0.832-2.997
				c0.351-1.474,1.227-2.045,2.603-1.425c1.138,0.513,2.156,0.882,3.389,0.376c1.443-0.592,1.999,0.866,2.788,1.45
				c0.352-0.103,0.644-0.101,0.703-0.217c1.732-3.407,2.449-3.795,6.166-3.62c1.45,0.068,2.163,0.776,2.407,2.124
				c0.145,0.796,0.183,1.611-0.1,2.354c-1.161,3.052-1.636,6.273-2.401,9.422c-0.091,0.375-0.28,0.744-0.493,1.071
				c-1.6,2.452-1.594,2.92,0.218,5.501c1.105-0.538,2.216-1.14,3.374-1.627c1.464-0.616,2.35,0.455,3.148,1.437
				c1.544-1.697,1.735-3.017,0.352-4.381c-1.359-1.339-1.527-2.671-0.793-4.284c0.308-0.676,0.906-1.395,1.543-1.167
				c2.091,0.747,3.583-0.542,5.272-1.235c0.534-0.218,1.136-0.271,1.598-0.375c0.194-0.289,0.409-0.46,0.394-0.607
				c-0.287-2.751,0.985-4.55,3.423-5.622c2.17-0.953,4.226-2.022,6.765-0.676c2.142,1.135,4.616,1.599,6.749,2.969
				c1.154-1.752-0.205-3.543,0.884-5.092c-1.599-1.01-3.141-1.804-3.477-3.665c-0.433-2.401,2.272-2.144,3.051-3.564
				c-0.037-0.287,0.023-0.64-0.125-0.838c-0.649-0.87-1.074-1.699-0.042-2.583c1.095-0.938,2.502-1.071,3.542-0.239
				c0.454,0.363,0.794,0.874,1.161,1.337c0.308,0.39,0.581,0.807,1.169,0.952c1.141-0.146,1.08-1.141,1.302-1.978
				c0.561-2.115,4.8-5.149,6.964-5.179c1.216-0.018,1.742,1.331,2.9,1.244c0.19-0.112,0.451-0.171,0.508-0.313
				c1.125-2.764,2.118-3.5,4.743-3.473c1.745,0.018,4.05,1.982,4.682,4c0.323,1.034,0.616,2.077,0.95,3.107
				c0.434,1.341,1.735,2.224,3.075,2.114c0.526-0.043,0.955-0.236,1.148-0.771c0.188-0.519,0.094-1.012-0.326-1.347
				c-0.539-0.431-1.122-0.818-1.721-1.163c-2.75-1.582-1.609-5.634,0.36-6.435C348.008,19.903,350.639,18.517,353.544,20.987z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M622.897,105.382c0,41.153,0,82.072,0,123.14c-2.485-0.188-4.364-0.539-4.959-3.371
				c-0.574-2.735-5.878-6.279-9.001-5.404c-2.563,0.719-5.194,1.875-7.454,3.528c-0.389,0.285-0.663,0.748-1.288,0.792
				c-1.019-0.223-1.188-1.125-1.372-1.999c-0.28-1.331-1.09-1.629-2.395-1.583c-2.261,0.078-4.53-0.092-6.796-0.141
				c-1.154-0.025-1.948-0.491-1.954-1.725c-0.006-1.08-0.72-1.557-1.465-2.062c-2.998-2.03-6.014-3.894-9.865-2.176
				c-0.628,0.279-1.306-0.005-1.863-0.58c-1.492-1.538-3.195-1.869-4.894-0.643c-1.155-0.081-1.759-1.525-2.842-0.932
				c-1.818,0.996-4.33-0.109-5.747,1.856c-1.084,1.504-2.721,1.879-4.289,2.5c-1.016-0.094-1.077-1.798-2.34-1.436
				c-0.849,0.243-1.701,0.844-2.512,0.779c-2.11-0.17-2.73,1.247-3.364,2.732c-0.704,1.65,0.268,2.808,1.171,4.031
				c0.954,1.294,1.896,2.348,3.672,1.349c0.362-0.204,0.768-0.06,1.285,0.364c-0.289,1.992,0.08,4.11,0.224,6.242
				c0.089,1.313,0.461,2.216,1.653,2.859c0.771,0.415,1.412,1.078,2.094,1.651c0.611,0.514,1.085,1.01,0.967,1.993
				c-0.274,2.269,0.697,4.011,2.64,5.211c0.168,0.104,0.329,0.222,0.486,0.343c3.468,2.665,4.021,2.697,7.924,0.359
				c1.095,1.172,3.309,1.305,3.401,3.856c-1.602,0.171-3.541-0.177-4.004,2.037c-0.386,1.851,1.058,3.035,1.789,4.502
				c-0.342,0.079-0.557,0.207-0.683,0.146c-0.956-0.456-1.292-1.872-2.614-1.749c-1.151,0.107-1.371,1.354-2.177,1.892
				c-0.409,0.273-0.896,0.434-1.292,0.722c-1.474,1.07-2.392,2.465-1.767,4.32c0.429,1.272,0.05,2.009-0.911,2.798
				c-2.137,1.752-1.85,3.641,0.663,4.916c0.261,0.133,0.529,0.25,0.757,0.356c0.448,0.972-0.039,1.751-0.305,2.602
				c-0.835,2.669,0.416,4.501,3.251,4.832c1.539,0.18,2.759,1.081,4.078,1.736c3.27,1.625,3.254,1.656,6.548,1.637
				c1.338,1.176,2.398,2.548,2.652,4.434c0.226,1.666,0.527,3.318,1.479,4.923c-0.942,0.818-1.876,1.288-2.987,1.246
				c-1.685-0.064-2.178,1.205-2.652,2.375c-0.478,1.177,0.188,2.084,1.051,2.863c0.483,0.435,1.216,0.702,1.195,1.62
				c-0.305,0.045-0.65,0.212-0.838,0.105c-1.068-0.613-2.097-1.945-3.361-0.569c-1.207,1.312-3.028,2.467-2.105,4.773
				c0.934,2.336,1.38,4.947,4.302,5.933c1.305,0.44,1.42,1.584,1.012,2.959c-1.059,3.572-0.604,6.853,2.378,9.47
				c0.434,0.38,0.73,0.915,1.043,1.317c-0.076,0.303-0.062,0.576-0.176,0.647c-2.233,1.401-3.29,3.305-2.744,5.997
				c0.113,0.561-0.059,1.183-0.12,1.774c-0.113,1.099-0.31,2.29,0.883,2.905c1.185,0.611,2.486,0.755,3.702,0.011
				c0.579-0.354,1.006-0.899,1.787-0.956c1.735-0.125,3.29-0.087,4.447,1.602c0.83,1.212,1.926,2.27,3.027,3.259
				c1.297,1.166,1.54,2.6,0.977,4.038c-0.689,1.756-0.614,3.285,0.578,4.705c1.152,1.375,0.743,3.062,0.875,4.579
				c0.169,1.938,0.994,3.275,2.678,3.885c2.942,1.066,4.6,3.608,6.793,5.528c0.599,0.524,0.779,1.331,0.898,2.145
				c0.305,2.078,1.898,3.961,3.748,3.798c3.822-0.338,5.67,2.099,7.517,4.656c0.058,1.25-1.21,1.53-1.731,2.633
				c0.382,1.431,0.378,3.035-0.397,4.628c-0.526,1.082-0.329,2.13,0.876,2.783c0.819,0.444,1.429,1.127,1.642,2.027
				c0.549,2.327,2.131,4.032,3.617,5.703c2.428,2.729,3.765,5.723,3.524,9.412c-0.159,2.46,0.164,4.94-0.222,7.624
				c-1.982-1.491-3.865,0.59-5.556,0.009c-2.097-2.254-2.984-4.957-4.574-7.264c-1.262-1.83-2.921-1.645-4.483-2.37
				c-0.285-2.643-1.557-4.314-4.393-4.74c-1.411-0.211-2.397-1.328-2.873-2.747c-0.157-0.469-0.324-0.944-0.398-1.43
				c-0.343-2.258-1.579-3.73-3.754-4.438c-0.361-0.116-0.684-0.353-1.022-0.533c-0.27-1.403,0.148-2.894-1.098-4.224
				c-1.406,0.265-2.966,0.698-3.528-1.729c-0.304-1.312-2.022-1.83-3.453-1.903c-1.345-0.069-2.377-0.391-3.384-1.449
				c-1.145-1.202-2.775-1.582-4.324-0.498c-1.132,0.792-2.116,0.696-2.733-0.482c-0.879-1.675-2.453-2.17-3.998-2.679
				c-0.776-2.237-1.641-4.41,0.359-6.409c-1.131-1.885-2.399-3.026-4.663-2.338c-0.861,0.262-1.765,0.088-2.733-0.429
				c-0.521-2.145-0.407-4.433-0.438-6.663c-0.036-2.779-1.581-5.355-0.911-8.209c0.141-0.599-0.553-0.99-1.051-1.326
				c-0.744-0.502-1.383-1.107-1.943-1.82c-1.55-1.967-3.127-2.557-5.393-1.424c-1.589,0.795-2.752,0.512-4.149-0.187
				c-2.101-1.049-4.297-1.745-5.152-4.438c-0.433-1.363-1.942-2.091-3.661-1.967c-2.12,0.153-2.113,0.032-2.475-2.13
				c-0.268-1.599-1.436-3.089-0.422-4.96c0.098-0.066,0.313-0.291,0.575-0.378c1.687-0.566,3.413-0.901,3.717-3.238
				c0.345-2.649,0.562-5.146-1.628-7.177c-0.678-0.628-1.287-1.179-2.34-0.715c-1.402,0.617-2.743,0.038-3.895-0.665
				c-1.791-1.091-3.696-1.749-5.738-2.046c-0.429-0.062-0.935-0.185-1.007-0.471c-0.439-1.762-2.253-2.478-2.958-4.013
				c-0.823-1.793-2.21-2.99-3.93-3.897c-2.234-1.179-4.097-3.232-7.004-2.759c-0.613,0.1-1.024-0.465-1.434-0.926
				c-2.617-2.938-3.363-2.991-6.605-0.553c-0.223,0.167-0.435,0.378-0.777,0.151c-0.307-0.519,0.124-0.875,0.356-1.279
				c1.477-2.566,1.469-3.012-0.538-5.192c-0.936-1.016-2.012-1.905-3.06-2.812c-1.089-0.944-2.248-1.647-3.773-0.89
				c-0.541,0.269-1.386,0.412-1.708,0.013c-1.936-2.398-5.171-2.127-7.463-3.76c-0.571-0.407-1.079-0.845-1.559-1.354
				c-1.643-1.749-3.427-1.948-5.532-0.677c-2.124,1.283-2.107,1.271-3.628-0.818c-1.604-2.206-4.27-2.297-6.531-3.175
				c-0.523-0.204-1.186,0.033-1.691-0.398c-0.896-0.765-1.824-1.906-3.091-1.148c-1.64,0.979-3.921,1.079-4.74,3.273
				c-0.396,1.06-1.131,1.847-2.137,2.383c-1.411,0.752-1.809,2.028-1.748,3.509c0.081,1.981,0.212,3.961,0.275,5.943
				c0.032,0.98,0.432,1.604,1.275,2.16c3.255,2.142,2.67,2.429,1.283,5.292c-0.95,1.964-0.786,3.213,0.482,4.768
				c1.235,1.514,1.99,3.271,2.704,5.156c1.247,3.292,3.148,6.408,6.573,8.069c0.828,0.401,1.61,0.765,1.99,1.775
				c-1.33,3.586-1.703,7.201,0.671,10.583c-2.066,3.323-2.323,6.503-0.515,9.708c0.797,1.413,2.454,2.166,3.938,2.416
				c2.052,0.345,4.377,0.994,6.043-1.202c0.377-0.498,0.99-0.621,1.627-0.295c0.643,0.621,0.189,1.295,0.003,1.916
				c-0.518,1.725-0.575,3.328,1,4.569c0.899,0.709,0.798,1.609,0.324,2.395c-0.617,1.024-0.461,1.864,0.211,2.726
				c0.304,0.39,0.75,0.817,0.571,1.306c-0.583,1.584,0.385,2.603,1.384,3.63c-1.434,2.685-1.515,5.625-2.43,8.339
				c-0.251,0.745-0.285,1.562-0.784,2.224c-0.558,0.739-1.069,1.544-0.331,2.408c0.844,0.988,1.777,1.86,3.276,1.652
				c2.997-0.414,2.999-0.394,3.78,2.365c0.108,0.381,0.319,0.751,0.345,1.136c0.162,2.431,1.255,3.853,3.864,3.904
				c1.428,0.029,1.847,1.174,1.669,2.293c-0.263,1.645-0.622,3.317-2.078,4.473c-1.019,0.809-1.789,1.851-2.357,3.03
				c-1.201,2.492-0.588,3.657,2.15,4.073c0.287,0.044,0.58,0.056,0.814,0.077c0.804,0.527,0.599,1.476,1.088,2.119
				c1.302,1.713,2.304,1.896,4.146,0.616c1.696-1.178,2.292-1.175,3.943,0.024c0.241,0.175,0.48,0.353,0.702,0.55
				c0.931,0.826,2.349,0.91,2.945,2.328c0.598,1.421-0.444,2.244-0.785,3.307c-0.55,1.716,0.423,2.794,1.462,3.701
				c-0.099,0.397-0.092,0.711-0.198,0.756c-3.481,1.45-3.159,4.879-4,7.689c-0.189,0.632,0.268,1.127,0.644,1.584
				c0.654,0.797,0.917,1.699,1.021,2.728c0.288,2.82,1.797,3.969,4.55,3.558c0.486-0.072,0.961-0.271,1.572,0.175
				c-0.488,1.691-2.335,2.534-2.567,4.419c-4.863-1.175-9.424-2.22-13.438,1.725c3.093,2.7,3.81,6.779,6.737,9.436
				c-1.606,3.008-3.242,5.828-4.275,8.942c-1.008,3.034-1.588,6.111-1.751,9.312c-0.367,7.237-0.607,14.464,0.001,21.703
				c0.51,6.062,2.943,11.473,5.489,16.855c0.204,0.434,0.567,0.81,0.92,1.147c0.19,0.184,0.513,0.229,0.743,0.324
				c0.861-0.307,0.991-1.005,1.04-1.695c0.679-9.641,3.263-18.93,5.213-28.336c0.704-3.396,1.452-6.785,2.06-10.198
				c0.419-2.361,1.634-4.007,3.382-5.671c2.438-2.319,4.763-3.054,7.926-1.801c2.003,0.792,4.276,0.782,6.504,1.684
				c-0.094,1.436,0.621,2.526,1.878,3.304c0.818,0.507,1.15,1.255,1.167,2.246c0.032,1.979,0.287,3.957-0.147,5.921
				c-2.791,0.342-5.148-0.488-6.869-2.653c-2.696,1.605-3.023,2.343-2.155,4.837c0.454,1.304,0.977,2.531,2.12,3.5
				c1.109,0.94,1.51,2.257,0.854,3.757c-1.56,3.562-0.722,6.761,1.603,9.693c0.902,1.139,1.534,2.278,0.786,3.799
				c-0.521,1.061-0.331,2.364,0.354,3.117c1.328,1.46,1.047,3.352,1.719,4.859c1.729,3.879,2.178,8.296,4.594,11.789
				c1.285,1.859,2.547,3.435,2.045,5.907c-2.917,0.512-5.104-0.223-6.567-3.241c-1.23-2.54-3.511-2.322-4.88,0.192
				c-0.604,1.112-0.669,2.296-0.373,3.47c1.014,4.019,1.836,8.055,3.517,11.928c1.398,3.225,1.369,6.854,0.491,10.695
				c-1.29-0.946-1.654-1.968-1.527-3.029c0.115-0.96,0.018-1.9-0.503-2.556c-2.603-3.271-3.731-7.354-6.057-10.761
				c-1.669-2.445-4.099-4.27-5.528-6.955c-1.24-2.33-2.453-4.721-4.911-6.199c-0.85-0.511-1.262-1.736-1.901-2.614
				c-0.882-1.211-1.854-2.298-3.599-2.298c-0.895,1.274-0.433,2.575-0.202,3.772c0.319,1.656,0.192,3.12-0.852,4.502
				c-0.737,0.976-1.28,2.224-0.881,3.349c0.934,2.634,0.642,5.508,1.788,8.082c0.682,1.529,0.671,3.091-0.257,4.553
				c-0.901,1.42-1.077,2.984-0.838,4.584c0.462,3.086,0.233,6.09-0.872,9.078c-0.914-0.023-0.93-0.9-1.198-1.425
				c-2.757-5.382-5.467-10.787-8.188-16.188c-0.536-1.063-0.993-2.106-0.84-3.391c0.354-2.964-0.607-5.683-1.858-8.303
				c-1.244-2.607-2.896-4.961-4.692-7.217c-6.322-7.938-11.563-16.581-16.581-25.375c-0.624-1.095-0.812-2.168-0.676-3.402
				c0.723-6.551-0.783-12.54-4.278-18.154c-6.497-10.438-12.862-20.957-19.234-31.472c-7.298-12.045-14.022-24.397-20.358-36.984
				c-7.799-15.493-15.991-30.79-24.062-46.145c-5.97-11.357-10.869-23.131-14.252-35.524c-3.055-11.188-6.666-22.204-10.361-33.193
				c-0.342-1.017-0.836-2.013-0.815-3.154c0.81-0.95,2.056-1.071,3.001-0.881c2.182,0.439,4.546-0.501,6.603,1.034
				c0.798,0.596,2.057,0.398,2.848-0.178c1.782-1.296,4.286-1.664,5.277-3.995c0.331-0.779,1.009-0.863,1.726-0.878
				c1.867-0.038,3.566-0.374,4.382-2.372c0.324-0.797,1.063-0.744,1.756-0.801c3.277-0.271,4.537-2.166,3.597-5.36
				c-0.08-0.273-0.053-0.578-0.073-0.839c2.753-2.374,2.903-3.413,0.902-6.438c-0.583-0.882-0.952-1.725-0.22-2.708
				c0.639-0.857,0.701-1.853,0.479-2.847c-0.808-3.615,0.628-7.152-0.124-11.085c2.459,0.723,4.256,2.224,6.529,0.677
				c1.657-1.129,4.575-0.319,4.92-3.583c3.232,1.407,6.466,1.666,9.712,1.818c2.471,0.115,4.123-2.52,2.978-4.74
				c-0.315-0.61-0.759-1.153-1.109-1.677c0.001-0.871,0.715-1.216,1.07-1.783c1.104-1.759,1.172-2.812,0.223-4.112
				c-1.079-1.476-3.16-2.338-4.691-1.572c-1.054,0.526-1.704,0.469-2.602-0.17c-0.918-0.655-1.866,0.046-2.815,0.591
				c-1.496-2.975-2.864-5.693-4.215-8.377c0.869-0.614,1.802-0.251,1.884-0.027c1.023,2.776,4.109,1.739,5.814,3.229
				c0.702,0.614,1.516,0.165,2.18-0.316c0.961-0.697,1.898-1.426,2.985-2.246c1.273,0.6,2.431,1.609,4.015,1.493
				c0.663-0.048,1.249,0.398,1.737,0.985c2.138,2.569,3.881,2.36,5.515-0.588c0.085-0.153,0.219-0.279,0.473-0.594
				c1.247,1.55,2.514,3.153,4.692,3.229c2.056,0.071,3.845-0.692,5.221-2.109c1.147,0.519,1.248,1.483,1.658,2.237
				c0.938,1.725,1.797,2.237,3.258,1.92c1.667-0.361,2.224-1.48,2.092-3.082c-0.023-0.276,0.103-0.564,0.146-0.782
				c0.698-0.674,1.301-0.376,1.941-0.036c1.463,0.776,2.685,0.23,3.77-0.794c1.046-0.986,0.774-2.069,0.325-3.272
				c-0.74-1.985-0.603-2.156,1.416-3.063c1.973-0.887,2.374-1.5,1.986-3.029c-0.418-1.649-1.088-2.221-3.005-2.05
				c-2.164,0.193-3.887-1.12-5.826-1.623c-2.034-0.528-2.364-6-0.666-7.432c0.782-0.66,1.499-1.285,1.79-2.283
				c0.479-1.637,1.595-2.02,3.2-1.901c3.017,0.222,4.312-1.128,4.184-4.167c-0.021-0.488-0.192-0.969-0.273-1.456
				c-0.669-4.011-0.668-4.011-4.079-5.377c-0.99-4.257-2.737-5.093-6.097-2.829c-1.511-0.402-2.67-1.526-4.044-2.208
				c-0.028-1.071,0.634-1.362,1.35-1.775c2.164-1.252,2.597-2.419,1.808-4.81c-0.21-0.638-0.141-1.14,0.079-1.697
				c0.036-0.092,0.078-0.182,0.114-0.274c0.978-2.528,3.53-4.627,1.741-7.87c-1.338-2.426-2.273-5.087-4.889-6.278
				c-0.238-0.937,0.192-1.527,0.387-2.18c0.668-2.245-0.827-4.425-3.167-4.596c-0.463-0.034-0.959,0.306-1.392-0.106
				c-2.338-2.23-4.795-0.89-7.41-0.323c-0.793-0.458-0.514-1.443-0.668-2.271c-0.372-1.999-1.281-2.669-3.07-1.995
				c-3.298,1.241-6.062,3.123-7.12,6.728c-0.326,0.181-0.568,0.064-0.827-0.11c-2.093-1.401-3.513-1.321-4.658,0.737
				c-0.713,1.28-1.559,1.918-2.863,2.236c-1.496,0.364-1.838,1.638-2.016,3.066c-2.537-0.236-4.096-1.57-5.423-3.588
				c0.314-1.188,1.197-2.372,0.836-3.837c-0.183-0.742,0.37-1.355,0.935-1.75c1.55-1.082,2.212-2.561,2.778-4.356
				c0.597-1.891,1.312-3.844,2.99-5.313c1.874-1.641,2.613-3.977,2.349-6.459c-0.163-1.53-0.186-2.774,0.88-4.135
				c1.133-1.446,0.714-3.212-0.363-4.703c-1.48-2.048-1.483-2.036-0.052-4.179c0.322-0.482,0.571-1.014,0.896-1.6
				C494.007,105.382,558.267,105.382,622.897,105.382z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M219.203,435.961c-1.421-0.351-1.973-0.958-1.744-2.031
				c0.295-1.388-0.093-2.991,1.456-3.982c1.882-1.202,2.262-2.214,1.414-4.099c-1.243-2.766-1.252-5.505-0.333-8.34
				c0.393-1.21,0.467-2.477,0.174-3.853c-3.207,2.249-6.434,4.203-8.382,7.607c-0.649,1.135-1.787,1.815-2.849,2.562
				c-1.912,1.345-3.407,2.936-3.521,5.531c-0.098,2.234-1.659,3.847-3.231,5.144c-1.959,1.614-2.873,3.378-2.471,5.913
				c0.151,0.954,0.071,1.967,0.12,2.954c0.144,2.926-1.429,4.83-4.379,5.391c-1.249,0.237-2.476,0.463-3.814,0.221
				c-1.705-0.309-3.23,0.36-3.742,2.155c-0.367,1.288-1.221,1.895-2.312,1.952c-2.701,0.141-3.642,1.981-3.824,4.128
				c-0.229,2.691-0.997,4.738-3.636,5.917c-1.06,0.475-1.771,1.563-2.32,2.647c-0.551,1.087-1.337,2.014-2.358,2.632
				c-2.033,1.231-2.541,3.179-2.736,5.314c-0.036,0.393,0.026,0.794,0.007,1.189c-0.147,3.055-0.633,5.821-3.901,7.349
				c-2.136,0.998-2.811,3.281-3.23,5.431c-0.313,1.606-0.933,2.77-2.063,4.046c-1.586,1.79-3.116,4.018-2.993,6.603
				c0.108,2.265-1.004,3.488-2.657,4.568c-2.083,1.361-3.932,2.992-5.47,4.95c-0.292,0.371-0.411,0.877-0.598,1.293
				c1.321,1.034,1.321,1.034,3.322,1.258c-0.414,1.416-0.766,2.823-1.239,4.188c-0.596,1.718-1.636,2.985-3.675,2.849
				c-2.044-0.138-3.095,0.999-3.724,2.747c-0.355,0.985-0.566,2.06-1.692,2.947c-0.187-2.397,0.848-4.065,1.83-5.769
				c2.521-4.371,4.849-8.829,5.816-13.854c0.168-0.872,0.416-1.752,0.419-2.628c0.009-2.408,0.671-4.488,2.6-6.053
				c0.229-0.186,0.452-0.408,0.598-0.659c1.931-3.336,3.86-6.673,5.759-10.026c0.619-1.093,0.66-2.071-0.083-3.303
				c-0.845-1.401-1.725-2.881-2.081-4.714c1.52-1.316,1.66-3.068,1.627-5.045c-0.03-1.836,0.509-3.654-0.199-5.505
				c-0.285-0.744,0.214-1.535,0.616-2.231c0.518-0.896,0.94-1.82,0.003-2.693c-0.944-0.88-2.075-1.259-3.276-0.646
				c-1.211,0.616-2.343,1.391-3.655,2.184c-0.328-1.399-0.376-2.445,0.651-3.421c0.704-0.668,4.658-3.486,5.496-4.008
				c1.549-0.966,3.177-0.829,4.624-0.608c0.792-0.475,1.014-1.04,0.956-1.651c-0.256-2.713-0.167-5.544-1.782-7.909
				c-0.865-1.267-0.166-2.772-1.041-4.121c1.984-1.597,2.766-3.516,1.01-5.773c1.301-2.002,2.408-3.693,5.004-4.353
				c2.407-0.61,3.496-3.13,3.982-5.528c0.335-1.651,1.062-2.654,2.455-3.719c1.999-1.528,4.077-3.364,5.315-5.461
				c1.726-2.921,3.597-6.083,2.549-9.935c-0.463-1.7,0.081-2.141,2.07-2.717c1.178-0.341,2.177-0.911,2.887-2.175
				c-1.398-1.865-1.677-3.728,0.359-5.412c0.277-0.229,0.324-0.716,0.537-1.046c0.543-0.842-0.005-2.174,0.944-2.712
				c1.444-0.817,2.547-2.278,4.46-2.295c0.657-2.024,1.763-3.822,1.728-5.984c-0.01-0.602,0.106-1.292,0.589-1.639
				c2.397-1.721,3.926-4.438,6.75-5.695c1.533-0.683,2.786-1.713,3.331-3.554c0.455-1.536,1.917-2.507,3.102-3.533
				c1.791-1.554,3.387-3.029,2.46-5.77c-0.224-0.659,0.112-1.57,0.353-2.313c0.667-2.06,0.86-4.064-0.372-5.802
				c0.229-0.898,0.835-0.941,1.37-1.201c1.953-0.95,2.492-2.511,1.34-4.323c-0.782-1.232-1.036-2.476-0.379-3.632
				c1.106-1.947,1.161-3.911,0.575-5.783c0.489-0.774,1.142-0.791,1.679-1.002c1.938-0.76,3.224-1.871,2.781-4.241
				c-0.14-0.749,0.155-1.57,0.186-2.361c0.112-2.938,0.102-2.938,3.074-4.046c2.012-0.75,2.678-2.024,2.461-4.713
				c0.258-0.343,0.613-0.57,1.017-0.401c1.556,0.651,2.163-0.434,2.759-1.487c0.614-1.084-0.073-1.627-0.754-2.479
				c-1.134-1.416-2.154-3.023-2.739-4.977c2.566-2.157,3.926-5.132,5.504-7.958c0.74-1.325,1.17-2.715,0.662-4.273
				c-0.315-0.963-0.302-1.917,0.337-2.834c0.254-0.364,0.284-0.941,0.278-1.419c-0.023-1.935-0.766-2.516-2.794-2.199
				c-0.397,0.062-0.758,0.194-1.254-0.319c3.656-3.33,1.037-6.592-0.256-9.893c-0.285-0.728-0.999-0.948-1.721-0.955
				c-0.99-0.009-2.024-0.115-2.96,0.126c-1.581,0.406-3.099,0.503-4.641-0.051c-0.88-0.316-1.762-0.258-2.62,0.065
				c-1.118,0.421-2.268,0.554-3.543,0.255c-0.72,2.163-2.07,3.368-4.246,3.653c-0.763,0.1-1.403,0.691-1.996,1.241
				c-0.503,0.467-1.033,1.135-1.629,1.232c-3.015,0.493-3.79,2.354-3.414,5.075c0.036,0.26-0.164,0.553-0.283,0.919
				c-1.321,0.233-2.615,0.003-4.013,0.788c-3.148,1.769-3.323,1.611-2.785,5.084c-0.642,1.31-1.942,1.586-3.15,2.018
				c-3.628,1.298-3.656,1.365-2.424,5.09c-3.866-0.552-6.785,2.323-10.545,2.893c-0.523-5.939,1.594-12.121-1.719-17.612
				c1.036-2.15,2.012-4.172,3.033-6.291c-2.208-1.285-3.706,0.169-5.328,0.93c-0.453,0.07-0.754-0.205-1.022-0.489
				c-2.819-2.986-6.301-3.501-10.116-2.685c-2.13,0.455-4.253,0.949-6.357,1.507c-0.914,0.242-1.994,0.368-2.313,1.528
				c0.245,0.95,1.057,0.843,1.698,0.912c3.251,0.349,5.723,2.581,8.715,3.585c1.387,0.465,1.607,2.449,0.556,3.778
				c-1.66,2.098-3.427,4.113-5.182,6.134c-0.971,1.118-2.01,2.176-3.015,3.259c0.099,1.218,1.457,1.771,1.164,3.167
				c3.79-2.397,6.652-5.773,10.605-7.537c1.61,0.398,2.262,1.444,2.332,3.027c0.066,1.483,0.14,2.991,0.459,4.432
				c0.533,2.398-0.675,3.858-2.542,4.801c-1.593,0.804-3.198,1.539-4.635,2.619c-0.457,0.343-1.063,0.537-1.631,0.676
				c-2.956,0.721-2.959,0.707-4.339,3.723c0.103,1.107,1.587,1.557,1.381,2.71c-0.147,0.471-0.464,0.493-0.746,0.511
				c-4.424,0.282-4.425,0.28-6.395,4.518c1.515,3.203,1.804,3.403,4.678,2.972c-1.255,1.917-1.842,4.222-3.634,5.283
				c-1.812,1.072-3.981,1.774-6.245,1.678c-0.89-0.038-1.791-0.035-2.674,0.066c-1.369,0.157-2.547,0.549-3.723,1.524
				c-1.199,0.994-2.542,2.039-4.008,2.647c-3.175,1.316-5.133,3.296-4.703,6.979c0.034,0.285-0.062,0.588-0.118,0.878
				c-0.016,0.085-0.1,0.156-0.266,0.4c-3.422,0.417-6.949-0.308-10.482-0.254c-0.736,0.012-1.087-0.76-1.545-1.282
				c-2.161-2.461-4.614-2.209-6.526,0.487c-1.068,1.505-1.874,3.014-1.488,5.104c-2.711,0.28-4.402,2.587-7.168,2.686
				c-1.374,0.049-2.539,1.361-2.61,2.876c-0.083,1.745-0.951,2.408-2.487,2.767c-3.025,0.707-3.575,2.537-2.536,5.432
				c0.96,2.675,3.331,4.386,4.157,7.083c0.249,0.812,1.064,1.435,1.868,1.857c1.003,0.528,1.293,1.399,1.434,2.354
				c-3.441,2.636-3.418,2.596-7.066,0.531c-1.763-0.998-3.607-1.431-5.694-0.699c-3.167,1.11-6.403,2.028-9.626,2.974
				c-1.313,0.385-2.6,0.793-4.048,0.211c-2.4-0.964-4.647-0.105-5.298,2.453c-1.151,4.524-3.838,8.502-4.466,13.188
				c-0.131,0.983-0.708,1.837-1.27,2.664c-1.365,2.014-1.191,3.931,0.755,5.345c1.058,0.769,1.788,1.377,1.551,2.832
				c-0.244,1.499,1.133,1.998,2.115,2.517c1.1,0.581,1.479,1.36,1.081,2.407c-0.915,2.401,0.354,4.104,1.852,6.191
				c-2.861,0.934-3.853,4.201-6.8,5.258c-1.237,0.443-1.965,1.445-2.206,2.915c-0.37,2.262-0.65,2.349-3.182,2.373
				c-0.374-0.955-0.196-1.958-0.197-2.938c-0.014-14.681-0.011-29.361-0.012-44.042c0-1.286,0.222-2.573,0.245-3.833
				c0.034-1.908,0.204-3.371,2.552-3.439c0.262-0.008,0.607-0.211,0.759-0.431c1.214-1.757,2.866-1.737,4.744-1.419
				c1.261,0.215,2.578,0.124,3.194-1.464c0.5-1.288,1.294-1.423,2.563-0.658c1.962,1.182,3.941,0.668,5.416-1.267
				c2.027-2.66,3.78-5.496,4.986-8.617c0.958-0.407,2.009-0.152,2.908-0.688c0.864-0.516,1.78-0.884,1.67-2.165
				c-0.09-1.051,0.562-1.88,1.048-2.732c0.99-1.735,2.058-3.415,0.861-5.52c-0.204-0.359-0.169-1.122,0.083-1.427
				c1.571-1.899,1.111-4.1,0.925-6.431c1.375-0.219,2.731,0.221,3.897-0.849c1.524-1.398,0.144-2.446-0.247-3.473
				c0.797-0.831,1.468-0.147,2.119-0.228c1.955-0.241,3.559-0.945,4.651-2.753c1.429-2.366,2.193-5.119,4.194-7.154
				c0.646-0.656,0.417-1.461,0.195-2.244c-0.188-0.661-0.646-1.479-0.425-1.98c0.91-2.053,0.662-4.215,0.704-6.208
				c0.954-0.651,1.386-0.029,1.98,0.328c2.148,1.289,3.481,0.833,4.515-1.51c0.396-0.899,0.998-1.589,1.947-1.592
				c1.434-0.005,2.061-0.951,2.798-1.901c0.726-0.937,1.545-1.804,2.343-2.684c0.672-0.742,1.329-1.478,1.411-2.546
				c0.109-1.43,0.494-2.717,1.736-3.628c0.286-0.21,0.483-0.629,0.583-0.991c0.429-1.553-0.29-6.353-1.327-8.293
				c1.857,0.594,3.433,1.244,5.016,1.779c2.308,0.782,4.505,0.308,6.747-0.028c0.379-0.057,0.906-0.19,1.068-0.464
				c1.681-2.824,5.328-4.232,5.742-7.972c0.041-0.368,0.332-0.708,0.583-1.215c1.767,0.026,3.188-0.627,3.706-2.567
				c0.162-0.604,0.298-1.215-0.121-1.698c-2.271-2.626-1.679-5.918-2.107-8.966c-0.063-0.452,0.084-1.007,0.314-1.412
				c0.845-1.483,0.42-2.857-0.529-4.548c2.193,0.499,4.091,0.677,5.965,0.941c0.972,0.137,1.699,0.089,2.489-0.432
				c1.484-0.976,2.962-1.976,4.524-2.815c1.011-0.543,1.61-1.409,1.53-2.358c-0.201-2.399,0.629-5.077-1.594-7.037
				c-0.205-0.18-0.46-0.633-0.38-0.757c1.303-2.002-0.907-4.461,0.875-6.427c0.417-0.46,0.461-1.257,0.764-2.156
				c2.045,1.53,4.619,1.438,6.299,3.37c0.734,0.845,2.518,0.469,3.649-0.849c0.805-0.939,1.489-1.183,2.723-1.175
				c1.761,0.012,3.035-1.442,4.207-2.569c1.518-1.459,2.982-2.364,5.191-2.051c1.034,0.146,2.153-0.078,3.249-0.029
				c2.28,0.102,3.616-1.597,2.958-3.856c-0.207-0.709-0.366-1.276-0.068-1.954c0.847-1.918,1.114-3.965,1.188-5.875
				c0.431-0.578,0.89-0.718,1.391-0.817c2.794-0.556,3.659-2.976,1.805-5.172c-1.245-1.475-1.949-3.419-4.014-4.208
				c-0.805-0.308-0.818-1.501-1.167-2.416c1.062-1.33,2.063-2.649,3.806-3.141c0.893-0.252,1.479-0.96,2.145-1.553
				c1.143-1.016,1.436-2.087,0.396-3.351c-1.116-1.357-1.435-2.741-0.292-4.299c0.481-0.657,0.557-1.56,0.131-2.276
				c-0.907-1.524-0.208-2.545,0.792-3.678c2.015-2.284,2.287-4.665,0.97-6.758c-0.576-0.917-1.22-1.543-2.426-1.064
				c-1.416,0.562-2.23-0.053-2.703-1.42c-0.642-1.859-1.825-2.767-3.891-2.076c-0.435,0.146-1.087,0.17-1.441-0.057
				c-2.135-1.37-4.653-0.613-6.903-1.332c-1.488-0.476-2.666,0.328-3.771,1.253c-0.587,0.491-0.585,1.532-1.489,1.645
				c-0.802-0.105-0.535-0.733-0.705-1.144c-1.047-2.531-3.191-2.412-5.292,0.373c-1.059-0.64-1.827-1.786-3.163-2.004
				c-1.493-0.244-2.152,1.228-3.487,1.763c-0.557-1.134,0.075-2.194-0.118-3.199c-0.184-0.96-0.134-1.965-0.186-2.922
				c-2.485-1.19-3.423-1.139-4.869,0.646c-0.718,0.887-1.539,1.175-2.551,1.239c-0.919,0.058-1.893,0.211-2.413,0.968
				c-1.895,2.754-4.929,2.971-7.762,3.591c-1.007,0.221-1.873,0.511-2.622,1.236c-1.057,1.024-2.305,1.531-3.799,1.071
				c-1.532-0.472-2.905,0.2-4.138,0.655c-0.367-0.126-0.607-0.138-0.729-0.263c-0.849-0.878-1.371-2.279-3.083-1.556
				c-1.795,0.757-3.616,1.538-3.567,3.84c0.035,1.656-0.65,2.668-1.794,3.781c-1.602,1.56-2.195,4.005-4.539,5.301
				c-1.278-0.423-2.71-1.06-4.315-1.128c-1.222-0.053-2.108-0.897-2.963-1.798c-2.253-2.375-3.634-2.299-6.562-1.163
				c-2.238,0.869-4.217,2.361-6.695,2.697c-1.675,0.228-2.746,1.914-2.378,3.823c0.303,1.574,0.155,2.467-1.654,2.679
				c-1.372,0.161-1.814,1.394-2.286,2.495c-1.204,2.815-2.313,3.057-4.483,0.971c-0.071-0.068-0.114-0.165-0.185-0.233
				c-2.075-1.984-3.198-2.052-5.558-0.337c-3.496-1.377-6.74-0.493-10.043,0.871c-1.974,0.815-4.031,1.378-5.857,2.568
				c-0.637,0.415-1.538,0.3-2.507-0.115c0-37.668,0-75.42,0-113.325c1.182-0.593,2.482-0.343,3.727-0.344
				c26.882-0.015,53.763-0.011,80.645-0.011c31.841,0,63.682,0.004,95.524-0.019c1.844-0.001,3.521-0.002,4.595,1.926
				c0.622,1.118,1.845,1.535,3.113,1.254c1.713-0.38,2.575,0.546,3.262,1.843c-1.415,3.582-1.582,7.153-0.079,11.01
				c-5.502,3.242-3.704,7.994-2.208,12.674c-2.563,0.002-3.103-0.488-2.79-2.482c0.107-0.682,0.353-1.348,0.417-2.031
				c0.276-2.96-1.254-4.53-4.175-4.317c-0.493,0.036-0.984,0.131-1.469,0.229c-1.375,0.279-2.418-0.19-2.792-1.542
				c-0.747-2.707-2.79-3.716-5.146-4.843c-2.894-1.385-6.112-1.485-8.987-2.927c-1.37-0.687-3.118-0.727-4.672-1.418
				c-2.022-0.9-3.658,0.472-3.759,2.737c-0.13,2.926-0.124,2.953-2.959,3.556c-1.01,0.215-1.808,0.779-2.657,1.282
				c-1.248,0.738-2.32,1.567-2.044,3.267c0.251,1.55,1.166,2.557,2.654,2.84c1.54,0.293,2.393,1.303,3.145,2.487
				c1.096,1.727,0.429,3.336-1.556,3.756c-1.064,0.226-2.152,0.337-3.217,0.559c-2.208,0.458-3.757,2.21-3.445,4.31
				c0.204,1.372,0.517,2.633-0.021,4c-0.302,0.767,0.705,1.409,0.473,2.279c-0.53,0.879-1.505,0.765-2.339,1.008
				c-2.903,0.845-3.829,3.497-1.788,5.695c1.19,1.281,1.936,2.67,2.602,4.231c1.08,2.529,3.646,4.059,6.256,3.85
				c0.926-0.075,1.452,0.252,1.953,0.99c0.914,1.346,2.304,1.883,3.869,1.676c0.977-0.129,1.716,0.224,2.527,0.601
				c2.498,1.162,2.505,1.145,5.064,0.765c1.754,3.745,3.93,4.194,6.97,1.102c1.226,0.682,1.543,2.268,2.87,2.697
				c-0.573,3.897-3.266,7.16-1.87,11.564c-3.102-0.012-5.212,2.328-8.346,2.356c-1.809,0.017-3.802,1.021-5.317,2.35
				c-1.403,1.229-1.581,2.04-1.711,5.931c-1.417,0.245-3.079,0.298-3.518,2.277c-0.446,2.012-0.056,3.651,1.363,5.196
				c1.226,1.334,2.33,2.637,4.3,2.914c1.055,0.148,1.67,0.913,1.478,2.17c-0.27,1.759,0.111,3.329,1.567,4.549
				c1.252,1.05,0.934,2.522,0.84,3.891c-0.085,1.236-0.021,2.365,1.1,3.192c1.673,1.235,3.263,1.124,4.709-0.561
				c0.795-0.925,1.385-1.809,2.884-1.59c1.673,0.245,3.025,0.912,4.111,2.146c0.598,0.681,0.446,1.519,0.152,2.262
				c-0.774,1.962-0.337,3.743,0.873,5.313c0.705,0.915,0.708,1.745,0.418,2.759c-0.216,0.752-0.302,1.554-0.349,2.339
				c-0.091,1.521-0.654,2.482-2.219,3.113c-2.299,0.927-2.835,2.803-1.576,5.009c0.519,0.909,0.359,1.646,0.211,2.531
				c-0.419,2.503,0.333,3.538,2.821,3.704c1.389,0.092,2.462,0.482,3.291,1.65c0.828,1.166,2.057,1.066,3.24,0.686
				c1.56-0.502,2.121-1.549,1.27-2.936c-1.29-2.101-0.726-3.904,0.277-5.824c1.222-0.012,1.754,1.024,2.661,1.367
				c2.043,0.772,3.754,0.277,4.652-1.696c0.617-1.355,1.349-2.068,2.94-1.789c1.281,0.224,1.956-0.629,2.405-1.746
				c0.764-1.9,0.865-3.408,0.232-5.967c0.954-0.462,2.29-0.573,2.579-1.98c0.272-1.323,0.217-2.545-0.649-3.667
				c-0.753-0.974-0.92-2.164-1.112-3.328c0.468-0.349,0.989-0.062,1.458-0.175c2.096-0.508,2.889-2.104,1.992-4.047
				c-0.247-0.536-0.628-1.008-0.922-1.525c-0.498-0.877-0.18-1.798,0.637-1.934c2.961-0.491,3.047-2.667,3.053-4.803
				c1.49-1.334,3.168,0.445,4.649-0.594c1.243-0.871,2.136-2.038,3.105-3.147c1.208,0.208,1.23,1.205,1.547,1.938
				c-0.072,0.596-0.559,0.797-0.91,1.131c-1.646,1.566-1.879,2.085-0.497,3.73c1.394,1.659,0.267,3.182,0.533,4.613
				c-0.86,0.624-1.507-0.401-2.383,0.386c-0.904,1.361-0.603,3.112-0.249,4.673c-0.676,1.59-3.021,0.764-3.213,2.526
				c-0.168,1.533-0.227,3.128,0.009,4.643c0.28,1.795,0.965,2.057,4.366,2.514c-2.348,0.499-3.366,3.062-5.999,3.386
				c-1.804,0.221-2.461,2.241-1.916,4.151c0.873,3.056,0.142,5.229-2.393,7.114c-0.567,0.421-2.674,5.521-2.535,6.224
				c0.155,0.78,0.37,1.525,1.062,2.057c2.108,1.621,3.93,3.343,4.463,6.228c0.37,2.001,4.054,2.4,5.386,0.498
				c1.304-1.862,2.78-1.954,4.629-1.438c1.555,0.434,2.497,1.17,2.614,3.059c0.164,2.617,1.543,3.646,4.18,3.355
				c1.083-0.12,1.889-0.044,2.691,0.697c0.504,0.466,1.171,0.862,1.882,0.777c1.698-0.203,3.042,0.365,4.358,1.427
				c1.16,0.937,2.691,0.363,4.05,0.492c1.761,0.166,3.349,0.117,4.564,2.006c1.122,1.743,3.547,1.363,5.13-0.368
				c1.274-1.394,1.274-1.394,3.262-0.754c-0.096,2.04-0.408,4.075-1.198,6.001c-2.145,5.226-4.244,10.471-7.141,15.353
				c-1.434,2.416-2.102,5.128-2.591,7.868c-0.58,3.25-1.812,6.246-3.125,9.26c-3.968,9.109-9.563,17.341-13.929,26.229
				c-3.363,6.846-6.974,13.6-9.84,20.653c-2.688,6.614-7.083,11.86-11.63,17.098c-0.909,1.047-1.943,1.984-2.863,3.023
				c-0.929,1.048-1.815,2.227-1.967,3.615c-0.356,3.26-1.964,6.021-3.341,8.854c-4.815,9.909-10.245,19.453-16.507,28.551
				c-3.077,4.47-5.386,9.466-8.056,14.217C222.976,429.335,221.113,432.595,219.203,435.961z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M344.158,438.469c0.395,3.418,2.694,5.624,4.621,8.127
				c2.421,3.144,3.898,6.889,5.726,10.396c0.789,1.515,0.797,3.446,1.11,5.197c0.365,2.035,1.395,3.323,3.473,3.974
				c3.574,1.118,6.089,3.507,7.784,6.892c2.396,4.785,4.777,9.551,6.426,14.673c0.876,2.724,2.207,5.3,3.301,7.957
				c1.099,2.668,1.99,5.383,1.542,8.936c-0.687-1.489-1.138-2.507-1.622-3.508c-0.65-1.343-1.32-2.742-2.435-3.713
				c-2.868-2.499-4.342-5.849-5.986-9.125c-0.549-1.094-0.808-2.47-2.114-3.02c-0.945,0.43-0.724,1.157-0.613,1.825
				c0.492,2.983,1.602,5.723,3.357,8.183c1.76,2.464,2.931,5.12,3.313,8.169c0.423,3.359,1.731,6.501,2.758,9.708
				c0.725,2.266,1.43,4.532,1.663,6.92c0.249,2.563-0.6,4.691-2.475,6.4c-2.471,2.254-5.303,3.793-8.676,4.222
				c-1.18,0.149-2.38,0.264-3.518,0.584c-2.154,0.605-3.621,2.023-4.228,4.205c-0.412,1.484,0.181,2.329,1.747,2.346
				c1.178,0.012,2.376-0.171,3.533-0.416c1.588-0.335,3.124-0.246,4.716,0.01c2.843,0.456,4.669,1.957,5.562,4.693
				c0.871,2.669,2.186,5.063,4.246,7.059c1.557,1.508,3.289,2.527,5.429,2.83c4.021,0.569,8.043,1.142,12.068,1.68
				c2.743,0.366,4.926,1.474,6.93,3.56c3.428,3.567,7.283,6.722,10.935,10.076c3.952,3.628,7.734,7.39,10.485,12.086
				c1.466,2.5,3.679,4.412,5.858,6.293c3.154,2.72,6.372,5.366,9.542,8.067c3.542,3.018,6.091,6.619,7.596,11.135
				c3.101,9.303,6.831,18.396,9.404,27.876c2.131,7.847,3.765,15.816,5.025,23.84c0.939,5.981,0.596,12.021-0.329,18.184
				c-1.188-0.931-1.434-1.895-1.269-3.069c0.675-4.807-0.177-9.486-1.287-14.133c-0.555-2.322-0.884-4.652-0.968-7.049
				c-0.177-5.026-1.391-9.777-4.229-14.022c-0.329-0.492-0.639-1.016-0.849-1.565c-2.545-6.661-5.973-12.969-7.931-19.864
				c-0.865-3.047-2.449-5.617-5.063-7.49c-0.727-0.521-1.316-1.116-1.7-1.994c-2.436-5.571-5.96-10.231-11.224-13.493
				c-2.318-1.438-3.874-3.602-5.077-6.081c-1.946-4.011-4.075-7.934-6.109-11.903c-2.817-5.498-7.668-9.014-12.385-12.632
				c-5.553-4.259-11.872-6.25-18.88-5.956c-0.986,0.041-1.97,0.195-2.948,0.341c-2.803,0.415-5.436,0.464-7.887-1.497
				c-1.863-1.491-4.241-1.579-6.552-1.044c-1.832,0.425-3.333,1.321-3.792,3.333c-0.184,0.801-0.571,1.478-1.111,2.07
				c-1.108,1.218-1.795,1.231-2.941,0.087c-3.704-3.694-9.593-3.797-13.694-0.601c-2.344,1.826-4.33,3.699-5.15,6.639
				c-0.658,2.357-0.527,4.733-0.233,7.085c0.481,3.845,2.2,7.275,4.671,10.134c3.151,3.646,9.759,5.82,14.629,0.368
				c1.12-1.255,2.341-2.408,3.332-3.794c1.083-1.516,1.983-1.562,3.477-0.387c1.1,0.867,1.961,1.922,2.563,3.225
				c2.211,4.778,5.628,8.591,9.671,11.895c1.304,1.064,2.584,2.161,3.827,3.296c6.003,5.48,10.288,4.585,14.788,1.812
				c2.47-1.523,4.774-3.101,6.489-5.542c1.945-2.77,4.614-3.269,7.535-1.55c2.738,1.612,5.881,2.274,8.582,4.039
				c2.055,1.341,4.132,0.008,5.417-3.167c7.87,5.099,13.219,11.604,13.149,21.46c-0.021,2.954-0.123,5.89-0.442,8.856
				c-0.594,5.507,0.932,10.537,4.792,14.794c3.013,3.322,4.772,7.25,4.553,11.888c-0.074,1.578-0.011,3.163-0.011,4.505
				c-0.612,0.77-1.275,0.617-1.812,0.816c-1.387,0.514-2.26,0.049-2.769-1.34c-2.003-5.463-4.923-10.369-8.926-14.62
				c-0.536-0.57-0.951-1.269-1.356-1.947c-2.006-3.366-7.361-5.116-10.849-3.526c-2.485,1.134-3.817,4.568-2.527,7.75
				c0.904,2.231,0.671,4.371,0.739,6.805c-1.622-0.446-2.649-1.298-3.194-2.635c-1.163-2.854-2.787-5.535-4.455-8.016
				c-3.728-5.545-8.609-9.85-15.856-10.175c-1.301-0.058-2.555-0.384-3.757-0.85c-2.231-0.864-4.494-0.697-6.736-0.204
				c-3.994,0.879-6.494,4.537-5.935,8.556c-0.614,0.566-1.252-0.691-1.874,0.067c-0.683,0.907,0.07,1.661,0.474,2.371
				c1.005,1.771,1.387,3.68,1.631,5.664c0.351,2.853,0.586,5.745,1.264,8.525c1.321,5.417,4.145,10.068,8.23,13.852
				c2.152,1.994,4.837,2.988,7.811,3.289c7.888,0.799,15.732,1.896,23.436,3.847c1.58,0.399,3.149,0.26,4.652-0.423
				c1.546-0.703,3.16-1.146,4.851-1.348c2.685-0.321,5.102,0.252,7.266,1.93c2.82,2.187,5.692,4.307,8.526,6.477
				c2.229,1.706,4.109,3.749,5.491,6.194c3.157,5.587,4.904,11.647,6.016,18.091c-2.233,0.413-4.34,0.338-6.382,0.082
				c-0.542-1.031-0.233-1.887-0.044-2.744c0.68-3.077-0.307-5.51-2.985-7.125c-2.348-1.415-3.096-3.788-3.895-6.111
				c-0.928-2.694-2.275-4.99-4.611-6.747c-3.823-2.874-7.944-3.068-12.073-1.201c-2.801,1.267-5.35,1.782-8.224,0.438
				c-1.513-0.707-3.188-1.065-4.793-1.574c-2.459-0.78-4.998-1.016-7.151,0.542c-2.911,2.107-6.327,2.723-9.601,3.776
				c-1.423,0.457-2.854,0.885-4.107,1.689c-1.947,1.25-2.449,3.452-0.94,5.069c3.671,3.934,3.663,8.757,3.795,13.428
				c-0.673,0.888-1.336,0.762-2.064,0.547c-0.088-0.253-0.247-0.521-0.268-0.8c-0.102-1.382-0.156-2.768-0.247-4.151
				c-0.229-3.521-1.938-6.152-4.212-8.875c-4.303-5.154-7.036-11.142-7.045-18.146c-0.005-4.17-0.547-8.443-2.436-12.132
				c-1.923-3.755-2.478-7.697-3.161-11.682c-0.369-2.147-0.691-4.315-0.835-6.487c-0.18-2.725-1.395-4.689-3.747-6.011
				c-0.422-0.237-0.804-0.547-1.354-0.927c1.732-3.201,0.221-5.846-1.607-8.223c-1.883-2.447-2.92-5.087-3.369-8.109
				c-0.528-3.555-2.682-6.163-5.66-7.997c-1.281-0.789-2.174-1.562-2.315-3.137c-0.141-1.562-1.206-2.642-2.21-3.771
				c-2.623-2.952-4.606-6.139-4.16-10.403c0.237-2.273-0.784-3.177-3.102-3.44c-4.786-0.545-9.229-5.114-9.594-9.86
				c-0.076-0.986-0.092-1.987-0.285-2.952c-0.325-1.617-1.209-2.928-2.584-3.854c-1.589-1.071-2.667-0.647-2.887,1.219
				c-0.138,1.169-0.014,2.371,0.018,3.558c0.075,2.774,0.321,5.566-1.014,8.165c-0.135,0.264-0.155,0.64-0.358,0.808
				c-2.551,2.107-2.484,5.426-3.78,8.108c-0.872,1.804-0.625,3.549,0.64,5.003c2.833,3.257,4.488,7.235,6.788,10.815
				c2.344,3.648,1.731,6.96-1.438,10.11c-0.486,0.483-0.896,1.041-1.573,1.834c-0.167-1.55,0.425-2.622,0.862-3.697
				c1.517-3.731,0.762-6.818-2.072-9.746c-2.538-2.621-4.665-5.641-6.963-8.494c-0.844-1.048-1.158-2.286-1.173-3.608
				c-0.043-3.728-1.77-6.874-3.564-9.956c-1.042-1.789-2.321-3.453-3.611-5.08c-0.884-1.114-1.311-2.381-1.871-3.646
				c-1.132-2.553-0.709-4.768,0.627-7.032c2.096-3.552,3.845-7.303,7.549-9.666c1.99-1.271,3.634-3.069,5.053-5.005
				c1.133-1.545,1.933-3.258,2.13-5.168c0.244-2.359,1.196-4.384,2.65-6.196c2.676-3.332,3.39-6.877,2.196-11.122
				c-1.778-6.321-2.393-12.853-2.159-19.438c0.028-0.793,0.07-1.585,0.108-2.378c0.281-5.922,3.261-9.809,8.841-11.747
				c0.748-0.26,1.496-0.533,2.213-0.867c1.049-0.487,1.822-1.273,1.886-2.476c0.237-4.449,0.941-8.886,0.392-13.357
				c-0.208-1.688-0.741-3.254-1.493-4.792c-1.709-3.496-2.905-7.167-3.119-11.087c-0.064-1.181-0.116-2.417,0.153-3.551
				c1.378-5.816,1.579-11.781,2.333-17.675C342.807,443.416,343.23,440.985,344.158,438.469z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M61.126,494.227c0-2.948,0.007-5.896-0.003-8.845
				c-0.005-1.419,0.967-2.354,1.684-3.396c8.78-12.734,17.581-25.455,26.375-38.18c0.675-0.977,1.342-1.959,2.113-3.086
				c-1.56-1.846-1.822-4.309-3.051-6.327c-0.612-1.006-0.301-1.874,0.073-2.814c0.888-2.235,1.346-4.539,1.209-6.969
				c-0.129-2.297-0.275-2.498-2.638-2.459c-0.592,0.01-1.35-0.124-1.746,0.181c-1.577,1.21-3.053,1.029-4.662,0.111
				c-0.48-0.274-1.298-0.48-1.715-0.258c-2.068,1.106-3.019-0.341-4.188-1.559c-1.188-1.237-1.659-2.531-1.267-4.271
				c0.388-1.726,0.258-3.383-1.903-4.043c-0.844-0.258-1.099-1.001-1.215-1.863c-0.19-1.413-0.765-2.522-2.139-3.34
				c-1.433-0.852-1.459-1.679-0.497-3.214c0.859-1.371,1.349-2.837,1.698-4.405c0.906-4.071,3.133-7.688,4.138-11.738
				c0.215-0.867,1.011-1.438,1.979-1.941c4.06,2.196,7.75-0.437,11.597-1.3c1.401-0.314,2.801-0.936,4.21-1.394
				c1.854-0.604,3.542-0.273,5.03,0.92c0.79,0.633,1.642,1.06,2.568,1.441c1.305,0.537,2.448,0.786,3.434-0.562
				c0.281-0.384,0.754-0.632,1.154-0.921c0.759-0.548,2.155-0.581,2.026-1.866c-0.137-1.356-0.445-2.775-1.618-3.718
				c-2.997-2.406-4.52-5.825-6.211-9.105c-0.77-1.491-0.267-2.338,1.415-2.807c0.762-0.212,1.543-0.304,2.227-0.765
				c0.97-0.653,1.552-1.477,1.544-2.699c-0.008-1.274,0.618-2.072,1.946-2.144c2.685-0.145,4.505-2.293,6.968-2.989
				c0.771-0.218,1.155-1.121,0.929-1.986c-0.285-1.088,0.16-1.882,0.73-2.745c1.846-2.79,2.505-2.884,4.813-0.413
				c0.65,0.696,1.364,1.109,2.312,1.156c3.263,0.16,6.526,0.301,9.786,0.507c2.802,0.177,5.047-1.042,6.865-2.944
				c1.003-1.051,2.026-1.882,3.395-2.245c2.514-0.666,4.856-1.667,6.999-3.164c0.963-0.673,2.003-1.416,3.422-0.368
				c0.398,0.828-0.563,2.295,0.834,3.001c1.225,0.62,2.513,0.725,3.718,0.067c2.069-1.13,3.744,0.597,5.636,0.767
				c0.848,0.076,0.525,1.312-0.308,1.783c-1.132,0.641-2.162,1.347-2.951,2.431c-1.454,2-1.616,3.945-0.181,6.037
				c1.112,1.621,2.378,3.139,2.838,5.17c0.614,2.705-0.571,5.604-3.042,6.893c-2.606,1.359-2.828,1.796-2.545,5.064
				c0.13,1.495-0.718,2.636-1.364,3.855c-0.408,0.771-1.095,1.597-0.274,2.427c0.757,0.765,1.76,0.764,2.78,0.468
				c0.8-0.233,1.366-0.755,1.954-1.297c1.988-1.835,4.53-1.975,6.827-0.306c1.261,0.916,2.627,0.689,4,0.645
				c0.895-0.029,1.853-0.159,2.221,0.965c0.295,0.898-0.154,1.492-0.882,1.984c-1.168,0.79-1.127,1.752-0.299,2.777
				c0.125,0.154,0.249,0.31,0.374,0.463c2.505,3.078,2.129,6.378,0.601,9.669c-0.668,1.439-2.015,2.171-3.63,2.178
				c-2.218,0.01-3.985,0.791-5.451,2.524c-1.292,1.526-2.735,1.739-4.494,0.882c-1.234-0.602-2.595-1.006-3.56-2.096
				c-0.655-0.741-1.556-0.759-2.472-0.731c-2.571,0.077-2.787,0.58-2.9,3.201c-0.098,2.261,1.559,3.904,1.596,6.081
				c0.011,0.632,1.009,1.14,1.72,1.528c2.505,1.369,3.237,3.692,2.099,6.313c-0.47,1.082-0.875,2.214-1.132,3.363
				c-0.375,1.672-0.275,3.243,0.855,4.759c1.324,1.774,0.255,5.299-1.74,6.339c-0.7,0.364-1.564,0.534-2.113,1.049
				c-1.827,1.714-3.547,0.088-5.28-0.11c-0.565-0.064-1.1-0.972-1.112-1.648c-0.031-1.742-0.857-3.239-1.277-4.856
				c-0.679-2.616-3.075-3.272-5.017-1.379c-0.274,0.267-0.531,0.553-0.708,0.738c-0.966,0.138-1.499-0.271-1.856-0.922
				c-0.549-1.001-1.477-1.579-2.35-2.23c-3.142-2.346-6.349-1.104-7.233,2.801c-0.369,1.631-0.911,3.244,0.346,4.786
				c0.366,0.449,0.293,1.114,0.318,1.719c0.055,1.32,0.491,2.54,1.505,3.44c1.542,1.37,2.204,3.27,2.989,5.055
				c0.601,1.364,0.256,2.631-1.155,3.564c-1.655,1.096-3.318,2.504-4.045,4.232c-1.16,2.762-2.737,5.528-1.791,8.869
				c0.828,2.925,0.161,3.583-2.872,3.81c-0.885,0.066-1.759,0.238-2.572,0.665c-1.437,0.757-2.843,0.723-4.518,0.544
				c-2.147-0.229-3.877-0.635-5.827-1.726c-1.636-0.915-3.888-1.347-5.972-1.539c-3.784-0.35-5.838-2.619-5.637-6.432
				c0.09-1.695-0.042-3.368-0.157-5.04c-0.106-1.539-0.624-2.942-2.833-3.087c-10.218,16.353-20.494,32.8-30.771,49.246
				C61.579,494.381,61.352,494.304,61.126,494.227z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M142.499,524.764c1.937-0.704,2.339-2.233,2.815-3.592
				c0.58-1.655,1.28-2.77,3.333-2.646c1.862,0.111,3.228-0.996,3.899-2.7c0.79-2.004,1.397-4.079,2.019-5.929
				c-0.853-1.466-2.223-0.418-3.232-1.256c1.22-1.953,2.974-3.377,4.826-4.601c2.203-1.454,3.628-3.028,3.545-5.972
				c-0.061-2.163,1.262-4.196,2.835-5.689c1.172-1.114,1.832-2.29,2.026-3.804c0.348-2.716,1.472-4.893,4.024-6.185
				c2.997-1.517,2.832-4.498,3.188-7.191c0.142-1.07,0.008-2.167,0.377-3.229c0.229-0.66,0.336-1.413,0.927-1.819
				c1.921-1.322,3.333-3.059,4.532-5.037c0.389-0.641,1.092-1.231,1.786-1.52c2.362-0.978,3.071-2.929,3.348-5.197
				c0.06-0.491,0.025-1.003,0.15-1.475c0.371-1.4,0.941-2.501,2.682-2.725c1.587-0.205,2.987-0.845,3.464-2.757
				c0.344-1.382,1.552-1.654,2.873-1.306c1.727,0.457,3.411-0.132,4.951-0.617c2.415-0.761,3.934-2.594,4.081-5.281
				c0.032-0.587-0.051-1.181-0.09-1.77c-0.2-3.001-0.191-5.751,2.738-7.865c1.68-1.211,3.01-3.164,2.987-5.584
				c-0.016-1.655,0.97-2.863,2.222-3.671c2.22-1.431,3.845-3.367,5.405-5.431c1.125-1.488,2.284-3.042,4.667-3.037
				c-0.69,1.972-0.65,4.065-0.416,6.18c0.12,1.082,0.13,2.126,0.724,3.131c0.688,1.162,0.595,2.05-0.905,2.824
				c-1.865,0.962-2.731,5.497-1.356,7.115c1.034,1.218,0.748,2.146,0.119,3.19c-0.716,1.187-1.466,2.37-2.343,3.438
				c-3.293,4.015-5.878,8.461-8.158,13.101c-1.493,3.035-3.274,5.832-5.546,8.397c-2.357,2.661-4.321,5.675-6.393,8.581
				c-0.764,1.073-1.569,1.925-2.845,2.464c-5.817,2.457-9.981,6.672-12.807,12.293c-3.121,6.21-5.603,12.691-8.254,19.102
				c-1.63,3.94-3.376,7.813-5.408,11.575c-2.836,5.251-5.021,10.791-6.087,16.693c-0.279,1.543-1.07,2.516-2.274,3.28
				c-1.674,1.063-3.381,2.074-5.072,3.109c-5.989,3.663-10.582,8.568-13.643,14.938c-4.446,9.25-7.536,18.959-9.982,28.888
				c-1.059,4.296-3.843,7.541-6.312,10.994c-0.697,0.975-1.753,0.77-2.727,0.771c-5.654,0.008-11.308,0.003-16.963,0.003
				c-8.729,0-17.458,0.001-26.188,0.001c-1.086,0-2.172,0-3.615,0c0.929-1.894,1.12-3.702,1.322-5.503
				c0.199-1.774,1.316-2.898,2.743-3.634c2.65-1.365,4.27-3.379,4.88-6.321c0.466-2.241,2.42-3.571,3.676-5.331
				c0.329-0.46,1.089-0.685,1.007-1.366c-0.157-1.299,0.988-1.811,1.434-2.696c1.937-3.848,6.311-4.546,9.016-7.329
				c2.856-2.938,5.725-5.9,8.43-8.938c1.884-2.115,4.363-4.062,5.209-7.09c0.252-0.901,1.304-1.16,2.142-1.463
				c1.214-0.438,2.163-1.051,1.925-2.56c-0.229-1.463-1.112-2.11-2.978-2.196c-0.295-0.014-0.591-0.002-0.891-0.002
				c-2.482-3.232-2.667-3.308-8.358-3.232c1.681-0.916,3.524-1.22,3.846-3.375c3.072,0.322,5.952-0.314,8.761-1.355
				c2.492-0.925,5.184-1.576,6.146-4.561c2.584-1.454,2.508-4.011,2.709-6.467c0.056-0.683,0.026-1.555,0.421-1.995
				c1.72-1.926,1.732-4.622,3.169-6.648c0.692-0.977,0.552-2.076,0.428-3.161c-0.123-1.083-0.285-2.161-0.434-3.241
				c-0.248-1.794,0.062-3.344,1.765-4.368c0.997-0.599,1.556-1.48,1.521-2.705c-0.047-1.688,1.263-2.868,1.712-4.386
				c0.202-0.682,0.842-1.029,1.643-1.038c1.276-0.014,1.836-0.971,1.729-1.987c-0.271-2.607,0.362-5.405-0.911-7.779
				c-0.781-1.458-1.574-2.923-2.325-4.403c-0.124-0.244-0.149-0.539-0.292-1.084c2.095,0.623,3.901-0.363,5.857-0.396
				c1.608-0.026,2.986-1.046,3.102-3.016c0.075-1.279,1.005-2.12,1.685-3.084c0.623-0.884,1.481-1.123,2.267-0.415
				c0.55,0.496,0.974,1.04,1.746,0.702c1.075-0.47,2.342-0.721,2.995-1.813c1.056-1.765,2.372-3.438,2.004-5.745
				c-0.279-1.75,0.403-3.037,2.094-3.83c0.881-0.413,1.606-1.153,2.429-1.705c0.998-0.668,1.99-0.837,3.068,0.328
				c-1.063,2.004-0.853,4.137-0.845,6.385c0.009,2.28,0.978,5.222-1.498,7.206c-0.606,0.486-0.105,1.308-0.027,1.98
				c0.12,1.04,0.781,1.873,1.323,2.622c1.573,2.173,1.025,4.22-0.363,5.96c-2.412,3.025-3.429,6.928-6.383,9.619
				c-1.261,1.148-1.443,2.981-1.578,4.694c-0.281,3.577-1.265,6.89-2.837,10.186c-1.365,2.862-2.96,5.59-4.315,8.442
				C141.739,520.661,140.786,522.632,142.499,524.764z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M633.802,603.097c-1.07,0.717-2.296,0.404-3.454,0.404
				c-24.796,0.02-49.594-0.052-74.39,0.095c-3.33,0.019-5.52-0.809-6.587-4.049c0.015-0.031,0.045-0.13,0.102-0.209
				c0.05-0.071,0.122-0.162,0.197-0.175c0.291-0.049,0.588-0.098,0.882-0.099c24.497-0.04,48.993-0.075,73.489-0.116
				c0.09,0,0.179-0.081,0.435-0.204c0.856-0.986,0.494-2.33,0.498-3.565c0.047-19.242,0.08-38.484,0.09-57.726
				c0.072-143.325,0.14-286.65,0.196-429.975c0-1.269,0.244-2.571-0.27-3.821c-1.049-0.872-2.29-0.504-3.436-0.505
				c-17.854-0.024-35.707-0.017-53.561-0.017c-45.526,0-91.054,0.001-136.58-0.001c-3.449,0-4.127-0.655-4.23-4.228
				c68.885,0,137.764,0,206.641,0C634.405,100.893,634.385,598.677,633.802,603.097z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M59.729,599.083c19.628,0,39.306,0,59.685,0c-1.445,1.666-2.416,2.864-3.475,3.978
				c-0.696,0.731-1.686,0.444-2.554,0.446c-7.737,0.017-15.475,0.009-23.212,0.009c-12.201,0-24.402,0.005-36.603-0.008
				c-1.072-0.001-2.178,0.186-3.549-0.447c0-167.897,0-335.893,0-504.034c0.612-0.547,1.547-0.294,2.408-0.294
				c61.401-0.021,122.803,0,184.205-0.12c3.21-0.006,3.523,2.141,4.61,3.873c-0.111,0.245-0.136,0.349-0.198,0.421
				c-0.055,0.064-0.146,0.118-0.229,0.135c-0.193,0.04-0.391,0.058-0.588,0.079c-0.098,0.01-0.198,0.008-0.297,0.011
				c-0.1,0.002-0.199,0.002-0.298,0.002c-0.397,0-0.793,0.001-1.19,0.001c-59.021,0-118.042,0-177.063,0
				c-0.198,0-0.396,0.002-0.595,0.004c-0.099,0.001-0.199,0-0.297,0.007c-0.198,0.014-0.396,0.027-0.593,0.052
				c-0.096,0.013-0.19,0.047-0.283,0.079c-0.093,0.033-0.184,0.075-0.306,0.125c-0.931,0.857-0.594,2.016-0.594,3.063
				c-0.015,25.592-0.022,51.185-0.001,76.776c0.037,46.125,0.096,92.251,0.14,138.376c0.087,91.258,0.17,182.517,0.268,273.776
				C59.119,596.459,58.869,597.572,59.729,599.083z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M622.746,493.339c-1.67,0.702-3.086,1.857-4.988,1.599
				c-1.413,3.749,0.976,6.675,1.542,10.084c-3.102-0.204-4.283,1.553-4.682,4.324c-0.783-1.933-2.955-2.526-3.04-4.988
				c-0.085-2.454-4.493-3.923-6.425-2.312c-0.711,0.593-1.187,0.786-2.038,0.612c-2.318-0.477-4.673,0.463-7.06-0.322
				c-1.18-0.387-2.887,1.335-2.957,2.696c-0.098,1.903-1.175,3.312-2.089,4.816c-0.358,0.591-0.81,1.025-1.602,1.172
				c-3.454,0.639-3.448,0.671-5.571,2.481c-0.471-0.988-0.981-1.923-1.37-2.905c-1.025-2.586-3.034-3.622-5.641-3.36
				c-1.376,0.139-2.331-0.306-3.146-1.224c-1.551-1.748-3.631-3.069-4.319-5.53c-0.171-0.609-0.773-1.134-1.264-1.608
				c-0.911-0.883-1.315-1.84-1.221-3.17c0.126-1.771-0.413-3.333-2.302-4.067c-0.351-0.137-0.817-0.398-0.912-0.701
				c-0.808-2.577-3.075-3.847-4.946-5.379c-2.965-2.429-1.498-5.346,1.261-6.755c0.439-0.225,0.94-0.313,1.308-1.062
				c-0.024-0.978-0.777-1.723-1.677-2.347c-2.884-2-4.869-7.741-3.76-10.954c0.161-0.468,0.36-0.904,0.785-1.225
				c1.408-1.063,2.397-2.291,2.094-4.268c-0.121-0.781,0.473-1.827,1.248-1.785c3.283,0.175,5.333-2.276,7.92-3.533
				c1.913-0.93,2.773-1.1,4.558,0.179c2.126,1.522,2.126,1.522,4.459-0.31c0.201-0.572-0.155-0.932-0.471-1.312
				c-1.517-1.827-1.697-3.832-0.355-5.744c1.387-1.976,1.835-3.808,0.351-5.934c-0.42-0.602-0.436-1.501-0.585-2.273
				c-0.176-0.907-0.536-1.619-1.338-2.197c-2.183-1.57-2.398-3.828-0.642-5.698c0.332-0.354,0.643-0.9,1.179-0.749
				c1.982,0.558,2.479-1.161,3.636-2.214c-1.076-2.275-0.846-4.807-1.472-7.187c-0.098-0.373-0.162-0.824-0.043-1.172
				c1.366-4.018,2.265-8.384,6.896-9.959c1.541,0.085,1.575,1.969,3.014,2.021c1.243-0.393,1.368-1.525,1.447-2.604
				c0.058-0.781-0.068-1.58-0.15-2.366c-0.098-0.942-0.024-1.805,0.949-2.279c0.855-0.418,1.499-0.025,2.117,0.566
				c0.891,0.853,1.927,1.378,3.079,1.216c1.279-0.334,0.422-1.768,1.626-2.315c1.853,0.319,3.814-0.14,5.663-1.224
				c0.824-0.483,1.716-0.371,2.522,0.153c0.466,2.74,1.617,3.671,4.352,3.307c3.804-0.507,7.73-0.04,11.396-1.743
				c1.395-0.648,2.646,0.48,2.805,2.088c0.049,0.491,0.017,0.991,0.016,1.486c-0.016,27.857-0.031,55.715-0.053,83.572
				C622.848,491.72,622.783,492.506,622.746,493.339z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M68.229,487.286c8.386-13.425,16.592-26.563,24.799-39.702
				c0.203,0.06,0.405,0.118,0.607,0.179c0.113,1.62,0.44,3.261,0.294,4.857c-0.297,3.253,0.853,5.763,3.29,7.815
				c0.877,0.737,1.812,0.988,2.969,1.072c2.828,0.208,5.625,0.622,7.997,2.483c0.911,0.715,2.027,1.274,3.318,0.727
				c0.346-0.146,0.879-0.324,1.121-0.169c2.146,1.383,3.951,0.067,5.846-0.635c0.55-0.204,1.335-0.614,1.677-0.398
				c1.684,1.06,2.511-0.158,3.565-1.055c1.233-1.048,1.576-2.386,1.023-3.745c-1.165-2.864,0.364-5.391,0.983-7.941
				c0.479-1.971,2.475-3.256,4.112-4.524c1.71-1.325,2.62-3.561,1.724-5.417c-1.02-2.112-1.743-4.434-3.61-6.059
				c-0.55-0.479-0.836-1.117-0.76-1.854c0.12-1.149-0.333-2.139-0.823-3.115c-0.816-1.628-0.03-3.145,0.574-4.5
				c0.626-1.408,2.471-1.598,3.767-0.676c0.722,0.514,1.47,1.044,2.039,1.71c2.248,2.633,3.492,2.742,5.912,0.414
				c0.123-0.118,0.358-0.119,0.705-0.225c1.379,1.05,1.69,2.788,1.912,4.315c0.258,1.774,0.698,3.396,2.111,4.401
				c1.451,1.033,3.394,0.814,5.076,1.338c0.468,0.146,0.936-0.201,1.292-0.587c0.545-0.592,1.221-0.983,1.959-1.311
				c2.89-1.279,4.63-4.666,3.966-7.669c-0.085-0.383-0.099-0.89-0.349-1.112c-2.39-2.135-1.155-4.461-0.121-6.503
				c1.573-3.105,0.522-8.456-3.438-9.149c0.235-2.389-2.248-4.094-1.64-6.828c0.89-0.581,1.869-0.707,2.802,0.17
				c1.042,0.979,2.365,1.438,3.616,2.021c2.462,1.146,4.686,0.808,6.433-1.203c1.136-1.306,2.407-1.866,4.103-1.992
				c3.388-0.253,4.992-1.696,5.819-4.908c0.025-0.096,0.016-0.207,0.061-0.29c2.062-3.722,0.261-6.781-1.68-9.819
				c0.067-0.785,0.883-1.016,1.198-1.623c1.064-2.056-0.09-4.487-2.315-4.683c-1.904-0.167-3.896,0.435-5.442-1.485
				c-0.449-0.558-1.864-0.337-2.965-0.491c-0.021-1.198,0.193-2.449,1.611-2.01c1.401,0.434,1.885-0.229,2.288-1.126
				c0.838-1.865,2.02-2.78,4.195-2.347c1.267,0.252,2.512-0.369,3.733-0.8c2.555-0.9,5.007-1.698,7.421,0.69
				c0.946,0.937,2.847,0.468,3.965,1.322c0.889-0.244,1.25-0.89,1.77-1.332c1.186-1.008,2.495-1.172,3.87-0.497
				c1.526,0.749,2.186,2.028,1.99,3.836c-0.075,0.701-0.264,1.478,0.134,2.034c1.4,1.956,0.724,4.028,0.358,6.022
				c-0.28,1.528-0.794,2.968-2.638,3.616c-1.351,0.475-2.498,1.532-3.899,2.436c0.198,1.827-0.38,3.438-1.735,4.949
				c-1.236,1.378-0.652,3.177-0.127,4.756c-0.183,0.196-0.308,0.453-0.446,0.461c-2.819,0.151-4.322,1.7-3.908,4.444
				c0.401,2.652-0.026,4.804-1.486,7.227c-1.85,3.07-3.966,5.605-6.942,7.522c-0.968,0.623-1.28,1.749-1.507,2.817
				c-0.25,1.17-0.552,2.316-1.086,3.385c-0.639,1.279-1.517,2.265-3.038,2.549c-2.923,0.545-3.865,3.151-5.265,5.243
				c-0.334,0.498,0.017,1.098,0.301,1.658c0.902,1.777,0.874,1.812-0.952,2.7c-0.373,0.182-0.682,0.373-0.879,0.752
				c0.843,4.245,3.227,8.112,3.296,12.815c-2.598-0.281-4.669,1.621-7.233,1.285c-0.619-0.081-1.194,0.333-1.53,0.799
				c-1.381,1.917-2.87,3.789-3.144,6.273c-0.292,2.647-1.134,5.206-1.085,7.925c0.023,1.289-1.012,2.342-1.796,3.347
				c-0.414,0.531-1.053,0.667-1.862,0.475c-3.757-0.896-5.38-0.192-6.501,3.412c-0.665,2.137-1.35,3.168-3.812,3.004
				c-1.377-0.092-2.824,0.89-3.746-1.048c-1.703,1.722-2.43,3.154-1.302,5.052c0.796,1.34,0.97,2.96,2.153,4.175
				c0.634,0.65,0.862,1.812,0.614,2.823c-0.32,1.306-0.096,2.533,0.523,3.931c-3.73,1.172-4.584,4.319-5.326,7.492
				c-0.173,0.738-0.424,1.311-1.035,1.695c-2.386,1.504-2.976,3.865-2.215,6.246c1.205,3.776-0.669,6.806-2.086,9.821
				c-0.909,1.935-1.667,3.773-1.727,5.867c-0.039,1.362-0.322,2.487-1.472,3.382c-0.599,0.467-0.969,1.232-1.428,1.872
				c-0.228,0.317-0.347,0.791-0.647,0.968c-3.595,2.115-7.354,3.613-11.664,3.159c-0.141-1.286,0.856-1.514,1.24-2.151
				c0.682-1.133,1.161-2.199,0.617-3.614c-0.916-2.382-0.291-4.656,1.049-6.705c1.344-2.054,1.093-3.711-1.203-4.667
				c-1.332-0.555-1.722-1.413-2.008-2.496c1.016-1.705,2.218-0.042,3.256-0.372c1.161-0.369,1.706-1.265,2.07-2.218
				c-0.216-1.488-1.531-1.551-2.37-2.161c-1.09-0.794-1.784-1.54-1.35-3.195c0.697-2.659-0.97-4.102-3.664-3.499
				c-3.452,0.772-5.345-0.598-5.618-4.063c-0.201-2.562-1.906-3.666-4.224-2.671c-0.899,0.386-1.701,1.072-2.76,0.509
				c-0.108-0.167-0.3-0.327-0.308-0.496c-0.146-3.13-1.507-4.117-4.296-2.831c-1.147,0.528-2.325,0.388-3.438,0.753
				c-1.023,0.336-1.386,1.771-2.708,1.646c-1.094-0.118-0.587-1.318-1.224-1.814c-0.678-0.528-1.572-0.229-2.35-0.877
				c-0.361-1.503-0.852-3.12-2.938-3.354c-0.226-0.024-0.473-0.34-0.613-0.577c-1.496-2.525-1.49-2.527-4.362-2.062
				C69.07,487.312,68.876,487.286,68.229,487.286z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M128.189,272.766c0,1.651,0,2.923,0,4.066c-1.133,1.579-2.679,0.505-3.815,0.793
				c-0.755,0.547-0.82,1.244-0.916,1.932c-0.409,2.914-0.766,3.18-3.464,2.362c-1.002-0.304-1.943-0.34-2.889,0.094
				c-1.449,0.666-1.68,1.435-0.812,2.8c0.318,0.5,0.744,0.931,1.069,1.427c0.264,0.404,0.451,0.857,0.746,1.434
				c-1.011,0.953-2.299,1.59-2.773,2.999c-0.869,2.587,0.094,3.803,2.784,3.337c0.679-0.118,1.335-0.438,2.036,0.178
				c0.054,0.202,0.229,0.494,0.184,0.747c-0.55,3.136-0.575,3.164,2.117,4.706c1.082,0.62,1.564,1.467,1.438,2.672
				c-0.061,0.585-0.262,1.163-0.266,1.746c-0.004,0.577,0.158,1.156,0.258,1.8c-1.322,1.272-2.311,2.719-2.314,4.644
				c-0.001,0.632-0.318,1.18-0.765,1.563c-1.891,1.624-2.851,4.264-5.723,4.854c-1.577,0.324-1.98,2.064-2.631,3.621
				c-1.883-1.288-3.591-2.525-5.805-1.065c-0.569-2.208-0.331-4.199-0.093-6.055c0.148-1.162,0.411-2.032-0.476-2.92
				c-0.587-0.588-0.68-1.395-0.903-2.159c-0.793-2.717-2.11-3.44-4.823-2.749c-1.561,0.398-1.561,0.398-5.581-1.105
				c0.056-3.837-2.016-5.238-5.425-3.621c-1.478,0.701-3.303,1.082-3.458,3.236c-0.031,0.425-0.352,0.752-0.732,0.866
				c-1.942,0.579-2.045,2.163-1.978,3.708c-1.077,0.785-1.975,0.77-2.715-0.178c-0.601-0.77-0.764-1.738-0.119-2.479
				c1.241-1.425,0.621-2.515-0.387-3.638c-1.045-1.165-2.202-1.129-3.473-0.4c-0.909,0.521-1.837,0.877-2.621-0.37
				c0.442-1.217,1.952-1.639,2.391-2.867c0.448-1.254,1.096-2.253,2.263-3.001c0.754-0.483,0.615-1.406,0.255-2.172
				c-0.394-0.838-0.985-1.726-1.97-1.54c-4.617,0.872-6.21-2.381-7.868-5.593c-0.271-0.525-0.443-1.116-0.781-1.591
				c-0.923-1.295-2.189-1.996-3.815-1.88c-0.975,0.07-1.944,0.21-3.137,0.343c0-20.793,0-41.465,0-62.234
				c2.07-0.048,3.747-0.998,5.561-1.801c2.979-1.32,6.141-3.025,9.425-2.657c2.144,0.24,4.03,1.001,6.022-0.409
				c0.384-0.272,1.059,0.318,1.53,0.717c2.967,2.506,6.18,4.429,10.27,4.29c0.687-0.023,1.359,0.203,1.644,1.011
				c0.375,1.061,1.002,1.922,2.072,2.319c1.219,0.112,1.19-1.664,2.597-1.464c0.579,0.759-0.152,1.981,0.721,2.83
				c0.807,0.785,1.86,0.966,2.815,1.396c-0.269,1.101-1.24,1.077-1.832,1.517c-1.108,0.821-1.514,1.842-0.958,3.104
				c0.563,1.279,1.558,0.56,2.509,0.574c2.505,0.035,5.353-0.71,7.045,2.159c0.24,0.406,0.666,0.846,1.094,0.973
				c2.422,0.721,2.958,2.666,3.235,4.875c-0.033,0.104-0.049,0.32-0.158,0.469c-2.734,3.748-2.596,4.571,1.185,6.968
				c0.575,0.364,1.315,0.506,1.735,1.39c-0.315,1.029-1.5,1.297-2.248,1.997c-0.686,0.642-1.341,1.312-0.586,2.27
				c0.706,0.896,1.624,1.066,2.639,0.482c0.085-0.049,0.155-0.124,0.233-0.185c1.065-0.832,1.57-2.506,3.167-2.487
				c1.437,0.018,2.617-0.68,4.085-1.182c0.462,2.349-2.334,2.041-2.584,3.664c0.223,1.185,1.25,1.678,2.263,2.001
				c1.705,0.544,2.607,1.832,3.105,3.336c0.598,1.806,1.142,3.558,2.719,4.8c0.228,0.179,0.164,0.729,0.276,1.328
				c-2.14,0.331-2.836,2.831-5.258,2.968c-1.252,0.07-0.944,1.492-0.552,2.449C125.535,272.139,126.959,272.281,128.189,272.766z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M559.961,477.507c-2.395,0.523-2.867,2.866-4.55,4.439
				c-0.603-1.261-1.023-2.259-1.236-3.443c-0.703-3.908-2.278-7.177-6.665-8.139c-0.975-0.214-1.887-0.673-2.057-1.87
				c-0.027-0.189-0.141-0.369-0.233-0.544c-1.432-2.692-2.933-5.293-6.705-4.508c-0.571-1.477-0.543-2.744-0.371-4.014
				c0.188-1.385,0.182-2.766,0.084-4.155c-0.224-3.175-0.214-3.175-2.778-5.649c2.178,0.366,3.776,1.352,5.495,1.951
				c1.119,0.392,2.172,0.969,3.28,1.395c0.795,0.306,1.598,1.053,2.491,0.383c1.058-0.793,0.444-1.777,0.162-2.706
				c-0.055-0.181-0.029-0.387-0.04-0.561c0.461-1.182,1.693-0.871,2.514-1.344c1.091-0.63,1.271-1.556,0.348-2.41
				c-0.646-0.597-1.442-1.043-2.21-1.492c-6.085-3.558-12.008-7.345-17.525-12.218c0.475-3.176,1.041-6.359,4.42-8.311
				c2.655-1.533,5.129-3.38,7.701-5.062c1.904-1.245,3.877-2.368,5.182-4.36c0.656-1.003,1.812-1.526,2.833-2.13
				c3.414-2.019,6.936-3.838,10.493-5.591c0.922-0.454,2.378-0.763,2.051-2.149c-0.357-1.513-1.879-1.271-3.005-1.171
				c-6.227,0.556-12.367,1.663-18.448,3.099c-3.28,0.774-6.519,1.704-9.685,2.864c-4.421,1.619-8.518,3.765-11.776,7.277
				c-0.737,0.794-1.575,1.495-2.484,2.35c-1.246-0.419-2.438-1.051-3.91-0.471c-1.165,0.458-2.207-0.362-2.271-1.633
				c-0.078-1.559-0.6-2.853-1.392-4.155c-0.563-0.928,0.655-5.099,1.501-5.733c2.915-2.188,3.017-2.877,0.801-5.918
				c0.892-2.486,2.656-3.884,5.354-3.731c3.126,0.177,5.74-1.328,8.475-2.375c2.175-0.833,2.556-0.875,3.749,1.059
				c0.864,1.398,1.672,2.666,3.765,1.752c1.084-0.474,1.973,0.673,1.539,1.931c-0.521,1.515-1.055,2.967-0.071,4.686
				c1.546-0.297,2.582-1.418,3.792-2.163c0.87-0.536,0.709-1.413,0.591-2.378c-0.197-1.623,0.566-2.362,2.25-2.168
				c1.935,0.223,2.669-0.78,3.11-2.304c-0.153-0.244-0.22-0.503-0.365-0.562c-3.603-1.435-3.591-1.43-3.378-5.289
				c0.042-0.772,0.19-1.587-0.283-2.299c-1.601-0.256-1.82-0.339-3.311-1.295c0.723-1.573,0.769-3.12-0.524-4.591
				c-0.818-0.929-0.515-2.024,0.678-2.31c1.318-0.316,1.646-1.269,2.117-2.243c0.431-0.889,0.896-1.763,1.387-2.62
				c0.649-1.136,1.062-2.291,1.063-3.649c0.001-2.076,0.748-2.962,2.824-2.866c2.49,0.116,4.689-0.398,6.778-1.708
				c0.769-0.482,1.648-0.418,2.577-0.229c1.521,0.311,2.944,0.119,3.869-1.444c0.69-1.167,1.997-1.339,2.803-0.527
				c1.867,1.881,3.772,1.842,5.694,0.605c0.768,0.499,0.878,1.002,0.957,1.646c0.318,2.588,2.06,3.506,4.246,2.322
				c2.217-1.2,2.438-1.146,4.074,0.999c1.115,1.462,2.772,2.043,4.404,1.163c1.354-0.731,2.332-0.861,3.336,0.433
				c0.863,1.115,2.174,1.324,3.472,1.395c1.381,0.074,2.383,0.378,2.901,1.944c0.506,1.528,0.935,1.602,3.759,2.418
				c-0.293,2.196-0.079,2.983,2.057,3.79c1.917,0.724,2.91,1.904,3.271,3.787c0.543,2.828,2.169,4.594,5.009,5.2
				c1.289,0.274,2.209,0.913,2.191,2.369c-0.018,1.331,0.851,2.232,1.892,2.34c2.295,0.236,3.248,1.986,4.543,3.397
				c0.479,0.834-0.606,1.444-0.325,2.25c0.37,1.092,1.727,0.333,2.214,1.178c0.392,0.681,0.632,1.41,0.588,2.376
				c-2.491,0.652-5.089,0.278-7.607,0.653c-0.614,0.092-1.296-0.312-1.557-1.12c-0.764-2.368-2.096-3.251-4.122-2.418
				c-1.754,0.72-3.39,1.359-5.375,1.156c-1.351-0.138-2.205,1.042-2.568,2.195c-1.057-0.011-1.521-0.56-1.996-1.046
				c-0.907-0.926-1.919-0.916-2.992-0.396c-1.214,0.589-1.671,1.587-1.601,2.917c0.065,1.227,0.885,2.507-0.315,3.838
				c-1.03-0.664-1.795-2.457-3.341-1.549c-1.778,1.045-4.34,1.311-4.637,4.071c-0.101,0.94-0.638,1.658-1.218,2.335
				c-1.607,1.879-1.9,4.037-1.483,6.388c0.312,1.754,0.582,3.516,0.828,5.28c0.23,1.656-0.035,1.964-1.761,2.226
				c-1.493,0.228-3.226,1.867-3.624,3.43c-0.437,1.712,0.241,4.335,1.66,5.012c1.121,0.535,1.364,1.299,1.538,2.286
				c0.034,0.194,0.037,0.414,0.133,0.577c1.558,2.655,1.127,5.021-0.512,7.594c-1.215,1.907-0.008,3.979,0.945,5.769
				c-0.669,1.058-1.269,0.751-2.014,0.224c-2.959-2.094-3.603-2.095-6.565-0.16c-1.494,0.976-3.26,1.456-4.668,2.596
				c-0.279,0.226-0.715,0.312-1.09,0.354c-2.75,0.305-3.301,0.764-3.437,3.503c-0.055,1.132-0.487,1.992-1.344,2.453
				c-1.366,0.736-1.476,2.004-1.777,3.239c-0.961,3.925,1.337,6.953,2.795,10.218C557.805,476.479,559.217,476.079,559.961,477.507z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M671.47,613.701c-2.158,0.236-3.813,0.262-5.708,0.019c0-175.628,0-351.14,0-526.856
				c-60.898,0-121.55,0-182.24,0c0.336-0.876,1.107-0.796,1.747-0.957c21.534-5.375,43.069-10.743,64.598-16.14
				c1.039-0.261,2.336-0.234,3.018-1.546c-1.218-1.057-2.455-0.688-3.572-0.691c-17.359-0.036-34.719-0.041-52.078-0.048
				c-29.561-0.014-59.121-0.049-88.682,0.008c-2.319,0.004-4.11-0.749-5.823-2.046c0.105-0.236,0.135-0.327,0.185-0.406
				c0.051-0.08,0.121-0.209,0.183-0.209c58.52,0.026,117.04,0.062,175.561,0.102c0.363,0,0.768-0.042,1.173,0.596
				c-23.564,5.894-47.037,11.766-70.925,17.741c1.27,1.228,2.382,0.872,3.351,0.874c12.003,0.02,24.006,0.007,36.009-0.009
				c39.777-0.054,79.555-0.116,119.332-0.158c1.265-0.001,2.562-0.233,3.874,0.27C671.47,260.668,671.47,437.079,671.47,613.701z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M136.249,68.231c4.245,1.823,8.584,2.593,12.764,3.82
				c4.373,1.284,8.786,2.429,13.18,3.636c4.3,1.182,8.597,2.372,12.897,3.549c4.396,1.204,8.796,2.394,13.193,3.594
				c4.271,1.166,8.54,2.336,12.738,3.957c-60.979,0-121.958,0-183.379,0c-0.138,7.181-0.038,14.178-0.048,21.17
				c-0.011,7.042,0.002,14.085,0.006,21.128c0.003,6.943,0.01,13.887,0.012,20.831c0.002,7.042,0,14.085,0.001,21.128
				c0,7.042,0,14.085,0.001,21.128c0.001,7.042,0.001,14.085,0.005,21.128s0.013,14.085,0.019,21.128
				c0.005,6.943,0.01,13.887,0.015,20.83c0.006,7.043,0.012,14.085,0.019,21.128c0.007,7.042,0.015,14.085,0.021,21.128
				c0.007,7.043,0.013,14.085,0.02,21.128c0.007,7.043,0.019,14.085,0.024,21.128c0.005,6.943,0.002,13.887,0.006,20.831
				c0.004,7.043,0.011,14.085,0.02,21.128s0.021,14.085,0.03,21.128c0.008,7.043,0.012,14.086,0.021,21.128
				c0.01,7.043,0.027,14.085,0.038,21.128c0.01,6.943,0.014,13.887,0.021,20.831c0.008,7.042,0.019,14.085,0.027,21.128
				c0.008,7.043,0.015,14.085,0.021,21.128s0.016,14.085,0.02,21.128c0.004,7.043,0.003,14.086,0.005,21.128
				c0.002,6.944,0.004,13.888,0.007,20.831c0.002,7.043,0.007,14.086,0.008,21.128c0.001,7.021,0,14.044,0,21.209
				c-1.985,0.399-3.712,0.278-5.817,0.098C11.997,437.217,11.846,260.812,11.694,84.1c27.809,0,55.34,0,82.871,0
				c27.517,0,55.034,0,82.501,0c0.061-0.427,0.103-0.53,0.08-0.617c-0.023-0.09-0.092-0.186-0.168-0.239
				c-0.158-0.11-0.325-0.219-0.505-0.284c-0.372-0.135-0.752-0.252-1.134-0.357c-21.304-5.855-42.609-11.709-63.912-17.57
				c-0.064-0.018-0.1-0.139-0.25-0.36c44.3,0,88.499,0,132.863,0c-0.75,2.312-2.26,2.638-4.204,2.633
				c-28.369-0.069-56.737-0.085-85.106-0.113c-5.159-0.005-10.316-0.017-15.475,0.004C138.33,67.2,137.278,66.888,136.249,68.231z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M376.877,338.516c2.314,1.437,3.794,3.333,4.345,5.87c0.744,3.427,2.128,6.59,3.9,9.6
				c1.164,1.976,2.023,4.085,2.57,6.313c1.43,5.824,3.477,11.351,7.219,16.163c1.523,1.96,2.457,4.298,3.231,6.664
				c1.295,3.957,2.509,7.944,3.302,12.031c0.645,3.315,2.075,6.172,4.292,8.68c1.116,1.263,2.266,2.504,3.294,3.837
				c1.461,1.896,2.605,3.89,2.712,6.448c0.092,2.188,1.044,4.236,2.165,6.119c2.406,4.046,4.007,8.392,5.106,12.947
				c0.875,3.629,2.545,6.839,4.993,9.684c2.197,2.555,4.32,5.18,6.362,7.86c3.197,4.194,5.131,8.925,5.552,14.21
				c0.482,6.037,3.256,10.721,8.219,14.134c0.897,0.617,1.806,1.243,2.782,1.715c4.415,2.128,7.391,5.598,9.363,9.985
				c2.316,5.151,4.613,10.313,6.832,15.509c1.651,3.868,2.502,7.944,2.533,12.153c0.036,4.972,1.769,9.302,4.796,13.154
				c1.347,1.715,2.771,3.372,4.228,4.995c3.307,3.687,5.531,7.944,6.965,12.663c3.242,10.665,7.976,20.695,13.087,30.561
				c0.732,1.413,1.567,2.771,2.625,3.959c2.416,2.71,4.101,5.83,5.268,9.24c1.438,4.204,3.564,7.941,7.003,10.859
				c1.303,1.104,2.282,2.519,2.918,4.087c2.537,6.261,5.006,12.589,4.798,19.483c-0.18,5.976,1.429,11.345,4.509,16.371
				c0.777,1.268,1.451,2.598,2.229,3.865c3.703,6.041,4.713,12.53,3.346,19.447c-0.504,2.548-0.069,3.367,2.302,4.354
				c1.276,0.531,2.629,0.88,3.938,1.335c1.509,0.522,3.006,1.111,4.058,2.376c1.189,1.43,2.716,1.89,4.492,1.853
				c0.69-0.014,1.396,0.012,2.071,0.143c1.362,0.265,2.351,0.926,2.584,2.465c0.237,1.564,0.657,3.102,0.92,4.663
				c0.362,2.158,0.009,4.169-1.557,5.811c-1.964,2.059-2.053,4.426-1.134,6.915c0.713,1.933,1.566,3.813,2.457,5.957
				c-9.06,0-17.897,0-26.754,0c-0.521-3.7,0.439-6.646,2.972-9.31c3.087-3.245,6.563-6.031,9.836-9.054
				c0.361-0.335,0.808-0.579,1.179-0.905c2.393-2.106,2.242-4.404-0.552-5.989c-2.688-1.524-5.3-3.115-7.463-5.348
				c-0.688-0.711-1.39-1.428-1.956-2.233c-1.542-2.192-1.915-4.512-0.607-6.987c2.184-4.133,1.861-8.244-0.402-12.197
				c-1.926-3.366-4.056-6.586-7.592-8.581c-3.223-1.816-5.832-1.645-8.285,1.086c-2.713,3.02-5.41,6.098-5.752,10.483
				c-0.215,2.753,0.226,5.132,2.727,6.755c1.11,0.721,1.84,1.803,2.449,2.946c0.887,1.663,1.78,3.308,2.995,4.774
				c1.277,1.543,1.335,3.246,0.341,4.982c-0.196,0.344-0.368,0.707-0.603,1.024c-2.73,3.699-2.434,7.582-0.719,11.554
				c0.157,0.363,0.298,0.742,0.506,1.076c3.025,4.859,3.302,10.251,3.122,15.754c-1.296,0.719-2.078,0.315-2.477-1.006
				c-0.458-1.517-0.932-3.029-1.355-4.557c-1.247-4.487-2.389-9.005-3.741-13.46c-0.684-2.253-0.557-4.268,0.652-6.266
				c0.512-0.846,0.81-1.838,1.396-2.62c1.336-1.784,1.135-3.394-0.064-5.172c-1.23-1.825-2.493-3.588-4.299-4.879
				c-2.204-1.578-3.22-3.691-3.033-6.396c0.138-2.015-0.242-3.946-0.982-5.817c-0.622-1.571-0.501-2.989,0.428-4.509
				c1.439-2.355,0.842-3.789-1.727-4.854c-0.456-0.189-0.934-0.357-1.418-0.442c-5.948-1.046-8.724-5.499-10.791-10.436
				c-1.221-2.916-2.062-5.978-3.777-8.692c-0.57-0.901-0.941-1.98-1.179-3.029c-0.715-3.172-2.154-5.949-4.254-8.404
				c-2.141-2.502-3.404-5.3-3.229-8.688c0.11-2.141-0.607-4.111-1.803-5.878c-3.056-4.517-6.081-9.041-8.066-14.175
				c-0.571-1.478-1.505-2.812-2.658-3.92c-5.374-5.158-8.977-11.563-12.645-17.919c-1.883-3.261-3.671-6.577-5.525-9.854
				c-0.883-1.558-1.785-3.127-2.206-4.862c-1.331-5.486-2.936-10.909-3.942-16.474c-0.355-1.969-0.552-3.899,0.499-5.79
				c0.871-1.568,0.559-3.169-0.724-4.356c-2.858-2.646-4.397-6.026-5.838-9.541c-2.256-5.506-4.954-10.812-7.734-16.07
				c-2.299-4.348-3.331-8.792-2.142-13.742c1.098-4.563-0.342-8.829-2.413-12.881c-0.902-1.765-1.9-3.479-2.843-5.225
				c-1.045-1.935-1.854-3.979-2.103-6.159c-0.483-4.261-1.778-8.33-2.789-12.465c-0.689-2.816-1.096-5.587-0.626-8.518
				c0.586-3.659-0.029-7.233-0.865-10.871c-2.333-10.152-7.253-19.038-12.908-27.581c-3.509-5.299-5.756-11.045-6.983-17.239
				c-0.835-4.212-2.331-8.163-4.431-11.927c-2.137-3.832-3.023-8.151-4.311-12.301c-1.048-3.381-0.965-7.063-2.666-10.289
				c-0.329-0.625-0.326-1.35-0.23-2.044C377.418,341.848,377.478,340.197,376.877,338.516z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M104.355,514.705c1.281,1.474,1.873,3.198,3.901,3.708
				c0.844,0.212,1.156,1.353,0.388,2.477c-1.73,2.531-2.505,5.273-1.293,8.336c0.411,1.038-0.214,1.959-1,2.613
				c-1.037,0.862-1.739,1.898-2.01,3.204c-0.278,1.34-0.987,2.053-2.42,2.135c-1.045,0.06-1.98,0.485-1.835,1.821
				c0.124,1.15,1.338,2.475,2.416,2.179c2.868-0.787,4.999-0.284,6.402,2.525c0.325,0.651,1.178,0.624,1.894,0.575
				c0.564-0.038,1.201-0.308,1.597,0.425c0.218,0.481-0.149,0.766-0.489,0.888c-1.986,0.713-2.839,2.419-4.108,3.932
				c-2.31,2.754-3.732,6.361-7.159,8.155c-0.973,0.51-1.764,1.331-2.295,2.29c-1.75,3.159-4.303,5.284-7.731,6.442
				c-1.254,0.424-2.151,1.458-2.785,2.585c-0.751,1.337-1.592,2.495-3.295,2.735c0.386,2.117-0.707,3.451-1.827,4.693
				c-1.484,1.646-2.725,3.374-3.197,5.596c-0.372,1.748-1.446,3.094-3.013,3.855c-2.963,1.44-4.361,3.729-4.691,6.986
				c-0.319,3.16-1.531,4.066-4.616,4.086c-1.859,0.013-3.719,0.003-5.305,0.003c-0.855-1.012-0.651-1.839-0.652-2.605
				c-0.045-31.135-0.068-62.27-0.136-93.404c-0.003-1.56,0.343-2.898,1.198-4.181c1.1-1.648,2.211-3.302,3.147-5.044
				c1.388-2.586,3.58-3.515,6.554-3.563c0.26,1.53,0.879,2.725,2.562,3.134c1.81,0.439,1.161,2.274,1.671,3.433
				c0.586,0.802,1.341-0.207,1.995,0.229c0.82,0.538,0.474,2.137,1.969,2.195c1.178,0.046,2.131-0.245,2.928-1.121
				c0.257-0.283,0.649-0.682,0.942-0.652c1.677,0.168,2.986-0.99,4.548-1.152c0.357-0.038,0.741,0.18,1.023,0.256
				c0.161,0.282,0.331,0.445,0.341,0.618c0.198,3.151,1.176,3.779,4.129,2.63c2.446-0.953,2.891-0.704,3.21,1.794
				c0.474,3.703,2.992,5.499,6.694,4.775c2.452-0.479,3.081,0.061,2.495,2.519c-0.366,1.536,0.281,2.51,1.259,3.379
				c0.72,0.64,1.604,1.094,2.387,1.613c-0.198,0.896-0.679,1.525-1.459,1.144C106.615,511.928,105.573,513.194,104.355,514.705z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M473.588,88.178c4.205,0.587,7.918,0.233,11.604,0.287
				c3.867,0.056,7.736,0.014,11.604,0.011c3.77-0.003,7.539-0.01,11.308-0.015c3.868-0.005,7.737-0.011,11.605-0.014
				c3.769-0.002,7.538,0,11.308-0.002c3.868-0.003,7.736-0.01,11.604-0.016c3.77-0.006,7.538-0.016,11.308-0.02
				s7.538-0.003,11.308-0.005c3.868-0.002,7.736-0.005,11.604-0.009c3.77-0.004,7.539-0.01,11.308-0.015
				c3.868-0.005,7.736-0.012,11.605-0.015c3.769-0.003,7.538,0,11.308-0.002c3.868-0.002,7.736-0.009,11.604-0.012
				c3.747-0.002,7.494,0,11.383,0c0.462,2.976,0.28,5.692,0.147,8.286c-0.979,0.731-1.903,0.486-2.768,0.486
				c-67.15,0.006-134.301,0-201.452-0.016c-1.063,0-2.131,0.17-3.12-0.606c-1.281-1.004-2.77-1.387-4.824-0.154
				c1.069-2.775,2.07-4.95,1.216-7.692c7.2-1.607,14.229-3.134,21.235-4.753c6.976-1.611,14.015-2.981,20.864-4.762
				c-0.095-1.287-0.649-1.416-1.271-1.429c-0.793-0.018-1.587-0.016-2.38-0.016c-14.184,0-28.368,0.004-42.552-0.008
				c-1.081-0.001-2.181,0.171-3.32-0.22c-0.083-2.495-2.369-3.59-3.66-5.3c-1.138-1.507-3.151-0.766-3.97,1.101
				c-0.231,0.528-0.365,1.1-0.504,1.528c-1.048,0.494-1.913,0.321-2.873,0.099c-0.736-2.077-0.542-3.973,1.188-5.677
				c45.534,0,91.144,0,137.083,0c-0.776,0.981-1.682,0.976-2.426,1.163c-20.953,5.246-41.916,10.453-62.865,15.718
				C476.5,86.425,474.882,86.312,473.588,88.178z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M612.236,561.829c0.559,0.312,0.741,0.509,0.896,0.488
				c4.123-0.541,6.42,2.505,9.171,4.652c0.531,0.415,0.508,1.044,0.505,1.637c-0.041,9.217-0.086,18.433-0.135,27.648
				c-0.001,0.09-0.069,0.179-0.195,0.481c-19.295,0-38.68,0-58.187,0c-0.995-2.184-1.106-4.581-1.489-6.888
				c-0.202-1.219,0.09-2.327,1.599-3.1c2.098-1.076,1.869-3.183-0.221-4.494c-2.292-1.438-2.306-3.874-2.184-6.041
				c0.127-2.252-0.09-4.088-1.982-5.495c-0.476-0.354-0.891-0.818-0.926-1.465c-0.142-2.569-1.439-4.919-1.26-7.585
				c0.12-1.775-0.626-3.512-1.355-5.128c-0.686-1.521-0.283-2.721,0.716-3.8c0.735-0.794,1.592-1.495,2.471-2.133
				c0.739-0.537,1.803-0.941,2.443-0.191c1.177,1.378,2.367,1.002,3.743,0.563c1.938-0.617,2.332-0.355,2.881,1.582
				c0.546,1.931,0.729,2.052,3.139,2.088c0.824-0.838-0.238-1.672,0.022-2.662c1.002-1.338,2.245-2.507,4.135-2.876
				c2.88-0.561,2.873-0.599,2.822-3.584c-0.012-0.693-0.074-1.389-0.046-2.081c0.07-1.704-0.395-3.119-1.948-4.045
				c-0.696-0.415-1.25-0.978-1.37-1.961c0.538-0.843,1.816-0.484,2.442-1.302c0.343-0.448,0.771-0.427,1.266-0.059
				c1.719,1.279,2.384-0.13,3.171-0.986c-0.126-1.791-2.147-1.809-2.27-3.685c0.474-1.173,2.361-2.329,0.686-4.914
				c3.416,0.981,6.075,1.218,7.782,3.673c0.382,0.548,0.955,1.16,1.554,1.323c2.456,0.667,3.282,2.942,4.84,4.512
				c2.915,2.939,4.759,6.668,7.534,9.683c1.799,1.954,3.718,3.592,6.174,4.91c2.182,1.171,3.669,3.8,4.587,6.386
				c0.192,0.541-0.108,0.929-0.34,1.368C612.333,559.444,612.525,560.661,612.236,561.829z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M118.758,250.832c0.38-1.769-0.166-3.01-1.922-3.724
				c-1.106-0.449-1.937-1.325-2.699-2.026c-0.003-1.847,1.092-2.849,2.208-3.777c-0.228-1.09-0.338-2.084-0.652-3.008
				c-0.474-1.395-0.927-2.847-2.696-3.234c-0.448-0.098-0.973-0.435-1.209-0.817c-2.318-3.77-5.964-2.921-9.417-2.868
				c-0.026-0.793,0.15-1.005,2.957-3.44c-0.013-1.124-0.689-2.024-1.671-2.112c-2.29-0.206-2.122-1.535-1.609-3.036
				c-2.178-1.086-2.178-1.086-4.747,0.256c-0.501-2.498-1.988-3.304-4.48-2.826c-1.101,0.211-2.312-0.315-3.219-1.384
				c0.526-1.186,2.358-1.584,1.973-3.374c-0.163-0.754,0.784-0.888,1.459-1.137c2.129-0.782,2.8-1.817,2.42-4.071
				c-0.253-1.508,0.019-2.083,1.636-2.384c2.303-0.429,4.206-1.986,6.412-2.811c2.237-0.836,3.017-1.052,4.448,0.576
				c1.411,1.605,3.078,2.26,5.122,2.644c2.11,0.397,4.16,1.215,6.17,2.015c1.34,0.533,2.118,1.728,2.661,3.061
				c0.409,1.002,0.759,2.193,2.191,2.005c1.351-0.177,2.523-0.708,2.599-2.346c0.107-2.329,2.313-2.2,3.645-2.942
				c1.477-0.824,3.095-1.081,4.302,0.934c0.706,1.176,2.176,1.151,3.519,0.832c0.83-0.198,1.535-0.961,2.745-0.517
				c-0.923,1.147-1.271,2.394-0.494,3.786c-1.593,1.266-2.998,2.564-1.586,4.753c1.747,0.246,2.533-1.556,4.003-1.748
				c1.525-0.199,2.937,0.404,4.384,0.673c1.347,0.25,2.627,0.855,3.941,1.291c1.395,0.462,2.947,0.728,3.143,2.79
				c-1.076,1.407-3.194,1.894-3.961,3.745c-0.157,0.38-0.227,0.756,0.092,1.235c0.839,0.675,1.577-0.178,2.342-0.452
				c1.085-0.388,1.985-0.69,2.91,0.429c0.939,1.138,1.161,2.239,0.504,3.528c-0.132,0.259-0.358,0.469-0.518,0.717
				c-1.035,1.606-1.033,3.247,0.59,3.998c1.889,0.875,1.481,3.132,3.121,4.058c0.476,0.268,0.049,1.294-0.464,1.838
				c-0.883,0.937-0.99,2.116-1.075,3.316c-0.219,3.088-1.125,5.96-2.818,8.692c0.292,1.184,1.385,2.378,0.282,3.79
				c-0.386,0.495-0.044,1.242,0.313,1.666c1.142,1.354,1.288,2.97,1.577,4.612c0.424,2.41,0.533,2.405-1.578,3.808
				c-1.069,0.71-2.111,1.521-3.281,2.008c-1.653,0.687-3.567,0.575-5.075-0.094c-2.078-0.923-3.348-0.332-4.515,1.028
				c-0.206,0.864,0.193,1.336,0.557,1.798c0.388,0.494,0.729,1.06,0.443,1.632c-1.741,3.489-0.089,6.903,0.238,10.33
				c0.084,0.88,0.905,1.69,1.375,2.509c-0.159,0.661-0.438,1.242-1.144,1.207c-2.08-0.103-2.811,1.326-3.146,2.923
				c-0.308,1.47-0.916,2.55-2.226,3.291c-0.406,0.229-0.705,0.683-0.994,1.077c-2.263,3.088-4.455,3.875-8.114,2.745
				c-1.124-0.347-2.425-0.368-3.324-1.208c-1.29-1.206-2.451-0.979-3.686,0.019c-0.37,0.299-0.716,0.713-1.363,0.603
				c-0.666-0.669-0.132-1.413-0.023-2.128c0.325-2.129-0.396-2.94-2.534-2.818c-0.764,0.044-1.513,0.508-2.456,0.025
				c-0.296-1.307,0.112-2.363,1.21-3.28c2.012-1.68,2.014-1.844,0.604-4.056c-0.368-0.576-0.773-1.128-1.156-1.685
				c0.528-1.104,1.402-1.086,2.29-0.893c4.348,0.948,5.117,0.438,5.452-3.67c0.449-0.592,1.089-0.286,1.65-0.356
				c2.458-0.308,2.99-0.943,2.917-3.398c-0.018-0.59,0.008-1.188,0.08-1.774c0.145-1.169-0.31-1.809-1.457-2.203
				c-1.288-0.442-1.252-0.545-2.117-2.012c2.424,0.017,3.267-2.743,5.601-2.725c0.663-1.751,0.782-3.212-0.881-4.332
				c-0.688-0.463-1.019-1.191-1.198-1.992c-0.672-3.005-2.281-5.208-5.514-6.093c0.4-1.267,1.783-1.342,2.244-2.333
				c0.557-1.199,0.112-2.071-0.694-3.177c-2.133,1.039-4.39,1.551-6.672,1.961L118.758,250.832z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M209.941,87.974c-0.483-1.462-1.711-1.37-2.668-1.633
				c-13.464-3.709-26.934-7.398-40.403-11.091c-6.59-1.806-13.182-3.606-19.772-5.413c-0.639-0.175-1.37-0.186-1.754-0.904
				c31.798,0,63.566,0,95.637,0c-1.749,2.881,0.299,5.804-0.985,8.636c-6.955,0.524-14.082-0.407-21.155,0.485
				c-0.091,0.012-0.144,0.33-0.379,0.916c7.349,2.253,14.959,3.291,22.3,5.33c0.203,1.388-0.897,1.718-1.69,2.184
				c-1.724,1.012-2.124,2.449-1.73,4.309c0.423,2.001,1.275,3.933,1.173,6.193c-62.84,0-125.577,0-188.409,0
				c-0.809-1.261-0.335-2.68-0.416-4.015c-0.083-1.376-0.019-2.76-0.019-3.995c1.01-0.948,2.052-0.616,3.001-0.616
				c43.431,0.023,86.861,0.064,130.292,0.104c7.933,0.007,15.865,0.023,23.798,0.013C207.79,88.476,208.893,88.703,209.941,87.974z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M510.62,498.899c0.099-0.007,0.259-0.056,0.287-0.015
				c2.053,2.993,5.14,5.09,7.053,8.214c1.598,2.609,3.198,5.18,5.419,7.397c2.163,2.161,3.247,5.151,4.643,7.869
				c0.313,0.611,0.305,1.515,0.766,1.903c2.538,2.142,2.059,5.333,2.945,8.037c0.388,1.183,0.481,2.488,1.621,3.369
				c2.123-6.487,2.124-6.487,1.566-12.156c-0.097-0.986-0.149-1.994-0.665-2.875c-2.049-3.494-2.526-7.479-3.484-11.302
				c-0.625-2.495-0.199-3.226,2.031-4.666c1.526,1.008,1.865,2.944,3.129,4.2c1.477,1.469,3.482,0.456,5.22,1.394
				c0.204,1.348,0.698,2.71,1.329,4.039c0.945,1.993,1.401,3.986,0.647,6.274c-0.715,2.165-0.841,4.469,0.468,6.615
				c0.683,1.121,1.342,2.173,2.648,2.677c1.457,0.562,1.854,1.444,1.168,3.024c-0.795,1.833-0.276,3.182,1.403,4.301
				c0.685,0.455,1.265,0.854,1.57,1.661c1.575,4.163,3.337,8.242,5.646,11.724c-0.051,1.312-0.858,1.889-1.173,2.717
				c-0.476,1.256-0.658,2.388-0.003,3.697c0.979,1.96,1.494,4.074,1.408,6.312c-0.093,2.405,1.339,4.552,1.257,6.972
				c-0.008,0.238,0.263,0.555,0.488,0.722c2.032,1.504,2.385,3.441,2.333,5.94c-0.062,2.934,0.599,5.969,4.101,7.531
				c-3.522,1.635-3.638,4.242-3.037,7.272c0.316,1.602,1.041,3.217,0.532,5.193c-4.549,0-9.094-0.017-13.639,0.011
				c-1.194,0.007-1.709-0.828-2.316-1.608c-5.971-7.658-12.146-15.166-16.997-23.631c-0.787-1.373-1.696-2.681-2.608-3.977
				c-1.367-1.945-2.578-3.878-3.075-6.325c-0.474-2.335-2.069-4.273-3.652-6.054c-3.388-3.807-6.055-8.096-8.458-12.545
				c-1.857-3.439-3.22-7.016-1.729-11.07c0.511-1.388,0.301-2.952,0.036-4.421c-0.362-2.016-0.314-3.914,0.88-5.704
				c0.82-1.231,0.965-2.806,0.388-4.011c-1.189-2.487-0.798-5.279-1.859-7.764c-0.434-1.016-0.489-2.277,0.236-3.146
				c1.728-2.069,1.634-4.361,1.213-6.767c-0.049-0.275,0.029-0.577,0.074-0.863C510.439,499.002,510.553,498.955,510.62,498.899z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M64.969,374.958c-0.629,1.739-1.768,2.601-3.798,1.836c0-30.567,0-61.185,0-91.79
				c0.578-0.709,1.404-0.716,2.17-0.808c2.649-0.319,3.636,0.186,4.586,2.678c0.58,1.522,1.298,2.924,2.505,3.978
				c1.689,1.475,3.296,3.244,5.983,2.346c0.832-0.278,1.221,0.425,1.506,1.364c-2.238,1.384-2.477,4.482-4.867,5.949
				c-0.469,0.288-0.62,1.004-0.245,1.615c0.555,0.903,1.34,1.485,2.416,1.461c0.472-0.011,0.984-0.205,1.398-0.45
				c1.585-0.938,2.599-0.44,3.31,1.342c-1.215,1.539-1.631,3.267-0.133,4.981c0.802,0.918,1.846,1.066,2.99,0.765
				c1.846-0.485,1.558-2.024,1.625-3.439c1.181-0.817,2.581-1.371,2.841-3.15c0.139-0.952,3.559-2.567,4.52-2.329
				c1.114,0.275,2.081,0.796,1.812,2.237c-0.09,0.479-0.34,0.939-0.005,1.653c2.32,1.061,4.694,2.636,7.617,1.285
				c0.9-0.416,1.576-0.069,2.089,0.59c1.158,1.485,1.084,3.562,2.334,5.017c-0.794,1.421,0.125,2.948-0.214,4.334
				c-0.437,1.789,0.092,3.32,0.684,4.898c0.54,1.438,0.791,2.885-0.039,4.291c-0.556,0.942-0.612,1.911-0.164,2.843
				c0.612,1.273,0.245,2.232-0.617,3.228c-1.313,1.518-1.781,3.521-2.824,5.209c-1.475,2.384-1.848,2.613-4.743,2.243
				c-1.551-0.198-2.94,1.291-2.801,2.986c0.047,0.578,0.785,0.942,0.387,1.834c-1.032,0.013-2.362-0.865-3.277,0.609
				c-0.738,1.188-1.049,2.251-0.466,3.61c0.366,0.853-0.046,1.722-0.564,2.508c-0.814,1.234-1.01,2.598-0.412,3.967
				c0.455,1.043,0.184,1.903-0.342,2.802c-0.5,0.854-1.194,1.712-1.329,2.637c-0.357,2.445-1.786,3.06-3.984,2.613
				c-1.333,2.413-2.676,4.689-3.852,7.048c-0.545,1.094-1.318,1.98-2.065,2.884c-0.719,0.87-1.688,1.274-2.887,0.711
				c-2.947-1.386-3.622-1.17-5.526,1.687C68.894,375.365,67.131,374.748,64.969,374.958z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M495.544,557.616c1.277,0.851,1.951,2.101,3.096,2.913
				c1.735,1.23,3.283,1.347,4.953,0.311c3.742-2.321,8.3-0.93,10.008,3.145c0.491,1.172,0.733,2.466,0.958,3.728
				c0.514,2.877,1.869,5.108,4.324,6.809c3.196,2.214,5.967,4.904,8.022,8.267c0.515,0.841,1.117,1.648,1.789,2.369
				c1.859,1.997,3.176,4.303,4.021,6.876c2.106,6.421,6.167,11.522,11.17,15.827c2.915,2.508,4.911,5.339,5.842,9.038
				c0.711,2.819,2.491,5.154,3.75,7.723c1.881,3.839,4.032,7.054,8.865,7.056c6.28,2.743,12.952,4.361,19.359,6.737
				c0.65,0.241,1.276,0.553,1.936,0.762c5.622,1.782,9.201,5.537,11.08,11.104c1.297,3.846,2.805,7.623,4.282,11.405
				c1.46,3.736,3.485,7.079,6.588,9.751c2.19,1.885,3.759,4.344,5.354,6.734c5.013,7.516,9.284,15.458,13.414,23.479
				c0.174,0.338,0.261,0.72,0.475,1.329c-2.088,0-3.993,0-5.99,0c-0.235-0.639-0.546-1.263-0.695-1.922
				c-0.706-3.104-2.105-5.732-4.787-7.612c-1.727-1.21-2.433-3.095-2.856-5.063c-0.442-2.062-1.235-3.956-2.298-5.769
				c-0.954-1.626-2.177-2.691-4.143-3.021c-2.743-0.46-2.725-0.568-2.646-3.204c0.075-2.571,0.047-2.606-2.637-3.295
				c-0.285-0.073-0.703-0.048-0.837-0.23c-1.954-2.683-5.103-2.252-7.778-3.062c-1.088-0.33-2.372-0.373-3.083-1.49
				c0.201-1.279,1.184-1.545,2.144-1.794c0.476-0.123,0.986-0.101,1.473-0.188c3.693-0.67,4.148-1.613,2.419-4.857
				c-0.842-1.578-1.468-3.249-1.87-4.997c-1.031-4.483-3.152-8.094-7.716-9.822c-1.347-0.51-2.354-1.479-2.87-2.854
				c-0.489-1.302-1.41-2.076-2.679-2.594c-1.003-0.409-1.928-1.008-2.891-1.514c-1.879-0.987-11.203-1.202-13.06-0.225
				c-2.692,1.416-4.496,3.524-4.894,6.659c-0.211,1.671,0.052,3.243,1.373,4.339c1.75,1.45,2.114,3.242,1.782,5.336
				c-0.37,2.335,0.158,4.513,1.108,6.645c0.644,1.443,1.229,2.924,1.688,4.435c0.568,1.876,1.793,2.894,3.564,3.659
				c2.956,1.276,5.902,1.442,8.884,0.367c0.584,1.316-0.301,1.597-0.886,2.024c-3.151,2.304-4.214,6.085-2.604,9.661
				c0.816,1.811,1.802,3.543,3.569,4.648c1.336,0.836,1.61,1.861,0.707,3.238c-1.441,2.196-0.931,4.369,0.331,6.43
				c1.135,1.852,2.324,3.663,3.034,6.066c-1.865,0.419-3.608,0.147-5.324,0.197c-0.983,0.028-1.307-0.748-1.706-1.428
				c-1.658-2.822-3.197-5.694-4.333-8.783c-3.594-9.773-6.478-19.751-8.593-29.943c-0.666-3.205-1.963-5.996-4.246-8.344
				c-2.182-2.243-4.055-2.659-6.977-1.551c-1.341,0.509-2.576,1.438-4.232,1.283c-0.295-2.601,0.429-4.88,1.76-6.84
				c2.535-3.732,2.232-8.143-1.649-11.372c-0.669-0.557-0.91-1.273-1.195-1.999c-0.634-1.61-1.674-2.932-2.958-4.041
				c-2.469-2.133-5.182-3.222-8.473-1.899c-2.103,0.843-4.193,0.85-6.233-0.53c0.16-1.533,1.481-1.86,2.35-2.549
				c4.016-3.182,4.537-5.311,2.281-9.953c-0.737-1.518-1.431-3.045-1.958-4.649c-1.707-5.191-5.361-8.691-9.941-11.375
				c-1.401-0.821-2.978-1.129-4.55-1.271c-2.79-0.255-4.675-2.014-6.591-3.734c-0.696-0.624-0.826-1.521-0.916-2.439
				c-0.592-6.03-2.238-11.666-6.278-16.38c-0.808-0.941-1.118-2.108-1.299-3.279C497.875,567.229,497.708,562.248,495.544,557.616z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M496.644,389.464c-0.072-1.587,1.032-2.617,2.04-3.664
				c3.002-3.112,3.56-5.521,1.689-9.322c-1.004-2.038-0.597-3.578,0.953-5.262c1.82,0.774,3.776,1.4,5.808,0.439
				c0.352-0.166,1.008-0.268,1.031-0.459c0.252-2.06,1.893-3.5,2.37-5.405c0.242-0.967-0.233-1.91-1.346-2.374
				c-1.093-0.456-2.148-1.009-3.185-1.585c-1.087-0.605-1.423-1.429-0.29-2.292c1.349-1.028,1.458-2.473,1.604-3.975
				c0.227-2.33,1.11-2.938,3.502-2.479c2.631,0.505,5.078-0.63,7.633-0.832c1.206-0.096,1.407-1.268,1.358-2.312
				c-0.104-2.21,0.674-4.02,2.167-5.648c0.899-0.98,1.71-1.849,3.155-1.953c1.369-0.098,2.377-0.832,2.981-2.138
				c0.894-1.931,2.416-2.205,4.444-1.947c3.256,0.413,6.423,0.167,8.854-2.632c0.985-1.134,2.397-0.706,3.593-0.145
				c2.063,0.967,4.109,1.975,6.124,3.039c1.924,1.016,3.757,1.59,5.889,0.383c1.32-0.747,2.652-0.514,3.547,1.027
				c0.383,0.661,1.065,1.159,1.651,1.686c0.376,0.337,0.779,0.645,0.805,1.185c0.193,4.047,1.261,8.001,1.277,12.066
				c0.015,3.476,1.089,4.433,4.647,4.134c0.565-0.047,1.119-0.507,1.694-0.044c0.15,0.121,0.279,0.269,0.594,0.576
				c-0.93,1.86-0.792,3.869-0.114,5.883c0.124,0.369,0.114,0.783,0.175,1.175c0.125,0.799-0.149,1.438-0.953,1.587
				c-0.923,0.171-1.698-0.443-1.688-1.248c0.021-1.604-0.697-2.609-1.796-3.31c-0.726-0.294-1.109,0.062-1.499,0.352
				c-1.246,0.927-2.306,0.637-3.475-0.277c-1.955-1.527-3.255-1.459-4.813,0.372c-0.755,0.887-1.556,1.348-2.54,1.034
				c-1.707-0.545-3.136,0.033-4.51,0.834c-1.432,0.835-2.913,1.18-4.552,1.037c-0.583-0.051-1.198-0.006-1.768,0.128
				c-2.208,0.522-2.9,1.416-2.982,3.725c-0.092,2.585-1.994,6.019-4.202,7.587c-1.269,0.9-1.734,2.577-0.897,3.912
				c0.482,0.77,1.028,1.404,0.885,2.405c-0.388,2.707-0.274,2.893,2.087,3.838c0.358,0.144,0.783,0.131,1.137,0.751
				c-0.633,2.924-0.57,5.812,3.261,6.802c-0.079,0.568-0.345,0.982-0.736,0.923c-1.309-0.198-2.539-0.348-3.495,0.879
				c-0.704,0.903-0.839,1.407-0.676,3.646c-0.686,0.682-1.438,1.28-2.458,1.437c-0.467-0.841-0.013-1.536,0.227-2.286
				c0.782-2.448-0.604-4.105-3.082-3.517c-1.182,0.281-1.639-0.218-2.117-1.021c-1.701-2.86-2.44-2.885-5.626-1.609
				c-2.521,1.009-5.002,2.467-7.93,2.109c-1.317-0.16-2.488,0.521-3.919,0.918c-0.683-3.314-4.005-3.748-6.07-5.589
				c-0.807-0.719-2.282-0.247-3.418,0.24c-0.725,0.312-1.153,1.104-1.96,1.192c-1.394-0.583-1.111-2.019-1.486-2.856
				C499.176,389.412,497.742,390.42,496.644,389.464z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M414.819,406.415c2.688-0.455,4.688,0.48,6.121,2.638
				c0.936,1.409,1.568,2.987,2.049,4.605c1.671,5.619,5.069,9.521,10.798,11.301c2.937,0.912,5.138,2.945,6.981,5.349
				c3.183,4.148,5.739,8.665,6.326,13.918c0.95,8.511,4.283,16.017,8.92,23.067c0.696,1.059,1.487,2.1,1.854,3.521
				c-1.686,0.526-3.481,0.636-4.961,1.714c-2.548,1.859-2.838,3.38-0.953,5.936c1.294,1.757,2.648,3.47,3.904,5.252
				c0.74,1.05,1.635,2.12,1.95,3.315c0.903,3.424,3.154,5.908,5.387,8.432c0.885,1.001,1.293,2.02,1.34,3.444
				c0.087,2.676,0.749,5.253,2.851,7.239c1.279,1.21,1.89,2.766,1.694,4.574c-0.269,2.498,0.456,4.662,2.027,6.631
				c0.859,1.077,1.456,2.358,2.207,3.525c0.996,1.551,2.46,2.566,4.061,3.398c1.507,0.784,2.866,1.753,4.087,2.941
				c1.254,1.222,2.156,2.623,2.244,4.396c0.246,4.943,2.533,9.347,3.665,14.055c0.26,1.08,0.973,1.929,1.771,2.704
				c2.319,2.257,3.481,5.052,4.669,8.08c2.096,5.344,3.006,10.848,3.901,16.409c0.211,1.309,0.706,2.472,1.562,3.48
				c3.465,4.083,5.003,8.943,5.691,14.141c0.549,4.143,3.419,7.678,7.546,8.323c8.14,1.271,12.698,6.233,15.189,13.669
				c0.438,1.306,1.189,2.503,1.742,3.773c1.567,3.608,1.148,5.316-1.858,7.706c-0.776,0.616-1.641,1.135-2.351,1.817
				c-1.322,1.271-1.052,2.897,0.654,3.526c1.781,0.657,3.761,1.165,5.505,0.198c4.329-2.4,9.291,1.392,10.787,5.151
				c0.411,1.034,0.9,1.867,1.781,2.628c2.935,2.535,3.425,5.697,1.457,9.065c-1.326,2.269-2.246,4.638-2.389,7.265
				c-0.1,1.847,0.507,2.378,2.288,1.928c1.047-0.265,2.037-0.76,3.046-1.168c3.184-1.287,4.455-0.945,6.807,1.617
				c1.729,1.886,2.807,4.059,3.321,6.54c2.198,10.589,5.172,20.953,8.901,31.107c1.164,3.169,2.924,6.032,4.544,9.299
				c-1.609,0.437-3.036,0.235-4.607,0.177c-1.523-2.154-2.814-4.492-3.719-6.996c-2.931-8.104-5.646-16.279-7.503-24.713
				c-0.426-1.934-0.823-3.874-1.258-5.807c-0.461-2.047-1.145-4.004-2.478-5.673c-1.47-1.841-2.163-2.025-4.316-0.977
				c-1.257,0.612-2.505,1.202-3.924,1.294c-3.078,0.2-5.145-1.632-5.221-4.721c-0.081-3.335,0.601-6.469,2.448-9.335
				c1.624-2.522,1.494-3.386-0.652-5.362c-0.69-0.635-1.087-1.383-1.499-2.172c-0.505-0.963-0.982-1.961-1.628-2.826
				c-1.853-2.484-2.883-2.763-5.861-1.876c-2.95,0.877-5.834,1.11-8.434-1.017c-0.578-0.473-1.223-0.681-1.896-0.167
				c-0.779,0.595-0.74,1.358-0.326,2.121c1.512,2.78,2.664,5.731,4.417,8.408c3.186,4.866,5.163,10.235,5.939,16.029
				c0.229,1.704,0.099,3.333-0.379,4.991c-0.839,2.91-0.145,4.204,2.716,4.729c1.562,0.288,2.811,0.874,3.812,2.059
				c1.1,1.301,2.493,1.892,4.198,1.831c0.688-0.025,1.392,0.023,2.072,0.136c3.699,0.612,5.967,2.63,6.458,6.373
				c0.729,5.561,2.987,10.427,5.967,15.068c1.693,2.638,3.422,5.28,4.527,8.533c-4.033,0-7.762,0-11.623,0
				c-1.405-2.214-2.514-4.61-3.254-7.208c-0.511-1.789-0.283-3.358,0.946-4.712c2.229-2.453,2.552-5.254,1.727-8.329
				c-0.231-0.859-0.335-1.753-0.503-2.63c-0.548-2.865-1.39-3.708-4.218-4.126c-0.782-0.115-1.581-0.135-2.373-0.159
				c-0.923-0.028-1.833-0.183-2.433-0.914c-1.809-2.206-4.387-2.97-6.924-3.829c-0.75-0.254-1.514-0.473-2.25-0.762
				c-1.268-0.498-1.859-1.334-1.512-2.786c1.886-7.898,0.13-15.11-4.141-21.863c-1.11-1.757-2.034-3.632-3.033-5.458
				c-1.797-3.287-2.745-6.8-2.627-10.553c0.218-6.88-1.647-13.33-3.944-19.701c-1.096-3.037-2.721-5.724-5.165-7.87
				c-2.369-2.08-3.987-4.659-5.043-7.602c-1.618-4.512-3.786-8.664-6.878-12.405c-2.465-2.982-3.976-6.625-5.561-10.146
				c-3.576-7.948-7.334-15.823-9.718-24.26c-1.259-4.453-3.645-8.332-6.714-11.797c-1.446-1.632-2.905-3.262-4.237-4.986
				c-2.794-3.62-4.499-7.637-4.53-12.323c-0.033-5.021-1.264-9.802-3.321-14.382c-1.99-4.43-3.923-8.885-5.896-13.322
				c-1.851-4.163-4.494-7.604-8.378-10.175c-2.883-1.908-3.465-5.14-3.628-8.393c-0.02-0.395-0.029-0.808,0.065-1.186
				c2.225-8.925-2.065-15.767-7.217-22.274c-1.722-2.175-3.832-4.019-6.219-5.496c-3.484-2.156-6.027-5.146-7.617-8.896
				c-2.163-5.106-4.394-10.194-5.682-15.623c-0.734-3.093-1.659-6.117-3.196-8.927C415.125,408.901,414.816,407.771,414.819,406.415z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M253.163,703.067c-24.227,0-48.278,0-72.566,0c0.955-4.598,1.752-9.1,3.657-13.208
				c1.325-2.855,3.504-5.299,6.16-7.108c1.74-1.186,3.309-2.451,4.47-4.234c1.315-2.021,3.078-3.585,5.165-4.818
				c2.301-1.359,4.51-2.876,6.828-4.204c2.004-1.148,3.371-2.696,3.921-4.964c0.643-2.645,2.419-4.503,4.509-6.047
				c2.004-1.48,3.737-1.41,5.158,0.638c1.83,2.637,3.873,2.843,6.656,1.843c3.143-1.129,5.989-0.707,8.129,2.322
				c1.364,1.932,3.56,2.76,5.741,3.503c2.803,0.955,5.666,1.719,8.174,3.442c2.339,1.608,3.609,3.849,4.468,6.428
				c0.062,0.187,0.058,0.395,0.12,0.581c1.44,4.353,2.049,8.655,0.783,13.27c-0.771,2.811-0.512,5.9-0.74,8.862
				C253.707,700.544,253.817,701.755,253.163,703.067z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M259.068,143.184c-1.062,2.28-0.076,3.779,1.595,4.966
				c1.129,0.801,2.342,1.485,3.534,2.193c1.658,0.984,3.393,1.232,5.037,0.08c1.629-1.143,3.141-0.912,4.713,0.022
				c1.747,1.038,3.598,1.638,5.547,1.092c1.68,2.574-1.406,3.48-1.431,5.369c-2.397,0.679-4.691,0.322-6.871-0.196
				c-5.981-1.423-12.063-2.27-18.088-3.439c-2.697-0.523-5.066-1.568-7.018-3.583c-1.382-1.428-3.113-2.444-4.815-3.465
				c-4.512-2.707-9.288-4.892-14.059-7.086c-0.517-0.238-1.062-0.589-1.637-0.025c0.373,1.075,1.453,1.259,2.27,1.744
				c3.919,2.328,7.849,4.638,11.552,7.309c1.851,1.335,3.709,2.677,5.168,4.451c1.64,1.994,3.758,3.271,6.162,3.965
				c6.75,1.95,13.54,3.767,20.31,5.649c2.708,0.753,5.487,0.491,8.113,0.494c0.878,0.978,0.646,1.961,0.639,2.956
				c-0.02,2.717,2.342,4.321,4.871,3.381c0.276-0.103,0.515-0.301,0.789-0.41c1.963-0.781,2.449-0.604,3.56,1.243
				c0.914,1.519,1.713,3.143,3.73,3.821c-1.126,1.936-1.992,3.804-4.485,3.873c-0.815,0.022-1.525,0.35-2.206,0.794
				c-0.929,0.605-1.889,0.732-3.065,0.643c-1.942-0.147-3.659,0.542-4.541,2.598c-0.692,1.612-1.868,2.736-3.678,3.168
				c-1.779,0.424-3.351,1.253-4.052,2.897c-1.276,0.368-2.149-0.032-2.963-0.421c-0.789-4.223-0.789-4.223,1.937-7.633
				c-1.86-2.643-3.014-2.786-5.099-0.646c-1.014,1.041-1.7,2.487-3.512,2.799c-1.152-1.501-2.86-2.123-4.561-2.041
				c-0.509-1.046-0.243-1.537,0.38-1.811c1.925-0.847,2.71-2.536,3.242-4.382c0.521-1.805,1.081-3.616,1.38-5.463
				c0.373-2.298-0.359-3.507-1.914-3.715c-1.936-0.259-3.101,0.834-3.375,3.177c-0.057,0.487-0.089,0.976-0.127,1.398
				c-2.731,1.762-2.731,1.762-4.772-0.108c-1.836-1.683-3.986-1.333-5.362,0.866c-0.151,0.243-0.34,0.463-0.697,0.942
				c-1.083-1.765-2.553-2.368-4.424-1.57c-0.729,0.31-1.489,0.09-1.943-0.383c-1.414-1.471-3.541-2.06-4.532-4.118
				c-0.761-1.58-3.256-1.713-4.676-0.519c-0.444,0.373-0.576,1.053-1.237,1.285c-0.544-0.013-0.789-0.398-0.942-0.908
				c-0.605-2.008-2.131-2.565-3.958-2.204c-1.076,0.213-1.932,0.024-2.819-0.466c-0.346-0.191-0.699-0.401-1.076-0.5
				c-2.095-0.545-4.312-0.695-5.919-2.523c-0.447-0.508-1.468-0.75-2.245-0.499c-2.141,0.689-3.31-0.79-4.052-2.17
				c-1.026-1.911-1.762-3.949-3.431-5.458c-0.216-0.196-0.123-0.734-0.164-1.047c0.807-0.91,1.866-0.86,2.791-1.136
				c1.509-0.45,2.706-1.544,2.328-2.981c-0.514-1.953,0.477-3.851-0.315-5.785c-0.541-1.319,0.174-2.149,1.671-2.483
				c1.159-0.259,2.346-0.386,3.512-0.618c2.637-0.526,4.387-3.938,2.979-6.225c-1.107-1.797-2.184-3.871-4.778-4.061
				c-0.488-0.036-0.903-0.381-1.081-1.179c0.609-1.168,1.817-1.774,3.158-2.098c4.076-0.984,4.325-1.261,4.518-5.533
				c0.008-0.176,0.159-0.346,0.308-0.653c3.444,1.23,7.011,2.074,10.586,2.907c1.713,0.399,3.34,1.214,4.961,1.941
				c1.005,0.451,1.694,1.212,2.141,2.324c1.328,3.308,2.536,3.955,5.898,3.472c2.525-0.363,2.938,0.08,2.473,2.651
				c-0.854,4.717,0.946,6.77,5.755,6.293c1.471-0.146,2.513-0.014,3.222,1.635c-1.743,0.547-2.141,2.03-2.241,3.625
				c-0.126,2.027,1.124,2.958,2.969,2.075C256.034,143.104,257.325,142.976,259.068,143.184z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M194.567,520.796c1.118-2.324,2.497-4.462,3.495-6.815
				c1.697-4.006,2.764-8.056,1.576-12.423c-0.811-2.979,0.228-5.427,2.24-7.609c1.422-1.542,2.99-2.917,4.727-4.061
				c4.627-3.049,6.941-7.506,7.875-12.781c2.193-12.396,7.103-23.879,11.969-35.352c2.422-5.712,6.134-10.604,11.234-14.362
				c3.526-2.599,5.934-6.128,7.265-10.286c0.849-2.652,2.51-4.635,4.318-6.647c5.52-6.145,9.614-13.069,10.71-21.421
				c0.315-2.401,1.413-4.346,2.881-6.081c2.773-3.277,5.17-6.719,6.666-10.805c0.67-1.831,2.224-3.337,2.903-5.166
				c1.659-4.467,4.838-8.154,6.292-12.702c0.311-0.973,0.995-1.702,1.682-2.421c3.647-3.822,6.777-7.986,9.081-12.789
				c0.931-1.94,2.423-3.61,3.645-5.414c0.33-0.486,0.708-0.98,0.444-1.731c-1.164-1.195-2.073,0.312-3.455,0.455
				c0.748-2.886,0.769-5.836,2.838-8.197c1.052-1.201,1.706-2.675,2.276-4.156c1.105-2.872,2.413-5.651,3.833-8.374
				c1.29-2.472,2.355-5.002,2.867-7.765c0.478-2.573,2.05-4.709,3.04-7.081c0.614-1.472,0.971-2.978,1.315-4.533
				c1.05-4.743,2.76-9.188,6.991-12.438c0.475,1.571,0.639,2.872,0.327,4.295c-0.614,2.805-1.144,5.633-1.588,8.47
				c-0.438,2.795-1.375,5.29-3.223,7.51c-2.832,3.402-4.225,7.357-3.857,11.843c0.016,0.197,0,0.396,0,0.595
				c0.004,2.379,0.45,4.87-0.115,7.105c-0.55,2.175-3.027,3.257-4.496,4.985c-2.055,2.419-3.419,5.048-3.726,8.243
				c-0.285,2.958-0.763,5.896-1.075,8.852c-0.326,3.096-1.438,5.558-4.158,7.454c-6.502,4.531-10.327,10.819-11.387,18.733
				c-0.387,2.888-1.307,5.58-3.066,7.955c-1.366,1.844-2.314,3.923-3.202,6.024c-0.927,2.195-1.875,4.342-3.662,6.049
				c-1.263,1.206-1.586,2.957-1.926,4.601c-1.001,4.849-1.95,9.709-2.878,14.572c-0.622,3.261-1.712,6.277-3.869,8.887
				c-0.874,1.058-1.523,2.32-2.172,3.543c-2.107,3.975-4.704,7.521-8.103,10.539c-4.279,3.802-5.353,9.102-6.146,14.453
				c-0.116,0.782-0.171,1.582-0.161,2.372c0.021,1.546-0.638,2.538-2.011,3.316c-3.25,1.842-5.762,4.507-7.844,7.583
				c-4.36,6.438-8.086,13.188-9.865,20.836c-0.923,3.971-2.856,7.064-6.497,9.135c-2.317,1.317-4.381,3.062-6.372,4.865
				c-3.822,3.463-6.609,7.599-8.386,12.448c-1.192,3.253-2.118,6.609-3.661,9.729C198.913,517.357,197.326,519.584,194.567,520.796z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M62.032,703.187c0.748-2.052,1.817-3.342,2.855-4.622
				c5.33-6.57,11.508-12.3,17.857-17.849c2.328-2.034,5.248-2.704,8.204-3.185c2.346-0.383,4.723-0.606,7.046-1.09
				c6.364-1.325,8.673-5.751,6.197-11.702c-0.152-0.365-0.315-0.728-0.459-1.097c-1.584-4.04,0-6.857,4.285-6.948
				c5.552-0.117,10.645-2.209,15.971-3.264c2.228-0.441,4.237-1.695,5.961-3.233c3.345-2.984,6.333-6.282,8.458-10.271
				c2.827-5.305,7.354-7.449,13.34-6.235c0.774,0.156,1.542,0.379,2.285,0.646c5.697,2.047,7.322,6.642,4.086,11.79
				c-0.884,1.407-1.438,2.815-1.279,4.462c0.168,1.751-0.141,3.347-1.307,4.729c-0.25,0.297-0.441,0.667-0.579,1.033
				c-0.734,1.958-1.485,3.709-3.9,4.364c-1.51,0.41-2.367,2.039-3.093,3.473c-1.642,3.241-4.328,5.272-7.51,6.729
				c-1.458-0.905-0.976-2.183-0.755-3.271c0.293-1.449,0.824-2.851,1.258-4.271c0.287-0.937,0.74-1.864,0.081-2.924
				c-1.739-0.455-2.711,0.804-3.682,1.857c-1.687,1.831-2.955,3.975-4.172,6.134c-1.605,2.848-3.184,5.711-4.709,8.602
				c-0.729,1.382-1.675,2.377-3.205,2.885c-5.328,1.766-9.41,5.381-13.285,9.235c-1.088,1.082-2.152,1.939-3.697,2.282
				c-2.533,0.562-4.451,1.999-5.747,4.324c-1.157,2.075-2.53,4.028-3.746,6.071c-0.566,0.95-1.266,1.365-2.4,1.362
				C85.094,703.174,73.797,703.187,62.032,703.187z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M212.831,351.034c-0.379,1.115-1.127,0.922-1.606,1.214
				c-1.592,0.971-2.077,2.44-1.234,4.2c0.565,1.179,0.48,2.318,0.12,3.453c-0.367,1.157-0.63,2.341-0.436,3.508
				c0.279,1.672-0.381,2.747-1.545,3.876c-1.746,1.694-3.871,3.122-4.581,5.706c-0.132,0.48-0.77,0.914-1.274,1.184
				c-2.463,1.319-4.704,2.883-6.374,5.18c-0.206,0.283-0.653,0.391-1.08,0.632c-1.046-0.742-1.36-1.759-1.098-2.853
				c0.604-2.513-0.732-4.008-2.655-5.078c-1.979-1.103-3.824-0.558-5.432,0.945c-0.142,0.132-0.326,0.218-0.492,0.323
				c-2.593,0.012-4.502-1.586-6.706-2.78c0.248-2.256,0.09-4.553,1.354-6.6c0.197-0.319,0.37-0.799,0.279-1.129
				c-0.759-2.759-0.677-5.819-2.937-8.219c1.712-2.678-0.221-4.559-1.944-6.037c-1.809-1.552-4.149-2.483-6.376-3.753
				c-0.41-2.916-1.733-3.983-4.676-4.936c-2.802-0.908-5.641-0.765-8.329-0.907c-0.638-1.077-0.122-1.721,0.318-2.378
				c0.496-0.741,1.033-1.455,1.487-2.219c0.563-0.947,0.519-1.884-0.229-2.745c-0.688-0.792-1.47-0.948-2.362-0.354
				c-0.273,0.182-0.54,0.176-0.988-0.081c-1.348-1.564,0.604-2.451,0.9-3.801c2.032-0.167,4.032,0.111,5.883-0.684
				c0.479-1.84-0.931-3.006-1.391-4.56c0.266-0.971,0.807-1.684,2.005-1.699c1.026-0.013,2.001-0.359,2.761-0.98
				c2.011-1.647,4.597-2.234,6.683-3.994c1.146,3.504,0.522,7.009,1.167,10.459c1.915-1.463,2.41-2.646,4.63-10.777
				c2.68-1.065,5.418-2.143,8.146-3.247c0.477-0.193,0.895-0.309,1.404-0.075c0.441,0.202,1.02,0.505,1.4,0.372
				c2.465-0.865,4.717,0.704,7.15,0.518c1.81-0.138,4.205,2.552,4.686,4.741c0.083,0.381-0.039,0.813,0.072,1.18
				c0.434,1.436,0.199,2.936,0.411,4.352c0.249,1.658,2.6,0.769,3.35,2.021c1.461,2.441,4.25,3.822,5.225,6.645
				c0.188,0.547,0.443,1.167,0.863,1.521c1.416,1.195,1.721,2.834,2.021,4.5c0.035,0.195,0.013,0.407,0.08,0.588
				c1.027,2.803,1.632,5.515,0.141,8.466C210.836,348.287,212.087,349.907,212.831,351.034z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M330.846,673.328c0.449-2.571,0.331-5.15-1.315-7.508
				c-0.594-0.851-0.198-1.92,0.245-2.844c1.765-3.683,1.39-7.271-0.351-10.866c-1.64-3.387-2.93-6.99-6.398-9.077
				c-0.909-0.547-0.839-1.494-0.967-2.367c-0.475-3.23-2.356-5.661-4.72-7.676c-2.418-2.061-5.235-0.981-5.584,2.216
				c-0.262,2.4-0.905,4.655-1.582,6.927c-0.318,1.067-0.581,2.182-0.275,3.222c1.102,3.742-0.195,7.033-1.887,10.195
				c-0.986,1.844-1.375,3.709-1.122,5.72c0.213,1.693,1.013,3.081,2.824,3.404c1.812,0.323,2.683,1.348,3.18,3.057
				c0.897,3.08,2.663,5.729,4.539,8.351c-0.251,1.224-1.327,1.893-2.022,2.822c-1.707,2.284-2.552,4.722-1.27,7.482
				c0.627,1.35,0.636,2.629,0.134,4.018c-1.019,2.818-0.917,5.63,0.252,8.404c0.545,1.293,0.831,2.628,0.356,4.198
				c-5.175,0-10.371,0-15.843,0c0.651-3.42,0.787-6.664,0.632-9.923c-0.049-1.039,0.535-1.881,1.19-2.652
				c0.898-1.058,1.761-2.146,2.685-3.181c1.359-1.521,2.32-3.251,2.524-5.278c0.228-2.256,1.508-3.563,3.452-4.436
				c0.903-0.405,1.8-0.839,2.643-1.354c0.589-0.36,1.146-0.869,0.947-1.689c-0.177-0.735-0.781-1.434-1.412-1.345
				c-2.812,0.396-3.158-1.806-4.492-3.43c-2.213-2.694-2.96-5.446-2.881-8.739c0.036-1.466-0.321-2.951-0.59-4.411
				c-0.197-1.067-0.905-1.823-1.994-1.929c-1.03-0.099-1.599,0.653-1.941,1.541c-0.279,0.725-0.36,1.57-1.254,1.876
				c-1.904-1.552-2.221-4.222-0.292-5.387c2.76-1.666,3.551-4.09,3.716-6.981c0.094-1.663,0.863-3.032,1.851-4.299
				c1.036-1.328,2.13-2.611,3.166-3.939c1.509-1.937,1.208-3.7-0.851-4.976c-1.132-0.701-2.355-1.004-3.698-0.687
				c-4.455,1.055-7.555,4.479-8.126,9.119c-0.118,0.957-0.363,1.735-1.211,2.231c-1.831,1.071-2.744,2.808-3.41,4.712
				c-0.505,1.444-1.271,2.708-2.562,3.553c-2.849,1.866-3.816,4.713-4.109,7.874c-0.248,2.664-0.332,5.343-0.544,8.011
				c-0.075,0.938,0.21,2.014-0.822,2.784c-1.31-0.469-1.67-1.558-1.727-2.776c-0.064-1.384-0.008-2.773-0.034-4.16
				c-0.019-1.09,0.056-2.38-1.189-2.79c-1.458-0.48-2.569,0.485-3.139,1.665c-1.461,3.021-2.93,6.036-3.384,9.461
				c-0.6,4.525-0.149,9.117-1.062,13.618c-1.07,5.274-0.749,10.665-1.205,15.993c-0.049,0.577-0.167,1.149-0.28,1.903
				c-4.698,0-9.289,0-14.166,0c0.285-5.573,0.313-11.009,1.583-16.324c0.771-3.228,2.941-5.604,5.409-7.644
				c1.902-1.572,3.097-3.49,4.057-5.734c1.796-4.196,3.529-8.391,4.554-12.861c0.588-2.567,2.141-4.65,3.827-6.633
				c3.739-4.395,7.785-8.467,12.445-11.889c2.607-1.915,4.202-4.356,4.671-7.617c0.511-3.541,2.845-5.796,5.877-7.38
				c2.291-1.196,4.706-2.17,7.227-2.684c5.378-1.096,10.049,0.414,13.704,4.511c1.288,1.443,2.647,2.634,4.363,3.478
				c1.155,0.568,2.265,1.229,3.402,1.832c1.463,0.777,2.429,1.956,3.1,3.471c2.446,5.515,5.018,10.938,6.641,16.824
				c1.37,4.967,4.028,9.475,7.933,13.06c1.362,1.25,2.235,2.728,2.504,4.594c0.153,1.068,0.314,2.238,0.855,3.13
				c2.28,3.765,2.72,7.985,3.438,12.184c0.898,5.252,1.767,10.529,3.877,15.696c-7.547,0-14.808,0-22.057,0
				c-0.316-1.412,0.238-1.916,1.375-1.622c1.047,0.271,2.023,0.812,3.063,1.119c1.179,0.348,2.365,0.421,3.261-0.695
				c0.853-1.062,0.824-2.183,0.185-3.323c-0.437-0.777-0.869-1.559-1.363-2.299c-0.856-1.284-1.364-2.669-1.107-4.209
				c0.465-2.791-0.284-4.886-3.106-5.945c-0.244-0.092-0.41-0.408-0.589-0.637c-0.052-0.066-0.026-0.192-0.04-0.323
				c0.514-0.897,1.481-0.759,2.31-0.977c2.243-0.59,3.164-2.095,2.427-4.212c-0.621-1.782-1.639-3.387-2.793-4.885
				c-0.729-0.947-1.787-1.154-2.912-1.227C332.726,673.71,331.801,674.09,330.846,673.328z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M193.261,524.245c-2.41,2.643-3.85,5.798-5.572,8.795
				c-1.475,2.567-3.316,4.931-5.067,7.332c-3.452,4.734-6.498,9.698-9.159,14.931c-2.514,4.942-5.362,9.714-8.023,14.582
				c-1.282,2.347-2.515,4.723-3.69,7.124c-1.139,2.326-1.915,4.744-2.004,7.396c-0.082,2.424-0.502,2.791-2.888,2.604
				c-2.188-0.172-3.937,0.531-5.268,2.149c-1.607,1.952-3.584,3.333-5.756,4.586c-3.053,1.762-5.413,4.35-7.315,7.286
				c-1.378,2.128-3.018,3.973-5.163,5.258c-8.62,5.163-13.845,12.795-16.674,22.298c-1.311,4.4-3.308,8.482-6.539,11.872
				c-0.672,0.704-1.238,1.581-1.619,2.478c-1.557,3.664-4.188,6.465-7.086,9.059c-1.92,1.719-3.871,3.404-5.825,5.084
				c-3.999,3.435-7.644,7.158-10.639,11.552c-4.411,6.473-9.175,12.692-14.306,18.621c-0.573,0.662-1.165,1.308-1.956,2.192
				c-0.301-1.118,0.107-1.775,0.331-2.461c0.337-1.035,0.819-2.055,0.954-3.117c0.512-4.045,2.293-7.57,4.43-10.921
				c5.81-9.108,10.49-18.89,16.6-27.817c2.463-3.599,4.938-7.19,7.464-10.744c4.308-6.061,8.499-12.177,12.013-18.76
				c3.038-5.69,6.696-10.98,11.164-15.709c3.14-3.323,5.598-7.19,7.792-11.204c0.859-1.572,1.836-3.06,2.943-4.462
				c3.494-4.428,7.858-6.828,13.674-5.843c1.808,0.307,3.126-0.498,3.906-2.054c0.797-1.589,1.459-3.251,2.106-4.91
				c1.335-3.427,2.814-6.771,5.047-9.73c0.722-0.957,1.434-1.943,2.693-2.213c2.066-0.44,3.122-1.987,4.07-3.646
				c2.667-4.659,4.625-9.641,6.579-14.622c0.253-0.646,0.48-1.302,0.704-1.958c0.97-2.846,2.283-5.317,4.844-7.253
				c2.107-1.592,3.787-3.766,6.292-4.876C185.772,525.613,189.38,524.624,193.261,524.245z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M198.823,615.022c1.544-2.813,3.126-5.072,5.24-6.867
				c1.34-1.137,2.361-2.37,2.804-4.074c0.549-2.108,1.799-3.695,3.563-4.963c1.123-0.806,2.169-1.735,3.171-2.693
				c1.766-1.688,3.793-2.855,6.181-3.38c1.769-0.389,3.499-0.976,4.815-2.213c2.853-2.681,6.377-3.788,9.986-4.989
				c3.21-1.068,6.372-1.032,10.213-1.085c-1.764,1.536-3.13,2.735-4.506,3.923c-0.609,0.525-1.313,0.302-1.992,0.201
				c-2.723-0.403-5.087,0.438-7.225,2.068c-1.024,0.782-2.036,1.582-3.063,2.36c-1.517,1.149-3.153,1.987-5.061,2.373
				c-4.286,0.864-7.969,2.717-10.865,6.203c-1.758,2.117-3.993,3.822-5.459,6.238c-1.904,3.134-1.603,5.431,1.234,7.821
				c2.324,1.957,4.848,3.351,8.035,3.399c2.837,0.044,4.86-1.061,5.812-3.692c1.163-3.216,3.16-5.928,4.897-8.804
				c1.295-2.142,2.82-4.077,4.819-5.606c1.683-1.286,2.959-2.881,3.976-4.747c2.634-4.835,6.552-8.429,11.264-11.163
				c3.941-2.287,8.118-4.136,12.044-6.474c3.374-2.008,5.902-4.677,7.475-8.215c1.654-3.721,3.569-7.288,5.585-10.823
				c2.219-3.889,3.169-8.315,4.786-12.466c1.156-2.968,2.381-5.842,4.182-8.503c1.493-2.206,2.577-4.685,3.929-6.99
				c1.537-2.622,6.341-3.793,8.917-2.24c1.019,0.615,1.46,1.578,1.563,2.728c0.199,2.227-0.147,4.377-1.189,6.345
				c-0.926,1.751-0.939,3.424,0.075,5.075c1.752,2.854,1.899,6.025,1.781,9.206c-0.115,3.058-0.223,6.082,0.698,9.059
				c0.546,1.763,0.062,3.41-1.173,4.689c-3.834,3.976-5.989,8.786-7.423,14.033c-0.519,1.898-1.436,3.689-2.177,5.526
				c-0.495,1.228-1.276,2.229-2.376,2.978c-1.607,1.095-2.717,2.629-3.517,4.347c-1.127,2.422-2.108,4.91-3.426,7.244
				c-0.666,1.179-0.494,2.495-0.501,3.784c-0.028,4.49-1.427,8.623-4.967,11.332c-2.522,1.929-4.25,4.277-6.076,6.713
				c-2.54,3.387-6.098,5.355-10.162,6.305c-2.739,0.64-5.335-0.065-7.602-1.71c-2.105-1.527-2.449-3.167-1.288-5.619
				c0.357-0.755,0.738-1.481,0.006-2.157c-0.713-0.658-1.443-0.295-2.172,0.11c-2.25,1.251-4.563,2.393-6.767,3.719
				c-2.38,1.432-4.87,1.513-7.468,0.978c-1.522-0.314-2.385-1.03-2.389-2.825c-0.004-2.43-1.056-3.04-3.46-2.466
				c-0.995,0.238-1.819,0.701-2.586,1.376c-2.781,2.445-5.821,2.751-8.877,0.607c-2.206-1.548-4.506-2.848-6.929-4.003
				C203.244,616.07,201.458,614.813,198.823,615.022z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M473.75,478.117c-1.336-0.658-2.68-0.308-4.117-0.072
				c1.658-2.881,1.525-3.395-1.276-4.956c-1.298-0.724-2.72-1.25-3.941-2.077c-1.588-1.075-3.072,0.157-4.76,0
				c-5.029-7.899-10.214-15.747-11.219-25.494c-0.597-5.779-3.027-10.836-6.459-15.453c-2.117-2.85-4.683-5.15-8.112-6.261
				c-4.92-1.593-8.032-4.857-9.564-9.771c-0.529-1.693-1.27-3.335-2.054-4.931c-1.25-2.544-3.447-3.735-6.206-3.98
				c-1.874-0.167-2.621,0.589-2.266,2.397c0.19,0.965,0.435,1.969,0.9,2.82c1.494,2.727,2.398,5.654,3.105,8.647
				c1.282,5.432,3.497,10.525,5.631,15.644c1.682,4.034,4.4,7.246,8.098,9.649c6.711,4.36,10.684,10.763,13.221,18.165
				c0.547,1.596,0.593,3.285,0.3,4.996c-0.619,3.619-1.154,7.257,0.549,10.854c-4.796-2.595-7.832-6.521-8.273-11.795
				c-0.679-8.116-4.144-14.777-9.419-20.713c-0.987-1.111-1.865-2.321-2.862-3.424c-2.523-2.787-4.15-6.04-5.016-9.666
				c-1.089-4.564-2.74-8.884-5.104-12.95c-1.249-2.148-2.204-4.443-2.169-7.021c0.012-0.922-0.369-1.754-0.811-2.524
				c-0.64-1.115-1.194-2.365-2.099-3.228c-4.871-4.644-7.271-10.401-8.537-16.919c-1.258-6.466-3.772-12.482-7.836-17.815
				c-2.061-2.705-3.006-6.018-3.859-9.287c-1.031-3.949-2.338-7.769-4.469-11.286c-1.345-2.221-2.145-4.676-2.73-7.199
				c-0.505-2.173-1.491-4.077-3.09-5.666c-0.929-0.923-1.873-1.718-3.233-1.969c-2.501-0.461-3.863-2.041-4.447-4.51
				c-0.524-2.214-1.285-4.332-0.459-6.669c0.489-1.385-0.033-2.764-0.881-3.928c-1.687-2.315-2.542-4.78-2.418-7.74
				c0.095-2.24-0.405-4.538-1.869-6.442c-1.831-2.383-2.021-5.411-2.555-8.119c-0.708-3.6-1.743-7.301-0.85-11.137
				c0.461-1.981-0.616-3.872-1.332-5.704c-2.094-5.364-3.729-10.861-4.797-16.513c-0.939-4.973-2.313-9.835-3.557-14.731
				c-1.001-3.943-1.786-7.925-2.513-11.922c-0.085-0.468-0.203-0.956,0.147-1.53c1.594,0.926,2.408,2.373,2.482,4.107
				c0.091,2.104,0.839,3.445,3.091,3.506c-0.017,0.942-1.175,1.25-0.805,2.5c0.309,0.968,1.749,0.643,2.706,1.476
				c-1.095,1.66-0.854,3.03,1.096,3.962c0.483,0.231,1.017,0.821,1.112,1.325c0.659,3.464,2.735,6.652,1.975,10.4
				c-0.397,1.962,0.107,3.712,1.557,5.187c0.89,0.906,1.622,1.947,1.589,3.285c-0.337,0.292-0.605,0.125-0.868,0.003
				c-0.657-0.305-1.351-0.521-1.928,0.064c-0.684,0.695-0.437,1.493,0.043,2.172c0.848,1.201,0.95,2.609,1.103,3.969
				c0.353,3.135,1.259,6.001,3.448,8.367c0.257,0.277,0.395,0.664,0.556,0.945c-0.451,1.213-1.552,0.833-2.328,1.134
				c-2.398,0.93-2.784,2.856-0.857,4.536c0.371,0.323,0.788,0.614,1.226,0.837c2.448,1.248,4.043,3.014,4.328,5.93
				c0.182,1.858,1.403,3.238,3.073,4.148c3.888,2.122,5.51,5.959,7.195,9.853c-0.667-0.477-1.298-1.02-2.011-1.415
				c-0.942-0.524-1.907-0.468-2.764,0.259c-0.924,0.784-0.963,1.799-0.333,2.669c1.879,2.595,2.129,5.446,1.685,8.49
				c-0.129,0.882,0.118,1.786,0.607,2.566c0.997,1.59,1.595,3.396,2.433,5.006c1.019,1.957,1.797,3.978,2.716,5.958
				c0.915,1.969,2.416,3.626,3.907,5.225c2.023,2.17,2.254,3.172,0.109,5.244c-0.197,0.189-0.253,0.524-0.365,0.771
				c1.494,2.332,2.176,2.541,4.213,1.314c1.357-0.817,2.674-1.711,4.077-2.437c2.26-1.167,3.686-0.841,5.136,1.212
				c1.028,1.454,1.914,3.012,2.813,4.553c6.006,10.284,10.887,21.151,16.414,31.683c2.949,5.618,5.92,11.224,8.88,16.835
				c2.241,4.249,5.203,7.977,8.517,11.409c4.709,4.879,8.798,10.146,11.996,16.182c2.595,4.896,5.793,9.47,8.688,14.207
				c2.228,3.646,4.097,7.475,5.647,11.456c1.662,4.266,3.782,8.275,6.308,12.104c1.848,2.801,3.388,5.804,5.065,8.716
				C473.967,477.772,473.859,477.945,473.75,478.117z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M365.967,367.678c3.265,3.409,6.272,6.549,6.429,11.516
				c0.081,2.553,1.487,4.901,2.5,7.271c0.816,1.91,1.807,3.756,2.264,5.806c0.446,2.006,0.015,3.259-1.403,4.15
				c-1.517,0.954-3.282,0.933-4.717-0.229c-0.838-0.679-1.521-1.555-2.253-2.359c-0.649-0.711-1.07-1.664-2.195-2.063
				c-0.962,1.067-0.322,2.096,0.027,3.033c0.765,2.057,2.048,3.842,3.218,5.68c1.231,1.932,2.077,3.998,2.808,6.185
				c2.869,8.588,6.445,16.837,12.348,23.878c1.987,2.37,3.459,5.091,4.673,7.916c2.036,4.741,5.018,9.004,6.894,13.818
				c2.235,5.734,3.87,11.659,5.315,17.625c2.938,12.136,6.337,24.142,9.853,36.117c0.137,0.467,0.436,0.898,0.278,1.421
				c-2.512-1.71-6.251-6.399-6.864-9.457c-0.749-3.735-2.777-6.74-4.726-9.824c-1.429-2.262-2.966-4.456-4.485-6.659
				c-0.683-0.988-1.404-2.003-1.555-3.18c-0.5-3.917-1.513-7.769-1.526-11.748c-0.012-3.473-0.98-6.844-1.067-10.322
				c-0.034-1.352-0.947-2.583-1.668-3.774c-2.007-3.316-4.212-6.49-6.94-9.262c-3.809-3.868-5.975-8.419-6.869-13.844
				c-0.962-5.83-2.536-11.591-6.035-16.55c-3.855-5.464-5.889-11.563-6.934-18.125c-0.873-5.479-2.703-10.727-4.594-15.944
				c-1.229-3.39-1.616-6.857-0.959-10.512c0.978-5.43,0.344-10.864-0.645-16.255c-1.089-5.944-2.303-11.869-2.949-17.885
				c-0.378-3.526-0.234-7.123-1.906-10.445c-0.407-0.809-0.3-1.968-0.182-2.935c1.228-9.978-0.086-19.745-2.192-29.482
				c-0.835-3.856-1.729-7.722-1.55-11.753c0.11-2.487,0.208-4.926,0.97-7.326c0.704-2.221,0.571-4.396-0.202-6.684
				c-1.053-3.113-2.366-6.084-3.776-9.035c-0.68-1.424-1.508-2.341-3.162-1.581c-0.499-0.929-0.354-1.78,0.172-2.359
				c2.922-3.217,2.594-7.35,3.174-11.197c0.264-1.753,0.215-3.556,0.8-5.266c0.262-0.766,0.522-1.649,0.115-2.319
				c-1.205-1.98-0.768-3.992-0.409-6.044c0.427-2.435,0.786-4.881,1.214-7.316c0.136-0.773,0.401-1.521,1.001-2.144
				c0.66,1.199,0.745,2.496,0.786,3.771c0.086,2.674,0.359,5.383,0.059,8.021c-0.643,5.636,0.372,11.061,1.55,16.49
				c0.608,2.806,1.202,5.628,2.051,8.366c2.634,8.5,3.786,17.254,3.965,26.074c0.28,13.819,3.232,27.102,7.007,40.282
				c0.895,3.124,1.951,6.207,2.087,9.532c0.253,6.18-0.878,12.034-3.683,17.54c-0.84,1.647-1.204,3.342-1.09,5.161
				c0.161,2.583-0.139,5.124-0.403,7.695c-0.633,6.166,0.037,12.271,1.636,18.26c0.201,0.754,0.577,1.474,0.957,2.162
				c0.296,0.537,0.789,0.945,1.465,0.727c0.638-0.206,0.892-0.79,0.908-1.397c0.014-0.479-0.123-0.97-0.25-1.439
				c-0.904-3.348-0.428-6.698-0.007-10.043C365.358,368.88,365.687,368.377,365.967,367.678z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M327.968,69.71c1.299,0.224,2.026,1.099,3.177,1.023
				c1.514-0.706,1.439-2.747,3.021-3.696c0.925,0.838,1.228,2.28,2.43,2.88c-0.745,1.133-1.619,1.638-2.51,2.05
				c-1.825,0.844-3.582,1.676-4.17,3.877c-0.236,0.885-1.093,1.019-1.917,1.122c-1.388,0.173-2.432,0.802-2.746,2.297
				c-0.258,1.231-1.545,1.45-2.412,2.421c0,2.167,0,4.516,0,6.896c-1.584,0.193-1.688-0.947-1.982-1.836
				c-0.391-1.182-0.903-2.399-2.272-2.441c-2.049-0.063-2.396-1.311-2.495-2.91c-0.165-2.664-1.603-3.98-4.142-3.54
				c-1.222,0.212-2.084,0.143-2.784-0.978c-1.31-2.098-2.997-2.155-4.462-0.196c-0.172,0.23-0.224,0.548-0.362,0.809
				c-0.088,0.167-0.234,0.304-0.363,0.465c-0.893,0.394-1.698,0.028-2.558-0.246c-2.304-0.736-4.259,0.408-4.485,2.762
				c-0.133,1.386-0.227,2.71-0.783,4.028c-0.698,1.654,0.732,2.957,1.169,4.443c-0.949,1.005-1.845,0.794-2.69,0.141
				c-2.349-1.815-5.067-2.12-7.894-2.009c-1.204,0.047-2.34-0.257-3.508-0.485c-2.742-0.537-4.214,0.572-4.742,3.355
				c-0.4,2.106-0.88,2.37-2.718,1.494c-2.309-1.101-3.919-0.426-4.776,2.001c-0.695,1.972-0.64,2.852,0.337,5.154
				c-0.48,1.177-1.578,1.378-2.647,1.534c-1.059,0.155-1.85,0.62-2.399,1.542c-0.873,1.463-0.779,2.604,0.584,3.625
				c0.993,0.744,1.999,1.348,1.649,2.96c-0.229,0.148-0.555,0.543-0.874,0.538c-4.069-0.068-8.055,1.435-12.176,0.583
				c-0.955-0.197-1.7-0.547-2.24-1.273c-1.092-1.47-2.404-2.319-4.322-1.937c-0.579,0.115-1.167,0.085-1.64-0.42
				c-2.974-3.18-6.18-6.181-6.688-10.911c-0.208-1.94-1.238-3.76-1.073-5.675c0.517-0.829,1.306-1.096,1.928-1.572
				c1.171-0.896,1.921-2.091,1.981-3.522c0.132-3.143,1.47-5.07,4.704-5.705c2.022-0.397,4.105-1,4.683-3.296
				c0.961-0.486,1.926-0.414,2.401,0.121c1.02,1.148,2.443,1.139,3.644,1.718c1.757,0.846,2.994,0.757,3.902-1.104
				c0.151-0.31,0.565-0.6,0.912-0.688c1.909-0.487,3.326-1.281,4.082-3.392c0.567-1.582,4.742-1.586,6.113-0.01
				c1.337,1.536,2.759,2.459,4.835,2.379c0.8-0.03,1.517,0.414,2.055,1.052c0.192,0.227,0.342,0.498,0.561,0.692
				c1.316,1.168,2.925,1.38,3.707,0.509c0.925-1.03,0.615-3.019-0.816-4.085c-1.302-0.97-1.786-1.826-0.319-3.026
				c0.696-0.57,0.583-1.408,0.593-2.204c0.016-1.187,0.075-2.373,0.144-3.558c0.07-1.212,0.64-2.055,2.127-2.436
				c0.773,0.334,0.959,1.875,2.302,1.725c1.056-0.119,1.842-0.726,2.601-1.34c1.417-1.148,1.702-4.506,0.595-6.319
				c-0.4-0.654-1.251-0.985-1.438-2.19c2.716,0.336,4.695-1.562,7.157-2.047c1.627-0.321,2.309-1.435,2.223-3.026
				c-0.061-1.129-0.079-2.188,1.121-2.811c1.477-0.767,2.956-1.969,4.644-1.554c2.095,0.516,4.079,1.501,6.085,2.343
				c0.525,0.22,0.949,0.666,1.088,1.723c-2.864,0.315-4.943,2.112-7.197,3.676c-1.721,1.194-2.583,2.908-2.065,4.541
				c0.816,2.579-0.183,3.721-2.35,4.479c-0.101,1.637,0.504,2.75,1.896,3.68c1.354-1.098,2.651-0.829,4.268-0.263
				c2.048,0.716,4.145,2.009,6.568,1.014c0.919-0.377,1.731,0.133,2.131,1.109c0.113,0.275,0.319,0.529,0.364,0.812
				c0.391,2.454,1.954,3.629,4.232,4.337c2.278,0.709,4.273,0.342,6.234-0.827C327.449,69.938,327.722,69.83,327.968,69.71z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M266.68,255.135c-0.917-3.566-3.431-5.813-5.72-8.392
				c0.877-0.78,1.695-1.643,1.623-2.961c-0.024-0.433,0.243-0.733,0.583-0.965c3.377-2.296,2.83-5.792,2.781-9.13
				c-0.005-0.378-0.126-0.754-0.25-1.446c3.284,0.166,4.721-2.855,7.348-3.918c1.26-0.51,1.077-2.012,0.716-3.191
				c-0.365-1.191-1.189-1.781-2.495-1.539c-0.555,0.103-1.102,0.545-1.831-0.021c-0.332-1.039,0.593-2.179-0.002-3.244
				c2.898-1.491,3.046-1.738,3.351-5.416c3.276-1.397,3.348-6.107,1.498-9.568c0.771-1.214,2.361,0.009,3.403-1.356
				c-0.926-2.041-2.18-4.052-3.836-6.03c0.78-1.303,1.578-2.636,2.475-4.133c1.907,1.004,3.844-0.302,5.787,0.311
				c0.707,0.223,1.332-0.225,1.578-1.014c0.323-1.036,0.578-2.105,1.468-3.093c1.236,0.181,2.476-0.494,3.681-0.662
				c0.756,0.724,0.096,1.246,0.08,1.793c-0.059,1.956,1,3.204,2.972,3.13c1.472-0.056,2.649,0.384,3.189,1.654
				c1.138,2.675,3.253,3.521,5.904,3.621c0.391,0.015,0.777,0.123,1.159,0.187c0.778,1.016,0.481,2.088,0.3,3.155
				c-0.482,2.837,1.268,4.788,4.183,4.46c1.669-0.188,3.064-0.307,4.112,1.426c0.592,0.979,1.823,0.663,2.742,0.3
				c0.795-0.314,1.49-0.886,2.374-1.435c1.438,1.519,3.131,2.613,5.729,2.635c-2.16,8.125-5.436,15.775-6.061,24.389
				c-3.084-1.721-6.175-2.727-7.239-6.171c-0.305-0.987-1.116-1.604-1.932-2.518c1.187-0.454,2.478-0.646,2.848-2.093
				c0.504-1.97-0.282-3.365-2.251-3.131c-3.016,0.359-6.192-0.058-8.998,1.556c-0.259,0.149-0.553,0.154-0.878-0.367
				c1.374-1.132,2.712-2.407,4.226-3.42c0.955-0.64,1.467-1.281,1.245-2.403c-0.229-1.16-0.326-2.437-1.878-2.668
				c-1.093-0.163-1.701-0.712-1.962-1.86c-0.533-2.338-1.789-3.032-4.011-2.377c-0.364,0.108-0.695,0.327-1.076,0.51
				c-1.062-0.799-0.959-2.344-2.397-2.913c-2.354-0.931-3.204-0.909-4.924,1.019c-1.586,1.777-1.645,1.783-3.534,0.333
				c-1.302-0.999-2.473-1.04-3.763-0.2c-1.203,0.783-0.998,1.805-0.555,2.877c0.599,1.451,0.605,2.665-1.065,3.456
				c-0.985,0.467-1.346,1.442-1.396,2.47c-0.047,0.984-0.079,1.993,0.082,2.958c0.564,3.406,0.814,3.569,4.113,3.223
				c-0.813,4.55-0.38,5.562,3.105,6.838c-0.001,2.049,1.932,3.338,2.378,5.455c0.283,1.34,2.093,1.634,3.312,1.285
				c1.916-0.548,3.166,0.175,4.843,0.986c1.838,0.888,4.288,0.576,6.467,0.839c0.5,0.061,1.144-0.7,1.652-1.16
				c1.926-1.746,3.79-1.936,5.778-0.425c1.972,1.498,3.842,3.121,5.702,5.164c-2.712,6.63-2.986,13.975-5.236,20.634
				c-0.971,0.431-1.637,0.053-2.315,0.022c-1.481-0.065-2.641,0.298-3.595,1.591c-1.063,1.441-1.597,1.491-2.443,0.196
				c-0.956-1.463-2.21-1.686-3.761-1.883c-2.393-0.304-4.979,0.044-7.035-1.771c-0.601-0.53-1.489-0.52-2.288-0.446
				c-0.837,0.077-1.564-0.069-2.168-0.702c-0.99-1.036-2.1-1.325-3.499-0.752c-1.159,0.475-2.164-0.128-2.088-1.305
				c0.223-3.417-2.008-4.472-4.646-5.136c-2.614-0.659-4.898-0.173-6.446,2.307C267.772,254.889,267.368,254.899,266.68,255.135z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M309.025,517.764c0.549-3.654,1.681-6.856,4.105-9.521
				c1.272-1.399,2.009-3.131,2.162-5.111c0.14-1.807,0.935-3.447,1.963-4.924c2.958-4.249,2.582-8.617,0.317-12.92
				c-0.734-1.395-0.795-2.57-0.307-3.936c0.334-0.932,0.602-1.889,0.951-2.815c0.652-1.732,0.719-3.448,0.167-5.237
				c-2.693-8.729,0.11-11.359,5.536-15.5c0.63-0.481,1.283-0.933,1.932-1.389c1.839-1.294,2.935-3.064,2.707-5.308
				c-0.731-7.185,1.378-13.921,3.054-20.736c1.016-4.132,2.19-8.212,2.754-12.465c0.722-5.45,0.038-10.717-1.588-15.844
				c-2.677-8.436-2.934-16.919-1.282-25.546c0.581-3.033,0.487-6.031-0.069-9.135c-0.663-3.701-1.061-7.429-2.384-10.991
				c-0.459-1.238-0.252-2.594,0.374-3.762c1.556-2.904,1.77-6.05,1.837-9.237c0.053-2.516,0.298-4.908,2.478-6.701
				c0.715-0.588,1.206-1.612,1.489-2.532c1.193-3.87,2.773-7.678,2.585-11.835c-0.068-1.496,0.52-2.704,1.218-3.896
				c2.139-3.649,3.268-7.43,1.788-11.635c-0.16-0.455-0.261-1.015-0.149-1.467c1.184-4.794,0.197-10.082,3.269-14.447
				c0.221-0.314,0.394-0.663,0.682-1.152c0.931,0.684,0.76,1.615,0.837,2.407c0.594,6.116,1.157,12.235,1.737,18.352
				c0.405,4.282,1.393,8.45,3.318,12.278c1.745,3.469,2.604,7.017,2.277,11.077c-2.015-0.346-3.505-0.939-3.931-3.403
				c-0.282-1.639-0.895-3.446-2.571-4.36c-1.566,0.529-1.493,1.705-1.423,2.759c0.183,2.744-0.562,5.276-1.457,7.824
				c-2.363,6.733-4.592,13.504-5.352,20.664c-0.326,3.075-0.322,6.141-0.081,9.206c0.252,3.205-0.247,6.296-0.891,9.426
				c-2.062,10.033-1.955,20.067,0.448,30.047c0.23,0.956,0.498,1.971,1.028,2.774c1.636,2.479,2.252,5.225,2.598,8.11
				c0.544,4.525,1.288,9.027,1.777,13.559c0.351,3.253,0.239,6.488-0.56,9.736c-1.327,5.401-1.408,10.997-2.187,16.488
				c-0.181,1.275-0.216,2.591-0.561,3.819c-1.77,6.308-0.321,12.207,2.371,17.865c1.193,2.507,1.703,5.023,1.709,7.736
				c0.007,3.079-0.29,6.138-0.467,9.205c-0.087,1.518-0.743,2.339-2.1,2.863c-1.475,0.569-2.918,1.232-4.335,1.935
				c-3.667,1.818-5.568,4.853-6.25,8.847c-0.504,2.952-0.506,5.915-0.55,9.004c-1.226,0.595-2.433,1.18-3.86,1.871
				c0.144-5.255,0.819-10.111,2.848-14.713c1.434-3.252,3.813-5.547,7.146-6.749c2.31-0.834,3.496-2.189,3.558-4.801
				c0.048-2.047,0.642-4.108,1.196-6.108c0.483-1.745,0.313-3.358-0.489-4.893c-1.293-2.471-2.198-5.084-3.141-7.695
				c-1.653-4.581-5.123-6.111-9.684-4.377c-5.146,1.958-6.716,4.354-6.579,9.947c0.065,2.69,0.567,5.313,1.068,7.943
				c0.663,3.483,0.76,6.897-1.273,10.063c-0.475,0.739-0.854,1.599-1.042,2.454c-1.089,4.933-4.075,8.747-7.201,12.495
				C311.346,514.817,310.197,516.301,309.025,517.764z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M388.635,384.897c1.685,6.239,5.229,11.518,8.699,16.818
				c5.104,7.795,8.596,16.251,10.795,25.288c0.746,3.063,0.624,6.102,0.313,9.16c-0.295,2.902-0.066,5.733,0.709,8.54
				c1.107,4.01,2.22,8.023,2.771,12.156c0.5,3.74,2.337,6.921,4.12,10.122c1.113,1.997,2.141,4.014,2.778,6.226
				c0.754,2.619,1.074,5.263,0.396,7.922c-1.223,4.794-0.229,9.25,1.979,13.504c3.424,6.6,6.767,13.233,9.504,20.155
				c0.853,2.157,2.262,3.957,3.891,5.57c1.326,1.313,1.967,2.651,0.973,4.49c-0.779,1.44-0.759,3.025-0.51,4.642
				c0.953,6.185,2.716,12.176,4.298,18.208c0.274,1.044,0.7,2.082,1.24,3.015c4.071,7.033,7.784,14.272,12.147,21.134
				c1.816,2.854,4.087,5.362,6.414,7.804c1.525,1.601,2.882,3.33,3.628,5.393c1.572,4.349,4.148,8.111,6.729,11.861
				c1.688,2.454,2.729,5.009,2.617,8.027c-0.096,2.594,0.895,4.885,2.503,6.84c2.495,3.034,4.435,6.3,5.241,10.199
				c0.196,0.945,0.672,1.877,1.198,2.699c1.403,2.197,2.4,4.564,3.072,7.061c0.906,3.365,2.895,6.074,5.182,8.562
				c1.619,1.761,3.53,3.193,6.086,3.415c0.578,0.051,1.152,0.291,1.701,0.511c2.141,0.854,2.516,1.599,1.346,3.546
				c-1.093,1.819-1,3.58-0.339,5.412c0.648,1.801,0.975,3.636,0.848,5.543c-0.196,2.941,1.047,5.198,3.343,6.904
				c1.789,1.33,3.103,3.026,4.271,4.895c0.76,1.213,0.889,2.254,0.12,3.564c-4.438,7.574-2.957,7.088-1.275,13.711
				c1.146,4.511,2.497,8.97,3.733,13.459c0.151,0.549,0.513,1.089,0.195,1.814c-2.902,0-5.854,0-8.911,0
				c-1.671-4.538-2.729-9.16-3.798-13.779c-0.402-1.738-0.882-3.456-0.779-5.272c0.096-1.71,0.399-3.363,1.365-4.803
				c0.895-1.335,0.774-2.589-0.044-3.883c-0.795-1.256-1.506-2.568-2.35-3.789c-2.986-4.322-4.474-9.252-5.816-14.235
				c-0.425-1.578-0.359-3.141,0.78-4.501c1.713-2.043,0.281-4.033-1.486-4.776c-0.456-0.192-0.914-0.428-1.395-0.509
				c-5.635-0.955-8.368-5.231-10.268-9.819c-1.543-3.728-2.725-7.604-4.476-11.286c-1.227-2.578-1.696-5.475-1.854-8.371
				c-0.092-1.682-0.191-3.364-0.328-5.044c-0.213-2.601-1.305-4.793-3.366-6.386c-2.114-1.634-3.524-3.714-4.482-6.168
				c-0.687-1.76-1.627-3.394-2.646-4.982c-1.234-1.927-2.239-3.957-2.978-6.134c-0.799-2.36-1.86-4.63-3.594-6.451
				c-5.553-5.831-9.15-12.904-12.82-19.937c-0.688-1.317-1.161-2.769-1.982-3.99c-5.338-7.939-6.137-17.251-7.974-26.239
				c-0.371-1.813-0.406-3.722,0.645-5.492c0.973-1.635,0.716-3.273-0.623-4.63c-2.575-2.607-4.019-5.806-5.265-9.197
				c-2.086-5.674-4.638-11.162-7.306-16.587c-2.262-4.6-3.226-9.243-1.732-14.359c1.261-4.321-0.213-8.427-1.965-12.366
				c-0.845-1.898-1.895-3.706-2.795-5.581c-1.078-2.249-1.791-4.601-2.016-7.109c-0.371-4.16-1.518-8.179-2.428-12.236
				c-0.619-2.761-0.68-5.512-0.214-8.269c0.623-3.688,0.414-7.293-0.658-10.878c-0.597-1.991-1.075-4.02-1.548-6.045
				c-1.35-5.772-4.224-10.877-7.01-16.013c-1.418-2.612-3.003-5.133-4.489-7.708C390.713,392.716,389.217,388.985,388.635,384.897z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M371.102,478.12c-1.353-1.299-1.753-2.423-2.2-3.469
				c-2.001-4.678-4.989-8.302-10.075-9.792c-1.177-0.345-1.767-1.212-1.999-2.357c-0.217-1.066-0.474-2.132-0.584-3.211
				c-0.113-1.1-0.22-2.243-0.804-3.144c-2.737-4.223-4.179-9.247-7.959-12.827c-0.719-0.682-1.271-1.542-1.447-2.552
				c-0.492-2.824-1.425-5.594-1.759-8.398c-0.715-6.001-1.699-11.965-2.356-17.97c-0.131-1.2-0.308-2.405-0.938-3.412
				c-3.289-5.264-3.963-11.176-4.058-17.14c-0.108-6.821-0.349-13.681,1.798-20.341c0.473-1.467,0.544-3.164,0.39-4.715
				c-1.053-10.623,1.282-20.688,4.889-30.545c1.198-3.273,2.299-6.537,1.931-10.439c1.542,0.868,1.739,2.14,1.968,3.278
				c0.343,1.708,1.075,2.865,2.881,3.352c2.337,0.63,2.634,2.73,2.987,4.696c0.756,4.19,1.504,8.382,2.216,12.579
				c0.795,4.695,1.709,9.443,0.918,14.178c-0.931,5.558-0.191,10.67,1.805,15.955c2.193,5.807,3.703,11.904,4.615,18.094
				c0.47,3.188,1.618,6.091,3.274,8.846c1.837,3.056,3.566,6.176,5.298,9.293c0.721,1.299,1.346,2.657,1.615,4.136
				c0.46,2.524-0.41,3.817-2.969,4.122c-3.197,0.38-6.319-0.159-9.373-1.123c-1.133-0.358-2.254-0.788-3.466-0.764
				c-0.597,0.012-1.214,0.101-1.454,0.781c-0.217,0.612-0.042,1.168,0.38,1.642c0.678,0.763,1.601,1.153,2.483,1.597
				c8.075,4.053,8.255,4.265,10.793,12.953c1.201,4.109,1.938,8.286,1.896,12.588c-0.015,1.384,0.112,2.772,0.236,4.152
				c0.235,2.627,0.511,5.124-0.625,7.78c-1.034,2.419-0.71,5.265-0.561,7.945C370.915,475.071,370.99,476.253,371.102,478.12z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="M593.176,664.052c-1.166,1.076-2.396,1.099-3.549,1.312
				c-0.782,0.145-1.558,0.24-2.247,0.696c-0.859,0.569-1.614,1.184-1.487,2.359c0.115,1.067,0.932,1.477,1.786,1.771
				c1.78,0.613,3.593,1.128,5.475,1.324c0.898,0.094,1.904,0.115,2.489,0.81c1.152,1.37,2.588,2.04,4.195,2.084
				c0.847,1.059,0.326,2.067,0.366,3.021c0.08,1.879,0.691,2.994,2.516,3.064c3.649,0.139,4.771,2.754,5.901,5.42
				c0.497,1.175,0.721,2.467,1.064,3.706c0.548,1.968,1.511,3.695,3.204,4.872c2.944,2.046,3.894,5.077,4.513,8.542
				c-12.117,0-24.074,0-36.242,0c-1.169-2.642-2.662-5.198-4.097-7.787c-0.778-1.403-0.586-2.776,0.177-4.232
				c1.278-2.439,1.041-2.885-1.098-4.764c-2.57-2.257-4.261-4.899-3.531-8.58c0.33-1.669,1.017-2.969,2.56-3.769
				c0.436-0.226,0.843-0.525,1.217-0.845c0.777-0.663,0.941-1.51,0.488-2.404c-0.437-0.864-1.27-0.957-2.059-0.678
				c-2.685,0.952-5.225,0.545-7.802-0.488c-1.517-0.607-2.543-1.526-2.921-3.086c-0.401-1.653-1.146-3.161-1.811-4.707
				c-0.83-1.935-1.354-3.893-0.902-6.062c0.452-2.167-0.054-4.119-1.812-5.651c-1.432-1.247-1.631-2.808-1.148-4.602
				c0.94-3.499,3.898-5.69,7.484-5.418c0.888,0.067,1.818,0.358,2.655,0.186c3.23-0.671,6.002,0.305,8.732,1.924
				c1.062,0.63,2.006,1.177,2.472,2.381c0.652,1.682,1.984,2.725,3.605,3.339c3.35,1.27,5.195,3.836,6.283,7.073
				c0.534,1.592,0.885,3.25,1.479,4.817C591.687,661.142,592.452,662.523,593.176,664.052z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="M339.501,55.846c-2.83,2.116-2.84,2.232-0.537,6.755
				c-1.813-0.401-3.173-2.483-5.258-0.915c0.58,2.853-1.469,4.738-3.052,7.056c-1.868-0.909-3.53-0.579-5.434,0.423
				c-2.758,1.452-5.652-0.008-6.845-2.923c-1.273-3.114-1.777-3.444-5.103-3.052c-1.967,0.232-3.678-0.629-5.433-1.212
				c-1.016-0.338-2.021-0.755-3.351-1.137c1.104-1.592,1.931-3.169,0.983-5.126c-0.36-0.745-0.073-1.936,0.665-2.451
				c1.92-1.341,3.412-3.358,5.935-3.786c2.839-0.481,4.364-2.642,5.236-4.995c0.623-1.68,0.375-3.698,0.422-5.567
				c0.019-0.745-0.52-1.258-1.206-1.56c-0.693-0.306-1.546-0.377-1.975-1.454c1.486-0.934,2.974-1.959,3.095-3.917
				c0.822-0.203,1.015,0.517,1.467,0.792c2.108,1.278,4.438,0.576,4.849-1.849c0.379-2.234,1.78-3.108,3.436-4.047
				c0.967-0.549,1.682-0.519,2.706,0.037c2.272,1.235,3.691,0.601,4.89-1.861c0.833-1.71,2.446-2.013,3.734-0.58
				c1.288,1.432,1.75,3.209,1.95,5.132c0.233,2.249,2.364,2.407,3.944,3.039c1.484,0.594,2.941,0.412,4.051-0.912
				c1.396-1.664,1.219-3.549-0.436-4.982c-0.812-0.704-1.967-0.985-2.524-2.033c0.011-1.102,0.476-2.023,1.566-2.219
				c1.304-0.234,2.584-1.222,3.984-0.329c1.285,0.818,2.649,1.213,4.454,0.6c-0.66,2.571-0.219,4.625,1.483,6.464
				c1.132,1.224,0.39,3.078,0.947,4.659c1.584,0.827,2.868-0.08,4.113-0.778c1.039-0.582,2.019-1.159,3.228-1.414
				c3.33-0.704,3.797-1.403,3.637-4.868c1.025-0.426,0.766,0.625,1.021,0.971c1.349,1.836,2.948,2.459,4.831,1.327
				c1.147-0.689,1.982-0.578,3.012-0.036c0.266,1.871,0.546,3.729,0.79,5.592c0.222,1.692-0.083,3.22-0.721,4.881
				c-1.163,3.032-1.794,6.265-3.487,9.109c-0.736,1.238-0.697,1.262-2.769,1.343c-0.399-0.978-0.122-1.961,0.108-2.899
				c0.573-2.331-1.378-3.034-3.044-2.556c-0.19,0.055-0.39,0.098-0.562,0.188c-1.125,0.59-2.242,1.191-3.292,1.751
				c-0.539,1.246,0.982,2.716-0.791,3.711c-0.975-0.326-1.946-1.061-3.263-0.994c0.02-1.816,1.587-3.017,1.658-5.196
				c-2.142,1.661-2.614,3.833-4.127,5.492c-1.764-0.163-3.624-0.047-4.533,2.071c-0.612-0.613-0.891-1.932-0.765-2.986
				c-0.502-0.751-1.398-0.654-2.093-0.987c-1.649-0.79-3.549-1.177-4.205-3.375c-0.327-1.098-1.836-1.144-3.054-0.348
				c-1.028,0.671-0.833,1.481-0.203,2.269c1.409,1.761,2.081,4.08,4.175,5.333c0.884,0.529,1.061,1.38,0.303,2.503
				c-2.726-1.225-3.852,0.803-5.098,3.372c-3.277-4.978-6.285-9.545-9.292-14.114c-0.234,0.13-0.468,0.259-0.703,0.389
				c0.569,2.192,2.041,4.002,3.059,5.996C337.157,51.749,338.362,53.791,339.501,55.846z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="M504.485,362.493c1.961,0.863,3.923,1.307,5.202,3.264
				c-1.295,1.155-2.352,2.417-2.036,4.297c-1.711,0.937-3.297,1.506-5.043,0.316c-1.194-0.814-2.064-0.253-2.726,0.78
				c-0.855,1.337-1.683,2.7-1.282,4.513c-1.274,0.332-2.085,0.199-2.399-1.072c-0.262-1.055-0.541-2.104-0.788-3.162
				c-0.592-2.537-2.161-3.683-4.688-3.21c-0.805,0.151-1.503,0.547-2.217-0.529c1.696-3.515,1.728-7.66,3.403-11.356
				c0.531-1.172,0.321-1.365-1.15-3.35c-0.086-0.747,0.88-1.093,0.711-1.872c-0.234-0.864-1.396-0.755-1.669-1.625
				c0.145-0.926,0.355-1.887,0.423-2.856c0.055-0.79-0.009-1.67-0.768-2.138c-1.596-0.983-1.212-2.275-0.706-3.634
				c0.382-1.023,0.826-2.069,0.621-3.173c-0.331-1.774-0.193-3.313,1.409-4.486c0.855-0.625,0.735-1.572,0.436-2.451
				c-0.64-1.873-0.547-3.695,0.268-5.491c0.423-0.933,0.433-1.863,0.249-2.89c-0.352-1.97,0.09-2.389,1.827-3.456
				c2.121-1.303,4.421-2.048,6.733-2.877c2.464-0.883,4.405-0.448,6.196,1.328c0.784,0.777,1.405,1.661,1.938,2.616
				c0.513,0.917,1.188,1.67,2.332,1.492c1.207-0.188,2.304-0.662,2.721-1.983c0.148-0.471,0.21-0.97,0.381-1.431
				c0.485-1.314,1.462-1.908,2.733-1.387c1.748,0.717,3.314,1.189,4.881-0.414c0.43-0.441,1.336-0.568,1.991-0.303
				c1.824,0.739,3.747,1.368,5.367,2.439c1.876,1.24,3.352,3.06,5.668,3.668c0.689,0.181,0.988,0.951,1.305,1.561
				c0.772,1.489,1.36,3.089,2.896,4.046c0.419,0.261,0.54,0.747,0.603,1.279c0.348,2.992,1.019,3.63,4.059,3.529
				c1.308-0.043,1.953,0.556,2.804,1.798c-1.286-0.123-2.208-0.273-3.131-0.283c-0.992-0.01-2.054,0.041-2.739,0.886
				c-2.061,2.542-4.823,2.769-7.698,2.237c-2.356-0.436-4.12,0.172-5.286,2.244c-0.509,0.905-1.044,1.569-2.128,1.863
				c-3.7,1.002-5.989,3.748-6.407,7.509c-0.076,0.685-0.138,1.371-0.249,2.488c-2.789-0.212-5.342,1.569-8.322,0.411
				c-1.539-0.599-3.732,1.203-3.951,3.09c-0.192,1.663-0.054,3.236-1.843,4.377C503.253,359.838,504.148,361.27,504.485,362.493z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(43)} fill={props.fillColors[43]} d="M156.518,188.037c1.124,2.813,0.065,4.922-1.71,6.808
				c-1.854,1.969-1.977,2.013-0.641,4.182c0.797,1.295,0.189,2.521,0.233,3.754c0.131,3.725,0.884,4.206,4.179,2.335
				c0.768-0.436,1.458-1.101,2.406-0.581c0.476,0.343,0.554,0.821,0.611,1.322c0.153,1.351,1.044,1.535,2.155,1.316
				c0.485-0.096,0.954-0.278,1.44-0.361c2.478-0.424,3.721,0.557,3.646,3.162c-0.039,1.347,0.63,2.058,1.565,2.62
				c1.609,0.969,3.361,1.693,3.878,3.821c0.16,0.658,0.937,0.982,1.681,1.041c0.795,0.063,1.563,0.015,2.342-0.239
				c3.292-1.075,2.78-0.821,4.219,2.311c1.04,2.263,1.829,5.142,3.917,6.589c1.664,1.152,2.426,2.794,3.692,4.117
				c0.875,0.914,0.562,1.761-0.738,2.17c-1.26,0.397-2.394,0.902-3.438,2.055c0.672,1.515,0.454,3.142-0.203,4.698
				c-0.741,1.757-1.176,3.515-0.327,5.485c-2.044,0.446-3.898,0.298-5.732,0.292c-1.752-0.006-3.18,0.303-4.427,1.8
				c-1.3,1.561-2.874,2.907-5.264,2.804c-1.47-0.063-2.464,1.093-3.189,2.586c-2.49-1.881-5.486-2.168-7.877-4.173
				c0.012-1.895-0.281-3.841,1.146-5.735c0.875-1.162,0.857-2.758-0.542-3.983c-1.11-0.972-1.131-2.802-2.946-3.391
				c-0.86-0.279-0.45-1.383,0.103-2.268c1.608-2.576,1.361-3.771-0.43-5.816c-1.097-1.252-2.394-0.868-3.75-0.657
				c0.263-0.862,0.918-1.001,1.373-1.361c2.096-1.659,1.974-3.991-0.491-5.059c-3.083-1.335-6.317-2.293-9.681-2.718
				c-1.56-0.197-2.713,0.836-3.885,1.481c-0.524-0.563-0.373-1.039-0.096-1.233c1.223-0.854,2.123-1.779,1.548-3.444
				c-0.129-0.372,0.104-0.756,0.443-1.028c0.799-0.641,0.683-1.405,0.127-2.083c-0.621-0.758-1.436-1.012-2.388-0.534
				c-0.441,0.223-0.894,0.45-1.368,0.574c-1.088,0.286-2.143,0.466-2.773-0.875c-0.544-1.157-1.694-1.284-2.786-1.35
				c-2.441-0.149-4.29,1.336-6.264,2.417c-0.658,0.359-0.61,1.181-0.663,1.881c-0.087,1.155-0.941,1.331-1.969,1.522
				c-1.116-2.391-2.3-4.672-5.387-5.69c2.679-1.784,2.911-5.041,5.663-6.323c0.939-0.438,0.672-1.591,0.618-2.449
				c-0.116-1.847,0.535-3.071,2.609-3.478c1.416,1.672,3.195,2.307,5.371,1.173c0.64-0.334,1.37-0.311,2.02-0.003
				c1.259,0.596,2.5,0.167,3.368-0.527c2.594-2.078,5.83-2.21,8.769-3.221c0.369-0.127,0.998-0.065,1.107-0.287
				c1.21-2.453,3.586-2.856,5.863-3.441C154.827,189.745,154.798,187.847,156.518,188.037z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(44)} fill={props.fillColors[44]} d="M575.084,295.936c0.444,0.261,0.763,0.487,1.111,0.644
				c1.551,0.699,2.974,0.423,3.621-0.756c0.762-1.389-0.189-2.152-1.106-2.995c-1.841-1.69-1.679-2.199,0.753-2.958
				c1.135-0.354,2.241-0.796,3.37-1.17c0.844-0.279,1.688-0.593,2.558-0.752c2.785-0.511,3.547-0.113,4.805,2.352
				c3.199-0.45,3.396-0.635,3.836-3.394c0.171-1.067,0.14-2.182,0.694-3.183c1.185-0.618,2.312-1.055,3.541,0.029
				c0.88,0.776,2.058,0.76,3.126,0.516c2.1-0.478,3.897,0.044,5.597,1.277c0.761,0.552,1.578,1.462,2.624,0.621
				c0.968-0.777,1.066-2.012,0.656-3.008c-0.567-1.377-0.675-2.563-0.025-3.944c0.419-0.892-0.249-1.681-0.884-2.359
				c-0.731-0.781-1.584-1.548-0.873-3.065c2.002,0.462,3.847-0.119,4.342-2.238c0.314-1.346,0.178-2.942-0.468-4.333
				c-0.082-0.178-0.198-0.347-0.329-0.494c-0.525-0.59-0.632-1.197-0.169-1.883c0.459-0.682,1.03-0.805,1.797-0.548
				c1.979,0.66,3.455-0.242,4.755-1.619c1.152-1.22,2.327-2.396,4.383-2.326c0,12.257,0,24.418,0.002,36.58
				c-3.363,1.998-3.768,2.832-3.199,6.617c-0.513,0.353-0.961,0.096-1.31-0.237c-0.76-0.726-1.29-2.116-2.559-1.641
				c-1.984,0.744-4.453,0.281-5.938,2.31c-0.42,0.574-0.889,1.18-0.668,1.892c0.562,1.813-0.532,2.491-1.905,3.088
				c-3.093,1.346-3.452,2.063-2.625,5.357c0.268,1.063-0.096,1.921-0.543,2.823c-1.07,2.154-0.813,3.376,1.167,4.733
				c0.759,0.52,1.338,0.857,0.547,1.922c-1.161-0.101-2.407-0.208-3.608-0.312c-2.084-3.32-2.374-3.428-5.394-1.928
				c0.276,2.267-1.26,4.828,1.298,6.777c-1.323,1.029-1.84-0.78-2.973-0.365c-1.122,1.332-1.625,3.161-2.496,5.01
				c-1.495-0.729-2.262-2.019-3.221-3.087c-2.064-2.298-4.881-2.816-7.653-1.338c-0.514,0.274-0.903,0.797-1.571,0.688
				c-0.283-0.046-0.558-0.145-0.89-0.234c0-1.87-0.045-3.713,0.019-5.552c0.035-1.023,0.988-1.417,1.663-1.972
				c2.026-1.67,2.061-1.935,0.418-4.029c-0.244-0.312-0.476-0.646-0.77-0.906c-2.355-2.083-2.745-4.631-1.995-7.568
				c0.818-3.202,0.545-3.698-2.35-5.47C573.317,301.717,572.744,298.644,575.084,295.936z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(45)} fill={props.fillColors[45]} d="M209.873,577.465c-1.136-3.822-1.035-7.083,0.279-10.292
				c0.638-1.557,1.413-3.057,2.111-4.589c1.214-2.665,1.196-5.405,0.288-8.122c-1.006-3.006-3.323-3.789-5.724-1.796
				c-7.505,6.229-13.248,13.656-15.17,23.491c-1.181,6.043-3.604,11.504-7.246,16.451c-1.785,2.426-3.203,4.989-3.223,8.144
				c-0.025,4.194,0.944,5.29,5.153,5.485c-0.268,1.391-1.183,1.92-2.457,2.038c-0.296,0.027-0.591,0.081-0.888,0.091
				c-6.937,0.248-11.321,4.138-14.271,10.039c-0.886,1.771-1.661,3.599-2.531,5.378c-0.479,0.981-1.05,1.931-1.926,2.615
				c-1.471,1.147-3.212,0.71-3.843-1.018c-0.965-2.647-0.644-5.057,1.535-7.055c3.652-3.352,7.276-6.734,10.94-10.073
				c2.131-1.941,2.869-4.463,3.03-7.19c0.114-1.933-0.455-2.479-2.362-2.652c-1.81-0.165-3.558,0.281-5.285,0.694
				c-4.254,1.018-8.341,2.513-12.239,4.515c-4.681,2.404-8.571,5.641-11.354,10.19c-4.866,7.952-10.537,15.37-15.562,23.215
				c-2.396,3.741-5.542,6.029-10.033,6.611c-4.074,0.528-7.884,1.919-11.15,4.548c-0.358,0.289-0.706,0.698-1.37,0.592
				c2.494-5.205,5.527-9.928,10.319-13.312c2.581-1.822,4.116-4.231,5.226-7.29c2.622-7.229,6.849-13.385,13.76-17.323
				c2.514-1.433,4.697-3.313,6.669-5.426c3.793-4.064,8.342-6.694,13.946-7.173c5.781-0.494,10.522-3.088,14.905-6.728
				c5.896-4.895,10.828-10.397,13.428-17.798c2.641-7.517,6.927-13.969,12.995-19.246c5.044-4.387,7.365-10.065,7.866-16.743
				c0.488-6.509,4.629-10.707,10.045-13.795c0.875-0.499,1.892-0.571,2.893-0.557c2.629,0.04,4.063,1.312,4.338,3.905
				c0.181,1.697-0.036,3.368-0.57,4.987c-1.487,4.515-2.997,9.021-4.487,13.534c-1.405,4.258-2.217,8.553-2.082,13.117
				c0.135,4.566-0.767,9.062-3.121,13.136C211.776,573.675,210.973,575.358,209.873,577.465z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(46)} fill={props.fillColors[46]} d="M273.933,92.291c0.658,0.384,1.063,0.662,1.501,0.868
				c2.274,1.073,4.098,0.154,4.638-2.318c0.622-2.847,0.806-2.998,3.707-2.414c1.18,0.238,2.353,0.319,3.544,0.298
				c2.323-0.042,4.539,0.25,6.449,1.772c0.612,0.489,1.356,0.816,2.188,0.844c2.235,0.072,3.643-1.744,2.644-3.667
				c-0.742-1.426-1.12-2.646-0.312-4.175c0.339-0.642,0.225-1.549,0.228-2.335c0.002-1.009,0.427-1.696,1.316-2.108
				c4.676,1.253,4.923,1.183,6.892-1.958c0.845-0.152,0.864,0.659,1.188,1.078c0.874,1.13,2.069,1.491,3.407,1.473
				c2.605-0.035,2.603-0.054,3.14,2.669c0.057,0.291,0.147,0.576,0.245,0.856c0.292,0.833,0.595,1.661,0.85,2.371
				c1.004,0.751,2.089,0.097,2.997,0.55c0.804,0.581,0.754,1.579,1.132,2.368c0.514,1.072,1.314,1.73,2.443,1.98
				c1.221,0.271,2.198-0.318,2.411-1.558c0.131-0.768,0.166-1.618-0.024-2.362c-0.796-3.119,1.497-5.089,2.732-7.42
				c0.378-0.713,1.416-0.691,2.097-0.033c1.091,1.053,2.294,0.946,3.596,0.527c0.554-0.179,1.208-0.385,1.732-0.251
				c1.663,0.423,3.115,0.24,4.421-0.94c0.309-0.279,0.752-0.102,1.225,0.108c0.909,3.666,3.166,6.431,6.589,8.08
				c2.903,1.397,3.817,4.061,4.972,6.676c-0.473,1.118-1.428,1.858-1.726,3.093c-0.4,1.657-1.299,2.094-2.91,1.353
				c-1.74-0.8-3.007-0.464-4.3,0.743c-1.082,1.01-2.747,1.163-3.683,2.352c-0.447,1.203,1.417,1.833,0.478,3.269
				c-1.288,0.535-2.716,1.25-4.413,0.247c-0.819-0.484-1.739-0.198-2.746,0.507c0.004-2.905-2.202-2.492-4.07-2.859
				c0.195-1.856-1.297-3.13-1.569-4.904c-0.154-1.002-1.251-1.75-2.294-1.377c-1.481,0.53-2.781-0.026-4.043-0.406
				c-1.952-0.588-3.268-0.121-4.229,1.813c-1.4-0.667-2.874-1.005-4.168,0.042c-1.235,0.999-2.736,0.06-4,0.738
				c-1.379,0.741-0.295,2.514-1.529,3.349c-1.501,1.017-1.558,2.558-1.907,4.275c-0.531,2.604,1.411,3.667,2.162,5.25
				c-0.491,1.494-2.007,1.09-2.601,1.997c-0.885-0.469-1.664-0.878-2.439-1.293c-3.241-1.733-5.867-0.673-6.979,2.922
				c-0.555,1.794-2.411,2.228-3.302,3.702c-1.485-0.584-2.51-1.899-4.095-1.683c-0.156-0.449-0.328-0.717-0.331-0.987
				c-0.045-4.492-2.077-5.595-7.182-5.671c-1.308-0.02-2.527,0.234-3.62-0.666c-1.697-1.397-3.558-1.588-5.76-0.566
				c-0.349-2.21-1.326-3.947-3.118-4.939c-0.005-1.544,1.024-1.771,1.997-1.971c0.994-0.205,1.907-0.518,2.656-1.228
				c0.863-0.818,1.374-1.705,0.644-2.879C271.58,95.518,272.355,93.95,273.933,92.291z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(47)} fill={props.fillColors[47]} d="M257.268,682.864c-2.783-8.284-3.63-15.948-0.07-23.623
				c1.976-4.261,4.599-8.144,7.767-11.562c7.565-8.165,14.212-17.03,20.48-26.195c1.391-2.033,3.139-3.479,5.331-4.537
				c5.003-2.418,9.985-5.063,15.578-5.617c9.39-0.928,17.417,1.794,23.059,9.847c1.484,2.119,2.676,4.417,3.615,6.814
				c2.824,7.207,6.402,14.058,9.758,21.016c2.321,4.812,4.725,9.629,5.381,15.037c0.423,3.481,0.709,6.979,0.814,10.511
				c-0.837-0.744-1.013-1.677-1.113-2.651c-0.213-2.068-1.193-3.729-2.688-5.148c-4.369-4.146-7.104-9.291-8.516-15.052
				c-1.364-5.563-4.283-10.424-6.469-15.612c-0.676-1.607-2.138-2.532-3.668-3.337c-2.882-1.515-5.486-3.283-7.854-5.679
				c-3.926-3.97-8.975-5.304-14.515-3.742c-2.573,0.725-5.106,1.602-7.434,2.963c-3.05,1.784-5.283,4.232-5.799,7.841
				c-0.438,3.065-2.025,5.269-4.458,7.061c-4.49,3.308-8.362,7.271-12.044,11.448c-2.485,2.82-4.276,5.942-5.027,9.626
				c-0.922,4.518-3.132,8.538-4.881,12.735c-0.295,0.707-0.811,1.394-1.392,1.898C261.179,678.595,259.378,680.423,257.268,682.864z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(48)} fill={props.fillColors[48]} d="M301.902,655.745c1.275,1.644,1.289,3.575,1.294,5.389
				c0.01,3.373,0.352,6.537,3.072,8.952c0.272,0.242,0.496,0.67,0.528,1.03c0.236,2.612,1.81,3.29,4.14,3
				c0.238-0.029,0.511,0.213,0.938,0.406c-0.431,1.242-1.58,1.461-2.479,1.839c-2.597,1.092-4.189,2.864-4.491,5.748
				c-0.169,1.618-0.963,3.001-2.018,4.231c-0.903,1.054-1.768,2.141-2.694,3.172c-1.259,1.401-1.835,2.978-1.688,4.891
				c0.22,2.867,0.054,5.719-0.756,8.64c-8.839,0-17.622,0-26.406,0c0-3.71-0.235-7.348,0.053-10.943
				c0.489-6.111,1.738-12.146,1.656-18.327c-0.051-3.853,1.571-7.318,3.189-10.731c0.386-0.813,0.876-1.562,2.114-1.541
				c0.79,1.117,0.333,2.421,0.389,3.638c0.067,1.478-0.125,2.989,0.647,4.356c0.556,0.983,1.306,1.947,2.485,1.831
				c1.344-0.132,1.528-1.439,1.617-2.508c0.236-2.862,0.366-5.732,0.539-8.6c0.206-3.411,1.215-6.37,4.271-8.376
				c1.36-0.892,2.018-2.407,2.566-3.919c0.585-1.615,1.394-3.034,2.962-3.948c1.093-0.638,1.511-1.697,1.641-2.986
				c0.414-4.096,3.225-7.2,7.152-8.08c1.134-0.254,2.17-0.031,3.106,0.625c1.207,0.847,1.425,1.834,0.548,3.03
				c-0.935,1.277-1.992,2.465-2.991,3.695c-1.471,1.813-2.443,3.813-2.534,6.209c-0.083,2.199-0.916,3.916-2.955,5.136
				c-1.858,1.11-2.439,4.172-1.466,6.109c0.417,0.83,1.029,1.462,2.035,1.532c1.057,0.073,1.598-0.528,2.005-1.426
				C300.681,657.134,300.679,656.221,301.902,655.745z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(49)} fill={props.fillColors[49]} d="M331.896,595.532c1.188-2.828,1.991-5.574,4.208-7.877
				c2.003,3.385,4.663,6.023,8.786,6.909c0.019,5.137,2.562,9.043,5.927,12.542c0.64,0.664,1.09,1.41,1.161,2.344
				c0.198,2.588,1.767,4.129,4.007,5.057c1.731,0.717,2.588,2.103,3.13,3.735c0.395,1.189,0.199,2.358-0.621,3.361
				c-1.1,1.345-1.499,3-2.041,4.588c-0.372,1.091-0.389,2.168,0.563,3.012c0.923,0.818,1.849,0.325,2.767-0.063
				c0.268-0.114,0.556-0.176,0.893-0.279c0.939,0.863-0.12,2.773,1.65,3.157c0.854,0.168,1.294-0.802,2.065-0.814
				c0.378,0.106,0.524,0.399,0.507,0.682c-0.031,0.487-0.122,0.979-0.261,1.449c-0.688,2.328,0.235,4.008,2.023,5.441
				c3.553,2.85,3.536,2.884,0.937,6.88c-1.363,2.094-1.318,3.972,0.61,5.547c2.036,1.665,3.338,3.578,3.672,6.189
				c0.072,0.566,0.397,1.121,0.685,1.637c2.334,4.183,3.048,8.711,2.961,13.429c-0.018,0.984,0.052,1.997,0.271,2.954
				c1.607,7.034,4.078,13.672,9.281,18.931c1.36,1.376,1.839,3.021,2.01,4.81c0.113,1.175,0.021,2.369,0.021,3.99
				c-3.187,0-6.315,0.049-9.441-0.026c-1.073-0.026-1.271-1.144-1.667-1.901c-2.599-4.974-4.031-10.326-4.858-15.833
				c-1.015-6.747-3.275-12.964-6.67-18.914c-4.272-7.49-8.854-14.783-13.308-22.158c-1.899-3.146-3.973-6.174-6.74-8.644
				c-4.246-3.79-5.829-8.363-4.188-13.916c1.16-3.928,0.831-7.849-0.14-11.74c-0.309-1.238-0.658-2.553-1.347-3.596
				C336.401,602.86,334.735,598.887,331.896,595.532z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(50)} fill={props.fillColors[50]} d="M584.461,515.371c2.058-3.298,2.232-3.388,6.134-3.153
				c1.654-2.122,3.315-4.273,3.697-7.172c0.168-1.268,1.253-1.9,2.413-1.502c2.529,0.87,5.147-0.539,7.648,0.464
				c0.462,0.186,1.004-0.078,1.259-0.56c0.793-1.495,2.077-1.034,3.101-0.589c1.027,0.445,2.193,1.095,1.64,2.685
				c-0.169,0.483-0.011,1.061,0.474,1.299c1.57,0.774,1.889,2.461,2.784,3.857c2.294-0.375,3.166-1.762,2.907-3.666
				c0.597-0.601,1.057-0.712,1.564-0.782c2.223-0.308,3.223-1.927,1.933-3.611c-1.215-1.588-0.359-3.687-1.742-5.155
				c-0.308-0.326,0.098-1.15,0.762-1.391c1.097-0.397,2.201-0.77,3.308-1.153c0.635,2.299,0.732,66.25,0.096,69.804
				c-1.632-0.685-2.604-2.132-3.892-3.123c-1.34-1.029-3.18-0.158-4.627-1.543c2.049-1.319,1.383-2.84,0.579-4.513
				c-1.091-2.27-2.812-4.061-4.243-6.068c-0.165-0.231-0.435-0.489-0.691-0.536c-3.122-0.564-4.576-3.285-6.696-5.14
				c-0.149-0.13-0.361-0.242-0.425-0.408c-1.263-3.285-3.879-5.579-6.009-8.225c-1.646-2.045-2.873-4.547-5.716-5.337
				c-0.336-0.093-0.673-0.438-0.86-0.756c-0.388-0.657-0.358-1.554-1.201-1.95c-0.617-0.291-1.209-0.635-1.943-1.024
				c1.315-2.366,0.832-4.44-0.692-6.389C585.003,518.436,585.016,516.771,584.461,515.371z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(51)} fill={props.fillColors[51]} d="M413.782,490.574c3.45,9.104,8.356,17.524,11.452,26.713
				c0.706,2.093,2.081,3.834,3.6,5.41c1.331,1.383,1.764,2.762,0.75,4.535c-0.988,1.729-0.796,3.65-0.529,5.526
				c0.81,5.698,2.281,11.264,3.461,16.888c0.281,1.338,0.833,2.663,1.484,3.874c3.754,6.982,7.135,14.163,11.148,21.008
				c1.663,2.836,3.708,5.401,5.908,7.823c2.039,2.245,3.561,4.752,4.521,7.608c0.731,2.178,1.808,4.176,2.99,6.134
				c0.818,1.354,1.671,2.715,2.273,4.17c1.197,2.891,2.736,5.444,5.292,7.39c2.212,1.684,2.827,4.208,2.955,6.877
				c0.119,2.47,0.042,4.939,0.64,7.39c0.708,2.903,1.487,5.73,3.066,8.321c1.139,1.869,1.654,4.032,2.216,6.135
				c0.924,3.459,2.9,6.274,5.269,8.838c1.432,1.549,3.166,2.683,5.312,3.094c0.77,0.147,1.539,0.412,2.243,0.757
				c1.74,0.853,2.047,1.637,0.933,3.236c-1.346,1.932-1.156,3.846-0.638,5.949c1.417,5.742,3.657,11.102,7.103,15.923
				c1.303,1.823,1.805,3.46,0.496,5.676c-1.4,2.37-1.271,5.161-0.609,7.829c1.121,4.519,2.299,9.023,3.45,13.534
				c0.143,0.561,0.508,1.09,0.215,1.797c-1.418,0.303-2.883,0.218-4.469,0.041c-1.651-3.651-2.209-7.578-2.911-11.457
				c-0.334-1.848-0.489-3.731-0.653-5.605c-0.169-1.927-0.854-3.654-1.849-5.279c-1.307-2.136-2.913-4.049-4.894-5.554
				c-2.534-1.926-3.864-4.43-4.336-7.509c-0.312-2.039-0.469-4.121-2.432-5.526c-0.495-0.354-0.549-1.446-0.645-2.219
				c-0.525-4.224-2.187-7.999-4.416-11.57c-2.889-4.629-5.588-9.361-7.857-14.333c-0.873-1.911-1.615-3.85-1.656-5.972
				c-0.085-4.398-0.882-8.655-2.268-12.829c-0.717-2.159-0.867-4.447-1.225-6.688c-0.66-4.14-1.086-8.572-5.238-11.143
				c-0.953-0.59-1.168-1.524-1.261-2.55c-0.163-1.788-0.715-3.486-1.243-5.192c-0.603-1.948-1.561-3.628-3.276-4.819
				c-1.604-1.113-2.586-2.686-3.304-4.478c-2.299-5.739-5.978-10.25-11.73-12.821c-1.849-0.825-2.865-2.23-3.686-4.035
				c-2.388-5.252-2.987-10.871-3.563-16.487c-0.668-6.513-1.129-13.039-0.4-19.594c0.224-2.012-0.421-3.853-1.482-5.639
				c-4.999-8.421-8.359-17.49-10.375-27.062C413.329,493.334,413.344,491.956,413.782,490.574z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(52)} fill={props.fillColors[52]} d="M279.628,145.481c0.176-1.161,0.85-1.797,2.196-1.752
				c1.624,0.055,3.122-0.392,4.406-1.332c0.456-1.206-0.122-2.464,0.649-3.87c2.083-1.081,5.322-1.879,3.283-5.644
				c0.932-1.151,2.689-0.519,3.277-2.02c0.471-1.201,0.267-2.5,0.625-4.206c2.238,2.404,4.406,3.803,6.985,1.105
				c1.789,1.227,1.991,2.867,1.531,4.662c-0.334,1.307-0.473,2.534,0.42,3.673c1.028,1.313,2.828,1.612,3.475,0.282
				c1.196-2.459,3.258-2.468,5.241-2.667c1.39,0.723-0.029,1.989,0.986,2.919c1.248,0.805,2.615-0.077,4.054-0.24
				c0.755-2.131,0.04-4.123-0.104-6.299c0.951,2.385,2.751,2.295,4.739,2.114c2.652-0.242,4.755,0.913,6.194,3.216
				c1.385-0.493,1.952-1.606,2.454-2.614c0.743-1.49,1.729-1.938,3.345-1.637c1.686,0.314,1.96,1.393,1.997,2.645
				c-0.448,1.134-1.863,1.031-2.154,2.125c0.721,2.14,0.721,2.14-0.2,4.601c1.921,1.812,1.921,1.812,4.5,0.394
				c1.345,0.321,1.754,1.46,2.228,2.559c0.573,1.327,1.607,1.702,2.923,1.255c1.363-0.462,1.877-1.384,1.29-2.783
				c-0.339-0.807-0.195-1.556,0.291-2.25c0.596-0.852,0.658-1.819,0.777-2.817c0.218-1.83,1.373-2.749,3.055-2.146
				c2.683,0.961,4.353-0.006,5.482-2.314c1.131,0.12,1.98,0.576,2.818,1.105c0.065,1.411-0.254,2.502-1.148,3.598
				c-1.549,1.899-2.195,4.032-0.32,6.184c0.647,0.742,0.757,1.512,0.523,2.474c-0.397,1.638,0.631,3.244,0.144,4.968
				c-0.22,0.776,0.694,1.31,1.459,1.657c0.606,0.275,1.378-0.133,2.035,0.634c-1.279,1.691-2.922,3.205-3.486,5.468
				c-2.229,0.043-4.39-0.024-6.505,0.625c-3.097,0.951-4.522,2.83-4.511,6.299c-1.694,1.315-3.942,1.821-5.251,3.721
				c-0.774,1.123-1.426,2.31-0.79,3.578c0.651,1.299,0.66,2.339-0.136,3.551c-0.408,0.621-0.472,1.467-0.71,2.279
				c-0.88,0-1.751,0-2.685,0c-1.292-2.005-2.403-4.129-4.614-5.327c-1.988-1.077-3.91-2.276-6.113-3.568
				c0.898-0.944,1.741-1.229,2.754-1.025c1.793,0.361,3.57-0.017,5.331-0.187c2.734-0.262,3.329-1.75,1.768-3.999
				c-0.969-1.396-0.905-1.783,0.475-2.862c2.124-1.661,2.239-3.524,0.27-5.302c-0.721-0.651-1.758-0.966-2.17-2.3
				c1.189-1.012,2.249-2.31,1.329-4.162c-0.93-1.873-2.536-0.895-3.738-0.812c-0.845-0.549-0.94-1.247-0.902-1.946
				c0.069-1.244-0.524-2.126-1.525-2.69c-1.033-0.582-1.633-1.415-1.885-2.526c-0.376-1.659-1.269-2.457-3.089-2.57
				c-1.756-0.109-3.456-0.806-5.276-0.295c-2.075,0.582-3.728,1.431-3.509,4.011c0.029,0.341-0.258,0.709-0.365,0.98
				c-0.744,0.266-1.349,0.234-1.734-0.327c-1.201-1.75-3.106-2.416-4.911-3.201c-2.477-1.078-4.702-0.475-6.159,1.825
				c-0.798,1.26-1.882,1.604-3.17,1.875c-2.948,0.619-4.079,2.975-2.826,5.756c0.365,0.811,0.613,1.687,1.067,2.441
				c0.43,0.715,1.059,1.311,1.589,1.946c-1.021,1.121-1.773,0.18-2.473-0.138c-1.373-0.623-2.617-0.915-3.793,0.339
				c-1.213,1.293-1.329,2.159-0.489,4.003c0.649,1.426,1.058,3.047,2.974,3.381c0.24,0.042,0.563,0.348,0.62,0.585
				c0.647,2.735,3.016,3.129,5.153,3.796c1.023,0.319,2.014,0.777,1.921,1.919c-0.129,1.593,0.625,2.623,1.622,3.644
				c0.573,0.587,0.779,1.385,0.912,2.165c0.108,0.636-0.435,0.779-0.91,0.916c-0.589-0.006-0.531-0.51-0.625-0.848
				c-0.382-1.366-0.872-3.116-2.163-3.341c-1.605-0.28-3.446-0.482-5.112,0.419c-2.061,1.114-2.912,0.855-4.164-1.022
				c-0.165-0.247-0.329-0.497-0.472-0.757c-1.488-2.709-2.901-3.289-5.671-2.323c-2.835,0.989-3.445,0.579-3.476-2.457
				c-0.009-0.865-0.323-1.69,0.054-2.619c1.364-3.359,1.317-3.378-1.517-5.031C282.87,153.063,281.867,149.306,279.628,145.481z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(53)} fill={props.fillColors[53]} d="M240.664,191.405c2.213-0.649,3.846-1.463,3.647-4.106
				c-2.365,0.033-4.109-2.884-7.085-0.686c0.197-2.19-0.613-3.487-1.496-4.918c-1.058-1.717-1.499-3.917-0.938-6.018
				c0.532-1.992,1.287-3.924,1.939-5.87c1.982,0.215,3.522,2.191,5.39,0.606c1.913,2.505,3.621,2.553,5.213,0.322
				c1.232-1.726,1.362-1.735,3.103-0.225c2.099,1.822,2.867,1.872,5.337,0.74c1.448-0.664,2.132-1.686,2.136-3.236
				c0.002-1.042,0.058-2.055,1.555-1.962c0.854,0.974,0.45,2.083,0.21,3.093c-0.388,1.636-0.945,3.231-1.445,4.839
				c-0.303,0.977-0.868,1.713-1.753,2.302c-2.566,1.705-2.576,1.893-1.04,5.222c1.626,0.017,3.156,0.12,4.558,1.683
				c1.027,1.146,2.763-0.064,3.946-1.03c1.047-0.854,1.633-2.213,2.777-2.817c0.562,0.222,0.583,0.47,0.322,0.685
				c-2.719,2.237-1.625,5.071-0.938,7.624c0.558,2.073,4.633,2.575,6.009,0.823c0.857-1.09,1.802-1.794,3.111-2.089
				c2.202-0.496,3.702-1.903,4.632-3.861c0.707-1.488,1.765-2.101,3.315-1.806c1.263,0.241,2.34-0.184,3.413-0.704
				c0.621-0.301,1.18-0.728,1.909-0.771c2.963-0.174,4.422-2.279,5.709-4.542c0.962-1.691,3.392-2.868,5.164-2.37
				c1.166,0.327,1.299,1.334,1.519,2.315c0.519,2.311,0.915,2.629,2.94,1.785c1.491-0.621,2.917-1.059,4.517-0.686
				c0.97,0.226,1.787-1.332,3.036-0.229c-0.825,1.075-2.446,0.266-3.042,1.547c-0.577,1.24,0.555,2.286,0.173,3.658
				c-1.748,0.516-3.21,1.523-4.057,3.384c-0.823,1.811-0.705,3.405,0.338,5.062c0.855,1.358,1.973,2.036,3.488,1.413
				c1.342-0.551,2.442-0.535,3.555,0.462c1.118,1.002,2.518-0.172,3.954,0.458c0.8,1.415,1.003,3.651,2.93,4.345
				c1.85,0.666,2.751,2.578,4.831,2.909c-0.14,2.979-1.018,5.62-1.835,8.224c-0.556,1.771-1.81,1.922-3.208,0.66
				c-0.511-0.462-0.912-1.059-1.589-1.289c-1.229-0.417-2.484-0.944-3.328,0.697c-0.155,0.3-0.625,0.438-1.124,0.767
				c-1.684-1.892-3.661-2.733-6.27-2.077c-1.375,0.346-2.347-0.931-2.187-2.384c0.065-0.588,0.277-1.164,0.309-1.751
				c0.147-2.744-0.455-3.503-3.285-3.689c-2.042-0.134-3.588-0.721-4.477-2.745c-0.792-1.805-2.43-2.525-4.324-2.638
				c-0.499-0.03-0.985,0.048-1.505-0.504c0.708-1.973,0.392-3.731-2.022-4.793c-1.384,0.586-2.833,1.843-4.638,0.37
				c-0.731,1.73-1.332,3.153-2.011,4.762c-1.733-0.534-3.446,0.271-5.193-0.083c-1.146-0.231-1.913,0.274-2.37,1.312
				c-1.142,2.596-3.46,4.317-4.957,6.654c-0.556,0.868-1.361,1.41-2.522,1.141c-1.308-0.303-2.535,0.111-3.784,0.476
				c-2.658,0.775-2.669,0.738-4.019-2.078c1.771-1.602,2.68-3.723,3.161-5.891c-0.429-0.918-1.093-0.762-1.655-0.741
				c-1.002,0.038-1.72-0.278-2.103-1.265c-0.368-0.948-1.154-1.294-2.122-1.339c-1.537-0.072-2.248,0.492-2.309,2.012
				c-0.04,0.983,0.126,1.957-0.126,2.952c-0.806,3.182-3.419,4.652-6.506,3.501c-1.957-0.729-3.835-1.429-6.038-1.171
				c-1.815,0.213-2.561-1.087-1.545-2.615C240.75,194.055,240.866,192.877,240.664,191.405z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(54)} fill={props.fillColors[54]} d="M598.015,322.144c0.995-0.661,1.874-0.542,2.229,0.494
				c0.597,1.739,1.742,2.267,3.435,2.036c0.898-0.123,1.673,0.666,2.601,0.388c1.56-2.479,1.528-2.729-0.517-4.159
				c-1.323-0.924-1.542-1.61-0.725-3.236c0.708-1.408,0.911-2.743,0.397-4.276c-0.546-1.626-0.091-2.439,1.567-3.219
				c0.802-0.377,1.693-0.561,2.369-1.196c0.661-0.621,1.176-1.234,1.167-2.268c-0.021-2.539,0.256-2.818,2.621-3.318
				c0.961-0.203,1.924-0.403,2.965-0.621c0.702,0.622,1.357,1.39,2.18,1.875c0.768,0.452,1.744,0.559,2.494-0.154
				c0.616-0.585,0.317-1.305,0.009-1.925c-0.915-1.842-0.003-2.957,1.344-3.913c0.224,0.071,0.326,0.087,0.41,0.135
				c0.075,0.043,0.18,0.117,0.188,0.186c0.07,0.684,0.174,1.37,0.174,2.055c0.009,28.321,0.007,56.643,0.007,85.456
				c-2.557-1.778-4.028-3.914-5.119-6.107c-0.844-1.695-1.539-3.364-3.231-4.52c0.875-1.491,1.366-2.844,1.055-4.553
				c-0.255-1.397,1.624-2.138,1.974-3.752c-1.416-1.509-2.274-3.579-4.454-4.787c-1.451-0.804-2.772-1.342-4.388-1.411
				c-2.14-0.092-2.668-0.558-3.087-2.716c-0.256-1.323-0.981-2.277-1.818-3.301c-1.9-2.325-4.174-4.067-6.873-5.328
				c-0.958-0.448-1.416-1.259-1.508-2.369c-0.184-2.245-0.159-4.562-1.751-6.452c-0.209-0.249-0.274-0.861-0.115-1.136
				c1.102-1.903,0.427-3.86,0.278-5.801c-0.136-1.788,0.706-3.226,1.638-4.884c1.651,0.973,3.024,0.709,4.236-0.985
				C597.269,326.786,597.658,324.46,598.015,322.144z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(55)} fill={props.fillColors[55]} d="M579.206,267.391c-1.577,1.125-2.48-1.207-4.119-0.854
				c-1.067,0.909-1.546,2.247-0.797,3.572c0.994,1.759,0.991,3.806,2.173,5.568c-1.685,0.734-2.855-0.08-4.069-0.661
				c-1.945-0.932-3.697-2.256-6.017-2.371c-1.342-0.066-1.676-1.208-1.413-2.419c0.167-0.767,0.585-1.494,0.666-2.263
				c0.154-1.463,0.003-2.838-1.937-3.036c-0.539-0.055-0.887-0.39-0.966-1.059c0.023-0.873,1.006-1.039,1.468-1.641
				c0.75-0.979,1.43-2.046,0.905-3.269c-0.715-1.669-0.099-2.664,1.354-3.341c0.831-0.388,1.428-1.034,2.054-1.702
				c0.893,0.525,1.674,1.153,2.566,1.467c0.99,0.349,1.997,0.06,2.64-0.853c0.582-0.826-0.024-1.49-0.46-2.144
				c-0.687-1.027-1.933-1.813-1.469-3.292c0.413-0.481,0.878-0.43,1.393-0.416c2.329,0.062,3.035-0.857,2.415-3.052
				c-0.279-0.99-0.803-1.788-1.693-2.351c-1.17-0.74-2.267-1.608-3.479-2.268c-1.757-0.957-1.954-2.752-2.154-4.321
				c-0.137-1.079,0.678-2.261,2.069-2.526c0.74-0.142,1.611,0.267,2.194-0.523c-0.543-2.51-0.543-2.51-3.283-2.774
				c-0.516-1.204-1.044-2.479-2.021-3.501c-0.611-0.639-0.631-1.428-0.209-2.203c0.431-0.791,1.1-1.138,1.982-0.912
				c1.758,0.452,3.36-0.023,4.842-0.889c1.289-0.753,2.413-0.705,3.544,0.296c0.453,1.035-0.071,2.43,1.214,3.314
				c1.312-0.501,2.019-2.501,3.932-1.912c1.6,0.494,0.89,2.336,2.251,3.089c-2.383,1.986-0.943,4.024-0.287,5.803
				c0.507,1.375,2.209,1.407,3.736,1.365c1.205-2.749,2.421-5.458,4.464-7.483c1.104,0.294,1.248,1.093,1.746,1.551
				c1.824,1.673,3.333,1.507,4.812-0.495c0.934-1.265,2.104-2.332,2.83-3.781c0.451-0.898,1.476-1.278,2.396-1.666
				c1.002-0.422,2.088-0.715,2.988-1.295c2.192-1.413,4.148-0.768,5.979,0.578c1.642,1.207,3.512,2.314,3.376,4.838
				c-0.044,0.82,0.777,1.027,1.366,1.39c1.372,0.844,2.963,1.026,4.616,1.482c0,5.492,0,10.903,0,16.51
				c-1.179,0.077-2.448-0.104-3.595,0.503c-0.73,0.386-1.5,0.252-2.296-0.029c-3.188-1.126-3.694-0.902-5.325,2.113
				c-0.091,0.167-0.221,0.312-0.384,0.54c-1.309,0.301-2.475,0.391-2.88-1.426c-0.284-1.278-1.297-1.677-2.512-1.552
				c-0.681,0.069-1.351,0.237-2.144,0.383c-1.275-1.308-2.794-2.032-4.748-1.401c-0.636,0.205-1.295,0.136-1.949-0.338
				c-2.341-1.695-2.719-1.624-4.847,0.46c-0.135,0.132-0.34,0.194-0.654,0.366c-1.017,0.027-1.45-1.807-2.944-1.086
				c-1.432,0.691-2.355,1.635-3.007,3.081c-0.874,1.937-1.854,3.77,0.209,5.677c-2.45,1.917-3.212,4.77-4.96,6.972
				c-0.52,0.655-1.066,1.054-1.707,1.491c-1.957,1.333-1.972,2.103-0.194,3.885C579.063,266.772,579.378,266.918,579.206,267.391z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(56)} fill={props.fillColors[56]} d="M375.904,89.964c0.475-2.65-2.493-3.677-2.073-6.135
				c0.971-0.592,1.621,0.218,2.358,0.61c1.944,1.034,2.825,0.922,4.047-0.756c0.929-1.275,1.81-2.605,2.538-4.001
				c0.909-1.741,0.056-3.05-2.718-4.167c1.5-2.942-0.548-4.896-2.133-6.97c-0.339-0.444-1.03-1.032-1.434-0.949
				c-3.636,0.754-6.277-3.17-10.065-2.036c-0.827,0.248-2.062-0.864-3.039-1.316c-0.056-1.278,0.688-1.774,1.55-2.087
				c1.447-0.525,1.605-1.475,1.242-2.835c-0.828-3.101,0.328-5.265,3.319-6.498c0.823-0.339,1.659-0.671,2.425-1.116
				c1.046-0.608,1.98-0.585,3.074-0.039c3.075,1.535,3.096,1.494,5.978-0.63c0.604,2.052,2.59,2.962,3.57,4.778
				c0.749,1.389,2.657,1.882,3.901,1.395c2.767-1.084,2.767-1.084,5.59,0.282c0.311,4.636,0.486,4.84,4.346,5.225
				c2.22,1.491,1.519,5.164,5.067,5.672c-1.196,1.504-1.982,2.907-1.149,4.649c0.263,0.55-0.07,1.191-0.372,1.693
				c-0.896,1.489-1.586,2.951-0.749,4.746c0.258,0.555-0.176,1.413-0.768,1.336c-2.401-0.311-2.568,1.692-3.46,2.989
				c0.66,1.862,1.214,3.688,2.431,5.267c-1.122,0.489-1.479-0.134-1.942-0.383c-1.249-0.67-2.461-0.712-3.646,0.085
				c-1.43,0.963-0.315,2.127-0.188,3.184c-0.572,1.302-1.801,1.427-2.914,1.826c-1.293,0.464-1.559,1.294-1.026,2.589
				c0.584,1.417,1.545,1.917,2.776,1.379c0.446-0.195,0.831-0.535,1.241-0.812c0.422-0.285,0.879-0.441,1.427-0.418
				c0.984,0.312,0.505,1.375,0.967,1.981c0.667,0.875,1.537,1.218,2.583,1.165c0.967-0.049,1.237-0.777,1.528-1.564
				c0.846-2.29,1.826-2.801,3.932-1.909c1.433,0.607,2.802,0.937,4.302,0.352c0.712-0.277,1.355-0.134,1.956,0.581
				c-0.902,3.578-1.852,7.27,0.873,10.658c-0.298,0.676-0.83,0.694-1.461,0.462c-0.867-0.32-1.754-0.339-2.623-0.042
				c-1.211,0.415-2.007,0.952-1.734,2.578c0.395,2.356,0.084,2.532-2.226,2.146c-2.77-0.463-4.112,0.386-5.899,3.73
				c0.05,0.667,0.557,0.997,0.985,1.411c1.718,1.665,1.535,2.509-0.671,3.202c-0.563,0.177-1.142,0.308-1.801,0.483
				c-2.464-3.856-5.758-0.584-8.784-1.041c-1.733-2.355-4.07-4.41-4.938-7.727c0.58-1.919,3.885-1.944,3.848-4.606
				c-0.334-1.223-2.188-1.12-2.209-2.618c2.891-2.695,2.933-4.264,0.026-7.448c1.208-2.71,0.055-5.504-0.194-8.302
				c-0.189-2.124-2.908-3.09-4.95-1.946C377.769,88.542,377.012,89.181,375.904,89.964z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(57)} fill={props.fillColors[57]} d="M442.453,595.344c-4.811-4.308-10.016-7.952-14.63-12.287
				c-1.815-1.705-3.386-3.597-4.666-5.751c-2.257-3.792-5.449-6.785-8.594-9.812c-4.426-4.263-9.37-7.963-13.496-12.551
				c-0.957-1.064-2.304-1.487-3.662-1.854c-3.657-0.987-7.427-1.35-11.165-1.603c-6.106-0.413-9.805-3.518-11.862-9.132
				c-1.887-5.146-5.516-7.022-10.999-6.143c-1.51,0.242-3.025,0.939-4.77,0.292c0.135-2.076,1.261-3.334,3.034-3.998
				c1.006-0.377,2.113-0.585,3.189-0.657c3.301-0.221,6.104-1.626,8.688-3.532c3.345-2.468,4.697-5.722,3.851-9.888
				c-0.575-2.829-1.49-5.547-2.385-8.274c-0.928-2.827-1.846-5.647-2.146-8.632c-0.199-1.967-1.314-3.627-1.969-5.474
				c2.022,1.233,3.263,3.078,4.409,5.031c1.083,1.847,1.348,4.085,2.513,5.802c1.5-0.221,1.517-1.199,1.588-2.062
				c0.216-2.602-0.06-5.157-1.027-7.595c-0.876-2.209-1.83-4.387-2.757-6.574c-3.157-7.453-4.307-15.201-3.393-23.245
				c0.065-0.58,0.26-1.149,0.442-1.708c0.053-0.16,0.275-0.266,0.507-0.474c1.023,1.315,1.378,2.844,1.395,4.414
				c0.036,3.579,1.822,6.196,4.418,8.391c0.983,0.83,2.004,1.629,3.077,2.337c3.12,2.061,4.674,5.012,5.134,8.646
				c0.223,1.765,0.359,3.534,0.917,5.259c0.482,1.495-0.093,2.952-0.778,4.282c-1.429,2.773-1.454,5.424,0.151,8.185
				c1.195,2.055,1.961,4.322,2.272,6.716c0.366,2.827,1.9,4.948,4.117,6.634c2.146,1.631,4.535,2.822,7.005,3.873
				c2.375,1.01,4.649,2.209,6.795,3.653c2.406,1.62,3.361,3.754,2.54,6.572c-0.26,0-0.535,0.08-0.615-0.012
				c-3.981-4.548-9.137-7.203-14.741-9.108c-2.248-0.764-4.521-1.479-6.82-2.064c-2.701-0.688-4.883,0.156-6.362,2.595
				c-0.463,0.763-0.886,1.554-1.398,2.28c-4.932,7.004-1.064,15.351,3.949,19.273c1.53,1.198,3.385,1.562,5.254,1.89
				c3.904,0.686,7.886,0.772,11.775,1.601c2.188,0.465,4.09,1.22,5.681,2.924c1.765,1.891,4.106,3.091,6.289,4.46
				c5.036,3.159,10.182,6.149,14.868,9.841c2.863,2.255,5.2,4.979,6.48,8.367c2.104,5.566,4.781,10.881,6.951,16.413
				C441.854,593.531,442.139,594.443,442.453,595.344z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(58)} fill={props.fillColors[58]} d="M335.217,265.746c0.878,1.62,0.595,2.608,0.599,3.567
				c0.003,0.99-0.183,2.002-0.039,2.968c0.73,4.914,0.954,9.83,0.758,14.803c-0.091,2.301-0.21,4.519-0.927,6.713
				c-2.1,6.423-3.063,13.1-4.279,19.717c-0.251,1.368,0.017,2.757,0.114,4.143c0.171,2.425,0.958,4.81,0.279,7.311
				c-0.336,1.242,0.22,2.55,0.709,3.748c0.734,1.797,0.817,3.66,0.677,5.556c-0.059,0.801-0.118,1.653-0.737,2.208
				c-1.985,1.783-2.125,4.189-2.219,6.56c-0.13,3.286-0.312,6.513-1.886,9.528c-0.987,1.891-0.388,3.837,0.154,5.784
				c1.835,6.596,3.106,13.167,1.678,20.139c-1.466,7.157-1.131,14.392,0.966,21.479c0.73,2.468,1.405,4.963,1.9,7.485
				c1.11,5.655,0.629,11.271-0.923,16.776c-1.505,5.339-2.391,10.832-4.161,16.106c-0.398,1.187-0.354,2.544-0.386,3.827
				c-0.058,2.279-0.05,4.562-0.011,6.843c0.033,1.948-0.72,3.443-2.318,4.551c-1.794,1.242-3.523,2.565-5.153,4.025
				c-3.358,3.007-4.804,6.89-3.361,11.009c1.811,5.173,0.01,9.438-2.022,14.152c-0.548-0.83-0.256-1.493-0.252-2.095
				c0.037-6.446,0.181-12.893,0.107-19.337c-0.045-3.894,1.255-7.207,3.757-10.041c1.613-1.826,2.167-3.82,2.316-6.214
				c0.413-6.62,1.096-13.222,3.627-19.459c0.901-2.221,0.661-4.474,0.168-6.72c-0.168-0.77-0.377-1.572-0.768-2.243
				c-2.906-4.983-2.236-10.336-1.557-15.638c0.868-6.781,1.944-13.535,2.925-20.302c0.326-2.244-0.115-4.427-1.191-6.331
				c-3.46-6.122-3.169-12.426-1.645-19.011c1.808-7.811,3.572-15.669,3.613-23.752c0.018-3.366-0.312-6.733-0.447-10.102
				c-0.088-2.198,0.316-4.3,0.991-6.418c1.934-6.081,2.499-12.416,3.167-18.724c0.616-5.811,0.316-11.722,1.706-17.442
				C332.333,276.031,333.744,271.199,335.217,265.746z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(59)} fill={props.fillColors[59]} d="M477.531,318.339c-1.02-1.592-1.813-2.841-2.617-4.083
				c-0.786-1.216-1.094-2.545-0.888-3.971c0.068-0.473,0.152-1.099,0.47-1.352c0.835-0.664,1.909-1.012,1.928-2.389
				c0.014-1.022-0.33-1.94-0.547-2.908c0.515-1.069,1.304-1.184,2.439-1.064c2.842,0.299,3.903-1.081,3.115-3.779
				c-0.109-0.375-0.213-0.767-0.224-1.154c-0.03-1.083-0.011-2.167-0.011-3.13c2.204-2.399,4.826-1.183,6.668-0.332
				c2.66,1.229,4.471,0.966,6.192-1.105c3.205,0.565,0.807,3.912,3.035,5.166c1.324-0.111,3.079-0.214,4.308,1.149
				c1.107,1.228,1.356,3.062,2.763,4.261c1.287-0.986,2.65-1.796,3.318-3.334c0.45-1.038,1.354-1.703,2.14-2.434
				c1.678-1.561,2.329-1.523,3.772,0.417c0.929,1.25,2.106,1.616,3.535,1.841c3.77,0.593,6.827,2.464,8.813,5.737
				c0.87,1.433,2.404,2.343,2.909,4.043c0.115,0.39,0.697,0.84,1.12,0.907c3.412,0.54,6.3,2.597,9.664,3.272
				c0.813,0.163,1.555-0.249,2.299-0.072c2.617,1.931,1.705,4.354,1.095,6.705c-1.243,1.015-2.903,0.876-4.088,1.758
				c-0.712,0.699,0.208,1.781-0.59,2.452c-0.803-0.285-0.898-1.042-1.21-1.618c-0.729-1.349-1.607-2.454-3.222-2.832
				c-0.652-0.152-1.399-0.469-1.814-0.957c-1.819-2.136-4.32-3.012-6.768-4.108c-1.737-0.778-3.311-0.807-4.755,0.177
				c-1.099,0.749-1.945,0.572-3.021,0.158c-2.383-0.917-3.901-0.108-4.664,2.367c-0.088,0.283-0.075,0.603-0.193,0.867
				c-0.701,1.566-2.162,1.73-3.179,0.354c-0.529-0.715-0.947-1.518-1.521-2.192c-2.321-2.734-4.765-3.104-8.184-2.065
				c-2.378,0.722-4.549,1.714-6.706,2.877c-1.781,0.96-2.736,2.262-2.438,4.213c0.137,0.893,0.425,1.749,0.007,2.601
				c-0.921,1.879-0.947,3.817-0.451,5.795c0.23,0.917,0.275,1.75-0.573,2.415c-1.32,1.034-1.594,2.44-1.18,4.271
				c-1.99-0.321-3.412,0.287-4.871,1.514c-2.002,1.684-6.324-0.229-6.856-2.816c-0.16-0.782-0.382-1.52-0.656-2.267
				c-0.399-1.085-0.337-2.178,0.637-2.981c1.469-1.211,1.456-2.818,0.347-3.92c-1.451-1.441-0.231-3.06-0.819-4.435
				C475.317,320.618,476.582,319.595,477.531,318.339z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(60)} fill={props.fillColors[60]} d="M279.636,149.365c-0.946,1.046-2.091,0.901-3.245,0.425
				c-0.821-0.34-1.598-0.786-2.404-1.166c-1.711-0.808-3.438-1.04-5.073,0.133c-1.527,1.095-2.948,0.853-4.401-0.117
				c-0.824-0.549-1.709-1.006-2.531-1.558c-1.23-0.825-2.048-1.649-1.188-3.41c0.821-1.682,0.215-2.419-1.607-2.388
				c-1.853,0.031-3.749-0.057-5.485,1.12c-0.644-1.641,0.162-2.252,1.295-2.928c1.533-0.915,1.549-1.459,0.397-2.79
				c-0.647-0.748-1.313-1.499-2.071-2.127c-2.387-1.981-2.524-4.833-2.917-7.545c-0.159-1.096,0.487-2.118,1.438-2.774
				c0.83-0.573,1.53-1.248,2.099-2.073c0.605-0.878,1.002-1.778,0.026-2.64c-0.777-0.686-0.985-1.461-0.545-2.384
				c0.384-0.806-0.008-1.547-0.114-2.317c-0.347-2.514,0.977-4.008,3.542-3.719c1.469,0.165,2.985-0.263,4.408,0.365
				c0.085,0.038,0.214,0.062,0.289,0.025c3.049-1.512,6.636-0.421,9.66-1.977c1.596-0.821,3.042-0.704,4.433,0.381
				c1.332,1.038,2.749,1.44,4.442,0.936c1.517-0.451,2.93,0.346,4.317,0.767c1.141,0.346,1.529,1.565,1.523,2.681
				c-0.009,1.896,0.329,3.389,2.659,3.392c2.272,2.172,4.107,1.429,5.889-0.745c0.636-0.776,1.749-0.916,2.768-0.766
				c1.23,0.182,2.235,0.891,1.799,2.177c-0.646,1.905,0.023,2.815,1.755,3.328c0.672,0.198,0.817,0.805,0.964,1.383
				c0.242,0.951,0.469,1.906,0.773,3.15c-1.302-0.022-2.52-0.09-3.481,0.964c-0.693,0.76-1.752,0.564-2.264-0.366
				c-0.721-1.311-1.53-2.003-3.083-1.341c-1.433,0.612-1.902,1.574-1.513,3.083c0.111,0.432-0.139,0.956-0.251,1.599
				c-1.005,0.535-2.53,0.201-3.094,1.748c-0.442,1.214-0.256,2.298,0.224,3.654c-2.508,1.165-5.333,2.132-4.258,5.484
				c-1.239,1.161-2.468,0.966-3.631,1.098c-2.869,0.325-4.044,2.988-2.348,5.354C279.247,148.059,279.653,148.584,279.636,149.365z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(61)} fill={props.fillColors[61]} d="M374.221,425.995c0.657,1.207,0.935,2.333,1.051,3.506
				c0.56,5.673,3.082,10.38,7.036,14.396c2.59,2.629,4.677,5.641,6.631,8.756c1.293,2.062,1.798,4.253,2.062,6.701
				c0.669,6.184,0.952,12.418,2.129,18.542c0.304,1.579,1.046,2.966,1.935,4.307c2.354,3.553,4.854,7.007,7.006,10.694
				c0.657,1.127,1.313,2.245,1.52,3.521c0.561,3.473,2.365,6.343,4.604,8.861c7.021,7.895,10.697,17.239,12.287,27.517
				c0.771,4.985,1.362,9.998,1.86,15.204c-1.88-2.279-4.656-3.203-6.854-4.896c-2.801-2.154-4.463-5.039-4.101-8.945
				c0.529-5.697-0.915-11.053-4.15-15.771c-2.18-3.179-4.144-6.526-6.801-9.374c-1.391-1.49-2.012-3.415-2.421-5.363
				c-0.716-3.413-2.046-6.622-3.222-9.877c-1.091-3.021-2.897-5.638-5.163-7.914c-2.657-2.67-5.314-5.34-8.444-7.485
				c-3.351-2.298-5.651-5.232-5.67-9.551c-0.003-0.871-0.166-1.829-0.553-2.598c-1.753-3.492-2.009-7.265-1.979-11.023
				c0.05-6.346-1.403-12.356-3.321-18.338c-1.141-3.555-3.339-6.003-6.582-7.648c-1.501-0.762-3.016-1.499-4.509-2.277
				c-0.408-0.213-0.927-0.375-0.988-1.286C363.14,426.314,368.618,429.526,374.221,425.995z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(62)} fill={props.fillColors[62]} d="M60.62,703.069c-0.722,0.045-1.308,0.104-1.893,0.109
				c-0.37,0.003-0.779,0.063-1.218-0.484c0.853-1.58,2.07-3.021,3.461-4.225c10.677-9.245,18.786-20.626,26.961-31.981
				c3.824-5.311,8.61-9.498,15.15-11.4c3.986-1.159,7.839-2.777,11.757-4.176c1.776-0.634,3.585-1.172,5.46-1.405
				c5.727-0.715,9.87-3.78,12.966-8.52c5.26-8.049,11.025-15.761,16.083-23.944c1.934-3.129,4.546-5.465,7.715-7.239
				c0.247-0.139,0.543-0.189,0.945-0.324c0.532,0.949-0.056,1.729-0.33,2.479c-0.915,2.511-2.014,4.95-2.783,7.521
				c-1.032,3.452-0.405,6.696,1.195,9.768c1.29,2.475,3.438,3.726,6.293,3.759c2.671,0.03,5.071-0.839,7.356-2.074
				c1.387-0.748,2.687-1.655,4.267-2.643c-0.061,2.068-0.992,3.626-2.029,5.057c-1.16,1.6-2.469,3.108-3.841,4.532
				c-1.768,1.835-3.019,3.913-3.6,6.391c-0.457,1.949-1.18,3.759-2.385,5.394c-0.61,0.828-1.518,1.771-0.632,2.922
				c0.84,1.093,1.921,0.73,2.962,0.276c0.269-0.117,0.532-0.288,0.975-0.021c-0.292,0.953-1.223,1.343-1.941,1.868
				c-2.216,1.623-4.005,3.532-4.74,6.271c-0.444,1.656-1.503,2.772-3.164,3.253c-1.539,0.445-2.58,1.438-3.236,2.855
				c-1.859,4.017-5.402,6.211-8.995,8.354c-1.034,0.617-2.017,0.456-3.105-0.004c-3.145-1.331-4.616-0.77-6.274,2.248
				c-0.381,0.693-0.726,1.41-1.047,2.135c-1.076,2.431-2.99,3.984-5.433,4.754c-4.832,1.523-8.256,4.983-11.771,8.312
				c-1.234,1.168-2.326,2.546-4.039,2.902c-4.015,0.837-6.542,3.14-7.353,7.169c-1.606,0.335-3.044,0.3-4.881,0.015
				c1.772-3.121,3.434-6.016,5.51-8.626c0.938-1.181,2.494-1.571,3.946-1.936c1.075-0.271,2.153-0.583,2.852-1.479
				c1.857-2.379,4.437-3.943,6.596-5.978c1.995-1.88,4.486-2.925,6.986-3.846c2.01-0.74,3.261-2.016,4.249-3.866
				c2.051-3.846,4.234-7.623,6.454-11.375c0.947-1.601,2.016-3.167,3.923-4.277c0.062,1.946-0.825,3.421-1.167,5.011
				c-0.23,1.069-0.566,2.115-0.432,3.218c0.235,1.931,1.731,2.758,3.412,1.774c2.751-1.607,5.317-3.439,6.847-6.396
				c1.068-2.062,2.364-3.901,4.913-4.365c0.869-0.159,1.18-0.985,1.451-1.747c0.533-1.5,1.192-2.927,2.141-4.226
				c0.866-1.187,0.914-2.593,0.821-3.997c-0.101-1.53,0.267-2.854,1.112-4.199c3.605-5.742,1.587-11.242-4.855-13.424
				c-3.076-1.042-6.203-1.188-9.371-0.357c-3.038,0.797-5.457,2.409-6.783,5.311c-1.64,3.585-4.222,6.452-6.824,9.311
				c-2.694,2.959-6.056,4.73-9.97,5.523c-1.745,0.353-3.488,0.749-5.192,1.261c-2.389,0.717-4.795,1.226-7.301,1.213
				c-0.691-0.004-1.395,0.037-2.073,0.162c-3.659,0.678-5.547,3.751-4.461,7.303c0.288,0.942,0.778,1.821,1.144,2.741
				c1.782,4.486-0.011,8.254-4.643,9.407c-2.395,0.596-4.866,0.92-7.32,1.225c-4.253,0.529-7.982,2.019-11.173,5.013
				c-4.702,4.412-9.45,8.769-13.76,13.574C64.318,697.364,62.262,699.903,60.62,703.069z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(63)} fill={props.fillColors[63]} d="M608.925,285.157c-1.157-0.141-1.747-0.895-2.534-1.28
				c-1.077-0.527-2.197-1.151-3.383-0.941c-1.899,0.335-3.522,0.361-5.336-0.76c-1.711-1.058-3.996,0.42-4.438,2.448
				c-0.189,0.868-0.238,1.766-0.353,2.649c-0.127,0.983-0.527,1.703-1.703,1.719c-1.776-3.352-4.827-2.085-7.626-1.956
				c-0.865-0.999-1.551-2.148-1.614-3.416c-0.161-3.181-1.748-5.608-3.77-7.847c-2.007-2.223-2.313-5.01-3.036-7.667
				c1.263-0.48,1.76,0.404,2.495,0.575c1.15,0.266,2.253,0.228,2.901-0.859c0.749-1.255-0.418-1.702-1.176-2.292
				c-0.223-0.174-0.385-0.427-0.53-0.593c-0.093-0.838,0.444-1.222,0.993-1.357c1.103-0.271,1.436-1.105,2.002-1.944
				c1.131-1.674,2.06-3.486,3.234-5.097c1.748-2.397,1.911-2.278,0.405-4.932c1.049-1.675,0.894-4.247,3.455-5.001
				c2.177,1.541,2.549,1.577,4.555-0.164c0.964-0.836,1.75-1.395,2.597,0.007c0.575,0.953,1.466,0.859,2.361,0.812
				c3-0.153,3-0.153,5.254,1.613c0.67,0.188,1.077-0.41,1.642-0.545c0.991-0.238,1.777-0.124,2.031,1.054
				c0.336,1.564,1.445,1.961,2.88,1.969c1.353,0.007,2.097-0.514,2.657-1.84c0.769-1.817,1.42-1.995,3.229-1.217
				c1.275,0.548,2.486,0.62,3.739,0.134c0.966-0.375,1.892-0.644,2.946,0.374c0,3.101,0,6.436,0,9.964
				c-2.111,0.587-4.043,1.646-5.522,3.449c-0.9,1.097-2.113,1.19-3.505,1.033c-2.816-0.319-4.119,1.53-2.736,3.943
				c0.66,1.152,0.569,2.29,0.608,3.435c0.054,1.573-0.519,2.023-2.141,2.061c-2.144,0.05-3.214,1.369-2.158,3.119
				c1.091,1.808,2.244,3.367,1.184,5.585c-0.149,0.313,0.259,0.915,0.448,1.366C609.303,283.526,609.411,284.28,608.925,285.157z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(64)} fill={props.fillColors[64]} d="M205.433,520.804c2.015-4.546,3.496-9.262,5.725-13.671
				c1.629-3.223,3.864-5.984,6.538-8.398c1.916-1.73,3.901-3.402,6.133-4.686c3.867-2.224,6.053-5.506,7.039-9.81
				c1.684-7.351,5.277-13.839,9.386-20.077c2.334-3.544,5.334-6.356,8.963-8.535c2.45-1.471,4.869-2.993,7.218-4.44
				c1.14,0.507,1.283,1.466,1.381,2.308c0.43,3.689,0.548,7.447-1.597,10.659c-3.267,4.892-2.609,9.233,1.067,13.53
				c1.604,1.877,2.964,3.969,3.917,6.285c1.354,3.287,1.291,5.483-0.389,9.06c-1.135-2.028-2.412-3.782-2.849-6.001
				c-0.231-1.171-0.814-2.229-1.498-3.214c-2.763-3.981-8.824-4.131-11.806-0.293c-0.701,0.903-0.958,1.929-0.9,3.063
				c0.087,1.711,0.418,3.415,1.298,4.84c1.56,2.526,1.437,5.092,0.526,7.685c-0.981,2.797-2.157,5.479-4.277,7.715
				c-1.781,1.877-2.787,4.297-3.597,6.762c-1.086,3.311-1.704,6.711-2.048,10.171c-0.274,2.762-0.679,5.505-1.396,8.192
				c-0.538,2.017-0.237,3.836,0.844,5.682c2.492,4.258,2.101,6.024-1.907,9.61c-0.945-2.334-2.472-4.228-4.034-6.104
				c-1.063-1.278-1.444-2.678-1.061-4.414c0.664-3.005,0.807-6.093,0.736-9.169c-0.152-6.608,1.379-12.732,4.826-18.414
				c2.208-3.641,4.2-7.415,5.803-11.372c0.556-1.372,1.15-2.757,1.258-4.259c0.137-1.897-0.918-2.906-2.475-2.039
				c-3.922,2.186-8.263,3.332-12.267,5.271c-2.297,1.112-3.846,3.096-5.141,5.239c-2.766,4.579-5.476,9.191-8.245,13.769
				c-0.716,1.184-1.489,2.35-2.372,3.41C208.75,520.939,208.276,521.052,205.433,520.804z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(65)} fill={props.fillColors[65]} d="M557.828,550.388c-2.295-3.721-4.218-7.38-5.602-11.311
				c-0.49-1.393-0.963-2.757-2.615-3.375c-0.867-0.323-1.049-1.201-0.661-2.252c0.892-2.415,0.455-3.863-1.633-4.912
				c-2.407-1.208-3.097-3.15-2.967-5.604c0.137-2.557,1.037-4.788,3.606-5.627c2.736-0.894,3.883-2.806,4.526-5.371
				c0.325-1.299,1.273-2.618,2.594-2.542c2.279,0.132,4.18-1.161,6.6-1.214c0.858,1.871,3.601,1.888,4.4,4.168
				c0.181,0.513,1.062,0.523,1.641,0.403c1.104-0.231,2.137,0.08,3.287,0.37c1.04-1.793,2.583-2.972,4.471-3.683
				c2.791-1.05,4.535-0.348,5.719,2.373c0.904,2.08,1.822,4.167,2.309,6.396c0.219,1.003,0.695,1.872,1.3,2.641
				c1.479,1.884,1.323,3.593-0.352,5.292c-0.282-0.03-0.569-0.039-0.847-0.096c-2.956-0.599-2.966-0.602-3.201,2.393
				c-0.042,0.527-0.306,0.897-0.573,1.319c-1.259,1.984-1.222,2.409,0.264,4.092c0.312,0.354,0.856,0.529,0.76,1.541
				c-0.799-0.416-1.476-0.769-2.327-1.212c-1.047,1.004-2.318,1.835-3.956,1.684c-0.583,1.67-0.243,3.113,0.986,3.86
				c2.072,1.26,2.209,3.087,2.129,5.113c-0.034,0.88,0.216,1.776-0.211,2.852c-3.233,0.575-6.419,1.504-7.012,5.6
				c-0.199-0.128-0.461-0.203-0.486-0.327c-0.696-3.39-1.438-3.854-4.789-2.932c-0.484,0.133-1.103,0.398-1.42,0.059
				C561.727,547.901,559.857,549.206,557.828,550.388z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(66)} fill={props.fillColors[66]} d="M216.205,193.307c3.004-0.643,3.573-1.24,3.661-3.762
				c0.116-3.353,1.742-4.964,5.065-4.857c0.805,0.026,1.559,0.146,2.292-0.277c1.021-0.59,1.902-1.423,3.115-1.688
				c1.157-0.252,2.295-0.597,3.64-0.954c0.769,1.066,2.566,1.582,1.737,3.735c-0.323,0.84,1.602,2.413,2.461,1.914
				c2.07-1.201,3.224,0.573,4.826,0.859c-0.264,2.102-2.275,1.417-3.336,2.486c-0.171,1.537-0.131,3.227-1.196,4.819
				c-0.904,1.353,0.744,3.612,2.536,3.406c2.509-0.288,4.662,0.506,6.896,1.316c2.459,0.892,5.783-0.667,6.729-3.119
				c0.318-0.826,0.552-1.702,0.68-2.576c0.112-0.771,0.023-1.573,0.023-2.435c0.9-0.768,1.862-0.708,2.347,0.432
				c0.611,1.436,1.842,1.642,3.099,1.89c-0.117,1.325-0.498,2.387-1.308,3.343c-1.919,2.266-1.503,4.435,1.288,5.432
				c1.6,0.572,1.648,1.396,1.419,2.625c-4.177,2.324-4.293,2.681-2.544,7.732c-0.614,0.153-0.8-0.386-1.106-0.693
				c-0.976-0.977-2.016-1.28-3.126-0.267c-1.023,0.933-1.276,2.07-0.317,3.147c1.641,1.843,1.771,4.318,3.201,6.584
				c-2.053,0.356-4.119,0.391-4.585,2.714c-0.339,1.689,1.474,1.577,2.125,2.404c0.111,1.168-0.182,2.188-0.699,3.421
				c-2.924-0.405-4.543,1.315-5.496,3.623c-1.369,0.468-2.031-0.353-2.811-0.947c-1.976-1.504-3.099-1.361-4.198,0.847
				c-1.101,2.214-1.885,4.577-0.765,7.201c-1.69-1.491-3.536-2.24-5.78-2.267c-0.065-2.201,0.478-4.395-1.05-6.577
				c3.775-0.781,4.295-3.373,4.7-6.454c0.3-2.278,0.655-4.191-1.042-6.056c-0.685-0.752-0.525-1.907-0.124-2.851
				c1.012-2.38-0.098-3.966-1.854-5.392c-1.628-1.322-3.541-1.707-5.545-1.809c-1.193-0.061-2.043,0.602-2.78,1.47
				c-0.543,0.639-0.872,1.622-1.979,1.442c-0.937-0.552-0.668-1.4-0.622-2.15c0.12-1.956-0.14-3.764-1.736-5.134
				c-0.743-0.637-0.948-1.466-0.866-2.41c0.254-2.915-0.172-3.82-3.029-4.658c-1.817-0.533-2.757-1.807-3.812-3.096
				C215.783,195.047,215.709,194.3,216.205,193.307z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(67)} fill={props.fillColors[67]} d="M465.013,702.933c-1.183,0.552-1.603-0.129-1.891-1.082
				c-0.718-2.371-1.424-4.745-2.178-7.104c-0.725-2.265-1.479-4.52-2.269-6.762c-0.621-1.769-1.82-3.263-2.648-4.864
				c-1.908-3.692-4.389-6.774-7.373-9.558c-0.271-0.254-0.447-0.676-0.535-1.051c-0.686-2.946-2.784-4.138-5.539-4.629
				c-1.566-0.28-3.035-0.877-4.406-1.718c-4.311-2.64-8.886-2.697-13.434-0.922c-2.185,0.853-4.162,1.005-6.305,0.192
				c-0.734-0.279-1.525-0.485-2.306-0.555c-3.816-0.339-7.327-1.502-10.615-3.497c-1.654-1.003-3.426-1.294-5.341-0.202
				c-3.313,1.888-7.506,0.568-9.369-2.792c-1.111-2.005-2.062-4.059-1.96-6.448c0.033-0.78-0.006-1.603-0.646-2.188
				c-0.78-0.475-1.248,0.49-2.049,0.32c-0.862-1.903-1.907-3.954-1.697-6.13c0.244-2.524-0.117-4.938-0.459-7.383
				c-0.212-1.516-0.162-2.924,1.04-4.07c1.15,0.55,1.398,1.747,2.164,2.502c0.501,0.495,1.01,1.022,1.802,0.744
				c0.736-0.258,1.058-0.872,1.098-1.613c0.038-0.685,0.008-1.372,0.008-1.961c0.948-0.53,1.242,0.122,1.561,0.564
				c1.528,2.121,3.653,3.619,5.551,5.351c0.731,0.667,1.697,1.304,2.616,0.336c0.893-0.938,0.626-1.984-0.093-2.965
				c-0.669-0.912-2.001-1.358-2.031-2.711c0.69-0.716,1.15-0.178,1.568,0.184c1.947,1.685,3.897,3.367,5.79,5.113
				c0.646,0.596,1.352,1.287,1.642,2.078c1.071,2.92,3.095,5.295,4.428,8.061c0.968,2.005,2.496,3.595,4.411,4.725
				c3.938,2.324,7.938,0.404,8.631-4.096c0.502-3.257,0.815-6.507-0.7-9.631c2.987-0.509,3.339-0.324,4.24,2.243
				c0.627,1.788,0.873,3.656,1.127,5.522c0.156,1.153,0.24,2.344,1.22,3.245c2.389-0.973,0.965-3.104,1.551-5.03
				c1.942,3.327,3.573,6.331,4.911,9.494c0.231,0.547,0.395,1.122,0.628,1.668c1.377,3.215,3.446,4.677,6.914,4.484
				c1.425-0.08,2.264,0.363,2.902,1.491c0.486,0.86,1.022,1.704,1.404,2.61c0.737,1.751,1.93,3.217,2.862,4.825
				c2.024,3.491,4.406,6.712,6.705,10.003c0.385,0.552,0.562,1.263,0.771,1.92c1.974,6.233,3.939,12.469,5.892,18.709
				C464.863,701.176,465.364,701.976,465.013,702.933z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(68)} fill={props.fillColors[68]} d="M280.295,522.476c2.896-1.781,4.47-4.413,5.741-7.893
				c-3.038,0.098-5.757,0.365-7.767,2.422c-1.725,1.766-3.32,3.666-5.099,5.396c-0.922,0.897-0.854,0.968-0.707,2.954
				c-3.125,1.152-3.335,1.59-2.55,5.302c-0.127,0.138-0.301,0.415-0.375,0.391c-3.315-1.095-6.399,0.518-9.585,0.852
				c-1.148,0.12-2.429,0.493-3.325,1.178c-1.551,1.185-2.974,2.101-5.08,1.649c-1.35-0.289-2.075,0.956-2.783,1.903
				c-1.661,2.223-3.358,4.422-4.919,6.715c-4.054,5.956-10.133,9.266-16.455,12.413c0.47-0.508,0.918-1.039,1.415-1.519
				c2.212-2.136,4.442-4.253,6.66-6.382c2.391-2.295,4.287-4.957,5.61-7.985c1.164-2.666,2.865-4.98,4.541-7.31
				c2.316-3.222,5.488-5.067,9.441-5.594c2.357-0.314,4.701-0.734,7.052-1.1c2.215-0.343,3.941-1.6,5.012-3.414
				c2.151-3.643,5.429-6.291,7.982-9.562c1.945-2.492,4.829-2.951,7.593-3.682c0.954-0.252,1.955-0.32,2.93-0.498
				c4.066-0.743,5.162-1.873,5.724-5.994c0.361-2.65,0.528-5.323,1.071-7.955c0.645-3.124,1.386-6.176,3.319-8.817
				c0.899-1.229,1.031-2.78,1.395-4.21c0.505-1.985,1.451-3.667,2.875-5.117c0.581-0.591,1.206-0.988,2.064-1.023
				c1.852-0.074,2.977,1.163,2.176,2.829c-1.575,3.272-2.476,6.81-4.857,9.79c-2.506,3.136-3.373,7.123-3.507,11.175
				c-0.026,0.793-0.058,1.592,0.005,2.38c0.345,4.36-0.95,8.09-3.618,11.61c-2.495,3.292-3.396,7.33-3.722,11.444
				c-0.078,0.977,0.164,1.982-0.335,3.13c-2.908,0.366-4.366,2.601-5.515,5.062c-1.559,3.337-3.772,6.29-5.443,9.548
				c-0.728,1.418-1.866,1.69-3.158,1.759c-1.781,0.095-3.57,0.076-5.354,0.053c-1.413-0.02-2.527,0.41-3.14,1.771
				c-1.232,2.737-3.74,3.883-6.351,4.422c-2.897,0.599-5.77,1.49-8.775,1.519c-1.443,0.014-2.766,0.499-3.84,1.409
				c-2.141,1.816-4.492,3.236-7.101,4.259c-0.455,0.178-0.921,0.432-1.265,0.77c-3.949,3.874-8.364,7.313-11.301,12.136
				c-1.34,2.202-2.818,4.322-4.103,6.554c-1.419,2.467-3.542,3.956-6.149,4.86c-2.428,0.842-4.849,1.698-7.458,2.979
				c0.376-2.722,1.486-4.886,2.607-7.025c0.446-0.851,1.426-0.993,2.32-1.145c1.778-0.303,3.079-1.248,3.953-2.842
				c0.475-0.866,1.157-1.621,1.61-2.496c3.292-6.369,8.365-11.271,13.331-16.263c0.418-0.421,0.859-0.843,1.358-1.155
				c3.674-2.308,7.614-4.174,11.12-6.771c0.765-0.566,1.815-0.794,2.827-0.834c3.392-0.135,6.621-1.253,9.967-1.648
				c1.217-0.144,1.963-0.749,2.534-1.782c1.695-3.068,4.243-4.615,7.822-4.239c0.807,0.085,1.591,0.044,2.229-0.562
				c0.305-0.29,0.675-0.517,1.035-0.19c1.3,1.179,2.442,0.179,3.551-0.292c4.191-1.781,6.374-5.177,7.35-9.473
				c0.153-0.675,0.18-1.377,0.304-2.059C283.75,523.13,283.576,522.896,280.295,522.476z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(69)} fill={props.fillColors[69]} d="M337.562,139.698c-1.191,0.106-1.819,1.663-3.1,0.795
				c0.73-2.302,0.73-2.302,0.127-4.104c2.106-1.905,2.471-3.862,1.019-5.462c-1.304-1.437-4.34-1.103-6.867,0.757
				c-1.569-1.121-3.225-3.17-3.987-4.995c0.841-0.995,1.718-2.043,2.606-3.082c1.018-1.19,1.643-2.279,1.694-4.122
				c0.064-2.348,1.427-4.647,1.841-7.098c0.117-0.695,1.021-1.186,1.947-0.896c0.094,0.029,0.183,0.078,0.276,0.108
				c5.713,1.816,7.1,4.226,5.988,10.39c-0.051,0.282-0.007,0.58-0.007,0.786c0.697,0.898,1.642,0.875,2.507,0.946
				c1.1,0.089,1.859-0.328,2.155-1.544c0.488-2.002,0.848-3.986,0.036-6.143c1.479-0.377,2.573,0.676,3.888,0.209
				c1.52-0.539,1.175-2.193,2.097-2.894c1.395-0.117,1.66,1.513,2.841,1.559c2.815-1.126,0.002-4.075,1.644-5.596
				c2.838,1.224,3.301,1.149,5.497-1.028c0.437-0.433,0.801-0.92,1.477-0.772c0.793,0.173,1.105,0.794,1.172,1.531
				c0.072,0.788,0.006,1.593,0.131,2.37c0.263,1.645,1.352,2.15,2.773,1.303c0.411-0.245,0.827-0.597,1.061-1.003
				c0.589-1.03,1.383-1.299,2.496-1.006c1.326,0.349,2.336-0.047,2.878-1.391c0.453-1.122,1.334-1.648,2.634-1.81
				c1.479,2.605,4.762,3.459,5.992,6.4c1.301,3.11,3.445,5.695,5.654,8.204c0.611,0.694,0.77,1.342,0.261,2.122
				c-0.617,0.538-1.32,0.264-1.993,0.205c-1.344-0.117-2.031,0.61-2.301,1.81c-0.285,1.273,0.285,2.31,1.71,2.666
				c1.916,0.478,3.846,0.779,5.533,1.964c1.715,1.203,1.85,1.816,0.238,3.304c-1.313,1.213-1.74,3.075-3.243,4.174
				c-0.57,0.417-0.39,1.312,0,1.931c0.473,0.75,1.066,1.423,1.551,2.166c0.741,1.135,0.502,2.48-0.756,2.92
				c-2.573,0.9-4.101,3.039-6.021,4.708c-0.678,0.588-1.099,1.327-0.826,2.371c0.812,3.115,0.637,3.391-2.687,4.765
				c-1.55-1.132-3.202-2.61-5.717-0.858c0.107-1.677,0.245-2.719,1.696-3.104c1.366-0.363,2.074-1.486,2.688-2.621
				c0.91-1.682,2.046-3.111,3.619-4.251c1.386-1.004,2.282-2.412,2.773-4.108c0.456-1.577,0.445-2.953-1.01-3.973
				c-0.95-0.666-1.062-1.485-0.899-2.581c0.608-4.139,0.499-4.27-3.354-5.986c2.062-2.182,2.064-2.943-0.008-5.082
				c-0.481-0.498-0.987-0.977-1.514-1.428c-2.843-2.435-4.414-2.388-7.064,0.191c-4.226-1.035-6.257,0.928-5.638,5.76
				c-2.931-0.83-4.623,0.893-6.002,2.942c-0.975,0.152-1.663-0.325-2.383-0.596c-1.203-0.454-2.115-0.417-2.601,0.979
				c-0.542,1.56-1.693,1.677-3.121,1.363c-3.429-0.752-4.528,0.012-5.047,3.35c-0.075,0.484-0.084,1.054-0.35,1.42
				c-1.023,1.412-1.069,2.908-0.445,4.477c-1.058,0.859-1.881,0.98-2.437-0.304C340.072,141.387,339.241,140.216,337.562,139.698z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(70)} fill={props.fillColors[70]} d="M221.046,634.172c-0.164,5.468-0.913,10.457-5.368,14.067
				c-1.24,1.005-2.489,1.931-4.16,2.141c-2.165,0.271-3.146,1.688-3.663,3.725c-0.939,3.701-1.43,3.916-4.405,1.644
				c-2.698-2.061-5.265-1.5-7.691,0.133c-3.393,2.283-6.22,5.213-8.528,8.607c-1.621,2.383-2.893,4.961-4.094,7.573
				c-1.738,3.778-3.367,7.608-6.061,10.865c-0.548,0.663-0.958,1.552-1.128,2.397c-0.882,4.382-3.023,8.376-3.824,12.788
				c-0.296,1.632-0.807,3.273-1.858,4.89c-5.581,0-11.265,0-16.972,0c-0.146-0.996,0.588-1.498,0.988-2.132
				c1.164-1.843,2.492-3.589,3.561-5.482c3.221-5.711,6.817-11.102,11.644-15.639c2.244-2.109,3.738-4.857,5.086-7.637
				c0.691-1.426,1.354-2.865,2.055-4.287c2.009-4.067,4.516-7.656,8.834-9.641c2.161-0.993,3.604-2.752,4.458-4.968
				c0.418-1.082,0.474-2.131-0.494-3c-2.176-1.955-2.338-4.52-1.929-7.118c0.746-4.741,2.792-9.032,5.007-13.605
				c2.949,3.581,6.901,3.557,10.85,3.362c2.631-0.13,4.989,0.183,7.359,1.633c2.82,1.726,5.988,1.987,9.049,0.201
				C220.089,634.499,220.469,634.401,221.046,634.172z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(71)} fill={props.fillColors[71]} d="M448.324,703.042c-17.28,0-34.398,0-51.682,0c-0.144-1.876-0.21-3.635-0.428-5.373
				c-0.421-3.369-1.089-6.613-3.635-9.211c-1.332-1.36-0.859-2.777,0.993-3.822c2.181-1.231,4.641-1.714,7.013-2.372
				c1.843-0.511,3.584-1.115,5.132-2.235c2.479-1.795,5.127-1.84,7.914-0.837c1.77,0.636,3.602,1.104,5.351,1.789
				c2.505,0.982,4.9,0.646,7.145-0.575c7.118-3.875,14.091,0.778,15.77,6.522c0.893,3.054,2.111,5.906,5.188,7.644
				c1.624,0.916,2.173,2.781,1.922,4.699C448.852,700.446,448.587,701.608,448.324,703.042z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(72)} fill={props.fillColors[72]} d="M202.125,522.861c-3.263,3.344-3.268,3.386-0.997,7.089
				c-2.976,7.336-4.388,15.229-8.328,22.282c-1.218-0.267-0.804-2.045-2.055-2.253c-1.042-0.231-1.656,0.473-2.141,1.203
				c-0.709,1.069-1.389,2.18-1.91,3.349c-4.316,9.698-9.504,18.954-14.367,28.374c-1.137,2.202-2.353,4.371-3.15,6.722
				c-1.167,3.436-3.755,5.29-7.008,6.29c-1.977,0.607-4.051,0.963-6.107,1.231c-5.321,0.694-9.852,2.93-13.555,6.782
				c-2.561,2.664-5.432,4.882-8.553,6.862c-6.563,4.165-10.479,10.419-13.146,17.521c-1.368,3.642-1.301,3.753-4.712,6.181
				c-0.586-0.708,0.074-1.268,0.275-1.81c1.274-3.436,2.619-6.845,3.929-10.267c1.901-4.963,5.091-8.917,9.373-12.023
				c1.364-0.99,2.713-2.002,4.083-2.983c2.274-1.63,4.241-3.517,5.786-5.901c2.188-3.38,5.053-6.107,8.714-7.892
				c1.478-0.72,2.542-1.887,3.558-3.103c1.429-1.708,3.071-2.604,5.435-2.351c2.311,0.247,3.447-0.624,3.512-2.979
				c0.129-4.684,2.274-8.574,4.396-12.481c3.83-7.054,7.918-13.962,11.531-21.139c2.503-4.973,5.968-9.351,9.238-13.837
				c1.462-2.006,2.795-4.079,3.905-6.311c0.702-1.412,1.546-2.775,2.487-4.042C194.657,524.229,197.756,522.559,202.125,522.861z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(73)} fill={props.fillColors[73]} d="M245.979,530.063c0.935-3.136,2.271-6.025,4.274-8.425
				c2.458-2.943,4.253-6.241,5.622-9.712c1.785-4.527,4.536-8.062,8.688-10.549c0.508-0.305,0.946-0.725,1.445-1.046
				c4.019-2.591,5.975-6.207,6.536-11.028c0.464-3.996,2.027-7.843,3.663-11.572c0.203-0.464,0.433-0.926,0.902-1.14
				c2.786-1.27,5.535-2.733,8.762-2.281c1.799,0.251,3.41,0.026,5.163-0.708c2.965-1.244,5.983-1.183,8.638,1.713
				c-2.527,2.347-3.635,5.362-4.218,8.61c-0.256,1.427-1.446,2.313-2.016,3.59c-1.601,3.587-2.219,7.386-2.767,11.214
				c-0.197,1.372-0.278,2.762-0.493,4.131c-0.549,3.495-1.356,4.358-4.941,4.825c-2.278,0.297-4.477,0.832-6.649,1.528
				c-1.14,0.365-2.312,0.798-3.077,1.733c-2.569,3.14-5.924,5.601-8.014,9.14c-1.787,3.023-4.151,4.927-7.85,5.021
				c-1.763,0.046-3.509,0.596-5.271,0.862C251.17,526.454,248.435,527.905,245.979,530.063z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(74)} fill={props.fillColors[74]} d="M313.693,310.957c0.309,2.855,0.363,5.416-1.798,7.494
				c-0.679,0.652-0.792,1.538-0.939,2.439c-0.398,2.445-0.828,4.879-0.874,7.368c-0.027,1.444-0.48,2.675-1.759,3.574
				c-1.953,1.373-2.626,3.443-2.62,5.712c0.008,2.773,0.102,5.55,0.271,8.319c0.277,4.495,0.14,8.905-1.746,13.118
				c-0.902,2.017-0.915,4.256-1.244,6.4c-0.811,5.275-2.094,10.467-2.348,15.849c-0.195,4.141-0.978,8.254-1.494,12.38
				c-0.163,1.302-0.58,2.539-1.28,3.617c-1.102,1.695-1.286,3.489-0.96,5.41c0.417,2.451,0.359,4.887,0.205,7.382
				c-0.173,2.807,0.803,5.58,3.092,7.385c2.558,2.017,4.151,4.463,4.76,7.613c0.056,0.292,0.089,0.605,0.223,0.862
				c2.791,5.371,1.246,10.835,0.278,16.255c-0.383,2.146-1.849,3.677-3.675,4.844c-1.001,0.64-2.054,1.23-2.942,2.006
				c-1.263,1.103-1.142,2.214,0.201,3.193c0.236,0.172,0.522,0.277,0.787,0.41c6.747,3.405,9.618,9.33,10.446,16.442
				c0.383,3.293-0.744,6.408-1.875,9.48c-1.218-0.353-0.637-1.119-0.522-1.702c0.48-2.435,0.908-4.845,0.477-7.363
				c-0.458-2.679-1.878-4.854-3.685-6.646c-1.972-1.955-3.794-4.045-5.757-6.003c-1.037-1.035-2.179-1.692-3.692-1.541
				c-1.011,0.102-1.947-0.188-2.896-0.519c-3.033-1.052-5.873-0.55-8.422,1.337c-1.746,1.292-3.48,2.616-4.559,4.609
				c-0.727,1.342-1.994,2.181-3.224,3.024c-3.554,2.437-5.351,5.754-5.051,10.112c0.143,2.068,0.308,4.135,0.475,6.368
				c-0.745,0.5-1.543,1.036-2.38,1.597c-1.075-0.699-1.081-1.726-1.008-2.663c0.357-4.627,0.404-9.311,2.441-13.646
				c0.721-1.532,1.466-3.045,2.579-4.324c1.57-1.806,2.959-3.714,3.948-5.907c0.589-1.309,1.747-2.119,2.803-3.023
				c2.883-2.469,3.147-4.049,0.743-7.068c-2.599-3.264-3.153-6.521-0.8-10.089c0.91-1.379,0.674-2.75,0.282-4.262
				c-1.093-4.204-1.149-8.424,0.521-12.521c1.25-3.066,2.995-5.552,6.775-5.775c1.585-0.094,2.877-0.962,2.995-2.759
				c0.128-1.968,1.023-3.598,2.015-5.205c0.902-1.462,1.588-3.023,1.491-4.753c-0.224-4.004,0.756-7.879,1.116-11.819
				c0.153-1.676,0.3-3.353,0.473-5.026c0.111-1.083,0.086-2.223,0.433-3.229c1.292-3.758,2.027-7.644,2.877-11.506
				c0.148-0.672,0.224-1.548,0.672-1.932c2.089-1.79,2.065-4.12,2.002-6.531c-0.021-0.779,0.153-1.579,0.338-2.345
				c1.465-6.099,1.242-12.404,2.266-18.565c0.383-2.303,0.334-4.752-0.298-7.091c-0.364-1.345-0.247-2.635,0.821-3.565
				c1.67-1.455,2.518-3.555,4.139-5.034c0.522-0.477,0.75-1.042,0.757-1.826C309.571,318.689,311.658,313.56,313.693,310.957z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(75)} fill={props.fillColors[75]} d="M434.047,201.725c-0.894,2.428-3.309,3.389-5.248,5.064
				c-1.021-0.69-1.969-1.467-3.246-1.311c-0.55,0.066-0.951-0.208-1.187-0.697c-0.9-1.873-2.806-1.67-4.378-2.017
				c-1.222-0.269-1.722,0.439-1.562,1.794c0.201,1.725,1.152,3.125,2.065,4.451c-3.747,5.685-3.906,5.719-8.68,1.979
				c-0.574-0.709,0.344-1.277,0.183-1.949c-0.404-1.693-2.552-2.209-4.007-0.962c-1.978,1.696-1.998,1.713-3.751-0.388
				c-0.611-0.731-1.418-1.063-2.251-1.364c-0.741-0.268-1.594-0.313-2.252-0.701c-1.541-0.909-2.945-0.993-4.289,0.58
				c-1.046-1.51-0.589-3.342-1.96-4.418c-1.469-1.153-3.032,0.683-4.523-0.239c0.057-0.357,0.019-0.823,0.155-0.882
				c2.124-0.917,2.628-3.655,5.069-4.327c1.068-0.294,1.192-1.725,1.242-2.833c0.044-0.964-0.345-2.221,0.873-2.571
				c1.256-0.361,2.216,0.592,3.06,1.438c0.269,0.27,0.428,0.662,0.596,1.017c1.449,3.062,1.68,3.147,5.217,1.56
				c-0.966-2.091-0.097-4.773-2.336-6.565c-0.41-0.328-0.714-0.926-0.492-1.317c1.055-1.863,0.734-4.337,2.599-5.826
				c0.861-0.687,1.609-1.491,2.128-2.458c0.626-1.168,1.616-1.339,2.773-1.201c1.276,0.152,1.44,1.131,1.652,2.135
				c0.583,2.768,1.452,3.569,3.546,3.368c2.105-0.203,3.432-1.7,3.681-3.985c0.121-1.109-0.677-2.383,0.479-3.309
				c0.243,0,0.472-0.071,0.612,0.01c2.721,1.562,3.503,1.463,5.385-1.022c0.537-0.708,1.059-1.202,1.926-1.065
				c1.029,0.163,1.094,1.049,1.096,1.84c0.008,3.029,1.727,4.658,4.376,5.679c1.361,0.525,2.837,0.987,3.626,2.417
				c0.184,0.717-0.466,1.034-0.722,1.533c-1.218,2.373-0.89,3.492,1.437,4.839c2.001,1.158,2.032,1.58,0.116,2.976
				c-0.575,0.419-1.228,0.827-1.364,1.505c-0.317,1.577-1.186,2.616-2.536,3.444c-0.873,0.535-1.019,1.417-0.248,2.276
				C433.295,200.658,433.87,200.945,434.047,201.725z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(76)} fill={props.fillColors[76]} d="M378.857,637.216c1.641-0.213,2.276-0.918,1.531-2.227
				c-0.843-1.48-1.21-2.925-0.25-4.466c1.139-1.831,2.6-3.257,4.829-3.63c0.977-0.164,2.01-0.514,2.939-0.345
				c7.057,1.284,14.426,1.816,19.645,7.79c0.916,1.048,2.07,1.981,2.528,3.272c1.04,2.938,3.228,5.22,4.365,8.081
				c0.591,1.484,1.809,2.358,3.165,2.995c1.193,0.56,2.375-0.175,2.169-1.46c-0.34-2.114,0.354-4.331-0.838-6.378
				c-0.721-1.237-0.371-2.719-0.169-4.091c0.135-0.921,0.662-1.646,1.405-2.197c2.377-1.76,8.116-0.682,9.608,1.889
				c0.803,1.381,1.634,2.734,2.729,3.878c3.495,3.647,5.788,8.005,7.673,12.625c1.214,2.975,1.237,3.009,4.308,2.695
				c1.065-0.109,1.96,0.173,2.816,0.692c0.859,0.52,1.654,1.195,2.088,2.081c2.209,4.519,5.368,8.458,7.58,13.002
				c4.716,9.689,6.798,20.251,10.477,30.275c0.154,0.422,0.392,0.934-0.223,1.411c-1.22-0.295-1.05-1.538-1.312-2.347
				c-1.898-5.839-3.72-11.703-5.517-17.574c-0.998-3.263-2.279-6.332-5.047-8.537c-0.152-0.121-0.332-0.277-0.378-0.449
				c-1.035-3.831-4.418-6.314-5.724-9.995c-0.294-0.829-0.813-1.58-1.248-2.355c-0.885-1.58-1.97-2.445-4.049-2.188
				c-2.811,0.35-4.69-0.93-5.715-3.701c-1.279-3.459-3.128-6.622-4.736-9.91c-1.03-2.105-1.247-2.15-3.741-1.194
				c-0.343-1.323-0.693-2.636-1.023-3.953c-0.523-2.086-1.804-3.245-3.998-3.271c-0.673-0.008-1.323-0.513-2.074-0.05
				c-0.765,0.745-0.402,1.619-0.051,2.418c1.315,2.979,0.924,6.05,0.4,9.072c-0.546,3.151-3.381,4.509-6.332,3.142
				c-2.042-0.946-3.572-2.522-4.547-4.537c-1.337-2.761-3.384-5.117-4.426-8.057c-0.312-0.879-1.109-1.645-1.825-2.31
				c-1.959-1.819-3.974-3.579-6.007-5.316c-0.822-0.702-1.749-1.497-2.869-0.517c-0.817,0.716-0.629,2.02,0.335,3.195
				c0.683,0.833,2.084,1.27,1.62,3.102c-2.478-1.293-4.041-3.342-5.894-5.023c-0.896-0.814-1.256-2.547-2.909-2.03
				c-1.654,0.517-0.978,2.158-1.237,3.338c-0.021,0.095-0.073,0.184-0.168,0.408c-1.821-0.447-1.765-3.138-3.949-3.276
				c-1.664,1.003-2.117,2.701-1.916,4.541c0.345,3.152,0.508,6.301,0.599,9.47c0.068,2.36,1.192,4.533,2.108,6.757
				c0.697-0.172,1.256-0.31,1.866-0.46c0.096,0.292,0.265,0.558,0.254,0.815c-0.102,2.614,1.094,4.81,2.323,6.954
				c2.004,3.494,6.97,4.912,10.527,2.946c1.514-0.836,2.985-0.829,4.214,0.035c4.759,3.351,10.519,3.248,15.785,4.824
				c1.03,0.309,1.939,0.13,2.886-0.228c1.481-0.561,2.987-1.022,4.541-1.38c3.161-0.727,6.121-0.253,8.903,1.286
				c1.926,1.064,3.897,1.944,6.063,2.348c2.024,0.377,3.448,1.392,3.997,3.479c0.397,1.512,1.523,2.505,2.632,3.515
				c1.992,1.814,3.837,3.763,4.619,6.437c0.467,1.598,2.217,2.222,2.693,3.847c0.584,1.991,1.453,3.897,2.089,5.875
				c1.117,3.478,2.151,6.981,3.252,10.576c-1.522,0.602-2.874,0.31-4.257,0.252c-0.822-1.103-0.895-2.421-1.164-3.649
				c-0.574-2.615-1.273-5.188-2.119-7.731c-2.3-6.917-6.201-12.566-12.519-16.43c-1.428-0.873-2.746-1.964-3.993-3.089
				c-3.801-3.427-8.162-4.03-12.731-2.196c-2.767,1.109-5.271,0.986-8.079,0.336c-5.979-1.383-12.049-2.561-18.169-2.896
				c-6.671-0.365-11.249-3.246-14.721-8.938c-2.86-4.689-3.764-9.727-4.43-14.936C379.803,641.488,379.542,639.432,378.857,637.216z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(77)} fill={props.fillColors[77]} d="M365.628,208.708c-1.092-1.658-1.153-3.072,0.015-4.516
				c0.49-0.606,0.783-1.374,1.163-2.07c2.991-5.487,3.167-11.159,1.019-16.939c-0.748-2.009-1.181-3.744,0.227-5.825
				c1.175-1.737,1.18-4.118,2.414-5.993c0.611-0.927,0.284-2.17-0.456-3.066c-0.907-1.098-1.463-2.319-1.818-3.684
				c-0.26-0.994-0.89-1.828-1.875-2.178c-2.607-0.926-3.967-3.54-6.599-4.329c0.667-2.862,2.439-4.631,3.914-6.753
				c1.275,0.325,2.445,0.289,3.625-0.504c1.213-0.815,2.444,0.007,3.859,0.481c-0.825,2.025-0.192,4.07-0.676,6.086
				c-0.37,1.542,0.412,2.894,1.633,3.922c1.219,1.027,2.474,1.124,3.789,0.167c0.421-0.307,0.928-0.368,1.419-0.213
				c1.285,0.406,1.948-0.285,2.697-1.225c1.143-1.433,1.921-1.569,2.841-0.35c1.409,1.865,2.91,1.234,4.474,0.553
				c2.414-1.052,2.633-0.99,3.573,1.445c0.8,2.068,2.587,2.587,4.853,1.485c0.936-0.455,1.554-1.643,3.036-1.24
				c0.664,1.378,0.996,3.056,2.144,4.271c-3.473,3.612-4.037,3.729-1.568,8.148c-0.993,1.35-3.422,0.599-3.901,2.327
				c-0.284,1.339,1.083,1.581,1.119,2.7c-1.277,0.882-3.106,0.963-3.998,2.355c-2.893-0.745-5.774,0.204-6.868,2.153
				c-0.8,1.424,0.278,3.731,2.77,5.721c-1.438,0.713-2.66,1.844-4.28,0.469c-0.512-0.434-1.136-0.392-1.618,0.072
				c-0.52,0.499-0.862,1.115-0.492,1.862c0.219,0.442,0.566,0.828,0.739,1.284c0.383,1.007-0.249,2.033-1.206,1.719
				c-2.506-0.822-3.988,0.447-5.28,2.212c-1.033,1.412-2.336,1.706-3.924,1.262c-0.934-0.261-1.984-0.855-2.744,0.132
				c-0.847,1.101-0.76,2.434-0.287,3.69c1.436,3.82,1.36,3.96-2.721,4.365C366.347,208.733,366.052,208.708,365.628,208.708z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(78)} fill={props.fillColors[78]} d="M87.449,423.316c0.728,3.366,0.163,5.748-0.823,8.047
				c-0.549,1.279-0.508,2.426,0.093,3.688c0.889,1.868,1.619,3.811,2.474,5.855c-9.152,13.236-18.305,26.473-27.572,39.875
				c-0.765-1.045-0.501-1.854-0.502-2.592C61.09,465,61.1,451.81,61.074,438.619c-0.004-1.96-0.094-3.71,2.579-4.185
				c1.365-0.243,2.266-1.672,2.41-3.131c0.136-1.385,0.776-2.202,2.003-2.635c1.372-0.483,2.422-1.32,3.118-2.599
				c0.728-1.338,2.323-1.44,2.859-2.02C78.83,423.427,83.065,426.242,87.449,423.316z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(79)} fill={props.fillColors[79]} d="M396.176,347.335c-2.902,1.016-4.983,2.943-7.589,3.885
				c-0.18,0.065-0.498-0.258-0.725-0.385c-0.042-0.227-0.165-0.463-0.094-0.575c2.167-3.391,2.133-3.731-0.4-6.694
				c-1.094-1.279-2.138-2.604-3.134-3.961c-0.462-0.63-0.989-1.357-1.075-2.088c-0.187-1.595-0.284-3.062-1.911-4.027
				c-0.79-0.469-0.283-1.801-0.798-2.466c-2.271-2.934-2.795-6.188-2.248-9.766c0.15-0.982-0.541-1.873-0.995-2.756
				c-0.445-0.867-1.13-1.634-1.201-2.834c1.081-1.221,1.978-0.506,2.899,0.344c1.05,0.969,1.8,2.349,3.196,2.685
				c0.938-0.473,0.863-1.078,0.605-1.633c-2.217-4.773-4.379-9.589-9.223-12.42c-1.354-0.792-2.261-1.955-2.404-3.557
				c-0.254-2.843-1.798-4.747-4.239-6.037c-0.087-0.046-0.174-0.095-0.255-0.151c-0.873-0.602-2.171-0.963-2.05-2.284
				c0.133-1.458,1.559-1.233,2.543-1.558c1.943-0.64,2.461-1.771,1.089-3.288c-2.496-2.761-3.325-6.03-3.551-9.549
				c-0.078-1.216-0.797-2.172-1.2-3.382c1.306-0.257,1.88,1.408,3.213,0.761c0.53-2.02-0.193-3.881-1.549-5.319
				c-1.677-1.78-2.097-3.678-1.703-5.988c0.268-1.577-0.053-3.153-0.558-4.684c-0.434-1.314-0.911-2.634-1.148-3.99
				c-0.323-1.843-1.487-2.954-2.891-3.801c-0.437-1.117,0.895-1.874,0.33-2.914c-0.498-0.917-1.48-0.895-2.238-1.279
				c-0.14-0.47,0.17-0.706,0.436-0.984c1.019-1.066,0.562-2.074-0.612-2.142c-2.19-0.127-2.546-1.347-2.647-3.124
				c-0.108-1.907-1.322-3.298-2.655-4.502c-1.229-1.108-1.582-2.325-1.396-3.965c0.503-4.443,0.534-8.902-0.149-13.341
				c-0.184-1.188-0.221-2.379-0.02-3.555c0.63-3.692,0.876-7.435,1.956-11.071c0.58-1.954,0.432-4.123,0.634-6.192
				c0.053-0.544-0.145-1.198,0.908-1.722c0.606,3.141,1.754,6.084,0.599,9.24c-0.66,1.805-0.905,3.639-0.814,5.544
				c0.15,3.18-0.064,6.351-0.521,9.491c-0.718,4.935,0.4,9.664,1.469,14.399c0.333,1.473,1.254,2.691,2.206,3.839
				c1.075,1.297,2.118,2.641,3.333,3.797c3.828,3.645,5.727,8.218,6.722,13.288c0.496,2.524,1.093,5.032,1.729,7.525
				c0.568,2.222,0.897,4.447,0.906,6.75c0.024,6.088,1.585,11.761,4.951,16.873c2.955,4.487,5.515,9.132,7.071,14.333
				c0.731,2.444,2.018,4.732,3.143,7.047c1.69,3.478,3.396,6.933,4.316,10.729c0.422,1.735,1.31,3.317,2.29,4.815
				c3.167,4.842,4.277,10.414,5.557,15.904C396.777,342.721,395.468,344.882,396.176,347.335z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(80)} fill={props.fillColors[80]} d="M48.384,528.21c-1.268-0.38-1.323-1.288-1.648-1.939
				c-8.786-17.546-17.536-35.109-26.331-52.65c-0.667-1.331-0.858-2.411-0.13-3.86c9.033-17.975,17.989-35.987,26.972-53.986
				c0.124-0.248,0.336-0.451,0.502-0.671c0.877,0.32,0.518,1.018,0.524,1.526c0.041,3.471-0.026,6.943,0.067,10.412
				c0.047,1.756-0.406,3.288-1.216,4.817c-3.848,7.271-7.583,14.602-11.505,21.832c-0.978,1.802-0.979,3.224,0.035,4.947
				c1.046,1.778,2.659,3.615,2.551,5.578c-0.101,1.821-1.44,3.645-2.466,5.325c-1.101,1.802-1.183,3.355-0.064,5.212
				c1.059,1.76,2.674,3.599,2.524,5.577c-0.139,1.832-1.459,3.643-2.49,5.321c-1.113,1.814-1.017,3.333-0.014,5.214
				c3.87,7.257,7.535,14.624,11.35,21.911c0.947,1.809,1.441,3.613,1.368,5.67C48.301,521.656,48.384,524.874,48.384,528.21z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(81)} fill={props.fillColors[81]} d="M276.044,545.515c-1.67,4.608-3.268,9.021-4.866,13.433
				c-0.858-1.261-0.897-2.421-0.447-3.628c0.239-0.643,0.446-1.32,0.508-1.997c0.063-0.687-0.05-1.421-0.701-1.856
				c-0.785-0.526-1.556-0.252-2.186,0.28c-4.272,3.61-9.63,3.964-14.774,4.761c-2.173,0.337-3.989,0.984-5.6,2.39
				c-1.051,0.916-2.155,1.759-3.489,2.227c-2.895,1.014-5.154,2.882-7.151,5.155c-1.044,1.188-2.161,2.332-3.357,3.367
				c-3.298,2.851-5.02,6.852-7.582,10.236c-0.566,0.748-0.727,1.622-0.125,2.49c0.711,1.024,1.727,1.353,2.87,1.081
				c3.969-0.942,8.164-1.883,11.232-4.466c3.229-2.72,6.641-3.08,10.234-2.478c3.614,0.606,6.85-0.001,10.093-1.36
				c0.244-0.103,0.543-0.285,0.925,0.244c-2.188,1.994-4.913,3.197-7.396,4.754c-0.586,0.367-1.229,0.644-1.827,0.993
				c-3.023,1.766-6.129,2.877-9.784,2.504c-4.539-0.463-8.762,1.245-12.989,2.629c-1.716,0.562-3.186,1.595-4.531,2.788
				c-1.586,1.408-3.362,2.396-5.462,2.863c-2.773,0.616-5.145,2.003-7.157,4.027c-0.766,0.77-1.601,1.489-2.486,2.118
				c-2.096,1.488-3.631,3.344-4.247,5.891c-0.288,1.192-0.932,2.161-1.911,2.929c-2.301,1.803-4.111,4.094-5.44,6.625
				c-1.709,3.254-4.471,3.89-7.673,3.841c-0.883-0.013-1.763-0.236-2.643-0.375c-1.774-0.279-3.53,0.122-5.419,0.311
				c-0.472-1.565,0.412-2.609,1.017-3.642c1.711-2.919,3.885-5.505,6.123-8.028c3.357-3.787,6.91-7.382,10.678-10.765
				c2.285-2.052,4.581-4.086,6.541-6.482c1.543-1.889,3.73-2.916,5.972-3.759c9.33-3.505,8.251-2.446,13.383-11.102
				c3-5.06,7.332-8.804,11.202-13.021c0.397-0.434,0.873-0.846,1.393-1.105c3.188-1.59,6.341-3.219,9.119-5.5
				c0.495-0.406,1.293-0.627,1.948-0.621c3.12,0.028,6.059-0.993,9.065-1.55c3.048-0.564,5.889-1.823,7.386-4.91
				c0.426-0.878,1.093-1.324,2.125-1.306C270.976,545.543,273.338,545.515,276.044,545.515z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(82)} fill={props.fillColors[82]} d="M47.744,295.991c0.633,0.636,0.359,1.459,0.37,2.188
				c0.046,3.172-0.027,6.347,0.072,9.517c0.058,1.856-0.427,3.47-1.293,5.093c-3.826,7.167-7.533,14.397-11.386,21.549
				c-0.852,1.581-0.929,2.89,0.01,4.406c0.832,1.344,1.407,2.847,2.236,4.193c0.723,1.172,0.627,2.167-0.048,3.304
				c-0.809,1.36-1.387,2.86-2.229,4.196c-0.971,1.54-0.808,2.884,0.051,4.417c1.057,1.887,2.809,3.773,2.669,5.838
				c-0.13,1.916-1.524,3.826-2.621,5.575c-1.115,1.776-0.801,3.225,0.111,4.952c3.839,7.271,7.535,14.618,11.342,21.906
				c0.851,1.628,1.31,3.253,1.26,5.106c-0.091,3.35-0.026,6.703-0.026,10.092c-1.151,0.115-1.123-0.838-1.376-1.345
				c-8.832-17.625-17.625-35.27-26.442-52.901c-0.641-1.282-0.972-2.346-0.214-3.852c8.909-17.696,17.718-35.443,26.564-53.171
				C46.994,296.651,47.096,296.111,47.744,295.991z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(83)} fill={props.fillColors[83]} d="M332.772,151.174c-2.56,3.184-2.541,3.377,0.581,6.091
				c0.642,0.558,0.642,0.558,1.25,2.177c-3.213,2.442-3.775,3.446-1.33,7.27c-1.88,0.165-3.615,0.525-5.321,0.425
				c-4.387-0.259-6.169,0.879-7.322,5.039c-0.614,2.214-0.836,2.348-2.643,1.218c-2.179-1.363-4.405-1.638-6.766-0.56
				c-0.719,0.329-1.43,0.678-2.164,0.97c-1.086,0.431-2.536,0.505-3.029-0.38c-0.931-1.672-2.663-2.971-2.741-5.062
				c-0.081-2.146-1.393-3.241-3.219-3.708c-1.893-0.484-3.592-0.976-4.237-3.138c-0.231-0.774-1.033-1.08-1.823-1.367
				c-1.392-0.505-1.476-2.014-1.977-3.137c-0.3-0.673,0.363-1.258,0.997-1.664c1.065-0.166,1.811,0.642,2.725,0.952
				c1.516,0.514,2.463,0.232,3.321-1.024c0.649-0.952,0.802-1.949-0.328-2.533c-1.637-0.847-1.792-2.548-2.465-3.918
				c-0.621-1.26,0.074-2.637,1.499-2.96c2.103-0.478,3.919-1.09,4.988-3.247c0.49-0.989,1.85-1.28,2.912-0.897
				c1.653,0.597,3.449,1.072,4.625,2.526c1.021,1.263,2.081,2.006,3.822,1.381c1.733-0.622,1.672-1.991,1.727-3.385
				c0.007-0.19,0.101-0.376,0.176-0.639c2.277-1.878,4.81-0.993,7.305-0.537c0.695,0.127,0.905,0.722,1.116,1.268
				c0.177,0.458,0.105,1.133,0.414,1.401c1.881,1.634,3.013,3.654,3.707,6.034c0.26,0.891,1.264,1.143,2.263,0.985
				C331.435,150.659,332.139,149.962,332.772,151.174z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(84)} fill={props.fillColors[84]} d="M47.985,176.476c0,4.713,0.029,9.427-0.023,14.14c-0.011,1.004-0.721,1.827-1.178,2.7
				c-3.726,7.108-7.409,14.239-11.228,21.296c-0.912,1.686-0.924,3.03,0.014,4.686c1.059,1.87,2.827,3.744,2.612,5.837
				c-0.198,1.934-1.605,3.788-2.648,5.579c-0.888,1.523-0.985,2.852-0.072,4.418c1.092,1.875,2.596,3.839,2.736,5.847
				c0.142,2.031-1.587,3.974-2.695,5.832c-1.004,1.685-0.747,3.053,0.114,4.688c3.788,7.186,7.432,14.448,11.209,21.64
				c0.907,1.726,1.351,3.446,1.299,5.39c-0.085,3.244-0.023,6.492-0.023,9.791c-1.161-0.102-1.199-0.922-1.478-1.479
				c-8.731-17.452-17.443-34.915-26.179-52.365c-0.643-1.284-0.974-2.345-0.216-3.849c8.952-17.785,17.807-35.618,26.693-53.437
				c0.131-0.264,0.326-0.495,0.492-0.742C47.604,176.456,47.794,176.466,47.985,176.476z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(85)} fill={props.fillColors[85]} d="M636.577,296.021c3.145,6.283,6.297,12.562,9.432,18.85
				c5.613,11.255,11.159,22.545,16.867,33.752c1.203,2.362,1.227,4.282,0.021,6.667c-8.491,16.791-16.849,33.649-25.256,50.482
				c-0.382,0.765-0.829,1.496-1.827,2.163c0-2.405,0.158-4.823-0.036-7.213c-0.31-3.8,0.696-7.13,2.523-10.453
				c3.53-6.42,6.729-13.021,10.147-19.503c0.765-1.451,0.929-2.659,0.047-4.127c-1.119-1.861-2.014-3.857-3.109-6
				c1.024-2.021,2.021-4.163,3.182-6.212c0.709-1.253,0.746-2.288,0.059-3.59c-1.104-2.088-3.055-4.132-2.918-6.369
				c0.127-2.091,1.72-4.154,2.879-6.103c0.845-1.418,0.58-2.53-0.125-3.855c-3.814-7.169-7.53-14.392-11.366-21.55
				c-0.876-1.633-1.282-3.251-1.245-5.092c0.078-3.92,0.023-7.843,0.023-11.765C636.108,296.076,636.342,296.048,636.577,296.021z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(86)} fill={props.fillColors[86]} d="M636.531,415.51c3.229,6.474,6.458,12.941,9.684,19.409
				c5.524,11.082,10.983,22.197,16.623,33.22c1.271,2.484,1.2,4.5-0.04,6.954c-8.488,16.798-16.854,33.659-25.264,50.496
				c-0.347,0.694-0.749,1.361-1.146,2.078c-0.968-0.422-0.611-1.214-0.625-1.813c-0.238-10.395-0.24-10.398,4.543-19.748
				c2.661-5.204,5.305-10.417,8.059-15.572c0.82-1.534,0.995-2.812,0.098-4.399c-1.108-1.961-2.952-3.928-2.749-6.103
				c0.188-2.02,1.675-3.972,2.786-5.836c0.882-1.479,0.752-2.712-0.065-4.13c-1.085-1.884-2.014-3.857-3.066-5.903
				c0.901-1.761,1.672-3.598,2.73-5.25c1.206-1.881,1.171-3.449,0.07-5.452c-3.864-7.03-7.483-14.195-11.264-21.273
				c-0.774-1.45-1.169-2.885-1.135-4.526c0.074-3.468,0.013-6.938,0.062-10.408C635.839,416.674,635.41,415.839,636.531,415.51z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(87)} fill={props.fillColors[87]} d="M645.362,241.017c1.078-2.099,2.029-4.175,3.188-6.127
				c0.817-1.376,0.739-2.513-0.036-3.859c-1.134-1.968-2.092-4.038-3.143-6.102c0.989-1.913,1.821-3.836,2.933-5.582
				c1.108-1.745,0.87-3.189-0.066-4.924c-3.813-7.065-7.476-14.21-11.251-21.296c-0.722-1.355-1.102-2.696-1.072-4.238
				c0.069-3.668,0.018-7.339,0.041-11.009c0.003-0.44-0.213-0.974,0.418-1.394c0.789,0.629,1.044,1.605,1.467,2.451
				c8.368,16.759,16.684,33.544,25.129,50.264c1.151,2.281,1.143,4.081-0.014,6.366c-8.415,16.625-16.692,33.319-25.021,49.988
				c-0.468,0.935-0.999,1.837-1.955,2.638c0-4.203-0.046-8.406,0.044-12.607c0.016-0.726,0.579-1.454,0.939-2.155
				c3.758-7.318,7.469-14.662,11.334-21.922c0.95-1.785,1.016-3.229-0.028-4.937C647.189,244.804,646.334,242.899,645.362,241.017z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(88)} fill={props.fillColors[88]} d="M519.176,703.096c-1.652,0-3.021,0-4.496,0c-0.811-1.389-0.684-2.895-0.633-4.336
				c0.137-3.865-0.736-7.46-2.543-10.864c-0.602-1.135-1.125-2.32-1.579-3.522c-1.146-3.036-1.181-5.992,0.754-8.789
				c0.504-0.729,0.921-1.53,1.298-2.335c0.823-1.762,0.722-3.522-0.468-5.054c-1.347-1.733-2.429-3.609-3.467-5.526
				c-0.67-1.236-1.617-2.239-2.775-3.053c-1.508-1.06-2.061-2.56-2.04-4.373c0.06-5.379,3.258-8.979,6.996-12.2
				c1.295-1.115,3.013-1.011,4.495-0.176c5.419,3.055,8.66,7.724,9.775,13.804c0.363,1.98-0.124,3.955-1.122,5.736
				c-1.925,3.432-1.1,6.499,1.257,9.319c2.186,2.615,4.902,4.604,7.831,6.313c0.342,0.199,0.71,0.354,1.047,0.562
				c2.282,1.4,2.516,2.797,0.426,4.338c-2.647,1.953-4.818,4.418-7.382,6.451c-1.003,0.796-1.862,1.776-2.772,2.686
				c-2.598,2.597-4.613,5.449-4.238,9.4C519.583,701.937,519.335,702.424,519.176,703.096z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(89)} fill={props.fillColors[89]} d="M394.152,95.226c-0.123-0.358-0.39-0.705-0.02-1.083
				c1.109-1.133,1.795-2.378,0.706-3.809c1.146-0.794,1.762-0.213,2.399,0.24c0.876,0.623,1.726,1.544,2.855,0.5
				c0.924-0.854,1.059-2.569,0.199-3.795c-0.823-1.174-1.33-2.384-1.329-3.926c1.096-0.775,2.613-0.705,3.472-1.994
				c0.921-1.383-0.04-2.687,0.134-3.965c0.896-0.633,1.813-0.34,2.646-0.218c2.551,0.376,4.633,0.05,5.132-3.559
				c0.767,1.182,0.988,2.062,1.786,2.453c0.344,0.168,0.855,0.391,0.92,0.674c0.853,3.746,4.024,5.557,6.51,7.947
				c0.922,0.886,0.743,2.214,1.149,3.312c0.786,2.122,0.95,4.172-0.29,6.244c-0.541,0.903-0.733,2.016-1.095,3.065
				c1.45,1.584,3.267,1.287,5.193,1.37c0.011,2.46,1.564,4.443,2.602,6.672c-0.268,0.398-0.478,0.718-0.693,1.033
				c-1.521,2.225-1.576,3.621,0.168,5.582c1.024,1.151,1.342,2.031,0.297,3.331c-1.046,1.303-1.265,2.867-0.78,4.499
				c0.541,1.821-0.084,3.599-1.332,4.612c-2.252,1.825-2.879,4.455-3.966,6.815c-0.537,1.166-0.824,2.29-1.913,3.156
				c-1.354,1.076-2.249,2.491-1.884,4.401c0.064,0.336-0.164,0.728-0.268,1.133c-2.157-1.49-2.531-2.333-1.734-4.547
				c0.435-1.207,1.198-2.289,0.36-3.615c-0.312-0.494,0.056-0.926,0.345-1.334c1.16-1.638,1.345-3.282-0.07-4.869
				c-1.734-1.944-2.751-2.11-5.526-0.998c-0.178,0.072-0.375,0.099-0.665,0.173c-0.999-0.867-1.795-1.689-1.645-3.368
				c0.137-1.517-2.168-3.004-4.498-3.059c-1.57-0.037-2.956-0.187-3.923-1.737c0.722-1.502,1.769-2.374,3.675-1.965
				c3.103,0.666,4.293-0.402,4.216-3.632c-0.007-0.287-0.001-0.574-0.001-0.849c0.724-0.643,1.531-0.532,2.257-0.219
				c1.368,0.589,2.29-0.014,3.101-0.995c1-1.209,0.917-1.818-0.363-2.931c-0.294-0.256-0.623-0.471-0.988-0.744
				c-0.229-1.252,0.474-2.519-0.123-3.784c-0.409-0.867,0.098-1.743,0.545-2.523c0.523-0.913,0.849-1.865,0.117-2.766
				c-0.857-1.055-2.037-1.789-3.39-1.318c-1.431,0.498-2.619,0.295-3.96-0.278c-2.361-1.009-4.49-0.156-5.73,2.086
				c-0.219,0.396-0.133,1.009-0.787,1.089c-1.209-3.14-1.209-3.14-3.815-2.508C394.15,95.219,394.15,95.226,394.152,95.226z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(90)} fill={props.fillColors[90]} d="M161.055,537.101c0.882-6.091,2.738-11.33,5.373-16.193
				c3.982-7.35,6.924-15.13,10.146-22.801c1.459-3.473,2.959-6.927,4.729-10.257c2.872-5.402,7.104-9.214,12.974-11.126
				c1.518-0.494,3.056-1.09,4.165-2.231c2.374-2.444,5.371-3.215,8.569-3.642c1.169-0.155,2.314-0.508,3.731-0.308
				c-1.201,3.292-2.841,6.154-5.154,8.635c-2.984,3.201-6.247,6.109-9.676,8.803c-5.844,4.591-7.054,11.017-7.087,17.802
				c-0.01,1.942-0.271,3.419-1.546,4.84c-2.784,3.102-5.442,6.308-7.549,9.93c-1.55,2.665-2.938,5.422-3.818,8.367
				c-0.596,1.994-1.833,3.177-3.567,4.104C168.91,534.862,165.193,535.858,161.055,537.101z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(91)} fill={props.fillColors[91]} d="M47.609,168.708c-0.778-0.53-0.982-1.43-1.352-2.17
				c-8.637-17.266-17.234-34.552-25.894-51.806c-0.783-1.56-1.153-3.086-1.14-4.82c0.056-7.029,0.023-14.06,0.023-21.044
				c2.227-0.675,15.565-0.784,19.18-0.14c-1.025,1.985-1.949,3.927-3.016,5.788c-0.76,1.327-0.875,2.481-0.081,3.873
				c1.123,1.969,2.669,4.007,2.85,6.117c0.183,2.142-1.71,4.11-2.801,6.086c-0.828,1.499-0.711,2.718,0.094,4.15
				c1.062,1.89,2.699,3.755,2.74,5.844c0.042,2.096-1.622,3.971-2.72,5.827c-1.001,1.693-0.735,3.052,0.141,4.677
				c3.713,6.885,7.291,13.843,10.978,20.742c0.956,1.788,1.537,3.566,1.447,5.641c-0.132,3.066-0.012,6.142-0.044,9.213
				C48.008,167.327,48.28,168.051,47.609,168.708z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(92)} fill={props.fillColors[92]} d="M352.487,648.725c-2.029-0.161-3.348-0.841-4.569-1.63
				c-4.746-3.066-8.73-6.803-10.828-12.253c-1.208-3.141-2.614-6.207-3.96-9.294c-0.942-2.159-0.755-3.383,0.861-5.057
				c3.483-3.607,4.054-7.352,1.397-11.49c-2.298-3.579-3.918-7.584-6.796-10.801c-0.527-0.589-1.087-1.407-0.84-2.15
				c0.823-2.478,0.981-5.315,2.531-7.38c2.278-3.032,3.276-6.242,3.147-9.947c-0.068-1.938-0.508-3.904,0.064-6.052
				c1.729,0.362,2.442,1.504,2.926,2.786c0.381,1.013,0.55,2.087,0.604,3.194c0.268,5.48,4.776,10.355,10.159,11.062
				c2.068,0.271,2.572,0.622,2.398,2.582c-0.394,4.447,1.637,7.846,4.417,10.945c1.083,1.207,2.078,2.382,2.149,4.103
				c0.057,1.353,0.962,2.073,2.053,2.691c3.397,1.925,5.495,4.758,5.971,8.706c0.315,2.623,1.309,4.941,3.025,6.987
				c0.755,0.899,1.342,1.971,1.851,3.037c0.557,1.165,0.951,2.395,0.261,3.702c-0.598,1.132-0.436,2.194,0.778,2.762
				c2.874,1.341,3.815,3.679,4.084,6.687c0.326,3.644,1.06,7.249,1.571,10.878c0.253,1.794,0.832,3.453,1.694,5.037
				c2.289,4.203,2.755,8.855,3,13.487c0.127,2.398,0.203,4.768,1.086,6.995c1.894,4.78,3.554,9.707,7.683,13.208
				c1.085,0.92,1.69,2.219,1.998,3.587c0.564,2.511,0.913,5.052,0.54,7.723c-1.046,0.614-2.086,0.287-3.105,0.263
				c-0.599-1.178-0.27-2.288-0.381-3.348c-0.249-2.394-0.898-4.471-2.602-6.43c-5.608-6.452-8.938-13.848-8.961-22.645
				c-0.009-3.516-0.648-7.11-2.249-10.362c-0.791-1.607-1.6-3.184-1.922-4.962c-0.338-1.872-1.518-3.137-2.917-4.331
				c-2.34-1.998-2.392-2.795-0.704-5.264c0.39-0.571,0.829-1.124,1.119-1.744c1.033-2.205,0.64-3.64-1.329-5.12
				c-0.396-0.298-0.797-0.591-1.198-0.881c-1.59-1.152-2.436-2.556-1.733-4.602c0.476-1.385,0.496-2.731-0.802-3.691
				c-1.06-0.323-1.538,0.899-2.449,0.874c-0.61-3.297-0.66-3.32-4.412-2.124c-0.84-0.352-0.849-1.074-0.622-1.746
				c0.504-1.493,0.914-3.011,1.809-4.372c1.975-3.006,1.175-6.679-2.044-8.354c-2.364-1.23-4.162-2.576-4.363-5.454
				c-0.078-1.131-0.98-1.901-1.748-2.689c-1.818-1.865-3.219-4.022-4.23-6.415c-0.343-0.813-0.812-1.752-0.688-2.551
				c0.396-2.57-0.917-3.374-3.109-4.087c-2.157-0.701-3.958-2.23-5.083-4.354c-0.277-0.523-0.562-1.067-0.958-1.498
				c-0.74-0.806-1.574-0.834-2.23,0.099c-1.708,2.428-2.868,5.141-3.972,7.876c-0.31,0.768,0.155,1.333,0.606,1.866
				c2.517,2.978,4.189,6.505,6.089,9.844c2.531,4.447,2.86,9.403,1.773,14.429c-0.479,2.216-1.153,4.411-0.737,6.741
				c0.582,3.257,2.162,5.946,4.58,8.14C347.046,639.456,349.676,643.855,352.487,648.725z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(93)} fill={props.fillColors[93]} d="M635.959,168.452c0-4.351,0.017-8.702-0.012-13.053
				c-0.008-1.17,0.608-2.067,1.109-3.023c3.725-7.105,7.398-14.237,11.221-21.289c0.958-1.767,1.037-3.195,0-4.919
				c-1.062-1.767-2.627-3.573-2.606-5.563c0.02-1.988,1.54-3.811,2.618-5.572c1.059-1.729,1.017-3.208-0.013-4.928
				c-1.06-1.77-2.721-3.609-2.582-5.552c0.138-1.919,1.523-3.818,2.597-5.583c1.055-1.735,1.022-3.223-0.027-4.928
				c-1.022-1.661-1.807-3.469-2.721-5.26c2.486-0.542,15.071-0.653,18.441-0.208c0.619,0.657,0.4,1.489,0.401,2.252
				c0.011,6.739-0.019,13.477,0.004,20.216c0.004,1.216-0.179,2.336-0.729,3.435c-8.86,17.711-17.704,35.431-26.559,53.146
				c-0.167,0.334-0.423,0.625-0.637,0.935C636.297,168.523,636.128,168.487,635.959,168.452z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(94)} fill={props.fillColors[94]} d="M243.582,624.179c1.639,3.919,4.52,5.5,8.172,6.026
				c1.529,0.221,2.974,0.029,4.392-0.429c4.794-1.55,8.676-4.31,11.235-8.733c0.754-1.305,1.739-2.238,3.003-3.139
				c4.298-3.062,6.507-7.433,6.594-12.691c0.039-2.371,0.445-4.466,1.772-6.443c0.595-0.887,0.865-1.991,1.288-2.994
				c0.975-2.311,2.171-4.446,4.321-5.911c1.271-0.866,2.027-2.157,2.585-3.56c1.134-2.852,2.368-5.665,3.151-8.647
				c0.794-3.021,2.237-5.724,4.314-8.053c2.062-2.312,3.739-4.863,5.118-7.629c0.793-1.592,1.713-3.096,3.358-4.092
				c0.505,0.839,0.18,1.587,0.08,2.319c-0.242,1.767-0.671,3.525-0.726,5.296c-0.11,3.526-1.492,6.181-3.909,8.868
				c-3.494,3.886-6.061,8.515-7.115,13.828c-0.524,2.642-1.877,5.005-3.302,7.275c-3.964,6.316-5.731,13.278-5.537,20.634
				c0.118,4.459-0.614,8.342-3.729,11.702c-1.264,1.364-2.146,3.087-3.177,4.662c-1.134,1.734-2.464,3.291-4.063,4.897
				c-0.514-0.992-0.148-1.724-0.067-2.445c0.224-1.991,1.137-3.982-0.672-5.846c-0.687-0.707,0.155-2.15-0.745-3.229
				c-1.918,0.049-3.07,1.2-3.986,2.852c-2.359,4.256-5.88,7.107-10.489,8.729c-2.82,0.992-5.476,0.646-8.079-0.507
				c-1.8-0.798-3.135-2.08-3.253-4.245c-0.049-0.9-0.188-1.784-1.16-2.145c-1.161,0.214-1.405,1.085-1.629,1.954
				c-0.976,3.778-0.053,9.846,4.386,12.305c1.726,0.957,3.333,2.195,4.421,3.907c1.207,1.898,0.48,3.228-1.774,3.386
				c-5.674,0.397-9.563,3.504-11.177,8.941c-0.163,0.548-0.174,1.155-0.672,1.661c-1.061-0.53-1.053-1.38-0.962-2.259
				c0.23-2.222,1.061-4.238,2.311-6.05c1.553-2.251,2.339-4.558,1.268-7.26c-0.207-0.522-0.256-1.214-0.107-1.752
				c0.895-3.216,0.48-6.503,0.663-9.758C239.908,631.613,241.329,627.994,243.582,624.179z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(95)} fill={props.fillColors[95]} d="M252.021,54.877c1.555-2.296,3.278-4.023,5.68-5.269
				c2.202-1.142,4.235-1.114,6.216-0.118c1.476,0.742,2.977,1.025,4.532,1.269c2.793,0.437,4.452-1.643,3.421-4.295
				c-0.173-0.445-0.512-0.825-0.912-1.451c1.527-0.134,2.718-0.448,4.149,0.544c1.761,1.221,3.384,0.477,4.324-1.483
				c0.252-0.526,0.442-1.08,0.63-1.543c1.086-0.402,2.063-0.432,3.037-0.403c0.713,0.676,0.606,1.535,0.3,2.223
				c-1.176,2.643-1.512,5.495-2.221,8.252c-0.072,0.281-0.11,0.668-0.306,0.807c-1.879,1.336-1.641,3.259-1.383,5.124
				c0.275,1.991,1.471,3.416,3.159,4.455c0.248,0.152,0.438,0.396,0.71,0.649c-0.111,1.971,0.509,4.011-1.167,5.836
				c-1.079,1.174-0.229,2.477,0.859,3.314c0.938,0.722,1.692,1.377,1.055,2.695c-0.704,0.453-1.293,0.083-1.851-0.318
				c-0.234-0.168-0.402-0.433-0.584-0.666c-1.256-1.598-3.153-1.383-4.855-1.798c-1.758-0.428-2.201-2.807-4.249-3.022
				c-1.403-0.147-2.765-0.098-4.136,0.149c-0.719,0.129-1.48,0.457-1.635,1.132c-0.442,1.94-1.731,2.731-3.527,3.055
				c-1.214,0.219-1.513,1.483-2.449,2.312c-1.555-0.795-3.422-0.662-4.953-2.1c-1.344-1.262-3.323-0.582-4.439,1.079
				c-0.466,0.694-0.957,1.197-1.803,1.396c-1.342,0.314-2.592,0.913-3.966,1.188c-2.589,0.52-2.896,0.131-3.152-2.522
				c-0.328-3.413,1.114-5.904,2.974-8.579c1.659-2.385,3.754-4.302,5.598-6.469C252.578,58.558,253.455,56.948,252.021,54.877z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(96)} fill={props.fillColors[96]} d="M38.381,613.777c-6.455,0-12.388,0-18.225,0c-0.796-0.714-0.596-1.553-0.599-2.316
				c-0.025-6.248-0.018-12.496-0.043-18.744c-0.004-1.106,0.154-2.115,0.667-3.141c9.05-18.078,18.064-36.174,27.083-54.268
				c0.175-0.353,0.317-0.72,1.125-1.035c0,3.027-0.133,5.928,0.038,8.811c0.17,2.882-0.553,5.387-1.937,7.91
				c-3.716,6.778-7.175,13.696-10.872,20.485c-0.973,1.787-1,3.22,0.033,4.948c1.058,1.771,2.69,3.603,2.571,5.571
				c-0.11,1.823-1.453,3.642-2.486,5.318c-1.109,1.803-1.186,3.361-0.063,5.212c1.066,1.76,2.688,3.598,2.54,5.577
				c-0.137,1.83-1.463,3.643-2.505,5.313c-1.133,1.818-1.151,3.404,0.001,5.206C36.656,610.107,37.346,611.753,38.381,613.777z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(97)} fill={props.fillColors[97]} d="M196.681,473.758c2.056-3.201,4.109-6.128,6.521-8.758
				c1.767-1.927,3.208-4.025,4.356-6.347c2.644-5.342,5.551-10.504,9.304-15.182c2.233-2.782,3.987-5.921,5.713-9.045
				c0.882-1.599,2.014-2.902,3.446-4.022c2.108-1.648,4.128-3.411,6.209-5.095c6.03-4.879,10.383-11.146,14.553-17.561
				c1.388-2.136,1.983-4.537,1.9-7.076c-0.091-2.772,0.748-5.258,2.084-7.64c1.358-2.419,2.66-4.87,3.954-7.323
				c1.91-3.619,4.047-7.045,7.373-9.585c1.361-1.039,2.506-2.392,2.91-4.049c0.718-2.947,2.625-5.062,4.519-7.228
				c0.979-1.119,1.943-2.251,2.89-3.397c0.382-0.462,0.625-0.993,0.308-1.729c-1.304-0.434-2.232,0.325-3.17,1.033
				c-2.067,1.559-3.866,3.412-5.667,5.255c-2.621,2.684-5.276,5.329-8.551,8.184c0.626-1.766,1.559-2.615,2.431-3.479
				c4.103-4.068,7.565-8.65,10.781-13.425c0.909-1.349,2.081-2.41,3.332-3.364c4.353-3.32,7.814-7.612,12.107-10.995
				c1.029-0.811,1.588-2.01,2.247-3.12c1.397-2.354,3.015-4.529,5.176-6.237c-3.662,6.299-6.831,12.921-12.232,18.013
				c-0.286,0.27-0.626,0.569-0.743,0.92c-1.191,3.595-3.593,6.565-5.112,9.976c-0.766,1.721-1.467,3.462-2.439,5.077
				c-1.33,2.207-2.578,4.434-3.521,6.86c-0.896,2.307-2.376,4.337-4.038,6.205c-2.549,2.865-4.544,5.96-4.668,10.008
				c-0.061,1.985-0.805,3.894-1.689,5.668c-2.559,5.135-4.949,10.384-9.344,14.342c-1.801,1.622-2.934,3.691-3.682,6.015
				c-1.179,3.663-3.104,6.963-6.215,9.245c-7.348,5.39-11.662,12.847-14.827,21.111c-1.877,4.901-3.671,9.843-5.284,14.838
				c-1.481,4.588-4.58,6.983-9.211,7.68c-1.371,0.206-2.728,0.508-4.099,0.709c-2.23,0.325-4.159,1.228-5.798,2.785
				C198.107,473.402,197.743,473.954,196.681,473.758z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(98)} fill={props.fillColors[98]} d="M424.229,546.213c-0.638-4.597-1.178-9.21-1.938-13.786
				c-0.942-5.669-2.185-11.219-4.992-16.393c-2.169-3.997-3.137-8.541-4.472-12.896c-0.957-3.125-1.7-6.319-2.745-9.413
				c-1.638-4.848-2.479-9.757-1.937-14.88c0.146-1.383-0.268-2.719-0.557-4.084c-1.74-8.226-4.266-16.278-5.542-24.598
				c-0.342-2.223-0.226-4.563,0-6.817c0.604-6.069-1.053-11.74-2.779-17.414c-1.093-3.592-2.616-7.06-3.047-10.842
				c-0.148-1.307-0.636-2.563-1.545-3.488c-2.146-2.186-3.319-4.921-4.494-7.641c-1.809-4.188-3.701-8.327-5.978-12.287
				c-1.715-2.985-2.624-6.181-2.43-9.663c0.077-1.384-0.029-2.777,0.019-4.164c0.122-3.545-0.619-6.902-2.192-10.062
				c-2.307-4.63-4.024-9.45-5.323-14.466c-1.19-4.595-2.819-9.074-4.199-13.622c-0.282-0.93-0.762-1.841-0.646-2.867
				c3.239-1.001,6.658,0.94,6.391,4.193c-0.331,4.02,1.459,7.516,1.812,11.325c0.413,4.462,2.13,8.633,3.214,12.946
				c0.311,1.235,0.84,2.442,1.438,3.573c1.916,3.622,3.265,7.412,3.925,11.471c1.125,6.922,3.439,13.391,7.274,19.326
				c2.798,4.33,5.195,8.914,7.282,13.625c2.459,5.551,3.658,11.496,4.831,17.42c0.365,1.846,0.208,3.784-0.17,5.627
				c-0.694,3.384-0.429,6.688,0.352,10.009c0.951,4.049,1.954,8.082,2.354,12.246c0.348,3.63,2.116,6.784,3.761,9.949
				c0.961,1.852,1.856,3.721,2.453,5.726c1.05,3.531,1.13,7.026-0.352,10.47c-2.056,4.777-1.999,9.649-0.565,14.55
				c2.035,6.955,4.662,13.688,7.842,20.201c0.433,0.886,0.965,1.733,1.531,2.542c1.44,2.053,1.847,4.227,1.478,6.746
				c-0.647,4.432-0.292,8.903,0.075,13.348C424.469,543.487,424.617,544.831,424.229,546.213z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(99)} fill={props.fillColors[99]} d="M296.067,114.639c0.985-2.747,2.877-3.595,4.965-2.421
				c0.259,0.145,0.551,0.269,0.748,0.478c1.617,1.705,3.212,1.306,4.927,0.105c1.593-1.115,1.981-2.321,0.573-3.6
				c-1.279-1.162-1.201-2.306-1.279-3.847c-0.086-1.693,0.436-2.616,1.613-3.453c1.028-0.73,0.85-1.916,1.171-3.082
				c1.516-0.183,3.147,0.031,4.49-0.977c0.279-0.21,0.892-0.374,1.101-0.22c2.125,1.567,3.208,0.116,4.16-1.464
				c2.366,0.046,4.464,1.434,6.936,0.5c0.412,2.31,2.061,3.993,2.069,6.137c1.106,0.814,2.332,0.163,3.289,0.758
				c0.703,3.436,0.939,3.651,3.687,1.668c1.698,0.944,2.714,2.951,2.281,4.532c-0.182,0.665-0.688,1.003-1.324,0.958
				c-0.47-0.033-0.931-0.281-1.385-0.463c-2.798-1.12-4.906,0.086-5.167,3.06c-0.124,1.411-0.528,2.708-1.05,3.996
				c-0.26,0.641-0.785,1.356-0.671,1.935c0.444,2.255-1.193,3.454-2.345,4.802c-2.194,2.57-2.309,3.328-0.214,5.984
				c0.179,0.227,0.366,0.447,0.803,0.981c-1.966-0.305-3.629-0.666-5.316-0.437c-0.644,0.087-1.121-0.167-1.383-0.838
				c-0.435-1.109-1.358-1.322-2.434-1.206c-1.769,0.19-2.267,0.882-1.59,2.57c0.414,1.032,0.712,2.05,0.73,3.15
				c0.007,0.436-0.226,0.731-0.654,0.854c-1.599-4.503-6.969-3.997-9.817,0.289c-1.055-0.362-1.191-1.14-0.942-2.084
				c1.061-4.033-0.82-7.688-1.499-11.493c-0.221-1.235-1.813-1.064-2.485-1.896C300.512,116.272,300.104,115.718,296.067,114.639z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(100)} fill={props.fillColors[100]} d="M636.497,535.081c1.989,3.967,3.986,7.931,5.968,11.901
				c6.718,13.463,13.416,26.936,20.162,40.384c0.908,1.81,1.405,3.606,1.368,5.666c-0.107,5.843-0.036,11.688-0.061,17.532
				c-0.004,0.971,0.193,1.972-0.328,3.106c-5.81,0-11.689,0-17.599,0c-0.172-1.246,0.521-2.195,1.138-3.004
				c2.438-3.201,2.195-6.256-0.077-9.404c-0.686-0.949-1.092-2.099-1.693-3.289c0.707-1.382,1.439-2.777,2.138-4.19
				c2.326-4.7,2.367-2.9-0.064-7.77c-0.662-1.325-1.336-2.646-2.067-4.091c0.841-1.648,1.554-3.407,2.577-4.964
				c1.348-2.05,1.3-3.773,0.079-5.974c-3.842-6.922-7.389-14.008-11.12-20.992c-0.82-1.535-1.26-3.055-1.218-4.802
				c0.08-3.331,0.022-6.665,0.022-9.997C635.98,535.156,636.239,535.119,636.497,535.081z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(101)} fill={props.fillColors[101]} d="M370.471,272.096c0.517,0.136,0.794,0.125,0.866,0.241
				c1.868,2.992,4.78,5.264,5.999,8.703c0.404,1.138,0.955,2.247,1.956,2.927c2.053,1.398,2.549,3.426,2.566,5.693
				c0.027,3.304,0.483,6.548,1.81,9.578c1.506,3.436,3.188,6.794,4.834,10.166c0.345,0.705,0.751,1.47,1.338,1.952
				c3.071,2.521,4.26,6.096,5.621,9.604c0.752,1.938,1.621,3.832,2.489,5.723c2.364,5.148,3.087,10.37,1.111,15.85
				c-1.021,2.836-0.535,3.92,2.223,5.203c1.56,0.725,2.459,1.871,3.24,3.301c5.697,10.439,11.218,20.972,16.421,31.664
				c2.149,4.417,4.648,8.578,8.138,11.996c6.949,6.804,12.084,14.84,16.933,23.189c6.618,11.396,13.636,22.562,20.53,33.796
				c2.571,4.188,4.716,8.494,4.849,13.541c0.031,1.16,0.174,2.328-0.22,3.434c-0.777-0.062-0.949-0.573-1.206-0.952
				c-1.446-2.135-2.96-4.258-3.862-6.679c-3.908-10.483-10.1-19.707-15.688-29.294c-4.084-7.005-8.721-13.433-14.339-19.229
				c-3.12-3.219-5.539-6.955-7.687-10.9c-7.487-13.754-14.188-27.912-21.537-41.736c-1.441-2.711-2.993-5.365-4.54-8.019
				c-0.995-1.707-1.966-3.43-3.833-4.43c-1.388-0.743-1.456-2.165-1.054-3.406c0.724-2.229,0.146-4.266-0.355-6.407
				c-1.297-5.542-3.122-10.846-6.279-15.631c-0.27-0.409-0.502-0.876-0.615-1.349c-1.404-5.845-4.368-11.033-6.878-16.412
				c-0.126-0.269-0.272-0.534-0.354-0.817c-1.9-6.649-5.369-12.51-8.968-18.345C371.614,281.213,370.517,276.882,370.471,272.096z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(102)} fill={props.fillColors[102]} d="M280.423,372.895c0.141,1.469-0.37,2.236-0.81,2.976
				c-1.94,3.255-3.337,6.74-4.569,10.307c-0.355,1.028-0.746,2.079-1.535,2.863c-1.646,1.635-2.198,3.704-2.525,5.896
				c-0.805,5.39-1.209,10.831-2.185,16.2c-0.45,2.477-1.255,4.736-2.586,6.893c-1.349,2.185-2.445,4.532-3.57,6.848
				c-1.354,2.787-2.944,5.407-5.209,7.545c-3.823,3.605-5.413,8.209-5.994,13.255c-0.283,2.457,0.167,5.193-0.787,7.323
				c-0.917,2.045-3.8,2.645-5.616,4.185c-1.598,1.356-3.095,2.79-4.321,4.486c-4.558,6.307-8.326,13.02-10.467,20.558
				c-0.352,1.239-0.696,2.48-1.051,3.719c-0.74,2.575-2.178,4.607-4.451,6.101c-2.485,1.632-4.901,3.365-7.166,5.308
				c-3.919,3.361-6.817,7.441-8.773,12.198c-1.131,2.748-2.252,5.504-3.272,8.294c-0.883,2.416-2.388,3.817-5.078,3.648
				c-1.042-0.065-2.102,0.377-3.491-0.094c4.13-3.674,5.782-8.458,7.244-13.273c2.319-7.641,6.857-13.54,13.402-17.989
				c0.164-0.111,0.311-0.255,0.486-0.343c5.232-2.615,7.549-7.149,8.913-12.622c1.945-7.808,6.064-14.602,10.92-20.947
				c1.648-2.153,3.75-3.841,6.128-5.108c1.506-0.803,2.067-1.873,2.051-3.553c-0.022-2.382,0.484-4.717,1.007-7.045
				c0.964-4.298,2.915-7.96,6.36-10.804c2.161-1.782,3.806-4.012,5.2-6.448c1.721-3.009,3.396-6.047,5.494-8.831
				c1.277-1.696,1.743-3.777,2.167-5.812c0.99-4.752,1.912-9.518,2.817-14.287c0.487-2.565,1.151-4.984,3.195-6.858
				c1.101-1.011,1.644-2.486,2.156-3.872C275.895,379.853,278.121,376.58,280.423,372.895z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(103)} fill={props.fillColors[103]} d="M303.12,573.28c0.499-3.901,0.982-7.805,1.5-11.704
				c0.534-4.021,1.102-8.037,1.653-12.056c0.215-1.571,0.562-3.107,1.002-4.636c0.645-2.242,1.714-3.831,4.219-4.43
				c2.587-0.619,4.011-2.781,5.179-5.015c1.746-3.338,3.584-6.613,5.831-9.646c1.182-1.595,2.448-3.117,3.944-4.429
				c0.961-0.843,1.956-1.658,3.382-1.849c0.801,2.649,0.713,5.343,1.177,7.951c0.434,2.438,0.778,4.898,1.506,7.259
				c0.864,2.804,0.343,5.307-1.321,7.586c-2.008,2.749-3.725,5.562-4.021,9.091c-0.154,1.824-1.312,3.29-2.479,4.654
				c-2.137,2.501-4.784,4.441-7.311,6.507c-0.365,0.298-0.861,0.558-1.318,0.6c-4.236,0.388-6.949,3.074-9.384,6.169
				c-1.032,1.312-1.977,2.692-2.962,4.042C303.518,573.343,303.318,573.312,303.12,573.28z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(104)} fill={props.fillColors[104]} d="M408.171,233.978c-0.887,2.817,0.754,5.438-0.746,8.023
				c-0.546,0.941-0.076,2.106,0.402,3.134c0.374,0.801,1.272,1.292,1.318,2.511c-2.054,1.132-2.81,2.88-1.894,5.301
				c0.436,1.151-0.15,1.963-1.42,2.007c-1.894,0.066-3.512,0.481-4.338,2.452c-0.319,0.761-1.129,0.618-1.812,0.702
				c-1.784,0.219-3.468,0.5-4.291,2.547c-0.488,1.216-2.088,1.34-3.039,2.235c-1.157,1.091-2.325-0.149-3.511-0.323
				c-2.375-0.351-4.82-0.713-7.247-0.272c-0.769,0.14-1.506,0.48-2.463,0.091c-1.801-5.25-3.156-10.71-4.448-16.179
				c-0.55-2.326,1.018-3.547,3.345-2.839c0.471,0.144,0.905,0.421,1.381,0.533c2.127,0.498,3.922-0.7,4.225-2.875
				c0.111-0.798,0.169-1.568,0.925-2.351c0.919-0.075,2.138,0.59,3.115-0.531c0.854-0.979,0.989-2.068,0.924-3.365
				c1.447-0.21,2.657-0.847,3.855-1.555c1.721-1.017,3.107-0.555,4.089,1.227c1.524,2.768,3.004,3.116,5.627,1.339
				c0.405-0.275,0.821-0.598,1.28-0.72C404.866,234.691,406.307,234.4,408.171,233.978z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(105)} fill={props.fillColors[105]} d="M179.229,703.07c-2.625,0.168-4.886,0.188-7.345-0.01
				c0.909-3.325,1.657-6.581,2.706-9.736c0.811-2.441,1.732-4.839,2.33-7.35c0.181-0.759,0.421-1.596,0.901-2.177
				c3.271-3.959,5.123-8.685,7.249-13.257c2.167-4.657,5.14-8.686,9.039-12.021c0.899-0.769,1.828-1.553,2.862-2.105
				c2.057-1.1,4.125-1.351,6.049,0.421c0.499,0.46,1.123,0.837,1.755,1.087c1.806,0.715,3.485,0.129,3.64-1.751
				c0.35-4.256,3.232-4.903,6.527-5.344c3.481-0.464,6.694-0.259,9.296,2.577c1.199,1.306,2.687,2.34,4.316,3.121
				c1.084,0.521,1.97,1.25,2.563,2.67c-2.249,0.16-4.236,0.608-6.194,1.191c-1.046,0.312-1.942,0.045-2.624-0.87
				c-0.414-0.555-0.854-1.1-1.346-1.584c-1.804-1.777-3.789-2.043-5.907-0.608c-2.507,1.697-4.527,3.853-5.295,6.899
				c-0.494,1.957-1.647,3.309-3.381,4.292c-1.895,1.074-3.704,2.301-5.595,3.381c-3.155,1.803-5.769,4.171-7.74,7.223
				c-0.447,0.691-0.986,1.255-1.686,1.634c-2.966,1.606-4.924,4.252-7.028,6.75c-0.983,1.168-1.508,2.556-1.91,3.99
				C181.345,695.295,180.315,699.105,179.229,703.07z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(106)} fill={props.fillColors[106]} d="M458.583,186.396c0,0.296-0.006,0.68,0.001,1.063c0.046,2.519-0.552,3.074-2.889,2.343
				c-0.876-0.273-1.771-0.035-2.854-0.487c0.531-1.681,0.828-3.496-1.284-4.395c-1.4-0.596-2.176,0.389-2.995,1.694
				c-0.788-1.483-2.08-2.389-1.045-4.305c0.636-1.176-0.499-2.887-1.981-3.545c-0.802-0.356-1.697-0.5-2.798-0.808
				c0.605-3.694-3.063-4.424-4.853-6.566c-0.528-0.633-1.462-0.335-2.249-0.076c-1.879,0.618-3.853,0.946-5.712,1.959
				c-1.174-0.816-2.364-1.453-3.303-2.704c-0.996-1.327-2.842-1.16-4.425-1.21c-0.528-1.744-0.642-1.857-3.961-3.896
				c0.467-1.013,1.195-1.699,2.316-1.901c2.612-0.47,2.615-0.457,3.329-3.007c-0.146-0.881-1.184-1.028-1.515-1.887
				c0.034-0.194,0.072-0.476,0.134-0.753c0.588-2.681,0.588-2.643,3.307-2.575c1.218,0.031,2.265-0.475,3.257-1.238
				c1.921-1.479,2.439-1.34,4.015,0.602c0.894,1.103,2.41,1.911,1.383,3.761c-0.415,0.75-0.352,1.558,0.616,1.974
				c0.925,0.397,1.837,0.232,2.449-0.482c0.559-0.652,0.866-1.52,1.329-2.376c1.811-0.413,2.858,0.839,3.898,1.707
				c0.445,0.927-0.081,1.529-0.196,2.191c-0.56,3.217,0.583,4.172,3.662,3.065c1.032,0.338,0.741,1.543,1.623,2.353
				c3.311-0.403,6.297,1.135,9.235,2.821c3.318,1.903,3.317,1.905,6.637,0.3c-0.053,2.907,1.661,4.263,4.365,4.76
				c0.403,1.941,1.052,3.795,0.362,5.655c-0.93,0.557-1.71,0.104-2.481,0.126c-1.733,0.049-3.034,0.815-3.937,2.265
				C460.627,185.066,460.637,185.072,458.583,186.396z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(107)} fill={props.fillColors[107]} d="M633.881,605.281c0.313,2.834,0.149,5.546,0.104,8.117
				c-1.204,0.691-2.214,0.46-3.178,0.46c-23.19,0.015-46.381-0.021-69.571,0.063c-2.173,0.008-3.472-0.612-4.448-2.541
				c-1.017-2.006-2.345-3.854-3.739-6.1C580.188,605.281,606.944,605.281,633.881,605.281z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(108)} fill={props.fillColors[108]} d="M172.714,634.479c0.213,3.389,0.035,5.875-2.306,7.889
				c-1.64,1.411-1.703,3.698-2.332,5.679c0.333,0.379,0.593,0.666,0.844,0.96c2.096,2.449,2.115,4.667,0.033,7.154
				c-1.156,1.381-2.643,2.392-4.065,3.46c-0.924,0.693-1.495,1.492-1.708,2.651c-0.594,3.233-1.884,6.043-4.975,7.69
				c-0.852,0.455-1.104,1.392-1.4,2.214c-1.725,4.803-5.417,7.97-9.191,11.039c-1.823,1.482-3.209,1.611-6.478,1.091
				c-0.904,0.857-1.031,2.101-1.428,3.188c-1.023,2.806-2.663,5.192-5.229,6.641c-3.345,1.889-6.105,4.358-8.424,7.349
				c-1.087,1.402-2.381,1.774-4.102,1.741c-5.34-0.102-10.685-0.037-16.262-0.037c0.35-3.069,1.848-5.024,4.539-5.749
				c2.809-0.756,4.844-2.379,6.902-4.349c3.119-2.984,6.235-6.076,10.594-7.415c3.08-0.946,5.317-2.99,6.54-6.051
				c0.256-0.641,0.587-1.26,0.948-1.849c1.252-2.04,2.285-2.471,4.414-1.398c1.965,0.989,3.446,0.458,5.237-0.545
				c3.745-2.096,6.79-4.772,8.711-8.639c0.582-1.171,1.622-1.692,2.766-2.079c1.734-0.587,2.93-1.806,3.372-3.498
				c0.728-2.786,2.528-4.635,4.74-6.242c0.718-0.522,1.384-1.126,2.023-1.743c0.441-0.426,0.721-0.975,0.349-1.591
				c-0.352-0.583-0.944-0.629-1.56-0.619c-0.945,0.016-1.745,1.12-2.88,0.314c0.474-1.823,2.069-3.087,2.575-4.922
				c0.473-1.713,0.918-3.472,1.692-5.056C167.973,639.061,170.385,637.217,172.714,634.479z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(109)} fill={props.fillColors[109]} d="M102.511,653.468c4.998-3.478,9.338-7.625,15.526-8.552
				c0.782-0.117,1.545-0.37,2.329-0.466c4.045-0.497,6.903-2.754,9.113-6.027c4.604-6.817,9.226-13.623,13.839-20.434
				c0.278-0.411,0.577-0.812,0.81-1.247c5.746-10.757,15.848-14.663,26.888-17.074c0.763-0.167,1.58-0.118,2.371-0.109
				c0.375,0.003,0.75,0.148,1.427,0.296c0.102,2.909-0.461,5.501-2.624,7.541c-2.669,2.517-5.413,4.952-8.115,7.434
				c-1.021,0.939-2.009,1.915-3.023,2.863c-2.288,2.14-2.686,4.733-1.826,7.62c0.383,1.285,1.002,2.425,2.444,2.73
				c1.585,0.336,2.952-0.128,3.979-1.409c1.379-1.72,2.264-3.716,3.12-5.724c1.215-2.853,2.812-5.457,4.978-7.695
				c2.664-2.753,6.025-3.519,9.889-3.394c-2.245,2.732-2.468,6.43-4.896,9.007c-0.959,1.019-1.4,2.377-1.924,3.665
				c-1.983,4.875-9.157,9.481-14.456,9.37c-2.673-0.057-4.551-1.276-5.597-3.687c-1.214-2.797-1.727-5.707-0.704-8.682
				c0.87-2.527,1.909-4.996,2.773-7.524c0.394-1.15,1.415-2.534,0.137-3.551c-1.141-0.907-2.193,0.319-3.175,0.877
				c-2.985,1.694-5.401,3.992-7.219,6.926c-4.962,8.008-10.588,15.572-15.747,23.449c-1.143,1.744-2.412,3.393-3.933,4.835
				c-1.616,1.533-3.46,2.787-5.641,3.15c-6.115,1.019-11.761,3.479-17.574,5.432C104.785,653.391,103.915,654.073,102.511,653.468z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(110)} fill={props.fillColors[110]} d="M193.01,618.607c0.091,1.565-0.535,2.575-1.199,3.563
				c-0.991,1.475-1.521,2.979-0.614,4.721c0.742,1.423,0.017,2.723-0.572,3.953c-1.37,2.862-2.579,5.781-3.484,8.823
				c-0.77,2.587-1.268,5.202-0.5,7.882c0.325,1.132,0.557,2.336,1.602,3.101c1.159,0.85,0.861,1.848,0.331,2.892
				c-0.736,1.449-1.715,2.728-3.201,3.401c-5.436,2.462-8.291,7.131-10.628,12.25c-1.819,3.985-3.913,7.783-7.033,10.926
				c-0.345,0.348-0.651,0.753-1.185,0.83c-0.351-0.483-0.115-0.93,0.094-1.372c2.013-4.251,3.121-8.742,3.574-13.41
				c0.469-4.837,1.438-9.581,2.621-14.289c1.212-4.824,1.651-9.759,1.79-14.71c0.165-5.926,2.666-11.105,5.029-16.33
				c0.696-1.539,2.06-2.266,3.753-2.571C186.581,617.688,189.677,618.538,193.01,618.607z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(111)} fill={props.fillColors[111]} d="M464.444,300.387c0.387-1.024,0.656-2.101,1.175-3.041
				c1.214-2.202,0.418-3.969-1.37-5.138c-1.5-0.98-1.806-2.174-1.788-3.724c0.008-0.69-0.101-1.383-0.188-2.071
				c-0.236-1.875-0.132-3.57,1.926-4.491c0.418-0.187,0.847-0.627,1.013-1.051c0.847-2.16,2.713-3.002,4.849-3.871
				c1.581,2.375,4.497,1.623,6.729,2.701c1.064,0.514,2.084,0.83,2.695,2.089c0.926,1.909,2.896,2.349,4.737,1.369
				c2.712-1.444,2.612-1.308,5.166,0.564c1.693,1.242,3.422,2.658,5.703,2.795c0.631,0.038,1.139,0.214,1.484,0.792
				c0.862,1.44,2.341,1.622,3.658,1.226c1.61-0.484,2.638,0.155,3.73,1.073c1.293,1.085,2.521,2.213,3.344,3.846
				c-1.49,2.073-2.773,4.216-1.049,6.661c-0.46,0.857-0.982,1.41-2.047,1.797c-1.39-2.712-2.987-5.399-6.661-4.832
				c-0.474-0.71-0.893-1.354-0.662-2.051c0.506-1.522-0.334-2.149-1.514-2.727c-1.181-0.579-2.092-0.354-2.867,0.651
				c-0.97,1.259-2.214,1.399-3.376,0.483c-1.627-1.282-3.617-0.829-5.401-1.314c-1.863-0.506-3.702,1.516-3.693,3.515
				c0.003,0.792-0.08,1.599,0.043,2.372c0.123,0.767,0.456,1.5,0.711,2.29c-0.454,1.027-1.197,1.5-2.345,1.098
				c-1.062-0.372-1.933,0.021-2.773,0.614c-0.8,0.563-1.287,1.229-0.836,2.258c0.661,1.507,0.69,2.658-1.008,3.731
				c-1.394,0.882-0.946,2.681-0.904,4.151c0.014,0.48,0.112,0.959,0.178,1.483c-1.047-0.122-1.862-0.655-2.193-1.453
				c-0.895-2.154-3.035-3.642-3.082-6.302c-0.016-0.925-1.087-1.825-1.652-2.75C465.611,302.208,464.767,301.461,464.444,300.387z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(112)} fill={props.fillColors[112]} d="M425.995,171.535c-0.703,0.954-1.682,1.457-2.259,2.388
				c-1.027,1.655-1.374,1.593-2.899,0.259c-0.288-0.253-0.615-0.462-0.988-0.738c-2.141,0.829-3.222,2.188-2.866,4.549
				c0.2,1.333,0.031,2.592-1.385,3.29c-1.107,0.546-1.917,0.253-2.318-0.91c-0.159-0.463-0.209-0.968-0.282-1.457
				c-0.246-1.647-1.082-2.771-2.802-3.001c-1.639-0.22-3.229-0.088-4.188,1.521c-0.725,1.215-1.687,2.171-2.744,3.087
				c-0.975,0.843-2.657,5.79-2.553,7.044c0.068,0.824,0.31,1.54,0.968,2.088c1.47,1.225,1.1,2.9,1.359,4.869
				c-1.905-1.087-1.765-3.171-3.259-4.078c-1.402-0.852-2.791-1.428-4.377-1.279c-2.044-2.854-2.044-2.854-6.226-3.57
				c0.17-0.778,0.739-0.931,1.325-0.651c1.525,0.727,2.846,0.637,4.039-0.654c0.244-0.264,0.677-0.368,1.038-0.506
				c2.815-1.08,3.167-1.794,2.165-4.79c1.427,0.136,2.373-0.478,3.021-1.721c0.68-1.303,0.271-2.205-0.657-3.163
				c-0.927-0.955-0.688-1.999,0.191-2.886c0.485-0.49,1.062-0.889,1.582-1.347c0.972-0.857,1.174-1.851,0.293-2.855
				c-0.74-0.845-1.135-1.78-1.451-2.858c-0.605-2.062-2.287-2.625-4.202-1.525c-1.092,0.627-1.976,1.747-3.68,1.184
				c-0.562-1.346-1.023-2.863-2.667-3.808c-1.592-0.915-1.507-3.131-0.218-4.669c1.177-1.404,2.538-1.413,4.278-0.04
				c0.148,0.116,0.343,0.173,0.729,0.362c1.396-1.474,3.147-2.277,5.363-2.072c0.987,0.092,2.01-0.51,2.21-1.637
				c0.432-2.43,2.432-2.49,4.107-2.527c1.757-0.039,3.516,0.315,5.316,0.12c1.105-0.12,2.264,0.302,2.105,1.808
				c-0.11,1.053,0.68,1.853,0.643,2.62c-0.1,0.781-0.528,1.075-0.873,1.417c-0.552,0.547-0.837,1.179-0.32,1.838
				c0.558,0.712,1.462,1.532,2.254,1.005c2.986-1.984,4.65-0.221,6.277,1.926c0.176,0.232,0.398,0.428,0.602,0.643
				c-0.073,0.844-0.268,1.82-1.235,1.688c-2.417-0.33-3.429,1.58-5.132,2.599c1.07,1.785,2.859,2.071,4.164,2.94
				c0.511,0.974-0.402,1.979,0.267,3.109C422.419,170.629,424.23,169.972,425.995,171.535z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(113)} fill={props.fillColors[113]} d="M194.636,300.453c2.056-0.628,3.905-1.143,5.716-1.767
				c1.102-0.38,1.862-1.1,1.686-2.448c-0.115-0.878-0.154-1.771,0.27-2.882c1.768-0.324,3.295-1.341,4.658-2.684
				c0.714-0.704,1.751-0.934,2.733-1.055c0.852-0.105,1.581-0.349,1.918-1.164c0.971-2.339,3.453-2.482,5.017-2.21
				c2.534,0.441,4.886,0.22,7.324-0.083c0.473-0.059,0.968,0.058,1.555,0.101c0.569,1.299,1.089,2.566,1.674,3.804
				c0.641,1.354,0.4,2.555-0.728,3.444c-0.883,0.696-1.459,1.443-0.936,2.503c0.566,1.146,1.52,1.818,2.909,1.468
				c0.381-0.096,0.761-0.252,1.242,0.351c-0.944,1.627-1.109,3.484-0.412,5.404c0.12,0.331,0.026,0.8-0.108,1.152
				c-1.097,2.877-2.847,5.353-4.66,7.955c-2.156-2.384-4.692-3.479-7.528-3.984c-0.775-0.138-1.623-0.142-2.308-0.474
				c-3.013-1.462-5.607-0.633-7.931,1.403c-1.007,0.883-1.964,1.523-3.375,1.569c-1.921,0.063-3.575,1.081-5.497,1.988
				c-1.576-1.833-4.043-1.109-6.147-1.821c-1.934-0.654-2.612-1.789-3.163-3.35c0.746-0.945,1.798-1.054,2.696-1.396
				C194.603,304.995,195.193,304.07,194.636,300.453z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(114)} fill={props.fillColors[114]} d="M350.472,319.756c-1.606-4.114-2.229-8.4-2.575-12.744
				c-0.472-5.925-0.923-11.851-1.739-17.742c-0.136-0.975-0.29-1.941-0.147-2.952c0.563-3.979-0.519-7.884-0.628-11.839
				c-0.044-1.588-1.15-2.464-2.732-2.625c-0.949-0.096-1.944,0.029-2.927-0.72c0.897-1.014,1.761-1.926,2.55-2.898
				c1.233-1.518,1.525-3.19,0.609-4.981c-0.496-0.969-1.057-1.903-1.208-3.016c-0.118-0.863-0.557-1.512-1.506-1.553
				c-0.886-0.038-1.496,0.404-1.787,1.266c-0.115,0.341,0.001,0.786-0.617,1.091c-0.461-1.216,0.116-2.225,0.39-3.306
				c1.022-4.028,1.706-8.08,0.108-12.141c-0.257-0.654-0.419-1.336-0.262-2.036c0.268-1.195,0.141-2.35-0.186-3.518
				c-0.68-2.428,0.102-4.274,2.313-5.5c0.686-0.38,1.477-0.642,1.674-1.801c-0.327-1.158-1.59-1.925-2.139-3.228
				c-1.439-3.419-1.254-6.749-0.006-10.076c0.514-1.37,0.583-2.739,0.431-4.089c-0.694-6.205-0.357-12.456-0.909-18.666
				c-0.094-1.065-0.301-2.16,0.066-3.201c0.606-0.568,1.354-0.267,2.002-0.438c1.787-0.473,2.933-1.625,3.465-3.351
				c0.555-1.797-0.685-2.718-1.904-3.637c-0.869-0.655-1.716-1.345-2.541-2.055c-0.523-0.45-0.635-0.984,0.07-1.679
				c1.574,0.94,3.292,1.139,5.229,0.733c2.262-0.474,4.333,1.459,4.464,3.785c0.056,0.989,0.021,1.984,0.097,2.971
				c0.173,2.266-0.405,4.034-2.659,5.111c-2.237,1.069-3.022,2.967-2.578,5.437c0.28,1.555,0.388,3.143,0.538,4.719
				c0.094,0.994,0.128,2.041,0.725,2.85c2.5,3.393,1.698,6.925,0.582,10.5c-1.243,3.98-1.495,8.152-2.319,12.214
				c-0.075,0.368-0.061,0.862,0.129,1.159c1.263,1.965,0.709,3.914,0.268,5.989c-0.597,2.8-0.466,5.715-1.044,8.543
				c-0.377,1.846-0.691,3.613-2.047,5.126c-1.086,1.212-1.299,2.828-0.822,4.292c1.008,0.793,1.873-0.115,2.764,0.114
				c2.279,3.685,3.758,7.656,4.94,11.754c0.376,1.302,0.113,2.606-0.247,3.812c-1.666,5.578-1.312,11.138-0.184,16.745
				c1.055,5.241,2.185,10.48,2.905,15.771c0.654,4.806,0.367,9.684,0.177,14.532C351.236,318.957,351.106,319.45,350.472,319.756z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(115)} fill={props.fillColors[115]} d="M319.619,702.873c-1.297,0.427-2.154,0.321-3.229,0.129
				c-0.023-2.034-0.538-3.915-1.24-5.763c-0.85-2.239-0.584-4.515,0.216-6.698c0.547-1.492,0.577-2.871-0.119-4.303
				c-1.186-2.438-0.564-4.621,1.04-6.633c0.371-0.465,0.774-0.902,1.15-1.363c1.55-1.897,1.496-1.864,0.064-3.973
				c-1.446-2.13-2.81-4.325-3.538-6.833c-0.56-1.931-1.483-3.405-3.722-3.719c-1.479-0.207-2.136-1.312-2.236-2.75
				c-0.119-1.705-0.042-3.371,0.997-4.845c1.598-2.268,1.743-5.021,2.483-7.572c0.131-0.453,0.151-1.041-0.026-1.463
				c-0.963-2.295-0.42-4.458,0.39-6.641c0.518-1.395,0.891-2.836,0.89-4.347c0-1.269,0.331-2.441,1.598-2.937
				c1.354-0.53,2.3,0.46,3.058,1.325c1.759,2.007,3.596,4.02,3.608,6.952c0.005,1.209,0.675,1.991,1.601,2.684
				c4.421,3.305,6.049,8.184,7.206,13.244c0.403,1.762-0.309,3.537-1.051,5.172c-0.756,1.664-0.817,3.194,0.279,4.764
				c1.02,1.461,1.052,3.135,0.791,4.86c-0.318,2.104,0.011,2.511,1.974,2.65c1.26,0.09,2.643-0.326,3.664,0.748
				c1.243,1.31,2.108,2.893,2.721,4.566c0.568,1.554,0.039,2.368-1.576,2.864c-1.262,0.389-3.021,0.152-3.195,2.041
				c-0.15,1.621,1.295,2.052,2.44,2.614c1.013,0.498,1.527,1.354,1.375,2.409c-0.449,3.127,0.315,5.866,2.235,8.359
				c0.605,0.785,1.022,1.778,0.183,2.716c-0.863,0.964-1.833,0.334-2.757,0.084c-0.282-0.076-0.532-0.265-0.808-0.375
				c-3.288-1.326-3.671-1.158-4.961,2.168c-0.771,0.24-1.542,0.276-2.326-0.075c-1.461-3.388-1.75-6.814-0.546-10.381
				c1.079-3.194,1.004-6.655,2.144-9.857c0.428-1.202-0.107-2.355-0.792-3.358c-0.67-0.979-1.349-1.977-2.172-2.82
				c-1.153-1.181-1.55-2.453-1.118-4.053c0.431-1.597,0.253-3.151-0.646-4.576c-1.292-2.047-1.221-4.236-0.227-6.263
				c1.362-2.777,1.021-5.308-0.289-7.952c-1.323-2.673-2.442-5.444-5.277-6.967c-0.846-0.454-1.222-1.25-1.254-2.219
				c-0.023-0.69,0.002-1.408-0.178-2.062c-0.354-1.28-1.559-1.985-2.731-1.727c-1.185,0.262-1.957,1.376-1.848,2.673
				c0.009,0.098,0.007,0.209,0.053,0.291c1.834,3.29,0.731,6.588-0.131,9.804c-0.796,2.969-0.349,5.689,0.904,8.363
				c1.893,4.043,4.044,7.947,6.499,11.677c1.654,2.512,1.668,3.369-0.114,5.866c-0.172,0.242-0.328,0.505-0.537,0.712
				c-1.95,1.944-3.163,3.95-1.526,6.804c0.69,1.204,0.302,2.715-0.215,4.05c-0.859,2.22-0.929,4.396,0.085,6.631
				C319.625,699.215,319.526,701.021,319.619,702.873z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(116)} fill={props.fillColors[116]} d="M522.135,633.95c3.225,2.021,6.547,2.013,9.99,1.017
				c2.403-0.696,3.076-0.494,4.565,1.498c0.587,0.785,1.074,1.668,1.472,2.566c0.449,1.016,1.019,1.883,1.846,2.644
				c1.664,1.532,1.703,1.88,0.342,4.027c-1.867,2.946-2.616,6.172-2.571,9.607c0.056,4.188,3.277,6.79,7.346,5.887
				c1.143-0.254,2.302-0.677,3.305-1.271c1.482-0.879,2.38-0.323,3.237,0.866c1.064,1.477,1.724,3.133,2.06,4.889
				c1.97,10.329,5.075,20.33,8.606,30.214c0.863,2.417,2.242,4.592,3.341,6.992c-1.25,0.457-2.297,0.337-3.535,0.119
				c-1.885-4.633-4.837-8.747-7.324-13.128c-1.733-3.054-2.731-6.345-3.282-9.795c-0.869-5.442-3.311-7.788-8.852-8.174
				c-2-0.14-3.628-0.597-4.937-2.19c-0.912-1.11-2.245-1.491-3.666-1.743c-2.196-0.39-2.69-1.142-2.005-3.316
				c0.811-2.57,0.615-5.133,0.113-7.64c-0.979-4.881-2.646-9.568-5.389-13.743C524.882,640.361,523.494,637.225,522.135,633.95z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(117)} fill={props.fillColors[117]} d="M517.318,556.087c-2.628-0.764-4.66-1.032-5.248-3.444
				c-0.175-0.716-0.975-1.134-1.675-0.955c-1.984,0.507-4.122,0.353-5.916,1.733c-1.906,1.467-3.225,1.263-4.906-0.501
				c-0.814-0.854-1.492-1.849-2.172-2.822c-2.154-3.088-4.139-6.304-6.877-8.943c-0.581-0.561-0.964-1.256-1.154-2.047
				c-1.108-4.605-3.373-8.897-3.56-13.766c-0.069-1.81-1.024-3.387-2.331-4.702c-1.34-1.35-2.813-2.534-4.543-3.313
				c-3.207-1.445-4.396-4.254-5.512-7.705c1.638,0.604,3.004,0.798,3.896,1.972c1.007,1.326,2.376,1.601,3.944,1.617
				c3.069,0.031,6.129-0.267,9.209-0.086c2.243,0.131,3.572,0.984,4.553,3.07c4.213,8.971,8.698,17.809,13.537,26.463
				C511.074,547.147,513.674,551.58,517.318,556.087z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(118)} fill={props.fillColors[118]} d="M328.25,285.506c0.098,2.21-0.215,3.901-1.612,5.914
				c-2.33,3.355-4.203,7.205-4.89,11.353c-0.5,3.02-0.63,6.109-0.788,9.175c-0.113,2.192,0.113,4.335,1.586,6.19
				c0.825,1.04,0.892,2.374,0.888,3.689c-0.012,3.668,0.112,7.331,0.433,10.988c0.44,5.015-0.606,9.933-1.831,14.683
				c-0.703,2.727-2.369,5.451-4.394,7.64c-1.15,1.244-1.625,2.438-1.102,4.004c0.688,2.064,0.506,4.111-0.088,6.141
				c-1.098,3.753-1.526,7.561-1.098,11.458c0.053,0.488,0.117,1.012,0.005,1.478c-1.64,6.819-0.941,13.822-1.634,20.716
				c-0.258,2.57-0.062,5.108,0.936,7.59c1.233,3.069,1.48,6.358,1.515,9.634c0.1,9.32-0.024,18.613-2.169,27.78
				c-1.038,4.437-1.072,9.034-1.322,14.22c-1.296-2.405-1.941-4.508-3.33-6.223c-0.57-0.704-0.358-1.971,0.115-2.867
				c2.469-4.67,2.745-9.874,3.795-14.9c1.052-5.034,0.446-9.782-1.472-14.509c-1.15-2.836-1.949-5.816-2.844-8.751
				c-0.591-1.938-0.686-3.901,0.119-5.825c1.825-4.366,1.811-8.905,1.099-13.454c-0.421-2.691-0.463-5.313,0.195-7.954
				c0.432-1.733,0.62-3.502,0.767-5.285c0.22-2.657,0.473-5.3,1.81-7.742c0.758-1.384,0.854-3.028,0.941-4.623
				c0.119-2.177,0.328-4.348,0.447-6.524c0.126-2.316,0.549-4.484,2.087-6.369c0.891-1.091,1.013-2.527,1.221-3.918
				c0.693-4.634,0.59-9.292,0.526-13.947c-0.032-2.325,0.304-4.544,1.242-6.659c1.664-3.75,1.753-7.488-0.107-11.179
				c-0.763-1.515-0.791-3.04-0.445-4.625c0.513-2.356,0.439-4.669,0.018-7.064C317.791,299.596,319.838,289.706,328.25,285.506z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(119)} fill={props.fillColors[119]} d="M155.19,381.978c0.924-2.33,1.974-4.483,1.018-6.807
				c0.349-0.832,0.876-1.366,1.527-1.552c3.059-0.873,3.949-3.393,4.542-6.029c0.53-2.356-0.474-4.452-1.647-6.433
				c-0.402-0.679-0.943-1.274-1.368-1.942c-1.436-2.257-1.141-3.951,0.996-5.614c0.543-0.422,1.184-0.717,1.766-1.09
				c1.144-0.734,1.603-1.74,1.081-3.05c-0.455-1.141-0.999-2.229-2.555-1.916c-0.501,0.101-0.991,0.136-1.427-0.167
				c-1.587-1.103-3.145-0.936-4.72,0.041c-0.446,0.277-0.947,0.223-1.401-0.069c-0.409-3.803-2.599-4.909-5.993-2.877
				c-2.306,1.38-4.641,2.615-7.252,3.304c-1.461,0.386-2.74,1.085-3.654,2.381c-0.637,0.904-1.538,1.494-2.656,1.722
				c-0.822-3.667-0.183-4.469,3.358-5.759c1.573-0.573,3.017-1.769,4.236-2.969c1.12-1.103,2.344-1.415,3.745-1.374
				c2.513,0.074,4.86-0.547,7.268-1.212c3.163-0.874,6.464-0.542,9.705-0.162c0.876,0.103,1.73,0.391,2.593,0.6
				c2.013,0.49,3.133,1.883,3.192,3.971c0.016,0.58-0.184,1.192,0.191,1.626c3.661,0.306,6.417,1.989,8.502,4.864
				c0.625,0.861,0.312,1.695-0.035,2.527c-0.392,0.94-0.377,1.763,0.672,2.235c0.654,0.294,0.817,0.818,0.807,1.438
				c-0.019,1.133,0.469,2.111,0.896,3.112c0.314,0.738,0.689,1.583,0.307,2.292c-1.258,2.329-1.301,4.936-1.68,7.542
				c-3.138,0.534-5.751,2.347-9.024,1.88c-1.97-0.28-2.767,1.606-3.635,3.255c-2.064-0.413-3.407,0.457-4.01,2.453
				c-0.084,0.278-0.182,0.74-0.348,0.777C158.184,379.418,157.115,381.312,155.19,381.978z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(120)} fill={props.fillColors[120]} d="M311.523,488.779c-0.658-1.577-1.369-2.993-1.843-4.485
				c-0.605-1.906-0.688-3.811-0.035-5.817c1.256-3.861,2.337-7.755,1.503-11.944c-0.515-2.584-1.392-4.941-2.855-7.104
				c-0.722-1.067-1.517-2.09-2.164-3.2c-1.195-2.047-2.785-3.573-5.064-4.354c-0.725-0.248-1.556-0.435-1.865-1.334
				c0.273-1.101,1.275-1.49,2.105-1.938c3.414-1.841,5.18-4.671,5.769-8.485c0.502-3.25,0.567-6.51,0.77-9.77
				c0.024-0.388,0.018-0.839-0.155-1.166c-1.219-2.314-0.805-4.82-0.977-7.271c-0.296-4.215-0.222-8.515-2.618-12.318
				c-0.808-1.282-0.892-2.798-0.573-4.323c0.548-2.617,1.006-5.252,1.553-7.869c0.895-4.28,1.429-8.597,1.454-12.974
				c0.013-2.299,0.56-4.55,1.191-6.704c1.441-4.921,1.37-9.997,1.958-15.003c0.924-7.864,1.828-15.726,4.101-23.355
				c0.277-0.931,0.293-1.957,0.32-2.942c0.048-1.783,0.201-3.593-0.023-5.349c-0.624-4.905,1.753-8.72,4.267-12.76
				c1.634,2.967,1.615,5.901,0.438,8.673c-1.482,3.487-1.821,7.046-1.716,10.75c0.087,3.069,0.029,6.138-0.276,9.208
				c-0.258,2.598-0.579,5.09-2.309,7.223c-0.824,1.016-0.999,2.356-1.11,3.65c-0.205,2.37-0.476,4.739-0.562,7.114
				c-0.091,2.512-0.388,4.935-1.706,7.142c-0.2,0.334-0.383,0.737-0.396,1.114c-0.207,6.398-2.876,12.573-1.582,19.126
				c0.841,4.255,0.481,8.483-1.147,12.561c-0.722,1.807-0.719,3.653-0.285,5.547c0.8,3.488,1.894,6.9,3.279,10.171
				c1.944,4.59,2.031,9.205,1.112,13.973c-0.916,4.753-1.322,9.62-3.588,14.045c-0.744,1.452-0.858,3.124,0.197,4.529
				c2.725,3.628,3.794,7.831,3.877,12.205c0.144,7.527,0.289,15.069-0.483,22.584C312.059,488.196,312.104,488.506,311.523,488.779z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(121)} fill={props.fillColors[121]} d="M409.804,209.203c-0.6,1.853,0.17,2.925,1.71,3.663
				c0.431,0.206,0.757,0.629,1.131,0.953c2.391,2.067,4.897,1.852,6.921-0.602c0.372-0.451,0.564-1.064,1.309-1.317
				c0.915,0.789,0.783,2.73,2.173,2.871c2.444,0.248,4.812,2.063,7.399,0.4c1.078-0.692,1.809,0.126,2.45,0.858
				c0.032,1.763-1.938,2.34-2.06,3.913c-0.122,1.58,1.581,2.457,1.704,4.007c-1.375,1.075-3.017,0.741-4.067,0.422
				c-1.471-0.445-3.023-0.481-4.457-1.078c-2.68-1.114-3.746-0.601-5.093,2.354c-1.586,0.064-3.086,0.79-4.604,1.531
				c-0.729-0.424-1.369-0.926-2.094-1.193c-2.004-0.74-4.08,0.703-4.203,2.847c-0.196,3.43-0.198,3.422-3.442,4.259
				c-1.335,0.345-2.697,0.59-3.756,1.64c-0.93,0.923-2.139,0.605-2.445-0.39c-0.57-1.855-1.938-2.636-3.465-3.294
				c0.11-1.276,1.061-1.7,1.792-2.324c1.791-1.527,1.816-2.382,0.099-4.095c-0.428-0.427-0.887-0.786-0.99-1.419
				c1.545-3.084,1.498-3.267-1.323-5.101c-0.102-0.788,0.719-1.116,0.902-1.767c0.419-1.491-0.64-3.605-2.005-3.303
				c-3.573,0.791-4.782-2.002-7.016-3.868c2.003-1.969,3.981-3.657,6.357-5.093c0.238,3.511,2.698,4.604,5.192,2.698
				c1.135,0.361,2.256,0.733,3.387,1.073c0.577,0.173,1.113,0.378,1.528,0.849C405.471,211.674,406.581,211.767,409.804,209.203z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(122)} fill={props.fillColors[122]} d="M186.889,194.047c0.531,3.576,2.623,4.458,5.514,3.882
				c1.553,2.064,0.493,3.67-0.684,5.143c-1.241,1.551-2.24,3.041-1.074,5.076c0.319,0.557,0.2,1.234-0.197,1.681
				c-1.413,1.591-0.836,3.069,0.125,4.568c0.263,0.41,0.493,0.842,0.687,1.176c-0.189,1.012-0.823,1.604-1.528,1.843
				c-1.464,0.495-2.551,1.441-3.686,2.411c-1.384,1.183-2.322,0.811-2.672-0.928c-0.737-3.661-1.466-4.089-4.985-2.961
				c-0.558,0.178-1.134,0.331-1.712,0.398c-0.374,0.043-0.768-0.093-1.266-0.166c-0.642-1.988-1.944-3.356-3.875-4.188
				c-0.779-0.335-1.454-0.824-1.54-1.952c-0.293-3.873-1.876-4.998-5.59-4.171c-0.382,0.085-0.767,0.152-1.325,0.262
				c-0.553-1.441-0.978-3.104-3.058-2.92c-1.513,0.133-2.358,1.851-4.194,1.671c-0.837-2.483,0.983-5.54-1.502-7.537
				c0.501-1.393,1.707-1.866,2.559-2.932c2.035,1.097,3.659,0.047,4.96-1.181c1.752-0.013,1.312,1.809,2.327,2.207
				c2.047,0.341,2.885-1.395,4.273-2.581c0.687,1.146,0.564,2.69,2.083,3.042c1.234,0.286,2.34,0.536,2.946-1.004
				c0.706-1.796,2.043-1.789,3.83-1.809C180.483,193.042,183.296,195.213,186.889,194.047z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(123)} fill={props.fillColors[123]} d="M385.599,405.681c-2.299-1.099-4.099-1.711-5.003-3.599
				c-0.478-0.995-1.539-1.219-2.483-1.46c-1.404-0.358-2.289,0.322-2.413,1.801c-0.103,1.217,0.096,2.464,0.864,3.39
				c1.27,1.529,1.617,3.36,2.087,5.173c0.225,0.862,0.494,1.718,0.651,2.592c0.886,4.899,3.575,8.541,7.804,11.042
				c1.533,0.907,2.49,1.864,1.86,3.802c-0.281,0.866,0.161,1.74,0.442,2.594c0.588,1.781,1.005,3.604,1.109,5.52
				c-0.172-0.05-0.309-0.046-0.347-0.105c-4.896-7.639-10.854-14.627-14.023-23.318c-1.05-2.881-2.228-5.703-3.047-8.674
				c-0.817-2.969-3.086-5.237-4.675-8.689c1.132,0.786,1.696,1.211,2.292,1.587c1.984,1.252,3.967,1.075,5.862-0.163
				c1.706-1.113,2.291-2.865,1.602-5.271c-0.516-1.801-1.245-3.551-2.003-5.269c-1.482-3.364-2.923-6.728-3.188-10.46
				c-0.034-0.489-0.143-0.989-0.312-1.449c-1.305-3.56-4.402-5.685-6.386-8.783c-2.571,2.615-3.245,8.643-1.885,14.793
				c-1.211-0.702-1.276-1.828-1.449-2.7c-1.254-6.336-2.12-12.688-0.926-19.169c0.318-1.727,0.103-3.556,0.094-5.338
				c-0.008-1.495-0.02-2.987,0.733-4.344c2.301-4.145,3.345-8.658,3.941-13.307c0.049-0.38,0.158-0.753,0.329-1.55
				c1.531,3.541,2.25,6.924,3.208,10.213c1.738,5.972,3.399,11.971,4.51,18.106c0.864,4.774,1.82,9.528,2.426,14.351
				c0.793,6.329,3.031,12.291,4.93,18.334C383.237,398.61,384.346,401.866,385.599,405.681z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(124)} fill={props.fillColors[124]} d="M126.219,703.188c2.57-3.255,4.767-5.828,7.941-7.517
				c3.245-1.726,5.481-4.501,6.671-8.043c0.217-0.646,0.495-1.272,0.799-2.046c3.651,0.686,6.187-1.059,8.792-3.285
				c3.245-2.774,5.855-5.881,7.441-9.823c0.394-0.979,1.002-1.646,1.875-2.198c2.113-1.339,3.653-3.35,3.954-5.706
				c0.444-3.469,2.86-4.896,5.504-6.779c0.284,1.982-0.426,3.553-0.508,5.171c-0.403,7.981-2.971,15.285-6.947,22.161
				c-3.123,5.399-6.308,10.754-9.822,15.915c-1.046,1.535-2.151,2.248-4.109,2.203c-6.235-0.145-12.477-0.054-18.716-0.053
				C128.328,703.188,127.562,703.188,126.219,703.188z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(125)} fill={props.fillColors[125]} d="M48.335,432.969c0,9.041-0.012,17.752,0.012,26.463
				c0.004,1.257-0.52,2.302-1.046,3.364c-1.581,3.192-3.173,6.379-4.764,9.574c1.638,3.296,3.216,6.493,4.817,9.68
				c0.583,1.161,1.027,2.315,1.022,3.671c-0.033,8.233-0.004,16.467-0.008,24.7c0,0.359,0.136,0.787-0.294,1.063
				c-0.844,0.071-0.84-0.729-1.074-1.179c-3.426-6.599-6.771-13.239-10.244-19.812c-0.826-1.563-0.931-2.842-0.043-4.41
				c1.067-1.887,2.725-3.751,2.735-5.853c0.011-2.104-1.647-3.974-2.72-5.855c-0.895-1.567-0.893-2.899,0.03-4.407
				c0.826-1.35,1.409-2.848,2.232-4.198c0.716-1.175,0.667-2.221-0.061-3.316c-0.164-0.247-0.25-0.552-0.434-0.778
				c-3.06-3.782-2.414-7.307,0.043-11.28c3.068-4.962,5.493-10.32,8.221-15.494C47.072,434.31,47.206,433.571,48.335,432.969z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(126)} fill={props.fillColors[126]} d="M340.632,122.375c-0.779-3.65,0.741-7.645-2.953-10.465
				c1.384-1.638,0.375-3.595,0.71-5.363c3.311-1.196,3.481-1.483,3.203-5.246c0.899-0.6,1.833-1.154,2.685-1.816
				c0.711-0.552,1.348-0.541,2.195-0.298c3.323,0.957,4.261,0.421,5.524-2.92c0.067-0.179,0.197-0.334,0.32-0.538
				c0.9-0.3,1.83-0.605,2.757-0.92c0.572-0.194,1.186-0.416,1.676,0.076c2.098,2.104,3.938,0.952,5.6-0.405
				c1.054-0.042,1.206,0.765,1.672,1.234c1.344,1.355,3.212,1.208,4.32-0.351c0.439-0.617,0.497-1.633,1.549-1.513
				c1.196,0.137,2.329,0.692,3.013,1.632c2.047,2.817,2.268,2.931,5.796,1.458c1.524,1.158,3.14,2.303,4.657,3.563
				c1.083,0.899,1.082,2.156,0.162,3.04c-2.047,1.963-2.083,2.36-0.404,4.533c0.228,0.295,0.638,0.459,0.648,1.083
				c-0.94,0.814-2.263,1.335-3.073,2.911c-1.522-2.215-3.84-2.892-5.146-4.871c-0.74-1.123-2.467-0.972-3.674,0.143
				c-0.637,0.588-1.071,1.396-1.735,2.292c-2.396-0.646-4.445,0.128-5.608,2.504c-1.54-1.055-0.901-2.264-1.05-3.269
				c-0.157-1.061-0.475-1.899-1.567-2.289c-1.175-0.418-2.03-0.096-2.893,0.821c-1.886,2.007-1.934,1.962-4.436,0.524
				c-2.656,0.924-2.197,3.021-1.699,4.939c-0.909,0.767-1.158-0.133-1.617-0.342c-1.395-0.633-2.23-0.422-3.01,0.809
				c-1.401,2.21-1.401,2.21-3.849,0.864c-1.317,1.024-2.223,2.037-1.31,3.978c0.604,1.285-0.126,2.738-0.638,4.007
				C342.117,123.03,341.327,122.698,340.632,122.375z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(127)} fill={props.fillColors[127]} d="M422.626,592.902c-1.986,2.227-2.457,2.388-4.518,1.034
				c-2.839-1.865-6.185-2.588-9.127-4.275c-2.207-1.266-5.499-0.124-7.103,2.26c-2.035,3.024-4.923,4.801-7.985,6.467
				c-3.077,1.674-6.265,1.779-8.981-0.333c-6.314-4.909-12.943-9.595-16.325-17.303c-0.656-1.496-1.778-2.631-3.059-3.581
				c-1.79-1.33-3.293-1.17-4.608,0.645c-1.058,1.457-2.271,2.749-3.521,4.015c-3.907,3.956-9.959,3.639-13.222-0.855
				c-2.657-3.661-4.463-7.713-4.293-12.409c0.131-3.646,1.347-6.504,4.442-8.882c2.946-2.263,5.908-2.989,9.367-2.355
				c1.372,0.251,2.541,0.897,3.508,2.043c1.673,1.981,3.253,1.894,4.859-0.103c0.366-0.456,0.651-0.994,0.894-1.531
				c1.42-3.156,3.791-4.277,7.142-3.278c0.748,0.224,1.543,0.529,2.126,1.022c2.264,1.913,4.896,2.077,7.592,1.604
				c7.735-1.359,14.744,0.311,21.116,4.828c6.133,4.347,11.476,9.3,14.714,16.364C417.867,583.129,420.385,587.858,422.626,592.902z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(128)} fill={props.fillColors[128]} d="M42.534,352.767c1.597,3.208,3.132,6.314,4.692,9.409
				c0.586,1.163,1.021,2.331,1.018,3.676c-0.019,8.428,0.004,16.854,0.003,25.281c0,0.173-0.124,0.346-0.211,0.576
				c-0.964,0.045-0.942-0.873-1.209-1.384c-3.344-6.413-6.594-12.875-9.99-19.261c-0.876-1.648-1.195-3.071-0.05-4.662
				c0.401-0.557,0.652-1.222,0.96-1.843c2.462-4.958,2.395-3.003-0.07-8.052c-2.36-4.835-2.606-3.125,0.055-7.75
				c1.74-3.026,1.747-5.401,0.002-8.412c-2.703-4.663-2.425-2.967-0.008-7.728c2.602-5.124,5.308-10.195,7.979-15.283
				c0.546-1.041,1.14-2.058,1.714-3.091c1.073,0.337,0.718,1.164,0.721,1.76c0.039,7.833,0.021,15.665,0.072,23.497
				c0.009,1.447-0.4,2.719-1.032,3.973C45.622,346.564,44.083,349.665,42.534,352.767z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(129)} fill={props.fillColors[129]} d="M269.722,552.435c0.683,0.415,0.621,1.022,0.337,1.568
				c-0.863,1.658-0.828,3.433-0.784,5.218c0.037,1.511-0.275,2.914-0.975,4.288c-1.167,2.292-2.158,4.672-3.28,6.988
				c-0.75,1.548-1.725,2.792-3.617,3.205c-1.619,0.354-3.077,1.24-4.758,1.593c-1.89,0.396-3.647,0.421-5.553,0.108
				c-2.03-0.333-4.146-0.194-6.225-0.17c-1.482,0.018-2.588,1.002-3.642,1.858c-3.408,2.771-7.544,3.841-11.584,5.139
				c-0.826,0.266-1.829,0.463-2.394-0.526c-0.491-0.861,0.255-1.474,0.632-2.135c3.176-5.572,7.645-10.061,12.175-14.493
				c0.631-0.618,1.337-1.233,2.124-1.604c2.872-1.353,5.68-2.782,8.086-4.904c0.601-0.529,1.392-0.789,2.198-0.813
				c3.195-0.1,6.228-1.119,9.352-1.621c2.493-0.4,4.609-1.719,6.683-3.079C268.888,552.799,269.157,552.307,269.722,552.435z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(130)} fill={props.fillColors[130]} d="M387.914,139.573c0.564-0.64,1.146-0.193,1.676-0.104
				c1.854,0.314,3.21-0.296,4.867-1.332c2.177-1.361,4.039-2.703,5.54-4.838c1.031-1.468,3.036-1.216,4.729-0.933
				c1.073,0.18,1.381,1.041,1.397,1.998c0.035,2.065,1.261,2.982,3.111,3.37c0.969,0.203,1.942,0.422,2.876,0.743
				c0.639,0.219,1.499,0.375,1.717,1.07c0.729,2.333,2.877,3.472,4.233,5.266c0.362,0.48,0.71,0.977,1.296,1.18
				c1.774,0.613,2.749,1.828,2.981,3.698c0.125,1.006,0.853,1.467,1.778,1.765c1.219,0.393,2.375,0.926,2.905,2.652
				c-2.473-0.252-5.305-1.363-5.543,2.646c-2.215,0.247-4.49-1.634-6.767,0.306c1.254-1.913,1.002-3.656,0.573-5.581
				c-0.489-2.196-1.375-3.297-3.681-3.045c-1.299,0.142-2.545,0.128-3.832-0.017c-3.703-0.416-5.257,0.492-6.746,3.878
				c-1.891,0.751-4.453-0.534-5.853,1.816c-1.838-1.3-3.792-1.541-5.348-0.259c-1.615,1.331-2.787,3.181-1.511,5.438
				c-0.916,1.181-1.975,1.815-3.291,1.996c-2.766-2.605-3.339-2.629-6.434-0.222c-0.318,0.248-0.609,0.583-1.021,0.474
				c-1.403-0.374-2.438,0.562-3.524,1.029c-2.221-1.708-2.656-3.003-1.496-4.419c0.681-0.829,1.65-1.451,2.548-0.543
				c1.951,1.974,3.831,1.119,5.632-0.016c1.614-1.017,1.995-3.309,1.19-6.062c1.838-1.617,3.289-3.731,5.896-4.626
				c2.128-0.731,2.727-3.623,1.347-5.452C388.704,140.842,387.941,140.437,387.914,139.573z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(131)} fill={props.fillColors[131]} d="M493.153,506.8c-3.919-0.607-7.557-0.26-11.197-0.182
				c-0.799,0.017-1.69,0.093-2.251-0.529c-2.074-2.3-5.021-3.116-7.629-4.439c-3.322-1.686-4.845-4.178-4.639-7.79
				c0.076-1.321-0.474-2.115-1.669-2.422c-0.992-0.254-1.604-0.802-1.729-1.78c-0.259-2.015-1.525-3.372-2.88-4.71
				c-3.279-3.24-5.002-7.529-7.767-11.021c1.071-1.418,2.37-1.58,3.643-1.688c1.761-0.149,3.563,0.231,5.284-0.502
				c0.314-0.133,0.829-0.057,1.146,0.115c2.065,1.124,4.387,1.817,6.104,3.461c-0.034,0.291-0.007,0.503-0.087,0.662
				c-0.412,0.82-1.746,1.307-1.088,2.501c0.644,1.168,1.726,0.631,2.663,0.415c1.176-0.27,2.146,0.158,3.038,0.84
				c1.266,0.968,2.4,2.062,3.242,3.439c3.926,6.432,8.205,12.616,12.877,18.532C491.361,503.152,492.474,504.661,493.153,506.8z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(132)} fill={props.fillColors[132]} d="M636.437,433.641c2.617,4.977,5.237,9.952,7.851,14.932
				c1.059,2.018,2.044,4.076,3.176,6.051c0.617,1.075,0.637,1.965,0.011,3.034c-0.899,1.536-1.581,3.202-2.499,4.727
				c-0.76,1.261-0.69,2.346,0.03,3.581c0.897,1.538,1.593,3.193,2.49,4.731c0.628,1.075,0.587,1.98-0.031,3.041
				c-0.897,1.538-1.583,3.201-2.499,4.727c-0.76,1.265-0.668,2.352,0.051,3.582c0.897,1.538,1.614,3.181,2.487,4.734
				c0.531,0.945,0.627,1.77,0.1,2.777c-3.668,7.023-7.288,14.072-10.936,21.106c-0.072,0.14-0.283,0.207-0.483,0.346
				c-0.687-0.49-0.412-1.234-0.414-1.854c-0.019-7.831,0.011-15.662-0.026-23.493c-0.007-1.436,0.311-2.72,0.965-3.981
				c1.323-2.549,2.521-5.166,3.9-7.684c0.654-1.193,0.577-2.162-0.038-3.314c-1.353-2.534-2.576-5.136-3.893-7.688
				c-0.603-1.17-0.924-2.358-0.918-3.693c0.036-8.128,0.021-16.257,0.032-24.385c0.001-0.388,0.082-0.774,0.126-1.162
				C636.091,433.717,636.264,433.679,636.437,433.641z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(133)} fill={props.fillColors[133]} d="M636.596,194.774c1.155,2.178,2.32,4.352,3.466,6.536
				c2.438,4.651,4.827,9.329,7.326,13.947c0.637,1.177,0.739,2.129,0.047,3.303c-0.955,1.619-1.705,3.357-2.627,4.998
				c-0.544,0.967-0.56,1.799-0.012,2.775c0.97,1.727,1.784,3.541,2.754,5.267c0.546,0.974,0.551,1.805,0.001,2.775
				c-0.928,1.637-1.683,3.373-2.64,4.991c-0.693,1.173-0.633,2.172,0.025,3.32c0.936,1.632,1.715,3.352,2.622,5.001
				c0.467,0.848,0.595,1.616,0.127,2.515c-3.656,7.032-7.287,14.078-10.938,21.113c-0.072,0.138-0.294,0.198-0.502,0.329
				c-0.619-0.537-0.327-1.266-0.328-1.888c-0.018-7.833,0.021-15.667-0.023-23.499c-0.009-1.542,0.35-2.897,1.056-4.251
				c1.51-2.895,2.925-5.839,4.415-8.837c-1.51-3.04-2.991-6.06-4.508-9.062c-0.54-1.071-0.954-2.126-0.948-3.377
				c0.042-8.329,0.028-16.658,0.042-24.987c0.001-0.273,0.101-0.545,0.153-0.818C636.268,194.875,636.432,194.825,636.596,194.774z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(134)} fill={props.fillColors[134]} d="M48.035,194.535c0,5.361,0.002,10.41-0.001,15.46
				c-0.002,3.867,0.459,7.811-0.151,11.58c-0.596,3.682-3.127,6.791-4.682,10.211c-0.204,0.449-0.391,0.905-0.628,1.456
				c1.487,2.99,2.975,6.009,4.491,9.014c0.585,1.161,1.034,2.318,1.029,3.669c-0.027,8.132-0.001,16.263-0.004,24.395
				c0,0.464,0.118,0.982-0.377,1.391c-0.774-0.19-0.865-0.953-1.157-1.517c-3.283-6.335-6.5-12.705-9.856-19
				c-0.852-1.598-0.768-2.873,0.071-4.408c1.038-1.898,2.715-3.738,2.677-5.865c-0.035-2.001-1.593-3.791-2.636-5.576
				c-0.959-1.641-0.967-3.004-0.022-4.678c1.059-1.875,2.837-3.749,2.625-5.853c-0.195-1.936-1.596-3.789-2.62-5.591
				c-0.855-1.506-0.983-2.772-0.096-4.396c3.327-6.086,6.483-12.266,9.724-18.4C46.729,195.843,46.876,195.124,48.035,194.535z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(135)} fill={props.fillColors[135]} d="M635.827,391.855c0-2.121-0.001-3.681,0-5.241c0.006-6.736,0.044-13.473-0.006-20.208
				c-0.012-1.734,0.299-3.293,1.137-4.828c1.326-2.432,2.46-4.969,3.757-7.417c0.518-0.977,0.515-1.791,0.002-2.767
				c-1.382-2.628-2.679-5.301-4.001-7.96c-0.44-0.885-0.862-1.762-0.86-2.805c0.017-8.712,0.01-17.424,0.01-26.145
				c1.133-0.168,1.172,0.721,1.448,1.243c3.335,6.3,6.599,12.638,9.983,18.91c0.751,1.392,0.804,2.509-0.022,3.846
				c-0.882,1.428-1.505,3.014-2.373,4.452c-0.708,1.173-0.609,2.176,0.047,3.315c0.888,1.543,1.572,3.205,2.493,4.726
				c0.713,1.18,0.635,2.167-0.048,3.304c-0.763,1.271-1.252,2.715-2.087,3.929c-1.18,1.717-0.95,3.189,0.044,4.907
				c1.031,1.779,2.605,3.56,2.555,5.588c-0.051,2.013-1.634,3.755-2.569,5.601c-2.462,4.856-4.97,9.689-7.474,14.524
				C637.415,389.693,637.119,390.653,635.827,391.855z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(136)} fill={props.fillColors[136]} d="M291.864,351.632c-1.077,2.422-2.207,4.822-3.216,7.272
				c-1.243,3.014-2.676,6.018-1.507,9.438c0.117,0.342,0.036,0.807-0.09,1.166c-1.627,4.646-2.388,9.585-5.238,13.814
				c-2.056,3.049-3.065,6.643-4.175,10.135c-2.191,6.89-4.133,13.849-5.459,20.967c-0.311,1.67-0.824,3.283-1.83,4.667
				c-1.638,2.254-3.028,4.512-1.09,6.996c-0.338,0.855-0.836,0.99-1.304,1.113c-2.264,0.597-3.759,2.147-4.936,4.039
				c-2.116,3.401-3.416,7.139-4.272,11.027c-0.391,1.774-0.198,3.553,0.519,5.247c0.424,1.003,0.863,2,1.292,3.002
				c1.863,4.345,2.405,8.855,0.871,13.34c-1.682,4.919-0.938,9.407,1.779,13.609c1.833,2.837,1.529,5.553,0.171,8.812
				c-0.785-1.761-1.303-3.293-2.113-4.65c-1.166-1.951-2.455-3.844-3.851-5.638c-2.6-3.342-3.154-6.704-0.689-10.384
				c2.134-3.186,2.556-6.812,2.361-10.542c-0.042-0.785-0.168-1.577-0.359-2.341c-0.737-2.957-1.745-3.313-4.361-1.615
				c-0.565,0.367-1.046,0.928-2.067,0.897c-0.455-2.401-0.01-4.803,0.353-7.173c0.672-4.392,2.149-8.416,5.505-11.576
				c2.332-2.196,4.007-4.879,5.388-7.771c1.364-2.857,2.792-5.68,4.468-8.385c1.765-2.847,1.993-6.189,2.465-9.411
				c0.645-4.408,1.152-8.835,1.739-13.251c0.226-1.698,0.79-3.244,1.998-4.542c1.316-1.413,1.86-3.252,2.491-5.021
				c0.766-2.147,1.465-4.332,2.693-6.264c1.913-3.007,2.783-6.287,3.08-9.808C283.076,361.769,286.097,355.95,291.864,351.632z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(137)} fill={props.fillColors[137]} d="M114.199,605.312c-1.739,2.805-3.298,5.303-4.839,7.811
				c-0.357,0.581-0.88,0.744-1.506,0.74c-0.496-0.003-0.991,0.007-1.486,0.007c-17.835,0.001-35.67,0.001-53.505,0.002
				c-0.779,0-1.559,0-2.475,0c-0.674-2.907-0.294-5.626-0.297-8.285C52.376,604.939,108.189,604.699,114.199,605.312z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(138)} fill={props.fillColors[138]} d="M396.966,226.828c-3.104,3.146-5.843,6.484-10.168,6.705
				c-0.83,1.347,0.675,2.23-0.35,3.403c-0.875,0.419-2.325-0.765-3.274,0.613c-0.704,1.021-1.031,2.107-1.202,3.305
				c-0.205,1.436-1.226,1.655-2.423,1.39c-3.239-0.719-3.236-0.731-6.072,0.144c-1.948-5.886-4.136-11.699-5.457-18.145
				c7.811-2.242,12.154-8.649,17.119-14.054c1.543,0.471,2.274,1.404,3.009,2.327c0.932,1.17,2.105,1.798,3.624,1.592
				c0.912-0.125,1.81-0.263,2.366,0.704c0.59,1.023-0.179,1.639-0.722,2.338c-0.231,0.297-0.35,0.683-0.457,0.902
				c0.275,1.637,2.081,1.241,2.76,2.588c-0.254,0.964-1.516,1.842-0.938,3.306C395.247,225.123,396.705,225.41,396.966,226.828z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(139)} fill={props.fillColors[139]} d="M650.546,320.39c-1.037-0.464-1.169-1.207-1.465-1.797
				c-4.128-8.232-8.22-16.482-12.358-24.709c-0.571-1.135-0.729-2.082-0.102-3.314c4.308-8.474,8.526-16.993,12.786-25.491
				c0.208-0.414,0.311-0.975,1.225-1.057c0.817,1.646,1.662,3.398,2.557,5.124c0.426,0.821,0.164,1.508-0.212,2.232
				c-0.866,1.666-1.71,3.344-2.616,5.124c1.338,2.641,2.673,5.277,4.071,8.039c-1.322,2.602-2.662,5.24-4.088,8.048
				c1.107,2.146,2.178,4.364,3.386,6.505c0.609,1.08,0.584,1.968-0.01,3.031c-1.156,2.073-2.198,4.208-3.299,6.341
				c0.456,1.687,1.406,3.089,2.2,4.546c0.636,1.167,0.705,2.172,0.019,3.331C651.886,317.615,651.273,318.97,650.546,320.39z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(140)} fill={props.fillColors[140]} d="M650.277,143.739c1.317,2.64,2.325,4.659,3.341,6.693
				c-1.102,2.201-2.162,4.319-3.26,6.512c1.101,2.134,2.126,4.272,3.295,6.328c0.661,1.162,0.683,2.133,0.017,3.301
				c-1.173,2.06-2.206,4.2-3.329,6.368c1.369,2.694,2.709,5.329,4.103,8.071c-1.033,2.014-2.01,4.069-3.129,6.042
				c-0.744,1.312-0.853,2.392-0.096,3.828c2.48,4.706,2.406,4.745-1.041,10.166c-4.939-9.568-9.521-19.048-14.289-28.513
				C640.595,163.115,645.276,153.748,650.277,143.739z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(141)} fill={props.fillColors[141]} d="M650.639,503.262c0.961,1.921,1.932,3.863,2.976,5.95
				c-1.045,2.099-2.095,4.207-3.246,6.521c1.073,2.101,2.137,4.321,3.334,6.469c0.595,1.065,0.581,1.914,0.049,3.027
				c-1.094,2.291-3.367,4.398-3.013,6.905c0.327,2.319,2.071,4.438,3.189,6.647c0.134,0.264,0.251,0.535,0.495,1.058
				c-0.999,1.957-1.99,4.104-3.173,6.14c-0.726,1.249-0.999,2.391-0.043,3.534c2.601,3.109,1.929,6.126-0.155,9.135
				c-0.208,0.301-0.239,0.76-0.784,0.829c-0.786-0.329-0.92-1.185-1.257-1.856c-4.087-8.143-8.144-16.3-12.228-24.443
				c-0.549-1.095-0.875-2.027-0.22-3.311c4.366-8.555,8.63-17.161,12.932-25.747C649.667,503.779,649.771,503.357,650.639,503.262z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(142)} fill={props.fillColors[142]} d="M650.441,383.26c1.173,2.345,2.186,4.368,3.185,6.364
				c-1.128,2.252-2.188,4.369-3.264,6.515c1.38,2.709,2.722,5.344,4.082,8.017c-1.119,2.178-2.143,4.322-3.309,6.386
				c-0.659,1.167-0.638,2.138,0.017,3.297c1.163,2.063,2.185,4.207,3.293,6.372c-1.106,2.158-2.136,4.299-3.293,6.367
				c-0.644,1.151-0.86,2.225,0.011,3.272c2.666,3.209,1.982,6.309-0.22,9.387c-0.161,0.226-0.33,0.445-0.5,0.673
				c-0.964-0.277-1.073-1.165-1.407-1.829c-4.136-8.229-8.232-16.479-12.376-24.704c-0.526-1.045-0.684-1.911-0.109-3.043
				c4.39-8.655,8.708-17.348,13.058-26.023C649.731,384.063,649.954,383.867,650.441,383.26z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(143)} fill={props.fillColors[143]} d="M29.476,180.962c1.101-2.141,2.125-4.28,3.291-6.341
				c0.661-1.167,0.625-2.155-0.011-3.305c-1.147-2.072-2.182-4.206-3.293-6.374c1.366-2.686,2.706-5.321,4.081-8.022
				c-0.867-1.705-1.697-3.387-2.576-5.042c-0.444-0.837-0.632-1.628-0.165-2.528c0.856-1.648,1.665-3.322,2.467-4.932
				c1.105,0.119,1.167,0.95,1.448,1.509c4.131,8.223,8.214,16.469,12.361,24.684c0.621,1.229,0.838,2.255,0.162,3.585
				c-4.349,8.555-8.615,17.152-12.915,25.733c-0.128,0.256-0.326,0.478-0.641,0.931c-1.458-1.623-2.091-3.584-2.95-5.396
				c-0.386-0.813,0.207-1.591,0.665-2.177c2.262-2.895,2.088-5.674-0.023-8.577C30.576,183.61,30.119,182.259,29.476,180.962z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(144)} fill={props.fillColors[144]} d="M29.466,300.581c1.052-2.056,1.961-4.041,3.061-5.913
				c0.833-1.419,0.937-2.625,0.058-4.091c-1.164-1.942-2.09-4.027-3.121-6.055c1.087-2.117,2.059-4.179,3.188-6.15
				c0.708-1.235,1.012-2.409,0.045-3.54c-3.112-3.642-1.83-7.068,0.423-10.468c0.092-0.138,0.306-0.196,0.513-0.321
				c0.782,0.357,0.917,1.192,1.253,1.861c4.042,8.047,8.066,16.104,12.102,24.155c0.578,1.153,1.065,2.147,0.333,3.577
				c-4.418,8.633-8.708,17.331-13.05,26.002c-0.121,0.242-0.345,0.433-0.81,1c-0.918-1.832-1.702-3.481-2.565-5.087
				c-0.523-0.974-0.126-1.814,0.411-2.475c2.516-3.097,2.092-6.063-0.104-9.107C30.516,303.02,30.112,301.868,29.466,300.581z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(145)} fill={props.fillColors[145]} d="M29.454,420.168c1.25-2.415,2.308-4.542,3.448-6.624
				c0.531-0.97,0.501-1.81-0.017-2.769c-1.174-2.173-2.273-4.388-3.432-6.639c1.159-2.244,2.263-4.453,3.438-6.623
				c0.522-0.963,0.59-1.845-0.008-2.769c-0.107-0.165-0.195-0.344-0.283-0.521c-2.616-5.295-2.616-5.295,0.918-10.569
				c0.848,0.142,0.936,0.945,1.221,1.514c4.131,8.225,8.228,16.466,12.351,24.693c0.544,1.087,0.913,2.011,0.251,3.309
				c-4.45,8.729-8.793,17.511-13.18,26.271c-0.08,0.16-0.268,0.267-0.677,0.658c-1.047-1.675-1.822-3.438-2.678-5.152
				c-0.428-0.856-0.007-1.628,0.466-2.204c2.627-3.2,2.047-6.256-0.167-9.37C30.426,422.418,30.014,421.275,29.454,420.168z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(146)} fill={props.fillColors[146]} d="M33.593,503.275c0.795,0.213,0.895,0.988,1.184,1.564
				c4.131,8.226,8.213,16.476,12.378,24.685c0.648,1.277,0.661,2.325,0.023,3.59c-4.283,8.481-8.51,16.991-12.762,25.487
				c-0.168,0.336-0.278,0.742-1.098,0.974c-0.773-1.545-1.567-3.192-2.419-4.809c-0.439-0.832-0.136-1.512,0.243-2.231
				c0.784-1.488,1.543-2.99,2.406-4.669c-1.298-2.564-2.633-5.202-4.077-8.055c0.795-1.567,1.502-3.315,2.513-4.866
				c1.387-2.128,1.591-4.033,0.067-6.218c-1.062-1.523-1.737-3.315-2.59-4.996c1.08-2.109,2.011-4.197,3.19-6.136
				c0.843-1.386,0.816-2.557-0.075-3.812c-0.057-0.079-0.086-0.178-0.13-0.267C29.971,508.612,30.02,508.169,33.593,503.275z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(147)} fill={props.fillColors[147]} d="M47.912,550.563c0.681,1.189,0.463,1.982,0.465,2.731
				c0.021,7.528-0.027,15.058,0.043,22.586c0.016,1.749-0.359,3.295-1.16,4.819c-1.335,2.541-2.533,5.154-3.898,7.678
				c-0.684,1.264-0.716,2.362,0.033,3.58c0.568,0.925,0.889,2.012,1.512,2.891c4.014,5.66,3.888,12.036,3.273,18.85
				c-2.749,0.356-5.564,0.116-8.457,0.131c-1.15-2.201-2.214-4.321-3.361-6.396c-0.535-0.968-0.553-1.8-0.007-2.779
				c0.964-1.728,1.767-3.545,2.731-5.272c0.544-0.975,0.547-1.806,0.005-2.775c-0.917-1.642-1.662-3.38-2.61-5.001
				c-0.683-1.167-0.67-2.156,0.013-3.313c0.904-1.532,1.586-3.195,2.494-4.726c0.695-1.172,0.625-2.174-0.033-3.32
				c-0.936-1.63-1.719-3.346-2.619-4.997c-0.464-0.852-0.554-1.619-0.081-2.515C40.088,565.482,43.894,558.215,47.912,550.563z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(148)} fill={props.fillColors[148]} d="M571.996,213.825c1.867,2.264,3.751,2.662,6.267,1.796
				c2.781-0.957,6.419,1.112,7.744,3.906c0.428,0.901,0.97,1.72,1.816,2.537c2.928-0.353,5.932,0.378,9.143,0.243
				c0.428,1.594,1.495,2.844,2.855,3.945c-0.766,1.063-1.449,2.01-2.145,2.976c-2.396,0.809-2.739-1.785-4.392-2.698
				c-3.826,0.901-3.608,5.206-6.528,7.615c-1.23,0.321-1.303-1.146-1.819-2.301c-0.616-1.376,0.145-2.224,1.031-2.997
				c-0.456-1.007-0.828-1.807-1.183-2.614c-1.055-2.4-2.182-2.903-4.604-1.999c-0.362,0.135-0.669,0.417-1.125,0.709
				c-0.454-0.984-0.008-2.398-1.614-2.764c-1.271-0.29-2.421-0.817-3.687-0.024c-1.559,0.977-3.213,1.434-5.097,1.001
				c-1.218-0.279-2.077,0.447-2.646,1.481c-0.566,1.027-0.699,2.095,0.084,3.06c0.883,1.088,1.642,2.213,2.024,3.587
				c0.425,1.525,1.868,0.587,2.8,0.988c-0.089,1.117-1.048,0.637-1.54,0.923c-1.861,1.078-2.568,2.839-1.965,4.937
				c0.356,1.235,0.782,2.429,1.45,3.517c-2.049,1.879-2.467,1.906-4.433,0.344c-0.772-0.614-1.661-1.117-2.315-1.835
				c-0.653-0.716-1.321-1.661-0.949-2.676c0.626-1.709-0.33-2.856-1.463-3.521c-3.941-2.316-3.981-5.707-2.966-9.452
				c-1.937-2.517-1.937-2.517-4.954-1.952c-0.796-0.719-1.405-1.603-1.893-2.557c0.966-2.531,2.88-3.361,5.035-2.089
				c0.766,0.452,1.433,0.61,2.218,0.254c1.765-0.8,3.828-0.988,5.059-2.851c0.582-0.88,4.547-1.466,5.775-0.892
				C569.402,215.087,570.634,215.005,571.996,213.825z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(149)} fill={props.fillColors[149]} d="M267.254,474.403c-0.31-7.617,0.113-15.088,3.011-22.242
				c1.432-3.533,1.698-7.319,1.744-11.099c0.05-4.094,0.484-8.059,2.283-11.856c1.287-2.715,1.352-5.785,1.41-8.752
				c0.063-3.207,0.624-6.213,1.748-9.256c2.028-5.487,3.084-11.275,4.142-17.014c1.514-8.222,4.328-16.05,6.752-23.999
				c0.321-1.052,0.736-2.073,1.408-2.933c1.783-2.274,3.357-4.655,4.286-7.422c0.08-0.238,0.397-0.396,0.664-0.647
				c0.562,1.105-0.125,1.96-0.391,2.836c-2.161,7.105-4.443,14.174-6.134,21.415c-0.499,2.14-0.8,4.289-0.718,6.479
				c0.098,2.587-0.237,5.144-0.418,7.712c-0.065,0.926-0.322,1.75-0.803,2.512c-2.219,3.507-3.441,7.335-3.724,11.468
				c-0.076,1.112-0.403,2.103-1.078,3.025c-3.561,4.873-3.572,10.371-2.483,15.936c0.342,1.748,0.501,3.158-0.466,4.781
				c-1.911,3.206-1.731,6.422,0.62,9.422c0.061,0.078,0.116,0.161,0.172,0.242c2.632,3.778,2.607,3.975-0.858,7.018
				c-0.298,0.262-0.686,0.478-0.867,0.806c-2.443,4.411-6.149,8.013-8.159,12.694C268.178,468.362,267.436,471.325,267.254,474.403z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(150)} fill={props.fillColors[150]} d="M283.782,411.982c-0.203-3.547,0.763-6.692,2.432-9.463
				c1.563-2.595,2.322-5.332,2.168-8.177c-0.44-8.123,2.078-15.649,4.297-23.241c1.333-4.559,2.874-9.057,4.323-13.582
				c0.119-0.37,0.314-0.734-0.149-1.341c-2.356,0.275-3.47,2.118-4.206,4.083c-0.818,2.185-2.026,4.063-3.474,5.833
				c-0.115,0.142-0.295,0.229-0.716,0.543c-0.497-1.459-0.226-2.753,0.26-3.969c1.139-2.849,2.175-5.764,3.605-8.465
				c3.252-6.144,5.282-12.624,5.43-19.558c0.13-6.134,4.74-11.232,9.917-14.338c0.817,0.635,1.083,1.33,0.213,2.151
				c-1.52,1.436-2.351,3.412-3.925,4.825c-1.347,1.21-1.721,2.8-1.272,4.613c0.75,3.027,0.641,6.076,0.029,9.119
				c-0.117,0.582-0.261,1.175-0.259,1.763c0.021,6.562-1.926,12.912-2.1,19.451c-0.018,0.696-0.006,1.463-0.577,1.939
				c-1.848,1.546-2.09,3.767-2.478,5.885c-0.483,2.636-1.037,5.241-1.96,7.761c-0.621,1.691-0.855,3.455-0.944,5.241
				c-0.167,3.368-0.555,6.711-1.151,10.029c-0.3,1.671-0.295,3.358-0.24,5.04c0.07,2.146-0.428,4.11-1.545,5.943
				c-1.03,1.693-2.053,3.387-2.097,5.479c-0.021,1.025-0.721,1.617-1.681,1.807C286.445,411.6,285.193,411.76,283.782,411.982z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(151)} fill={props.fillColors[151]} d="M297.937,224.619c2.873-2.635,6.373-1.605,10.022-2.466
				c-0.958,2.456-4.269,2.015-4.027,4.898c1.256,0.679,2.79,1.303,3.365,3.081c0.594,1.833-0.276,3.668-2.136,4.002
				c-3.235,0.583-3.235,0.583-5.476,2.743c-1.414-1.287-3.089-1.237-4.801-0.415c-1.716-1.776-3.579-2.587-5.929-1.537
				c-0.532,0.238-1.162-0.033-1.864-0.484c0.11-1.762-2.086-2.87-1.608-5c0.136-0.605-0.644-0.934-1.202-1.142
				c-0.737-0.274-1.497-0.488-2.123-0.688c-1.867-2.352,0.328-3.606,1.303-5.167c-1.422-1.777-3.191-1.411-5.036-1.113
				c-1.022-1.234-0.858-2.731-0.802-4.197c0.023-0.603,0.155-1.173,0.782-1.485c2.233-1.11,2.389-2.921,1.69-5.026
				c-0.122-0.368-0.249-0.758,0.193-1.368c1.974-0.064,3.195,2.235,5.831,2.035c0.505-0.185,0.568-1.346,1.179-2.088
				c1.447-1.757,2.608-1.749,3.912,0.172c0.165,0.243,0.245,0.545,0.417,0.782c1.168,1.617,1.724,1.716,3.679,0.678
				c0.256-0.136,0.492-0.339,0.569-0.302c1.73,0.668,1.052,2.371,1.981,3.34c0.891,0.931,2.456,0.856,3.088,2.369
				c-1.13,1.303-2.428,2.453-3.821,3.504C294.111,222.019,294.196,222.737,297.937,224.619z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(152)} fill={props.fillColors[152]} d="M357.245,150.402c-0.165-1.034,1.112-1.319,1.243-2.52
				c-0.467-0.99-1.83-1.933-1.281-3.621c0.194-0.598-0.429-1.288-0.885-1.812c-1.307-1.501-0.921-2.966,0.206-4.277
				c0.805-0.936,1.287-1.893,1.513-3.126c0.597-3.267,1.35-3.855,4.839-4.267c1.586-0.187,2.324-0.973,1.909-2.61
				c-0.377-1.491,0.108-2.47,1.192-3.604c2.243,1.095,4.277,0.836,5.581-1.619c2.624,0.454,3.981,2.301,5.403,3.859
				c0.149,0.804-0.421,0.963-0.728,1.312c-1.552,1.765-1.24,2.86,1.014,3.626c2.1,0.714,2.469,1.098,1.912,3.167
				c-0.52,1.935-0.489,3.483,1.342,4.67c0.786,0.51,0.75,1.335,0.535,2.144c-0.37,1.391-1.131,2.564-2.287,3.384
				c-1.744,1.238-2.971,2.852-4.018,4.688c-1.229,2.158-1.403,2.153-3.794,1.641c-1.702-0.365-3.425-1.256-5.066,0.239
				c-0.511,0.465-1.146,0.256-1.652-0.093c-1.659-1.146-3.401-1.462-5.345-0.79C358.367,150.97,357.699,151.028,357.245,150.402z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(153)} fill={props.fillColors[153]} d="M644.199,613.769c-2.869,0-5.426,0-8.094,0c-0.727-1.442-0.275-2.947-0.438-4.374
				c-0.617-5.414,0.514-10.431,3.587-14.922c2.014-2.941,2.059-5.646,0.022-8.626c-3.062-4.48-3.986-9.482-3.648-14.924
				c0.343-5.523,0.08-11.083,0.107-16.627c0.004-0.936-0.197-1.917,0.239-2.82c0.753,0.123,0.844,0.679,1.051,1.072
				c3.463,6.562,6.881,13.148,10.376,19.693c0.627,1.175,0.714,2.127,0.015,3.299c-0.96,1.611-1.694,3.355-2.613,4.993
				c-0.543,0.97-0.548,1.802-0.002,2.772c0.968,1.724,1.796,3.526,2.758,5.255c0.534,0.962,0.564,1.79,0.007,2.77
				c-0.978,1.718-1.792,3.528-2.758,5.254c-0.542,0.967-0.555,1.796-0.007,2.77c0.97,1.724,1.805,3.522,2.744,5.263
				c0.449,0.832,0.673,1.587,0.169,2.501C646.525,609.279,645.404,611.479,644.199,613.769z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(154)} fill={props.fillColors[154]} d="M636.246,88.465c2.615-0.199,5.151-0.198,7.8,0.006
				c1.203,2.295,2.367,4.584,3.602,6.834c0.486,0.888,0.423,1.644-0.05,2.503c-0.905,1.646-1.661,3.378-2.621,4.991
				c-0.75,1.261-0.695,2.349,0.03,3.581c0.853,1.447,1.481,3.029,2.368,4.452c0.788,1.265,0.7,2.325-0.042,3.564
				c-0.914,1.524-1.598,3.184-2.479,4.729c-0.608,1.065-0.581,1.978,0.016,3.046c1.157,2.07,2.195,4.206,3.248,6.246
				c-1.2,3.102-10.442,20.541-11.821,22.31c-0.62-0.559-0.328-1.285-0.33-1.909c-0.02-7.822,0.011-15.645-0.023-23.467
				c-0.007-1.427,0.267-2.703,0.944-3.97c1.305-2.442,2.445-4.973,3.751-7.416c0.57-1.065,0.728-1.996,0.02-3.027
				c-0.276-0.403-0.385-0.924-0.668-1.322C635.498,103.296,635.401,96.161,636.246,88.465z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(155)} fill={props.fillColors[155]} d="M39.879,88.446c2.742,0,5.198,0,7.785,0c0.599,1.588,0.167,3.186,0.348,4.719
				c0.679,5.739-0.662,10.972-3.757,15.819c-0.685,1.072-1.147,2.287-1.715,3.439c1.55,3.11,3.048,6.126,4.559,9.137
				c0.538,1.071,0.938,2.141,0.932,3.391c-0.033,8.232-0.012,16.465-0.018,24.698c0,0.375-0.09,0.751-0.144,1.169
				c-0.981-0.102-1.026-0.919-1.318-1.47c-3.294-6.22-6.52-12.476-9.876-18.662c-0.807-1.486-0.792-2.656-0.004-4.132
				c1.061-1.987,2.973-3.908,2.728-6.115c-0.226-2.035-1.652-3.992-2.763-5.855c-0.886-1.488-0.727-2.699,0.068-4.134
				c1.05-1.897,2.707-3.747,2.739-5.85c0.032-2.111-1.635-3.977-2.701-5.86c-0.884-1.561-0.925-2.87-0.003-4.402
				C37.859,92.475,38.795,90.5,39.879,88.446z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(156)} fill={props.fillColors[156]} d="M305.626,478.029c2.774,2.421,3.081,5.063,2.536,7.76
				c-0.765,3.78-1.812,7.504-2.764,11.246c-0.659,2.591-1.343,5.177-2.048,7.755c-0.847,3.094-1.062,6.246-0.922,9.43
				c0.111,2.538-0.595,4.824-1.597,7.157c-2.373,5.526-3.877,11.207-1.931,17.277c1.513,4.72,0.666,9.647,1.052,14.469
				c0.04,0.49-0.157,1.026-0.026,1.478c0.797,2.741-0.044,5.057-1.845,7.312c-1.076-0.918-1.269-2.355-1.175-3.393
				c0.161-1.785-0.218-3.512-0.094-5.282c0.25-3.589,0.007-7.145-1.965-10.339c-0.765-1.238-0.555-2.433,0.089-3.657
				c0.99-1.883,1.351-3.915,1.311-6.035c-0.065-3.495-1.259-4.811-4.738-5.166c-0.48-0.049-0.96-0.107-1.694-0.189
				c-0.602-5.196,0.622-9.943,3.541-14.055c2.474-3.484,3.787-7.075,3.588-11.335c-0.186-3.993,0.01-8.094,2.228-11.504
				C301.762,486.974,303.766,482.733,305.626,478.029z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(157)} fill={props.fillColors[157]} d="M510.699,559.175c6.068,0.852,10.03,3.537,12.827,8.192
				c6.797,11.314,14.86,21.732,22.822,32.238c7.009,9.248,12.741,19.353,18.256,29.558c0.359,0.664,0.896,1.29,0.743,2.468
				c-2.153-0.864-4.301-1.48-6.519-1.831c-1.143-0.18-1.821-1.113-2.347-2.114c-2.38-4.545-5.395-8.766-6.66-13.874
				c-0.556-2.242-2.041-4.012-3.844-5.524c-5.706-4.788-10.292-10.387-12.611-17.625c-0.7-2.186-1.992-4.064-3.494-5.822
				c-2.628-3.076-4.419-6.92-7.889-9.207c-4.054-2.674-6.52-6.124-7.022-11.065C514.733,562.316,512.921,560.798,510.699,559.175z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(158)} fill={props.fillColors[158]} d="M356.238,203.87c1.493,2.651,2.037,5.002,1.953,7.501
				c-0.152,4.579,0.027,9.068,2.298,13.249c1.54,2.834,1.418,5.953,0.826,8.989c-0.29,1.486-0.394,2.913-0.311,4.404
				c0.125,2.237,0.994,3.839,2.992,5.084c2.913,1.814,5.005,4.442,6.253,7.868c-0.571,0.6-1.604,0.062-2.127,0.873
				c0.175,1.404,1.553,1.59,2.511,2.104c1.539,0.827,2.564,1.904,3.351,3.539c5.17,10.754,8.262,22.235,11.888,33.525
				c1.937,6.029,3.64,12.133,5.398,18.218c0.206,0.713,0.819,1.4,0.348,2.363c-1.622-1.336-2.465-3.123-3.234-4.927
				c-0.812-1.908-1.672-3.77-2.683-5.597c-1.739-3.145-2.648-6.61-2.653-10.252c-0.004-3.066-0.379-5.959-3.238-7.853
				c-0.314-0.208-0.638-0.567-0.731-0.917c-0.904-3.373-3.185-5.995-5.401-8.383c-4.111-4.431-4.104-10.114-5.556-15.346
				c-0.978-3.521-1.479-7.155-3.3-10.43c-0.779-1.401-1.577-2.733-2.697-3.873c-0.485-0.495-0.909-1.056-1.427-1.514
				c-6.392-5.644-7.295-13.08-6.687-20.919c0.199-2.569,0.543-5.135,0.404-7.703C354.235,210.568,354.794,207.436,356.238,203.87z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(159)} fill={props.fillColors[159]} d="M215.023,468.251c-0.726,3.633-1.395,7.022-2.082,10.408
				c-0.871,4.283-3.112,7.642-6.725,10.131c-1.958,1.35-3.783,2.876-5.376,4.668c-2.11,2.372-3.062,5.036-2.349,8.214
				c1.773,7.892-1.839,14.033-6.518,19.796c-0.816,1.005-1.92,1.692-3.229,2.01c-3.857,0.937-7.537,2.315-10.806,4.637
				c-0.539-0.889,0.021-1.468,0.272-2.07c1.965-4.715,4.816-8.864,8.151-12.704c0.91-1.047,1.807-2.105,2.674-3.188
				c0.845-1.054,0.975-2.228,0.936-3.604c-0.201-6.956,1.009-13.412,7.003-18.008c1.649-1.265,3.143-2.729,4.737-4.066
				c4.31-3.621,7.856-7.82,10.045-13.072C212.339,470.004,213.066,468.719,215.023,468.251z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(160)} fill={props.fillColors[160]} d="M337.126,319.771c-1.866-0.871-2.76,0.407-4.018,1.495
				c-0.464-3.362-1.073-6.367-0.358-9.543c1.02-4.538,1.761-9.138,2.659-13.705c0.171-0.868,0.485-1.714,0.794-2.548
				c1.075-2.91,1.881-5.841,1.325-9.003c-0.133-0.76,0.011-1.582,0.105-2.365c0.299-2.481,0.208-4.938-0.213-7.402
				c-0.433-2.539-0.717-5.082-0.491-7.688c0.236-2.724-0.259-5.372-2.033-7.57c-0.881-1.091-1.154-2.254-1.077-3.587
				c0.25-4.356,0.422-8.707-0.224-13.056c-0.462-3.115,0.637-6.074,1.124-9.094c0.6-3.715,0.929-7.473,1.751-11.154
				c0.211-0.947,0.42-1.876,1.222-2.698c-0.676,4.034,0.236,7.621,2.672,11.007c-0.064,0.686-0.944,0.694-1.46,1.09
				c-2.01,1.541-2.913,3.497-2.23,5.988c0.349,1.272,0.446,2.52,0.306,3.816c-0.052,0.484-0.122,1.083,0.101,1.463
				c1.352,2.307,0.701,4.791,0.783,7.217c0.061,1.812-0.082,3.563-0.839,5.229c-0.505,1.111-0.652,2.282-0.561,3.485
				c0.056,0.742,0.345,1.365,1.168,1.504c0.848,0.144,1.254-0.376,1.526-1.09c0.208-0.545,0.188-1.191,0.942-1.815
				c0.741,1.726,1.487,3.366,2.147,5.041c0.317,0.806-0.078,1.592-0.5,2.248c-0.478,0.744-1.116,1.384-1.695,2.062
				c-0.609,0.713-1.978,1.049-1.591,2.26c0.427,1.335,1.8,1.375,2.978,1.538c2.68,0.373,2.766,0.439,2.872,3.056
				c0.108,2.671,0.812,5.281,0.724,7.981c-0.05,1.52-0.278,2.905-0.982,4.278c-1.036,2.024-1.887,4.143-2.842,6.21
				c-0.936,2.03-1.235,4.154-1.004,6.362c0.03,0.291,0.29,0.654,0.188,0.847c-1.552,2.904-0.493,5.801-0.057,8.709
				c0.211,1.403-0.088,2.78-0.654,4.062C338.925,316.113,338.076,317.787,337.126,319.771z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(161)} fill={props.fillColors[161]} d="M305.407,421.367c-1.265-2.007-3.126-3.333-4.653-4.971
				c-1.825-1.958-3.01-4.342-2.448-7.264c0.397-2.066,0.055-4.15-0.254-6.213c-0.285-1.901-0.293-3.717,0.909-5.411
				c0.745-1.05,1.17-2.334,1.25-3.624c0.315-5.148,1.543-10.183,1.761-15.35c0.184-4.359,1.458-8.582,2.053-12.897
				c0.432-3.138,1.13-6.198,2.221-9.207c0.981-2.706,1.054-5.626,0.91-8.522c-0.157-3.168-0.239-6.339-0.365-9.509
				c-0.096-2.404,0.442-4.515,2.553-5.991c1.233-0.862,1.708-2.138,1.739-3.606c0.044-2.088,0.35-4.142,0.745-6.19
				c0.337-1.744,0.741-3.32,1.968-4.815c1.586-1.932,1.241-4.544,1.084-6.896c-0.165-2.473,0.833-4.346,2.348-6.366
				c0.586,1.312,0.983,2.433,0.903,3.592c-0.325,4.73-0.441,9.532-3.112,13.72c-1.496,2.346-2.125,4.871-2.008,7.629
				c0.06,1.386-0.021,2.777,0.02,4.164c0.079,2.694-0.163,5.3-1.109,7.886c-0.638,1.743-0.774,3.673-1.1,5.525
				c-1.376,7.809-2.622,15.636-2.851,23.581c-0.064,2.222-0.803,4.28-1.322,6.386c-1.005,4.069-1.091,8.23-1.502,12.364
				c-0.442,4.437-1.139,8.844-2.238,13.171c-0.81,3.187-0.856,6.187,0.972,9.153C305.662,414.6,304.911,418.055,305.407,421.367z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(162)} fill={props.fillColors[162]} d="M540.934,502.396c-0.944-2.693-2.694-4.813-2.808-7.617
				c-0.029-0.741-0.447-1.535-0.878-2.176c-0.807-1.201-1.175-2.461-1.172-3.905c0.003-1.088-0.333-2.052-1.137-2.953
				c-1.171-1.313-1.216-2.553,0.219-3.574c2.368-1.685,2.373-4.295,2.593-6.515c0.202-2.037,1.625-3.587,1.518-5.671
				c-0.042-0.81,1.284-1.121,1.908-0.551c1.709,1.562,3.947,1.897,5.96,2.479c3.02,0.872,4.776,2.852,5.062,5.565
				c0.465,4.401,3.244,7.074,6.073,9.837c1.471,1.435,3.342,2.48,4.04,4.62c0.215,0.659,0.91,0.986,1.593,1.239
				c0.818,0.302,1.258,0.924,1.148,2.061c-2.434-0.382-5.108,0.183-6.622-2.664c-0.52-0.979-1.489-0.708-2.372-0.313
				c-1.288,0.576-2.452,0.927-3.833-0.169c-1.03-0.818-2.681-0.732-3.544,0.351c-1.055,1.321-2.237,1.316-3.665,1.274
				c-1.648-0.048-2.888,1.414-2.098,2.642c1.249,1.941,0.367,3.102-0.848,4.397C541.677,501.174,541.404,501.709,540.934,502.396z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(163)} fill={props.fillColors[163]} d="M270.69,353.153c0.074-1.349,0.644-2.291,1.114-3.219
				c5.018-9.895,9.606-20.005,14.915-29.754c0.427-0.784,0.812-1.603,1.336-2.318c3.551-4.852,5.461-10.537,8.095-15.854
				c1.159-2.339,1.614-4.889,2.134-7.408c0.626-3.036,1.647-5.876,3.245-8.564c2.129-3.583,3.795-7.423,5.211-11.332
				c1.127-3.11,2.988-5.577,5.495-7.583c1.852-1.481,3.294-2.854,1.695-5.445c-0.614-0.996,0.076-2.112,0.773-2.999
				c0.856-1.09,1.799-2.119,2.597-3.25c1.282-1.816,2.161-3.819,1.996-6.1c-0.168-2.315,0.542-4.33,1.983-6.064
				c1.845-2.221,2.351-4.713,1.982-7.512c-0.333-2.528,0.253-4.853,2.058-6.87c-0.15,2.324,0.086,4.649-0.23,6.927
				c-0.383,2.756-0.544,5.521-0.724,8.288c-0.169,2.601-0.724,5.059-1.904,7.422c-1.26,2.523-1.637,5.311-1.588,8.105
				c0.037,2.126-0.614,3.914-2.078,5.382c-2.911,2.921-4.499,6.466-5.196,10.48c-0.367,2.115-1.382,3.829-3.09,5.225
				c-6.413,5.242-9.379,12.575-12.11,20.075c-2.675,7.346-5.373,14.689-9.929,21.174c-2.016,2.869-2.992,6.231-3.874,9.583
				c-0.487,1.85-1.133,3.523-2.444,4.998c-1.872,2.105-2.892,4.729-3.751,7.372C277.089,347.952,274.549,350.906,270.69,353.153z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(164)} fill={props.fillColors[164]} d="M212.529,338.296c0.274-3.717-2.163-6.385-4.09-8.886
				c-1.904-2.47-3.256-6.091-7.294-6.491c-0.116-3.189-0.517-6.26-1.983-9.211c1.415-1.279,3.124-1.728,4.868-1.879
				c1.278-0.111,2.204-0.606,3.126-1.45c2.109-1.929,4.396-2.854,7.29-1.41c1.185,0.591,2.741,0.388,4.04,0.814
				c1.299,0.426,2.488,1.185,4.043,1.956c-0.031,3.486,1.813,6.503,3.76,9.536c-0.914,0.368-1.852,0.101-2.574,0.705
				c-0.777,0.651-1.642,1.269-1.243,2.442c0.526,1.552-0.254,2.141-1.581,2.531c-3.019,0.889-3.819,1.975-3.836,5.026
				c-0.002,0.396,0.003,0.793-0.02,1.188C216.81,337.076,216.808,337.076,212.529,338.296z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(165)} fill={props.fillColors[165]} d="M323.789,252.327c0.421,2.279,0.715,4.032,1.074,5.771
				c0.707,3.425-0.099,6.501-2.211,9.213c-1.09,1.4-1.613,2.864-1.584,4.648c0.047,2.916-1.147,5.598-1.82,8.378
				c-0.318,1.312-0.984,2.601-0.323,3.963c0.271,0.044,0.56,0.168,0.633,0.09c2.26-2.417,4.827-4.643,5.966-7.866
				c1.321-3.74,2.629-7.442,5.042-10.682c0.843-1.132,1.216-2.614,1.857-4.057c1.278,1.016,1.201,2.19,0.941,3.325
				c-1.241,5.404-2.805,10.722-4.46,16.009c-0.695,2.221-2.121,4.017-4.072,5.347c-3.279,2.236-5.194,5.429-6.126,9.184
				c-1.227,4.942-2.927,9.638-5.879,13.85c-1.368,1.952-2.036,4.284-3.026,6.441c-0.639,1.393-1.26,2.884-3.332,3.303
				c-0.381-6.922-1.035-13.643,4.066-19.209c1.441-1.572,2.21-3.407,2.516-5.565c0.457-3.23,1.201-6.42,1.86-9.619
				c0.346-1.681,0.183-3.352,0.047-5.031c-0.404-4.977,0.83-9.444,4.286-13.14c2.057-2.2,3.25-4.617,3.062-7.736
				C322.181,256.883,322.6,254.826,323.789,252.327z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(166)} fill={props.fillColors[166]} d="M420.124,547.932c-4.369-1.341-8.237-2.421-10.519-6.484
				c-3.888-6.928-9.715-11.668-17.165-14.4c-1.395-0.512-2.784-0.992-4.105-1.707c-1.803-0.974-2.316-0.815-3.565,0.693
				c-1.284,1.552-2.138,3.341-2.675,5.267c-0.513,1.84-0.182,3.512,1.145,4.87c1.582,0.351,2.061-1.616,3.58-1.459
				c1.009,0.696,0.786,1.856,0.859,2.896c0.277,3.933,1.443,5.041,5.441,4.855c2.703-0.126,5.349,0.264,7.988,0.674
				c1.869,0.291,3.697,0.988,5.176,2.158c4.599,3.643,9.785,6.4,14.524,9.824c3.736,2.698,6.421,6.106,7.355,10.73
				c-0.174,0.452-0.519,0.217-0.707,0.081c-4.823-3.494-9.975-6.48-14.988-9.679c-2.089-1.331-4.169-2.658-5.899-4.487
				c-1.352-1.427-3.213-1.928-5.097-2.205c-4.019-0.593-8.06-1.039-12.064-1.71c-8.177-1.37-12.818-11.154-8.782-18.39
				c0.531-0.951,1.089-1.891,1.679-2.807c1.712-2.657,2.898-3.227,5.992-2.455c4.917,1.227,9.678,2.917,14.091,5.464
				c3.976,2.295,7.07,5.415,8.85,9.712c1.112,2.686,2.889,4.696,5.596,5.861C418.004,545.738,419.159,546.316,420.124,547.932z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(167)} fill={props.fillColors[167]} d="M441.414,207.622c-1.285-2.517-4.069-2.513-6.552-3.403
				c0.969-1.926,1.371-3.607-0.556-5.083c1.476-0.787,2.298-1.759,2.658-3.208c0.502-2.014,2.769-2.298,3.997-3.668
				c0.824-0.919,2.293-1.144,3.014-2.323c0.131-0.215,0.511-0.452,0.731-0.415c3.005,0.507,4.356-1.767,5.938-3.609
				c1.569,0.367,1.48,1.316,1.262,2.512c-0.35,1.903-0.051,2.083,1.885,2.337c2.706,0.357,5.162,1.143,6.072,4.203
				c0.299,1.005,1.196,1.767,2.166,1.858c2.342,0.219,4.194,2.121,6.879,1.753c-3.886,1.489-4.265,4.194-2.864,7.521
				c-6.593-0.763-6.012,0.142-6.738,3.725c-0.835,0.204-0.938-0.524-1.178-0.989c-1.77-3.425-2.752-3.721-5.898-1.719
				c-0.167,0.106-0.304,0.262-0.476,0.355c-1.571,0.853-3.139,0.65-4.207-0.798c-0.727-0.984-1.533-1.806-2.562-2.409
				c-1.431-0.839-2.145-0.537-2.548,1.08C442.251,206.087,442.454,206.96,441.414,207.622z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(168)} fill={props.fillColors[168]} d="M566.633,511.456c-0.327-2.289-2.854-2.511-4.146-4.102
				c-0.724-0.891-2.446-0.101-3.679,0.182c-0.848,0.194-1.582,0.807-2.536,0.681c-2.667-0.352-3.941,1.321-4.926,3.415
				c-0.244,0.52-0.37,1.094-0.556,1.621c-0.91,2.6-3.417,2.84-5.468,4.232c-0.606-1.822-0.977-3.454-1.815-4.889
				c-0.313-0.535-0.329-1.085-0.28-1.704c0.179-2.269,0.04-4.524-0.564-6.731c-0.304-1.105-0.348-2.138,0.543-3.032
				c1.658-1.664,2.101-3.507,0.692-5.415c0.309-0.682,0.711-0.876,1.263-0.847c1.586,0.082,3.164,0.273,4.154-1.496
				c0.34-0.605,1.397-0.829,1.882-0.427c2.023,1.677,4.048,0.814,6.209,0.021c0.992,2.527,3.137,2.89,5.328,3.125
				c1.164,0.124,1.939,0.639,2.62,1.649c2.555,3.796,4.748,7.89,8.772,10.792C571.922,510.364,570.085,512.267,566.633,511.456z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(169)} fill={props.fillColors[169]} d="M315.243,564.72c0.049,1.244-0.488,2.26-1.257,3.173
				c-1.173,1.394-1.637,3.152-2.46,4.726c-0.545,1.042-0.197,2.104-0.02,3.188c0.601,3.684,2.908,6.44,4.981,9.31
				c2.534,3.508,4.555,7.177,4.602,11.661c0.019,1.779,0.905,3.252,2.061,4.501c2.558,2.764,4.455,6.062,7.184,8.691
				c1.711,1.651,2.119,3.773,1.49,6.038c-0.366,1.316-0.902,2.585-1.379,3.92c-1.252-0.48-1.318-1.312-1.031-2.211
				c1.096-3.433,0.068-6.136-2.548-8.518c-1.602-1.459-2.942-3.213-4.345-4.88c-1.991-2.367-4.636-3.14-7.485-2.597
				c-5.157,0.982-10.34,1.766-15.523,2.573c-2.277,0.355-4.286,1.503-6.322,2.516c-1.008,0.502-1.629,1.417-2.138,2.404
				c-0.767,1.487-1.55,2.966-2.651,5.068c0.388-3.695,1.208-6.604,1.933-9.515c0.405-1.632,1.932-2.455,3.27-3.3
				c1.713-1.082,3.694-1.387,5.637-1.76c2.527-0.485,5.064-0.914,7.592-1.396c1.173-0.224,2.302-0.604,3.311-1.267
				c2.025-1.329,2.799-3.34,2.102-5.668c-0.427-1.428-1.132-2.718-2.062-3.913c-3.349-4.303-4.221-9.295-3.584-14.544
				C306.985,569.733,311.722,564.28,315.243,564.72z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(170)} fill={props.fillColors[170]} d="M438.068,608.72c2.284,1.369,3.5,3.162,4.129,5.257
				c1.771,5.905,4.355,11.479,6.73,17.14c1.112,2.651,2.306,5.226,3.774,7.722c1.711,2.905,2.696,6.256,2.822,9.613
				c0.181,4.8,1.174,9.427,2.12,14.084c0.476,2.337,0.648,4.69,0.211,7.172c-1.259-2.862-3.979-4.651-5.099-7.627
				c-0.945-2.515-2.362-4.829-4.587-6.462c-0.85-0.622-1.047-1.355-1.03-2.29c0.025-1.389-0.024-2.779,0.037-4.165
				c0.221-4.959-1.813-9.041-4.95-12.663c-3.813-4.405-5.075-9.396-4.174-15.159C438.679,617.337,438.314,613.25,438.068,608.72z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(171)} fill={props.fillColors[171]} d="M281.607,386.854c-1.486,7.432-2.526,14.938-4.964,22.03
				c-1.681,4.887-2.352,9.763-2.569,14.813c-0.086,1.995-0.507,3.961-1.357,5.752c-1.614,3.4-2.244,6.994-2.122,10.684
				c0.157,4.738-0.705,9.247-2.33,13.666c-1.795,4.885-2.578,9.935-2.482,15.152c0.055,2.936,0.187,5.915-0.881,9.299
				c-2.337-4.01-4.157-7.608-2.881-12.05c1.214-4.226,1.969-8.491,0.675-12.87c-0.536-1.811-1.186-3.57-2.007-5.266
				c-1.089-2.25-1.19-4.552-0.542-6.941c0.885-3.265,2.007-6.426,3.82-9.304c1.082-1.719,2.421-3.04,4.491-3.643
				c2.015-0.586,2.364-1.322,1.471-3.31c-0.677-1.509-0.376-2.651,0.494-3.828c1.748-2.367,2.523-5.064,3.123-7.925
				c1.647-7.849,3.725-15.593,6.456-23.146C280.403,388.864,280.708,387.703,281.607,386.854z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(172)} fill={props.fillColors[172]} d="M382.561,97.438c-1.379-0.205-2.145-1.546-3.383-2.108
				c-1.46-0.664-2.289,1.087-3.849,0.741c-0.479-2.316-2.53-3.06-4.536-3.745c-2.406-0.822-3.511,1.279-4.85,2.66
				c-1.164-0.828-0.998-1.892-1.099-2.825c-0.284-2.655-0.564-5.312-0.754-7.975c-0.251-3.537-1.292-6.779-3.313-9.703
				c-0.276-0.4-0.459-0.865-0.684-1.298c1.149-1.215,2.85-0.874,4.076-1.643c0.411-0.527,0.302-1.15,0.418-1.722
				c0.641-3.144,4.099-4.42,6.532-2.26c1.012,0.897,1.917,1.141,3.177,1.071c3.154-0.174,5.57,2.907,4.695,6.033
				c-0.393,1.4,0.012,1.931,1.337,2.347c1.817,0.571,2.104,1.279,1.013,2.737c-1.048,1.399-1.405,3.328-3.137,4.24
				c-1.211,0.067-1.898-0.979-2.896-1.349c-0.905-0.336-1.741-0.359-2.463,0.376c-0.595,0.604-0.35,1.241-0.098,1.918
				c0.796,2.143,2.096,4.042,2.603,6.207c1.651-0.104,2.37-1.168,3.337-1.81c2.095-1.39,3.596-0.675,3.717,1.816
				c0.081,1.651,1.209,3.147,0.673,4.885C382.942,96.475,383.137,97.021,382.561,97.438z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(173)} fill={props.fillColors[173]} d="M322.814,523.058c0.077-5.94,1.303-11.227,2.835-16.456
				c0.809-2.757,1.842-5.434,2.944-8.091c0.84-2.027,2.055-3.63,4.183-4.339c1.506-0.502,2.223-1.479,2.383-3.037
				c0.121-1.177,0.423-2.336,0.657-3.5c0.179-0.891,0.22-1.776-0.124-2.633c-0.773-1.929-1.495-3.882-2.37-5.764
				c-0.827-1.778-2.521-2.68-4.406-2.23c-3.345,0.797-5.518,2.351-5.3,6.364c0.091,1.674,0.407,3.343,0.72,4.994
				c0.408,2.152,0.606,4.322,0.639,6.5c0.07,4.8-0.979,9.191-4.421,12.851c-2.249,2.392-4.089,5.111-4.671,8.478
				c-0.196,1.138-0.921,1.974-1.798,2.649c-2.437,1.88-3.703,4.413-3.875,7.406c-0.255,4.445-1.837,8.714-1.593,13.229
				c0.085,1.576-1.683,2.304-2.044,4.368c-0.867-2.001-0.795-3.393-0.83-4.755c-0.097-3.773,0.322-7.522,0.611-11.277
				c0.25-3.24,1.37-6.189,3.292-8.792c1.883-2.549,3.848-5.039,5.829-7.515c2.252-2.814,4.08-5.83,5.013-9.356
				c0.302-1.141,0.696-2.31,1.309-3.305c2.012-3.267,2.042-6.736,1.382-10.343c-0.554-3.02-1.112-6.044-1.065-9.138
				c0.074-4.923,3.783-8.52,8.758-8.449c2.292,0.032,4.046,1.004,4.902,3.187c1.264,3.223,2.334,6.521,3.906,9.621
				c0.564,1.114,0.388,2.287,0.125,3.484c-0.531,2.416-1.039,4.844-1.375,7.291c-0.171,1.252-0.692,2.037-1.829,2.417
				c-6.964,2.326-9.385,8.002-10.732,14.487c-0.567,2.729-0.724,5.496-0.869,8.269C324.917,521.25,324.885,521.249,322.814,523.058z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(174)} fill={props.fillColors[174]} d="M446.166,153.417c1.387-2.019,3.135-2.791,5.435-2.173
				c3.097,0.832,5.874,4.412,5.694,7.656c-0.062,1.134,0.331,2.121,0.526,3.055c-0.411,1.225-1.75,1.047-2.342,1.865
				c-0.578,0.8-1.165,1.594-1.78,2.434c-1.519,0.202-2.925-0.527-4.405-0.502c-0.407,0.007-0.893-0.101-1.015-0.484
				c-0.769-2.413-2.354-2.258-4.132-1.229c-0.918-1.145-0.449-2.17-0.224-3.213c0.383-1.778-0.754-3.362-2.891-4.134
				c-2.043-0.738-2.76-0.485-3.76,1.5c-0.324,0.645-0.52,1.293-1.681,1.197c0.79-2.291-0.117-3.945-1.725-5.626
				c-1.841-1.925-2.707-2.266-5.738-0.715c-0.914-1.059-1.903-2.191-3.408-2.535c-1.43-0.327-1.354-1.447-1.572-2.517
				c1.321-0.939,3.291-1.038,3.323-3.127c0.008-0.489,0.464-0.54,0.858-0.625c1.913-0.417,3.006-1.749,3.796-3.769
				c1.823,1.854,3.54,1.737,5.553,0.413c1.047-0.688,2.579-1.022,3.985-0.256c0.829,0.451,1.852,1.121,1.625,1.921
				c-0.773,2.732,1.622,5.16,0.522,7.919C442.468,151.334,443.293,151.916,446.166,153.417z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(175)} fill={props.fillColors[175]} d="M225.677,553.827c1.068-2.938,0.471-5.67-0.726-8.375
				c-0.713-1.612-0.333-3.326-0.07-4.987c0.544-3.444,0.647-6.904,0.457-10.377c-0.122-2.21-0.56-4.329-1.958-6.129
				c-0.438-0.564-0.797-1.103-0.573-1.885c1.471-5.139,2.688-10.383,6.152-14.685c0.497-0.617,1.049-1.189,1.54-1.812
				c0.359-0.456,0.802-0.907,0.571-1.63c-0.44-0.753-1.113-0.554-1.803-0.383c-2.451,0.608-4.164,2.223-5.624,4.154
				c-2.217,2.933-3.986,6.149-5.801,9.335c-0.734,1.289-1.514,2.552-2.267,3.815c-1.621,0.428-3.137,0.033-5.133,0.156
				c4.587-6.602,7.972-13.314,12.099-19.578c1.334-2.024,3.093-3.6,5.433-4.438c3.542-1.27,7.093-2.523,10.374-4.412
				c0.232-0.134,0.572-0.08,0.901-0.117c0.685,0.968,0.236,1.897-0.053,2.81c-0.689,2.179-1.623,4.26-2.67,6.282
				c-1.366,2.64-2.706,5.301-4.237,7.844c-3.104,5.158-4.465,10.751-4.542,16.713c-0.047,3.668-0.082,7.338-0.79,10.957
				c-0.351,1.795,0.203,3.305,1.312,4.694c0.987,1.237,2.04,2.447,2.843,3.8c1.124,1.893,0.925,2.961-0.616,4.488
				c-1.391,1.38-2.852,2.688-4.282,4.027C226.036,554.007,225.856,553.917,225.677,553.827z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(176)} fill={props.fillColors[176]} d="M268.963,627.395c0.613,0.764,0.254,1.576,0.333,2.328
				c0.051,0.491-0.164,1.228,0.09,1.446c2.084,1.789,0.672,3.819,0.468,5.738c-0.217,2.024-1.172,3.669-2.586,5.189
				c-3.582,3.852-6.874,7.951-9.674,12.423c-0.202,0.323-0.492,0.592-0.784,0.935c-1.493-0.326-2.925-0.712-4.382-0.942
				c-3.948-0.623-8.141,0.983-9.036,5.641c-0.351,1.825-1.716,3.361-1.424,5.273c-0.393,0.11-0.594,0.243-0.739,0.197
				c-3.725-1.169-4.168-1.801-2.663-5.256c1.847-4.241,4.72-7.239,9.874-7.193c3.144,0.028,4.337-2.443,2.611-5.111
				c-1.271-1.963-3.241-3.171-5.101-4.491c-3.492-2.48-4.789-6.919-3.065-11.518c0.223,2.993,1.633,4.738,3.899,5.806
				c2.792,1.315,5.677,1.705,8.663,0.73c4.811-1.57,8.555-4.482,11.177-8.84C267.195,628.803,267.471,627.461,268.963,627.395z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(177)} fill={props.fillColors[177]} d="M170.059,590.817c0.357-1.748,1.043-3.146,1.745-4.511
				c5.431-10.578,11.266-20.95,16.128-31.817c0.549-1.228,1.091-2.551,2.334-3.312c1.046,0.62,0.79,2.166,2.122,2.511
				c1.287-0.062,1.727-1.165,2.093-2.144c1.8-4.814,4.322-9.366,5.264-14.497c0.323-1.762,0.914-3.454,1.815-5.011
				c0.762-1.315,0.889-2.477-0.082-3.839c-1.25-1.754-1.02-2.344,0.712-3.608c3.763-2.75,7.955-2.331,12.271-1.984
				c-0.294,1.063-1.043,1.247-1.567,1.667c-4.896,3.931-8.433,8.54-8.591,15.231c-0.118,4.962-2.336,9.157-5.764,12.713
				c-0.275,0.285-0.531,0.596-0.834,0.848c-6.982,5.815-11.495,13.305-14.564,21.724c-2.369,6.495-6.881,11.263-12.073,15.53
				C170.856,590.492,170.565,590.571,170.059,590.817z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(178)} fill={props.fillColors[4]} d="M215.631,634.713c-1.732,0.034-3.394-0.378-4.778-1.358
				c-2.164-1.531-4.519-1.82-7.053-1.651c-2.17,0.144-4.33,0.121-6.49-0.355c-3.176-0.702-4.228-3.28-5.409-5.786
				c-0.04-0.085-0.056-0.199-0.038-0.291c0.497-2.547,1.804-4.806,3.167-6.882c0.987-1.504,3.098-2.018,5.019-2.162
				c0.572-0.043,1.261-0.007,1.729,0.273c2.795,1.671,6.026,2.536,8.578,4.65c1.564,1.294,3.348,2.069,5.382,2.268
				c2.164,0.212,4.163-0.056,5.649-1.86c0.806-0.978,1.891-1.401,3.081-1.613c0.496-0.088,1.006-0.017,1.295,0.479
				c0.288,0.494,0.196,1.046-0.257,1.312c-2.992,1.759-2.97,4.912-3.688,7.743C220.907,633.076,218.949,634.694,215.631,634.713z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(179)} fill={props.fillColors[179]} d="M135.078,570.498c4.962-19.282,16.181-29.728,32.044-33.363
				c-1.645,4.124-3.288,7.953-5.733,11.324c-1.409-0.039-0.498-1.768-2.112-1.871c-0.946,0.184-1.319,1.435-1.884,2.402
				c-2.304,3.945-4.185,8.097-5.852,12.348c-0.723,1.845-1.438,3.692-2.446,5.412c-0.775,1.322-1.856,2.238-3.406,1.717
				c-2.471-0.831-4.668-0.169-6.902,0.776C137.7,569.703,136.548,570.008,135.078,570.498z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(180)} fill={props.fillColors[180]} d="M313.093,177.859c0.984-1.326-0.594-2.323-0.141-3.646
				c1.246-0.785,2.557-0.372,3.741,0.358c2.825,1.742,3.639,1.664,5.949-0.895c2.711,1.869,5.176,3.857,6.299,7.223
				c0.579,1.733,1.817,3.246,2.751,4.847c-2.434,2.057-2.566,2.488-1.662,5.261c0.681,2.086-0.148,4.183-2.21,5.594
				c-1.518,1.038-4.011,0.942-5.566-0.343c-0.981-0.811-1.777-1.761-3.099-2.19c-0.966-0.314-1.2-1.531-1.459-2.478
				c-0.417-1.521-1.455-2.101-2.889-1.974c-1.228,0.109-2.304,0.006-3.349-0.757c-1.44-1.05-2.855,0.081-4.27,0.256
				c-2.225-2.288-1.984-4.961,0.546-6.307c0.919-0.488,2.256-0.288,2.685-1.588c0.358-1.088-0.477-2.07-0.45-3.147
				C311.031,177.481,312.024,178.404,313.093,177.859z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(181)} fill={props.fillColors[181]} d="M502.629,426.739c1.946-1.344,4.045-1.235,6.082-0.813
				c7.426,1.54,14.46,4.069,20.693,8.535c1.893,1.355,3.443,2.974,4.302,5.167c0.956,2.444,0.24,4.003-2.241,4.86
				c-1.923,0.664-3.954,0.644-5.878,0.293c-8.714-1.589-16.108-5.364-20.89-13.21C503.778,430.062,502.873,428.535,502.629,426.739z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(182)} fill={props.fillColors[182]} d="M283.871,516.15c-0.404,2.009-1.364,3.311-2.883,4.235
				c-0.506,0.308-0.97,0.687-1.447,1.041c-0.596,0.442-1.021,1.018-0.773,1.772c0.256,0.781,0.966,0.905,1.703,0.878
				c0.571-0.021,1.174-0.325,1.691,0.171c-0.071,7.38-3.644,11.899-8.529,13.001c-0.157-0.099-0.393-0.16-0.459-0.301
				c-0.348-0.739,0.132-1.905-1.101-2.18c-0.716-0.158-0.981,0.229-1.913,2.623c-0.514,0.418-1.108,0.317-1.704,0.285
				c-3.464-0.185-6.23,1.17-8.029,4.098c-0.815,1.326-1.74,1.935-3.242,2.157c-3.03,0.449-5.974,1.363-9.072,1.51
				c-2.354,0.112-4.248,1.507-6.484,3.024c0.212-1.21,0.977-1.657,1.423-2.266c2.167-2.955,4.38-5.877,6.525-8.848
				c0.75-1.038,1.535-1.799,2.94-1.476c1.479,0.341,2.713-0.074,3.762-1.194c0.394-0.421,0.932-0.896,1.458-0.974
				c3.549-0.517,6.871-2.463,10.67-1.511c2.097,0.525,2.999-0.516,2.598-2.6c-0.47-2.441-0.469-2.434,2.124-2.675
				c0.277-0.026,0.541-0.202,0.719-0.272c0.924-1.136-0.551-1.847-0.309-2.993c1.662-1.882,3.713-3.607,5.341-5.742
				C279.909,516.571,281.125,516.306,283.871,516.15z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(183)} fill={props.fillColors[183]} d="M664.329,168.21c-1.021-0.364-1.107-0.91-1.316-1.33
				c-3.742-7.536-7.451-15.09-11.215-22.616c-0.562-1.125-0.83-2.051-0.19-3.306c3.999-7.851,7.905-15.748,11.853-23.625
				c0.08-0.159,0.276-0.259,0.855-0.78C664.43,133.943,664.292,150.944,664.329,168.21z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(184)} fill={props.fillColors[184]} d="M664.068,228.173c-0.961-0.637-1.126-1.44-1.467-2.121
				c-3.59-7.171-7.144-14.36-10.779-21.508c-0.634-1.246-0.806-2.24-0.129-3.571c3.859-7.589,7.614-15.231,11.414-22.851
				c0.199-0.398,0.259-0.971,0.961-0.992C664.068,194.111,664.068,211.046,664.068,228.173z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(185)} fill={props.fillColors[185]} d="M206.771,561.247c1.586,2.222,1.492,4.487,0.605,6.811
				c-0.493,1.292-1.148,2.529-1.549,3.847c-0.519,1.704-0.737,3.462-0.56,5.272c0.164,1.669,0.276,3.359,0.205,5.031
				c-0.167,3.896-1.325,7.335-4.612,9.842c-1.723,1.314-3.255,2.885-4.836,4.377c-1.262,1.191-2.781,1.904-4.403,2.355
				c-2.108,0.588-3.235-0.362-3.13-2.591c0.076-1.604,0.57-3.118,1.476-4.452c2.99-4.405,4.736-9.248,5.759-14.479
				c1.218-6.231,4.818-11.187,9.412-15.451C205.514,561.459,205.961,561.276,206.771,561.247z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(186)} fill={props.fillColors[186]} d="M19.443,168.959c0-17.739,0-35.167,0-52.665c0.889,0.202,0.952,0.975,1.234,1.538
				c3.771,7.519,7.491,15.062,11.318,22.552c0.746,1.459,0.792,2.604,0.03,4.1c-3.771,7.407-7.407,14.882-11.109,22.324
				C20.585,167.474,20.386,168.26,19.443,168.959z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(187)} fill={props.fillColors[187]} d="M19.467,236.044c0.962,0.237,1.028,1.017,1.311,1.581
				c3.777,7.519,7.526,15.053,11.295,22.576c0.54,1.077,0.941,1.988,0.272,3.303c-4.039,7.941-7.953,15.945-11.917,23.924
				c-0.164,0.33-0.312,0.732-0.961,0.716C19.467,270.787,19.467,253.447,19.467,236.044z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(188)} fill={props.fillColors[188]} d="M19.513,468.164c0-17.7,0-34.893,0-52.102c0.86-0.269,0.889,0.487,1.119,0.949
				c3.843,7.698,7.662,15.407,11.53,23.093c0.572,1.136,0.728,2.082,0.105,3.306c-4.031,7.935-7.978,15.912-11.959,23.872
				C20.224,467.45,20.05,467.574,19.513,468.164z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(189)} fill={props.fillColors[189]} d="M19.603,587.126c0-17.29,0-34.371,0-51.478c1.04-0.005,0.997,0.92,1.267,1.456
				c3.778,7.52,7.482,15.077,11.29,22.582c0.656,1.293,0.628,2.296-0.021,3.571c-3.86,7.589-7.635,15.221-11.443,22.836
				C20.525,586.435,20.451,586.86,19.603,587.126z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(190)} fill={props.fillColors[190]} d="M19.543,296.198c0.86,0.189,0.902,0.863,1.15,1.36
				c3.802,7.616,7.583,15.243,11.404,22.849c0.556,1.106,0.875,2.031,0.227,3.306c-4.037,7.94-7.974,15.931-11.958,23.898
				c-0.116,0.232-0.391,0.385-0.824,0.794C19.543,330.839,19.543,313.54,19.543,296.198z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(191)} fill={props.fillColors[191]} d="M19.6,355.45c1.203,1.327,1.606,2.67,2.216,3.879
				c3.438,6.812,6.822,13.65,10.254,20.465c0.551,1.094,0.927,2.018,0.272,3.309c-4.028,7.955-7.954,15.963-11.926,23.947
				c-0.121,0.244-0.371,0.424-0.817,0.919C19.6,390.387,19.6,373.123,19.6,355.45z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(192)} fill={props.fillColors[192]} d="M19.898,176.305c4.369,8.785,8.682,17.455,13.096,26.33
				c-4.315,8.658-8.667,17.389-13.121,26.324C19.052,226.114,19.093,178.438,19.898,176.305z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(193)} fill={props.fillColors[193]} d="M20.033,475.377c1.373,2.719,2.753,5.435,4.116,8.159
				c2.658,5.315,5.286,10.646,7.975,15.945c0.569,1.121,0.81,2.055,0.174,3.309c-3.942,7.772-7.79,15.592-11.666,23.397
				c-0.172,0.347-0.288,0.732-0.977,0.852c0-17.192,0-34.35,0-51.506C19.781,475.48,19.907,475.429,20.033,475.377z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(194)} fill={props.fillColors[194]} d="M387.811,485.87c2.899,2.295,4.664,4.995,5.84,8.045
				c1.207,3.128,2.444,6.259,3.169,9.557c0.647,2.947,2.192,5.355,4.006,7.801c3.389,4.568,7.29,8.923,8.656,14.712
				c0.088,0.374,0.131,0.758,0.313,1.837c-4.585-3.648-9.79-4.988-14.208-7.866c-2.705-1.762-4.482-4.035-4.895-7.379
				c-0.29-2.355-1.185-4.603-2.397-6.658c-1.281-2.173-1.273-4.332-0.153-6.497c1.198-2.314,1.434-4.572,0.599-7.131
				C388.118,490.381,388.13,488.264,387.811,485.87z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(195)} fill={props.fillColors[195]} d="M309.322,268.12c-0.381-1.548,0.118-2.576,0.496-3.56
				c1.467-3.818,2.124-7.822,2.885-11.806c0.688-3.598,1.605-7.114,3.139-10.476c0.731-1.603,1.299-3.374,1.476-5.115
				c0.361-3.561,1.262-7.012,1.784-10.529c0.294-1.984,0.796-3.851,1.499-5.71c1.648-4.357,2.977-8.788,3.503-13.462
				c0.286-2.539,1.085-5.02,1.646-7.497c0.916-0.598,1.81-0.925,2.813-0.848c0.479,0.658,0.268,1.364,0.228,2.027
				c-0.167,2.775-0.279,5.575,0.134,8.308c0.604,4.001-0.154,7.656-1.994,11.177c-0.596,1.141-1.115,2.332-1.249,3.633
				c-0.136,1.323-0.67,2.472-1.411,3.547c-1.698,2.466-2.419,5.196-2.073,8.166c0.303,2.598-0.276,4.869-1.98,6.911
				c-1.368,1.638-2.144,3.599-2.005,5.765c0.191,2.988-1.082,5.369-2.864,7.58c-0.498,0.617-1.048,1.19-1.536,1.814
				c-1.103,1.412-1.718,2.932-0.699,4.647c0.708,1.192,0.298,2.05-0.658,2.828C311.474,266.321,310.505,267.139,309.322,268.12z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(196)} fill={props.fillColors[196]} d="M664.07,236.823c0,16.741,0,33.524,0,50.328c-1.113,0.018-1.123-0.841-1.397-1.392
				c-3.666-7.357-7.296-14.731-10.977-22.081c-0.522-1.043-0.699-1.919-0.128-3.044c3.943-7.771,7.821-15.575,11.721-23.368
				C663.417,237.01,663.537,236.739,664.07,236.823z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(197)} fill={props.fillColors[197]} d="M273.215,479.357c-1.134-3.057-1.269-6.014-0.742-8.944
				c0.42-2.339,1.884-4.155,3.837-5.468c1.926-1.295,3.384-2.96,4.809-4.78c2.178-2.782,4.859-5.033,8.742-4.799
				c2.157,0.13,4.179,1.141,6.414,1.028c0.556-0.027,1.243,0.598,1.71,1.077c2.211,2.267,4.401,4.557,6.537,6.894
				c3.379,3.698,3.511,7.938,2.071,12.39c-0.751,0.201-1.036-0.233-1.278-0.657c-0.727-1.271-1.855-1.793-3.246-1.615
				c-1.393,0.178-2.261-0.432-2.897-1.543c0.967-6.674-4.382-11.391-11.012-9.735c-3.34,0.834-6.135,2.773-8.903,4.654
				c-1.791,1.218-2.421,3.274-2.528,5.48C276.604,475.899,275.096,477.729,273.215,479.357z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(198)} fill={props.fillColors[198]} d="M663.881,536.587c0,16.526,0,33.053,0,49.586c-0.93,0.242-0.921-0.57-1.137-1
				c-3.686-7.348-7.34-14.711-11.016-22.064c-0.448-0.896-0.85-1.68-0.305-2.761c4.005-7.944,7.948-15.919,11.911-23.884
				C663.517,536.505,663.699,536.546,663.881,536.587z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(199)} fill={props.fillColors[199]} d="M663.393,406.793c-1.093-2.176-2.195-4.347-3.275-6.528
				c-2.764-5.582-5.497-11.179-8.288-16.747c-0.554-1.105-0.911-2.028-0.259-3.306c3.865-7.572,7.634-15.194,11.443-22.795
				c0.123-0.246,0.337-0.445,0.526-0.69C664.208,358.793,664.462,399.67,663.393,406.793z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(200)} fill={props.fillColors[200]} d="M663.988,296.572c0,17.285,0,33.894,0,50.479c-0.798,0.305-0.873-0.347-1.053-0.705
				c-3.773-7.521-7.508-15.062-11.306-22.571c-0.548-1.084-0.561-1.955-0.01-3.045c3.834-7.602,7.604-15.236,11.403-22.855
				C663.148,297.625,663.357,297.417,663.988,296.572z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(201)} fill={props.fillColors[201]} d="M663.655,467.397c-4.229-8.462-8.157-16.342-12.109-24.211
				c-0.428-0.852-0.546-1.597-0.089-2.507c3.989-7.943,7.939-15.907,11.981-24.026C664.132,418.874,664.341,462.561,663.655,467.397z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(202)} fill={props.fillColors[202]} d="M360.537,181.849c-0.788,0.888-1.595,1.408-2.559,1.72
				c-1.163-0.614-0.748-2.255-2.079-2.943c-1.367-0.034-2.636,0.869-4.039,1.263c-1.215,0.342-2.295,0.545-3.276-0.504
				c-0.659-0.705-1.531-0.806-2.467-0.725c-3.749,0.324-3.749,0.313-6.371-2.677c-0.238-0.77,0.315-1.3,0.634-1.886
				c0.562-1.029,0.743-2.059,0.089-3.09c-0.987-1.559-0.118-2.613,0.941-3.607c1.334-1.25,3.086-1.675,5.609-1.413
				c0.722-0.758,0.214-1.438-0.113-2.155c-0.993-2.179-0.23-4.029,2.009-4.863c0.647-0.241,1.318-0.44,1.995-0.573
				c3.838-0.753,4.411-0.276,4.772,3.582c0.304,3.257,0.896,6.447,2.125,9.524C358.901,176.238,359.923,179.021,360.537,181.849z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(203)} fill={props.fillColors[203]} d="M663.897,475.795c0,17.263,0,33.859,0,50.708c-0.963-0.508-1.133-1.315-1.455-1.962
				c-3.568-7.183-7.096-14.386-10.689-21.556c-0.575-1.147-0.722-2.082-0.1-3.306c3.818-7.499,7.548-15.044,11.314-22.57
				C663.094,476.854,663.297,476.637,663.897,475.795z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(204)} fill={props.fillColors[204]} d="M314.697,456.068c0.238-4.611,0.311-8.58,1.235-12.421
				c1.518-6.311,2.009-12.714,2.076-19.16c0.048-4.551,0.292-9.114-0.377-13.652c-0.339-2.296,0.382-4.525,0.554-6.791
				c0.644-8.502,0.841-17.022,1.159-25.539c0.092-2.469,0.168-4.938,0.655-7.943c1.228,2.798,2.327,5.08,3.231,7.438
				c0.676,1.762,0.805,3.582,0.489,5.532c-1.14,7.038-2.202,14.093-3.059,21.17c-0.355,2.932-0.154,5.936-0.136,8.905
				c0.008,1.322,0.688,2.465,1.267,3.615c2.019,4.009,2.687,8.052,0.425,12.22c-0.324,0.597-0.539,1.294-0.631,1.969
				c-0.733,5.388-2.248,10.673-2.223,16.159c0.009,1.888-0.526,3.459-1.85,4.839C316.635,453.321,315.95,454.418,314.697,456.068z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(205)} fill={props.fillColors[205]} d="M389.558,137.503c0.102-0.992,0.519-1.565,1.18-2.111
				c2.26-1.866,1.979-4.432-0.57-5.886c-1.722-0.982-3.418-2.007-5.508-2.007c-0.51,0-0.952-0.265-0.886-1.197
				c2.81,0.376,4.671-0.702,4.771-3.71c1.12-0.581,2.196-0.734,3.262-0.925c0.925-0.167,1.748-0.137,2.279,0.858
				c0.572,1.072,1.557,1.147,2.575,0.788c1.948-0.687,4.635-0.463,4.443-3.694c1.561-0.393,2.892-0.409,4.164,0.344
				c0.861,0.51,1.473,0.956,1.391,2.278c-0.155,2.502,2.099,3.986,4.335,3.122c1.786-0.69,2.632-0.457,3.868,0.987
				c1.043,1.217,0.74,2.357-0.114,3.493c-0.36,0.478-0.913,1.058-0.626,1.583c1.096,1.999-0.411,3.515-0.668,5.386
				c-1.934-0.067-3.604-0.52-5.212-1.213c-0.144-0.328-0.329-0.586-0.363-0.863c-0.247-1.977-0.764-3.774-3.114-4.099
				c-2.577-0.355-5.137-0.067-6.676,2.228c-1.038,1.547-1.915,2.903-3.93,3.104c-0.604,0.061-0.899,0.691-1.273,1.164
				C392.016,138.234,390.924,138.315,389.558,137.503z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(206)} fill={props.fillColors[206]} d="M333.375,187.941c1.09,0.67,0.885,1.627,0.875,2.494
				c-0.025,2.183-0.015,4.355,0.432,6.51c0.285,1.376,0.055,2.758-0.145,4.138c-0.256,1.763-0.518,3.535-0.6,5.311
				c-0.104,2.237,0.453,4.52-1.277,6.509c-0.48,0.552-0.347,1.712-0.347,2.594c0.001,1.883,0.085,3.766,0.16,5.647
				c0.081,2.027-0.285,3.923-1.327,5.706c-0.493,0.844-0.858,1.794-1.109,2.742c-0.759,2.87-1.483,5.75-2.122,8.649
				c-0.409,1.855-0.187,3.745-0.003,5.624c0.519,5.32,1.344,10.635,0.44,15.996c-0.169,1.005-0.095,1.987,0.521,2.849
				c0.958,1.339,0.691,2.681-0.16,3.875c-2.052,2.88-3.146,6.167-4.119,9.493c-0.738,2.522-3.056,3.778-4.551,5.786
				c0.459-1.779,0.923-3.556,1.375-5.337c0.438-1.725,0.985-3.431,0.829-5.254c-0.099-1.153,0.363-2.127,1.081-2.998
				c2.885-3.498,3.399-7.498,2.37-11.761c-0.52-2.151-0.689-4.306-0.508-6.49c0.54-6.51,1.372-13.008,1.584-19.528
				c0.155-4.753,1.007-9.167,3.121-13.418c1.146-2.306,0.719-4.861,0.435-7.324c-0.387-3.364-0.424-6.721-0.018-10.084
				c0.191-1.58,0.496-3.071,1.465-4.44c1.12-1.582,0.925-3.396,0.257-5.13C331.456,188.598,332.276,188.187,333.375,187.941z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(207)} fill={props.fillColors[207]} d="M424.928,557.229c-5.418-5.231-11.804-7.942-17.123-12.231
				c-4.07-3.28-9.102-3.587-14.097-3.689c-0.593-0.012-1.189,0.104-1.779,0.073c-2.041-0.108-2.846-0.862-3.075-2.871
				c-0.101-0.884-0.048-1.792-0.222-2.658c-0.518-2.575-1.633-2.967-3.955-1.464c-0.246,0.158-0.504,0.297-0.757,0.444
				c-1.552-2.659-0.878-5.052,2.486-8.844c9.369,2.916,17.674,7.298,22.521,16.443c1.491,2.813,4.076,4.238,6.917,5.275
				c1.487,0.544,2.971,1.07,4.391,1.789C423.166,550.978,424.274,553.536,424.928,557.229z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(208)} fill={props.fillColors[208]} d="M171.48,535.252c-2.607,7.253-5.109,14.009-8.694,20.305
				c-0.691,1.214-1.447,2.417-2.952,2.749c-1.686,0.372-2.705,1.563-3.658,2.854c-2.139,2.893-3.667,6.097-4.919,9.457
				c-0.656,1.76-1.418,3.484-2.235,5.176c-0.562,1.167-1.533,1.787-2.913,1.513c-5.522-1.101-9.784,1.242-13.539,4.966
				c-0.34,0.337-0.54,0.843-1.155,0.935c-0.549-0.613-0.159-1.263,0.012-1.856c0.655-2.283,1.369-4.551,2.042-6.829
				c0.227-0.767,0.374-1.651,1.073-2.067c3.068-1.825,6.249-3.547,9.968-3.092c3.495,0.428,4.551,0.111,6.103-3.108
				c1.202-2.494,2.162-5.104,3.247-7.655c1.467-3.447,3.066-6.826,5.047-9.926c1.133,0.021,0.626,1.655,1.832,1.434
				c1.46-0.337,2.067-1.644,2.776-2.807c1.808-2.97,3.139-6.177,4.553-9.342C168.844,536.218,168.844,536.218,171.48,535.252z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(209)} fill={props.fillColors[209]} d="M226.221,428.082c2.924-5.251,5.226-10.444,8.457-15.061
				c7.479-10.687,13.665-22.073,19.044-33.921c0.793-1.747,1.748-3.35,3.096-4.681c3.809-3.764,7.655-7.489,11.514-11.201
				c0.52-0.5,1.006-1.144,2.055-1.366c-0.571,1.348-1.527,2.068-2.271,2.935c-1.684,1.962-3.347,3.886-3.992,6.519
				c-0.463,1.889-1.78,3.301-3.3,4.473c-2.076,1.602-3.735,3.579-5.009,5.835c-2.387,4.227-4.68,8.508-6.968,12.79
				c-0.955,1.788-1.255,3.745-1.178,5.764c0.106,2.769-0.683,5.286-2.168,7.6c-4.746,7.393-10.171,14.183-17.3,19.455
				C227.745,427.56,227.39,428.088,226.221,428.082z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(210)} fill={props.fillColors[210]} d="M309.497,486.674c1.48,4.025,1.817,7.875,1.295,11.709
				c-0.909,6.666-2.246,13.224-4.273,19.697c-2.781,8.878-3.23,18.08-2.099,27.354c0.497,4.076-0.253,7.977-2.6,11.89
				c-0.716-1.668-0.839-2.968-0.844-4.329c-0.022-5.832,0.234-11.689-1.7-17.349c-0.306-0.898-0.295-1.988-0.144-2.944
				c0.621-3.892,0.842-7.89,2.904-11.429c0.91-1.56,1.545-3.288,1.422-5.094c-0.51-7.497,1.807-14.488,3.744-21.545
				C307.903,492.087,308.271,489.432,309.497,486.674z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(211)} fill={props.fillColors[211]} d="M237.909,541.08c-0.611-1.913-1.461-3.456-2.346-4.984
				c-0.448-0.774-0.67-1.662-0.448-2.579c1.191-4.917,1.524-9.976,2.449-14.934c0.86-4.609,2.629-8.74,5.723-12.496
				c2.54-3.084,4.266-6.893,4.043-11.211c-0.062-1.219-0.247-2.296-0.947-3.349c-0.534-0.803-0.838-1.79-1.103-2.734
				c-0.885-3.157-0.426-4.522,1.863-5.861c2.825-1.653,5.653-1.447,7.653,0.559c0.857,0.86,1.432,1.904,1.831,3.028
				c0.659,1.856,1.197,3.722,2.271,5.448c1.194,1.92,0.542,3.865-1.962,6.745c0.092-2.714-0.542-4.902-1.231-7.086
				c-0.384-1.218-0.816-2.231-2.315-2.272c-1.452-0.04-3.472,1.11-3.958,2.231c-0.279,0.645,0.002,1.138,0.25,1.676
				c1.271,2.754,1.13,5.703,0.406,8.438c-0.983,3.717-2.697,7.134-5.232,10.2c-3.517,4.25-5.8,9.181-6.427,14.762
				c-0.166,1.476-0.327,2.952-0.541,4.421c-0.248,1.696-0.18,3.333,0.444,4.963C238.942,537.635,239.059,539.253,237.909,541.08z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(212)} fill={props.fillColors[212]} d="M305.115,574.07c0.515,0.35,0.349,0.903,0.296,1.334
				c-0.409,3.375,0.895,6.409,1.827,9.519c0.318,1.061,0.819,2.021,1.631,2.783c0.811,0.762,1.305,1.71,1.819,2.678
				c1.997,3.759-0.467,5.98-3.308,6.677c-2.492,0.61-5.046,0.964-7.574,1.429c-2.821,0.519-5.648,1.018-8.084,2.854
				C291.452,594.742,298.579,580.327,305.115,574.07z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(213)} fill={props.fillColors[213]} d="M434.526,398.633c-5.084-4.913-9.68-10.138-12.746-16.646
				c-4.941-10.487-10.452-20.694-15.961-30.896c-1.058-1.958-2.167-3.741-4.451-4.514c-1.426-0.483-1.968-1.651-1.465-3.108
				c0.928-2.686,1.654-5.407,1.729-8.367C412.774,356.251,424.439,377.009,434.526,398.633z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(214)} fill={props.fillColors[214]} d="M351.511,79.757c-4.436-0.074-5.188,0.556-5.37,4.491
				c-1.67-1.586-3.283-2.902-3.726-5.162c-0.417-2.126-2.39-3.226-4.158-2.114c-1.193,0.75-2.225,1.13-3.533,0.612
				c-1.191-0.472-2.198,0.94-3.547,0.304c0.212-1.854,0.878-3.289,2.803-4.021c1.193-0.454,2.296-1.15,3.254-2.038
				c1.54-1.426,1.6-1.873,0.189-3.357c-1.611-1.695-2.143-3.784-2.765-5.829c1.392-0.691,1.786,0.075,2.301,0.659
				c0.848,0.961,1.822,0.635,2.66,0.048c1.029-0.721,0.429-1.539-0.042-2.317c-0.528-0.875-1.524-1.612-0.98-2.877
				c2.643,1.08,4.331,3.005,5.593,5.419c1.902,3.637,5.018,5.744,8.883,6.912c0.994,0.3,2.196,0.293,2.907,1.434
				C355.208,74.896,351.951,76.442,351.511,79.757z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(215)} fill={props.fillColors[215]} d="M223.184,629.512c5.325,2.4,10.68,1.489,16.111,1.533
				c-0.899,3.138-0.748,6.287-0.769,9.428c-0.005,0.888,0.029,1.817-0.212,2.652c-0.568,1.967-0.644,3.87,0.106,5.8
				c0.129,0.333,0.019,0.759,0.019,1.052c-0.374,0.302-0.649,0.296-0.854,0.038c-2.288-2.874-5.527-3.613-8.927-3.979
				c-1.381-0.149-2.758-0.348-4.048-0.86c-2.578-1.024-3.476-2.535-2.958-5.3C222.286,636.485,221.902,632.947,223.184,629.512z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(216)} fill={props.fillColors[216]} d="M475.629,661.736c-1.714-0.42-2.494-1.493-3.315-2.447
				c-4.022-4.68-7.199-9.938-10.287-15.251c-0.241-0.417-0.486-0.91-0.494-1.371c-0.075-4.572-1.548-8.977-1.59-13.555
				c-0.021-2.194-0.58-4.301-1.212-6.397c-0.969-3.217-1.653-6.458-2.006-9.848c-0.637-6.133-3.327-11.13-9.668-13.29
				c-1.915-0.653-1.38-2.601-2.485-3.881c6.527-0.503,10.712,2.446,12.385,8.139c0.673,2.291,1.28,4.533,1.354,6.947
				c0.066,2.182,0.784,4.284,1.492,6.345c1.073,3.125,1.6,6.337,1.646,9.623c0.043,2.939,0.875,5.641,2.116,8.271
				c2.329,4.935,5.042,9.652,7.911,14.286C473.795,653.053,475.412,657.035,475.629,661.736z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(217)} fill={props.fillColors[217]} d="M29.823,271.687c0.859,1.653,1.664,3.201,2.496,4.803
				c-1.373,2.696-2.713,5.329-4.09,8.033c1.039,2.051,1.955,4.142,3.125,6.08c0.89,1.474,0.754,2.667-0.077,4.08
				c-1.101,1.871-2.007,3.856-3.047,5.892c1.332,2.643,2.663,5.284,4.047,8.03c-0.696,1.477-1.335,2.993-2.539,4.575
				c-2.224-4.461-4.33-8.685-6.435-12.91c-1.103-2.214-2.175-4.444-3.318-6.637c-0.499-0.956-0.544-1.795-0.046-2.786
				c3.066-6.101,6.082-12.227,9.127-18.337C29.192,272.259,29.457,272.079,29.823,271.687z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(218)} fill={props.fillColors[218]} d="M167.809,301.7c-3.476,1.917-6.346,4.331-9.151,6.774
				c-1.191-0.889-0.653-1.478-0.094-2.077c2.23-2.394,4.414-4.827,6.41-7.424c1.8-2.342,1.298-5.008-1.443-5.89
				c-2.647-0.852-4.637-3.065-7.571-3.421c3.74-1.444,7.503-2.462,11.502-1.817c1.484,0.24,3.305,1.87,4.801,3.901
				c1.408-0.372,2.358-1.751,4.038-1.994c0.118,2.138-1.518,3.534-2.06,5.125c3.447,9.25,1.801,18.132-0.631,26.986
				c-0.206,0.005-0.413,0.01-0.619,0.015c-0.412-3.469-0.829-6.937-1.234-10.407c-0.184-1.574-0.34-3.15-0.521-4.725
				C170.867,303.556,170.864,303.557,167.809,301.7z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(219)} fill={props.fillColors[219]} d="M29.78,552.476c-3.391-6.806-6.567-13.168-9.728-19.538
				c-0.395-0.796-0.712-1.591-0.244-2.53c3.21-6.445,6.388-12.905,9.829-19.872c1.029,1.981,1.818,3.5,2.684,5.167
				c-1.119,2.173-2.192,4.387-3.391,6.53c-0.602,1.075-0.58,1.97,0.012,3.031c1.153,2.07,2.195,4.202,3.372,6.481
				c-1.048,2.049-2.055,4.186-3.22,6.231c-0.709,1.245-0.771,2.303-0.047,3.564c1.13,1.97,2.109,4.026,3.209,6.155
				C31.729,549.234,30.922,550.663,29.78,552.476z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(220)} fill={props.fillColors[220]} d="M328.116,617.411c-3.216-2.992-6.8-5.005-10.826-6.229
				c-5.165-1.572-10.315-1.701-15.491-0.026c-3.4,1.101-6.686,2.466-9.903,4.011c-0.696,0.334-1.32,0.863-2.55,0.85
				c1.295-3.604,2.05-7.204,5.944-9.062c2.931-1.398,6.024-2.029,9.161-2.33c3.37-0.324,6.636-1.088,9.935-1.731
				c2.946-0.575,5.461,0.014,7.495,2.367c1.812,2.096,3.765,4.072,5.564,6.18C328.9,613.145,329.242,615.076,328.116,617.411z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(221)} fill={props.fillColors[221]} d="M29.85,152.129c0.826,1.59,1.627,3.13,2.47,4.751
				c-1.359,2.671-2.699,5.306-4.091,8.042c1.333,2.635,2.67,5.277,4.082,8.066c-1.067,2.068-2.087,4.202-3.255,6.251
				c-0.719,1.261-0.663,2.328,0.041,3.566c1.123,1.977,2.102,4.034,3.188,6.149c-0.637,1.531-1.391,2.989-2.515,4.652
				c-1.402-2.798-2.675-5.328-3.939-7.863c-1.901-3.81-3.787-7.626-5.698-11.431c-0.502-1-0.806-1.892-0.217-3.049
				c3.142-6.176,6.198-12.396,9.296-18.594C29.292,152.511,29.504,152.417,29.85,152.129z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(222)} fill={props.fillColors[222]} d="M29.745,391.113c0.947,1.845,1.71,3.333,2.567,5.003
				c-1.098,2.131-2.17,4.344-3.371,6.484c-0.601,1.071-0.607,1.966-0.014,3.033c1.152,2.073,2.194,4.207,3.392,6.53
				c-0.805,1.575-1.514,3.324-2.533,4.867c-1.403,2.126-1.58,4.03-0.074,6.212c1.054,1.527,1.73,3.315,2.589,5.015
				c-0.749,1.464-1.458,2.85-2.201,4.302c-0.978-0.495-1.12-1.301-1.447-1.954c-2.786-5.574-5.518-11.176-8.358-16.722
				c-0.745-1.455-0.785-2.649-0.022-4.136c2.935-5.719,5.737-11.507,8.596-17.266C29.042,392.14,29.283,391.832,29.745,391.113z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(223)} fill={props.fillColors[223]} d="M651.578,172.985c1.179-2.295,2.216-4.436,3.37-6.511
				c0.594-1.066,0.625-1.959,0.018-3.04c-1.166-2.071-2.2-4.216-3.31-6.37c0.587-1.696,1.479-3.227,2.345-4.748
				c0.938,0.151,1.01,0.959,1.295,1.529c2.748,5.496,5.445,11.017,8.203,16.508c0.656,1.307,1.002,2.525,0.288,3.939
				c-3.125,6.193-6.208,12.407-9.317,18.609c-0.074,0.148-0.267,0.237-0.491,0.427c-1.017-1.245-1.559-2.74-2.383-4.31
				c1.083-2.109,2.166-4.331,3.357-6.493c0.587-1.064,0.624-1.957,0.01-3.039C653.791,177.419,652.758,175.273,651.578,172.985z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(224)} fill={props.fillColors[224]} d="M651.569,276.502c0.831-1.587,1.638-3.125,2.427-4.632
				c0.753,0.26,0.895,0.754,1.113,1.194c2.728,5.497,5.439,11.001,8.2,16.48c0.752,1.493,1.14,2.964,0.359,4.536
				c-3.118,6.288-6.249,12.569-9.445,18.993c-1.19-1.111-1.535-2.491-2.244-3.642c-0.514-0.835-0.04-1.511,0.315-2.206
				c0.858-1.674,1.656-3.384,2.613-4.999c0.692-1.17,0.633-2.164-0.026-3.305c-0.89-1.541-1.572-3.205-2.498-4.722
				c-0.715-1.171-0.631-2.146,0.023-3.287c0.934-1.629,1.695-3.357,2.61-4.999c0.536-0.963,0.536-1.784-0.006-2.762
				C653.859,281.077,652.82,278.938,651.569,276.502z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(225)} fill={props.fillColors[225]} d="M654.18,432.778c-0.402-0.477-0.536-0.622-0.654-0.778
				c-1.799-2.363-2.15-4.746-0.192-7.23c2.355-2.989,2.463-5.981,0.075-9.075c-0.768-0.994-1.171-2.268-1.797-3.527
				c0.975-1.923,1.817-3.945,2.979-5.766c1.062-1.666,0.954-3.056-0.076-4.624c-0.701-1.068-1.093-2.347-1.823-3.392
				c-1.13-1.615-1.003-3.079,0.062-4.626c0.494-0.718,0.872-1.518,1.289-2.256c0.637,0.197,0.824,0.65,1.046,1.1
				c2.721,5.495,5.436,10.992,8.188,16.472c0.801,1.593,1.092,3.175,0.276,4.824C660.485,420.097,657.404,426.287,654.18,432.778z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(226)} fill={props.fillColors[226]} d="M654.037,511.099c0.898,0.259,1,1.036,1.296,1.627
				c2.57,5.127,5.06,10.295,7.687,15.393c1.14,2.212,1.205,4.225,0.048,6.468c-2.987,5.794-5.839,11.657-8.819,17.645
				c-1.143-0.818-1.349-2.038-1.964-2.976c-0.604-0.919-0.603-1.791-0.042-2.765c0.788-1.37,1.351-2.879,2.214-4.194
				c1.178-1.795,1.174-3.408-0.077-5.147c-0.629-0.874-0.954-1.964-1.553-2.864c-1.094-1.647-1.242-3.183-0.072-4.898
				c0.83-1.217,1.314-2.664,2.101-3.916c0.79-1.256,0.67-2.344-0.059-3.557c-0.763-1.27-1.27-2.703-2.102-3.918
				c-1.111-1.622-1.012-3.076,0.053-4.626C653.243,512.647,653.619,511.843,654.037,511.099z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(227)} fill={props.fillColors[227]} d="M469.983,507.965c1.418,0.077,1.712,0.869,2.038,1.574
				c0.539,1.169,1.053,2.353,1.52,3.552c0.865,2.227,2.352,3.851,4.464,4.971c1.133,0.601,2.223,1.306,3.251,2.072
				c2.173,1.617,3.442,3.723,3.62,6.521c0.301,4.714,2.555,8.926,3.648,13.442c0.133,0.547,0.518,1.101,0.933,1.5
				c3.173,3.062,5.386,6.846,7.923,10.388c0.572,0.8,1.207,1.59,1.944,2.233c1.906,1.663,3.537,1.739,5.617,0.283
				c0.826-0.579,1.625-1.147,2.66-1.21c0.988-0.06,1.952-0.244,2.794-0.563c1.376,0.691,1.076,2.518,2.401,3.045
				c1.103,0.437,2.296,0.678,3.346,1.208c2.095,1.058,4.024,2.347,4.723,4.875c-2.075-1.603-4.509-2.273-6.883-3.137
				c-3.894-1.415-7.521-1.312-10.969,1.162c-1.335,0.958-2.646,0.584-3.83-0.343c-1.026-0.803-1.74-1.89-2.47-2.955
				c-2.241-3.269-4.335-6.648-7.209-9.437c-0.577-0.561-0.979-1.245-1.17-2.037c-1.135-4.706-3.22-9.146-3.62-14.072
				c-0.253-3.118-2.432-5.375-5.05-6.586c-4.198-1.94-6.308-5.557-8.587-9.142c-0.77-1.211-1.113-2.568-0.976-3.973
				C470.207,510.267,470.165,509.206,469.983,507.965z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(228)} fill={props.fillColors[228]} d="M209.461,565.927c-0.365-1.582-0.524-2.559-0.817-3.493
				c-0.812-2.584-2.415-3.101-4.389-1.281c-5,4.607-8.605,10.09-9.853,16.88c-0.799,4.346-2.289,8.379-4.722,12.072
				c-0.926,1.404-1.765,2.85-2.085,4.555c-0.708,3.763,0.23,5.115,4.171,5.6c-1.321,2.602-3.517,3.92-6.104,4.626
				c-1.958,0.535-2.949-0.232-3.242-2.343c-0.43-3.098,0.41-5.88,2.246-8.385c2.348-3.203,4.467-6.535,5.88-10.274
				c0.84-2.224,1.624-4.46,2.042-6.812c1.743-9.777,7.353-17.143,14.707-23.378c1.993-1.69,3.383-1.248,4.232,1.2
				c0.732,2.112,0.865,4.285,0.049,6.405C211.05,562.667,210.363,563.973,209.461,565.927z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(229)} fill={props.fillColors[229]} d="M327.527,702.742c-1.081,0.636-1.501,0.323-1.73-0.537
				c-0.851-3.196-1.472-6.422-0.351-9.659c1.142-3.3,1.165-6.827,2.233-10.146c0.538-1.67-0.212-2.712-2.745-4.603
				c-1.118,1.699-2.132,3.479-3.541,4.964c-0.566,0.596-0.317,1.287-0.102,1.959c0.756,2.352,0.691,4.699-0.188,6.993
				c-0.568,1.482-0.411,2.85,0.132,4.311c0.829,2.232,1.31,4.543,0.77,6.893c-0.791,0.315-1.391,0.173-1.386-0.604
				c0.015-2.426-0.642-4.688-1.437-6.938c-0.418-1.188-0.101-2.384,0.33-3.489c0.91-2.335,1.019-4.626-0.121-6.896
				c-0.539-1.073-0.142-1.862,0.552-2.686c0.893-1.06,1.742-2.163,2.529-3.304c1.27-1.836,1.218-3.648-0.099-5.507
				c-2.585-3.647-4.621-7.617-6.622-11.606c-1.399-2.79-1.734-5.63-0.953-8.653c0.445-1.721,0.802-3.474,1.047-5.233
				c0.211-1.516-0.401-2.919-0.951-4.309c-0.431-1.087,0.088-1.772,1.119-1.964c0.972-0.181,1.6,0.483,1.505,1.374
				c-0.29,2.716,1.301,4.107,3.295,5.486c2.044,1.413,2.578,3.928,3.688,6.011c1.221,2.29,0.989,4.514-0.001,6.854
				c-0.915,2.165-0.98,4.456,0.188,6.607c0.741,1.363,1.044,2.702,0.645,4.262c-0.506,1.978,0.103,3.645,1.617,5.096
				c2.147,2.058,2.961,4.362,1.95,7.494c-1.057,3.272-1.314,6.791-2.237,10.141C325.946,697.658,327.021,700.135,327.527,702.742z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(230)} fill={props.fillColors[230]} d="M325.317,503.225c-0.648,3.606-2.033,7.027-2.602,10.663
				c-0.491,3.135-1.069,6.252-1.09,9.437c-0.006,1.004-0.072,1.951-0.709,2.814c-2.244,3.042-3.877,6.45-5.782,9.696
				c-1.111,1.895-2.448,3.61-5.072,3.653c-0.54-1.438-0.486-2.798-0.146-4.142c0.611-2.413,0.881-4.868,1.108-7.339
				c0.307-3.333,1.099-6.445,4.045-8.576c1.066-0.771,1.706-1.907,1.954-3.238c0.468-2.51,1.677-4.684,3.368-6.529
				c1.836-2.004,2.976-4.534,4.987-6.388L325.317,503.225z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(231)} fill={props.fillColors[231]} d="M377.072,401.662c1.163,0.014,2.036,0.216,2.424,0.902
				c0.991,1.756,2.614,2.609,4.275,3.53c2.118,1.177,3.102,3.314,3.915,5.439c1.912,4.996,3.717,10.035,5.525,15.071
				c1.504,4.192,1.732,13.115,0.413,16.766c-1.205-1.233-1.775-2.606-1.818-4.23c-0.078-2.987-0.4-5.934-1.474-8.755
				c-0.327-0.857-0.367-1.753-0.17-2.638c0.386-1.734-0.389-2.834-1.834-3.658c-0.772-0.439-1.479-0.995-2.207-1.509
				c-2.697-1.903-4.511-4.414-5.306-7.646c-0.427-1.73-0.96-3.437-1.35-5.175c-0.327-1.462-0.672-2.855-1.673-4.065
				C376.878,404.591,376.588,403.251,377.072,401.662z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(232)} fill={props.fillColors[232]} d="M400.768,445.016c-1.835-2.908-2.867-6.124-4.881-8.839
				c-0.438-0.59-0.329-1.334-0.319-2.02c0.049-3.527-0.854-6.836-2.105-10.103c-3.752-9.795-7.165-19.71-10.4-29.686
				c-0.284-0.877-0.996-1.744-0.405-2.944c1.982,2.971,3.462,6.083,4.89,9.222c1.879,4.129,3.306,8.486,6.466,11.943
				c0.939,1.028,1.284,2.473,1.395,3.86c0.161,2.007,0.721,3.899,1.466,5.73c1.683,4.14,2.689,8.506,3.743,12.795
				C401.393,438.144,400.768,441.656,400.768,445.016z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(233)} fill={props.fillColors[233]} d="M441.951,134.86c0.67,0.569,0.4,1.21,0.531,1.754c0.59,2.449,2.454,3.514,4.608,2.285
				c1.124-0.642,2.229-0.511,3.104-0.284c1.338,0.349,2.386,1.57,4.054,1.166c0.46-0.111,0.91,0.746,0.728,1.393
				c-0.24,0.852-0.631,1.657-0.629,2.574c0.002,1.395-0.23,2.791,1.907,2.951c1.132,0.084,1.71,1.24,2.189,2.234
				c0.557,1.153,1.09,2.317,1.699,3.616c-0.255,0.526-0.549,1.142-0.85,1.754c-0.25,0.509-0.161,1.213-0.842,1.477
				c-5.554-6.597-8.313-7.435-12.703-3.827c-1.1-0.521-1.867-1.001-1.608-2.627c0.382-2.395-0.778-4.729-0.799-7.188
				c-0.015-1.645-2.322-3.006-4.25-2.988c-0.565,0.005-1.13,0.172-1.992,0.313C437.733,136.481,440.083,135.927,441.951,134.86z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(234)} fill={props.fillColors[234]} d="M452.42,474.551c1.545,2.281,3.007,4.354,4.374,6.489
				c0.747,1.168,1.333,2.46,2.331,3.434c1.85,1.806,3.726,3.513,4.153,6.31c0.22,1.438,1.78,1.67,2.819,1.969
				c0.387,1.341,0.051,2.425,0.31,3.513c0.656,2.764,2.067,4.738,4.672,6.093c2.778,1.444,5.834,2.449,8.144,4.762
				c0.702,0.704,1.854,0.553,2.854,0.577c2.675,0.066,5.333-0.369,8.019-0.13c3.617,0.322,4.737,1.502,4.758,5.579
				c-4.163-1.943-8.486-1.036-12.745-1.055c-1.749-0.008-3.32-0.091-4.465-1.708c-0.36-0.507-1.152-0.718-1.761-1.037
				c-1.93-1.011-3.826-2.099-5.81-2.992c-3.367-1.516-4.393-4.368-4.427-7.692c-0.014-1.404-0.357-2.512-1.28-3.493
				c-2.532-2.69-4.539-5.65-5.883-9.169c-1.168-3.059-3.635-5.407-5.554-8.051C452.255,477.02,451.366,476.153,452.42,474.551z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(235)} fill={props.fillColors[235]} d="M371.014,334.753c-0.613,0.59-1.269,0.592-2.262,0.328
				c-1.063-3.742-2.203-7.618-3.266-11.516c-2.421-8.887-5.1-17.725-6.473-26.849c-0.84-5.582-0.971-11.253-1.162-16.901
				c-0.017-0.47,0.137-0.946,0.392-1.468c0.97,2.658,2.421,5.1,3.051,7.87c0.13,0.573,0.341,1.195,0.239,1.744
				c-0.906,4.915,0.519,9.566,1.471,14.285c0.535,2.653,1.498,5.057,2.879,7.394c0.834,1.411,1.051,3.209,0.903,4.896
				c-0.219,2.499,0.404,4.688,1.895,6.682c1.301,1.74,2.043,3.56,1.071,5.788c-0.589,1.351,0.113,2.723,0.395,4.065
				C370.407,332.314,371.083,333.466,371.014,334.753z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(236)} fill={props.fillColors[236]} d="M330.584,262.846c-1.29-1.06-1.24-2.06-1.102-3.054
				c0.73-5.257,0.09-10.476-0.435-15.696c-0.29-2.88-0.156-5.657,0.699-8.497c1.021-3.393,1.528-6.908,3.205-10.134
				c1.171-2.254,0.636-4.869,0.555-7.33c-0.077-2.369-0.523-4.739,1.291-6.769c0.534-0.598,0.362-1.508,0.29-2.304
				c-0.179-1.984-0.057-3.957,0.264-5.919c0.464-2.842,0.752-5.665,0.127-8.546c-0.266-1.225-0.268-2.553,0.094-3.86
				c1.37,0.307,1.596,1.276,1.643,2.455c0.19,4.749,0.43,9.495,0.642,14.242c0.161,3.61-0.591,7.12-1.564,10.534
				c-1.634,5.732-2.563,11.57-3.461,17.444c-0.553,3.621-1.528,7.19-0.99,10.868c0.522,3.568,0.295,7.113,0.075,10.668
				C331.796,258.9,331.472,260.818,330.584,262.846z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(237)} fill={props.fillColors[237]} d="M233.514,597.148c-0.69,1.557-1.59,2.275-2.488,2.977
				c-4.264,3.331-6.558,8.104-9.245,12.59c-0.201,0.335-0.331,0.722-0.444,1.099c-1.587,5.27-5.536,5.128-9.489,3.479
				c-1.993-0.831-2.358-1.849-1.382-3.772c0.892-1.758,2.012-3.402,3.455-4.797c1.351-1.307,2.681-2.638,3.982-3.993
				c1.472-1.533,3.194-2.67,5.216-3.298c1.793-0.557,3.622-0.998,5.426-1.521C230.237,599.422,231.516,598.113,233.514,597.148z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(238)} fill={props.fillColors[238]} d="M273.646,480.476c-1.322,5.216-2.177,9.971-3.792,14.451
				c-0.969,2.687-3.938,4.05-6.224,5.76c-0.946,0.708-1.934,1.351-2.867,2.088c-2.74,2.164-4.371,5.037-5.64,8.193
				c-0.545,1.354-0.938,2.803-2.056,3.947c-0.34-2.907,0.758-7.774,2.524-10.898c0.874-1.546,1.831-3.055,2.86-4.501
				c2.067-2.905,3.79-5.981,5.403-9.17C265.915,486.271,268.917,482.905,273.646,480.476z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(239)} fill={props.fillColors[239]} d="M359.725,216.905c0.822,1.735,1.907,2.883,3.67,3.688
				c1.418,0.647,2.053,2.404,2.385,3.915c2.188,9.958,6.391,19.322,8.552,29.283c0.081,0.372,0.165,0.744,0.311,1.399
				c-1.335-0.876-2.432-1.597-3.712-2.438c0.963-1.034,0.622-1.991,0.057-3.132c-1.344-2.714-2.984-5.278-5.542-6.809
				c-3.599-2.152-3.782-5.2-3.081-8.679c0.597-2.96,0.622-5.89-0.32-8.769c-0.304-0.928-0.898-1.756-1.303-2.656
				C359.929,220.898,359.618,218.977,359.725,216.905z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(240)} fill={props.fillColors[240]} d="M343.659,58.244c0.934-0.993,1.562-1.979,1.803-2.96
				c0.625-0.453,1.111-0.37,1.592-0.248c1.089,0.278,2.001,0.062,2.458-1.04c0.447-1.078,0.49-2.29-0.554-2.962
				c-2.203-1.418-3.137-3.749-4.536-5.757c0.303-0.669,1.045-1.078,1.319-0.446c1.18,2.711,3.807,3.222,5.967,4.226
				c0.366,0.354,0.208,0.637,0.105,0.893c-0.534,1.335,0.257,2.071,1.26,2.614c1.005,0.545,1.676-0.044,2.191-0.875
				c0.602-0.968,1.358-1.379,2.585-0.687c-1.856,5.299-1.538,10.893-2.41,16.482C349.305,67.245,346.775,62.608,343.659,58.244z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(241)} fill={props.fillColors[241]} d="M441.59,178.761c0.983,0.784,1.941,0.507,2.782,0.741
				c1.646,0.458,2.255,1.337,1.713,3.014c-0.433,1.339,0.21,2.275,0.645,3.361c0.692,1.731,0.362,2.307-1.493,2.423
				c-0.953,0.06-1.733,0.19-2.271,1.069c-0.548,0.896-1.484,1.261-2.561,1.673c-0.744-1.576-2.081-2.51-3.546-2.861
				c-0.556-1.098-0.187-1.749,0.28-2.44c1.238-1.837,1.098-2.798-0.536-4.219c-1.066-0.929-2.369-1.439-3.657-1.934
				c-2.233-0.856-3.397-2.305-2.883-4.969c1.808-1.488,4.27-1.597,6.462-2.384c0.17-0.061,0.434-0.06,0.576,0.033
				c1.377,0.888,2.188,2.415,3.823,3.096c0.772,0.321,0.577,1.583,0.563,2.477C441.484,178.131,441.551,178.422,441.59,178.761z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(242)} fill={props.fillColors[242]} d="M250.492,492.007c0.939-1.189,2.03-1.654,3.539-1.337
				c2.069,4.327,2.532,8.635,0.123,13.034c-0.613,1.12-1.178,2.336-1.422,3.575c-0.445,2.258-1.698,3.961-3.18,5.59
				c-4.476,4.917-7.084,10.651-7.687,17.311c-0.209,2.318-0.123,4.758-1.865,7.14c-0.662-1.676-1.214-3.039-1.113-4.607
				c0.44-6.812,1.55-13.449,5.95-18.974c1.926-2.419,3.625-4.915,4.912-7.724C251.814,501.506,252.377,496.947,250.492,492.007z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(243)} fill={props.fillColors[243]} d="M279.219,345.222c0.926-3.314,2.09-6.015,4.038-8.303c1.193-1.4,1.84-2.96,2.297-4.736
				c1.011-3.93,2.358-7.737,4.778-11.097c2.504-3.477,4.033-7.457,5.765-11.352c2.775-6.24,4.529-12.885,7.706-18.955
				c0.732-1.4,1.421-2.814,2.625-3.931c-1.337,4.368-1.654,9.027-4.125,13.041c-0.663,1.077-1.199,2.333-1.406,3.572
				c-0.5,2.988-1.761,5.653-3.112,8.295c-1.354,2.647-2.572,5.351-3.689,8.106c-0.407,1.004-0.806,2.098-1.51,2.879
				c-1.96,2.174-2.6,4.818-3.172,7.553C288.454,334.876,284.324,342.5,279.219,345.222z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(244)} fill={props.fillColors[244]} d="M423.3,86.943c-0.884-0.505-0.906-1.132-1.005-1.705
				c-0.215-1.254-0.809-2.229-2.004-2.75c-1.198-0.521-1.762-1.471-2.075-2.909c12.218,0,24.312,0,36.405,0
				c0.027,0.114,0.055,0.229,0.083,0.342C444.159,82.28,433.613,84.638,423.3,86.943z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(245)} fill={props.fillColors[245]} d="M359.319,94.182c-1.762-1.698-3.644-1.26-5.691-0.897
				c-0.419-1.638-1.374-3.006-2.075-4.499c-0.604-1.283-1.216-2.378-2.706-2.9c-1.862-0.652-1.077-2.496-1.494-3.779
				c0.819-1.143,1.821-1.606,3.127-1.235c1.044,0.297,1.89-0.072,2.106-1.055c0.548-2.48,2.678-3.813,3.921-6.059
				C360.839,80.223,359.394,87.211,359.319,94.182z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(246)} fill={props.fillColors[246]} d="M354.624,183.384c0.987,0.359,0.948,1.385,1.231,2.176
				c2.432,6.791,4.707,13.633,6.519,20.621c0.916,3.534,2.544,6.859,3.032,10.523c0.157,1.179,0.337,2.339,0.193,3.469
				c-4-0.412-4.495-4.161-6.298-6.65c-0.283-0.391-0.081-1.151-0.062-1.74c0.1-3.124-0.451-6.051-2.253-8.709
				c-1.118-1.649-1.675-3.568-2.021-5.528c-0.742-4.201-0.75-8.465-1.067-12.703C353.853,184.258,353.81,183.616,354.624,183.384z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(247)} fill={props.fillColors[247]} d="M325.378,503.276c-0.039-0.485-0.203-1-0.097-1.451
				c1.232-5.235,0.736-10.44-0.182-15.647c-0.172-0.974-0.338-1.961-0.365-2.946c-0.073-2.72,0.887-4.038,3.429-4.881
				c2.209-0.732,3.542-0.129,4.485,2.082c0.66,1.547,1.396,3.077,1.879,4.682c0.754,2.504-0.444,4.835-0.968,7.209
				c-0.128,0.577-0.928,0.68-1.471,0.905c-2.25,0.936-3.602,2.66-4.532,4.861c-0.726,1.718-1.145,3.575-2.243,5.132
				C325.317,503.225,325.378,503.276,325.378,503.276z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(248)} fill={props.fillColors[248]} d="M451.636,595.082c-1.61-0.246-2.692-0.589-3.755-0.537
				c-2.6,0.127-3.984-1.289-4.938-3.447c-1.358-3.076-2.819-6.109-4.105-9.215c-1.848-4.464-3.14-9.194-6.554-12.94
				c2.777,0.336,4.739,1.937,6.441,3.773c2.085,2.251,3.943,4.713,4.971,7.675c0.698,2.013,1.753,3.805,3.5,5.045
				C450.466,587.755,450.778,591.314,451.636,595.082z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(249)} fill={props.fillColors[249]} d="M238.548,590.224c-3.85,4.7-7.8,8.348-13.628,9.499
				c-3.462,0.685-6.453,2.394-8.716,5.269c-1.524,1.936-3.761,3.213-5.079,5.382c-0.866,1.425-2,2.693-2.219,4.478
				c-2.473-1.496-3.081-2.973-1.934-5.088c0.795-1.466,1.789-2.863,2.896-4.111c1.572-1.774,3.321-3.393,5.012-5.061
				c1.592-1.57,3.518-2.668,5.672-3.081c4.214-0.809,7.775-2.735,11-5.507C233.385,590.428,235.541,589.478,238.548,590.224z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(250)} fill={props.fillColors[250]} d="M297.777,472.188c-1.784-0.211-3.708-0.717-5.472-0.075
				c-2.731,0.993-5.44,1.208-8.335,1.09c-1.873-0.077-3.789,0.881-5.881,1.427c-0.757-2.519,0.082-4.681,1.955-5.955
				c2.661-1.81,5.353-3.735,8.604-4.492c3.59-0.836,7.866,1.188,9.399,4.488C298.574,469.806,298.574,470.948,297.777,472.188z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(251)} fill={props.fillColors[251]} d="M384.922,194.707c1.194-0.36,2.422-0.726,3.647-1.099
				c0.955-0.292,1.502-0.909,1.511-1.942c0.008-1.014,0.035-2.043-1.294-2.299c-1.047-0.201-1.508-0.842-1.465-2.055
				c1.057,0.041,2.091,0.314,3.146,0.32c2.353,0.013,3.508,1.373,3.238,3.798c-0.12,1.076,0.331,2.182-0.305,3.388
				c-2.713,0.54-3.344,3.812-5.845,4.893c-0.145,0.062-0.305,0.339-0.285,0.494c0.325,2.555-1.976,3.141-3.317,4.437
				c-0.275,0.266-0.654,0.604-0.984,0.602c-1.033-0.004-2.047-0.78-3.104,0.049c-0.758,0.595-1.149,1.653-2.491,1.855
				c-0.999-1.571-2.523-2.303-4.552-2.17c-1.71,0.112-2.049-1.167-2.137-2.46c0.438-0.592,0.923-0.529,1.414-0.409
				c2.35,0.575,4.258,0.07,5.673-2.062c0.65-0.979,1.489-1.726,2.955-1.521c2.809,0.392,3.527-0.268,4.013-3.196
				C384.771,195.138,384.848,194.954,384.922,194.707z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(252)} fill={props.fillColors[252]} d="M370.544,51.13c-4.794,1.596-6.837,4.696-5.442,9.357
				c-3.496,2.438-4.364,4.229-0.638,5.638c0.434,0.896-0.484,1.411-0.628,2.152c-0.148,0.771-0.41,1.52-0.659,2.407
				c-1.128,0.372-2.286,0.898-3.566,0.873c-0.827-0.795-0.941-1.794-0.941-2.776c0-4.35,0.005-8.701,0.103-13.049
				c0.026-1.171,0.275-2.367,0.616-3.493c0.498-1.644,1.605-2.107,3.191-1.52c2.659,0.985,3.648,0.273,3.675-2.646
				c0.003-0.287,0.001-0.573,0.001-1.04c1.492-0.15,2.419-2.062,4.425-1.213C371.099,47.623,370.079,49.395,370.544,51.13z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(253)} fill={props.fillColors[253]} d="M234.41,660.188c-1.425-0.033-1.878-0.756-2.272-1.593
				c-0.704-1.495-1.874-2.479-3.354-3.208c-0.964-0.475-1.872-1.118-2.691-1.82c-2.7-2.312-5.239-4.921-9.537-4.132
				c1.659-1.891,2.945-3.705,4.157-5.802c2.24,2.626,5.187,3.245,8.265,3.595c2.771,0.313,5.441,0.809,7.419,3.151
				c0.963,1.142,1.341,2.129,0.497,3.43C235.648,655.728,234.82,657.821,234.41,660.188z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(254)} fill={props.fillColors[254]} d="M510.196,470.142c-1.443-2.399-1.828-5.102-2.185-7.775
				c-0.354-2.655-0.271-5.339-0.013-8.007c0.356-3.675,1.841-6.857,4.346-9.545c1.224-1.313,2.582-2.054,4.432-0.922
				c0.976,0.598,2.159,0.857,3.473,1.354c-0.867,1.436-2.142,2.217-3.256,3.114c-1.375,1.105-2.23,2.413-2.581,4.174
				c-1.061,5.336-2.217,10.652-3.362,15.971C510.935,469.034,511.102,469.724,510.196,470.142z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(255)} fill={props.fillColors[255]} d="M252.988,672.081c-2.942-3.062-6.191-4.632-9.786-5.802
				c-0.08-3.435,1.107-6.323,2.83-8.967c0.843-1.294,2.433-1.641,3.959-1.744c2.093-0.143,4.09,0.262,6.34,1.126
				C253.753,661.42,252.826,666.39,252.988,672.081z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(256)} fill={props.fillColors[256]} d="M223.596,532.577c0.713,3.029,0.137,5.963-0.284,8.89
				c-0.264,1.837-0.192,3.552,0.604,5.213c1.857,3.879,0.697,7.503-1.45,10.677c-1.69,2.497-3.808,4.749-5.736,7.445
				c0.037-7.471,0.264-14.571,3.057-21.353C221.247,539.903,222.338,536.206,223.596,532.577z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(257)} fill={props.fillColors[257]} d="M501.846,454.764c1.074-0.104,1.666,0.192,2.444,0.516
				c-0.322,3.497-0.163,6.949,0.057,10.402c0.305,4.783,1.286,9.415,3.08,13.851c0.543,1.342,0.783,2.608,0.524,4.028
				c-0.425,2.327-0.764,4.67-1.262,7.769c-1.941-3.732-3.013-7.045-4.077-10.362c-0.613-1.908-0.73-3.897-0.872-5.866
				C501.257,468.379,501.336,461.659,501.846,454.764z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(258)} fill={props.fillColors[258]} d="M459.875,639.957c-0.222-0.312-0.566-0.596-0.648-0.942
				c-3.068-12.979-8.029-25.351-12.452-38.04c3.389,0.751,6.791,3.911,7.574,6.924c0.667,2.567,1.487,5.074,1.54,7.81
				c0.045,2.356,0.964,4.629,1.64,6.905c0.394,1.324,0.829,2.653,1.031,4.013c0.652,4.382,0.405,8.873,1.911,13.155
				C460.273,639.84,460.074,639.898,459.875,639.957z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(259)} fill={props.fillColors[259]} d="M534.934,480.621c-0.977-1.409-2.028-2.695-2.817-4.126
				c-1.099-1.989-1.31-4.132-0.329-6.267c1.06-2.311,0.336-4.223-1.298-5.91c-1.258-1.3-2.088-2.695-1.568-4.904
				c1.903,1.236,3.424,2.876,5.797,2.013c0.688,2.903,2.371,3.907,5.057,3.697c2.78-0.218,2.876,2.361,3.913,3.855
				c-0.358,0.541-0.666,0.396-0.889,0.238c-2.758-1.959-3.752-1.43-4.813,1.707c-1.002,2.96-1.664,5.958-2.243,9.001
				C535.702,480.138,535.335,480.287,534.934,480.621z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(260)} fill={props.fillColors[260]} d="M359.058,156.415c-1.737,3.252-0.871,6.494-0.285,9.675
				c0.538,2.922,1.524,5.72,2.857,8.403c2.204,4.435,4.112,8.986,5.383,13.801c1.41,5.348,0.225,10.148-2.659,14.739
				c-1.652-2.24-2.325-5.154-1.533-7.338c1.272-3.505,0.936-7.014,0.242-10.541c-0.824-4.189-2.165-8.225-3.722-12.191
				c-1.654-4.217-2.251-8.597-2.055-13.103C357.347,158.461,357.55,157.124,359.058,156.415z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(261)} fill={props.fillColors[261]} d="M462.281,652.95c1.135,0.188,1.582,1.164,2.155,1.903
				c1.76,2.272,3.432,4.613,5.135,6.929c1.294,1.759,2.563,3.539,4.33,4.876c1.432,1.084,2.247,2.575,2.373,4.304
				c0.193,2.638,1.35,4.784,3.153,6.553c6.518,6.388,9.032,14.506,10.24,23.217c0.117,0.847,0.55,1.792-0.688,2.235
				c-0.886-0.146-0.993-0.867-1.118-1.539c-0.254-1.363-0.474-2.733-0.706-4.101c-1.193-7.014-3.899-13.274-9-18.39
				c-2.077-2.083-3.491-4.536-3.735-7.573c-0.097-1.204-0.604-2.265-1.592-3.085c-4.19-3.48-6.572-8.42-10.015-12.508
				C462.162,654.997,461.952,654.009,462.281,652.95z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(262)} fill={props.fillColors[262]} d="M283.376,620.86c-0.004-4.233-0.221-7.906,0.592-11.593
				c1.157-5.252,2.729-10.29,5.764-14.829c1.542-2.305,2.068-5.086,2.963-7.703c1.656-4.836,3.94-9.449,7.879-13.338
				c0.654,1.568,0.236,2.664-0.551,3.678c-6.879,8.857-9.797,19.326-12.198,30.001c-0.738,3.282-1.433,6.574-2.087,9.875
				C285.462,618.354,284.8,619.46,283.376,620.86z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(263)} fill={props.fillColors[263]} d="M525.836,414.248c10.454-5.55,21.721-7.09,33.087-8.806
				c-0.143,1.086-0.92,1.139-1.455,1.437c-3.019,1.681-5.969,3.517-9.098,4.962c-3.135,1.449-6.586,0.085-9.906,0.632
				c-3.418,0.562-6.82,1.021-10.043,2.352C527.617,415.155,526.807,415.587,525.836,414.248z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(264)} fill={props.fillColors[264]} d="M245.127,620.897c-2.448,2.163-3.821,4.951-5.131,7.798
				c-0.51,1.108-1.787,1.176-2.856,1.273c-3.369,0.308-6.738,0.026-10.089-0.276c-1.481-0.134-3.025-0.654-3.337-2.454
				c-0.297-1.711,0.435-3.04,1.843-4.007c3.689,3.001,7.623,3.322,11.892,1.03c2.392-1.285,4.788-2.563,7.182-3.845
				C244.795,620.577,244.961,620.737,245.127,620.897z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(265)} fill={props.fillColors[265]} d="M387.593,203.275c1.067-0.035,2.018,0.838,3.245,0.341
				c-5.217,4.039-9.32,8.961-13.579,13.745c-2.417,2.716-6.065,4.671-9.077,5.296c-0.229-1.355-0.429-2.696-0.036-4.279
				c4.878-1.618,8.476-5.115,11.988-8.764C382.394,207.266,384.883,205.152,387.593,203.275z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(266)} fill={props.fillColors[266]} d="M243.82,149.497c0.529,0.043,0.934,0.349,1.26,0.713
				c2.369,2.647,5.47,3.795,8.854,4.394c6.808,1.206,13.609,2.439,20.419,3.628c2.072,0.361,4.398-0.524,6.244,1.71
				c-0.162,1.234-1.37,1.518-2.665,1.628c-2.988,0.252-5.962,0.039-8.799-0.911c-5.256-1.759-10.604-3.17-15.979-4.483
				c-3.998-0.976-7.312-2.896-9.548-6.447C243.676,149.651,243.749,149.574,243.82,149.497z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(267)} fill={props.fillColors[267]} d="M507.415,437.796c1.975,1.754,3.749,3.156,5.88,4.515
				c-1.192,1.398-2.7,2.394-3.525,3.946c-0.833,1.566-1.649,3.144-2.53,4.684c-0.54,0.944-1.225,1.793-2.16,2.398
				c-0.717,0.463-1.461,0.531-2.241,0.158c-0.909-0.435-0.881-1.235-0.762-2.031C502.801,446.624,504.599,442.206,507.415,437.796z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(268)} fill={props.fillColors[268]} d="M514.289,425.534c2.854-4.331,6.678-7.133,9.94-10.402
				c2.9,1.786,3.377,3.211,2.057,5.89c-1.169,2.374-2.954,4.42-3.682,7.361C519.891,427.453,517.34,426.58,514.289,425.534z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(269)} fill={props.fillColors[269]} d="M316.124,405.928c-1.285-1.54-1.499-2.784-1.391-4.062
				c0.633-7.48,0.505-14.989,1.412-22.484c0.684-5.649,0.483-11.417,1.934-17.003c0.024-0.094,0.013-0.196,0.016-0.296
				c0.131-5.092,0.131-5.092,3.016-10.132c0.167,1.256-0.072,2.133-0.317,3.001c-2.441,8.636-3.162,17.471-3.424,26.409
				C317.136,389.364,316.998,397.387,316.124,405.928z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(270)} fill={props.fillColors[270]} d="M323.127,171.252c3.731,2.165,8.133,3.332,10.241,7.78
				c0.972,2.052,2.774,3.588,4.49,5.09c1.264,1.105,2.589,2.143,3.895,3.2c0.844,0.684,1.881,1.258,1.07,2.63
				c-0.764,1.294-2.224,1.877-3.555,1.248c-0.605-0.286-1.16-0.76-1.62-1.258c-3.227-3.493-6.38-7.044-8.09-11.597
				c-0.693-1.844-2.149-3.039-3.587-4.255C324.952,173.23,323.72,172.594,323.127,171.252z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(271)} fill={props.fillColors[271]} d="M324.088,317.848c-1.495-1.182-1.912-2.466-1.917-3.887
				c-0.019-5.926-0.167-11.878,3.01-17.252c0.882-1.491,1.027-3.363,2.539-4.617C327.411,300.75,326.784,309.341,324.088,317.848z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(272)} fill={props.fillColors[272]} d="M523.667,429.048c0.598-3.514,3.004-5.926,4.351-8.858
				c0.393-0.856,1.397-1.142,2.238-1.313c2.887-0.589,5.799-0.639,8.908,0.228c-1.661,1.13-3.267,2.354-4.995,3.368
				c-2.885,1.693-4.852,3.977-5.359,7.368c-0.087,0.582-0.189,1.183-0.945,1.603C526.516,430.673,525.086,429.857,523.667,429.048z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(273)} fill={props.fillColors[273]} d="M351.358,184.054c1.298,0.641,1.154,1.418,1.167,2.064
				c0.054,2.668,0.051,5.337,0.057,8.006c0.001,0.394,0.082,0.882-0.111,1.166c-1.676,2.46-1.086,5.34-1.521,8.021
				c-0.407,2.512-1.722,4.831-1.441,7.556c-1.456-1.951-2.955-3.711-3.042-6.207c-0.056-1.577-0.231-3.16-0.5-4.715
				c-0.312-1.814,0.287-3.218,1.885-3.945c3.086-1.404,3.5-3.952,3.391-6.892C351.183,187.521,350.672,185.873,351.358,184.054z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(274)} fill={props.fillColors[274]} d="M367.793,217.102c-0.503-2.143-0.974-4.151-1.514-6.458
				c2.618-0.636,5.837-0.55,6.009-4.396c1.434-0.045,2.527-0.242,3.544,1.082c1.056,1.376,2.13,1.206,3.564,0.026
				c0.618-0.507,0.943-1.631,2.41-0.915C377.373,210.424,374.014,215.505,367.793,217.102z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(275)} fill={props.fillColors[275]} d="M365.9,179.524c-2.72-5.474-5.637-10.817-5.969-17.346
				c2.36,1.102,3.741,3.249,6.036,4.028c0.53,0.18,0.876,0.901,0.844,1.49c-0.119,2.106,2.977,3.165,1.981,5.275
				c-0.81,1.715-1.469,3.422-1.922,5.24C366.749,178.702,366.478,179.11,365.9,179.524z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(276)} fill={props.fillColors[276]} d="M212.867,575.756c3.065-5.68,5.671-11.197,9.924-15.656
				c1.921-2.015,4.815-2.43,7.464-4.214c-0.444,1.583-1.262,2.169-1.962,2.779c-3.701,3.227-6.403,7.223-8.924,11.371
				c-0.819,1.348-1.72,2.646-2.563,3.981C215.989,575.309,214.82,575.777,212.867,575.756z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(277)} fill={props.fillColors[277]} d="M545.904,413.234c-1.817,3.466-3.933,4.723-7.125,4.441
				c-3.454-0.304-6.869-0.156-10.197,0.867c-0.688-0.596-0.84-1.34-0.94-2.396C533.265,413.14,539.393,413.064,545.904,413.234z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(278)} fill={props.fillColors[278]} d="M508.254,479.395c-2.003-4.61-2.773-9.076-3.161-13.589
				c-0.261-3.041-0.059-6.119-0.157-9.177c-0.053-1.616,0.575-2.63,2.277-3.141c-0.261,3.073-0.459,6.088,0.011,9.116
				c0.38,2.45,0.786,4.908,1.856,7.125c0.977,2.022,1.003,3.926,0.322,5.967C509.035,476.8,509.167,478.054,508.254,479.395z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(279)} fill={props.fillColors[279]} d="M363.784,92.372c-1.001,0.54-1.837,0.667-3.043,0.32c0-1.989-0.107-4.053,0.021-6.102
				c0.348-5.544-1.434-10.512-3.863-15.351c-0.42-0.837-1.017-1.15-1.801-1.336c-1.744-0.412-3.408-1.04-5.003-1.851
				c-1.612-0.821-3.02-1.871-3.754-3.719c2.769,2.566,5.928,4.23,9.768,4.591c1.2-1.862,0.267-3.994,1.231-5.858
				c-0.736,5.777,1.4,10.679,4.32,15.391c1.005,1.622,1.07,3.584,1.261,5.459C363.209,86.759,363.5,89.601,363.784,92.372z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(280)} fill={props.fillColors[280]} d="M480.808,701.831c-0.462-0.814-0.599-1.736-0.767-2.666
				c-0.263-1.459-0.563-2.913-0.897-4.358c-0.444-1.921-1.473-3.306-3.284-4.312c-4.417-2.451-7.153-6.17-7.677-11.308
				c-0.181-1.778-0.937-3.157-2.302-4.315c-2.349-1.993-3.385-4.547-2.982-7.717c0.801,0.391,0.846,1.091,0.958,1.731
				c0.392,2.227,1.352,4.1,3.171,5.524c1.491,1.169,2.107,2.812,2.279,4.663c0.442,4.734,2.995,8.112,7.012,10.379
				c2.049,1.155,3.304,2.69,3.867,4.957c0.503,2.02,0.946,4.043,1.244,6.1C481.496,700.964,481.698,701.49,480.808,701.831z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(281)} fill={props.fillColors[281]} d="M536.74,448.086c2.979-0.774,4.655-1.96,3.719-5.129
				c2.903,0.967,4.915,2.63,7.393,4.041c-1.473,1.105-3.31,1.89-2.549,4.338C542.397,450.66,540.011,449.332,536.74,448.086z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(282)} fill={props.fillColors[282]} d="M530.214,445.801c4.564-1.709,4.95-2.389,4.354-7.249
				c1.647,1.176,3.286,2.045,4.385,3.629c1.915,2.761,1.031,4.653-2.355,4.934C534.488,447.289,532.56,446.529,530.214,445.801z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(283)} fill={props.fillColors[283]} d="M380.33,384.587c-2.336-6.42-2.352-13.203-3.781-19.73
				C381.11,370.805,380.942,377.682,380.33,384.587z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(284)} fill={props.fillColors[284]} d="M51.834,620.744c-2.222,0.288-4.054,0.275-6.01,0.046
				c-0.334-1.699-0.249-3.238-0.092-4.949c1.979-0.5,3.885-0.369,5.888-0.096C52.094,617.387,51.804,618.932,51.834,620.744z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(285)} fill={props.fillColors[285]} d="M86.217,615.739c2.082-0.294,3.986-0.312,6.033,0.011
				c0.278,1.656,0.244,3.188,0.027,4.864c-1.935,0.593-3.848,0.325-5.878,0.189C85.865,619.11,86.074,617.503,86.217,615.739z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(286)} fill={props.fillColors[286]} d="M604.969,620.961c-0.485-1.908-0.367-3.443-0.173-5.19
				c1.985-0.375,3.881-0.303,5.908-0.049c0.36,1.673,0.144,3.209,0.151,4.819C608.887,621.267,607.03,620.722,604.969,620.961z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(287)} fill={props.fillColors[287]} d="M618.356,620.765c-0.343-1.766-0.255-3.225-0.109-4.818
				c1.877-0.756,3.803-0.344,5.784-0.267c0.544,1.689,0.419,3.302,0.084,5.069C622.191,621.001,620.372,620.972,618.356,620.765z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(288)} fill={props.fillColors[288]} d="M645.388,620.791c-0.436-1.798-0.249-3.329-0.171-4.981
				c1.991-0.509,3.832-0.157,5.699-0.258c0.802,1.729,0.292,3.353,0.416,5.12C649.285,621.053,647.452,620.932,645.388,620.791z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(289)} fill={props.fillColors[289]} d="M570.243,615.722c0.173,1.795,0.258,3.327-0.086,5.045
				c-1.929,0.219-3.758,0.215-5.664,0.002c-0.701-1.668-0.297-3.284-0.303-4.988C566.273,615.418,568.109,615.48,570.243,615.722z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(290)} fill={props.fillColors[290]} d="M38.289,620.816c-2.164,0-3.898,0-5.786,0c-0.481-1.706-0.326-3.309-0.129-5.053
				c1.928-0.423,3.75-0.153,5.654-0.173C38.619,617.352,38.29,618.96,38.289,620.816z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(291)} fill={props.fillColors[291]} d="M65.057,620.772c-1.913,0.322-3.72,0.153-5.622,0.083
				c-0.491-1.714-0.165-3.264-0.28-4.875c1.982-0.786,3.883-0.354,5.948-0.361C65.381,617.419,65.416,619.017,65.057,620.772z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(292)} fill={props.fillColors[292]} d="M72.823,615.653c2.062-0.151,3.809-0.181,5.67,0.027
				c0.648,1.613,0.321,3.234,0.208,4.973c-1.891,0.577-3.719,0.172-5.65,0.267C72.389,619.159,72.664,617.544,72.823,615.653z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(293)} fill={props.fillColors[293]} d="M577.863,620.782c-0.355-1.817-0.219-3.342-0.134-5.054
				c1.99-0.291,3.808-0.219,5.798-0.075c0.541,1.662,0.404,3.26,0.077,4.965C581.75,621.121,579.929,620.913,577.863,620.782z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(294)} fill={props.fillColors[294]} d="M637.628,620.781c-1.972,0-3.715,0-5.611,0c-0.568-1.667-0.315-3.281-0.195-5.071
				c1.989-0.254,3.813-0.229,5.812-0.023C637.908,617.36,637.873,618.904,637.628,620.781z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(295)} fill={props.fillColors[295]} d="M597.123,620.765c-1.925,0.234-3.734,0.188-5.665,0.047
				c-0.414-1.689-0.247-3.232-0.161-4.882c1.881-0.771,3.792-0.215,5.846-0.368C597.437,617.398,597.453,618.995,597.123,620.765z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(296)} fill={props.fillColors[296]} d="M99.825,620.665c-0.239-1.706-0.234-3.236-0.004-4.959
				c1.932-0.258,3.747-0.199,5.534-0.065C106.613,620.665,105.773,621.445,99.825,620.665z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(297)} fill={props.fillColors[297]} d="M663.953,620.744c-1.907,0.223-3.439,0.273-5.099-0.023
				c-0.437-1.634-0.169-3.177-0.202-4.817c1.753-0.611,3.371-0.346,5.121-0.234C664.159,617.354,663.914,618.905,663.953,620.744z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(298)} fill={props.fillColors[298]} d="M19.617,620.873c-0.288-1.899-0.269-3.426-0.06-5.095
				c1.734-0.382,3.336-0.275,5.097-0.096c0.267,1.777,0.233,3.376-0.011,5.088C22.917,621.071,21.389,620.923,19.617,620.873z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(299)} fill={props.fillColors[299]} d="M334.524,331.919c-0.121-2.737-2.662-5.123-1.245-8.001
				c0.625-1.269,1.022-2.799,2.964-3.41C337.521,324.681,335.682,328.241,334.524,331.919z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(300)} fill={props.fillColors[300]} d="M241.792,79.575c0.04,1.329-0.467,2.019-1.133,2.89
				c-3.747-0.833-7.557-1.681-11.367-2.529c0.011-0.12,0.022-0.241,0.034-0.36C233.42,79.575,237.515,79.575,241.792,79.575z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(301)} fill={props.fillColors[301]} d="M207.991,570.442c-0.343,5.062,2.235,10.062-1.46,14.692
				c0-1.882,0.048-3.64-0.013-5.393c-0.064-1.874-0.402-3.756-0.083-5.617C206.641,572.92,206.722,571.594,207.991,570.442z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(302)} fill={props.fillColors[302]} d="M361.095,184.154c0.799,3.23,1.545,6.38,0.345,10.14
				c-1.495-2.976-2.171-5.765-3.12-8.351C359.046,184.96,359.774,184.381,361.095,184.154z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(303)} fill={props.fillColors[303]} d="M307.924,234.722c0.396-0.818,0.562-1.406,1.426-1.694
				c1.789,1.101,3.704,2.278,5.604,3.447c0.345,1.335-0.359,2.303-0.73,3.66C312.015,238.237,310.024,236.526,307.924,234.722z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(304)} fill={props.fillColors[304]} d="M315.924,485.255c3.243,4.241,3.016,9.069-0.767,13.638
				c0.714-1.866,1.123-3.351,1.151-4.938c0.027-1.504,0.048-2.958-0.792-4.293C314.545,488.117,315,486.738,315.924,485.255z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(305)} fill={props.fillColors[305]} d="M172.243,642.458c0.152,2.589-0.391,4.717-0.85,6.969
				C168.836,647.521,169.106,645.211,172.243,642.458z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(306)} fill={props.fillColors[306]} d="M314.251,500.694c0.134,2.965-0.491,5.664-3.085,7.991
				C311.242,505.414,314.12,503.573,314.251,500.694z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(307)} fill={props.fillColors[307]} d="M266.694,479.164c0.403,1.285,0.807,2.571,1.255,4.003
				c-0.698,0.735-1.424,1.499-2.488,2.619C265.519,483.201,266.497,481.266,266.694,479.164z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(308)} fill={props.fillColors[308]} d="M118.705,250.774c-0.538,1.228-1.158,2.348-3.162,2.492
				c0.952-1.538,1.895-2.242,3.214-2.435C118.758,250.832,118.705,250.774,118.705,250.774z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(309)} fill={props.fillColors[309]} d="M338.032,219.198c0.171-0.87,0.298-1.509,0.428-2.167
				c0.81,0.102,0.452,0.676,0.471,1.031C338.95,218.447,338.823,218.834,338.032,219.198z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(310)} fill={props.fillColors[310]} d="M243.605,149.729c-0.866-0.219-1.44-0.827-2.286-1.826
				c1.275,0.346,1.992,0.789,2.501,1.593C243.748,149.574,243.676,149.651,243.605,149.729z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(311)} fill={props.fillColors[311]} d="M394.146,95.225c-1-0.343-1.618,0.897-2.728,0.771c0.717-1.507,1.75-1.033,2.732-0.77
				C394.152,95.226,394.146,95.225,394.146,95.225z"/>
			<path fill-rule="evenodd" d="M414.831,296.918c0.544-1.229,1.67-1.018,2.404-1.679c0.92-5.792,4.55-9.941,9.187-13.49
				c-1.216,2.595-2.432,5.19-3.939,8.41c2.419-1.189,4.035-2.342,6.345-2.483c-1.632,2.611-4.07,4.254-5.495,6.823
				c2.183,1.211,4.345,0.687,6.462,1.16c-0.07,0.735-0.329,1.335-1.155,1.279c-2.218-0.15-4.302,0.557-6.445,0.884
				C419.713,298.202,417.261,298.167,414.831,296.918z"/>
			<path fill-rule="evenodd" d="M517.021,147.777c-2.457-1.092-4.472-1.988-6.65-2.957c-2.571,1.24-3.978,3.911-6.183,5.762
				c-0.282-2.1,0.002-2.9,1.078-4.896c0.769-1.425,2.638-2.232,2.188-4.378c1.682-0.031,3.134-0.113,4.634-0.504
				c2.331-0.607,4.913-0.399,7.446,0.782c-1.729,1.368-4.244,0.147-5.717,2.019C513.863,145.542,516.213,145.727,517.021,147.777z"/>
			<path fill-rule="evenodd" d="M520.181,255.688c-0.916,2.729,0.354,6.023-2.591,8.444c-0.096-1.68-0.178-3.016-0.248-4.353
				c-0.078-1.491-0.872-2.066-2.337-2.009c-1.042,0.041-2.096,0.425-3.413,0.031c0.973-1.333,2.758-0.942,3.639-2.21
				c-0.464-1.736-2.717-1.853-3.295-3.506C515.357,251.676,517.341,254.573,520.181,255.688z"/>
			<path fill-rule="evenodd" d="M501.128,256.124c1.003,1.903,3.133,3.07,2.694,5.594c-1.611-0.367-1.986-2.276-3.554-2.541
				c-1.411,0.111-1.901,1.63-3.286,2.211c-0.248-1.219,1.249-1.807,0.93-3.049c-1.146-0.673-2.41-0.025-3.854-0.125
				C496.051,256.047,498.77,256.856,501.128,256.124z"/>
			<path fill-rule="evenodd" d="M581.415,168.22c0.663-1.061,2.044-0.569,2.569-1.6c-0.229-1.275-1.881-1.329-2.318-2.601
				c2.593-0.261,4.03,1.816,6.058,2.877c-0.189,1.986,0.574,4.211-1.394,6.111c-0.55-0.654-0.385-1.35-0.447-1.959
				c-0.272-2.678-0.463-2.853-3.075-2.666c-0.295,0.021-0.589,0.066-0.884,0.076C581.836,168.462,581.748,168.381,581.415,168.22z"/>
			<path fill-rule="evenodd" d="M501.826,267.84c-1.106-0.177-1.379-1.132-2.147-1.377c-0.896-0.284-1.219,0.9-2.104,0.807
				c0.102-0.373,0.194-0.72,0.292-1.081c-0.21-0.952-1.325-0.136-1.666-0.835C499.644,263.818,500.563,264.196,501.826,267.84z"/>
			<path fill-rule="evenodd" d="M172.198,159.863c0.654-2.226,1.165-3.914,1.644-5.611c0.736-2.609,0.143-3.242-2.608-2.696
				c-1.356,0.269-2.679,0.708-4.032,1c-0.506,0.109-1.121,0.59-1.694-0.355c2.159-1.758,5.381-1.637,7.477-3.857
				c-1.523-1.821-3.673-2.426-5.38-3.717c3.778-0.749,7.359-0.162,9.882,2.043c1.096,0.958,2.315,0.07,3.485,0.927
				c-1.654,1.003-1.927,2.609-2.481,4.274C177.467,154.946,175.639,157.737,172.198,159.863z"/>
			<path fill-rule="evenodd" d="M210.127,253.528c5.091-0.12,8.5,3.03,12.603,4.818c-1.644,3.583-5.869,3.389-8.66,5.453
				c0.252-1.74,2.212-2.039,2.814-3.735c-1.736-1.041-3.79-0.97-5.565-1.708c0.872-1.354,2.375-0.778,3.794-1.247
				C213.903,255.447,211.841,255.085,210.127,253.528z"/>
			<path fill-rule="evenodd" d="M211.125,412.122c-0.25-0.231-0.467-0.335-0.499-0.479c-0.842-3.908-0.839-3.909-4.536-3.793
				c0.207-1.125,1.743-0.795,2.045-1.879c-0.625-1.209-2.146-1.354-2.976-2.542c2.756-0.169,4.689,1.53,6.847,2.618
				C211.845,408.063,212.903,410.285,211.125,412.122z"/>
			<path fill-rule="evenodd" d="M164.376,134.464c0.443-1.16,1.878-0.766,2.439-1.875c-0.452-1.187-2.092-1.224-2.759-2.48
				c2.584-0.253,4.393,1.406,6.585,2.566c-0.291,1.856,0.848,4.129-0.907,6.044c-0.61-0.338-0.5-0.874-0.597-1.31
				c-0.729-3.27-0.723-3.266-3.97-2.814C164.927,134.629,164.667,134.516,164.376,134.464z"/>
			<path fill-rule="evenodd" d="M87.292,116.935c2.521-0.505,3.969,1.569,5.959,2.143c-0.227,2.105,0.779,4.347-1.108,6.456
				c-0.374-1.552-0.684-2.839-0.981-4.073c-1.233-1.2-2.702-0.02-4.019-0.519c0.301-1.148,1.804-0.714,2.212-1.795
				C89.225,118.062,87.776,118.171,87.292,116.935z"/>
			<path fill-rule="evenodd" d="M90.339,182.556c2.031-0.78,3.563,0.366,5.124,1.342c-1.144,2.075-1.911,4.47-4.089,6.038
				c-0.592-1.386,1.111-2.35,0.456-3.63c-0.827-0.521-1.682,0.614-2.583-0.122c0.861-0.513,1.692-1.01,2.45-1.462
				C91.9,183.478,90.326,183.549,90.339,182.556z"/>
			<path fill-rule="evenodd" d="M352.401,589.426c1.869,0.298,2.14,1.919,3.661,2.127c0.626-1.432,1.238-2.957,1.953-4.434
				c0.855-1.766,2.099-2.2,3.543-0.879c2.276,2.082,4.98,3.592,7.204,5.709c0.784,0.746,1.587,1.538,2.131,2.458
				c2.534,4.276,6.363,6.528,11.093,7.683c4.451,1.086,8.58,2.825,11.873,6.291c2.6,2.737,3.22,6.066,2.588,9.354
				c-0.521,2.703-3.209,4.116-5.79,4.985c-1.099,0.369-2.346,0.32-3.529,0.41c-2.781,0.209-5.012-0.531-6.414-3.246
				c-0.714-1.382-1.88-2.538-2.883-3.762c-0.687-0.838-1.457-1.598-2.758-1.726c-0.68,0.94-0.682,1.786-0.078,2.729
				c0.64,0.997,1.261,2.016,1.777,3.08c0.832,1.714,0.63,2.622-0.715,3.981c-1.632,1.647-2.771,1.488-3.67-0.698
				c-0.351-0.855-0.854-1.235-1.628-1.52c-2.115-0.778-3.219-2.463-3.175-4.573c0.099-4.687-1.934-7.945-6.024-10.047
				c-0.953-0.489-1.59-1.317-1.623-2.339c-0.064-1.972-1.175-3.281-2.442-4.58c-1.174-1.202-2.303-2.454-3.395-3.733
				C352.317,594.61,352.054,592.198,352.401,589.426z"/>
			<path fill-rule="evenodd" d="M362.25,520.576c0.299,2.394-1.017,3.922-2.715,5.283c-2.09,1.675-3.644,3.901-5.757,5.574
				c-3.995,3.164-6.758,3.431-11.144,0.893c-0.514-0.298-0.987-0.704-1.537-0.896c-3.654-1.273-5.386-4.003-6.296-7.622
				c-0.414-1.646-0.35-2.947,0.829-4.055c1.879-1.767,2.063-3.786,1.657-6.236c-1.047-6.317,5.311-12.214,11.677-11.009
				c0.776,0.147,1.54,0.389,2.322,0.482c0.811,0.097,1.635,0.005,1.979-0.914c0.404-1.077,0.935-2.001,2.223-2.098
				c1.708-0.128,3.393,0.021,4.844,1.021c1.407,0.97,2.596,2.213,3.18,3.836c1.567,4.36,3.59,8.625,3.161,13.46
				c-0.034,0.392,0.039,0.792,0.021,1.188c-0.042,0.903-0.121,1.828-1.062,2.291c-1.045,0.514-1.656-0.214-2.243-0.903
				C363.187,520.635,362.98,520.465,362.25,520.576z"/>
			<path fill-rule="evenodd" d="M344.667,538.135c4.081-0.022,8.696,3.304,9.646,6.79c0.674,2.475,0.108,4.711-1.768,6.531
				c-1.311,1.271-2.88,2.127-4.668,2.441c-2.407,0.425-4.479,1.375-6.24,3.119c-1.775,1.759-4.009,1.982-6.363,1.49
				c-2.745-0.574-4.139-1.997-4.557-4.763c-0.319-2.108-0.036-4.176,0.943-6.064c2.39-4.606,6.081-7.723,11.055-9.255
				C343.459,538.195,344.274,538.189,344.667,538.135z"/>
			<path fill-rule="evenodd" d="M331.343,569.131c-1.734,4.383-0.313,9.273-2.351,13.633c-0.589,1.261-1.027,2.58-2.059,3.557
				c-1.424,1.348-3.036,1.314-3.995-0.331c-1.011-1.738-2.196-3.255-3.687-4.584c-0.672-0.6-1.072-1.412-1.054-2.372
				c0.009-0.493,0.156-1.093-0.063-1.465c-1.62-2.751,0.043-4.683,1.588-6.631c1.415-1.783,2.889-3.519,4.34-5.273
				c0.572-0.692,1.202-1.321,2.033-1.699c1.831-0.832,3.291-0.358,4.218,1.434C330.911,566.549,331.304,567.775,331.343,569.131z"/>
			<path fill-rule="evenodd" d="M443.578,518.804c-1.036-1.329-2.387-2.146-3.721-2.969c-2.964-1.828-4.995-4.366-6.419-7.564
				c-1.808-4.062-4.348-7.752-6.146-11.848c-1.938-4.413-1.557-7.461,2.017-10.592c0.996-0.872,1.746-1.819,2.31-3.019
				c1.776-3.782,4.23-4.396,7.538-2.016c1.69,1.217,3.334,2.479,4.863,3.902c1.398,1.301,2.569,2.576,1.257,4.419
				c0.424,1.098,1.312,1.185,2.05,1.243c3.097,0.244,5.137,2.056,6.552,4.555c1.902,3.358,3.449,6.904,4.835,10.512
				c0.77,2.002,0.563,4.103,0.474,6.179c-0.056,1.275-0.861,2.12-1.735,2.987c-2.326,2.306-2.13,4.25,0.575,6.062
				c0.729,0.487,1.614,0.735,2.417,1.115c3.993,1.892,6.511,6.016,6.104,10.301c-0.124,1.303,0.219,2.096,1.338,2.639
				c2.578,1.252,4.052,3.456,4.97,6.037c1.032,2.901,2.317,5.682,3.652,8.447c1.088,2.253,1.816,4.612,1.798,7.143
				c-0.05,6.619,2.653,12.298,6.061,17.727c0.579,0.922,1.331,1.738,1.47,2.871c0.226,1.842-0.774,2.893-2.604,2.51
				c-6.86-1.437-13.333-3.797-18.824-8.339c-1.381-1.142-2.663-2.411-3.602-3.935c-1.87-3.032-4.385-5.625-5.924-8.888
				c-0.681-1.442-1.921-2.512-3.067-3.608c-1.994-1.905-3.365-4.12-3.714-6.921c-0.368-2.954-2.056-5.341-3.587-7.755
				c-3.086-4.863-4.371-10.134-3.956-15.855c0.131-1.804,0.142-3.614,2.327-4.329C443.167,519.723,443.311,519.211,443.578,518.804z"
				/>
			<path fill-rule="evenodd" d="M500.936,604.292c2.48-0.425,4.644,0.903,6.135,3.669c1.419,2.633,2.511,5.387,3.329,8.246
				c0.553,1.929,1.511,3.527,2.684,5.187c2.07,2.926,3.013,6.406,2.807,10.083c-0.071,1.262-0.854,1.901-1.991,2.293
				c-3.394,1.17-6.53,2.854-9.449,4.933c-2.618,1.864-5.271,1.979-8.084,0.428c-5.201-2.867-8.692-6.836-8.762-13.12
				c-0.025-2.283-1.045-4.215-2.318-6.013c-1.377-1.944-1.925-4.082-1.874-6.441c0.058-2.621,0.694-4.813,3.203-6.251
				c1.69-0.968,1.62-1.976,0.146-3.224c-0.703-0.595-1.507-0.905-2.423-1.024c-2.919-0.381-5.436-1.822-7.962-3.177
				c-1.823-0.978-2.937-2.671-3.706-4.525c-1.742-4.198-4.138-8.144-4.997-12.689c-0.214-1.129-0.797-2.012-1.697-2.706
				c-0.313-0.242-0.623-0.494-0.905-0.772c-0.979-0.965-1.752-2.16-0.895-3.42c0.877-1.289,2.186-0.554,3.207,0.004
				c3.229,1.761,6.562,3.209,10.111,4.238c3.633,1.053,7.373,1.514,11.044,2.346c4.36,0.987,7.556,3.305,9.599,7.272
				c0.544,1.055,1.149,2.093,1.852,3.046c2.125,2.889,1.262,5.025-1.066,7.322c-0.349,0.345-0.799,0.584-1.197,0.879
				c-0.898,0.664-1.256,1.569-0.893,2.612c0.363,1.045,1.291,1.089,2.231,0.978C499.458,604.417,499.854,604.39,500.936,604.292z"/>
			<path fill-rule="evenodd" d="M422.262,489.416c-1.111-0.736-1.056-1.666-0.944-2.541c0.273-2.159,0.334-4.403,1.002-6.441
				c1.363-4.159,0.433-7.999-1.145-11.772c-0.475-1.133-0.812-2.195-0.705-3.296c-4.096-2.896-3.828-7.118-3.511-11.197
				c0.128-1.635,0.686-3.245,0.63-4.957c-0.042-1.288,0.429-2.542,1.113-3.646c0.938-1.514,2.28-1.742,3.407-0.444
				c2.135,2.46,4.359,4.883,4.337,8.513c-0.012,1.894,0.909,3.395,2.685,4.304c3.315,1.697,3.968,4.953,4.656,8.159
				c0.101,0.47-0.174,1.044-0.014,1.473c1.336,3.577-0.481,6.063-2.795,8.415c-2.28,2.317-3.633,5.346-5.879,7.702
				c-0.76,0.797-1.076,1.863-1.18,2.989C423.819,487.773,423.527,488.809,422.262,489.416z"/>
			<path fill-rule="evenodd" d="M515.296,601.479c3.026,0.778,6.326,3.473,7.841,6.572c1.3,2.658,2.361,5.434,3.547,8.148
				c0.411,0.941,0.351,1.785-0.562,2.333c-1.278,0.77-2.396,1.742-3.59,2.615c-1.017,0.743-1.836,0.294-2.069-1.017
				c-0.086-0.481-0.08-0.989-0.048-1.48c0.31-4.836-1.32-9.116-3.698-13.205C516.055,604.307,514.917,603.311,515.296,601.479z"/>
			<path fill-rule="evenodd" d="M262.581,588.975c5.21,1.875,5.485,3.234,1.741,6.974c-1.688,1.687-3.464,3.247-5.354,4.703
				c-3.722,2.866-6.742,6.407-9.373,10.269c-1.258,1.848-2.644,3.45-4.771,4.301c-1.323,0.528-2.335,1.473-3.217,2.57
				c-1.636,2.037-3.868,3.042-6.337,3.598c-2.319,0.522-4.529-0.039-5.96-1.808c-1.36-1.682-1.419-3.872-0.399-5.916
				c0.308-0.617,0.613-1.278,1.08-1.765c4.104-4.276,6.887-9.437,9.672-14.57c1.908-3.519,4.576-6.068,7.958-8.181
				c4.432-2.768,9.099-5.277,12.831-9.025c3.899-3.917,7.734-7.858,9.396-13.437c1.075-3.609,3.177-6.827,5.333-9.896
				c2.056-2.927,3.686-6.097,5.654-9.067c1.154-1.74,1.811-3.712,2.319-5.754c0.754-3.027,2.081-5.791,4.545-7.839
				c0.881-0.731,1.772-1.775,3.124-0.899c0.964,0.625,1.397,2.462,0.897,3.814c-0.203,0.551-0.602,1.025-0.847,1.564
				c-0.243,0.533-0.584,1.107-0.572,1.656c0.108,5.076-1.791,9.623-3.536,14.242c-1.816,4.807-3.828,9.574-6.139,14.104
				c-2.127,4.172-4.058,8.786-8.557,11.419c-2.376,1.391-4.479,3.268-6.621,5.029C264.254,586.044,263.069,587.121,262.581,588.975z"
				/>
			<path fill-rule="evenodd" d="M406.924,377.755c0.476,0.791,0.927,1.095,1.426,1.005c2.282-0.413,3.604,0.939,4.553,2.627
				c3.978,7.079,8.269,13.962,12.611,20.818c0.873,1.379,1.577,2.961,0.624,4.603c-1.197,0.253-1.628-0.539-1.961-1.198
				c-2.064-4.093-5.858-5.009-9.899-5.5c-1.375-0.167-2.758-0.275-4.131-0.46c-1.867-0.251-3.354-1.201-4.457-2.695
				c-3.194-4.328-3.839-9.221-3.113-14.407c0.128-0.914,0.831-1.549,1.126-2.29c-0.298-1.141-0.968-1.96-1.854-2.353
				c-2.176-0.964-2.575-2.795-2.728-4.843c-0.207-2.767-0.46-5.529-0.673-8.295c-0.288-3.726-1.639-6.715-5.428-8.107
				c-0.544-0.2-1.056-0.563-1.5-0.946c-1.236-1.063-1.115-1.979,0.353-2.695c0.709-0.346,1.454-0.627,2.204-0.875
				c2.432-0.803,4.242-0.226,5.609,2.003c0.874,1.426,1.554,2.985,2.206,4.534c1.841,4.371,3.145,8.96,5.69,13.043
				C408.84,373.74,408.687,375.904,406.924,377.755z"/>
			<path fill-rule="evenodd" d="M346.875,412.032c-0.081-0.925,0.248-1.738,0.635-2.557c1.12-2.368,1.067-3.832-1.071-5.073
				c-3.117-1.809-3.781-4.467-4.051-7.689c-0.256-3.061-1.012-6.062,0.544-9.013c0.597-1.133-0.077-2.217-0.809-2.979
				c-3.242-3.381-2.976-6.979-0.608-10.562c3.3-4.992,2.691-10.386,1.93-15.815c-0.221-1.575-0.493-3.135-0.366-4.726
				c0.147-1.854,0.889-3.47,2.301-4.679c1.003-0.858,1.318-1.843,1.312-3.129c-0.011-1.876,0.008-3.766,0.411-5.622
				c0.252-1.162,0.674-2.183,2.125-1.87c1.261,0.271,1.404,1.291,1.154,2.341c-0.404,1.693-0.62,3.219,0.99,4.559
				c0.94,0.783,0.715,2.056,0.102,3.029c-1.433,2.276-1.211,4.634-0.872,7.165c0.553,4.129,0.651,8.299-0.629,12.372
				c-1.122,3.57-0.566,6.79,1.847,9.753c1.37,1.682,2.462,3.593,3.651,5.419c0.321,0.494,0.63,1.027,0.795,1.587
				c1.39,4.716,2.772,9.434,4.092,14.17c0.433,1.553,0.396,3.136-0.297,4.656c-0.607,1.334-0.213,2.266,1.147,2.856
				c3.206,1.394,5.193,3.873,6.22,7.163c0.391,1.251,1.12,2.301,1.796,3.399c0.985,1.601,0.79,3.117-0.414,4.332
				c-1.162,1.171-2.35,0.933-3.794,0.389c-4.997-1.882-10.06-3.587-15.093-5.372C347.499,415.279,346.922,414.485,346.875,412.032z"/>
			<path fill-rule="evenodd" d="M207.8,544.14c0.308-2.725,0.492-5.49,0.957-8.207c0.682-3.98,3.163-6.519,6.98-7.655
				c2.829-0.842,4.192,0.531,3.543,3.438c-0.373,1.673-1.12,3.211-2.458,4.302c-2.53,2.063-3.747,4.749-4.139,7.914
				c-0.133,1.076-0.349,2.178-1.246,2.929c-0.692,0.579-1.494,1.069-2.435,0.705c-0.828-0.32-1.076-1.096-1.134-1.925
				C207.835,545.149,207.823,544.655,207.8,544.14z"/>
			<path fill-rule="evenodd" d="M300.839,425.724c-0.097,1.228-1.373,1.367-1.502,2.554c1.874,0.127,2.788,1.153,2.594,3.069
				c-0.028,0.273,0.098,0.597,0.24,0.847c2.355,4.143,0.644,7.775-1.426,11.351c-0.409,0.705-1.138,1.052-1.866,1.407
				c-5.027,2.452-9.887,1.32-14.591-0.844c-2.512-1.156-2.397-5.212,0.062-7.032c0.791-0.585,1.741-0.952,2.548-1.52
				c1.99-1.397,1.939-2.564-0.118-3.766c-0.426-0.25-0.9-0.418-1.327-0.668c-2.157-1.266-2.849-2.541-2.339-4.916
				c0.315-1.467,0.455-2.912,0.524-4.402c0.145-3.157,2.583-6.107,5.33-6.795c1.446-0.362,2.755-0.177,3.181,1.306
				c1.125,3.92,3.635,6.407,7.34,7.885C300.098,424.441,300.681,424.926,300.839,425.724z"/>
			<path fill-rule="evenodd" d="M342.431,568.33c-0.543-4.062,2.247-6.522,5.464-8.079c2.933-1.42,6.16-1.06,8.841,1.409
				c2.966,2.729,6.394,1.803,7.914-2.029c0.382-0.96,0.105-2.348,1.48-2.679c1.661-0.398,3.434-0.618,4.841,0.58
				c2.957,2.516,6.377,2.5,9.859,1.932c6.703-1.094,12.768,0.409,18.339,4.177c5.47,3.699,10.237,8.07,13.744,13.764
				c1.751,2.842,2.238,5.898,2.177,9.091c-0.033,1.756-0.982,2.218-2.607,1.515c-0.36-0.156-0.677-0.412-1.025-0.598
				c-4.4-2.344-8.378-1.695-11.271,2.3c-1.767,2.439-4.056,3.995-6.541,5.442c-3.092,1.801-6.049,1.65-8.792-0.676
				c-2.793-2.368-5.561-4.769-8.275-7.227c-2.373-2.149-4.243-4.698-5.579-7.624c-0.921-2.017-2.221-3.747-3.983-5.102
				c-2.686-2.064-5.406-1.81-7.584,0.801c-1.204,1.443-2.273,2.994-3.712,4.236c-3.005,2.597-7.394,2.356-9.671-0.828
				C343.908,575.74,342.219,572.466,342.431,568.33z"/>
			<path fill-rule="evenodd" d="M506.711,427.812c-0.407-0.01-0.652-0.196-0.695-0.479c-0.064-0.425,0.177-0.766,0.617-0.704
				c0.208,0.028,0.51,0.371,0.517,0.581C507.157,427.42,506.848,427.64,506.711,427.812z"/>
			<path fill-rule="evenodd" d="M660.143,150.088c-1.116-2.267-2.234-4.531-3.347-6.8c-0.287-0.585-0.165-1.164,0.108-1.711
				c1.052-2.104,2.116-4.202,3.453-6.853c0.717,2.949,0.338,5.346,0.407,7.705c0.072,2.469,0.017,4.942,0.017,7.414
				C660.568,149.925,660.356,150.006,660.143,150.088z"/>
			<path fill-rule="evenodd" d="M660.694,195.297c0,4.885,0,9.769,0,15.18c-1.868-2.486-2.736-4.96-3.926-7.249
				c-0.235-0.455-0.178-0.968,0.049-1.428c1.107-2.253,2.226-4.501,3.341-6.751C660.336,195.132,660.515,195.215,660.694,195.297z"/>
			<path fill-rule="evenodd" d="M23.31,149.893c0-4.857,0-9.714,0-14.571c0.165-0.052,0.331-0.104,0.496-0.155
				c1.118,2.268,2.235,4.536,3.354,6.803c0.302,0.611,0.062,1.154-0.207,1.69c-1.059,2.111-2.123,4.219-3.185,6.328
				C23.616,149.957,23.463,149.925,23.31,149.893z"/>
			<path fill-rule="evenodd" d="M23.319,269.503c0-4.851,0-9.702,0-14.553c0.164-0.05,0.328-0.1,0.492-0.149
				c1.084,2.173,2.158,4.351,3.257,6.516c0.355,0.7,0.209,1.323-0.12,1.971c-1.069,2.101-2.125,4.209-3.185,6.314
				C23.616,269.569,23.468,269.536,23.319,269.503z"/>
			<path fill-rule="evenodd" d="M23.771,434.373c1.056,2.094,2.105,4.189,3.171,6.278c0.328,0.643,0.488,1.262,0.136,1.958
				c-1.13,2.242-2.239,4.496-3.807,6.586c0-4.915,0-9.829,0-14.744C23.438,434.425,23.604,434.398,23.771,434.373z"/>
			<path fill-rule="evenodd" d="M23.752,553.934c1.1,2.193,2.192,4.392,3.307,6.578c0.294,0.578,0.309,1.121,0.021,1.694
				c-1.096,2.188-2.185,4.379-3.739,6.453c0-4.857,0-9.715,0-14.572C23.479,554.035,23.615,553.984,23.752,553.934z"/>
			<path fill-rule="evenodd" d="M23.746,314.648c1.146,2.333,2.292,4.667,3.44,7c0.19,0.387,0.138,0.786-0.035,1.138
				c-1.141,2.327-2.149,4.729-3.878,7.344c0-5.479,0-10.397,0-15.314C23.431,314.76,23.588,314.704,23.746,314.648z"/>
			<path fill-rule="evenodd" d="M23.22,389.129c0-4.894,0-9.787,0-15.028c1.295,0.97,1.431,2.428,2.18,3.452
				c2.026,2.771,2.189,5.488,0.075,8.332c-0.741,0.996-1.15,2.239-1.711,3.37C23.582,389.213,23.401,389.171,23.22,389.129z"/>
			<path fill-rule="evenodd" d="M23.744,195.115c1.053,2.091,2.107,4.182,3.157,6.275c0.314,0.626,0.556,1.245,0.196,1.961
				c-1.133,2.254-2.241,4.521-3.8,6.647c0-4.919,0-9.837,0-14.756C23.446,195.2,23.595,195.157,23.744,195.115z"/>
			<path fill-rule="evenodd" d="M23.725,493.859c1.091,2.177,2.176,4.355,3.278,6.526c0.338,0.667,0.346,1.302,0.008,1.97
				c-1.1,2.172-2.184,4.353-3.677,6.439c0-4.913,0-9.826,0-14.739C23.465,493.99,23.595,493.925,23.725,493.859z"/>
			<path fill-rule="evenodd" d="M660.684,255.096c0,4.809,0,9.619,0,14.975c-1.86-2.533-2.778-4.995-3.914-7.329
				c-0.152-0.313-0.159-0.826-0.01-1.14C657.841,259.327,658.829,256.995,660.684,255.096z"/>
			<path fill-rule="evenodd" d="M660.7,554.102c0,4.806,0,9.611,0,15.027c-1.601-1.713-1.951-3.589-2.959-5.028
				c-1.31-1.871-1.198-3.576,0.006-5.464c0.941-1.474,1.604-3.127,2.39-4.7C660.325,553.991,660.512,554.046,660.7,554.102z"/>
			<path fill-rule="evenodd" d="M660.182,389.303c-1.097-2.18-2.191-4.359-3.288-6.539c-0.335-0.666-0.331-1.326,0.006-1.993
				c1.062-2.1,2.117-4.202,3.468-6.885c0.688,3.015,0.332,5.427,0.397,7.804c0.067,2.473,0.015,4.948,0.015,7.422
				C660.581,389.175,660.381,389.239,660.182,389.303z"/>
			<path fill-rule="evenodd" d="M660.674,314.833c0,4.879,0,9.759,0,14.825c-1.293-0.476-1.245-1.846-1.886-2.683
				c-2.462-3.223-2.377-6.416,0.014-9.655c0.567-0.77,0.897-1.714,1.336-2.579C660.317,314.772,660.496,314.803,660.674,314.833z"/>
			<path fill-rule="evenodd" d="M660.643,434.45c0,4.914,0,9.827,0,15.295c-1.786-2.622-2.747-5.063-3.899-7.398
				c-0.286-0.58-0.038-1.16,0.228-1.692c1.046-2.099,2.104-4.19,3.158-6.284C660.3,434.396,660.472,434.423,660.643,434.45z"/>
			<path fill-rule="evenodd" d="M660.681,494.115c0,4.879,0,9.759,0,15.175c-1.848-2.522-2.741-4.985-3.917-7.287
				c-0.232-0.457-0.17-0.969,0.057-1.428c1.109-2.249,2.227-4.492,3.342-6.737C660.335,493.931,660.508,494.022,660.681,494.115z"/>
			<path fill-rule="evenodd" d="M317.812,648.575c4.441,2.131,6.681,7.401,5.062,11.835c-1.002,2.747-0.927,5.422,0.362,8.072
				c0.351,0.721,1.227,1.44,0.325,2.362c-0.834,0.193-1.02-0.501-1.282-0.973c-1.633-2.947-3.207-5.927-4.851-8.867
				c-1.536-2.747-1.909-5.616-0.913-8.593C316.942,651.138,316.754,649.631,317.812,648.575z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(312)} fill={props.fillColors[312]} d="M376.293,621.83c-0.543,1.112-1.073,2.029-2.511,2.34
				c-0.327-1.868-1.374-2.927-3.126-3.523c-1.443-0.492-1.998-1.91-1.964-3.384c0.117-4.982-2.145-8.5-6.464-10.811
				c-0.748-0.399-1.232-1.012-1.257-1.883c-0.058-2.057-1.269-3.464-2.607-4.831c-0.97-0.989-1.811-2.108-2.816-3.056
				c-1.382-1.301-1.985-2.867-2.064-4.681c0.662-0.38,0.96,0.156,1.324,0.398c1.25,0.83,2.045,0.338,2.568-0.866
				c0.701-1.615,1.175-3.339,2.186-4.727c0.324-0.022,0.588-0.124,0.689-0.036c3.785,3.253,8.333,5.548,11.059,10.13
				c1.901,3.195,5.423,4.892,8.975,5.944c1.984,0.589,3.967,1.213,5.968,1.758c3.329,0.907,5.653,3.27,7.742,5.757
				c1.666,1.983,1.936,4.69,1.379,7.082c-0.511,2.191-2.657,3.379-4.715,4.132c-1.173,0.43-2.53,0.364-3.809,0.492
				c-2.294,0.23-3.949-0.583-5.146-2.679c-1.073-1.881-2.427-3.64-4.123-5.03c-0.955-0.782-2.042-1.807-3.409-0.673
				c-0.948,0.787-1.096,2.502-0.232,3.81C374.818,618.822,375.565,620.203,376.293,621.83z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(313)} fill={props.fillColors[313]} d="M364.899,520.644c-0.258-0.223-0.511-0.378-0.682-0.599
				c-0.494-0.641-0.996-1.341-1.923-1.05c-0.861,0.271-1.216,1.051-1.17,1.885c0.104,1.929-0.973,3.11-2.357,4.199
				c-2.107,1.657-3.634,3.916-5.773,5.565c-3.603,2.776-5.86,3.001-9.783,0.745c-0.602-0.346-1.162-0.797-1.804-1.033
				c-3.207-1.182-4.739-3.592-5.531-6.807c-0.332-1.348-0.264-2.321,0.687-3.209c2.039-1.904,2.227-4.189,1.837-6.815
				c-0.879-5.914,4.621-10.999,10.517-9.892c0.876,0.164,1.76,0.363,2.645,0.391c1.238,0.037,2.414-0.2,2.82-1.642
				c0.393-1.396,1.425-1.396,2.594-1.313c2.558,0.18,4.364,1.586,5.294,3.779c1.971,4.652,3.811,9.385,3.345,14.616
				C365.578,519.854,365.581,520.269,364.899,520.644z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(314)} fill={props.fillColors[314]} d="M331.749,551.898c-0.068-1.289,0.31-2.623,0.959-3.856
				c2.168-4.121,5.48-6.997,9.886-8.441c3.95-1.296,8.903,1.244,10.473,5.145c0.913,2.268,0.219,4.777-1.783,6.373
				c-1.264,1.007-2.737,1.675-4.295,1.902c-2.263,0.332-4.097,1.318-5.713,2.87c-1.426,1.368-3.082,2.056-5.126,1.696
				C332.895,557.015,331.694,555.546,331.749,551.898z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(315)} fill={props.fillColors[315]} d="M324.865,586.113c-0.912-0.259-1.227-0.996-1.607-1.69
				c-0.33-0.604-0.574-1.438-1.098-1.72c-2.894-1.551-2.691-4.552-3.502-7.079c-0.277-0.865,0.188-1.52,0.628-2.182
				c1.806-2.721,3.983-5.155,6.142-7.579c1.842-2.067,3.422-1.578,4.311,1.035c0.529,1.558,0.449,3.141-0.007,4.663
				c-0.434,1.449-0.647,2.908-0.488,4.38c0.313,2.897-0.978,5.381-1.939,7.938C326.895,584.967,326.214,586.002,324.865,586.113z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(316)} fill={props.fillColors[316]} d="M484.238,578.555c-9.462-2.173-18.167-5.22-23.919-13.924
				c-2.702-4.09-4.92-8.617-8.792-11.897c-1.142-0.967-1.841-2.372-2.03-3.887c-0.471-3.77-2.42-6.872-4.359-9.984
				c-2.886-4.632-3.928-9.676-3.438-15.075c0.108-1.203,0.351-2.134,1.591-2.909c1.809-1.131,1.808-2.898-0.11-4
				c-4.858-2.794-7.887-6.885-10.258-11.896c-1.511-3.193-3.246-6.33-4.829-9.518c-1.586-3.191-1.203-5.948,1.419-8.317
				c1.428-1.291,2.537-2.666,3.378-4.412c1.206-2.504,3.068-2.855,5.314-1.273c2.268,1.598,4.517,3.221,6.165,5.244
				c0.341,0.558,0.144,0.908,0.058,1.294c-0.444,1.98-0.021,3.048,1.856,3.218c4.447,0.402,6.475,3.363,8.075,6.943
				c0.848,1.898,1.652,3.82,2.578,5.681c1.053,2.112,1.299,4.336,1.231,6.645c-0.036,1.238-0.303,2.3-1.33,3.15
				c-3.653,3.023-2.339,6.709,1.761,8.562c1.658,0.75,3.215,1.544,4.423,2.898c1.781,1.999,2.878,4.283,2.415,7.034
				c-0.271,1.614,0.289,2.748,1.697,3.413c2.524,1.19,3.856,3.308,4.763,5.802c0.948,2.608,2.021,5.16,3.3,7.627
				c1.342,2.591,2.055,5.338,2.08,8.277c0.055,6.498,2.834,12.06,6.152,17.408C484.144,575.813,485.232,576.803,484.238,578.555z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(317)} fill={props.fillColors[317]} d="M465.143,576.397c1.332-0.069,2.175,0.498,3.014,0.986
				c4.693,2.737,9.844,4.079,15.127,5.028c0.195,0.035,0.391,0.069,0.585,0.104c9.498,1.717,11.381,3.127,15.77,11.648
				c0.614,1.191,0.582,2.236-0.081,3.358c-0.678,1.147-1.733,1.868-2.731,2.684c-1.191,0.973-1.524,2.262-1.009,3.736
				c0.52,1.485,1.658,1.842,3.079,1.678c0.098-0.011,0.197-0.021,0.296-0.024c5.069-0.209,6.076,0.402,8.042,5.124
				c0.912,2.189,1.847,4.379,2.383,6.705c0.388,1.679,1.312,3.019,2.37,4.378c2.06,2.646,2.769,5.826,2.895,9.133
				c0.04,1.041-0.602,1.562-1.555,1.889c-3.391,1.161-6.54,2.827-9.434,4.924c-2.375,1.722-4.678,1.777-7.189,0.341
				c-4.669-2.672-7.858-6.265-8.008-11.962c-0.063-2.441-0.938-4.641-2.398-6.599c-1.363-1.828-1.856-3.901-1.782-6.149
				c0.072-2.194,0.698-3.963,2.77-5.21c2.326-1.399,2.283-3.177,0.166-4.952c-0.772-0.648-1.629-1.162-2.648-1.24
				c-2.868-0.22-5.239-1.726-7.709-2.955c-1.783-0.888-2.806-2.53-3.508-4.28c-1.688-4.213-4.147-8.127-4.909-12.705
				c-0.222-1.335-1.139-2.271-2.177-3.086C465.738,578.354,464.905,577.772,465.143,576.397z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(318)} fill={props.fillColors[318]} d="M423.013,484.301c-0.274-1.271-0.052-2.394,0.318-3.367
				c1.636-4.296,0.515-8.35-0.934-12.368c-0.367-1.018-0.979-1.905-0.771-3.081c0.132-0.741-0.229-1.406-0.979-1.643
				c-1.458-0.46-1.771-1.538-2.068-2.907c-0.631-2.922-1.38-5.759,0.014-8.69c0.312-0.653,0.1-1.559,0.115-2.349
				c0.039-1.959,0.182-2.386,1.481-4.348c2.532,1.241,4.784,4.221,4.979,7.098c0.203,3.015,1.327,5.147,4.075,6.701
				c2.505,1.416,2.9,4.31,3.519,6.897c0.087,0.363-0.208,0.836-0.084,1.168c1.342,3.617-0.758,5.937-2.945,8.333
				C427.356,478.35,425.784,481.596,423.013,484.301z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(319)} fill={props.fillColors[319]} d="M526.017,617.123c-1.499,1.089-2.744,1.993-4.424,3.212
				c0.372-6.271-1.384-11.583-4.39-16.596c2.223,0.979,3.807,2.626,4.898,4.707C523.566,611.238,524.233,614.37,526.017,617.123z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(320)} fill={props.fillColors[320]} d="M265.945,592.283c-1.586,2.451-3.604,4.354-5.785,5.997
				c-4.721,3.559-8.644,7.817-11.961,12.681c-0.551,0.809-1.15,1.788-1.97,2.174c-2.752,1.294-4.854,3.332-7.02,5.368
				c-1.403,1.317-3.299,1.819-5.209,1.896c-3.49,0.141-5.49-2.71-4.22-5.948c0.25-0.637,0.553-1.314,1.016-1.797
				c4.308-4.494,7.173-9.948,10.145-15.312c1.694-3.059,4.071-5.22,6.985-7.079c4.487-2.863,9.261-5.368,13.081-9.168
				c4.128-4.105,8.264-8.199,9.887-14.126c0.868-3.167,2.754-5.956,4.573-8.621c1.956-2.865,3.615-5.902,5.567-8.762
				c1.451-2.125,2.509-4.465,3.11-7.017c0.691-2.934,1.926-5.624,4.292-7.641c0.441-0.376,0.878-0.82,1.605-0.753
				c0.943,0.681,1.064,1.771,0.531,2.597c-1.14,1.767-0.962,3.7-1.316,5.604c-0.681,3.666-1.651,7.311-3.092,10.821
				c-2.098,5.11-4.125,10.302-6.637,15.165c-2.091,4.049-3.975,8.611-8.505,11.073c-2.592,1.409-4.869,3.402-7.025,5.457
				c-0.713,0.68-1.403,1.422-1.937,2.243c-1.104,1.703-0.623,2.853,1.403,3.448c0.578,0.17,1.148,0.304,1.607,0.702
				C265.29,591.476,265.458,591.723,265.945,592.283z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(321)} fill={props.fillColors[321]} d="M392.03,354.396c0.653-0.819,1.618-0.863,2.404-1.146
				c1.974-0.712,3.153-0.268,4.332,1.479c1.173,1.738,1.816,3.736,2.61,5.655c1.626,3.925,2.808,8.033,5.138,11.666
				c0.865,1.349,1.095,2.86-0.007,4.445c-1.159,1.669-0.496,3.36,1.438,3.313c2.754-0.066,3.903,1.605,4.929,3.533
				c3.213,6.039,6.727,11.889,10.635,17.504c0.717,1.029,1.375,2.137,1.528,3.546c-2.958-4.277-7.365-5.182-12.063-5.48
				c-4.918-0.313-7.128-2.056-8.583-6.746c-0.677-2.184-1.03-4.43-1.074-6.724c-0.021-1.109,0.141-2.186,0.56-3.198
				c1.092-2.639,1.121-3.609-1.039-4.913c-2.121-1.28-2.57-2.97-2.674-5.17c-0.14-2.965-0.464-5.925-0.821-8.872
				c-0.426-3.509-2.179-6.1-5.529-7.495C393.119,355.503,392.344,355.251,392.03,354.396z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(322)} fill={props.fillColors[322]} d="M349.014,339.5c0.691,0.809,0.153,1.464,0.053,2.104
				c-0.224,1.424-0.313,2.75,1.004,3.776c0.972,0.757,0.82,1.745,0.282,2.674c-1.318,2.277-1.219,4.612-0.88,7.167
				c0.56,4.217,0.679,8.483-0.659,12.667c-1.129,3.53-0.539,6.816,1.764,9.817c1.385,1.804,2.576,3.758,3.832,5.659
				c0.326,0.494,0.71,1.013,0.827,1.57c1.042,4.925,3.148,9.54,4.153,14.478c0.253,1.243,0.062,2.321-0.322,3.472
				c-0.807,2.415-0.527,3.319,1.648,4.324c2.796,1.29,4.601,3.362,5.565,6.226c0.447,1.325,1.008,2.581,1.865,3.696
				c0.758,0.986,0.807,2.005,0.03,2.998c-0.873,1.119-1.821,0.678-2.909,0.238c-4.867-1.969-9.989-3.218-14.848-5.23
				c-2.319-0.96-3.053-2.528-2.072-4.876c1.556-3.725,1.23-5.222-2.104-7.438c-1.673-1.111-2.447-2.456-2.532-4.392
				c-0.052-1.182-0.344-2.351-0.457-3.532c-0.196-2.066-0.596-4.189,0.457-6.129c0.896-1.65,0.456-3.048-0.487-4.433
				c-0.334-0.49-0.723-0.943-1.09-1.411c-1.489-1.899-1.706-3.949-0.728-6.14c0.202-0.452,0.36-0.94,0.642-1.339
				c3.752-5.307,3.378-11.197,2.479-17.173c-0.161-1.077-0.284-2.162-0.362-3.248c-0.149-2.088,0.396-3.956,2.043-5.339
				c1.254-1.052,1.689-2.329,1.623-3.937c-0.062-1.48,0.075-2.972,0.187-4.454C348.066,340.655,348.009,339.895,349.014,339.5z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(323)} fill={props.fillColors[323]} d="M218.221,529.394c0.382,2.199-0.288,4.034-1.677,5.262
				c-2.976,2.63-4.646,5.79-5.062,9.7c-0.122,1.145-0.7,2.012-1.928,2.14c-0.62-0.479-0.8-1.085-0.727-1.66
				c0.422-3.319,0.667-6.686,1.425-9.931C211.099,531.28,214.252,529.229,218.221,529.394z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(324)} fill={props.fillColors[324]} d="M290.538,416.037c1.648,1.62,1.661,4.188,3.152,5.917
				c1.528,1.771,4.123,1.929,5.737,3.664c-0.012,1.143-1.826,1.349-1.436,2.684c0.375,1.283,1.749,1.133,2.606,1.7
				c0.212,1.315,0.583,2.479,1.111,3.678c1.048,2.375,0.281,4.857-0.664,7.148c-1.534,3.72-4.904,4.296-8.336,4.401
				c-2.397,0.074-4.671-0.799-6.894-1.668c-2.57-1.006-3.023-3.854-0.845-5.561c0.772-0.605,1.733-0.964,2.544-1.527
				c2.763-1.921,2.658-4.073-0.247-5.684c-0.433-0.24-0.888-0.439-1.329-0.664c-1.536-0.779-2.169-2.046-1.736-3.69
				c0.435-1.647,0.475-3.312,0.625-4.987C285.106,418.363,287.234,416.435,290.538,416.037z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(325)} fill={props.fillColors[325]} d="M366.508,558.074c1.514-0.364,2.96-0.444,4.245,0.619
				c2.757,2.28,6.015,2.523,9.302,1.95c7.717-1.347,14.479,0.617,20.594,5.361c4.174,3.237,7.968,6.766,10.867,11.269
				c1.927,2.994,2.568,6.119,2.438,9.459c-0.899,0.647-1.42-0.007-1.987-0.302c-5.023-2.615-9.551-1.791-12.816,2.805
				c-1.83,2.576-4.338,4-6.938,5.399c-1.991,1.073-4.133,1.023-5.865-0.474c-3.888-3.36-8.153-6.312-11.451-10.324
				c-1.2-1.46-2.185-3.062-2.963-4.788c-0.956-2.12-2.372-3.881-4.18-5.326c-3.164-2.531-6.548-2.232-9.165,0.883
				c-1.146,1.362-2.125,2.861-3.483,4.043c-2.688,2.337-6.251,2.158-8.279-0.703c-2.145-3.026-3.505-6.361-3.349-10.249
				c0.138-3.426,2.444-5.258,4.945-6.464c2.436-1.175,5.182-0.877,7.484,1.155c3.755,3.315,7.962,2.225,9.815-2.453
				C365.937,559.392,365.879,558.748,366.508,558.074z"/>
			<path fill-rule="evenodd" d="M347.574,529.471c-2.112-0.239-4.328-1.767-6.325-3.504c-1.294-1.125-1.331-3.17-0.312-4.601
				c1.176-1.65,1.411-3.359,1.149-5.324c-0.601-4.501,3.234-7.721,7.646-6.527c1.816,0.492,2.333,1.104,1.924,2.967
				c-0.381,1.729-0.171,3.334,0.335,4.954c0.644,2.062,1.155,2.489,3.338,2.597c0.916,0.045,1.825-0.027,2.219,1.027
				c0.374,1.007-0.197,1.67-0.882,2.31c-1.659,1.551-3.269,3.156-4.944,4.688C350.755,528.938,349.627,529.55,347.574,529.471z"/>
			<path fill-rule="evenodd" d="M339.215,552.641c-3.107,0.034-4.366-1.316-4.163-4.051c0.263-3.535,4.505-7.492,8.065-7.524
				c1.98-0.018,4.409,1.591,5.179,3.43c0.761,1.82,0.121,4.011-1.721,5.569C344.35,551.948,341.629,552.43,339.215,552.641z"/>
			<path fill-rule="evenodd" d="M457.013,549.641c-3.72-0.555-3.722-0.554-4.452-3.972c-0.613-2.869-2.068-5.367-3.682-7.732
				c-3.039-4.456-3.897-9.411-3.813-14.553c0.53-1.09,1.838-0.455,2.472-1.514c1.479-2.471,4.971-1.47,6.185,1.149
				c0.166,0.357,0.31,0.739,0.377,1.124c0.292,1.667,1.127,2.365,2.912,2.047c2.073-0.369,3.682,0.557,4.722,2.353
				c1.104,1.904,1.564,3.866,0.098,5.837c-0.059,0.079-0.111,0.164-0.162,0.249c-1.727,2.927-1.444,3.516,1.847,3.863
				c3.677,0.388,5.834,2.583,5.753,6.201c-0.044,1.948,0.442,3.446,1.966,4.687c1.967,1.602,2.704,3.826,2.928,6.28
				c0.217,2.365,0.41,4.737,0.757,7.085c0.246,1.66,0.85,3.244,1.394,4.841c0.546,1.604,1.816,2.79,2.403,4.202
				c-0.655,1.259-1.475,1.198-2.366,0.747c-5.143-2.605-10.068-5.318-13.29-10.544c-1.685-2.733-3.249-5.668-5.584-7.996
				C456.248,552.772,456.698,551.261,457.013,549.641z"/>
			<path fill-rule="evenodd" d="M436.557,483.123c1.721,1.271,3.078,2.208,4.353,3.247c0.627,0.511,0.791,1.31,0.894,2.131
				c0.436,3.485,1.693,5.182,4.997,5.582c2.431,0.294,3.78,1.502,4.716,3.464c1.275,2.672,2.536,5.357,3.636,8.104
				c0.809,2.019,0.954,4.017-1.1,5.608c-0.579,0.449-0.852,1.311-1.228,2.003c-1.153,2.123-3.51,2.887-5.097,1.237
				c-2.452-2.55-5.5-4.163-8.341-6.112c-0.163-0.112-0.318-0.235-0.474-0.358c-0.713-0.568-1.464-1.202-1.138-2.209
				c0.35-1.08,1.326-1.013,2.244-0.878c0.382,0.057,0.762,0.235,1.327-0.109c-0.626-1.521-1.837-2.418-3.322-2.923
				c-1.73-0.589-2.775-1.781-3.543-3.363c-0.904-1.865-1.655-3.801-2.765-5.573c-0.738-1.179-0.817-2.581,0.294-3.551
				C433.944,487.736,435.733,485.977,436.557,483.123z"/>
			<path fill-rule="evenodd" d="M369.698,567.635c1.867-3.063,3.868-4.827,7.235-4.424c1.152,0.139,2.373-0.06,3.534-0.264
				c5.542-0.975,10.608,0.186,15.227,3.336c3.192,2.178,6.2,4.588,8.81,7.456c1.541,1.694,1.498,2.492-0.4,3.748
				c-1.395,0.924-2.923,1.652-4.414,2.424c-1.789,0.924-3.384,2.086-4.459,3.806c-1.223,1.955-3.232,2.842-5.075,3.97
				c-1.274,0.781-2.399,0.155-3.221-0.657c-2.745-2.717-6.08-4.764-8.645-7.669c-0.848-0.96-1.738-1.937-2.336-3.052
				C374.273,573.175,372.73,569.967,369.698,567.635z"/>
			<path fill-rule="evenodd" d="M355.114,567.345c0.014,1.445-2.225,4.445-3.534,4.929c-0.785,0.29-1.37-0.033-1.812-0.589
				c-1.303-1.637-2.198-3.45-2.427-5.58c-0.206-1.924,1.286-2.549,2.496-3.251c1.221-0.708,2.492-0.383,3.464,0.687
				C354.313,564.655,354.966,565.956,355.114,567.345z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(326)} fill={props.fillColors[326]} d="M356.435,521.487c-1.978,2.221-3.684,4.494-5.997,6.175
				c-0.657,0.478-1.398,0.897-2.188,0.794c-2.44-0.322-4.404-1.737-6.171-3.273c-0.845-0.735-1.114-2.11-0.204-3.255
				c1.392-1.749,1.581-3.727,1.342-5.885c-0.5-4.503,2.881-6.786,7.422-4.868c-0.35,2.252-0.481,4.566,0.301,6.86
				C351.827,520.634,353.719,521.432,356.435,521.487z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(327)} fill={props.fillColors[327]} d="M339.163,551.508c-2.542,0.105-3.66-1.249-2.85-3.696
				c0.969-2.928,3.059-4.79,6.059-5.512c1.826-0.439,4.096,0.825,4.86,2.551c0.65,1.467,0.142,3.136-1.449,4.435
				C343.867,550.85,341.565,551.347,339.163,551.508z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(328)} fill={props.fillColors[328]} d="M454.052,548.006c-0.257-5.024-3.285-8.923-5.631-13.135
				c-1.792-3.217-2.268-6.716-2.177-10.505c0.496-0.611,1.542-0.629,2.129-1.558c1.016-1.608,2.7-1.519,3.864-0.05
				c0.301,0.379,0.501,0.852,0.681,1.307c1.181,2.991,1.175,2.993,4.582,3.06c2.82,0.055,4.936,3.452,3.731,6.058
				c-0.371,0.804-0.995,1.491-1.361,2.296c-0.883,1.937-0.012,3.601,2.058,3.992c0.578,0.11,1.186,0.047,1.776,0.104
				c2.545,0.245,4.538,2.092,4.423,4.658c-0.124,2.751,0.848,4.717,2.865,6.472c1.662,1.446,2.112,3.582,2.164,5.686
				c0.123,4.994,0.986,9.798,3.438,14.366c-3.611-0.617-8.683-3.923-11.026-7.181c-2.479-3.444-4.868-6.952-7.319-10.417
				c-0.438-0.62-0.443-1.232-0.38-1.956c0.229-2.623,0.188-2.632-2.317-2.933C455.172,548.226,454.796,548.139,454.052,548.006z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(329)} fill={props.fillColors[329]} d="M437.074,485.141c2.352,1.085,3.523,2.387,3.785,4.984
				c0.311,3.072,2.913,4.698,5.829,5.096c1.94,0.265,3.056,1.121,3.814,2.753c1.167,2.507,2.421,4.979,3.474,7.532
				c0.733,1.777,1.067,3.609-0.819,5.085c-0.697,0.545-0.932,1.491-1.348,2.276c-0.778,1.466-2.346,1.938-3.411,0.758
				c-2.446-2.71-5.706-4.215-8.571-6.296c-0.3-0.218-0.665-0.439-0.65-0.965c0.439-0.585,1.016-0.176,1.528-0.116
				c0.74,0.086,1.413,0.034,1.738-0.746c0.291-0.701,0.124-1.362-0.382-1.921c-1.069-1.181-2.194-2.296-3.774-2.778
				c-1.104-0.338-1.871-1.034-2.427-2.039c-1.148-2.076-1.934-4.329-3.202-6.354c-0.668-1.066-0.273-2.137,0.741-2.856
				C434.964,488.442,436.123,486.999,437.074,485.141z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(330)} fill={props.fillColors[330]} d="M404.494,575.471c-0.981,1.541-2.544,2.034-3.929,2.723
				c-2.611,1.295-4.943,2.868-6.608,5.343c-1.149,1.708-3.065,2.418-4.71,3.363c-0.283-0.06-0.527-0.038-0.659-0.149
				c-5.038-4.233-10.803-7.766-13.454-14.278c-0.823-2.023-2.516-3.51-3.926-5.16c1.365-2.067,2.957-3.237,5.471-2.967
				c1.254,0.135,2.566-0.109,3.834-0.307c5.774-0.899,11.001,0.35,15.656,3.9C399.144,570.207,402.146,572.45,404.494,575.471z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(331)} fill={props.fillColors[331]} d="M350.985,571.165c-1.416-1.05-1.978-2.64-2.416-4.234
				c-0.394-1.432,0.273-2.472,1.663-3.029c1.401-0.563,2.233,0.172,2.931,1.262C354.68,567.53,354.207,568.888,350.985,571.165z"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M177.347,83.841c-2.067-0.437-4.107-1.041-6.26-0.33c-0.626,0.207-1.378,0.032-2.073,0.032c-51.583,0-103.167,0-154.75,0
				c-1.002,0-2.003,0-2.854,0c-0.711,1.136-0.403,2.086-0.403,2.976c-0.019,32.438-0.28,64.879,0.056,97.313
				c0.356,34.424,0.017,68.845,0.23,103.267c0.672,108.126,0.101,216.254,0.303,324.38c0.002,1.113-0.312,2.31,0.585,3.273"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M509.464,83.543c2.977-0.534,5.952-0.032,8.928-0.03c50.195,0.042,100.39,0.03,150.584,0.03c1.002,0,2.004,0,2.854,0
				c0.711,1.136,0.403,2.086,0.403,2.976c0.017,174.987,0.017,349.974-0.005,524.96c0,0.999,0.51,2.161-0.574,2.977"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M547.854,598.684c0.965-0.897,2.162-0.584,3.274-0.585c22.915-0.014,45.829-0.011,68.744-0.011c0.596,0,1.193,0.034,1.785-0.006
				c1.84-0.126,2.396-0.612,2.367-2.374c-0.159-9.821,0.348-19.646-0.217-29.467c-0.065-1.135,0.228-2.371,0.228-3.567
				c0.004-51.98,0.004-103.96,0.004-155.94c0-4.563,0.27-9.146-0.077-13.684c-0.336-4.392,0.221-8.732,0.104-13.101
				c-0.12-4.461-0.026-8.928-0.026-13.392c0-4.364,0-8.729,0-13.094c0-4.365,0-8.729,0-13.095c0-4.365,0-8.729,0-13.094
				c0-4.464,0-8.928,0-13.392c0-4.365,0-8.729,0-13.094c0-65.372,0-130.744,0-196.123c-0.439-0.661-1.138-0.682-1.783-0.574
				c-3.166,0.529-6.352,0.268-9.525,0.269c-60.313,0.019-120.626,0.012-180.939,0.03c-0.988,0-2.232-0.47-2.928,0.324
				c-0.983,1.123-1.802,2.469-2.368,3.851c-0.356,0.869,0.127,1.832,0.806,2.675c1.918,2.38,1.914,2.447,0.301,5.023
				c-0.607,0.971-0.781,2.023-0.528,2.979c0.716,2.704-0.222,4.753-2.124,6.562c-1.795,1.707-2.289,4.115-3.245,6.26
				c-0.517,1.161-0.507,2.439-2.085,3.038c-1.201,0.455-1.688,1.838-1.501,3.195c0.199,1.45-0.424,2.451-1.504,3.276"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M242.818,103.482c-1.272,0.843-2.676,0.916-4.166,0.915c-58.428-0.026-116.856-0.023-175.285-0.014
				c-1.094,0-2.219-0.213-3.275,0.288c-0.5,1.056-0.287,2.181-0.288,3.275c-0.01,36.307-0.008,72.614-0.008,108.92
				c0,43.152-0.107,86.304,0.081,129.455c0.042,9.818,0.098,19.642,0.203,29.462c0.01,0.956,0.115,1.852,0.608,2.679"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M376.141,337.989c0.496-0.464,0.949-0.404,1.495-0.01c2.765,1.993,4.083,4.926,4.661,8.062c0.343,1.861,1.133,3.468,1.944,5.007
				c2.108,3.997,3.726,8.077,4.822,12.503c0.973,3.922,2.564,7.806,5.234,11.039c3.232,3.915,4.626,8.658,5.9,13.408
				c0.683,2.548,1.524,5.051,1.936,7.709c0.427,2.762,1.756,5.337,3.756,7.466c1.347,1.434,2.786,2.841,3.829,4.485
				c1.244,1.961,2.407,3.918,2.567,6.507c0.138,2.223,1.065,4.635,2.328,6.498c2.322,3.426,3.809,7.164,4.596,11.085
				c1.016,5.056,3.636,9.033,6.908,12.797c2.951,3.394,5.907,6.817,7.748,10.998c1.461,3.318,2.412,6.734,2.763,10.405
				c0.417,4.374,2.467,8.022,5.807,11.093c1.854,1.704,4.038,2.825,6.088,3.972c5.384,3.013,7.615,8.155,10.01,13.234
				c1.191,2.524,2.056,5.208,3.312,7.719c2.858,5.712,4.52,11.715,4.48,18.152c-0.019,2.895,0.961,5.584,2.413,8.007
				c1.268,2.116,2.751,4.167,4.452,5.946c4.492,4.698,7.598,10.035,9.281,16.377c1.113,4.193,2.991,8.198,4.697,12.215
				c1.873,4.412,3.821,8.801,5.968,13.084c1.477,2.946,2.984,5.896,5.286,8.381c2.654,2.863,3.992,6.543,5.323,10.115
				c1.1,2.954,2.514,5.593,4.83,7.707c3.973,3.627,5.827,8.323,7.195,13.383c0.775,2.866,1.628,5.692,2.044,8.644
				c0.209,1.488,0.389,2.977,0.264,4.465c-0.484,5.74,1.554,10.724,4.469,15.482c2.14,3.492,4.437,7.01,5.254,11.027
				c0.562,2.763,1.521,5.62,0.49,8.636c-0.495,1.448,0,3.168-0.626,4.753c-0.419,1.061,0.623,1.527,1.415,2.098
				c1.283,0.924,2.772,1.225,4.19,1.751c1.863,0.691,3.9,0.979,5.098,2.953c0.666,1.1,1.927,1.379,3.235,1.226
				c0.588-0.068,1.194-0.062,1.783-0.003c2.459,0.247,3.654,1.144,4.127,3.571c0.4,2.058,1.057,4.088,0.953,6.242
				c-0.065,1.33-0.241,2.665-1.232,3.545c-3.213,2.854-2.167,6.01-0.88,9.254c0.674,1.696,2.059,3.098,2.093,5.056"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M98.781,703.734c1.481-4.082,3.967-7.523,6.865-10.695c0.041-0.045,0.349-0.253,0.579-0.28c4.234-0.509,6.656-3.771,9.49-6.317
				c2.853-2.563,5.946-4.632,9.554-5.914c2.062-0.733,3.177-2.257,4.221-4.14c2.417-4.355,4.412-8.969,7.649-12.855
				c0.888-1.066,1.738-2.067,3.012-2.662c0.788,0.718,0.727,1.459,0.324,2.404c-0.612,1.438-1.228,2.933-1.458,4.46
				c-0.213,1.411-0.577,3.15,1.619,3.927c3.058-1.93,6.258-3.725,7.925-7.471c0.827-1.856,2.448-3.404,4.677-3.916
				c0.202-0.046,0.455,0.246,0.63-0.275c1.048-3.127,3.907-5.567,3.531-9.235c-0.17-1.657,0.275-3.069,1.167-4.485
				c1.295-2.057,2.276-4.213,1.549-6.832c-0.917-3.303-3.452-4.959-6.308-5.852c-2.742-0.856-5.628-1.047-8.628-0.185
				c-3.169,0.91-5.383,2.537-6.832,5.441c-2.089,4.189-5.183,7.562-8.569,10.771c-2.516,2.384-5.557,3.42-8.695,4.026
				c-3.019,0.583-5.88,1.692-8.905,2.208c-1.201,0.205-2.387,0.322-3.576,0.246c-5.612-0.359-7.077,3.998-5.322,8.035
				c0.636,1.462,1.04,2.875,1.203,4.475c0.421,4.134-2.231,6.085-5.718,7.096c-2.302,0.668-4.734,0.968-7.13,1.214
				c-3.815,0.394-7.216,1.527-10.135,4.173c-6.672,6.047-13.329,12.092-18.739,19.358c-0.806,1.082-1.381,2.188-1.181,3.578"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M414.531,489.763c0.984,5.36,4.029,9.897,6.046,14.842c1.423,3.49,3.118,6.893,4.328,10.469c0.917,2.708,2.257,5.13,4.246,7.09
				c1.821,1.795,1.921,3.736,0.798,5.658c-1.006,1.72-0.71,3.499-0.449,5.078c0.856,5.197,2.042,10.344,3.205,15.485
				c0.674,2.977,2.066,5.699,3.571,8.325c2.805,4.895,5.083,10.058,7.915,14.95c2.066,3.568,4.227,7.052,7.018,9.991
				c2.68,2.822,4.205,6.146,5.502,9.621c0.433,1.161,0.995,2.189,1.57,3.242c1.234,2.26,2.837,4.297,3.61,6.834
				c0.612,2.011,1.834,3.862,3.562,5.024c3.6,2.423,4.388,6.084,4.355,9.868c-0.045,5.149,1.243,9.822,3.639,14.289
				c0.851,1.587,1.451,3.244,1.851,5.042c0.795,3.582,2.849,6.562,5.313,9.227c1.511,1.634,3.335,2.727,5.635,3.033
				c0.93,0.123,1.894,0.609,2.663,1.175c1.436,1.056,1.493,1.809,0.302,3.529c-1.099,1.587-1.287,3.475-0.795,5.046
				c1.688,5.394,3.242,10.886,6.75,15.512c0.695,0.916,1.23,1.958,1.792,2.969c0.484,0.872,0.572,1.868-0.014,2.658
				c-2.062,2.787-1.707,5.879-1.144,8.939c0.913,4.953,2.129,9.837,3.818,14.596c0.188,0.528,0.025,1.182,0.025,1.776"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M388.938,383.818c0.908,0.689,0.579,1.791,0.885,2.681c1.502,4.368,3.776,8.324,6.229,12.217c2.702,4.287,5.368,8.617,7.711,13.105
				c2.083,3.988,2.795,8.517,4.254,12.757c1.556,4.521,1.226,9.092,0.865,13.701c-0.166,2.142,0.354,4.186,0.859,6.256
				c1.084,4.441,2.068,8.909,2.979,13.39c0.455,2.236,1.354,4.26,2.405,6.244c1.582,2.988,3.271,5.887,4.25,9.201
				c0.805,2.725,1.036,5.521,0.384,8.026c-1.117,4.287-0.399,8.259,1.407,11.894c2.964,5.957,6.105,11.842,8.751,17.938
				c1.566,3.609,3.088,7.083,6.024,9.8c0.939,0.868,1.05,2.491,0.526,3.193c-2.001,2.686-0.952,5.479-0.429,8.082
				c1.008,5.017,2.521,9.931,3.777,14.898c0.747,2.951,2.442,5.45,3.931,8.002c2.956,5.07,5.616,10.307,8.814,15.247
				c1.637,2.527,3.383,4.926,5.438,7.085c2.673,2.811,5.007,5.743,6.287,9.522c1.073,3.167,3.095,5.921,5.038,8.623
				c2.273,3.16,3.802,6.456,3.549,10.443c-0.142,2.24,1.15,3.986,2.393,5.634c2.187,2.898,4.39,5.67,4.851,9.529
				c0.152,1.275,0.896,2.722,1.91,3.931c1.532,1.825,1.944,4.289,2.693,6.514c1.218,3.618,3.305,6.593,5.997,9.208
				c1.655,1.608,3.877,2.037,5.969,2.611c0.794,0.218,1.436,0.559,2.053,0.979c1.109,0.758,1.347,1.882,0.582,2.941
				c-1.423,1.975-1.265,3.762-0.41,6.021c0.588,1.552,1.087,3.443,0.743,5.307c-0.487,2.647,0.683,4.711,2.691,6.216
				c2.093,1.567,3.631,3.548,5.037,5.685c0.788,1.198,0.778,2.369,0.004,3.576c-0.818,1.273-1.795,2.497-2.333,3.886
				c-0.613,1.58-0.939,3.251-0.381,5.048c1.965,6.324,3.244,12.852,5.657,19.047c0.201,0.518,0.026,1.181,0.026,1.775"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M403.52,75.806c0.854,0.446,1.778,0.279,2.679,0.293c1.293,0.021,2.556,0.146,2.751-1.774c0.063-0.625,0.606-1.569,1.713-1.508
				c0.992,0.055,1.428,0.51,1.505,1.499c0.081,1.026,1.081,1.137,1.777,1.472c1.118,0.54,0.346,1.646,0.825,2.435
				c0.49,0.805,1.494,0.635,1.968,1.694c0.62,1.386,1.472,2.779,3.09,3.729c2.229,1.308,3.414,7.48,2.081,9.68
				c-0.612,1.011-0.506,2.03-0.904,2.939c0.371,0.372,0.693,0.948,0.963,0.924c2.526-0.224,4.917,0.694,7.443,0.688
				c28.669-0.077,57.34-0.305,86.005,0.015c37.697,0.421,75.393,0.051,113.088,0.22c1.983,0.009,3.968-0.185,5.952-0.284"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M355.309,203.177c-1.304,4.273-1.747,8.637-1.513,13.096c0.066,1.281-0.04,2.605-0.296,3.863
				c-1.074,5.287-0.048,10.414,1.279,15.462c0.58,2.207,2.078,4.145,3.826,5.645c5.51,4.727,7.861,10.961,8.917,17.881
				c0.108,0.708,0.391,1.389,0.582,2.085c1.128,4.09,1.743,8.256,1.782,12.499c0.042,4.559,1.671,8.646,3.915,12.474
				c1.373,2.341,2.887,4.629,4.323,6.931c1.54,2.465,2.271,5.413,3.18,8.237c1.036,3.221,2.921,6.161,4.344,9.267
				c1.15,2.512,2.402,4.98,3.021,7.724c0.532,2.358,1.656,4.506,2.946,6.554c1.962,3.115,3.229,6.495,3.879,10.116
				c0.127,0.707,0.353,1.402,0.591,2.083c0.816,2.333,1.367,4.603,0.67,7.167c-0.439,1.62-0.407,3.527,1.992,4.18
				c1.536,0.417,1.94,2.065,2.661,3.255c5.868,9.694,10.687,19.945,15.813,30.034c3.899,7.673,7.743,15.384,11.954,22.886
				c2.77,4.935,6.774,9.021,10.666,13.114c3.073,3.233,5.714,6.73,7.737,10.721c2.883,5.685,6.719,10.802,9.887,16.329
				c2.176,3.797,4.302,7.596,5.824,11.671c1.697,4.541,4.304,8.569,6.615,12.768c0.329,0.599,0.899,1.143,1.763,0.904"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M446.375,654.929c0.993-6.885,0.168-13.151-4.993-18.515c-3.026-3.146-5.492-9.886-3.706-15.137
				c0.454-1.333,0.128-2.956,0.055-4.44c-0.12-2.479,0.075-4.948-0.212-7.448c-0.396-3.455-2.265-6.101-3.924-8.93
				c-2.308-3.934-5.892-6.491-9.578-8.858c-1.807-1.161-2.778-2.86-3.452-4.556c-2.034-5.113-4.587-10.022-7.647-14.463
				c-5.216-7.567-12.443-13.08-21.336-15.741c-3.754-1.123-7.837-1.202-11.893-0.222c-2.245,0.543-4.788,0.438-6.836-1.367
				c-1.774-1.564-4.003-1.732-6.231-1.484c-1.826,0.204-3.337,1.054-3.841,2.963c-0.241,0.913-0.737,1.68-1.26,2.342
				c-1.339,1.698-2.495,1.814-3.775-0.008c-2.362-3.365-10.188-2.857-12.901-0.617c-2.351,1.941-4.824,3.893-5.194,6.919
				c-0.447,3.652-0.711,7.58,1.149,10.958c1.591,2.889,2.915,6.102,6.167,7.8c3.504,1.83,7.153,1.516,10.115-1.229
				c1.547-1.434,3.006-2.945,4.223-4.718c1.14-1.66,2.588-1.688,4.11-0.311c1.046,0.945,2.12,1.892,2.698,3.255
				c2.061,4.854,5.692,8.528,9.591,11.82c2.038,1.721,3.829,3.746,6.151,5.186c3.447,2.135,6.74,2.812,10.466,0.599
				c2.911-1.729,5.798-3.249,7.782-6.247c1.85-2.795,5.299-3.188,8.264-1.536c2.715,1.513,5.762,2.309,8.366,4.119
				c1.794,1.246,4.312-0.32,4.324-2.63c0.002-0.393,0.221-0.793,0.105-1.189"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M289.541,539.759c0.732-1.33,1.772-2.495,1.818-4.165c0.025-0.917-0.174-1.69-0.945-2.041c-0.574-0.261-1.177,0.078-1.794,0.512
				c-2.75,1.934-4.229,4.753-4.93,7.801c-0.538,2.344-1.289,4.546-2.54,6.487c-1.331,2.064-2.371,4.322-3.852,6.255
				c-2.082,2.717-3.493,5.808-5.284,8.682c-0.959,1.539-1.756,3.2-2.134,5.035c-0.443,2.148-1.754,4.025-3.043,5.588
				c-4.282,5.189-8.958,10.01-15.063,13.218c-1.953,1.026-3.776,2.366-5.647,3.572c-2.262,1.459-4.086,3.446-5.413,5.887
				c-3.016,5.551-5.992,11.138-10.38,15.798c-0.765,0.812-1.305,1.852-1.486,2.965c-0.541,3.328,1.659,5.775,5.042,5.646
				c2.984-0.114,5.529-1.308,7.405-3.595c1.097-1.336,2.247-2.387,3.895-2.973c1.455-0.517,2.402-1.683,3.303-2.936
				c3.386-4.708,6.959-9.222,11.846-12.568c1.82-1.247,3.258-3.077,4.788-4.718c1.94-2.079,1.586-3.243-1.182-4.146
				c-0.477-0.156-1.079,0.239-1.556-0.393c-0.544-1.068-0.239-1.926,0.635-2.917c2.848-3.226,6.425-5.517,9.854-8.005
				c0.579-0.419,1.434-0.659,1.744-1.215c2.164-3.88,5.323-7.228,6.511-11.605c0.28-1.033,0.916-1.74,1.281-2.651
				c2.362-5.888,5.522-11.46,6.597-17.851c0.302-1.799,1.708-3.43,0.53-5.368"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M239.544,97.828c-2.777,0-5.555-0.004-8.333,0c-58.924,0.099-117.848,0.19-176.772,0.336c-1.687,0.004-3.373-0.788-5.059-0.039"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M349.953,339.477c-0.87-1.129-1.441-0.889-2.112,0.281c-0.78,1.36-0.558,2.778-0.549,4.183c0.015,2.255-0.064,4.228-2.025,6.033
				c-2.282,2.1-1.742,5.125-1.282,7.958c0.64,3.944,0.518,7.95,0.204,11.892c-0.139,1.739-1.281,3.514-2.311,5.041
				c-2.244,3.329-2.326,6.248,0.577,9.308c1.212,1.277,1.923,3.139,0.656,5.006c-1.27,1.871-1.017,4.244-0.407,5.88
				c0.688,1.845,0.105,3.643,0.842,5.49c0.912,2.285,3.192,2.642,4.361,4.376c1.026,1.524,1.252,2.729,0.261,4.489
				c-1.765,3.13-0.976,4.885,2.364,6.288c3.894,1.636,7.951,2.74,11.917,4.136c0.92,0.324,1.906,0.471,2.692,1.17
				c1.146,1.02,2.261,0.7,3.26-0.288c1.128-1.115,1.212-2.013,0.295-3.565c-0.841-1.423-1.57-2.876-2.105-4.457
				c-0.917-2.709-2.885-4.572-5.329-5.961c-2.378-1.351-2.38-1.347-1.487-3.87c0.525-1.487,0.408-3.022-0.035-4.453
				c-0.7-2.261-1.338-4.531-1.723-6.859c-0.135-0.812-0.582-1.419-0.955-2.06c-0.29-0.497-0.793-1.303-0.771-1.497
				c0.473-4.054-3.084-6.264-4.255-9.533c-4.089-3.539-3.387-8.062-2.329-12.487c0.909-3.801,0.661-7.555,0.196-11.314
				c-0.229-1.855-0.477-3.631,0.354-5.359c1.344-2.793,1.358-2.78-0.582-5.079c-0.057-0.067-0.021-0.38-0.353-0.602
				c0.692-1.183-0.102-2.725,0.928-3.845"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M587.435,64.2c-2.47,0.36-4.91,1.105-7.449,0.327c-0.635-0.194-1.38-0.029-2.074-0.029c-32.239,0-64.481,0.231-96.718-0.087
				c-19.444-0.192-38.89,0.086-58.328-0.284c-6.053-0.115-12.104-0.119-18.155-0.214c-1.019-0.016-2.118-0.285-2.976,0.584"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M352.036,580.827c2.373-0.477,4.019-1.944,5.369-3.86c0.523-0.744,1.281-1.333,1.755-2.103c1.609-2.616,5.8-2.691,8.079-0.914
				c1.691,1.32,2.926,2.888,3.854,4.789c1.996,4.086,4.68,7.658,8.334,10.403c2.092,1.571,3.929,3.423,5.946,5.068
				c2.569,2.096,5.18,2.418,7.994,0.553c2.331-1.545,4.619-3.02,6.434-5.493c2.679-3.651,6.81-5.477,12.062-2.211
				c0.601,0.372,1.229,0.934,2.421,0.469c0.693-3.211,0.16-6.443-1.571-9.36c-4.042-6.809-9.699-12.08-16.669-15.724
				c-4.875-2.549-10.11-3.475-15.764-2.277c-2.82,0.599-6.039,0.705-8.631-1.629c-1.467-1.322-3.417-1.394-5.376-1.183
				c-0.703,0.579-0.84,1.505-1.141,2.344c-0.593,1.652-1.588,2.859-3.264,3.6c-1.961,0.867-3.684,0.973-5.021-0.667
				c-2.242-2.751-5.193-2.478-8.083-2.285c-2.345,0.156-5.648,4.146-5.644,6.494c0.005,2.532,0.062,4.957,1.058,7.486
				c1.459,3.71,3.733,5.979,7.559,6.798"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M332.395,704.032c0-0.298,0.022-0.597-0.003-0.893c-0.181-2.057,0.875-3.055,2.666-2.341c0.808,0.321,1.574,0.803,2.382,1.181
				c1.357,0.635,2.234,0.017,2.973-0.937c0.627-0.809,0.537-1.661-0.274-2.375c-0.049-0.043,0.036-0.225-0.013-0.285
				c-2.042-2.536-2.802-5.427-2.37-8.635c0.195-1.444-0.744-2.1-1.794-2.673c-0.907-0.494-1.925-0.855-2.112-2.337
				c0.816-0.663,1.738-1.139,3.001-1.293c1.671-0.203,2.73-2.041,2.052-3.501c-0.727-1.565-1.69-3.032-2.66-4.469
				c-0.757-1.122-2.014-1.205-3.255-1.207c-1.075-0.002-2.226,0.215-3.052-1.002c0.901-2.067,1.045-4.077-0.448-6.187
				c-0.781-1.104-0.86-2.494-0.18-4.16c1.914-4.694,1.382-6.704-1.032-11.883c-1.401-3.004-2.872-5.822-5.7-7.721
				c-0.719-0.483-0.801-1.219-0.948-2.067c-0.712-4.112-2.931-7.174-6.749-9.095c-1.964,0.886-2.873,2.255-2.728,4.324
				c0.103,1.468-0.43,2.813-0.892,4.165c-0.737,2.156-0.929,4.175-0.475,6.582c0.497,2.632-0.402,5.566-1.935,7.98
				c-1.012,1.593-1.622,3.164-1.48,5.081c0.164,2.22,0.834,3.521,3.016,4.111c1.855,0.502,2.732,1.375,3.034,3.315
				c0.225,1.447,0.945,2.902,1.758,4.148c0.928,1.422,1.747,2.944,3.097,4.062c-0.627,1.722-1.993,2.683-2.873,3.983
				c-1.349,1.99-2.022,4.101-0.807,6.235c0.899,1.581,0.659,3.086,0.18,4.477c-0.844,2.454-0.988,4.777,0.057,7.164
				c0.874,1.996,1.077,4.097,0.896,6.248"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M358.286,276.684c-1.24-0.446-1.271,0.603-1.151,1.183c0.716,3.446-0.405,7.031,0.837,10.43c0.127,0.35,0.042,0.789,0.013,1.184
				c-0.44,6.021,0.995,11.838,2.488,17.532c1.829,6.978,3.468,14.02,5.762,20.887c0.902,2.701,0.7,5.763,0.042,8.591
				c-0.698,2.995-0.933,6.116-2.292,8.959c-0.222,0.462-0.267,0.989-0.607,1.488c-1.712,2.507-1.992,5.336-1.848,8.313
				c0.203,4.174-0.863,8.293-0.6,12.5c0.286,4.571,1.092,9.021,2.432,13.384c0.072,0.237,0.381,0.402,0.579,0.601
				c1.125,0.087,1.486-0.732,1.149-1.47c-1.522-3.331-0.548-6.756-0.527-10.137c0.008-1.236,0.601-2.348,1.498-3.688
				c1.594,2.723,4.005,4.372,5.35,6.945c0.701,1.344,1.396,2.825,1.36,4.18c-0.081,3.21,1.615,5.785,2.541,8.612
				c0.719,2.198,2.016,4.14,2.422,6.55c0.352,2.092-0.282,3.262-1.896,4.172c-1.555,0.877-3.062,1.391-4.8,0.073
				c-0.836-0.634-1.343-1.848-2.633-1.847"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M353.226,335.013c-0.991-0.496-1.938-1.187-2.987-1.438c-1.255-0.299-1.689-1.171-1.729-2.135
				c-0.071-1.755-1.474-2.879-1.573-4.369c-0.839-0.263-1.428,0.186-1.402,0.498c0.423,5.277-2.146,9.781-3.52,14.614
				c-1.271,4.472-2.639,8.938-3.187,13.649c-0.5,4.305-0.5,8.529-0.209,12.811c0.171,2.511-0.265,5.052-0.96,7.411
				c-0.702,2.384-1.125,4.712-1.107,7.17c0.03,4.166-0.073,8.334,0.028,12.498c0.129,5.336,0.761,10.538,3.804,15.207
				c1.358,2.084,1.102,4.733,1.477,7.122c0.869,5.527,1.272,11.131,2.197,16.646c0.654,3.903,1.303,7.854,4.635,10.802
				c1.498,1.325,2.435,3.41,3.286,5.304c0.734,1.63,1.438,3.295,2.48,4.704c1.49,2.018,1.094,4.416,1.651,6.603
				c0.406,1.592,0.941,2.826,2.479,3.222c5.412,1.389,8.025,5.519,10.075,10.163c0.604,1.368,1.237,2.622,2.417,3.556"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M313.944,309.419c-0.702,0.392-0.857,1.147-1.253,1.744c-1.671,2.521-1.999,5.647-4.397,8.305c-1.027,0.322-2.753,0.659-3.88,2.147
				c-0.251,0.333-0.554,0.648-0.891,0.891c-5.315,3.824-6.492,9.432-6.885,15.479c-0.169,2.607-0.43,5.23-1.39,7.76
				c-0.726,1.913-1.71,3.173-3.567,4.244c-2.558,1.475-4.353,3.938-6.039,6.428c-2.582,3.813-4.13,7.915-4.479,12.517
				c-0.077,1.02-0.438,2.007-1.05,3.035c-2.399,4.036-5.22,7.871-6.67,12.434c-0.277,0.871-0.654,1.768-1.454,2.398
				c-2.781,2.194-3.27,5.508-3.818,8.632c-0.931,5.298-1.82,10.589-3.302,15.771c-0.341,1.194-0.794,2.281-1.52,3.264
				c-1.805,2.446-3.391,5.018-4.78,7.729c-1.402,2.735-3.252,5.236-5.665,7.112c-4.152,3.228-5.751,7.724-6.784,12.546
				c-0.396,1.846-0.828,3.707-0.649,5.651c0.123,1.331-0.497,2.302-1.749,2.994c-5.27,2.915-8.406,7.713-11.358,12.754
				c-3.19,5.447-5.623,11.144-7.141,17.273c-0.716,2.894-2.205,5.267-4.979,6.925s-5.331,3.72-7.792,5.867
				c-4.776,4.167-7.297,9.639-9.216,15.488c-0.945,2.882-1.923,5.74-3.552,8.344c-1.251,2.002-3.476,3.217-4.444,5.363
				c-1.297,2.872-4.1,4.597-5.386,7.427c-1.239,2.727-2.766,5.285-4.482,7.726c-1.837,2.613-3.82,5.124-5.625,7.758
				c-1.557,2.273-3.006,4.627-4.184,7.131c-3.368,7.162-7.782,13.755-11.319,20.826c-1.846,3.691-4.066,7.191-3.923,11.613
				c0.066,2.049-0.979,2.812-3.207,2.633c-2.397-0.193-4.255,0.517-5.652,2.428c-1.253,1.715-2.918,2.891-4.765,3.87
				c-2.813,1.492-5.056,3.696-6.806,6.272c-2.479,3.649-5.748,6.397-9.277,8.886c-4.065,2.867-7.702,6.137-9.783,10.74
				c-2.187,4.836-4.199,9.766-5.608,14.888c-0.357,1.295-1.154,2.199-1.917,2.892c-3.511,3.187-5.082,7.616-7.634,11.403
				c-0.312,0.463-0.509,1.047-0.941,1.454c-1.582,1.495-2.981,3.287-4.793,4.413c-7.161,4.449-12.279,10.729-16.996,17.566
				c-3.962,5.742-8.258,11.276-12.98,16.456c-0.741,0.813-1.298,1.488-2.428,1.445"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M202.94,87.115c-0.762,0.785-1.746,0.592-2.678,0.592c-21.03,0.004-42.064,0.254-63.089-0.059
				c-28.571-0.425-57.14-0.07-85.709-0.228c-10.019-0.055-20.038-0.019-30.057,0.008c-0.89,0.002-1.84-0.309-2.682,0.25
				c-0.294,0.229-0.316,0.424-0.308,0.626c0.033,0.792,0.014,1.586,0.014,2.38c0,25.196,0,50.393,0,75.589c0,20.236,0,40.473,0,60.71
				c0,19.84,0,39.679,0,59.519c0,20.038,0,40.077,0,60.115c0,19.84,0,39.68,0,59.52c0,7.142-0.187,14.29,0.041,21.426
				c0.408,12.797,0.213,25.594,0.244,38.391c0.048,19.74,0.008,39.481,0.023,59.222c0.001,1.112-0.312,2.31,0.585,3.273"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M635.645,170.144c1.237,0.022,1.133-1.047,1.501-1.779c3.749-7.451,7.561-14.87,11.287-22.331c4.6-9.208,9.129-18.45,13.71-27.667
				c0.414-0.834,0.827-1.775,1.51-2.347c1.285-1.078,1.457-2.423,1.456-3.908c-0.003-7.638-0.015-15.277,0.023-22.915
				c0.003-0.644-0.33-1.172-0.422-1.786c-8.531,0-17.161,0-25.791,0c-28.967,0-57.936-0.251-86.897,0.081
				c-22.422,0.257-44.84,0.045-67.258,0.205c-1.111,0.008-2.31,0.314-3.273-0.583"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M344.894,288.587c-2.658,3.852-4.905,7.836-3.825,12.787c0.059,0.272,0.195,0.301-0.019,0.622
				c-1.396,2.092-0.866,4.246-0.383,6.542c0.948,4.506-1.105,8.288-3.46,11.966c-0.845-1.026-1.783-0.787-2.405-0.051
				c-1.89,2.234-3.521,4.652-1.79,7.702c1.074,1.893,0.132,4.062,1.066,6.014c0.279,0.583-0.264,1.98-1.1,2.614
				c-2.233,1.692-2.315,4.204-2.308,6.562c0.01,3.333-0.259,6.58-1.89,9.499c-1.069,1.914-0.503,3.735,0.124,5.35
				c1.06,2.729,1.847,5.457,1.648,8.36c-0.067,0.974,0.49,1.585,0.696,2.373c0.604,2.314,0.389,4.715-0.154,6.828
				c-1.248,4.853-1.021,9.722-1.085,14.61c-0.044,3.299,0.88,6.386,1.725,9.541c1.272,4.756,2.603,9.542,2.457,14.565
				c-0.115,3.949-0.79,7.887-1.934,11.562c-1.478,4.747-1.841,9.787-3.969,14.355c-0.418,0.896-0.061,2.154-0.061,3.246
				c0,1.587-0.136,3.188,0.027,4.758c0.412,3.975-0.964,6.958-4.485,8.941c-1.613,0.908-2.84,2.298-4.19,3.541
				c-3.123,2.874-3.25,6.485-2.295,10.12c0.893,3.396,1.219,6.619-0.373,9.835c-0.911,1.842-1.621,3.615,0.603,5.063
				c1.277,3.806,2.297,7.676-0.338,11.278c-1.412,1.931-2.403,4.026-2.559,6.285c-0.193,2.818-1.757,4.619-3.688,6.198
				c-0.953,0.781-1.236,1.562-1.531,2.703c-0.589,2.282-1.844,4.392-1.706,6.865c0.019,0.335-0.104,0.594-0.271,0.907
				c-1.53,2.873-1.947,6.006-2.126,9.211c-0.204,3.67-0.786,7.313-0.607,11.013c0.078,1.609,0.483,3.065,1.229,4.458
				c0.021,0.041,0.209,0.04,0.283-0.004c0.117-0.068,0.203-0.189,0.302-0.289"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M318.11,317.157c1.381,1.174,1.634,2.745,1.825,4.459c0.377,3.396-0.83,6.449-1.896,9.503c-0.358,1.027-0.515,1.976-0.519,3.001
				c-0.012,3.273-0.13,6.553,0.038,9.818c0.121,2.339-0.598,4.546-0.724,6.842c-0.054,0.982-0.535,2.221-1.419,2.959
				c-0.971,0.81-1.421,1.986-1.396,2.996c0.071,2.915-1.103,5.713-0.667,8.632c0.231,1.548-0.004,3.191-0.672,4.424
				c-1.95,3.596-1.883,7.494-2.333,11.346c-0.28,2.393-1.158,4.678-1.212,7.145c-0.041,1.846,0.557,3.559,0.752,5.346
				c0.455,4.167-0.246,8.044-1.577,11.923c-0.831,2.423-0.191,4.788,0.563,7.14c1.304,4.062,2.739,8.08,3.946,12.179
				c0.69,2.345,0.589,4.567,0.227,6.862c-0.47,2.98-1.011,5.949-1.474,8.93c-0.384,2.478-0.904,4.914-2.102,7.136
				c-1.161,2.152-1.539,4.071,0.563,6.004c1.001,0.921,1.143,2.501,1.502,3.83c0.276,1.017,0.498,1.903,1.814,1.778"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M366.023,486.191c0.843,2.685,2.274,5.13,3.23,7.754c1.116,3.064,3.044,5.802,3.356,9.206c0.401,4.359,2.455,8.304,3.526,12.504
				c0.327,1.281,0.716,2.563,0.878,3.87c0.612,4.95-2.293,7.675-6.211,9.852c-1.804,1.001-3.587,1.628-5.668,1.805
				c-3.256,0.276-6.397,1.018-7.147,5.006c0.38,1.225,1.472,1.457,2.356,1.126c1.006-0.377,2.043,0.019,3.013-0.509
				c2-1.088,4.079-0.086,5.904,0.405c2.201,0.593,3.687,2.321,4.346,4.893c0.354,1.385,1.326,2.87,2.157,4.252
				c2.169,3.606,5.448,5.154,9.311,5.55c3.48,0.356,6.952,0.765,10.403,1.29c2.083,0.316,4.096,0.919,5.686,2.612
				c4.428,4.718,9.532,8.718,14.227,13.152c2.711,2.561,5.276,5.284,7.215,8.572c2.39,4.054,5.978,7.104,9.513,10.107
				c3.324,2.824,6.905,5.349,9.787,8.669c0.447,0.517,0.997,0.192,1.493,0.292"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M356.797,675.761c-1.67,0.336-3.332,0.656-5.06,0.609c-3.096-0.082-4.211-0.804-4.392-3.887c-0.14-2.392-1.227-4.189-2.788-5.609
				c-4.489-4.08-6.849-9.389-8.478-14.954c-0.929-3.173-2.151-6.171-3.399-9.188c-0.627-1.516-1.505-2.937-2.044-4.479
				c-1.005-2.877-3.084-4.7-5.698-5.908c-2.336-1.08-4.339-2.244-6.032-4.424c-3.345-4.31-11.08-5.833-16.259-3.616
				c-2.564,1.098-5.188,2.017-7.513,3.76c-2.155,1.614-3.354,3.746-3.694,6.049c-0.484,3.286-2.046,5.526-4.562,7.444
				c-2.852,2.173-5.615,4.466-8.054,7.118c-1.473,1.602-2.972,3.178-4.461,4.764c-2.304,2.455-3.737,5.435-4.406,8.643
				c-0.947,4.541-3.021,8.598-4.817,12.785c-0.58,1.352-1.496,2.357-2.642,3.319c-1.926,1.618-3.942,3.307-5.301,5.373
				c-1.329,2.021-1.702,4.598-1.876,7.08c-0.311,4.413-0.599,8.829-0.896,13.243"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M245.199,64.498c-0.762-0.786-1.746-0.592-2.678-0.592c-43.449-0.003-86.898-0.029-130.347,0.064
				c-2.481,0.005-4.96,0.345-7.44-0.067"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M545.176,69.259c-0.75-1.104-1.901-0.888-2.977-0.89c-5.455-0.006-10.911-0.003-16.367-0.003c-39.382,0-78.764,0.006-118.146-0.02
				c-1.637-0.001-3.025-0.009-3.933,1.773c-0.452,0.891-0.491,1.483-0.304,2.427c0.25,1.254,0.278,2.636-1.099,3.588
				c-0.875,0.604-0.707,1.727-0.596,2.645c0.292,2.418,0.228,2.604-1.807,3.276c-1.576,0.521-2.421,1.648-1.703,2.93
				c0.553,0.988,0.51,2.183,1.378,3.05c0.749,0.748,0.428,1.634-0.17,2.313c-0.841,0.278-1.45-0.211-1.853-0.59
				c-1.094-1.031-2.214-1.095-3.286-0.237c-1.134,0.906,0.349,1.741,0.315,2.543c-0.724,2.087-2.986,1.854-4.211,3.067
				c0.722,2.066,1.068,2.39,2.345,1.449c1.181-0.872,2.354-0.813,3.638-0.835c-0.254,0.48,0.314,0.757,0.274,1.191
				c-0.083,0.912-0.036,1.709,1.189,1.796c1.122,0.079,1.243-0.502,1.563-1.474c0.719-2.177,2.723-2.788,4.98-1.802
				c1.172,0.512,2.245,1.265,3.588,0.309c1.104-0.786,2.173-0.194,2.931,0.606c0.693,0.731,0.189,1.5-0.17,2.389
				c-0.413,1.023-1.288,2.222-0.17,3.593c0.435,0.532,0.188,1.76-0.247,2.568c0.486,1.046,1.421,1.63,2.159,2.496
				c-0.16,1.047-1.088,1.456-1.73,2.112c-1.637-0.616-3.191-1.519-4.687,0.347c0.134,0.66,0.6,1.399,0.418,2.239
				c-0.287,1.333-1.64,2.577-2.649,2.02c-2.383-1.315-3.69,0.107-5.087,1.557c-0.244,0.253-0.677,0.407-0.633,0.699
				c0.874,0.821,1.644,1.545,2.641,2.482c2.126-0.742,4.405-0.134,6.317,1.873c0.288,0.303,0.28,0.311-0.331,1.873
				c0.785,0.937,1.601,1.911,2.496,2.98c1.211-0.481,2.395-1.634,3.771-1.226c1.445,0.429,2.512,1.582,3.29,3.204
				c-0.142,0.428-0.368,1.113-0.596,1.798l-0.595,3.571c0.021,1.839-1.016,3.366-1.488,5.06"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M184.787,609.694c-4.094-1.558-7.565,0.026-10.677,2.428c-2.153,1.663-3.624,4.006-4.813,6.493c-0.918,1.92-2.12,3.717-2.902,5.685
				c-0.433,1.09-1.028,1.942-1.858,2.64c-1.579,1.324-4.066,0.777-4.702-1.19c-0.804-2.486-0.944-5.076,1.157-7.114
				c3.525-3.42,6.979-6.939,10.748-10.074c3.084-2.564,3.839-5.603,3.402-9.387c-4.129-0.801-7.86,0.582-11.447,1.71
				c-3.774,1.186-7.418,2.932-10.777,5.2c-2.76,1.865-4.994,4.096-6.847,6.865c-5.476,8.187-11.154,16.239-16.595,24.449
				c-1.726,2.604-3.684,4.756-6.594,5.911c-1.908,0.757-3.985,0.996-5.967,1.4c-3.103,0.632-5.714,2.024-8.345,3.617
				c-0.87,0.527-1.396,2.236-2.943,1.245"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M289.838,333.525c0.789,0.19,1.068-0.499,1.478-0.903c0.699-0.691,1.006,0.189,1.795,0.297c-4.128,4.284-5.537,10.109-9.488,14.328
				c-0.952,1.016-1.691,2.259-2.715,3.239c-1.642,1.571-3.065,3.27-3.254,5.658c-0.038,0.473-0.399,0.605-0.614,0.89
				c-3.248,4.293-4.368,9.754-7.712,14.006c-0.937,1.19-1.033,2.811-1.745,4.182c-1.404,2.707-3.203,5.109-5.16,7.375
				c-1.827,2.114-2.968,4.532-3.166,7.18c-0.294,3.94-1.835,7.384-3.688,10.683c-2.296,4.091-4.509,8.262-8.246,11.384
				c-1.567,1.309-2.296,3.366-3.023,5.305c-1.288,3.433-2.934,6.69-5.959,8.911c-7.738,5.68-12.144,13.635-15.446,22.345
				c-1.869,4.929-3.611,9.905-5.362,14.878c-1.075,3.051-1.055,3.03-2.979,4.174c-1.615,0.96-2.819,2.461-5.066,2.457
				c-2.181-0.005-4.353,0.818-6.553,1.04c-0.729,0.074-1.261,0.503-1.732,0.748c-1.42,0.742-2.518,2.117-3.951,2.813
				c-1.09,0.53-2.383,0.735-3.577,1.144c-7.917,2.704-12.268,8.667-15.44,15.89c-4.677,10.652-8.733,21.574-14.225,31.874
				c-1.915,3.593-3.124,7.608-3.783,11.601c-0.4,2.427-1.633,3.33-3.379,4.493c-3.662,2.439-7.548,4.533-10.792,7.64
				c-3.917,3.75-6.426,8.343-8.542,13.159c-2.79,6.354-4.905,12.975-6.835,19.638c-0.373,1.287-0.461,2.737-0.542,4.14
				c-0.097,1.683-1.22,3.446-2.146,5.096c-2.249,4.007-5.11,7.533-8.091,10.976c-3.281,3.789-6.388,7.674-8.692,12.174
				c-0.354,0.691-0.65,1.65-1.421,2.11c-2.046,1.221-2.267,3.352-3.092,5.315c-1.21,2.879-3.165,5.467-4.952,8.076
				c-5.818,8.488-12.246,16.58-17.208,25.625c-4.259,7.765-9.079,15.191-13.367,22.929c-0.977,1.762-1.762,3.731-1.922,5.912
				c-0.111,1.516-1.22,2.954-1.356,4.475c-0.377,4.204-2.931,6.776-5.969,9.27c-2.458,2.017-4.642,4.404-6.09,7.423
				c0.697,0.78,1.556,0.877,2.478,0.864c13.391-0.194,26.787,0.341,40.178-0.239c1.143-0.049,2.373,0.231,3.568,0.231
				c15.277,0.006,30.554,0.004,45.83,0.004c34.125,0,68.249,0,102.373,0c23.114,0,46.227,0.004,69.34-0.011
				c1.297,0,2.629,0.257,3.873-0.427c1.172-0.644,2.099,0.503,3.27,0.491c9.125-0.097,18.252-0.063,27.379-0.042
				c1.186,0.003,2.177-0.321,2.976-1.202"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M328.228,704.032c-0.241-2.701-1.612-5.232-1.193-8.035c0.463-3.102,1.61-6.069,1.8-9.225c0.006-0.104,0.252-0.186,0.276-0.301
				c1.19-5.704,1.184-5.701-2.665-10.417c-0.711-0.871-0.899-1.735-0.693-2.987c0.307-1.864,0.545-3.826-0.75-5.674
				c-1.28-1.827-0.879-4.012-0.008-5.898c1.251-2.711,1.073-5.205-0.325-7.764c-1.344-2.46-2.228-5.222-5.064-6.558
				c-1.797-0.847-1.463-2.576-1.512-4.148c-0.044-1.419-0.767-1.984-2.067-1.786c-1.224,0.188-2.302,1.43-1.735,2.35
				c1.727,2.801,1.162,5.552,0.246,8.364c-1.538,4.721-0.11,8.997,2.11,13.08c1.551,2.851,3.333,5.578,5.037,8.344
				c1.652,2.681,1.698,4.16-0.341,6.516c-1.019,1.176-1.838,2.503-2.939,3.635c-0.073,0.904,0.552,1.581,0.907,2.346
				c1.048,2.252,0.266,4.445-0.382,6.53c-0.407,1.312-0.52,2.317,0.029,3.612c1.076,2.537,1.134,5.294,1.233,8.017"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M618.087,704.032c-0.521-3.79-1.25-7.415-4.736-9.856c-2.178-1.524-3.139-3.933-3.594-6.512c-0.416-2.361-1.633-4.348-2.998-6.235
				c-0.841-1.163-2.294-1.218-3.557-1.479c-2.088-0.433-2.328-0.624-2.374-2.701c-0.013-0.596-0.07-1.2,0.014-1.783
				c0.144-1.001-0.482-1.763-1.202-1.723c-1.897,0.105-2.821-1.374-4.171-2.147c-1.66-0.95-3.588-0.702-5.369-1.139
				c-1.088-0.267-2.116-0.539-2.952-1.256c-0.978-0.839-0.802-1.516,0.272-2.386c1.161-0.942,2.569-0.842,3.875-1.201
				c2.541-0.699,3.04-0.998,1.771-3.232c-1.122-1.979-1.873-4.015-2.442-6.241c-1.001-3.916-2.945-7.345-7.054-8.96
				c-1.441-0.567-2.7-1.348-3.305-2.955c-0.491-1.305-1.167-2.564-2.952-2.419c-0.181,0.014-0.373-0.396-0.596-0.562
				c-2.431-1.816-5.091-2.219-8.042-1.534c-1.242,0.289-2.536-0.337-3.86-0.288c-3.61,0.132-7.564,3.912-7.369,7.468
				c0.062,1.111,0.292,2.475,1.419,3.277c2.214,1.578,2.381,3.927,1.997,6.235c-0.162,0.979-0.09,1.794,0.08,2.694
				c0.57,3.024,2.015,5.752,3.071,8.596c0.96,2.582,3.866,2.761,6.178,3.217c1.282,0.253,2.819,0.551,4.193-0.444
				c0.45-0.326,1.639-0.378,2.002,0.549c0.234,0.599,0.141,1.335-0.557,1.774c-0.604,0.382-1.231,0.743-1.78,1.195
				c-1.919,1.58-2.537,4.508-1.823,7.143c0.661,2.446,1.872,4.501,3.941,5.881c0.674,0.45,0.803,1.201,1.526,1.594
				c-0.222,0.67-0.254,1.55-0.709,2.007c-2.054,2.061-0.942,4.101,0.078,5.96c1.331,2.426,2.994,4.669,3.526,7.465"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M322.574,523.986c-0.458-1.885-0.329-3.749-0.028-5.658c0.552-3.505,1.222-6.977,2.097-10.415c0.91-3.574,2.335-6.969,3.656-10.386
				c0.684-1.769,2.166-3.266,4.113-3.834c0.675-0.197,1.262-0.515,1.427-0.97c0.934-2.58,2.179-5.179,1.202-8.009
				c-0.49-1.42-1.23-2.753-1.792-4.163c-1.018-2.557-2.778-3.5-5.022-2.99c-2.154,0.488-4.033,2.83-4.18,5.059
				c-0.122,1.833,0.365,3.634,0.721,5.334c1.01,4.828,0.803,9.552-0.125,14.305c-0.348,1.782-1.66,3.046-2.664,4.468
				c-1.652,2.338-3.922,4.306-4.731,7.15c-0.769,2.707-2.022,4.967-4.164,6.875c-2.33,2.078-2.462,5.159-2.642,8.001
				c-0.121,1.908-0.41,3.847-0.824,5.613c-0.466,1.988-0.446,3.784-0.141,5.69"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M92.83,445.123c0.099,0.397,0.419,0.956,0.265,1.167c-3.708,5.046-6.723,10.532-10.067,15.808
				c-5.128,8.091-10.116,16.271-15.206,24.386c-2.121,3.381-3.909,7.008-6.845,9.815c-0.716,0.685-0.722,1.562-0.86,2.396
				c-0.112,0.676-0.022,1.386-0.022,2.08c0,31.148-0.002,62.296,0.006,93.445c0,1.145-0.185,2.314,0.278,3.413
				c1.036,0.658,2.18,0.451,3.287,0.448c2.582-0.005,5.189-0.291,7.732-0.173c2.483,0.114,4.966,0.079,7.446,0.161
				c2.577,0.085,5.158,0.019,7.737,0.019c2.579,0,5.158,0,7.737,0s5.158,0,7.737,0c2.48,0,4.96,0,7.44,0
				c2.58,0,5.159-0.024,7.737,0.012c1.113,0.015,2.31-0.313,3.274,0.584"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M377.034,634.395c1.399,2.71,2.174,5.567,2.404,8.629c0.604,8.021,2.858,15.397,8.915,21.12c2.147,2.029,4.71,2.984,7.718,3.363
				c4.879,0.614,9.775,1.137,14.614,2.198c3.047,0.668,6.174,0.99,9.222,1.818c1.397,0.38,2.983,0.158,4.455-0.502
				c4.721-2.116,9.243-1.911,13.38,1.468c2.2,1.798,4.479,3.475,6.814,5.104c5.187,3.616,7.825,9.089,9.811,14.848
				c1.293,3.751,2.353,7.602,2.721,11.592"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M219.308,415.363c-3.185,1.726-5.93,3.882-7.794,7.11c-0.524,0.907-1.703,1.458-2.628,2.107c-1.568,1.1-2.615,2.286-2.77,4.465
				c-0.18,2.542-1.789,4.726-3.821,6.188c-1.711,1.233-2.15,2.65-2.045,4.533c0.061,1.088,0.011,2.182,0.011,3.272
				c0,4.126-1.166,5.383-5.345,6.304c-1.124,0.248-2.2,0.229-3.285,0.216c-1.897-0.024-3.068,0.227-3.191,1.523
				c-0.218,2.294-1.697,2.439-3.355,2.683c-1.581,0.231-2.503,1.313-2.682,2.964c-0.298,2.766-0.409,5.549-3.839,6.634
				c-0.821,0.26-1.72,1.225-2.117,2.292c-0.516,1.39-1.542,2.505-2.706,3.223c-2.358,1.454-2.519,3.74-2.607,6.007
				c-0.12,3.062-0.302,5.762-3.556,7.537c-2.267,1.236-3.711,3.576-3.619,6.448c0.035,1.104-0.521,2.043-1.239,2.627
				c-0.949,0.771-1.442,1.744-2.114,2.678c-1.153,1.604-1.557,3.208-1.437,5.112c0.147,2.326-1.777,3.369-3.29,4.409
				c-2.199,1.512-4.171,3.161-5.724,5.255c0.947,1.373,2.397,0.356,3.786,1.091c-0.768,2.245-1.012,4.581-2.498,6.497
				c-0.734,0.946-1.522,1.787-2.971,1.517c-2.076-0.389-3.087,0.81-3.57,2.656c-0.342,1.311-0.989,2.436-1.951,3.453
				c-0.858-0.84-1.331-1.462-0.758-2.576c0.749-1.457,1.016-3.093,2.076-4.479c1.899-2.483,2.727-5.574,4.086-8.36
				c1.17-2.396,1.994-5.145,1.909-7.976c-0.065-2.16,0.43-4.129,2.084-5.651c1.26-1.159,2.045-2.569,2.72-4.15
				c1.049-2.457,3.137-4.312,4.173-6.843c0.498-1.215,0.741-2.198-0.05-3.297c-1.05-1.457-1.664-3.135-2.256-4.932
				c1.527-0.893,1.814-2.463,1.682-4.294c-0.142-1.977,0.595-3.939,0.007-5.959c-0.421-1.447,0.863-2.539,1.23-3.798
				c-2.239-1.965-2.565-2.014-4.533-0.654c-1.214,0.838-2.277,1.95-3.866,2.073c-0.727,1.305-1.032,2.7-0.891,4.173
				c0.216,2.247-0.824,3.952-2.409,5.333c-0.872,0.761-1.768,1.623-3.027,0.084c-0.758-0.928-1.976-0.431-2.933,0.506
				c-1.146,1.122-2.032,2.405-2.3,3.908c-0.331,1.85-1.457,2.265-3.026,2.387c-0.909,0.07-1.802-0.234-2.734,0.512
				c-0.721,0.576-1.473-0.688-2.167-0.893c-2.208,1.881-0.306,3.78,0.302,5.048c1.14,2.376,3.111,4.319,1.622,7.222
				c0.667,0.274,0.828,1.105,0.813,1.484c-0.035,0.852,0.079,2.172-1.407,2.067c-1.577-0.11-2.2,1.136-2.291,2.11
				c-0.15,1.612-2.168,2.562-1.372,4.493c0.138,0.333-0.815,1.266-1.414,1.729c-2.394,1.851-2.418,4.248-1.778,6.866
				c0.44,1.8,0.285,3.328-0.749,5.135c-1.093,1.91-1.626,4.205-2.763,6.201c-0.296,0.521-0.423,1.303-0.375,2.056
				c0.29,4.56-2.685,7.5-6.027,9.338c-2.495,1.371-5.62,2.496-8.83,1.985c-0.677-0.108-1.386-0.017-2.081-0.017"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M325.848,520.118c-0.491-6.245,0.81-12.11,3.894-17.544c0.926-1.632,2.35-2.833,4.13-3.61c1.051-0.459,1.93-1.368,3.002-1.714
				c1.585-0.511,2.16-1.435,2.083-3.023c-0.115-2.358,0.846-4.564,1.314-6.815c0.3-1.443,0.464-2.562-0.117-3.907
				c-1.358-3.145-2.488-6.392-3.923-9.499c-1.397-3.024-3.678-4.206-6.804-3.545c-2.188,0.463-4.269,1.449-5.927,2.971
				c-1.222,1.122-1.891,2.603-1.854,4.429c0.075,3.716,0.394,7.35,1.076,11.039c0.572,3.091,0.831,6.56-1.312,9.514
				c-0.539,0.743-0.693,1.745-0.994,2.634c-1.366,4.038-3.191,7.834-6.124,11.083c-1.646,1.822-3.286,3.722-4.537,5.893
				c-0.343,0.595-0.9,1.145-1.764,0.906"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M335.668,264.78c-1.314,0.079-1.515,1.135-1.778,2.085c-1.404,5.055-2.678,10.149-4.229,15.158
				c-0.562,1.815-0.588,3.583-0.511,5.373c0.179,4.192-0.273,8.381-0.755,12.485c-0.743,6.318-1.06,12.806-3.758,18.754
				c-0.413,0.91-0.213,1.793-0.27,2.688c-0.282,4.483,0.169,8.942,0.626,13.388c0.232,2.263-0.479,4.384-0.765,6.533
				c-0.29,2.173-0.326,4.506-1.139,6.533c-0.832,2.076-0.503,4.231-1.091,6.287c-0.587,2.057-0.877,4.207-1.567,6.224
				c-0.325,0.948-0.109,1.809-0.234,2.699c-0.966,6.912-1.838,13.8-1.941,20.829c-0.074,5.045,0.069,10.182-0.632,15.153
				c-0.389,2.757,0.617,5.835-1.3,8.359c0.387,1.569-0.105,3.308,0.402,4.724c0.553,1.544,0.446,3.003,0.473,4.502
				c0.06,3.272,0.323,6.582-0.049,9.812c-0.642,5.569-0.369,11.235-2.163,16.722c-0.565,1.729-1.057,3.883-0.729,5.9
				c0.318,1.959-1.037,3.798-0.555,5.643c0.817,3.128-0.774,6.094-0.167,9.262c0.532,2.774,0.111,5.731,0.111,8.608
				c0,2.977,0.368,6.011-0.093,8.913c-0.577,3.633,0.358,7.15,0.127,10.73c-0.171,2.664-0.57,5.18-1.354,7.786
				c-0.763,2.536-1.569,5.105-2.22,7.695c-0.188,0.75-0.152,1.618,0.265,2.372"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M369.296,335.905c1.615,0.147,3.179-0.517,4.83,0.808c2.009,1.611,2.62,3.332,2.352,5.744c-0.181,1.615-0.223,3.281,0.835,4.77
				c1.126,1.584,0.727,3.579,1.117,5.364c0.97,4.44,2.419,8.725,3.598,13.092c0.336,1.242,1.009,2.197,1.575,3.244
				c2.222,4.111,3.92,8.459,4.072,13.108c0.229,7.005,3.511,12.701,6.899,18.459c3.453,5.871,6.986,11.699,8.683,18.432
				c1.287,5.112,3.261,10.05,3.002,15.487c-0.077,1.633-0.609,3.133-0.726,4.754c-0.312,4.34,1.28,8.338,1.94,12.49
				c0.33,2.08,0.898,4.135,1.129,6.26c0.434,4.01,2.168,7.549,4.116,11.028c1.898,3.391,3.057,7.048,2.99,10.997
				c-0.014,0.82-0.185,1.583-0.477,2.418c-0.723,2.068-0.9,4.327-1.297,6.51"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M317.812,303.765c1.877,4.563,0.509,9.157-0.105,13.675c-0.268,1.97-1.52,3.884-2.615,5.643c-1.338,2.15-1.813,4.414-1.748,6.871
				c0.023,0.892-0.091,1.797,0.02,2.676c0.49,3.872-0.292,7.646-1.296,11.286c-0.872,3.16-1.003,6.389-1.677,9.552
				c-1.251,5.878-1.58,11.854-1.871,17.849c-0.146,3.008-1.144,5.976-1.886,8.889c-0.441,1.732-0.505,3.468-0.616,5.108
				c-0.408,6.048-1.286,11.977-2.747,17.859c-1.057,4.254-0.929,4.319,1.171,8.311c0.802,1.523,1.317,3.016,1.197,4.775
				c-0.129,1.896,1.116,3.767,0.5,5.63c-0.38,1.151-0.158,2.203-0.226,3.296c-0.053,0.848,0.061,1.719,0.606,2.366
				c1.065,1.262,0.985,2.744,0.85,4.179c-0.317,3.38-0.626,6.772-1.21,10.112c-0.459,2.625-2.163,4.628-4.447,5.909
				c-1.299,0.729-2.404,1.531-3.393,2.646c0.637,1.126,1.49,1.955,2.536,2.117c2.433,0.376,3.694,2.062,4.724,3.935
				c1.412,2.566,3.456,4.667,4.321,7.686c1.122,3.916,1.456,7.554-0.096,11.356c-0.555,1.358-0.344,3.007-1.517,4.154"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M328.823,284.421c-4.498,1.957-8.449,6.766-9.557,11.3c-0.556,2.273-1.265,4.488-1.516,6.846c-0.135,1.26-1.48,2.058-2.085,3.248
				c-0.838,1.652-1.515,3.063-1.227,5.106c0.404,2.859,0.145,5.843-2.295,8.004c-0.606,0.538-0.515,1.141-0.599,1.8
				c-0.354,2.783-0.886,5.548-1.119,8.339c-0.095,1.144-0.333,1.987-1.239,2.681c-2.563,1.962-3.177,4.654-3.009,7.732
				c0.152,2.783,0.105,5.553,0.552,8.343c0.512,3.201-0.046,6.578-1.261,9.452c-1.329,3.147-1.658,6.375-2.185,9.591
				c-0.759,4.634-1.678,9.229-1.994,13.968c-0.304,4.557-1.073,9.133-1.684,13.693c-0.152,1.137-0.81,2.276-1.466,3.261
				c-1.07,1.604-1.264,3.485-0.754,5.043c0.634,1.939,0.639,3.849,0.392,5.677c-0.248,1.847,0.044,3.411,0.66,5.083
				c0.677,1.84,1.599,3.402,3.299,4.46c1.677,1.042,2.22,2.944,3.277,4.457"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M469.586,507.321c0.202,2.903-0.676,6.016,1.218,8.61c2.031,2.781,3.181,6.354,6.807,7.785c0.77,0.304,1.348,1.069,2.103,1.442
				c3.608,1.778,4.614,5.175,4.946,8.658c0.198,2.083,1.025,3.93,1.387,5.925c0.098,0.536,0.357,1.182,0.534,1.794
				c0.949,3.289,2.546,6.134,4.94,8.72c1.95,2.105,3.524,4.638,3.474,7.956c-0.029,1.978,1.415,3.812,1.805,5.926
				c0.385,2.082,0.798,4.185,1.001,6.279c0.215,2.218,0.778,4.236,2.157,5.934c3.608,4.438,5.126,9.633,5.588,15.194
				c0.067,0.805,0.279,1.474,0.665,2.066c1.565,2.401,3.725,4.432,6.536,4.685c6.413,0.577,10.468,4.398,13.608,9.361
				c1.836,2.9,2.352,6.491,4.135,9.538c0.648,1.108,0.271,2.94,0.052,4.395c-0.18,1.19-1.38,1.707-2.279,2.448
				c-1.235,1.019-2.802,1.718-3.619,3.215c1.649,2.545,4.075,2.499,6.538,1.76c4.843-1.453,8.182-0.208,10.722,4.191
				c0.928,1.607,1.893,3.142,3.233,4.504c2.522,2.564,2.89,6.004,0.891,8.859c-1.801,2.572-2.616,5.278-2.295,8.385
				c1.594,0.841,2.825-0.436,4.102-0.966c3.518-1.46,5.26-1.098,7.785,1.512c2.616,2.702,3.317,6.175,4.089,9.561
				c1.352,5.925,2.824,11.78,4.687,17.587c1.891,5.897,3.705,11.748,6.587,17.266c0.679,1.299,2.24,2.319,1.872,4.121"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M519.88,704.032c0.198-2.083,0.43-4.164,0.575-6.251c0.042-0.603,0.205-1.018,0.59-1.506c1.922-2.437,3.715-4.979,6.275-6.825
				c2.463-1.775,4.372-4.159,6.797-6.014c2.755-2.106,2.471-3.374-0.546-5.299c-2.871-1.832-5.806-3.593-8.034-6.248
				c-2.709-3.228-3.79-5.449-1.477-9.811c1.757-3.312,1.426-6.723-0.102-9.785c-1.798-3.604-4.03-7.064-7.649-9.27
				c-3.231-1.97-6.07-1.578-8.069,0.866c-2.238,2.735-4.532,5.362-5.341,8.951c-0.633,2.812-0.358,5.208,2.081,7.17
				c3.027,2.434,4.148,6.286,6.503,9.253c0.683,0.86,0.813,2.298,0.082,3.531c-0.494,0.835-0.651,1.901-1.237,2.646
				c-2.428,3.083-2.037,6.183-0.642,9.575c0.694,1.688,1.36,3.389,2.17,5.023c0.98,1.979,1.599,4.023,1.477,6.255
				c-0.143,2.605,0.104,5.181,0.595,7.737"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M457.98,671.297c-1.601-1.063-1.267-3.269-2.669-4.475c-1.471-1.266-2.339-2.893-3.054-4.724c-0.67-1.714-1.777-3.312-2.938-4.765
				c-1.971-2.464-3.273-2.731-6.747-1.744c-0.745-0.86-1.396-1.746-1.953-2.997c-1.682-3.767-3.311-7.601-6.115-10.787
				c-1.684-1.913-3.382-3.83-4.563-6.188c-0.801-1.599-2.628-1.906-4.392-2.156c-2.596-0.368-5.231-0.681-6.79,1.863
				c-1.287,2.1-1.01,4.587-0.022,6.79c0.97,2.162,0.275,4.184,0.297,6.358c-2.408-0.312-3.799-1.819-4.401-3.403
				c-1.175-3.085-2.952-5.875-4.71-8.495c-2.637-3.93-6.318-7.09-11.146-8.486c-0.474-0.137-0.894-0.636-1.504-0.538
				c-2.611,0.42-4.984-0.686-7.435-1.217c-3.525-0.765-6.621-0.117-9.22,2.418c-1.296,1.264-1.586,3.085-1.229,5.35
				c0.131,0.836,0.398,2.002-1.163,1.93"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M429.411,645.703c-1.014-1.433-0.377-3.24-1.15-4.781c-0.911-1.812-1.968-3.079-4.207-2.666c-0.288,0.054-0.594,0.008-0.892,0.008
				c-0.1,0.396-0.411,0.915-0.266,1.172c1.936,3.397,1.278,6.943,0.526,10.427c-0.668,3.094-3.774,4.316-7.386,2.647
				c-1.329-0.614-2.608-1.512-3.286-2.939c-1.412-2.976-3.977-5.304-4.802-8.62c-0.096-0.382-0.388-0.906-0.607-1.157
				c-2.702-3.086-5.815-5.729-9.05-8.352c-1.226,0.844-1.324,1.733-0.698,2.639c0.47,0.682,1.222,1.162,1.746,1.814
				c0.673,0.835,0.414,1.65-0.447,2.628c-3.307-1.922-5.634-4.967-8.307-7.61c-2.073,1.092-0.117,3.356-1.738,4.436
				c-1.942-0.445-1.973-3-3.952-3.718c-1.271,0.984-1.829,2.55-1.534,3.939c0.693,3.266,0.667,6.553,0.791,9.838
				c0.074,1.999,1.075,3.616,1.522,5.063c0.982,0.997,1.677-0.648,2.673,0.701c-0.4,2.293,0.479,4.683,1.734,7.056
				c1.09,2.061,2.545,3.248,4.79,3.906c2.087,0.612,3.83,0.898,5.724-0.593c0.893-0.702,2.323-1.227,3.532-0.078
				c0.104,0.1,0.444-0.069,0.574,0.025c3.73,2.713,8.076,3.564,12.509,4.146c0.328,0.043,0.613,0.185,0.902,0.267
				c2.192,0.62,4.169,0.882,6.595-0.119c4.265-1.762,8.812-1.907,13.047,0.735c1.719,1.072,3.657,1.633,5.631,2.15
				c1.799,0.472,3.601,1.107,3.936,3.514c0.188,1.354,1.251,2.402,2.329,3.278c2.278,1.849,4.203,3.941,5.093,6.837
				c0.091,0.294,0.129,0.756,0.283,0.876c3.589,2.815,3.766,7.29,5.229,11.08c0.795,2.06,1.253,4.349,1.974,6.479
				c0.382,1.129,0.476,2.181,0.514,3.301"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M242.223,68.664c-0.762-0.785-1.746-0.592-2.678-0.592c-31.148-0.003-62.297-0.003-93.445,0c-0.932,0-1.916-0.193-2.678,0.592"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M406.199,377.569c2.124-2.613,2.421-3.712,0.858-5.928c-2.385-3.383-3.435-7.321-5.03-11.033c-0.859-1.999-1.565-4.047-2.616-5.985
				c-1.229-2.263-3.105-2.212-5.113-2.022c-0.696,0.066-1.313,0.51-2.071,0.646c-1.152,0.207-1.27,1.547-0.304,2.289
				c0.632,0.486,1.397,0.859,2.097,1.149c3.662,1.516,4.805,4.629,4.967,8.09c0.14,2.987,0.86,5.904,0.755,8.928
				c-0.041,1.193,0.08,2.775,1.97,3.35c1.238,0.376,1.941,1.786,2.826,2.897c-3.35,5.193-1.69,10.277,0.54,15.146
				c1.523,3.326,4.73,4.586,8.262,4.441c2.429-0.099,4.614,0.72,6.821,1.338c1.333,0.374,2.63,1.379,3.33,2.868
				c0.38,0.81,1.071,1.472,1.713,2.319c0.916-1.313,0.745-2.398,0.044-3.497c-1.198-1.879-2.232-3.886-3.604-5.629
				c-3.171-4.033-5.28-8.662-7.724-13.108c-0.58-1.056-1.354-2.007-2.071-2.982c-0.805-1.096-1.828-1.754-3.27-1.493
				c-1.234,0.223-1.662-0.869-2.38-1.486"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M308.289,319.835c0.144,0.672,0.497,1.239,1.305,1.775c-2.209,1.651-3.015,4.108-4.898,5.638c-1.852,1.503-1.892,3.614-1.356,5.35
				c0.791,2.566,0.493,4.99,0.166,7.471c-0.288,2.183-1.109,4.322-0.847,6.547c0.447,3.781-1.246,7.297-1.215,11.014
				c-1.193,1.025-0.48,2.38-0.562,3.574c-0.109,1.565-0.042,2.929-1.449,4.241c-1.654,1.54-1.495,4.047-1.816,6.179
				c-0.463,3.083-1.812,5.895-2.484,8.908c-0.112,0.503-0.247,1.013-0.218,1.501c0.257,4.429-0.952,8.716-1.289,13.089
				c-0.059,0.771-0.228,1.61-0.186,2.385c0.153,2.833-0.418,5.399-2.082,7.762c-0.774,1.1-1.149,2.401-1.286,3.841
				c-0.192,2.027-0.867,2.661-2.627,2.893c-1.304,0.172-2.541,0.66-3.857,0.685"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M233.146,548.538c-1.25-2.027-1.833-4.441-3.709-6.112c-2.184-1.944-2.475-4.555-1.725-7.11c0.619-2.111,0.512-4.179,0.502-6.27
				c-0.018-3.92,0.247-7.793,1.206-11.608c0.892-3.55,2.717-6.672,4.484-9.811c2.072-3.681,3.957-7.453,5.592-11.337
				c0.526-1.25,1.13-2.694,0.466-4.053c-1.207-0.947-2.354-0.265-3.05,0.264c-2.27,1.721-4.941,2.553-7.513,3.43
				c-3.656,1.246-6.368,3.362-8.31,6.626c-2.957,4.971-6.031,9.876-8.867,14.914c-0.764,1.355-2.112,2.146-2.734,3.54"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M198.476,615.349c-0.224-1.668,1.026-2.767,1.863-3.807c1.212-1.506,2.225-3.231,4.048-4.299c1.258-0.736,1.617-2.41,2.122-3.799
				c0.606-1.669,1.346-3.186,2.96-4.195c1.185-0.742,2.281-1.676,3.276-2.665c2.182-2.171,4.535-3.729,7.733-4.269
				c1.967-0.332,3.912-1.568,5.365-3.166c1.235-1.358,2.874-2.172,4.495-2.596c4.192-1.098,8.224-2.859,12.783-2.521
				c4.812,0.357,4.851,0.161,8.915-2.031c2.229-1.202,4.342-2.62,6.562-3.841c4.334-2.384,6.73-6.233,8.333-10.724
				c0.148-0.414,0.383-0.8,0.589-1.191c0.89-1.688,2.208-3.294,2.544-5.085c0.271-1.446-0.054-3.153-0.202-4.733
				c-0.08-0.854,0.345-1.423,0.641-2.083c0.476-1.062,0.487-1.987-0.616-2.69c-1.633-0.08-2.299,1.496-3.513,2.172
				c-3.294,1.836-6.919,2.165-10.466,2.869c-1.465,0.291-2.889,0.77-4.462,0.71c-0.789-0.03-1.679,0.688-2.404,1.372
				c-1.369,1.293-2.999,2.247-4.734,3.008c-2.737,1.198-5.119,2.831-6.872,5.335c-0.634,0.905-1.466,1.742-2.372,2.375
				c-3.898,2.725-5.398,7.274-8.308,10.744c-0.543,0.646-0.465,1.363-0.016,2.081c0.534,0.852,1.379,0.672,2.087,0.565
				c3.365-0.505,6.445-1.873,9.537-3.227c1.685-0.738,2.943-2.043,4.425-3.041c0.97-0.653,1.89-0.932,3.005-0.855
				c2.285,0.157,4.549-0.059,6.868,0.502c2.257,0.547,4.625-0.351,6.8-1.148c1.094-0.401,2.002-1.274,3.294-0.836"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M292.517,350.19c0.997,1.588-0.459,2.674-0.958,3.841c-1.185,2.779-2.628,5.47-3.451,8.376c-0.452,1.599-1.276,3.259-0.139,5.095
				c0.417,0.673,0.122,2.035-0.254,2.907c-1.892,4.391-2.568,9.236-5.037,13.398c-0.165,0.278-0.298,0.405-0.292,0.606
				c0.183,5.762-2.053,11.095-3.01,16.657c-0.644,3.741-1.382,7.538-2.997,10.987c-1.188,2.54-1.129,5.173-1.37,7.773
				c-0.381,4.109-0.5,8.158-2.626,11.958c-0.917,1.637-0.812,3.9-0.969,5.896c-0.211,2.67-0.225,5.354-0.326,8.033
				c-0.091,2.412-1.015,4.613-1.795,6.841c-2.504,7.143-3.443,14.538-2.84,22.016c0.218,2.705,0.386,5.792,2.552,8.045"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M449.052,704.032c0.083-1.405,0.104-2.763,0.501-4.192c0.648-2.324-0.135-4.652-2.316-5.872c-2.807-1.568-3.785-4.154-4.745-6.894
				c-0.925-2.641-2.304-4.872-4.705-6.611c-3.725-2.699-7.519-2.47-11.325-0.775c-2.617,1.166-4.906,1.585-7.805,0.664
				c-3.058-0.972-5.99-2.821-9.491-2.268c-0.929,0.146-2.034,0.175-2.625,0.702c-2.923,2.609-6.726,2.885-10.163,4.115
				c-1.474,0.527-3.018,0.891-4.171,2.08c-1.12,1.153-1.386,2.405-0.265,3.549c3.63,3.701,3.773,8.433,4.13,13.122
				c0.06,0.789,0.009,1.586,0.009,2.38"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M347.87,217.165c-1.516,3.307-1.712,6.915-2.39,10.414c-0.437,2.253-0.914,4.403,0.54,6.591c0.685,1.028-0.372,2.374-0.658,3.496
				c-0.312,1.221-0.495,2.391-0.452,3.603c0.082,2.34-0.573,4.57-0.926,6.843c-0.209,1.352-0.617,2.625-1.749,3.596
				c-0.762,0.654-1.079,1.616-0.908,2.655c0.269,1.644,1.089,1.433,2.305,0.498c2.327,3.038,3.478,6.678,4.835,10.216
				c1.028,2.681,0.843,5.302,0.043,8.048c-1.162,3.995-0.873,8.126-0.307,12.184c0.529,3.792,1.353,7.544,2.048,11.313
				c0.42,2.278,0.92,4.532,1.26,6.834c0.822,5.586,0.98,11.152-0.146,16.66c-0.443,2.171,0.308,3.893,1.034,5.643
				c0.537,1.295,0.869,2.51,0.842,3.896c-0.077,3.937,0.854,7.75,1.442,11.611c1.042,6.836,2.794,13.574,2.999,20.53
				c0.073,2.495-0.111,4.999-0.618,7.433c-0.739,3.548,0.111,6.887,1.271,10.107c1.793,4.981,3.422,9.989,4.42,15.194
				c0.572,2.98,1.016,6,1.828,8.915c0.854,3.063,2.942,5.562,4.417,8.347c1.558,2.941,3.663,5.562,4.536,8.909
				c0.545,2.088,0.726,4.254,1.63,6.305c0.797,1.808,0.888,3.929,1.272,5.916c0.67,3.468,2.506,6.341,4.788,8.905
				c2.396,2.691,4.82,5.35,6.812,8.36c1.906,2.882,3.883,5.764,3.278,9.517c-0.158,0.982,0.455,1.77,0.635,2.672
				c0.473,2.37,0.432,4.761,0.604,7.146c0.209,2.89,0.687,5.768,1.177,8.628c0.201,1.173,0.747,2.261,1.438,3.292
				c2.633,3.933,5.417,7.767,7.767,11.886c0.432,0.756,0.766,1.46,0.943,2.374c1.036,5.322,4.591,9.176,7.997,13.087
				c0.128,0.147,0.573,0.019,0.871,0.019"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M566.305,704.032c-0.097-0.818,0.002-1.557-0.538-2.417c-2.925-4.661-4.221-10.004-6.017-15.139
				c-2.551-7.291-4.083-14.85-5.949-22.321c-0.55-2.198-1.512-4.148-3.655-5.41c-1.612,0.344-3.033,1.354-4.657,1.885
				c-3.066,1.004-6.912-0.69-7.154-5.106c-0.195-3.564,0.779-6.827,2.682-9.816c1.565-2.46,1.18-3.186-0.636-4.416
				c-1.49-1.01-1.79-2.632-2.585-3.956c-2.158-3.592-2.15-3.739-6.305-2.627c-2.206,0.592-4.366,0.086-6.552,0.011
				c-1.125-0.038-1.424-0.984-2.065-1.534c-0.732-0.628-1.077-0.119-1.646,0.464c1.494,3.145,2.767,6.43,4.845,9.416
				c2.852,4.1,4.561,8.853,5.573,13.679c0.526,2.51,1.143,5.338-0.382,8.049c-0.866,1.539,0.661,3.734,2.604,3.746
				c1.913,0.011,3.178,0.871,4.124,2.193c1.05,1.468,2.405,1.396,3.909,1.491c2.837,0.181,5.455,0.938,7.463,3.222
				c1.535,1.746,1.691,4.008,1.938,5.986c0.533,4.254,2.508,7.757,4.586,11.294c1.891,3.216,3.938,6.342,5.403,9.801
				c0.229,0.542,0.152,1.011,0.252,1.507"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M320.491,370.129c1.114,3.343,3.016,6.364,3.93,9.805c0.222,0.836,0.342,1.665,0.194,2.388c-1.064,5.222-1.513,10.535-2.415,15.769
				c-0.837,4.857-1.197,9.691-1.144,14.596c0.014,1.308,0.304,2.442,0.909,3.578c1.432,2.687,2.344,5.515,2.105,8.624
				c-0.072,0.929-0.08,1.785-0.561,2.7c-1.599,3.046-1.819,6.485-2.399,9.803c-0.653,3.734-1.522,7.475-1.166,11.299
				c0.091,0.98-0.318,1.893-0.714,2.322c-1.795,1.95-2.903,4.384-4.693,6.313"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M495.18,556.425c1.498,0.088,1.633,1.545,2.375,2.386c2.231,2.527,3.786,2.762,6.549,0.882c1.094-0.746,2.317-0.808,3.573-0.906
				c1.412-0.11,2.23,0.777,3.334,1.404c2.826,1.607,3.559,4.191,4.083,6.952c0.542,2.843,1.486,5.151,4.132,6.928
				c3.385,2.274,6.477,5.06,8.387,8.843c0.04,0.079-0.038,0.245,0.011,0.288c3.585,3.104,5.13,7.399,6.851,11.608
				c1.821,4.458,4.987,7.945,8.309,11.326c2.165,2.202,5.049,3.803,6.186,6.86c0.212,0.57,0.604,1.062,0.734,1.745
				c1.005,5.254,4.419,9.38,6.836,13.971c0.568,1.081,1.554,2.212,3.224,1.834c0.74-0.168,1.393,0.379,2.086,0.546
				c4.128,0.994,8.128,2.41,12.201,3.578c4.054,1.162,7.997,2.641,11.877,4.238c4.463,1.839,7.631,5.064,9.276,9.761
				c1.546,4.414,3.368,8.73,5.019,13.108c0.86,2.283,1.878,4.551,3.619,6.206c4.694,4.46,8.273,9.715,11.575,15.213
				c3.913,6.515,7.457,13.229,10.999,19.946c0.041,0.077,0.006,0.195,0.006,0.272c-0.312,0.434-0.581,0.908-1.19,0.913
				c-0.794,0.007-1.588,0.002-2.381,0.002c-42.061,0-84.121,0-126.182,0c-0.893,0-1.939,0.301-2.646-0.062
				c-1.494-0.768-2.725-0.733-4.229,0c-0.995,0.485-2.229-0.048-3.232-0.554c-1.167-0.587-1.67-1.471-1.937-2.93
				c-0.271-1.482-0.347-3.015-0.723-4.497c-0.882-3.479-2.052-6.788-4.493-9.516"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M473.158,678.736c-1.198-4.345-3.936-7.852-6.165-11.655c-1.101-1.876-2.769-3.393-3.395-5.595
				c-0.582-2.049-1.336-4.049-2.193-6.027c-0.644-1.485-0.769-3.708,0.742-5.292"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M343.704,437.386c-0.937,3.11-1.534,6.268-1.825,9.52c-0.445,4.974-0.796,10.001-1.852,14.86
				c-0.946,4.354-0.346,8.426,0.794,12.496c0.271,0.967,0.522,2.104,1.111,2.993c2.88,4.353,2.636,9.144,2.074,13.996
				c-0.137,1.187-0.248,2.379-0.294,3.571c-0.101,2.601-0.517,3.383-3.003,4.106c-0.996,0.289-1.763,0.883-2.669,1.227
				c-5.674,2.154-7.046,6.884-7.429,12.225c-0.416,5.809,0.354,11.541,1.212,17.258c0.242,1.61,0.584,3.255,1.205,4.748
				c1.286,3.09,0.356,5.938-1.322,8.283c-1.806,2.525-3.427,4.974-3.772,8.102c-0.332,3.004-2.218,5.101-4.182,7.13
				c-1.023,1.058-2.091,2.107-3.282,2.96c-1.503,1.076-3.41,2.062-4.031,3.645c-1.184,3.011-3.899,5.223-4.281,8.585
				c-1.249,0.53-0.346,1.416-0.268,2.08c0.479,4.039,3.172,6.951,5.339,10.112c2.478,3.614,4.526,7.363,4.444,11.913
				c-0.024,1.35,0.739,2.398,1.532,3.239c2.669,2.829,4.618,6.226,7.375,8.991c2.369,2.377,2.76,5.25,1.218,8.303
				c-0.484,0.958-0.287,2.188-1.189,2.977"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M329.121,197.821c0.198,0.396,0.607,0.819,0.563,1.186c-0.497,4.074-0.569,8.174,0.099,12.194c0.553,3.326-0.705,6.085-1.874,8.931
				c-0.676,1.647-1.523,3.169-1.542,5.067c-0.009,0.928-0.609,1.858-1.343,2.738c-1.726,2.068-2.588,4.523-2.196,7.387
				c0.381,2.79-0.052,5.481-2.031,7.741c-1.626,1.857-2.352,4.06-2.088,6.538c0.229,2.156-0.86,3.896-1.825,5.636
				c-0.5,0.901-1.313,1.647-2.058,2.391c-1.499,1.499-2.082,3.007-0.962,5.11c0.431,0.809,0.432,2.3-0.825,2.913
				c-1.763,0.86-2.343,3.315-4.749,3.377c-0.412,0.01-0.967,1.056-1.165,1.711c-1.213,4.028-3.335,7.674-4.798,11.595
				c-0.341,0.914-0.587,1.973-1.217,2.649c-2.528,2.717-3.453,6.223-3.987,9.578c-0.616,3.871-2.206,7.221-3.706,10.694
				c-4.145,9.605-10.171,18.22-14.565,27.685c-2.748,5.92-5.943,11.642-8.486,17.619c-1.417,3.33-2.763,6.606-4.969,9.421
				c-2.472,3.156-4.839,6.414-7.692,9.262c-1.042,1.041-1.986,2.181-2.986,3.264c-0.81,0.877-1.21,1.823-0.593,2.978"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M305.313,477.562c-0.464-0.815-0.928-1.63-1.39-2.442c-1.182,0.235-2.507-0.437-3.33,0.399c-1.286,1.307-2.898,2.303-3.377,4.409
				c-0.772,3.396-2.645,6.397-4.006,9.579c-1.711,3.997-1.831,8.468-2.446,12.76c-0.631,4.406-2.061,5.896-6.58,6.245
				c-3.974,0.308-7.474,1.587-10.127,4.754c-2.488,2.97-5.436,5.55-7.44,8.93c-1.123,1.894-2.784,2.762-5.04,3.057
				c-2.383,0.311-4.722,0.92-7.143,1.198c-2.839,0.325-5.469,1.604-7.42,3.812c-0.87,0.984-1.8,1.302-3.006,1.165"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M272.28,537.378c0.297,0.298,0.708,0.908,0.876,0.848c5.178-1.869,8.824-5.078,9.231-10.966c0.083-1.193,1.244-2.294,0.298-3.587
				c-0.929-0.841-2.449,0.436-3.321-1.046c0.597-1.632,2.727-1.724,3.612-3.115c0.832-1.309,1.525-2.705,2.344-4.179
				c-2.844-0.098-5.289,0.407-7.059,2.43c-1.345,1.537-2.726,3.034-4.213,4.42c-1.184,1.104-1.945,2.138-0.446,3.304
				c-0.472,0.873-1.033,0.822-1.616,0.906c-1.762,0.255-2.369,1.219-1.753,2.938c0.275,0.771,0.446,1.402-0.034,2.097
				c-0.101,0.099-0.187,0.264-0.301,0.287c-1.649,0.332-3.144-1.024-4.75-0.551c-1.577,0.467-3.032,1.214-4.771,1.211
				c-1.131-0.003-2.434,0.305-3.245,1.162c-1.872,1.979-4.19,1.91-6.61,1.68c-1.692,2.332-3.504,4.538-4.984,6.949
				c-1.5,2.442-4.019,4.052-5.164,6.793c-0.406,0.972-1.568,1.584-2.606,2.126c-2.799,1.464-5.624,2.888-7.186,5.918
				c-0.469,0.909-1.241,1.755-2.067,2.372c-3.507,2.62-5.629,6.336-8.01,9.851c-0.926,1.366-1.93,2.706-2.653,4.178
				c-1.27,2.585-3.369,3.229-5.982,2.961"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M277.042,543.926c2.347-3.931,4.76-7.822,6.849-11.902c1.442-2.815,3.61-4.126,6.846-3.293c0.959,0.246,2.017-0.241,2.974,0.312
				c1.744,1.008,2.186,2.673,2.048,4.465c-0.157,2.045-0.39,4.128-1.471,5.945c-0.737,1.238-0.735,2.351,0.002,3.588
				c2.099,3.517,2.451,7.331,1.811,11.304c-0.093,0.58-0.124,1.214,0.008,1.778c0.411,1.771-0.061,3.755,0.916,5.335
				c1.05,1.697,0.512,3.105-0.369,4.473c-0.759,1.178-1.707,2.247-2.661,3.282c-2.369,2.57-3.87,5.645-4.691,8.952
				c-0.755,3.042-2.156,5.771-3.302,8.628c-0.461,1.148-1.116,1.949-2.092,2.697c-2.174,1.667-3.424,3.971-4.564,6.48
				c-1.659,3.651-2.78,7.195-3.126,11.325c-0.371,4.424-2.722,8.537-7.196,10.769c-0.642,0.32-1.151,0.989-1.529,1.737
				c-2.292,4.529-6.103,7.342-10.69,9.238c-4.027,1.664-9.412,0.261-11.63-2.961c-0.396-0.576-0.039-1.473-0.869-1.802"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M332.395,149.312c-2.323,0.948-3.39,0.471-3.855-1.788c-0.147-0.716,0.3-1.661-0.616-2.063c-1.733-0.76-2.502-2.206-2.958-3.889
				c-0.262-0.969-0.945-0.995-1.775-1.254c-2.109-0.659-4.138-1.107-6.271-0.233c-1.338,0.549-2.478,1.274-2.064,2.973
				c0.283,1.162-0.544,1.632-1.231,2.052c-0.738,0.451-1.697-0.107-2.004-0.595c-1.084-1.724-2.768-2.289-4.507-2.975
				c-2.199-0.868-3.916-0.951-5.013,0.671c-1.226,1.813-2.733,2.657-4.816,2.975c-1.516,0.231-2.783,2.258-2.012,3.483
				c1.09,1.733,0.961,4.368,3.563,5.06c-0.177,1.16-1.105,1.569-1.808,2.286c-1.253-0.612-2.411-1.178-3.458-1.689
				c-1.307,0.337-1.96,1.192-2.697,2.097c0.749,1.536,0.996,3.231,2.535,4.202c1.047,0.66,1.826,1.516,2.422,2.654
				c0.698,1.335,2.195,1.747,3.54,2.068c1.833,0.439,3.224,1.16,2.93,3.304c-0.111,0.808,0.352,1.295,0.676,1.76
				c0.564,0.809,1.307,1.478,1.758,2.403c0.999,2.048,2.547,2.794,4.405,1.719c1.178-0.682,2.486-0.689,3.686-1.264
				c1.201-0.575,2.984-0.42,4.421,0.706c2.231,1.748,3.523,1.208,4.482-1.434c0.153-0.421,0.148-0.812,0.258-1.206
				c0.636-2.281,2.824-4.031,5.033-3.497c1.973,0.478,3.779-0.405,5.677-0.101c0.99,0.159,1.452-0.504,1.52-1.596
				c0.309-1.163-1.434-0.987-1.513-1.952c-0.056-0.69-0.011-1.388-0.011-2.157c0.98-0.735,1.996-1.497,2.972-2.229
				c0.13-3.079-3.341-3.429-4.023-5.881c2.143-2.303,2.143-2.303,1.051-4.314"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M48.487,431.434c-1.597,0.956-2.196,2.753-2.885,4.21c-2.963,6.269-6.333,12.312-9.62,18.404c-0.761,1.41-0.823,2.798,0.013,4.166
				c1.163,1.901,2.796,3.869,2.796,5.956c0,2.086-1.634,4.054-2.796,5.956c-0.836,1.367-0.836,2.79,0,4.158
				c1.163,1.901,2.796,3.869,2.796,5.956c0,2.086-1.634,4.054-2.796,5.955c-0.836,1.368-0.76,2.749-0.015,4.167
				c3.589,6.835,6.833,13.88,10.861,20.444c1.891,3.082,2.374,6.002,2.236,9.311c-0.128,3.075,0.338,6.168-0.292,9.226"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M634.456,604.04c-1.164,0.425-2.375,0.293-3.571,0.294c-25.098,0.005-50.195,0-75.292,0.014c-1.112,0.001-2.31-0.312-3.273,0.585"
				/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M290.136,415.363c-3.805,0.872-5.871,3.39-5.973,7.143c-0.027,1.002-0.125,1.975-0.431,3.018c-0.51,1.738-0.6,3.941,1.658,4.982
				c0.368,0.17,0.575,0.439,0.877,0.63c3.641,2.29,3.61,3.17,0.025,5.399c-0.708,0.439-1.543,0.673-2.138,1.423
				c-2.031,2.562-1.526,5.134,1.531,6.233c2.21,0.795,4.521,1.402,6.838,1.777c2.452,0.397,4.661-0.926,6.796-1.835
				c1.621-0.689,2.348-2.656,2.667-4.377c0.462-2.479,1.702-5.063-0.487-7.48c-0.395-0.437-0.056-1.537-0.056-2.33
				c-0.767-0.97-2.208-0.816-2.976-1.786c-0.128-1.314,1.398-1.21,1.843-2.213c-0.463-1.602-2.058-1.958-3.348-2.511
				c-2.105-0.902-3.547-2.238-4.173-4.495c-0.411-1.484-1.073-2.889-2.358-3.875"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M48.19,312.991c-2.044,0.64-2.478,2.749-3.162,4.218c-2.682,5.766-5.915,11.231-8.79,16.887c-0.887,1.744-1.006,3.114,0.009,4.809
				c1.066,1.781,2.521,3.746,2.521,5.631s-1.455,3.85-2.521,5.631c-1.015,1.695-1.015,3.113,0,4.808
				c1.066,1.781,2.521,3.746,2.521,5.631c0,1.884-1.455,3.85-2.521,5.631c-1.015,1.695-0.898,3.065-0.007,4.808
				c3.415,6.678,6.837,13.349,10.406,19.946c0.31,0.572,0.524,1.347,0.98,1.715c1.206,0.974,1.447,2.229,1.451,3.611
				c0.013,4.166,0.011,8.333-0.008,12.499c-0.001,0.298-0.186,0.596-0.285,0.893"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M280.613,371.915c0.666,0.991,0.725,1.797,0.063,3.01c-2.127,3.899-4.1,7.894-5.434,12.163c-0.195,0.627-0.461,1.308-0.898,1.771
				c-2.626,2.786-2.899,6.286-3.292,9.835c-0.518,4.683-1.054,9.368-1.992,14.002c-0.321,1.591-0.778,3.167-1.609,4.407
				c-1.901,2.838-3.222,5.934-4.724,8.959c-1.205,2.427-2.766,4.657-4.733,6.561c-3.873,3.744-5.611,8.375-5.999,13.688
				c-0.167,2.287-0.963,4.531-0.25,6.848"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M48.19,193.356c-1.033-0.274-1.197,0.774-1.438,1.218c-3.648,6.704-6.904,13.616-10.762,20.21c-0.81,1.384-0.831,2.794,0.005,4.162
				c1.163,1.902,2.796,3.87,2.796,5.956c0,2.086-1.634,4.054-2.796,5.956c-0.836,1.368-0.836,2.791,0,4.158
				c1.163,1.902,2.796,3.87,2.796,5.956s-1.72,4.01-2.763,5.973c-0.624,1.174-0.88,2.398-0.091,3.875
				c3.784,7.082,7.383,14.262,11.084,21.388c0.211,0.406,0.326,1.201,1.168,0.905"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M350.25,236.806c2.354,1.419,3.562,3.51,3.557,6.25c-0.003,1.279,0.52,1.851,1.801,1.795c0.648-0.027,1.388-0.208,1.829,0.679
				c-0.116,1.025-1.387,0.945-1.831,1.693c-0.074,0.479,0.391,0.6,0.598,0.891c0.591,0.83,1.973-0.436,2.171,0.535
				c0.694,1.404-0.69,2.033-0.313,3.28c3.194,1.185,2.921,4.603,4.074,7.206c0.924,2.084,0.694,4.346,0.581,6.537
				c-0.09,1.746,0.215,3.243,1.52,4.467c1.374,1.29,2.069,2.844,1.744,4.768c-1.012,0.181-1.547-1.188-2.615-0.906
				c-0.387,0.318-0.908,0.752-0.587,1.174c1.16,1.524,1.205,3.314,1.475,5.078c0.443,2.908,1.293,5.649,3.543,7.754
				c0.308,0.288,0.438,0.765,0.634,1.123c-0.439,1.158-1.188,1.562-2.404,1.595c-0.962,0.026-1.953,0.432-2.1,1.73
				c-0.117,1.038,0.247,1.68,1.195,2.107c0.358,0.162,0.553,0.721,0.91,0.848c2.979,1.049,4.263,3.144,4.537,6.266
				c0.138,1.553,1.439,2.767,2.894,3.581c3.491,1.954,5.249,5.335,7.179,8.607c0.532,0.903,0.545,1.766,0.561,2.699"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M105.031,704.032c-0.21-3.516,2.254-6.846,6.246-7.462c1.254-0.193,2.376-0.859,3.268-1.772c2.067-2.115,4.357-3.981,6.51-5.993
				c1.781-1.663,3.857-2.802,6.025-3.429c3.516-1.016,5.597-3.357,7.033-6.381c0.386-0.812,0.678-1.633,1.264-2.35
				c1.428-1.746,1.682-1.801,3.881-0.893c1.873,0.773,3.45,0.678,5.417-0.479c3.514-2.066,6.427-4.505,8.259-8.148
				c0.655-1.304,1.8-2.308,3.007-2.58c1.997-0.452,2.684-1.707,3.27-3.361c0.94-2.658,2.522-4.796,5.004-6.317
				c0.766-0.47,1.807-0.946,2.219-2.179c-1.35-1.928-3.603,1.327-4.846-0.813c1.27-2.76,3.354-5.158,3.721-8.534
				c0.163-1.502,1.341-3.273,2.792-4.506c1.816-1.542,2.697-4.045,5.08-5.035"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M298.469,704.032c0.436-3.657,0.821-7.321,0.576-11.01c-0.065-0.984,0.478-1.69,0.947-2.357c0.762-1.084,1.819-1.956,2.628-3.012
				c1.488-1.94,2.883-3.884,3.043-6.533c0.135-2.239,1.937-3.417,3.834-4.122c1.292-0.48,2.36-1.128,3.307-2.188
				c-0.647-1.23-1.737-1.578-3.025-1.417c-1.037,0.129-1.884-0.199-2.049-1.209c-0.298-1.831-1.67-2.851-2.651-4.206
				c-0.949-1.312-1.271-3.019-1.26-4.716c0.013-1.903-0.118-3.772-0.484-5.675c-0.238-1.237-0.86-1.896-1.592-2.658
				c-1.629,0.166-1.413,1.811-1.997,2.733c-0.567,0.895-1.161,0.905-1.868,0.81c-0.747-0.102-1.193-0.613-1.202-1.46
				c-0.023-2.239-0.3-4.46,2.368-5.699c1.869-0.868,2.218-2.956,2.328-4.721c0.146-2.346,0.729-4.294,2.428-5.976
				c0.946-0.937,1.477-2.304,2.447-3.203c0.959-0.889,1.601-1.978,1.075-2.979c-0.712-1.356-2.098-2.249-3.791-2.108
				c-2.437,0.202-4.525,1.357-6.211,3.002c-1.428,1.393-2.593,3.16-2.382,5.312c0.14,1.431-0.524,2.301-1.54,2.96
				c-1.777,1.154-2.759,2.769-3.276,4.777c-0.393,1.527-1.485,2.662-2.685,3.546c-2.755,2.031-3.688,4.763-3.904,8.061
				c-0.189,2.877-0.699,5.736-0.523,8.633c0.066,1.079-0.563,1.712-1.206,2.382c-2.161-0.759-2.528-2.447-2.396-4.465
				c0.084-1.284-0.036-2.581,0.035-3.866c0.045-0.823-0.255-1.507-0.927-1.745c-0.804-0.285-1.562,0.036-2.076,0.846
				c-1.697,2.672-2.88,5.572-3.531,8.643c-0.684,3.222-0.771,6.521-0.624,9.813c0.09,2.016-0.412,3.997-0.708,5.936
				c-0.906,5.943-1.077,11.895-1.081,17.872"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M436.851,484.406c-1.029,1.799-2.107,3.494-3.833,4.809c-1.702,1.295-1.629,1.836-0.632,3.822c0.797,1.585,1.568,3.184,2.387,4.758
				c0.856,1.646,1.475,3.323,3.848,3.679c1.605,0.24,3.121,2.698,3.289,4.062c-1.052,1.029-2.629-1.094-3.604,0.814
				c0.476,1.487,2.054,1.964,3.316,2.743c2.124,1.31,4.142,2.728,5.961,4.459c2.052,1.951,3.875,1.639,4.979-0.9
				c0.34-0.781,0.653-1.567,1.274-2.027c1.721-1.275,1.411-2.886,0.852-4.487c-1.114-3.192-2.573-6.242-4.156-9.227
				c-0.743-1.4-1.803-2.034-3.556-2.17c-3.54-0.273-5.396-2.415-5.609-6.171c-0.081-1.431-1.146-1.88-1.872-2.643
				c-0.626-0.655-1.553-1.024-2.346-1.521"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M359.774,585.887c4.215,3.085,8.641,5.8,11.707,10.348c2.338,3.468,6.043,5.438,10.304,6.369c5.223,1.141,9.995,3.363,13.057,8.016
				c1.368,2.078,1.39,4.649,1.201,7.107c-0.039,0.516-0.479,1.068-0.868,1.479c-2.251,2.382-5.081,3.511-8.32,3.567
				c-2.133,0.037-3.864-0.362-5.169-2.598c-1.136-1.946-2.547-3.911-4.365-5.407c-0.84-0.69-1.764-1.454-3.128-0.494
				c-0.889,3.138,2.031,5.015,2.665,7.789c-0.518,0.552-0.669,1.647-1.599,2.229c-1.021,0.64-2.134,0.35-2.341-0.916
				c-0.169-1.033-0.409-1.554-1.528-1.817c-2.415-0.568-3.421-2.325-3.272-4.722c0.263-4.231-1.847-7.294-5.127-9.436
				c-1.513-0.988-2.567-1.698-2.705-3.653c-0.074-1.056-0.862-2.125-1.694-2.988c-1.316-1.364-2.565-2.791-3.881-4.155
				c-1.629-1.688-1.723-3.811-1.997-5.849c0.415-0.207,0.725-0.442,0.795-0.385c0.813,0.669,1.592,1.382,2.43,2.127
				c1.773-1.796,1.679-4.613,3.539-6.313"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M458.576,471.015c-0.849-2.783-2.716-4.999-4.183-7.431c-3.448-5.714-5.654-11.816-6.568-18.455
				c-1.025-7.452-4.51-13.737-10.132-18.696c-1.552-1.37-3.623-2.349-5.623-2.971c-4.438-1.382-6.497-4.708-8.04-8.684
				c-0.705-1.817-1.405-3.636-2.281-5.403c-1.185-2.391-3.186-3.232-5.442-3.789c-0.816-0.202-1.943-0.426-2.328,1.425
				c2.487,5.093,3.947,11.124,5.962,16.965c1.693,4.909,3.185,9.93,6.164,14.326c1.468,2.165,3.267,3.898,5.395,5.327
				c6.639,4.456,10.888,10.56,13.518,18.115c1.021,2.938,0.869,5.803,0.26,8.327c-0.708,2.931,0.26,5.237,0.819,7.783
				c0.087,0.396,1.044,0.582,0.279,1.195"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M209.785,565.947c1.148-1.764,2.12-3.624,2.664-5.658c0.889-3.318-0.1-6.088-2.33-8.307c-1.436-0.096-2.255,0.559-2.998,1.186
				c-4.7,3.962-8.944,8.313-11.626,13.967c-1.272,2.682-2.495,5.312-3.041,8.323c-1.205,6.644-3.989,12.701-7.983,18.152
				c-1.844,2.518-2.902,5.17-2.685,8.348c0.197,2.87,0.774,3.517,3.597,3.591c0.79,0.021,1.654-0.354,2.381,0.276"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M335.073,189.488c-0.482,2.083-0.505,4.172,0.007,6.248c0.443,1.794,0.415,3.56,0.006,5.361c-0.577,2.542-0.843,5.164-0.52,7.723
				c0.156,1.232,0.099,2.17-0.703,2.969c-0.969,0.965-0.887,2.109-0.88,3.292c0.017,2.579-0.068,5.161,0.025,7.737
				c0.101,2.797-2.022,4.776-2.482,7.426c-0.708,4.077-2.923,7.993-2.188,12.196c0.523,2.997,0.088,6.011,0.781,8.948
				c0.562,2.38,0.474,4.801-0.053,7.13c-0.452,2.003-0.398,3.677,1.244,5.071"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M270.495,560.293c1.86-0.919,1.729-2.917,2.352-4.475c1.094-2.737,1.838-5.627,3.096-8.278c0.463-0.977,0.336-1.822,0.517-2.722
				c-1.897,0-3.784,0.056-5.667-0.015c-2.238-0.084-3.957-0.014-5.208,2.622c-1.14,2.4-4.041,3.577-6.705,3.853
				c-2.999,0.311-5.816,1.604-8.925,1.354c-1.098-0.087-2.328,0.59-3.266,1.413c-1.941,1.701-3.942,3.237-6.534,3.924
				c-0.889,0.235-1.713,1.03-2.377,1.745c-3.683,3.968-8.191,7.164-10.787,12.156c-1.374,2.642-3.369,4.955-4.988,7.479
				c-1.287,2.007-3.52,2.731-5.425,3.417c-2.323,0.836-4.628,1.678-6.759,2.883c-0.711,0.402-1.026-0.38-1.522-0.061"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M262.757,486.787c-1.017-3.369-2.546-6.485-4.776-9.213c-1.002-1.226-1.864-2.528-2.66-3.884c-1.189-2.024-1.21-4.162-0.268-6.232
				c0.622-1.368,1.84-2.501,2.294-3.901c1.371-4.217,2.198-8.514,0.08-12.785c-0.844,0.108-1.603-0.078-2.446,0.549
				c-1.959,1.456-4.133,2.622-6.209,3.924c-3.779,2.368-6.802,5.412-9.286,9.184c-3.581,5.438-6.669,11.061-8.562,17.303
				c-0.355,1.173-0.537,2.399-0.914,3.564c-1.058,3.275-2.754,5.88-5.884,7.83c-5.304,3.304-10.124,7.358-13.103,13.03
				c-1.648,3.137-2.821,6.523-4.213,9.796c-0.888,2.085-1.656,4.237-2.747,6.211c-0.382,0.692-1.049,1.398-1.97,1.904
				c-2.319,1.276-2.475,2.509-0.909,4.661c0.535,0.735,0.761,1.276,0.281,2.11c-2.231,3.879-2.331,8.491-4.207,12.479
				c-1.531,3.256-2.321,6.845-4.292,9.976c-1.534-0.657-1.381-2.406-2.431-3.06c-1.663,0.479-2.179,2.006-2.717,3.239
				c-3.413,7.82-7.593,15.253-11.378,22.883c-1.794,3.616-3.761,7.146-5.636,10.724c-0.736,1.403-1.407,2.836-1.491,4.462"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M352.631,651.061c1.052-1.379,0.44-2.585-0.282-3.878c-2.723-4.875-6.262-9.106-10.133-13.086
				c-1.548-1.591-2.487-3.485-3.001-5.648c-0.337-1.419-0.326-2.757-0.001-4.178c0.585-2.559,1.552-5.189,1.376-7.722
				c-0.215-3.094-0.368-6.423-1.989-9.21c-2.308-3.97-4.431-8.074-7.335-11.8c1.028-2.458,1.931-5.169,3.508-7.571
				c0.778-1.184,1.67-1.276,2.346,0.021c1.131,2.172,2.899,3.609,4.753,5.096c1.155,0.925,2.705-0.101,3.615,1.171
				c-0.363,4.464,1.976,7.929,4.502,11.243c1.2,1.574,2.647,2.77,2.755,5.083c0.071,1.54,1.63,2.651,3.165,3.266
				c1.392,0.557,2.51,1.562,3.201,2.731c0.902,1.523,1.297,3.445,0.025,4.984c-1.055,1.275-1.35,2.811-2.032,4.205
				c-0.688,1.407-0.456,2.638,0.889,3.566c1.03-0.287,1.781-1.288,2.971-1.189c1.34,0.748-0.148,2.524,1.191,3.273
				c1.132,0.28,1.474-1.02,2.522-1.218c0.236,0.687,1.415,0.766,0.808,2.136c-1.222,2.76-0.198,4.854,2.296,6.563
				c2.711,1.859,2.835,3.512,0.917,6.205c-0.368,0.517-0.973,0.938-1.15,1.502c-0.418,1.331-0.783,2.811,0.571,3.841
				c2.398,1.824,4.274,3.897,4.154,7.159c2.681,3.405,2.955,7.609,3.921,11.594c0.096,0.395,0.312,0.826,0.226,1.198
				c-1.165,4.979,0.901,9.348,2.749,13.672c1.64,3.838,3.474,7.621,6.769,10.468c1.459,1.26,1.682,3.195,1.792,5.03
				c0.09,1.482,0.019,2.975,0.019,4.463"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M572.555,367.153c-0.528-2.253-1.9-4.362-0.645-6.87c0.646-1.29-1.089-2.836-2.617-2.321c-1.849,0.623-3.211,0.006-4.263-0.996
				c0.366-5.046-0.646-9.763-1.52-14.488c0.193-0.059,0.386-0.118,0.578-0.177c-1.047-1.041-2.368-1.923-3.079-3.158
				c-1.218-2.112-3.304-1.777-4.439-1.025c-1.902,1.26-3.374,0.604-4.82-0.179c-2.153-1.166-4.288-2.443-6.601-3.137
				c-1.617-0.486-3.257-0.75-4.899,1.268c-1.462,1.795-4.39,1.872-6.982,1.687c-1.744-0.125-3.863-0.822-5.062,1.418
				c-0.88,1.644-2.086,2.585-4.15,2.775c-1.875,0.172-4.633,4.336-4.764,6.455c-0.042,0.692-0.022,1.389-0.002,2.083
				c0.024,0.847-0.503,1.533-1.188,1.438c-2.26-0.316-4.318,0.845-6.559,0.707c-0.821-0.051-1.289-0.794-2.079-0.657
				c-1.92,0.872-2.998,2.142-2.753,4.472c0.098,0.923-0.194,2.014-1.374,2.735c-1.462,0.893-1.318,2.587,0.289,3.116
				c1.136,0.374,1.905,1.162,2.927,1.622c1.876,0.844,1.991,1.723,0.6,3.22c-0.421,0.453-0.795,0.789-0.916,1.495
				c-0.426,2.497-2.642,3.68-5.002,2.639c-0.594-0.263-1.488,0.006-1.556-1.116c-3.084,3.333-3.308,4.502-1.423,7.998
				c1.227,2.274-0.17,4.089-0.977,5.932c-0.418,0.958-1.5,1.639-2.319,2.404c-1.057,0.986-1.426,2.174-1.115,3.673
				c0.777,1.096,2.26-0.041,3.244,0.833c0.619,1.329,0.994,2.907,2.764,3.587c1.091-0.805,2.013-2.122,3.746-1.859
				c0.966,0.146,1.865-0.028,2.717,0.875c1.512,1.604,4.154,2.244,4.125,5.099"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M245.794,584.994c-1.091,0.793-2.401,1.408-3.227,2.419c-1.553,1.903-3.239,2.741-5.711,2.081c-1.604-0.429-3.108,0.27-4.448,1.163
				c-1.121,0.748-2.334,1.439-3.25,2.399c-2.063,2.164-4.684,2.966-7.467,3.556c-2.823,0.598-5.393,1.702-7.453,3.847
				c-2.582,2.689-5.43,5.137-7.43,8.343c-1.437,2.304-1.163,4.383,0.898,6.243c2.527,2.282,5.261,3.925,8.927,3.641
				c1.761-0.136,2.941-0.657,3.917-2.111c0.333-0.498,0.643-1.043,0.798-1.536c1.119-3.569,3.659-6.311,5.445-9.484
				c0.92-1.635,2.29-2.982,3.763-4.262c1.464-1.271,3.45-2.348,3.631-4.692"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M260.376,494.524c-0.915-2.847-2.598-5.384-3.295-8.327c-0.377-1.591-1.443-2.845-2.671-3.856
				c-2.718-2.237-9.014-0.673-10.012,2.688c-0.574,1.928-0.061,4.098,1.101,5.924c1.923,3.021,1.559,6.158,0.261,9.212
				c-1.101,2.59-2.143,5.101-4.304,7.281c-2.48,2.504-3.531,6.21-4.237,9.707c-1.113,5.512-1.372,11.178-2.765,16.647
				c-0.234,0.919,0.186,2.051,0.419,2.305c1.688,1.832,1.797,4.348,3.034,6.333"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M306.801,478.156c1.935,2.444,2.558,5.813,1.801,8.635c-1.833,6.832-3.678,13.663-5.314,20.544
				c-0.474,1.994-0.396,4.157-0.322,6.234c0.106,2.961-0.403,5.746-1.83,8.328c-1.847,3.346-1.903,7.12-2.642,10.725
				c-0.589,2.874,1.177,5.467,1.326,8.319c0.045,0.869,0.394,1.983,0.412,2.984c0.06,3.372-0.058,6.748,0.063,10.116
				c0.047,1.299,0.554,2.581,0.852,3.87"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M331.502,623.086c1.109-0.914,1.402-2.36,2.608-3.358c2.782-2.304,3.432-6.632,1.181-9.979c-2.52-3.748-4.184-8.037-7.336-11.386
				c-1.208-1.282-1.354-2.971-0.286-4.414c0.895-1.211,1.281-2.449,1.149-3.895c-0.056-0.615,0.127-1.05,0.587-1.502
				c3.466-3.412,4.094-7.607,3.293-12.189c-0.242-1.385,0.078-2.713,0.927-4.238c2.995,0.598,3.745,3.114,3.732,5.43
				c-0.027,5.11,2.894,8.071,6.618,10.748c0.968,0.694,1.969,0.735,3.002,0.846c2.735,0.29,3.404,0.822,3.232,3.581
				c-0.245,3.949,1.365,7.042,3.905,9.826c0.616,0.676,1.224,1.363,1.782,2.086c0.942,1.219,0.414,3.022,1.513,4.144
				c1.236,1.262,2.965,1.896,4.175,3.268c1.685,1.912,2.801,3.891,3.048,6.562c0.205,2.217,0.948,4.507,2.591,6.248
				c0.983,1.043,1.448,2.346,2.127,3.551c1.017,1.805,1.401,3.403-0.258,5.112c0.803,0.684,1.226,1.436,2.264,1.825
				c1.923,0.722,3.003,2.651,3.163,4.414c0.181,1.991,0.624,3.941,0.777,5.936c0.254,3.317,0.893,6.586,1.638,9.848
				c0.365,1.602,1.613,2.52,1.977,4.123c0.984,4.337,2.473,8.518,2.065,13.121c-0.216,2.441,0.805,4.845,1.728,7.13
				c1.692,4.19,3.452,8.278,7.071,11.379c2.392,2.05,2.375,5.333,2.704,8.271c0.165,1.47,0.029,2.973,0.029,4.461"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M229.724,504.643c-3.937,2.463-5.795,6.475-7.727,10.421c-0.469,0.957-0.899,1.953-1.18,2.977c-0.492,1.788-1.836,2.662-3.28,3.588
				c-4.58,2.938-8.78,6.303-11.307,11.295c-0.699,1.382-1.22,2.855-1.208,4.455c0.05,6.349-2.371,11.646-6.842,16.072
				c-1.596,1.579-3.15,3.199-4.769,4.754c-3.076,2.957-5.201,6.507-6.866,10.41c-1.461,3.427-3.128,6.767-4.74,10.128
				c-2.658,5.548-7.088,9.505-11.934,13.049c-0.302,0.222-0.845,0.28-1.155,0.644c-3.203,3.756-7.719,4.727-12.211,5.28
				c-5.246,0.646-9.67,2.687-13.367,6.341c-2.893,2.861-6.062,5.266-9.472,7.524c-5.37,3.557-9.036,8.72-11.389,14.774
				c-1.268,3.26-2.252,6.728-5.622,8.668c-0.437,0.252-0.802,0.205-1.209,0.266"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M443.994,519.225c-0.442,1.301-1.911,1.446-2.679,2.382c0.096,2.578-0.385,5.119-0.09,7.747c0.334,2.977,1.354,5.651,2.511,8.305
				c0.562,1.291,1.654,2.342,2.316,3.602c1.098,2.088,2.38,4.116,2.705,6.534c0.333,2.476,1.402,4.564,3.267,6.255
				c1.594,1.446,2.816,3.154,3.921,5.025c1.703,2.885,3.477,5.796,5.658,8.317c4.488,5.188,10.115,8.688,16.907,10.183
				c1.499,0.331,2.951,0.869,4.452,1.236c0.879,0.215,1.521,0.327,2.089-0.372c0.534-0.656,0.539-1.416-0.004-2.063
				c-0.387-0.46-0.453-1.036-0.84-1.53c-2.684-3.431-4.026-7.503-5.438-11.561c-0.717-2.061-1.062-4.193-1.062-6.265
				c0-3.361-1.049-6.312-2.467-9.225c-0.938-1.927-1.697-3.919-2.468-5.919c-1.022-2.652-2.096-5.475-5.246-6.643
				c-0.75-0.278-1.215-1.325-1.924-2.149c1.775-2.132,0.253-4.2-0.564-6.085c-0.975-2.251-2.818-3.943-5.268-4.809
				c-1.658-0.586-3.16-1.544-4.124-2.996c-0.685-1.032-0.511-2.357-0.032-3.535c0.098-0.239,0.37-0.417,0.583-0.599
				c3.677-3.132,2.721-6.924,1.472-10.711c-0.963-2.92-2.551-5.566-3.84-8.344c-1.272-2.74-3.213-4.559-6.263-5.06
				c-0.306-0.05-0.594-0.203-0.895-0.289c-2.433-0.692-2.433-0.691-1.144-3.372c-1.948-2.401-4.326-4.364-6.877-6.172
				c-2.616-1.854-4.816-1.649-6.185,1.247c-0.969,2.053-2.429,3.501-3.938,5.032c-2.35,2.386-2.611,4.959-1.222,8.034
				c2.251,4.981,5.291,9.543,7.543,14.535c0.965,2.138,2.807,3.829,4.942,5.151c1.652,1.022,3.513,1.944,4.498,3.814"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M271.982,360.606c-0.915,1.793-2.174,3.352-3.592,4.74c-1.825,1.787-3.004,3.919-3.837,6.275c-0.349,0.987-0.651,2.011-1.506,2.665
				c-5.489,4.195-8.258,10.357-11.561,16.102c-2.036,3.542-3.835,7.228-3.346,11.588c0.26,2.316-0.93,4.453-2.123,6.195
				c-5.031,7.346-10.383,14.45-17.755,19.729c-0.962,0.689-1.792,1.302-3.003,1.152"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M361.261,151.098c0.079,1.706-0.195,2.999-2.044,3.945c-2.428,1.241-2.643,3.139-2.801,5.573
				c-0.444,6.818,2.541,12.775,4.263,19.048c0.919,3.346,1.868,6.689,2.33,10.127c0.236,1.756-0.037,3.609-1.189,5.024
				c-1.038,1.275-0.883,2.713-0.491,3.876c1.465,4.344,2.699,8.758,4.036,13.136c1.02,3.34,1.632,6.74,1.411,10.406
				c-0.209,3.489,1.044,7.051,2.276,10.386c1.393,3.769,2.862,7.57,3.927,11.38c1.15,4.122,3.041,8.246,2.554,12.743
				c-0.057,0.522,0.28,0.806,0.458,1.193c2.07,4.51,3.257,9.331,4.912,13.986c4.491,12.634,7.934,25.594,11.849,38.407
				c1.652,5.406,3.864,10.563,6.067,15.73c0.802,1.883,1.885,3.644,2.321,5.68c0.352,1.64,1.701,2.693,2.467,4.12
				c5.161,9.621,10.04,19.388,15.309,28.958c5.902,10.721,11.189,21.779,16.745,32.691c0.876,4.687,4.393,7.929,6.626,11.859
				c7.701,13.549,15.943,26.778,24.242,39.974c2.125,3.377,4.394,6.854,5.146,10.959c0.579,3.163,1.511,6.269,0.349,9.544
				c-0.409,1.153,0.275,2.435,0.841,3.549c2.363,4.652,5.115,9.073,8.031,13.395c3.02,4.477,5.917,9.031,9.495,13.117
				c2.621,2.993,4.024,6.698,5.406,10.384c0.266,0.708,0.386,1.418,0.25,2.087c-0.515,2.528,0.392,4.71,1.517,6.849
				c2.501,4.75,4.557,9.731,7.435,14.287c3.159,5,5.192,10.619,8.606,15.492c2.449,3.496,4.929,6.966,7.438,10.418
				c0.724,0.996,1.793,2.068,0.921,3.551"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M423.459,485.001c3.093-3.778,5.571-8.029,8.929-11.605c0.635-0.677,1.308-1.304,1.197-2.382c-0.309-3.009-0.139-6.087-1.485-8.933
				c-0.732-1.546-1.687-2.765-3.266-3.602c-2.181-1.156-3.187-3.1-3.002-5.619c0.098-1.338-0.533-2.492-1.204-3.557
				c-1.056-1.673-2.233-3.271-3.714-4.625c-2.422,0.798-2.687,2.791-2.748,4.613c-0.062,1.823-0.161,3.634-0.721,5.334
				c-0.277,0.844-0.235,1.622-0.204,2.399c0.07,1.713,0.87,3.295,0.913,5.06c0.036,1.476,1.488,1.864,2.606,2.344
				c0.84,1.017-0.259,2.374,0.626,3.301c0.828,0.868,0.544,2.087,0.959,2.95c1.779,3.707,1.339,7.341,0.228,11.051
				c-0.353,1.176-0.565,2.386-0.003,3.566c0.646,1.356-0.744,2.539-0.302,3.871"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M457.683,526.367c-2.843,0.879-2.812,0.866-3.89-1.777c-0.297-0.729-0.627-1.455-1.149-2.108c-1.491-1.867-3.683-2.236-4.607-0.201
				c-0.646,1.421-1.728,0.684-2.454,1.658c-0.1,4.646,0.927,9.252,3.494,13.425c1.897,3.082,4.018,5.997,4.195,9.833
				c0.045,0.953,0.909,1.678,2.029,1.794c0.6,0.062,1.263-0.234,1.827,0.254c0.549,0.876,0.118,1.828,0.261,2.716
				c0.08,0.499-0.163,0.999,0.278,1.502c2.824,3.222,4.912,6.975,7.434,10.418c2.758,3.768,6.737,5.74,10.753,7.686
				c0.364,0.177,1.063,0.285,1.564-0.378c-1.391-2.655-2.467-5.488-3.105-8.506c-0.502-2.371-0.356-4.78-0.739-7.169
				c-0.381-2.371-1.062-4.376-2.818-5.908c-1.497-1.309-2.118-2.816-2.088-4.786c0.058-3.653-1.992-5.865-5.629-5.899
				c-1.715-0.016-2.801-0.469-3.151-1.908c0.746-2.457,3.248-4.166,1.973-7.077c-0.768-1.753-1.865-3.033-3.88-3.269"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M401.437,333.525c0.099,0.198-0.235,0.369-0.263,0.599c-0.407,3.436-0.803,6.858-2.084,10.128
				c-0.385,0.985,0.399,2.059,1.176,2.307c4.454,1.422,5.538,5.612,7.357,9.021c5.139,9.626,10.051,19.376,14.911,29.146
				c2.134,4.289,5.291,7.742,8.37,11.288c0.966,1.113,2.203,1.982,2.972,3.278c0.42,0.709,1.109,0.636,1.784,0.596"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M465.718,704.032c0.168-2.663-1.038-4.987-1.745-7.451c-1.438-5.013-3.072-9.952-4.751-14.886
				c-0.592-1.739-1.316-3.351-2.451-4.729c-3.548-4.307-6.127-9.224-8.831-14.047c-0.55-0.98-1.559-1.548-1.843-2.634
				c-0.517,0-1.021-0.059-1.508,0.009c-3.685,0.511-5.963-1.108-7.388-5.384c-1.127-3.379-3.332-6.188-4.544-9.496
				c-0.099-0.27-0.386-0.68-1.298-0.541c-0.979,0.472-2.001,1.342-1.001,2.877c0.461,0.706-0.374,1.027-0.055,1.523"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M444.291,594.814c4.457-0.453,8.075,1.188,11.004,4.471c1.061,1.188,1.629,2.585,2.195,4.121c1.399,3.797,0.905,7.883,2.252,11.653
				c1.064,2.977,1.859,6.04,2.116,9.217c0.033,0.401,0.307,0.805,0.271,1.188c-0.581,6.402,2.892,11.431,5.696,16.654
				c1.954,3.64,4.229,7.108,6.196,10.742c1.438,2.659,1.733,5.712,2.351,8.627c0.2,0.95-0.292,2.045-0.834,2.964"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M175.264,627.253c-1.712-0.091-2.606,1.339-3.868,2.084c-2.764,1.63-5.505,3.114-8.93,3.04c-2.545-0.056-4.432-0.847-5.642-3.047
				c-1.705-3.098-2.33-6.227-1.297-9.843c0.906-3.172,2.546-6.032,3.333-9.207c0.131-0.526,0.914-1.36-0.65-1.588
				c-4.838,1.985-8.179,5.928-11.018,10.766c-3.458,5.895-7.64,11.365-11.509,17.02c-1.29,1.885-2.533,3.804-3.878,5.647
				c-2.942,4.034-6.922,6.342-11.896,6.839c-2.387,0.238-4.653,1.078-6.799,1.913c-2.763,1.075-5.579,1.939-8.338,2.966
				c-1.332,0.496-2.387,0.489-3.61,0.193"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M48.487,549.282c-1.597,0.956-2.199,2.752-2.884,4.209c-2.953,6.274-6.379,12.289-9.622,18.404
				c-0.751,1.416-0.822,2.799,0.014,4.166c1.163,1.902,2.796,3.87,2.796,5.956s-1.634,4.055-2.796,5.956
				c-0.836,1.368-0.836,2.791,0,4.158c1.163,1.902,2.796,3.87,2.796,5.956s-1.634,4.055-2.796,5.956
				c-0.836,1.368-0.899,2.837,0.013,4.148c1.616,2.323,2.262,5.128,3.849,7.454"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M580.887,65.688c-8.53,2.182-17.053,4.399-25.595,6.539c-13.186,3.304-26.385,6.554-39.576,9.837
				c-0.117,0.029-0.201,0.189-0.3,0.289"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M38.964,88.007c-0.166,2.395-1.705,4.219-2.74,6.218c-0.901,1.739-0.993,3.121,0.022,4.816c1.066,1.781,2.521,3.746,2.521,5.631
				s-1.455,3.85-2.521,5.631c-1.015,1.695-0.979,3.097-0.009,4.812c1.065,1.882,2.806,3.824,2.562,5.906
				c-0.231,1.974-1.821,3.788-2.812,5.674c-0.625,1.189-0.807,2.191-0.061,3.603c3.771,7.141,7.391,14.362,11.327,21.418
				c0.288,0.516,0.505,0.571,0.935,0.572"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M49.976,604.338c20.534,0,41.068-0.005,61.603,0.01c1.112,0.001,2.31-0.312,3.273,0.585"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M270.792,638.562c-0.401-1.29-0.466-2.604,0.017-3.862c0.563-1.47,0.491-2.934-0.358-4.143c-0.875-1.248-0.218-2.687-0.929-4.359
				c-1.011,0.758-1.959,0.823-2.69,2.196c-1.775,3.332-4.292,6.238-7.672,8.035c-3.521,1.873-7.264,3.1-11.286,1.247
				c-2.336-1.076-4.525-2.2-4.181-5.366c0.061-0.557-0.523-0.832-0.878-1.325c-1.967,2.629-1.536,5.453-0.833,8.157
				c0.549,2.112,1.822,3.917,3.811,5.077c2.377,1.387,4.508,3.029,5.62,5.882c-0.497,1.171-0.74,2.606-2.942,2.503
				c-5.091-0.238-8.143,2.738-10.104,7.092c-0.476,1.057-0.805,2.178-1.202,3.269"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M462.742,676.653c-3.819-1.086-4.591-2.342-4.185-6.251c0.448-4.328-0.263-8.606-1.247-12.777c-0.608-2.58-1.084-5.108-1.135-7.755
				c-0.088-4.609-1.366-8.923-3.88-12.777c-1.705-2.614-2.552-5.578-3.79-8.373c-2.059-4.647-4.011-9.341-5.483-14.24
				c-0.824-2.743-2.414-5.271-5.278-6.57"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M551.723,68.366c-1.687,0.198-3.373,0.396-5.059,0.596c-0.397,0.047-0.941-0.052-1.168,0.171c-2.579,2.538-6.176,2.442-9.286,3.253
				c-5.109,1.333-10.269,2.57-15.428,3.757c-7.971,1.833-15.889,3.9-23.814,5.922c-4.85,1.238-9.67,2.589-14.579,3.59
				c-0.344,0.07-0.602,0.565-0.898,0.863"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M367.511,333.822c1.814,2.484,2.055,5.599,2.805,8.378c1.417,5.247,3.478,10.326,4.36,15.723c0.247,1.512,0.347,3.37,1.27,4.373
				c1.277,1.389,1.713,3.057,2.557,4.569c1.9,3.406,3.019,7.05,2.682,10.999c-0.236,2.776,0.701,5.632-0.569,8.339
				c-0.076,0.162-0.095,0.466,0.003,0.579c2.096,2.415,0.898,5.648,2.175,8.433c1.115,2.433,1.97,5.207,2.589,7.943
				c1.311,5.789,4.192,11.03,5.918,16.674c0.708,2.316,1.703,4.523,2.489,6.809c0.515,1.493,1.05,3.037,1.017,4.493
				c-0.08,3.502,0.57,6.826,1.591,10.114c0.279,0.896,0.241,1.783,0.285,2.684c0.035,0.734,0.095,1.365,0.527,2.119
				c2.417,4.215,3.134,8.996,4.256,13.647c0.75,3.109,2.019,6.109,2.589,9.243c0.83,4.555,2.167,8.993,2.526,13.672
				c0.448,5.826,2.769,11.296,4.111,16.963c0.731,3.088,2.466,5.91,2.392,9.232c-0.013,0.606,0.596,1.233,0.79,1.807
				c1.464,4.314,4.208,8.071,5.406,12.481c0.852,3.136,1.556,6.307,2.123,9.515c0.803,4.54,1.116,9.138,1.768,13.692
				c0.188,1.312,0.83,2.502,1.093,3.829c0.24,1.205,0.431,2.779,1.305,3.891c0.408,0.519,0.166,1.013,0.274,1.507
				c1.277,5.847,3.431,11.336,7.179,16.041c1.809,2.271,2.824,4.894,3.764,7.494c1.852,5.123,4.37,9.923,6.637,14.845
				c0.127,0.277,0.28,0.411,0.289,0.605c0.224,4.79,3.188,8.645,4.501,13.079c1.254,4.236,2.99,8.326,4.396,12.52
				c1.755,5.234,3.838,10.418,4.938,15.796c0.938,4.583,3.19,8.612,4.366,13.049c0.053,0.199,0.104,0.641,0.499,0.951
				c3.055,2.41,4.791,5.914,7.162,8.897c1.815,2.283,3.483,4.665,6.245,5.979c0.594,0.283,1.154,0.837,1.252,1.742
				c0.1,0.914,0.717,1.771,0.82,2.687c0.412,3.657,2.631,6.226,5.102,8.613c2.919,2.821,4.89,6.005,5.729,10.114
				c1.033,5.067,2.313,10.089,3.187,15.195"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M296.98,356.737c-0.76,0.066-1.445,0.596-1.441,1.19c0.028,4.666-2.299,8.712-3.389,13.078c-0.995,3.986-2.265,7.907-3.241,11.912
				c-0.888,3.641-1.274,7.29-1.163,11.021c0.085,2.867-0.474,5.446-1.945,8.105c-1.706,3.084-3.216,6.516-2.541,10.35
				c0.133,0.752-0.298,1.458-0.848,2.091c-4.208,4.854-4.355,10.57-2.848,16.318c0.429,1.633,0.327,2.849-0.174,4.191
				c-0.339,0.907-0.816,1.795-1.24,2.649c-1.076,2.167-0.309,3.917,0.694,5.686c0.704,1.241,1.462,2.484,2.392,3.556
				c1.042,1.2,0.53,2.209-0.034,3.296c-0.062,0.119-0.18,0.223-0.294,0.298c-4.034,2.64-5.659,7.224-8.512,10.809
				c-2.892,3.635-4.533,8.116-4.282,13.002c0.035,0.675,0.11,1.359-0.596,1.785"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M110.09,65.093c2.832,1.374,5.953,1.784,8.933,2.662c8.797,2.59,17.677,4.889,26.505,7.37c8.799,2.473,17.656,4.763,26.462,7.229"
				/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M307.099,285.612c-2.037,0.646-2.396,2.615-3.27,4.168c-2.392,4.249-3.914,8.861-5.725,13.363
				c-2.548,6.336-4.678,12.886-8.823,18.506c-2.386,3.235-3.492,7.134-4.439,10.998c-0.271,1.106-0.574,2.116-1.268,2.948
				c-2.551,3.065-4.381,6.465-5.025,10.431c-0.093,0.573-0.467,1.192-0.681,1.424c-2.188,2.374-4.423,4.725-7.089,6.589
				c-0.2,0.14-0.583,0.018-0.88,0.018"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M374.058,425.185c-2.651,2.443-5.781,1.906-8.931,1.508c-2.464-0.312-4.717-1.385-7.144-1.783c-0.37-0.061-0.79,0.176-1.132,0.262
				c-0.44,1.543,0.746,1.909,1.734,2.389c1.676,0.814,3.468,1.436,5.036,2.418c3.492,2.189,5.251,5.546,6.33,9.475
				c0.843,3.067,1.374,6.205,2.292,9.251c0.191,0.637,0.069,1.383,0.021,2.074c-0.269,3.796,0.424,7.539,0.585,11.31
				c0.09,2.071,1.807,3.556,1.86,5.652c0.014,0.54,0.562,1.157,0.521,1.786c-0.241,3.724,1.602,6.323,4.487,8.324
				c3.375,2.342,6.3,5.203,9.186,8.077c2.755,2.745,4.888,5.942,6.02,9.777c0.969,3.28,2.317,6.433,3.01,9.817
				c0.281,1.374,1.105,2.785,2.055,3.848c3.05,3.416,5.438,7.304,7.92,11.096c1.51,2.306,2.708,4.985,2.158,7.985"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M202.94,86.52c-1.176-1.633-3.236-1.571-4.792-1.96c-9.579-2.392-19.023-5.255-28.554-7.805
				c-8.136-2.176-16.244-4.458-24.398-6.564c-0.722-0.186-1.16-0.676-1.775-0.932"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M67.236,488.274c1.74,0.252,3.283-1.163,5.335-0.401c0.195,1.372,0.671,2.754,2.687,3.151c1.517,0.3,0.972,2.127,1.564,3.207
				c1.158,0.1,2.735-0.634,2.728,1.781c-0.002,0.818,1.606,1.015,2.227,0.263c1.516-1.838,3.971-1.273,5.76-2.555
				c1.381-0.988,2.796,0.297,2.912,2.292c0.067,1.149,1.467,2.296,2.336,1.719c0.596-0.395,1.276-0.737,1.869-1.051
				c1.29-0.685,3.077,0.561,3.235,2.01c0.528,4.858,2.73,6.18,7.523,4.434c1.454,1.048,2.88,2.068,1.424,4.21
				c-0.469,0.69,0.311,1.247,0.61,1.752c0.798,1.341,2.452,1.665,3.538,2.572c0.071,1.227-1.018,1.498-1.526,2.275
				c-1.395-0.578-2.852-1.433-4.237,0.372c0.882,1.149,0.667,3.146,3.079,3.242c0.842,0.034,1.129,1.604,1.588,2.51
				c-2.194,2.735-3.191,5.592-1.922,9.004c0.376,1.012,0.035,2.148-0.853,2.958c-0.52,0.474-1.432,0.607-1.476,1.49
				c-0.144,2.896-1.629,4.57-4.457,4.783c-0.154,2.414,1.638,2.481,2.613,1.97c2.365-1.24,3.604,0.155,4.766,1.614
				c0.764,0.959,1.403,1.734,2.722,1.451c1.487-0.319,1.59,1.188,2.381,1.788c-0.587,0.283-0.585,1.022-1.458,1.288
				c-1.592,0.483-3.39,1.105-3.378,3.474c0.003,0.592-0.876,1.231-1.421,1.776c-1.165,1.163-2.343,2.296-2.994,3.866
				c-0.114,0.275-0.282,0.581-0.284,0.582c-4.552,2.346-6.475,7.444-10.669,10.19c-0.977,0.64-2.354,0.404-2.953,1.185
				c-1.811,2.359-4.663,3.953-5.387,7.106c-0.101,0.44-0.495,0.645-0.675,0.834c-2.223,2.332-3.77,5.024-4.705,8.089
				c-0.478,1.563-1.657,2.664-3.001,3.214c-3.206,1.312-4.169,3.847-4.086,6.902c0.042,1.558-1.476,2.204-1.544,3.572"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M413.341,489.763c-1.476,2.737-0.2,5.419,0.352,8.023c1.293,6.11,3.87,11.805,6.209,17.564c1.073,2.645,2.34,5.18,4.113,7.476
				c1.169,1.515,1.023,3.497,0.914,5.325c-0.315,5.27-0.685,10.52,0.256,15.784c0.236,1.324,0.49,2.886-0.535,4.156"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M256.805,683.647c-1.121-1.781-1.783-3.831-1.991-5.813c-0.351-3.328-2.111-5.49-4.542-7.448c-2.653-2.138-5.806-3.11-8.945-4.14
				c-1.702-0.559-3.267-1.052-4.533-2.63c-1.742-2.17-4.696-3.209-5.916-5.993c-0.165-0.377-0.642-0.694-1.136-0.946
				c-2.677-1.365-4.897-3.378-7.163-5.318c-1.027-0.879-2.175-1.289-3.571-1.214c-2.389,0.129-4.83-0.347-7.139,0.624
				c-1.523,0.641-3.13,0.955-3.397,3.253c-0.151,1.298-0.166,3.188-2.204,3.879c-0.773-0.335-1.893-0.585-2.701-1.228
				c-1.184-0.941-2.263-1.981-3.899-1.729c-1.753,0.271-3.357,0.964-4.763,2.068c-5.516,4.33-9.165,10.053-11.857,16.388
				c-1.39,3.271-2.818,6.452-5.097,9.213c-1.485,1.798-1.593,4.167-2.44,6.221c-1.841,4.462-2.366,9.328-4.389,13.722
				c-0.188,0.409-0.025,0.98-0.025,1.477"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M282.101,385.009c-2.173,0.72-2.026,2.998-2.562,4.505c-2.482,6.984-4.494,14.13-6.067,21.386c-0.301,1.389-0.706,2.765-0.869,4.17
				c-0.286,2.45-1.697,4.338-3.04,6.218c-0.896,1.256-0.756,2.375-0.25,3.597c0.782,1.892,0.805,1.89-1.198,2.68
				c-2.085,0.823-3.64,2.189-4.784,4.154c-1.812,3.108-3.026,6.49-4.031,9.868c-0.787,2.645,0.018,5.481,1.375,8.002
				c1.856,3.449,2.31,7.16,2.094,11.011c-0.109,1.954-0.704,3.873-1.322,5.61c-1.18,3.315-0.076,6.133,1.092,8.939
				c0.576,1.385,1.134,3.009,2.601,3.901"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M400.842,446.909c0.719-0.546,0.594-1.333,0.595-2.083c0.001-1.885,0.013-3.771-0.003-5.654c-0.021-2.346-0.381-4.558-1.021-6.891
				c-1.172-4.273-2.563-8.481-3.787-12.737c-0.259-0.901-0.482-1.756-0.567-2.69c-0.164-1.818-0.479-3.772-1.807-5.015
				c-3.178-2.977-4.196-7.052-5.873-10.776c-1.665-3.699-3.572-7.249-5.708-10.686c-0.056-0.089-0.379-0.012-0.577-0.012"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M335.966,265.375c0.802,1.09,0.765,2.389,0.555,3.564c-0.528,2.956,0.49,5.793,0.514,8.638c0.054,6.385,0.964,12.833-1.83,19.104
				c-1.443,3.237-1.568,7.075-2.2,10.657c-0.503,2.859-1.534,5.679-1.17,8.623c0.271,2.195,0.262,4.46,1.155,6.552"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M192.227,628.74c-2.277,4.886-4.506,9.766-5.415,15.168c-0.381,2.268,0.505,4.1,1.342,5.918c0.361,0.785,1.543,1.125,1.435,2.428
				c-0.111,1.334-0.907,2.248-1.546,3.259c-0.702,1.108-1.887,1.857-2.985,2.331c-4.283,1.85-6.519,5.402-8.656,9.258
				c-1.937,3.494-3.131,7.414-5.891,10.452c-1.526,1.68-2.421,3.812-5.024,4.599c-1.519,0.459-2.444,2.502-3.288,4.04
				c-3.158,5.757-6.635,11.307-10.466,16.635c-0.266,0.369-0.253,0.688-0.276,1.057"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M475.241,478.752c-1.984,0.057-3.964-1.03-5.967-0.03c-0.08,0.039-0.382-0.366-0.631-0.616c0.144-1.119,1.257-1.524,1.84-2.34
				c-0.197-0.389-0.294-0.961-0.616-1.151c-1.949-1.149-3.982-2.158-5.914-3.336c-0.541-0.331-1.178-0.418-1.48-0.221
				c-1.439,0.938-3.001,0.394-4.493,0.537c-3.188,0.307-3.727,0.517-6.523,2.971c-0.112,1.459,0.027,2.736,1.132,3.923
				c1.438,1.547,2.745,3.258,3.862,5.051c0.859,1.379,1.816,2.68,2.216,4.417c0.605,2.628,2.611,4.614,4.423,6.522
				c1.436,1.511,1.979,3.029,2.118,5.1c0.221,3.283,1.108,6.324,4.949,7.529c1.369,0.431,2.31,1.561,2.893,3.429
				c0.955,3.061,2.646,6.023,6.036,7.556c2.808,1.27,5.026,3.324,6.018,6.478c0.354,1.124-0.026,2.24,0.282,3.278
				c1.127,3.788,2.432,7.525,3.484,11.333c0.219,0.793,0.472,1.545,0.98,2.04c2.985,2.904,5.215,6.355,7.442,9.829
				c0.711,1.108,1.693,2.07,2.666,2.977c1.274,1.188,2.557,1.23,4.149,0.312c1.666-0.96,3.371-1.57,5.312-1.994
				c1.425-0.311,2.067,0.056,2.369,0.828c1.227,3.144,3.994,3.144,6.604,3.549"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M423.459,549.877c-1.314,0.038-2.228-0.935-2.603-1.817c-0.857-2.02-2.607-3.112-4.286-3.715c-2.18-0.783-3.68-2.255-4.331-4.023
				c-3.279-8.896-10.693-12.441-18.843-15.129c-2.237-0.737-4.409-1.723-6.839-1.826c-1.485-0.062-2.59,0.251-3.606,1.484
				c-2.335,2.836-4.316,5.98-4.58,9.562c-0.199,2.698,0.768,5.6,2.272,8c2.164,3.451,5.031,5.743,9.475,6.101
				c2.761,0.222,5.571,0.621,8.343,1.065c3.16,0.507,6.22,0.983,8.683,3.521c2.436,2.508,5.634,4.159,8.622,5.929
				c3.967,2.35,7.818,4.857,11.5,7.597c0.939,0.699,1.456,0.079,2.145,0.216"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M321.979,350.488c-1.299-0.111-1.792,1.133-1.986,1.813c-0.413,1.449-1.126,2.626-2.207,3.511
				c-1.175,0.963-1.201,2.363-0.783,3.267c1.152,2.493,0.494,4.9-0.168,7.156c-0.843,2.87-1.318,5.719-1.076,8.653
				c0.276,3.348-0.485,6.56-0.898,9.826c-0.235,1.867-0.256,3.765-0.315,5.651c-0.122,3.867-0.215,7.736-0.3,11.605
				c-0.038,1.746,0.097,3.453,1.483,4.762"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M93.424,446.313c0.966,0.369,1.281,1.137,1.183,2.083c-0.187,1.788,0.255,3.54,0.077,5.364c-0.354,3.623,2.671,6.42,6.166,6.938
				c2.653,0.393,5.561,0.43,7.798,2.529c0.674,0.633,1.774,1.713,2.953,0.366c0.129-0.147,0.639-0.132,0.86-0.002
				c2.248,1.327,4.228,0.109,6.259-0.615c0.502-0.18,1.287-0.697,1.443-0.545c1.398,1.355,2.163,0.22,3.022-0.643
				c0.396-0.397,0.793-0.794,1.292-1.292c-0.837-1.297-0.427-2.996-1.28-4.37c-0.089-0.143-0.076-0.418-0.003-0.584
				c1.539-3.471,2.046-7.437,5.608-9.878c2.805-1.922,3.013-3.204,1.486-5.877c-0.88-1.542-1.443-3.314-2.927-4.498
				c-1.021-0.815-1.378-1.954-1.208-3.262c0.051-0.391,0.17-1.019-0.018-1.154c-1.703-1.228-0.725-2.769-0.564-4.201
				c0.341-3.032,3.232-4.41,5.64-2.666c0.85,0.615,1.643,1.33,2.381,2.079c1.98,2.011,2.529,2.027,4.473-0.008
				c0.194-0.203,0.396-0.397,0.595-0.596c0.562,0.661,1.519-0.059,2.218,0.748c0.033,2.008,1.329,3.844,1.482,6.088
				c0.089,1.302,1.169,2.846,3.146,2.697c1.128-0.085,2.233,1.214,3.233,0.527c1.343-0.92,3.045-1.304,4.14-2.39
				s1.856-2.643,2.357-4.131c0.234-0.695-0.127-2.161-0.575-2.572c-2.737-2.52-1.466-5.418-0.07-7.403
				c1.519-2.16-0.352-3.663,0.024-5.492c0.192-0.935-1.171-1.742-2.269-2.048c-1.409-0.391-1.568-1.356-1.885-2.665
				c-0.481-1.985-1.425-3.859-1.381-6.176c1.706-0.587,3.339-0.902,4.776,0.778c0.266,0.311,0.783,0.398,1.17,0.613
				c3.893,2.16,4.788,2.104,8.018-0.916c1.14-1.065,2.297-1.543,3.888-1.511c2.895,0.061,4.439-1.318,5.056-4.123
				c0.021-0.095-0.036-0.222,0.008-0.294c2.34-3.812,0.307-6.824-1.785-9.775c-0.157-1.183,1.089-1.164,1.471-1.84
				c0.49-0.869,0.365-1.748,0.036-2.682c-0.346-0.982-1.045-0.883-1.811-0.894c-1.966-0.027-4.149,0.555-5.356-1.776
				c-2.27,0.464-4.946-1.167-6.819,1.211c-0.669,0.85-1.688,1.07-2.422,1.76c-1.221-0.436-1.647-1.187-0.876-2.374
				c1.076-1.658,1.912-3.188,0.989-5.399c-0.628-1.505,1.282-2.307,2.325-2.855c1.608-0.846,2.998-1.58,2.934-3.65
				c2.479-2.694-0.007-4.999-0.634-7.43c-0.121-0.468-0.809-0.763-1.142-1.207c-2.178-2.908-1.912-5.134,0.89-7.425
				c0.551-0.451,1.196-0.785,1.776-1.203c0.704-0.508,1.319-1.311,0.834-2.038c-0.456-0.685-0.974-1.788-2.335-1.277
				c-0.489,0.183-1.32,0.036-1.74-0.29c-1.078-0.834-1.909-0.739-3.051-0.039c-0.52,0.318-1.374,0.556-2.371,0.17
				c-1.797-0.695-1.27-1.454-0.648-2.544c-2.155-1.662-2.917-1.778-4.965-0.518c-2.145,1.321-4.147,2.756-6.832,3.104
				c-1.022,0.133-2.592,0.735-3.27,1.958c-1.322,2.384-3.78,2.886-5.986,3.151c-2.62,0.315-5.36,0.428-8.025-0.172
				c-0.474-0.107-0.856-0.307-1.513-0.109c-1.235,0.37-2.458,0.104-3.291-1.367c-1.421-2.509-4.099-1.824-5.299,0.279
				c-0.831,1.457-2.282,2.798-1.098,4.659c-1.761,1.582-4.156,1.651-5.861,3.261c-0.705,0.665-2.631-0.201-3.484,1.29
				c-0.202,0.353-0.875,0.654-0.833,0.893c0.505,2.931-1.583,3.284-3.605,3.911c-1.702,0.528-2.311,2.029-1.47,3.521
				c0.917,1.627,1.552,3.358,2.855,4.858c1.467,1.688,1.85,4.207,4.224,5.35c0.737,0.354,0.667,1.66,0.63,2.604
				c-0.892,0.596-2.03,1.011-2.627,1.823c-1.313,1.784-2.634,0.364-3.913,0.206c-0.439-0.055-0.748-0.817-1.206-1.122
				c-1.732-1.152-3.545-1.433-5.657-0.959c-3.232,0.726-6.123,2.448-9.5,2.83c-1.849,0.21-3.475,1.306-5.412-0.037
				c-1.036-0.719-2.631,0.566-3.511,2.623c-0.491,1.15-0.793,2.38-1.21,3.562c-1.117,3.166-2.737,6.142-3.333,9.514
				c-0.181,1.02-0.668,2.081-1.388,3.017c-1.104,1.435-0.884,2.629,0.572,3.821c1.262,1.032,2.377,1.851,2.179,3.897
				c-0.139,1.434,1.995,1.204,3.163,2.748c0,0.305,0.414,1.659-0.08,2.231c-1.321,1.53-0.034,2.754,0.487,3.57
				c0.955,1.494,2.195,3.944,4.007,3.456c2.173-0.585,3.825,0.226,5.707,0.463c0.689,0.087,1.509,0.227,2.065-0.295
				c1.156-1.085,2.386-0.307,3.498-0.642c1.122,3.375-0.052,6.197-0.899,8.962c-0.299,0.977-0.502,1.455,0.032,2.444
				c1.071,1.982,1.781,4.159,2.713,6.436c-2.893,4.28-5.863,8.749-8.908,13.166c-4.732,6.865-9.467,13.729-14.301,20.522
				c-1.884,2.647-3.371,5.604-5.903,7.783c-0.541,0.466-0.936,1.197-0.929,2.055c0.027,3.472-0.012,6.944,0.038,10.415
				c0.007,0.499,0.37,0.992,0.569,1.488"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M280.018,150.503c-2.025,1.136-3.952,0.327-5.621-0.652c-2.025-1.188-3.726-1.744-5.72,0.02c-1.006,0.89-2.349,0.717-3.539,0.037
				c-1.389-0.794-2.847-1.489-4.149-2.405c-1.749-1.23-2.011-2.888-0.667-4.809c-2.55-1.107-4.695-0.498-6.692,0.791
				c-1.786-2.284-1.517-3.046,1.803-5.26c-0.755-1.527-2.077-2.506-3.388-3.495c-0.876-0.13-1.583,0.427-2.384,0.583
				c-2.17,0.421-4.287-1.368-3.895-3.563c0.13-0.733,0.422-1.392,0.591-2.086c0.52-2.132-1.38-3.987-3.539-3.564
				c-3.557,0.696-3.702,0.667-5.035-2.687c-0.634-1.594-1.86-2.392-3.305-2.949c-1.496-0.577-3.003-1.543-4.468-1.684
				c-2.714-0.259-4.946-2.168-7.727-1.91c-0.95,0.088-1.379-0.832-2.096-1.157c-1.271-0.577-2.546,0.319-2.661,1.757
				c-0.031,0.395,0.011,0.794-0.007,1.19c-0.1,2.21-0.8,3.112-2.976,3.225c-1.293,0.067-2.316,0.507-3.266,1.246
				c-2.23,1.735-2.088,3.041,0.605,4.122c1.578,0.633,2.716,1.505,3.591,2.992c1.4,2.382,0.358,4.963-2.42,5.349
				c-1.015,0.141-1.99,0.348-2.976,0.608c-2.12,0.561-3.207,1.734-2.595,3.561c0.412,1.23,0.653,2.185-0.052,3.317
				c-0.178,0.286-0.089,0.789-0.017,1.171c0.448,2.371,0.312,2.624-1.794,3.282c-0.508,0.159-0.997,0.383-1.489,0.591
				c-1.667,0.706-2.073,2.007-0.874,3.253c1.255,1.302,2.388,2.533,2.715,4.473c0.341,2.029,2.553,2.559,3.856,3.909
				c1.768-0.531,3.644-0.832,4.659,1.189c0.468,0.931,1.575,1.154,1.812,1.071c2.042-0.722,3.476,0.574,5.095,1.271
				c0.371,0.16,0.79,0.208,1.187,0.307l4.464,2.38c0.893,0.602,1.783,1.052,2.681,0.002c0.182-0.212,0.39-0.404,0.594-0.597
				c1.341-1.269,2.163-1.342,3.248,0.02c1.177,1.478,2.422,2.734,4.167,3.6c1.599,0.795,2.987,2.045,4.481,0.334
				c1.575,0.648,1.979,2.814,4.046,1.706c0.046-0.097,0.225-0.503,0.427-0.898c0.759-1.479,2.359-2.058,3.554-1.171
				c0.954,0.708,1.775,1.596,2.542,2.3c0.913,0.361,1.207-0.524,1.93-0.598c1.597-0.162,2.369-1.193,2.421-2.911
				c0.073-2.415,0.635-2.748,3.403-2.359c0.661,3.265-0.686,6.207-1.663,9.199c-0.428,1.31-1.406,2.124-2.658,2.701
				c-0.989,0.456-1.159,1.524-0.837,2.337c0.279,0.706,0.86,1.503,2.025,1.246c0.889-0.197,1.847-0.096,2.369,0.866
				c0.861,1.586,2.038,0.945,2.953,0.257c1.11-0.834,2.042-1.918,2.996-2.946c1.071-1.153,1.875-0.52,2.687,0.191
				c0,0.312,0.073,0.644-0.01,0.689c-2.894,1.576-2.045,4.15-1.773,6.566c0.159,1.41,1.248,1.827,2.384,2.061
				c0.834,0.172,1.712,0.839,2.432-0.543c0.736-1.416,2.179-2.06,3.799-2.467c1.54-0.388,2.762-1.383,3.627-2.895
				c1.06-1.851,2.135-4.083,5.044-2.454c0.293,0.164,1.015-0.272,1.451-0.561c1.186-0.785,2.401-1.133,3.871-1.261
				c2.102-0.183,4.022-2.928,4.174-5.272"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M215.439,467.741c1.019,0.933-0.231,1.586-0.342,2.374c-0.432,3.101-0.93,6.194-1.723,9.236c-0.861,3.307-2.254,6.201-5.06,8.352
				c-2.174,1.667-4.179,3.555-6.269,5.331c-2.899,2.465-3.966,5.723-2.881,9.198c1.066,3.415,0.229,6.494-0.768,9.526
				c-0.907,2.761-2.15,5.478-4.082,7.769c-0.641,0.76-1.119,1.608-0.898,2.674"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M33.607,502.262c-1.167,0.364-1.354,1.589-1.721,2.41c-3.118,6.976-6.908,13.612-10.189,20.502
				c-0.498,1.045-0.799,2.339-1.806,2.935c-1.439,0.85-1.045,2.342-1.065,3.32c-0.173,8.329-0.099,16.663-0.099,24.996
				c0,9.622-0.008,19.244,0.011,28.866c0.002,1.112-0.313,2.31,0.584,3.274"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M231.509,659.69c-2.946-0.104-5.643,1.597-8.673,1.147c-0.852-0.947-1.651-2.05-2.66-2.903c-1.088-0.92-2.468-1.4-3.833-0.606
				c-2.83,1.645-5.199,3.611-6.034,7.106c-0.505,2.111-2.025,3.723-4.076,4.813c-1.961,1.044-3.808,2.323-5.707,3.48
				c-2.609,1.589-4.83,3.456-6.53,5.996c-0.908,1.357-2.154,2.366-3.522,3.33c-4.634,3.268-7.587,7.733-8.65,13.35
				c-0.547,2.888-1.891,5.608-1.797,8.628"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M350.25,183.833c0.198,2.083,0.46,4.163,0.578,6.25c0.123,2.179-0.565,4.115-2.676,5.025c-2.991,1.29-2.857,3.795-2.621,6.278
				c0.15,1.586,0.657,3.153,0.528,4.764c-0.074,0.922,0.555,1.423,0.924,2.082c1.302,2.325,2.94,4.529,1.518,7.461
				c-0.47,0.967,0.43,1.961,0.644,2.939c1.019,4.661,0.446,9.346,0.444,14.006c-0.002,3.526,0.357,6.967,0.951,10.417
				c1.161,6.75,3.271,13.271,4.735,19.945c0.971,4.425,1.621,8.921,2.414,13.385"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M288.053,616.539c1.176,0.834,2.32,0.166,3.251-0.34c3.259-1.77,6.637-3.263,10.148-4.398c9.513-3.078,18.064-0.885,25.534,5.396
				c3.102,2.607,5.202,6.339,6.492,10.093c3.099,9.019,7.609,17.048,14.374,23.79c0.05,0.049,0.019,0.378,0.316,0.576"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M372.273,701.949c2.658,1.984,5.626,2.447,8.928,2.437c28.271-0.097,56.543-0.057,84.814-0.054c1.506,0,3.051,0.161,4.167-1.192"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M664.81,175.798c0.481,0.954,0.289,1.98,0.29,2.976c0.011,15.872,0.013,31.744-0.003,47.615c-0.001,1.112,0.312,2.31-0.585,3.274"
				/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M664.81,115.684c0.481,0.954,0.289,1.979,0.29,2.976c0.011,15.872,0.013,31.744-0.003,47.615c-0.001,1.112,0.312,2.31-0.585,3.273"
				/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M664.81,235.318c0.618,2.259,0.433,4.59,0.228,6.838c-0.255,2.788-0.207,5.561-0.215,8.339c-0.03,11.606-0.013,23.213-0.013,34.819
				c0,19.939,0,39.878,0,59.817c0,19.939,0,39.878,0,59.817c0,19.938,0,39.878,0,59.816c0,19.84,0,39.68,0,59.52
				c0,19.244,0,38.489,0,57.733c0,10.912,0,21.824,0,32.735"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M452.624,473.99c1.578,2.387,3.426,4.577,4.807,7.118c1.039,1.91,2.46,3.615,4.113,5.092c1.049,0.938,1.792,2.023,1.829,3.562
				c0.029,1.186,0.861,2.004,2.049,2.053c1.554,0.064,1.51,1.053,1.485,2.114c-0.027,1.229,0.254,2.391,0.506,3.591
				c0.281,1.335,0.743,2.208,1.856,2.985c2.167,1.516,4.612,2.501,6.903,3.771c0.705,0.392,1.537,0.668,2.021,1.279
				c1.778,2.253,4.332,2.12,6.546,1.649c2.197-0.469,4.174-0.354,6.282,0.08c1.047,0.215,2.175,0.037,3.267,0.037"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M271.387,360.903c-1.529-0.207-2.352,0.882-3.273,1.786c-3.553,3.491-6.844,7.274-10.746,10.375
				c-3.196,2.539-4.621,5.971-6.324,9.513c-4.976,10.341-10.521,20.407-17.188,29.786c-1.823,2.565-3.149,5.377-4.711,8.069
				c-3.306,5.695-5.595,11.995-10.134,16.953c-1.501,5.005-5.734,8.271-8.122,12.751c-1.481,2.779-2.923,5.586-4.352,8.392
				c-1.609,3.159-4.225,5.518-6.267,8.325c-1.814,2.493-3.956,4.777-5.067,7.731"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M408.58,233.234c0.427,0.737,0.888,1.558,0.561,2.367c-0.463,1.146-0.453,2.354,0.103,3.245c0.612,0.98,0.193,2.045-0.134,2.353
				c-1.639,1.542-0.706,2.924,0.094,4.219c1.316,2.132,1.423,2.117-0.644,4.163c-0.57,0.563-0.581,1.132-0.583,1.807
				c-0.003,0.945,0.787,1.7,0.568,2.862c-0.727,1.246-1.762,2.073-3.54,1.947c-0.96-0.068-2.124,0.238-2.349,1.446
				c-0.276,1.493-1.305,1.462-2.409,1.489c-1.561,0.037-2.716,0.382-3.69,2.006c-0.806,1.342-2.602,2.154-4.07,3.007
				c-0.759,0.44-1.818,0.779-2.644,0.028c-1.717-1.565-3.799-0.224-5.689-0.802c-1.751-0.535-3.59,0.266-5.333,0.814"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M388.938,587.672c2.604-1.27,4.943-2.761,6.607-5.317c1.188-1.822,3.325-2.689,5.291-3.621c1.558-0.738,3.183-1.414,4.373-2.952
				c-2.438-3.99-6.147-6.414-9.738-8.922c-4.775-3.337-10.013-4.538-15.766-3.021c-0.742,0.195-1.591,0.108-2.372,0.012
				c-3.003-0.37-5.323,0.612-6.88,3.561c2.204,1.879,3.864,4.386,5.082,7.173c1.298,2.971,3.732,4.984,5.99,7.107
				c2.254,2.119,4.733,3.998,7.114,5.981"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M107.114,320.728c-0.054,1.9,0.992,3.899-0.568,5.678c-0.196,0.225-0.047,0.771-0.023,1.167c0.201,3.41,0.168,3.389-1.181,5.662
				c-0.86,1.449-1.58,2.979-2.397,4.454c-1.337,2.412-3.178,3.079-5.65,2.086c-1.73,1.696-1.73,1.696-0.592,3.875
				c-0.801,0.577-1,2.217-2.75,0.981c-0.937-0.662-1.747,0.646-1.684,1.688c0.132,2.212,0.264,4.364-1.198,6.271
				c-0.191,0.25-0.181,0.873-0.001,1.155c1.335,2.094,0.188,3.712-0.907,5.379c-0.363,0.553-0.709,1.155-0.885,1.784
				c-0.739,2.636-0.714,2.643-3.292,2.673c-0.199,0.002-0.397,0-0.729,0c-1.706,3.201-3.033,6.704-5.518,9.525
				c-1.425,1.618-2.774,1.809-4.468,0.595c-0.291-0.209-0.412-0.672-1.165-0.558c-0.492,0.501-1.693,0.23-2.13,1.44
				c-0.575,1.592-1.705,1.613-3.268,1.278c-1.464-0.313-3.264-0.545-4.162,1.402c-0.274,0.595-0.887,0.54-1.472,0.627
				c-2.521,0.373-2.979,0.914-2.979,3.247c-0.001,10.813,0,21.626,0,32.438c0,5.853,0.07,11.707-0.042,17.558
				c-0.034,1.791,0.555,3.507,0.125,5.377c-0.391,1.695-0.083,3.552-0.083,5.337c0,1.786,0,3.571,0,5.356c0,1.786,0,3.571,0,5.357
				c0,1.687,0,3.372,0,5.059c0,1.786,0,3.571,0,5.357c0,1.785,0,3.571,0,5.356s0,3.571,0,5.356c0,1.786-0.029,3.572,0.011,5.356
				c0.025,1.113-0.313,2.311,0.584,3.274"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M623.444,259.721c-2.065-0.226-3.774,0.625-5.026,2.111c-1.667,1.981-3.58,2.564-6.043,1.631c-1.403,1.026-2.075,2.285-0.795,3.662
				c1.228,1.32,0.408,2.843,0.713,4.234c0.318,1.456-1.887,2.222-3.746,1.45c-0.384,0.5-0.771,1.003-1.271,1.654
				c0.397,0.686,0.778,1.502,1.303,2.211c0.896,1.213,1.78,2.319,0.635,3.918c-0.229,0.319-0.174,1.122,0.199,1.772
				c0.657,1.148,0.557,2.415-0.097,3.514c-1.329,0.263-2.123-0.627-2.785-1.215c-1.474-1.306-3.182-1.375-4.796-1.056
				c-1.408,0.279-2.445,0.113-3.602-0.652c-1.889-1.25-4.253-0.151-4.421,2.063c-0.126,1.647-0.062,3.369-1.566,4.388
				c-0.343,0.233-0.853,0.693-1.429,0.689c-1.422-3.07-1.927-2.814-5.674-2.57c-2.491,0.162-4.427,1.637-6.833,1.704
				c-1.31,0.036-2.028,2.167-1.174,3.213c0.393,0.481,1.011,0.771,1.463,1.212c1.206,1.179,0.586,1.995-0.322,2.639
				c-1.396,0.172-1.813-1.295-3.297-1.116c-0.974,0.694-2.454,1.296-2.328,3.232c0.151,2.332,1.011,4.354,3.005,5.612
				c1.107,0.699,2.032,1.537,2.994,2.466c-0.906,2.055-1.583,4.287-1.163,6.491c0.483,2.541,2.637,4.226,4.221,6.117
				c-0.467,1.789-1.99,2.249-3.056,3.085c-0.286,1.814,0.26,3.61-0.302,5.407c-0.297,0.948-0.027,1.883,1.133,2.401
				c0.865,0.387,1.456,0.346,2.105-0.324c1.418-1.462,3.439-0.487,5.133-1.374c2.104,1.85,3.605,4.215,5.839,5.875
				c1.555,1.156,1.289,3.27,0.801,4.675c-0.609,1.758-0.576,2.88,0.676,4.25c0.832,0.91,0.737,2.304,0.628,3.529
				c-0.184,2.036,0.572,3.752,2.415,4.401c2.964,1.044,4.747,3.381,6.801,5.441c0.689,0.691,1.089,1.612,1.232,2.655
				c0.302,2.205,1.3,3.079,3.549,2.954c2.681-0.148,4.266,1.803,6.251,3.001c-0.119,1.368,1.628,1.493,1.815,2.903
				c-1.388,1.307-2.04,3.005-1.891,5.137c0.067,0.969-0.677,1.995-1.08,3.07c1.457,1.095,2.545,2.442,3.344,4.319
				c1.274,2.996,3.34,5.61,6.144,7.484"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M357.095,521.308c-0.488-0.511-0.938-1.176-1.783-0.884c-2.072,0.716-3.5-0.732-3.722-2.115c-0.302-1.889-1.794-3.901-0.193-5.963
				c0.342-0.439,0.108-1.265-0.542-1.764c-1.517-1.162-4.031-1.113-5.676-0.006c-2.312,1.558-3.264,3.449-2.428,6.285
				c0.401,1.364-0.077,2.868-0.839,4.146c-1.881,3.157-1.716,4.276,1.542,5.853c0.809,0.391,1.497,1.002,2.272,1.406
				c2.044,1.063,3.739,0.98,5.406-0.423c2.259-1.9,4.286-4.045,6.26-6.238"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M358.583,277.279c1.19,2.976,2.401,5.944,3.548,8.937c0.166,0.433,0.127,1.01,0.007,1.476c-1.222,4.713,0.334,9.182,1.239,13.687
				c0.547,2.718,1.232,5.375,2.867,7.796c1.177,1.744,1.395,4.033,1.245,6.196c-0.138,1.996,0.267,3.75,1.492,5.371
				c1.473,1.946,2.396,3.996,1.254,6.555c-0.556,1.245,0.244,2.583,0.568,3.843c0.355,1.38,0.951,2.702,0.873,4.171"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M272.578,479.942c-1.916-6.145-1.56-12.329,4.167-16.07c1.441-0.94,2.665-2.126,3.552-3.583c1.063-1.747,2.699-2.942,4.24-4.082
				c2.146-1.586,4.821-1.774,7.36-0.89c0.599,0.209,1.487,0.464,1.796,0.418c3.266-0.486,5.042,1.465,6.894,3.644
				c1.815,2.138,4.047,3.909,5.625,6.275c2.378,3.57,1.946,7.423,1.166,11.308c-0.046,0.229-0.37,0.409-0.576,0.599
				c-2.871,2.622-2.849,2.63-4.178,6.246c-0.413,1.121-0.734,2.421-1.517,3.238c-3.798,3.966-4.528,8.891-4.717,14.026
				c-0.025,0.694-0.037,1.391,0.002,2.083c0.213,3.713-1.052,6.805-3.197,9.878c-2.857,4.093-4.436,8.8-4.237,13.93
				c0.016,0.4,0.188,0.794,0.287,1.191"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M387.45,485.299c-0.195,2.733,0.038,5.506,0.994,7.996c0.753,1.96,0.133,3.44-0.408,5.096c-0.136,0.417-0.415,0.785-0.581,1.194
				c-0.862,2.125-1.145,4.105,0.236,6.284c1.519,2.395,2.249,5.183,2.739,7.998c0.691,3.976,3.678,5.976,6.853,7.717
				c2.721,1.493,5.554,2.778,8.315,4.199c1.026,0.527,2.055,1.093,2.969,1.791c2.638,2.012,3.116,4.214,1.798,7.721"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M328.526,618.324c1.635-3.072,0.89-5.646-1.465-8.057c-2.059-2.107-3.768-4.546-5.975-6.524c-1.359-1.218-4.371-1.899-6.25-1.496
				c-4.23,0.908-8.471,1.73-12.793,2.108c-2.959,0.26-5.63,1.555-8.326,2.681c-1.165,0.486-1.868,1.516-2.393,2.656
				c-0.779,1.693-1.583,3.375-2.377,5.061"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M301.444,572.792c-0.396-0.447-0.804-0.456-1.188,0.002c-1.792,2.138-3.731,4.12-4.846,6.809c-0.556,1.341-1.252,2.74-2.25,3.942
				c-0.65,0.782-1.146,1.934-1.184,2.938c-0.14,3.775-2.177,6.748-3.956,9.8c-1.856,3.186-2.944,6.575-3.931,10.11
				c-1.08,3.873-1.17,7.728-1.043,11.632c0.036,1.103-0.94,2.184-0.051,3.275"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M205.916,587.374c-0.201-1.703-0.364-3.319,0.198-5.09c0.497-1.562-0.107-3.348-0.451-5.037c-0.534-2.626,0.681-5.917,2.61-8.342
				c0.454-0.57,0.763-1.347,0.882-2.07c0.182-1.108-1.403-5.038-2.351-6.25c-0.988,0.006-1.686,0.517-2.39,1.182
				c-3.838,3.629-6.811,7.813-8.634,12.805c-0.675,1.849-1.017,3.812-1.355,5.682c-0.718,3.981-2.566,7.35-4.604,10.678
				c-0.811,1.323-1.408,2.681-1.771,4.179c-0.702,2.888,0.393,4.327,3.282,4.493c1.912,0.109,3.433-0.453,4.772-1.804
				c3.753-3.785,7.843-7.231,11.323-11.297c0.483-0.563,0.795-1.184,0.83-1.808c0.073-1.334,0.868-2.384,1.163-3.586
				c0.205-0.834,0.849-1.83,0.333-2.642c-1.552-2.443-1.079-5.128-1.17-7.758c-0.018-0.498-0.187-0.992-0.286-1.488"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M382.986,98.126c1.179-1.677,0.645-3.609,0.453-5.341c-0.078-0.703-0.357-1.578-0.453-2.396c-0.221-1.891-2.19-2.985-3.86-2.067
				c-1.181,0.649-1.993,1.843-3.282,2.365c-1.378-0.823,0.218-2.768-1.464-3.619c-1.312-0.664-0.986-2.346-1.659-3.507
				c2.334-1.743,3.506,0.839,5.257,1.29c2.554-1.12,2.946-3.882,4.342-5.822c0.783-1.089-0.135-2.292-1.721-2.59
				c-0.52-0.098-0.871-0.265-1.271-0.751c0.896-1.685,0.756-3.187-0.409-5.003c-1.262-1.968-2.591-2.886-4.862-2.632
				c-1.064,0.119-2,0.086-2.74-1.134c-0.758-1.249-2.389-0.774-3.491-0.554c-1.827,0.363-2.979-0.937-4.457-1.352
				c-0.943-0.264-0.905-1.454-0.31-2.291c0.653-0.916,1.861-1.068,2.674-1.768c-0.106-0.621-0.025-1.193-0.256-1.829
				c-0.855-2.359,0.087-4.723,2.344-5.913c1.974-1.041,3.697-2.766,6.246-2.468c0.201,0.023,1-0.192,1.191-0.824
				c0.773-2.565,2.658-4.675,2.995-7.439c0.123-1.013,0.331-2.165,0.915-2.941c1.147-1.524,1.044-3.225,0.771-4.787
				c-0.364-2.083-0.429-4.192-0.9-6.346c-1.544-1.089-3.126-1.642-5.007-0.251c-1.09,0.806-1.803,0.333-2.688-0.827
				c-0.523-0.685-0.582-1.684-1.451-2.111c-2.213,0.776-2.213,0.776-1.731,4.587c-2.92,0.794-5.97,1.566-8.768,3.637
				c-0.633-2.646-0.757-5.011-2.555-6.777c-0.723-0.708-0.59-2.065-0.251-2.9c0.345-0.853,0.746-1.441-0.09-2.114
				c-0.875-0.702-1.633-0.187-2.375,0.296c-0.115,0.075-0.201,0.195-0.352,0.346c-2.207-1.72-4.513-1.808-7.119-0.713
				c-1.407,0.591-1.824,1.5-2.051,2.746c-0.223,1.227,0.427,1.904,1.476,2.397c0.641,0.301,1.282,0.693,1.776,1.19
				c1.031,1.037,0.715,3.455-0.595,3.785c-1.138,0.287-2.402,0.164-3.572-0.496c-1.015-0.571-1.631-1.039-1.862-2.397
				c-0.356-2.091-0.998-4.247-2.878-5.675c-1.634-1.242-4.235-0.489-5.062,1.492c-0.834,1.998-1.34,2.236-3.26,1.167
				c-1.261-0.703-2.397-0.778-3.584,0.024c-1.769,1.196-4.255,1.681-3.945,4.769c0.114,1.13-1.291,1.476-2.409,1.81
				c-1.026-0.877-2.098-1.794-3.169-2.71"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M327.633,564.162c-1.181,0.117-2.225,0.625-2.957,1.504c-1.941,2.333-3.782,4.751-5.676,7.124c-0.871,1.09-1.314,2.279-0.566,3.558
				c0.417,0.714,0.281,1.412,0.245,2.098c-0.063,1.219,0.252,2.324,1.238,2.944c1.659,1.043,2.521,2.665,3.562,4.19
				c1.03,1.51,2.189,1.681,3.227,0.281c1.744-2.354,2.743-5.085,2.986-8.012c0.192-2.301,0.178-4.554,0.785-6.878
				c0.681-2.604,0.355-5.479-2.546-7.107"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M88.365,218.652c1.56,1.624,3.257,2.583,5.676,1.856c0.751-0.226,1.923,0.369,2.015,1.422c0.097,1.111,1.085,1.352,1.58,2.163
				c0.949-0.528,1.5-1.636,2.634-1.869c1.674,0.425,1.341,1.352,0.59,2.333c0.281,2.358,3.238,1.182,4.057,3.126
				c-1.175,1.429-4.923,1.41-2.854,4.879c2.274-1.891,4.563,0.146,6.833-0.244c0.714-0.123,1.29,0.432,1.428,0.659
				c0.878,1.441,1.787,2.594,3.605,3.054c0.585,0.148,1.145,1.673,1.132,2.561c-0.014,0.949,0.709,1.532,0.724,2.519
				c-1.73,0.601-1.689,2.404-2.5,3.875c1.403,1.898,3.889,2.546,5.436,4.321c-0.919,0.885-1.708,1.908-2.968,2.401
				c-0.604,0.236-0.815,0.91-0.576,1.456c0.402,0.916,1.334,0.643,2.051,0.562c0.327-0.036,0.685-0.503,0.883-0.856
				c0.861-1.535,2.092-2.291,3.884-2.081c1.188,0.139,1.783-1.288,2.969-1.189c0.824,0.709,1.428,1.46,0.299,2.38
				c-0.769,0.625-1.586,1.191-2.417,1.809c0.236,0.582,0.576,1.017,1.23,1.177c2.676,0.653,4.031,2.712,4.669,5.08
				c0.324,1.2,0.807,2.077,1.568,2.961c1.37,1.591,1.263,1.914-0.577,3.271c-1.226,0.905-1.816,2.628-3.882,2.475
				c-0.664-0.049-0.845,1.189-0.291,1.981c0.728,1.039,2.03,1.114,2.977,1.785c0.297,0.198,0.331,0.389,0.308,0.596
				c-0.162,1.483,0.487,3.125-0.356,4.432c-0.957,1.481-2.847,0.077-3.625,0.545c-1.04,0.785-0.764,1.368-0.78,1.867
				c-0.087,2.722-1.147,3.543-3.861,2.623c-0.948-0.322-1.807-0.176-2.698-0.211c-0.655-0.025-0.995,0.561-1.288,1.285
				c0.732,1.355,2.021,2.461,2.449,4.354c-1.579,1.233-3.839,2.47-2.65,5.432c1.552,0.98,3.764-1.701,5.082,1.081
				c-0.081,0.935-1.213,1.97-0.469,3.317c0.397,0.626,1.227,0.609,1.918,0.91c1.821,0.792,2.1,1.708,1.826,4.127
				c-0.114,1.013-0.176,2.091,0.573,2.973c-1.387,1.538-2.713,3.093-2.698,5.357c0.004,0.599-0.443,1.057-0.866,1.495
				c-1.077,1.117-2.321,2.08-3.02,3.546c-0.181,0.379-0.526,0.927-1.154,0.909c-2.068-0.059-3.068,1.23-3.543,2.985
				c-0.285,1.05-1.323,0.629-1.813,1.183c-2.428-1.902-2.428-1.902-5.356-1.19"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M371.975,152.884c1.698-0.216,2.829-1.288,3.54-2.694c0.865-1.712,1.881-3.112,3.561-4.214c1.883-1.235,3.018-3.185,3.026-5.7
				c-0.126-0.611-0.735-0.981-1.217-1.356c-1.284-0.998-1.346-2.116-0.912-3.605c0.931-3.197-0.064-4.156-2.94-4.435
				c-0.473-0.046-0.586-0.426-0.89-0.603c-0.246-1.463,1.242-1.76,1.926-2.933c-1.349-2.733-3.363-4.725-6.39-5.159
				c-1.18,0.158-1.375,0.978-1.77,1.547c-0.571,0.824-1.61,0.848-2.055,0.52c-1.245-0.918-2.181-0.725-3.008,0.372
				c-0.938,1.244-1.927,2.55-0.571,4.129c-0.329,0.68-0.646,1.25-1.526,1.226c-3.294-0.092-4.514,0.904-5.372,4.153
				c-0.364,1.378-0.536,2.758-1.715,3.929c-1.482,1.473-1.319,2.888-0.051,4.703c0.706,1.011,1.442,2.026,0.57,3.262
				c-0.276,0.391-0.399,0.628,0.022,0.904c1.047,0.685,1.129,1.285,0.356,2.425c-0.921,1.361-0.285,1.927,1.414,2.386
				c2.263,0.611,4.672,0.159,6.87,1.117c0.319,0.139,0.785,0.115,1.193-0.252c1.944-1.746,3.8-0.701,5.639,0.28"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M613.028,561.483c1.436-3.928,1.461-5.369-0.638-8.001c-1.443-1.809-2.739-3.624-5.241-4.673c-2.936-1.23-4.806-4.269-6.59-6.986
				c-0.948-1.443-1.668-2.861-3.23-3.954c-1.754-1.226-2.307-3.727-3.954-5.239c-0.854-0.784-1.392-2.072-2.97-1.838
				c-0.604,0.09-0.9-0.582-1.206-1.14c-1.873-3.428-5.243-2.808-8.605-3.382c0.129,1.755,1.071,3.388-0.874,4.31
				c-0.625,0.297-0.435,1.104-0.037,1.748c0.603,0.974,1.489,1.71,2.271,2.556c-1.624,1.077-1.624,1.077-3.196-0.224
				c-0.859,0.386-1.26,1.386-2.058,1.778c-0.617,0.303-1.56-0.233-2.061,0.643c0.628,1.038,0.687,2.455,2.08,2.983
				c1.377,0.522,1.536,1.683,1.502,2.969c-0.044,1.69-0.011,3.384-0.011,5.37c-3.312-0.319-5.305,1.482-7.15,3.607
				c-0.124,0.743,1.356,1.537-0.108,2.399c-0.965-0.347-1.527-0.92-1.719-2.145c-0.297-1.913-1.553-2.784-3.188-2.014
				c-1.46,0.688-2.52,0.845-3.604-0.378c-0.532-0.601-1.221-0.322-1.786-0.262c-1.744,0.185-5.111,3.119-5.313,4.734
				c-0.108,0.869-0.055,1.743,0.458,2.721c0.98,1.868,1.552,3.999,1.274,6.201c-0.305,2.412,1.605,4.41,1.245,6.853
				c-0.052,0.345,0.465,0.995,0.857,1.149c2.353,0.919,2.144,2.917,2.047,4.794c-0.124,2.409,0.199,4.614,1.782,6.57
				c0.609,0.753,2.357,0.233,2.065,1.755c-0.185,0.961-0.951,1.437-1.957,1.953c-0.965,0.496-1.641,2.292-1.232,3.698
				c0.711,2.45,0.715,5.02,1.448,7.452"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M157.408,193.356c2.099,1.893,3.064-0.463,4.244-1.185c1.945-0.084,2.253,1.787,3.375,2.436c0.831-0.416,1.42-0.847,1.938-1.521
				c1.068-1.392,2.488-1.208,3.239,0.271c0.867,1.708,0.867,1.708,2.083,1.786c2.167-3.117,2.158-2.627,5.658-2.562
				c2.151,0.041,3.956,0.877,5.952,1.039c1.074,0.086,2.177-0.184,3.264-0.292c0.204,0.675,0.708,1.128,0.958,1.793
				c0.734,1.955,0.841,1.915,3.512,1.808c1.38-0.056,2.627,1.354,2.336,2.968c-0.225,1.25-0.284,2.617-1.427,3.598
				c-1.397,1.201-2.224,2.585-0.962,4.48c0.226,0.34,0.321,1.188-0.234,1.76c-1.715,1.765-0.53,3.418,0.308,5.039
				c0.205,0.396,0.74,0.623,0.417,1.658c-1.499,2.184-5.045,2.538-6.356,5.363c-1.124-0.562-1.917-0.958-2.723-1.362
				c0.077-1.913,0.246-3.878-2.465-4.675c-1.452,0.5-3.211,1.494-5.483,1.01c-0.457-2.049-1.893-3.394-3.917-4.416
				c-2.021-1.021-1.926-1.209-1.252-3.404c-1.369-2.464-3.106-3.69-5.943-2.25c-0.671,0.341-1.55,0.225-1.828-1.124
				c-0.432-2.098-1.247-2.229-3.218-1.231c-1.089,0.551-2.033,1.957-3.694,0.834c-0.751-2.521,0.95-5.606-1.345-7.665
				c-0.079-1.385,0.887-1.791,1.503-2.344c1.899-1.707,2.914-3.668,2.373-6.277c-0.099-0.475-0.014-0.989-0.014-1.407
				c-1.429-0.735-2.855-0.505-3.515,0.843c-1.084,2.216-3.567,0.684-4.799,2.072c-1.247,1.405-2.481,2.499-4.452,3.067
				c-2.443,0.705-5.222,0.665-7.267,2.778c-0.476,0.492-1.965,1.28-3.194,0.409c-0.487-0.345-1.305-0.633-1.744-0.283
				c-2.367,1.887-3.967,0.83-5.079-1.022c-3.63,0.5-4.395,2.74-3.779,5.727c-3.222,1.805-3.908,5.65-6.803,7.789
				c-1.52-0.553-2.964-1.636-4.902-1.589c-1.387,0.033-2.541-0.956-3.594-1.985c-2.312-2.259-2.235-1.72-5.674-0.783
				c-2.472,0.673-4.332,2.709-7.1,2.674c-0.705-0.009-1.796,1.235-1.266,2.87c0.625,1.927,0.124,2.687-2.028,3.485
				c-1.17,0.434-1.663,1.204-2.045,2.15c-1.246,3.082-3.431,3.558-5.67,1.184c-1.698-1.8-2.425-2.04-4.435-0.843
				c-0.879,0.524-1.705,0.944-2.37,0.211c-0.934-1.03-1.758-0.353-2.713-0.398c-2.854-0.135-5.074,1.706-7.742,1.946
				c-0.411,0.037-0.881,0.289-1.178,0.587c-1.678,1.683-3.753,1.938-5.966,1.771"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M313.349,278.767c-1.19,1.19-2.361,2.401-3.576,3.567c-2.711,2.604-3.24,6.231-4.018,9.561c-0.502,2.149-0.985,4.304-1.997,6.179
				c-1.439,2.667-2.574,5.375-2.874,8.373c-0.117,1.168-0.831,2.055-1.312,2.925c-1.925,3.476-3.486,7.1-4.94,10.774
				c-0.417,1.054-0.845,2.162-1.562,2.924c-2.401,2.552-2.898,5.804-3.451,8.981c-0.217,1.249-0.677,2.225-1.255,3.268
				c-2.408,4.344-5.063,8.457-9.537,11.003"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M425.84,558.21c-0.407-0.06-0.828,0.136-1.201-0.289c-3.158-3.598-7.296-5.895-11.318-8.308c-1.912-1.147-3.498-2.686-5.337-3.902
				c-2.717-1.798-5.513-3.08-8.915-3.409c-2.488-0.241-4.966-0.326-7.452-0.474c-2.113-0.125-3.079-1.095-3.269-3.26
				c-0.087-0.993,0.109-1.994-0.287-2.981c-0.669-1.663-1.559-1.956-2.993-0.889c-0.51,0.38-0.767,1.075-1.587,1.243
				c-2.636-4.529,0.299-7.59,2.812-11.017c2.954,2.07,6.38,2.861,9.541,4.307c5.444,2.49,9.744,6.314,12.569,11.225
				c2.307,4.01,5.333,6.099,9.404,7.33c0.968,0.292,1.882,0.904,2.974,0.604"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M426.733,172.525c-0.948,0.518-1.745,1.057-2.455,2.029c-1.638,2.24-2.375,2.084-4.39-0.243c-1.983,0.596-2.293,2.063-2.1,3.871
				c0.125,1.173,0.147,2.36-0.87,3.279c-0.867,0.784-1.949,1.102-2.968,0.829c-0.992-0.266-1.469-1.063-1.57-2.319
				c-0.216-2.67-1.261-3.478-3.503-3.276c-0.409,0.037-0.834-0.146-1.2,0.287c-2.389,2.818-5.4,5.201-5.997,9.225
				c-0.097,0.653-0.267,1.133,0.02,1.809c0.493,1.162,2.14,1.728,1.796,3.253c-0.257,1.137,0.713,1.931,0.621,2.981
				c-2.028,1.259-2.324,1.303-2.939-0.312c-0.836-2.192-2.515-3.298-4.502-4.152c-1.76,0.569-2.418,1.819-2.062,3.567
				c0.175,0.859-0.233,1.903-0.637,2.008c-2.764,0.715-3.318,4.04-5.933,4.779c0.066,2.862,0.066,2.862,2.68,2.443
				c1.882-0.302,2.943,0.373,3.273,2.083c0.466,2.406,0.865,2.636,2.957,1.453c0.436-0.247,0.816-0.3,1.207-0.245
				c1.346,0.19,2.377,1.204,3.869,1.245c1.59,0.043,1.672,1.846,2.383,2.903c1.643-0.033,3.288,0.299,4.524-1.444
				c0.689-0.972,1.669-0.214,2.347,0.582c-0.68,0.947-1.127,1.834,0.254,2.678c0.697,0.427,1.16,1.221,1.813,1.744
				c1.4,1.121,4.165,0.809,5.339-0.589c0.77-0.916,1.154-2.171,2.389-2.671"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M171.99,534.7c0.244,1.818-0.93,3.203-1.514,4.752c-2.197,5.823-4.161,11.753-7.681,16.993c-0.667,0.992-1.151,2.147-2.416,2.333
				c-2.883,0.424-4.188,2.745-5.26,4.836c-1.614,3.146-3.407,6.258-4.306,9.762c-0.299,1.166-1.177,2.178-1.729,3.29
				c-0.566,1.138-2.021,1.641-2.64,1.372c-1.958-0.847-3.844-0.665-5.691-0.148c-3.138,0.879-5.936,2.443-8.035,5.029
				c-0.573,0.707-0.906,1.675-2.094,1.479"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M243.413,242.163c-0.992,0.967-1.947,1.584-2.987,0.007c-0.831-1.259-2.118-1.519-3.56-1.519c-1.464,0-2.371-0.977-2.041-2.347
				c0.35-1.45,0.377-2.61-0.576-3.924c-0.683-0.94,0.092-2.324,1.435-2.911c1.874-0.817,2.844-2.109,2.673-4.188
				c-0.024-0.295-0.111-0.653,0.014-0.883c1.153-2.134,0.832-3.913-0.835-5.731c-0.505-0.551-0.53-1.805-0.132-2.928
				c1.234-3.483-0.954-5.354-3.809-5.939c-2.07-0.425-4.021-0.902-5.12,1.769c-0.39,0.947-1.501,1.156-2.631,0.647
				c-0.996-0.449-1.236-1.099-1.197-2.111c0.09-2.322,0.351-4.675-2.036-6.291c-0.924-0.625-0.88-2.003-0.563-2.913
				c0.586-1.684-0.08-2.296-1.544-2.714c-2.02-0.577-3.648-1.533-4.838-3.513c-1.438-2.392-1.129-4.114,1.568-4.773
				c1.253-0.306,1.445-1.051,1.498-2.114c0.049-0.993-0.189-1.986,0.255-2.988c0.666-1.5,4.279-3.887,5.959-3.486
				c0.796,0.19,1.572,0.351,2.071-0.105c1.968-1.798,4.642-1.626,6.874-2.646"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M473.752,314.776c0.663-0.977-0.304-1.763-0.321-2.678c-0.04-2.044-0.237-4.099,2.42-4.556c-0.106-1.512,0.379-2.907-0.778-3.959
				c0.648-1.113,1.489-1.894,2.532-1.553c1.912,0.625,3.11-0.158,4.145-1.578c-1.765-1.776-0.979-4.085-1.178-6.272
				c1.312-0.696,2.271-2.133,4.224-1.195c1.142,0.548,2.617-0.293,3.901,0.889c1.027,0.946,2.586,1.125,3.82-0.214
				c0.422-0.458,0.753-1.087,1.606-1.263c0.831,0.423,1.829,0.731,2.575,1.582c-0.543,1.206-0.402,2.393,0.444,3.838
				c1.31-0.001,3.007-0.404,4.262,0.62c1.382,1.128,1.597,3.074,2.704,4.434c1.12-0.399,1.501-1.752,2.64-1.866
				c0.231-2.757-0.93-5.568,1.407-7.538c-0.887-2.548-2.921-3.987-4.918-5.505c-0.841-0.64-1.496-0.743-2.411-0.283
				c-1.395,0.7-2.638,0.288-3.545-0.892c-0.717-0.933-1.573-1.169-2.696-1.209c-1.646-0.06-2.785-1.349-4.147-2.087
				c-0.709-0.384-1.161-1.215-1.816-1.735c-1.189-0.946-2.806-1.168-3.783-0.233c-1.159,1.109-2.301,1.18-3.789,1.082
				c-1.702-3.188-4.607-4.6-8.19-4.705c-1.363-0.04-1.492-1.654-2.921-1.843c-1.716,1.197-4.448,1.327-5.152,4.188
				c-0.115,0.466-0.688,0.931-1.162,1.179c-2.594,1.356-2.751,3.562-2.039,5.964c0.387,1.308,0.142,2.59,0.276,3.756
				c-0.093,0.6,0.406,0.546,0.604,0.69c3.281,2.398,3.289,2.387,1.766,6.277c-0.899,2.295-0.887,2.695,0.606,4.753
				c1.122,1.546,2.831,2.79,2.369,5.067c1.838,2.241,2.541,5.645,5.409,6.704c1.81,0.668,2.469,1.957,3.638,3.064
				c-0.598,1.908-2.115,3.601-0.781,5.749c0.244,0.393-0.387,1.328-0.524,1.744c-0.023,1.17,0.883,1.401,1.26,2.04
				c0.623,1.053,0.864,1.879-0.083,2.71c-1.642,1.442-1.185,2.952-0.682,4.799c0.482,1.771,0.659,3.427,2.455,4.475
				c1.094,0.638,2.257,0.276,3.276,0.616c1.443,0.48,2.326,0.002,3.313-0.929c1.101-1.037,2.36-1.559,3.551-0.022"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M345.489,63.307c-0.439,2.442,1.54,3.421,3.021,4.396c1.794,1.181,3.853,2.168,5.932,2.633c1.334,0.298,1.871,0.891,2.434,2.067
				c-1.603,2.778-4.521,4.826-5.158,8.094c-1.269,0.273-2.028-0.923-3.321-0.029c-1.17,0.809-2.157,1.176-1.428,2.782
				c0.409,0.901-0.395,1.282-0.656,1.938c-2.562-1.756-4.324-3.938-4.654-6.114c-0.237-1.565-1.63-1.177-2.465-2.114
				c-1.111,1.539-2.37,2.195-4.137,1.574c-0.582-0.204-1.37-0.2-2.083,0.219c-0.875,0.513-1.901,0.699-2.65-0.277
				c-0.759-0.987-1.783-0.518-2.69-0.565c-1.17-0.061-1.328,0.742-1.55,1.75c-0.248,1.125-1.404,1.721-2.318,2.647
				c0,1.632-0.065,3.52,0.028,5.4c0.045,0.919-0.16,1.766-0.939,2.029c-0.87,0.293-1.638-0.198-2.092-1.122
				c-0.195-0.397-0.522-0.666-0.601-1.199c-0.3-2.019-1.6-2.713-3.683-2.31c-0.526-1.491-1.719-2.63-1.142-4.569
				c0.314-1.054-1.601-2.442-2.857-1.992c-2.633,0.943-3.841-0.363-4.63-2.439c-1.162-0.037-2.001,0.073-2.562,1.171
				c-1.054,2.063-1.369,2.244-3.216,1.436c-0.695-0.304-1.769,0.291-2.109-0.814c-1.69,1-2.336,2.391-2.491,4.445
				c-0.159,2.104-0.936,4.038,0.644,6.007c0.619,0.771-0.284,1.922-1.431,2.018c-0.926,0.077-1.896,0.172-2.68-0.574
				c-1.766-1.684-3.946-1.913-6.248-1.804c-1.192,0.057-2.38,0.034-3.576-0.262c-3.524-0.871-4.327-0.321-5.054,3.253
				c-0.291,1.429-1.789,2.448-2.944,1.734c-1.256-0.776-2.22-1.929-3.615-0.255c-1.436,1.724-1.572,3.49-0.282,5.662
				c0.07,0.117,0.195,0.201,0.294,0.299c-0.646,1.822-2.294,2.199-3.869,2.679c-2.381,0.725-2.762,1.826-1.184,3.565
				c1.021,1.126,2.372,2.053,2.077,3.875"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M198.476,314.181c1.794,1.934,1.272,4.665,2.328,6.87c0.206,0.431-0.781,1.02-0.492,1.892c1.048,0.917,2.886,0.498,3.836,1.94
				c1.519,2.304,3.678,4.129,5.024,6.568c0.126,0.228-0.109,0.749,0.035,0.859c2.747,2.122,2.516,5.606,3.901,8.332
				c0.778,1.531,0.631,2.768-0.031,4.201c-0.784,1.695-1.182,3.362,0.238,5.083c0.841,1.019,0.383,2.676-0.866,2.839
				c-1.545,0.202-1.666,1.387-2.619,2.191c1.574,1.762,1.885,3.564,0.904,5.676c-0.435,0.935-0.617,2.287-0.279,3.22
				c0.7,1.936-0.483,2.855-1.571,3.887c-1.666,1.579-3.965,2.642-4.182,5.364c-0.017,0.213-0.342,0.449-0.576,0.589
				c-3.425,2.049-6.229,4.924-9.519,7.15"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M256.805,499.881c-0.719-0.546-0.666-1.341-0.582-2.081c0.318-2.802-0.833-5.246-1.786-7.739c-2.882,0-2.882,0-4.774,2.083
				c1.076,1.081,1.094,2.441,1.226,3.865c0.605,6.517-2.05,11.746-5.942,16.704c-3.518,4.483-5.943,9.529-6.023,15.439
				c-0.014,0.995-0.455,1.977-0.552,2.979c-0.232,2.376,0.14,4.672,1.173,6.843"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M560.949,405.245c-0.763-0.785-1.771-0.723-2.675-0.571c-8.224,1.369-16.479,2.505-24.429,5.27
				c-5.023,1.747-9.582,3.93-13.435,7.735c-2.257,2.23-4.815,4.125-6.514,6.892c-0.267,0.436-0.206,0.803-0.266,1.209"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M460.361,641.537c1.562-1.687,0.261-3.553-0.141-5.021c-0.764-2.789-0.317-5.609-0.893-8.403c-0.49-2.384-1.006-4.769-1.675-7.102
				c-0.758-2.644-1.181-5.306-1.562-8.029c-0.523-3.734-0.935-7.793-4.672-10.109c-1.161-0.72-1.893-1.923-3.237-2.458
				c-0.812-0.324-1.41-0.189-2.104,0.054"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M392.807,312.693c-0.675,0.04-1.338,0.096-1.791-0.592c-0.624-0.945-1.654-1.772-1.998-2.711c-1.611-4.4-4.428-8.259-5.7-12.777
				c-0.623-2.21-1.069-4.494-0.93-6.835c0.14-2.351-0.419-4.454-2.392-5.935c-0.582-0.437-1.297-0.806-1.449-1.511
				c-0.734-3.377-3.55-5.456-5.092-8.323c-0.757-1.406-1.986-2.375-3.267-3.277"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M305.611,572.792c0.446,0.854,0.396,1.797,0.274,2.676c-0.436,3.149,1.086,5.944,1.607,8.914c0.182,1.035,1.035,2.156,1.97,3.012
				c1.041,0.95,1.567,2.276,2.09,3.557c1.07,2.621,0.333,3.979-2.027,5.712c-1.882,1.382-4.04,1.022-6.012,1.681
				c-2.599,0.867-5.375,1.052-8.048,1.72c-1.802,0.449-2.854,2.166-4.734,2.191"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M367.213,393.044c0.718,3.289,2.219,6.215,4.168,8.927c1.54,2.142,1.678,4.796,2.641,7.158c3.002,7.374,5.992,14.718,11.312,20.846
				c1.27,1.461,2.33,3.12,3.091,4.997c0.442,1.094,0.839,2.55,2.298,3.01"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M221.093,633.204c-1.854,1.066-3.58,2.32-5.955,2.128c-1.984-0.161-3.827-0.764-5.299-1.901c-1.269-0.979-2.504-1.062-3.922-1.14
				c-2.978-0.16-5.96,0.393-8.945-0.522c-2.942-0.901-5.766-4.309-5.693-7.196c0.019-0.766,0.361-1.304,0.695-1.75
				c0.574-0.766,1.053-1.563,1.43-2.42c0.448-1.02,0.001-2.404,1.492-2.995c1.85-0.732,3.451-2.15,5.667-1.783
				c0.65,0.107,1.186,0.225,1.811,0.564c2.961,1.611,6.196,2.729,8.889,4.827c1.511,1.177,3.107,1.822,5.069,1.812
				c1.873-0.011,3.607-0.088,4.759-1.826c0.781-1.177,2.23-1.09,3.305-1.735c0.762-0.457,1.732,0.085,1.996,0.864
				c0.251,0.746,0.313,1.881-0.554,2.328c-2.403,1.241-3.185,3.095-2.697,5.694c0.138,0.735-0.691,1.275-0.95,2.045
				c-1.249,3.703-0.223,7.668-1.567,11.385c-0.382,1.058,0.92,2.479,2.243,3.252c1.681,0.981,3.494,1.622,5.373,1.718
				c4.099,0.212,7.58,1.407,9.818,5.104"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M276.744,203.475c-1.384-0.185-2.627-0.059-3.595,1.171c-0.409,0.519-0.396,1.355-0.24,1.455c2.233,1.442,1.28,3.507,1.16,5.409
				c-0.019,0.296-0.002,0.595-0.002,0.893c-0.794,0.223-1.155,1.203-2.083,1.19c0.276,0.753-0.88,0.709-0.725,1.804
				c0.416,2.947,0.166,2.982-3.085,3.92c0.959,1.852-1.126,4.193,1.19,5.918c0.843-0.197,1.734-0.405,2.629-0.615
				c1.317,2.46,1.327,2.482-0.907,3.542c-0.743,0.353-1.488,0.381-1.892,1.459c-0.263,0.704-1.379,1.212-2.56,1.31
				c-2.048,0.17-1.972,0.838-1.877,2.902c0.13,2.836,0.979,5.987-2.259,8.088c-1.457,0.945-1.211,3.237-2.675,4.455
				c-0.584,0.485,0.267,1.507,0.589,1.671c1.723,0.874,2.072,2.903,3.54,3.932c0.735,0.515,1.193,1.327,1.132,2.083
				c-0.105,1.286,0.604,1.814,1.553,2.051c0.878,0.22,1.932,0.136,2.343-0.867c0.523-1.276,1.314-1.594,2.678-1.9
				c1.752-0.393,3.126,0.31,4.45,0.828c1.217,0.476,2.017,1.75,1.889,3.469c-0.141,1.9,0.686,2.596,2.61,2.327
				c1.413-0.197,2.583-0.449,3.621,0.91c0.484,0.634,1.478,0.66,2.338,0.637c0.802-0.021,1.854-0.306,2.34,0.32
				c1.795,2.31,4.419,1.113,6.605,1.688c1.326,0.349,2.66-0.123,3.361,1.813c0.526,1.454,2.789,1.3,4.061,0.038
				c2.369-2.35,2.369-2.35,5.059-0.595"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M326.145,227.58c0.243,0.694,0.396,1.297,0.06,2.108c-0.364,0.882-0.299,1.957-0.351,2.951c-0.298,5.672-0.617,11.342-1.498,16.962
				c-0.078,0.499-0.02,1.2-0.322,1.455c-2.185,1.842-2.331,4.463-2.268,6.875c0.094,3.628-1.062,6.406-3.598,8.988
				c-2.086,2.125-3.227,4.907-3.679,7.97c-0.312,2.121-0.951,4.131-0.003,6.28c0.33,0.748,0.233,1.808,0.021,2.647
				c-1.013,4.028-1.72,8.108-2.412,12.199c-0.155,0.918-0.681,1.865-1.372,2.732c-2,2.507-3.964,5.091-4.815,8.29
				c-0.522,1.962-0.302,3.968-0.277,5.952c0.03,2.381-0.31,4.782,0.277,7.143"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M382.093,317.752c-1.29-0.992-2.527-2.064-3.888-2.946c-0.845-0.548-1.886-0.689-2.651,0.275c-0.6,0.755-0.371,1.418,0.28,2.085
				c2.148,2.202,1.998,5.021,1.682,7.715c-0.08,0.684,0.071,1.25,0.163,1.791c0.278,1.627,1.728,2.771,2.217,4.214
				c0.477,1.409,0.197,3.033,1.889,3.853c0.275,0.134,0.668,0.386,0.608,0.87c-0.443,3.625,2.391,5.608,4.215,7.996
				c2.675,3.503,2.819,3.392,0.247,6.883c0.611,0.477,0.968,1.259,1.978,1.554c2.378-1.471,4.815-3.124,7.545-4.232"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M300.254,474.883c-2.989-3.108-6.452-3.183-10.108-1.465c-1.405,0.66-2.737,0.829-4.178,0.278c-1.132-0.434-2.174-0.35-3.295,0.25
				c-0.774,0.414-1.721,0.544-2.646,0.698c-3.439,0.573-5.094,3.181-5.88,6.211c-0.411,1.58-0.651,3.245-1.312,4.723
				c-1.115,2.491-0.825,5.25-1.74,7.758c-0.583,1.596-1.241,2.985-2.616,4.238c-2.674,2.436-5.755,4.312-8.478,6.683
				c-2.173,1.893-3.272,4.251-4.3,6.672c-0.502,1.185-1.171,2.309-1.569,3.536c-0.62,1.909-1.974,2.578-3.875,2.379"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M225.558,139.194c5.083,1.861,9.41,5.177,14.285,7.439c0.684,1.951,2.665,2.749,3.803,4.217c2.793,3.605,6.525,5.253,10.775,6.213
				c4.537,1.024,9.007,2.302,13.396,3.853c3.771,1.333,7.687,1.249,11.606,1.209c1.479-0.015,1.828-0.999,2.07-2.103
				c0.207-0.944-0.187-1.627-1.18-1.771c-1.083-0.157-1.77-1.061-3.336-0.487c-2.171,0.794-4.743-0.218-7.068-0.757
				c-4.633-1.074-9.311-1.871-13.998-2.626c-3.856-0.622-7.537-1.448-10.467-4.422c-1.24-1.258-2.494-2.953-4.708-2.73"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M623.444,297.813c-3.253,0.44-4.074,2.082-2.954,6.029c-2.463,1.782-2.926-0.972-4.221-1.885c-1.46,0.399-2.925,0.98-4.438,1.159
				c-1.269,0.15-1.524,1.18-2.004,1.889c-0.317,0.471-0.354,1.533,0.512,2.027c-0.696,1.544-2.221,1.999-3.563,2.681
				c-2.292,1.164-2.6,1.769-1.766,4.165c0.47,1.351,0.175,2.421-0.579,3.599c-0.957,1.497-0.673,3.608,0.892,4.023
				c2.635,0.699,1.209,1.912,0.86,3.099c-1.05,0.091-1.843-0.834-2.983-0.64c-1.057,0.18-1.932,0.135-2.479-1.408
				c-0.689-1.943-1.179-1.864-3.502-0.916c0.38,2.332-1.129,5.163,2.026,6.719c-1.296,1.297-2.387,1.475-3.457,0.138
				c-1.604,1.173-1.722,3.196-2.699,4.735"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M19.323,473.692c1.167,0.364,1.316,1.585,1.73,2.408c8.406,16.729,16.727,33.499,25.018,50.284
				c0.226,0.458,0.505,1.236,0.691,1.401c2.416,2.155,2.329,5.019,2.292,7.807c-0.058,4.364-0.253,8.762-0.116,13.089
				c0.183,5.753-0.085,11.511,0.369,17.271c0.584,7.41,0.166,14.829-2.059,22.001c-0.463,1.493-0.463,2.705,0,4.197
				c2.224,7.172,2.42,14.573,2.132,22.007"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M48.785,413.876c0.481,0.953,0.308,1.98,0.286,2.976c-0.101,4.662-0.265,9.349-0.159,13.984c0.182,7.939-0.087,15.879,0.207,23.81
				c0.163,4.373-0.346,8.65-1.591,12.777c-0.989,3.274-0.951,6.282-0.01,9.565c1.184,4.132,1.725,8.405,1.61,12.775
				c-0.174,6.643-0.039,13.292-0.053,19.938c-0.002,0.996,0.191,2.021-0.29,2.976"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M457.087,79.377c-0.622-0.755-1.432-0.909-2.381-0.908c-12.003,0.023-24.006,0.02-36.009,0.003
				c-0.692-0.001-1.273,0.161-1.786,0.607"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M48.785,88.007c0.175,3.371,0,6.741,0.194,10.125c0.201,3.502-0.723,7.03-1.753,10.388c-0.782,2.548-1.011,5.034-0.179,7.445
				c1.68,4.866,1.834,9.842,1.759,14.897c-0.091,6.05-0.021,12.102-0.021,18.153c0,5.952,0,11.904,0,17.856c0,7.539,0,15.078,0,22.617
				c0,8.035-0.105,16.072,0.035,24.105c0.104,5.971-0.131,11.858-2.115,17.56c-0.412,1.183-0.394,2.379-0.003,3.57
				c1.875,5.713,2.271,11.584,2.116,17.559c-0.151,5.849-0.033,11.705-0.033,17.557c0,5.555,0,11.11,0,16.666
				c0,7.539,0,15.078,0,22.617c0,6.646-0.056,13.293,0.021,19.939c0.044,3.771,0.403,7.515,0.056,11.316
				c-0.327,3.585-1.328,6.966-2.176,10.405c-0.299,1.213-0.382,2.387,0.016,3.574c2.205,6.58,2.608,13.358,2.409,20.238
				c-0.149,5.154-0.021,10.316-0.038,15.475c-0.003,0.996,0.192,2.021-0.29,2.976"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M635.05,88.007c-0.175,3.271-0.021,6.544-0.194,9.827c-0.191,3.603,0.675,7.235,1.75,10.687c0.794,2.546,1.015,5.034,0.182,7.444
				c-1.68,4.866-1.834,9.842-1.759,14.897c0.091,6.05,0.021,12.102,0.021,18.153c0,5.952,0,11.904,0,17.856c0,7.539,0,15.078,0,22.617
				c0,8.729,0.115,17.461-0.047,26.188c-0.086,4.66,0.217,9.25,1.598,13.671c0.795,2.546,1.02,5.032,0.187,7.442
				c-1.68,4.866-1.834,9.842-1.759,14.897c0.091,6.05,0.021,12.102,0.021,18.153c0,5.555,0,11.11,0,16.666c0,7.539,0,15.078,0,22.617
				c0,8.729,0.115,17.461-0.047,26.188c-0.086,4.66,0.217,9.25,1.598,13.671c0.795,2.546,1.02,5.032,0.187,7.442
				c-1.68,4.866-1.834,9.843-1.759,14.896c0.091,6.05,0.021,12.103,0.021,18.153c0,5.754-0.154,11.513,0.036,17.26
				c0.28,8.433-0.012,16.866,0.103,25.299c0.103,7.621,0.117,15.278-0.171,22.912c-0.176,4.661,0.202,9.249,1.583,13.671
				c0.795,2.546,1.02,5.031,0.187,7.442c-1.68,4.866-1.834,9.843-1.759,14.896c0.091,6.05,0.021,12.103,0.021,18.153
				c0,5.556,0,11.11,0,16.666c0,6.745,0,13.49,0,20.236c0,8.233,0.104,16.469-0.035,24.7c-0.102,5.971,0.132,11.858,2.115,17.56
				c0.412,1.183,0.317,2.36,0.017,3.573c-0.946,3.821-2.263,7.562-2.123,11.604c0.124,3.567,0.026,7.142,0.026,10.713"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M338.347,215.379c-1.708,0.686-1.033,2.488-1.337,3.612c-1.154,4.266-2.338,8.54-2.681,13.042
				c-0.186,2.442-0.702,5.013-1.472,7.502c-0.756,2.444-0.656,5.426-0.035,7.952c0.706,2.874,0.458,5.607,0.104,8.356
				c-0.297,2.314-0.45,4.383,1.219,6.289c0.523,0.596,0.63,1.556,0.928,2.35"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M542.2,509.999c-1.373-0.368-2.623-1.131-4.17-0.923c-1.682,0.226-2.184-1.354-2.942-2.372c-0.726-0.974-0.94-2.365-2.388-2.672
				c-3.111,2.165-2.863,2.122-2.191,5.982c0.458,2.636,1.434,5.118,1.823,7.729c0.288,1.933,1.997,3.156,1.973,5.351
				c-0.021,1.829-0.104,3.791,0.426,5.662c0.356,1.261-0.464,2.283-1.16,3.265"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M507.083,437.088c-3.601,4.974-5.447,10.392-5.821,16.656c-0.309,5.159-0.672,10.312-0.729,15.484
				c-0.08,7.283,1.523,14.204,4.486,20.823c0.401,0.898,0.664,1.977,1.93,2.542c1.318-1.432,1.023-3.404,1.202-4.929
				c0.731-6.262,2.113-12.354,3.657-18.446c1.17-4.616,2.087-9.315,2.888-13.999c0.549-3.204,1.655-5.608,4.274-7.443
				c0.905-0.635,1.604-1.562,2.398-2.355"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M385.665,406.436c-2.109-1.083-4.362-1.996-5.642-4.174c-0.559-0.951-1.774-0.705-2.202-1.643c-1.142,0.968-2.04,3.198-1.33,3.984
				c2.721,3.009,2.699,6.939,3.771,10.477c1.215,4.01,3.559,6.914,7.173,8.941c0.966,0.542,1.907,1.255,2.412,2.389
				c-0.544,1.688-0.254,3.313,0.226,5.039c0.611,2.201,1.225,4.355,0.809,6.855c-0.331,1.994,1.046,4.145,2.287,5.88
				c0.648,0.906,2.082,0.647,3.21,0.642"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M118.423,209.129c2.657,1.017,3.845,3.347,4.95,5.826c1.508-0.214,2.63-0.534,2.803-2.253c0.265-2.651,2.871-2.711,4.515-3.419
				c1.236-0.533,3.291-0.837,4.487,1.284c0.621,1.1,2.091,1.533,3.544,0.43c0.338-0.257,0.962-0.978,2.179-0.496
				c0.429,0.317,1.25,0.945,0.112,1.867c-0.667,0.541-0.24,1.025-0.255,1.523c-0.033,1.074,0.082,2.033-1.172,2.737
				c-1.077,0.605-0.872,1.726-0.084,2.635c1.413-0.454,2.25-1.96,3.92-1.814c3.308,0.288,6.346,1.62,9.5,2.473
				c1.103,0.299,1.769,1.653,2.171,2.802c-0.675,1.865-3.263,1.658-3.711,3.634c0.239,0.429,0.682,0.756,0.95,0.597
				c2.121-1.259,3.426-0.295,4.489,1.512c0.138,0.234,0.389,0.401,0.666,0.678c-0.621,1.093-0.463,2.461-1.514,3.544
				c-0.883,0.91-0.629,2.342,0.567,2.872c1.935,0.856,1.849,3.078,3.253,4.216c0.532,0.431,0.445,1.28,0.255,1.448
				c-1.94,1.719-1.699,4.229-1.897,6.308c-0.236,2.468-1.101,4.489-2.347,6.648c0.92,1.373,1.495,2.861-0.246,4.396
				c2.518,1.639,2.099,4.272,2.697,6.512c0.266,0.994-0.037,1.89-1.088,2.737c-1.701,1.372-3.875,1.94-5.183,3.42
				c-2.994-0.416-5.748-1.174-9.121-0.606c1.535,1.878,2.225,3.226,1.261,5.203c-0.586,1.202-0.331,2.966-0.057,4.401
				c0.436,2.279,0.144,4.881,2.562,6.417c-0.741,1.366-1.089,2.644-2.913,2.564c-1.188-0.052-1.687,0.871-1.799,2.039
				c-0.158,1.649-1.068,2.882-2.358,3.879c-0.44,0.34-0.852,0.746-1.188,1.189c-0.857,1.134-1.407,2.859-2.987,2.892
				c-1.969,0.041-3.953,0.81-5.972-0.176c-1.549-0.756-3.594-0.425-4.442-2.279c-1.01,0.506-1.766,0.81-2.083,1.639"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M561.841,405.245c-2.507,2.06-5.578,3.133-8.351,4.73c-2.924,1.684-5.879,3.082-8.125,5.897c-1.795,2.25-4.849,3.266-7.323,4.861
				c-1.732,1.118-3.416,2.321-5.059,3.569c-1.768,1.343-2.809,3.172-3.295,5.342c-0.227,1.008-0.583,1.987-0.881,2.979"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M359.178,215.676c-0.773,2.759-0.079,5.547,1.307,7.662c2.228,3.401,1.431,6.959,1.669,10.492
				c-1.267,1.454-0.487,3.184-0.525,4.763c-0.028,1.16,0.268,2.599,1.435,3.242c3.832,2.111,6.217,5.417,8.024,9.292
				c-0.544,1.324-1.506,1.414-2.684,0.856"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M223.772,628.74c1.02,1.455,2.711,1.619,4.171,1.719c3.159,0.217,6.345,0.101,9.518,0.041c0.7-0.014,1.393-0.361,2.098-0.557
				c0.569,0.502,0.703,0.964,0.328,1.793c-0.755,1.667-0.944,3.474-0.979,5.336c-0.051,2.68,0.52,5.403-0.512,8.052
				c-0.269,0.689-0.332,1.739,0.012,2.335c1.229,2.126,0.336,3.874-0.643,5.687c-1.277,2.364-2.384,4.8-2.981,7.438"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M185.382,221.926c0.228,1.816,0.509,3.346,2.629,4.279c1.676,0.738,1.94,3.255,3.645,4.274c-0.485,1.561-0.662,3.234-3.014,2.839
				c-0.509-0.085-1.195,0.885-1.792,1.362c0.782,1.684,0.189,3.278-0.319,4.79c-0.475,1.413-1.276,2.609-0.322,4.219
				c0.479,0.808-0.66,1.968-1.721,2.04c-0.395,0.026-0.793,0.002-1.19,0.004c-5.336,0.027-5.36-0.003-9.211,2.994
				c-1.175,0.915-1.998,2.308-3.907,1.528c-0.638-0.261-1.262,0.592-1.762,1.132c-0.592,0.639-1.279,1.145-2.28,1.573
				c-2.198-1.962-5.151-2.682-7.838-3.953"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M533.272,535.593c0.009-1.835-0.494-3.474-1.372-5.121c-0.262-0.492-1.699-1.724-0.115-2.616c-0.539-0.723-0.327-1.965-1.208-2.342
				c-1.659-0.711-1.765-2.361-2.306-3.635c-1.383-3.249-3.343-6.572-5.406-8.614c-1.809-1.79-2.979-4.026-4.194-6.23
				c-0.218-0.395-0.41-0.944-0.622-1.154c-2.646-2.617-5.122-5.393-7.419-8.407c-1.364,1.367-1.069,2.814-0.779,3.873
				c0.515,1.883,0.027,3.307-0.965,4.795c-0.861,1.291-1.298,2.57-0.384,4.196c0.551,0.979,0.936,2.256,0.672,3.532
				c-0.182,0.874,0.193,1.627,0.582,2.383c0.914,1.775,0.92,3.578,0.002,5.352c-0.941,1.815-1.472,3.512-0.702,5.695
				c0.523,1.483,0.453,3.423-0.231,5c-0.597,1.374-0.25,2.862-0.847,4.187"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M211.273,470.121c-1.015,3.659-2.958,6.974-5.648,9.827c-2.95,3.128-6.167,5.979-9.562,8.581
				c-3.523,2.701-5.045,6.353-5.918,10.459c-0.585,2.75-1.389,5.453-0.634,8.343c0.397,1.521-0.885,2.569-1.775,3.535
				c-2.898,3.141-5.56,6.484-7.637,10.185c-1.382,2.461-2.887,4.871-3.207,7.979c-0.269,2.608-2.785,4.385-5.225,5.301
				c-2.719,1.021-5.404,2.192-8.286,2.837c-1.105,0.248-2.171,0.482-3.293,0.509"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M169.907,657.012c-3.125,1.518-6.04,3.098-6.62,7.132c-0.276,1.92-1.208,3.974-2.945,5c-2.106,1.246-3.291,3.146-4.052,5.157
				c-1.718,4.54-5.59,6.942-9.04,9.745c-1.548,1.258-3.558,1.737-5.484,0.878c-1.134,0.539-1.353,1.733-1.522,2.466
				c-0.89,3.852-3.511,6.168-6.662,7.982c-3.518,2.025-6.202,4.753-8.018,8.362"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M159.491,547.497c-1.106-0.115-1.12,0.885-1.48,1.492c-3.138,5.294-5.293,11.047-7.75,16.659c-0.266,0.608-0.53,1.233-0.9,1.779
				c-1.092,1.608-2.567,2.298-4.447,1.482c-1.932-0.837-3.587,0.211-5.369,0.574c-1.831,0.373-3.325,1.886-5.35,1.522"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M510.357,558.806c0.199,0.099,0.482-0.286,0.583-0.257c2.951,0.884,5.948,1.526,8.68,3.171c3.055,1.839,4.777,4.727,6.447,7.539
				c6.131,10.321,13.539,19.697,20.88,29.138c3.435,4.416,6.358,9.188,9.229,13.982c0.902,1.508,1.653,3.009,3.569,3.606
				c2.082,0.649,2.078,2.912,2.259,4.438c0.258,2.178,1.263,3.84,2.2,5.647c0.96,1.85,2.325,3.513,2.696,5.646"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M220.499,521.011c-0.1,0.198,0.075,0.337,0.286,0.604c4.223,5.337,4.323,11.544,3.533,17.841c-0.349,2.777-0.798,5.467,0.654,8.036
				c0.938,1.661,0.665,3.402,0.237,5.051c-0.351,1.355-0.449,2.593,0.145,3.812c1.261,0.809,2.602,0.365,3.74-0.289
				c0.625-0.36,1.147-0.573,1.821-0.534"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M223.474,531.427c-2.671,7.246-5.312,14.509-7.087,22.035c-0.377,1.597,0.016,3.363-0.074,5.045
				c-0.101,1.891,0.452,3.831-0.546,5.672c-0.382,0.705,0.438,1.352,0.565,2.066"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M465.42,78.484c-2.381,0.199-4.762,0.398-7.143,0.593c-0.197,0.016-0.475-0.084-0.584,0.015c-3.143,2.844-7.349,2.428-11.047,3.47
				c-5.759,1.623-11.682,2.664-17.533,3.959c-2.185,0.484-4.365,0.99-6.548,1.486"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M362.75,519.522c-1.055,0.318-1.307,1.071-1.201,2.084c0.096,0.917-0.043,1.705-0.862,2.403c-2.726,2.32-4.908,5.216-7.757,7.418
				c-3.354,2.593-6.264,2.897-9.787,0.543c-1.972-1.316-4.432-1.889-5.977-3.825c-1.383-1.731-1.143-4.142-2.372-5.811
				c-0.093-0.614,0.354-0.75,0.581-1.023c2.167-2.613,3.182-5.388,2.458-8.947c-0.805-3.954,2.015-6.125,4.971-8.031
				c2.043-1.317,4.064-2.053,6.57-1.228c1.865,0.614,4.016,1.234,4.771-1.729c0.2-0.784,1.396-0.456,1.761-1.197
				c1.265,0.829,3.028,0.263,4.131,1.231c1.522,1.337,2.812,2.779,3.423,4.986c0.773,2.795,2.292,5.436,2.503,8.369
				c0.164,2.279,1.268,4.794-0.775,6.87c-1.172-0.303-1.275-1.588-2.139-2.113"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M316.027,564.162c-1.392-1.01-2.522,0.161-3.539,0.66c-2.108,1.036-3.713,2.777-5.095,4.694c-1.486,2.062-2.471,4.575-5.056,5.656
				c-1.729,3.334-4.151,6.218-6.048,9.468c-2.754,4.72-4.38,9.841-5.705,14.975c-1.066,4.134-2.082,8.307-3.108,12.465
				c-0.139,0.561-0.022,1.186-0.019,1.78c0.018,2.949,0.09,3.077-1.506,5.345c-4.214,5.988-8.18,12.199-12.896,17.769
				c-3.166,3.739-6.55,7.346-9.66,11.146c-3.425,4.187-6.571,8.542-8.196,13.916c-0.792,2.618-1.018,5.137-1.088,7.772
				c-0.032,1.206,0.365,2.505-0.581,3.571"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M326.145,132.052c-2.473-0.286-4.913-0.979-7.378-0.516c-0.359-0.477-0.714-0.843-0.942-1.276c-0.427-0.811-0.582-1.585-1.85-0.66
				c-0.895,0.652-0.404,0.976-0.261,1.563c0.356,1.462,1.01,2.919,0.354,4.372c-0.841,0.264-1.621,0.509-2.392,0.751
				c-0.217-0.372-0.656-0.473-0.634-0.96c0.108-2.392-0.076-2.222-2.374-2.172c-2.463,0.053-4.193,0.675-4.827,2.932
				c-1.812,0.572-2.152-0.853-2.98-1.746c1.05-2.849,1.614-5.695-1.745-7.65c-0.45-0.068-0.576,0.389-0.86,0.604
				c-2.117,1.601-2.445,1.74-4.12-0.048c-1.211-1.293-2.107-1.516-3.322-0.252c-0.261,0.893,0.525,1.635,0.11,2.721
				c-0.415,1.086-0.64,1.64-1.89,1.791c-1.627,0.198-2.118,1.025-1.442,2.608c0.588,1.377,0.109,2.065-0.946,2.699
				c-2.974,1.788-2.975,1.785-2.975,4.779c-0.915,0.574-1.769,1.318-2.976,1.173c-2.296-0.276-3.511,0.958-4.134,3.079
				c2.674,1.888,1.687,5.016,2.34,7.637c0.148,0.597-0.086,1.069-0.572,1.502c-0.606,0.541-1.418,1.024-1.205,2.066"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M613.624,399.591c-0.098-0.818,0.326-1.745-0.591-2.388c-1.074-0.754-1.515-2.039-2.36-2.965c-1.691-0.262-3.675,0.214-3.718-2.681
				c-0.016-1.09-1.299-2.088-2.838-2.452c-2.197-0.52-4.098-1.536-4.242-4.392c-0.101-2.005-1.366-3.993-3.238-4.335
				c-2.452-0.447-2.078-1.867-2.046-3.405c0.004-0.2-0.014-0.396-0.354-0.624c-1.565-0.547-3.774-0.17-3.923-2.941
				c-0.037-0.701-1.203-1.583-2.28-1.469c-1.849,0.195-3.187-0.481-4.517-1.758c-0.931-0.894-2.26-1.132-3.542-0.077
				c-1.187,0.976-2.345,0.671-3.256-0.568c-0.376-0.511-0.721-1.08-1.201-1.474c-1.186-0.97-2.114-1.456-3.556-0.312
				c-0.606,0.481-1.192,0.989-1.58,1.312c-3.073-0.496-1.666-4.013-3.779-5.183c-0.955,0.253-1.504,1.242-2.678,1.239
				c-1.17-0.002-1.893-0.446-2.71-1.207c-1.171-1.089-3.33-1.007-3.729,0.297c-0.604,1.975-2.076,1.731-3.049,1.362
				c-1.685-0.64-3.124,0.017-4.129,0.783c-1.383,1.054-2.735,1.087-4.239,1.101c-0.9,0.007-1.795-0.083-2.685,0.276
				c-1.38,0.556-2.354,1.31-2.08,2.992c0.281,1.739-0.479,3.174-1.502,4.45c-0.969,1.207-0.703,3.166-2.929,3.709
				c-1.486,0.361-1.719,2.226-0.582,3.405c1.311,1.361,0.975,2.558,0.207,4.001c0.872,1.23,1.911,2.184,3.611,1.978
				c0.869,1.83-1.138,3.793,0.521,5.738c0.921,1.079,2.009,1.215,3.137,1.755c-0.437,1.423-0.695,2.803-2.809,1.533
				c-0.552-0.331-1.455,0.6-2.097,1.351c-0.942,1.103-0.438,1.839,0.206,2.858c-0.926,1.143-2.414,1.551-3.483,2.618
				c-1.89-1.782,0.012-3.256,0.129-5.167c-0.33-0.483-1.024-1.178-1.931-1.796c-0.558,0.382-1.147,0.785-1.751,1.198
				c-0.756-0.652-1.562-1.279-2.071-2.318c-1.006-2.052-2.026-2.306-4.123-1.197c-1.819,0.963-3.938,1.102-5.711,2.295
				c-0.683,0.459-1.938,0.141-2.924,0.061c-2.063-0.166-3.607,0.96-5.031,2.133c-1.354,1.113-1.33,2.305-0.001,3.809
				c0.905,1.023,0.77,2.257-0.063,2.622c-2.892,1.269-2.675,4.062-3.521,6.336c-0.107,0.291,0.062,0.682-0.319,0.88
				c1.14,1.28,1.999,2.589,1.84,4.476c-0.124,1.473,1.562,1.762,2.333,2.676c1.289,0.002,2.579-1.488,3.868-0.002"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M253.532,110.625c-1.053-2.29-2.639-3.69-5.359-3.29c-0.389,0.057-0.812,0.133-1.202-0.268c-1.418-1.46-3.255-2.667-4.35-4.249
				c-2.601-3.756-3.439-8.286-4.233-12.733c-0.199-1.114-0.058-2.049,1.396-2.788c1.444-0.735,2.802-1.914,2.288-4.379
				c-0.422-2.021,2.17-4.338,4.624-4.674c1.49-0.204,2.542-1.343,4.151-1.413c0.714-0.03,1.352-1.849,2.042-2.88
				c2.97,0.171,4.925,2.786,8.032,3.069c1.351-1.001,2.164-3.138,4.518-3.074c0.482,0.013,1.419-0.922,1.414-1.414
				c-0.025-2.543,1.892-2.113,3.348-2.372c1.562-0.278,3.169-0.177,4.125,1.208c1.072,1.552,2.333,2.145,4.206,2.133
				c0.921-0.006,2.063,0.305,2.703,1.396c0.625,1.063,1.686,1.553,3.139,1.494c0.536-1.423,0.997-2.659-0.749-3.91
				c-1.733-1.242-1.632-1.739-0.334-3.522c0.292-0.4,0.57-0.81,0.596-0.847c0-1.617,0.059-2.917-0.02-4.21
				c-0.056-0.919,0.322-1.763-1.118-2.488c-2.853-1.437-3.161-4.311-1.251-6.745c1.359-1.734,1.16-3.981,1.693-5.973
				c0.293-1.095,0.551-2.186,0.974-3.254c0.538-1.358,1.096-2.77-0.034-4.228c-0.678-0.534-1.567-0.286-2.322-0.187
				c-1.557,0.205-3.246,0.311-3.322,2.634c-0.047,1.431-1.713,1.589-2.271,1.114c-1.69-1.442-3.565-1.021-5.42-1.159
				c-0.237-0.018-0.559-0.271-0.969-0.24c-0.268,0.353-0.568,0.748-0.835,1.101c0.232,1.344,1.428,1.846,2.141,2.918
				c-0.183,0.553-0.41,1.237-0.741,2.237c-1.94-0.208-3.824,0.1-5.924-1.07c-1.945-1.083-4.243-1.533-6.866-0.009
				c-2.469,1.433-4.606,2.845-6.185,5.206c-0.5,0.749-0.899,1.389-0.245,2.087c0.814,0.869,0.683,1.473,0.027,2.431
				c-0.891,1.302-1.872,2.499-3.035,3.525c-2.493,2.202-3.896,5.22-5.96,7.734c-0.606,0.738-0.972,1.494-0.855,2.396
				c0.195,1.497,0.238,2.949,0.074,4.476c-0.117,1.087,0.64,2.25,1.695,2.966"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M236.271,168.656c-1.513,3.308-3.288,6.494-2.453,10.431c0.31,1.461,0.81,2.62,1.87,3.542c1.014,0.883,1.198,1.835,0.581,3.048
				c0.579,0.462,1.184,0.945,1.802,1.438c0.69-0.145,0.96-0.824,1.608-1.295c0.974,1.427,3.158,1.083,4.13,2.686
				c-0.437,1.918-2.231,1.744-3.674,2.662c-0.075,1.335,0.117,2.922-1.104,4.342c-0.924,1.075,0.395,2.951,2,2.86
				c2.415-0.136,4.646,0.42,6.841,1.253c0.992,0.376,2.018,1.162,2.955,0.252c1.254-1.219,3.684-1.345,3.615-3.84
				c-0.024-0.893,0.699-1.576,0.37-2.704c-0.502-1.718-0.085-1.99,1.697-2.056c0.297-0.011,0.595-0.001,1.075-0.001
				c0.574,1.878,2.036,2.657,3.984,2.679c0.064,2.439-1.368,4.093-3.052,5.419c1.125,3.449,3.194,4.499,6.015,3.182
				c0.312-0.146,0.589-0.347,0.904-0.259c4.529,1.276,5.708-2.48,7.778-5.038c1.086-1.342,1.346-3.237,3.082-4.284
				c1.782,1.364,3.894-1.099,5.706,0.52c1.459-1.926,2.318-4.134,2.186-5.492"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M538.629,326.085c-1.298-0.092-1.66-1.152-2.07-2.089c-0.657-1.503-1.566-2.565-3.26-3.061c-0.537-0.157-1.303-0.49-1.839-1.075
				c-1.753-1.914-3.794-3.363-6.516-3.645c-0.434-0.045-0.799-0.745-1.274-1.22c-1.092,0.621-2.408,0.559-3.551,1.496
				c-1.274,1.046-2.705,0.276-3.811-0.82c-1.42,0.562-3.236,0.522-3.062,2.98c0.078,1.115-1.112,2.084-2.303,2.335
				c-1.27,0.267-1.855-0.733-2.384-1.74c-1.437-2.741-3.599-4.849-6.804-4.054c-2.318,0.576-5.037,0.438-6.916,2.522
				c-0.439,0.488-1.315,0.696-2.019,0.986c-1.632,0.674-1.828,1.7-1.56,3.819c0.122,0.962,0.264,1.964-0.472,3.022
				c-0.789,1.133-1.005,2.633-0.396,4.124c0.62,1.519,0.582,2.944-0.84,4.19c-0.816,0.715-0.954,1.668-0.762,2.962
				c0.208,1.398-0.608,2.971-1.069,4.439c-0.365,1.165-0.614,2.223,0.303,3.288c1.812,2.106,1.785,2.129,0.573,4.825
				c0.402,0.748,0.998,1.343,1.867,1.811c-0.327,0.993-1.387,2.155-0.032,3.153c1.073,0.792,0.81,2.033,0.5,2.662
				c-1.265,2.576-1.664,5.358-2.245,8.086c-0.224,1.052-1.279,1.583-1.281,2.878c0.956,0.99,1.643,2.057,3.655,1.084
				c1.717-0.829,3.315,0.521,3.458,2.577c0.057,0.818,0.511,1.368,0.705,2.065c0.965,3.445,0.832,3.081,3.821,2.989"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M337.156,220.141c1.952,0.672,1.25,2.363,1.131,3.565c-0.345,3.494,0.417,6.5,3.115,9.056c-0.532,0.962-1.665,1.291-2.489,1.925
				c-1.766,1.358-2.172,3.21-1.676,5.073c0.386,1.449,0.556,2.753,0.246,4.195c-0.098,0.453,0.055,1.046,0.285,1.472
				c1.729,3.196,0.188,6.564,0.547,9.833c0.184,1.671-1.178,2.996-1.295,4.75c-0.081,1.205,0.268,1.596,0.868,2.135
				c1.775-0.475,0.406-2.399,1.648-3.019c0.099,0,0.21-0.03,0.295,0.004c2.542,1.041,1.435,4.143,3.182,5.752
				c0.351,0.323-0.51,1.924-1.042,2.903c-0.778,1.43-2.671,1.824-3.09,3.417c0.566,1.187,1.54,0.976,2.44,1.022
				c2.972,0.156,3.494,0.754,3.588,3.565c0.103,3.079,0.924,6.117,0.576,9.226c-0.431,3.858,0.538,7.566,1.186,11.31
				c0.05,0.289,0.036,0.598,0.002,0.891c-0.407,3.508,0.568,6.927,0.699,10.413c0.171,4.572,1.297,9.063,2.888,13.392
				c0.119,0.326,0.386,0.4,0.584,0.301"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M335.371,549.282c0.58,0.847,0.399,2.39,1.502,2.604c1.53,0.297,3.274,0.426,4.709-0.04c1.082-0.352,2.273-0.7,3.333-1.337
				c2.685-1.615,3.858-4.181,2.656-6.286c-1.377-2.412-5.423-3.448-7.711-1.75c-2.188,1.624-4.006,3.646-4.49,6.512"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M451.731,596.005c0.761-3.805-0.479-7.184-3.051-9.746c-2.398-2.388-3.949-5.049-5.386-8.061c-1.94-4.068-4.973-7.439-9.123-9.57
				c-0.948-0.487-1.896-1.063-2.975-0.3"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M324.359,251.388c0.38,2.896,0.931,5.766,1.408,8.644c0.431,2.6-0.92,5.031-2.373,7.078c-1.214,1.711-1.758,3.328-1.772,5.407
				c-0.021,2.985-1.23,5.788-2.108,8.604c-0.27,0.863,0.22,1.415,0.064,2.209c1.771-0.501,1.924-2.366,3.012-3.356
				c1.014-0.923,1.662-1.84,2.183-3.249c2.109-5.702,4.624-11.258,7.919-16.411"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M271.09,537.378c-0.82,1.293-2.135,0.923-3.272,0.867c-2.84-0.139-5.107,1.002-6.486,3.336c-1.097,1.858-2.478,2.68-4.521,2.973
				c-2.887,0.414-5.666,1.506-8.635,1.45c-1.834-0.035-3.313,0.929-4.729,1.841c-0.967,0.623-1.649,1.677-3.009,1.438"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M284.779,189.19c1.446,0.112,2.788-0.114,3.87-1.191c1.312,0.758,2.042,1.562,1.26,3.306c-0.535,1.192,0.248,2.024,1.715,2.019
				c2.104-0.008,3.571,0.699,4.493,2.697c0.96,2.08,2.854,2.67,5.03,2.71c1.794,0.032,2.319,0.621,2.378,2.363
				c0.02,0.595,0.109,1.213-0.015,1.782c-0.598,2.739,0.23,3.706,2.995,3.594c1.729-0.07,3.655-0.491,4.75,1.475
				c0.7,1.255,1.659,0.406,2.375,0.225c0.683-0.173,0.997-1.177,1.775-1.434c2.359,0.397,3.574,3.851,6.574,2.394"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M298.171,472.8c1.42-2.844,1.292-3.698-0.893-5.951c-0.098-0.102-0.203-0.195-0.297-0.299c-4.21-4.682-7.309-3.808-12.469-1.421
				c-1.006,0.465-1.967,1.188-2.983,1.761c-3.88,2.186-4.401,4.523-4.189,8.589"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M557.08,551.365c-0.604-1.681-1.465-3.229-2.36-4.773c-0.822-1.417-1.786-2.729-2.172-4.437c-0.376-1.659-1.046-3.411-2.095-4.712
				c-0.969-1.2-2.726-1.761-2.76-3.84c1.373-2.053,1.127-3.366-1.028-4.558c-2.338-1.293-3.708-3.7-3.201-6.235
				c0.156-0.78-0.079-1.688,0.518-2.399c1.323-1.574,0.63-3.514-0.105-4.688c-1.685-2.689-1.695-5.501-1.662-8.402
				c0.02-1.764-0.186-3.164-1.715-4.557c-1.677-1.527-2.57-3.959-2.933-6.178c-0.285-1.748-0.891-3.229-1.624-4.736
				c-0.325-0.669-0.615-1.32-0.592-2.088c0.045-1.49-0.318-2.717-1.426-3.921c-1.353-1.469-0.803-3.194,1.146-4.39
				c1.067-0.655,1.816-1.398,1.455-2.695c-0.344-1.236,1.156-1.887,0.881-2.973c-0.25-0.986-0.184-1.781,0.286-2.709
				c0.446-0.881,0.936-1.691,0.791-2.971c-0.146-1.3,2.074-2.118,3.068-1.121c1.64,1.645,4.147,0.764,5.707,2.328
				c4.409,0.526,5.423,4.001,6.179,7.456c0.331,1.514,0.796,2.706,1.814,3.889c1.611,1.87,3.124,3.794,5.08,5.348
				c1.055,0.838,1.932,1.846,2.465,3.223c0.521,1.347,2.821,1.09,3.164,2.713c0.24,1.14-0.295,2.516,0.134,3.524
				c0.528,1.245,2.062,2.087,2.516,3.338c0.964,2.656,3.098,4.247,4.805,6.25c0.953,1.118,2.024,1.271,3.306,1.165
				c2.882-0.238,4.424,0.93,5.478,3.822c1.208,3.318,2.082,6.853,4.089,9.835c0.596,0.886,0.487,1.523,0.298,2.419
				c-0.185,0.869-1.045,1.426-0.937,2.372"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M623.444,247.817c-1.614-0.336-3.241-0.559-4.751,0.315c-0.655,0.379-1.136,0.319-1.807,0.006
				c-3.187-1.485-3.413-1.362-4.758,2.057c-0.047,0.12-0.191,0.201-0.291,0.3c-3.104,0.428-3.104,0.428-4.464-2.678
				c-0.893,0-1.788-0.037-2.676,0.027c-0.212,0.016-0.399,0.37-0.657,0.627c-0.964-0.585-1.59-1.623-2.965-2.099
				c-3.865,0.488-3.865,0.488-5.838-1.467c-1.642,0.264-2.504,1.752-3.934,2.318c-2.867-1.647-3.894-1.401-5.326,1.501
				c-0.43,0.869-0.757,1.801-1.167,2.306c-0.177,1.162,0.495,1.606,0.875,2.115c0.466,0.624,0.296,1.72,0.234,1.742
				c-2.416,0.884-1.95,3.804-3.769,5.224c-1.092,0.853-1.506,2.45-3.008,3.258c-1.447,0.779-1.355,1.737-0.002,2.849
				c0.369,0.304,0.719,0.69,1.126,0.913c-1.723,2.489-3.188,0.438-4.693-0.293c-1.184,0.636-1.24,1.875-0.876,2.674
				c1.288,2.821,1.587,6.132,4.096,8.372c1.483,1.323,2.205,3.057,2.076,5.047c-0.114,1.741,1.522,2.998,1.207,4.763"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M328.526,290.968c-2.438,0.151-2.42,2.449-3.18,3.917c-0.358,0.693-0.581,1.608-1.008,2.319c-2.051,3.406-2.407,7.207-2.636,11.025
				c-0.119,1.977,0.017,3.968-0.032,5.951c-0.053,2.146,0.872,3.695,2.689,4.762"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M314.241,46.046c-2.296-2.084-5.39-2.508-8.063-3.811c-1.328-0.647-6.583,1.886-7.069,3.232c-0.253,0.702-0.078,1.573-0.037,2.364
				c0.068,1.289-0.23,2.214-1.793,2.085c-0.766-0.063-1.523,0.124-2.067,0.611c-1.439,1.292-3.025,1.461-4.683,0.803
				c-0.79,0.596-0.578,1.508-0.988,2.155c3.442,3.965,3.247,6.611-0.62,8.044c-0.947-0.208-0.779-1.356-1.463-1.795
				c-1.53-0.083-2.524,0.905-3.571,1.786"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M106.221,319.538c-0.558-0.352-0.825-0.943-0.57-1.476c0.879-1.838-0.718-3.893,0.558-5.675c0.094-0.131,0.098-0.463,0-0.575
				c-1.643-1.87-1.2-5.023-3.895-6.247c-3.633,1.331-4.083,1.284-8.652-0.936c0.167-0.963,1.469-1.918,0.069-2.958
				c-1.118-0.831-2.31-1.339-3.563-0.558c-1.441,0.898-3.926,0.792-3.646,3.551c0.022,0.219-0.57,0.72-1.118,0.924
				c-1.155,0.431-2.241,0.899-1.586,2.673c0.273,0.74-0.55,1.524-1.702,1.482c-1.459-0.054-2.427-0.791-2.696-2.104
				c-0.283-1.384,0.707-2.225,1.293-3.297c-0.336-0.505-0.62-1.018-0.985-1.462c-0.874-1.062-1.772-1.296-2.99-0.331
				c-1.521,1.205-2.848,0.69-3.787-0.936c1.295-1.5,3.138-2.66,3.579-4.976c0.153-0.804,1.568-0.914,2.015-1.799
				c-0.807-2.218-0.814-2.251-2.972-1.769c-0.858,0.191-1.484-0.243-2.074-0.633c-2.127-1.406-4.081-2.812-4.863-5.609
				c-0.869-3.104-3.291-4.045-6.439-2.943c-0.646,0.226-1.218,0.094-1.806,0.24"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M455.004,167.168c1.085,1.126,2.716,1.377,3.861,2.389c1.026,0.906,1.875,0.754,3.009,0.032c2.047-1.305,3.146-0.809,3.191,1.449
				c0.029,1.438,0.731,1.988,1.845,2.372c0.682,0.235,1.389,0.395,2.044,0.577c1.021,1.484,0.614,3.213,0.861,4.798
				c0.367,2.357-0.674,3.154-3.205,2.966c-1.729-0.128-3.109,0.41-3.865,2.084c-0.128,0.285-0.269,0.588-0.29,0.892
				c-0.124,1.703-2.846,1.122-2.661,2.972c0.127,1.263-0.417,2.242-0.921,3.277"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M295.195,115.684c-1.17,1.017-2.34,2.034-3.524,3.063c-1.812-0.373-2.708-2.647-4.417-1.91c-0.688-0.459-0.709-0.654-0.701-0.856
				c0.075-1.758,0.226-3.821-1.523-4.663c-1.469-0.707-3.168-1.55-5.014-0.995c-1.276,0.383-2.545,0.259-3.556-0.607
				c-2.373-2.034-4.948-0.918-7.449-0.547c-1.295,0.193-2.46,1.592-3.892,0.286c-0.163-0.148-0.655-0.139-0.863-0.002
				c-2.583,1.697-5.279,1.247-8.054,0.607c-1.747-0.403-4.227,1.436-4.07,3.236c0.15,1.723-0.124,3.394-0.03,5.064
				c0.12,2.127-0.271,3.946-1.528,5.376c-0.864,0.982-1.425,2.142-1.218,3.556c0.326,2.224,0.49,4.484,1.519,6.535
				c0.139,0.278,0.375,0.408,0.276,0.606"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M48.19,289.778c-1.61,0.008-1.594-1.567-2.02-2.413c-8.418-16.722-16.712-33.506-25.106-50.24
				c-0.483-0.962-0.381-2.557-2.039-2.701"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M48.19,174.906c-1.237-0.022-1.132,1.047-1.5,1.779c-3.749,7.45-7.532,14.884-11.295,22.327
				c-4.965,9.818-9.927,19.638-14.869,29.467c-0.369,0.733-0.264,1.802-1.5,1.78"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M357.095,133.242c-0.981-0.998-2.458-0.806-3.324-1.512c-1.245,0.1-0.864,1.181-1.191,1.448c-1.06,0.862-1.896,2.023-3.874,1.074
				c-2.274-1.092-4.219,0.479-4.333,3.16c-0.04,0.93-0.442,1.655-0.758,2.337c-0.287,0.62-0.617,1.165-0.494,1.824
				c0.479,2.566,0.498,2.563-2.096,2.68c-0.099,0.004-0.198,0-0.298,0c-1.655-3.068-1.655-3.068-3.273-4.167
				c-1.979,1.297-1.979,1.297-3.847,0.604c-0.421-1.2,1.016-1.473,1.189-2.397c-0.373-0.713-0.743-1.422-1.436-2.75
				c0.126,0.377,0.294,0.442,0.511,0.35c1.413-0.596,2.624-1.414,2.083-3.243c-0.49-1.658-1.269-2.073-3.559-2.086
				c-0.496-0.002-0.992,0-1.489-0.001c-0.93,0.856-1.419,2.162-2.68,2.673c-0.693,0.281-0.087,0.827-0.295,1.197"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M435.661,139.789c-0.734,0.911-1.542,1.241-2.687,0.611c-2.208-1.214-2.304-1.221-3.528,0.898
				c-0.527,0.912-1.252,1.595-2.125,1.705c-1.408,0.177-1.997,0.62-2.152,2.135c-0.121,1.191-2.145,1.829-3.191,1.188
				c-2.676-1.64-4.942-3.863-6.731-6.326c-1.062-1.461-2.302-2.092-3.714-2.511c-0.849-0.251-1.714-0.641-2.653-0.695
				c-1.049-0.061-1.754-0.722-1.76-1.766c-0.01-1.535-0.457-3.151-1.839-3.455c-1.582-0.349-3.441-0.539-5.034,0.478
				c-0.488,0.311-1.352,0.523-1.431,0.906c-0.718,3.48-4.485,3.417-6.409,5.55c-0.979,1.085-2.885,0.48-4.063-0.8"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M48.19,294.54c-1.237-0.022-1.132,1.047-1.5,1.779c-3.749,7.45-7.531,14.884-11.295,22.327
				c-4.965,9.818-9.927,19.638-14.869,29.467c-0.369,0.733-0.264,1.802-1.5,1.78"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M48.19,170.144c-1.61,0.008-1.594-1.567-2.02-2.413c-8.418-16.722-16.712-33.506-25.106-50.24
				c-0.483-0.962-0.381-2.557-2.039-2.701"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M363.642,93.662c-0.704,0.033-1.453-0.15-2.079,0.302c-1.499,1.082-2.846,1.67-4.521,0.049c-0.95-0.92-2.16,0.26-3.238,0.423
				c-1.697,0.256-2.633,0.354-2.78,2.49c-0.108,1.572-2.404,2.572-3.994,1.698c-1.55-0.853-2.653-0.566-3.578,0.436
				c-0.884,0.957-2.41,0.803-3.301,2.138c0.672,1.192,1.473,2.483,0.278,3.771c-0.729,0.786-1.794,0.149-2.754,0.775
				c-1.059,0.69-1.886-1.357-3.701-0.953c-0.16,0.121-0.842,0.633-1.547,1.164c-1.109-3.197-1.109-3.197-4.496-3.664
				c-0.749-0.771,0.263-2.08-0.623-2.634c-1.049-0.656-0.731-1.551-0.893-2.42c-0.163-0.878-1.087-1.659-1.704-1.132
				c-1.647,1.409-3.03-0.643-4.518-0.063c-1.78-1.575-2.357-0.167-2.988,1.185c-0.111,0.239-0.384,0.403-0.696,0.714
				c-1.474-1.002-3.378-1.349-4.557-0.032c-1.127,1.26-2.004-0.333-3.144,0.394c-1.329,0.847-0.278,2.105-1.116,2.795
				c-0.383,0.315-0.823,0.564-1.182,0.902c-0.953,0.898-1.777,1.81-0.92,3.28c0.452,0.774-0.711,1.28-0.549,2.211
				c0.219,0.665,0.959,1.058,1.468,1.641c1.383,1.587,1.275,2.671-0.63,3.504c-0.535,0.234-1.152,0.489-1.736,0.672
				c-2.237-0.789-3.961-3.62-6.88-1.519c-1.316,0.948-2.375,1.821-1.531,3.586c4.043,0.604,4.462,1.138,3.333,4.176
				c-0.074,0.479,0.388,0.602,0.599,0.89c0.588,0.8,2.004,0.478,2.307,1.221c0.537,1.317,0.636,2.804,1.238,4.148
				c0.326,0.728-0.522,1.011-0.869,1.477"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M19.323,469.526c1.167-0.364,1.313-1.586,1.728-2.41c3.867-7.688,7.53-15.479,11.383-23.175
				c4.489-8.965,9.133-17.852,13.641-26.808c0.563-1.118,0.991-2.283,1.809-3.265c1.259-1.51,1.28-2.663-0.008-4.146
				c-0.784-0.901-1.25-1.954-1.766-2.991c-8.338-16.762-16.647-33.538-25.065-50.26c-0.425-0.846-0.41-2.42-2.02-2.413"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M240.14,629.633c0.595-1.289,1.413-2.527,1.726-3.882c0.346-1.499,2.016-1.656,2.463-2.955c0.153-0.446,0.551-0.819,0.875-1.193
				c0.444-0.514,0.472-1.009-0.008-1.489c-0.48-0.479-0.956-0.426-1.49-0.01c-2.52,1.961-5.488,3.134-8.353,4.413
				c-3.137,1.4-6.252,1.021-8.902-1.431"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M48.487,532.617c-1.167,0.363-1.309,1.586-1.729,2.409c-3.866,7.589-7.593,15.249-11.366,22.886
				c-4.56,9.227-9.105,18.461-13.729,27.657c-0.352,0.699-0.581,1.608-1.177,2.069c-1.856,1.434-1.76,3.406-1.76,5.391
				c0,6.646,0.003,13.292-0.003,19.938c0,0.984,0.234,2.062-0.89,2.679"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M664.214,348.702c-1.106,0.114-1.159-0.87-1.474-1.495c-3.989-7.925-7.949-15.865-11.918-23.801
				c-4.562-9.126-9.12-18.255-13.703-27.372c-0.314-0.625-0.368-1.61-1.475-1.495"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M664.214,474.883c-1.106-0.114-1.159,0.87-1.474,1.495c-3.989,7.926-7.95,15.865-11.918,23.801
				c-4.563,9.126-9.12,18.256-13.703,27.372c-0.314,0.625-0.368,1.609-1.475,1.495"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M33.607,143.36c-1.064,0.58-1.285,1.736-1.771,2.686c-3.796,7.426-7.562,14.868-11.311,22.319c-0.369,0.732-0.264,1.801-1.5,1.779"
				/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M635.645,174.906c1.237-0.022,1.133,1.047,1.501,1.779c3.749,7.45,7.561,14.869,11.287,22.331
				c4.6,9.207,9.115,18.457,13.738,27.652c0.352,0.699,0.57,1.653,1.179,2.061c1.867,1.25,1.441,3.071,1.45,4.804
				c0.003,0.406,0.05,0.779-0.265,1.207c-4.304,5.848-6.801,12.666-10.107,19.047c-5.876,11.344-11.546,22.795-17.282,34.212
				c-0.368,0.732-0.264,1.802-1.501,1.779"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M33.607,262.994c-1.064,0.58-1.285,1.737-1.771,2.686c-3.796,7.426-7.562,14.868-11.311,22.319c-0.369,0.732-0.264,1.801-1.5,1.779
				"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M664.214,587.97c-1.106,0.114-1.159-0.87-1.474-1.495c-3.989-7.925-8.023-15.829-11.898-23.81
				c-4.536-9.339-9.624-18.405-13.743-27.95c-0.199-0.462-0.665-1.098-1.454-0.907"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M635.645,414.173c1.237-0.021,1.133,1.047,1.501,1.779c3.749,7.451,7.53,14.885,11.295,22.327
				c4.766,9.422,9.53,18.844,14.273,28.277c0.368,0.732,0.264,1.802,1.5,1.779"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M664.214,355.249c-1.106-0.114-1.159,0.87-1.474,1.495c-3.989,7.925-7.949,15.865-11.918,23.801
				c-4.562,9.126-9.12,18.256-13.703,27.372c-0.314,0.625-0.368,1.609-1.475,1.495"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M33.607,382.628c-1.064,0.58-1.285,1.736-1.771,2.687c-3.796,7.426-7.562,14.868-11.311,22.318c-0.369,0.732-0.264,1.801-1.5,1.779
				"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M160.979,379.95c0.096-3.356,0.347-3.607,3.342-3.267c0.658-0.876,1.286-1.824,2.027-2.675c0.861-0.985,1.834-1.509,3.278-0.948
				c1.075,0.418,2.227-0.125,3.248-0.569c1.439-0.626,2.98-0.813,4.481-1.128c0.74-0.155,2.008-0.132,2.298,0.303
				c1.339,2.009,3.811,1.405,5.363,2.577c0.727-0.586,1.36-1.339,2.168-1.692c1.259-0.549,2.644-0.758,3.847,0.261
				c1.194,1.01,2.979,1.617,2.154,3.889c-0.266,0.732-0.449,1.833,0.233,2.653c1.9,2.285,0.375,4.522,0.089,6.856
				c-0.32,2.609-2.63,2.848-4.028,4.078c-1.032,0.907-2.312,1.269-2.157,3.065c0.022,0.256-0.157,1.545-1.022,2.11
				c-1.68,1.096-1.416,2.769-0.923,4.427c0.467,1.573,0.169,2.115-1.487,2.349c-0.898,0.126-1.946-0.069-2.341,0.937
				c-0.36,0.917-0.786,1.79-0.395,2.982c0.772,2.354,0.618,4.77-1.458,6.488c-0.996,0.824,0.003,2.108-0.876,2.693
				c-2.161,1.435-3.311,3.953-5.41,5.271c-1.344,0.844-1.995,1.713-2.312,3.086c-0.697,3.017-1.826,5.503-5.338,6.329
				c-1.925,0.452-2.758,2.598-3.818,4.135c-0.715,1.038,0.795,1.908,1.121,2.928c-0.51,1.146-1.257,2.079-2.396,2.814
				c-0.079,0.915,0.924,1.715,0.645,2.849c-0.11,0.444-0.181,0.983,0.245,1.495c2.095,2.515,1.636,5.683,2.399,9.106
				c-3.067-0.984-5.316,1.223-8.036,0.999c-0.504-0.041-0.952,0.205-1.175,0.601c-1.359,2.412-4.063,4.367-2.693,7.729"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M239.247,590.053c-1.389,1.786-2.605,3.752-4.221,5.301c-1.322,1.268-3.229,1.904-4.677,3.07c-1.448,1.167-3.193,1.307-4.808,1.678
				c-2.95,0.679-5.558,1.827-7.718,3.941c-1.604,1.57-3.237,3.117-4.749,4.772c-1.734,1.898-3.111,4.046-3.885,6.533"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M177.645,371.021c0.845-2.521,0.262-5.342,1.718-7.777c0.305-0.509,0.199-1.522-0.182-2.362c-0.541-1.191-1.121-2.436-0.951-3.848
				c0.155-1.293-1.255-1.324-1.776-2.082c0.806-1.805,0.978-3.512-0.589-5.065c-2.087-2.069-4.667-3.148-7.569-4.102
				c0.146-2.004-0.494-4.342-2.601-4.965c-1.794-0.53-3.662-1.224-5.608-1.352c-1.891-0.125-3.764,0.175-5.696-0.462
				c-0.956-0.315-2.34,0.668-3.53,1.067c-1.451,0.485-2.909,1.105-4.461,0.872c-3.444-0.517-6,1.065-8.342,3.287
				c-0.418,0.397-0.824,0.728-1.46,0.958c-4.56,1.649-5.117,3.923-4.188,7.68"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M332.692,186.81c-1.103,0.814-2.583,1.147-1.852,3.296c1.441,4.235-0.444,7.313-4.695,8.312c-0.398,0.093-0.794,0.197-1.272,0.317
				c-0.653,4.058-2.104,7.958-2.411,12.173c-0.213,2.919-1.051,5.975-2.365,8.59c-2.201,4.384-2.37,9.164-3.378,13.759
				c-0.657,2.992-0.923,6.028-2.14,8.923c-2.037,4.844-3.002,10.007-3.894,15.163c-0.396,2.291-1.119,4.43-2.018,6.578
				c-0.587,1.404-1.133,3.087-0.379,4.728"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M372.868,464.467c-1.988,2.369-1.646,5.342-1.66,8.036c-0.012,2.156,0.528,4.307,0.256,6.557c-0.161,1.333,0.36,2.822,0.837,4.146
				c1.512,4.193,3.012,8.4,4.781,12.488c1.173,2.709,1.992,5.388,1.754,8.354c-0.054,0.67-0.055,1.309-0.882,2.017
				c-1.606-4.571-3.687-8.766-7.765-11.541"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M664.214,534.997c-1.236-0.021-1.132,1.047-1.5,1.779c-3.948,7.847-7.923,15.681-11.896,23.515c-0.06,0.119-0.193,0.201-0.293,0.3"
				/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M623.444,403.46c-3.764-0.764-3.677-0.425-8.362,0.776c-3.242,0.831-6.844,1.897-10.184-0.31c0.289-0.568,0.759-1.197,0.648-1.323
				c-1.122-1.297-2.56-1.658-4.112-0.903c-1.538,0.747-3.032,1.258-4.779,0.895c-0.834-0.173-1.483,0.073-1.833,1.146
				c-0.44,1.353-1.33,1.583-2.321,0.9c-0.341-0.235-0.62-0.569-0.896-0.886c-0.81-0.929-1.77-0.751-2.673-0.277
				c-1.045,0.547-1.451,1.562-1.173,2.653c0.429,1.681,0.613,3.23-0.93,4.479c-0.241-0.44-0.663-0.701-0.932-1.17
				c-0.575-1.003-1.485-1.25-2.641-0.645c-1.81,0.946-4.015,1.436-3.886,4.188c0.014,0.29-0.4,0.63-0.604,0.857
				c-2.136,2.378-2.319,5.047-1.835,8.083c0.271,1.699,0.499,3.391,0.981,5.046c-0.186,1.102-1.133,1.631-1.793,2.386
				c-0.394-0.199-0.964-0.678-1.157-0.557c-1.507,0.946-2.885,2.008-2.723,4.123c0.119,1.57-0.302,3.3,2.092,3.631
				c0.567,0.079,1.091,0.853,0.923,1.729c-0.072,0.381-0.015,0.77,0.243,1.205c1.577,2.672,1.454,5.092-0.46,7.791
				c-1.303,1.837-0.407,4.038,1.396,5.571c-1.965,1.725-2.047,1.82-3.848,0.271c-1.02-0.877-2.345-0.398-3.316-1.187
				c-2.619,1.879-5.273,3.686-8.326,4.783c-0.455,0.163-0.584-0.397-0.979-0.627c-0.567,1.27-3.112,1.721-1.502,3.985
				c0.108,0.152-0.871,1.623-1.652,2.339c-2.004,1.836-2.583,5.981-1.183,8.278c0.42,0.69,0.83,1.386,1.132,2.115
				c0.61,1.475,1.086,2.964,2.989,3.312c0.358,0.065,0.604,0.745,1.088,1.387c-2.411,0.652-3.191,2.903-4.65,4.518"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M171.097,314.181c-2.573,2.275-5.909,3.32-8.63,5.357c-2.231-0.418-3.323,0.937-4.207,2.731c0.086,1.412,2.243,1.822,1.744,3.621
				c-0.46,0.63-1.256,0.51-2.001,0.487c-2.699-0.083-4.853,0.672-5.502,3.949c0.293,0.714,1.093,1.524,1.952,2.303
				c0.75-0.272,1.498-1.206,2.342-0.278c0.645,0.709,0.196,1.33-0.229,2.096c-0.771,1.39-2.322,2.516-1.837,4.436"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M504.406,454.646c0.67,0.829,0.327,1.806,0.268,2.677c-0.475,6.904,0.196,13.663,2.427,20.232c0.3,0.882,0.262,2.039,1.472,2.387"
				/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M457.98,156.455c-1.13-2.117-2.628-3.863-4.738-5.098c-0.506-0.296-0.965-0.431-1.5-0.593c-2.436-0.741-4.299,0.162-5.758,1.893
				c-1.45-0.137-1.826-1.508-2.893-1.841c0.486-0.969,0.856-1.828,0.374-3.019c-0.377-0.933,0.16-2.187-0.94-2.976
				c-0.141-0.101-0.147-0.682,0-0.848c1.415-1.597,0.229-2.438-0.915-3.288c-0.969-0.719-1.98-1.515-3.257-0.867
				c-0.581,0.295-0.948,0.143-1.514,0.03c-1.304-0.259-0.999-1.69-0.843-1.761c1.254-0.572,1.297-2.417,2.365-2.667
				c1.633-0.382,2.617-2.125,4.382-1.888c1.196,0.747,0.445,2.017,0.905,3.005c0.887,1.903,1.347,2.265,2.986,1.117
				c0.696-0.487,1.127-0.438,1.83-0.284c0.665,0.146,1.427,0.165,2.136-0.299c1.09,0.405,1.514,1.805,3.224,1.652
				c1.956-0.174,2.628,1.224,2.064,3.147c-0.149,0.51-0.525,0.985-0.557,1.492c-0.056,0.884-0.194,1.647,1.131,2.157
				c2.841,1.093,3.431,4.166,4.705,6.518c0.773,1.427-0.838,2.794-1.472,4.086c-0.89,1.813-2.247,3.322-0.589,5.44
				c0.354,0.452-0.112,1.841-0.842,1.969c-2.199,0.387-2.587,2.654-4.154,3.636c-1.912-0.127-3.655-1.32-5.668-0.829
				c-0.595-0.848-1.164-1.66-1.71-2.438c-1.174-0.127-2.146,1.28-3.396,0.242c-0.699-1.496-0.012-2.945,0.455-4.417
				c-1.127-1.677-2.676-2.579-4.267-2.973c-2.447-0.005-1.263,4.997-4.759,2.976c0.939-3.311,0.554-4.411-2.071-6.562
				c-1.085-0.89-2.214-0.844-3.262-0.242c-0.723,0.415-1.396,0.979-2.079,1.485c-0.732,0.543-1.251,0.631-2.125,0.297
				c-2.088-0.799-2.746-0.367-2.94,1.75c-0.055,0.598-0.059,1.133-0.617,1.486"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M373.463,243.948c0.496-0.298,1.038-0.539,1.479-0.903c1.339-1.107,2.675-1.039,4.187-0.311c1.849,0.891,3.061,0.217,3.566-1.761
				c0.266-1.039,0.373-2.148,1.182-2.979c0.332-0.343,0.622-0.639,1.193-0.609c2.8,0.145,3.02-0.148,2.359-3.051
				c1.122-0.895,2.769-0.371,3.907-1.377c1.107-0.978,2.723-1.426,3.243-3c0.515-1.559,2.781-1.464,3.048-3.253
				c-0.44-1.59-2.399-1.98-2.572-3.827c1.828-2.648,1.828-2.648-1.348-4.22c-0.236-0.923,0.284-1.487,0.865-2.105
				c0.574-0.611,0.578-1.44-0.002-2.044c-0.527-0.548-0.726-1.512-2.095-1c-1.482,0.554-3.166-0.127-4.077-1.44
				c-0.824-1.19-1.976-1.818-3.12-2.84c-1.992,2.192-4.059,4.219-5.834,6.475c-2.711,3.446-6.116,5.814-10.133,7.454
				c-0.735,0.3-1.406,0.148-2.099,0.26"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M635.645,512.38c0.43,0,0.639-0.072,0.937-0.569c4.395-7.319,7.705-15.203,11.879-22.633c0.544-0.967,0.095-1.671-0.33-2.382
				c-1.031-1.721-1.808-3.566-2.731-5.33c-0.52-0.991-0.54-1.465,0.001-2.453c0.848-1.548,1.509-3.207,2.438-4.73
				c0.833-1.364,0.858-2.784,0.003-4.157c-0.943-1.515-1.594-3.181-2.441-4.729c-0.541-0.987-0.557-1.467,0.011-2.449
				c1.259-2.185,2.216-4.542,3.362-6.968c-3.842-7.316-7.75-14.736-11.626-22.172c-0.378-0.726-0.265-1.801-1.502-1.778"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M439.53,191.869c-0.3-1.016-0.805-1.788-1.765-2.414c-2.81-1.83-2.819-2.297-0.615-5.025c0.088-0.108,0.197-0.199,0.327-0.329
				c-0.926-1.689-2.189-2.766-4.169-3.34c-1.336-0.387-2.703-1.305-3.592-2.587c-0.25-0.361-0.66-0.87-0.563-1.172
				c0.911-2.847-1.815-3.994-2.781-5.934c-0.662-1.332-2.717-1.423-4.697-0.627c-1.508-0.602,0.385-1.959-0.569-2.718
				c-1.208-0.93-2.995-0.913-3.866-2.405c1.385-1.026,2.239-3.396,4.627-1.927c0.921-0.808,1.229-1.805,1.714-2.758
				c-2.467-1.9-4.142-5.805-8.165-2.683c-0.694-0.146-0.965-0.824-1.479-1.199c0-0.099-0.04-0.227,0.006-0.293
				c0.486-0.702,0.985-1.393,1.48-2.087c-0.341-1.251-0.682-2.502-1.021-3.753c-0.587-1.328-1.851-1.796-3.443-1.629
				c-1.178,0.124-2.385,0.102-3.567,0.008c-2.356-0.185-4.449,0.002-5.371,2.69c-0.422,1.233-1.462,1.636-2.663,1.477
				c-1.779-0.237-3.198,0.23-4.23,2.114c-0.99-1.888-2.642-1.656-4.097-1.447c-1.363,0.197-1.853,1.687-2.651,2.651
				c-0.508,0.613,0.684,3.473,1.439,4.152c0.678,0.608,1.697,1.1,1.746,2.071c0.044,0.899,0.2,1.867,0.96,2.001
				c0.94,0.166,1.976,0.323,3.031-0.418c0.572-0.402,1.078-1.113,1.727-1.236c0.945-0.18,2.596-0.259,2.547,0.836
				c-0.083,1.886,1.57,2.672,1.98,4.063c-0.172,1.274-1.208,1.747-1.871,2.478c-1.628,1.797-1.654,2.861,0.014,4.771
				c0.185,0.211,0.396,0.398,0.582,0.585c-0.221,2.623-2.98,1.695-4.149,2.945c-0.13,1.035,0.998,1.311,1.256,1.919
				c-0.396,3.276-4.442,1.479-4.682,4.025c-1.477,0.156-2.155-0.782-3.064-0.885c-0.872,0.28-1.277,1.008-1.843,1.535
				c-2.6,2.429-2.387,3.594,0.885,5.06c0.12,0.053,0.201,0.192,0.301,0.292c-0.237,2.254-1.539,2.939-5.693,2.977
				c0.567,1.829,0.723,3.534-1.435,4.469c-2.753-0.983-3.173-0.996-4.741,1.209c-1.521,2.138-3.204,2.998-5.703,1.788
				c-0.401-0.195-0.979-0.027-1.46-0.027c-0.26,1.219-0.594,2.355,0.251,3.593c1.615,2.37,0.752,4.376-2.058,4.673
				c-1.049,0.11-1.984,0.427-2.969,0.662"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M635.645,273.113c1.292,0.101,1.385-1.033,1.774-1.792c2.898-5.643,5.779-11.295,8.608-16.973c0.177-0.355,0.458-0.564,0.625-0.878
				c2.328-4.373,2.568-4.521-0.086-8.872c-1.633-2.675-1.638-4.578,0.001-7.251c2.61-4.259,2.662-4.598-0.015-8.807
				c-1.797-2.826-1.781-5.08,0.121-7.492c2.162-2.74,1.844-5.103,0.079-8.106c-3.502-5.958-6.456-12.238-9.604-18.402
				c-0.344-0.672-0.657-1.224-1.504-1.183"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M635.645,392.746c1.817-0.335,2.007-2.196,2.576-3.325c3.234-6.416,6.676-12.73,9.704-19.255c0.579-1.247,0.603-1.916-0.035-3.038
				c-0.96-1.69-1.482-3.635-2.48-5.298c-0.587-0.979-0.551-1.463-0.01-2.451c0.848-1.548,1.498-3.214,2.441-4.729
				c0.855-1.373,0.83-2.794-0.003-4.158c-0.93-1.523-1.591-3.183-2.438-4.73c-0.541-0.988-0.616-1.485,0.014-2.452
				c0.812-1.247,0.942-3.017,1.905-4.07c1.646-1.803,1.129-3.295,0.258-5.133c-2.909-6.138-6.603-11.87-9.306-18.118
				c-0.351-0.81-0.963-1.594-1.448-2.396c-0.243-0.402-0.611-0.743-1.178-0.603"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M172.883,641.835c0.779,2.893,0.159,5.653-0.94,8.312c-0.224,0.542-0.178,1.009-0.245,1.509c-0.236,1.765-0.628,3.437-1.42,5.093
				c-0.671,1.403,0.27,3.109-0.564,4.779c-0.667,1.335-0.435,3.166-0.558,4.721c-0.375,4.754-1.568,9.162-3.659,13.407
				c-0.246,0.499-0.883,1.362-0.054,2.057"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M644.871,614.456c0.923-3.103,2.765-5.761,4.03-8.701c-1.444-2.047-2.521-4.447-3.752-6.764c-0.354-0.664-0.348-1.144,0-1.807
				c1.299-2.475,2.521-4.99,3.741-7.433c-1.07-2.164-2.479-4.211-3.5-6.51c-0.442-0.995-0.574-1.472,0.022-2.447
				c1.081-1.77,1.801-3.757,2.748-5.613c0.479-0.94,0.486-1.757-0.005-2.693c-3.691-7.031-7.345-14.083-11.035-21.116
				c-0.326-0.621-0.369-1.609-1.476-1.495"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M565.413,496.013c-1.587-0.298-3.189-0.535-4.756-0.918c-0.837-0.204-1.739-0.328-2.127-1.45c-0.481-1.395-1.301-1.452-2.694-0.71
				c-0.907,0.484-2.249,1.467-3.608-0.117c-0.714-0.833-2.651-0.926-3.403,0.268c-0.855,1.358-1.942,1.236-3.048,1.104
				c-1.367-0.164-2.133,0.413-2.677,1.557c0.172,0.873,1.344,1.307,1.171,2.349c-0.148,0.896-0.533,1.767-1.198,2.359
				c-0.929,0.827-1.428,1.778-1.467,2.999"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M154.134,289.778c4.001-2.157,8.308-2.81,12.797-2.709c0.972,0.022,1.729,0.461,2.348,0.964c1.137,0.926,2.445,1.69,3.201,2.977
				c1.582-0.665,2.685-2.224,4.773-1.787c-0.207,2.261-1.558,4.021-2.701,6.027c2.059,3.126,2.466,6.606,1.938,10.306
				c-0.222,1.556-0.1,3.172-0.025,4.756c0.208,4.411-1.408,8.419-2.736,12.484c-0.088,0.271-0.104,1.056-0.847,0.908"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M644.871,88.007c0.528,3.208,2.742,5.574,4.016,8.338c-1.217,2.434-2.437,4.95-3.737,7.424c-0.351,0.666-0.364,1.149,0.003,1.806
				c1.281,2.286,2.493,4.61,3.713,6.884c-1.195,2.391-2.415,4.907-3.716,7.381c-0.351,0.665-0.364,1.149,0.003,1.806
				c1.281,2.286,2.493,4.61,3.736,6.929c-3.077,5.93-6.165,12.027-9.408,18.041c-1.078,1.999-1.526,4.485-3.835,5.673"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M536.545,462.086c-1.152-0.852-2.207-1.892-3.871-1.502c-1.222,0.286-1.679-0.853-2.363-1.49c-0.581-0.542-1.213-0.135-1.801-0.281
				c-0.171,0.749-1.305,1.328-0.842,2.051c0.8,1.252,0.614,2.905,2.001,3.943c1.661,1.243,2.249,2.809,1.289,5.046
				c-1.372,3.203-0.479,6.224,1.697,8.916c0.633,0.782,1.013,1.769,1.509,2.661"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M462.742,664.75c-0.755,0.622-0.921,1.431-0.908,2.381c0.054,3.798,0.328,7.478,2.022,11.047c1.278,2.694,1.853,5.721,2.768,8.59
				c0.104,0.327,0.384,0.6,0.582,0.897"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M327.633,133.242c-1.634-0.642-0.379-2.938-2.078-3.584c-1.324-0.504-1.146-2.001-1.916-3.151c1.802-1.548,3.134-3.556,4.59-5.466
				c0.361-0.794-0.818-1.391-0.063-2.427c1.165-1.598,1.518-3.583,1.577-5.608c0.047-1.61,1.717-2.829,3.238-2.351
				c1.434,0.451,2.826,1.084,4.166,1.774c1.645,0.846,2.615,2.149,2.41,4.149c-0.091,0.89-0.103,1.809,0.274,2.678
				c0.449,1.036,0.694,2.071-0.412,2.799c0.62,0.91,1.49,1.037,2.216,1.329c0.662,0.266,1.331-0.334,1.427-0.864
				c0.266-1.473,1.337-3.096,0.559-4.406c-1.039-1.749-0.006-2.418,1.023-3.289c2.409,1.723,2.685,1.659,3.821-0.927
				c0.55-1.25,1.463-1.585,2.667-0.873c0.468,0.276,0.806,0.774,1.317,1.286c1.11-1.212,1.64-2.367,0.183-3.687
				c0.776-0.539,0.563-1.926,1.785-2.083c0.992-0.125,1.27,1.003,1.807,1.231c2.203-0.281,3.088-1.978,4.442-3.016
				c3.726,0.535,1.311,4.447,3.274,5.951c1.683-0.481,2.324-2.315,4.028-2.857c0.645,0.479,1.572,0.52,2.687,0.467
				c0.683-1.521,1.402-3.181,3.694-3.617c1.86,2.779,5.562,4.061,7.06,7.24c1.388,2.947,3.358,5.348,5.493,7.652
				c0.872,0.942,0.668,1.925,0.457,2.696c-0.228,0.832-1.162,1.496-2.287,1.206c-0.117-0.03-0.189-0.258-0.302-0.277
				c-1.006-0.171-1.854,0.108-2.056,1.185c-0.197,1.051,0.309,1.622,1.457,1.81c1.91,0.312,3.738,0.686,5.409,1.994
				c2.39,1.87,2.546,2.465,0.582,4.557c-1.308,1.394-2.286,3.095-3.382,4.617c0.172,1.279,1.269,1.696,1.859,2.493
				c0.948,1.28,0.616,3.654-0.915,4.089c-2.812,0.799-4.257,3.105-6.219,4.849c-0.258,0.229-0.679,0.403-0.604,0.882
				c1.401,3.387,0.997,4.195-3.272,6.548c-1.415-0.324-2.04-2.24-3.877-1.821c-0.827,0.188-1.319,0.572-1.79,1.217
				c-1.532,2.104-1.167,3.373,1.896,6.046c1.203-0.444,2.239-1.711,3.768-1.267c0.874,0.255,1.05-0.528,1.485-0.908
				c2.323-2.022,2.43-2.025,4.771,0.001c0.268,0.232,0.591,0.401,0.889,0.601c1.44-0.104,2.695-0.691,3.868-1.489"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M360.071,154.967c-0.025,0.595,0.404,1.159,0.009,1.791c-2.065,3.304-0.835,6.716-0.313,10.114
				c0.595,3.888,2.609,7.244,4.268,10.667c1.916,3.958,3.461,8.076,4.22,12.274c0.917,5.073-0.262,10.081-3.712,14.263
				c-0.262,0.318-0.563,0.306-0.899,0.292"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M366.023,181.155c1.204-1.25,1.84-2.802,2.053-4.468c0.204-1.599,1.628-2.626,1.794-4.165c0.192-1.775-1.738-2.647-1.988-4.176
				c-0.125-0.767-0.214-1.722-0.701-2.017c-2.643-1.598-4.605-4.223-7.704-5.114"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M473.158,509.106c1.866,0.235,3.585,0.833,4.743,2.395c0.791,1.067,1.786,1.227,2.995,1.195c2.379-0.062,4.761-0.019,7.142-0.019
				c1.289,0,2.593,0.119,3.865-0.03c1.542-0.183,2.052,1.819,3.574,1.519"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M399.056,61.224c-1.25,0.618-2.425,0.332-3.76-0.389c-0.179-1-0.256-2.091,0.229-3.28c-1.187-2.072-3.44-2.038-5.399-2.57
				c-0.593-0.161-1.005,0.228-1.509,0.548c-1.731,1.104-2.042,0.986-3.242-0.861c-0.528-0.813-0.996-1.576-2.085-1.808
				c-1.249-0.265-0.144-1.725-1.149-2.421c-1.098-0.759-1.867-0.58-2.719,0.076c-1.932,1.489-1.916,1.508-3.875-0.009"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M447.862,186.81c-0.49-1.551-2.717-2.884-0.595-4.762c-0.447-1.085-0.681-2.095-2.013-2.81c-1.064-0.571-2.208,0.047-3.003-0.71
				c-0.609-0.679-0.367-1.453-0.311-2.132c0.087-1.048-0.677-1.473-1.218-2.089c-0.48-0.546-1.449-0.064-1.791-0.889
				c-0.967-2.33-2.629-1.927-4.469-1.21c-1.752,0.682-3.564,1.209-5.35,1.804"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M444.291,205.261c1.103,0.557,1.822,1.547,2.678,2.382c1.842,1.798,3.355,1.973,5.649,0.583c0.538-0.326,0.973-1.066,1.5-1.114
				c0.817-0.074,1.904-0.536,2.401,0.817c0.426,1.159,1.086,2.232,1.624,3.309c2.487-0.47,2.487-0.47,2.516-3.618
				c1.204-1.189,2.529-1.08,3.862-0.267c0.823,0.502,1.415,0.328,2.11-0.285c0.607-0.537,0.866-1.33,0.524-1.77
				c-0.83-1.068-1.038-2.078-0.554-3.316c0.487-1.246,1.541-1.513,2.675-1.826c0.471-0.13,0.806-0.75,1.188-1.131
				c-0.419-1.09-0.749-2.064-2.372-1.85c-2.196,0.29-4.002-1.518-6.243-1.523c-0.397-0.001-1.121-0.77-1.236-1.695
				c-0.224-1.792-1.438-2.664-3.221-3.017c-1.695-0.335-3.241-1.346-4.658-0.856c-1.502-1.093,0.142-1.284-0.005-2.104
				c-0.196-1.094-0.29-2.164-1.316-2.613c-0.934-0.409-1.49,0.334-2.108,1.107c-1.09,1.363-2.316,2.769-4.419,2.423
				c-0.329-0.054-0.625-0.017-0.901,0.283c-1.311,1.421-3.104,2.12-4.649,3.141c-1.366,0.902-3.4,1.852-3.469,4.306
				c-0.032,1.172-1.872,1.388-2.605,2.41c-0.035,1.299,1.598,1.5,1.798,2.598c-1.043,3.122-3.95,4.24-6.261,6.166
				c-1.15-0.622-2.097-1.743-3.554-1.352c-0.562,0.151-0.998-0.185-1.168-0.602c-0.843-2.071-2.879-1.801-4.521-2.401
				c-0.631,1.189-0.247,2.458,0.392,3.255c1.376,1.718,1.832,3.784,2.562,5.723c0.545,1.446,1.364,1.879,2.736,1.766
				c1.816-0.151,1.817-0.146,3.273,0.891c1.943-1.383,3.236-1.555,4.464-0.595c1.171,0.915,1.452,2.344,0.576,3.557
				c-0.487,0.673-1.171,1.204-1.793,1.827c0.501,0.878,1.048,1.744,1.506,2.655c0.896,1.777-0.241,3.742-2.029,3.136
				c-1.474-0.499-2.915,0.821-4.234-0.438c-1.03-0.983-2.72,0.151-3.939-1.125c-0.489-0.514-2.162-0.384-2.589,1.1
				c-0.384,1.332-1.345,1.929-2.671,1.763c-0.88-0.11-1.221,0.692-1.807,0.854c-1.044,0.291-2.259,0.959-3.258-0.233
				c-0.791-0.944-1.753-0.738-2.67-0.287c-1.149,0.565-0.958,1.688-0.883,2.665c0.165,2.126-0.03,2.771-1.821,3.194
				c-0.657,0.156-1.136,0.676-1.766,0.66c-2.183-0.057-3.939,0.775-5.106,2.555c-3.185-0.559-3.595-4.113-6.203-4.92"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M376.141,363.284c-0.319,0.992-0.27,1.927-0.054,2.987c0.641,3.145,1.24,6.3,1.587,9.507c0.363,3.351,0.698,6.757,2.336,9.826"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M322.276,172.228c1.425,0.815,2.399,2.179,3.835,3.032c1.058,0.629,1.883,1.694,2.426,2.914c2.33,5.233,5.452,9.909,9.426,13.233
				c0.343,8.102,0.945,15.734,0.681,23.377"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M507.381,298.408c1.336-0.975,2.593-2.073,4.166-2.678c0.804,0.343,1.634,0.769,2.055,1.504c1.033,1.806,2.587,2.266,4.493,2.069
				c0.196-0.02,0.464-0.087,0.585,0.01c2.394,1.907,5.881,2.41,7.397,5.372c0.838,1.636,2.262,2.774,3.08,4.411
				c0.516,1.032,1.658,1.5,2.924,1.528c2.764,0.062,4.742,2.364,7.436,2.7c0.232,0.029,0.437,0.4,0.595,0.256
				c1.276-1.167,2.149-0.413,2.982,0.601c0.928,1.128,1.517,2.456,1.114,3.848c-0.183,0.635-0.167,1.214-0.204,1.808
				c-0.074,1.192-0.336,1.979-1.798,2.141c-1.017,0.113-1.972,0.783-2.98,1.215c-0.028,1.014-0.524,2.489-0.167,3.114
				c0.742,1.299,0.869,2.568,1.154,3.924c0.148,0.71,1.29,1.213,1.981,1.808c2.786-1,4.031-0.221,4.767,2.974"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M362.154,44.856c-1.472,3.038-3.593,5.796-4.149,9.228c-0.433,2.666-1.08,5.281-0.104,8.063c0.496,1.413,0.192,3.152,0.064,4.729
				c-0.233,2.857,0.762,5.414,2.164,7.704c1.772,2.896,3.051,5.774,3.3,9.255c0.234,3.276,0.965,6.498,0.591,9.836
				c-0.072,0.64,0.403,1.828,1.414,2.044c0.892,0.19,2.016,0.142,2.327-0.877c0.402-1.317,1.211-2.022,2.413-1.714
				c1.318,0.336,2.939,0.665,3.506,2.059c0.312,0.768,0.403,1.639,1.274,1.704c0.886,0.066,1.91,0.431,2.721-0.506
				c0.167-0.193,0.542-0.448,0.793-0.595c1.615,1.185,3.248,2.317,4.808,3.541c2.604,2.042,2.513,3.363-0.288,5.35
				c-0.113,0.08-0.2,0.196-0.248,0.244c-0.436,2.027,1.05,2.756,2.429,3.689c-0.782,1.982-2.944,2.439-4.266,3.802"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M311.86,174.311c-0.402,0.943,0.622,1.531,0.633,2.305c-0.504,0.95-1.154,0.697-1.825,0.375c-0.78-0.374-1.226,0.287-1.826,0.615
				c0.095,1.193,1.246,2.225,0.221,3.789c-1.433,0.501-3.349,1.086-4.074,3.324c-0.516,1.593-0.042,4.132,1.225,5.056
				c0.543,0.396,1.143,0.183,1.784,0.043c2.667-0.584,2.677-0.542,5.052,1.16c2.511-0.944,3.165-0.973,3.781,0.921
				c0.656,2.017,2.002,2.849,3.576,3.958c1.287,0.907,2.708,1.603,3.952,2.561"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M219.605,413.578c-0.042,1.705,0.168,3.354-0.52,5.089c-0.925,2.329-0.33,4.8,0.534,7.107c0.774,2.065,0.835,2.055-1.207,3.572
				c-1.11,0.826-1.226,2.149-1.421,3.289c-0.232,1.353-0.469,2.937,1.126,3.857"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M334.478,65.688c-1.835,0.659-2.107,2.643-3.273,3.869c-0.496,0-1.171,0.209-1.457-0.036c-1.262-1.082-2.475-0.682-3.589,0.056
				c-2.4,1.589-4.652,0.993-6.844-0.34c-0.731-0.444-1.482-0.954-1.536-2.058c-0.043-0.895-0.705-1.613-1.129-2.397
				c-0.487-0.902-1.314-1.525-2.083-1.123c-2.966,1.55-5.253-0.569-7.741-1.321c-1.064-0.322-1.957-0.625-3-0.22"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M521.667,621.003c1.846-0.846,3.114-2.46,5.014-3.689c-1.502-2.834-2.646-5.833-3.887-8.783c-1.163-2.765-3.401-4.731-6.539-6.307
				c0.153,0.924-0.113,1.465,0.34,2.123c3.268,4.744,4.806,9.992,4.466,15.763c-0.013,0.204,0.011,0.397,0.309,0.596"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M356.797,73.425c3.159,3.716,3.17,8.322,3.545,12.799c0.157,1.872,0.025,3.768,0.026,5.652c0.001,0.75-0.124,1.537,0.595,2.083"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M351.441,571.602c0.198,0.1,0.354-0.042,0.602-0.291c3.487-3.5,3.507-3.949,0.291-8.041c-0.595,0-1.208-0.092-1.782,0.016
				c-2.28,0.429-3.101,1.896-2.384,4.15c0.563,1.773,1.035,3.608,2.976,4.464"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M33.31,201.689c-4.067-8.035-8.101-16.088-12.246-24.083c-0.498-0.96-0.381-2.558-2.039-2.701"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M33.31,440.957c-4.067-8.035-8.101-16.088-12.246-24.082c-0.498-0.961-0.381-2.558-2.039-2.702"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M256.507,656.119c-1.933-0.653-3.868-1.247-5.952-1.2c-3.263,0.072-5.092,1.289-6.229,4.48c-0.8,2.244-1.906,4.405-2.103,6.838"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M33.31,321.323c-4.067-8.035-8.101-16.088-12.246-24.083c-0.498-0.96-0.381-2.558-2.039-2.701"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M305.313,226.39c-0.003-0.995,0.495-1.534,1.487-1.477c1.106,0.064,1.393-0.849,1.752-1.517c0.469-0.87-0.49-2.292-1.438-2.009
				c-2.45,0.731-5.041,0.205-7.479,1.071c-0.796,0.283-1.167,0.858-1.778,1.262c-0.826-0.433-1.687-0.833-2.094-1.764
				c-0.074-0.229,0-0.498,0.327-0.614c1.967-0.697,3.109-2.467,4.737-3.611c1.422-1,1.294-2.945-0.3-3.475
				c-1.635-0.543-2.534-1.061-2.465-3.047c0.036-1.027-2.187-1.843-3.127-1.135c-1.652,1.245-2.588,0.29-2.926-0.969
				c-0.317-1.179-1.153-1.297-1.876-1.759c-0.867-0.554-1.661-0.512-2.384,0.291c-0.469,0.521-0.983,1-1.484,1.492
				c-1.16,1.138-2.038,1.226-3.268,0.292c-0.332-0.252-0.563-0.642-0.905-0.875c-0.77-0.523-1.579-0.497-2.374-0.011
				c-0.703,0.429-0.835,1.21-0.56,1.769c1.172,2.379,0.429,3.959-1.812,5.092c-0.413,0.208-0.646,0.604-0.621,1.176
				c0.093,2.093-0.324,4.3,1.509,5.961c1.187-0.697,2.377-1.007,3.586,0.006c-0.168,0.569-0.633,0.992-0.866,1.493
				c-0.379,0.814-0.379,1.681-0.135,2.679c0.29,1.187,0.667,1.566,1.85,1.862c0.829,0.207,1.907,0.71,1.917,2.282
				c0.009,1.322,1.483,2.326,1.766,3.853c0.19,1.026,1.733,1.439,2.547,1.103c0.8-0.332,2.036,0.591,2.188-0.887
				c1.523,0.623,2.373,1.748,3.518,2.476c2.523-0.901,2.672-0.882,4.854,0.67c0.541-0.405,1.14-0.802,1.678-1.269
				c0.892-0.773,1.432-1.876,2.984-1.838c1.802,0.044,3.433,0.224,4.759,1.548c1.75,1.747,3.473,3.526,5.655,4.761"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M255.317,214.188c0.397,0,0.794,0,1.189,0c0.085,2.137,0.975,4.077,1.847,5.923c1.341,2.839,1.313,3.347-1.555,4.171
				c-0.464,0.133-0.921,0.151-1.272,0.822c1.606,1.488,1.909,3.32,1.023,5.468c-0.388,0.94-0.795,1.64-1.822,1.445
				c-1.716-0.325-2.702,0.712-3.555,1.827c-1.934,2.53-1.863,2.523-4.506,0.911c-0.55-0.336-0.977-1.016-2.208-0.513
				c-2.41,2.252-2.7,4.958-0.45,7.918"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M19.323,533.808c1.064,0.58,1.286,1.735,1.771,2.686c4.093,8.021,8.149,16.062,12.216,24.098"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M358.583,50.51c-1.435-0.328-2.816-0.93-3.914,0.866c-0.81,1.325-1.824,0.577-2.634-0.271c-0.135-0.79,0.935-1.235,0.518-2.262
				c-2.321-1.227-5.7-1.365-6.267-4.875c-1.116-0.029-2.059-0.126-2.604,1.216c0,0.009,0.034,0.284,0.306,0.576
				c1.64,1.762,2.254,4.306,4.454,5.678c0.729,0.454,0.612,0.641,0.317,2.962c-1.089,0.845-2.18-0.383-3.503,0.106
				c-0.408,1.545-1.202,3.086-3.042,3.742"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M188.655,310.907c0.247-1.453-1.489-2.621-0.587-4.318c1.146-0.43,2.361-0.867,3.562-1.339c2.604-1.025,2.882-1.734,1.699-4.678
				c1.499-1.423,3.273-2.332,5.444-2.464c1.997-0.121,2.348-0.476,2.378-2.378c0.014-0.893,0.002-1.786,0.002-2.742
				c1.992-0.842,4.108-1.474,5.699-3.169c0.516-0.549,1.403-0.996,2.336-0.938c0.758,0.047,1.366-0.322,1.774-0.897
				c1.897-2.674,4.521-3.129,7.442-2.342c1.582,0.427,2.972,0.079,4.483-0.283c2.247-0.538,3.16,0.124,4.186,2.321
				c0.145,0.311,0.184,0.605,0.285,0.901c0.401,1.168,1.562,2.038,0.925,3.597c-0.492,1.207-1.109,2.194-2.094,2.647
				c-0.159,1.248,0.755,1.498,1.192,2.157c0.76-0.238,1.539-0.483,2.28-0.715c0.872,0.813,1.428,1.803,0.615,2.699
				c-0.975,1.074-0.827,2.472-0.447,3.258c0.751,1.559,0.097,2.753-0.452,3.898c-1.204,2.516-2.605,5.006-4.762,6.811
				c-1.129,0.945-1,1.749-0.533,2.727c0.905,1.892,1.675,3.88,3.274,5.379c-0.029,1.731-1.327,1.334-2.394,1.499
				c-1.786,0.275-1.99,0.823-1.151,2.75c-0.909,1.227-1.512,2.554-3.603,2.718c-1.386,0.109-2.478,1.648-2.397,3.435
				c0.036,0.792-0.026,1.588,0.012,2.38c0.161,3.405-0.037,3.54-3.573,4.773c-0.328,0.114-0.415,0.663-0.896,0.584"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M223.474,312.396c-0.72-1.303-2.032-1.739-3.242-2.436c-2.532-1.457-5.435-1.366-8.111-2.183c-1.422-0.434-3.442,0.129-4.734,1.628
				c-0.911,1.058-1.889,1.856-3.553,1.883c-1.815,0.03-3.563,0.847-5.061,2"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M664.214,295.73c-1.236-0.022-1.132,1.047-1.5,1.779c-3.948,7.847-7.923,15.68-11.896,23.514c-0.06,0.119-0.193,0.201-0.293,0.3"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M664.214,415.363c-1.236-0.021-1.132,1.047-1.5,1.779c-3.948,7.847-7.923,15.681-11.896,23.515c-0.06,0.119-0.193,0.201-0.293,0.3"
				/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M654.394,151.396c2.811,4.339,4.708,9.154,7.146,13.687c0.822,1.529,1.446,3.143,2.64,4.497c1.405,1.595,1.174,4.451-0.267,6.216
				c-1.314,1.611-2.035,3.546-2.964,5.364c-3.493,6.834-6.952,13.685-10.425,20.53"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M546.367,413.28c-1.878-1.258-3.992-0.614-5.949-0.53c-4.509,0.192-8.96,0.919-13.097,2.911"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M623.444,494.227c-1.775-0.356-2.993,1.255-4.759,1.302c-1.046,0.027-1.322,1.963-0.58,3.151c0.243,0.389,0.661,0.629,0.589,1.203
				c-0.159,1.275-0.015,2.403,1.122,3.341c0.276,0.229,0.325,1.387-0.256,2.033c-0.283,0.476-0.768,0.23-1.176,0.275
				c-0.788,0.088-1.478,0.311-2.1,0.878c-0.736,0.673-0.294,1.097-0.132,1.836c0.153,0.695-1.123,1.474-1.979,2.078
				c-1.213-1.113-1.169-3.118-2.904-3.952c-0.829-0.397,0.252-1.337,0.176-1.71c-0.482-2.343-3.969-3.62-5.804-2.04
				c-0.977,0.842-1.686,0.708-2.738,0.581c-1.564-0.188-3.178-0.151-4.752-0.007c-0.86,0.079-1.407-0.385-2.075-0.641
				c-1.538,0.705-2.245,1.689-2.529,3.554c-0.315,2.079-1.862,3.897-3.085,5.603c-2.38-0.736-4.235,0.065-5.673,1.885
				c-0.371,0.469-0.582,1.012-1.224,1.164"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M570.174,242.163c-0.992,0.595-1.945,1.277-2.993,1.748c-0.495,0.222-1.429,0.294-1.727-0.004c-1.787-1.786-4.667-2.59-5.144-5.606
				c-0.041-0.261-0.403-0.744-0.234-0.871c1.387-1.035-0.034-1.671-0.357-2.392c-0.178-0.396-0.722-0.676-1.156-0.9
				c-3.405-1.75-3.082-5.007-3.238-8.047c-0.021-0.386,0.367-0.792,0.564-1.189c-1.865-2.008-1.865-2.008-3.868-1.19
				c-3.781-3.629-3.798-3.729-1.19-7.142c1.817,0.249,1.817,0.249,3.971-0.788c0.492,0.491,1.088,1.085,1.812,1.809
				c2.026-0.375,3.789-2.125,5.869-3.323c2.177-1.251,4.716-1.609,7.119-0.065c0.685-0.39,1.43-0.698,1.944-1.44
				c0.759,0.569,1.335,0.991,1.901,1.427c1.183,0.911,2.086,1.367,3.921,0.746c3.42-1.158,6.459,0.716,9.177,2.822
				c0.282,0.219,0.309,0.399,0.308,0.598c-0.007,3.208,2.31,2.966,4.451,2.975c0.894,0.004,1.827-0.164,2.667,0.047
				c0.847,0.212,1.571,0.306,2.4-0.026s1.729,0.256,1.705,0.87c-0.063,1.679,1.161,2.197,2.155,2.979"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M507.381,452.563c0.417,0.754,0.392,1.594,0.279,2.378c-0.805,5.604,0.099,10.939,2.399,16.073"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M330.014,518.63c-3.485,1.111-5.615,3.796-7.756,6.532c-2.465,3.15-4.151,6.758-6.229,10.134c-1.416,2.3-2.588,4.65-5.946,4.871
				c-1.783,0.117-2.27,2.4-3.281,3.759"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M654.394,390.663c3.015,4.733,5.059,9.974,7.779,14.856c0.532,0.954,0.384,2.559,2.041,2.702"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M654.394,510.297c3.015,4.733,5.058,9.974,7.779,14.856c0.532,0.955,0.384,2.559,2.041,2.702"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M654.394,271.029c3.015,4.734,5.058,9.974,7.779,14.856c0.532,0.955,0.384,2.558,2.041,2.702"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M623.444,229.366c-1.465,0.059-2.862-0.175-4.169-0.888c-0.869-0.474-1.569-0.872-1.842-2.075
				c-0.764-3.369-3.239-5.067-6.497-5.618c-0.679-0.115-1.174-0.469-1.969-0.717c-1.699,2.011-4.454,2.273-6.676,3.604
				c-2.354,1.41-1.731,4.723-4.346,5.88c-2.643,1.454-3.287-1.114-4.567-2.668c-1.528,0.548-2.592,1.457-2.972,3.075
				c-0.364,1.549-2.181,2.285-2.169,4.168c0.006,0.797-2,0.834-2.878-0.01c-0.911-0.877-0.712-2.199-1.16-3.28
				c-0.403-0.972,0.623-1.448,1.245-2.194c-1.13-1.072-1.137-2.713-1.887-4.055c-1.696,0.137-3.675-0.618-4.604,1.645
				c-0.948-0.901-0.712-1.89-0.752-2.954c-1.778-0.939-3.357-1.643-5.432,0.032c-1.125,0.908-3.092,1.324-4.613,0.041
				c-2.417,2.247-2.431,2.452-0.667,5.124c0.611,0.928,0.676,2.151,1.5,2.966c0.693,0.685,1.272,0.337,1.769-0.304
				c0.509,0.54,1.146,0.975,1.201,1.797c0.1,0.297-0.15,0.221-0.272,0.326c-0.794,0.682-1.895-0.107-2.747,0.825
				c-1.603,1.75-1.74,4.432,0.056,6.577c0.968,1.156,2.385,1.78,3.54,2.712c0.755,0.609,1.655,1.056,2.131,2.046
				c0.689,1.438,0.226,2.336-1.517,2.371c-1.044,0.02-1.947,0.301-2.341,1.23c-0.34,0.8,0.075,1.666,0.57,2.354
				c0.532,0.74,1.178,1.397,1.774,2.09c-1.473,1.387-1.473,1.387-4.613-0.688c-1.021,0.542-1.688,1.639-2.776,2.272
				c-1.344,0.78-2.651,2.003-1.608,4.106c0.388,0.781,0.082,1.814-0.785,2.687c-2.437,2.451-2.178,3.166,1.362,4.187
				c0.119,1.261,0.129,2.472-0.451,3.833c-0.841,1.971,0.124,3.188,2.319,3.556c2.207,0.37,4.013,1.668,5.979,2.614
				c1.336,0.643,2.866,1.516,4.438,0.352"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M273.768,480.24c-2.067-0.548-3.126,1.302-4.389,2.189c-1.972,1.386-2.932,4.008-5.425,4.97c-0.604,0.233-0.494,0.91-0.634,1.463
				c-0.967,3.789-3.038,7.096-5.41,10.058c-2.077,2.592-3.751,5.434-4.519,8.441c-0.868,3.401-2.52,6.442-3.579,9.733
				c-0.682,2.121-2.709,3.863-3.683,6.016c-1.152,2.548-2.495,5.062-2.443,8.019c0.029,1.706-0.821,3.321-1.548,4.717
				c-2.999,5.765-6.405,11.312-11.54,15.497c-1.592,1.297-2.952,2.807-4.445,4.188"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M323.169,169.847c1.551,0.957,3.105,1.896,4.713,2.768c1.813,0.983,3.774,2.062,4.809,4.078c1.207,2.351,3.169,3.738,5.656,4.463"
				/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M340.43,57.058c-2.623,0.14-3.333,1.251-2.081,3.271c0.376,0.607,0.795,1.189,1.181,1.792c0.045,0.07,0.006,0.194,0.006,0.597
				c-0.671,0.778-1.673,1.069-2.324-0.039c-0.758-1.289-1.594-1.307-2.717-0.99c-0.461,3.354,0.389,5.649,3.03,8.236
				c-0.381,1.406-1.555,2.118-2.763,2.575c-2.579,0.976-4.025,2.836-4.749,5.39"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M74.081,422.506c-1.572,1.602-3.571,2.683-4.872,4.69c-0.687,1.06-2.641,1.141-3.758,2.154c0.118,2.375-0.634,4.083-3.269,4.496
				c-0.433,0.068-0.798,0.563-1.195,0.86"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M353.226,196.035c-0.641,0.152-1.22,0.568-1.155,1.187c0.173,1.657-0.485,3.176-0.607,4.767c-0.136,1.763-0.029,3.42-1.094,5.132
				c-0.752,1.21,0.278,3.199-0.715,4.687"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M303.825,556.722c0.932,2.858-0.419,5.555-0.635,8.33c-0.217,2.792-0.777,5.555-1.128,8.339c-0.06,0.476,0.176,0.989,0.275,1.485"
				/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M650.525,262.994c1.273,2.39,2.546,4.78,3.869,7.264c-1.405,1.943-2.228,4.327-3.447,6.381c1.18,2.318,2.265,4.717,3.753,6.884
				c0.433,0.629,0.359,1.138,0.012,1.801c-1.299,2.475-2.521,4.991-3.779,7.508c1.213,2.184,2.461,4.49,3.775,6.757
				c0.38,0.655,0.352,1.143,0.004,1.805c-1.299,2.475-2.521,4.991-3.794,7.538c1.119,1.448,1.477,3.508,2.882,4.951"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M30.037,271.327c1.006,1.678,2.014,3.355,3.04,5.066c-1.558,2.616-2.527,5.512-4.399,8.104c1.443,2.663,2.881,5.316,4.325,7.981
				c-1.378,2.755-2.767,5.533-4.195,8.389c1.384,2.525,2.793,5.093,4.234,7.723c-1.115,1.669-1.598,3.738-3.004,5.293"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M650.525,143.36c1.273,2.39,2.546,4.78,3.869,7.264c-1.405,1.943-2.228,4.327-3.512,6.49c0.498,0.649,0.729,1.542,1.169,2.295
				c1.079,1.845,2.888,3.689,2.751,5.663c-0.135,1.944-1.854,3.755-2.762,5.68c-0.38,0.807-0.66,1.654-1.208,2.368
				c0.548,0.713,0.778,1.606,1.218,2.359c1.079,1.846,2.889,3.689,2.752,5.664c-0.135,1.944-1.854,3.755-2.762,5.68
				c-0.38,0.807-0.66,1.654-1.207,2.367c1.203,1.557,1.561,3.617,2.966,5.06"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M650.525,502.262c1.252,0.865,1.129,2.492,2.028,3.616c0.805,1.006,1.139,2.391,1.733,3.728c-1.056,1.897-2.223,3.994-3.455,6.209
				c1.324,1.841,2.147,4.121,3.506,6.128c0.582,0.859,1.004,1.956,0.121,3.277c-1.371,2.055-2.213,4.448-3.63,6.517
				c0.975,2.379,2.632,4.4,3.645,6.799c0.42,0.995,0.456,1.453-0.007,2.448c-1.093,2.345-2.298,4.61-3.608,6.885
				c0.845,1.679,1.705,3.442,2.941,4.985"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M30.037,510.297c1.013,1.773,2.026,3.547,3.03,5.303c-1.454,2.727-2.636,5.513-4.399,8.11c1.709,2.584,2.653,5.493,4.453,7.86
				c-1.507,2.826-2.689,5.612-4.453,8.21c1.709,2.584,2.653,5.493,4.488,7.907c-1.229,1.84-1.712,3.908-3.119,5.464"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M194.607,522.796c-0.617-0.639-1.754-0.504-2.026-0.238c-1.864,1.825-4.459,1.659-6.648,2.505
				c-2.404,0.931-4.808,1.643-6.865,3.309c-0.306,0.247-0.951,1.001-1.721,0.675"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M30.037,390.961c1.45,1.417,1.605,3.595,3.083,4.866c-0.3,0.592-0.536,0.974-0.696,1.386c-1.004,2.57-3.734,4.812-3.353,7.404
				c0.386,2.622,2.579,4.979,3.966,7.427c-0.717,1.436-1.407,2.826-2.108,4.212c-0.661,1.307-1.232,2.663-2.26,3.903
				c0.77,1.407,1.529,2.763,2.257,4.135c0.687,1.294,1.238,2.66,2.194,3.813c-1.193,1.786-1.676,3.854-3.083,5.41"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M650.525,382.628c1.273,2.391,2.546,4.78,3.837,7.203c-1.117,2.047-2.266,4.152-3.506,6.426c1.206,2.172,2.496,4.553,3.852,6.896
				c0.379,0.655,0.353,1.143,0.003,1.805c-1.265,2.395-2.45,4.831-3.786,7.491c1.138,2.05,2.428,4.431,3.783,6.774
				c0.379,0.655,0.353,1.143,0.003,1.805c-1.265,2.395-2.45,4.831-3.771,7.462c1.169,1.293,1.609,3.385,2.86,5.027"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M263.352,203.772c-0.068,1.627,0.493,3.284-2.051,3.983c-2.025,0.557-2.421,2.297-1.159,4.006c0.977,1.323,1.228,2.229-0.063,3.319
				c-1.169,0.292-1.792-1.234-2.976-0.893"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M387.45,202.582c-3.855,3.171-7.692,6.331-11.072,10.062c-2.347,2.59-5.546,4.202-8.866,5.413"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M167.526,536.485c-0.947,5.062-3.513,9.279-6.493,13.122c-1.354-0.102-0.598-1.229-1.245-1.516"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M316.324,30.571c0.107,1.614,0.015,2.972-1.752,3.931c-1.74,0.944-1.614,1.646-0.362,2.944c0.465,0.483,1.064,0.706,1.532,0.792
				c0.693,0.127,0.44,0.809,0.88,0.964c-0.882,2.198,0.776,5.046-1.539,6.777c-0.741,0.554-0.765,1.361-1.38,1.917
				c-0.4,0.361-0.58,0.856-1.248,0.835c-3.125-0.098-4.985,2.334-7.042,3.987c-1.416,1.138-0.992,3.447-0.103,5.242
				c-0.344,1.406-1.804,2.013-2.379,3.264c0.794,0.397,0.567,1.134,0.596,1.785"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M513.928,426.077c1.531,1.35,3.588,1.353,5.376,2.034c5.239,1.994,9.735,4.984,14.058,8.571c3.67,3.045,7.751,5.718,12.096,7.877
				c1.379,0.685,2.913,1.333,3.612,2.827c-0.196,0.956-1.031,0.688-1.524,0.992c-1.045,0.646-2.549,0.903-1.233,2.735
				c0.454,0.633-0.708,1.513-1.721,1.125c-1.822-0.699-3.554-1.632-5.373-2.339c-3.451-1.343-6.905-2.695-10.426-3.837
				c-1.494-0.483-3.181-0.338-4.742-0.661c-6.351-1.314-11.943-4.202-16.625-8.658c-1.568-1.492-2.829-3.416-3.846-5.346
				c-0.777-1.475-1.518-3.012-2.406-4.623c2.461-2.271,5.153-2.071,8.001-1.643c1.385,0.209,2.656,1.247,4.159,0.646"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M241.627,78.187c-3.456,0.642-6.945,0.141-10.416,0.291c-0.99,0.043-2.016-0.143-2.964,0.062c-0.592,0.127-1.898,0.043-1.351,1.434
				"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M280.315,162.407c0.378,0.964,0.333,1.977,0.289,2.976c-0.106,2.404,1.745,3.901,3.853,2.924c0.696-0.322,1.318-0.814,2.108-0.829
				c1.153-0.021,2.345-0.217,2.732,1.464c0.177,0.768,0.932,1.405,1.435,2.095c1.503,2.064,2.441,2.314,4.741,0.864
				c1.567-0.988,3.218-0.167,4.699-0.68c1.106,1.558,1.97,3.07,1.346,5.574c1.792-1.231,3.092-1.111,3.795-2.185"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M360.963,195.737c-1.943-2.675-2.517-5.929-3.446-8.966c-0.555-1.816-1.365-3.396-2.184-5.033
				c-1.215,0.507-2.367,1.229-3.605,1.438c-1.298,0.22-2.824,0.605-3.864-0.827c-0.279-0.385-0.627-0.766-1.184-0.595
				c-2.585,0.798-4.883-0.047-7.157-1.168c-0.194-0.096-0.576,0.188-0.921,0.314c-0.513,1.185-0.311,2.208,0.611,3.252
				c1.052,1.193,2.513,1.828,3.607,2.944c0.668,0.683,1.552,1.297,1.155,2.381c-0.499,1.364-1.401,2.452-2.954,2.668
				c-0.778,0.108-1.584,0.019-2.378,0.019"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M241.627,83.246c-1.365,0.29-2.582-0.374-3.869-0.594c-3.088-0.527-6.051-1.681-9.224-1.826c-0.41-0.019-0.795-0.557-1.192-0.854"
				/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M400.544,119.255c-0.22,2.58-2.328,2.777-4.172,3.254c-0.546,0.141-1.08,0.101-1.509-0.558c-1.041-1.596-2.644-1.554-4.117-0.862
				c-0.986,0.463-2.254-0.275-2.999,0.845"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M534.76,448.694c0.136,0.589-1.034,1.228,0.279,1.825c2.403,1.095,2.608,3.278,2.418,5.616c-0.145,1.795,0.026,3.573-0.552,5.375
				c-0.562,1.751,0.152,2.452,2.021,2.659c3.566,0.395,5.17,1.999,5.654,5.654"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M333.288,43.368c2.002,3.874,4.767,7.313,6.53,11.316c1.177,2.674,2.906,5.061,4.863,7.051c3.056,3.11,6.134,6.639,11.226,6.333
				c0.742-2.019-0.259-4.288,0.891-6.249"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M345.489,166.275c-1.694,0.978-3.706,1.408-5.062,2.973c-1.104,1.273-1.857,2.538-0.625,4.194c0.24,0.324,0.611,0.835,0.064,1.489
				c-1.413,1.689-0.558,3.73-0.626,5.628"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M318.11,648.68c0.959,3.52,1.779,7.095,2.006,10.718c0.148,2.371,0.92,4.557,1.219,6.847c0.232,1.786,1.865,2.771,2.132,4.457"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M352.929,183.238c0.642,2.646,0.419,5.379,0.481,8.038c0.085,3.626,0.97,6.997,1.88,10.418c0.124,0.466,0.203,0.818,0.588,1.211
				c2.433,2.479,3.027,5.54,2.73,8.905c-0.137,1.548,0.554,2.802,1.292,4.251c1.385,2.719,4.012,3.812,5.839,5.852
				c0.088,0.099,0.383,0.013,0.581,0.013"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M337.156,320.728c0.26,4.367-0.495,8.541-2.381,12.499"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M155.325,290.076c2.144,0.394,3.886,1.752,5.933,2.437c2.933,0.981,3.346,1.612,4.527,4.517c-2.428,2.948-4.682,6.117-7.497,8.806
				c-1.151,1.1-2.073,2.112-0.028,3.539c2.882-2.002,5.646-5.016,9.292-6.812c2.208,0.349,2.861,1.603,3.034,3.876
				c0.446,5.872,1.294,11.713,2.016,17.563c0.087,0.708-0.594,1.391-0.015,2.085"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M355.607,159.729c-2.216-0.644-4.389-0.338-6.547,0.297c-1.167,0.343-1.933,1.044-2.753,2.021c-1.596,1.9-0.795,3.436,0.074,5.122"
				/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M433.875,204.666c0.422,0.264,0.569,0.836,1.188,0.905c2.188,0.242,4.157,0.751,5.423,2.926c0.538,0.925,2.104,0.405,2.575-0.87
				c0.356-0.96,1.087-1.833,0.932-2.961"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M198.179,313.586c-2.892-1.422-6.516-2.165-10.416-2.07c-0.949,0.023-1.479-0.897-2.288-0.969c-2.811,1.9-6.098,2.312-9.021,3.634"
				/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M539.82,418.638c-2.292-1.269-4.782-0.551-7.139-0.473c-1.1,0.037-2.486-0.261-3.615,0.724c-0.759,0.661-1.535,1.087-2.121,2.084
				c-1.444,2.455-2.853,4.92-4.089,7.485"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M193.417,618.324c-3.846-0.648-7.691-1.291-11.606-0.595"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M305.611,545.116c0.27,3.548-0.825,6.94-1.173,10.417c-0.046,0.47-0.348,0.741-0.623,0.868c-2.762,1.27-4.086,3.736-5.352,6.27
				c-0.158,0.318-0.391,0.399-0.589,0.301"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M574.936,508.512c-1.149,1.548-3.353,1.96-4.146,3.913c-1.31-0.479-2.6-0.479-4.003,0.034c-0.949-2.189-3.303-3.011-4.62-4.843
				c-2.396-0.367-4.38,1.572-6.893,0.984c-1.441-0.338-3.347,1.942-3.422,4.082c-0.02,0.578-0.334,0.845-0.428,0.85
				c-1.754,0.082-0.428,2.852-2.678,2.843c-1.287-0.004-2.578,1.263-3.868,1.957"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M426.733,281.445c-4.054,3.424-5.75,7.974-6.222,13.133c1.096,1.466,2.705,1.756,4.436,1.748c1.19-0.006,2.381-0.001,3.571-0.001"
				/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M224.962,556.722c-3.881,2.574-5.633,7.027-8.928,10.119c-0.6,3.431-3.146,5.978-4.193,9.217c-0.327,1.011-0.752,2.067-1.758,2.687
				"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M295.195,358.225c-1.297,0.092-1.783,1.127-2.049,2.093c-0.826,3.001-2.685,5.286-4.796,7.43"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M514.226,442.445c-2.939,2.257-4.938,5.158-6.283,8.617c-1.076,2.77-3.45,3.679-6.216,2.691"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M533.868,437.684c-0.28,0.496-0.204,0.965-0.013,1.492c1.347,3.702,0.583,5.084-3.264,5.937c-0.316,0.07-0.68-0.052-0.891,0.309"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M193.12,600.171c-0.523,1.771-2.111,2.658-3.297,3.847c-3.097,3.104-5.783,6.561-7.604,10.489c-0.946,2.041-2.29,3.761-3.252,5.67
				c-1.325,2.627-2.452,5.449-3.586,8.191c-1.452,3.506-1.585,6.921-1.602,10.49c-0.006,1.294-0.397,2.039-1.733,2.777
				c-2.229,1.23-2.696,3.827-3.495,6.392c0.918,0.883,1.882,1.809,2.845,2.735"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M346.679,85.329c3.501,1.965,5.562,4.889,5.952,8.928"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M94.02,615.646c-0.099-0.1-0.18-0.239-0.3-0.292c-2.215-0.979-7.142-0.818-9.223,0.292"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M324.955,677.844c1.49,2.709-0.58,5.134-0.682,7.734c-0.09,2.278-0.397,4.597-1.057,6.861c-0.967,3.32,0.532,6.535,0.841,9.808
				c0.037,0.394,0.005,0.793,0.005,1.19"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M173.775,157.645c0.823-1.201,1.49-2.489,1.925-3.872c1.537-4.881-0.752-7.266-4.605-8.322c-1.275-0.349-2.577-0.603-3.866-0.901"
				/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M613.92,561.187c1.125,1.322,2.964-0.073,4.19,1.166c1.282,1.296,2.731,2.433,4.164,3.567c0.244,0.193,0.772,0.027,1.169,0.027"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M571.959,615.646c-0.099-0.1-0.18-0.239-0.3-0.292c-2.215-0.979-7.142-0.818-9.223,0.292"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M626.123,615.646c-0.1-0.1-0.181-0.239-0.301-0.292c-2.215-0.979-7.142-0.818-9.223,0.292"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M612.433,615.646c-0.1-0.1-0.181-0.239-0.301-0.292c-2.215-0.979-7.142-0.818-9.223,0.292"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M175.561,149.312c-3.174,0.992-6.349,1.984-9.523,2.976"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M475.539,700.759c0.529,0.355,0.96,0.837,1.451,1.235c2.51,2.034,5.042,2.347,7.774,0.55"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M571.365,621.003c-2.976,1.257-5.952,0.54-8.928,0"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M598.744,615.646c-1.162-1.358-2.748-0.908-4.165-0.854c-1.606,0.062-3.408-0.687-4.763,0.854"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M626.123,620.407c-2.862,1.904-5.883,1.432-8.928,0.596"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M643.383,620.407c2.862,1.904,5.883,1.432,8.928,0.596"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M585.054,615.646c-1.162-1.358-2.748-0.908-4.165-0.854c-1.606,0.061-3.408-0.688-4.763,0.854"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M602.91,620.407c2.862,1.904,5.883,1.432,8.928,0.596"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M66.641,615.646c-1.162-1.358-2.748-0.908-4.165-0.854c-1.606,0.061-3.409-0.688-4.763,0.854"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M107.114,615.646c-1.161-1.358-2.748-0.908-4.165-0.854c-1.606,0.061-3.409-0.688-4.763,0.854"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M80.33,615.646c-1.162-1.358-2.748-0.908-4.165-0.854c-1.606,0.062-3.409-0.687-4.763,0.854"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M365.428,66.581c-0.742,1.195-1.674,2.3-1.503,3.87c0.087,0.79-0.674,1.224-1.166,1.133c-1.14-0.209-1.933,0.757-2.985,0.651"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M519.583,141.575c-0.991,0-1.985,0.037-2.976-0.008c-1.623-0.074-3.159,0.079-4.486,1.171c-0.129,0.106-0.374,0.027-0.275,0.325
				c-4.393,0.009-5.546,3.701-7.431,6.553c-0.167,0.253-0.208,0.589-0.307,0.887"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M23.192,373.7c0.496,2.381,0.971,4.767,1.501,7.14c0.159,0.713,0.22,1.312,0.06,2.101c-0.467,2.291-1.011,4.561-1.561,6.83"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M52.952,621.003c-2.778,0.969-5.555,0.969-8.333,0"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M93.424,621.003c-2.777,0.969-5.555,0.969-8.333,0"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M422.566,146.932c-0.1,1.965,0.086,3.947,2.395,4.395c1.436,0.278,1.821,1.396,2.665,2.152"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M23.192,314.181c0.55,2.27,1.093,4.54,1.561,6.83c0.161,0.788,0.1,1.388-0.06,2.101c-0.53,2.373-1.005,4.758-1.501,7.139"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M23.192,194.547c0.537,3.268,2.044,6.41,1.462,9.816c-0.359,2.106-0.967,4.171-1.462,6.254"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M660.643,314.181c-0.595,2.679-1.654,5.357-1.654,8.035c0,2.679,1.06,5.357,1.654,8.035"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M660.643,194.547c-0.595,2.679-1.654,5.357-1.654,8.035c0,2.679,1.06,5.357,1.654,8.035"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M23.192,493.334c1.69,5.356,2.049,10.714,0,16.07"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M226.45,79.229c-2.678-0.248-5.357-0.496-8.035-0.744"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M30.037,151.693c1.128,1.606,2.023,3.35,2.875,5.262c-1.288,2.575-2.676,5.352-4.092,8.183c1.356,2.557,2.729,5.146,4.136,7.799
				c-1.332,2.663-2.72,5.44-4.109,8.218c1.32,2.616,2.557,5.278,4.256,7.711c-1.176,1.76-1.659,3.828-3.065,5.383"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M23.192,433.814c0.55,2.271,0.986,4.577,1.593,6.816c0.287,1.06-0.044,1.858-0.165,2.698c-0.319,2.208-0.933,4.373-1.428,6.556"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M210.083,253.471c1.772,0.823,3.665,1.325,5.408,2.289c0.813,0.45,1.625,0.956,1.976,1.61c1.322,2.467,3.17,1.864,5.116,1.16"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M660.643,433.814c-0.595,2.679-1.654,5.357-1.654,8.035c0,2.679,1.06,5.356,1.654,8.035"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M660.643,553.448c-0.099,0.596-0.103,1.227-0.312,1.78c-1.748,4.613-1.228,9.163,0.312,13.695"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M388.045,185.917c0.488,0.511,0.91,1.066,1.788,0.905c2.086-0.383,3.595,0.391,4.471,2.364c0.2,0.45,0.895,0.566,0.883,1.194"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M660.643,493.334c-0.595,2.679-1.654,5.356-1.654,8.035s1.06,5.356,1.654,8.035"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M23.192,135.028c0.55,2.27,1.137,4.528,1.544,6.835c0.117,0.66,0.115,1.146-0.001,1.805c-0.404,2.308-0.993,4.565-1.543,6.835"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M660.643,374.296c-0.496,2.083-0.847,4.217-1.549,6.228c-0.354,1.015-0.048,1.841,0.1,2.693c0.382,2.202,0.953,4.371,1.449,6.554"
				/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M66.046,621.003c-2.58,0.932-5.159,0.932-7.738,0"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M79.735,621.003c-2.58,0.932-5.159,0.932-7.738,0"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M23.192,254.662c0.55,2.27,1.137,4.528,1.544,6.835c0.117,0.66,0.115,1.146-0.001,1.805c-0.404,2.308-0.993,4.565-1.543,6.835"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M38.667,614.753c-1.786,0-3.573,0.039-5.356-0.016c-0.951-0.028-1.759,0.153-2.381,0.909"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M660.643,135.028c-0.496,2.083-0.847,4.217-1.549,6.228c-0.354,1.015-0.048,1.841,0.1,2.693c0.382,2.202,0.953,4.371,1.449,6.554"
				/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M584.458,621.003c-2.579,0.932-5.158,0.932-7.737,0"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M660.643,254.662c-0.496,2.083-0.847,4.217-1.549,6.228c-0.354,1.015-0.048,1.841,0.1,2.693c0.382,2.202,0.954,4.371,1.449,6.554"
				/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M23.192,553.448c0.55,2.27,1.137,4.528,1.544,6.835c0.117,0.66,0.115,1.147-0.001,1.806c-0.404,2.307-0.993,4.564-1.543,6.835"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M39.262,621.003c-2.579,0.932-5.159,0.932-7.738,0"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M598.148,621.003c-2.579,0.932-5.158,0.932-7.737,0"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M643.383,615.646c2.202-1.724,4.766-0.561,7.143-0.891c0.946-0.132,1.758,0.135,2.38,0.891"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M203.535,522.201c-2.598-0.402-5.151,0.385-7.737,0.297"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M638.622,621.003c-2.579,0.932-5.158,0.932-7.737,0"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M665.703,615.646c-2.566-1.259-6.271-1.259-8.63,0"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M428.518,287.992c-1.338,0.696-2.605,1.384-3.638,2.623c-0.785,0.942-2.008,1.784-3.505,1.544"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M266.774,476.966c-0.859,0.286-1.337,1.202-1.271,1.778c0.315,2.78-1.069,5.319-0.96,8.043"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M664.81,621.003c-2.381,0.96-4.762,0.96-7.143,0"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M19.025,614.753c1.587,0,3.176,0.041,4.761-0.015c0.951-0.034,1.759,0.152,2.381,0.908"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M25.573,621.003c-2.381,0.96-4.762,0.96-7.143,0"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M517.797,257.042c1.589,1.806,0.691,3.743,0.298,5.654c-0.102,0.496-0.199,0.992-0.298,1.488"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M217.522,257.935c-2.253,0.755-4.575-0.079-6.845,0.298"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M392.211,202.88c0.042,0.847-0.604,1.077-1.164,1.521c-1.759,1.397-3.398,2.942-5.085,4.43"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M540.415,442.147c0.015,0.335-0.271,0.821-0.25,0.873c0.939,2.256-0.254,3.385-2.129,4.189c-0.407,0.175-0.796,0.394-1.192,0.592"
				/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M309.479,485.597c0.931,1.009,1.1,2.348,1.46,3.579c0.335,1.146,0.939,1.857,2.111,2.075"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M382.391,206.153c-0.704-0.82-1.562-0.91-2.365-0.278c-0.768,0.603-1.404,1.369-2.271,2.235c-1.769-1.295-3.263-3.756-6.078-2.254"
				/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M426.137,98.126c-1.053,2.854,0.888,4.619,2.381,6.547"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M210.678,412.388c0.536-1.341,1.131-2.995,0.488-4.104c-0.92-1.586-2.06-3.295-4.059-3.934c-0.807-0.259-1.405-1.08-2.382-0.89"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M371.379,50.808c-0.537-1.531-0.447-3.019,0.268-4.495c-2.001-2.041-2.001-2.041-6.563,0.954c1.179,1.517,0.817,2.691-0.926,3.657
				c-1.164-0.613-2.352-1.352-3.789-1.009"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M480.895,86.817c-2.182,0.297-4.364,0.595-6.547,0.893"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M220.499,643.025c-1.321,2.465-3.306,4.416-5.06,6.547"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M304.718,226.688c1.709-0.103,2.548,1.39,2.868,2.415c0.771,2.467,2.626,3.571,4.572,4.726c1.186,0.703,2.38,1.391,3.57,2.085"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M219.01,259.423c-1.236,2.095-3.328,3.09-5.356,4.166"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M511.845,252.281c1.26-0.068,2.04,1.055,3.259,1.271c0.959,0.17,1.436,1.333,1.801,2.3"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M581.483,164.192c4.214,1.409,6.555,5.16,4.762,8.928"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M511.845,143.36c0.8,1.671,2.381,2.434,3.884,3.246c0.44,0.238,0.821,0.382,0.878,0.921"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M104.733,622.193c-0.858-0.869-1.957-0.535-2.976-0.595c-1.02-0.061-2.118,0.273-2.976-0.596"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M209.487,87.71c-1.984-0.298-3.968-0.595-5.952-0.893"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M204.726,521.903c2.568-0.401,5.151-0.331,7.738-0.323c1.061,0.003,2.004,0.318,2.976,0.621"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M337.454,106.161c-0.196,1.9,0.836,3.995-0.893,5.654"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M53.546,615.646c-0.958-1.228-2.328-0.937-3.57-0.869c-2.003,0.109-4.165-0.734-5.953,0.869"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M138.064,68.068c1.557,0.727,3.461,0.02,4.911,1.19"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M520.178,255.852c-1.155,1.388-2.812,0.015-4.211,0.816c-1.174,0.672-2.733,0.672-4.122,0.97"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M169.609,138.599c0.696-5.274-1.316-6.964-5.952-8.333"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M419.59,295.432c-1.22,1.671-3.222,0.84-4.762,1.488"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M338.942,215.379c0.961,1.732,1.072,3.358-0.595,4.762"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M525.237,414.769c1.754,0.972,2.162,2.75,2.679,4.464"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M497.263,260.911c0.33-1.176,1.76-1.497,2.257-2.713c-2.158-1.797-4.142-0.282-6.126,0.035"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M399.354,60.629c0.859,2.702,2.2,5.669,4.45,6.871c0.396,0.212,0.608,0.768,0.906,1.164"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M639.216,615.646c-2.565-1.369-6.361-1.369-8.928,0"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M93.127,185.619c0.016,1.744-1.18,2.882-2.083,4.167"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M370.189,252.579c0.831,1.633,3.011,1.672,3.869,3.273"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M180.918,147.527c-1.455,0.312-2.838,0.822-4.167,1.488"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M93.424,184.429c-0.77-1.436-2.219-1.513-3.571-1.786"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M371.975,153.181c-0.1,0.496-0.03,1.163-0.328,1.457c-1.04,1.026-0.484,1.708,0.328,2.412"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M87.473,117.172c1.414,0.425,2.956,0.686,3.273,2.53"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M499.644,257.935c1.35-0.328,2.041,0.681,2.636,1.518c0.498,0.7,1.153,1.399,1.232,2.351"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M168.121,132.944c-1.289,0.496-2.579,0.992-3.869,1.488"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M613.028,399.889c1.396-0.207,2.218,0.46,2.348,1.789c0.076,0.78,0.67,1.166,0.926,1.782"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M585.054,167.168c-1.245,0.08-2.236,1.171-3.571,0.893"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M360.963,182.941c-1.189,0.694-2.38,1.389-3.57,2.083"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M92.234,125.505c0.099-0.496,0.415-1.079,0.258-1.472c-0.527-1.317,0.092-2.761-0.689-4.182c-1.627-0.108-3.193,0.956-4.925,0.893"
				/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M95.805,183.833c-2.186,0.784-4.245,1.916-6.547,2.381"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M495.775,265.375c0.298,0,0.604,0.046,0.892-0.007c2.267-0.42,4.14-0.033,5.061,2.388"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M308.884,232.044c-1.078,0.724-1.53,1.884-2.083,2.976"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M498.751,265.673c0.104,0.981-1.062,1.235-1.19,2.083"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M654.989,617.432c0,0.396,0,0.794,0,1.19"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M641.299,617.432c0,0.396,0,0.794,0,1.19"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M628.206,617.432c0,0.396,0,0.794,0,1.19"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M614.516,617.432c0,0.396,0,0.794,0,1.19"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M600.827,617.432c0,0.396,0,0.794,0,1.19"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M574.043,617.432c0,0.396,0,0.794,0,1.19"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M96.103,617.432c0,0.396,0,0.794,0,1.19"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M82.414,617.432c0,0.396,0,0.794,0,1.19"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M55.63,617.432c0,0.396,0,0.794,0,1.19"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M41.94,617.432c0,0.396,0,0.794,0,1.19"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M215.737,528.748c-0.893,0.396-1.915,0.632-2.655,1.22c-2.454,1.95-4.015,4.415-3.897,7.708c0.072,2.034-0.722,3.952-0.869,5.953
				c-0.093,1.256-0.125,2.555,0.868,3.59c2.135-0.077,2.752-1.573,2.965-3.295c0.398-3.217,1.495-5.971,4.147-8.079
				c1.162-0.924,2.084-2.257,2.414-3.824c0.614-2.926,0.395-3.185-2.675-3.272"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M465.42,575.769c-1.278,0.762-1.391,1.918-0.266,2.628c2.6,1.642,3.093,4.284,3.855,6.89c1.091,3.728,2.792,7.246,4.529,10.676
				c2.111,4.172,6.459,5.529,10.654,6.479c1.67,0.378,2.889,1.032,3.796,2.23c0.628,0.83,0.828,1.975-0.537,2.661
				c-0.556,0.279-0.952,0.875-1.508,1.151c-1.534,0.763-1.73,2.083-1.796,3.59c-0.137,3.154,0.162,6.002,2.296,8.714
				c1.398,1.779,2.043,4.267,1.907,6.763c-0.13,2.401,1.161,4.329,2.371,6.244c1.77,2.801,4.666,4.216,7.464,5.596
				c1.858,0.915,3.979,0.361,5.596-0.866c2.922-2.217,6.138-3.788,9.553-5.018c1.561-0.562,2.371-1.584,2.075-3.277
				c-0.594-3.395-0.824-6.941-3.571-9.518c-1.392-1.305-1.448-3.161-2.05-4.778c-1.057-2.84-1.961-5.732-3.593-8.326
				c-1.255-1.995-2.909-3.084-5.365-2.691c-0.677,0.108-1.405-0.087-2.076,0.038c-1.365,0.257-2.298-0.362-2.627-1.525
				c-0.256-0.905-0.255-1.995,0.829-2.675c1.328-0.834,2.398-1.964,3.28-3.262c0.806-1.187,0.859-2.425-0.012-3.561
				c-1.424-1.853-2.354-3.995-3.604-5.932c-1.903-2.949-4.905-4.39-8.286-5.093c-3.276-0.681-6.561-1.329-9.817-2.094
				c-4.113-0.966-7.979-2.591-11.591-4.778c-0.437-0.264-0.803-0.207-1.209-0.267"/>
			
				<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M345.191,538.568c-5.843,0.538-9.647,4.149-12.767,8.651c-1.399,2.018-1.547,4.483-1.172,6.816c0.555,3.45,5.612,5.724,8.501,3.475
				c3.044-2.37,6.373-3.777,9.906-4.945c3.366-1.113,4.297-3.568,4.225-6.855c-0.027-1.283-0.67-2.168-1.302-2.935
				c-1.825-2.216-4.097-3.873-7.093-4.207"/>
			</g>
			</svg>
		</div>
	)
}