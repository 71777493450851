import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { API_URL } from '../../GlobalConfig'
import ColoredInImage from '../Images/ColoredInImage'
import LoadingSpeaner from '../LoadingSpeaner'

function MyGalary() {

    const token = localStorage.getItem("token")
    const LocalUser = localStorage.getItem("user")
    const [user, setUser] = useState({})
    const [myGalary, setMyGalary] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetch(`${API_URL}/user_fill_image`, {
            method: 'GET',
            headers: {Authorization: `Bearer ${token}`}
        })
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            // console.log("edited images", data)
            setLoading(true)
            setMyGalary(data)
        })
        .catch((err) => console.log(err))

        if(LocalUser) {
            setUser(JSON.parse(LocalUser))
        }
    }, [])

    // console.log("user", user.name)

    if(!loading) {
        return(
            <LoadingSpeaner />
        )
    }

    if(myGalary.length === 0) {
        return(
            <h2 className = "text-dark mt-5">No images yet!</h2>
        )
    }

    // console.log("galary data", myGalary)
    return (
        <div className = 'container'>
            <h2 className = 'm-4 text-dark'>{user.name}'s Gallery</h2>
            <div className = 'row'>
                {myGalary.map((item) => {
                    return(
                        <div className = 'col-md-4 col-sm-12 col-xs-12'>
                            <div className = 'card cardHover shadow m-3 text-center' style = {{width: '20rem'}}>
                                <div className = 'card-body'>
                                    <ColoredInImage component = {item.component} onFill = {() => {}} fillColors = {JSON.parse(item.fill_colors)}/>
                                    <Link to = {
                                        {
                                            pathname: '/featuredimage',
                                            state: item
                                        }
                                    }>
                                        <button type="button" className="btn btn-outline-primary mt-3 col-12">View Image</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )})}
            </div>
        </div>
    )
}

export default MyGalary
