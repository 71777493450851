import React from 'react';
import './Everglades.css';

export default function Everglades(props) {
    
    return (
        <div className={`everglades-container ${props.size}`}>
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id}>
            <g className="everglades-image">
 
            <path d="M0.59,0.61c387.3,0,774.61,0,1161.91,0c0,499.63,0,999.26,0,1498.89c-387.3,0-774.61,0-1161.91,0
                C0.59,999.87,0.59,500.24,0.59,0.61z M101.51,478.31c0.3,0.32,0.61,0.65,0.91,0.97c0.71,0.33,1.41,0.65,2.12,0.98
                c0.66,0.33,1.32,0.67,1.98,1c0.66,0.33,1.33,0.65,1.99,0.98c6.29,3.36,12.05,7.43,14.33,14.54c1.8,5.63,4.34,10.27,9.76,13.2
                c1.02,0.55,1.28,3.02,1.38,4.63c0.07,1.23-0.88,2.49-1,3.77c-0.67,6.93-0.95,7.25-7.47,7.56c-2.9,9.87,6.32,18.87,17.19,16.53
                c0.12-0.89,0.38-1.86,0.36-2.83c-0.22-12.29,3.3-23.48,8.94-34.33c3.89-7.49,6.63-15.57,9.91-23.38c1.81-4.31,0.32-7.23-3.99-9.57
                c-4.67,10.39-11.1,19.15-19.93,26.83c-0.54-1.58-0.92-2.32-1.05-3.11c-1.42-8.51-2.95-17-4.14-25.54
                c-0.61-4.42-2.92-5.43-6.89-4.77c-3.78,0.63-5.47,2.07-4.55,6.36c0.98,4.58,1.05,9.35,1.62,15.17
                c-10.41-3.24-12.8-11.98-17.55-18.69c0.75,3.34,2.39,6.16,3.47,9.18c0.46,1.28-0.25,2.97-0.43,4.48c-0.64-0.35-1.28-0.7-1.93-1.05
                c-0.66-0.34-1.32-0.67-1.98-1.01c-0.71-0.33-1.41-0.65-2.12-0.97c-0.3-0.32-0.61-0.65-0.91-0.97c-0.32-0.34-0.64-0.68-0.96-1.02
                c-0.71-0.34-1.43-0.68-2.14-1.02c-0.3-0.3-0.59-0.61-0.89-0.91c-0.33-0.33-0.66-0.65-0.99-0.98l-0.06-0.09
                c0,0-0.01,0.16-0.02,0.15c0.33,0.33,0.67,0.65,1,0.97c0.3,0.3,0.6,0.6,0.9,0.91c0.71,0.34,1.43,0.68,2.14,1.02
                C100.86,477.63,101.19,477.97,101.51,478.31z M672.96,474.26L672.96,474.26C672.96,474.26,672.96,474.26,672.96,474.26z
                M976.67,1438.63c0.32-0.33,0.64-0.66,0.96-0.98l0.06-0.11c0,0-0.13,0.04-0.12,0.03c-0.32,0.33-0.63,0.67-0.95,1
                c-0.31,0.32-0.62,0.64-0.93,0.96c-1,0.67-1.99,1.35-2.99,2.02c-0.34,0.34-0.67,0.68-1.01,1.02c-1.52,0.99-3.25,1.77-4.54,3
                c-17.07,16.25-34.08,32.55-51.07,48.88c-0.82,0.79-1.24,2-2.23,3.67c6.68,0,12.49,0.22,18.25-0.15c1.57-0.1,3.74-1.66,4.43-3.12
                c9.01-19.27,20.74-36.67,35.12-52.31c0.34-0.34,0.68-0.67,1.02-1.01c1.02-0.65,2.04-1.31,3.05-1.96
                C976.04,1439.25,976.36,1438.94,976.67,1438.63z M696.92,185.48l-0.12-0.16l0.15,0.15l0.12,0.2
                C697.08,185.66,696.92,185.48,696.92,185.48z M94.52,471.25l0.11,0.16l-0.15-0.15l-0.07-0.22
                C94.42,471.04,94.52,471.25,94.52,471.25z M699.92,190.47l-0.12-0.17l0.15,0.15l0.12,0.21
                C700.07,190.67,699.92,190.47,699.92,190.47z M852.78,1377.7l0.03-0.12c0,0-0.1,0.06-0.09,0.06c-0.3,0.31-0.6,0.62-0.9,0.93
                c-2.72,1.26-5.43,2.52-8.15,3.78c-0.32-0.36-0.65-0.71-0.97-1.07c0.7-1.56,1.4-3.11,2.11-4.67c0,0-0.01-0.01-0.01-0.01
                c-2.64,0.98-5.28,1.96-7.92,2.93c-1.28-4.61,2.96-6.14,4.98-8.92c0.31-0.32,0.63-0.64,0.94-0.95c0,0-0.06-0.06-0.06-0.06
                c-0.31,0.32-0.62,0.65-0.93,0.97c-3.67,1.79-7.34,3.59-11.01,5.38c-0.51-0.67-1.02-1.34-1.53-2.01c2.87-3.44,5.73-6.89,8.6-10.33
                c0.31-0.3,0.62-0.6,0.92-0.91l0.04-0.12c0,0-0.11,0.05-0.1,0.05c-0.3,0.31-0.6,0.63-0.9,0.94c-4.52,2.23-9.04,4.46-13.55,6.7
                c-0.36-0.53-0.72-1.06-1.07-1.59c1.41-5.58,5.87-9.09,9.61-13.09l0.14-0.13l-0.12,0.14c-4.35,2.43-8.69,4.86-13.04,7.29
                c-0.42-0.52-0.84-1.04-1.26-1.57c1.76-2.24,3.52-4.49,5.28-6.73l0.14-0.13l-0.12,0.14c-3.31,1.66-6.61,3.31-9.92,4.96
                c-0.39-0.5-0.77-1-1.16-1.5c2.02-2.83,4.04-5.65,6.05-8.48c0.67-0.33,1.34-0.66,2.01-0.99l0.16-0.14l-0.13,0.15
                c-0.67,0.33-1.34,0.67-2.01,1c-3.24,1.19-6.48,2.37-9.73,3.56c-0.31-0.48-0.62-0.96-0.92-1.44c1.55-2.04,3.11-4.09,4.66-6.13
                c0.32-0.3,0.64-0.6,0.97-0.91l0.05-0.11c0,0-0.12,0.05-0.12,0.04c-0.3,0.32-0.6,0.65-0.9,0.97c-2.24,0.65-4.47,1.3-6.71,1.96
                c-0.24-0.32-0.47-0.64-0.71-0.96c0.81-1,1.62-2,2.43-2.99l-0.01-0.01c-1.86,0.43-3.72,0.87-6.3,1.46
                c0.62-2.15,0.99-3.43,1.38-4.78c-1.45,0.1-2.71,0.19-4.25,0.3c1.34-6.26-5.04-6.98-8.04-11.78c4.82,0,8.89-0.64,12.68,0.11
                c16.26,3.23,31.46,9.07,44.5,19.7c0.34,0.33,0.67,0.66,1.01,0.98c0.34,0.34,0.68,0.67,1.01,1.01c0.39,0.35,0.78,0.71,0.07,0.06
                c0.89,0.34,0.4,0.15-0.1-0.04c-0.34-0.34-0.67-0.67-1.01-1.01c-0.33-0.34-0.65-0.67-0.98-1.01c0.66-0.67,1.33-1.34,1.99-2.01
                c0,0,0-0.01,0-0.01c-3.44-2.58-6.96-5.06-10.22-7.85c-0.48-0.41,0.26-2.27,0.63-4.75c-1.74-3.69-8.36-6.11-8.66-13.74
                c15.76,10.19,30.8,19.93,45.85,29.66c-4.4-3.38-9.01-6.31-8.54-13.03c0.08-1.2-1.82-2.59-2.91-3.8c-0.9-1-1.96-1.85-2.94-2.76
                c0.25-0.35,0.5-0.71,0.75-1.06c12.12,4.37,25.46,6.14,34.26,18.18c10.08-12.71,23.45-13.87,37.2-13.99
                c3.33-0.03,6.79,0.45,9.96-0.29c6.08-1.42,12.76-2.35,17.81-5.62c11.43-7.4,22.04-16.06,32.94-24.28c1.8-1.36,3.36-3.05,5.47-5.01
                c-1.63-0.52-2.52-0.97-3.45-1.08c-12.39-1.4-24.78-2.82-37.18-4.11c-30.6-3.17-61.21-6.19-91.8-9.44
                c-29.27-3.11-58.55-6.08-87.74-9.82c-12.79-1.64-25.37-4.97-38.01-7.71c-1.68-0.36-3.13-1.79-4.69-2.72
                c0.14-0.55,0.27-1.09,0.41-1.64c16.39-2.37,32.89-1.88,49.37-0.45c16.54,1.43,33.04,3.23,50.12,4.93
                c-15.24-4.45-30.94-6.22-43.24-16.37c0.08-0.52,0.17-1.04,0.25-1.56c2.31-0.52,4.62-1.04,8.07-1.82
                c-2.82-0.66-4.57-0.97-6.26-1.47c-10.03-2.97-20.11-5.83-30.04-9.1c-2.71-0.89-4.99-3.07-7.46-4.67c0.19-0.69,0.38-1.39,0.57-2.08
                c14.53-0.67,29.06-1.35,44.19-2.05c-11.71-1.73-22.93-3.08-34.02-5.12c-12.57-2.31-25.05-5.16-37.52-7.99
                c-2.33-0.53-4.43-2.08-6.64-3.16c0.17-0.53,0.34-1.06,0.5-1.59c16.64,0,33.29,0,42.55,0c-14.9-0.83-37.18-1.64-59.38-3.48
                c-11.37-0.94-22.6-3.68-33.87-5.76c-1.69-0.31-3.22-1.5-4.82-2.28c0.12-0.52,0.25-1.04,0.37-1.56c8.13,0,16.25,0,24.38,0
                c-18.02-2.72-23.81-4.1-28.34-8.17c23.31-5.63,46.79-3.65,69.55-8.02c-6.72-0.98-13.46-1.65-20.2-2.37
                c-22.71-2.44-45.43-3.16-68.23-1.02c-14,1.31-27.97,0.95-41.45-3.71c-3.04-1.05-5.78-2.98-8.66-4.51c0.2-0.67,0.39-1.35,0.59-2.02
                c33.28-6.57,67.33-3.58,102.35-7.15c-2.56-0.6-3.68-0.8-4.76-1.12c-7.96-2.33-15.97-4.52-23.84-7.14
                c-1.6-0.53-2.66-2.68-3.96-4.09c-10.09-2.81-21.48-3.28-30.79-10.15c0.04-0.77,0.08-1.55,0.12-2.32c14.3-1.1,28.6-2.19,42.89-3.29
                c-4.14-1.69-8.28-2.12-12.31-3.06c-8.71-2.02-17.45-4.02-25.99-6.64c-2.77-0.85-4.97-3.6-7.42-5.48c0.24-0.62,0.49-1.23,0.73-1.85
                c19.21-4.55,38.87-6.44,58.51-7.39c19.46-0.94,38.99-0.21,58.5-0.21c0-0.4,0-0.79,0-1.19c-3.94-1.89-7.88-3.78-11.82-5.68
                c0.18-0.57,0.37-1.13,0.55-1.7c1.58-0.12,3.16-0.33,4.74-0.34c10.3-0.05,20.6-0.23,30.89,0c4.13,0.09,5.97-1.42,6.77-5.41
                c2.48-12.4,5.26-24.73,7.85-37.11c0.29-1.38,0.17-2.85,0.24-4.35c-8.11,0-15.78-0.6-23.32,0.15c-9.52,0.94-17.14-2.96-24.93-8.29
                c6.76-1.15,13.52-2.3,21.63-3.69c-2.83-1.06-4.38-1.34-5.58-2.14c-4.13-2.76-8.78-5.49-8.37-11.32c0.26-3.74-1.28-4.65-4.26-3.96
                c-2.84,0.65-3.58-0.73-3.91-3.01c-0.52-3.56-1.06-7.12-1.67-11.26c-8.65,0-17.12,0.17-25.58-0.06
                c-6.31-0.17-12.68-0.39-18.89-1.39c-15.94-2.58-31.78-5.17-47.57,0.86c-1.79,0.68-3.93,0.62-5.91,0.62
                c-13.48,0.05-26.97,0.05-40.45,0c-2.32-0.01-4.73,0-6.94-0.59c-15.04-3.99-30.44-2.57-45.69-3.1
                c-16.44-0.57-32.91-0.24-49.37-0.45c-1.92-0.02-3.82-0.95-5.74-1.46c0.06-0.32,0.13-0.64,0.19-0.96
                c9.92-3.07,19.85-6.14,29.77-9.21c-0.13-0.51-0.26-1.01-0.4-1.52c-2.23,0.34-4.46,0.91-6.69,0.94c-2.22,0.03-5.6,0.31-6.46-0.92
                c-3.51-5-8.28-3.9-13.02-3.89c-53.61,0.03-107.23,0.02-160.84,0.03c-1.66,0-3.44-0.3-4.95,0.2c-1.13,0.37-2.77,1.88-2.69,2.76
                c0.31,3.59,0.78,7.03,4.93,8.99c6.91,3.26,13.39,7.43,20.28,10.75c3.96,1.91,8.32,3.88,12.59,4.12
                c16.27,0.92,32.59,1.53,48.89,1.55c22.97,0.03,45.93-0.6,68.9-0.86c2.5-0.03,5,0.54,8.16,0.9c-1.18,2.23-1.83,3.47-3.07,5.81
                c1.82,0.94,3.73,1.93,5.65,2.92c-3.35,2.82-6.06,3.93-8.63,5.3c-1.44,0.77-2.64,2-3.95,3.02c1.55,0.66,3.07,1.79,4.65,1.9
                c17.59,1.16,35.2,2.08,52.78,3.27c4.63,0.31,9.2,1.33,15.04,2.22c-4.82,6.18-10.31,6.9-15.66,7.11
                c-8.64,0.35-17.35-0.39-25.96,0.26c-8.78,0.66-17.48,2.42-26.21,3.71c16.6,6.37,34.38,0.78,50.8,6.31
                c0.02,0.93,0.03,1.86,0.05,2.79c-28.42,0.12-57.05-2.3-84.92,5.12c2.76,0.65,5.54,1.29,8.31,1.24c8.31-0.17,16.62-0.93,24.92-0.88
                c26.63,0.17,53.26,0.56,79.89,1c2.82,0.05,5.63,0.96,8.44,1.47c0.06,0.7,0.11,1.4,0.17,2.1c-3.31,1-6.58,2.66-9.94,2.88
                c-13.77,0.9-27.56,1.68-41.36,1.93c-31.45,0.58-62.92,0.72-94.37,1.3c-7.69,0.14-15.36,1.29-23.03,1.97
                c-0.08,0.71-0.16,1.42-0.23,2.13c6.72,1.36,13.39,3.35,20.18,3.96c29.97,2.68,59.97,4.94,89.96,7.41c1.75,0.14,3.45,0.79,5.18,1.2
                c-0.06,0.51-0.12,1.01-0.17,1.52c-2.05,0.11-4.09,0.35-6.13,0.29c-3.99-0.12-7.97-0.41-11.96-0.61
                c-19.27-0.95-38.54-1.98-57.82-2.79c-13.13-0.55-26.27-0.98-39.41-1.05c-4.8-0.03-9.6,1.12-14.4,1.72
                c0.01,0.75,0.03,1.49,0.04,2.24c32.83,2.93,65.77,4.2,98.82,3.78c-0.01,0.78-0.02,1.56-0.03,2.34
                c-26.54,0.72-53.08,1.45-79.62,2.17c0.01,0.54,0.01,1.08,0.02,1.62c16.27,5.57,33.24,2.65,49.89,3.58
                c16.55,0.92,33.13,1.31,49.7,1.93c0,0.76,0,1.52,0,2.29c-37.26,1.94-74.52,3.88-112.59,5.86c6.32,3.13,12,5.86,17.58,8.79
                c1.03,0.54,2.49,2.13,2.29,2.8c-0.43,1.43-1.57,3.07-2.87,3.7c-1.33,0.64-3.28,0.52-4.82,0.14c-11.13-2.77-22.2-5.82-33.34-8.52
                c-8.54-2.07-17.31-5.71-25.74-5.15c-12.1,0.79-23.07-1.56-34.15-5.31c-4.41-1.49-8.86-2.99-13.42-3.82
                c-3.73-0.68-7.15,0.96-8.27,4.84c-1.12,3.9,1.46,6.26,4.78,7.68c1.66,0.71,3.7,0.69,5.23,1.58c3.92,2.27,8.86,4.03,11.23,7.48
                c8.77,12.79,14.56,26.85,14.5,42.82c-0.07,18.37-1.8,36.75,0.76,55.1c0.28,1.99-0.47,4.17-1.01,6.2
                c-0.41,1.56-1.41,2.96-1.84,4.52c-2.83,10.16,0.3,20.95-5.58,31.28c-3.62,6.37-7.4,13.07-10.59,19.89
                c-0.3,0.64-2.05,0.59-3.13,0.86c0.33-0.68,0.66-1.36,0.99-2.04c2-6.65,4-13.3,6.01-19.95l0.18-0.25l-0.15,0.28
                c-2,6.65-4,13.31-5.99,19.96c-0.33,0.68-0.67,1.35-1,2.03c-0.9,2.16-1.79,4.31-2.69,6.47c-0.55-0.16-1.1-0.33-1.65-0.49
                c1.11-5.33,2.21-10.66,3.32-16l0.15-0.22c0,0-0.14,0.23-0.14,0.23c-1.61,2.4-3.21,4.8-4.82,7.19c-0.35-0.24-0.69-0.48-1.04-0.72
                c2.28-5.16,4.56-10.32,6.84-15.48c0.08-0.25,0.16-0.51,0.24-0.76c-0.07,0.26-0.14,0.52-0.21,0.79c-2.04,1.11-0.88,6.79-5.49,4.25
                c-1.14,4.18-2.34,8.57-3.53,12.95c-0.6-0.15-1.21-0.31-1.81-0.46c0.69-3.82,1.37-7.65,2.05-11.42c-0.68,0.2-1.5,0.45-2.73,0.81
                c0.61-3.17,1.13-5.87,1.64-8.58c-0.69-0.13-1.38-0.27-2.08-0.4c-1.32,5.82-2.63,11.64-3.65,16.15c-2.2,1.82-3.76,2.66-4.68,3.95
                c-3.37,4.73-6.54,9.6-9.76,14.43c-2.85,4.27-6.17,8.32-8.38,12.9c-3.9,8.06-8.33,15.41-16.21,20.28
                c-1.53,0.95-2.57,3.31-3.07,5.22c-4.11,15.74-2.67,31.69-0.75,47.47c2.83,23.26,6.52,46.42,9.65,69.65
                c0.46,3.4,1.96,4.54,5.08,4.74c6.97,0.44,13.93,1.46,20.89,1.48c11.47,0.03,22.95-0.92,34.42-0.74
                c9.09,0.14,16.97-2.62,24.39-7.45c4.32-2.82,8.65-5.63,13-8.4c24.14-15.37,50.14-20.59,78.32-14.76
                c20.24,4.19,40.31,6.67,61.28,2.73c26.61-5,53.51-8.18,79.55-16.2c7.61-2.34,16.11-2.59,24.18-2.48
                c20.16,0.27,39.97,3.98,59.86,6.94c10.01,1.49,20.18,3.27,30.22,2.99c20.52-0.58,39.81-7.03,58.78-14.61
                c19.45-7.77,39.14-14.95,58.72-22.4c1.46-0.56,2.9-1.16,4.05-1.62c-1-1.86-1.72-3.2-2.43-4.53l-0.01-0.01
                c-9.26,0.13-21.26-6.64-23.51-13.63c15.99,2.5,32.35,1.08,47.51,7.63c8.28,5.48,9.61,5.39,15.03-0.97
                C852.17,1378.3,852.47,1378,852.78,1377.7z M284.33,552.21c-0.33,0.33-0.67,0.66-1,0.99c-0.68,0.33-1.35,0.66-2.03,0.99
                c-0.32,0.32-0.65,0.65-0.97,0.97c-0.33,0.34-0.66,0.68-0.99,1.02c-0.69,0.33-1.37,0.67-2.06,1c-0.31,0.33-0.62,0.66-0.93,0.99
                c-0.68,0.34-1.36,0.67-2.04,1.01c-0.32,0.33-0.64,0.67-0.96,1c-0.66,0.34-1.33,0.68-1.99,1.02c-1.67,0.99-3.34,1.97-5.02,2.96
                c-1.79,0.63-3.58,1.26-5.96,2.1c0.07-8.34,4.79-14.08,7.68-20.57c-3.19,4.42-6.08,9-8.53,13.81c-3.8,7.46-9.08,13.43-16.28,17.73
                c-7.68,4.58-15.3,9.28-23.03,13.77c-0.57,0.33-1.83-0.55-2.77-0.86c0.3-0.31,0.6-0.61,0.91-0.92c0.68-0.35,1.36-0.7,2.03-1.05
                l0.15-0.13l-0.12,0.15c-0.71,0.33-1.41,0.65-2.12,0.98c-0.3,0.31-0.6,0.61-0.9,0.92c-0.67,0.52-1.34,1.03-1.99,1.53
                c1.43-3.93,3.27-7.55,4.01-11.38c0.81-4.21,0.51-8.61,0.84-12.92c0.41-5.3,1.09-10.58,1.46-15.89c1.17-16.4,2.46-32.8,3.28-49.23
                c0.44-8.8,0.2-17.64-0.12-26.46c-0.28-7.89,2.64-14.13,8.19-19.54c3.16-3.09,5.84-6.66,8.74-10.01c-1.63-1.11-3.07-2.09-3.9-2.65
                c-3.94,3.29-7.48,6.52-11.31,9.35c-2.76,2.04-7.38,2.23-5.91,7.93c-6.42-9.13-15.89-15.32-17.89-26.49
                c0.09,1.76,0.18,3.53,0.35,6.98c-3.15-2.5-5.66-3.83-7.23-5.87c-4.05-5.25-7.55-10.92-11.57-16.18c-1.05-1.37-3.68-3.02-4.8-2.57
                c-3.3,1.31-1.06,3.58,0.17,5.12c6.65,8.31,13.32,16.6,20.17,24.74c6.78,8.06,11.59,17.1,13.73,27.38
                c3.78,18.13,2.35,36.41,0.95,54.67c-0.25,3.24-0.04,6.53-0.04,10.95c-1.1-1.8-1.63-2.57-2.06-3.39
                c-3.31-6.29-6.32-12.76-9.96-18.86c-5.28-8.85-11.45-17.19-16.44-26.19c-8.54-15.41-16.45-31.17-24.63-46.79
                c-0.33-0.68-0.65-1.36-0.98-2.03l-0.15-0.21l0.13,0.23c0.34,0.67,0.68,1.33,1.01,2c-1.33,0.33-2.67,0.66-4,1
                c-0.32-0.33-0.64-0.67-0.96-1c-0.32-0.33-0.65-0.67-0.97-1c-0.32-0.33-0.64-0.66-0.96-0.99c-0.33-0.33-0.66-0.66-0.99-0.99
                c-0.33-0.33-0.66-0.65-1-0.98c-0.34-0.33-0.69-0.65-1.03-0.98l-0.07-0.08c0,0,0,0.15,0,0.15c0.34,0.33,0.69,0.66,1.03,0.98
                c0.34,0.32,0.67,0.65,1.01,0.97c0.33,0.33,0.66,0.66,0.99,0.99c0.32,0.33,0.65,0.65,0.97,0.98c0.33,0.33,0.65,0.66,0.98,0.99
                c0.33,0.32,0.67,0.64,1,0.97c7.1,5.55,11.45,12.7,13.27,21.3c-0.4,0.34-0.8,0.68-1.2,1.02c-7.36-6.1-14.71-12.21-22.07-18.31
                c-0.34-0.34-0.67-0.67-1.01-1.01c-0.33-0.33-0.66-0.67-0.99-1c-0.32-0.34-0.64-0.68-0.97-1.01c-0.33-0.33-0.66-0.67-0.99-1
                c-0.33-0.33-0.66-0.67-0.98-1c-0.32-0.33-0.65-0.66-0.97-0.99c-0.34-0.33-0.67-0.66-1.01-0.99c-0.34-0.33-0.68-0.66-1.02-0.99
                c-0.34-0.33-0.68-0.66-1.02-1c-1.04-1.77-2.08-3.54-3.11-5.31c-0.33,0.26-0.67,0.51-1,0.77c1.36,1.53,2.72,3.06,4.08,4.59
                c0.34,0.33,0.67,0.67,1.01,1c0.34,0.33,0.68,0.67,1.02,1c0.34,0.33,0.68,0.66,1.01,0.99c0.33,0.33,0.66,0.65,0.98,0.98
                c0.33,0.33,0.66,0.66,0.99,1c0.33,0.33,0.66,0.66,1,1c0.34,0.33,0.67,0.65,1.01,0.98c0.33,0.33,0.67,0.66,1,0.99
                c0.34,0.34,0.67,0.67,1.01,1.01c-1.81,0.63-3.89,2.17-5.37,1.73c-4.07-1.22-8.11-2.92-11.77-5.1c-4.82-2.87-9.26-6.36-13.86-9.59
                c-0.33-0.34-0.67-0.68-1-1.01c-0.32-0.33-0.65-0.67-0.97-1c-0.33-0.32-0.66-0.65-0.99-0.97c-0.33-0.32-0.66-0.65-1-0.97
                c-0.34-0.72-0.68-1.43-1.02-2.15c-0.3-0.29-0.61-0.59-0.91-0.88c0,0-0.06,0.06-0.07,0.06c0.31,0.29,0.62,0.58,0.93,0.88
                c0.34,0.72,0.67,1.43,1.01,2.15c0.34,0.32,0.67,0.65,1.01,0.97c0.34,0.32,0.67,0.63,1.01,0.95c0.34,0.32,0.68,0.64,1.02,0.95
                c0.34,0.34,0.68,0.67,1.01,1.01c1,2,1.99,4,2.98,6.01c-1-0.34-2-0.67-3.01-1.01l-0.23-0.13l0.21,0.15
                c1.02,0.32,2.04,0.64,3.06,0.96c10.53,5.36,21.06,10.71,31.6,16.07c-0.13,0.65-0.26,1.3-0.39,1.95c-2.8,0.62-5.6,1.25-7.88,1.75
                c3.88,1.1,9.16,1.15,12.22,3.79c5.15,4.45,9.65,10.09,13.07,16.02c7.99,13.82,14.95,28.23,22.75,42.17
                c10.93,19.53,15.19,40.24,12.51,62.47c-0.18,1.46,1.04,3.88,2.31,4.58c3.14,1.72,6.88,2.34,10.04,4.04
                c7.24,3.88,10.28,3.42,16.18-2.12c6.05-5.68,12.4-11.07,18.77-16.39c5.1-4.26,10.44-8.23,15.68-12.34
                c1.65-1.01,3.3-2.02,4.95-3.03c0.68-0.33,1.36-0.65,2.04-0.98c0.32-0.33,0.64-0.66,0.97-0.99c0.68-0.34,1.36-0.67,2.04-1.01
                c0.31-0.33,0.63-0.66,0.94-0.99c0.68-0.34,1.36-0.68,2.04-1.01c0.33-0.34,0.67-0.67,1-1.01c0.32-0.33,0.64-0.66,0.97-0.99
                c0.67-0.34,1.34-0.67,2-1.01c0.33-0.34,0.66-0.67,0.99-1.01c0.33-0.34,0.67-0.67,1-1.01c2.7-1.95,5.39-3.89,8.09-5.84
                c-0.28-0.34-0.56-0.68-0.84-1.02c-2.41,2.3-4.82,4.59-7.23,6.89C285,551.54,284.67,551.87,284.33,552.21z M701.91,193.47
                l-0.12-0.17l0.15,0.15c0.3,0.32,0.61,0.64,0.91,0.96c0.35,0.72,0.71,1.44,1.06,2.15c0.31,0.3,0.62,0.59,0.94,0.89
                c0.35,0.34,0.7,0.67,1.05,1.01c0.67,1,1.35,2.01,2.02,3.01c0.34,0.33,0.68,0.66,1.02,0.99c0.33,0.33,0.67,0.67,1,1
                c6.45,7.65,15.2,11.56,24.11,15.47c6.91,3.04,13.61,6.75,19.96,10.85c7.67,4.96,14.23,12.1,22.41,15.7
                c15.25,6.69,28.24,16.01,39.83,27.67c0.47,0.47,0.98,0.91,1.53,1.28c7.22,4.84,10.21,12.01,11.19,20.28
                c0.37,3.14,0.65,6.29,1.12,9.42c4.31,28.57,14.52,54.5,33.67,76.6c11.77,13.58,23.49,27.24,34.59,41.36
                c10.57,13.44,17.28,28.67,17.83,46.18c0.51,16.29,1.11,32.59,1.95,48.87c0.74,14.28,1.71,28.55,2.87,42.8
                c0.64,7.89,2.1,15.7,2.81,23.59c0.49,5.4,0.24,10.86,0.46,16.29c0.12,2.98,0.85,5.96,0.76,8.93c-0.57,20.29-2.1,40.59-1.76,60.86
                c0.58,34.59,2.44,69.16,3.5,103.75c0.86,28.11,1.99,56.24,1.77,84.36c-0.16,21.25-0.96,42.66-3.88,63.67
                c-2.74,19.68-9.24,38.81-13.03,58.39c-5.68,29.38-10.6,58.91-15.51,88.44c-2.97,17.88-5.36,35.85-7.8,53.81
                c-0.3,2.2-0.09,5.5,1.28,6.69c2.92,2.54,6.76,3.99,10.2,5.94c3.69,2.09,7.34,4.24,11.02,6.34c4.83,2.75,9.54,5.73,14.54,8.13
                c4.05,1.95,8.44,3.18,12.6,4.71c2.45-42.48-0.48-85.69-5.71-128.82c0.6-0.09,1.21-0.18,1.81-0.27c2.28,4.28,4.56,8.57,6.88,12.93
                c1.78-0.91,3-1.53,5.32-2.72c0.99,7.09,2.41,13.42,2.66,19.8c0.65,16.62,0.89,33.26,1.1,49.9c0.13,9.92,1.84,19.78,8.39,27.08
                c4.76,5.3,7.95,10.63,9.39,17.34c0.03,0.15,0.5,0.21,2.25,0.88c-2.62-30.69-5.18-60.71-7.79-91.35c1.61,0.65,2.11,0.71,2.16,0.89
                c0.3,1.11,0.55,2.23,0.71,3.36c2.32,16.78,4.34,33.61,7.04,50.33c1.58,9.82,4.02,19.53,6.53,29.17c0.81,3.1,3.06,5.82,4.55,8.53
                c6.39-5.54,11.89-10.31,17.39-15.08c0.7-0.32,1.41-0.64,2.11-0.96c0.29-0.31,0.59-0.62,0.88-0.92l0.05-0.12
                c0,0-0.12,0.05-0.12,0.05c-0.29,0.32-0.58,0.63-0.87,0.95c-0.68,0.35-1.35,0.69-2.03,1.04c-5.81,1.45-11.12,5.69-18.64,3.44
                c4.8-6.71,11.7-8.91,17.64-12.43l0-0.01c-6.03,1.07-11.9,4.1-18.19,2.01c-0.27-0.52-0.53-1.05-0.8-1.57
                c4.51-2.95,9.02-5.89,13.85-9.04c-4.44,0-8.53,0-12.62,0c-0.21-0.47-0.42-0.94-0.62-1.4c2.03-2.12,4.06-4.25,6.08-6.36
                c-1.93-0.25-3.76-0.48-6.34-0.81c1.59-2.25,2.59-3.67,3.14-4.46c-2.91-3.91-5.68-7.53-8.2-11.31c-0.12-0.18,2.3-2.8,2.88-2.57
                c2.92,1.12,5.64,2.76,8.39,4.3c3.07,1.71,6.1,3.5,9.41,5.42c0.4-1.44,0.69-1.92,0.64-2.37c-1.36-12.38-1.92-24.93-4.5-37.05
                c-1.28-6.02,0.12-10.63,1.9-15.73c0.34-0.67,0.67-1.34,1.01-2.01l0.12-0.24c0,0-0.15,0.21-0.15,0.21
                c-0.33,0.68-0.66,1.35-0.99,2.03c-2.3,2.92-4.59,5.83-6.89,8.75c-0.37-0.19-0.74-0.38-1.11-0.57c0-1.72,0-3.45,0-5.17
                c0.33-0.66,0.67-1.33,1.01-1.99l-0.01-0.01c-0.32,0.68-0.64,1.36-0.96,2.04c-1.13,0.74-2.26,1.49-4.04,2.66c0-3.21,0-5.45,0-7.7
                c0.34-0.67,0.68-1.33,1.01-2c0,0,0,0,0,0c-0.33,0.68-0.65,1.35-0.98,2.03c-1.82,1.88-3.63,3.75-5.44,5.63
                c-0.59-0.32-1.18-0.64-1.77-0.96c1.06-3.57,2.13-7.14,3.19-10.71c0.34-0.66,0.67-1.32,1.01-1.99l-0.01-0.01
                c-0.33,0.67-0.65,1.34-0.98,2.01c-2.28,2.38-4.55,4.76-6.83,7.14c-0.52-0.28-1.03-0.56-1.55-0.84c0.46-2.43,0.91-4.86,1.37-7.3
                l0-0.01c-1.56,1.51-3.11,3.02-5.26,5.1c-1.92-6.17,1.86-9.85,3.25-14.1c0.34-0.33,0.68-0.67,1.01-1c0.34-0.32,0.67-0.65,1.01-0.97
                c0.33-0.32,0.67-0.64,1-0.96c0.32-0.31,0.65-0.63,0.97-0.94l0.02-0.15c0,0-0.08,0.08-0.08,0.08c-0.32,0.32-0.64,0.64-0.95,0.96
                c-0.33,0.33-0.65,0.66-0.98,0.99c-0.33,0.34-0.65,0.67-0.98,1.01c-0.33,0.34-0.67,0.68-1,1.02c-3.33,3.42-6.66,6.85-10,10.27
                c-0.61-0.38-1.21-0.76-1.82-1.14c1.26-3.39,2.53-6.77,3.79-10.16c0.34-0.32,0.68-0.65,1.03-0.97c0.33-0.31,0.66-0.62,1-0.93
                c0.33-0.32,0.66-0.64,0.99-0.96l-0.01-0.16c0,0-0.06,0.09-0.06,0.09c-0.33,0.33-0.66,0.65-0.98,0.97
                c-0.32,0.32-0.64,0.64-0.96,0.96c-0.33,0.34-0.65,0.68-0.98,1.03c-2.16,1.55-4.33,3.11-6.49,4.66c-0.43-0.4-0.86-0.81-1.29-1.21
                c1.81-2.93,3.63-5.86,5.38-8.69c-2.96,1.37-5.89,2.73-8.83,4.09c-0.24-0.39-0.48-0.79-0.73-1.18c0.54-1.33,1.08-2.66,1.44-3.53
                c-4.09,1.2-8.11,2.37-12.12,3.55c-0.29-0.6-0.57-1.2-0.86-1.8c2.49-2.69,4.98-5.37,8.36-9.01c-6.58,0-11.97,0-18.7,0
                c2.35-2,3.53-3,4.88-4.15c-5.06-1.9-10.61-2.89-13.34-9.51c20.39,4.7,39.9,9.19,58.6,13.49c-0.45-1.53-0.97-3.29-1.35-4.57
                c-2.2-1.13-6.58-2.39-6.54-3.52c0.14-4.37-1.95-4.14-4.96-3.91c-1.13,0.09-2.34-0.83-3.51-1.28c0.52-1.13,0.82-2.45,1.62-3.34
                c1.68-1.88,3.62-3.52,5.46-5.26c-0.23-0.4-0.47-0.79-0.7-1.19c-2.18,0.47-4.34,1.06-6.53,1.39c-2.24,0.34-4.36,0.3-2.76-3.01
                l-0.02-0.02c-1.67,0-3.34,0-6.12,0c1.58-2.02,2.35-3.01,3.12-4l0.02,0.02c-2.87,0.31-5.74,0.61-8.62,0.92
                c-0.12-0.51-0.24-1.01-0.36-1.52c1.46-1.81,2.93-3.62,4.74-5.87c-4.93-0.63-9.15-1.16-13.36-1.7c-0.06-0.47-0.12-0.94-0.18-1.42
                c1.8-1.23,3.6-2.46,5.6-3.82c-9.86,1.49-13.79-3.81-19.29-11.89c7.69,1.57,14.51,2.97,21.78,4.46c0-14.15,0.29-27.59-0.06-41.01
                c-0.71-27.78-2.13-55.55-2.51-83.33c-0.18-13.43,1.63-26.88,1.95-40.34c0.22-9.13-0.28-18.31-1.02-27.43
                c-1.68-20.88-3.69-41.74-5.58-62.61c-1.1-12.09-2.27-24.17-3.31-36.26c-0.14-1.62,0.35-3.29,0.54-4.94
                c0.48-0.04,0.95-0.08,1.43-0.12c0.55,1.63,0.99,3.3,1.66,4.88c2.48,5.79,4.74,11.7,7.71,17.23c1.33,2.48,3.84,5.3,6.35,5.97
                c5.22,1.4,5.51,4.66,5.52,8.94c0.02,19.64-0.05,39.29,0.43,58.92c0.4,16.07,1.53,32.12,2.34,48.18
                c1.65-8.64,2.72-17.22,3.46-25.84c1.5-17.39,4.4-34.85,3.87-52.19c-0.85-27.88,1.02-55.54,2.77-83.27
                c1.99-31.54,4.07-63.07,5.74-94.62c1.08-20.44,1.39-40.92,2.25-61.38c0.49-11.63,1.38-23.25,2.17-34.87
                c1.51-22.24,3.05-44.47,4.64-66.7c1.28-17.91,1.47-36.01,4.25-53.69c4.02-25.51,14.26-48.33,34.11-66.15
                c5.2-4.67,9.6-10.78,12.8-17.03c4.75-9.29,8.11-19.29,12.06-28.98c0.33-1,0.67-2,1-3c0.34-1,0.67-2,1.01-3
                c0.34-0.66,0.68-1.31,1.02-1.97c0.33-1.01,0.66-2.02,0.99-3.02c0.33-1,0.67-2,1-3c0.33-1,0.66-2,0.99-3
                c0.11-0.27,0.22-0.53-0.01,0.02c0.22-0.56,0.11-0.29,0.01-0.02c-0.34,0.99-0.68,1.99-1.01,2.98c-0.33,1-0.67,2-1,3
                c-0.33,1.01-0.66,2.01-0.99,3.02c-0.33,0.66-0.66,1.33-1,1.99c-0.32,1.01-0.65,2.02-0.97,3.04c-0.33,1-0.66,2-0.99,3
                c-4.02,7.85-7.45,16.07-12.24,23.42c-4.2,6.45-9.82,11.99-15.04,17.73c-1.17,1.28-3.29,1.7-6.11,3.07
                c0.48-2.83,0.59-4.42,1.04-5.9c2.03-6.69,4.03-13.39,6.27-20.01c6.35-18.77,12.62-37.56,14.89-57.38
                c0.17-1.48-0.15-3.01-0.28-5.21c-1.23,1.04-1.62,1.2-1.69,1.46c-0.27,0.95-0.47,1.92-0.62,2.9
                c-4.44,29.26-12.66,57.51-23.22,85.07c-5.13,13.38-10.83,26.56-20.69,37.35c-1.43,1.56-3.29,2.73-4.96,4.08
                c-0.5-0.3-1-0.6-1.51-0.9c1.27-32.99,9.86-65.06,12.99-98.02c-2.91,3.74-6.47,7.5-7.39,11.82c-5.76,27.05-9,54.47-10.22,82.11
                c-0.66,14.96-1.49,29.92-2.28,44.87c-1.18,22.43-2.63,44.85-3.49,67.3c-0.76,19.63-0.8,39.29-1.3,58.93
                c-0.2,7.98-0.54,15.98-1.19,23.94c-2.1,25.7-3.51,51.49-6.8,77.04c-3.68,28.68-9.08,57.14-13.86,85.68
                c-0.35,2.06-1.7,3.96-2.58,5.93c-0.55-0.06-1.09-0.13-1.64-0.19c-0.81-2.58-2.04-5.12-2.36-7.76c-2.47-20.46-5.87-40.88-6.9-61.42
                c-2.07-41.36-3.01-82.77-4.07-124.18c-0.62-24.29-0.32-48.61-0.92-72.91c-0.75-30.2,0.93-60.21,5.07-90.12
                c1.87-13.49,2.77-27.05,9.05-39.65c7.84-15.74,19.01-28.87,30.71-41.65c11.62-12.69,21.8-26.39,30.36-41.3
                c0.68-1.19,1.13-2.52,1.71-3.82c-1.99-0.31-3.56-0.26-4.89-0.81c-2.84-1.18-4.25,0.02-5.52,2.43
                c-6.46,12.27-16.28,21.83-25.87,31.55c-0.83,0.84-2.02,1.33-4.22,2.73c0.63-3.22,0.71-5.2,1.41-6.93c3.89-9.72,8-19.36,11.86-29.1
                c0.73-1.85,2.94-4.24-0.78-5.78c-3.5-1.45-4.2,0.86-5.24,3.32c-3.11,7.35-6.33,14.66-9.64,21.92c-0.71,1.55-1.94,2.86-3.83,5.58
                c-5.35-14.97-0.75-28.44,0.16-41.84c-2.51,0.68-4.78,2-6.79,1.68c-4.11-0.64-4.89,1.23-5.1,4.62c-0.91,15.1-2.3,30.18-2.81,45.28
                c-0.47,14.01-7.91,24.93-14.43,38.1c-2.37-3.75-4.28-6.03-5.39-8.65c-6.11-14.39-12.27-28.77-17.9-43.35
                c-3.17-8.2-6.14-16.63-7.82-25.22c-2.67-13.68-3.43-27.76-6.56-41.32c-1.37-5.93-5.36-11.2-3.69-17.89c0.18-0.74-1.73-2-2.67-3.01
                c-0.67,1.13-1.92,2.26-1.91,3.38c0.06,6.82-0.27,13.75,0.79,20.44c3.21,20.19,7.01,40.28,10.57,60.41
                c0.26,1.5,0.39,3.02,0.58,4.53c-0.59,0.23-1.18,0.46-1.77,0.68c-15.67-15.37-24.37-34.83-31.06-55.43
                c-6.7-20.63-12.02-41.55-13.14-63.36c0.63,11.09,1.25,22.18,1.88,33.27c-0.44,0.12-0.88,0.24-1.33,0.36
                c-1.12-1.74-2.48-3.37-3.32-5.24c-2.8-6.22-6-12.34-7.98-18.83c-3.49-11.44-6.19-23.12-9.22-34.69c-0.33-1.34-0.66-2.68-0.99-4.02
                c0,0,0.03-0.03,0.03-0.03c0.33,1.34,0.66,2.68,0.99,4.02c2.58,13.95,5.16,27.89,7.92,42.86c-10.25-6.73-16.13-16.23-22.93-24.85
                c-0.33-0.67-0.66-1.34-0.99-2.01c-0.32-0.68-0.65-1.36-0.97-2.04c-0.32-0.68-0.65-1.37-0.97-2.05c-0.34-0.66-0.69-1.32-1.03-1.98
                l-0.17-0.22c0,0,0.15,0.25,0.15,0.25c0.33,0.66,0.67,1.33,1,1.99c0.33,0.68,0.66,1.36,0.99,2.03c0.34,0.67,0.68,1.33,1.01,2
                c0.33,0.67,0.67,1.33,1,2c5.12,7.52,9.58,15.62,15.52,22.42c6.66,7.61,12.71,15.45,16.51,24.78c3.7,9.08,7.19,18.28,10.14,27.63
                c6.89,21.81,16.03,42.41,31.55,59.52c7.79,8.58,12.95,18.46,17.04,29.09c0.72,1.86,1.44,3.74,2.39,5.49
                c5.18,9.64,7.29,20.01,6.94,30.82c-0.29,9.14-1.28,18.25-1.78,27.38c-1.51,27.74-2.91,55.49-4.41,83.23
                c-0.14,2.64-0.75,5.25-0.95,6.53c-2.78-2.09-5.71-4.03-8.34-6.31c-19.15-16.67-33.47-37.42-48.17-57.79
                c-10.73-14.87-16.96-31.57-14.54-50.14c1.54-11.82,4.35-23.56,7.58-35.05c6.61-23.56,13.23-47.08,15.87-71.5
                c0.19-1.75-0.37-3.58-0.59-5.37c-0.4-0.01-0.81-0.01-1.21-0.02c-2.84,8.09-5.68,16.19-8.53,24.28c-0.54-0.1-1.08-0.21-1.62-0.31
                c-6.32-10.86-5.24-23.43-7.6-35.28c-0.48,0.91-0.71,1.82-0.58,2.67c1.76,11.65,3.43,23.32,5.39,34.94
                c1.58,9.35-1.39,17.76-4.17,27.73c-14.6-13.69-19.42-30.46-23.98-47.58c-0.73,1.46-1.06,2.87-0.68,4.04
                c3.74,11.53,6.07,23.87,11.83,34.3c6.74,12.2,7.93,23.57,3.2,36.14c-0.35,0.93-0.46,1.94-0.7,2.91
                c-2.7,11.34-4.09,12.28-12.86,4.23c-11.98-10.99-23.19-22.87-34.14-34.91c-9.25-10.18-13.6-22.71-14.46-36.44
                c-0.07-1.17-0.48-2.31-0.73-3.46c-0.8,0.25-1.61,0.51-2.41,0.76c4.41,20.36,8.6,40.8,22.61,58.25c-5.05-1.19-9.36-2.79-13.14-5.21
                c-24.37-15.56-48.07-31.89-62.87-57.88c-0.49-1.11-0.98-2.21-1.48-3.32c-0.19,0.1-0.38,0.2-0.58,0.3c0.67,1.02,1.33,2.04,2,3.06
                c6,9.59,12.01,19.18,18.57,29.66c-2.95-0.69-5.51-0.78-7.55-1.86c-6.43-3.4-12.67-7.18-18.99-10.81c-0.33-0.34-0.66-0.67-1-1.01
                c-0.33-0.34-0.66-0.68-0.99-1.02c-0.66-1.01-1.32-2.03-1.99-3.04c-0.34-0.34-0.68-0.68-1.03-1.02c-0.31-0.3-0.62-0.59-0.94-0.89
                c-0.35-0.72-0.71-1.44-1.06-2.15C702.59,194.06,702.25,193.76,701.91,193.47z M864.79,1370.66c0.32,0.3,0.64,0.61,0.96,0.91
                c0.34,0.69,0.68,1.37,1.01,2.06c0.33,0.32,0.66,0.64,1,0.96c0.34,0.67,0.67,1.33,1.01,2c0.17,0.25,0.33,0.5,0.5,0.74
                c0.01,0,0.01-0.01,0.02-0.01c-0.17-0.25-0.33-0.5-0.5-0.75c-0.33-0.67-0.66-1.35-0.99-2.02c-0.33-0.32-0.66-0.64-0.99-0.97
                c-0.34-0.69-0.67-1.38-1.01-2.07c-0.32-0.31-0.63-0.61-0.95-0.92c-0.34-0.32-0.67-0.64-1.01-0.96c-0.36-0.72-0.72-1.44-1.07-2.17
                c-0.29-0.28-0.59-0.56-0.88-0.84l-0.11-0.06c0,0,0.04,0.13,0.04,0.13c0.3,0.28,0.59,0.56,0.89,0.84c0.36,0.72,0.72,1.44,1.08,2.16
                C864.12,1370.03,864.45,1370.35,864.79,1370.66z M116.5,451.35c0.31,0.31,0.62,0.63,0.94,0.94c2.36,3.85,5.52,6.3,10.27,6.6
                c4.86,0.3,5.93-0.9,4.55-5.73c-0.76,1.36-1.52,2.72-2.32,4.15c-2.82-1.35-5.27-2.63-7.81-3.69c-1.48-0.62-3.1-0.91-4.66-1.35
                c-0.3-0.32-0.61-0.64-0.91-0.96c-0.32-0.33-0.64-0.66-0.97-0.98l-0.13-0.04c0,0,0.06,0.11,0.05,0.11
                C115.85,450.7,116.17,451.03,116.5,451.35z M822.81,1358.6c0.34-0.33,0.67-0.65,1.01-0.98c5.37-2.98,10.73-5.95,16.1-8.93
                c0.23,0.29,0.47,0.59,0.7,0.88c-4.6,5.67-9.2,11.35-13.8,17.02l0.01,0c6.18-3.46,12.36-6.91,18.54-10.37
                c0.33,0.49,0.65,0.97,0.98,1.46c-4.09,4.4-8.17,8.8-12.26,13.2c0.16,0.2,0.31,0.39,0.47,0.59c5.43-2.86,10.86-5.71,16.3-8.57
                c0.63,0.84,1.26,1.69,1.88,2.53c-3.98,3.39-7.95,6.77-11.93,10.16l-0.02-0.02c4.21-2.09,8.41-4.18,12.62-6.28
                c0.27,0.47,0.53,0.95,0.8,1.42c-2.47,2.26-4.93,4.53-7.4,6.79c0.13,0.16,0.26,0.33,0.39,0.49c3.9-2.08,7.8-4.17,11.7-6.25
                c0.27,0.39,0.55,0.77,0.82,1.16c-2.31,2.89-4.61,5.79-6.92,8.68c0,0-0.02-0.02-0.02-0.02c2.77-1.53,5.54-3.07,8.31-4.6
                c0.22,0.3,0.45,0.6,0.67,0.9c-0.99,0.9-1.99,1.8-2.98,2.7l0,0.01c2.52-0.53,5.03-1.05,7.63-1.59
                c-8.58-23.28-44.19-48.11-68.35-47.85c3.94,2.12,7.62,4.1,11.59,6.24c-1.13,1.3-1.98,2.28-2.82,3.25c0,0-0.02-0.02-0.02-0.02
                c3,0,6,0,9,0l0.01,0.02c1.59-0.63,3.18-1.26,4.77-1.9c0.25,0.33,0.51,0.66,0.76,0.99c-2.65,2.84-5.31,5.68-7.96,8.51
                c0.19,0.21,0.38,0.41,0.57,0.62c4.3-1.88,8.6-3.76,12.9-5.64c0.28,0.4,0.56,0.79,0.85,1.19c-3.63,3.74-7.25,7.48-10.88,11.22
                l-0.12,0.12c0,0,0.11-0.12,0.11-0.12c5.46-3.44,10.92-6.88,16.37-10.33c0.29,0.36,0.59,0.72,0.88,1.09
                c-3.43,3.74-6.86,7.49-10.29,11.23c-0.32,0.34-0.64,0.68-0.96,1.02l-0.15,0.13L822.81,1358.6z M116.48,448.27
                C116.48,448.27,116.48,448.26,116.48,448.27c-0.34-0.33-0.68-0.66-1.02-0.98c-1.33-1.66-2.66-3.33-3.98-4.99
                c0,0,0.02-0.02,0.02-0.02c1.33,1.66,2.66,3.32,3.99,4.99C115.82,447.59,116.15,447.93,116.48,448.27z M855.81,1338.6l0.12,0.15
                l-0.14-0.13l-0.13-0.18C855.66,1338.44,855.81,1338.6,855.81,1338.6z M836.82,1326.67c0.32,0.32,0.65,0.64,0.97,0.97
                c3.03,4.27,9.27,6.05,9.99,12.39c1.35,0,3.07,0,5.88,0c-5.82-4.56-10.84-8.48-15.86-12.41c-0.31-0.33-0.62-0.67-0.93-1
                C836.88,1326.62,836.82,1326.67,836.82,1326.67z M255.34,1320.61c0.33-0.33,0.67-0.67,1-1c3.33-3.65,6.66-7.31,10-10.96
                c0,0-0.01-0.01-0.01-0.01c-3.32,3.67-6.64,7.34-9.95,11c-0.33,0.33-0.67,0.67-1,1c-0.34,0.34-0.67,0.68-1.01,1.01
                c-2.12,1.59-4.12,3.37-6.37,4.72c-6.59,3.94-13.15,7.96-19.99,11.42c-3.06,1.54-6.73,1.93-10.17,2.63
                c-4.69,0.96-10.08,0.61-13.95,2.89c-3.72,2.19-6.49,5.03-10.51,2.83c-8.93,7.1-9.04,16.05-6.33,25.49
                c0.47,1.65,2.27,2.91,3.46,4.35c1.15-1.29,2.91-2.4,3.33-3.89c1.64-5.73,2.85-11.59,4.25-17.39c0.3-1.22,0.77-2.4,1.16-3.61
                c0.65,0.16,1.3,0.33,1.95,0.49c-1.93,10-3.85,20-5.78,30c-0.81,1.04-1.45,2.31-2.47,3.08c-1.3,0.99-2.9,1.59-4.38,2.35
                c-0.19-1.62-0.38-3.24-0.56-4.85c-0.16-1.39-0.05-2.88-0.51-4.17c-1.8-5.02-3.78-9.98-6.24-16.41c1,9.35,1.71,17.51,2.9,25.6
                c0.22,1.5,2.4,3.77,3.71,3.79c1.67,0.02,3.82-1.54,4.93-3.04c1.32-1.79,1.76-4.23,2.59-6.39c3.05-9.66,6.11-19.32,9.16-28.98
                c0.51,0.16,1.02,0.31,1.52,0.47c-2.04,11.82-5.37,23.31-9.66,34.54c0,0,0.01,0.01,0.01,0.01c2.54-3.77,5.95-7.22,7.43-11.37
                c2.88-8.08,4.65-16.55,6.97-24.83c0.49-1.77,1.34-3.43,2.02-5.15c0.5,0.13,1.01,0.27,1.51,0.4c-0.32,2.32-0.63,4.64-0.95,6.96
                c-0.06,0.27-0.11,0.54-0.17,0.8c0.05-0.28,0.09-0.55,0.14-0.83c1.27-1.89,2.53-3.79,3.8-5.68c0.41,0.15,0.82,0.29,1.22,0.44
                c0,1.76,0,3.52,0,5.27l-0.01-0.01c1.87-8.46,7.33-11.89,15.72-11.33c1.95,0.13,3.94,0.14,5.88-0.1c2.25-0.28,4.89-0.29,6.62-1.48
                c7.83-5.39,15.7-10.69,20.72-19.33c4.3-7.4,9.59-14.22,14.34-21.37c2.01-3.03,3.77-6.22,5.65-9.34c0.33-0.67,0.67-1.34,1-2.02
                c0.82-1.24,1.83-2.4,2.44-3.74c9.47-20.51,12.8-42.15,11.74-64.58c-0.19-3.93-0.36-7.86-0.54-11.79c0.71-0.04,1.42-0.09,2.13-0.13
                c0.45,4.86,1.48,9.76,1.23,14.58c-0.59,11.42-1.7,22.83-2.82,34.22c-0.33,3.31-0.27,5.54,3.95,5.64c0.94,0.02,2.24,1.88,2.63,3.13
                c0.39,1.27-0.13,2.82-0.26,4.25c0.15,0.04,0.31,0.09,0.46,0.13c1.2-2.82,2.39-5.63,3.59-8.45c0.56-0.05,1.11-0.1,1.67-0.16
                c0.64,1.81,1.27,3.62,1.75,5c1-1.81,2.27-4.12,4.1-7.43c1.24,2.63,1.91,4.04,3.17,6.71c0-15.13,0-29.02,0-42.92
                c0.39-0.04,0.79-0.08,1.18-0.11c0.61,0.77,1.21,1.53,2.1,2.66c-0.52-5.55-1.11-10.5-1.33-15.46c-0.05-1.14,1.17-2.34,1.8-3.51
                c0.12,0.25,0.23,0.49,0.35,0.74c-0.13-0.24-0.26-0.47-0.39-0.71c0.03-1.33-0.23-2.73,0.12-3.97c3.47-12.16,3.26-24.61,2.02-36.94
                c-0.58-5.8-3.35-11.38-5.13-17.06l0.03,0.07c-4.11-11.03-7.92-22.32-22.14-24.07c-0.95-0.12-1.89-0.65-2.74-1.16
                c-3.33-1.99-5.8-4.64-5.36-8.83c0.44-4.15,3.26-6.6,6.98-7.56c2.29-0.59,5-0.55,7.29,0.07c7.69,2.07,15.24,4.71,22.94,6.76
                c3.78,1,7.79,1.17,12.77,1.87c-2.16-2.6-3.34-4.03-4.02-4.86c22.48,6.24,45.4,11.77,68.19,17.73l0.27,0.14l-0.24-0.15
                c-28.87-9.7-57.74-19.41-86.61-29.11c32.41,6.23,63.1,18.14,94.33,28.43c-2.12-1.69-4.47-2.8-6.79-3.99
                c-18.51-9.46-37.06-18.85-55.49-28.46c-3.13-1.63-5.34-2.69-8.97-0.45c-5.48,3.39-8.45,2.58-11.1-1.5c1.43-0.2,2.81-0.11,3.93-0.6
                c2.12-0.92,4.57-1.79,5.91-3.46c0.54-0.68-1.05-3.81-2.37-5.12c-7.88-7.83-17.75-9.28-28.22-7.96
                c-16.12,2.03-29.4,10.05-41.58,20.24c-0.32,0.33-0.64,0.67-0.96,1l0.02,0.01c0.33-0.32,0.66-0.65,0.99-0.97
                c4.73,0.71,3.54,3.61,1.98,6.12c-5.92,9.52-3.77,18.6,2.7,26.29c5.48,6.5,12.28,11.99,18.99,17.31
                c6.55,5.2,11.66,10.93,12.15,19.66c0.33,5.84-1.48,10.93-6.28,14.47c-4.73,3.48-9.54,1.89-14.07-0.63
                c-2.9-1.61-5.62-3.56-8.39-5.41c-7.78-5.21-15.71-5.19-23.35-0.04c-3.83,2.58-7.39,5.72-10.55,9.1
                c-24.95,26.61-41.79,58.01-52.6,92.55c-6.1,19.5-10.38,39.57-15.48,59.38l-0.18,0.27c0,0,0.15-0.3,0.15-0.3
                c1.46-2.57,3.52-4.97,4.27-7.73c2.44-8.99,4.39-18.11,6.57-27.17c0.43-1.79,1.07-3.54,1.62-5.31c0.44,0.13,0.87,0.26,1.31,0.39
                c-1.71,7.58-3.66,15.11-5.06,22.74c-1.72,9.46-3.06,19-4.34,28.54c-0.16,1.19,0.9,3.49,1.69,3.64c1.32,0.24,3.34-0.44,4.25-1.46
                c2.07-2.3,3.64-5.05,5.42-7.61c5.39,3.61,7.79,3.36,10.54-2.58c3.2-6.9,5.41-14.27,8.07-21.42c0.63-1.7,1.37-3.37,2.06-5.06
                c0.59,0.21,1.18,0.41,1.76,0.62c-2.26,8.16-4.52,16.32-6.78,24.48c11.4,2.14,14.48-8.98,16.09-24.73
                c-0.9,2.72-1.96,5.92-3.05,9.18c-2.01-5.58-4.14-10.22-5.36-15.09c-4.69-18.76-7.08-37.63-1.16-56.62
                c0.27-0.87,1.03-1.6,1.56-2.39c0.4,0.11,0.8,0.22,1.19,0.33c-5,16.57-4.4,33.13-1,50.03c5.31-0.66,5.23,3.18,5.67,5.96
                c0.46-1.77,1.02-3.94,1.59-6.11c0.53,0.04,1.07,0.07,1.6,0.11c0.74,3.78,1.47,7.56,2.03,10.44c1.79-3.75-2.93-10.14,3.79-14.08
                c0.75,1.64,1.34,2.93,1.61,3.53c1.85-3.31,3.75-6.72,6.38-11.43c1.23,3.84,1.95,6.1,2.8,8.78c0.46-2.71,0.83-4.91,1.21-7.11
                c0.56,0.03,1.11,0.06,1.67,0.09c0.55,5.07,1.11,10.14,1.66,15.21c0.67-10.3,2.55-20.17,9.73-28.48c1.24,2.73,2.44,5.38,3.72,8.19
                c0.47-1.46,0.87-2.71,1.49-4.64c1.14,1.65,1.79,2.59,2.15,3.12c1.17-5.1,2.34-10.24,3.52-15.38c0.59,0,1.18-0.01,1.77-0.01
                c1.22,4.38,2.44,8.77,3.46,12.4c0-1.28,0.01-3.4,0-5.52c-0.01-4.11-0.12-8.23,0.06-12.33c0.02-0.53,1.54-0.98,2.37-1.47
                c0.32,1.02,0.64,2.03,0.96,3.05l0.13,0.22l-0.12-0.23c-0.34-1-0.68-2-1.01-3c-0.33-1.96-0.67-3.92-1-5.88
                c0.51-0.14,1.01-0.27,1.52-0.41c0.93,2.54,1.86,5.08,3.1,8.48c-0.41-3.22-0.84-5.48-0.87-7.74c-0.01-0.47,1.47-0.97,2.26-1.45
                c0.14,1.07,0.28,2.14,0.42,3.21c0.09-0.02,0.18-0.04,0.26-0.05c-0.23-1.05-0.47-2.09-0.7-3.14c-2.28-11.22-4.5-22.45-6.88-33.64
                c-1.18-5.54-0.38-10.15,2.98-13.35c-0.28,2.09-0.5,3.81-0.87,6.54c1.37-2.69,2.14-4.54,3.25-6.15c0.33-0.48,1.65-0.27,2.52-0.38
                l0.01,0.01c0.83-1.34,1.67-2.68,2.5-4.02c0.49,0.26,0.98,0.53,1.46,0.79c-1.55,2.63-3.39,5.14-4.57,7.93
                c-1.42,3.37-3.51,7.12-3.14,10.47c1.02,9.33,3.33,18.51,4.8,27.8c1.2,7.59,1.93,15.26,3.02,24.14c-3.95-1.3-6.28-2.06-8.48-2.78
                c-2.41,6.19,0.21,13.53-4.99,20.2c-0.7-2.88-1.16-4.74-1.62-6.6l0.15-0.22l-0.13,0.24c-0.87-0.08-1.74-0.16-2.64-0.24
                c0.36,3.61,0.71,7.11,1.06,10.6c-0.48,0.12-0.96,0.25-1.43,0.37c-0.93-1.92-1.87-3.85-3.34-6.87c0,3.14,0,5.04,0,8.05
                c-2.95-3.91-5.16-6.84-7.4-9.82c0,3.55,0.05,7.05-0.01,10.54c-0.08,4.19-1.6,4.95-5.66,3.05c-1.28,5.08-1.82,10.53-7.57,14.91
                c0-2.74,0-4.19,0-5.65c0.22-0.49,0.43-0.99,0.08-0.18c0.12-0.87,0.04-0.32-0.03,0.22c-2.43,1.93-3.2,0.69-3.23-1.74
                c-0.04-3.13-0.01-6.26-0.01-9.38c-0.27-0.04-0.53-0.07-0.8-0.11c-1.26,4.47-2.52,8.93-3.95,13.99c-0.93-1.72-1.64-3.04-2.29-4.25
                c-3.95,9.91-7.92,19.89-12.22,30.68c12.17-2.32,12.87-14.03,18.57-21.12c0.53,0.31,1.07,0.61,1.6,0.92
                c-2.23,4.65-4.46,9.29-6.69,13.94l0.01,0.02c10.52-4.63,13.74-15.18,18.62-24.4c0.54,0.31,1.08,0.61,1.62,0.92
                c-2.08,5.12-4.17,10.24-6.39,15.69c9-5.15,10.32-15.2,14.98-22.9c0.5,0.25,1,0.5,1.5,0.75c-1.12,3.32-2.23,6.64-3.35,9.96
                l-0.12,0.09c0,0,0.13-0.11,0.13-0.11c5.25-8.45,10.5-16.89,15.75-25.34c0.27,0.07,0.55,0.14,0.82,0.21
                c-2.19,5.38-4.39,10.76-6.58,16.15l-0.01-0.01c0,1.33,0,2.67,0,4c0,0,0.01,0.01,0.01,0.01c6.56-7.67,13.12-15.34,19.62-22.94
                c0.01,0.17,0.15,0.53,0.05,0.8c-0.53,1.4-1.11,2.77-1.67,4.16c-0.33,0.67-0.66,1.35-1,2.02c-8.22,9.23-16.16,18.72-24.75,27.59
                c-5.34,5.52-11.63,10.14-17.57,15.07c-0.67,0.56-1.85,0.52-2.79,0.76c0.38,0.24,0.76,0.48,1.15,0.73c3.65-4.4,7.3-8.8,10.95-13.2
                C254.67,1321.29,255.01,1320.95,255.34,1320.61z M1012.66,1266.78l-0.01-0.15c0,0-0.05,0.09-0.05,0.09
                c-0.32,0.32-0.64,0.63-0.96,0.95c-5.59,5.69-11.18,11.38-16.77,17.07c-0.54-0.35-1.09-0.7-1.63-1.05c1.08-2.62,1.8-5.47,3.32-7.81
                c4.87-7.51,10.03-14.82,15.09-22.21l0.14-0.1l-0.15,0.13c-9.56,8.85-18.03,18.93-29.3,26.08c1.39-4.63,3.34-8.77,5.93-12.45
                c5.62-8,11.56-15.78,17.37-23.64c0.33-0.32,0.67-0.63,1-0.95c0,0-0.05-0.05-0.05-0.05c-0.31,0.34-0.62,0.69-0.92,1.03
                c-10.73,8.09-21.46,16.19-32.19,24.28c-0.17-0.15-0.34-0.29-0.51-0.44c5.9-9.28,11.81-18.57,17.71-27.85c0,0-0.01-0.01-0.01-0.01
                c-10.58,7.74-21.17,15.48-31.75,23.22c-0.49-0.43-0.99-0.86-1.48-1.28c1.43-3.04,2.52-6.31,4.36-9.08
                c5.14-7.73,10.59-15.25,15.92-22.85c0.31-0.31,0.62-0.61,0.93-0.92l0.08-0.07c0,0-0.14,0.01-0.14,0.01
                c-0.3,0.31-0.6,0.63-0.9,0.94c-8.91,7.58-17.55,15.5-27.47,22.04c0.61-3.26,1.79-5.96,3.26-8.48c4.01-6.88,8.14-13.68,12.23-20.51
                c0.33-0.34,0.67-0.67,1-1.01c0.34-0.3,0.69-0.59,1.03-0.89c0.32-0.7,0.64-1.41,0.97-2.11l0.17-0.13l-0.17,0.17
                c-0.34,0.67-0.68,1.34-1.03,2.01c-0.32,0.32-0.64,0.65-0.96,0.97c-0.34,0.33-0.67,0.67-1.01,1c-7.15,6.68-14.3,13.36-21.45,20.04
                c-0.48-0.3-0.95-0.6-1.43-0.9c1.06-3.04,1.76-6.28,3.26-9.08c4.72-8.78,9.74-17.4,14.64-26.08c0.31-0.29,0.62-0.59,0.93-0.88
                l0.05-0.1c0,0-0.12,0.04-0.12,0.04c-0.3,0.3-0.61,0.6-0.91,0.9c-8.18,7.72-16.6,15.12-26.37,21.13c1.01-4.32,2.64-8.05,4.61-11.59
                c3.47-6.22,7.16-12.33,10.76-18.48c0.34-0.32,0.69-0.64,1.03-0.96c0,0-0.03-0.04-0.03-0.04c-0.32,0.34-0.64,0.68-0.97,1.03
                c-9.41,8.06-18.82,16.12-28.23,24.18c-0.51-0.44-1.03-0.88-1.54-1.32c1.17-2.25,2.12-4.64,3.54-6.71
                c5.33-7.78,10.8-15.46,16.23-23.18c0,0-0.02,0.01-0.02,0.01c-9.16,7.7-18.33,15.4-27.49,23.1c-0.5-0.49-0.99-0.98-1.49-1.47
                c3.35-10.23,10.99-17.96,16.76-26.91c-11.17,8.07-21.34,17.35-34.56,22.21c-0.04-9.63,5.72-16.12,10.84-22.92
                c0.33-0.31,0.67-0.62,1-0.93c0.32-0.32,0.65-0.64,0.97-0.95c0.33-0.33,0.66-0.66,0.99-0.99l0.04-0.13c0,0-0.12,0.05-0.12,0.05
                c-0.33,0.33-0.65,0.67-0.98,1c-0.32,0.32-0.64,0.65-0.95,0.97c-0.32,0.33-0.64,0.65-0.96,0.98c-6.26,4.25-12.52,8.51-18.78,12.76
                c-0.4-0.46-0.79-0.91-1.19-1.37c1.13-1.94,2.09-4.01,3.41-5.8c5.11-6.91,10.35-13.73,15.53-20.59c0.34-0.32,0.68-0.64,1.02-0.96
                c0.32-0.32,0.65-0.64,0.97-0.96c0.33-0.32,0.65-0.64,0.98-0.96l0.06-0.12c0,0-0.13,0.05-0.13,0.05c-0.32,0.33-0.65,0.65-0.97,0.98
                c-0.32,0.33-0.64,0.65-0.95,0.98c-0.32,0.34-0.63,0.69-0.95,1.03c-8.08,5.82-16.16,11.64-24.24,17.46
                c-0.58-0.6-1.17-1.19-1.75-1.79c4.66-6.23,9.31-12.47,13.97-18.7c0.34-0.32,0.68-0.64,1.03-0.97c0,0-0.04-0.03-0.03-0.03
                c-0.32,0.34-0.64,0.69-0.96,1.03c-7.63,4.4-15.27,8.8-22.9,13.2c-0.55-0.61-1.11-1.22-1.66-1.83c4.18-5.13,8.36-10.26,12.54-15.39
                c0.34-0.32,0.68-0.63,1.02-0.95c0.32-0.32,0.64-0.65,0.96-0.97l0.08-0.08c0,0-0.14,0.02-0.14,0.01c-0.32,0.33-0.63,0.66-0.94,0.99
                c-0.32,0.34-0.63,0.68-0.95,1.02c-5.99,3.42-11.98,6.84-17.97,10.26c-0.49-0.52-0.97-1.04-1.46-1.56
                c3.47-5.24,6.94-10.48,10.42-15.72c0.34-0.32,0.68-0.63,1.02-0.95c0.32-0.31,0.64-0.63,0.95-0.94l0.05-0.11
                c0,0-0.12,0.04-0.12,0.04c-0.31,0.32-0.62,0.65-0.93,0.97c-0.32,0.34-0.63,0.68-0.95,1.02c-6.95,4.14-13.89,8.28-20.84,12.42
                c-0.4-0.47-0.79-0.95-1.19-1.42c1.09-7.17,6.58-12.06,10.03-18.02l-0.02,0c-6.56,4.65-12.6,10.18-20.2,13.08
                c-0.57-0.51-1.15-1.01-1.72-1.52c4.32-6.85,8.64-13.71,12.95-20.56l-0.02,0.01c-6.07,4.36-12.1,8.79-18.27,13
                c-0.98,0.67-2.67,0.55-3.99,0.41c-4.25-0.43-8.49-1.04-12.73-1.58c-0.21,0.67-0.42,1.34-0.62,2.01c4.83,3.01,9.53,6.24,14.51,8.96
                c4.22,2.3,8.88,3.77,13.11,6.05c2.25,1.21,4,3.35,5.97,5.07c-0.23,0.58-0.47,1.16-0.7,1.74c-5.41,0.26-10.82,0.51-16.31,0.78
                c7.28,1.7,14.53,2.93,21.46,5.14c6.94,2.22,13.86,5.04,17.91,13.48c-3.15,0-5.25,0.15-7.32-0.02c-23.85-1.95-47.7-3.87-71.53-5.99
                c-7.93-0.71-15.83-1.85-23.7-3.08c-2.18-0.34-4.19-1.73-6.28-2.63c0.15-0.52,0.29-1.05,0.44-1.57
                c15.55-3.16,31.38,1.41,47.22-0.53c-13.58-7.12-29.22-4.49-42.67-11.25c13.9-4.27,28.77,0.29,42.44-5.91
                c-9.59-2.66-19.26-4.76-28.95-6.76c-10.08-2.08-20.24-3.81-30.25-6.2c-3.29-0.78-6.21-3.13-9.3-4.76
                c0.15-0.72,0.29-1.44,0.44-2.17c4.49-0.38,8.98-1.05,13.48-1.1c18.14-0.22,36.28-0.19,54.42-0.4c7.42-0.08,14.87-0.45,17.24-9.69
                c0.34-0.32,0.69-0.64,1.03-0.97c0.33-0.3,0.65-0.61,0.98-0.91l0.02-0.14c0,0-0.08,0.07-0.08,0.07c-0.31,0.32-0.63,0.64-0.94,0.95
                c-0.32,0.34-0.65,0.69-0.97,1.03c-5.88,2.42-10.32,8.08-17.2,8.28c-0.44-0.42-0.89-0.83-1.33-1.25
                c3.84-5.35,7.67-10.71,11.51-16.06l0.03,0.03c-6.83,3.18-13.66,6.36-20.49,9.53c-0.39-0.59-0.79-1.17-1.18-1.76
                c2.89-3.26,5.78-6.52,8.67-9.78c0,0-0.01,0-0.01,0c-4.64,2.06-9.28,4.12-13.92,6.18c-0.39-0.58-0.78-1.16-1.17-1.74
                c2.04-2.48,4.07-4.95,6.11-7.43c0,0,0-0.02,0-0.02c-4.77,1.29-9.55,2.58-14.32,3.87c-0.21-0.52-0.42-1.04-0.63-1.56
                c1.04-1.38,2.08-2.77,3.37-4.49c-2.76,0.59-5.15,1.23-7.58,1.6c-2.46,0.37-4.97,0.49-8.4,0.8c1.4-2.89,2.21-4.54,3.19-6.57
                c-4.27,0-8.03,0-12.4,0c1.18-1.83,1.86-2.89,2.12-3.29c-40.32,0.05-80.82-2.02-122,7.18c15.98,10.08,34.06,7.3,49.49,14.16
                c-14.09,6.37-29.43,3.33-43.87,6.21c-0.06,0.64-0.12,1.29-0.18,1.93c12.16,2.16,24.31,4.32,36.47,6.48
                c0.01,0.39,0.02,0.79,0.03,1.18c-2.03,0.76-4.06,1.53-6.09,2.29c-0.06,0.41-0.11,0.82-0.17,1.23c11.59,2.87,23.17,5.74,34.76,8.61
                c-1.62,1.76-3.23,2.61-4.9,2.74c-13.58,1.03-27.17,2.07-40.77,2.83c-15.61,0.87-31.24,1.37-46.85,2.22
                c-4.3,0.24-8.56,1.18-14.02,1.97c11.68,5.32,27.85,7.75,42.91,6.01c32.53-3.75,64.76-0.15,97.01,3.45
                c1.42,0.16,2.77,0.97,4.16,1.48c-0.06,0.56-0.13,1.12-0.19,1.68c-23.33,6.12-47.71,4.11-71.33,8.06
                c-0.03,0.42-0.06,0.83-0.08,1.25c16.8,1.84,33.59,3.68,50.39,5.52c-0.01,0.69-0.02,1.38-0.03,2.07c-13.73,0-27.47,0-41.2,0
                c-0.03,0.56-0.06,1.11-0.08,1.67c1.39,0.34,2.76,0.87,4.17,1c18.86,1.7,37.71,3.56,56.6,4.94c14.6,1.06,29.24,1.46,43.85,2.26
                c2.42,0.13,4.81,0.87,7.21,1.33c-0.08,0.58-0.16,1.16-0.24,1.75c-19.42,3.43-39.04,1.83-58.66,1.64c2.17,1.01,4.36,1.76,6.6,2.13
                c20.33,3.37,40.68,6.65,61.02,10.03c3.72,0.62,7.4,1.56,11.09,2.36c-0.06,0.83-0.11,1.66-0.17,2.49
                c-16.43,0.76-32.86,1.52-49.29,2.28c14.38,8.35,30.55,9.92,45.83,14.31c0,0.58-0.01,1.15-0.01,1.73c-3.85,0.85-7.71,1.7-12.68,2.8
                c17.76,9.4,36.74,11.44,54.49,17.7c-18.15,2.33-35.8-1.53-53.58-3.11c-18.15-1.62-36.3-2.69-54.59-0.81
                c6.83,2.22,13.71,4.2,20.72,5.05c19.47,2.37,39.01,4.19,58.5,6.39c30.9,3.48,61.76,7.2,92.67,10.6
                c27.76,3.06,55.55,5.86,83.33,8.68c1.51,0.15,3.56-0.06,4.61-0.97c10.2-8.84,18.71-18.94,22.52-32.27c0.34-0.67,0.68-1.33,1.02-2
                l0.13-0.24l-0.15,0.22c-0.32,0.68-0.64,1.37-0.97,2.05c-6.43,7.95-11.92,16.87-21.32,21.76c-0.57-0.33-1.13-0.65-1.7-0.98
                c2.01-4.94,4.01-9.88,6.02-14.82C1011.99,1267.38,1012.32,1267.08,1012.66,1266.78z M981.68,1258.67
                c0.34-0.32,0.67-0.63,1.01-0.95c8.73-7.55,17.61-14.87,28.67-19.11c-0.91,3.01-2.44,5.28-4.04,7.5
                c-6.87,9.53-13.75,19.05-20.63,28.57l-0.15,0.1l0.14-0.13c10.17-9.65,19.38-20.42,31.74-27.73c-1.33,3.52-3.36,6.42-5.34,9.35
                c-4.82,7.12-9.61,14.25-14.42,21.38l0.01,0.01c6.56-6.91,13.12-13.82,19.68-20.73c0.48,0.4,0.96,0.8,1.44,1.19
                c-3.38,6.84-6.77,13.68-10.15,20.52l0.01,0.02c13.9-11.33,23.65-25.44,28.26-42.86c0.37-1.38-0.41-3.95-1.5-4.69
                c-6.84-4.6-13.9-8.87-20.93-13.16c-3.25-1.99-6.8-3.53-9.9-5.72c-6.45-4.57-12.74-9.38-18.99-14.23c-1.8-1.4-3.3-3.22-4.81-4.95
                c-6.64-7.59-13.52-14.87-22.42-19.95c-6.42-3.66-12.7-7.57-18.96-11.49c-1.38-0.86-2.32-2.42-3.68-3.32
                c-3.22-2.14-6.34-5.12-9.91-5.9c-7.32-1.59-13.22-4.99-18.52-10.11c-2.06-1.99-4.83-3.4-7.5-4.56
                c-10.09-4.4-19.47-10.46-30.37-13.06c-1.68-0.4-3.07-1.95-4.67-2.83c-1.59-0.88-3.22-1.76-4.94-2.36
                c-11.41-3.95-22.82-7.94-34.3-11.69c-7-2.29-14.24-4.23-21.34-0.61c-6.41,3.27-12.57,7.02-18.83,10.57
                c0.23,0.44,0.45,0.89,0.68,1.33c5.89-2.34,11.77-4.67,17.66-7.01c0.14,0.25,0.28,0.49,0.42,0.74c-3.41,3.65-6.83,7.31-10.24,10.96
                l0.01,0c5.93-2.78,11.86-5.56,17.79-8.34c0.51,0.73,1.02,1.47,1.54,2.2c-4.12,3.71-8.23,7.42-12.35,11.13l0.02,0.01
                c7.46-3.65,14.93-7.29,22.39-10.94c0.23,0.39,0.45,0.78,0.68,1.17c-4.69,6.26-9.39,12.51-14.08,18.77l-0.03-0.03
                c7.37-4.54,14.75-9.08,22.45-13.83c-4.03,7.34-7.73,14.09-11.44,20.85l-0.03-0.03c8.2-5.95,16.4-11.89,24.6-17.84
                c0.22,0.24,0.45,0.48,0.67,0.72c-5.08,7.04-10.17,14.09-15.25,21.13c-0.33,0.33-0.67,0.67-1,1l-0.14,0.11l0.12-0.13
                c0.33-0.34,0.67-0.67,1-1.01c8.72-6.07,17.44-12.13,26.16-18.2c0.19,0.18,0.38,0.36,0.57,0.55c-6.11,8.44-12.22,16.88-18.33,25.32
                c0.36,0.32,0.71,0.63,1.07,0.95c7.67-5.18,15.33-10.37,23-15.55c0.35,0.4,0.71,0.81,1.06,1.21c-4.71,6.51-9.41,13.02-14.12,19.52
                c0.45,0.43,0.9,0.86,1.35,1.29c9.34-6.29,18.68-12.58,28.03-18.88c0.13,0.19,0.26,0.38,0.39,0.58
                c-5.39,7.4-10.78,14.81-16.17,22.21c-0.34,0.33-0.67,0.67-1.01,1l-0.13,0.1l0.12-0.12c0.33-0.34,0.66-0.68,0.99-1.02
                c7.69-4.57,15.37-9.14,23.06-13.71c0.3,0.38,0.61,0.77,0.91,1.15c-5.66,6.52-11.31,13.05-16.97,19.57l0.01,0.01
                c9.18-5.45,18.36-10.89,27.54-16.34c0.32,0.34,0.65,0.68,0.97,1.03c-5.68,6.91-11.37,13.82-17.05,20.73
                c0.21,0.23,0.42,0.45,0.62,0.68c8.95-6.79,17.89-13.58,26.84-20.37c0.48,0.5,0.96,1,1.44,1.5c-7.6,9.68-15.21,19.36-22.81,29.04
                c0.23,0.21,0.47,0.42,0.7,0.63c9.8-7.54,19.59-15.07,29.39-22.61c0.39,0.43,0.78,0.86,1.17,1.3
                c-9.08,10.43-20.6,19.11-25.18,32.76c0.51,0.57,1.02,1.14,1.53,1.7c12.91-9.52,25.83-19.03,38.74-28.55
                c0.21,0.22,0.42,0.45,0.63,0.67c-7.19,10.61-14.38,21.21-21.58,31.82l-0.11,0.09l0.12-0.11c10.32-8.52,20.63-17.05,30.95-25.57
                c0.52,0.53,1.04,1.07,1.55,1.6c-7.05,9.74-14.09,19.48-21.14,29.21c0.23,0.16,0.46,0.32,0.69,0.49
                c9.39-8.3,18.78-16.61,28.18-24.91c0.57,0.52,1.14,1.05,1.72,1.57c-6.04,10.18-12.07,20.37-18.11,30.55
                c0.24,0.19,0.49,0.38,0.73,0.57c9.33-8.15,18.66-16.31,27.98-24.46c0.23,0.27,0.46,0.54,0.69,0.8
                c-7.09,13.05-14.18,26.1-21.27,39.15l-0.13,0.1l0.13-0.12c9.29-9.32,18.42-18.81,28.86-27c-0.73,3.02-2.22,5.36-3.67,7.72
                c-5.4,8.76-10.79,17.53-16.19,26.29c-0.34,0.33-0.67,0.65-1.01,0.98l-0.01,0.01c0.33-0.34,0.66-0.68,0.99-1.02
                c9.88-8,19.76-16,29.64-24c0.47,0.51,0.94,1.01,1.41,1.52c-2.57,3.38-5.27,6.67-7.68,10.17c-5.53,8.06-10.92,16.21-16.37,24.33
                c-0.33,0.33-0.67,0.66-1.01,0.99c0.33-0.33,0.66-0.67,0.99-1c10.78-7.66,21.56-15.31,32.34-22.97c0.52,0.5,1.03,1.01,1.55,1.51
                c-4.64,7.49-9.27,14.99-13.91,22.48c-0.33,0.34-0.65,0.68-0.98,1.01l-0.15,0.1C981.53,1258.8,981.68,1258.67,981.68,1258.67z
                M101.5,646.11c-0.33-0.33-0.66-0.66-0.99-0.99l-0.15-0.28c0,0,0.13,0.3,0.13,0.3c0.33,0.33,0.65,0.66,0.98,0.99
                c0.34,0.33,0.67,0.66,1.01,1c0.33,0.33,0.67,0.67,1,1c1.01,0.66,2.01,1.32,3.02,1.98c0.34,0.34,0.68,0.67,1.01,1.01
                c1.61,1.16,3.07,2.8,4.87,3.4c10.81,3.64,15.42,11.24,15.47,22.39c0.01,1.54,2.41,3.99,4.12,4.44c8.43,2.19,10.54,0.28,10.07-8.34
                c-0.04-0.66-0.13-1.33-0.16-1.99c-0.71-19.43-1.52-38.86-2.07-58.29c-0.19-6.7-2.28-9.87-8.39-11.05
                c-0.51,18.34,1.68,36.84-2.1,55.55c-6.22-2.68-7.04-7.72-8.87-12.07c-0.11-0.76-0.21-1.51-0.32-2.27
                c-0.13,0.03-0.26,0.07-0.38,0.1c0.25,0.71,0.5,1.42,0.75,2.13c-0.4,3.08-2.12,3.3-3.93,1.36c-2-2.15-3.62-4.68-5.24-7.16
                c-1.15-1.76-2.02-3.69-3.02-5.55c-0.41,0.23-0.81,0.46-1.22,0.68c0.6,7.47,7.09,12.3,9.85,18.81c-0.32,0.35-0.64,0.71-0.95,1.06
                c-2.83-1.06-5.66-2.13-8.5-3.19c-0.34-0.34-0.67-0.67-1.01-1.01c-0.99-0.67-1.98-1.34-2.97-2.02c-0.33-0.33-0.67-0.67-1-1
                C102.17,646.78,101.83,646.45,101.5,646.11z M98.52,647.1l-0.05,0.05C98.47,647.15,98.52,647.1,98.52,647.1z M339.28,260.41
                c-0.33-0.33-0.67-0.66-1-0.99c-0.67-0.99-1.34-1.98-2.02-2.97c-0.33-0.68-0.66-1.35-0.99-2.03c-0.33-0.66-0.67-1.32-1-1.98
                l0.02-0.02c0.33,0.66,0.67,1.32,1,1.98c0.33,0.68,0.66,1.35,0.99,2.03c0.66,1,1.32,2,1.98,3c0.33,0.33,0.65,0.67,0.98,1
                c0.33,0.33,0.67,0.67,1,1c1.38,1.13,2.75,2.27,4.13,3.4c0.29-0.47,0.59-0.94,0.88-1.41c0.21-0.72,0.43-1.43,0.64-2.15
                c-0.07-0.02-0.14-0.04-0.2-0.06c-0.14,0.75-0.27,1.49-0.41,2.24c-1.67-0.68-3.34-1.36-5.02-2.04
                C339.94,261.07,339.61,260.74,339.28,260.41z M149.47,436.28l0.12,0.16l-0.15-0.14l-0.14-0.17
                C149.3,436.12,149.47,436.28,149.47,436.28z M1019.64,1152.77c0,0-0.02-0.02-0.02-0.02c-0.32,0.34-0.65,0.69-0.97,1.03
                c-9.52,4.71-18.05,11.61-30.65,14.07c5.06-6.69,0.06-8.95-3.04-11.71c-6.41-5.71-9.51-13.09-11.44-21.15
                c-0.81-3.39-1.48-6.82-2.21-10.24c-0.19,10.41,2.68,20.94-2.37,31.27c-2.39-4.06-3.87-8.33-5.44-12.56
                c-0.68-1.82-1.09-4.09-2.42-5.25c-7.94-6.97-11.93-15.83-12.87-26.12c-0.57-6.28-0.57-12.61-0.83-18.92
                c-0.67-16.1-1.31-32.2-2.05-48.3c-0.06-1.39-0.42-3.3-1.36-4c-2.34-1.74-5.1-2.93-7.72-4.35c0,0.88-0.09,2.31,0.01,3.73
                c1.79,24.35,4.03,48.68,5.26,73.07c0.68,13.59-0.21,27.26-0.59,40.89c-0.11,3.97,1.37,6.18,5.11,7.39
                c2.49,0.8,5.09,1.81,7.13,3.38c7.16,5.52,14.22,11.18,21.08,17.07c3.59,3.09,6.68,6.77,10.01,10.16c1.15,1.18,2.22,2.51,3.55,3.45
                c4.17,2.95,8.5,5.66,12.65,8.64c3.21,2.31,6.23,4.88,9.32,7.33c0.55,0.37,1.07,0.79,1.65,1.11c8.35,4.68,8.38,4.7,14.02-2.83
                c5.76-7.7,11.45-15.45,17.17-23.18l0.01,0.01c-2.44,1.97-4.93,3.88-7.31,5.92c-6.41,5.51-12.68,11.2-19.23,16.54
                c-1.63,1.32-4.13,1.57-6.23,2.31c0.26-1.73,0.04-3.76,0.89-5.14c2.07-3.38,4.6-6.49,7-9.67c2-2.65,4.13-5.21,6.11-7.88
                c1.93-2.6,3.76-5.29,5.63-7.94c-8.87,6.62-16,15.29-27.23,18.54c6.64-12.38,16.95-21.8,25.38-32.66l0.11-0.12l-0.1,0.11
                c-1.79,1.2-3.56,2.43-5.37,3.6c-8.91,5.77-17.79,11.59-26.78,17.22c-1.45,0.91-3.43,0.99-6.56,1.83c1.5-3.16,2.03-5.14,3.21-6.59
                c2.83-3.47,5.96-6.7,8.98-10.01c5.82-6.36,11.66-12.71,17.49-19.06C1018.95,1153.42,1019.3,1153.09,1019.64,1152.77z
                M381.24,272.41c-0.34,0.3-0.67,0.6-1.01,0.9c-0.34,0.33-0.68,0.66-1.02,0.99c-0.33,0.68-0.65,1.37-0.98,2.05
                c-1.34,1.01-2.68,2.02-4.01,3.04c-0.32,0.34-0.65,0.68-0.97,1.02c-0.67,0.33-1.34,0.67-2.01,1c-1-0.67-2.01-1.34-3.01-2.01
                c0.33-0.67,0.67-1.35,1-2.02c0.35-0.64,0.69-1.29,1.04-1.93l0.11-0.25c0,0-0.14,0.22-0.14,0.22c-0.33,0.66-0.65,1.33-0.98,1.99
                c-0.33,0.68-0.66,1.36-0.98,2.04c-2,2.99-4,5.98-6,8.97c-1.01,1.33-2.02,2.66-3.04,3.99c-0.33,0.33-0.66,0.65-0.99,0.98
                c-0.33,0.33-0.67,0.67-1,1c-0.33,0.34-0.66,0.68-0.99,1.02c-0.33,0.34-0.66,0.67-1,1.01c-0.33,0.33-0.67,0.67-1,1
                c-0.36-1.75-1.46-3.75-0.98-5.23c3.96-12.14,8.33-24.16,12.25-36.31c0.5-1.54-0.84-3.67-1.33-5.52c-1.51,1.1-3.88,1.89-4.41,3.36
                c-5.27,14.35-10.01,28.89-15.4,43.19c-1.91,5.08-0.03,7.94,3.65,11.1c2.2-3.77,4.19-7.19,6.19-10.61c0.33-0.33,0.66-0.67,1-1
                c0.34-0.33,0.67-0.66,1.01-1c0.34-0.33,0.69-0.66,1.03-0.99c0.34-0.33,0.67-0.66,1.01-1c0.33-0.33,0.65-0.65,0.98-0.98
                c0.99-1.35,1.97-2.71,2.96-4.06c2.99-2.33,5.99-4.65,8.98-6.98c0.67-0.33,1.34-0.66,2.01-0.99c0.34-0.33,0.68-0.65,1.02-0.98
                c1.35-1,2.7-2.01,4.05-3.01c0.32-0.68,0.65-1.36,0.97-2.04c0.34-0.33,0.68-0.66,1.02-0.99c0.31-0.33,0.62-0.65,0.94-0.98
                l0.14-0.12L381.24,272.41z M104.5,435.27l0.12,0.12l-0.13-0.1l-0.18-0.12C104.31,435.17,104.5,435.27,104.5,435.27z
                M237.37,1131.77c0.34-0.3,0.68-0.59,1.02-0.89c0.34-0.71,0.69-1.42,1.03-2.12c0.32-0.3,0.65-0.6,0.97-0.91
                c0.33-0.7,0.66-1.39,0.99-2.09c0.33-0.67,0.67-1.33,1-2c0.33-0.31,0.66-0.61,1-0.92c0.33-0.7,0.66-1.4,0.99-2.1
                c0.32-0.66,0.65-1.33,0.98-1.99l0.15-0.11c0,0-0.14,0.14-0.14,0.14c-0.35,0.64-0.69,1.27-1.04,1.91c-0.33,0.7-0.66,1.4-0.99,2.1
                c-0.33,0.31-0.66,0.62-0.98,0.93c-0.34,0.67-0.67,1.34-1.01,2c-0.34,0.69-0.67,1.38-1.01,2.06c-0.33,0.3-0.65,0.6-0.98,0.91
                c-0.35,0.71-0.69,1.41-1.03,2.12c-0.31,0.32-0.63,0.64-0.94,0.97l-0.14,0.12L237.37,1131.77z M237.38,1127.85
                c0,0-0.05-0.05-0.05-0.05c-0.32,0.33-0.63,0.67-0.95,1c-3.03,2.05-6.05,4.1-9.08,6.15c-0.4-0.49-0.81-0.98-1.21-1.46
                c14.89-16.27,32.22-29.92,50.82-43.9c-9.58-0.59-17.48-1.07-25.39-1.56c0.01-0.66,0.02-1.31,0.03-1.97
                c10.81-1.36,21.95,0.82,32.68-1.25c-0.03-0.46-0.07-0.92-0.1-1.37c-16.37,0-32.73,0-49.1,0c0.34,0.37,0.68,0.73,1.02,1.1
                c-1.48,1.43-2.75,3.74-4.46,4.15c-8.83,2.07-17.77,5.12-26.68,5.11c-19.57-0.01-39.13-1.86-58.72-2.58
                c-23.4-0.86-46.89-0.4-69.87-6.16c-4.18-1.05-8.6-1.13-12.91-1.66c0.02-0.61,0.05-1.22,0.07-1.83c15.55-3.22,31.31-1.12,47-0.77
                c1.37,0.27,2.74,0.54,4.11,0.82c0.02-0.14,0.05-0.28,0.07-0.42c-1.41-0.12-2.81-0.23-4.22-0.35c-1.63-0.25-3.26-0.56-4.9-0.74
                c-18.5-2.01-37.05-3.71-55.5-6.1c-11.19-1.44-22.31-3.66-33.35-6.05c-6.36-1.38-8.14-4.24-7.88-10.45
                c-2.01-0.2-4.06-0.41-6.02-0.61c-0.38,0.95-0.72,1.41-0.72,1.86c-0.05,30.13-0.07,60.27-0.11,90.4c0,2.96,1.49,4.25,4.33,4.2
                c10.64-0.2,21.3-0.07,31.91-0.73c10.76-0.66,21.44-2.6,32.2-3.09c17.28-0.77,34.6-0.72,51.9-1.22c4.91-0.14,9.79-0.99,14.69-1.51
                c0.01-0.26,0.02-0.53,0.03-0.79c-6.97-2.05-13.94-4.1-20.91-6.14c0.16-0.86,0.32-1.71,0.49-2.57
                c58.11,3.46,116.23,6.84,174.43,10.43c-2.51-1.39-5.12-2.17-7.79-2.64c-13.23-2.34-26.5-4.51-39.71-7.01
                c-1.85-0.35-3.42-2.17-5.12-3.3c6.86-0.74,13.71-1.58,20.59-2.17c2.55-0.22,4.22-0.41,4.52-3.73c0.61-6.73,1.84-13.4,2.81-20.1
                l0.17-0.31c0,0-0.14,0.34-0.14,0.34c-3.92,5.47-7.83,10.95-11.75,16.42c-0.43-0.24-0.87-0.47-1.3-0.71
                c2.68-5.91,5.36-11.82,8.04-17.73l-0.01-0.01c-4.21,3.68-9.03,6.88-12.48,11.17c-4.11,5.11-7.04,11.16-10.48,16.8
                c-3.07,2.29-6.15,4.59-9.22,6.88c-0.27-0.39-0.54-0.77-0.81-1.15c2.67-3.9,5.34-7.81,8.01-11.71
                C236.69,1128.46,237.04,1128.15,237.38,1127.85z M83.5,672.13l-0.11-0.14l0.13,0.13l0.11,0.17
                C83.63,672.29,83.5,672.13,83.5,672.13z M237.36,1124.77l0.16-0.15c0,0-0.14,0.17-0.14,0.17l-0.14,0.12L237.36,1124.77z
                M240.38,1122.77l0.19-0.06c0,0-0.2,0.08-0.2,0.08l-0.13,0.09L240.38,1122.77z M262.34,1109.78l0.14-0.13c0,0-0.13,0.15-0.13,0.15
                l-0.14,0.12L262.34,1109.78z M264.35,1106.8c0.33-0.34,0.66-0.68,0.99-1.02c1.01-0.99,2.02-1.98,3.04-2.98
                c0.33-0.3,0.65-0.6,0.98-0.9l0-0.15c0,0-0.06,0.09-0.06,0.09c-0.32,0.31-0.63,0.62-0.95,0.94c-1,1.01-1.99,2.01-2.99,3.02
                c-0.34,0.33-0.68,0.66-1.01,0.98C264.34,1106.78,264.35,1106.8,264.35,1106.8z M488.13,280.39c-0.33,0.33-0.67,0.66-1,0.99
                c-0.66,1.01-1.32,2.01-1.98,3.02c-0.33,0.34-0.67,0.68-1,1.01c-1.29,1.51-2.72,2.92-3.84,4.55
                c-18.43,26.7-27.83,57.46-39.13,87.36l0.01,0.01c2.04-1.87,5.1-3.36,5.96-5.67c4.58-12.28,8.32-24.88,12.92-37.16
                c3.42-9.13,7.46-18.06,11.79-26.81c1.33-2.68,4.5-4.45,6.47-6.3c-2.22-0.57-2.47-2.28-0.82-4.89c2.31-3.64,4.42-7.41,6.61-11.12
                c0.34-0.34,0.67-0.67,1.01-1.01c0.67-0.99,1.34-1.99,2.01-2.98c0.33-0.33,0.67-0.67,1-1c0.33-0.32,0.67-0.65,1-0.97
                c0.67-1.01,1.34-2.02,2.01-3.03c0.33-0.32,0.66-0.64,0.99-0.96c0.66-1.02,1.32-2.03,1.98-3.05l0.01,0.01c-0.67,1-1.35,2-2.02,3
                c-0.33,0.32-0.66,0.64-0.99,0.96c-0.67,1.01-1.34,2.02-2,3.03C488.79,279.73,488.46,280.06,488.13,280.39z M266.34,1100.86
                c0.33-0.34,0.65-0.68,0.98-1.02c0,0-0.05-0.05-0.05-0.05c-0.33,0.34-0.65,0.68-0.98,1.02c-0.31,0.32-0.62,0.65-0.93,0.97
                c-0.67,0.34-1.33,0.68-2.01,1.01c0.68-0.32,1.37-0.65,2.05-0.97C265.71,1101.49,266.02,1101.17,266.34,1100.86z M1024.63,1066.82
                l0.14-0.13c0,0-0.12,0.14-0.12,0.14l-0.12,0.11L1024.63,1066.82z M1058.61,1060.81l0.12,0.14l-0.14-0.12
                c-0.34-0.33-0.68-0.66-1.01-1c-14.84-22.81-30.74-44.52-53.62-60.34c-30.5-21.09-63.17-37.18-72.54-34.58
                c3.22,3.03,6.3,5.4,11.09,4.03c1.33-0.38,3.07,0.7,4.63,1.12c-0.47,1.28-0.94,2.57-1.4,3.85l-0.31,0.16c0,0,0.27-0.2,0.27-0.2
                c3.25,0.83,6.51,1.66,10.64,2.71c-2.01,2.63-3.4,4.43-4.86,6.34c2.79-0.5,5.29-0.95,8.41-1.5c-0.49,2.15-0.78,3.43-1.19,5.24
                c3.96,0.78,7.62,1.29,11.1,2.39c0.58,0.18,1.01,3.29,0.37,4.37c-1.16,1.97-3.18,3.45-5.17,5.46c8.24-0.31,9.79,0.89,8.86,6.18
                c3.35,0.6,6.7,1.19,10.71,1.91c-3.95,4.49-3.35,7.99,0.86,10.22c7.73,4.1,15.67,7.82,23.23,12.21
                c7.73,4.49,15.06,9.66,22.65,14.39c8.71,5.42,17.52,10.69,26.28,16.02C1057.94,1060.14,1058.27,1060.48,1058.61,1060.81z
                M695.95,897.92c-0.34-0.32-0.68-0.65-1.03-0.97c-7.97-4.66-15.48-10.55-26.06-9.92c2.52-3.75,5.1-7.33,8.33-10.17
                c4.03-3.54,7.24-8.72,13.79-8.34c0.56,0.03,1.35-1.38,1.79-2.24c2.06-4.1,5.26-4.77,9.6-4.21c2.87,0.37,5.94-0.76,8.9-1.29
                c2.93-0.52,5.92-1.84,8.75-1.54c4.11,0.43,7.99,2.03,11.88-1.06c0.87-0.69,3.32-0.22,4.57,0.52c6.43,3.75,13.05,0.88,19.17-0.12
                c6.33-1.03,11.61-0.79,15.9,4.49c1.32,1.63,2.71,3.2,4.5,5.3c2.86-2.68,5.29-5.03,7.8-7.28c0.71-0.63,1.61-1.3,2.5-1.43
                c4.77-0.65,9.62-0.87,14.34-1.77c3.1-0.59,6.04-2.07,10.15-3.54c-2.52-2.72-3.89-5.52-5.86-6.05c-5.42-1.45-9.52-7.2-16.09-4.97
                c-0.9,0.3-2.28-0.36-3.27-0.89c-4.86-2.59-10.86,0.79-15.47-3c-0.09-0.08-0.35-0.03-0.48,0.04c-6.13,3.01-13.71-3.69-19.33,2.88
                c-0.14,0.16-0.65-0.04-0.97,0.02c-4.61,0.86-9.21,1.73-13.81,2.6c-0.34-0.25-0.69-0.5-1.03-0.74c5.7-4.7,5.59-13.54,13.56-16.71
                c1.38-0.55,2.59-2.01,3.45-3.33c4.92-7.5,4.87-7.53,12.87-5.83c2.01-1.92,4.68-3.5,5.88-5.83c1.92-3.72,4.7-4.45,8.42-4.98
                c4.25-0.61,9.94-0.97,12.17-3.73c3.72-4.61,6.8-5.09,10.8-2.73c5.07-3.98,9.89-7.58,14.46-11.46c2.04-1.73,4.9-3.92,5.12-6.12
                c1.11-11.41,1.53-22.89,2.03-34.36c0.04-1.03-0.82-2.62-1.7-3.04c-1.74-0.84-10.98,3.95-11.49,5.83
                c-0.89,3.28-2.82,4.15-6.01,3.37c-1.31-0.32-3.49-0.51-4.08,0.22c-3.2,3.9-7.41,3.64-11.79,3.98c-5.39,0.42-10.33,1.22-12.73,7.99
                c-2.29,6.47-8.88,8.1-15.57,5.92c-1.45-0.47-3.22,0.11-4.81-0.07c-5.77-0.65-11.36,3.6-17.3,0.58c-0.69-0.35-2.83,0.79-3.37,1.76
                c-3.96,7.18-3.87,7.05-12.33,6.26c-1.51-0.14-4.14,1.37-4.64,2.71c-1.52,4.08-4.08,5.9-8.28,5.7c-2.55-0.12-3.7,0.95-5.24,3.29
                c-2.78,4.22-6.42,7.85-8.35,12.69c-0.25,0.63-1.46,0.82-1.99,1.43c-2.17,2.51-4.35,5.03-6.32,7.7c-2.8,3.78-5.51,7.63-8.08,11.56
                c-1.19,1.82-2.85,3.98-2.74,5.89c0.38,6.35-4.96,10.06-6.49,15.46c-0.2,0.72-1.5,1.4-2.41,1.62c-3.92,0.94-4.48,3.51-4.46,7.13
                c0.03,5.08,0.23,10.48-1.34,15.17c-2.07,6.18-7.45,11.3-4.49,20.01c-4.62-2.61-7.91-4.73-11.44-6.32
                c-1.44-0.65-4.33-0.84-4.97-0.01c-3.75,4.84-8.43,4.66-13.4,3.12c-3.93-1.22-6.92-0.68-9.76,2.61c-2.93,3.4-5.87,6.87-11.1,7.42
                c-3.57,0.38-6.17,2.28-3.83,6.89c0.68,1.34-0.19,3.79-0.93,5.49c-2.78,6.39-5.79,12.68-8.71,19.01c3.14-3.68,4.67-8.49,7.86-11.57
                c3.77-3.64,9.14-5.57,13.54-8.63c5.61-3.9,11.06-7.88,18.32-8.03c1.04-0.02,2.22-0.82,3.05-1.57c3.78-3.39,7.22-3.56,10.73,0.46
                c2.17,2.48,4.34,2.79,7.2,0.25c3.86-3.43,7.68-6.69,13.95-6.07c3.77,0.37,8.05,0.27,11.73-0.09c5.53-0.55,10.31,1.44,15.46,1.66
                c0.32,0.34,0.65,0.68,0.97,1.02c0.32,0.33,0.64,0.67,0.97,1c0.32,0.33,0.64,0.66,0.96,0.99c0,0,0.06-0.06,0.06-0.06
                c-0.32-0.32-0.65-0.65-0.97-0.97C696.6,898.58,696.28,898.25,695.95,897.92z M528.08,295.4l-0.14-0.18l0.17,0.15l0.21,0.19
                C528.31,295.56,528.08,295.4,528.08,295.4z M563.06,318.39c0.33,0.33,0.66,0.65,0.99,0.98c0.67,1,1.35,1.99,2.02,2.99
                c0.28,1.36,0.56,2.73,1.05,5.13c-9.32-2.93-15.88-8.59-23.04-13.14c-0.32-0.33-0.64-0.65-0.96-0.98
                c-0.32-0.33-0.65-0.66-0.97-0.99c-0.33-0.33-0.66-0.66-0.99-1c-0.37-0.37-0.75-0.74-1.12-1.11c-0.34-0.33-0.67-0.66-1.01-0.99
                c-0.34-0.34-0.67-0.67-1.01-1.01c-0.32-0.3-0.64-0.6-0.96-0.9l-0.12-0.17l0.16,0.16c0.29,0.32,0.57,0.65,0.86,0.97
                c0.34,0.34,0.67,0.67,1.01,1.01c0.34,0.33,0.67,0.66,1.01,0.99c0.37,0.37,0.75,0.74,1.12,1.1c0.33,0.33,0.66,0.66,0.99,0.99
                c0.33,0.33,0.66,0.65,0.99,0.98c0.32,0.32,0.65,0.64,0.97,0.96c4.87,3.73,9.61,7.63,14.62,11.14
                c9.12,6.38,15.88,14.58,19.54,25.09c0.79,2.27,1.04,5.68-0.13,7.47c-2.42,3.67-1.39,5.78,1.18,8.6
                c14.91,16.37,33.4,26.44,54.74,31.62l-0.03,0.03c0.19-5.54-2.32-8.55-7.73-10.52c-22.08-8.03-38.18-22.38-46.36-45.01
                c-2.84-7.85-7.09-15.07-13.85-20.42c-0.66-1.01-1.32-2.02-1.98-3.03c-0.33-0.33-0.65-0.66-0.98-0.99
                c-0.33-0.33-0.66-0.66-0.98-0.99c-0.33-0.32-0.66-0.65-0.99-0.97c-0.33-0.33-0.67-0.66-1-0.99c-0.34-0.33-0.68-0.66-1.02-0.99
                c-0.67-1.02-1.35-2.05-2.02-3.07c-0.33-0.32-0.67-0.65-1-0.97c-0.34-0.33-0.68-0.66-1.02-0.99c-0.67-1-1.33-2-2-3
                c-2.19-4.66-4.49-9.26-6.54-13.98c-2.02-4.63-3.78-9.37-5.65-14.06c-0.75,0.37-1.51,0.74-2.26,1.11
                c4.06,9.34,8.09,18.69,14.49,26.9c0.66,1,1.33,2,1.99,3.01c0.33,0.34,0.66,0.68,0.99,1.03c0.33,0.33,0.66,0.66,0.99,0.99
                c0.67,1.03,1.33,2.06,1.99,3.09c0.34,0.33,0.68,0.66,1.01,0.99c0.33,0.33,0.67,0.66,1,0.99c0.33,0.32,0.67,0.64,1,0.97
                C562.4,317.74,562.73,318.07,563.06,318.39z M603.02,317.38c0.33,0.33,0.67,0.66,1,0.99c5.06,6.13,10.59,11.93,15.07,18.45
                c9.17,13.34,19.98,25.71,25.7,41.22c0.34,0.91,0.95,1.8,1.66,2.46c0.69,0.64,1.68,0.96,2.87,1.6
                c-4.96-11.01-9.64-21.4-14.31-31.78c-0.14-0.25-0.28-0.5-0.43-0.74c0.13,0.25,0.27,0.51,0.4,0.76c-1.99,3.25-3.6,0.53-4.5-0.67
                c-4.62-6.21-8.99-12.61-13.43-18.96c-0.85-1.21-1.34-2.77-2.41-3.7c-3.44-3-7.07-5.78-10.63-8.64c-0.33-0.33-0.66-0.67-0.98-1
                c-0.33-0.33-0.66-0.67-1-1c-0.33-0.33-0.66-0.67-0.99-1c-0.34-0.33-0.67-0.66-1.01-1c-0.33-0.33-0.67-0.66-1-0.99
                c-0.33-0.7-0.67-1.39-1-2.09c-0.32-0.31-0.65-0.62-0.97-0.93c-0.34-0.33-0.68-0.66-1.02-0.99c-0.34-0.68-0.67-1.35-1.01-2.03
                c-0.33-0.32-0.66-0.65-1-0.97c-0.33-0.66-0.67-1.33-1-1.99l-0.01,0c0.33,0.67,0.66,1.34,0.99,2.01c0.33,0.33,0.66,0.65,0.99,0.98
                c0.33,0.68,0.67,1.35,1,2.03c0.34,0.33,0.68,0.66,1.01,1c0.32,0.31,0.65,0.63,0.97,0.94c0.34,0.69,0.67,1.38,1.01,2.08
                c0.33,0.33,0.67,0.66,1,0.99c0.33,0.33,0.67,0.66,1,1c0.33,0.33,0.67,0.66,1,0.99C602.35,316.72,602.69,317.05,603.02,317.38z
                M959.71,1025.85c0.33-0.33,0.66-0.65,0.99-0.98c2.47-2.03,4.93-4.07,7.4-6.1c0.37,0.4,0.75,0.81,1.12,1.21
                c-2.51,4.62-5.02,9.25-7.52,13.87l-0.02-0.02c4.51-4.49,7.07-11.36,15.5-12.9c-3.19,5.98-5.84,10.95-8.49,15.93l-0.03-0.03
                c4.5-3.92,6.77-10.35,14.04-12.92c-3.7,5.78-6.77,10.57-9.84,15.36c0.35,0.23,0.7,0.45,1.06,0.68c4.15-4.1,6.49-10.15,13.07-11.86
                c-2.66,5.06-5.33,10.11-7.99,15.17c5.36-4.62,8.05-11.5,14.4-14.72c0.38,0.31,0.75,0.62,1.13,0.94c-3.25,5.2-6.5,10.41-9.75,15.61
                c0.3,0.17,0.61,0.35,0.91,0.52c3.01-3.99,6.02-7.98,9.02-11.97c0.49,0.3,0.98,0.6,1.46,0.9c-1.84,4.1-3.67,8.21-5.51,12.31
                l-0.17,0.28l0.14-0.3c2.8-3.41,5.59-6.81,8.39-10.22c0.49,0.31,0.98,0.63,1.47,0.94c-1.94,5.43-3.89,10.87-5.83,16.3l-0.02-0.02
                c2.54-3.81,5.09-7.62,7.63-11.43c0.7,0.35,1.4,0.7,2.1,1.05c-1.49,5.33-2.97,10.67-4.46,16c0.22,0.06,0.44,0.12,0.66,0.18
                c2.85-3.8,5.7-7.6,8.55-11.4c0.39,0.27,0.77,0.54,1.16,0.82c-0.21,0.93-0.41,1.87-0.62,2.8c-2,4-4,8-6,12c0,0-0.03-0.03-0.03-0.03
                c2-4,3.99-8,5.99-12.01c1.42-1.12,2.84-2.25,4.26-3.37c0.38,0.33,0.77,0.66,1.15,0.99c-1.65,2.75-3.46,5.42-4.91,8.28
                c-1.48,2.92-2.61,6.02-3.89,9.05c2.71-2.39,4.25-5.32,6.14-8c1.73-2.45,3.85-4.62,5.79-6.91c0.27,0.28,0.54,0.56,0.81,0.84
                c-3.11,7.72-6.22,15.44-9.33,23.16l-0.02-0.02c5.44-7.9,7.23-18.23,16.01-24.03c-0.33,2.84-1.44,5.26-2.56,7.68
                c-2.82,6.12-5.63,12.24-8.45,18.36l0,0c5.44-7,8.82-15.8,17.89-19.81c-4.62,8.42-9.25,16.84-13.87,25.26
                c0.43,0.23,0.87,0.47,1.3,0.7c4.78-6.73,9.57-13.46,14.35-20.19c0.62,0.38,1.23,0.77,1.85,1.15
                c-4.85,9.44-12.66,17.64-13.56,30.14c6.76-9.22,12.78-17.43,18.79-25.63c0.64,0.42,1.29,0.84,1.93,1.25
                c-5.66,10.96-11.33,21.92-16.99,32.88c0.34,0.2,0.69,0.4,1.03,0.6c7.36-10.43,14.73-20.87,22.09-31.3
                c0.59,0.36,1.18,0.72,1.76,1.08c-5.81,12.68-11.63,25.36-17.44,38.04c4.16-4.97,7.43-10.48,10.93-15.83
                c3.43-5.25,7.09-10.34,10.66-15.5c0.47,0.32,0.93,0.65,1.4,0.97c-2.55,14.41-11.36,26.12-17.42,39.04
                c9.35-10.19,13.05-24.25,23.66-33.66c-0.61,5.09-1.77,9.88-3.6,14.41c-3.04,7.54-6.52,14.91-9.81,22.36l-0.21,0.3l0.16-0.33
                c5.58-9.69,11.16-19.38,16.75-29.08c0.56,0.23,1.12,0.45,1.69,0.68c-2.47,8.81-4.94,17.62-7.4,26.44
                c-0.35,0.64-0.7,1.29-1.05,1.93l-0.11,0.29c0,0,0.16-0.25,0.16-0.25c0.33-0.67,0.65-1.33,0.98-2c2.63-5.92,5.38-11.78,7.82-17.77
                c0.96-2.36,1.76-5,1.74-7.51c-0.04-6.79-0.38-13.6-0.99-20.37c-0.19-2.1-1.07-4.71-2.56-6.02c-3.59-3.15-7.62-5.85-11.68-8.4
                c-11.63-7.32-23.62-14.08-34.97-21.8c-10.42-7.08-20.97-13.58-32.49-18.87c-16.89-7.76-34.86-10.57-52.74-14.15
                c-2.66-0.67-5.32-1.34-7.99-2.01c0,0-0.01,0.02-0.01,0.02c2.68,0.65,5.36,1.3,8.03,1.95c0.67,1.35,1.33,2.7,2,4.06l-0.36,0.16
                l0.32-0.2c4.75,0,9.49,0,14.24,0c0.2,0.63,0.4,1.26,0.6,1.89c-2.73,2.61-5.46,5.21-8.2,7.82c0.19,0.25,0.37,0.51,0.56,0.76
                c4.56-2.49,9.12-4.98,13.67-7.47c0.29,0.4,0.58,0.8,0.87,1.2c-1.59,2.27-3.18,4.53-4.77,6.8l0.01,0.02
                c2.98-1.59,5.96-3.18,8.94-4.77c0.36,0.43,0.72,0.87,1.08,1.3c-1.01,2.15-2.03,4.31-3.04,6.46c-0.33,0.33-0.66,0.67-0.98,1
                L959.71,1025.85z M530.07,298.4l-0.15-0.19l0.18,0.16l0.19,0.2C530.3,298.57,530.07,298.4,530.07,298.4z M770.87,1014.85
                l0.12,0.12l-0.12-0.11c-0.32-0.33-0.64-0.65-0.96-0.98c-0.35-0.64-0.7-1.29-1.05-1.93c-0.13,0.08-0.25,0.16-0.38,0.23
                c0.46,0.58,0.92,1.16,1.39,1.75C770.19,1014.24,770.53,1014.55,770.87,1014.85z M1012.66,1002.86l0.12,0.16
                c0,0-0.15-0.14-0.15-0.14c-0.33-0.34-0.65-0.67-0.98-1.01c-9.83-16.82-22.38-31.24-37.97-42.98c-0.67-0.33-1.34-0.65-2.01-0.97
                c-0.33-0.34-0.66-0.68-0.98-1.01l-0.02,0.01c0.34,0.33,0.68,0.66,1.02,0.99c0.66,0.34,1.31,0.68,1.97,1.02
                c-0.44,1.16-0.88,2.32-1.32,3.47c0.43-0.23,0.85-0.45,1.27-0.68c2.41,3.56,4.82,7.11,7.9,11.64c3.32-0.08,3.53,3.79,4.21,7.52
                c0.3,1.65,0.95,3.75,2.17,4.63c7.8,5.62,15.81,10.95,23.74,16.38C1011.98,1002.2,1012.32,1002.53,1012.66,1002.86z M532.07,301.39
                l-0.15-0.2l0.19,0.19c0.28,0.32,0.57,0.64,0.85,0.96c0.34,0.33,0.67,0.66,1,0.98l0.1,0.09c0,0-0.02-0.17-0.02-0.16
                c-0.34-0.33-0.68-0.65-1.02-0.98C532.72,301.98,532.4,301.69,532.07,301.39z M591.05,301.38l-0.02,0.02
                C591.03,301.4,591.05,301.38,591.05,301.38z M492.13,302.39l-0.11-0.12l0.12,0.11c0.33,0.67,0.67,1.33,1,2
                c0.73,1.84,1.72,3.64,2.07,5.55c0.19,1.05-0.69,2.29-1.08,3.45c-0.67-0.33-1.34-0.66-2.02-1c-1-0.67-2-1.33-3-2
                c-1.93-1.97-3.85-3.93-5.78-5.9c-0.23,0.27-0.47,0.55-0.7,0.82c2.17,1.68,4.34,3.37,6.51,5.05c1,0.67,2,1.33,3,2
                c0.67,0.33,1.35,0.66,2.02,1c5.16,4.39,10.31,8.77,15.47,13.16c0.24-0.27,0.47-0.53,0.71-0.8c-5.74-7.11-11.47-14.21-17.21-21.32
                C492.79,303.73,492.46,303.06,492.13,302.39z M490.13,299.39c0.67-0.33,1.33-0.67,2-1c0.64-0.34,1.28-0.68,1.92-1.02
                c0.66-0.33,1.31-0.67,1.97-1c0.7-0.33,1.4-0.66,2.1-0.99l0.14-0.12l-0.12,0.13c-0.72,0.3-1.45,0.6-2.17,0.9
                c-0.66,0.34-1.31,0.67-1.97,1.01c-0.63,0.35-1.26,0.7-1.9,1.06c-0.66,0.34-1.33,0.67-1.99,1.01l-0.21,0.15L490.13,299.39z
                M247.35,1031.85l-0.13-0.28c0,0,0.14,0.26,0.14,0.26c0.33,0.67,0.67,1.33,1,2c0.17,3.23,0.19,6.48,0.59,9.68
                c0.12,1.01,1.07,2.37,1.97,2.73c2.08,0.81,4.37,1.1,8.04,1.94c-4.06-5.47-7.34-9.9-10.62-14.33
                C248.01,1033.19,247.68,1032.52,247.35,1031.85z M1000.64,1047.84c-0.32,0.68-0.64,1.36-0.96,2.03c-2.92,3.62-4.43,7.43-3.7,12.38
                c1.67,11.33,2.95,22.73,4.06,34.14c0.5,5.15,2.47,9.11,6.59,12.22c9.67,7.31,18.53,15.49,25.69,25.34
                c2.43,3.34,4.36,7.04,6.79,10.37c4.87,6.66,10.03,13.11,14.86,19.79c2.16,2.99,3.82,6.35,6.32,10.59
                c2.24-12.08,4.26-23.01,6.28-33.94c0.13-0.27,0.26-0.54,0.26-0.54c0,0.01-0.11,0.29-0.23,0.57c-1.31,1.96-2.62,3.93-3.93,5.89
                c-0.47-0.16-0.93-0.31-1.4-0.47c-1.94-7.46,1.23-14.83,1.59-23.15c-2.92,5.29-5.36,9.73-7.8,14.17c-0.6-0.27-1.21-0.55-1.81-0.82
                c0.93-3.25,1.82-6.52,2.81-9.75c2.14-6.97,4.34-13.93,6.52-20.9c0.34-1,0.68-2,1.02-3l0.13-0.23l-0.14,0.21
                c-0.32,1.02-0.64,2.04-0.96,3.05c-4.95,7.98-7.98,17.26-15.87,23.43c0.98-13.06,10.64-23.03,13.01-36.98
                c-7.9,11.74-12.08,24.42-22.48,34.32c-1.69-6.68,1.75-10.46,3.74-14.63c3.85-8.06,7.72-16.11,11.58-24.16l0.01,0.01
                c-7.39,9.44-10.99,21.71-21.7,28.89c0.09-3.29,0.92-6.18,2.11-8.91c2.77-6.37,5.71-12.66,8.57-18.99c0.34-0.67,0.67-1.33,1.01-2
                c0.34-0.66,0.67-1.33,1.01-1.99l-0.01-0.01c-0.32,0.68-0.65,1.35-0.97,2.03c-0.33,0.67-0.66,1.34-0.99,2.01
                c-6.11,6.48-9.6,15.32-18.13,19.99c-0.09-2.73,0.23-5.27,1.26-7.48c2.44-5.26,5.21-10.37,7.84-15.55c0.68-1,1.35-2,2.03-2.99
                c0.32-0.67,0.65-1.34,0.97-2c-0.33,0.66-0.67,1.32-1,1.98c-0.66,1.01-1.31,2.03-1.97,3.04c-3.68,4.39-7.36,8.79-11.04,13.18
                c-0.74-0.35-1.49-0.71-2.23-1.06c0.76-4.05,1.51-8.09,2.27-12.14l-0.01-0.02c-1.48,1.04-2.95,2.08-4.43,3.12
                c-0.45-0.3-0.9-0.6-1.35-0.9c1.16-4.13,2.32-8.25,3.48-12.38c-0.27-0.24-0.53-0.48-0.8-0.72c-1.62,2.56-3.25,5.12-4.87,7.68
                c-0.54-0.44-1.08-0.87-1.63-1.31c-0.23-1.18-0.45-2.37-0.65-3.4c-1.21,0.88-2.45,1.79-3.69,2.7c-0.52-0.24-1.04-0.47-1.56-0.71
                c0.84-3.69,1.69-7.37,2.53-11.06l-0.01-0.01c-1.39,1.33-2.78,2.66-4.57,4.37c-1.39-3.8-2.41-6.58-3.43-9.37l0.02,0.02
                c-2.83,2.23-3.54,0.4-3.23-1.97c0.53-4.04,1.45-8.02,2.21-12.03C999.98,1049.17,1000.31,1048.51,1000.64,1047.84L1000.64,1047.84z
                M1060.57,26.52c-0.35,0.64-0.71,1.28-1.06,1.91c-4.55,10.79-10.8,20.29-20.65,27.36c-0.55-2.64-0.77-5.11-0.12-7.33
                c1.88-6.37,4-12.67,6.22-18.93c2.67-7.52,5.6-14.95,8.25-22.47c0.49-1.39,0.2-3.05,0.32-5.77c-9.32,11.01-24.64,82.16-19.13,89.19
                c0.76-2.25,1.68-3.93,1.86-5.68c2.45-24.04,16.12-41.33,33.9-55.99c8.52-7.03,14.92-15.37,18.84-25.64
                c-2.27,3.39-4.2,7.02-6.38,10.48c-4.44,7.05-10.17,12.76-17.97,16.03c-1.74,0.73-4.18,2.55-5.06-1.18c0.33-0.65,0.66-1.3,1-1.95
                l0.16-0.26C1060.74,26.28,1060.57,26.52,1060.57,26.52z M1022.62,1069.82c-0.33,0.66-0.65,1.33-0.98,1.99l0.01,0
                C1021.97,1071.15,1022.3,1070.48,1022.62,1069.82z M750.89,337.37c0.67,0.33,1.34,0.65,2.01,0.98c0.67,0.33,1.35,0.65,2.02,0.98
                c1.86,1.46,3.73,2.93,5.59,4.4c-0.13,0.52-0.27,1.03-0.4,1.55c-3.41-0.31-6.81-0.61-10.22-0.92c-1-0.33-2-0.67-3-1
                c-1.01-0.34-2.02-0.67-3.02-1.01l-0.24-0.14l0.21,0.17c1.02,0.32,2.05,0.64,3.07,0.96c1.01,0.33,2.01,0.66,3.02,0.98
                c3.97,0.68,5.72,6.18,10.62,4.87c1.55-0.41,3.14-0.68,4.71-1.01c-0.86-1.88-1.24-4.38-2.68-5.48c-2.17-1.64-5.08-2.28-7.68-3.34
                c-0.67-0.33-1.34-0.67-2-1C752.22,338.03,751.55,337.7,750.89,337.37z M526.11,292.39l0.13,0.16l-0.15-0.14
                c-0.33-0.65-0.65-1.3-0.98-1.95c-0.33-0.68-0.66-1.37-0.99-2.05c-0.34-0.67-0.68-1.34-1.03-2.01c-0.19-0.2-0.39-0.4-0.58-0.6
                c0.2,0.2,0.4,0.39,0.6,0.59c0.32,0.69,0.64,1.37,0.97,2.06c0.32,0.69,0.65,1.38,0.97,2.07
                C525.4,291.14,525.76,291.76,526.11,292.39z M671.94,415.33l-0.16-0.3l0.19,0.27c0.33,0.67,0.67,1.33,1,2
                c0.94,1.73,1.55,3.81,2.9,5.14c3.39,3.35,7.1,6.37,10.71,9.5c0.75,0.65,1.51,1.42,2.41,1.71c7.91,2.56,12.02,9.16,16.37,15.43
                c3.85,5.54,7.03,11.57,11.15,16.89c9.25,11.92,21.27,20.03,35.89,23.99c9.31,2.52,18.71,4.67,28.08,6.97
                c4.18,1.03,8.59,3.4,12.49,2.74c8.57-1.45,16.11,1.1,23.85,3.57c-18.98-24.22-42.63-42.91-70.04-56.37
                c-22.31-10.96-45.45-20.23-68.3-30.06c-1.49-0.64-3.67,0.32-5.54,0.53C672.61,416.66,672.28,416,671.94,415.33z M670.95,420.32
                c-0.33-0.68-0.66-1.35-0.99-2.03c-0.17-0.2-0.34-0.41-0.51-0.61c0.18,0.19,0.36,0.39,0.55,0.58c0.33,0.68,0.66,1.36,0.99,2.04
                c0.33,0.66,0.67,1.33,1,1.99c0.67,0.99,1.34,1.99,2,2.98l-0.04,0.04c-0.66-1-1.32-2-1.99-3
                C671.62,421.65,671.28,420.99,670.95,420.32z M966.7,174.5l-0.15,0.23c0,0,0.13-0.25,0.13-0.25l0.18-0.24L966.7,174.5z
                M107.49,437.3L107.49,437.3c0.65,0.34,1.3,0.68,1.95,1.02c0.64,0.35,1.27,0.7,1.91,1.06l0.33,0.09c0,0-0.28-0.16-0.27-0.16
                c-0.64-0.34-1.29-0.68-1.93-1.02C108.81,437.95,108.15,437.62,107.49,437.3z M667.94,471.27l-0.11-0.16l0.15,0.15
                c0.67,0.33,1.34,0.65,2.01,0.98l0.11,0.13c0,0-0.12-0.12-0.12-0.12C669.29,471.93,668.61,471.6,667.94,471.27z M155.45,249.42
                c0.33,0.67,0.67,1.34,1,2.01c3.53,6.97,6.95,13.8,6.62,22.13c-0.11,2.76,2.73,7.65,4.82,8c3.13,0.52,8.52-1.12,9.91-3.57
                c5.79-10.19,10.55-20.99,15.39-31.7c0.54-1.19-0.85-3.24-1.35-4.89c-1.31,1.15-3.17,2.06-3.83,3.51
                c-2.12,4.68-3.48,9.71-5.72,14.33c-2.27,4.69-5.59,8.67-11.1,10.48c0.96-1.56,1.93-3.12,2.84-4.59
                c-7.97-10.26-3.04-21.28-2.59-31.58c-7.61-0.65-7.51,4.75-7.78,9.94c-0.09,1.65-0.41,3.3-0.63,4.94
                c-0.32,2.46-0.65,4.91-1.17,8.84c-2.59-2.77-4.02-4.29-5.45-5.81c-0.33-0.67-0.66-1.35-0.99-2.02c-0.33-0.67-0.67-1.34-1-2
                c-1.51-3.08,0.03-7.85-5-8.97c-0.33-0.67-0.66-1.34-0.99-2.01l0.01-0.01c0.34,0.67,0.67,1.33,1.01,2c1.67,2.99,3.33,5.98,5,8.97
                C154.79,248.07,155.12,248.74,155.45,249.42z M204.41,1382.58c0.33-0.67,0.65-1.33,0.98-2c1-1.66,1.99-3.32,2.99-4.99
                c0.33-0.67,0.67-1.34,1-2.01c0.33-0.67,0.67-1.33,1-2c0,0,0.01,0.01,0.01,0.01c-0.33,0.66-0.67,1.33-1,1.99
                c-0.33,0.68-0.65,1.35-0.98,2.03c-1,1.65-2.01,3.31-3.01,4.96c-0.33,0.67-0.66,1.34-1,2.01l-0.14,0.1
                C204.27,1382.7,204.41,1382.58,204.41,1382.58z M860.78,1341.63c0.33,0.33,0.65,0.66,0.98,0.99c1.02,0.66,2.03,1.32,3.05,1.97
                c7.19,7.91,14.37,15.81,20.69,22.76c-3.94-8.97-11.27-16.76-20.71-22.72c-1-0.68-1.99-1.35-2.99-2.03c-0.32-0.33-0.65-0.67-0.97-1
                c-0.68-0.32-1.36-0.65-2.04-0.97l-0.1-0.12l0.11,0.11C859.45,1340.96,860.12,1341.3,860.78,1341.63z M1080.59,1377.58
                c0.34-0.67,0.68-1.34,1.01-2c0.33-0.32,0.65-0.64,0.98-0.97c0.66-1.02,1.31-2.03,1.97-3.05c2.37-2.66,4.73-5.31,8.01-8.99
                c2.11,8.94,0.55,16.38-1.84,23.45c-2.46,7.26-6.05,14.13-9.56,22.13c2.1-0.96,3.61-1.45,4.89-2.28c4.19-2.7,8.25-5.62,12.49-8.25
                c1.43-0.89,3.25-1.14,4.89-1.69c-0.12,1.83-0.11,3.69-0.4,5.5c-0.15,0.94-0.82,1.81-1.25,2.71
                c-12.59,26.23-28.41,50.42-46.17,73.38l0,0c0.79-0.24,1.74-0.28,2.34-0.76c9.14-7.4,18.27-14.83,27.32-22.35
                c1.9-1.58,3.45-3.58,5.16-5.38c0.45,0.43,0.9,0.85,1.36,1.28c-9.11,20.05-28.2,31.47-43.16,47.75c14.54,0,28.14,0.11,41.73-0.16
                c1.54-0.03,3.32-1.98,4.5-3.43c4.19-5.17,7.38-11.47,12.4-15.59c11.42-9.39,13.56-23.71,21.66-35.75
                c-2.43,0.39-3.75,0.61-5.78,0.94c0.31-1.74,0.4-2.99,0.76-4.16c4.67-15.59,9.63-31.1,14-46.77c3.99-14.33,5.63-29.05,5.35-43.95
                c-0.32-16.81-0.78-33.61-0.96-50.42c-0.04-3.51,0.84-7.04,1.48-12.04c4.57,3.48,8.55,5.53,11.1,8.73
                c2.66,3.33,3.94,7.76,5.81,11.69c3.11-15.44-3.07-28.46-9.95-41.39c-1.6-3-2.83-6.37-3.37-9.71c-0.79-4.91-0.78-9.94-1.15-14.92
                c-0.62-8.44-3.51-16.02-8.4-23.37c-2.8,4.35-5.24,8.15-7.69,11.95c-0.48-0.27-0.96-0.54-1.45-0.81c0-1.94-0.14-3.88,0.02-5.81
                c1.86-22.7,4.21-45.36,5.51-68.09c1.23-21.42,1.35-42.9,2.01-64.35c0.13-4.3,0.58-8.6,0.72-12.9c0.36-11.45,0.13-22.96,1.1-34.35
                c0.76-8.89,4.14-17.62,4.35-26.47c0.56-24.29,0.2-48.6-0.04-72.9c-0.2-20.8-0.85-41.6-1.04-62.41c-0.04-4.25,1.08-8.51,1.67-12.76
                c0.67-0.02,1.33-0.04,2-0.05c1.83,5.87,3.66,11.73,5.21,16.7c0-13.16,0-26.97,0-40.77c0-23.81,0-47.63-0.01-71.44
                c0-7.15,0-14.29-0.11-21.44c-0.18-11.6-0.4-23.2-0.69-34.8c-0.41-16.77-1-33.54-1.33-50.31c-0.28-13.97,0.08-27.97-0.54-41.92
                c-0.64-14.45-2.15-28.86-3.17-43.3c-0.14-1.98,0.39-4,0.6-6c0.43-0.1,0.87-0.2,1.3-0.3c4.09,7.51,6.43,16.3,14.93,21.72
                c0-9.06-0.19-17.36,0.07-25.64c0.15-4.79-2.19-7.95-6.19-9.17c-4.5-1.37-5.23-4.4-5.75-8.2c-1.23-9.06-2.65-18.09-3.96-27.13
                c-0.52-3.59-1.28-6.79-6.22-4.76c-0.37-1.38-0.84-2.32-0.83-3.25c0.12-14.95,0.3-29.9,0.48-44.84
                c0.19-15.78,0.24-31.56,0.66-47.33c0.16-6.05,0.94-12.11,1.82-18.11c0.66-4.51,2.68-8.9,2.82-13.39
                c0.63-20.63,0.73-41.27,1.22-61.91c0.08-3.33,1.2-6.63,1.84-9.94c0.8-0.19,1.59-0.38,2.39-0.56c-0.88,9.49,4.35,14.37,11.66,16.84
                c0-7.75,0.02-16.41-0.01-25.07c-0.02-5.83,1-11.93-0.36-17.42c-2.95-11.86-2.57-23.76-2.5-35.73c0.03-5.43,0.01-10.86,0.01-16.28
                c-1.88,5.08-2.51,10.29-3.51,15.42c-1.91,9.78-3.79,19.59-6.21,29.25c-0.8,3.2-3.21,5.99-5.55,10.14
                c-1.52-3.57-2.28-5.38-2.97-6.99c-2.44,3.89-4.72,7.53-7.01,11.18c-0.72-0.2-1.45-0.4-2.17-0.6
                c-3.09-34.93-3.33-69.97-4.57-104.98c0.46-0.04,0.93-0.09,1.39-0.13c2.57,6.23,5.13,12.45,7.92,19.21
                c7.37-6.22,9.25-12.61,8.59-20.76c-1.47-18.05-2.16-36.17-2.91-54.28c-0.15-3.59,0.53-7.32,1.48-10.82
                c0.44-1.61,2.31-3.67,3.79-3.89c1.17-0.18,2.87,1.99,4.06,3.36c0.79,0.91,1.09,2.24,1.6,3.37c1.29-11.19,1.17-22.3,2.11-33.31
                c0.45-5.25,2.91-10.33,4.45-15.49c0.79-2.64,2.24-5.28,2.26-7.93c0.18-23.31,0.09-46.63,0.13-69.94c0.01-3.25-0.08-5.9-4.57-5.97
                c-4.16-0.07-6.26,1.15-6.12,5.61c0.09,2.81-0.43,5.63-0.6,8.46c-1.4,22.24-2.73,44.48-4.26,66.71c-0.16,2.38-1.51,4.67-2.25,7.02
                c-1.53,4.86-4.01,9.66-4.38,14.61c-1.76,23.56-2.92,47.16-4.37,70.74c-0.23,3.78-0.91,7.53-1.39,11.29
                c-0.62-0.03-1.24-0.05-1.87-0.08c-0.37-4.2-1.09-8.4-1.06-12.6c0.23-31.8,0.52-63.6,1.02-95.39c0.09-5.93,1.37-11.84,1.85-17.77
                c0.43-5.28,0.77-10.59,0.7-15.88c-0.16-12.29-0.58-24.58-0.91-36.88c-0.04-1.6-0.22-3.19-0.33-4.79
                c-0.58,0.01-1.17,0.03-1.75,0.04c-0.32,5.37-0.82,10.74-0.94,16.11c-0.31,14.45,0.18,28.97-5.47,42.77
                c-1.05,2.56-1.34,5.5-1.56,8.3c-1.67,21.36-5.16,42.58-4.53,64.14c0.23,7.94-0.88,15.93-1.62,23.88
                c-0.45,4.89-1.35,9.73-2.05,14.6c-0.73-0.05-1.45-0.09-2.18-0.14c-0.42-4.2-1.1-8.39-1.22-12.6c-0.46-16.3-0.85-32.6-1.07-48.91
                c-0.15-11.12,0.02-22.25,0.05-33.38c0.01-2.94,0.13-5.89-0.02-8.82c-0.2-3.96-1.03-7.93-0.87-11.86
                c0.7-17.09,1.72-34.17,2.39-51.26c0.05-1.22-1.91-3.64-2.64-3.53c-1.55,0.24-3.02,1.65-4.28,2.84c-0.44,0.41-0.15,1.6-0.18,2.43
                c-0.92,24.95-1.91,49.89-2.73,74.84c-0.87,26.45-1.57,52.91-2.34,79.36c-0.41,14.14-0.61,28.29-1.25,42.43
                c-0.63,13.77-0.11,27.28,5.66,40.12c0.59,1.31,0.83,2.9,0.81,4.36c-0.54,37.29-1.18,74.58-1.71,111.86
                c-0.15,10.28-0.02,20.56-0.02,30.51c1.94-3.82,4.07-7.77,5.96-11.82c0.96-2.05,2-4.26,2.15-6.45c0.81-11.94,1.14-23.92,2.06-35.85
                c0.6-7.69,1.6-15.41,6.12-22.28c0.81,1.71,1.22,3.41,1.24,5.11c0.11,6.83,0.24,13.66,0.1,20.48c-0.59,30.12-1.39,60.24-1.8,90.36
                c-0.06,4.37,1.56,8.76,2.6,14.11c2.28-2.66,3.48-4.06,5.38-6.29c0.89,2.8,1.74,4.45,1.9,6.17c1.06,11.75,2.55,23.5,2.82,35.27
                c0.71,31.46,0.81,62.93,1.28,94.39c0.16,10.65,0.65,21.3,1.08,31.94c1.24,31.25,3.3,62.5,3.51,93.76
                c0.14,21.07-2.63,42.15-3.54,63.25c-1.05,24.28-1.14,48.59-2.3,72.86c-0.86,18.1-2.48,36.18-4.25,54.22
                c-3.04,31.11-6.45,62.18-9.71,93.27c-0.43,4.12-0.93,8.23-1.39,12.34c-0.5-0.06-0.99-0.11-1.49-0.17
                c-3.28-30.95,3.36-61.75,1.65-92.73c-2.12,13.07-4.24,26.15-6.36,39.22c-0.81-0.06-1.61-0.11-2.42-0.17
                c-0.58-31.11-0.41-62.26-1.93-93.33c-1.53-31.21-4.73-62.33-7.2-93.5c-0.53,0.02-1.05,0.04-1.58,0.06
                c1.26,91.32,2.51,182.64,3.77,273.96c0.63,0.06,1.26,0.11,1.9,0.17c1.64-5.29,3.28-10.57,4.92-15.86c0.6,0.11,1.2,0.22,1.8,0.33
                c0.16,2.71,0.46,5.42,0.45,8.13c-0.32,48.45-0.57,96.91-1.1,145.36c-0.12,10.8-1.23,21.58-1.96,32.37
                c-0.07,1.03-0.7,2.03-1.08,3.04c-0.44-0.08-0.88-0.16-1.32-0.24c-0.79-8.51-1.58-17.01-2.37-25.52
                c-2.31,8.24-1.98,16.87-7.14,24.17c-2.53-18.08-5.07-36.17-7.6-54.25c-3.52,20.39-6.15,40.65-8.14,60.98
                c-1.71,17.41-2.46,34.89,0.95,52.23c1.44,7.34,1.01,14.2-1.8,21.4c-2.49,6.4-3.23,13.53-4.33,20.41
                c-3.05,19.03-5.66,38.13-8.92,57.11c-1.55,9.01-4.21,17.83-6.37,26.74c18.6-20.54,32.91-43.5,42.52-69.49
                c0.59,1.81,0.47,3.33,0.2,4.83c-3.19,17.63-8.66,34.51-16.79,50.5c-0.68,0.99-1.36,1.99-2.04,2.98c-0.33,0.31-0.67,0.63-1,0.94
                c-0.33,0.69-0.65,1.38-0.98,2.07l-0.13,0.09C1080.46,1377.7,1080.59,1377.58,1080.59,1377.58z M111.5,449.33
                c0.31,0.31,0.63,0.61,0.94,0.92c0.32,3.15,1.25,5.79,5.04,6.03l0.01-0.01c-1.66-2.03-3.33-4.05-4.99-6.08
                c-0.32-0.3-0.63-0.61-0.95-0.91l-0.29-0.14C111.25,449.14,111.5,449.33,111.5,449.33z M1057.6,203.46
                c-0.12,0.26-0.25,0.52-0.36,0.79C1057.36,203.99,1057.48,203.72,1057.6,203.46c0.12-0.27,0.25-0.53,0.37-0.79
                C1057.85,202.93,1057.73,203.2,1057.6,203.46z M1094.55,1337.59c-0.32,1.02-0.64,2.04-0.96,3.06c-1.61,2.71-3.06,5.55-4.86,8.12
                c-9.15,13.05-18.34,26.09-27.68,39c-1.46,2.02-3.78,3.41-5.71,5.09c-0.4-0.26-0.79-0.52-1.19-0.78c0.39-1.66,0.7-3.34,1.18-4.98
                c7.47-25.27,12.57-50.97,15.18-77.23c1.01-10.19,3.83-20.19,5.58-30.31c0.92-5.31,2.62-10.92,1.75-16.02
                c-3-17.39-3.48-34.73-1.67-52.21c2.99-28.94,5.97-57.88,8.99-86.82c0.1-0.94,0.66-1.83,1.39-3.78
                c3.33,24.19,6.48,47.12,9.64,70.06c0.14,0.04,0.28,0.08,0.42,0.11c1.13-9.43,2.25-18.86,3.53-29.61c1.34,1.85,1.93,2.32,2.05,2.88
                c1.1,5.18,2.12,10.38,3.16,15.57c0.18,0.02,0.37,0.04,0.55,0.06c0.56-51.38,1.13-102.76,1.69-154.14
                c-0.23-0.01-0.47-0.03-0.7-0.04c-1.84,4.34-3.68,8.68-5.52,13.01c-0.55-0.15-1.1-0.3-1.65-0.45c-0.18-2.07-0.48-4.14-0.53-6.21
                c-0.4-16.31-0.61-32.62-1.14-48.93c-1.11-34.76-2.59-69.51-3.48-104.28c-0.38-14.8,0.53-29.63,0.55-44.44
                c0.04-37.47-0.1-74.94-0.13-112.41c0-1.13,0.38-2.27,0.59-3.4c0.44,0.03,0.88,0.06,1.33,0.09c7.46,68.56,14.6,137.14,12.92,206.3
                c2.36-12.1,3.44-24.46,8.31-35.86c0.46,0.08,0.93,0.16,1.39,0.24c0,16.19,0,32.37,0,48.56c0.4-8.43,1.31-16.86,2.23-25.29
                c2.12-19.34,4.96-38.63,6.18-58.02c1.47-23.41,1.29-46.92,2.34-70.36c0.91-20.27,3.55-40.5,3.64-60.76
                c0.14-32.94-1.05-65.89-1.9-98.83c-1.04-40.1-2.37-80.19-3.43-120.3c-0.37-13.98-0.13-27.98-0.5-41.96
                c-0.15-5.73-1.17-11.44-1.84-17.69c-1.95,1.73-3.22,2.87-5.54,4.93c-1.92-6.4-4.48-11.78-4.98-17.34
                c-0.72-8.08-0.02-16.29,0.19-24.45c0.69-26.87,1.43-53.74,2.15-80.61c-0.65,1.59-1.06,3.2-1.15,4.84
                c-0.71,12.13-1.07,24.28-2.19,36.37c-0.41,4.42-2.41,8.73-3.93,13c-1.45,4.05-4.03,7.86-4.71,12c-1.52,9.17-2.13,18.48-3.17,27.73
                c-0.18,1.58-0.64,3.12-0.97,4.69c-0.52-0.03-1.03-0.06-1.55-0.09c-0.32-2.21-0.92-4.41-0.92-6.62
                c-0.02-13.32-0.06-26.65,0.19-39.97c0.78-42.77,1.7-85.55,2.47-128.32c0.04-2.42-0.8-4.86-1.28-7.27
                c-1.83-9.08-4.62-18.08-5.28-27.24c-0.76-10.57,0.38-21.27,0.63-31.92c0.47-20.14,0.7-40.28,1.37-60.42
                c0.58-17.63,1.84-35.23,2.42-52.86c0.7-21.29,1.08-42.6,1.33-63.9c0.02-1.71-1.69-4.4-3.22-4.96c-2.63-0.97-5.47-0.42-6.98,2.96
                c-1.69,3.79-3.97,7.31-5.78,11.05c-0.77,1.59-1.34,3.44-1.39,5.18c-1.08,45.1-2.06,90.2-3.1,135.3
                c-0.37,16.14-0.74,32.29-1.32,48.43c-1.11,31.1-2.89,62.18-3.38,93.29c-0.33,20.95,1.1,41.93,1.7,62.89
                c0.57,19.8,1.22,39.6,1.6,59.4c0.22,11.48,0.21,22.98-0.13,34.46c-0.63,20.92-1.81,41.81-2.36,62.73
                c-0.84,31.61-1.28,63.23-2,94.85c-0.97,42.61-2.27,85.21-3,127.82c-0.52,30.3-0.09,60.62-0.55,90.92
                c-0.71,47.09-2.46,94.17-2.53,141.26c-0.04,27.59,2.48,55.18,3.55,82.78c0.7,18.12,1.99,36.33,0.94,54.38
                c-1.01,17.36-4.19,34.64-7.32,51.8c-2.83,15.51-5.77,31-12.18,45.71c-7.7,17.68-14.4,35.8-21.38,53.78
                c-4.33,11.16-10.38,20.99-18.84,29.66c-14.65,15.02-31.91,26.69-48.68,38.95c-4.36,3.19-10.24,4.98-15.67,5.85
                c-7.5,1.2-15.24,1.05-22.89,1.25c-13.76,0.36-23.84,6.32-29.77,18.93c-1.06,2.26-2.48,4.38-3.32,6.71
                c-4.52,12.46-14.04,17.55-26.54,19.51c-32.84,5.16-64.6,14.24-95.27,27.18c-13.48,5.68-27.11,11.11-40.96,15.8
                c-15.86,5.37-32.28,8.37-49.13,6.34c-21.27-2.57-42.47-5.76-63.75-8.25c-15.3-1.79-30.56-1.38-45.48,3.2
                c-6.2,1.9-12.35,4.23-18.7,5.39c-16.5,3.01-33.08,5.63-49.67,8.1c-10.33,1.53-20.76,3.47-31.13,3.42
                c-9.37-0.05-18.74-2.53-28.1-3.98c-12.81-1.97-25.54-4.51-38.66-2.94c-19.74,2.36-36.99,10.64-52.95,21.93
                c-9.47,6.69-19.92,10.59-31.22,12.55c-22.18,3.85-43.1,11.28-62.81,22.13c-2.36,1.3-4.65,2.73-6.97,4.11
                c6.74,1.07,13.04,1.24,19.31,0.96c1.98-0.09,4.19-1.02,5.82-2.22c14.78-10.94,30.87-18.92,48.99-22.65
                c4.36-0.9,8.72-2.33,12.77-4.18c11.79-5.41,23.38-11.25,35.12-16.76c17.31-8.11,34.78-7.28,49.63,4.17
                c21.32,16.43,44.73,20.32,70.22,16.29c16.75-2.65,33.37-2.71,50.21-0.95c9.6,1,19.39,0.18,29.95,0.18
                c-3.2-2.39-5.69-4.24-9.26-6.91c16.16-6.82,32.24-6.72,47.5-10.84c-8.3-0.73-16.8-1.95-24.79-0.43c-11,2.09-21.6,6.29-32.4,9.51
                c-4.1,1.22-8.25,3.03-12.41,3.15c-8.29,0.25-16.61-0.47-24.92-0.8c-14.61-0.59-29.26-0.73-43.83-1.91
                c-14.06-1.14-27.07-5.55-37.17-16.17c-1.27-1.33-2.29-2.89-3.43-4.35c0.2-0.47,0.4-0.93,0.59-1.4c3.03,0,6.13-0.4,9.09,0.07
                c16.02,2.51,32.07,3.93,48.19,1.32c9.3-1.5,18.18-0.52,26.99,2.67c2.08,0.76,4.62,0.84,6.85,0.54c13.81-1.86,27.6-3.9,41.39-5.93
                c9.34-1.37,18.72-4.24,28-3.96c23.08,0.7,46.12,2.81,69.18,4.38c0.92,0.06,1.82,0.39,3.44,0.75c-1.14,0.95-1.65,1.62-2.32,1.9
                c-9.72,4.06-17.33,10.94-25.04,17.9c-5.12,4.62-11.15,8.33-17.06,11.98c-10.61,6.54-21.47,12.66-32.16,19.09
                c-2.13,1.28-3.92,3.13-5.87,4.71c2.24,0.98,3.89,1.05,5.54,0.97c5.44-0.28,11.71,1.02,16.17-1.24
                c14.95-7.58,29.49-15.84,41.12-28.65c14.03-15.45,32.02-20.81,52.62-17.01c19.56,3.61,35.09,13.83,47.39,29.19
                c0.89,1.11,0.9,2.92,1.33,4.4c-1.4,0.09-2.85,0.49-4.18,0.22c-3.91-0.79-7.75-1.9-11.65-2.8c-12.29-2.82-24.71-4.19-37.05-0.92
                c-9.14,2.42-18.09,5.66-27.01,8.84c-5.21,1.85-10.22,4.29-15.31,6.46c0.13,0.51,0.25,1.01,0.38,1.52c1.92,0,3.84,0,5.76,0
                c68.1,0,136.2,0,204.3,0c13.32,0,26.64,0.08,39.96-0.09c2.15-0.03,4.9-0.59,6.34-1.97c11.4-10.95,22.88-21.86,33.71-33.36
                c17.11-18.17,37.25-32.11,58.96-44.02c1.6-0.88,3.56-1.12,5.35-1.66c0.27,0.54,0.54,1.08,0.82,1.62
                c-32.43,18.13-54.94,44.8-69.83,78.62c9.84,2.12,11.05,1.58,15.5-5.63c10.43-16.89,23.89-31.1,38.94-43.91
                c9.22-7.85,18.33-15.84,27.53-23.72c1.15-0.99,2.53-1.7,5.02-3.33c-0.38,2.69-0.23,4.06-0.78,5.05
                c-2.09,3.77-3.99,7.76-6.71,11.04c-10.65,12.79-21.87,25.12-32.31,38.08c-5.42,6.74-9.64,14.45-14.55,21.94
                c1.8,0.85,2.23,1.23,2.67,1.23c13.65,0.05,27.31,0.16,40.95-0.07c1.41-0.02,3.47-1.87,4.07-3.35c2.64-6.45,5.03-13.02,7.19-19.65
                c2.32-7.11,3.86-14.5,6.48-21.48c3.79-10.07,6.84-20.14,6.07-31.07c-0.15-2.1,0.64-4.27,1-6.41c0.63-0.03,1.26-0.06,1.89-0.1
                c0.95,4.83,1.9,9.66,3.06,15.56c2.4-5.28,4.35-9.58,6.3-13.88c0.68,0.11,1.35,0.22,2.03,0.33c0.29,3.16,0.91,6.34,0.8,9.48
                c-0.46,12.41-4.75,23.77-10.63,34.47c-4.31,7.85-9.52,15.21-14.28,22.82c-2.5,4-4.9,8.06-7.99,13.17c8.23,0,14.96,0.08,21.69-0.09
                c0.97-0.02,2.01-1.1,2.86-1.86c9.91-8.87,19.84-17.73,29.66-26.71c2.3-2.1,4.19-4.64,6.76-7.53
                c-11.67,7.38-20.64,17.62-33.79,21.24c0.07-1.57,0.52-2.61,1.19-3.47c5.12-6.55,10.58-12.85,15.39-19.62
                c13.53-19.02,25.94-38.73,35.21-60.26l0.02,0.02c-5.32,3.4-10.54,6.98-16.02,10.1c-1.63,0.93-3.99,0.55-6.02,0.77
                c0.21-2.15-0.08-4.52,0.74-6.4c2.58-5.92,6.07-11.49,8.27-17.53c1.79-4.89,2.23-10.28,3.27-15.44c-0.41-0.06-0.81-0.11-1.22-0.17
                c-6.22,7.11-12.45,14.22-18.67,21.33c-0.63-0.44-1.27-0.89-1.9-1.33c0.96-1.89,1.75-3.9,2.92-5.65
                c9.24-13.9,17.77-28.14,21.61-44.65c0.34-1,0.68-2,1.02-3l0.14-0.23L1094.55,1337.59z M103.51,465.29
                c0.24-0.16,0.49-0.33,0.73-0.49c-0.26,0.14-0.52,0.29-0.78,0.43c-0.07,0.09-0.14,0.19-0.21,0.28
                C103.34,465.44,103.42,465.37,103.51,465.29z M218.4,250.4l0.13,0.24l-0.15-0.22c-0.17-0.2-0.34-0.41-0.51-0.61
                C218.05,250.01,218.22,250.2,218.4,250.4z M192.39,677.08c-0.32,1.02-0.64,2.04-0.96,3.06c-2.64,2.33-4.32,1.13-4.97-1.7
                c-1.53-6.68-2.8-13.41-3.73-17.94c-0.13,6.91,0.65,15.88,5.24,23.95c0.55,0,1.1,0,1.65,0.01c0.59-1.45,1.18-2.9,1.78-4.35
                c0.34-1,0.68-2,1.01-3.01c0.11-0.26,0.23-0.52,0.35-0.77C192.64,676.58,192.52,676.83,192.39,677.08z M93.43,678.12l-0.24-0.17
                c0,0,0.22,0.19,0.22,0.19c1.04,0.32,2.07,0.63,3.11,0.95c2.87,2.32,5.73,4.64,8.6,6.96c0.41-0.36,0.82-0.71,1.23-1.07
                c-0.71-2.44-1.42-4.89-1.75-6.04c-1.7,0.33-3.03,0.78-4.38,0.8c-1.24,0.02-2.48-0.4-3.72-0.63
                C95.48,678.79,94.46,678.46,93.43,678.12z M69.53,680.12c-0.68-0.33-1.36-0.66-2.04-0.98c-3.86-2.58-7.62-5.32-11.61-7.68
                c-3.66-2.16-5.32-7.61-11.26-7.28c3.78,5.9,14.37,12.63,22.9,14.92c0.67,0.33,1.35,0.66,2.02,0.99c0.99,0.34,1.98,0.68,2.97,1.02
                l0.23,0.13l-0.21-0.15C71.53,680.77,70.53,680.44,69.53,680.12z M501.13,293.4c0.5-0.2,1-0.39,1.51-0.59
                c-0.08-0.15-0.17-0.29-0.25-0.44c-0.42,0.34-0.85,0.67-1.27,1.01C501.11,293.38,501.13,293.4,501.13,293.4z M1060.59,192.46
                c0.33-1.33,0.67-2.66,1-3.98c0.18-0.44,0.36-0.89,0.07-0.16c0.15-0.77,0.06-0.29-0.04,0.19c-0.34,1.32-0.68,2.65-1.01,3.97
                c-0.13,0.26-0.26,0.53-0.38,0.8C1060.35,193,1060.47,192.73,1060.59,192.46z M1063.57,179.44c0.19-0.45,0.38-0.9,0.06-0.15
                c0.15-0.8,0.06-0.31-0.03,0.18c-0.18,0.45-0.36,0.9-0.07,0.17C1063.39,180.41,1063.48,179.93,1063.57,179.44z M474.13,285.42
                l0.04-0.05c0.35,1.23,0.71,2.45,1.06,3.68c0.23-0.1,0.46-0.2,0.68-0.29C475.32,287.65,474.72,286.53,474.13,285.42z
                M154.47,696.06c0.66,1.68,1.32,3.36,1.98,5.03c1.39,3.56,2.47,7.27,4.23,10.63c3.51,6.71,4.67,13.69,4.24,21.22
                c-0.89,15.45-2.03,30.92-1.96,46.38c0.17,37.62,1.03,75.23,1.46,112.85c0.11,10.14-1.89,20.55-0.24,30.38
                c3.81,22.6,9.06,44.97,14.22,67.33c1.35,5.83,4.51,11.24,6.94,17.07c4.62-2.59,6.32-5.31,4.78-9.85c-1.33-3.92-2.59-8-2.97-12.09
                c-1.83-19.53-3.51-39.08-4.95-58.65c-1.89-25.71-3.74-51.42-5.19-77.15c-1.82-32.21-3.19-64.44-4.85-96.65
                c-0.53-10.31,2.02-19.67,7.35-28.51c4.46-7.39,6.95-15.57,8.02-24.22c-3.38,7.97-4.67,17.01-12.89,23.9
                c-0.26-3.14-0.47-5.03-0.57-6.93c-0.34-6.81-0.69-13.62-0.94-20.43c-0.15-4.11-2-5.62-6.05-4.6c-0.46,5.03-0.81,9.82-1.38,14.59
                c-0.38,3.17-2.47,3.66-4.47,1.66c-1.95-1.95-3.23-4.57-4.81-6.89c-0.66-1.67-1.33-3.35-1.99-5.02c-0.45-1.7-0.91-3.4-1.36-5.09
                c-0.23,0.08-0.46,0.16-0.69,0.24C153.07,692.85,153.78,694.45,154.47,696.06z M130.46,430.3l0.01-0.01
                c-0.53-1.43-1.06-2.85-1.58-4.28c-0.42,0.25-0.84,0.49-1.26,0.74C128.58,427.93,129.52,429.11,130.46,430.3z M189.41,1466.52
                c0.79-0.44,1.57-0.87,3.35-1.86c-2.18-0.35-3.25-0.52-4.69-0.75c0.62,1.22,0.99,1.92,1.35,2.62c-0.34,0.33-0.67,0.66-1.01,0.99
                c-6.32,0.04-12.68,0.58-18.94-0.02c-6.05-0.59-11.99-2.32-17.96-3.66c-0.6-0.13-1-1.1-1.62-1.82c1.26-1.08,2.27-1.94,3.78-3.24
                c-8.19-0.93-15.61-1.77-23.02-2.62c0.02-0.48,0.03-0.96,0.05-1.44c1.36-0.16,2.73-0.5,4.09-0.44c17.27,0.7,34.56,1.23,51.81,2.34
                c5.38,0.34,10.66,2.3,16,3.47c3.52,0.77,7.03,1.91,10.58,2.1c6.76,0.36,6.97-0.06,7.05-6.91c0.26-22.29,0.63-44.58,0.74-66.87
                c0.05-10.6-0.39-21.2-0.59-31.79c-1.4,3.19-3.01,6.31-4.18,9.59c-4.3,12.11-11.47,21.66-23.71,26.57c-2.11,0.85-5.45,1.64-6.9,0.6
                c-5.23-3.72-10.24-7.79-9.59-15.59c1.08-13.03,1.64-26.11,2.41-39.17c0,0,0.01,0.01,0.01,0.01c-1.71,1.24-3.42,2.48-5.14,3.73
                c-0.37-0.33-0.73-0.67-1.1-1c0.4-1.72,0.76-3.45,1.2-5.16c2.6-9.98,5.51-19.88,7.78-29.93c8.83-39.05,22.79-75.85,47.55-107.78
                c6.8-8.76,14.31-17.17,22.55-24.56c9.75-8.76,19.25-8.42,30.08-1c2.87,1.97,5.82,3.94,8.99,5.34c6.39,2.82,11.83,0.09,13.23-6.76
                c1.29-6.28,0.58-12.46-4.46-17.06c-1.8-1.64-4.49-3.01-6.87-3.19c-21.55-1.63-43.12-3.03-64.69-4.39
                c-33.02-2.07-66.05-4.06-99.08-6.08c-0.26-0.12-0.53-0.25-0.55-0.26c0.03,0.01,0.3,0.12,0.58,0.23c5.23,1.65,10.47,3.31,15.7,4.96
                c-0.03,0.55-0.07,1.09-0.1,1.64c-1.97,0.59-3.91,1.59-5.91,1.7c-18.58,1.05-37.16,2.01-55.75,2.88c-8.14,0.38-16.4-0.26-24.41,0.9
                c-16.84,2.44-33.67,4-50.67,3.15c-4.21-0.21-5.47,1.54-5.47,5.57c0.07,95.05,0.07,190.1-0.01,285.15c0,4.45,1.48,5.86,5.93,5.34
                c7.42-0.85,14.91-1.67,22.37-1.64c23.12,0.1,46.25,0.5,69.37,1.03c5.12,0.12,10.23,1.15,15.32,1.93c1.15,0.18,2.2,1.02,3.53,1.67
                c-0.76,1.34-1.05,2.4-1.74,2.99c-4.02,3.44-2.02,5.88,1.49,7.45c3.6,1.61,7.43,2.9,11.3,3.7c10.9,2.25,21.89,4.1,32.81,6.27
                c2.22,0.44,5.05,0.97,6.34,2.51c6.76,8.12,16.03,9.69,25.6,10.27c17.43,1.05,34.87,1.89,52.31,2.49
                c4.07,0.14,8.69,0.01,12.18-1.75c17.77-8.98,36.18-16,55.58-20.46c0.76-0.08,1.52-0.15,2.28-0.23c-0.03-0.15-0.06-0.29-0.1-0.44
                c-0.71,0.24-1.43,0.48-2.14,0.72c-16.37-0.19-32.74-0.37-48.65-0.55c0.59,0.2,1.9,0.41,2.92,1.06c1.42,0.9,2.63,2.13,3.93,3.22
                c-1.2,1.29-2.21,3.38-3.64,3.71c-3.81,0.89-7.82,1.55-11.69,1.33c-7.66-0.42-15.31-4.68-22.93,0.22
                c-0.25,0.16-0.65,0.12-0.98,0.12c-7.48-0.09-14.99,0.2-22.43-0.4c-6.76-0.55-13.48-1.87-20.12-3.26
                c-3.59-0.76-5.25-2.99-2.27-6.46C188.75,1467.19,189.08,1466.85,189.41,1466.52z M102.48,670.13c0,0,0.03-0.03,0.03-0.03
                c2.33,2,4.66,4,7,6c3.18,2.48,6.37,4.97,10.16,7.92c-2.15,1.29-3.65,2.2-5.15,3.1l-0.26,0.15l0.23-0.18
                c7.3-0.34,14.61-0.62,21.91-1.03c15.58-0.87,31.05-0.35,45.49,6.29c14.14,6.51,23.77,17.04,25.65,33.09
                c0.87,7.41,1.29,14.94,1.08,22.39c-0.81,29.41-2.23,58.81-2.88,88.23c-0.42,19.12-0.59,38.29,0.38,57.38
                c1.36,26.76,2.73,53.44-0.31,80.18c-2.15,18.94-11.12,33.44-27.76,42.39c-11,5.92-20.43,13.59-29.56,21.85
                c-0.43,0.2-0.87,0.41-0.14,0.06c-0.61,0.54-0.25,0.22,0.11-0.09c7.97-5.26,17.43-8.15,24.03-15.65c1.87-2.12,4.87-3.61,7.64-4.49
                c5.48-1.75,9.85-4.63,12.92-9.54c0.86-1.38,2.27-2.42,3.34-3.68c6.26-7.38,11.48-15.16,12.85-25.19
                c0.96-7.01,1.98-14.36,4.91-20.66c3.91-8.4,4.01-16.16,0.8-24.43c-0.66-1.69-1.58-3.41-1.69-5.15
                c-0.83-13.61-1.69-27.23-2.1-40.85c-0.16-5.25,1.31-10.56,1.15-15.81c-0.49-15.46-1.59-30.9-2.1-46.36
                c-0.16-4.93-0.37-10.39,1.5-14.73c2.58-6,2.66-11.92,2.5-17.98c-0.23-8.64-0.98-17.27-1.41-25.9
                c-0.58-11.46-1.14-22.92-1.56-34.38c-0.25-6.61-0.18-13.23-0.32-19.85c-0.09-3.81-0.39-7.61-0.47-11.41
                c-0.2-9.46-0.1-18.93-0.67-28.37c-0.14-2.3-2.39-4.47-3.67-6.7c-0.78,0.22-1.56,0.44-2.35,0.66c0.48,10.48,0.97,20.96,1.45,31.45
                c-0.46,0.18-0.91,0.36-1.37,0.53c-1.78-1.36-3.71-2.56-5.31-4.09c-6.95-6.64-14.89-11.36-24.69-11.72
                c-8.14-0.3-16.4-0.71-24.45,0.22c-14.23,1.64-27.52-0.12-39.88-7.59C107.15,674.12,104.81,672.13,102.48,670.13z M123.48,451.27
                l-0.01,0.01c-0.49-1.68-0.99-3.36-1.48-5.03c-0.5,0.26-1,0.52-1.51,0.77C121.48,448.44,122.48,449.85,123.48,451.27z
                M1040.62,928.91c-0.34-0.33-0.68-0.66-1.01-0.98c-1.66-2.33-3.32-4.66-4.98-6.99l-0.12-0.13l0.13,0.11
                c1.67,2.33,3.33,4.66,5,6.99c0.33,0.34,0.66,0.68,0.98,1.02c1,1.66,1.99,3.33,2.99,4.99c2.19,3.56,4.38,7.11,6.91,11.22
                c1.41-2.35,2.39-3.97,3.13-5.2c-1.94-1.24-3.39-2.2-4.88-3.1c-1.69-1.02-3.42-1.97-5.13-2.95c0-1.55,0-3.1,0-4.74
                C1042.46,929.06,1041.54,928.99,1040.62,928.91z M180.44,1384.57l0.11,0.26l-0.13-0.24c-0.35-2.71-0.71-5.42-1.06-8.13
                c-0.35,0.08-0.69,0.15-1.04,0.23C179.02,1379.31,179.73,1381.94,180.44,1384.57z M91.5,432.29l0.01-0.01
                c-0.64-1.17-1.27-2.34-2.16-3.97C87.34,431.98,88.82,432.56,91.5,432.29z M142.46,448.27c0,0-0.01,0.01-0.01,0.01
                c-1.52-1.34-3.04-2.68-4.56-4.02c-0.37,0.65-0.74,1.3-1.11,1.95C138.68,446.9,140.57,447.58,142.46,448.27z M147.45,673.12
                l-0.14-0.24l0.15,0.23c0.85,2.14,1.7,4.29,2.54,6.43c0.3-0.08,0.61-0.17,0.91-0.25c0.18-2.06,0.36-4.11,0.53-6.17
                c0.58-3.94,1.16-7.88,1.74-11.83c-0.57-0.04-1.14-0.09-1.71-0.13c0,3.99,0,7.99,0,11.98
                C150.14,673.14,148.79,673.13,147.45,673.12z M509.11,288.39c2.76-1.49,5.52-2.99,8.28-4.48c-0.44-0.57-0.88-1.15-1.32-1.72
                c-2.32,2.07-4.63,4.14-6.95,6.21L509.11,288.39z M492.13,309.37l0.13,0.14l-0.14-0.12c-2.52-6.24-2.52-6.24-6.21-5.16
                C488.2,306.12,490.17,307.74,492.13,309.37z M113.5,437.27c0,0-0.02,0.02-0.02,0.02c-2-2.63-4-5.26-6.63-8.72
                C108.16,435.04,108.16,435.04,113.5,437.27z M240.36,452.26c2.54-0.62,5.08-1.24,8.74-2.13c-5.3-3.49-6.64,0.28-8.72,2.16
                l-0.35,0.19C240.03,452.48,240.36,452.26,240.36,452.26z M95.49,668.13c0,0,0.02-0.02,0.02-0.02c1.5,2.76,3.01,5.51,4.51,8.27
                C101.54,671.58,98.76,669.68,95.49,668.13z M1062.6,21.61c2.32-5.94,4.64-11.88,6.96-17.82c-0.46-0.16-0.92-0.31-1.38-0.47
                c-1.86,6.09-3.73,12.19-5.59,18.28l-0.13,0.23C1062.46,21.83,1062.6,21.61,1062.6,21.61z M102.5,651.13l0,0.02
                c5.72,3.66-0.27,8.69,1.3,13.11C108.49,656.98,108.24,654.84,102.5,651.13z M793.87,973.91c-4.43,2.95-3.66,7.3-2.43,11.75
                c0.8-3.93,1.59-7.86,2.39-11.8L793.87,973.91z M151.4,640.17c-0.06-0.26-0.13-0.52-0.19-0.78c0.08,0.25,0.16,0.5,0.25,0.75
                c0.22,4.18,0.44,8.36,0.66,12.55C154.45,648.19,155.97,643.78,151.4,640.17z M106.49,478.25l0-0.01c-0.97-1.9-1.99-3.78-2.9-5.71
                c-1.91-4.07-2-4.12-7.36-1.53C99.91,473.6,103.2,475.92,106.49,478.25z M117.47,644.14l0.02-0.01c-0.41-2.94-0.74-5.89-1.24-8.82
                c-0.53-3.09-0.6-3.07-5.5-2.29C113.12,636.94,115.3,640.54,117.47,644.14z M472.12,278.44c0.09,0.07,0.19,0.15,0.28,0.22
                c-0.08-0.09-0.15-0.18-0.23-0.27c-0.24-4.69-0.48-9.38-0.71-14.07c-0.64,0.08-1.28,0.17-1.92,0.25
                C465.94,269.8,469.99,273.99,472.12,278.44z M487.15,286.41c-1.67,2.44-3.54,4.78-4.93,7.36c-0.59,1.08-0.6,2.9-0.07,3.99
                c0.3,0.61,2.58,0.96,3.38,0.46c1.13-0.7,2.35-2.26,2.4-3.5c0.11-2.77-0.49-5.56-0.8-8.35L487.15,286.41z M764.88,1007.86
                l-0.01,0.01c-1.96-4.82-3.93-9.63-6.14-15.06c-1.48,2.69-5.74,3.77-2.56,7.22C758.8,1002.89,761.95,1005.27,764.88,1007.86z
                M165.43,670.13l-0.12-0.23c0,0,0.14,0.21,0.14,0.21c0.26,2.3,0.19,4.7,0.91,6.85c0.47,1.41,2.09,2.43,3.2,3.62
                c0.84-1.37,2.31-2.71,2.39-4.13c0.29-5.1,0.11-10.23,0.11-15.91c-0.32,4.84-0.6,9.12-0.87,13.39c-0.52,0.17-1.03,0.34-1.55,0.51
                C168.24,673,166.83,671.57,165.43,670.13z M64.53,594.19l-0.3-0.21l0.34,0.17c4.6,6.75,11.47,3.49,17.88,4.44
                c-0.93-4.79-3.98-6.45-7.18-4.38C71.27,596.8,68.09,595.48,64.53,594.19z M122.49,437.27l-0.03,0.03
                c1.33-5.79,1.68-11.31-4.57-15.95C118.15,427.6,115.37,433.78,122.49,437.27z M609.04,319.35l-0.05,0.04
                c-1.49-4.41-2.84-8.87-4.55-13.18c-0.54-1.36-1.85-2.71-3.16-3.4c-2.63-1.38-5.5-2.3-8.27-3.4l0.04-0.04
                C598.38,306.03,603.71,312.69,609.04,319.35z M1011.66,1142.78c-7.97,3.77-15.94,7.54-23.97,11.34c4.02,5.82,4.63,5.97,9.26,1.91
                c4.96-4.35,9.79-8.85,14.68-13.28L1011.66,1142.78z M776.87,997.86l-0.02,0.02c-0.74-7.22-1.48-14.44-2.19-21.33
                c-1.54,1.29-3.43,2.3-4.44,3.87c-1.07,1.66-2.16,4.06-1.74,5.78C769.67,991.07,771.99,995.48,776.87,997.86z M372.23,277.39
                c7.7-4.84,11.91-12.29,16.43-20.8c-4.89,0.22-8.24,0.77-9.87,4.77c-2.18,5.35-4.36,10.7-6.54,16.06L372.23,277.39z M561.07,528.2
                c4.2,1.36,8.4,2.73,12.6,4.09c0.17-0.64,0.34-1.28,0.52-1.92c-4.38-0.71-8.77-1.42-13.15-2.13c-7.21-4.68-14.42-9.36-21.63-14.03
                c-2.52,6.89-1.46,9.77,5.13,11.39C549.93,526.93,555.55,527.37,561.07,528.2z M357.26,1012.85c0.1,0.03,0.2,0.06,0.3,0.09
                c-0.11-0.02-0.22-0.04-0.32-0.06c-7.47-4.25-14.93-8.5-22.4-12.75c-0.69,2.49-0.5,3.62-0.21,4.72c1.94,7.54,2.07,7.61,9.67,7.71
                C348.62,1012.61,352.94,1012.75,357.26,1012.85z M127.45,653.15l0.05-0.05c0.78-9.38,2.89-18.96-3.7-27.28
                C120.02,635.65,121.82,644.67,127.45,653.15z M1064.62,973.85l0.14,0.32l-0.18-0.28c-0.05-6.46,0.23-12.94-0.31-19.35
                c-0.22-2.63-1.57-6.17-3.56-7.34c-1.84-1.08-5.21,0.45-8.18,0.87C1056.92,957.43,1060.77,965.64,1064.62,973.85z M202.42,695.08
                c0,0-0.04,0.04-0.04,0.04c-0.43-6.95-0.78-13.91-1.38-20.84c-0.11-1.26-1.04-2.58-1.93-3.58c-0.26-0.29-2.31,0.25-2.46,0.74
                c-1.49,4.89-2.81,9.84-4.01,14.82c-0.13,0.53,0.71,1.47,1.31,1.97C196.71,690.55,199.58,692.8,202.42,695.08z M817.83,1384.59
                c1.79-0.53,3.58-1.05,5.37-1.58c0.17,0.32,0.35,0.64,0.52,0.96c-0.63,0.87-1.27,1.74-1.9,2.6l0.01,0.01
                c5.35-0.81,10.7-1.63,17.07-2.6c-12.68-7.15-25.75-6.62-38.72-7.57c1.02,1.38,2.31,2.27,3.66,3.06c1.31,0.76,2.67,1.42,4.01,2.12
                l-0.02-0.02c2.26-0.32,4.52-0.84,6.78-0.91c2.51-0.08,4.92,0.34,3.21,3.92C817.82,1384.58,817.83,1384.59,817.83,1384.59z
                M1005.64,1110.79l0.01-0.02c-9-5.65-18-11.31-27-16.96l0.03-0.03c4.15,3.22,8.31,6.43,12.74,9.86c-1.3,0.9-2.95,2.06-5.46,3.8
                c4.21-0.27,7.39-0.48,10.57-0.69c0.08,0.47,0.17,0.94,0.25,1.41c-3.8,2.4-7.6,4.8-11.96,7.56c9.27,0,17.57,0,25.87,0
                c0.07-0.26,0.14-0.52,0.21-0.78c-5.12-1.07-10.23-2.13-15.35-3.2c0.07-0.53,0.14-1.06,0.21-1.59
                C999.06,1110.37,1002.35,1110.58,1005.64,1110.79z M250.35,1473.52c0,0,0.01-0.01,0.01-0.01c-1.29-1.22-2.4-3.17-3.9-3.53
                c-6.27-1.52-12.61-2.89-18.87,0.48c-4.58,2.46-6.06,1.62-7.78-3.35c-0.3-0.87-2.09-2.12-2.58-1.88c-2.43,1.15-4.64,2.73-7.69,4.63
                c5.59,3.64,10.85,4.69,16.58,3.99c1.07-0.13,2.18-0.33,3.24-0.21c2.35,0.26,5.57,1.86,6.87,0.87
                C240.97,1470.86,245.57,1472.43,250.35,1473.52z M976.67,181.47c-3.88,4.44-7.28,1.07-11.55-1.91
                c-0.56,10.13-1.05,19.04-1.54,27.95c0.75,0.16,1.5,0.32,2.25,0.47C969.44,199.14,973.06,190.31,976.67,181.47L976.67,181.47z
                M263.33,562.18c9.5-4.35,17.97-10.13,25.32-17.72c-1.56,0-2.95,0.29-4.16-0.05c-9.02-2.5-14.41,1.65-17.8,9.54
                c-1.17,2.72-2.23,5.5-3.34,8.25L263.33,562.18z M356.24,291.36c4.62-7.05,9.4-14,13.79-21.19c2.15-3.52,4.01-7.35,5.1-11.3
                c0.43-1.56-1.63-3.79-2.55-5.72c-1.35,0.82-3.5,1.35-3.92,2.51c-4.29,11.85-8.3,23.8-12.38,35.72l-0.18,0.3L356.24,291.36z
                M221.39,260.4l-0.15-0.22c0,0,0.13,0.23,0.13,0.23c-0.66,3.33-1.32,6.66-1.98,9.99l-0.12,0.11l0.11-0.11
                c2.4-0.57,6.47-0.53,6.94-1.81c3.32-9.03,6.05-18.28,8.56-27.58c0.41-1.51-1.27-3.58-1.99-5.39c-1.41,1.46-3.54,2.7-4.08,4.43
                c-1.64,5.21-2.49,10.67-4.04,15.92C224.28,257.64,222.55,258.94,221.39,260.4z M99.52,685.08c0,0-0.04,0.04-0.04,0.04
                c-0.41-0.52-0.73-1.19-1.26-1.52c-6.29-3.97-12.6-7.91-18.95-11.77c-0.59-0.36-1.77-0.47-2.26-0.12
                c-5.05,3.57-7.88,0.81-9.98-3.24c-3.7,0-7.12,0-10.93,0C69.05,678.81,83.78,683.28,99.52,685.08z M1052.64,1200.76
                c-7.81,7.05-15.63,14.11-24.07,21.73c3.7,2.55,7.25,4.99,11.63,8.01c4.42-10.62,8.4-20.2,12.39-29.78
                c0.34-0.67,0.68-1.35,1.01-2.02c2.95-8.99,7.84-17.53,2.09-27.53c-5.13-8.92-8.88-10.48-17.83-5.04c-2.6,1.58-5.11,3.29-7.66,4.94
                c4.16-6.97,11.06-9.39,19.08-11.41c-1.92-2.05-3.26-3.44-4.55-4.88c-5.27-5.89-7.04-6.12-13.52-1.26
                c-2.53,1.89-4.82,4.1-7.21,6.17c-0.39-0.48-0.78-0.95-1.16-1.43c4.73-3.89,9.46-7.77,14.26-11.71
                c-4.03-12.12-11.66-3.89-18.21-2.72c3.93-3.83,8.4-5.94,13.75-6.07l-0.01,0.01c-4.62-8.41-11.87-5.69-19.01-3.47
                c0-0.56-0.01-1.11-0.01-1.67c3.79-1.27,7.58-2.54,12.74-4.27c-5.22-3.51-9.24-7.48-14.07-9.2c-10.28-3.67-18.46,2.41-26.58,7.63
                l-0.03-0.03c7.34-1.38,14.68-2.76,22.02-4.13c0.23,0.53,0.47,1.05,0.7,1.58c-6.94,4.11-13.88,8.22-20.82,12.33
                c0.22,0.43,0.43,0.85,0.65,1.28c8.47-3.65,16.93-7.29,25.4-10.94c0.17,0.37,0.34,0.73,0.51,1.1
                c-8.81,7.59-17.62,15.19-26.43,22.78l-0.12,0.11l0.11-0.12c10.51-4.71,21.03-9.42,31.54-14.13c0.38,0.62,0.75,1.24,1.13,1.86
                c-9.04,8.18-18.08,16.35-27.12,24.53c0.29,0.39,0.59,0.78,0.88,1.17c10.5-5.75,20.99-11.5,31.49-17.25c0.5,0.62,1,1.25,1.5,1.87
                c-11.07,11.8-22.14,23.61-33.21,35.41c0.43,0.53,0.87,1.05,1.3,1.58c12.09-8.09,24.18-16.19,36.27-24.28
                c0.54,0.58,1.08,1.16,1.63,1.74c-8.73,10.57-17.46,21.13-26.19,31.7c0.21,0.23,0.43,0.45,0.64,0.68
                c8.79-7.26,17.58-14.52,26.37-21.79c0.67,0.63,1.35,1.27,2.02,1.9c-7.83,10.64-15.66,21.28-24.22,32.91
                c13.7-6.51,20.53-19.84,33.69-25.28c-0.23,2.65-0.8,5.07-2.15,6.91c-4.99,6.86-10.35,13.44-15.32,20.31
                c-2.07,2.86-3.51,6.18-5.24,9.3c0.56,0.44,1.11,0.87,1.67,1.31c3.07-2.14,6.28-4.11,9.18-6.47c6.44-5.24,12.73-10.68,19.08-16.03
                C1052.62,1199.39,1052.63,1200.07,1052.64,1200.76z M273.36,269.44c-0.44,1.03-0.87,2.05-1.31,3.08
                c-0.54-0.36-1.08-0.71-1.62-1.07c-0.47-1.72-1.09-3.41-1.4-5.16c-1.35-7.69-2.68-15.39-3.86-23.12c-0.47-3.11-2.04-4.63-5.64-4.95
                c0,1.72-0.13,3.16,0.02,4.56c1.02,9.33,2.32,18.64,3.08,27.99c0.68,8.35,0.42,8.37,9.5,9.45c0.4-3.6,0.8-7.21,1.2-10.81
                c0.13-0.26,0.26-0.53,0.28-0.56C273.59,268.89,273.47,269.16,273.36,269.44z M705.95,457.24c0,4.29,0,8.58,0,12.87
                c0.34-0.01,0.68-0.02,1.02-0.04c-0.35-4.27-0.7-8.54-1.05-12.81c-1.18-8.8-7.61-14.91-13.8-19.1c-4.81-3.26-8.88-6.97-13.1-10.73
                c-0.44-0.39-1.11-0.52-1.74-0.81c-3.73,5.46,0.46,9.97,0.88,14.07c1.1-0.75,2.52-2.44,3.4-2.2c6.33,1.76,6.62,7.53,7.59,13
                c4.72-1.05,5.02,2.3,6.08,5.33c0.88,2.51,2.56,4.74,3.59,6.56C699.58,460.36,699.38,455.25,705.95,457.24z M749.89,1100.8
                l-0.22-0.15l0.25,0.12c6.27,2.05,12.55,4.11,19.26,6.3c-1.24,1.08-2.22,1.93-3.54,3.08c4.88-0.57,9.11-1.06,13.34-1.56
                c0.1,0.45,0.21,0.89,0.31,1.34c-1.95,1.82-3.9,3.63-5.85,5.45c0.3,0.58,0.6,1.15,0.89,1.73c11.09-4.32,22.17-8.64,33.26-12.97
                c-0.3-0.78-0.59-1.56-0.89-2.34c-8.22,3.2-16.43,6.4-24.65,9.6c-0.16-0.37-0.31-0.75-0.47-1.12c5.82-3,11.64-6,17.46-9
                c-0.05-0.33-0.1-0.66-0.15-0.98c-6.32,1.19-12.65,2.39-18.97,3.58c-0.11-0.17-0.22-0.35-0.32-0.52c1.52-1,3.04-2,5.04-3.32
                c-2.12,0-3.75-0.03-5.37,0.01C769.47,1100.3,759.68,1100.55,749.89,1100.8z M631.02,346.33l0.14,0.35l-0.17-0.32
                c-2.05-8.36-4.97-16.63-5.9-25.12c-0.94-8.57-5.11-14.77-10.75-20.41c-1.16-1.16-3.46-1.18-5.23-1.73
                c-0.31,1.74-1.4,3.81-0.81,5.16c4.54,10.48,9.13,20.95,14.29,31.13C624.64,339.41,628.17,342.7,631.02,346.33z M563.05,323.37
                l0.02-0.01c-4.14-5.19-8.78-10.07-12.3-15.65c-4.69-7.43-8.55-15.39-12.7-23.16c-1.05-1.96-1.69-4.3-4.65-2.3
                c-2.28,1.54-4.61,2.99-6.9,4.47C530.46,299.07,544.13,312.77,563.05,323.37z M1009.65,81.54l-0.02,0.02
                c2.37-20.72,4.74-41.44,7.19-62.84c-4.55,2.45-7.23,5.03-7.17,9.94c0.06,4.81-0.32,9.64-0.77,14.43
                c-0.57,5.98-1.41,11.93-2.13,17.89c-0.75,0.12-1.51,0.24-2.26,0.36c-1.38-3.17-2.96-6.26-4.09-9.52
                c-1.19-3.45-2.06-7.02-2.86-10.58c-0.75-3.32-1.24-6.71-1.85-10.07c-0.42,0.09-0.85,0.19-1.27,0.28c1.06,5.69,2,11.4,3.22,17.06
                c1.64,7.62,4.17,15.12,5.02,22.82C1003.24,76.54,1003.4,80.77,1009.65,81.54z M980.67,980.87l0.33,0.19l-0.36-0.15
                c1.79-2.98-0.36-3.77-2.53-4.5c-1.7-0.58-3.42-1.1-5.9-1.89c2.06-1.79,3.23-2.81,4.99-4.33c-3.13-0.58-5.52-1.02-8.42-1.55
                c1.03-1.63,1.6-2.52,2.18-3.43c-1.48-1.14-2.75-2.13-4.03-3.12c0.43-0.21,0.86-0.41,1.29-0.62c-0.34-1.61-0.49-3.29-1.06-4.81
                c-0.72-1.92-2.54-3.69-2.58-5.56c-0.26-12.79-0.18-25.58-0.21-38.38c-0.01-2.55,0-5.11,0-7.66c-1.53,6.88-2.15,13.73-2.94,20.57
                c-1.49,12.84-3.21,25.66-4.49,38.52c-0.17,1.76,1.18,4.55,2.67,5.43C966.47,973.62,973.62,977.16,980.67,980.87z M81.51,1082.82
                c-0.45-0.18-0.91-0.36-0.15-0.06c-0.8-0.11-0.31-0.04,0.18,0.03c10.14,1.56,20.24,3.71,30.44,4.54
                c12.74,1.03,25.57,0.87,38.35,1.42c18.41,0.8,36.8,2.13,55.21,2.52c8.26,0.17,16.7-0.68,24.24-5.01
                c-8.3,0.35-16.57,1.83-24.8,1.66c-21.59-0.43-43.17-1.54-64.75-2.46C120.66,1084.63,101.09,1083.71,81.51,1082.82z M896.77,206.44
                l-0.03,0.03c-2.93-15.35-6-30.68-8.71-46.07c-1.1-6.26-1.42-12.66-2.17-19.77c-4.02,8.62-7.7,16.4-11.23,24.24
                c-0.42,0.94-0.38,2.38,0.03,3.35C880.32,181.94,886.31,195.49,896.77,206.44z M298.31,1041.83c0,0,0-0.02,0-0.02
                c-7.25-3.97-14.54-7.85-21.71-11.96c-1.79-1.03-3.39-2.6-4.69-4.23c-3.4-4.27-6.69-8.64-9.83-13.11
                c-2.56-3.64-5.87-7.19-7.01-11.3c-1.87-6.7-6.51-7.73-12.67-8.72c2.73,7.75,3.23,15.92,9.47,21.09
                c7.51,6.23,12.77,13.91,16.77,22.56c1.56,3.38,3.76,4.48,7.35,4.55C283.44,1040.85,290.87,1041.43,298.31,1041.83z M750.9,334.36
                l-0.01,0.01c4,1.8,7.75,3.36,12.51,3.72c6.82,0.5,14.97-0.19,19.29,7.75c0.62,1.14,2.74,1.56,4.24,2.1
                c1.69,0.6,3.47,0.94,5.2,1.41c3.45,0.93,6.88,2.57,10.35,2.64c4.14,0.08,7.38,0.96,10.09,4.17c0.9,1.06,2.56,1.48,4.68,2.62
                c-1.55-3.45-2.97-5.73-3.58-8.2c-0.69-2.82-1.96-4.27-4.66-5.67c-5.34-2.76-9.9-7.17-15.38-9.49c-4.52-1.91-10.42-3.45-14.78-2.12
                c-5.22,1.59-7.81-0.12-10.73-3.2c-1.66-1.75-3.06-3.73-5-6.12C760.57,329.98,758.78,336.09,750.9,334.36z M231.56,1348.8
                c0,0-0.33-0.33-0.33-0.33c-0.48-1.03-0.96-2.06-1.44-3.09c-1.98,1.57-5.1,2.8-5.7,4.8c-1.18,3.88-1.29,8.19-1.25,12.32
                c0.09,9.41,0.82,18.82,0.78,28.23c-0.1,25.13-0.47,50.27-0.7,75.4c-0.01,0.9,0.4,1.8,0.61,2.7c0.55,0.14,1.09,0.29,1.64,0.43
                c0.61-1.99,1.7-3.97,1.74-5.97c0.48-19.63,0.5-39.28,1.22-58.9c0.53-14.23-0.37-28.64,4.18-42.53c1.09-3.33,0.69-6.8-2.62-8.86
                C230.33,1351.57,230.95,1350.19,231.56,1348.8z M1019.62,1430.54c-4.96,4.17-9.77,8.54-14.91,12.47
                c-17.31,13.23-32.69,28.3-44.88,46.45c-1.61,2.39-2.93,4.98-4.48,7.64c10.24,2.84,13.03,1.7,17.43-6.5
                c1.02-1.9,1.93-3.92,3.26-5.6c7.72-9.77,15.56-19.46,23.35-29.18c6.75-8.42,13.5-16.84,20.25-25.26
                C1019.64,1430.56,1019.62,1430.54,1019.62,1430.54z M802.85,970.9c-5.34,1.11-7.18,4.79-7.86,9.83c-0.39,2.89-1.95,5.62-2.91,8.45
                c-5.09,14.9-8.82,29.99-5.75,45.88c0.24,1.24,1.97,3.22,2.83,3.13c3.52-0.35,7.14-0.55,7.61-5.82
                c1.82-20.51,4.01-40.99,6.07-61.49L802.85,970.9z M421.17,270.45c-0.09-0.48-0.18-0.95-0.04-0.2c-0.32-0.7-0.12-0.27,0.08,0.17
                c2.2,5.34,3.75,11.07,6.73,15.92c6.44,10.48,9.04,20.71,4.6,33.03c-4.78,13.25-7.81,27.15-11.28,40.84
                c-0.42,1.65,1.16,3.81,1.81,5.74c0.97-1.46,2.41-2.79,2.84-4.4c8.27-31.13,17.57-61.81,36.19-88.72c1.59-2.3,2.66-4.98,3.78-7.57
                c0.25-0.58-0.27-1.49-0.42-2.18c-1.45,1.83-2.5,3.53-3.91,4.83c-2.05,1.9-4.94,3.12-6.45,5.33c-3.56,5.2-6.55,10.79-9.72,16.26
                c-2.04,3.52-4,7.1-6.4,11.38c-6.15-5.74-4.88-12.36-5.2-18.98c-1.87,4.62-3.45,5.18-5.73,1.76
                C425.3,279.55,423.42,274.88,421.17,270.45z M70.53,1052.82c7.68-2.42,16.14-3.53,22.87-7.55c14.71-8.78,29.91-13.65,47.17-13.07
                c5.06,0.17,10.64-2.1,15.26-4.6c5.92-3.21,11.1-7.77,16.6-11.75c0,0,0.02,0.02,0.02,0.02c-5.98,0.1-12.1-0.57-17.91,0.47
                c-8.62,1.56-16.94,4.7-25.53,6.5c-12.04,2.52-23.22,6.7-33.4,13.72c-8.19,5.65-16.68,10.87-25.04,16.28l-0.24,0.15
                C70.31,1053,70.53,1052.82,70.53,1052.82z M1060.61,177.51c-2.99,1.13-5.99,2.26-9.12,3.44c1.26,8.73,1.26,8.73-10.16,5.96
                c-2.33,27.46-13.64,52.54-20.1,79.18c4.5-4.09,8.8-8.28,11.87-13.24c14.35-23.19,22.72-48.64,27.48-75.37l0.18-0.28
                L1060.61,177.51z M788.86,245.42l-0.01,0.01c-6.12-8.41-10.89-17.49-13.58-27.58c-1.71-6.42-3.93-12.78-4.86-19.31
                c-1-7.01-5.99-9.41-11.33-10.92c-3.36-0.95-7.26-0.62-10.41,2.74c-2.48,2.64-5.96,5.16-9.38,5.89
                c-5.54,1.18-13.99-5.7-15.78-11.44c-0.36-1.16-1.49-2.08-2.27-3.11c-0.49,0.31-0.97,0.62-1.46,0.93c0.72,1.54,1.3,3.16,2.17,4.61
                c6.53,10.94,14.09,21.14,24.45,28.67C760.32,226.05,774.68,235.61,788.86,245.42z M452.17,423.31c0.33,0.33,0.65,0.66,0.98,0.99
                c-1.76,2.05-3.56,3.25-6.49,1.77c-1.5-0.75-3.67-1.06-5.26-0.6c-7.45,2.17-16.25-0.99-22.61,5.65c-0.42,0.44-1.85-0.18-2.81-0.16
                c-2.16,0.05-4.33,0.11-6.48,0.34c-2.11,0.23-4.2,0.96-6.31,1c-6.23,0.13-12.5,1.27-18.63-1.24c-1.32-0.54-3.42-0.02-4.83,0.68
                c-5.08,2.5-10.13,3.84-15.63,1.39c-0.78-0.34-1.86-0.09-2.79,0.03c-3.07,0.38-6.14,1.18-9.21,1.15c-3.25-0.03-6.54-0.6-9.72-1.34
                c-5.65-1.31-11.22-2.93-16.83-4.43c-0.06-0.53-0.13-1.06-0.19-1.59c1.45-1.17,2.73-2.74,4.38-3.44c4.31-1.81,8.41-2.98,13.63-2.5
                c6.85,0.62,13.98-1.77,20.98-2.96c1.1-0.19,2.1-0.95,3.19-1.29c1.81-0.56,3.66-1.38,5.51-1.42c4.06-0.1,8.49,1.29,12.09,0.07
                c3.57-1.22,6.4-1.43,9.95-0.54c2.65,0.67,5.64-0.04,8.47-0.13c0-0.52-0.01-1.05-0.01-1.57c-3.49-1.13-7.24-1.81-10.37-3.57
                c-1.79-1.01-3.97-4.23-3.5-5.65c0.62-1.92,3.47-4,5.63-4.37c8.36-1.42,16.73-1.83,25.34-1.17c6.71,0.52,13.7-1.63,20.46-3.06
                c2.01-0.42,3.76-2.32,5.43-3.78c0.27-0.23-0.69-2.51-0.85-2.48c-2.2,0.37-4.36,1.01-6.54,1.52c-1.95,0.46-4.64,1.85-5.74,1.06
                c-2.94-2.1-5.49-2.14-8.74-1.15c-2.11,0.64-4.57,0.41-6.84,0.17c-6.38-0.67-11.87-4.92-19.35-1.55
                c-5.8,2.62-13.19-1.75-20.04,0.22c-1.58,0.45-3.87-1.42-5.79-2.3c-1.37-0.63-2.67-1.39-2.98-1.56c-2.45,3.41-3.82,6.86-6.35,8.52
                c-5.07,3.31-11.03,1.56-15.01-3.42c-1.6-2-13.82-4.41-15.88-3.12c-0.28,0.17-0.47,0.47-0.73,0.67c-3.6,2.84-9.21,2.79-12.63-0.33
                c-5.3-4.84-10.49-9.7-18.6-6.35c-0.79,0.32-1.95-0.42-2.91-0.34c-2.53,0.21-5.39-0.06-7.48,1.06c-4.12,2.2-7.47,3.2-9.59-1.84
                c-1.64,0.47-2.87,1.1-4.11,1.13c-6.8,0.16-13.62,0.3-20.42,0.15c-1.83-0.04-3.6-1.41-5.45-1.62c-5.45-0.62-9.96-3.17-14.39-6.16
                c-0.92-0.62-2.61-0.28-3.91-0.12c-4.51,0.55-7.42-1.6-10.16-4.97c-1.43-1.77-4.12-2.47-6.09-3.85c-1.91-1.34-3.9-2.72-5.38-4.48
                c-2-2.41-3.08-5.75-5.38-7.72c-6.71-5.73-7.71-8.33-4.98-15.37c-1.73-2.32-4.53-4.41-4.37-6.26c0.2-2.35,2.68-6.3,4.38-6.42
                c6.63-0.44,7.07-8.03,12.75-9.47c-2.71-1.4-5.2-2.48-7.69-2.5c-4.29-0.03-8.58,0.71-12.88,0.91c-4.92,0.23-11.96,7.56-12.06,12.56
                c-0.06,2.75,0.24,5.9-0.97,8.15c-2.56,4.73-7.13,8.59-12.29,8.5c-5.45-0.09-11.49-1.13-16.4-3.09c-3.85-1.54-6.8-5.4-11.83-4.87
                c-1.16,0.12-2.49-0.84-3.66-1.47c-2.04-1.11-3.93-2.58-6.06-3.44c-1.2-0.49-2.76-0.08-4.16-0.07c0.09,1.53-0.41,3.91,0.36,4.43
                c3.29,2.26,6.78,4.58,10.55,5.74c4.12,1.26,11.73,8.54,11.74,12.77c0,1.06-0.44,2.57-1.21,3.1c-5.55,3.88-11.48,6.69-18.55,6.67
                c-6.31-0.02-13.36-1.18-18.74,1.2c-5.99,2.66-10.03,0.59-15.06-0.86c-2.72-0.78-5.97-0.27-8.87,0.25
                c-7.02,1.25-14.17,6.85-20.04-0.77c-2.58,0.94-4.77,2.56-6.63,2.25c-4.67-0.76-9.19-2.42-13.78-3.65
                c-0.77-0.21-1.74-0.35-2.44-0.07c-3.9,1.55-6.47-0.27-8.43-3.31c-1.48-2.3-3.03-2.92-5.81-2.06c-4.2,1.29-11-2.89-9.61-5.83
                c2.31-4.9,1.72-9.48,1.48-14.31c-0.02-0.44,0.49-0.89,0.56-1.37c0.28-1.76,0.8-3.55,0.67-5.28c-0.29-3.93-0.31-7.31,5.22-6.47
                c0.56,0.09,1.37-0.25,1.79-0.67c2.34-2.38,4.6-4.84,6.72-7.09c-1.68-0.33-3.91-0.4-5.82-1.22c-6.62-2.85-13.14-5.91-19.66-8.99
                c-5.45-2.57-7.42-1.52-7.42,4.35c-0.03,39.97-0.02,79.93,0,119.9c0,5.11,4.56,7.8,9.23,5.55c7.3-3.53,17.84-2.52,21.44,2.94
                c2.17,3.3,4.48,3.25,7.43,2.81c3.2-0.47,6.39-1.07,9.73-1.64c-0.28-1.05-0.64-2.35-1.08-3.97c1.68-0.18,2.99-0.21,4.24-0.49
                c1.27-0.28,2.45-0.99,3.72-1.17c1.54-0.22,3.14-0.03,4.71-0.13c2.07-0.13,4.53,0.27,6.1-0.7c2.5-1.55,4.41-2.12,7.3-0.86
                c4.58,1.99,8.34-0.04,11.08-4.66c0.51-0.86,2.64-0.94,4.05-1.05c2.88-0.23,6.04,0.49,8.61-0.48c4.91-1.85,8.23-6.56,15.11-4.74
                c4.2,1.11,9.5-2.33,14.39-3.33c2.87-0.59,5.9-0.4,8.86-0.56c2.53-0.05,5.09-0.8,7.32-0.23c7.54,1.92,14.77,2.55,22.43-0.12
                c5.24-1.82,11.26-1.31,16.58-2.97c6.44-2,12.44-3.94,18.54,0.62c0.78,0.59,2.44,0.7,3.36,0.29c7.35-3.27,12.88,2.31,18.91,4.27
                c5.88,1.91,10.99,6.25,16.38,9.6c1.21,0.75,2.19,1.87,3.27,2.82c-1.45,1.19-2.76,2.65-4.4,3.48c-1.45,0.74-3.26,0.79-5.11,1.19
                c2.57,2.13,4.51,3.76,6.48,5.37c4.18,3.43,8.02,7.46,12.65,10.12c8.61,4.97,17.56,9.42,26.63,13.48c3.21,1.44,7.2,1.23,10.85,1.55
                c2.07,0.18,4.24-0.42,6.26-0.06c4.85,0.86,9.61,2.22,14.46,3.12c6.14,1.15,12.34,2.03,18.5,3.06c1.62,0.27,3.21,0.85,4.83,1
                c6.24,0.59,12.58,0.6,18.72,1.71c6.07,1.09,11.95,3.26,17.92,4.92c0.62,0.17,1.7,0.29,1.93-0.03c2.98-4.17,7.24-2.16,10.86-2.03
                c8.52,0.31,17.01,1.38,25.53,1.76c3.33,0.15,7.19,2.23,10.04-1.72c0.51-0.71,2.11-0.97,3.18-0.9c3.39,0.2,6.76,0.65,10.21,1.01
                c1.81-4.76,9.72-8.32,13.77-5.39c6.8,4.91,16.36,3.99,22.14,10.91c0.58,0.7,2.18,0.84,3.28,0.77c3.09-0.18,6.17-0.6,8.74-0.87
                c0.49-2.33,0.89-4.27,1.39-6.64c-3.84-1.05-7.79-2.14-12.45-3.41c1.26-1.01,1.63-1.57,1.98-1.55c6.62,0.35,13.3,3.41,19.83-0.49
                c0.13-0.08,0.36-0.12,0.49-0.06c3.76,1.76,9.16-1.29,11.76,4.07c0.29,0.59,2.02,1.12,2.67,0.8c3.81-1.84,6.22,0.77,8.99,2.43
                c1.55,0.93,3.72,2.71,4.77,2.24c3.73-1.68,6.34,0.51,9.24,1.75c2.74,1.17,6.39,0.45,6.01-1.84c-0.63-3.77-2.71-8.17-5.62-10.49
                c-4.67-3.73-10.55-5.92-15.86-8.87c-2.76-1.53-5.42-3.24-8.06-4.98c-0.75-0.5-1.85-1.42-1.79-2.05c0.51-5.29-3.8-5-6.86-6.14
                c-1.69-0.63-4.83-1.06-4.88-1.76c-0.33-4.75-4.14-4.75-7.12-6.06c-0.96-0.42-1.9-1.43-2.35-2.4c-2.52-5.38-8.34-8.31-14.18-6.97
                c-3.62,0.83-14.06-3.21-15.93-6.16c-0.33-0.33-0.65-0.66-0.98-0.98c-1.6-0.27-3.37-0.17-4.76-0.86
                c-7.84-3.86-16.8-5.74-22.98-12.57c-1.7-1.88-3.41-3.89-4.52-6.13c-1.61-3.22-3.32-4.77-6.95-2.74c-1.18,0.66-2.84,0.52-4.29,0.63
                c-4.53,0.33-9.08,0.51-13.6,0.97c-0.94,0.1-2.59,1.29-2.52,1.72c0.21,1.13,0.97,2.69,1.9,3.05c2.58,0.99,5.51,1.12,8.02,2.24
                c1.97,0.87,3.51,2.7,5.25,4.11c-1.43,1.21-2.69,2.79-4.34,3.52c-1.83,0.81-4.12,1.44-6,1.07c-4.38-0.87-8.48-1.8-12.8,0.61
                c-1.43,0.8-3.8-0.04-5.74-0.19c-3.8-0.3-7.3-0.27-11.26,0.92c-8.24,2.47-16.97,3.32-25.51,4.81c-0.29,0.05-0.65-0.27-0.95-0.24
                c-2.88,0.27-5.76,0.58-8.64,0.88c-0.03,0.62-0.06,1.23-0.09,1.85c1.4,0.65,2.73,1.62,4.2,1.91c5.63,1.09,11.6,3.53,16.88,2.52
                c6.69-1.28,13.07,0.09,19.52-0.55c2.97-0.29,5.8-2.48,8.74-2.61c4.6-0.19,9.24,0.95,13.86,0.96c4.52,0.01,9.04-0.61,13.55-1.09
                c2.66-0.29,5.3-0.84,7.94-1.28c1.64-0.31,3.28-2.43,4.81-2.36c9.15,0.43,18.25-4.77,27.45-0.31
                C450.02,424.44,451.22,423.61,452.17,423.31z M820.82,1148.77c-1.16-1.32-2.32-2.64-2.97-3.39c-24.34,0-48.5,0-72.67,0
                c-0.07,0.65-0.15,1.31-0.22,1.96c21.82,7.8,46.03,6.46,67.39,16.74c-12.42,7.78-26.35,5.35-39.83,6.57
                c15.33,1.25,29.93,5.34,44.44,10.21c-15.89,6.04-32.25,0.83-48.27,3.39c31.76,2.56,63.51,5.12,95.27,7.67
                c0.15-0.41,0.3-0.81,0.45-1.22c-3.91-2.07-7.61-4.87-11.77-6.07c-8.11-2.34-16.48-3.76-24.73-5.62c-2.22-0.5-4.4-1.22-6.59-1.84
                c0.04-0.7,0.08-1.4,0.13-2.1c6.47-0.91,12.93-1.82,19.39-2.74c0.08-0.42,0.16-0.84,0.24-1.26c-11.81-4.77-23.42-9.87-34.22-19.61
                c5.45-1.07,9.7-1.9,13.95-2.73c10.16,3.14,11.1-6.51,15.58-11.25C831.19,1141.25,826.01,1145.01,820.82,1148.77z M987.65,228.43
                c-9.71,11.61-19.85,22.88-28.99,34.91c-8.12,10.7-13.49,22.94-15.02,36.5c-0.18,1.62,0.92,3.76,2.12,4.99
                c0.55,0.56,2.82-0.34,4.2-0.86c1.38-0.52,2.55-1.57,3.93-2.1c5.46-2.08,8.2-0.04,7.87,5.74c-0.18,3.18,2.59,5,5.58,3.51
                c1.42-0.71,2.63-1.85,4.07-2.89c1.68,1.79,3.16,3.38,4.27,4.57c4.01-28.26,8-56.31,11.98-84.35
                C987.67,228.45,987.65,228.43,987.65,228.43z M251.37,1493.52c-2.33,0.06-4.67,0.32-6.98,0.15c-35.97-2.7-71.93-5.42-107.89-8.26
                c-2.57-0.2-6-0.53-7.43-2.19c-4.59-5.33-10.68-6.58-16.92-7.48c-27.97-4.01-55.94-7.96-83.95-11.67
                c-6.9-0.91-13.94-0.88-20.91-1.21c-2.54-0.12-5.18-0.24-5.31,3.52c-0.11,3.22,1.92,3.84,4.48,4.05c2.15,0.18,4.29,0.59,6.44,0.72
                c22.23,1.4,44.48,2.5,66.68,4.23c11.93,0.93,23.91,2.44,33.71,10.63c1.21,1.01,3.01,1.39,4.59,1.88
                c20.94,6.47,42.29,10.29,64.27,10.24c17.81-0.03,35.62-0.19,53.42,0.1c5.94,0.09,11.18-0.96,15.77-4.76L251.37,1493.52z
                M765.86,341.37l0.02-0.03c0.7,4.88,1.87,9.75,2,14.64c0.3,11.14,0.1,22.29,0.1,33.43c1.88-5.5,3.02-11.05,4.64-16.46
                c0.65-2.16,2.37-4.01,3.6-6c1.6,2.33,3.34,4.57,4.71,7.02c0.51,0.9,0.18,2.29,0.21,3.45c0.31,10.97,0.5,21.95,1.02,32.92
                c0.1,2.24,1.4,4.42,2.15,6.63c0.35-0.11,0.7-0.22,1.05-0.33c0.82-11.46,1.63-22.92,2.45-34.38c0.65-0.01,1.3-0.01,1.94-0.02
                c4.23,11.73,1.42,24.14,2.38,36.12c0.91-3.75,1.83-7.52,2.74-11.29c0.8,0.07,1.6,0.15,2.4,0.22c0.42,6.66,0.84,13.33,1.22,19.31
                c0-9.07-0.03-18.84,0.01-28.61c0.04-9.66-0.67-19.44,3.71-28.92c4.49,1.86,5.54,5.4,6.39,8.91c0.85,3.48,1.35,7.04,2.08,10.98
                c0.92-2.46,1.66-4.43,2.22-5.91c5.18-1.13,6.19,1.69,6.37,5.39c0.73-7.15,1.3-14.22,2.01-21.28c0.33-3.31-0.46-5.2-4.21-5.95
                c-2.57-0.52-4.81-2.58-7.23-3.88c-1.68-0.9-3.35-1.97-5.16-2.43c-7.11-1.83-14.28-3.41-21.41-5.18c-0.75-0.18-1.63-0.77-1.99-1.43
                C777.89,342.09,772.01,341.45,765.86,341.37z M745.9,1482.5l-0.02,0.02c-12.4-15.93-28.47-25.65-48.48-28.79
                c-19.16-3.01-35.28,3.24-48.24,17.01c-9.04,9.61-19.14,17.42-30.81,23.37c-1.83,0.93-3.59,2.03-5.38,3.05
                c10.93,0.9,21.53,0.97,32.12,0.83c1.36-0.02,2.74-1.02,4.05-1.69c4.57-2.36,8.93-5.27,13.7-7.05c9.48-3.54,19.13-6.68,28.83-9.58
                c5.2-1.55,10.61-2.81,15.99-3.23C720.84,1475.4,733.38,1479.1,745.9,1482.5z M1073.6,255.4l-0.04,0.04
                c0.21-1.48,0.54-2.95,0.61-4.44c1.02-24.24,2.04-48.49,2.97-72.73c0.18-4.59,0.03-9.18,0.03-13.78c-0.51-0.21-1.02-0.41-1.52-0.62
                c-3.26,5.63-8.13,10.91-9.49,16.96c-5.19,23.12-12.63,45.46-21.42,67.38c-4.05,10.11-9.8,19.24-17.48,27.07
                c-1.74,1.78-3.53,3.57-4.92,5.61c-2.44,3.57-1.91,10.22,0.91,10.63c6.17,0.89,12.47,1.79,18.44-1.57c1.8-1.01,4.2-1.65,6.21-1.42
                c4.85,0.57,5.69,0.07,4.84-4.85c-0.54-3.13-0.32-5.59,3.09-6.89c0.65-0.25,1.45-1.2,1.46-1.84c0.06-4.21,2.62-5.59,6.3-5.53
                C1062,255.59,1064.21,252.59,1073.6,255.4z M799.81,551.16c2.24-10.18,1.26-20.97,5.11-30.85c0.51,0.03,1.03,0.06,1.54,0.1
                c0,4.3,0,8.61,0,12.91c0.25,0,0.49-0.01,0.74-0.01c0.79-2.09,1.57-4.17,2.63-6.98c1.28,1.59,2,2.48,2.71,3.35
                c1.76-4.09,3.84-3.44,6.58,0.55c0.51-6.88,1.15-12.83,1.25-18.79c0.03-1.69-1.19-4.48-2.47-4.93c-7.12-2.48-14.12-5.74-22.16-4.03
                c-3.04,0.65-6.46,0.16-9.59-0.44c-4.05-0.77-8.35-3.79-11.88-2.97c-7.19,1.66-13.82-0.57-20.69-0.63
                c-7.52-0.06-13.89-2.64-20.09-6.46c-2.04-1.26-4.55-2.23-6.91-2.4c-10.07-0.72-19.62-3.02-28.68-7.59
                c-4.89-2.47-10.02-4.49-15.12-6.53c-5.06-2.02-6.82-0.83-6.44,4.74c0.4,5.96,1.36,11.88,2.02,17.83
                c0.86,7.79,1.67,15.59,2.5,23.38c0.44-0.03,0.87-0.05,1.31-0.08c0-6.39,0-12.78,0-19.81c1.94,1.03,3.19,2.28,4.19,2.11
                c4.75-0.81,5.64,1.9,5.88,5.65c0.31,4.74,0.77,9.46,1.16,14.19c1.15-4.74,0.8-9.61,1.74-14.23c0.82-4.02,2.95-7.82,4.83-11.55
                c0.36-0.72,2.28-0.99,3.45-0.92c5.62,0.32,6.76,4.57,7.08,8.69c1.81-1.53,3.47-2.94,5.13-4.34c0.82,2.22,1.81,4.4,2.4,6.69
                c0.36,1.41,0.13,2.98,0.13,4.48c0.01,7.65,0,15.3,0,20.47c0.83-5.78,2.02-14.09,3.34-23.3c1.38,1.69,2.17,2.67,3.03,3.72
                c0.92-1.59,1.38-3.31,2.48-4.03c1.07-0.7,2.89-0.13,4.25-0.56c1.96-0.62,4.83-2.83,5.49-2.26c1.91,1.66,3.14,4.27,4.18,6.7
                c0.55,1.28,0.12,2.96,0.19,4.45c0.06,1.33,0.19,2.66,0.2,2.73c2.11-4.11,4.6-8.95,7.41-14.42c6.44,7.21,9.06,14.22,7.41,22.64
                c0.99-2.45,1.37-4.98,2.45-7.16c1.09-2.19,2.89-4.03,4.37-6.03c1.96,1.88,4.65,3.42,5.73,5.71c2.65,5.62,4.57,11.59,6.59,16.92
                c0.75-3.69,1.6-8.01,2.52-12.32c1.13-5.32,3.48-9.25,9.31-11.07c5.68-1.77,7.67-1.74,8.68,4.25c1.82,10.75,2.76,21.64,4.07,32.48
                c-0.22,0.5-0.43,1-0.06,0.14C799.72,552.27,799.76,551.72,799.81,551.16z M949.69,504.25l0.02-0.02c0.1,5.01-2.04,6.79-6.09,4.34
                c-2.71-1.64-4.61-4.61-7.11-7.24c0.82,12.05,1.89,24.15,2.42,36.26c1.18,26.74,1.62,53.52,3.29,80.23
                c0.99,15.82,3.72,31.52,5.67,47.28c11.71-49.23,17.67-98.98,20.44-149.72c-6.68,1.76-9-3.02-12.29-6.44
                C954.25,507.06,951.83,505.79,949.69,504.25z M949.72,174.5c-8.84,0.49-14.51-4.86-13.99-13.17c0.25-3.98-3.37-6.3-7.38-5.07
                c-2.08,0.64-5.05,1.54-6.46,0.6c-3.89-2.61-7.1-6.22-10.71-9.27c-3.07-2.6-5.29-7.11-10.77-5.44
                c0.95,41.24,16.23,77.91,33.68,114.08c6.02-9.89,11.61-19.43,12.26-31.01c0.95-16.92,2.2-33.82,3.33-50.73l0.21-0.34L949.72,174.5
                z M643,380.32l-0.02,0.02c-7.59-19.28-20.69-35.01-33.02-51.22c-6.62-8.7-13.5-17.19-20.35-25.71c-0.99-1.23-2.46-2.1-3.73-3.09
                c-5.05-3.94-11.81-6.75-9.82-14.3c-3.46-0.46-6.61-0.94-9.77-1.25c-1.24-0.12-2.67-0.22-3.75,0.26
                c-5.85,2.65-11.24,1.51-16.48-1.6l-0.14-0.32l0.17,0.3c4.59,13.81,12.29,25.55,21.99,36.44c6.08,6.82,11.66,14.8,15,23.23
                c8.59,21.65,24.43,34.66,45.51,42.53c2.62,0.98,4.17,0.6,5.86-1.75C636.44,381.1,639.12,379,643,380.32z M1129.51,210.49
                l-0.14-0.29l0.18,0.26c1,29.33,2.01,58.67,3.01,88c0.51,0.05,1.03,0.11,1.54,0.16c1.75-3.92,3.5-7.85,5.73-12.85
                c1.76,3.07,2.86,4.99,4.48,7.81c7.91-18.6,7.04-38.49,12.59-56.79c0.52-0.02,1.04-0.04,1.57-0.05c0.93,3.22,1.86,6.44,2.79,9.66
                c-0.17,0.02-0.34,0.04-0.51,0.06c0-52.63,0-105.26,0-157.88c-0.49-0.08-0.97-0.16-1.46-0.24c-0.81,3.96-2.12,7.89-2.34,11.88
                c-0.63,11.12-0.75,22.28-1.21,33.41c-0.09,2.25-0.81,4.48-1.25,6.72c-0.55,0.01-1.1,0.02-1.66,0.03
                c-1.87-3.34-3.74-6.69-5.62-10.03c-0.47,0-0.94,0-1.4,0c-0.64,2.76-1.87,5.53-1.82,8.28c0.33,16.13,0.67,32.26,1.6,48.36
                c0.62,10.79,3.82,21.67-5.53,30.76c-2.4,2.33-3.96,2.99-5.92,0.39C1132.37,215.76,1131.04,213.05,1129.51,210.49z M22.59,484.23
                c0,0-0.05,0.04-0.05,0.04c0.83-2.18,1.66-4.36,2.48-6.55c0.32,0.62,0.65,1.25,0.97,1.87c12.87-0.9,25.75-1.8,38.81-2.71
                c-2.04-5.17-1.78-5.91,2.59-7.52c2.51-0.92,4.95-2.76,7.86-0.6c0.37,0.27,1.96-1.16,3.02-1.74c2.81-1.54,5.58-3.15,8.47-4.53
                c2.52-1.21,5.18-2.14,7.77-3.2c1.11-0.45,2.23-1.3,3.34-1.28c4.77,0.07,8.42-2.02,11.68-4.96c-1.38-3.02-1.94-6.29-3.87-8.05
                c-3.43-3.11-7.41-6.09-11.69-7.67c-4.48-1.65-8.53-4.25-14.58-2.73c-4.73,1.19-10.48-0.58-15.49,2.29
                c-0.61,0.35-1.88-0.41-2.84-0.69c-3.22-0.96-5.67-0.59-7.51,2.82c-0.73,1.36-2.99,2.32-4.71,2.65c-3.71,0.71-7.59,0.57-11.25,1.41
                c-3.01,0.69-4.62-0.14-6.13-2.65c-2.05-3.43-4.84-5.52-9.34-4.74c-6.32,1.09-12.71,1.73-19.69,2.65c0,11.27,0,23.36,0,35.35
                c5.87-0.14,10.93,2.09,13.37,6.24C17.72,483.16,19.59,483.96,22.59,484.23z M982.66,962.9l0.01-0.01
                c4.15,4.07,8.51,7.96,12.42,12.25c13.17,14.47,22.78,31.66,36.52,45.91c11.66,12.1,20.31,27.09,30.41,40.73
                c1.66,2.25,3.88,4.09,5.83,6.11c0.42-0.25,0.85-0.5,1.27-0.75c0-1.93,0.08-3.87-0.01-5.79c-1.28-25.41-2.57-50.82-3.92-76.23
                c-0.1-1.8-0.49-3.63-1.07-5.34c-5.9-17.3-15.14-32.9-24.88-48.22c-0.74-1.16-2.14-1.89-3.24-2.82c-0.43,0.35-0.86,0.69-1.29,1.04
                c0.65,1.2,1.01,2.96,2.02,3.49c2.88,1.52,1.03,2.47-0.1,3.63c0.88,1.41,1.76,2.81,2.79,4.45c-2.23,1.38-4,2.47-5.76,3.56
                c0,0-0.03-0.03-0.03-0.03c2.95,0.51,7.39,0,8.57,1.72c3.22,4.69-2.01,7.42-5.3,11.26c4.66-0.91,8.21-1.6,12.63-2.47
                c-1.38,4.51-7.06,7.92-0.19,11.01c-1.24,1.46-2.16,2.54-3.25,3.8c1.82,0.68,3.33,1.24,4.33,1.61c0.66,3.73,2.61,7.61,1.47,10.06
                c-1.71,3.7,0.65,4.26,2.28,5.77c2.47,2.28,4.98,4.51,7.47,6.76c-0.34,0.46-0.69,0.91-1.03,1.37
                c-18.55-16.46-39.44-28.47-63.97-34.78c1.93,2.73,3.51,4.98,5.97,8.47c-3.81-0.32-6.08-0.02-7.93-0.77
                C990.56,967.05,986.66,964.87,982.66,962.9z M124.47,507.25c-1.39-0.4-2.91-0.58-4.16-1.25c-5.96-3.22-11.85-6.57-17.8-9.9
                c-0.76,3.92-5.04,7.16-6.88,5.15c-3.59-3.9-8.09-3.13-12.39-3.63c-3.27-0.38-6.6-0.31-9.82-0.9c-4.91-0.9-9.71-2.35-14.62-3.2
                c-1.18-0.2-2.56,1.29-3.93,1.52c-3,0.5-6.77,1.95-8.93,0.73c-3.4-1.92-6.34-2.92-10.04-1.92c-0.77,0.21-1.77,0.45-2.4,0.13
                c-6.01-3.01-12.61-0.08-18.74-1.94c-2.13-0.65-4.53-0.53-6.53-1.39c-4.15-1.79-6.17-0.93-6.22,3.49
                c-0.12,11.98-0.14,23.96,0.03,35.94c0.05,3.51,1.37,6.47,5.78,6.68c2.44,0.11,4.85,0.81,7.29,1.07c4.46,0.48,9.82-0.57,13.22,1.55
                c6.06,3.78,9.56,1.57,13.88-2.24c1.23-1.09,5.59-1.06,5.84-0.45c2.25,5.53,5.45,3.3,8.8,1.62c1.01-0.5,2.67-1.22,3.27-0.78
                c3.5,2.53,6.3-0.34,9.4-0.84c2-0.32,4.04-0.34,6.05-0.63c1.07-0.15,2.41-0.26,3.08-0.94c3.11-3.14,3.04-7.42,0.25-11.13
                c-0.77-1.02-1.2-2.34-1.57-3.59c-0.45-1.5-1.19-4.31-0.93-4.42c2.19-0.9,5.06-2.42,6.84-1.66c6.74,2.85,13.31,6.21,19.63,9.91
                c7.19,4.2,14.33,7.08,20.58-1.06c0.61-0.8,2.35-0.65,3.4-1.24c1.15-0.65,2.65-1.46,3.01-2.54c0.76-2.25,0.88-4.72,1.03-5.71
                c-2.62-2.64-4.53-4.55-6.43-6.46l0.34-0.14L124.47,507.25z M357.25,544.21c-5.78,0.67-11.18,3.09-17.52,0.69
                c-3.51-1.33-8.37,0.95-12.63,1.54c-0.95,0.13-2.15,0.37-2.86-0.06c-5.24-3.23-10.91-2.69-16.73-3.19
                c-7.11-0.62-11.21,2.21-15.52,6.61c-2.43,2.48-5.24,4.64-8.07,6.67c-11.77,8.43-23.74,16.57-35.36,25.19
                c-5.51,4.08-10.41,8.99-15.7,13.62c3.11,3.6,4.97,1.62,7.16,0.46c1.75-0.93,4.6-2.23,5.72-1.48c6.04,4,9.99,0.16,14.6-2.74
                c2.17-1.36,5.16-1.96,7.77-1.92c2.36,0.04,4.66,1.58,7.06,2.16c3.44,0.82,7.53,2.8,10.26,1.67c3.2-1.32,5.19-0.37,7.86,0.22
                c2.86,0.63,6.6,1.74,8.7,0.5c6.23-3.65,12.08-3.32,18.42-0.95c1.56,0.58,3.58,0.37,5.29,0.04c5.18-1,9.75-5.27,15.76-0.82
                c2.45,1.81,8.37,2.13,10.56,0.4c4.37-3.46,7.85-1.69,11.99-0.91c3.65,0.68,7.46,0.63,11.2,0.62c7.04-0.01,14.22-1.14,21.08-0.1
                c6.87,1.04,11.51-1.46,16.12-5.62c1.28-1.15,2.94-2.78,4.36-2.71c5.38,0.25,6.45-3.32,7.6-6.84c-3.68-1.23-7.13-3.51-10.34-3.22
                c-7.41,0.68-13.05-3.04-19.24-5.7c-1.9-0.81-4.31-0.36-6.45-0.71c-1.74-0.29-3.61-0.62-5.1-1.48c-3.24-1.88-6.14-4.37-9.43-6.14
                c-2.81-1.51-5.92-2.54-9-3.42c-2.2-0.62-4.61-0.46-6.86-0.96c-1.76-0.38-4.28-0.78-4.92-1.98c-0.81-1.53-0.55-4.1,0.19-5.82
                c0.65-1.51,2.6-2.46,3.98-3.65c0.34,0.01,0.69,0.01,1.03,0.02c0.35,3.23-5.73,4.85-2.23,8.14c3.41,0.16,6.52,0.79,9.52,1.77
                c1.87,0.61,3.49,2.3,5.35,2.56c6.37,0.88,11.58,3.68,15.91,7.92c2.9-0.16,5.88-0.23,8.3,0.9c6.19,2.92,12.1,5.85,19.36,5.68
                c3.91-0.09,7.9,1.97,11.78,3.26c0.73,0.24,1.51,1.41,1.61,2.23c0.3,2.4-5.1,9.84-7.36,9.81c-6.8-0.11-9.12,6.32-15.05,8.69
                c1.62,1.08,2.21,1.7,2.92,1.91c6.45,1.88,14.2,1.16,17.63,8.99c0.1,0.23,0.58,0.42,0.88,0.4c5.42-0.22,10.22,1.6,14.77,4.34
                c6.34,3.82,12.71,7.59,19.06,11.4c0.27,0.16,0.44,0.5,0.62,0.78c2.81,4.44,5.61,8.89,8.41,13.34c-0.42,0.04-0.83,0.07-1.25,0.11
                c0,2.08-0.85,4.82,0.16,6.1c3.1,3.93,7.26,7.03,10.3,11c1.64,2.15,2.56,5.45,2.37,8.13c-0.09,1.16-3.79,2.1-5.9,3.05
                c-1.04,0.47-2.14,0.85-3.26,1.06c-3.65,0.7-7.34,1.95-10.96,1.79c-4.68-0.21-9.29-1.81-13.98-2.37
                c-3.95-0.47-8.08-0.91-11.95-0.26c-3.94,0.67-6.38-0.27-8.3-3.57c-0.6-1.03-1.64-2.61-2.46-2.59c-8.32,0.14-16.19-1.28-23.79-4.87
                c-2.92-1.38-6.57-2.49-9.64-2.04c-5.58,0.83-8.37-1.71-9.49-4.43c-7.36-2.28-13.3-4.11-18.31-5.66c1.23,2.49,2.43,4.93,3.64,7.37
                c-2.62,0.91-5.19,2.02-7.87,2.64c-1.37,0.32-2.95-0.24-4.43-0.41c-8.16-0.94-16.31-1.88-24.47-2.82c-0.16-0.02-0.3-0.15-0.45-0.21
                c-4.34-1.56-5.45-0.63-4.71,3.92c0.61,3.75,1.09,7.76-3.49,9.18c-3.24,1-6.93,1.66-10.2,1.12c-3.9-0.64-4.63,0.55-4.69,3.98
                c-0.18,10.65-0.96,21.3-0.83,31.94c0.43,35.6,0.55,71.23,1.95,106.8c1.05,26.74,3.85,53.41,5.96,80.1c0.12,1.53,0.67,3.4,1.7,4.43
                c2.42,2.43,5.03,4.82,7.96,6.55c6.68,3.95,8.5,10.19,9.16,17.22c0.1,1.03,0.4,2.52,1.11,2.94c4.41,2.61,4.65,6.87,5.06,11.25
                c0.42,4.45,1.1,8.88,1.9,13.28c0.2,1.08,1.27,2.79,2.03,2.85c4.43,0.32,8.88,0.22,13.33,0.29c1.46,0.02,3.48-0.5,4.27,0.25
                c3.24,3.1,6.95,2.62,10.72,2.03c3.55-0.56,5.86,0.76,6.84,4.19c0.8,2.8,2.59,3.57,5.39,4.14c4.39,0.9,8.57,2.81,12.82,4.35
                c0.42,0.15,0.73,0.67,1.04,1.06c2.45,3.11,5.41,3.68,9.31,2.88c4.88-1,9.75-0.8,12.08,5.06c0.38,0.97,1.6,1.57,2.33,2.43
                c1.64,1.96,3.87,3.74,4.69,6.02c1.67,4.64,4.38,6.3,9.32,6.24c19.14-0.22,38.29-0.08,57.44-0.01c3.41,0.01,6.81,0.32,11.23,0.54
                c-1.56-3.02-2.53-5.26-3.81-7.3c-4.32-6.82-4.01-8.56,2.57-12.73c3.31-2.1,4.57-6.73,2.22-8.31c-2.83-1.9-5.73-3.71-8.61-5.54
                c-5.49-3.49-7.42-7.79-5.2-13.94c1.39-3.85,3.45-7.33,2.32-11.69c-0.2-0.79,0.78-2.57,1.54-2.81c5.81-1.78,3.96-5.61,3.21-9.65
                c-0.6-3.24-1-7.14,0.25-9.97c1.88-4.26,2.04-10.47,8.77-11.2c0.48-0.05,0.68-1.5,1.26-2.03c4.17-3.89,8.05-8.42,15.51-7.13
                c1.68-3.83,3.77-7.71,5.07-11.84c1.36-4.3,2.2-8.51,8.21-7.77c0.7,0.09,1.49-1.17,2.33-1.65c3.39-1.93,6.87-5.44,10.2-5.32
                c6.12,0.21,5.42-3.73,7.11-8.23c-27.35-2.36-54.04-4.27-81.05-3.55c2.36-3.08,5.08-4.94,7.29-7.28c5.06-5.37,10.97-7.18,18.1-5.29
                c5.13,1.36,9.32-2.09,11.68-5.05c3.58-4.47,6.18-8.35,12.31-5.04c4.01-10.58,6.71-11.89,21.39-10.7
                c9.54-21.28,19.52-25.64,33.65-18.56c2.3-5.62,2.32-5.57,8.85-8.39c0.89-0.38,1.74-1.52,2.05-2.49c2.67-8.4,2.63-8.41,11.07-7.73
                c1.62-5.59,1.96-12.22,8.35-14.97c5.38-2.32,11.08-6.24,16.3,0.83c2.89-12.46,12.14-17.58,22.6-18.99
                c21.89-2.96,43.73-3.59,60.06,15.57c8.02-5.65,14.88,0.35,22.3,1.47c1.91-0.92,4.28-3.04,6.47-2.87c2.44,0.2,4.72,2.48,7.06,3.86
                c7.26-10.07,16.51-11.35,25.03-2.81c3.67,3.68,7.14,4.65,11.97,3.48c4.44-1.07,9.69-0.34,13.39-2.48
                c3.76-2.18,6.62-5.21,10.07-3.32c2.67-2.56,4.34-4.82,6.57-6.12c2.53-1.48,5.71-1.83,8.33-3.2c1.41-0.73,3.11-2.52,3.19-3.92
                c0.88-15.28,1.53-30.57,2.09-45.86c0.41-10.98,0.57-21.96,0.9-32.94c0.37-12.47,1.14-24.94,1.11-37.41
                c-0.02-8.73-4.78-13.44-14.02-15.1c-0.39,1.52-0.93,3.07-1.17,4.65c-3.8,24.47-7.52,48.96-11.41,73.42
                c-0.37,2.3-1.75,4.43-2.67,6.64c-0.46-0.15-0.91-0.3-1.37-0.45c-2.62-22.66-5.23-45.32-7.85-67.98c-0.61,0.03-1.22,0.07-1.82,0.1
                c-6.31,23.44-4.76,48.14-10.95,71.69c-1.01-14.14-0.45-28.25-1.17-42.29c-0.72-14.09-2.71-28.11-4.15-42.17
                c-0.68,0-1.37-0.01-2.05-0.01c-1.21,4.1-3.09,8.12-3.52,12.3c-1.95,19.01-3.52,38.07-5.19,57.11c-0.96,10.93-1.8,21.88-2.78,32.81
                c-0.14,1.51-0.73,2.99-1.11,4.48c-0.46-0.02-0.93-0.03-1.39-0.05c-0.56-10.04-1.12-20.07-1.68-30.11
                c-0.45,0.04-0.9,0.07-1.35,0.11c-1.75,7.57-3.5,15.13-5.25,22.7c-0.47-0.06-0.94-0.13-1.41-0.19c-1.28-18.33-2.57-36.66-3.85-55
                c-0.86-0.04-1.71-0.08-2.57-0.13c-1.85,8.87-4.54,17.66-5.37,26.62c-1.37,14.73-1.49,29.57-2.25,44.35
                c-0.1,1.87-0.84,3.7-1.29,5.55c-0.56-0.08-1.12-0.16-1.69-0.24c-0.2-2.04-0.77-4.13-0.53-6.13c0.51-4.27-1.59-5.42-5.36-5.76
                c-5.95-0.53-11.9-1.25-17.78-2.28c-7.85-1.37-15.58-3.87-23.47-4.53c-15.23-1.28-30.55-1.51-45.83-2.36
                c-13.95-0.78-27.87-2.09-41.82-2.62c-8.71-0.33-17.8-3.94-26.08,2.16c-0.93,0.69-2.58,0.53-3.9,0.55
                c-24.3,0.36-48.59,0.7-72.89,1.02c-2.16,0.03-4.4,0.13-6.46-0.39c-1.36-0.34-2.45-1.73-3.66-2.65c1.09-0.87,2.06-2.19,3.29-2.52
                c3.53-0.93,7.15-1.53,10.76-2.13c5.34-0.89,10.7-1.66,16.88-2.61c-3.66-5.26-0.66-7.8,2.39-10.32c4.39-3.64,9.6-4.45,15.04-4.06
                c3.75,0.27,6.32-0.81,9-3.81c2.22-2.49,6.18-4.79,9.35-4.77c5.88,0.05,11.55,0.67,17.44-1.04c2.7-0.78,4.16-1.09,4.23-4.03
                c0.12-5.33,2.75-9.04,7.44-11.53c1.14-0.61,2.6-1.99,2.64-3.07c0.29-6.6,3.07-11.07,9.84-12.38c0-3.07-0.13-5.74,0.02-8.39
                c0.62-10.46,9.07-18.99,18.81-19.1c10.08-0.12,10.25-0.32,8.27-10.09c-0.06-0.3-0.24-0.58-0.37-0.88
                c-3.12,2.29-3.38,0.21-3.77-2.27c-1.26-7.87-2.52-15.74-4.11-23.55c-0.59-2.88-2.1-5.56-3.19-8.33c-0.62,0.06-1.23,0.13-1.85,0.19
                c-2.53,12.59-5.07,25.17-7.6,37.76c-0.58-0.06-1.15-0.11-1.73-0.17c-0.71-10.78-1.41-21.56-2.12-32.34
                c-0.48,0.3-0.95,0.61-1.43,0.91c-3.06-2.47-6.11-4.94-9.51-7.68c-3.91,15.12-2.96,31.16-8.14,46.16
                c-0.94-4.13-0.76-8.19-1.03-12.23c-0.13-1.86-0.74-4.93-1.83-5.28c-4.73-1.51-3.88-5.18-3.93-8.45
                c-0.06-4.32,0.24-8.64,0.35-12.96c0.01-0.42-0.22-0.86-0.38-1.43c-0.84,1.34-1.57,2.49-2.98,4.73c-2.21-3.16-3.91-5.59-5.58-7.98
                c-2.62,22.87-5.23,45.72-7.84,68.56c-0.49-0.02-0.97-0.03-1.46-0.05c-0.46-10.32-0.91-20.65-1.37-30.97
                c-0.47-0.01-0.93-0.01-1.4-0.02c-2.41,10.76-2.51,22.08-7.43,32.38c-0.89-2.75-1.18-5.46-0.84-8.1c1.15-8.95,1.1-17.62-1.02-26.64
                c-2.17-9.21-6.86-18.04-5.67-27.96c0.03-0.26-0.29-0.56-0.44-0.85c-0.34,0.29-0.67,0.57-1.01,0.86c-1.61-1.69-3.56-3.17-4.77-5.1
                c-2.56-4.09-6.25-4.44-8.89-0.39c-0.98,1.5-1.72,3.28-2.06,5.04c-0.84,4.4-1.46,8.85-2.01,13.3c-0.63,5.1-1.08,10.22-1.62,15.34
                c-0.26-0.05-0.53-0.1-0.79-0.15c-3.92-11.91-7.83-23.81-12.15-36.93c-3.83,5.93-2.59,13.71-11,14.73
                c-1.66,8.45-3.49,17.72-5.31,26.98c-0.52-0.05-1.04-0.1-1.55-0.15c-1.33-9.37-2.67-18.74-4.01-28.17
                c-3.15,2.55-5.57,4.51-7.99,6.46c-0.55-0.16-1.09-0.32-1.64-0.48c-1.69-15.06-3.38-30.13-5.28-47.11
                c-6.04,9.68-12.79,7.68-19.4,4.12c-3.73,4.04-11.87,5.31-17.81,2.87c-4.11-1.69-7.92-5.48-12.61-1.22
                c-0.26,0.23-1.04,0.13-1.44-0.08c-6.89-3.69-15.52,2.08-21.99-4.04c-0.36-0.34-1.31-0.25-1.93-0.11
                c-8.85,2.12-17.09-1.72-25.63-2.61C357.92,544.21,357.59,544.21,357.25,544.21z M434.18,1086.8c2.36,0.3,4.72,0.59,7.09,0.89
                c0.02-0.14,0.03-0.28,0.05-0.42c-2.39-0.14-4.78-0.28-7.17-0.42c-7.03-1.22-14.15-2.08-21.04-3.83c-3.36-0.85-6.3-3.36-9.44-5.13
                c0.19-0.64,0.39-1.27,0.58-1.91c1.63-0.5,3.24-1.34,4.9-1.44c17.59-1.06,35.18-2.48,52.79-2.88c31.95-0.73,63.92-0.79,95.89-1.24
                c3.9-0.06,7.79-0.81,11.69-1.24c-0.95-0.92-1.86-1.01-2.77-1.03c-18.14-0.33-36.27-0.89-54.41-0.9
                c-16.13-0.01-32.27,0.68-48.4,0.84c-5.75,0.06-11.39-0.93-16.29-4.87c16.06-4.61,32.54-5.66,48.6-7.34
                c-4.2-1.52-8.48-3.08-12.77-4.64c0.06-0.53,0.13-1.06,0.19-1.59c0.41-0.26,0.8-0.63,1.25-0.76c1.76-0.5,3.54-0.89,5.3-1.38
                c16.27-4.47,32.92-4.14,49.57-4.08c3.79,0.01,7.59-1.05,11.38-1.61c-0.04-0.56-0.09-1.11-0.13-1.67
                c-11.53-0.99-23.04-2.15-34.59-2.94c-10.29-0.7-20.6-0.97-30.91-1.47c-4.03-0.19-6.65-1.71-5.49-6.72
                c3.91-1.55,7.99-3.16,12.09-4.78c-1.45-2.93-2.61-5.29-3.99-8.08c-49.41,0-99.33,0-149.36,0c0.77,5.19,3.11,9.05,8.56,10.3
                c4.67,1.07,9.32,2.53,14.06,2.98c21.93,2.08,43.14,6.5,62.06,18.49c2.6,1.65,6.68,4.55,6.43,6.39c-0.59,4.51-5.52,4.57-9.25,4.78
                c-9.64,0.53-19.31,0.45-28.93,1.17c-6.09,0.45-12.22,1.45-18.13,2.99c-7.79,2.03-15.38,4.82-20.05,6.32
                c9.06-0.02,21.65,1.42,33.04-5.63c1.59-0.99,3.84-1.12,5.81-1.28c10.28-0.79,20.57-1.43,30.86-2.17c3.03-0.22,6.05-0.59,9.07-0.89
                c0.14,0.45,0.27,0.9,0.41,1.35c-1.48,0.8-2.88,1.89-4.46,2.34c-4.95,1.42-9.91,2.93-14.97,3.77c-6.87,1.15-13.86,1.59-20.77,2.55
                c-7.34,1.02-14.63,3.22-21.95,3.24c-9.04,0.02-18.06-2.13-27.12-2.53c-9.96-0.44-19.96,0.01-29.94,0.19
                c-0.93,0.02-1.84,0.88-2.76,1.35c0.06,0.56,0.12,1.12,0.18,1.69c2.5,0,5.01,0.02,7.51,0c11.93-0.1,21.68,4.2,28.89,13.98
                c1.43,1.94,3.47,3.67,5.59,4.82c11.86,6.39,23.76,12.74,35.83,18.72c3.14,1.55,6.98,2.55,10.45,2.44
                c27.96-0.95,55.92-2.22,83.87-3.4c-16.79-1-33.6-0.72-50.39-1.14c-6.4-0.16-12.81-1.68-19.1-3.09c-2.32-0.52-4.31-2.53-6.46-3.86
                c0.12-0.74,0.25-1.47,0.37-2.21c8.23-0.61,16.47-1.21,22.65-1.67c-12.8-0.57-28.21,1.78-41.72-7.19
                C403.43,1085.1,418.85,1086.52,434.18,1086.8z M331.28,1055.82l0.21,0.15l-0.23-0.13c-12.92-15.37-28.9-26.18-47.88-32.81
                c-5.58-1.95-8.9-6.75-11.02-12.3c2.52,1.38,4.31,3.46,6.15,5.51c4.27,4.76,10.14,6.03,16.02,7.29c7.08,1.51,14.17,3.03,21.24,4.63
                c2.91,0.66,5.83,2.34,8.65,2.13c9.98-0.72,18.96,2.96,28.23,5.46c3.59,0.97,7.29,1.54,10.94,2.3c0.21-0.74,0.42-1.48,0.63-2.22
                c-12.83-4.19-25.46-9.21-38.55-12.3c-9.27-2.19-19.15-1.72-28.75-2.58c-2.79-0.25-5.98-0.24-8.23-1.6
                c-13.56-8.14-27.68-15.8-28.8-34.62c-0.47-7.91-4.75-14.2-10.77-19.61c-3.3-2.97-6.67-7.2-7.49-11.36
                c-3.98-20.04-7.88-40.15-10.31-60.41c-2.42-20.12-2.86-40.46-4.51-60.68c-2.72-33.39-3.03-66.77-1.66-100.25
                c0.62-15.2,6.26-26.91,17.18-37.21c5.65-5.34,9.41-12.67,14.03-19.11l0.02,0.02c-5.34,1.21-8.68,4.56-11.75,9.09
                c-3,4.43-6.97,8.62-11.41,11.57c-7.63,5.07-9.53,3.78-9.75-5.32c-0.13-5.63-0.03-11.26-0.03-16.91c-1.79-0.51-3.28-0.73-4.6-1.34
                c-6.38-2.94-6.41-2.92-6.11,4.25c0.62,15.24,1.22,30.48,1.81,45.72c0.04,1,0.06,2.01-0.07,2.99c-2.59,18.35,1.51,36.48,1.79,54.73
                c0.2,12.97,0.11,25.95,0.09,38.92c0,0.46-0.65,0.92-1.35,1.84c-0.75-1.69-1.27-2.87-2.05-4.63c0,4.78-0.17,8.78,0.04,12.75
                c0.46,8.63,1.47,17.25,1.58,25.88c0.12,9.59-0.84,19.18-0.73,28.77c0.14,11.96,0.8,23.92,1.42,35.88
                c0.21,4.11,0.03,8.65,1.72,12.18c3.97,8.29,2.74,16.55,1.57,24.95c-0.1,0.75-0.71,1.43-1.08,2.14c-1.25,2.35-3.31,4.6-3.62,7.07
                c-1.55,12.08-3.37,24-10.94,34.12c-1.08,1.44-1.98,3.03-3.19,4.35c-6.63,7.19-13.25,14.41-23.24,17.05
                c-0.45,0.12-0.93,0.47-1.21,0.85c-4.92,6.48-12.66,8.9-18.97,13.36c-7.45,5.27-15.55,8.02-24.56,8.76
                c-11.93,0.97-21.03,7.8-29.72,15.57c6.02-1.08,11.74-2.97,17.4-5.01c5.3-1.91,10.49-4.11,15.82-5.94
                c6.71-2.3,13.75-3.79,20.2-6.65c6.79-3.02,13.28-5.58,20.99-5.05c8.14,0.56,10.95-1.94,13.44-9.59c0.93-2.85,1.74-5.73,2.66-8.58
                c2.75-8.52,8.72-14.73,15.19-22.29c-0.23,1.99-0.16,2.51-0.36,2.91c-0.44,0.89-0.87,1.85-1.54,2.56
                c-7.84,8.21-11.24,18.55-14.81,29.02c-1.68,4.93-5.12,9.94-9.12,13.26c-6.6,5.47-14.31,9.59-21.44,14.21
                c4.37-0.97,9.01-2.07,13.69-3c1.9-0.38,3.88-0.44,5.82-0.56c2.32-0.13,4.81,0.35,6.92-0.34c5.66-1.86,11.51-3.61,16.62-6.55
                c4.61-2.66,8.87-6.41,12.21-10.55c1.25-1.54-0.75-5.53-0.91-8.42c-0.2-3.72-0.05-7.46-0.05-11.19c3.8,6.05,5.82,12.49,9.51,17.77
                c4.82,6.89,5.38,12.75-0.55,18.9c-0.07,0.07,0.21,0.49,0.31,0.71c5.55-2.63,11.15-5.15,16.62-7.93c6-3.06,7.19-6.11,5.3-12.64
                c-0.93-3.19-2.06-6.33-2.89-9.54c-0.45-1.74-0.47-3.6-0.83-6.57c1.61,1.28,2.24,1.56,2.54,2.05c5.11,8.18,10.57,16.18,15.13,24.66
                c2.95,5.48,7,9.24,12.4,11.72c3.92,1.8,8.04,3.17,12.07,4.73l-0.01,0.01c-8.03-8.72-14.58-18.36-20.05-28.97
                c-3.34-6.46-8.48-12.19-13.61-17.49c-7.83-8.09-9.13-18.18-11.39-28.81c9.15,3.02,17.72,5.13,19.33,15.65
                c0.14,0.91,0.91,1.77,1.5,2.56c4.86,6.52,9.71,13.04,14.65,19.5c0.68,0.88,1.72,1.54,2.71,2.12c9.11,5.4,18.35,10.59,27.34,16.18
                C313.14,1048.76,321.39,1054.03,331.28,1055.82z M184.41,1039.83c-4.29,0.34-8.61,0.45-12.85,1.09c-3.72,0.56-7.47,1.38-11,2.66
                c-16.61,6.01-33.12,12.27-49.73,18.28c-2.29,0.83-4.91,1.22-7.34,1.08c-2.61-0.16-5.18-1.18-8.87-2.11
                c6.04-3.95,10.61-7.55,15.7-10.12c6.3-3.17,13.06-5.43,19.58-8.19c5.88-2.49,11.7-5.12,17.54-7.69c0,0,0.02,0.02,0.02,0.02
                c-1.66,0.08-3.34,0.36-4.98,0.22c-18.18-1.57-34.14,4.02-49.66,13.28c-7.14,4.26-16.26,5.28-24.57,7.46
                c-1.5,0.39-3.38-0.71-5.09-1.12c-0.14-0.5-0.27-1-0.41-1.49c13.97-5.25,27.2-11.1,25.14-30.14c-7.72,2.21-15.42,4.15-22.94,6.64
                c-13.19,4.36-25.16,12.3-39.59,13.08c-5.79,0.31-11.53,1.51-17.3,2.28c-3.02,0.4-6.19,0.51-6.09,4.88c0.09,4.04,3.09,4.06,5.9,4.6
                c1.98,0.38,3.89,1.12,5.87,1.71c-2.5,6.47-2.04,7.8,4.26,9.15c9.25,1.98,18.57,3.92,27.95,5.03c33.98,4,67.93,8.93,102.04,11.02
                c26.96,1.65,54.14-0.35,81.23-0.74c8.62-0.12,17.25-0.37,25.87-0.38c16.51-0.02,33.21,2.64,49.13-4.58
                c3.4-1.54,4.34-3.09,2.25-5.8c-2.2-2.87-4.86-5.42-7.53-7.88c-2.57-2.36-5.42-4.41-8.15-6.59c0.41-0.53,0.83-1.06,1.24-1.59
                c4.66,3.77,9.23,7.67,14.03,11.25c1.69,1.26,3.95,2.45,5.95,2.44c9.64-0.04,19.27-0.57,28.91-0.79c11.21-0.25,22.25-1,32.41-6.58
                c1.97-1.08,4.45-1.56,6.73-1.7c12.6-0.78,25.22-1.28,37.83-2.06c3.08-0.19,6.12-1.06,9.18-1.62c-0.02-0.49-0.05-0.99-0.07-1.48
                c-1.23-0.78-2.46-1.56-3.69-2.34c-18.64-11.82-39.38-16.55-61.05-18.15l-0.22-0.15c0,0,0.24,0.13,0.24,0.13
                c5.39,3.26,10.78,6.52,16.17,9.79c-0.23,0.63-0.47,1.26-0.7,1.89c-1.54,0.2-3.12,0.73-4.61,0.54c-13-1.66-25.98-3.52-38.99-5.14
                c-4.41-0.55-8.88-0.57-13.32-0.84c-0.41,0.85-0.83,1.71-1.24,2.56c5.66,5.55,11.32,11.11,17.63,17.29
                c-2.6-0.18-4.82,0.17-6.63-0.54c-7.71-3.03-15.52-5.96-22.89-9.71c-11.12-5.65-22.66-6.19-35.51-4.66
                c6.35,7.95,12.2,15.27,18.06,22.6c-0.4,0.36-0.8,0.72-1.19,1.08c-9.59-4.33-19.97-6.78-28.21-14.22
                c-5.72-5.17-12.69-6.09-19.93-2.26c-5.29,2.8-10.71,5.34-16.16,7.81c-1.46,0.67-3.22,0.69-4.84,1.01c-0.3-0.4-0.6-0.8-0.9-1.19
                c2.29-3.85,4.58-7.7,6.37-10.72c-4.43,1.79-9.27,4.35-14.43,5.71c-9.78,2.57-19.73,4.46-29.63,6.58c-0.93,0.2-2.51,0.48-2.82,0.04
                c-2-2.87-4.36-1.46-6.61-0.77c-10.93,3.35-21.99,2.38-33.16,0.72c3.16-1.29,6.44-2.36,9.71-2.34c15.18,0.08,25.28-9.6,35.97-18.23
                L184.41,1039.83z M251.34,530.23l0.04-0.04c2.12,0.92,4.34,1.67,6.32,2.82c1.78,1.04,4.2,2.3,4.63,3.91
                c0.33,1.26-1.95,3.22-3.02,4.8c0.44,0,1.55,0.04,2.66-0.01c6.75-0.26,13.49-0.55,20.23-0.8c6.22-0.23,12.45-0.53,18.67-0.6
                c4.96-0.06,9.94,0.49,14.88,0.22c4.84-0.27,8.89,3.88,14.25,1.63c3.03-1.27,7.42-1.52,10.35-0.26c4.32,1.86,7.98,0.91,12.05,0.22
                c2.99-0.5,6.14-0.5,9.16-0.2c4.25,0.43,8.42,1.58,12.67,2.04c8.39,0.91,16.79,1.99,25.2,2.13c3.78,0.06,7.95,2.86,11.68-0.72
                c0.52-0.5,2.25-0.05,3.27,0.34c2.32,0.89,4.57,1.98,6.82,3.05c4.08,1.96,12.87,1.94,14.6-0.56c1.79-2.59,3.26-2.27,5.81-1.27
                c4.78,1.88,9.82,4.37,13.9-1.81c0.73-1.1,3.65-1.5,5.31-1.12c6.69,1.5,12.54,1.42,16.12-5.71c0.58-1.15,1.65-2.07,2.55-3.05
                c3.55-3.87,2.86-7.49-2-9.39c-5.58-2.18-6.63-8.68-11.37-11.67c-0.42-0.26-0.2-1.91,0.06-2.81c2.15-7.36,2.09-6.93-5.12-8.27
                c-3.22-0.6-8.36,0.32-8.97-5.04c-0.38-3.35-2.26-3.78-4.96-4.95c-5.26-2.29-11.97-2.66-14.24-9.99c-1.37-4.41-8.84-5.2-12.76-2.39
                c-1.41,1.01-3.56,1.42-5.36,1.38c-3.55-0.08-8.49-2.32-10.37-0.73c-4.66,3.93-10.39,3.94-15.15,6.37
                c-5.65,2.88-10.74,4.42-16.94,2.96c-2.79-0.66-5.9,0.13-8.79-0.24c-3.25-0.42-4.63-2.07-2.95-5.62c0.58-1.22,0.03-4.28-0.78-4.61
                c-3.24-1.33-6.78-1.95-10.23-2.78c-2.05-0.49-4.12-0.9-6.19-1.34c-2.05-0.43-4.09-1.1-6.16-1.23c-5.56-0.34-11.15-0.31-16.71-0.72
                c-1.36-0.1-2.62-1.33-3.95-2c-0.86-0.43-1.71-0.98-2.62-1.17c-3.31-0.7-6.64-1.34-9.99-1.86c-3.43-0.53-7.21-2-10.27-1.11
                c-8.33,2.43-15.39-0.79-22.24-4.16c-5.99-2.95-11.89-4.8-18.55-4.11c-4.27,0.44-8.55,0.74-13.98,1.2
                c1.46,3.62,2.89,7.17,4.44,11.02c3.8,0.35,5.53,2.19,2.83,6.23c5.09,2.22,5.29,3.61,1.37,6.77c-1.96,1.58-3.75,3.39-6.72,6.09
                c3.22,0.64,4.91,1.32,6.55,1.21c2.54-0.16,5.08-0.73,7.55-1.37c2.15-0.56,4.36-1.19,6.26-2.28c2.67-1.53,5-3.75,7.43,0.34
                c0.38,0.64,2.45,1.14,2.9,0.74c2.98-2.62,5.63-0.28,8.44,0.16c2.16,0.34,4.39,0.13,6.58,0.29c3.12,0.22,6.76-0.36,9.2,1.07
                c3.09,1.81,5.13,2.18,8.02,0.08c1.01-0.74,3.49-0.86,4.28-0.15c0.93,0.85,1.49,3.33,0.9,4.36c-1.26,2.23-3.04,4.47-5.17,5.84
                c-5.4,3.47-10.87,7.08-16.77,9.48c-5.67,2.31-7.37,9.14-15.08,9.9C264.82,521.43,258.18,526.88,251.34,530.23z M1044.62,958.91
                c-3.7,0.49-7.69,3.17-10.7-1.21c2.1-3.21,4.1-6.29,6.47-9.9c-4.5-0.46-7.6-0.77-10.7-1.09c-0.25-0.64-0.5-1.28-0.74-1.92
                c1.42-0.79,2.85-1.59,4.63-2.58c-3.06-2.46-4.54-4.76-1.01-7.21c-2.15-0.82-3.85-1.47-5.55-2.12c10.4-3.31,3.23-11.16,4.49-17.2
                c5.27,4.1,9.76,7.59,14.97,11.64c-0.11,3.82,3.63,6.17,7.35,8.16c2.97,1.59,3.36,3.31,1.75,5.96c-0.69,1.14-1.32,2.31-1.98,3.47
                l-0.02-0.02c3.48-0.43,6.96-0.87,10.78-1.35c0.94-30.18,2.16-61.09,2.78-92.01c0.66-32.78,0.76-65.57,1.1-98.35
                c0.01-1.16,0.2-2.39-0.08-3.48c-0.34-1.35-0.78-3.16-1.76-3.69c-0.75-0.4-2.41,0.89-3.66,1.43c-1.55,0.68-3.4,2.26-4.59,1.86
                c-2.96-1-4.29,0.44-6.45,1.99c-3.33,2.39-6.67,4.17-6.12,9.07c0.08,0.72-1.1,1.55-1.64,2.37c-1.11,1.66-2.39,3.24-3.21,5.04
                c-1.19,2.61-2.14,4.36-5.85,3.7c-3.81-0.67-8.01-0.46-11.78,0.43c-2.81,0.67-4.31,0.37-5.87-1.88c-0.86-1.24-2.14-2.91-3.37-3.05
                c-8.34-0.89-15.36-3.85-20.09-11.18c-0.64-1-2.38-2.37-3.02-2.11c-5.54,2.22-8.39-2.82-12.51-4.32c-1.32-0.48-2.65-0.94-3.97-1.41
                c-0.45,1.51-1.32,3.02-1.29,4.51c0.25,9.81,0.54,19.62,1.1,29.42c1.05,18.43,2.21,36.85,3.47,55.27
                c1.75,25.55,3.77,51.08,5.43,76.63c0.94,14.44,1.22,28.92,2.18,43.35c0.15,2.25,1.85,5.18,3.73,6.36
                c2.84,1.79,6.46,2.32,9.76,3.39l0.01-0.01c-2.63-2.53-5.27-5.07-7.9-7.6c0.27-0.47,0.54-0.93,0.8-1.4
                c1.7,0.15,3.48,0.03,5.09,0.49c15.06,4.33,29.55,10,42.79,18.54c5.98,3.86,5.98,3.86,7.51-3.07c-6.04-1.79-6.04-1.79-3.35-5.96
                l0.36-0.19l-0.34,0.22c-4.59-3.54-0.28-6.11,0.98-9.02L1044.62,958.91z M614.03,600.18c1.08,0.55,2.71,0.85,3.13,1.72
                c0.77,1.59,0.9,3.52,1.15,5.33c1.15,8.46,2.25,16.93,3.39,25.55c1.82-0.35,3.25-0.62,4.79-0.91c0.86,1.82,1.64,3.47,2.9,6.16
                c-1.63-19.31-0.09-37.09,4.61-54.65c-2.24,22.29-4.6,44.55-0.69,65.55c11.99-22.71,11.51-49.38,16.22-74.93
                c0.48,0.05,0.95,0.09,1.43,0.14c-0.17,3.76-0.34,7.52-0.49,10.98c0.6-0.64,1.28-1.38,2.82-3.04c0,7.69,0,14.22,0,20.97
                c3.22-3.07,6.03-5.76,9.9-9.45c3.5,17.13,6.79,33.25,9.97,48.87c2.62-0.39,4.94-0.74,7.92-1.19c2.08-5,5.05-11.17,7.23-17.6
                c2.09-6.16,3.36-12.59,4.99-18.9c0.59,0.04,1.18,0.07,1.77,0.11c2.14,14.81,4.28,29.62,6.51,45.04c1.21-4.38,2.22-8.04,3.23-11.7
                c0.76-0.08,1.52-0.15,2.28-0.23c2.84,7.08,5.67,14.16,8.21,20.48c0.12-1.57,0.04-4.38,0.63-7.03c0.54-2.47,1.79-4.79,2.73-7.17
                c0.64,0.12,1.27,0.24,1.91,0.36c5.56,16.34,6.16,33.64,8.92,51.09c0.55-16.45,2.64-31.94,11.22-45.99
                c5.75,12.68,3.18,26.59,6.66,39.18c1.4-2.95,2.85-5.99,4.31-9.03c0.51,0,1.02,0,1.53,0c1.09,4.38,2.17,8.75,3.2,12.87
                c0.36-4.08,0.77-8.38,1.12-12.69c1.43-17.23,2.57-34.49,4.41-51.68c0.65-6.03,2.84-11.93,4.65-17.79c0.46-1.49,2.17-2.6,3.3-3.88
                c1.01,1.41,2.3,2.69,2.95,4.25c0.76,1.81,1.17,3.84,1.36,5.81c1.66,16.72,3.23,33.44,4.32,44.74c1.1-7.45,2.9-20.28,4.94-33.08
                c0.49-3.07,0.06-7.79,4.35-7.65c1.58,0.05,3.97,4.89,4.35,7.75c2.43,18.5,4.41,37.05,6.54,55.61c0.79-6.51,1.49-13.1,2.41-19.66
                c3.18-22.71,6.37-45.42,9.7-68.12c1.03-7-4.3-12.93-11.02-13.96c-6.42-0.99-13.12-1.21-18.98-4.87c-1.56-0.98-3.86-0.73-5.78-1.18
                c-8.67-2.03-17.78-2.89-25.04-8.88c-5.34-4.4-11.99-4.46-18.3-5.53c-6.12-1.03-12.32-1.59-18.46-2.47
                c-3.74-0.54-7.45-1.27-11.17-1.97c-3.55-0.67-7.16-1.18-10.61-2.18c-4.62-1.34-9.11-3.11-13.67-4.66
                c-4.79-1.63-10.08-2.43-14.27-5.02c-8.74-5.4-17.42-10.12-27.99-10.95c-3.53-0.28-6.85-2.9-10.31-4.35
                c-3.93-1.65-7.93-3.13-11.86-4.78c-3.54-1.49-7.62-2.42-10.43-4.81c-7.18-6.11-14.37-11.69-24.05-13.29
                c-3.54-0.59-7.66-1.49-10.18-3.74c-6.99-6.24-15.49-6.45-23.86-7.23c-6.04-0.57-7.1,0.74-7.79,6.81c-0.4,3.55-0.32,6.01,3.92,7.93
                c5.17,2.33,9.29,6.92,14.39,9.48c5.95,2.99,12.44,4.97,18.77,7.15c2.61,0.9,5.4,1.36,8.15,1.74c7.02,0.96,14.08,1.68,21.1,2.69
                c1.42,0.21,2.72,1.28,4.07,1.96c-0.1,0.21-0.21,0.41-0.31,0.62c-9.27-1.04-18.54-2.08-28.97-3.25c1.52,2.31,1.99,3.02,3.06,4.63
                c-3.59-0.93-6.1-1.69-8.66-2.23c-10.96-2.3-21.99-4.27-32.88-6.87c-7-1.68-13.61-3.35-21.04-1.3c-3.68,1.02-8.33-0.54-12.31-1.73
                c-4.69-1.39-9.15-3.59-13.66-5.54c-2.07-0.9-4.79-1.52-5.91-3.16c-3.23-4.72-8.17-5.52-12.99-6.46c-1.29-0.25-3.65,1.03-4.23,2.23
                c-0.47,0.98,0.67,3.17,1.68,4.3c1.38,1.55,3.43,2.48,4.98,3.9c1.38,1.27,2.21,3.32,3.75,4.21c5.36,3.09,6.19,9.68,1.71,13.93
                c-1.65,1.56-2.53,3.92-4.1,5.59c-1.41,1.5-3.11,3.06-4.98,3.71c-3.03,1.05-6.32,1.38-10.23,2.17c1.27,12.98,2.51,25.66,3.76,38.38
                c2.49-2.62,4.55-4.77,7.6-7.97c2.18,7.89,4.01,14.51,5.9,21.37c1.09-5.09,1.97-9.99,3.24-14.79c0.43-1.63,2.02-4.41,2.71-4.3
                c5.33,0.85,4.59-3.54,6-6.19c0.91-1.72,2.4-3.13,3.63-4.68c1.28,1.39,3.04,2.56,3.75,4.2c2.91,6.78,5.51,13.69,8.53,21.36
                c0.59-4.37,0.62-7.92,1.63-11.18c1.3-4.18,2.71-8.65,5.33-12.03c3.19-4.12,7.95-3.05,10.61,1.5c0.56,0.96,1.09,1.94,1.69,3.01
                c1.28-1.07,2.33-1.94,4-3.34c3.13,15.59,6.12,30.47,9.17,45.66c1.65-5.58,3.16-10.65,4.66-15.72c0.55,0.05,1.1,0.1,1.65,0.15
                c0.58,2.15,1.28,4.27,1.7,6.45c0.4,2.05,0.5,4.16,0.73,6.24c2.14-16.61,3.79-33.22,6.9-49.58c0.65-0.01,1.3-0.02,1.95-0.03
                c1.32,2.64,2.64,5.28,4.25,8.47c1.59-2.6,2.55-4.15,3.5-5.71c0.71,0.12,1.41,0.24,2.12,0.36c0.25,8.18,0.49,16.36,0.74,24.54
                l-0.04-0.04c1.97,1.22,3.94,2.45,5.78,3.59c1.4-10.99,1.86-22.91,5.71-34.2c3.23,3.36,5.88,6.12,8.52,8.87
                c2.87-1.69,3.49-1.7,4.09,1.59c1.29,7.07,2.08,14.23,3.01,20.93c0.05-0.18,0.31-0.93,0.48-1.7c1.4-6.31,2.56-12.69,4.29-18.91
                c0.55-1.99,2.56-3.59,3.9-5.36c1.25,1.57,3.15,2.97,3.65,4.75c2.39,8.42,4.39,16.96,6.53,25.45l-0.18,0.38
                C613.83,600.54,614.03,600.18,614.03,600.18z M251.37,280.42c-4.85-1.05-8.24-6.47-14.07-4.63c-4.17,1.32-7.96,0.71-11.77-1.71
                c-1.12-0.71-3.73,0.29-5.35,1.1c-3.32,1.67-6.22,4.29-10.4,1.9c-1.12-0.64-3.02-0.16-4.51,0.12c-1.89,0.35-3.76,1.53-5.59,1.42
                c-4.93-0.3-8.56,1.44-11.66,5.26c-2.54,3.13-5.45,4.37-9.38,1.15c-1.53-1.25-4.68-0.48-7.09-0.68c-2.14-0.18-4.26-0.51-6.4-0.62
                c-4.41-0.23-8.84-0.49-13.25-0.4c-0.74,0.01-1.48,1.69-2.18,2.64c-1.11,1.51-2.03,4.17-3.32,4.35c-3.38,0.48-7.42,2.03-9.95-2.11
                c-3.96-6.49-14.75-9.3-21.22-5.47c-5.17,3.06-8.69,8.61-15.23,9.69c-1.58,0.26-3.01,1.86-4.38,3
                c-5.74,4.82-11.16,10.01-19.89,7.37c-1.6-0.49-4.16,1.8-6.14,3.03c-2.2,1.36-4.04,3.68-6.39,4.36
                c-6.75,1.94-13.69,3.23-20.54,4.85c-0.94,0.22-1.81,0.82-2.64,1.36c-5.3,3.43-10.58,6.9-16.25,10.6c0.56,1.38,1.22,3.01,1.97,4.87
                c-2.71,2.62-4.92,5.4-1.35,9.24c0.55,0.59-0.48,2.68-0.82,4.06c-0.47,1.94-0.98,3.87-1.47,5.81c2.25,0.42,4.52,1.2,6.76,1.15
                c2.97-0.06,5.79-1.2,7.1,3.14c0.4,1.33,3.4,2.28,5.35,2.66c4.88,0.96,10.05,0.86,14.68,2.44c2.57,0.88,4.03,1.03,6.2-0.39
                c2.76-1.8,5.55-2.43,7.35,1.57c0.4,0.88,1.76,2.03,2.52,1.93c3.49-0.49,7.57-0.46,10.25-2.32c5.67-3.95,10.47-2.42,15.84,0.11
                c2.06,0.97,5.26,1.75,6.98,0.82c6.3-3.39,13.43-4.61,19.67-3.28c8.16,1.74,13.35-2.68,20.38-5.09c-3.22-3.84-3.03-9.06-8.66-10.36
                c-3.93-0.9-8.01-2.42-11.2-4.77c-2.21-1.63-3.49-5.05-4.19-7.91c-0.26-1.04,2.37-3.99,3.66-3.97c2.78,0.03,5.54,1.41,8.31,2.25
                c3.4,1.03,6.85,1.95,10.18,3.18c2.18,0.8,4.64,1.61,6.18,3.19c2.04,2.08,3.38,4.02,6.56,1.73c0.86-0.62,2.91-0.28,4.06,0.31
                c5.16,2.66,9.86,2.25,14.12-1.81c1.2-1.15,3.06-3.15,2.73-4.11c-2.07-6.03,0.28-10.75,4.08-14.87c2.17-2.35,5.02-5.37,7.78-5.62
                c7.86-0.69,15.85-0.27,23.76,0.15c1.03,0.05,3.04,3.49,2.72,3.91c-3.31,4.27-6.98,8.26-10.64,12.24
                c-0.29,0.31-1.43-0.26-1.89,0.02c-1.35,0.84-2.95,1.69-3.64,2.97c-0.36,0.67,0.8,2.46,1.66,3.38c1.17,1.26,2.71,2.18,4.08,3.24
                c-4.07,5.46-4.73,8.74-0.15,11.32c7.05,3.98,7.58,14.74,17.22,15.57c0.87,0.07,1.73,1.89,2.33,3.04c1.68,3.17,4.14,4.37,7.76,4.2
                c2.39-0.12,5.44-0.07,7.11,1.26c3.91,3.11,7.57,5.26,12.58,1.61c1.32,6.61,5.62,5.25,9.13,4.34c3.09-0.8,5.58-1.49,8.96-0.22
                c2.34,0.88,5.67-1.12,8.59-1.44c1.42-0.15,3.82,0.18,4.24,1.05c1.56,3.2,2.75,3.68,6.01,1.59c2.19-1.4,5.58-1,8.45-1.22
                c1.27-0.1,2.82,0.8,3.83,0.34c7.88-3.58,13.15,1.66,18.28,5.8c3.81,3.08,6.53,3.93,10.79,1.28c1.66-1.03,3.94-1.56,5.91-1.49
                c3.23,0.12,6.48,1.53,9.62,1.25c3.43-0.31,4.82,0.32,6.27,3.69c0.85,1.97,4.57,3.54,7.1,3.7c2.52,0.16,5.29-1.1,5.76-4.92
                c0.84-6.9,2.76-7.58,8.58-3.54c3.43,2.38,6.71,4.26,10.85,1.05c0.98-0.76,3.32-0.5,4.68,0.1c5.44,2.4,10.39,1.82,15.54-1.04
                c1.47-0.82,4.51-0.58,5.86,0.45c5.17,3.92,10.52,3.88,16.36,2.22c1.55-0.44,3.63-0.34,5.04,0.36c4.16,2.06,11.96,0.42,14.35-3.53
                c0.57-0.94,0.41-2.32,0.59-3.5c-1.19-0.18-2.72-0.91-3.52-0.43c-3.36,2.02-5.56,0.23-8.04-1.68c-4.07-3.13-8.33-6.01-12.44-9.08
                c-1.85-1.38-3.49-3.01-5.32-4.42c-2.43-1.88-4.75-4.04-7.47-5.36c-4.07-1.98-9.32-0.9-12.64-5.38c-0.95-1.28-4.05-1.16-6.19-1.33
                c-5.25-0.42-10.56-0.36-15.77-1.1c-1.91-0.27-4.61-1.9-5.11-3.49c-0.86-2.69-2.14-6.34,2.02-8.18c3.15-1.39,3.19-3.95,1.34-6.06
                c-1.44-1.63-3.75-2.65-5.85-3.51c-3.33-1.37-7.05-1.98-10.08-3.78c-1.03-0.61-0.21-3.93-0.76-5.87c-0.37-1.29-1.5-2.53-2.59-3.42
                c-2.42-1.99-6.04-3.2-7.39-5.69c-5.96-11-19.16-10.48-27.38-17.81c-1.49-1.33-4.81-0.41-7.15-1.02
                c-6.24-1.62-12.39-3.54-18.6-5.29c-3.16-0.89-6.32-1.88-9.54-2.46c-5.15-0.93-10.37-1.44-15.52-2.35c-5-0.88-9.93-3.22-15.03-0.23
                c-0.26,0.15-1.41-1.22-2.14-1.89c9.73-4.2,9.98-4.26,7.69-14.76c-1.73-7.89-3.7-15.82-6.61-23.32c-1.1-2.83-4.88-5.45-7.96-6.34
                c-1.62-0.47-5.59,2.58-6.41,4.8c-3.27,8.84-5.81,17.97-8.45,27.04c-1.5,5.16-0.61,6,4.92,5.34
                C242.67,272.17,248.68,274.76,251.37,280.42z M714.94,504.26c-1.59,2.36-3.19,4.72-4.78,7.08c-0.47-0.12-0.95-0.24-1.42-0.37
                c-0.44-2.36-0.37-5-1.47-6.99c-1.13-2.02-3.42-3.4-6.2-6c-2.56,13.96-4.83,26.26-7.09,38.57c-0.63-0.07-1.27-0.13-1.9-0.2
                c-0.96-9.58-1.93-19.15-2.89-28.73c-0.5,0.01-0.99,0.02-1.49,0.03c-1.96,8.27-3.92,16.54-5.88,24.8
                c-1.53-1.12-2.08-2.11-2.22-3.16c-1.7-13.18-3.59-26.33-4.91-39.55c-0.74-7.45-1.38-14.41-9.51-17.95
                c-2.13-0.93-3.73-3.26-5.33-5.14c-0.7-0.82-0.79-2.14-1.16-3.23c2.86,0.55,4.68,1.9,6.63,3.03c3.15,1.82,6.24,3.91,9.62,5.11
                c4.92,1.75,10.22,2.48,15.13,4.27c5.92,2.16,11.46,5.42,17.44,7.37c5.95,1.94,12.23,2.84,19.19,4.38
                c-6.56-6.6-12.07-12.15-17.23-17.34c-0.53,2.78-1.21,6.29-1.88,9.8c-0.74-0.02-1.48-0.04-2.22-0.06
                c-0.9-4.59-1.79-9.18-2.69-13.77c-0.34,0.06-0.67,0.13-1.01,0.19c-0.34,3.3-0.68,6.59-1.03,9.89c-0.63,0.11-1.25,0.22-1.88,0.33
                c-2.45-7.04-4.9-14.09-7.28-20.92c-0.58,0.98-1.36,2.31-2.38,4.05c-4.47-5.58-0.61-13.79-7.33-18.9
                c-0.99,3.38-1.76,6.03-2.53,8.67c-0.56,0.05-1.13,0.1-1.69,0.15c-1.56-5.11-4.39-10.23-4.35-15.34c0.05-6.37-3.48-10.29-6.5-16.07
                c-0.67,5.88-1.2,10.62-1.74,15.36c-0.65,0.02-1.31,0.05-1.96,0.07c-1.34-8.14-2.68-16.28-4.03-24.42l0.2-0.32
                c0,0-0.15,0.36-0.15,0.36c-0.95,2.32-2.55,4.58-2.74,6.96c-1.2,14.76-2.01,29.54-3.12,44.3c-0.22,2.95-1.19,5.85-1.82,8.77
                c-0.82-0.07-1.63-0.14-2.45-0.22c0-18.35,0-36.7,0-55.05c-0.44,0.02-0.87,0.03-1.31,0.05c-0.87,2.09-1.73,4.18-3.13,7.54
                c-0.93-2.95-1.36-4.33-1.8-5.71c-0.25,0.03-0.51,0.06-0.76,0.09c-0.74,14.01-1.49,28.03-2.23,42.04c-0.65,0.01-1.3,0.01-1.94,0.02
                c-0.3-1.36-0.81-2.72-0.88-4.09c-0.71-14.46-1.45-28.91-1.98-43.38c-0.31-8.55-0.14-8.56-8.42-11.43
                c-0.94-0.33-1.88-0.67-2.85-0.93c-17.78-4.75-32.21-14.8-44.62-28.08c-0.82-0.88-1.84-1.56-2.65-2.23
                c-1.64,19.59-3.26,38.86-4.87,58.13c-0.78-0.01-1.56-0.01-2.34-0.02c-0.84-14.45,0.14-29.12-4.51-43.08
                c-0.61,5.57-1.22,11.15-1.83,16.72c-0.43,0-0.86,0-1.29,0.01c-1.02-3.49-2.04-6.97-3.06-10.46c-0.51,0.08-1.02,0.16-1.52,0.24
                c0,15.93,0,31.86,0,47.79c-0.76,0.05-1.53,0.1-2.29,0.14c-1.69-17.48-3.38-34.96-5.07-52.45c-0.73,0.04-1.46,0.07-2.19,0.11
                c0,10.06,0,20.12,0,30.18c-0.7,0-1.41,0-2.11,0c0-4.97,0-9.93,0-14.9c-0.62,0.01-1.24,0.02-1.85,0.02c0,5.09,0,10.18,0,15.27
                c-0.45,0.01-0.89,0.02-1.34,0.03c-0.47-1.48-0.93-2.97-1.42-4.5c-1.16,10.27,1.25,20.71-1.66,30.7c-0.78,0.03-1.57,0.05-2.35,0.08
                c-2.62-22.64-5.25-45.27-7.87-67.91c-0.82,0.05-1.64,0.1-2.46,0.15c0,12.27,0,24.53,0,36.8c-0.68,0.02-1.36,0.04-2.04,0.07
                c-2.39-18.94-4.78-37.88-7.26-57.52c-1.7,6.73-3.18,12.59-4.66,18.46c-1.07-1.17-1.5-2.47-1.54-3.78
                c-0.32-10.97-0.44-21.96-0.96-32.92c-0.12-2.41-0.95-5.49-2.61-6.98c-6.03-5.4-12.57-10.23-18.89-15.32
                c-2.2-1.77-4.41-3.55-6.41-5.53c-3.54-3.51-5.06-3.67-7.64,0.67c-2.96,4.98-5.83,10.15-7.8,15.57
                c-5.63,15.48-11.09,31.03-15.98,46.75c-1.65,5.32-2.56,11.43-1.69,16.84c0.92,5.7-0.24,8.71-5.53,10.11
                c-2.98,0.79-6.06,1.18-9.12,1.68c-2.93,0.47-5.93,0.6-8.79,1.33c-2.59,0.66-3.83,2.95-1.7,4.86c3.75,3.36,7.8,6.56,12.19,9.01
                c6.69,3.74,13.67,7.02,20.73,10.02c3.62,1.54,7.65,2.2,11.55,2.95c2.83,0.54,6.29-0.33,8.53,1.01
                c6.25,3.71,12.04,8.23,17.96,12.49c0.47,0.34,0.45,1.29,0.88,1.75c0.62,0.66,1.4,1.53,2.19,1.62c7.47,0.89,9.95,8.42,15.68,11.64
                c2.52,1.42,4.76,3.37,7.04,5.19c5.74,4.58,14.36,5.21,17.42,13.69c1.15,3.19,1.83,5.73-0.22,8.24c-2.02,2.47-4.63,3.31-8.03,2
                c-6.05-2.33-12.27-4.23-18.44-6.22c-1.06-0.34-2.29-0.1-3.42-0.28c-1.44-0.23-3.46-0.13-4.17-1.02c-2.57-3.2-6.05-2.71-9.4-3.08
                c-6.46-0.71-8.15,0.85-9.17,7.27c-0.28,1.75-2.37,4.37-3.89,4.57c-6.33,0.81-13.05,2.03-17.77-4.36
                c-0.44-0.59-1.38-0.87-2.14-1.17c-4.11-1.62-8.33-3-12.32-4.86c-4.1-1.92-10.17-0.56-12.02,3.09c-1.4,2.78,1.45,7.12,5.83,8.83
                c2.91,1.13,7.47,1.43,8.47,3.48c3.53,7.22,9.97,8.04,16.26,10.82c3.93,1.74,8.02,3.78,12.21,5.37c2.17,0.82,5.08,1.38,6.17,3.03
                c3.38,5.15,9.71,4.2,13.55,7.04c9.32,6.87,19.01,5.95,29.2,4.43c2.18-0.32,4.45-0.05,6.81-0.05c-0.5-6.94-0.98-13.37-1.42-19.8
                c-0.35-5.09,2.17-8.09,7.13-8.33c8.5-0.41,16.83,0.49,24.64,4.4c4.85,2.43,9.64,5.28,14.8,6.69c7.38,2.02,13.97,4.85,19.7,10.09
                c3.81,3.48,7.44,7.21,13.43,7.95c4.7,0.58,9.11,3.49,13.67,5.33c3.17,1.28,6.28,3.08,9.59,3.61c7.28,1.17,14.41,2.34,20.63,6.77
                c6.77,4.83,13.61,9.51,22.5,9.77c2.55,0.08,5.01,1.75,7.59,2.37c10.07,2.43,20.16,4.79,30.27,7.04c3.54,0.79,7.23,1.88,10.75,1.59
                c9.63-0.79,17.9,1.86,25.42,7.79c1.99,1.57,4.75,2.35,7.29,2.99c13.62,3.41,27.35,6.44,40.89,10.14c3.26,0.89,7.32,3.2,8.64,5.98
                c3.92,8.28,12.14,8.87,19.25,13.36c0.17-2.7,0.52-4.33,0.32-5.89c-1.09-8.73-2.75-17.41-3.37-26.17
                c-0.69-9.73-1.17-19.58-0.38-29.26c0.4-4.85-0.71-7.02-5.3-8.97c-0.83,4.34-1.57,8.25-2.53,13.3c-1.6-2.74-2.59-4.43-3.8-6.52
                c-1.28,1.52-2.07,2.47-3.1,3.7c-0.88-1.12-1.51-1.94-2.23-2.85c-0.76,2.25-1.45,4.31-2.14,6.37c-0.43-0.01-0.86-0.02-1.29-0.03
                c-0.54-2.46-1.08-4.91-1.32-5.97c-1.85,9.17-3.94,19.52-6.03,29.86c-0.73-0.06-1.46-0.11-2.19-0.17
                c0.21-16.94,1.4-33.98-4.24-50.36c-1.87,0.66-3.31,1.41-4.82,1.65c-4.01,0.63-6.63,2.47-7.09,6.79
                c-1.13,10.71-2.31,21.42-3.51,32.13c-0.23,2.1-0.6,4.19-0.91,6.29c-0.35-0.05-0.7-0.09-1.04-0.14
                c-1.27-10.83-2.54-21.67-3.92-33.48c-1.83,1.59-2.5,2.16-3.86,3.34c-1.35-5.34,0.9-11.47-6.03-15.58
                c-1.54,9.73-2.96,18.77-4.39,27.81c-1.73-1.94-2.72-3.9-2.82-5.89c-0.49-9.69-1.29-19.29-5.25-28.33l0.16-0.24l-0.12,0.27
                c-2.58,8.7-5.17,17.39-7.75,26.09c-2.56-8.61-0.04-18.26-6-25.54c-2.57,1.14-4.96,2.19-7.34,3.25c-0.38-0.37-0.77-0.74-1.15-1.12
                c-1.19,5.42-2.38,10.84-3.57,16.26c-0.31-0.04-0.63-0.09-0.94-0.13c-0.3-1-0.6-2.01-0.77-2.58c-1.85,6.46-0.81,14.18-6.49,19.96
                c0-14.41,0-28.83,0-43.24L714.94,504.26z M856.82,154.46c-1.47,1.99-3.04,3.92-4.39,5.99c-1.16,1.77-2.87,3.68-2.95,5.58
                c-0.18,4.63,0.21,9.32,0.8,13.94c0.68,5.28,1.84,10.5,2.79,15.74c3.73-7.34,6.36-14.51,8.75-21.75c0.48-1.46,0.42-3.34-0.03-4.83
                c-1.51-4.92-3.32-9.75-5.02-14.62c-4.48-15.1-9.82-29.81-21.2-41.3c-8.08-8.15-14.47-17.4-19.18-27.79
                c-3-6.62-5.51-13.47-7.82-19.17c-3.12-0.68-5.45-1.31-7.83-1.68c-1.73-0.27-3.63-0.65-5.24-0.2c-3.45,0.95-6.92,2.1-10.09,3.73
                c-2.67,1.37-5.51,3.13-7.21,5.48c-2.01,2.79-4.38,4.09-7.14,2.88c-5.05-2.22-9.78-5.17-14.69-7.73c-1.21-0.63-2.99-1.67-3.84-1.23
                c-4.6,2.36-8.11-0.09-11.55-2.34c-2.49-1.63-4.47-1.93-7.29-0.44c-4.8,2.53-9.61,0.93-12.62-3.52c-3.74-5.52-3.56-5.27-9.37-1.24
                c-1.79,1.24-5.31,1.7-7.13,0.76c-2.18-1.13-3.69-3.99-4.93-6.38c-1.38-2.69-2.54-4.53-5.38-1.66c-3.93,3.98-6.22,4.12-10.18-0.02
                c-2.62-2.75-5.04-5.22-9.35-4.53c-0.93,0.15-2.67-0.97-3.08-1.92c-1.75-4.04-4.23-3.57-7.65-2.19c-5.66,2.29-9.46,0.14-11.45-5.57
                c-0.39-1.12-1.8-2.31-2.95-2.6c-0.86-0.22-2.15,0.85-3.12,1.53c-1.53,1.07-3.05,3.4-4.4,3.28c-5.19-0.46-10.33-1.6-15.44-2.68
                c-2.06-0.44-4.11-1.21-6-2.16c-2.67-1.33-6.15-2.36-7.56-4.61c-2.41-3.82-4.35-6.48-9.32-4.43c-0.92,0.38-2.4-0.75-3.66-1
                c-2.48-0.49-5.06-1.48-7.45-1.15c-4.91,0.67-9.24,0.28-12.94-3.37c-3.19-3.15-6.14-6.08-3.26-11.23c0.52-0.94-1.84-3.49-2.86-5.3
                c-1.1,0.95-2.12,2.04-3.33,2.83c-2.26,1.48-4.5,3.6-6.99,4c-5.81,0.95-11.85,0.54-17.61,1.62c-6.02,1.13-10.49-1.11-13.26-5.66
                c-2.8-4.61-6.26-5.62-11.15-5.61C475.97,2,430.68,1.96,385.4,1.96c-1.79,0-3.57,0-5.59,0c-0.07,3.56-0.71,6.67,4.23,4.98
                c0.96-0.33,3.17,0.24,3.31,0.77c0.58,2.32,1.22,4.91,0.72,7.16c-1.23,5.53-0.5,9.29,4.1,11.9c3.41,1.93,4.76,4.38,4.33,8.05
                c-0.27,2.23-0.69,4.45-1.05,6.72c2.82,2.3,5.71,4.66,8.72,7.11c-1.43,1.85-2.39,3.07-3.92,5.05c4.69,2.34,9.71,3.63,12.99,6.75
                c5.14,4.89,10.42,3.58,15.65,1.97c7.05-2.17,12.42-0.63,16.58,5.32c1.46,2.08,2.9,2.82,5.42,1.48c4.58-2.44,9.21-3.77,13.6,0.77
                c0.91,0.94,2.86,0.76,4.12,1.47c3.24,1.81,7.51,3.15,9.2,5.97c1.61,2.69,2.66,5.24,5.56,6.94c3.18,1.86,6.23,4.08,8.89,6.62
                c1.57,1.5,3.01,3.85,3.19,5.93c0.44,4.93,2.96,8.62,7.72,9.77c7.26,1.74,10.03,7.54,6.18,13.94c-2.13,3.54-1.5,4.87,2.31,5.88
                c3.43,0.91,7.25,3.7,5.33,6.96c-3.41,5.81,1.26,13.92-5.8,18.3c-0.38,0.24-0.44,1.23-0.4,1.86c0.27,4.91,8.68,12.55,13.68,13.24
                c2.17,0.3,4.87,1.82,5.93,3.61c0.84,1.42-0.12,4.22-0.85,6.2c-1.54,4.17-3.88,8.08-5.1,12.32c-1.18,4.12,0.76,9.31-4.61,11.89
                c-0.43,0.21-0.29,1.56-0.45,2.37c-0.99,5.23-4.24,6.86-9.07,4.57c-4.13-1.96-8.96-0.37-11.57,3.19c-1.4,1.91-3.72,3.14-5.63,4.68
                c-1.33-1.89-2.67-3.77-3.96-5.7c-0.26-0.39-0.14-1.11-0.45-1.4c-1.33-1.26-2.75-2.41-4.14-3.6c-0.76,1.49-2.01,2.92-2.2,4.47
                c-0.76,6.25-1.65,7.29-7.93,7.94c-1.32,0.14-2.81-0.05-3.93,0.5c-4.3,2.09-6.54-0.05-8.27-3.62c-0.56-1.15-1.22-2.25-1.84-3.39
                c-2.96,2.72-5.68,5.23-8.17,7.52c1.57,2.63,4.24,5.07,3.82,6.74c-1.71,6.89,1.87,8.63,7.43,9.45c1.18,0.17,2.13,1.57,3.32,2.06
                c1.88,0.77,4.01,1.01,5.8,1.92c3.25,1.66,8.85,3.45,9.02,5.53c0.54,6.83,5.02,5.19,8.81,5.84c2.42,0.42,4.79,1.25,7.12,2.05
                c1.99,0.68,4.91,2.8,5.67,2.18c3.1-2.58,5.64-1.03,8.52-0.12c2.93,0.93,5.97,1.52,9.14,2.3c0.41-1,0.62-1.79,1.03-2.47
                c2.55-4.17,9.68-6.53,12.12-3.61c2.97,3.57,6.24,4.66,10.76,4.53c3.2-0.09,4.79-0.26,4.15-3.96c-0.56-3.25,0.92-4.34,4.33-4.14
                c1.91,0.11,4.52-0.89,5.76-2.31c2.91-3.35,4.63-2.69,6.92,0.57c4.17,5.91,9.08,5.31,12.18-0.93c0.74-1.49,3.52-3.36,4.71-2.98
                c3.66,1.16,7.25,1.87,11.02,2.52c3.81,0.65,5.98,0.88,8.43-3.66c1.27,1.85,1.92,3.25,2.97,4.24c3.64,3.41,6.99,2.36,8.43-2.32
                c0.31-1.01,1.48-1.97,2.49-2.52c1.86-1.02,3.9-1.72,5.56-2.43c2.72,1.22,5.41,2.42,8.1,3.63c0.44-0.41,0.87-0.81,1.31-1.22
                c-1.28-3.8-1.48-9.12-4.12-11.01c-3.29-2.35-6.68-3.86-7.94-8.07c-0.1-0.34-1.53-0.3-2.35-0.42c-5.7-0.79-9.89-4.83-9.77-9.4
                c1.75-1.36,2.46-3.66,3.57-5.75c1.65-3.09,3.43-2.55,5.13,0.12c0.96,1.51,2.08,2.91,3.26,4.55c3.81-4.66,3.8-4.63,9.27-3.56
                c0.95,0.19,2,0.28,2.94,0.08c7.94-1.64,13.19,1,18.06,7.75c1.8,2.5,5.43,3.71,8.29,5.39c1.16,0.68,3.41,0.85,3.59,1.63
                c1.19,4.99,4.85,4.08,7.94,3.26c4.73-1.26,8.42-0.72,11.32,3.6c1.27,1.9,3,3.6,4.83,4.97c0.92,0.69,3.26,1.04,3.72,0.49
                c2.38-2.8,4.75-1.26,7.28-0.62c3.09,0.78,6.25,1.28,9.38,1.93c6.67,1.38,14.14,0.94,17.81,8.81c0.59,1.27,3.51,2.02,5.35,2.02
                c5.81,0.02,11.01,1.13,15.76,4.84c1.9,1.49,5,2.55,7.29,2.19c2.91-0.46,5.57-2.56,7.7-3.64c0.88-2.88,1.75-5.73,2.99-9.75
                c-7.48-4.86-15.26-12.65-24.54-15.43c-25.62-7.67-39.13-26.39-49.22-48.88c-1.8-4.02-3.52-6.12-7.87-5.55
                c-8.71,1.14-15.94-1.83-22.2-7.8c-1.39-1.33-3.5-3.16-4.99-2.92c-7.49,1.19-12.23-4.37-18.17-6.84c-2.38-0.99-4.48-2.88-6.38-4.7
                c-2.52-2.41-2.43-4.97,0.58-7.11c0.91-0.64,1.84-1.24,2.15-1.45c-3.99-5.03-7.71-9.96-11.75-14.61c-0.83-0.96-3.37-1.68-4.3-1.12
                c-8.07,4.84-13.22-0.09-18.17-5.26c-4.21-4.4-3.28-8.57,1.71-12.07c2.64-1.85,6.26-4.24,6.74-6.88
                c1.26-6.95,5.1-11.27,10.63-14.87c0.46-0.3,0.06-3.21-0.78-3.9c-1.15-0.94-3.17-0.74-4.69-1.35c-5-2-10.16-3.75-14.84-6.35
                c-3.4-1.89-2.95-5,0.19-7.22c1.45-1.02,3.25-2.04,4.93-2.13c4.17-0.22,8.39-0.08,12.56,0.25c6.26,0.49,13.63-3.15,18.08,4.67
                c0.3,0.52,1.82,0.49,2.76,0.46c1.66-0.04,3.36-0.6,4.96-0.37c5.86,0.85,12.81-1.35,16.45,6.14c0.57,1.18,4.19,2.02,5.7,1.37
                c4-1.72,7.02-0.59,10.19,1.64c3.74,2.63,7.43,3.86,12.4,2.31c2.7-0.84,6.38,1.35,9.59,2.27c1.36,0.39,2.66,1.53,3.95,1.47
                c4.77-0.23,9.56-0.63,14.29-1.35c3.9-0.59,6.17,0.84,8.94,3.69c3.53,3.64,8.25,7.93,12.77,8.32c4.92,0.42,9.65,0.84,12.71,4.12
                c4.86,5.19,9.18,3.09,14.25,1.13c4.93-1.91,9.89-5.25,15.59-1.66c0.7,0.44,2.35-0.55,3.52-0.97c4.34-1.58,10.18,1.5,10.63,6.1
                c0.44,4.52,2.53,4.71,6.07,3.51c9.09-3.08,13.92-0.71,17.62,8.2c0.66,1.6,1.68,3.44,3.07,4.27c5.28,3.16,10.76,5.97,16.2,8.84
                c0.68,0.36,1.66,0.5,2.41,0.34c3.26-0.69,5.51,0.91,6.18,3.68c1.71,7.01,6.3,12.26,10.17,17.97
                C853.93,150.62,855.41,152.52,856.82,154.46z M503.18,271.1c-1.66,4.21,1.23,6.44,4.34,7.43c3.81,1.22,8.51,0.3,11.88,2.08
                c6.73,3.56,11.63,0.9,16.74-2.81c1.21-0.88,2.51-1.64,3.82-2.37c3.58-2,5.94-1.05,7.27,2.89c1.31,3.88,8.9,7.63,11.49,5.02
                c3.69-3.72,6.48-1.93,10-0.67c1.56,0.56,3.65,0.3,5.31-0.21c3.48-1.06,5.69,0.07,6.62,1.71c-0.68,3.2-2.04,5.97-1.3,6.78
                c2.49,2.68,6.62,7.11,8.54,6.43c4.67-1.67,7.49,0.79,11.13,1.83c1.88,0.54,4.54-0.05,6.32-1.06c5.3-2.99,8.04-2.47,11.99,2.27
                c2.19,2.63,4.81,5.07,7.66,6.93c2.34,1.53,5.25,2.39,8.02,2.97c3.65,0.77,7.71,4.39,10.97-1c0.37-0.62,2.29-0.94,3.08-0.55
                c3.09,1.51,6.33,2.99,8.91,5.19c3.03,2.6,5.29,4.16,9.54,1.81c6.02-3.32,7.06-2.35,10.32,3.4c1.07,1.89,3.91,3.79,6,3.86
                c4.18,0.14,7.21,1.41,10.06,4.44c1.18,1.26,4.08,2.15,5.59,1.59c3.43-1.27,5.28-0.14,7.1,2.49c1.98,2.88,4.54,4.25,8.17,2.76
                c1.16-0.48,2.62-0.35,3.94-0.29c2.82,0.14,5.04-0.21,6.57-3.23c0.71-1.39,3.12-2.59,4.77-2.61c1.1-0.01,2.54,2.07,3.25,3.5
                c1.88,3.74,4.42,4.52,8.3,2.4c1.09-0.6,2.49-0.59,3.73-0.96c6.49-1.94,15.46,0.57,17.03-10.14c0.41-2.83,3.2-2.58,5.03-0.62
                c1.81,1.94,3.12,4.38,5.08,6.13c1.28,1.15,4.52,2.38,4.83,1.98c2.66-3.43,5.36-1.27,8.13-0.61c1.74,0.41,3.57,0.38,5.32,0.75
                c2.02,0.43,4.23,0.69,5.94,1.72c4.18,2.52,7.97,5.67,12.18,8.13c4.7,2.75,10.57,3.71,10.54,11.35c-0.01,3.48,3.07,3.8,6.25,1.47
                c1.57-1.15,3.8-2.57,5.42-2.26c3.41,0.65,10.93,13.39,11.24,17.06c0.16,1.92,1.52,3.74,2.35,5.6c0.57-0.16,1.15-0.32,1.72-0.48
                c1.99-4.98,3.37-9.85,0.28-15.3c-10.51-18.54-15.82-38.74-18.13-59.75c-1.03-9.37-3.64-17.48-12.01-22.93
                c-2.76-1.8-5.39-4.07-7.42-6.64c-10.01-12.68-25.85-15.98-38.63-24.17c-1.01-0.65-3.17,0.51-4.79,0.83
                c1.8,6.79-2.05,7.94-7.5,7.58c-0.38-0.02-0.79,0.56-1.2,0.83c-4.76,3.19-7.83,2.31-12.5-0.87c-3.39-2.31-7.99-3.83-12.06-3.86
                c-3.96-0.03-7.46-0.74-9.09-3.76c-3.9-7.22-10.42-7.17-17.09-7.67c-2.69-0.2-5.44-0.94-7.95-1.97c-2.34-0.96-3.97-2.14-6.56,0.15
                c-1.1,0.97-4.69,0.09-6.53-1c-2.2-1.3-3.49-4.1-5.64-5.56c-1.87-1.26-4.6-2.54-6.6-2.12c-10.68,2.22-16.49-5.96-23.81-10.74
                c-3.47-2.26-5.98-6.5-9.66-7.81c-3.8-1.36-8.49-0.27-12.78-0.22c-0.29,0-0.59,0.29-0.85,0.48c-5.95,4.26-5.95,4.26-10.71-1.47
                c-1.29,1.42-2.56,2.81-3.73,4.1c2.23,3.95,5.09,5.87,9.47,5.95c1.48,0.03,3.01,1.94,4.37,3.15c1.35,1.2,2.59,2.54,3.75,3.93
                c1.81,2.18,3.81,4.29,5.16,6.74c0.92,1.66,1.09,3.84,1.2,5.81c0.11,1.82,0.35,4.38-0.66,5.32c-1.12,1.03-3.61,0.82-5.49,0.73
                c-3.34-0.17-6.67-0.65-9.95-1c-1.02,6.69-4.75,8.41-11.11,6.16c-1.32-0.47-2.73-0.76-4.13-0.94c-5.48-0.69-11.07-0.87-16.41-2.13
                c-2.78-0.66-4.46-1.85-5.49,1.93c-1.11,4.05-4.49,5.69-8.2,4.56c-3.56-1.09-6.74-3.41-9.38-4.81c-3.42,1.37-6.84,2.73-9.72,3.88
                c-1.08,2.16-1.61,5.02-3.32,6.25c-3.33,2.39-12.1,0.46-15.09-2.63c-3.19-3.29-7.41-3.38-9.23,0.72c-2.39,5.36-5.43,4.88-9.53,2.86
                c-1.01-0.5-2.2-0.85-3.31-0.86c-3.49-0.04-7.68,1.39-10.33-0.05c-6.1-3.32-12.59-4.31-19.13-5.55c-0.62-0.12-1.59-0.54-1.68-0.97
                c-1.54-7.85-7.94-8.79-14.11-10.35c-2.46-0.62-4.63-2.31-7.03-3.26c-3.95-1.56-7.23-3.63-7.14-8.47c0.11-6.31-1.81-8.29-8.24-9.54
                c-2.63-0.51-5.4-1.82-7.42-3.56c-4.17-3.57-8.39-6.06-14.19-5.75c-3.02,0.16-6.1-1.37-9.17-1.44c-4.99-0.11-10.08-2.5-14.9,1.75
                c-4.98,4.39-17.69-0.24-19.2-6.57c-1.56-6.55-2.45-7.05-8.86-4.77c-5.01,1.78-9.53,5.75-15.48,3.22
                c-0.53-0.22-1.53,0.49-2.23,0.91c-3.63,2.16-7.48,1.83-10.2-0.88c-3.91-3.9-7.34-8.36-10.41-12.97c-1.9-2.86-3.24-4.32-6.6-2.09
                c-7.97,5.3-16.15,2.8-24.29,0.31c-1.52-0.47-3.1-1.11-4.63-1.06c-9.58,0.36-19.39-9.39-18.75-18.97c0.22-3.26-1.09-4.87-4.24-4.4
                c-6.85,1.01-7.95,0.19-8.08-6.82c-0.1-5.32,0.52-10.77-0.42-15.94c-1.15-6.35-1.82-12.11,3.58-16.51
                c-1.19-2.58-3.38-5.01-3.01-6.98c0.78-4.21-0.22-8.71,3.36-12.72c2.19-2.46,3.65-4.75,6.93-3.98c4.09,0.96,4.6-1.06,4.49-4.25
                c-0.18-5.66,1.02-6.8,7.08-7.18c0.76-0.05,1.68-0.32,2.21-0.81c4.56-4.25,11.01-6.29,14.13-12.34c0.56-1.08,3.04-1.28,4.69-1.66
                c1.54-0.35,3.15-0.39,4.72-0.59c5.39-0.66,8.77-4.02,11.52-8.38c2.48-3.94,5.63-4.66,9.74-2.08c1.51,0.94,3.64,1.05,5.52,1.2
                c7.99,0.64,14.98-3.35,22.48-4.94c2.64-0.56,5.5-0.06,8.26-0.05c2.69,0,5.39,0.05,8.07-0.03c2.57-0.08,5.88-1.35,7.54-0.2
                c2.7,1.88,4.4,1.39,7.12,0.54c2.4-0.75,5.3,0.12,7.98,0.33c0.84,0.06,1.67,0.29,2.51,0.43c-1.02-1.65-2.7-2.05-3.67-3.11
                c-1.22-1.34-3.02-3.8-2.54-4.74c1.98-3.89-0.64-5.03-3.02-6.58c-2.96-1.92-4.53-3.85-2.23-7.76c1.57-2.67,0.95-5.76-2.02-8.33
                c-2.48-2.14-4.82-5.15-5.72-8.21c-0.98-3.35-0.22-7.21-0.22-10.86c-2.79,1.01-5.62,0.63-7.44-2.82c-2.86-5.38-8.78-6.74-14.2-3.97
                c-2.26,1.16-5.48,1.74-7.84,1.08c-2.94-0.82-4.89-0.86-7.11,1.34c-1.1,1.1-2.93,1.48-4.46,2.13c-3.31,1.41-7.29,2.06-9.83,4.33
                c-4.94,4.42-9.78,8.17-16.83,8.03c-1.08-0.02-2.53,1.46-3.23,2.6c-5.62,9.24-15.9,11.65-24.32,16.76
                c-0.53,0.32-1.31,0.21-1.94,0.4c-2.63,0.81-5.26,1.61-7.85,2.51c-3.59,1.24-7.18,3.67-10.71,3.57c-4.07-0.11-6.78,0.48-9.04,3.91
                c-1.88,2.84-3.92,5.74-6.47,7.95c-6.02,5.21-12.58,9.79-18.51,15.09c-3.11,2.78-5.72,4.74-10.08,2.93
                c-3.44-1.42-4.99,0.86-5.17,3.96c-0.32,5.58-2.21,7.76-7.45,9.25c-2.51,0.71-4.76,2.36-6.26,3.13c1.58,3.92,2.79,6.93,4.36,10.85
                c1.12,0.76,3.41,3.6,5.39,3.39c5.04-0.53,6.26,2.7,8.04,5.91c1.28,2.3,2.44,4.64,5.97,4.29c0.98-0.1,2.73,1.81,3.18,3.12
                c1.78,5.17,0.12,9.6-5.35,10.94c-9.4,2.31-14.25,10.39-21.49,15.37c-2.37,1.63-4.15,4.12-6.2,6.22c-1.19,4.06-1.2,4.01-6.03,5.17
                c-1.05,0.25-2.19,0.81-2.94,1.58c-4.53,4.68-9.72,8.44-9.4,16.59c0.28,7.09,4.93,18.55,11.64,20.42c4.8,1.34,10.78,2.94,14.87,1.2
                c5.17-2.2,8.42-0.14,12.45,1.25c1.03,0.35,2.36,0.72,3.28,0.37c6.12-2.36,11.56-0.67,16.91,2.35c1.2,0.68,2.74,0.73,4.07,1.24
                c3.43,1.32,6.82,2.76,10.25,4.09c1.5,0.58,3.05,1.07,4.6,1.48c0.91,0.24,2.08-0.07,2.8,0.4c3.56,2.29,7.47,4.3,10.4,7.26
                c5.88,5.93,11.27,12.36,16.7,18.71c2.1,2.45,3.77,4.55,7.71,4.12c3.34-0.36,6.95,0.5,10.27,1.41c11.31,3.08,22.53,6.5,33.83,9.62
                c3,0.83,6.18,1.45,9.27,1.43c2.7-0.01,5.39-1.14,8.11-1.61c2.36-0.41,5.11-1.48,7.07-0.7c4.13,1.63,9.26,1.42,12.13,5.96
                c0.44,0.7,2.78,0.32,4.24,0.25c2.57-0.12,5.13-0.51,7.71-0.57c3.6-0.08,7.22,0.17,10.82,0.02c3.88-0.16,7.89-1.97,11.35,1.4
                c3.15,3.07,6.42,4.14,9.55-0.03c4.18,5.23,4.14,5.14,10.77,2.15c1.08-0.49,2.76,0.02,4.05,0.42c7.78,2.39,15.52,4.88,22.54,7.11
                c1.53-2.2,2.4-4.63,3.42-4.7c6.9-0.46,13.77-0.09,20.31,3.03c-0.75,2.13-1.32,3.75-1.97,5.61c3.85,1.05,7.14,3.22,10.51-0.38
                c0.63-0.67,2.53-0.49,3.73-0.2C497.82,269.26,500.31,270.19,503.18,271.1z M576.86,2.09c0.89,4.5,5.5,8.06,1.7,12.64
                c-0.26,0.31,0.61,1.8,1.21,2.54c3.04,3.78,6.73,5.61,11.82,4.64c2.66-0.51,5.53-0.06,8.29,0.12c2.44,0.15,4.91,0.93,7.3,0.71
                c3.57-0.33,6.1-0.08,7.09,4.16c0.44,1.87,1.97,4.15,3.63,4.94c4.72,2.22,9.64,4.26,14.7,5.44c4.32,1.01,9.31,2.73,12.5-3.04
                c0.59-1.06,3.65-1.59,5.16-1.09c2.13,0.7,4.94,2.2,5.57,4.02c1.71,4.91,2.84,6.2,7.38,4.18c4.38-1.95,7.87-2.5,10.4,2.5
                c0.31,0.61,1.36,1.18,2.07,1.18c4.63-0.01,7.65,2.56,10.5,5.84c1.78,2.05,3.7,2.84,6.07-0.16c3.34-4.21,8.42-2.68,10.07,2.67
                c1.96,6.36,4.36,7.11,9.32,2.89c4.62-3.92,8.2-3.27,10.97,2c2.48,4.71,6.99,6.51,10.61,3.44c3.16-2.69,5.27-1.58,7.83,0.3
                c3.48,2.56,7.05,4.15,11.6,2.46c1.4-0.52,3.65,0.11,5.04,0.98c3.43,2.13,6.38,5.07,9.9,6.98c3.14,1.71,6.99,3.44,8.67-2.24
                c0.23-0.77,1.39-1.41,2.27-1.81c5.77-2.62,11.52-5.3,17.42-7.61c1.33-0.52,3.53-0.2,4.75,0.61c2.88,1.9,5.02,1.2,7.64-0.54
                c4.77-3.17,9.75-4.12,14.71-0.01c0.98,0.81,2.4,1.09,3.64,1.57c2.4,0.93,4.13,0.57,5.02-2.22c1.18-3.68,3.49-3.97,6.54-1.94
                c1.51,1.01,3.44,2.73,4.79,2.43c7.73-1.73,11.79,1.88,14.07,8.69c1.08,3.23,3.81,7.06,2.89,9.55c-1.39,3.76,0.25,4.34,2.53,5.5
                c3.32,1.68,5.5,4.08,3.86,7.95c-0.67,1.59-2.34,2.76-3.52,4.15c-2.02,2.35-1.83,5.03,0.54,6.5c1.75,1.09,4.38,0.96,6.62,0.97
                c1.5,0,3.81-1.52,4.37-0.96c2.32,2.3,5.37,4.94,5.81,7.81c0.75,4.85,3.98,9.14,9.1,9.23c5.29,0.09,9.82,6.5,7.27,11.26
                c-2.7,5.04,0.45,5.76,3.77,6.78c2.2,0.68,4.97,0.48,6.64,1.76c4.77,3.68,9.42,7.63,13.51,12.05c2.4,2.6,4.08,2.64,7.26,1.72
                c2.37-0.68,5.86,0.17,7.95,1.62c1.72,1.2,3.25,4.51,2.88,6.51c-0.69,3.7,0.61,6.82,3.28,7.47c5.69,1.38,11.71,1.45,17.59,2.02
                c0.23,0.02,0.56-0.35,0.76-0.61c2.24-2.81,4.79-2.54,7.48-0.75c2.41,1.6,3.24,3.5,1.81,6.37c-0.41,0.83,0.17,2.76,0.95,3.4
                c0.75,0.62,2.34,0.29,3.56,0.29c2.06,0,4.11-0.05,6.17-0.1c3.1-0.07,7,1.02,9.15-0.44c6.04-4.11,7.53-4.24,11.5,2.08
                c3.4,5.41,5.67,5.81,10.44,1.84c6.92,3.64,6.77,3.51,11.68-2.63c1.22-1.53,4.09-3.07,5.73-2.67c4.89,1.2,9.57,3.33,14.29,5.18
                c1.85,0.73,3.62,1.68,6.48,3.02c-0.87-2.9-1.72-4.46-1.67-5.99c0.04-1.11,1.49-3.19,1.96-3.09c5.86,1.15,9.51-1.39,11.28-6.99
                c2.33,1.49,4.02,2.58,4.92,3.15c3.52-4.6,6.78-8.46,9.51-12.66c0.94-1.45,1.42-4.92,0.66-5.47c-5.15-3.77-2.93-9.11-3.61-13.82
                c-0.47-3.31-0.24-7.14-1.86-9.79c-3.98-6.49-8.89-12.4-13.32-18.62c-0.68-0.95-1.21-2.31-1.13-3.43
                c0.33-4.93-2.17-10.68-6.82-12.05c-7.58-2.23-14.94-7.04-23.49-3.13c-2.74,1.26-4.88,0.51-4.73-3.29
                c0.15-3.82-2.03-5.29-5.71-5.16c-1.95,0.07-4.1,0.18-5.84-0.53c-6.52-2.64-13.54-1.49-20.21-3.01c-2.66-0.61-5.83,0.72-8.33-0.13
                c-2.92-0.99-6.4-2.82-7.78-5.32c-2.48-4.48-5.44-7.76-10.91-9.4c-1.43-0.43-3.07-1.7-3.59-2.99c-0.29-0.73,1.6-2.25,2.35-3.5
                c0.53-0.88,1.42-2.44,1.11-2.81c-3.95-4.65-2.22-7.67,2.42-10.31c1.88-1.07,3.75-2.47,5.06-4.16c1.87-2.39,4.07-5.02,4.61-7.84
                c1.01-5.37,7.13-9.37,12.57-7.37c4.32,1.59,6.96,0.49,8.02-4.03c1.14-4.84,5.1-5.48,8.02-4.17c3.8,1.71,6.3,1.34,8.74-1.49
                c2.05-2.38,4.51-4.71,5.6-7.53c1.99-5.11,6.48-8.29,11.84-7.61c1.97,0.25,4.04-0.3,6.07-0.49c-0.06-0.36-0.12-0.72-0.17-1.08
                C882.53,2.09,730.05,2.09,576.86,2.09z M195.45,151.67c-0.33-2.31,0.54-5.09,5.35-4.51c0.5,0.06,1.11-1.04,1.72-1.54
                c1.46-1.2,2.97-2.34,4.44-3.54c1.1-0.9,2.49-1.65,3.19-2.81c2.81-4.69,6.04-8.41,12.03-9.21c2.61-0.35,5.49-1.87,7.29-3.77
                c1.08-1.14,0.32-4.04,0.39-6.14c-6.28,0.17-7.12-5.45-9.81-9.14c-0.5-0.68-1.57-1-2.43-1.34c-2.78-1.11-5.51-2.48-8.4-3.17
                c-5.24-1.26-2.28-4.81-2.6-7.56c-0.13-1.08-1.9-1.9-2.27-3.05c-0.53-1.66-1.25-4.47-0.49-5.1c2.35-1.93,5.21-3.63,8.14-4.37
                c3.85-0.97,5.65-2.47,5.51-6.74c-0.16-5.1,3.85-7.95,8.65-6.29c3.28,1.13,5.17,0.07,7.38-2.21c3.85-3.97,8.09-7.57,12.16-11.32
                c3.45-3.18,7.08-6.2,10.24-9.65c0.93-1.02,1.02-3.42,0.61-4.95c-1.64-6.12-8.07-7.11-12.16-10.62c-3.17-2.72-7.12-4.54-10.7-6.78
                c-2.52-1.57-4.96-3.27-7.54-4.72c-1.47-0.82-3.11-1.56-4.75-1.79c-2.57-0.35-5.2-0.22-7.81-0.33c-3.66-0.16-7.32-0.58-10.97-0.47
                c-2.42,0.08-4.82,0.89-7.23,1.38c-6.24,1.26-12.44,2.72-18.72,3.69c-2.29,0.36-4.77-0.38-7.15-0.7
                c-4.33-0.57-8.75-2.06-12.97-1.62c-7.33,0.75-14.4,4.02-22.02,1.8c-0.83-0.24-2.15,0.05-2.83,0.6
                c-4.72,3.84-12.13,2.24-15.97,8.01c-0.52,0.78-2.46,0.8-3.76,0.86c-6.83,0.34-13.93-0.45-19.38,5.18
                c-0.62,0.64-1.91,0.58-2.74,1.09c-3.92,2.43-8.24,4.45-11.55,7.55c-3.98,3.73-6.93,8.53-10.64,12.59
                c-1.03,1.12-3.22,1.91-4.74,1.73c-6.31-0.76-6.07-0.89-5.63,5.17c0.15,2.05-1.02,5.45-2.48,6.04c-1.98,0.81-4.91-0.21-7.25-0.99
                c-1.42-0.48-2.38-2.15-3.72-3.02c-4.03-2.61-7.93-5.58-12.29-7.48c-3.87-1.69-8.27-2.22-12.47-3.1c-2.04-0.42-4.42,0.07-6.21-0.77
                c-6.59-3.09-10.94-0.66-10.94,6.43c0,41.62,0,83.24,0,124.86c0,1.66-0.42,3.52,0.17,4.95c0.75,1.81,2.35,4.66,3.58,4.65
                c6.78-0.03,13.55-0.77,20.33-1.32c0.44-0.04,0.88-0.46,1.27-0.77c3.52-2.83,6.54-3.03,8.9,1.48c0.42,0.8,1.31,1.66,2.14,1.87
                c3.72,0.94,7.5,1.64,11.25,2.46c6.67,1.45,13.59,2.01,19.19,6.83c1.22,1.05,4.16,0.75,6.07,0.22c4.75-1.32,9.12-2.6,13.42,1.47
                c1.31,1.24,4,1.24,6.09,1.39c3.2,0.23,6.45-0.08,9.65,0.18c6.72,0.55,13.41,1.65,20.13,1.88c4.56,0.16,9.42-2.52,13.24,2.19
                c0.31,0.38,1.25,0.32,1.9,0.32c7.07-0.04,14.26-0.68,18.68,6.83c1.02,1.74,2.83,3.22,4.6,4.24c1.85,1.06,4.81,2.6,6.06,1.86
                c3.93-2.34,5.38-0.42,7.68,2.35c3.25,3.92,6.41,9,13.14,5.45c1.2-0.63,3.49-0.17,4.82,0.59c6.23,3.57,11.85,2.27,16.23-0.94
                c0-2.62-0.75-5.22,0.18-6.17c1.33-1.36,3.87-2.01,5.93-2.07c4.11-0.13,8.52-0.8,11.75,3.03c0.31,0.37,1.59-0.21,2.36-0.07
                c1.44,0.26,2.82,0.86,4.26,1.07c1.65,0.24,3.53,0.73,4.96,0.2c3.37-1.25,6.93-3.01,9.52,0.49c2.52,3.41,4.92,2.16,8.17,1.82
                c6.01-0.64,12.16-0.02,18.25,0.08c0.14,0,0.3,0.15,0.42,0.26c4.2,3.87,7.34,2.05,10.89-2.62c-2.58-2.03-5.12-3.91-7.5-5.96
                c-0.91-0.79-1.51-1.97-2.19-3.01c-1.12-1.71-1.83-3.89-3.34-5.1c-3.71-2.96-7.75-5.5-11.71-8.13c-0.62-0.41-1.73-0.79-2.27-0.51
                c-3.27,1.63-4.66-0.55-6.62-2.54c-1.65-1.68-4.48-3.94-6.16-3.51c-4.56,1.18-7.51-1.35-10.93-3.05c-1.04-0.52-2.13-1.53-3.13-1.45
                c-6.74,0.53-13.54,2.27-19.93-1.57c-4.06,3.69-8.55,0.51-12.82,0.75c-5.4,0.29-11.66,2.46-14.11-5.32
                c-0.12-0.39-0.87-0.53-1.15-0.91c-2.63-3.54-7.15-6.96-7.44-10.7c-0.67-8.55,0.05-17.47,8.1-23.45
                C188.82,157.41,191.48,154.98,195.45,151.67z M543.25,904.97c-1.32-0.43-2.37-0.9-3.46-1.12c-3.25-0.67-8.04,2.72-8.38,5.85
                c-0.71,6.45-1.92,7.58-8.65,6.21c-4.68-0.95-6,0.98-6.34,5.07c-0.19,2.26-0.22,5.69-1.57,6.48c-4.31,2.55-6.35,6.79-9.47,10.2
                c-4.21,4.6-4.18,10.37,0.7,14.05c1.15,0.87,2.05,2.66,2.22,4.13c0.58,4.78-1.49,8.28-5.71,10.72c-1.17,0.68-2.81,2.79-2.5,3.43
                c1.74,3.71,3.27,8.05,6.24,10.53c4.45,3.72,8.42,10.26,16.12,6.54c0.23-0.11,0.63,0.17,0.96,0.2c9.19,0.8,18.37,1.71,27.57,2.31
                c3.62,0.24,7.29-0.34,10.94-0.35c10.73-0.05,21.46,0.19,32.18-0.05c7.18-0.16,14.37-1.54,21.43,0.94
                c0.39,0.14,0.95-0.31,1.44-0.33c7.23-0.27,14.46-0.69,21.69-0.72c9.43-0.04,18.87,0.25,28.3,0.35c5.6,0.06,11.21-0.15,16.8,0.1
                c8.04,0.35,16.06,1.08,24.1,1.5c4.3,0.22,8.62-0.01,12.92,0.23c4.8,0.26,9.58,0.99,14.39,1.13c1.4,0.04,3.25-0.89,4.1-1.99
                c0.52-0.67-0.55-2.5-0.76-3.83c-0.33-2.11-0.7-4.24-0.76-6.37c-0.04-1.6,0.42-3.22,0.59-4.83c0.27-2.57,0.49-5.14,0.74-7.71
                c0.41,0.01,0.82,0.03,1.23,0.04c-0.8-1.41-1.59-2.82-2.25-3.97c1.11-1.67,2.33-2.87,2.76-4.3c0.89-2.94,0.97-6.16,2.1-8.97
                c0.91-2.27,2.87-4.12,4.37-6.15c1.48,1.72,2.94,3.44,4.44,5.14c0.2,0.23,0.56,0.32,0.85,0.47c6.54-2.36,7.4-4.24,4.89-10.61
                c-1.11-2.8,1.16-9.32,3.72-10.72c3.46-1.88,6.94-3.73,10.36-5.69c0.68-0.39,1.47-1.09,1.64-1.78c1.13-4.75,4.08-5.05,7.76-3.2
                c2.25-6.62,8.48-6.29,12.88-6.43c8.66-0.27,13.08,5.95,14.97,14.96c0.84-1.74,1.37-2.42,1.5-3.17
                c3.22-18.85,6.67-37.66,6.41-56.94c-0.24-17.31,0.48-34.54,3.49-51.71c1.37-7.8,0.23-16.04,0.23-25.35
                c-5.15,4.51-10.18,8.17-14.19,12.73c-2.24,2.54-3.6,3.52-6.42,1.62c-2.56-1.73-4.42-1.43-6.05,1.56
                c-0.73,1.34-2.69,2.67-4.21,2.82c-9.08,0.91-17.01,3.84-22.1,11.9c-1.56,2.48-3.16,2.58-5.37,1.35c-3.16-1.75-5.26-0.6-7.04,2.27
                c-1.39,2.23-3.04,4.33-4.82,6.27c-2.24,2.45-4.85,4.57-7.04,7.06c-0.66,0.75-0.44,2.28-0.63,3.45c1.09,0.08,2.23,0.43,3.25,0.21
                c6.3-1.36,11.84-8.01,19.1-2.66c0.19,0.14,0.64-0.12,0.96-0.11c5.67,0.09,11.45-0.47,16.97,0.47c6.33,1.08,12.35,3.81,18.64,5.27
                c8.03,1.86,10.25,8.93,14.7,14.16c-4.86,1.14-9.05,2.05-13.21,3.1c-3.62,0.92-7.38,3.18-10.77,2.69c-4.23-0.61-6.1,0.7-8.99,3.44
                c-6.07,5.76-8.31,5.34-12.45,0.01c-5.14-6.63-7.16-7.23-15.06-4.52c-0.62,0.21-1.35,0.52-1.93,0.37
                c-6.32-1.6-13.18,3.42-19.25-1.6c-0.41-0.34-1.72-0.07-2.31,0.34c-4.06,2.83-8.23,1.49-12.51,0.75c-2.06-0.35-4.9-0.32-6.36,0.83
                c-3.21,2.53-6.37,2.9-10.23,2.49c-2.37-0.25-6.08,0.08-7.12,1.58c-4.2,6.07-13.13,5.41-16.53,12.25c-0.1,0.19-0.66,0.11-0.93,0.27
                c-2.8,1.63-2.92,4.24-0.08,5.66c4.09,2.03,8.27,3.91,12.52,5.56c6.1,2.37,11.57,9.31,11.22,15.56c-1.88-1.32-3.47-2.3-4.91-3.47
                c-2.53-2.06-4.53-4.33-8.67-3.36c-3.18,0.75-6.91-0.08-10.24-0.87c-8.94-2.11-16.9-1.02-23.83,5.55
                c-1.24,1.18-4.46,2.38-5.02,1.82c-5.61-5.57-10.71-4.06-17.39-1.32c-5.21,2.14-10.12,4.72-15.17,7.08
                c-4.15,1.94-7.33,5.17-10.93,7.73c-5.01,3.57-7.38,7.81-8.83,13.28c-0.35,1.34-1.6,2.44-2.43,3.65c-1.01-1.19-3-2.52-2.85-3.54
                c0.67-4.77,1.44-9.65,3.11-14.13c1.98-5.29,7.44-9.5,3.95-16.3c-0.41-0.8,1.6-3.79,3.07-4.42c4.87-2.08,10.15-3.43,13.5-7.91
                c3.57-4.77,7.88-5.12,13.3-3.71c2.57,0.67,6.85,0.48,8.24-1.14c4.19-4.86,7.68-4.19,11.82-0.5c0.68,0.61,1.73,0.81,2.7,1.24
                c2.51-5.35,5.62-10.28,7.11-15.65c1.29-4.65,0.95-9.82,0.95-14.75c0-3.92,1-6.58,5.03-8.13c1.73-0.67,3.3-2.92,4.02-4.81
                c1.92-4.99,5.86-9.27,5.43-15.17c-0.06-0.77,1.34-1.94,2.31-2.44c4.7-2.42,6.36-6.78,8.34-11.31c1.41-3.23,4.58-5.67,6.87-8.53
                c1.2-1.49,2.19-3.15,3.28-4.73c-0.41-0.28-0.81-0.57-1.22-0.85c-5.87,5.89-15.99,5.41-20.21,13.77c-0.06,0.12-0.31,0.13-0.43,0.24
                c-2.21,2-4.38,4.03-6.62,6c-1.09,0.96-2.26,1.81-3.43,2.67c-0.88,0.65-2.18,1.04-2.65,1.9c-3.63,6.61-3.55,6.45-11.1,4.97
                c-1.98-0.39-5.1,0-6.23,1.31c-2.41,2.81-4.52,6.25-5.54,9.78c-1.07,3.75-3.25,4.62-6.47,4.85c-1.8,0.13-4.26-0.24-5.27,0.77
                c-4.49,4.5-8.9,9.17-12.66,14.27c-1.97,2.68-3.7,3.65-6.92,3.46c-2.25-0.13-5.6,0.2-6.69,1.65c-3.72,4.93-6.66,10.44-9.86,15.76
                c-0.24,0.39-0.24,0.97-0.24,1.46c0.01,6.94-6.71,10.1-8.91,15.81c-0.18,0.47-1.08,0.85-1.69,0.94c-4.1,0.58-5.86,3.2-6.56,7
                c-0.21,1.15-1.09,2.17-1.67,3.25c-0.45,0.01-0.91,0.01-1.36,0.02c-0.63-2.26-1.24-4.52-1.9-6.77c-1.4-4.83-3.11-5.44-7.29-2.98
                c-0.99,0.58-2.55,0.09-3.72,0.49C551.96,901.89,545.79,897.83,543.25,904.97z M493.06,211.66c3.5-2.73,7.27-4.64,9.51-7.69
                c2.92-3.97,4.64-2.8,7.33-0.17c1.03,1.01,3.01,1.6,4.44,1.43c0.68-0.08,1.04-2.41,1.66-3.67c1.16-2.33,2.4-4.62,3.61-6.93
                c2.27-5.91,4.02-12.17,5.79-18.42c0.08-0.28,0.5-0.45,0.66-0.72c0.7-1.17,1.36-2.36,2.04-3.54c-1.55-0.71-3.08-1.46-4.64-2.13
                c-2.19-0.94-4.91-1.3-6.49-2.85c-2.83-2.75-5.16-6.08-7.36-9.39c-2.13-3.19-2.53-6.44,0.9-9.35c0.84-0.72,1.2-2.18,1.47-3.38
                c0.64-2.88,0.67-5.97,1.73-8.67c1.6-4.07,0.69-6.14-3.52-7.43c-5.98-1.84-6.47-3.48-3.42-8.89c2.82-4.99,1.21-8.96-4.14-10.22
                c-5.36-1.26-10.23-6.79-9.85-12.14c0.21-3.08-1.05-4.11-3.41-5.13c-2.09-0.9-4.68-1.72-5.88-3.4c-2.75-3.85-8.38-5.58-8.43-11.59
                c-0.01-0.9-2.84-1.96-4.5-2.61c-2.3-0.9-5.43-0.79-6.94-2.34c-3.75-3.83-7.47-2.99-11.26-0.95c-3.83,2.07-6.47,1.41-8.82-2.24
                c-2.53-3.93-6.19-6.06-11.04-5.13c-3.84,0.74-7.61,1.96-11.48,2.46c-1.73,0.22-4.13-0.27-5.34-1.39
                c-3.32-3.06-6.49-5.14-11.09-2.76c-0.67,0.35-2.09,0.02-2.74-0.52c-2.99-2.47-5.89-1.66-8.8-0.02c-2.32,1.3-4.49,2.37-6.44-0.61
                c-0.4-0.6-1.58-1.34-2.05-1.15c-6.56,2.65-13.14,1.17-19.74,0.41c-0.73-0.08-1.52,0.64-2.33,0.82c-6.49,1.52-12.99,3-19.49,4.47
                c-0.81,0.18-1.81-0.03-2.44,0.38c-2.45,1.58-4.13,0.72-6.06-1.1c-2.11-1.99-5.08-3.03-6.77,0.16c-3.23,6.09-8.18,9.46-14.69,10.93
                c-1.03,0.23-2.33,0.9-3.06,0.52c-2.79-1.45-3.88-0.16-4.87,2.23c-0.47,1.15-1.44,2.22-2.44,2.99c-4.41,3.37-8.92,6.62-13.58,10.04
                c-4.91-2-5.41-1.67-5.44,4.05c-0.03,5.8-1.44,6.99-7.01,6.36c-1.13-0.13-2.94,0.21-3.44,0.98c-3.82,5.81-6,11.83-1.71,18.54
                c0.58,0.9,0.14,3.61-0.6,4c-3.58,1.88-3.51,4.97-3.36,8.17c0.36,7.46,0.82,14.91,1.16,22.36c0.15,3.35,0.81,5.02,4.83,3.37
                c4.06-1.67,8.66,2.84,7.86,7.22c-1.53,8.29,8.1,17.19,16.41,15.34c1.17-0.26,2.78,0.32,3.85,1.02c6.14,3.97,12.86,3.8,19.58,2.67
                c3.41-0.57,7.36-3.77,9.8-2.75c2.7,1.12,3.96,5.71,5.85,8.79c0.35,0.57,0.66,1.16,1.05,1.69c4.66,6.36,8.46,7.5,16.42,5.02
                c0.75-0.23,1.79-0.45,2.39-0.12c3.66,1.99,6.63,0.2,9.85-1.28c2.69-1.24,5.6-2.22,8.51-2.69c4.38-0.71,7.25,1.5,7.38,5.89
                c0.15,5.08,3.92,6.09,7.4,7.56c3.36,1.42,6.26,0.99,9.16-1.63c1.42-1.28,3.95-1.95,5.93-1.87c6.83,0.27,13.65,0.86,20.45,1.59
                c5.24,0.56,10.17,2.04,13.63,6.52c3.08,3.98,9.96,4.4,13.86,0.83c1.34-1.23,2.42-2.75,3.73-4.03c3.53-3.45,5.21-3.08,7.43,1.32
                c0.79,1.56,1.96,4.16,2.96,4.16c3.4,0.01,7-0.45,10.1-1.75c1.23-0.52,1.07-3.85,2.04-5.63c1.18-2.17,2.91-5.74,4.35-5.71
                c2.47,0.05,5.29,2.01,7.19,3.94C492.15,207.41,492.44,210.05,493.06,211.66z M71.39,791.22c3.95,0.51,7.21,0.03,9.29,1.39
                c5.57,3.62,11.33,7.4,15.69,12.29c5.09,5.7,8.07,13.34,13.31,18.85c4.61,4.84,4.03,9.75,3.46,15.06c-0.3,2.82-0.4,6.44-4.74,6.38
                c-7.99-0.11-9.78-1.29-10.14-6.95c-0.18-2.82-1.12-4.15-3.88-5.46c-2.25-1.07-4.49-4.11-4.96-6.61
                c-1.98-10.61-1.74-10.55-12.2-10.5c-1.79,0.01-3.97-0.84-5.32-2.03c-5.2-4.58-7.5-12.38-15.52-13.87
                c-0.42-0.08-0.84-0.58-1.1-0.99c-4.37-7.1-10.93-8.27-18.62-7.47c-5.41,0.56-5.95-0.55-4.57-5.99c0.23-0.93,0.33-1.97,0.18-2.9
                c-0.63-3.71-1.54-7.37-2.04-11.1c-0.34-2.58-1.27-4.33-4.05-3.69c-3.59,0.82-4.87-1.16-6.51-3.84c-1.96-3.2-4.7-5.94-7.27-8.73
                c-0.5-0.54-1.79-0.61-2.66-0.48c-2.37,0.36-4.71,0.94-7.81,1.59c0,1.65,0,3.76,0,5.87c0,69.27,0,138.54,0,207.8
                c0,5.16,0.1,10.33-0.02,15.48c-0.08,3.6,1.52,5.21,5.06,5.19c6.99-0.04,14.06,0.66,20.95-0.18c13.64-1.65,26.84,0.13,40.09,2.97
                c3.62,0.78,7.53,0.35,11.3,0.22c4.97-0.17,9.99-1.24,14.87-0.74c4.77,0.49,6.74-1.27,7.66-5.46c4.1-18.67,9.82-37.15,11.97-56.03
                c3.12-27.38,3.91-55.05,5.25-82.62c1.1-22.6,1.5-45.24,2.32-67.86c0.12-3.43,0.82-6.68-4.1-7.35c-1.14-0.16-1.96-2.07-3.09-2.98
                c-1.41-1.14-2.88-2.34-4.52-3.06c-4.15-1.84-7.58-0.42-10.89,4.4c-2.03-4.45-3.68-7.77-5.08-11.19
                c-2.18-5.34-4.26-10.58-11.28-11.09c-1.03-0.07-2.48-1.34-2.85-2.37c-2.57-7.24-8-11.81-13.97-16.09c-1.75-1.26-3-3.52-3.86-5.58
                c-0.37-0.88,0.54-3.12,1.45-3.56c4.44-2.17,13.87,1.24,17.06,5.09c1.68,2.02,4.61,3.03,7.04,4.37c5.59,3.08,11.95,5.27,16.6,9.4
                c3.19,2.83,4.68,6.17,9.38,2.96c0.86-0.59,2.55-0.03,3.85,0.13c1.7,0.21,3.38,0.54,5.06,0.82c0.36-2.28,0.72-4.56,1.21-7.65
                c-5.38,1.19-7.85-0.67-9.38-4.76c-0.56-1.49-2.91-2.34-4.5-3.4c-0.9-0.6-2.13-0.84-2.82-1.6c-3.01-3.27-5.54-7.09-8.92-9.87
                c-2-1.65-5.26-1.84-8-2.49c-1.53-0.36-3.28,0.01-4.71-0.53c-2.66-1-5.2-2.34-7.71-3.69c-3.12-1.68-6.13-3.55-9.23-5.25
                c-1.83-1-3.71-1.92-5.63-2.72c-1.93-0.81-3.96-1.36-5.9-2.13c-4.25-1.67-8.47-3.42-12.72-5.1c-2.14-0.85-4.7-1.14-6.41-2.51
                c-4.62-3.68-10.59-4.56-14.91-2.55c-6.91,3.22-13.36,2.78-20.6,1.62c0,4.39,0.06,8.7-0.02,13.01c-0.06,3.2,1.11,4.24,4.51,4.14
                c2.28-0.07,4.67,1.52,6.91,2.57c1.78,0.83,3.35,2.66,5.13,2.83c12.63,1.25,22.41,7.43,28.35,18.25
                c3.62,6.59,6.74,13.17,12.58,18.15c1.03,0.88,1.19,2.92,1.49,4.47c0.34,1.76-0.05,3.77,0.64,5.34c2.79,6.37,0.03,14.88,7.48,19.45
                c0.32,0.19,0.34,0.91,0.44,1.39C70.2,785.3,70.93,788.92,71.39,791.22z M1073.03,505.8c-4.27,0.99-8.53,0.76-10.75,2.77
                c-4.03,3.66-7.5,2.41-11.43,0.93c-3.12-1.17-6.17-2.6-9.39-3.34c-1.41-0.33-3.79,0.27-4.62,1.32c-2.63,3.3-4.93,2.65-7.55,0.28
                c-3.71-3.36-5.87-2.99-7.21,1.72c-1.08,3.8-3.04,4.21-6.31,3.1c-1.97-0.67-4.13-0.77-6.16-1.32c-2.4-0.64-5.26-0.87-7.03-2.35
                c-4.93-4.13-10.51-2.82-13.19,3.31c-0.69,1.57-2.53,3.68-3.89,3.72c-3.5,0.08-3.7,2.06-3.79,4.53
                c-0.58,16.45-1.02,32.91-1.78,49.36c-0.91,19.77-1.51,39.57-3.28,59.27c-3.16,35.12-6.51,70.18-4.8,105.53
                c0.27,5.55,0.15,10.14,6.9,11.8c3.14,0.78,5.95,4.44,8.82,4.33c4.83-0.18,7.08,1.69,9.61,5.32c3.55,5.08,9.4,7.31,15.3,7.92
                c2.79,0.29,4.55,0.55,6.12,2.93c0.71,1.07,2.54,2.07,3.78,1.98c4.87-0.35,9.71-1.15,14.98-1.84c-0.89-5.61,7.65-7.78,7.2-14.35
                c-0.03-0.4,0.86-1.08,1.44-1.26c4.47-1.4,6.96-7.78,13.03-5.19c0.3,0.13,0.88-0.35,1.31-0.59c2.46-1.38,4.91-2.77,8.06-4.55
                C1067.84,663.27,1075.39,584.8,1073.03,505.8z M1017.73,285.45c-0.3,0.07-0.69,0.05-0.87,0.24c-0.59,0.58-1.12,1.23-1.65,1.86
                c-9.6,11.5-14.68,25.22-19.05,39.25c-5.26,16.9-4.93,34.5-6.09,51.89c-0.97,14.44-1.47,28.91-2.42,43.35
                c-1.77,26.88-3.73,53.75-5.56,80.63c-0.17,2.48-0.1,5,0.15,7.48c0.08,0.8,0.95,1.91,1.68,2.14c0.63,0.2,2.13-0.5,2.31-1.09
                c2.74-8.62,11.37-6.36,17.1-10.62c0.05,6.94,0.94,7.67,6.54,7.39c1.58-0.08,3.3,0.4,4.78,1.03c2.45,1.04,3.64,0.6,5.08-1.91
                c3.6-6.25,6.72-6.41,11.76-1.29c0.31,0.32,0.88,0.38,1.82,0.75c2.24-6.33,6.65-4.25,10.95-2.69c2.19,0.8,4.4,1.55,6.55,2.44
                c3.34,1.38,6.57,2.81,9.2-1.3c0.39-0.61,1.78-0.79,2.72-0.81c8.53-0.14,10.91-2.18,11.33-11.16c0.9-19.1,2.28-38.23,2.06-57.33
                c-0.54-46.76-1.94-93.51-2.87-140.27c-0.21-10.82,0.19-21.64,0.07-32.46c-0.02-1.74-0.86-4.13-2.14-5.01
                c-1.07-0.73-4.1-0.3-4.91,0.71c-1.02,1.26-1.29,3.83-0.76,5.48c1.38,4.3,3,8.25-3.67,9.21c-0.34,0.05-0.59,0.73-0.87,1.14
                c-1.64,2.41-3.28,4.83-5.55,8.18c-0.1-0.69,0-0.05,0.09,0.6c1.07,7.57-0.26,8.88-7.94,8.05c-1.09-0.12-2.27,0.03-3.34,0.3
                c-4.58,1.17-9.07,3.16-13.7,3.53C1019.69,296.05,1019.44,295.57,1017.73,285.45z M824.51,124.97c-0.1,0.2-0.19,0.39-0.29,0.59
                c-2.53-1.72-5.25-3.22-7.53-5.23c-1.88-1.65-3.83-3.66-4.7-5.92c-2.64-6.85-7.05-9.15-14.03-6.68c-6.77,2.4-8.61,1.39-9.96-5.45
                c-0.59-3-4.41-5.56-6.06-3.75c-2.91,3.21-4.97,1.51-7.69,0.16c-1.3-0.65-3.27-0.58-4.74-0.16c-4.94,1.41-9.7,3.57-14.71,4.52
                c-2.26,0.43-5.32-0.75-7.33-2.17c-4.12-2.91-7.77-6.31-13.47-4.46c-1.05,0.34-2.76-0.04-3.65-0.74
                c-4.14-3.26-8.05-6.81-12.17-10.11c-1.07-0.86-2.62-1.69-3.9-1.62c-5.25,0.3-10.48,1.09-15.74,1.38
                c-1.62,0.09-3.82-0.36-4.87-1.43c-3.46-3.56-7.28-3.62-11.46-2.04c-3.05,1.15-5.54,0.9-8.29-1.26c-3.47-2.73-7.21-4.99-12.3-3.01
                c-1.8,0.7-5.75-0.3-6.66-1.79c-4.19-6.79-10.55-5.61-16.67-5.42c-4.08,0.13-8.56,1.74-10.22-4.08c-0.11-0.38-0.75-0.84-1.16-0.85
                c-4.1-0.1-8.2-0.13-12.3-0.15c-1.41-0.01-2.83,0.16-4.24,0.13c-3.32-0.06-6.67-0.44-9.95-0.15c-1.13,0.1-2.49,1.63-3,2.83
                c-0.21,0.49,1.33,2.28,2.38,2.69c4.42,1.72,9.05,2.92,13.41,4.77c2.54,1.07,5.78,2.52,6.78,4.69c1.28,2.79-0.19,5.33-3.63,7.47
                c-3.15,1.96-5.11,6.26-6.89,9.88c-1.83,3.73-2.38,8.01-7.45,9.32c-2.92,0.75-5.01,2.94-3.65,6.45c2.27,5.85,9.94,8.81,15.59,6.11
                c3.81-1.82,6.94-0.38,9.28,3.05c2.61,3.83,5.56,7.67,9.13,10.55c4.79,3.87,5.06,3.86,0.41,7.92c-0.92,0.81-1.59,3.63-1.38,3.75
                c6.37,3.58,12.81,7.05,19.38,10.26c2.69,1.31,6.44,1.18,8.44,3.06c6.63,6.23,14.25,8.75,23.09,7.87c4.81-0.48,7.52,1.55,8.88,6.07
                c0.37,1.24,1.49,3.04,2.46,3.18c8.42,1.19,16.87,2.35,25.35,2.91c4.59,0.3,7.38,1.16,8.13,6.46c0.91,6.51,9.9,9.52,15.17,5.61
                c7.41-5.5,15.02-9.21,23.94-2.8c0.96,0.69,2.93,0.01,4.44-0.08c4.12-0.25,8.65-1.73,12.25-0.49c4.88,1.68,9.46,1.8,13.79-1.39
                c3.3-2.44,7.3-5.49,10.98-5.49c4.92,0,9.04,4.52,15.01-0.06c4.02-3.08,8.25-4.79,8.8-10.41c0.39-4.03,2.25-5.18,6.08-3.9
                c1.22,0.41,3.11,0.45,4.05-0.23c4.04-2.96,7.84-6.24,11.7-9.39c-1.01-1.36-1.98-2.64-2.93-3.94c-2.32-3.2-4.75-6.33-6.89-9.64
                c-0.85-1.31-1.58-3.06-1.48-4.55c0.32-4.52-2.04-7.53-6.49-8c-1.37-0.14-2.7-0.88-4-1.47
                C829.18,127.27,826.86,126.1,824.51,124.97z M882.86,1124.94c0.56,0,1.11,0,1.67,0c0.28-0.91,0.68-1.8,0.81-2.73
                c2.73-19.59,4.92-39.28,8.26-58.77c5.56-32.44,10.02-65.23,18.3-97c9.63-36.96,14.31-74.05,13.48-112.03
                c-0.6-27.29-1.31-54.57-1.94-81.85c-0.12-5,0.61-10.37-6.32-11.7c-0.54-0.1-1.03-0.82-1.43-1.33c-3.47-4.47-6.36-9.75-13.53-8.49
                c-0.75,0.13-1.68-1.63-2.68-1.89c-4.13-1.05-8.89-3.54-12.35-2.36c-5.56,1.9-12.67,1.72-16.19,8.06
                c-0.19,0.35-0.86,0.48-1.33,0.63c-3.15,1.05-3.99,3.17-3.07,6.25c1.43,4.78,0.53,8.86-4.62,10.5c-9.63,3.07-9.93,10.36-9.92,18.75
                c0.06,34.35,3.89,68.39,7.6,102.46c2.16,19.83,3.82,39.72,5.53,59.6c0.94,10.93,0.61,22.05,2.41,32.83
                c6.17,36.96,10.23,74.12,12.63,111.49C880.77,1106.57,881.95,1115.75,882.86,1124.94z M363.53,647.18
                c-0.96-0.71-2.02-1.2-2.62-2.02c-1.06-1.45-1.83-3.1-2.72-4.67c1.68-0.59,3.42-1.77,5.04-1.63c2.21,0.19,4.3,1.52,6.51,2.1
                c2.95,0.78,6.13,2.22,8.92,1.72c3.07-0.55,4.2,0.28,5.42,2.65c0.71,1.37,1.78,3.16,3.04,3.52c7.21,2.07,14.5,3.87,21.81,5.56
                c3.96,0.91,8.3,2.65,11.96,1.8c5.25-1.21,7.79,0.01,9.49,4.77c0.24,0.66,1.94,1.1,2.98,1.13c5.04,0.17,10.54-1.1,15.04,0.49
                c9.94,3.52,19.24,1.08,28.73-0.97c0.74-0.16,1.86-2.27,1.57-2.85c-0.92-1.82-2.33-3.44-3.73-4.97c-3.5-3.82-7.13-7.52-10.6-11.36
                c-0.43-0.48-0.48-1.7-0.21-2.36c1.92-4.67-0.29-7.74-2.84-11.65c-2.5-3.82-4.93-6.16-9.27-6.99c-1.36-0.26-2.84-1.23-3.74-2.3
                c-5.23-6.26-12.25-8.66-19.99-9.56c-3.14-0.36-5.63-1.12-6.96-4.59c-0.57-1.5-2.78-2.9-4.5-3.34c-7.27-1.87-15.35-0.88-21.56-6.46
                c-0.53-0.47-1.91,0.08-2.89,0.02c-10.56-0.73-21.24,1.62-31.74-1.43c-1.48-0.43-3.39,0.59-5.09,0.99
                c-2.13,0.5-4.34,1.73-6.35,1.46c-4.11-0.55-8.08-2.03-12.17-2.84c-1.08-0.22-2.36,0.65-3.57,0.92c-5.29,1.2-9.94,5.42-16.19,1.45
                c-3.67-2.34-8.9-2.31-12.64,1.03c-2.39,2.13-4.4,2.23-7.21,1.37c-3.65-1.11-7.29-3.58-11.34-0.87c-0.51,0.34-1.61-0.08-2.4-0.29
                c-4.14-1.1-8.37-1.96-12.38-3.43c-4.39-1.61-8.29-2.76-10.94,2.59c-0.27,0.54-1.55,0.5-2.19,0.96
                c-6.72,4.81-15.53-3.11-21.93,3.61c-0.07,0.07-0.35,0.03-0.48-0.05c-6.08-3.76-12.06,1.49-16.62-0.07
                c-1.43,2.84-1.93,5.38-3.47,6.59c-5.02,3.95-10.2,7.81-15.77,10.87c-2.2,1.21-6.32,1.32-8.29,0.01c-2.69-1.79-4.08-0.86-6.13,0.28
                c-4.19,2.34-8.22,5.9-13.24,1.28c-0.86-0.8-2.9-0.81-4.24-0.5c-3.5,0.79-5.97-0.31-8.47-2.79c-1.28-1.27-3.72-2-5.58-1.91
                c-2.46,0.12-9.78,10.34-9.72,12.88c0.02,0.62,0.16,1.51,0.58,1.79c2.94,1.99,2.46,7.89,8.04,7.01c0.55-0.09,1.25,1.82,2.11,2.51
                c3.27,2.6,6.7,4.46,11.19,2.79c1.21-0.45,2.97,0.02,4.28,0.56c1.5,0.61,2.78,2.57,4.14,2.56c7.54-0.09,8.59,8.01,13.87,10.67
                c0.45,0.23,0.69,1.64,0.47,2.31c-1.97,6.05,3.77,5.97,6.51,8.17c2.4,1.93,4.72,1.71,7.03-0.69c0.85-0.88,2.92-1.38,4.1-1.01
                c3.59,1.15,7.37,2.29,10.4,4.4c3.16,2.21,6.02,1.76,9.36,1.47c2.07-0.18,4.3,0.95,6.38,1.72c0.86,0.32,1.43,1.63,2.25,1.77
                c6.59,1.11,13.21,2.67,19.77-0.24c1.03-0.46,2.45-1.23,3.25-0.87c6.02,2.68,12.13,2.4,18.23,0.68c1.45-0.41,3.29-1.77,3.71-3.09
                c1.34-4.19,7.5-9.73,11.9-9.82c7.26-0.14,14.52-0.11,21.78-0.18c5.92-0.06,9.05-2,8.57-5.8c-0.36-2.82-1.78-5.51-2.75-8.25
                c-0.59-1.69-1.22-3.37-2.16-5.97c6.93,4.1,15.43,6.58,18.63,6.41c2.53-0.13,5.46-0.17,7.59,0.94c6.17,3.19,12.57,0.59,18.79,1.38
                C362.56,648.57,362.91,647.87,363.53,647.18z M854.01,1114.99c0-5.14-0.37-9.8,0.07-14.39c1.12-11.7-0.52-23.05-4.89-33.77
                c-2.54-6.24-3.28-12.45-3.43-18.93c-0.1-4.33,0.02-8.66-0.05-12.99c-0.33-21.12-0.3-42.26-1.15-63.36
                c-0.6-14.77-2.82-29.48-3.55-44.25c-0.44-8.95,0.34-17.98,6.1-25.73c1.06-1.42,1.36-3.76,1.26-5.64
                c-0.49-8.63-1.43-17.24-1.87-25.87c-0.84-16.78-1.61-33.57-2.1-50.37c-1.07-36.44-2-72.89-2.83-109.33
                c-0.09-3.78,0.63-7.63,1.42-11.35c0.64-2.99,1.65-6.34,6.32-2.86c0-2.22-0.03-3.7,0-5.17c0.29-12.65,0.07-25.33,1-37.93
                c1.36-18.58,2.44-37.14,0.99-55.75c-0.5-6.42-1.7-12.79-2.8-20.71c-3.11,4.31-4.21,2.15-5.17-0.05c-0.65,1.08-1.21,2.01-2.47,4.12
                c-1.97-5.97-3.34-10.12-4.71-14.28c-1.38,8.01-2.13,15.88-2.83,23.76c-0.15,1.66-0.15,3.33-0.17,4.99
                c-0.09,7.98-0.99,8.51-8.34,4.89c-3.3-1.63-6.59-3.29-9.97-4.98c-2.25,5.68-1.86,9.12,3.74,11.06c7.31,2.53,11.65,8.06,11.7,15.8
                c0.08,10.45-0.69,20.9-1.05,31.36c-1.38,40.08-2.76,80.16-4.12,120.24c-0.45,13.31,0.45,26.81-1.55,39.88
                c-2.38,15.56-3.71,31.03-3.48,46.7c0.27,18.21-2.14,36.14-5.03,54.09c-3.75,23.31-7.01,46.71-10.05,70.12
                c-2.17,16.65-2.9,33.51-5.6,50.07c-3.11,19.03-7.69,37.81-11.59,56.71c-0.39,1.88-0.52,3.81-0.82,6.07
                c5.33,0.53,10.09,1.21,14.86,1.34c1.17,0.03,3.32-1.65,3.45-2.74c1.67-13.86,2.98-27.76,4.55-41.63
                c1.04-9.22,2.18-18.44,3.63-27.61c0.51-3.22,2.52-6.2,3.04-9.42c3.58-22.16,6.93-44.35,10.41-66.53c0.29-1.86,1.01-3.66,1.52-5.48
                c1.35,1.02,1.7,1.79,1.7,2.56c-0.02,25.62-0.03,51.24-0.24,76.86c-0.01,1.8-1.52,3.57-2.31,5.37c-2.18,4.96-5.71,9.77-6.29,14.92
                c-2.08,18.32-3.21,36.76-4.49,55.16c-0.07,1.08,1.47,2.9,2.64,3.33c10.11,3.68,20.3,7.13,30.48,10.63
                C851,1114.26,852.09,1114.48,854.01,1114.99z M786.65,427.41c-0.72,0.01-1.43,0.02-2.15,0.03c-0.68-2.64-0.52-6.4-2.22-7.68
                c-3.49-2.62-2.62-5.75-2.73-8.88c-0.39-11.81-0.64-23.62-1.07-35.43c-0.06-1.6-0.85-3.17-1.29-4.75
                c-0.64-0.02-1.28-0.04-1.92-0.05c-2.72,12.54-5.43,25.09-8.37,38.65c-0.78-1.6-1.17-2.02-1.17-2.44
                c-0.24-16.14-0.74-32.28-0.48-48.41c0.07-4.73-1.43-6.37-5.61-6.73c-3.64-0.32-7.29-0.62-10.92-1.1
                c-10.37-1.38-20.15-4.05-28.21-11.43c-1.63-1.49-5.09-2.27-7.2-1.66c-4.22,1.24-6.88-0.19-9.89-2.75
                c-1.6-1.36-4.19-1.51-6.29-2.32c-2.5-0.96-4.98-2-7.43-3.08c-2.76-1.21-5.41-3.32-8.23-3.55c-3.83-0.31-6.61-1.55-7.78-5.02
                c-1.62-4.79-4.84-4.27-7.79-2.32c-4.36,2.88-7.3,1.12-10.6-1.7c-2.67-2.28-5.78-4.05-8.4-5.84c-6.75,7.69-12.6,1.33-18.53,0.41
                c-0.05,0.7-0.19,1.21-0.09,1.67c4.67,21.14,9.24,42.3,20.47,61.27c1.98,3.35,3.66,6.03,8.19,5.96c1.89-0.03,4.64,1.21,5.58,2.75
                c3.75,6.15,6.78,12.78,13.15,16.87c0.24,0.15,0.23,0.66,0.46,0.87c1.07,0.95,2.04,2.22,3.31,2.72c21.55,8.39,43.02,17,64.76,24.89
                c14.07,5.11,27.16,11.71,39.01,20.85c13.45,10.37,26.94,20.68,40.46,30.94c2.14,1.63,4.56,2.89,7.51,4.73
                c0.95-4.13,1.92-7.15,2.3-10.23c2.14-16.99,3.73-34.06,6.35-50.97c1.68-10.86-0.46-20.48-6.05-29.58
                c-2.58-4.19-5.65-7.99-10.65-10.05c-1.24,5.42-2.34,10.21-3.44,15c-0.6-0.06-1.19-0.12-1.79-0.18c-0.53-2.65-1.06-5.3-1.51-7.51
                c-1.15-0.07-2.25-0.13-3.21-0.18c-0.89,1.59-1.85,3.3-2.82,5.02c-0.37-0.11-0.74-0.22-1.12-0.32c-1.48-7.6-2.96-15.2-4.44-22.81
                c-0.67,0.07-1.35,0.14-2.02,0.22c-4.23,24.77,0.77,50.17-3.59,74.93c-0.64,0.02-1.27,0.04-1.91,0.06
                c-0.69-10.64-1.38-21.29-2.02-31.19c-1.06,6-2.25,12.71-3.44,19.41c-0.66-0.07-1.32-0.14-1.99-0.21c0-13.61,0-27.22,0-40.82
                c-0.07,0.02-0.14,0.03-0.2,0.05C788.64,406.14,787.64,416.77,786.65,427.41z M79.72,516.35c1.03,3.14,1.08,5.82,2.38,6.79
                c4.13,3.04,2.73,6.05,1.07,9.55c-0.39,0.83,0.17,2.91,0.92,3.36c3.14,1.88,6.63,3.17,9.75,5.08c1.45,0.89,2.34,2.69,3.48,4.08
                c-1.76,1.38-3.38,3.65-5.31,3.96c-3.74,0.6-7.72,2.91-11.55-0.42c-0.87-0.76-4.1-0.56-4.19-0.23c-1.94,6.98-6.92,5.45-11.76,4.47
                c-0.26-0.05-0.59,0.26-0.9,0.39c-4.26,1.7-8.47,3.5-13.16,1.17c-1.09-0.54-2.6-0.44-3.88-0.32c-3.3,0.33-6.86,3.3-9.64-1.17
                c-0.14-0.23-1.47,0.43-2.25,0.43c-7.12,0-14.48,1.07-21.29-0.4c-7.03-1.52-11.44,0.48-11.45,7.91c-0.01,25.64,0,51.29,0,76.93
                c0,4.83,0,9.66,0.02,14.49c0.01,2.68,0.58,4.68,4.08,3.83c1.01-0.25,2.25,0.17,3.32,0.49c3.43,1.01,6.79,2.83,10.25,3.01
                c6.17,0.31,12.61-4.3,18.51,1.46c0.76,0.74,2.91,0.35,4.32,0.06c3.76-0.78,7.25-0.91,10.04,2.37c0.35,0.41,1.19,0.5,1.83,0.57
                c4.86,0.54,9.72,1.04,14.58,1.56c3.02,7.94,7.92,0.2,11.78,1.39c2.12,0.66,4.33,1.08,6.53,1.38c0.86,0.12,1.82-0.34,2.71-0.63
                c3.16-1.02,6.31-2.09,10.29-3.41c0.02-0.15-0.12-1.9,0.46-3.36c2-4.98,1.03-8.42-3.54-11.8c-1.73-1.28-1.83-5.79-1.4-8.63
                c0.41-2.75,1.92-5.8,5.95-5.49c0.75,0.06,1.6-0.75,2.34-1.27c2.22-1.55,4.29-4.27,6.63-4.51c4.73-0.49,7.77-1.83,10.6-6.13
                c2.22-3.38,1.06-4.19-1.12-5.75c-1.03-0.73-2.08-1.44-3.14-2.18c0.71-1.63,1.25-2.87,1.59-3.66c-6.63-4.37-14.89-4.22-20.36-10.32
                c-0.99-1.1-4.14-0.49-6.26-0.3c-2.07,0.18-4.1,1.18-6.15,1.19c-3.73,0.03-7.46-0.58-11.19-0.61c-4.5-0.04-8.61-0.97-11.62-4.51
                c-1.06-1.25-1.52-3-2.26-4.52c1.78-0.34,3.54-0.9,5.33-0.98c2.22-0.09,4.59,0.78,6.65,0.27c7.51-1.89,15.07-3.88,19.84-11.04
                c4.29,3.49,6.44,2.29,8.88-1.89c1.4-2.4,5.05-3.94,8.01-4.87c5.37-1.7,10.38-4.96,16.56-3.8c2.87,0.54,5.97-0.19,8.97-0.28
                c1.42-0.04,3.23,0.64,4.19,0c3.93-2.63,9.53,1.5,12.86-3.31c0.14-0.21,1.48,0.45,2.28,0.64c1.08,0.25,2.29,0.86,3.24,0.59
                c7.12-2.01,14.2-4.2,21.25-6.33c-2.49-8.97-2.49-8.92-11.09-9.45c-1.24-0.08-2.41-0.98-3.64-1.44c-1.63-0.61-3.23-1.38-4.92-1.72
                c-5.89-1.16-11.77,2.24-18.08-2.07c-4.5-3.08-11.33-2.67-15.34-7.88c-0.96-1.25-3.07-1.71-4.73-2.31
                c-3.47-1.25-7.37-1.75-10.42-3.65c-6.82-4.25-13.02-9.48-19.88-13.66C87.71,517.64,83.73,517.43,79.72,516.35z M2.32,203.01
                c0,31.66,0,64.14,0,95.12c7.15,3.6,13.17,7.32,19.68,9.7c5.74,2.1,12.03,3.57,18.08,3.57c3,0,5.92-3.98,9.37-0.11
                c0.37,0.41,2.39-0.56,3.59-1.02c2.59-0.97,5.22-1.89,7.71-3.09c2.52-1.22,4.87-2.8,7.33-4.15c2.39-1.31,5.42-4.13,7.16-3.5
                c8.03,2.91,13.13-2.25,17.98-6.35c4.97-4.21,11.31-5.84,15.96-10.96c4.8-5.29,12.86-5.75,19.43-3.15
                c3.74,1.48,10.36,0.46,10.84,7.46c0.03,0.47,2.27,1.26,3.33,1.08c1.25-0.21,3.11-1.12,3.38-2.09c1.41-5.18,4.84-7.02,9.79-6.52
                c3.11,0.32,4.24-1.11,4.34-3.95c0.24-6.4-0.64-12.36-3.83-18.24c-5.85-10.78-10.9-22-16.37-32.99c-0.62-1.25-1.65-2.89-2.79-3.18
                c-4.41-1.14-8.92-2.11-13.44-2.59c-6-0.64-12.06-0.71-18.08-1.14c-3.54-0.25-7.08-0.62-10.58-1.16c-2.87-0.44-6.25-0.44-8.41-2.01
                c-3.66-2.65-6.94-2.03-10.77-1.2c-2.58,0.56-6.17,1.14-7.94-0.16c-5.3-3.89-11.35-4.92-17.4-6.35c-3.47-0.82-6.91-2.56-10.35-2.55
                c-3.06,0.01-4.89-0.26-6.45-3.11c-0.56-1.01-3.76-1.86-4.53-1.25c-3.26,2.61-6.55,2.65-10.3,1.7c-1.68-0.43-3.59-0.31-5.34-0.04
                C10.06,201.36,6.45,202.19,2.32,203.01z M702.94,662.43c-0.77-0.04-1.54-0.09-2.31-0.13c-2.21-15.21-4.42-30.42-6.74-46.37
                c-5.03,13.35-9.76,25.88-14.74,39.09c-2.52-3.16,2.5-11.78-7.43-7.36c-3.15-15.65-6.25-31.1-9.5-47.25
                c-3.74,5.69-7.05,10.73-11.12,16.92c0-7.48,0-14.17,0-20.86c-0.57-0.01-1.14-0.02-1.72-0.04c-1.35,8.66-2.57,17.35-4.11,25.98
                c-1.03,5.79,0.37,12.43-5.55,16.71c-0.9,0.65-1.12,2.36-1.44,3.64c-1.09,4.35-2,8.75-3.12,13.09c-1.21,4.66-2.58,9.28-3.88,13.92
                c-0.49-0.09-0.99-0.19-1.48-0.28c-0.09-1.04-0.32-2.09-0.26-3.13c0.32-4.97,1.25-9.97,0.96-14.91c-0.37-6.38-0.9-13.11-9.26-14.94
                c-1.07-0.23-2.07-2.43-2.44-3.89c-0.61-2.4-0.35-5.03-1.08-7.38c-0.45-1.43-1.86-3.33-3.15-3.62
                c-15.04-3.42-26.43,8.65-22.52,23.65c0.4,1.53,0.77,3.06,1.19,4.71c-9.75,0.46-9.62,0.48-11.14,10.17
                c-0.23,1.46-2.12,3.34-3.62,3.8c-5.05,1.54-7.25,5.26-6.74,9.81c0.53,4.74-2.61,5.2-5.38,6.23c-3.06,1.14-6.3,1.83-10.64,3.04
                c-9.44-5.5-16.45-2.72-17.3,8.62c-1.29-0.47-2.47-1.02-3.7-1.33c-7.2-1.76-14.24-2.03-20.2,3.33c-0.98,0.88-1.96,3.3-1.47,4.01
                c2.48,3.65,0.03,4.94-2.59,5.82c-2.81,0.93-5.79,1.39-8.73,1.88c-5.58,0.93-11.18,1.72-15.31,2.35c24.78,0,50.91,0.01,77.03-0.03
                c1.28,0,3.18-0.07,3.75-0.86c2.77-3.83,6.68-3.16,10.4-2.99c6.31,0.28,12.61,0.84,18.92,1.21c27.22,1.58,54.44,3.09,81.66,4.75
                c4.12,0.25,8.21,1.08,12.29,1.76c10.32,1.72,20.61,3.56,30.94,5.23c4.77,0.77,6.77-1.44,6.3-6.23
                c-1.14-11.41-1.93-22.85-3.34-34.22c-1.12-8.98-2.95-17.86-4.46-26.79c-2.95,9.15-0.75,18.99-5.33,28.24
                c-0.91-1.07-1.39-1.39-1.5-1.8c-0.24-0.96-0.46-1.97-0.39-2.94c0.75-10.31-1.79-19.82-7.18-28.72
                C704.64,650.36,703.79,656.4,702.94,662.43z M279.78,1497.66c2.6,0.25,3.86,0.48,5.12,0.48c96.37,0.02,192.75,0.03,289.12-0.1
                c2.58,0,5.7-1.03,7.64-2.67c11.65-9.89,24.75-17.37,38.31-24.2c4.58-2.31,9.18-4.83,13.18-8c7.65-6.06,14.87-12.65,22.31-19.06
                c-0.96-0.07-2.21-0.15-3.46-0.25c-1.33-0.1-2.65-0.19-3.98-0.34c-27.25-2.96-54.39-6.84-81.76,0.18
                c-11.48,2.95-23.63,3.23-35.4,5.2c-5.72,0.96-10.97,0.56-16.65-0.72c-7.17-1.62-14.88-2.72-22.1-1.88
                c-15.02,1.75-29.82,2.05-44.67-0.96c-2.44-0.49-5.02-0.28-8.65-0.44c6.72,7.46,14,11.61,22.27,13.04
                c11.59,2.01,23.33,3.49,35.07,4.16c12.78,0.72,25.68-0.44,38.42,0.56c8.12,0.64,15.43-0.03,22.68-3.6
                c19.78-9.73,40.54-10.92,61.84-6.59c1.82,0.37,3.57,1.07,5.35,1.62c0,0.6-0.01,1.21-0.01,1.81c-17.59,2.97-35.18,5.94-52.77,8.91
                c-0.03,0.57-0.06,1.14-0.09,1.71c3.36,1.63,6.71,3.25,10.07,4.88c-2.17,1.89-4.13,2.96-6.14,3.1c-7.78,0.51-15.65,1.57-23.35,0.88
                c-20.79-1.84-41.37-2.12-62.16,0.83c-24.38,3.46-47.4-0.5-67.12-16.99c-6.82-5.71-15.3-7.82-24.12-8.44
                c-9.74-0.69-18.52,1.83-27.35,6.06c-14.79,7.1-29.7,14.41-45.3,19.24c-13.97,4.33-27.28,9.45-39.76,16.93
                C284.4,1494.17,282.65,1495.61,279.78,1497.66z M480.56,841.05c14.61,0,28.55-0.36,42.47,0.12c10.93,0.38,21.87,1.54,32.73,2.9
                c3.94,0.49,6.58-0.33,9.36-2.99c1.59-1.52,4.14-2.08,6.3-2.93c2.09-0.82,4.49-1.09,6.32-2.28c3.93-2.54,7.3-6.05,11.42-8.11
                c3.03-1.52,7.61-2.9,10.25-1.66c5.48,2.56,8.76-1.05,12.83-2.56c2.16-0.8,4.14-2.09,6.26-2.99c2.87-1.22,5.24-1,7.44,1.81
                c2.41,3.08,4.68,2.83,6.89-0.89c1.19-2,3.65-4.08,5.85-4.5c6.22-1.18,12.61-1.43,18.91-2.24c7.02-0.89,14.64-0.81,17.08-9.88
                c0.24-0.89,2.08-2,3.11-1.94c8.77,0.51,14.33-6.96,22.14-8.79c0.68-0.16,1.4-1.47,1.54-2.33c0.68-4.19,3.31-4.5,6.93-4.5
                c1.98,0,5.39-1.7,5.69-3.14c1.25-6.04,4.7-6.62,9.56-5.05c4.14,1.33,6.72,0.18,7.76-4.26c1.03-4.42,3.76-5.29,7.94-3.83
                c1.8,0.63,4.01,0.14,6.04,0.06c5.18-0.19,10.54-1.35,15.5-0.39c4.75,0.92,8.8,0.74,11.64-3.56c2.48-3.76,0.39-6.89-2.19-9.27
                c-2.39-2.2-5.3-4.1-8.31-5.32c-6.88-2.78-12.52,0.2-14.84,7.29c-0.51,1.56-1.14,3.08-2.11,5.65c-1.25-2.35-1.88-3.48-2.46-4.63
                c-3.05-6.03-4.61-6.16-9.39-1.33c-0.71,0.71-2.92,0.28-4.3-0.11c-1.28-0.37-2.28-1.66-3.56-2.08c-1.95-0.64-4.23-0.47-6.01-1.36
                c-3.45-1.72-6.26-2.31-8.3,1.84c-0.39,0.79-1.2,1.38-2.06,2.33c-0.94-1.23-1.76-2.04-2.28-3.02c-3.21-6.04-8.16-10.18-14.54-12.23
                c-5.5-1.77-11.18-3.51-16.89-3.97c-12.07-0.99-24.3-1.99-35.67,4.05c-5.94,3.15-10.16,7.41-10.54,14.59
                c-0.08,1.48-0.78,2.94-1.6,5.85c-1.74-2.79-2.47-4.44-3.64-5.69c-1.15-1.23-2.72-2.84-4.16-2.9c-7.57-0.3-13.39,4.87-14.4,12.42
                c-0.41,3.05-0.56,6.12-0.85,9.41c-0.89-0.28-1.41-0.31-1.76-0.58c-7.44-5.57-7.89-5.31-9.46,4.11c-0.21,1.25-2.2,3.24-3.2,3.15
                c-5.67-0.54-6.97,2.53-6.9,7.25c0.02,1.02-0.44,2.05-0.83,3.71c-7.35-5.56-14.87-9.29-22.54-2.68
                c-6.14,5.29-11.75,11.42-9.85,21.61c-2.5-1.24-4.05-2.08-5.66-2.8c-7.46-3.35-14.44,0.07-15.88,7.9
                c-0.55,2.98-2.11,3.75-4.54,2.39c-4.7-2.64-6.97-0.28-8.89,3.73c-1.89,3.94-9.13,7.68-13.24,6.48
                C492.67,830.25,486.3,833.98,480.56,841.05z M346.84,3.55c-0.07-0.4-0.14-0.81-0.21-1.21c-1.26-0.13-2.52-0.36-3.78-0.36
                c-111.7-0.02-223.4,0-335.1-0.08c-4.25,0-5.95,1.28-5.88,5.71c0.2,13.82,0.17,27.64,0.02,41.46c-0.05,4.38,2.05,5.91,6.02,6.11
                c5.08,0.26,10.21,0.34,15.2,1.18c3.47,0.59,6.85,2.11,10.08,3.61c4.61,2.14,9.07,4.64,13.57,7.02c0.7,0.37,1.31,0.94,1.93,1.45
                c1.44,1.17,2.86,2.36,4.29,3.55c0.45-1.84,1.44-3.73,1.25-5.5c-0.66-6.17,1.1-7.89,7.2-7.33c1.85,0.17,4.41-0.78,5.64-2.14
                c1.97-2.17,2.48-5.96,4.69-7.56c6.08-4.39,10.76-11.12,19.18-11.95c1.74-0.17,3.26-2.12,4.99-3.05c1.78-0.95,3.63-2.17,5.55-2.35
                c6.07-0.59,12.8,1.38,17.13-5.14c0.34-0.51,1.53-0.44,2.31-0.69c2.96-0.94,5.9-1.96,8.88-2.83c1.71-0.5,3.47-0.95,5.24-1.12
                c6.69-0.64,13.43-0.9,20.08-1.79c5.37-0.71,10.44-1.19,15.47,1.67c1.58,0.9,4.06,0.4,6.11,0.26c1.6-0.11,3.16-0.74,4.75-1.01
                c6.44-1.08,13.24-1.19,19.27-3.39c8.66-3.16,16.51,1.46,24.77,0.95c0.68-0.04,1.79,0.64,2.05,1.26c1.67,3.98,5.43,4.3,8.81,5.43
                c1.69,0.57,3.3,1.48,4.82,2.44c4.36,2.74,8.7,5.51,12.93,8.45c1.11,0.77,1.78,2.2,2.63,3.34c0.66,0.89,1.23,2.56,1.91,2.6
                c4.83,0.28,11.28,2.21,14.18-0.14c6.57-5.32,16.64-2.64,21.53-10.53c0.19-0.31,1-0.51,1.38-0.37c4.81,1.75,7.21-1.75,10.1-4.41
                c5.07-4.66,8.45-12.16,17.42-10.49c0.69,0.13,1.62-1.03,2.43-1.61c3.16-2.22,6.13-4.78,9.51-6.58
                C338.87,6.44,342.94,5.14,346.84,3.55z M1144.11,875.76c-0.13,3.02-0.3,4.65-0.26,6.28c0.27,11.14,0.69,22.28,0.86,33.43
                c0.38,24.47,0.79,48.94,0.92,73.42c0.08,15.42,1.46,30.99-3.83,46c-0.65,1.85-0.85,3.92-0.94,5.9
                c-1.08,23.24-2.29,46.48-3.07,69.73c-0.61,18.47-0.55,36.95-1.03,55.43c-0.18,6.97-0.98,13.93-1.64,20.89
                c-1.13,11.89-2.37,23.76-3.67,36.62c2.03-3.85,3.54-6.74,5.6-10.66c2.09,2.79,3.67,4.62,4.94,6.65
                c6.37,10.13,7.77,21.52,7.71,33.09c-0.06,10.6,7.99,17.53,11.45,26.59c0-1.82,0-3.64,0-5.46c0-221.32,0-442.64,0-663.95
                c0-1.83,0.16-3.69-0.09-5.49c-0.2-1.41-0.59-3.03-1.47-4.08c-3.78-4.54-7.76-8.9-12.06-13.76c0.59,6.98,1.34,13.7,1.7,20.44
                c0.81,14.94,1.82,29.89,2.03,44.84c0.24,16.63-0.81,33.28-0.4,49.91c0.39,15.9,2.99,31.61,2.28,47.73
                c-1.19,26.76-0.5,53.61-0.32,80.41c0.13,20.14,0.72,40.27,1.05,60.41c0.03,2.01-0.3,4.02-0.46,6.03c-0.58,0.1-1.17,0.2-1.75,0.29
                C1149.32,890.02,1146.98,883.6,1144.11,875.76z M282.89,676.15c-7.56,0.4-14.37,0.67-21.17,1.19c-1.22,0.09-3.01,0.9-3.43,1.86
                c-4.34,9.92-12.01,17.17-19.62,24.34c-6.82,6.42-9.65,14.28-9.84,23.21c-0.5,23.46-0.67,46.92-1.18,70.38
                c-0.1,4.38,1.89,6.42,5.69,7.46c1.44,0.4,2.89,0.8,4.33,1.18c4.22,1.11,6.78,3.28,7.44,8.12c0.43,3.18,2.86,6.05,4.21,9.14
                c1.65,3.79,4.23,7.64,4.33,11.52c0.12,4.66,4.05,13.86,8.8,14.01c5.92,0.19,8.38,4.83,12.43,7.38c1.53,0.96,2.73,2.45,4.26,3.44
                c1.96,1.26,3.63,1,3.79-1.72c0.68-11.62,1.85-23.25,1.77-34.87c-0.14-19.47-1.42-38.92-1.64-58.39
                c-0.32-27.8-0.12-55.61-0.14-83.42C282.89,679.21,282.89,677.46,282.89,676.15z M861.7,593.96
                c-10.56,47.79-9.77,97.21-9.39,147.54c4.27-0.62,7.88-0.72,11.19-1.77c2.82-0.89,5.18-3.83,7.96-4.2
                c7.74-1.01,13.94-7.97,22.45-5.65c0.4,0.11,0.94-0.25,1.41-0.41c7.65-2.67,11.3-0.98,15.61,5.73c2.29,3.57,5.88,6.32,8.89,9.44
                c0.55-0.35,1.09-0.7,1.64-1.05c0-1.95,0.07-3.9-0.01-5.85c-0.62-14.96-1.92-29.92-1.75-44.87c0.28-23.28,1.62-46.55,2.38-69.83
                c0.23-7.19,0.04-14.39,0.04-21.59c-0.63-0.04-1.25-0.08-1.88-0.13c-1.06,3.62-2.45,7.19-3.12,10.89
                c-1.86,10.3-3.31,20.68-5.18,30.98c-0.51,2.82-1.96,5.47-2.97,8.21c-0.62-0.14-1.24-0.27-1.85-0.41
                c-1.89-17.63,0.97-35.56-2.68-53.29c-1.94,2.83-3.08,5.86-4.48,8.75c-0.3,0.61-1.6,0.73-2.44,1.07c-0.04-0.16-0.09-0.32-0.13-0.48
                c-0.1,0.05-0.19,0.09-0.2,0.1c-1.69,10.39-3.26,20.74-5.11,31.05c-0.42,2.31-1.78,4.46-2.7,6.68c-0.5-0.17-1-0.35-1.5-0.52
                c0-15.83,0-31.66,0-48.83c-4.02,15.93-7.69,30.46-11.35,45c-0.55-0.07-1.09-0.13-1.64-0.2c-0.21-2.09-0.42-4.19-0.61-6.06
                c-1.29,3.72-2.64,7.59-3.99,11.47c-0.45-0.11-0.91-0.22-1.36-0.33c-0.18-1.07-0.49-2.14-0.52-3.22
                c-0.33-11.31-0.5-22.62-1.01-33.93c-0.14-3.06-0.97-6.2-2.09-9.06C864.41,597.03,862.6,595.23,861.7,593.96z M336.01,1021.88
                c-4.15-17.77-8.56-35.82-12.57-53.96c-7.16-32.37-11.57-65.16-14.27-98.17c-2.06-25.19-4.26-50.41-5.18-75.66
                c-1.09-30.08-1.03-60.21-1.16-90.32c-0.05-12.14,0.8-24.28,0.99-36.42c0.02-1.6-1.36-4.53-2.29-4.61
                c-3.32-0.28-6.98-0.26-10.03,0.91c-2.71,1.04-5.99,2.16-5.92,6.82c0.56,41.28-0.13,82.6,1.51,123.82
                c1.38,34.61-0.79,68.87-5.18,103.03c-2.94,22.85-3.36,45.29,3.16,66.19c0.62-5.41,1.4-12.17,2.18-18.92
                c0.65-0.02,1.29-0.05,1.94-0.07c0.44,1.65,1.06,3.28,1.29,4.95c1.11,8.07,1.93,16.18,3.19,24.22c2.11,13.46,4.37,26.9,6.87,40.3
                c0.31,1.65,2.28,4.06,3.73,4.27C315.11,1019.77,326,1020.8,336.01,1021.88z M943.39,307.48c-0.51,1.53-0.93,2.29-1.01,3.09
                c-1.81,18.03-4.4,36.04-5.14,54.11c-0.89,21.59-0.18,43.24-0.18,64.87c0.01,19.14-0.34,38.28,0.21,57.41
                c0.16,5.52,2.69,11.03,4.58,16.38c0.5,1.41,2.69,2.22,4.75,3.8c0-2.69,0-4.13,0-5.86c1.19-0.06,2.16-0.26,3.09-0.14
                c5.32,0.65,8.83,3.3,11.19,8.39c2.04,4.41,4.75,4.13,7.09-0.11c0.7-1.27,1.17-2.77,1.31-4.21c0.45-4.64,0.8-9.29,0.99-13.94
                c2.33-56.7,4.63-113.4,6.94-170.09c0.2-4.98-4.58-8.74-9.26-7.25c-5.42,1.73-9.43-1.57-9.13-7.18c0.05-0.86-1.92-2.68-2.71-2.55
                c-1.69,0.27-3.78,1.13-4.72,2.45C949.12,309.86,946.69,309.8,943.39,307.48z M676.99,406.15c-1.48,3.97-1.31,6.33,2.74,7.95
                c12.82,5.12,25.5,10.59,38.25,15.88c37.16,15.42,71.17,35.28,97.15,67.04c4.4,5.37,7.77,12.55,16.05,14.21
                c-1.17,0.93-2.28,1.78-3.38,1.77c-3.58-0.02-5.19,1.51-4.86,5.11c0.26,2.87-0.65,6.66,0.85,8.44c4.27,5.06,9.8,8.73,16.53,10.31
                c2.66,0.63,5.09,2.2,7.65,3.3c1.59,0.68,3.22,1.28,4.84,1.91c0.56-1.8,1.45-3.56,1.63-5.4c1.07-10.92,1-22.04,3.11-32.74
                c6.11-31.04,8.8-63.17,26.47-90.86c0.09-0.14,0.18-0.28,0.25-0.43c1.92-4.49-2.45-6.06-4.38-8.58c-0.42-0.56-3.84,0.33-5.14,1.43
                c-6.76,5.69-12.97,4.82-15.84-3.51c-1.88-5.47-2.9-11.9-1.99-17.53c1.28-7.92-3.45-12.09-7.4-17.96
                c-0.57,2.08-0.92,3.36-1.27,4.63c-1.61,5.98-4.51,7.71-10.28,5.57c-2.76-1.02-5.27-2.77-7.8-4.33c-1.89-1.16-3.65-2.55-5.61-3.94
                c-2.61,6.92-1.93,11.39,1.59,14.16c13.3,10.46,18.72,24.12,16.51,40.88c-1.3,9.89-2.47,19.79-3.78,29.67
                c-1.24,9.38-2.19,18.82-4.01,28.09c-1.53,7.8-3.01,8.3-9.39,3.73c-10.55-7.56-20.91-15.37-31.19-23.3
                c-11.99-9.25-23.55-19.2-37.79-24.93c-24.07-9.69-48.26-19.08-72.43-28.54C681.84,407.31,679.44,406.84,676.99,406.15z
                M130.88,874.33c0.91-0.04,1.83-0.07,2.74-0.11c2.88,47.03,5.75,94.07,8.57,140.13c12.57-0.67,23.79-1.15,34.99-1.91
                c5.05-0.34,5.73-2.46,3.99-7.48c-3.91-11.29-8.19-22.52-10.98-34.1c-5.92-24.56-10.59-49.43-16.54-73.98
                c-4.89-20.17-7.18-40.65-8.29-61.22c-1.1-20.43-0.79-40.93-1.11-61.4c-0.07-4.66-0.09-9.32-0.28-13.98
                c-0.87-20.93-1.91-41.85-2.66-62.79c-0.29-8.25-0.27-8.34-8.35-8.16c-3.91,0.09-5.37,1.63-5.26,5.41
                c0.06,1.97-0.56,3.95-0.71,5.93c-0.35,4.55-3.11,5.14-6.09,2.95c-3.69-2.72-6.8-6.22-10.1-9.45c-1.17-1.14-2.15-2.48-3.22-3.73
                c-0.6,1.52-0.39,2.48,0.18,3.11c4.04,4.4,7.74,9.27,12.37,12.94c5.16,4.09,6.76,8.88,6.76,15.15c0,13.3,0.5,26.6,0.81,39.91
                c0.01,0.24,0.22,0.47,0.3,0.63c0.52-0.55,1.05-1.11,2.04-2.15c0.33,1.72,0.71,2.79,0.71,3.87c-0.01,23.14,0.02,46.29-0.18,69.43
                c-0.05,5.8-0.59,11.63-1.41,17.37c-1.23,8.65-3.79,17.19-4.14,25.85c-0.38,9.53,1.77,19.14,1.63,28.7
                c-0.24,16.41-1.45,32.67-4.84,48.96c-3.01,14.43-2.53,29.58-3.57,44.05c6.71-5.45,7.17-13.06,7.2-21.24
                c0.05-12.63,0.78-25.27,1.64-37.88c1.04-15.37,2.65-30.7,3.72-46.06C131.24,886.88,130.88,880.59,130.88,874.33z M265.98,998.36
                c0.58-0.06,1.16-0.13,1.75-0.19c0.42-2.24,0.98-4.46,1.23-6.72c1.15-10.24,2.21-20.48,3.31-30.73
                c3.21-30.07,6.38-60.15,9.67-90.22c0.46-4.23-0.49-7.2-4.48-9.41c-3.14-1.74-5.63-4.59-8.64-6.61c-1.59-1.07-3.66-1.41-5.52-2.08
                c-2.49-0.66-5.17-1.95-6.43-4.07c-2.56-4.32-5.94-9.07-5.99-13.68c-0.05-4.14-0.9-7.57-3.38-9.71c-3.71-3.2-4.43-7.11-5.38-11.32
                c-0.36-1.58-1.39-3.66-2.69-4.24c-3.36-1.5-7.04-2.3-10.9-3.47c0,2.06-0.08,3.38,0.01,4.69c1.34,20.23,3.16,40.44,3.93,60.69
                c0.94,24.85,4.77,49.28,9.52,73.59c1.33,6.78,2.68,13.81,8.84,18.47c7.02,5.31,10.43,12.69,11.96,21.19
                C263.62,989.19,264.9,993.75,265.98,998.36z M939.13,683.47c0,1.65-0.15,3.31,0.02,4.94c2.46,23.98,5.36,47.92,7.33,71.94
                c1.26,15.38,1.5,30.88,1.56,46.32c0.03,8.44-1.96,16.9-1.87,25.34c0.19,19.79,1.19,39.57,1.66,59.36
                c0.53,22.46,0.83,44.93,1.23,67.39c0.03,1.5-0.28,3.11,0.17,4.46c0.41,1.22,1.65,2.16,2.53,3.23c0.81-1.05,2.18-2.03,2.31-3.16
                c0.48-3.95,0.36-7.98,0.79-11.94c2.17-20.31,4.43-40.62,6.68-60.92c0.4-3.59,0.89-7.17,1.34-10.76c0.51,0.03,1.01,0.06,1.52,0.1
                c0.26,1.23,0.69,2.45,0.74,3.68c0.88,21.43,1.7,42.87,2.59,64.31c0.07,1.74,0.04,4.3,1.08,5.04c3.7,2.62,7.83,4.61,11.8,6.83
                c0.65-0.34,1.31-0.67,1.96-1.01c-4.19-63.82-8.38-127.63-12.49-190.35c-2.02,18.88-4.16,38.86-6.3,58.84
                c-0.67,0.03-1.34,0.05-2.01,0.08c-0.42-1.44-1.12-2.87-1.23-4.34c-1.43-19.06-3.21-38.11-4.01-57.2
                c-0.83-19.94-0.64-39.93-1.05-59.89c-0.04-1.97-0.59-4.95-1.91-5.72C946.84,696.14,943.05,690.05,939.13,683.47z M850.4,261.94
                c-1.4,5.87-2.96,11.44-4.04,17.1c-3.29,17.2-2.88,34.38,7.22,49.14c12.95,18.91,27.37,36.81,41.27,55.05
                c0.7,0.91,3.08,1.79,3.66,1.35c5.82-4.43,12.16-9.04,12.83-16.91c1.74-20.55,2.59-41.18,4.37-61.73c0.48-5.55-2.04-7.9-6.3-8.9
                c-4.43-1.04-6.91-3.13-8.14-7.58c-0.72-2.6-2.8-5.42-6.23-2.96c-6.35,4.56-18.3-0.24-19.73-8.02c-0.49-2.64-1.4-5.2-2.13-7.86
                C864.11,273.9,861.95,273.04,850.4,261.94z M498.36,947.05c1.08-3.79,1.74-7.42,3.18-10.7c0.91-2.08,2.94-3.69,4.55-5.44
                c1.2-1.3,2.67-2.37,3.77-3.74c1.42-1.77,3.44-3.62,3.71-5.63c1.08-8.08,2.28-9.21,10.38-8.17c3.1,0.4,5.19-0.05,4.52-4.24
                c-0.26-1.64,0.88-4.44,2.24-5.21c4.49-2.56,9.36-4.46,14.13-6.53c0.7-0.3,1.61-0.22,2.41-0.19c4.64,0.18,9.19,0.87,13.52-2.26
                c1.12-0.81,4.54,0.85,6.45,2.08c1.46,0.93,2.21,2.97,2.86,3.92c2.13-1.31,4.09-2.38,5.89-3.68c1.65-1.2,3.96-2.37,4.52-4.04
                c1.75-5.18,7.76-8.23,7.14-14.52c-0.04-0.38,0.54-0.8,0.81-1.22c1.99-3.15,4.05-6.26,5.92-9.47c2.2-3.77,4.07-7.82,9.39-7.75
                c3.99,0.06,10.96-5.74,13.21-9.13c2.32-3.5,6.13-8.69,9.1-8.58c7.84,0.31,9.6-5.5,12.29-9.87c3.97-6.45,4.95-7.53,12.57-6.17
                c3.15,0.56,5.51,0.37,6.08-3.72c0.17-1.24,2.22-2.3,3.53-3.26c0.77-0.56,1.8-0.78,2.71-1.15c-0.88-1.07-1.62-1.36-2.3-1.25
                c-4.75,0.72-9.11,2.57-14.37,1.97c-3.4-0.39-8.76-0.59-11.45,4.12c-2.98,5.21-6.41,5.44-11.33,1.65
                c-1.37-1.05-3.79-1.29-5.61-1.04c-1.69,0.23-3.16,1.78-4.84,2.45c-3.56,1.41-7.4,4.07-10.76,3.59
                c-5.36-0.77-10.27-0.26-14.57,2.26c-4.77,2.79-8.85,6.76-13.74,10.62c-1.01-0.27-3.8-2-5.2-1.22c-3.69,2.05-8.05,4.55-9.79,8.04
                c-2.65,5.33-5.19,9.22-11.7,8.67c-0.14-0.01-0.3,0.13-0.44,0.23c-2.81,1.91-5.4,4.91-8.47,5.52c-6.89,1.36-7.32,6.29-8.58,11.54
                c-0.73,3.04-2.78,5.99-4.94,8.34c-0.82,0.9-3.62-0.23-5.45,0.04c-1.38,0.21-2.84,0.93-3.91,1.85c-2.95,2.53-5.39,5.79-8.62,7.84
                c-5.33,3.38-8.62,10.7-7.06,16.94c0.72,2.85,2.19,7.61,1.03,8.48c-5.04,3.82-4.61,9.33-6.04,14.3c-0.13,0.44-0.66,0.76-0.89,1.19
                C491.46,939.72,493.4,945.01,498.36,947.05z M418.06,360.1c3.96-13.91,7.26-28.2,12.18-41.91c3.98-11.08,1.39-20.17-3.82-29.82
                c-3.72-6.89-6.37-14.47-8.64-22c-1.77-5.84-7.16-10.47-13.33-9.75c-2.47,0.29-4.93,0.96-7.39,0.9c-3.3-0.08-5.07,1.25-6.53,4.18
                c-5.04,10.11-12.09,18.14-21.46,24.99c-7.11,5.19-11.91,13.58-17.57,20.68c-1.45,1.82-1.57,3.5,1.2,5.17
                c4.31,2.59,9.14,5.44,6.41,12.51c3.78,0.83,6.94,1.74,10.16,2.17c5.12,0.69,6.73,4.61,7.81,8.62c0.39,1.46-0.92,3.47-1.68,5.12
                c-0.78,1.7-1.83,3.28-2.76,4.91c2.02,1.05,3.95,2.71,6.08,3.02c4.85,0.71,9.98,0.03,14.65,1.24c6.39,1.66,12.47,4.5,18.64,6.95
                C414.11,357.9,416.08,359.09,418.06,360.1z M879.03,1125.15c0.1-2.02,0.27-2.98,0.18-3.93c-1.25-14.08-2.45-28.16-3.83-42.23
                c-1.82-18.53-3.57-37.07-5.77-55.56c-2.12-17.83-5.27-35.54-7.05-53.4c-2.64-26.47-4.35-53.03-6.67-79.53
                c-1.97-22.53-5.46-45-6.08-67.56c-1.08-39.09-0.43-78.23-0.64-117.35c-0.01-2.31-1.16-4.62-1.77-6.93
                c-0.77,0.09-1.54,0.18-2.31,0.28c-0.28,7.73-0.96,15.47-0.78,23.18c0.66,27.95,1.56,55.9,2.47,83.84
                c0.65,19.79,1.37,39.58,2.25,59.36c0.5,11.13,1.47,22.23,2,33.36c0.08,1.77-0.82,3.7-1.63,5.38c-1.25,2.6-3.81,4.96-4.06,7.59
                c-0.8,8.41-1.77,16.99-0.91,25.32c3.03,29.16,5.02,58.34,4.44,87.68c-0.21,10.32-0.09,20.65,0.09,30.97
                c0.04,2.42,0.52,5,1.46,7.22c4.52,10.74,6.97,21.83,6.63,33.51c-0.15,4.99-0.41,10.01-0.07,14.97c0.12,1.75,1.49,3.98,2.98,4.96
                c3.44,2.26,7.25,4,11,5.75C873.4,1123.2,875.98,1124,879.03,1125.15z M1128.17,1436.71c5.54-1.26,4.97,1.95,3.58,4.82
                c-6.67,13.81-10.94,28.92-22.96,39.81c-4.88,4.42-8.42,10.3-12.57,15.52c0.36,0.42,0.73,0.84,1.09,1.26
                c5.71-0.95,12.17,2.69,17.03-3.43c11.64-14.7,22.39-29.86,25.92-48.89c0.38-2.03,1.64-3.89,2.5-5.82
                c1.52,1.07,1.98,1.81,1.95,2.54c-0.23,4.48-0.68,8.95-0.85,13.43c-0.39,10.44-0.42,20.89-1.04,31.31
                c-0.64,10.93-0.86,10.92,10.15,10.91c0.67,0,1.34-0.08,2,0.01c4.86,0.64,6.28-1.52,6.27-6.29c-0.13-56.79,0.24-113.58-0.45-170.35
                c-0.1-8.16-4.83-16.37-8-24.32c-0.8-2.01-3.87-3.11-6.59-5.16c-0.57,2.93-1.2,4.69-1.2,6.44c0.02,8.32-0.19,16.67,0.41,24.95
                c1.85,25.39,1.06,50.59-5.48,75.3C1136.57,1411.42,1132.19,1423.83,1128.17,1436.71z M855.17,619.55
                c1.25-9.96,2.71-21.59,4.26-33.99c14.4,14.49,9.74,32.01,11.94,48.23c1.51-7.2,3.02-14.4,4.54-21.61c0.59,0.07,1.18,0.14,1.77,0.2
                c0.2,2.64,0.4,5.28,0.6,7.92c1.08-10.58,4.47-20.4,9.98-29.89c1.57,4.79,2.4,9.56,2.52,14.34c0.13,4.97-0.53,9.96-0.66,14.95
                c-0.13,4.78-0.03,9.57-0.03,14.36c0.14,0,0.28,0,0.41,0c2.06-13.77,4.11-27.54,6.17-41.3c0.55-0.02,1.1-0.04,1.65-0.06
                c0.75,1.65,1.5,3.3,2.01,4.41c1.6-0.89,3.23-1.79,4.85-2.7c0.92,1.7,2.32,3.32,2.67,5.13c0.67,3.41,0.99,6.93,1.03,10.41
                c0.12,11.08,0.04,22.17,0.04,33.26c0.9-1.73,1.13-3.53,1.49-5.29c2.5-12.36,5.25-24.67,7.46-37.08c1.57-8.8,2.84-17.69,3.43-26.6
                c0.39-5.75-2.16-11.21-7.76-13.46c-5.7-2.29-10.25-6.05-15.27-9.25c-1.81-1.16-4.21-1.6-6.4-1.9c-6.29-0.83-12.6-1.57-18.93-2.07
                c-6.88-0.54-13.11-6.37-20.58-2.45c-0.75,0.39-2.23-0.28-3.24-0.75c-4.77-2.2-9.44-4.61-14.24-6.74c-4.18-1.86-6.67-0.6-6.54,4.1
                c0.34,11.91,1.3,23.81,2.01,35.71c0.43-0.03,0.85-0.06,1.28-0.09c0.75-5.58,1.49-11.16,2.24-16.75c0.67-0.07,1.34-0.14,2.01-0.21
                c1.6,3.53,3.2,7.06,4.79,10.58c0.86-0.16,2.53-0.94,2.97-0.49c3.83,3.85,8.35,6.94,8.63,13.53
                C852.84,596.44,854.26,608.84,855.17,619.55z M878.56,435.36c-0.8-0.13-1.6-0.25-2.41-0.38c-1.04,3.05-2.34,6.03-3.08,9.15
                c-7.28,30.41-14.78,60.8-15.59,92.3c-0.04,1.44,1.36,3.79,2.63,4.25c5.59,2.04,11.27,4.25,17.11,5.05
                c5.05,0.69,10.16,3.2,15.62,0.63c1.38-0.65,4.02,0.96,5.9,1.89c5.89,2.94,11.7,6.06,17.96,9.33c-0.81-19.22-1.61-36.98-2.25-54.74
                c-0.11-3.06-1.15-4.63-4.05-5.97c-3.93-1.82-7.71-4.25-10.98-7.08c-2.14-1.85-3.43-4.79-4.82-7.38c-0.99-1.84-2.18-3.94-2.14-5.9
                c0.08-3.68-1.72-5.71-4.31-7.85c-3.29-2.72-7.28-5.46-8.91-9.11c-2.64-5.9-6.25-12.37-1.04-19.12
                C879.05,439.3,878.47,437.07,878.56,435.36z M256.28,457.35c0.03-0.89,0.07-1.78,0.1-2.67c-2.44,0-4.9-0.24-7.3,0.07
                c-2.61,0.34-5.15,1.67-7.74,1.72c-5.31,0.1-9.19,2.42-10.94,7.17c-1.51,4.09-2.86,8.61-2.72,12.89
                c0.93,27.17-0.92,54.18-3.74,81.16c-0.74,7.1-1.08,14.26-1.32,21.4c-0.16,4.68,1.24,5.53,5.36,3.49c4.47-2.21,8.76-4.78,13.2-7.06
                c12.54-6.42,16.58-19.16,23.71-30.79c-4.97-0.27-9.76,1.03-12.22-4.42c2.18-1.07,4.15-2.03,6.61-3.24
                c-3.6-1.56-6.79-2.55-9.55-4.24c-3.24-1.98-2.92-4.49,0.57-6.11c5.37-2.49,10.83-4.79,16.24-7.2c4.09-1.82,8.39-3.3,12.22-5.58
                c7.49-4.46,14.56-9.61,22.1-13.97c3.7-2.14,4.96-2.78,5.15-6.29c-3.66,3.14-7.08,2.52-11.62,1.01
                c-6.16-2.04-13.01-1.99-19.57-2.83c-0.14-0.02-0.33,0.1-0.44,0.21c-3.96,3.53-6.83,1.5-8.4-0.87c-6.92,1.55-12.93,3.34-19.07,4.04
                c-2.34,0.27-4.96-1.97-7.45-3.07c1-2.11,1.76-4.38,3.08-6.26c1.1-1.56,2.86-2.65,4.53-4.13c-4.35-0.82-6.21-2.68-3.78-6.06
                c-3.64-3.67-6.13-7.51-6.79-12.54c-0.52-3.97,1.33-6.03,5.24-5.9C246.57,457.48,251.43,457.35,256.28,457.35z M1161.15,561.73
                c0-2.09,0-3.56,0-5.04c0-64.78,0.06-129.57-0.1-194.35c-0.01-5.93-1.29-11.89-2.26-17.78c-0.24-1.48-1.31-3.05-2.43-4.12
                c-2.68-2.53-5.63-4.79-8.64-7.3c0,1.39,0.02,3.02,0,4.64c-0.28,19.98-0.51,39.96-0.89,59.93c-0.05,2.77-0.03,6.03-1.4,8.19
                c-3.66,5.76-3.55,11.88-3.58,18.27c-0.08,18.43-0.52,36.86-0.77,55.3c-0.16,11.63-0.27,23.26-0.28,34.88
                c0,1.11,0.68,2.37,1.37,3.31c1.74,2.37,4.75,4.36,5.32,6.93c1.9,8.59,3.55,17.32,4.15,26.08
                C1152.06,556.93,1154.93,559.9,1161.15,561.73z M35.88,788.4c0.08,0.01,1.1,0.38,1.98,0.18c7.73-1.77,13.69,0.79,18.22,7.16
                c0.55,0.78,1.64,1.97,2.2,1.83c5.56-1.34,6.02,3.77,8.7,6.29c3.61,3.39,5.34,9.23,12.07,8.96c10.39-0.42,11.98,1.26,12.74,11.79
                c0.25,3.41,1.42,5.49,5.13,6.23c1.41,0.28,3.58,2.57,3.47,3.77c-0.64,7.23,4.35,6.85,10.11,7.66c-1.91-5.96,3.58-12.08-3.43-17.05
                c-2.71-1.92-4.4-5.4-6.29-8.34c-1.44-2.24-1.98-5.2-3.75-7.05c-4.49-4.66-9.23-9.15-14.31-13.15c-2.33-1.84-5.31-4.03-8.83-0.66
                c-2.55,2.44-3.67-0.57-4.55-2.43c-2.99-6.35-2.61-14.19-8.67-19.24c-0.77-0.64-0.57-2.49-0.74-3.79
                c-0.73-5.65-1.39-11.31-2.16-16.95c-0.08-0.59-0.58-1.24-1.06-1.64c-5.77-4.78-9.12-11.06-12.15-17.81
                c-1.68-3.73-4.77-7.34-8.14-9.67c-6.27-4.34-13.21-7.52-21.31-7.23c-1.42,0.05-2.82-2.32-4.42-3.24
                c-1.61-0.93-3.53-2.13-5.16-1.93c-1.32,0.16-3.4,2.21-3.43,3.46c-0.27,10.81-0.23,21.64-0.1,32.45c0.05,3.98,2.51,5.85,5.83,4.1
                c3.2-1.68,5.14-0.39,6.96,1.62c2.65,2.93,4.9,6.23,7.58,9.13c0.92,0.99,2.68,1.8,4,1.72c4.59-0.26,6.29,2.05,6.56,6.23
                c0.18,2.79,0.49,5.59,0.95,8.35C34.34,781.9,35.05,784.62,35.88,788.4z M915.91,296.62c0-8.29,1.41-15.91-0.32-22.74
                c-2.76-10.86-7.38-21.28-11.69-31.69c-1.95-4.71-4.45-9.43-7.69-13.32c-10.78-12.98-20.85-26.36-27.88-41.81
                c-0.08-0.18-0.53-0.2-1.28-0.46c-0.67,21.98-7.7,42.51-13.29,63.35c-2.26,8.43-0.96,12.26,6.49,17.14
                c5.4,3.54,6.88,3.38,11.7-2.32c2.19,3.84,4.83,7.05,5.9,10.72c1.36,4.64,6,10.03,10.89,10.44c1.18,0.1,2.69-0.34,3.6-1.07
                c4.24-3.42,9.72-2.74,11.42,2.77C905.73,294.07,911.18,293.71,915.91,296.62z M99.81,587.47c2.26,0.64,4.53,0.63,5.81,1.75
                c5.18,4.51,10.59,7.25,17.88,6.72c9.84-0.72,16.16,7.59,24.38,11.18c2.66,1.16,5.27,2.92,8.17,4.24
                c5.67,2.6,10.88,5.37,17.41,5.85c2.27,0.17,4.48,1.69,7.01,0.35c2.16-1.15,4.32-2.41,6.64-3.11c1.43-0.43,3.16-0.01,4.74,0.21
                c1.87,0.26,3.85,1.34,5.52,0.95c2.48-0.58,4.84-1.96,7.05-3.33c2.88-1.79,5.49-4.01,8.35-5.85c4.37-2.8-0.33-6.22,1.15-9.05
                c-4.25-1.46-8.14-3.94-11.95-3.82c-4.49,0.15-5.25-2.48-6.63-5.19c-0.5-0.98-0.38-2.4-1.07-3.13
                c-8.37-8.85-19.33-12.46-30.9-13.84c-4.79-0.57-10.37-2.63-14.98,1.6c-0.46,0.42-1.87-0.35-2.82-0.3
                c-1.83,0.08-3.66,0.3-5.47,0.54c-1.11,0.15-2.32,0.86-3.29,0.61c-7.69-2.03-14.81-0.15-21.82,2.82c-0.91,0.39-1.86,1.28-2.68,1.17
                C104.96,576.85,103.08,582.6,99.81,587.47z M741,983.15c0.36-0.03,0.72-0.07,1.07-0.1c1.06,1.91,1.88,4.01,3.23,5.67
                c1.68,2.07,3.59,4.17,5.86,5.44c0.9,0.51,3.98-0.79,4.45-1.93c3.37-8.22,11.35-12.02,16.89-18.11c0.85-0.93,2.72-1.48,4.03-1.34
                c6.83,0.72,13.05-0.43,19.72-2.63c5.62-1.85,7.5-4.48,8.2-9.42c0.91-6.41,2.03-12.8,2.75-19.23c0.64-5.76,4.02-11.9-1.77-17.07
                c-0.41-0.37-0.33-1.26-0.52-1.9c-1.55-5.02-8.86-8.79-14-8.05c-5.37,0.77-7.07,9.25-13.99,6.36c-0.1-0.04-0.7,1.41-1.23,2.01
                c-0.86,0.98-1.74,2.02-2.82,2.72c-2.86,1.89-6.29,3.16-8.61,5.53c-2.25,2.3-5.46,4.74-3.12,9.26c2.31,4.47,0.08,8.72-5.19,11.78
                c-4.43,2.57-7.12,0.71-9.32-3.72c-1.53,5.8-2.51,11.35-3.38,16.91c-0.08,0.51,0.54,1.19,0.95,1.69c1.74,2.11,1.76,3.78-0.92,5.18
                c-0.72,0.38-1.43,1.42-1.54,2.23C741.36,977.33,741.23,980.25,741,983.15z M1.94,673.98c0.01,0,0.02,0,0.03,0
                c0,3.66-0.48,7.41,0.18,10.95c0.38,2.02,2.42,5.03,4.03,5.25c3.8,0.51,8.2,0.57,11.62-0.89c8.07-3.46,15.22-3.36,22.06,2.41
                c0.7,0.59,1.79,0.75,2.73,1.02c3.12,0.91,6.29,1.65,9.35,2.69c4.49,1.53,8.93,3.21,13.35,4.93c1.22,0.47,2.69,1.01,3.38,1.99
                c3.19,4.54,8.05,5.15,12.86,6.62c4.46,1.36,8.6,3.54,13.46,3.26c0.72-0.04,1.65,0.5,2.22,1.05c6.77,6.45,13.62,12.81,20.14,19.5
                c2.31,2.37,3.88,2.38,5.44-0.16c3.52-5.74,0.95-19.22-4.25-23.76c-2.75-2.4-6.01-4.63-7.79-7.67
                c-5.03-8.55-12.5-11.45-21.95-12.59c-20.8-2.5-37.59-14.13-54.26-25.67c-2.56-1.77-4.4-2.29-7.59-0.87
                c-3.75,1.67-8.12,2.54-12.27-0.27c-1.68-1.14-4.02-1.45-6.11-1.81c-5.06-0.88-6.6,0.46-6.64,5.52
                C1.93,668.32,1.94,671.15,1.94,673.98z M88.4,1036.56c0.53,0.34,1.06,0.69,1.58,1.03c13.1-10.64,28.42-15.75,44.67-18.61
                c0.81-0.14,1.84-0.24,2.34-0.76c1.03-1.07,2.49-2.42,2.5-3.66c0.01-6.48-0.21-12.98-0.68-19.45c-1.13-15.58-2.61-31.14-3.62-46.73
                c-0.93-14.41-1.43-28.85-1.92-39c-0.72,4.87-2.64,13.8-3.26,22.83c-1.13,16.42-1.52,32.89-2.15,49.35
                c-0.22,5.72,0.85,11.76-5.32,15.55c-1.05,0.65-1.25,2.64-1.93,3.97c-0.7,1.39-1.5,2.74-2.26,4.11c-0.52-0.04-1.03-0.07-1.55-0.11
                c-0.47-2.27-1.65-4.65-1.31-6.79c2.87-17.85,1.37-36.02,5.55-53.92c3.09-13.25,2.26-27.42,3.13-41.18
                c0.06-0.97-0.19-1.98-0.4-2.95c-2.94-13.36-2.22-26.77-0.12-40.12c0.74-4.71,3.24-9.26,3.37-13.92
                c0.66-23.78,0.82-47.58,1.12-71.38c0.02-1.38-0.26-2.77-0.4-4.16c-0.43-0.07-0.87-0.14-1.3-0.2c-0.52,1.55-1.4,3.08-1.5,4.66
                c-0.48,7.47-0.82,14.95-1.09,22.43c-1.34,36.58-2.2,73.18-4.1,109.73c-1.69,32.4-8.38,63.9-19.71,94.4
                C95.76,1013.17,92.25,1024.93,88.4,1036.56z M340.15,301.33c1.79-5.36,3.75-9.21,4.27-13.24c1.24-9.55,2.42-19.19-6.7-26.43
                c-2.57-2.04-3.56-5.97-5.85-8.51c-2.28-2.53-5.03-4.98-8.07-6.37c-2.55-1.16-5.9-0.4-8.76-1.08c-8.93-2.11-17.79-4.53-26.7-6.75
                c-0.76-0.19-1.86-0.17-2.43,0.25c-3.15,2.33-6.76,4.02-6.39,9.28c0.3,4.21-0.93,8.54-1.58,12.8c-0.82,5.41-1.78,10.81-2.59,16.23
                c-0.61,4.15,1.89,5.21,5.39,5.17c2.12-0.02,4.3-0.36,6.34,0.05c5.84,1.16,11.62,2.57,17.4,3.99c2.06,0.51,4.04,1.33,6.06,1.99
                c1.85,0.61,3.71,1.23,5.57,1.78c2.45,0.73,5.08,1.09,7.36,2.18C328.89,295.27,334.15,298.19,340.15,301.33z M42.38,1027.64
                c11.44-1.67,24.21-3.73,37.03-5.33c8.46-1.06,13.57-5.12,14.83-13.76c0.28-1.94,1.25-3.78,1.94-5.65c2.53-6.9,2.03-8.15-5.43-7.53
                c-11.59,0.96-23.06,2.27-34.45-1.33c-2.18-0.69-4.59-0.85-6.9-0.85c-14.41-0.04-28.83,0.07-43.24,0.04
                c-2.97-0.01-4.24,1.24-4.22,4.17c0.05,7.65,0.06,15.3,0,22.95c-0.02,2.97,1.46,4.07,4.26,4.28
                C17.8,1025.53,29.38,1026.54,42.38,1027.64z M192.85,997.59c0.87,0.1,1.73,0.19,2.6,0.29c1.87-4.97,4.36-9.8,5.49-14.93
                c4.23-19.29,4.47-38.91,3.9-58.55c-0.73-24.92-1.71-49.83-2.32-74.74c-0.25-10.21-2.2-13.04-12.45-14.85
                c-2.04-0.36-4.27,0.15-6.37,0.5c-1.52,0.25-2.97,0.9-4.45,1.37c0.81,1.52,1.59,3.06,2.43,4.57c1.32,2.39,4.35,5.49,3.69,7
                c-2.2,5.07,0.32,8.68,1.84,12.94c2.16,6.06,1.11,7.13-5.49,6.39c0.31,3.69,0.68,7.26,0.91,10.84c2.07,32.34,3.95,64.69,6.28,97
                C189.44,982.87,191.49,990.21,192.85,997.59z M1081.46,22.29c-4.02,3.53-6.83,6.09-9.74,8.53
                c-16.82,14.14-29.98,30.47-32.63,53.33c-0.8,6.84-0.33,7.79,6.78,9.17c7.12,1.38,13.03,7.99,13.71,15.23
                c0.12,1.25,0.23,2.7,0.9,3.68c3.83,5.62,8.13,10.94,11.64,16.74c1.89,3.11,3.02,6.91,3.6,10.53c0.6,3.77,0.13,7.71,0.13,11.57
                c0.53-0.02,1.05-0.03,1.58-0.05C1078.76,108.58,1080.09,66.12,1081.46,22.29z M199.78,590.42c0.55-3.75,1.09-6.28,1.28-8.83
                c1.51-19.91-2.27-38.62-12.18-56.14c-8.07-14.27-15.63-28.85-23.47-43.4c-5.64,12.5-11.82,24.45-16.38,36.99
                c-2.55,7-2.52,14.97-3.4,22.53c-0.4,3.43,1.01,5.15,5.02,4.62c5.32-0.71,10.92-2.13,15.58,2.4c0.55,0.54,1.89,0.43,2.85,0.36
                c6.76-0.54,13,5.03,12.68,11.87c-0.06,1.31-2.01,2.92-3.46,3.7c-2.89,1.55-6.04,2.62-9.08,3.89
                C179.98,571.58,196.51,579.19,199.78,590.42z M537.85,428.36c-0.53-12.96,0.59-28.42,5.5-43.69c0.99,0.88,1.58,1.41,2.01,1.8
                c0.57-4.11,1.12-8.04,1.66-11.97c0.55,0.01,1.09,0.03,1.64,0.04c3.62,13.02,6.26,26.21,6.04,39.91
                c0.34-9.49,0.23-18.96,0.43-28.43c0.04-2.11,1.18-4.19,1.82-6.29c0.47,0.11,0.95,0.22,1.42,0.33c0.85,2.67,1.7,5.34,2.62,8.21
                c0.89-3.89,1.67-7.34,2.46-10.79c0.82-0.01,1.64-0.02,2.46-0.03c1.11,3.68,2.47,7.3,3.27,11.05c0.81,3.83,1.07,7.77,1.45,10.75
                c3.63-13.96-0.56-29.78,5.75-44.36c0.47-1.09-0.95-3.41-2.08-4.57c-0.38-0.39-2.62,0.98-3.99,1.6c-0.57,0.26-1.08,0.66-1.81,1.13
                c-4.85-3.03-9.56-5.97-14.26-8.91c-0.31,0.41-0.62,0.83-0.93,1.24c0.41,2.29,0.81,4.58,1.22,6.86c-2.73-3.18-4.08-6.86-6.02-10.21
                c-1.46-2.51-3.23-5.46-5.64-6.66c-4.33-2.15-9.37-2.84-13.86-4.73c-2.84-1.19-5.48-3.2-7.75-5.33c-1.8-1.68-2.91-4.11-4.45-6.39
                c-3.92,5.07-6.36,31.7-3.41,32.5c0.95-1.02,1.89-2.04,3.62-3.9c5.4,13.35,4.12,26.91,6.51,39.16c0.93-7.41,1.93-15.37,2.93-23.33
                c0.93,0,1.87,0,2.8,0C532.26,385.99,535.24,408.62,537.85,428.36z M838.04,240.26c-0.97-2.78-1.52-5.8-2.98-8.3
                c-7.45-12.8-12.33-26.52-15.49-40.93c-0.31-1.41-0.75-3.07-1.72-4.02c-5.89-5.81-13.81-5.19-19.31,1.33
                c-3.35,3.97-10.72,4.98-15.16,2.31c-1.26-0.76-3.68-1.57-4.3-0.99c-1.1,1.02-1.79,3.13-1.66,4.7
                c1.34,15.53,7.91,28.71,18.46,40.02c0.66,0.7,1.63,1.78,2.32,1.69c7.06-0.91,10.68,4.46,15.33,8.04c1.35,1.04,3.09,1.78,4.76,2.12
                c2.77,0.58,5.83,0.19,8.42,1.15C833.06,249.74,837.42,247.12,838.04,240.26z M987.61,308.2c0.77,0.35,1.54,0.7,2.31,1.05
                c3.56-5.5,7.71-10.71,10.6-16.54c16.63-33.64,26.6-69.39,32.5-106.33c0.26-1.63-1.29-5.02-2.12-5.06
                c-2.12-0.1-5.23,0.59-6.29,2.12c-2.33,3.34-4.36,6.1-9.01,5.75c-0.97-0.07-2.38,1.6-3.09,2.77c-2.94,4.82-5.83,9.69-8.45,14.69
                c-1.05,2.01-1.6,4.36-1.95,6.63c-1.65,10.66-3.07,21.36-4.71,32.03C994.19,266.26,990.88,287.23,987.61,308.2z M741.88,1004.78
                c-1.88-2.42-3.8-4.02-4.54-6.06c-1.29-3.53-3.92-4.39-7-4.45c-8.78-0.16-17.56-0.04-26.34-0.21c-5.28-0.1-10.56-0.51-15.84-0.83
                c-7.03-0.43-14.06-1.25-21.1-1.3c-14.7-0.1-29.4,0.31-44.09,0.25c-5.71-0.02-11.41-0.8-17.13-1.09c-1.63-0.08-3.28,0.45-4.93,0.52
                c-2.78,0.11-5.57,0.1-8.36,0.09c-9.63-0.03-19.25-0.09-28.88-0.13c-6.06-0.03-12.12,0-18.19-0.09c-2.77-0.04-5.72-1.02-8.27-0.35
                c-9.88,2.59-19.63,5.68-29.42,8.58c0.06,0.57,0.12,1.14,0.19,1.71c11.61,0,23.21,0.02,34.82-0.01c11.3-0.03,22.63-0.58,33.9-0.04
                c13.22,0.64,26.4,3.12,39.6,3.14c13.41,0.02,27.28,3,40.2-3.49c0.57-0.28,1.3-0.33,1.96-0.32c10.48,0.16,21.16-0.88,31.38,0.82
                C707.01,1004.37,724.17,1004.57,741.88,1004.78z M423.67,982.78c-0.71-4.72-0.28-9.03-5.58-10.23c-0.65-0.15-1.39-1.07-1.62-1.79
                c-1.79-5.71-6.14-4.7-10.57-4.58c-2.9,0.08-5.87-1.19-8.76-2.02c-1.94-0.56-3.8-1.41-5.67-2.16c-4.62-1.86-9.31-3.57-13.79-5.72
                c-1.06-0.51-1.26-2.6-2.15-3.74c-0.95-1.21-2.26-3.06-3.39-3.05c-4.85,0.05-9.8,1.47-13.84-2.73c-0.34-0.35-1.57,0.22-2.4,0.25
                c-1.82,0.05-3.67,0.25-5.45-0.02c-8.39-1.23-13.82,3.16-12.8,11.57c1,8.18,0.33,15.17-7.06,20.22c-0.7,0.48-1.21,2.19-0.9,2.96
                c0.32,0.8,1.69,1.38,2.71,1.65c1.09,0.28,2.31,0.11,3.47,0.1c27.43-0.23,54.85-0.47,82.28-0.7
                C419.77,982.77,421.4,982.78,423.67,982.78z M209.99,525.81c0.86-0.28,1.71-0.56,2.57-0.84c0.25-19.17,2.74-38.45-3.5-57.3
                c-5.6-16.9-19.86-27.46-30.4-42.53c-1.39,6.61-1.96,11.91-3.64,16.83c-3.41,9.98-2.98,18.85,2.83,28.24
                C189.12,488.39,199.34,507.23,209.99,525.81z M176.01,1487.38c-3.02-2.61-5.66-5.06-8.48-7.27c-1.26-0.98-2.83-1.83-4.38-2.15
                c-9.28-1.87-18.64-3.35-27.88-5.39c-6.27-1.38-12.72-2.73-18.47-5.42c-6.44-3-6.51-5.66-1.67-11.24c-2.02-0.42-3.71-1-5.41-1.08
                c-17.43-0.77-34.86-1.96-52.3-2.01c-16.93-0.05-33.87,1.02-50.79,1.83c-1.54,0.07-3,1.83-4.49,2.81c1.65,0.93,3.24,2.52,4.97,2.68
                c11.41,1.08,22.9,1.4,34.26,2.83c19.61,2.48,39.17,5.37,58.69,8.53c8.63,1.4,17.94,2.32,25.34,6.38
                c5.94,3.26,11.47,5.2,17.93,5.85C154.17,1484.84,165,1486.14,176.01,1487.38z M191.13,701.8c-0.67,1.45-1.09,2.3-1.46,3.17
                c-3.36,7.94-6.95,15.78-9.96,23.85c-1.89,5.07-3.16,10.42-4.13,15.75c-0.32,1.76,0.8,4.1,1.9,5.74c2.52,3.73,4.51,7.28,2.5,12.03
                c-0.34,0.79,1.16,3.2,2.29,3.64c7.99,3.15,13.68,11.58,12.77,19.72c1.46,0.59,2.95,1.27,4.5,1.79c3.43,1.15,5.62,0.23,5.62-3.74
                c-0.02-18.94,0.18-37.88-0.23-56.81C204.69,716.6,199.55,708.49,191.13,701.8z M238.6,1364.37c0.46-0.11,0.93-0.21,1.39-0.32
                c0-4.32,0.25-8.67-0.12-12.96c-0.15-1.74-1.64-3.36-2.53-5.04c-0.86,1.83-2.67,3.8-2.43,5.47c0.87,6.08,0.8,11.89-1.81,17.56
                c-0.46,1.01-0.43,2.28-0.47,3.44c-0.98,26.9-2.06,53.8-2.83,80.71c-0.34,11.7-1.56,10.89,10.31,11.29
                c1.99,0.07,3.97,0.46,5.96,0.56c6.41,0.32,7.51-1.11,6.66-7.58c-1.36-10.37-2.41-20.78-3.76-31.15
                c-1.61-12.35-3.19-24.71-5.19-36.99C242.41,1380.97,240.35,1372.7,238.6,1364.37z M465.83,272.5c-1.38,1.81-2.33,2.92-3.13,4.12
                c-18.01,27.05-27.01,57.65-34.77,88.72c-0.33,1.34,0.2,3.55,1.17,4.44c2.63,2.41,5.72,4.33,9.04,6.75c0.75-1.75,1.41-3.21,2-4.7
                c10.04-25.52,18.61-51.67,32.53-75.53c0.75-1.28,1.12-3.35,0.64-4.69C471.1,285.39,468.53,279.3,465.83,272.5z M1024.33,91.25
                c4.34,0.35,4.65-2.13,5.07-5.01c1.85-12.65,3.19-25.43,6.05-37.86c3.2-13.9,7.9-27.46,11.85-41.2c1.3-4.5-0.45-6.23-5.05-5.02
                c-1.38,0.36-2.51,1.59-3.87,2.12c-2.72,1.08-5.58,2.89-8.28,2.73c-6.93-0.41-9.82,1.82-10.2,8.83
                c-0.39,7.13-0.85,14.27-1.66,21.36c-1.55,13.53-3.39,27.03-5.05,40.55c-0.28,2.29-0.72,5.29,2.72,5.26
                C1021.52,82.97,1023.4,86.4,1024.33,91.25z M887.02,412.93c-2.4,4.57-4.51,8.82-6.83,12.96c-1.51,2.69-1.92,4.92,0.27,7.66
                c1.84,2.3,2.91,5.08,0.66,8.22c-3,4.18-2.25,13.49,1.35,17.39c2.55,2.77,5.81,4.91,8.25,7.76c2.02,2.35,3.49,5.24,4.87,8.06
                c1.02,2.09,1.29,4.54,2.28,6.65c0.83,1.76,2.89,3.13,3.23,4.89c0.79,4.14,3.05,3.85,5,2.83c2.54,2.06,4.57,3.7,7.22,5.86
                C914.92,463.7,910.39,435.69,887.02,412.93z M520.29,287.73c-6.37,2.89-12.38,5.58-18.35,8.35c-1.8,0.84-3.7,1.66-5.19,2.93
                c-0.9,0.76-1.88,2.93-1.48,3.48c4.82,6.59,9.88,13,15.01,19.62c7.03-7.54,7.28-7.68,11.64,0.35c2.26,4.15,5.76,5.23,9.72,6.72
                c6.84,2.56,15.29,2.78,18.74,11.23c0.29,0.71,1.79,0.94,2.75,1.36c2.89,1.28,6.2,2.02,8.58,3.92c3.95,3.16,7.3,3.58,11.53-0.04
                c-3.92-8.09-10.54-13.6-17.47-19.17c-8.23-6.6-16.05-13.84-23.27-21.53C527.71,299.82,524.25,293.41,520.29,287.73z
                M147.42,1498.15c-9.7-2.49-19.43-4.92-29.12-7.54c-2.53-0.69-4.9-1.97-7.33-3.02c-6.01-2.6-11.85-6.94-18.05-7.56
                c-29.22-2.92-58.53-4.84-87.82-7.03c-0.88-0.07-1.83,0.75-2.75,1.15c0,0.56,0,1.12,0.01,1.68c5.1,0.79,10.21,1.47,15.29,2.38
                c16.16,2.91,32.31,5.86,48.43,8.97c1.79,0.35,4.45,1.5,4.85,2.83c1.97,6.6,7,8.04,12.7,8.08
                C104.9,1498.23,126.18,1498.15,147.42,1498.15z M1114.17,153.69c1.44-20.27,3-46.37,5.28-72.41c0.81-9.33,3.34-18.52,5.06-27.78
                c0.6-3.26,1.46-6.5,1.65-9.79c0.69-12.11,1.09-24.24,1.72-36.36c0.14-2.64,0.59-4.98-3.17-5.41c-6.97-0.8-9.65,0.03-9.91,4.04
                c-0.46,7.29-0.45,14.61-0.8,21.92c-0.43,8.92-1.08,17.83-1.57,26.75c-0.2,3.64-0.36,7.29-0.3,10.93
                c0.08,4.74,0.58,9.48,0.54,14.21c-0.04,4.81-0.87,9.63-0.72,14.42C1112.63,115.98,1113.55,137.73,1114.17,153.69z M992.24,33.71
                c-2.93-1.15-5.33-2.09-7.74-3.04c0.05,0.37,0.1,0.73,0.15,1.1c-2.32,0.58-4.63,1.15-6.59,1.64c-1.73,4.73-3.52,9.61-5.48,14.96
                c-4.85-3.9-5.21,2.69-8.26,3.62c-0.42,0.13-0.85,2.4-0.41,2.87c3.64,3.92,1.28,6.86-2.38,10.54c2.99-0.3,4.88-0.52,6.77-0.68
                c4.67-0.4,8.13,2.38,8.25,6.62c0.21,7.28,1.08,8.06,8.57,8.09c4.74,0.02,9.47,0.21,14.97,0.34
                C1001.95,64.47,992.72,49.79,992.24,33.71z M1133.9,160.43c-0.28-0.04-0.56-0.09-0.84-0.13c0.85-15.6,1.44-31.22,2.65-46.79
                c0.56-7.22,1.06-14.91,3.92-21.36c2.81-6.34,4.04-12.62,4.46-19.16c1.42-22.05,2.46-44.13,3.74-66.19
                c0.17-2.98-0.3-5.15-3.81-4.78c-3.21,0.34-8.22-1.99-8.24,4.13c-0.04,12.3,0.45,24.6,0.39,36.9c-0.03,6.75-0.65,13.5-1.14,20.24
                c-0.16,2.13-1.08,4.24-1.09,6.36C1133.88,99.91,1133.9,130.17,1133.9,160.43z M286.83,972.14c-0.74,0.05-1.48,0.1-2.22,0.15
                c-4.12-10.28-3.82-21.69-6.82-30.55c-2.35,18.26-4.97,38.16-7.39,58.08c-0.18,1.5,0.57,3.75,1.7,4.67
                c4.75,3.87,9.58,7.74,14.82,10.88c3.07,1.84,6.96,2.31,11.2,3.62c-3.3-21.23-6.41-41.2-9.56-61.41
                C287.96,962.59,287.4,967.36,286.83,972.14z M852.22,772.08c7.69,1.12,12.65-3.69,11.28-10.5c-0.78-3.88-0.31-6.78,4.01-8.33
                c1.7-0.61,2.88-2.54,4.47-3.62c1.66-1.13,3.45-2.18,5.33-2.87c1.68-0.62,3.81-0.26,5.3-1.11c4.29-2.43,8.6-3.23,13.21-1.28
                c1.93,0.81,3.68,2.42,5.64,2.7c7.66,1.09,13.21,5,17.3,11.43c0.26,0.42,2.69,0.21,3.21-0.43c1.59-1.96,0.13-9.12-2.06-10.54
                c-5.3-3.44-9.48-7.75-12.77-13.26c-1.22-2.04-5.12-3.76-7.54-3.47c-3.42,0.41-6.78,0.99-10.26,1.33
                c-5.88,0.57-12.12,2.82-17.06,6.1c-5.23,3.47-9.95,6.36-16.46,5.71c-1.07-0.11-3.39,2.2-3.44,3.45
                C852.07,755.63,852.22,763.9,852.22,772.08z M328.1,975.78c0.5,0.21,1.01,0.42,1.51,0.62c1.68-2.01,4.68-3.95,4.8-6.06
                c0.53-9.31,2.04-18.45,3.57-27.6c0.05-0.32-0.04-0.66-0.08-0.99c-0.65-5.42-0.95-10.91-2.11-16.21c-0.51-2.33-2.85-4.24-4.22-6.43
                c-0.83-1.33-2.08-2.9-1.96-4.25c0.85-8.94-4.85-17.77-14.86-21.27C317.02,921.69,322.35,948.77,328.1,975.78z M205.03,791.97
                c-3.63-1.1-6.59-2.11-9.6-2.88c-3.26-0.84-4.32-2.63-3.34-5.9c0.4-1.33,0.81-3.34,0.14-4.19c-3.22-4.04-5.31-9.27-11.33-10.52
                c-3.89-0.81-5.33-3.07-3.71-7.41c0.89-2.39-0.08-5.47-0.21-8.24c-1.2,12.48-1.28,24.77-1.11,37.05c0.03,1.85,0.79,4.07,1.99,5.45
                c4.65,5.38,5.07,13.78,12.36,17.28c0.59,0.28,0.68,1.49,1.16,2.15c3.36,4.68,6.76,9.33,10.14,13.99c0.4-0.21,0.81-0.42,1.21-0.63
                C203.5,816,204.27,803.87,205.03,791.97z M73.31,1498.15c-4.4-4.21-6.19-9.06-12.05-9.93c-18.53-2.77-36.98-6.12-55.5-8.97
                c-1.07-0.17-3.37,1.9-3.6,3.19c-0.55,3.06,0.03,6.31-0.26,9.44c-0.44,4.76,1.36,6.43,6.22,6.37c17.28-0.23,34.57-0.09,51.86-0.09
                C64.08,1498.15,68.19,1498.15,73.31,1498.15z M123.17,749.32c-4.94-1.95-9-4-13.73-1.14c-0.98,0.59-4.74-1.34-5.6-2.96
                c-3.76-7.15-11.08-8.1-17.35-11c-2.86-1.33-6.11-2.68-8.03-4.98c-3.84-4.62-7.62-6.09-14.27-3.56c1.24,1.31,2.26,2.47,3.38,3.54
                c3.63,3.47,7.35,6.85,10.93,10.38c3.04,3,4.79,7.04,9.27,8.95c2.7,1.15,4.85,4.3,6.52,6.99c1.87,3.02,2.92,6.55,4.41,10.03
                c0.9-0.33,1.49-0.73,2.09-0.74c4.17-0.07,8.41-0.48,12.49,0.14c1.48,0.22,2.47,3.05,3.9,4.48c0.73,0.73,1.99,1.47,2.89,1.34
                c0.78-0.11,1.91-1.41,1.97-2.25C122.56,762.09,122.84,755.63,123.17,749.32z M219.03,265.16c-0.35-9.78-4.7-16.03-9.37-22.08
                c-0.38-0.49-1.76-0.38-2.65-0.29c-2.43,0.24-4.87,0.5-7.25,1.01c-1.2,0.26-2.87,0.78-3.32,1.68
                c-5.31,10.83-10.51,21.72-15.49,32.71c-0.61,1.35,0.22,3.37,0.39,5.07c1.62-0.61,3.66-0.79,4.77-1.91
                c3.65-3.67,7.5-6.28,13.01-5.52c1.37,0.19,3.17,0,4.22-0.77c2.61-1.92,4.94-2.2,7.81-0.58c0.79,0.44,2.95-0.02,3.38-0.74
                C216.48,270.55,218.04,267.11,219.03,265.16z M237.94,429.76c-0.05-0.06-0.44-0.9-1.08-1.21c-8.86-4.39-17.62-9.01-26.7-12.89
                c-3.58-1.53-6.76,1.88-6.23,5.88c0.5,3.79,1.23,7.54,1.89,11.31c1.24,7.08,4.16,13.36,9.57,18.17c1.26,1.12,3.74,2.09,5.12,1.6
                c6.19-2.19,11.55-5.68,14.04-10.79c-1.49-3.07-3.94-5.97-3.28-7.26C232.37,432.45,235.49,431.39,237.94,429.76z M667.27,405.75
                c0.45,0.1,0.9,0.21,1.35,0.31c0.29,1.56,0.57,3.12,0.76,4.16c1.41-0.11,2.71-0.22,5.29-0.43c-1.17-3.77-1.2-8.69-3.63-10.64
                c-3.97-3.19-6.72-6.63-8.66-11.17c-2.05-4.78-5.15-5.56-10.11-3.82c-2.33,0.82-5.28,1.24-7.57,0.56c-6.77-2.01-9.1-0.33-8.51,6.88
                c1.01,12.24,1.99,24.48,2.63,32.44c0.64-4.55,1.87-13.4,3.11-22.25c0.59-0.04,1.18-0.07,1.77-0.11c0.67,2.26,1.34,4.51,1.91,6.42
                c0.59-0.95,1.3-2.11,2.37-3.85c6.08,10.99,2.05,22.49,4.41,34.09c0.76-12.47,1.45-23.93,2.12-34.93c9.12-0.46,5.82,7.5,9.18,11.68
                c0.87-2.27,1.38-3.62,1.9-4.97C666.14,408.64,666.71,407.19,667.27,405.75z M71.17,549.96c2.93-6.1,4.95-4.79,11.85-3.4
                c3.47,0.7,7.31-0.39,10.98-0.68c0.06-0.65,0.11-1.3,0.17-1.95c-3.78-1.69-7.46-3.74-11.39-4.94c-2.34-0.71-5.1-0.32-7.63-0.04
                c-5.88,0.64-12.17,0.44-17.48,2.59c-5.49,2.23-9.39,1.2-13.84-2.27c-2.52,5.65-7.58,5.37-12.23,5.3
                c-3.24-0.05-6.45-1.21-9.68-1.85c-6.52-1.3-13.04-2.59-19.39-3.84c0,4.37,0,8.19,0,12.36c2.93-1,5.29-2.6,7.5-2.41
                c5.65,0.46,11.23,1.75,16.85,2.63c1.46,0.23,3.31,0.79,4.4,0.17c6.2-3.53,12.29,1.23,18.49-0.05c3.95-0.82,8.12-0.56,12.19-0.84
                C64.76,550.55,67.56,550.27,71.17,549.96z M870.21,155.49c0.5,0.09,1,0.19,1.5,0.28c3.87-10.22,7.88-20.4,11.43-30.73
                c0.51-1.49-0.62-4.72-1.93-5.43c-3.21-1.75-3.81-4.13-3.89-7.34c-0.13-5.21-2.24-6.51-7.01-4.95c-4.2,1.38-7.56-0.23-10.02-3.6
                c-2.4-3.29-1.75-6.45,0.85-9.51c1.17-1.38,2.91-3.96,2.41-4.72c-1.13-1.76-3.47-2.73-5.91-4.43
                C857.6,110.2,862.75,133.06,870.21,155.49z M826.88,972.79c-1.15,6.47-2.49,13.97-3.82,21.47c-1.62,9.14-3.12,18.31-4.89,27.42
                c-1.51,7.76-3.55,15.42-4.91,23.2c-0.74,4.22-0.34,8.63-0.79,12.92c-1.34,12.71-3,25.39-4.13,38.11c-0.14,1.61,2.08,3.43,3.2,5.15
                c0.99-1.62,2.71-3.19,2.86-4.88c0.97-11.24,1.7-22.51,2.34-33.79c0.69-12.31,0.81-24.7,9.1-35.06c0.83-1.04,0.99-2.85,0.99-4.3
                C826.9,1005.97,826.88,988.9,826.88,972.79z M96.9,497.71c1.31-1.33,3.08-4.32,4.27-4.11c4.24,0.76,8.45,2.28,12.35,4.17
                c2.67,1.29,4.8,3.68,8.18,6.37c-0.77-10.45-6.53-15.07-13.28-19.21c-4.9-3.01-9.88-6.17-14-10.12c-5.38-5.15-9.3-6.41-15.86-3.28
                c-0.87,0.42-1.9,0.77-2.84,0.75c-6.09-0.18-6.09-0.22-5.74,6.4c8.89,0.93,10.77,7.82,11.76,14.63
                C87.15,494.88,92.12,496.33,96.9,497.71z M836.55,168.45c-2.33,3.78-3.89,6.78-5.89,9.43c-3.09,4.11-5.23,8.13-2.97,13.48
                c3.79,8.98,7.13,18.16,11.04,27.09c1.24,2.82,3.71,5.1,6.13,8.31c3.03-10.25,4.59-19.1,1.8-28.59c-2.14-7.27-3.49-14.94-3.84-22.5
                C842.57,170.02,839.97,168.69,836.55,168.45z M158.2,836.34c0.45-0.08,0.89-0.15,1.34-0.23c0.2-0.41,0.59-0.82,0.59-1.23
                c0.01-20.43,0.04-40.87-0.08-61.3c-0.02-3.64-7.18-9.63-10.56-8.92c-0.9,0.19-2.08,2-2.08,3.06c0.03,13.62,0.07,27.25,0.59,40.86
                c0.11,2.85,1.42,6.68,3.52,8.2c4.44,3.21,5.41,7.06,5.29,12.02C156.75,831.29,157.71,833.82,158.2,836.34z M148.29,818.98
                c0.41,31.3,3.03,61.75,12.73,92.27c0-20.82,0.18-40.61-0.18-60.39c-0.06-3.31-3.56-6.38-1.51-10.25c0.11-0.2-0.42-0.91-0.8-1.18
                c-4.45-3.1-4.87-7.65-4.48-12.47C154.36,823.19,153.07,820.47,148.29,818.98z M743.23,991.31c-3.59,4.08-4.12,7,0.09,9.43
                c3.34,1.93,4.57,4.31,3.12,8.28c-1.59,4.38,0.81,7.74,5.1,9.87c1.66,0.83,3.47,3.45,3.44,5.22c-0.09,4.67,2.92,6.99,5.97,9.11
                c2.95,2.05,6.38,3.45,9.71,4.88c1.04,0.45,2.43,0.07,4.4,0.07c-0.89-4.53-1.68-8.4-2.42-12.28c-1.17-6.14-4.06-10.98-9.4-14.64
                c-3.92-2.69-7.28-6.25-10.67-9.64C749.44,998.47,746.61,995.06,743.23,991.31z M250.15,1342.63c-0.46-0.42-0.93-0.84-1.39-1.26
                c-2.03,2.64-5.85,5.32-5.79,7.9c0.33,13.3-2.79,26.98,3.44,39.81c0.77,1.59,0.83,3.55,1.06,5.36c3.07,24.72,6.08,49.45,9.24,74.16
                c0.19,1.48,1.5,2.83,2.29,4.23c0.59-1.68,1.76-3.39,1.66-5.02c-0.3-4.96-1.07-9.9-1.68-14.84c-3.08-24.68-5.73-49.42-9.46-74.01
                c-1.52-10.05-1.66-19.77-0.13-29.69c0.23-1.52-0.64-3.21-1.08-5.19C248.65,1343.81,249.4,1343.22,250.15,1342.63z M75.28,483.91
                c-7.64-3.14-14.33-5.09-21.8-2.59c-3.32,1.11-7.13,0.9-10.73,1.05c-4.6,0.2-9.21,0.1-13.81,0.3c-0.8,0.03-1.65,0.79-2.33,1.38
                c-1.26,1.11-2.67,3.52-3.56,3.33c-3.25-0.71-8.49-1.69-9.02-3.64c-1.41-5.19-7.11-2.79-8.78-6.38c-0.97,0.51-2.32,0.77-2.84,1.57
                c-2,3.11-0.17,7.2,3.47,7.74c4.44,0.66,9.41-0.02,13.25,1.79c5.82,2.75,11.83-1.3,17.76,2c2.3,1.28,6.65-0.68,9.82,2.24
                c0.96,0.89,3.98-0.06,5.89-0.69c3.97-1.3,7.77-3.93,11.87-0.27c0.53,0.47,1.56,0.44,2.38,0.52c2.81,0.29,5.62,0.53,9.25,0.86
                c0.05-0.1,0.71-1.35,1.44-2.74C75.46,488.56,70.16,488.16,75.28,483.91z M836.29,61.94c0.13,11.23,11.28,48.38,17.1,55.98
                c0-15,0.06-29.9-0.06-44.81c-0.02-2.45-0.54-4.98-1.36-7.29c-0.39-1.11-1.94-2.33-3.12-2.53
                C844.8,62.62,840.68,62.37,836.29,61.94z M59.6,1027.63c-2.87,0.49-6.15,1.55-9.4,1.51c-12.75-0.17-25.5-0.53-38.24-1.11
                c-10.78-0.49-10.86-0.52-10.05,10.46c0.22,2.98,1.31,4.19,4.38,3.76C24.85,1039.63,43.67,1038.04,59.6,1027.63z M838.98,105.64
                c-2.42-12.77-4.62-24.63-6.98-36.47c-0.23-1.13-1.25-2.5-2.28-2.99c-3.7-1.8-7.51-3.41-11.39-4.78c-1.23-0.43-2.95-0.16-4.18,0.39
                c-0.51,0.22-0.64,2.11-0.39,3.09C817.89,80.77,826.69,93.76,838.98,105.64z M163.09,733.56c-1.2-6.26-1.7-12.04-3.48-17.39
                c-2.62-7.87-6.07-15.47-9.38-23.09c-0.61-1.4-2.26-2.36-3.43-3.52c-0.71,1.51-1.99,3-2.04,4.52c-0.25,7.48-0.23,14.98-0.2,22.47
                C144.59,724.36,153.14,732.95,163.09,733.56z M143.92,727.04c0.96,11.27,1.78,21.44,2.75,31.6c0.09,0.97,0.97,2.28,1.84,2.69
                c3.65,1.74,7.44,3.19,11.71,4.97c0.44-8.45,1-16.7,1.19-24.96c0.04-1.55-1-3.72-2.25-4.61
                C154.79,733.62,150.11,730.92,143.92,727.04z M858.03,1347.33c-0.42,2.37-0.15,4.7-1.08,5.38c-2.87,2.1-1.32,3.43,0.13,5.2
                c4.7,5.79,9.42,11.57,13.92,17.52c3.51,4.64,6.49,0.59,9.53-0.41c3.33-1.1,3.26-3.39,1.75-6.32c-4.75-9.25-11.93-16.28-19.94-22.6
                c-5.42-4.28-5.42-4.29-12.55-2.2C853.01,1345.23,855.98,1346.48,858.03,1347.33z M1141.05,1456.08
                c-5.37,15.55-15.19,28.41-26.11,42.07c7.47,0,13.4-0.13,19.32,0.05c3.64,0.11,5.05-1.45,5.18-4.97
                C1139.9,1480.84,1140.5,1468.46,1141.05,1456.08z M1045.51,1435.32c-0.5-0.2-1.01-0.4-1.51-0.6c-0.62-1.81-1.25-3.61-1.48-4.29
                c-4.45,14.98-9.1,30.66-14.09,47.47c9.07-3.97,27.29-40.66,23.31-54.58C1049.71,1427.22,1047.61,1431.27,1045.51,1435.32z
                M211.12,1465.35c-5.45-1.75-11.15-4.43-17.1-5.28c-9.68-1.38-19.53-1.91-29.31-2.08c-3.51-0.06-7.06,1.95-10.59,3.01
                c0.12,0.9,0.25,1.8,0.37,2.7c8.7,0.55,17.39,1.13,26.1,1.58c0.77,0.04,1.71-0.7,2.35-1.3c3.67-3.43,12.57-3,16.43,0.91
                c-3.35,1.67-6.68,3.33-10.01,5c0.12,0.68,0.25,1.37,0.37,2.05c7.68,1.19,15.36,2.38,23.03,3.57
                C205.94,1470.41,205.8,1469.25,211.12,1465.35z M178.4,799.29c-0.53,0.27-1.06,0.54-1.59,0.81c0.56,8.74,1.09,17.48,1.71,26.22
                c0.39,5.55,2.41,6.87,7.78,5.78c1.78-0.37,3.85-0.26,5.6,0.27c3.49,1.06,6.85,2.56,10.26,3.88c0.31-0.35,0.63-0.69,0.94-1.04
                c-0.66-1.32-1.21-2.71-1.99-3.95c-3.82-6.09-10.05-9.93-13.54-16.88C185.16,809.6,178.99,806.22,178.4,799.29z M139.4,493.35
                c0.56,0.31,1.11,0.62,1.67,0.93c4.63-7.18,9.38-14.28,13.73-21.62c0.57-0.96-0.61-4.29-1.61-4.62c-4.52-1.48-9.22-2.63-13.94-3.21
                c-3.27-0.4-4.68,1.62-4,5.12C136.74,477.73,138.02,485.55,139.4,493.35z M1041.09,49.66c0.38,0.21,0.76,0.42,1.14,0.62
                c1.9-2.21,3.94-4.32,5.69-6.65c8.15-10.82,12.75-23.27,16.1-36.21c0.41-1.58-0.29-4.77-1.23-5.11c-2.77-1.01-5.32-0.38-6.56,3.24
                c-3.53,10.37-7.44,20.61-11.08,30.94C1043.63,40.82,1042.43,45.26,1041.09,49.66z M780.48,1037.99c0.62-0.15,1.24-0.29,1.87-0.44
                c-0.59-9.16-0.94-18.34-1.92-27.46c-0.32-2.98-1.78-6.18-3.61-8.6c-3.91-5.14-8.41-9.82-13.05-15.13
                c-1.89,0.77-2.62,3.08-1.35,6.2c1.87,4.6,2.92,9.82,5.81,13.66c6.33,8.42,9.46,17.78,10.97,27.94
                C779.38,1035.49,780.04,1036.72,780.48,1037.99z M729.61,211.54c-5.32-8.96-10.57-17.95-16.01-26.83c-0.76-1.24-2.3-2.45-3.7-2.78
                c-4.22-1.01-8.55-1.59-13.06-2.37C704.15,194.1,713.78,206.16,729.61,211.54z M455.55,266.86c-3.97-1.12-7.7-1.99-11.3-3.24
                c-2.86-0.99-4.53-0.41-5,2.66c-0.93,6.04-2.12,12.06-2.62,18.14c-0.31,3.73,0.46,7.55,0.74,11.33c0.43-0.02,0.85-0.04,1.28-0.06
                C444.14,286.33,449.62,276.98,455.55,266.86z M974.16,217.18c0.49,0.22,0.99,0.45,1.48,0.67c4.76-5.34,9.73-10.51,14.21-16.06
                c3.23-3.99,5.85-8.48,8.68-12.79c2.42-3.68,0.09-6.49-2.26-8.74c-2.73-2.6-4.32,0.6-5.43,2.2c-2.33,3.35-4.33,6.97-6.05,10.68
                C981.11,201.09,977.69,209.16,974.16,217.18z M887.83,1365.38c10.88-13.88,8.65-22.04-7.29-27.94c-0.93-0.34-1.87-0.72-2.84-0.87
                c-1.31-0.2-2.66-0.19-4.18-0.27c2.89,5.9,3.82,12.45,12.58,13.67c-3.3,1.77-4.94,2.65-6.6,3.54
                C882.29,1357.48,884.93,1361.25,887.83,1365.38z M801.57,238.75c4.39,9.23,23.63,26.26,29.98,27.41
                c1.26-4.84,2.53-9.72,3.82-14.66C822.5,250.97,810.75,248.49,801.57,238.75z M227.16,673.8c0,7.1,0,14.57,0,22.58
                c9.92-2.98,14.1-10.57,18.46-17.25C239.42,677.34,233.57,675.65,227.16,673.8z M110.75,657.5c-1.32,3.53-2.68,6.72-3.66,10.02
                c-0.31,1.05-0.03,2.87,0.7,3.52c4.57,4.11,9.71,7.26,15.91,8.42C126.47,667.24,122.37,660.21,110.75,657.5z M568.49,2.42
                c-11.85,0-23.3,0-36,0c1.81,2.56,2.78,4.45,4.23,5.85c2.38,2.3,15.54,2.55,18.81,0.93c1.52-0.75,3.53-1.17,5.17-0.88
                C565.66,9.19,567.29,8.18,568.49,2.42z M783.02,1008.99c4.54-10.5,4.12-20.69,4.73-30.65c0.04-0.68-4.19-1.91-6.54-2.3
                c-0.93-0.16-3,1.21-3.06,1.99C777.41,988.35,778.61,998.39,783.02,1008.99z M752.5,1043.04c0.04,0.37,0.08,0.73,0.12,1.1
                c2.85,1.17,5.67,3.25,8.55,3.33c9.63,0.29,19.29,0,28.92-0.37c3.9-0.15,5.43-2.53,4.13-6.74
                C780.21,1041.27,766.35,1042.15,752.5,1043.04z M370.88,1042.76c-0.02-0.22-0.03-0.45-0.05-0.67c-9.31-2.18-18.63-4.37-27.94-6.54
                c-3.7-0.86-7.41-1.72-11.13-2.48c-6.91-1.4-13.86-2.63-20.76-4.13c-3.88-0.84-7.68-2.08-11.52-3.14c-0.16,0.5-0.32,0.99-0.48,1.49
                c2.94,1.31,6.29,2.11,8.75,4.03c5.46,4.28,10.87,7.39,18.06,4.61c1.1-0.42,2.61,0.13,3.92,0.34
                C343.45,1038.43,357.16,1040.6,370.88,1042.76z M861.36,382.03c-0.43,0.1-0.85,0.2-1.28,0.3c0,4.59-0.42,9.23,0.15,13.74
                c0.35,2.8,1.73,5.79,3.49,8.02c2.56,3.26,5.71,2.31,10.26-1.63c3.2-2.77,1.23-4.47-0.57-6.53
                C869.37,391.32,865.37,386.67,861.36,382.03z M175.37,262.31c0.87,0.18,1.73,0.35,2.6,0.53c2.38-6.03,4.81-12.04,7.09-18.1
                c0.2-0.53-0.3-1.72-0.83-2.08c-3.24-2.16-6.58-4.17-10.39-6.53C172.93,245.19,170.49,254,175.37,262.31z M508.56,283.34
                c-4.29-4.07-7.47-7.08-10.61-10.07c-7.26,5.39-8.67,12.21-3.79,22.07C499.05,291.26,503.72,287.38,508.56,283.34z M1063.43,27.67
                c3.93-2.7,6.9-4.37,9.43-6.55c4.62-3.97,8.67-8.48,7.23-15.28c-0.3-1.41-1.87-3.55-2.91-3.58c-1.79-0.05-4.72,0.83-5.26,2.11
                C1068.96,11.49,1066.56,18.86,1063.43,27.67z M363.59,487.5c6.55,0,13.06,0.86,19.22-0.24c6.08-1.09,11.8-4.18,17.68-6.39
                c-5.51-1.14-10.8-1.39-16.06-1.07c-6.37,0.39-12.7,4.57-19.1,0.05c-0.09-0.06-0.51,0.33-0.52,0.34
                C364.4,482.61,364.03,484.81,363.59,487.5z M158.38,645.61c-0.93,5.44-1.73,9.66-2.35,13.91c-0.81,5.57-1.56,11.15-2.2,16.74
                c-0.51,4.52,2.6,4.45,5.81,4.53c3.54,0.09,4.23-1.54,3.71-4.65C161.69,666.37,160.16,656.58,158.38,645.61z M182.1,682
                c-0.65-9.85-1.18-19.52-2.04-29.16c-0.15-1.63-1.72-3.13-2.63-4.69c-0.66,0.22-1.32,0.44-1.98,0.66
                c-0.23,8.21-0.56,16.41-0.66,24.62C174.7,681.14,174.81,681.14,182.1,682z M161.02,458.31c2.81-5.02-0.42-6.55-2.16-8.7
                c-5.09-6.24-10.18-12.48-15.11-18.85c-1.41-1.82-2.26-4.07-3.37-6.12c-0.92,4.51-2.24,9.97-0.09,12.65
                C146.27,444.77,153.71,451.09,161.02,458.31z M198.2,435.31c0.52-0.35,1.03-0.69,1.55-1.04c-0.22-4.77-0.16-9.58-0.79-14.3
                c-0.31-2.32-1.63-6.07-3.03-6.33c-2.89-0.54-6.15,0.76-9.2,1.52c-0.26,0.07-0.52,1.99-0.11,2.62
                C190.4,423.67,194.32,429.48,198.2,435.31z M1003.53,53.51c0.54-0.07,1.08-0.13,1.62-0.2c0.48-8.53,1.28-17.07,1.15-25.59
                c-0.02-1.62-3.74-3.19-5.75-4.78c-0.74,1.88-1.81,3.7-2.13,5.65c-0.25,1.56,0.31,3.28,0.66,4.89
                C1000.54,40.17,1002.05,46.84,1003.53,53.51z M820.13,359.77c5.97,5.13,11.44,9.84,17.56,15.1c-0.25-7.32-1.03-12.78-5.58-17.33
                c-2.94-2.94-4.92-4.86-8.14-0.82C823.38,357.46,822.45,357.95,820.13,359.77z M422.55,260.77c-0.43,0.34-0.87,0.67-1.3,1.01
                c2.5,6.78,5,13.56,7.5,20.34c0.6-0.13,1.2-0.25,1.8-0.38c1.61-7,3.22-14,5.06-22.03C430.66,261.8,426.83,265.82,422.55,260.77z
                M765.96,497.27c-22.9-4.4-42.12-14.94-56.26-34.17c-0.11,1.1,0.04,1.8,0.47,2.22c8.16,8.08,16.1,16.4,24.65,24.03
                c3.07,2.74,7.45,4.57,11.53,5.47C752.75,496.25,759.41,496.52,765.96,497.27z M247.24,1135.08c-0.06,0.76-0.12,1.52-0.18,2.28
                c11.74,1.76,23.47,3.52,36.01,5.41c-3.94-6.66-8.78-8.21-14.99-7.79C261.16,1135.44,254.18,1135.08,247.24,1135.08z
                M489.14,274.51c-10.99-3.43-10.99-3.43-10.67-10.28c-1.17,0.53-2.73,0.83-2.8,1.32c-0.98,7.52,0.03,14.84,2.31,22.78
                C481.98,283.39,485.38,279.18,489.14,274.51z M159.85,251.76c0.56-4.65,0.95-7.87,1.34-11.1c1.15-9.51,0.32-11.16-8.66-16.44
                C154.03,233.44,154.27,242.49,159.85,251.76z M169.1,669.09c0.34-6.93,0.8-13.86,0.88-20.79c0.01-1.02-1.83-2.98-2.66-2.9
                c-1.47,0.15-4.1,1.64-4.03,2.36C164.01,655.08,163.83,662.69,169.1,669.09z M176.48,716.66c0.6,0.16,1.21,0.32,1.81,0.47
                c1.71-4.96,3.65-9.86,5.07-14.9c1.6-5.7-0.54-7.94-6.88-7.76C176.48,701.87,176.48,709.26,176.48,716.66z M219.11,1032.8
                c-0.44,2.46,0.01,6.14-1.62,7.92c-4.81,5.27-8.98,11.82-17.23,12.81c7.4-1.7,14.44-4.05,21.25-6.95c1.26-0.53,2.57-3.63,2.12-4.96
                C222.5,1038.24,220.39,1035.17,219.11,1032.8z M275.42,239.26c-0.64-0.36-1.29-0.72-1.93-1.07c-1.81,1.43-5.26,2.98-5.17,4.28
                c0.49,7.19,1.83,14.32,2.89,21.47c0.48-0.01,0.97-0.02,1.45-0.02C273.57,255.7,274.49,247.48,275.42,239.26z M163.55,443.83
                c0.6-0.17,1.19-0.34,1.79-0.51c2.64-7.56-0.27-14.41-3.29-21.18c-0.3-0.68-2.08-1.25-2.89-0.99c-0.76,0.25-1.78,1.84-1.59,2.53
                C159.44,430.44,161.53,437.12,163.55,443.83z M144.26,419.62c1.53,7.81,7.85,18.17,12.4,20.54c-0.97-4.02-2.49-7.99-2.78-12.06
                C153.44,421.99,150.46,419.47,144.26,419.62z M348.96,253.54c0,7.83,0,15.15,0,22.47c0.29,0.02,0.59,0.05,0.88,0.07
                c2.2-7.02,4.4-14.03,6.63-21.14C353.81,254.45,351.74,254.06,348.96,253.54z M122.01,701.97c0.77-0.37,1.55-0.73,2.32-1.1
                c0-3.16,1.13-8.3-0.23-9.08c-3.01-1.72-7.28-1.25-11.03-1.68c-0.33,0.69-0.66,1.38-0.99,2.07
                C115.39,695.44,118.7,698.71,122.01,701.97z M118.2,483.35c0.63-0.33,1.25-0.67,1.88-1c-0.35-2.89-0.67-5.79-1.06-8.68
                c-0.85-6.25-2.58-7.24-10.18-4.78C112.08,473.89,115.14,478.62,118.2,483.35z M1062.59,1140.28c7.11-10.03,7.33-21.34,6.68-32.83
                C1067.05,1118.39,1064.82,1129.34,1062.59,1140.28z M161.37,705.52c0.58-0.11,1.16-0.23,1.74-0.34c0.28-4.09,0.86-8.19,0.75-12.28
                c-0.09-3.41-3.06-3.87-5.51-3.19c-0.86,0.24-1.79,3.12-1.41,4.42C158.08,698.03,159.85,701.74,161.37,705.52z M147.64,644.01
                c-0.53,0.2-0.82,0.22-0.88,0.34c-3.51,8.52-3.67,16.82,2.64,24.68C148.82,660.76,148.24,652.48,147.64,644.01z M127.33,621.05
                c0.48-0.22,0.96-0.44,1.44-0.65c-2.1-6.84-4.21-13.68-6.28-20.41c-4.25,0.78-4.48,3.98-3.31,6.53
                C121.49,611.55,124.57,616.22,127.33,621.05z M88.21,599.23c7.74-2.94,9.1-4.65,7.83-9.91c-0.31-1.3-1.79-2.8-3.05-3.21
                c-0.76-0.25-2.87,1.25-3.12,2.27C89.03,591.76,88.76,595.29,88.21,599.23z M887.01,127.17c-1.74,0.38-2.07,0.37-2.1,0.47
                c-4.29,11.23-8.57,22.47-12.84,33.7c0.65,0.23,1.3,0.47,1.95,0.7C878.23,150.72,882.46,139.38,887.01,127.17z M223.99,232.81
                c-0.7-0.01-1.41-0.03-2.11-0.04c0,7.14,0,14.28,0,21.42c0.45,0.01,0.9,0.02,1.35,0.03C222.77,247.07,229.55,240.06,223.99,232.81z
                M899.26,388.63c2.02,0.64,3.7,1.81,4.95,1.45c3.98-1.13,7.13-8.09,6.59-13.9C907.14,380.13,903.59,383.96,899.26,388.63z
                M380.1,1069.83c12.63-2.08,25.45-4.19,38.28-6.3C405.55,1065.93,391.62,1061.97,380.1,1069.83z M725.7,333.18
                c-2.44,3.95-2.51,4.77,1.5,6.66c4.58,2.16,9.48,3.62,14.24,5.39c0.04-0.18,0.09-0.36,0.13-0.54
                C736.39,340.93,731.2,337.17,725.7,333.18z M149.83,612.45c-2.03-1.18-3.71-2.15-5.39-3.13c-0.59,1.77-1.45,3.5-1.71,5.32
                c-0.39,2.72-0.36,5.49-0.6,9.75C145.05,619.85,147.22,616.5,149.83,612.45z M113.38,604.94c1.74-7.29,0.86-8.63-4.98-7.93
                C107,603.47,107.43,604.2,113.38,604.94z M267.95,1120.32c-0.29,2.55-0.42,4.21-0.68,5.84c-0.94,6.1-0.02,6.95,6.36,5.55
                C271.86,1128.16,270.1,1124.64,267.95,1120.32z M109.92,649.7c0.36-0.45,0.72-0.9,1.08-1.35c-3.95-2.43-3.01-10.25-9.7-9.66
                c-0.47,0.5-0.94,1.01-1.4,1.51C103.24,643.36,106.58,646.53,109.92,649.7z M188.35,677.02c4.68-8.22,4.14-10.36-2.45-12.12
                C186.74,669.07,187.54,673.05,188.35,677.02z M105.47,442.38c0.45-0.63,0.9-1.26,1.35-1.9c-4.17-3.39-8.35-6.78-12.52-10.18
                c-0.45,0.53-0.9,1.07-1.35,1.6C97.12,435.4,101.29,438.89,105.47,442.38z M215.05,244.94c0.87-0.21,1.73-0.43,2.6-0.64
                c-0.71-3.98-1.43-7.95-2.14-11.93c-0.91,0.18-1.82,0.36-3.71,0.73c3.27,2.35,5.5,4.03,2.2,7.08
                C213.4,240.73,214.66,243.3,215.05,244.94z M170.44,440.3c-0.57-0.06-1.13-0.12-1.7-0.18c0.14,4.08-3.59,8.43,1.7,13.31
                C170.44,448.32,170.44,444.31,170.44,440.3z M172.38,432.24c2.26-6.37,2.7-11.64,0-15.43
                C172.38,423.04,172.38,428.15,172.38,432.24z M116.81,462.85c0.08-0.45,0.16-0.89,0.24-1.34c-2.02-0.95-3.97-2.15-6.08-2.74
                c-1.04-0.29-2.4,0.54-3.61,0.87c0.8,1.07,1.44,2.87,2.42,3.06C112.04,463.16,114.46,462.85,116.81,462.85z M136.65,453.85
                c-0.91,5.41,0.35,5.99,9.36,4.53C142.45,456.65,139.65,455.3,136.65,453.85z M342.24,259.99c2.46-6.51,1.42-7.84-5.39-7.56
                C338.68,255,340.19,257.11,342.24,259.99z M131.15,442.88c3.09-4.84-0.69-6.64-3.71-8.75
                C128.11,437.2,125.61,441.56,131.15,442.88z M909.85,397.62c0-2.82,0-5.54,0-8.88C904.76,394.02,904.76,394.1,909.85,397.62z
                M143.78,221.85c1.42,3.44,2.71,6.54,3.99,9.65C147.77,222.15,147.77,222.15,143.78,221.85z M101.88,601.16
                c0.41-0.06,0.82-0.12,1.24-0.18c0.16-2.38,0.44-4.76,0.4-7.13c-0.01-0.72-0.93-1.42-1.44-2.13c-0.65,0.75-1.92,1.59-1.83,2.25
                C100.55,596.4,101.3,598.77,101.88,601.16z M742.03,335.79c-0.34,0.77-0.68,1.54-1.02,2.31c3.48,1.28,6.96,2.55,10.44,3.83
                c0.18-0.39,0.35-0.79,0.53-1.18C748.67,339.09,745.35,337.44,742.03,335.79z M115.3,683.86c-2.87-1.97-5.15-3.55-7.98-5.5
                C108.12,683.1,109.36,686.11,115.3,683.86z M134.02,418.53c-0.79,0.19-1.59,0.39-2.38,0.58c0.83,2.67,1.65,5.34,2.48,8
                c0.55-0.14,1.1-0.28,1.65-0.42C135.18,423.98,134.6,421.26,134.02,418.53z M180.15,843.99c0,3.13,0,5.55,0,8.72
                C182.96,849.56,184.25,847.15,180.15,843.99z M182.97,863.94c0.63-0.25,1.26-0.51,1.89-0.76c-0.92-2.18-1.85-4.37-2.77-6.55
                c-0.57,0.23-1.14,0.46-1.72,0.69C181.24,859.53,182.11,861.73,182.97,863.94z M188.42,1085.22c0,0.04,0,0.08,0,0.12
                c6.63,0,13.25,0,19.88,0c0-0.04,0-0.08,0-0.12C201.67,1085.22,195.05,1085.22,188.42,1085.22z M253.51,1472.79
                c0.4-5.48,0.4-5.48-3.56-4.51C250.99,1469.6,251.96,1470.82,253.51,1472.79z M512.52,1056.29c0.04,0.09,0.07,0.17,0.11,0.26
                c4.53,0,9.05,0,13.58,0c0-0.09,0-0.17,0-0.26C521.65,1056.29,517.09,1056.29,512.52,1056.29z M112.96,428.6
                c0.55-0.35,1.1-0.7,1.65-1.05c-1.34-1.43-2.67-2.86-4.01-4.29c-0.29,0.25-0.59,0.5-0.88,0.75
                C110.8,425.54,111.88,427.07,112.96,428.6z M1160.93,277.28c0.05-0.05,0.1-0.11,0.15-0.16c0-4.21,0-8.41,0-12.62
                c-0.05,0-0.1,0-0.15,0C1160.93,268.76,1160.93,273.02,1160.93,277.28z M265.32,1470.46c-0.28,0.56-0.55,1.13-0.83,1.69
                c1.51,0.39,3.02,0.79,4.53,1.18c0.15-0.54,0.29-1.08,0.44-1.62C268.08,1471.3,266.7,1470.88,265.32,1470.46z M93.06,674.95
                c0.22-0.45,0.44-0.9,0.66-1.35c-1.22-0.64-2.44-1.28-3.66-1.92c-0.24,0.52-0.48,1.04-0.72,1.57
                C90.58,673.81,91.82,674.38,93.06,674.95z M1085.35,2.69c-0.58-0.16-1.15-0.33-1.73-0.49c-0.28,1.35-0.55,2.7-0.83,4.05
                c0.33,0.1,0.66,0.2,0.98,0.3C1084.3,5.26,1084.82,3.97,1085.35,2.69z M145.58,675.48c-0.31,0.08-0.62,0.16-0.93,0.24
                c0.24,1.46,0.47,2.92,0.71,4.37c0.5-0.12,0.99-0.24,1.49-0.36C146.43,678.31,146.01,676.89,145.58,675.48z M100.1,428.35
                c-0.08,0.5-0.16,1-0.24,1.5c0.94,0.27,1.88,0.53,2.82,0.8c0.09-0.77,0.19-1.53,0.28-2.3C102.01,428.35,101.06,428.35,100.1,428.35
                z M167.77,419.52c-0.42,0.01-0.84,0.01-1.25,0.02c0.17,1.49,0.35,2.99,0.52,4.48c0.24-0.02,0.49-0.03,0.73-0.05
                C167.77,422.48,167.77,421,167.77,419.52z M122.26,530.81c-0.38-0.33-0.76-0.65-1.14-0.98c-0.8,0.85-1.61,1.7-2.41,2.54
                c0.44,0.38,0.88,0.76,1.32,1.14C120.77,532.61,121.51,531.71,122.26,530.81z M651.65,444.4c-0.08,0.02-0.16,0.03-0.24,0.05
                c0,2.25,0,4.49,0,6.74c0.08,0,0.16,0,0.24,0C651.65,448.92,651.65,446.66,651.65,444.4z M142.8,637.58
                c0.27,0.02,0.53,0.04,0.8,0.07c0.12-1.23,0.24-2.46,0.35-3.69c-0.31-0.02-0.62-0.05-0.93-0.07
                C142.95,635.11,142.88,636.35,142.8,637.58z M164.94,458.58c-0.32,0.24-0.63,0.48-0.95,0.73c0.69,0.78,1.39,1.57,2.08,2.35
                c0.17-0.14,0.35-0.29,0.52-0.43C166.04,460.35,165.49,459.46,164.94,458.58z M129.58,461.85c0,0.3,0,0.61,0,0.91
                c1.41,0,2.81,0,4.22,0c0.01-0.11,0.01-0.22,0.02-0.33C132.4,462.24,130.99,462.04,129.58,461.85z M94.16,463.92
                c0.04,0.32,0.07,0.64,0.11,0.96c0.82-0.09,1.63-0.17,2.45-0.26c-0.02-0.23-0.03-0.46-0.05-0.7
                C95.84,463.92,95,463.92,94.16,463.92z M89.56,467.71c-0.07,0.07-0.13,0.14-0.2,0.2c1.42,0,2.85,0,4.27,0c0-0.07,0-0.14,0-0.2
                C92.27,467.71,90.92,467.71,89.56,467.71z M135.47,441.37c-0.21-0.11-0.42-0.21-0.63-0.32c-0.21,0.8-0.43,1.59-0.64,2.39
                c0.23,0.06,0.45,0.12,0.68,0.17C135.07,442.86,135.27,442.11,135.47,441.37z M329.73,1228.96c0.02,0,0.03,0,0.05,0
                c0-0.8,0-1.61,0-2.41c-0.02,0-0.03,0-0.05,0C329.73,1227.35,329.73,1228.15,329.73,1228.96z M1117.11,955.57
                c-0.05,0-0.09,0-0.14,0c0,0.87,0,1.74,0,2.6c0.05,0,0.09,0,0.14,0C1117.11,957.31,1117.11,956.44,1117.11,955.57z M357.84,1.96
                c-0.01,0.09-0.02,0.18-0.02,0.27c0.86,0.16,1.71,0.32,2.57,0.48c0.02-0.21,0.05-0.42,0.07-0.63C359.59,2.05,358.72,2,357.84,1.96z
                M144.04,645.46c-0.23-0.09-0.45-0.19-0.68-0.28c-0.18,0.55-0.36,1.1-0.53,1.65c0.13,0.06,0.26,0.11,0.39,0.16
                C143.5,646.48,143.77,645.97,144.04,645.46z M1118.23,945.62c-0.11-0.02-0.23-0.04-0.34-0.06c0.03,0.59,0.06,1.19,0.09,1.78
                C1118.07,946.76,1118.15,946.19,1118.23,945.62z M202.01,426.8c-0.04,0-0.09,0-0.13-0.01c-0.02,0.41-0.04,0.82-0.06,1.22
                c0.04,0,0.09,0,0.13,0.01C201.97,427.61,201.99,427.21,202.01,426.8z M129.51,450.88c-0.17-0.24-0.34-0.48-0.51-0.73
                c-0.18,0.21-0.5,0.43-0.49,0.64c0.01,0.27,0.28,0.53,0.44,0.79C129.13,451.35,129.32,451.12,129.51,450.88z M103.71,461.67
                c0.09-0.24,0.2-0.48,0.24-0.73c0.01-0.05-0.3-0.24-0.37-0.2c-0.23,0.12-0.41,0.32-0.61,0.49
                C103.22,461.38,103.46,461.52,103.71,461.67z M276.95,252.5c0-0.18,0-0.34,0-0.49c-0.08,0.17-0.19,0.33-0.23,0.51
                c-0.04,0.14,0,0.3,0.01,0.45C276.81,252.8,276.89,252.63,276.95,252.5z M120.96,1082.14c1,0.23,1.12,0.28,1.22,0.27
                c0.05-0.01,0.1-0.12,0.14-0.19C122.18,1082.21,122.03,1082.2,120.96,1082.14z M877.45,625.66c-0.25,0.96-0.3,1.08-0.29,1.19
                c0.01,0.06,0.14,0.1,0.21,0.15C877.38,626.85,877.39,626.69,877.45,625.66z M118.88,636.29c-0.01,0.16-0.04,0.32-0.02,0.48
                c0.01,0.07,0.14,0.11,0.21,0.17c0.01-0.16,0.04-0.32,0.02-0.48C119.08,636.39,118.96,636.34,118.88,636.29z M970.2,1117.84
                c0,0.29,0,0.59,0,0.88C970.2,1118.43,970.2,1118.13,970.2,1117.84z M544.04,277.87c0,0.14-0.04,0.38,0.01,0.4
                c0.17,0.07,0.37,0.07,0.57,0.1c-0.05-0.14-0.07-0.31-0.17-0.41C544.36,277.88,544.19,277.9,544.04,277.87z M434.84,274.96
                c-0.23,0.95-0.28,1.06-0.27,1.17c0.01,0.05,0.13,0.1,0.2,0.14C434.77,276.13,434.78,275.98,434.84,274.96z M181.06,1357.33
                c0-0.15,0-0.31,0-0.46c-0.08,0.04-0.22,0.07-0.23,0.12c-0.03,0.15,0,0.31,0,0.46C180.91,1357.41,180.99,1357.37,181.06,1357.33z
                M194.08,670.4c-0.07-0.06-0.14-0.12-0.22-0.19c-0.03,0.13-0.1,0.27-0.07,0.39c0.02,0.09,0.18,0.14,0.28,0.21
                C194.07,670.67,194.07,670.53,194.08,670.4z M168.69,432.1c0.11,0.03,0.22,0.06,0.32,0.09c-0.01-0.16,0.02-0.34-0.03-0.49
                c-0.02-0.06-0.21-0.06-0.33-0.09C168.66,431.78,168.67,431.94,168.69,432.1z M400.81,595.96c-0.11-0.07-0.22-0.21-0.32-0.2
                c-0.11,0.01-0.21,0.14-0.32,0.22c0.17,0.12,0.34,0.24,0.51,0.36C400.73,596.21,400.77,596.08,400.81,595.96z M124.39,421.54
                c-0.23-0.04-0.47-0.08-0.7-0.12c0.02,0.14-0.01,0.39,0.06,0.41c0.21,0.09,0.46,0.09,0.69,0.13
                C124.42,421.82,124.4,421.68,124.39,421.54z M280.9,1261.79l-0.04-0.34l-0.14,0.21L280.9,1261.79z M435.6,270.31l0.04,0.35
                l0.15-0.21L435.6,270.31z M629.54,330.2l-0.13,0.28l0.19,0.05L629.54,330.2z M627.53,321.21l-0.14,0.29l0.2,0.05L627.53,321.21z
                M185.52,657.1c-0.02,0.13-0.1,0.29-0.05,0.39c0.04,0.08,0.23,0.08,0.35,0.11c0.02-0.13,0.1-0.29,0.05-0.39
                C185.84,657.14,185.65,657.14,185.52,657.1z M112.92,531.62c0.07-0.28,0.11-0.47,0.16-0.65c-0.13,0.05-0.26,0.09-0.4,0.14
                C112.74,531.24,112.81,531.38,112.92,531.62z M128.04,600.95l-0.17,0.35l0.27,0.05L128.04,600.95z"/>
            <path onClick={() => props.onFill(0)} fill={props.fillColors[0]} d="M311.29,1291.66c1.08-0.27,2.83-0.22,3.13-0.86c3.19-6.81,6.96-13.52,10.59-19.89
                c5.87-10.32,2.75-21.11,5.58-31.28c0.43-1.56,1.43-2.96,1.84-4.52c0.53-2.02,1.28-4.21,1.01-6.2c-2.56-18.34-0.82-36.73-0.76-55.1
                c0.06-15.96-5.72-30.02-14.5-42.82c-2.37-3.45-7.31-5.21-11.23-7.48c-1.53-0.89-3.56-0.87-5.23-1.58
                c-3.32-1.42-5.9-3.78-4.78-7.68c1.12-3.88,4.54-5.51,8.27-4.84c4.56,0.83,9.02,2.33,13.42,3.82c11.09,3.75,22.06,6.1,34.15,5.31
                c8.44-0.55,17.2,3.08,25.74,5.15c11.15,2.7,22.21,5.75,33.34,8.52c1.53,0.38,3.48,0.5,4.82-0.14c1.3-0.63,2.44-2.27,2.87-3.7
                c0.2-0.67-1.25-2.26-2.29-2.8c-5.57-2.92-11.25-5.65-17.58-8.79c38.07-1.98,75.33-3.92,112.59-5.86c0-0.76,0-1.52,0-2.29
                c-16.57-0.62-33.15-1.01-49.7-1.93c-16.64-0.93-33.62,2-49.89-3.58c-0.01-0.54-0.01-1.08-0.02-1.62
                c26.54-0.72,53.08-1.45,79.62-2.17c0.01-0.78,0.02-1.56,0.03-2.34c-33.05,0.42-65.99-0.85-98.82-3.78
                c-0.01-0.75-0.03-1.49-0.04-2.24c4.8-0.61,9.61-1.75,14.4-1.72c13.14,0.08,26.28,0.5,39.41,1.05c19.28,0.81,38.55,1.84,57.82,2.79
                c3.99,0.2,7.97,0.49,11.96,0.61c2.04,0.06,4.09-0.18,6.13-0.29c0.06-0.51,0.12-1.01,0.17-1.52c-1.73-0.41-3.43-1.05-5.18-1.2
                c-29.99-2.47-59.99-4.74-89.96-7.41c-6.79-0.61-13.46-2.6-20.18-3.96c0.08-0.71,0.16-1.42,0.23-2.13
                c7.68-0.68,15.35-1.83,23.03-1.97c31.45-0.58,62.92-0.71,94.37-1.3c13.79-0.26,27.59-1.03,41.36-1.93
                c3.36-0.22,6.63-1.88,9.94-2.88c-0.06-0.7-0.11-1.4-0.17-2.1c-2.81-0.51-5.62-1.42-8.44-1.47c-26.63-0.44-53.26-0.83-79.89-1
                c-8.3-0.05-16.61,0.71-24.92,0.88c-2.77,0.06-5.55-0.59-8.31-1.24c27.87-7.42,56.5-5,84.92-5.12c-0.02-0.93-0.03-1.86-0.05-2.79
                c-16.42-5.53-34.2,0.05-50.8-6.31c8.73-1.28,17.43-3.05,26.21-3.71c8.61-0.64,17.32,0.09,25.96-0.26
                c5.35-0.21,10.84-0.93,15.66-7.11c-5.83-0.88-10.41-1.9-15.04-2.22c-17.59-1.19-35.19-2.11-52.78-3.27
                c-1.59-0.1-3.1-1.24-4.65-1.9c1.31-1.02,2.51-2.25,3.95-3.02c2.57-1.38,5.28-2.48,8.63-5.3c-1.92-0.99-3.83-1.98-5.65-2.92
                c1.23-2.33,1.89-3.58,3.07-5.81c-3.15-0.37-5.66-0.93-8.16-0.9c-22.97,0.26-45.93,0.89-68.9,0.86
                c-16.3-0.02-32.61-0.63-48.89-1.55c-4.27-0.24-8.63-2.21-12.59-4.12c-6.89-3.32-13.37-7.49-20.28-10.75
                c-4.15-1.96-4.62-5.4-4.93-8.99c-0.07-0.88,1.56-2.38,2.69-2.76c1.51-0.5,3.29-0.2,4.95-0.2c53.61-0.01,107.23,0.01,160.84-0.03
                c4.74,0,9.51-1.1,13.02,3.89c0.87,1.23,4.25,0.95,6.46,0.92c2.24-0.03,4.46-0.6,6.69-0.94c0.13,0.51,0.26,1.01,0.4,1.52
                c-9.92,3.07-19.85,6.14-29.77,9.21c-0.06,0.32-0.13,0.64-0.19,0.96c1.91,0.51,3.82,1.43,5.74,1.46
                c16.46,0.21,32.93-0.12,49.37,0.45c15.24,0.53,30.65-0.89,45.69,3.1c2.21,0.59,4.62,0.58,6.94,0.59c13.48,0.05,26.97,0.05,40.45,0
                c1.98-0.01,4.12,0.06,5.91-0.62c15.78-6.02,31.63-3.43,47.57-0.86c6.21,1,12.58,1.22,18.89,1.39c8.46,0.23,16.93,0.06,25.58,0.06
                c0.62,4.13,1.15,7.69,1.67,11.26c0.33,2.28,1.08,3.66,3.91,3.01c2.98-0.68,4.53,0.22,4.26,3.96c-0.41,5.83,4.24,8.56,8.37,11.32
                c1.2,0.8,2.75,1.08,5.58,2.14c-8.11,1.38-14.87,2.53-21.63,3.69c7.78,5.33,15.41,9.23,24.93,8.29c7.54-0.75,15.21-0.15,23.32-0.15
                c-0.07,1.5,0.05,2.97-0.24,4.35c-2.6,12.37-5.37,24.71-7.85,37.11c-0.8,3.99-2.65,5.5-6.77,5.41c-10.29-0.22-20.59-0.05-30.89,0
                c-1.58,0.01-3.16,0.22-4.74,0.34c-0.18,0.57-0.37,1.13-0.55,1.7c3.94,1.89,7.88,3.78,11.82,5.68c0,0.4,0,0.79,0,1.19
                c-19.5,0-39.04-0.73-58.5,0.21c-19.64,0.95-39.3,2.84-58.51,7.39c-0.24,0.62-0.49,1.23-0.73,1.85c2.46,1.88,4.65,4.63,7.42,5.48
                c8.54,2.62,17.28,4.62,25.99,6.64c4.03,0.94,8.17,1.37,12.31,3.06c-14.3,1.1-28.6,2.19-42.89,3.29c-0.04,0.77-0.08,1.55-0.12,2.32
                c9.31,6.87,20.7,7.33,30.79,10.15c1.31,1.41,2.36,3.56,3.96,4.09c7.87,2.62,15.88,4.82,23.84,7.14c1.09,0.32,2.21,0.52,4.76,1.12
                c-35.02,3.57-69.07,0.59-102.35,7.15c-0.2,0.67-0.39,1.35-0.59,2.02c2.88,1.53,5.62,3.45,8.66,4.51
                c13.48,4.66,27.46,5.02,41.45,3.71c22.8-2.14,45.52-1.42,68.23,1.02c6.74,0.73,13.48,1.4,20.2,2.37
                c-22.75,4.36-46.24,2.38-69.55,8.02c4.53,4.06,10.32,5.45,28.34,8.17c-8.13,0-16.25,0-24.38,0c-0.12,0.52-0.25,1.04-0.37,1.56
                c1.6,0.78,3.13,1.97,4.82,2.28c11.27,2.08,22.5,4.81,33.87,5.76c22.2,1.84,44.48,2.65,59.38,3.48c-9.26,0-25.91,0-42.55,0
                c-0.17,0.53-0.34,1.06-0.5,1.59c2.21,1.08,4.31,2.63,6.64,3.16c12.47,2.83,24.95,5.68,37.52,7.99
                c11.09,2.04,22.31,3.39,34.02,5.12c-15.12,0.7-29.65,1.37-44.19,2.05c-0.19,0.69-0.38,1.39-0.57,2.08
                c2.48,1.59,4.76,3.77,7.46,4.67c9.94,3.27,20.01,6.12,30.04,9.1c1.69,0.5,3.43,0.82,6.26,1.47c-3.45,0.78-5.76,1.3-8.07,1.82
                c-0.08,0.52-0.17,1.04-0.25,1.56c12.3,10.16,28,11.92,43.24,16.37c-17.08-1.69-33.58-3.5-50.12-4.93
                c-16.48-1.42-32.98-1.92-49.37,0.45c-0.14,0.55-0.27,1.09-0.41,1.64c1.56,0.93,3.01,2.36,4.69,2.72
                c12.64,2.74,25.22,6.07,38.01,7.71c29.19,3.74,58.47,6.71,87.74,9.82c30.59,3.25,61.2,6.27,91.8,9.44
                c12.4,1.29,24.79,2.71,37.18,4.11c0.93,0.11,1.83,0.56,3.45,1.08c-2.11,1.95-3.67,3.65-5.47,5.01
                c-10.9,8.22-21.51,16.88-32.94,24.28c-5.05,3.27-11.73,4.2-17.81,5.62c-3.17,0.74-6.63,0.27-9.96,0.29
                c-13.74,0.12-27.12,1.27-37.2,13.99c-8.81-12.04-22.15-13.81-34.26-18.18c-0.25,0.35-0.5,0.71-0.75,1.06
                c0.99,0.92,2.04,1.77,2.94,2.76c1.09,1.21,2.99,2.6,2.91,3.8c-0.47,6.71,4.14,9.65,8.54,13.03
                c-15.04-9.73-30.09-19.47-45.85-29.66c0.3,7.63,6.92,10.04,8.66,13.74c-0.37,2.48-1.12,4.34-0.63,4.75
                c3.26,2.79,6.78,5.27,10.22,7.85c0,0,0,0,0,0c-0.66,0.67-1.33,1.34-1.99,2.01l0,0c-13.03-10.63-28.24-16.46-44.5-19.7
                c-3.79-0.75-7.86-0.11-12.68-0.11c2.99,4.8,9.38,5.52,8.04,11.78c1.54-0.11,2.8-0.2,4.25-0.3c-0.39,1.36-0.76,2.64-1.38,4.78
                c2.58-0.6,4.44-1.03,6.3-1.46l0.01,0.01c-0.81,1-1.62,2-2.43,2.99c0.24,0.32,0.47,0.64,0.71,0.96c2.24-0.65,4.47-1.3,6.71-1.96
                l0,0c-1.55,2.04-3.11,4.09-4.66,6.13c0.31,0.48,0.62,0.96,0.92,1.44c3.24-1.19,6.48-2.37,9.73-3.56c0,0-0.02-0.03-0.02-0.03
                c-2.02,2.83-4.04,5.65-6.05,8.48c0.39,0.5,0.77,1,1.16,1.5c3.31-1.66,6.61-3.31,9.92-4.96l-0.02-0.01
                c-1.76,2.24-3.52,4.49-5.28,6.73c0.42,0.52,0.84,1.04,1.26,1.57c4.35-2.43,8.69-4.86,13.04-7.29l-0.02-0.02
                c-3.74,4-8.2,7.52-9.61,13.09c0.36,0.53,0.72,1.06,1.07,1.59c4.52-2.23,9.04-4.46,13.55-6.7c0,0,0.04,0.04,0.04,0.04
                c-2.87,3.44-5.73,6.89-8.6,10.33c0.51,0.67,1.02,1.34,1.53,2.01c3.67-1.79,7.34-3.59,11.01-5.38c0,0,0.04,0.04,0.04,0.04
                c-2.01,2.77-6.26,4.3-4.98,8.92c0,0-0.04,0.05-0.04,0.05c-15.16-6.55-31.52-5.12-47.51-7.63c2.25,6.99,14.25,13.75,23.51,13.63
                l0.01,0.01c0.72,1.33,1.43,2.67,2.43,4.53c-1.15,0.46-2.59,1.06-4.05,1.62c-19.58,7.45-39.27,14.62-58.72,22.4
                c-18.97,7.58-38.25,14.04-58.78,14.61c-10.04,0.28-20.21-1.49-30.22-2.99c-19.89-2.96-39.71-6.67-59.86-6.94
                c-8.07-0.11-16.57,0.14-24.18,2.48c-26.04,8.01-52.94,11.19-79.55,16.2c-20.97,3.94-41.03,1.46-61.28-2.73
                c-28.18-5.83-54.19-0.61-78.32,14.76c-4.35,2.77-8.68,5.58-13,8.4c-7.41,4.83-15.3,7.59-24.39,7.45
                c-11.47-0.17-22.95,0.78-34.42,0.74c-6.96-0.02-13.92-1.04-20.89-1.48c-3.12-0.2-4.62-1.34-5.08-4.74
                c-3.13-23.23-6.82-46.39-9.65-69.65c-1.92-15.79-3.37-31.73,0.75-47.47c0.5-1.91,1.54-4.27,3.07-5.22
                c7.88-4.87,12.31-12.22,16.21-20.28c2.21-4.58,5.53-8.63,8.38-12.9c3.22-4.83,6.39-9.7,9.76-14.43c0.92-1.3,2.49-2.14,4.68-3.95
                c1.02-4.51,2.33-10.33,3.65-16.15c0.69,0.13,1.38,0.27,2.08,0.4c-0.52,2.7-1.04,5.41-1.64,8.58c1.23-0.37,2.04-0.61,2.73-0.81
                c-0.68,3.78-1.36,7.6-2.05,11.42c0.6,0.15,1.21,0.31,1.81,0.46c1.2-4.38,2.39-8.77,3.53-12.95c4.61,2.54,3.45-3.14,5.49-4.25
                c0,0-0.03-0.03-0.03-0.03c-2.28,5.16-4.56,10.32-6.84,15.48c0.35,0.24,0.69,0.48,1.04,0.72c1.61-2.4,3.21-4.8,4.82-7.19
                l-0.02-0.02c-1.11,5.33-2.21,10.66-3.32,15.99c0.55,0.16,1.1,0.33,1.65,0.49c0.9-2.16,1.79-4.31,2.69-6.47
                C311.32,1291.68,311.29,1291.66,311.29,1291.66z M537.9,1319.96c34.93-4.2,68.39-8.22,101.85-12.24c0.05-0.92,0.1-1.84,0.15-2.76
                c-27.2-11.06-56.93-12.09-84.94-20.54c13.3,0,26.61,0,39.91,0c0.08-0.43,0.15-0.85,0.23-1.28c-15.88-9.91-34.36-13.36-51.69-20.03
                c9.83-1.42,19.56-2.86,29.3-2.94c23.62-0.19,47.25,0.3,70.87,0.38c3.84,0.01,7.68-0.68,11.52-1.05c0.15-0.5,0.3-1,0.45-1.5
                c-2.84-1.81-5.58-3.81-8.55-5.39c-9.74-5.15-20.39-7.01-31.18-8.21c-12.48-1.38-24.96-2.65-37.45-3.97
                c15-2.48,30.27-0.06,45.06-3.59c0.14-0.68,0.27-1.37,0.41-2.05c-6.34-1.93-12.68-3.85-17.82-5.41c1.66-2.05,3.65-4.52,6.12-7.57
                c-2-0.57-3.64-1.39-5.31-1.46c-16.27-0.66-32.55-1.58-48.83-1.6c-7.99-0.01-15.99,2.48-23.97,2.45
                c-10.11-0.05-20.21-1.51-30.31-2.38c-4.15-0.36-8.22-0.49-11.69,3.56c7.08,6.87,16.15,8.32,25.24,11.1
                c-5.37,1.15-10.02,2.14-14.66,3.13c0.02,0.55,0.05,1.1,0.07,1.65c2.19,1.29,4.38,2.58,7.57,4.46c-3.06,0.73-5.07,1.5-7.12,1.65
                c-14.92,1.06-29.84,1.98-44.76,2.98c-25.21,1.7-50.43,3.36-75.63,5.23c-4.91,0.37-9.76,1.64-14.64,2.5
                c-0.03,0.73-0.06,1.46-0.09,2.19c3.67,1.22,7.27,3.12,11.03,3.56c16.17,1.89,32.4,3.33,48.6,4.96
                c23.48,2.36,46.97,4.67,70.44,7.18c4.12,0.44,8.14,1.77,12.2,2.69c-1.82,1.71-3.48,2.49-5.22,2.74
                c-13.97,2.06-27.9,4.59-41.95,5.86c-18.04,1.62-36.19,2.13-54.28,3.27c-4.24,0.27-8.44,1.19-12.66,1.8
                c0.03,0.6,0.05,1.21,0.08,1.81c17.69,6.61,37.15,4.69,55.05,10.68c-23.28,6.95-47.51,4.19-71.23,7.44
                c13.75,6.76,28.84,6.78,43.27,10.24c-7.52,0.78-14.98,0.78-22.45,0.78c-14.48,0-28.99-0.5-43.44,0.19
                c-10.23,0.49-20.41,2.46-30.56,4.06c-2.41,0.38-4.64,1.89-7.96,3.31c2.57,1.86,3.87,3.39,5.48,3.87
                c7.45,2.22,14.88,4.82,22.52,6.06c29.51,4.79,59.37,3.94,89.11,4.73c33.27,0.89,66.56,0.76,99.83,1.49
                c22.61,0.5,45.2,2.29,67.8,2.45c20.77,0.15,41.56-1.03,62.33-1.81c4.12-0.16,8.21-1.18,13.54-1.98c-1.73-1.62-2.21-2.41-2.87-2.63
                C632.02,1325.71,585.18,1326.32,537.9,1319.96z"/>
            <path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M358.25,544.21c8.54,0.89,16.77,4.73,25.63,2.61c0.62-0.15,1.57-0.24,1.93,0.11
                c6.47,6.12,15.1,0.35,21.99,4.04c0.4,0.21,1.18,0.31,1.44,0.08c4.69-4.26,8.5-0.46,12.61,1.22c5.94,2.44,14.09,1.17,17.81-2.87
                c6.61,3.56,13.35,5.56,19.4-4.12c1.9,16.98,3.59,32.05,5.28,47.11c0.55,0.16,1.09,0.32,1.64,0.48c2.42-1.96,4.84-3.92,7.99-6.46
                c1.34,9.43,2.68,18.8,4.01,28.17c0.52,0.05,1.04,0.1,1.55,0.15c1.82-9.27,3.65-18.53,5.31-26.98c8.4-1.01,7.17-8.8,11-14.73
                c4.32,13.12,8.23,25.03,12.15,36.93c0.26,0.05,0.53,0.1,0.79,0.15c0.53-5.11,0.99-10.24,1.62-15.34c0.55-4.45,1.18-8.9,2.01-13.3
                c0.33-1.75,1.08-3.54,2.06-5.04c2.64-4.06,6.33-3.7,8.89,0.39c1.21,1.94,3.16,3.42,4.77,5.1c0.34-0.29,0.67-0.57,1.01-0.86
                c0.15,0.28,0.47,0.59,0.44,0.85c-1.2,9.92,3.5,18.74,5.67,27.96c2.12,9.02,2.16,17.69,1.02,26.64c-0.34,2.63-0.05,5.34,0.84,8.1
                c4.92-10.3,5.02-21.62,7.43-32.38c0.47,0.01,0.93,0.01,1.4,0.02c0.46,10.32,0.91,20.65,1.37,30.97c0.49,0.02,0.97,0.03,1.46,0.05
                c2.61-22.85,5.23-45.7,7.84-68.56c1.67,2.39,3.37,4.82,5.58,7.98c1.41-2.23,2.14-3.39,2.98-4.73c0.16,0.57,0.39,1,0.38,1.43
                c-0.11,4.32-0.41,8.64-0.35,12.96c0.05,3.26-0.8,6.94,3.93,8.45c1.09,0.35,1.7,3.42,1.83,5.28c0.27,4.04,0.08,8.11,1.03,12.23
                c5.18-15,4.23-31.05,8.14-46.16c3.4,2.74,6.45,5.21,9.51,7.68c0.47-0.3,0.95-0.61,1.43-0.91c0.71,10.78,1.41,21.56,2.12,32.34
                c0.58,0.06,1.15,0.11,1.73,0.17c2.53-12.59,5.07-25.17,7.6-37.76c0.62-0.06,1.23-0.13,1.85-0.19c1.09,2.77,2.6,5.46,3.19,8.33
                c1.59,7.81,2.85,15.68,4.11,23.55c0.4,2.48,0.66,4.56,3.77,2.27c0.13,0.3,0.31,0.58,0.37,0.88c1.98,9.78,1.81,9.98-8.27,10.09
                c-9.74,0.11-18.19,8.65-18.81,19.1c-0.16,2.65-0.02,5.32-0.02,8.39c-6.76,1.31-9.55,5.79-9.84,12.38
                c-0.05,1.08-1.5,2.46-2.64,3.07c-4.69,2.49-7.32,6.2-7.44,11.53c-0.07,2.93-1.53,3.25-4.23,4.03c-5.89,1.7-11.56,1.08-17.44,1.04
                c-3.17-0.03-7.14,2.28-9.35,4.77c-2.68,3-5.25,4.08-9,3.81c-5.44-0.39-10.64,0.42-15.04,4.06c-3.05,2.53-6.05,5.06-2.39,10.32
                c-6.18,0.95-11.54,1.72-16.88,2.61c-3.61,0.6-7.23,1.2-10.76,2.13c-1.23,0.33-2.2,1.65-3.29,2.52c1.21,0.92,2.3,2.31,3.66,2.65
                c2.06,0.52,4.3,0.41,6.46,0.39c24.3-0.31,48.59-0.65,72.89-1.02c1.32-0.02,2.96,0.14,3.9-0.55c8.28-6.09,17.38-2.49,26.08-2.16
                c13.95,0.54,27.87,1.84,41.82,2.62c15.27,0.85,30.59,1.08,45.83,2.36c7.89,0.66,15.62,3.16,23.47,4.53
                c5.88,1.03,11.83,1.75,17.78,2.28c3.77,0.34,5.87,1.49,5.36,5.76c-0.24,1.99,0.33,4.08,0.53,6.13c0.56,0.08,1.12,0.16,1.69,0.24
                c0.44-1.85,1.19-3.68,1.29-5.55c0.76-14.79,0.88-29.63,2.25-44.35c0.83-8.96,3.52-17.75,5.37-26.62c0.86,0.04,1.71,0.08,2.57,0.13
                c1.29,18.33,2.57,36.66,3.85,55c0.47,0.06,0.94,0.13,1.41,0.19c1.75-7.57,3.5-15.13,5.25-22.7c0.45-0.04,0.9-0.07,1.35-0.11
                c0.56,10.04,1.12,20.07,1.68,30.11c0.46,0.02,0.93,0.03,1.39,0.05c0.38-1.49,0.98-2.96,1.11-4.48
                c0.97-10.93,1.82-21.87,2.78-32.81c1.66-19.04,3.23-38.1,5.19-57.11c0.43-4.18,2.31-8.2,3.52-12.3c0.68,0,1.37,0.01,2.05,0.01
                c1.44,14.05,3.43,28.08,4.15,42.17c0.72,14.04,0.16,28.15,1.17,42.29c6.19-23.56,4.64-48.26,10.95-71.69
                c0.61-0.03,1.22-0.07,1.82-0.1c2.62,22.66,5.23,45.32,7.85,67.98c0.46,0.15,0.91,0.3,1.37,0.45c0.91-2.21,2.3-4.34,2.67-6.64
                c3.89-24.46,7.61-48.94,11.41-73.42c0.25-1.59,0.78-3.13,1.17-4.65c9.24,1.67,14,6.37,14.02,15.1
                c0.03,12.47-0.74,24.94-1.11,37.41c-0.33,10.98-0.49,21.96-0.9,32.94c-0.57,15.29-1.22,30.58-2.09,45.86
                c-0.08,1.4-1.79,3.19-3.19,3.92c-2.62,1.37-5.79,1.72-8.33,3.2c-2.23,1.3-3.9,3.56-6.57,6.12c-3.44-1.89-6.3,1.14-10.07,3.32
                c-3.7,2.14-8.95,1.4-13.39,2.48c-4.83,1.17-8.29,0.21-11.97-3.48c-8.52-8.55-17.78-7.26-25.03,2.81
                c-2.35-1.38-4.62-3.66-7.06-3.86c-2.19-0.18-4.56,1.95-6.47,2.87c-7.42-1.12-14.29-7.12-22.3-1.47
                c-16.33-19.16-38.17-18.52-60.06-15.57c-10.46,1.41-19.71,6.53-22.6,18.99c-5.22-7.08-10.92-3.15-16.3-0.83
                c-6.39,2.76-6.73,9.38-8.35,14.97c-8.44-0.68-8.4-0.67-11.07,7.73c-0.31,0.97-1.16,2.1-2.05,2.49c-6.52,2.82-6.55,2.77-8.85,8.39
                c-14.14-7.08-24.12-2.73-33.65,18.56c-14.68-1.18-17.38,0.12-21.39,10.7c-6.13-3.31-8.73,0.57-12.31,5.04
                c-2.37,2.96-6.55,6.41-11.68,5.05c-7.14-1.9-13.04-0.09-18.1,5.29c-2.21,2.34-4.93,4.2-7.29,7.28c27.02-0.73,53.7,1.19,81.05,3.55
                c-1.68,4.5-0.98,8.45-7.11,8.23c-3.34-0.12-6.82,3.4-10.2,5.32c-0.84,0.48-1.64,1.73-2.33,1.65c-6.02-0.74-6.86,3.47-8.21,7.77
                c-1.3,4.13-3.4,8.01-5.07,11.84c-7.46-1.3-11.34,3.23-15.51,7.13c-0.57,0.54-0.78,1.98-1.26,2.03c-6.73,0.74-6.89,6.94-8.77,11.2
                c-1.25,2.84-0.85,6.73-0.25,9.97c0.75,4.04,2.61,7.87-3.21,9.65c-0.77,0.24-1.75,2.02-1.54,2.81c1.13,4.36-0.93,7.84-2.32,11.69
                c-2.22,6.15-0.29,10.45,5.2,13.94c2.88,1.83,5.78,3.64,8.61,5.54c2.35,1.58,1.09,6.21-2.22,8.31c-6.58,4.17-6.89,5.91-2.57,12.73
                c1.29,2.04,2.25,4.28,3.81,7.3c-4.42-0.22-7.83-0.53-11.23-0.54c-19.15-0.07-38.29-0.21-57.44,0.01c-4.94,0.06-7.65-1.6-9.32-6.24
                c-0.82-2.28-3.04-4.06-4.69-6.02c-0.72-0.86-1.95-1.47-2.33-2.43c-2.33-5.86-7.2-6.07-12.08-5.06c-3.9,0.8-6.86,0.23-9.31-2.88
                c-0.31-0.39-0.62-0.9-1.04-1.06c-4.25-1.54-8.43-3.45-12.82-4.35c-2.81-0.58-4.59-1.34-5.39-4.14c-0.98-3.43-3.29-4.75-6.84-4.19
                c-3.77,0.59-7.47,1.07-10.72-2.03c-0.79-0.75-2.81-0.23-4.27-0.25c-4.45-0.06-8.9,0.03-13.33-0.29c-0.77-0.06-1.84-1.77-2.03-2.85
                c-0.8-4.4-1.48-8.83-1.9-13.28c-0.41-4.38-0.65-8.64-5.06-11.25c-0.71-0.42-1.02-1.91-1.11-2.94c-0.66-7.03-2.48-13.28-9.16-17.22
                c-2.93-1.73-5.54-4.12-7.96-6.55c-1.03-1.03-1.58-2.89-1.7-4.43c-2.11-26.69-4.91-53.36-5.96-80.1
                c-1.4-35.57-1.52-71.2-1.95-106.8c-0.13-10.64,0.64-21.29,0.83-31.94c0.06-3.43,0.79-4.62,4.69-3.98
                c3.27,0.54,6.96-0.12,10.2-1.12c4.58-1.42,4.1-5.43,3.49-9.18c-0.74-4.56,0.36-5.48,4.71-3.92c0.16,0.06,0.3,0.19,0.45,0.21
                c8.16,0.95,16.31,1.89,24.47,2.82c1.48,0.17,3.06,0.73,4.43,0.41c2.68-0.62,5.25-1.73,7.87-2.64c-1.21-2.44-2.41-4.88-3.64-7.37
                c5.01,1.55,10.95,3.39,18.31,5.66c1.12,2.73,3.91,5.26,9.49,4.43c3.07-0.46,6.72,0.65,9.64,2.04c7.6,3.59,15.47,5.01,23.79,4.87
                c0.82-0.01,1.86,1.56,2.46,2.59c1.92,3.3,4.36,4.24,8.3,3.57c3.86-0.65,8-0.21,11.95,0.26c4.69,0.56,9.3,2.16,13.98,2.37
                c3.62,0.16,7.31-1.09,10.96-1.79c1.11-0.21,2.22-0.59,3.26-1.06c2.1-0.95,5.81-1.88,5.9-3.05c0.2-2.68-0.72-5.98-2.37-8.13
                c-3.04-3.97-7.2-7.07-10.3-11c-1.01-1.28-0.16-4.03-0.16-6.1c0.42-0.04,0.83-0.07,1.25-0.11c-2.8-4.45-5.6-8.89-8.41-13.34
                c-0.18-0.28-0.35-0.62-0.62-0.78c-6.35-3.81-12.72-7.58-19.06-11.4c-4.55-2.74-9.35-4.56-14.77-4.34c-0.3,0.01-0.78-0.18-0.88-0.4
                c-3.43-7.83-11.18-7.12-17.63-8.99c-0.71-0.21-1.3-0.83-2.92-1.91c5.93-2.37,8.25-8.8,15.05-8.69c2.26,0.04,7.65-7.4,7.36-9.81
                c-0.1-0.82-0.89-1.99-1.61-2.23c-3.88-1.29-7.87-3.35-11.78-3.26c-7.26,0.17-13.17-2.76-19.36-5.68c-2.41-1.14-5.39-1.06-8.3-0.9
                c-4.33-4.24-9.54-7.04-15.91-7.92c-1.86-0.26-3.48-1.95-5.35-2.56c-3-0.98-6.11-1.61-9.52-1.77
                C352.54,549.06,358.61,547.43,358.25,544.21C358.26,544.2,358.25,544.21,358.25,544.21z"/>
            <path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M1093.55,1340.62c-3.84,16.51-12.37,30.75-21.61,44.65c-1.17,1.76-1.96,3.76-2.92,5.65
                c0.63,0.44,1.27,0.89,1.9,1.33c6.22-7.11,12.45-14.22,18.67-21.33c0.41,0.06,0.81,0.11,1.22,0.17
                c-1.04,5.17-1.48,10.55-3.27,15.44c-2.21,6.04-5.69,11.61-8.27,17.53c-0.82,1.88-0.52,4.25-0.74,6.4
                c2.03-0.22,4.39,0.15,6.02-0.77c5.48-3.12,10.7-6.7,16.02-10.1l-0.02-0.02c-9.28,21.52-21.68,41.24-35.21,60.26
                c-4.81,6.77-10.27,13.07-15.39,19.62c-0.67,0.86-1.13,1.9-1.19,3.47c13.15-3.62,22.12-13.86,33.79-21.24
                c-2.57,2.89-4.46,5.43-6.76,7.53c-9.82,8.98-19.74,17.83-29.66,26.71c-0.85,0.76-1.89,1.84-2.86,1.86
                c-6.73,0.16-13.46,0.09-21.69,0.09c3.09-5.11,5.49-9.17,7.99-13.17c4.76-7.61,9.96-14.97,14.28-22.82
                c5.87-10.7,10.17-22.06,10.63-34.47c0.12-3.15-0.51-6.32-0.8-9.48c-0.68-0.11-1.35-0.22-2.03-0.33c-1.95,4.3-3.91,8.61-6.3,13.88
                c-1.16-5.9-2.11-10.73-3.06-15.56c-0.63,0.03-1.26,0.07-1.89,0.1c-0.36,2.14-1.15,4.31-1,6.41c0.77,10.94-2.28,21.01-6.07,31.07
                c-2.63,6.98-4.17,14.37-6.48,21.48c-2.16,6.63-4.54,13.2-7.19,19.65c-0.6,1.47-2.66,3.32-4.07,3.35
                c-13.65,0.23-27.3,0.12-40.95,0.07c-0.43,0-0.86-0.38-2.67-1.23c4.91-7.49,9.13-15.2,14.55-21.94
                c10.43-12.96,21.66-25.29,32.31-38.08c2.73-3.28,4.62-7.27,6.71-11.04c0.55-0.99,0.4-2.36,0.78-5.05
                c-2.48,1.63-3.87,2.34-5.02,3.33c-9.2,7.88-18.3,15.87-27.53,23.72c-15.05,12.81-28.51,27.02-38.94,43.91
                c-4.45,7.21-5.66,7.74-15.5,5.63c14.9-33.82,37.41-60.5,69.83-78.62c-0.27-0.54-0.54-1.08-0.82-1.62
                c-1.79,0.54-3.75,0.78-5.35,1.66c-21.71,11.91-41.85,25.85-58.96,44.02c-10.83,11.51-22.31,22.41-33.71,33.36
                c-1.44,1.39-4.19,1.94-6.34,1.97c-13.32,0.18-26.64,0.09-39.96,0.09c-68.1,0-136.2,0-204.3,0c-1.92,0-3.84,0-5.76,0
                c-0.13-0.51-0.25-1.01-0.38-1.52c5.1-2.17,10.1-4.61,15.31-6.46c8.93-3.17,17.87-6.42,27.01-8.84c12.33-3.26,24.76-1.9,37.05,0.92
                c3.89,0.89,7.74,2,11.65,2.8c1.32,0.27,2.78-0.13,4.18-0.22c-0.42-1.48-0.44-3.29-1.33-4.4c-12.3-15.37-27.83-25.58-47.39-29.19
                c-20.6-3.81-38.59,1.56-52.62,17.01c-11.63,12.81-26.17,21.06-41.12,28.65c-4.45,2.26-10.72,0.96-16.17,1.24
                c-1.65,0.09-3.3,0.01-5.54-0.97c1.95-1.58,3.74-3.43,5.87-4.71c10.68-6.42,21.55-12.55,32.16-19.09
                c5.91-3.64,11.94-7.35,17.06-11.98c7.71-6.95,15.32-13.84,25.04-17.9c0.67-0.28,1.18-0.95,2.32-1.9
                c-1.63-0.36-2.53-0.69-3.44-0.75c-23.06-1.57-46.1-3.68-69.18-4.38c-9.29-0.28-18.66,2.58-28,3.96
                c-13.79,2.03-27.58,4.07-41.39,5.93c-2.23,0.3-4.76,0.22-6.85-0.54c-8.81-3.2-17.69-4.18-26.99-2.67
                c-16.12,2.61-32.17,1.19-48.19-1.32c-2.96-0.46-6.06-0.07-9.09-0.07c-0.2,0.47-0.4,0.93-0.59,1.4c1.14,1.45,2.16,3.02,3.43,4.35
                c10.1,10.62,23.11,15.04,37.17,16.17c14.57,1.18,29.22,1.32,43.83,1.91c8.31,0.33,16.63,1.05,24.92,0.8
                c4.17-0.13,8.31-1.93,12.41-3.15c10.8-3.22,21.39-7.41,32.4-9.51c7.99-1.52,16.49-0.3,24.79,0.43
                c-15.26,4.12-31.34,4.03-47.5,10.84c3.58,2.67,6.06,4.52,9.26,6.91c-10.56,0-20.35,0.82-29.95-0.18
                c-16.84-1.76-33.46-1.7-50.21,0.95c-25.48,4.03-48.9,0.14-70.22-16.29c-14.86-11.45-32.32-12.28-49.63-4.17
                c-11.75,5.5-23.33,11.35-35.12,16.76c-4.05,1.86-8.4,3.29-12.77,4.18c-18.13,3.74-34.21,11.72-48.99,22.65
                c-1.62,1.2-3.83,2.13-5.82,2.22c-6.28,0.28-12.58,0.1-19.31-0.96c2.32-1.37,4.61-2.81,6.97-4.11
                c19.71-10.85,40.63-18.28,62.81-22.13c11.29-1.96,21.75-5.86,31.22-12.55c15.96-11.28,33.22-19.57,52.95-21.93
                c13.12-1.57,25.85,0.97,38.66,2.94c9.36,1.44,18.73,3.93,28.1,3.98c10.37,0.05,20.8-1.89,31.13-3.42
                c16.59-2.46,33.17-5.09,49.67-8.1c6.35-1.16,12.49-3.49,18.7-5.39c14.92-4.57,30.19-4.99,45.48-3.2
                c21.28,2.49,42.48,5.69,63.75,8.25c16.85,2.03,33.27-0.97,49.13-6.34c13.85-4.69,27.48-10.12,40.96-15.8
                c30.67-12.93,62.43-22.02,95.27-27.18c12.5-1.96,22.02-7.05,26.54-19.51c0.85-2.33,2.26-4.45,3.32-6.71
                c5.93-12.6,16.01-18.57,29.77-18.93c7.64-0.2,15.38-0.05,22.89-1.25c5.43-0.87,11.31-2.66,15.67-5.85
                c16.77-12.26,34.03-23.93,48.68-38.95c8.46-8.67,14.51-18.5,18.84-29.66c6.98-17.99,13.68-36.11,21.38-53.78
                c6.41-14.71,9.35-30.2,12.18-45.71c3.13-17.15,6.31-34.44,7.32-51.8c1.05-18.05-0.24-36.26-0.94-54.38
                c-1.07-27.6-3.59-55.19-3.55-82.78c0.07-47.09,1.81-94.17,2.53-141.26c0.46-30.3,0.02-60.62,0.55-90.92
                c0.73-42.61,2.03-85.21,3-127.82c0.72-31.62,1.16-63.24,2-94.85c0.55-20.92,1.73-41.82,2.36-62.73
                c0.35-11.48,0.35-22.98,0.13-34.46c-0.38-19.8-1.02-39.6-1.6-59.4c-0.61-20.97-2.03-41.94-1.7-62.89
                c0.49-31.11,2.27-62.19,3.38-93.29c0.58-16.14,0.94-32.28,1.32-48.43c1.04-45.1,2.02-90.2,3.1-135.3
                c0.04-1.75,0.62-3.59,1.39-5.18c1.81-3.74,4.09-7.26,5.78-11.05c1.51-3.39,4.35-3.93,6.98-2.96c1.52,0.56,3.24,3.25,3.22,4.96
                c-0.25,21.3-0.63,42.61-1.33,63.9c-0.58,17.63-1.84,35.23-2.42,52.86c-0.67,20.13-0.9,40.28-1.37,60.42
                c-0.25,10.65-1.39,21.35-0.63,31.92c0.66,9.16,3.45,18.16,5.28,27.24c0.49,2.42,1.33,4.86,1.28,7.27
                c-0.77,42.77-1.69,85.55-2.47,128.32c-0.24,13.32-0.21,26.64-0.19,39.97c0,2.21,0.6,4.41,0.92,6.62c0.52,0.03,1.03,0.06,1.55,0.09
                c0.33-1.56,0.79-3.11,0.97-4.69c1.04-9.25,1.66-18.56,3.17-27.73c0.69-4.14,3.26-7.95,4.71-12c1.53-4.27,3.53-8.58,3.93-13
                c1.11-12.09,1.47-24.24,2.19-36.37c0.1-1.64,0.51-3.26,1.15-4.84c-0.72,26.87-1.46,53.74-2.15,80.61
                c-0.21,8.15-0.91,16.37-0.19,24.45c0.5,5.56,3.06,10.94,4.98,17.34c2.32-2.07,3.6-3.2,5.54-4.93c0.68,6.25,1.69,11.96,1.84,17.69
                c0.37,13.98,0.13,27.98,0.5,41.96c1.06,40.1,2.39,80.19,3.43,120.3c0.85,32.94,2.04,65.89,1.9,98.83
                c-0.09,20.26-2.73,40.49-3.64,60.76c-1.05,23.44-0.87,46.95-2.34,70.36c-1.22,19.39-4.06,38.69-6.18,58.02
                c-0.92,8.43-1.83,16.86-2.23,25.29c0-16.19,0-32.37,0-48.56c-0.46-0.08-0.93-0.16-1.39-0.24c-4.87,11.4-5.95,23.76-8.31,35.86
                c1.68-69.16-5.46-137.74-12.92-206.3c-0.44-0.03-0.88-0.06-1.33-0.09c-0.2,1.13-0.59,2.27-0.59,3.4
                c0.03,37.47,0.17,74.94,0.13,112.41c-0.02,14.82-0.92,29.64-0.55,44.44c0.89,34.77,2.37,69.52,3.48,104.28
                c0.52,16.3,0.74,32.62,1.14,48.93c0.05,2.07,0.35,4.14,0.53,6.21c0.55,0.15,1.1,0.3,1.65,0.45c1.84-4.34,3.68-8.67,5.52-13.01
                c0.23,0.01,0.47,0.03,0.7,0.04c-0.56,51.38-1.13,102.76-1.69,154.14c-0.18-0.02-0.37-0.04-0.55-0.06
                c-1.04-5.19-2.06-10.39-3.16-15.57c-0.12-0.57-0.71-1.03-2.05-2.88c-1.28,10.75-2.41,20.18-3.53,29.61
                c-0.14-0.04-0.28-0.08-0.42-0.11c-3.16-22.94-6.31-45.88-9.64-70.06c-0.74,1.95-1.3,2.84-1.39,3.78
                c-3.03,28.94-6,57.88-8.99,86.82c-1.81,17.48-1.33,34.82,1.67,52.21c0.88,5.09-0.83,10.71-1.75,16.02
                c-1.76,10.12-4.57,20.12-5.58,30.31c-2.61,26.26-7.72,51.96-15.18,77.23c-0.48,1.63-0.79,3.32-1.18,4.98
                c0.4,0.26,0.79,0.52,1.19,0.78c1.92-1.68,4.25-3.07,5.71-5.09c9.34-12.92,18.53-25.95,27.68-39c1.81-2.58,3.25-5.41,4.86-8.12
                C1093.59,1340.65,1093.55,1340.62,1093.55,1340.62z M1008.45,1369.7c-0.41-0.33-0.81-0.67-1.22-1c-1.33,0.87-2.63,1.79-3.99,2.61
                c-1.57,0.94-3.15,1.87-4.78,2.69c-19.75,9.88-41.03,11.86-62.63,11.88c-14.79,0.02-29.82-2.03-44.33-0.08
                c-34.79,4.68-69.21,11.65-101.56,26.22c-4.07,1.83-8.47,2.96-12.74,4.33c-21.69,6.93-43.4,13.81-65.07,20.79
                c-1.71,0.55-3.2,1.8-5.83,3.32c4.63,2.65,8.05,5,11.77,6.67c11.68,5.24,21.23,12.8,28.36,23.6c3.94,5.97,9.93,9.6,16.73,12.04
                c14.34,5.15,28.76,5.73,43.69,2.72c14.16-2.85,28.48-5.05,42.83-6.75c9.87-1.17,20.12,0.12,29.82-1.69
                c12.19-2.28,24-6.58,35.96-10.08c0.65-0.19,1.13-0.98,2.22-1.98c-7.62-4.03-15.21-4.91-23.02-4.24
                c-15.9,1.37-31.76,3.26-47.68,4.35c-31.27,2.15-59.54-10.28-89.06-18.68c23.69-4.94,46.87-9.76,70.91-14.77
                c-9.94-7.71-21.46-8.77-33.4-11.69c2.53-0.51,3.98-0.83,5.44-1.09c14.75-2.63,29.1-0.62,43.37,3.37
                c11.64,3.26,23.38,4.1,35.5,1.74c20.39-3.96,40.83-7.96,61.44-10.44c28.5-3.44,49.53-17.86,65.58-40.79
                C1007.42,1371.82,1007.89,1370.73,1008.45,1369.7z M944.36,1424.89c-4.62,1.62-7.98,2.58-11.17,3.95
                c-16.35,7.06-33.18,8.51-50.77,5.79c-12.29-1.9-24.78-2.72-37.22-3.26c-8.55-0.37-15.61,3.96-21.92,9.92
                c19.16-5.73,36.87,3.87,55.4,4.43c-2.5,1.84-5.01,3.68-7.51,5.52c0.21,0.45,0.42,0.91,0.64,1.36c2.52-0.43,5.06-0.77,7.57-1.29
                c16.42-3.41,32.75-7.37,49.28-10.14c12.6-2.11,24.63-5.18,35.92-11.32c11.83-6.43,23.77-12.71,35.97-18.39
                c12.83-5.97,22.69-14.93,30.11-26.8c4.23-6.77,8.42-13.57,12.5-20.44c0.99-1.66,1.48-3.62,2.19-5.44
                c-1.74,0.38-2.44,1.23-3.15,2.07c-9.19,10.91-17.91,22.28-27.7,32.63c-12.33,13.04-28.13,19.57-45.91,21.9
                c-11.99,1.57-23.89,3.9-35.8,6.09c-2.06,0.38-3.94,1.78-5.9,2.71c0.13,0.6,0.26,1.2,0.4,1.8
                C932.47,1425.66,937.62,1425.33,944.36,1424.89z M947.34,1350.33c-0.03-0.44-0.07-0.88-0.1-1.31c2.16-0.66,4.29-1.7,6.49-1.91
                c14.86-1.4,26.88-8.14,36.71-19.14c7.43-8.32,16.78-13.79,27.29-16.84c18.61-5.4,28.82-17.53,31.28-36.54
                c0.28-2.14,0.54-4.3,1.01-6.4c2.81-12.63,5.69-25.24,8.53-37.86c0.26-1.14,0.42-2.3,0.63-3.45c-1.78,1.03-2.71,2.36-3.21,3.83
                c-2.01,5.99-3.67,12.1-5.84,18.02c-4.51,12.29-8.94,24.64-14.04,36.69c-3.35,7.91-10.12,13.06-18.14,15.44
                c-14.49,4.31-26.33,12.19-36.72,23.05c-7.16,7.48-16.17,12.08-26.92,12.63c-4.13,0.21-8.25,1.1-12.3,2
                c-6,1.32-11.35,3.89-14.92,9.2c-2.11,3.13-3.84,6.37-1.35,10.13c2.43,3.67,5.88,5.68,10.2,4.76c5.82-1.24,11.57-2.9,17.25-4.69
                c1.69-0.53,3.05-2.1,5.46-3.85C954.12,1352.59,950.73,1351.46,947.34,1350.33z"/>
            <path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M220.38,1356.6c0.22,10.6,0.65,21.2,0.6,31.8c-0.11,22.29-0.48,44.58-0.74,66.87
                c-0.08,6.85-0.29,7.27-7.05,6.91c-3.56-0.19-7.07-1.32-10.58-2.1c-5.34-1.17-10.61-3.13-16-3.47c-17.25-1.1-34.54-1.64-51.81-2.34
                c-1.35-0.06-2.72,0.29-4.09,0.44c-0.02,0.48-0.03,0.96-0.05,1.44c7.41,0.84,14.83,1.69,23.02,2.62c-1.52,1.3-2.52,2.16-3.78,3.24
                c0.62,0.72,1.03,1.69,1.62,1.82c5.96,1.35,11.91,3.08,17.96,3.66c6.25,0.6,12.62,0.06,18.94,0.02l0.01,0.01
                c-2.97,3.47-1.32,5.7,2.27,6.46c6.65,1.4,13.37,2.71,20.12,3.26c7.44,0.61,14.95,0.31,22.43,0.4c0.33,0,0.73,0.04,0.98-0.12
                c7.62-4.9,15.27-0.65,22.93-0.22c3.88,0.21,7.89-0.45,11.69-1.33c1.42-0.33,2.44-2.42,3.64-3.71c-1.3-1.09-2.51-2.32-3.93-3.22
                c-1.02-0.65-2.33-0.86-2.92-1.06c15.91,0.18,32.28,0.37,48.65,0.55l-0.04-0.05c-19.4,4.45-37.81,11.48-55.58,20.46
                c-3.48,1.76-8.11,1.89-12.18,1.75c-17.45-0.6-34.89-1.44-52.31-2.49c-9.56-0.58-18.83-2.15-25.6-10.27
                c-1.28-1.54-4.11-2.07-6.34-2.51c-10.92-2.17-21.91-4.02-32.81-6.27c-3.86-0.8-7.69-2.09-11.3-3.7c-3.52-1.57-5.51-4.01-1.49-7.45
                c0.69-0.59,0.98-1.65,1.74-2.99c-1.33-0.65-2.38-1.49-3.53-1.67c-5.09-0.77-10.2-1.81-15.32-1.93
                c-23.12-0.53-46.24-0.93-69.37-1.03c-7.46-0.03-14.94,0.78-22.37,1.64c-4.45,0.51-5.93-0.9-5.93-5.34
                c0.08-95.05,0.08-190.1,0.01-285.15c0-4.03,1.26-5.78,5.47-5.57c17,0.84,33.84-0.72,50.67-3.15c8.01-1.16,16.27-0.52,24.41-0.9
                c18.59-0.87,37.17-1.82,55.75-2.88c1.99-0.11,3.94-1.11,5.91-1.7c0.03-0.55,0.07-1.09,0.1-1.64c-5.23-1.65-10.47-3.31-15.7-4.96
                c0,0-0.03,0.03-0.03,0.03c33.03,2.02,66.05,4.01,99.08,6.08c21.57,1.35,43.14,2.75,64.69,4.39c2.38,0.18,5.08,1.55,6.87,3.19
                c5.04,4.6,5.75,10.78,4.46,17.06c-1.41,6.85-6.85,9.58-13.23,6.76c-3.17-1.4-6.11-3.37-8.99-5.34c-10.84-7.41-20.33-7.76-30.08,1
                c-8.23,7.4-15.75,15.8-22.55,24.56c-24.77,31.92-38.73,68.73-47.55,107.78c-2.27,10.05-5.18,19.95-7.78,29.93
                c-0.44,1.71-0.8,3.44-1.2,5.16c0.37,0.33,0.73,0.67,1.1,1c1.71-1.24,3.42-2.48,5.14-3.73c0,0-0.01-0.01-0.01-0.01
                c-0.78,13.06-1.33,26.13-2.41,39.17c-0.65,7.81,4.36,11.87,9.59,15.59c1.45,1.03,4.79,0.25,6.9-0.6
                c12.24-4.91,19.41-14.46,23.71-26.57C217.38,1362.93,218.99,1359.81,220.38,1356.6L220.38,1356.6z"/>
            <path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M1055.6,1477.52c17.77-22.97,33.58-47.16,46.17-73.38c0.43-0.9,1.1-1.76,1.25-2.71
                c0.29-1.81,0.29-3.66,0.4-5.5c-1.64,0.55-3.46,0.8-4.89,1.69c-4.24,2.63-8.29,5.55-12.49,8.25c-1.28,0.83-2.79,1.31-4.89,2.28
                c3.52-8,7.11-14.87,9.56-22.13c2.39-7.07,3.95-14.51,1.84-23.45c-3.28,3.68-5.64,6.33-8.01,8.99l0.05,0.04
                c8.13-15.99,13.6-32.86,16.79-50.5c0.27-1.5,0.4-3.03-0.2-4.83c-9.61,26-23.92,48.96-42.52,69.49c2.16-8.91,4.82-17.73,6.37-26.74
                c3.27-18.99,5.87-38.09,8.92-57.11c1.1-6.88,1.84-14.01,4.33-20.41c2.8-7.2,3.24-14.06,1.8-21.4
                c-3.41-17.34-2.66-34.82-0.95-52.23c1.99-20.32,4.62-40.59,8.14-60.98c2.53,18.08,5.07,36.17,7.6,54.25
                c5.16-7.3,4.83-15.93,7.14-24.17c0.79,8.51,1.58,17.01,2.37,25.52c0.44,0.08,0.88,0.16,1.32,0.24c0.37-1.01,1.01-2.01,1.08-3.04
                c0.73-10.78,1.84-21.57,1.96-32.37c0.53-48.45,0.78-96.91,1.1-145.36c0.02-2.71-0.29-5.42-0.45-8.13c-0.6-0.11-1.2-0.22-1.8-0.33
                c-1.64,5.29-3.28,10.57-4.92,15.86c-0.63-0.06-1.26-0.11-1.9-0.17c-1.26-91.32-2.51-182.64-3.77-273.96
                c0.53-0.02,1.05-0.04,1.58-0.06c2.47,31.16,5.67,62.29,7.2,93.5c1.52,31.07,1.36,62.21,1.93,93.33c0.81,0.06,1.61,0.11,2.42,0.17
                c2.12-13.07,4.24-26.15,6.36-39.22c1.71,30.98-4.93,61.78-1.65,92.73c0.5,0.06,0.99,0.11,1.49,0.17
                c0.47-4.11,0.96-8.22,1.39-12.34c3.26-31.09,6.67-62.16,9.71-93.27c1.76-18.04,3.39-36.12,4.25-54.22
                c1.16-24.27,1.25-48.59,2.3-72.86c0.91-21.1,3.68-42.18,3.54-63.25c-0.21-31.26-2.27-62.51-3.51-93.76
                c-0.42-10.64-0.92-21.29-1.08-31.94c-0.47-31.46-0.57-62.93-1.28-94.39c-0.27-11.77-1.76-23.52-2.82-35.27
                c-0.15-1.72-1.01-3.37-1.9-6.17c-1.91,2.23-3.11,3.63-5.38,6.29c-1.03-5.36-2.66-9.75-2.6-14.11c0.41-30.12,1.21-60.24,1.8-90.36
                c0.13-6.82,0-13.66-0.1-20.48c-0.03-1.7-0.43-3.4-1.24-5.11c-4.53,6.86-5.53,14.59-6.12,22.28c-0.92,11.93-1.25,23.91-2.06,35.85
                c-0.15,2.2-1.19,4.4-2.15,6.45c-1.9,4.05-4.02,8-5.96,11.82c0-9.95-0.12-20.23,0.02-30.51c0.53-37.29,1.17-74.58,1.71-111.86
                c0.02-1.46-0.22-3.04-0.81-4.36c-5.77-12.84-6.29-26.35-5.66-40.12c0.64-14.13,0.84-28.28,1.25-42.43
                c0.77-26.45,1.47-52.91,2.34-79.36c0.82-24.95,1.81-49.89,2.73-74.84c0.03-0.83-0.25-2.01,0.18-2.43c1.26-1.19,2.73-2.6,4.28-2.84
                c0.73-0.11,2.69,2.31,2.64,3.53c-0.68,17.09-1.7,34.17-2.39,51.26c-0.16,3.94,0.66,7.9,0.87,11.86c0.15,2.93,0.03,5.88,0.02,8.82
                c-0.03,11.13-0.2,22.25-0.05,33.38c0.22,16.3,0.6,32.61,1.07,48.91c0.12,4.21,0.8,8.4,1.22,12.6c0.73,0.05,1.45,0.09,2.18,0.14
                c0.7-4.86,1.6-9.71,2.05-14.6c0.74-7.95,1.85-15.94,1.62-23.88c-0.63-21.56,2.86-42.78,4.53-64.14c0.22-2.8,0.51-5.75,1.56-8.3
                c5.64-13.8,5.16-28.31,5.47-42.77c0.12-5.38,0.61-10.74,0.94-16.11c0.58-0.01,1.17-0.03,1.75-0.04c0.11,1.59,0.29,3.19,0.33,4.79
                c0.34,12.29,0.75,24.58,0.91,36.88c0.07,5.29-0.27,10.6-0.7,15.88c-0.48,5.94-1.76,11.84-1.85,17.77
                c-0.5,31.8-0.79,63.59-1.02,95.39c-0.03,4.2,0.69,8.4,1.06,12.6c0.62,0.03,1.24,0.05,1.87,0.08c0.47-3.76,1.15-7.51,1.39-11.29
                c1.46-23.58,2.61-47.18,4.37-70.74c0.37-4.95,2.84-9.75,4.38-14.61c0.74-2.35,2.09-4.64,2.25-7.02
                c1.53-22.23,2.86-44.47,4.26-66.71c0.18-2.82,0.69-5.65,0.6-8.46c-0.14-4.46,1.96-5.68,6.12-5.61c4.49,0.07,4.58,2.72,4.57,5.97
                c-0.04,23.31,0.05,46.63-0.13,69.94c-0.02,2.65-1.46,5.29-2.26,7.93c-1.54,5.16-4.01,10.24-4.45,15.49
                c-0.94,11.01-0.82,22.12-2.11,33.31c-0.52-1.14-0.82-2.47-1.6-3.37c-1.19-1.37-2.88-3.53-4.06-3.36
                c-1.47,0.22-3.35,2.28-3.79,3.89c-0.95,3.49-1.63,7.22-1.48,10.82c0.75,18.1,1.44,36.22,2.91,54.28
                c0.66,8.14-1.22,14.54-8.59,20.76c-2.78-6.75-5.35-12.98-7.92-19.21c-0.46,0.04-0.93,0.09-1.39,0.13
                c1.24,35,1.48,70.05,4.57,104.98c0.72,0.2,1.45,0.4,2.17,0.6c2.29-3.65,4.57-7.29,7.01-11.18c0.69,1.61,1.45,3.42,2.97,6.99
                c2.33-4.15,4.75-6.94,5.55-10.14c2.42-9.66,4.3-19.46,6.21-29.25c1-5.13,1.62-10.34,3.51-15.42c0,5.43,0.03,10.86-0.01,16.28
                c-0.08,11.97-0.45,23.87,2.5,35.73c1.37,5.5,0.35,11.6,0.36,17.42c0.02,8.66,0.01,17.32,0.01,25.07
                c-7.31-2.47-12.54-7.36-11.66-16.84c-0.8,0.19-1.59,0.38-2.39,0.56c-0.64,3.31-1.76,6.62-1.84,9.94
                c-0.5,20.63-0.6,41.28-1.22,61.91c-0.14,4.48-2.16,8.88-2.82,13.39c-0.88,6-1.66,12.06-1.82,18.11
                c-0.41,15.77-0.47,31.55-0.66,47.33c-0.18,14.95-0.35,29.89-0.48,44.84c-0.01,0.94,0.47,1.88,0.83,3.25
                c4.94-2.03,5.71,1.17,6.22,4.76c1.31,9.05,2.72,18.08,3.96,27.13c0.52,3.8,1.25,6.83,5.75,8.2c4,1.22,6.34,4.37,6.19,9.17
                c-0.26,8.28-0.07,16.58-0.07,25.64c-8.5-5.42-10.83-14.21-14.93-21.72c-0.43,0.1-0.87,0.2-1.3,0.3c-0.22,2-0.74,4.02-0.6,6
                c1.02,14.44,2.53,28.85,3.17,43.3c0.62,13.96,0.26,27.95,0.54,41.92c0.33,16.77,0.92,33.54,1.33,50.31
                c0.29,11.6,0.51,23.2,0.69,34.8c0.11,7.15,0.1,14.29,0.11,21.44c0.01,23.81,0.01,47.63,0.01,71.44c0,13.8,0,27.61,0,40.77
                c-1.55-4.97-3.38-10.84-5.21-16.7c-0.67,0.02-1.33,0.04-2,0.05c-0.59,4.25-1.71,8.51-1.67,12.76c0.19,20.8,0.84,41.6,1.04,62.41
                c0.24,24.3,0.61,48.61,0.04,72.9c-0.21,8.85-3.58,17.58-4.35,26.47c-0.98,11.4-0.74,22.9-1.1,34.35c-0.14,4.3-0.59,8.6-0.72,12.9
                c-0.66,21.45-0.78,42.93-2.01,64.35c-1.31,22.73-3.66,45.4-5.51,68.09c-0.16,1.92-0.02,3.87-0.02,5.81
                c0.48,0.27,0.97,0.54,1.45,0.81c2.44-3.8,4.89-7.61,7.69-11.95c4.89,7.35,7.78,14.93,8.4,23.37c0.37,4.98,0.36,10.01,1.15,14.92
                c0.54,3.34,1.77,6.71,3.37,9.71c6.87,12.92,13.05,25.95,9.95,41.39c-1.87-3.93-3.15-8.36-5.81-11.69
                c-2.55-3.19-6.53-5.25-11.1-8.73c-0.64,5.01-1.52,8.53-1.48,12.04c0.19,16.81,0.65,33.61,0.96,50.42
                c0.28,14.9-1.36,29.62-5.35,43.95c-4.36,15.67-9.33,31.18-14,46.77c-0.35,1.17-0.45,2.42-0.76,4.16c2.02-0.33,3.35-0.54,5.78-0.94
                c-8.11,12.04-10.24,26.37-21.66,35.75c-5.01,4.12-8.2,10.42-12.4,15.59c-1.18,1.45-2.96,3.4-4.5,3.43
                c-13.59,0.27-27.19,0.16-41.73,0.16c14.96-16.28,34.05-27.7,43.16-47.75c-0.45-0.43-0.9-0.85-1.36-1.28
                c-1.71,1.8-3.26,3.8-5.16,5.38c-9.05,7.52-18.18,14.94-27.32,22.35C1057.35,1477.24,1056.39,1477.27,1055.6,1477.52
                L1055.6,1477.52z M1087.76,1478.33c-0.01-0.26-0.02-0.52-0.02-0.77c-1.46,0.07-2.92,0.15-4.38,0.22c0.01,0.18,0.02,0.37,0.04,0.55
                C1084.86,1478.33,1086.31,1478.33,1087.76,1478.33z"/>
            <path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M503.18,271.1c-2.88-0.91-5.37-1.84-7.93-2.45c-1.2-0.28-3.1-0.46-3.73,0.2
                c-3.37,3.59-6.67,1.42-10.51,0.38c0.65-1.86,1.22-3.49,1.97-5.61c-6.54-3.12-13.41-3.49-20.31-3.03c-1.02,0.07-1.88,2.5-3.42,4.7
                c-7.01-2.22-14.76-4.72-22.54-7.11c-1.29-0.4-2.97-0.91-4.05-0.42c-6.64,2.99-6.6,3.08-10.77-2.15c-3.13,4.17-6.4,3.09-9.55,0.03
                c-3.46-3.37-7.47-1.56-11.35-1.4c-3.6,0.15-7.22-0.1-10.82-0.02c-2.57,0.06-5.13,0.44-7.71,0.57c-1.45,0.07-3.79,0.45-4.24-0.25
                c-2.87-4.54-8-4.33-12.13-5.96c-1.96-0.78-4.71,0.29-7.07,0.7c-2.72,0.47-5.4,1.6-8.11,1.61c-3.09,0.01-6.27-0.6-9.27-1.43
                c-11.3-3.12-22.52-6.54-33.83-9.62c-3.33-0.91-6.93-1.78-10.27-1.41c-3.94,0.43-5.61-1.66-7.71-4.12
                c-5.43-6.36-10.83-12.78-16.7-18.71c-2.93-2.96-6.84-4.97-10.4-7.26c-0.72-0.46-1.89-0.16-2.8-0.4c-1.56-0.41-3.1-0.89-4.6-1.48
                c-3.43-1.33-6.81-2.77-10.25-4.09c-1.32-0.51-2.86-0.56-4.07-1.24c-5.35-3.01-10.79-4.71-16.91-2.35
                c-0.92,0.35-2.25-0.01-3.28-0.37c-4.03-1.38-7.28-3.44-12.45-1.25c-4.09,1.74-10.07,0.14-14.87-1.2
                c-6.71-1.87-11.36-13.33-11.64-20.42c-0.32-8.15,4.87-11.9,9.4-16.59c0.74-0.77,1.88-1.33,2.94-1.58
                c4.83-1.16,4.84-1.11,6.03-5.17c2.05-2.1,3.83-4.59,6.2-6.22c7.24-4.98,12.09-13.06,21.49-15.37c5.47-1.34,7.14-5.77,5.35-10.94
                c-0.45-1.31-2.2-3.22-3.18-3.12c-3.53,0.35-4.7-1.98-5.97-4.29c-1.78-3.21-3-6.45-8.04-5.91c-1.97,0.21-4.27-2.64-5.39-3.39
                c-1.58-3.92-2.79-6.93-4.36-10.85c1.5-0.77,3.75-2.42,6.26-3.13c5.24-1.49,7.12-3.67,7.45-9.25c0.18-3.1,1.73-5.39,5.17-3.96
                c4.37,1.8,6.97-0.15,10.08-2.93c5.93-5.3,12.5-9.88,18.51-15.09c2.55-2.21,4.59-5.11,6.47-7.95c2.27-3.42,4.97-4.02,9.04-3.91
                c3.53,0.1,7.12-2.33,10.71-3.57c2.6-0.9,5.23-1.7,7.85-2.51c0.63-0.19,1.41-0.08,1.94-0.4c8.42-5.12,18.7-7.52,24.32-16.76
                c0.7-1.14,2.15-2.63,3.23-2.6c7.05,0.14,11.89-3.6,16.83-8.03c2.54-2.27,6.51-2.92,9.83-4.33c1.52-0.65,3.35-1.03,4.46-2.13
                c2.21-2.2,4.17-2.17,7.11-1.34c2.36,0.66,5.58,0.07,7.84-1.08c5.42-2.77,11.35-1.42,14.2,3.97c1.83,3.44,4.65,3.83,7.44,2.82
                c0,3.65-0.76,7.51,0.22,10.86c0.89,3.06,3.24,6.07,5.72,8.21c2.97,2.56,3.59,5.66,2.02,8.33c-2.3,3.91-0.73,5.84,2.23,7.76
                c2.39,1.55,5,2.69,3.02,6.58c-0.48,0.94,1.32,3.41,2.54,4.74c0.97,1.06,2.66,1.46,3.67,3.11c-0.84-0.15-1.67-0.37-2.51-0.43
                c-2.68-0.2-5.58-1.07-7.98-0.33c-2.72,0.85-4.42,1.33-7.12-0.54c-1.66-1.15-4.97,0.12-7.54,0.2c-2.69,0.08-5.38,0.03-8.07,0.03
                c-2.76,0-5.62-0.51-8.26,0.05c-7.51,1.6-14.49,5.59-22.48,4.94c-1.88-0.15-4.01-0.26-5.52-1.2c-4.11-2.58-7.26-1.86-9.74,2.08
                c-2.75,4.35-6.13,7.72-11.52,8.38c-1.58,0.19-3.19,0.23-4.72,0.59c-1.65,0.38-4.13,0.58-4.69,1.66
                c-3.12,6.05-9.56,8.09-14.13,12.34c-0.53,0.5-1.45,0.77-2.21,0.81c-6.06,0.38-7.27,1.52-7.08,7.18c0.1,3.2-0.41,5.21-4.49,4.25
                c-3.28-0.77-4.74,1.52-6.93,3.98c-3.58,4.01-2.57,8.52-3.36,12.72c-0.36,1.96,1.82,4.4,3.01,6.98c-5.39,4.4-4.73,10.17-3.58,16.51
                c0.94,5.17,0.32,10.62,0.42,15.94c0.13,7.01,1.23,7.84,8.08,6.82c3.15-0.47,4.46,1.14,4.24,4.4c-0.64,9.58,9.17,19.33,18.75,18.97
                c1.53-0.06,3.12,0.59,4.63,1.06c8.14,2.5,16.32,5,24.29-0.31c3.35-2.23,4.69-0.78,6.6,2.09c3.06,4.6,6.5,9.07,10.41,12.97
                c2.72,2.71,6.57,3.04,10.2,0.88c0.7-0.42,1.71-1.14,2.23-0.91c5.95,2.52,10.48-1.44,15.48-3.22c6.41-2.28,7.3-1.78,8.86,4.77
                c1.51,6.33,14.21,10.96,19.2,6.57c4.81-4.24,9.91-1.86,14.9-1.75c3.07,0.07,6.15,1.6,9.17,1.44c5.8-0.31,10.02,2.17,14.19,5.75
                c2.03,1.74,4.8,3.05,7.42,3.56c6.43,1.24,8.35,3.23,8.24,9.54c-0.09,4.84,3.19,6.91,7.14,8.47c2.4,0.95,4.57,2.64,7.03,3.26
                c6.18,1.56,12.58,2.5,14.11,10.35c0.08,0.43,1.06,0.85,1.68,0.97c6.55,1.24,13.03,2.23,19.13,5.55c2.66,1.45,6.84,0.02,10.33,0.05
                c1.11,0.01,2.31,0.36,3.31,0.86c4.1,2.01,7.14,2.5,9.53-2.86c1.83-4.1,6.05-4.01,9.23-0.72c2.99,3.09,11.76,5.02,15.09,2.63
                c1.71-1.22,2.24-4.08,3.32-6.25c2.88-1.15,6.29-2.51,9.72-3.88c2.64,1.4,5.82,3.73,9.38,4.81c3.71,1.13,7.09-0.5,8.2-4.56
                c1.04-3.78,2.71-2.59,5.49-1.93c5.34,1.27,10.93,1.44,16.41,2.13c1.39,0.18,2.8,0.47,4.13,0.94c6.36,2.25,10.09,0.53,11.11-6.16
                c3.28,0.35,6.61,0.83,9.95,1c1.88,0.09,4.37,0.31,5.49-0.73c1.01-0.93,0.77-3.5,0.66-5.32c-0.12-1.97-0.28-4.15-1.2-5.81
                c-1.36-2.45-3.35-4.56-5.16-6.74c-1.16-1.39-2.4-2.73-3.75-3.93c-1.36-1.21-2.89-3.12-4.37-3.15c-4.38-0.08-7.24-2-9.47-5.95
                c1.17-1.29,2.44-2.68,3.73-4.1c4.76,5.73,4.76,5.73,10.71,1.47c0.27-0.19,0.57-0.48,0.85-0.48c4.3-0.05,8.98-1.14,12.78,0.22
                c3.67,1.31,6.19,5.54,9.66,7.81c7.32,4.78,13.13,12.95,23.81,10.74c2-0.41,4.73,0.86,6.6,2.12c2.15,1.46,3.44,4.25,5.64,5.56
                c1.84,1.09,5.44,1.97,6.53,1c2.59-2.3,4.22-1.11,6.56-0.15c2.51,1.03,5.26,1.77,7.95,1.97c6.67,0.5,13.19,0.46,17.09,7.67
                c1.64,3.02,5.13,3.73,9.09,3.76c4.07,0.03,8.67,1.55,12.06,3.86c4.66,3.18,7.74,4.06,12.5,0.87c0.41-0.28,0.82-0.86,1.2-0.83
                c5.45,0.36,9.3-0.79,7.5-7.58c1.62-0.32,3.79-1.47,4.79-0.83c12.78,8.19,28.62,11.49,38.63,24.17c2.03,2.57,4.67,4.84,7.42,6.64
                c8.37,5.45,10.98,13.56,12.01,22.93c2.3,21.01,7.62,41.21,18.13,59.75c3.09,5.44,1.71,10.32-0.28,15.3
                c-0.57,0.16-1.15,0.32-1.72,0.48c-0.82-1.86-2.19-3.68-2.35-5.6c-0.31-3.67-7.83-16.41-11.24-17.06
                c-1.62-0.31-3.85,1.11-5.42,2.26c-3.18,2.33-6.26,2.02-6.25-1.47c0.03-7.64-5.84-8.61-10.54-11.35c-4.2-2.46-8-5.61-12.18-8.13
                c-1.71-1.03-3.92-1.29-5.94-1.72c-1.75-0.37-3.59-0.34-5.32-0.75c-2.77-0.66-5.47-2.82-8.13,0.61c-0.31,0.4-3.55-0.83-4.83-1.98
                c-1.96-1.75-3.27-4.19-5.08-6.13c-1.83-1.97-4.62-2.21-5.03,0.62c-1.57,10.71-10.54,8.2-17.03,10.14
                c-1.23,0.37-2.64,0.36-3.73,0.96c-3.88,2.12-6.42,1.34-8.3-2.4c-0.72-1.43-2.16-3.51-3.25-3.5c-1.65,0.01-4.06,1.21-4.77,2.61
                c-1.53,3.02-3.76,3.37-6.57,3.23c-1.31-0.06-2.78-0.19-3.94,0.29c-3.63,1.49-6.19,0.12-8.17-2.76c-1.82-2.63-3.67-3.76-7.1-2.49
                c-1.52,0.56-4.41-0.33-5.59-1.59c-2.85-3.04-5.88-4.3-10.06-4.44c-2.09-0.07-4.93-1.98-6-3.86c-3.26-5.75-4.3-6.73-10.32-3.4
                c-4.25,2.35-6.51,0.79-9.54-1.81c-2.57-2.21-5.82-3.68-8.91-5.19c-0.79-0.39-2.71-0.06-3.08,0.55c-3.26,5.39-7.32,1.78-10.97,1
                c-2.78-0.59-5.69-1.45-8.02-2.97c-2.85-1.86-5.47-4.3-7.66-6.93c-3.95-4.74-6.69-5.25-11.99-2.27c-1.78,1.01-4.44,1.6-6.32,1.06
                c-3.64-1.05-6.46-3.5-11.13-1.83c-1.92,0.68-6.04-3.74-8.54-6.43c-0.75-0.8,0.62-3.57,1.3-6.78c-0.93-1.64-3.14-2.77-6.62-1.71
                c-1.66,0.51-3.75,0.77-5.31,0.21c-3.52-1.26-6.31-3.05-10,0.67c-2.59,2.61-10.18-1.14-11.49-5.02c-1.33-3.94-3.69-4.89-7.27-2.89
                c-1.31,0.73-2.61,1.49-3.82,2.37c-5.11,3.71-10.01,6.37-16.74,2.81c-3.37-1.79-8.08-0.86-11.88-2.08
                C504.42,277.54,501.52,275.31,503.18,271.1z"/>
            <path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M856.82,154.45c-1.42-1.94-2.89-3.83-4.24-5.82c-3.86-5.71-8.46-10.96-10.17-17.97
                c-0.67-2.77-2.92-4.37-6.18-3.68c-0.75,0.16-1.73,0.02-2.41-0.34c-5.45-2.87-10.93-5.68-16.2-8.84c-1.39-0.83-2.4-2.68-3.07-4.27
                c-3.7-8.9-8.53-11.27-17.62-8.2c-3.54,1.2-5.63,1.02-6.07-3.51c-0.45-4.6-6.29-7.67-10.63-6.1c-1.17,0.43-2.82,1.41-3.52,0.97
                c-5.71-3.58-10.66-0.24-15.59,1.66c-5.08,1.96-9.39,4.07-14.25-1.13c-3.06-3.27-7.79-3.7-12.71-4.12
                c-4.52-0.39-9.24-4.68-12.77-8.32c-2.76-2.85-5.04-4.28-8.94-3.69c-4.72,0.72-9.51,1.11-14.29,1.35
                c-1.29,0.06-2.59-1.08-3.95-1.47c-3.21-0.92-6.89-3.11-9.59-2.27c-4.96,1.55-8.66,0.32-12.4-2.31c-3.17-2.23-6.19-3.35-10.19-1.64
                c-1.51,0.65-5.13-0.19-5.7-1.37c-3.65-7.48-10.59-5.29-16.45-6.14c-1.6-0.23-3.3,0.33-4.96,0.37c-0.94,0.02-2.46,0.06-2.76-0.46
                c-4.45-7.82-11.82-4.18-18.08-4.67c-4.17-0.33-8.39-0.47-12.56-0.25c-1.69,0.09-3.49,1.11-4.93,2.13
                c-3.14,2.21-3.59,5.32-0.19,7.22c4.68,2.61,9.84,4.36,14.84,6.35c1.52,0.61,3.53,0.4,4.69,1.35c0.83,0.68,1.24,3.6,0.78,3.9
                c-5.52,3.6-9.36,7.92-10.63,14.87c-0.48,2.64-4.1,5.03-6.74,6.88c-5,3.5-5.92,7.67-1.71,12.07c4.94,5.17,10.09,10.1,18.17,5.26
                c0.93-0.55,3.46,0.16,4.3,1.12c4.04,4.65,7.76,9.58,11.75,14.61c-0.31,0.21-1.24,0.81-2.15,1.45c-3,2.14-3.09,4.7-0.58,7.11
                c1.9,1.82,4.01,3.71,6.38,4.7c5.94,2.47,10.69,8.03,18.17,6.84c1.49-0.24,3.59,1.59,4.99,2.92c6.26,5.97,13.49,8.93,22.2,7.8
                c4.36-0.57,6.07,1.53,7.87,5.55c10.09,22.49,23.6,41.22,49.22,48.88c9.28,2.78,17.06,10.56,24.54,15.43
                c-1.23,4.02-2.1,6.87-2.99,9.75c-2.13,1.08-4.79,3.18-7.7,3.64c-2.3,0.36-5.39-0.69-7.29-2.19c-4.74-3.72-9.95-4.83-15.76-4.84
                c-1.84-0.01-4.76-0.76-5.35-2.02c-3.67-7.87-11.14-7.43-17.81-8.81c-3.13-0.65-6.29-1.14-9.38-1.93
                c-2.53-0.64-4.91-2.18-7.28,0.62c-0.46,0.55-2.81,0.19-3.72-0.49c-1.83-1.37-3.56-3.07-4.83-4.97c-2.9-4.32-6.59-4.86-11.32-3.6
                c-3.09,0.83-6.75,1.74-7.94-3.26c-0.19-0.78-2.44-0.95-3.59-1.63c-2.86-1.69-6.49-2.9-8.29-5.39c-4.87-6.74-10.12-9.38-18.06-7.75
                c-0.94,0.19-1.98,0.1-2.94-0.08c-5.46-1.07-5.46-1.1-9.27,3.56c-1.18-1.64-2.3-3.04-3.26-4.55c-1.7-2.67-3.48-3.21-5.13-0.12
                c-1.11,2.09-1.82,4.39-3.57,5.75c-0.13,4.57,4.06,8.61,9.77,9.4c0.82,0.11,2.25,0.07,2.35,0.42c1.26,4.2,4.65,5.72,7.94,8.07
                c2.65,1.89,2.84,7.21,4.12,11.01c-0.44,0.41-0.87,0.81-1.31,1.22c-2.69-1.2-5.38-2.41-8.1-3.63c-1.67,0.71-3.7,1.41-5.56,2.43
                c-1.01,0.55-2.18,1.5-2.49,2.52c-1.45,4.68-4.79,5.73-8.43,2.32c-1.05-0.98-1.7-2.39-2.97-4.24c-2.45,4.54-4.63,4.31-8.43,3.66
                c-3.78-0.64-7.36-1.36-11.02-2.52c-1.19-0.38-3.97,1.49-4.71,2.98c-3.09,6.23-8.01,6.84-12.18,0.93
                c-2.29-3.25-4.01-3.91-6.92-0.57c-1.24,1.42-3.85,2.42-5.76,2.31c-3.41-0.2-4.89,0.89-4.33,4.14c0.64,3.7-0.94,3.87-4.15,3.96
                c-4.51,0.13-7.78-0.95-10.76-4.53c-2.43-2.92-9.57-0.56-12.12,3.61c-0.41,0.68-0.63,1.48-1.03,2.47c-3.17-0.78-6.21-1.37-9.14-2.3
                c-2.88-0.92-5.42-2.46-8.52,0.12c-0.75,0.63-3.68-1.5-5.67-2.18c-2.34-0.8-4.7-1.63-7.12-2.05c-3.79-0.65-8.26,0.98-8.81-5.84
                c-0.17-2.09-5.77-3.88-9.02-5.53c-1.79-0.91-3.92-1.15-5.8-1.92c-1.2-0.49-2.14-1.88-3.32-2.06c-5.55-0.82-9.14-2.56-7.43-9.45
                c0.42-1.67-2.25-4.11-3.82-6.74c2.49-2.29,5.21-4.8,8.17-7.52c0.62,1.14,1.28,2.23,1.84,3.39c1.73,3.58,3.96,5.72,8.27,3.62
                c1.13-0.55,2.61-0.36,3.93-0.5c6.28-0.65,7.17-1.69,7.93-7.94c0.19-1.56,1.44-2.98,2.2-4.47c1.39,1.19,2.82,2.34,4.14,3.6
                c0.31,0.29,0.19,1.01,0.45,1.4c1.28,1.92,2.63,3.8,3.96,5.7c1.91-1.54,4.23-2.77,5.63-4.68c2.61-3.56,7.44-5.16,11.57-3.19
                c4.83,2.29,8.07,0.66,9.07-4.57c0.15-0.81,0.02-2.17,0.45-2.37c5.37-2.57,3.42-7.76,4.61-11.89c1.22-4.24,3.56-8.15,5.1-12.32
                c0.73-1.98,1.69-4.78,0.85-6.2c-1.06-1.79-3.76-3.31-5.93-3.61c-5-0.69-13.41-8.33-13.68-13.24c-0.03-0.63,0.02-1.63,0.4-1.86
                c7.07-4.39,2.39-12.49,5.8-18.3c1.91-3.26-1.9-6.05-5.33-6.96c-3.81-1.01-4.44-2.34-2.31-5.88c3.84-6.4,1.08-12.19-6.18-13.94
                c-4.76-1.14-7.28-4.84-7.72-9.77c-0.19-2.08-1.63-4.43-3.19-5.93c-2.66-2.54-5.71-4.76-8.89-6.62c-2.9-1.7-3.95-4.25-5.56-6.94
                c-1.69-2.82-5.96-4.16-9.2-5.97c-1.26-0.71-3.22-0.53-4.12-1.47c-4.39-4.54-9.01-3.21-13.6-0.77c-2.52,1.34-3.96,0.6-5.42-1.48
                c-4.16-5.95-9.53-7.49-16.58-5.32c-5.24,1.61-10.52,2.92-15.65-1.97c-3.28-3.12-8.3-4.41-12.99-6.75c1.53-1.97,2.49-3.2,3.92-5.05
                c-3.01-2.45-5.89-4.8-8.72-7.11c0.36-2.27,0.78-4.49,1.05-6.72c0.44-3.67-0.91-6.12-4.33-8.05c-4.61-2.61-5.33-6.36-4.1-11.9
                c0.5-2.25-0.14-4.84-0.72-7.16c-0.13-0.53-2.35-1.1-3.31-0.77c-4.94,1.69-4.3-1.42-4.23-4.98c2.02,0,3.8,0,5.59,0
                c45.28,0,90.57,0.05,135.85-0.07c4.89-0.01,8.35,1,11.15,5.61c2.76,4.55,7.24,6.78,13.26,5.66c5.77-1.08,11.81-0.67,17.61-1.62
                c2.49-0.41,4.73-2.52,6.99-4c1.21-0.79,2.23-1.88,3.33-2.83c1.03,1.81,3.39,4.36,2.86,5.3c-2.88,5.16,0.07,8.09,3.26,11.23
                c3.7,3.65,8.03,4.04,12.94,3.37c2.39-0.33,4.97,0.66,7.45,1.15c1.26,0.25,2.74,1.38,3.66,1c4.96-2.05,6.91,0.61,9.32,4.43
                c1.42,2.24,4.89,3.27,7.56,4.61c1.89,0.94,3.94,1.72,6,2.16c5.11,1.08,10.25,2.22,15.44,2.68c1.35,0.12,2.87-2.2,4.4-3.28
                c0.97-0.68,2.26-1.75,3.12-1.53c1.16,0.29,2.56,1.48,2.95,2.6c1.99,5.7,5.79,7.86,11.45,5.57c3.41-1.38,5.89-1.86,7.65,2.19
                c0.41,0.95,2.15,2.07,3.08,1.92c4.31-0.69,6.73,1.77,9.35,4.53c3.95,4.15,6.24,4,10.18,0.02c2.84-2.87,3.99-1.03,5.38,1.66
                c1.24,2.4,2.75,5.25,4.93,6.38c1.82,0.94,5.34,0.48,7.13-0.76c5.8-4.03,5.63-4.28,9.37,1.24c3.02,4.45,7.83,6.05,12.62,3.52
                c2.81-1.49,4.79-1.19,7.29,0.44c3.45,2.25,6.95,4.7,11.55,2.34c0.86-0.44,2.63,0.6,3.84,1.23c4.91,2.56,9.63,5.52,14.69,7.73
                c2.76,1.21,5.13-0.09,7.14-2.88c1.7-2.35,4.54-4.1,7.21-5.48c3.17-1.63,6.64-2.79,10.09-3.73c1.61-0.44,3.51-0.07,5.24,0.2
                c2.37,0.37,4.71,0.99,7.83,1.68c2.31,5.7,4.81,12.55,7.82,19.17c4.71,10.39,11.11,19.63,19.18,27.79
                c11.38,11.49,16.72,26.2,21.2,41.3L856.82,154.45z"/>
            <path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M717.95,186.44c14.79,25.98,38.5,42.32,62.87,57.88c3.79,2.42,8.09,4.02,13.14,5.21
                c-14.01-17.45-18.2-37.89-22.61-58.25c0.8-0.25,1.61-0.51,2.41-0.76c0.25,1.15,0.66,2.3,0.73,3.46
                c0.86,13.73,5.21,26.26,14.46,36.44c10.95,12.04,22.16,23.92,34.14,34.91c8.77,8.05,10.16,7.11,12.86-4.23
                c0.23-0.97,0.35-1.98,0.7-2.91c4.73-12.57,3.54-23.95-3.2-36.14c-5.76-10.43-8.09-22.77-11.83-34.3
                c-0.38-1.18-0.05-2.58,0.68-4.04c4.56,17.11,9.38,33.89,23.98,47.58c2.78-9.98,5.75-18.39,4.17-27.73
                c-1.96-11.62-3.64-23.28-5.39-34.94c-0.13-0.85,0.1-1.76,0.58-2.67c2.36,11.85,1.28,24.42,7.6,35.28c0.54,0.1,1.08,0.21,1.62,0.31
                c2.84-8.09,5.68-16.19,8.53-24.28c0.4,0.01,0.81,0.01,1.21,0.02c0.21,1.79,0.78,3.62,0.59,5.37c-2.64,24.42-9.26,47.94-15.87,71.5
                c-3.23,11.5-6.04,23.24-7.58,35.05c-2.42,18.57,3.81,35.27,14.54,50.14c14.7,20.37,29.02,41.12,48.17,57.79
                c2.63,2.29,5.56,4.22,8.34,6.31c0.19-1.28,0.8-3.89,0.95-6.53c1.51-27.74,2.91-55.49,4.41-83.23c0.5-9.13,1.48-18.24,1.78-27.38
                c0.35-10.81-1.76-21.18-6.94-30.82c-0.94-1.75-1.67-3.63-2.39-5.49c-4.09-10.63-9.25-20.5-17.04-29.09
                c-15.52-17.11-24.66-37.72-31.55-59.52c-2.95-9.35-6.44-18.55-10.14-27.63c-3.8-9.33-9.86-17.16-16.51-24.78
                c-5.94-6.8-10.4-14.9-15.52-22.42c0,0-0.03,0.03-0.03,0.03c6.8,8.63,12.68,18.13,22.93,24.85c-2.77-14.97-5.35-28.91-7.92-42.86
                c0,0-0.03,0.03-0.03,0.03c3.03,11.58,5.73,23.25,9.22,34.69c1.98,6.49,5.17,12.61,7.98,18.83c0.84,1.87,2.2,3.5,3.32,5.24
                c0.44-0.12,0.88-0.24,1.33-0.36c-0.63-11.09-1.25-22.18-1.88-33.27c1.12,21.81,6.44,42.73,13.14,63.36
                c6.69,20.6,15.39,40.07,31.06,55.43c0.59-0.23,1.18-0.46,1.77-0.68c-0.19-1.51-0.31-3.03-0.58-4.53
                c-3.55-20.13-7.35-40.23-10.57-60.41c-1.07-6.69-0.73-13.62-0.79-20.44c-0.01-1.12,1.24-2.25,1.91-3.38
                c0.94,1.02,2.85,2.28,2.67,3.01c-1.67,6.7,2.32,11.96,3.69,17.89c3.13,13.55,3.88,27.63,6.56,41.32
                c1.68,8.59,4.65,17.02,7.82,25.22c5.64,14.58,11.8,28.96,17.9,43.35c1.11,2.62,3.02,4.9,5.39,8.65
                c6.52-13.18,13.96-24.09,14.43-38.1c0.51-15.11,1.9-30.19,2.81-45.28c0.2-3.39,0.99-5.26,5.1-4.62c2.01,0.32,4.28-1.01,6.79-1.68
                c-0.91,13.41-5.51,26.87-0.16,41.84c1.89-2.72,3.12-4.03,3.83-5.58c3.3-7.27,6.53-14.57,9.64-21.92c1.04-2.46,1.73-4.77,5.24-3.32
                c3.72,1.54,1.51,3.93,0.78,5.78c-3.86,9.74-7.97,19.38-11.86,29.1c-0.69,1.74-0.78,3.72-1.41,6.93c2.2-1.4,3.39-1.88,4.22-2.73
                c9.59-9.72,19.41-19.27,25.87-31.55c1.27-2.41,2.68-3.61,5.52-2.43c1.32,0.55,2.89,0.51,4.89,0.81c-0.57,1.3-1.02,2.62-1.71,3.82
                c-8.56,14.91-18.74,28.61-30.36,41.3c-11.7,12.78-22.87,25.91-30.71,41.65c-6.28,12.6-7.19,26.16-9.05,39.65
                c-4.14,29.91-5.82,59.92-5.07,90.12c0.6,24.3,0.3,48.61,0.92,72.91c1.06,41.4,1.99,82.82,4.07,124.18
                c1.03,20.54,4.42,40.96,6.9,61.42c0.32,2.64,1.55,5.18,2.36,7.76c0.55,0.06,1.09,0.13,1.64,0.19c0.88-1.97,2.24-3.87,2.58-5.93
                c4.78-28.54,10.17-57,13.86-85.68c3.28-25.55,4.7-51.34,6.8-77.04c0.65-7.96,0.99-15.95,1.19-23.94c0.5-19.64,0.54-39.3,1.3-58.93
                c0.87-22.44,2.32-44.87,3.49-67.3c0.78-14.96,1.62-29.91,2.28-44.87c1.22-27.65,4.46-55.06,10.22-82.11
                c0.92-4.32,4.48-8.07,7.39-11.82c-3.13,32.97-11.72,65.03-12.99,98.02c0.5,0.3,1,0.6,1.51,0.9c1.66-1.35,3.53-2.52,4.96-4.08
                c9.85-10.8,15.56-23.98,20.69-37.35c10.57-27.57,18.78-55.81,23.22-85.07c0.15-0.98,0.35-1.95,0.62-2.9
                c0.07-0.26,0.47-0.43,1.69-1.46c0.13,2.2,0.45,3.73,0.28,5.21c-2.27,19.82-8.54,38.61-14.89,57.38
                c-2.24,6.62-4.24,13.32-6.27,20.01c-0.45,1.49-0.55,3.08-1.04,5.9c2.82-1.37,4.94-1.79,6.11-3.07
                c5.22-5.74,10.84-11.28,15.04-17.73c4.79-7.35,8.22-15.58,12.24-23.42c0,0-0.04-0.03-0.04-0.03c-3.95,9.7-7.31,19.7-12.06,28.98
                c-3.2,6.25-7.59,12.36-12.8,17.03c-19.85,17.82-30.09,40.64-34.11,66.15c-2.78,17.68-2.97,35.77-4.25,53.69
                c-1.59,22.23-3.13,44.46-4.64,66.7c-0.79,11.62-1.67,23.24-2.17,34.87c-0.87,20.45-1.17,40.93-2.25,61.38
                c-1.67,31.55-3.74,63.09-5.74,94.62c-1.75,27.73-3.62,55.39-2.77,83.27c0.53,17.34-2.37,34.8-3.87,52.19
                c-0.74,8.61-1.81,17.2-3.46,25.84c-0.81-16.06-1.94-32.11-2.34-48.18c-0.48-19.63-0.41-39.28-0.43-58.92
                c-0.01-4.28-0.3-7.54-5.52-8.94c-2.51-0.68-5.02-3.49-6.35-5.97c-2.97-5.53-5.23-11.45-7.71-17.23c-0.68-1.57-1.12-3.25-1.66-4.88
                c-0.48,0.04-0.95,0.08-1.43,0.12c-0.2,1.65-0.68,3.32-0.54,4.94c1.03,12.09,2.21,24.17,3.31,36.26
                c1.89,20.87,3.9,41.72,5.58,62.61c0.73,9.12,1.23,18.3,1.02,27.43c-0.32,13.46-2.13,26.9-1.95,40.34
                c0.38,27.78,1.8,55.55,2.51,83.33c0.34,13.42,0.06,26.86,0.06,41.01c-7.27-1.49-14.09-2.89-21.78-4.46
                c5.5,8.08,9.43,13.38,19.29,11.89c-2,1.36-3.8,2.59-5.6,3.82c0.06,0.47,0.12,0.94,0.18,1.42c4.21,0.53,8.42,1.07,13.36,1.7
                c-1.82,2.25-3.28,4.06-4.74,5.87c0.12,0.51,0.24,1.01,0.36,1.52c2.87-0.31,5.74-0.61,8.62-0.92l-0.02-0.02
                c-0.77,0.99-1.54,1.98-3.12,4c2.78,0,4.45,0,6.12,0l0.01,0.02c-1.6,3.31,0.53,3.35,2.77,3.01c2.2-0.34,4.36-0.92,6.53-1.39
                c0.23,0.4,0.47,0.79,0.7,1.19c-1.83,1.74-3.77,3.38-5.46,5.26c-0.79,0.88-1.09,2.21-1.62,3.34c1.17,0.46,2.38,1.37,3.51,1.28
                c3.01-0.23,5.1-0.46,4.96,3.91c-0.04,1.12,4.34,2.39,6.54,3.52c0.38,1.28,0.9,3.04,1.35,4.57c-18.7-4.31-38.2-8.8-58.6-13.49
                c2.73,6.63,8.28,7.62,13.34,9.51c-1.35,1.15-2.53,2.15-4.88,4.15c6.73,0,12.12,0,18.7,0c-3.38,3.64-5.87,6.33-8.36,9.01
                c0.29,0.6,0.57,1.2,0.86,1.8c4.02-1.17,8.03-2.35,12.12-3.55c-0.36,0.88-0.9,2.2-1.44,3.53c0.24,0.39,0.48,0.79,0.73,1.18
                c2.93-1.36,5.87-2.72,8.83-4.09c-1.75,2.83-3.56,5.76-5.38,8.69c0.43,0.4,0.86,0.81,1.29,1.21c2.16-1.55,4.33-3.11,6.49-4.66
                l-0.03-0.03c-1.26,3.39-2.53,6.77-3.79,10.16c0.61,0.38,1.21,0.76,1.82,1.14c3.33-3.42,6.66-6.85,10-10.27l-0.03-0.03
                c-1.39,4.25-5.16,7.93-3.25,14.1c2.14-2.08,3.7-3.59,5.26-5.1c0,0,0,0.01,0,0.01c-0.46,2.43-0.91,4.86-1.37,7.3
                c0.52,0.28,1.03,0.56,1.55,0.84c2.28-2.38,4.55-4.76,6.83-7.14c0,0-0.02-0.01-0.02-0.01c-1.06,3.57-2.13,7.14-3.19,10.71
                c0.59,0.32,1.18,0.64,1.77,0.96c1.81-1.88,3.63-3.75,5.44-5.63l-0.04-0.04c0,2.24,0,4.49,0,7.7c1.78-1.17,2.91-1.92,4.04-2.66
                l-0.03-0.03c0,1.72,0,3.45,0,5.17c0.37,0.19,0.74,0.38,1.11,0.57c2.3-2.92,4.59-5.83,6.89-8.75l0,0c-1.78,5.09-3.18,9.7-1.9,15.73
                c2.57,12.12,3.14,24.67,4.5,37.05c0.05,0.45-0.24,0.93-0.64,2.37c-3.32-1.91-6.34-3.7-9.41-5.42c-2.75-1.53-5.47-3.18-8.39-4.3
                c-0.59-0.22-3,2.39-2.88,2.57c2.53,3.78,5.29,7.4,8.2,11.31c-0.55,0.79-1.56,2.21-3.14,4.46c2.59,0.33,4.42,0.56,6.34,0.81
                c-2.02,2.11-4.05,4.23-6.08,6.36c0.21,0.47,0.42,0.93,0.62,1.4c4.09,0,8.18,0,12.62,0c-4.82,3.15-9.33,6.09-13.85,9.04
                c0.27,0.52,0.53,1.05,0.8,1.57c6.29,2.09,12.16-0.93,18.19-2.01l0,0.01c-5.94,3.52-12.84,5.72-17.64,12.43
                c7.52,2.25,12.83-1.99,18.64-3.44l-0.03-0.03c-5.5,4.77-11,9.54-17.39,15.08c-1.49-2.71-3.74-5.43-4.55-8.53
                c-2.51-9.64-4.94-19.35-6.53-29.17c-2.7-16.72-4.72-33.55-7.04-50.33c-0.16-1.13-0.41-2.26-0.71-3.36
                c-0.05-0.18-0.55-0.25-2.16-0.89c2.61,30.65,5.18,60.67,7.79,91.35c-1.75-0.67-2.22-0.72-2.25-0.88
                c-1.45-6.71-4.64-12.04-9.39-17.34c-6.55-7.3-8.26-17.16-8.39-27.08c-0.21-16.64-0.45-33.28-1.1-49.9
                c-0.25-6.38-1.67-12.71-2.66-19.8c-2.32,1.19-3.54,1.81-5.32,2.72c-2.32-4.36-4.6-8.64-6.88-12.93c-0.6,0.09-1.21,0.18-1.81,0.27
                c5.23,43.13,8.16,86.34,5.71,128.82c-4.15-1.52-8.55-2.76-12.6-4.71c-5-2.4-9.71-5.38-14.54-8.13c-3.68-2.1-7.33-4.25-11.02-6.34
                c-3.44-1.95-7.29-3.4-10.2-5.94c-1.37-1.19-1.58-4.49-1.28-6.69c2.44-17.96,4.84-35.93,7.8-53.81
                c4.9-29.52,9.83-59.06,15.51-88.44c3.78-19.58,10.29-38.7,13.03-58.39c2.92-21.01,3.72-42.42,3.88-63.67
                c0.22-28.11-0.91-56.24-1.77-84.36c-1.06-34.59-2.92-69.16-3.5-103.75c-0.34-20.27,1.19-40.57,1.76-60.86
                c0.08-2.97-0.64-5.94-0.76-8.93c-0.22-5.43,0.03-10.89-0.46-16.29c-0.71-7.88-2.17-15.7-2.81-23.59
                c-1.16-14.25-2.14-28.52-2.87-42.8c-0.84-16.28-1.43-32.57-1.95-48.87c-0.55-17.51-7.26-32.73-17.83-46.18
                c-11.1-14.12-22.82-27.78-34.59-41.36c-19.15-22.1-29.36-48.03-33.67-76.6c-0.47-3.12-0.74-6.28-1.12-9.42
                c-0.98-8.28-3.97-15.44-11.19-20.28c-0.55-0.37-1.07-0.81-1.53-1.28c-11.58-11.66-24.58-20.98-39.83-27.67
                c-8.18-3.59-14.74-10.74-22.41-15.7c-6.35-4.1-13.04-7.81-19.96-10.85c-8.91-3.91-17.66-7.82-24.11-15.47c0,0-0.03,0.03-0.03,0.03
                c6.31,3.63,12.55,7.4,18.99,10.81c2.04,1.08,4.6,1.17,7.55,1.86c-6.56-10.48-12.56-20.07-18.57-29.66
                C717.89,186.49,717.95,186.44,717.95,186.44z"/>
            <path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M576.86,2.09c153.19,0,305.67,0,458.15,0c0.06,0.36,0.12,0.72,0.17,1.08c-2.03,0.19-4.1,0.73-6.07,0.49
                c-5.36-0.68-9.85,2.5-11.84,7.61c-1.09,2.81-3.56,5.15-5.6,7.53c-2.44,2.83-4.94,3.2-8.74,1.49c-2.92-1.31-6.88-0.67-8.02,4.17
                c-1.06,4.52-3.7,5.62-8.02,4.03c-5.45-2.01-11.56,2-12.57,7.37c-0.53,2.82-2.74,5.45-4.61,7.84c-1.32,1.69-3.18,3.09-5.06,4.16
                c-4.64,2.64-6.38,5.67-2.42,10.31c0.31,0.37-0.58,1.93-1.11,2.81c-0.76,1.25-2.64,2.77-2.35,3.5c0.52,1.29,2.16,2.57,3.59,2.99
                c5.47,1.64,8.43,4.91,10.91,9.4c1.38,2.49,4.86,4.32,7.78,5.32c2.5,0.85,5.68-0.47,8.33,0.13c6.67,1.52,13.69,0.37,20.21,3.01
                c1.74,0.71,3.89,0.6,5.84,0.53c3.68-0.13,5.86,1.34,5.71,5.16c-0.14,3.8,1.99,4.55,4.73,3.29c8.55-3.91,15.91,0.9,23.49,3.13
                c4.65,1.37,7.14,7.12,6.82,12.05c-0.07,1.13,0.45,2.49,1.13,3.43c4.43,6.22,9.34,12.13,13.32,18.62c1.62,2.65,1.39,6.48,1.86,9.79
                c0.68,4.71-1.54,10.06,3.61,13.82c0.76,0.56,0.28,4.03-0.66,5.47c-2.73,4.2-5.99,8.06-9.51,12.66c-0.9-0.58-2.59-1.66-4.92-3.15
                c-1.77,5.6-5.42,8.14-11.28,6.99c-0.48-0.09-1.92,1.99-1.96,3.09c-0.05,1.53,0.8,3.09,1.67,5.99c-2.86-1.34-4.63-2.3-6.48-3.02
                c-4.72-1.85-9.4-3.97-14.29-5.18c-1.64-0.4-4.51,1.14-5.73,2.67c-4.91,6.14-4.75,6.27-11.68,2.63c-4.77,3.97-7.04,3.57-10.44-1.84
                c-3.97-6.32-5.46-6.19-11.5-2.08c-2.15,1.47-6.05,0.38-9.15,0.44c-2.06,0.04-4.11,0.1-6.17,0.1c-1.22,0-2.81,0.34-3.56-0.29
                c-0.78-0.64-1.36-2.58-0.95-3.4c1.43-2.86,0.6-4.77-1.81-6.37c-2.69-1.78-5.24-2.06-7.48,0.75c-0.2,0.25-0.52,0.63-0.76,0.61
                c-5.89-0.57-11.9-0.64-17.59-2.02c-2.67-0.65-3.97-3.77-3.28-7.47c0.37-2-1.15-5.31-2.88-6.51c-2.09-1.46-5.58-2.3-7.95-1.62
                c-3.18,0.91-4.86,0.87-7.26-1.72c-4.08-4.41-8.73-8.37-13.51-12.05c-1.67-1.29-4.43-1.09-6.64-1.76
                c-3.32-1.02-6.47-1.74-3.77-6.78c2.55-4.76-1.98-11.17-7.27-11.26c-5.12-0.09-8.35-4.38-9.1-9.23c-0.45-2.87-3.49-5.51-5.81-7.81
                c-0.56-0.56-2.87,0.96-4.37,0.96c-2.24-0.01-4.87,0.12-6.62-0.97c-2.37-1.47-2.56-4.14-0.54-6.5c1.19-1.38,2.85-2.55,3.52-4.15
                c1.64-3.88-0.55-6.28-3.86-7.95c-2.28-1.15-3.92-1.74-2.53-5.5c0.92-2.49-1.81-6.32-2.89-9.55c-2.27-6.81-6.33-10.42-14.07-8.69
                c-1.36,0.3-3.28-1.42-4.79-2.43c-3.04-2.03-5.36-1.75-6.54,1.94c-0.89,2.79-2.62,3.15-5.02,2.22c-1.24-0.48-2.66-0.76-3.64-1.57
                c-4.95-4.11-9.93-3.16-14.71,0.01c-2.62,1.74-4.76,2.44-7.64,0.54c-1.22-0.81-3.41-1.13-4.75-0.61
                c-5.89,2.32-11.65,4.99-17.42,7.61c-0.87,0.4-2.04,1.04-2.27,1.81c-1.67,5.68-5.52,3.95-8.67,2.24c-3.52-1.92-6.47-4.85-9.9-6.98
                c-1.39-0.86-3.64-1.49-5.04-0.98c-4.55,1.69-8.12,0.1-11.6-2.46c-2.56-1.88-4.67-2.99-7.83-0.3c-3.61,3.08-8.13,1.28-10.61-3.44
                c-2.77-5.26-6.35-5.92-10.97-2c-4.96,4.21-7.37,3.47-9.32-2.89c-1.65-5.35-6.73-6.88-10.07-2.67c-2.37,2.99-4.29,2.21-6.07,0.16
                c-2.85-3.28-5.86-5.85-10.5-5.84c-0.71,0-1.76-0.58-2.07-1.18c-2.53-5-6.01-4.45-10.4-2.5c-4.54,2.02-5.66,0.73-7.38-4.18
                c-0.63-1.82-3.44-3.32-5.57-4.02c-1.51-0.49-4.57,0.03-5.16,1.09c-3.19,5.77-8.18,4.04-12.5,3.04c-5.06-1.18-9.98-3.21-14.7-5.44
                c-1.66-0.78-3.19-3.07-3.63-4.94c-0.99-4.24-3.53-4.49-7.09-4.16c-2.39,0.22-4.86-0.56-7.3-0.71c-2.76-0.18-5.63-0.62-8.29-0.12
                c-5.09,0.97-8.78-0.87-11.82-4.64c-0.6-0.74-1.47-2.23-1.21-2.54C582.36,10.15,577.75,6.59,576.86,2.09z"/>
            <path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M195.45,151.67c-3.97,3.31-6.62,5.73-9.49,7.87c-8.05,5.98-8.77,14.91-8.1,23.45
                c0.29,3.74,4.81,7.16,7.44,10.7c0.28,0.38,1.03,0.53,1.15,0.91c2.45,7.78,8.72,5.61,14.11,5.32c4.27-0.23,8.76,2.95,12.82-0.75
                c6.39,3.84,13.19,2.1,19.93,1.57c1-0.08,2.09,0.94,3.13,1.45c3.42,1.7,6.37,4.24,10.93,3.05c1.68-0.43,4.51,1.83,6.16,3.51
                c1.96,1.99,3.36,4.18,6.62,2.54c0.55-0.27,1.65,0.1,2.27,0.51c3.96,2.63,8,5.17,11.71,8.13c1.51,1.2,2.22,3.39,3.34,5.1
                c0.68,1.04,1.27,2.22,2.19,3.01c2.38,2.06,4.92,3.93,7.5,5.96c-3.55,4.67-6.69,6.49-10.89,2.62c-0.12-0.11-0.28-0.26-0.42-0.26
                c-6.09-0.11-12.24-0.72-18.25-0.08c-3.24,0.35-5.64,1.6-8.17-1.82c-2.58-3.5-6.15-1.75-9.52-0.49c-1.44,0.53-3.32,0.04-4.96-0.2
                c-1.44-0.21-2.82-0.81-4.26-1.07c-0.78-0.14-2.06,0.44-2.36,0.07c-3.22-3.83-7.64-3.16-11.75-3.03c-2.05,0.06-4.6,0.72-5.93,2.07
                c-0.93,0.95-0.18,3.55-0.18,6.17c-4.38,3.2-9.99,4.51-16.23,0.94c-1.33-0.76-3.62-1.22-4.82-0.59c-6.73,3.55-9.89-1.53-13.14-5.45
                c-2.29-2.77-3.75-4.69-7.68-2.35c-1.24,0.74-4.2-0.8-6.06-1.86c-1.78-1.02-3.58-2.5-4.6-4.24c-4.42-7.51-11.61-6.87-18.68-6.83
                c-0.65,0-1.59,0.06-1.9-0.32c-3.82-4.71-8.68-2.03-13.24-2.19c-6.72-0.23-13.41-1.33-20.13-1.88c-3.2-0.26-6.44,0.06-9.65-0.18
                c-2.09-0.15-4.79-0.15-6.09-1.39c-4.3-4.08-8.67-2.79-13.42-1.47c-1.91,0.53-4.85,0.83-6.07-0.22c-5.6-4.82-12.52-5.38-19.19-6.83
                c-3.75-0.81-7.53-1.52-11.25-2.46c-0.83-0.21-1.73-1.07-2.14-1.87c-2.36-4.51-5.38-4.3-8.9-1.48c-0.39,0.31-0.83,0.74-1.27,0.77
                c-6.77,0.55-13.55,1.3-20.33,1.32c-1.22,0-2.82-2.84-3.58-4.65c-0.59-1.43-0.17-3.28-0.17-4.95c0-41.62,0-83.24,0-124.86
                c0-7.1,4.35-9.52,10.94-6.43c1.79,0.84,4.17,0.34,6.21,0.77c4.2,0.87,8.6,1.41,12.47,3.1c4.36,1.9,8.26,4.87,12.29,7.48
                c1.34,0.87,2.31,2.54,3.72,3.02c2.34,0.79,5.27,1.8,7.25,0.99c1.46-0.59,2.63-4,2.48-6.04c-0.43-6.06-0.68-5.93,5.63-5.17
                c1.52,0.18,3.71-0.6,4.74-1.73c3.7-4.06,6.66-8.86,10.64-12.59c3.3-3.1,7.63-5.12,11.55-7.55c0.83-0.51,2.12-0.45,2.74-1.09
                c5.45-5.63,12.54-4.84,19.38-5.18c1.3-0.06,3.24-0.08,3.76-0.86c3.83-5.76,11.25-4.16,15.97-8.01c0.68-0.55,2-0.84,2.83-0.6
                c7.62,2.23,14.69-1.04,22.02-1.8c4.22-0.43,8.64,1.05,12.97,1.62c2.38,0.32,4.85,1.05,7.15,0.7c6.28-0.97,12.49-2.43,18.72-3.69
                c2.41-0.49,4.81-1.3,7.23-1.38c3.65-0.11,7.31,0.31,10.97,0.47c2.6,0.12,5.24-0.02,7.81,0.33c1.64,0.23,3.29,0.97,4.75,1.79
                c2.58,1.45,5.02,3.15,7.54,4.72c3.59,2.24,7.53,4.06,10.7,6.78c4.09,3.51,10.52,4.5,12.16,10.62c0.41,1.53,0.32,3.93-0.61,4.95
                c-3.15,3.45-6.79,6.47-10.24,9.65c-4.07,3.75-8.31,7.35-12.16,11.32c-2.21,2.28-4.1,3.35-7.38,2.21c-4.8-1.66-8.81,1.19-8.65,6.29
                c0.13,4.26-1.66,5.77-5.51,6.74c-2.93,0.74-5.8,2.44-8.14,4.37c-0.76,0.62-0.03,3.44,0.49,5.1c0.37,1.15,2.14,1.96,2.27,3.05
                c0.32,2.75-2.63,6.31,2.6,7.56c2.89,0.69,5.62,2.06,8.4,3.17c0.86,0.35,1.94,0.66,2.43,1.34c2.69,3.69,3.52,9.31,9.81,9.14
                c-0.07,2.11,0.7,5-0.39,6.14c-1.8,1.9-4.69,3.43-7.29,3.77c-5.99,0.8-9.22,4.51-12.03,9.21c-0.69,1.16-2.09,1.91-3.19,2.81
                c-1.46,1.2-2.97,2.34-4.44,3.54c-0.61,0.5-1.22,1.6-1.72,1.54C195.98,146.58,195.11,149.36,195.45,151.67z"/>
            <path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M453.18,424.27c1.87,2.95,12.31,6.99,15.93,6.16c5.84-1.34,11.66,1.59,14.18,6.97
                c0.46,0.97,1.39,1.98,2.35,2.4c2.98,1.31,6.79,1.31,7.12,6.06c0.05,0.69,3.2,1.13,4.88,1.76c3.06,1.14,7.37,0.86,6.86,6.14
                c-0.06,0.63,1.04,1.55,1.79,2.05c2.64,1.74,5.3,3.45,8.06,4.98c5.31,2.95,11.19,5.15,15.86,8.87c2.91,2.32,4.99,6.72,5.62,10.49
                c0.38,2.3-3.27,3.02-6.01,1.84c-2.9-1.24-5.51-3.43-9.24-1.75c-1.05,0.47-3.22-1.31-4.77-2.24c-2.77-1.67-5.18-4.28-8.99-2.43
                c-0.64,0.31-2.38-0.21-2.67-0.8c-2.6-5.36-8.01-2.31-11.76-4.07c-0.13-0.06-0.35-0.01-0.49,0.06c-6.53,3.9-13.2,0.84-19.83,0.49
                c-0.34-0.02-0.72,0.54-1.98,1.55c4.66,1.28,8.61,2.36,12.45,3.41c-0.5,2.37-0.9,4.31-1.39,6.64c-2.58,0.27-5.65,0.69-8.74,0.87
                c-1.1,0.06-2.7-0.07-3.28-0.77c-5.77-6.92-15.34-5.99-22.14-10.91c-4.05-2.93-11.96,0.63-13.77,5.39
                c-3.45-0.36-6.82-0.81-10.21-1.01c-1.07-0.06-2.67,0.19-3.18,0.9c-2.84,3.95-6.7,1.87-10.04,1.72
                c-8.52-0.38-17.01-1.45-25.53-1.76c-3.62-0.13-7.88-2.14-10.86,2.03c-0.23,0.32-1.31,0.2-1.93,0.03
                c-5.98-1.65-11.85-3.82-17.92-4.92c-6.14-1.11-12.48-1.12-18.72-1.71c-1.63-0.15-3.21-0.73-4.83-1
                c-6.17-1.03-12.36-1.92-18.5-3.06c-4.85-0.9-9.61-2.26-14.46-3.12c-2.02-0.36-4.19,0.25-6.26,0.06
                c-3.65-0.32-7.64-0.11-10.85-1.55c-9.08-4.06-18.02-8.51-26.63-13.48c-4.62-2.67-8.46-6.7-12.65-10.12
                c-1.96-1.61-3.91-3.24-6.48-5.37c1.86-0.4,3.66-0.45,5.11-1.19c1.63-0.83,2.95-2.29,4.4-3.48c-1.08-0.95-2.06-2.06-3.27-2.82
                c-5.39-3.36-10.5-7.69-16.38-9.6c-6.03-1.96-11.57-7.54-18.91-4.27c-0.92,0.41-2.58,0.3-3.36-0.29
                c-6.11-4.56-12.11-2.63-18.54-0.62c-5.32,1.66-11.35,1.15-16.58,2.97c-7.66,2.67-14.89,2.04-22.43,0.12
                c-2.22-0.57-4.78,0.18-7.32,0.23c-2.96,0.16-5.99-0.03-8.86,0.56c-4.89,1-10.19,4.44-14.39,3.33c-6.88-1.82-10.2,2.89-15.11,4.74
                c-2.56,0.97-5.73,0.25-8.61,0.48c-1.41,0.11-3.54,0.19-4.05,1.05c-2.74,4.62-6.5,6.64-11.08,4.66c-2.89-1.25-4.8-0.69-7.3,0.86
                c-1.58,0.98-4.03,0.57-6.1,0.7c-1.57,0.1-3.16-0.09-4.71,0.13c-1.27,0.18-2.46,0.89-3.72,1.17c-1.25,0.28-2.56,0.31-4.24,0.49
                c0.44,1.62,0.8,2.93,1.08,3.97c-3.34,0.57-6.53,1.16-9.73,1.64c-2.96,0.44-5.26,0.49-7.43-2.81c-3.6-5.46-14.14-6.47-21.44-2.94
                c-4.67,2.25-9.23-0.43-9.23-5.55c-0.02-39.97-0.02-79.93,0-119.9c0-5.86,1.97-6.92,7.42-4.35c6.51,3.08,13.04,6.14,19.66,8.99
                c1.91,0.82,4.14,0.89,5.82,1.22c-2.12,2.25-4.37,4.71-6.72,7.09c-0.41,0.42-1.23,0.76-1.79,0.67c-5.53-0.84-5.51,2.54-5.22,6.47
                c0.13,1.74-0.38,3.53-0.67,5.28c-0.08,0.48-0.59,0.92-0.56,1.37c0.24,4.83,0.83,9.41-1.48,14.31c-1.39,2.94,5.41,7.12,9.61,5.83
                c2.78-0.86,4.33-0.24,5.81,2.06c1.96,3.04,4.53,4.86,8.43,3.31c0.7-0.28,1.67-0.14,2.44,0.07c4.6,1.23,9.11,2.89,13.78,3.65
                c1.87,0.3,4.05-1.31,6.63-2.25c5.87,7.61,13.01,2.02,20.04,0.77c2.9-0.52,6.15-1.03,8.87-0.25c5.03,1.45,9.07,3.52,15.06,0.86
                c5.37-2.39,12.43-1.23,18.74-1.2c7.07,0.02,12.99-2.79,18.55-6.67c0.77-0.54,1.22-2.05,1.21-3.1
                c-0.01-4.23-7.61-11.51-11.74-12.77c-3.77-1.15-7.25-3.47-10.55-5.74c-0.77-0.53-0.27-2.91-0.36-4.43
                c1.4-0.01,2.96-0.41,4.16,0.07c2.13,0.87,4.01,2.33,6.06,3.44c1.16,0.63,2.5,1.59,3.66,1.47c5.03-0.53,7.99,3.33,11.83,4.87
                c4.91,1.96,10.95,3,16.4,3.09c5.16,0.09,9.74-3.77,12.29-8.5c1.21-2.24,0.91-5.4,0.97-8.15c0.11-5,7.15-12.33,12.06-12.56
                c4.3-0.2,8.59-0.95,12.88-0.91c2.5,0.02,4.98,1.1,7.69,2.5c-5.68,1.43-6.12,9.03-12.75,9.47c-1.7,0.11-4.18,4.06-4.38,6.42
                c-0.16,1.84,2.64,3.94,4.37,6.26c-2.73,7.03-1.74,9.63,4.98,15.37c2.3,1.97,3.37,5.31,5.38,7.72c1.47,1.77,3.47,3.14,5.38,4.48
                c1.98,1.39,4.66,2.09,6.09,3.85c2.73,3.36,5.65,5.51,10.16,4.97c1.3-0.16,2.99-0.5,3.91,0.12c4.43,2.99,8.94,5.53,14.39,6.16
                c1.86,0.21,3.63,1.58,5.45,1.62c6.8,0.15,13.61,0.01,20.42-0.15c1.24-0.03,2.47-0.66,4.11-1.13c2.12,5.04,5.47,4.04,9.59,1.84
                c2.09-1.12,4.96-0.85,7.48-1.06c0.96-0.08,2.13,0.66,2.91,0.34c8.11-3.35,13.3,1.51,18.6,6.35c3.42,3.12,9.03,3.17,12.63,0.33
                c0.26-0.2,0.45-0.5,0.73-0.67c2.06-1.29,14.28,1.12,15.88,3.12c3.98,4.97,9.94,6.73,15.01,3.42c2.54-1.66,3.9-5.11,6.35-8.52
                c0.31,0.17,1.62,0.93,2.98,1.56c1.92,0.88,4.21,2.76,5.79,2.3c6.85-1.96,14.24,2.4,20.04-0.22c7.48-3.37,12.98,0.88,19.35,1.55
                c2.26,0.24,4.73,0.47,6.84-0.17c3.25-0.99,5.8-0.96,8.74,1.15c1.1,0.79,3.79-0.6,5.74-1.06c2.18-0.51,4.34-1.15,6.54-1.52
                c0.16-0.03,1.12,2.25,0.85,2.48c-1.67,1.46-3.42,3.36-5.43,3.78c-6.77,1.43-13.75,3.58-20.46,3.06
                c-8.61-0.66-16.99-0.25-25.34,1.17c-2.17,0.37-5.01,2.45-5.63,4.37c-0.46,1.42,1.71,4.64,3.5,5.65c3.14,1.76,6.88,2.44,10.37,3.57
                c0,0.52,0.01,1.05,0.01,1.57c-2.84,0.09-5.83,0.79-8.47,0.13c-3.54-0.89-6.38-0.67-9.95,0.54c-3.6,1.22-8.03-0.16-12.09-0.07
                c-1.85,0.04-3.69,0.86-5.51,1.42c-1.09,0.34-2.09,1.1-3.19,1.29c-7,1.19-14.13,3.58-20.98,2.96c-5.22-0.48-9.32,0.69-13.63,2.5
                c-1.65,0.69-2.93,2.27-4.38,3.44c0.06,0.53,0.13,1.06,0.19,1.59c5.61,1.49,11.18,3.11,16.83,4.43c3.18,0.74,6.47,1.31,9.72,1.34
                c3.06,0.03,6.13-0.77,9.21-1.15c0.93-0.12,2.02-0.37,2.79-0.03c5.51,2.45,10.55,1.11,15.63-1.39c1.41-0.7,3.51-1.22,4.83-0.68
                c6.13,2.51,12.4,1.37,18.63,1.24c2.11-0.05,4.19-0.77,6.31-1c2.15-0.24,4.32-0.29,6.48-0.34c0.96-0.02,2.39,0.59,2.81,0.16
                c6.37-6.64,15.16-3.48,22.61-5.65c1.59-0.46,3.76-0.16,5.26,0.6c2.93,1.48,4.73,0.27,6.49-1.77
                C453.15,424.3,453.18,424.27,453.18,424.27z"/>
            <path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M658.95,409.3c1.34,8.14,2.68,16.28,4.03,24.42c0.65-0.02,1.31-0.05,1.96-0.07
                c0.54-4.74,1.08-9.48,1.74-15.36c3.02,5.78,6.54,9.7,6.5,16.07c-0.04,5.1,2.79,10.22,4.35,15.34c0.56-0.05,1.13-0.1,1.69-0.15
                c0.77-2.64,1.54-5.28,2.53-8.67c6.72,5.11,2.86,13.32,7.33,18.9c1.03-1.74,1.81-3.07,2.38-4.05c2.38,6.83,4.83,13.87,7.28,20.92
                c0.63-0.11,1.25-0.22,1.88-0.33c0.34-3.3,0.68-6.59,1.03-9.89c0.34-0.06,0.67-0.13,1.01-0.19c0.9,4.59,1.79,9.18,2.69,13.77
                c0.74,0.02,1.48,0.04,2.22,0.06c0.67-3.51,1.35-7.02,1.88-9.8c5.16,5.2,10.67,10.74,17.23,17.34c-6.96-1.54-13.24-2.44-19.19-4.38
                c-5.98-1.95-11.52-5.21-17.44-7.37c-4.91-1.79-10.2-2.52-15.13-4.27c-3.39-1.21-6.47-3.29-9.62-5.11
                c-1.95-1.13-3.77-2.48-6.63-3.03c0.37,1.09,0.47,2.42,1.16,3.23c1.61,1.89,3.2,4.22,5.33,5.14c8.14,3.53,8.77,10.49,9.51,17.95
                c1.32,13.22,3.2,26.37,4.91,39.55c0.13,1.04,0.68,2.03,2.22,3.16c1.96-8.27,3.92-16.54,5.88-24.8c0.5-0.01,0.99-0.02,1.49-0.03
                c0.96,9.58,1.93,19.15,2.89,28.73c0.63,0.07,1.27,0.13,1.9,0.2c2.26-12.31,4.52-24.61,7.09-38.57c2.78,2.6,5.07,3.97,6.2,6
                c1.11,1.99,1.03,4.63,1.47,6.99c0.47,0.12,0.95,0.24,1.42,0.37c1.59-2.36,3.19-4.72,4.78-7.08l-0.04-0.05c0,14.41,0,28.83,0,43.24
                c5.68-5.78,4.64-13.49,6.49-19.96c0.17,0.57,0.47,1.58,0.77,2.58c0.31,0.04,0.63,0.09,0.94,0.13c1.19-5.42,2.38-10.84,3.57-16.26
                c0.38,0.37,0.77,0.74,1.15,1.12c2.39-1.06,4.78-2.11,7.34-3.25c5.96,7.28,3.44,16.92,6,25.54c2.58-8.7,5.17-17.39,7.75-26.09
                l-0.04-0.04c3.96,9.03,4.77,18.63,5.25,28.32c0.1,2,1.09,3.95,2.82,5.89c1.43-9.04,2.86-18.09,4.39-27.81
                c6.93,4.11,4.68,10.24,6.03,15.58c1.36-1.17,2.02-1.75,3.86-3.34c1.38,11.82,2.65,22.65,3.92,33.48c0.35,0.05,0.7,0.09,1.04,0.14
                c0.31-2.1,0.67-4.19,0.91-6.29c1.19-10.71,2.38-21.41,3.51-32.13c0.46-4.33,3.08-6.16,7.09-6.79c1.52-0.24,2.95-0.99,4.82-1.65
                c5.64,16.38,4.45,33.43,4.24,50.36c0.73,0.06,1.46,0.11,2.19,0.17c2.09-10.35,4.18-20.7,6.03-29.86c0.23,1.06,0.77,3.52,1.32,5.97
                c0.43,0.01,0.86,0.02,1.29,0.03c0.69-2.06,1.38-4.12,2.14-6.37c0.71,0.91,1.35,1.73,2.23,2.85c1.03-1.22,1.82-2.17,3.1-3.7
                c1.22,2.09,2.2,3.78,3.8,6.52c0.96-5.04,1.71-8.96,2.53-13.3c4.6,1.95,5.7,4.12,5.3,8.97c-0.79,9.68-0.31,19.53,0.38,29.26
                c0.62,8.76,2.28,17.44,3.37,26.17c0.2,1.56-0.16,3.19-0.32,5.89c-7.11-4.5-15.33-5.09-19.25-13.36c-1.32-2.78-5.38-5.09-8.64-5.98
                c-13.54-3.7-27.27-6.73-40.89-10.14c-2.54-0.64-5.3-1.42-7.29-2.99c-7.53-5.93-15.79-8.59-25.42-7.79
                c-3.52,0.29-7.21-0.81-10.75-1.59c-10.11-2.25-20.2-4.61-30.27-7.04c-2.58-0.62-5.04-2.3-7.59-2.37
                c-8.89-0.26-15.72-4.95-22.5-9.77c-6.22-4.43-13.35-5.6-20.63-6.77c-3.3-0.53-6.41-2.33-9.59-3.61
                c-4.56-1.84-8.97-4.75-13.67-5.33c-5.98-0.74-9.62-4.46-13.43-7.95c-5.73-5.24-12.32-8.08-19.7-10.09
                c-5.16-1.41-9.95-4.26-14.8-6.69c-7.8-3.91-16.14-4.81-24.64-4.4c-4.96,0.24-7.48,3.24-7.13,8.33c0.44,6.43,0.92,12.87,1.42,19.8
                c-2.35,0-4.63-0.28-6.81,0.05c-10.19,1.52-19.87,2.45-29.2-4.43c-3.84-2.83-10.16-1.89-13.55-7.04c-1.09-1.66-4-2.21-6.17-3.03
                c-4.19-1.59-8.28-3.64-12.21-5.37c-6.28-2.78-12.73-3.6-16.26-10.82c-1-2.05-5.56-2.35-8.47-3.48c-4.38-1.7-7.24-6.05-5.83-8.83
                c1.84-3.65,7.92-5.01,12.02-3.09c3.99,1.86,8.21,3.24,12.32,4.86c0.76,0.3,1.7,0.57,2.14,1.17c4.71,6.39,11.43,5.17,17.77,4.36
                c1.52-0.19,3.61-2.82,3.89-4.57c1.01-6.41,2.7-7.98,9.17-7.27c3.35,0.37,6.83-0.12,9.4,3.08c0.71,0.89,2.73,0.79,4.17,1.02
                c1.13,0.18,2.36-0.06,3.42,0.28c6.18,1.98,12.39,3.89,18.44,6.22c3.39,1.3,6.01,0.46,8.03-2c2.05-2.51,1.38-5.05,0.22-8.24
                c-3.06-8.48-11.68-9.11-17.42-13.69c-2.28-1.82-4.52-3.77-7.04-5.19c-5.73-3.21-8.21-10.75-15.68-11.64
                c-0.79-0.09-1.57-0.96-2.19-1.62c-0.43-0.46-0.41-1.41-0.88-1.75c-5.92-4.26-11.7-8.78-17.96-12.49c-2.24-1.33-5.7-0.46-8.53-1.01
                c-3.9-0.75-7.93-1.41-11.55-2.95c-7.06-3-14.04-6.28-20.73-10.02c-4.38-2.45-8.43-5.65-12.19-9.01c-2.14-1.91-0.89-4.2,1.7-4.86
                c2.85-0.73,5.86-0.86,8.79-1.33c3.05-0.49,6.14-0.89,9.12-1.68c5.3-1.4,6.45-4.41,5.53-10.11c-0.87-5.41,0.03-11.52,1.69-16.84
                c4.89-15.72,10.34-31.28,15.98-46.75c1.97-5.42,4.84-10.59,7.8-15.57c2.58-4.34,4.11-4.19,7.64-0.67c2,1.98,4.21,3.76,6.41,5.53
                c6.31,5.09,12.85,9.92,18.89,15.32c1.66,1.49,2.5,4.57,2.61,6.98c0.52,10.96,0.64,21.95,0.96,32.92c0.04,1.31,0.46,2.61,1.54,3.78
                c1.48-5.87,2.96-11.73,4.66-18.46c2.48,19.64,4.87,38.58,7.26,57.52c0.68-0.02,1.36-0.04,2.04-0.07c0-12.27,0-24.53,0-36.8
                c0.82-0.05,1.64-0.1,2.46-0.15c2.62,22.64,5.25,45.27,7.87,67.91c0.78-0.03,1.57-0.05,2.35-0.08c2.91-9.99,0.5-20.44,1.66-30.7
                c0.48,1.53,0.95,3.02,1.42,4.5c0.45-0.01,0.89-0.02,1.34-0.03c0-5.09,0-10.18,0-15.27c0.62-0.01,1.24-0.02,1.85-0.02
                c0,4.97,0,9.93,0,14.9c0.7,0,1.41,0,2.11,0c0-10.06,0-20.12,0-30.18c0.73-0.04,1.46-0.07,2.19-0.11
                c1.69,17.48,3.38,34.96,5.07,52.45c0.76-0.05,1.52-0.1,2.29-0.14c0-15.93,0-31.86,0-47.79c0.51-0.08,1.02-0.16,1.52-0.24
                c1.02,3.49,2.04,6.97,3.06,10.46c0.43,0,0.86,0,1.29-0.01c0.61-5.57,1.22-11.15,1.83-16.72c4.65,13.96,3.67,28.64,4.51,43.08
                c0.78,0.01,1.56,0.01,2.34,0.02c1.62-19.27,3.23-38.54,4.87-58.13c0.8,0.67,1.83,1.35,2.65,2.23
                c12.41,13.28,26.84,23.33,44.62,28.08c0.96,0.26,1.9,0.6,2.85,0.93c8.28,2.87,8.11,2.88,8.42,11.43
                c0.52,14.46,1.26,28.92,1.98,43.38c0.07,1.38,0.57,2.73,0.88,4.09c0.65-0.01,1.3-0.01,1.94-0.02c0.74-14.01,1.49-28.03,2.23-42.04
                c0.25-0.03,0.51-0.06,0.76-0.09c0.43,1.38,0.87,2.76,1.8,5.71c1.4-3.37,2.26-5.45,3.13-7.54c0.44-0.02,0.87-0.03,1.31-0.05
                c0,18.35,0,36.7,0,55.05c0.82,0.07,1.63,0.14,2.45,0.22c0.63-2.92,1.6-5.82,1.82-8.77c1.11-14.76,1.92-29.55,3.12-44.3
                c0.19-2.38,1.79-4.64,2.74-6.96L658.95,409.3z"/>
            <path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M543.25,904.97c2.54-7.14,8.71-3.07,13.11-4.57c1.17-0.4,2.73,0.1,3.72-0.49
                c4.18-2.46,5.89-1.84,7.29,2.98c0.65,2.25,1.27,4.51,1.9,6.77c0.45-0.01,0.91-0.01,1.36-0.02c0.58-1.08,1.46-2.1,1.67-3.25
                c0.71-3.8,2.46-6.42,6.56-7c0.61-0.09,1.51-0.47,1.69-0.94c2.2-5.71,8.92-8.87,8.91-15.81c0-0.49,0-1.07,0.24-1.46
                c3.2-5.31,6.14-10.83,9.86-15.76c1.09-1.45,4.44-1.78,6.69-1.65c3.23,0.19,4.96-0.79,6.92-3.46c3.76-5.11,8.17-9.78,12.66-14.27
                c1.01-1.01,3.47-0.65,5.27-0.77c3.23-0.23,5.4-1.11,6.47-4.85c1.01-3.53,3.12-6.98,5.54-9.78c1.12-1.3,4.24-1.69,6.23-1.31
                c7.55,1.48,7.47,1.64,11.1-4.97c0.47-0.86,1.77-1.25,2.65-1.9c1.16-0.86,2.34-1.72,3.43-2.67c2.23-1.97,4.41-4,6.62-6
                c0.12-0.11,0.37-0.12,0.43-0.24c4.22-8.37,14.35-7.88,20.21-13.77c0.41,0.28,0.81,0.57,1.22,0.85c-1.09,1.58-2.08,3.24-3.28,4.73
                c-2.29,2.87-5.46,5.31-6.87,8.53c-1.98,4.53-3.64,8.89-8.34,11.31c-0.97,0.5-2.37,1.67-2.31,2.44c0.43,5.9-3.51,10.18-5.43,15.17
                c-0.73,1.88-2.29,4.14-4.02,4.81c-4.03,1.56-5.03,4.22-5.03,8.13c0,4.94,0.34,10.1-0.95,14.75c-1.49,5.37-4.6,10.3-7.11,15.65
                c-0.97-0.43-2.02-0.63-2.7-1.24c-4.14-3.7-7.63-4.37-11.82,0.5c-1.4,1.62-5.67,1.81-8.24,1.14c-5.41-1.4-9.73-1.06-13.3,3.71
                c-3.35,4.48-8.63,5.83-13.5,7.91c-1.47,0.63-3.48,3.62-3.07,4.42c3.49,6.8-1.98,11.01-3.95,16.3c-1.68,4.48-2.44,9.36-3.11,14.13
                c-0.14,1.03,1.84,2.35,2.85,3.54c0.83-1.21,2.07-2.32,2.43-3.65c1.45-5.47,3.82-9.71,8.83-13.28c3.6-2.56,6.78-5.79,10.93-7.73
                c5.05-2.37,9.96-4.95,15.17-7.08c6.68-2.74,11.78-4.26,17.39,1.32c0.56,0.56,3.78-0.64,5.02-1.82c6.92-6.58,14.88-7.67,23.83-5.55
                c3.33,0.79,7.06,1.61,10.24,0.87c4.14-0.97,6.14,1.3,8.67,3.36c1.44,1.17,3.02,2.15,4.91,3.47c0.35-6.25-5.12-13.19-11.22-15.56
                c-4.25-1.65-8.43-3.54-12.52-5.56c-2.85-1.41-2.72-4.02,0.08-5.66c0.28-0.16,0.84-0.07,0.93-0.27
                c3.39-6.84,12.33-6.18,16.53-12.25c1.04-1.5,4.76-1.84,7.12-1.58c3.86,0.42,7.02,0.05,10.23-2.49c1.46-1.15,4.3-1.18,6.36-0.83
                c4.29,0.73,8.45,2.07,12.51-0.75c0.59-0.41,1.9-0.68,2.31-0.34c6.07,5.02,12.93,0,19.25,1.6c0.58,0.15,1.31-0.16,1.93-0.37
                c7.9-2.71,9.92-2.11,15.06,4.52c4.14,5.33,6.38,5.76,12.45-0.01c2.89-2.74,4.76-4.05,8.99-3.44c3.39,0.49,7.15-1.77,10.77-2.69
                c4.16-1.05,8.35-1.97,13.21-3.1c-4.45-5.22-6.67-12.3-14.7-14.16c-6.29-1.45-12.31-4.18-18.64-5.27
                c-5.52-0.94-11.31-0.38-16.97-0.47c-0.33-0.01-0.78,0.25-0.96,0.11c-7.26-5.35-12.8,1.3-19.1,2.66c-1.02,0.22-2.17-0.13-3.25-0.21
                c0.18-1.17-0.03-2.7,0.63-3.45c2.19-2.49,4.8-4.61,7.04-7.06c1.78-1.94,3.43-4.04,4.82-6.27c1.79-2.86,3.89-4.02,7.04-2.27
                c2.21,1.22,3.81,1.12,5.37-1.35c5.09-8.06,13.01-11,22.1-11.9c1.52-0.15,3.48-1.48,4.21-2.82c1.63-2.99,3.49-3.29,6.05-1.56
                c2.82,1.9,4.19,0.92,6.42-1.62c4.01-4.56,9.04-8.22,14.19-12.73c0,9.31,1.14,17.55-0.23,25.35c-3.02,17.17-3.73,34.4-3.49,51.71
                c0.27,19.28-3.19,38.08-6.41,56.94c-0.13,0.75-0.66,1.43-1.5,3.17c-1.89-9.01-6.31-15.23-14.97-14.96
                c-4.39,0.14-10.62-0.19-12.88,6.43c-3.68-1.85-6.63-1.55-7.76,3.2c-0.17,0.69-0.96,1.39-1.64,1.78c-3.42,1.95-6.9,3.8-10.36,5.69
                c-2.56,1.4-4.83,7.92-3.72,10.72c2.52,6.37,1.65,8.25-4.89,10.62c-0.29-0.15-0.65-0.24-0.85-0.47c-1.5-1.7-2.97-3.43-4.44-5.14
                c-1.5,2.03-3.46,3.88-4.37,6.15c-1.13,2.82-1.21,6.03-2.1,8.97c-0.43,1.44-1.66,2.63-2.76,4.3c0.65,1.15,1.45,2.56,2.25,3.97
                c-0.41-0.01-0.82-0.03-1.23-0.04c-0.24,2.57-0.46,5.14-0.74,7.71c-0.17,1.61-0.63,3.23-0.59,4.83c0.06,2.13,0.42,4.25,0.76,6.37
                c0.21,1.33,1.28,3.16,0.76,3.83c-0.85,1.1-2.7,2.03-4.1,1.99c-4.8-0.15-9.58-0.87-14.39-1.13c-4.3-0.23-8.62,0-12.92-0.23
                c-8.04-0.42-16.06-1.15-24.1-1.5c-5.59-0.25-11.2-0.04-16.8-0.1c-9.43-0.1-18.87-0.39-28.3-0.35c-7.23,0.03-14.46,0.45-21.69,0.72
                c-0.49,0.02-1.05,0.47-1.44,0.33c-7.06-2.47-14.25-1.1-21.43-0.94c-10.72,0.24-21.45,0-32.18,0.05
                c-3.65,0.02-7.32,0.59-10.94,0.35c-9.2-0.6-18.38-1.52-27.57-2.31c-0.33-0.03-0.73-0.31-0.96-0.2
                c-7.71,3.72-11.67-2.82-16.12-6.54c-2.97-2.48-4.51-6.82-6.24-10.53c-0.3-0.65,1.33-2.75,2.5-3.43c4.22-2.44,6.29-5.94,5.71-10.72
                c-0.18-1.47-1.07-3.26-2.22-4.13c-4.88-3.68-4.91-9.45-0.7-14.05c3.12-3.41,5.16-7.65,9.47-10.2c1.35-0.8,1.37-4.22,1.57-6.48
                c0.35-4.08,1.66-6.02,6.34-5.07c6.74,1.37,7.95,0.24,8.65-6.21c0.34-3.13,5.13-6.52,8.38-5.85
                C540.88,904.07,541.93,904.53,543.25,904.97z"/>
            <path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M493.06,211.66c-0.61-1.61-0.9-4.25-2.37-5.73c-1.91-1.92-4.73-3.89-7.19-3.94
                c-1.44-0.03-3.17,3.53-4.35,5.71c-0.97,1.78-0.81,5.11-2.04,5.63c-3.1,1.3-6.7,1.76-10.1,1.75c-1,0-2.17-2.6-2.96-4.16
                c-2.22-4.4-3.9-4.77-7.43-1.32c-1.31,1.28-2.38,2.8-3.73,4.03c-3.9,3.58-10.78,3.16-13.86-0.83c-3.46-4.48-8.39-5.96-13.63-6.52
                c-6.8-0.73-13.62-1.32-20.45-1.59c-1.99-0.08-4.52,0.59-5.93,1.87c-2.9,2.62-5.8,3.05-9.16,1.63c-3.48-1.47-7.24-2.48-7.4-7.56
                c-0.13-4.39-3-6.6-7.38-5.89c-2.91,0.47-5.82,1.45-8.51,2.69c-3.22,1.48-6.19,3.27-9.85,1.28c-0.6-0.33-1.64-0.11-2.39,0.12
                c-7.96,2.48-11.76,1.34-16.42-5.02c-0.39-0.53-0.71-1.13-1.05-1.69c-1.89-3.08-3.15-7.67-5.85-8.79c-2.45-1.02-6.39,2.18-9.8,2.75
                c-6.72,1.13-13.44,1.3-19.58-2.67c-1.08-0.7-2.69-1.28-3.85-1.02c-8.31,1.85-17.94-7.05-16.41-15.34c0.81-4.37-3.8-8.88-7.86-7.22
                c-4.01,1.65-4.68-0.02-4.83-3.37c-0.33-7.46-0.8-14.91-1.16-22.36c-0.15-3.2-0.22-6.29,3.36-8.17c0.74-0.39,1.18-3.1,0.6-4
                c-4.29-6.71-2.11-12.73,1.71-18.54c0.51-0.77,2.31-1.11,3.44-0.98c5.57,0.62,6.97-0.57,7.01-6.36c0.03-5.73,0.53-6.06,5.44-4.05
                c4.66-3.43,9.16-6.67,13.58-10.04c1-0.77,1.97-1.84,2.44-2.99c0.99-2.39,2.08-3.68,4.87-2.23c0.73,0.38,2.03-0.28,3.06-0.52
                c6.51-1.47,11.46-4.84,14.69-10.93c1.69-3.19,4.66-2.15,6.77-0.16c1.93,1.82,3.61,2.68,6.06,1.1c0.63-0.41,1.63-0.2,2.44-0.38
                c6.5-1.47,13-2.96,19.49-4.47c0.8-0.19,1.59-0.91,2.33-0.82c6.6,0.76,13.18,2.24,19.74-0.41c0.47-0.19,1.65,0.55,2.05,1.15
                c1.95,2.98,4.13,1.91,6.44,0.61c2.91-1.64,5.81-2.45,8.8,0.02c0.65,0.53,2.07,0.86,2.74,0.52c4.6-2.38,7.78-0.3,11.09,2.76
                c1.22,1.12,3.61,1.61,5.34,1.39c3.87-0.49,7.63-1.72,11.48-2.46c4.85-0.94,8.51,1.2,11.04,5.13c2.36,3.66,4.99,4.31,8.82,2.24
                c3.8-2.05,7.51-2.89,11.26,0.95c1.52,1.55,4.65,1.44,6.94,2.34c1.66,0.65,4.49,1.71,4.5,2.61c0.05,6.01,5.68,7.74,8.43,11.59
                c1.21,1.68,3.79,2.5,5.88,3.4c2.36,1.02,3.63,2.05,3.41,5.13c-0.37,5.35,4.49,10.88,9.85,12.14c5.35,1.26,6.96,5.23,4.14,10.22
                c-3.06,5.41-2.56,7.05,3.42,8.89c4.21,1.29,5.12,3.37,3.52,7.43c-1.06,2.7-1.09,5.79-1.73,8.67c-0.27,1.19-0.62,2.66-1.47,3.38
                c-3.43,2.91-3.03,6.16-0.9,9.35c2.21,3.31,4.53,6.64,7.36,9.39c1.59,1.54,4.3,1.91,6.49,2.85c1.56,0.67,3.09,1.42,4.64,2.13
                c-0.68,1.18-1.34,2.37-2.04,3.54c-0.17,0.28-0.58,0.44-0.66,0.72c-1.77,6.25-3.52,12.51-5.79,18.42c-1.21,2.31-2.45,4.6-3.61,6.93
                c-0.62,1.25-0.98,3.59-1.66,3.67c-1.44,0.17-3.41-0.42-4.44-1.43c-2.68-2.63-4.41-3.8-7.33,0.17
                C500.33,207.02,496.55,208.93,493.06,211.66z"/>
            <path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M71.39,791.22c-0.46-2.3-1.19-5.92-1.93-9.54c-0.1-0.48-0.13-1.2-0.44-1.39
                c-7.44-4.57-4.69-13.09-7.48-19.45c-0.69-1.57-0.3-3.58-0.64-5.34c-0.3-1.55-0.46-3.59-1.49-4.47
                c-5.84-4.98-8.96-11.56-12.58-18.15c-5.94-10.82-15.71-16.99-28.35-18.25c-1.78-0.18-3.35-2.01-5.13-2.83
                c-2.25-1.04-4.63-2.63-6.91-2.57c-3.4,0.1-4.57-0.94-4.51-4.14c0.08-4.31,0.02-8.61,0.02-13.01c7.24,1.15,13.69,1.59,20.6-1.62
                c4.32-2.01,10.29-1.13,14.91,2.55c1.71,1.37,4.27,1.66,6.41,2.51c4.25,1.68,8.47,3.43,12.72,5.1c1.95,0.76,3.98,1.32,5.9,2.13
                c1.92,0.8,3.8,1.72,5.63,2.72c3.1,1.7,6.11,3.57,9.23,5.25c2.51,1.35,5.05,2.69,7.71,3.69c1.43,0.54,3.18,0.17,4.71,0.53
                c2.74,0.65,6,0.84,8,2.49c3.39,2.78,5.91,6.6,8.92,9.87c0.7,0.76,1.92,0.99,2.82,1.6c1.59,1.06,3.95,1.91,4.5,3.4
                c1.53,4.09,4,5.96,9.38,4.76c-0.49,3.09-0.85,5.37-1.21,7.65c-1.69-0.28-3.37-0.62-5.06-0.82c-1.29-0.16-2.99-0.72-3.85-0.13
                c-4.7,3.21-6.19-0.13-9.38-2.96c-4.65-4.12-11.01-6.32-16.6-9.4c-2.43-1.34-5.36-2.35-7.04-4.37c-3.19-3.84-12.62-7.26-17.06-5.09
                c-0.91,0.44-1.81,2.68-1.45,3.56c0.85,2.06,2.1,4.32,3.86,5.58c5.97,4.28,11.4,8.85,13.97,16.09c0.37,1.03,1.82,2.3,2.85,2.37
                c7.01,0.51,9.1,5.75,11.28,11.09c1.4,3.42,3.05,6.74,5.08,11.19c3.31-4.82,6.74-6.24,10.89-4.4c1.64,0.73,3.1,1.92,4.52,3.06
                c1.13,0.91,1.95,2.82,3.09,2.98c4.92,0.68,4.23,3.93,4.1,7.35c-0.81,22.62-1.22,45.26-2.32,67.86
                c-1.34,27.57-2.13,55.23-5.25,82.62c-2.15,18.89-7.87,37.37-11.97,56.03c-0.92,4.19-2.89,5.95-7.66,5.46
                c-4.88-0.51-9.9,0.56-14.87,0.74c-3.77,0.13-7.68,0.56-11.3-0.22c-13.25-2.84-26.45-4.62-40.09-2.97
                c-6.89,0.83-13.96,0.13-20.95,0.18c-3.54,0.02-5.14-1.59-5.06-5.19c0.12-5.16,0.02-10.32,0.02-15.48c0-69.27,0-138.54,0-207.8
                c0-2.11,0-4.22,0-5.87c3.1-0.65,5.44-1.23,7.81-1.59c0.86-0.13,2.16-0.06,2.66,0.48c2.57,2.79,5.32,5.53,7.27,8.73
                c1.64,2.68,2.92,4.66,6.51,3.84c2.78-0.64,3.71,1.11,4.05,3.69c0.49,3.72,1.41,7.39,2.04,11.1c0.16,0.93,0.06,1.97-0.18,2.9
                c-1.38,5.44-0.85,6.55,4.57,5.99c7.7-0.8,14.25,0.38,18.62,7.47c0.25,0.41,0.68,0.91,1.1,0.99c8.02,1.49,10.32,9.29,15.52,13.87
                c1.35,1.19,3.53,2.04,5.32,2.03c10.47-0.05,10.22-0.11,12.2,10.5c0.47,2.5,2.71,5.54,4.96,6.61c2.76,1.31,3.7,2.64,3.88,5.46
                c0.36,5.66,2.15,6.84,10.14,6.95c4.34,0.06,4.44-3.56,4.74-6.38c0.57-5.31,1.14-10.23-3.46-15.06
                c-5.25-5.51-8.22-13.15-13.31-18.85c-4.37-4.89-10.13-8.66-15.69-12.29C78.6,791.26,75.33,791.74,71.39,791.22z"/>
            <path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M902.73,1169.74c-5.19,6.86-10.42,13.68-15.53,20.59c-1.33,1.79-2.28,3.86-3.41,5.8
                c0.4,0.46,0.79,0.91,1.19,1.37c6.26-4.25,12.52-8.51,18.78-12.76l0.01,0c-5.12,6.8-10.88,13.29-10.84,22.92
                c13.22-4.86,23.39-14.14,34.56-22.21c-5.77,8.96-13.41,16.68-16.76,26.91c0.5,0.49,0.99,0.98,1.49,1.47
                c9.16-7.7,18.33-15.4,27.49-23.1l0.02-0.01c-5.42,7.72-10.9,15.4-16.22,23.18c-1.42,2.07-2.37,4.47-3.54,6.71
                c0.51,0.44,1.03,0.88,1.54,1.32c9.41-8.06,18.82-16.12,28.23-24.18c0,0-0.04-0.04-0.04-0.04c-3.6,6.15-7.28,12.26-10.76,18.48
                c-1.97,3.54-3.6,7.27-4.61,11.59c9.77-6.01,18.19-13.42,26.36-21.13c0,0,0.04,0.05,0.04,0.05c-4.9,8.68-9.92,17.3-14.64,26.08
                c-1.5,2.8-2.2,6.04-3.26,9.08c0.48,0.3,0.95,0.6,1.43,0.9c7.15-6.68,14.3-13.36,21.45-20.04l-0.02-0.02
                c-4.08,6.83-8.22,13.63-12.23,20.51c-1.47,2.52-2.65,5.22-3.26,8.48c9.92-6.54,18.56-14.46,27.47-22.04l0.04,0.04
                c-5.33,7.6-10.78,15.13-15.92,22.85c-1.84,2.77-2.93,6.04-4.36,9.08c0.49,0.43,0.99,0.86,1.48,1.28
                c10.58-7.74,21.17-15.48,31.75-23.22c0,0,0.01,0.01,0.01,0.01c-5.9,9.28-11.81,18.57-17.71,27.85c0.17,0.15,0.34,0.29,0.51,0.44
                c10.73-8.09,21.46-16.19,32.19-24.28c0,0-0.03-0.03-0.03-0.03c-5.81,7.87-11.75,15.64-17.37,23.64
                c-2.59,3.68-4.54,7.82-5.93,12.45c11.28-7.16,19.74-17.24,29.3-26.08l0.01-0.03c-5.05,7.39-10.22,14.7-15.09,22.21
                c-1.52,2.34-2.23,5.19-3.32,7.81c0.54,0.35,1.09,0.7,1.63,1.05c5.59-5.69,11.18-11.38,16.77-17.07l0.01,0.01
                c-2.01,4.94-4.01,9.88-6.02,14.82c0.57,0.33,1.13,0.65,1.7,0.98c9.4-4.89,14.89-13.81,21.32-21.76l-0.04-0.04
                c-3.81,13.33-12.32,23.42-22.52,32.27c-1.05,0.91-3.1,1.13-4.61,0.97c-27.78-2.82-55.57-5.62-83.33-8.68
                c-30.9-3.41-61.77-7.13-92.67-10.6c-19.49-2.19-39.03-4.01-58.5-6.39c-7.01-0.85-13.88-2.84-20.72-5.05
                c18.29-1.88,36.44-0.81,54.59,0.81c17.78,1.59,35.44,5.45,53.58,3.11c-17.75-6.26-36.73-8.3-54.49-17.7
                c4.97-1.1,8.83-1.95,12.68-2.8c0-0.58,0.01-1.15,0.01-1.73c-15.28-4.39-31.46-5.96-45.83-14.31c16.43-0.76,32.86-1.52,49.29-2.28
                c0.06-0.83,0.11-1.66,0.17-2.49c-3.7-0.79-7.37-1.74-11.09-2.36c-20.33-3.38-40.68-6.66-61.02-10.03
                c-2.25-0.37-4.43-1.12-6.6-2.13c19.62,0.19,39.25,1.79,58.66-1.64c0.08-0.58,0.16-1.16,0.24-1.75c-2.4-0.46-4.79-1.2-7.21-1.33
                c-14.62-0.81-29.26-1.2-43.85-2.26c-18.89-1.38-37.74-3.24-56.6-4.94c-1.41-0.13-2.78-0.66-4.17-1c0.03-0.56,0.06-1.11,0.08-1.67
                c13.73,0,27.47,0,41.2,0c0.01-0.69,0.02-1.38,0.03-2.07c-16.8-1.84-33.59-3.68-50.39-5.52c0.03-0.42,0.06-0.83,0.08-1.25
                c23.61-3.94,48-1.94,71.33-8.06c0.06-0.56,0.13-1.12,0.19-1.68c-1.38-0.51-2.73-1.32-4.16-1.48c-32.24-3.59-64.47-7.2-97.01-3.45
                c-15.06,1.74-31.24-0.69-42.91-6.01c5.46-0.79,9.72-1.73,14.02-1.97c15.61-0.86,31.24-1.35,46.85-2.22
                c13.6-0.76,27.19-1.8,40.77-2.83c1.67-0.13,3.28-0.97,4.9-2.74c-11.59-2.87-23.17-5.74-34.76-8.61c0.06-0.41,0.11-0.82,0.17-1.23
                c2.03-0.76,4.06-1.53,6.09-2.29c-0.01-0.39-0.02-0.79-0.03-1.18c-12.16-2.16-24.31-4.32-36.47-6.48c0.06-0.64,0.12-1.29,0.18-1.93
                c14.44-2.88,29.78,0.16,43.87-6.21c-15.43-6.87-33.51-4.08-49.49-14.16c41.18-9.2,81.69-7.13,122-7.18
                c-0.26,0.4-0.94,1.46-2.12,3.29c4.37,0,8.13,0,12.4,0c-0.98,2.02-1.79,3.68-3.19,6.57c3.43-0.31,5.94-0.43,8.4-0.8
                c2.43-0.37,4.82-1,7.58-1.6c-1.3,1.73-2.34,3.11-3.37,4.49c0.21,0.52,0.42,1.04,0.63,1.56c4.77-1.29,9.55-2.58,14.32-3.87l0,0.02
                c-2.04,2.48-4.07,4.95-6.11,7.43c0.39,0.58,0.78,1.16,1.17,1.74c4.64-2.06,9.28-4.12,13.92-6.18c0,0,0.01,0,0.01,0
                c-2.89,3.26-5.78,6.52-8.67,9.78c0.39,0.59,0.79,1.17,1.18,1.76c6.83-3.18,13.66-6.36,20.49-9.53l-0.03-0.03
                c-3.84,5.35-7.67,10.71-11.51,16.06c0.44,0.42,0.89,0.83,1.33,1.25c6.88-0.2,11.31-5.86,17.2-8.28c0,0-0.04-0.04-0.04-0.04
                c-2.37,9.24-9.82,9.6-17.24,9.69c-18.14,0.2-36.28,0.17-54.42,0.4c-4.5,0.06-8.99,0.72-13.48,1.1c-0.15,0.72-0.29,1.44-0.44,2.17
                c3.09,1.63,6.01,3.98,9.3,4.76c10.01,2.39,20.17,4.12,30.25,6.2c9.69,2,19.36,4.1,28.95,6.76c-13.67,6.19-28.54,1.63-42.44,5.91
                c13.44,6.76,29.09,4.13,42.67,11.25c-15.84,1.94-31.67-2.63-47.22,0.53c-0.15,0.52-0.29,1.05-0.44,1.57
                c2.09,0.91,4.1,2.29,6.28,2.63c7.87,1.23,15.77,2.37,23.7,3.08c23.83,2.12,47.68,4.04,71.53,5.99c2.07,0.17,4.17,0.02,7.32,0.02
                c-4.05-8.43-10.96-11.26-17.91-13.48c-6.93-2.21-14.18-3.44-21.46-5.14c5.5-0.26,10.9-0.52,16.31-0.78
                c0.23-0.58,0.47-1.16,0.7-1.74c-1.97-1.72-3.72-3.86-5.97-5.07c-4.23-2.28-8.9-3.75-13.11-6.05c-4.99-2.71-9.69-5.95-14.51-8.96
                c0.21-0.67,0.42-1.34,0.62-2.01c4.24,0.54,8.48,1.15,12.73,1.58c1.32,0.13,3.01,0.25,3.99-0.41c6.18-4.21,12.2-8.64,18.27-13
                l0.01-0.01c-4.32,6.85-8.64,13.71-12.95,20.56c0.57,0.51,1.15,1.01,1.72,1.52c7.6-2.9,13.64-8.43,20.2-13.08l0.02,0
                c-3.44,5.95-8.94,10.84-10.02,18.02c0.4,0.47,0.79,0.95,1.19,1.42c6.95-4.14,13.89-8.28,20.84-12.42c0,0-0.03-0.03-0.03-0.03
                c-3.47,5.24-6.94,10.48-10.42,15.72c0.49,0.52,0.97,1.04,1.46,1.56c5.99-3.42,11.98-6.84,17.97-10.26c0,0-0.02-0.02-0.02-0.02
                c-4.18,5.13-8.36,10.26-12.54,15.39c0.55,0.61,1.11,1.22,1.66,1.83c7.63-4.4,15.27-8.8,22.9-13.2c0,0-0.03-0.03-0.03-0.03
                c-4.66,6.23-9.31,12.47-13.97,18.7c0.58,0.6,1.17,1.19,1.75,1.79c8.08-5.82,16.16-11.64,24.24-17.46
                C902.75,1169.76,902.73,1169.74,902.73,1169.74z"/>
            <path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M251.34,280.39c0.73,0.66,1.88,2.04,2.14,1.89c5.1-2.99,10.02-0.66,15.03,0.23
                c5.15,0.91,10.37,1.42,15.52,2.35c3.22,0.58,6.38,1.57,9.54,2.46c6.2,1.75,12.36,3.66,18.6,5.29c2.35,0.61,5.66-0.31,7.15,1.02
                c8.21,7.34,21.42,6.81,27.38,17.81c1.35,2.49,4.97,3.7,7.39,5.69c1.09,0.89,2.22,2.13,2.59,3.42c0.55,1.93-0.26,5.26,0.76,5.87
                c3.04,1.8,6.75,2.41,10.08,3.78c2.1,0.86,4.42,1.88,5.85,3.51c1.85,2.11,1.81,4.66-1.34,6.06c-4.16,1.84-2.88,5.49-2.02,8.18
                c0.51,1.59,3.21,3.22,5.11,3.49c5.2,0.73,10.51,0.67,15.77,1.1c2.14,0.17,5.24,0.05,6.19,1.33c3.33,4.48,8.57,3.4,12.64,5.38
                c2.72,1.32,5.04,3.49,7.47,5.36c1.82,1.41,3.47,3.05,5.32,4.42c4.12,3.07,8.38,5.95,12.44,9.08c2.48,1.91,4.68,3.7,8.04,1.68
                c0.8-0.48,2.32,0.25,3.52,0.43c-0.18,1.18-0.02,2.56-0.59,3.5c-2.39,3.96-10.2,5.59-14.35,3.53c-1.41-0.7-3.5-0.8-5.04-0.36
                c-5.84,1.66-11.19,1.7-16.36-2.22c-1.35-1.03-4.39-1.27-5.86-0.45c-5.15,2.86-10.1,3.44-15.54,1.04c-1.36-0.6-3.7-0.86-4.68-0.1
                c-4.13,3.22-7.42,1.33-10.85-1.05c-5.82-4.03-7.75-3.36-8.58,3.54c-0.46,3.82-3.24,5.08-5.76,4.92c-2.53-0.16-6.25-1.73-7.1-3.7
                c-1.46-3.37-2.84-3.99-6.27-3.69c-3.14,0.28-6.4-1.13-9.62-1.25c-1.97-0.07-4.25,0.45-5.91,1.49c-4.26,2.65-6.98,1.79-10.79-1.28
                c-5.13-4.14-10.4-9.38-18.28-5.8c-1.01,0.46-2.56-0.44-3.83-0.34c-2.87,0.22-6.26-0.18-8.45,1.22c-3.26,2.08-4.45,1.61-6.01-1.59
                c-0.42-0.86-2.83-1.2-4.24-1.05c-2.93,0.31-6.26,2.32-8.59,1.44c-3.38-1.27-5.87-0.58-8.96,0.22c-3.52,0.91-7.81,2.27-9.13-4.34
                c-5,3.65-8.66,1.5-12.58-1.61c-1.67-1.33-4.72-1.37-7.11-1.26c-3.62,0.18-6.08-1.02-7.76-4.2c-0.6-1.15-1.47-2.96-2.33-3.04
                c-9.65-0.82-10.17-11.59-17.22-15.57c-4.58-2.58-3.92-5.86,0.15-11.32c-1.37-1.06-2.91-1.98-4.08-3.24
                c-0.86-0.92-2.02-2.72-1.66-3.38c0.68-1.28,2.29-2.14,3.64-2.97c0.46-0.29,1.6,0.29,1.89-0.02c3.66-3.98,7.33-7.97,10.64-12.24
                c0.32-0.42-1.69-3.85-2.72-3.91c-7.91-0.42-15.9-0.84-23.76-0.15c-2.76,0.24-5.61,3.27-7.78,5.62c-3.8,4.12-6.15,8.83-4.08,14.87
                c0.33,0.96-1.53,2.97-2.73,4.11c-4.27,4.06-8.97,4.47-14.12,1.81c-1.15-0.59-3.2-0.93-4.06-0.31c-3.18,2.29-4.52,0.35-6.56-1.73
                c-1.55-1.58-4-2.39-6.18-3.19c-3.34-1.22-6.78-2.14-10.18-3.18c-2.76-0.84-5.53-2.22-8.31-2.25c-1.29-0.01-3.91,2.93-3.66,3.97
                c0.7,2.86,1.99,6.28,4.19,7.91c3.19,2.36,7.27,3.87,11.2,4.77c5.63,1.29,5.44,6.52,8.66,10.36c-7.03,2.41-12.23,6.83-20.38,5.09
                c-6.24-1.33-13.37-0.11-19.67,3.28c-1.72,0.93-4.92,0.15-6.98-0.82c-5.37-2.54-10.18-4.06-15.84-0.11
                c-2.68,1.86-6.76,1.83-10.25,2.32c-0.76,0.11-2.12-1.05-2.52-1.93c-1.8-4-4.59-3.37-7.35-1.57c-2.16,1.41-3.63,1.26-6.2,0.39
                c-4.63-1.58-9.8-1.48-14.68-2.44c-1.95-0.38-4.95-1.33-5.35-2.66c-1.31-4.34-4.13-3.2-7.1-3.14c-2.24,0.05-4.51-0.74-6.76-1.15
                c0.49-1.94,1-3.87,1.47-5.81c0.34-1.39,1.37-3.47,0.82-4.06c-3.57-3.84-1.36-6.62,1.35-9.24c-0.75-1.86-1.41-3.49-1.97-4.87
                c5.66-3.7,10.94-7.16,16.25-10.6c0.83-0.54,1.7-1.14,2.64-1.36c6.85-1.62,13.79-2.91,20.54-4.85c2.35-0.67,4.19-2.99,6.39-4.36
                c1.98-1.23,4.53-3.52,6.14-3.03c8.73,2.64,14.15-2.55,19.89-7.37c1.36-1.14,2.79-2.74,4.38-3c6.54-1.08,10.07-6.63,15.23-9.69
                c6.47-3.83,17.25-1.02,21.22,5.47c2.53,4.15,6.57,2.59,9.95,2.11c1.28-0.18,2.2-2.84,3.32-4.35c0.7-0.94,1.43-2.62,2.18-2.64
                c4.41-0.09,8.84,0.17,13.25,0.4c2.14,0.11,4.26,0.44,6.4,0.62c2.41,0.2,5.57-0.57,7.09,0.68c3.93,3.22,6.84,1.98,9.38-1.15
                c3.1-3.82,6.73-5.56,11.66-5.26c1.82,0.11,3.69-1.07,5.59-1.42c1.49-0.27,3.38-0.76,4.51-0.12c4.18,2.39,7.08-0.23,10.4-1.9
                c1.63-0.82,4.23-1.82,5.35-1.1c3.81,2.42,7.6,3.03,11.77,1.71c5.84-1.85,9.22,3.58,14.07,4.63L251.34,280.39z"/>
            <path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M568.08,595.19c-0.25-8.18-0.49-16.36-0.74-24.54c-0.71-0.12-1.41-0.24-2.12-0.36
                c-0.95,1.55-1.91,3.11-3.5,5.71c-1.6-3.2-2.92-5.83-4.25-8.47c-0.65,0.01-1.3,0.02-1.95,0.03c-3.1,16.36-4.76,32.97-6.9,49.58
                c-0.23-2.08-0.33-4.19-0.73-6.24c-0.42-2.18-1.12-4.3-1.7-6.45c-0.55-0.05-1.1-0.1-1.65-0.15c-1.5,5.07-3.01,10.14-4.66,15.72
                c-3.05-15.19-6.04-30.07-9.17-45.66c-1.68,1.4-2.72,2.27-4,3.34c-0.6-1.07-1.13-2.05-1.69-3.01c-2.66-4.55-7.42-5.62-10.61-1.5
                c-2.62,3.38-4.03,7.85-5.33,12.03c-1.01,3.25-1.04,6.81-1.63,11.18c-3.03-7.67-5.62-14.58-8.53-21.36
                c-0.7-1.63-2.47-2.81-3.75-4.2c-1.23,1.55-2.72,2.96-3.63,4.68c-1.41,2.65-0.66,7.04-6,6.19c-0.68-0.11-2.28,2.67-2.71,4.3
                c-1.26,4.8-2.15,9.69-3.24,14.79c-1.89-6.85-3.73-13.48-5.9-21.37c-3.04,3.19-5.1,5.35-7.6,7.97c-1.25-12.71-2.49-25.4-3.76-38.38
                c3.91-0.78,7.2-1.11,10.23-2.17c1.88-0.65,3.57-2.21,4.98-3.71c1.57-1.68,2.45-4.03,4.1-5.59c4.48-4.25,3.65-10.85-1.71-13.93
                c-1.54-0.89-2.37-2.94-3.75-4.21c-1.55-1.42-3.6-2.35-4.98-3.9c-1.01-1.13-2.15-3.33-1.68-4.3c0.57-1.2,2.93-2.48,4.23-2.23
                c4.82,0.94,9.76,1.74,12.99,6.46c1.12,1.64,3.84,2.27,5.91,3.16c4.51,1.95,8.97,4.14,13.66,5.54c3.98,1.18,8.63,2.74,12.31,1.73
                c7.42-2.05,14.04-0.38,21.04,1.3c10.88,2.61,21.92,4.58,32.88,6.87c2.56,0.54,5.07,1.3,8.66,2.23c-1.06-1.61-1.54-2.33-3.06-4.63
                c10.43,1.17,19.7,2.21,28.97,3.25c0.1-0.21,0.21-0.41,0.31-0.62c-1.35-0.67-2.65-1.75-4.07-1.96c-7.02-1.01-14.07-1.73-21.1-2.69
                c-2.75-0.38-5.54-0.84-8.15-1.74c-6.33-2.18-12.82-4.16-18.77-7.15c-5.1-2.56-9.22-7.16-14.39-9.48
                c-4.24-1.91-4.32-4.37-3.92-7.93c0.69-6.07,1.75-7.38,7.79-6.81c8.37,0.79,16.87,0.99,23.86,7.23c2.52,2.25,6.64,3.16,10.18,3.74
                c9.68,1.6,16.88,7.18,24.05,13.29c2.81,2.39,6.89,3.32,10.43,4.81c3.93,1.65,7.93,3.13,11.86,4.78c3.46,1.45,6.79,4.07,10.31,4.35
                c10.57,0.83,19.25,5.55,27.99,10.95c4.19,2.59,9.49,3.39,14.27,5.02c4.56,1.56,9.05,3.32,13.67,4.66c3.46,1,7.06,1.51,10.61,2.18
                c3.71,0.7,7.43,1.44,11.17,1.97c6.15,0.88,12.34,1.44,18.46,2.47c6.31,1.06,12.96,1.13,18.3,5.53c7.26,5.99,16.37,6.85,25.04,8.88
                c1.93,0.45,4.22,0.21,5.78,1.18c5.86,3.65,12.55,3.88,18.98,4.87c6.72,1.03,12.04,6.96,11.02,13.96
                c-3.32,22.69-6.52,45.4-9.7,68.12c-0.92,6.56-1.62,13.15-2.41,19.66c-2.13-18.56-4.11-37.12-6.54-55.61
                c-0.38-2.87-2.77-7.71-4.35-7.75c-4.29-0.13-3.87,4.59-4.35,7.65c-2.04,12.8-3.84,25.63-4.94,33.08
                c-1.08-11.3-2.66-28.02-4.32-44.74c-0.2-1.97-0.6-3.99-1.36-5.81c-0.65-1.56-1.94-2.84-2.95-4.25c-1.13,1.28-2.84,2.39-3.3,3.88
                c-1.81,5.86-4,11.76-4.65,17.79c-1.84,17.19-2.98,34.45-4.41,51.68c-0.36,4.31-0.76,8.61-1.12,12.69
                c-1.02-4.12-2.11-8.49-3.2-12.87c-0.51,0-1.02,0-1.53,0c-1.45,3.04-2.9,6.09-4.31,9.03c-3.48-12.6-0.91-26.5-6.66-39.18
                c-8.59,14.05-10.68,29.55-11.22,45.99c-2.76-17.45-3.36-34.75-8.92-51.09c-0.64-0.12-1.27-0.24-1.91-0.36
                c-0.94,2.38-2.19,4.7-2.73,7.17c-0.58,2.66-0.51,5.46-0.63,7.03c-2.54-6.33-5.37-13.4-8.21-20.48c-0.76,0.08-1.52,0.15-2.28,0.23
                c-1.01,3.66-2.02,7.32-3.23,11.7c-2.23-15.42-4.37-30.23-6.51-45.04c-0.59-0.04-1.18-0.07-1.77-0.11
                c-1.63,6.31-2.91,12.74-4.99,18.9c-2.18,6.43-5.14,12.6-7.23,17.6c-2.98,0.45-5.29,0.8-7.92,1.19
                c-3.19-15.62-6.48-31.74-9.97-48.87c-3.86,3.69-6.68,6.38-9.9,9.45c0-6.75,0-13.28,0-20.97c-1.53,1.65-2.22,2.39-2.82,3.04
                c0.15-3.47,0.32-7.23,0.49-10.98c-0.48-0.05-0.95-0.09-1.43-0.14c-4.71,25.54-4.23,52.21-16.22,74.93
                c-3.9-21-1.54-43.26,0.69-65.55c-4.7,17.56-6.24,35.34-4.61,54.65c-1.27-2.69-2.04-4.34-2.9-6.16c-1.54,0.29-2.97,0.57-4.79,0.91
                c-1.14-8.63-2.24-17.09-3.39-25.55c-0.25-1.81-0.38-3.73-1.15-5.33c-0.42-0.87-2.05-1.16-3.13-1.72c0,0-0.02-0.02-0.02-0.02
                c-2.14-8.49-4.15-17.02-6.53-25.45c-0.51-1.79-2.4-3.18-3.65-4.75c-1.34,1.78-3.35,3.37-3.9,5.36c-1.72,6.22-2.89,12.6-4.29,18.91
                c-0.17,0.77-0.42,1.52-0.48,1.7c-0.93-6.7-1.72-13.86-3.01-20.93c-0.6-3.29-1.22-3.28-4.09-1.59c-2.64-2.75-5.29-5.51-8.52-8.87
                c-3.85,11.29-4.31,23.21-5.71,34.2c-1.84-1.14-3.81-2.37-5.78-3.59L568.08,595.19z M638.97,544.23l0.04-0.04
                c0.33,0.34,0.67,0.67,1,1.01c0.91,2.06,1.83,4.13,2.74,6.19c0.44-1.51,0.08-2.62-0.53-3.56c-0.62-0.95-1.48-1.74-2.24-2.6
                C639.65,544.89,639.31,544.56,638.97,544.23z M565.04,509.21c-2.72,2.36-2.5,5.07-1.92,7.83c0.65,0.06,1.3,0.13,1.95,0.19
                c0-2.66,0-5.32,0-7.98L565.04,509.21z M733.66,622.77c0.4-0.08,0.79-0.17,1.19-0.25c0.77-10.78,1.55-21.56,2.33-32.34
                c-0.38-0.05-0.76-0.1-1.14-0.16c-0.82,4.83-1.65,9.65-2.68,15.69c-0.24-1.78-0.26-2.28-0.39-2.75c-0.11-0.42-0.35-0.8-0.54-1.2
                c-0.4,0.11-0.8,0.21-1.2,0.32C732.04,608.97,732.85,615.87,733.66,622.77z M723.34,602.47c0,9.41,0,18.83,0,28.24
                c0.49-0.02,0.98-0.05,1.47-0.07C726.99,621.08,724.9,611.68,723.34,602.47z M796.3,590.78c-0.69,6.49-1.38,12.99-2.07,19.48
                C798.2,604.06,798.56,597.5,796.3,590.78z M644.24,543.29c-0.48,0.28-0.95,0.56-1.43,0.84c2.61,4.66,5.22,9.33,7.83,13.99
                c0.52-0.3,1.05-0.6,1.57-0.9C649.56,552.58,646.9,547.94,644.24,543.29z M792.22,594.37c-0.36-0.01-0.71-0.01-1.07-0.02
                c0,3.87,0,7.74,0,11.61c0.36,0.01,0.71,0.01,1.07,0.02C792.22,602.11,792.22,598.24,792.22,594.37z M526.95,551.67
                c-0.51-0.03-1.03-0.06-1.54-0.1c-0.5,3.94-1,7.88-1.5,11.82c0.62,0.06,1.23,0.12,1.85,0.18
                C526.15,559.61,526.55,555.64,526.95,551.67z M631.3,541.07c0.22-0.56,0.44-1.12,0.66-1.69c-3.91-1.53-7.81-3.05-11.72-4.58
                c-0.17,0.44-0.33,0.87-0.5,1.31C623.6,537.77,627.45,539.42,631.3,541.07z M654.9,563.88c0.56-0.14,1.12-0.28,1.67-0.43
                c-1.06-4.02-2.12-8.05-3.18-12.07c-0.4,0.11-0.81,0.21-1.21,0.32C653.08,555.77,653.99,559.82,654.9,563.88z M570.4,510.32
                c-4.06,5.11-4.51,7.92-1.82,12.66C569.22,518.51,569.76,514.77,570.4,510.32z M522.86,555.87c-0.28,0.01-0.56,0.01-0.84,0.02
                c0,1.83,0,3.65,0,5.48c0.28,0,0.56-0.01,0.84-0.01C522.86,559.52,522.86,557.7,522.86,555.87z M559.18,518.08
                c0.45-0.1,0.9-0.2,1.35-0.3c-0.43-1.81-0.86-3.62-1.3-5.44c-0.42,0.1-0.84,0.19-1.26,0.29
                C558.37,514.44,558.78,516.26,559.18,518.08z M560.51,510.63c0.19,0.17,0.38,0.34,0.57,0.51c0.1-0.11,0.29-0.31,0.28-0.32
                c-0.17-0.19-0.36-0.35-0.55-0.52C560.7,510.41,560.61,510.52,560.51,510.63z"/>
            <path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M1073.03,505.8c2.35,79-5.19,157.47-4.62,235.32c-3.15,1.78-5.6,3.17-8.06,4.55
                c-0.43,0.24-1.01,0.72-1.31,0.59c-6.07-2.59-8.57,3.79-13.03,5.19c-0.58,0.18-1.47,0.86-1.44,1.26c0.45,6.57-8.09,8.74-7.2,14.35
                c-5.27,0.69-10.11,1.48-14.98,1.84c-1.24,0.09-3.07-0.91-3.78-1.98c-1.57-2.38-3.33-2.64-6.12-2.93
                c-5.9-0.61-11.74-2.84-15.3-7.92c-2.54-3.63-4.79-5.51-9.61-5.32c-2.87,0.11-5.69-3.56-8.82-4.33c-6.75-1.67-6.63-6.25-6.9-11.8
                c-1.71-35.34,1.63-70.41,4.8-105.53c1.77-19.7,2.37-39.5,3.28-59.27c0.76-16.45,1.2-32.91,1.78-49.36
                c0.09-2.47,0.29-4.45,3.79-4.53c1.35-0.03,3.2-2.14,3.89-3.72c2.68-6.13,8.26-7.44,13.19-3.31c1.77,1.48,4.63,1.71,7.03,2.35
                c2.03,0.54,4.19,0.65,6.16,1.32c3.27,1.11,5.24,0.7,6.31-3.1c1.33-4.71,3.5-5.08,7.21-1.72c2.62,2.37,4.92,3.02,7.55-0.28
                c0.84-1.05,3.21-1.64,4.62-1.32c3.21,0.74,6.27,2.17,9.39,3.34c3.94,1.47,7.4,2.73,11.43-0.93
                C1064.5,506.56,1068.76,506.79,1073.03,505.8z"/>
            <path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M1017.73,285.45c1.71,10.12,1.95,10.59,12.8,9.74c4.63-0.37,9.13-2.35,13.7-3.53
                c1.07-0.27,2.25-0.42,3.34-0.3c7.69,0.83,9.01-0.48,7.94-8.05c-0.09-0.65-0.19-1.3-0.09-0.6c2.27-3.34,3.91-5.76,5.55-8.18
                c0.27-0.4,0.53-1.09,0.87-1.14c6.67-0.97,5.05-4.91,3.67-9.21c-0.53-1.65-0.26-4.22,0.76-5.48c0.81-1.01,3.84-1.44,4.91-0.71
                c1.28,0.88,2.12,3.26,2.14,5.01c0.12,10.82-0.29,21.65-0.07,32.46c0.92,46.76,2.32,93.51,2.87,140.27
                c0.22,19.1-1.17,38.23-2.06,57.33c-0.42,8.97-2.81,11.01-11.33,11.16c-0.94,0.02-2.33,0.2-2.72,0.81c-2.64,4.11-5.87,2.68-9.2,1.3
                c-2.15-0.89-4.36-1.65-6.55-2.44c-4.3-1.56-8.72-3.64-10.95,2.69c-0.94-0.38-1.51-0.44-1.82-0.75c-5.04-5.12-8.16-4.96-11.76,1.29
                c-1.44,2.5-2.63,2.94-5.08,1.91c-1.48-0.63-3.2-1.11-4.78-1.03c-5.6,0.27-6.49-0.46-6.54-7.39c-5.73,4.27-14.35,2-17.1,10.62
                c-0.19,0.59-1.69,1.28-2.31,1.09c-0.74-0.23-1.6-1.34-1.68-2.14c-0.25-2.47-0.32-4.99-0.15-7.48c1.83-26.88,3.79-53.75,5.56-80.63
                c0.95-14.44,1.46-28.91,2.42-43.35c1.17-17.39,0.83-34.99,6.09-51.89c4.37-14.03,9.45-27.75,19.05-39.25
                c0.53-0.64,1.06-1.28,1.65-1.86C1017.05,285.51,1017.44,285.53,1017.73,285.45z"/>
            <path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M824.51,124.97c2.34,1.13,4.67,2.3,7.04,3.38c1.29,0.59,2.63,1.32,4,1.47c4.45,0.47,6.81,3.48,6.49,8
                c-0.1,1.49,0.63,3.24,1.48,4.55c2.15,3.31,4.57,6.44,6.89,9.64c0.94,1.3,1.91,2.58,2.93,3.94c-3.86,3.14-7.66,6.43-11.7,9.39
                c-0.93,0.68-2.83,0.64-4.05,0.23c-3.83-1.28-5.69-0.13-6.08,3.9c-0.55,5.63-4.78,7.33-8.8,10.41c-5.97,4.58-10.1,0.06-15.01,0.06
                c-3.69,0-7.68,3.05-10.98,5.49c-4.33,3.2-8.91,3.08-13.79,1.39c-3.6-1.24-8.13,0.24-12.25,0.49c-1.5,0.09-3.47,0.78-4.44,0.08
                c-8.92-6.42-16.53-2.7-23.94,2.8c-5.27,3.91-14.26,0.9-15.17-5.61c-0.74-5.31-3.53-6.16-8.13-6.46
                c-8.48-0.56-16.93-1.72-25.35-2.91c-0.97-0.14-2.09-1.94-2.46-3.18c-1.36-4.52-4.07-6.55-8.88-6.07
                c-8.84,0.88-16.46-1.64-23.09-7.87c-2-1.88-5.75-1.74-8.44-3.06c-6.57-3.2-13.01-6.68-19.38-10.26c-0.21-0.12,0.46-2.94,1.38-3.75
                c4.65-4.07,4.39-4.05-0.41-7.92c-3.57-2.88-6.52-6.72-9.13-10.55c-2.35-3.43-5.48-4.87-9.28-3.05c-5.65,2.7-13.32-0.25-15.59-6.11
                c-1.36-3.51,0.73-5.7,3.65-6.45c5.07-1.3,5.62-5.59,7.45-9.32c1.78-3.62,3.74-7.92,6.89-9.88c3.43-2.13,4.91-4.68,3.63-7.47
                c-1-2.17-4.24-3.62-6.78-4.69c-4.36-1.85-8.99-3.04-13.41-4.77c-1.05-0.41-2.59-2.2-2.38-2.69c0.52-1.2,1.87-2.73,3-2.83
                c3.29-0.29,6.63,0.09,9.95,0.15c1.41,0.03,2.82-0.14,4.24-0.13c4.1,0.02,8.2,0.05,12.3,0.15c0.4,0.01,1.05,0.47,1.16,0.85
                c1.66,5.82,6.14,4.21,10.22,4.08c6.12-0.19,12.49-1.36,16.67,5.42c0.92,1.49,4.86,2.49,6.66,1.79c5.1-1.98,8.83,0.28,12.3,3.01
                c2.75,2.16,5.24,2.41,8.29,1.26c4.18-1.57,8-1.51,11.46,2.04c1.04,1.07,3.24,1.52,4.87,1.43c5.26-0.29,10.48-1.08,15.74-1.38
                c1.28-0.07,2.83,0.76,3.9,1.62c4.12,3.29,8.03,6.85,12.17,10.11c0.89,0.7,2.6,1.08,3.65,0.74c5.7-1.86,9.35,1.54,13.47,4.46
                c2.01,1.42,5.07,2.6,7.33,2.17c5.01-0.95,9.77-3.11,14.71-4.52c1.47-0.42,3.44-0.49,4.74,0.16c2.71,1.35,4.78,3.05,7.69-0.16
                c1.65-1.82,5.47,0.75,6.06,3.75c1.35,6.83,3.2,7.84,9.96,5.45c6.98-2.47,11.39-0.17,14.03,6.68c0.87,2.26,2.82,4.27,4.7,5.92
                c2.28,2.01,5,3.51,7.53,5.23C824.32,125.36,824.42,125.17,824.51,124.97z"/>
            <path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M882.86,1124.94c-0.91-9.2-2.09-18.37-2.68-27.59c-2.4-37.37-6.46-74.53-12.63-111.49
                c-1.8-10.77-1.47-21.89-2.41-32.83c-1.71-19.88-3.37-39.77-5.53-59.6c-3.71-34.07-7.54-68.1-7.6-102.46
                c-0.02-8.39,0.29-15.68,9.92-18.75c5.15-1.64,6.04-5.72,4.62-10.5c-0.92-3.09-0.08-5.2,3.07-6.25c0.47-0.16,1.13-0.28,1.33-0.63
                c3.52-6.34,10.63-6.16,16.19-8.06c3.46-1.18,8.22,1.31,12.35,2.36c1.01,0.25,1.93,2.02,2.68,1.89c7.17-1.27,10.06,4.02,13.53,8.49
                c0.4,0.52,0.89,1.23,1.43,1.33c6.93,1.33,6.21,6.7,6.32,11.7c0.63,27.28,1.34,54.57,1.94,81.85
                c0.84,37.98-3.85,75.07-13.48,112.03c-8.28,31.77-12.75,64.56-18.3,97c-3.34,19.49-5.53,39.18-8.26,58.77
                c-0.13,0.93-0.54,1.82-0.81,2.73C883.97,1124.94,883.42,1124.94,882.86,1124.94z"/>
            <path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M324.29,1148.75c1.78,5.68,4.55,11.26,5.13,17.06c1.24,12.33,1.45,24.77-2.02,36.94
                c-0.35,1.23-0.09,2.64-0.12,3.97l0.04-0.04c-0.63,1.17-1.85,2.37-1.8,3.51c0.22,4.97,0.82,9.91,1.33,15.46
                c-0.89-1.13-1.49-1.89-2.1-2.66c-0.39,0.04-0.79,0.08-1.18,0.11c0,13.89,0,27.79,0,42.92c-1.26-2.67-1.93-4.07-3.17-6.71
                c-1.83,3.31-3.1,5.62-4.1,7.43c-0.48-1.37-1.12-3.18-1.75-5c-0.56,0.05-1.11,0.1-1.67,0.16c-1.2,2.82-2.39,5.63-3.59,8.45
                c-0.15-0.04-0.31-0.09-0.46-0.13c0.12-1.43,0.65-2.99,0.26-4.25c-0.39-1.25-1.69-3.11-2.63-3.13c-4.23-0.09-4.28-2.33-3.95-5.64
                c1.12-11.39,2.22-22.79,2.82-34.22c0.25-4.83-0.78-9.72-1.23-14.58c-0.71,0.04-1.42,0.09-2.13,0.13
                c0.18,3.93,0.35,7.86,0.54,11.79c1.06,22.43-2.27,44.07-11.74,64.58c-0.62,1.34-1.62,2.5-2.44,3.74c0,0,0.03,0.02,0.03,0.02
                c0.56-1.38,1.15-2.76,1.67-4.16c0.1-0.27-0.03-0.63-0.05-0.8c-6.5,7.6-13.06,15.27-19.62,22.94c0,0-0.01-0.01-0.01-0.01
                c0-1.33,0-2.67,0-4l0.01,0.01c2.19-5.38,4.39-10.76,6.58-16.15c-0.27-0.07-0.55-0.14-0.82-0.21c-5.25,8.45-10.5,16.89-15.75,25.34
                c0,0-0.01,0.02-0.01,0.02c1.12-3.32,2.23-6.64,3.35-9.96c-0.5-0.25-1-0.5-1.5-0.75c-4.66,7.7-5.98,17.75-14.98,22.9
                c2.22-5.46,4.31-10.58,6.39-15.69c-0.54-0.31-1.08-0.61-1.62-0.92c-4.88,9.21-8.1,19.77-18.62,24.4l-0.01-0.02
                c2.23-4.65,4.46-9.29,6.69-13.94c-0.53-0.31-1.07-0.61-1.6-0.92c-5.7,7.09-6.4,18.8-18.57,21.12c4.3-10.79,8.28-20.77,12.22-30.68
                c0.65,1.2,1.36,2.52,2.29,4.25c1.43-5.06,2.69-9.52,3.95-13.99c0.27,0.04,0.53,0.07,0.8,0.11c0,3.13-0.03,6.26,0.01,9.38
                c0.03,2.44,0.8,3.67,3.23,1.74l-0.05-0.04c0,1.45,0,2.9,0,5.65c5.76-4.38,6.3-9.83,7.57-14.91c4.06,1.9,5.58,1.14,5.66-3.05
                c0.07-3.49,0.01-6.99,0.01-10.54c2.24,2.97,4.46,5.91,7.4,9.82c0-3,0-4.91,0-8.05c1.47,3.02,2.4,4.94,3.34,6.87
                c0.48-0.12,0.96-0.25,1.43-0.37c-0.35-3.5-0.7-7-1.06-10.6c0.9,0.08,1.77,0.16,2.64,0.24l-0.02-0.02c0.46,1.86,0.91,3.72,1.62,6.6
                c5.2-6.67,2.58-14.01,4.99-20.2c2.2,0.72,4.52,1.48,8.48,2.78c-1.09-8.88-1.82-16.55-3.02-24.14c-1.47-9.29-3.78-18.47-4.8-27.8
                c-0.37-3.35,1.72-7.1,3.14-10.47c1.18-2.79,3.02-5.3,4.57-7.93c-0.49-0.26-0.98-0.53-1.46-0.79c-0.83,1.34-1.67,2.68-2.5,4.02
                l-0.01-0.01c-0.87,0.11-2.18-0.1-2.52,0.38c-1.11,1.61-1.88,3.46-3.25,6.15c0.36-2.73,0.59-4.46,0.87-6.54
                c-3.36,3.2-4.15,7.81-2.98,13.35c2.38,11.2,4.59,22.43,6.88,33.64c0,0,0.02-0.01,0.02-0.01c-0.79,0.49-2.27,0.98-2.26,1.45
                c0.03,2.26,0.46,4.52,0.87,7.74c-1.25-3.4-2.17-5.94-3.1-8.48c-0.51,0.14-1.01,0.27-1.52,0.41c0.33,1.96,0.67,3.92,1,5.88
                c0,0,0.04-0.03,0.04-0.03c-0.83,0.49-2.35,0.95-2.37,1.47c-0.18,4.1-0.07,8.22-0.06,12.33c0.01,2.12,0,4.24,0,5.52
                c-1.01-3.63-2.24-8.02-3.46-12.4c-0.59,0-1.18,0.01-1.77,0.01c-1.18,5.14-2.35,10.28-3.52,15.38c-0.36-0.52-1.01-1.46-2.15-3.12
                c-0.62,1.93-1.02,3.18-1.49,4.64c-1.27-2.81-2.48-5.46-3.72-8.19c-7.18,8.31-9.06,18.18-9.73,28.48
                c-0.55-5.07-1.11-10.14-1.66-15.21c-0.56-0.03-1.11-0.06-1.67-0.09c-0.37,2.2-0.75,4.4-1.21,7.11c-0.85-2.68-1.58-4.94-2.8-8.78
                c-2.63,4.71-4.53,8.12-6.38,11.43c-0.28-0.61-0.86-1.89-1.61-3.53c-6.72,3.94-2,10.34-3.79,14.08c-0.56-2.87-1.29-6.66-2.03-10.44
                c-0.53-0.04-1.07-0.07-1.6-0.11c-0.56,2.17-1.13,4.34-1.59,6.11c-0.44-2.78-0.36-6.62-5.67-5.96c-3.4-16.91-4-33.47,1-50.03
                c-0.4-0.11-0.8-0.22-1.19-0.33c-0.53,0.79-1.29,1.52-1.56,2.39c-5.92,18.99-3.53,37.86,1.16,56.62c1.22,4.87,3.35,9.51,5.36,15.09
                c1.08-3.26,2.14-6.46,3.05-9.18c-1.62,15.75-4.69,26.87-16.09,24.73c2.26-8.16,4.52-16.32,6.78-24.48
                c-0.59-0.21-1.18-0.41-1.76-0.62c-0.69,1.68-1.42,3.35-2.06,5.06c-2.66,7.16-4.86,14.52-8.07,21.42
                c-2.76,5.94-5.15,6.19-10.54,2.58c-1.77,2.56-3.35,5.31-5.42,7.61c-0.92,1.02-2.93,1.7-4.25,1.46c-0.8-0.15-1.85-2.45-1.69-3.64
                c1.28-9.54,2.61-19.07,4.34-28.54c1.39-7.63,3.35-15.16,5.06-22.74c-0.44-0.13-0.87-0.26-1.31-0.39
                c-0.54,1.77-1.19,3.51-1.62,5.31c-2.18,9.06-4.13,18.18-6.57,27.17c-0.75,2.76-2.81,5.16-4.27,7.73c0,0,0.03,0.03,0.03,0.03
                c5.1-19.81,9.38-39.88,15.48-59.38c10.81-34.54,27.65-65.94,52.6-92.55c3.16-3.37,6.72-6.52,10.55-9.1
                c7.64-5.15,15.57-5.16,23.35,0.04c2.77,1.85,5.49,3.79,8.39,5.41c4.53,2.52,9.34,4.12,14.07,0.63c4.8-3.54,6.61-8.63,6.28-14.47
                c-0.49-8.73-5.6-14.46-12.15-19.66c-6.71-5.32-13.51-10.81-18.99-17.31c-6.48-7.69-8.62-16.77-2.7-26.29
                c1.56-2.51,2.74-5.41-1.98-6.12c0,0-0.04-0.04-0.04-0.04c12.18-10.2,25.47-18.21,41.58-20.24c10.46-1.32,20.34,0.12,28.22,7.96
                c1.32,1.31,2.91,4.44,2.37,5.12c-1.34,1.67-3.79,2.54-5.91,3.46c-1.13,0.49-2.5,0.4-3.93,0.6c2.65,4.08,5.62,4.88,11.1,1.5
                c3.63-2.24,5.84-1.18,8.97,0.45c18.43,9.61,36.98,19.01,55.49,28.46c2.32,1.18,4.67,2.3,6.79,3.99
                c-31.23-10.3-61.92-22.2-94.33-28.43c28.87,9.7,57.74,19.41,86.61,29.11l-0.02,0.01c-22.8-5.96-45.71-11.49-68.19-17.72
                c0.69,0.83,1.87,2.25,4.02,4.86c-4.98-0.7-8.99-0.86-12.77-1.87c-7.7-2.04-15.24-4.69-22.94-6.76c-2.29-0.62-5-0.66-7.29-0.07
                c-3.73,0.96-6.55,3.42-6.98,7.56c-0.44,4.19,2.02,6.84,5.36,8.83c0.85,0.51,1.79,1.04,2.74,1.16
                c14.23,1.74,18.03,13.04,22.14,24.07c-2.01-3.2-3.79-6.58-6.09-9.56c-2.52-3.28-5.52-6.19-8.31-9.26
                c-0.45,0.39-0.9,0.78-1.35,1.17c4.11,5.6,8.15,11.25,12.4,16.73C321.52,1148.62,323.16,1148.49,324.29,1148.75z M293.35,1090.78
                c0.14,0.59,0.28,1.18,0.42,1.76c0.87-0.72,1.73-1.43,3.05-2.53c-1.14-0.23-1.33-0.33-1.44-0.27c-0.7,0.35-1.39,0.74-2.08,1.11
                c-4.46-2.42-5.18,1.49-7.2,3.18c0.35,0.53,0.69,1.07,1.04,1.6C289.2,1094.01,291.27,1092.4,293.35,1090.78z M302.1,1087.64
                c-1.48,0.63-2.96,1.26-4.44,1.89c0.77,1.04,1.55,2.09,2.32,3.13c4.44-1.62,8.87-3.23,13.31-4.85c0.01,0.67,0.03,1.34,0.04,2.01
                l-0.36,0.17c0,0,0.33-0.2,0.33-0.2c3.33-1,6.67-2,10-3l-0.03,0.02c1.28,0.6,2.55,1.2,3.83,1.8c-2.88,0.43-7.2-0.58-6.05,4.54
                c0.41,1.82,2.59,3.87,4.42,4.52c1.28,0.46,3.35-1.28,5.06-2.04c-1-2.23-2.01-4.47-3.01-6.7c2.65,0.47,5.3,0.94,8.25,1.47
                c1.94-3.5,1.44-4.36-4.21-5.81c-0.52,0-1.05,0-1.57,0c-1.77-1-2.67-4.85-5.71-1.77l0,0c-1.66,0-3.62-0.57-4.9,0.12
                c-2.25,1.22-4.08,3.21-6.08,4.87c-1-0.33-1.99-0.65-2.99-0.98c-0.34,0-0.69,0-1.03,0c-0.66-0.01-1.33-0.02-1.99-0.03l0.03,0.03
                c-1.27,0.28-2.55,0.56-3.83,0.84C303.03,1087.65,302.56,1087.64,302.1,1087.64z M279.49,1190.59c3.45,0,6.91,0,10.53,0
                c-2.83-3.07-6.01-4.98-10.33-3c-4.55,2.09-7.15,7.94-5.72,14.01c1-2.46,1.69-4.17,2.39-5.88c0,1.64,0,3.29,0,4.93
                c0.36,0.05,0.73,0.11,1.09,0.16c0.49-0.82,0.99-1.64,1.4-2.31c0.35,0.59,0.74,1.25,1.13,1.91c0.56-0.17,1.12-0.34,1.67-0.51
                C280.93,1196.79,280.21,1193.69,279.49,1190.59z M289.31,1202.73c1.94,3.43,3.89,6.85,6.14,10.82c1.58-5.08-0.23-11.69-3.32-13.63
                c-0.31-0.2-1.19,0.51-1.8,0.79l-0.02,0.02c-4.76-0.79-5.7,1.06-3.29,9.1c0.77-2.38,1.54-4.75,2.31-7.13L289.31,1202.73z
                M316.06,1227.66c-0.71,0.02-1.42,0.04-2.13,0.06c2.42,9.97-4.4,20.03,2.15,30.82c2.92-7.82,4.56-15.06,3.99-22.66
                c-0.41-0.08-0.82-0.15-1.22-0.23c-0.53,3.73-1.06,7.47-1.59,11.2c-0.4-0.03-0.8-0.06-1.19-0.09
                C316.06,1240.4,316.06,1234.03,316.06,1227.66z M249.53,1270.92c0-17.33,0-35.11,0-52.9
                C244.26,1230.42,245.32,1264.76,249.53,1270.92z M199.23,1290.91c-0.46-0.05-0.91-0.1-1.37-0.16
                c-2.54,13.96-6.57,27.76-3.85,42.32C195.76,1319.03,197.5,1304.97,199.23,1290.91z M241.65,1225.83
                c-2.76,9.74,0.05,38.77,4.17,43.49C244.44,1254.86,243.07,1240.61,241.65,1225.83z M240.13,1269.61c0.47-0.05,0.95-0.11,1.42-0.16
                c-1.25-10.7-2.49-21.39-3.74-32.09C235.92,1248.41,237.43,1259.08,240.13,1269.61z M234.91,1255.99c-0.55,0.06-1.1,0.12-1.65,0.18
                c1.61,8.4,3.23,16.8,4.84,25.2c0.66-0.13,1.32-0.26,1.97-0.38C238.35,1272.65,236.63,1264.32,234.91,1255.99z M353.32,1099.07
                c-0.24,0.66-0.49,1.33-0.73,1.99c5.8,2.56,11.61,5.11,17.41,7.67c0.28-0.64,0.56-1.29,0.83-1.93
                C365.7,1102.66,359.64,1100.57,353.32,1099.07z M226.67,1285.11c0.76-0.03,1.53-0.05,2.29-0.08c-0.49-8.51-0.98-17.02-1.48-25.53
                c-0.27,0.03-0.54,0.05-0.82,0.08C226.67,1268.09,226.67,1276.6,226.67,1285.11z M294.68,1195.02c1.16,6.6,2.09,11.85,3.12,17.72
                C301.88,1208.11,300.66,1201.27,294.68,1195.02z M232.09,1281.43c0.7-0.1,1.4-0.2,2.09-0.3c-1.15-6.28-2.29-12.55-3.44-18.83
                c-0.48,0.09-0.97,0.18-1.45,0.27C230.23,1268.86,231.16,1275.14,232.09,1281.43z"/>
            <path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M363.53,647.18c-0.61,0.7-0.97,1.39-1.23,1.36c-6.22-0.79-12.62,1.81-18.79-1.38
                c-2.14-1.11-5.06-1.07-7.59-0.94c-3.19,0.17-11.7-2.32-18.63-6.41c0.94,2.61,1.57,4.29,2.16,5.97c0.96,2.74,2.39,5.43,2.75,8.25
                c0.48,3.8-2.65,5.74-8.57,5.8c-7.26,0.08-14.52,0.04-21.78,0.18c-4.4,0.08-10.56,5.63-11.9,9.82c-0.42,1.32-2.26,2.68-3.71,3.09
                c-6.09,1.72-12.2,2-18.23-0.68c-0.8-0.36-2.21,0.41-3.25,0.87c-6.56,2.91-13.18,1.35-19.77,0.24c-0.82-0.14-1.4-1.45-2.25-1.77
                c-2.08-0.76-4.31-1.9-6.38-1.72c-3.34,0.29-6.2,0.74-9.36-1.47c-3.03-2.12-6.81-3.26-10.4-4.4c-1.18-0.38-3.25,0.12-4.1,1.01
                c-2.31,2.4-4.63,2.61-7.03,0.69c-2.74-2.2-8.48-2.12-6.51-8.17c0.22-0.68-0.02-2.08-0.47-2.31c-5.28-2.67-6.33-10.77-13.87-10.67
                c-1.36,0.02-2.64-1.94-4.14-2.56c-1.31-0.54-3.07-1.01-4.28-0.56c-4.48,1.66-7.91-0.2-11.19-2.79c-0.86-0.69-1.56-2.6-2.11-2.51
                c-5.58,0.89-5.1-5.01-8.04-7.01c-0.41-0.28-0.56-1.17-0.58-1.79c-0.07-2.54,7.25-12.76,9.72-12.88c1.86-0.09,4.3,0.64,5.58,1.91
                c2.5,2.49,4.98,3.58,8.47,2.79c1.34-0.3,3.37-0.29,4.24,0.5c5.02,4.62,9.06,1.06,13.24-1.28c2.04-1.14,3.43-2.07,6.13-0.28
                c1.97,1.31,6.08,1.2,8.29-0.01c5.57-3.06,10.75-6.92,15.77-10.87c1.54-1.21,2.04-3.75,3.47-6.59c4.55,1.56,10.54-3.69,16.62,0.07
                c0.13,0.08,0.41,0.12,0.48,0.05c6.39-6.72,15.21,1.2,21.93-3.61c0.64-0.46,1.92-0.42,2.19-0.96c2.66-5.35,6.56-4.2,10.94-2.59
                c4.01,1.47,8.24,2.34,12.38,3.43c0.79,0.21,1.89,0.63,2.4,0.29c4.05-2.71,7.69-0.24,11.34,0.87c2.81,0.85,4.82,0.76,7.21-1.37
                c3.74-3.34,8.97-3.37,12.64-1.03c6.24,3.98,10.9-0.24,16.19-1.45c1.21-0.28,2.48-1.14,3.57-0.92c4.09,0.81,8.06,2.29,12.17,2.84
                c2.01,0.27,4.22-0.96,6.35-1.46c1.7-0.4,3.61-1.42,5.09-0.99c10.49,3.05,21.18,0.7,31.74,1.43c0.98,0.07,2.36-0.49,2.89-0.02
                c6.22,5.57,14.3,4.59,21.56,6.46c1.72,0.44,3.92,1.84,4.5,3.34c1.33,3.47,3.82,4.23,6.96,4.59c7.74,0.89,14.76,3.29,19.99,9.56
                c0.9,1.08,2.38,2.05,3.74,2.3c4.34,0.83,6.77,3.17,9.27,6.99c2.55,3.9,4.76,6.98,2.84,11.65c-0.27,0.67-0.23,1.88,0.21,2.36
                c3.47,3.85,7.1,7.54,10.6,11.36c1.4,1.53,2.81,3.14,3.73,4.97c0.29,0.58-0.83,2.69-1.57,2.85c-9.48,2.05-18.78,4.49-28.73,0.97
                c-4.5-1.59-10-0.32-15.04-0.49c-1.04-0.04-2.75-0.47-2.98-1.13c-1.7-4.76-4.24-5.98-9.49-4.77c-3.66,0.85-8-0.89-11.96-1.8
                c-7.31-1.69-14.6-3.49-21.81-5.56c-1.26-0.36-2.34-2.15-3.04-3.52c-1.22-2.36-2.35-3.2-5.42-2.65c-2.79,0.5-5.97-0.94-8.92-1.72
                c-2.21-0.58-4.3-1.91-6.51-2.1c-1.62-0.14-3.36,1.04-5.04,1.63c0.89,1.57,1.66,3.22,2.72,4.67
                C361.5,645.98,362.57,646.46,363.53,647.18z"/>
            <path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M1053.62,944.92c0.66-1.16,1.29-2.33,1.98-3.47c1.61-2.65,1.21-4.37-1.75-5.96
                c-3.72-2-7.46-4.34-7.35-8.16c-5.21-4.05-9.7-7.54-14.97-11.64c-1.27,6.04,5.91,13.89-4.49,17.2c1.7,0.65,3.39,1.3,5.55,2.12
                c-3.53,2.45-2.05,4.75,1.01,7.21c-1.78,0.99-3.21,1.79-4.63,2.58c0.25,0.64,0.5,1.28,0.74,1.92c3.1,0.31,6.2,0.63,10.7,1.09
                c-2.36,3.62-4.37,6.69-6.47,9.9c3,4.38,7,1.7,10.7,1.21l-0.02-0.02c-1.26,2.9-5.57,5.48-0.98,9.02l-0.02-0.03
                c-2.69,4.17-2.69,4.17,3.35,5.96c-1.53,6.94-1.53,6.94-7.51,3.07c-13.24-8.54-27.73-14.21-42.79-18.54
                c-1.61-0.46-3.39-0.34-5.09-0.49c-0.27,0.47-0.54,0.93-0.8,1.4c2.63,2.53,5.27,5.07,7.9,7.6l-0.01,0.01
                c-3.29-1.07-6.92-1.6-9.76-3.39c-1.88-1.19-3.58-4.11-3.73-6.36c-0.96-14.43-1.25-28.91-2.18-43.35
                c-1.66-25.55-3.68-51.08-5.43-76.63c-1.26-18.42-2.42-36.84-3.47-55.27c-0.56-9.8-0.85-19.61-1.1-29.42
                c-0.04-1.49,0.83-3.01,1.29-4.51c1.32,0.47,2.65,0.93,3.97,1.41c4.12,1.5,6.97,6.54,12.51,4.32c0.65-0.26,2.38,1.12,3.02,2.11
                c4.74,7.33,11.75,10.29,20.09,11.18c1.23,0.13,2.52,1.81,3.37,3.05c1.56,2.25,3.06,2.55,5.87,1.88c3.77-0.9,7.97-1.11,11.78-0.43
                c3.71,0.65,4.66-1.09,5.85-3.7c0.82-1.79,2.1-3.38,3.21-5.04c0.54-0.82,1.72-1.65,1.64-2.37c-0.54-4.9,2.79-6.68,6.12-9.07
                c2.16-1.55,3.49-2.99,6.45-1.99c1.19,0.4,3.04-1.19,4.59-1.86c1.25-0.54,2.9-1.83,3.66-1.43c0.98,0.52,1.42,2.34,1.76,3.69
                c0.28,1.1,0.09,2.32,0.08,3.48c-0.34,32.78-0.44,65.57-1.1,98.35c-0.62,30.92-1.84,61.83-2.78,92.01
                c-3.83,0.48-7.31,0.91-10.78,1.35L1053.62,944.92z"/>
            <path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M110.49,1080.8c-15.69-0.36-31.45-2.46-47,0.77c-0.02,0.61-0.05,1.22-0.07,1.83
                c4.31,0.53,8.73,0.61,12.91,1.66c22.98,5.76,46.47,5.3,69.87,6.16c19.58,0.72,39.14,2.57,58.72,2.58
                c8.9,0.01,17.85-3.04,26.68-5.11c1.72-0.4,2.99-2.71,4.46-4.15c-0.34-0.37-0.68-0.73-1.02-1.1c16.37,0,32.73,0,49.1,0
                c0.03,0.46,0.07,0.92,0.1,1.37c-10.74,2.07-21.88-0.12-32.68,1.25c-0.01,0.66-0.02,1.31-0.03,1.97c7.9,0.48,15.81,0.97,25.39,1.56
                c-18.6,13.98-35.94,27.62-50.82,43.9c0.4,0.49,0.81,0.98,1.21,1.46c3.03-2.05,6.05-4.1,9.08-6.15c0,0-0.03-0.03-0.03-0.03
                c-2.67,3.9-5.34,7.81-8.01,11.71c0.27,0.39,0.54,0.77,0.81,1.15c3.07-2.29,6.15-4.59,9.22-6.88c0,0,0.05,0.04,0.05,0.04
                c1.7,1.14,3.27,2.96,5.12,3.3c13.2,2.49,26.47,4.66,39.71,7.01c2.67,0.47,5.28,1.25,7.79,2.64c-58.2-3.6-116.31-6.97-174.43-10.43
                c-0.16,0.86-0.32,1.71-0.49,2.57c6.97,2.05,13.94,4.1,20.91,6.14c-0.01,0.26-0.02,0.53-0.03,0.79c-4.9,0.52-9.78,1.37-14.69,1.51
                c-17.3,0.5-34.62,0.44-51.9,1.22c-10.76,0.48-21.45,2.43-32.2,3.09c-10.61,0.65-21.27,0.52-31.91,0.73
                c-2.84,0.05-4.33-1.24-4.33-4.2c0.04-30.13,0.06-60.27,0.11-90.4c0-0.45,0.34-0.91,0.72-1.86c1.97,0.2,4.01,0.4,6.02,0.61
                c-0.26,6.21,1.52,9.07,7.88,10.45c11.04,2.39,22.16,4.6,33.35,6.05c18.46,2.38,37,4.09,55.5,6.1c1.64,0.18,3.27,0.49,4.9,0.74
                C110.45,1080.84,110.49,1080.8,110.49,1080.8z"/>
            <path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M854.01,1114.99c-1.91-0.51-3.01-0.74-4.06-1.1c-10.18-3.5-20.37-6.94-30.48-10.63
                c-1.17-0.43-2.71-2.25-2.64-3.33c1.28-18.41,2.41-36.84,4.49-55.16c0.58-5.15,4.11-9.96,6.29-14.92c0.79-1.8,2.29-3.57,2.31-5.37
                c0.2-25.62,0.21-51.24,0.24-76.86c0-0.77-0.34-1.54-1.7-2.56c-0.52,1.83-1.23,3.62-1.52,5.48c-3.48,22.17-6.83,44.37-10.41,66.53
                c-0.52,3.22-2.53,6.2-3.04,9.42c-1.44,9.17-2.59,18.39-3.63,27.61c-1.57,13.87-2.88,27.78-4.55,41.63
                c-0.13,1.09-2.28,2.77-3.45,2.74c-4.77-0.12-9.53-0.8-14.86-1.34c0.3-2.26,0.43-4.19,0.82-6.07c3.9-18.9,8.48-37.69,11.59-56.71
                c2.7-16.55,3.44-33.41,5.6-50.07c3.05-23.41,6.3-46.81,10.05-70.12c2.89-17.94,5.3-35.87,5.03-54.09
                c-0.23-15.67,1.09-31.14,3.48-46.7c2-13.06,1.1-26.57,1.55-39.88c1.35-40.08,2.73-80.16,4.12-120.24
                c0.36-10.45,1.13-20.91,1.05-31.36c-0.06-7.74-4.4-13.27-11.7-15.8c-5.6-1.94-6-5.38-3.74-11.06c3.38,1.69,6.66,3.35,9.97,4.98
                c7.35,3.62,8.24,3.09,8.34-4.89c0.02-1.66,0.02-3.34,0.17-4.99c0.71-7.88,1.46-15.75,2.83-23.76c1.37,4.16,2.74,8.31,4.71,14.28
                c1.26-2.11,1.82-3.04,2.47-4.12c0.96,2.21,2.06,4.36,5.17,0.05c1.09,7.92,2.3,14.29,2.8,20.71c1.44,18.6,0.37,37.16-0.99,55.75
                c-0.92,12.6-0.71,25.28-1,37.93c-0.03,1.48,0,2.96,0,5.17c-4.67-3.48-5.69-0.13-6.32,2.86c-0.79,3.72-1.51,7.57-1.42,11.35
                c0.83,36.45,1.76,72.89,2.83,109.33c0.49,16.8,1.27,33.59,2.1,50.37c0.43,8.63,1.38,17.24,1.87,25.87
                c0.11,1.87-0.2,4.21-1.26,5.64c-5.77,7.75-6.54,16.78-6.1,25.73c0.73,14.77,2.95,29.48,3.55,44.25
                c0.86,21.1,0.83,42.24,1.15,63.36c0.07,4.33-0.05,8.66,0.05,12.99c0.15,6.48,0.89,12.69,3.43,18.93
                c4.37,10.72,6.02,22.07,4.89,33.77C853.64,1105.19,854.01,1109.85,854.01,1114.99z"/>
            <path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M786.65,427.41c1-10.63,2-21.27,2.99-31.9c0.07-0.02,0.14-0.03,0.2-0.05c0,13.61,0,27.22,0,40.82
                c0.66,0.07,1.32,0.14,1.99,0.21c1.19-6.7,2.37-13.41,3.44-19.41c0.64,9.9,1.33,20.55,2.02,31.19c0.64-0.02,1.27-0.04,1.91-0.06
                c4.36-24.76-0.64-50.16,3.59-74.93c0.67-0.07,1.35-0.14,2.02-0.22c1.48,7.6,2.96,15.2,4.44,22.81c0.37,0.11,0.74,0.22,1.12,0.32
                c0.96-1.72,1.93-3.43,2.82-5.02c0.96,0.05,2.06,0.12,3.21,0.18c0.44,2.21,0.98,4.86,1.51,7.51c0.6,0.06,1.19,0.12,1.79,0.18
                c1.1-4.79,2.2-9.58,3.44-15c4.99,2.05,8.07,5.86,10.65,10.05c5.59,9.09,7.73,18.71,6.05,29.58c-2.62,16.91-4.2,33.98-6.35,50.97
                c-0.39,3.09-1.36,6.1-2.3,10.23c-2.95-1.84-5.37-3.11-7.51-4.73c-13.52-10.27-27.01-20.58-40.46-30.94
                c-11.85-9.13-24.94-15.74-39.01-20.85c-21.73-7.89-43.21-16.5-64.76-24.89c-1.27-0.49-2.24-1.77-3.31-2.72
                c-0.23-0.2-0.22-0.71-0.46-0.87c-6.37-4.09-9.4-10.72-13.15-16.87c-0.94-1.53-3.68-2.78-5.58-2.75c-4.53,0.07-6.21-2.61-8.19-5.96
                c-11.23-18.98-15.8-40.13-20.47-61.27c-0.1-0.45,0.05-0.96,0.09-1.67c5.93,0.92,11.78,7.27,18.53-0.41
                c2.61,1.79,5.73,3.55,8.4,5.84c3.31,2.83,6.25,4.58,10.6,1.7c2.95-1.95,6.17-2.47,7.79,2.32c1.17,3.47,3.95,4.71,7.78,5.02
                c2.82,0.23,5.47,2.34,8.23,3.55c2.46,1.08,4.93,2.11,7.43,3.08c2.1,0.81,4.69,0.96,6.29,2.32c3.01,2.56,5.67,3.98,9.89,2.75
                c2.11-0.62,5.57,0.17,7.2,1.66c8.06,7.38,17.84,10.05,28.21,11.43c3.62,0.48,7.27,0.78,10.92,1.1c4.18,0.36,5.69,2,5.61,6.73
                c-0.25,16.13,0.24,32.27,0.48,48.41c0.01,0.43,0.39,0.85,1.17,2.44c2.94-13.56,5.65-26.1,8.37-38.65
                c0.64,0.02,1.28,0.04,1.92,0.05c0.45,1.58,1.24,3.15,1.29,4.75c0.43,11.81,0.68,23.62,1.07,35.43c0.1,3.13-0.76,6.27,2.73,8.88
                c1.7,1.28,1.54,5.04,2.22,7.68C785.21,427.43,785.93,427.42,786.65,427.41z"/>
            <path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M79.72,516.35c4.01,1.08,7.99,1.29,10.99,3.12c6.86,4.18,13.06,9.41,19.88,13.66
                c3.05,1.9,6.95,2.39,10.42,3.65c1.66,0.6,3.77,1.06,4.73,2.31c4,5.21,10.84,4.8,15.34,7.88c6.32,4.32,12.2,0.91,18.08,2.07
                c1.69,0.33,3.29,1.1,4.92,1.72c1.22,0.46,2.4,1.36,3.64,1.44c8.6,0.53,8.6,0.47,11.09,9.45c-7.06,2.12-14.13,4.31-21.25,6.33
                c-0.95,0.27-2.16-0.34-3.24-0.59c-0.8-0.19-2.13-0.84-2.28-0.64c-3.32,4.81-8.93,0.68-12.86,3.31c-0.96,0.64-2.77-0.04-4.19,0
                c-3,0.09-6.1,0.82-8.97,0.28c-6.18-1.16-11.19,2.1-16.56,3.8c-2.95,0.93-6.6,2.47-8.01,4.87c-2.44,4.17-4.59,5.38-8.88,1.89
                c-4.76,7.17-12.33,9.15-19.84,11.04c-2.06,0.52-4.43-0.36-6.65-0.27c-1.79,0.07-3.55,0.63-5.33,0.98c0.74,1.52,1.2,3.27,2.26,4.52
                c3.01,3.55,7.11,4.48,11.62,4.51c3.73,0.03,7.46,0.64,11.19,0.61c2.05-0.01,4.08-1.01,6.15-1.19c2.12-0.19,5.27-0.8,6.26,0.3
                c5.48,6.1,13.73,5.95,20.36,10.32c-0.34,0.79-0.88,2.03-1.59,3.66c1.06,0.74,2.11,1.45,3.14,2.18c2.18,1.56,3.34,2.37,1.12,5.75
                c-2.83,4.31-5.87,5.65-10.6,6.13c-2.34,0.24-4.41,2.96-6.63,4.51c-0.73,0.51-1.59,1.32-2.34,1.27c-4.04-0.3-5.54,2.74-5.95,5.49
                c-0.43,2.84-0.32,7.35,1.4,8.63c4.57,3.39,5.53,6.82,3.54,11.8c-0.59,1.46-0.44,3.21-0.46,3.36c-3.98,1.32-7.13,2.39-10.29,3.41
                c-0.89,0.29-1.85,0.75-2.71,0.63c-2.2-0.3-4.41-0.73-6.53-1.38c-3.86-1.19-8.76,6.55-11.78-1.39c-4.86-0.51-9.73-1.01-14.58-1.56
                c-0.63-0.07-1.47-0.15-1.83-0.57c-2.8-3.28-6.28-3.14-10.04-2.37c-1.42,0.29-3.56,0.68-4.32-0.06c-5.9-5.76-12.35-1.15-18.51-1.46
                c-3.46-0.18-6.82-2-10.25-3.01c-1.07-0.32-2.31-0.73-3.32-0.49c-3.5,0.85-4.07-1.15-4.08-3.83c-0.02-4.83-0.02-9.66-0.02-14.49
                c0-25.64-0.01-51.29,0-76.93c0-7.43,4.41-9.43,11.45-7.91c6.81,1.47,14.17,0.4,21.29,0.4c0.78,0,2.11-0.66,2.25-0.43
                c2.78,4.47,6.34,1.49,9.64,1.17c1.28-0.13,2.8-0.22,3.88,0.32c4.69,2.33,8.9,0.53,13.16-1.17c0.31-0.12,0.64-0.44,0.9-0.39
                c4.84,0.98,9.82,2.5,11.76-4.47c0.09-0.33,3.31-0.53,4.19,0.23c3.83,3.33,7.81,1.02,11.55,0.42c1.93-0.31,3.55-2.58,5.31-3.96
                c-1.14-1.39-2.03-3.19-3.48-4.08c-3.12-1.91-6.61-3.19-9.75-5.08c-0.75-0.45-1.31-2.52-0.92-3.36c1.65-3.5,3.06-6.5-1.07-9.55
                C80.8,522.18,80.75,519.49,79.72,516.35z"/>
            <path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M251.34,530.23c6.84-3.36,13.48-8.8,20.58-9.5c7.71-0.76,9.4-7.59,15.08-9.9
                c5.91-2.41,11.37-6.01,16.77-9.48c2.13-1.37,3.91-3.61,5.17-5.84c0.58-1.04,0.03-3.51-0.9-4.36c-0.79-0.72-3.27-0.59-4.28,0.15
                c-2.89,2.1-4.93,1.73-8.02-0.08c-2.44-1.43-6.09-0.85-9.2-1.07c-2.19-0.16-4.43,0.05-6.58-0.29c-2.81-0.44-5.46-2.77-8.44-0.16
                c-0.45,0.39-2.52-0.1-2.9-0.74c-2.43-4.09-4.76-1.87-7.43-0.34c-1.9,1.09-4.11,1.73-6.26,2.28c-2.47,0.65-5.01,1.21-7.55,1.37
                c-1.64,0.1-3.34-0.58-6.55-1.21c2.97-2.71,4.75-4.51,6.72-6.09c3.92-3.16,3.72-4.55-1.37-6.77c2.71-4.04,0.97-5.88-2.83-6.23
                c-1.55-3.85-2.98-7.4-4.44-11.02c5.43-0.46,9.71-0.77,13.98-1.2c6.66-0.68,12.55,1.17,18.55,4.11c6.85,3.37,13.9,6.59,22.24,4.16
                c3.06-0.89,6.84,0.58,10.27,1.11c3.34,0.52,6.67,1.16,9.99,1.86c0.92,0.19,1.77,0.74,2.62,1.17c1.33,0.67,2.59,1.9,3.95,2
                c5.56,0.41,11.14,0.39,16.71,0.72c2.07,0.13,4.11,0.8,6.16,1.23c2.06,0.44,4.14,0.84,6.19,1.34c3.44,0.83,6.98,1.45,10.23,2.78
                c0.81,0.33,1.35,3.39,0.78,4.61c-1.68,3.55-0.3,5.2,2.95,5.62c2.9,0.38,6-0.41,8.79,0.24c6.21,1.46,11.3-0.08,16.94-2.96
                c4.77-2.43,10.5-2.44,15.15-6.37c1.88-1.59,6.82,0.64,10.37,0.73c1.8,0.04,3.95-0.36,5.36-1.38c3.91-2.81,11.39-2.02,12.76,2.39
                c2.28,7.33,8.98,7.71,14.24,9.99c2.7,1.17,4.58,1.61,4.96,4.95c0.61,5.36,5.76,4.44,8.97,5.04c7.21,1.35,7.27,0.91,5.12,8.27
                c-0.26,0.9-0.48,2.55-0.06,2.81c4.74,2.99,5.8,9.49,11.37,11.67c4.86,1.9,5.56,5.51,2,9.39c-0.9,0.98-1.97,1.89-2.55,3.05
                c-3.58,7.13-9.43,7.22-16.12,5.71c-1.66-0.37-4.58,0.02-5.31,1.12c-4.08,6.18-9.12,3.69-13.9,1.81c-2.55-1-4.03-1.31-5.81,1.27
                c-1.73,2.5-10.51,2.52-14.6,0.56c-2.24-1.08-4.49-2.16-6.82-3.05c-1.02-0.39-2.75-0.84-3.27-0.34c-3.73,3.57-7.89,0.78-11.68,0.72
                c-8.41-0.13-16.82-1.22-25.2-2.13c-4.25-0.46-8.42-1.6-12.67-2.04c-3.02-0.31-6.17-0.31-9.16,0.2c-4.07,0.68-7.73,1.64-12.05-0.22
                c-2.93-1.26-7.32-1.01-10.35,0.26c-5.36,2.25-9.4-1.9-14.25-1.63c-4.94,0.28-9.92-0.27-14.88-0.22
                c-6.23,0.07-12.45,0.37-18.67,0.6c-6.75,0.25-13.49,0.54-20.23,0.8c-1.11,0.04-2.22,0.01-2.66,0.01c1.07-1.58,3.35-3.53,3.02-4.8
                c-0.43-1.61-2.85-2.87-4.63-3.91c-1.98-1.16-4.2-1.9-6.32-2.82C251.38,530.2,251.34,530.23,251.34,530.23z"/>
            <path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M2.32,203.01c4.13-0.82,7.74-1.66,11.39-2.23c1.74-0.27,3.66-0.39,5.34,0.04c3.75,0.95,7.04,0.91,10.3-1.7
                c0.77-0.61,3.97,0.23,4.53,1.25c1.57,2.86,3.39,3.12,6.45,3.11c3.45-0.01,6.88,1.73,10.35,2.55c6.04,1.43,12.09,2.46,17.4,6.35
                c1.77,1.3,5.36,0.72,7.94,0.16c3.83-0.83,7.12-1.45,10.77,1.2c2.16,1.57,5.54,1.57,8.41,2.01c3.5,0.54,7.04,0.91,10.58,1.16
                c6.02,0.43,12.08,0.5,18.08,1.14c4.53,0.48,9.03,1.45,13.44,2.59c1.14,0.3,2.16,1.93,2.79,3.18c5.47,10.99,10.53,22.2,16.37,32.99
                c3.19,5.88,4.06,11.84,3.83,18.24c-0.11,2.84-1.23,4.27-4.34,3.95c-4.95-0.5-8.38,1.34-9.79,6.52c-0.26,0.97-2.13,1.88-3.38,2.09
                c-1.07,0.18-3.3-0.61-3.33-1.08c-0.48-6.99-7.1-5.98-10.84-7.46c-6.57-2.61-14.63-2.14-19.43,3.15
                c-4.65,5.12-10.99,6.76-15.96,10.96c-4.85,4.1-9.95,9.27-17.98,6.35c-1.74-0.63-4.77,2.19-7.16,3.5
                c-2.46,1.35-4.81,2.93-7.33,4.15c-2.49,1.2-5.12,2.11-7.71,3.09c-1.21,0.45-3.23,1.43-3.59,1.02c-3.45-3.87-6.37,0.11-9.37,0.11
                c-6.05,0-12.34-1.48-18.08-3.57c-6.51-2.38-12.53-6.1-19.68-9.7C2.32,267.16,2.32,234.67,2.32,203.01z"/>
            <path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M702.94,662.43c0.85-6.04,1.7-12.07,2.55-18.11c5.38,8.91,7.92,18.41,7.18,28.72
                c-0.07,0.97,0.15,1.99,0.39,2.94c0.1,0.41,0.58,0.73,1.5,1.8c4.59-9.25,2.38-19.1,5.33-28.24c1.51,8.93,3.35,17.81,4.46,26.79
                c1.41,11.37,2.2,22.82,3.34,34.22c0.48,4.79-1.53,7.01-6.3,6.23c-10.32-1.67-20.62-3.52-30.94-5.23
                c-4.08-0.68-8.17-1.51-12.29-1.76c-27.22-1.66-54.44-3.17-81.66-4.75c-6.31-0.37-12.61-0.93-18.92-1.21
                c-3.72-0.16-7.63-0.83-10.4,2.99c-0.57,0.79-2.46,0.86-3.75,0.86c-26.12,0.05-52.25,0.03-77.03,0.03
                c4.13-0.63,9.73-1.43,15.31-2.35c2.94-0.49,5.92-0.94,8.73-1.88c2.63-0.87,5.08-2.16,2.59-5.82c-0.48-0.71,0.49-3.13,1.47-4.01
                c5.96-5.36,13-5.09,20.2-3.33c1.24,0.3,2.41,0.86,3.7,1.33c0.85-11.34,7.87-14.12,17.3-8.62c4.35-1.22,7.58-1.9,10.64-3.04
                c2.78-1.03,5.92-1.49,5.38-6.23c-0.51-4.55,1.69-8.27,6.74-9.81c1.5-0.46,3.39-2.34,3.62-3.8c1.52-9.69,1.39-9.71,11.14-10.17
                c-0.42-1.65-0.79-3.18-1.19-4.71c-3.91-15,7.47-27.07,22.52-23.65c1.29,0.29,2.71,2.19,3.15,3.62c0.73,2.34,0.48,4.98,1.08,7.38
                c0.37,1.46,1.36,3.66,2.44,3.89c8.36,1.83,8.89,8.55,9.26,14.94c0.29,4.93-0.64,9.93-0.96,14.91c-0.07,1.03,0.16,2.08,0.26,3.13
                c0.49,0.09,0.99,0.19,1.48,0.28c1.3-4.64,2.67-9.26,3.88-13.92c1.12-4.34,2.04-8.74,3.12-13.09c0.32-1.28,0.54-2.99,1.44-3.64
                c5.93-4.28,4.53-10.92,5.55-16.71c1.53-8.63,2.75-17.32,4.11-25.98c0.57,0.01,1.14,0.02,1.72,0.04c0,6.69,0,13.38,0,20.86
                c4.06-6.19,7.38-11.23,11.12-16.92c3.25,16.15,6.35,31.6,9.5,47.25c9.93-4.43,4.91,4.2,7.43,7.36
                c4.98-13.21,9.7-25.74,14.74-39.09c2.32,15.95,4.53,31.16,6.74,46.37C701.4,662.34,702.17,662.39,702.94,662.43z"/>
            <path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M279.78,1497.66c2.87-2.05,4.62-3.49,6.54-4.64c12.47-7.48,25.79-12.6,39.76-16.93
                c15.6-4.84,30.51-12.15,45.3-19.24c8.83-4.24,17.61-6.75,27.35-6.06c8.83,0.63,17.3,2.74,24.12,8.44
                c19.72,16.49,42.75,20.46,67.12,16.99c20.8-2.95,41.38-2.67,62.16-0.83c7.7,0.68,15.58-0.37,23.35-0.88
                c2.02-0.13,3.97-1.2,6.14-3.1c-3.36-1.63-6.71-3.25-10.07-4.88c0.03-0.57,0.06-1.14,0.09-1.71c17.59-2.97,35.18-5.94,52.77-8.91
                c0-0.6,0.01-1.21,0.01-1.81c-1.78-0.55-3.53-1.25-5.35-1.62c-21.3-4.33-42.06-3.14-61.84,6.59c-7.25,3.57-14.57,4.23-22.68,3.6
                c-12.74-1-25.64,0.17-38.42-0.56c-11.74-0.66-23.48-2.15-35.07-4.16c-8.27-1.43-15.55-5.59-22.27-13.04
                c3.63,0.16,6.21-0.06,8.65,0.44c14.85,3.01,29.64,2.72,44.67,0.96c7.22-0.84,14.93,0.26,22.1,1.88
                c5.68,1.29,10.93,1.69,16.65,0.72c11.76-1.98,23.91-2.26,35.4-5.2c27.37-7.02,54.51-3.14,81.76-0.18
                c1.32,0.14,2.65,0.23,3.98,0.34c1.25,0.1,2.51,0.18,3.46,0.25c-7.44,6.41-14.67,13-22.31,19.06c-4,3.17-8.59,5.69-13.18,8
                c-13.57,6.83-26.66,14.31-38.31,24.2c-1.94,1.65-5.06,2.67-7.64,2.67c-96.37,0.13-192.75,0.12-289.12,0.1
                C283.64,1498.13,282.39,1497.91,279.78,1497.66z"/>
            <path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M362.25,1032.85c21.67,1.6,42.41,6.33,61.05,18.15c1.23,0.78,2.46,1.56,3.69,2.34
                c0.02,0.49,0.05,0.99,0.07,1.48c-3.06,0.56-6.1,1.43-9.18,1.62c-12.6,0.78-25.22,1.29-37.83,2.06c-2.28,0.14-4.76,0.62-6.73,1.7
                c-10.15,5.58-21.2,6.33-32.41,6.58c-9.64,0.22-19.27,0.75-28.91,0.79c-2,0.01-4.27-1.19-5.95-2.44
                c-4.8-3.58-9.37-7.48-14.03-11.25c-0.41,0.53-0.83,1.06-1.24,1.59c2.73,2.18,5.58,4.23,8.15,6.59c2.68,2.45,5.33,5.01,7.53,7.88
                c2.08,2.71,1.15,4.26-2.25,5.8c-15.92,7.22-32.62,4.56-49.13,4.58c-8.62,0.01-17.25,0.26-25.87,0.38
                c-27.08,0.39-54.27,2.39-81.23,0.74c-34.11-2.09-68.06-7.02-102.04-11.02c-9.39-1.11-18.7-3.04-27.95-5.03
                c-6.3-1.35-6.76-2.68-4.26-9.15c-1.99-0.59-3.9-1.33-5.87-1.71c-2.81-0.55-5.81-0.56-5.9-4.6c-0.1-4.37,3.07-4.48,6.09-4.88
                c5.77-0.77,11.51-1.96,17.3-2.28c14.44-0.78,26.4-8.71,39.59-13.08c7.52-2.49,15.22-4.42,22.94-6.64
                c2.06,19.03-11.17,24.89-25.14,30.14c0.14,0.5,0.27,1,0.41,1.49c1.71,0.41,3.59,1.51,5.09,1.12c8.31-2.18,17.43-3.19,24.57-7.46
                c15.52-9.26,31.48-14.85,49.66-13.28c1.64,0.14,3.32-0.14,4.98-0.22c0,0-0.02-0.02-0.02-0.02c-5.84,2.57-11.66,5.2-17.54,7.69
                c-6.52,2.76-13.27,5.02-19.58,8.19c-5.09,2.57-9.66,6.17-15.7,10.12c3.7,0.93,6.26,1.95,8.87,2.11c2.42,0.15,5.05-0.25,7.34-1.08
                c16.6-6.02,33.12-12.28,49.73-18.28c3.53-1.28,7.28-2.1,11-2.66c4.24-0.64,8.57-0.75,12.85-1.09l0.01,0.01
                c-10.69,8.62-20.79,18.31-35.97,18.23c-3.27-0.02-6.55,1.05-9.71,2.34c11.17,1.66,22.23,2.63,33.16-0.72
                c2.26-0.69,4.61-2.1,6.61,0.77c0.31,0.44,1.88,0.16,2.82-0.04c9.89-2.12,19.85-4.01,29.63-6.58c5.16-1.35,10-3.92,14.43-5.71
                c-1.79,3.02-4.08,6.87-6.37,10.72c0.3,0.4,0.6,0.8,0.9,1.19c1.62-0.32,3.38-0.34,4.84-1.01c5.44-2.48,10.87-5.02,16.16-7.81
                c7.24-3.83,14.21-2.92,19.93,2.26c8.23,7.45,18.62,9.89,28.21,14.22c0.4-0.36,0.8-0.72,1.19-1.08
                c-5.85-7.32-11.7-14.65-18.06-22.6c12.85-1.53,24.38-0.99,35.51,4.66c7.37,3.75,15.18,6.67,22.89,9.71
                c1.8,0.71,4.03,0.36,6.63,0.54c-6.3-6.18-11.96-11.74-17.63-17.29c0.41-0.85,0.83-1.71,1.24-2.56c4.44,0.26,8.91,0.29,13.32,0.84
                c13.01,1.62,25.98,3.49,38.99,5.14c1.49,0.19,3.07-0.34,4.61-0.54c0.23-0.63,0.47-1.26,0.7-1.89c-5.39-3.26-10.78-6.52-16.17-9.79
                L362.25,1032.85z"/>
            <path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M480.56,841.05c5.74-7.07,12.11-10.8,21.13-8.16c4.11,1.2,11.35-2.54,13.24-6.48
                c1.92-4.01,4.19-6.37,8.89-3.73c2.43,1.36,3.99,0.59,4.54-2.39c1.44-7.83,8.41-11.26,15.88-7.9c1.61,0.72,3.16,1.56,5.66,2.8
                c-1.9-10.19,3.71-16.32,9.85-21.61c7.67-6.61,15.19-2.88,22.54,2.68c0.39-1.65,0.85-2.68,0.83-3.71c-0.07-4.72,1.23-7.79,6.9-7.25
                c1,0.09,2.99-1.9,3.2-3.15c1.56-9.42,2.01-9.68,9.46-4.11c0.35,0.26,0.88,0.3,1.76,0.58c0.29-3.28,0.44-6.36,0.85-9.41
                c1.01-7.56,6.82-12.72,14.4-12.42c1.44,0.06,3,1.67,4.16,2.9c1.17,1.24,1.9,2.9,3.64,5.69c0.82-2.91,1.52-4.36,1.6-5.85
                c0.38-7.18,4.6-11.44,10.54-14.59c11.37-6.04,23.59-5.04,35.67-4.05c5.71,0.47,11.39,2.21,16.89,3.97
                c6.38,2.05,11.33,6.19,14.54,12.23c0.52,0.98,1.34,1.79,2.28,3.02c0.86-0.95,1.67-1.54,2.06-2.33c2.04-4.15,4.86-3.55,8.3-1.84
                c1.78,0.89,4.07,0.72,6.01,1.36c1.28,0.42,2.28,1.71,3.56,2.08c1.37,0.4,3.59,0.83,4.3,0.11c4.78-4.83,6.34-4.71,9.39,1.33
                c0.58,1.15,1.21,2.28,2.46,4.63c0.96-2.57,1.6-4.09,2.11-5.65c2.31-7.09,7.96-10.06,14.84-7.29c3.01,1.21,5.92,3.12,8.31,5.32
                c2.59,2.38,4.67,5.51,2.19,9.27c-2.84,4.31-6.89,4.48-11.64,3.56c-4.96-0.96-10.32,0.2-15.5,0.39c-2.02,0.07-4.24,0.57-6.04-0.06
                c-4.18-1.46-6.91-0.59-7.94,3.83c-1.04,4.44-3.62,5.59-7.76,4.26c-4.86-1.57-8.31-0.98-9.56,5.05c-0.3,1.45-3.71,3.14-5.69,3.14
                c-3.62,0-6.26,0.31-6.93,4.5c-0.14,0.87-0.87,2.17-1.54,2.33c-7.81,1.83-13.36,9.3-22.14,8.79c-1.03-0.06-2.87,1.04-3.11,1.94
                c-2.44,9.07-10.06,8.99-17.08,9.88c-6.3,0.8-12.69,1.05-18.91,2.24c-2.2,0.42-4.66,2.49-5.85,4.5c-2.21,3.72-4.49,3.97-6.89,0.89
                c-2.2-2.81-4.57-3.03-7.44-1.81c-2.13,0.9-4.1,2.19-6.26,2.99c-4.07,1.51-7.35,5.12-12.83,2.56c-2.64-1.23-7.21,0.14-10.25,1.66
                c-4.12,2.07-7.5,5.58-11.42,8.11c-1.83,1.18-4.23,1.45-6.32,2.28c-2.17,0.85-4.72,1.41-6.3,2.93c-2.78,2.66-5.42,3.49-9.36,2.99
                c-10.86-1.36-21.8-2.52-32.73-2.9C509.11,840.69,495.17,841.05,480.56,841.05z M662.84,778.29c-0.56-10.84,3.45-17.55,14.06-16.74
                c8.92,0.68,14.89,6.15,17.84,14.84c0.39-8.5-6.34-15.51-16.13-17.4c-8.15-1.58-14.94,2.99-18.78,12.62
                c-5.85-0.21-5.56,0.13-11.66,5.46c-2.12,1.85-4.7,4.09-9.08,3.04c-13.32-3.18-21.11,3.64-20.1,16c-2.96,2.57-5.69,4.94-7.09,6.15
                c-4.86,0.38-8.45,0.42-11.94,1.03c-2.07,0.36-3.9,2.04-5.97,2.4c-3.15,0.55-6.4,0.57-9.62,0.64c-6.21,0.13-10.4,5.22-8.42,9.08
                c2.15-1.93,4.2-5.18,6.81-5.73c2.98-0.63,6.46,1.16,9.86,1.92c1.56-2.87,1.56-2.87,7.52-1.4c1.16-6.89,2.87-8.31,11.91-1.73
                c4.35-9.77,8.62-13.14,22.22-3.58c-2.44-2.72-5.1-5.31-8.23-7.07c-2.85-1.61-5.61-3.38-4.68-6.7c0.76-2.7,2.64-6.04,4.96-7.15
                c6.09-2.93,12.5-2.98,18.05,1.82c1.2-1.63,2.21-4.18,3.23-4.18c3.14,0,3.35-1.67,3.79-4.01c0.67-3.56,3.48-4.64,6.77-2.79
                C659.41,775.49,660.47,776.5,662.84,778.29z"/>
            <path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M346.84,3.55c-3.91,1.59-7.97,2.89-11.67,4.87c-3.38,1.81-6.35,4.36-9.51,6.58
                c-0.81,0.57-1.74,1.73-2.43,1.61c-8.97-1.67-12.35,5.83-17.42,10.49c-2.9,2.66-5.3,6.17-10.1,4.41c-0.38-0.14-1.19,0.06-1.38,0.37
                c-4.89,7.9-14.96,5.21-21.53,10.53c-2.9,2.35-9.36,0.41-14.18,0.14c-0.68-0.04-1.25-1.71-1.91-2.6c-0.85-1.15-1.52-2.57-2.63-3.34
                c-4.22-2.94-8.57-5.7-12.93-8.45c-1.52-0.96-3.13-1.87-4.82-2.44c-3.37-1.13-7.14-1.45-8.81-5.43c-0.26-0.62-1.37-1.31-2.05-1.26
                c-8.26,0.51-16.11-4.11-24.77-0.95c-6.03,2.2-12.82,2.31-19.27,3.39c-1.6,0.27-3.15,0.9-4.75,1.01c-2.05,0.14-4.53,0.65-6.11-0.26
                c-5.03-2.87-10.1-2.39-15.47-1.67c-6.65,0.88-13.39,1.15-20.08,1.79c-1.77,0.17-3.53,0.62-5.24,1.12
                c-2.98,0.87-5.92,1.89-8.88,2.83c-0.78,0.25-1.97,0.18-2.31,0.69c-4.33,6.52-11.06,4.55-17.13,5.14
                c-1.91,0.19-3.77,1.41-5.55,2.35c-1.73,0.92-3.25,2.87-4.99,3.05c-8.43,0.83-13.11,7.56-19.18,11.95
                c-2.21,1.6-2.73,5.38-4.69,7.56c-1.23,1.35-3.79,2.31-5.64,2.14c-6.1-0.56-7.86,1.16-7.2,7.33c0.19,1.77-0.8,3.66-1.25,5.5
                c-1.43-1.18-2.85-2.38-4.29-3.55c-0.62-0.51-1.23-1.07-1.93-1.45c-4.5-2.38-8.95-4.88-13.57-7.02c-3.23-1.5-6.61-3.02-10.08-3.61
                c-4.99-0.84-10.12-0.92-15.2-1.18c-3.96-0.21-6.06-1.74-6.02-6.11C2.04,35.25,2.07,21.43,1.87,7.61C1.8,3.17,3.5,1.89,7.75,1.9
                c111.7,0.08,223.4,0.06,335.1,0.08c1.26,0,2.52,0.24,3.78,0.36C346.7,2.74,346.77,3.15,346.84,3.55z"/>
            <path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M694.95,896.93c-5.15-0.22-9.93-2.2-15.46-1.66c-3.68,0.36-7.95,0.47-11.73,0.09
                c-6.26-0.62-10.09,2.64-13.95,6.07c-2.86,2.55-5.04,2.24-7.2-0.25c-3.51-4.02-6.96-3.85-10.73-0.46
                c-0.84,0.75-2.02,1.55-3.05,1.57c-7.26,0.15-12.71,4.13-18.32,8.03c-4.4,3.06-9.77,4.99-13.54,8.63
                c-3.19,3.08-4.72,7.88-7.86,11.57c2.92-6.33,5.94-12.62,8.71-19.01c0.74-1.69,1.61-4.15,0.93-5.49c-2.35-4.61,0.26-6.52,3.83-6.89
                c5.22-0.55,8.17-4.02,11.1-7.42c2.84-3.3,5.83-3.84,9.76-2.61c4.97,1.54,9.65,1.72,13.4-3.12c0.64-0.83,3.53-0.64,4.97,0.01
                c3.53,1.59,6.82,3.71,11.44,6.32c-2.97-8.71,2.42-13.83,4.49-20.01c1.57-4.69,1.37-10.09,1.34-15.17
                c-0.02-3.62,0.55-6.19,4.46-7.13c0.91-0.22,2.2-0.9,2.41-1.62c1.52-5.4,6.87-9.11,6.49-15.46c-0.11-1.91,1.55-4.06,2.74-5.89
                c2.57-3.94,5.28-7.79,8.08-11.56c1.98-2.66,4.16-5.18,6.32-7.7c0.52-0.61,1.73-0.79,1.99-1.43c1.93-4.84,5.58-8.47,8.35-12.69
                c1.54-2.34,2.69-3.41,5.24-3.29c4.2,0.2,6.76-1.62,8.28-5.7c0.5-1.35,3.13-2.85,4.64-2.71c8.45,0.79,8.36,0.91,12.33-6.26
                c0.53-0.97,2.68-2.11,3.37-1.76c5.94,3.02,11.53-1.24,17.3-0.58c1.59,0.18,3.36-0.41,4.81,0.07c6.7,2.19,13.28,0.56,15.57-5.92
                c2.4-6.77,7.34-7.57,12.73-7.99c4.37-0.34,8.59-0.08,11.79-3.98c0.59-0.73,2.77-0.54,4.08-0.22c3.19,0.78,5.12-0.09,6.01-3.37
                c0.51-1.88,9.75-6.66,11.49-5.83c0.88,0.42,1.74,2.02,1.7,3.04c-0.5,11.46-0.92,22.95-2.03,34.36c-0.21,2.2-3.08,4.39-5.12,6.12
                c-4.57,3.89-9.39,7.48-14.46,11.46c-4-2.35-7.07-1.88-10.8,2.73c-2.23,2.76-7.91,3.12-12.17,3.73c-3.71,0.53-6.5,1.26-8.42,4.98
                c-1.2,2.33-3.87,3.91-5.87,5.83c-8-1.71-7.96-1.68-12.87,5.82c-0.86,1.32-2.07,2.78-3.45,3.33c-7.97,3.17-7.86,12.01-13.56,16.71
                c0.34,0.25,0.69,0.5,1.03,0.74c4.6-0.87,9.21-1.75,13.81-2.6c0.32-0.06,0.83,0.14,0.97-0.02c5.61-6.57,13.2,0.13,19.33-2.88
                c0.14-0.07,0.39-0.11,0.48-0.04c4.61,3.79,10.61,0.41,15.47,3c0.99,0.53,2.38,1.19,3.27,0.89c6.57-2.23,10.67,3.52,16.09,4.97
                c1.97,0.53,3.33,3.33,5.86,6.05c-4.11,1.48-7.05,2.95-10.15,3.54c-4.72,0.9-9.57,1.12-14.34,1.77c-0.89,0.12-1.8,0.79-2.5,1.43
                c-2.51,2.25-4.94,4.6-7.8,7.28c-1.78-2.1-3.17-3.67-4.5-5.3c-4.29-5.28-9.57-5.52-15.9-4.49c-6.13,1-12.74,3.87-19.17,0.12
                c-1.26-0.73-3.7-1.21-4.57-0.52c-3.89,3.08-7.77,1.49-11.88,1.06c-2.83-0.3-5.82,1.02-8.75,1.54c-2.97,0.53-6.04,1.66-8.9,1.29
                c-4.34-0.56-7.54,0.11-9.6,4.21c-0.43,0.87-1.23,2.28-1.79,2.24c-6.55-0.38-9.75,4.8-13.79,8.34c-3.23,2.84-5.81,6.42-8.33,10.17
                c10.58-0.63,18.09,5.26,26.06,9.92L694.95,896.93z"/>
            <path onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M256.34,676.1c-4.61,6.43-8.38,13.77-14.03,19.11c-10.92,10.31-16.56,22.01-17.18,37.21
                c-1.37,33.48-1.07,66.86,1.66,100.25c1.65,20.22,2.09,40.56,4.51,60.68c2.43,20.27,6.33,40.38,10.31,60.41
                c0.83,4.16,4.19,8.39,7.49,11.36c6.03,5.42,10.3,11.7,10.77,19.61c1.12,18.81,15.24,26.48,28.8,34.62
                c2.26,1.35,5.44,1.35,8.23,1.6c9.6,0.86,19.48,0.39,28.75,2.58c13.09,3.09,25.72,8.11,38.55,12.3c-0.21,0.74-0.42,1.48-0.63,2.22
                c-3.65-0.75-7.34-1.33-10.94-2.3c-9.28-2.5-18.26-6.19-28.23-5.46c-2.81,0.2-5.74-1.48-8.65-2.13
                c-7.07-1.59-14.15-3.11-21.24-4.63c-5.88-1.26-11.74-2.52-16.02-7.29c-1.84-2.05-3.63-4.12-6.15-5.51
                c2.12,5.55,5.44,10.35,11.02,12.3c18.98,6.63,34.95,17.44,47.88,32.81l0.02-0.02c-9.89-1.79-18.14-7.06-26.5-12.25
                c-8.99-5.59-18.23-10.78-27.34-16.18c-0.98-0.58-2.03-1.24-2.71-2.12c-4.93-6.46-9.79-12.98-14.65-19.5
                c-0.59-0.8-1.36-1.65-1.5-2.56c-1.6-10.52-10.18-12.63-19.33-15.65c2.26,10.63,3.56,20.72,11.39,28.81
                c5.12,5.3,10.27,11.02,13.61,17.49c5.47,10.61,12.02,20.25,20.05,28.97l0.01-0.01c-4.03-1.56-8.15-2.93-12.07-4.73
                c-5.4-2.48-9.45-6.24-12.4-11.72c-4.56-8.48-10.02-16.48-15.13-24.66c-0.31-0.49-0.94-0.78-2.54-2.05
                c0.36,2.97,0.38,4.83,0.83,6.57c0.83,3.21,1.96,6.35,2.89,9.54c1.89,6.53,0.7,9.58-5.3,12.64c-5.47,2.79-11.07,5.3-16.62,7.93
                c-0.1-0.22-0.38-0.64-0.31-0.71c5.93-6.15,5.37-12,0.55-18.9c-3.69-5.28-5.71-11.72-9.51-17.77c0,3.73-0.16,7.47,0.05,11.19
                c0.16,2.89,2.15,6.88,0.91,8.42c-3.34,4.14-7.6,7.9-12.21,10.55c-5.11,2.94-10.96,4.69-16.62,6.55c-2.12,0.7-4.61,0.21-6.92,0.34
                c-1.95,0.11-3.92,0.18-5.82,0.56c-4.67,0.93-9.31,2.03-13.69,3c7.13-4.62,14.84-8.74,21.44-14.21c4.01-3.32,7.44-8.32,9.12-13.26
                c3.57-10.47,6.97-20.81,14.81-29.02c0.67-0.7,1.1-1.66,1.54-2.56c0.2-0.4,0.13-0.92,0.36-2.91c-6.47,7.56-12.44,13.77-15.19,22.29
                c-0.92,2.85-1.74,5.73-2.66,8.58c-2.49,7.65-5.3,10.15-13.44,9.59c-7.72-0.53-14.21,2.03-20.99,5.05
                c-6.44,2.87-13.49,4.35-20.2,6.65c-5.33,1.83-10.52,4.03-15.82,5.94c-5.67,2.04-11.39,3.92-17.4,5.01
                c8.7-7.77,17.79-14.6,29.72-15.57c9.01-0.74,17.11-3.49,24.56-8.76c6.31-4.47,14.06-6.88,18.97-13.36
                c0.29-0.38,0.76-0.73,1.21-0.85c9.98-2.64,16.6-9.87,23.24-17.05c1.21-1.31,2.12-2.91,3.19-4.35
                c7.57-10.12,9.39-22.03,10.94-34.12c0.32-2.47,2.37-4.72,3.62-7.07c0.38-0.71,0.98-1.39,1.08-2.14c1.17-8.4,2.4-16.66-1.57-24.95
                c-1.69-3.53-1.51-8.08-1.72-12.18c-0.63-11.95-1.29-23.91-1.42-35.88c-0.11-9.59,0.85-19.18,0.73-28.77
                c-0.11-8.63-1.13-17.25-1.58-25.88c-0.21-3.98-0.04-7.97-0.04-12.75c0.78,1.77,1.3,2.94,2.05,4.63c0.7-0.92,1.35-1.38,1.35-1.84
                c0.02-12.97,0.11-25.95-0.09-38.92c-0.28-18.25-4.38-36.38-1.79-54.73c0.14-0.98,0.11-2,0.07-2.99
                c-0.59-15.24-1.19-30.48-1.81-45.72c-0.29-7.17-0.26-7.19,6.11-4.25c1.31,0.61,2.8,0.83,4.6,1.34c0,5.65-0.11,11.28,0.03,16.91
                c0.22,9.09,2.12,10.38,9.75,5.32c4.44-2.95,8.41-7.13,11.41-11.57c3.07-4.54,6.41-7.88,11.75-9.09L256.34,676.1z M228.05,971.58
                c-0.42,0.06-0.84,0.11-1.26,0.17c-0.13,0.61-0.46,1.24-0.37,1.81c1.5,9.67,0.7,19.03-1.78,28.61
                c-3.16,12.17,4.5,21.63,10.29,31.45c0.4-4.15,0.4-8.33-1.02-11.94c-4.18-10.61-4.92-21.13-2.03-32.22
                C233.54,983.11,230.68,977.22,228.05,971.58z"/>
            <path onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M1144.11,875.76c2.87,7.85,5.21,14.26,7.55,20.68c0.58-0.1,1.17-0.2,1.75-0.29
                c0.16-2.01,0.49-4.02,0.46-6.03c-0.33-20.14-0.92-40.27-1.05-60.41c-0.18-26.81-0.86-53.66,0.32-80.41
                c0.72-16.12-1.89-31.82-2.28-47.73c-0.41-16.62,0.64-33.28,0.4-49.91c-0.21-14.96-1.22-29.9-2.03-44.84
                c-0.37-6.74-1.11-13.46-1.7-20.44c4.3,4.86,8.28,9.23,12.06,13.76c0.88,1.05,1.27,2.66,1.47,4.08c0.25,1.8,0.09,3.66,0.09,5.49
                c0,221.32,0,442.64,0,663.95c0,1.82,0,3.64,0,5.46c-3.46-9.07-11.51-15.99-11.45-26.59c0.07-11.58-1.34-22.97-7.71-33.09
                c-1.27-2.03-2.85-3.86-4.94-6.65c-2.06,3.92-3.58,6.8-5.6,10.66c1.29-12.86,2.54-24.74,3.67-36.62
                c0.66-6.95,1.45-13.91,1.64-20.89c0.48-18.47,0.42-36.96,1.03-55.43c0.77-23.25,1.99-46.49,3.07-69.73
                c0.09-1.98,0.29-4.05,0.94-5.9c5.29-15.01,3.91-30.58,3.83-46c-0.12-24.47-0.53-48.95-0.92-73.42
                c-0.17-11.14-0.59-22.28-0.86-33.43C1143.81,880.4,1143.98,878.77,1144.11,875.76z"/>
            <path onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M864.8,1167.75c5.39-7.4,10.78-14.81,16.17-22.21c-0.13-0.19-0.26-0.38-0.39-0.58
                c-9.34,6.29-18.68,12.58-28.03,18.88c-0.45-0.43-0.9-0.86-1.35-1.29c4.71-6.51,9.41-13.02,14.12-19.52
                c-0.35-0.4-0.71-0.81-1.06-1.21c-7.67,5.18-15.33,10.37-23,15.55c-0.36-0.32-0.71-0.63-1.07-0.95
                c6.11-8.44,12.22-16.88,18.33-25.32c-0.19-0.18-0.38-0.36-0.57-0.55c-8.72,6.07-17.44,12.13-26.16,18.2c0,0,0.02,0.02,0.02,0.02
                c5.08-7.04,10.17-14.09,15.25-21.13c-0.22-0.24-0.45-0.48-0.67-0.72c-8.2,5.95-16.4,11.89-24.6,17.84c0,0,0.03,0.03,0.03,0.03
                c3.7-6.75,7.41-13.51,11.44-20.85c-7.7,4.74-15.08,9.29-22.45,13.83l0.03,0.03c4.69-6.26,9.39-12.51,14.08-18.77
                c-0.23-0.39-0.45-0.78-0.68-1.17c-7.46,3.65-14.93,7.29-22.39,10.94c0,0-0.02-0.01-0.02-0.01c4.12-3.71,8.23-7.42,12.35-11.13
                c-0.51-0.73-1.02-1.47-1.54-2.2c-5.93,2.78-11.86,5.56-17.79,8.34c0,0-0.01,0-0.01,0c3.41-3.65,6.83-7.31,10.24-10.96
                c-0.14-0.25-0.28-0.49-0.42-0.74c-5.89,2.34-11.77,4.67-17.66,7.01c-0.23-0.44-0.45-0.89-0.68-1.33
                c6.27-3.54,12.43-7.3,18.83-10.57c7.1-3.62,14.34-1.68,21.34,0.61c11.48,3.75,22.89,7.73,34.3,11.69
                c1.71,0.59,3.34,1.47,4.94,2.36c1.6,0.88,2.99,2.43,4.67,2.83c10.9,2.59,20.27,8.65,30.37,13.06c2.67,1.16,5.45,2.57,7.5,4.56
                c5.29,5.12,11.19,8.51,18.52,10.11c3.57,0.78,6.69,3.75,9.91,5.9c1.36,0.91,2.3,2.46,3.68,3.32c6.27,3.92,12.55,7.82,18.96,11.49
                c8.9,5.08,15.78,12.36,22.42,19.95c1.52,1.73,3.01,3.55,4.81,4.95c6.25,4.85,12.54,9.66,18.99,14.23c3.1,2.19,6.65,3.73,9.9,5.72
                c7.04,4.29,14.09,8.57,20.93,13.16c1.1,0.74,1.87,3.31,1.5,4.69c-4.61,17.42-14.36,31.53-28.26,42.86l-0.01-0.02
                c3.38-6.84,6.77-13.68,10.15-20.52c-0.48-0.4-0.96-0.8-1.44-1.19c-6.56,6.91-13.12,13.82-19.68,20.73l-0.01-0.01
                c4.8-7.13,9.6-14.26,14.42-21.38c1.98-2.93,4.01-5.83,5.34-9.35c-12.36,7.31-21.57,18.08-31.74,27.73c0,0,0,0.03,0,0.03
                c6.88-9.52,13.76-19.04,20.63-28.57c1.6-2.22,3.14-4.49,4.04-7.5c-11.06,4.23-19.93,11.56-28.67,19.11l-0.03-0.03
                c4.64-7.49,9.27-14.99,13.91-22.48c-0.52-0.5-1.03-1.01-1.55-1.51c-10.78,7.66-21.56,15.31-32.34,22.97l0.02,0.02
                c5.45-8.12,10.83-16.27,16.36-24.33c2.4-3.5,5.11-6.79,7.68-10.17c-0.47-0.51-0.94-1.01-1.41-1.52c-9.88,8-19.76,16-29.64,24
                c0,0,0.03,0.03,0.03,0.03c5.39-8.76,10.79-17.53,16.19-26.29c1.45-2.36,2.94-4.7,3.67-7.72c-10.44,8.19-19.57,17.69-28.86,27
                l0,0.02c7.09-13.05,14.18-26.1,21.27-39.15c-0.23-0.27-0.46-0.54-0.69-0.8c-9.33,8.15-18.66,16.31-27.98,24.46
                c-0.24-0.19-0.49-0.38-0.73-0.57c6.04-10.18,12.07-20.37,18.11-30.55c-0.57-0.52-1.14-1.04-1.72-1.57
                c-9.39,8.3-18.78,16.61-28.18,24.91c-0.23-0.16-0.46-0.32-0.69-0.49c7.05-9.74,14.09-19.48,21.14-29.21
                c-0.52-0.53-1.04-1.07-1.55-1.6c-10.32,8.52-20.63,17.05-30.95,25.57l0,0.01c7.19-10.61,14.38-21.21,21.58-31.82
                c-0.21-0.22-0.42-0.45-0.63-0.67c-12.91,9.52-25.83,19.03-38.74,28.55c-0.51-0.57-1.02-1.14-1.53-1.7
                c4.57-13.65,16.09-22.33,25.18-32.76c-0.39-0.43-0.78-0.86-1.17-1.3c-9.8,7.54-19.59,15.07-29.39,22.61
                c-0.23-0.21-0.47-0.42-0.7-0.63c7.6-9.68,15.21-19.36,22.81-29.04c-0.48-0.5-0.96-1-1.44-1.5c-8.95,6.79-17.89,13.58-26.84,20.37
                c-0.21-0.23-0.42-0.45-0.62-0.68c5.68-6.91,11.37-13.82,17.05-20.73c-0.32-0.34-0.65-0.68-0.97-1.03
                c-9.18,5.45-18.36,10.89-27.54,16.34l-0.01-0.01c5.66-6.52,11.31-13.05,16.97-19.57c-0.3-0.38-0.61-0.77-0.91-1.15
                c-7.69,4.57-15.37,9.14-23.06,13.71L864.8,1167.75z"/>
            <path onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="M434.18,1086.8c-15.33-0.28-30.75-1.7-45.75,4.14c13.51,8.97,28.91,6.62,41.72,7.19
                c-6.18,0.46-14.42,1.06-22.65,1.67c-0.12,0.74-0.25,1.47-0.37,2.21c2.14,1.33,4.13,3.33,6.46,3.86c6.29,1.41,12.7,2.93,19.1,3.09
                c16.79,0.42,33.6,0.14,50.39,1.14c-27.96,1.18-55.91,2.45-83.87,3.4c-3.47,0.12-7.31-0.88-10.45-2.44
                c-12.07-5.98-23.97-12.33-35.83-18.72c-2.12-1.15-4.16-2.88-5.59-4.82c-7.22-9.78-16.96-14.07-28.89-13.98
                c-2.5,0.02-5.01,0-7.51,0c-0.06-0.56-0.12-1.12-0.18-1.69c0.92-0.47,1.83-1.33,2.76-1.35c9.98-0.18,19.98-0.63,29.94-0.19
                c9.06,0.4,18.09,2.55,27.12,2.53c7.32-0.01,14.61-2.21,21.95-3.24c6.91-0.96,13.89-1.41,20.77-2.55
                c5.06-0.84,10.02-2.36,14.97-3.77c1.58-0.45,2.98-1.54,4.46-2.34c-0.14-0.45-0.27-0.9-0.41-1.35c-3.02,0.3-6.04,0.67-9.07,0.89
                c-10.28,0.74-20.58,1.38-30.86,2.17c-1.97,0.15-4.21,0.29-5.81,1.28c-11.39,7.05-23.98,5.61-33.04,5.63
                c4.67-1.5,12.26-4.29,20.05-6.32c5.91-1.54,12.04-2.54,18.13-2.99c9.62-0.71,19.3-0.63,28.93-1.17c3.73-0.21,8.66-0.27,9.25-4.78
                c0.24-1.84-3.83-4.74-6.43-6.39c-18.92-11.99-40.13-16.41-62.06-18.49c-4.74-0.45-9.39-1.91-14.06-2.98
                c-5.45-1.24-7.78-5.1-8.56-10.3c50.03,0,99.95,0,149.36,0c1.38,2.79,2.54,5.15,3.99,8.08c-4.09,1.62-8.18,3.24-12.09,4.78
                c-1.16,5,1.46,6.52,5.49,6.72c10.3,0.5,20.62,0.77,30.91,1.47c11.54,0.78,23.06,1.94,34.59,2.94c0.04,0.56,0.09,1.11,0.13,1.67
                c-3.79,0.57-7.59,1.63-11.38,1.61c-16.65-0.06-33.3-0.39-49.57,4.08c-1.76,0.48-3.55,0.88-5.3,1.38c-0.45,0.13-0.83,0.5-1.25,0.76
                c-0.06,0.53-0.13,1.06-0.19,1.59c4.29,1.56,8.57,3.11,12.77,4.64c-16.06,1.68-32.54,2.74-48.6,7.34
                c4.9,3.94,10.54,4.92,16.29,4.87c16.13-0.16,32.27-0.86,48.4-0.84c18.14,0.01,36.27,0.57,54.41,0.9c0.91,0.02,1.82,0.11,2.77,1.03
                c-3.9,0.43-7.79,1.19-11.69,1.24c-31.96,0.45-63.93,0.51-95.89,1.24c-17.61,0.4-35.2,1.82-52.79,2.88
                c-1.66,0.1-3.27,0.95-4.9,1.44c-0.19,0.64-0.39,1.27-0.58,1.91c3.13,1.76,6.07,4.27,9.44,5.13c6.89,1.75,14.01,2.61,21.04,3.83
                L434.18,1086.8z"/>
            <path onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="M282.89,676.15c0,1.31,0,3.06,0,4.82c0.02,27.81-0.18,55.61,0.14,83.42c0.22,19.47,1.5,38.92,1.64,58.39
                c0.08,11.62-1.09,23.25-1.77,34.87c-0.16,2.72-1.83,2.99-3.79,1.72c-1.52-0.98-2.72-2.47-4.26-3.44
                c-4.05-2.54-6.51-7.19-12.43-7.38c-4.75-0.15-8.69-9.34-8.8-14.01c-0.1-3.88-2.68-7.73-4.33-11.52c-1.34-3.09-3.78-5.97-4.21-9.14
                c-0.66-4.85-3.21-7.01-7.44-8.12c-1.45-0.38-2.89-0.79-4.33-1.18c-3.79-1.04-5.78-3.08-5.69-7.46
                c0.51-23.46,0.68-46.92,1.18-70.38c0.19-8.93,3.03-16.79,9.84-23.21c7.61-7.17,15.28-14.42,19.62-24.34
                c0.42-0.96,2.22-1.76,3.43-1.86C268.51,676.82,275.33,676.55,282.89,676.15z"/>
            <path onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="M861.7,593.96c0.9,1.27,2.71,3.07,3.57,5.24c1.12,2.86,1.95,6,2.09,9.06c0.51,11.3,0.68,22.62,1.01,33.93
                c0.03,1.08,0.34,2.15,0.52,3.22c0.45,0.11,0.91,0.22,1.36,0.33c1.35-3.87,2.7-7.75,3.99-11.47c0.19,1.87,0.4,3.97,0.61,6.06
                c0.55,0.07,1.09,0.13,1.64,0.2c3.67-14.53,7.33-29.07,11.35-45c0,17.17,0,33,0,48.83c0.5,0.17,1,0.35,1.5,0.52
                c0.93-2.22,2.29-4.37,2.7-6.68c1.85-10.3,3.43-20.66,5.11-31.05c0.01,0,0.11-0.05,0.2-0.1c0.04,0.16,0.09,0.32,0.13,0.48
                c0.84-0.34,2.14-0.46,2.44-1.07c1.41-2.9,2.54-5.92,4.48-8.75c3.65,17.73,0.79,35.66,2.68,53.29c0.62,0.14,1.24,0.27,1.85,0.41
                c1.02-2.73,2.46-5.38,2.97-8.21c1.87-10.3,3.32-20.68,5.18-30.98c0.67-3.7,2.06-7.26,3.12-10.89c0.63,0.04,1.25,0.08,1.88,0.13
                c0,7.2,0.2,14.4-0.04,21.59c-0.76,23.28-2.11,46.55-2.38,69.83c-0.18,14.95,1.13,29.91,1.75,44.87c0.08,1.95,0.01,3.9,0.01,5.85
                c-0.55,0.35-1.09,0.7-1.64,1.05c-3-3.12-6.59-5.86-8.89-9.44c-4.31-6.71-7.96-8.4-15.61-5.73c-0.47,0.16-1.01,0.52-1.41,0.41
                c-8.51-2.32-14.71,4.64-22.45,5.65c-2.78,0.36-5.14,3.3-7.96,4.2c-3.31,1.05-6.92,1.14-11.19,1.77
                C851.93,691.17,851.14,641.76,861.7,593.96z"/>
            <path onClick={() => props.onFill(43)} fill={props.fillColors[43]} d="M336.01,1021.88c-10.01-1.08-20.9-2.11-31.73-3.61c-1.46-0.2-3.43-2.61-3.73-4.27
                c-2.49-13.4-4.76-26.84-6.87-40.3c-1.26-8.04-2.09-16.16-3.19-24.22c-0.23-1.68-0.85-3.3-1.29-4.95
                c-0.65,0.02-1.29,0.05-1.94,0.07c-0.78,6.75-1.55,13.5-2.18,18.92c-6.53-20.91-6.1-43.35-3.16-66.19
                c4.39-34.16,6.56-68.42,5.18-103.03c-1.64-41.23-0.95-82.55-1.51-123.82c-0.06-4.66,3.21-5.78,5.92-6.82
                c3.05-1.17,6.7-1.19,10.03-0.91c0.92,0.08,2.31,3.01,2.29,4.61c-0.19,12.14-1.04,24.28-0.99,36.42
                c0.13,30.11,0.07,60.24,1.16,90.32c0.92,25.25,3.12,50.46,5.18,75.66c2.69,33.01,7.1,65.81,14.27,98.17
                C327.45,986.06,331.85,1004.12,336.01,1021.88z M303.02,939.3c0.42,0.04,0.84,0.09,1.26,0.13c0.33-1.33,0.93-2.66,0.93-3.99
                c0.01-13.3,0.33-26.62-0.26-39.89c-0.65-14.57-2.3-29.09-3.27-43.65c-0.87-13.09-1.38-26.21-2.13-39.31
                c-0.07-1.23-0.75-2.43-1.14-3.65c-0.46,0.11-0.93,0.21-1.39,0.32C296.92,852.7,295.66,896.2,303.02,939.3z M329,1013.55
                c0.56-0.26,1.12-0.51,1.68-0.77c-9.5-28.69-17.42-57.74-20.66-87.92c-1.08,1.91-1.3,3.67-1.29,5.44
                c0.1,10.46,0.48,20.93,0.34,31.39c-0.24,17.87,6.68,33.21,16.18,47.71C326.26,1010.94,327.74,1012.18,329,1013.55z"/>
            <path onClick={() => props.onFill(44)} fill={props.fillColors[44]} d="M943.39,307.48c3.3,2.33,5.74,2.38,8.01-0.82c0.94-1.32,3.03-2.18,4.72-2.45
                c0.79-0.13,2.76,1.69,2.71,2.55c-0.3,5.61,3.71,8.91,9.13,7.18c4.68-1.49,9.46,2.27,9.26,7.25c-2.31,56.7-4.6,113.4-6.94,170.09
                c-0.19,4.65-0.55,9.3-0.99,13.94c-0.14,1.44-0.62,2.94-1.31,4.21c-2.33,4.24-5.04,4.52-7.09,0.11c-2.36-5.1-5.86-7.74-11.19-8.39
                c-0.93-0.11-1.9,0.08-3.09,0.14c0,1.72,0,3.16,0,5.86c-2.06-1.58-4.25-2.39-4.75-3.8c-1.89-5.35-4.42-10.87-4.58-16.38
                c-0.55-19.12-0.21-38.27-0.21-57.41c-0.01-21.63-0.71-43.28,0.18-64.87c0.74-18.08,3.33-36.08,5.14-54.11
                C942.46,309.77,942.87,309.01,943.39,307.48z"/>
            <path onClick={() => props.onFill(45)} fill={props.fillColors[45]} d="M676.99,406.15c2.45,0.69,4.85,1.16,7.09,2.04c24.16,9.46,48.35,18.85,72.43,28.54
                c14.24,5.73,25.8,15.68,37.79,24.93c10.27,7.93,20.64,15.75,31.19,23.3c6.38,4.57,7.86,4.07,9.39-3.73
                c1.82-9.27,2.77-18.71,4.01-28.09c1.31-9.88,2.48-19.79,3.78-29.67c2.21-16.76-3.2-30.42-16.51-40.88
                c-3.52-2.77-4.2-7.24-1.59-14.16c1.96,1.39,3.71,2.78,5.61,3.94c2.54,1.56,5.04,3.31,7.8,4.33c5.77,2.13,8.67,0.4,10.28-5.57
                c0.34-1.28,0.7-2.55,1.27-4.63c3.94,5.88,8.67,10.04,7.4,17.96c-0.91,5.63,0.11,12.05,1.99,17.53c2.87,8.33,9.08,9.2,15.84,3.51
                c1.3-1.1,4.71-1.98,5.14-1.43c1.93,2.52,6.29,4.09,4.38,8.58c-0.06,0.15-0.16,0.29-0.25,0.43
                c-17.68,27.69-20.36,59.82-26.47,90.86c-2.11,10.7-2.04,21.82-3.11,32.74c-0.18,1.84-1.07,3.6-1.63,5.4
                c-1.61-0.63-3.24-1.23-4.84-1.91c-2.56-1.1-4.99-2.67-7.65-3.3c-6.73-1.59-12.26-5.25-16.53-10.31c-1.5-1.78-0.59-5.57-0.85-8.44
                c-0.33-3.6,1.28-5.13,4.86-5.11c1.11,0,2.22-0.84,3.38-1.77c-8.28-1.66-11.66-8.84-16.05-14.21
                c-25.98-31.76-59.99-51.62-97.15-67.04c-12.75-5.29-25.43-10.76-38.25-15.88C675.68,412.48,675.51,410.12,676.99,406.15z"/>
            <path onClick={() => props.onFill(46)} fill={props.fillColors[46]} d="M130.88,874.33c0,6.26,0.36,12.54-0.07,18.77c-1.06,15.37-2.68,30.69-3.72,46.06
                c-0.85,12.61-1.59,25.25-1.64,37.88c-0.03,8.17-0.49,15.78-7.2,21.24c1.04-14.47,0.57-29.63,3.57-44.05
                c3.39-16.29,4.61-32.55,4.84-48.96c0.14-9.56-2.01-19.17-1.63-28.7c0.35-8.66,2.91-17.2,4.14-25.85
                c0.82-5.75,1.36-11.58,1.41-17.37c0.2-23.14,0.17-46.29,0.18-69.43c0-1.07-0.38-2.15-0.71-3.87c-0.99,1.04-1.51,1.6-2.04,2.15
                c-0.08-0.16-0.29-0.39-0.3-0.63c-0.31-13.3-0.81-26.6-0.81-39.91c0-6.26-1.6-11.05-6.76-15.15c-4.63-3.67-8.33-8.54-12.37-12.94
                c-0.57-0.63-0.78-1.59-0.18-3.11c1.07,1.25,2.05,2.58,3.22,3.73c3.3,3.23,6.41,6.73,10.1,9.45c2.97,2.19,5.73,1.6,6.09-2.95
                c0.15-1.99,0.77-3.96,0.71-5.93c-0.11-3.78,1.35-5.33,5.26-5.41c8.07-0.18,8.05-0.09,8.35,8.16c0.74,20.93,1.78,41.86,2.66,62.79
                c0.19,4.66,0.21,9.32,0.28,13.98c0.32,20.47,0.01,40.97,1.11,61.4c1.1,20.57,3.39,41.05,8.29,61.22
                c5.96,24.55,10.62,49.42,16.54,73.98c2.79,11.58,7.07,22.82,10.98,34.1c1.74,5.02,1.06,7.14-3.99,7.48
                c-11.2,0.76-22.42,1.24-34.99,1.91c-2.82-46.06-5.69-93.1-8.57-140.13C132.71,874.26,131.8,874.3,130.88,874.33z M143.02,881.85
                c-0.59,0.1-1.18,0.21-1.76,0.31c0,2.94-0.32,5.92,0.06,8.81c1.69,12.84,3.36,25.69,5.44,38.46c3.06,18.69,7.32,37.09,14.71,54.64
                c1.87,4.44,3.16,9.11,4.92,13.6c0.58,1.48,1.79,2.7,2.71,4.04c0.42-0.19,0.83-0.37,1.25-0.56c-0.35-2.57-0.49-5.18-1.09-7.69
                c-3.81-16.14-7.85-32.23-11.56-48.39c-4.38-19.12-8.48-38.31-12.76-57.46C144.51,885.65,143.67,883.77,143.02,881.85z"/>
            <path onClick={() => props.onFill(47)} fill={props.fillColors[47]} d="M357.23,544.18c-1.38,1.19-3.33,2.14-3.98,3.65c-0.74,1.71-1,4.29-0.19,5.82c0.64,1.21,3.15,1.6,4.92,1.98
                c2.25,0.49,4.66,0.33,6.86,0.96c3.08,0.87,6.19,1.91,9,3.42c3.29,1.76,6.19,4.25,9.43,6.14c1.49,0.87,3.36,1.19,5.1,1.48
                c2.14,0.36,4.55-0.1,6.45,0.71c6.19,2.66,11.83,6.38,19.24,5.7c3.21-0.29,6.65,1.99,10.34,3.22c-1.14,3.52-2.22,7.09-7.6,6.84
                c-1.42-0.07-3.08,1.56-4.36,2.71c-4.6,4.15-9.25,6.66-16.12,5.62c-6.86-1.04-14.04,0.09-21.08,0.1c-3.74,0.01-7.55,0.06-11.2-0.62
                c-4.14-0.78-7.62-2.55-11.99,0.91c-2.19,1.73-8.12,1.41-10.56-0.4c-6.02-4.45-10.59-0.18-15.76,0.82
                c-1.71,0.33-3.73,0.54-5.29-0.04c-6.34-2.37-12.19-2.7-18.42,0.95c-2.11,1.23-5.84,0.13-8.7-0.5c-2.67-0.59-4.66-1.54-7.86-0.22
                c-2.73,1.13-6.83-0.84-10.26-1.67c-2.4-0.58-4.7-2.12-7.06-2.16c-2.6-0.04-5.6,0.55-7.77,1.92c-4.6,2.9-8.56,6.75-14.6,2.74
                c-1.12-0.74-3.97,0.56-5.72,1.48c-2.19,1.16-4.05,3.14-7.16-0.46c5.29-4.63,10.19-9.54,15.7-13.62
                c11.63-8.62,23.6-16.76,35.36-25.19c2.83-2.03,5.64-4.19,8.07-6.67c4.31-4.4,8.4-7.23,15.52-6.61c5.82,0.51,11.49-0.04,16.73,3.19
                c0.7,0.43,1.91,0.19,2.86,0.06c4.26-0.59,9.12-2.87,12.63-1.54c6.34,2.41,11.73-0.02,17.52-0.69L357.23,544.18z"/>
            <path onClick={() => props.onFill(48)} fill={props.fillColors[48]} d="M265.98,998.36c-1.08-4.6-2.36-9.17-3.19-13.82c-1.52-8.5-4.93-15.87-11.96-21.19
                c-6.15-4.66-7.51-11.69-8.84-18.47c-4.76-24.31-8.58-48.73-9.52-73.59c-0.77-20.25-2.6-40.46-3.93-60.69
                c-0.09-1.31-0.01-2.62-0.01-4.69c3.86,1.17,7.54,1.97,10.9,3.47c1.3,0.58,2.33,2.66,2.69,4.24c0.95,4.21,1.67,8.12,5.38,11.32
                c2.48,2.14,3.33,5.58,3.38,9.71c0.06,4.61,3.43,9.36,5.99,13.68c1.26,2.13,3.94,3.41,6.43,4.07c1.86,0.67,3.93,1.01,5.52,2.08
                c3.01,2.02,5.5,4.87,8.64,6.61c3.99,2.21,4.95,5.17,4.48,9.41c-3.29,30.07-6.46,60.15-9.67,90.22
                c-1.09,10.24-2.16,20.49-3.31,30.73c-0.25,2.26-0.81,4.48-1.23,6.72C267.14,998.23,266.56,998.29,265.98,998.36z"/>
            <path onClick={() => props.onFill(49)} fill={props.fillColors[49]} d="M939.13,683.47c3.92,6.58,7.7,12.67,14.43,16.57c1.32,0.77,1.87,3.74,1.91,5.72
                c0.41,19.96,0.22,39.95,1.05,59.89c0.8,19.09,2.58,38.14,4.01,57.2c0.11,1.47,0.81,2.89,1.23,4.34c0.67-0.03,1.34-0.05,2.01-0.08
                c2.14-19.98,4.28-39.96,6.3-58.84c4.12,62.72,8.31,126.53,12.49,190.35c-0.65,0.34-1.31,0.67-1.96,1.01
                c-3.97-2.23-8.11-4.22-11.8-6.83c-1.04-0.73-1-3.3-1.08-5.04c-0.9-21.43-1.71-42.87-2.59-64.31c-0.05-1.24-0.48-2.46-0.74-3.68
                c-0.51-0.03-1.01-0.06-1.52-0.1c-0.45,3.59-0.94,7.17-1.34,10.76c-2.25,20.3-4.51,40.61-6.68,60.92
                c-0.42,3.96-0.31,7.99-0.79,11.94c-0.14,1.13-1.51,2.11-2.31,3.16c-0.88-1.06-2.12-2.01-2.53-3.23c-0.45-1.35-0.14-2.96-0.17-4.46
                c-0.4-22.46-0.71-44.93-1.23-67.39c-0.47-19.79-1.46-39.57-1.66-59.36c-0.08-8.44,1.9-16.89,1.87-25.34
                c-0.05-15.45-0.29-30.94-1.56-46.32c-1.97-24.02-4.86-47.96-7.33-71.94C938.99,686.78,939.13,685.12,939.13,683.47z"/>
            <path onClick={() => props.onFill(50)} fill={props.fillColors[50]} d="M850.4,261.94c11.56,11.1,13.72,11.96,22.77,8.69c0.73,2.66,1.65,5.22,2.13,7.86
                c1.44,7.77,13.38,12.58,19.73,8.02c3.43-2.47,5.52,0.36,6.23,2.96c1.23,4.45,3.71,6.53,8.14,7.58c4.26,1.01,6.78,3.35,6.3,8.9
                c-1.78,20.55-2.63,41.18-4.37,61.73c-0.67,7.88-7,12.49-12.83,16.91c-0.58,0.44-2.96-0.44-3.66-1.35
                c-13.9-18.25-28.32-36.15-41.27-55.05c-10.11-14.76-10.51-31.94-7.22-49.14C847.43,273.38,848.99,267.81,850.4,261.94z"/>
            <path onClick={() => props.onFill(51)} fill={props.fillColors[51]} d="M498.36,947.05c-4.96-2.04-6.89-7.33-4.11-12.54c0.23-0.43,0.76-0.75,0.89-1.19
                c1.42-4.97,1-10.49,6.04-14.3c1.15-0.87-0.31-5.63-1.03-8.48c-1.57-6.24,1.73-13.56,7.06-16.94c3.23-2.05,5.67-5.31,8.62-7.84
                c1.07-0.92,2.53-1.64,3.91-1.85c1.83-0.27,4.62,0.86,5.45-0.04c2.15-2.35,4.21-5.3,4.94-8.34c1.26-5.25,1.69-10.18,8.58-11.54
                c3.06-0.6,5.66-3.6,8.47-5.52c0.14-0.09,0.3-0.24,0.44-0.23c6.51,0.55,9.05-3.34,11.7-8.67c1.74-3.49,6.1-5.99,9.79-8.04
                c1.4-0.78,4.2,0.94,5.2,1.22c4.88-3.85,8.97-7.83,13.74-10.62c4.3-2.52,9.21-3.03,14.57-2.26c3.35,0.48,7.2-2.18,10.76-3.59
                c1.68-0.67,3.15-2.22,4.84-2.45c1.82-0.25,4.25-0.02,5.61,1.04c4.92,3.79,8.35,3.56,11.33-1.65c2.69-4.71,8.05-4.5,11.45-4.12
                c5.26,0.6,9.62-1.24,14.37-1.97c0.68-0.1,1.43,0.19,2.3,1.25c-0.91,0.37-1.94,0.59-2.71,1.15c-1.32,0.96-3.36,2.02-3.53,3.26
                c-0.58,4.09-2.94,4.28-6.08,3.72c-7.62-1.36-8.59-0.27-12.57,6.17c-2.69,4.37-4.45,10.18-12.29,9.87
                c-2.98-0.12-6.78,5.07-9.1,8.58c-2.25,3.39-9.22,9.18-13.21,9.13c-5.33-0.07-7.2,3.98-9.39,7.75c-1.87,3.22-3.94,6.32-5.92,9.47
                c-0.26,0.42-0.84,0.85-0.81,1.22c0.62,6.29-5.39,9.33-7.14,14.52c-0.56,1.67-2.87,2.85-4.52,4.04c-1.8,1.3-3.76,2.37-5.89,3.68
                c-0.65-0.95-1.41-2.99-2.86-3.92c-1.92-1.23-5.33-2.89-6.45-2.08c-4.33,3.13-8.88,2.44-13.52,2.26c-0.81-0.03-1.71-0.12-2.41,0.19
                c-4.76,2.07-9.63,3.97-14.13,6.53c-1.36,0.77-2.5,3.58-2.24,5.21c0.67,4.19-1.42,4.64-4.52,4.24c-8.1-1.04-9.3,0.08-10.38,8.17
                c-0.27,2.01-2.3,3.87-3.71,5.63c-1.1,1.37-2.57,2.44-3.77,3.74c-1.61,1.75-3.64,3.36-4.55,5.44
                C500.1,939.63,499.44,943.25,498.36,947.05z"/>
            <path onClick={() => props.onFill(52)} fill={props.fillColors[52]} d="M418.06,360.1c-1.98-1.01-3.95-2.2-6.05-3.03c-6.17-2.45-12.25-5.29-18.64-6.95
                c-4.67-1.21-9.8-0.54-14.65-1.24c-2.13-0.31-4.06-1.97-6.08-3.02c0.93-1.63,1.98-3.21,2.76-4.91c0.76-1.66,2.07-3.66,1.68-5.12
                c-1.08-4.01-2.68-7.92-7.81-8.62c-3.22-0.44-6.38-1.35-10.16-2.17c2.73-7.07-2.1-9.92-6.41-12.51c-2.77-1.67-2.65-3.35-1.2-5.17
                c5.66-7.1,10.47-15.48,17.57-20.68c9.37-6.85,16.42-14.88,21.46-24.99c1.46-2.92,3.23-4.26,6.53-4.18
                c2.45,0.06,4.92-0.61,7.39-0.9c6.17-0.72,11.57,3.9,13.33,9.75c2.28,7.53,4.92,15.11,8.64,22c5.21,9.65,7.8,18.74,3.82,29.82
                C425.32,331.89,422.02,346.19,418.06,360.1z"/>
            <path onClick={() => props.onFill(53)} fill={props.fillColors[53]} d="M879.03,1125.15c-3.05-1.15-5.64-1.95-8.06-3.09c-3.75-1.76-7.56-3.49-11-5.75
                c-1.48-0.98-2.86-3.21-2.98-4.96c-0.34-4.97-0.07-9.98,0.07-14.97c0.34-11.68-2.11-22.78-6.63-33.51
                c-0.94-2.23-1.42-4.8-1.46-7.22c-0.17-10.32-0.29-20.65-0.09-30.97c0.58-29.34-1.41-58.51-4.44-87.68
                c-0.87-8.33,0.11-16.92,0.91-25.32c0.25-2.63,2.81-4.99,4.06-7.59c0.81-1.68,1.71-3.61,1.63-5.38c-0.53-11.13-1.51-22.23-2-33.36
                c-0.88-19.78-1.6-39.57-2.25-59.36c-0.91-27.94-1.81-55.89-2.47-83.84c-0.18-7.72,0.49-15.46,0.78-23.18
                c0.77-0.09,1.54-0.18,2.31-0.28c0.62,2.31,1.76,4.62,1.77,6.93c0.21,39.12-0.44,78.25,0.64,117.35
                c0.62,22.56,4.11,45.03,6.08,67.56c2.31,26.5,4.03,53.06,6.67,79.53c1.78,17.86,4.93,35.57,7.05,53.4
                c2.19,18.49,3.94,37.03,5.77,55.56c1.38,14.06,2.58,28.15,3.83,42.23C879.3,1122.17,879.14,1123.13,879.03,1125.15z"/>
            <path onClick={() => props.onFill(54)} fill={props.fillColors[54]} d="M1128.17,1436.71c4.02-12.87,8.4-25.29,11.76-37.97c6.54-24.71,7.33-49.91,5.48-75.3
                c-0.6-8.28-0.4-16.63-0.41-24.95c0-1.75,0.63-3.51,1.2-6.44c2.73,2.04,5.79,3.15,6.59,5.16c3.17,7.95,7.9,16.16,8,24.32
                c0.69,56.78,0.32,113.57,0.45,170.35c0.01,4.76-1.41,6.93-6.27,6.29c-0.65-0.09-1.33-0.01-2-0.01
                c-11.01,0.01-10.79,0.02-10.15-10.91c0.61-10.42,0.65-20.88,1.04-31.31c0.17-4.48,0.62-8.95,0.85-13.43
                c0.04-0.72-0.43-1.47-1.95-2.54c-0.85,1.94-2.12,3.8-2.5,5.82c-3.53,19.03-14.28,34.19-25.92,48.89
                c-4.85,6.13-11.32,2.48-17.03,3.43c-0.36-0.42-0.73-0.84-1.09-1.26c4.15-5.22,7.69-11.1,12.57-15.52
                c12.02-10.89,16.29-25.99,22.96-39.81C1133.14,1438.66,1133.71,1435.45,1128.17,1436.71z"/>
            <path onClick={() => props.onFill(55)} fill={props.fillColors[55]} d="M124.45,507.21c1.9,1.91,3.81,3.83,6.43,6.46c-0.16,0.98-0.27,3.45-1.03,5.71
                c-0.36,1.08-1.87,1.89-3.01,2.54c-1.04,0.59-2.78,0.44-3.4,1.24c-6.25,8.15-13.39,5.27-20.58,1.06c-6.32-3.7-12.9-7.05-19.63-9.91
                c-1.78-0.75-4.64,0.77-6.84,1.66c-0.26,0.11,0.48,2.92,0.93,4.42c0.38,1.25,0.81,2.57,1.57,3.59c2.79,3.71,2.86,8-0.25,11.13
                c-0.67,0.67-2.02,0.78-3.08,0.94c-2.01,0.29-4.05,0.31-6.05,0.63c-3.09,0.5-5.9,3.36-9.4,0.84c-0.6-0.43-2.26,0.28-3.27,0.78
                c-3.35,1.68-6.55,3.91-8.8-1.62c-0.25-0.61-4.61-0.63-5.84,0.45c-4.31,3.82-7.82,6.02-13.88,2.24c-3.4-2.12-8.76-1.07-13.22-1.55
                c-2.44-0.26-4.85-0.96-7.29-1.07c-4.4-0.21-5.72-3.17-5.78-6.68C1.86,518.11,1.88,506.12,2,494.15c0.04-4.43,2.07-5.28,6.22-3.49
                c2,0.86,4.41,0.74,6.53,1.39c6.13,1.86,12.73-1.07,18.74,1.94c0.63,0.31,1.63,0.07,2.4-0.13c3.7-1,6.64,0,10.04,1.92
                c2.16,1.22,5.93-0.22,8.93-0.73c1.37-0.23,2.75-1.73,3.93-1.52c4.91,0.85,9.72,2.3,14.62,3.2c3.22,0.59,6.55,0.52,9.82,0.9
                c4.3,0.5,8.81-0.27,12.39,3.63c1.85,2.01,6.12-1.24,6.88-5.15c5.96,3.33,11.84,6.68,17.8,9.9c1.25,0.67,2.76,0.85,4.16,1.25
                C124.47,507.25,124.45,507.21,124.45,507.21z"/>
            <path onClick={() => props.onFill(56)} fill={props.fillColors[56]} d="M855.17,619.55c-0.91-10.71-2.33-23.11-2.85-35.53c-0.28-6.59-4.8-9.68-8.63-13.53
                c-0.44-0.45-2.11,0.33-2.97,0.49c-1.59-3.52-3.19-7.05-4.79-10.58c-0.67,0.07-1.34,0.14-2.01,0.21
                c-0.75,5.58-1.49,11.16-2.24,16.75c-0.43,0.03-0.85,0.06-1.28,0.09c-0.71-11.9-1.67-23.79-2.01-35.71
                c-0.13-4.7,2.36-5.96,6.54-4.1c4.8,2.13,9.47,4.54,14.24,6.74c1.01,0.47,2.49,1.14,3.24,0.75c7.47-3.93,13.7,1.9,20.58,2.45
                c6.33,0.5,12.64,1.23,18.93,2.07c2.19,0.29,4.6,0.74,6.4,1.9c5.01,3.21,9.56,6.96,15.27,9.25c5.6,2.25,8.14,7.71,7.76,13.46
                c-0.6,8.91-1.87,17.8-3.43,26.6c-2.21,12.41-4.96,24.72-7.46,37.08c-0.36,1.77-0.59,3.56-1.49,5.29c0-11.09,0.08-22.17-0.04-33.26
                c-0.04-3.48-0.35-7-1.03-10.41c-0.36-1.82-1.75-3.43-2.67-5.13c-1.63,0.91-3.26,1.81-4.85,2.7c-0.51-1.12-1.26-2.77-2.01-4.41
                c-0.55,0.02-1.1,0.04-1.65,0.06c-2.06,13.77-4.11,27.54-6.17,41.3c-0.14,0-0.28,0-0.41,0c0-4.79-0.1-9.57,0.03-14.36
                c0.13-4.99,0.79-9.97,0.66-14.95c-0.12-4.78-0.96-9.55-2.52-14.34c-5.5,9.49-8.9,19.31-9.98,29.89c-0.2-2.64-0.4-5.28-0.6-7.92
                c-0.59-0.07-1.18-0.14-1.77-0.2c-1.51,7.2-3.02,14.4-4.54,21.61c-2.2-16.22,2.46-33.74-11.94-48.23
                C857.87,597.96,856.42,609.59,855.17,619.55z M882.4,556.09c0.42,0.48,0.84,0.95,1.25,1.43c1.96-1.76,3.93-3.52,5.89-5.29
                c-0.4-0.45-0.79-0.9-1.19-1.36C886.37,552.61,884.38,554.35,882.4,556.09z M890.1,556.47c0.37,0.52,0.74,1.03,1.11,1.55
                c1.74-1.1,3.49-2.2,5.23-3.3c-0.31-0.49-0.62-0.97-0.93-1.46C893.71,554.33,891.91,555.4,890.1,556.47z"/>
            <path onClick={() => props.onFill(57)} fill={props.fillColors[57]} d="M878.56,435.36c-0.09,1.71,0.49,3.94-0.37,5.06c-5.21,6.75-1.59,13.22,1.04,19.12
                c1.63,3.65,5.63,6.39,8.91,9.11c2.59,2.15,4.39,4.17,4.31,7.85c-0.04,1.96,1.15,4.06,2.14,5.9c1.4,2.6,2.68,5.53,4.82,7.38
                c3.28,2.83,7.06,5.26,10.98,7.08c2.9,1.34,3.94,2.91,4.05,5.97c0.64,17.76,1.44,35.52,2.25,54.74
                c-6.26-3.27-12.06-6.39-17.96-9.33c-1.88-0.94-4.52-2.54-5.9-1.89c-5.46,2.57-10.57,0.06-15.62-0.63c-5.83-0.8-11.52-3-17.11-5.05
                c-1.26-0.46-2.66-2.81-2.63-4.25c0.8-31.5,8.31-61.88,15.59-92.3c0.75-3.12,2.04-6.1,3.08-9.15
                C876.95,435.11,877.76,435.23,878.56,435.36z"/>
            <path onClick={() => props.onFill(58)} fill={props.fillColors[58]} d="M119.49,442.29c-1-2-1.99-4-2.99-6.01c0,0-0.03,0.03-0.03,0.03c4.6,3.23,9.04,6.73,13.86,9.59
                c3.66,2.18,7.69,3.87,11.77,5.1c1.48,0.45,3.56-1.09,5.37-1.73c0,0-0.03,0.03-0.03,0.03c7.36,6.1,14.71,12.21,22.07,18.31
                c0.4-0.34,0.8-0.68,1.2-1.02c-1.82-8.61-6.17-15.75-13.27-21.3c0,0,0.01,0,0.01,0c1.33-0.33,2.67-0.66,4-1
                c0,0-0.02,0.02-0.02,0.02c8.18,15.61,16.09,31.37,24.63,46.79c4.99,9,11.16,17.34,16.44,26.19c3.64,6.1,6.65,12.57,9.96,18.86
                c0.43,0.82,0.96,1.59,2.06,3.39c0-4.43-0.21-7.71,0.04-10.95c1.4-18.26,2.84-36.53-0.95-54.67c-2.14-10.27-6.95-19.32-13.73-27.38
                c-6.85-8.14-13.53-16.43-20.17-24.74c-1.23-1.54-3.46-3.81-0.17-5.12c1.12-0.45,3.75,1.2,4.8,2.57
                c4.03,5.27,7.53,10.94,11.57,16.18c1.58,2.04,4.08,3.37,7.23,5.87c-0.17-3.46-0.26-5.22-0.35-6.98
                c1.99,11.17,11.46,17.36,17.89,26.49c-1.47-5.7,3.16-5.89,5.91-7.93c3.83-2.83,7.37-6.06,11.31-9.35c0.82,0.56,2.26,1.54,3.9,2.65
                c-2.89,3.35-5.58,6.92-8.74,10.01c-5.55,5.42-8.47,11.65-8.19,19.54c0.32,8.81,0.55,17.66,0.12,26.46
                c-0.82,16.42-2.11,32.82-3.28,49.23c-0.38,5.3-1.05,10.59-1.46,15.89c-0.33,4.31-0.04,8.72-0.84,12.92
                c-0.73,3.83-2.58,7.46-4.01,11.38c0.65-0.5,1.32-1.01,1.99-1.53c0,0,0.05,0.06,0.05,0.06c0.94,0.32,2.21,1.19,2.77,0.86
                c7.73-4.5,15.35-9.19,23.03-13.77c7.2-4.29,12.48-10.27,16.28-17.73c2.45-4.81,5.34-9.39,8.53-13.81
                c-2.89,6.49-7.6,12.23-7.68,20.57c2.37-0.83,4.16-1.47,5.96-2.1l0.05,0.06c-5.24,4.1-10.58,8.08-15.68,12.34
                c-6.38,5.32-12.72,10.71-18.77,16.39c-5.89,5.54-8.94,6-16.18,2.12c-3.16-1.69-6.9-2.32-10.04-4.04
                c-1.27-0.69-2.49-3.11-2.31-4.58c2.68-22.23-1.57-42.93-12.51-62.47c-7.8-13.94-14.76-28.35-22.75-42.17
                c-3.42-5.92-7.92-11.57-13.07-16.02c-3.06-2.64-8.34-2.69-12.22-3.79c2.28-0.51,5.08-1.13,7.88-1.75c0.13-0.65,0.26-1.3,0.39-1.95
                c-10.53-5.36-21.06-10.71-31.6-16.07L119.49,442.29z M158.01,464.9c0.49-0.39,0.97-0.79,1.46-1.18c-1.18-1.45-2.36-2.89-3.54-4.34
                c-0.4,0.32-0.81,0.64-1.21,0.96C155.81,461.86,156.91,463.38,158.01,464.9z"/>
            <path onClick={() => props.onFill(59)} fill={props.fillColors[59]} d="M156.42,701.12c1.58,2.32,2.86,4.94,4.81,6.89c2,2,4.09,1.51,4.47-1.66c0.57-4.77,0.91-9.56,1.38-14.59
                c4.05-1.02,5.9,0.49,6.05,4.6c0.24,6.81,0.6,13.62,0.94,20.43c0.09,1.9,0.31,3.79,0.57,6.93c8.21-6.89,9.5-15.94,12.89-23.9
                c-1.07,8.65-3.56,16.83-8.02,24.22c-5.33,8.84-7.87,18.2-7.35,28.51c1.65,32.22,3.02,64.45,4.85,96.65
                c1.46,25.73,3.3,51.45,5.19,77.15c1.44,19.57,3.12,39.12,4.95,58.65c0.38,4.09,1.64,8.17,2.97,12.09
                c1.54,4.53-0.17,7.26-4.78,9.85c-2.43-5.83-5.59-11.24-6.94-17.07c-5.16-22.35-10.41-44.72-14.22-67.33
                c-1.66-9.83,0.35-20.24,0.24-30.38c-0.42-37.62-1.29-75.23-1.46-112.85c-0.07-15.46,1.07-30.93,1.96-46.38
                c0.43-7.53-0.73-14.51-4.24-21.22c-1.76-3.36-2.84-7.08-4.23-10.63L156.42,701.12z"/>
            <path onClick={() => props.onFill(60)} fill={props.fillColors[60]} d="M256.28,457.35c-4.86,0-9.72,0.13-14.57-0.04c-3.91-0.14-5.77,1.93-5.24,5.9
                c0.66,5.03,3.15,8.87,6.79,12.54c-2.43,3.37-0.57,5.24,3.78,6.06c-1.67,1.48-3.43,2.57-4.53,4.13c-1.32,1.88-2.08,4.16-3.08,6.26
                c2.49,1.1,5.11,3.33,7.45,3.07c6.14-0.7,12.15-2.49,19.07-4.04c1.57,2.37,4.45,4.4,8.4,0.87c0.12-0.11,0.31-0.22,0.44-0.21
                c6.56,0.83,13.41,0.79,19.57,2.83c4.54,1.5,7.96,2.12,11.62-1.01c-0.19,3.52-1.45,4.15-5.15,6.29
                c-7.54,4.36-14.61,9.52-22.1,13.97c-3.82,2.28-8.12,3.76-12.22,5.58c-5.41,2.4-10.87,4.71-16.24,7.2
                c-3.49,1.62-3.8,4.13-0.57,6.11c2.76,1.69,5.96,2.68,9.55,4.24c-2.46,1.21-4.43,2.17-6.61,3.24c2.46,5.45,7.26,4.14,12.22,4.42
                c-7.13,11.62-11.17,24.36-23.71,30.79c-4.44,2.27-8.73,4.84-13.2,7.06c-4.13,2.05-5.52,1.19-5.36-3.49
                c0.24-7.14,0.58-14.3,1.32-21.4c2.83-26.99,4.68-53.99,3.74-81.16c-0.15-4.28,1.21-8.8,2.72-12.89c1.75-4.75,5.63-7.07,10.94-7.17
                c2.59-0.05,5.12-1.38,7.74-1.72c2.4-0.31,4.86-0.07,7.3-0.07C256.35,455.57,256.31,456.46,256.28,457.35z"/>
            <path onClick={() => props.onFill(61)} fill={props.fillColors[61]} d="M1036.62,936.92c1.12-1.16,2.98-2.12,0.1-3.64c-1.01-0.53-1.36-2.29-2.02-3.49
                c0.43-0.35,0.86-0.69,1.29-1.04c1.1,0.93,2.51,1.66,3.24,2.82c9.74,15.32,18.99,30.91,24.88,48.22c0.58,1.71,0.98,3.54,1.07,5.34
                c1.35,25.41,2.64,50.82,3.92,76.23c0.1,1.93,0.01,3.86,0.01,5.79c-0.42,0.25-0.85,0.5-1.27,0.75c-1.96-2.03-4.17-3.87-5.83-6.11
                c-10.09-13.63-18.74-28.63-30.41-40.73c-13.74-14.25-23.35-31.43-36.52-45.91c-3.91-4.3-8.27-8.18-12.42-12.25l-0.01,0.01
                c3.99,1.97,7.9,4.15,12.01,5.8c1.85,0.74,4.12,0.45,7.93,0.77c-2.46-3.49-4.04-5.74-5.97-8.47c24.53,6.3,45.43,18.32,63.97,34.78
                c0.34-0.46,0.69-0.91,1.03-1.37c-2.49-2.25-5-4.48-7.47-6.76c-1.64-1.51-3.99-2.07-2.28-5.77c1.13-2.45-0.82-6.33-1.47-10.06
                c-1-0.37-2.5-0.93-4.33-1.61c1.08-1.27,2-2.35,3.25-3.8c-6.87-3.09-1.19-6.5,0.19-11.01c-4.42,0.86-7.97,1.56-12.63,2.47
                c3.29-3.83,8.51-6.57,5.3-11.26c-1.18-1.72-5.62-1.21-8.57-1.72c0,0,0.03,0.03,0.03,0.03c1.77-1.09,3.54-2.19,5.76-3.56
                C1038.38,939.72,1037.5,938.32,1036.62,936.92L1036.62,936.92z"/>
            <path onClick={() => props.onFill(62)} fill={props.fillColors[62]} d="M816.82,503.23c-7.75-2.47-15.29-5.01-23.85-3.57c-3.9,0.66-8.32-1.71-12.49-2.74
                c-9.37-2.3-18.77-4.45-28.08-6.97c-14.62-3.96-26.64-12.06-35.89-23.99c-4.12-5.31-7.3-11.35-11.15-16.89
                c-4.35-6.26-8.46-12.87-16.37-15.43c-0.9-0.29-1.66-1.07-2.41-1.71c-3.61-3.13-7.32-6.15-10.71-9.5c-1.34-1.33-1.95-3.4-2.9-5.14
                l-0.03,0.03c1.86-0.21,4.04-1.17,5.54-0.53c22.85,9.83,45.99,19.1,68.3,30.06C774.19,460.33,797.84,479.01,816.82,503.23
                L816.82,503.23z"/>
            <path onClick={() => props.onFill(63)} fill={props.fillColors[63]} d="M1161.15,561.73c-6.22-1.84-9.09-4.8-9.52-11.05c-0.6-8.76-2.24-17.49-4.15-26.08
                c-0.57-2.57-3.58-4.56-5.32-6.93c-0.69-0.95-1.37-2.2-1.37-3.31c0.01-11.63,0.13-23.26,0.28-34.88
                c0.25-18.43,0.69-36.86,0.77-55.3c0.03-6.39-0.08-12.51,3.58-18.27c1.37-2.17,1.35-5.42,1.4-8.19
                c0.38-19.98,0.61-39.96,0.89-59.93c0.02-1.62,0-3.25,0-4.64c3.01,2.51,5.96,4.77,8.64,7.3c1.13,1.06,2.19,2.64,2.43,4.12
                c0.97,5.9,2.24,11.85,2.26,17.78c0.16,64.78,0.1,129.56,0.1,194.35C1161.15,558.17,1161.15,559.65,1161.15,561.73z"/>
            <path onClick={() => props.onFill(64)} fill={props.fillColors[64]} d="M22.59,484.23c-3-0.27-4.87-1.06-6.77-4.3c-2.44-4.15-7.5-6.38-13.37-6.24c0-11.99,0-24.08,0-35.35
                c6.98-0.91,13.38-1.56,19.69-2.65c4.5-0.78,7.28,1.31,9.34,4.74c1.5,2.51,3.12,3.34,6.13,2.65c3.67-0.84,7.55-0.7,11.25-1.41
                c1.72-0.33,3.97-1.29,4.71-2.65c1.84-3.41,4.29-3.78,7.51-2.82c0.95,0.28,2.22,1.05,2.84,0.69c5-2.88,10.76-1.1,15.49-2.29
                c6.05-1.52,10.1,1.08,14.58,2.73c4.28,1.58,8.26,4.56,11.69,7.67c1.93,1.76,2.5,5.03,3.87,8.05c-3.26,2.94-6.9,5.03-11.68,4.96
                c-1.11-0.02-2.23,0.83-3.34,1.28c-2.6,1.05-5.25,1.99-7.77,3.2c-2.88,1.38-5.66,2.99-8.47,4.53c-1.06,0.58-2.65,2.02-3.02,1.74
                c-2.9-2.17-5.35-0.32-7.86,0.6c-4.37,1.6-4.62,2.35-2.59,7.52c-13.06,0.91-25.94,1.81-38.81,2.71c-0.32-0.62-0.65-1.25-0.97-1.87
                c-0.83,2.18-1.66,4.36-2.48,6.55C22.55,484.27,22.59,484.23,22.59,484.23z"/>
            <path onClick={() => props.onFill(65)} fill={props.fillColors[65]} d="M35.88,788.4c-0.83-3.78-1.54-6.5-2-9.27c-0.46-2.76-0.77-5.55-0.95-8.35c-0.27-4.17-1.97-6.48-6.56-6.23
                c-1.32,0.07-3.08-0.73-4-1.72c-2.69-2.9-4.93-6.2-7.58-9.13c-1.82-2.01-3.75-3.3-6.96-1.62c-3.32,1.74-5.78-0.13-5.83-4.1
                c-0.13-10.82-0.17-21.64,0.1-32.45c0.03-1.25,2.11-3.29,3.43-3.46c1.63-0.2,3.55,1,5.16,1.93c1.6,0.92,3,3.29,4.42,3.24
                c8.1-0.28,15.03,2.9,21.31,7.23c3.37,2.33,6.46,5.94,8.14,9.67c3.04,6.75,6.38,13.03,12.15,17.81c0.49,0.4,0.98,1.05,1.06,1.64
                c0.77,5.64,1.44,11.3,2.16,16.95c0.17,1.3-0.03,3.15,0.74,3.79c6.06,5.05,5.68,12.89,8.67,19.24c0.88,1.86,2,4.87,4.55,2.43
                c3.52-3.36,6.5-1.17,8.83,0.66c5.08,4,9.82,8.49,14.31,13.15c1.77,1.84,2.32,4.81,3.75,7.05c1.89,2.94,3.57,6.42,6.29,8.34
                c7.02,4.97,1.52,11.09,3.43,17.05c-5.76-0.81-10.75-0.42-10.11-7.66c0.11-1.2-2.06-3.49-3.47-3.77c-3.71-0.74-4.88-2.81-5.13-6.23
                c-0.76-10.52-2.35-12.21-12.74-11.79c-6.73,0.27-8.47-5.57-12.07-8.96c-2.68-2.52-3.14-7.63-8.7-6.29
                c-0.56,0.14-1.65-1.05-2.2-1.83c-4.53-6.37-10.49-8.93-18.22-7.16C36.98,788.79,35.97,788.41,35.88,788.4z"/>
            <path onClick={() => props.onFill(66)} fill={props.fillColors[66]} d="M915.91,296.62c-4.73-2.91-10.18-2.55-12.16-8.99c-1.7-5.51-7.17-6.19-11.42-2.77
                c-0.92,0.74-2.42,1.17-3.6,1.07c-4.89-0.41-9.54-5.8-10.89-10.44c-1.07-3.67-3.71-6.88-5.9-10.72c-4.82,5.7-6.3,5.86-11.7,2.32
                c-7.44-4.89-8.75-8.72-6.49-17.14c5.59-20.84,12.63-41.37,13.29-63.35c0.75,0.26,1.2,0.28,1.28,0.46
                c7.03,15.45,17.1,28.83,27.88,41.81c3.24,3.9,5.74,8.61,7.69,13.32c4.3,10.41,8.93,20.83,11.69,31.69
                C917.32,280.71,915.91,288.33,915.91,296.62z"/>
            <path onClick={() => props.onFill(67)} fill={props.fillColors[67]} d="M1129.51,210.49c1.53,2.56,2.86,5.27,4.65,7.64c1.96,2.6,3.51,1.95,5.92-0.39
                c9.35-9.08,6.15-19.97,5.53-30.76c-0.93-16.1-1.27-32.24-1.6-48.36c-0.06-2.74,1.18-5.52,1.82-8.28c0.47,0,0.94,0,1.4,0
                c1.87,3.34,3.74,6.69,5.62,10.03c0.55-0.01,1.1-0.02,1.66-0.03c0.43-2.24,1.15-4.46,1.25-6.72c0.46-11.14,0.58-22.29,1.21-33.41
                c0.22-3.99,1.53-7.92,2.34-11.88c0.49,0.08,0.97,0.16,1.46,0.24c0,52.63,0,105.26,0,157.88c0.17-0.02,0.34-0.04,0.51-0.06
                c-0.93-3.22-1.86-6.44-2.79-9.66c-0.52,0.02-1.04,0.04-1.57,0.05c-5.55,18.3-4.68,38.19-12.59,56.79
                c-1.61-2.81-2.72-4.74-4.48-7.81c-2.23,5-3.98,8.93-5.73,12.85c-0.51-0.05-1.03-0.11-1.54-0.16c-1-29.33-2.01-58.67-3.01-88
                C1129.54,210.46,1129.51,210.49,1129.51,210.49z"/>
            <path onClick={() => props.onFill(68)} fill={props.fillColors[68]} d="M546.06,283.42c5.24,3.11,10.63,4.25,16.48,1.6c1.07-0.49,2.51-0.39,3.75-0.26
                c3.16,0.32,6.31,0.8,9.77,1.25c-1.99,7.55,4.77,10.36,9.82,14.3c1.28,1,2.74,1.86,3.73,3.09c6.85,8.52,13.74,17.01,20.35,25.71
                c12.33,16.21,25.43,31.94,33.02,51.22l0.02-0.02c-3.87-1.32-6.56,0.78-8.55,3.53c-1.69,2.34-3.24,2.72-5.86,1.75
                c-21.08-7.87-36.92-20.88-45.51-42.53c-3.35-8.43-8.92-16.41-15-23.23c-9.7-10.88-17.4-22.63-21.99-36.44L546.06,283.42z"/>
            <path onClick={() => props.onFill(69)} fill={props.fillColors[69]} d="M99.81,587.47c3.27-4.87,5.15-10.62,12.49-9.62c0.83,0.11,1.77-0.78,2.68-1.17
                c7.01-2.97,14.14-4.85,21.82-2.82c0.97,0.26,2.18-0.46,3.29-0.61c1.82-0.24,3.64-0.46,5.47-0.54c0.96-0.04,2.37,0.72,2.82,0.3
                c4.62-4.24,10.19-2.18,14.98-1.6c11.56,1.38,22.52,5,30.9,13.84c0.69,0.73,0.57,2.15,1.07,3.13c1.37,2.71,2.14,5.33,6.63,5.19
                c3.81-0.13,7.7,2.36,11.95,3.82c-1.48,2.83,3.22,6.24-1.15,9.05c-2.86,1.84-5.47,4.06-8.35,5.85c-2.21,1.37-4.57,2.75-7.05,3.33
                c-1.67,0.39-3.66-0.69-5.52-0.95c-1.57-0.22-3.31-0.65-4.74-0.21c-2.32,0.7-4.47,1.97-6.64,3.11c-2.53,1.34-4.74-0.18-7.01-0.35
                c-6.53-0.48-11.74-3.25-17.41-5.85c-2.89-1.32-5.5-3.08-8.17-4.24c-8.22-3.59-14.55-11.9-24.38-11.18
                c-7.29,0.53-12.7-2.21-17.88-6.72C104.33,588.1,102.07,588.11,99.81,587.47z"/>
            <path onClick={() => props.onFill(70)} fill={props.fillColors[70]} d="M1027.62,1166.76c-8.42,10.86-18.74,20.28-25.38,32.66c11.23-3.25,18.36-11.91,27.23-18.54
                c-1.87,2.65-3.69,5.34-5.63,7.94c-1.98,2.67-4.11,5.23-6.11,7.88c-2.4,3.18-4.93,6.28-7,9.67c-0.84,1.38-0.62,3.41-0.89,5.15
                c0,0-0.07,0.12-0.07,0.12c-3.1-2.45-6.12-5.02-9.33-7.33c-4.14-2.98-8.48-5.69-12.65-8.64c-1.33-0.94-2.39-2.27-3.55-3.45
                c-3.33-3.4-6.41-7.08-10.01-10.16c-6.86-5.89-13.92-11.56-21.08-17.07c-2.04-1.57-4.64-2.58-7.13-3.38
                c-3.74-1.2-5.22-3.42-5.11-7.39c0.39-13.63,1.28-27.3,0.59-40.89c-1.23-24.38-3.47-48.71-5.26-73.07
                c-0.1-1.41-0.01-2.84-0.01-3.73c2.62,1.42,5.38,2.61,7.72,4.35c0.94,0.7,1.3,2.62,1.36,4c0.75,16.1,1.39,32.2,2.05,48.3
                c0.26,6.31,0.26,12.64,0.83,18.92c0.93,10.29,4.93,19.15,12.87,26.12c1.33,1.16,1.74,3.44,2.42,5.25
                c1.57,4.23,3.05,8.49,5.44,12.56c5.05-10.33,2.19-20.85,2.37-31.27c0.73,3.41,1.4,6.84,2.21,10.24
                c1.93,8.06,5.02,15.44,11.44,21.15c3.1,2.76,8.11,5.02,3.04,11.71c12.61-2.46,21.13-9.36,30.65-14.07l-0.04-0.04
                c-5.83,6.35-11.67,12.7-17.49,19.06c-3.03,3.31-6.15,6.54-8.98,10.01c-1.18,1.45-1.71,3.43-3.21,6.59
                c3.13-0.84,5.1-0.92,6.56-1.83c8.99-5.64,17.87-11.45,26.78-17.22c1.81-1.17,3.58-2.4,5.37-3.6L1027.62,1166.76z"/>
            <path onClick={() => props.onFill(71)} fill={props.fillColors[71]} d="M949.69,174.47c-1.12,16.91-2.38,33.81-3.33,50.73c-0.65,11.57-6.25,21.12-12.26,31.01
                c-17.45-36.17-32.74-72.85-33.68-114.08c5.48-1.67,7.69,2.84,10.77,5.44c3.61,3.05,6.82,6.66,10.71,9.27
                c1.41,0.95,4.38,0.04,6.46-0.6c4.01-1.23,7.63,1.09,7.38,5.07c-0.52,8.32,5.16,13.66,13.99,13.17L949.69,174.47z"/>
            <path onClick={() => props.onFill(72)} fill={props.fillColors[72]} d="M949.69,504.25c2.14,1.54,4.56,2.81,6.36,4.69c3.29,3.42,5.61,8.2,12.29,6.44
                c-2.77,50.74-8.74,100.49-20.44,149.72c-1.95-15.76-4.69-31.46-5.67-47.28c-1.66-26.71-2.11-53.49-3.29-80.23
                c-0.53-12.11-1.6-24.2-2.42-36.26c2.5,2.62,4.4,5.6,7.11,7.24c4.05,2.45,6.19,0.68,6.09-4.34L949.69,504.25z"/>
            <path onClick={() => props.onFill(73)} fill={props.fillColors[73]} d="M741,983.15c0.23-2.9,0.36-5.82,0.76-8.7c0.11-0.82,0.82-1.85,1.54-2.23c2.68-1.4,2.66-3.07,0.92-5.18
                c-0.41-0.5-1.03-1.18-0.95-1.69c0.86-5.56,1.85-11.11,3.38-16.91c2.2,4.43,4.89,6.3,9.32,3.72c5.26-3.06,7.5-7.31,5.19-11.78
                c-2.34-4.52,0.87-6.96,3.12-9.26c2.32-2.38,5.76-3.65,8.61-5.53c1.07-0.71,1.96-1.74,2.82-2.72c0.53-0.61,1.13-2.05,1.23-2.01
                c6.92,2.89,8.62-5.59,13.99-6.36c5.15-0.74,12.45,3.03,14,8.05c0.2,0.63,0.11,1.53,0.52,1.9c5.79,5.17,2.41,11.3,1.77,17.07
                c-0.72,6.43-1.84,12.82-2.75,19.23c-0.7,4.95-2.58,7.57-8.2,9.42c-6.67,2.19-12.89,3.34-19.72,2.63
                c-1.31-0.14-3.19,0.41-4.03,1.34c-5.54,6.09-13.52,9.89-16.89,18.11c-0.47,1.14-3.54,2.43-4.45,1.93
                c-2.27-1.27-4.17-3.37-5.86-5.44c-1.36-1.66-2.18-3.77-3.23-5.67C741.71,983.08,741.35,983.12,741,983.15z"/>
            <path onClick={() => props.onFill(74)} fill={props.fillColors[74]} d="M799.85,551.2c-1.31-10.83-2.25-21.73-4.07-32.48c-1.01-5.98-3-6.02-8.68-4.25
                c-5.83,1.82-8.17,5.75-9.31,11.07c-0.92,4.31-1.77,8.63-2.52,12.32c-2.03-5.34-3.94-11.31-6.59-16.92
                c-1.08-2.29-3.77-3.83-5.73-5.71c-1.49,2-3.28,3.84-4.37,6.03c-1.08,2.18-1.46,4.71-2.45,7.16c1.65-8.42-0.97-15.43-7.41-22.64
                c-2.81,5.47-5.3,10.32-7.41,14.42c0-0.06-0.14-1.39-0.2-2.73c-0.07-1.5,0.36-3.18-0.19-4.45c-1.04-2.43-2.27-5.04-4.18-6.7
                c-0.66-0.57-3.53,1.64-5.49,2.26c-1.35,0.43-3.17-0.14-4.25,0.56c-1.11,0.72-1.57,2.44-2.48,4.03c-0.86-1.05-1.65-2.02-3.03-3.72
                c-1.32,9.21-2.51,17.52-3.34,23.3c0-5.17,0-12.82,0-20.47c0-1.5,0.23-3.07-0.13-4.48c-0.59-2.28-1.58-4.46-2.4-6.69
                c-1.66,1.41-3.32,2.81-5.13,4.34c-0.32-4.12-1.46-8.37-7.08-8.69c-1.17-0.07-3.08,0.2-3.45,0.92c-1.88,3.73-4.01,7.53-4.83,11.55
                c-0.94,4.61-0.6,9.48-1.74,14.23c-0.39-4.73-0.85-9.46-1.16-14.19c-0.24-3.75-1.14-6.46-5.88-5.65c-1,0.17-2.25-1.09-4.19-2.11
                c0,7.03,0,13.42,0,19.81c-0.44,0.03-0.87,0.05-1.31,0.08c-0.83-7.79-1.63-15.59-2.5-23.38c-0.66-5.95-1.62-11.87-2.02-17.83
                c-0.38-5.57,1.39-6.76,6.44-4.74c5.1,2.04,10.23,4.06,15.12,6.53c9.06,4.57,18.61,6.88,28.68,7.59c2.36,0.17,4.87,1.14,6.91,2.4
                c6.2,3.82,12.57,6.39,20.09,6.46c6.86,0.06,13.5,2.29,20.69,0.63c3.53-0.82,7.83,2.2,11.88,2.97c3.13,0.6,6.55,1.08,9.59,0.44
                c8.04-1.7,15.03,1.56,22.16,4.03c1.28,0.45,2.5,3.24,2.47,4.93c-0.09,5.96-0.74,11.91-1.25,18.79c-2.73-3.99-4.82-4.64-6.57-0.54
                c-0.71-0.88-1.43-1.77-2.71-3.36c-1.06,2.81-1.85,4.9-2.63,6.98c-0.25,0-0.49,0.01-0.74,0.01c0-4.3,0-8.61,0-12.91
                c-0.51-0.03-1.03-0.06-1.54-0.1c-3.85,9.87-2.87,20.67-5.11,30.85L799.85,551.2z M805.81,511.26c-0.31-1.02-0.61-2.04-0.92-3.06
                c-0.37,0.23-0.75,0.46-1.12,0.69c0.7,0.77,1.4,1.54,2.09,2.31c0.63,0.38,1.26,0.77,1.9,1.15c0-1.82,0-3.65,0-5.47
                c-0.31,0.05-0.61,0.11-0.92,0.16C806.5,508.44,806.16,509.85,805.81,511.26z M802.07,509.98c-0.27-0.04-0.54-0.08-0.82-0.12
                c-0.07,0.71-0.13,1.42-0.2,2.13c0.26,0.01,0.75,0.05,0.76,0.01C801.93,511.33,801.99,510.65,802.07,509.98z"/>
            <path onClick={() => props.onFill(75)} fill={props.fillColors[75]} d="M1.94,673.98c0-2.83-0.02-5.66,0-8.48c0.03-5.06,1.57-6.41,6.64-5.52c2.09,0.37,4.43,0.67,6.11,1.81
                c4.15,2.81,8.53,1.95,12.27,0.27c3.19-1.43,5.04-0.9,7.59,0.87c16.67,11.54,33.46,23.17,54.26,25.67
                c9.45,1.14,16.92,4.04,21.95,12.59c1.79,3.04,5.04,5.27,7.79,7.67c5.2,4.53,7.77,18.01,4.25,23.76c-1.56,2.55-3.14,2.53-5.44,0.16
                c-6.52-6.69-13.37-13.06-20.14-19.5c-0.57-0.54-1.49-1.09-2.22-1.05c-4.86,0.28-9.01-1.9-13.46-3.26
                c-4.81-1.47-9.67-2.08-12.86-6.62c-0.69-0.98-2.16-1.52-3.38-1.99c-4.42-1.72-8.86-3.4-13.35-4.93c-3.07-1.04-6.24-1.78-9.35-2.69
                c-0.94-0.27-2.03-0.43-2.73-1.02c-6.84-5.77-14-5.87-22.06-2.41c-3.42,1.47-7.82,1.41-11.62,0.89c-1.61-0.22-3.66-3.23-4.03-5.25
                c-0.66-3.54-0.18-7.29-0.18-10.95C1.96,673.98,1.95,673.98,1.94,673.98z"/>
            <path onClick={() => props.onFill(76)} fill={props.fillColors[76]} d="M88.4,1036.56c3.86-11.63,7.37-23.39,11.63-34.87c11.34-30.5,18.03-62,19.71-94.4
                c1.9-36.55,2.76-73.15,4.1-109.73c0.27-7.48,0.61-14.96,1.09-22.43c0.1-1.58,0.98-3.11,1.5-4.66c0.43,0.07,0.87,0.14,1.3,0.2
                c0.14,1.39,0.42,2.77,0.4,4.16c-0.3,23.79-0.46,47.59-1.12,71.38c-0.13,4.67-2.63,9.22-3.37,13.92
                c-2.1,13.35-2.83,26.75,0.12,40.12c0.21,0.97,0.46,1.98,0.4,2.95c-0.87,13.76-0.04,27.93-3.13,41.18
                c-4.18,17.9-2.68,36.07-5.55,53.92c-0.34,2.14,0.84,4.52,1.31,6.79c0.52,0.04,1.03,0.07,1.55,0.11c0.76-1.37,1.56-2.71,2.26-4.11
                c0.67-1.33,0.87-3.33,1.93-3.97c6.17-3.79,5.1-9.83,5.32-15.55c0.64-16.45,1.03-32.93,2.15-49.35c0.62-9.02,2.54-17.96,3.26-22.83
                c0.49,10.15,0.98,24.59,1.92,39c1.01,15.59,2.5,31.15,3.62,46.73c0.47,6.47,0.69,12.96,0.68,19.45c0,1.24-1.47,2.59-2.5,3.66
                c-0.5,0.52-1.53,0.62-2.34,0.76c-16.24,2.85-31.57,7.97-44.67,18.61C89.45,1037.25,88.92,1036.9,88.4,1036.56z"/>
            <path onClick={() => props.onFill(77)} fill={props.fillColors[77]} d="M340.15,301.33c-6-3.14-11.26-6.06-16.67-8.65c-2.28-1.09-4.9-1.44-7.36-2.18
                c-1.87-0.56-3.72-1.18-5.57-1.78c-2.02-0.66-4-1.48-6.06-1.99c-5.78-1.42-11.57-2.83-17.4-3.99c-2.04-0.4-4.22-0.07-6.34-0.05
                c-3.5,0.04-6-1.03-5.39-5.17c0.8-5.42,1.77-10.81,2.59-16.23c0.64-4.26,1.87-8.59,1.58-12.8c-0.37-5.26,3.24-6.95,6.39-9.28
                c0.57-0.42,1.67-0.44,2.43-0.25c8.91,2.23,17.77,4.64,26.7,6.75c2.86,0.68,6.21-0.08,8.76,1.08c3.04,1.39,5.79,3.84,8.07,6.37
                c2.29,2.54,3.28,6.46,5.85,8.51c9.12,7.24,7.94,16.88,6.7,26.43C343.9,292.12,341.94,295.97,340.15,301.33z"/>
            <path onClick={() => props.onFill(78)} fill={props.fillColors[78]} d="M42.38,1027.64c-13-1.09-24.58-2.1-36.17-3.01c-2.8-0.22-4.29-1.31-4.26-4.28c0.06-7.65,0.05-15.3,0-22.95
                c-0.02-2.93,1.25-4.18,4.22-4.17c14.41,0.03,28.83-0.08,43.24-0.04c2.31,0.01,4.72,0.17,6.9,0.85c11.39,3.6,22.86,2.29,34.45,1.33
                c7.46-0.62,7.95,0.63,5.43,7.53c-0.69,1.87-1.65,3.71-1.94,5.65c-1.26,8.64-6.37,12.7-14.83,13.76
                C66.58,1023.91,53.81,1025.97,42.38,1027.64z"/>
            <path onClick={() => props.onFill(79)} fill={props.fillColors[79]} d="M192.85,997.59c-1.35-7.39-3.41-14.72-3.94-22.17c-2.33-32.32-4.21-64.67-6.28-97
                c-0.23-3.58-0.6-7.15-0.91-10.84c6.6,0.74,7.65-0.33,5.49-6.39c-1.52-4.26-4.05-7.87-1.84-12.94c0.66-1.51-2.38-4.61-3.69-7
                c-0.83-1.51-1.62-3.04-2.43-4.57c1.48-0.47,2.93-1.12,4.45-1.37c2.1-0.35,4.33-0.85,6.37-0.5c10.26,1.8,12.2,4.64,12.45,14.85
                c0.61,24.92,1.59,49.83,2.32,74.74c0.57,19.64,0.33,39.26-3.9,58.55c-1.12,5.13-3.62,9.96-5.49,14.93
                C194.58,997.79,193.71,997.69,192.85,997.59z"/>
            <path onClick={() => props.onFill(80)} fill={props.fillColors[80]} d="M953.7,1021.84c1.59-2.27,3.18-4.53,4.77-6.8c-0.29-0.4-0.58-0.8-0.87-1.2
                c-4.56,2.49-9.12,4.98-13.67,7.47c-0.19-0.25-0.37-0.51-0.56-0.76c2.73-2.61,5.46-5.21,8.2-7.82c-0.2-0.63-0.4-1.26-0.6-1.89
                c-4.75,0-9.49,0-14.24,0l0.04,0.04c-0.67-1.35-1.33-2.7-2-4.06l-0.04,0.04c17.88,3.57,35.85,6.39,52.74,14.15
                c11.52,5.29,22.08,11.79,32.49,18.87c11.35,7.72,23.35,14.48,34.97,21.8c4.06,2.55,8.09,5.25,11.68,8.4
                c1.5,1.31,2.37,3.92,2.56,6.02c0.61,6.77,0.95,13.57,0.99,20.37c0.02,2.51-0.78,5.15-1.74,7.51c-2.45,5.99-5.2,11.85-7.82,17.77
                c0,0,0.02,0.02,0.02,0.02c2.47-8.81,4.94-17.62,7.41-26.44c-0.56-0.23-1.12-0.45-1.69-0.68c-5.58,9.69-11.16,19.38-16.75,29.08
                l0.04,0.04c3.29-7.44,6.76-14.81,9.81-22.36c1.83-4.52,2.98-9.32,3.6-14.41c-10.61,9.42-14.31,23.48-23.66,33.66
                c6.06-12.92,14.87-24.63,17.42-39.04c-0.47-0.32-0.93-0.65-1.4-0.97c-3.56,5.16-7.23,10.25-10.66,15.5
                c-3.5,5.36-6.77,10.86-10.93,15.83c5.81-12.68,11.63-25.36,17.44-38.04c-0.59-0.36-1.18-0.72-1.76-1.08
                c-7.36,10.43-14.73,20.87-22.09,31.3c-0.34-0.2-0.69-0.4-1.03-0.6c5.66-10.96,11.33-21.92,16.99-32.88
                c-0.64-0.42-1.29-0.84-1.93-1.25c-6.02,8.21-12.03,16.41-18.79,25.63c0.9-12.5,8.71-20.7,13.56-30.14
                c-0.62-0.38-1.23-0.77-1.85-1.15c-4.78,6.73-9.57,13.46-14.35,20.19c-0.43-0.23-0.87-0.47-1.3-0.7
                c4.62-8.42,9.25-16.84,13.87-25.26c-9.07,4-12.45,12.81-17.89,19.81l0,0c2.82-6.12,5.63-12.24,8.45-18.36
                c1.11-2.42,2.23-4.85,2.56-7.68c-8.78,5.8-10.57,16.13-16.01,24.03l0.02,0.02c3.11-7.72,6.22-15.44,9.33-23.16
                c-0.27-0.28-0.54-0.56-0.81-0.84c-1.95,2.29-4.06,4.47-5.79,6.91c-1.89,2.68-3.43,5.61-6.14,8c1.28-3.02,2.41-6.13,3.89-9.05
                c1.45-2.85,3.26-5.52,4.91-8.28c-0.39-0.33-0.77-0.66-1.15-0.99c-1.42,1.12-2.84,2.25-4.26,3.37c0,0,0.05,0.04,0.05,0.04
                c0.21-0.93,0.41-1.87,0.62-2.8c-0.39-0.27-0.77-0.55-1.16-0.82c-2.85,3.8-5.7,7.6-8.55,11.4c-0.22-0.06-0.44-0.12-0.66-0.18
                c1.49-5.33,2.97-10.67,4.46-16c-0.7-0.35-1.4-0.7-2.1-1.05c-2.54,3.81-5.09,7.62-7.63,11.43c0,0,0.02,0.02,0.02,0.02
                c1.94-5.43,3.89-10.87,5.83-16.3c-0.49-0.31-0.98-0.63-1.47-0.94c-2.8,3.41-5.59,6.81-8.39,10.22c0,0,0.03,0.03,0.03,0.03
                c1.84-4.1,3.67-8.21,5.51-12.31c-0.49-0.3-0.98-0.6-1.46-0.9c-3.01,3.99-6.02,7.98-9.02,11.97c-0.3-0.17-0.61-0.35-0.91-0.52
                c3.25-5.2,6.5-10.41,9.75-15.61c-0.38-0.31-0.75-0.62-1.13-0.94c-6.35,3.23-9.04,10.11-14.4,14.72
                c2.66-5.06,5.33-10.11,7.99-15.17c-6.57,1.71-8.91,7.76-13.07,11.86c-0.35-0.23-0.7-0.45-1.06-0.68
                c3.07-4.79,6.14-9.58,9.84-15.36c-7.27,2.57-9.54,9-14.04,12.92l0.03,0.03c2.65-4.98,5.3-9.95,8.49-15.93
                c-8.43,1.54-10.99,8.42-15.5,12.9l0.02,0.02c2.51-4.62,5.02-9.25,7.52-13.87c-0.37-0.4-0.75-0.81-1.12-1.21
                c-2.47,2.03-4.93,4.07-7.4,6.1l-0.02-0.02c1.01-2.15,2.03-4.31,3.04-6.46c-0.36-0.43-0.72-0.87-1.08-1.3
                c-2.98,1.59-5.96,3.18-8.94,4.77L953.7,1021.84z"/>
            <path onClick={() => props.onFill(81)} fill={props.fillColors[81]} d="M1081.46,22.29c-1.37,43.83-2.7,86.29-4.03,128.74c-0.53,0.02-1.05,0.03-1.58,0.05
                c0-3.87,0.47-7.81-0.13-11.57c-0.58-3.63-1.71-7.42-3.6-10.53c-3.52-5.8-7.81-11.12-11.64-16.74c-0.67-0.98-0.78-2.42-0.9-3.68
                c-0.68-7.24-6.59-13.86-13.71-15.23c-7.11-1.38-7.58-2.33-6.78-9.17c2.66-22.85,15.81-39.19,32.63-53.33
                C1074.63,28.38,1077.44,25.82,1081.46,22.29z"/>
            <path onClick={() => props.onFill(82)} fill={props.fillColors[82]} d="M199.78,590.42c-3.27-11.23-19.8-18.84-30.56-22.02c3.04-1.27,6.19-2.34,9.08-3.89
                c1.45-0.78,3.4-2.39,3.46-3.7c0.32-6.83-5.92-12.4-12.68-11.87c-0.95,0.08-2.3,0.18-2.85-0.36c-4.66-4.53-10.26-3.11-15.58-2.4
                c-4.01,0.53-5.42-1.19-5.02-4.62c0.88-7.56,0.85-15.53,3.4-22.53c4.56-12.54,10.74-24.48,16.38-36.99
                c7.84,14.55,15.4,29.12,23.47,43.4c9.91,17.52,13.69,36.23,12.18,56.14C200.86,584.14,200.33,586.67,199.78,590.42z"/>
            <path onClick={() => props.onFill(83)} fill={props.fillColors[83]} d="M537.85,428.36c-2.61-19.75-5.59-42.37-8.57-65c-0.93,0-1.87,0-2.8,0c-1,7.96-2,15.93-2.93,23.33
                c-2.39-12.25-1.11-25.81-6.51-39.16c-1.73,1.86-2.68,2.88-3.62,3.9c-2.95-0.81-0.51-27.44,3.41-32.5c1.54,2.28,2.65,4.7,4.45,6.39
                c2.27,2.13,4.91,4.13,7.75,5.33c4.49,1.89,9.53,2.57,13.86,4.73c2.41,1.2,4.18,4.15,5.64,6.66c1.94,3.34,3.29,7.03,6.02,10.21
                c-0.41-2.29-0.81-4.58-1.22-6.86c0.31-0.41,0.62-0.83,0.93-1.24c4.7,2.94,9.41,5.88,14.26,8.91c0.73-0.47,1.24-0.87,1.81-1.13
                c1.37-0.62,3.61-2,3.99-1.6c1.13,1.17,2.55,3.48,2.08,4.57c-6.32,14.58-2.12,30.4-5.75,44.36c-0.38-2.98-0.64-6.92-1.45-10.75
                c-0.79-3.75-2.15-7.37-3.27-11.05c-0.82,0.01-1.64,0.02-2.46,0.03c-0.79,3.45-1.57,6.9-2.46,10.79c-0.92-2.87-1.77-5.54-2.62-8.21
                c-0.47-0.11-0.95-0.22-1.42-0.33c-0.64,2.09-1.77,4.18-1.82,6.29c-0.2,9.47-0.09,18.94-0.43,28.43
                c0.22-13.7-2.42-26.89-6.04-39.91c-0.55-0.01-1.09-0.03-1.64-0.04c-0.55,3.93-1.09,7.86-1.66,11.97c-0.43-0.39-1.02-0.92-2.01-1.8
                C538.44,399.95,537.31,415.4,537.85,428.36z"/>
            <path onClick={() => props.onFill(84)} fill={props.fillColors[84]} d="M1073.6,255.4c-9.4-2.81-11.6,0.19-10.02,14.03c-3.68-0.06-6.24,1.32-6.3,5.53
                c-0.01,0.64-0.81,1.59-1.46,1.84c-3.41,1.3-3.64,3.76-3.09,6.89c0.85,4.92,0,5.42-4.84,4.85c-2.01-0.24-4.41,0.41-6.21,1.42
                c-5.97,3.35-12.27,2.46-18.44,1.57c-2.82-0.41-3.35-7.06-0.91-10.63c1.39-2.04,3.18-3.84,4.92-5.61
                c7.68-7.83,13.43-16.96,17.48-27.07c8.79-21.92,16.22-44.26,21.42-67.38c1.36-6.06,6.23-11.33,9.49-16.96
                c0.51,0.21,1.02,0.41,1.52,0.62c0,4.59,0.15,9.19-0.03,13.78c-0.94,24.25-1.95,48.49-2.97,72.73c-0.06,1.49-0.4,2.96-0.61,4.44
                L1073.6,255.4z"/>
            <path onClick={() => props.onFill(85)} fill={props.fillColors[85]} d="M987.67,1150.76c8.81-7.59,17.62-15.19,26.43-22.78c-0.17-0.37-0.34-0.73-0.51-1.1
                c-8.47,3.65-16.93,7.29-25.4,10.94c-0.22-0.43-0.43-0.85-0.65-1.28c6.94-4.11,13.88-8.22,20.82-12.33
                c-0.23-0.53-0.47-1.05-0.7-1.58c-7.34,1.38-14.68,2.76-22.02,4.13l0.03,0.03c8.12-5.21,16.3-11.3,26.58-7.63
                c4.83,1.72,8.85,5.69,14.07,9.2c-5.16,1.73-8.95,3-12.74,4.27c0,0.56,0.01,1.11,0.01,1.67c7.14-2.22,14.4-4.95,19.01,3.47
                l0.01-0.01c-5.34,0.14-9.82,2.25-13.75,6.07c6.55-1.17,14.19-9.4,18.21,2.72c-4.8,3.94-9.53,7.83-14.26,11.71
                c0.39,0.48,0.78,0.95,1.16,1.43c2.4-2.07,4.69-4.28,7.21-6.17c6.48-4.85,8.25-4.63,13.52,1.26c1.29,1.44,2.63,2.82,4.55,4.88
                c-8.02,2.01-14.92,4.44-19.08,11.41c2.55-1.65,5.07-3.36,7.66-4.94c8.94-5.44,12.69-3.89,17.83,5.04
                c5.75,10,0.86,18.54-2.09,27.53c0,0,0.03,0.03,0.03,0.03c-0.35,0-0.71,0-1.06,0l0.04-0.03c-6.35,5.36-12.63,10.79-19.08,16.03
                c-2.9,2.36-6.11,4.32-9.18,6.47c-0.56-0.44-1.11-0.87-1.67-1.31c1.73-3.11,3.16-6.43,5.24-9.3c4.97-6.87,10.33-13.45,15.32-20.31
                c1.34-1.85,1.91-4.26,2.15-6.91c-13.16,5.44-19.99,18.77-33.69,25.28c8.56-11.63,16.39-22.27,24.22-32.91
                c-0.67-0.63-1.35-1.27-2.02-1.9c-8.79,7.26-17.58,14.52-26.37,21.79c-0.21-0.23-0.43-0.45-0.64-0.68
                c8.73-10.57,17.46-21.13,26.19-31.7c-0.54-0.58-1.08-1.16-1.63-1.74c-12.09,8.09-24.18,16.19-36.27,24.28
                c-0.43-0.53-0.87-1.05-1.3-1.58c11.07-11.8,22.14-23.61,33.21-35.41c-0.5-0.62-1-1.25-1.5-1.87c-10.5,5.75-20.99,11.5-31.49,17.25
                c-0.29-0.39-0.59-0.78-0.88-1.17c9.04-8.18,18.08-16.35,27.12-24.53c-0.38-0.62-0.75-1.24-1.13-1.86
                c-10.51,4.71-21.03,9.42-31.54,14.13L987.67,1150.76z"/>
            <path onClick={() => props.onFill(86)} fill={props.fillColors[86]} d="M114.51,687.12c1.5-0.9,3-1.81,5.15-3.1c-3.79-2.95-6.97-5.44-10.16-7.92c0,0-0.02,0.03-0.02,0.03
                c12.36,7.47,25.65,9.23,39.88,7.59c8.05-0.93,16.31-0.52,24.45-0.22c9.8,0.36,17.73,5.08,24.69,11.72
                c1.61,1.54,3.53,2.74,5.31,4.09c0.46-0.18,0.91-0.36,1.37-0.53c-0.48-10.48-0.97-20.96-1.45-31.45c0.78-0.22,1.56-0.44,2.35-0.66
                c1.28,2.23,3.53,4.4,3.67,6.7c0.57,9.44,0.46,18.91,0.67,28.37c0.08,3.81,0.39,7.61,0.47,11.41c0.15,6.62,0.08,13.24,0.32,19.85
                c0.43,11.46,0.98,22.92,1.56,34.38c0.43,8.64,1.19,17.26,1.41,25.9c0.16,6.07,0.08,11.99-2.5,17.98c-1.87,4.34-1.66,9.8-1.5,14.73
                c0.51,15.46,1.61,30.9,2.1,46.36c0.17,5.25-1.31,10.56-1.15,15.81c0.42,13.63,1.27,27.24,2.1,40.85c0.11,1.75,1.03,3.46,1.69,5.15
                c3.21,8.26,3.11,16.03-0.8,24.43c-2.93,6.3-3.95,13.65-4.91,20.66c-1.37,10.03-6.59,17.81-12.85,25.19
                c-1.07,1.27-2.48,2.3-3.34,3.68c-3.07,4.92-7.44,7.79-12.92,9.54c-2.77,0.88-5.77,2.37-7.64,4.49
                c-6.61,7.51-16.06,10.4-24.03,15.65l0.03,0.03c9.13-8.26,18.55-15.93,29.56-21.85c16.64-8.96,25.61-23.45,27.76-42.39
                c3.04-26.74,1.67-53.42,0.31-80.18c-0.97-19.08-0.8-38.26-0.38-57.38c0.65-29.42,2.07-58.81,2.88-88.23
                c0.21-7.45-0.21-14.98-1.08-22.39c-1.88-16.05-11.51-26.58-25.65-33.09c-14.44-6.64-29.91-7.16-45.49-6.29
                c-7.3,0.41-14.61,0.69-21.91,1.03C114.49,687.09,114.51,687.12,114.51,687.12z"/>
            <path onClick={() => props.onFill(87)} fill={props.fillColors[87]} d="M745.9,1482.5c-12.53-3.41-25.06-7.1-38.22-6.07c-5.39,0.42-10.8,1.68-15.99,3.23
                c-9.7,2.9-19.35,6.04-28.83,9.58c-4.78,1.79-9.13,4.7-13.7,7.05c-1.31,0.67-2.69,1.67-4.05,1.69
                c-10.59,0.14-21.19,0.08-32.12-0.83c1.79-1.02,3.54-2.12,5.38-3.05c11.68-5.94,21.77-13.76,30.81-23.37
                c12.96-13.77,29.07-20.02,48.24-17.01c20.01,3.14,36.08,12.86,48.48,28.79L745.9,1482.5z"/>
            <path onClick={() => props.onFill(88)} fill={props.fillColors[88]} d="M838.04,240.26c-0.61,6.86-4.98,9.48-11.31,7.13c-2.59-0.96-5.65-0.58-8.42-1.15
                c-1.67-0.35-3.41-1.09-4.76-2.12c-4.65-3.58-8.26-8.95-15.33-8.04c-0.7,0.09-1.66-0.98-2.32-1.69
                c-10.55-11.31-17.12-24.49-18.46-40.02c-0.14-1.57,0.56-3.69,1.66-4.7c0.62-0.58,3.04,0.23,4.3,0.99
                c4.44,2.67,11.81,1.66,15.16-2.31c5.5-6.52,13.42-7.14,19.31-1.33c0.96,0.95,1.41,2.6,1.72,4.02
                c3.16,14.41,8.04,28.13,15.49,40.93C836.52,234.46,837.07,237.49,838.04,240.26z"/>
            <path onClick={() => props.onFill(89)} fill={props.fillColors[89]} d="M987.61,308.2c3.27-20.97,6.58-41.93,9.8-62.91c1.64-10.67,3.06-21.36,4.71-32.03
                c0.35-2.26,0.9-4.62,1.95-6.63c2.62-5,5.51-9.87,8.45-14.69c0.71-1.17,2.12-2.84,3.09-2.77c4.65,0.35,6.68-2.41,9.01-5.75
                c1.06-1.52,4.17-2.21,6.29-2.12c0.83,0.04,2.38,3.43,2.12,5.06c-5.9,36.94-15.87,72.69-32.5,106.33
                c-2.88,5.83-7.03,11.04-10.6,16.54C989.15,308.9,988.38,308.55,987.61,308.2z"/>
            <path onClick={() => props.onFill(90)} fill={props.fillColors[90]} d="M741.88,1004.78c-17.71-0.2-34.87-0.41-52.05-3.26c-10.23-1.7-20.91-0.65-31.38-0.82
                c-0.66-0.01-1.39,0.04-1.96,0.32c-12.92,6.49-26.79,3.51-40.2,3.49c-13.2-0.02-26.37-2.5-39.6-3.14
                c-11.27-0.55-22.6,0.01-33.9,0.04c-11.61,0.03-23.21,0.01-34.82,0.01c-0.06-0.57-0.12-1.14-0.19-1.71
                c9.8-2.9,19.55-5.99,29.42-8.58c2.55-0.67,5.5,0.31,8.27,0.35c6.06,0.09,12.12,0.06,18.19,0.09c9.63,0.04,19.25,0.1,28.88,0.13
                c2.79,0.01,5.58,0.02,8.36-0.09c1.65-0.07,3.3-0.6,4.93-0.52c5.71,0.29,11.42,1.07,17.13,1.09c14.7,0.06,29.4-0.36,44.09-0.25
                c7.04,0.05,14.06,0.87,21.1,1.3c5.28,0.32,10.56,0.73,15.84,0.83c8.78,0.17,17.56,0.05,26.34,0.21c3.09,0.06,5.72,0.92,7,4.45
                C738.08,1000.75,740,1002.35,741.88,1004.78z"/>
            <path onClick={() => props.onFill(91)} fill={props.fillColors[91]} d="M1066.58,1140.77c-2.02,10.93-4.05,21.86-6.28,33.94c-2.51-4.25-4.16-7.6-6.32-10.59
                c-4.83-6.69-10-13.13-14.86-19.79c-2.44-3.33-4.36-7.04-6.79-10.37c-7.17-9.85-16.03-18.02-25.69-25.34
                c-4.12-3.11-6.08-7.07-6.59-12.22c-1.11-11.4-2.39-22.8-4.06-34.14c-0.73-4.95,0.78-8.76,3.7-12.38c0,0-0.04-0.03-0.04-0.03
                c-0.76,4.01-1.69,7.99-2.21,12.03c-0.31,2.38,0.4,4.2,3.23,1.97l-0.02-0.02c1.02,2.79,2.04,5.57,3.43,9.37
                c1.79-1.71,3.18-3.04,4.57-4.37c0,0,0.01,0.01,0.01,0.01c-0.84,3.69-1.69,7.37-2.53,11.06c0.52,0.24,1.04,0.47,1.56,0.71
                c1.24-0.91,2.48-1.82,3.69-2.7c0.2,1.03,0.42,2.22,0.65,3.4c0.54,0.44,1.08,0.87,1.63,1.31c1.62-2.56,3.25-5.12,4.87-7.68
                c0.27,0.24,0.53,0.48,0.8,0.72c-1.16,4.13-2.32,8.25-3.48,12.38c0.45,0.3,0.9,0.6,1.35,0.9c1.48-1.04,2.95-2.08,4.43-3.12
                l0.01,0.02c-0.76,4.05-1.51,8.09-2.27,12.14c0.74,0.35,1.49,0.71,2.23,1.06c3.68-4.39,7.36-8.79,11.04-13.18
                c0,0-0.03-0.03-0.03-0.03c-2.63,5.17-5.4,10.28-7.84,15.54c-1.02,2.21-1.34,4.74-1.26,7.48c8.53-4.67,12.02-13.51,18.13-19.99
                l-0.04-0.04c-2.87,6.32-5.81,12.62-8.57,18.99c-1.19,2.74-2.03,5.63-2.11,8.91c10.71-7.19,14.31-19.46,21.7-28.89l-0.01-0.01
                c-3.86,8.05-7.73,16.1-11.58,24.16c-1.99,4.17-5.44,7.95-3.74,14.63c10.4-9.89,14.58-22.58,22.48-34.32
                c-2.37,13.95-12.03,23.92-13.01,36.98c7.89-6.17,10.92-15.44,15.87-23.43c0,0-0.04-0.03-0.04-0.03
                c-2.18,6.96-4.37,13.92-6.52,20.9c-0.99,3.23-1.88,6.5-2.81,9.75c0.6,0.27,1.21,0.55,1.81,0.82c2.44-4.44,4.89-8.88,7.8-14.17
                c-0.36,8.31-3.53,15.68-1.59,23.15c0.47,0.15,0.93,0.31,1.4,0.47c1.31-1.96,2.62-3.93,3.93-5.89
                C1066.62,1140.81,1066.58,1140.77,1066.58,1140.77z"/>
            <path onClick={() => props.onFill(92)} fill={props.fillColors[92]} d="M423.67,982.78c-2.27,0-3.9-0.01-5.54,0c-27.43,0.23-54.85,0.47-82.28,0.7c-1.16,0.01-2.38,0.19-3.47-0.1
                c-1.02-0.27-2.39-0.85-2.71-1.65c-0.31-0.77,0.2-2.48,0.9-2.96c7.38-5.05,8.06-12.04,7.06-20.22c-1.03-8.41,4.41-12.8,12.8-11.57
                c1.78,0.26,3.64,0.07,5.45,0.02c0.82-0.02,2.06-0.6,2.4-0.25c4.04,4.2,9,2.78,13.84,2.73c1.13-0.01,2.44,1.84,3.39,3.05
                c0.89,1.14,1.09,3.23,2.15,3.74c4.48,2.15,9.17,3.86,13.79,5.72c1.88,0.75,3.74,1.6,5.67,2.16c2.89,0.84,5.86,2.1,8.76,2.02
                c4.44-0.11,8.78-1.13,10.57,4.58c0.22,0.71,0.96,1.64,1.62,1.79C423.39,973.74,422.96,978.06,423.67,982.78z"/>
            <path onClick={() => props.onFill(93)} fill={props.fillColors[93]} d="M1057.6,1059.81c-8.76-5.33-17.57-10.6-26.28-16.02c-7.59-4.73-14.92-9.9-22.65-14.39
                c-7.56-4.39-15.5-8.12-23.23-12.21c-4.21-2.23-4.81-5.73-0.86-10.22c-4.02-0.72-7.37-1.31-10.71-1.91
                c0.93-5.29-0.61-6.49-8.86-6.18c1.99-2.01,4-3.48,5.17-5.46c0.64-1.09,0.21-4.19-0.37-4.37c-3.48-1.1-7.14-1.61-11.1-2.39
                c0.41-1.8,0.7-3.09,1.19-5.24c-3.12,0.56-5.62,1-8.41,1.5c1.46-1.91,2.84-3.71,4.86-6.34c-4.13-1.05-7.39-1.88-10.64-2.71
                l0.03,0.04c0.47-1.28,0.94-2.57,1.4-3.85c-1.55-0.41-3.3-1.49-4.63-1.12c-4.79,1.36-7.87-1.01-11.09-4.03
                c9.37-2.6,42.04,13.49,72.54,34.58c22.87,15.82,38.78,37.54,53.62,60.34C1057.58,1059.83,1057.6,1059.81,1057.6,1059.81z"/>
            <path onClick={() => props.onFill(94)} fill={props.fillColors[94]} d="M209.99,525.81c-10.66-18.57-20.87-37.42-32.15-55.61c-5.82-9.39-6.25-18.26-2.83-28.24
                c1.68-4.92,2.25-10.21,3.64-16.83c10.54,15.07,24.81,25.63,30.4,42.53c6.24,18.84,3.75,38.13,3.5,57.3
                C211.71,525.25,210.85,525.53,209.99,525.81z"/>
            <path onClick={() => props.onFill(95)} fill={props.fillColors[95]} d="M176.01,1487.38c-11.01-1.23-21.84-2.54-32.7-3.63c-6.45-0.65-11.99-2.6-17.93-5.85
                c-7.4-4.06-16.71-4.98-25.34-6.38c-19.51-3.16-39.08-6.05-58.69-8.53c-11.36-1.43-22.85-1.75-34.26-2.83
                c-1.73-0.16-3.32-1.75-4.97-2.68c1.49-0.98,2.95-2.74,4.49-2.81c16.92-0.81,33.86-1.88,50.79-1.83
                c17.44,0.05,34.87,1.24,52.3,2.01c1.7,0.07,3.39,0.66,5.41,1.08c-4.84,5.58-4.76,8.24,1.67,11.24c5.76,2.68,12.21,4.04,18.47,5.42
                c9.24,2.04,18.6,3.52,27.88,5.39c1.55,0.31,3.12,1.16,4.38,2.15C170.35,1482.31,172.99,1484.76,176.01,1487.38z"/>
            <path onClick={() => props.onFill(96)} fill={props.fillColors[96]} d="M765.86,341.37c6.15,0.08,12.02,0.72,15.45,6.95c0.36,0.65,1.24,1.24,1.99,1.43
                c7.13,1.76,14.3,3.35,21.41,5.18c1.81,0.46,3.48,1.54,5.16,2.43c2.43,1.3,4.67,3.36,7.23,3.88c3.74,0.76,4.54,2.64,4.21,5.95
                c-0.71,7.06-1.29,14.13-2.01,21.28c-0.17-3.7-1.19-6.52-6.37-5.39c-0.55,1.48-1.29,3.45-2.22,5.91c-0.73-3.94-1.23-7.5-2.08-10.98
                c-0.86-3.52-1.9-7.05-6.39-8.91c-4.38,9.48-3.67,19.26-3.71,28.92c-0.04,9.77-0.01,19.54-0.01,28.61
                c-0.38-5.98-0.8-12.64-1.22-19.31c-0.8-0.07-1.6-0.15-2.4-0.22c-0.92,3.77-1.83,7.54-2.74,11.29c-0.97-11.98,1.85-24.4-2.38-36.12
                c-0.65,0.01-1.3,0.01-1.94,0.02c-0.82,11.46-1.63,22.92-2.45,34.38c-0.35,0.11-0.7,0.22-1.05,0.33c-0.75-2.21-2.05-4.39-2.15-6.63
                c-0.51-10.96-0.71-21.94-1.02-32.92c-0.03-1.16,0.3-2.55-0.21-3.45c-1.38-2.45-3.12-4.7-4.71-7.02c-1.23,1.99-2.95,3.83-3.6,6
                c-1.62,5.4-2.75,10.95-4.64,16.46c0-11.14,0.21-22.3-0.1-33.43c-0.13-4.9-1.3-9.76-2-14.64L765.86,341.37z"/>
            <path onClick={() => props.onFill(97)} fill={props.fillColors[97]} d="M251.34,1493.49c-4.59,3.8-9.83,4.85-15.77,4.76c-17.8-0.28-35.62-0.13-53.42-0.1
                c-21.98,0.04-43.34-3.78-64.27-10.24c-1.58-0.49-3.38-0.87-4.59-1.88c-9.8-8.19-21.78-9.7-33.71-10.63
                c-22.2-1.73-44.45-2.84-66.68-4.23c-2.15-0.14-4.29-0.55-6.44-0.72c-2.56-0.21-4.59-0.83-4.48-4.05c0.12-3.76,2.76-3.64,5.31-3.52
                c6.98,0.33,14.01,0.29,20.91,1.21c28.01,3.71,55.99,7.65,83.95,11.67c6.23,0.9,12.33,2.15,16.92,7.48
                c1.43,1.65,4.86,1.98,7.43,2.19c35.96,2.84,71.92,5.56,107.89,8.26c2.31,0.17,4.65-0.09,6.98-0.15L251.34,1493.49z"/>
            <path onClick={() => props.onFill(98)} fill={props.fillColors[98]} d="M987.67,228.45c-3.98,28.05-7.97,56.09-11.98,84.35c-1.11-1.18-2.59-2.77-4.27-4.57
                c-1.44,1.04-2.65,2.18-4.07,2.89c-2.99,1.49-5.76-0.34-5.58-3.51c0.33-5.78-2.41-7.82-7.87-5.74c-1.38,0.52-2.55,1.58-3.93,2.1
                c-1.38,0.52-3.66,1.43-4.2,0.86c-1.19-1.23-2.3-3.37-2.12-4.99c1.53-13.56,6.89-25.8,15.02-36.5
                c9.14-12.04,19.28-23.31,28.99-34.91C987.65,228.43,987.67,228.45,987.67,228.45z"/>
            <path onClick={() => props.onFill(99)} fill={props.fillColors[99]} d="M820.8,1148.74c-4.25,0.83-8.5,1.66-13.95,2.73c10.79,9.74,22.41,14.84,34.22,19.61
                c-0.08,0.42-0.16,0.84-0.24,1.26c-6.46,0.91-12.93,1.82-19.39,2.74c-0.04,0.7-0.08,1.4-0.13,2.1c2.2,0.62,4.37,1.34,6.59,1.84
                c8.25,1.86,16.62,3.28,24.73,5.62c4.16,1.2,7.86,4,11.77,6.07c-0.15,0.41-0.3,0.81-0.45,1.22c-31.76-2.56-63.51-5.12-95.27-7.67
                c16.02-2.55,32.38,2.66,48.27-3.39c-14.51-4.86-29.11-8.95-44.44-10.21c13.48-1.22,27.41,1.21,39.83-6.57
                c-21.36-10.28-45.57-8.94-67.39-16.74c0.07-0.65,0.15-1.31,0.22-1.96c24.17,0,48.33,0,72.67,0c0.66,0.75,1.81,2.07,2.97,3.39
                C820.82,1148.77,820.8,1148.74,820.8,1148.74z"/>
            <path onClick={() => props.onFill(100)} fill={props.fillColors[100]} d="M191.13,701.8c8.42,6.7,13.56,14.8,13.78,25.14c0.41,18.93,0.21,37.87,0.23,56.81
                c0,3.97-2.19,4.89-5.62,3.74c-1.55-0.52-3.04-1.2-4.5-1.79c0.91-8.15-4.78-16.57-12.77-19.72c-1.13-0.45-2.62-2.85-2.29-3.64
                c2.01-4.74,0.02-8.29-2.5-12.03c-1.11-1.64-2.22-3.98-1.9-5.74c0.97-5.33,2.24-10.68,4.13-15.75c3.01-8.07,6.6-15.91,9.96-23.85
                C190.03,704.1,190.45,703.25,191.13,701.8z"/>
            <path onClick={() => props.onFill(101)} fill={props.fillColors[101]} d="M238.6,1364.37c1.75,8.33,3.81,16.6,5.18,24.99c2,12.29,3.58,24.65,5.19,36.99
                c1.35,10.37,2.4,20.78,3.76,31.15c0.85,6.47-0.25,7.9-6.66,7.58c-1.99-0.1-3.97-0.49-5.96-0.56c-11.87-0.4-10.64,0.42-10.31-11.29
                c0.77-26.91,1.86-53.81,2.83-80.71c0.04-1.16,0.01-2.43,0.47-3.44c2.61-5.68,2.69-11.48,1.81-17.56
                c-0.24-1.67,1.56-3.64,2.43-5.47c0.88,1.68,2.38,3.3,2.53,5.04c0.37,4.29,0.12,8.64,0.12,12.96
                C239.53,1364.16,239.06,1364.27,238.6,1364.37z"/>
            <path onClick={() => props.onFill(102)} fill={props.fillColors[102]} d="M465.83,272.5c2.7,6.79,5.27,12.88,7.48,19.1c0.48,1.34,0.11,3.41-0.64,4.69
                c-13.93,23.86-22.5,50.01-32.53,75.53c-0.59,1.49-1.25,2.95-2,4.7c-3.32-2.42-6.41-4.34-9.04-6.75c-0.97-0.89-1.5-3.1-1.17-4.44
                c7.76-31.07,16.75-61.67,34.77-88.72C463.5,275.42,464.44,274.31,465.83,272.5z"/>
            <path onClick={() => props.onFill(103)} fill={props.fillColors[103]} d="M1024.33,91.25c-0.93-4.85-2.8-8.28-8.42-8.23c-3.44,0.03-3-2.97-2.72-5.26
                c1.66-13.52,3.5-27.02,5.05-40.55c0.81-7.09,1.27-14.23,1.66-21.36c0.38-7.02,3.27-9.24,10.2-8.83c2.7,0.16,5.56-1.65,8.28-2.73
                c1.36-0.54,2.49-1.76,3.87-2.12c4.6-1.21,6.35,0.52,5.05,5.02c-3.96,13.73-8.65,27.29-11.85,41.2
                c-2.86,12.43-4.2,25.21-6.05,37.86C1028.98,89.12,1028.67,91.6,1024.33,91.25z"/>
            <path onClick={() => props.onFill(104)} fill={props.fillColors[104]} d="M887.02,412.93c23.37,22.76,27.9,50.77,26.28,82.27c-2.66-2.16-4.68-3.8-7.22-5.86
                c-1.94,1.02-4.2,1.32-5-2.83c-0.34-1.77-2.4-3.13-3.23-4.89c-0.99-2.11-1.26-4.56-2.28-6.65c-1.38-2.82-2.85-5.71-4.87-8.06
                c-2.44-2.85-5.69-4.99-8.25-7.76c-3.59-3.89-4.35-13.2-1.35-17.39c2.26-3.14,1.18-5.92-0.66-8.22c-2.19-2.74-1.78-4.97-0.27-7.66
                C882.51,421.75,884.63,417.5,887.02,412.93z"/>
            <path onClick={() => props.onFill(105)} fill={props.fillColors[105]} d="M108.49,482.26c0.18-1.51,0.88-3.2,0.43-4.48c-1.08-3.02-2.72-5.84-3.47-9.18
                c4.75,6.7,7.13,15.45,17.55,18.69c-0.58-5.82-0.65-10.59-1.62-15.17c-0.92-4.29,0.77-5.73,4.55-6.36
                c3.97-0.66,6.27,0.35,6.89,4.77c1.18,8.54,2.72,17.03,4.14,25.54c0.13,0.79,0.51,1.53,1.05,3.11
                c8.83-7.68,15.26-16.44,19.93-26.83c4.32,2.34,5.81,5.26,3.99,9.57c-3.28,7.81-6.03,15.89-9.91,23.38
                c-5.63,10.85-9.16,22.05-8.94,34.33c0.02,0.96-0.24,1.93-0.36,2.83c-10.87,2.35-20.09-6.65-17.19-16.53
                c6.51-0.3,6.79-0.63,7.47-7.56c0.12-1.28,1.08-2.54,1-3.77c-0.1-1.61-0.35-4.08-1.38-4.63c-5.42-2.93-7.97-7.57-9.76-13.2
                c-2.27-7.11-8.03-11.18-14.33-14.54L108.49,482.26z"/>
            <path onClick={() => props.onFill(106)} fill={props.fillColors[106]} d="M520.29,287.73c3.96,5.68,7.41,12.09,12.23,17.22c7.22,7.69,15.04,14.93,23.27,21.53
                c6.93,5.56,13.55,11.07,17.47,19.17c-4.23,3.62-7.59,3.2-11.53,0.04c-2.38-1.91-5.7-2.65-8.58-3.92
                c-0.95-0.42-2.46-0.65-2.75-1.36c-3.45-8.45-11.91-8.67-18.74-11.23c-3.97-1.49-7.46-2.57-9.72-6.72
                c-4.37-8.03-4.61-7.89-11.64-0.35c-5.13-6.62-10.2-13.02-15.01-19.62c-0.4-0.55,0.58-2.72,1.48-3.48
                c1.49-1.26,3.38-2.09,5.19-2.93C507.92,293.31,513.92,290.62,520.29,287.73z"/>
            <path onClick={() => props.onFill(107)} fill={props.fillColors[107]} d="M452.17,423.31c-0.94,0.3-2.15,1.12-2.79,0.81c-9.2-4.46-18.3,0.73-27.45,0.31
                c-1.53-0.07-3.17,2.05-4.81,2.36c-2.65,0.43-5.28,0.99-7.94,1.28c-4.51,0.48-9.03,1.1-13.55,1.09c-4.62-0.01-9.26-1.15-13.86-0.96
                c-2.94,0.12-5.77,2.31-8.74,2.61c-6.45,0.64-12.84-0.74-19.52,0.55c-5.28,1.01-11.25-1.43-16.88-2.52
                c-1.47-0.28-2.8-1.25-4.2-1.91c0.03-0.62,0.06-1.23,0.09-1.85c2.88-0.3,5.76-0.61,8.64-0.88c0.31-0.03,0.66,0.29,0.95,0.24
                c8.53-1.49,17.26-2.34,25.51-4.81c3.96-1.19,7.45-1.22,11.26-0.92c1.94,0.15,4.3,0.99,5.74,0.19c4.32-2.41,8.42-1.48,12.8-0.61
                c1.88,0.37,4.17-0.26,6-1.07c1.64-0.72,2.91-2.31,4.34-3.52c-1.73-1.4-3.28-3.23-5.25-4.11c-2.51-1.11-5.44-1.24-8.02-2.24
                c-0.94-0.36-1.7-1.92-1.9-3.05c-0.08-0.43,1.57-1.62,2.52-1.72c4.52-0.46,9.07-0.64,13.6-0.97c1.45-0.11,3.11,0.03,4.29-0.63
                c3.63-2.03,5.35-0.48,6.95,2.74c1.12,2.24,2.82,4.25,4.52,6.13c6.19,6.83,15.15,8.7,22.98,12.57c1.39,0.68,3.16,0.59,4.76,0.86
                L452.17,423.31z"/>
            <path onClick={() => props.onFill(108)} fill={props.fillColors[108]} d="M788.86,245.42c-14.18-9.81-28.53-19.37-42.47-29.51c-10.36-7.53-17.92-17.74-24.45-28.67
                c-0.87-1.45-1.45-3.07-2.17-4.61c0.49-0.31,0.97-0.62,1.46-0.93c0.78,1.03,1.91,1.95,2.27,3.11c1.79,5.74,10.24,12.62,15.78,11.44
                c3.43-0.73,6.91-3.25,9.38-5.89c3.16-3.36,7.05-3.69,10.41-2.74c5.34,1.51,10.33,3.91,11.33,10.92
                c0.93,6.54,3.15,12.89,4.86,19.31c2.7,10.09,7.46,19.17,13.58,27.58L788.86,245.42z"/>
            <path onClick={() => props.onFill(109)} fill={props.fillColors[109]} d="M147.42,1498.15c-21.24,0-42.52,0.08-63.79-0.06c-5.7-0.04-10.73-1.48-12.7-8.08
                c-0.4-1.33-3.06-2.49-4.85-2.83c-16.12-3.11-32.27-6.05-48.43-8.97c-5.07-0.92-10.19-1.59-15.29-2.38c0-0.56,0-1.12-0.01-1.68
                c0.92-0.4,1.86-1.21,2.75-1.15c29.29,2.19,58.61,4.11,87.82,7.03c6.21,0.62,12.04,4.96,18.05,7.56c2.43,1.05,4.8,2.33,7.33,3.02
                C127.98,1493.23,137.72,1495.65,147.42,1498.15z"/>
            <path onClick={() => props.onFill(110)} fill={props.fillColors[110]} d="M1114.17,153.69c-0.61-15.96-1.53-37.71-2.23-59.47c-0.15-4.79,0.68-9.61,0.72-14.42
                c0.04-4.74-0.47-9.47-0.54-14.21c-0.06-3.64,0.1-7.29,0.3-10.93c0.49-8.92,1.13-17.83,1.57-26.75c0.36-7.3,0.34-14.62,0.8-21.92
                c0.25-4.01,2.93-4.84,9.91-4.04c3.77,0.43,3.31,2.76,3.17,5.41c-0.63,12.12-1.03,24.24-1.72,36.36c-0.19,3.28-1.05,6.53-1.65,9.79
                c-1.72,9.26-4.24,18.44-5.06,27.78C1117.17,107.32,1115.6,133.42,1114.17,153.69z"/>
            <path onClick={() => props.onFill(111)} fill={props.fillColors[111]} d="M195.42,1381.58c1.93-10,3.85-20,5.78-30c-0.65-0.16-1.3-0.33-1.95-0.49c-0.39,1.2-0.86,2.38-1.16,3.61
                c-1.41,5.8-2.61,11.66-4.25,17.39c-0.43,1.49-2.19,2.6-3.33,3.89c-1.19-1.44-2.99-2.71-3.46-4.35
                c-2.71-9.44-2.61-18.38,6.33-25.49c4.01,2.2,6.78-0.63,10.51-2.83c3.87-2.28,9.26-1.93,13.95-2.89c3.44-0.7,7.12-1.09,10.17-2.63
                c6.84-3.46,13.4-7.48,19.99-11.42c2.26-1.35,4.26-3.13,6.37-4.72c0,0-0.03-0.03-0.03-0.03c-3.65,4.4-7.3,8.8-10.95,13.2
                c-0.38-0.24-0.76-0.48-1.15-0.73c0.94-0.24,2.11-0.2,2.79-0.76c5.94-4.93,12.23-9.55,17.57-15.07
                c8.59-8.87,16.53-18.36,24.75-27.59c0,0-0.03-0.03-0.03-0.03c-1.87,3.12-3.63,6.32-5.65,9.34c-4.75,7.14-10.04,13.97-14.34,21.37
                c-5.02,8.64-12.89,13.94-20.72,19.33c-1.73,1.19-4.37,1.2-6.62,1.48c-1.93,0.24-3.93,0.23-5.88,0.1
                c-8.39-0.56-13.86,2.87-15.72,11.33l0.01,0.01c0-1.76,0-3.52,0-5.27c-0.41-0.15-0.82-0.29-1.22-0.44
                c-1.27,1.89-2.53,3.79-3.8,5.68c0,0,0.03,0.03,0.03,0.03c0.32-2.32,0.63-4.64,0.95-6.96c-0.5-0.13-1.01-0.27-1.51-0.4
                c-0.68,1.71-1.53,3.38-2.02,5.15c-2.31,8.29-4.08,16.76-6.97,24.83c-1.48,4.15-4.89,7.6-7.43,11.37c0,0-0.01-0.01-0.01-0.01
                c4.29-11.24,7.62-22.73,9.66-34.54c-0.51-0.16-1.02-0.31-1.52-0.47c-3.05,9.66-6.11,19.32-9.16,28.98L195.42,1381.58z"/>
            <path onClick={() => props.onFill(112)} fill={props.fillColors[112]} d="M992.24,33.71c0.48,16.08,9.71,30.76,7.88,46.05c-5.5-0.13-10.24-0.32-14.97-0.34
                c-7.49-0.03-8.36-0.8-8.57-8.09c-0.12-4.23-3.58-7.02-8.25-6.62c-1.89,0.16-3.78,0.38-6.77,0.68c3.66-3.68,6.02-6.61,2.38-10.54
                c-0.44-0.47-0.02-2.74,0.41-2.87c3.04-0.93,3.41-7.52,8.26-3.62c1.96-5.35,3.74-10.23,5.48-14.96c1.95-0.49,4.27-1.06,6.59-1.64
                c-0.05-0.37-0.1-0.73-0.15-1.1C986.9,31.62,989.31,32.57,992.24,33.71z"/>
            <path onClick={() => props.onFill(113)} fill={props.fillColors[113]} d="M1133.9,160.43c0-30.26-0.02-60.52,0.05-90.78c0.01-2.12,0.93-4.22,1.09-6.36
                c0.49-6.74,1.11-13.49,1.14-20.24c0.05-12.3-0.43-24.6-0.39-36.9c0.02-6.12,5.03-3.79,8.24-4.13c3.51-0.37,3.98,1.81,3.81,4.78
                c-1.28,22.06-2.32,44.14-3.74,66.19c-0.42,6.54-1.65,12.83-4.46,19.16c-2.86,6.44-3.36,14.14-3.92,21.36
                c-1.21,15.57-1.81,31.19-2.65,46.79C1133.34,160.35,1133.62,160.39,1133.9,160.43z"/>
            <path onClick={() => props.onFill(114)} fill={props.fillColors[114]} d="M286.83,972.14c0.57-4.78,1.14-9.55,1.73-14.56c3.14,20.21,6.25,40.18,9.56,61.41
                c-4.24-1.31-8.13-1.78-11.2-3.62c-5.24-3.14-10.07-7-14.82-10.88c-1.12-0.92-1.88-3.17-1.7-4.67c2.42-19.92,5.03-39.82,7.39-58.08
                c3.01,8.86,2.7,20.27,6.82,30.55C285.35,972.24,286.09,972.19,286.83,972.14z"/>
            <path onClick={() => props.onFill(115)} fill={props.fillColors[115]} d="M1060.58,177.48c-4.76,26.73-13.13,52.18-27.48,75.37c-3.07,4.96-7.37,9.15-11.87,13.24
                c6.47-26.64,17.78-51.71,20.1-79.18c11.41,2.77,11.41,2.77,10.16-5.96c3.13-1.18,6.12-2.31,9.12-3.44L1060.58,177.48z"/>
            <path onClick={() => props.onFill(116)} fill={props.fillColors[116]} d="M852.22,772.08c0-8.18-0.15-16.45,0.16-24.7c0.05-1.26,2.36-3.56,3.44-3.45
                c6.51,0.65,11.23-2.24,16.46-5.71c4.94-3.28,11.18-5.52,17.06-6.1c3.47-0.34,6.84-0.93,10.26-1.33c2.42-0.29,6.32,1.44,7.54,3.47
                c3.3,5.52,7.47,9.82,12.77,13.26c2.19,1.42,3.65,8.58,2.06,10.54c-0.52,0.64-2.95,0.85-3.21,0.43
                c-4.09-6.43-9.64-10.34-17.3-11.43c-1.97-0.28-3.72-1.88-5.64-2.7c-4.61-1.94-8.92-1.15-13.21,1.28c-1.49,0.85-3.62,0.49-5.3,1.11
                c-1.88,0.69-3.66,1.74-5.33,2.87c-1.59,1.08-2.77,3.01-4.47,3.62c-4.32,1.54-4.79,4.44-4.01,8.33
                C864.87,768.38,859.91,773.19,852.22,772.08z"/>
            <path onClick={() => props.onFill(117)} fill={props.fillColors[117]} d="M328.1,975.78c-5.75-27.01-11.08-54.09-13.35-82.18c10.01,3.5,15.71,12.33,14.86,21.27
                c-0.13,1.35,1.12,2.92,1.96,4.25c1.37,2.19,3.71,4.09,4.22,6.43c1.16,5.3,1.46,10.8,2.11,16.21c0.04,0.33,0.13,0.67,0.08,0.99
                c-1.52,9.15-3.04,18.29-3.57,27.6c-0.12,2.1-3.12,4.04-4.8,6.06C329.11,976.2,328.6,975.99,328.1,975.78z"/>
            <path onClick={() => props.onFill(118)} fill={props.fillColors[118]} d="M205.03,791.97c-0.76,11.9-1.53,24.03-2.3,36.16c-0.4,0.21-0.81,0.42-1.21,0.63
                c-3.39-4.66-6.78-9.31-10.14-13.99c-0.48-0.67-0.57-1.87-1.16-2.15c-7.28-3.5-7.71-11.9-12.36-17.28c-1.2-1.38-1.96-3.6-1.99-5.45
                c-0.17-12.28-0.09-24.57,1.11-37.05c0.13,2.77,1.1,5.85,0.21,8.24c-1.62,4.34-0.17,6.6,3.71,7.41c6.02,1.25,8.11,6.48,11.33,10.52
                c0.67,0.84,0.26,2.86-0.14,4.19c-0.98,3.27,0.08,5.06,3.34,5.9C198.44,789.86,201.39,790.87,205.03,791.97z"/>
            <path onClick={() => props.onFill(119)} fill={props.fillColors[119]} d="M73.31,1498.15c-5.12,0-9.23,0-13.34,0c-17.29,0-34.57-0.14-51.86,0.09c-4.86,0.07-6.66-1.6-6.22-6.37
                c0.29-3.13-0.29-6.38,0.26-9.44c0.23-1.29,2.53-3.35,3.6-3.19c18.52,2.86,36.97,6.2,55.5,8.97
                C67.12,1489.1,68.91,1493.95,73.31,1498.15z"/>
            <path onClick={() => props.onFill(120)} fill={props.fillColors[120]} d="M120.45,645.17c1.83,4.34,2.65,9.38,8.87,12.07c3.78-18.71,1.59-37.21,2.1-55.55
                c6.11,1.18,8.2,4.35,8.39,11.05c0.55,19.43,1.36,38.86,2.07,58.29c0.02,0.66,0.12,1.33,0.16,1.99c0.47,8.61-1.64,10.53-10.07,8.34
                c-1.71-0.44-4.12-2.9-4.12-4.44c-0.05-11.15-4.66-18.75-15.47-22.39c-1.8-0.61-3.25-2.24-4.87-3.4c0,0-0.03,0.03-0.03,0.03
                c2.83,1.06,5.66,2.13,8.5,3.19c0.32-0.35,0.64-0.71,0.95-1.06c-2.75-6.51-9.25-11.34-9.85-18.81c0.41-0.23,0.81-0.46,1.22-0.68
                c1,1.86,1.87,3.79,3.02,5.55c1.62,2.48,3.24,5.01,5.24,7.16c1.8,1.94,3.53,1.72,3.93-1.36L120.45,645.17z"/>
            <path onClick={() => props.onFill(121)} fill={props.fillColors[121]} d="M123.17,749.32c-0.34,6.32-0.61,12.77-1.11,19.21c-0.07,0.84-1.2,2.13-1.97,2.25
                c-0.9,0.13-2.16-0.61-2.89-1.34c-1.43-1.43-2.42-4.26-3.9-4.48c-4.08-0.61-8.32-0.21-12.49-0.14c-0.6,0.01-1.19,0.41-2.09,0.74
                c-1.48-3.48-2.54-7.01-4.41-10.03c-1.67-2.7-3.82-5.84-6.52-6.99c-4.48-1.91-6.23-5.95-9.27-8.95c-3.58-3.53-7.3-6.9-10.93-10.38
                c-1.11-1.07-2.14-2.23-3.38-3.54c6.65-2.53,10.43-1.06,14.27,3.56c1.91,2.3,5.17,3.66,8.03,4.98c6.26,2.9,13.58,3.86,17.35,11
                c0.85,1.62,4.61,3.55,5.6,2.96C114.17,745.32,118.23,747.37,123.17,749.32z"/>
            <path onClick={() => props.onFill(122)} fill={props.fillColors[122]} d="M219.03,265.16c-0.99,1.95-2.55,5.39-4.51,8.59c-0.44,0.72-2.6,1.19-3.38,0.74
                c-2.86-1.62-5.2-1.35-7.81,0.58c-1.05,0.77-2.85,0.96-4.22,0.77c-5.51-0.76-9.36,1.85-13.01,5.52c-1.11,1.12-3.16,1.3-4.77,1.91
                c-0.16-1.71-1-3.72-0.39-5.07c4.98-10.99,10.18-21.87,15.49-32.71c0.44-0.9,2.12-1.43,3.32-1.68c2.38-0.51,4.83-0.76,7.25-1.01
                c0.89-0.09,2.27-0.21,2.65,0.29C214.33,249.13,218.68,255.38,219.03,265.16z"/>
            <path onClick={() => props.onFill(123)} fill={props.fillColors[123]} d="M251.37,280.42c-2.7-5.66-8.71-8.25-16.86-7.27c-5.53,0.66-6.42-0.18-4.92-5.34
                c2.64-9.07,5.18-18.19,8.45-27.04c0.82-2.23,4.79-5.27,6.41-4.8c3.08,0.88,6.86,3.51,7.96,6.34c2.91,7.5,4.89,15.43,6.61,23.32
                c2.29,10.5,2.04,10.55-7.69,14.76C251.34,280.39,251.37,280.42,251.37,280.42z"/>
            <path onClick={() => props.onFill(124)} fill={props.fillColors[124]} d="M566.05,322.39c6.76,5.35,11.01,12.57,13.85,20.42c8.18,22.63,24.27,36.97,46.36,45.01
                c5.4,1.96,7.91,4.98,7.73,10.52l0.03-0.03c-21.34-5.18-39.83-15.25-54.74-31.62c-2.57-2.82-3.6-4.94-1.18-8.6
                c1.17-1.78,0.92-5.2,0.13-7.47c-3.66-10.51-10.42-18.7-19.54-25.09c-5.02-3.51-9.76-7.41-14.62-11.14c0,0,0.03-0.03,0.03-0.03
                c7.16,4.55,13.72,10.2,23.04,13.14c-0.49-2.41-0.77-3.77-1.05-5.13L566.05,322.39z"/>
            <path onClick={() => props.onFill(125)} fill={props.fillColors[125]} d="M172.42,1015.85c-5.5,3.98-10.68,8.55-16.6,11.75c-4.61,2.5-10.19,4.77-15.26,4.6
                c-17.25-0.57-32.45,4.3-47.17,13.07c-6.73,4.01-15.19,5.12-22.87,7.55l0.03,0.03c8.36-5.41,16.85-10.63,25.04-16.28
                c10.18-7.02,21.36-11.21,33.4-13.72c8.59-1.8,16.91-4.94,25.53-6.5c5.81-1.05,11.93-0.38,17.91-0.47L172.42,1015.85z"/>
            <path onClick={() => props.onFill(126)} fill={props.fillColors[126]} d="M237.94,429.76c-2.45,1.63-5.57,2.69-6.67,4.81c-0.67,1.29,1.79,4.19,3.28,7.26
                c-2.49,5.11-7.85,8.6-14.04,10.79c-1.38,0.49-3.87-0.49-5.12-1.6c-5.42-4.81-8.33-11.09-9.57-18.17
                c-0.66-3.76-1.38-7.52-1.89-11.31c-0.53-4,2.65-7.41,6.23-5.88c9.08,3.89,17.84,8.51,26.7,12.89
                C237.5,428.86,237.89,429.69,237.94,429.76z"/>
            <path onClick={() => props.onFill(127)} fill={props.fillColors[127]} d="M667.27,405.75c-0.56,1.45-1.13,2.89-1.69,4.34c-0.52,1.35-1.03,2.7-1.9,4.97
                c-3.37-4.17-0.06-12.13-9.18-11.68c-0.67,11-1.36,22.46-2.12,34.93c-2.36-11.6,1.67-23.1-4.41-34.09
                c-1.07,1.74-1.78,2.9-2.37,3.85c-0.57-1.9-1.24-4.16-1.91-6.42c-0.59,0.04-1.18,0.07-1.77,0.11c-1.24,8.85-2.47,17.7-3.11,22.25
                c-0.64-7.96-1.62-20.2-2.63-32.44c-0.59-7.21,1.74-8.89,8.51-6.88c2.29,0.68,5.24,0.26,7.57-0.56c4.97-1.74,8.06-0.96,10.11,3.82
                c1.95,4.54,4.69,7.98,8.66,11.17c2.42,1.95,2.46,6.87,3.63,10.64c-2.58,0.21-3.88,0.32-5.29,0.43c-0.19-1.04-0.48-2.6-0.76-4.16
                C668.17,405.95,667.72,405.85,667.27,405.75z"/>
            <path onClick={() => props.onFill(128)} fill={props.fillColors[128]} d="M71.17,549.96c-3.61,0.31-6.4,0.59-9.21,0.78c-4.07,0.28-8.24,0.02-12.19,0.84
                c-6.2,1.28-12.29-3.48-18.49,0.05c-1.09,0.62-2.93,0.06-4.4-0.17c-5.62-0.88-11.2-2.17-16.85-2.63c-2.21-0.18-4.57,1.41-7.5,2.41
                c0-4.17,0-7.99,0-12.36c6.35,1.26,12.87,2.54,19.39,3.84c3.23,0.64,6.45,1.8,9.68,1.85c4.65,0.07,9.71,0.35,12.23-5.3
                c4.45,3.47,8.35,4.5,13.84,2.27c5.31-2.15,11.6-1.95,17.48-2.59c2.53-0.28,5.29-0.67,7.63,0.04c3.93,1.2,7.61,3.25,11.39,4.94
                c-0.06,0.65-0.11,1.3-0.17,1.95c-3.67,0.28-7.51,1.38-10.98,0.68C76.12,545.16,74.1,543.86,71.17,549.96z"/>
            <path onClick={() => props.onFill(129)} fill={props.fillColors[129]} d="M870.21,155.49c-7.46-22.43-12.61-45.29-12.56-70.44c2.43,1.7,4.77,2.68,5.91,4.43
                c0.49,0.76-1.24,3.35-2.41,4.72c-2.61,3.06-3.25,6.22-0.85,9.51c2.45,3.37,5.82,4.97,10.02,3.6c4.77-1.56,6.88-0.26,7.01,4.95
                c0.08,3.22,0.69,5.59,3.89,7.34c1.31,0.72,2.44,3.94,1.93,5.43c-3.56,10.33-7.57,20.5-11.43,30.73
                C871.21,155.68,870.71,155.58,870.21,155.49z"/>
            <path onClick={() => props.onFill(130)} fill={props.fillColors[130]} d="M826.88,972.79c0,16.1,0.02,33.18-0.04,50.25c-0.01,1.45-0.16,3.26-0.99,4.3
                c-8.29,10.36-8.41,22.75-9.1,35.06c-0.63,11.27-1.36,22.54-2.34,33.79c-0.15,1.7-1.87,3.26-2.86,4.88
                c-1.13-1.72-3.35-3.54-3.2-5.15c1.14-12.73,2.79-25.41,4.13-38.11c0.45-4.29,0.06-8.69,0.79-12.92c1.36-7.78,3.4-15.44,4.91-23.2
                c1.77-9.11,3.27-18.28,4.89-27.42C824.39,986.77,825.73,979.27,826.88,972.79z"/>
            <path onClick={() => props.onFill(131)} fill={props.fillColors[131]} d="M421.16,270.45c2.25,4.43,4.14,9.1,6.88,13.21c2.28,3.42,3.86,2.86,5.73-1.76
                c0.33,6.62-0.95,13.24,5.2,18.98c2.4-4.28,4.35-7.86,6.4-11.38c3.17-5.46,6.16-11.06,9.72-16.26c1.51-2.21,4.4-3.43,6.45-5.33
                c1.41-1.31,2.46-3,3.91-4.83c0.15,0.69,0.67,1.6,0.42,2.18c-1.13,2.59-2.19,5.27-3.78,7.57c-18.62,26.91-27.92,57.59-36.19,88.72
                c-0.43,1.6-1.87,2.94-2.84,4.4c-0.65-1.93-2.23-4.09-1.81-5.74c3.47-13.7,6.5-27.59,11.28-40.84c4.44-12.32,1.85-22.54-4.6-33.03
                c-2.98-4.85-4.53-10.59-6.73-15.92C421.2,270.41,421.16,270.45,421.16,270.45z"/>
            <path onClick={() => props.onFill(132)} fill={props.fillColors[132]} d="M96.9,497.71c-4.78-1.39-9.75-2.83-15.16-4.4c-0.99-6.81-2.87-13.7-11.76-14.63
                c-0.34-6.62-0.35-6.58,5.74-6.4c0.94,0.03,1.97-0.33,2.84-0.75c6.56-3.13,10.48-1.87,15.86,3.28c4.12,3.95,9.1,7.11,14,10.12
                c6.75,4.14,12.51,8.76,13.28,19.21c-3.37-2.7-5.51-5.08-8.18-6.37c-3.9-1.89-8.11-3.41-12.35-4.17
                C99.98,493.39,98.21,496.38,96.9,497.71z"/>
            <path onClick={() => props.onFill(133)} fill={props.fillColors[133]} d="M836.55,168.45c3.42,0.24,6.01,1.57,6.27,7.24c0.35,7.56,1.7,15.23,3.84,22.5
                c2.79,9.49,1.24,18.34-1.8,28.59c-2.42-3.22-4.89-5.5-6.13-8.31c-3.91-8.93-7.25-18.11-11.04-27.09
                c-2.26-5.35-0.12-9.38,2.97-13.48C832.67,175.23,834.22,172.23,836.55,168.45z"/>
            <path onClick={() => props.onFill(134)} fill={props.fillColors[134]} d="M815.82,1340.6c-3,0-6,0-9,0c0,0,0.02,0.02,0.02,0.02c0.85-0.97,1.69-1.95,2.82-3.25
                c-3.97-2.14-7.65-4.12-11.59-6.24c24.16-0.27,59.77,24.56,68.35,47.85c-2.6,0.54-5.12,1.07-7.63,1.59l0-0.01
                c0.99-0.9,1.99-1.8,2.98-2.7c-0.22-0.3-0.45-0.6-0.67-0.9c-2.77,1.53-5.54,3.07-8.31,4.6l0.02,0.02c2.31-2.89,4.61-5.79,6.92-8.68
                c-0.27-0.39-0.55-0.77-0.82-1.16c-3.9,2.08-7.8,4.17-11.7,6.25c-0.13-0.16-0.26-0.33-0.39-0.49c2.47-2.26,4.93-4.53,7.4-6.79
                c-0.27-0.47-0.53-0.95-0.8-1.42c-4.21,2.09-8.41,4.18-12.62,6.28c0,0,0.02,0.02,0.02,0.02c3.98-3.39,7.95-6.77,11.93-10.16
                c-0.63-0.84-1.26-1.69-1.88-2.53c-5.43,2.86-10.86,5.71-16.3,8.57c-0.16-0.2-0.31-0.39-0.47-0.59c4.09-4.4,8.17-8.8,12.26-13.2
                c-0.33-0.49-0.65-0.97-0.98-1.46c-6.18,3.46-12.36,6.91-18.54,10.37l-0.01,0c4.6-5.67,9.2-11.35,13.8-17.02
                c-0.23-0.29-0.47-0.59-0.7-0.88c-5.37,2.98-10.73,5.95-16.1,8.93c0,0-0.03-0.03-0.03-0.03c3.43-3.74,6.86-7.49,10.29-11.23
                c-0.29-0.36-0.59-0.72-0.88-1.09c-5.46,3.44-10.92,6.88-16.37,10.33l0.01,0.01c3.63-3.74,7.25-7.48,10.88-11.22
                c-0.28-0.4-0.56-0.79-0.85-1.19c-4.3,1.88-8.6,3.76-12.9,5.64c-0.19-0.21-0.38-0.41-0.57-0.62c2.65-2.84,5.31-5.68,7.96-8.51
                c-0.25-0.33-0.51-0.66-0.76-0.99c-1.59,0.63-3.18,1.26-4.77,1.9C815.84,1340.62,815.82,1340.6,815.82,1340.6z"/>
            <path onClick={() => props.onFill(135)} fill={props.fillColors[135]} d="M158.2,836.34c-0.49-2.52-1.45-5.05-1.39-7.55c0.12-4.96-0.84-8.81-5.29-12.02
                c-2.1-1.52-3.42-5.36-3.52-8.2c-0.52-13.61-0.56-27.24-0.59-40.86c0-1.06,1.18-2.87,2.08-3.06c3.38-0.71,10.54,5.29,10.56,8.92
                c0.12,20.43,0.09,40.87,0.08,61.3c0,0.41-0.38,0.82-0.59,1.23C159.1,836.19,158.65,836.26,158.2,836.34z"/>
            <path onClick={() => props.onFill(136)} fill={props.fillColors[136]} d="M802.83,970.89c-2.05,20.49-4.24,40.97-6.07,61.49c-0.47,5.27-4.09,5.47-7.61,5.82
                c-0.86,0.09-2.59-1.89-2.83-3.13c-3.07-15.89,0.66-30.98,5.75-45.88c0.96-2.83,2.52-5.55,2.91-8.45c0.68-5.04,2.52-8.72,7.86-9.83
                L802.83,970.89z"/>
            <path onClick={() => props.onFill(137)} fill={props.fillColors[137]} d="M1019.64,1430.56c-6.75,8.42-13.5,16.84-20.25,25.26c-7.79,9.72-15.63,19.4-23.35,29.18
                c-1.33,1.68-2.24,3.7-3.26,5.6c-4.4,8.21-7.19,9.35-17.43,6.5c1.55-2.66,2.88-5.24,4.48-7.64c12.19-18.15,27.57-33.22,44.88-46.45
                c5.14-3.93,9.95-8.3,14.91-12.47L1019.64,1430.56z"/>
            <path onClick={() => props.onFill(138)} fill={props.fillColors[138]} d="M156.43,251.45c1.43,1.52,2.85,3.05,5.45,5.81c0.52-3.93,0.85-6.38,1.17-8.84
                c0.22-1.65,0.54-3.29,0.63-4.94c0.27-5.19,0.18-10.59,7.78-9.94c-0.44,10.29-5.38,21.32,2.59,31.58
                c-0.91,1.47-1.87,3.03-2.84,4.59c5.51-1.81,8.83-5.79,11.1-10.48c2.23-4.62,3.6-9.65,5.72-14.33c0.66-1.45,2.52-2.36,3.83-3.51
                c0.5,1.65,1.88,3.71,1.35,4.89c-4.84,10.71-9.59,21.5-15.39,31.7c-1.39,2.45-6.78,4.09-9.91,3.57c-2.09-0.35-4.93-5.24-4.82-8
                c0.32-8.33-3.1-15.16-6.62-22.13C156.46,251.42,156.43,251.45,156.43,251.45z"/>
            <path onClick={() => props.onFill(139)} fill={props.fillColors[139]} d="M148.29,818.98c4.78,1.49,6.07,4.21,5.77,7.98c-0.39,4.82,0.03,9.37,4.48,12.47
                c0.38,0.26,0.91,0.97,0.8,1.18c-2.05,3.87,1.45,6.94,1.51,10.25c0.35,19.78,0.18,39.57,0.18,60.39
                C151.32,880.74,148.71,850.29,148.29,818.98z"/>
            <path onClick={() => props.onFill(140)} fill={props.fillColors[140]} d="M231.56,1348.8c-0.61,1.39-1.23,2.78-1.86,4.19c3.3,2.06,3.71,5.54,2.62,8.86
                c-4.55,13.89-3.65,28.3-4.18,42.53c-0.73,19.62-0.75,39.27-1.22,58.9c-0.05,2-1.14,3.98-1.74,5.97c-0.55-0.14-1.09-0.29-1.64-0.43
                c-0.21-0.9-0.62-1.8-0.61-2.7c0.22-25.13,0.6-50.27,0.7-75.4c0.04-9.41-0.69-18.82-0.78-28.23c-0.04-4.13,0.07-8.44,1.25-12.32
                c0.61-1.99,3.72-3.22,5.7-4.8c0.48,1.03,0.96,2.06,1.44,3.09c-0.32,0.35-0.63,0.7-0.95,1.06
                C230.71,1349.28,231.14,1349.04,231.56,1348.8z M230.71,1355.69c-0.08-0.24-0.15-0.49-0.27-0.71c-0.02-0.04-0.38,0.01-0.4,0.08
                c-0.08,0.24-0.08,0.51-0.11,0.76C230.2,1355.78,230.45,1355.74,230.71,1355.69z M230.41,1358.52c-0.25,0.17-0.62,0.28-0.69,0.49
                c-0.06,0.17,0.23,0.47,0.37,0.71c0.32-0.22,0.65-0.45,0.97-0.67C230.84,1358.87,230.63,1358.7,230.41,1358.52z M228.94,1363.58
                c-0.02-0.15-0.04-0.42-0.06-0.42c-0.27-0.01-0.53,0.03-0.8,0.05c0.03,0.15,0.05,0.43,0.08,0.43
                C228.42,1363.65,228.68,1363.61,228.94,1363.58z"/>
            <path onClick={() => props.onFill(141)} fill={props.fillColors[141]} d="M743.23,991.31c3.38,3.75,6.21,7.17,9.34,10.29c3.39,3.39,6.75,6.95,10.67,9.64
                c5.34,3.66,8.23,8.5,9.4,14.64c0.74,3.88,1.53,7.75,2.42,12.28c-1.96,0-3.36,0.38-4.4-0.07c-3.33-1.43-6.76-2.83-9.71-4.88
                c-3.05-2.11-6.06-4.43-5.97-9.11c0.03-1.77-1.77-4.39-3.44-5.22c-4.29-2.13-6.69-5.49-5.1-9.87c1.44-3.97,0.22-6.35-3.12-8.28
                C739.11,998.31,739.64,995.39,743.23,991.31z"/>
            <path onClick={() => props.onFill(142)} fill={props.fillColors[142]} d="M750.9,334.36c7.89,1.73,9.67-4.38,12.23-10.38c1.94,2.39,3.34,4.38,5,6.12c2.92,3.07,5.51,4.79,10.73,3.2
                c4.36-1.33,10.26,0.21,14.78,2.12c5.48,2.31,10.04,6.73,15.38,9.49c2.7,1.39,3.97,2.85,4.66,5.67c0.6,2.47,2.03,4.75,3.58,8.2
                c-2.12-1.15-3.78-1.56-4.68-2.62c-2.71-3.21-5.95-4.08-10.09-4.17c-3.46-0.07-6.9-1.7-10.35-2.64c-1.73-0.47-3.51-0.81-5.2-1.41
                c-1.5-0.53-3.62-0.96-4.24-2.1c-4.32-7.94-12.47-7.25-19.29-7.75c-4.76-0.35-8.51-1.92-12.51-3.72L750.9,334.36z"/>
            <path onClick={() => props.onFill(143)} fill={props.fillColors[143]} d="M250.15,1342.63c-0.75,0.59-1.5,1.18-1.84,1.45c0.44,1.97,1.31,3.66,1.08,5.19
                c-1.53,9.92-1.39,19.64,0.13,29.69c3.72,24.58,6.38,49.33,9.46,74.01c0.62,4.94,1.39,9.88,1.68,14.84
                c0.1,1.64-1.07,3.35-1.66,5.02c-0.79-1.41-2.1-2.75-2.29-4.23c-3.16-24.71-6.17-49.44-9.24-74.16c-0.22-1.81-0.29-3.78-1.06-5.36
                c-6.23-12.82-3.1-26.51-3.44-39.81c-0.06-2.58,3.76-5.26,5.79-7.9C249.22,1341.79,249.69,1342.21,250.15,1342.63z M246.61,1351.49
                c-0.23,0.62-0.47,0.99-0.48,1.37c-0.01,0.16,0.56,0.54,0.66,0.48c0.34-0.21,0.59-0.57,0.87-0.87
                C247.37,1352.2,247.08,1351.93,246.61,1351.49z M247.6,1348.56c0.04-0.14,0.08-0.28,0.12-0.42c-0.26-0.03-0.53-0.07-0.79-0.08
                c-0.03,0-0.06,0.25-0.08,0.39C247.09,1348.49,247.34,1348.53,247.6,1348.56z"/>
            <path onClick={() => props.onFill(144)} fill={props.fillColors[144]} d="M75.28,483.91c-5.12,4.25,0.18,4.65,2.27,6.47c-0.73,1.39-1.38,2.64-1.44,2.74
                c-3.63-0.33-6.44-0.57-9.25-0.86c-0.81-0.08-1.85-0.05-2.38-0.52c-4.1-3.66-7.9-1.03-11.87,0.27c-1.91,0.63-4.93,1.57-5.89,0.69
                c-3.18-2.92-7.53-0.96-9.82-2.24c-5.92-3.3-11.93,0.75-17.76-2c-3.84-1.81-8.82-1.13-13.25-1.79c-3.64-0.54-5.48-4.63-3.47-7.74
                c0.52-0.8,1.87-1.06,2.84-1.57c1.67,3.6,7.37,1.19,8.78,6.38c0.53,1.95,5.77,2.93,9.02,3.64c0.89,0.2,2.3-2.22,3.56-3.33
                c0.68-0.6,1.53-1.35,2.33-1.38c4.6-0.2,9.21-0.1,13.81-0.3c3.6-0.16,7.4,0.06,10.73-1.05C60.95,478.82,67.64,480.77,75.28,483.91z
                "/>
            <path onClick={() => props.onFill(145)} fill={props.fillColors[145]} d="M971.66,1442.53c-14.39,15.64-26.11,33.04-35.12,52.31c-0.68,1.46-2.86,3.02-4.43,3.12
                c-5.77,0.37-11.57,0.15-18.25,0.15c0.99-1.67,1.41-2.88,2.23-3.67c16.98-16.33,34-32.63,51.07-48.88c1.29-1.23,3.02-2.01,4.54-3
                C971.69,1442.57,971.66,1442.53,971.66,1442.53z"/>
            <path onClick={() => props.onFill(146)} fill={props.fillColors[146]} d="M298.31,1041.83c-7.44-0.4-14.87-0.99-22.32-1.13c-3.59-0.07-5.78-1.17-7.35-4.55
                c-4-8.65-9.26-16.34-16.77-22.56c-6.24-5.17-6.74-13.35-9.47-21.09c6.16,0.98,10.8,2.02,12.67,8.72c1.14,4.1,4.45,7.66,7.01,11.3
                c3.14,4.47,6.43,8.84,9.83,13.11c1.3,1.63,2.9,3.21,4.69,4.23c7.17,4.11,14.46,7.99,21.71,11.96L298.31,1041.83z"/>
            <path onClick={() => props.onFill(147)} fill={props.fillColors[147]} d="M896.77,206.44c-10.46-10.95-16.45-24.49-22.12-38.21c-0.4-0.97-0.45-2.42-0.03-3.35
                c3.53-7.85,7.21-15.62,11.23-24.24c0.75,7.1,1.07,13.5,2.17,19.77c2.71,15.39,5.78,30.72,8.71,46.07L896.77,206.44z"/>
            <path onClick={() => props.onFill(148)} fill={props.fillColors[148]} d="M836.29,61.94c4.4,0.44,8.51,0.68,12.56,1.36c1.18,0.2,2.73,1.42,3.12,2.53c0.82,2.3,1.34,4.84,1.36,7.29
                c0.12,14.9,0.06,29.8,0.06,44.81C847.57,110.31,836.42,73.17,836.29,61.94z"/>
            <path onClick={() => props.onFill(149)} fill={props.fillColors[149]} d="M59.6,1027.63c-15.94,10.41-34.76,12-53.31,14.61c-3.06,0.43-4.15-0.78-4.38-3.76
                c-0.81-10.97-0.74-10.95,10.05-10.46c12.74,0.58,25.49,0.95,38.24,1.11C53.46,1029.18,56.73,1028.12,59.6,1027.63z"/>
            <path onClick={() => props.onFill(150)} fill={props.fillColors[150]} d="M1059.59,28.49c0.88,3.74,3.32,1.92,5.06,1.19c7.8-3.27,13.53-8.98,17.97-16.03
                c2.18-3.46,4.11-7.09,6.38-10.48c-3.92,10.27-10.32,18.62-18.84,25.64c-17.78,14.66-31.45,31.95-33.9,55.99
                c-0.18,1.76-1.1,3.44-1.86,5.68c-5.51-7.02,9.81-78.18,19.13-89.19c-0.12,2.72,0.17,4.38-0.32,5.77
                c-2.65,7.53-5.59,14.95-8.25,22.47c-2.22,6.26-4.35,12.56-6.22,18.93c-0.65,2.22-0.43,4.69,0.12,7.33
                c9.85-7.07,16.1-16.57,20.65-27.36C1059.51,28.43,1059.59,28.49,1059.59,28.49z"/>
            <path onClick={() => props.onFill(151)} fill={props.fillColors[151]} d="M838.98,105.64c-12.29-11.88-21.08-24.87-25.21-40.76c-0.26-0.98-0.12-2.87,0.39-3.09
                c1.23-0.55,2.94-0.82,4.18-0.39c3.88,1.36,7.69,2.98,11.39,4.78c1.02,0.5,2.05,1.87,2.28,2.99
                C834.36,81.01,836.56,92.87,838.98,105.64z"/>
            <path onClick={() => props.onFill(152)} fill={props.fillColors[152]} d="M81.51,1082.82c19.57,0.88,39.15,1.81,58.72,2.64c21.58,0.91,43.16,2.02,64.75,2.46
                c8.23,0.17,16.49-1.31,24.8-1.66c-7.54,4.33-15.98,5.18-24.24,5.01c-18.41-0.39-36.8-1.72-55.21-2.52
                c-12.78-0.55-25.62-0.39-38.35-1.42c-10.2-0.83-20.3-2.98-30.44-4.54L81.51,1082.82z"/>
            <path onClick={() => props.onFill(153)} fill={props.fillColors[153]} d="M163.09,733.56c-9.96-0.62-18.5-9.2-18.53-17c-0.03-7.49-0.05-14.99,0.2-22.47
                c0.05-1.53,1.33-3.02,2.04-4.52c1.17,1.16,2.82,2.11,3.43,3.52c3.31,7.62,6.76,15.22,9.38,23.09
                C161.4,721.52,161.89,727.3,163.09,733.56z"/>
            <path onClick={() => props.onFill(154)} fill={props.fillColors[154]} d="M143.92,727.04c6.19,3.88,10.87,6.57,15.24,9.7c1.25,0.89,2.28,3.06,2.25,4.61
                c-0.19,8.26-0.75,16.51-1.19,24.96c-4.27-1.78-8.05-3.24-11.71-4.97c-0.87-0.41-1.74-1.72-1.84-2.69
                C145.7,748.49,144.88,738.31,143.92,727.04z"/>
            <path onClick={() => props.onFill(155)} fill={props.fillColors[155]} d="M980.67,980.87c-7.05-3.72-14.2-7.25-21.05-11.29c-1.49-0.88-2.85-3.67-2.67-5.43
                c1.27-12.86,2.99-25.68,4.49-38.52c0.79-6.84,1.42-13.7,2.94-20.57c0,2.55,0,5.11,0,7.66c0.03,12.79-0.05,25.59,0.21,38.38
                c0.04,1.86,1.86,3.64,2.58,5.56c0.57,1.52,0.72,3.2,1.06,4.81c-0.43,0.21-0.86,0.41-1.29,0.62c1.28,0.99,2.56,1.98,4.03,3.12
                c-0.58,0.91-1.15,1.8-2.18,3.43c2.9,0.53,5.29,0.98,8.42,1.55c-1.75,1.52-2.92,2.54-4.99,4.33c2.48,0.79,4.2,1.32,5.9,1.89
                c2.17,0.74,4.32,1.53,2.53,4.5C980.64,980.9,980.67,980.87,980.67,980.87z"/>
            <path onClick={() => props.onFill(156)} fill={props.fillColors[156]} d="M858.03,1347.33c-2.05-0.85-5.02-2.09-8.24-3.44c7.13-2.08,7.13-2.08,12.55,2.2
                c8.01,6.33,15.19,13.36,19.94,22.6c1.51,2.93,1.58,5.22-1.75,6.32c-3.04,1-6.02,5.04-9.53,0.41c-4.5-5.95-9.22-11.73-13.92-17.52
                c-1.44-1.78-2.99-3.11-0.13-5.2C857.88,1352.03,857.61,1349.7,858.03,1347.33z"/>
            <path onClick={() => props.onFill(157)} fill={props.fillColors[157]} d="M1141.05,1456.08c-0.55,12.38-1.15,24.76-1.61,37.15c-0.13,3.51-1.54,5.08-5.18,4.97
                c-5.92-0.18-11.85-0.05-19.32-0.05C1125.86,1484.49,1135.67,1471.63,1141.05,1456.08z"/>
            <path onClick={() => props.onFill(158)} fill={props.fillColors[158]} d="M1009.65,81.54c-6.26-0.76-6.41-5-6.99-10.2c-0.85-7.7-3.38-15.2-5.02-22.82
                c-1.22-5.65-2.16-11.37-3.22-17.06c0.42-0.09,0.85-0.19,1.27-0.28c0.6,3.36,1.09,6.74,1.85,10.07c0.81,3.56,1.67,7.13,2.86,10.58
                c1.13,3.25,2.71,6.35,4.09,9.52c0.75-0.12,1.51-0.24,2.26-0.36c0.72-5.96,1.57-11.92,2.13-17.89c0.45-4.79,0.83-9.62,0.77-14.43
                c-0.06-4.91,2.62-7.49,7.17-9.94c-2.45,21.4-4.82,42.12-7.19,62.84L1009.65,81.54z"/>
            <path onClick={() => props.onFill(159)} fill={props.fillColors[159]} d="M1045.51,1435.32c2.1-4.05,4.2-8.1,6.23-12c3.97,13.92-14.25,50.61-23.31,54.58
                c4.99-16.82,9.64-32.49,14.09-47.47c0.23,0.68,0.86,2.48,1.48,4.29C1044.5,1434.92,1045,1435.12,1045.51,1435.32z"/>
            <path onClick={() => props.onFill(160)} fill={props.fillColors[160]} d="M211.12,1465.35c-5.32,3.9-5.18,5.06,1.64,10.15c-7.68-1.19-15.36-2.38-23.03-3.57
                c-0.12-0.68-0.25-1.37-0.37-2.05c3.33-1.66,6.66-3.33,10.01-5c-3.86-3.92-12.76-4.34-16.43-0.91c-0.65,0.6-1.59,1.34-2.35,1.3
                c-8.7-0.44-17.4-1.03-26.1-1.58c-0.12-0.9-0.25-1.8-0.37-2.7c3.53-1.06,7.08-3.07,10.59-3.01c9.79,0.17,19.63,0.7,29.31,2.08
                C199.98,1460.92,205.67,1463.6,211.12,1465.35z"/>
            <path onClick={() => props.onFill(161)} fill={props.fillColors[161]} d="M563.05,323.37c-18.92-10.6-32.59-24.3-36.53-36.64c2.3-1.48,4.63-2.94,6.9-4.47
                c2.96-2,3.61,0.34,4.65,2.3c4.15,7.77,8.01,15.73,12.7,23.16c3.52,5.58,8.16,10.46,12.3,15.65L563.05,323.37z"/>
            <path onClick={() => props.onFill(162)} fill={props.fillColors[162]} d="M631.02,346.33c-2.85-3.63-6.37-6.92-8.42-10.95c-5.16-10.18-9.75-20.65-14.29-31.13
                c-0.58-1.35,0.5-3.42,0.81-5.16c1.77,0.55,4.07,0.57,5.23,1.73c5.64,5.65,9.81,11.84,10.75,20.41c0.93,8.49,3.85,16.75,5.9,25.12
                L631.02,346.33z"/>
            <path onClick={() => props.onFill(163)} fill={props.fillColors[163]} d="M354.24,297.38c-1.99,3.42-3.99,6.84-6.19,10.61c-3.68-3.16-5.57-6.01-3.65-11.1
                c5.39-14.3,10.13-28.84,15.4-43.19c0.54-1.46,2.9-2.25,4.41-3.36c0.49,1.86,1.83,3.99,1.33,5.52
                c-3.92,12.15-8.29,24.17-12.25,36.31c-0.48,1.48,0.61,3.47,0.98,5.23L354.24,297.38z"/>
            <path onClick={() => props.onFill(164)} fill={props.fillColors[164]} d="M749.89,1100.8c9.79-0.25,19.58-0.51,29.37-0.75c1.62-0.04,3.25-0.01,5.37-0.01
                c-2,1.31-3.52,2.32-5.04,3.32c0.11,0.17,0.22,0.35,0.32,0.52c6.32-1.19,12.65-2.39,18.97-3.58c0.05,0.33,0.1,0.66,0.15,0.98
                c-5.82,3-11.64,6-17.46,9c0.16,0.37,0.31,0.75,0.47,1.12c8.22-3.2,16.43-6.4,24.65-9.6c0.3,0.78,0.59,1.56,0.89,2.34
                c-11.09,4.32-22.17,8.64-33.26,12.97c-0.3-0.58-0.6-1.15-0.89-1.73c1.95-1.82,3.9-3.63,5.85-5.45c-0.1-0.45-0.21-0.89-0.31-1.34
                c-4.23,0.49-8.46,0.99-13.34,1.56c1.32-1.15,2.3-2,3.54-3.08c-6.71-2.19-12.98-4.25-19.26-6.3L749.89,1100.8z"/>
            <path onClick={() => props.onFill(165)} fill={props.fillColors[165]} d="M178.4,799.29c0.59,6.92,6.76,10.31,9.16,15.09c3.49,6.94,9.72,10.79,13.54,16.88
                c0.78,1.24,1.34,2.63,1.99,3.95c-0.31,0.35-0.63,0.69-0.94,1.04c-3.41-1.32-6.77-2.82-10.26-3.88c-1.74-0.53-3.81-0.64-5.6-0.27
                c-5.37,1.1-7.39-0.23-7.78-5.78c-0.62-8.74-1.14-17.48-1.71-26.22C177.34,799.84,177.87,799.56,178.4,799.29z"/>
            <path onClick={() => props.onFill(166)} fill={props.fillColors[166]} d="M139.4,493.35c-1.37-7.8-2.65-15.62-4.16-23.4c-0.68-3.5,0.74-5.53,4-5.12c4.72,0.59,9.42,1.73,13.94,3.21
                c1,0.33,2.18,3.66,1.61,4.62c-4.34,7.34-9.1,14.44-13.73,21.62C140.51,493.97,139.95,493.66,139.4,493.35z"/>
            <path onClick={() => props.onFill(167)} fill={props.fillColors[167]} d="M1041.09,49.66c1.34-4.39,2.54-8.83,4.06-13.16c3.63-10.34,7.54-20.57,11.08-30.94
                c1.23-3.62,3.79-4.25,6.56-3.24c0.95,0.35,1.64,3.54,1.23,5.11c-3.35,12.94-7.95,25.39-16.1,36.21c-1.75,2.33-3.79,4.44-5.69,6.65
                C1041.85,50.07,1041.47,49.86,1041.09,49.66z"/>
            <path onClick={() => props.onFill(168)} fill={props.fillColors[168]} d="M261.35,1106.8c-2.68,5.91-5.36,11.82-8.04,17.73c0.43,0.24,0.87,0.47,1.3,0.71
                c3.92-5.47,7.83-10.95,11.75-16.42l-0.03-0.03c-0.97,6.7-2.2,13.37-2.81,20.1c-0.3,3.33-1.97,3.51-4.52,3.73
                c-6.87,0.59-13.73,1.43-20.59,2.17c0,0-0.05-0.04-0.05-0.04c3.44-5.64,6.38-11.69,10.48-16.8c3.45-4.29,8.27-7.49,12.48-11.17
                C261.34,1106.79,261.35,1106.8,261.35,1106.8z"/>
            <path onClick={() => props.onFill(169)} fill={props.fillColors[169]} d="M780.48,1037.99c-0.44-1.27-1.1-2.5-1.29-3.81c-1.51-10.16-4.64-19.52-10.97-27.94
                c-2.89-3.84-3.94-9.06-5.81-13.66c-1.27-3.12-0.54-5.43,1.35-6.2c4.64,5.3,9.14,9.99,13.05,15.13c1.84,2.41,3.29,5.61,3.61,8.6
                c0.98,9.12,1.33,18.3,1.92,27.46C781.72,1037.7,781.1,1037.84,780.48,1037.99z"/>
            <path onClick={() => props.onFill(170)} fill={props.fillColors[170]} d="M729.61,211.54c-15.83-5.38-25.46-17.44-32.77-31.99c4.52,0.79,8.84,1.36,13.06,2.37
                c1.39,0.33,2.93,1.54,3.7,2.78C719.04,193.59,724.29,202.58,729.61,211.54z"/>
            <path onClick={() => props.onFill(171)} fill={props.fillColors[171]} d="M705.96,457.24c-6.57-1.99-6.37,3.12-7.13,6.17c-1.03-1.83-2.71-4.05-3.59-6.56
                c-1.06-3.03-1.36-6.38-6.08-5.33c-0.96-5.47-1.25-11.24-7.59-13c-0.88-0.25-2.3,1.45-3.4,2.2c-0.41-4.11-4.61-8.61-0.88-14.07
                c0.63,0.28,1.3,0.41,1.74,0.81c4.22,3.76,8.29,7.47,13.1,10.73c6.18,4.19,12.62,10.3,13.8,19.1L705.96,457.24z"/>
            <path onClick={() => props.onFill(172)} fill={props.fillColors[172]} d="M1011.64,1001.88c-7.94-5.43-15.94-10.76-23.74-16.38c-1.22-0.88-1.86-2.98-2.17-4.63
                c-0.68-3.73-0.89-7.6-4.21-7.52c-3.07-4.53-5.48-8.09-7.9-11.64c-0.42,0.23-0.85,0.45-1.27,0.68c0.44-1.16,0.88-2.32,1.32-3.47
                l0.02-0.03c15.59,11.73,28.14,26.16,37.97,42.98L1011.64,1001.88z"/>
            <path onClick={() => props.onFill(173)} fill={props.fillColors[173]} d="M455.55,266.86c-5.93,10.12-11.42,19.47-16.9,28.83c-0.43,0.02-0.85,0.04-1.28,0.06
                c-0.28-3.78-1.05-7.6-0.74-11.33c0.5-6.08,1.69-12.1,2.62-18.14c0.47-3.07,2.14-3.65,5-2.66
                C447.85,264.87,451.58,265.74,455.55,266.86z"/>
            <path onClick={() => props.onFill(174)} fill={props.fillColors[174]} d="M974.16,217.18c3.53-8.02,6.95-16.09,10.63-24.04c1.72-3.71,3.72-7.33,6.05-10.68
                c1.11-1.6,2.71-4.8,5.43-2.2c2.36,2.25,4.69,5.06,2.26,8.74c-2.83,4.31-5.45,8.8-8.68,12.79c-4.49,5.56-9.45,10.73-14.21,16.06
                C975.15,217.63,974.65,217.4,974.16,217.18z"/>
            <path onClick={() => props.onFill(175)} fill={props.fillColors[175]} d="M856.78,154.49c1.7,4.87,3.51,9.7,5.02,14.62c0.46,1.49,0.51,3.37,0.03,4.83
                c-2.39,7.25-5.02,14.42-8.75,21.75c-0.95-5.24-2.11-10.46-2.79-15.74c-0.6-4.61-0.99-9.3-0.8-13.94c0.08-1.9,1.79-3.8,2.95-5.58
                c1.35-2.07,2.92-4,4.39-5.99C856.82,154.45,856.78,154.49,856.78,154.49z"/>
            <path onClick={() => props.onFill(176)} fill={props.fillColors[176]} d="M887.83,1365.38c-2.9-4.13-5.54-7.89-8.33-11.87c1.66-0.89,3.3-1.77,6.6-3.54
                c-8.76-1.23-9.69-7.77-12.58-13.67c1.52,0.09,2.87,0.08,4.18,0.27c0.97,0.15,1.91,0.53,2.84,0.87
                C896.48,1343.34,898.71,1351.49,887.83,1365.38z"/>
            <path onClick={() => props.onFill(177)} fill={props.fillColors[177]} d="M801.57,238.75c9.18,9.74,20.93,12.22,33.81,12.75c-1.29,4.94-2.56,9.82-3.82,14.66
                C825.2,265.01,805.96,247.98,801.57,238.75z"/>
            <path onClick={() => props.onFill(178)} fill={props.fillColors[178]} d="M273.33,269.41c-0.4,3.6-0.8,7.21-1.2,10.81c-9.08-1.07-8.82-1.09-9.5-9.45
                c-0.76-9.35-2.06-18.66-3.08-27.99c-0.15-1.41-0.02-2.84-0.02-4.56c3.6,0.32,5.16,1.83,5.64,4.95c1.18,7.72,2.51,15.42,3.86,23.12
                c0.31,1.75,0.92,3.44,1.4,5.16c0.54,0.36,1.08,0.71,1.62,1.07c0.44-1.03,0.87-2.05,1.31-3.08L273.33,269.41z"/>
            <path onClick={() => props.onFill(179)} fill={props.fillColors[179]} d="M484.12,285.39c-2.19,3.71-4.31,7.48-6.61,11.12c-1.65,2.61-1.4,4.32,0.82,4.89
                c-1.97,1.85-5.15,3.62-6.47,6.3c-4.32,8.74-8.36,17.68-11.79,26.81c-4.6,12.27-8.34,24.87-12.92,37.16
                c-0.86,2.31-3.91,3.8-5.96,5.67l-0.01-0.01c11.3-29.9,20.7-60.66,39.13-87.36c1.12-1.62,2.55-3.04,3.84-4.55
                C484.15,285.42,484.12,285.39,484.12,285.39z"/>
            <path onClick={() => props.onFill(180)} fill={props.fillColors[180]} d="M227.16,673.8c6.41,1.85,12.25,3.54,18.46,5.34c-4.36,6.68-8.54,14.27-18.46,17.25
                C227.16,688.36,227.16,680.9,227.16,673.8z"/>
            <path onClick={() => props.onFill(181)} fill={props.fillColors[181]} d="M110.75,657.5c11.62,2.71,15.72,9.74,12.95,21.97c-6.2-1.16-11.33-4.32-15.91-8.42
                c-0.73-0.66-1.01-2.48-0.7-3.52C108.07,664.22,109.43,661.03,110.75,657.5z"/>
            <path onClick={() => props.onFill(182)} fill={props.fillColors[182]} d="M568.49,2.42c-1.2,5.76-2.83,6.77-7.78,5.9c-1.64-0.29-3.65,0.13-5.17,0.88
                c-3.27,1.62-16.43,1.37-18.81-0.93c-1.45-1.4-2.42-3.3-4.23-5.85C545.18,2.42,556.64,2.42,568.49,2.42z"/>
            <path onClick={() => props.onFill(183)} fill={props.fillColors[183]} d="M783.02,1008.99c-4.41-10.6-5.61-20.64-4.87-30.95c0.06-0.78,2.13-2.15,3.06-1.99
                c2.34,0.39,6.58,1.62,6.54,2.3C787.14,988.29,787.56,998.49,783.02,1008.99z"/>
            <path onClick={() => props.onFill(184)} fill={props.fillColors[184]} d="M752.5,1043.04c13.85-0.89,27.71-1.77,41.72-2.67c1.3,4.2-0.22,6.59-4.13,6.74
                c-9.64,0.37-19.29,0.65-28.92,0.37c-2.88-0.09-5.7-2.17-8.55-3.33C752.58,1043.77,752.54,1043.41,752.5,1043.04z"/>
            <path onClick={() => props.onFill(185)} fill={props.fillColors[185]} d="M370.88,1042.76c-13.72-2.16-27.43-4.33-41.15-6.48c-1.31-0.2-2.82-0.76-3.92-0.34
                c-7.2,2.78-12.61-0.33-18.06-4.61c-2.46-1.93-5.8-2.72-8.75-4.03c0.16-0.5,0.32-0.99,0.48-1.49c3.84,1.06,7.64,2.3,11.52,3.14
                c6.89,1.5,13.84,2.72,20.76,4.13c3.72,0.76,7.43,1.62,11.13,2.48c9.32,2.17,18.63,4.36,27.94,6.54
                C370.85,1042.31,370.87,1042.54,370.88,1042.76z"/>
            <path onClick={() => props.onFill(186)} fill={props.fillColors[186]} d="M1052.59,1200.72c-3.99,9.58-7.97,19.16-12.39,29.78c-4.39-3.02-7.93-5.46-11.63-8.01
                c8.45-7.62,16.26-14.68,24.07-21.73L1052.59,1200.72z"/>
            <path onClick={() => props.onFill(187)} fill={props.fillColors[187]} d="M99.52,685.08c-15.74-1.8-30.47-6.27-43.43-16.61c3.81,0,7.23,0,10.93,0c2.1,4.05,4.93,6.81,9.98,3.24
                c0.5-0.35,1.68-0.24,2.26,0.12c6.35,3.86,12.66,7.81,18.95,11.77c0.53,0.33,0.84,1.01,1.26,1.52
                C99.47,685.12,99.52,685.08,99.52,685.08z"/>
            <path onClick={() => props.onFill(188)} fill={props.fillColors[188]} d="M861.36,382.03c4.01,4.64,8.01,9.29,12.05,13.9c1.8,2.06,3.77,3.76,0.57,6.53
                c-4.55,3.94-7.7,4.88-10.26,1.63c-1.76-2.24-3.13-5.22-3.49-8.02c-0.57-4.52-0.15-9.15-0.15-13.74
                C860.51,382.23,860.93,382.13,861.36,382.03z"/>
            <path onClick={() => props.onFill(189)} fill={props.fillColors[189]} d="M175.37,262.31c-4.89-8.3-2.44-17.12-1.53-26.18c3.81,2.37,7.15,4.37,10.39,6.53
                c0.53,0.35,1.03,1.54,0.83,2.08c-2.28,6.06-4.7,12.08-7.09,18.1C177.1,262.66,176.24,262.48,175.37,262.31z"/>
            <path onClick={() => props.onFill(190)} fill={props.fillColors[190]} d="M219.4,270.41c0.66-3.33,1.32-6.66,1.98-9.99l0.02-0.01c1.16-1.46,2.89-2.76,3.38-4.42
                c1.55-5.25,2.4-10.71,4.04-15.92c0.55-1.73,2.68-2.97,4.08-4.43c0.72,1.81,2.39,3.88,1.99,5.39c-2.5,9.29-5.24,18.55-8.56,27.58
                c-0.47,1.28-4.54,1.24-6.94,1.81L219.4,270.41z"/>
            <path onClick={() => props.onFill(191)} fill={props.fillColors[191]} d="M508.56,283.34c-4.84,4.03-9.51,7.92-14.41,12c-4.88-9.87-3.47-16.68,3.79-22.07
                C501.09,276.26,504.26,279.27,508.56,283.34z"/>
            <path onClick={() => props.onFill(192)} fill={props.fillColors[192]} d="M356.27,291.39c4.09-11.92,8.09-23.87,12.38-35.72c0.42-1.16,2.57-1.69,3.92-2.51
                c0.92,1.93,2.98,4.16,2.55,5.72c-1.08,3.94-2.95,7.78-5.1,11.3c-4.39,7.19-9.17,14.14-13.79,21.19L356.27,291.39z"/>
            <path onClick={() => props.onFill(193)} fill={props.fillColors[193]} d="M1063.43,27.67c3.14-8.81,5.53-16.18,8.5-23.3c0.54-1.29,3.47-2.17,5.26-2.11
                c1.04,0.03,2.61,2.17,2.91,3.58c1.44,6.8-2.61,11.3-7.23,15.28C1070.33,23.3,1067.36,24.97,1063.43,27.67z"/>
            <path onClick={() => props.onFill(194)} fill={props.fillColors[194]} d="M363.59,487.5c0.45-2.69,0.81-4.89,1.21-7.31c0.01-0.01,0.43-0.41,0.52-0.34
                c6.4,4.52,12.73,0.34,19.1-0.05c5.26-0.32,10.56-0.07,16.06,1.07c-5.88,2.21-11.6,5.3-17.68,6.39
                C376.65,488.37,370.13,487.5,363.59,487.5z"/>
            <path onClick={() => props.onFill(195)} fill={props.fillColors[195]} d="M158.38,645.61c1.77,10.96,3.31,20.76,4.97,30.54c0.53,3.11-0.17,4.74-3.71,4.65
                c-3.21-0.08-6.32-0.01-5.81-4.53c0.63-5.59,1.38-11.17,2.2-16.74C156.65,655.27,157.45,651.06,158.38,645.61z"/>
            <path onClick={() => props.onFill(196)} fill={props.fillColors[196]} d="M182.1,682c-7.3-0.86-7.4-0.86-7.31-8.56c0.1-8.21,0.43-16.42,0.66-24.62c0.66-0.22,1.32-0.44,1.98-0.66
                c0.91,1.56,2.48,3.06,2.63,4.69C180.92,662.48,181.45,672.15,182.1,682z"/>
            <path onClick={() => props.onFill(197)} fill={props.fillColors[197]} d="M161.02,458.31c-7.31-7.21-14.74-13.53-20.74-21.01c-2.15-2.68-0.83-8.14,0.09-12.65
                c1.11,2.05,1.96,4.3,3.37,6.12c4.93,6.37,10.03,12.6,15.11,18.85C160.6,451.75,163.83,453.28,161.02,458.31z"/>
            <path onClick={() => props.onFill(198)} fill={props.fillColors[198]} d="M1042.61,1186.72c-5.72,7.73-11.41,15.48-17.17,23.18c-5.63,7.54-5.67,7.52-14.02,2.83
                c-0.58-0.32-1.1-0.74-1.65-1.11c0,0,0.07-0.11,0.07-0.12c2.1-0.74,4.6-0.99,6.23-2.32c6.55-5.34,12.82-11.03,19.23-16.54
                c2.38-2.04,4.87-3.95,7.31-5.92L1042.61,1186.72z"/>
            <path onClick={() => props.onFill(199)} fill={props.fillColors[199]} d="M263.36,562.2c1.11-2.75,2.17-5.52,3.34-8.25c3.39-7.89,8.78-12.05,17.8-9.54
                c1.21,0.34,2.59,0.05,4.16,0.05c-7.35,7.59-15.82,13.37-25.32,17.72L263.36,562.2z"/>
            <path onClick={() => props.onFill(200)} fill={props.fillColors[200]} d="M976.68,181.48c-3.62,8.83-7.24,17.67-10.86,26.5c-0.75-0.16-1.5-0.32-2.25-0.47
                c0.49-8.91,0.98-17.82,1.54-27.95C969.39,182.54,972.8,185.91,976.68,181.48L976.68,181.48z"/>
            <path onClick={() => props.onFill(201)} fill={props.fillColors[201]} d="M250.35,1473.52c-4.78-1.09-9.38-2.66-14.12,0.97c-1.3,0.99-4.51-0.61-6.87-0.87
                c-1.06-0.12-2.16,0.08-3.24,0.21c-5.73,0.7-10.99-0.35-16.58-3.99c3.04-1.9,5.26-3.48,7.69-4.63c0.49-0.23,2.28,1.01,2.58,1.88
                c1.72,4.97,3.2,5.81,7.78,3.35c6.26-3.36,12.6-1.99,18.87-0.48c1.5,0.36,2.61,2.31,3.9,3.53
                C250.36,1473.51,250.35,1473.52,250.35,1473.52z"/>
            <path onClick={() => props.onFill(202)} fill={props.fillColors[202]} d="M635.01,350.32c4.68,10.39,9.36,20.78,14.31,31.78c-1.19-0.64-2.18-0.96-2.87-1.6
                c-0.71-0.65-1.33-1.55-1.66-2.46c-5.73-15.51-16.53-27.88-25.7-41.22c-4.48-6.52-10.01-12.32-15.07-18.45c0,0,0,0,0,0
                c3.56,2.86,7.19,5.64,10.63,8.64c1.07,0.93,1.56,2.49,2.41,3.7c4.43,6.35,8.8,12.75,13.43,18.96c0.9,1.2,2.51,3.92,4.5,0.67
                L635.01,350.32z"/>
            <path onClick={() => props.onFill(203)} fill={props.fillColors[203]} d="M198.2,435.31c-3.88-5.83-7.81-11.63-11.59-17.53c-0.41-0.63-0.15-2.56,0.11-2.62
                c3.05-0.76,6.31-2.06,9.2-1.52c1.39,0.26,2.71,4.02,3.03,6.33c0.64,4.72,0.57,9.53,0.79,14.3
                C199.24,434.61,198.72,434.96,198.2,435.31z"/>
            <path onClick={() => props.onFill(204)} fill={props.fillColors[204]} d="M1003.53,53.51c-1.48-6.67-2.99-13.34-4.44-20.02c-0.35-1.62-0.92-3.34-0.66-4.89
                c0.32-1.95,1.38-3.77,2.13-5.65c2.01,1.59,5.72,3.16,5.75,4.78c0.13,8.52-0.67,17.06-1.15,25.59
                C1004.61,53.38,1004.07,53.45,1003.53,53.51z"/>
            <path onClick={() => props.onFill(205)} fill={props.fillColors[205]} d="M978.66,1093.81c9,5.65,18,11.31,27,16.96c0,0-0.01,0.02-0.01,0.02c-3.29-0.21-6.58-0.43-9.87-0.64
                c-0.07,0.53-0.14,1.06-0.21,1.59c5.12,1.07,10.23,2.13,15.35,3.2c-0.07,0.26-0.14,0.52-0.21,0.78c-8.3,0-16.6,0-25.87,0
                c4.36-2.76,8.16-5.16,11.96-7.56c-0.08-0.47-0.17-0.94-0.25-1.41c-3.18,0.21-6.36,0.41-10.57,0.69c2.51-1.75,4.16-2.9,5.46-3.8
                c-4.43-3.43-8.58-6.64-12.74-9.86L978.66,1093.81z"/>
            <path onClick={() => props.onFill(206)} fill={props.fillColors[206]} d="M817.82,1384.58c1.71-3.57-0.7-4-3.21-3.92c-2.27,0.07-4.52,0.59-6.78,0.91l0.02,0.02
                c-1.34-0.7-2.7-1.36-4.01-2.12c-1.35-0.79-2.64-1.67-3.66-3.06c12.97,0.95,26.04,0.42,38.72,7.57c-6.37,0.97-11.72,1.78-17.07,2.6
                c0,0-0.01-0.01-0.01-0.01c0.63-0.87,1.27-1.74,1.9-2.6c-0.17-0.32-0.35-0.64-0.52-0.96c-1.79,0.53-3.58,1.05-5.37,1.58
                C817.83,1384.59,817.82,1384.58,817.82,1384.58z"/>
            <path onClick={() => props.onFill(207)} fill={props.fillColors[207]} d="M820.13,359.77c2.32-1.82,3.24-2.31,3.84-3.05c3.23-4.04,5.2-2.12,8.14,0.82
                c4.55,4.55,5.33,10.01,5.58,17.33C831.57,369.6,826.1,364.9,820.13,359.77z"/>
            <path onClick={() => props.onFill(208)} fill={props.fillColors[208]} d="M422.55,260.77c4.28,5.04,8.11,1.03,13.06-1.05c-1.85,8.03-3.45,15.03-5.06,22.03
                c-0.6,0.13-1.2,0.25-1.8,0.38c-2.5-6.78-5-13.56-7.5-20.34C421.68,261.45,422.12,261.11,422.55,260.77z"/>
            <path onClick={() => props.onFill(209)} fill={props.fillColors[209]} d="M765.96,497.27c-6.55-0.75-13.21-1.03-19.61-2.44c-4.08-0.9-8.47-2.73-11.53-5.47
                c-8.55-7.64-16.5-15.95-24.65-24.03c-0.42-0.42-0.58-1.12-0.47-2.22C723.84,482.33,743.06,492.87,765.96,497.27z"/>
            <path onClick={() => props.onFill(210)} fill={props.fillColors[210]} d="M247.24,1135.08c6.95,0,13.92,0.36,20.84-0.11c6.2-0.42,11.04,1.13,14.99,7.79
                c-12.53-1.88-24.27-3.64-36.01-5.41C247.12,1136.6,247.18,1135.84,247.24,1135.08z"/>
            <path onClick={() => props.onFill(211)} fill={props.fillColors[211]} d="M202.42,695.08c-2.84-2.28-5.71-4.52-8.51-6.86c-0.6-0.5-1.44-1.44-1.31-1.97
                c1.2-4.97,2.51-9.92,4.01-14.82c0.15-0.49,2.2-1.03,2.46-0.74c0.89,0.99,1.83,2.31,1.93,3.58c0.59,6.94,0.94,13.89,1.38,20.84
                C202.38,695.11,202.42,695.08,202.42,695.08z"/>
            <path onClick={() => props.onFill(212)} fill={props.fillColors[212]} d="M1064.62,973.85c-3.85-8.21-7.7-16.42-12.09-25.79c2.96-0.42,6.34-1.95,8.18-0.87
                c1.99,1.17,3.34,4.72,3.56,7.34c0.54,6.41,0.26,12.9,0.31,19.35L1064.62,973.85z"/>
            <path onClick={() => props.onFill(213)} fill={props.fillColors[213]} d="M489.14,274.51c-3.77,4.67-7.17,8.88-11.16,13.82c-2.28-7.94-3.29-15.26-2.31-22.78
                c0.06-0.5,1.63-0.8,2.8-1.32C478.16,271.08,478.16,271.08,489.14,274.51z"/>
            <path onClick={() => props.onFill(214)} fill={props.fillColors[214]} d="M159.85,251.76c-5.58-9.27-5.82-18.32-7.32-27.54c8.99,5.29,9.81,6.94,8.66,16.44
                C160.8,243.89,160.41,247.12,159.85,251.76z"/>
            <path onClick={() => props.onFill(215)} fill={props.fillColors[215]} d="M127.45,653.16c-5.63-8.48-7.43-17.51-3.65-27.33c6.59,8.32,4.48,17.89,3.7,27.28L127.45,653.16z"/>
            <path onClick={() => props.onFill(216)} fill={props.fillColors[216]} d="M357.26,1012.85c-4.32-0.1-8.64-0.24-12.97-0.3c-7.6-0.1-7.73-0.17-9.67-7.71
                c-0.28-1.1-0.48-2.22,0.21-4.72c7.47,4.25,14.93,8.5,22.4,12.75L357.26,1012.85z"/>
            <path onClick={() => props.onFill(217)} fill={props.fillColors[217]} d="M561.07,528.2c-5.52-0.83-11.14-1.28-16.53-2.6c-6.59-1.62-7.65-4.5-5.13-11.39
                c7.21,4.68,14.42,9.36,21.63,14.03L561.07,528.2z"/>
            <path onClick={() => props.onFill(218)} fill={props.fillColors[218]} d="M169.1,669.09c-5.28-6.4-5.1-14-5.81-21.33c-0.07-0.72,2.56-2.21,4.03-2.36c0.83-0.08,2.67,1.88,2.66,2.9
                C169.9,655.23,169.44,662.16,169.1,669.09z"/>
            <path onClick={() => props.onFill(219)} fill={props.fillColors[219]} d="M176.48,716.66c0-7.39,0-14.79,0-22.2c6.34-0.17,8.49,2.07,6.88,7.76c-1.42,5.04-3.36,9.94-5.07,14.9
                C177.69,716.97,177.09,716.81,176.48,716.66z"/>
            <path onClick={() => props.onFill(220)} fill={props.fillColors[220]} d="M219.11,1032.8c1.28,2.37,3.39,5.43,4.53,8.83c0.45,1.33-0.87,4.43-2.12,4.96
                c-6.81,2.9-13.86,5.24-21.25,6.95c8.25-0.99,12.42-7.54,17.23-12.81C219.12,1038.94,218.67,1035.26,219.11,1032.8z"/>
            <path onClick={() => props.onFill(221)} fill={props.fillColors[221]} d="M275.42,239.26c-0.92,8.22-1.84,16.44-2.76,24.66c-0.48,0.01-0.97,0.02-1.45,0.02
                c-1.05-7.15-2.4-14.28-2.89-21.47c-0.09-1.31,3.35-2.85,5.17-4.28C274.13,238.55,274.77,238.91,275.42,239.26z"/>
            <path onClick={() => props.onFill(222)} fill={props.fillColors[222]} d="M163.55,443.83c-2.03-6.71-4.12-13.39-5.98-20.15c-0.19-0.7,0.83-2.29,1.59-2.53
                c0.81-0.26,2.59,0.31,2.89,0.99c3.02,6.77,5.93,13.62,3.29,21.18C164.75,443.49,164.15,443.66,163.55,443.83z"/>
            <path onClick={() => props.onFill(223)} fill={props.fillColors[223]} d="M195.38,1381.55c-0.82,2.16-1.26,4.6-2.59,6.39c-1.11,1.5-3.26,3.06-4.93,3.04
                c-1.3-0.02-3.49-2.29-3.71-3.79c-1.19-8.08-1.9-16.24-2.9-25.6c2.47,6.43,4.44,11.39,6.24,16.41c0.46,1.29,0.36,2.77,0.51,4.17
                c0.18,1.62,0.37,3.24,0.56,4.85c1.47-0.77,3.07-1.36,4.38-2.35c1.02-0.77,1.66-2.03,2.47-3.08
                C195.42,1381.58,195.38,1381.55,195.38,1381.55z"/>
            <path onClick={() => props.onFill(224)} fill={props.fillColors[224]} d="M144.26,419.62c6.2-0.16,9.19,2.37,9.62,8.48c0.29,4.07,1.81,8.04,2.77,12.06
                C152.11,437.8,145.78,427.44,144.26,419.62z"/>
            <path onClick={() => props.onFill(225)} fill={props.fillColors[225]} d="M372.25,277.41c2.18-5.35,4.36-10.71,6.54-16.06c1.63-4,4.98-4.54,9.87-4.77
                c-4.53,8.51-8.74,15.97-16.43,20.8L372.25,277.41z"/>
            <path onClick={() => props.onFill(226)} fill={props.fillColors[226]} d="M776.87,997.86c-4.88-2.38-7.2-6.79-8.39-11.66c-0.42-1.71,0.67-4.12,1.74-5.78
                c1.02-1.58,2.91-2.59,4.44-3.87c0.71,6.89,1.45,14.11,2.19,21.33L776.87,997.86z"/>
            <path onClick={() => props.onFill(227)} fill={props.fillColors[227]} d="M348.96,253.54c2.78,0.52,4.85,0.91,7.52,1.4c-2.23,7.11-4.43,14.13-6.63,21.14
                c-0.29-0.02-0.59-0.05-0.88-0.07C348.96,268.7,348.96,261.38,348.96,253.54z"/>
            <path onClick={() => props.onFill(228)} fill={props.fillColors[228]} d="M1011.63,1142.75c-4.89,4.43-9.72,8.93-14.68,13.28c-4.63,4.06-5.24,3.9-9.26-1.91
                c8.03-3.8,16-7.57,23.97-11.34L1011.63,1142.75z"/>
            <path onClick={() => props.onFill(229)} fill={props.fillColors[229]} d="M122.01,701.97c-3.31-3.26-6.62-6.52-9.93-9.79c0.33-0.69,0.66-1.38,0.99-2.07
                c3.76,0.42,8.02-0.05,11.03,1.68c1.36,0.78,0.23,5.92,0.23,9.08C123.56,701.24,122.78,701.6,122.01,701.97z"/>
            <path onClick={() => props.onFill(230)} fill={props.fillColors[230]} d="M118.2,483.35c-3.06-4.73-6.12-9.45-9.37-14.47c7.61-2.46,9.34-1.46,10.18,4.78
                c0.39,2.89,0.71,5.79,1.06,8.68C119.46,482.68,118.83,483.01,118.2,483.35z"/>
            <path onClick={() => props.onFill(231)} fill={props.fillColors[231]} d="M593.02,299.41c2.77,1.11,5.64,2.03,8.27,3.4c1.31,0.69,2.62,2.04,3.16,3.4
                c1.71,4.32,3.06,8.78,4.55,13.18l0.05-0.04c-5.33-6.66-10.66-13.32-15.99-19.98L593.02,299.41z"/>
            <path onClick={() => props.onFill(232)} fill={props.fillColors[232]} d="M1062.59,1140.28c2.23-10.94,4.46-21.89,6.68-32.83C1069.92,1118.94,1069.7,1130.25,1062.59,1140.28z"/>
            <path onClick={() => props.onFill(233)} fill={props.fillColors[233]} d="M161.37,705.52c-1.52-3.78-3.29-7.49-4.43-11.39c-0.38-1.3,0.55-4.18,1.41-4.42
                c2.45-0.68,5.42-0.22,5.51,3.19c0.1,4.08-0.47,8.18-0.75,12.28C162.53,705.29,161.95,705.4,161.37,705.52z"/>
            <path onClick={() => props.onFill(234)} fill={props.fillColors[234]} d="M147.64,644.01c0.6,8.47,1.18,16.75,1.77,25.03c-6.31-7.86-6.15-16.16-2.64-24.68
                C146.82,644.23,147.11,644.21,147.64,644.01z"/>
            <path onClick={() => props.onFill(235)} fill={props.fillColors[235]} d="M127.33,621.05c-2.76-4.82-5.84-9.5-8.15-14.53c-1.17-2.55-0.94-5.75,3.31-6.53
                c2.07,6.73,4.17,13.57,6.28,20.41C128.29,620.61,127.81,620.83,127.33,621.05z"/>
            <path onClick={() => props.onFill(236)} fill={props.fillColors[236]} d="M88.21,599.23c0.55-3.94,0.82-7.47,1.66-10.86c0.25-1.02,2.36-2.52,3.12-2.27
                c1.27,0.41,2.74,1.91,3.05,3.21C97.31,594.58,95.95,596.28,88.21,599.23z"/>
            <path onClick={() => props.onFill(237)} fill={props.fillColors[237]} d="M887.01,127.17c-4.55,12.21-8.77,23.54-13,34.88c-0.65-0.23-1.3-0.47-1.95-0.7
                c4.27-11.24,8.55-22.47,12.84-33.7C884.94,127.55,885.27,127.56,887.01,127.17z"/>
            <path onClick={() => props.onFill(238)} fill={props.fillColors[238]} d="M223.99,232.81c5.55,7.25-1.22,14.26-0.76,21.41c-0.45-0.01-0.9-0.02-1.35-0.03c0-7.14,0-14.28,0-21.42
                C222.58,232.78,223.29,232.79,223.99,232.81z"/>
            <path onClick={() => props.onFill(239)} fill={props.fillColors[239]} d="M899.26,388.63c4.33-4.67,7.88-8.5,11.54-12.45c0.54,5.82-2.61,12.77-6.59,13.9
                C902.96,390.44,901.28,389.27,899.26,388.63z"/>
            <path onClick={() => props.onFill(240)} fill={props.fillColors[240]} d="M493.12,304.4c5.74,7.11,11.47,14.21,17.21,21.32c-0.24,0.27-0.47,0.53-0.71,0.8
                c-5.16-4.39-10.31-8.77-15.47-13.16c0,0-0.02,0.02-0.02,0.02c0.4-1.16,1.28-2.4,1.08-3.45c-0.36-1.91-1.35-3.71-2.07-5.55
                C493.14,304.38,493.12,304.4,493.12,304.4z"/>
            <path onClick={() => props.onFill(241)} fill={props.fillColors[241]} d="M380.1,1069.83c11.52-7.86,25.45-3.89,38.28-6.3C405.55,1065.64,392.73,1067.75,380.1,1069.83z"/>
            <path onClick={() => props.onFill(242)} fill={props.fillColors[242]} d="M725.7,333.18c5.5,3.99,10.69,7.75,15.88,11.51c-0.04,0.18-0.09,0.36-0.13,0.54
                c-4.76-1.77-9.67-3.23-14.24-5.39C723.19,337.95,723.26,337.13,725.7,333.18z"/>
            <path onClick={() => props.onFill(243)} fill={props.fillColors[243]} d="M122.49,437.27c-7.12-3.49-4.34-9.68-4.59-15.92c6.25,4.64,5.9,10.16,4.57,15.95L122.49,437.27z"/>
            <path onClick={() => props.onFill(244)} fill={props.fillColors[244]} d="M749.89,344.36c3.41,0.31,6.81,0.61,10.22,0.92c0.13-0.52,0.27-1.03,0.4-1.55
                c-1.86-1.47-3.73-2.93-5.59-4.4l-0.03,0.04c2.6,1.07,5.52,1.71,7.68,3.34c1.45,1.09,1.83,3.6,2.68,5.48
                c-1.57,0.33-3.16,0.6-4.71,1.01c-4.9,1.3-6.65-4.19-10.62-4.87C749.93,344.32,749.89,344.36,749.89,344.36z"/>
            <path onClick={() => props.onFill(245)} fill={props.fillColors[245]} d="M553.09,306.35c-6.4-8.21-10.43-17.56-14.49-26.9c0.75-0.37,1.51-0.74,2.26-1.11
                c1.87,4.69,3.64,9.43,5.65,14.06c2.05,4.72,4.35,9.32,6.54,13.98C553.06,306.38,553.09,306.35,553.09,306.35z"/>
            <path onClick={() => props.onFill(246)} fill={props.fillColors[246]} d="M149.83,612.45c-2.61,4.05-4.78,7.41-7.7,11.94c0.23-4.26,0.2-7.03,0.6-9.75
                c0.26-1.82,1.12-3.55,1.71-5.32C146.12,610.29,147.8,611.27,149.83,612.45z"/>
            <path onClick={() => props.onFill(247)} fill={props.fillColors[247]} d="M64.53,594.19c3.56,1.29,6.74,2.61,10.73,0.02c3.2-2.07,6.25-0.41,7.18,4.38
                c-6.41-0.95-13.28,2.31-17.88-4.44L64.53,594.19z"/>
            <path onClick={() => props.onFill(248)} fill={props.fillColors[248]} d="M67.52,679.1c-8.54-2.29-19.13-9.02-22.9-14.92c5.94-0.33,7.6,5.11,11.26,7.28
                c3.99,2.36,7.75,5.1,11.61,7.68C67.49,679.13,67.52,679.1,67.52,679.1z"/>
            <path onClick={() => props.onFill(249)} fill={props.fillColors[249]} d="M248.35,1033.86c3.28,4.43,6.56,8.85,10.62,14.33c-3.67-0.84-5.95-1.13-8.04-1.94
                c-0.9-0.35-1.85-1.72-1.97-2.73c-0.4-3.2-0.42-6.45-0.59-9.68C248.37,1033.84,248.35,1033.86,248.35,1033.86z"/>
            <path onClick={() => props.onFill(250)} fill={props.fillColors[250]} d="M165.43,670.13c1.41,1.44,2.81,2.87,4.22,4.31c0.52-0.17,1.03-0.34,1.55-0.51
                c0.28-4.27,0.56-8.55,0.87-13.39c0,5.68,0.18,10.81-0.11,15.91c-0.08,1.42-1.56,2.75-2.39,4.13c-1.11-1.19-2.73-2.21-3.2-3.62
                c-0.72-2.14-0.65-4.55-0.91-6.85C165.44,670.11,165.43,670.13,165.43,670.13z"/>
            <path onClick={() => props.onFill(251)} fill={props.fillColors[251]} d="M764.88,1007.86c-2.92-2.59-6.08-4.97-8.71-7.83c-3.18-3.45,1.08-4.53,2.56-7.22
                c2.21,5.43,4.18,10.25,6.14,15.06L764.88,1007.86z"/>
            <path onClick={() => props.onFill(252)} fill={props.fillColors[252]} d="M487.12,286.38c0.31,2.78,0.9,5.58,0.8,8.35c-0.05,1.24-1.27,2.8-2.4,3.5c-0.79,0.49-3.07,0.15-3.38-0.46
                c-0.53-1.09-0.51-2.91,0.07-3.99c1.4-2.59,3.26-4.93,4.93-7.36C487.15,286.41,487.12,286.38,487.12,286.38z"/>
            <path onClick={() => props.onFill(253)} fill={props.fillColors[253]} d="M113.38,604.94c-5.95-0.74-6.37-1.47-4.98-7.93C114.24,596.32,115.12,597.65,113.38,604.94z"/>
            <path onClick={() => props.onFill(254)} fill={props.fillColors[254]} d="M117.47,452.25c1.56,0.44,3.18,0.73,4.66,1.35c2.54,1.07,4.98,2.34,7.81,3.69
                c0.79-1.42,1.56-2.79,2.32-4.15c1.38,4.84,0.31,6.03-4.55,5.73c-4.75-0.29-7.91-2.75-10.27-6.6
                C117.43,452.29,117.47,452.25,117.47,452.25z"/>
            <path onClick={() => props.onFill(255)} fill={props.fillColors[255]} d="M820.82,1148.77c5.19-3.76,10.37-7.52,15.56-11.28c-4.49,4.73-5.43,14.38-15.58,11.25
                C820.8,1148.74,820.82,1148.77,820.82,1148.77z"/>
            <path onClick={() => props.onFill(256)} fill={props.fillColors[256]} d="M864.78,1344.63c9.44,5.96,16.78,13.76,20.71,22.72c-6.31-6.94-13.5-14.85-20.69-22.76
                C864.81,1344.6,864.78,1344.63,864.78,1344.63z"/>
            <path onClick={() => props.onFill(257)} fill={props.fillColors[257]} d="M267.95,1120.32c2.16,4.32,3.91,7.84,5.69,11.39c-6.38,1.4-7.3,0.56-6.36-5.55
                C267.52,1124.53,267.65,1122.88,267.95,1120.32z"/>
            <path onClick={() => props.onFill(258)} fill={props.fillColors[258]} d="M109.92,649.7c-3.34-3.17-6.68-6.33-10.02-9.5c0.47-0.5,0.94-1.01,1.4-1.51c6.69-0.59,5.75,7.23,9.7,9.66
                C110.64,648.8,110.28,649.25,109.92,649.7z"/>
            <path onClick={() => props.onFill(259)} fill={props.fillColors[259]} d="M188.35,677.02c-0.81-3.98-1.61-7.95-2.45-12.12C192.48,666.66,193.03,668.8,188.35,677.02z"/>
            <path onClick={() => props.onFill(260)} fill={props.fillColors[260]} d="M105.47,442.38c-4.17-3.49-8.34-6.98-12.52-10.47c0.45-0.53,0.9-1.07,1.35-1.6
                c4.17,3.39,8.35,6.78,12.52,10.18C106.37,441.12,105.92,441.75,105.47,442.38z"/>
            <path onClick={() => props.onFill(261)} fill={props.fillColors[261]} d="M191.41,680.11c-0.59,1.45-1.18,2.9-1.78,4.35c-0.55,0-1.1,0-1.65-0.01c-4.59-8.07-5.37-17.04-5.24-23.95
                c0.93,4.53,2.21,11.26,3.73,17.94c0.65,2.83,2.33,4.03,4.97,1.7L191.41,680.11z"/>
            <path onClick={() => props.onFill(262)} fill={props.fillColors[262]} d="M1043.63,933.9c1.71,0.98,3.44,1.93,5.13,2.95c1.49,0.9,2.94,1.86,4.88,3.1c-0.74,1.23-1.72,2.85-3.13,5.2
                c-2.53-4.11-4.72-7.66-6.91-11.22C1043.6,933.92,1043.63,933.9,1043.63,933.9z"/>
            <path onClick={() => props.onFill(263)} fill={props.fillColors[263]} d="M215.05,244.94c-0.4-1.64-1.65-4.2-1.05-4.76c3.29-3.04,1.07-4.73-2.2-7.08c1.89-0.37,2.8-0.55,3.71-0.73
                c0.71,3.98,1.43,7.95,2.14,11.93C216.79,244.51,215.92,244.72,215.05,244.94z"/>
            <path onClick={() => props.onFill(264)} fill={props.fillColors[264]} d="M472.12,278.44c-2.13-4.45-6.18-8.64-2.58-13.88c0.64-0.08,1.28-0.17,1.92-0.25
                c0.24,4.69,0.48,9.38,0.71,14.07L472.12,278.44z"/>
            <path onClick={() => props.onFill(265)} fill={props.fillColors[265]} d="M117.47,644.14c-2.18-3.6-4.35-7.2-6.72-11.12c4.9-0.78,4.97-0.8,5.5,2.29c0.5,2.92,0.83,5.88,1.24,8.82
                C117.49,644.12,117.47,644.14,117.47,644.14z"/>
            <path onClick={() => props.onFill(266)} fill={props.fillColors[266]} d="M170.44,440.3c0,4.01,0,8.03,0,13.14c-5.29-4.89-1.56-9.23-1.7-13.31
                C169.31,440.18,169.88,440.24,170.44,440.3z"/>
            <path onClick={() => props.onFill(267)} fill={props.fillColors[267]} d="M172.38,432.24c0-4.09,0-9.2,0-15.43C175.08,420.6,174.65,425.86,172.38,432.24z"/>
            <path onClick={() => props.onFill(268)} fill={props.fillColors[268]} d="M106.49,478.25c-3.29-2.32-6.58-4.65-10.25-7.24c5.35-2.59,5.45-2.55,7.36,1.53
                C104.5,474.46,105.52,476.34,106.49,478.25L106.49,478.25z"/>
            <path onClick={() => props.onFill(269)} fill={props.fillColors[269]} d="M116.81,462.85c-2.35,0-4.77,0.31-7.03-0.15c-0.97-0.2-1.62-1.99-2.42-3.06c1.21-0.32,2.57-1.16,3.61-0.87
                c2.12,0.59,4.07,1.79,6.08,2.74C116.97,461.96,116.89,462.4,116.81,462.85z"/>
            <path onClick={() => props.onFill(270)} fill={props.fillColors[270]} d="M851.86,1378.61c-5.43,6.37-6.75,6.45-15.03,0.97c0,0,0.04-0.05,0.04-0.05c2.64-0.98,5.28-1.96,7.92-2.93
                l0.01,0.01c-0.7,1.56-1.4,3.11-2.11,4.67c0.32,0.36,0.65,0.71,0.97,1.07c2.72-1.26,5.43-2.52,8.15-3.78
                C851.81,1378.56,851.86,1378.61,851.86,1378.61z"/>
            <path onClick={() => props.onFill(271)} fill={props.fillColors[271]} d="M837.81,1327.62c5.02,3.93,10.04,7.85,15.86,12.41c-2.81,0-4.54,0-5.88,0c-0.72-6.34-6.97-8.12-9.99-12.39
                L837.81,1327.62z"/>
            <path onClick={() => props.onFill(272)} fill={props.fillColors[272]} d="M136.65,453.85c2.99,1.45,5.8,2.81,9.36,4.53C137,459.84,135.74,459.25,136.65,453.85z"/>
            <path onClick={() => props.onFill(273)} fill={props.fillColors[273]} d="M96.5,679.12c1.24,0.23,2.48,0.65,3.72,0.63c1.34-0.03,2.68-0.47,4.38-0.8c0.33,1.15,1.04,3.59,1.75,6.04
                c-0.41,0.36-0.82,0.71-1.23,1.07c-2.87-2.32-5.73-4.64-8.6-6.96C96.52,679.09,96.5,679.12,96.5,679.12z"/>
            <path onClick={() => props.onFill(274)} fill={props.fillColors[274]} d="M342.24,259.99c-2.05-2.87-3.56-4.99-5.39-7.56C343.66,252.14,344.69,253.48,342.24,259.99z"/>
            <path onClick={() => props.onFill(275)} fill={props.fillColors[275]} d="M131.15,442.88c-5.54-1.31-3.04-5.68-3.71-8.75C130.46,436.24,134.23,438.04,131.15,442.88z"/>
            <path onClick={() => props.onFill(276)} fill={props.fillColors[276]} d="M909.85,397.62c-5.08-3.53-5.09-3.6,0-8.88C909.85,392.09,909.85,394.8,909.85,397.62z"/>
            <path onClick={() => props.onFill(277)} fill={props.fillColors[277]} d="M143.78,221.85c4,0.3,4,0.3,3.99,9.65C146.48,228.4,145.2,225.29,143.78,221.85z"/>
            <path onClick={() => props.onFill(278)} fill={props.fillColors[278]} d="M101.88,601.16c-0.58-2.39-1.33-4.76-1.63-7.19c-0.08-0.66,1.19-1.5,1.83-2.25
                c0.5,0.71,1.42,1.41,1.44,2.13c0.04,2.37-0.24,4.75-0.4,7.13C102.71,601.04,102.3,601.1,101.88,601.16z"/>
            <path onClick={() => props.onFill(279)} fill={props.fillColors[279]} d="M151.4,640.18c4.57,3.6,3.05,8.02,0.71,12.51c-0.22-4.18-0.44-8.36-0.66-12.55L151.4,640.18z"/>
            <path onClick={() => props.onFill(280)} fill={props.fillColors[280]} d="M742.03,335.79c3.32,1.65,6.64,3.3,9.96,4.95c-0.18,0.39-0.35,0.79-0.53,1.18
                c-3.48-1.28-6.96-2.55-10.44-3.83C741.35,337.33,741.69,336.56,742.03,335.79z"/>
            <path onClick={() => props.onFill(281)} fill={props.fillColors[281]} d="M793.82,973.87c-0.8,3.93-1.59,7.86-2.39,11.8c-1.22-4.45-2-8.8,2.43-11.75
                C793.87,973.91,793.82,973.87,793.82,973.87z"/>
            <path onClick={() => props.onFill(282)} fill={props.fillColors[282]} d="M115.3,683.86c-5.94,2.25-7.18-0.76-7.98-5.5C110.15,680.31,112.44,681.89,115.3,683.86z"/>
            <path onClick={() => props.onFill(283)} fill={props.fillColors[283]} d="M102.5,651.13c5.75,3.71,6,5.85,1.31,13.14c-1.57-4.43,4.41-9.46-1.3-13.11L102.5,651.13z"/>
            <path onClick={() => props.onFill(284)} fill={props.fillColors[284]} d="M1062.59,21.59c1.86-6.09,3.73-12.19,5.59-18.28c0.46,0.16,0.92,0.31,1.38,0.47
                c-2.32,5.94-4.64,11.88-6.96,17.82C1062.6,21.61,1062.59,21.59,1062.59,21.59z"/>
            <path onClick={() => props.onFill(285)} fill={props.fillColors[285]} d="M134.02,418.53c0.58,2.72,1.16,5.44,1.75,8.17c-0.55,0.14-1.1,0.28-1.65,0.42c-0.83-2.67-1.65-5.34-2.48-8
                C132.43,418.92,133.23,418.73,134.02,418.53z"/>
            <path onClick={() => props.onFill(286)} fill={props.fillColors[286]} d="M180.15,843.99c4.09,3.16,2.81,5.57,0,8.72C180.15,849.54,180.15,847.12,180.15,843.99z"/>
            <path onClick={() => props.onFill(287)} fill={props.fillColors[287]} d="M561.03,528.25c4.38,0.71,8.77,1.42,13.15,2.13c-0.17,0.64-0.34,1.28-0.52,1.92
                c-4.2-1.36-8.4-2.73-12.6-4.09C561.07,528.2,561.03,528.25,561.03,528.25z"/>
            <path onClick={() => props.onFill(288)} fill={props.fillColors[288]} d="M95.49,668.13c3.27,1.56,6.04,3.46,4.53,8.25c-1.5-2.76-3.01-5.51-4.51-8.27
                C95.52,668.11,95.49,668.13,95.49,668.13z"/>
            <path onClick={() => props.onFill(289)} fill={props.fillColors[289]} d="M318.28,1269.67c-2,6.65-4,13.3-6.01,19.95c0,0,0.04,0.04,0.04,0.04c2-6.65,4-13.31,5.99-19.96
                C318.31,1269.7,318.28,1269.67,318.28,1269.67z"/>
            <path onClick={() => props.onFill(290)} fill={props.fillColors[290]} d="M182.97,863.94c-0.87-2.21-1.73-4.42-2.6-6.62c0.57-0.23,1.14-0.46,1.72-0.69
                c0.92,2.18,1.85,4.37,2.77,6.55C184.23,863.43,183.6,863.69,182.97,863.94z"/>
            <path onClick={() => props.onFill(291)} fill={props.fillColors[291]} d="M188.42,1085.22c6.63,0,13.25,0,19.88,0c0,0.04,0,0.08,0,0.12c-6.63,0-13.25,0-19.88,0
                C188.42,1085.29,188.42,1085.26,188.42,1085.22z"/>
            <path onClick={() => props.onFill(292)} fill={props.fillColors[292]} d="M371.21,281.38c-2.99,2.33-5.99,4.65-8.98,6.98c0,0,0.05,0.04,0.05,0.04c2-2.99,4-5.98,6-8.97l-0.04-0.04
                c1,0.67,2.01,1.34,3.01,2.01C371.24,281.41,371.21,281.38,371.21,281.38z"/>
            <path onClick={() => props.onFill(293)} fill={props.fillColors[293]} d="M256.37,1319.64c3.32-3.67,6.64-7.34,9.95-11c0,0,0.01,0.01,0.01,0.01c-3.33,3.65-6.66,7.31-10,10.96
                C256.34,1319.61,256.37,1319.64,256.37,1319.64z"/>
            <path onClick={() => props.onFill(294)} fill={props.fillColors[294]} d="M240.38,452.29c2.08-1.87,3.42-5.65,8.72-2.16c-3.66,0.89-6.2,1.51-8.74,2.13
                C240.36,452.26,240.38,452.29,240.38,452.29z"/>
            <path onClick={() => props.onFill(295)} fill={props.fillColors[295]} d="M113.5,437.27c-5.34-2.24-5.34-2.24-6.66-8.69c2.63,3.45,4.63,6.09,6.63,8.72
                C113.47,437.3,113.5,437.27,113.5,437.27z"/>
            <path onClick={() => props.onFill(296)} fill={props.fillColors[296]} d="M492.14,309.37c-1.97-1.63-3.93-3.25-6.22-5.14c3.69-1.08,3.69-1.08,6.21,5.16L492.14,309.37z"/>
            <path onClick={() => props.onFill(297)} fill={props.fillColors[297]} d="M509.12,288.4c2.32-2.07,4.63-4.14,6.95-6.21c0.44,0.57,0.88,1.15,1.32,1.72
                c-2.76,1.49-5.52,2.99-8.28,4.48L509.12,288.4z"/>
            <path onClick={() => props.onFill(298)} fill={props.fillColors[298]} d="M253.51,1472.79c-1.56-1.97-2.52-3.2-3.56-4.51C253.92,1467.31,253.92,1467.31,253.51,1472.79z"/>
            <path onClick={() => props.onFill(299)} fill={props.fillColors[299]} d="M147.45,673.12c1.34,0.01,2.69,0.02,4.03,0.03l-0.03-0.03c-0.18,2.06-0.36,4.11-0.53,6.17
                c-0.3,0.08-0.61,0.17-0.91,0.25c-0.85-2.14-1.7-4.29-2.54-6.43L147.45,673.12z"/>
            <path onClick={() => props.onFill(300)} fill={props.fillColors[300]} d="M512.52,1056.29c4.56,0,9.13,0,13.69,0c0,0.09,0,0.17,0,0.26c-4.53,0-9.05,0-13.58,0
                C512.6,1056.47,512.56,1056.38,512.52,1056.29z"/>
            <path onClick={() => props.onFill(301)} fill={props.fillColors[301]} d="M112.96,428.6c-1.08-1.53-2.17-3.06-3.25-4.59c0.29-0.25,0.59-0.5,0.88-0.75
                c1.34,1.43,2.67,2.86,4.01,4.29C114.06,427.9,113.51,428.25,112.96,428.6z"/>
            <path onClick={() => props.onFill(302)} fill={props.fillColors[302]} d="M1160.93,277.28c0-4.26,0-8.52,0-12.78c0.05,0,0.1,0,0.15,0c0,4.21,0,8.41,0,12.62
                C1161.03,277.17,1160.98,277.23,1160.93,277.28z"/>
            <path onClick={() => props.onFill(303)} fill={props.fillColors[303]} d="M265.32,1470.46c1.38,0.42,2.76,0.84,4.13,1.25c-0.15,0.54-0.29,1.08-0.44,1.62
                c-1.51-0.39-3.02-0.79-4.53-1.18C264.77,1471.59,265.04,1471.02,265.32,1470.46z"/>
            <path onClick={() => props.onFill(304)} fill={props.fillColors[304]} d="M151.48,673.14c0-3.99,0-7.99,0-11.98c0.57,0.04,1.14,0.09,1.71,0.13c-0.58,3.94-1.16,7.88-1.74,11.83
                C151.45,673.12,151.48,673.14,151.48,673.14z"/>
            <path onClick={() => props.onFill(305)} fill={props.fillColors[305]} d="M149.44,238.45c5.03,1.12,3.49,5.89,5,8.97c0,0,0.03-0.03,0.03-0.03c-1.67-2.99-3.33-5.98-5-8.97
                L149.44,238.45z"/>
            <path onClick={() => props.onFill(306)} fill={props.fillColors[306]} d="M285.34,551.21c2.41-2.3,4.82-4.59,7.23-6.89c0.28,0.34,0.56,0.68,0.84,1.02
                c-2.7,1.95-5.39,3.89-8.09,5.84L285.34,551.21z"/>
            <path onClick={() => props.onFill(307)} fill={props.fillColors[307]} d="M1009.62,1051.8c-2,4-3.99,8-5.99,12.01l0.03,0.03c2-4,4-8,6-12
                C1009.67,1051.84,1009.62,1051.8,1009.62,1051.8z"/>
            <path onClick={() => props.onFill(308)} fill={props.fillColors[308]} d="M1039.62,927.91c-1.67-2.33-3.33-4.66-5-6.99l0,0.02c1.66,2.33,3.32,4.66,4.98,6.99L1039.62,927.91z"/>
            <path onClick={() => props.onFill(309)} fill={props.fillColors[309]} d="M142.46,448.27c-1.89-0.69-3.79-1.37-5.68-2.06c0.37-0.65,0.74-1.3,1.11-1.95
                c1.52,1.34,3.04,2.68,4.56,4.02C142.45,448.28,142.46,448.27,142.46,448.27z"/>
            <path onClick={() => props.onFill(310)} fill={props.fillColors[310]} d="M93.06,674.95c-1.24-0.57-2.48-1.14-3.73-1.71c0.24-0.52,0.48-1.04,0.72-1.57
                c1.22,0.64,2.44,1.28,3.66,1.92C93.5,674.05,93.28,674.5,93.06,674.95z"/>
            <path onClick={() => props.onFill(311)} fill={props.fillColors[311]} d="M1085.35,2.69c-0.52,1.28-1.05,2.57-1.57,3.85c-0.33-0.1-0.66-0.2-0.98-0.3c0.28-1.35,0.55-2.7,0.83-4.05
                C1084.19,2.36,1084.77,2.53,1085.35,2.69z"/>
            <path onClick={() => props.onFill(312)} fill={props.fillColors[312]} d="M145.58,675.48c0.42,1.42,0.85,2.84,1.27,4.25c-0.5,0.12-0.99,0.24-1.49,0.36
                c-0.24-1.46-0.47-2.92-0.71-4.37C144.96,675.63,145.27,675.55,145.58,675.48z"/>
            <path onClick={() => props.onFill(313)} fill={props.fillColors[313]} d="M100.1,428.35c0.95,0,1.91,0,2.86,0c-0.09,0.77-0.19,1.53-0.28,2.3c-0.94-0.27-1.88-0.53-2.82-0.8
                C99.94,429.35,100.02,428.85,100.1,428.35z"/>
            <path onClick={() => props.onFill(314)} fill={props.fillColors[314]} d="M705.92,457.26c0.35,4.27,0.7,8.54,1.05,12.81c-0.34,0.01-0.68,0.02-1.02,0.04c0-4.29,0-8.58,0-12.87
                C705.96,457.24,705.92,457.26,705.92,457.26z"/>
            <path onClick={() => props.onFill(315)} fill={props.fillColors[315]} d="M167.77,419.52c0,1.48,0,2.96,0,4.45c-0.24,0.02-0.49,0.03-0.73,0.05c-0.17-1.49-0.35-2.99-0.52-4.48
                C166.93,419.53,167.35,419.53,167.77,419.52z"/>
            <path onClick={() => props.onFill(316)} fill={props.fillColors[316]} d="M122.26,530.81c-0.74,0.9-1.49,1.8-2.23,2.71c-0.44-0.38-0.88-0.76-1.32-1.14c0.8-0.85,1.61-1.7,2.41-2.54
                C121.5,530.16,121.88,530.48,122.26,530.81z"/>
            <path onClick={() => props.onFill(317)} fill={props.fillColors[317]} d="M489.14,310.37c-2.17-1.68-4.34-3.37-6.51-5.05c0.23-0.27,0.47-0.55,0.7-0.82
                c1.93,1.97,3.85,3.93,5.78,5.9C489.11,310.39,489.14,310.37,489.14,310.37z"/>
            <path onClick={() => props.onFill(318)} fill={props.fillColors[318]} d="M112.49,450.19c1.66,2.03,3.33,4.05,4.99,6.08l-0.01,0.01c-3.79-0.24-4.72-2.88-5.04-6.03
                C112.44,450.25,112.49,450.19,112.49,450.19z"/>
            <path onClick={() => props.onFill(319)} fill={props.fillColors[319]} d="M91.5,432.29c-2.68,0.27-4.16-0.31-2.15-3.99c0.89,1.64,1.52,2.8,2.16,3.97L91.5,432.29z"/>
            <path onClick={() => props.onFill(320)} fill={props.fillColors[320]} d="M180.43,1384.57c-0.71-2.63-1.42-5.25-2.12-7.88c0.35-0.08,0.69-0.15,1.04-0.23
                c0.35,2.71,0.71,5.42,1.06,8.13L180.43,1384.57z"/>
            <path onClick={() => props.onFill(321)} fill={props.fillColors[321]} d="M1040.62,928.91c0.92,0.07,1.84,0.15,3.01,0.24c0,1.65,0,3.2,0,4.74c0,0-0.03,0.02-0.03,0.02
                c-1-1.66-1.99-3.33-2.99-4.99L1040.62,928.91z"/>
            <path onClick={() => props.onFill(322)} fill={props.fillColors[322]} d="M651.65,444.4c0,2.26,0,4.52,0,6.79c-0.08,0-0.16,0-0.24,0c0-2.25,0-4.49,0-6.74
                C651.5,444.43,651.58,444.41,651.65,444.4z"/>
            <path onClick={() => props.onFill(323)} fill={props.fillColors[323]} d="M142.8,637.58c0.08-1.23,0.15-2.47,0.23-3.7c0.31,0.02,0.62,0.05,0.93,0.07
                c-0.12,1.23-0.24,2.46-0.35,3.69C143.33,637.62,143.07,637.6,142.8,637.58z"/>
            <path onClick={() => props.onFill(324)} fill={props.fillColors[324]} d="M123.48,451.27c-1-1.42-2-2.83-3-4.25c0.5-0.26,1-0.52,1.51-0.77c0.49,1.68,0.99,3.36,1.48,5.03
                C123.47,451.28,123.48,451.27,123.48,451.27z"/>
            <path onClick={() => props.onFill(325)} fill={props.fillColors[325]} d="M138.45,440.33c-1.36-1.53-2.72-3.06-4.08-4.59c0.33-0.26,0.67-0.51,1-0.77c1.04,1.77,2.08,3.54,3.11,5.31
                C138.48,440.29,138.45,440.33,138.45,440.33z"/>
            <path onClick={() => props.onFill(326)} fill={props.fillColors[326]} d="M109.5,676.1c-2.33-2-4.66-4-7-6c0,0-0.03,0.03-0.03,0.03c2.33,2,4.67,4,7,6
                C109.48,676.12,109.5,676.1,109.5,676.1z"/>
            <path onClick={() => props.onFill(327)} fill={props.fillColors[327]} d="M189.42,1466.53c-0.36-0.7-0.72-1.41-1.35-2.62c1.44,0.23,2.51,0.4,4.69,0.75
                c-1.78,0.99-2.57,1.43-3.35,1.86C189.41,1466.52,189.42,1466.53,189.42,1466.53z"/>
            <path onClick={() => props.onFill(328)} fill={props.fillColors[328]} d="M164.94,458.58c0.55,0.88,1.1,1.77,1.65,2.65c-0.17,0.14-0.35,0.29-0.52,0.43
                c-0.69-0.78-1.39-1.57-2.08-2.35C164.31,459.06,164.63,458.82,164.94,458.58z"/>
            <path onClick={() => props.onFill(329)} fill={props.fillColors[329]} d="M129.58,461.85c1.41,0.19,2.82,0.39,4.24,0.58c-0.01,0.11-0.01,0.22-0.02,0.33c-1.41,0-2.81,0-4.22,0
                C129.57,462.46,129.58,462.15,129.58,461.85z"/>
            <path onClick={() => props.onFill(330)} fill={props.fillColors[330]} d="M94.16,463.92c0.84,0,1.68,0,2.52,0c0.02,0.23,0.03,0.46,0.05,0.7c-0.82,0.09-1.63,0.17-2.45,0.26
                C94.24,464.55,94.2,464.24,94.16,463.92z"/>
            <path onClick={() => props.onFill(331)} fill={props.fillColors[331]} d="M115.49,447.26c-1.33-1.66-2.66-3.32-3.99-4.99c0,0-0.02,0.02-0.02,0.02c1.33,1.66,2.66,3.33,3.98,4.99
                C115.46,447.29,115.49,447.26,115.49,447.26z"/>
            <path onClick={() => props.onFill(332)} fill={props.fillColors[332]} d="M89.56,467.71c1.36,0,2.72,0,4.07,0c0,0.07,0,0.14,0,0.2c-1.42,0-2.85,0-4.27,0
                C89.42,467.85,89.49,467.78,89.56,467.71z"/>
            <path onClick={() => props.onFill(333)} fill={props.fillColors[333]} d="M434.14,1086.84c2.39,0.14,4.78,0.28,7.17,0.42c-0.02,0.14-0.03,0.28-0.05,0.42
                c-2.36-0.3-4.72-0.59-7.09-0.89C434.18,1086.8,434.14,1086.84,434.14,1086.84z"/>
            <path onClick={() => props.onFill(334)} fill={props.fillColors[334]} d="M345.26,263.42c-0.29,0.47-0.59,0.94-0.88,1.4c-1.38-1.13-2.75-2.27-4.13-3.4c0,0,0.02-0.02,0.02-0.02
                c1.67,0.68,3.34,1.36,5.02,2.04L345.26,263.42z"/>
            <path onClick={() => props.onFill(335)} fill={props.fillColors[335]} d="M130.46,430.3c-0.94-1.18-1.89-2.37-2.83-3.55c0.42-0.25,0.84-0.49,1.26-0.74
                c0.53,1.43,1.06,2.85,1.58,4.28C130.47,430.29,130.46,430.3,130.46,430.3z"/>
            <path onClick={() => props.onFill(336)} fill={props.fillColors[336]} d="M154.48,696.06c-0.7-1.6-1.4-3.21-2.1-4.81c0.23-0.08,0.46-0.16,0.69-0.24c0.45,1.7,0.91,3.4,1.36,5.09
                C154.43,696.1,154.48,696.06,154.48,696.06z"/>
            <path onClick={() => props.onFill(337)} fill={props.fillColors[337]} d="M934.75,1006.83c-2.68-0.65-5.36-1.3-8.03-1.95c0,0,0.01-0.02,0.01-0.02c2.66,0.67,5.32,1.34,7.99,2.01
                C934.71,1006.88,934.75,1006.83,934.75,1006.83z"/>
            <path onClick={() => props.onFill(338)} fill={props.fillColors[338]} d="M135.47,441.37c-0.2,0.75-0.39,1.49-0.59,2.24c-0.23-0.06-0.45-0.12-0.68-0.17
                c0.21-0.8,0.43-1.59,0.64-2.39C135.05,441.15,135.26,441.26,135.47,441.37z"/>
            <path onClick={() => props.onFill(339)} fill={props.fillColors[339]} d="M329.73,1228.96c0-0.8,0-1.61,0-2.41c0.02,0,0.03,0,0.05,0c0,0.8,0,1.61,0,2.41
                C329.76,1228.96,329.74,1228.96,329.73,1228.96z"/>
            <ellipse onClick={() => props.onFill(340)} fill={props.fillColors[340]} cx="1117.04" cy="956.88" rx="0.1" ry="1.84"/>
            <path onClick={() => props.onFill(341)} fill={props.fillColors[341]} d="M357.84,1.96c0.87,0.04,1.75,0.08,2.62,0.12c-0.02,0.21-0.05,0.42-0.07,0.63
                c-0.86-0.16-1.71-0.32-2.57-0.48C357.83,2.14,357.84,2.05,357.84,1.96z"/>
            <path onClick={() => props.onFill(342)} fill={props.fillColors[342]} d="M144.04,645.46c-0.27,0.51-0.54,1.02-0.82,1.54c-0.13-0.05-0.26-0.11-0.39-0.16
                c0.18-0.55,0.36-1.1,0.53-1.65C143.59,645.27,143.82,645.36,144.04,645.46z"/>
            <path onClick={() => props.onFill(343)} fill={props.fillColors[343]} d="M266.32,564.16c1.67-0.99,3.34-1.97,5.02-2.96l-0.01-0.01c-1.65,1.01-3.3,2.02-4.95,3.03
                C266.38,564.22,266.32,564.16,266.32,564.16z"/>
            <path onClick={() => props.onFill(344)} fill={props.fillColors[344]} d="M208.38,1375.6c-1,1.66-1.99,3.32-2.99,4.98l0.01,0.01c1-1.65,2.01-3.31,3.01-4.96
                C208.42,1375.63,208.38,1375.6,208.38,1375.6z"/>
            <path onClick={() => props.onFill(345)} fill={props.fillColors[345]} d="M1118.23,945.62c-0.08,0.57-0.16,1.15-0.25,1.72c-0.03-0.59-0.06-1.19-0.09-1.78
                C1118.01,945.58,1118.12,945.6,1118.23,945.62z"/>
            <path onClick={() => props.onFill(346)} fill={props.fillColors[346]} d="M474.13,285.42c0.6,1.11,1.19,2.23,1.79,3.34c-0.23,0.1-0.46,0.2-0.68,0.29
                c-0.35-1.23-0.71-2.45-1.06-3.68L474.13,285.42z"/>
            <path onClick={() => props.onFill(347)} fill={props.fillColors[347]} d="M110.45,1080.84c1.41,0.12,2.81,0.23,4.22,0.35c-0.02,0.14-0.05,0.28-0.07,0.42
                c-1.37-0.27-2.74-0.54-4.11-0.81C110.49,1080.8,110.45,1080.84,110.45,1080.84z"/>
            <path onClick={() => props.onFill(348)} fill={props.fillColors[348]} d="M717.89,186.49c-0.67-1.02-1.33-2.04-2-3.06c0.19-0.1,0.38-0.2,0.58-0.3c0.49,1.11,0.98,2.21,1.48,3.32
                C717.95,186.44,717.89,186.49,717.89,186.49z"/>
            <path onClick={() => props.onFill(349)} fill={props.fillColors[349]} d="M202.01,426.8c-0.02,0.41-0.04,0.82-0.06,1.22c-0.04,0-0.09,0-0.13-0.01c0.02-0.41,0.04-0.82,0.06-1.22
                C201.92,426.79,201.96,426.8,202.01,426.8z"/>
            <path onClick={() => props.onFill(350)} fill={props.fillColors[350]} d="M359.24,292.39c1.01-1.33,2.02-2.66,3.04-3.99c0,0-0.05-0.04-0.05-0.04c-0.99,1.35-1.97,2.71-2.96,4.06
                L359.24,292.39z"/>
            <path onClick={() => props.onFill(351)} fill={props.fillColors[351]} d="M378.29,276.4c-1.35,1-2.7,2.01-4.05,3.01l-0.03-0.02c1.34-1.01,2.68-2.02,4.01-3.04
                C378.23,276.36,378.29,276.4,378.29,276.4z"/>
            <path onClick={() => props.onFill(352)} fill={props.fillColors[352]} d="M129.51,450.88c-0.2,0.25-0.38,0.48-0.56,0.71c-0.16-0.26-0.43-0.52-0.44-0.79
                c-0.01-0.21,0.32-0.43,0.49-0.64C129.17,450.39,129.34,450.64,129.51,450.88z"/>
            <path onClick={() => props.onFill(353)} fill={props.fillColors[353]} d="M103.71,461.67c-0.25-0.15-0.5-0.3-0.74-0.45c0.2-0.17,0.39-0.37,0.61-0.49c0.07-0.04,0.38,0.15,0.37,0.2
                C103.91,461.19,103.8,461.43,103.71,461.67z"/>
            <path onClick={() => props.onFill(354)} fill={props.fillColors[354]} d="M279.32,1252.68c0.23,1.05,0.47,2.09,0.7,3.14c-0.09,0.02-0.18,0.04-0.26,0.05
                c-0.14-1.07-0.28-2.14-0.42-3.21C279.34,1252.67,279.32,1252.68,279.32,1252.68z"/>
            <path onClick={() => props.onFill(355)} fill={props.fillColors[355]} d="M276.95,252.5c-0.06,0.13-0.14,0.3-0.22,0.47c-0.01-0.15-0.05-0.31-0.01-0.45
                c0.05-0.18,0.15-0.34,0.23-0.51C276.95,252.16,276.95,252.31,276.95,252.5z"/>
            <path onClick={() => props.onFill(356)} fill={props.fillColors[356]} d="M156.46,701.09c-0.66-1.68-1.32-3.36-1.98-5.03c0,0-0.05,0.04-0.05,0.04c0.66,1.67,1.33,3.35,1.99,5.02
                C156.42,701.12,156.46,701.09,156.46,701.09z"/>
            <path onClick={() => props.onFill(357)} fill={props.fillColors[357]} d="M120.96,1082.14c1.07,0.06,1.22,0.07,1.36,0.07c-0.05,0.07-0.09,0.19-0.14,0.19
                C122.08,1082.42,121.96,1082.37,120.96,1082.14z"/>
            <path onClick={() => props.onFill(358)} fill={props.fillColors[358]} d="M769.85,1013.94c-0.46-0.58-0.92-1.16-1.39-1.75c0.13-0.08,0.25-0.16,0.38-0.23
                c0.35,0.64,0.7,1.29,1.05,1.93C769.9,1013.89,769.85,1013.94,769.85,1013.94z"/>
            <path onClick={() => props.onFill(359)} fill={props.fillColors[359]} d="M265.35,1105.8c1-1.01,1.99-2.01,2.99-3.02c0,0,0.03,0.03,0.03,0.03c-1.01,0.99-2.02,1.98-3.04,2.98
                C265.34,1105.78,265.35,1105.8,265.35,1105.8z"/>
            <path onClick={() => props.onFill(360)} fill={props.fillColors[360]} d="M877.45,625.66c-0.06,1.03-0.07,1.19-0.08,1.34c-0.08-0.05-0.21-0.09-0.21-0.15
                C877.15,626.74,877.21,626.62,877.45,625.66z"/>
            <path onClick={() => props.onFill(361)} fill={props.fillColors[361]} d="M118.88,636.29c0.07,0.06,0.2,0.1,0.21,0.17c0.02,0.15-0.01,0.32-0.02,0.48c-0.07-0.06-0.2-0.1-0.21-0.17
                C118.84,636.61,118.87,636.45,118.88,636.29z"/>
            <path onClick={() => props.onFill(362)} fill={props.fillColors[362]} d="M120.49,645.13c-0.25-0.71-0.5-1.42-0.75-2.13c0.13-0.03,0.26-0.07,0.38-0.1
                c0.11,0.76,0.21,1.51,0.32,2.27C120.45,645.17,120.49,645.13,120.49,645.13z"/>
            <path onClick={() => props.onFill(363)} fill={props.fillColors[363]} d="M970.2,1117.84c0,0.29,0,0.59,0,0.88C970.2,1118.43,970.2,1118.13,970.2,1117.84z"/>
            <path onClick={() => props.onFill(364)} fill={props.fillColors[364]} d="M544.04,277.87c0.15,0.03,0.32,0.01,0.41,0.09c0.1,0.09,0.11,0.27,0.17,0.41
                c-0.19-0.03-0.39-0.02-0.57-0.1C544,278.25,544.04,278.01,544.04,277.87z"/>
            <path onClick={() => props.onFill(365)} fill={props.fillColors[365]} d="M434.84,274.96c-0.06,1.02-0.06,1.17-0.07,1.31c-0.07-0.05-0.19-0.09-0.2-0.14
                C434.56,276.02,434.61,275.91,434.84,274.96z"/>
            <path onClick={() => props.onFill(366)} fill={props.fillColors[366]} d="M314.3,1468.54c0.71-0.24,1.43-0.48,2.14-0.72c0.03,0.15,0.06,0.29,0.1,0.44
                c-0.76,0.08-1.52,0.15-2.28,0.23C314.26,1468.49,314.3,1468.54,314.3,1468.54z"/>
            <path onClick={() => props.onFill(367)} fill={props.fillColors[367]} d="M181.06,1357.33c-0.07,0.04-0.15,0.08-0.23,0.12c0-0.15-0.03-0.31,0-0.46c0.01-0.05,0.15-0.08,0.23-0.12
                C181.06,1357.02,181.06,1357.18,181.06,1357.33z"/>
            <path onClick={() => props.onFill(368)} fill={props.fillColors[368]} d="M194.07,670.4c-0.01,0.14-0.01,0.27-0.01,0.41c-0.1-0.07-0.25-0.12-0.28-0.21
                c-0.03-0.11,0.04-0.26,0.07-0.39C193.93,670.27,194.01,670.33,194.07,670.4z"/>
            <path onClick={() => props.onFill(369)} fill={props.fillColors[369]} d="M168.69,432.1c-0.01-0.16-0.03-0.32-0.04-0.49c0.11,0.03,0.31,0.03,0.33,0.09
                c0.05,0.15,0.03,0.32,0.03,0.49C168.9,432.16,168.79,432.13,168.69,432.1z"/>
            <path onClick={() => props.onFill(370)} fill={props.fillColors[370]} d="M345.29,263.45c0.14-0.75,0.27-1.49,0.41-2.24c0.07,0.02,0.14,0.04,0.2,0.06
                c-0.21,0.72-0.43,1.43-0.64,2.15C345.26,263.42,345.29,263.45,345.29,263.45z"/>
            <path onClick={() => props.onFill(371)} fill={props.fillColors[371]} d="M400.81,595.96c-0.04,0.13-0.08,0.25-0.12,0.38c-0.17-0.12-0.34-0.24-0.51-0.36
                c0.11-0.08,0.21-0.22,0.32-0.22C400.59,595.75,400.7,595.88,400.81,595.96z"/>
            <path onClick={() => props.onFill(372)} fill={props.fillColors[372]} d="M124.39,421.54c0.02,0.14,0.03,0.28,0.05,0.42c-0.23-0.04-0.48-0.04-0.69-0.13
                c-0.06-0.03-0.04-0.27-0.06-0.41C123.92,421.46,124.16,421.5,124.39,421.54z"/>
            <path onClick={() => props.onFill(373)} fill={props.fillColors[373]} d="M799.81,551.16c-0.04,0.55-0.09,1.1-0.01,0.18c-0.37,0.85-0.15,0.36,0.06-0.14
                C799.85,551.2,799.81,551.16,799.81,551.16z"/>
            <polygon onClick={() => props.onFill(374)} fill={props.fillColors[374]} points="280.9,1261.79 280.72,1261.66 280.86,1261.45 		"/>
            <path onClick={() => props.onFill(375)} fill={props.fillColors[375]} d="M673.98,425.28c-0.67-0.99-1.34-1.99-2.01-2.98l-0.02,0.01c0.66,1,1.32,2,1.99,3L673.98,425.28z"/>
            <path onClick={() => props.onFill(376)} fill={props.fillColors[376]} d="M338.27,259.42c-0.66-1-1.32-2-1.98-3c0,0-0.03,0.03-0.03,0.03C336.93,257.44,337.6,258.43,338.27,259.42
                L338.27,259.42z"/>
            <path onClick={() => props.onFill(377)} fill={props.fillColors[377]} d="M1063.6,179.47c0.09-0.49,0.18-0.97,0.03-0.18c0.31-0.75,0.12-0.3-0.06,0.15
                C1063.57,179.44,1063.6,179.47,1063.6,179.47z"/>
            <path onClick={() => props.onFill(378)} fill={props.fillColors[378]} d="M421.2,270.41c-0.2-0.44-0.4-0.87-0.08-0.17c-0.14-0.75-0.05-0.27,0.04,0.2
                C421.16,270.45,421.2,270.41,421.2,270.41z"/>
            <path onClick={() => props.onFill(379)} fill={props.fillColors[379]} d="M242.4,1308.68c0.08-0.55,0.15-1.09,0.03-0.22c0.35-0.81,0.14-0.31-0.08,0.18
                C242.35,1308.64,242.4,1308.68,242.4,1308.68z"/>
            <path onClick={() => props.onFill(380)} fill={props.fillColors[380]} d="M834.81,68.57c-0.33-1.34-0.66-2.68-0.99-4.02c0,0-0.03,0.03-0.03,0.03c0.33,1.34,0.66,2.68,0.99,4.02
                C834.78,68.59,834.81,68.57,834.81,68.57z"/>
            <polygon onClick={() => props.onFill(381)} fill={props.fillColors[381]} points="435.6,270.31 435.78,270.44 435.63,270.66 		"/>
            <path onClick={() => props.onFill(382)} fill={props.fillColors[382]} d="M492.11,275.41c0.67-1,1.35-2,2.02-3l-0.01-0.01c-0.66,1.02-1.32,2.03-1.98,3.05L492.11,275.41z"/>
            <path onClick={() => props.onFill(383)} fill={props.fillColors[383]} d="M705.94,198.41c0.66,1.01,1.32,2.03,1.99,3.04c0,0,0,0.01,0,0.01c-0.67-1-1.35-2.01-2.02-3.01
                C705.91,198.46,705.94,198.41,705.94,198.41z"/>
            <path onClick={() => props.onFill(384)} fill={props.fillColors[384]} d="M972.7,1441.55c1-0.67,1.99-1.35,2.99-2.02c0,0,0.04,0.04,0.04,0.04c-1.02,0.65-2.04,1.31-3.05,1.96
                C972.67,1441.52,972.7,1441.55,972.7,1441.55z"/>
            <polygon onClick={() => props.onFill(385)} fill={props.fillColors[385]} points="629.54,330.2 629.59,330.53 629.4,330.48 		"/>
            <path onClick={() => props.onFill(386)} fill={props.fillColors[386]} d="M849.79,1350.63c0.49,0.19,0.99,0.38,0.1,0.04c0.71,0.64,0.32,0.29-0.07-0.06
                C849.81,1350.6,849.79,1350.63,849.79,1350.63z"/>
            <polygon onClick={() => props.onFill(387)} fill={props.fillColors[387]} points="627.53,321.21 627.59,321.56 627.4,321.5 		"/>
            <path onClick={() => props.onFill(388)} fill={props.fillColors[388]} d="M1084.55,1371.56c-0.66,1.02-1.31,2.03-1.97,3.05l-0.02-0.03c0.68-0.99,1.36-1.99,2.04-2.98
                C1084.6,1371.6,1084.55,1371.56,1084.55,1371.56z"/>
            <path onClick={() => props.onFill(389)} fill={props.fillColors[389]} d="M489.12,279.4c0.67-1.01,1.34-2.02,2-3.03c0,0,0.03,0.04,0.03,0.04c-0.67,1.01-1.34,2.02-2.01,3.03
                C489.15,279.43,489.12,279.4,489.12,279.4z"/>
            <path onClick={() => props.onFill(390)} fill={props.fillColors[390]} d="M566.07,322.36c-0.67-1-1.35-1.99-2.02-2.99c0,0,0.02-0.02,0.02-0.02c0.66,1.01,1.32,2.02,1.98,3.03
                C566.05,322.39,566.07,322.36,566.07,322.36z"/>
            <path onClick={() => props.onFill(391)} fill={props.fillColors[391]} d="M185.52,657.1c0.12,0.03,0.32,0.04,0.36,0.11c0.05,0.1-0.03,0.26-0.05,0.39
                c-0.12-0.04-0.31-0.04-0.35-0.11C185.43,657.4,185.5,657.24,185.52,657.1z"/>
            <path onClick={() => props.onFill(392)} fill={props.fillColors[392]} d="M112.92,531.62c-0.11-0.25-0.17-0.38-0.24-0.52c0.13-0.05,0.26-0.09,0.4-0.14
                C113.03,531.16,112.99,531.34,112.92,531.62z"/>
            <path onClick={() => props.onFill(393)} fill={props.fillColors[393]} d="M106.5,650.11c-1.01-0.66-2.01-1.32-3.02-1.98c0,0,0.02-0.02,0.02-0.02c0.99,0.67,1.98,1.34,2.98,2.02
                C106.47,650.14,106.5,650.11,106.5,650.11z"/>
            <path onClick={() => props.onFill(394)} fill={props.fillColors[394]} d="M559.05,314.48c-0.67-1.03-1.33-2.06-2-3.1c0,0,0.03-0.03,0.03-0.03c0.67,1.02,1.35,2.05,2.02,3.07
                C559.1,314.42,559.05,314.48,559.05,314.48z"/>
            <path onClick={() => props.onFill(395)} fill={props.fillColors[395]} d="M485.15,284.4c0.66-1.01,1.32-2.01,1.98-3.02c0,0,0.02,0.02,0.02,0.02c-0.67,0.99-1.34,1.99-2.01,2.98
                C485.13,284.39,485.15,284.4,485.15,284.4z"/>
            <path onClick={() => props.onFill(396)} fill={props.fillColors[396]} d="M489.11,310.39c1,0.67,2,1.33,3,2l0.02-0.02c-1-0.67-2-1.33-3-2
                C489.14,310.37,489.11,310.39,489.11,310.39z"/>
            <path onClick={() => props.onFill(397)} fill={props.fillColors[397]} d="M555.08,309.36c-0.66-1-1.33-2-1.99-3.01c0,0-0.03,0.03-0.03,0.03c0.67,1,1.33,2,2,3
                C555.06,309.39,555.08,309.36,555.08,309.36z"/>
            <path onClick={() => props.onFill(398)} fill={props.fillColors[398]} d="M861.79,1342.6c1,0.68,1.99,1.35,2.99,2.03c0,0,0.03-0.03,0.03-0.03c-1.01-0.66-2.03-1.32-3.05-1.97
                L861.79,1342.6z"/>
            <path onClick={() => props.onFill(399)} fill={props.fillColors[399]} d="M1060.6,192.47c0.34-1.32,0.68-2.65,1.01-3.97l-0.03-0.02c-0.33,1.33-0.67,2.66-1,3.98L1060.6,192.47z"/>
            <path onClick={() => props.onFill(400)} fill={props.fillColors[400]} d="M501.12,293.38c0.42-0.34,0.85-0.67,1.27-1.01c0.08,0.15,0.17,0.29,0.25,0.44c-0.5,0.2-1,0.39-1.51,0.59
                C501.13,293.4,501.12,293.38,501.12,293.38z"/>
            <path onClick={() => props.onFill(401)} fill={props.fillColors[401]} d="M1032.64,1085.83c0.66-1.01,1.31-2.03,1.97-3.04l0.03,0.02c-0.68,1-1.35,2-2.03,2.99
                C1032.61,1085.81,1032.64,1085.83,1032.64,1085.83z"/>
            <path onClick={() => props.onFill(402)} fill={props.fillColors[402]} d="M1061.62,188.5c0.09-0.48,0.19-0.95,0.04-0.19c0.3-0.73,0.12-0.28-0.07,0.16
                C1061.59,188.47,1061.62,188.5,1061.62,188.5z"/>
            <polygon onClick={() => props.onFill(403)} fill={props.fillColors[403]} points="128.04,600.95 128.13,601.35 127.87,601.29 		"/>
            <path onClick={() => props.onFill(404)} fill={props.fillColors[404]} d="M1063.57,179.44c-0.09,0.48-0.18,0.97-0.04,0.19c-0.29,0.74-0.11,0.29,0.07-0.17
                C1063.6,179.47,1063.57,179.44,1063.57,179.44z"/>
            <path onClick={() => props.onFill(405)} fill={props.fillColors[405]} d="M81.54,1082.79c-0.49-0.07-0.98-0.14-0.18-0.03c-0.76-0.3-0.3-0.12,0.15,0.06
                C81.51,1082.82,81.54,1082.79,81.54,1082.79z"/>
            <path onClick={() => props.onFill(406)} fill={props.fillColors[406]} d="M148.45,1037.83c-0.36,0.31-0.72,0.63-0.11,0.09c-0.73,0.34-0.3,0.14,0.14-0.06
                C148.48,1037.86,148.45,1037.83,148.45,1037.83z"/>
            <path onClick={() => props.onFill(407)} fill={props.fillColors[407]} d="M746.89,343.36c1,0.33,2,0.67,3,1c0,0,0.03-0.04,0.03-0.04c-1.01-0.33-2.01-0.66-3.02-0.98
                C746.91,343.34,746.89,343.36,746.89,343.36z"/>
            <path onClick={() => props.onFill(408)} fill={props.fillColors[408]} d="M746.91,343.34c-1.02-0.32-2.05-0.64-3.07-0.96l0.03-0.02c1.01,0.34,2.02,0.67,3.03,1.01
                C746.89,343.36,746.91,343.34,746.91,343.34z"/>
            <path onClick={() => props.onFill(409)} fill={props.fillColors[409]} d="M634.99,350.35c-0.13-0.25-0.27-0.51-0.4-0.77c0.14,0.25,0.28,0.49,0.42,0.74
                C635.01,350.32,634.99,350.35,634.99,350.35z"/>
            <path onClick={() => props.onFill(410)} fill={props.fillColors[410]} d="M523.11,286.39c-0.2-0.2-0.4-0.39-0.6-0.59c0.19,0.2,0.39,0.4,0.58,0.6L523.11,286.39z"/>
            <path onClick={() => props.onFill(411)} fill={props.fillColors[411]} d="M669.99,418.26c-0.18-0.19-0.36-0.39-0.55-0.58c0.17,0.2,0.34,0.41,0.51,0.61
                C669.95,418.3,669.99,418.26,669.99,418.26z"/>
            <path onClick={() => props.onFill(412)} fill={props.fillColors[412]} d="M69.53,680.12c1,0.33,2.01,0.65,3.01,0.98l-0.01,0.01c-0.99-0.34-1.98-0.68-2.97-1.02
                C69.55,680.09,69.53,680.12,69.53,680.12z"/>
            <path onClick={() => props.onFill(413)} fill={props.fillColors[413]} d="M93.43,678.13c1.02,0.33,2.05,0.66,3.07,0.99c0,0,0.02-0.03,0.02-0.03c-1.04-0.32-2.07-0.63-3.11-0.95
                C93.41,678.14,93.43,678.13,93.43,678.13z"/>
            <path onClick={() => props.onFill(414)} fill={props.fillColors[414]} d="M191.44,680.14c0.32-1.02,0.64-2.04,0.96-3.06c0,0,0.03,0.02,0.03,0.02c-0.34,1-0.68,2-1.01,3.01
                C191.41,680.11,191.44,680.14,191.44,680.14z"/>
            <path onClick={() => props.onFill(415)} fill={props.fillColors[415]} d="M1063.6,1102.79c-0.34,1-0.68,2-1.01,3c0,0,0.04,0.03,0.04,0.03c0.32-1.02,0.64-2.03,0.96-3.05
                L1063.6,1102.79z"/>
            <path onClick={() => props.onFill(416)} fill={props.fillColors[416]} d="M192.39,677.08c0.12-0.25,0.25-0.5,0.37-0.75c-0.12,0.26-0.23,0.51-0.34,0.77
                C192.42,677.1,192.39,677.08,192.39,677.08z"/>
            <path onClick={() => props.onFill(417)} fill={props.fillColors[417]} d="M1066.62,1140.81c0.11-0.28,0.22-0.57,0.23-0.57c0,0-0.13,0.27-0.26,0.54
                C1066.58,1140.77,1066.62,1140.81,1066.62,1140.81z"/>
            <path onClick={() => props.onFill(418)} fill={props.fillColors[418]} d="M128.5,1140.74c-0.27-0.11-0.55-0.22-0.58-0.23c0.02,0.01,0.29,0.13,0.55,0.26
                C128.48,1140.77,128.5,1140.74,128.5,1140.74z"/>
            <path onClick={() => props.onFill(419)} fill={props.fillColors[419]} d="M273.36,269.44c0.12-0.28,0.24-0.55,0.25-0.58c-0.01,0.03-0.15,0.29-0.28,0.56
                C273.33,269.41,273.36,269.44,273.36,269.44z"/>
            <path onClick={() => props.onFill(420)} fill={props.fillColors[420]} d="M327.27,1206.71c0.13,0.24,0.26,0.47,0.39,0.71c-0.12-0.25-0.23-0.49-0.35-0.74
                C327.32,1206.68,327.27,1206.71,327.27,1206.71z"/>
            <path onClick={() => props.onFill(421)} fill={props.fillColors[421]} d="M275.33,1262.69c-0.32-1.02-0.64-2.03-0.96-3.05c0,0-0.04,0.03-0.04,0.03c0.34,1,0.68,2,1.01,3
                L275.33,1262.69z"/>
            <path onClick={() => props.onFill(422)} fill={props.fillColors[422]} d="M151.46,640.14c-0.08-0.25-0.16-0.5-0.25-0.75c0.06,0.26,0.13,0.52,0.19,0.78
                C151.4,640.18,151.46,640.14,151.46,640.14z"/>
            <path onClick={() => props.onFill(423)} fill={props.fillColors[423]} d="M311.32,1272.7c0.07-0.26,0.14-0.52,0.21-0.79c-0.08,0.25-0.16,0.51-0.24,0.76
                C311.29,1272.67,311.32,1272.7,311.32,1272.7z"/>
            <path onClick={() => props.onFill(424)} fill={props.fillColors[424]} d="M218.4,250.4c-0.18-0.2-0.35-0.39-0.53-0.59c0.17,0.2,0.34,0.41,0.51,0.61L218.4,250.4z"/>
            <path onClick={() => props.onFill(425)} fill={props.fillColors[425]} d="M103.46,465.23c0.26-0.14,0.52-0.29,0.78-0.43c-0.24,0.16-0.49,0.33-0.73,0.49L103.46,465.23z"/>
            <path onClick={() => props.onFill(426)} fill={props.fillColors[426]} d="M119.52,442.25c-1.02-0.32-2.04-0.64-3.06-0.96l0.02-0.02c1,0.34,2,0.67,3.01,1.01
                C119.49,442.29,119.52,442.25,119.52,442.25z"/>
            <path onClick={() => props.onFill(427)} fill={props.fillColors[427]} d="M1050.6,224.45c-0.33,1-0.67,2-1,3c0,0,0.04,0.03,0.04,0.03c0.33-1,0.66-2,0.99-3L1050.6,224.45z"/>
            <path onClick={() => props.onFill(428)} fill={props.fillColors[428]} d="M868.78,1376.58c0.17,0.25,0.33,0.5,0.5,0.75c-0.01,0-0.01,0.01-0.02,0.01c-0.17-0.25-0.33-0.5-0.5-0.74
                L868.78,1376.58z"/>
            <path onClick={() => props.onFill(429)} fill={props.fillColors[429]} d="M1094.57,1337.61c-0.34,1-0.68,2-1.02,3c0,0,0.04,0.04,0.04,0.04c0.32-1.02,0.64-2.04,0.96-3.06
                L1094.57,1337.61z"/>
            <path onClick={() => props.onFill(430)} fill={props.fillColors[430]} d="M1050.63,224.48c0.32-1.01,0.65-2.02,0.97-3.04l0,0.01c-0.34,1-0.67,2-1.01,3
                C1050.6,224.45,1050.63,224.48,1050.63,224.48z"/>
            <path onClick={() => props.onFill(431)} fill={props.fillColors[431]} d="M1052.6,219.45c0.33-1.01,0.66-2.01,0.99-3.02c0,0,0.02,0.02,0.02,0.02c-0.33,1.01-0.66,2.02-0.99,3.02
                C1052.62,219.47,1052.6,219.45,1052.6,219.45z"/>
            <path onClick={() => props.onFill(432)} fill={props.fillColors[432]} d="M1060.59,192.46c-0.12,0.27-0.24,0.54-0.37,0.81c0.12-0.27,0.25-0.53,0.38-0.79
                C1060.6,192.47,1060.59,192.46,1060.59,192.46z"/>
            <path onClick={() => props.onFill(433)} fill={props.fillColors[433]} d="M1054.61,213.45c-0.33,1-0.67,2-1,3c0,0-0.02-0.02-0.02-0.02c0.33-1,0.67-2,1-3
                C1054.59,213.43,1054.61,213.45,1054.61,213.45z"/>
            <path onClick={() => props.onFill(434)} fill={props.fillColors[434]} d="M1054.59,213.43c0.34-0.99,0.68-1.99,1.01-2.98c0,0,0,0,0,0c-0.33,1-0.66,2-0.99,3
                C1054.61,213.45,1054.59,213.43,1054.59,213.43z"/>
            <path onClick={() => props.onFill(435)} fill={props.fillColors[435]} d="M1055.6,210.45c0.11-0.27,0.21-0.54-0.01,0.02C1055.82,209.92,1055.71,210.19,1055.6,210.45
                C1055.6,210.45,1055.6,210.45,1055.6,210.45z"/>
            <path onClick={() => props.onFill(436)} fill={props.fillColors[436]} d="M1057.6,203.46c-0.12,0.27-0.23,0.53-0.36,0.8C1057.36,203.99,1057.48,203.73,1057.6,203.46
                C1057.6,203.46,1057.6,203.46,1057.6,203.46z"/>
            <path onClick={() => props.onFill(437)} fill={props.fillColors[437]} d="M213.37,1353.58c-0.05,0.28-0.09,0.55-0.14,0.83c0.06-0.27,0.11-0.54,0.17-0.8
                C213.4,1353.6,213.37,1353.58,213.37,1353.58z"/>
            <path onClick={() => props.onFill(438)} fill={props.fillColors[438]} d="M1057.6,203.46c0.12-0.26,0.25-0.53,0.38-0.79C1057.85,202.94,1057.73,203.2,1057.6,203.46
                C1057.6,203.46,1057.6,203.46,1057.6,203.46z"/>
            <path onClick={() => props.onFill(439)} fill={props.fillColors[439]} d="M862.77,1367.48c0.36,0.72,0.72,1.44,1.07,2.17c0,0-0.06,0.06-0.06,0.06c-0.36-0.72-0.72-1.44-1.08-2.17
                C862.7,1367.55,862.77,1367.48,862.77,1367.48z"/>
            <path onClick={() => props.onFill(440)} fill={props.fillColors[440]} d="M271.34,561.2c0.66-0.34,1.33-0.68,1.99-1.02l0.03,0.03c-0.68,0.33-1.36,0.65-2.04,0.98
                C271.33,561.19,271.34,561.2,271.34,561.2z"/>
            <path onClick={() => props.onFill(441)} fill={props.fillColors[441]} d="M210.39,1371.59c-0.33,0.67-0.67,1.33-1,2c0,0,0.01,0.01,0.01,0.01c0.33-0.66,0.67-1.33,1-1.99
                C210.4,1371.6,210.39,1371.59,210.39,1371.59z"/>
            <path onClick={() => props.onFill(442)} fill={props.fillColors[442]} d="M276.38,558.21c-0.68,0.34-1.36,0.67-2.04,1.01c0,0-0.04-0.04-0.04-0.04c0.68-0.34,1.36-0.67,2.04-1.01
                L276.38,558.21z"/>
            <path onClick={() => props.onFill(443)} fill={props.fillColors[443]} d="M866.76,1373.64c-0.34-0.69-0.68-1.37-1.01-2.06l0.05-0.05c0.34,0.69,0.67,1.38,1.01,2.07
                C866.8,1373.6,866.76,1373.64,866.76,1373.64z"/>
            <path onClick={() => props.onFill(444)} fill={props.fillColors[444]} d="M279.36,556.21c-0.68,0.34-1.36,0.68-2.04,1.01c0,0-0.05-0.04-0.05-0.04c0.69-0.33,1.37-0.67,2.06-1
                C279.33,556.18,279.36,556.21,279.36,556.21z"/>
            <path onClick={() => props.onFill(445)} fill={props.fillColors[445]} d="M218.28,589.16c0.71-0.33,1.41-0.65,2.12-0.98l-0.03-0.02c-0.68,0.35-1.36,0.7-2.03,1.06L218.28,589.16z"
                />
            <polygon onClick={() => props.onFill(446)} fill={props.fillColors[446]} points="111.5,449.33 111.25,449.14 111.55,449.28 		"/>
            <path onClick={() => props.onFill(447)} fill={props.fillColors[447]} d="M820.81,1348.6c-0.67,0.33-1.34,0.66-2.01,0.99c0,0,0.02,0.03,0.02,0.03c0.67-0.33,1.34-0.67,2.01-1
                C820.83,1348.62,820.81,1348.6,820.81,1348.6z"/>
            <polygon onClick={() => props.onFill(448)} fill={props.fillColors[448]} points="1129.54,210.46 1129.36,210.2 1129.51,210.49 		"/>
            <path onClick={() => props.onFill(449)} fill={props.fillColors[449]} d="M702.92,194.35c0.35,0.72,0.71,1.44,1.06,2.16c0,0-0.06,0.06-0.06,0.06c-0.35-0.72-0.71-1.44-1.06-2.15
                C702.86,194.41,702.92,194.35,702.92,194.35z"/>
            <path onClick={() => props.onFill(450)} fill={props.fillColors[450]} d="M209.39,1373.58c-0.33,0.67-0.67,1.34-1,2.01c0,0,0.04,0.03,0.04,0.03c0.33-0.68,0.65-1.35,0.98-2.03
                C209.4,1373.6,209.39,1373.58,209.39,1373.58z"/>
            <path onClick={() => props.onFill(451)} fill={props.fillColors[451]} d="M867.79,1374.56c0.33,0.67,0.66,1.35,0.99,2.02l-0.02,0.01c-0.34-0.67-0.67-1.33-1.01-2L867.79,1374.56z"
                />
            <polygon onClick={() => props.onFill(452)} fill={props.fillColors[452]} points="64.57,594.14 64.23,593.97 64.53,594.19 		"/>
            <path onClick={() => props.onFill(453)} fill={props.fillColors[453]} d="M283.33,553.2c-0.67,0.34-1.34,0.67-2,1.01c0,0-0.03-0.03-0.03-0.03
                C281.97,553.86,282.65,553.53,283.33,553.2L283.33,553.2z"/>
            <path onClick={() => props.onFill(454)} fill={props.fillColors[454]} d="M1051.6,221.44c0.33-0.66,0.66-1.33,1-1.99c0,0,0.03,0.02,0.03,0.02
                C1052.28,220.13,1051.94,220.79,1051.6,221.44C1051.61,221.45,1051.6,221.44,1051.6,221.44z"/>
            <path onClick={() => props.onFill(455)} fill={props.fillColors[455]} d="M1080.58,1377.61c0.33-0.69,0.65-1.38,0.98-2.08l0.05,0.05c-0.34,0.67-0.68,1.34-1.01,2.01
                C1080.59,1377.58,1080.58,1377.61,1080.58,1377.61z"/>
            <path onClick={() => props.onFill(456)} fill={props.fillColors[456]} d="M858.78,1340.63c0.68,0.32,1.36,0.65,2.04,0.97c0,0-0.03,0.03-0.03,0.03c-0.66-0.34-1.33-0.67-1.99-1.01
                L858.78,1340.63z"/>
            <polygon onClick={() => props.onFill(457)} fill={props.fillColors[457]} points="240.36,452.26 240.03,452.48 240.38,452.29 		"/>
            <polygon onClick={() => props.onFill(458)} fill={props.fillColors[458]} points="1094.55,1337.59 1094.71,1337.38 1094.57,1337.61 		"/>
            <polygon onClick={() => props.onFill(459)} fill={props.fillColors[459]} points="176.41,1335.59 176.26,1335.89 176.44,1335.62 		"/>
            <path onClick={() => props.onFill(460)} fill={props.fillColors[460]} d="M149.47,238.43c-0.34-0.67-0.67-1.33-1.01-2l-0.01,0.01c0.33,0.67,0.66,1.34,0.99,2.01
                C149.44,238.45,149.47,238.43,149.47,238.43z"/>
            <path onClick={() => props.onFill(461)} fill={props.fillColors[461]} d="M160.45,442.27c0.33,0.68,0.65,1.36,0.98,2.03c0,0,0.02-0.02,0.02-0.02c-0.34-0.67-0.68-1.33-1.01-2
                L160.45,442.27z"/>
            <path onClick={() => props.onFill(462)} fill={props.fillColors[462]} d="M312.27,1289.62c-0.33,0.68-0.66,1.36-0.99,2.04c0,0,0.03,0.03,0.03,0.03c0.33-0.67,0.67-1.35,1-2.03
                C312.32,1289.66,312.27,1289.62,312.27,1289.62z"/>
            <polygon onClick={() => props.onFill(463)} fill={props.fillColors[463]} points="614.03,600.18 613.83,600.54 614.01,600.16 		"/>
            <path onClick={() => props.onFill(464)} fill={props.fillColors[464]} d="M287.34,1290.68c0.33-0.67,0.66-1.35,1-2.02c0,0-0.03-0.02-0.03-0.02c-0.33,0.67-0.67,1.34-1,2.02
                C287.31,1290.66,287.34,1290.68,287.34,1290.68z"/>
            <polygon onClick={() => props.onFill(465)} fill={props.fillColors[465]} points="269.35,1283.68 269.48,1283.44 269.33,1283.66 		"/>
            <polygon onClick={() => props.onFill(466)} fill={props.fillColors[466]} points="310.31,1281.68 310.45,1281.44 310.29,1281.66 		"/>
            <path onClick={() => props.onFill(467)} fill={props.fillColors[467]} d="M204.41,1382.6c0.33-0.67,0.66-1.34,1-2.01c0,0-0.01-0.01-0.01-0.01c-0.33,0.67-0.65,1.33-0.98,2
                C204.41,1382.58,204.41,1382.6,204.41,1382.6z"/>
            <path onClick={() => props.onFill(468)} fill={props.fillColors[468]} d="M155.45,249.42c-0.33-0.67-0.66-1.34-0.99-2.02c0,0-0.03,0.03-0.03,0.03c0.33,0.67,0.67,1.34,1,2
                L155.45,249.42z"/>
            <polygon onClick={() => props.onFill(469)} fill={props.fillColors[469]} points="160.44,442.28 160.3,442.06 160.45,442.27 		"/>
            <polygon onClick={() => props.onFill(470)} fill={props.fillColors[470]} points="180.41,1384.59 180.54,1384.83 180.44,1384.57 		"/>
            <path onClick={() => props.onFill(471)} fill={props.fillColors[471]} d="M103.51,465.29c-0.09,0.08-0.17,0.15-0.26,0.23c0.07-0.09,0.14-0.19,0.21-0.28
                C103.46,465.23,103.51,465.29,103.51,465.29z"/>
            <polygon onClick={() => props.onFill(472)} fill={props.fillColors[472]} points="116.46,441.29 116.25,441.14 116.48,441.28 		"/>
            <polygon onClick={() => props.onFill(473)} fill={props.fillColors[473]} points="318.31,1269.7 318.46,1269.42 318.28,1269.67 		"/>
            <polygon onClick={() => props.onFill(474)} fill={props.fillColors[474]} points="111.4,439.3 111.67,439.46 111.34,439.37 		"/>
            <polygon onClick={() => props.onFill(475)} fill={props.fillColors[475]} points="100.49,645.15 100.36,644.85 100.51,645.13 		"/>
            <polygon onClick={() => props.onFill(476)} fill={props.fillColors[476]} points="275.34,1262.67 275.46,1262.91 275.33,1262.69 		"/>
            <path onClick={() => props.onFill(477)} fill={props.fillColors[477]} d="M155.44,249.43c0.33,0.67,0.66,1.35,0.99,2.02c0,0,0.03-0.03,0.03-0.03c-0.33-0.67-0.67-1.34-1-2.01
                C155.45,249.42,155.44,249.43,155.44,249.43z"/>
            <path onClick={() => props.onFill(478)} fill={props.fillColors[478]} d="M1028.65,1261.71c0.32-0.68,0.64-1.37,0.97-2.05l0.02,0.01c-0.34,0.67-0.68,1.33-1.02,2
                C1028.61,1261.67,1028.65,1261.71,1028.65,1261.71z"/>
            <polygon onClick={() => props.onFill(479)} fill={props.fillColors[479]} points="218.38,250.42 218.53,250.64 218.4,250.4 		"/>
            <polygon onClick={() => props.onFill(480)} fill={props.fillColors[480]} points="1029.62,1259.66 1029.76,1259.44 1029.63,1259.68 		"/>
            <path onClick={() => props.onFill(481)} fill={props.fillColors[481]} d="M334.27,252.43c0.33,0.66,0.67,1.32,1,1.98l0.03-0.03c-0.33-0.66-0.67-1.32-1-1.98L334.27,252.43z"/>
            <path onClick={() => props.onFill(482)} fill={props.fillColors[482]} d="M335.26,254.42c0.33,0.68,0.66,1.35,0.99,2.03c0,0,0.03-0.03,0.03-0.03c-0.33-0.67-0.66-1.35-0.99-2.03
                C335.29,254.39,335.26,254.42,335.26,254.42z"/>
            <path onClick={() => props.onFill(483)} fill={props.fillColors[483]} d="M109.47,438.28c0.64,0.34,1.29,0.68,1.93,1.02c0,0-0.06,0.07-0.06,0.07c-0.64-0.35-1.27-0.7-1.91-1.05
                C109.44,438.32,109.47,438.28,109.47,438.28z"/>
            <polygon onClick={() => props.onFill(484)} fill={props.fillColors[484]} points="221.38,260.42 221.24,260.18 221.39,260.4 		"/>
            <path onClick={() => props.onFill(485)} fill={props.fillColors[485]} d="M968.67,1218.69c-0.32,0.7-0.64,1.41-0.97,2.11c0,0-0.05-0.05-0.05-0.05c0.34-0.67,0.68-1.34,1.03-2.02
                L968.67,1218.69z"/>
            <path onClick={() => props.onFill(486)} fill={props.fillColors[486]} d="M669.98,472.24c-0.67-0.33-1.34-0.65-2.01-0.98c0,0-0.03,0.01-0.03,0.01c0.67,0.33,1.35,0.65,2.02,0.98
                L669.98,472.24z"/>
            <path onClick={() => props.onFill(487)} fill={props.fillColors[487]} d="M107.49,437.3c0.66,0.33,1.32,0.65,1.99,0.98c0,0-0.04,0.04-0.04,0.04
                C108.79,437.98,108.14,437.64,107.49,437.3L107.49,437.3z"/>
            <polygon onClick={() => props.onFill(488)} fill={props.fillColors[488]} points="1060.61,177.51 1060.76,177.2 1060.58,177.48 		"/>
            <path onClick={() => props.onFill(489)} fill={props.fillColors[489]} d="M1052.64,1200.76c-0.01-0.69-0.01-1.37-0.02-2.06c0,0-0.04,0.03-0.04,0.03c0.35,0,0.71,0,1.06,0
                c0,0-0.03-0.03-0.03-0.03c-0.34,0.67-0.68,1.35-1.01,2.02C1052.59,1200.72,1052.64,1200.76,1052.64,1200.76z"/>
            <polygon onClick={() => props.onFill(490)} fill={props.fillColors[490]} points="966.67,174.48 966.54,174.73 966.7,174.5 		"/>
            <polygon onClick={() => props.onFill(491)} fill={props.fillColors[491]} points="966.7,174.5 966.85,174.24 966.67,174.48 		"/>
            <polygon onClick={() => props.onFill(492)} fill={props.fillColors[492]} points="370.24,275.41 370.38,275.19 370.27,275.44 		"/>
            <polygon onClick={() => props.onFill(493)} fill={props.fillColors[493]} points="949.72,174.5 949.9,174.13 949.69,174.47 		"/>
            <path onClick={() => props.onFill(494)} fill={props.fillColors[494]} d="M818.81,84.56c0.33,0.67,0.66,1.34,0.99,2.01c0,0,0.03-0.03,0.03-0.03c-0.33-0.67-0.67-1.33-1-2
                C818.83,84.54,818.81,84.56,818.81,84.56z"/>
            <path onClick={() => props.onFill(495)} fill={props.fillColors[495]} d="M379.27,274.36c-0.32,0.68-0.65,1.36-0.97,2.04c0,0-0.06-0.04-0.06-0.04c0.33-0.69,0.65-1.37,0.98-2.05
                C379.21,274.3,379.27,274.36,379.27,274.36z"/>
            <path onClick={() => props.onFill(496)} fill={props.fillColors[496]} d="M1000.65,1135.78c0.68-0.35,1.35-0.69,2.03-1.04l0.05,0.05c-0.7,0.32-1.41,0.64-2.11,0.96
                C1000.62,1135.75,1000.65,1135.78,1000.65,1135.78z"/>
            <polygon onClick={() => props.onFill(497)} fill={props.fillColors[497]} points="165.44,670.11 165.3,669.9 165.43,670.13 		"/>
            <path onClick={() => props.onFill(498)} fill={props.fillColors[498]} d="M369.26,277.4c0.33-0.66,0.65-1.33,0.98-1.99c0,0,0.03,0.03,0.03,0.03c-0.35,0.64-0.69,1.29-1.04,1.93
                C369.23,277.38,369.26,277.4,369.26,277.4z"/>
            <path onClick={() => props.onFill(499)} fill={props.fillColors[499]} d="M368.27,279.44c0.33-0.68,0.66-1.36,0.98-2.04c0,0-0.03-0.03-0.03-0.03c-0.33,0.67-0.67,1.35-1,2.02
                C368.23,279.4,368.27,279.44,368.27,279.44z"/>
            <polygon onClick={() => props.onFill(500)} fill={props.fillColors[500]} points="408.25,1128.75 408.49,1128.91 408.23,1128.77 		"/>
            <path onClick={() => props.onFill(501)} fill={props.fillColors[501]} d="M238.33,1130.83c0.35-0.71,0.69-1.41,1.03-2.12c0,0,0.05,0.05,0.05,0.05c-0.34,0.71-0.69,1.42-1.03,2.12
                C238.38,1130.88,238.33,1130.83,238.33,1130.83z"/>
            <path onClick={() => props.onFill(502)} fill={props.fillColors[502]} d="M241.38,1125.77c-0.33,0.7-0.66,1.39-0.99,2.09c0,0-0.05-0.05-0.05-0.05c0.34-0.69,0.67-1.38,1.01-2.07
                C241.34,1125.74,241.38,1125.77,241.38,1125.77z"/>
            <polygon onClick={() => props.onFill(503)} fill={props.fillColors[503]} points="1059.6,1123.75 1059.45,1124 1059.56,1123.71 		"/>
            <path onClick={() => props.onFill(504)} fill={props.fillColors[504]} d="M111.5,430.19c0.34,0.72,0.68,1.43,1.02,2.15l-0.06,0.06c-0.34-0.72-0.67-1.43-1.01-2.15
                C111.45,430.25,111.5,430.19,111.5,430.19z"/>
            <polygon onClick={() => props.onFill(505)} fill={props.fillColors[505]} points="1049.58,1123.74 1049.42,1124.07 1049.62,1123.78 		"/>
            <polygon onClick={() => props.onFill(506)} fill={props.fillColors[506]} points="147.46,673.11 147.31,672.88 147.45,673.12 		"/>
            <path onClick={() => props.onFill(507)} fill={props.fillColors[507]} d="M242.39,1123.77c-0.33,0.67-0.67,1.33-1.01,2c0,0-0.04-0.03-0.04-0.03c0.34-0.67,0.67-1.34,1.01-2
                C242.35,1123.73,242.39,1123.77,242.39,1123.77z"/>
            <path onClick={() => props.onFill(508)} fill={props.fillColors[508]} d="M1059.56,1123.71c0.35-0.64,0.7-1.29,1.05-1.93c0,0-0.02-0.02-0.02-0.02c-0.33,0.67-0.65,1.33-0.98,1.99
                C1059.6,1123.75,1059.56,1123.71,1059.56,1123.71z"/>
            <path onClick={() => props.onFill(509)} fill={props.fillColors[509]} d="M243.33,1122.8c0.33-0.7,0.66-1.4,0.99-2.1c0,0,0.05,0.04,0.05,0.04c-0.33,0.7-0.66,1.4-0.99,2.11
                C243.38,1122.85,243.33,1122.8,243.33,1122.8z"/>
            <path onClick={() => props.onFill(510)} fill={props.fillColors[510]} d="M245.35,1118.76c-0.32,0.66-0.65,1.33-0.98,1.99c0,0-0.05-0.04-0.05-0.05c0.35-0.64,0.69-1.27,1.04-1.91
                L245.35,1118.76z"/>
            <path onClick={() => props.onFill(511)} fill={props.fillColors[511]} d="M818.83,84.54c-0.34-0.67-0.68-1.33-1.01-2c0,0,0.03-0.03,0.03-0.03c0.32,0.68,0.65,1.36,0.97,2.04
                C818.81,84.56,818.83,84.54,818.83,84.54z"/>
            <path onClick={() => props.onFill(512)} fill={props.fillColors[512]} d="M472.18,278.39c0.08,0.09,0.15,0.18,0.23,0.27c-0.09-0.07-0.19-0.15-0.28-0.22
                C472.12,278.44,472.18,278.39,472.18,278.39z"/>
            <polygon onClick={() => props.onFill(513)} fill={props.fillColors[513]} points="266.36,1108.82 266.5,1108.48 266.33,1108.79 		"/>
            <path onClick={() => props.onFill(514)} fill={props.fillColors[514]} d="M373.23,280.4c-0.67,0.33-1.34,0.66-2.01,0.99c0,0,0.03,0.03,0.03,0.03c0.67-0.33,1.34-0.67,2.01-1
                C373.25,280.41,373.23,280.4,373.23,280.4z"/>
            <polygon onClick={() => props.onFill(515)} fill={props.fillColors[515]} points="93.41,678.14 93.19,677.96 93.43,678.12 		"/>
            <path onClick={() => props.onFill(516)} fill={props.fillColors[516]} d="M817.82,82.54c-0.33-0.68-0.66-1.36-0.99-2.03c0,0,0.05-0.04,0.05-0.04c0.32,0.68,0.65,1.37,0.97,2.05
                C817.85,82.52,817.82,82.54,817.82,82.54z"/>
            <path onClick={() => props.onFill(517)} fill={props.fillColors[517]} d="M100.54,477.29c-0.71-0.34-1.43-0.68-2.14-1.02c0,0,0.06-0.05,0.06-0.05c0.71,0.34,1.43,0.68,2.14,1.02
                C100.6,477.24,100.54,477.29,100.54,477.29z"/>
            <polygon onClick={() => props.onFill(518)} fill={props.fillColors[518]} points="546.09,283.4 545.92,283.1 546.06,283.42 		"/>
            <path onClick={() => props.onFill(519)} fill={props.fillColors[519]} d="M816.83,80.51c-0.33-0.66-0.67-1.33-1-1.99c0,0,0.02-0.02,0.02-0.02c0.34,0.66,0.69,1.32,1.03,1.97
                C816.88,80.46,816.83,80.51,816.83,80.51z"/>
            <path onClick={() => props.onFill(520)} fill={props.fillColors[520]} d="M67.49,679.13c0.68,0.33,1.36,0.66,2.04,0.98c0,0,0.02-0.02,0.02-0.02c-0.67-0.33-1.35-0.66-2.03-0.99
                C67.52,679.1,67.49,679.13,67.49,679.13z"/>
            <polygon onClick={() => props.onFill(521)} fill={props.fillColors[521]} points="815.82,78.52 815.68,78.27 815.84,78.49 		"/>
            <polygon onClick={() => props.onFill(522)} fill={props.fillColors[522]} points="72.54,681.1 72.75,681.24 72.52,681.11 		"/>
            <polygon onClick={() => props.onFill(523)} fill={props.fillColors[523]} points="748.91,511.27 749.04,511 748.88,511.23 		"/>
            <path onClick={() => props.onFill(524)} fill={props.fillColors[524]} d="M263.35,1102.79c0.67-0.34,1.33-0.68,2-1.02c0,0,0.04,0.04,0.04,0.04
                C264.71,1102.14,264.03,1102.46,263.35,1102.79L263.35,1102.79z"/>
            <polygon onClick={() => props.onFill(525)} fill={props.fillColors[525]} points="1063.59,1102.78 1063.73,1102.56 1063.6,1102.79 		"/>
            <polygon onClick={() => props.onFill(526)} fill={props.fillColors[526]} points="114.49,687.09 114.25,687.26 114.51,687.12 		"/>
            <polygon onClick={() => props.onFill(527)} fill={props.fillColors[527]} points="749.92,1100.77 749.67,1100.66 749.89,1100.8 		"/>
            <path onClick={() => props.onFill(528)} fill={props.fillColors[528]} d="M671.97,422.29c-0.33-0.66-0.67-1.33-1-1.99l-0.03,0.03c0.34,0.66,0.67,1.32,1.01,1.98
                C671.95,422.31,671.97,422.29,671.97,422.29z"/>
            <path onClick={() => props.onFill(529)} fill={props.fillColors[529]} d="M670.97,420.3c-0.33-0.68-0.66-1.36-0.99-2.04c0,0-0.04,0.03-0.04,0.03c0.33,0.68,0.66,1.35,1,2.03
                C670.95,420.32,670.97,420.3,670.97,420.3z"/>
            <path onClick={() => props.onFill(530)} fill={props.fillColors[530]} d="M672.97,417.3c-0.33-0.67-0.67-1.33-1-2l-0.03,0.03c0.33,0.67,0.67,1.33,1,2
                C672.94,417.33,672.97,417.3,672.97,417.3z"/>
            <polygon onClick={() => props.onFill(531)} fill={props.fillColors[531]} points="671.97,415.3 671.79,415.03 671.94,415.33 		"/>
            <polygon onClick={() => props.onFill(532)} fill={props.fillColors[532]} points="659,409.34 659.15,408.98 658.95,409.3 		"/>
            <path onClick={() => props.onFill(533)} fill={props.fillColors[533]} d="M102.47,479.22c0.71,0.33,1.41,0.65,2.12,0.98c0,0-0.05,0.06-0.05,0.06c-0.71-0.33-1.41-0.65-2.12-0.98
                L102.47,479.22z"/>
            <path onClick={() => props.onFill(534)} fill={props.fillColors[534]} d="M523.09,286.4c0.34,0.67,0.68,1.34,1.03,2.01c0,0-0.04,0.04-0.04,0.04c-0.32-0.69-0.64-1.37-0.97-2.06
                C523.11,286.39,523.09,286.4,523.09,286.4z"/>
            <path onClick={() => props.onFill(535)} fill={props.fillColors[535]} d="M1041.64,1088.84c0.33-0.67,0.66-1.34,0.99-2.01l-0.02-0.02c-0.34,0.67-0.67,1.33-1.01,2
                C1041.6,1088.8,1041.64,1088.84,1041.64,1088.84z"/>
            <path onClick={() => props.onFill(536)} fill={props.fillColors[536]} d="M106.52,481.26c-0.66-0.33-1.32-0.67-1.99-1c0,0,0.05-0.06,0.05-0.06c0.66,0.34,1.32,0.67,1.98,1.01
                C106.57,481.21,106.52,481.26,106.52,481.26z"/>
            <path onClick={() => props.onFill(537)} fill={props.fillColors[537]} d="M525.04,290.51c-0.32-0.69-0.65-1.38-0.97-2.07c0,0,0.04-0.04,0.04-0.04c0.33,0.68,0.66,1.37,0.99,2.05
                L525.04,290.51z"/>
            <polygon onClick={() => props.onFill(538)} fill={props.fillColors[538]} points="630.99,346.35 631.16,346.67 631.02,346.33 		"/>
            <polygon onClick={() => props.onFill(539)} fill={props.fillColors[539]} points="743.84,342.38 743.62,342.21 743.87,342.36 		"/>
            <path onClick={() => props.onFill(540)} fill={props.fillColors[540]} d="M525.11,290.46c0.33,0.65,0.65,1.3,0.98,1.95l0.02-0.02c-0.36-0.62-0.71-1.25-1.07-1.87
                C525.04,290.51,525.11,290.46,525.11,290.46z"/>
            <path onClick={() => props.onFill(541)} fill={props.fillColors[541]} d="M1042.63,1086.83c0.32-0.68,0.65-1.35,0.97-2.03l0.01,0.01c-0.34,0.66-0.67,1.33-1.01,1.99
                C1042.6,1086.8,1042.63,1086.83,1042.63,1086.83z"/>
            <path onClick={() => props.onFill(542)} fill={props.fillColors[542]} d="M108.52,482.24c-0.66-0.33-1.33-0.65-1.99-0.98c0,0,0.04-0.05,0.04-0.05c0.64,0.35,1.28,0.7,1.93,1.05
                C108.49,482.26,108.52,482.24,108.52,482.24z"/>
            <polygon onClick={() => props.onFill(543)} fill={props.fillColors[543]} points="356.24,291.36 356.09,291.69 356.27,291.39 		"/>
            <path onClick={() => props.onFill(544)} fill={props.fillColors[544]} d="M754.92,339.33c-0.67-0.33-1.35-0.65-2.02-0.98c0,0-0.01,0.01-0.01,0.01c0.67,0.33,1.34,0.67,2,1
                C754.89,339.37,754.92,339.33,754.92,339.33z"/>
            <path onClick={() => props.onFill(545)} fill={props.fillColors[545]} d="M750.88,337.37c0.67,0.33,1.33,0.66,2,0.99c0,0,0.01-0.01,0.01-0.01
                C752.23,338.02,751.56,337.7,750.88,337.37L750.88,337.37z"/>
            <path onClick={() => props.onFill(546)} fill={props.fillColors[546]} d="M1035.61,1080.81c-0.32,0.67-0.65,1.34-0.97,2c0,0-0.03-0.02-0.03-0.02
                C1034.94,1082.13,1035.28,1081.47,1035.61,1080.81L1035.61,1080.81z"/>
            <path onClick={() => props.onFill(547)} fill={props.fillColors[547]} d="M1021.64,1071.81c0.33-0.66,0.65-1.33,0.98-1.99l0,0C1022.3,1070.48,1021.97,1071.15,1021.64,1071.81
                L1021.64,1071.81z"/>
            <polygon onClick={() => props.onFill(548)} fill={props.fillColors[548]} points="124.47,507.25 124.78,507.07 124.45,507.21 		"/>
            <polygon onClick={() => props.onFill(549)} fill={props.fillColors[549]} points="331.26,1055.84 331.49,1055.97 331.28,1055.82 		"/>
            <path onClick={() => props.onFill(550)} fill={props.fillColors[550]} d="M1060.58,26.54c-0.33,0.65-0.66,1.3-1,1.95c0,0-0.07-0.06-0.07-0.06c0.35-0.64,0.71-1.28,1.06-1.91
                C1060.57,26.52,1060.58,26.54,1060.58,26.54z"/>
            <polygon onClick={() => props.onFill(551)} fill={props.fillColors[551]} points="70.53,1052.82 70.31,1053 70.55,1052.84 		"/>
            <path onClick={() => props.onFill(552)} fill={props.fillColors[552]} d="M498.12,295.38c-0.7,0.33-1.4,0.66-2.1,1c0,0-0.06-0.08-0.06-0.08c0.72-0.3,1.45-0.6,2.17-0.9
                C498.14,295.4,498.12,295.38,498.12,295.38z"/>
            <path onClick={() => props.onFill(553)} fill={props.fillColors[553]} d="M1000.64,1047.84c-0.33,0.67-0.67,1.33-1,2c0,0,0.04,0.03,0.04,0.03
                C1000,1049.19,1000.32,1048.51,1000.64,1047.84L1000.64,1047.84z"/>
            <polygon onClick={() => props.onFill(554)} fill={props.fillColors[554]} points="990.64,1046.81 990.5,1047.11 990.67,1046.83 		"/>
            <path onClick={() => props.onFill(555)} fill={props.fillColors[555]} d="M986.69,1048.87c0.32-0.68,0.64-1.36,0.96-2.04c0,0,0.01,0.01,0.01,0.01c-0.33,0.66-0.67,1.33-1,1.99
                C986.66,1048.84,986.69,1048.87,986.69,1048.87z"/>
            <path onClick={() => props.onFill(556)} fill={props.fillColors[556]} d="M995.67,1043.83c-0.34,0.67-0.67,1.34-1.01,2.01l0,0c0.33-0.68,0.66-1.35,0.99-2.03
                C995.64,1043.8,995.67,1043.83,995.67,1043.83z"/>
            <polygon onClick={() => props.onFill(557)} fill={props.fillColors[557]} points="995.64,1043.8 995.79,1043.59 995.67,1043.83 		"/>
            <path onClick={() => props.onFill(558)} fill={props.fillColors[558]} d="M982.69,1043.87c0.33-0.68,0.65-1.35,0.98-2.03c0,0,0,0,0,0c-0.34,0.67-0.68,1.33-1.01,2
                C982.65,1043.84,982.69,1043.87,982.69,1043.87z"/>
            <path onClick={() => props.onFill(559)} fill={props.fillColors[559]} d="M978.68,1037.85c0.33-0.67,0.65-1.34,0.98-2.01l0.01,0.01c-0.34,0.66-0.67,1.32-1.01,1.99L978.68,1037.85z
                "/>
            <path onClick={() => props.onFill(560)} fill={props.fillColors[560]} d="M493.99,297.31c0.66-0.34,1.31-0.67,1.97-1.01c0,0,0.06,0.08,0.06,0.08c-0.66,0.33-1.32,0.67-1.97,1
                C494.05,297.38,493.99,297.31,493.99,297.31z"/>
            <path onClick={() => props.onFill(561)} fill={props.fillColors[561]} d="M492.1,298.37c0.63-0.35,1.26-0.7,1.89-1.06c0,0,0.05,0.07,0.05,0.07c-0.64,0.34-1.28,0.68-1.92,1.02
                C492.13,298.39,492.1,298.37,492.1,298.37z"/>
            <polygon onClick={() => props.onFill(562)} fill={props.fillColors[562]} points="362.28,1032.83 362.03,1032.7 362.25,1032.85 		"/>
            <path onClick={() => props.onFill(563)} fill={props.fillColors[563]} d="M248.37,1033.84c-0.33-0.67-0.67-1.33-1-2l-0.01,0.01c0.33,0.67,0.66,1.34,0.99,2.01
                C248.35,1033.86,248.37,1033.84,248.37,1033.84z"/>
            <polygon onClick={() => props.onFill(564)} fill={props.fillColors[564]} points="1060.57,26.52 1060.74,26.28 1060.58,26.54 		"/>
            <polygon onClick={() => props.onFill(565)} fill={props.fillColors[565]} points="247.37,1031.84 247.23,1031.58 247.35,1031.85 		"/>
            <path onClick={() => props.onFill(566)} fill={props.fillColors[566]} d="M492.13,298.39c-0.67,0.33-1.33,0.67-2,1c0,0-0.02-0.02-0.02-0.02c0.66-0.34,1.33-0.67,1.99-1.01
                C492.1,298.37,492.13,298.39,492.13,298.39z"/>
            <path onClick={() => props.onFill(567)} fill={props.fillColors[567]} d="M357.24,1012.88c0.11,0.02,0.22,0.04,0.32,0.06c-0.1-0.03-0.2-0.06-0.3-0.09
                C357.26,1012.85,357.24,1012.88,357.24,1012.88z"/>
            <polygon onClick={() => props.onFill(568)} fill={props.fillColors[568]} points="936.71,1010.84 936.39,1011.04 936.75,1010.89 		"/>
            <polygon onClick={() => props.onFill(569)} fill={props.fillColors[569]} points="490.13,299.39 489.9,299.53 490.11,299.37 		"/>
            <path onClick={() => props.onFill(570)} fill={props.fillColors[570]} d="M492.13,302.39c0.33,0.67,0.66,1.34,0.99,2.01c0,0,0.02-0.02,0.02-0.02c-0.33-0.67-0.67-1.33-1-2
                L492.13,302.39z"/>
            <path onClick={() => props.onFill(571)} fill={props.fillColors[571]} d="M492.11,312.39c0.67,0.33,1.34,0.66,2.02,1c0,0,0.02-0.02,0.02-0.02c-0.67-0.33-1.35-0.66-2.02-1
                C492.13,312.37,492.11,312.39,492.11,312.39z"/>
            <path onClick={() => props.onFill(572)} fill={props.fillColors[572]} d="M593.05,304.39c0.33,0.66,0.67,1.33,1,1.99l-0.02,0.03C593.7,305.73,593.37,305.06,593.05,304.39
                L593.05,304.39z"/>
            <polygon onClick={() => props.onFill(573)} fill={props.fillColors[573]} points="1062.6,21.61 1062.46,21.83 1062.59,21.59 		"/>
            <path onClick={() => props.onFill(574)} fill={props.fillColors[574]} d="M599.02,313.42c-0.34-0.69-0.67-1.38-1.01-2.08l0.05-0.05c0.33,0.7,0.67,1.39,1,2.09L599.02,313.42z"/>
            <polygon onClick={() => props.onFill(575)} fill={props.fillColors[575]} points="980.64,980.9 981,981.06 980.67,980.87 		"/>
            <polygon onClick={() => props.onFill(576)} fill={props.fillColors[576]} points="1064.57,973.89 1064.76,974.17 1064.62,973.85 		"/>
            <polygon onClick={() => props.onFill(577)} fill={props.fillColors[577]} points="945.71,973.87 945.43,974.07 945.74,973.91 		"/>
            <path onClick={() => props.onFill(578)} fill={props.fillColors[578]} d="M973.66,958.91c-0.66-0.34-1.31-0.68-1.97-1.02c0,0-0.02,0.02-0.02,0.02c0.67,0.32,1.34,0.65,2.01,0.97
                C973.68,958.88,973.66,958.91,973.66,958.91z"/>
            <path onClick={() => props.onFill(579)} fill={props.fillColors[579]} d="M595.05,307.35c0.34,0.68,0.67,1.35,1.01,2.03l-0.03,0.03c-0.33-0.68-0.67-1.35-1-2.03
                C595.02,307.38,595.05,307.35,595.05,307.35z"/>
            <polygon onClick={() => props.onFill(580)} fill={props.fillColors[580]} points="1043.62,967.91 1043.96,967.69 1043.6,967.88 		"/>
            <path onClick={() => props.onFill(581)} fill={props.fillColors[581]} d="M540.04,310.29c0.37,0.37,0.75,0.74,1.12,1.11c0,0-0.06,0.06-0.06,0.06c-0.37-0.37-0.75-0.74-1.12-1.1
                L540.04,310.29z"/>
            <path onClick={() => props.onFill(582)} fill={props.fillColors[582]} d="M982.68,962.89c0,0-0.01,0.01-0.01,0.01S982.68,962.89,982.68,962.89z"/>
            <path onClick={() => props.onFill(583)} fill={props.fillColors[583]} d="M596.05,309.38c0.34,0.33,0.68,0.66,1.02,0.99c0,0-0.04,0.04-0.04,0.04c-0.34-0.33-0.68-0.66-1.02-1
                C596.02,309.41,596.05,309.38,596.05,309.38z"/>
            <path onClick={() => props.onFill(584)} fill={props.fillColors[584]} d="M556.07,310.39c-0.33-0.34-0.66-0.68-0.99-1.03c0,0-0.02,0.02-0.02,0.02c0.34,0.33,0.68,0.66,1.02,0.99
                L556.07,310.39z"/>
            <path onClick={() => props.onFill(585)} fill={props.fillColors[585]} d="M998.66,966.88c0,0-0.01,0.01-0.01,0.01S998.66,966.88,998.66,966.88z"/>
            <path onClick={() => props.onFill(586)} fill={props.fillColors[586]} d="M556.08,310.37c0.33,0.32,0.67,0.65,1,0.97c0,0-0.03,0.03-0.03,0.03c-0.33-0.33-0.66-0.66-0.99-0.99
                L556.08,310.37z"/>
            <path onClick={() => props.onFill(587)} fill={props.fillColors[587]} d="M539.98,310.35c-0.34-0.33-0.67-0.66-1.01-0.99c0,0,0.07-0.07,0.07-0.07c0.34,0.33,0.67,0.66,1.01,0.99
                C540.04,310.29,539.98,310.35,539.98,310.35z"/>
            <polygon onClick={() => props.onFill(588)} fill={props.fillColors[588]} points="492.12,309.39 492.26,309.51 492.14,309.37 		"/>
            <path onClick={() => props.onFill(589)} fill={props.fillColors[589]} d="M802.85,970.9c0,0-0.01-0.01-0.01-0.01S802.85,970.9,802.85,970.9z"/>
            <path onClick={() => props.onFill(590)} fill={props.fillColors[590]} d="M538.03,308.28c0.34,0.34,0.67,0.67,1.01,1.01c0,0-0.07,0.07-0.07,0.07c-0.34-0.34-0.67-0.67-1.01-1.01
                L538.03,308.28z"/>
            <path onClick={() => props.onFill(591)} fill={props.fillColors[591]} d="M1044.62,958.91c0,0-0.02-0.02-0.02-0.02S1044.62,958.91,1044.62,958.91z"/>
            <path onClick={() => props.onFill(592)} fill={props.fillColors[592]} d="M793.87,973.91c0,0-0.04-0.05-0.04-0.05C793.82,973.87,793.87,973.91,793.87,973.91z"/>
            <path onClick={() => props.onFill(593)} fill={props.fillColors[593]} d="M537.96,308.35c-0.29-0.32-0.57-0.65-0.86-0.97l-0.04,0.01c0.32,0.3,0.64,0.6,0.96,0.9
                C538.03,308.28,537.96,308.35,537.96,308.35z"/>
            <path onClick={() => props.onFill(594)} fill={props.fillColors[594]} d="M970.69,956.9c0.33,0.34,0.66,0.68,0.98,1.01c0,0,0.02-0.02,0.02-0.02c-0.34-0.33-0.68-0.66-1.02-0.99
                L970.69,956.9z"/>
            <path onClick={() => props.onFill(595)} fill={props.fillColors[595]} d="M970.67,956.91c0,0,0.02-0.01,0.02-0.01S970.67,956.91,970.67,956.91z"/>
            <path onClick={() => props.onFill(596)} fill={props.fillColors[596]} d="M594.05,306.38c0.33,0.32,0.66,0.65,1,0.97c0,0-0.03,0.03-0.03,0.03c-0.33-0.33-0.66-0.65-0.99-0.98
                C594.03,306.4,594.05,306.38,594.05,306.38z"/>
            <polygon onClick={() => props.onFill(597)} fill={props.fillColors[597]} points="537.11,307.38 536.95,307.22 537.07,307.39 		"/>
            <path onClick={() => props.onFill(598)} fill={props.fillColors[598]} d="M598.01,311.35c-0.32-0.31-0.65-0.63-0.97-0.94c0,0,0.04-0.04,0.04-0.04c0.33,0.31,0.65,0.62,0.97,0.93
                C598.05,311.29,598.01,311.35,598.01,311.35z"/>
            <path onClick={() => props.onFill(599)} fill={props.fillColors[599]} d="M542.09,312.45c-0.33-0.33-0.66-0.66-0.99-0.99c0,0,0.06-0.06,0.06-0.06c0.33,0.33,0.66,0.66,0.99,1
                C542.15,312.39,542.09,312.45,542.09,312.45z"/>
            <path onClick={() => props.onFill(600)} fill={props.fillColors[600]} d="M1053.59,944.9c0,0,0.02,0.02,0.02,0.02S1053.59,944.9,1053.59,944.9z"/>
            <path onClick={() => props.onFill(601)} fill={props.fillColors[601]} d="M1033.61,944.89c0,0,0.03,0.03,0.03,0.03C1033.64,944.93,1033.61,944.89,1033.61,944.89z"/>
            <path onClick={() => props.onFill(602)} fill={props.fillColors[602]} d="M955.7,984.89c0,0-0.02-0.02-0.02-0.02C955.68,984.87,955.7,984.89,955.7,984.89z"/>
            <path onClick={() => props.onFill(603)} fill={props.fillColors[603]} d="M543.08,313.43c-0.33-0.33-0.66-0.65-0.99-0.98c0,0,0.06-0.06,0.06-0.06c0.33,0.33,0.65,0.66,0.97,0.99
                L543.08,313.43z"/>
            <path onClick={() => props.onFill(604)} fill={props.fillColors[604]} d="M958.68,988.87c0,0,0.02,0.02,0.02,0.02C958.7,988.89,958.68,988.87,958.68,988.87z"/>
            <path onClick={() => props.onFill(605)} fill={props.fillColors[605]} d="M593.04,304.39C593.04,304.39,593.05,304.38,593.04,304.39C593.05,304.39,593.04,304.39,593.04,304.39z"/>
            <polygon onClick={() => props.onFill(606)} fill={props.fillColors[606]} points="534.06,303.24 534.08,303.41 533.98,303.32 		"/>
            <path onClick={() => props.onFill(607)} fill={props.fillColors[607]} d="M533.04,302.27c0.34,0.33,0.68,0.65,1.02,0.98c0,0-0.08,0.08-0.08,0.08c-0.34-0.33-0.67-0.66-1.01-0.98
                L533.04,302.27z"/>
            <path onClick={() => props.onFill(608)} fill={props.fillColors[608]} d="M543.12,313.38c0.32,0.33,0.64,0.65,0.96,0.98c0,0-0.03,0.03-0.03,0.03c-0.32-0.32-0.65-0.64-0.97-0.96
                C543.08,313.43,543.12,313.38,543.12,313.38z"/>
            <path onClick={() => props.onFill(609)} fill={props.fillColors[609]} d="M591.05,301.38l-0.02,0.02L591.05,301.38z"/>
            <path onClick={() => props.onFill(610)} fill={props.fillColors[610]} d="M776.85,997.88c0,0,0.02-0.02,0.02-0.02C776.87,997.86,776.85,997.88,776.85,997.88z"/>
            <path onClick={() => props.onFill(611)} fill={props.fillColors[611]} d="M532.97,302.34c-0.28-0.32-0.57-0.64-0.85-0.96l-0.05,0.02c0.32,0.29,0.64,0.58,0.97,0.87
                C533.04,302.27,532.97,302.34,532.97,302.34z"/>
            <polygon onClick={() => props.onFill(612)} fill={props.fillColors[612]} points="492.14,302.38 492.02,302.28 492.13,302.39 		"/>
            <path onClick={() => props.onFill(613)} fill={props.fillColors[613]} d="M1011.65,1001.86c0.33,0.34,0.65,0.67,0.98,1.01c0,0,0.03-0.02,0.03-0.02c-0.34-0.33-0.68-0.65-1.02-0.98
                C1011.64,1001.88,1011.65,1001.86,1011.65,1001.86z"/>
            <polygon onClick={() => props.onFill(614)} fill={props.fillColors[614]} points="1012.63,1002.87 1012.78,1003.02 1012.66,1002.86 		"/>
            <path onClick={() => props.onFill(615)} fill={props.fillColors[615]} d="M926.72,1004.88c0,0,0.01-0.02,0.01-0.02C926.73,1004.87,926.72,1004.88,926.72,1004.88z"/>
            <path onClick={() => props.onFill(616)} fill={props.fillColors[616]} d="M591.03,301.4c0,0,0.02-0.02,0.02-0.02S591.03,301.4,591.03,301.4z"/>
            <polygon onClick={() => props.onFill(617)} fill={props.fillColors[617]} points="532.12,301.38 531.93,301.19 532.08,301.39 		"/>
            <path onClick={() => props.onFill(618)} fill={props.fillColors[618]} d="M764.87,1007.87c0,0,0.01-0.01,0.01-0.01S764.87,1007.87,764.87,1007.87z"/>
            <path onClick={() => props.onFill(619)} fill={props.fillColors[619]} d="M1040.61,928.93c-0.33-0.34-0.66-0.68-0.98-1.02c0,0-0.02,0.02-0.02,0.02c0.34,0.33,0.68,0.66,1.01,0.98
                C1040.62,928.91,1040.61,928.93,1040.61,928.93z"/>
            <path onClick={() => props.onFill(620)} fill={props.fillColors[620]} d="M599.05,313.38c0.33,0.33,0.67,0.66,1,0.99c0,0-0.03,0.03-0.03,0.03c-0.33-0.33-0.67-0.66-1-0.99
                C599.02,313.42,599.05,313.38,599.05,313.38z"/>
            <path onClick={() => props.onFill(621)} fill={props.fillColors[621]} d="M560.06,315.47c-0.34-0.33-0.68-0.66-1.01-0.99c0,0,0.05-0.06,0.06-0.06c0.34,0.33,0.68,0.66,1.02,0.99
                L560.06,315.47z"/>
            <path onClick={() => props.onFill(622)} fill={props.fillColors[622]} d="M770.87,1014.85c-0.34-0.3-0.68-0.61-1.02-0.91c0,0,0.05-0.05,0.05-0.05c0.32,0.33,0.64,0.65,0.96,0.98
                L770.87,1014.85z"/>
            <path onClick={() => props.onFill(623)} fill={props.fillColors[623]} d="M172.44,1015.87c0,0-0.02-0.02-0.02-0.02C172.42,1015.85,172.44,1015.87,172.44,1015.87z"/>
            <polygon onClick={() => props.onFill(624)} fill={props.fillColors[624]} points="770.86,1014.87 770.98,1014.98 770.87,1014.85 		"/>
            <path onClick={() => props.onFill(625)} fill={props.fillColors[625]} d="M593.06,299.37c0,0-0.04,0.04-0.04,0.04C593.02,299.41,593.06,299.37,593.06,299.37z"/>
            <path onClick={() => props.onFill(626)} fill={props.fillColors[626]} d="M601.02,315.4c-0.33-0.33-0.67-0.66-1-1c0,0,0.03-0.03,0.03-0.03c0.34,0.33,0.67,0.66,1.01,1
                C601.06,315.37,601.02,315.4,601.02,315.4z"/>
            <polygon onClick={() => props.onFill(627)} fill={props.fillColors[627]} points="530.07,298.4 530.3,298.57 530.11,298.37 		"/>
            <path onClick={() => props.onFill(628)} fill={props.fillColors[628]} d="M953.7,1021.85c0,0-0.01-0.02-0.01-0.02C953.7,1021.84,953.7,1021.85,953.7,1021.85z"/>
            <polygon onClick={() => props.onFill(629)} fill={props.fillColors[629]} points="964.62,1023.91 964.68,1023.82 964.69,1023.98 		"/>
            <polygon onClick={() => props.onFill(630)} fill={props.fillColors[630]} points="973.59,1023.9 973.68,1023.82 973.66,1023.97 		"/>
            <path onClick={() => props.onFill(631)} fill={props.fillColors[631]} d="M963.64,1024.89c0.33-0.33,0.66-0.65,0.98-0.98c0,0,0.07,0.07,0.07,0.07c-0.33,0.32-0.66,0.64-0.99,0.97
                L963.64,1024.89z"/>
            <path onClick={() => props.onFill(632)} fill={props.fillColors[632]} d="M972.64,1024.86c0.32-0.32,0.64-0.64,0.95-0.96c0,0,0.07,0.07,0.07,0.07c-0.33,0.31-0.65,0.63-0.97,0.94
                C972.69,1024.91,972.64,1024.86,972.64,1024.86z"/>
            <path onClick={() => props.onFill(633)} fill={props.fillColors[633]} d="M960.7,1024.87c-0.33,0.33-0.66,0.65-0.99,0.98l-0.01,0c0.33-0.33,0.66-0.67,0.98-1
                C960.68,1024.85,960.7,1024.87,960.7,1024.87z"/>
            <path onClick={() => props.onFill(634)} fill={props.fillColors[634]} d="M963.69,1024.94c-0.33,0.31-0.66,0.62-1,0.93c0,0-0.02-0.03-0.02-0.03c0.32-0.32,0.64-0.64,0.96-0.96
                C963.64,1024.89,963.69,1024.94,963.69,1024.94z"/>
            <path onClick={() => props.onFill(635)} fill={props.fillColors[635]} d="M971.66,1025.85c0.33-0.33,0.65-0.66,0.98-0.99c0,0,0.05,0.05,0.05,0.05c-0.33,0.32-0.67,0.64-1,0.96
                L971.66,1025.85z"/>
            <polygon onClick={() => props.onFill(636)} fill={props.fillColors[636]} points="1034.63,920.92 1034.5,920.81 1034.62,920.94 		"/>
            <path onClick={() => props.onFill(637)} fill={props.fillColors[637]} d="M959.71,1025.85c0,0-0.01,0-0.01,0C959.7,1025.85,959.71,1025.85,959.71,1025.85z"/>
            <path onClick={() => props.onFill(638)} fill={props.fillColors[638]} d="M961.7,1026.87c0.33-0.34,0.65-0.68,0.98-1.03c0,0,0.02,0.03,0.02,0.03c-0.34,0.32-0.68,0.65-1.03,0.97
                C961.67,1026.84,961.7,1026.87,961.7,1026.87z"/>
            <path onClick={() => props.onFill(639)} fill={props.fillColors[639]} d="M971.69,1025.87c-0.34,0.32-0.67,0.65-1.01,0.97l0.01,0.01c0.33-0.34,0.65-0.67,0.98-1.01
                C971.66,1025.85,971.69,1025.87,971.69,1025.87z"/>
            <path onClick={() => props.onFill(640)} fill={props.fillColors[640]} d="M560.12,315.41c0.33,0.33,0.67,0.66,1,0.99c0,0-0.06,0.06-0.06,0.06c-0.33-0.33-0.67-0.66-1-0.99
                C560.06,315.47,560.12,315.41,560.12,315.41z"/>
            <path onClick={() => props.onFill(641)} fill={props.fillColors[641]} d="M969.69,1027.87c0.33-0.34,0.67-0.68,1-1.02l-0.01-0.01c-0.34,0.33-0.68,0.67-1.01,1
                C969.66,1027.84,969.69,1027.87,969.69,1027.87z"/>
            <polygon onClick={() => props.onFill(642)} fill={props.fillColors[642]} points="530.11,298.37 529.93,298.21 530.07,298.4 		"/>
            <path onClick={() => props.onFill(643)} fill={props.fillColors[643]} d="M602.02,316.39c-0.33-0.33-0.67-0.66-1-0.99c0,0,0.03-0.03,0.03-0.03c0.33,0.33,0.66,0.67,0.99,1
                C602.05,316.37,602.02,316.39,602.02,316.39z"/>
            <path onClick={() => props.onFill(644)} fill={props.fillColors[644]} d="M562.06,317.42c-0.33-0.32-0.67-0.64-1-0.97c0,0,0.06-0.06,0.06-0.06c0.33,0.33,0.66,0.65,0.99,0.98
                L562.06,317.42z"/>
            <path onClick={() => props.onFill(645)} fill={props.fillColors[645]} d="M603.02,317.38c-0.33-0.33-0.67-0.66-1-0.99c0,0,0.03-0.02,0.03-0.02c0.33,0.33,0.66,0.67,1,1
                C603.04,317.37,603.02,317.38,603.02,317.38z"/>
            <path onClick={() => props.onFill(646)} fill={props.fillColors[646]} d="M562.11,317.37c0.33,0.33,0.66,0.66,0.98,0.99l-0.03,0.03c-0.33-0.33-0.66-0.65-1-0.98
                C562.06,317.42,562.11,317.37,562.11,317.37z"/>
            <path onClick={() => props.onFill(647)} fill={props.fillColors[647]} d="M147.46,1034.86c0,0-0.02-0.02-0.02-0.02S147.46,1034.86,147.46,1034.86z"/>
            <path onClick={() => props.onFill(648)} fill={props.fillColors[648]} d="M697.91,899.88c0,0-0.06,0.06-0.06,0.06C697.84,899.94,697.91,899.88,697.91,899.88z"/>
            <path onClick={() => props.onFill(649)} fill={props.fillColors[649]} d="M961.68,1033.83c0,0,0.02,0.02,0.02,0.02C961.7,1033.85,961.68,1033.83,961.68,1033.83z"/>
            <path onClick={() => props.onFill(650)} fill={props.fillColors[650]} d="M979.66,1035.84c0,0,0.01,0.01,0.01,0.01C979.67,1035.85,979.66,1035.84,979.66,1035.84z"/>
            <path onClick={() => props.onFill(651)} fill={props.fillColors[651]} d="M971.67,1036.84C971.67,1036.84,971.67,1036.85,971.67,1036.84
                C971.67,1036.85,971.67,1036.84,971.67,1036.84z"/>
            <path onClick={() => props.onFill(652)} fill={props.fillColors[652]} d="M696.93,898.9c0.32,0.32,0.65,0.65,0.97,0.97c0,0-0.06,0.06-0.06,0.06c-0.32-0.33-0.64-0.66-0.96-0.99
                C696.89,898.95,696.93,898.9,696.93,898.9z"/>
            <path onClick={() => props.onFill(653)} fill={props.fillColors[653]} d="M968.67,1036.83c0,0,0.03,0.03,0.03,0.03S968.67,1036.83,968.67,1036.83z"/>
            <path onClick={() => props.onFill(654)} fill={props.fillColors[654]} d="M354.27,297.41c0.33-0.33,0.67-0.67,1-1c0,0-0.03-0.03-0.03-0.03c-0.33,0.33-0.66,0.67-1,1
                C354.24,297.38,354.27,297.41,354.27,297.41z"/>
            <path onClick={() => props.onFill(655)} fill={props.fillColors[655]} d="M184.41,1039.83c0,0,0.01,0.01,0.01,0.01C184.42,1039.85,184.41,1039.83,184.41,1039.83z"/>
            <polygon onClick={() => props.onFill(656)} fill={props.fillColors[656]} points="528.08,295.4 528.31,295.56 528.11,295.37 		"/>
            <path onClick={() => props.onFill(657)} fill={props.fillColors[657]} d="M983.67,1041.84C983.67,1041.84,983.67,1041.84,983.67,1041.84L983.67,1041.84z"/>
            <path onClick={() => props.onFill(658)} fill={props.fillColors[658]} d="M298.31,1041.82c0,0,0,0.02,0,0.02C298.31,1041.83,298.31,1041.82,298.31,1041.82z"/>
            <path onClick={() => props.onFill(659)} fill={props.fillColors[659]} d="M695.95,897.92c0.33,0.33,0.66,0.65,0.99,0.98c0,0-0.05,0.05-0.05,0.05c-0.32-0.33-0.64-0.67-0.97-1
                C695.92,897.95,695.95,897.92,695.95,897.92z"/>
            <path onClick={() => props.onFill(660)} fill={props.fillColors[660]} d="M694.92,896.95c0.34,0.32,0.68,0.65,1.03,0.97c0,0-0.03,0.03-0.03,0.03c-0.32-0.34-0.65-0.68-0.97-1.02
                C694.95,896.93,694.92,896.95,694.92,896.95z"/>
            <path onClick={() => props.onFill(661)} fill={props.fillColors[661]} d="M604.02,318.37c-0.33-0.33-0.67-0.66-1-0.99c0,0,0.02-0.02,0.02-0.02
                C603.37,317.7,603.7,318.04,604.02,318.37C604.03,318.37,604.02,318.37,604.02,318.37z"/>
            <path onClick={() => props.onFill(662)} fill={props.fillColors[662]} d="M987.65,1046.83c0,0,0.01,0.01,0.01,0.01C987.66,1046.84,987.65,1046.83,987.65,1046.83z"/>
            <path onClick={() => props.onFill(663)} fill={props.fillColors[663]} d="M563.1,318.36c0.33,0.33,0.65,0.66,0.98,0.99c0,0-0.02,0.02-0.02,0.02c-0.33-0.33-0.66-0.65-0.99-0.98
                C563.06,318.39,563.1,318.36,563.1,318.36z"/>
            <path onClick={() => props.onFill(664)} fill={props.fillColors[664]} d="M609,319.39c0,0,0.05-0.04,0.05-0.04C609.04,319.35,609,319.39,609,319.39z"/>
            <path onClick={() => props.onFill(665)} fill={props.fillColors[665]} d="M1000.64,1047.84C1000.64,1047.84,1000.64,1047.84,1000.64,1047.84
                C1000.64,1047.84,1000.64,1047.84,1000.64,1047.84z"/>
            <path onClick={() => props.onFill(666)} fill={props.fillColors[666]} d="M563.07,323.36c0,0-0.02,0.01-0.02,0.01C563.05,323.37,563.07,323.36,563.07,323.36z"/>
            <path onClick={() => props.onFill(667)} fill={props.fillColors[667]} d="M994.65,1053.82c0,0,0.02,0.02,0.02,0.02C994.67,1053.84,994.65,1053.82,994.65,1053.82z"/>
            <path onClick={() => props.onFill(668)} fill={props.fillColors[668]} d="M356.25,295.39c-0.34,0.33-0.67,0.66-1.01,1c0,0,0.03,0.03,0.03,0.03c0.33-0.34,0.66-0.67,1-1.01
                L356.25,295.39z"/>
            <path onClick={() => props.onFill(669)} fill={props.fillColors[669]} d="M1058.61,1060.81c-0.33-0.33-0.67-0.67-1-1c0,0-0.03,0.03-0.03,0.03c0.34,0.33,0.68,0.66,1.01,1
                L1058.61,1060.81z"/>
            <polygon onClick={() => props.onFill(670)} fill={props.fillColors[670]} points="1058.59,1060.83 1058.73,1060.95 1058.61,1060.81 		"/>
            <path onClick={() => props.onFill(671)} fill={props.fillColors[671]} d="M284.32,1062.83c0,0,0.01-0.01,0.01-0.01C284.33,1062.82,284.32,1062.83,284.32,1062.83z"/>
            <polygon onClick={() => props.onFill(672)} fill={props.fillColors[672]} points="528.11,295.37 527.93,295.22 528.08,295.4 		"/>
            <path onClick={() => props.onFill(673)} fill={props.fillColors[673]} d="M1000.66,1063.84c0,0-0.02-0.02-0.02-0.02C1000.64,1063.81,1000.66,1063.84,1000.66,1063.84z"/>
            <path onClick={() => props.onFill(674)} fill={props.fillColors[674]} d="M1003.63,1063.8c0,0,0.03,0.03,0.03,0.03C1003.67,1063.84,1003.63,1063.8,1003.63,1063.8z"/>
            <polygon onClick={() => props.onFill(675)} fill={props.fillColors[675]} points="1024.64,1066.83 1024.77,1066.69 1024.63,1066.82 		"/>
            <polygon onClick={() => props.onFill(676)} fill={props.fillColors[676]} points="498.14,295.4 498.26,295.27 498.12,295.38 		"/>
            <polygon onClick={() => props.onFill(677)} fill={props.fillColors[677]} points="1024.63,1066.82 1024.52,1066.94 1024.64,1066.83 		"/>
            <path onClick={() => props.onFill(678)} fill={props.fillColors[678]} d="M1008.64,1068.81c0,0,0.01,0.01,0.01,0.01S1008.64,1068.81,1008.64,1068.81z"/>
            <path onClick={() => props.onFill(679)} fill={props.fillColors[679]} d="M750.88,334.37c0,0,0.01-0.01,0.01-0.01C750.9,334.36,750.88,334.37,750.88,334.37z"/>
            <path onClick={() => props.onFill(680)} fill={props.fillColors[680]} d="M1021.65,1071.82C1021.65,1071.82,1021.64,1071.81,1021.65,1071.82
                C1021.64,1071.81,1021.65,1071.82,1021.65,1071.82z"/>
            <path onClick={() => props.onFill(681)} fill={props.fillColors[681]} d="M1009.63,1075.8c0,0,0.02,0.02,0.02,0.02C1009.66,1075.82,1009.63,1075.8,1009.63,1075.8z"/>
            <path onClick={() => props.onFill(682)} fill={props.fillColors[682]} d="M1014.64,1077.81C1014.64,1077.81,1014.64,1077.81,1014.64,1077.81L1014.64,1077.81z"/>
            <path onClick={() => props.onFill(683)} fill={props.fillColors[683]} d="M356.26,295.4c0.33-0.34,0.66-0.68,0.99-1.02l0.03,0.02c-0.34,0.33-0.69,0.66-1.03,0.99
                C356.25,295.39,356.26,295.4,356.26,295.4z"/>
            <path onClick={() => props.onFill(684)} fill={props.fillColors[684]} d="M501.13,293.4c0,0-0.01-0.02-0.01-0.02C501.12,293.38,501.13,293.4,501.13,293.4z"/>
            <path onClick={() => props.onFill(685)} fill={props.fillColors[685]} d="M357.25,294.37c0.33-0.33,0.67-0.67,1-1c0,0,0.03,0.03,0.03,0.03c-0.34,0.33-0.67,0.66-1.01,1
                C357.28,294.4,357.25,294.37,357.25,294.37z"/>
            <polygon onClick={() => props.onFill(686)} fill={props.fillColors[686]} points="526.09,292.41 526.24,292.54 526.11,292.39 		"/>
            <path onClick={() => props.onFill(687)} fill={props.fillColors[687]} d="M1043.6,1084.8c0,0,0.01,0.01,0.01,0.01C1043.61,1084.81,1043.6,1084.8,1043.6,1084.8z"/>
            <path onClick={() => props.onFill(688)} fill={props.fillColors[688]} d="M359.27,292.42c-0.33,0.33-0.65,0.65-0.98,0.98c0,0-0.03-0.03-0.03-0.03c0.33-0.33,0.66-0.65,0.99-0.98
                C359.24,292.39,359.27,292.42,359.27,292.42z"/>
            <path onClick={() => props.onFill(689)} fill={props.fillColors[689]} d="M1021.62,1085.8c0,0,0.01,0.02,0.01,0.02C1021.64,1085.82,1021.62,1085.8,1021.62,1085.8z"/>
            <path onClick={() => props.onFill(690)} fill={props.fillColors[690]} d="M765.89,341.34c0,0-0.02,0.03-0.02,0.03C765.86,341.37,765.89,341.34,765.89,341.34z"/>
            <path onClick={() => props.onFill(691)} fill={props.fillColors[691]} d="M509.11,288.39c0,0,0.01,0.01,0.01,0.01S509.11,288.39,509.11,288.39z"/>
            <path onClick={() => props.onFill(692)} fill={props.fillColors[692]} d="M441.19,377.33c0,0-0.01-0.01-0.01-0.01C441.18,377.32,441.19,377.33,441.19,377.33z"/>
            <path onClick={() => props.onFill(693)} fill={props.fillColors[693]} d="M1052.61,1087.81L1052.61,1087.81C1052.6,1087.8,1052.61,1087.81,1052.61,1087.81z"/>
            <path onClick={() => props.onFill(694)} fill={props.fillColors[694]} d="M642.98,380.34c0,0,0.02-0.02,0.02-0.02C643,380.32,642.98,380.34,642.98,380.34z"/>
            <path onClick={() => props.onFill(695)} fill={props.fillColors[695]} d="M487.15,286.41c0,0-0.03-0.03-0.03-0.03C487.12,286.38,487.15,286.41,487.15,286.41z"/>
            <path onClick={() => props.onFill(696)} fill={props.fillColors[696]} d="M633.98,398.33c0,0,0.03-0.03,0.03-0.03S633.98,398.33,633.98,398.33z"/>
            <path onClick={() => props.onFill(697)} fill={props.fillColors[697]} d="M202.38,695.11c0,0,0.04-0.04,0.04-0.04C202.42,695.08,202.38,695.11,202.38,695.11z"/>
            <path onClick={() => props.onFill(698)} fill={props.fillColors[698]} d="M978.69,1093.79c0,0-0.03,0.03-0.03,0.03C978.66,1093.81,978.69,1093.79,978.69,1093.79z"/>
            <path onClick={() => props.onFill(699)} fill={props.fillColors[699]} d="M484.15,285.42c0.33-0.34,0.67-0.68,1-1.01c0,0-0.02-0.02-0.02-0.02c-0.34,0.34-0.67,0.67-1.01,1.01
                C484.12,285.39,484.15,285.42,484.15,285.42z"/>
            <path onClick={() => props.onFill(700)} fill={props.fillColors[700]} d="M270.33,1097.79c0.32-0.33,0.64-0.67,0.96-1c0,0,0.04,0.04,0.04,0.04c-0.33,0.32-0.66,0.65-0.99,0.97
                C270.35,1097.8,270.33,1097.79,270.33,1097.79z"/>
            <path onClick={() => props.onFill(701)} fill={props.fillColors[701]} d="M270.35,1097.8c0,0-0.02-0.01-0.02-0.01C270.33,1097.79,270.35,1097.8,270.35,1097.8z"/>
            <path onClick={() => props.onFill(702)} fill={props.fillColors[702]} d="M267.26,1099.78c0,0,0.05,0.05,0.05,0.05C267.32,1099.83,267.26,1099.78,267.26,1099.78z"/>
            <path onClick={() => props.onFill(703)} fill={props.fillColors[703]} d="M474.17,285.38c0,0-0.04,0.05-0.04,0.05C474.13,285.42,474.17,285.38,474.17,285.38z"/>
            <path onClick={() => props.onFill(704)} fill={props.fillColors[704]} d="M266.29,1100.8c0.33-0.34,0.65-0.68,0.98-1.02c0,0,0.05,0.05,0.05,0.05c-0.33,0.34-0.65,0.68-0.98,1.02
                C266.34,1100.86,266.29,1100.8,266.29,1100.8z"/>
            <path onClick={() => props.onFill(705)} fill={props.fillColors[705]} d="M266.34,1100.86c-0.31,0.32-0.63,0.64-0.94,0.96c0,0-0.04-0.04-0.04-0.04c0.31-0.32,0.62-0.65,0.93-0.97
                C266.29,1100.8,266.34,1100.86,266.34,1100.86z"/>
            <polygon onClick={() => props.onFill(706)} fill={props.fillColors[706]} points="269.29,1101.84 269.35,1101.75 269.35,1101.9 		"/>
            <path onClick={() => props.onFill(707)} fill={props.fillColors[707]} d="M99.47,685.12c0,0,0.04-0.04,0.04-0.04C99.52,685.08,99.47,685.12,99.47,685.12z"/>
            <path onClick={() => props.onFill(708)} fill={props.fillColors[708]} d="M268.34,1102.78c0.32-0.31,0.63-0.62,0.95-0.94c0,0,0.06,0.06,0.06,0.06c-0.33,0.3-0.65,0.6-0.98,0.9
                C268.37,1102.81,268.34,1102.78,268.34,1102.78z"/>
            <path onClick={() => props.onFill(709)} fill={props.fillColors[709]} d="M452.2,423.28c0.33,0.33,0.65,0.66,0.98,0.98c0,0-0.03,0.03-0.03,0.03c-0.33-0.33-0.65-0.66-0.98-0.99
                C452.17,423.31,452.2,423.28,452.2,423.28z"/>
            <path onClick={() => props.onFill(710)} fill={props.fillColors[710]} d="M488.15,280.41c-0.33,0.33-0.67,0.67-1,1c0,0-0.02-0.02-0.02-0.02c0.33-0.33,0.67-0.66,1-0.99
                C488.13,280.39,488.15,280.41,488.15,280.41z"/>
            <path onClick={() => props.onFill(711)} fill={props.fillColors[711]} d="M673.94,425.31c0,0,0.04-0.04,0.04-0.04C673.98,425.28,673.94,425.31,673.94,425.31z"/>
            <path onClick={() => props.onFill(712)} fill={props.fillColors[712]} d="M488.13,280.39c0.33-0.33,0.66-0.66,0.99-0.99c0,0,0.03,0.03,0.03,0.03c-0.33,0.32-0.67,0.65-1,0.97
                C488.15,280.41,488.13,280.39,488.13,280.39z"/>
            <path onClick={() => props.onFill(713)} fill={props.fillColors[713]} d="M261.34,1106.79c0,0,0.01,0.01,0.01,0.01C261.35,1106.8,261.34,1106.79,261.34,1106.79z"/>
            <path onClick={() => props.onFill(714)} fill={props.fillColors[714]} d="M264.34,1106.78c0.34-0.33,0.68-0.66,1.01-0.98c0,0-0.01-0.01-0.01-0.01c-0.33,0.34-0.66,0.68-0.99,1.02
                C264.35,1106.8,264.34,1106.78,264.34,1106.78z"/>
            <path onClick={() => props.onFill(715)} fill={props.fillColors[715]} d="M264.35,1106.8c0,0-0.01-0.02-0.01-0.02C264.34,1106.78,264.35,1106.8,264.35,1106.8z"/>
            <path onClick={() => props.onFill(716)} fill={props.fillColors[716]} d="M256.36,676.12c0,0-0.02-0.02-0.02-0.02C256.34,676.1,256.36,676.12,256.36,676.12z"/>
            <polygon onClick={() => props.onFill(717)} fill={props.fillColors[717]} points="262.36,1109.8 262.49,1109.65 262.34,1109.78 		"/>
            <path onClick={() => props.onFill(718)} fill={props.fillColors[718]} d="M374.25,279.42c-0.34,0.33-0.68,0.65-1.02,0.98c0,0,0.02,0.02,0.02,0.02c0.32-0.34,0.65-0.68,0.97-1.02
                C374.22,279.39,374.25,279.42,374.25,279.42z"/>
            <polygon onClick={() => props.onFill(719)} fill={props.fillColors[719]} points="262.34,1109.78 262.22,1109.92 262.36,1109.8 		"/>
            <path onClick={() => props.onFill(720)} fill={props.fillColors[720]} d="M1005.66,1110.78c0,0-0.01,0.02-0.01,0.02C1005.64,1110.79,1005.66,1110.78,1005.66,1110.78z"/>
            <path onClick={() => props.onFill(721)} fill={props.fillColors[721]} d="M110.52,429.37c0,0,0.06-0.06,0.06-0.06S110.52,429.37,110.52,429.37z"/>
            <polygon onClick={() => props.onFill(722)} fill={props.fillColors[722]} points="245.36,1118.79 245.5,1118.65 245.35,1118.76 		"/>
            <path onClick={() => props.onFill(723)} fill={props.fillColors[723]} d="M795.84,1118.78c0,0,0,0.02,0,0.02S795.84,1118.78,795.84,1118.78z"/>
            <path onClick={() => props.onFill(724)} fill={props.fillColors[724]} d="M372.23,277.39c0,0,0.02,0.02,0.02,0.02S372.23,277.39,372.23,277.39z"/>
            <path onClick={() => props.onFill(725)} fill={props.fillColors[725]} d="M111.45,430.25c-0.31-0.29-0.62-0.58-0.93-0.88c0,0,0.06-0.06,0.06-0.06c0.31,0.3,0.61,0.59,0.91,0.88
                C111.5,430.19,111.45,430.25,111.45,430.25z"/>
            <path onClick={() => props.onFill(726)} fill={props.fillColors[726]} d="M804.83,1121.78C804.83,1121.78,804.83,1121.79,804.83,1121.78
                C804.83,1121.79,804.83,1121.78,804.83,1121.78z"/>
            <polygon onClick={() => props.onFill(727)} fill={props.fillColors[727]} points="240.37,1122.79 240.57,1122.71 240.38,1122.77 		"/>
            <polygon onClick={() => props.onFill(728)} fill={props.fillColors[728]} points="240.38,1122.77 240.24,1122.89 240.37,1122.79 		"/>
            <path onClick={() => props.onFill(729)} fill={props.fillColors[729]} d="M242.35,1123.73c0.33-0.31,0.66-0.62,0.98-0.93c0,0,0.05,0.05,0.05,0.05c-0.33,0.31-0.66,0.61-1,0.92
                C242.39,1123.77,242.35,1123.73,242.35,1123.73z"/>
            <path onClick={() => props.onFill(730)} fill={props.fillColors[730]} d="M817.84,1123.79c0,0-0.03-0.03-0.03-0.03C817.81,1123.76,817.84,1123.79,817.84,1123.79z"/>
            <polygon onClick={() => props.onFill(731)} fill={props.fillColors[731]} points="237.38,1124.79 237.52,1124.62 237.36,1124.77 		"/>
            <polygon onClick={() => props.onFill(732)} fill={props.fillColors[732]} points="83.5,672.13 83.63,672.29 83.52,672.11 		"/>
            <path onClick={() => props.onFill(733)} fill={props.fillColors[733]} d="M794.86,1123.78c0,0-0.01,0-0.01,0S794.86,1123.78,794.86,1123.78z"/>
            <polygon onClick={() => props.onFill(734)} fill={props.fillColors[734]} points="83.52,672.11 83.39,671.99 83.5,672.13 		"/>
            <path onClick={() => props.onFill(735)} fill={props.fillColors[735]} d="M130.47,430.29c0,0-0.01,0.01-0.01,0.01C130.46,430.3,130.47,430.29,130.47,430.29z"/>
            <polygon onClick={() => props.onFill(736)} fill={props.fillColors[736]} points="237.36,1124.77 237.23,1124.91 237.38,1124.79 		"/>
            <path onClick={() => props.onFill(737)} fill={props.fillColors[737]} d="M999.65,1126.78C999.65,1126.78,999.65,1126.79,999.65,1126.78
                C999.65,1126.79,999.65,1126.78,999.65,1126.78z"/>
            <path onClick={() => props.onFill(738)} fill={props.fillColors[738]} d="M237.33,1127.79l0.05,0.05L237.33,1127.79z"/>
            <path onClick={() => props.onFill(739)} fill={props.fillColors[739]} d="M985.65,1126.76c0,0,0.03,0.03,0.03,0.03C985.68,1126.79,985.65,1126.76,985.65,1126.76z"/>
            <path onClick={() => props.onFill(740)} fill={props.fillColors[740]} d="M237.38,1127.85c-0.34,0.31-0.69,0.61-1.03,0.92c0,0,0.03,0.03,0.03,0.03c0.32-0.33,0.63-0.67,0.95-1
                C237.33,1127.79,237.38,1127.85,237.38,1127.85z"/>
            <path onClick={() => props.onFill(741)} fill={props.fillColors[741]} d="M240.39,1127.85c-0.32,0.3-0.65,0.6-0.97,0.91c0,0-0.05-0.05-0.05-0.05c0.33-0.3,0.65-0.6,0.98-0.91
                C240.34,1127.8,240.39,1127.85,240.39,1127.85z"/>
            <path onClick={() => props.onFill(742)} fill={props.fillColors[742]} d="M714.94,504.26c0,0-0.04-0.05-0.04-0.05C714.9,504.21,714.94,504.26,714.94,504.26z"/>
            <path onClick={() => props.onFill(743)} fill={props.fillColors[743]} d="M801.85,1128.78c0,0-0.02-0.01-0.02-0.01C801.83,1128.77,801.85,1128.78,801.85,1128.78z"/>
            <path onClick={() => props.onFill(744)} fill={props.fillColors[744]} d="M102.51,670.1c0,0-0.03,0.03-0.03,0.03S102.51,670.1,102.51,670.1z"/>
            <polygon onClick={() => props.onFill(745)} fill={props.fillColors[745]} points="826.74,1130.81 826.82,1130.74 826.8,1130.88 		"/>
            <path onClick={() => props.onFill(746)} fill={props.fillColors[746]} d="M237.38,1131.79c0.31-0.32,0.63-0.64,0.94-0.97c0,0,0.06,0.06,0.06,0.06c-0.34,0.3-0.68,0.59-1.02,0.89
                L237.38,1131.79z"/>
            <path onClick={() => props.onFill(747)} fill={props.fillColors[747]} d="M825.8,1131.77c0.31-0.32,0.63-0.64,0.94-0.95c0,0,0.06,0.07,0.06,0.07c-0.33,0.3-0.65,0.61-0.98,0.91
                L825.8,1131.77z"/>
            <polygon onClick={() => props.onFill(748)} fill={props.fillColors[748]} points="237.37,1131.77 237.24,1131.91 237.38,1131.79 		"/>
            <path onClick={() => props.onFill(749)} fill={props.fillColors[749]} d="M825.83,1131.79c-0.34,0.32-0.69,0.65-1.03,0.97c0,0,0.04,0.04,0.04,0.04c0.32-0.34,0.65-0.69,0.97-1.03
                L825.83,1131.79z"/>
            <polygon onClick={() => props.onFill(750)} fill={props.fillColors[750]} points="1003.55,1133.8 1003.67,1133.75 1003.62,1133.87 		"/>
            <path onClick={() => props.onFill(751)} fill={props.fillColors[751]} d="M1002.68,1134.75c0.29-0.32,0.58-0.63,0.87-0.95c0,0,0.07,0.07,0.07,0.07c-0.3,0.31-0.59,0.62-0.88,0.92
                C1002.73,1134.79,1002.68,1134.75,1002.68,1134.75z"/>
            <path onClick={() => props.onFill(752)} fill={props.fillColors[752]} d="M492.15,275.45c-0.33,0.32-0.66,0.64-0.99,0.96c0,0-0.03-0.04-0.03-0.04c0.33-0.32,0.66-0.64,0.99-0.96
                C492.11,275.41,492.15,275.45,492.15,275.45z"/>
            <path onClick={() => props.onFill(753)} fill={props.fillColors[753]} d="M91.51,432.27c0,0-0.01,0.01-0.01,0.01C91.5,432.29,91.51,432.27,91.51,432.27z"/>
            <path onClick={() => props.onFill(754)} fill={props.fillColors[754]} d="M112.52,432.34c0.33,0.32,0.66,0.65,1,0.97l-0.05,0.05c-0.34-0.32-0.67-0.65-1.01-0.97
                C112.46,432.4,112.52,432.34,112.52,432.34z"/>
            <path onClick={() => props.onFill(755)} fill={props.fillColors[755]} d="M95.52,668.11c0,0-0.02,0.02-0.02,0.02S95.52,668.11,95.52,668.11z"/>
            <path onClick={() => props.onFill(756)} fill={props.fillColors[756]} d="M380.28,273.37c-0.34,0.33-0.68,0.66-1.02,0.99c0,0-0.05-0.06-0.05-0.06c0.34-0.33,0.68-0.66,1.02-0.99
                C380.23,273.31,380.28,273.37,380.28,273.37z"/>
            <path onClick={() => props.onFill(757)} fill={props.fillColors[757]} d="M810.82,1137.75c0,0,0.03,0.03,0.03,0.03S810.82,1137.75,810.82,1137.75z"/>
            <path onClick={() => props.onFill(758)} fill={props.fillColors[758]} d="M1032.62,1137.77l0.01-0.01C1032.63,1137.76,1032.62,1137.77,1032.62,1137.77z"/>
            <path onClick={() => props.onFill(759)} fill={props.fillColors[759]} d="M113.51,433.32c0.33,0.32,0.66,0.65,0.99,0.97c0,0-0.03,0.03-0.03,0.03c-0.34-0.32-0.67-0.63-1.01-0.95
                C113.47,433.37,113.51,433.32,113.51,433.32z"/>
            <path onClick={() => props.onFill(760)} fill={props.fillColors[760]} d="M848.77,1139.77c0,0,0.02-0.01,0.02-0.01S848.77,1139.77,848.77,1139.77z"/>
            <polygon onClick={() => props.onFill(761)} fill={props.fillColors[761]} points="104.5,435.27 104.31,435.17 104.49,435.29 		"/>
            <path onClick={() => props.onFill(762)} fill={props.fillColors[762]} d="M1011.66,1142.78c0,0-0.03-0.03-0.03-0.03C1011.63,1142.75,1011.66,1142.78,1011.66,1142.78z"/>
            <path onClick={() => props.onFill(763)} fill={props.fillColors[763]} d="M381.22,272.39c-0.31,0.33-0.62,0.65-0.93,0.98c0,0-0.05-0.06-0.05-0.06c0.34-0.3,0.67-0.6,1.01-0.9
                L381.22,272.39z"/>
            <path onClick={() => props.onFill(764)} fill={props.fillColors[764]} d="M114.5,434.29c0.32,0.33,0.65,0.67,0.97,1l0.02-0.02c-0.34-0.32-0.68-0.64-1.02-0.95
                C114.47,434.32,114.5,434.29,114.5,434.29z"/>
            <path onClick={() => props.onFill(765)} fill={props.fillColors[765]} d="M821.81,1144.75c0,0,0.03,0.03,0.03,0.03C821.83,1144.78,821.81,1144.75,821.81,1144.75z"/>
            <path onClick={() => props.onFill(766)} fill={props.fillColors[766]} d="M857.76,1148.76c0,0,0.02,0,0.02,0S857.76,1148.76,857.76,1148.76z"/>
            <path onClick={() => props.onFill(767)} fill={props.fillColors[767]} d="M830.82,1149.77c0.33-0.33,0.67-0.67,1-1c0,0-0.02-0.02-0.02-0.02c-0.33,0.34-0.67,0.67-1,1.01
                L830.82,1149.77z"/>
            <polygon onClick={() => props.onFill(768)} fill={props.fillColors[768]} points="830.81,1149.75 830.69,1149.89 830.82,1149.77 		"/>
            <polygon onClick={() => props.onFill(769)} fill={props.fillColors[769]} points="987.66,1150.75 987.55,1150.87 987.67,1150.76 		"/>
            <path onClick={() => props.onFill(770)} fill={props.fillColors[770]} d="M1019.62,1152.75c0,0,0.02,0.02,0.02,0.02C1019.64,1152.77,1019.62,1152.75,1019.62,1152.75z"/>
            <polygon onClick={() => props.onFill(771)} fill={props.fillColors[771]} points="871.66,1153.79 871.79,1153.75 871.73,1153.86 		"/>
            <path onClick={() => props.onFill(772)} fill={props.fillColors[772]} d="M1018.65,1153.78c0.32-0.34,0.65-0.69,0.97-1.03c0,0,0.02,0.02,0.02,0.02c-0.34,0.33-0.69,0.65-1.03,0.98
                C1018.61,1153.74,1018.65,1153.78,1018.65,1153.78z"/>
            <path onClick={() => props.onFill(773)} fill={props.fillColors[773]} d="M871.73,1153.86c-0.32,0.31-0.64,0.63-0.95,0.94l-0.05-0.05c0.31-0.32,0.62-0.65,0.93-0.97
                C871.66,1153.79,871.73,1153.86,871.73,1153.86z"/>
            <path onClick={() => props.onFill(774)} fill={props.fillColors[774]} d="M870.78,1154.8c-0.34,0.32-0.68,0.63-1.02,0.95c0,0,0.03,0.03,0.03,0.03c0.32-0.34,0.63-0.68,0.95-1.02
                C870.73,1154.75,870.78,1154.8,870.78,1154.8z"/>
            <polygon onClick={() => props.onFill(775)} fill={props.fillColors[775]} points="880.66,1160.76 880.8,1160.74 880.73,1160.82 		"/>
            <path onClick={() => props.onFill(776)} fill={props.fillColors[776]} d="M880.73,1160.82c-0.32,0.32-0.64,0.65-0.96,0.97c0,0-0.05-0.05-0.05-0.05c0.32-0.33,0.63-0.66,0.94-0.99
                C880.66,1160.76,880.73,1160.82,880.73,1160.82z"/>
            <path onClick={() => props.onFill(777)} fill={props.fillColors[777]} d="M879.77,1161.8c-0.34,0.32-0.68,0.63-1.02,0.95c0,0,0.02,0.02,0.02,0.02c0.32-0.34,0.63-0.68,0.95-1.02
                C879.72,1161.75,879.77,1161.8,879.77,1161.8z"/>
            <path onClick={() => props.onFill(778)} fill={props.fillColors[778]} d="M494.13,272.41c0,0-0.01-0.01-0.01-0.01C494.12,272.4,494.13,272.41,494.13,272.41z"/>
            <path onClick={() => props.onFill(779)} fill={props.fillColors[779]} d="M891.72,1165.74l0.04,0.03L891.72,1165.74z"/>
            <path onClick={() => props.onFill(780)} fill={props.fillColors[780]} d="M891.76,1165.77c-0.34,0.32-0.68,0.64-1.03,0.97c0,0,0.03,0.03,0.03,0.03c0.32-0.34,0.64-0.69,0.96-1.03
                C891.72,1165.74,891.76,1165.77,891.76,1165.77z"/>
            <polygon onClick={() => props.onFill(781)} fill={props.fillColors[781]} points="905.62,1166.78 905.75,1166.73 905.7,1166.85 		"/>
            <polygon onClick={() => props.onFill(782)} fill={props.fillColors[782]} points="1027.63,1166.75 1027.73,1166.64 1027.62,1166.76 		"/>
            <path onClick={() => props.onFill(783)} fill={props.fillColors[783]} d="M904.65,1167.76c0.32-0.33,0.65-0.65,0.97-0.98c0,0,0.07,0.07,0.07,0.08c-0.33,0.32-0.65,0.64-0.98,0.96
                L904.65,1167.76z"/>
            <path onClick={() => props.onFill(784)} fill={props.fillColors[784]} d="M864.77,1167.72c-0.33,0.34-0.66,0.68-0.99,1.02l0.01,0.02c0.34-0.33,0.67-0.67,1.01-1
                C864.8,1167.75,864.77,1167.72,864.77,1167.72z"/>
            <path onClick={() => props.onFill(785)} fill={props.fillColors[785]} d="M904.72,1167.82c-0.32,0.32-0.65,0.64-0.97,0.96l-0.04-0.04c0.32-0.33,0.64-0.65,0.95-0.98
                C904.65,1167.76,904.72,1167.82,904.72,1167.82z"/>
            <polygon onClick={() => props.onFill(786)} fill={props.fillColors[786]} points="863.78,1168.74 863.66,1168.86 863.79,1168.75 		"/>
            <path onClick={() => props.onFill(787)} fill={props.fillColors[787]} d="M903.74,1168.78c-0.34,0.32-0.68,0.64-1.02,0.96c0,0,0.03,0.03,0.03,0.03c0.32-0.34,0.63-0.69,0.95-1.03
                C903.7,1168.74,903.74,1168.78,903.74,1168.78z"/>
            <path onClick={() => props.onFill(788)} fill={props.fillColors[788]} d="M871.78,1174.74C871.78,1174.74,871.77,1174.74,871.78,1174.74
                C871.77,1174.74,871.78,1174.74,871.78,1174.74z"/>
            <polygon onClick={() => props.onFill(789)} fill={props.fillColors[789]} points="381.24,272.41 381.36,272.27 381.22,272.39 		"/>
            <polygon onClick={() => props.onFill(790)} fill={props.fillColors[790]} points="906.63,1181.78 906.75,1181.73 906.71,1181.86 		"/>
            <path onClick={() => props.onFill(791)} fill={props.fillColors[791]} d="M906.71,1181.86c-0.33,0.33-0.66,0.66-0.99,0.99c0,0-0.07-0.07-0.07-0.07c0.33-0.33,0.65-0.67,0.98-1
                C906.63,1181.78,906.71,1181.86,906.71,1181.86z"/>
            <path onClick={() => props.onFill(792)} fill={props.fillColors[792]} d="M904.7,1183.75c0.32-0.32,0.64-0.65,0.95-0.97c0,0,0.07,0.07,0.07,0.07c-0.32,0.32-0.65,0.64-0.97,0.96
                C904.75,1183.81,904.7,1183.75,904.7,1183.75z"/>
            <path onClick={() => props.onFill(793)} fill={props.fillColors[793]} d="M904.75,1183.81c-0.33,0.31-0.67,0.62-1,0.93c0,0-0.01,0-0.01,0c0.32-0.33,0.64-0.65,0.96-0.98
                C904.7,1183.75,904.75,1183.81,904.75,1183.81z"/>
            <path onClick={() => props.onFill(794)} fill={props.fillColors[794]} d="M1042.62,1186.74c0,0-0.01-0.01-0.01-0.01C1042.61,1186.72,1042.62,1186.74,1042.62,1186.74z"/>
            <path onClick={() => props.onFill(795)} fill={props.fillColors[795]} d="M939.69,1190.73c0,0,0.02-0.01,0.02-0.01C939.71,1190.72,939.69,1190.73,939.69,1190.73z"/>
            <polygon onClick={() => props.onFill(796)} fill={props.fillColors[796]} points="219.39,270.4 219.28,270.52 219.4,270.4 		"/>
            <polygon onClick={() => props.onFill(797)} fill={props.fillColors[797]} points="104.49,435.29 104.63,435.39 104.5,435.27 		"/>
            <path onClick={() => props.onFill(798)} fill={props.fillColors[798]} d="M950.68,1196.72l0.03,0.04L950.68,1196.72z"/>
            <path onClick={() => props.onFill(799)} fill={props.fillColors[799]} d="M950.71,1196.76c-0.34,0.32-0.69,0.64-1.03,0.96c0,0,0.04,0.04,0.04,0.04c0.32-0.34,0.64-0.68,0.97-1.03
                C950.68,1196.72,950.71,1196.76,950.71,1196.76z"/>
            <path onClick={() => props.onFill(800)} fill={props.fillColors[800]} d="M115.47,435.29c0.33,0.34,0.67,0.68,1,1.01c0,0,0.03-0.03,0.03-0.03c-0.34-0.34-0.68-0.67-1.01-1.01
                C115.49,435.27,115.47,435.29,115.47,435.29z"/>
            <polygon onClick={() => props.onFill(801)} fill={props.fillColors[801]} points="149.47,436.28 149.3,436.12 149.44,436.3 		"/>
            <path onClick={() => props.onFill(802)} fill={props.fillColors[802]} d="M107.49,437.3C107.49,437.3,107.49,437.3,107.49,437.3C107.49,437.3,107.49,437.3,107.49,437.3z"/>
            <polygon onClick={() => props.onFill(803)} fill={props.fillColors[803]} points="961.58,1205.74 961.7,1205.71 961.65,1205.81 		"/>
            <path onClick={() => props.onFill(804)} fill={props.fillColors[804]} d="M280.33,1205.72C280.33,1205.72,280.32,1205.71,280.33,1205.72
                C280.32,1205.71,280.33,1205.72,280.33,1205.72z"/>
            <path onClick={() => props.onFill(805)} fill={props.fillColors[805]} d="M961.65,1205.81c-0.31,0.29-0.62,0.59-0.93,0.88c0,0-0.04-0.05-0.04-0.05c0.3-0.3,0.61-0.6,0.91-0.9
                C961.58,1205.74,961.65,1205.81,961.65,1205.81z"/>
            <polygon onClick={() => props.onFill(806)} fill={props.fillColors[806]} points="149.44,436.3 149.59,436.43 149.47,436.28 		"/>
            <polygon onClick={() => props.onFill(807)} fill={props.fillColors[807]} points="914.75,1208.71 914.63,1208.82 914.74,1208.72 		"/>
            <path onClick={() => props.onFill(808)} fill={props.fillColors[808]} d="M127.5,653.1c0,0-0.05,0.05-0.05,0.05C127.45,653.16,127.5,653.1,127.5,653.1z"/>
            <polygon onClick={() => props.onFill(809)} fill={props.fillColors[809]} points="968.68,1218.73 968.85,1218.56 968.67,1218.69 		"/>
            <path onClick={() => props.onFill(810)} fill={props.fillColors[810]} d="M107.51,651.12c-0.34-0.34-0.68-0.67-1.01-1.01c0,0-0.02,0.02-0.02,0.02c0.34,0.34,0.67,0.67,1.01,1.01
                C107.48,651.15,107.51,651.12,107.51,651.12z"/>
            <path onClick={() => props.onFill(811)} fill={props.fillColors[811]} d="M102.5,651.15c0,0,0-0.02,0-0.02S102.5,651.15,102.5,651.15z"/>
            <path onClick={() => props.onFill(812)} fill={props.fillColors[812]} d="M113.47,437.3c0,0,0.02-0.02,0.02-0.02C113.5,437.27,113.47,437.3,113.47,437.3z"/>
            <path onClick={() => props.onFill(813)} fill={props.fillColors[813]} d="M966.7,1221.71c0.32-0.32,0.64-0.65,0.96-0.97c0,0,0.05,0.05,0.05,0.05c-0.34,0.3-0.69,0.59-1.03,0.89
                C966.68,1221.69,966.7,1221.71,966.7,1221.71z"/>
            <path onClick={() => props.onFill(814)} fill={props.fillColors[814]} d="M965.69,1222.72c0.34-0.33,0.67-0.67,1.01-1c0,0-0.02-0.02-0.02-0.02c-0.33,0.34-0.67,0.67-1,1.01
                C965.67,1222.7,965.69,1222.72,965.69,1222.72z"/>
            <path onClick={() => props.onFill(815)} fill={props.fillColors[815]} d="M339.28,260.41c0.33,0.33,0.67,0.67,1,1c0,0-0.02,0.02-0.02,0.02c-0.33-0.33-0.67-0.67-1-1
                C339.26,260.42,339.28,260.41,339.28,260.41z"/>
            <path onClick={() => props.onFill(816)} fill={props.fillColors[816]} d="M338.28,259.42c0.33,0.33,0.67,0.66,1,0.99c0,0-0.02,0.02-0.02,0.02
                C338.93,260.09,338.6,259.75,338.28,259.42C338.27,259.42,338.28,259.42,338.28,259.42z"/>
            <path onClick={() => props.onFill(817)} fill={props.fillColors[817]} d="M122.46,437.3c0,0,0.03-0.03,0.03-0.03S122.46,437.3,122.46,437.3z"/>
            <polygon onClick={() => props.onFill(818)} fill={props.fillColors[818]} points="978.56,1228.71 978.7,1228.7 978.63,1228.77 		"/>
            <path onClick={() => props.onFill(819)} fill={props.fillColors[819]} d="M977.66,1229.65c0.3-0.31,0.6-0.63,0.9-0.94c0,0,0.06,0.06,0.06,0.06c-0.31,0.31-0.62,0.61-0.93,0.92
                C977.7,1229.69,977.66,1229.65,977.66,1229.65z"/>
            <path onClick={() => props.onFill(820)} fill={props.fillColors[820]} d="M103.48,648.14c-0.33-0.33-0.67-0.67-1-1c0,0,0.03-0.02,0.03-0.02c0.33,0.33,0.67,0.67,1,1
                C103.5,648.12,103.48,648.14,103.48,648.14z"/>
            <path onClick={() => props.onFill(821)} fill={props.fillColors[821]} d="M1073.56,255.44c0,0,0.04-0.04,0.04-0.04C1073.6,255.4,1073.56,255.44,1073.56,255.44z"/>
            <polygon onClick={() => props.onFill(822)} fill={props.fillColors[822]} points="946.71,1237.69 946.59,1237.81 946.72,1237.71 		"/>
            <path onClick={() => props.onFill(823)} fill={props.fillColors[823]} d="M990.65,1239.69c0,0,0.01,0.01,0.01,0.01C990.67,1239.7,990.65,1239.69,990.65,1239.69z"/>
            <path onClick={() => props.onFill(824)} fill={props.fillColors[824]} d="M1006.58,1242.68c0,0,0.05,0.05,0.05,0.05S1006.58,1242.68,1006.58,1242.68z"/>
            <path onClick={() => props.onFill(825)} fill={props.fillColors[825]} d="M1005.65,1243.71c0.31-0.34,0.62-0.69,0.92-1.03c0,0,0.05,0.05,0.05,0.05c-0.34,0.32-0.67,0.63-1.01,0.95
                C1005.63,1243.68,1005.65,1243.71,1005.65,1243.71z"/>
            <path onClick={() => props.onFill(826)} fill={props.fillColors[826]} d="M954.7,1245.68c0.34-0.33,0.67-0.65,1.01-0.98c0,0-0.03-0.03-0.03-0.03
                C955.36,1245.01,955.03,1245.35,954.7,1245.68L954.7,1245.68z"/>
            <path onClick={() => props.onFill(827)} fill={props.fillColors[827]} d="M954.7,1245.69C954.7,1245.69,954.7,1245.68,954.7,1245.69C954.7,1245.68,954.7,1245.69,954.7,1245.69z"/>
            <path onClick={() => props.onFill(828)} fill={props.fillColors[828]} d="M98.52,647.1l-0.05,0.05L98.52,647.1z"/>
            <polygon onClick={() => props.onFill(829)} fill={props.fillColors[829]} points="1011.63,1253.69 1011.78,1253.57 1011.64,1253.66 		"/>
            <path onClick={() => props.onFill(830)} fill={props.fillColors[830]} d="M962.68,1256.67c-0.33,0.33-0.66,0.67-0.99,1l0,0c0.33-0.33,0.67-0.66,1-0.99
                C962.7,1256.69,962.68,1256.67,962.68,1256.67z"/>
            <path onClick={() => props.onFill(831)} fill={props.fillColors[831]} d="M101.5,646.11c0.33,0.33,0.67,0.67,1,1c0,0-0.03,0.02-0.03,0.02c-0.34-0.33-0.67-0.66-1.01-1
                C101.47,646.14,101.5,646.11,101.5,646.11z"/>
            <path onClick={() => props.onFill(832)} fill={props.fillColors[832]} d="M982.68,1257.72c-0.34,0.32-0.67,0.63-1.01,0.95c0,0,0,0.03,0,0.03c0.33-0.34,0.65-0.68,0.98-1.01
                C982.66,1257.68,982.68,1257.72,982.68,1257.72z"/>
            <path onClick={() => props.onFill(833)} fill={props.fillColors[833]} d="M98.47,647.15c0,0,0.05-0.05,0.05-0.05C98.52,647.1,98.47,647.15,98.47,647.15z"/>
            <polygon onClick={() => props.onFill(834)} fill={props.fillColors[834]} points="981.68,1258.67 981.53,1258.8 981.68,1258.7 		"/>
            <path onClick={() => props.onFill(835)} fill={props.fillColors[835]} d="M334.29,252.41c0,0-0.02,0.02-0.02,0.02C334.27,252.43,334.29,252.41,334.29,252.41z"/>
            <path onClick={() => props.onFill(836)} fill={props.fillColors[836]} d="M100.51,645.13c0.33,0.33,0.66,0.66,0.99,0.99c0,0-0.03,0.03-0.03,0.03c-0.33-0.33-0.65-0.66-0.98-0.99
                C100.49,645.15,100.51,645.13,100.51,645.13z"/>
            <polygon onClick={() => props.onFill(837)} fill={props.fillColors[837]} points="151.46,439.4 151.46,439.25 151.53,439.33 		"/>
            <path onClick={() => props.onFill(838)} fill={props.fillColors[838]} d="M117.49,644.12c0,0-0.02,0.01-0.02,0.01C117.47,644.14,117.49,644.12,117.49,644.12z"/>
            <polygon onClick={() => props.onFill(839)} fill={props.fillColors[839]} points="1012.6,1266.72 1012.64,1266.63 1012.66,1266.78 		"/>
            <path onClick={() => props.onFill(840)} fill={props.fillColors[840]} d="M1011.64,1267.66c0.32-0.32,0.64-0.63,0.96-0.94c0,0,0.06,0.06,0.06,0.06c-0.34,0.3-0.67,0.6-1.01,0.9
                L1011.64,1267.66z"/>
            <path onClick={() => props.onFill(841)} fill={props.fillColors[841]} d="M151.53,439.33c0.34,0.33,0.69,0.65,1.03,0.98c0,0-0.07,0.07-0.07,0.07c-0.34-0.33-0.69-0.66-1.03-0.98
                C151.46,439.4,151.53,439.33,151.53,439.33z"/>
            <path onClick={() => props.onFill(842)} fill={props.fillColors[842]} d="M139.45,441.34c-0.34-0.33-0.67-0.67-1.01-1c0,0,0.04-0.04,0.04-0.04c0.34,0.33,0.68,0.66,1.02,1
                L139.45,441.34z"/>
            <path onClick={() => props.onFill(843)} fill={props.fillColors[843]} d="M153.5,441.36c-0.34-0.32-0.67-0.65-1.01-0.97c0,0,0.07-0.07,0.07-0.07c0.33,0.33,0.67,0.65,1,0.98
                C153.56,441.29,153.5,441.36,153.5,441.36z"/>
            <path onClick={() => props.onFill(844)} fill={props.fillColors[844]} d="M111.5,442.27c0,0-0.02,0.02-0.02,0.02C111.48,442.29,111.5,442.27,111.5,442.27z"/>
            <polygon onClick={() => props.onFill(845)} fill={props.fillColors[845]} points="986.67,1274.66 986.53,1274.79 986.68,1274.69 		"/>
            <path onClick={() => props.onFill(846)} fill={props.fillColors[846]} d="M998.67,1277.67c0,0-0.01-0.01-0.01-0.01S998.67,1277.67,998.67,1277.67z"/>
            <path onClick={() => props.onFill(847)} fill={props.fillColors[847]} d="M1009.65,1278.67c0,0-0.01-0.02-0.01-0.02S1009.65,1278.67,1009.65,1278.67z"/>
            <path onClick={() => props.onFill(848)} fill={props.fillColors[848]} d="M139.5,441.29c0.34,0.33,0.68,0.66,1.02,0.99c0,0-0.05,0.05-0.05,0.05c-0.34-0.33-0.68-0.67-1.02-1
                C139.45,441.34,139.5,441.29,139.5,441.29z"/>
            <path onClick={() => props.onFill(849)} fill={props.fillColors[849]} d="M153.56,441.29c0.33,0.33,0.66,0.66,0.99,0.99c0,0-0.06,0.06-0.06,0.06c-0.33-0.33-0.66-0.66-0.99-0.99
                C153.5,441.36,153.56,441.29,153.56,441.29z"/>
            <path onClick={() => props.onFill(850)} fill={props.fillColors[850]} d="M788.85,245.43c0,0,0.01-0.01,0.01-0.01C788.86,245.42,788.85,245.43,788.85,245.43z"/>
            <path onClick={() => props.onFill(851)} fill={props.fillColors[851]} d="M141.48,443.32c-0.34-0.33-0.68-0.66-1.01-0.99c0,0,0.05-0.05,0.05-0.05c0.34,0.33,0.68,0.66,1.01,0.99
                L141.48,443.32z"/>
            <path onClick={() => props.onFill(852)} fill={props.fillColors[852]} d="M155.46,443.33c-0.32-0.33-0.65-0.65-0.97-0.98c0,0,0.06-0.06,0.06-0.06c0.32,0.33,0.64,0.66,0.96,0.99
                C155.51,443.27,155.46,443.33,155.46,443.33z"/>
            <path onClick={() => props.onFill(853)} fill={props.fillColors[853]} d="M270.33,1302.64c0,0,0.01,0.01,0.01,0.01C270.35,1302.65,270.33,1302.64,270.33,1302.64z"/>
            <path onClick={() => props.onFill(854)} fill={props.fillColors[854]} d="M141.54,443.27c0.32,0.33,0.65,0.66,0.97,0.99c0,0-0.04,0.04-0.04,0.04c-0.33-0.33-0.66-0.65-0.98-0.98
                C141.48,443.32,141.54,443.27,141.54,443.27z"/>
            <path onClick={() => props.onFill(855)} fill={props.fillColors[855]} d="M270.34,1306.65C270.34,1306.65,270.33,1306.64,270.34,1306.65
                C270.33,1306.64,270.34,1306.65,270.34,1306.65z"/>
            <path onClick={() => props.onFill(856)} fill={props.fillColors[856]} d="M266.33,1308.64c0,0,0.01,0.01,0.01,0.01C266.34,1308.65,266.33,1308.64,266.33,1308.64z"/>
            <path onClick={() => props.onFill(857)} fill={props.fillColors[857]} d="M148.46,236.43c0,0-0.01,0.01-0.01,0.01C148.45,236.44,148.46,236.43,148.46,236.43z"/>
            <polygon onClick={() => props.onFill(858)} fill={props.fillColors[858]} points="260.36,1311.63 260.23,1311.75 260.36,1311.65 		"/>
            <path onClick={() => props.onFill(859)} fill={props.fillColors[859]} d="M256.34,1319.61c-0.33,0.33-0.67,0.67-1,1c0,0,0.03,0.03,0.03,0.03c0.33-0.33,0.67-0.67,1-1
                C256.37,1319.64,256.34,1319.61,256.34,1319.61z"/>
            <path onClick={() => props.onFill(860)} fill={props.fillColors[860]} d="M255.34,1320.61c-0.34,0.34-0.67,0.67-1.01,1.01c0,0,0.03,0.03,0.03,0.03c0.34-0.34,0.67-0.68,1.01-1.01
                C255.37,1320.64,255.34,1320.61,255.34,1320.61z"/>
            <path onClick={() => props.onFill(861)} fill={props.fillColors[861]} d="M836.82,1326.67c0,0,0.05-0.05,0.05-0.05C836.88,1326.62,836.82,1326.67,836.82,1326.67z"/>
            <path onClick={() => props.onFill(862)} fill={props.fillColors[862]} d="M837.79,1327.63c-0.32-0.32-0.65-0.64-0.97-0.96c0,0,0.05-0.05,0.06-0.05c0.31,0.33,0.62,0.67,0.93,1
                L837.79,1327.63z"/>
            <path onClick={() => props.onFill(863)} fill={props.fillColors[863]} d="M987.65,228.43c0,0,0.02,0.01,0.01,0.01C987.67,228.45,987.65,228.43,987.65,228.43z"/>
            <path onClick={() => props.onFill(864)} fill={props.fillColors[864]} d="M233.38,1331.63c0,0-0.01-0.02-0.01-0.02S233.38,1331.63,233.38,1331.63z"/>
            <path onClick={() => props.onFill(865)} fill={props.fillColors[865]} d="M156.44,444.32c-0.33-0.33-0.65-0.66-0.98-0.99c0,0,0.05-0.05,0.05-0.05c0.32,0.33,0.65,0.67,0.97,1
                C156.48,444.27,156.44,444.32,156.44,444.32z"/>
            <path onClick={() => props.onFill(866)} fill={props.fillColors[866]} d="M178.43,1338.63c0,0-0.01-0.01-0.01-0.01C178.42,1338.62,178.43,1338.63,178.43,1338.63z"/>
            <polygon onClick={() => props.onFill(867)} fill={props.fillColors[867]} points="855.81,1338.6 855.66,1338.44 855.79,1338.62 		"/>
            <polygon onClick={() => props.onFill(868)} fill={props.fillColors[868]} points="855.79,1338.62 855.93,1338.75 855.81,1338.6 		"/>
            <polygon onClick={() => props.onFill(869)} fill={props.fillColors[869]} points="858.79,1340.63 858.67,1340.52 858.78,1340.63 		"/>
            <path onClick={() => props.onFill(870)} fill={props.fillColors[870]} d="M806.83,1340.61c0,0,0.02,0.02,0.02,0.02S806.83,1340.61,806.83,1340.61z"/>
            <path onClick={() => props.onFill(871)} fill={props.fillColors[871]} d="M815.84,1340.62c0,0-0.01-0.02-0.01-0.02C815.82,1340.6,815.84,1340.62,815.84,1340.62z"/>
            <path onClick={() => props.onFill(872)} fill={props.fillColors[872]} d="M568.04,595.15c0,0,0.04,0.04,0.04,0.04C568.08,595.19,568.04,595.15,568.04,595.15z"/>
            <path onClick={() => props.onFill(873)} fill={props.fillColors[873]} d="M143.46,445.3c-0.33-0.33-0.66-0.66-0.99-1c0,0,0.04-0.04,0.04-0.04c0.33,0.33,0.66,0.67,0.98,1
                L143.46,445.3z"/>
            <path onClick={() => props.onFill(874)} fill={props.fillColors[874]} d="M861.76,1342.63c-0.33-0.33-0.65-0.66-0.98-0.99c0,0,0.03-0.03,0.03-0.03c0.32,0.33,0.65,0.67,0.97,1
                C861.79,1342.6,861.76,1342.63,861.76,1342.63z"/>
            <path onClick={() => props.onFill(875)} fill={props.fillColors[875]} d="M807.83,1343.61C807.83,1343.61,807.84,1343.62,807.83,1343.61
                C807.84,1343.62,807.83,1343.61,807.83,1343.61z"/>
            <path onClick={() => props.onFill(876)} fill={props.fillColors[876]} d="M156.48,444.27c0.32,0.33,0.64,0.67,0.96,1c0,0-0.01,0-0.01,0c-0.33-0.32-0.67-0.64-1-0.97
                C156.44,444.32,156.48,444.27,156.48,444.27z"/>
            <polygon onClick={() => props.onFill(877)} fill={props.fillColors[877]} points="813.73,1344.64 813.85,1344.6 813.8,1344.71 		"/>
            <path onClick={() => props.onFill(878)} fill={props.fillColors[878]} d="M143.49,445.27c0.33,0.33,0.66,0.67,0.99,1c0,0-0.02,0.02-0.02,0.02c-0.33-0.33-0.66-0.66-1-1
                C143.46,445.3,143.49,445.27,143.49,445.27z"/>
            <path onClick={() => props.onFill(879)} fill={props.fillColors[879]} d="M813.8,1344.71c-0.32,0.3-0.64,0.6-0.96,0.91l0,0c0.3-0.32,0.6-0.65,0.9-0.97
                C813.73,1344.64,813.8,1344.71,813.8,1344.71z"/>
            <path onClick={() => props.onFill(880)} fill={props.fillColors[880]} d="M145.46,447.27c-0.34-0.33-0.67-0.65-1.01-0.98c0,0,0.02-0.02,0.03-0.02c0.32,0.34,0.64,0.68,0.97,1.01
                L145.46,447.27z"/>
            <path onClick={() => props.onFill(881)} fill={props.fillColors[881]} d="M849.79,1346.6C849.79,1346.6,849.79,1346.6,849.79,1346.6C849.79,1346.6,849.79,1346.6,849.79,1346.6z"/>
            <path onClick={() => props.onFill(882)} fill={props.fillColors[882]} d="M116.48,448.27c-0.33-0.34-0.66-0.67-0.99-1.01c0,0-0.03,0.03-0.03,0.03
                C115.8,447.61,116.14,447.94,116.48,448.27C116.48,448.26,116.48,448.27,116.48,448.27z"/>
            <polygon onClick={() => props.onFill(883)} fill={props.fillColors[883]} points="820.83,1348.62 820.96,1348.47 820.81,1348.6 		"/>
            <path onClick={() => props.onFill(884)} fill={props.fillColors[884]} d="M218.34,589.22c-0.3,0.31-0.6,0.61-0.91,0.92c0,0-0.06-0.06-0.05-0.06c0.3-0.31,0.6-0.61,0.9-0.92
                C218.28,589.16,218.34,589.22,218.34,589.22z"/>
            <path onClick={() => props.onFill(885)} fill={props.fillColors[885]} d="M145.44,447.28c0.33,0.33,0.66,0.67,0.99,1l0.02-0.02c-0.33-0.33-0.67-0.66-1-0.99
                C145.46,447.27,145.44,447.28,145.44,447.28z"/>
            <path onClick={() => props.onFill(886)} fill={props.fillColors[886]} d="M848.8,1349.6c-0.34-0.33-0.67-0.66-1.01-0.98l0,0c0.33,0.34,0.65,0.67,0.98,1.01L848.8,1349.6z"/>
            <path onClick={() => props.onFill(887)} fill={props.fillColors[887]} d="M848.78,1349.62c0.34,0.34,0.67,0.67,1.01,1.01c0,0,0.03-0.03,0.03-0.03c-0.34-0.34-0.68-0.67-1.01-1.01
                C848.8,1349.6,848.78,1349.62,848.78,1349.62z"/>
            <polygon onClick={() => props.onFill(888)} fill={props.fillColors[888]} points="220.4,588.18 220.52,588.03 220.37,588.16 		"/>
            <path onClick={() => props.onFill(889)} fill={props.fillColors[889]} d="M896.73,206.47c0,0,0.03-0.03,0.03-0.03C896.77,206.44,896.73,206.47,896.73,206.47z"/>
            <path onClick={() => props.onFill(890)} fill={props.fillColors[890]} d="M116.48,448.26C116.48,448.26,116.48,448.27,116.48,448.26C116.48,448.27,116.48,448.26,116.48,448.26z"/>
            <path onClick={() => props.onFill(891)} fill={props.fillColors[891]} d="M218.38,1353.6c0,0,0.01,0.01,0.01,0.01S218.38,1353.6,218.38,1353.6z"/>
            <polygon onClick={() => props.onFill(892)} fill={props.fillColors[892]} points="823.83,1354.61 823.95,1354.47 823.81,1354.6 		"/>
            <path onClick={() => props.onFill(893)} fill={props.fillColors[893]} d="M708.92,202.47c0.33,0.34,0.66,0.67,1,1.01c0,0,0.03-0.03,0.03-0.03c-0.33-0.33-0.67-0.67-1-1
                C708.94,202.45,708.92,202.47,708.92,202.47z"/>
            <path onClick={() => props.onFill(894)} fill={props.fillColors[894]} d="M142.45,448.28c0,0,0.01-0.01,0.01-0.01S142.45,448.28,142.45,448.28z"/>
            <path onClick={() => props.onFill(895)} fill={props.fillColors[895]} d="M708.94,202.45c-0.34-0.33-0.68-0.66-1.02-0.99c0,0,0-0.01,0-0.01c0.33,0.34,0.66,0.68,0.99,1.02
                C708.92,202.47,708.94,202.45,708.94,202.45z"/>
            <path onClick={() => props.onFill(896)} fill={props.fillColors[896]} d="M146.44,448.28c0.34,0.34,0.67,0.67,1.01,1.01c0,0,0.03-0.03,0.03-0.03c-0.34-0.34-0.67-0.67-1.01-1.01
                C146.46,448.26,146.44,448.28,146.44,448.28z"/>
            <polygon onClick={() => props.onFill(897)} fill={props.fillColors[897]} points="832.82,1355.61 832.94,1355.47 832.8,1355.6 		"/>
            <polygon onClick={() => props.onFill(898)} fill={props.fillColors[898]} points="816.82,1355.6 816.71,1355.72 816.84,1355.61 		"/>
            <path onClick={() => props.onFill(899)} fill={props.fillColors[899]} d="M704.92,197.39c0.34,0.34,0.68,0.68,1.03,1.02c0,0-0.04,0.04-0.04,0.04c-0.35-0.34-0.7-0.67-1.05-1.01
                C704.86,197.45,704.92,197.39,704.92,197.39z"/>
            <path onClick={() => props.onFill(900)} fill={props.fillColors[900]} d="M823.82,1357.62c-0.34,0.33-0.67,0.65-1.01,0.98l0.02,0.02c0.32-0.34,0.64-0.68,0.95-1.02
                C823.79,1357.59,823.82,1357.62,823.82,1357.62z"/>
            <path onClick={() => props.onFill(901)} fill={props.fillColors[901]} d="M111.55,449.28c0.32,0.3,0.63,0.61,0.94,0.91c0,0-0.06,0.06-0.06,0.06c-0.31-0.31-0.63-0.61-0.94-0.92
                C111.5,449.33,111.55,449.28,111.55,449.28z"/>
            <polygon onClick={() => props.onFill(902)} fill={props.fillColors[902]} points="115.53,450.38 115.47,450.26 115.6,450.31 		"/>
            <polygon onClick={() => props.onFill(903)} fill={props.fillColors[903]} points="822.81,1358.6 822.68,1358.75 822.83,1358.62 		"/>
            <polygon onClick={() => props.onFill(904)} fill={props.fillColors[904]} points="838.71,1362.64 838.82,1362.59 838.78,1362.71 		"/>
            <path onClick={() => props.onFill(905)} fill={props.fillColors[905]} d="M263.33,562.18c0,0,0.02,0.02,0.02,0.02S263.33,562.18,263.33,562.18z"/>
            <path onClick={() => props.onFill(906)} fill={props.fillColors[906]} d="M116.5,451.35c-0.33-0.33-0.65-0.65-0.98-0.98c0,0,0.07-0.07,0.07-0.07c0.32,0.33,0.64,0.66,0.97,0.98
                C116.56,451.29,116.5,451.35,116.5,451.35z"/>
            <path onClick={() => props.onFill(907)} fill={props.fillColors[907]} d="M838.78,1362.71c-0.31,0.3-0.62,0.6-0.92,0.91c0,0-0.04-0.04-0.04-0.04c0.3-0.31,0.6-0.63,0.9-0.94
                C838.71,1362.64,838.78,1362.71,838.78,1362.71z"/>
            <polygon onClick={() => props.onFill(908)} fill={props.fillColors[908]} points="861.82,1366.71 861.78,1366.58 861.89,1366.64 		"/>
            <path onClick={() => props.onFill(909)} fill={props.fillColors[909]} d="M826.83,1366.6c0,0-0.01,0-0.01,0S826.83,1366.6,826.83,1366.6z"/>
            <path onClick={() => props.onFill(910)} fill={props.fillColors[910]} d="M861.89,1366.64c0.29,0.28,0.59,0.56,0.88,0.84c0,0-0.07,0.06-0.07,0.06c-0.3-0.28-0.59-0.56-0.88-0.84
                C861.82,1366.71,861.89,1366.64,861.89,1366.64z"/>
            <path onClick={() => props.onFill(911)} fill={props.fillColors[911]} d="M117.43,452.29c-0.31-0.31-0.62-0.63-0.93-0.94c0,0,0.06-0.06,0.06-0.06c0.31,0.32,0.61,0.64,0.91,0.96
                C117.47,452.25,117.43,452.29,117.43,452.29z"/>
            <path onClick={() => props.onFill(912)} fill={props.fillColors[912]} d="M842.73,1369.6l0.06,0.06L842.73,1369.6z"/>
            <path onClick={() => props.onFill(913)} fill={props.fillColors[913]} d="M842.79,1369.66c-0.31,0.32-0.63,0.64-0.94,0.95c0,0-0.04-0.04-0.04-0.04c0.31-0.32,0.62-0.65,0.93-0.97
                C842.73,1369.6,842.79,1369.66,842.79,1369.66z"/>
            <path onClick={() => props.onFill(914)} fill={props.fillColors[914]} d="M864.79,1370.66c-0.34-0.32-0.68-0.64-1.01-0.95c0,0,0.06-0.06,0.06-0.06c0.34,0.32,0.67,0.64,1.01,0.96
                C864.85,1370.61,864.79,1370.66,864.79,1370.66z"/>
            <path onClick={() => props.onFill(915)} fill={props.fillColors[915]} d="M210.4,1371.6c0,0-0.01-0.01-0.01-0.01C210.39,1371.59,210.4,1371.6,210.4,1371.6z"/>
            <path onClick={() => props.onFill(916)} fill={props.fillColors[916]} d="M865.75,1371.58c-0.32-0.3-0.64-0.61-0.96-0.91c0,0,0.06-0.06,0.06-0.06c0.32,0.31,0.63,0.61,0.95,0.92
                C865.8,1371.53,865.75,1371.58,865.75,1371.58z"/>
            <path onClick={() => props.onFill(917)} fill={props.fillColors[917]} d="M273.33,560.18c0.32-0.33,0.64-0.67,0.96-1c0,0,0.04,0.04,0.04,0.04c-0.32,0.33-0.64,0.66-0.97,0.99
                C273.37,560.21,273.33,560.18,273.33,560.18z"/>
            <path onClick={() => props.onFill(918)} fill={props.fillColors[918]} d="M276.34,558.17c0.31-0.33,0.62-0.66,0.93-0.99c0,0,0.05,0.04,0.05,0.04c-0.31,0.33-0.63,0.66-0.94,0.99
                C276.38,558.21,276.34,558.17,276.34,558.17z"/>
            <path onClick={() => props.onFill(919)} fill={props.fillColors[919]} d="M703.98,196.51c0.31,0.3,0.62,0.59,0.94,0.89c0,0-0.06,0.06-0.06,0.06c-0.31-0.3-0.63-0.59-0.94-0.88
                C703.92,196.57,703.98,196.51,703.98,196.51z"/>
            <path onClick={() => props.onFill(920)} fill={props.fillColors[920]} d="M279.33,556.18c0.33-0.34,0.66-0.68,0.99-1.02l0.04,0.04c-0.33,0.34-0.67,0.67-1,1.01
                C279.36,556.21,279.33,556.18,279.33,556.18z"/>
            <path onClick={() => props.onFill(921)} fill={props.fillColors[921]} d="M280.32,555.15c0.32-0.32,0.65-0.65,0.97-0.97c0,0,0.03,0.03,0.03,0.03c-0.32,0.33-0.64,0.66-0.97,0.98
                C280.36,555.2,280.32,555.15,280.32,555.15z"/>
            <path onClick={() => props.onFill(922)} fill={props.fillColors[922]} d="M867.76,1374.6c-0.33-0.32-0.66-0.64-0.99-0.96c0,0,0.04-0.04,0.04-0.04c0.33,0.32,0.66,0.64,0.99,0.97
                C867.79,1374.56,867.76,1374.6,867.76,1374.6z"/>
            <path onClick={() => props.onFill(923)} fill={props.fillColors[923]} d="M123.47,451.28c0,0,0.01-0.01,0.01-0.01C123.48,451.27,123.47,451.28,123.47,451.28z"/>
            <path onClick={() => props.onFill(924)} fill={props.fillColors[924]} d="M1081.56,1375.53c0.33-0.31,0.67-0.63,1-0.94c0,0,0.02,0.03,0.02,0.03c-0.33,0.32-0.65,0.64-0.98,0.97
                C1081.61,1375.58,1081.56,1375.53,1081.56,1375.53z"/>
            <path onClick={() => props.onFill(925)} fill={props.fillColors[925]} d="M701.91,193.47c0.34,0.29,0.67,0.59,1.01,0.88c0,0-0.06,0.06-0.06,0.06c-0.3-0.32-0.61-0.64-0.91-0.96
                L701.91,193.47z"/>
            <path onClick={() => props.onFill(926)} fill={props.fillColors[926]} d="M840.79,1375.58c0,0,0.02,0.02,0.02,0.02C840.81,1375.6,840.79,1375.58,840.79,1375.58z"/>
            <path onClick={() => props.onFill(927)} fill={props.fillColors[927]} d="M844.79,1376.59c0,0,0.01,0.01,0.01,0.01S844.79,1376.59,844.79,1376.59z"/>
            <path onClick={() => props.onFill(928)} fill={props.fillColors[928]} d="M284.32,552.2c-0.33,0.34-0.66,0.67-0.99,1.01c0,0-0.01,0-0.01,0c0.33-0.33,0.67-0.66,1-0.99
                C284.33,552.21,284.32,552.2,284.32,552.2z"/>
            <polygon onClick={() => props.onFill(929)} fill={props.fillColors[929]} points="852.71,1377.63 852.81,1377.57 852.78,1377.7 		"/>
            <path onClick={() => props.onFill(930)} fill={props.fillColors[930]} d="M117.49,456.27C117.49,456.27,117.48,456.28,117.49,456.27C117.48,456.28,117.49,456.27,117.49,456.27z"/>
            <path onClick={() => props.onFill(931)} fill={props.fillColors[931]} d="M852.78,1377.7c-0.31,0.3-0.61,0.61-0.92,0.91c0,0-0.05-0.04-0.05-0.04c0.3-0.31,0.6-0.62,0.9-0.93
                C852.71,1377.63,852.78,1377.7,852.78,1377.7z"/>
            <polygon onClick={() => props.onFill(932)} fill={props.fillColors[932]} points="1080.59,1377.58 1080.46,1377.7 1080.58,1377.61 		"/>
            <polygon onClick={() => props.onFill(933)} fill={props.fillColors[933]} points="701.95,193.45 701.79,193.3 701.91,193.47 		"/>
            <path onClick={() => props.onFill(934)} fill={props.fillColors[934]} d="M285.32,551.19c-0.33,0.34-0.67,0.67-1,1.01c0,0,0.01,0.01,0.01,0.01c0.34-0.33,0.68-0.67,1.01-1
                C285.34,551.21,285.32,551.19,285.32,551.19z"/>
            <path onClick={() => props.onFill(935)} fill={props.fillColors[935]} d="M858.79,1380.58C858.79,1380.58,858.79,1380.57,858.79,1380.58
                C858.79,1380.57,858.79,1380.58,858.79,1380.58z"/>
            <polygon onClick={() => props.onFill(936)} fill={props.fillColors[936]} points="699.92,190.47 700.07,190.67 699.95,190.46 		"/>
            <path onClick={() => props.onFill(937)} fill={props.fillColors[937]} d="M807.82,1381.57c0,0,0.02,0.02,0.02,0.02C807.85,1381.6,807.82,1381.57,807.82,1381.57z"/>
            <path onClick={() => props.onFill(938)} fill={props.fillColors[938]} d="M852.78,1381.58c0,0,0.02,0.02,0.02,0.02C852.8,1381.6,852.78,1381.58,852.78,1381.58z"/>
            <polygon onClick={() => props.onFill(939)} fill={props.fillColors[939]} points="204.41,1382.58 204.27,1382.7 204.41,1382.6 		"/>
            <polygon onClick={() => props.onFill(940)} fill={props.fillColors[940]} points="699.95,190.46 699.8,190.3 699.92,190.47 		"/>
            <path onClick={() => props.onFill(941)} fill={props.fillColors[941]} d="M812.82,1385.58C812.82,1385.58,812.83,1385.59,812.82,1385.58
                C812.83,1385.59,812.82,1385.58,812.82,1385.58z"/>
            <path onClick={() => props.onFill(942)} fill={props.fillColors[942]} d="M817.83,1384.59c0,0-0.01-0.01-0.01-0.01C817.82,1384.58,817.83,1384.59,817.83,1384.59z"/>
            <path onClick={() => props.onFill(943)} fill={props.fillColors[943]} d="M821.83,1386.58c0,0-0.01-0.01-0.01-0.01S821.83,1386.58,821.83,1386.58z"/>
            <path onClick={() => props.onFill(944)} fill={props.fillColors[944]} d="M196.41,1387.59C196.41,1387.59,196.4,1387.58,196.41,1387.59
                C196.4,1387.58,196.41,1387.59,196.41,1387.59z"/>
            <path onClick={() => props.onFill(945)} fill={props.fillColors[945]} d="M1100.57,1399.59c0,0-0.02-0.02-0.02-0.02C1100.55,1399.57,1100.57,1399.59,1100.57,1399.59z"/>
            <path onClick={() => props.onFill(946)} fill={props.fillColors[946]} d="M357.25,544.21c0.33,0,0.67,0,1,0c0,0,0.01-0.01,0.01-0.01c-0.34-0.01-0.69-0.01-1.03-0.02
                C357.23,544.18,357.25,544.21,357.25,544.21z"/>
            <polygon onClick={() => props.onFill(947)} fill={props.fillColors[947]} points="94.52,471.25 94.42,471.04 94.49,471.26 		"/>
            <polygon onClick={() => props.onFill(948)} fill={props.fillColors[948]} points="667.97,471.26 667.83,471.12 667.94,471.27 		"/>
            <polygon onClick={() => props.onFill(949)} fill={props.fillColors[949]} points="696.92,185.48 697.08,185.66 696.95,185.46 		"/>
            <polygon onClick={() => props.onFill(950)} fill={props.fillColors[950]} points="696.95,185.46 696.8,185.31 696.92,185.48 		"/>
            <polygon onClick={() => props.onFill(951)} fill={props.fillColors[951]} points="94.49,471.26 94.63,471.41 94.52,471.25 		"/>
            <path onClick={() => props.onFill(952)} fill={props.fillColors[952]} d="M1019.62,1430.54c0,0,0.02,0.02,0.02,0.02S1019.62,1430.54,1019.62,1430.54z"/>
            <path onClick={() => props.onFill(953)} fill={props.fillColors[953]} d="M976.67,181.47C976.67,181.47,976.68,181.48,976.67,181.47C976.68,181.48,976.67,181.47,976.67,181.47z"/>
            <polygon onClick={() => props.onFill(954)} fill={props.fillColors[954]} points="977.56,1437.57 977.69,1437.54 977.63,1437.64 		"/>
            <path onClick={() => props.onFill(955)} fill={props.fillColors[955]} d="M976.62,1438.57c0.32-0.33,0.63-0.67,0.95-1c0,0,0.07,0.07,0.07,0.07c-0.32,0.33-0.64,0.66-0.96,0.98
                C976.67,1438.63,976.62,1438.57,976.62,1438.57z"/>
            <path onClick={() => props.onFill(956)} fill={props.fillColors[956]} d="M976.67,1438.63c-0.32,0.31-0.63,0.62-0.95,0.93c0,0-0.04-0.04-0.04-0.04c0.31-0.32,0.62-0.64,0.93-0.96
                C976.62,1438.57,976.67,1438.63,976.67,1438.63z"/>
            <polygon onClick={() => props.onFill(957)} fill={props.fillColors[957]} points="669.96,472.25 670.09,472.37 669.98,472.24 		"/>
            <path onClick={() => props.onFill(958)} fill={props.fillColors[958]} d="M971.69,1442.57c0.34-0.34,0.67-0.68,1.01-1.02c0,0-0.03-0.03-0.03-0.03c-0.34,0.34-0.68,0.67-1.02,1.01
                C971.66,1442.53,971.69,1442.57,971.69,1442.57z"/>
            <path onClick={() => props.onFill(959)} fill={props.fillColors[959]} d="M251.38,530.2c0,0-0.04,0.04-0.04,0.04C251.34,530.23,251.38,530.2,251.38,530.2z"/>
            <polygon onClick={() => props.onFill(960)} fill={props.fillColors[960]} points="96.51,474.4 96.52,474.24 96.58,474.33 		"/>
            <path onClick={() => props.onFill(961)} fill={props.fillColors[961]} d="M672.96,474.26L672.96,474.26L672.96,474.26z"/>
            <path onClick={() => props.onFill(962)} fill={props.fillColors[962]} d="M97.51,475.37c-0.33-0.33-0.67-0.65-1-0.98c0,0,0.07-0.07,0.07-0.07c0.33,0.33,0.66,0.65,0.99,0.98
                C97.57,475.31,97.51,475.37,97.51,475.37z"/>
            <path onClick={() => props.onFill(963)} fill={props.fillColors[963]} d="M672.96,474.26C672.96,474.26,672.96,474.26,672.96,474.26C672.96,474.26,672.96,474.26,672.96,474.26z"/>
            <path onClick={() => props.onFill(964)} fill={props.fillColors[964]} d="M1009.63,81.56c0,0,0.02-0.02,0.02-0.02C1009.65,81.54,1009.63,81.56,1009.63,81.56z"/>
            <path onClick={() => props.onFill(965)} fill={props.fillColors[965]} d="M97.57,475.31c0.3,0.3,0.59,0.61,0.89,0.91c0,0-0.06,0.05-0.06,0.05c-0.3-0.3-0.6-0.6-0.9-0.9
                C97.51,475.37,97.57,475.31,97.57,475.31z"/>
            <path onClick={() => props.onFill(966)} fill={props.fillColors[966]} d="M100.6,477.24c0.32,0.34,0.64,0.68,0.96,1.02c0,0-0.05,0.05-0.05,0.05c-0.32-0.34-0.64-0.68-0.96-1.01
                C100.54,477.29,100.6,477.24,100.6,477.24z"/>
            <path onClick={() => props.onFill(967)} fill={props.fillColors[967]} d="M102.42,479.27c-0.3-0.32-0.61-0.65-0.91-0.97c0,0,0.05-0.05,0.05-0.05c0.3,0.32,0.61,0.65,0.91,0.97
                C102.47,479.22,102.42,479.27,102.42,479.27z"/>
            <path onClick={() => props.onFill(968)} fill={props.fillColors[968]} d="M188.41,1467.52c0.34-0.33,0.67-0.66,1.01-0.99c0,0-0.01-0.01-0.01-0.01c-0.33,0.34-0.66,0.67-0.99,1.01
                C188.42,1467.53,188.41,1467.52,188.41,1467.52z"/>
            <path onClick={() => props.onFill(969)} fill={props.fillColors[969]} d="M106.49,478.24c0,0,0,0.01,0,0.01C106.49,478.25,106.49,478.24,106.49,478.24z"/>
            <path onClick={() => props.onFill(970)} fill={props.fillColors[970]} d="M22.55,484.27c0,0,0.05-0.04,0.05-0.04S22.55,484.27,22.55,484.27z"/>
            <path onClick={() => props.onFill(971)} fill={props.fillColors[971]} d="M833.82,64.55c0,0-0.03,0.03-0.03,0.03S833.82,64.55,833.82,64.55z"/>
            <path onClick={() => props.onFill(972)} fill={props.fillColors[972]} d="M250.36,1473.51c0,0-0.01,0.01-0.01,0.01C250.35,1473.52,250.36,1473.51,250.36,1473.51z"/>
            <path onClick={() => props.onFill(973)} fill={props.fillColors[973]} d="M1055.6,1477.52L1055.6,1477.52L1055.6,1477.52z"/>
            <path onClick={() => props.onFill(974)} fill={props.fillColors[974]} d="M949.71,504.23c0,0-0.02,0.02-0.02,0.02C949.69,504.25,949.71,504.23,949.71,504.23z"/>
            <path onClick={() => props.onFill(975)} fill={props.fillColors[975]} d="M745.88,1482.52c0,0,0.02-0.02,0.02-0.02C745.9,1482.5,745.88,1482.52,745.88,1482.52z"/>
            <path onClick={() => props.onFill(976)} fill={props.fillColors[976]} d="M251.37,1493.52c0,0-0.02-0.02-0.02-0.02S251.37,1493.52,251.37,1493.52z"/>
            <path d="M537.9,1319.96c47.28,6.36,94.12,5.75,138.77,20.1c0.66,0.21,1.14,1.01,2.87,2.63c-5.33,0.81-9.42,1.83-13.54,1.98
                c-20.77,0.78-41.56,1.96-62.33,1.81c-22.61-0.16-45.19-1.95-67.8-2.45c-33.27-0.73-66.56-0.61-99.83-1.49
                c-29.74-0.79-59.6,0.05-89.11-4.73c-7.64-1.24-15.07-3.84-22.52-6.06c-1.61-0.48-2.91-2.01-5.48-3.87
                c3.32-1.42,5.55-2.94,7.96-3.31c10.16-1.6,20.33-3.57,30.56-4.06c14.45-0.69,28.96-0.18,43.44-0.19c7.46,0,14.93,0,22.45-0.78
                c-14.43-3.46-29.52-3.48-43.27-10.24c23.72-3.25,47.95-0.49,71.23-7.44c-17.91-5.99-37.37-4.07-55.05-10.68
                c-0.03-0.6-0.05-1.21-0.08-1.81c4.22-0.62,8.42-1.54,12.66-1.8c18.09-1.15,36.23-1.65,54.28-3.27c14.05-1.26,27.98-3.8,41.95-5.86
                c1.74-0.26,3.41-1.03,5.22-2.74c-4.06-0.92-8.08-2.25-12.2-2.69c-23.47-2.51-46.96-4.82-70.44-7.18
                c-16.2-1.63-32.43-3.08-48.6-4.96c-3.76-0.44-7.36-2.33-11.03-3.56c0.03-0.73,0.06-1.46,0.09-2.19c4.88-0.86,9.72-2.13,14.64-2.5
                c25.2-1.87,50.42-3.53,75.63-5.23c14.92-1.01,29.85-1.92,44.76-2.98c2.05-0.15,4.06-0.92,7.12-1.65
                c-3.19-1.88-5.38-3.17-7.57-4.46c-0.02-0.55-0.05-1.1-0.07-1.65c4.65-0.99,9.29-1.98,14.66-3.13c-9.09-2.78-18.16-4.22-25.24-11.1
                c3.48-4.06,7.54-3.92,11.69-3.56c10.1,0.87,20.2,2.33,30.31,2.38c7.99,0.04,15.98-2.46,23.97-2.45
                c16.28,0.02,32.56,0.94,48.83,1.6c1.67,0.07,3.3,0.89,5.31,1.46c-2.47,3.05-4.47,5.52-6.12,7.57c5.13,1.56,11.48,3.49,17.82,5.41
                c-0.14,0.68-0.27,1.37-0.41,2.05c-14.79,3.53-30.06,1.1-45.06,3.59c12.48,1.32,24.97,2.59,37.45,3.97
                c10.79,1.19,21.44,3.05,31.18,8.21c2.97,1.57,5.71,3.58,8.55,5.39c-0.15,0.5-0.3,1-0.45,1.5c-3.84,0.37-7.68,1.06-11.52,1.05
                c-23.62-0.08-47.25-0.57-70.87-0.38c-9.74,0.08-19.46,1.51-29.3,2.94c17.32,6.68,35.81,10.13,51.69,20.03
                c-0.08,0.43-0.15,0.85-0.23,1.28c-13.3,0-26.61,0-39.91,0c28.01,8.45,57.74,9.48,84.94,20.54c-0.05,0.92-0.1,1.84-0.15,2.76
                C606.28,1311.75,572.82,1315.77,537.9,1319.96z M496.67,1221.36c-0.1,0.65-0.19,1.31-0.29,1.96c8.96,5.8,19.78,6.73,29.84,9.66
                c-0.06,0.52-0.12,1.05-0.18,1.57c-5.97,1.1-11.93,2.2-18.47,3.41c2.68,1.86,4.58,3.17,6.48,4.49c-0.08,0.48-0.16,0.95-0.24,1.43
                c-3.96,1.04-7.88,2.69-11.9,3.02c-15.07,1.2-30.18,1.91-45.27,2.92c-25.22,1.7-50.43,3.47-75.64,5.27
                c-2.34,0.17-4.65,0.77-6.97,1.17c2.74,1.83,5.42,2.79,8.17,3.08c13.55,1.44,27.12,2.71,40.69,4.02
                c24.81,2.39,49.65,4.58,74.43,7.25c6.22,0.67,12.28,2.85,18.41,4.33c-0.03,0.61-0.05,1.21-0.08,1.82
                c-1.89,0.86-3.71,2.18-5.69,2.5c-16.55,2.65-33.08,5.66-49.73,7.47c-14.19,1.54-28.55,1.57-42.83,2.39
                c-4.59,0.26-9.15,0.92-13.73,1.4c17.68,5.1,36.5,3.63,53.67,11.18c-22.46,9.34-46.19,7.21-69.62,9.26
                c15.22,2.34,30.44,4.68,45.66,7.02c0,0.67-0.01,1.33-0.01,2c-3.97,0.65-7.93,1.76-11.92,1.86c-18.63,0.46-37.26,0.56-55.89,0.96
                c-8.47,0.18-16.97,0.43-25.39,1.3c-5.45,0.56-10.78,2.31-16.16,3.53c-0.02,0.61-0.05,1.23-0.07,1.84c3.84,1.31,7.6,3.32,11.55,3.8
                c18.63,2.25,37.28,5.18,55.99,5.82c41.24,1.39,82.52,1.5,123.78,2.23c17.13,0.3,34.26,0.77,51.39,1.3
                c14.29,0.44,28.58,1.47,42.87,1.5c13.29,0.03,26.58-0.91,39.88-1.42c7.42-0.29,14.83-0.59,22.24-0.88
                c0.06-0.44,0.11-0.88,0.17-1.33c-2.64-0.82-5.22-2.01-7.92-2.39c-27.49-3.82-54.97-7.68-82.5-11.18
                c-17.31-2.2-34.7-3.79-52.05-5.69c-1.53-0.17-3.02-0.65-4.54-0.98c0.02-0.49,0.04-0.98,0.05-1.47c36.4-4.2,72.79-8.4,109.19-12.61
                c-28.28-10.46-58.49-12.15-86.98-21.47c13.37-4.15,27.07-1.27,40.49-2.44c0.1-0.49,0.21-0.97,0.31-1.46
                c-17.01-5.64-34.01-11.28-51.02-16.92c0.06-0.64,0.12-1.27,0.17-1.91c3.62-0.84,7.19-2.1,10.85-2.45
                c31.87-3.05,63.79-2.61,95.73-1.43c1.78,0.07,3.58-0.49,5.37-0.76c0.07-0.71,0.14-1.42,0.21-2.13c-8.39-2.41-16.64-5.68-25.2-7.04
                c-16.57-2.64-33.31-4.16-49.97-6.25c-1.71-0.21-3.34-1.08-5.01-1.64c0.07-0.74,0.15-1.48,0.22-2.22
                c7.85-0.57,15.7-1.36,23.56-1.64c7.85-0.28,15.72-0.06,23.59-0.06c0.02-0.56,0.05-1.12,0.07-1.69c-6.06-1.43-12.13-2.86-18.66-4.4
                c3.2-2.69,5.48-4.61,7.76-6.53c-0.32-0.64-0.64-1.28-0.95-1.92c-17.6,0-35.2,0-52.03,0c0.26,0.16,1.39,0.85,2.52,1.54
                c-0.09,0.55-0.17,1.1-0.26,1.64C535.45,1223.8,516.06,1222.58,496.67,1221.36z"/>
            <path d="M1008.45,1369.7c-0.56,1.03-1.03,2.12-1.7,3.07c-16.05,22.93-37.07,37.35-65.58,40.79c-20.6,2.48-41.05,6.48-61.44,10.44
                c-12.12,2.36-23.86,1.52-35.5-1.74c-14.28-3.99-28.62-6-43.37-3.37c-1.46,0.26-2.9,0.58-5.44,1.09
                c11.94,2.92,23.46,3.98,33.4,11.69c-24.04,5.01-47.22,9.83-70.91,14.77c29.53,8.4,57.79,20.83,89.06,18.68
                c15.92-1.1,31.78-2.98,47.68-4.35c7.81-0.67,15.4,0.21,23.02,4.24c-1.08,0.99-1.56,1.78-2.22,1.98
                c-11.96,3.5-23.77,7.8-35.96,10.08c-9.7,1.81-19.94,0.53-29.82,1.69c-14.34,1.7-28.67,3.9-42.83,6.75
                c-14.93,3.01-29.35,2.43-43.69-2.72c-6.8-2.44-12.79-6.07-16.73-12.04c-7.13-10.8-16.68-18.36-28.36-23.6
                c-3.72-1.67-7.14-4.01-11.77-6.67c2.63-1.52,4.11-2.77,5.83-3.32c21.67-6.99,43.38-13.86,65.07-20.79
                c4.27-1.37,8.67-2.49,12.74-4.33c32.36-14.57,66.77-21.54,101.56-26.22c14.51-1.95,29.54,0.09,44.33,0.08
                c21.61-0.02,42.88-2,62.63-11.88c1.63-0.82,3.22-1.75,4.78-2.69c1.36-0.82,2.66-1.74,3.99-2.61
                C1007.64,1369.03,1008.04,1369.37,1008.45,1369.7z M1001.65,1375.6c-5.82,2.45-11.51,5.28-17.49,7.27
                c-24.59,8.18-49.96,6.25-75.23,5.46c-4.31-0.13-8.7-0.4-12.93,0.22c-13.15,1.94-26.45,3.45-39.32,6.64
                c-20.46,5.09-40.64,11.3-60.87,17.26c-6.04,1.78-11.76,4.62-17.78,6.48c-17.64,5.46-35.37,10.6-53.02,16.03
                c-4.35,1.34-8.51,3.31-13.91,5.45c3.34,1.86,5.39,3.21,7.61,4.2c12.12,5.4,22.16,13.16,29.87,24.18
                c2.65,3.78,6.81,7.11,11.01,9.07c12.13,5.67,25.11,8.16,38.42,5.71c26.52-4.87,53.01-9.07,80.12-9.73
                c10.13-0.25,20.15-5.14,30.22-7.91c-0.08-0.44-0.17-0.88-0.25-1.31c-2.63-0.26-5.28-0.87-7.88-0.71
                c-10.95,0.7-21.92,1.24-32.8,2.56c-19.27,2.34-38.46,3.62-57.45-1.67c-19.54-5.44-39.04-11.03-58.55-16.6
                c-0.84-0.24-1.55-0.97-3.54-2.27c12.44-4.95,24.74-4.74,36.31-7.68c11.64-2.96,23.31-5.78,34.97-8.66
                c0.07-0.26,0.13-0.53,0.2-0.79c-11.13-2.53-22.25-5.07-33.38-7.6c1.21-1.73,2.29-2.49,3.49-2.82c14.82-3.98,29.95-6.05,44.83-1.54
                c19.5,5.91,38.73,6.18,58.56,1.82c16.54-3.64,33.33-6.37,50.13-8.49c18.83-2.37,34.54-10.46,48.07-23.29
                c3.73-3.54,7.06-7.51,10.57-11.28l0.11-0.11L1001.65,1375.6z"/>
            <path d="M944.36,1424.89c-6.74,0.44-11.9,0.77-17.05,1.11c-0.13-0.6-0.26-1.2-0.4-1.8c1.96-0.93,3.84-2.33,5.9-2.71
                c11.9-2.19,23.8-4.52,35.8-6.09c17.77-2.33,33.57-8.85,45.91-21.9c9.79-10.35,18.51-21.72,27.7-32.63
                c0.71-0.84,1.41-1.69,3.15-2.07c-0.72,1.82-1.21,3.78-2.19,5.44c-4.08,6.86-8.27,13.66-12.5,20.44
                c-7.41,11.86-17.28,20.83-30.11,26.8c-12.2,5.68-24.14,11.95-35.97,18.39c-11.29,6.14-23.32,9.22-35.92,11.32
                c-16.53,2.77-32.86,6.72-49.28,10.14c-2.5,0.52-5.04,0.86-7.57,1.29c-0.21-0.45-0.42-0.91-0.64-1.36c2.5-1.84,5.01-3.68,7.51-5.52
                c-18.54-0.55-36.25-10.15-55.4-4.43c6.3-5.96,13.36-10.29,21.92-9.92c12.44,0.54,24.93,1.36,37.22,3.26
                c17.59,2.72,34.41,1.27,50.77-5.79C936.38,1427.46,939.74,1426.51,944.36,1424.89z M1028.59,1382.67c0,0-0.06-0.06-0.06-0.06
                c-0.31,0.29-0.61,0.59-0.92,0.88c-0.65,1.03-1.31,2.07-1.96,3.1c-0.34,0.34-0.67,0.68-1.01,1.01
                c-6.32,5.66-12.65,11.32-18.97,16.97c-0.34,0.34-0.67,0.67-1.01,1.01c-0.69,0.32-1.37,0.63-2.06,0.95
                c-0.65,0.34-1.29,0.68-1.94,1.02l-0.12,0.11c0,0,0.1-0.12,0.1-0.12c0.66-0.33,1.33-0.66,1.99-0.98c0.66-0.34,1.33-0.68,1.99-1.01
                c0.34-0.34,0.67-0.67,1.01-1.01c8.36-3.38,14.29-9.48,18.97-16.98c0.34-0.34,0.67-0.67,1.01-1.01c0.68-1.01,1.36-2.01,2.04-3.02
                C1027.97,1383.25,1028.28,1382.96,1028.59,1382.67z M990.63,1412.57l0.24-0.16c0,0-0.28,0.11-0.28,0.11
                c-0.63,0.35-1.27,0.7-1.9,1.04c-1.01,0.34-2.01,0.67-3.02,1.01c-14.62,2.5-29.24,5-43.76,7.49c5.17,0,10.41,0,15.66,0
                c0.08,0.44,0.16,0.88,0.24,1.33c-1.45,0.62-2.86,1.34-4.35,1.85c-9.44,3.21-18.84,6.57-28.36,9.5
                c-11.55,3.55-23.33,5.02-35.47,3.49c-12.86-1.63-25.76-2.97-38.67-4.19c-5.71-0.54-11.47-0.46-16.29,2.46
                c14.9,1.93,30.03,3.86,45.14,5.89c3.45,0.46,4.36,2.19,0.86,5.6c4.06-1.04,7.06-1.96,10.13-2.57c13.53-2.67,26.98-5.95,40.64-7.7
                c20.41-2.62,36.85-13.82,54.23-23.16c1.02-0.32,2.05-0.63,3.07-0.95C989.35,1413.25,989.99,1412.91,990.63,1412.57z"/>
            <path d="M947.34,1350.33c3.39,1.13,6.77,2.25,11.31,3.77c-2.41,1.74-3.77,3.31-5.46,3.85c-5.68,1.79-11.42,3.45-17.25,4.69
                c-4.32,0.92-7.77-1.09-10.2-4.76c-2.49-3.77-0.76-7.01,1.35-10.13c3.57-5.31,8.93-7.88,14.92-9.2c4.05-0.89,8.17-1.78,12.3-2
                c10.76-0.56,19.77-5.15,26.92-12.63c10.39-10.86,22.23-18.74,36.72-23.05c8.01-2.38,14.79-7.53,18.14-15.44
                c5.1-12.05,9.53-24.4,14.04-36.69c2.17-5.92,3.84-12.03,5.84-18.02c0.49-1.47,1.43-2.8,3.21-3.83c-0.21,1.15-0.38,2.31-0.63,3.45
                c-2.84,12.62-5.72,25.23-8.53,37.86c-0.47,2.1-0.73,4.26-1.01,6.4c-2.47,19-12.67,31.14-31.28,36.54
                c-10.51,3.05-19.87,8.52-27.29,16.84c-9.83,11-21.84,17.74-36.71,19.14c-2.2,0.21-4.33,1.25-6.49,1.91
                C947.28,1349.46,947.31,1349.9,947.34,1350.33z M1046.63,1270.7c-1.16,2.39-2.47,4.72-3.47,7.18
                c-5.79,14.31-15.62,23.9-30.84,28.1c-9.78,2.7-18.05,8.41-24.97,15.9c-10.13,10.97-22.23,17.5-37.5,18.3
                c-7.78,0.41-15.65,2.15-20.73,9.24c-3.45,4.82-1.3,10.72,4.35,10.5c6.21-0.23,12.35-2.14,18.53-3.32
                c-0.12-0.45-0.24-0.89-0.36-1.34c-2.97-1.72-5.94-3.43-8.92-5.15c0.07-0.64,0.14-1.29,0.21-1.93c3.67-1.25,7.26-3.21,11.02-3.62
                c13.79-1.49,25.1-7.59,34.19-17.74c8.63-9.64,19.43-15.52,31.48-19.37c14.34-4.59,23.12-13.9,25.55-28.93
                c0.42-2.62,0.94-5.23,1.42-7.85c0.11-0.27,0.22-0.54,0.2-0.5C1046.82,1270.14,1046.73,1270.42,1046.63,1270.7z"/>
            <path d="M1087.76,1478.33c-1.45,0-2.91,0-4.36,0c-0.01-0.18-0.02-0.37-0.04-0.55c1.46-0.07,2.92-0.15,4.38-0.22
                C1087.75,1477.82,1087.76,1478.08,1087.76,1478.33z"/>
            <path d="M733.66,622.77c-0.81-6.9-1.62-13.8-2.43-20.69c0.4-0.11,0.8-0.21,1.2-0.32c0.18,0.4,0.42,0.78,0.54,1.2
                c0.13,0.47,0.15,0.98,0.39,2.75c1.03-6.04,1.86-10.87,2.68-15.69c0.38,0.05,0.76,0.1,1.14,0.16c-0.78,10.78-1.55,21.56-2.33,32.34
                C734.45,622.6,734.06,622.68,733.66,622.77z"/>
            <path d="M723.34,602.47c1.56,9.21,3.65,18.6,1.47,28.17c-0.49,0.02-0.98,0.05-1.47,0.07C723.34,621.3,723.34,611.89,723.34,602.47
                z"/>
            <path d="M796.3,590.78c2.26,6.72,1.9,13.28-2.07,19.48C794.92,603.77,795.61,597.27,796.3,590.78z"/>
            <path d="M644.24,543.29c2.66,4.64,5.32,9.29,7.97,13.93c-0.52,0.3-1.05,0.6-1.57,0.9c-2.61-4.66-5.22-9.33-7.83-13.99
                C643.29,543.85,643.77,543.57,644.24,543.29z"/>
            <path d="M792.22,594.37c0,3.87,0,7.74,0,11.61c-0.36-0.01-0.71-0.01-1.07-0.02c0-3.87,0-7.74,0-11.61
                C791.51,594.36,791.86,594.37,792.22,594.37z"/>
            <path d="M526.95,551.67c-0.4,3.97-0.8,7.94-1.19,11.91c-0.62-0.06-1.23-0.12-1.85-0.18c0.5-3.94,1-7.88,1.5-11.82
                C525.92,551.6,526.43,551.63,526.95,551.67z"/>
            <path d="M631.3,541.07c-3.85-1.65-7.71-3.3-11.56-4.96c0.17-0.44,0.33-0.87,0.5-1.31c3.91,1.53,7.81,3.05,11.72,4.58
                C631.74,539.95,631.52,540.51,631.3,541.07z"/>
            <path d="M654.9,563.88c-0.91-4.06-1.81-8.12-2.72-12.18c0.4-0.11,0.81-0.21,1.21-0.32c1.06,4.02,2.12,8.05,3.18,12.07
                C656.01,563.6,655.46,563.74,654.9,563.88z"/>
            <path d="M570.4,510.32c-0.64,4.45-1.18,8.19-1.82,12.66C565.89,518.24,566.34,515.43,570.4,510.32z"/>
            <path d="M565.08,509.25c0,2.66,0,5.32,0,7.98c-0.65-0.06-1.3-0.13-1.95-0.19c-0.58-2.76-0.81-5.47,1.92-7.83L565.08,509.25z"/>
            <path d="M522.86,555.87c0,1.83,0,3.66,0,5.48c-0.28,0-0.56,0.01-0.84,0.01c0-1.83,0-3.65,0-5.48
                C522.3,555.88,522.58,555.87,522.86,555.87z"/>
            <path d="M559.18,518.08c-0.4-1.82-0.81-3.64-1.21-5.45c0.42-0.1,0.84-0.19,1.26-0.29c0.43,1.81,0.86,3.62,1.3,5.44
                C560.08,517.88,559.63,517.98,559.18,518.08z"/>
            <path d="M639.98,545.22c0.76,0.86,1.63,1.65,2.24,2.6c0.61,0.94,0.97,2.05,0.53,3.56c-0.91-2.06-1.83-4.13-2.74-6.19
                L639.98,545.22z"/>
            <path d="M560.51,510.63c0.1-0.11,0.2-0.22,0.29-0.33c0.19,0.17,0.38,0.33,0.55,0.52c0.01,0.01-0.18,0.21-0.28,0.32
                C560.88,510.97,560.7,510.8,560.51,510.63z"/>
            <path d="M640.01,545.2c-0.33-0.34-0.67-0.67-1-1.01l-0.04,0.04c0.34,0.33,0.67,0.67,1.01,1
                C639.98,545.22,640.01,545.2,640.01,545.2z"/>
            <path d="M639.01,544.19c0,0-0.04,0.04-0.04,0.04C638.97,544.22,639.01,544.19,639.01,544.19z"/>
            <path d="M565.04,509.21c0,0,0.04,0.04,0.04,0.04C565.08,509.25,565.04,509.21,565.04,509.21z"/>
            <path d="M316.06,1227.66c0,6.37,0,12.73,0,19.1c0.4,0.03,0.8,0.06,1.19,0.09c0.53-3.73,1.06-7.47,1.59-11.2
                c0.41,0.08,0.82,0.15,1.22,0.23c0.57,7.6-1.07,14.84-3.99,22.66c-6.54-10.8,0.27-20.85-2.15-30.82
                C314.64,1227.7,315.35,1227.68,316.06,1227.66z"/>
            <path d="M249.53,1270.92c-4.21-6.16-5.27-40.51,0-52.9C249.53,1235.81,249.53,1253.59,249.53,1270.92z"/>
            <path d="M327.11,1088.62c-1.28-0.6-2.55-1.2-3.83-1.8c0,0,0.03-0.02,0.03-0.02c-3.33,1-6.67,2-10,3c0,0,0.03,0.04,0.03,0.04
                c-0.01-0.67-0.03-1.34-0.04-2.01c0,0,0.01-0.02,0.01-0.02c2-1.67,3.83-3.66,6.08-4.87c1.28-0.69,3.24-0.12,4.9-0.12
                c3.04-3.07,3.93,0.77,5.71,1.77c0.24,0.16,0.48,0.32,0.72,0.49l0.18-0.01c0.22-0.16,0.45-0.32,0.68-0.48
                c5.65,1.46,6.15,2.31,4.21,5.82c-2.95-0.53-5.6-1-8.25-1.47C327.38,1088.83,327.24,1088.72,327.11,1088.62z M328.28,1085.8l0-0.01
                c-0.3-0.33-0.59-0.66-0.89-0.99l-0.15,0c0,0,0.09,0.06,0.09,0.06C327.65,1085.17,327.97,1085.49,328.28,1085.8z"/>
            <path d="M199.23,1290.91c-1.74,14.06-3.48,28.11-5.22,42.17c-2.72-14.57,1.31-28.36,3.85-42.32
                C198.32,1290.81,198.78,1290.86,199.23,1290.91z"/>
            <path d="M241.65,1225.83c1.42,14.79,2.78,29.04,4.17,43.49C241.71,1264.59,238.89,1235.57,241.65,1225.83z"/>
            <path d="M290.34,1200.72c0.61-0.29,1.49-0.99,1.8-0.79c3.09,1.94,4.89,8.55,3.32,13.63c-2.25-3.97-4.2-7.39-6.14-10.82
                c0,0,0.02-0.02,0.02-0.02c-0.77,2.38-1.54,4.75-2.31,7.13c-2.41-8.04-1.47-9.89,3.29-9.1c0.33,0.33,0.65,0.65,0.98,0.98l0.15,0.23
                c0,0-0.11-0.27-0.11-0.27C291,1201.35,290.67,1201.04,290.34,1200.72z"/>
            <path d="M327.52,1088.92c1,2.23,2.01,4.47,3.01,6.7c-1.71,0.76-3.78,2.49-5.06,2.04c-1.83-0.65-4.01-2.7-4.42-4.52
                c-1.15-5.12,3.17-4.1,6.05-4.54C327.24,1088.72,327.38,1088.83,327.52,1088.92z"/>
            <path d="M240.13,1269.61c-2.7-10.53-4.21-21.2-2.32-32.25c1.25,10.7,2.49,21.39,3.74,32.09
                C241.08,1269.5,240.6,1269.55,240.13,1269.61z"/>
            <path d="M324.29,1148.75c-1.13-0.26-2.76-0.14-3.31-0.85c-4.25-5.49-8.29-11.14-12.4-16.73c0.45-0.39,0.9-0.78,1.35-1.17
                c2.79,3.07,5.78,5.99,8.31,9.26c2.3,2.98,4.08,6.36,6.09,9.56C324.32,1148.82,324.29,1148.75,324.29,1148.75z"/>
            <path d="M276.36,1195.71c-0.69,1.71-1.39,3.43-2.39,5.88c-1.44-6.06,1.17-11.92,5.72-14.01c4.31-1.98,7.49-0.07,10.33,3
                c-3.62,0-7.07,0-10.53,0l-0.34,0.33c-0.21,0.14-0.38,0.32-0.52,0.52c-0.19,0.19-0.38,0.39-0.57,0.58c-0.16,0.1-0.3,0.24-0.4,0.4
                c-0.18,0.23-0.36,0.46-0.54,0.69c-0.27,0.89-0.54,1.77-0.81,2.66L276.36,1195.71z"/>
            <path d="M234.91,1255.99c1.72,8.33,3.44,16.67,5.16,25c-0.66,0.13-1.32,0.26-1.97,0.38c-1.61-8.4-3.23-16.8-4.84-25.2
                C233.81,1256.11,234.36,1256.05,234.91,1255.99z"/>
            <path d="M353.32,1099.07c6.32,1.5,12.38,3.59,17.51,7.73c-0.28,0.64-0.56,1.29-0.83,1.93c-5.8-2.56-11.61-5.11-17.41-7.67
                C352.83,1100.4,353.07,1099.74,353.32,1099.07z"/>
            <path d="M226.67,1285.11c0-8.51,0-17.02,0-25.53c0.27-0.03,0.54-0.05,0.82-0.08c0.49,8.51,0.98,17.02,1.48,25.53
                C228.2,1285.06,227.43,1285.08,226.67,1285.11z"/>
            <path d="M294.68,1195.02c5.98,6.25,7.2,13.09,3.12,17.72C296.77,1206.87,295.85,1201.62,294.68,1195.02z"/>
            <path d="M310.32,1086.82c1,0.33,1.99,0.65,2.99,0.98c0,0-0.01,0.02-0.01,0.02c-4.44,1.62-8.88,3.23-13.31,4.85
                c-0.77-1.04-1.55-2.09-2.32-3.13c1.48-0.63,2.96-1.26,4.44-1.89c0.17,0.11,0.33,0.21,0.5,0.32l0.43-0.01
                c0.15-0.1,0.31-0.2,0.47-0.3c1.27-0.28,2.55-0.56,3.82-0.84c0,0-0.03-0.03-0.03-0.03c0.66,0.01,1.33,0.02,1.99,0.03l-0.04-0.03
                c0.37,0,0.74,0,1.11,0L310.32,1086.82z"/>
            <path d="M232.09,1281.43c-0.93-6.29-1.86-12.57-2.8-18.86c0.48-0.09,0.97-0.18,1.45-0.27c1.15,6.28,2.29,12.55,3.44,18.83
                C233.49,1281.23,232.79,1281.33,232.09,1281.43z"/>
            <path d="M279.49,1190.59c0.72,3.1,1.44,6.2,2.17,9.3c-0.56,0.17-1.12,0.34-1.67,0.51c-0.39-0.66-0.78-1.31-1.13-1.91
                c-0.41,0.67-0.9,1.49-1.4,2.31c-0.36-0.05-0.73-0.11-1.09-0.16c0-1.64,0-3.29,0-4.93c0,0-0.05,0.07-0.05,0.07
                c0.27-0.89,0.54-1.77,0.81-2.66c0.18-0.23,0.36-0.46,0.54-0.69c0.18-0.08,0.31-0.22,0.4-0.4c0.19-0.2,0.38-0.39,0.57-0.58
                c0.21-0.13,0.39-0.31,0.53-0.52L279.49,1190.59z"/>
            <path d="M293.35,1090.78c-2.07,1.62-4.15,3.24-6.22,4.85c-0.35-0.53-0.69-1.07-1.04-1.6c2.03-1.69,2.75-5.6,7.2-3.18
                C293.29,1090.85,293.35,1090.78,293.35,1090.78z"/>
            <path d="M293.29,1090.85c0.69-0.37,1.38-0.76,2.08-1.11c0.11-0.05,0.3,0.05,1.44,0.27c-1.32,1.09-2.18,1.81-3.05,2.53
                c-0.14-0.59-0.28-1.18-0.42-1.76C293.35,1090.78,293.29,1090.85,293.29,1090.85z"/>
            <polygon points="313.3,1089.79 312.97,1089.99 313.33,1089.83 		"/>
            <path d="M329.99,1084.58c0.52,0,1.05,0,1.57,0c-0.23,0.16-0.45,0.32-0.67,0.48c0,0-0.18,0.01-0.18,0.01
                C330.47,1084.9,330.23,1084.74,329.99,1084.58z"/>
            <path d="M309.28,1086.82c0.34,0,0.69,0,1.03,0c0,0,0.04-0.03,0.04-0.03c-0.37,0-0.74,0-1.11,0
                C309.25,1086.79,309.28,1086.82,309.28,1086.82z"/>
            <path d="M302.1,1087.64c0.46,0.01,0.93,0.01,1.39,0.02c-0.15,0.1-0.31,0.2-0.46,0.3c0,0-0.43,0.01-0.43,0.01
                C302.43,1087.85,302.26,1087.74,302.1,1087.64z"/>
            <path d="M662.84,778.29c-2.37-1.79-3.43-2.79-4.67-3.49c-3.29-1.84-6.1-0.76-6.77,2.79c-0.44,2.34-0.65,4.01-3.79,4.01
                c-1.02,0-2.03,2.55-3.23,4.18c-5.55-4.79-11.97-4.75-18.05-1.82c-2.32,1.12-4.2,4.45-4.96,7.15c-0.94,3.33,1.82,5.09,4.68,6.7
                c3.13,1.76,5.79,4.35,8.23,7.07c-13.6-9.56-17.87-6.19-22.22,3.58c-9.04-6.58-10.75-5.16-11.91,1.73
                c-5.97-1.47-5.97-1.47-7.52,1.4c-3.4-0.77-6.88-2.55-9.86-1.92c-2.61,0.55-4.65,3.8-6.81,5.73c-1.98-3.86,2.2-8.95,8.42-9.08
                c3.21-0.07,6.47-0.09,9.62-0.64c2.07-0.36,3.9-2.04,5.97-2.4c3.49-0.61,7.08-0.65,11.94-1.03c1.4-1.21,4.12-3.58,7.09-6.15
                c-1.01-12.36,6.78-19.17,20.1-16c4.38,1.05,6.97-1.19,9.08-3.04c6.1-5.33,5.81-5.66,11.66-5.46c3.83-9.63,10.63-14.2,18.78-12.62
                c9.79,1.89,16.52,8.9,16.13,17.4c-2.95-8.69-8.92-14.15-17.84-14.84C666.29,760.73,662.28,767.45,662.84,778.29z"/>
            <path d="M228.05,971.58c2.62,5.64,5.48,11.53,3.83,17.87c-2.89,11.09-2.15,21.62,2.03,32.22c1.42,3.61,1.42,7.79,1.02,11.94
                c-5.79-9.82-13.45-19.27-10.29-31.45c2.48-9.58,3.28-18.93,1.78-28.61c-0.09-0.57,0.24-1.21,0.37-1.81
                C227.21,971.7,227.63,971.64,228.05,971.58z M230.45,1022.88c0.32-0.08,0.63-0.16,0.95-0.25c-1.63-5.91-3.26-11.83-4.89-17.74
                c-0.26,0.07-0.52,0.14-0.78,0.2C227.3,1011.03,228.87,1016.95,230.45,1022.88z"/>
            <path d="M303.02,939.3c-7.36-43.1-6.1-86.6-5.99-130.04c0.46-0.11,0.93-0.21,1.39-0.32c0.39,1.21,1.07,2.41,1.14,3.65
                c0.75,13.1,1.26,26.22,2.13,39.31c0.97,14.56,2.62,29.08,3.27,43.65c0.59,13.28,0.27,26.59,0.26,39.89c0,1.33-0.61,2.66-0.93,3.99
                C303.86,939.38,303.44,939.34,303.02,939.3z M302.23,917.77c0.24,0.05,0.48,0.1,0.72,0.15c0-5.97,0.12-11.94-0.03-17.9
                c-0.15-5.96-0.47-11.93-0.9-17.88c-0.4-5.69-1.03-11.37-1.56-17.06c-0.37,0.03-0.75,0.06-1.12,0.1
                C300.3,882.71,301.27,900.24,302.23,917.77z"/>
            <path d="M329,1013.55c-1.26-1.38-2.74-2.61-3.75-4.15c-9.5-14.5-16.42-29.84-16.18-47.71c0.14-10.46-0.24-20.93-0.34-31.39
                c-0.02-1.76,0.21-3.53,1.29-5.44c3.24,30.18,11.16,59.23,20.66,87.92C330.12,1013.04,329.56,1013.3,329,1013.55z M326.28,1006.87
                c0,0,0.02-0.02,0.02-0.02c-5.08-17.95-10.15-35.91-14.6-51.63C309.58,972.71,315.55,990.43,326.28,1006.87z"/>
            <path d="M143.02,881.85c0.65,1.92,1.48,3.81,1.92,5.78c4.28,19.15,8.38,38.33,12.76,57.46c3.7,16.17,7.75,32.25,11.56,48.39
                c0.59,2.51,0.73,5.12,1.09,7.69c-0.42,0.19-0.83,0.37-1.25,0.56c-0.92-1.34-2.13-2.57-2.71-4.04c-1.76-4.49-3.06-9.16-4.92-13.6
                c-7.39-17.55-11.65-35.95-14.71-54.64c-2.09-12.78-3.76-25.62-5.44-38.46c-0.38-2.89-0.06-5.87-0.06-8.81
                C141.84,882.06,142.43,881.95,143.02,881.85z M148.43,915.97c-0.09-0.48-0.19-0.96-0.04-0.18c-0.32-0.73-0.13-0.29,0.07,0.15
                c1.37,23.57,6.59,46.23,16.01,67.91c0.13,0.24,0.27,0.49,0.4,0.73c-0.15-0.23-0.3-0.46-0.45-0.69
                c-3.22-13.46-6.48-26.91-9.66-40.38C152.59,934.34,150.53,925.15,148.43,915.97z M145.86,903.97l0.19,0.09L146,903.7
                L145.86,903.97z M147.06,908.89l-0.19-0.09l0.05,0.37L147.06,908.89z"/>
            <path d="M882.4,556.09c1.98-1.74,3.97-3.48,5.95-5.21c0.4,0.45,0.79,0.9,1.19,1.36c-1.96,1.76-3.93,3.52-5.89,5.29
                C883.23,557.04,882.82,556.57,882.4,556.09z"/>
            <path d="M890.1,556.47c1.81-1.07,3.61-2.14,5.42-3.21c0.31,0.49,0.62,0.97,0.93,1.46c-1.74,1.1-3.49,2.2-5.23,3.3
                C890.84,557.5,890.47,556.98,890.1,556.47z"/>
            <path d="M158.01,464.9c-1.1-1.52-2.2-3.04-3.3-4.56c0.4-0.32,0.81-0.64,1.21-0.96c1.18,1.45,2.36,2.89,3.54,4.34
                C158.98,464.11,158.5,464.5,158.01,464.9z"/>
            <path d="M805.81,511.26c0.34-1.41,0.69-2.82,1.03-4.23c0.31-0.05,0.61-0.11,0.92-0.16c0,1.82,0,3.65,0,5.47
                c-0.63-0.38-1.26-0.77-1.9-1.15L805.81,511.26z"/>
            <path d="M805.87,511.19c-0.7-0.77-1.4-1.54-2.09-2.31c0.37-0.23,0.75-0.46,1.12-0.69c0.31,1.02,0.61,2.04,0.92,3.06
                C805.81,511.26,805.87,511.19,805.87,511.19z"/>
            <path d="M802.07,509.98c-0.07,0.67-0.14,1.35-0.26,2.02c-0.01,0.04-0.49,0-0.76-0.01c0.07-0.71,0.13-1.42,0.2-2.13
                C801.52,509.89,801.79,509.93,802.07,509.98z"/>
            <path d="M1053.63,1198.73c-0.35,0-0.71,0-1.06,0C1052.93,1198.73,1053.28,1198.73,1053.63,1198.73z"/>
            <path d="M230.71,1355.69c-0.26,0.05-0.52,0.09-0.78,0.13c0.03-0.26,0.03-0.52,0.11-0.76c0.02-0.07,0.38-0.12,0.4-0.08
                C230.57,1355.2,230.63,1355.45,230.71,1355.69z"/>
            <path d="M230.41,1358.52c0.22,0.18,0.44,0.36,0.65,0.53c-0.32,0.22-0.65,0.45-0.97,0.67c-0.14-0.24-0.43-0.54-0.37-0.71
                C229.79,1358.8,230.15,1358.68,230.41,1358.52z"/>
            <path d="M228.94,1363.58c-0.26,0.03-0.52,0.07-0.78,0.05c-0.03,0-0.05-0.28-0.08-0.43c0.27-0.02,0.53-0.06,0.8-0.05
                C228.9,1363.16,228.92,1363.44,228.94,1363.58z"/>
            <path d="M231.56,1348.8c-0.43,0.24-0.85,0.48-1.28,0.73c0.32-0.35,0.63-0.7,0.95-1.06C231.23,1348.47,231.56,1348.8,231.56,1348.8
                z"/>
            <path d="M246.61,1351.49c0.48,0.44,0.76,0.71,1.05,0.98c-0.28,0.3-0.53,0.66-0.87,0.87c-0.1,0.06-0.67-0.32-0.66-0.48
                C246.14,1352.48,246.37,1352.11,246.61,1351.49z"/>
            <path d="M247.6,1348.56c-0.25-0.03-0.5-0.07-0.76-0.1c0.03-0.14,0.06-0.39,0.08-0.39c0.26,0,0.53,0.04,0.79,0.08
                C247.67,1348.28,247.64,1348.42,247.6,1348.56z"/>
            <path onClick={() => props.onFill(977)} fill={props.fillColors[977]} d="M496.67,1221.36c19.39,1.22,38.78,2.44,58.17,3.66c0.09-0.55,0.17-1.1,0.26-1.64
                c-1.13-0.69-2.27-1.39-2.52-1.54c16.83,0,34.43,0,52.03,0c0.32,0.64,0.64,1.28,0.95,1.92c-2.28,1.92-4.56,3.84-7.76,6.53
                c6.53,1.54,12.59,2.97,18.66,4.4c-0.02,0.56-0.05,1.12-0.07,1.69c-7.86,0-15.73-0.22-23.59,0.06c-7.86,0.28-15.71,1.07-23.56,1.64
                c-0.07,0.74-0.15,1.48-0.22,2.22c1.67,0.56,3.3,1.43,5.01,1.64c16.66,2.08,33.4,3.61,49.97,6.25c8.56,1.36,16.81,4.64,25.2,7.04
                c-0.07,0.71-0.14,1.42-0.21,2.13c-1.79,0.27-3.59,0.82-5.37,0.76c-31.94-1.18-63.87-1.62-95.73,1.43
                c-3.66,0.35-7.24,1.61-10.85,2.45c-0.06,0.64-0.12,1.27-0.17,1.91c17.01,5.64,34.01,11.28,51.02,16.92
                c-0.1,0.49-0.21,0.97-0.31,1.46c-13.42,1.17-27.12-1.71-40.49,2.44c28.48,9.32,58.7,11.01,86.98,21.47
                c-36.4,4.2-72.79,8.4-109.19,12.61c-0.02,0.49-0.04,0.98-0.05,1.47c1.51,0.34,3.01,0.82,4.54,0.98
                c17.35,1.9,34.74,3.49,52.05,5.69c27.53,3.5,55.01,7.35,82.5,11.18c2.7,0.38,5.28,1.57,7.92,2.39c-0.06,0.44-0.11,0.88-0.17,1.33
                c-7.41,0.29-14.83,0.59-22.24,0.88c-13.29,0.52-26.59,1.45-39.88,1.42c-14.29-0.03-28.57-1.06-42.87-1.5
                c-17.13-0.53-34.26-1-51.39-1.3c-41.26-0.73-82.54-0.84-123.78-2.23c-18.71-0.63-37.36-3.57-55.99-5.82
                c-3.95-0.48-7.7-2.49-11.55-3.8c0.02-0.61,0.05-1.23,0.07-1.84c5.38-1.22,10.71-2.96,16.16-3.53c8.42-0.87,16.92-1.12,25.39-1.3
                c18.63-0.4,37.26-0.5,55.89-0.96c3.99-0.1,7.95-1.21,11.92-1.86c0-0.67,0.01-1.33,0.01-2c-15.22-2.34-30.44-4.68-45.66-7.02
                c23.43-2.05,47.17,0.08,69.62-9.26c-17.17-7.56-35.99-6.08-53.67-11.18c4.58-0.48,9.14-1.14,13.73-1.4
                c14.28-0.82,28.63-0.85,42.83-2.39c16.65-1.81,33.18-4.82,49.73-7.47c1.98-0.32,3.8-1.64,5.69-2.5c0.03-0.61,0.05-1.21,0.08-1.82
                c-6.13-1.48-12.19-3.66-18.41-4.33c-24.78-2.67-49.62-4.86-74.43-7.25c-13.57-1.3-27.14-2.58-40.69-4.02
                c-2.75-0.29-5.43-1.25-8.17-3.08c2.32-0.4,4.63-1,6.97-1.17c25.21-1.8,50.42-3.58,75.64-5.27c15.09-1.02,30.2-1.72,45.27-2.92
                c4.02-0.32,7.93-1.97,11.9-3.02c0.08-0.48,0.16-0.95,0.24-1.43c-1.9-1.32-3.8-2.63-6.48-4.49c6.53-1.21,12.5-2.31,18.47-3.41
                c0.06-0.52,0.12-1.05,0.18-1.57c-10.06-2.93-20.88-3.85-29.84-9.66C496.48,1222.66,496.57,1222.01,496.67,1221.36z"/>
            <path onClick={() => props.onFill(978)} fill={props.fillColors[978]} d="M1001.64,1375.6c-3.51,3.77-6.83,7.75-10.57,11.29c-13.54,12.83-29.24,20.92-48.07,23.29
                c-16.81,2.12-33.6,4.84-50.13,8.49c-19.82,4.36-39.06,4.1-58.56-1.82c-14.88-4.51-30-2.44-44.83,1.54
                c-1.2,0.32-2.28,1.08-3.49,2.82c11.12,2.53,22.25,5.07,33.38,7.6c-0.07,0.26-0.13,0.53-0.2,0.79c-11.66,2.88-23.33,5.7-34.97,8.66
                c-11.57,2.94-23.88,2.73-36.31,7.68c2,1.3,2.7,2.03,3.54,2.27c19.5,5.57,39,11.16,58.55,16.6c19,5.29,38.18,4.01,57.45,1.67
                c10.88-1.32,21.86-1.86,32.8-2.56c2.6-0.17,5.25,0.45,7.88,0.71c0.08,0.44,0.17,0.88,0.25,1.31c-10.07,2.77-20.09,7.67-30.22,7.91
                c-27.11,0.66-53.6,4.85-80.12,9.73c-13.31,2.45-26.29-0.04-38.42-5.71c-4.2-1.96-8.36-5.29-11.01-9.07
                c-7.72-11.02-17.75-18.78-29.87-24.18c-2.22-0.99-4.27-2.34-7.61-4.2c5.4-2.14,9.56-4.11,13.91-5.45
                c17.65-5.43,35.38-10.57,53.02-16.03c6.02-1.86,11.74-4.7,17.78-6.48c20.23-5.97,40.41-12.18,60.87-17.26
                c12.86-3.2,26.16-4.71,39.32-6.64c4.23-0.62,8.62-0.36,12.93-0.22c25.27,0.79,50.64,2.72,75.23-5.46
                c5.97-1.99,11.66-4.82,17.49-7.27L1001.64,1375.6z"/>
            <polygon onClick={() => props.onFill(979)} fill={props.fillColors[979]} points="1001.65,1375.6 1001.75,1375.49 1001.64,1375.6 		"/>
            <path onClick={() => props.onFill(980)} fill={props.fillColors[980]} d="M985.65,1414.55c-17.37,9.34-33.82,20.54-54.23,23.16c-13.65,1.75-27.11,5.03-40.64,7.7
                c-3.07,0.61-6.07,1.53-10.13,2.57c3.5-3.41,2.59-5.14-0.86-5.6c-15.11-2.03-30.24-3.96-45.14-5.89c4.81-2.92,10.58-3,16.29-2.46
                c12.91,1.22,25.81,2.56,38.67,4.19c12.13,1.54,23.92,0.07,35.47-3.49c9.53-2.93,18.92-6.29,28.36-9.5
                c1.49-0.51,2.9-1.23,4.35-1.85c-0.08-0.44-0.16-0.88-0.24-1.33c-5.24,0-10.49,0-15.66,0c14.53-2.49,29.14-4.99,43.76-7.49
                L985.65,1414.55z"/>
            <path onClick={() => props.onFill(981)} fill={props.fillColors[981]} d="M1005.67,1404.58c6.32-5.66,12.65-11.32,18.97-16.97c0,0-0.03-0.03-0.03-0.03
                c-4.68,7.5-10.61,13.59-18.97,16.98C1005.64,1404.55,1005.67,1404.58,1005.67,1404.58z"/>
            <path onClick={() => props.onFill(982)} fill={props.fillColors[982]} d="M1027.66,1383.54c-0.68,1.01-1.36,2.01-2.04,3.02c0,0,0.03,0.03,0.03,0.03c0.65-1.03,1.31-2.07,1.96-3.1
                L1027.66,1383.54z"/>
            <path onClick={() => props.onFill(983)} fill={props.fillColors[983]} d="M985.67,1414.57c1.01-0.34,2.01-0.67,3.01-1.01c0,0,0.04,0.04,0.04,0.04c-1.02,0.32-2.05,0.63-3.07,0.95
                C985.65,1414.55,985.67,1414.57,985.67,1414.57z"/>
            <path onClick={() => props.onFill(984)} fill={props.fillColors[984]} d="M1002.6,1406.54c0.69-0.32,1.37-0.63,2.06-0.95l-0.03-0.03c-0.66,0.34-1.33,0.68-1.99,1.01L1002.6,1406.54
                z"/>
            <path onClick={() => props.onFill(985)} fill={props.fillColors[985]} d="M1002.63,1406.58c-0.66,0.33-1.33,0.66-1.99,0.98l0.01,0c0.65-0.34,1.29-0.68,1.94-1.03
                C1002.6,1406.54,1002.63,1406.58,1002.63,1406.58z"/>
            <polygon onClick={() => props.onFill(986)} fill={props.fillColors[986]} points="990.58,1412.52 990.87,1412.41 990.63,1412.57 		"/>
            <path onClick={() => props.onFill(987)} fill={props.fillColors[987]} d="M990.63,1412.57c-0.64,0.34-1.27,0.69-1.91,1.03c0,0-0.04-0.04-0.04-0.04c0.63-0.35,1.27-0.7,1.9-1.04
                C990.58,1412.52,990.63,1412.57,990.63,1412.57z"/>
            <path onClick={() => props.onFill(988)} fill={props.fillColors[988]} d="M1028.53,1382.61c0,0,0.06,0.06,0.06,0.06S1028.53,1382.61,1028.53,1382.61z"/>
            <path onClick={() => props.onFill(989)} fill={props.fillColors[989]} d="M1027.61,1383.49c0.31-0.29,0.61-0.59,0.92-0.88c0,0,0.06,0.06,0.06,0.06c-0.31,0.29-0.62,0.58-0.93,0.87
                C1027.66,1383.54,1027.61,1383.49,1027.61,1383.49z"/>
            <path onClick={() => props.onFill(990)} fill={props.fillColors[990]} d="M1024.64,1387.6c0.34-0.34,0.67-0.68,1.01-1.01c0,0-0.03-0.03-0.03-0.03c-0.34,0.34-0.67,0.68-1.01,1.01
                C1024.61,1387.58,1024.64,1387.6,1024.64,1387.6z"/>
            <path onClick={() => props.onFill(991)} fill={props.fillColors[991]} d="M1004.66,1405.59c0.34-0.34,0.67-0.67,1.01-1.01c0,0-0.03-0.03-0.03-0.03c-0.34,0.34-0.67,0.67-1.01,1.01
                C1004.63,1405.56,1004.66,1405.59,1004.66,1405.59z"/>
            <polygon onClick={() => props.onFill(992)} fill={props.fillColors[992]} points="1000.64,1407.56 1000.54,1407.68 1000.66,1407.56 		"/>
            <path onClick={() => props.onFill(993)} fill={props.fillColors[993]} d="M1046.6,1270.68c-0.47,2.62-0.99,5.22-1.42,7.85c-2.43,15.03-11.21,24.34-25.55,28.93
                c-12.05,3.85-22.85,9.74-31.48,19.37c-9.09,10.15-20.41,16.24-34.19,17.74c-3.76,0.41-7.35,2.37-11.02,3.62
                c-0.07,0.64-0.14,1.29-0.21,1.93c2.97,1.72,5.94,3.43,8.92,5.15c0.12,0.45,0.24,0.89,0.36,1.34c-6.17,1.18-12.32,3.08-18.53,3.32
                c-5.65,0.21-7.8-5.68-4.35-10.5c5.08-7.09,12.95-8.84,20.73-9.24c15.27-0.8,27.37-7.33,37.5-18.3c6.92-7.49,15.19-13.2,24.97-15.9
                c15.22-4.2,25.05-13.78,30.84-28.1c0.99-2.46,2.3-4.79,3.47-7.18L1046.6,1270.68z"/>
            <path onClick={() => props.onFill(994)} fill={props.fillColors[994]} d="M1046.63,1270.7c0.09-0.28,0.18-0.56,0.17-0.53c0.01-0.03-0.1,0.24-0.2,0.51
                C1046.6,1270.68,1046.63,1270.7,1046.63,1270.7z"/>
            <polygon onClick={() => props.onFill(995)} fill={props.fillColors[995]} points="327.33,1084.86 327.24,1084.8 327.39,1084.8 		"/>
            <path onClick={() => props.onFill(996)} fill={props.fillColors[996]} d="M327.39,1084.8c0.3,0.33,0.59,0.66,0.89,0.99l0,0.01c-0.32-0.31-0.64-0.63-0.95-0.94
                C327.33,1084.86,327.39,1084.8,327.39,1084.8z"/>
            <path onClick={() => props.onFill(997)} fill={props.fillColors[997]} d="M330.71,1085.06c0,0,0.18-0.01,0.18-0.01C330.89,1085.06,330.71,1085.06,330.71,1085.06z"/>
            <path onClick={() => props.onFill(998)} fill={props.fillColors[998]} d="M323.3,1086.79c0,0-0.03,0.03-0.03,0.02C323.28,1086.82,323.3,1086.79,323.3,1086.79z"/>
            <path onClick={() => props.onFill(999)} fill={props.fillColors[999]} d="M328.28,1085.79c0,0,0,0.01,0,0.01C328.29,1085.8,328.28,1085.79,328.28,1085.79z"/>
            <polygon onClick={() => props.onFill(1000)} fill={props.fillColors[1000]} points="291.33,1201.67 291.44,1201.94 291.29,1201.72 		"/>
            <path onClick={() => props.onFill(1001)} fill={props.fillColors[1001]} d="M291.29,1201.72c-0.33-0.33-0.65-0.65-0.98-0.98c0,0,0.02-0.02,0.02-0.02c0.33,0.32,0.66,0.64,1,0.96
                C291.33,1201.67,291.29,1201.72,291.29,1201.72z"/>
            <path onClick={() => props.onFill(1002)} fill={props.fillColors[1002]} d="M289.33,1202.71c0,0-0.02,0.02-0.02,0.02C289.31,1202.73,289.33,1202.71,289.33,1202.71z"/>
            <path onClick={() => props.onFill(1003)} fill={props.fillColors[1003]} d="M277.12,1193.11c-0.27,0.89-0.54,1.77-0.81,2.66C276.58,1194.89,276.85,1194,277.12,1193.11z"/>
            <path onClick={() => props.onFill(1004)} fill={props.fillColors[1004]} d="M279.15,1190.92c-0.14,0.21-0.31,0.38-0.53,0.52C278.77,1191.23,278.95,1191.06,279.15,1190.92z"/>
            <path onClick={() => props.onFill(1005)} fill={props.fillColors[1005]} d="M278.06,1192.02c-0.08,0.19-0.22,0.32-0.4,0.4C277.76,1192.26,277.89,1192.12,278.06,1192.02z"/>
            <path onClick={() => props.onFill(1006)} fill={props.fillColors[1006]} d="M307.29,1086.79c0,0,0.03,0.03,0.03,0.03C307.32,1086.82,307.29,1086.79,307.29,1086.79z"/>
            <path onClick={() => props.onFill(1007)} fill={props.fillColors[1007]} d="M309.25,1086.79c0.37,0,0.74,0,1.11,0C309.99,1086.79,309.62,1086.79,309.25,1086.79z"/>
            <path onClick={() => props.onFill(1008)} fill={props.fillColors[1008]} d="M302.6,1087.96c0,0,0.43-0.01,0.43-0.01S302.6,1087.96,302.6,1087.96z"/>
            <path onClick={() => props.onFill(1009)} fill={props.fillColors[1009]} d="M230.45,1022.88c-1.57-5.93-3.15-11.85-4.72-17.78c0.26-0.07,0.52-0.14,0.78-0.2
                c1.63,5.91,3.26,11.83,4.89,17.74C231.08,1022.72,230.77,1022.8,230.45,1022.88z"/>
            <path onClick={() => props.onFill(1010)} fill={props.fillColors[1010]} d="M302.23,917.77c-0.97-17.53-1.93-35.06-2.9-52.59c0.37-0.03,0.75-0.06,1.12-0.1
                c0.53,5.69,1.16,11.36,1.56,17.06c0.42,5.95,0.75,11.91,0.9,17.88c0.15,5.96,0.03,11.93,0.03,17.9
                C302.71,917.87,302.47,917.82,302.23,917.77z"/>
            <path onClick={() => props.onFill(1011)} fill={props.fillColors[1011]} d="M326.28,1006.87c-10.72-16.44-16.69-34.16-14.58-51.65c4.45,15.73,9.52,33.68,14.6,51.63L326.28,1006.87z"
                />
            <path onClick={() => props.onFill(1012)} fill={props.fillColors[1012]} d="M326.29,1006.86c0,0-0.02,0.02-0.02,0.02C326.28,1006.87,326.29,1006.86,326.29,1006.86z"/>
            <path onClick={() => props.onFill(1013)} fill={props.fillColors[1013]} d="M164.47,983.85c-9.42-21.67-14.64-44.34-16.01-67.91l-0.03,0.03c2.11,9.18,4.17,18.37,6.33,27.54
                c3.18,13.47,6.44,26.92,9.66,40.38L164.47,983.85z"/>
            <polygon onClick={() => props.onFill(1014)} fill={props.fillColors[1014]} points="145.86,903.97 146,903.7 146.05,904.06 		"/>
            <polygon onClick={() => props.onFill(1015)} fill={props.fillColors[1015]} points="147.06,908.89 146.92,909.18 146.87,908.8 		"/>
            <path onClick={() => props.onFill(1016)} fill={props.fillColors[1016]} d="M148.46,915.94c-0.2-0.44-0.39-0.88-0.07-0.15c-0.15-0.78-0.06-0.3,0.04,0.18
                C148.43,915.97,148.46,915.94,148.46,915.94z"/>
            <path onClick={() => props.onFill(1017)} fill={props.fillColors[1017]} d="M164.42,983.89c0.15,0.23,0.3,0.46,0.45,0.69c-0.13-0.24-0.27-0.49-0.4-0.73
                C164.47,983.85,164.42,983.89,164.42,983.89z"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1104.06,1.61c9.32-0.17,18.65-0.4,27.97-0.48c9.82-0.08,19.65-0.02,29.97-0.02c0,83.6,0,167.21,0,250.81"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1162,259.42c0,413.19,0,826.39,0,1239.58c-386.8,0-773.61,0-1160.91,0c0-11.99,0-23.98,0-35.97c0-4.66,0-9.33,0-13.99
                c0-96.76,0-193.52,0.02-290.29c0-1.17,0.31-2.33,0.48-3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1126.53,439.78c1,8.16,2.18,16.3,2.93,24.49c0.5,5.46,0.49,10.99,0.55,16.48c0.21,20.65,0.11,41.31,0.58,61.95
                c0.59,26.15,1.59,52.3,2.44,78.44c0.98,30.31,2.49,60.62,2.79,90.93c0.16,15.81-1.79,31.64-2.8,47.46
                c-0.49,7.66-1.24,15.32-1.45,22.98c-0.49,17.82-0.2,35.67-1.19,53.45c-1.04,18.85-3.09,37.66-4.89,56.46
                c-1.51,15.84-3.28,31.65-5.02,47.46c-0.1,0.88-0.95,1.67-1.45,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M804.84,1382.09c3.1,0.64,6.19,1.28,10.22,2.11c0.84,1.11,2.48,3.3,4.45,5.92c-2.2,0.97-4.35,2.3-6.69,2.91
                c-18.3,4.74-35.69,11.98-52.91,19.59c-18.47,8.16-37.45,14.87-57.5,17.16c-9.64,1.1-19.62-0.72-29.44-1.22
                c-17.31-0.88-33.95-6.83-51.45-6.62c-6.98,0.08-14.04-2.11-20.96-1.66c-9.38,0.62-18.77,2.29-27.96,4.4
                c-11.6,2.66-22.93,6.54-34.53,9.24c-6.15,1.43-12.65,1.29-18.94,2.18c-9.51,1.34-19.05,2.66-28.46,4.53
                c-10.06,2-20.02,1.4-29.98-0.11c-11.18-1.69-22.3-3.72-33.48-5.45c-28.97-4.47-53.42,6.75-76.38,22.53
                c-8.85,6.08-18.22,10.08-29.02,10.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1038.12,1.61c-1.33-0.17-2.66-0.48-4-0.48c-151.86-0.02-303.71-0.02-455.57,0c-1.33,0-2.66,0.31-4,0.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M348.76,1.61c-1.67-0.17-3.33-0.48-5-0.48C229.71,1.11,115.65,1.11,1.09,1.11c0,16.98-0.11,33.97,0.07,50.96
                C1.21,56.91,5.41,57,8.55,56.4c3.49-0.67,5.46,0.38,7.06,3.25c3.63-2.12,7.13-3.76,11.06-0.2c1.17,1.06,4.79,0.26,5.18,1.2
                c1.89,4.5,7.74,2.75,9.87,6.82c0.47,0.89,3.13,0.63,4.59,0.87c1.21,1.69,2.13,4.07,3.84,5.02c1.26,0.7,4,0.07,5.23-0.97
                c0.92-0.77,0.99-3.3,0.56-4.8c-1.91-6.71,0.05-8.65,6.58-6.53c0.65,0.21,1.62,0.71,1.95,0.45c2.58-2.06,5.26-4.08,7.42-6.54
                c0.74-0.84,0.12-2.89,0.87-3.77c1.58-1.87,2.86-4.19,4.81-5.53c3.42-2.35,7.21-4.2,10.94-6.06c2.4-1.2,6.17-1.34,7.24-3.17
                c2.55-4.4,6.32-3.16,9.73-3.28c4.89-0.17,10.2,1.59,13.1-4.52c0.34-0.72,2.23-0.7,3.38-1.06c2.67-0.82,5.32-1.69,8-2.49
                c1.82-0.54,3.98-1.89,5.44-1.36c6.87,2.5,13.1-0.86,19.51-1.8c5.3-0.78,10.24-1.5,15.03,1.62c2.39,1.56,4.65,2.06,7.58,0.19
                c2.32-1.48,5.53-1.66,8.39-2.13c7.64-1.26,15.31-2.36,22.97-3.53c0.84-0.13,2.23-0.71,2.41-0.44c2.9,4.33,6.98,1.69,10.57,2
                c1.7,0.15,4.46,0.54,4.81,1.51c1.54,4.36,4.8,4.62,8.6,5.72c4.2,1.22,8.73,3.97,11.29,7.41c2.48,3.33,5.22,3.23,8.18,3.28
                c1.46,8.12,2.67,8.71,10.61,6.08c0.8-0.27,1.97,0.87,2.97,0.86c2.47-0.02,5.06,2.25,7.28-1.78c1.22-2.22,5.38-3.94,8.2-3.92
                c5.33,0.04,9.21-1.27,11.34-6.57c3.66,1.99,6.63,1.87,9.19-1.63c2.79-3.82,5.9-7.4,8.99-10.99c0.52-0.6,1.7-1.11,2.44-0.97
                c5.24,0.97,9.46-1.17,13.51-4.04c0.22-0.16,0.88,0.1,0.95-0.04c3.6-6.53,10.65-6.66,16.5-9.02c0.95-0.38,2.22-1.16,2.42-1.98
                c0.78-3.29,2.83-2.22,4.57-1.44c3.49,1.58,6.15,2.35,10.24-0.16c3.15-1.93,8.43-0.49,12.75-0.25c0.55,0.03,1.13,1.83,1.48,2.87
                c1.1,3.23,1.68,7.18,6.57,3.56c0.46-0.34,1.87,0.01,2.37,0.51c0.5,0.5,0.75,1.71,0.52,2.41c-3.1,9.44-2.31,12.12,5.55,18
                c2.93,2.19,2.7,7.57-0.88,11.96c3.14,2.35,6.26,4.69,9.37,7.03c-3.69,5.68-3.65,5.61,2.47,8.56c1.2,0.58,2.03,1.94,3.03,2.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1103.06,2.11c-0.83,29.64-1.62,59.29-2.51,88.93c-0.59,19.49-1.32,38.97-1.98,58.46c-0.67,19.82-1.51,39.63-1.93,59.46
                c-0.26,12.14,0.83,24.17,5.91,35.48c0.49,1.09,0.98,2.32,0.98,3.49c-0.11,26.48-0.09,52.96-0.52,79.44
                c-0.33,19.99-1.39,39.97-1.88,59.96c-0.28,11.32-0.07,22.65-0.03,33.97c0.01,1.5,0.31,3,0.48,4.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M426.69,1319.63c-4.5,0.67-8.99,1.86-13.49,1.91c-20.48,0.21-40.97-0.22-61.44,0.22c-10.59,0.23-21.11,2.02-31.3,6.11
                c7.94,6.31,17.18,7.62,26.32,8.65c12.61,1.42,25.29,2.14,37.95,3.09c5.82,0.43,11.65,0.91,17.48,0.98
                c23.14,0.26,46.29,0.35,69.43,0.55c9.33,0.08,18.65,0.28,27.97,0.5c12.49,0.29,24.98,0.61,37.46,1
                c19.65,0.62,39.29,1.47,58.95,1.93c12.31,0.29,24.64,0.18,36.96,0.02c10.99-0.15,21.99-0.48,32.97-1.03
                c4.19-0.21,8.33-1.26,12.49-1.92"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M845.3,577.68c0.33-0.67,0.67-1.89,1-1.89c0.83,0,2.24,0.38,2.41,0.92c1.17,3.78,2.46,7.58,3,11.48
                c0.71,5.11,0.69,10.32,1.11,15.47c0.6,7.5,1.61,14.98,1.85,22.49c0.11,3.47-1.61,6.97-1.79,10.49c-0.68,13.15-1,26.31-1.6,39.47
                c-0.34,7.5-1.34,14.99-1.39,22.49c-0.1,14.49,0.29,28.98,0.41,43.47c0.07,8.66-0.09,17.32,0.03,25.98
                c0.27,19.82,0.24,39.66,1.12,59.45c0.57,12.86,2.57,25.65,3.84,38.48c1.07,10.82,1.88,21.66,3.05,32.47
                c1.17,10.84,2.89,21.63,3.88,32.49c0.88,9.63,1.23,19.31,1.54,28.97c0.51,15.66,2.43,31.1,5.41,46.49
                c1.97,10.2,3,20.61,4.06,30.96c1.24,12.13,2.06,24.31,3.05,36.47c1.01,12.32,1.93,24.65,3.03,36.97c0.45,5.02,1.3,10,1.96,14.99"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1096.06,719.58c0.5,9.49,1.35,18.98,1.42,28.48c0.12,15.65-0.24,31.31-0.42,46.96c-0.32,27.48-1.27,54.97-0.84,82.44
                c0.54,35.32,2.24,70.61,3.31,105.93c0.38,12.65,0.34,25.31,0.55,37.97c0.09,5.66,0.32,11.33,0.49,16.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M538.09,691.6c-1.5-1.17-2.94-3.24-4.51-3.35c-8.93-0.59-18.02-1.06-24,9.37c1.24,0.59,2.82,1.35,4.41,2.1
                c-0.21,0.71-0.42,1.42-0.63,2.12c-10.78,1.88-21.55,3.75-32.33,5.63c-0.05,0.45-0.11,0.9-0.16,1.36c1.26,0.42,2.52,1.2,3.77,1.2
                c21.15-0.09,42.29-0.26,63.44-0.46c4.5-0.04,8.99-0.27,13.49-0.5c3.36-0.17,6.23,0.03,9.37-3.1c2.17-2.16,7.97-1.08,12.1-0.82
                c15.66,1,31.3,2.36,46.96,3.41c18.98,1.27,38,1.96,56.93,3.69c9.41,0.86,18.63,3.71,28,5.23c4.41,0.72,8.98,0.43,13.47,0.59"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1148.01,582.68c-0.67,1.5-1.99,3.04-1.9,4.49c0.82,13.17,2.22,26.31,2.8,39.48c0.58,13.31,0.43,26.64,0.59,39.97
                c0.17,14.66-0.53,29.39,0.74,43.95c1.29,14.71,2.39,29.26,0.88,44c-0.57,5.59,0.35,11.31,0.36,16.98
                c0.05,27.65-0.07,55.29,0.07,82.94c0.05,10.16,0.65,20.32,0.94,30.48c0.11,3.83,0.02,7.66,0.02,11.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M848.8,698.09c-0.34-1.32-0.69-2.65-1.03-3.97c-0.8,0.64-1.6,1.28-2.4,1.92c-3.4,9.07-2.4,18.61-2.54,28.03
                c-0.01,0.83,0.44,1.66,0.46,2.5c0.36,16.99,0.58,33.98,1.04,50.96c0.74,26.98,1.45,53.97,2.55,80.94
                c0.53,13.01,2.04,25.98,2.78,38.98c0.11,1.94-1.23,4.19-2.42,5.95c-4.45,6.57-5.08,14.11-4.79,21.51
                c0.43,11.18,2,22.31,2.81,33.48c0.85,11.81,1.75,23.64,1.95,35.47c0.32,18.65,0.08,37.3,0.09,55.96c0,5.74,1.06,11.03,3.22,16.6
                c4.16,10.73,6.42,22.08,4.86,33.88c-0.54,4.09-0.2,8.32-0.04,12.48c0.1,2.73,1.74,3.72,4.29,5.27
                c10.47,6.34,23.19,7.38,33.18,14.65c2,1.46,4.71,1.9,6.92,3.1c3.41,1.85,7.14,3.47,9.91,6.06c5.08,4.75,10.55,8.02,17.57,9
                c3.78,0.53,7.71,1.49,9.61,5.84c0.7,1.61,2.4,3.61,3.92,3.89c9,1.66,14.47,9.35,22.45,12.64c0.34,0.14,0.74,0.26,0.99,0.51
                c5.84,5.65,11.6,11.38,17.52,16.95c3.01,2.83,6.25,5.42,9.48,8.01c3.43,2.74,7.52,4.87,10.36,8.11c5.39,6.16,12.8,8.7,19.6,12.39"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M581.55,1471.52c-16.6,4.89-33.25,1.49-49.96,0.78c-13.1-0.55-26.34,0.96-39.44,2.31c-23.53,2.44-45.95,1.04-65.48-14.54
                c-13.64-10.88-29.16-13.88-45.89-8.92c-4.72,1.4-8.97,4.35-13.53,6.35c-13.46,5.9-26.53,13.33-40.57,17.07
                c-15.41,4.11-29.62,9.83-42.82,18.47c-2.35,1.54-4.36,3.62-6.52,5.45"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M830.81,581.18c-1-5.33-2.38-10.62-2.9-16c-0.71-7.29-0.96-14.64-1.04-21.98c-0.09-8.14,2.36-9.14,10.01-6.17
                c4.74,1.84,9.68,2.84,13.52,6.57c0.6,0.59,2.72,0.1,3.86-0.46c2.92-1.41,5.52-0.94,8.56-0.05c8.06,2.34,16.27,4.18,24.46,6.03
                c1.04,0.24,2.31-0.85,3.48-0.87c2.16-0.03,4.84-0.48,6.4,0.58c4.99,3.4,9.25,7.04,15.99,7.97c3.32,0.46,7.15,7.04,8.23,11.46
                c1.42,5.82,0.16,12.25,0.4,18.4c0.3,7.84,1.35,15.66,1.36,23.48c0.02,11.99-0.54,23.98-0.92,35.97
                c-0.27,8.66-1.07,17.33-0.91,25.98c0.43,22.49,1.06,44.98,1.98,67.45c0.68,16.5,2.06,32.97,2.88,49.47
                c0.45,8.98,0.44,17.98,0.54,26.98c0.2,18.15,0.75,36.31,0.42,54.46c-0.56,30.48-3.59,60.55-12.59,90.03
                c-5.34,17.48-7.74,35.86-11.26,53.87c-2.53,12.95-5.02,25.93-6.96,38.97c-3.42,22.95-6.38,45.96-9.6,68.93
                c-0.24,1.7-0.96,3.34-1.46,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1007.15,1370.1c-8.33,6.87-18.39,10.14-28.5,12.88c-14.34,3.88-29.08,4.81-43.93,4.14c-10.65-0.49-21.36-1.49-31.96-0.82
                c-11.88,0.75-23.82,2.39-35.44,4.95c-20.11,4.42-40.87,7.68-59.77,15.33c-27.85,11.27-57.13,17.55-85.12,27.98
                c-4.41,1.64-8.79,3.34-14.37,5.47c4.82,2.73,8.46,5.16,12.39,6.96c10.75,4.93,19.4,11.95,26.09,21.95
                c7.27,10.86,19.3,13.68,31.32,16.13c11.39,2.32,22.37-0.25,33.48-2c14.79-2.33,29.63-4.38,44.45-6.53
                c1.65-0.24,3.35-0.62,4.99-0.47c15.01,1.36,29.14-1.99,43-7.43c4.24-1.66,8.95-2.1,13.45-3.1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M936.71,667.62c3,32.48,6.32,64.93,8.85,97.44c1.18,15.26,1.2,30.64,1.01,45.95c-0.13,10.5-2.14,20.98-2.21,31.48
                c-0.1,14.15,0.8,28.31,1.37,42.47c0.41,10.16,1.2,20.31,1.43,30.48c0.36,15.82,0.39,31.64,0.53,47.46c0.01,1-0.31,2-0.48,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1143.52,874.46c-0.33,1.67-0.98,3.34-0.95,5c0.44,28.81,0.97,57.62,1.45,86.44c0.21,12.49,0.54,24.98,0.44,37.47
                c-0.08,9.63-0.73,19.04-3.54,28.59c-2.64,8.96-2.35,18.86-2.79,28.37c-0.33,7.14,0.57,14.33,0.32,21.48
                c-0.36,10.67-1.52,21.31-1.86,31.98c-0.56,17.15-0.38,34.32-1.17,51.45c-0.61,13.18-2.18,26.32-3.4,39.47
                c-0.76,8.17-1.65,16.32-2.48,24.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M743.39,1264.67c9.82,2.33,19.54,5.5,29.5,6.82c20.74,2.74,41.61,4.43,62.42,6.69c25.32,2.75,50.62,5.65,75.93,8.47
                c23.98,2.67,47.96,5.31,71.93,8.01c6.67,0.75,13.32,1.71,19.99,2.43c0.95,0.1,2.21-0.34,2.96-0.98
                c12.07-10.3,20.98-22.8,25.97-37.95c0.13-0.4-0.3-0.99-0.46-1.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M744.39,1005.87c-14.99-0.33-30.2,0.83-44.89-1.46c-12.25-1.91-24.33-2.46-36.54-2.38c-3.84,0.02-7.65,1.87-11.49,2.83
                c-1.65,0.41-3.33,0.96-4.99,0.97c-15.32,0.07-30.64,0.15-45.96-0.05c-3.01-0.04-5.97-1.52-9-1.85
                c-6.31-0.69-12.65-1.44-18.98-1.48c-13.65-0.09-27.31,0.54-40.96,0.32c-9.5-0.15-18.98-1.27-28.48-1.88
                c-2.49-0.16-4.99-0.02-7.49-0.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1102.06,1.61c-9.33-1.26-9.33-1.26-13.5,6.99c-0.1,0.2-0.32,0.34-0.49,0.5c-6.55,6.53-5.35,14.86-5.54,22.98
                c-0.71,30.48-1.63,60.96-2.44,91.43c-0.37,13.82-0.53,27.65-1.04,41.47c-0.88,23.99-1.95,47.97-2.97,71.95
                c-0.28,6.66-0.95,13.32-0.94,19.99c0.04,25.81,0.2,51.63,0.48,77.44c0.12,11.49,0.72,22.98,0.95,34.48
                c0.55,27.81,1.53,55.63,1.32,83.44c-0.12,16.83-2.21,33.64-3.25,50.47c-0.22,3.64,0.41,7.32,0.42,10.99
                c0.04,22.65,0.53,45.31-0.1,67.95c-0.81,28.99-2.54,57.96-3.86,86.94c-0.05,1.16,0,2.33-0.01,3.5
                c-0.33,49.13-0.45,98.26-1.09,147.39c-0.34,26.49-1.61,52.96-2.4,79.44c-0.56,18.65-1.77,37.32-1.33,55.95
                c0.67,28.83,2.53,57.63,3.8,86.44c0.46,10.49,0.71,20.98,1.01,31.48c0.36,12.16,1.18,24.33,0.83,36.47
                c-0.24,8.35-3.02,16.65-3.11,24.99c-0.13,11.72-3.75,22.67-5.75,33.96c-0.54,3.05-1.62,6-2.45,9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M115.98,1138.77c9.49,0.5,18.98,0.96,28.47,1.51c13.99,0.8,27.98,1.6,41.96,2.5c19.82,1.28,39.63,2.65,59.44,3.98
                c14.65,0.99,29.33,2.98,43.95,2.61c8.2-0.21,11.74,4.02,14.27,9.48c2.84,6.14,2.8,13.03-1.8,18.86c-3.32,4.2-8.81,4.68-14.45,1.51
                c-1.89-1.06-3.81-2.15-5.48-3.51c-13.34-10.83-23.34-7.44-33.36,1.67c-6.86,6.23-12.71,13.64-18.56,20.88
                c-11.32,13.99-19.94,29.67-27.5,45.93c-8.72,18.73-14.95,38.31-19.54,58.44c-2.48,10.87-5.28,21.66-7.97,32.48
                c-0.58,2.35-1.32,4.67-1.99,7"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1.59,1045.34c-0.17,4.66-0.47,9.33-0.48,13.99c-0.04,29.98,0.06,59.96-0.11,89.93c-0.03,4.35,1.22,5.78,5.59,5.57
                c11.98-0.56,24.21,0.63,35.92-1.36c26.75-4.55,53.71-1.52,80.46-3.81c6.22-0.53,12.32-2.27,18.5-3.36
                c0.78-0.14,1.66,0.29,2.49,0.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1.59,710.58c-0.17,13.66-0.34,27.31-0.5,40.97c-0.02,1.83,0,3.66,0,5.5c0,76.94,0,153.89,0.02,230.83c0,0.83,0.31,1.67,0.48,2.5"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M129.47,760.55c0,21.65,0.03,43.3-0.01,64.95c-0.02,11.51-1.56,22.72-4.17,34.05c-3.07,13.37-3.59,27.28,0.15,40.91
                c0.26,0.93,0.09,1.99,0.02,2.99c-0.99,14.49-0.25,29.38-3.46,43.36c-3.53,15.37-2.47,30.79-4.45,46.08
                c-0.4,3.09,0.25,6.32,0.42,9.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1065.59,176.98c-5.51,26.56-13.91,52.18-24.95,76.95c-4.94,11.08-13.14,19.7-21.65,27.81c-7.09,6.76-12.28,14.6-16.2,23.2
                c-5.7,12.52-10.37,25.52-11.6,39.41c-0.95,10.65-1.76,21.31-2.5,31.98c-1.55,22.31-3.08,44.63-4.49,66.95
                c-1.1,17.48-2.2,34.96-2.98,52.46c-1.31,29.31-2.02,58.64-3.64,87.93c-1.52,27.51-4.1,54.95-5.82,82.45
                c-1.08,17.29-1.68,34.63-1.99,51.96c-0.25,13.98-1.25,28.2,0.81,41.91c2.31,15.36,1.6,30.73,3.04,46.03
                c1.42,15.13,2.42,30.3,3.55,45.46c1.2,16.15,2.43,32.3,3.47,48.47c0.67,10.48,1.08,20.98,1.52,31.48
                c0.4,9.82,0.68,19.65,1.01,29.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M282.83,671.61c0.2-6.59,5.85-10.88,12.01-9.62c1.87,0.38,4.05-0.76,7.53-1.52c2.65,0.53,3.08,3.4,3,7.15
                c-0.62,30.14-1.7,60.29-1.35,90.43c0.26,22.33,2.38,44.64,3.81,66.95c1.22,18.99,2.39,38,4.03,56.95
                c2.75,31.82,7.82,63.29,14.95,94.43c0.16,0.71,0.64,1.34,0.98,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M665.97,1441.54c-6.16,0.17-12.35,0.79-18.47,0.38c-6.86-0.47-13.63-2.41-20.49-2.76c-13.63-0.71-27.32-1.33-40.95-0.89
                c-7.2,0.23-14.32,2.79-21.49,4.26c-1.31,0.27-2.68,0.58-3.99,0.48c-10.62-0.84-20.76,2.03-30.95,4.14
                c-4.3,0.89-8.25,1.55-12.53-0.06c-8.74-3.28-17.67-3.65-26.97-2.17c-15.33,2.44-30.68,1.08-45.94-1.35
                c-2.61-0.42-5.28-0.44-8.04-0.02c6.74,9.02,15.57,14.54,26.05,16.31c12.2,2.06,24.59,3.31,36.94,4.04
                c14.45,0.86,28.98,1.34,43.44,0.85c5.88-0.2,11.93-2.81,17.44-5.34c17.06-7.83,34.72-9.29,53-6.4c3.81,0.6,7.66,1.01,11.49,1.51"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M127.97,767.04c-3.32-0.26-3.87,1.77-4.03,4.49c-0.58,9.83-1.58,19.64-1.91,29.48c-1.13,33.97-1.37,67.99-3.26,101.91
                c-1.58,28.51-6.41,56.67-15.32,83.94c-3.4,10.41-7.19,20.7-11.06,30.95c-0.55,1.46-2.38,2.99-3.92,3.43
                c-7.26,2.07-14.76,3.36-21.91,5.72c-7,2.3-14.07,4.89-20.43,8.53c-9.47,5.44-19.98,4.91-30.07,6.8
                c-4.62,0.87-9.32,1.37-13.98,2.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1003.65,1374.09c-4.45,8.44-11.88,14-18.92,20.05c-13.44,11.57-29.32,15.99-46.5,18.08c-14.08,1.71-27.98,4.92-41.98,7.31
                c-9.63,1.64-19.36,4.49-28.95,4.15c-11.71-0.42-23.43-3.15-34.95-5.74c-13.37-3.01-26.41-2-39.48,1.16
                c-0.41,0.1-0.68,0.79-1.01,1.21"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1148.01,322.87c-3.48,25.9-2.2,51.94-2.56,77.94c-0.02,1.67-0.58,3.57-1.5,4.96c-4.23,6.37-3.63,13.42-3.49,20.53
                c0.21,10.65-0.19,21.32,0.11,31.97c0.59,20.83-4.61,41.6-0.38,62.71c0.97-0.72,1.94-1.44,3.01-2.24c4.73,5.04,3.86,11.89,5.21,18
                c1.3,5.89,1.83,11.96,2.57,17.97c0.36,2.88-0.19,5.76,4.52,5.74c1.99-0.01,4,2.75,5.99,4.25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M258.85,675.11c-4.65,10.65-12.1,19.35-20.61,26.83c-8.94,7.86-10.96,17.88-11.28,28.63c-0.59,19.48-1.32,38.98-0.91,58.45
                c0.42,19.5,2.07,38.98,3.36,58.46c1.2,18,1.92,36.07,4.17,53.94c2.24,17.77,5.88,35.38,9.5,52.94c0.71,3.46,3.52,7.13,6.4,9.39
                c8.29,6.51,11.6,15.32,12.79,25.14c0.97,7.97,4.66,13.91,11.06,18.49c7.16,5.12,13.38,11.92,22.99,12.46
                c6.66,0.38,13.33,0.58,19.98,1.03c11.21,0.76,22.12,2.97,32.46,7.49c5.53,2.41,11.01,4.95,16.48,7.51
                c0.94,0.44,1.68,1.3,2.51,1.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1012.64,188.97c-6.43,11.75-13,23.39-21.87,33.57c-1.13,1.3-0.78,3.9-1.11,5.9c-2.83,16.99-6.32,33.9-8.3,50.98
                c-1.88,16.22-2.24,32.62-3.17,48.94c-1.38,24.15-2.78,48.29-3.96,72.45c-0.59,12.14-0.69,24.31-1.05,36.47
                c-0.65,22.48-1.26,44.97-2.09,67.45c-0.09,2.53-0.9,5.61-2.53,7.37c-3.68,3.98-9.23,2.65-10.99-3.97
                c-1.68-6.31-6.25-3.74-8.74-5.8c-0.64,2.33-1.12,4.11-1.61,5.9c-2.16-1.17-4.82-1.9-6.35-3.63c-1.36-1.55-1.33-4.26-2.2-6.34
                c-0.54-1.29-1.61-2.35-2.44-3.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M283.33,672.61c0.33,1.83,0.95,3.66,0.96,5.5c0.06,24.81-0.22,49.63,0.12,74.44c0.32,23.82,1.59,47.63,1.79,71.45
                c0.1,12.32-0.87,24.68-1.93,36.97c-2.09,24.17-4.48,48.32-6.96,72.45c-1.62,15.68-3.58,31.32-5.47,46.97
                c-0.71,5.84-1.59,11.67-2.52,17.48c-0.17,1.05-0.95,2.01-1.45,3.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M988.66,225.94c-15.63,16.67-31.58,32.99-41.12,54.39c-4.33,9.72-5.57,19.85-6.77,30.06c-2.73,23.24-6.03,46.43-5.6,69.94
                c0.49,27.14,0.86,54.29,0.97,81.44c0.05,13.16-1.26,26.34-0.79,39.47c0.8,22.34,2.66,44.63,3.78,66.96
                c0.43,8.64-0.05,17.32,0.09,25.98c0.38,23.87,3.64,47.44,7.5,70.94c0.42,2.54,1.3,5,1.96,7.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1134.53,2.11c0,10.66-0.09,21.32,0.05,31.98c0.05,4.17,1.04,8.35,0.85,12.49c-0.29,6.35-1.2,12.67-1.93,18.99
                c-0.16,1.35-0.92,2.66-0.93,4c-0.39,29.48-0.8,58.96-0.98,88.43c-0.05,8.49,0.63,16.99,0.91,25.48c0.04,1.16-0.3,2.33-0.47,3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1098.56,751.05c1,7.16,2.3,14.3,2.94,21.49c1.52,17.3,2.86,34.63,4.02,51.96c1.02,15.31,1.97,30.63,2.46,45.97
                c0.5,15.64,0.33,31.31,0.61,46.96c0.15,8.33,0.63,16.65,0.97,24.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M253.85,1492.51c-3.33-0.17-6.66-0.33-9.99-0.5c-19.15-1-38.31-1.83-57.44-3.05c-13.51-0.87-26.98-2.21-40.46-3.48
                c-4.68-0.44-9.33-1.26-13.99-1.99c-0.86-0.13-2-0.39-2.45-1.01c-5.83-7.82-14.85-7.46-23.04-8.89
                c-12.94-2.26-25.95-4.16-38.96-6.03c-8.3-1.19-16.63-2.25-24.98-2.98c-13.47-1.18-26.97-2.05-40.46-3.05"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M810.83,61.56c-2.16,1.17-4.33,2.33-6.79,3.66c-3.7-3.12-7.55-5.34-11.81-0.26c-0.85,1.02-3.27,0.61-4.87,1.13
                c-3.2,1.05-6.35,2.28-9.49,3.49c-0.4,0.15-0.97,0.65-0.96,0.97c0.04,2.34,0.05,4.98-3.04,4.88c-2.52-0.08-5.66-0.49-7.35-2.04
                c-3.14-2.88-8.57-2.39-10.27-7.29c-0.18-0.51-2.54-0.93-3.31-0.46c-5.09,3.12-8.73,0.05-12.55-2.58
                c-0.99-0.68-2.05-1.24-2.89-1.74c-2.7,1.08-5.33,2.77-8.11,3.07c-3.71,0.4-6.4-1.84-8-5.33c-1.95-4.25-6.27-4.76-9.53-1.58
                c-1.44,1.4-4.24,3.34-5.3,2.8c-2.26-1.14-3.87-3.67-5.6-5.75c-1.29-1.56-2.07-3.8-3.66-4.74c-0.68-0.4-3.19,1.49-4.29,2.82
                c-2.95,3.57-5.22,1.48-7.55-0.56c-2.81-2.47-4.79-6.34-9.5-5.48c-0.67,0.12-1.33,0.33-1.84,0.45c-1.79-2.29-3.53-4.52-5.23-6.71
                c-4.57,2.31-8.78,5.26-13.31,1.62c-1.12-0.9-0.98-3.37-1.6-5.86c-9.12-4.48-7.54-2.97-9.59,3.3c-7.01,0.62-13.84,0.24-19.92-4.26
                c-0.91-0.67-2.35-1.12-3.45-0.98c-4.81,0.61-7.72-2.6-8.25-6.1c-0.93-6.21-4.03-3.76-7.26-3.04c-1.39,0.31-3.57,0.31-4.37-0.52
                c-2.82-2.94-5.95-2.07-9.09-1.37c-6.5,1.44-12.45-1.06-14.82-7.09c-0.65-1.64,0.76-4.1,1.4-7.01c-1.26-1.71-3.34-4.56-5.83-7.94
                c-4.16,4.8-5.85,13.36-14.46,7.2c-1.57,2.17-2.73,4.9-6.4,2.52c-1.37-0.89-4.32,0.13-6.34,0.86c-6.2,2.27-10.71-1.43-12.66-5.72
                c-2.15-4.74-5.04-4.83-8.84-4.83c-25.98,0-51.95,0-77.93,0c-21.65,0-43.29-0.01-64.94,0.02c-1.17,0-2.33,0.31-3.5,0.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1113.55,2.11c-0.33,14.49-0.53,28.98-1.07,43.47c-0.19,5.01-1.58,9.98-1.8,15c-0.14,3.14,1.17,6.31,1.3,9.49
                c0.15,3.82-0.32,7.66-0.41,11.49c-0.21,8.16-0.61,16.33-0.44,24.48c0.18,8.83,1.08,17.65,1.39,26.48
                c0.47,13.49,0.7,26.98,1.04,40.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1046.61,1266.67c-4.18,9.18-7.5,18.76-14.42,26.54c-6.26,7.03-14.81,9.34-23.05,12.42c-8.22,3.07-15.43,7.69-21.44,14.04
                c-6.36,6.71-13.08,13.03-22.05,15.83c-5.44,1.7-11.29,2.07-16.94,3.14c-8.34,1.58-16.85,2.91-21.49,11.48
                c-2.73,5.04-0.12,10.54,5.5,11.49c4.97,0.85,18.33-3.31,22.54-7.05c-3.8-1.42-7.57-2.83-11.35-4.24c0-0.58,0-1.16,0-1.74
                c5.09-1.32,10.12-3.24,15.3-3.82c8.19-0.92,15.94-3.84,21.24-9.35c13.01-13.52,27.93-23.32,45.65-29.35
                c14.72-5.01,18.94-17.81,21.48-31.4c2.12-11.3,3.79-22.69,6.13-33.95c1.03-4.96,3.22-9.68,4.89-14.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M576.55,1067.82c-7.66,3.33-15.72,4-23.98,4.05c-34.47,0.21-68.94,0.37-103.4,1.07c-14.33,0.29-28.64,1.87-42.96,2.92
                c-0.36,0.03-0.67,0.63-1.21,1.17c4.51,4.95,10.7,5.85,16.71,6.7c7.28,1.02,14.64,1.4,21.96,2.12c0.53,0.05,1,0.63,1.5,0.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M753.38,1107.79c-12.99-0.17-25.98-0.58-38.96-0.42c-11.49,0.14-23.02,0.48-34.46,1.54c-12.2,1.13-24.33,3.18-36.47,4.9
                c-2.03,0.29-4.01,0.95-6.57,1.57c5.75,6.19,13.15,7.87,20.56,9.36c8.96,1.8,17.98,3.36,26.97,5.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M190.41,699.59c9.65,6.43,15.55,15.46,15.84,26.98c0.51,20.31,0.44,40.64,0.07,60.95c-0.57,31.48-2.1,62.95-2.25,94.43
                c-0.1,20.98,2.08,41.97,2.12,62.95c0.03,15.12-0.16,30.56-5.91,44.92c-4.44,11.08-12.2,19.32-23.28,24.7
                c-6.62,3.22-13.35,7.27-18.32,12.55c-5.96,6.32-12.77,7.12-20.22,6.69c-16.18-0.94-30.62,3.74-43.97,12.55
                c-7.72,5.09-16.52,7.06-25.48,8.45c-0.6,0.09-1.32-0.61-1.99-0.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1149.51,2.11c-0.67,15.49-1.14,30.99-2.06,46.46c-0.69,11.68-1.84,23.33-3.02,34.97c-0.21,2.06-1.66,3.98-2.39,6.01
                c-1.55,4.31-3.95,8.57-4.34,13c-1.15,13.28-1.41,26.64-2.21,39.95c-0.44,7.18-1.29,14.33-1.96,21.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1.59,56.07c-0.17,1.67-0.48,3.33-0.48,5C1.09,105.03,1.09,149,1.09,192.97c0,7.59,4.2,10.67,10.91,7.33
                c2.56-1.28,4.55-1.28,7.09-0.89c5.66,0.88,5.75,0.88,10.45-1.99c2.53-1.55,4.04-1.64,5.06,1.53c1.07,3.29,3.03,4.81,6.49,2.56
                c0.59-0.38,1.89-0.4,2.42,0c4.5,3.37,9.38,4.5,15.04,4.18c2.08-0.12,4.34,2.4,6.48,3.77c3.67,2.36,7.05,5.05,11.62,1.14
                c1.32-1.13,4.2-0.44,7.27-0.65c3.44,3.41,7.63,7.43,14.18,3.16c1.06-0.69,3.54,0.91,5.39,1.32c7.73,1.7,15.58,2.04,23.47,2.13
                c2.27,0.03,5.36-4.32,6.99,0.88"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1129.53,2.11c-0.67,14.82-1.06,29.66-2.15,44.46c-0.36,4.9-2.84,9.61-3.76,14.52c-1.31,6.94-1.84,14.02-3.12,20.96
                c-3.42,18.52-2.45,37.33-3.92,55.97c-0.52,6.65-0.65,13.32-1.06,19.98c-0.1,1.68-0.63,3.33-0.96,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M749.39,1446.54c6.83,1.67,13.69,3.19,20.47,5.02c17.51,4.75,34.8,10.43,52.52,14.19c12.74,2.7,25.88,1.34,38.93-0.54
                c13.03-1.88,26.27-2.45,39.44-3.03c3.95-0.18,7.99,1.51,11.98,2.34"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M230.88,1083.81c0.96,0.58,1.92,1.15,4.39,2.64c-6.57,1.97-12.13,4.57-17.9,5.16c-10.25,1.04-20.63,1.26-30.95,1.09
                c-10.66-0.18-21.31-1.31-31.97-1.87c-14.32-0.75-28.63-1.46-42.96-1.99c-12.1-0.45-23.92-2.39-35.47-6.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M831.81,487.75c-19.98-14.82-39.91-29.72-59.96-44.44c-10.79-7.92-23.65-11.35-35.92-16.08c-18.41-7.09-36.65-14.61-54.99-21.88
                c-1.55-0.62-3.32-0.7-4.98-1.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M969.68,512.73c-0.33,7.99-0.41,16.01-1.05,23.98c-1.32,16.17-2.53,32.37-4.56,48.45c-1.94,15.39-4.86,30.66-7.38,45.98
                c-1.81,11-3.61,21.99-5.54,32.97c-0.3,1.73-1.28,3.34-1.95,5.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1086.57,1142.76c-1,4.5-2.32,8.95-2.94,13.5c-1.68,12.3-3.53,24.61-4.44,36.97c-1.28,17.29-2.2,34.63-2.42,51.96
                c-0.1,7.98,2.15,15.96,2.66,23.99c0.23,3.61-0.51,7.62-1.97,10.93c-4.33,9.79-4.41,20.27-5.84,30.54
                c-1.28,9.14-1.76,18.44-3.65,27.45c-3.37,16.08-7.55,32-11.31,48c-0.21,0.91,0.28,1.99,0.45,2.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M309.3,1069.82c9.99-0.5,19.99-0.83,29.97-1.55c7.85-0.57,15.72-1.26,23.46-2.59c4.13-0.71,7.9-3.47,12.04-4.23
                c7.91-1.45,15.94-2.41,23.96-3.04c7.13-0.57,14.32-0.31,21.47-0.64c2.35-0.11,4.79-0.63,6.97-1.51c2.96-1.2,2.93-2.64,0.06-4.47
                c-17.43-11.11-36.21-18.17-56.98-19.43c-3.83-0.23-7.66-0.34-11.49-0.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M834.81,563.69c-0.33,3.66-0.68,7.33-1,10.99c-0.68,7.83-1.32,15.66-2.02,23.48c-0.09,1.03-0.39,2.05-0.66,3.41
                c-5.88-2.94-11.43-5.52-16.75-8.5c-1.53-0.86-2.76-2.74-3.48-4.43c-2.12-4.96-6.09-6.59-11.04-7.57
                c-6.25-1.24-12.53-1.97-18.61-4.64c-5.99-2.64-12.96-2.97-19.34-4.85c-3.67-1.08-7.28-2.84-10.47-4.97
                c-6.76-4.52-13.93-5.87-22.04-5.67c-5.63,0.14-11.31-2.13-16.98-3.23c-3.14-0.61-6.33-0.97-9.48-1.53
                c-3.34-0.6-6.71-1.13-9.98-2.02c-6.2-1.69-12.39-3.45-18.47-5.52c-3.94-1.34-7.95-2.84-11.46-5.02
                c-8.51-5.29-16.93-10.15-27.53-10.14c-3.16,0-6.33-2.77-9.49-4.26c-3.16-1.5-6.23-3.95-9.51-4.3c-7.08-0.75-12.97-3.64-17.35-8.78
                c-5.67-6.66-13.56-7.91-21.06-10.49c-4.34-1.49-8.36-3.89-12.51-5.91c-0.87-0.42-1.62-1.31-2.51-1.44
                c-7.48-1.09-14.96-2.24-22.48-2.97c-3.97-0.39-5.39,2.11-5.01,5.92c0.59,5.99,1.09,11.98,1.48,17.99
                c0.11,1.65-0.29,3.33-0.46,4.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M938.71,677.11c2.5,4.83,4.51,10.01,7.65,14.38c2.43,3.38,6.29,5.73,10.83,9.69c0,2.91-0.04,7.91,0.01,12.91
                c0.14,13.49,0.05,26.99,0.56,40.47c0.74,19.66,1.88,39.31,2.97,58.96c0.26,4.68,0.96,9.33,1.46,13.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M416.7,1130.27c-7.49,0.87-14.4-1.48-21.51-3.38c-13.88-3.72-27.94-6.78-41.93-10.1c-0.83-0.2-1.74-0.64-2.49-0.46
                c-11.12,2.73-21.29-0.86-31.49-4.51c-4.26-1.52-8.61-2.85-12.99-3.98c-3.94-1.02-9.76,2.04-10.98,5.45
                c-1.25,3.5,1.34,9.19,6.02,9.79c17.18,2.18,20.5,16.24,25.96,28.68c5.52,12.58,3.82,25.88,3.92,38.98
                c0.04,5.66-0.5,11.33-0.99,16.98c-0.17,2.03-1.46,4.04-1.33,5.99c0.2,2.86,1.21,5.67,1.88,8.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M151.45,1497.5c-9.66-2.33-19.31-4.68-28.97-6.99c-5.11-1.22-9.52-3.73-14.15-6.2c-6.35-3.38-13.71-5.9-20.84-6.56
                c-21.42-1.99-42.94-2.85-64.42-4.27c-7.01-0.46-13.99-1.3-20.98-1.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1144.52,1288.16c-0.33,4.16-1.03,8.34-0.93,12.49c0.33,14.99,1.63,30,1.23,44.96c-0.37,13.68-1.31,27.56-4.08,40.91
                c-3.44,16.57-9.01,32.71-13.72,49.02c-0.68,2.37-1.64,4.67-2.48,7"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M248.36,677.11c-5.24,9.3-11,18.06-23.47,21.94c0-7.93-0.14-15.44,0.06-22.94c0.08-2.85,0.68-4.98-3.63-3.71
                c-1.26,0.37-3.22-1.89-4.95-2.75c-1.07-0.53-2.85-1.34-3.37-0.9c-1.04,0.89-2.02,2.53-2.02,3.86c0.01,11.82,0.19,23.65,0.45,35.47
                c0.05,2.5,0.86,4.99,0.91,7.5c0.14,6.16-0.23,12.34,0.1,18.48c0.84,16,2.33,31.97,2.8,47.97c0.28,9.64-1.03,19.31-1.26,28.98
                c-0.08,3.48,0.91,6.99,1.41,10.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M670.96,409.81c1.33,1.33,2.42,3.22,4.03,3.91c22.59,9.78,45.75,18.4,67.77,29.32c27.23,13.5,52.47,30.23,71.1,55.17
                c3.39,4.54,7.29,8.69,10.95,13.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M703.93,1159.75c-5,0.67-9.97,1.72-14.99,1.93c-20.14,0.83-40.3,1.16-60.44,2.17c-9.39,0.47-18.72,2.06-28.09,3.69
                c6.27,4.5,13.27,7.04,20.6,7.06c20.31,0.06,40.64-1.13,60.94-0.67c15.67,0.36,31.31,2.43,46.96,3.82
                c6.17,0.55,12.32,1.31,18.48,1.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M789.35,989.38c0.17-4.5-0.3-9.15,0.78-13.42c0.49-1.92,3.95-4.17,6.23-4.37c7.57-0.64,9.1-5.51,10.03-11.68
                c2.67-17.68,5.97-35.27,8.31-52.99c1.77-13.41,3.07-26.95,3.46-40.46c0.52-17.74,1.01-35.35,3.78-53.01
                c2.17-13.87,0.78-28.27,1.44-42.41c0.69-15,2.06-29.97,2.88-44.97c0.46-8.48,0.3-16.99,0.57-25.48
                c0.79-24.65,1.79-49.29,2.42-73.95c0.32-12.33-2.4-15.59-13.87-20.61c-3.16-1.39-3.64-0.46-4.06,2.13
                c-3.15,19.49-6.4,38.96-9.44,58.46c-0.95,6.12-1.27,12.33-2.1,18.47c-0.3,2.21-1.27,4.34-1.94,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M512.61,1275.67c-7.33-1.67-14.59-3.72-21.99-4.9c-10.6-1.7-21.29-2.81-31.96-4.06c-4.14-0.49-8.32-0.77-12.49-1.01
                c-7.16-0.4-14.39-0.18-21.47-1.12c-16.46-2.19-33.34-0.92-49.46-5.9c-2.52-0.78-5-1.66-7.5-2.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M212.39,814.01c-0.33,5-1.09,10.01-0.91,14.99c0.4,11.33,1.62,22.65,1.8,33.98c0.17,10.65-0.96,21.32-0.78,31.97
                c0.19,11.67,1.22,23.32,1.91,34.97c0.04,0.67,0.12,1.49,0.51,1.98c4.75,5.88,3.51,12.85,3.32,19.51
                c-0.06,2.19-2.06,4.27-2.81,6.51c-1.48,4.45-2.96,8.92-3.96,13.49c-0.75,3.41-0.41,7.05-1.18,10.45c-0.7,3.1-2.16,6.05-3.41,9.01
                c-3.52,8.33-9.76,14.72-15.49,21.48c-1.06,1.25-2.16,3.1-3.51,3.39c-12.12,2.58-19.04,13.61-29.91,18.18
                c-2.72,1.14-4.85,3.71-7.59,4.76c-1.84,0.71-4.27,4.67-6.43,0.16c-0.67,0.83-1.33,1.67-2,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M649.98,1498.5c5-2.83,9.74-6.31,15.04-8.36c10.65-4.12,21.4-8.25,32.47-10.92c12.81-3.09,25.77-1.31,38.42,2.27
                c3.93,1.11,7.92,2,11.88,2.99c0.34-0.46,0.68-0.92,1.03-1.38c-4.97-4.86-9.65-10.05-14.96-14.51
                c-15.57-13.09-33.66-18.99-53.88-16.41c-12.64,1.61-23.45,7.97-31.89,17.43c-8.97,10.05-19.51,17.64-31.53,23.47
                c-2.74,1.33-5.03,3.59-7.53,5.42"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M504.62,1238.19c2.66,1.33,5.33,2.66,9.58,4.79c-6.41,1.43-11.44,3.02-16.58,3.6c-6.94,0.78-13.97,0.83-20.97,1.1
                c-9.82,0.38-19.66,0.51-29.47,1.05c-15.49,0.85-30.99,1.71-46.45,3.05c-9.7,0.84-19.32,2.57-28.98,3.91"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M602.03,243.93c4.01,3.46,8.42,4.78,13.61,2.57c-0.36-1.76-0.7-3.42-1.18-5.75c4.37,1.26,8.76-2.83,13.15,1.06
                c0.76,0.67,3.18,0.21,4.28-0.55c0.62-0.42,0.24-2.52,0.08-3.83c-0.22-1.85-0.59-3.68-0.98-5.5c-0.71-3.33-1.6-6.45-5.98-6.52
                c-4.84-0.08-2.02-4.47-3.77-7c-6.91,1.71-10.95-2.47-14.2-7.42c2.35-2.55,4.42-4.8,6.49-7.05c-0.76,0.76-1.52,1.53-2.28,2.29
                c2.75,1.57,5.38,3.59,8.34,4.42c0.85,0.24,2.66-2.98,4.18-4.79c3.4,1.01,6.68,2.86,10.86,0.3c2.84-1.74,10.54,3.58,12.85,7.77
                c2.84,5.14,6.44,7.09,12.37,6.45c-0.45,5.37,3.23,6.7,7.05,5.38c7.69-2.65,11.87,1.37,16.09,6.61c3.61,4.49,5.07,4.26,8.44-0.46
                c0.33,0,0.81-0.15,0.98,0.02c5.55,5.41,13.02,3.27,19.51,4.94c4.17,1.08,8.05,1.66,9.85,6.9c0.64,1.86,4.21,6.96,8.81,2.31
                c0.66-0.66,3.78,1.57,5.82,1.72c3.52,0.26,5.78,1.83,8.1,4.49c3.46,3.96,6.61,3.18,11.37-1.9c1.17,0.17,2.33,0.4,3.5,0.49
                c3.32,0.26,3.56-0.19,2.54-3.5c-0.43-1.41-0.42-2.95-0.57-4.2c4-1.69,6.75-1.22,10.14,1.03c5.54,3.68,12.06,5.85,17.81,9.27
                c5.42,3.23,10.53,7.04,15.49,10.96c4.06,3.2,7.38,7.41,11.6,10.34c9.46,6.56,10.62,16.39,12.02,26.56
                c2.76,20.03,7.4,39.55,18.36,56.99c1.82,2.9,2.7,6.74,5.14,8.84c8.18,7.05,5.86,16.29,6.55,25.11c0.33,4.24,1.81,8.75,4,12.4
                c2.54,4.21,6.17,4.17,9.8,1.1c1.71-1.45,3.34-2.99,5-4.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M674.46,1340.62c-9.32-2.33-18.56-5.14-27.99-6.88c-13.24-2.45-26.59-4.37-39.95-6.02c-9.1-1.13-18.32-1.24-27.46-2.13
                c-11.67-1.14-23.32-2.61-34.97-3.96c-2.5-0.29-5-0.66-7.49-0.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M332.78,998.37c8.83,4.5,18.18,8.23,26.27,13.79c4.59,3.15,9.03,2.18,13.21,2.42c17.45,0.99,34.95,1.22,52.43,1.21
                c20.65-0.02,41.29-0.6,61.94-0.92c2-0.03,3.99,0,6.95,0c-1.69,1.76-2.67,2.79-4.63,4.85c2.02,1.48,3.85,2.81,5.68,4.15"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M829.31,944.41c0,8.16,0,16.32,0,24.48c0,1.17,0.02,2.33,0,3.5c-0.33,17.32-0.63,34.64-1.06,51.96c-0.04,1.51-0.55,3.34-1.5,4.44
                c-5.77,6.65-7.43,14.6-7.94,23.04c-0.94,15.49-1.95,30.98-3.03,46.46c-0.11,1.53-0.95,3-1.45,4.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M177.93,833.99c0.33,8.49,0.44,17,1.06,25.48c0.75,10.18,2.2,20.31,2.88,30.49c1.51,22.47,2.83,44.96,4.01,67.45
                c0.73,13.86,1.86,27.63,6.06,40.96c0.17,0.55,0.65,1.01,0.98,1.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M827.81,966.89c-0.67,1-1.75,1.93-1.94,3.01c-2.59,14.97-4.89,30-7.65,44.94c-0.88,4.78-3.39,9.25-4.28,14.03
                c-1.29,6.91-1.83,13.96-2.6,20.96c-0.89,8.15-1.71,16.32-2.5,24.48c-0.86,8.82-1.67,17.65-2.5,26.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M523.6,1092.8c-8.49-0.5-16.99-0.94-25.48-1.51c-16.49-1.12-32.95-2.88-49.46-3.32c-15.63-0.42-31.3,0.42-46.95,0.89
                c-4.05,0.12-8.09,0.93-12.08,1.94c7.12,4.98,15.19,5.3,23.08,5.9c6.29,0.48,12.65,0.03,18.97,0.14c1.67,0.03,3.33,0.62,5,0.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M39.06,314.38c-4-1-8.01-1.94-11.98-3.02c-1.87-0.51-3.78-1.1-5.48-2.01c-4.72-2.55-9.26-5.46-14.04-7.89
                c-3-1.53-6.45,0.95-6.44,4.43c0.14,42.97,0.37,85.94,0.45,128.9c0.03,15.99-0.32,31.98-0.44,47.96c-0.01,1.66,0.31,3.33,0.48,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M744.89,760.55c5.88-14.01,16.14-12.55,24.95-4.96c7.94,6.83,7.75,10.59-1.07,16.31c-1.83,1.19-4.9,0.64-7.4,0.59
                c-1.67-0.03-3.33-0.86-5-0.89c-2.66-0.05-5.33,0.29-7.99,0.44c-0.83,0.05-2.19-0.31-2.42,0.06c-2.38,3.87-5.33,1.62-7.5,0.33
                c-3.64-2.16-4.6-0.4-5.6,2.61c-2.02,6.13-3.74,7.29-9.93,5.39c-4.36-1.33-6.66-1.25-7.13,4.1c-0.41,4.65-4.38,5.08-7.86,4.39
                c-3.46-0.69-5.22-0.38-5.1,3.62c0.03,0.98-0.76,2.73-1.44,2.88c-6.81,1.47-12.11,5.81-18,9.03c-1.03,0.56-2.63,0.18-3.95,0.06
                c-2.64-0.25-3.87,0.45-4.2,3.5c-0.18,1.63-2.25,3.32-3.84,4.45c-2.39,1.69-5.69,1.79-6.28,6.02c-0.2,1.46-4.75,1.95-6.51,3.69
                c-1.25,1.24-0.53,4.75-1.8,5.61c-1.48,1.01-4.27,0.65-6.32,0.16c-6.17-1.5-8.72-0.64-10.4,5.11c-1.02,3.5-6.13,4.86-4.6,9.46
                c-2,0.33-3.99,0.79-6,0.97c-2.72,0.24-5.04-0.44-6.86,3.35c-1.27,2.65-5.06,4.24-5.26,8.16c-0.02,0.38-1.96,0.51-2.84,1.05
                c-1.1,0.67-2.86,1.61-2.88,2.46c-0.09,4.38-2.32,4.39-5.65,3.54c-5.07-1.31-9.07,1.73-11,7.94c-0.53,1.71-0.91,3.72-2.06,4.93
                c-3.02,3.16-6.25,6.04-4.92,11.07c-5.26,2.57-4.94,10.44-9.64,12.06c-4.18,1.45-6.38,2.99-7.34,6.92"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1011.14,187.47c5.45,0.69,10.34,0.93,12.64-5.94c1.43-4.25,5.83-2.4,8.81-1.9c3.19,0.53,2.45,3.31,2.08,5.85
                c-4.77,33.18-14.34,65-27.1,95.89c-4.69,11.35-10.22,22.33-20.73,31.59c0.31-5.6,0.24-10.13,0.88-14.57
                c2.35-16.18,4.91-32.32,7.44-48.47c1.78-11.33,3.68-22.65,5.44-33.98c0.17-1.11-0.29-2.32-0.45-3.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M849.79,258.42c-5.62,19.22-10.14,38.59-3.42,58.43c4.43,13.09,12.46,24.24,20.94,34.97c11.13,14.08,22.5,27.97,34.32,42.61
                c3.97-3.56,6.79-6.08,9.61-8.61"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1118.54,322.87c0.33,2,0.96,4,0.95,6c-0.07,12.99-0.14,25.98-0.48,38.97c-0.54,20.49-1.61,40.97-1.77,61.46
                c-0.05,5.92,2.27,11.85,3.77,19.03c2.25-3,3.39-4.52,4.53-6.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M934.71,1034.34c1,8.83,2.07,17.65,2.97,26.48c0.49,4.81,0.92,9.65,0.99,14.49c0.41,26.98,0.71,53.96,1.01,80.94
                c0.01,0.66-0.31,1.33-0.48,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M118.98,1080.31c-14.65-1.5-29.34-2.73-43.95-4.57c-14.36-1.81-28.65-4.22-42.97-6.44c-4.52-0.7-9.08-1.35-13.46-2.57
                c-3.32-0.93-6.4-2.73-10.25-4.43c2-2.19,2.96-3.25,4.61-5.05c-4.08-0.91-7.48-1.66-10.87-2.41"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M544.08,281.9c4.23,13.2,10.11,25.58,20.06,35.39c9.33,9.19,15.54,20.12,20.42,32.05c6.97,17.03,20.57,27.25,36.48,34.92
                c3.96,1.91,8.3,3.06,12.46,4.56"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M251.86,1340.12c-0.67,6.83-1.57,13.64-1.92,20.49c-0.25,4.81,0.05,9.67,0.46,14.48c0.85,9.84,1.77,19.68,3.01,29.48
                c1.27,10.02,3.05,19.98,4.42,29.99c1.83,13.45,3.48,26.92,5.37,41.7c2.89-1.02,6.17-2.18,9.45-3.33
                c-0.04-0.54-0.08-1.08-0.13-1.62c-3.06-0.76-6.12-1.52-9.18-2.28"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M452.67,1064.82c9.18,3.2,18.59,2.07,27.97,1.49c1.67-0.1,3.33-0.48,5-0.47c27.97,0.3,55.95,0.62,83.92,1.04
                c1.34,0.02,2.67,0.94,4,1.44"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M797.34,688.1c-0.17-2.5-0.25-5-0.51-7.49c-0.96-9.16-1.99-18.32-2.98-27.48c-0.67-6.16-1.25-12.33-2.03-18.48
                c-0.51-4.02-1.21-8.02-2.06-11.98c-0.15-0.71-1.46-1.18-3.38-2.62c-1.28,6.77-2.73,12.64-3.46,18.6
                c-1.26,10.29-2.04,20.65-3.12,30.96c-0.09,0.88-0.95,1.67-1.44,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M241.37,1345.11c0,5.83,0.28,11.67-0.06,17.48c-0.6,10.19,1.46,19.77,4.26,29.57c2.55,8.93,2.59,18.57,3.8,27.89
                c1.63,12.66,3.43,25.3,4.94,37.98c0.65,5.46,0.72,10.99,1.05,16.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M898.75,140.5c0.84,26.26,6.46,51.54,16.05,75.92c4.97,12.63,10.56,25.02,15.96,37.48c0.8,1.84,2.11,3.45,3.61,5.87
                c6.89-12.61,13.57-24.2,13.56-38.83c-0.02-14.82,1.74-29.65,2.83-44.46c0.1-1.36,0.95-2.67,1.45-4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M944.7,1037.84c1,5.16,2.68,10.3,2.87,15.49c0.66,17.64,0.82,35.3,1.11,52.96c0.16,9.71,2.24,19.48,8.84,26.15
                c5.6,5.67,7.71,11.76,9.23,18.8c0.17,0.77,1.26,1.35,1.93,2.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M293.82,1283.66c-1.83,2.66-3.66,5.33-5.5,7.99c-2.99,4.33-6.1,8.59-8.96,13.01c-3.29,5.08-6.11,10.48-9.59,15.42
                c-3.33,4.73-7.09,9.2-10.99,13.48c-2.13,2.34-4.86,4.16-7.44,6.04c-5.7,4.15-11.92,5.59-18.99,4.11
                c-5.05-1.06-10.1,2.54-10.36,7.41c-0.3,5.47,0.32,10.99,0.37,16.48c0.08,8.66,0.09,17.32,0,25.98
                c-0.07,7.33-0.38,14.66-0.47,21.98c-0.21,17.32-0.39,34.64-0.4,51.96c0,1,2.18,2.94,2.3,2.86c1.96-1.3,5.32-2.83,5.35-4.36
                c0.36-19.15,0-38.3,0.32-57.45c0.21-12.5-0.16-25.28,2.38-37.38c1.89-9,0.76-17.79,2.03-26.58"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M214.39,538.71c-1.7-4.74-1.34-9.52-1.18-14.5c0.49-14.97,1.03-30.03-0.07-44.94c-1.03-13.98-8.4-25.65-17.71-36.02
                c-4.81-5.36-9.31-11-14.04-16.44c-1.15-1.32-2.64-2.36-3.97-3.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M133.47,900.44c0.67,6.99,1.56,13.98,1.95,20.99c1.12,19.98,1.95,39.97,3.08,59.95c0.62,11,1.61,21.99,2.49,32.98
                c0.08,1.02,0.63,2,0.97,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M949.7,1424.56c-17.48,5.73-34.35,14.21-53.44,12.91c-10.52-0.72-20.97-2.45-31.48-3.36c-10.52-0.92-21.22-4.03-31.45,1.48
                c-1.12,0.6-2.01,1.63-3.01,2.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M515.61,1275.67c-8.94,4.58-18.65,5.96-28.47,7.02c-11.33,1.23-22.65,2.66-33.97,3.95c-1.31,0.15-2.66-0.03-3.99,0.03
                c-13.99,0.65-27.98,1.26-41.96,2.03c-3.85,0.21-7.66,0.96-11.49,1.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M867.78,182.97c8.12,17.25,18.99,32.57,31.25,47.15c4.51,5.36,6.76,12.71,9.65,19.33c3.8,8.7,8.88,17.07,9,26.96
                c0.09,7.49-0.28,14.99-0.45,22.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M179.92,1487.51c-4.16-3.16-7.98-7.04-12.59-9.29c-4.36-2.12-9.52-2.72-14.39-3.66c-9.3-1.8-18.68-3.2-27.95-5.1
                c-5.31-1.09-10.09-3.43-13.01-8.45c1.92-1.74,3.84-3.47,6.55-5.93c-6.14-0.95-11.33-2.33-16.54-2.45
                c-25.64-0.55-51.28-0.94-76.93-0.99c-7.66-0.02-15.32,1.23-22.98,1.89"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1030.62,1380.59c-3.63,8.95-9.81,15.92-17.54,21.4c-4.85,3.44-10.17,6.25-15.45,9.04c-10.93,5.78-22.15,11.03-32.9,17.11
                c-11.74,6.64-24.48,9.52-37.5,11.96c-14.7,2.75-29.32,5.94-43.97,8.94c-4,0.82-7.99,1.66-11.99,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M708.93,459.77c6.99,6.99,13.59,14.45,21.08,20.87c9.34,8,21.18,10.77,32.86,13.65c8.55,2.11,16.98,4.66,25.5,6.91
                c1.6,0.42,3.58,1,4.94,0.43c8.23-3.44,15.44,1.03,23.02,2.6c0.83,0.17,1.66,0.33,2.5,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M652.48,1258.18c-4.83,0.17-9.66,0.46-14.49,0.48c-21.31,0.05-42.63-0.3-63.94,0.16c-10.84,0.23-21.65,1.84-32.47,2.92
                c-0.71,0.07-1.34,0.94-2,1.44"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1.59,553.7c-0.17,1.5-0.48,3-0.48,4.5c-0.03,31.31-0.02,62.62-0.02,93.93c0,3.98-0.62,8.33,6.06,5.67
                c1.7-0.68,4.64,0.26,6.35,1.43c5.35,3.64,10.33,3.26,15.62,0c0.89-0.55,2.89-0.2,3.89,0.47c6.15,4.1,12.14,8.52,18.22,12.63
                c7,4.73,15.46,7.28,23.29,10.78"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M636.49,1306.64c-3.83,0.67-7.64,1.48-11.49,1.97c-13.65,1.73-27.3,3.44-40.96,5c-9.97,1.14-19.99,1.9-29.96,3.07
                c-6.36,0.75-12.64,2.13-19,2.88c-3.45,0.41-6.98,0.07-10.48,0.07"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M234.87,453.27c1.02,0.21,2.05,0.41,2.73,0.55c-2.82,3.07-5.7,5.87-8.18,8.98c-3.13,3.92-3.05,8.62-3.14,13.45
                c-0.36,19.16,1.02,38.31-1.69,57.49c-2.21,15.67-2.61,31.61-3.61,47.44c-0.1,1.62,1.23,3.32,1.9,4.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M150.95,459.27c-4.41,0.62-8.82,1.25-13.23,1.87c0.05,0.5,0.11,1,0.16,1.51c1.86,0.37,3.7,0.89,5.58,1.09
                c11,1.18,18.04,7.64,22.98,17.01c9.14,17.33,18.73,34.44,27.35,52.02c8.4,17.15,11.15,35.42,7.61,54.4
                c-0.65,3.5,3.26,6.76,8.48,7.57c2.24,0.35,4.56,1.25,6.46,2.49c3.89,2.55,11.69,2.76,14.47-0.62
                c13.16-16.05,31.04-26.07,47.45-38.02c6.27-4.56,11.73-10.23,17.55-15.39"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M297.81,810.51c0.5,16.15,1.16,32.31,1.45,48.47c0.24,13.82-0.27,27.66,0.18,41.46c0.23,7.02,1.86,13.99,2.86,20.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M886.76,411.3c-8.95,13.84-15.18,28.85-18.43,44.98c-3.74,18.6-7.47,37.23-10.37,55.97c-1.5,9.67-1.17,19.63-1.67,29.45"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1004.15,1297.65c-10.82,8.49-22.04,16.54-32.36,25.61c-10.54,9.26-22.5,12.41-36.08,11.96c-6.14-0.2-12.54-0.34-18.43,1.12
                c-8.57,2.13-15.59,7.27-20.03,15.28c-3.04,5.47-6.52,10.78-8.87,16.54c-3.16,7.71-9.59,9.91-16.62,11.41
                c-12.3,2.62-24.62,5.13-36.97,7.48c-5.1,0.97-10.31,1.38-15.48,2.05"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M503.62,271.91c-3.85-0.72-7.7-1.45-10.44-1.96c-4.59,6.43-9.33,12.52-13.47,19c-5.11,7.98-10.58,15.9-14.38,24.52
                c-8.55,19.41-16.18,39.22-24.12,58.89c-0.87,2.17-1.27,4.53-1.89,6.8c0.61,0.41,1.23,0.83,1.84,1.24c0.65-1.56,1.3-3.12,1.95-4.68
                c0.4,0.31,0.8,0.63,1.2,0.94c1.7,1.29,3.4,2.57,5.08,3.85c-2.14,7.85-9.79,7.45-14.96,11.14c-2.64-3.26-5.05-4.56-9.9-2.72
                c-4.53,1.72-10.6,1.79-14.95-2.99c-0.84-0.92-3.81-1.22-4.78-0.48c-5.45,4.16-10.84,3.98-16.62,0.94
                c-0.91-0.48-2.77-0.57-3.38,0.01c-3.65,3.48-7.05,2.18-10.58-0.07c-2.19-1.4-4.45-2.68-7.22-4.33c-0.34,1.95-0.84,3.17-0.71,4.32
                c0.63,5.71-3.18,7.29-7.55,7.93c-4.54,0.67-7.54-1.78-9.07-6.4c-0.23-0.7-1.6-1.48-2.41-1.45c-3.94,0.16-7.93,1.97-11.64-1.42
                c-0.66-0.6-3.54,0.41-4.79,1.42c-4.08,3.3-9.16,3.44-11.97-0.63c-3.26-4.72-8.78-4.88-13.13-7.33c-0.74-0.42-2.25,0.65-3.44,0.87
                c-1.48,0.27-3.66,1.07-4.37,0.4c-2.94-2.76-5.49,0.07-8.1,0.29c-1.9,0.16-4.05,2.98-5.41,2.54c-1.65-0.53-2.58-3.26-3.83-5.05
                c-4.08,2.63-8.38,4.72-13.32,0.94c-0.65-0.5-2.27,0.19-3.42,0.42c-3.17,0.64-6.31,1.73-9.5,1.87c-2.87,0.12-3.72-2.23-3.49-4.87"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M822.32,381.83c11.94,5.67,21.35,22.86,19.46,38.97c-1.91,16.33-3.81,32.67-6.07,48.95c-0.75,5.41-2.57,10.67-3.9,16"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1161.5,85.05c-4.58,3.56-5.8,8.6-5.97,13.99c-0.4,12.99-0.69,25.99-1.03,38.99c-0.49,0.17-0.97,0.33-1.46,0.5
                c-1.18-2.34-2.23-4.75-3.58-6.98c-0.71-1.17-1.95-2.81-2.95-2.81c-1,0-2.43,1.61-2.9,2.82c-0.72,1.85-1.06,3.96-1.05,5.96
                c0.07,11.49,0.15,22.99,0.51,34.47c0.19,6.17,0.91,12.33,1.46,18.49c0.23,2.51,0.84,4.99,0.95,7.5
                c0.46,10.44,0.24,10.86-8.84,21.42c-5.04-6.42-9.33-12.9-9.11-21.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1.59,199.96c-0.17,0.83-0.48,1.67-0.48,2.5c-0.02,31.98-0.03,63.95,0,95.93c0,1.33,0.31,2.66,0.48,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M132.47,900.44c-0.33,2.33-0.83,4.65-0.97,7c-0.37,6.36,0.24,12.65-1.78,19.06c-1.83,5.8-1.93,12.23-2.17,18.41
                c-0.56,13.98-0.77,27.98-1.04,41.97c-0.05,2.82-0.8,4.83-2.82,7.17c-2.67,3.11-3.87,7.48-5.7,11.31"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M760.38,1182.73c1-0.17,2.55-0.83,2.91-0.42c3.86,4.31,9.38,3.47,14.1,4.21c7.2,1.13,14.63,0.69,21.94,1.25
                c13.66,1.03,27.32,2.24,40.96,3.48c8.82,0.8,17.62,1.76,27.63,2.77c-4.49-6.65-10.3-9.32-16.19-11.13
                c-7.47-2.29-15.26-3.53-22.94-5.12c-2.46-0.51-4.99-0.69-7.49-1.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M842.8,1266.67c-5.99,0-12.1,0.75-17.96-0.13c-24.53-3.68-49.17-5.35-73.95-4.81c-4.17,0.09-8.33,0.94-12.49,1.44"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M677.96,1198.22c5.33,1.5,10.59,3.95,16,4.34c27.78,2.01,55.6,3.47,83.41,5.21c1.52,0.1,3,0.95,4.5,1.45"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M142.96,688.6c0.17,18.82-0.97,37.77,0.85,56.42c2.02,20.69,1.89,41.31,2.19,61.99c0.08,5.5,0.87,10.99,0.92,16.49
                c0.25,28.64,3.06,56.93,11.58,84.42c0.76,2.46,2.28,4.68,3.44,7.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M468.65,262.41c-4.83,8.66-9.57,17.37-14.51,25.97c-8.79,15.29-14.61,31.8-19.87,48.5c-3.31,10.51-5.75,21.29-8.58,31.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1108.55,1013.36c0,4.33,0,8.66,0,12.99c0,27.15,0.12,54.29-0.04,81.44c-0.12,20.15-0.49,40.31-1.03,60.45
                c-0.18,6.67-1.44,13.31-1.86,19.99c-0.31,4.98-0.06,9.99-0.06,14.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M529.59,990.38c-3-0.67-6.55-2.68-8.88-1.72c-5.12,2.12-8.38,0.77-11.04-3.31c-1.24-1.89-1.83-1.88-4.13-1.21
                c-3.38,0.98-7.25,0.25-10.92,0.25c-20.15,0-40.3,0-60.44,0c-29.64,0-59.28-0.05-88.92,0.05c-5.33,0.02-10.66,0.62-15.99,0.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M99.5,995.37c-5.16-0.5-10.71-2.44-15.4-1.18c-8.21,2.19-15.82,1.05-23.55-0.87c-12.26-3.04-24.61-1.83-36.98-1.51
                c-7.15,0.18-14.32-0.27-21.48-0.44"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M813.83,77.05c5.72,13.65,13.3,25.94,23.86,36.59c10.61,10.7,15.38,24.86,20.11,38.85c2.94,8.68,6.24,17.25,8.82,26.03
                c0.74,2.52-0.47,5.62-0.84,8.44c-0.98,7.5-1.41,15.12-3.12,22.45c-3.05,13.1-6.76,26.04-10.4,38.99
                c-2.95,10.52,0.47,17.49,11.01,22.02c3.66,1.57,6.59,1.42,9.73-3.03c1.09,3,2.58,5.92,3.18,9.01c0.96,4.98,8.79,12.01,14.05,11.28
                c2.15-0.3,4.01-2.71,6.32-4.39c2.76,1.7,7.35,2.76,6.91,8.59c-0.07,0.88,2.71,2.12,4.29,2.95c2.77,1.44,5.75,2.53,8.41,4.14
                c0.76,0.46,1.08,2.24,1.01,3.39c-1.6,27.48-3.28,54.96-4.95,82.44c-0.32,5.33-0.66,10.66-1,15.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M419.2,266.91c2,6,3.73,12.1,6.09,17.95c1.49,3.7,3.93,7.02,5.89,10.53c4.85,8.69,1.97,16.85-0.8,25.54
                c-4.39,13.76-7.52,27.92-11.18,41.91"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1128.03,207.46c0.17,9.33,0.25,18.66,0.53,27.98c0.16,5.33,0.72,10.66,0.95,15.99c0.71,15.99,1.34,31.98,2.03,47.96
                c0.04,0.84,0.34,1.66,1.71,3.12c2.09-4.37,4.17-8.74,6.26-13.11"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M161.94,846.48c0.33,9.16,0.81,18.32,0.96,27.48c0.17,10.16,1.09,20.45-0.19,30.45c-2.41,18.89,3.57,36.49,6.9,54.46
                c2.41,12.99,6.3,25.73,9.91,38.48c1.18,4.18,3.56,8.02,5.39,12.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1.59,658.62c-0.17,9.49-0.69,19-0.27,28.47c0.12,2.66,2.43,5.68,6.79,4.62c3.02-0.74,6.76,0.47,9.36-0.82
                c4.2-2.08,7.88-2.6,12.63-2.13c4.76,0.48,7.78,1.91,8.88,3.89c5.24,1.85,9.02,2.88,12.54,4.49c7.31,3.35,15.78,4.26,21.65,10.81
                c1.37,1.53,4.55,1.38,6.84,2.15c2.34,0.78,4.62,1.75,6.69,3.32c11.38-3.87,14.62,6.81,20.81,12.13c0.73,0.62,1.74,0.91,2.47,1.54
                c1.88,1.61,4.46,2.97,5.35,5.04c2.08,4.83,4.81,5.03,8.65,2.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M708.93,759.55c-1.38-5.73-5.35-9.28-9.96-12.54c-7.39-5.23-15.93-7.25-24.52-7.74c-10.68-0.61-21.62-1.54-31.96,2.79
                c-8.7,3.64-14.96,9.17-14.99,19.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M250.36,376.83c-2.33,0.34-4.66,0.68-7.18,1.04c-3.06-4-6.22-7.2-12.37-5.26c-3.46,1.09-6.37-2.16-7.83-5.32
                c-1.33-2.89-2.47-4.21-6.13-3.71c-2.27,0.32-5.76-0.46-4.81-5.31c0.34-1.74-3.26-4.31-5.16-6.41c-1.58-1.74-3.51-3.19-4.94-5.04
                c-3.25-4.22-2.83-6.38,1.91-9.79c-2.62-1.58-5.07-3.07-7.76-4.7c0.83-1.24,2.19-2.35,2.2-3.47c0.02-3.67,1.61-3.96,4.64-3.12
                c0.95,0.27,3.35-1.04,3.3-1.36c-0.57-3.77,2.06-4.51,4.64-5.55c2.93-1.17,4.64-3.09,2.94-6.25c-2.62-0.4-5.27-1.13-7.93-1.15
                c-5.49-0.05-11.11-0.36-16.43,0.69c-2.78,0.55-6.68,3.45-7.1,5.82c-0.46,2.58-2.5,2.47-2.66,3c-0.9,2.99-0.98,6.24-1.25,9.41
                c-0.11,1.32,0.52,3.05-0.11,3.93c-2.91,4.05-7.52,6.1-11.98,7.26c-2.29,0.59-5.39-1.98-9.02-3.49c-0.57,0.38-1.98,1.35-3.39,2.31
                c-0.66,0.65-1.31,1.3-1.97,1.95c-1.18-2.15-2.14-4.47-3.62-6.4c-0.89-1.17-2.8-2.66-3.88-2.4c-5.31,1.26-9.11-1.16-13.07-4.1
                c-4.49-3.34-5.91-2.88-9.45,1.94c3.15,6.82,8.11,10.66,15.98,10.69c1.5,0,3.18,2.32,4.45,3.85c1.38,1.65,2.56,3.54,3.47,5.49
                c0.45,0.96,0.5,3.07,0.01,3.34c-3.2,1.74-6.58,3.14-9.93,4.61c-1.98,0.87-4.51,2.87-5.89,2.27c-3.61-1.57-6.97-1.14-10.6-0.85
                c-4.68,0.37-9.18,0.18-13.64,2.82c-2.08,1.23-6.33,0.46-8.73-0.91c-5-2.86-9.12-3.74-14.21,0.02c-2.91,2.15-7.57-0.04-10.96,2.54
                c-0.5,0.38-2.43-0.65-3.36-1.43c-1.34-1.12-2.77-3.91-3.47-3.69c-1.91,0.57-3.46,2.35-5.22,3.7c-5.18-1.62-10.51-3.29-15.84-4.94
                c-0.3-0.09-0.74-0.14-0.98,0.01c-7.17,4.38-7.49-3.16-10.81-5.94c-8.56,2.15-8.56,2.15-13.28-3.7c1.54-2.08,3.11-4.2,4.89-6.61
                c-3.7-3.1-4.09-6.46-0.91-10.35c0.67-0.82-0.1-2.92-0.4-4.39c-0.75-3.58-0.7-3.08,2.97-3.76c1.35-0.25,6.04,1.01,4.6-3.76
                c-0.11-0.37,0.52-1.32,0.95-1.43c5.57-1.38,9.6-7.1,15.96-5.97c3.32,0.59,5.64-0.05,8.13-2.44c1.46-1.4,4.44-2.41,6.32-1.94
                c3.89,0.97,5.54-1.22,6.99-3.73c2.42-4.22,5.9-4.37,10.06-3.54c2.08,0.42,4.32,0.09,6.48,0.07c2.63-0.03,2.77-2.89,3.76-3.66
                c4.57-3.58,9.91-6.35,15.3-8.65c2.07-0.88,4.28-0.43,4-3.68c-0.07-0.78,0.74-2.16,1.44-2.41c7.4-2.64,14.77-6.17,22.51-0.66
                c1.24,0.88,3.29,0.64,4.91,0.91c1.1,6.51,1.1,6.51,11.61,5.09c-0.37-1.67-0.74-3.29-0.99-4.41c3.69-5.14,9.43-1.97,13.88-4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M488.13,1110.29c-1.83,0.5-3.65,1.37-5.5,1.44c-12.82,0.45-25.65,0.57-38.46,1.1c-15.83,0.66-31.64,2.06-47.46,2.17
                c-4.32,0.03-8.77-3.15-12.96-5.26c-12.38-6.21-24.67-12.61-36.99-18.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M311.3,77.05c-4.42-4.3-4.89-4.17-7.04,1.48c-0.31,0.8-1.2,1.47-1.96,2c-3.46,2.38-7.05,4.59-10.44,7.07
                c-1.11,0.81-1.76,2.24-2.77,3.58c-0.99-0.9-2.04-1.87-3.38-3.09c-1.37,1.79-2.6,3.4-3.01,3.94c-0.53,3.31-0.42,6.16-1.51,8.43
                c-1.22,2.55-3.51,0.9-5.37,0.09c-0.42-0.18-1.25-0.24-1.45,0.01c-1.57,1.94-2.64,4.61-4.62,5.82c-3.8,2.34-2.42,4.3-0.31,6.49
                c-0.96,1.12-1.81,2.1-2.83,3.28c1.64,2.58,3.28,5.16,4.92,7.73c-5.65,3.72-6.31,7.6-4.88,14.66c1.11,5.47,0.61,11.29,0.67,16.95
                c0.08,7.83,1.15,8.54,8.34,4.83c0.81,0.66,1.71,1.46,2.68,2.15c1.08,0.77,2.23,1.44,3.07,1.98c-3.57,6.37-2.84,8.51,4.42,15.52
                c5.05,4.89,8.62,5.71,12.35,3.39c10.69,5.73,20.82,7.9,31.58-0.75c1.67,2.53,3.66,4.51,4.41,6.89c1.74,5.51,5.73,8.88,10.16,11.87
                c1.3,0.88,3.79,1.59,4.85,0.94c6.38-3.9,14.58,0.52,20.63-4.78c1.37-1.2,3.55-1.58,5.44-2.04c2.89-0.7,5.93,1.91,5.98,4.96
                c0.11,5.45,1.65,7.06,9.5,9.47c3.96,1.22,7.48,0.88,9.51-3.46c0.28-0.6,0.97-1.01,1.54-0.42c3.14-0.2,6.28-0.4,9.42-0.59
                c0.66-0.04,1.66-0.29,1.95,0.04c3.57,4.13,8.31,2.08,12.54,2.38c2.98,0.21,9.6,3.06,11.48,5.57c3.62,4.83,12.03,5.69,16.44,1.46
                c2.29-2.2,4.16-4.83,6.04-7.06c1.35,0.74,1.83,0.84,1.97,1.12c1.7,3.2,1.57,8.25,6.04,8.75c3.2,0.36,6.62-1.51,9.97-2.25
                c3.57-0.79,2.97-3.49,3.01-6.01c0.04-2.57-0.83-5.92,2.99-6.41c3.72-0.47,5.92,1.64,6.51,5.4c0.16,1.03,0.48,2.1,1.01,2.98
                c0.67,1.1,1.54,2.72,2.48,2.84c1.06,0.14,3.29-1.12,3.31-1.82c0.12-4.19,2.25-5.71,6.14-6.14c1.01-0.11,1.8-2.08,2.48-2.93
                c3.1,1.33,6.49,4.13,9.48,3.75c4.69-0.58,1.36-5.9,3.03-8.67c1.7-0.56,3.41-1.12,5.25-1.73c-1.72-5.16-1.58-9.56,2.65-13.37
                c1.08-0.98,0.89-3.37,0.45-5.19c1.22-2.06,3.78-4.47,3.36-6.11c-0.86-3.37-3.87-4.9-7.74-3.66c-0.81,0.26-2.26-0.29-2.92-0.96
                c-2.3-2.37-4.63-4.77-6.5-7.47c-5.87-8.48-4.49-8.2,0.78-12.1c0.27-4.11-0.2-7.47,0.8-10.31c2.74-7.86,2.98-7.78-4.62-10.04
                c-3.75-1.11-4.92-3.44-2.41-5.9c2.88-2.82,2.19-5.84,1.94-9.09c-0.35-4.48-3.12-3.81-6.5-4.23c-6.35-0.78-9.99-8.04-7.33-13.67
                c-1.19-0.83-2.36-2.3-3.71-2.48c-5.96-0.83-7.9-7.15-12.95-9.15c-0.66-0.26-1.51-1.32-1.45-1.94c0.77-7.79-5.85-6.64-10.04-8.55
                c-2.98-1.36-5.73-3.21-8.89-5.01c-2.52,1.26-5.22,3.02-8.17,3.91c-1.39,0.42-3.9-0.31-4.85-1.43c-7.29-8.58-7.06-7.95-17.15-5.74
                c-4.41,0.97-10.33,4.13-14.75-2.45c-1.27-1.89-6.51-1.11-10.04-0.94c-5.09-3.41-6.05-3.34-13.98,1.96
                c-1.9-5.55-6.01-4.85-10.09-3.18c-2.8,1.15-4.97,2.05-8.14,0.13c-2.71-1.65-6.39-2.11-9.44,0.73c-0.88,0.82-2.63,0.65-3.92,1.09
                c-2.19,0.76-4.28,1.93-6.52,2.4c-2.43,0.51-4.98,0.37-7.48,0.59c-2.03,0.18-4.06,0.46-6.56,0.75c-1.76-1.06-4.26-2.56-7.58-4.56
                c-3.12,4.16-6.1,8.14-9.09,12.12c-0.09-0.32-0.17-0.65-0.26-0.97c-2.83,1.21-5.66,2.43-8.49,3.64"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M259.85,45.57c-1.66,5.29-4.28,9.38-9.89,11.73c-2.73,1.14-4.42,4.77-6.6,7.25c-0.62,0.71-1.25,1.44-2,1.99
                c-2.13,1.55-5,2.57-6.34,4.6c-3.28,4.98-6.88,5.79-11.67,2c-2.82,2.52-6.51,3.95-4.26,9.04c0.38,0.86-3.07,4.04-5.25,5.24
                c-3.36,1.84-7.16,2.89-11.76,4.66c2.13,1.7,3.25,2.55,4.32,3.47c0.71,0.61,2.03,1.71,1.91,1.93c-3.92,7.03,4.61,7.11,6.15,11.09
                c9.29-3.12,7.79,6.8,12.15,9.12c1.76,0.44,4.72,0.45,5.07,1.41c1.13,3.12,2.03,7.11-1.37,9.27c-2.9,1.85-6.86,1.99-9.82,3.77
                c-3.18,1.92-5.77,4.84-8.56,7.37c-1.39,1.26-2.67,2.65-4.01,3.99c-1.33,1.33-2.59,2.76-4.03,3.96c-0.83,0.7-1.92,1.27-2.98,1.48
                c-1.27,0.25-2.63,0.06-3.44,0.06c-0.21,2.52-0.08,4.62-0.75,6.42c-0.18,0.49-2.79,0.07-4.53,0.07
                c-3.84,4.08-9.13,7.96-11.88,13.19c-1.92,3.64-0.37,9.12-0.37,15.11c3.47,4.25,7.98,9.77,11.65,14.26
                c5.9,0.31,11.39,0.59,18.19,0.94c3.99-2.81,5.79-2.62,13.12,2.46c5.26-3.75,13.39-2.53,19.98,3c6.37-2.05,8.56-1.04,14.11,6.67
                c1.4-0.77,2.83-1.56,3.76-2.07c5.29,3.64,10.33,6.84,15.02,10.5c1.51,1.18,2.05,3.58,3.09,5.39c0.41,0.72,0.92,1.86,1.49,1.93
                c5.75,0.67,6.51,5.64,8.48,9.57"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M776.36,694.6c0.5-5.83,1.63-11.69,1.37-17.48c-0.59-13.01-1.75-26-2.91-38.97c-0.77-8.51-1.88-17-3.01-25.48
                c-0.24-1.79-1.24-3.48-2.09-5.77c-6.19,11.36-6.82,23.09-7.84,34.74c-1.32,15.16-2.7,30.31-3.98,45.47
                c-0.59,6.99-0.98,13.99-1.53,20.98c-0.23,2.84-0.65,5.66-0.98,8.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M410.71,1128.77c-13.82-5-27.61-10.08-41.47-14.96c-8.09-2.85-16.24-5.58-24.48-7.96c-7.91-2.29-15.97-4.06-23.96-6.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1049.61,2.11c-2.33,8.83-4.09,17.85-7.11,26.44c-7.06,20.11-10.5,40.84-11.87,61.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M551.57,1285.66c10.66,2.33,21.29,4.79,31.98,6.95c7.62,1.54,15.36,2.48,22.96,4.1c8.05,1.72,16.04,3.78,23.97,6.01
                c3.12,0.88,6.02,2.59,9.01,3.92"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M634,582.68c-1.33,15.49-3.17,30.96-3.79,46.47c-0.33,8.28,1.93,16.64,2.12,24.98c0.11,5.14-1.49,10.32-2.32,15.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M207.4,998.87c-7.87,9.67-14.68,19.79-17.12,32.45c-1.11,5.74-8.06,9.44-14.83,8.43c-5.82-0.86-10.84,0.47-16.05,2.98
                c-7.9,3.81-15.69,7.57-24.89,8.46c-6.01,0.58-11.67,4.69-17.53,7.12c-5.76,2.38-11.49,5.2-17.98,2.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1041.11,184.47c-1.17,5.33-2.17,10.7-3.53,15.98c-3.4,13.18-6.77,26.38-10.53,39.46c-2.47,8.6-5.62,17-8.38,25.51
                c-0.35,1.07-0.15,2.31-0.23,4c8.66-4.5,13.7-11.73,18.17-19.5c12.19-21.2,19.86-44.02,24.49-67.95c0.39-2.02,0.98-4,1.48-6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M309.8,926.42c0.17,10.66,0.84,21.34,0.38,31.97c-0.73,16.89,4.82,31.78,13.13,45.96c1.82,3.1,3.97,6.01,5.97,9.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M846.8,1127.78c-8.53,6.51-17.05,13.02-26.64,20.33c0.21-2.38,0.3-3.38,0.36-4.09c-26.19,0-52.66,0-79.14,0
                c-0.18,0.86-0.35,1.73-0.53,2.59c4.84,1.72,9.56,3.96,14.54,5.06c17.76,3.91,35.61,7.42,53.42,11.11c0.55,0.11,1,0.64,1.5,0.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M717.42,180.97c4.16,6.83,7.72,14.13,12.61,20.39c11.84,15.16,27.48,26.08,43.4,36.48c5.96,3.89,12.61,6.72,18.9,10.13
                c0.54,0.29,0.69,1.28,1.03,1.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M160.94,439.78c15.18,30.41,30.25,60.89,50.45,88.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1091.57,1367.6c1.3,12.06-3.11,22.65-8.5,32.97c-1.49,2.84-3.11,5.61-4.67,8.42c0.62,0.59,1.24,1.18,1.86,1.77
                c7.36-4.53,14.71-9.07,22.07-13.6c0.36,0.27,0.72,0.55,1.07,0.82c-3.78,7.36-7.49,14.76-11.35,22.08
                c-11.06,20.92-25,39.94-38.95,58.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M664.97,817c-2.45-1.94-10-1.1-13.49,1.5c-0.08-0.45-0.16-0.91-0.24-1.36c-3.42,0.45-6.89,1.53-10.24,1.21
                c-4.99-0.47-6.63,2.14-8.11,6.1c-1.01,2.71-5.93,2.05-8.36-0.98c-0.59-0.74-0.89-1.71-1.71-3.36c-6.15,2.99-12.12,5.89-18.51,9
                c-5.31-2.76-11.03-0.58-16.76,1.37c-5.68,1.93-9.63,5.85-12.55,11.39c-1.73-1.01-3.33-1.95-5.2-3.05
                c-0.51,0.34-1.32,0.61-1.7,1.18c-3.89,5.73-8.91,6.36-15.55,5.2c-9.67-1.69-19.61-1.94-29.43-2.7
                c-7.49-0.58-14.98-1.37-22.48-1.41c-6.32-0.03-12.65,0.91-18.98,1.38c-1.34,0.1-2.69,0.01-5.25,0.01
                c4.56-3.88,7.75-7.77,11.87-9.74c3.58-1.71,8.28-1.96,12.34-1.51c2.9,0.32,4.95-0.84,7.11-1.17c5.8-0.89,5.62-6.48,9.03-8.34
                c2.51-1.37,6.15-0.68,9.88-0.95c-0.31-5.11,2.43-9.78,9-10.64c4.91-0.64,10.18-0.74,13.47,4.37"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M920.73,1000.37c1.17,1,2.19,2.6,3.52,2.91c17.12,3.97,34.45,7.13,51.36,11.82c9.53,2.64,18.9,6.35,27.17,12.58
                c7.23,5.45,15.6,9.38,23.33,14.19c8.08,5.02,15.95,10.39,24.03,15.41c3.15,1.96,6.63,3.38,9.96,5.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M659.97,1443.54c-8.49,6.83-16.81,13.89-25.54,20.4c-5.13,3.82-10.77,7.05-16.45,10.02c-13.18,6.9-26.19,13.99-36.93,24.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M436.68,1098.8c-1.83,0.33-3.65,0.88-5.5,0.96c-7.33,0.34-14.67,0.54-22,0.78c-0.15,0.62-0.31,1.24-0.46,1.86
                c3.66,1.46,7.21,3.4,11,4.26c4.03,0.91,8.29,0.99,12.46,1.09c17.32,0.42,34.63,0.65,51.95,1.05c9.49,0.22,18.98,0.65,28.47,0.98"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M997.16,1107.29c-5.04,2.82-10.08,5.64-15.12,8.46c0.21,0.68,0.42,1.36,0.62,2.03c2,0,4,0.03,6-0.01
                c8.66-0.15,17.32-0.36,25.98-0.45c0.83-0.01,1.96,0.38,2.45,0.99c6.23,7.61,12.39,15.26,18.51,22.96
                c1.78,2.24,3.01,5.02,5.07,6.92c9.17,8.45,14.14,19.72,20.1,30.23c1.37,2.42-0.98,7.28-2.24,10.78
                c-3.63,10.06-7.56,20.02-11.43,30c-3.95,10.18-7.1,20.8-12.21,30.36c-5.81,10.86-13.19,20.95-22.76,29.09
                c-1.09,0.92-2.32,1.68-3.49,2.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M591.04,303.88c5.66,7.16,11.36,14.3,16.97,21.49c4.38,5.62,8.75,11.26,12.98,16.99c5.24,7.1,10.77,14.05,15.37,21.55
                c3.52,5.74,5.8,12.24,8.63,18.4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M530.09,1055.83c-6.99-0.33-14.03-1.33-20.97-0.84c-10.03,0.71-20.01,2.39-29.98,3.87c-9.02,1.34-17.99,2.97-26.98,4.45
                c-1.49,0.24-3,0.34-4.49,0.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M575.55,1240.69c6.66,1,13.3,2.17,19.99,2.96c16.52,1.95,33.42,1.79,48.93,9.06c3.77,1.77,7.34,3.96,11.01,5.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M483.14,302.38c-12.55,1.37-14.46,12.31-17.79,21.06c-6.81,17.91-12.52,36.23-18.68,54.39"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1025.13,1423.06c-10.66,8.99-21.47,17.8-31.93,27.02c-15.09,13.31-28.79,27.89-38.98,45.44c-0.52,0.89-0.69,1.98-1.02,2.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M300.31,860.97c1,4.66,2.65,9.3,2.87,14c0.67,14.31,0.89,28.64,1.08,42.96c0.09,6.82-0.29,13.66-0.46,20.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M467.15,634.64c-2-3.5-3.85-7.1-6.07-10.44c-0.87-1.31-2.58-2.89-3.92-2.9c-5.13-0.05-8.14-2.95-11.08-6.57
                c-1.07-1.32-3.21-1.81-4.92-2.54c-0.56-0.24-1.74,0.22-1.92-0.08c-2.75-4.64-7.31-3.62-11.53-4.12c-1.61-0.19-3.73-1.87-4.39-3.39
                c-2.47-5.72-8.07-3.72-12.13-5.41c-2.52-1.05-5.18-1.76-8.29-2.8c-1.43,1.21-4.07,5.73-4.87-1.71c-0.05-0.49-2.79-0.85-4.31-0.99
                c-4.65-0.42-9.32-0.85-13.99-0.98c-3.32-0.09-7.17-0.72-9.88,0.66c-5.45,2.78-9.22-3.22-14.77-1.23c-0.46,0.76-1.32,2.18-1.81,3
                c-3.67,0.01-6.89,0.35-9.98-0.12c-1.78-0.27-3.29-2-5.04-2.82c-1.22-0.57-3.73-1.18-3.81-0.98c-1.29,3-4.96,1.41-5.97,2.59
                c-5.3,6.21-9.52-0.64-14.16-0.87c-4.45-0.22-9.77,0.95-12.58,4.81c-3.11-0.86-6.41-1.4-9.37-2.71c-2.23-0.99-3.94-2.58-5.6,0.57
                c-0.21,0.41-1.35,0.65-1.92,0.49c-5.52-1.59-10.95-3.48-16.52-4.85c-2-0.49-5.91-0.33-6.2,0.49c-1.46,4.18-5.88,3.46-8.46,5.67
                c-1.18,1.01-5.13,0.34-6.65-0.95c-2.54-2.17-4.2-2.1-6.13,0.19c-3.21,3.79-6.34,3.39-9.5-0.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M421.7,1063.82c-7.16,1.33-14.32,2.68-21.48,3.99c-5.66,1.03-11.29,2.59-16.99,2.87c-9.63,0.48-19.31,0.28-28.96,0.04
                c-2.84-0.07-6.37,1.78-8.49-1.91"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M146.45,912.43c2.16,13.66,4.6,27.28,6.41,40.98c1.63,12.3,8.29,22.79,11.57,34.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M145.46,907.44c0.18,1.95-1.14,4.81,3.03,4.31c0.38-0.05,1.41,4.32,1.94,6.68c2.38,10.65,3.54,21.74,7.31,31.86
                c4.46,11.96,5.74,24.42,8.69,36.58"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M480.14,303.88c4.5,3.5,9.07,6.9,13.47,10.52c5.74,4.73,11.4,9.58,16.94,14.54c0.67,0.6,0.54,2.25,0.55,3.42
                c0.05,6.16-0.98,12.54,0.28,18.43c1.59,7.45,0.07,14.73,0.75,22.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1119.04,313.88c-1,5-2.19,9.96-2.95,15c-0.88,5.8-1.63,11.64-1.99,17.48c-0.55,8.98-0.71,17.98-1.07,26.98
                c-0.05,1.34,0,2.81-0.54,3.97c-2.72,5.88-5.59,11.69-8.47,17.5c-0.3,0.6-0.97,1.01-1.47,1.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M726.41,1209.71c9.66,2.83,19.21,6.15,29,8.35c9.51,2.14,19.3,3.05,28.94,4.66c8.18,1.37,16.32,2.97,24.48,4.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1081.58,20.09c-11.38,9.78-22.49,19.73-31.6,31.88c-7.47,9.97-10.6,21.17-12.36,33.07c-0.3,2-0.66,4-0.96,5.82
                c-4.72,0.87-9.35,1.73-13.52,2.5c-0.74-2.73-0.74-5.61-2.18-7.14c-1.27-1.35-4.13-1.24-6.31-1.65c-1.48-0.28-3.18,0.03-4.46-0.59
                c-5.99-2.91-5.95-2.99-11.39-0.25c-2.33-3.56-5.39-3.73-9.68-2.98c-4.09,0.71-8.69-0.73-12.87-1.93
                c-0.95-0.27-1.77-3.57-1.44-5.24c1.1-5.54-2.2-9.05-7.62-7.5c-3.28,0.94-6.18,1.04-8.09-2.48c6.33-2.36,6.29-2.33,2.64-8.06
                c-0.5-0.79-0.73-2.75-0.49-2.85c3.7-1.66,4.4-7.33,9.44-7.11"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M556.07,571.19c-1,6.99-2.03,13.98-2.99,20.99c-1.36,9.99-2.9,19.96-3.93,29.98c-0.73,7.12-0.74,14.31-1.08,21.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M740.4,1180.74c-6.33,1-12.62,2.36-18.99,2.92c-13.97,1.24-27.97,1.99-41.95,3.13c-3.69,0.3-7.33,1.28-10.99,1.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M454.67,1301.65c-5.66,1.67-11.25,4.37-17,4.8c-16.44,1.23-32.96,1.5-49.44,2.23c-2.84,0.12-5.66,0.64-8.49,0.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M482.14,1030.85c0.91,3.37,3.26,3.93,6.49,4.08c18.16,0.83,36.31,1.86,54.45,2.96c3.71,0.22,7.39,1.02,11.99,1.68
                c-4,4.33-8.55,5.02-12.99,5.19c-7.81,0.3-15.68-0.37-23.47,0.2c-8.7,0.64-17.33,2.17-25.98,3.41c-2.03,0.29-4,0.97-6,1.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M377.74,1043.84c-1.33-0.83-2.63-1.73-4.01-2.48c-1.96-1.06-4.19-3.15-5.95-2.83c-11.24,2.03-20.76-3.79-31-6.18
                c-0.67-0.16-1.37-0.56-1.99-0.46c-10.72,1.58-20.15-3.16-30-5.94c-2.67-0.75-5.64-0.42-8.47-0.59"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M886.26,124.02c0.33,1.33,0.98,2.67,0.95,4c-0.5,23.12,4.09,45.56,9.04,67.95c1.18,5.33,2.33,10.66,3.5,15.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M240.37,987.38c3,1.83,5.8,4.41,9.05,5.28c2.97,0.8,4.21,2.19,5.52,4.67c2.71,5.15,5.79,10.12,8.94,15.01
                c2.65,4.11,5.36,8.25,8.55,11.93c1.98,2.28,4.67,4.29,7.44,5.48c7.81,3.33,15.18,7.17,20.94,13.59"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M884.76,138.51c-4,8.66-8.02,17.31-11.94,26c-0.37,0.83-0.36,2.11,0,2.96c6,14.27,12.24,28.41,22.95,39.98
                c0.56,0.6,1.32,1.01,1.98,1.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M980.17,1435.55c-5.16,3.33-10.89,6.04-15.38,10.11c-12.3,11.15-24.15,22.79-36.05,34.38c-6.15,6-12.02,12.3-18.01,18.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M822.32,788.53c-3,1.33-6.13,2.42-8.96,4.05c-4.38,2.52-8.54,5.41-12.8,8.14c0.89,1.01,1.77,2.02,2.66,3.02
                c-1.13-0.57-2.38-0.99-3.36-1.75c-3.33-2.55-5.59-1.7-7,2.03c-0.97,2.56-2.08,2.49-5.02,2.43c-4.79-0.1-10.18,1.72-14.32,4.32
                c-3.6,2.27-5.82,6.74-8.65,10.24c-7.19-3.86-7.86-3.77-11.93,3.53c-1.54,2.77-2.81,5.29-6.56,5.01c-0.64-0.05-1.69,0.44-1.94,0.97
                c-1.61,3.44-3.06,6.97-4.55,10.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1021.63,1428.05c-1.5,3-2.5,6.41-4.58,8.92c-11.31,13.64-23.25,26.77-34.22,40.67c-4.94,6.25-8.15,13.86-12.15,20.86"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M854.29,122.02c-2.83-5.5-6.52-10.73-8.32-16.54c-3.9-12.65-6.84-25.59-10.16-38.42c-0.69-2.66-1.34-5.33-2-7.99
                c2.83,1,5.6,2.31,8.51,2.9c2.39,0.48,4.96,0.1,8.93,0.1c2.41,4.56,5.68,9.79,4.61,17.53c-1.06,7.62-0.09,15.7,1.07,23.42
                c1.81,12.07,4.28,24.06,6.95,35.97c1.45,6.45,3.9,12.68,5.9,19.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M828.31,1431.55c-14.79-0.73-28.38,4.93-42.47,7.93c-8.75,1.86-17.65,2.99-26.45,4.62c-1.91,0.35-3.67,1.44-5.51,2.19"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M738.4,994.37c-9.32-0.83-18.64-1.98-27.98-2.39c-6.14-0.26-12.32,0.56-18.48,0.88c-0.5,0.03-1.16,0.21-1.47-0.03
                c-6.94-5.36-15.2-1.99-22.53-2.3c-5.87-0.25-12.3-0.38-18.47,0.2c-3.76,0.36-7.65-0.56-11.48-0.86c-0.99-0.08-2.01-0.09-2.99,0
                c-6.66,0.64-13.32,1.34-19.99,1.95c-0.81,0.07-1.71-0.18-2.49-0.49c-4.45-1.79-8.67-1.96-13.57-0.77
                c-4.33,1.05-9.26-0.75-13.91-0.63c-8.33,0.21-16.65,1.24-24.98,1.31c-5.99,0.05-11.98-1.06-17.98-1.33
                c-3.82-0.17-7.89-0.49-11.44,0.59c-8.96,2.71-17.7,6.18-26.52,9.34"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M804.34,968.89c-0.33,5.83-0.39,11.69-1.05,17.48c-2.2,19.17-4.23,38.37-7.09,57.44c-1.71,11.44-4.9,22.66-7.33,34
                c-1.43,6.64-2.68,13.32-4.01,19.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M150.45,688.6c3.16,8.33,5.96,16.82,9.61,24.93c2.78,6.19,3.73,12.3,3.48,19.04c-0.93,24.64-1.59,49.29-2.03,73.94
                c-0.22,12.15,0.28,24.31,0.41,36.47c0.01,1-0.31,2-0.48,3c-0.5-0.17-1-0.33-1.5-0.5c0.5-1,1-2,1.5-3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M735.4,214.95c-13.61-3.47-24.42-10.98-32.5-22.46c-0.34-0.48-1.18-0.62-1.79-0.91c-0.41,0.35-0.81,0.71-1.22,1.06
                c2.51,3.44,4.84,7.03,7.57,10.29c7.92,9.44,18.59,14.5,29.91,18.58c3.48,1.25,6.98,2.86,9.95,5.02
                c6.52,4.73,12.72,9.91,19.04,14.91"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M853.79,200.96c-0.67-2.5-1.55-4.96-1.96-7.5c-1.59-9.81-3.23-19.62-4.33-29.5c-0.17-1.5,2.26-3.53,3.85-4.9
                c1.31-1.14,3.14-1.66,4.48-2.33c-2.75-4.28-5.3-7.92-7.48-11.76c-1.25-2.2-1.91-4.74-3.4-6.69c1.39-2.55-2.39-8.07-4.8-8.74
                c-4.79-1.34-8.88-3.29-12.91-5.92c-0.33-0.21-1.56,0.96-2.42,1.51c-0.71-0.92-1.19-2.15-2.04-2.55
                c-5.84-2.69-9.12-7.43-12.14-12.95c-2.6-4.76-7.41-5.2-12.83-3.17c-2.3,0.86-4.89,0.94-7.73,1.44
                c-0.55-5.21,0.13-10.64-6.25-11.24c-1.7-0.16-3.54,1.21-6.11,2.17c-4.19-5.24-9.84-1.4-15.35,0.24c-2.72,0.81-5.33,1.98-8,2.96
                c-2.51,0.92-3.97,0.51-6.19-1.81c-4.58-4.79-8.28-7.58-16.12-4.34c-4.2-3.94-8.65-8.17-13.19-12.3c-0.79-0.72-2-1.51-2.96-1.45
                c-6.36,0.37-12.7,0.95-19.08,2.04c-3.46-1.17-5.38-6.15-10.41-4.64c-0.46,0.14-1.11-0.17-1.48,0.05
                c-6.32,3.81-10.75-0.24-15.58-3.42c-1.35-0.89-3.87-1.11-5.37-0.48c-4.22,1.77-7.76,1.64-9.97-2.66c-1.5-2.93-3.01-3.95-6.59-3.65
                c-5.6,0.48-11.3-0.26-17.18-0.49c-0.3-4.16-1.93-4.57-6.71-5.04c-4.9-0.48-10.27,2.47-15.16-1.14
                c-2.45-1.81-10.13,1.78-13.64,5.63c4.96,4.46,10.04,8.16,17.23,8.36c3.06,0.08,7.43-0.07,7.03,5.36c-0.15,2.03-0.79,2.62-2.8,3.86
                c-3.37,2.09-8.13,4.61-8.09,9.62c0.05,4.93-3.07,7.69-6.22,9.86c-7.04,4.83-7.56,6.41-1.9,12.13c6.01,6.07,7,6,15.57,2.73
                c1.56-0.59,5.55,1.26,6.18,2.86c2.53,6.47,8.12,9.34,13.63,13.78c-2.24,1.72-4.66,3.57-9.1,6.99c2.72,1.75,4.93,3.23,7.19,4.61
                c4.14,2.53,8.31,5.03,12.5,7.48c0.57,0.33,1.4,0.64,1.97,0.48c6.97-2.04,11.22,2.64,15.43,6.61c5.63,5.32,12.47,4.46,19.05,4.27
                c4.2-0.12,6.44,0.69,7.12,5.13c0.27,1.78,1.88,3.35,2.88,5.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1130.53,1226.2c2.16-3.4,4.32-6.8,6.8-10.71c6.15,6.7,8.98,15.12,10.15,24.21c0.87,6.8,1.07,13.7,2.18,20.46
                c0.46,2.81,2.5,5.36,3.86,8.02c2.65,5.17,5.32,10.33,7.99,15.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M76.02,1498.5c-1.89-3.04-5.9-3.93-6.97-8.38c-0.45-1.86-6.1-2.93-9.53-3.53c-14.45-2.53-28.96-4.77-43.45-7.07
                c-4.65-0.74-9.32-1.34-13.99-2.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M581.55,535.71c-8.66-2-17.34-3.9-25.97-6.02c-6.86-1.69-13.57-4.21-20.52-5.28c-5.18-0.8-10.64-0.01-15.95,0.36
                c-4.61,0.32-9.03,1.42-13.11-2.42c-2.16-2.04-5.43-3.2-8.4-4.03c-4.57-1.27-8.5-2.4-12.34-6.21c-3.35-3.32-9.95-3.38-15.11-4.88"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M963.19,900.94c-0.5,1.83-1.21,3.64-1.47,5.5c-1.73,12.48-3.53,24.96-4.96,37.48c-0.85,7.45-1.04,14.98-1.6,22.47
                c-0.05,0.69-0.63,1.34-0.96,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1087.57,1.61c-10.16-0.17-20.31-0.34-30.47-0.49c-2.83-0.04-5.66-0.02-8.49,0c-4.16,0.03-8.35-0.54-12.04,2.44
                c-2.59,2.09-5.32,3.33-9.04,1.22c-2.78-1.58-8.49,3.87-8.9,7.83c-1.48,14.32-2.99,28.65-4.49,42.97c-1,9.49-2,18.99-3,28.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M320.79,1468.52c-5,1.33-9.96,2.79-14.99,3.97c-16.18,3.82-31.29,10.36-45.92,18.08c-4.23,2.23-8.03,5.26-12.02,7.93"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M868.78,1379.59c-9.47-17.75-21.74-32.97-40.52-41.33c-10.98-4.89-22.26-9.78-34.91-8.17c-0.63,0.08-1.33-0.3-1.99-0.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M739.9,652.13c-5.33,10.87-7.93,22.42-8.5,34.47c-0.64,13.66-1.32,27.31-2.01,40.97c-0.07,1.34-0.32,2.67-0.49,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M164.44,479.25c-5,11.16-10.34,22.18-14.87,33.52c-3.22,8.07-5.45,16.53-5.15,25.44c0.08,2.32-0.3,4.66-0.46,6.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M2.09,753.05c4.98,2.08,5.04,2.07,8.88-1.92c4.41,5.25,8.74,10.4,13.17,15.67c5.43-2.28,7.19-1.03,7.51,5.23
                c0.17,3.36,1.53,6.64,1.81,10.01c0.19,2.32-0.55,4.72-0.93,7.49c4.02,0,9.05-1.27,12.89,0.31c5.11,2.11,9.3,6.44,14.08,9.97
                c0.5-0.32,1.43-0.91,2.02-1.28c3.8,4.83,7.23,9.56,11.1,13.89c1.15,1.28,3.63,2.15,5.38,1.97c10.4-1.02,12.32,0.49,12.5,10.61
                c0.1,5.5,1.25,6.52,8.99,7.56c0,1.75-0.01,3.59,0,5.43c0.02,4.49,0.02,4.2,5,4.14c2.03-0.03,4.08,1.48,6.32,2.38
                c0.84-5.01,1.55-9,2.13-13.01c0.11-0.79-0.04-1.85-0.5-2.46c-2.92-3.89-6.09-7.59-8.89-11.56c-1.49-2.11-1.84-5.27-3.66-6.83
                c-4.31-3.68-6.11-9.74-12.79-11.45c-3.88-0.99-6.92-5.27-10.61-8.3c-1.68,2.25-2.65,3.57-4.54,6.1
                c-1.84-5.81-3.3-10.42-4.76-15.04c0.71-0.4,1.43-0.8,2.14-1.2c-1.43-1.07-2.91-2.08-4.28-3.23c-2.96-2.51-5.14-5.05-3.2-9.56
                c0.71-1.65-0.84-4.24-1.29-6.41c-0.51-2.43-0.93-4.88-1.56-8.23c-2.81-3.61-6.39-8.18-9.92-12.77c-0.59-0.76-1.12-1.61-1.49-2.5
                c-6.17-14.58-17.06-22.05-32.99-22c-0.67,0-1.33-0.31-2-0.48c0.05-5.15-1.24-6.32-6.47-4.92c-3.98,1.06-5.18-0.35-5.03-4.07
                c0.23-5.66,0.35-11.32,0.52-16.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1031.62,1178.24c-4.16,6.16-8.28,12.36-12.51,18.47c-2.57,3.73-5.35,7.32-7.94,11.03c-0.43,0.62-0.43,1.55-0.63,2.34
                c0.52,0.43,1.03,0.86,1.55,1.29c10.45-9,20.9-18.01,31.35-27.01c0.54,0.52,1.09,1.03,1.63,1.55c-3.98,5.44-8.03,10.84-11.93,16.33
                c-3.75,5.27-7.42,10.6-10.94,16.02c-0.65,1.01-0.45,2.57-0.79,4.88c2.33-0.64,4.14-0.61,5.16-1.49
                c8.76-7.55,17.37-15.26,26.03-22.92c0.18-0.16,0.33-0.46,0.5-0.46c0.17,0,0.33,0.3,0.5,0.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M289.32,954.4c1,6.33,1.92,12.67,3.01,18.98c2.62,15.16,5.31,30.31,7.98,45.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1040.62,1416.06c-0.17,6,0.66,12.23-0.7,17.94c-3.21,13.47-8.7,26.5-10.85,40.09c-1.42,8.96-6.98,15.85-8.43,24.41"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M528.1,370.33c1.67,8.49,3.86,16.92,4.87,25.5c1.48,12.6,2.12,25.3,3.16,37.95c0.03,0.36,0.63,0.67,0.96,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1007.65,1418.06c-8.49,5.5-17.92,9.96-25.29,16.69c-17.16,15.68-31.79,33.59-41.16,55.25c-1.24,2.87-2.65,5.67-3.98,8.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M859.78,378.83c5.83,6.66,11.73,13.26,17.46,20c6.58,7.75,12.96,15.68,19.51,23.46c9.67,11.47,14.57,25.05,17.42,39.49
                c0.71,3.59,0.87,7.31,1.03,10.98c0.42,9.49,0.67,18.99,1.03,28.48c0.65,17.32,1.34,34.64,1.98,51.96c0.09,2.49,0.01,5,0.01,7.49"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M842.3,166.98c-1.5,0.17-3.01,0.52-4.49,0.44c-1.35-0.07-3.06-1.26-3.89-0.76c-0.83,0.5-1.45,2.8-0.99,3.76
                c2.14,4.58-3.09,4.64-3.99,7.09c-1.08,2.97-1.69,5.17-5.71,3.72c-0.65-0.23-4.03,3.5-3.68,4.66c3.81,12.6,6.29,26.1,12.77,37.25
                c6.62,11.4,7.08,21.67,3.51,33.27c-1.27,4.13-2.35,8.32-3.83,13.61c-4.77-3.53-9.72-6.33-13.57-10.22
                c-9.79-9.87-19.41-19.94-28.48-30.46c-9.9-11.48-14.24-25.23-14.08-40.39"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M339.77,1024.35c-2-6-4.24-11.92-5.93-18c-2.25-8.09-4.15-16.28-5.95-24.49c-0.26-1.17,0.5-3.23,1.46-3.88
                c6.86-4.65,9.26-10.58,6.54-18.62c-0.47-1.39,0.16-3.51,0.95-4.92c3.18-5.68,3.02-11.46,1.51-17.54
                c-0.76-3.06-0.96-6.31-1.03-9.48c-0.15-7-0.96-8.37-7.04-10.49c4.29-10.79-7.12-21.53-16.48-25.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M649.98,572.69c0.13,14.9-2.84,29.41-5.46,43.97c-0.59,3.28-0.61,6.67-1.06,9.98c-0.52,3.89-1.97,7.22-4.22,10.66
                c-2.46,3.76-2.91,8.83-4.26,13.32c-0.3,1.01-0.66,2-0.99,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M64.03,1026.85c-2.83,0-5.82-0.6-8.46,0.1c-10.68,2.83-21.31,1.43-32,0.43c-7.14-0.67-14.32-1.03-21.48-1.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1052.6,1420.06c-0.17,5.5,0.18,11.07-0.6,16.47c-1.72,11.89-7.46,22.26-13.39,32.49c-1.28,2.2-2.45,4.51-4.05,6.45
                c-1.56,1.89-3.6,3.38-5.44,5.05"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M555.57,1223.7c-8.1-1.76-16.32-0.64-24.47-1.08c-8.84-0.47-17.65-1.71-26.48-2.35c-3.48-0.25-7.02,0.19-11.14,0.34
                c2.47,4.11,2.43,4.22,12.66,8.04c4.38,1.63,8.97,2.72,13.47,4.05"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M810.33,392.82c-0.83-4.16-1.47-8.38-2.56-12.48c-0.94-3.57-2.39-7.01-3.61-10.51c-0.68,0.13-1.36,0.25-2.04,0.38
                c-0.76,5.04-2.07,10.06-2.18,15.12c-0.3,13.82-0.06,27.64-0.16,41.47c-0.01,1.83-0.78,3.66-1.19,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M846.8,163.49c-4.8,0.41-3.5,3.46-3.05,6.5c1.17,7.97,1.97,15.99,3.1,23.97c0.36,2.54,1.17,5.04,1.97,7.49
                c2.34,7.15-0.09,14.03-1.07,20.99c-0.29,2.06-1.12,4.05-1.72,6.16c-10.57-7.03-19.8-30.81-21.21-46.12"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M302.31,1208.72c0.5,9.83,1.58,19.67,1.31,29.47c-0.2,7.35-2.24,14.65-3.23,22c-0.21,1.59,0.59,3.32,0.92,4.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M304.81,1271.67c-0.83,4.5-1.66,8.99-2.5,13.49c-0.31,1.67-0.66,3.33-1,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1157.51,236.93c-0.67,4-1.3,8-2,11.99c-1.98,11.16-3.61,22.41-6.16,33.44c-1.17,5.06-4.16,9.7-6.32,14.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M312.3,949.41c2.33,9.49,4.49,19.03,7.04,28.47c2.62,9.72,5.63,19.33,8.42,29c0.22,0.77,0.03,1.66,0.03,2.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M283.33,862.47c-4.83-3.33-9.68-6.63-14.47-10.01c-1.69-1.2-2.54-3.74-5.68-1.74c-0.89,0.57-4.93-1.62-5.61-3.32
                c-1.86-4.6-8.34-7.61-4.6-14.61c0.95-1.77-2.18-7.18-4.78-9.04c-3.92-2.81-4.36-6.63-4.61-10.25c-0.25-3.59-1.66-5.11-4.7-5.99
                c-3.84-1.12-7.66-2.32-11.49-3.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1129.53,1440.54c-0.5,2.5-0.45,5.29-1.59,7.44c-4.94,9.28-9.7,18.73-15.55,27.42c-4.86,7.2-11.18,13.42-16.79,20.12
                c-0.7,0.83-1.03,1.98-1.53,2.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M320.79,1467.52c-1.17-0.33-2.37-1.06-3.49-0.94c-17.37,1.9-34.68,1.19-51.96-1.02c-0.96-0.12-1.99,0.3-2.99,0.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M923.72,761.05c-3.52,0.02-6.53-0.04-8.15-4.44c-1.76-4.78-9.36-7.98-13.74-6.4c-1.69-3.02-4.29-3.82-7.92-4.76
                c-4.06-1.05-6.49-0.81-9.69,1.04c-1.59,0.92-3.6,1.33-5.46,1.51c-2.83,0.28-5.94-0.65-6.22,4.04c-0.07,1.1-3.03,2.7-4.77,2.87
                c-3.85,0.38-3.63,2.34-3.01,5.14c1.95,8.88-0.41,12.09-9.95,14.02c-1.22,0.25-2.34,0.96-3.5,1.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M926.22,961.4c5.99,1.33,12.09,2.32,17.96,4.07c14.36,4.28,27.46,11.26,39.99,19.4c14.51,9.42,29.87,18.04,41.24,31.18
                c12.58,14.52,23.41,30.55,35.26,45.72c2.57,3.29,6.24,5.73,9.4,8.56"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M214.89,1464.03c-5.64-0.4-10.93-1.51-16.09-4.29c-3.24-1.75-7.53-1.81-11.39-2.14c-6.47-0.56-13.04-0.31-19.46-1.17
                c-6.78-0.91-11.99,1.75-16.98,6.16c11.16,4.94,23.09,3.86,35.29,2.86c-1.11-0.64-2.23-1.27-3.34-1.91"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M810.83,927.42c-3.43-0.25-5.22-1.6-4.65-5.52c0.17-1.19-1.38-2.75-2.37-3.95c-1.19-1.45-2.42-3.12-4.02-3.91
                c-1.93-0.96-4.36-1.75-6.42-1.46c-3.4,0.47-6.68,1.86-10.01,2.85c-0.17,1.5-0.33,3-0.5,4.5c-1.33-0.33-2.92-0.32-3.94-1.07
                c-2.39-1.77-2.62-0.76-3.76,1.46c-1.02,2-4.76,2.7-5.59,4.69c-1.78,4.26-8.83,2.47-8.84,8.41c0,0.91-2.01,1.81-2.95,2.6
                c0.27,1.06,0.32,1.78,0.62,2.39c3.46,7.03,2.64,9.97-4.04,12.95c-2.67,1.19-5.16,1.21-5.01-2.95c0.03-0.85-0.39-1.72-0.63-2.67
                c-5.19,2.73-7.61,10.22-5.2,15.96c-1,0.71-2.06,1.45-3.12,2.2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M762.88,340.36c4.94,4.03,3.93,9.69,3.97,14.99c0.09,11.66,0.03,23.32,0.03,34.97c0,6.16,0,12.32,0,18.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M374.74,1042.84c-5-0.17-10.05,0.08-14.97-0.58c-10.36-1.39-20.62-3.54-31-4.75c-3.37-0.39-6.97,1.17-10.47,1.83"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M287.32,1082.31c-5.49,0-10.99,0.05-16.48-0.01c-12.16-0.13-24.31-0.35-36.47-0.44c-1.16-0.01-2.33,0.62-3.5,0.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M432.69,1059.33c-3.52,2.27-7.01,3.2-11.51,2.74c-6.23-0.63-12.64,0.32-18.95,0.79c-4.34,0.32-8.8,0.45-12.95,1.6
                c-4.34,1.2-8.36,3.52-12.53,5.36"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M963.69,1058.33c1.5,15.49,3.04,30.97,4.45,46.47c0.18,1.97-0.56,4.02-0.35,5.98c0.13,1.22,1.23,2.34,1.9,3.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M118.48,1082.81c1.5,0.33,2.99,0.93,4.5,0.96c11.82,0.24,23.65,0.29,35.47,0.57c8.16,0.19,16.32,0.64,24.48,0.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M814.33,857.47c-1-0.33-2.31-1.25-2.95-0.91c-5.66,3.03-12.74,1.25-18.04,5.4c-0.29,0.22-0.85,0.57-0.97,0.47
                c-6.32-5.26-8.01,3.62-12.49,4.19c-1.22,0.16-2.18,2.32-3.63,3.98c-1.97-2.25-3.79-4.11-5.36-6.16c-4.3-5.65-9.33-6.82-15.04-3.53
                c-0.95,0.54-2.3,0.45-3.47,0.54c-2.66,0.21-5.33,0.32-7.99,0.53c-0.51,0.04-0.99,0.37-1.5,0.47c-4.4,0.85-7.54-6.24-12.48-1.99
                c-4,3.45-8-0.24-11.99,0.03c-2.01,0.14-4.73-0.07-5.85,1.1c-2.91,3.02-5.97,3.21-9.64,2.4c-3.87-0.86-7.72-2.03-9.25,3.89
                c-0.38,1.46-3.98,2.45-6.25,2.96c-2.34,0.53-5.07-0.58-5.2,3.6c-0.04,1.21-3,2.14-4.22,3.55c-1.71,1.98-3.05,4.28-4.55,6.45"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M818.82,184.97c-2-0.5-4.53-0.39-5.9-1.6c-5.89-5.19-10.4-1.17-14.43,2.28c-2,1.7-2.8,4.21-6.21,3.01
                c-0.52-0.18-1.93,2.15-3.09,3.51c-1.08-0.55-2.54-0.85-3.28-1.75c-3.73-4.5-8.64-2.44-13.03-2.19c-0.93,0.05-2.74,3.69-2.34,5.19
                c3.2,12.07,6.34,24.2,10.52,35.95c2.22,6.23,6.46,11.73,9.79,17.56"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M248.86,1216.21c-2.24,18.01-2.37,36,0.53,53.96c0.09,0.54,0.63,1,0.97,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M399.72,1290.65c5,1.33,9.92,3.31,15,3.87c14.46,1.6,28.93,2.95,42.95,7.12"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M492.63,1097.8c-18.32,0.17-36.63,0.33-54.95,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M195.91,241.93c-2.12,11.51-8.05,21.39-13.5,31.47c-0.57,1.05-1.37,1.98-1.98,3.01c-1.59,2.73-2.07,6.62-7.06,5.18
                c-1.12-0.32-2.94,1.8-4.44,2.8c-5.87-2.39-8.36-6.63-7.52-12.99c1.35-10.26-6.35-17.3-9.49-25.97c-0.32-0.9-0.93-1.7-1.48-2.5
                c-2.07-3.02-3.78-6.13-2.99-10"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M601.53,240.93c-0.23,2.03-0.45,4.06-0.75,6.79c-3.1-1.38-5.27-2.35-7.85-3.49c-2.06,3.87-4.91,1.04-7.34-0.38
                c-3.57-2.08-6.19-4.07-6.7,2.56c-0.3,3.9-7.02,5.55-10.79,2.96c-1.22-0.84-2.08-2.24-3.02-3.45c-2.46-3.16-2.4-3.16-5.01,0.5
                c-2.59,3.63-5.83,2.04-9.53,0.81c-0.16,1.49-0.88,3.14-0.36,4.15c2.26,4.34-0.04,5.19-3.59,5.02c-4.95-0.25-10.43,1.21-13.16-5.39
                c-1-2.42-9.46,0.25-10.06,3.97c-0.91,5.61-4.04,2.53-6.24,2.35c-1.71-0.14-3.32-1.29-5.01-1.87c-1.52-0.53-3.1-0.91-5.12-1.48
                c-4.05,3.39-5.95,2.99-13.38-3.55c-4.62,2.25-8.94,0.84-12.85-1.64c-0.74-0.47-0.11-3.13-0.11-5.3c-2.62-1.3-5.03-5.06-9.5-3.05
                c-0.42,0.19-1.25,0.24-1.45-0.01c-3.1-3.85-7.87-4.26-11.95-6.14c-1.66-0.77-3.83-4.15-3.33-5.21c2.09-4.49-0.73-6.58-3.21-9.14
                c-0.58-0.6-0.7-1.63-1.04-2.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1094.56,1335.12c-8.61,16.54-17.77,32.74-30.04,46.91c-3.06,3.54-6.93,6.38-10.43,9.55"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1044.61,1358.6c-4.76,8.33-9.05,16.81-16.87,23.13c-6.56,5.3-11.83,12.16-18.16,17.78c-13.6,12.07-30.23,16.39-47.89,18.14
                c-8.05,0.8-15.99,2.57-23.99,3.91"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M174.93,1014.86c-7.83,0-15.88-1.19-23.42,0.27c-11.86,2.3-23.36,6.39-35.02,9.72c-9.21,2.63-17.24,7.52-24.99,12.97
                c-1.21,0.85-2.65,1.36-3.98,2.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M729.41,487.75c-10.31,1.18-19.71-1.96-28.99-5.96c-6.93-2.99-14-5.64-20.96-8.55c-3.23-1.35-4.37,0.32-5.01,3.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M969.68,762.54c-0.83,6-1.78,11.98-2.47,17.99c-1.25,10.82-2.34,21.65-3.55,32.47c-0.13,1.19-0.64,2.33-0.97,3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1002.15,81.55c0.37-15.05-5.98-28.86-7.99-43.47c-0.37-2.66-0.67-5.33-1.06-8.5c-3.75,4.88-5.99-0.17-9.22-1.31
                c-0.85,1.27-1.65,2.46-2.49,3.71c-1.35-0.5-2.98-1.1-5.11-1.88c-0.24,1.6-0.33,2.81-0.63,3.97c-0.6,2.35-0.8,5.02-2.11,6.91
                c-1.68,2.43-2.83,4.42-0.87,7.08"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M775.86,542.21c1.33-6,3.22-11.94,3.82-18c0.49-4.94,3.03-6.86,7.16-7.99c1.72-0.47,3.4-1.12,6-1.98
                c1.25,3.61,3.2,7.46,3.87,11.51c1.13,6.92,1.85,13.96,2.02,20.97c0.17,6.98-0.56,13.99-0.89,20.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M227.38,971.39c0.67,6.66,2.74,13.55,1.69,19.93c-1.84,11.22-0.14,21.43,4.27,31.54c0.24,0.56,0.03,1.32,0.03,1.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M817.32,1180.74c-5.33,0.67-10.65,1.86-15.99,1.89c-10.66,0.05-21.31-0.6-31.97-0.84c-1.66-0.04-3.33,0.61-4.99,0.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1086.57,1121.28c0,4.66-0.47,9.39,0.1,13.98c1.17,9.37,2.98,18.65,4.37,28c1.28,8.64,2.42,17.31,3.49,25.98
                c0.49,3.98,0.69,7.99,1.03,11.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M291.82,542.71c-4,0-7.99-0.06-11.99,0.02c-3.67,0.08-7.35,0.16-10.98,0.59c-0.92,0.11-1.83,1.45-2.48,2.41
                c-1.91,2.78-4.17,5.44-5.44,8.51c-5.81,14.01-16.76,22.32-30,28.56c-4.5,2.12-8.38,5.55-12.54,8.38"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M258.35,251.92c0.33,0.5,1.04,1.1,0.94,1.48c-2.13,8.09,3.92,15.42,2.22,23.53c-0.19,0.92,1.62,2.91,2.87,3.35
                c4.56,1.61,9.23,3.06,13.97,3.98c2.19,0.42,5.41-1.64,6.82-0.66c4.07,2.82,9.71,0.65,13.21,4.72c0.43,0.49,1.94-0.06,2.92,0.09
                c2.84,0.43,5.69,0.86,8.49,1.51c2.65,0.62,4.66,4,8.08,1.58c0.62-0.44,2.62,0.2,3.36,0.95c4.75,4.82,11.16,6.32,16.98,9.07
                c2.88,1.36,5.05,4.2,7.56,6.35c2.15,1.85,4.16,3.93,6.52,5.45c5.92,3.8,6.59,5.14,4.78,12.83c4.23,0.74,8.46,1.42,12.67,2.23
                c4.34,0.84,5.49,4.68,6.23,8.04c0.29,1.31-2.33,3.33-3.77,4.9c-0.44,0.48-1.35,0.52-2.43,0.89c1.98,2.44-0.41,6.53,3.49,7.55
                c2.54,0.66,5.31,0.33,7.97,0.61c5.67,0.6,11.37,1.07,16.96,2.12c1.78,0.33,3.25,2.3,5.68,4.15c4.25-0.46,9.28,0.93,13.4,5.63
                c2.23,2.54,5.18,4.5,7.93,6.54c4.6,3.41,9.31,6.68,13.97,10.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M622.51,1236.19c-13.65,0.5-27.32,0.84-40.96,1.59c-4.36,0.24-8.66,1.57-12.99,2.41"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M719.92,646.63c2,10.66,4.09,21.3,5.96,31.98c1.19,6.8,2.03,13.65,3.03,20.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M557.07,385.82c-0.33,1.33-0.95,2.66-0.96,4c-0.07,15.99-0.04,31.98-0.04,47.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M241.87,990.38c-0.53,7.72,1.5,16.03,6.34,20.62c11.1,10.53,17.79,23.5,25.27,36.26c3.13,5.34,7.87,9.72,11.82,14.6
                c0.54,0.67,0.69,1.64,1.03,2.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M536.59,1263.17c3.83,1.17,7.7,2.22,11.48,3.52c13.5,4.61,27,9.26,40.45,14.01c2.44,0.86,4.68,2.29,7.01,3.45"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M900.75,600.66c1.05-1.89,2.1-3.79,3.16-5.68c0.57-0.03,1.14-0.06,1.71-0.09c0.71,4.26,1.92,8.5,2.03,12.77
                c0.29,11.32,0.06,22.65,0.12,33.97c0.02,3.33,0.31,6.66,0.48,9.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M100,498.74c-1,0.83-2,1.67-3,2.5c-4.19-5.12-8.58-6.44-15.01-4.49c-1.04-1.41-2.09-2.83-3.45-4.67c-0.8,1.95-1.36,3.33-2.11,5.16
                c-2.24-1.78-4.3-3.42-6.49-5.16c-3.86,4.63-6.52,0.98-9.13-1.46c-3.61,1.45-6.89,2.93-10.29,4.04c-1.35,0.44-3.81,0.69-4.34-0.03
                c-3.82-5.23-8.25-2.65-12.79-1.29c-0.61-1.22-1.22-2.43-1.86-3.7c-2.15,0.69-4.29,1.45-6.47,2.06c-1.12,0.31-3.09,0.87-3.37,0.45
                c-2.61-3.89-5.96-1.42-9.1-1.61c-3.03-0.18-5.96-1.93-9.96-3.36c-0.56,2.2-1.45,4.12-1.47,6.05c-0.13,11.49-0.07,22.98-0.07,34.47
                c0,9.06,1.9,11.64,10.49,11.8c3.55,0.07,6.73,0.16,10.05,1.55c1.51,0.63,3.65-0.25,6.06-0.51c7.09,6.93,12.54,0.34,18.7-4.95
                c0.25,2.41,0.39,3.76,0.59,5.7c3.6-0.89,7.63,2.55,10.62-2.06c0.2-0.3,1.5-0.32,1.9,0c3.42,2.81,6.01,0.14,9.09-0.85
                c2.18-0.7,4.88-4,7.58-0.3c0.15,0.2,2.51-1.22,3.84-1.89"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M215.89,1248.69c-3.91,11.04-4.04,22.52-3.38,33.97c0.34,5.87,1.88,11.66,2.88,17.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M878.27,1449.04c1.17-1.5,2.33-3,3.83-4.93c-3.78-0.55-7.3-1.07-10.83-1.56c-8.32-1.17-16.62-2.82-24.99-3.35
                c-6.78-0.42-13.65,0.46-20.47,0.93c-0.71,0.05-1.34,1.25-2,1.92"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1055.6,2.11c-3.83,10.99-7.88,21.91-11.4,33c-1.85,5.83-2.76,11.96-4.09,17.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1119.04,890.45c-0.17,5-0.32,9.99-0.5,14.99c-0.32,8.66-0.92,17.32-0.88,25.98c0.01,4-1.34,8.14,1.63,12.18
                c1.1,1.49-0.74,5.14-1.24,7.8"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M964.69,901.44c0.33,1,0.94,1.99,0.96,3c0.23,15.65,0.12,31.32,0.77,46.96c0.12,2.97,3.17,5.83,5.16,9.25
                c-1.27,0.36-2.83,0.81-4.39,1.26"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1050.11,982.38c-1.17-0.67-2.26-1.56-3.51-1.94c-1.42-0.43-3.32,0.11-4.43-0.64c-13.3-8.92-28.06-14.63-43.04-19.83
                c-2.48-0.86-5.3-0.74-7.97-1.07"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M558.57,846.98c-0.83,2.17-1.49,4.42-2.56,6.46c-0.63,1.19-2.65,3.02-2.82,2.89c-3.55-2.6-5.8,0.99-8.59,1.69
                c-2.08,0.52-4.62,0.25-4.88,3.93c-0.06,0.89-4.84,1.44-7.51,2.17c-2.42,6.16-5.05,12.83-7.67,19.49
                c-6.02-2.06-12.66,0.82-15.42,6.34c-0.27,0.53-0.6,1.39-1,1.45c-5.87,0.82-7.2,5.48-8.44,10.06c-0.75,2.76-1.44,5.65-1.42,8.48
                c0.02,2.17,1.25,4.32,1.86,6.5c0.75,2.68,0.39,4.66-2.98,5.02c-3.2,0.35-3.43,2.22-2.59,4.99c0.51,1.68,0.8,4.07-0.02,5.4
                c-5.63,9.08-3.83,14.74,6.11,18.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M861.28,588.67c5.45,8.72,8.51,17.86,7.69,28.49c-0.55,7.25,0.89,14.64,1.23,21.97c0.09,1.81-0.6,3.66-0.94,5.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M696.44,797.52c0.22,4.35,0.22,8.12-5.41,9.77c-2.16,0.63-4.17,4.17-4.92,6.76c-1.52,5.27-3.62,9.79-8.66,12.44
                c-3.28,1.72-3.21,4.3-2.64,7.53c0.32,1.84,1.73,5.38-2.86,5.09c-0.44-0.03-1.47,1.58-1.41,2.38c0.32,4.82-1.56,7.54-6.56,8.1
                c-0.91,0.1-2.37,1.54-2.4,2.4c-0.21,6.16-0.11,12.32-1.26,18.18c-0.95,2.93-1.91,5.86-2.84,8.8c-0.21,0.65-0.13,1.7-0.54,1.94
                c-5.41,3.09-2.72,6.85-0.97,10.55"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M617.01,1455.03c-5.83,1.17-11.64,2.4-17.49,3.48c-10.37,1.92-20.75,3.75-31.13,5.62c-0.12,0.58-0.24,1.16-0.36,1.74
                c2.67,1.55,5.34,3.1,8.01,4.65"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M824.32,744.56c-4.66,1-9.34,1.94-13.97,3.07c-0.62,0.15-1.48,1.28-1.45,1.92c0.38,8.47-4.26,4.01-7.93,2.69
                c-1.17,1.07-2.27,2.39-3.66,3.26c-1.69,1.05-3.55,2.07-5.48,2.48c-5.45,1.19-10.98,2.06-16.47,3.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M42.05,664.12c3.87-3.11,6.78-1.9,10.09,1.4c3.92,3.91,8.64,7,12.87,10.62c3.35,2.87,9.83,1.61,10.52,7.96
                c5.83,1.17,11.61,2.66,17.5,3.41c5.94,0.75,11.98,1.05,17.97,1.02c7.66-0.04,15.32-0.83,22.98-0.88
                c15.9-0.1,31.95-1.14,46.96,5.94c2.49,1.18,5.16,2.09,7.41,3.62c0.85,0.57,1.29,2.67,1,3.85c-2.44,9.87-5.87,19.31-11.31,28.06
                c-4.31,6.92-4.38,15.06-4.51,22.93c-0.15,9.15-0.01,18.33,0.49,27.48c0.76,14,1.99,27.98,2.9,41.97
                c0.25,3.82,0.04,7.67,0.04,12.36c4.02-0.32,7.55-1.08,10.97-0.73c3.23,0.34,6.35,1.85,9.5,2.86c2.01,0.64,4,1.33,6,1.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M738.4,337.86c1.63-5.11,6.1-3.91,8.92-2.81c8.28,3.25,16.56,5.38,25.55,5.54c3.05,0.05,6.6,1.6,8.24,5.66
                c0.65,1.61,4.05,2.18,6.26,3.07c1.61,0.64,3.33,1,4.98,1.53c2.51,0.81,4.98,2.29,7.5,2.36c5.35,0.14,10.23,0.46,13.63,5.53
                c0.67,1,3.17,0.89,4.85,1.07c4.02,0.42,5.14,3.32,4.91,6.52c-0.58,8.01-1.56,16-2.45,23.98c-0.08,0.69-0.63,1.34-0.97,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M921.23,597.17c-4.75,8.01-5.94,17.05-7.49,25.98c-1.35,7.82-2.67,15.65-4,23.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M554.07,352.35c-2.33-4.83-4.83-9.59-6.94-14.51c-1.5-3.52-4.67-3.37-7.43-4.71c-6.75-3.27-16.19-2.08-19.67-11.23
                c-0.72-1.89-2.38-3.43-4.42-6.25c-1.87,5.09-3.19,8.65-4.5,12.22"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M769.87,190.97c-2.5-1.33-4.98-2.7-7.5-3.99c-5.35-2.75-10.3-1.75-14.98,2.99c-5.74,5.82-14.75,5.47-20.39-1.08
                c-1.12-1.3-0.8-3.74-1.68-5.37c-0.74-1.39-2.2-3.34-3.42-3.39c-8.79-0.36-17.82,1.3-26-3.59c-0.22-0.13-0.96,0.61-1.46,0.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M384.73,1310.64c5.99,1.33,11.96,2.8,17.99,3.96c8.47,1.63,16.97,3.11,25.48,4.47c1.76,0.28,3.65-0.27,5.48-0.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M661.97,594.67c1.33,6.66,2.81,13.3,3.96,19.99c1.64,9.5,5.88,18.53,5.13,28.48c-0.06,0.78,1.24,1.66,1.9,2.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M570.06,909.44c0.32-6.7-2.21-11.13-8.38-14.18c-0.88,1.34-1.8,2.73-2.65,4.03c-3.95-0.28-8-1.04-11.92-0.62
                c-1.84,0.2-3.43,2.67-5.33,4.28c-3.7-5.19-6.66,1.35-10.12,2.24c-5.16,1.34,0.59,6.5-2.73,9.78c-1.83-0.14-4.47,0.12-6.79-0.62
                c-5.13-1.63-6.91,1.82-7.04,5.59c-0.13,3.87,1.36,8.76-5.48,8.68c-0.82-0.01-1.65,2.49-2.5,3.81c-0.98,1.51-1.68,3.42-3.05,4.42
                c-5.12,3.78-4.53,12.9,0.98,16.18c1.15,0.68,1.98,2.89,1.95,4.38c-0.07,3.32-1.14,6.32-4.87,7.53c-1.61,0.53-3.09,1.46-5.51,2.65
                c3.29,5.47,6.39,10.64,9.5,15.8"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M768.87,392.82c2.05-8.23,4.1-16.47,6.23-25.01c4.3,1.86,4.74,4.71,4.8,8.02c0.23,12.49,0.58,24.98,1.04,37.47
                c0.04,1.02,1.34,1.95,1.9,3.01c1.88,3.6,3.63,7.25,2.02,11.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M626,309.88c1.17-0.17,2.47-0.76,3.47-0.42c2.74,0.93,5.42,2.12,8,3.45c4.14,2.13,6.79,1.46,7.16-4.76
                c4.48,2.92,8.4,5.03,11.76,7.82c3.05,2.54,5.12,4.27,9.19,1.07c4.25-3.34,6.76-2.23,8.79,2.86c1.36,3.42,3.3,5.04,7.07,4.5
                c4.05-0.58,6.99,1.01,9.67,4.31c1.25,1.54,4.73,2.44,6.75,1.91c2.68-0.71,4.08-0.97,5.63,1.69c2.24,3.85,5.04,5.87,10.05,3.3
                c1.84-0.94,5.86,0.29,7.74,1.88c7.04,5.97,14.67,9.74,24.1,9.41c1.98-0.07,3.99,0.61,5.99,0.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M693.94,604.66c0,2.5-0.3,5.04,0.05,7.49c1.89,13.01,3.94,25.99,5.98,38.97c0.08,0.54,0.63,1,0.97,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M357.76,544.71c-1.33,1.33-3.1,2.46-3.86,4.06c-0.65,1.36-0.53,3.42,0.02,4.87c0.31,0.82,3.03,1.7,3.22,1.46
                c3.77-4.99,6.15-0.3,9.15,1.03c4.04,1.78,8.43,2.78,12.4,4.68c2.14,1.02,3.62,3.35,5.59,4.82c0.91,0.68,3.01,1.35,3.34,0.96
                c2.54-2.94,4.77-1.03,7.11,0.1c6.32,3.05,12.22,6.64,20.01,5.81c3.97-0.42,8.28,2.38,12.49,3.74c-1.33,2.3-2.63,4.65-4.06,6.93
                c-0.49,0.78-1.4,2-1.96,1.92c-5.85-0.87-9.07,3.1-12.48,6.62c-2.83,2.92-6.54,2.45-10.01,2.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1141.52,1451.54c-1.17,2.5-2.49,4.94-3.47,7.5c-5.68,14.84-15.26,27.2-25,39.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M688.45,1130.77c-2,0.33-3.98,0.8-6,0.98c-11.49,1.04-22.98,2.01-34.46,3.03c-3.17,0.28-6.33,0.66-9.49,0.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M653.98,887.95c-2.66-1.33-5.43-2.5-7.96-4.04c-2.77-1.69-5.87-1.65-6.83,1.11c-1.71,4.94-6.97,4.47-8.5,3.6
                c-7.81-4.4-13.16-0.3-16.89,5.01c-2.9,4.14-7.39,2.56-10.32,5.25c-1.03,0.95-2.68,1.23-4.47,1.99c6.77,7.89-0.03,13.62-3.18,19.44
                c-2.56,4.72-3.98,10.14-0.6,16.38c0.37-2.66,0.41-4.27,0.86-5.75c0.43-1.41,1.31-2.67,1.94-4.02c0.7-1.48,1.36-2.99,1.99-4.5
                c0.12-0.28-0.1-0.9,0.03-0.96c4.19-1.99,7.51-4.95,10.6-8.42c1.45-1.63,4.14-2.29,6.38-3.07c5.09-1.77,8.76-6.73,14.99-6.21
                c1.9,0.16,4.09-1.65,5.95-2.86c2.76-1.79,4.94-3,7.16,0.96c0.95,1.69,3.47,2.49,5.57,3.89c2.94-2.45,5.69-5.12,8.84-7.21
                c1.62-1.07,3.91-1.23,5.93-1.55c1.46-0.23,3.48,0.57,4.4-0.15c3.59-2.82,6.29-1.07,9.33,1.3c1.18-0.89,2.44-1.84,2.91-2.2
                c7.41,1.65,14.57,2.59,19.31,8.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M876.77,432.29c1.72,1.98,3.45,3.97,5.23,6.02c-1.6,1.92-2.91,3.07-3.67,4.51c-0.8,1.51-1.82,3.5-1.44,4.93
                c1.91,7.13,3.44,14.74,10.41,18.93c4.21,2.53,7.89,4.7,6.58,10.61c-0.28,1.29,1.07,3.04,1.89,4.45c1.57,2.72,3.46,5.26,4.92,8.03
                c1.2,2.28,2.65,3.14,5.04,1.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M618.51,619.65c-5.49,0.5-11.18,0.28-16.45,1.65c-11.03,2.87-12.75,15.29-11.96,22.83c0.23,2.21,1.27,4.34,1.93,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M103.99,689.1c2,3.16,3.61,6.69,6.09,9.41c3.64,3.99,8.32,7.07,11.74,11.21c2.03,2.45,3.44,6.18,3.49,9.37
                c0.21,14.15-0.19,28.31-0.31,42.47c-0.02,1.83,0.31,3.66,0.47,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M749.39,1044.84c12.85,7.78,26.95,2.89,40.46,3.96c1.48,0.12,2.99-0.3,4.49-0.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M999.65,1037.34c-2,4.66-5.11,9.19-5.7,14.03c-0.64,5.34,1.16,10.94,1.67,16.45c1.09,11.65,2.02,23.31,3.02,34.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1143.52,1440.04c-0.67,12.49-1.35,24.98-1.99,37.47c-0.36,6.99-0.67,13.99-1,20.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M796.34,1209.71c-10.66,0.5-21.31,1.38-31.97,1.39c-10.16,0.01-20.31-0.92-30.47-1.34c-0.82-0.03-1.66,0.62-2.5,0.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M241.87,1224.2c-1.58,15.57,0.72,30.81,4,45.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M797.34,1249.68c6.91,6.44,16.13,7.82,24.52,10.88c7.46,2.71,15.25,4.56,22.95,6.55c2.73,0.71,5.65,0.73,8.48,1.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M229.88,1083.31c-13.49,0.17-26.98,0.3-40.46,0.54c-1.84,0.03-3.66,0.62-5.5,0.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1095.06,1334.12c0.33,1.33,1.24,2.83,0.91,3.97c-3.94,13.41-8.28,26.65-16.83,38.05c-0.7,0.93-0.4,2.61-0.57,3.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M591.54,1283.16c-9.66,0.17-19.32,0.25-28.97,0.53c-5.33,0.15-10.66,0.63-15.99,0.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M144.96,1041.34c-14.29,2.17-28.52,4.29-40.05,14.38c-2.36,2.06-5.9,2.77-8.9,4.1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M537.09,279.4c6.6,16.84,16.4,31.69,28.3,45.39c-11.53-4.15-20.77-11.17-28.8-19.91"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M841.3,1384.59c-7.99-7.14-18.12-7.83-27.99-8.88c-5.6-0.6-11.31-0.11-16.97-0.11"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M467.65,636.14c-0.99,1.74-1.98,3.48-2.62,4.6c5.99,6.75,11.49,12.93,16.98,19.11c-0.19,0.67-0.38,1.34-0.57,2.01
                c-2.1,0.42-4.21,0.77-6.29,1.27c-3.18,0.77-6.31,1.73-9.5,2.45c-1.62,0.36-3.34,0.26-4.98,0.55c-5.98,1.08-11.37-4.05-17.52-1.6
                c-0.75,0.3-2.05-0.43-2.95-0.94c-1.89-1.07-3.08-2.22-5.68-0.19c-2.49,1.94-5.36,0.26-6.38-3.26c-0.32-1.1-1.71-2.95-1.88-2.88
                c-4.61,2.15-8.68-1.33-13.05-1.15c-1.66,0.07-3.47,0.44-4.97-0.07c-5.39-1.8-10.68-3.93-16.01-5.91c-0.64-0.24-1.54-0.7-1.96-0.45
                c-5.43,3.25-6.29-1.43-8.01-4.56c-0.35-0.64-0.5-1.39-0.94-2.67c-3.57,4.58-7.11,1.9-10.55,0.65c-3.45-1.26-6.78-2.82-10.36-4.33
                c-3.31,6.93,8.03,4.59,4.4,11.37c-1.05,0-3.05,0.03-5.05-0.01c-6.46-0.12-13.18,1.6-19.05-2.9c-0.65-0.5-2.29,0.5-3.42,0.36
                c-2.68-0.34-5.32-1.05-8-1.41c-1.64-0.22-3.7-0.62-4.88,0.14c-1.03,0.66-1.93,3.2-1.45,4.25c1.39,3.03,2.19,6.91-0.31,8.28
                c-3.36,1.83-7.83,1.67-11.83,2.27c-1.98,0.29-3.99,0.35-5.99,0.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M530.09,578.68c0.45,8.25,3.05,16.05,4.97,23.99c1.12,4.63,1.6,9.46,3.11,13.95c3.21,9.56-0.22,18.7-1.09,28.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M816.33,1227.7c-10.66,0.5-21.31,1-31.97,1.5c-3.66,0.17-7.33,0.28-10.99,0.51c-4.33,0.28-8.66,0.65-12.99,0.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M198.91,1289.16c-1.83,12.66-3.74,25.31-5.43,37.98c-0.3,2.27,0.27,4.65,0.43,6.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M694.94,776.03c-3.18-9.87-10.09-15.84-18.48-15.99c-10.3-0.18-16.34,7.11-14.49,17.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M461.66,546.7c0.83,9.99,1.65,19.99,2.5,29.98c0.41,4.84,0.88,9.68,1.36,14.97c1.09-0.82,1.86-1.4,2.63-1.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M812.33,1163.75c-6.1,3.92-13.12,4.39-19.99,4.9c-6.46,0.47-12.98,0.01-19.47,0.17c-1.51,0.04-3,0.93-4.5,1.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M270.84,544.21c-3,6-6.01,11.98-8.96,18c-0.26,0.54-0.03,1.32-0.03,2.3c10.27-4.82,19.66-10.75,27.39-18.88
                c4.23-4.46,9.79-2.82,14.65-4.13c1.93-0.52,4.62,1.51,6.45-1.27c0.1-0.15,0.66-0.11,0.96-0.01c4.74,1.56,9.46,3.14,12.86,4.27
                c3.82-0.12,6.69,0.42,9.08-0.41c4.67-1.62,8.72-0.15,13.07,0.97c1.75,0.45,3.95-0.87,5.95-1.34c6.15-1.43,11.99-1,18.06,1.3
                c3.94,1.49,8.57,0.53,12.92,0.41c3.07-0.08,6.2,1.86,9.16,4.07c1.47-0.88,2.94-1.76,4.02-2.4c4.27,0.79,8.3,1.53,12.83,2.37
                c2.15-6.42,7.06-2.09,9.25-0.49c5.09,3.73,10.42,2.61,15.68,2.53c1.5-0.02,2.97-2.46,4.86-4.15c2.85,1.22,6.46,2.87,10.16,4.27
                c0.62,0.23,1.82-0.39,2.43-0.96c2.17-1.99,4.2-4.13,6.59-4.93c4.97-0.01,9.97,0.21,14.91-0.19c1.25-0.1,2.46-2.08,3.49-3.36
                c2.28-2.83,4.43-5.76,7.2-9.4c-1.49-6.72-1.49-6.72-10.04-9.7c1.99-2.82,0.85-4.1-2.06-5.51c-3.29-1.59-7.09-3.6-2.69-8.47
                c2.07-2.29-0.61-6.84-3.87-7.35c-3.83-0.6-7.7-1-12.12-1.56c0.97-5.78-0.48-9.42-6.86-8.45c-2.37-4.31-11.22-1.07-10.49-8.99
                c-3.78-0.85-5.04-7.35-10.54-4.6c-0.77,0.38-2.24-0.66-3.81-1.18c-2.32,6.51-7.19,3.86-11.62,3.31
                c-4.98-0.62-9.98-1.25-14.99-1.47c-5.65-0.25-11.33-0.06-17.73-0.06c-1.09,3.89-4.17,2.69-7.79,1.09
                c-2.62-1.15-5.61-1.48-8.45-2.08c-1.98-0.42-3.99-0.71-5.99-1.01c-1.49-0.22-3.01-0.25-4.49-0.53c-2.18-0.42-4.33-0.98-6.5-1.48
                c-2.16-0.5-4.6-1.89-6.44-1.32c-4.15,1.29-7.55,1.12-10.41-2.54c-3.81,2.18-7.54,4.45-10.17-1.12c-0.16-0.33-1.04-0.61-1.44-0.48
                c-7.34,2.32-13.5-3.4-20.49-2.98c-4.04,0.24-8.06,1.02-11.85,1.52c-2.85-1.51-5.69-3.15-8.64-4.55
                c-4.11-1.94-8.25-3.85-12.51-5.43c-1.26-0.47-2.97,0.2-4.46,0.41c-4.34,0.62-8.83-0.96-13.12,1.78c-1.38,0.88-4.33-0.71-6.61-1.18
                c0,3.39,0,5.39,0,7.57c1.25,0.82,2.75,1.81,4.25,2.8c-4.07,6.69,3.54,2.85,4.62,5.33c-1.05,1.41-2.22,2.95-3.57,4.75
                c2.47,0.59,4.55,1.08,7.42,1.76c-3.1,2.55-5.4,4.23-7.44,6.2c-1.31,1.27-4.25,2.82-1.9,4.79c1.8,1.5,6.45,2.73,7.06,1.94
                c2.07-2.68,4.11-1.62,6.28-1.26c4.82,0.81,7.65-2.64,11.12-5.54c1.44,2.02,2.67,3.76,4.2,5.9c1.71-1.28,3.44-2.58,4.61-3.45
                c4.92-0.13,8.31,6.13,13.76,1.32c0.99-0.87,4.88,1.21,7.25,2.31c3.32,1.54,6.37,3.13,9.09-1c0.45-0.69,1.92-0.72,2.92-1.05
                c0.33,1.17,1.28,2.64,0.88,3.43c-0.95,1.86-2.22,3.91-3.93,4.97c-6.01,3.74-12.4,6.87-18.37,10.67
                c-3.65,2.32-6.78,5.45-10.11,8.18c-9.96-0.08-17.03,9.14-27.5,9.97c2.28,4.99,6.77,5.01,10.56,6.32c1,0.35,1.69,1.59,2.52,2.42
                c-1,0.67-1.97,1.39-3.01,1.98c-1.1,0.63-2.26,1.15-3.79,2.87c2.1,0.38,4.18,0.84,6.29,1.12c1.98,0.27,3.99,0.35,5.99,0.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M390.73,256.92c-2.66,5.16-5.07,10.48-8.09,15.43c-1.81,2.98-4.08,6.24-6.98,7.9c-12.65,7.26-20.98,18.25-27.89,30.62"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M969.18,955.4c10.01,3.39,16.64,11.36,23.27,18.67c7.88,8.69,14.21,18.79,21.15,28.32c0.45,0.62,0.37,1.64,0.54,2.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M768.87,498.24c-3,0-6.01,0.19-8.99-0.06c-2.52-0.21-5.04-1.5-7.49-1.34c-9.04,0.59-16.18-3.71-22.37-9.22
                c-7.46-6.64-14.12-14.18-21.06-21.39c-0.58-0.6-0.96-1.62-0.99-2.47c-0.32-8.9-2.3-16.86-10.97-21.58
                c-1.07-0.58-1.24-2.66-2.1-3.87c-0.81-1.14-2.11-3-2.93-2.87c-5.18,0.8-6.7-3.55-9.56-6.11c-1.95-1.74-4.49-2.83-6.39-4.61
                c-1.63-1.52-2.74-3.59-4.05-5.43c-1.4-1.98-2.77-3.99-4.82-6.95c0.15-1.21,0.48-3.87,0.82-6.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1073.58,503.24c-3.89,1.35-7.44,4.31-11.99,2.5c-5.93,8.97-11.35,0.47-16.98-0.53c-1.86-0.33-3.64-1.16-5.51-1.39
                c-0.94-0.12-2.23,0.32-2.93,0.98c-1.2,1.12-2.05,2.61-3.05,3.94c-1.5-1.33-3.05-2.61-4.47-4.03c-0.5-0.49-0.66-1.32-1.55-3.19
                c-2.89,3.23-5.41,6.04-7.93,8.85c0.3,0.48,0.59,0.95,0.89,1.43c-1.47-0.19-3.02-0.16-4.41-0.61c-2.5-0.81-4.77-3.89-7.51-0.45
                c-2.33-2.17-4.66-4.33-6.99-6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M763.38,1231.7c6.26,5.6,14.35,6.8,21.99,8.94c6.76,1.9,13.64,3.38,20.47,5.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M966.18,171.48c0,0.17,0.01,0.33,0,0.5c-0.16,1.83-0.33,3.66-0.5,5.5c1.26-1.25,2.51-2.5,4.31-4.28c-2.52-1.4-4.54-2.52-6.87-3.81
                c-2.36,4.74-5.6,4.85-9.44,2.63c-1.02-0.59-2.87-1.37-3.39-0.94c-6.09,5.08-9.06-0.31-12.51-3.66c-0.75-0.73-1.35-2.55-0.96-3.39
                c3.03-6.55-2.01-8.23-6.62-10.52c-3.7,2.5-6.51,3.97-11.44-1.05c-3.78-3.84-7.46-8.71-12.55-11.38c-1.9-1-4.27-1.19-6.45-1.56
                c-5.38-0.92-6.48-3.76-4.72-8.6c0.85-2.34-0.12-5.94-1.43-8.32c-1.65-2.99-4.5-1.22-6.83-0.09"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M831.81,64.56c2,9.49,3.92,19,6.02,28.47c1.34,6.03,2.97,12,4.47,17.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M194.91,1054.83c-2.66,0.83-5.31,1.75-8,2.48c-2.85,0.77-5.6,3.8-8.76-0.27c-0.62-0.8-4.77,0.99-7.21,1.81
                c-8.41,2.84-16.99,0.7-25.48,1.04c-2.51,0.1-5,0.94-7.5,1.44"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M211.39,669.61c-1-0.83-1.89-1.87-3.02-2.45c-1.39-0.71-3.36-1.83-4.36-1.33c-1,0.5-1.59,2.8-1.53,4.27
                c0.36,9.17,0.95,18.32,1.52,28.64c-8.66-9.17-18.11-15.52-30.07-17.15"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M530.09,574.68c-0.88,1.97-1.76,3.93-3.08,6.87c-1.39-2.81-2.19-4.32-2.9-5.88c-1.65-3.64-5.81-5.1-8.39-2.39
                c-2.04,2.14-3.78,5.07-4.46,7.92c-1.2,5.04-1.42,10.3-2.2,15.45c-0.16,1.05-0.95,2.01-1.45,3.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M604.02,778.53c-0.17-0.5-0.18-1.24-0.52-1.46c-2.66-1.66-4.77-6.22-8.29-3.78c-2,1.39-2.21,5.39-3.16,8.24
                c-0.27,0.8-0.33,1.66-0.38,1.94c-2.88,0.73-6.25,0.62-7.92,2.26c-1.71,1.68-1.77,5.05-2.39,7.13c-4.04-1.21-8.4-3.42-12.82-3.56
                c-3.45-0.1-7.79,1.52-10.31,3.89c-5.35,5.02-10.31,10.74-10.66,18.81"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M368.25,252.42c-4.5,12.99-9.01,25.97-13.45,38.98c-0.31,0.9-0.04,1.98-0.04,2.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M189.91,1294.15c-2.83,11.82-5.62,23.66-8.51,35.47c-1.81,7.42-2.52,14.76-1.72,22.51c0.65,6.29-0.77,12.8-1.26,19.21"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M243.86,1136.77c10.16,2,20.29,4.15,30.48,5.92c3.75,0.65,7.65,0.41,11.48,0.58"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M126.47,1082.56c-2.44-2.61-4.97-2.14-8.05-0.88c-2.4,0.98-5.29,1.18-7.94,1.06c-11-0.51-21.98-1.28-32.97-1.89
                c-0.48-0.03-1,0.62-1.5,0.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M340.27,946.41c3.66-0.17,7.33-0.25,10.99-0.53c2.67-0.2,6.66-1.9,7.76-0.75c4.29,4.52,8.96,2.35,13.7,2.66
                c2.09,0.13,3.92,4.42,5.4,7.39c3.99,0.99,9.24-0.52,11.79,5.15c0.19,0.42,2.85-0.27,5.05-0.53c2.66,3.72,6.02,6.77,12.33,4.85
                c3.63-1.1,9.04-1.87,9.95,4.75c0.07,0.54,0.56,1.37,0.98,1.45c7.04,1.37,5.75,8.31,8.48,12.55"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M740.9,649.63c2.08,14.82,4.36,29.62,6.11,44.48c0.46,3.89-0.74,7.97-1.1,11.98c-0.13,1.49-0.02,3-0.02,4.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M611.52,1222.7c-19.99-4.2-40.26-2.73-60.44-2.95c-1.16-0.01-2.33,0.62-3.5,0.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M250.36,451.27c-2.5-2.5-4.62-5.82-7.63-7.19c-1.9-0.87-2.86-1.66-3.96-3.23c-0.83-1.18-2.64-1.64-3.87-2.6
                c-1.11-0.86-2.02-1.97-3.02-2.97c1.17-0.83,2.22-1.98,3.52-2.43c2.15-0.74,4.46-1.02,6.7-1.5c0.09-0.69,0.17-1.38,0.26-2.08
                c-10.79-5.4-21.58-10.79-31.91-15.96c-3.18,1.1-5.86,2.04-8.54,2.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M765.37,1169.74c8.99,1.67,18.03,3.14,26.96,5.06c6.07,1.31,12,3.26,18,4.93"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M527.6,362.84c-1.17,9.66-2.74,19.29-3.35,28.99c-0.42,6.61,0.52,13.31,0.86,19.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M855.79,82.55c2.5,1.17,5.12,2.13,7.46,3.55c3.34,2.03,3.33,4.54,0.01,7.92c-2.85,2.89-5.24,5.57-1.5,9.54
                c3.89,4.12,7.1,4.06,11.02-0.52c4.48,2.75,7.31,6.3,6.06,12.01c-0.16,0.73,0.37,1.92,0.97,2.43c2.17,1.83,4.5,3.46,6.06,4.64
                c-1.54,3.9-2.79,7.16-4.11,10.39c-3.48,8.5-7.12,16.94-10.37,25.53c-0.64,1.69,0.22,3.95,0.38,5.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M779.36,1109.79c-5.09-0.36-6,4.65-9.29,6.61c0.13,0.4,0.26,0.79,0.38,1.19c2.14-0.27,4.3-0.39,6.4-0.83
                c7.33-1.54,14.6-2.83,21.1-7.3c4.21-2.89,9.45-5.44,14.4-5.81c5.21-0.39,10.79,1.51,15.92,3.26c8.13,2.76,16.01,6.26,24.03,9.33
                c1.02,0.39,2.31,0.05,3.48,0.05"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M613.02,602.66c-1.33-6.83-2.41-13.72-4.09-20.46c-1.02-4.08-2.96-7.93-5.33-11.8c-4.67,13.61-8.34,27.15-9.56,41.25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M641.49,1136.77c6.12,4.95,13.79,5.41,21.01,6.87c6.21,1.26,12.63,1.46,18.96,2.12"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M669.46,1147.76c9.91,5.73,21.05,7.52,31.98,9.96c2.61,0.58,5.32,0.7,7.98,1.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M514.11,354.85c0.61-1.52,1.22-3.05,2.45-6.14c0.81,2.44,1.35,3.51,1.51,4.64c1.38,9.82,2.7,19.65,4.02,29.47
                c0.36,2.66,0.67,5.33,1,7.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M575.05,365.34c-0.33,1.67-0.87,3.32-0.97,5c-0.57,10.32-0.85,20.66-1.6,30.97c-0.46,6.36-1.71,12.65-2.37,19
                c-0.22,2.12,0.27,4.32,0.44,6.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M494.13,270.41c-4.16,3.2-10.76,2.54-15.19-1.69c0.89-1.4,1.81-2.84,3.18-5.01c-6.88-0.88-13.37-1.71-20.15-2.58
                c1.02,4.26,0.64,4.55-2.78,5.39c-1.17,0.29-2.26,1.74-2.99,2.91c-5.93,9.49-11.76,19.05-17.57,28.5
                c-3.44-2.85-3.44-2.85-3.44-18.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M193.91,1465.53c-2.27,1.52-4.55,3.03-6.82,4.55c-0.01,0.61-0.03,1.23-0.04,1.84c5.12,1.2,10.18,3.06,15.36,3.46
                c10.29,0.8,20.64,1.07,30.96,0.97c2.91-0.03,5.81-2.07,7.64-2.77c7.45,0.69,14.14,1.32,20.83,1.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M333.78,1093.3c3.84,2.6,10.1,2.01,11.83-1.58c0.65-1.35,0.09-4.06-0.93-5.34c-8.06-10.17-19.34-12.82-31.35-11.37
                c-9.94,1.2-19.5,4.45-28.08,10.17c-2.27,1.51-5.57,1.99-8.41,2.03c-8.66,0.11-17.32-0.23-25.97-0.4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M887.76,590.67c3.87,14.58-0.21,29.33,0.96,43.97c0.27,3.31,0.04,6.66,0.04,9.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M570.56,1.61c-12.99,0-25.98,0-38.96,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M591.04,648.63c-7.29-1.21-10.85,1.96-10.45,9.49c0.13,2.43,0.7,4.04-3,4.7c-5.79,1.03-8.33,6.89-7.03,13.79"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M803.34,1101.29c-2.33-0.5-4.64-1.25-7-1.46c-13.48-1.17-26.96-1.98-40.47-0.11c-3.1,0.43-6.32,0.06-9.48,0.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M154.45,688.6c0.87,7.35,3.41,13.97,8.04,19.68c0.61-0.02,1.21-0.04,1.82-0.06c0.38-6.04,0.76-12.08,1.13-18.13"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M580.05,564.69c-0.67,4.16-1.42,8.32-1.98,12.49c-1.07,7.99-2,15.99-3.05,23.98c-0.07,0.53-0.63,1-0.96,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M94.5,469.76c2.5,1.88-0.13,5.9,3.45,7.58c3.36,1.58,6.24,4.16,9.56,5.85c5.81,2.97,11.07,6.56,13.85,12.6
                c1.32,2.87,1.44,6.28,2.11,9.44"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1054.1,1085.81c-1.57,9.51-6.78,17.37-11.44,25.51c-2.19,3.83-3.87,7.94-5.78,11.93c0.7,0.37,1.4,0.74,2.11,1.1
                c8.04-12.68,16.07-25.36,24.11-38.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M716.92,543.21c0-1.17,0.04-2.33-0.01-3.5c-0.15-3.83-0.44-7.66-0.47-11.49c-0.07-8.66-0.02-17.33-0.02-25.99
                c-0.4-0.16-0.8-0.32-1.2-0.48c-1.61,2.66-3.21,5.31-5.58,9.23c-0.62-6.38-0.57-12.5-8.36-13.53c-3.43,4.54-4.35,10.26-4.94,16.27
                c-0.48,4.88-1.89,9.67-2.89,14.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M131.97,428.29c0.17,0.83,0.33,1.67,0.5,2.5c4.81-1.02,3.6,3.28,5.16,5.37c3.64,4.88,7.85,9.47,12.4,13.53
                c5.84,5.19,12.24,9.75,18.4,14.58"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M986.67,183.97c-5.16,13.16-10.32,26.31-15.49,39.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M205.4,1352.11c-3.66,11.99-7.08,24.07-11.09,35.94c-2.15,6.38-6.04,5.97-11.06,1.74c-6.18-5.2-6.88-10.8-4.82-17.69"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M775.86,563.69c-0.17-5.5,0.05-11.04-0.6-16.48c-0.71-5.88-1.59-11.9-3.59-17.43c-1.74-4.83-5.13-9.06-8.43-14.64
                c-1.32,1.99-2.72,3.41-3.29,5.1c-1.25,3.75-2.08,7.64-3.07,11.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1031.62,1162.75c-12.16,7.83-24.3,15.67-36.48,23.45c-0.86,0.55-1.98,0.69-2.98,1.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M627.5,762.54c-0.33-0.33-0.67-0.67-1-1c-2.44-2.43-1.87-7.76-8.59-5.82c-7.35,2.12-12.67,4.76-13.91,12.82
                c-0.36,2.35-0.98,4.67-1.48,7"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M967.68,1198.72c-7.49,12.99-15,25.97-22.43,39c-0.36,0.63-0.05,1.64-0.05,2.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M860.28,587.67c-1.17,11.66-2.32,23.32-3.53,34.97c-0.09,0.86-0.63,1.67-0.97,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M453.17,424.29c-2.33,1.74-4.44,1.87-7.08,0.13c-1.54-1.02-4.86-1.78-5.74-0.94c-2.98,2.85-6.54-0.02-9.09,1.45
                c-4.16,2.4-11.23-2.36-12.89,5.73c-2.06-1.11-3.84-2.06-5.53-2.98c-5.8,3.34-11.69,4.74-18.14,2.67
                c-0.86-0.27-1.98,0.28-2.98,0.44c-1.16,0.18-2.56,0.83-3.46,0.42c-4.27-2-7.05-1.97-11.03,0.58c-2.22,1.43-11.78,0.44-13.48-0.78
                c-12.46,3.42-23.39,1.77-33.97-3.72"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M836.81,248.92c-0.83,0-1.73-0.2-2.48,0.05c-0.75,0.25-1.71,1.52-1.96,1.36c-6-3.76-13.82-0.28-19.52-4.93
                c-2.56-2.09-4.78-4.72-7.59-6.33c-1.7-0.98-4.24-0.48-6.41-0.65"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M549.08,381.83c1.33,7.83,2.78,15.64,3.95,23.49c0.61,4.12,0.72,8.32,1.05,12.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1061.1,1090.8c-1.67,5.33-3.02,10.78-5.08,15.96c-2.31,5.8-5.29,11.34-7.86,17.04c-0.37,0.83-0.05,1.98-0.05,2.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1025.63,1147.26c-9.99,10.99-20.05,21.92-29.91,33.03c-1.9,2.14-3.05,4.94-4.55,7.44"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M647.98,404.81c0.33,2.66,0.46,5.37,1.03,7.99c2.68,12.28,0.82,24.65,0.95,36.98c0.07,6.66,0.01,13.32,0.01,19.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1001.65,186.47c-6.87,12.1-15.45,22.85-25.5,32.46c-0.85,0.82-1.98,1.35-2.98,2.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M666.97,416.3c2.33,3.66,4.67,7.32,6.98,11c0.27,0.43,0.58,1.05,0.48,1.48c-1.85,7.73,3.81,13.83,4.53,20.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1010.64,1238.19c-6.33,8.83-12.81,17.55-18.92,26.52c-3.35,4.92-6.06,10.28-9.05,15.45"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M857.79,1130.77c-7.51,8.4-17.24,13.88-26.53,19.89c-1.06,0.68-2.94,0.05-4.44,0.1c-5.52,0.17-11.04,0.36-16.56,0.55
                c-0.14,0.79-0.27,1.59-0.41,2.38c11.68,6.36,23.36,12.72,35.04,19.08c-0.2,0.31-0.39,0.61-0.59,0.92
                c-5.99,0.68-11.99,1.36-17.98,2.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M632,344.85c7.77,9.07,9.61,21.2,16,30.96c1.37,2.09,2.98,4.01,4.48,6.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M935.71,1176.74c-12.56,11.35-26.29,21.07-40.65,29.91c-0.45-0.23-0.9-0.46-1.34-0.69c0.51-2.08,0.52-4.48,1.62-6.18
                c4.28-6.63,8.9-13.05,13.4-19.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M326.04,1257.68c0.08,3.83,0.17,7.66,0.25,11.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M922.23,746.56c-2.16-1.5-4.52-2.79-6.45-4.55c-2.88-2.63-5.68-5.12-7.8-8.79c-3.23-5.6-8.19-5.47-13.74-1.14
                c-2.82-1.31-5.23-3.51-8.64-0.18c-1.94,1.9-5.52,2.12-8.33,3.17c-3.34,1.25-7.64,0.97-9,5.48c-3.87-1-6.71,4-11.09,1.69
                c-1.36-0.72-3.9,0.81-5.89,1.31"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M237.87,1235.2c-1.25,11.74,0.69,23.15,3.5,34.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M278.83,243.93c-0.83-1-1.88-1.89-2.45-3.02c-0.76-1.51-1.15-3.21-1.75-4.98c-5.68,3.63-9.58,3.78-14.18,0.11
                c-2.32,1.29-4.6,2.56-7.09,3.95c-1.36-1.18-2.57-2-3.49-3.07c-4.1-4.75-11.59-3.51-13.45,2.54c-3.37,10.93-6.39,21.97-9.55,32.96"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M540.08,407.81c-0.33,3.83-0.77,7.66-0.98,11.49c-0.41,7.49-0.69,14.99-1.02,22.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M896.75,603.66c-1,5.66-2,11.32-3,16.99c-1,5.66-2,11.32-3,16.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M745.39,515.23c1.16-2.33,2.32-4.67,3.76-7.55c4.46,6.21,4.55,13.33,5.67,20.05c0.99,5.94,1.4,11.98,2.07,17.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1.59,992.38c-0.17,10.66-0.43,21.32-0.46,31.98c-0.02,6.33,0.3,12.66,0.46,18.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M981.67,1024.85c-4.64,4.85-9.28,9.7-14.47,15.14c-0.4-7.81,5.01-12.18,8.76-17.29c-0.54-0.4-1.08-0.8-1.62-1.19
                c-4.52,4.81-9.05,9.63-14.43,15.35c0.38-7.87,5.77-11.84,8.78-17"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M857.29,1340.62c11.48,8.38,21.66,17.93,27.97,30.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M659.47,405.81c-3.69,1.67-4.47,4.62-4.59,8.49c-0.41,13.16-1.22,26.32-1.94,39.47c-0.03,0.52-0.63,1-0.96,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M969.18,1114.78c0.24,2.51-3.43,6.63,2.99,6.85c0.59,0.02,0.92,5,1.53,7.64c2.01,8.74,3.25,17.85,11.32,24.65
                c9.02-4.38,18.07-8.77,27.12-13.15"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M569.56,677.11c-1.33,0.5-2.92,1.73-3.95,1.37c-4.36-1.54-6.36,0.83-8.25,3.98c-9.47-6.14-17.59-3.88-21.26,6.14"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M812.83,74.05c7.33,9.83,14.58,19.71,22.05,29.42c1.33,1.72,3.59,2.72,5.42,4.05"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M965.19,177.98c-2.83,9.83-3.03,19.88-2.44,29.97c0.08,1.36,0.94,2.67,1.44,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M719.92,644.63c-1,3.16-2.45,6.26-2.91,9.51c-1.11,7.79-1.75,15.64-2.59,23.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M271.34,1041.84c5.66,0,11.33-0.18,16.98,0.04c8.72,0.35,16.53,3.24,24.09,7.77c5.44,3.26,11.84,4.95,17.9,7.11
                c2.03,0.72,4.31,0.73,6.47,1.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1008.15,24.59c-0.95,11.01,0.72,22.29-3.41,33.01c-0.35,0.9,0.58,2.3,0.91,3.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M980.67,1227.2c-3,4.16-6.25,8.17-8.93,12.53c-4.54,7.37-8.72,14.95-13.04,22.45"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M277.33,239.43c2.56,0.57,5.27,2.97,7.28-1.37c0.31-0.66,4.86-3.48,6.8,0.83c0.36,0.79,2.08,1.67,2.88,1.44
                c7.46-2.11,13.92,0.99,20.52,3.56c1.07,0.42,2.32,0.51,3.48,0.51c5.14-0.02,9.85,1.04,13.49,5.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M215.89,1018.36c0.17,3.33,0.18,6.68,0.54,9.99c0.37,3.37,1.08,6.7,1.64,10.04c-5.4,6.92-11.87,12.78-21.16,14.45"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M713.92,180.97c4.5,7.99,8.91,16.04,13.53,23.96c1.74,2.98,3.95,5.69,5.95,8.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M854.79,89.04c-0.33,5.66-1.08,11.34-0.89,16.98c0.23,7.01,1.23,13.99,1.89,20.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1029.13,1165.25c-5.99,7.49-12.08,14.92-17.94,22.52c-2.42,3.13-4.37,6.63-6.54,9.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M540.58,1056.83c-10.32,0.33-20.65,0.68-30.97,0.95c-0.49,0.01-1-0.62-1.5-0.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M692.94,613.65c-4,10.83-7.99,21.65-11.99,32.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1037.12,91.04c2.83,1.17,5.57,2.81,8.51,3.4c5.75,1.15,12.62,7.91,12.89,13.59c0.04,0.79-0.98,1.62-1.27,2.08
                c5.38,7.78,10.61,15.34,15.84,22.9c0.13,0.19,0.46,0.33,0.48,0.5c0.38,5.99,0.72,11.99,1.11,18.53c0.84,0.42,2.37,1.19,3.91,1.95"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M786.35,1420.56c11.66,2.66,23.32,5.29,34.95,8.07c0.98,0.23,1.68,1.59,2.52,2.42"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M671.46,474.76c2.63,1.14,3.31,3.04,3.63,5.98c1.7,15.35,3.89,30.66,5.82,45.99c0.25,1.97,0.03,3.99,0.03,5.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M541.58,276.9c3.45,6.8,9.91,7.94,16.49,8.34c1.59,0.1,3.29-1.68,4.3-2.24c5.88,0.49,11.58,0.96,18.16,1.51
                c-4.89,2.99-4.14,6.27-1.39,8.76c2.91,2.63,6.57,4.43,9.9,6.6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M278.33,936.42c0.33,1.33,0.79,2.65,0.96,4c0.17,1.31-0.18,2.7,0.07,3.99c1.59,8.01,3.29,16,4.96,23.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M315.3,1226.2c-0.33,7.33-0.74,14.65-0.93,21.99c-0.03,1.15,1.27,2.31,1.35,3.5c0.12,1.98-0.25,3.99-0.42,5.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M133.47,465.26c1,6.66,2,13.32,3,19.99c0.67,4.5,1.33,8.99,2,13.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M97.5,645.13c-2.36,2.84-0.12,3.46,2.01,3.96c0.95,0.22,2.21-0.34,2.95,0.1c6.06,3.56,12.17,7.05,17.96,11.01
                c3.44,2.35,5.25,5.94,5.11,10.41c-0.11,3.49,0.28,6.99,0.44,10.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M173.43,233.44c-1.88,9.33-4.84,18.67,0.03,27.96c0.59,1.13,1.63,2.02,2.46,3.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M995.66,1234.7c-11.66,8.33-23.31,16.65-34.97,24.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M755.38,342.86c-1.33-1.17-2.56-2.48-4.02-3.47c-3.59-2.43-3.67-2.54-1.48-7.03c10.47,4.01,9.34-5.78,12.76-11.29
                c2.26,2.82,4.23,5.31,6.23,7.79c0.82,1.01,1.47,2.38,2.53,2.93c2.75,1.41,5.85,3.05,6.7-1.95c4.58,0.5,8.62,4.63,13.33,1.11
                c0.58,0.58,1.36,1.17,1.9,1.93c4.47,6.24,11.36,8.68,18.01,11.45c2.11,0.88,4.43,1.23,3.09,4.57c-0.36,0.89,0.48,2.32,0.91,3.45
                c1.84,4.89,5.74,5.73,9.44,1.97c0.91-0.93,1.4-2.26,2.5-4.11c4.35,5.19,7.75,9.11,10.96,13.18c0.69,0.88,1.38,2.89,0.95,3.36
                c-3.66,4.06,1.4,5.99,2.46,8.43c0.82,1.89,4,0.6,4.64-2.34c0.62-2.84,1.32-5.66,1.99-8.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M186.42,1038.34c-2.33,2.5-4.36,5.41-7.06,7.41c-4.93,3.65-10.2,6.85-15.45,10.03c-2.15,1.3-4.63,2.05-6.96,3.05"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M722.41,600.66c1.8,10.26,3.36,20.54,1.5,30.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1057.1,1081.31c-5.83,8.33-11.6,16.69-17.51,24.96c-2.69,3.77-5.64,7.35-8.47,11.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M969.68,1122.28c-0.17,11.33-0.33,22.65-0.5,33.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M166.44,569.19c0.67,0.67,1.27,1.82,2.01,1.91c2.95,0.38,6.46-0.96,7.98,3.08c4.6-2.47,6.72,2.15,9.99,3.49
                c6.02,2.47,10.07,6.82,11.93,13.01c0.86,2.86,1.78,1.94,3.05,0.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1116.54,898.94c-1.67,8.83-3.3,17.66-5.02,26.47c-0.3,1.54-0.97,3-1.47,4.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M672.96,417.3c0.67-4.66,1.7-9.32,1.82-13.99c0.05-1.97-1.48-5.4-2.88-5.74c-5.17-1.26-6.19-5.6-7.85-9.28
                c-3.03-6.73-6.13-7.89-13.13-5.11c-2.1,0.83-5.07,0.28-7.4-0.43c-6.04-1.85-9.56,0.75-9.02,7.08c0.31,3.67,0.66,7.33,1,10.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M741.9,989.88c-5.2,7.57-5.16,7.6,1.95,13.05c0.69,0.53,0.98,1.93,1,2.94c0.1,4.49,0.05,8.99,0.05,13.99
                c2.15,0,4.08-0.52,5.43,0.14c1.47,0.72,3.36,2.5,3.4,3.86c0.15,5.25,3.11,9.68,8.14,11.54c2.28,0.84,4.35,2.28,6.51,3.44"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M974.68,1211.71c-7.33,11.49-14.67,22.97-21.93,34.51c-0.25,0.4,0.28,1.3,0.45,1.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M473.15,580.68c1.33,5.66,2.95,11.28,3.91,17c0.89,5.26,1.08,10.64,1.58,15.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M789.35,390.32c-0.5,1.67-1.34,3.31-1.44,5c-0.47,7.65-0.71,15.32-1.1,22.98c-0.03,0.52-0.63,1-0.96,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M225.88,1044.84c-8.69,7.27-18.77,10.15-29.97,9.49c-0.17-0.01-0.33,0-0.5,0c0.17-0.33,0.33-0.67,0.5-1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1045.61,1084.31c-4.5,9.16-9.02,18.31-13.44,27.5c-0.39,0.81-0.05,1.97-0.05,2.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M952.2,1194.73c-1.83,3.5-3.67,6.99-5.49,10.49c-4,7.66-7.99,15.32-11.99,22.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1021.63,1150.76c-8.66,4.66-17.29,9.38-26,13.94c-1.39,0.73-3.14,0.77-4.73,1.12c-0.26-0.55-0.51-1.09-0.77-1.64
                c7.67-6.97,15.34-13.94,23.01-20.91"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M160.94,644.13c8.48-2.73,14.58,3.06,21.58,5.4c-2.08,11.15-1.38,21.48,2.35,31.59c0.32,0.88,0.04,1.98,0.04,2.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1.59,536.71c0,5.5,0,10.99,0,17.22c2.38-1.36,4.34-3.23,6.52-3.53c2.09-0.29,4.41,1.05,6.67,0.88c3.76,0.47,7.54,0.84,11.28,1.45
                c2.81,0.45,5.23,1.37,8.13-0.86c2.45-1.88,5.78-1.6,5.96,3.12c8.13-6.07,18.61,3.7,26.34-6.11c1.24,1.77,1.91,3.71,3.1,4.11
                c0.99,0.34,3.11-0.8,3.8-1.87c0.95-1.48,1.03-3.52,1.34-4.74c2.76,0.39,4.36,0.37,5.76,0.89c1.66,0.62,3.15,1.7,4.48,2.45
                c3.94-1.31,7.91-2.64,11.88-3.96c0.01-0.58,0.02-1.16,0.03-1.75c-5.56-2.96-11.12-5.91-16.8-8.93c1.46-2.92,2.7-5.39,3.84-7.66
                c-2.47-3.32-4.86-6.56-7.52-10.13c1.85-0.85,3.81-1.74,4.89-2.23c2.7,0.82,5.03,1.33,7.2,2.23c1.77,0.74,3.24,2.27,5.04,2.89
                c6.67,2.31,11.03,8.49,18.9,9.55c4.73,0.63,6.97-1.18,10.06-2.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M537.09,513.73c0.83-0.83,1.67-1.66,2.74-2.73c2.3,2.39,2.92,7.8,7.71,5.67c5.9,3.82,10.04,9.95,18.02,10.17
                c2.52,0.07,4.94,1.94,7.51,2.3c6.46,0.9,13.03,1.1,19.45,2.18c4.91,0.82,9.67,2.56,14.5,3.9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M403.21,479.25c-2.68,2.96-4.41,6.08-10.04,5.49c-3.99-0.42-8.47,3.74-11.76,5.38c-7.34-0.48-14.04-0.91-21.87-1.42
                c2.06-2.57,3.18-3.97,4.03-5.03c-0.51-1.88-0.92-3.4-1.33-4.92"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M978.67,181.47c-3.66,7.99-7.3,16-11.01,23.97c-0.88,1.89-1.98,3.67-2.98,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1060.1,24.09c-4.98,11.4-10.23,22.61-20.94,30.03c-0.31,0.22-0.04,1.28-0.04,1.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1017.64,16.6c-3.38,2.42-6.77,4.85-10.29,7.37c-2.2-0.88-4.65-2.52-7.21-2.72c-3.88-0.31-4.5,3.04-3.9,5.83
                c2.1,9.71,4.57,19.34,6.94,28.99c0.1,0.39,0.64,0.67,0.97,1.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M266.34,239.43c1.5,9.16,3.04,18.31,4.46,27.49c0.25,1.63,0.04,3.33,0.04,4.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M175.93,796.52c4.39,2.2,5.89,5.78,5.37,10.8c1.78,1.22,4,2.64,6.1,4.22c1.12,0.84,2.74,1.87,2.9,2.99
                c1.13,7.93,8.43,10.98,12.62,16.46c0.06,0.08,0.32,0.01,0.49,0.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M275.83,1205.22c-5.05,5.39-1.61,11.41-0.85,16.97c1.68,12.22,4.18,24.34,6.34,36.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M877.27,611.66c-0.83,5.33-3.17,11-2.06,15.89c1.04,4.58,0.69,8.72,0.56,13.09"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M212.39,1085.81c-3,0.33-6,0.99-8.99,0.93c-6.5-0.12-12.99-0.6-19.48-0.93"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M907.74,1165.25c-6.66,8.66-13.34,17.31-19.94,26.01c-0.46,0.61-0.37,1.64-0.54,2.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M420.7,400.81c-1.69-0.96-3.37-1.93-5.39-3.08c-2.42,1.68-4.54,3.35-8.73,1.98c-4.06-1.33-9.24,0.38-13.83,1.26
                c-1.11,0.21-1.82,2.43-2.51,3.43c2.55,8.77,12.55,3.51,16.48,9.43c-4.64,4.22-9.59,4.24-15.06,1.6c-1.08-0.52-3.04,0.31-4.41,0.92
                c-2.83,1.26-5.05,2.08-8.66,0.88c-2.86-0.95-7.55-3.08-11.06,1.4c-1.34,1.71-5.26,2.39-7.75,1.98c-5.23-0.87-9.86,0.23-14.52,2.17
                c-0.63,0.26-1.49,0.69-1.97,0.46c-6.36-3.09-10.94,0.38-15.54,4.01c-0.41,0.32-1.3,0.04-1.96,0.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M670.46,886.45c1.83-0.33,3.94-1.39,5.43-0.83c2.16,0.82,5.23,1.26,6.32,2.13c5.47,4.34,13.28,5.28,17.18,11.72
                c0.49,0.81,0.38,1.97,0.54,2.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M957.69,983.38c-3.14,0.46-6.28,0.92-10.81,1.59c3.19-2.87,5.38-4.85,8.45-7.61c-4.56-0.58-8.09-1.02-11.63-1.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M218.39,252.92c-2.81-4.34-5.62-8.69-8.4-12.99c-0.42,0-0.92-0.14-1.06,0.02c-4.19,4.89-8.82,2.82-13.52,1
                c-6.61-2.57-6.72-2.46-8.99,4.48c-2.79,8.53-5.66,17.02-13,22.97c-0.75,0.61-1.65,1.01-2.49,1.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M174.93,692.1c0,9.5,0,19,0,28.5c0.52,0.07,1.04,0.14,1.56,0.21c5.49-6.91,7.65-15.23,9.43-23.72"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1014.14,1116.78c-10.51-11.09-23.49-18.31-36.7-25.18c-0.42,0.57-0.84,1.14-1.27,1.71c2,1.83,4,3.65,6,5.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1008.15,1241.19c-7.66,5.33-15.32,10.66-22.98,15.99c-1.67,1.16-3.33,2.33-5,3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1037.12,1078.81c-3,6.16-6.01,12.32-8.98,18.49c-1.46,3.03-2.85,6.09-3.65,9.51c8.44-6.26,12.93-15.56,19.13-23.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1013.64,1252.18c-2.33,3.5-4.5,7.12-7.03,10.47c-4.99,6.63-9.44,13.52-11.95,21.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1064.59,1099.8c-3.66,6.83-7.2,13.73-11.05,20.45c-1.64,2.87-3.94,5.36-5.93,8.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M644.49,784.53c-6.47-4.83-16.67-4.83-21.98,0c-4.7,4.28-3.57,10.28,1.93,13.6c3.69,2.23,6.73,5.55,10.06,8.38"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M172.93,651.63c0.17,8.83,0.11,17.67,0.61,26.47c0.2,3.58-0.98,4.04-4.11,4.08c-9.33,0.11-18.65,0.66-27.98,0.9
                c-13.53,0.35-26.02-2.54-35.93-12.5c-4.09-4.12-5.54-8.4-1.03-14.96c-3.17-4.31-5.71-11.17-4.82-14.07
                c4.28,6.42,10.39,9.65,17.31,11.57"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1018.14,1246.19c-2.5,2.33-5.03,4.63-7.49,7c-6.51,6.31-12.95,12.7-19.52,18.95c-1.8,1.71-3.97,3.03-5.96,4.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M672.46,1189.73c7.49,1.33,14.99,2.63,22.47,4.02c1.86,0.35,3.67,0.98,5.5,1.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M216.39,598.17c0,1.82-0.01,3.63-0.01,5.45c0.53,0.29,1.05,0.57,1.58,0.86c-5.52,3.89-11.04,7.79-16.56,11.68
                c-4.62,3.26-8.57,2.57-11.36-1.8c-4.38,2.28-8.75,4.55-13.45,6.99c-2.62-3.1-5.52-5.41-10.24-3.4c-1.25,0.53-3.9-1.09-5.35-2.36
                c-5.99-5.27-9.09-4.44-12.66,3.01c-1.51,3.15-4.22,5.72-6.38,8.55"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M289.82,1282.16c-4.37,10.55-11.29,19.18-19.99,26.47c-0.1,0.08-0.36,0.07-0.48,0c-0.2-0.12-0.34-0.32-0.51-0.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M233.87,1254.18c1.83,9.33,3.66,18.65,5.49,27.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M941.71,1189.23c-4.16,6-8.47,11.9-12.43,18.03c-2.01,3.11-3.39,6.62-5.06,9.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M947.2,1184.23c-11.16,8.83-22.31,17.65-33.47,26.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M142.46,642.13c0.17,6.16,0.28,12.33,0.51,18.49c0.27,7.16,0.65,14.32,0.98,21.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M930.22,1183.23c-3.83,5.66-7.82,11.23-11.44,17.02c-2.6,4.16-4.71,8.63-7.05,12.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M418.7,260.92c-1.83-1-4.17-1.58-5.4-3.08c-3.82-4.63-8.61-2.35-13.08-2.41c-3.83-0.05-7.74,0.48-11.47-0.13
                c-4.77-0.79-9.91,1.57-12.07,6.09c-1.18,2.48-2.98,4.67-4.41,7.04c-0.34,0.56-0.59,1.37-0.45,1.96c0.1,0.43,0.92,0.68,1.42,1.02
                c0.17-1,0.33-2,0.5-3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M689.44,459.77c-0.83-4.66-1.24-9.46-2.66-13.94c-0.85-2.67-3.29-4.83-5.55-7.96c-1.06,3.2-1.67,5.06-2.28,6.91"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M213.39,1312.14c-2.62,10.14-4.38,20.59-10.5,29.47c-3.34,4.85-5.15,5.16-9.98,2.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M126.47,599.17c0.17,2,0.22,4.01,0.52,5.99c0.94,6.17,2.13,12.31,2.9,18.5c0.3,2.44-0.3,4.98-0.41,7.49
                c-0.36,7.83-0.67,15.65-1.01,23.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M955.69,1190.73c-10.82,9.16-21.65,18.31-32.46,27.49c-0.89,0.76-1.67,1.66-2.51,2.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1067.09,1094.3c-2.5,8.66-4.98,17.32-7.5,25.98c-1.65,5.67-3.33,11.33-4.99,16.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M123.98,746.06c-2.04,0.35-3.91,2.78-6.22-0.33c-0.69-0.93-4.41-0.45-6.2,0.46c-3.65,1.84-5.55,0.78-7.11-2.6
                c-0.68-1.48-1.7-3.49-2.99-3.88c-5.7-1.72-9.68-7.38-16.46-6.82c-1.56,0.13-4.18-1.79-4.83-3.37c-2.56-6.29-7.6-6.67-13.14-6.44
                c-1.33,0.06-2.66,0.01-5.04,0.01c1.32,2.61,1.89,5.98,3.64,6.78c6.04,2.78,9.65,7.75,13.34,12.74c0.81,1.1,0.92,2.7,1.59,3.94
                c0.43,0.79,1.29,1.93,1.95,1.93c8.45,0.01,10.4,6.62,12.98,12.58c1.12,2.6,2.11,5.25,3.42,8.52c2.57-4.44,5.23-5.13,9.11-4.16
                c3.77,0.94,7.71,0.59,8.27,6.1c0.07,0.73,4.35,1.03,6.68,1.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M156.94,1457.53c-8.99-0.67-17.98-1.33-26.97-2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M174.43,750.05c5.5,4.02,6.22,6.65,3.5,12.49c-1.43,3.08,0.3,4.08,2.99,4.5c7.28,1.13,9.6,7.75,13.28,12.64
                c0.82,1.09-1.03,4.2-1.8,6.89c4.28,0.75,8.01,4.34,13,2.44"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M985.67,1221.71c-10.49,8.83-20.99,17.64-31.46,26.5c-1.43,1.21-2.68,2.65-4.01,3.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M86.51,596.67c0,1.35,0,2.7,0,4.33c-0.85-0.47-1.46-0.66-1.46-0.83c0.22-5.62,1.58-10.93,5.06-15.41
                c0.58-0.75,2.86-0.99,3.84-0.48c4.05,2.13,6.34,0.18,8.01-3.12c1.94-3.84,4.42-5.51,9.07-4.71c2.1,0.36,5.51-1.13,6.77-2.9
                c2.65-3.71,4.75-1.61,7.11-0.32c3.37-2.43,6.52-3.39,10.67-1.27c1.68,0.86,4.65-0.79,6.96-2.43c1.78,0.94,3.56,1.88,5.47,2.89
                c1.43-1.72,2.79-3.35,5.07-6.09c0.99,2.07,1.74,3.65,2.09,4.37c4.9-1.2,8.55-2.29,12.28-2.96c4.31-0.78,6.82-6.09,12.01-4.64
                c0.29,0.08,1.31-1.19,1.42-1.91c0.78-5.33-6.62-11.72-11.95-10.54c-1.08,0.24-2.53,0.07-3.45-0.5
                c-5.58-3.45-11.43-3.99-16.66-1.97c-4.83-1.75-8.5-3.7-12.37-4.31c-4.05-0.64-6.36-2.75-9.12-5.51c-5.05-5.06-6.09-9.8-1.89-15.58
                c1.36,0.65,3.6,2.11,3.88,1.8c1.2-1.33,1.96-3.14,2.59-4.88c0.25-0.68-0.49-1.67-0.4-2.47c0.72-6.04,0.75-6.04-4-8.84
                c-0.84-1.05-1.56-2.87-2.55-3.03c-5.39-0.9-9.08-2.93-12.54-7.06c-0.65-0.77-2.26-0.73-3.42-1.06c-1.17-0.33-2.41-0.51-3.48-1.04
                c-1.06-0.52-1.94-1.4-3.2-2.34c-1.25,1.08-2.41,2.08-4.18,3.61c1.82,0.36,2.84,0.56,3.86,0.77"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M795.35,972.39c-0.99,6.57-2.07,12.87-6.22,18.68c-2.33,3.26-1.3,8.72-2.89,12.75c-4.55,11.55-2.34,23.3-1.88,35.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M910.74,1161.75c-9.16,6.99-18.3,14.01-27.5,20.95c-0.61,0.46-1.64,0.37-2.47,0.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M795.84,406.81c2.67,8.64-0.51,17.75,2.68,26.59c1.63,4.51-0.02,10.21-0.18,15.38"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M147.95,816.51c6.52,2.26,8.42,7,7.54,13.5c-0.58,4.3,0.91,7.65,5.45,8.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M562.06,390.82c0.55-3.85,1.1-7.7,1.65-11.55c4.13,5.28,4.63,11.43,5.37,17.54c0.34,2.85,0.97,5.67,1.47,8.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M971.18,746.56c6.91-2.25,10.37,3.45,15.19,6.63c4.11-2.09,6.96-1.59,9.52,3.24c1.86,3.53,5.37,6.44,10.25,6.78
                c1.75,0.12,3.38,2.12,5.09,3.27c6.56-4.84,5.74,3.7,8.44,4.76c4.44-1.05,8.48-2,12.21-2.88c1.99,0.75,3.87,1.46,5.74,2.17
                c0.5-1.83,0.93-3.69,1.53-5.49c0.35-1.05,1.06-2.91,1.46-2.87c5.4,0.5,3.54-3.42,4.13-6.11c0.32-1.48,1.72-2.87,2.93-3.96
                c0.89-0.8,2.53-0.79,3.4-1.6c4.03-3.81,3.98-3.87,8.95-1.78c1.79-3.96,4.52-6.3,9.08-3.67"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M132.47,873.46c0.17,8.66,0.33,17.32,0.5,25.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1035.12,1174.24c-10.95,7.77-19.31,18.94-31.97,24.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M963.19,1203.72c-5,4.16-9.95,8.38-15,12.47c-3.59,2.92-7.31,5.68-10.98,8.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M897.75,1348.61c-6.19-7.07-13.26-12.83-22.99-13.92c-4.6-0.52-8.25-2.49-11.48-5.56"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M199.9,1350.11c-1.83,9.66-3.66,19.32-5.49,28.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M970.18,1216.71c-7.49,7.16-14.97,14.34-22.5,21.47c-1.37,1.3-2.98,2.35-4.48,3.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M396.72,1115.78c6.16,3.33,12.32,6.67,18.48,9.99c0.98,0.53,2.19,0.82,2.91,1.58c0.34,0.36-0.25,1.58-0.41,2.42"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M216.89,1028.35c3.16,5,6.33,9.99,9.49,14.99c0.64,1.01,1.24,2.05,1.86,3.08c-2.47,3.67-5.02,7.46-8.01,11.9
                c1.63,0,2.82,0.35,3.63-0.06c6.21-3.06,12.38-6.22,18.5-9.45c6.49-3.42,13.36-1.57,17.73,2.3c8.09,7.17,17.48,10.78,27.22,14.23
                c0.75,0.26,1.34,0.97,2.01,1.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M812.33,855.98c-2.32-5.15-4.66-10.24-11.49-10.55c-0.69-0.03-1.29-1.02-2.01-1.42c-1.3-0.73-2.59-1.7-4-1.95
                c-4.7-0.84-9.63-0.37-14.06-2.91c-0.6-0.34-2.36,1.34-3.59,2.09c-3.42-1.98-6.66-6.57-11.45-1.36c-0.28,0.3-2.53-1.21-3.86-1.87
                c-2.88-1.43-5.26-1.74-8.1,0.88c-3.16,2.91-7.64,4.04-11.84,2.98c-3.8-0.96-5.19,0.49-6.03,3.64"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M157.44,470.76c-4.63,9.46-10.04,18.36-17.98,25.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M358.26,253.42c-2.66,8.49-5.33,16.99-7.99,25.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M192.91,1341.12c-2.66,4-5.32,8-8,11.99c-0.46,0.69-1.54,1.48-1.41,1.98c1.77,6.75-0.61,14.32,3.95,20.48
                c0.75,1.02,1.96,1.69,2.96,2.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M226.38,1000.87c-3.51,9.08,1.04,16.72,4.68,24.39c0.41,0.86,5.04-0.88,2.31,3.09"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M438.18,472.26c1.17,0.67,2.95,2.19,3.4,1.87c5.84-4.21,8.87,2.95,13.58,3.22c1.7,0.1,3.31,1.34,5.01,1.87
                c1.11,0.35,3.09,0,3.37,0.59c3.93,8.32,11.69,4.27,17.64,5.17c3.9,0.59,2.73-2.91,1.46-5.23"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M638.49,434.04c0,8.24,0,16.49,0,24.73"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M201.9,422.3c0.83,3,2.18,5.96,2.4,9c0.7,9.37,5.79,16.16,12.01,22.55c1.81,1.86,2.74,4.59,4.07,6.92"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M776.86,974.89c-0.6,12.81-0.23,25.45,6.49,36.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M288.32,944.41c-0.5,6-0.98,11.99-1.51,17.99c-0.29,3.33-0.66,6.66-0.99,9.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M615.51,324.87c4.72,9.83,9.96,19.3,18.48,26.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M272.34,1010.86c4.48,7.47,11.61,10.92,19.41,14.16c12.63,5.25,24.63,11.83,33.59,22.77c2.67,3.26,5.94,6.04,8.94,9.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M942.71,304.88c1.44,1.08,2.88,2.16,4.56,3.43c2.09-1.73,3.79-3.84,5.99-4.78c2.03-0.87,4.6-0.48,6.93-0.65c0,2.14,0,4.27,0,7.45
                c4.51,4.3,5.42,4.33,10.28,0.16c2.73,1.73,5.22,3.31,7.71,4.88"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M805.34,519.72c0.08,6.39-1.5,12.89,1.88,19.05c0.66,1.21-0.21,3.27-0.38,4.93"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M788.85,381.83c3.12,18.23,1.72,36.63,2,54.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M863.78,173.98c-3,7.99-5.99,15.99-8.99,23.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1074.58,257.92c-1.67-0.67-3.37-1.99-4.99-1.88c-6.17,0.4-7.47,3.22-5.07,9.4c0.64,1.65,0.91,3.82,0.34,5.38
                c-0.25,0.68-3.06,0.41-5.33,0.63c-1.86,1.8,2.17,8.98-5.96,6.95c0.43,4.15,0.8,7.79,1.28,12.39c-4-0.58-7.15-1.04-10.56-1.54
                c-3.8,4.17-17.21,6.59-22.6,3.55c-1-0.57-1.73-2.18-1.97-3.43c-0.44-2.28-0.41-4.65-0.58-6.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M237.37,237.43c-1.42-1.24-2.84-2.48-4.39-3.83c-3.79,1.56-3.79,1.56-11.59-3.85c-0.73,2.08-1.71,3.84-1.92,5.7
                c-0.31,2.8-0.3,5.68-0.02,8.48c0.29,2.93-1.17,3.56-3.56,3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1002.65,502.24c-2,1-3.9,2.48-6.01,2.9c-4.47,0.89-8,1.69-8.73,7.56c-0.49,3.9-4.33,1.22-6.74,1.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1081.58,2.11c-0.17,4,1.01,8.78-0.77,11.83c-4.17,7.16-10.48,12.56-18.36,15.2c-3.76-9.86,3.62-17.87,4.14-27.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M226.88,1258.18c0.17,9.33,0.33,18.65,0.5,27.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M156.94,643.13c-0.83,6-1.76,11.98-2.47,17.99c-0.78,6.65-1.36,13.32-2.02,19.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M492.63,300.89c6.33,7.66,12.65,15.32,18.98,22.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M893.75,1163.75c-4.66,2.66-9.33,5.32-13.98,8c-4.01,2.31-7.99,4.66-11.99,6.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M803.84,532.71c-0.67,5-1.24,10.01-2.04,14.98c-0.46,2.86-1.29,5.67-1.96,8.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M591.04,576.68c0.67,5.83,1.3,11.66,2.01,17.48c0.23,1.85,0.65,3.67,0.98,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M788.85,974.89c-3.16-0.33-6.32-0.84-9.49-0.95c-3.11-0.11-6.01-0.5-8.2,3.33c-2.03,3.55-5.3,7.15-8.91,8.84
                c-4.04,1.89-2.6,3.77-1.88,6.27c2.51,8.71,7.09,16.31,12.5,23.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1100.56,1162.25c0.97,11.49-1.25,22.55-4.5,33.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M880.77,1145.76c-9.85,6.87-19.69,13.74-29.54,20.61c-0.52-0.44-1.04-0.87-1.56-1.31c2.93-8.72,8.89-15.59,15.11-22.3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M290.82,1191.23c-0.5-0.17-1.03-0.27-1.49-0.51c-9.06-4.82-14.07-1.99-15.01,8.5c-0.03,0.35-0.32,0.67-0.48,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M743.89,512.73c0.17,2.66,1.43,6,0.29,7.87c-3.34,5.45-2.44,11.39-3.29,17.11"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1145.52,1290.16c9.41,3.59,11.57,12.5,14.99,20.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M337.28,1013.86c7.66,0.17,15.32,0.33,22.98,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1013.64,1127.78c-7.83,6.16-15.93,12.01-23.39,18.58c-6.25,5.51-7.21,6.63-0.08,12.89c0.62,0.55,1.33,1.01,1.99,1.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M171.43,657.62c-0.33,3.16-0.7,6.33-0.98,9.49c-0.16,1.83-0.18,3.67-0.33,6.78c-2.35-3.01-4.02-5.14-5.68-7.28"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M175.43,416.8c6.83-3.48,6.32-3.13,11.14,3.9c4.65,6.79,10.51,12.76,15.84,19.09"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M705.93,640.63c-1.5,7.33-3,14.66-4.5,21.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1070.59,2.11c-2.66,7.49-5.33,14.99-7.99,22.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M276.33,1133.27c-5,0.17-10,0.18-14.98,0.53c-7.5,0.53-14.99,1.3-22.48,1.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M228.38,235.43c-0.83,3.83-1.58,7.68-2.52,11.49c-1.07,4.35-2.31,8.67-3.48,13"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M486.14,581.68c-1.67,6.5-3.32,12.99-5,19.48c-0.26,1.02-0.66,2-0.99,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M796.34,588.67c-0.17,5.83-0.17,11.67-0.61,17.48c-0.12,1.55-1.56,3.01-2.39,4.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M243.36,1020.35c0.1,5.95,4.75,9.47,7.53,13.97c2.84,4.6,6,8.99,8.9,13.54c0.38,0.59,0.05,1.63,0.05,2.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M526.6,1233.7c-7.99,1.33-15.98,2.66-23.98,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M992.66,1238.19c-1.17,2.33-2.1,4.82-3.54,6.97c-5.06,7.57-10.28,15.02-15.44,22.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1050.61,947.41c-3.33-5.16-6.57-10.39-10.04-15.45c-1.18-1.72-2.92-4.17-4.45-4.21c-1.49-0.03-3.05,2.6-5.48,4.92
                c3.36,1.01,5.42,1.63,7.48,2.25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M811.33,1103.29c-2.16-0.67-4.57-2.3-6.45-1.83c-8.46,2.12-16.62,5.19-23.52,10.83"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1064.59,1122.28c-1.11,7.29-2.23,14.58-3.35,21.87c0.41,0.13,0.82,0.25,1.22,0.38c1.87-2.75,3.75-5.51,5.62-8.26"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M726.41,1196.72c-7.33-0.17-14.65-0.33-21.98-0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M629,323.37c-0.07-1.89-0.25-1.69-2.89-4.11c-0.84-0.77-1.14-3.62-0.48-4.25c2.9-2.77,0.46-3.99-1.11-5.63
                c-3.19-3.31-6.46-6.55-9.46-10.02c-2.1-2.43-4.48-2.48-7.02-1.42c-3.07,1.28-3.13,3.72-2.02,6.46c2.34,5.78,4.65,11.58,7.31,18.2
                c-1.46-0.52-3.68-0.63-4.72-1.8c-5.2-5.82-10.08-11.91-15.08-17.9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M690.44,505.73c1,8.16,2.08,16.31,2.94,24.49c0.22,2.12-0.27,4.32-0.44,6.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M243.36,1288.16c1.87,7.81-1.89,14.73-3.5,21.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M229.88,1260.18c1.17,7.33,2.33,14.66,3.5,21.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M370.24,272.91c-5.66,7.99-11.32,15.99-16.98,23.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1160.5,259.92c0,7.33,0,14.66,0,21.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M85.51,590.17c-4.32,2.03-9.65,0.81-13.61,4.37c-0.66,0.6-2.89-0.5-4.38-0.88c-1.91-0.49-3.8-1.05-5.88-0.93
                c5.4,9.43,14.11,7.38,22.36,6.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M571.56,596.67c-0.17,0.83-0.75,1.91-0.44,2.46c3.65,6.38,1.06,13,0.93,19.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M744.89,764.54c0.25-3.3-3.59-9.73-6.51-10.4c-1.04-0.24-2.6,0.25-3.41,0.98c-1.47,1.32-2.57,3.06-3.94,4.78
                c-2.52-2.06-3.45-7.54-8.2-3.45c-0.33,0.28-2-0.77-2.89-1.42c-3.87-2.81-7.31-3.18-10.01,1.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1041.61,948.41c-0.33,4.56-3.53,6.88-7.38,8.69c0.18,0.57,0.35,1.14,0.53,1.7c4.12-0.47,8.24-0.93,11.97-1.36
                c-1,2.55-2.05,5.25-3.11,7.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M50.54,435.79c0.67,1.17,1.33,2.33,2,3.5c-1.33,0.33-2.65,0.75-4,0.98c-3.99,0.71-7.97,1.59-12,1.87
                c-1.27,0.09-2.77-1.38-3.95-2.39c-1.15-0.99-1.83-2.59-3.06-3.41c-6.54-4.36-12.98-2.68-19.5,0.35c-1.17,0.54-2.96-0.16-4.44-0.42
                c-1.19-0.21-2.34-0.64-3.5-0.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M850.79,1138.27c-3.33,5.16-6.74,10.28-9.95,15.52c-1.08,1.77-1.66,3.85-3.06,7.18c8.18-5.28,15.09-9.74,22-14.2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1144.02,868.47c2.16,6.99,4.33,13.99,6.49,20.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M164.44,232.44c-1.27,7.14-2.55,14.28-3.85,21.61c-4.48-3.75-5.88-9.27-6.98-14.65c-0.63-3.07-1.55-4.58-4.65-4.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M919.73,1169.74c-11.16,8.33-22.32,16.64-33.45,25.01c-0.83,0.62-1.35,1.64-2.02,2.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1005.65,1132.27c-5,1.83-9.97,3.74-15,5.46c-1.87,0.64-3.89,0.84-5.84,1.24c-0.25-0.55-0.49-1.1-0.74-1.65
                c4.19-2.52,8.42-4.98,12.57-7.57c1.92-1.2,3.68-2.64,5.51-3.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M963.69,879.46c0.17,6.99,0.33,13.99,0.5,20.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M345.27,257.42c-0.56,1.32-1.13,2.63-2.23,5.2c-3.38-4.59-6.46-8.75-9.53-12.91c0,8.63,6.54,12.71,11.75,17.7"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1050.11,1161.25c-8.24,0.11-14.52,4.47-20.48,9.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M237.37,440.28c-4.18,7.36-10.42,12.09-18.48,14.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M66.53,477.76c-1,0.5-2.17,1.62-2.97,1.4c-6.11-1.68-12.17,2.92-16.87,1.94c-6.93-1.44-13.63,1.92-20.12-0.84"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M545.08,395.82c0.33,6.66,0.67,13.32,1,19.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M598.53,1229.7c6.33,1.33,12.67,2.6,18.97,4.06c1.09,0.25,2.01,1.27,3.01,1.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M796.34,1038.84c-9.32,0.17-18.68-0.05-27.96,0.63c-6.21,0.46-12.33,2.22-18.5,3.35c-1.48,0.27-2.99,0.35-4.49,0.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M90.51,1021.85c-1.17,6.16-0.82,13.28-3.93,18.21c-2.99,4.76-9.41,7.52-14.62,10.66c-2.64,1.59-5.93,2.1-8.93,3.1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M929.22,962.9c-0.15,3.48,2.96,3.91,4.89,5.62c2.34,2.07,4.09,3.05,7.17,1.5c1.47-0.74,3.63-0.13,6.48-0.13
                c-2.99,3.03-4.91,4.99-6.84,6.94c-0.32-0.42-0.64-0.84-0.95-1.26c0.75-0.56,1.5-1.12,2.25-1.68"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M107.49,466.76c2.47,7.47,5.79,14.34,14.25,19.62c-0.69-5.72-1.23-10.17-1.76-14.63c-0.18-1.5-0.74-3.08-0.44-4.48
                c1.97-9.11-3.2-15.04-8.72-21.08c-2.13-2.33-3.25-5.58-4.83-8.41"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M142.46,881.46c0.33,6,0.62,11.99,1.06,17.98c0.05,0.7,0.94,1.34,1.44,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M641.99,412.8c-0.67,4.5-1.33,8.99-2,13.49c-0.32,2.17-0.66,4.33-1,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M823.32,1119.78c-8.99,4.16-17.98,8.33-26.97,12.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M591.04,809.51c-7.57-3.18-12.19-2.27-14.44,3.02c-0.5,1.19-0.38,2.64-0.55,3.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M143.96,725.07c3.5,2.66,6.86,5.54,10.54,7.92c2.41,1.56,5.28,2.41,7.94,3.57"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M314.3,1272.17c-2,8.66-4,17.32-5.99,25.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M421.2,258.42c-1,2.17-3.08,6.09-2.84,6.24c7.64,4.7,4.83,15.97,13.25,20.41c0.2-1.63,0-3.37,0.7-4.59c0.57-1,2.68-1.13,3.25-2.14
                c0.68-1.21,0.46-2.93,0.62-4.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1036.12,1064.32c-3,4.16-5.97,8.34-9,12.49c-2.58,3.52-5.22,7-7.83,10.5c-0.56-0.25-1.12-0.5-1.67-0.75
                c0.51-2.08,0.9-4.2,1.55-6.24c0.7-2.18,0.58-3.75,0.63-7.5c0.02-1.51,1.84-3,2.84-4.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M882.26,1159.25c-6.49,3.83-12.99,7.66-19.48,11.49"/>
        <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M487.63,586.67c2.41-5.04,4.82-10.09,7.23-15.13c0.46-0.08,0.91-0.17,1.37-0.25c0.96,3.13,1.74,6.33,2.93,9.37
                c2.36,6.05,5.37,11.88,7.3,18.05c1.17,3.74,0.82,7.95,1.15,11.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1078.08,160.99c-3.49,3.35-7.96,5.75-8.63,11.47c-0.36,3.04-2.36,5.29-5.35,1.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1042.11,1069.32c-6.51,9.39-13.02,18.78-19.52,28.17c-0.66-0.29-1.31-0.57-1.97-0.86c0.84-4.27,1.67-8.55,2.51-12.82"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M642.99,401.81c0.5,6.33,1,12.66,1.5,18.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M275.83,1285.16c-2.83,6.5-5.55,13.04-8.55,19.46c-1.01,2.16-0.03,2.83,1.56,3.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M473.65,263.41c0.33,5.33,0.73,10.66,0.95,15.99c0.04,0.98-0.61,2-0.95,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M828.31,1128.77c-5,3.16-9.96,6.38-15.01,9.45c-1.28,0.78-2.84,1.08-4.27,1.6c-0.28-0.34-0.56-0.68-0.84-1.02
                c4.04-5.51,8.08-11.02,12.12-16.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M347.27,282.9c-2,6.33-4,12.66-5.99,18.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M130.47,598.67c2.83,1.5,6.4,2.39,8.28,4.67c1.81,2.19,2.47,5.8,2.59,8.82c0.34,8.15,0.3,16.32,0.02,24.47
                c-0.11,3.18,0.91,5.15,3.59,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M136.46,430.79c1.04-4.89,2.07-9.78,3.06-14.43c5,1.07,9.11,1.94,14.49,3.09c1.36,6.03,3.03,13.4,4.7,20.77
                c0.24,0.03,0.49,0.06,0.73,0.09c0-1.01,0-2.02,0-3.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M291.32,1054.83c3.5,2.66,6.96,5.37,10.5,7.98c4.13,3.05,6.9,6.95,7.98,12"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M216.89,275.4c3.91-0.44,2.76-2.14,1.06-3.98c-1.52,2.11-3.04,4.22-4.75,6.59c-3.19-2.88-5.64-5.42-9.51-1.32
                c-1.33,1.42-5.24,0.09-7.75,0.81c-2.97,0.85-5.89,2.26-8.45,3.99c-1.64,1.11-2.59,3.25-4.09,5.25c-2.3-1.83-3.89-3.09-5.48-4.36"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M737.4,588.67c-0.83,6.16-1.67,12.32-2.5,18.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M898.75,1159.75c-5.99,7.66-11.96,15.35-18.01,22.96c-0.94,1.18-2.3,2.02-3.46,3.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1008.15,1123.78c-8.28,1.72-16.57,3.44-24.85,5.16c-0.27-0.59-0.54-1.17-0.81-1.76c4.23-2.46,8.47-4.89,12.66-7.42
                c1.1-0.66,2.01-1.64,3.01-2.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M547.58,373.83c-0.17,5.16-0.24,10.33-0.61,15.48c-0.05,0.72-1.56,1.34-2.39,2.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M765.87,983.38c2.74,6.41,4.07,13.84,11.99,16.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M854.29,1338.62c1.33,2.13,2.66,2.14,3.97,0c12.52,7.15,22.79,16.53,29.5,29.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M545.58,605.66c-0.83,2.33-1.86,4.61-2.45,7.01c-0.85,3.45-1.38,6.98-2.05,10.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M182.42,653.63c5.86,2.52,5.69,2.43,2.63,8.56c-0.7,1.4,0.02,3.64,0.41,5.42c0.88,4.02,1.96,8,2.96,12"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M229.88,1466.52c5.86,0.43,11.96-1.35,17.48,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M245.86,1028.85c0.17,1.33,0.06,2.78,0.55,3.98c1.92,4.71,2.27,9.35-0.05,14.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M109.99,689.1c2.4,7.26,7.24,12.07,15.55,15.05c0.15-5.35,0.29-10.2,0.43-15.05"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M52.04,436.29c3.62-1.05,7.06-4.53,11.09-0.6c0.43,0.42,2.38-0.33,3.36-0.95c1.72-1.09,3.23-2.5,4.96-3.89
                c3.79,3.07,7.56,4.2,11.58,0.98c0.49-0.39,1.64-0.73,1.92-0.48c4.62,4.21,12.3,3.11,15.83,9.78c1.07,2.02,5.7,2.16,8.69,3.16"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M873.77,1152.26c-4.33,6.5-8.66,12.99-12.99,19.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M278.83,1088.3c-1.5,1.5-2.9,3.12-4.51,4.48c-7.79,6.54-15.97,12.66-23.31,19.66c-3.38,3.23-5.46,8.02-7.57,12.37
                c-3.39,6.95-9.19,11.44-15.06,15.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M149.45,434.79c1.47,4.5,2.86,8.94,7.45,11.57c2.1,1.2,3.88,3.1,5.5,4.96c2.03,2.34,3.09,4.96,0.04,7.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M252.36,1307.14c-3.01,10.9-7.95,20.44-18.51,25.94c-0.66,0.34-1.64,0.04-2.47,0.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M446.67,386.82c2.92,6.64,1.11,9.38-6.99,10.49c-4.99,0.69-10.02,1.17-14.96,2.12c-1.31,0.25-3.22,1.81-3.31,2.9
                c-0.13,1.6,0.73,3.69,1.87,4.9c2.75,2.9,5.78,5.59,8.98,7.98c1.23,0.92,3.32,0.59,4.91,1.13c4.69,1.58,9.39,3.15,13.97,5.02
                c2.46,1.01,4.69,2.61,7.02,3.94c-0.52,0.22-1.04,0.44-1.56,0.66c2.02,0.61,4.1,1.08,6.04,1.88c2.33,0.95,4.23,1.44,7.08,0.8
                c2.99-0.67,6.92,0.73,9.8,2.36c2.04,1.16,7.63,1,4.61,6.29c3.11,1.28,6.22,2.56,10,4.12c0.08,2.11-0.89,5.58,4.54,4.73
                c2.16-0.34,6.76,3.05,7.06,5.19c0.64,4.59,2.79,3.48,5.36,2.91c1.95,4.16,4.51,6.89,9.41,8.34c3.92,1.15,7.45,4.16,11.4,6.06
                c2.92,1.41,4.63,6.1,6.04,9.67c1.23,3.12-3.68,7.08-6.86,5.94c-3.39-1.21-6.18-4.48-10.6-2.23c-1.05,0.54-3.66-1.61-5.35-2.81
                c-2.46-1.75-4.73-2.57-8.05-2.27c-2.35,0.22-4.96-2.42-7.59-3.85c-1.99-0.24-4.43-0.54-6.87-0.84"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1148.51,329.86c2.52,3.68,3.39,8.87,9.48,9.24c0.94,0.06,1.68,3.42,2.51,5.25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1014.14,1048.33c-2.83,5.16-5.86,10.23-8.38,15.54c-0.87,1.83-0.44,4.28-0.61,6.44"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1019.14,1257.18c-7.33,7.66-14.65,15.32-21.98,22.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M85.01,674.11c-1.33-0.83-3.45-1.39-3.85-2.55c-1.32-3.84-2.51-3.82-5.22-1.03c-2.62,2.71-4.5,1.99-6.47-1.39
                c-0.69-1.18-2.52-3.06-2.86-2.87c-4.08,2.27-7.76-0.12-11.58-0.6c-0.45-0.06-0.99,0.61-1.49,0.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M223.38,1298.15c1,5.5,2.1,10.98,2.92,16.5c0.19,1.26-0.44,2.71-0.95,3.98c-2.34,5.89-2.17,12.72-7.39,17.58
                c-3.83,3.56-7.71,4.81-12.56,2.92"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M272.34,1259.68c2.16,8.21,2.01,16.37-0.5,24.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M300.31,1266.67c-4.26,6.56-6.86,13.5-5,21.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1040.12,1148.26c-7.43,1.49-12.53,6.29-16.98,11.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M887.76,1154.76c-5.99,7.33-11.99,14.66-17.98,21.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M823.82,365.84c5.16,3.33,10.31,6.68,15.51,9.95c0.28,0.18,0.97-0.29,1.47-0.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M520.6,282.9c-3.44-1.87-8.92-1.15-10.98,1.51c-1.39,1.79-2.67,3.66-4.01,5.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M672.46,1197.22c8.99,0,17.98,0,26.97,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1002.15,1045.84c-1,4-2.11,7.97-2.94,12c-0.33,1.6-0.05,3.32-0.05,4.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M422.2,255.92c1.23,2.31,2.46,4.63,3.98,7.5c3.06-2.01,5.31-3.49,7.58-4.98c5.75,2.24,11.21,5.55,17.92,5.13
                c1.6-0.1,3.32,1.84,4.99,2.83"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M267.34,1266.17c0.33,5.66,0.67,11.33,1,16.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M118.48,631.14c-0.17,2.33-1.4,5.79-0.32,6.81c5.51,5.21,3.73,14.26,10.31,18.68"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M272.34,1100.79c-1,2-1.96,4.02-3,5.99c-4.83,9.09,1.24,15.97,5.14,22.91c4.69,8.33,12.63,13.69,20.34,19.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M353.26,1099.8c5.66,2.66,11.32,5.33,16.98,7.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M948.7,1013.36c-6.42-2.86-12.97-0.89-19.48-0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M831.81,1321.63c7.5,5.23,15,10.46,22.35,15.59c-1.01,0.96-1.01,0.96-2.87-1.1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1014.14,1264.67c-1.33,3.16-2.76,6.29-3.97,9.5c-0.98,2.61-1.7,5.32-2.53,7.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M377.24,256.42c-1.92-2.86-3.63-5.88-8.01-5.1c-1.04,0.19-2.56-0.63-3.43-1.45c-2.35-2.2-4.02-2.12-6.11,0.5
                c-2.27,2.83-5.38,4.22-8.9,2.02c-2.73-1.71-4.5-1.23-4.45,2.03c0.13,8,0.57,15.99,0.97,23.98c0.04,0.85,0.63,1.67,0.96,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M739.4,963.4c1.41,1.95,2.82,3.89,4.48,6.19c-3.13,0.98-6.56,1.58-3.6,5.88c0.65,0.94,0.29,3.26-0.47,4.35
                c-2.8,4.03-1.47,6.06,2.84,7.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M238.87,1316.14c-2.29,7.69-5.65,14.79-11.49,20.48c-3.11,3.04-6.65,3.37-10.49,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M281.33,673.11c-0.67,0.67-1.3,1.87-2,1.91c-4.98,0.33-9.98,0.42-14.98,0.59"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M912.24,372.83c-2.16,2.33-4.26,4.73-6.51,6.98c-2.59,2.58-5.31,5.01-7.97,7.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M295.31,1195.72c1.98,5.32,4.61,10.52,3,16.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1148.51,583.68c4.33,5.33,8.66,10.66,12.99,15.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M871.27,1379.09c-3.66-5-7.33-9.99-10.99-14.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M682.95,501.74c2.35,7.94,0.36,15.7-1,23.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M839.3,1135.27c-3.66,5.33-7.33,10.66-10.99,15.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1071.09,1103.79c-0.83,0.83-2.32,1.62-2.39,2.51c-0.35,4.83-4.96,8.7-3.19,14.01c0.03,0.1-1.24,0.64-1.91,0.97c0-0.67,0-1.33,0-2
                "/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M186.92,241.93c-5.33-1.11-9.6-4.3-12.32-8.6c-2.76-4.38-4.17-3.12-6.8,1.23c-4.25-3.83-8.54-7.25-12.29-11.18
                c-3.74-3.93-8.32-3.81-13.05-3.97c-4-0.14-8-0.57-11.98-1.04c-0.41-0.05-0.68-1.25-1.01-1.92"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M147.95,639.14c0.83,4.83,2.02,9.63,2.4,14.5c0.42,5.3,0.13,10.65,0.06,15.98c-0.01,1.02-0.46,2.04-0.66,2.89
                c-1.72-1.04-3.26-1.96-4.8-2.89"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1052.6,1418.06c-2.41,5.37-4.83,10.73-7.58,16.85c-0.95-3.47-1.68-6.17-2.42-8.86"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M642.49,542.71c3,5.33,5.99,10.66,8.99,15.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M483.64,1049.83c5.33,1.67,10.66,3.33,15.98,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M980.67,961.4c1.33,0.17,3.14-0.16,3.92,0.58c4.39,4.23,9.85,5.57,15.56,6.41"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M774.86,1023.35c0.5,5.33,1,10.66,1.5,15.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M660.97,774.54c-3.12-1.03-6.28-4.39-9.41-0.44c-1.24,1.58-1.58,3.87-2.4,6.01c-0.88-0.31-2.02-0.71-3.52-1.24
                c-0.4,1.61-0.78,3.14-1.16,4.67"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M319.29,1234.2c-0.67,5.33-1.33,10.66-2,15.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M558.07,379.83c0.17,2.33-0.39,5.07,0.65,6.91c2.34,4.15,3.41,8.41,3.35,13.07"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M220.88,1463.53c-5.47-1.13-8.13,4.1-12.69,5.04c-0.15,0.55-0.3,1.1-0.46,1.66c2.72,1.43,5.43,2.86,8.15,4.29"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M616.01,599.67c0.33,3,0.62,6,1.01,8.99c1.14,8.66,2.32,17.32,3.55,26.48c1.58,0,3.26,0,4.93,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M254.85,239.93c0.83,5.16,1.67,10.33,2.5,15.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M251.36,280.4c-3.16-2.33-6.33-4.66-9.49-6.99c-5.4,0.89-10.76,3.6-16.12-0.84c-1.28-1.06-4.2-0.16-6.36-0.16"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M659.97,406.81c0.47,9.12,6.02,17.49,4,26.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M811.83,63.56c-0.08-3.85,3.41-5.53,7.48-3.47c2.39,1.21,4.54,3.23,7.05,3.79c1.96,0.44,4.29-0.81,6.45-1.31"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1033.62,1139.27c-6.24-1.68-10.49,2.82-15.49,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M267.34,1117.28c-0.67,5.16-1.33,10.33-2,15.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M703.93,456.77c-0.17,1.5,0.23,3.78-0.59,4.37c-5.92,4.28-4.34,9.98-3.41,15.61"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M124.48,1140.77c5.16,1.67,10.32,3.33,15.49,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M211.39,1362.6c1.17-0.33,2.33-0.67,3.5-1c0,1.17,0.37,2.5-0.06,3.47c-2.02,4.56-4.23,9.04-6.44,13.51
                c-2.79,5.66-7.63,8.74-13.48,10.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M263.84,1301.15c-1.83,3.83-3.79,7.61-5.46,11.5c-2.16,5.02-5.07,9.38-10.05,11.92c-1.13,0.57-2.63,0.39-3.97,0.56"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1101.06,1318.13c-1.67,5.16-3.33,10.33-5,15.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M179.42,1372.09c0.83,5.16,1.67,10.33,2.5,15.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M846.3,1357.61c-7.06,3.7-14.12,7.39-21.18,11.09c-0.3-0.35-0.6-0.7-0.9-1.05c3.53-4.51,7.06-9.02,10.59-13.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M275.33,266.91c-0.5,5-1,9.99-1.5,14.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M557.07,567.69c1.17,3.33,2.28,6.68,3.52,9.98c0.52,1.39,1.31,2.67,1.97,4.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M526.6,549.7c-0.5,5-1,9.99-1.5,14.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M822.32,508.73c-0.33,3.66-0.42,7.37-1.06,10.98c-0.77,4.33-0.58,7.76,3.98,10.15c1.8,0.94,2.74,3.52,4.07,5.35"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M812.33,1246.69c-5.33,0.83-10.66,1.67-15.98,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M213.39,1366.6c-0.95-0.59-1.9-1.18-2.68-1.66c-0.48,0.24-0.65,0.32-0.82,0.41c0.08,0.08,0.25,0.2,0.24,0.24
                c-2.57,6.33-5.15,12.66-7.73,18.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1092.07,1365.1c-7.66,8.49-15.32,16.99-22.98,25.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M541.58,399.81c0.33,5,0.67,9.99,1,14.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M732.9,608.66c0.83,5,1.67,9.99,2.5,14.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M199.9,1464.53c-2.83-0.5-5.65-1.27-8.5-1.41c-2.14-0.11-4.32,0.58-6.49,0.91"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M109.49,632.14c2.39,5.05,3.66,10.83,9.48,14.65c-0.2-2.49-0.34-4.32-0.49-6.15"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M720.92,523.72c-1,5-2,9.99-3,14.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M743.89,990.38c9.99,9.16,19.98,18.32,29.97,27.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M650.98,592.67c0.33,7.99,0.67,15.99,1,23.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M546.08,280.4c-0.5-5.32-2.22-6.29-6.48-3.47c-3.08,2.04-5.92,4.45-9.05,6.4c-1.23,0.77-3.4,1.5-4.37,0.94
                c-4.3-2.5-6.58,0.34-9.67,2.49c-2.87,1.99-6.56,2.81-9.89,4.14"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M834.81,560.69c2.16,4.66,4.33,9.33,6.49,13.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M160.94,769.04c-1.33-0.83-2.72-1.59-3.99-2.51c-3.14-2.29-6.38-4.2-10.5-3.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M611.02,806.01c-6.84-4.82-9.52-4.39-12.49,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M956.19,987.38c1.33,0,2.66,0,4.49,0c-0.36,1.1-0.68,2.05-0.99,3c1,0,2.02,0.13,2.99-0.02c2.72-0.42,5.54-2.9,8.34,1.15
                c-2.86,2.56-5.83,5.23-8.81,7.9c0.04,0.36,0.08,0.72,0.11,1.08c1.62-0.2,3.29-0.75,4.84-0.52c1.73,0.26,3.46,1.07,4.91,2.05
                c0.29,0.19-0.69,2.26-1.38,4.38c4.41,0.32,8.94,0.64,13.47,0.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M652.48,549.7c1.17,4.83,2.33,9.66,3.5,14.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M605.02,301.39c-3.16-0.83-6.34-1.63-9.49-2.51c-1.69-0.47-3.33-2.95-5,0.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M806.83,1120.28c-5.49,2.17-10.99,4.33-16.48,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M126.47,448.28c5.33,2.33,10.7,4.57,15.96,7.04c2.29,1.07,4.35,2.62,6.51,3.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M703.18,462.27c0.58,3,1.1,6.01,1.78,8.99c0.32,1.37,0.97,2.67,1.47,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M218.89,247.43c0.41,8.05,3.78,16.29-1.5,23.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1027.63,1130.27c-4.66,1.17-9.32,2.33-13.99,3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M165.44,990.88c-0.21-1.26-0.42-2.52-0.63-3.78c0.39-0.08,0.78-0.16,1.17-0.23c1.15,4.83,2.3,9.67,3.46,14.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M710.92,649.13c1,4.66,2,9.33,3,13.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M575.05,347.85c-2.27,1.07-4.54,2.15-5.97,2.82c-1.68-0.31-3.24-0.29-4.48-0.9c-1.33-0.66-2.44-1.85-3.52-2.94
                c-2.7-2.72-5.22-5.93-9.51-2.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M106.99,593.67c0.67,0.33,1.31,0.73,2,0.99c2.32,0.86,6.1,1.12,6.69,2.63c1.21,3.1,0.71,6.87,0.92,10.37
                c-0.95,0-1.33,0.11-1.61-0.02c-3.18-1.47-7.41-2.23-9.16-4.72c-1.38-1.97,0.68-6.11,0.53-9.25c-0.11-2.21-1.08-4.64-4.38-4.03
                c-0.97,0.18-1.99,0.03-2.99,0.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M804.84,1112.79c-6.65,3.97-13.53,7.26-21.48,7.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M105.49,637.14c2.66,4.66,5.33,9.33,7.99,13.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1065.09,947.91c-3.85-4.67-8.56-1.86-12.99-1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M168.93,428.29c0.33-2.83,0.45-5.71,1.08-8.47c0.37-1.61,1.58-3.02,2.41-4.52c1.17,1,3.1,1.84,3.34,3.03
                c0.51,2.56,0.46,5.34,0.09,7.95c-0.56,3.87-1.59,7.67-2.43,11.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M752.89,684.1c0.67,3.33,1.27,6.68,2.03,9.99c0.32,1.37,0.97,2.67,1.47,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M85.51,465.26c-2.66,1.67-5.18,3.69-8.05,4.85c-1.53,0.62-3.67-0.27-6.16-0.55c-1.03,0.53-2.95,1.52-5.18,2.67
                c1.17,2.94,1.59,6.53,3.6,8.29c2,1.76,5.61,1.68,8.62,2.44c-0.84,1.86-1.33,2.95-1.83,4.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M218.39,232.44c-3.54-4.19-5.81-1.55-8.43,1.77c1.61,0.83,2.98,1.53,5.09,2.62c-1.45,1.26-2.8,2.43-4.15,3.6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M488.63,283.9c-2.5,3.16-5.03,6.3-7.48,9.5c-1.46,1.91-2.77,3.93-4.72,6.71c2.06-0.1,3.38-0.16,4.7-0.23"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M108.99,655.62c-1.67,4.16-3.33,8.33-5,12.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M791.35,592.67c-0.17,4.5-0.33,8.99-0.5,13.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M619.01,535.21c4.5,1.83,8.99,3.66,13.49,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1048.61,984.88c4.5,3.5,8.99,6.99,13.49,10.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1018.64,1052.83c-4.99,5.91-7.43,13.76-13.99,18.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1039.12,940.91c-2.76,1.3-5.53,2.6-9.46,4.44c4.5,1.49,7.34,1.24,9.96-0.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M466.15,269.91c2.16,4.16,4.33,8.33,6.49,12.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M179.42,854.98c6.95-4.57,6.8-8.43-0.5-13.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M150.95,221.44c0,4.5,0,8.99,0,13.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1025.63,1051.83c-3.16,5.33-6.3,10.67-9.5,15.98c-2.21,3.68-4.5,7.3-6.76,10.95c-0.5-0.2-1-0.39-1.5-0.59
                c0.76-3.79,1.51-7.57,2.27-11.36"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1008.15,1112.29c-4.33-0.17-8.66-0.33-12.99-0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M496.63,1023.85c-5.66,2-11.32,4-16.98,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M752.89,349.35c4.33,0.83,8.66,1.67,12.99,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M91.01,678.11c3,1.83,6.13,3.49,8.92,5.59c0.96,0.72,1.07,2.57,1.57,3.9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M382.73,269.91c-3.81,3.39-7.61,6.77-11.49,10.22c0-3.57,0-6.4,0-9.23"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M953.7,1017.86c-3.16,1.5-6.28,3.1-9.52,4.43c-1,0.41-2.31,0.06-3.47,0.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M967.18,177.98c3.72,5.64,4.85,5.81,9.99,1.5c6.67,4.84,9.26,4.76,14.99-0.5c2.87-2.64,4.53-1.86,7.49,3.5
                c3.46,6.26,7.81,7.16,12.83,1.56c0.57,1.02,1.11,1.98,1.66,2.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M832.81,1124.78c-4,5.83-7.99,11.66-11.99,17.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M16.08,480.25c-1.92,3.24-1.98,3.62,0.52,4.43c2.54,0.82,5.25,1.12,7.53,1.57c0.74-3.24,1.34-5.87,1.94-8.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M834.81,1325.13c2.5,3,5.02,5.98,7.48,9c0.75,0.93,1.35,1.99,2.01,2.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1009.64,1048.83c-4.3,5.85-7.93,12-8.49,19.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M722.91,517.73c0,4.33,0,8.66,0,12.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M570.56,509.73c-2.63,3.99-2.72,8.46-2.5,12.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M496.63,271.41c-0.83,1.17-1.5,2.51-2.52,3.47c-6.85,6.39-4.92,13.94-2.97,21.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M839.8,1349.61c-5.99,3.66-11.99,7.33-17.98,10.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M566.06,575.68c0.17,6.16,0.31,12.32,0.52,18.49c0.03,1,0.29,2,0.5,2.99c0.04,0.19,0.31,0.47,0.48,0.47c0.17,0,0.33-0.3,0.5-0.46"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M254.85,1323.63c-4.16,3.33-8.33,6.66-12.49,9.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M742.4,340.36c5.99,1.33,11.99,2.66,17.98,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M986.67,1027.85c-6.42,5.73-8.03,13.69-9.99,21.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M958.19,1423.06c-9.99,0.67-19.98,1.33-29.97,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M840.8,1361.1c-3.06,3.82-6.12,7.64-9.17,11.47c0.35,0.45,0.7,0.9,1.05,1.35c3.04-1.61,6.06-3.25,9.13-4.8
                c0.93-0.47,1.99-0.69,2.99-1.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M249.36,1295.15c-1,4.16-2,8.33-3,12.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1027.13,1222.7c4.16,3,8.33,6,12.49,8.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M881.76,611.66c-1,5-1.98,10-3.01,14.99c-0.21,1.02-0.65,2-0.98,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M312.3,1278.16c-2.5,3.66-5,7.33-7.49,10.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M622.01,797.52c-7.33,2.08-10.63,5.72-9.99,10.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1004.15,1043.84c-2.16,2.5-4.35,4.98-6.49,7.5c-1.38,1.63-2.67,3.32-4,4.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M259.35,456.27c-3.59-4.21-7.78-5.19-13.03-3.09c-2.61,1.05-5.62,1.09-8.45,1.59"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M738.9,512.73c0.67,4,1.33,7.99,2,11.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M260.85,1112.29c0.5-1.33,0.97-2.68,1.53-3.98c0.06-0.15,0.62-0.08,1.45-0.16c-3.27,5.74-6.38,11.19-9.48,16.63"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M169.93,436.29c4.87,2.98,4.93,3,3.48,8.49c-0.89,3.36-1.98,6.67-2.98,10"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M74.52,1082.31c-3.83,0.17-7.66,0.33-11.49,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M274.33,1253.18c1,3.83,2,7.66,3,11.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M924.22,1003.87c3.66,1.67,7.33,3.33,10.99,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M814.83,391.82c0-1.68,0-3.36,0-5.05c4.36,3.26,3.98,7.71,4,12.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1033.12,918.93c3.08,2.44,8.46,2.71,7.58,8.17c1.6,1.29,7.1-3.03,4.15,3.44c-0.31,0.67,2.57,3.17,4.3,4.31
                c1.97,1.3,4.32,2.02,6.93,3.17c-1.71,3.05-3.35,5.97-4.98,8.89"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M752.39,679.61c-1.67,4.83-3.33,9.66-5,14.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M773.87,1111.29c-3.83,0-7.66,0-11.49,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M118.98,465.26c-5.42,0.25-11.08-1.05-15.98,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M317.29,1280.66c0.4-1.04,0.79-2.07,1.23-3.23c5.5,4.73-3.9,2.94-2.6,6.85c0.71,2.15-2.3,5.54-3.63,8.37"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M741.4,1098.8c5.24,2.33,11.4,2.78,15.49,7.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M277.83,244.43c0.17,2,0.52,4,0.44,5.99c-0.07,1.84-0.61,3.67-0.94,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M106.49,676.11c3.8,2.54,7.6,5.08,11.68,7.8c-2.43,1.08-4.81,2.14-7.19,3.19"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M252.6,1295.65c0.08,0.5,0.17,1,0.25,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M181.92,854.48c1.83,3.66,3.66,7.33,5.49,10.99c-0.42,0.39-0.83,0.77-1.25,1.16c-1.75-0.72-3.49-1.44-5.24-2.16"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M194.41,674.61c-1.33,3.83-2.66,7.66-4,11.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M97.5,468.26c1.51-0.29,3.01-0.57,5.02-0.95c1.92,3.97,3.95,8.2,5.99,12.43c-0.25,0.36-0.5,0.72-0.75,1.09
                c-3.58-2.69-7.17-5.38-10.75-8.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M813.33,593.67c-0.33,3.83-0.67,7.66-1,11.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M500.62,272.41c0.64,5.35,5.57,6.42,8.99,8.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M486.64,299.89c-2.02,1.84-4.12,3.57-0.52,6.03c2.48,1.7,4.72,3.73,7.07,5.61c0.33-0.28,0.65-0.57,0.98-0.85
                c-1.34-2.93-2.68-5.86-4.02-8.79"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M200.9,437.29c0.17-1,0.47-2,0.48-3c0.05-5,0.02-9.99,0.02-14.99c0-6.07-4.43-8.88-10.02-6.57c-1.28,0.53-2.96,0.08-5.21,0.08
                c-0.41,0.54-1.33,1.77-2.25,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M318.79,1083.81c1.01,0,2.01,0,3.02,0c0.09,0.32,0.19,0.65,0.28,0.97c-3.43,1.84-6.86,3.68-10.29,5.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M213.39,1346.11c-0.67,3.16-1.51,6.3-1.95,9.5c-0.41,2.96-0.38,5.99-0.55,8.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M223.88,1307.64c-1,2.66-2.11,5.29-2.96,8.01c-0.55,1.76-0.71,3.65-1.04,5.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M307.8,1130.27c4,4.66,7.96,9.35,12.01,13.97c1.39,1.59,2.98,3.01,4.48,4.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M324.79,1222.21c0.5,3.66,1,7.33,1.5,10.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M167.93,435.79c-0.35-2.45-0.71-4.9-1.06-7.35c4.86,2.69,3.97,5.05,1.25,8.5c-2,2.54-2.19,6.51-3.19,9.84"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M546.08,609.66c0.67,3.66,1.33,7.33,2,10.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M672.96,647.63c0.67-1.57,1.34-3.14,1.99-4.66c7.46,2.23,4.61,7.28,4,11.65"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M997.16,1040.84c-2.66,3.16-5.33,6.33-7.99,9.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M981.17,1033.84c-2.83,3-5.66,6-8.49,8.99c-0.31-0.06-0.61-0.11-0.92-0.17c0.47-2.61,0.94-5.22,1.42-7.83"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1061.1,174.48c0.67,0.17,1.33,0.33,2,0.5c-0.2-1.03-0.39-2.05-0.52-2.74c-2.24,2.16-4.05,4.83-6.57,6.04
                c-2.03,0.98-4.9,0.2-7.4,0.2c0,1.33-0.08,1.69,0.01,1.99c0.64,2.17,2.54,5.39,1.75,6.3c-2.18,2.53-4.54-0.44-6.74-1.34
                c-2.75-1.14-5.35-2.62-8.01-3.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M689.94,453.77c4.34-3.01,3.2,1.45,3.94,3.02c0.81,1.72,1.22,3.64,1.8,5.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M268.34,1309.14c-4.16,4.66-8.33,9.33-12.49,13.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M822.32,1347.11c-4.33,1.67-8.66,3.33-12.99,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M230.38,1295.15c-1,6.16-2,12.32-3,18.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M104.99,603.16c-1.17,0-2.88,0.5-3.41-0.08c-5.06-5.56-4.99-5.59-11.59-2.45c-0.88,0.42-1.98,0.37-2.98,0.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M124.97,623.15c-1.33,0.5-2.66,1-3.99,1.5c0.54,4.79-1.21,6.18-7.49,5.99c-4.46-0.14-5.14,0.49-7.61,4.93
                c-0.53,0.95-2.53,1.12-3.88,1.55c-1.31,0.41-2.66,0.68-3.99,1.02c0.67,1.17,1.33,2.33,2,3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1101.56,1173.24c0.67,1.83,1.43,3.64,1.97,5.5c0.47,1.63,0.69,3.32,1.03,4.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M261.35,278.9c-3.28,1.06-7.67-0.54-9.49,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M140.46,421.3c-2-1-4.19-1.74-5.95-3.06c-2.57-1.93-2.43-2.11-5.04,0.56"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M439.68,474.26c-0.83,1.63-5.31,0.06-3.5,4c-1.17,0.5-2.33,1-3.5,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M827.32,1343.12c-4.5,5.16-8.99,10.33-13.49,15.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1106.55,1022.35c-1.33,3.33-2.66,6.66-4,9.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M994.16,1028.35c-3.02,4.02-6.04,8.04-9.15,12.18c-0.11-0.21-0.72-0.95-0.73-1.69c-0.01-0.32,1.22-0.66,1.89-1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M995.66,1034.84c-4.16,5.5-8.33,10.99-12.49,16.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M109.49,447.28c-0.17,0.83-0.54,2.38-0.47,2.4c4.1,1.2,2.03,3.83,1.47,6.1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M125.47,441.28c6.01,3.95,11.76,8.47,19.36,8.77c0.53-0.57,1.05-1.14,1.58-1.71c-2.82-2.19-5.64-4.37-8.45-6.56"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M812.33,534.21c4.34-1.55,5.72-0.74,6.01,3.5c0.09,1.34,0.32,2.67,0.49,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M581.05,569.19c0.89,1.56,1.78,3.13,2.66,4.69c0.44-0.06,0.89-0.12,1.33-0.19c0-1.34,0-2.67,0-4.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M108.49,428.79c2.52,3.15,5.04,6.3,7.57,9.47c-4.93,3,1.95,5.25,0.83,7.02c1.98,0,4.47-0.56,6.54,0.14
                c2.73,0.93,2.19,3.46,1.63,5.88c-0.32,1.39,0.25,2.98,0.41,4.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M833.31,1345.11c-3.98,6.75-11.51,8.53-17.48,12.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1117.04,941.91c0,3.16,0,6.33,0,9.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M103.49,673.61c2.64,0.87,1.91,2.92,2.16,4.98c0.32,2.58,1.83,5.02,2.83,7.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M897.75,592.67c0.33,3.16,0.67,6.33,1,9.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M813.33,1116.78c-6.6,2.49-9.75,8.55-13.99,13.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M327.28,1229.2c0.68-2.37,1.37-4.75,2.05-7.12c0.55-0.01,1.1-0.02,1.65-0.03c0.43,4.38,0.86,8.77,1.3,13.15"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M141.46,220.45c1.27,4.35,2.54,8.7,3.81,13.05c0.43-0.22,0.87-0.43,1.3-0.65c-0.54-0.97-1.07-1.94-1.61-2.91"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M255.35,1275.17c-0.33,4.83,1.22,8.88,5,11.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M320.29,1260.18c-0.46,6.16,1.98,12.32,0,18.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M794.85,412.8c-0.67,3-1.33,6-2,8.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M951.2,1011.86c-4.33,3.66-8.66,7.33-12.99,10.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M522.6,553.7c0,3,0,6,0,8.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M163.44,419.3c1.33,3,2.66,6,4,8.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M107.49,426.29c0.8,0.6,1.6,1.2,2.39,1.8c-2.71-5.02-4.72-5.07-13.38,0.45"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M961.19,1019.36c-1.67,0.83-3.33,1.66-5,2.5c-1.4,0.7-2.79,1.4-4.19,2.11c-0.32-0.38-0.63-0.76-0.95-1.14
                c2.38-2.65,4.76-5.31,7.13-7.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M851.29,1363.1c-4.33,4.66-8.66,9.33-12.99,13.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M292.82,1205.22c-1.43-1.56-2.86-3.12-4.14-4.52c-1.33,2.94-2.59,5.73-3.85,8.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1158.5,241.93c0.67,3,1.33,6,2,8.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M135.46,432.29c-4.45,4.04-7.28-0.74-9.26-2.73c-2.9-2.9,0.43-6.05,3.23-9.42c-4.25-0.33-7.6-0.69-10.96-0.76
                c-0.82-0.02-1.91,0.74-2.45,1.46c-1.51,2.03,0.39,13.29,2.46,14.93c1.59,1.26,3.32,2.35,4.98,3.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M854.29,1376.09c-3.09,1.5-6.18,2.99-10.53,5.11c1.45-2.91,2.24-4.51,3.04-6.11"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M280.33,1194.23c-0.16-0.66-0.32-1.31-0.48-1.97c-0.18,0.16-0.37,0.32-0.55,0.48c0.18,2.83,0.36,5.65,0.54,8.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M906.74,488.75c-1.79,4.45,1.49,5.68,4.51,6.96c1.25,0.53,2.65,0.7,3.98,1.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M638.49,543.71c1.67,3,3.33,6,5,8.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M565.56,508.73c-0.5,3-1,6-1.5,8.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1090.07,1478.02c-3,0-5.99,0-8.99,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M145.46,672.61c1,3,2,6,3,8.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M524.6,284.4c1.17,3,2.33,6,3.5,8.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M520.6,285.4c1.73,5.91,8.96,8.33,9.49,14.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M339.77,1109.29c2.33,2.33,4.66,4.66,6.99,6.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M363.25,1.61c-2.83,0-5.66,0-8.49,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1091.57,1.61c-2.78-1.41-3.93,0.14-5.06,2.47c-1.04,2.14-2.6,4.03-3.93,6.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M246.36,1116.78c-6.49,6.16-12.99,12.32-19.48,18.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M886.76,593.67c-0.67,2.83-1.33,5.66-2,8.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M855.29,1341.12c-2.83,0.33-5.66,0.67-8.49,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M890.26,551.7c-2.91,1.19-6.28,1.89-6.49,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M732.4,599.67c0,4.01,0,4.01,3.5,10.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M758.88,990.38c-2.16,2.17-4.33,4.33-6.49,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M903.24,393.32c2.33,2.5,4.78,4.9,6.94,7.54c0.85,1.04,1.06,2.62,1.56,3.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M810.33,526.72c0.5,4.33,1,8.66,1.5,12.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M962.69,1017.36c-1.67,3.5-3.33,6.99-5,10.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M870.77,1335.12c0.98,6.76,3.4,12.51,10.49,14.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M217.89,1347.11c-1.33,4.5-2.66,8.99-4,13.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M106.24,422.3c0.08,4.33,0.17,8.66,0.25,12.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M966.18,1021.85c-3.83,3-7.66,6-11.49,8.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M98,585.68c0.08,4.16,0.17,8.33,0.25,12.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M244.36,446.28c-2.5,2.33-5,4.66-7.49,6.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M134.97,452.27c-0.17,2.66-0.33,5.33-0.5,7.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M164.94,455.77c2,3.5,4,6.99,5.99,10.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M104.99,458.77c2.24-2.77,4.58-4.06,8.05-1.59c1.64,1.17,3.93,1.42,5.93,2.08"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M708.43,466.76c-0.5,4.33-1,8.66-1.5,12.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M195.91,668.61c-2.5,4.33-5,8.66-7.49,12.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M797.84,1117.28c-1.83,2.66-3.66,5.33-5.49,7.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M97,676.11c1.51,0.99,3.02,1.97,4.52,2.95c3.34-6.39,0.13-9.32-5.03-11.92c-0.73-0.37-1.65-0.36-2.48-0.53
                c0,1-0.19,2.04,0.03,2.99c0.74,3.19,1.63,6.34,2.46,9.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M754.38,1107.29c5,0,9.99,0,14.99,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M590.04,571.69c-0.5,1.5-1.03,2.99-1.49,4.5c-0.24,0.81-0.35,1.66-0.51,2.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M715.92,540.21c0,2.5,0,5,0,7.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M854.29,1370.6c-5.49,2.5-10.99,5-16.48,7.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M787.35,1100.8c-2.5,1-5,2-7.49,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M291.32,1199.72c1.33,4.5,2.66,8.99,4,13.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M558.57,510.73c0.5,2.5,1,5,1.5,7.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M994.66,961.4c1.83,2.33,3.66,4.66,5.49,6.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M141.96,607.66c1.33-0.32,2.67-0.64,4-0.97c-0.51,0.5-1.03,1.01-1.54,1.51c2.51,1.65,5.02,3.3,7.53,4.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M985.67,1041.34c-0.5,2.5-1,5-1.5,7.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M983.67,1040.34c-1.83,2.17-3.66,4.33-5.49,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M721.91,509.73c0.5,2.5,1,5,1.52,7.49c0.01,0.03,0.35,0.06,0.47-0.01c0.2-0.12,0.34-0.32,0.51-0.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M125.97,463.27c3.83,0.87,7.66,2.59,11.49,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M262.85,1295.15c0.23-3.35-4.41-6.46,0.16-10.74c1.72,3.29,3.28,6.26,4.83,9.24"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M137.46,461.27c-2.83-0.33-5.66-0.82-8.49-0.91c-0.98-0.03-2.64,0.7-2.88,1.45c-1.08,3.27-3.51,3.16-6.11,2.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M86.01,431.29c0.66-1.67,1.32-3.34,1.96-4.98c6.43,0.94,11.31,4.29,15.01,9.48c1.54,2.16,3.16-0.34,4.47,0.1
                c2.45,0.81,4.7,2.23,7.03,3.4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1032.12,916.43c1.17,3.5,2.33,6.99,3.5,10.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M859.29,1348.11c-3.5-0.17-6.99-0.33-10.49-0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M276.33,248.42c-0.17,2.5-1.43,5.86-0.28,7.33c2.87,3.68-0.13,6.51-0.38,9.65c-0.05,0.59-2.17,1.01-3.34,1.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M312.8,1268.17c-1.5,2-3,4-4.5,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M238.37,1123.28c-0.17,1.83-0.14,3.71-0.56,5.48c-0.41,1.73-1.28,3.34-1.94,5.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M170.18,417.3c-5.08,1.17-10.16,2.33-15.24,3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M235.37,1297.15c-2.55,4.51-1.17,9.29-1,13.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M265.84,1307.14c-2.33,2.17-4.66,4.33-6.99,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M600.03,810.51c-1.5-1.17-2.86-3.04-4.52-3.36c-4.85-0.94-4.18,2.34-3.47,5.36"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M825.82,1353.11c-2,2.83-4,5.66-5.99,8.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1019.14,1073.32c-1.8,2.34-3.59,4.67-5.99,7.8c0-2.97,0-4.63,0-6.3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M151.95,437.78c2.66,2.33,5.33,4.66,7.99,6.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M936.71,1033.34c1.72,2.25,3.44,4.5,5.62,7.36c0.99-2.23,1.68-3.8,2.38-5.36"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M689.44,505.24c-0.83,0.17-1.9,0.07-2.44,0.56c-0.87,0.77-1.39,1.94-2.05,2.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M120.48,613.65c2.66,4,5.33,7.99,7.99,11.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M186.42,662.12c4.85,2.51,8.72,7.26,14.99,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M277.33,1252.68c0.67,2.33,1.33,4.66,2,6.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M821.32,1339.62c-5,2.14-8.06,6.05-9.99,10.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M652.48,582.68c-0.5,2.33-1,4.66-1.5,6.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M266.34,1279.16c-0.5,2.33-1,4.66-1.5,6.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M498.62,293.39c-3.66,2-7.33,4-10.99,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M85.51,468.26c2.33,0.5,4.66,1,6.99,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M725.91,330.36c3.5,2.66,6.99,5.33,10.49,7.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M146.45,640.14c-1,3.33-2,6.66-3,9.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M862.28,1377.09c-2,1.5-4,3-5.99,4.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M706.93,641.63c0.67,3.33,1.33,6.66,2,9.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M117.48,599.17c4.14-0.42,8.27-0.84,12.93-1.31c-0.79,3.17-1.36,5.49-1.94,7.8"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M815.33,1343.12c-3.5-0.33-6.99-0.67-10.49-1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M811.33,1342.62c-2,1.33-4,2.66-5.99,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M665.47,469.76c1.5,1.33,2.93,2.76,4.55,3.93c0.35,0.26,1.36-0.39,2.28-0.69c-4.33-3.07-8.33-5.9-12.32-8.73"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M120.98,615.65c-1.03-0.16-2.05-0.32-3.79-0.59c1.99-1.36,3.25-2.23,4.99-3.42c-2.04-1.37-3.62-2.42-5.2-3.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M245.86,1466.52c0.5,0.5,1.03,0.97,1.49,1.5c1.85,2.15,3.67,4.32,5.5,6.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M990.66,1109.29c-2.34-0.27-4.68-0.54-7.94-0.91c2.21-1.86,3.82-3.22,5.44-4.59"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1062.59,1121.28c-1.33,2-2.66,4-4,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M109.99,530.22c1.39,1.89,2.38,4.63,5.59,2.12c0.3-0.23,1.83,1.44,2.92,1.82c1.24,0.43,2.64,0.39,3.98,0.56"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1139.52,286.9c1.17,3,2.33,6,3.5,8.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M96.5,457.77c0.56,4.09,2.95,5.73,7,5.56c1.32-0.06,2.66,0.61,3.99,0.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M125.97,460.77c-2-0.33-4-0.67-5.99-1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1050.61,1073.81c-2.16,2.66-4.39,5.29-6.41,8.05c-0.23,0.31,0.64,1.42,1.41,3c0.26-2.04,0.38-3.05,0.51-4.05"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M281.33,1265.17c0.44-1.96,0.87-3.93,1.51-6.78c-4.01,3.13-4.26,4.66-2.5,10.28"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M820.82,527.22c-0.67,2-1.33,4-2,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M814.83,526.72c0.17,2,0.33,4,0.5,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M933.72,1024.35c0,2.5-0.12,5.01,0.09,7.49c0.05,0.55,1.24,1.01,1.91,1.51c0-0.67,0-1.33,0-2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M73.02,486.75c3.11,0.88,8.36-0.37,5.99,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M119.48,633.14c0.33,2,0.67,4,1,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M873.77,630.64c-0.67,2-1.33,4-2,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M154.95,458.77c1.17,2,2.33,4,3.5,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M193.41,667.12c-0.33,2-0.67,4-1,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M134.47,438.78c-0.83,2-1.67,4-2.5,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M278.33,1195.23c-0.33,2-0.67,4-1,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M184.42,655.12c0.33,2,0.67,4,1,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M96,461.27c-1.83,1.17-3.66,2.33-5.49,3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M2.09,476.26c1.33-0.51,2.66-1.01,4.41-1.68c1.22,2.32,2.14,5.94,6.14,3.27c0.15-0.1,1.27,1.23,1.93,1.9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M607.52,1223.7c-0.53,4.13-4.47,3.9-6.99,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M794.35,1330.63c2.66,1.83,5.33,3.66,7.99,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M149.95,1033.84c-1.83,1.67-3.66,3.33-5.49,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M92.01,428.29c0.33,2,0.67,4,1,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M848.3,1338.12c-1.46-0.34-2.92-0.67-5.25-1.21c1.2,5.68,5.39,7.32,8.75,9.7"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M261.35,1279.16c0.17,2,0.33,4,0.5,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M125.97,430.29c-0.18,3.51-0.35,7.02-0.53,10.54c-0.32-0.02-0.65-0.03-0.97-0.04c0-1.17,0-2.33,0-3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M283.33,1201.72c-1,2-2,4-3,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M318.29,641.13c1.67,1.83,3.33,3.66,5,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M192.41,1378.59c2.42,1.66,0.73,2.7-0.46,4.03c-0.88,0.98-1.37,2.29-2.04,3.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M325.79,1206.22c0.83,1.83,1.67,3.66,2.5,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M543.08,384.82c0.5,3.33,1,6.66,1.5,9.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M858.79,1372.59c-2.66,3.33-5.33,6.66-7.99,9.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M831.31,511.23c-2.83,0-5.66,0-8.49,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M900.25,601.66c-0.5,2.33-1,4.66-1.5,6.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M274.83,1098.3c-1.67,0.5-3.72,0.55-4.92,1.59c-2.75,2.38-5.07,5.24-7.57,7.9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M986.17,1099.8c-1.4,0.23-2.81,0.47-4.21,0.7c0.05,0.65,0.11,1.31,0.16,1.96c3.35,0.28,6.7,0.56,10.05,0.84"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M275.33,256.42c0.17,1.83,0.33,3.66,0.5,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M86.01,463.77c3.5,0.83,6.99,1.67,10.49,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1041.11,966.39c1.98-0.16,3.96-0.33,7.51-0.63c-2.63,2.21-4.07,3.41-5.65,4.73c2.24,1.15,4.43,2.27,6.62,3.39
                c-1.17,1-2.33,2-3.5,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M822.82,1383.59c-1.5,1.33-3,2.66-4.5,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M329.78,1201.72c-2.24,1.48-1.81,2.79,0.25,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M969.18,962.4c2.83,2.17,5.8,4.18,8.38,6.61c0.58,0.54-0.22,2.55-0.39,3.88"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M231.37,1026.35c1.17,2.66,2.33,5.33,3.5,7.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M335.28,1091.3c-1.12-1.88,0.54-5.87-4.56-4.83c-1.56,0.32-3.93-3.33-5.93-5.16"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1090.07,1449.04c-1.83,3.66-3.66,7.33-5.49,10.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M989.16,1044.84c-0.5,3.16-1,6.33-1.5,9.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M120.98,420.3c1.17,1.67,2.33,3.33,3.5,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M790.35,1373.09c4.49,0.71,6.18,4.78,8.99,7.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M729.41,333.36c0-1.5,0-2.99,0-4.49c-0.59-0.21-1.18-0.42-1.78-0.63c-1.91,2.37-3.81,4.75-5.72,7.12"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M437.18,268.91c-0.21,2.4-0.66,1.96-2.35,3.65c-1.25,1.24-1.15,3.85-1.65,5.85"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M787.35,1115.28c-1.83,2.33-3.66,4.66-5.49,6.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M696.94,458.77c-0.79,3.28-2.04,6.61,1,9.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M142.46,630.14c4.99,0.87,2.06,6.41,5,8.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M220.39,586.18c-1.67,1.67-3.33,3.33-5,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M253.85,1465.03c-1.83,0.5-3.66,1-5.49,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M129.47,422.3c1.67,2.66,3.33,5.33,5,7.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M92.51,668.61c-2,1-4,2-5.99,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M804.34,506.73c0,1.67,0,3.33,0,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M110.99,427.79c1.33,1.33,2.66,2.66,4,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M312.8,1086.31c-3.16,1.17-6.32,2.35-9.49,3.49c-1.49,0.54-2.99,1.01-4.49,1.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M895.75,554.2c-1.67,1-3.33,2-5,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M145.46,897.45c2.52,2.13-0.53,3.05-0.8,4.53c-0.23,1.22-2.02,4.7,2.33,4.18c0.3-0.04,0.97,3.11,1.47,4.77"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M973.18,973.89c3.66,0.17,7.33,0.33,10.99,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M119.48,447.78c1.45,2.03,2.9,4.07,3.63,5.09c2.45,0.41,3.9,0.66,5.36,0.9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M438.18,265.91c-0.67,0.67-1.51,1.23-1.96,2.02c-0.8,1.42-1.37,2.98-2.04,4.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M842.8,569.69c-0.67,3-1.33,6-2,8.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M335.78,250.92c3.82-1.77,7.05,0.58,10.49,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1020.13,1137.77c-2.52,0.95-5.04,1.9-7.61,2.87c0.44,0.8,0.8,1.45,1.39,2.51c0.64-1.07,1.19-1.98,1.73-2.89"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M131.97,431.79c-1.65,5.18,3.7,6.31,5.61,9.23c-0.95,2.09-1.78,3.92-2.62,5.76"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M770.87,1017.36c2.16,1.2,5.24,1.82,3.48,5.49c-0.03,0.06-0.35,0.07-0.47-0.01c-0.2-0.12-0.34-0.32-0.51-0.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M628,328.87c0.21,2.42,0.29,2.28,2.48,3.53c2.64,1.52,1.98,3.3-0.38,5.3c-0.41-1.42-0.75-2.63-1.1-3.84"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M627,324.87c0.14,1.53-0.76,4.09,2.5,2.5c0.5,1.67,1,3.33,1.5,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M106.99,422.3c1.33-0.17,2.68-0.53,3.99-0.45c1.35,0.09,2.67,0.61,4,0.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1022.13,1056.33c-0.67,1.33-1.33,2.66-2,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1031.62,1057.33c-1.67,2.66-3.33,5.33-5,7.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M331.28,1232.2c-2.16,0.42-4.33,0.83-6.49,1.25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M626,319.87c0.22,2.49,0.4,2.23,2.42,3.61c0.96,0.66,1.08,2.55,1.58,3.88"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M241.87,1121.28c-0.43,4.26-3.64,4.96-6.99,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1025.13,1064.32c-0.09,1.67-4.72,2.89-0.5,5c-1,1.5-2,3-3,4.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M143.46,426.79c-0.58-1.31-1.16-2.63-1.75-3.94c0.15,0,0.3,0,0.45,0c2.26,3.48,4.53,6.95,6.77,10.45
                c0.15,0.24,0.16,0.79-0.01,0.96c-0.42,0.42-0.97,0.69-1.47,1.03c-0.17-1-0.33-2-0.5-3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M812.83,387.82c-1,2.66-2,5.33-3,7.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M806.33,1338.12c-1.08-0.73-2.16-1.45-3.24-2.18c-0.24,0.75-0.48,1.51-0.71,2.26c2.32-0.19,4.63-0.39,6.95-0.58"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M296.81,1089.3c-1,1.33-2,2.66-3,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M88.01,676.61c2.96,1.96,5.64-0.76,8.49-0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M279.83,1204.22c-0.18,1.38-4.26,3.24,0.08,3.62c-1.56,2.35-3.07,4.61-4.58,6.87"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M765.37,1107.79c-2,1.33-4,2.66-5.99,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M251.36,1344.12c-1.17,0.67-2.33,1.33-3.5,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M806.83,506.23c-0.17,2.5-0.33,5-0.5,7.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M884.26,1350.61c-2.33,0.5-4.66,1-6.99,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M270.34,1281.16c0.33,2.66,0.67,5.33,1,7.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M105.99,462.77c-1.03-4.81-1.39-4.97-5.49-2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M193.91,1376.09c-1.27,2.11-6.01,2.35-3.5,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M726.91,511.73c-0.67,1.33-1.33,2.66-2,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M439.18,261.91c-0.15,1.85-0.17,3.78-3.02,3.11c-0.13-0.03-0.64,1.55-0.97,2.39"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M306.3,1272.17c0.33,2,0.67,4,1,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M313.3,1262.18c0.5,3.16,1,6.33,1.52,9.49c0,0.03,0.35,0.06,0.47-0.01c0.2-0.12,0.34-0.32,0.51-0.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M113.99,448.28c1.17,0.67,2.33,1.33,3.89,2.23c0.22-1.69,0.41-3.21,0.6-4.72"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M736.4,338.86c0.83,0.83,1.67,1.67,2.5,2.5c0.33-0.5,0.67-1,1-1.5c-1-0.5-2-1-3-1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M732.9,506.73c-0.62,2.13-1.24,4.27-2.08,7.15c-1.63-1.76-2.27-2.46-2.91-3.15"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M630,337.86c0.5,0.83,0.81,2.11,1.53,2.41c2.08,0.88,2.15,0.72,2.46,3.08"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M631,340.86c0.14,1.53-0.77,4.08,2.55,2.63c0.05-0.02,0.94,1.87,1.44,2.87"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M232.37,1352.11c-1.17,0.33-2.33,0.67-3.5,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M958.69,981.38c-0.76,3.07-1.86,5.86-5.49,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M703.43,1195.72c-1.24-0.16-2.48-0.31-3.72-0.47c-0.06,0.68-0.13,1.36-0.19,2.04c1.3-0.19,2.61-0.38,3.91-0.57"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M307.3,1086.81c-0.67,0.67-1.33,1.33-2,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M805.84,1339.12c-1.5,1.67-3,3.33-4.5,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M660.97,598.67c-0.76,1.19-1.52,2.38-2.75,4.31c-0.48-1.65-0.86-2.98-1.24-4.31"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M876.27,1340.12c-2.66-0.17-5.33-0.33-7.99-0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M77.52,684.1c2.5-4.27,5-0.66,7.49,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M229.88,1473.02c-1,0.83-2,1.67-3,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M976.18,1010.86c1.86,0.58,3.71,1.16,7.38,2.31c-1.96,1.1-2.92,1.65-3.88,2.19"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M186.42,1466.03c1,0.17,2,0.33,3,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M87.01,671.11c-0.71-0.9-1.42-1.8-2.14-2.7c-0.54,0.49-1.07,0.97-1.61,1.46c0.92,0.74,1.83,1.49,2.75,2.23"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M591.04,299.39c0.5,1,1.06,1.98,1.42,3.02c0.05,0.15-0.92,0.64-1.42,0.97c-0.33-1.33-0.67-2.66-1-4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M99,465.26c-2.82,0.81-1.82,6.6-6.65,5.11c0.72,0.96,1.44,1.92,2.16,2.88"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M244.36,1310.14c0.55-0.94,1.11-1.88,1.66-2.81c-0.54-0.25-1.07-0.5-1.61-0.76c-0.35,2.19-0.7,4.38-1.05,6.57"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1045.61,981.88c2.16,5.31,4.33-0.97,6.49,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M486.64,300.89c4.69,0.72,10.66-2.85,12.57-6.96c1.25-2.7,3.41-4.71,6.92-3.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M292.82,1089.3c-0.83,0.83-1.67,1.67-2.5,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M270.34,1302.65c-0.67,1.67-1.33,3.33-2,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M330.78,1089.3c-1.59-3.74-6.04-3.09-8.49-5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M801.34,508.73c0,1.67,0,3.33,0,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M47.05,312.38c-0.59-0.89-1.18-1.77-1.92-2.89c-1.01,1.13-1.79,2.01-2.57,2.89"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M852.29,1349.11c1.5,1.33,3,2.66,4.5,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M694.94,184.97c1.54,1.02,3.07,2.05,4.09,2.72c0.31,1.64,0.61,3.21,0.91,4.77"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M220.39,1353.61c-1.5,2.83-3,5.66-4.5,8.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M301.81,1089.3c0.5-0.5,1-1,1.5-1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M811.83,71.06c0.67,0.67,1.37,1.3,1.99,2.01c0.71,0.8,1.34,1.66,2.01,2.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M810.83,68.06c0.67,0.67,1.37,1.3,1.99,2.01c0.71,0.8,1.34,1.66,2.01,2.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M479.64,296.89c1.5,1.58,3,3.16,4.5,4.75"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M809.83,65.06c0.67,0.67,1.37,1.3,1.99,2.01c0.71,0.8,1.34,1.66,2.01,2.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M808.83,62.56c0.5,0.67,0.93,1.41,1.52,1.98c0.76,0.74,1.65,1.35,2.48,2.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M692.44,177.98c0.17,0.83,0.33,1.67,0.5,2.5c0.67-0.33,1.33-0.67,2-1c-0.67-0.67-1.33-1.33-2-2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1001.65,1061.82c-1.17,1.17-2.33,2.33-3.5,3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M614.02,322.37c0.17,0.67,0.33,1.33,0.5,2c-1-0.33-2-0.67-3-1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M143.46,422.3c-0.67-0.5-1.33-1-2-1.5c0,0.83,0,1.67,0,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M696.44,181.97c-0.17,0.67-0.33,1.33-0.5,2c-0.67-0.33-1.33-0.67-2-1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M692.94,180.97c0.67,0.33,1.33,0.67,2,1c0.17-0.67,0.33-1.33,0.5-2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M484.64,476.26c-0.33,1-0.67,2-1,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M323.29,1086.81c-0.5,0-1,0-1.5,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M740.4,339.36c0.17,0.83,0.36,1.66,0.47,2.5c0.02,0.14-0.31,0.33-0.47,0.5c-0.33-0.33-0.67-0.67-1-1"/>
        
            </g>
            </svg>
        </div>
        )
    }