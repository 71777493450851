import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {Form, Button, Alert} from 'react-bootstrap'
import swal from 'sweetalert';
import { API_URL } from '../GlobalConfig';

function Auth() {

    const [login, setLogin] = useState(true);
    const [signupForm, setSignupForm] = useState({name: '', email: '', password: '', password_confirmation: ''})
    const [signinForm, setSigninForm] = useState({email: '', password: ''})
    const [signupError, setSignupError] = useState([])
    const [signinError, setSigninError] = useState([])
    const [signinFlag, setSigninFlag] = useState(false)
    const [loading, setLoading] = useState(false)

    // console.log("Error sign up", signupError)
    // console.log("Error sign in", signinError)

    const dispatch = useDispatch();
    let history = useHistory();


    const handleSignupChange = (e) => {
        setSignupForm(() => ({ ...signupForm, [e.target.name]: e.target.value }));
    }

    const handleLoginChange = (e) => {
        setSigninForm(() => ({ ...signinForm, [e.target.name]: e.target.value }));
    }

    const handleUser = () => {
        const token = localStorage.getItem("token")
        // console.log("user token", token)
        fetch(`${API_URL}/user`, {
            method: 'GET',
            headers: {'Authorization': `Bearer ${token}`}
        })
        .then(r => r.json())
        .then(data => {
            // console.log("user data",data)
            localStorage.setItem("user",JSON.stringify(data))
        })
    }

    const handleRegisterSubmit = (e) => {
        setLoading(true)
        e.preventDefault();
        const config = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
            // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            // 'Origin': 'X-Requested-With', 
        },
            body: JSON.stringify(signupForm)
        };
        fetch(`${API_URL}/register`, config)
        .then(r => r.json())
        .then(token => {
            if(token.errors) {
                setLoading(false)
                // console.log("token error",token.errors)
                setSignupError(token.errors)
            }else {
                // console.log("sign up token",token) 
                dispatch({
                    type: 'SET_TOKEN',
                    payload: token
                })
                handleUser()
                setLoading(false)
                swal("Register Successful!", "Welcome to Digital Color book", "success");
                history.push("/gallery")
            }
        })
    }

    const handleSigninSubmit = (e) => {
        setLoading(true)
        e.preventDefault();
        const config = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                    // "Access-Control-Allow-Origin": "*",
                    // "Content-Type": "Accept",
                    "Origin": "X-Requested-With" },
            body: JSON.stringify(signinForm)
        };
        fetch(`${API_URL}/login`, config)
        .then(r => r.json())
        .then(token => {
            if(token.message) {
                setLoading(false)
                // console.log("signin error",token.message)
                setSigninError(token.message)
                setSigninFlag(true)
            }else if(token.errors){
                setLoading(false)
                setSigninError(token.errors)
                setSigninFlag(true)
            }else {
                // console.log("login token",token)
                dispatch({
                    type: 'SET_TOKEN',
                    payload: token
                })
                handleUser()
                setLoading(false)
                setLoading(false)
                swal("Login Successful!", "Welcome to Digital Color book", "success");
                history.push("/gallery")
            }
        })
    }

    const changeFormBottom = () => {
        return login ? (
            <Button className = 'btn btn-info' onClick = {() => setLogin(false)}>New here? Register</Button>
        ) : <Button className = 'btn btn-info' onClick = {() => setLogin(true)}>Already have an account? Login</Button>
    }

    return (
        <div>
            {login ? 
            <Form className = "container mt-5" onSubmit = {handleSigninSubmit}>
                <div className = 'col-md-6 offset-md-3'>
                    <h2 style = {{color: 'black'}}>Login</h2>

                    {signinFlag === true ?  <Alert variant = 'danger'>{signinError}</Alert> : null}
                
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control name = 'email' required type="email" placeholder="Enter email" value = {signinForm.email} onChange = {handleLoginChange}/>
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control name = 'password' required type="password" placeholder="Password" value = {signinForm.password} onChange = {handleLoginChange}/>
                    </Form.Group>
                    <div className = 'text-center'>
                        {loading === true ? (
                        <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button>
                        ) : <Button variant="primary" type="submit">Login</Button> }
                        {loading === false ? <div className = 'mt-2'>{changeFormBottom()}</div> : null}
                    </div>
                </div>
            </Form> : 
            <Form className = "container mt-5" onSubmit = {handleRegisterSubmit}>
                <div className = 'col-md-6 offset-md-3'>
                    <h2 style = {{color: 'black'}}>Register</h2>
                    
                    {signupError.map((error) => (
                        <Alert variant = 'danger'>{error}</Alert>
                    ))}
                    <Form.Group controlId="formBasicUser">
                        <Form.Label>Username</Form.Label>
                        <Form.Control required name = 'name' type="text" placeholder="Enter Username" value = {signupForm.name} onChange = {handleSignupChange}/>
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control required name = 'email' type="email" placeholder="Enter email" value = {signupForm.email} onChange = {handleSignupChange}/>
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control required name = 'password' type="password" placeholder="Password" value = {signupForm.password} onChange = {handleSignupChange}/>
                    </Form.Group>

                    <Form.Group controlId="formBasicRePassword">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control required name = 'password_confirmation' type="password" placeholder="Confirm Password" value = {signupForm.password_confirmation} onChange = {handleSignupChange}/>
                    </Form.Group>
                    <div className = 'text-center'>
                    {loading === true ? (
                        <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button>
                        ) : <Button variant="primary" type="submit">Register</Button> }
                    {loading === false ? <div className = 'mt-2'>{changeFormBottom()}</div> : null}
                    </div>
                </div>
            </Form> }
        </div>
    )
}

export default Auth
