import { combineReducers } from 'redux'
import authReudcer from './authReducer'
import imageReducer from './imageReducer'

const state = {
    auth : authReudcer,
    image : imageReducer
}

export default combineReducers(state);
//  export default rootReducer;
