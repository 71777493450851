import React from 'react';
import './GrandTeton.css';

export default function GrandTeton(props) {

    return (
        <div className={`grand-teton-container ${props.size}`}>
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id}>
            <g className="grand-teton-image">
        
            <path onClick={() => props.onFill(0)} fill={props.fillColors[0]}  d="M1164,1388c0,37.667,0,75.333,0,113c-46.667,0-93.333,0-140,0c-2.057-1.034-4.255-0.47-6.374-0.47
                c-336.307-0.032-672.612-0.03-1008.919-0.037c-2.152,0-4.315,0.143-7.207-0.26c0-91.083,0-182.122,0-273.266
                c10.654-1.689,20.029-0.801,27.097,8.021c1.239,1.547,2.678,3.088,4.74,3.515c6.242,1.29,9.619,5.658,11.799,11.12
                c2.207,5.53,6.2,8.777,11.71,10.689c5.114,1.775,9.854,0.302,12.866-4.327c4.071-6.258,9.911-11.124,13.669-17.696
                c1.842-3.223,5.279-3.621,8.72-1.905c0.744,0.371,1.625,0.581,2.223,1.121c7.445,6.729,17.48,5.514,26.169,8.418
                c4.461,1.492,8.447-2.183,12.628-3.568c5.88-1.95,10.613-5.86,15.792-9.062c4.798-2.968,11.004-3.925,16.868-3.767
                c4.853,0.132,8.444-0.748,11.544-5.095c3.337-4.68,7.625-8.733,13.893-9.851c2.967-0.529,5.562-2.336,7.21-4.983
                c2.073-3.33,4.868-3.543,8.232-2.507c3.051,0.939,5.851,2.397,8.62,3.912c9.248,5.057,14.252,4.65,22.073-1.918
                c0.509-0.429,0.938-1.115,1.558-1.218c9.215-1.526,17.984-6.316,27.697-4.065c2.202,0.511,4.357,0.835,6.534,0.627
                c8.246-0.79,16.423-1.005,24.746-0.294c7.102,0.606,13.521-1.589,16.666-9.387c2.02-5.008,3.537-5.253,10.452-3.529
                c7.942,1.98,15.218,0.662,19.881-6.245c2.768-4.102,6.194-6.796,10.15-9.213c2.74-1.673,5.454-2.579,8.96-1.693
                c4.303,1.088,8.613,0.458,11.787-3.415c0.937-1.143,2.006-2.42,3.434-2.843c4.645-1.377,7.454-4.466,9.65-8.679
                c1.655-3.175,4.157-3.271,6.979-0.725c1.865,1.685,3.726,3.359,5.938,4.526c4.895,2.583,10.418,0.726,12.984-4.287
                c0.837-1.634,1.647-3.342,1.985-5.116c1.35-7.087,6.064-11.096,12.208-13.954c1.097-0.511,2.126-1.095,3.293-0.535
                c5.397,2.59,10.134,0.101,14.594-2.304c5.604-3.022,11.05-4.255,17.262-2.005c4.14,1.5,8.117,0.233,10.73-3.29
                c3.833-5.172,10.413-4.807,15.193-8.062c1.563-1.064,3.221,0.608,3.314,2.562c0.207,4.318,0.432,8.652-0.153,12.967
                c-0.755,5.565-1.413,11.128-1.759,16.749c-0.456,7.397,3.255,12.237,8.762,16.265c1.077,0.787,2.153,1.569,3.364,2.143
                c1.364,0.645,2.835,1.331,3.736,2.458c5.066,6.334,10.921,11.271,19.315,12.536c1.449,0.219,1.846,2.248,2.477,3.59
                c3.598,7.643,9.838,12.299,17.53,15.07c2.134,0.769,2.724,2.188,3.373,3.881c1.385,3.615,2.776,7.199,5.451,10.148
                c2.361,2.605,4.581,5.144,8.629,4.949c1.867-0.09,3.239,1.51,4.764,2.519c8.883,5.874,9.854,4.131,12.414-4.323
                c1.981-6.54,4.729-11.918,9.668-16.376c5.272-4.759,8.885-10.545,11.258-17.255c1.613-4.562,5.147-7.851,8.875-10.772
                c3.741-2.933,8.081-4.879,12.378-6.857c1.589-0.731,3.438-1.351,4.534,0.347c2.327,3.601,5.293,1.873,7.978,1.103
                c1.615-0.464,3.177-1.612,4.631-0.279c3.325,3.047,5.317,0.673,7.502-1.519c3.947-3.961,4.293-3.815,6.881,1.017
                c2.237,4.179,4.986,7.93,10.17,8.806c1.551,0.262,2.604,1.44,3.809,2.312c5.154,3.724,10.081,7.377,17.009,7.959
                c3.211,0.271,6.668,2.886,9.471,5.491c4.077,3.79,7.84,8.211,13.94,8.935c1.053,0.125,1.651,1.036,2.201,1.896
                c0.725,1.134,1.595,2.307,2.7,2.915c10.677,5.881,10.596,17.337,14.317,26.896c2.163,5.556,1.296,10.846,0.016,16.105
                c-1.87,7.682,0.454,15.247-0.063,22.854c-0.226,3.309,0.156,6.608-0.395,9.946c-1.055,6.386-4.841,11.102-9.691,14.558
                c-9.546,6.803-15.581,16.123-20.872,26.191c-1.464,2.786-3.389,5.323-4.236,8.427c-1.114,4.078,0.275,6.355,4.319,5.773
                c3.565-0.514,3.87,0.887,3.93,3.727c0.099,4.726,1.65,6.235,6.297,6.276c10.362,0.092,19.854-3.093,28.836-8.021
                c1.666-0.915,2.682-2.456,3.544-4.096c2.985-5.675,8.315-6.604,14.017-7.774c7.03-1.443,14.565-0.654,21.11-4.1
                c8.061-4.244,16.771-5.976,25.471-8.242c6.259-1.63,12.716-3.6,18.743-6.396c10.175-4.722,19.807-10.652,30.774-13.626
                c4.199-1.139,8.631-0.044,12.729-1.617c5.675-2.18,11.072-2.034,16.794,0.474c3.252,1.426,7.463,1.208,10.754-0.31
                c3.983-1.837,8.018-2.343,12.186-2.297c1.397,0.016,2.702-0.171,3.681-0.82c7.603-5.049,16.801-6.789,24.36-11.94
                c7.442-5.072,15.207-6.196,23.295-1.265c2.698,1.646,5.748,2.612,8.611,3.935c5.516,2.546,10.82,2.798,16.143-0.409
                c1.278-0.771,2.676-1.267,4.229-1.393c8.749-0.704,16.16-5.618,24.435-7.855c3.086-0.835,4.791-3.683,6.262-5.005
                c-1.443,1.434-3.352,3.947-6.399,5.098c-1.192,0.45-2.347,0.49-3.239-0.454c-0.985-1.043-0.514-2.164,0.208-3.164
                c5.348-7.413,5.987-15.954,5.903-24.66c-0.153-15.911,1.305-31.68,4.034-47.346c0.473-2.713,0.236-5.491,0.411-8.237
                c0.429-6.734,3.777-12.046,7.123-17.599c5.959-9.891,8.469-20.905,9.508-32.367c1.176-12.973,3.286-25.463,10.174-37.238
                c10.243-17.514,15.495-37.13,19.983-56.85c0.492-2.162,0.5-4.509,2.494-5.883c1.719,0.033,2.149,1.143,2.337,2.307
                c3.119,19.42,9.069,38.038,16.659,56.091c4.103,9.759,2.865,18.503-2.771,26.888c-2.81,4.18-4.343,8.789-6.015,13.419
                c-0.896,2.484-0.86,4.647,0.111,7.177c1.301,3.386,1.594,6.932-2.55,8.92c13.438,2.404,18.8-6.585,21.489-18.629
                c3.246-14.541-1.294-28.438-6.968-41.535c-6.878-15.875-13.437-31.785-15.467-49.037c-0.719-6.107-1.807-12.402-0.547-18.699
                c0.533-2.668-0.114-5.405-0.429-8.127c-0.596-5.155-0.392-9.71,5.479-12.762c4.31-2.24,8.132-5.435,12.782-7.362
                c5.918-2.455,9.296-7.852,13.001-12.763c4.368-5.789,7.379-5.774,11.617,0.012c4.274,5.834,8.273,6.619,13.298,1.159
                c4.236-4.603,7.735-4.169,12.517-1.484c5.912,3.319,6.66,2.817,12.14-1.829c3.467-2.938,7.687-4.157,12.15-4.097
                c1.008,2.38,0.594,4.402,0.594,6.367c0.025,65.828-0.092,131.656,0.167,197.483c0.032,8.045-2.226,9.852-10.27,13.52
                c-1.913,0.872-4.062,1.253-6.154,1.579c-3.904,0.608-7.176,2.114-9.963,5.102c-2.81,3.012-6.598,4.401-10.778,4.376
                c-2.058-0.013-4.193-0.003-5.404,1.89c-3.371,5.271-8.75,4.929-13.426,3.854c-5.952-1.366-10.777-0.438-15.296,3.361
                c-1.181,0.992-2.698,1.273-3.843,1.765c-0.004,0.001,0.348-0.021,0.611-0.241c6.956-5.821,14.975-6.156,23.391-4.626
                c3.281,0.597,5.85-0.72,7.793-3.329c1.533-2.059,3.552-2.755,6.087-2.697c4.549,0.105,8.355-1.738,11.568-4.91
                c1.061-1.047,1.958-2.377,3.417-2.869c7.072-2.38,14.896-2.66,20.548-8.123c2.382,2.116,1.53,4.258,1.532,6.12
                c0.05,60.162,0.035,120.323,0.067,180.485C1163.527,1384.438,1163.013,1386.298,1164,1388z"/>
            <path fill-rule="evenodd" d="M1164,1388c-0.375-2.005-1.076-4.01-1.078-6.016c-0.058-59.496-0.047-118.992-0.047-178.488
                c0-1.95,0-3.901,0-4.989c-6.24,2.013-12.243,3.946-18.245,5.885c-0.474,0.153-1.019,0.236-1.395,0.527
                c-6.035,4.674-12.05,9.373-20.339,8.877c-5.57,8.062-13.266,5.248-20.764,4.332c-2.03-0.248-4.362,1.047-6.401,1.974
                c-5.359,2.436-10.634,5.058-15.941,7.605c-0.229-0.413-0.46-0.825-0.688-1.238c0.802-0.582,1.538-1.309,2.421-1.715
                c1.809-0.833,3.924-1.17,5.513-2.282c7.96-5.573,16.414-8.122,26.127-5.501c1.311,0.354,3.767-0.351,4.439-1.382
                c2.608-3.999,6.297-4.797,10.647-5.273c2.642-0.29,5.758-1.475,7.534-3.334c3.473-3.633,7.553-5.25,12.245-6.075
                c1.961-0.345,3.92-0.82,5.806-1.456c6.729-2.267,9.041-5.554,9.041-12.617c0-65.329,0-130.658,0-195.987c0-1.966,0-3.932,0-7.642
                c-5.203,3.038-9.584,5.172-13.462,7.996c-3.108,2.264-6.011,2.62-9.276,1.086c-2.697-1.268-5.296-2.745-7.492-3.896
                c-3.742,2.474-6.89,5.299-10.576,6.811c-5.183,2.125-7.894,0.679-10.619-4.184c-2.853-5.091-5.5-5.236-8.677-0.484
                c-2.11,3.157-3.693,6.948-6.487,9.294c-3.169,2.661-7.552,3.817-11.178,6.01c-4.194,2.536-8.186,5.423-12.143,8.324
                c-0.608,0.446-0.713,1.769-0.799,2.709c-2.396,26.102,1.513,51.101,13.038,74.789c5.804,11.931,9.761,24.429,10.494,37.755
                c0.633,11.49-1.692,22.144-11.705,29.161c-2.72,1.907-7.012,1.56-10.575,2.282c-1.141,0.231-2.336,0.415-3.368,0.908
                c-1.806,0.862-3.506,1.947-5.545,2.503c1.906-4.954,9.357-5.934,8.103-13.067c-0.835,1.39-1.52,2.527-2.203,3.665
                c-0.379-0.073-0.757-0.147-1.136-0.221c0.353-2.619,0.228-5.423,1.153-7.821c2.143-5.556,4.144-11.343,7.376-16.264
                c6.64-10.106,7.466-19.784,2.433-31.055c-7.675-17.186-12.44-35.406-15.879-54.786c-1.314,5.693-2.3,10.717-3.642,15.644
                c-5.082,18.665-11.505,36.74-20.951,53.799c-3.345,6.04-4.955,13.583-5.423,20.569c-1.262,18.852-6.516,36.244-16.061,52.455
                c-0.721,1.225-1.183,2.741-1.301,4.161c-1.7,20.383-3.28,40.775-4.979,61.158c-0.704,8.45-0.966,17.081-8.284,24.765
                c8.37-2.208,11.771-10.933,20.483-11.062c-0.783,1.107-1.085,1.908-1.662,2.292c-7.275,4.835-14.352,10.047-21.988,14.229
                c-4.223,2.312-9.389,2.931-14.157,4.203c-2.223,0.594-4.839,0.377-6.713,1.481c-5.976,3.522-11.823,2.692-17.733,0.265
                c-3.692-1.516-7.382-3.094-10.894-4.978c-5.36-2.877-10.405-2.366-15.705,0.257c-6.209,3.074-12.676,5.625-18.911,8.647
                c-2.051,0.994-3.553,3.563-5.613,4.019c-10.013,2.21-19.986,7.029-30.225,4.949c-6.556-1.332-12.399-0.95-18.83-0.42
                c-14.63,1.208-26.828,7.88-39.411,14.369c-6.673,3.441-14.213,5.418-21.557,7.286c-8.428,2.144-16.699,4.102-24.712,7.978
                c-6.011,2.907-13.448,2.957-20.289,4.028c-6.012,0.941-11.47,2.144-14.946,8.184c-1.519,2.638-4.738,4.913-7.684,5.986
                c-6.686,2.437-13.584,4.55-20.572,5.835c-9.678,1.779-12.348-0.689-12.801-9.473c-3.119-0.478-6.086-0.933-9.052-1.387
                c0.619-2.965,0.651-6.222,1.957-8.844c6.229-12.502,12.313-25.15,24.559-33.299c7.306-4.86,9.991-12.053,9.821-20.658
                c-0.224-11.264-1.538-22.475,1.171-33.797c0.96-4.011-1.771-8.959-3.013-13.425c-0.801-2.877-2.224-5.593-2.893-8.49
                c-0.898-3.894-2.915-6.517-6.073-9.122c-9.148-7.548-19.552-13.264-28.899-20.495c-1.337-1.034-3.872-0.371-5.527-1.17
                c-4.326-2.086-8.415-4.662-12.716-6.809c-2.07-1.034-4.439-1.453-6.606-2.316c-1.16-0.462-2.516-1.032-3.204-1.976
                c-2.627-3.602-5.019-7.375-7.715-11.409c-3.966,3.397-7.559,8.653-14.931,8.237c0.959-1.604,1.646-2.754,2.68-4.482
                c-4.934,0.751-9.93,4.295-14.622-1.335c-8.588,4.722-18.825,8.46-22.241,20.561c-1.112,3.938-3.909,7.956-7.036,10.668
                c-9.469,8.214-13.827,18.302-13.044,31.169c-1.802-0.44-3.264-0.598-4.56-1.147c-6.052-2.574-12.215-4.956-18.037-7.985
                c-5.77-3.001-8.485-8.649-10.936-14.425c-0.644-1.518-2.233-2.851-3.703-3.763c-6.904-4.285-13.972-8.238-17.74-16.097
                c-0.846-1.764-3.171-2.869-4.93-4.117c-0.627-0.445-1.682-0.247-2.39-0.626c-10.913-5.82-19.449-14.628-28.241-23.03
                c-1.606-1.534-1.964-4.97-1.924-7.514c0.09-5.813,0.825-11.615,1.218-17.427c0.357-5.299,0.607-10.604,0.955-16.78
                c-6.421,4.069-13.851,4.15-18.146,10.693c-2.149,3.272-6.265,3.526-10.007,1.798c-5.074-2.344-9.797-1.971-14.754,0.779
                c-5.34,2.963-10.84,5.927-17.473,3.355c-1.948-0.756-10.576,5.716-11.398,8.018c-1.062,2.975-1.814,6.086-3.128,8.941
                c-4.199,9.125-12.339,10.78-19.805,4.182c-5.315-4.697-5.121-4.562-9.195,1.244c-1.512,2.154-4.148,3.533-6.315,5.209
                c-1.811,1.399-3.905,2.5-5.484,4.114c-3.369,3.447-7.311,4.39-11.877,3.514c-5.398-1.035-14.124,3.024-16.977,7.893
                c-4.57,7.802-12.738,11.338-21.579,9.362c-1.783-0.398-3.621-1.146-5.36-0.977c-1.499,0.146-3.808,1.051-4.191,2.161
                c-3.467,10.041-11.458,11.623-20.474,11.396c-12.292-0.31-24.597-1.237-36.865-0.877c-6.36,0.188-12.66,2.562-18.974,4.007
                c-0.595,0.137-1.157,0.629-1.627,1.07c-7.483,7.033-14.342,7.9-23.312,2.935c-1.891-1.047-3.768-2.131-5.714-3.067
                c-4.252-2.046-8.079-2.585-11.282,2.149c-1.216,1.797-3.515,3.678-5.54,3.964c-7.197,1.018-11.469,5.789-15.371,11.027
                c-2.431,3.264-5.085,4.292-9.245,4.191c-8.688-0.209-16.603,1.932-24.043,7.434c-5.958,4.405-13.596,6.539-20.503,9.649
                c-0.427,0.192-1.095,0.32-1.457,0.123c-8.06-4.404-18.051-1.677-25.914-7.804c-7.635-5.948-9.373-4.832-14.837,3.357
                c-3.659,5.483-7.803,10.724-12.289,15.554c-5.429,5.843-16.396,3.826-22.291-3.888c-1.895-2.48-2.75-5.786-4.75-8.146
                c-2.23-2.632-5.129-4.742-7.917-6.841c-1.947-1.466-4.697-2.097-6.218-3.854c-5.307-6.129-11.17-10.354-19.852-8.664
                c-1.702,0.331-3.557-0.128-5.537-0.233c0,90.651,0,181.058,0,272.048c2.733,0,5.185,0,7.636,0
                c335.644,0,671.288-0.002,1006.932,0.046c2.339,0.001,4.677,0.704,7.016,1.079c-340.967,0-681.933,0-1022.95,0
                c0-499.917,0-999.835,0-1499.876c387.496,0,775.042,0,1162.95,0C1164,463.333,1164,925.667,1164,1388z"/>
            <path fill-rule="evenodd" d="M1105.149,1191.896c-3.089,1.706-6.13,3.51-9.284,5.087c-2.343,1.171-4.812,2.104-7.261,3.05
                c-9.479,3.658-19.035,7.13-28.43,10.994c-3.356,1.38-6.508,3.443-9.443,5.614c-3.53,2.611-6.73,5.669-10.736,7.651
                c8.039-5.034,11.464-16.819,23.233-16.541c0.161,0.004,0.333-0.049,0.484-0.113c8.738-3.724,17.452-7.504,26.219-11.158
                c4.793-1.997,9.674-3.782,14.515-5.662C1104.681,1191.178,1104.915,1191.537,1105.149,1191.896z"/>
            <path fill-rule="evenodd" d="M843.897,1375.686c-4.202,5.658-8.404,11.316-12.606,16.975
                C832.513,1384.809,837.26,1379.552,843.897,1375.686z"/>
            <path fill-rule="evenodd" d="M818.964,1370.857c2.952-3.405,5.903-6.811,8.855-10.215c0.432,0.362,0.862,0.725,1.294,1.087
                c-2.938,3.417-5.878,6.834-8.816,10.251C819.853,1371.605,819.408,1371.231,818.964,1370.857z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(1)} fill={props.fillColors[1]}  d="M1021.933,700.078c-1.876,5.089-3.751,10.179-5.627,15.268
                c-0.367-0.555-0.734-1.108-1.103-1.663c-2.41,0.188-4.818,0.479-7.232,0.531c-2.106,0.045-4.659,0.546-6.249-0.418
                c-5.986-3.63-10.581-0.731-14.743,2.881c-5.399,4.688-12.232,5.081-18.499,7.21c-1.29,0.438-2.998,1.041-3.537,2.076
                c-3.78,7.257-9.437,9.842-17.444,8.14c-2.564-0.545-5.37,0.145-8.06,0.062c-5.557-0.17-11.165-1.056-16.642-0.517
                c-3.319,0.326-7.064,2.097-9.506,4.397c-5.447,5.135-10.932,6.455-17.604,2.893c-1.017-0.543-2.725-0.769-3.677-0.284
                c-4.096,2.086-7.986,4.572-12.047,6.734c-1.397,0.743-3.059,1.024-4.626,1.409c-1.752,0.429-3.917,0.222-5.227,1.198
                c-2.971,2.214-5.771,2.571-9.186,1.275c-1.204-0.457-3.052-0.14-4.259,0.489c-6.389,3.33-12.545,4.575-18.866-0.297
                c-2.263-1.743-9.929,0.492-12.285,3.71c-1.884,2.573-3.403,3.304-6.021,1.255c-4.804-3.761-8.146,0.572-11.477,2.657
                c-2.871,1.798-3.984,1.06-6.663-0.559c-4.144-2.502-9.3-3.353-14.062-4.783c-0.909-0.273-2.138,0.347-2.96-0.021
                c-7.146-3.203-13.182-1.994-16.757,3.451c-4.313-0.984-7.5-1.654-10.654-2.454c-1.593-0.404-3.114-1.082-4.69-1.563
                c-2.438-0.744-5.004-1.173-7.32-2.184c-6.106-2.666-12.147-5.147-18.773-1.696c-7.091-5.779-13.554-4.064-20.493,0.833
                c-4.018,2.835-9.685,3.41-13.515,6.402c-1.996,1.559-1.662,6.146-2.293,9.381c-0.216,1.11-0.167,2.271-0.239,3.41
                c-1.067-0.597-2.262-1.043-3.179-1.818c-2.12-1.795-4.168-3.687-6.118-5.664c-0.863-0.876-1.761-1.969-2.055-3.114
                c-1.223-4.751-4.422-6.264-8.957-6.068c-3.144,0.135-6.312,0.283-9.44,0.041c-2.804-0.218-4.543-1.448-6.365-4.308
                c-2.276-3.573-6.908-5.636-10.49-8.395c-1.691-1.303-3.889-2.384-4.851-4.122c-3.543-6.401-7.51-7.444-13.23-2.916
                c-1.952,1.544-3.555,4.034-5.732,4.673c-3.5,1.026-7.155,0.591-9.922-2.707c-2.721-3.241-5.74-2.983-9.258-0.678
                c-2.688,1.763-6.116,2.356-8.958,3.933c-2.977,1.65-5.572,3.978-8.487,5.759c-1.232,0.754-2.855,0.869-4.302,1.274
                c-0.275-1.483-1.129-3.147-0.73-4.42c2.163-6.901,2.252-6.765-5.044-7.969c-1.112-0.184-1.872-1.987-2.984-2.774
                c-2.978-2.105-6.038-4.096-9.104-6.072c-1.778-1.146-4.225-1.753-5.35-3.33c-5.694-7.979-9.526-8.866-17.229-2.937
                c-3.142,2.419-8.241,2.104-9.746,6.937c-0.068,0.22-2.56,0.103-3.59-0.462c-7.641-4.187-7.614-4.234-12.466,1.514
                c-3.333-0.621-6.246-1.164-9.16-1.707c-0.649-0.121-1.747-0.059-1.883-0.391c-3.24-7.904-12.25-4.312-17.26-8.677
                c-0.653-0.569-1.911-0.825-2.802-0.683c-7.285,1.167-14.665,2.033-21.765,3.932c-2.848,0.761-5.064,3.877-7.873,6.17
                c-2.294-3.259-5.149-6.781-7.4-10.655c-2.447-4.209-5.676-6.12-10.568-6.028c-5.657,0.107-11.324-0.321-16.987-0.501
                c-0.658-0.021-1.501-0.195-1.953,0.129c-3.678,2.633-8.323,4.689-10.644,8.246c-2.802,4.294-4.868,8.362-10.574,8.967
                c-1.147,0.122-2.174,1.38-2.119,1.343c-2.913-0.523-4.964-0.471-6.576-1.286c-2.085-1.055-3.732-2.938-5.729-4.215
                c-2.041-1.306-4.175-2.601-6.447-3.377c-3.368-1.15-7.285-1.172-10.247-2.904c-3.286-1.922-5.848-2.171-9.632-1.485
                c-5.871,1.063-12.072,0.153-18.09,0.627c-3.078,0.242-6.063,1.672-10.335,2.933c-4.558-1.603-8.218-5.963-10.564-11.828
                c-0.659-1.646-2.442-3.834-3.907-3.997c-6.716-0.747-13.607-1.71-20.224-0.892c-4.027,0.498-7.528,4.41-11.53,6.196
                c-3.367,1.504-7.08,2.345-10.723,3.038c-0.86,0.164-2.253-1.275-3.068-2.255c-7.437-8.927-14.8-17.915-22.461-27.221
                c5.936,0.601,11.137,1.126,17.721,1.792c-1.771-3.531-2.701-5.383-3.753-7.479c3.453,0.284,6.623,0.547,10.931,0.902
                c-6.624-5.528-12.3-10.266-17.976-15.003c0.292-0.412,0.585-0.824,0.877-1.236c4.045,0.994,8.09,1.988,12.135,2.983
                c0.308-0.518,0.616-1.035,0.923-1.553c-9.828-7.409-19.307-15.103-23.798-27.7c2.241,0.276,4.044,0.499,6.446,0.796
                c-0.407-1.599-0.696-2.729-0.979-3.84c3.478,0.8,7.016,1.613,10.554,2.427c0.217-0.415,0.434-0.83,0.651-1.246
                c-1.971-2.509-3.941-5.018-6.236-7.939c1.287-0.527,2.334-0.956,3.711-1.52c-0.846-1.14-1.505-2.029-2.565-3.457
                c0.611-0.396,1.361-0.763,1.969-1.293c47.32-41.291,94.799-82.383,144.187-121.229c11.759-9.25,22.329-19.998,33.689-29.775
                c4.812-4.142,10.248-7.556,15.401-11.3c0.394,0.408,0.788,0.817,1.183,1.226c-4.76,9.078-9.52,18.156-14.279,27.234
                c0.453,0.297,0.905,0.593,1.357,0.889c39.845-39.271,77.302-80.748,113.511-123.379c-0.304-0.426-0.608-0.851-0.912-1.275
                c-3.793,2.796-7.606,5.565-11.373,8.397c-3.965,2.98-7.885,6.021-12.231,8.75c12.533-14.86,20.927-31.977,28.706-49.931
                c-1.604-0.379-2.681-0.633-3.968-0.936c0.314-0.773,0.55-1.552,0.928-2.252c8.134-15.075,16.289-30.139,24.425-45.213
                c1.58-2.927,3.1-5.888,4.646-8.833c3.799-7.239,5.846-14.486,3.393-22.923c-1.526-5.25-1.071-11.075-1.63-18.227
                c7.299,4.982,13.767,8.229,17.322,15.101c2.829,5.468,6.31,10.642,8.68,16.291c2.412,5.748,3.811,11.921,5.672,17.902
                c1.181,3.794,1.961,7.795,3.718,11.305c1.909,3.813,1.667,6.825-1.043,9.948c-9.159,10.554-18.179,21.231-27.49,31.648
                c-1.539,1.721-4.291,2.35-6.466,3.513c-5.601,2.994-11.192,6.004-16.787,9.008c0.111,0.704,0.224,1.407,0.336,2.111
                c4.202,1.707,8.28,3.925,12.64,4.982c4.646,1.126,4.886,3.461,3.648,7.22c-3.534,10.74-10.149,19.34-18.204,27.044
                c-4.583,4.383-9.438,8.008-15.549,10.249c-6.627,2.432-11.758,7.229-14.002,14.089c-2.234,6.826-6.21,12.25-10.768,17.677
                c-12.839,15.285-24.915,31.214-37.851,46.414c-8.841,10.389-18.632,19.968-27.964,29.94c-1.61,1.721-3.051,3.602-4.752,5.625
                c1.219,0.407,1.628,0.675,1.769,0.572c21.138-15.368,42.433-30.541,59.544-50.662c5.128-6.031,10.711-10.729,19.64-11.188
                c6.506-0.335,11.887-5.13,15.626-10.695c3.4-5.062,7.8-7.474,13.612-9.712c13.486-5.192,26.283-12.277,34.744-24.817
                c3.628-5.377,8.015-9.432,13.921-12.389c7.436-3.723,13.11-9.563,16.299-17.532c2.246-5.616,4.707-11.146,7.072-16.714
                c-0.472-0.226-0.943-0.451-1.415-0.676c-1.071,2.193-2.082,4.419-3.226,6.573c-3.478,6.548-6.57,13.362-10.696,19.476
                c-2.11,3.128-5.804,5.639-9.319,7.268c-6.194,2.87-10.281,7.492-14.22,12.822c-9.19,12.438-21.995,19.798-36.366,24.956
                c-3.517,1.262-7.228,3.631-9.439,6.556c-5.807,7.677-12.446,12.765-22.481,14.009c-4.24,0.526-8.538,4.177-11.919,7.354
                c-9.946,9.351-19.176,19.47-29.225,28.702c-6.034,5.543-13.074,9.992-20.194,14.325c0.197-0.389,0.322-0.843,0.603-1.157
                c14.988-16.747,30.169-33.326,44.912-50.286c6.97-8.018,13.161-16.718,19.568-25.213c1.272-1.686,2.271-3.754,2.793-5.8
                c2.429-9.508,8.181-16.106,17.517-18.784c5.922-1.698,9.334-6.145,13.429-9.867c8.626-7.845,15.371-17.065,18.812-28.37
                c1.787-5.867,1.127-6.85-4.456-8.695c-3.583-1.185-7.109-2.539-10.662-3.817c-0.117-0.498-0.234-0.997-0.353-1.495
                c5.077-2.534,10.22-4.948,15.207-7.648c3.015-1.633,6.356-3.202,8.571-5.677c9.51-10.626,18.656-21.577,27.893-32.36
                c8.861,15.906,15.216,34.382,32.008,47.575c-5.805,3.874-11.104,7.41-16.865,11.255c8.284-0.182,12.759-6.2,18.692-10.646
                c4.943,3.326,9.981,6.716,15.766,10.609c-3.532,3.345-6.91,5.874-9.428,9.079c-3.967,5.052-7.505,10.463-10.932,15.907
                c-2.022,3.213-1.199,6.56,1.441,9.103c7.105,6.845,9.456,16.315,13.779,24.683c0.556,1.076,0.106,3.5-0.784,4.367
                c-10.495,10.221-21.129,20.302-31.834,30.305c-1.257,1.175-3.153,1.665-5.595,2.898c1.087-8.155,2.323-15.586-0.116-24.326
                c-3.436,3.354-6.515,5.495-8.459,8.397c-11.749,17.539-28.12,30.588-43.008,45.101c-5.811,5.663-10.797,12.164-16.308,18.146
                c-1.892,2.054-4.086,3.91-6.399,5.479c-9.395,6.365-17.244,14.166-22.677,24.183c-1.652,3.048-3.121,6.307-3.982,9.647
                c-2.106,8.173-0.385,11.312,7.192,14.935c1.774,0.849,3.988,1.763,4.872,3.297c2.377,4.124,5.404,6.595,10.889,5.711
                c-4.5,9.733-9.744,18.376-11.277,28.354c0.715,0.585,1.43,1.171,2.145,1.756c0.86-1.591,1.447-3.427,2.622-4.736
                c21.117-23.569,42.073-47.291,63.601-70.481c8.006-8.624,17.264-16.113,26.172-23.865c9.771-8.505,19.725-17.128,21.874-30.604
                c1.267-7.942,4.202-14.308,9.325-20.257c2.655-3.083,5.024-6.926,6.013-10.81c1.447-5.686,5.01-8.875,9.398-12.125
                c6.817-5.049,13.226-10.658,19.688-16.172c1.977-1.686,3.748-1.958,6.447-2.077c8.099-0.357,16.17-1.771,24.197-3.104
                c4.579-0.761,9.106-1.984,13.558-3.331c7.007-2.121,13.872-2.679,21.124-0.777c2.644,0.693,6.408-0.119,8.766-1.637
                c6.424-4.133,12.254-9.175,18.532-13.551c10.51-7.326,21.08-14.58,31.859-21.499c3.029-1.944,6.877-2.614,10.35-3.869
                c0.276,0.483,0.553,0.966,0.83,1.449c-2.485,2.998-5.019,5.957-7.436,9.01c-1.228,1.55-2.334,3.214-3.341,4.918
                c-2.508,4.246-3.374,7.989,1.978,11.177c3.783,2.254,4.568,6.171,2.312,10.047c-3.252,5.584-6.332,11.38-10.369,16.37
                c-7.947,9.821-16.508,19.146-24.795,28.694c-0.598,0.689-1.032,1.521-2.398,3.568c3.485-0.648,5.704-0.876,7.807-1.495
                c3.504-1.032,7.07-2.021,10.361-3.561c5.058-2.366,8.927-2.026,12.278,2.98c0.811,1.211,4.087,2.018,5.479,1.378
                c6.153-2.826,12.503-5.609,17.909-9.593c10.436-7.689,18.613-17.451,24.31-29.324c1.683-3.507,4.697-7.086,8.039-8.965
                c15.871-8.921,30.484-19.588,45.215-30.207c7.859-5.666,17.154-9.395,25.964-13.663c4.268-2.068,8.136-0.759,11.986,2.219
                c3.437,2.657,7.691,4.271,11.629,6.258c1.179,0.594,2.523,0.847,3.762,1.335c9.613,3.792,9.691,3.861,16.044-4.236
                c3.389-4.319,6.368-8.958,9.672-13.347c2.397-3.186,4.78-6.446,7.603-9.234c5.381-5.317,6.685-5.344,14.303-1.701
                c-4.984,5.356-5.062,11.311-4.059,18.381c1.598,11.255-4.623,20.418-11.327,28.94c-3.292,4.186-6.753,8.244-9.909,12.528
                c-4.94,6.706-8.709,13.822-11.048,22.078c-3.839,13.549-13.385,23.952-21.857,34.896c-10.383,13.414-20.279,27.203-30.386,40.831
                c-1.485,2.003-2.704,4.288-4.49,5.971c-17.164,16.168-25.21,38.189-37.181,57.685c-0.304,0.495-0.233,1.22,0.274,2.255
                c0.56-0.771,1.195-1.5,1.665-2.323c6.188-10.835,12.008-21.901,18.644-32.454c4.579-7.282,10.242-13.889,15.498-20.736
                c2.024-2.637,4.372-5.024,6.388-7.667c11.299-14.812,22.407-29.771,33.844-44.477c9.754-12.543,18.13-25.455,22.966-41.061
                c2.717-8.77,10.123-16.251,16.059-23.809c5.516-7.022,10.71-14.133,12.766-22.895c0.923-3.937,1.549-8.46,0.512-12.222
                c-1.672-6.068,0.084-10.868,4.143-14.446c7.496-6.61,11.686-15.143,16.189-23.702c2.762-5.249,6.811-9.808,10.09-14.802
                c4.104-6.248,7.988-12.639,12.005-18.944c4.244-6.662,8.532-13.296,13.26-20.655c3.77,5.919,6.562,10.607,9.653,15.089
                c4.976,7.211,10.83,13.475,19.055,17.18c3.007,1.354,4.647,0.931,6.395-2.045c2.684-4.57,5.912-8.831,9.048-13.122
                c2.841-3.887,6.784-5.157,11.473-4.288c0.982,0.182,1.975,0.312,2.965,0.442c7.413,0.983,7.177,0.915,9.267-6.446
                c2.085-7.344,4.758-14.52,7.086-21.797c2.386-7.461,7.5-12.775,13.857-17.886c0.164,1.578,0.4,2.79,0.401,4.003
                c0.021,28.164,0.021,56.328-0.001,84.493c-0.001,1.484,0.176,3.171-0.433,4.423c-8.065,16.59-16.054,33.224-24.515,49.612
                c-3.387,6.558-5.631,6.715-9.731,0.383c-3.357-5.186-6.498-5.203-10.831-2.151c-3.305,2.329-5.594,0.611-6.682-2.257
                c-1.812-4.776-3.13-9.756-4.404-14.715c-1.528-5.946-3.174-7.392-9.107-5.953c-5.682,1.378-10.87,1.784-16.361-1.175
                c-4.03-2.173-7.989-0.306-11.392,2.704c-11.532,10.203-20.785,22.157-26.57,36.414c-6.782,16.713-14.142,33.042-25.111,47.503
                c-0.886,1.167-1.713,2.578-2.916,3.274c-12.539,7.251-21.073,18.113-28.136,30.442c-13.892,24.253-28.305,48.215-41.773,72.697
                c-10.653,19.364-20.231,39.319-30.24,59.036c-0.836,1.646-1.317,3.47-2.248,5.979c1.486-0.61,2.053-0.673,2.351-0.989
                c10.152-10.786,20.215-21.659,30.44-32.375c4.572-4.792,8.845-10.258,14.325-13.724c19.175-12.125,30.919-30.408,42.431-48.998
                c2.256-3.642,4.174-7.491,7.041-10.984c-1.407,5.943-2.814,11.886-4.544,19.19c18.64-14.762,33.738-30.796,47.834-47.751
                c14.016-16.859,27.526-34.138,41.856-51.977c0.729,8.865,1.437,17.466,2.233,27.156c2.733-2.015,4.988-3.677,7.255-5.349
                c9.044,8.081,12.165,8.38,23.75,3.18c2.491-1.119,5.602-0.77,8.296-1.548c4.878-1.41,9.874-2.711,14.415-4.898
                c4.259-2.052,7.993-5.192,12.441-8.18c0,96.769,0,192.99,0,289.441c-5.23-11.812-10.408-23.504-15.586-35.196
                c-0.353,0.037-0.705,0.074-1.058,0.111c-1.768,8.597-3.534,17.193-5.306,25.813c-4.065-0.364-7.287-0.652-11.521-1.031
                c-0.911-6.56-1.874-13.484-2.837-20.408c-0.332-0.035-0.665-0.069-0.998-0.104c-0.908,5.997-1.816,11.995-2.544,16.795
                c-3.039,0.137-6.152-0.573-8.29,0.56c-3.848,2.039-6.66,0.906-9.387-1.426c-3.649-3.122-6.725-2.606-10.025,0.592
                c-2.481,2.406-5.36,4.401-8.232,6.716c-1.071-0.896-2.166-1.639-3.047-2.581c-2.758-2.95-5.468-2.158-7.713,0.378
                c-2.894,3.271-6.37,4.25-10.576,5.061c-2.734,0.526-6.157,3.843-6.952,6.583c-1.172,4.04-3.78,5.542-6.829,7.17
                c-2.112,1.129-4.244,2.222-6.972,3.646c-1.619-2.153-3.659-4.867-5.7-7.581c0.217-0.256,0.434-0.512,0.65-0.769
                c2.822,1.484,5.646,2.969,9.404,4.944c-0.61-3.935-1.065-6.873-1.598-10.306c2.921,1.938,5.676,3.766,8.431,5.594
                c0.436-0.375,0.871-0.75,1.307-1.125c-12.796-9.594-19.312-22.863-23.082-37.988c-5.803,11.43-11.392,22.438-16.981,33.448
                c0.309,0.267,0.617,0.534,0.925,0.801c1.321-1.4,2.643-2.802,3.963-4.202L1021.933,700.078z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(2)} fill={props.fillColors[2]}  d="M846.99,1112.938c-0.701-2.429-1.401-4.856-2.103-7.285
                c-0.353,0.031-0.704,0.062-1.057,0.095c-0.062,1.179-0.282,2.375-0.162,3.535c1.249,11.996,1.65,24.19-2.378,35.537
                c-3.752,10.568-9.683,20.355-14.539,30.541c-4.716,9.889-9.264,19.857-13.943,29.763c-0.666,1.411-0.117,4.342-3.409,3.194
                c-0.109-0.038-1.187,2.093-1.479,3.294c-1.211,4.969-2.294,9.969-4.347,14.833c0.121-1.703,0.241-3.407,0.362-5.11
                c-0.437-0.14-0.874-0.28-1.311-0.421c-2.984,7.77-5.968,15.539-8.951,23.308c-0.374-0.096-0.748-0.191-1.122-0.287
                c-0.29-0.954-0.58-1.908-1.028-3.383c-1.181,1.391-2.177,2.281-2.827,3.376c-5.396,9.077-14.55,12.156-23.789,15.252
                c-1.866,0.626-4.04,1.909-4.959,3.522c-4.755,8.35-5.466,17.726-5.859,27.051c-0.483,11.438-0.609,22.891-0.912,35.237
                c-5.253-2.481-10.915-2.513-10.377-10.273c-0.524,0.319-1.049,0.64-1.573,0.96c0,3.188,0,6.375,0,8.921
                c-11.423,0-22.094,0.121-32.758-0.077c-3.002-0.056-4.088-6.185-1.264-9.128c4.139-4.313,8.58-8.396,13.261-12.113
                c7.159-5.687,9.891-13.118,9.778-21.919c-0.044-3.498-0.238-6.995-0.243-10.493c-0.008-5.136-1.069-10.637,0.4-15.329
                c2.663-8.502-0.395-15.8-2.353-23.569c-2.262-8.977-3.889-18.111-5.882-27.158c-0.197-0.896-1.017-1.654-1.548-2.476
                c-0.649,0.242-1.298,0.484-1.946,0.727c1.165-7.697,2.331-15.395,3.605-23.808c-6.296,7.37-7.214,23.053-3.343,36.16
                c-3.952-4.514-7.905-9.026-11.857-13.54c-0.37,0.189-0.739,0.379-1.108,0.568c0.364,1.371,0.729,2.741,1.254,4.721
                c-2.759-2.348-5.419-4.254-7.646-6.575c-6.104-6.363-15.245-9.541-19.128-18.243c-0.178-0.397-1.057-0.482-1.609-0.713
                c-0.411,0.312-0.822,0.623-1.232,0.936c2.027,3.121,4.056,6.243,6.422,9.887c-15.497-6.252-25.601-15.954-25.092-34.292
                c-4.248,4.649-3.682,7.299,3.891,25.333c-6.124-4.969-8.111-12.355-10.422-20.535c-3.284,9.186-4.612,10.746-11.504,13.717
                c0.628-2.562,1.146-4.681,1.811-7.391c-1.127,0.6-1.986,1.082-2.868,1.52c-4.02,1.993-7.646,8.156-12.587,1.089
                c-0.357-0.511-2.577-0.074-3.745,0.39c-10.062,4.001-19.156,8.866-22.9,20.338c-1.336,4.093-4.512,7.716-7.328,11.162
                c-3.344,4.092-7.205,7.761-10.716,11.479c0.079-1.468,0.175-3.26,0.27-5.052c-5.433,7.4-1.866,15.521-2.218,24.793
                c-6.549-4.091-12.22-7.632-18.125-11.319c-0.134,0.718-0.28,1.502-0.452,2.427c-5.514-3.569-8.502-8.836-10.56-14.747
                c-0.762-2.188-1.059-4.779-4.478-3.621c-0.122,0.041-0.338-0.062-0.456-0.162c-5.99-5.043-14.317-7.716-17.025-16.886
                c7.165-0.545,14.316-0.379,20.238-5.253c-12.744,1.431-25.74,5.441-36.786-6.093c1.938-0.396,3.89-1.186,5.811-1.116
                c7.147,0.259,14.286,1.175,21.424,1.136c8.16-0.045,16.315-0.846,24.473-1.318c0.036-0.622,0.072-1.243,0.108-1.865
                c-0.98-0.604-1.96-1.208-2.634-1.624c1.536-3.18,3.064-6.344,4.593-9.508c-0.502-0.276-1.005-0.554-1.507-0.83
                c-0.724,1.267-1.476,2.519-2.166,3.803c-3.091,5.755-7.622,9.106-14.42,8.854c-11.974-0.445-24.004-0.272-35.908-1.42
                c-11.896-1.147-24.339-9.312-19.351-26.223c1.774,3.912,3.247,7.794,5.286,11.35c0.868,1.514,3.039,3.365,4.407,3.21
                c1.448-0.165,3.477-2.409,3.713-3.977c0.225-1.496-1.238-4.446-2.466-4.757c-5.808-1.471-6.794-7.457-10.707-10.585
                c-0.26-0.208-0.232-0.93-0.156-1.382c2.818-16.696-1.48-32.717-4.252-48.948c-1.188-6.96-1.126-14.293-0.513-21.359
                c0.733-8.442-0.178-16.333-4.523-23.569c-4.346-7.237-4.402-14.295-0.802-21.835c1.698-3.558,2.303-7.623,3.676-11.357
                c0.53-1.444,1.816-2.611,3.491-4.911c-0.136-0.186-1.094-1.623-2.171-2.963c-5.077-6.314-7.112-13.357-5.582-21.434
                c0.428-2.258,0.148-4.647,0.226-6.975c0.061-1.827-0.415-3.961,0.356-5.428c4.661-8.87,7.314-18.958,15.281-25.865
                c0.876-0.76,1.827-1.472,2.841-2.027c5.226-2.862,10.276-6.193,15.785-8.342c5.505-2.147,11.405-3.644,17.265-4.427
                c7.85-1.049,15.827-1.545,23.748-1.535c10.206,0.013,20.72-0.626,30.534,1.558c11.099,2.471,21.706,7.446,32.217,12.042
                c2.673,1.169,4.213,4.994,6.198,7.654c1.89,2.533,3.253,5.647,5.593,7.621c8.947,7.548,8.775,17.422,7.214,27.451
                c-1.366,8.771-8.087,14.151-17.023,14.711c-3.809,0.238-7.711,0.059-11.4,0.857c-5.629,1.22-8.895,5.959-7.524,11.124
                c2.761-8.474,9.014-9.213,16.054-9.071c14.893,0.298,24.594-9.789,23.294-24.706c-1.006-11.543-2.98-23.002-4.601-35.067
                c2.328,2.48,5.323,4.564,6.799,7.432c7.481,14.535,11.368,30.009,8.15,46.294c-3.502,17.727-20.967,28.252-38.695,24.22
                c-1.373-0.312-2.793-0.415-4.191-0.614c-0.173,0.455-0.346,0.91-0.519,1.365c1.561,0.724,3.053,1.722,4.692,2.129
                c21.523,5.356,42.565-11.644,42.761-34.897c0.105-12.596-2.437-24.847-7.696-36.364c-2.89-6.326-7.146-11.427-15.62-13.673
                c1.476,7.272,2.74,13.501,4.004,19.729c-0.277,0.197-0.555,0.395-0.832,0.593c-2.478-3.159-5.316-6.102-7.362-9.52
                c-4.948-8.268-11.06-14.587-21.377-15.238c-1.116-0.07-2.296-0.565-3.275-1.149c-6.686-3.986-13.598-7.01-21.679-6.021
                c-0.881,0.108-1.849-0.487-2.384-0.643c5.511-8.575,11.483-16.774,16.229-25.63c3.3-6.156,4.637-13.331,7.356-19.839
                c3.019-7.226,6.58-14.226,9.973-21.292c1.413-2.945,2.824-5.915,4.537-8.688c4.551-7.364,8.395-15.412,14.109-21.755
                c9.617-10.677,18.974-21.66,35.94-21.952c12.774-0.221,24.479,2.721,36.437,5.866c2.83,0.744,5.889,0.667,8.849,0.859
                c3.843,0.249,7.693,0.39,11.989,0.6c-2.167-3.303-5.357-4.012-9.295-4.011c-5.317,0.001-10.636-0.565-15.953-0.888
                c-0.01-0.533-0.021-1.065-0.03-1.599c1.169-0.189,2.38-0.244,3.5-0.591c4.7-1.453,10.186-1.94,13.854-4.767
                c4.698-3.618,9.383-3.894,14.726-4.311c7.896-0.617,15.703-2.321,23.572-3.381c3.048-0.411,6.177-0.564,9.243-0.391
                c2.463,0.14,5.117,1.855,7.289,1.306c9.632-2.442,19.004,1.158,28.54,0.748c3.888-0.167,7.907,1.471,11.78,2.587
                c4.413,1.272,8.698,2.984,13.096,4.318c8.056,2.444,16.154,4.746,24.23,7.123c1.109,0.327,2.227,1.144,3.284,1.056
                c8.7-0.724,16.521,2.869,24.669,4.841c0.965,0.233,1.953,0.471,2.843,0.892c5.995,2.84,12.071,5.532,17.918,8.652
                c7.866,4.197,15.417,8.996,23.348,13.058c8.713,4.462,17.703,8.381,26.573,12.532c8.578,4.015,17.371,7.632,25.68,12.143
                c8.874,4.817,17.877,8.939,28,9.952c17.393,1.74,29.43,11.902,38.705,25.502c6.923,10.149,12.779,21.058,18.648,31.878
                c2.179,4.018,3.603,8.649,4.398,13.173c2.447,13.911,4.646,27.877,6.457,41.885c0.606,4.688,0.517,9.744-0.568,14.32
                c-4.557,19.24-9.556,38.376-14.5,57.522c-4.932,19.097-12.373,37.097-21.864,54.475c-5.832,10.677-9.267,22.666-13.712,34.093
                c-0.301,0.774-0.039,1.77,0.868,2.922c2.801-7.597,5.601-15.194,8.4-22.791c0.08,20.323-3.521,34.279-12.206,48.774
                c-0.229-2.068-0.413-3.73-0.597-5.393c-0.467-0.077-0.935-0.155-1.402-0.233c-0.505,1.715-1.275,3.399-1.473,5.149
                c-1.992,17.699-4.095,35.391-5.723,53.126c-0.713,7.763-0.237,15.628-0.587,23.435c-0.715,15.962-6.194,22.481-22.962,26.748
                c-0.824-4.949-1.635-9.822-2.445-14.694c-0.377-0.169-0.754-0.338-1.131-0.507c-1.945,3.067-3.89,6.135-6.069,9.572
                c-3.925-6.646-1.011-12.867,0.208-19.682c10.638,3.88,21.058,4.26,31.45,0.25c-10.464-0.538-21.161,2.445-31.792-3.192
                c3.099-5.219,5.857-10.917,9.572-15.9c7.555-10.134,10.452-21.734,12.156-33.889c2.01-14.336,4.189-28.648,6.253-42.977
                c0.1-0.689-0.204-1.437-0.32-2.157c-0.505-0.082-1.009-0.164-1.513-0.246c-1.443,6.564-2.887,13.129-4.491,20.422
                c-1.807-11.501-1.912-22.178,0.919-32.723c4.52-16.835,4.769-33.9,2.904-51.075c-0.362-3.338,0.039-5.872,2.761-8.384
                c13.572-12.529,19.097-29.345,23.854-46.434c2.184-7.846,3.804-15.851,5.557-23.811c0.415-1.887,0.333-3.882,0.48-5.827
                c-0.438-0.14-0.876-0.279-1.313-0.419c-0.645,1.587-1.43,3.133-1.911,4.769c-4.729,16.084-8.531,32.516-14.463,48.14
                c-3.07,8.086-9.517,15.052-15.121,21.971c-5.854,7.225-12.919,13.471-18.747,20.714c-7.624,9.476-16.369,17.508-26.735,23.774
                c-6.849,4.142-13.929,7.909-20.678,12.2c-1.738,1.104-2.62,3.556-4.703,6.55c0.304-5.028,1.026-7.835,4.889-9.873
                c7.175-3.789,14.074-8.127,20.901-12.527c1.915-1.233,3.177-3.482,4.099-5.999c-0.565,0.151-1.282,0.131-1.675,0.479
                c-15.423,13.625-34.152,19.267-53.844,23.254c-19.994,4.048-40.03,6.597-59.779,6.159c0.618-12.464,1.228-24.764,1.838-37.062
                c1.388-1.207,1.555-2.729,1.345-4.465c-0.686-5.686-1.637-11.295-3.543-15.433C845.949,1098.757,850.225,1105.345,846.99,1112.938
                z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(3)} fill={props.fillColors[3]}  d="M622.143,35.992c-11.12-3.196-21.063-11.868-32.765-10.599
                c-15.463,1.679-29.48,0.079-43.747-6.049c-5.664-2.432-13.066-0.751-19.671-1.073c-6.95-0.341-13.897-0.767-20.836-1.295
                c-3.827-0.291-6.325-2.068-7.19-6.221c-0.562-2.692-2.152-5.17-3.488-8.215c222.808,0,445.277,0,668.088,0
                c0,17.771,0,35.477,0,53.262c-25.734-17.006-45.393-0.433-53.589,24.37c-1.997-2.59-3.713-5.173-5.782-7.431
                c-6.852-7.479-15.23-8.181-22.224-0.829c-4.115,4.325-6.631,10.182-9.797,15.392c-0.582,0.957-0.834,2.115-1.276,3.281
                c-2.285-0.317-4.527-1.197-6.471-0.758c-2.545,0.574-5.85,1.565-7.057,3.488c-2.454,3.91-5.813,5.596-9.822,7.055
                c-3.228,1.174-6.125,3.229-9.309,4.563c-1.549,0.648-3.773,1.281-5.078,0.65c-6.738-3.256-13.13-7.24-19.916-10.382
                c-15.031-6.959-29.863-7.18-43.063,3.96c-4.301,3.631-6.909,9.22-10.558,13.695c-1.42,1.743-3.464,3.893-5.418,4.109
                c-5.41,0.599-10.947,0.629-16.389,0.243c-9.177-0.652-18.094-1.205-24.5,7.362c-0.769,1.026-2.595,1.557-4.008,1.75
                c-4.072,0.557-8.001,0.584-11.286,4.146c-1.146,1.242-5.099,0.423-7.589-0.188c-15.067-3.695-30.149-4.992-45.477-1.602
                c-11.679,2.583-22.043,7.521-30.31,16.284c-3.204,3.396-6.609,4.271-11.209,3.446c-3.265-0.585-7.112-0.402-10.156,0.808
                c-5.643,2.242-11.152,2.484-16.507,0.252c-4.188-1.745-7.92-1.05-12.097-0.403c-5.644,0.872-11.779,1.976-17.129,0.654
                c-13.244-3.272-26.1-2.158-39.005,0.898c-10.308,2.441-20.541,5.199-30.795,7.863c-1.035,0.269-1.986,0.859-4.376,1.925
                c8.225,1.781,15.005,4.229,21.875,4.51c10.381,0.423,20.821-0.735,31.241-1.094c12.817-0.441,25.271,0.202,37.759,4.689
                c8.848,3.18,18.915,2.94,28.427,4.309c2.601,0.374,5.146,1.12,7.74,1.555c7.344,1.23,14.661,2.922,22.06,3.45
                c11.78,0.841,23.616,1.121,35.431,1.192c8.805,0.054,17.61-0.703,26.421-0.9c7.262-0.162,14.53-0.033,22.853-0.033
                c-5.174-8.767-13.482-12.01-21.72-16.901c2.15-0.215,3.368-0.486,4.573-0.437c19.461,0.806,38.926,1.579,58.375,2.611
                c1.775,0.095,3.634,1.702,5.147,2.972c2.515,2.109,4.387,5.423,7.215,6.647c18.69,8.098,37.854,15.645,58.615,13.696
                c14.14-1.326,28.023-5.312,42.046-7.979c10.115-1.925,20.24-3.847,30.42-5.383c9.732-1.468,18.98,1.074,28.462,3.299
                c13.984,3.281,28.36,4.888,43.543,7.365c0,8.266,0.066,18.024-0.094,27.779c-0.019,1.091-1.102,2.457-2.072,3.195
                c-13.409,10.199-17.64,25.303-21.777,40.466c-0.474,1.734-0.898,3.483-1.36,5.282c-6.864,1.162-14.537-3.267-20.053,3.302
                c-4.37,5.205-8.36,10.729-12.931,16.643c-14.788-5.782-21.016-20.068-29.543-33.534c-3.209,3.424-6.317,6.154-8.69,9.418
                c-3.022,4.157-5.354,8.809-8.156,13.134c-4.694,7.244-9.654,14.318-14.263,21.616c-4.164,6.596-8.01,13.395-11.99,20.106
                c-1.606,2.708-2.986,5.582-4.847,8.102c-2.974,4.027-6.378,6.241-11.766,3.262c-1.793-0.992-5.621-0.497-7.26,0.85
                c-4.316,3.551-8.223,7.711-11.772,12.054c-4.842,5.921-9.263,12.191-13.735,18.405c-1.59,2.209-2.985,2.266-5.411,1.106
                c-7.714-3.688-16.352-5.344-22.905-11.635c-1.553-1.49-5.481-1.516-7.959-0.86c-11.911,3.151-22.208,9.577-32.225,16.578
                c-15.515,10.844-31.196,21.447-47.884,32.891c-10.081-12.492-19.607-23.998-28.771-35.785c-4.211-5.416-6.704-7.091-12.987-4.189
                c-6.907,3.188-13.367,7.399-19.836,11.461c-2.769,1.737-4.978,2.513-8.142,0.738c-3.601-2.019-7.792-2.973-11.426-4.945
                c-5.513-2.992-10.899-2.612-16.235-0.101c-3.951,1.86-7.644,1.754-11.479-0.066c-6.008-2.854-12.34-5.161-17.988-8.598
                c-6.879-4.185-13.786-4.432-21.061-2.197c-14.586,4.479-29.128,9.103-44.237,13.838c-3.721-10.523-13.207-15.108-21.954-20.54
                c-8.025-4.984-13.602-12.3-17.993-20.396c-5.625-10.374-11.021-20.906-15.816-31.683c-2.522-5.667-3.475-12.021-5.285-18.021
                c-1.196-3.964-2.276-8.035-4.083-11.729c-3.435-7.022-6.457-14.542-11.324-20.484c-4.827-5.892-11.665-10.16-17.783-14.933
                c-0.556-0.434-2.452,0.408-3.455,1.069c-3.46,2.28-6.801,4.742-10.46,7.327c-9.419-9.143-19.088-18.029-28.103-27.537
                c-3.313-3.494-5.695-3.688-9.176-1.141c-1.431,1.047-3.058,1.825-4.889,2.897c-1.448-5.207-2.641-10.017-4.125-14.734
                c-4.829-15.354-9.327-30.832-14.766-45.971c-7.923-22.058-12.963-44.707-15.512-67.931c-0.796-7.25-3.518-13.284-8.115-18.874
                c-3.568-4.338-6.663-9.141-9.408-14.05c-1.603-2.867-3.635-3.76-5.999-2.301c-3.896,2.403-7.909,4.979-10.965,8.313
                c-7.929,8.654-13.901,19.186-24.712,24.9c-2.037-1.782-4.207-3.681-7.503-6.563c6.562-0.308,11.502-0.539,16.443-0.771
                c0.097-0.527,0.193-1.054,0.29-1.581c-3.572-1.48-7.058-3.248-10.74-4.365c-3.963-1.202-6.49-3.394-8.175-7.232
                c-2.795-6.369-7.435-10.905-14.396-12.868c-1.223-0.344-2.557-1.849-3-3.111c-1.425-4.064-2.457-8.268-3.399-11.573
                c8.438-0.74,16.896-1.934,25.371-2.082c6.928-0.122,13.875,1.03,20.817,1.56c2.457,0.188,5.062,0.752,7.366,0.189
                c6.635-1.62,12.733,0.074,19.109,1.604c8.333,1.997,16.891,3.128,25.396,4.312c4.929,0.686,9.986,1.31,14.915,0.964
                c10.238-0.72,20.424-2.162,30.645-3.18c3.127-0.312,6.348-0.618,9.438-0.227c18.79,2.381,37.538,5.099,56.34,7.377
                c4.208,0.51,8.574-0.286,12.867-0.48C621.937,37.627,622.039,36.81,622.143,35.992z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(4)} fill={props.fillColors[4]}  d="M285.678,536.706c2.034-1.95,4.223-3.765,6.078-5.872
                c26.549-30.149,58.432-53.888,91.459-76.267c12.129-8.218,24.16-16.579,36.28-24.811c14.843-10.08,26.062-23.399,33.627-39.483
                c5.664-12.043,13.578-21.962,23.569-30.499c4.684-4.003,8.899-8.588,13.765-12.341c18.553-14.308,29.181-33.724,36.701-55.337
                c4.094-11.766,8.673-23.362,13.071-35.021c1.6-4.24,4.729-6.728,8.676-9.066c6.643-3.936,12.677-8.902,18.937-13.478
                c3.052-2.23,6.034-4.556,8.832-6.675c1.138,8.704,2.425,17.018,3.195,25.378c0.231,2.504-0.55,5.336-1.613,7.679
                c-2.812,6.197-5.906,12.275-9.113,18.28c-8.695,16.283-17.514,32.501-26.256,48.759c-0.424,0.788-0.591,1.714-1.307,3.865
                c2.567-1.862,4.056-2.942,6.399-4.644c-8.633,21.965-20.992,40.391-36.298,57.009c0.538,0.541,1.076,1.083,1.614,1.625
                c7.442-5.764,14.885-11.527,22.328-17.291c-30.06,36.742-62.199,71.52-96.585,104.432c3.783-7.012,7.565-14.023,11.348-21.034
                c-0.38-0.442-0.761-0.884-1.142-1.325c-3.779,1.778-7.969,3.008-11.25,5.454c-6.252,4.661-11.921,10.097-17.947,15.071
                c-24.271,20.034-48.819,39.741-72.829,60.083c-31.518,26.702-62.625,53.889-93.902,80.874c-1.617,1.395-3.18,2.854-5.002,4.493
                c-7.535-5.979-11.854-13.832-15.083-22.231c-3.19-8.298-5.688-16.862-9.004-26.862c-4.516,18.927-11.756,35.136-21.946,50.669
                c2.207-0.819,4.413-1.639,7.401-2.749c-2.021,4.109-4.018,7.545-5.407,11.212c-0.96,2.536-3.868,5.798,1.13,7.755
                c0.399,0.156,0.592,1.91,0.272,2.662c-2.57,6.036-5.287,12.01-8.5,19.22c-7.185-9.624-9.426-19.479-12.578-28.842
                c-3.572,8.85-7.092,17.573-10.75,26.635c-0.04-0.016,0.95,0.365,1.941,0.746c-0.676,1.817-1.522,3.715-2.07,5.695
                c-0.363,1.311-0.3,2.74-0.428,4.116c0.497,0.154,0.995,0.309,1.492,0.464c-1.025,1.45-2.05,2.9-3.458,4.895
                c-3.84-4.44-7.386-8.541-10.932-12.642c0.177-0.238,0.354-0.478,0.53-0.716c2.303,0.906,4.605,1.812,8.199,3.227
                c-6.083-17.126-11.672-32.863-17.106-48.165c-8.01,10.737-16.607,22.262-25.475,34.148c3.164,0,6.598,0,10.581,0
                c-1.655,3.579-3.097,6.698-4.798,10.376c1.776-0.199,3.053-0.342,4.393-0.492c-0.854,4.477-1.678,8.788-2.5,13.1
                c-0.774,0.104-1.547,0.208-2.321,0.312c-3.087-5.391-6.175-10.781-9.263-16.172c4.158-5.729-3.504-9.905-1.965-16.042
                c0.943-3.761-2.986-8.839-4.969-13.216c-2.509-5.539-5.264-10.966-7.913-16.441c-0.451,0.002-0.902,0.004-1.353,0.007
                c-0.825,5.439-1.637,10.882-2.483,16.318c-0.281,1.806-0.652,3.597-0.982,5.396l0.084,0.082c-2.477-3.113-4.676-6.507-7.478-9.293
                c-12.105-12.036-18.038-26.893-20.459-43.447c-0.111-0.756-0.366-1.491-0.944-3.781c-6.857,14.719-10.824,29.491-23.162,40.782
                c2.708,0.206,4.051,0.309,5.78,0.439c-0.481,1.64-0.962,3.279-1.655,5.645c2.305,0.152,4.274,0.282,6.468,0.428
                c-0.269,10.647-9.141,16.161-15.115,23.394c0.297,0.418,0.593,0.837,0.89,1.255c3.858-0.975,7.716-1.949,12.382-3.129
                c-2.197,3.243-4.057,5.987-5.917,8.732c0.184,0.312,0.367,0.626,0.55,0.938c2.053-0.757,4.105-1.515,7.64-2.818
                c-3.93,5.644-6.803,10.558-10.459,14.796c-3.653,4.236-8.089,7.796-13.087,12.506c6.06,1.293,11.351,2.423,17.153,3.66
                c-0.362,6.079-6.146,10.633-10.454,16.734c2.37,0.155,3.798,0.249,5.887,0.387c-1.978,3.672-3.81,7.074-6.237,11.58
                c-4.688-10.391-8.875-19.67-12.857-28.496c0.396-1.108,1.323-2.333,1.048-3.156c-1.679-5.023-3.032-9.798-2.244-15.433
                c0.419-2.997-3.002-6.424-4.301-9.805c-0.955-2.484-4.924-5.003-0.496-7.958c0.309-0.206,0.184-1.3,0.031-1.925
                c-1.48-6.049-3.01-12.085-5.536-18.322c-2.422,5.646-7.294,11.657-6.522,16.826c0.667,4.471-0.917,6.834-2.249,10.474
                c1.079,0.498,2.165,0.999,4.102,1.893c-2.671,5.243-5.361,10.522-8.291,16.271c1.375-0.101,3.017-0.222,5.697-0.418
                c-2.326,5.089-4.465,9.768-6.722,14.705c1.547-0.621,3.204-1.286,5.628-2.259c-0.47,1.94-0.727,3.325-1.142,4.661
                c-2.212,7.124-4.463,14.234-6.701,21.35c-0.581-0.094-1.162-0.188-1.742-0.28c-0.137-1.146-0.394-2.293-0.394-3.439
                c-0.02-49.999-0.064-99.997,0.125-149.995c0.009-2.442,1.548-5.379,3.282-7.24c24.071-25.853,48.236-51.618,72.51-77.281
                c22.396-23.677,41.035-50.31,60.66-76.186c6.723-8.865,14.775-16.71,21.782-25.376c12.25-15.151,24.045-30.67,36.254-45.855
                c8.398-10.444,18.122-19.508,29.643-26.543c2.109-1.288,3.894-3.114,5.806-4.719c4.209-3.532,7.931-8.006,12.696-10.447
                c13.291-6.812,20.296-18.878,27.238-30.946c14.437-25.099,28.473-50.429,42.696-75.65c2.614-4.637,5.379-9.188,7.988-13.828
                c0.866-1.54,1.465-3.23,2.186-4.853c-0.389-0.33-0.776-0.659-1.165-0.988c-1.094,1.014-2.368,1.892-3.255,3.062
                c-17.303,22.824-34.536,45.702-51.873,68.501c-1.477,1.941-3.444,3.601-5.432,5.049c-8.979,6.541-18.534,12.391-26.93,19.599
                c-6.865,5.895-12.854,12.996-18.391,20.208c-8.41,10.953-15.797,22.69-24.184,33.663c-5.944,7.778-13.028,14.676-19.174,22.312
                c-2.314,2.876-3.272,6.806-5.105,10.113c-3.843,6.932-7.099,14.359-11.924,20.549c-29.377,37.684-63.114,71.332-97.689,104.166
                c-17.874,16.975-35.878,33.812-53.831,50.703c-0.957,0.899-1.986,1.722-3.55,3.069c-0.158-1.966-0.364-3.345-0.365-4.724
                c-0.017-38.832-0.075-77.664,0.123-116.495c0.013-2.64,1.373-5.808,3.146-7.807c6.522-7.348,13.484-14.305,20.277-21.412
                c11.319-11.845,21.401-24.597,29.558-38.88c5.835-10.219,13.343-18.775,23.59-25.259c7.08-4.48,12.96-10.945,19.084-16.828
                c15.26-14.658,30.264-29.583,45.537-44.227c14.64-14.037,30.358-26.706,49.881-33.292c8.746-2.95,17.959-4.558,27.016-6.524
                c3.641-0.791,5.557,0.95,5.565,4.918c0.004,1.905,0.86,3.81,1.45,6.201c2.193-0.768,3.984-1.395,6.328-2.214
                c-0.585,5.281-1.129,10.19-1.673,15.101c0.429,0.243,0.857,0.486,1.286,0.729c7.965-6.375,16.28-12.368,23.798-19.234
                c7.529-6.876,14.437-14.345,24.818-16.979c1.013-0.257,1.939-1.237,2.695-2.071c8.033-8.868,16.026-17.771,24.009-26.683
                c0.29-0.324,0.369-0.838,1.053-2.481c-2.161,1.519-3.502,2.282-4.629,3.287c-2.982,2.659-6.056,5.252-8.763,8.178
                c-8.234,8.899-15.2,18.963-27.418,23.589c-4.518,1.71-7.806,6.588-11.754,9.902c-7.227,6.066-14.527,12.045-22.729,18.831
                c0.842-5.834,1.489-10.319,2.287-15.845c-3.036,1.043-5.242,1.801-7.522,2.584c-0.295-0.373-0.619-0.6-0.672-0.877
                c-1.788-9.454-3.987-10.669-13.869-8.807c-25.402,4.789-47.506,16.037-66.219,33.773c-17.29,16.389-34.15,33.233-51.517,49.539
                c-8.228,7.725-17.609,14.232-25.699,22.084c-6.624,6.43-13.329,13.333-17.864,21.248C40.098,345.998,21.1,363.963,2.709,383.525
                c-0.259-1.589-0.559-2.581-0.562-3.573c-0.035-10-0.181-20.004,0.103-29.996c0.062-2.203,1.151-4.885,2.68-6.463
                c23.649-24.4,47.65-48.463,71.113-73.04c8.949-9.375,16.752-19.844,25.047-29.841c0.456-0.549,0.624-1.337,0.122-2.843
                c-2.69,2.118-5.381,4.237-8.957,7.053c5.046-12.171,12.259-22.533,11.408-36.149c-1.932,0.559-3.165,0.788-4.296,1.263
                c-9.204,3.86-18.255,8.264-28.783,6.052c-1.291-0.271-3.066,0.374-4.241,1.158c-10.193,6.801-21.365,6.606-32.665,4.406
                c-3.853-0.75-5.634,0.806-7.867,3.436c-7.169,8.445-14.659,16.619-22.024,24.898c-0.456-0.264-0.912-0.527-1.367-0.791
                c-0.098-1.067-0.28-2.136-0.281-3.203c-0.018-15.999-0.124-32.001,0.123-47.996c0.032-2.082,1.521-4.49,3.02-6.116
                c2.001-2.172,4.78-3.603,7.034-5.569c5.409-4.719,10.801-7.001,18.527-4.685c6.337,1.9,13.143,0.286,18.577-4.693
                c6.5-5.955,13.248-11.639,20.513-17.985c3.986,4.665,8.255,9.059,11.799,13.974c3.751,5.204,8.472,7.082,14.645,6.585
                c2.031-0.164,5.227-0.225,6.023,0.942c5.398,7.907,15.241,9.057,21.865,14.983c4.011,3.589,8.847,3.801,12.859,0.642
                c6.361-5.008,13.013-10.049,18.019-16.296c12.489-15.589,24.117-31.866,36.098-47.862c1.463-1.953,2.968-3.875,4.932-6.437
                c0.887,2.438,1.361,4.148,2.129,5.716c0.548,1.119,1.62,2.992,2.238,2.908c5.495-0.749,6.954,3.255,8.537,8.462
                c2.931-3.089,5.169-5.177,7.095-7.521c10.983-13.376,21.983-26.739,32.786-40.26c5.089-6.368,9.643-13.163,14.691-19.564
                c1.12-1.421,3.207-2.556,5.016-2.875c7.795-1.377,15.651-2.408,23.488-3.549c2.244-0.327,4.495-0.601,6.743-0.898
                c0.279,0.369,0.559,0.737,0.839,1.105c-6.467,8.381-12.877,16.807-19.42,25.127c-5.148,6.546-10.095,13.311-15.804,19.341
                c-5.889,6.222-7.675,13.88-9.657,21.655c-0.775,3.041-1.587,6.135-2.894,8.969c-5.322,11.545-10.846,22.996-16.273,34.492
                c-0.246,0.521-0.269,1.146-0.542,2.388c27.197-9.367,43.054-30.631,59.542-51.172c-0.297-0.501-0.595-1.002-0.893-1.503
                c-4.373,1.979-8.746,3.96-13.118,5.939c-0.229-0.21-0.459-0.42-0.689-0.63c7.617-9.549,14.956-19.34,22.953-28.56
                c4.971-5.731,11.042-10.493,16.431-15.881c5.533-5.532,10.684-11.457,16.335-16.86c1.674-1.601,4.398-2.444,6.78-2.887
                c4.653-0.862,9.699-0.364,14.063-1.901c16.078-5.663,31.83-6.695,47.486,1.148c0.587,0.295,1.26,0.618,1.886,0.604
                c8.909-0.195,16.792,1.771,22.386,9.563c0.453,0.632,3.567,0.088,4.899-0.764c9.597-6.133,15.875-15.486,23.02-24.024
                c2.591-3.097,5.898-5.594,8.88-8.363c0.441,0.34,0.884,0.68,1.326,1.02c-1.229,2.335-2.229,4.83-3.728,6.977
                c-5.229,7.489-10.47,14.983-16.031,22.226c-3.213,4.183-7.428,6.899-13.097,7.359c-1.767,0.144-3.604,2.431-4.962,4.102
                c-8.896,10.952-17.31,22.324-26.639,32.89c-4.624,5.237-10.924,8.702-18.742,7.453c-1.253-0.2-3.24,0.97-4.11,2.104
                c-9.705,12.657-19.438,25.303-28.722,38.266c-2.232,3.117-2.845,7.394-4.201,11.137c0.462,0.264,0.925,0.527,1.388,0.79
                c5.389-2.08,10.713-4.355,16.187-6.182c4.873-1.626,9.849-3.182,14.903-3.94c2.947-0.443,6.999-0.293,9.038,1.408
                c4.057,3.383,6.226,1.441,9.192-1.09c8.614-7.351,17.262-14.676,26.165-21.669c5.508-4.326,11.692-7.519,19.611-7.48
                c-0.409,7.071-0.796,13.76-1.262,21.823c1.705-1.566,2.598-2.019,2.911-2.728c7.057-15.969,14.008-31.985,21.077-47.948
                c2.575-5.817,1.281-10.336-3.291-14.737c-7.241-6.97-7.141-7.218-2.522-16.202c4.403-8.568,9.026-17.024,13.74-25.881
                c7.391,7.17,12.548,14.84,13.445,25.305c2.001,23.335,7.934,45.805,15.532,67.938c6.412,18.679,11.872,37.685,18.002,56.464
                c1.033,3.166,0.489,4.938-2.174,6.569c-5.337,3.269-10.658,6.568-15.884,10.011c-3.312,2.184-6.756,4.317-9.601,7.038
                c-16.111,15.412-28.965,33.468-41.312,51.886c-5.501,8.205-20.926,12.9-29.475,8.075c-2.115-1.193-3.438-4.296-4.478-6.79
                c-2.661-6.389-5.451-12.795-7.254-19.448c-1.638-6.04-2.047-12.414-2.983-18.643c-0.248-1.645-0.312-3.327-0.69-4.938
                c-0.878-3.741-3.035-5.726-6.818-3.604c-6.066,3.403-12.391,6.562-17.847,10.8c-13.276,10.312-26.141,21.155-39.119,31.847
                c-4.498,3.706-8.938,7.493-13.232,11.432c-4.296,3.94-8.099,7.539-8.717,14.548c-0.968,10.963-10.024,17.913-20.941,19.279
                c-4.236,0.53-8.439,1.922-12.46,3.442c-6.533,2.469-12.846,5.518-19.359,8.043c-6.867,2.662-11.3,6.81-14.151,14.138
                c-2.889,7.427-8.256,13.933-12.815,20.654c-3.925,5.789-7.956,11.187-8.458,18.783c-0.212,3.204-3.404,6.882-6.22,9.105
                c-6.646,5.25-14.075,9.5-20.835,14.618c-13.788,10.439-27.356,21.168-40.989,31.812c-6.035,4.712-11.881,9.673-18.028,14.229
                c-14.179,10.51-28.521,20.799-42.704,31.304c-1.561,1.156-2.516,3.131-3.876,4.888c12.236,5.341,14.306,5.707,23.757-2.155
                c6.998-5.822,13.177-12.738,19.142-19.665c8.594-9.98,19.883-14.739,32.093-18.048c0.746-0.202,1.594-0.026,2.375-0.026
                c0.63,3.768,1.214,7.263,1.945,11.64c9.109-6.289,15.497-13.712,21.818-21.464c7.322-8.979,15.71-17.092,23.681-25.537
                c0.76-0.806,1.842-1.308,1.925-1.365c3.923,6.936,7.779,13.75,12.11,21.406c-5.5,7.026-11.804,15.077-18.106,23.128
                c0.187,0.287,0.374,0.574,0.561,0.861c2.969-1.088,5.875-2.47,8.937-3.123c1.277-0.272,2.914,0.943,4.324,1.614
                c0.357,0.17,0.361,0.987,0.716,1.27c7.281,5.788,4.67,11.612,0.591,17.965c-2.227,3.468-3.235,7.717-4.801,11.634
                c4.287,2.282,6.095,0.286,7.847-2.175c11.168-15.688,26.249-26.773,42.734-36.189c0.995-0.568,2.039-1.049,2.898-1.487
                c1.214,3.829,2.534,7.389,3.417,11.054c0.379,1.575,0.316,3.582-0.343,5.028c-3.409,7.475-0.913,12.321,7.391,13.683
                c2.098,0.345,4.249,0.365,6.983,0.583c-2.239,3.952-5.082,7.258-6.08,11.05c-3.066,11.643-10.279,21.118-15.47,31.637
                c-1.832,3.713-3.713,7.528-4.644,11.514c-0.659,2.825,0.24,6.014,0.443,9.04c0.578,0.001,1.156,0.001,1.734,0.002
                c0.391-0.672,0.782-1.345,1.173-2.018C278.882,539.186,280.918,536.972,285.678,536.706z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(5)} fill={props.fillColors[5]}  d="M641.146,820.061c-5.935,6.974-12.318,13.624-17.65,21.03
                c-3.696,5.136-5.901,11.337-8.863,17.015c-1.282,2.46-2.699,4.862-4.217,7.185c-5.848,8.944-8.292,19.047-11.373,29.144
                c-2.681,8.786-9.016,16.458-13.745,24.616c-0.164,0.283-0.498,0.476-0.635,0.767c-5.059,10.741-14.723,9.831-24.183,9.723
                c-4.593-0.053-9.192,0.387-13.784,0.666c-1.148,0.07-2.276,0.411-3.421,0.579c-8.001,1.173-16.656,0.795-23.855,3.851
                c-11.246,4.773-24.011,9.063-30.03,20.809c-4.396,8.575-10.855,16.866-9.416,27.769c0.658,4.986,0.112,10.132,0.112,15.811
                c-7.673-5.423-10.767-12.754-10.825-20.93c-0.056-7.918,1.477-15.838,2.067-23.775c0.144-1.938-0.67-3.947-1.043-5.924
                c-2.105,0.5-5.255,0.304-6.133,1.621c-3.443,5.166-7.164,10.505-8.962,16.34c-4.03,13.077-1.462,26.003,3.217,38.211
                c-22.495-2.35-44.979-5.252-67.553-6.901c-22.552-1.648-45.161-1.181-67.696,2.74c17.797,8.981,37.655,8.696,56.66,13.621
                c-6.67,1.405-13.34,2.811-20.01,4.216c-0.173,0.483-0.345,0.966-0.518,1.448c5.471,2.651,10.94,5.303,16.716,8.101
                c-5.52,4.18-11.88,5.556-18.295,6.078c-12.11,0.986-24.289,1.153-36.39,2.222c-10.255,0.906-20.442,2.581-30.65,3.983
                c-1.1,0.15-2.137,0.753-3.9,1.401c6.855,4.453,14.117,5.672,21.246,6.405c14.051,1.445,28.163,2.45,42.275,3.104
                c20.285,0.938,40.593,1.397,60.89,2.072c0.778,0.026,1.553,0.174,2.329,0.266c0.069,0.552,0.139,1.103,0.207,1.654
                c-6.882,0.985-13.733,2.389-20.65,2.878c-22.248,1.575-44.522,2.787-66.781,4.217c-2.254,0.145-4.474,0.814-6.71,1.24
                c-0.092,0.463-0.184,0.926-0.276,1.389c2.761,1.084,5.439,2.723,8.299,3.149c8.709,1.301,17.465,2.502,26.246,3.073
                c18.938,1.232,37.914,1.869,56.853,3.093c13.444,0.868,26.846,2.384,40.281,3.425c4.212,0.326,8.472,0.051,12.875-0.927
                c-7.834-8.374-18.807-9.037-29.184-13.07c5.426-1.757,10.161-3.29,15.64-5.063c-7.698-10.001-19.299-10.101-29.118-14.974
                c2.076-1.144,3.806-2.097,6.367-3.507c-12.064-6.744-24.915-7.539-38.438-10.631c22.413-9.43,45.225-7.377,67.612-9.32
                c-8.978-6.693-19.62-8.441-29.804-11.887c7.03,0.792,14.061,1.58,21.089,2.381c0.826,0.094,1.696,0.511,2.458,0.354
                c10.667-2.207,16.557,4.975,22.886,11.581c-1.722,0.955-3.556,1.696-5.065,2.856c-7.944,6.109-9.187,12.653-4.191,21.361
                c4.156,7.244,9.881,12.596,18.263,14.364c2.885,0.609,3.965,1.997,3.755,4.78c-0.137,1.825,0.03,3.685-0.222,5.489
                c-2.027,14.528,1.32,28.462,4.482,42.444c1.708,7.552,0.138,9.379-7.388,11.735c-3.146,0.985-6.351,2.92-8.621,5.292
                c-3.288,3.434-6.252,5.06-10.892,2.714c-4.4-2.224-8.892-1.967-13.407,0.396c-5.817,3.045-11.479,6.924-18.761,3.966
                c-2.123-0.863-11.987,6.507-13.013,9.207c-1.238,3.262-2.073,6.698-3.526,9.854c-3.213,6.977-9.698,8.258-15.458,3.253
                c-0.88-0.764-1.66-1.644-2.551-2.394c-3.963-3.333-6.587-3.318-8.806,1.27c-3.489,7.218-11.696,8.254-16.541,13.637
                c-1.034,1.148-4.128,0.931-6.157,0.607c-6.409-1.018-16.503,3.386-19.753,8.938c-4.087,6.979-11.119,10.113-18.996,8.451
                c-1.627-0.345-3.284-0.623-4.855-1.149c-3.923-1.312-6.593,0.007-7.836,3.827c-2.439,7.494-8.265,9.631-15.241,9.546
                c-12.612-0.153-25.225-0.938-37.827-0.745c-5.196,0.08-10.341,2.142-15.552,3.051c-4.217,0.734-8.1,1.021-10.95,5.406
                c-2.453,3.774-11.24,3.193-17.361,0.175c-3.699-1.824-7.488-3.542-11.387-4.864c-3.054-1.035-5.494,0.152-7.956,2.587
                c-2.441,2.414-6.104,3.536-8.947,5.612c-4.232,3.092-8.462,6.269-12.219,9.899c-2.668,2.578-4.722,4.278-9.062,4.366
                c-8.681,0.177-17.02,1.931-24.547,7.749c-5.606,4.334-13.03,6.345-19.724,9.207c-0.831,0.356-2.157-0.37-3.232-0.675
                c-1.912-0.541-3.873-1.793-5.696-1.604c-7.571,0.78-13.406-2.857-19.393-6.607c-5.983-3.747-9.709-2.617-13.887,3.212
                c-4.233,5.904-8.598,11.721-13.078,17.44c-2.543,3.247-8.091,3.9-12.452,1.889c-4.982-2.298-8.068-6.26-10.115-11.206
                c-2.145-5.181-5.352-8.977-11.338-10.156c-2.006-0.396-3.794-2.504-5.422-4.096c-5.335-5.214-10.573-9.694-19.137-8.343
                c-6.79,1.071-7.174,0.071-7.174-6.986c-0.002-44.829,0.065-89.659-0.101-134.487c-0.017-4.612,1.127-6.484,5.754-8.359
                c7.095-2.876,13.63-7.389,19.93-11.87c3.683-2.619,3.265-3.745-0.317-7.335c7.156-3.003,15.113-4.309,21.307-11.641
                c-10.038-1.957-19.183-3.739-28.329-5.522c11.785-3.812,25.013-2.278,36.239-10.983c-10.97-6.267-23.688-5.14-34.542-11.029
                c2.395-1.527,4.38-2.794,7.264-4.634c-3.709-0.964-6.342-1.647-9.421-2.447c4.211-1.354,7.887-2.536,11.562-3.718
                c0.007-0.359,0.014-0.72,0.021-1.079c-2.376-0.741-4.753-1.483-7.738-2.415c7.066-1.686,13.45-3.208,19.833-4.73
                c-0.068-0.494-0.136-0.988-0.205-1.483c-6.624-0.632-13.248-1.264-19.871-1.896c15.986-3.931,32.49-4.581,47.989-9.98
                c-0.031-0.676-0.063-1.352-0.095-2.027c-22.894-0.647-45.787-1.295-68.989-1.95c0-3.368,0-7.479,0-11.879
                c0.846-0.2,1.763-0.587,2.689-0.61c13.653-0.338,27.309-0.604,40.962-0.939c8.854-0.218,17.309-1.887,25.833-4.711
                c7.276-2.411,15.437-2.023,23.115-3.385c8.646-1.532,17.216-3.528,25.759-5.571c2.149-0.515,4.071-1.982,6.049-3.725
                c-15.384-4.392-31.356-3.322-47.405-5.808c15.186-3.418,85.861,4.806,99.099,11.884c-3.996,0.858-6.897,1.786-9.859,2.066
                c-11.752,1.108-23.561,1.712-35.267,3.163c-4.044,0.501-7.849,2.922-11.764,4.466c0.014,0.792,0.028,1.584,0.042,2.377
                c10.236,2.716,20.472,5.432,30.708,8.148c-19.583,1.604-39.487-0.876-58.465,5.396c-0.031,0.751-0.062,1.502-0.093,2.254
                c30.923,4.644,61.847,9.288,92.77,13.932c-0.043,0.807-0.086,1.614-0.129,2.421c-14.657,1.452-29.322,2.815-43.967,4.382
                c-14.72,1.575-29.455,3.089-44.117,5.114c-14.686,2.029-29.801,2.524-44.759,10.526c2.685,1.027,4.028,1.799,5.461,2.052
                c26.673,4.7,53.358,9.332,80.038,13.993c9.323,1.629,18.667,3.157,27.94,5.028c2.806,0.566,5.41,2.133,9.27,3.721
                c-10.354,3.399-20.605,3.363-29.302,11.192c8.74,2.63,16.495,4.964,25.076,7.547c-4.894,2.135-8.904,3.886-12.915,5.636
                c0.021,0.449,0.041,0.898,0.061,1.348c3.697,0.956,7.395,1.912,11.728,3.033c-8.069,1.68-15.61,3.248-23.15,4.817
                c0.047,0.554,0.094,1.106,0.141,1.66c2.526,0.235,5.049,0.626,7.58,0.681c25.307,0.55,50.614,1.238,75.924,1.436
                c7.585,0.059,15.204-1.127,22.759-2.111c2.265-0.295,4.367-1.843,7.556-3.269c-24.041-9.914-48.969-7.477-72.448-13.332
                c20.015-3.056,40.574,1.304,60.292-4.208c0.031-0.592,0.062-1.184,0.092-1.775c-9.989-1.812-19.979-3.623-29.968-5.434
                c16.482-0.44,32.949,1.095,49.11-3.353c-23.287-9.269-48.34-7.094-72.362-11.29c0.001-0.523,0.001-1.047,0.002-1.57
                c13.411-0.919,26.817-1.93,40.236-2.729c13.214-0.787,26.696,1.876,39.641-2.302c0.045-0.496,0.09-0.991,0.135-1.486
                c-5.007-1.423-10.034-2.784-15.017-4.287c-4.882-1.474-9.72-3.092-14.512-5.802c3.534-1.023,7.266-1.623,10.552-3.171
                c4.434-2.089,8.812-4.536,12.672-7.527c3.062-2.371,2.667-5.406-0.804-7.759c-4.545-3.081-9.077-6.198-13.425-9.547
                c-1.735-1.337-2.964-3.331-4.846-5.517c4.561-2.407,8.561-4.086,12.077-6.476c5.191-3.526,10.292-7.279,14.945-11.475
                c3.07-2.767,3.628-6.3,0.707-10.219c-1.1-1.475-0.25-4.35-0.437-6.574c-0.241-2.876-0.631-5.74-1.043-9.373
                c1.973-1.699,4.928-4.244,8.271-7.123c-2.988-2.812-5.429-5.107-8.084-7.605c22.403-3.994,44.128-7.813,65.824-11.791
                c3.57-0.655,7.196-1.618,10.46-3.154c2.774-1.305,6.515-3.182,7.285-5.612c2.003-6.32,7.468-6.626,11.991-8.92
                c11.348-5.755,22.558-11.78,33.823-17.696c0.03-0.676,0.061-1.352,0.091-2.027c-0.753-0.365-1.535-0.681-2.254-1.103
                c-13.642-7.989-27.158-16.2-40.992-23.841c-3.449-1.904-7.885-2.566-11.918-2.762c-8.312-0.401-16.662,0.054-24.983-0.223
                c-1.69-0.056-3.358-1.728-4.976-2.752c-2.136-1.353-4.084-3.071-6.349-4.131c-2.223-1.041-4.74-1.453-7.792-2.333
                c1.697-0.923,2.938-1.597,5.076-2.759c-9.683-4.63-18.673-8.928-27.664-13.226c0.078-0.214,0.156-0.428,0.234-0.642
                c3.033,0.413,6.066,0.826,9.099,1.239c0.292-0.507,0.583-1.014,0.874-1.521c-3.694-2.646-7.388-5.293-11.082-7.94
                c0.567-0.675,1.134-1.349,1.701-2.023c3.257,1.077,6.513,2.154,11.201,3.704c-1.524-2.567-2.256-3.801-3.805-6.411
                c11.816,0,22.758,0.102,33.696-0.036c6.977-0.088,14.002-1.197,20.912-0.658c10.729,0.838,21.341,3.262,32.071,3.991
                c20.74,1.409,41.507,2.589,62.295-0.017c11.049-1.384,21.955-1.251,33.153,1.213c10.929,2.405,22.48,2.758,33.749,2.674
                c20.771-0.153,41.533-1.444,62.308-1.882c4.348-0.092,8.736,1.424,13.095,2.262c2.078,0.399,4.125,1.338,6.192,1.359
                c16.788,0.179,33.577,0.197,50.366,0.265L641.146,820.061z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(6)} fill={props.fillColors[6]}  d="M285.678,536.706c-2.21,2.404-4.42,4.809-6.631,7.213
                c-3.599-1.92-2.233-5.059-1.142-7.324c4.319-8.965,9.068-17.721,13.582-26.593c1.185-2.329,2.069-4.809,3.14-7.197
                c2.362-5.272,4.676-10.568,7.178-15.774c0.908-1.889,2.295-3.546,4.001-6.119c-2.599-0.305-4.321-0.471-6.033-0.715
                c-2.446-0.349-5.068-0.307-7.289-1.221c-5.868-2.416-6.354-3.747-3.322-9.258c1.612-2.932,1.79-5.408,0.159-8.326
                c-0.953-1.704-1.503-3.727-1.811-5.673c-0.301-1.907-0.065-3.898-0.065-6.844c-11.352,4.728-20.882,10.988-29.401,18.53
                c-8.376,7.416-15.989,15.692-24.892,23.292c2.085-4.464,3.636-9.295,6.389-13.299c3.593-5.229,3.77-9.477-1.12-13.685
                c-0.371-0.319-0.768-0.736-0.902-1.184c-1.865-6.212-5.843-6.811-12.042-3.34c5.993-7.797,11.198-14.568,16.662-21.678
                c-6.75-6.749-12.202-13.985-12.116-24.809c-2.417,1.449-4.168,2.264-5.648,3.424c-9.754,7.646-18.209,16.454-25.459,26.604
                c-5.019,7.026-11.28,13.164-16.987,19.699c-0.499-0.325-0.998-0.65-1.497-0.977c-0.107-1.762-0.402-3.537-0.283-5.283
                c0.298-4.343-1.902-5.271-5.567-4.292c-12.902,3.449-24.692,8.633-33.643,19.243c-5.676,6.728-12.028,12.917-18.378,19.034
                c-5.785,5.571-12.442,7.424-20.162,2.606c0.497-0.552,0.907-1.208,1.489-1.625c11.096-7.948,22.461-15.545,33.265-23.87
                c16.202-12.485,31.772-25.797,48.073-38.147c11.398-8.636,23.552-16.277,35.4-24.315c8.146-5.527,15.662-11.159,15.796-22.629
                c0.037-3.168,3.125-6.497,5.28-9.407c6.539-8.828,13.308-17.396,16.797-28.154c0.852-2.627,4.432-5.041,7.306-6.226
                c12.698-5.234,24.764-12.034,38.957-13.468c9.466-0.957,18.546-13.41,19.469-23.617c0.21-2.318,1.436-5.166,3.161-6.626
                c12.705-10.748,25.704-21.146,38.509-31.776c6.661-5.531,12.858-11.651,19.75-16.866c5.055-3.826,10.896-6.611,17.782-10.686
                c0.959,7.314,1.355,12.883,2.5,18.293c1.01,4.776,2.839,9.382,4.336,14.054c1.412,4.403,3.145,8.725,4.238,13.204
                c2.366,9.695,6.512,12.598,18.58,11.91c2.896-0.165,5.778-0.571,8.617-0.86c0.124,0.783,0.233,1.013,0.164,1.101
                c-13.579,17.183-19.368,39.323-35.08,55.169c-1.086,1.095-1.841,2.799-2.148,4.339c-2.779,13.95-10.296,25.105-20.402,34.691
                c-4.961,4.705-9.07,9.712-12.323,15.971c-3.366,6.479-9.022,11.821-13.945,17.425c-10.217,11.627-20.625,23.086-30.914,34.65
                c-1.061,1.192-1.846,2.63-2.098,4.966c1.758-0.58,3.726-0.831,5.24-1.793c11.34-7.202,22.799-14.246,33.795-21.947
                c7.337-5.139,13.789-11.523,20.973-16.903c8.946-6.699,15.17-15.557,21.613-24.299c-0.326-0.576-0.653-1.153-0.979-1.729
                c-5.949,2.263-11.898,4.524-17.848,6.787c-0.29-0.23-0.58-0.462-0.871-0.692c4.822-5.987,9.608-12.003,14.488-17.942
                c1.263-1.537,2.942-2.729,4.213-4.26c4.593-5.537,9.749-10.132,16.288-13.555c9.695-5.074,14.46-14.973,20.356-23.565
                c5.358-7.809,10.205-15.984,14.985-24.168c2.322-3.976,3.86-8.399,5.968-12.511c1.807-3.521,3.658-7.073,5.948-10.284
                c5.687-7.971,11.847-15.605,17.419-23.651c5.96-8.605,11.67-17.021,20.66-23.264c5.402-3.751,9.139-10.359,12.647-16.263
                c3.975-6.686,6.835-14.03,10.233-21.063c0.695-1.438,1.627-2.762,2.603-4.393c10.675,9.961,20.956,19.553,32.295,30.133
                c-6.238,4.089-11.647,8.138-17.527,11.315c-4.032,2.18-7.168,4.74-8.822,8.975c-4.233,10.832-9.03,21.492-12.503,32.564
                c-7.679,24.485-20.085,45.575-40.903,61.173c-1.596,1.194-2.995,2.646-4.521,3.938c-13.189,11.18-24.322,23.744-31.659,39.831
                c-8.349,18.307-23.53,30.446-40.037,41.468c-29.625,19.78-59.504,39.359-86.08,63.241c-12.351,11.099-23.464,23.577-35.072,35.496
                C286.87,533.915,286.392,535.426,285.678,536.706z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(7)} fill={props.fillColors[7]}  d="M68.636,109.737c5.532,1.367,11.065,2.735,16.597,4.104
                c-27.584,2.914-55.066,3.491-82.829,0.748c0-19.531,0-38.785,0-59.181c5.64,1.737,10.844,3.151,15.902,4.969
                c3,1.078,5.403,1.312,8.348-0.571c5.846-3.737,11.985-3.556,17.843,0.338c2.078,1.381,4.226,2.729,6.038,4.42
                c3.344,3.119,6.227,3.973,10.936,1.693c3.813-1.846,8.875-1.111,12.755-1.465c-3.212-3.14-8.271-3.797-14.537-0.509
                c-2.88,1.511-4.716,1.276-6.998-0.806c-2.201-2.009-4.701-3.727-7.212-5.353c-6.289-4.072-12.681-4.064-19.432-0.79
                c-2.079,1.009-5.001,1.113-7.319,0.615c-4.693-1.007-9.293-2.559-13.805-4.228c-1.168-0.433-2.653-2.123-2.666-3.25
                C2.083,34.652,2.139,18.829,2.139,2.403C4.125,2.303,5.878,2.137,7.63,2.137c159.489-0.012,318.977,0.022,478.465-0.152
                c5.534-0.006,7.823,2.843,8.631,6.945c1.768,8.982,8.129,11.02,15.683,11.244c8.298,0.247,16.626-0.341,24.911,0.053
                c4.525,0.214,9.288,1.004,13.427,2.755c12.305,5.205,24.751,7.19,38.033,4.795c3.659-0.66,7.873,0.247,11.528,1.398
                c6.135,1.933,12.026,4.638,18.021,7.018c-0.056,0.415-0.111,0.83-0.167,1.245c-6.361-0.953-12.703-2.058-19.087-2.824
                c-8.747-1.05-17.523-1.859-26.292-2.733c-1.986-0.197-4.123,0.18-5.962-0.414c-12.249-3.953-24.329-2.156-36.453,0.593
                c-2.897,0.657-5.952,1.098-8.901,0.958c-11.631-0.55-23.304-0.909-34.851-2.271c-6.516-0.77-12.761-3.617-19.238-5.012
                c-3.153-0.68-6.562-0.141-9.854-0.215c-3.764-0.085-7.525-0.344-11.287-0.344c-10.094,0.001-20.188,0.187-30.28,0.12
                c-4.595-0.029-9.191-0.424-13.777-0.774c-1.306-0.101-2.571-0.886-3.867-0.922c-22.285-0.616-45.368-6.549-66.097,10.78
                c4.703,1.341,8.896,3.354,13.187,3.591c8.928,0.494,17.403,2.213,25.657,5.646c7.874,3.274,16.03,3.71,24.471,1.978
                c13.178-2.706,20.303,0.707,25.853,12.833c2.676,5.848,8.316,5.911,13.804,8.882c-12.616-0.279-24.09,1.915-35.446-3.421
                c-12.026-5.651-24.752-4.18-37.041,0.075c-7.163,2.479-14.239,3.756-21.922,2.993c-5.896-0.586-11.956,0.732-17.922,0.541
                c-5.433-0.175-10.834-1.741-16.255-1.776c-5.389-0.033-10.939,0.482-16.144,1.822c-8.222,2.117-16.276,2.283-24.691,1.415
                c-22.334-2.303-44.492-1.151-66.491,3.987c-11.152,2.604-21.891,1.653-31.547-6.39c-3.292-2.741-9.102-3.29-13.787-3.373
                c-13.436-0.238-26.887,0.207-40.328,0.532c-4.296,0.104-8.601,0.526-12.863,1.097c-1.875,0.25-3.651,1.233-5.762,1.987
                c0.65,0.601,0.912,0.973,1.028,0.932c9.245-3.254,17.028-1.429,24.1,5.646c3.749,3.75,8.87,4.775,14.399,2.87
                c7.043-2.428,13.547-1.675,19.532,3.563c5.546,4.854,12.309,7.384,19.429,9.692c5.886,1.909,10.798,6.821,17.454,11.283
                c-7.367,0-13.242-0.39-19.04,0.11c-5.708,0.492-11.606,1.146-16.931,3.079c-6.203,2.252-11.968,2.88-18.443,1.439
                c-19.82-4.408-39.696-3.216-59.485,0.529c-2.211,0.418-4.295,1.505-6.438,2.282C68.606,108.729,68.621,109.232,68.636,109.737z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(8)} fill={props.fillColors[8]}  d="M182.058,955.986c-12.7-9.672-94.598-18.511-111.294-12.299
                c16.878,4.637,34.59,3.148,51.674,6.274c0.091,0.509,0.181,1.017,0.271,1.525c-6.974,1.577-13.878,3.656-20.936,4.625
                c-14.782,2.03-29.354,5.346-44.014,7.617c-17.961,2.783-36.467,2.048-55.206,2.854c0-18.228,0-35.444,0-53.742
                c6.714,0,13.482,0.178,20.239-0.037c14.299-0.455,28.624-0.716,42.874-1.859c7.136-0.572,13.702-3.4,18.802-9.269
                c1.613-1.856,5.409-2.574,8.184-2.526c9.979,0.173,19.952,1.37,29.922,1.309c19.804-0.12,39.606-0.78,59.405-1.334
                c2.294-0.064,4.815-0.382,6.811-1.403c12.587-6.446,26.244-5.471,39.578-5.387c22.142,0.14,44.274,1.519,66.419,1.86
                c12.468,0.192,24.978-0.279,37.418-1.156c7.837-0.554,15.397-2.562,20.467-9.769c0.967-1.374,3.835-1.866,5.864-1.966
                c13.629-0.667,27.268-1.118,41.346-0.457c-2.796,1.813-5.438,3.948-8.418,5.381c-8.519,4.094-17.199,7.849-25.752,11.872
                c-1.517,0.714-3.585,1.854-3.93,3.181c-1.463,5.621-5.526,8.438-10.489,9.609c-11.144,2.629-22.391,4.854-33.651,6.94
                c-14.035,2.602-28.126,4.898-43.468,7.546c3.223,3.481,5.821,6.287,8.529,9.212c-2.604,4.258-13.448,5.65-4.601,13.89
                c-4.088,3.749-3.375,7.359-0.329,11.057c2.38,2.888,0.878,5.738-1.326,7.477c-5.577,4.398-11.508,8.349-17.334,12.426
                c-0.943,0.659-2.081,1.038-3.123,1.559c-7.667,3.827-8.04,5.84-1.464,11.373c3.934,3.311,8.329,6.074,12.537,9.057
                c4.001,2.836,4.464,4.798,0.315,7.415c-4.688,2.958-10.067,4.895-15.287,6.908c-3.023,1.167-6.326,1.613-10.768,2.691
                c9.263,6.998,19.6,7.805,28.875,11.874c-26.651,1.005-53.47-0.4-79.687,5.581c-0.078,0.614-0.156,1.229-0.234,1.845
                c6.584,1.115,13.136,2.49,19.758,3.295c15.182,1.845,30.403,3.364,45.595,5.139c2.575,0.301,5.078,1.218,7.614,1.851
                c-0.03,0.409-0.061,0.818-0.09,1.228c-17.155,0-34.31,0-51.464,0c-0.068,0.562-0.136,1.124-0.204,1.686
                c10.124,4.744,21.503,4.689,32.172,7.35c0,0.468-0.001,0.936-0.001,1.402c-19.721,0.536-39.441,1.071-59.163,1.607
                c-0.102,0.727-0.204,1.454-0.306,2.182c3.475,1.203,6.867,2.989,10.44,3.495c9.379,1.328,18.875,1.825,28.252,3.163
                c11.236,1.604,22.396,3.736,33.588,5.64c-0.004,0.835-0.007,1.67-0.011,2.505c-6.935,0.842-13.886,2.511-20.802,2.379
                c-26.626-0.509-53.242-1.594-80.591-2.487c7.66-1.194,14.714-2.293,21.769-3.393c0.038-0.429,0.075-0.856,0.113-1.285
                c-4.504-1.219-9.007-2.438-14.601-3.95c4.865-1.881,8.836-3.417,12.807-4.952c0.075-0.554,0.149-1.108,0.224-1.662
                c-8.008-2.498-16.017-4.996-24.025-7.494c-0.081-0.53-0.162-1.06-0.243-1.589c9.049-2.592,18.098-5.184,27.146-7.776
                c0.13-0.588,0.261-1.176,0.391-1.764c-4.11-1.805-8.063-4.468-12.36-5.257c-14.866-2.73-29.888-4.594-44.781-7.186
                c-20.627-3.589-41.192-7.537-61.779-11.355c-0.273-0.051-0.488-0.414-1.719-1.522c7.012-1.724,13.229-3.941,19.619-4.707
                c33.027-3.955,66.101-7.521,99.153-11.272c4.861-0.552,9.692-1.366,14.538-2.059c0.11-0.596,0.22-1.191,0.331-1.787
                c-3.885-1.409-7.665-3.45-11.674-4.119c-17.54-2.926-35.145-5.469-52.73-8.119c-9.353-1.41-18.716-2.754-28.087-4.729
                c19.556-5.422,39.877-1.448,59.609-4.361c0.123-0.613,0.246-1.226,0.369-1.838c-11.414-3.025-22.828-6.051-34.242-9.076
                c-0.051-0.659-0.102-1.318-0.153-1.979c6.555-1.294,13.06-3.038,19.676-3.772c8.756-0.972,17.624-0.915,26.394-1.793
                C174.303,959.025,177.668,957.356,182.058,955.986z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(9)} fill={props.fillColors[9]}  d="M682.129,158.494c12.266-2.542,24.446-5.695,36.827-7.401
                c7.583-1.046,15.616-0.729,23.185,0.541c7.898,1.324,15.372,1.804,23.141-0.412c2.802-0.798,6.284-0.647,9.076,0.251
                c6.461,2.08,12.504,2.319,18.959-0.259c2.845-1.136,6.5-0.804,9.687-0.383c4.063,0.537,7.599,0.248,10.583-2.821
                c18.545-19.075,41.565-21.249,66.131-17.403c3.936,0.615,7.796,1.7,11.708,2.478c3.472,0.689,6.93,1.331,9.191-2.621
                c0.4-0.699,1.711-1.298,2.562-1.25c5.959,0.337,10.195-2.226,14.422-6.393c2.38-2.346,6.886-3.301,10.498-3.464
                c6.624-0.299,13.292,0.599,19.947,0.725c6.29,0.119,10.9-1.501,14.103-8.301c8.054-17.104,27.562-23.475,46.071-15.638
                c7.484,3.168,14.514,7.413,21.733,11.199c3.392,1.778,6.216,2.969,9.57-0.902c1.962-2.264,6.015-2.581,8.811-4.292
                c3.266-1.999,6.085-4.714,9.292-6.823c2.038-1.341,4.353-2.715,6.682-3.024c2.136-0.284,4.457,0.842,7.094,1.436
                c0.842-2.478,1.277-4.994,2.483-7.062c2.732-4.682,5.186-9.806,8.952-13.54c6.082-6.028,13.088-5.315,18.764,1.15
                c2.9,3.305,5.191,7.146,8.269,11.463c0.904-2.939,1.544-5.105,2.237-7.254c3.035-9.422,7.837-17.645,16.275-23.252
                c9.741-6.474,23.964-5.374,32.413,2.556c1.065,0.999,1.976,2.772,1.986,4.195c0.135,17.322,0.087,34.646,0.087,52.826
                c-11.168-6.104-22.583-7.902-34.374-4.654c-12.697,3.497-19.034,12.662-20.841,25.497c-1.895-0.59-3.478-1.039-5.03-1.574
                c-3.693-1.274-6.864-0.754-9.313,2.538c-0.493,0.664-1.322,1.871-1.631,1.766c-7.078-2.431-12.948,4.708-20.414,2.046
                c-6.946-2.478-14.484-3.559-21.858-4.438c-3.062-0.365-6.733,0.763-9.483,2.337c-3.295,1.888-6.112,1.88-9.425,0.692
                c-8.282-2.97-16.478-6.326-24.967-8.531c-7.902-2.054-15.478-0.344-21.991,5.154c-1.707,1.44-4.095,2.059-6.135,3.123
                c-4.545,2.37-9.186,4.592-13.533,7.284c-1.48,0.917-2.201,3.062-2.633,5.152c4.939-6.623,12.144-8.901,19.296-11.301
                c1.241-0.416,2.597-0.92,3.521-1.787c7.775-7.301,16.844-7.268,26.162-4.345c6.967,2.187,13.716,5.065,20.555,7.657
                c3.403,1.289,6.21,0.696,9.785-0.7c4.839-1.891,10.568-1.995,15.906-1.939c3.546,0.037,7.068,1.723,10.614,2.616
                c2.896,0.73,6.195,2.6,8.647,1.831c6.57-2.059,14.293-0.123,19.896-5.777c1.11-1.122,3.556-1.506,5.277-1.304
                c2.728,0.321,5.362,1.423,8.53,2.337c0.448-2.078,0.869-3.777,1.178-5.497c2.087-11.621,8.938-18.729,20.443-21.096
                c9.893-2.035,19.587-1.342,28.515,3.829c1.456,0.843,3.146,2.872,3.162,4.37c0.211,19.644,0.14,39.291,0.14,59.655
                c-5.895,0-11.574,0.641-17.049-0.144c-9.359-1.339-18.883-2.692-27.808-5.641c-12.364-4.084-24.617-3.833-36.938-1.489
                c-15.496,2.947-30.838,6.694-46.276,9.957c-12.647,2.672-25.422,3.878-38.097,0.519c-10.43-2.765-20.77-5.935-30.994-9.384
                c-5.018-1.692-9.582-4.313-11.945-9.838c-0.63-1.474-3.774-2.435-5.857-2.628c-10.272-0.952-20.565-1.857-30.871-2.225
                c-11.147-0.397-22.317-0.206-33.477-0.211c-1.716-0.001-3.432,0.322-6.567,0.64c9.149,5.391,16.996,10.014,25.646,15.11
                c-31.687,3.988-61.949,1.649-92.213,0.449c-0.499-0.021-1.052,0.062-1.488-0.12c-11.61-4.848-24.093-4.141-36.208-5.779
                c-2.94-0.397-5.97-1.035-8.66-2.231c-15.722-6.992-32.128-6.593-48.518-4.124c-11.463,1.727-22.39,0.808-33.221-2.775
                C682.259,159.396,682.193,158.945,682.129,158.494z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(10)} fill={props.fillColors[10]}  d="M929.506,1141.013c-0.603,2.087-1.385,3.512-1.301,4.884
                c0.078,1.288,0.794,2.867,1.768,3.645c0.555,0.443,2.395-0.162,3.301-0.8c11.245-7.927,22.426-15.945,34.067-24.258
                c4.48,11.064,7.706,21.423,8.062,32.572c0.158,4.972,0.562,9.99,1.465,14.874c2.959,16.002-0.886,30.408-9.984,43.521
                c-4.431,6.385-8.054,12.769-8.688,20.853c-0.324,4.118-2.602,8.17-4.415,12.056c-1.237,2.65-1.054,4.308,1.971,4.576
                c5.598,0.498,11.224,0.69,17.354,1.039c0.113,8.533-6.06,18.875,3.419,27.851c1.511-2.281,2.756-4.161,4.305-6.501
                c0.93,2.997,1.736,5.599,2.411,7.776c-8.655,6.95-17.539,5.662-25.81,0.568c-10.482-6.457-19.945-5.493-29.669,1.398
                c-3.029,2.146-7.155,2.686-10.385,4.618c-7.011,4.197-14.694,5.427-22.42,7.445c-7.465,1.951-13.604,2.752-20.475-0.125
                c-1.025-0.429-2.327-0.737-3.369-0.506c-15.302,3.399-31.59,3.658-44.105,14.062c0-8.187,0-16.133,0-24.387
                c1.846,0,2.809-0.136,3.721,0.021c7.581,1.312,8.188,0.816,6.103-6.347c-1.109-3.811-2.697-7.512-4.371-11.123
                c-5.056-10.908-6.435-22.48-4.899-34.138c0.827-6.274,3.905-12.254,5.988-18.359c0.321-0.941,1.025-1.825,1.094-2.768
                c0.869-12.067,3.769-24.272-2.677-35.854c-0.719-1.29-0.657-3.605,0.01-4.975c2.842-5.829,5.849-11.605,9.264-17.11
                c0.843-1.358,3.462-2.33,5.237-2.289c25.713,0.585,50.677-4.244,75.507-10.126
                C924.238,1142.575,926.453,1141.868,929.506,1141.013z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(11)} fill={props.fillColors[11]}  d="M900.022,825.507c27.028,2.811,54.622,5.328,82.105,8.743
                c8.623,1.071,16.815,5.684,25.436,6.684c14.84,1.722,29.861,1.865,44.805,2.698c24.426,1.362,48.839,3.095,73.283,3.919
                c7.812,0.263,15.69-1.976,23.563-2.845c4.264-0.471,8.579-0.469,13.398-0.706c0.102,2.249,0.251,4.015,0.251,5.78
                c0.014,41.976-0.056,83.95,0.111,125.925c0.017,4.276-1.341,6.138-5.355,7.424c-3.493,1.118-6.475,3.747-9.867,5.304
                c-1.901,0.872-4.188,1.27-6.284,1.219c-1.339-0.032-2.713-1.175-3.96-1.991c-4.02-2.631-7.097-3.359-10.741,1.435
                c-4.355,5.729-10.034,4.966-13.854-0.786c-3.112-4.688-8.119-4.827-11.366-0.255c-2.294,3.229-4.249,6.731-6.811,9.722
                c-1.255,1.465-3.562,2.013-5.364,3.029c-5.251,2.965-10.487,5.956-15.704,8.922c6.371-21.415-4.194-41.907-4.95-63.188
                c-0.023-0.649-0.234-1.326-0.497-1.93c-10.259-23.508-20.32-47.29-42.486-62.518c-5.265-3.617-11.962-5.811-18.313-7.034
                c-10.569-2.036-20.554-4.984-30.022-10.26c-8.101-4.514-16.769-8.01-25.178-11.972c-13.656-6.435-27.394-12.708-40.909-19.427
                C907.118,831.313,903.532,828.004,900.022,825.507z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(12)} fill={props.fillColors[12]}  d="M92.284,114.295c-5.851-1.91-11.703-3.82-17.554-5.73
                c-0.059-0.269-0.117-0.536-0.176-0.804c7.727-0.864,15.435-2.147,23.185-2.5c13.48-0.613,26.903-0.262,40.229,2.78
                c4.063,0.928,9.132,0.329,12.987-1.327c7.288-3.13,14.515-4.282,22.346-3.711c6.366,0.465,12.793,0.095,20.614,0.095
                c-1.812-1.934-2.43-2.813-3.247-3.429c-4.518-3.403-9.032-6.816-13.662-10.062c-1.602-1.123-3.462-2.315-5.323-2.554
                c-7.818-1.001-14.076-5.09-19.815-9.937c-5.25-4.435-11.286-5.83-17.255-3.644c-9.469,3.468-15.484-1.313-22.711-7.764
                c6.838-0.476,12.577-1.32,18.289-1.168c8.807,0.236,17.651,0.753,26.366,1.971c3.868,0.54,7.726,2.656,11.121,4.799
                c9.701,6.123,20.177,6.978,30.78,4.194c20.942-5.498,42.148-5.047,64.325-4.364c-14.941,24.612-36.33,43.12-51.891,66.57
                c-4.284-6.183-4.284-6.183-9.171-6.012c-1.49-3.408-3.04-6.952-4.933-11.281c-1.509,1.747-2.392,2.654-3.146,3.659
                c-13.99,18.651-27.726,37.502-42.096,55.856c-4.115,5.257-9.85,9.353-15.231,13.472c-3.679,2.815-7.59,2.025-11.5-0.758
                c-3.721-2.648-8.165-4.261-12.034-6.73c-2.317-1.479-4.224-3.649-6.167-5.652c-2.812-2.899-5.708-4.68-10.146-3.687
                c-5.156,1.153-9.301-0.869-12.523-5.271c-3.042-4.156-6.734-7.832-9.884-11.916c-3.005-3.897-5.501-3.493-8.848-0.245
                c-5.132,4.98-10.844,9.36-16.036,14.284c-6.11,5.795-13.345,6.854-20.856,4.638c-5.485-1.618-9.617-0.415-13.755,2.931
                c-3.724,3.01-7.627,5.798-12.044,9.131c0-24.557,0-48.404,0-73.101c29.935,2.897,59.714,2.171,89.434-0.636
                C92.067,115.694,92.175,114.995,92.284,114.295z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(13)} fill={props.fillColors[13]}  d="M1008.021,498.917c1.99-8.691,3.768-16.454,5.546-24.217
                c-0.551-0.198-1.101-0.397-1.65-0.597c-1.655,2.943-3.244,5.926-4.979,8.821c-6.062,10.121-11.914,20.379-18.334,30.269
                c-7.397,11.396-16.447,21.203-28.006,28.784c-5.602,3.674-10.036,9.107-15.082,13.651c-9.504,8.559-19.066,17.051-24.475,21.881
                c3.061-5.838,8.624-17.088,14.757-28.018c13.429-23.932,27.18-47.683,40.725-71.55c4.023-7.088,7.729-14.356,11.7-21.475
                c6.905-12.377,15.254-23.444,28.064-30.402c0.973-0.528,1.828-1.454,2.47-2.381c9.2-13.292,17.815-26.86,23.289-42.266
                c5.78-16.268,15.319-30.17,27.898-42.057c4.063-3.84,8.071-4.979,12.95-2.049c4.088,2.455,8.23,1.693,12.531,0.572
                c6.974-1.818,7.794-0.977,9.377,5.979c1.092,4.798,2.505,9.636,4.619,14.052c1.738,3.63,4.986,5.321,9.031,2.317
                c3.127-2.322,5.973-2.599,7.915,1.588c0.334,0.721,1.062,1.272,1.651,1.859c5.178,5.159,7.379,5.256,10.812-1.201
                c7.095-13.342,13.579-27.009,20.308-40.545c0.935-1.88,1.828-3.78,3.736-5.491c0,1.56,0,3.12,0,4.681
                c0,28.492-0.087,56.985,0.099,85.477c0.026,3.985-1.123,6.619-4.462,8.587c-5.29,3.117-10.356,6.657-15.824,9.411
                c-2.341,1.179-5.449,0.841-8.211,1.174c-1.711,0.206-3.524,0.087-5.115,0.63c-2.821,0.964-5.447,2.491-8.262,3.48
                c-7.213,2.536-9.748,1.817-14.76-3.91c-0.653-0.748-1.09-1.686-1.733-2.703c-2.421,1.666-4.121,2.836-6.188,4.258
                c-0.994-9.069-1.947-17.765-2.9-26.459c-0.705-0.198-1.409-0.397-2.114-0.596C1068.866,436.232,1041.686,469.661,1008.021,498.917
                z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(14)} fill={props.fillColors[14]}  d="M2.404,910.061c0-16.033,0-32.126,0-48.829c4.376,0,8.647-0.232,12.887,0.036
                c33.23,2.109,66.442,4.529,99.684,6.417c22.948,1.303,45.932,2.083,68.91,2.701c5.216,0.141,10.58-0.942,15.684-2.225
                c4.018-1.01,7.111-3.706,6.864-8.649c5.347,0,10.475-0.093,15.599,0.016c23.809,0.505,47.615,1.328,71.425,1.482
                c10.007,0.064,19.935-1.444,28.442-7.745c1.167-0.864,3.114-0.905,4.706-0.926c7.989-0.104,15.995-0.371,23.965,0.031
                c4.207,0.212,8.816,0.802,12.439,2.731c12.836,6.835,25.325,14.32,37.533,22.693c-7.258,0-14.517-0.091-21.772,0.027
                c-5.988,0.098-11.986,0.268-17.955,0.731c-4.961,0.386-9.011,1.262-13.204,5.729c-3.861,4.113-11.62,6.091-17.744,6.274
                c-19.438,0.585-38.913-0.186-58.374-0.286c-23.151-0.12-46.302-0.102-69.453-0.09c-1.124,0-2.376,0.281-3.352,0.822
                c-9.961,5.525-20.921,6.797-31.931,6.715c-21.637-0.162-43.269-0.985-64.904-1.438c-4.145-0.087-8.4-0.215-12.416,0.604
                c-2.831,0.578-5.801,2.247-7.906,4.267c-5.275,5.062-11.792,6.863-18.584,7.63c-7.427,0.838-14.939,1.116-22.421,1.228
                C27.915,910.197,15.298,910.061,2.404,910.061z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(15)} fill={props.fillColors[15]}  d="M576.998,736.265c-0.68,1.455-1.979,2.944-1.897,4.355
                c0.126,2.222,0.552,5.349,2.02,6.31c1.433,0.938,5.29,0.473,6.405-0.822c3.578-4.153,7.959-6.13,13.064-7.399
                c4.559-1.134,11.172-0.946,15.123,0.521c4.042,1.5,8.102,1.864,11.442-1.862c1.104-1.232,2.371-2.354,3.695-3.351
                c4.038-3.038,6.935-2.443,9.117,2.185c1.617,3.431,3.872,5.257,7.665,6.283c3.872,1.047,7.794,2.807,8.611,7.967
                c0.223,1.406,2.613,3.24,4.23,3.501c4.035,0.651,8.221,0.848,12.304,0.582c3.377-0.22,5.627,0.445,7.354,3.578
                c1.334,2.421,3.232,4.637,5.261,6.538c1.653,1.551,3.871,2.5,5.838,3.716c-0.453,0.797-0.905,1.595-1.358,2.392
                c-4.987,1.411-9.991,2.772-14.956,4.262c-1.689,0.507-3.994,0.871-4.797,2.099c-3.372,5.155-6.281,10.613-9.897,16.895
                c-74.501-3.801-149.903-7.647-226.154-11.537c0.308-2.073,0.723-4.871,1.267-8.535c4.008,0.577,8.025,0.373,11.344,1.799
                c6.59,2.83,9.516-2.468,13.176-5.47c1.148-0.941,0.567-4.767-0.127-6.989c-0.935-2.996-0.588-4.896,1.97-6.891
                c3.098-2.417,3.577-5.524,1.658-9.28c-0.693-1.358-0.26-3.999,0.681-5.336c1.922-2.733,1.598-4.308-0.593-6.849
                c-2.294-2.663-4.783-6.32-4.757-9.523c0.031-3.913,4.273-5.385,8.106-6c5.818-0.934,11.608-2.043,17.44-2.874
                c1.16-0.165,2.501,0.464,3.67,0.943c1.685,0.69,3.281,2.243,4.926,2.257c4.466,0.038,7.914,1.205,10.389,5.253
                c0.599,0.979,2.951,0.975,4.529,1.238c1.123,0.187,2.781-0.425,3.373,0.159c3.873,3.82,6.342,2.677,8.867-1.594
                c0.478-0.808,3.985-0.861,5.221-0.004c5.859,4.068,5.437,3.854,10.835-0.974c3.493-3.123,7.901-5.718,12.35-7.06
                c2.023-0.609,5.707,1.85,7.617,3.878c2.744,2.914,3.966,7.257,9.538,6.04c1.423-0.311,3.967,1.352,4.948,2.812
                c2.45,3.644,3.327,9.201,11.139,4.192C577.422,734.533,577.21,735.399,576.998,736.265z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(16)} fill={props.fillColors[16]}  d="M945.805,786.173c-4.808-0.216-9.46,0.022-13.944-0.734
                c-6.011-1.015-8.868,3.635-12.811,6.269c-3.968,2.651-9.383,3.472-11.439,8.743c-0.149,0.383-1.859,0.232-2.841,0.204
                c-30.13-0.874-60.266-1.619-90.387-2.739c-9.004-0.334-18.158-3.272-26.901-2.208c-9.448,1.149-18.817-2.511-27.485-0.455
                c-15.972,3.789-31.931,4.506-47.997,3.494c-17.408-1.095-34.742-3.357-52.367-5.137c0-0.782-0.227-1.521,0.035-1.985
                c2.459-4.355,4.362-9.285,7.746-12.766c2.193-2.255,6.576-2.397,9.992-3.441c4.069-1.243,8.28-2.136,12.184-3.77
                c2.247-0.939,3.759-2.789,2.507-6.045c-1.468-3.818-1.284-6.885,4.041-8.67c5.77-1.935,10.771-6.064,16.432-8.477
                c2.323-0.989,6.012-0.91,8.168,0.303c3.968,2.231,7.434,3.521,11.6,0.942c0.786-0.487,2.027-0.828,2.851-0.57
                c5.198,1.622,10.35,3.399,15.487,5.207c1.079,0.379,2.122,1.693,3.04,1.564c8.479-1.182,12.101,5.262,17.317,9.936
                c-1.067-1.769-2.135-3.537-3.337-5.53c3.745-4.259,7.503-7.815,14.508-4.881c4.566,1.913,9.955,1.802,14.911,2.891
                c1.439,0.316,2.881,1.436,3.93,2.554c2.17,2.312,4.024,2.819,6.851,0.743c4.03-2.963,8.195-6.518,13.666-2.146
                c0.613,0.489,2.849-0.74,4.14-1.497c1.352-0.793,2.402-2.083,3.708-2.974c4.146-2.828,7.747-2.346,12.001,0.652
                c4.45,3.137,9.484,0.566,14.135-0.94c1.223-0.396,2.611-1.83,3.477-1.524c5.311,1.88,9.662-1.173,14.465-1.979
                c6.711-1.126,11.608-5.138,17.413-7.604c1.459-0.62,3.513-1.087,4.849-0.539c6.671,2.734,12.81,2.399,17.681-3.17
                c4.873-5.573,10.962-4.215,16.957-3.865c3.349,0.195,6.682,0.634,10.384,0.998c-0.743,6.631-2.901,12.977-6.181,19.297
                c-1.993,3.841-1.77,8.938-2.01,13.499c-0.055,1.037,2.221,3.236,3.199,3.114c5.38-0.676,6.17,3.143,7.426,6.737
                C943.908,781.694,944.788,783.653,945.805,786.173z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(17)} fill={props.fillColors[17]}  d="M34.857,824.123c3.533-7.829,6.809-15.09,10.543-23.366
                c-4.645,1.506-8.423,2.73-12.201,3.955c5.971-4.699,12.564-8.606,15.423-16.766c-1.786-0.137-3.029-0.232-5.6-0.429
                c3.347-5.501,6.328-10.399,9.615-15.802c-7.05,2.441-13.468,4.663-19.885,6.886c-0.326-0.467-0.651-0.935-0.977-1.401
                c11.237-9.606,19.246-21.463,24.858-35.415c-2.617,1.623-5.233,3.247-7.85,4.871c-0.267-0.188-0.535-0.375-0.802-0.562
                c3.327-7.161,6.654-14.321,10.523-22.647c-6.812,1.609-12.007,2.838-18.286,4.322c3.99-9.821,7.608-18.487,11.006-27.238
                c1.038-2.674,4.194-5.646,0.583-7.854c2.581-5.873,5.107-11.621,8.144-18.533c-6.644,0-11.393,0-14.96,0
                c6.792-8.859,13.958-18.207,21.125-27.555c-0.426-0.473-0.851-0.946-1.277-1.419c-1.632,0.3-3.263,0.6-5.245,0.963
                c1.27-2.593,2.456-5.014,4.278-8.731c-4.451,1.415-7.636,2.428-10.821,3.44c5.641-7.04,11.503-13.8,10.709-24.046
                c-2.086,0.143-3.527,0.241-4.578,0.313c-1.604-8.022,3.791-13.608,6.8-20.07c2.581-5.545,4.987-11.171,8.045-18.055
                c1.489,19.733,11.213,33.961,24.457,46.959c-15.335-4.085-13.101,0.144-26.599-12.729c0.96,8.182,9.437,9.058,12.913,14.604
                c3.475,5.545,8.581,10.068,13.753,15.926c-3.411-1.565-5.854-2.687-8.726-4.004c-0.241,1.504-0.424,2.642-0.64,3.991
                c-2.281-0.936-4.504-1.848-6.729-2.761c-0.372,0.361-0.744,0.722-1.116,1.083c3.568,8.987,9.45,16.307,17.686,21.929
                c-0.235,0.364-0.471,0.728-0.707,1.091c-4.273-2.15-8.547-4.301-13.503-6.795c0.452,1.271,0.637,2.394,1.199,3.275
                c3.246,5.096,7.403,9.343,9.275,15.643c1.476,4.969,6.41,8.909,10.38,14.046c-11.775-5.141-23.072-10.074-33.814-14.764
                c0.649,3.443,3.968,5.865,10.281,7.51c7.745,2.018,14.591,5.226,19.462,12.385c3.573,5.251,8.723,9.43,13.595,14.53
                c-3.397-0.71-6.596-1.378-9.795-2.047c-0.327,0.312-0.654,0.626-0.98,0.938c2.078,2.858,4.156,5.717,6.831,9.397
                c-6.947-2.338-13.17-4.432-19.754-6.647c-0.247,0.776-0.594,1.87-0.952,2.994c-1.887-0.406-3.646-0.786-5.405-1.165
                c-0.275,0.351-0.55,0.7-0.825,1.051c6.923,8.592,14.75,16.235,24.269,21.672c-7.556,3.808-15.116,1.628-22.63-1.575
                c-0.255,0.456-0.51,0.913-0.765,1.37c2.114,2.117,4.125,4.353,6.374,6.314c1.872,1.633,3.825,3.552,6.087,4.293
                c10.293,3.374,16.906,11.715,25.91,18.652c-13.075-2.91-25.185-5.607-38.13-8.489c3.585,9.538,12.19,13.812,19.108,19.149
                c-0.184,1.554-0.788,3.068-0.389,4.242c0.448,1.32,1.573,2.78,2.798,3.405c8.374,4.272,12.548,12.908,19.555,18.472
                c-1.464,1.351-3.827,2.512-4.083,4.027c-0.311,1.849,0.706,4.333,1.931,5.947c3.277,4.32,6.973,8.322,10.796,12.793
                c-6.835,1.347-16.343-5.768-19.294,5.298c-3.91,0.298-7.289,0.343-10.596,0.856c-4.938,0.77-8.742-1.219-12.23-4.325
                c-2.339-2.083-4.515-3.604-7.864-1.271c-3.084,2.149-4.863-0.634-6.323-2.869c-2.691-4.118-6.24-6.934-11.224-5.376
                c-2.669,0.834-5.124,3.024-7.132,5.122c-2.178,2.276-3.798,2.39-6.349,0.645c-3.628-2.482-7.143-3.296-11.303,0.113
                C41.114,825.297,37.282,824.123,34.857,824.123z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(18)} fill={props.fillColors[18]}  d="M274.501,720.375c-5.817-2.542-11.634-5.084-17.452-7.625
                c-0.33,0.299-0.659,0.598-0.989,0.896c2.248,3.458,4.496,6.916,7.005,10.775c-2.333-0.669-4.406-1.264-7.599-2.18
                c1.116,3.431,1.9,5.841,3.049,9.369c-4.76-1.259-8.92-2.358-13.08-3.458c-0.37,0.316-0.739,0.634-1.109,0.951
                c7.681,16.217,20.366,28.317,34.111,39.897c-5.994-2.204-11.987-4.408-19.467-7.158c3.222,7.877,8.573,12.453,14.472,18.288
                c-2.878-0.996-4.463-1.544-7.462-2.582c5.798,12.806,16.896,17.634,26.825,23.736c-0.225,0.471-0.449,0.941-0.673,1.412
                c-7.83-3.286-15.659-6.571-23.488-9.857c-0.377,0.516-0.755,1.03-1.133,1.546c5.296,5.045,10.592,10.091,15.337,14.611
                c-2.583,5.99,3.368,8.312,5.384,12.831c-3.85-1.349-7.295-2.556-10.741-3.764c6.033,10.693,17.493,13.354,26.484,18.558
                c-6.55,0-13.585,0.376-20.548-0.169c-3.179-0.248-6.649-1.737-9.226-3.676c-4.874-3.667-9.254-5.403-13.921-1.818
                c-8.02,6.162-13.916,1.099-20.372-2.259c-1.018-0.529-1.822-1.453-2.79-2.1c-5.44-3.634-8.929-2.796-12.261,3.073
                c-1.377-0.23-2.823-0.529-4.283-0.7c-1.467-0.171-2.949-0.215-4.498-0.319c0.453-3.734,1.476-6.327,5.753-7.044
                c1.95-0.327,3.548-2.758,5.398-4.312c3.015,3.244,5.8,7.156,10.834,5.41c1.507-0.522,3.2-2.725,3.43-4.352
                c1.298-9.206,1.155-9.227-7.377-14.46c1.169-1.013,2.676-1.756,3.241-2.946c0.727-1.533,1.447-3.759,0.85-5.096
                c-1.495-3.346-1.117-5.665,2.106-7.435c2.566-1.41,4.067-3.627,2.033-6.073c-3.448-4.146-7.28-8.016-11.28-11.638
                c-1.15-1.041-3.432-0.834-5.754-1.315c0.24-1.117,0.168-2.521,0.806-3.422c1.764-2.489,1.975-4.488,0.033-7.193
                c-1.05-1.463-1.153-4.192-0.649-6.073c1.6-5.977-0.78-10.049-6.775-9.2c-6.193,0.876-12.179,3.222-18.919,5.11
                c-0.792-1.025-2.444-2.819-3.68-4.865c-1.19-1.97-1.95-4.198-3.039-6.625c3.331-4.131,6.176-8.759,12.798-9.224
                c2.695-0.188,5.225-2.734,7.415-4.627c-5.106,1.401-10.165,3.023-15.333,4.142c-5.094,1.104-10.299,1.696-15.807,1.666
                c8.426-6.434,15.607-13.445,21.073-23.856c-4.153,1.87-7.155,3.221-10.157,4.572c-0.209-0.253-0.418-0.506-0.627-0.759
                c2.63-4.43,5.26-8.86,8.552-14.406c-5.547,1.762-9.715,3.085-14.882,4.725c8.631-10.922,16.565-20.963,24.5-31.004
                c-0.363-0.391-0.726-0.78-1.089-1.17c-2.624,1.732-5.247,3.465-8.361,5.521c0.359-1.434,0.615-2.452,1.149-4.58
                c-3.22,2.258-5.587,3.918-7.955,5.579c-0.162-0.113-0.324-0.226-0.485-0.338c3.203-4.844,6.405-9.688,9.608-14.531
                c-0.286-0.362-0.573-0.725-0.859-1.086c-2.48,1.275-4.961,2.551-7.442,3.826c-0.217-0.134-0.435-0.267-0.652-0.4
                c1.106-1.427,2.163-2.896,3.327-4.274c5.375-6.369,8.615-13.743,10.597-21.78c0.706-2.864,0.705-4.714-3.783-3.593
                c3.135-7.122,5.961-13.541,9.17-20.833c-1.865,0.357-2.596,0.431-3.281,0.648c-0.582,0.186-1.111,0.537-1.208,0.586
                c5.272-11.8,10.644-23.823,15.651-35.031c1.33,4.32,2.919,10.052,4.861,15.661c3.078,8.893,7.723,16.835,14.027,23.953
                c2.488,2.811,3.513,6.917,4.73,11.322c-4.261-2.468-8.522-4.935-12.783-7.401c-0.279,0.197-0.557,0.396-0.835,0.593
                c1.392,2.381,2.784,4.762,4.2,7.184c-3.894-0.417-4.186,1.975-2.769,4.359c3.704,6.231,7.7,12.298,11.8,18.279
                c1.085,1.583,2.97,2.616,3.897,4.823c-1.963-0.596-3.926-1.191-6.596-2.002c1.053,7.867,5.986,12.222,11.52,16.888
                c-1.946-0.157-3.36-0.272-5.682-0.46c1.12,2.862,1.984,5.072,3.352,8.567c-6.332-1.495-11.807-2.789-17.282-4.082
                c-0.286,0.298-0.572,0.597-0.857,0.895c11.313,15.881,21.954,32.328,38.739,43.607
                C275.051,719.515,274.776,719.944,274.501,720.375z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(19)} fill={props.fillColors[19]}  d="M621.638,453.03c1.745-0.258,3.728-0.168,5.354-0.886
                c2.245-0.992,4.48-2.315,6.268-3.981c9.499-8.856,18.776-17.951,28.307-26.773c3.575-3.31,4.358-6.301,1.86-10.822
                c-4.396-7.957-6.969-16.882-13.59-23.674c-1.216-1.246-1.042-4.743-0.344-6.808c3.291-9.72,11.395-15.626,18.537-22.825
                c4.764,7.438,9.188,14.641,13.914,21.638c4.634,6.86,9.577,13.513,14.715,20.718c-7.911,6.206-16.447,13.036-25.144,19.654
                c-3.002,2.284-4.67,4.872-6.141,8.511c-2.633,6.511-7.08,12.271-10.546,18.469c-1.797,3.213-4.118,6.593-4.465,10.07
                c-1.03,10.354-5.782,18.604-12.642,25.961c-1.135,1.218-2.239,2.514-3.563,3.497c-27.56,20.487-48.231,47.704-71.171,72.708
                c-7.634,8.322-15.508,16.424-24.25,24.237c3.445-7.425,6.891-14.85,10.83-23.339c-7.53,1.957-11.352-2.571-15.306-6.944
                c-0.636-0.703-1.683-1.057-2.574-1.502c-7.051-3.518-8.468-6.492-5.966-14.083c3.43-10.406,9.734-18.985,18.313-25.642
                c7.111-5.519,13.543-11.421,19.408-18.397c7.783-9.256,17.48-16.871,25.946-25.59c8.554-8.809,16.626-18.087,24.871-27.194
                c2.531-2.797,4.937-5.707,7.4-8.565c0.555,0.163,1.11,0.326,1.666,0.489C622.783,438.733,622.24,445.51,621.638,453.03z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(20)} fill={props.fillColors[20]}  d="M817.564,799.742c22.968,0.621,45.942,1.083,68.901,1.926
                c13.639,0.501,27.278,1.321,40.875,2.493c31.495,2.714,62.928,6.238,94.454,8.463c26.736,1.888,53.571,2.383,80.36,3.552
                c19.961,0.871,39.917,1.834,60.287,2.775c0,7.62,0,14.849,0,23.039c-2.805,0-5.889-0.619-8.622,0.099
                c-21.461,5.631-42.934,1.611-64.429,0.568c-18.466-0.896-36.958-1.438-55.444-1.679c-16.453-0.215-32.289-2.769-47.992-7.996
                c-8.677-2.889-18.298-3.097-27.539-4.099c-21.664-2.349-43.361-4.408-65.031-6.712c-2.057-0.219-4.016-1.343-6.024-2.032
                c-9.158-3.141-17.905-7.832-28.011-7.403c-0.996,0.043-2.058,0.178-2.988-0.085c-7.78-2.199-15.531-4.505-23.306-6.725
                c-2.537-0.725-5.135-1.237-7.677-1.944c-2.764-0.769-5.496-1.65-8.242-2.483C817.279,800.913,817.422,800.327,817.564,799.742z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(21)} fill={props.fillColors[21]}  d="M102.081,213.015c-2.068,6.035-3.963,12.138-6.266,18.083
                c-1.557,4.018-3.953,7.714-5.462,11.745c-0.892,2.383-4.499,4.669-1.088,7.85c0.199,0.186-0.881,2.007-1.602,2.869
                c-3.628,4.343-7.11,8.839-11.05,12.886c-23.804,24.444-47.718,48.782-71.603,73.148c-0.571,0.583-1.232,1.078-2.324,2.025
                c-0.236-1.489-0.538-2.508-0.539-3.527c-0.026-26.978-0.076-53.956,0.093-80.933c0.012-1.994,1.208-4.366,2.589-5.9
                c7.562-8.398,15.295-16.647,23.134-24.79c1.183-1.229,3.191-2.162,4.887-2.285c2.786-0.202,5.781-0.116,8.418,0.709
                c7.466,2.337,14.14,0.175,20.646-3.044c4.36-2.157,8.551-3.248,13.678-3.051c5.448,0.209,11.033-1.854,16.473-3.253
                c2.854-0.733,5.529-2.168,8.285-3.286C100.927,212.512,101.504,212.764,102.081,213.015z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(22)} fill={props.fillColors[22]}  d="M1009.947,777.074c-2.532,3.299-5.064,6.598-7.596,9.896
                c0.226,0.317,0.452,0.634,0.678,0.951c3.362-1.452,6.725-2.905,10.779-4.656c-1.354,2.662-2.459,4.831-3.563,7
                c0.325,0.31,0.649,0.62,0.975,0.93c1.646-1.09,3.293-2.181,4.939-3.271c0.516-1.247,1.23-1.936,2.557-0.914
                c-0.904,1.734-1.845,3.452-2.703,5.209c-0.976,1.998-2.905,3.184-1.741,6.46c1.23,3.463-7.756,10.475-12.137,10.096
                c-11.768-1.017-23.525-2.135-35.29-3.192c-18.549-1.669-37.099-3.32-56.78-5.08c1.635-1.746,2.655-3.307,4.081-4.267
                c3.74-2.519,7.548-4.99,11.553-7.043c1.896-0.972,4.298-1.274,6.478-1.304c4.798-0.063,9.602,0.333,14.403,0.323
                c1.123-0.003,2.244-0.874,2.751-1.087c-2.212-4.372-4.252-8.199-6.096-12.119c-1.133-2.408-2.265-4.237-5.487-4.056
                c-1.062,0.06-3.284-1.902-3.171-2.675c0.603-4.117,1.631-8.192,2.795-12.2c1.402-4.827,3.551-9.479,4.529-14.375
                c1.05-5.257,2.904-6.946,8.054-5.545c4.987,1.357,13.429-2.696,15.862-7.211c0.762-1.414,2.514-2.723,4.08-3.203
                c7.627-2.341,15.694-3.741,21.633-9.648c2.35-2.337,4.42-1.771,7.189-1.28c3.694,0.655,7.571,0.234,11.364,0.402
                c1.275,0.057,2.533,0.501,4.505,0.917c-2.3,3.031-4.043,5.329-5.786,7.627c0.206,0.331,0.412,0.662,0.618,0.993
                c2.635-1.207,5.27-2.414,8.273-3.791c-1.456,2.509-2.716,4.68-3.976,6.85c0.317,0.297,0.636,0.594,0.953,0.892
                c2.368-1.315,4.737-2.63,7.032-3.904c-0.313,1.659-0.653,3.453-0.992,5.246c0.765,0.223,1.529,0.444,2.294,0.666
                c-5.355,8.67-10.712,17.34-16.881,27.326c3.989-1.854,6.916-3.215,10.614-4.934c-1.651,2.678-2.946,4.779-4.241,6.88
                c0.188,0.24,0.376,0.481,0.564,0.722c2.267-1.103,4.533-2.206,6.801-3.309c0.136,0.243,0.446,0.561,0.38,0.719
                c-1.861,4.439-3.561,8.963-5.736,13.245c-1.097,2.158-3.066,3.873-4.642,5.788c-3.579-3.852-7.679-4.36-11.185-1.109
                c-3.375,3.129-6.562,6.327-11.6,3.146c-0.396-0.25-1.133-0.341-1.451-0.092c-4.683,3.659-10.287,3.366-15.75,4.125
                c-3.391,0.472-7.543-0.98-9.542,2.271c3.92-2.624,9.108-2.902,14.233-2.55c3.296,0.226,5.855-0.981,8.367-2.568
                c1.488-0.939,3.016-2.002,4.57-0.998c4.525,2.919,7.516-0.046,10.478-2.601C1002.553,772.832,1005.662,772.803,1009.947,777.074z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(23)} fill={props.fillColors[23]}  d="M65.978,862.352c2.805-3.646,4.794-6.517,7.109-9.096
                c0.873-0.972,2.551-1.877,3.773-1.772c7.847,0.669,14.711-2.22,21.479-5.522c3.691-1.802,7.15-3.501,8.842,2.385
                c0.181,0.629,2.234,1.319,3.131,1.043c4.475-1.378,7.315-6.451,6.437-10.972c-1.152-5.922,3.171-10.841,9.212-10.777
                c4.192,0.044,8.517-0.991,12.543-0.248c3.146,0.579,6.529,2.601,8.699,4.988c2.271,2.499,4.193,3.035,7.31,2.615
                c1.985-0.268,4.174,0.731,6.232,1.305c3.048,0.849,6.054,1.851,9.091,2.737c2.038,0.596,4.501,0.568,6.079,1.749
                c5.706,4.269,12.185,2.681,14.954-4.058c0.439-1.069,0.536-2.281,0.983-3.348c2.008-4.782,6.78-5.93,10.369-2.458
                c3.143,3.04,5.552,6.277,10.425,1.354c1.793-1.812,6.916-0.157,10.488-0.459c1.614-0.136,4.179-0.831,4.542-1.885
                c1.43-4.14,4.237-3.519,6.782-2.171c4.642,2.458,8.897,5.652,13.565,8.053c1.749,0.899,4.231,0.743,6.311,0.496
                c1.377-0.164,2.613-1.386,3.947-2.066c8.369-4.271,8.527-3.697,16.209,2.018c3.021,2.248,8.075,1.845,12.233,2.444
                c1.584,0.229,3.961-0.855,4.77-0.081c7.089,6.785,16.901,2.75,24.619,6.53c1.761,0.863,3.465,2.452,4.357,4.148
                c0.319,0.605-1.57,2.961-2.905,3.756c-7.708,4.587-16.442,5.571-25.068,5.559c-18.984-0.028-37.967-0.719-56.95-1.173
                c-9.662-0.23-19.322-0.557-28.984-0.782c-1.4-0.033-2.807,0.254-4.329,0.404c2.049,5.914,0.979,8.727-5.44,9.429
                c-9.871,1.08-19.913,1.313-29.847,0.95c-24.638-0.902-49.256-2.331-73.881-3.58C84.292,863.422,75.522,862.888,65.978,862.352z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(24)} fill={props.fillColors[24]}  d="M1114.994,761.983c-3.306,0.854-2.042,3.936-3.043,5.838
                c-1.738,3.303-4.688,5.981-7.205,8.854c-0.937,1.069-2.087,1.951-3.556,3.301c1.757,0.55,2.945,0.921,4.473,1.398
                c-0.896,1.611-1.739,3.128-2.759,4.961c1.151,0.428,2.143,0.796,3.065,1.139c-1.692,3.004-3.482,5.963-5.06,9.031
                c-2.419,4.702-4.42,9.641-7.114,14.172c-1.984,3.336-7.601,4.049-10.906,1.802c-3.006-2.043-5.851-4.323-9.155-6.789
                c1.178,0.054,1.879,0.086,3.375,0.154c-0.945-1.064-1.378-1.866-2.05-2.251c-2.308-1.319-4.67-2.556-7.065-3.711
                c-10.84-5.227-15.854-16.115-23.672-24.281c2.907,1.396,5.814,2.794,9.367,4.5c-0.14-1.7-0.207-2.52-0.247-3.01
                c5.169,2.323,10.416,4.681,15.663,7.039c0.244-0.443,0.487-0.887,0.731-1.331c-2.407-2.657-4.814-5.314-7.891-8.71
                c2.072,0.409,3.271,0.646,4.471,0.884c0.198-0.228,0.397-0.455,0.596-0.683c-5.582-5.191-11.164-10.383-16.746-15.574
                c0.117-0.156,0.233-0.312,0.351-0.469c2.765,1.09,5.53,2.179,8.295,3.268c0.219-0.334,0.437-0.667,0.654-1.001
                c-1.42-2.466-2.841-4.933-4.896-8.499c4.512,2.412,7.881,4.213,11.249,6.014c0.293-0.388,0.585-0.774,0.878-1.162
                c-6.6-6.593-15.968-10.661-20.084-21.173c4.502,1.261,8.104,2.27,12.647,3.542c-1.3-2.596-2.245-4.483-3.712-7.411
                c3.161,1.715,5.31,2.881,7.459,4.047c0.22-0.306,0.439-0.612,0.659-0.918c-5.481-5.162-10.963-10.324-17.242-16.238
                c3.275-1.866,6.349-3.227,8.933-5.221c1.906-1.47,3.688-3.552,4.608-5.746c1.528-3.646,2.202-7.046,7.8-5.781
                c2.141,0.483,5.843-1.537,7.344-3.536c3.343-4.448,6.031-5.149,9.564-0.566c0.576,0.747,1.587,1.16,2.412,1.738
                c3.338-2.835,6.612-5.544,9.803-8.35c2.01-1.767,3.844-2.234,6.099-0.293c4.92,4.239,10.071,5.791,15.679-0.149
                c5.689,9.469-2.454,16.025-3.692,23.987c1.25,0,2.388,0,4.584,0c-4.261,6.886-8.061,13.029-12.578,20.332
                c3.502-1.309,6.042-2.258,8.583-3.207c0.151,0.167,0.303,0.334,0.455,0.5c-4.686,3.978-9.37,7.955-15.201,12.906
                c4.111,0.206,7.438,0.374,10.768,0.541c0.354,0.624,0.96,1.689,1.593,2.804c-3.615,4.033-7.356,8.206-12.181,13.588
                c4.346-0.293,7.481-0.565,10.623-0.684c1.062-0.04,2.139,0.31,3.21,0.481L1114.994,761.983z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(25)} fill={props.fillColors[25]}  d="M2.125,773.532c9.089,11.165,22.03,15.169,34.406,20.106
                c-5.228-3.219-10.455-6.438-16.104-9.916c-2.837,2.396-4.621,0.37-6.505-2.639c-1.071-1.712-3.334-2.645-4.567-4.296
                c-2.581-3.456-6.364-6.939-6.912-10.801c-1.229-8.651-0.896-17.349,5.057-25.081c2.941-3.821,4.108-9.01,5.574-12.41
                c5.529,5.413,11.394,11.432,17.579,17.102c3.362,3.082,7.271,4.94,10.844-0.103c-3.881-6.808-7.876-13.816-11.871-20.824
                c-0.339,0.146-0.678,0.292-1.018,0.438c1.558,3.469,3.351,6.854,4.605,10.43c1.193,3.403,6.019,5.777,2.969,10.886
                c-1.763-1.039-3.597-1.81-5.05-3.034c-4.065-3.426-8.373-6.697-11.797-10.703c-2.141-2.505-2.913-6.206-4.184-9.416
                c-0.461-1.163-0.588-2.459-1.07-4.584c-3.649,9.047-6.942,17.213-10.236,25.378c-0.563-0.179-1.126-0.357-1.69-0.536
                c0-10.26-0.016-20.52,0.033-30.778c0.004-0.776,0.253-1.749,0.754-2.295c7.216-7.855,8.267-18.153,10.913-27.777
                c0.503-1.832,2.705-5.079-0.916-5.779c1.365-4.438,2.802-9.113,4.497-14.625c-2.008,0.729-3.331,1.21-4.486,1.63
                c2.011-5.076,3.999-10.096,6.147-15.52c-1.36,0-2.488,0-3.4,0c1.385-6.523,2.705-12.742,4.025-18.962
                c0.386-0.012,0.772-0.022,1.159-0.034c0.862,3.205,1.724,6.41,2.586,9.615c-0.293-0.023-0.587-0.047-0.88-0.07
                c-0.372,1.696-1.471,3.649-0.978,5.038c1.434,4.035,3.778,7.746,5.229,11.776c0.952,2.646,1.061,5.614,1.391,8.457
                c0.315,2.715,0.429,5.453,0.629,8.159c-4.548,0.345-1.562,3.525-0.791,5.182c4.123,8.852,7.997,17.891,13.007,26.234
                c4.483,7.467,2.324,13.567-1.553,19.925c-0.839,1.377-1.856,2.646-2.791,3.964c0.16,0.576,0.32,1.152,0.479,1.728
                c5.695-0.672,11.39-1.343,17.566-2.071c-3.702,7.155-7.448,14.396-11.194,21.639c0.446,0.458,0.893,0.916,1.338,1.374
                c2.292-0.841,4.584-1.683,8.306-3.049c-4.393,6.315-7.838,11.919-11.943,16.989c-4.131,5.103-8.917,9.674-13.386,14.444
                c4.485,1.955,4.485,1.955,18.271-1.944c-1.721,3.878-3.608,8.13-5.669,12.773c1.74,0.398,3.062,0.701,6.228,1.426
                c-7.147,5.487-13.315,10.223-19.483,14.958c0.083,0.421,0.167,0.841,0.251,1.262c4.491-0.734,8.982-1.468,14.416-2.356
                c-2.779,3.261-5.172,6.068-7.879,9.243c1.435,0.317,2.514,0.556,4.25,0.938c-4.488,5.115-8.625,9.832-11.596,13.219
                c-5.188-1.205-9.229-2.5-13.366-3.017c-5.661-0.707-11.183-6.138-11.189-11.735C2.116,800.17,2.125,786.851,2.125,773.532z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(26)} fill={props.fillColors[26]}  d="M90.227,762.104c14.281,0.614,27.076,7.396,41.321,5.006
                c-5.431-3.271-10.861-6.543-17.092-10.296c3.709-3.076,6.88-5.215,9.427-7.937c3.482-3.722,6.618-7.794,9.608-11.929
                c0.743-1.028,0.358-2.872,0.491-4.341c-0.427-0.095-0.854-0.188-1.281-0.282c2.772-2.477,5.543-4.953,8.315-7.43
                c-0.249-0.369-0.497-0.738-0.745-1.107c-2.662,1.385-5.236,2.989-8.011,4.09c-2.654,1.052-5.516,1.579-8.927,2.512
                c4.774-11.51,16.454-18.149,16.727-32.025c-3.333,2.061-6.019,3.72-8.705,5.379c-0.13-0.13-0.26-0.26-0.39-0.389
                c4.741-4.945,9.482-9.89,14.224-14.834c-0.308-0.384-0.617-0.768-0.925-1.151c-3.307,1.185-6.614,2.369-10.777,3.861
                c3.243-6.395,6.058-11.943,9.09-17.922c-6.071,2.558-11.507,4.849-16.944,7.14c-0.259-0.38-0.517-0.76-0.776-1.14
                c8.988-6.923,16.62-14.61,15.341-27.099c-0.047-0.466-1.696-0.767-2.802-1.229c0.805-2.388,1.684-4.996,2.88-8.545
                c-3.541,0.686-6.293,1.218-9.084,1.758c7.002-9.411,13.834-18.593,20.288-27.267c3.913,11.873,8.069,24.482,12.225,37.093
                c-0.301,0.241-0.602,0.482-0.903,0.724c-2.922-2.055-5.844-4.109-8.767-6.165c-0.341,0.275-0.683,0.55-1.024,0.824
                c1.766,2.826,3.439,5.715,5.322,8.461c2.329,3.397,4.47,6.553,5.521,10.909c1.096,4.541,4.973,8.41,7.956,13.077
                c-3.627-2.192-6.908-4.174-10.189-6.156c-0.48,0.369-0.959,0.738-1.439,1.107c3.324,4.868,6.648,9.736,9.973,14.604
                c-0.148,0.267-0.297,0.533-0.446,0.799c-6.056-4.453-12.111-8.906-18.167-13.359c3.948,10.402,10.778,18.544,18.132,26.66
                c-2.766-1.598-5.532-3.196-8.298-4.794c0.645,7.836,5.4,13.067,11.347,19.151c-3.936-1.629-6.608-2.733-10.619-4.393
                c1.325,2.669,2.123,4.277,3.038,6.119c-2.962,4.014-5.988-0.593-10.258-0.363c3.666,5.399,8.749,7.497,14.451,10.169
                c-8.585,3.301-16.119,2.503-23.438-0.121c0.773,3.352,4.22,5.62,7.854,4.43c4.791-1.569,8.443,0.053,11.761,3.602
                c-11.035,5.147-15.884,14.263-11.776,22.998c-6.318-3.479-12.109-6.666-17.444-9.603c0.132,3.879,13.307,11.663,25.556,13.849
                c-2.653,4.825-5.727,9.771,0.82,14.624c-0.431,0.264-0.862,0.526-1.293,0.79c-2.825,0-5.65-0.013-8.475,0.003
                c-2.913,0.016-6.089-0.857-7.591,3.016c-1.604,4.14-4.345,8.168-0.11,12.316c0.466,0.457,0.965,0.881,1.673,1.523
                c-4.39,3.992-5.182,8.197-1.395,13.123c1.535,1.996,2.275,4.679,3.076,7.146c1.923,5.926,6.367,7.59,12.346,7.18
                c0.395,2.272,0.753,4.338,0.971,5.597c-9.226,3.421-14.526-3.845-21.307-7.09c-7.472-3.576-12.339-9.617-16.43-17.576
                c3.159-0.615,5.333-1.039,8.812-1.717c-3.243-2.95-5.789-5.266-9.118-8.295c3.827,0.461,6.676,0.804,9.526,1.146
                c0.177-0.552,0.354-1.104,0.53-1.655c-4.358-2.07-8.785-4.012-13.057-6.249c-4.15-2.175-8.142-4.654-11.707-8.011
                c2.982-0.222,5.964-0.443,9.923-0.737c-1.46-1.633-2.153-2.406-2.842-3.182c8.887-3.911,19.641-4.641,25.331-14.681
                c-4.987,2.814-9.521,5.723-14.352,8.001c-4.738,2.233-9.767,3.857-14.703,5.652c-0.493,0.179-1.267-0.237-1.85-0.508
                C102.862,773.36,96.15,768.356,90.227,762.104z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(27)} fill={props.fillColors[27]}  d="M1129.253,779.273c0.016,0.629-0.013,1.262,0.054,1.885
                c0.309,2.889,0.644,5.774,0.969,8.66c0.523,0.05,1.047,0.099,1.569,0.147c-4.273,5.85-8.547,11.699-13.764,18.84
                c4.218-1.096,7.203-1.872,10.447-2.715c-2.958,5.811-6.365,9.408-13.808,8.203c-6.408-1.038-13.117-0.216-19.719-0.216
                c4.705-9.795,9.094-18.933,13.621-28.357c-0.641-0.107-1.631-0.273-3.106-0.521c1.522-2.249,2.936-4.337,4.776-7.058
                c-1.845,0.156-2.989,0.254-4.253,0.36c2.708-3.528,5.497-6.648,7.635-10.165c1.06-1.741,0.919-4.213,1.32-6.354
                c-0.001,0-0.065,0.077-0.066,0.077c1.473-1.612,2.945-3.226,4.419-4.839c-0.178-0.192-0.354-0.386-0.531-0.578
                c-4.207,1.588-8.413,3.176-13.497,5.096c5.135-7.613,9.48-14.058,13.827-20.501c-0.24-0.261-0.48-0.521-0.721-0.781
                c-3.846,1.37-7.692,2.739-12.317,4.387c5.634-6.198,10.523-11.576,15.412-16.955c-0.398-0.481-0.797-0.964-1.195-1.445
                c-2.361,1.416-4.723,2.832-7.533,4.517c3.671-7.108,7.059-13.667,10.925-21.155c-2.15,0.51-3.408,0.809-4.53,1.074
                c2.216-7.903,4.466-15.931,6.682-23.835c1.612,6.678,3.127,13.808,5.114,20.802c0.925,3.251,2.77,6.241,4.197,9.35l-0.082-0.072
                c-1.945,2.322-3.891,4.646-5.835,6.968c0.252,0.358,0.504,0.718,0.757,1.076c2.418-1.56,4.837-3.118,7.256-4.678
                c0.479-0.379,1.197-0.071,1.064,0.234c-2.914,6.728-3.931,14.237-7.547,19.236c3.81-4.737,4.223-12.365,7.6-18.748
                c0.534-1.01-0.487-1.776-1.744-1.594c-0.079-0.401-0.365-1.021-0.206-1.172c6.604-6.289,6.116-15.135,8.776-23.388
                c-1.301,0.323-2.095,0.521-2.88,0.716c1.521-6.828,3.017-13.551,4.811-21.605c3.396,7.516,6.481,14.175,9.419,20.897
                c1.924,4.404,3.832,8.835,5.36,13.384c0.764,2.271,0.845,4.83,0.907,7.267c0.129,5.076,0.039,10.157-1.046,15.594
                c-2.009-2.721-4.019-5.441-6.028-8.162c-0.278,0.148-0.558,0.298-0.836,0.446c2.139,3.594,4.278,7.187,6.417,10.779
                c-0.155,0.233-0.312,0.466-0.467,0.699c-2.69-1.937-5.382-3.874-8.354-6.013c-0.376,0.758-0.756,1.524-1.69,3.408
                c-2.188-3.927-4.032-7.235-5.975-10.721c-0.617,6.765,2.647,10.757,9.078,11.416c4.185,0.429,8.934,5.678,8.938,9.9
                c0.007,7.888,0.002,15.776,0.002,23.664c-0.482,0.111-0.965,0.222-1.448,0.332c-2.194-5.706-4.39-11.412-6.584-17.119
                c-0.369,0.127-0.737,0.254-1.106,0.38c1.458,4.464,2.201,9.35,4.527,13.303c4.43,7.525,4.941,15.429,4.681,23.771
                c-0.3,9.601-0.069,19.218-0.069,29.366c-12.86-0.688-25.102-1.342-37.834-2.021c2.477-3.794,4.766-7.299,7.054-10.805
                c-0.229-0.336-0.46-0.672-0.689-1.009c-2.676,0.838-5.352,1.676-8.25,2.584c4.409-6.521,8.712-12.886,13.763-20.357
                c-2.465,0.609-3.599,0.891-5.562,1.377c1.305-4.091,2.497-7.826,3.887-12.181c-2.275,1.531-4.383,2.949-6.49,4.367
                c-1.385,1.733-3.203,2.541-6.299,2.85c3.926-6.977,7.529-13.305,11.908-19.011c-0.8-1.709-1.65-1.023-2.491-0.475
                c-1.22,0.639-2.291,1.988-4.28,0.792c2.781-5.435,7.122-9.622,8.79-13.044c-1.86,3.298-5.832,7.709-8.806,12.807
                c2.112,1.813,3.191-0.136,4.549-0.711c2.143,0.728,2.309,0.873,1.197,2.148c-4.123,4.727-6.221,10.764-10.211,15.564
                c-0.312,0.376-0.367,0.894,0.068,1.607C1125.566,782.098,1127.079,779.496,1129.253,779.273z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(28)} fill={props.fillColors[28]}  d="M2.129,544.955c0-9.267-0.148-17.098,0.116-24.915
                c0.058-1.712,1.208-3.772,2.498-4.992c6.172-5.835,12.719-11.277,18.854-17.15c19.855-19.01,39.49-38.251,59.392-57.212
                c28.281-26.943,54.428-55.818,78.509-86.545c3.841-4.9,7.233-10.555,9.214-16.408c2.432-7.184,5.908-13.688,11.177-18.63
                c15.517-14.553,26.191-32.71,38.413-49.723c7.16-9.966,15.94-17.661,26.276-24.663c12.169-8.244,24.426-17.039,33.009-29.976
                c9.375-14.131,20.196-27.303,30.401-40.881c0.254-0.338,0.682-0.545,1.999-1.565c-18.509,31.699-36.346,62.404-54.433,92.963
                c-1.678,2.835-4.8,4.907-7.489,7.032c-5.346,4.227-10.881,8.214-16.296,12.354c-7.263,5.554-14.592,11.028-21.704,16.771
                c-14.977,12.094-25.134,28.336-36.99,43.137c-9.053,11.301-19.067,21.828-28.163,33.097
                c-10.035,12.43-19.763,25.127-29.181,38.032c-30.472,41.755-67,78.105-102.457,115.419C11.187,535.4,7.105,539.709,2.129,544.955z
                "/>
            <path fill-rule="evenodd" onClick={() => props.onFill(29)} fill={props.fillColors[29]}  d="M451.206,1018.367c-22.098,1.975-44.673-0.042-65.002,11.841
                c12.345,2.782,24.69,5.564,38.353,8.644c-3.008,2.049-4.694,3.197-6.896,4.696c9.68,5.628,21.351,5.773,30.104,13.79
                c-5.961,2.015-11.293,3.817-16.626,5.62c0.034,0.351,0.068,0.702,0.103,1.053c9.915,3.339,19.83,6.677,29.744,10.016
                c-0.169,0.838-0.339,1.676-0.509,2.515c-22.889-1.933-45.775-3.87-68.665-5.794c-22.722-1.91-45.821,0.294-68.19-5.458
                c0-0.662-0.001-1.324-0.001-1.987c19.685-1.141,39.379-2.157,59.052-3.477c9.272-0.621,18.526-1.668,27.744-2.861
                c2.486-0.322,4.812-1.885,7.211-2.878c-0.021-0.5-0.041-1.001-0.062-1.501c-3.442-0.534-6.874-1.433-10.33-1.544
                c-23.628-0.759-47.286-0.899-70.884-2.175c-13.387-0.724-26.683-3.188-40.008-4.956c-1.713-0.228-3.357-0.982-5.033-1.494
                c0.022-0.562,0.044-1.123,0.066-1.686c13.092-1.242,26.181-2.529,39.279-3.714c11.751-1.062,23.565-1.617,35.241-3.211
                c4.467-0.609,8.625-3.479,12.924-5.318c-0.093-0.556-0.185-1.11-0.277-1.666c-5.224-2.223-10.446-4.445-16.568-7.05
                c8.228-2.146,15.289-3.986,22.35-5.827c-0.047-0.653-0.094-1.307-0.141-1.96c-18.976-3.583-38.611-3.969-56.93-11.026
                c44.637-5.089,88.834-2.428,132.693,6.706c-0.026,0.557-0.053,1.114-0.079,1.671c-6.855-0.544-13.715-1.043-20.565-1.645
                c-7.289-0.641-14.587-1.236-21.85-2.114c-7.026-0.85-14.012-2.033-21.167-2.387
                C414.615,1008.292,433.705,1010.553,451.206,1018.367z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(30)} fill={props.fillColors[30]}  d="M164.334,777.361c-4.758-0.506-3.879-3.096-2.214-6.242
                c1.786-3.375,3.5-6.425-2.72-7.784c-4.995-1.092-5.821-4.93-3.419-9.852c0.356-0.73,0.471-1.588,0.642-2.399
                c1.27-6.034,5.918-9.043,12.008-7.568c0.442-1.007,1.043-2.011,1.335-3.098c1.687-6.277,1.829-6.278,7.796-3.363
                c0.991,0.483,2.725-0.002,3.892-0.532c4.001-1.819,7.649-0.609,9.828,3.133c2.597,4.46,5.459,8.765,7.268,11.644
                c5.297-1.318,9.291-2.203,13.21-3.338c1.713-0.496,3.264-2.1,4.913-2.139c2.312-0.055,4.76,0.633,6.878,1.599
                c0.438,0.199-0.444,3.008-0.493,4.62c-0.086,2.856-2.571,6.002,1.14,8.513c0.562,0.38,0.285,2.327,0.057,3.481
                c-0.492,2.481-1.205,4.919-2.02,8.136c9.197-1.321,12.973,4.992,17.365,10.116c0.59,0.689,0.02,3.598-0.823,4.088
                c-4.706,2.738-5.76,6.708-1.855,12.26c-0.698,0.355-1.884,0.59-2.423,1.309c-1.093,1.459-2.589,3.237-2.473,4.773
                c0.086,1.129,2.35,2.687,3.86,2.997c4.09,0.84,4.875,2.949,3.358,6.464c-0.955,2.212-1.941,4.41-2.914,6.614
                c-2.458-1.71-4.928-3.406-7.366-5.146c-0.385-0.274-0.629-0.746-1.052-1.267c-3.107,1.904-5.859,4.508-9.08,5.376
                c-7.122,1.921-10.234-0.771-10.907-7.996c-0.098-1.053-1.248-2.641-2.159-2.838c-1.41-0.308-3.666-0.181-4.457,0.727
                c-3.103,3.558-5.461,4.004-8.769,0.039c-2.034-2.438-4.894-3.38-8.096-0.319c-4.299,4.109-4.987,3.673-6.633-1.876
                c-0.447-1.505-1.411-2.857-2.356-4.707c-1.513,1.349-2.453,2.775-3.704,3.142c-1.368,0.4-3.298,0.248-4.449-0.493
                c-0.695-0.447-0.714-2.553-0.425-3.768c0.375-1.576,1.397-2.99,2.068-4.506c0.667-1.506,1.244-3.052,2.119-5.221
                C160.703,791.081,164.485,783.527,164.334,777.361z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(31)} fill={props.fillColors[31]}  d="M527.534,209.154c-4.242,8.789-8.226,17.9-13.02,26.564
                c-2.019,3.648-5.231,7.237-8.798,9.314c-9.545,5.558-14.707,14.665-20.767,23.169c-5.777,8.106-11.933,15.941-17.729,24.034
                c-2.302,3.215-4.58,6.605-6.021,10.252c-7.798,19.718-20.348,36.496-32.805,53.301c-2.284,3.081-6.03,5.161-9.301,7.414
                c-15.62,10.756-27.557,24.751-36.645,41.284c-0.128,0.233,0.03,0.624,0.109,1.651c6.441-3.139,12.695-6.187,19.496-9.5
                c-6.109,14.275-55.215,54.168-73.557,59.082c8.702-9.55,17.229-19.267,26.158-28.601c8.626-9.019,18.015-17.307,22.653-29.456
                c0.929-2.433,3.63-4.271,5.711-6.166c11.57-10.54,20.503-22.649,23.662-38.396c0.335-1.668,1.599-3.26,2.732-4.64
                c8.256-10.044,16.014-20.203,20.267-32.913c1.865-5.576,6.842-10.123,10.46-15.098c16.7-22.966,31.519-47.376,51.825-67.572
                c2.122-2.109,4.118-4.489,6.604-6.062C508.047,220.829,517.699,215.119,527.534,209.154z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(32)} fill={props.fillColors[32]}  d="M444.323,154.08c0.324-4.74,0.515-8.342,0.832-11.933
                c0.304-3.444-1.256-4.403-4.485-3.926c-7.984,1.18-14.886,4.67-21.028,9.723c-9.125,7.506-18.153,15.13-27.492,22.93
                c-6.742-5.395-14.188-4.544-21.671-2.048c-6.904,2.304-13.727,4.854-21.815,6.524c3.954-6.361,7.582-12.957,11.945-19.024
                c5.524-7.684,11.681-14.909,17.493-22.388c2.036-2.62,4.087-4.152,7.884-3.932c7.53,0.437,13.671-3.662,18.282-8.918
                c8.861-10.101,17.072-20.784,25.287-31.431c1.907-2.473,3.45-4.102,6.831-4.815c3.505-0.739,7.368-2.826,9.695-5.517
                c6.178-7.143,11.709-14.857,17.29-22.497c2.041-2.794,3.495-6.017,5.323-9.229c3.498,2.938,3.489,5.57,1.245,9.378
                c-5.141,8.723-9.487,17.909-14.48,26.724c-2.556,4.513-2.342,8.741,1.475,11.676c9.246,7.109,7.34,14.909,3.056,23.759
                c-5.065,10.463-9.368,21.295-14.017,31.961C445.667,151.799,445.233,152.446,444.323,154.08z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(33)} fill={props.fillColors[33]}  d="M641.257,820.123c-3.392-0.544-6.777-1.511-10.176-1.558
                c-12.765-0.175-25.533-0.038-38.3-0.052c-1.812-0.001-3.973,0.426-5.378-0.381c-8.125-4.662-16.836-3.322-25.397-2.701
                c-27.431,1.99-54.915,3.313-82.201-0.623c-10.567-1.524-20.942-3.155-31.637-2.635c-15.607,0.76-31.23,1.745-46.844,1.707
                c-13.288-0.032-26.585-1.221-39.853-2.229c-4.093-0.312-8.1-2.45-12.151-2.471c-11.602-0.057-23.205,0.568-34.81,0.876
                c-4.494,0.119-9.071-0.415-13.472,0.257c-10.279,1.571-17.462-3.468-24.437-10.608c5.126,1.679,9.769,3.323,14.492,4.685
                c1.88,0.541,3.989,0.794,5.902,0.527c1.132-0.158,2.087-1.589,3.122-2.446c-0.236-0.636-0.472-1.271-0.707-1.906
                c21.775,0,43.553-0.186,65.325,0.056c19.477,0.216,38.949,1.287,58.423,1.263c15.78-0.02,31.582-1.949,47.333-1.521
                c24.107,0.657,48.184,2.557,72.266,4.04c14.918,0.918,29.67,2.333,44.686-0.94c7.703-1.679,16.243-0.075,24.339,0.719
                c14.505,1.423,28.96,3.355,44.831,5.241c-5.965,4.1-10.721,7.367-15.473,10.638C641.146,820.061,641.257,820.123,641.257,820.123z
                "/>
            <path fill-rule="evenodd" onClick={() => props.onFill(34)} fill={props.fillColors[34]}  d="M261.863,718.549c7.245,2.03,13.617,3.816,19.989,5.602
                c0.357-0.689,0.714-1.378,1.071-2.067c-4.582-3.726-9.164-7.45-13.852-11.262c4.912-1.857,10.097-2.511,13.314-5.375
                c3.772-3.357,7.512-4.54,12.068-4.413c1.888,0.052,3.763,0.675,5.638,1.062c1.068,0.221,2.328,0.993,3.155,0.658
                c5.398-2.184,7.705,0.46,9.809,5.087c1.381,3.038,4.278,5.369,6.399,8.093c1.967,2.524,3.874,5.102,5.674,7.746
                c1.295,1.902,2.69,3.842,3.435,5.977c0.513,1.471,0.464,3.521-0.18,4.924c-1.114,2.426-1.503,4.319,0.898,6.112
                c0.619,0.462,1.476,1.51,1.313,1.97c-1.327,3.785-1.308,7.417-0.585,11.427c0.526,2.924-0.332,6.495-1.694,9.217
                c-0.854,1.703-4.021,3.599-5.743,3.27c-5.837-1.113-9.432-0.231-11.356,6.206c-0.872,2.914-3.856,5.448-8.121,5.188
                c-9.127-0.559-18.41,0.112-27.368-1.335c-3.971-0.642-7.31-5.2-10.936-7.981c0.274-0.392,0.548-0.782,0.823-1.174
                c6.431,1.697,12.862,3.395,19.293,5.092c0.362-0.595,0.723-1.189,1.085-1.783c-14.387-11.158-28.513-22.558-37.681-38.5
                c4.528,0.532,8.91,1.046,14.245,1.672c-1.417-3.453-2.212-5.389-3.146-7.666c3.304,0.681,6.234,1.284,9.164,1.888
                c0.201-0.354,0.401-0.708,0.602-1.062C266.883,724.432,264.59,721.744,261.863,718.549z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(35)} fill={props.fillColors[35]}  d="M1018.716,787.011c-0.853,0.305-1.704,0.609-2.557,0.914
                c-0.809,0.122-1.617,0.245-3.151,0.478c1.938-3.164,3.662-5.979,5.386-8.793c-0.216-0.271-0.431-0.542-0.646-0.813
                c-3.459,1.92-6.917,3.84-9.485,5.266c5.38-9.24,11.101-19.065,16.821-28.891c-0.503-0.459-1.005-0.917-1.508-1.376
                c-1.952,0.834-3.904,1.667-6.527,2.787c1.637-2.851,2.911-5.071,4.186-7.292c-0.222-0.286-0.444-0.572-0.667-0.858
                c-3.202,1.875-6.404,3.75-10.243,5.997c7.115-10.986,15.087-20.674,15.379-34.872c-3.024,2.201-5.344,3.889-8.543,6.218
                c1.766-3.534,3.036-6.077,4.307-8.62c-0.297-0.302-0.593-0.604-0.89-0.906c-2.047,1.405-4.094,2.81-5.499,3.774
                c3.731-7.42,7.774-15.457,11.817-23.495c-0.434-0.305-0.867-0.609-1.301-0.914c-1.221,1.487-2.44,2.975-3.662,4.464
                c0,0.001,0.048-0.04,0.046-0.039c0.113-1.039-0.059-2.225,0.387-3.095c3.703-7.234,7.52-14.411,11.691-22.345
                c1.765,12.548,12.075,21.648,11.718,35.477c-3.093-1.78-5.719-3.292-9.58-5.515c0.779,2.769,0.832,4.803,1.825,6.14
                c2.955,3.979,5.724,8.433,9.598,11.309c4.919,3.651,5.644,8.888,7.585,13.832c-3.56-1.145-7.089-2.279-11.979-3.852
                c1.605,3.594,2.183,7.145,4.263,9.093c5.104,4.783,5.425,11.52,8.624,17.739c-3.162-1.803-5.805-3.309-8.447-4.815
                c2.395,10.479,14.868,14.172,15.215,25.535c-3.89-2.075-8.383-4.473-13.256-7.072c-0.241,1.401-0.39,2.268-0.573,3.335
                c-2.426-1.26-4.73-2.457-7.034-3.653c-0.328,0.358-0.656,0.717-0.983,1.076c7.339,17.922,24.425,26.793,37.786,40.273
                c-23.054-1.234-46.108-2.47-68.494-3.669c4.068-8.69,7.893-16.858,11.716-25.026c-0.415-0.237-0.83-0.475-1.246-0.711
                C1020.101,785.065,1019.408,786.038,1018.716,787.011z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(36)} fill={props.fillColors[36]}  d="M675.239,363.74c16.684-5.007,32.287-10.071,48.14-14.172
                c3.875-1.003,8.832,0.462,12.826,2.002c7.571,2.921,14.702,6.974,22.238,10.005c2.517,1.012,6.173,1.336,8.508,0.251
                c7.122-3.308,13.688-2.956,20.469,0.639c3.348,1.774,6.954,3.06,11.205,4.895c-10.628,7.896-20.635,15.478-30.854,22.76
                c-1.48,1.055-4.144,1.312-5.986,0.851c-7.842-1.968-15.334-1.084-23.11,0.829c-10.79,2.654-21.793,4.521-32.783,6.219
                c-2.39,0.369-5.565-0.809-7.571-2.335c-2.99-2.276-5.621-5.248-7.767-8.363C685.474,379.946,680.771,372.315,675.239,363.74z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(37)} fill={props.fillColors[37]}  d="M458.665,739.4c-2.518,2.274-4.406,5.635-0.515,10.047
                c0.62,0.703-0.732,4.206-2.077,5.369c-3.11,2.688-3.928,5.299-2.447,9.208c0.666,1.759,0.62,4.857-0.49,5.997
                c-2.338,2.401-4.679,5.828-9.261,3.956c-2.239-0.915-4.827-1.007-7.271-1.377c-1.636-0.248-3.817,0.289-4.863-0.588
                c-6.237-5.229-11.909-1.538-17.483,0.969c-3.203,1.44-5.181,0.606-6.652-2.026c-1.743-3.119-4.042-4.215-7.706-4.18
                c-7.57,0.071-12.776-8.183-12.263-17.164c0.364-6.374,0.416-12.71,3.16-18.566c0.874-1.866,2.63-4.541,4.156-4.663
                c5.488-0.438,7.018-4.834,9.851-8.12c1.924-2.231,5.02-3.528,6.694-5.882c2.308-3.242,4.614-2.961,8.081-2.412
                c4.492,0.712,9.211,0.082,13.827-0.103c3.821-0.152,6.427,1.145,8.639,4.57c3.069,4.752,7.181,8.82,10.428,13.473
                C454.708,731.114,456.234,734.814,458.665,739.4z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(38)} fill={props.fillColors[38]}  d="M829.825,1178.276c1.371,5.711,2.742,11.423,4.113,17.134
                c-0.476-0.011-0.952-0.021-1.428-0.031c-0.517,1.506-1.778,3.216-1.433,4.487c3.1,11.386-1.175,21.769-6.197,30.985
                c-2.789,5.117-1.521,8.747-1.239,13.18c0.727,11.437,3.511,22.271,8.928,32.435c1.064,1.997,1.326,4.421,1.943,6.593
                c-0.479,0.293-0.776,0.631-1.005,0.591c-8.353-1.48-9.026-0.922-8.925,7.674c0.039,3.328,0.814,6.801,0.097,9.943
                c-0.804,3.521-2.104,7.639-4.619,9.923c-7.078,6.431-24.289,6.497-32.697,0.729c1.518-3.059,3.005-6.189,4.613-9.257
                c1.518-2.894,3.164-5.72,4.753-8.575c-0.392-0.306-0.782-0.61-1.173-0.916c-2.678,3.824-5.729,7.449-7.914,11.537
                c-1.766,3.303-3.875,5.093-7.6,5.039c0.958-6.61,5.896-12.611,10.188-11.994c-1.108-5.906,1.132-10.63,5.048-14.812
                c5.262-5.621,5.979-12.902,6.593-19.999c0.745-8.609,0.773-17.279,1.247-25.915c0.097-1.766,0.793-3.499,2.355-5.097
                c0.53,2.762,1.061,5.522,1.59,8.283c0.479-0.055,0.957-0.108,1.436-0.163c-0.143-2.121,0.397-4.638-0.559-6.288
                c-2.733-4.713-0.243-8.727,1.313-12.638c4.606-11.568,9.58-22.993,14.562-34.409c1.294-2.965,3.14-5.688,4.731-8.523
                C828.973,1178.22,829.399,1178.248,829.825,1178.276z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(39)} fill={props.fillColors[39]}  d="M998.419,1092.258c2.135,18.136,1.889,35.032-2.073,52.01
                c-2.048,8.774-1.766,18.104-2.418,27.189c-0.447,6.235-0.445,12.51-1.078,18.723c-1.16,11.394-2.641,22.754-4.045,34.613
                c-6.823,1.738-13.777,7.743-22.612,2.678c4.854,5.522,8.435,5.449,22.121,0.182c-4.267,6.764-7.777,13.393-12.383,19.147
                c-2.054,2.565-6.307,3.37-9.562,4.975c-0.272-0.425-0.545-0.851-0.816-1.275c1.758-3.987,3.516-7.976,5.273-11.963
                c-6.285,1.805-5.134,11.653-12.779,11.038c1.494-6.868,2.553-13.714,4.57-20.264c1.192-3.869,3.625-7.464,5.978-10.85
                c10.47-15.068,15.159-31.312,10.787-49.58c-0.269-1.124-0.374-2.313-0.375-3.473c-0.004-14.654-2.651-28.766-8.625-41.479
                C979.787,1113.304,988.799,1103.125,998.419,1092.258z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(40)} fill={props.fillColors[40]}  d="M335.736,70.091c-22.443,21.41-45.329,42.089-60.164,69.952
                c4.603-2.664,9.205-5.328,14.261-8.255c-7.192,15.176-33.263,38.548-50.34,45.145c0.589-1.508,0.971-2.635,1.461-3.713
                c4.588-10.097,9.365-20.113,13.712-30.312c1.741-4.084,2.895-8.502,3.708-12.881c2.041-10.979,10.398-17.87,16.54-26.159
                c8.316-11.223,16.942-22.216,25.54-33.226c0.906-1.159,2.555-2.559,3.8-2.504C314.401,68.585,324.533,69.352,335.736,70.091z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(41)} fill={props.fillColors[41]}  d="M2.543,982.795c10.628,0,20.774-0.201,30.909,0.057
                c9.943,0.254,19.874,0.976,29.979,2.595c-15.755,2.476-31.51,4.951-47.266,7.427c-0.05,0.616-0.1,1.232-0.149,1.849
                c6.654,1.048,13.308,2.095,21.135,3.327c-6.583,1.299-12.236,2.413-17.889,3.527c-0.035,0.387-0.07,0.774-0.105,1.161
                c2.549,0.886,5.098,1.771,8.645,3.003c-5.046,1.278-9.242,2.341-13.438,3.403c-0.035,0.316-0.07,0.633-0.105,0.948
                c3.218,0.925,6.436,1.849,10.324,2.966c-2.179,1.272-3.801,2.221-6.145,3.59c10.634,7.222,23.223,6.591,35.111,11.47
                c-12.451,7.274-26.121,5.571-39.753,9.704c10.324,5.521,21.033,4.634,31.093,9.094c-6.855,2.856-13.711,5.712-20.973,8.737
                c1.517,1.449,2.796,2.673,4.523,4.324c-6.925,7.652-15.093,12.983-25.897,14.932C2.543,1044.304,2.543,1013.999,2.543,982.795z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(42)} fill={props.fillColors[42]}  d="M865.101,394.006c-5.698,14.389-15.636,25.97-28.696,34.911
                c-3.264,2.234-6.916,3.937-10.494,5.668c-3.397,1.644-6.487,1.988-8.919-2.028c-2.229-3.682-5.579-3.884-9.5-2.252
                c-4.55,1.895-9.317,3.265-15.213,5.279c6.949-8.361,13.208-15.517,19.03-23.011c5.269-6.779,10.566-13.63,14.909-21.005
                c3.775-6.411,3.033-9.172-2.647-13.837c-3.096-2.543-3.172-4.726-1.252-7.494c3.484-5.025,7.14-9.931,10.489-14.562
                C843.578,368.459,854.134,380.988,865.101,394.006z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(43)} fill={props.fillColors[43]}  d="M801.954,1319.416c-9.005,2.891-18.454,3.962-26.975,9.074
                c-4.252,2.551-10.193,2.328-15.389,3.254c-4.857,0.866-9.739,1.593-14.99,2.441c0-2.725,0-5.926,0-9.081
                c10.59,3.344,10.896,3.372,11.977-8.358c0.717-7.772,0.083-15.663,0.639-23.459c0.543-7.603,1.829-15.152,2.79-22.725
                c0.704-5.541,4.35-8.424,9.308-9.794c6.864-1.896,12.715-5.146,18.211-9.852c1.849-1.583,5.751-1.723,7.562-4.509
                c1.688-2.596,3.175-5.323,4.75-7.992c0.497,0.055,0.994,0.11,1.491,0.166c-1.57,12.391-3.119,24.783-4.754,37.165
                c-0.123,0.934-0.771,1.841-1.314,2.669c-4.982,7.585-10.011,15.14-14.989,22.727c-1.093,1.666-2.2,3.354-3.022,5.158
                c-2.605,5.721-1.754,6.892,4.536,6.527c0.646-0.037,1.648-0.365,1.884-0.072C788.55,1318.807,796.097,1317.404,801.954,1319.416z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(44)} fill={props.fillColors[44]}  d="M311.221,777.338c1.943-7.294,6.377-10.908,14.225-8.513
                c0.583,0.179,1.561-0.522,2.199-1.017c1.435-1.112,2.619-2.653,4.194-3.448c1.395-0.703,3.18-0.634,4.457-0.849
                c0.812-2.831,1.743-5.269,2.188-7.791c0.945-5.374,4.671-5.525,8.623-4.515c3.532,0.902,6.479,1.214,8.679-2.406
                c0.41-0.675,2.062-1.423,2.463-1.148c5.862,4.023,7.915-2.042,10.677-4.501c2.748-2.446,4.749-2.343,7.49-0.939
                c3.09,1.581,6.217,3.09,9.131,4.533c0.522,3.691,0.817,7.444,1.618,11.086c1.725,7.848,5.782,10.819,14.039,11.042
                c1.273,0.034,3.082,0.786,3.68,1.783c3.088,5.152,6.67,7.605,12.613,2.207c0.9,3.936,1.576,6.893,2.3,10.055
                C382.903,787.368,346.896,779.07,311.221,777.338z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(45)} fill={props.fillColors[45]}  d="M720.337,803.387c-4.764,1.529-9.505,3.136-14.298,4.571
                c-8.887,2.662-17.667,1.252-26.561-0.382c-3.797-0.697-7.952,0.795-11.86,0.419c-17.518-1.683-34.978-4.005-52.512-5.449
                c-11.501-0.947-22.88-1.929-34.695-0.157c-12.648,1.896-25.824,0.359-38.768,0.157c-4.231-0.066-8.454-0.644-12.679-1.739
                c8.858,0,17.718,0,26.576,0c0.032-0.575,0.064-1.149,0.098-1.724c-38.4-4.347-76.801-8.693-110.63-12.522
                c11.159,0,26.849-0.584,42.479,0.121c32.57,1.469,65.106,3.695,97.665,5.464c26.754,1.453,53.541,2.369,80.272,4.141
                c18.18,1.206,36.287,3.479,54.428,5.274C720.015,802.169,720.176,802.777,720.337,803.387z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(46)} fill={props.fillColors[46]}  d="M272.998,783.485c3.296,0.723,6.591,1.446,9.887,2.169
                c0.242-0.449,0.483-0.897,0.725-1.346c-1.839-1.54-3.679-3.079-5.685-4.759c29.599-3.125,139.357,9.223,157.499,19.417
                c-4.06,0.336-7.269,0.871-10.469,0.824c-29.309-0.43-58.613-1.152-87.923-1.372c-13.137-0.1-26.281,0.752-39.423,1.162
                c-0.812,0.026-1.815,0.275-2.413-0.093c-7.72-4.762-15.382-9.615-23.059-14.446C272.424,784.523,272.711,784.004,272.998,783.485z
                "/>
            <path fill-rule="evenodd" onClick={() => props.onFill(47)} fill={props.fillColors[47]}  d="M119.923,710.009c-7.109-4.687-13.741-9.866-18.611-17.995
                c5.241,1.092,9.822,2.046,14.404,3c0.214-0.356,0.428-0.714,0.642-1.071c-6.688-5.278-15.49-8.375-18.918-17.398
                c1.5-0.07,2.939-0.139,5.355-0.253c-4.087-3.816-7.498-7.002-11.485-10.725c4.389,0.648,7.836,1.158,11.284,1.667
                c0.233-0.416,0.466-0.833,0.698-1.249c-1.189-1.604-2.377-3.208-3.554-4.795c1.012-3.345,2.092-6.914,3.293-10.883
                c-1.816-0.265-3.013-0.439-4.418-0.645c0.535-1.01,0.989-1.866,1.443-2.723l-0.063,0.085c1.122,0,2.243,0,3.294,0
                c1.446-11.477,2.849-22.604,4.429-35.138c3.867,8.11,7.221,15.143,10.93,22.922c-1.434-0.557-2.28-0.885-4.154-1.613
                c1.349,2.946,2.576,4.918,3.156,7.063c0.781,2.888,4.701,5.383,1.294,9.017c-0.349,0.371,0.571,2.136,1.132,3.123
                c3.513,6.178,7.091,12.318,10.916,18.935c-3.504,2.955-7.519,6.341-11.534,9.727c0.127,0.511,0.255,1.021,0.382,1.532
                c5.448-0.965,10.896-1.931,17.538-3.107c-3.12,2.884-5.57,5.149-7.388,6.83c0.215,2.279,0.381,4.034,0.589,6.233
                c2.216,0,4.909,0,9.009,0c-5.956,4.988-11.158,9.278-16.271,13.672c-1.3,1.118-2.317,2.566-3.464,3.862L119.923,710.009z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(48)} fill={props.fillColors[48]}  d="M109.785,846.962c-3.912-6.086-5.63-6.628-11.967-3.607
                c-6.951,3.313-13.906,6.152-21.93,5.635c-1.777-0.114-4,1.923-5.51,3.466c-2.536,2.594-4.396,5.877-7.048,8.318
                c-2.188,2.015-4.105,1.78-6.229-1.401c-3.446-5.162-7.733-9.857-12.19-14.213c-2.696-2.635-6.712-3.893-9.518-6.447
                c-2.204-2.007-3.462-5.053-5.3-7.884c1.835-3.425,3.9-5.777,8.587-3.862c1.492,0.609,4.288,0.018,5.517-1.096
                c3.407-3.088,6.501-2.12,9.41,0.129c3.215,2.486,5.567,2.019,8.03-0.973c1.36-1.651,2.986-3.204,4.781-4.349
                c3.135-1.999,7.361-0.531,10.167,3.433c2.779,3.927,5.491,7.342,10.94,3.744c0.693-0.458,2.824,0.438,3.75,1.292
                c5.067,4.674,10.622,6.479,17.405,4.187c1.295-0.438,4.325,0.341,4.412,0.895c0.487,3.109,0.656,6.371,0.204,9.475
                C113.12,844.917,111.045,845.854,109.785,846.962z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(49)} fill={props.fillColors[49]}  d="M195.94,698.982c-2.454,4.441-4.574,8.277-7.338,13.279
                c3.593-0.613,5.913-1.009,9.14-1.559c-5.618,8.024-12.588,13.654-20.922,17.849c0.159,0.64,0.318,1.279,0.477,1.919
                c5.744,0.301,11.487,0.602,16.847,0.882c-1.626,2.871-1.622,2.809-6.911,2.473c-2.499-0.159-5.016-0.027-7.509-0.027
                c-4.742-2.279-9.841-4.729-16.349-7.857c3.456-0.472,5.546-0.758,7.637-1.043c0.363-0.351,0.727-0.701,1.09-1.052
                c-1.739-0.729-3.477-1.456-5.216-2.185c0.369-0.342,0.738-0.684,1.107-1.025c2.863,0.591,5.727,1.181,8.59,1.771
                c0.191-0.446,0.382-0.894,0.573-1.34c-4.088-4.607-8.175-9.215-12.263-13.822c0.216-0.27,0.433-0.539,0.649-0.809
                c4.244,1.751,8.489,3.502,12.733,5.253c0.213-0.364,0.426-0.729,0.64-1.094c-6.957-7.284-13.914-14.568-20.871-21.853
                c0.223-0.22,0.445-0.438,0.667-0.657c6.34,3.468,12.68,6.937,19.02,10.405c0.435-0.577,0.87-1.154,1.305-1.731
                c-4.495-4.997-8.989-9.993-14.394-16.002c4.999,2.278,8.64,3.938,12.281,5.597c0.341-0.416,0.683-0.832,1.024-1.248
                c-3.559-4.502-7.118-9.003-10.677-13.505c0.17-0.194,0.34-0.389,0.51-0.583c1.82,1.006,3.64,2.011,5.598,3.093
                c2.172-7.849,4.262-15.399,6.409-23.161c-0.908,0.173-1.792,0.341-2.395,0.456c2.355-5.428,4.764-10.979,7.052-16.252
                c3.125,6.963,6.437,14.347,10.029,22.353c-2.027,2.328-4.588,5.271-7.149,8.213c0.247,0.396,0.494,0.793,0.74,1.189
                c3.034-1.182,6.067-2.362,10.554-4.11c-3.923,5.562-7.122,10.099-11.022,15.631c6.086-1.72,10.959-3.097,17.214-4.865
                c-8.346,9.836-15.827,18.651-23.307,27.467c0.128,0.343,0.255,0.686,0.383,1.028C186.356,701.08,190.828,700.102,195.94,698.982z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(50)} fill={props.fillColors[50]}  d="M330.414,748.695c2.836-4.189,3.363-7.97-1.428-10.894
                c2.61-4.367,3.821-7.944-0.985-11.567c-1.427-1.075-1.646-5.533-0.656-7.596c1.336-2.785,4.39-3.329,8.088-3.128
                c6.444,0.351,12.976-0.934,19.475-1.484c1.073-0.092,2.229-0.158,3.239,0.133c5.39,1.55,10.775,3.129,16.089,4.916
                c1.058,0.355,1.729,1.743,2.695,2.509c2.27,1.803,4.385,4.443,6.969,5.067c4.937,1.192,6.173,3.482,3.647,8.279
                c-1.454,2.763-2.238,5.878-3.431,9.126c-1.463-0.708-3.927-1.646-6.119-3.018c-4.03-2.521-7.531-3.254-10.785,1.283
                c-1.216,1.695-3.219,2.826-5.129,4.439c-3.133-1.513-6.34-2.18-8.862,1.715c-0.518,0.799-2.396,1.312-3.45,1.076
                c-11.579-2.583-12.28,0.6-15.455,11.932c-1.291,0-2.671,0-4.098,0C328.846,756.928,336.233,752.937,330.414,748.695z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(51)} fill={props.fillColors[51]}  d="M55.66,861.501c-18.491-1.189-35.829-2.304-53.341-3.43c0-12.073,0-23.864,0-36.331
                c1.082,0.829,1.814,1.478,2.628,1.998c1.93,1.232,4.017,3.574,5.831,3.392c6.902-0.695,11.48,4.049,17.084,6.319
                c0.307,0.125,0.798,0.246,0.857,0.463c1.849,6.814,9.184,7.54,13.217,11.933c3.359,3.657,6.918,7.131,10.287,10.779
                C53.353,857.847,54.17,859.358,55.66,861.501z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(52)} fill={props.fillColors[52]}  d="M151.311,799.298c-2.507-3.102-7.541-3.979-5.935-9.451
                c2.533-8.633,9.394-4.392,14.11-5.326c2.389,3.434,4.705,6.761,7.02,10.089c0.443-0.198,0.887-0.396,1.331-0.594
                c-0.637,1.678-1.063,3.478-1.956,5.006c-1.684,2.88-3.281,5.642,0.052,8.382c3.279,2.696,6.391,2.013,9.328-1
                c0.544,1.981,0.726,3.81,1.523,5.311c1.657,3.119,3.883,4.3,7.217,1.737c5.66-4.35,5.734-4.254,10.106,1.319
                c-3.233,3.967-6.178,8.016-9.582,11.633c-3.266,3.471-6.972,0.948-9.312-1.082c-3.165-2.745-4.958-3.693-9.531-1.785
                c-8.025,3.349-14.478-1.073-16.95-9.69c-0.272-0.948-0.62-1.903-1.109-2.755C143.865,804.539,144.151,803.441,151.311,799.298z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(53)} fill={props.fillColors[53]}  d="M119.85,710.082c5.84-2.183,11.681-4.365,17.692-6.612
                c-3.225,10.828-13.251,17.785-18.323,28.793c3.834-0.254,7.164-0.475,12.086-0.801c-2.581,1.523-4.107,2.424-5.633,3.324
                c0.249,0.347,0.499,0.693,0.748,1.04c1.578,0,3.156,0,5.173,0c-3.002,8.511-10.608,12.334-16.493,17.682
                c-7.334-3.625-14.493-7.162-21.652-10.7c0.138-0.704,0.276-1.408,0.415-2.112c8.695,0,17.389,0,26.083,0
                c0.176-0.694,0.351-1.39,0.527-2.084c-12.484-2.892-22.045-9.877-29.575-19.774c3.969-2.688,7.764-1.132,11.774,0.046
                c4.896,1.438,9.975,2.252,14.975,3.335c0.177-0.515,0.353-1.029,0.53-1.544c-2.953-2.422-5.906-4.844-9.851-8.08
                c4.319,0.216,7.316,0.486,10.312,0.477c2.45-0.008,3.003-1.139,1.288-3.062C119.923,710.009,119.85,710.082,119.85,710.082z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(54)} fill={props.fillColors[54]}  d="M334.043,33.884c9.669-6.072,18.813-10.063,29.354-9.774
                c9.272,0.254,18.54,0.722,27.813,0.882c3.147,0.055,4.879,1.112,5.602,4.277c0.991,4.338,2.326,8.597,3.451,12.665
                c-12.67,4.152-24.384,2.463-36.297-2.083c-6.504-2.482-13.876-2.675-20.845-3.965C340.41,35.384,337.732,34.704,334.043,33.884z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(55)} fill={props.fillColors[55]}  d="M469.604,950.192c-1.164,5.731-2.339,10.683-3.149,15.692
                c-1.351,8.346-1.056,16.693,2.646,24.386c1.745,3.626,4.367,7.224,7.481,9.726c11.805,9.482,9.934,9.005,5.173,22.644
                c-12.106-2.075-23.503-18.58-25.482-36.903C454.501,969.337,459.909,953.969,469.604,950.192z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(56)} fill={props.fillColors[56]}  d="M740.684,1328.803c1.718,8.635-0.764,14.53-8.653,17.349
                c-7.29,2.604-14.949,4.452-22.598,5.696c-6.117,0.995-8.056-1.696-6.046-7.666c2.051-6.088,4.758-11.955,7.008-17.502
                C720.992,1327.423,730.851,1328.113,740.684,1328.803z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(57)} fill={props.fillColors[57]}  d="M390.449,786.062c45.552-1.387,92.82,8.645,140.618,12.563
                c-14.349,0-28.696,0.08-43.044-0.025c-10.648-0.079-21.319-0.921-31.938-0.462c-10.789,0.468-20.837-1.879-31.102-4.695
                C412.89,790.126,400.431,788.142,390.449,786.062z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(58)} fill={props.fillColors[58]}  d="M1073.974,1072.027c5.812,15.147,12.51,30.035,17.065,45.552
                c2.287,7.79,1.271,16.771,0.629,25.126c-0.456,5.949-4.432,10.701-9.36,14.19c-1.673,1.185-3.79,1.742-5.871,2.126
                c10.083-6.484,15.835-16.888,13.416-26.737c-0.412,1.927-1.07,3.837-1.192,5.781c-0.411,6.559-6.34,8.784-10.594,13.796
                c2.148-5.453,3.898-9.895,5.648-14.336c-0.432-0.193-0.863-0.386-1.295-0.579c-1.83,3.551-3.661,7.102-5.592,10.848
                c-1.985-7.317,4.573-10.862,6.467-16.522c-1.524,1.352-3.049,2.703-4.573,4.055c-0.219-0.139-0.437-0.278-0.655-0.418
                c1.256-2.03,2.5-4.067,3.77-6.089c5.566-8.862,5.814-17.934,2.042-27.658c-3.708-9.561-6.601-19.438-9.851-29.176
                L1073.974,1072.027z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(59)} fill={props.fillColors[59]}  d="M480.51,1026.588c-4.553,10.691-2.774,20.888,2.301,30.736
                c-8.708-0.342-19.205-11.673-18.774-20.622C464.4,1029.149,472.989,1023.777,480.51,1026.588z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(60)} fill={props.fillColors[60]}  d="M703.17,1321.745c1.77,2.644,2.938,4.388,4.429,6.614
                c-4.812,3.714-2.327,15.11-14.906,13.32C696.312,1334.794,699.688,1328.37,703.17,1321.745z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(61)} fill={props.fillColors[61]}  d="M193.607,819.436c0.397,4.896,1.108,8.657-2.997,11.568
                c-1.421,1.007-1.066,4.245-2.325,5.823c-1.652,2.07-4.098,3.507-6.881,5.768C180.47,831.45,187.828,826.393,193.607,819.436z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(62)} fill={props.fillColors[62]}  d="M102.966,628.947c-0.461,4.291-0.922,8.583-1.271,11.818
                c-4.67-4.001-9.614-8.237-15.45-13.237c6.547,0.585,11.676,1.043,16.805,1.501L102.966,628.947z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(63)} fill={props.fillColors[63]}  d="M1135.18,717.195c-1.683-6.572-3.365-13.145-5.324-20.793
                c3.299,0.375,5.838,0.664,9.283,1.056c-0.962,3.241-1.771,5.968-2.856,9.62c1.647-0.949,2.486-1.433,3.325-1.916
                c0.424,0.218,0.847,0.435,1.271,0.651c-1.929,3.768-3.857,7.536-5.783,11.308C1135.098,717.123,1135.18,717.195,1135.18,717.195z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(64)} fill={props.fillColors[64]}  d="M100.055,646.939c-1.853,1.172-3.706,2.344-6.757,4.273
                c3.089,0.811,4.691,1.231,7,1.837c-0.729,2.254-1.443,4.466-2.654,8.21c-4.863-5.834-8.813-10.572-12.763-15.312
                c0.259-0.319,0.518-0.639,0.777-0.958c2.511,1.269,5.021,2.536,8.038,4.061c0-1.93,0-3.243,0-4.993
                c2.25,1.061,4.273,2.014,6.297,2.967L100.055,646.939z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(65)} fill={props.fillColors[65]}  d="M1074.027,1071.985c-5.146-14.637-9.012-29.437-5.809-45.137
                c0.543,8.437,0.727,16.917,1.743,25.297c0.812,6.69,2.637,13.259,4.012,19.881
                C1073.974,1072.027,1074.027,1071.985,1074.027,1071.985z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(66)} fill={props.fillColors[66]}  d="M207.822,833.036c-5.134-4.421-1.544-8.919-1.189-13.41
                C212.77,822.504,213.245,827.457,207.822,833.036z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(67)} fill={props.fillColors[67]}  d="M205.067,810.278c-1.645,5.894-3.011,10.789-4.588,16.438
                C197.593,818.953,198.663,814.984,205.067,810.278z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(68)} fill={props.fillColors[68]}  d="M169.385,825.196c0,3.655,0,7.312,0,11.673
                C165.802,831.054,165.982,826.89,169.385,825.196z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(69)} fill={props.fillColors[69]}  d="M175.769,828.891c1.354,3.689,2.485,6.768,3.85,10.485
                C173.694,836.971,173.312,835.852,175.769,828.891z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(70)} fill={props.fillColors[70]}  d="M420.109,773.439c2.154,3.759,3.699,6.455,5.609,9.788
                C421.179,782.798,420.891,782.233,420.109,773.439z"/>
            <path fill-rule="evenodd" d="M672.83,699.754c5.491-20.138,19.251-36.003,28.511-53.229c-8.978-0.714-18.074-2.508-27.021-1.939
                c-23.412,1.487-46.606,4.932-69.369,10.893c-5.262,1.379-10.635,2.333-16.86,3.678c0.156-2.733-0.229-4.955,0.476-6.747
                c3.031-7.71,6.206-15.374,9.668-22.899c3.325-7.227,7.069-14.261,10.441-21.004c-4.957-1.065-9.629-1.664-13.941-3.243
                c-1.292-0.473-2.599-3.957-2.158-5.592c2.103-7.791,3.788-15.957,7.506-22.994c7.612-14.406,18.4-26.298,31.538-36.246
                c15.787-11.955,30.882-24.819,46.449-37.071c12.13-9.548,24.524-18.761,37.218-28.44c1.012,1.501,2.175,3.227,4.096,6.077
                c20.862-12.807,41.793-25.655,63.784-39.154c0,3.276,0.271,5.396-0.053,7.422c-0.854,5.319,1.771,8.247,6.457,9.594
                c4.267,1.226,8.647,2.057,12.981,3.044c1.098,0.25,2.801,0.052,3.224,0.707c4.178,6.462,10.429,11.916,10.369,20.522
                c-0.018,2.499,0.271,5.023,0.001,7.491c-0.576,5.263,0.393,9.25,6.052,11.465c1.537,0.602,3.262,4.22,2.789,5.837
                c-1.699,5.82-3.454,11.938-6.752,16.906c-5.502,8.289-11.599,16.377-18.543,23.476c-12.763,13.045-23.992,27.76-39.595,37.862
                c-1.944,1.259-3.622,2.928-5.446,4.377c-2.682,2.131-3.269,4.217-2.524,8.034c0.821,4.211,0.128,9.553-1.906,13.322
                c-5.505,10.201-10.968,20.776-18.447,29.486c-14.849,17.292-30.973,33.495-46.733,49.992c-3.328,3.483-7.36,6.296-11.068,9.417
                C673.59,700.449,673.21,700.102,672.83,699.754z"/>
            <path fill-rule="evenodd" d="M619.058,1066.562c1.031,6.644,2.023,13.036,3.016,19.429c-0.466,0.057-0.932,0.113-1.398,0.17
                c-2.692-8.87-5.385-17.74-8.307-27.368c1.6,0.799,2.895,1.628,4.309,2.115c4.058,1.396,8.194,3.859,12.244,3.755
                c15.806-0.407,31.127-3.175,44.017-13.425c1.682-1.337,3.374-2.812,4.587-4.554c2.191-3.147,2.254-6.402-1.408-8.564
                c-5.863-3.462-11.72-7.001-17.867-9.895c-5.755-2.708-11.751-2.679-17.926-0.294c-5.396,2.083-11.104,3.473-16.782,4.66
                c-2.914,0.609-6.061,0.107-9.103,0.107c-0.091-0.405-0.181-0.81-0.271-1.215c1.227-0.39,2.435-0.852,3.682-1.157
                c7.557-1.852,15.229-3.318,22.647-5.606c5.67-1.749,11.265-2.455,16.551,0.014c7.346,3.429,14.576,7.243,21.405,11.598
                c5.022,3.203,5.248,9.372-0.028,13.593c-6.27,5.016-13.164,9.769-20.553,12.721c-7.443,2.975-15.767,4.071-23.819,5.075
                C629.427,1068.296,624.575,1067.054,619.058,1066.562z"/>
            <path fill-rule="evenodd" d="M610.242,1145.874c2.604-3.836,5.061-7.784,7.872-11.461c1.477-1.929,4.366-3.056,5.218-5.121
                c1.427-3.463,1.751-7.384,2.504-11.12c0.218-1.083,0.314-2.191,1.634-3.5c0.335,1.537,0.67,3.074,1.12,5.137
                c5.734-15.167-3.923-28.432-4.251-42.531c5.801,13.57,11.908,27.238,7.169,42.295c-1.907,6.059-5.221,11.86-8.832,17.138
                c-2.945,4.306-7.241,7.703-11.086,11.349c-0.331,0.313-1.767-0.537-2.689-0.848C608.659,1146.075,608.978,1145.5,610.242,1145.874
                z"/>
            <path fill-rule="evenodd" d="M1022.262,909.634c0.927,1.236,2.272,2.342,2.71,3.732c2.472,7.856,5.199,15.681,6.912,23.714
                c1.515,7.099,1.635,14.482,2.695,21.692c1.476,10.04,1.218,19.89-2.23,29.521c-0.35,0.979-0.954,1.865-2.057,3.973
                c-0.314-2.01-0.674-2.778-0.495-3.39c3.648-12.478,2.844-24.858,0.198-37.457c-2.034-9.692-2.554-19.694-4.318-29.456
                c-0.731-4.042-2.955-7.815-4.5-11.711C1021.538,910.046,1021.9,909.84,1022.262,909.634z"/>
            <path fill-rule="evenodd" d="M550.364,1148.803c0.577,2.026,1.068,4.069,1.743,6.05c1.378,4.043,0,6.733-3.84,8.354
                c-1.362,0.575-3.146,0.867-3.959,1.901c-2.862,3.64-5.305,1.805-7.257-0.562c-1.574-1.907-3.607-4.69-3.204-6.617
                c0.387-1.85,3.484-3.414,5.68-4.538C542.971,1151.629,546.656,1150.342,550.364,1148.803z"/>
            <path fill-rule="evenodd" d="M598.344,1059.248c-0.134,6.049-3.196,9.754-6.591,8.792c-2.506-0.711-5.562-2.688-6.502-4.898
                c-1.159-2.726,5.066-8.556,8.134-7.679C595.576,1056.09,597.288,1058.395,598.344,1059.248z"/>
            <path fill-rule="evenodd" d="M646.953,1094.9c0.838,1.604,1.514,3.322,2.539,4.797c7.068,10.169,5.605,21.35,3.937,32.55
                c-0.127,0.855-0.763,1.635-1.811,2.458c1.516-14.604-1.177-28.312-7.282-41.579c0.903,0.624,1.807,1.248,2.711,1.872
                L646.953,1094.9z"/>
            <path fill-rule="evenodd" d="M855.672,906.746c3.992,5.666,10.693,9.524,10.646,17.931c-0.009,1.488,2.049,2.928,2.964,4.5
                c0.844,1.45,1.435,3.047,2.092,4.48c-9.571-9.321-19.018-18.521-28.463-27.719c0.076-0.161,0.152-0.322,0.229-0.483
                c6.698,5.111,13.396,10.223,21.116,16.115c-3.657-5.328-6.664-9.707-9.67-14.086C854.947,907.238,855.31,906.992,855.672,906.746z
                "/>
            <path fill-rule="evenodd" d="M1045.32,927.683c3.439,6.215,7.422,12.187,4.435,19.789c-0.429,1.091-0.019,2.546,0.12,3.819
                c0.773,7.104-1.243,13.407-5.138,19.237C1047.67,956.252,1051.29,942.104,1045.32,927.683z"/>
            <path fill-rule="evenodd" d="M716.407,1123.29c6.731,6.886,10.29,24.747,6.365,31.637
                C720.665,1144.451,718.545,1133.913,716.407,1123.29z"/>
            <path fill-rule="evenodd" d="M829.751,1053.606c6.621,4.571,18.033,30.143,16.665,36.115
                C840.951,1077.879,835.574,1066.227,829.751,1053.606z"/>
            <path fill-rule="evenodd" d="M714.51,1184.648c1.404-9.806,2.81-19.611,4.305-30.049
                C722.095,1160.809,719.867,1179.055,714.51,1184.648z"/>
            <path fill-rule="evenodd" d="M678.387,1120.645c7.646,7.995,13.06,25.188,10.093,30.584
                C685.215,1141.335,682.02,1131.654,678.387,1120.645z"/>
            <path fill-rule="evenodd" d="M857.062,925.638c10.958,5.425,20.485,24.971,15.663,34.334
                C872.199,945.445,866.005,934.871,857.062,925.638z"/>
            <path fill-rule="evenodd" d="M847.645,1026.411c2.192-4.442,4.744-8.75,6.479-13.365c1.773-4.72,2.746-9.741,4.147-14.916
                C861.313,1005.697,855.735,1021.576,847.645,1026.411z"/>
            <path fill-rule="evenodd" d="M857.375,831.843c9.6,4.16,20.159,23.294,16.891,31.419
                C871.738,850.732,866.131,840.999,857.375,831.843z"/>
            <path fill-rule="evenodd" d="M867.034,975.692c5.468,9.522,3.305,26.563-4.808,29.919
                C868.098,996.252,868.802,986.158,867.034,975.692z"/>
            <path fill-rule="evenodd" d="M825.627,935.355c6.402,7.944,10.021,26.749,6.501,32.321
                C830.003,957.112,827.752,945.922,825.627,935.355z"/>
            <path fill-rule="evenodd" d="M988.228,921.568c8.297,11.583,11.38,24.491,9.666,38.583
                C997.07,946.685,994.746,933.607,988.228,921.568z"/>
            <path fill-rule="evenodd" d="M846.99,1112.938c-1.777-9.426-3.555-18.852-5.473-29.023c5.531,9.081,8.603,18.144,7.704,28.313
                c-0.023,0.271-1.504,0.412-2.307,0.612C846.914,1112.838,846.99,1112.938,846.99,1112.938z"/>
            <path fill-rule="evenodd" d="M865.26,857.738c5.813,4.755,7.598,18.361,2.839,24.714
                C867.156,874.243,866.193,865.858,865.26,857.738z"/>
            <path fill-rule="evenodd" d="M822.909,973.291c3.006,11.022,1.407,20.353-3.995,29.328
                C820.186,993.288,821.456,983.958,822.909,973.291z"/>
            <path fill-rule="evenodd" d="M815.636,1160.28c4.915,6.214,4.314,18.93-1.458,24.332
                C814.657,1176.618,815.163,1168.164,815.636,1160.28z"/>
            <path fill-rule="evenodd" d="M798.896,1083.706c5.734,7.446,8.798,15.79,8.047,25.167c-0.412,0.117-0.825,0.234-1.237,0.352
                C803.436,1100.719,801.166,1092.212,798.896,1083.706z"/>
            <path fill-rule="evenodd" d="M672.973,1098.211c3.382,7.552,7.048,15.736,10.679,23.843
                C677.303,1119.859,670.698,1104.087,672.973,1098.211z"/>
            <path fill-rule="evenodd" d="M600.977,1046.906c0.895,2.021,1.789,4.042,2.684,6.062c-7.711-11.203-16.466-11.342-26.506-2.376
                c2.955-9.521,14.377-10.892,23.908-3.577L600.977,1046.906z"/>
            <path fill-rule="evenodd" d="M540.825,1151.284c-10.955,4.129-22.292,3.331-33.698,0
                C518.439,1151.284,529.752,1151.284,540.825,1151.284z"/>
            <path fill-rule="evenodd" d="M647.047,1094.998c-4.098-6.223-8.286-12.411-8.349-20.289c-0.008-0.957,0.114-1.915,0.179-2.925
                c3.604,7.975,7.143,15.804,10.834,23.971c-0.877-0.271-1.817-0.562-2.758-0.854L647.047,1094.998z"/>
            <path fill-rule="evenodd" d="M799.837,1054.843c2.107,8.491,4.215,16.981,6.41,25.827
                C801.838,1077.713,798.196,1063.74,799.837,1054.843z"/>
            <path fill-rule="evenodd" d="M680.66,933.659c8.281-0.057,19.835,6.78,21.622,12.856
                C695.031,942.204,687.909,937.97,680.66,933.659z"/>
            <path fill-rule="evenodd" d="M741.277,1143.596c2.202,7.515,4.403,15.03,6.604,22.545c-0.848,0.243-1.696,0.486-2.544,0.73
                c-2.111-7.543-4.223-15.085-6.334-22.628C739.762,1144.027,740.52,1143.812,741.277,1143.596z"/>
            <path fill-rule="evenodd" d="M771.366,1207.565c5.064,5.369,6.548,17.196,2.657,23.222
                C773.118,1222.878,772.237,1215.18,771.366,1207.565z"/>
            <path fill-rule="evenodd" d="M818.858,1135.66c5.294,5.338,8.434,18.003,5.836,23.627
                C822.718,1151.285,820.871,1143.81,818.858,1135.66z"/>
            <path fill-rule="evenodd" d="M838.065,1055.121c-4.424-12.535-4.727-14.547-2.806-19.854c2.001,7.2,3.92,14.102,6.088,21.903
                c-1.598-1.02-2.484-1.586-3.37-2.151L838.065,1055.121z"/>
            <path fill-rule="evenodd" d="M748.337,891.73c0.725-8.724-1.965-16.278-8.244-23.632
                C748.686,872.553,752.738,884.561,748.337,891.73z"/>
            <path fill-rule="evenodd" d="M837.978,1055.019c0.872,2.354,1.744,4.708,2.896,7.813c-5.357-2.712-10.532-13.634-9.417-19.263
                c2.372,4.146,4.49,7.848,6.608,11.551C838.065,1055.121,837.978,1055.019,837.978,1055.019z"/>
            <path fill-rule="evenodd" d="M865.027,897.073c0.963,2.207,1.926,4.413,2.937,6.728c-5.31-0.843-8.771-10.868-6.711-20.638
                c0.808,3.32,1.377,5.664,1.946,8.008C865.93,892.481,866.202,894.554,865.027,897.073z"/>
            <path fill-rule="evenodd" d="M740.28,1191.188c0-6.981,0-13.409,0-19.805C744.149,1174.399,744.46,1185.939,740.28,1191.188z"/>
            <path fill-rule="evenodd" d="M700.828,1144.705c-2.075-7.463-4.15-14.925-6.227-22.387c4.791,6.731,8.523,13.756,7.499,22.155
                C701.677,1144.551,701.253,1144.628,700.828,1144.705z"/>
            <path fill-rule="evenodd" d="M657.598,1039.488c-7.883,0.641-15.767,1.281-23.34,1.897
                C635.888,1037.456,647.667,1035.768,657.598,1039.488z"/>
            <path fill-rule="evenodd" d="M641.792,1127.804c1.287,7.452-3.071,19.424-8.66,22.793
                C636.092,1142.806,638.906,1135.398,641.792,1127.804z"/>
            <path fill-rule="evenodd" d="M537.59,1021.74c8.451-0.41,14.141-5.501,19.623-10.961
                C555.045,1018.73,545.972,1024.025,537.59,1021.74z"/>
            <path fill-rule="evenodd" d="M648.38,1132.649c2.3,7.119-1.176,16.227-7.086,18.845
                C643.642,1145.249,645.99,1139.006,648.38,1132.649z"/>
            <path fill-rule="evenodd" d="M602.893,1057.471c-1.681-0.635-3.754-0.846-4.973-1.978c-4.75-4.409-7.277-4.291-10.919,0.955
                c-0.86,1.239-1.856,2.383-2.791,3.571c-0.564-0.445-1.13-0.89-1.695-1.334c2.264-2.707,4.045-6.305,6.929-7.868
                c2.829-1.534,6.683-1.148,9.178,2.43c0.906,1.3,2.884,1.852,4.372,2.745C602.96,1056.484,602.927,1056.978,602.893,1057.471z"/>
            <path fill-rule="evenodd" d="M863.887,910.84c-6.592-2.071-12.197-10.654-11.809-17.203
                C855.912,899.223,859.736,904.793,863.887,910.84z"/>
            <path fill-rule="evenodd" d="M607.121,1163.507c-1.3,6.493-0.045,13.545-4.479,19.351c-0.454-0.091-0.907-0.183-1.361-0.273
                c1.327-6.473,2.653-12.946,3.98-19.419C605.882,1163.279,606.501,1163.393,607.121,1163.507z"/>
            <path fill-rule="evenodd" d="M871.999,970.151c-2.397-7.639-4.795-15.278-7.192-22.917c0.59-0.206,1.18-0.412,1.771-0.619
                c2.521,7.602,5.042,15.202,7.562,22.804C873.426,969.663,872.713,969.907,871.999,970.151z"/>
            <path fill-rule="evenodd" d="M684.323,1164.24c0-5.999,0-11.998,0-17.891C687.426,1149.224,688.147,1160.557,684.323,1164.24z"/>
            <path fill-rule="evenodd" d="M779.576,1236.45c1.43-6.492,2.917-13.247,4.306-19.553
                C787.676,1221.527,785.276,1233.24,779.576,1236.45z"/>
            <path fill-rule="evenodd" d="M790.31,953.845c-5.789-5.403-5.966-12.7-5.899-20.094
                C786.376,940.449,788.343,947.146,790.31,953.845z"/>
            <path fill-rule="evenodd" d="M786.314,1150.523c5.28,6.103,9.266,12.714,8.404,21.234
                C791.917,1164.68,789.116,1157.602,786.314,1150.523z"/>
            <path fill-rule="evenodd" d="M743.639,895.931c0,6.613,0,13.227,0,19.84c-0.439,0.017-0.879,0.032-1.318,0.049
                c0-6.605,0-13.211,0-19.816C742.76,895.979,743.199,895.955,743.639,895.931z"/>
            <path fill-rule="evenodd" d="M658.862,945.807c6.301,1.932,12.389,4.263,17.16,9.065c-0.275,0.516-0.552,1.03-0.828,1.546
                c-5.657-3.138-11.314-6.275-16.972-9.413C658.436,946.605,658.649,946.207,658.862,945.807z"/>
            <path fill-rule="evenodd" d="M766.029,1223.146c-2.285-7.243-4.569-14.486-6.854-21.729c0.545-0.175,1.09-0.35,1.634-0.523
                c2.479,7.17,4.959,14.342,7.438,21.512C767.508,1222.651,766.769,1222.898,766.029,1223.146z"/>
            <path fill-rule="evenodd" d="M767.463,1061.982c-2.49-6.754-4.979-13.508-7.47-20.262c0.595-0.225,1.188-0.449,1.783-0.674
                c2.652,6.723,5.305,13.443,7.957,20.166C768.977,1061.469,768.22,1061.726,767.463,1061.982z"/>
            <path fill-rule="evenodd" d="M865.027,897.073c-0.609-1.968-1.22-3.937-1.828-5.903c0-4.475,0-8.949,0-13.425
                c0.392-0.034,0.782-0.068,1.173-0.102c0.87,6.064,1.771,12.125,2.519,18.204C866.931,896.175,865.678,896.661,865.027,897.073z"/>
            <path fill-rule="evenodd" d="M880.69,842.878c-5.567-4.26-10.829-8.287-15.854-12.133
                C869.855,829.662,879.596,836.71,880.69,842.878z"/>
            <path fill-rule="evenodd" d="M600.074,1083.701c0.988-6.082,1.979-12.18,2.916-17.946
                C607.235,1071.037,606.463,1076.346,600.074,1083.701z"/>
            <path fill-rule="evenodd" d="M610.242,1145.874c-0.447,0.445-0.895,0.892-1.342,1.337c-4.521,4.475-9.043,8.947-13.564,13.422
                c-0.408-0.299-0.816-0.598-1.226-0.896c0.877-1.337,1.552-2.882,2.669-3.973c3.44-3.36,6.998-6.608,10.648-9.737
                C607.994,1145.542,609.286,1145.903,610.242,1145.874z"/>
            <path fill-rule="evenodd" d="M594.967,1075.771c0.781-1.387,1.562-2.773,2.53-4.489c-2.848,0-5.447,0-8.114,0
                c3.739-1.967,7.063-3.716,10.434-5.488c0.57,3.658,0.441,7.788-3.538,10.652C595.841,1076.221,595.404,1075.996,594.967,1075.771z
                "/>
            <path fill-rule="evenodd" d="M643.48,925.731c1.999-5.51,3.887-10.711,5.654-15.582C652.652,913.6,650.066,922.186,643.48,925.731
                z"/>
            <path fill-rule="evenodd" d="M754.892,1233.015c0.411,6.791,0.804,13.258,1.189,19.618
                C751.936,1250.118,751.151,1239.999,754.892,1233.015z"/>
            <path fill-rule="evenodd" d="M654.681,1085.065c-2.483-6.686-4.574-12.313-6.74-18.142
                C652.703,1069.926,656.189,1078.486,654.681,1085.065z"/>
            <path fill-rule="evenodd" d="M1026.926,1068.223c-3.42,6.879-7.293,13.334-14.675,16.627
                C1017.143,1079.307,1022.034,1073.766,1026.926,1068.223z"/>
            <path fill-rule="evenodd" d="M788.357,1176.726c5.892,5.916,7.457,12.929,4.363,18.632
                C791.289,1189.241,789.891,1183.271,788.357,1176.726z"/>
            <path fill-rule="evenodd" d="M665.584,1098.019c-1.103-7.035-2.078-13.259-3.103-19.793
                C666.682,1082.695,668.327,1092.276,665.584,1098.019z"/>
            <path fill-rule="evenodd" d="M746.16,1234.369c1.638,5.778,3.275,11.558,4.912,17.336c-0.5,0.137-1.001,0.273-1.502,0.41
                c-3.408-5.333-4.683-11.28-4.77-17.568C745.254,1234.487,745.707,1234.428,746.16,1234.369z"/>
            <path fill-rule="evenodd" d="M582.346,1145.696c-1.265,6.764-2.409,12.887-3.718,19.883
                C576.241,1160.501,578.251,1150.595,582.346,1145.696z"/>
            <path fill-rule="evenodd" d="M511.308,1003.094c-7.174-3.043-11.802-9.456-11.881-15.894
                C503.228,992.284,507.131,997.506,511.308,1003.094z"/>
            <path fill-rule="evenodd" d="M763.223,974.599c3.815,5.757,7.281,10.986,10.752,16.224
                C768.744,989.614,764.143,982.854,763.223,974.599z"/>
            <path fill-rule="evenodd" d="M838.7,995.785c-4.643-7.603-4.317-14.473-2.328-21.553
                C837.101,980.972,837.828,987.711,838.7,995.785z"/>
            <path fill-rule="evenodd" d="M1027.344,1088.171c-6.533,5.996-12.393,10.056-21.606,9.741
                C1013.26,1094.521,1019.635,1091.646,1027.344,1088.171z"/>
            <path fill-rule="evenodd" d="M570.649,1178.406c1.403-6.293,2.595-11.636,3.767-16.892
                C577.452,1165.379,576.084,1172.97,570.649,1178.406z"/>
            <path fill-rule="evenodd" d="M619.567,1051.951c-0.624,2.967-2.204,3.857-4.881,2.415c-3.179-1.712-3.777-3.746-2.044-6.897
                C614.943,1048.958,617.239,1050.443,619.567,1051.951z"/>
            <path fill-rule="evenodd" d="M893.921,842.037c-3.674-3.959-7.348-7.918-11.021-11.877c0.444-0.431,0.889-0.86,1.333-1.291
                c3.749,3.887,7.497,7.773,11.245,11.66C894.959,841.032,894.44,841.534,893.921,842.037z"/>
            <path fill-rule="evenodd" d="M662.685,940.035c-5.133-0.372-10.265-0.743-15.397-1.114c0.016-0.341,0.032-0.682,0.048-1.022
                c5.155,0,10.311,0,15.466,0C662.762,938.61,662.724,939.322,662.685,940.035z"/>
            <path fill-rule="evenodd" d="M600.713,1186.206c-2.393,4.357-4.786,8.715-7.179,13.072c-0.646-0.341-1.29-0.683-1.935-1.023
                c2.356-4.341,4.713-8.682,7.07-13.021C599.351,1185.558,600.031,1185.882,600.713,1186.206z"/>
            <path fill-rule="evenodd" d="M698.065,1164.083c0.22-4.312,0.439-8.623,0.659-12.935c0.49,0.006,0.979,0.012,1.469,0.018
                c0,4.318,0,8.637,0,12.955C699.484,1164.108,698.774,1164.096,698.065,1164.083z"/>
            <path fill-rule="evenodd" d="M770.312,907.736c0-5.207,0-10.197,0-15.348C774.453,895.433,774.465,903.924,770.312,907.736z"/>
            <path fill-rule="evenodd" d="M693.538,835.498c-5.358-2.298-10.718-4.595-16.076-6.893
                C683.817,828.741,689.769,829.792,693.538,835.498z"/>
            <path fill-rule="evenodd" d="M607.468,912.671c0.286-4.537,0.554-8.787,0.844-13.392
                C611.93,903.817,611.574,909.54,607.468,912.671z"/>
            <path fill-rule="evenodd" d="M765.666,1005.089c-1.637-4.513-3.273-9.025-4.91-13.538c0.647-0.242,1.296-0.485,1.943-0.729
                c1.754,4.467,3.508,8.934,5.262,13.4C767.196,1004.511,766.432,1004.8,765.666,1005.089z"/>
            <path fill-rule="evenodd" d="M773.768,1074.81c2.211,5.591,1.762,10.816-2.607,15.317
                C772.029,1085.021,772.898,1079.915,773.768,1074.81z"/>
            <path fill-rule="evenodd" d="M662.556,1112.68c0.738,4.873-1.008,9.062-3.477,13.052c-0.508-0.13-1.016-0.26-1.522-0.39
                c1.101-4.386,2.202-8.771,3.304-13.157C661.426,1112.35,661.99,1112.515,662.556,1112.68z"/>
            <path fill-rule="evenodd" d="M766.705,1239.092c0.754,6.508-2.006,11.38-7.396,13.206
                C761.734,1247.969,764.04,1243.851,766.705,1239.092z"/>
            <path fill-rule="evenodd" d="M809.952,1187.963c-1.217,4.684-2.434,9.367-3.65,14.051c-0.682-0.173-1.362-0.345-2.044-0.518
                c1.215-4.659,2.429-9.319,3.644-13.979C808.585,1187.666,809.269,1187.814,809.952,1187.963z"/>
            <path fill-rule="evenodd" d="M837.738,1038.665c-0.386-7.842,4.644-11.064,9.205-14.607
                C844.098,1028.572,841.253,1033.088,837.738,1038.665z"/>
            <path fill-rule="evenodd" d="M738.362,1248.619c0.766-5.398,1.391-9.809,2.023-14.264
                C743.72,1238.17,743.188,1243.81,738.362,1248.619z"/>
            <path fill-rule="evenodd" d="M626.21,1044.131c5.187-0.311,10.372-0.621,15.558-0.932c0.015,0.425,0.029,0.851,0.044,1.276
                c-5.144,1.656-10.314,2.483-15.558,1.127C626.239,1045.111,626.225,1044.621,626.21,1044.131z"/>
            <path fill-rule="evenodd" d="M559.421,1146.695c4.784,2.669,7.314,11.423,4.396,16.028
                C562.387,1157.511,560.837,1151.86,559.421,1146.695z"/>
            <path fill-rule="evenodd" d="M1049.187,927.837c-2.5-5.251-4.999-10.502-7.499-15.753c0.418-0.211,0.836-0.422,1.254-0.633
                c2.666,5.191,5.332,10.382,7.999,15.573C1050.355,927.295,1049.771,927.566,1049.187,927.837z"/>
            <path fill-rule="evenodd" d="M763.663,1065.686c1.005,4.906,2.009,9.813,3.014,14.72c-0.696,0.145-1.393,0.289-2.089,0.434
                c-0.874-4.928-1.748-9.856-2.621-14.784C762.532,1065.932,763.098,1065.809,763.663,1065.686z"/>
            <path fill-rule="evenodd" d="M718.648,969.542c4.434,4.063,6.704,8.92,5.385,15.089
                C722.238,979.602,720.443,974.572,718.648,969.542z"/>
            <path fill-rule="evenodd" d="M639.451,860.517c0.267-5.538,3.429-8.351,10.824-9.142
                C646.425,854.627,642.951,857.562,639.451,860.517z"/>
            <path fill-rule="evenodd" d="M626.903,1052.764c-4.548-2.083-9.097-4.166-13.645-6.249c0.257-0.616,0.515-1.231,0.772-1.847
                c4.478,2.218,8.955,4.436,13.433,6.654C627.277,1051.803,627.091,1052.283,626.903,1052.764z"/>
            <path fill-rule="evenodd" d="M582.264,1142.124c1.457-4.426,2.656-8.069,3.901-11.851
                C588.81,1133.836,587.249,1138.925,582.264,1142.124z"/>
            <path fill-rule="evenodd" d="M906.198,838.807c-3.399-2.316-6.8-4.634-10.199-6.95c0.323-0.483,0.646-0.967,0.97-1.45
                c3.543,2.165,7.086,4.329,10.629,6.494C907.131,837.536,906.664,838.171,906.198,838.807z"/>
            <path fill-rule="evenodd" d="M611.101,1113.712c-1.819-4.407-1.903-8.827,0.935-13.281c0.213,4.39,0.427,8.778,0.64,13.168
                C612.15,1113.637,611.625,1113.674,611.101,1113.712z"/>
            <path fill-rule="evenodd" d="M558.81,1075.072c2.968-4.732,5.937-9.465,8.905-14.198c0.477,0.293,0.954,0.585,1.431,0.878
                c-2.9,4.762-5.8,9.523-8.7,14.285C559.9,1075.716,559.354,1075.395,558.81,1075.072z"/>
            <path fill-rule="evenodd" d="M773.932,1245.174c-3.252,3.446-6.504,6.893-9.757,10.339c-0.489-0.44-0.978-0.881-1.467-1.321
                c3.282-3.449,6.564-6.899,9.847-10.349C773.014,1244.286,773.473,1244.73,773.932,1245.174z"/>
            <path fill-rule="evenodd" d="M782.689,1179.784c1.6,4.712,3.2,9.423,4.801,14.135c-0.667,0.216-1.334,0.433-2.001,0.648
                c-1.465-4.755-2.93-9.509-4.394-14.263C781.627,1180.131,782.158,1179.957,782.689,1179.784z"/>
            <path fill-rule="evenodd" d="M541.811,991.733c2.114-3.902,3.92-7.235,5.844-10.788
                C549.47,985.221,547.206,989.629,541.811,991.733z"/>
            <path fill-rule="evenodd" d="M712.869,1014.832c1.866-3.907,3.731-7.813,5.597-11.72c0.563,0.257,1.126,0.514,1.689,0.771
                c-1.786,3.965-3.572,7.93-5.358,11.895C714.154,1015.462,713.512,1015.147,712.869,1014.832z"/>
            <path fill-rule="evenodd" d="M587.77,1114.4c0.73,4.041,1.461,8.082,2.191,12.123c-0.68,0.123-1.36,0.245-2.041,0.367
                c-0.621-4.06-1.241-8.119-1.862-12.18C586.629,1114.607,587.199,1114.504,587.77,1114.4z"/>
            <path fill-rule="evenodd" d="M558.227,1090.142c-1.564,3.939-3.129,7.88-4.919,12.389
                C551.833,1098.44,553.317,1094.312,558.227,1090.142z"/>
            <path fill-rule="evenodd" d="M718.257,988.124c3.831,3.593,6.136,7.37,3.518,13.329
                C720.399,996.243,719.328,992.184,718.257,988.124z"/>
            <path fill-rule="evenodd" d="M581.024,982.115c-1.761,3.592-3.521,7.185-5.282,10.777c-0.582-0.275-1.164-0.551-1.746-0.826
                c1.686-3.531,3.371-7.062,5.057-10.593C579.71,981.688,580.367,981.901,581.024,982.115z"/>
            <path fill-rule="evenodd" d="M765.509,822.592c3.717,0.822,7.434,1.646,11.149,2.468c-0.148,0.644-0.297,1.287-0.445,1.931
                c-3.67-0.838-7.339-1.677-11.009-2.515C765.306,823.848,765.407,823.22,765.509,822.592z"/>
            <path fill-rule="evenodd" d="M587.641,1170.162c-1.912-4.544-0.407-7.457,4.923-10.293
                C590.947,1163.248,589.449,1166.382,587.641,1170.162z"/>
            <path fill-rule="evenodd" d="M655.33,1096.641c-1.155-3.56-2.312-7.119-3.467-10.678c0.558-0.197,1.116-0.394,1.674-0.59
                c1.292,3.565,2.584,7.131,3.876,10.697C656.719,1096.26,656.024,1096.45,655.33,1096.641z"/>
            <path fill-rule="evenodd" d="M705.372,988.891c0,3.887,0,7.774,0,11.661c-0.362,0.01-0.725,0.02-1.087,0.029
                c0-3.894,0-7.787,0-11.68C704.647,988.897,705.01,988.895,705.372,988.891z"/>
            <path fill-rule="evenodd" d="M792.064,1205.91c-1.31,3.32-2.619,6.641-3.929,9.961c-0.579-0.228-1.159-0.454-1.738-0.681
                c1.341-3.309,2.683-6.618,4.024-9.927C790.969,1205.479,791.517,1205.695,792.064,1205.91z"/>
            <path fill-rule="evenodd" d="M895.088,1014.08c-2.302,3.957-4.604,7.914-6.905,11.871c-0.544-0.317-1.089-0.635-1.634-0.952
                c2.404-3.896,4.808-7.793,7.211-11.688C894.203,1013.566,894.646,1013.823,895.088,1014.08z"/>
            <path fill-rule="evenodd" d="M508.59,1015.443c2.336,2.687,4.672,5.373,7.008,8.059c-0.483,0.426-0.967,0.851-1.45,1.276
                c-2.394-2.681-4.787-5.361-7.181-8.041C507.508,1016.306,508.049,1015.875,508.59,1015.443z"/>
            <path fill-rule="evenodd" d="M748.33,1268.931c0.707-4.202,1.274-7.568,1.865-11.081
                C753.03,1260.771,752.536,1264.81,748.33,1268.931z"/>
            <path fill-rule="evenodd" d="M881.824,1019.051c-2.585,3.906-5.171,7.812-7.757,11.72c-0.522-0.332-1.045-0.663-1.568-0.995
                c2.547-3.896,5.094-7.794,7.641-11.69C880.701,1018.407,881.263,1018.729,881.824,1019.051z"/>
            <path fill-rule="evenodd" d="M803.11,828.76c-3.624-1.074-7.247-2.148-10.871-3.223C796.48,824.359,800.286,824.898,803.11,828.76
                z"/>
            <path fill-rule="evenodd" d="M601.062,1047.016c-1.739-2.406-3.479-4.812-6.291-8.702c2.675,1.262,4.809,1.616,5.736,2.866
                c1.239,1.67,1.542,4.035,2.246,6.102c-0.593-0.124-1.186-0.249-1.778-0.374C600.977,1046.906,601.062,1047.016,601.062,1047.016z"
                />
            <path fill-rule="evenodd" d="M491.553,1052.793c-0.388-3.716-0.775-7.433-1.164-11.149c0.506-0.075,1.011-0.149,1.517-0.225
                c1.811,3.525,1.934,7.287,0.985,11.199C492.444,1052.677,491.999,1052.735,491.553,1052.793z"/>
            <path fill-rule="evenodd" d="M978.614,1062.766c-2.799,4.453-5.598,8.906-8.396,13.36c-0.451-0.274-0.902-0.55-1.353-0.824
                c2.802-4.461,5.604-8.922,8.406-13.383C977.72,1062.201,978.167,1062.483,978.614,1062.766z"/>
            <path fill-rule="evenodd" d="M599.352,1132.156c1.691-3.214,3.384-6.429,5.076-9.643c0.536,0.275,1.072,0.552,1.608,0.828
                c-1.665,3.303-3.33,6.605-4.995,9.908C600.478,1132.886,599.914,1132.521,599.352,1132.156z"/>
            <path fill-rule="evenodd" d="M603.099,854.325c-5.723-0.771-11.462-1.437-17.166-2.329c-22.225-3.48-44.466-2.332-66.765-0.784
                c-9.759,0.678-19.604,0.114-29.409,0.114c-0.046,0.61-0.091,1.221-0.136,1.831c9.594,1.276,19.165,2.777,28.787,3.777
                c14.724,1.529,29.498,2.567,44.215,4.144c3.809,0.407,7.485,2.041,11.96,3.323c-4.837,5.727-10.601,6.507-16.058,6.395
                c-12.469-0.255-24.938-1.074-37.376-2.054c-31.509-2.481-63-5.177-94.498-7.792c-0.742-0.062-1.479-0.188-2.272-0.801
                c9.947-5.408,21.462-4.122,33.343-8.074c-17.892-5.607-35.472-1.522-53.142-5.613c2.467-1.336,4.055-2.196,5.644-3.057
                c0.069-0.526,0.139-1.053,0.208-1.579c-7.447-1.909-14.797-4.547-22.363-5.56c-12.011-1.608-24.155-2.309-36.264-3.061
                c-10.798-0.67-21.625-0.868-32.437-1.325c-1.888-0.08-3.765-0.413-5.647-0.629c0.02-0.455,0.039-0.911,0.058-1.366
                c6.148,0,12.301-0.148,18.444,0.026c22.971,0.656,45.947,1.233,68.903,2.243c7.951,0.35,15.952,1.389,23.737,3.029
                c5.249,1.106,10.011,4.805,15.254,5.597c9.344,1.41,18.88,1.875,28.349,2.056c16.324,0.312,32.663-0.117,48.989,0.159
                c28.027,0.473,55.716,4.288,83.283,9.094c0.905,0.158,1.75,0.662,2.623,1.005C603.275,853.505,603.188,853.915,603.099,854.325z"
                />
            <path fill-rule="evenodd" d="M513.397,883.67c-10.648,1.129-21.296,2.257-31.944,3.385c-0.008,0.208-0.016,0.415-0.023,0.623
                c11.474,1.955,22.946,3.909,34.42,5.864c-0.027,0.377-0.055,0.755-0.083,1.132c-1.534,0.473-3.069,0.945-4.604,1.419
                c-0.059,0.385-0.118,0.77-0.177,1.155c14.408,2.495,28.816,4.99,43.226,7.485c-0.028,0.548-0.057,1.096-0.084,1.644
                c-3.348,0.189-6.726,0.765-10.038,0.504c-16.412-1.294-32.799-2.917-49.21-4.218c-20.893-1.657-41.797-3.164-62.702-4.638
                c-8.399-0.593-16.816-0.924-25.226-1.375c-0.069-0.585-0.139-1.171-0.208-1.757c5.486-0.963,10.941-2.204,16.467-2.812
                c5.944-0.653,11.951-0.83,17.937-0.994c5.754-0.158,11.516-0.034,17.35-1.151c-6.542-0.765-13.088-1.498-19.625-2.305
                c-6.516-0.804-13.022-1.68-19.534-2.524c0.003-0.472,0.006-0.943,0.009-1.414c7.009-0.686,14.017-1.371,21.025-2.058
                c0.057-0.306,0.113-0.611,0.17-0.917c-7.842-1.393-15.684-2.785-23.525-4.178c32.243,0.948,64.492,1.176,96.505,5.154
                C513.48,882.354,513.438,883.012,513.397,883.67z"/>
            <path fill-rule="evenodd" d="M27.602,18.688c2.603-6.288,9.666-9.78,16.795-8.38c6.309,1.239,12.448,3.421,18.589,5.412
                c3.626,1.176,7.08,2.873,10.674,4.16c1.159,0.414,2.859,0.818,3.73,0.281c4.276-2.635,8.581-1.974,12.975-0.686
                c2.458,0.72,4.326,0.579,5.81-2.084c2.463-4.421,6.355-5.695,11.06-3.644c4.85,2.115,9.804,4.084,14.389,6.686
                c3.585,2.034,6.585,2.514,10.375,0.487c8.514-4.552,17.927-4.53,27.259-4.466c12.009,0.082,23.225,3.716,34.324,7.861
                c4.461,1.666,9.004,3.771,13.493-0.029c0.12-0.101,0.302-0.167,0.46-0.178c6.444-0.429,12.579,2.077,19.481-1.608
                c6.194-3.307,13.048,2.022,19.145,4.892c3.588,1.688,5.647,1.422,8.445-1.687c9.387-10.431,19.651-10.244,29.013,0.215
                c2.347,2.622,4.134,5.745,6.178,8.638c-0.364,0.259-0.729,0.518-1.094,0.776c-1.497-2.042-2.946-4.122-4.499-6.12
                c-9.329-12.008-20.283-12.324-29.775-0.524c-2.234,2.777-4.042,2.725-6.767,1.488c-4.082-1.851-8.2-3.645-12.396-5.214
                c-4.434-1.659-8.604-1.308-12.831,1.365c-1.752,1.108-4.688,1.072-6.88,0.601c-4.032-0.867-7.583-1.28-11.474,1.06
                c-1.675,1.007-4.763,0.241-7.019-0.395c-8.945-2.519-17.683-5.939-26.746-7.83c-11.335-2.365-22.859-1.989-34.056,1.503
                c-2.449,0.764-4.676,3.072-7.027,3.105c-2.863,0.04-5.833-1.373-8.608-2.518c-4.741-1.955-9.288-4.395-14.066-6.241
                c-3.83-1.479-7.36-0.739-9.115,3.471c-1.254,3.01-3.128,3.204-5.984,2.482c-4.633-1.171-9.151-2.159-13.972,0.644
                c-1.774,1.031-5.335-0.586-7.924-1.46c-7.222-2.438-14.273-5.414-21.561-7.617C40.852,10.974,34.169,12.42,27.602,18.688z"/>
            <path fill-rule="evenodd" d="M342.768,53.862c-5.771-5.51-12.463-10.557-20.349-8.435c-11.521,3.101-23.052,1.931-34.565,2.798
                c-3.658,0.276-7.197,2.131-12.217,3.713c1.784-1.605,2.579-2.906,3.633-3.17c4.771-1.19,9.583-2.686,14.442-2.959
                c8.735-0.491,17.395,0.473,26.108-2.465C328.269,40.496,338.973,45.946,342.768,53.862z"/>
            <path fill-rule="evenodd" d="M166.708,937.709c7.997-0.834,15.995-1.667,23.992-2.5c0.038-0.477,0.075-0.952,0.112-1.429
                c-7.833-1.713-15.667-3.426-24.054-5.26c0.925-1.058,1.487-1.699,2.196-2.51c-20.153-2.426-40.18-4.836-60.208-7.247
                c-0.002-0.325-0.005-0.65-0.007-0.976c7.495,0,14.99,0,22.485,0c0.034-0.33,0.067-0.661,0.101-0.991
                c-3.116-0.749-6.232-1.498-9.348-2.247c-0.035-0.525-0.071-1.051-0.106-1.576c2.227-0.308,4.472-0.979,6.677-0.865
                c20.443,1.058,40.89,2.081,61.312,3.467c12.278,0.833,24.514,2.278,36.766,3.486c1.404,0.139,2.783,0.524,4.174,0.795
                c0.027,0.53,0.054,1.06,0.081,1.59c-8.399,1.442-16.798,2.885-25.197,4.327c-0.071,0.247-0.143,0.494-0.215,0.741
                c8.02,1.273,16.04,2.547,24.06,3.821c0.072,0.301,0.143,0.602,0.215,0.902c-2.022,0.425-4.044,0.849-6.066,1.273
                c-0.043,0.242-0.086,0.484-0.13,0.727c13.266,1.818,26.532,3.638,39.798,5.456c0.043,0.791,0.086,1.582,0.129,2.373
                c-5.157,0.928-10.345,2.793-15.465,2.621c-22.107-0.739-44.198-2.014-66.286-3.241c-5.057-0.281-10.081-1.15-15.121-1.749
                C166.638,938.368,166.673,938.039,166.708,937.709z"/>
            <path fill-rule="evenodd" d="M801.818,164.358c2.889-2.02,4.991-4.256,7.559-5.138c7.15-2.454,14.505-4.309,21.744-6.513
                c0.793-0.241,1.669-1.086,1.992-1.864c5.665-13.617,19.27-13.296,29.4-7.174c3.621,2.188,6.584,5.465,9.847,8.245
                c-0.38,0.418-0.76,0.836-1.14,1.254c-0.781-0.697-1.479-1.534-2.359-2.069c-4.759-2.894-9.258-6.616-14.418-8.356
                c-8.789-2.963-14.928,0.574-19.186,8.885c-0.814,1.588-3.247,2.655-5.156,3.319C821.088,158.084,811.997,160.997,801.818,164.358z
                "/>
            <path fill-rule="evenodd" d="M889.991,1243.449c14.932-4.25,29.864-8.501,44.796-12.751
                C920.812,1238.188,906.265,1243.748,889.991,1243.449z"/>
            <path fill-rule="evenodd" d="M96.33,798.018c-3.46-5.517-6.632-10.574-9.804-15.632c0.266-0.138,0.532-0.276,0.799-0.414
                c5.507,6.855,11.015,13.711,16.677,20.759c-9.489,0.506-16.279-4.384-22.83-10.566c-1.941,4.122-4.293,2.627-6.319,0.235
                c-1.375-1.621-2.222-3.678-3.427-5.457c-1.067-1.576-0.635-5.289-4.455-3.303c-0.227,0.118-1.032-0.71-1.469-1.188
                c-3.972-4.353-5.344-20.983-2.58-25.823c0.738,7.603,1.537,15.837,2.44,25.15c2.29-1.646,3.161-2.272,4.165-2.994
                c2.558,4.839,5.042,9.538,7.874,14.896c1.511-2.228,2.295-3.384,3.438-5.069C85.053,793.579,89.137,798.449,96.33,798.018z"/>
            <path fill-rule="evenodd" d="M1009.947,777.074c-4.22-2.689-7.83-3.217-11.863,1.055c-2.437,2.58-6.01,5.595-10.428,2.43
                c-0.594-0.426-1.918-0.709-2.343-0.365c-6.274,5.081-13.954,2.86-20.889,4.347c-3.256,0.698-6.048,3.557-9.305,5.006
                c3.103-3.405,6.131-6.182,11.343-7.057c5.896-0.99,12.698,1.002,17.719-4.142c0.488-0.5,2.1-0.51,2.806-0.095
                c4.859,2.859,7.967-0.051,11.207-3.05c4.366-4.04,10.258-3.006,11.672,1.943C1009.864,777.148,1009.947,777.074,1009.947,777.074z
                "/>
            <path fill-rule="evenodd" d="M1136.648,719.618c1.102-0.917,2.203-1.833,3.306-2.75c-2.594,11.287-6.831,21.612-12.404,31.31
                c-0.391-0.197-0.781-0.396-1.172-0.594c3.633-9.032,7.265-18.063,10.897-27.095
                C1137.066,720.198,1136.857,719.908,1136.648,719.618z"/>
            <path fill-rule="evenodd" d="M1131.716,761.667c2.095-1.727,4.188-3.453,6.283-5.18c0.252,0.238,0.504,0.477,0.756,0.715
                c-4.958,7.589-9.915,15.178-15.551,23.805c2.544-1.051,4.046-1.671,5.549-2.291c0.166,0.186,0.332,0.372,0.499,0.558
                c-2.736,1.588-5.475,3.176-8.212,4.764c-0.3-0.351-0.6-0.701-0.899-1.052c3.842-6.021,7.708-12.027,11.492-18.085
                c0.449-0.719,0.454-1.717,0.664-2.585c-0.293,0.068-0.587,0.137-0.88,0.205C1131.517,762.236,1131.616,761.951,1131.716,761.667z"
                />
            <path fill-rule="evenodd" d="M1156.715,770.271c-8.94-4.873-11.618-17.153-6.04-24.862c-1.934,7.651-2.249,15.021,3.407,21.75
                c0.808-0.987,1.463-1.787,2.117-2.588c0.429,0.072,0.858,0.145,1.287,0.217C1157.262,766.383,1157.037,767.978,1156.715,770.271z"
                />
            <path fill-rule="evenodd" d="M1132.298,762.315c-2.214,1.339-4.428,2.678-6.642,4.017c-0.344-0.338-0.687-0.675-1.03-1.013
                c5.177-7.492,10.354-14.983,15.53-22.476c0.334,0.206,0.668,0.413,1.002,0.62c-4.102,6.236-8.203,12.474-12.629,19.203
                c1.298-0.407,2.242-0.703,3.187-1C1131.909,761.884,1132.104,762.1,1132.298,762.315z"/>
            <path fill-rule="evenodd" d="M1133.973,745.402c2.49-3.752,4.98-7.505,7.47-11.258c0.438,0.278,0.876,0.556,1.314,0.834
                c-2.345,3.838-4.688,7.676-7.033,11.514C1135.141,746.129,1134.557,745.766,1133.973,745.402z"/>
            <path fill-rule="evenodd" d="M823.439,1298.653c-5.193,0-10.387,0-15.58,0c0.02-0.357,0.039-0.715,0.059-1.071
                c5.176,0,10.353,0,15.528,0C823.444,1297.938,823.441,1298.296,823.439,1298.653z"/>
            <path fill-rule="evenodd" d="M813.634,1241.291c-1.157-4.948-2.315-9.896-3.474-14.844c0.545-0.145,1.089-0.288,1.634-0.433
                c1.344,4.937,2.688,9.873,4.032,14.81C815.096,1240.98,814.364,1241.136,813.634,1241.291z"/>
            <path fill-rule="evenodd" d="M817.775,1226.494c0,4.082,0,8.164,0,12.245c-0.437,0.009-0.872,0.018-1.309,0.026
                c0-4.094,0-8.188,0-12.281C816.903,1226.487,817.339,1226.491,817.775,1226.494z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(71)} fill={props.fillColors[71]}  d="M714.764,477.8c3.345,5.383,3.358,5.411,9.716,2.366
                c2.098-1.005,4.175-2.086,6.143-3.323c15.899-9.999,31.769-20.045,47.654-30.066c0.662-0.418,1.388-0.736,1.994-1.055
                c0.339,0.374,0.506,0.473,0.527,0.596c0.646,3.654,0.011,8.891,2.182,10.632c4.216,3.382,9.914,6.382,15.165,6.69
                c9.512,0.559,12.027,7.522,14.429,13.875c1.849,4.891,1.362,10.815,1.215,16.261c-0.116,4.299,1.231,7.194,5.216,8.534
                c4.631,1.557,5.271,4.938,3.601,8.604c-2.806,6.157-5.238,12.873-9.534,17.894c-13.736,16.051-28.007,31.671-42.607,46.943
                c-5.228,5.469-12.075,9.364-18.001,14.201c-2.535,2.07-3.989,4.039-2.46,8.405c1.335,3.813-0.038,9.269-1.771,13.335
                c-5.718,13.424-13.916,25.365-24.035,35.908c-15.219,15.856-30.519,31.634-45.804,47.425c-0.774,0.8-1.739,1.414-3.707,2.989
                c7.314-20.425,20.279-36.27,29.859-54.101c-1.002-0.443-1.435-0.809-1.854-0.795c-17.772,0.594-35.67,0.121-53.279,2.152
                c-17.595,2.029-34.899,6.568-52.332,10.008c-2.25,0.443-4.5,0.892-7.625,1.511c3.189-19.083,16.163-33.472,21.459-50.509
                c-5.523-0.813-10.875-1.602-17.705-2.607c1.679-6.698,2.507-12.973,4.816-18.642c6.071-14.904,15.686-27.53,28.026-37.729
                c18.35-15.165,37.102-29.848,55.824-44.555C692.7,494.245,703.804,486.095,714.764,477.8z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(72)} fill={props.fillColors[72]}  d="M555.874,847.836c-24.512,0.941-49.024,1.882-73.536,2.822
                c-0.106,0.381-0.212,0.763-0.317,1.144c3.453,1.086,6.838,2.732,10.371,3.16c15.512,1.875,31.07,3.355,46.603,5.062
                c8.923,0.98,17.836,2.053,26.732,3.244c1.893,0.254,3.691,1.209,5.532,1.841c0.029,0.451,0.059,0.902,0.087,1.354
                c-3.798,0.853-7.62,2.523-11.39,2.413c-14.297-0.42-28.597-1.161-42.859-2.252c-28.197-2.157-56.37-4.628-84.553-6.981
                c-0.257-0.021-0.499-0.226-1.51-0.707c10.098-1.869,19.531-3.614,28.966-5.36c-0.044-0.631-0.088-1.262-0.132-1.893
                c-15.407-7.639-32.558-2.749-48.897-5.895c3.257-3.229,0.555-4.705-1.701-5.382c-7.779-2.338-15.651-4.388-23.536-6.353
                c-3.042-0.758-6.214-0.995-9.322-1.882c7.096,0.393,14.207,0.613,21.285,1.221c8.119,0.696,16.214,1.69,24.307,2.661
                c1.619,0.194,3.426,0.499,4.729,1.374c7.852,5.262,16.79,6.286,25.799,6.468c25.985,0.525,51.981,0.603,77.966,1.194
                c8.454,0.192,16.885,1.43,25.326,2.188C555.84,847.465,555.857,847.65,555.874,847.836z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(73)} fill={props.fillColors[73]}  d="M507.248,883.334c-11.7,0.928-23.4,1.855-35.101,2.783
                c-0.082,0.422-0.163,0.844-0.245,1.266c12.646,2.24,25.294,4.481,37.94,6.723c-0.021,0.281-0.041,0.562-0.062,0.843
                c-1.485,0.232-2.972,0.465-4.458,0.697c-0.055,0.556-0.109,1.111-0.165,1.667c14.024,2.514,28.049,5.028,42.073,7.542
                c-0.031,0.244-0.062,0.489-0.094,0.734c-44.43-3.627-88.86-7.254-132.87-10.846c16.798-3.439,34.62-0.771,52.128-3.524
                c-12.382-4.724-25.66-4.387-38.461-6.877c5.888-0.436,11.774-0.87,17.662-1.306c0.131-0.508,0.262-1.016,0.393-1.523
                c-3.803-1.317-7.605-2.634-12.701-4.397c25.585,1.652,49.747,3.213,73.91,4.773C507.214,882.37,507.231,882.853,507.248,883.334z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(74)} fill={props.fillColors[74]}  d="M225.542,921.364c-8.605,1.252-17.211,2.503-25.816,3.755
                c-0.079,0.442-0.157,0.885-0.236,1.327c6.945,1.425,13.89,2.849,21.406,4.39c-1.575,0.625-2.889,1.146-4.202,1.667
                c-0.057,0.412-0.113,0.825-0.169,1.237c14.067,1.918,28.135,3.835,43.346,5.909c-10.321,4.113-38.779,3.595-81.395-1.071
                c6.064-1.197,11.031-2.178,15.998-3.158c0.061-0.615,0.122-1.231,0.184-1.847c-7.375-1.797-14.75-3.594-23.016-5.608
                c2.047-0.766,3.319-1.241,4.59-1.717c-0.016-0.411-0.032-0.822-0.049-1.233c-7.988-0.782-15.983-1.492-23.961-2.368
                c-8.078-0.887-16.138-1.938-24.292-3.817c3.517,0,7.034,0,10.551,0c0.045-0.526,0.09-1.054,0.135-1.581
                c-4.338-1.11-8.677-2.221-13.015-3.331c0.037-0.311,0.074-0.621,0.111-0.932c33.246,2.219,66.491,4.438,99.736,6.655
                C225.479,920.216,225.511,920.79,225.542,921.364z"/>
            </g>
            </svg>
        </div>
    )
}