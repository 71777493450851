import React from 'react';
import './Yosemite2.css';

export default function Yosemite2(props) {
    
    return (
        <div className={`yosemite2-container ${props.size}`}> 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id}>
            <g className="yosemite2-image">

            <path d="M1281-5.111c0,1.333,0,2.667,0,4c-4.633-0.803-9.311-0.081-13.952-0.398c-3.174-0.217-5.653,0.753-8.164,2.803
                c-16.134,13.169-31.935,26.75-48.46,39.439c-3.354,2.575-5.992,5.78-8.02,9.472c-4.629,8.431-11.854,14.009-20.507,17.507
                c-6.103,2.467-10.984,6.157-15.134,11.055c-1.312,1.55-2.653,2.922-4.729,3.477c-4.964,1.326-8.936,4.321-12.65,7.733
                c-5.007,4.598-7.18,4.768-12.5,0.503c-2.322-1.862-4.486-1.993-6.858-0.541c-2.27,1.389-4.524,2.819-6.668,4.393
                c-7.125,5.228-13.884,10.916-20.593,16.66c-1.521,1.303-2.968,2.746-4.832,3.51c-7.918,3.244-15.009,7.916-22.1,12.599
                c-2.553,1.686-5.256,2.637-8.411,2.686c-4.903,0.075-9.097,1.895-11.903,6.175c-1.7,2.593-4.076,4.341-6.822,5.683
                c-3.889,1.9-7.768,3.825-11.623,5.793c-1.135,0.58-2.602,0.777-3.111,2.358c1.012,2.559,2.214,5.09,2.179,8.051
                c-0.034,2.924-2.055,5.087-2.788,7.652c1.245,0.815,2.189,0.285,3.107-0.014c13.816-4.504,26.816-10.893,39.813-17.284
                c1.198-0.589,2.338-1.295,2.886-2.631c2.152-5.246,7.039-6.922,11.726-7.095c5.731-0.211,10.242-2.547,14.757-5.368
                c5.655-3.534,11.308-7.018,17.604-9.373c2.64-0.987,4.963-2.678,7.206-4.442c6.023-4.738,12.061-9.464,18.192-14.061
                c2.926-2.193,6.044-4.14,9.155-6.071c0.881-0.546,2.02-1.419,3.346,0.16c-1.311,5.361-7.043,8.133-9.281,13.635
                c2.669-0.308,4.352-1.433,6.019-2.421c8.452-5.014,16.83-10.152,25.271-15.185c2.284-1.361,4.576-3.076,7.081-3.607
                c3.196-0.679,5.285-2.476,7.378-4.625c5.535-5.684,11.547-10.28,19.758-11.881c5.863-1.144,10.605-4.65,12.731-10.828
                c2.929-8.51,8.453-14.557,17.128-17.508c2.3-0.782,4.385-2.085,4.711-4.687c0.67-5.354,4.334-7.22,8.924-7.855
                c4.412-0.611,8.273-2.449,12.033-4.629c2.722-1.577,5.402-3.223,8.102-4.838c0,16,0,32,0,48c-2.495,0.695-4.377,2.696-4.288,4.856
                c0.196,4.741-3.46,6.469-5.964,9.138c-1.064,1.135-2.36,1.724-4.049,1.152c-3.053-1.033-5.285,0.623-6.859,2.84
                c-2.166,3.049-4.992,5.322-7.777,7.682c-1.928,1.633-3.848,3.234-3.822,6.095c0.01,1.083-0.531,1.988-1.447,2.467
                c-3.217,1.682-6.414,4.424-9.742,4.614c-4.301,0.245-7.104,2.359-10.221,4.485c-2.747,1.874-5.438,3.842-8.275,5.569
                c-2.379,1.449-4.344,3.005-4.533,6.074c-0.163,2.633-2.18,4.306-4.311,3.888c-4.905-0.961-8.645,1.871-12.852,3.097
                c-2.75,0.801-5.17,2.295-6.166,5.327c-0.434,1.316-1.568,2.052-2.616,2.907c-4.393,3.584-8.933,6.878-14.378,8.728
                c-0.896,0.305-1.926,0.619-2.327,1.708c0.304,0.141,0.593,0.389,0.88,0.385c0.65-0.008,1.359,0.003,1.939-0.244
                c7.504-3.197,14.818-6.663,19.043-14.257c0.232-0.417,0.631-0.836,1.055-1.036c4.45-2.094,8.674-4.832,13.934-4.528
                c4.45,0.256,6.834-1.475,7.775-5.87c0.388-1.815,1.176-3.061,2.687-3.93c4.339-2.496,8.308-5.527,12.343-8.463
                c1.529-1.113,3.176-1.352,5.045-1.626c4.794-0.703,8.923-3.224,12.805-6.056c0.496-0.362,1.057-1.089,1.039-1.629
                c-0.131-3.978,2.901-5.723,5.371-7.872c2.006-1.745,4.042-3.426,5.572-5.672c1.48-2.17,3.383-3.67,6.32-2.621
                c0.965,0.345,2.159,0.262,2.882-0.379c3.59-3.18,8.623-5.271,8.491-11.334c-0.032-1.499,1.072-2.717,2.447-3.496c0,25,0,50,0,75
                c-5.488-0.713-10.576-2.561-15.314-5.401c-1.854-1.111-3.679-2.279-5.58-3.302c-4.346-2.336-9.519,0.214-13.93-2.33
                c-0.954-0.55-2.659-0.149-3.895,0.224c-1.627,0.491-3.197,0.742-4.893,0.607c-5.108-0.405-8.906,2.133-10.99,6.38
                c-2.536,5.17-5.297,10.377-5.576,16.401c-0.209,4.462-1.275,8.797-2.13,12.995c-2.655,0.657-4.514,0.093-6.212-1.658
                c-2.136-2.202-4.897-3.356-7.89-4.056c-3.078-0.719-6.077-1.797-9.17-2.428c-6.206-1.268-11.53-4.174-16.298-8.234
                c-1.68-1.43-3.582-2.6-5.728-2.922c-4.329-0.65-8.354-2.216-12.438-3.637c-2.444-0.85-4.97-1.173-7.271,0.117
                c-1.857,1.042-3.454,0.69-5.187-0.021c-5.399-2.219-10.922-3.978-16.664-5.169c-4.934-1.024-9.049-0.105-13.085,2.972
                c-3.405,2.595-7.42,4.597-11.588,5.463c-6.453,1.341-11.602,4.796-16.454,8.816c-3.047,2.523-11.477,18.148-12.364,21.978
                c-0.112,0.485-0.249,0.981-0.254,1.474c-0.063,5.912-2.274,11.407-3.297,17.132c-0.337,1.881-1.094,3.398-2.896,4.385
                c-3.457,1.894-5.461,5.138-7.871,8.142c-3.342,4.167-5.138,8.746-6.616,13.715c-0.89,2.992-2.556,5.775-4.036,8.566
                c-3.371,6.356-5.965,13.014-7.901,19.928c-0.713,2.546-1.221,5.15-1.816,7.702c-0.81,0.297-1.41,0.65-2.045,0.729
                c-7.316,0.91-14.249,2.406-20.458,7.072c-3.338,2.508-7.568,2.906-12.091,0.258c-8.894-5.208-18.229-9.705-27.95-13.252
                c-7.756-2.83-15.66-4.505-23.98-2.593c-5.404,1.243-10.492,3.232-15.345,5.862c-2.489,1.349-4.834,3.005-7.413,4.137
                c-5.857,2.57-11.584,5.949-18.467,3.848c-1.269-0.388-2.613,0.041-3.898,0.525c-6.723,2.535-14.064,3.144-20.396,7.082
                c-1.837,1.142-4.144,1.618-6.034,3.11c-1.611,1.271-3.88,2.082-6.035,1.086c-2.287-1.057-3.791,0.088-5.385,1.38
                c-1.291,1.047-1.809,2.816-3.635,3.731c-8.455-1.592-16.608,1.462-24.944,2.939c-3.632,0.644-7.251,1.622-10.858,2.155
                c-3.697,0.546-7.201,1.849-10.967,2.276c-3.002,0.34-6.535,1.201-9.258,3.066c-2.092,1.434-3.967,2.883-6.68,2.745
                c-0.752-0.039-1.592,0.463-2.295,0.878c-4.352,2.569-8.604,5.293-13.959,5.339c-1.063,0.009-2.193,0.758-3.166,1.367
                c-3.931,2.458-8.675,2.446-12.831,2.829c-4.11,0.379-6.946,1.28-9.169,4.451c-0.781,1.114-1.363,2.424-2.918,2.395
                c-6.232-0.116-9.592,5.545-14.876,7.312c-1.901,3.792-5.535,5.61-8.729,8.033c-2.538,1.926-5.002,3.796-7.986,4.92
                c-2.471,0.93-5.014,1.698-6.997,3.456c-3.78-2.863-7.269-5.694-10.946-8.251c-6.613-4.595-13.919-7.066-22.08-6.568
                c-7.98,0.488-15.967,0.885-23.938,1.488c-2.974,0.225-5.97,0.34-8.911,1.104c-6.082,1.581-9.963,4.874-10.605,11.562
                c-0.529,5.499-2.361,10.717-4.711,15.723c-0.801,1.706-1.73,3.057-4.077,2.953c-4.739-0.21-8.323,2.248-10.958,5.896
                c-1.386,1.918-2.881,2.437-5.057,2.256c-5.976-0.496-12.014-0.522-17.929-1.399c-5.388-0.799-10.456-2.299-14.126-7.317
                c-3.547-4.849-9.102-8.162-15.112-7.369c-6.212,0.82-12.315,1.001-18.519,1.009c-1.969,0.003-3.941,0.298-5.914-0.076
                c-9.812-1.861-19.772-3.175-29.325-6.08c-11.346-3.45-21.717-1.343-31.756,4.062c-5.425,2.92-10.716,6.088-16.092,9.101
                c-1.422,0.797-2.683,1.997-4.5,2.037c-1.422-1.013-1.208-2.635-1.503-4.061c-1.196-5.796-3.042-11.278-7.622-15.387
                c-1.058-0.949-1.458-2.214-1.909-3.454c-2.421-6.65-6.11-12.559-10.495-18.064c-7.548-9.476-16.658-17.235-26.643-23.989
                c-2.146-1.452-3.759-3.128-4.977-5.409c-2.352-4.406-4.836-8.747-7.42-13.022c-1.986-3.286-4.112-6.652-7.553-8.519
                c-4.151-2.251-6.937-5.643-9.331-9.491c-3.343-5.373-6.614-10.792-9.933-16.18c-1.068-1.733-1.713-3.545-1.807-5.625
                c-0.257-5.656-0.663-11.306-0.981-16.96c-1.115-19.792-2.243-39.585-4.213-59.315c-1.62-16.217-8.755-28.852-24.068-35.907
                c-3.168-1.459-6.399-2.881-9.737-3.853c-11.879-3.458-23.125-8.466-34.319-13.62c-37.978-17.488-75.104-36.701-112.425-55.519
                c-18.1-9.125-37.258-14.104-57.393-15.871c-21.433-1.881-42.594,0.306-63.684,3.737c-10.21,1.661-20.39,3.081-30.793,2.588
                c-12.873-0.609-25.472-2.75-37.927-5.931c-0.451-0.115-0.973-0.25-1.531,0.429c-0.438,1.22-0.726,2.622,0.729,4.065
                c22.322,5.448,44.844,8.096,68.042,3.702c23.13-4.382,46.543-6.265,70.116-3.555c17.024,1.957,33.341,6.329,48.697,14.031
                c11.611,5.824,23.179,11.732,34.794,17.547c30.822,15.43,61.51,31.135,93.107,44.968c8.413,3.683,17.22,6.195,25.82,9.313
                c15.235,5.525,23.905,16.299,25.836,32.451c1.326,11.087,1.986,22.229,2.749,33.361c1.059,15.457,1.961,30.924,2.888,46.39
                c0.093,1.548,0.507,2.918,1.277,4.24c4.193,7.196,8.35,14.417,13.21,21.196c1.653,2.306,3.346,4.666,5.938,5.978
                c4.222,2.137,6.881,5.701,9.22,9.588c2.576,4.282,5.112,8.588,7.612,12.915c1.529,2.647,3.547,4.696,6.127,6.412
                c7.515,4.999,14.378,10.814,20.536,17.429c6.415,6.892,11.772,14.462,14.829,23.481c0.371,1.094,0.636,2.213,1.634,3.014
                c4.439,3.558,6.111,8.48,7.128,13.877c0.683,3.628,1.287,7.381,3.1,10.968c1.864-1.068,3.297-1.895,4.735-2.712
                c7.384-4.198,14.671-8.579,22.182-12.537c7.521-3.964,15.47-6.146,24.071-3.815c11.746,3.183,23.739,5.178,35.688,7.387
                c0.977,0.181,1.907,0.559,2.911,0.084c2.764-1.308,5.547-0.776,8.295,0.038c2.47,0.732,4.746,1.081,7.026-0.784
                c1.619-1.324,3.787-1.286,5.767-0.781c4.469,1.14,8.495,3.074,10.803,7.351c0.665,1.23,1.364,2.2,2.644,2.82
                c4.363,2.113,8.782,4.162,13.649,4.641c7.125,0.702,14.283,1.069,21.414,1.712c1.723,0.155,2.869-0.282,3.78-1.71
                c0.716-1.121,1.494-2.22,2.378-3.209c2.063-2.309,4.488-4.341,7.774-3.487c2.892,0.751,4.421-0.504,5.683-2.661
                c1.008-1.72,2.016-3.457,2.812-5.278c2.08-4.75,3.678-9.678,4.156-14.855c0.42-4.529,2.863-6.898,7.151-7.692
                c0.817-0.151,1.628-0.364,2.451-0.454c9.774-1.069,19.573-1.7,29.405-2.027c6.976-0.232,13.089,1.854,18.804,5.617
                c4.321,2.845,8.188,6.251,12.11,9.822c4.053-0.978,7.441-3.287,10.952-5.14c3.397-1.793,7.483-2.764,9.669-6.472
                c0.211-0.356,0.846-0.577,1.316-0.646c2.428-0.354,3.736-2.097,4.772-4c0.668-1.227,1.397-2.324,2.655-2.852
                c2.81-1.18,5.773-1.961,7.629-4.789c0.611-0.932,2.15-0.683,3.318-0.673c1.934,0.015,3.814-0.517,4.864-2.09
                c2.106-3.156,4.019-6.274,8.644-4.417c0.34,0.137,0.963-0.242,1.375-0.504c1.927-1.221,4.1-1.226,6.258-1.417
                c1.644-0.146,3.363-0.181,4.764-1.265c2.783-2.154,5.705-2.648,8.885-0.418c1.777-2.79,3.77-4.503,6.951-5.434
                c2.393-0.701,4.361-2.684,7.296-2.129c0.689,0.13,1.813-0.852,2.399-1.59c1.53-1.927,3.086-2.266,5.631-1.832
                c1.712,0.292,3.07-2.854,5.488-2.836c0.166,0.001,0.37-0.066,0.494,0.005c2.246,1.307,3.992,0.139,5.827-1.038
                c1.001-0.642,2.226-0.512,3.405-0.256c1.184,0.257,2.321,0.078,3.295-0.729c3.914-3.244,8.17-2.332,11.9-1.195
                c7.216-4.253,14.273-4.872,21.694-2.053c2.247-1.677,2.359-5.206,4.683-6.062c2.174-0.356,2.584,1.844,4.109,1.272
                c3.947-1.958,7.334-4.85,11.682-6.213c2.984-0.936,5.341-3.955,9.023-2.509c0.157,0.062,0.486-0.413,0.773-0.567
                c5.063-2.736,10.072-5.704,16.179-3.105c1.162,0.495,2.28-0.264,3.394-0.514c6.885-1.545,13.288-4.227,19.285-7.977
                c13.279-8.302,27.074-9.481,41.445-2.884c8.932,4.1,17.861,8.186,26.445,12.988c1.274,0.713,2.407,1.886,4.098,1.454
                c4.016-1.026,8.314-1.395,11.639-4.178c3.343-2.799,7.213-3.904,11.401-4.605c3.559-0.596,7.294-0.514,10.78-2.173
                c0.224-1.015,0.473-1.972,0.642-2.942c1.733-9.942,5.208-19.252,10.048-28.09c1.276-2.332,2.586-4.716,3.363-7.236
                c1.416-4.601,3-8.906,6.104-12.838c2.298-2.912,4.335-5.815,7.615-7.664c1.287-0.726,2.732-1.359,3.108-3.036
                c1.2-5.349,2.731-10.653,3.485-16.065c0.699-5.026,1.414-9.831,4.806-13.911c1.135-1.365,1.761-3.201,2.433-4.895
                c3.275-8.25,10.258-11.83,18.069-14.196c4.956-1.501,9.726-3.486,13.88-6.418c2.982-2.105,5.799-2.999,9.275-2.453
                c3.967,0.623,7.805,1.697,11.559,3.096c3.569,1.331,7.132,2.68,10.547,3.964c4.257-3.072,4.257-3.073,8.797-1.452
                c0.94,0.336,1.852,0.828,2.819,1c6.675,1.181,12.463,4.112,17.874,8.178c6.032,4.535,13.578,5.248,20.598,7.209
                c3.125,0.873,5.98,2.042,8.172,4.507c0.779,0.877,1.514,1.793,2.844,1.79c3.437-0.011,6.934,0.584,10.176-0.809
                c0.096-0.54,0.206-0.867,0.205-1.193c-0.043-9.214,1.13-18.173,4.799-26.759c2.43-5.683,4.5-7.201,10.912-6.824
                c0.493,0.029,1.152,0.238,1.455,0.003c3.586-2.782,6.542-0.922,9.268,1.345c3.089-1.116,5.796-1.808,8.793-0.003
                c6.078,3.658,12.062,7.694,19.562,7.978c0.753,0.029,1.483,0.673,2.224,1.032c0,1,0,2,0,3c-1.568,0.315-2.855-0.815-4.346-0.854
                c-4.601-0.118-8.726-1.445-12.485-4.215c-1.978-1.457-4.468-2.201-6.53-3.564c-1.594-1.053-3.037-1.3-4.602-0.331
                c-2.381,1.475-4.363,1.056-6.169-0.984c-0.547-0.619-1.247-1.104-2.237-0.868c-1.059,0.681-1.499,1.59-1.021,2.917
                c0.617,1.717,1.246,3.441,1.684,5.208c0.793,3.208,2.514,5.027,5.997,5.56c6.618,1.013,11.47,4.926,15.216,10.322
                c0.465,0.669,0.658,1.676,1.537,1.864c4.332,0.925,8.225,3.8,12.957,2.946c0,0.667,0,1.333,0,2
                c-3.237,0.231-6.245-0.604-9.219-1.807c-1.481-0.599-3.002-1.496-4.746-0.442c-2.631,1.589-5.721,1.657-8.586,2.465
                c-3.454,0.973-7.123,0.558-11.576,1.542c3.373,2.137,5.943,1.753,8.544,1.474c3.91-0.419,7.506,0.117,10.238,3.939
                c-5.514,3.229-10.312,7.193-17.099,5.785c-1.761-0.365-3.656-0.152-5.486-0.092c-6.797,0.222-13.055-0.769-16.592-7.485
                c-4.532,0.575-8.681,0.635-12.838,0.636c-2.166,0-3.815-0.521-5.085-2.415c-1.558-2.323-3.935-3.464-6.646-4.065
                c-2.598-0.576-5.129-1.461-7.734-1.993c-7.516-1.536-14.065-4.979-19.986-9.759c-0.767-0.618-1.627-1.373-2.532-1.521
                c-4.47-0.732-8.708-2.184-12.909-3.792c-1.445-0.554-2.191,0.059-2.428,1.402c0.158,1.078,1.061,1.313,1.713,1.764
                c4.521,3.124,9.256,5.979,13.52,9.42c2.45,1.978,4.945,3.301,7.959,3.672c5.85,0.72,10.742,3.367,15.089,7.196
                c1.874,1.651,3.784,3.265,5.612,4.967c1.996,1.859,4.137,3.109,6.992,3.439c2.949,0.341,6.141,0.788,8.664,2.195
                c6.047,3.373,12.732,4.034,19.249,4.83c10.065,1.23,20.24,1.77,30.188-1.503c5.072-1.669,10.39-2.294,15.693-2.849
                c0,0.667,0,1.333,0,2c-5,0.271-10.02,0.853-14.725,2.437c-10.936,3.679-22.025,3.009-33.084,1.511
                c-6.191-0.839-12.512-1.596-18.196-4.821c-2.017-1.144-4.528-1.646-7.849-1.339c5.693,4.222,7.038,10.178,9.177,15.709
                c0.12,0.311,0.306,0.609,0.358,0.93c0.88,5.432,4.271,8.506,9.12,10.785c4.946,2.324,9.894,4.971,13.658,9.055
                s8.2,7.292,12.548,10.625c2.831,2.169,6.222,2.766,9.678,2.871c1.012-3.232-3-4.524-2.38-7.366
                c5.897-0.535,11.385,0.641,21.694,4.603c0,1,0,2,0,3c-5.399-4.358-11.795-5.26-18.594-5.803c0.619,1.908,1.252,3.294,1.496,4.747
                c0.37,2.195-0.549,3.069-2.797,2.926c-0.656-0.042-1.298-0.288-1.955-0.374c-7.434-0.968-12.717-5.862-17.578-10.708
                c-5.664-5.646-12.266-9.575-19.068-13.424c-6.885-3.896-14.546-4.077-22.166-5.365c-1.488,4.463-4.889,7.014-7.682,10.062
                c-1.764,1.922-3.794,3.762-3.338,7.257c2.604,0.904,5.264,1.757,7.87,2.75c5.127,1.951,10.786,3.408,14.341,7.726
                c4.184,5.08,9.311,8.172,15.334,10.21c0.623,0.211,1.205,0.561,1.78,0.894c6.205,3.585,12.818,6.318,19.312,9.303
                c2.287,1.052,4.597,1.863,7.124,2.106c5.539,0.534,10.655,2.266,15.508,5.072c3.274,1.893,6.689,3.653,10.412,4.623
                c0,0.667,0,1.333,0,2c-4.377-1.137-8.336-3.3-12.213-5.471c-4.318-2.418-8.844-3.859-13.676-4.365
                c-2.878-0.301-5.505-1.182-8.1-2.352c-7.281-3.284-14.559-6.573-21.548-10.463c-3.586-1.996-7.384-2.416-11.526-2.321
                c-6.949,0.159-12.26,3.521-17.765,7.561c7.497,3.83,14.669,8.003,22.258,11.187c4.924,2.066,9.778,4.9,15.657,4.329
                c3.127-0.304,5.564,1.763,7.229,4.56c2.51,4.218,6.171,6.56,11.113,7.478c9.631,1.789,19.346,3.331,28.569,6.856
                c0,0.667,0,1.333,0,2c-9.182-3.664-18.89-4.961-28.618-6.618c1.687,2.728,3.954,4.894,4.888,7.688
                c1.527,4.571,4.776,7.39,8.555,9.894c1.521,1.008,3.138,1.97,4.389,3.265c3.216,3.331,6.789,3.634,10.787,1.771
                c0,1.333,0,2.667,0,4c-7.244,1.436-11.505,5.609-12.554,12.715c-1.021,6.911-1.999,13.831-1.813,20.867
                c0.178,6.705-0.467,13.3-3.481,19.477c-0.798,1.637-1.084,3.471-1.171,5.333c-0.664,14.138-1.209,28.283-2.103,42.407
                c-1.22,19.305-3.496,38.444-10.165,56.8c-1.756,4.834-3.687,9.651-4.293,14.828c-1.045,8.931-2.606,17.78-4.16,26.631
                c-0.354,2.016-0.499,3.951,1.062,5.369c4.122-4.263,2.363-10.703,4.967-15.159c2.059-0.483,2.153,1.486,3.592,1.362
                c1.481-5.43,3.01-10.869,4.445-16.334c2.412-9.181,4.032-18.529,5.67-27.868c0.676-3.862,2.25-7.146,6.042-8.523
                c3.394-1.232,4.265-3.745,4.765-6.815c2.439-14.961,4.521-29.955,4.748-45.15c0.095-6.335,0.69-12.651,1.716-18.889
                c0.719-4.375,0.806-8.918,2.733-13.051c0,2.667,0,5.333,0,8c-0.27,0.59-0.727,1.164-0.782,1.773
                c-0.694,7.622-1.653,15.237-1.908,22.876c-0.388,11.665-1.324,23.249-3.151,34.776c-0.666,4.192-1.77,8.354-1.799,12.889
                c2.777-0.478,5.209-0.896,7.641-1.314c0,0.667,0,1.333,0,2c-4.842,0.062-8.952,2.262-13.096,4.416
                c-2.873,1.494-4.481,3.687-5.02,6.953c-2.517,15.284-5.829,30.395-10.412,45.205c-0.672,2.169-0.691,4.2,0.667,6.419
                c1.392-1.188,1.145-3.842,3.623-3.65c2.173,1.769,1.17,4.898,2.743,7.041c2.701-3.476,2.959-8.067,5.595-11.074
                c2.063,0.178,1.559,1.977,2.847,2.673c3.017-4.776,3.912-10.512,6.248-15.675c3.141,1.153,2.147,5.367,5.538,5.345
                c1.094-2.425-0.354-5.306,1.267-7.651c0,6,0,12,0,18c-3.365-0.234-2.626-3.062-2.723-4.902c-0.152-2.887-1.459-4.666-3.768-6.23
                c-1.078,4.247-5.969,16.115-7.032,17.185c-1.993-0.347-1.126-2.657-1.87-3.651c-3.875,2.615-2.618,7.814-6.326,10.248
                c-2.512-1.899-1.787-4.959-2.912-7.322c-2.061,0.889-1.309,4.213-4.464,3.943c-1.681-2.25-0.188-6.344-3.913-7.963
                c-1.155,4.719-2.246,9.596-4.288,12.997c-2.833,1.097-2.31-2.042-3.39-1.575c-4.676,5.299-2.967,12.109-5.783,17.447
                c-3.814-1.399-0.99-5.77-4.307-7.223c-2.881,4.569-4.391,9.375-4.033,14.629c0.317,4.646-1.199,8.816-2.849,12.982
                c-0.421,1.062-0.984,2.154-2.468,1.951c-2.285-2.445-4.146-5.066-4.279-8.678c-2.354,4.828-4.504,9.604-6.416,14.473
                c-0.552,1.407,0.404,2.741,1.357,3.979c2.113-1.526,1.671-5.193,4.93-5.203c3.341,3.167,6.336,6.438,6.727,11.567
                c2.007-1.617,1.693-3.237,1.856-4.669c0.435-3.8,0.606-7.639,1.212-11.408c0.372-2.312,0.131-5.145,2.961-6.155
                c1.872-0.546,1.37,2.433,3.34,1.851c1.846-4.52-0.988-10.567,4.137-14.742c3,3.867,6.418,6.305,10.804,7.305
                c2.103-11.28,4.312-16.434,8.352-18.215c-0.485,4.5,3.462,7.197,3.544,11.523c0.75-7.628,4.627-13.646,8.293-19.674
                c1.742,0.498,2.137,1.42,2.189,2.434c0.13,2.492,0.164,4.983,3.013,6.118c0.519,3.8,5.454,4.62,5.63,8.713
                c0.534-2.101,0.653-4.093,1.039-6.03c0.512-2.562-0.349-5.385,1.438-7.703c0,4,0,8,0,12c-0.266,0.604-0.62,1.188-0.779,1.818
                c-0.35,1.391-0.042,3.395-2.075,3.447c-2.052,0.053-1.958-1.85-2.114-3.32c-0.103-0.977-0.238-1.791-1.087-2.604
                c-4.59-4.397-5.84-6.297-7.339-10.556c-2.898,4.522-5.653,8.823-5.625,14.359c0.01,2.091-1.087,3.776-3.904,4.042
                c-2.041-3.062-0.232-8.077-4.594-10.039c-1.173,4.669-4.005,8.764-4.074,13.744c-0.018,1.271-1.1,2.402-2.577,2.74
                c-4.161-1.354-7.517-3.926-10.378-7.267c-2.999,4.841,0.795,11.341-3.853,15.159c-2.024,0.018-2.211-2.023-3.81-1.971
                c-0.814,1.924-0.672,3.782-0.945,5.568c-0.682,4.438-0.473,8.988-1.69,13.357c-0.317,1.139-0.649,2.342-2.028,2.553
                c-1.765,0.271-2.521-1.186-2.665-2.393c-0.481-4.037-4.267-5.625-5.779-9.131c-1.595,2.062-1.663,4.672-4.14,5.336
                c-0.334-0.137-0.685-0.197-0.926-0.393c-1.922-1.559-1.592-4.335-3.232-6.072c-2.088,3.655-4.594,11.592-4.781,14.883
                c-0.122,2.158-0.275,4.32-0.571,6.46c-0.179,1.291-0.516,2.657-2.302,2.794c-2.5-1.783-1.387-5.273-3.303-7.266
                c-5.62,6.457-9.769,13.284-6.314,22.391c1.831-1.213,1.345-3.185,2.145-4.582c0.602-1.05,1.451-1.893,2.776-1.759
                c1.088,0.11,1.689,0.898,2.123,1.866c0.56,1.248,0.615,2.568,0.78,3.891c0.154,1.229-0.244,2.713,1.283,3.582
                c1.42-1.044,0.991-2.707,1.412-4.023c0.502-1.566,1.172-2.785,2.956-2.887c1.587-0.09,2.338,0.901,2.854,2.285
                c0.305,0.814,0.186,2.1,2.007,2.084c0.505-2.089,0.324-5.123,1.709-6.275c2.79-2.323,3.238-5.156,3.798-8.234
                c0.257-1.415,1.195-2.439,2.739-2.443c1.607-0.003,2.146,1.24,2.677,2.539c0.585,1.436,0.09,3.313,1.742,4.693
                c1.49-1.436,1.184-3.252,1.789-4.684c0.511-1.207,1.368-1.941,2.635-1.929c1.324,0.013,2.152,0.897,2.42,2.141
                c0.385,1.785,0.637,3.599,0.941,5.401c0.191,1.134-0.047,2.734,1.058,3.185c3.37,1.376,3.075,4.41,3.643,7.098
                c0.21,0.996,0.119,2.171,1.315,2.978c1.386-1.707,0.987-3.465,1.34-5.025c0.335-1.484,0.792-2.988,2.463-3.469
                c1.711-0.492,2.849,0.317,3.619,1.863c0.182,0.365,0.757,0.535,1.309,0.899c1.56-2.134,1.187-4.519,1.327-6.784
                c0.125-2.014,0.598-3.674,2.085-5.353c2.839-3.204,6.787-5.364,8.404-9.812c1.025-2.82,4.003-2.617,5.792,0.001
                c0.558,0.815,0.897,2.374,1.507,2.466c4.28,0.645,4.546,4.246,5.699,8.11c4.242-4.731,5.854,1.726,9.037,1.599
                c1.673-1.325,1.366-3.104,1.623-4.713c0.301-1.893,0.122-3.967,1.879-5.352c0,2,0,4,0,6c-0.301,0.942-0.637,1.875-0.896,2.828
                c-0.979,3.594-2.677,4.23-5.67,2.076c-0.929-0.67-1.539-1.788-2.79-1.932c-1.679-0.25-2.155,2.184-4.058,1.683
                c-1.314-1.124-1.354-2.827-1.709-4.437c-0.208-0.947-0.631-2.564-1.107-2.621c-3.518-0.417-4.213-3.846-6.258-5.811
                c-0.595,0.36-1.311,0.55-1.375,0.87c-0.918,4.564-5.205,6.56-7.778,9.812c-1.435,1.814-2.491,3.223-2.542,5.467
                c-0.029,1.324-0.165,2.661-0.404,3.965c-0.3,1.64-0.607,3.377-2.648,3.815c-1.896,0.407-2.532-1.171-3.491-2.265
                c-0.324-0.371-0.842-0.494-1.516-0.048c-1.205,1.193-0.579,3.005-0.969,4.565c-0.413,1.654-0.864,3.541-2.857,3.621
                c-2.352,0.094-2.819-1.871-3.065-3.787c-0.147-1.156-0.328-2.311-0.535-3.457c-0.176-0.975-0.32-1.949-1.417-2.446
                c-2.568-1.163-2.606-3.579-2.931-5.909c-0.245-1.759,0.338-3.901-1.699-5.07c-1.305,0.923-1.066,2.197-1.261,3.305
                c-0.285,1.63-1.036,2.92-2.784,3.039c-2.139,0.146-2.716-1.49-3.155-3.191c-0.339-1.311,0.143-3.029-1.663-3.709
                c-1.504,1.531-1.205,3.48-1.383,5.252c-0.238,2.386-2.863,2.702-3.641,4.518c-0.316,0.737-0.258,1.633-0.383,2.454
                c-0.125,0.82-0.178,1.665-0.42,2.451c-0.428,1.39-1.357,2.331-2.892,2.308c-1.307-0.02-2.12-0.836-2.563-2.033
                c-0.373-1.01-0.115-2.459-1.842-2.572c-1.533,1.375-0.855,3.461-1.562,5.104c-0.48,1.12-1.229,2.007-2.573,2.048
                c-1.41,0.045-2.146-0.902-2.475-2.066c-0.357-1.268-0.463-2.607-0.682-3.914c-0.207-1.227,0.246-2.732-1.492-3.396
                c-1.109,0.928-1.182,2.293-1.506,3.566c-0.401,1.566-1.253,2.864-3.057,2.69c-1.619-0.156-1.861-1.639-2.222-2.942
                c-0.905-3.268-0.424-6.638-0.805-9.94c-0.153-1.33,0.196-2.929-1.453-3.744c-1.607,1.543-1.25,4.209-3.606,4.808
                c-2.814,0.715-1.631,3.766-3.117,5.282c-1.431,1.46-3.613,1.267-4.58-0.579c-0.664-1.265-0.143-3.073-1.818-3.941
                c-1.561,1.847-1.479,4.162-1.217,6.199c0.67,5.182-4.633,7.278-5.646,11.583c-0.21,0.896-2.049,0.763-3.232,0.745
                c-4.527-0.066-4.959-0.385-6.043-4.854c-0.195-0.805-0.208-1.656-0.951-2.242c-0.298-0.049-0.651-0.217-0.941-0.141
                c-5.668,1.471-6.025,1.199-6.592-4.815c-0.174-1.856,0-3.834-1.416-5.89c-1.789,1.929-1.222,4.56-3.172,5.914
                c-2.731,1.896-3.935-1.365-5.899-1.818c-2.497,6.265-2.497,6.265-9.066,8.238c-0.198,0.435-0.554,0.878-0.587,1.344
                c-0.293,4.076-3.949,4.537-6.575,5.872c-1.708,0.868-3.134-0.308-3.802-2.176c-0.446-1.249,0.08-3.054-2.27-3.825
                c-0.311,3.389-4.147,3.372-5.262,6.086c-0.463,1.131-1.436,1.907-2.742,1.797c-1.581-0.134-2.25-1.395-2.551-2.707
                c-0.332-1.449-0.418-2.961-0.533-4.452c-0.146-1.907-0.242-3.813-1.276-5.485c-1.991,0.652-1.298,2.453-2.179,3.422
                c-1.314,1.443-3.547,1.926-4.257,0.377c-1.435-3.127-5.062-3.258-6.963-7.074c-0.217,4.627-0.566,7.941-1.485,11.141
                c-0.42,1.465-1.273,2.919-3.084,2.754c-1.488-0.135-2.124-1.404-2.506-2.777c-0.354-1.267,0.109-2.986-1.637-3.613
                c-1.155,0.983-1.145,2.203-1.34,3.333c-0.287,1.651-1.121,2.897-2.869,2.916c-1.571,0.017-2.234-1.321-2.607-2.638
                c-0.359-1.271-0.563-2.592-0.777-3.9c-0.317-1.949-0.185-3.996-1.284-5.836c-1.798,7.509-1.798,7.509-8.912,7.656
                c-1.071,1.307-0.571,3.148-1.237,4.648c-0.59,1.326-1.438,2.334-2.962,2.224c-1.261-0.091-2.069-0.959-2.449-2.185
                c-0.341-1.097-0.157-2.835-1.204-3.139c-3.17-0.922-3.611-3.987-5.193-6.378c-0.751,1.694-0.272,3.611-1.61,4.476
                c-2.017,1.304-3.354,2.895-4.42,5.079c-0.997,2.042-3.361,1.755-4.919-0.145c-0.689-0.841-0.236-2.47-1.87-2.672
                c-1.389,0.579-0.897,1.982-1.255,2.994c-0.504,1.429-0.785,3.04-2.697,3.134c-2.122,0.105-2.785-1.568-3.32-3.156
                c-0.479-1.42-0.734-2.916-1.286-5.197c-0.702,1.65-0.974,2.428-1.353,3.148c-0.655,1.242-1.594,2.235-3.121,2.06
                c-1.488-0.171-2.279-1.236-2.661-2.626c-0.29-1.055,0.019-2.452-1.731-2.794c-0.285,0.534-0.621,1.094-0.889,1.685
                c-0.272,0.604-0.367,1.314-0.732,1.845c-1.047,1.515-3.814,1.79-4.137,0.317c-0.619-2.817-3.598-4.037-4.186-6.115
                c-1.23-4.355-4.943-6.242-7.053-9.643c-1.55,1.366-1.284,3.065-2.012,4.373c-1.45,2.605-2.866,2.913-5.148,1.023
                c-1.244-1.03-1.648-3.01-3.82-3.22c-0.402,0.599-0.883,1.259-1.306,1.953c-0.456,0.749-1.021,1.298-2.679,1.403
                c-3.621-4.098-4.321-10.022-6.639-15.207c-2.378,1.541-1.129,5.375-4.266,5.807c-3.062-0.633-3.104-4.463-5.701-5.137
                c-1.374,0.138-1.874,0.977-2.455,1.822c-2.061,3.004-4.662,3.271-5.791,0.102c-2.035-5.713-7.883-9.375-7.568-16.836
                c-1.303,2.052-1.77,3.477-2.752,4.201c-2.521,1.86-4.269,3.913-4.213,7.285c0.017,1.045-0.79,2.178-1.418,3.141
                c-0.809,1.243-1.751,2.661-3.531,2.183c-1.473-0.396-1.805-1.819-2.027-3.171c-0.395-2.407-0.657-4.857-2.02-7.018
                c-2.086,0.914-1.903,2.857-2.639,4.25c-0.83,1.572-1.811,2.943-3.786,2.633c-2.026-0.319-2.409-2.033-2.667-3.742
                c-0.307-2.017,0.092-4.223-1.391-6.147c-4.568,7.249-4.732,7.311-8.812,3.517c-1.05,0.6-0.869,1.694-1.029,2.635
                c-0.279,1.639-0.383,3.318-0.802,4.918c-0.364,1.389-1.426,2.297-2.947,2.214c-1.402-0.075-1.879-1.103-2.09-2.378
                c-0.148-0.899,0.307-2.051-1.296-2.832c-0.783,4.123-5.465,5.625-6.054,10.152c-0.291,2.229-3.949,2.722-4.557,0.806
                c-1.077-3.398-5.296-3.768-5.737-7.973c-1.604,2.183-1.169,3.953-1.614,5.493c-0.479,1.662-1.162,3.372-3.232,3.175
                c-1.782-0.169-2.215-1.834-2.459-3.346c-0.428-2.642-0.771-5.296-1.265-8.738c-1.176,2.942-1.738,4.98-3.814,6.58
                c-6.143,4.733-6.326,4.804-8.944-2.997c-0.598,3.036-1.688,5.569-5.039,5.984c-2.731,0.339-4.027,1.951-4.179,4.696
                c-0.082,1.485-0.431,2.966-0.765,4.423c-0.453,1.979-1.045,3.902-3.688,4.457c-4.568-2.97-2.803-9.151-6.023-13.059
                c-2.932,2.363-1.108,6.77-3.652,8.464c-2.209,0.552-2.491-1.716-4.399-1.415c-0.481,3.498-2.887,5.898-5.083,8.69
                c-2.061,2.619-3.532,4.95-6.887,5.021c-1.495,2.775-0.744,5.51-1.001,8.127c-0.258,2.635-0.352,5.31-3.59,6.109
                c-2.233-1.861-0.722-4.641-2.018-6.625c-2.007,0.605-1.835,2.402-2.337,3.671c-0.488,1.233-0.562,2.849-1.668,3.544
                c-2.841,1.783-3.615,4.599-4.383,7.51c-0.209,0.796-0.471,1.617-0.897,2.311c-1.719,2.795-3.992,2.7-5.736-0.092
                c-0.72-1.152-0.391-3.103-2.575-3.391c-1.475,1.719-1.474,3.93-1.868,6.01c-0.31,1.631-0.589,3.271-1.017,4.873
                c-0.367,1.377-1.22,2.447-2.8,2.374c-1.656-0.076-2.071-1.337-2.384-2.733c-0.291-1.31,0.66-3.096-1.34-3.848
                c-1.403,1.24-0.953,3.174-1.806,4.583c-0.554,0.915-1.423,1.475-2.479,1.408c-1.078-0.067-1.924-0.755-2.137-1.81
                c-0.263-1.295-0.247-2.645-0.413-3.963c-0.192-1.527,0.554-3.344-1.28-4.486c-1.06,1.696-0.713,3.647-1.326,5.357
                c-0.941,2.627-2.568,3.227-4.936,1.676c-0.641-0.42-0.948-1.374-2.107-0.98c-0.223,0.312-0.631,0.669-0.76,1.107
                c-0.605,2.047-0.786,4.852-3.668,4.473c-2.771-0.364-2.438-3.11-2.537-5.246c-0.038-0.832-0.029-1.667-0.1-2.496
                c-0.068-0.831-0.174-1.893-1.133-1.981c-2.258-0.21-1.656-3.103-3.509-3.673c-1.224,2.051-1.101,4.275-1.513,6.383
                c-0.346,1.77-0.581,3.818-2.887,4.165c-2.119,0.318-2.66-1.539-3.532-2.903c-0.131-0.205-0.589-0.201-1.112-0.359
                c-0.717,2.6-2.439,3.673-5.191,3.285c-1.658-0.232-2.967,0.137-4.158,1.853c-1.575,2.269-3.9,1.635-4.713-1.042
                c-0.685-2.258-0.371-4.633-0.461-6.961c-0.066-1.689,0.363-3.557-1.559-5.201c-0.361,1.117-0.664,1.87-0.843,2.65
                c-0.223,0.973-0.26,1.991-0.521,2.95c-0.995,3.644-2.792,4.058-5.649,1.751c-2.756-2.224-4.363-4.782-4.556-8.392
                c-0.079-1.469-0.096-3.433-2.128-3.991c-1.365,0.344-1.848,1.491-2.555,2.389c-0.75,0.951-1.643,1.661-2.861,1.848
                c-2.751,0.422-9.044-3.223-10.152-5.815c-0.539-1.264-0.385-3.096-2.583-3.261c-2.126,3.852-2.448,8.257-3.807,12.329
                c-0.688,2.063-0.8,5.243-3.935,4.945c-2.687-0.255-2.45-3.104-3.007-5.148c-0.585-2.15,0.028-4.71-2.134-6.577
                c-2.051,1.612-2.205,4.116-3.226,6.131c-0.832,1.645-1.483,3.655-3.749,3.424c-2.163-0.221-2.713-2.246-3.289-3.986
                c-0.617-1.861-0.551-3.992-2.158-5.403c-0.385-0.101-0.721-0.295-1.018-0.247c-0.98,0.154-1.943,0.41-2.919,0.601
                c-4.508,0.879-5.349,0.409-7.323-3.813c-0.671-1.436-0.823-3.307-2.738-3.816c-1.594,0.209-2.168,1.519-3.02,2.502
                c-3.352,3.869-5.141,3.864-8.229-0.05c-0.711-0.901-1.197-2.024-2.5-2.261c-1.095,0.306-1.591,1.215-1.939,2.139
                c-0.939,2.492-1.687,5.059-2.689,7.522c-1.18,2.901-8.401,6.005-11.377,5.066c-1.257-0.397-1.906-1.327-2.291-2.442
                c-0.541-1.57-0.838-3.23-1.449-4.77c-0.488-1.232-0.272-3.057-2.227-3.375c-1.194,1.646-0.958,3.59-1.489,5.316
                c-0.679,2.209-2.369,2.772-4.22,1.4c-0.774-0.574-1.421-1.406-1.926-2.241c-1.263-2.093-3.096-2.317-5.204-1.783
                c-0.966,0.245-1.898,0.63-2.867,0.861c-4.528,1.081-6.008,0.249-7.435-4.152c-0.484-1.495-0.265-3.449-2.491-3.779
                c-4.303,4.826-4.296,4.755-9.707,1.075c-2.967-2.018-6.143-3.729-9.025-5.459c-1.9,0.719-2.353,2.271-3.26,3.43
                c-2.312,2.953-4.35,3.018-6.942,0.209c-0.77-0.832-1.115-2.096-2.464-2.328c-0.248,0.197-0.578,0.35-0.741,0.607
                c-0.834,1.323-0.716,3.607-2.947,3.536c-2.085-0.067-3.028-1.77-3.812-3.503c-0.574-1.269-0.851-2.841-2.486-3.239
                c-1.785,1.243-1.37,4.114-3.89,4.464c-4.038-1.738-4.038-1.738-6.339-4.87c-0.257,0.198-0.655,0.346-0.747,0.603
                c-0.447,1.252-0.858,2.52-1.2,3.804c-1.664,6.251-2.451,6.583-8.047,3.144c-3.168,5.622-2.766,13.053-8.309,17.292
                c-2.547-0.603-1.229-3.812-3.489-4.511c-0.868,1.646-1.207,3.271-3.146,4.324c-2.422,1.314-2.849,4.393-3.298,6.938
                c-0.36,2.039,1.475,3.312,2.612,4.769c1.841-1.218,2.155-3.001,3.176-4.29c1.107-1.4,2.636-2.057,3.76-0.576
                c1.442,1.9,3.37,3.68,3.146,6.535c-0.108,1.387-0.7,2.94,0.372,4.252c2.958-0.365,2.573-3.997,5.051-5.128
                c2.102,2.199,0.906,5.636,2.827,7.678c2.188-0.184,2.692-2.126,4.014-3.039c1.992-1.374,3.067-1.071,4.141,1.312
                c0.236,0.524-0.137,1.292,0.615,1.62c0.741-0.206,1.029-0.817,1.339-1.405c0.626-1.188,1.222-2.501,2.864-2.349
                c1.583,0.145,2.228,1.423,2.539,2.737c0.823,3.47,3.971,5.62,4.676,9.211c1.819-0.736,2.256-3.058,4.324-2.646
                c2.915,1.334,2.012,4.592,3.468,7.479c1.947-3.918,4.742-6.282,7.534-8.369c3.829,0.605,1.855,3.891,2.973,5.703
                c0.478,0.191,0.922,0.428,1.396,0.539c0.481,0.113,0.999,0.18,1.488,0.131c4.298-0.422,4.297-0.431,5.787,3.378
                c1.064-0.16,0.957-1.196,1.356-1.842c0.652-1.056,1.412-1.96,2.793-1.519c1.281,0.408,1.687,1.508,1.741,2.751
                c0.03,0.665,0.076,1.341-0.002,1.997c-0.343,2.866,1.223,4.38,3.656,5.653c0.454-1.95,0.806-3.569,1.212-5.174
                c0.297-1.177,0.781-2.284,2.243-2.251c1.136,0.025,1.54,0.897,2.069,1.823c2.037,3.562,2.884,7.604,5.04,12.044
                c0.205-4.059,3.81-5.247,4.65-8.434c0.426-1.613,2.601-1.566,3.505,0.186c1.142,2.211,2.825,3.727,4.671,5.6
                c0.487-0.936,0.799-1.5,1.079-2.08c0.362-0.748,0.599-1.58,1.066-2.252c1.366-1.967,3.122-2.078,4.196,0.096
                c1.116,2.258,2.862,3.018,5.005,3.475c2.584,0.551,3.775-0.924,5.049-2.957c1.398-2.236,3.203-1.83,4.383,0.669
                c0.318,0.675-0.125,1.673,1.312,2.265c0.852-2.666,3.811-2.57,5.722-4.176c1.305-1.096,2.831-0.649,3.672,1.09
                c0.417,0.862,0.125,2.031,1.444,2.812c5.603-4.121,11.501-7.621,17.311,0.631c2.053-1.934,4.004-2.243,6.438-0.895
                c1.769,0.979,3.565,0.091,4.12-2.217c0.154-0.641,0.324-1.307,0.647-1.871c0.437-0.763,1.226-1.233,2.086-1.077
                c0.854,0.155,1.469,0.827,1.501,1.745c0.059,1.652,0.018,3.309,0.018,5.074c4.014-2.018,5.363-7.688,10.229-7.496
                c1.788,1.588,0.5,3.529,1.831,4.465c2.209,0.061,2.998-1.304,3.653-2.935c0.403-1.002,1.259-1.585,2.393-1.349
                c1.142,0.238,1.481,1.172,1.677,2.206c0.209,1.107-0.358,2.349,0.812,3.623c1.325-2.034,3.619-0.16,5.284-1.292
                c0.427-1.725,0.674-3.398,1.262-4.943c0.861-2.264,2.48-2.507,4.039-0.613c2.711,3.297,4.193,7.231,5.088,10.871
                c3.324-1.625,3.807-4.746,4.303-7.872c0.076-0.486,0.252-0.979,0.479-1.416c1.009-1.937,2.274-2.155,3.897-0.671
                c1.432,1.311,2.522,2.9,3.31,4.647c2.056-1.38,3.419-2.77,4.095-5.07c0.594-2.026,3.364-2.093,3.618-0.267
                c0.447,3.219,2.571,4.044,5.037,4.564c1.562,0.33,2.577,1.262,3.668,2.39c0.179-0.589,0.313-1.062,0.468-1.526
                c0.592-1.795,0.33-4.501,3.051-4.43c2.602,0.068,1.29,2.969,2.301,4.348c1.518-3.536,4.193-3.127,6.746-2.492
                c1.408,1.563,0.365,3.277,1.298,4.694c2.388-0.479,4.761-0.899,6.374-3.188c1.316-1.867,3.23-2.233,5.332-1.357
                c0.914,0.38,1.761,0.999,2.959,0.484c1.777-1.48,2.113-3.969,3.427-5.867c1.841-2.662,3.542-2.719,5.362-0.129
                c0.56,0.795,0.73,1.875,1.738,2.271c1.156,0.212,1.881-0.58,2.758-1.021c3.204-1.615,4.497-1.002,5.324,2.41
                c0.417,1.725-0.216,3.723,1.335,5.218c1.585,0.476,3.097-0.954,4.812-0.097c1.504,0.752,2.024,2.65,3.976,3.029
                c1.012-3.912,0.869-8.154,4.986-10.845c3.311-2.163,4.321-2.196,5.572,1.583c0.658,1.99-0.039,4.376,1.65,6.118
                c1.416-0.808,1.565-2.186,2.039-3.386c0.487-1.236,0.892-2.535,1.576-3.658c1.399-2.292,3.527-2.543,4.612-0.225
                c1.442,3.083,4.707,3.749,6.394,6.518c2.24-2.652,2.237-5.68,3.514-8.158c1.623-3.15,3.529-3.207,5.462-0.23
                c1.99,3.066,4.29,5.967,5.948,9.199c1.43,2.787,3.941,5.094,4.088,8.725c0.562-3.84,1.855-6.787,5.963-7.586
                c1.424-0.276,1.049-1.931,1.637-2.871c1.168-1.871,2.686-1.826,3.844-0.008c0.669,1.049-0.017,2.66,1.615,3.525
                c1.253-2.326,3.1-2.853,5.451-1.748c0.586,0.275,1.281,0.326,1.934,0.447c4.605,0.864,4.58,0.864,5.12,5.676
                c0.11,0.988-0.485,2.4,1.392,2.768c0.515-2.637,0.914-5.271,1.574-7.838c0.389-1.512,0.549-3.666,2.814-3.484
                c1.926,0.155,1.85,2.022,2.102,3.484c0.236,1.375,0.021,2.913,1.625,4.412c0.678-3.862,3.871-4.908,6.412-6.557
                c2.037-1.321,3.857-0.614,3.961,1.713c0.148,3.375,2.452,4.199,4.489,5.547c0.552-0.504,0.878-0.68,1.022-0.951
                c2.334-4.365,7.426-6.318,9.498-11.029c0.568-1.293,2.575-0.736,3.139,0.795c1.074,2.914,4.324,4.361,5.054,7.916
                c-0.144-4.259,3.76-5.789,5.045-8.917c0.463-1.125,1.233-2.14,2.567-1.997c1.662,0.178,1.855,1.627,2.115,2.961
                c0.376,1.939-0.258,4.139,1.484,6.277c0.248-1.142,0.496-1.779,0.506-2.42c0.055-3.459,2.49-5.238,4.914-6.994
                c1.189-0.859,2.803-1.07,3.611,0.161c1.486,2.264,4.545,3.122,5.166,6.345c0.829-3.263,3.66-4.454,5.543-6.41
                c3.168-3.295,7.085-1.953,8.334,2.5c0.176,0.624,0.419,1.229,0.689,2.014c1.442-0.279,2.371,1.274,3.773,0.708
                c2.641-1.329,4.358-3.231,5.229-6.205c0.63-2.149,3.189-2.346,4.17-0.333c1.195,2.451,3.773,3.275,4.998,5.923
                c1.316-3.015,1.337-5.747,3.912-7.851c3.895-3.184,3.567-3.584,7.429-2.053c1.165-0.82,1.761-2.809,3.872-2.69
                c3.514,1.654,1.165,5.843,3.129,8.276c2.383,0.049,4.028-0.557,4.303-3.151c0.104-0.985,0.436-1.947,0.669-2.919
                c0.327-1.36,0.858-2.682,2.47-2.716c1.727-0.036,1.989,1.472,2.351,2.755c0.431,1.529-0.136,3.281,1.153,5.029
                c2.527-2.34,4.971-2.597,6.813,0.91c0.589-0.773,0.958-1.118,1.147-1.543c0.269-0.603,0.344-1.291,0.608-1.896
                c1.463-3.357,2.13-3.436,5.726-2.396c2.723,0.787,4.875,2.323,6.833,4.306c2.065-1.827,3.331-5.364,6.124-3.87
                c4.033,2.158,6.211-0.943,8.982-2.219c1.666-0.765,2.178-3.487,4.706-3.15c2.478,0.331,2.321,3.055,3.844,4.256
                c0.122,0.097,0.302,0.119,0.772,0.295c2.398-1.646,5-3.186,6.327-6.325c1.659-3.926,2.078-3.973,6.272-2.372
                c2.072-1.381,2.895-4.605,5.773-4.396c1.963,1.251,0.92,3.336,2.426,5.269c1.508-4.865,4.252-2.888,7.125-1.314
                c0.909-4.373,3.816-7.201,5.583-10.686c0.497-0.981,1.126-1.671,2.255-1.617c1.538,0.072,1.717,1.275,1.934,2.461
                c0.248,1.352-0.367,2.906,0.709,4.203c1.963-1.156,2.144-3.164,3.128-4.646c1.808-2.72,2.409-2.785,5.706-1.887
                c4.414,1.203,6.175,5.085,8.604,8.149c1.521-3.367,4.28-5.855,5.535-9.342c0.76-2.111,3.003-1.721,4.112,0.459
                c0.662,1.3,0.477,2.975,1.758,4.049c1.337-1.334,1.14-3.081,1.415-4.699c0.25-1.473,0.427-2.998,0.96-4.374
                c1.036-2.681,2.189-2.655,4.579,0.145c1.251-2.702,2.941-4.55,6.11-2.405c1.269-2.051,1.485-4.922,4.894-4.1
                c4.322,1.042,3.416,4.527,3.646,7.951c0.576-0.812,0.802-1.24,1.126-1.57c2.244-2.285,3.534-2.051,4.565,0.881
                c0.385,1.094,0.588,2.255,0.846,3.393c0.37,1.637,0.058,3.539,1.858,5.028c1.63-3.002,2.235-6.307,5.238-8.014
                c0.481-0.273,0.831-1.118,0.894-1.735c0.424-4.199,3.599-6.899,5.502-10.286c0.514-0.916,1.282-1.571,2.42-1.396
                c1.131,0.174,1.779,1.052,1.93,2.04c0.301,2,0.625,3.837,2.443,5.188c0.957,0.711,0.738,2.136,0.852,3.294
                c0.12,1.242-0.154,2.598,1.255,4.44c1.868-3.661,5.316-4.058,8.241-5.255c0.986-1.472,0.674-3.475,2.193-4.508
                c2.158-1.465,3.015,0.449,3.945,1.882c1.709-1.806,0.553-5.187,3.658-5.729c2.259-0.394,1.736,2.567,3.352,3.075
                c1.295-1.207,3.483-2.668,4.021-0.525c0.781,3.104,2.737,4.201,5.057,5.44c0.938,0.501,1.145,1.538,1.362,2.548
                c0.239,1.108-0.508,2.458,1.017,3.486c-0.158-4.217,3.703-6.449,4.568-10.272c0.395-1.743,3.277-3.048,4.998-2.471
                c3.06,1.026,6.662,0.009,9.211,2.698c1.641-1.408,1.509-3.453,2.41-4.986c1.318-2.241,3.996-2.421,4.502-0.127
                c0.82,3.718,4.551,5.033,5.679,9.009c2.188-4.31,6.586-5.337,8.636-8.901c0.564-0.982,1.943-1.252,3.135-1.312
                c2.526-0.127,4.855-0.602,7.021-2.146c1.77-1.26,3.459-0.6,4.646,1.271c0.863,1.358,0.891,3.262,2.525,4.037
                c1.277,0.148,1.611-1.24,2.633-1.391c0,1,0,2,0,3c-4.091,0.4-4.094,0.402-6.055-3.252c-0.538-1.003-0.838-2.229-2.71-2.383
                c-1.833,2.02-4.534,2.658-7.438,2.71c-1.213,0.022-2.52,0.247-3.035,1.388c-1.526,3.375-5.529,4.097-7.378,7.211
                c-0.998,1.682-3.159,1.705-4.076-0.197c-1.503-3.113-5.038-4.748-5.436-8.6c-2.018,1.41-1.737,3.539-2.725,5.043
                c-0.911,1.389-2.464,2.752-3.57,1.389c-2.083-2.566-4.997-1.311-7.344-2.379c-1.752-0.798-3.909,0.461-4.15,1.74
                c-0.708,3.753-4.771,6.076-4.079,10.337c0.191,1.182-0.508,2.417-1.838,2.595c-1.76,0.234-2.052-1.238-2.469-2.539
                c-0.607-1.9-0.104-4.018-1.116-5.785c-2.521-1.234-5.401-2.01-5.224-5.694c-3.906,1.929-3.906,1.929-6.171-0.837
                c-2.876,6.467-3.164,6.594-6.673,2.945c-1.436,3.732-1.391,3.888-5.216,4.988c-1.745,0.502-2.814,1.6-4.079,2.668
                c-2.697,2.278-4.409,1.566-5.039-1.975c-0.29-1.624,0.545-3.853-0.759-4.785c-2.069-1.479-2.28-3.631-2.605-5.438
                c-4.077,2.457-5.545,6.258-6.711,10.267c-0.222,0.762-0.673,1.725-1.302,2.052c-2.18,1.134-2.72,3.243-3.542,5.224
                c-0.253,0.611-0.506,1.248-0.896,1.773c-1.457,1.965-3.256,1.881-4.178-0.338c-0.631-1.514-0.852-3.206-1.176-4.835
                c-0.273-1.37-0.261-2.835-1.425-4.037c-1.174,1.378-1.28,3.846-3.419,3.372c-2.354-0.522-1.852-2.781-1.91-4.56
                c-0.011-0.333,0.007-0.667,0.023-0.999c0.104-2.129-1.062-2.951-3.604-3.053c-0.351,3.825-2.675,4.885-6.012,3.291
                c-0.957,1.58-1.061,3.199-2.748,3.844c-1.486,0.568-2.178-1.35-3.487-0.942c-0.548,2.681-1.009,5.3-1.649,7.875
                c-0.243,0.976-1.021,1.784-2.147,1.821c-1.174,0.039-1.685-0.903-2.178-1.789c-0.745-1.334-0.303-3.279-2.14-4.311
                c-1.441,2.166-1.399,4.887-3.764,6.48c-1.883,1.268-0.646,5.618-4.942,5.246c-1.423-5.012-4.151-8.897-9.337-10.257
                c-3.444,1.934-2.592,7.743-7.477,7.794c-1.889-1.693-1.061-4.001-1.957-5.836c-1.79,2.488-4.452,4.163-4.916,7.392
                c-0.191,1.326-1.027,2.421-2.273,3.136c-1.873,1.074-2.905-1.033-4.348-1.244c-1.441,1.006-1.578,3.438-3.672,3.205
                c-2.545-0.282-1.627-2.92-2.593-4.311c-0.093-0.133-0.247-0.223-0.396-0.352c-1.666,0.578-2.379,2.24-3.604,3.323
                c-1.794,1.585-3.383-0.479-4.783-0.188c-1.23,1.64-1.336,3.408-2.511,4.707c-5.012,5.534-7.014,5.532-11.299-0.146
                c-4.491,2.811-7.841,8.802-14.284,5.146c-1.377,0.423-1.784,1.399-2.464,2.11c-2.368,2.476-3.265,2.573-5.656,0.067
                c-1.972-2.065-4.483-2.301-6.851-3.091c-3.792,8.91-4.53,7.176-8.041,4.068c-1.43,0.241-2.237,2.123-4.041,1.564
                c-2.861-1.366-1.443-4.63-2.592-6.901c-1.729,1.245-1.21,2.93-1.631,4.297c-0.744,2.421-2.994,3.9-5.783,3.709
                c-2.477-0.169-2.607-2.147-2.947-3.96c-0.234-1.244,0.271-2.649-1.05-4.033c-1.872,2.959-4.318,3.357-7.099,0.889
                c-1.084,1.922-2.323,3.178-3.658,4.406c-1.371,1.262-0.988,3.464-1.678,5.145c-0.895,2.184-3.434,2.823-4.154,0.977
                c-1.078-2.758-4.051-3.437-5.238-7.003c-1.307,5.294-3.912,8.431-8.285,8.487c-3.738,0.047-5.721-2.777-5.607-6.668
                c-2.363-0.344-4.117-0.213-5.493,1.779c-1.185,1.715-3.204,2.627-4.151,4.771c-0.865,1.954-3.197,2.013-3.864,0.183
                c-1.04-2.854-3.693-3.865-5.028-5.898c-3.495,1.596-5.021,4.055-5.244,7.548c-0.09,1.416-0.788,3.137-2.793,2.825
                c-1.623-0.253-1.757-1.77-1.934-3.11c-0.262-1.974,0.136-4.12-1.248-6.141c-2.043,3.435-4.857,5.78-5.715,9.507
                c-0.383,1.66-3.103,1.723-3.471,0.162c-0.791-3.354-3.781-5.086-5.066-8.07c-1.394,0.79-1.046,2.533-2.043,3.405
                c-2.697,2.36-5.661,4.403-7.602,7.581c-0.754,1.234-2.326,1.166-3.629,0.482c-2.82-1.48-5.027-3.467-4.955-7.13
                c-4.324,2.45-4.334,2.443-6.512,5.481c-0.712,0.993-1.484,1.891-2.848,1.543c-1.325-0.338-1.635-1.471-1.906-2.656
                c-0.314-1.373,0.278-3.031-1.299-4.227c-1.55,3.271-1.209,6.879-2.472,10.074c-0.461,1.166-1.087,2.166-2.521,1.885
                c-1.128-0.223-1.471-1.22-1.662-2.24c-0.449-2.412,0.021-5.001-1.316-7.318c-2.297-0.508-4.541-1.002-6.82-1.506
                c-1.301,0.987-2.043,2.905-4.141,2.281c-1.875-0.559-1.534-2.522-2.383-4.986c-0.793,3.032-1.957,4.219-3.904,4.772
                c-2.37,0.673-3.438,2.414-3.223,4.925c0.057,0.652-0.051,1.335-0.188,1.982c-0.213,1.016-0.651,1.943-1.857,2.004
                c-1.178,0.059-1.848-0.736-2.121-1.748c-0.434-1.602-0.289-3.533-1.15-4.813c-3.314-4.926-5.726-10.448-9.955-15.205
                c-1.978,2.532-2.083,5.686-3.492,8.229c-1.321,2.385-3.018,2.874-4.703,0.94c-2.017-2.313-5.232-3.3-6.312-6.622
                c-2.28,1.709-2.258,4.082-3.189,5.951c-0.742,1.492-1.232,3.406-3.28,3.205c-2.103-0.208-2.042-2.363-2.557-3.863
                c-0.631-1.839,0.048-4.059-1.589-5.627c-3.698,1.911-5.52,4.914-5.688,9.053c-0.071,1.752-0.117,3.708-2.076,4.516
                c-1.706-0.415-2.179-1.775-2.928-2.835c-1.062-1.503-2.226-2.095-4.228-1.694c-3.09,0.617-3.653,0.01-4.483-2.955
                c-0.476-1.7,0.202-3.701-1.263-5.146c-6.186,2.368-6.186,2.368-9.613-2.367c-2.213,1.286-2.486,3.828-3.702,5.733
                c-1.077,1.688-2.519,3.123-4.479,2.091c-2.807-1.477-4.662-0.875-6.341,1.601c-0.667,0.983-1.876,1.251-3.003,1.637
                c-4.968,1.702-5.187,1.6-5.893-3.53c-0.039-0.276-0.351-0.516-0.568-0.816c-0.473,0.045-0.974,0.045-1.451,0.15
                c-1.418,0.314-1.33,1.76-1.979,2.658c-1.479,2.051-2.803,1.888-3.982-0.475c-0.277-0.553-0.292-1.236-0.481-2.11
                c-1.116,2.343-0.515,5.503-4.289,5.972c-0.778-3.268-3.292-4.535-6.253-5.155c-1.742-0.365-2.232-2.028-3.385-3.284
                c0.117,3.035-3.525,3.172-3.807,5.792c-0.154,1.439-1.232,2.434-2.857,2.022c-1.452-0.367-1.516-1.727-1.39-2.855
                c0.257-2.303-0.257-3.892-3.022-4.099c-1.045,2.203-0.134,5.354-2.434,6.798c-2.174,1.365-0.324,5.46-4.641,5.449
                c-2.314-4.102-1.479-10.229-6.628-13.58c-0.346,1.064-0.622,1.658-0.724,2.281c-0.215,1.324-0.407,3.535-1.4,3.488
                c-3.27-0.152-5.035,2.578-7.735,3.327c-0.132,0.037-0.312-0.097-0.491-0.159c-1.734-1.854-0.33-4.15-0.849-6.362
                c-2.005,1.823-3.986,3.565-6.556,3.237c-1.964-1.045-1.057-2.865-1.848-4.27c-3.36,1.134-4.877,4.23-7.009,6.568
                c-0.787,0.864-1.189,2.117-2.577,1.844c-1.699-0.335-1.686-1.803-1.728-3.158c-0.03-0.984,0.296-1.998-0.507-3.457
                c-0.479,2.628-2.117,3.412-4.214,3.66c-2.742,0.324-5.538-2.066-8.296,0.273c-0.471,0.4-1.619-0.23-2.012-1.077
                c-0.615-1.329-1.992-1.721-3.025-2.519c-2.439-1.884-3.936-2.064-6.018,0.018c-1.52,1.52-3.516,1.767-5.156,2.883
                c-1.65,1.123-3.192,0.51-3.758-1.639c-0.191-0.729,0.168-1.727-1.236-2.119c-1.438,1.597-3.869,1.768-5.496,3.707
                c-1.542,1.836-3.098,1.24-4.131-1.032c-0.305-0.673,0.137-1.665-0.865-2.053c-4.125,5.452-10.285,5.169-14.142-0.674
                c-1.51,0.812-1.324,2.595-2.169,3.779c-1.396,1.957-2.958,2.177-4.44,0.44c-1.431-1.677-2.719-3.476-4.14-5.312
                c-1.153,3.303-4.395,5.025-5.028,8.528c-0.281,1.556-3.256,1.235-3.286-0.435c-0.056-3.024-2.964-4.771-2.804-7.787
                c0.096-1.8-1.383-2.813-2.736-3.864c-1.004,2.343-0.313,5.259-3.102,6.901c-4.179-2.028-5.94-5.495-5.522-10.71
                c-1.887,1.466-2.014,4.178-4.366,3.759c-2.452-0.438-1.519-3.035-2.781-4.76c-1.734,0.99-3.629,0.502-5.56,0.086
                c-2.549-0.549-1.883-2.972-2.559-4.883c-1.303,1.895-3.639,1.625-4.491,3.553c-0.711,1.607-1.171,3.586-3.349,3.754
                c-3.552-1.169-1.789-5.155-3.792-7.127c-4.058,3.426-4.329,3.25-6.772-1.32c-1.438-2.69-3.13-5.264-4.374-8.116
                c-2.083,1.212-1.852,4.583-4.666,4.351c-2.405-0.197-1.586-2.908-2.639-4.24c-6.117,5.289-6.342,5.267-9.12-0.914
                c-0.698,0.455-1.563,0.763-2.021,1.375c-0.776,1.036-1.131,2.652-2.878,2.129c-1.843-0.553-1.634-2.279-1.724-3.751
                c-0.041-0.663-0.069-1.343,0.024-1.997c0.438-3.074-0.723-5.305-3.323-6.954c-1.908,1.83-2.142,4.923-4.986,5.43
                c-2.569-0.493-2.383-4.005-4.621-4.313c-2.444-0.337-3.949-1.686-5.066-3.4c-2.838,2.316-0.153,7.93-5.667,8.078
                c-1.517,2.342-0.262,5.727-2.77,7.712c-3.684-2.083-3.684-2.083-6.199-6.435c-2.241,2.504-3.23,5.861-5.519,7.795
                c-1.773,0.558-2.659-1.717-3.272-1.157c-2.808,2.567-5.302-0.434-8.121-0.271c-0.698,2.093-1.33,4.33-3.634,5.083
                c-3.087,1.008-3.418-2.566-5.459-3.54c-1.037,0.455-1.479,1.877-2.807,1.875c-3.823-0.005-5.346,2.792-6.815,5.627
                c-0.455,0.878-0.661,1.912-1.222,2.702c-1.838,2.586-3.512,2.563-5.328-0.022c-0.555-0.789-0.75-1.883-2.36-2.037
                c-1.116,0.986-1.207,3.192-3.287,3.749c-2.604,0.696-2.777-2.313-4.542-2.947c-1.836,2.407-2.259,6.08-6.617,5.753
                c0.054-3.99-3.732-5.98-4.732-9.717c-1.11,3.467-2.111,6.777-4.947,9.107c-0.42,0.346-0.366,1.243-0.582,1.867
                c-1.487,4.29-2.587,4.773-8.042,2.42c3.186,5.789,5.45,10.827,8.548,15.722c-0.622-4.257,3.453-5.591,4.946-8.51
                c0.694-1.356,2.402-1.003,3.45,0.098c1.831,1.923,3.533,3.851,3.448,6.886c-0.076,2.728-0.457,5.559,0.757,9.324
                c1.377-2.93,1.739-5.37,3.462-6.861c2.367-2.048,3.899-4.469,4.491-7.544c0.232-1.204,0.948-2.28,2.287-2.29
                c1.504-0.01,2.577,1.013,2.726,2.439c0.257,2.459,1.247,4.416,3.032,6.085c0.44,0.411,0.931,1.339,0.753,1.75
                c-1.449,3.343,2.254,5.337,1.692,8.396c0.949-1.261,1.219-2.905,2.156-3.503c1.589-1.014,2.379-2.083,2.395-3.942
                c0.01-1.189,0.411-2.389,1.845-2.592c1.579-0.225,2.71,0.71,2.949,2.102c0.593,3.451,3.504,4.855,5.648,7.508
                c0.584-1.586,1.024-2.65,1.368-3.744c0.419-1.336,0.997-2.555,2.534-2.756c1.58-0.207,2.11,1.061,3,2.104
                c2.373,2.781,4.431,5.662,5.225,9.521c0.474-4.482,5.299-5.428,6.623-9.135c0.56-1.568,3.409-1.137,3.94,0.723
                c1.528,5.348,7.861,7.863,7.356,14.681c2.53-1.846,2.137-4.479,3.651-5.604c3.758-2.789,6.12-7.13,10.333-9.297
                c1.339-0.688,2.131-1.395,2.468-2.79c0.303-1.255,1.142-2.143,2.447-2.096c1.522,0.056,2.246,1.177,2.684,2.557
                c0.494,1.559,0.161,3.67,1.663,4.57c5.167,3.102,6.835,7.67,6.255,13.381c-0.049,0.48,0.235,0.615,0.629,0.344
                c0.348-1.383,0.587-2.819,1.327-4.163c2.197-3.987,5.178-7.388,7.968-10.929c1.186-1.504,3.667-1.209,3.91,0.657
                c0.427,3.28,3.168,4.845,4.324,7.453c2.446-1.571,3.963-3.465,3.905-6.454c-0.027-1.388,0.839-2.605,2.464-2.631
                c1.591-0.025,2.316,1.018,2.615,2.496c0.229,1.136,0.361,2.39,0.945,3.34c2.812,4.584,4.678,9.471,5.131,14.941
                c3.3-2.136,4.781-5.09,5.244-8.76c0.269-2.129,0.653-4.404,2.414-5.892c1.803-1.523,3.418-2.997,3.705-5.558
                c0.115-1.027,1.101-1.628,2.252-1.554c1.157,0.075,1.782,0.821,2.069,1.847c0.177,0.635,0.293,1.316,0.276,1.972
                c-0.067,2.583,0.32,4.839,2.732,6.488c0.811,0.554,0.986,2.036,1.457,3.091c0.469,1.051,0.45,2.527,1.551,3.046
                c2.722,1.282,3.002,3.706,3.457,6.202c0.18,0.984,0.081,2.34,1.544,2.932c1.927-1.569,2.533-3.883,3.623-5.887
                c1.032-1.896,1.8-4.043,3.209-5.605c2.792-3.099,5.192-6.342,6.459-10.355c0.325-1.027,1.057-1.715,2.191-1.734
                c1.125-0.021,1.835,0.627,2.273,1.627c0.458,1.047,0.08,2.285,0.96,3.274c2.98,3.351,5.241,7.15,6.946,12.13
                c2.228-3.733,1.678-10.814,8.837-6.876c0.951-1.605,0.634-3.646,2.49-4.396c2.136-0.863,1.351-3.242,2.348-4.705
                c0.992-1.453,2.847-1.357,3.566,0.269c0.395,0.891,0.79,2.007,0.598,2.894c-1.23,5.697,2.852,9.221,5.076,13.768
                c1.622-1.521,1.619-3.304,2.092-4.85c0.631-2.064,0.918-4.236,1.556-6.299c1.315-4.262,2.634-4.547,6.468-2.475
                c3.518,1.901,6.346,4.065,6.818,8.352c0.039,0.351,0.667,0.637,1.385,1.277c1.682-4.049,2.533-8.444,6.451-10.898
                c0.34-0.213,0.409-0.889,0.561-1.363c0.478-1.494,1.115-2.912,2.963-2.866c2.103,0.052,2.607,1.787,3.121,3.384
                c0.559,1.738,0.494,4.215,1.666,5.115c3.484,2.676,3.268,6.314,3.602,9.919c0.121,1.311,0.156,2.655,1.321,3.933
                c0.752-2.324,1.354-4.658,3.121-6.242c1.934-1.732,2.27-3.795,2.3-6.267c0.064-5.399,3.484-9.329,6.506-13.37
                c0.189-0.254,0.912-0.109,1.355-0.146c1.679,1.363,1.51,3.354,1.926,5.069c0.394,1.625,0.433,3.521,1.561,4.687
                c3.484,3.605,3.475,8.084,3.725,12.582c0.101,1.815-0.344,3.789,0.975,5.963c1.64-2.532,1.684-5.517,3.793-6.893
                c1.912-1.248,3.117-2.716,4.116-4.709c1.335-2.662,3.323-2.82,5.606-0.757c0.949,0.858,1.34,2.38,3.127,2.462
                c0.375-0.631,0.909-1.287,1.183-2.039c0.797-2.187,1.282-4.509,2.271-6.598c1.451-3.067,3.773-3.062,5.535-0.117
                c0.762,1.274,0.887,3.086,2.172,3.863c2.924,1.77,2.982,4.587,3.184,7.41c0.303,4.256,0.186,8.557,1.595,12.846
                c-0.444-3.27,2.319-4.875,3.427-7.309c1.91-4.2,6.803-5.699,10.52-3.37c1.741,1.092,1.011,3.561,3.013,4.494
                c0.2-3.932,4.501-3.845,5.747-6.33c2.293-0.447,3.696,1.369,5.535,1.235c4.937-3.377,6.498-2.656,7.201,3.182
                c0.189,1.568-0.364,3.409,1.383,4.563c2.607-3.3,0.261-8.849,4.801-11.161c4.029,1.353,1.797,5.471,3.896,8.145
                c1.835-3.094,5.141-3.162,7.436-5.037c1.297-1.06,2.889-0.497,3.843,1.013c0.899,1.422,1.26,3.06,1.802,4.632
                c0.673,1.947,0.569,4.279,2.38,5.748c1.66-0.525,1.896-1.96,2.499-3.094c0.704-1.325,1.484-2.518,2.698-3.517
                c2.582-2.126,5.666-3.864,6.383-7.632c0.199-1.043,1.22-1.582,2.325-1.494c0.917,0.074,1.419,0.768,1.766,1.563
                c0.685,1.571,0.679,3.241,0.729,4.909c0.094,3.105-0.379,6.285,2.703,8.609c1.082,0.814,1.15,2.693,1.344,4.2
                c0.23,1.796,0.379,3.632,1.664,5.403c1.295-2.645,0.761-5.343,1.092-7.904c0.21-1.629-0.208-3.611,1.217-4.699
                c2.172-1.661,2.141-4,2.555-6.249c0.211-1.146,0.289-2.318,0.527-3.458c0.311-1.485,0.713-3.158,2.486-3.345
                c1.899-0.199,2.419,1.493,2.835,2.937c0.367,1.273,0.497,2.611,0.792,3.908c0.296,1.303,0.363,2.724,1.33,3.875
                c1.12-1.004,1.206-2.098,1.088-3.021c-0.463-3.6,1.919-5.362,4.338-7.159c1.57-1.168,3.171-1.281,4.392,0.317
                c2.065,2.705,5.779,4.426,5.33,8.972c1.599-3.309,4.052-5.562,6.381-7.953c3.529-3.622,8.289-2.611,9.816,2.206
                c0.249,0.784,0.472,1.576,0.722,2.415c1.727,0.695,3.374,1.359,4.86,1.957c2.772-1.732,4.825-3.463,5.437-6.775
                c0.444-2.401,3.362-2.82,4.432-0.652c1.562,3.165,5.236,4.131,6.418,7.762c1.33-1.569,1.088-2.954,1.201-4.234
                c0.119-1.342,0.104-2.822,1.369-3.591c1.9-1.155,1.827-3.009,2.081-4.8c0.948-6.691,1.392-6.945,7.229-4.171
                c1.156-1.121,1.17-2.683,1.621-4.093c0.535-1.675,0.738-4.127,3.066-3.971c2.688,0.18,2.393,2.841,2.576,4.694
                c0.312,3.146,0.227,6.329,0.479,9.483c0.18,2.252-0.038,4.64,1.291,6.579c2.738,0.433,4.486-0.233,4.777-3.104
                c0.083-0.822,0.32-1.635,0.536-2.438c0.379-1.405,1.147-2.478,2.762-2.325c1.344,0.127,1.932,1.178,2.237,2.372
                c0.448,1.753,0.114,4.075,1.656,5.082c2.394,1.563,2.472,3.723,2.677,6.054c0.096,1.103,0.024,2.332,1.229,3.143
                c1.593-1.842,0.821-4.814,2.683-6.307c1.814-1.455,2.844-3.01,3.145-5.316c0.189-1.449,0.873-3.019,2.801-2.984
                c1.873,0.034,2.339,1.569,2.863,2.998c0.609,1.664,0.457,3.795,2.449,4.661c2.056,0.894,2.746,2.753,3.661,4.53
                c0.707,1.375,0.815,3.254,2.724,3.811c1.548-1.325,1.062-3.139,1.385-4.708c0.5-2.438,0.697-4.972,1.48-7.313
                c0.811-2.426,2.875-2.627,4.58-0.733c0.853,0.947,0.861,2.583,2.885,3.094c1.578-3.351,5.154-4.938,7.617-7.731
                c2.225-2.522,4.009-2.33,6.02,0.383c0.94,1.27,1.144,3.202,3.079,3.576c4.628-2.955,5.264-3.615,6.321-6.557
                c1.605-4.459,2.562-4.789,6.697-2.283c1.744-0.818,2.281-2.812,3.743-3.916c1.96-1.48,3.332-1.094,4.207,1.211
                c0.454,1.197-0.034,2.731,1.272,3.639c3.121-3.403,7.235-5.521,10.676-8.494c1.125-0.974,2.465-1.469,2.588-3.312
                c0.091-1.369,0.963-2.621,2.605-2.449c1.619,0.172,1.867,1.621,2.247,2.925c0.517,1.771,0.261,3.802,1.479,5.703
                c1.951-1.722,2.104-3.85,2.992-5.573c0.807-1.562,1.942-3.398,3.597-2.918c2.272,0.66,5.405,0.789,6.554,2.873
                c1.927,3.499,6.072,4.062,7.967,7.866c2.115-3.107,2.162-6.241,3.317-8.947c0.536-1.255,1.208-2.469,2.757-2.562
                c1.612-0.098,2.277,1.155,2.882,2.366c0.808,1.619,0.757,3.986,2.415,4.787c2.816,1.358,2.873,3.777,3.379,6.197
                c0.256,1.227-0.227,2.708,1.18,3.789c0.699-2.028,0.422-4.024,0.517-5.979c0.089-1.835-0.084-3.966,1.136-5.276
                c1.869-2.008,1.642-4.217,1.617-6.529c-0.012-1.164-0.028-2.34,0.112-3.491c0.128-1.051,0.746-1.894,1.851-2.075
                c1.127-0.185,2.076,0.47,2.35,1.441c0.516,1.836,1.776,2.896,2.988,4.25c2.121,2.367,4.517,4.86,4.658,8.464
                c0.032,0.815,0.36,1.653,1.922,1.408c0-1.401-0.022-2.87,0.008-4.337c0.023-1.168-0.059-2.574,1.014-3.206
                c2.155-1.271,2.003-3.257,2.063-5.251c0.054-1.736,0.343-3.345,2.444-3.986c4.398,1.43,1.234,6.514,3.951,8.746
                c2.398-0.273,3.569-2.529,5.774-3.024c3-0.675,2.779,2.618,4.41,3.595c0.333-0.489,0.598-0.738,0.691-1.039
                c0.444-1.427,0.853-2.865,1.253-4.305c1.632-5.859,3.165-6.484,8.423-3.703c1.556,0.823,2.071,2.001,2.272,3.567
                c0.214,1.661,0.034,3.462,1.573,5.373c0.173-1.224,0.298-1.858,0.345-2.498c0.126-1.724,0.336-3.515,2.41-3.847
                c1.741-0.279,2.607,1.121,3.439,2.425c0.654,1.024,0.786,2.692,2.628,2.432c1.602-1.913,0.644-4.943,2.372-6.467
                c2.528-2.231,3.775-4.836,4.396-8.016c0.284-1.455,1.063-3.056,2.879-2.972c1.736,0.079,2.265,1.639,2.813,3.094
                c0.866,2.298,0.492,5.012,2.315,7.098c1.632-1.115,3.594-3.207,5.064-0.949c1.389,2.132,3.404,4.333,3.953,6.494
                c1.063,4.192,5.411,6.652,4.642,11.809c0.853-0.805,1.268-1.006,1.293-1.249c0.412-3.86-0.08-7.964,3.228-11
                c0.284-0.261,0.14-0.964,0.243-1.451c0.551-2.603,3.702-5.823,5.67-4.868c3.656,1.773,8.404,0.145,11.012,4.047
                c1.48-0.872,1.357-2.137,1.644-3.237c0.45-1.731,0.515-3.909,2.817-4.228c2.422-0.335,2.879,1.776,3.564,3.459
                c0.125,0.309,0.219,0.631,0.359,0.932c0.855,1.83,2.217,2.9,4.303,2.432c2.93-0.657,3.828-2.026,3.549-5.074
                c-5.083-1.794-6.271-2.021-1.39-6.528c-2.838-0.173-5.001-0.844-4.997-4.239c0.006-4.977,4.619-3.156,6.258-4.293
                c2.18-5.462,3.031-10.637,5.632-15.942c2.475,4.011,1.531,7.577,1.698,10.951c0.115,2.333,0.485,3.96,2.944,5.128
                c3.321,1.576,3.282,3.029,0.438,5.348c-0.514,0.419-1.1,0.723-1.301,1.495c1.279,2.96,3.856,4.42,6.469,5.804
                c1.9-2.928,0.873-7.133,4.805-8.461c2.164,0.957,2.381,2.826,2.699,4.625c0.378,2.132,0.623,4.293,1.113,6.396
                c0.454,1.945,1.047,3.922,3.475,4.403c2.138-0.571,3.242-2.663,5.423-3.396c5.873-1.973,10.736-5.057,10.56-12.372
                c-0.035-1.479,0.308-2.981,0.6-4.448c0.32-1.611,0.559-3.326,2.426-4.132c2.733,0.633,2.64,3.138,3.186,5.101
                c0.756,2.719,1.057,5.57,1.902,8.254c1.206,3.826,1.983,4.142,5.758,2.504c2.023-0.879,3.81-1.008,5.642,0.29
                c0.779,0.552,1.499,1.383,2.875,0.769c1.218-1.652,1.255-3.906,1.937-5.927c0.768-2.276,0.568-5.866,3.533-5.903
                c3.369-0.042,3.073,3.646,3.822,6.107c0.641,2.102,0.001,4.604,1.814,6.639c1.115-1.02,1.586-2.037,1.794-3.174
                c0.84-4.604,2.855-8.459,6.741-11.273c1.958-1.418,2.827-3.6,3.633-5.843c0.709-1.976,0.893-4.269,2.789-5.681c0,1,0,2,0,3
                c-1.313,4.906-3.474,9.205-7.771,12.305c-1.93,1.392-2.536,3.841-3.189,6.098c-0.463,1.596-0.768,3.277-1.491,4.748
                c-1.249,2.541-3.385,2.452-4.909,0.014c-0.861-1.375-0.488-2.926-0.846-4.354c-0.555-2.21-0.171-4.822-2.414-6.778
                c-1.979,3.15-1.812,6.49-3.029,9.38c-1.28,3.038-2.532,3.698-5.332,2.022c-1.938-1.158-3.414-1.39-5.509-0.4
                c-3.741,1.766-5.809,0.811-7.361-3.069c-1.245-3.11-1.56-6.461-2.235-9.718c-0.175-0.846-0.098-2.06-1.673-1.883
                c-0.612,3.609-1.144,7.225-1.859,10.805c-0.508,2.539-1.896,4.133-4.399,5.521c-3.127,1.736-6.579,2.92-9.442,5.182
                c-2.926,2.312-5.647,1.676-7.274-1.689c-0.711-1.471-1.025-3.152-1.383-4.77c-0.482-2.178-0.834-4.387-1.438-7.639
                c-2.429,4.16-3.377,7.459-2.937,9.834c-0.237,3.359-3.348,1.613-3.644,4.252c6.654-0.66,7.916,4.681,10.925,8.662
                c-4.161,2.705-8.722,0.545-13.425,3.497c7.07-0.079,10.545,4.08,14.816,7.462c-1.799,2.035-3.338,4.143-5.1,5.416
                c-1.723,1.244-4.639,0.834-7.031,1.148c4.781,1.049,8.322,5.009,13.351,5.17c1.61,0.052,3.163,1.018,3.204,2.985
                c-1.381,2.524-4.521,1.4-6.071,3.304c1.81,2.466,6.896,1.007,7.042,5.495c-1.65,1.735-4.082-0.06-5.289,1.511
                c-1.397,1.814-3.681,0.733-5.67,2.01c2.26,1.307,4.116,2.351,5.941,3.446c1.01,0.605,2.594,1.763,2.177,2.463
                c-1.679,2.817,1.842,4.034,1.549,6.529c-2.915,4.041-7.67,1.002-11.243,2.399c-0.283,0.943,0.169,1.498,0.749,1.797
                c5.054,2.609,10.267,1.887,15.216,0.022c3.193-1.202,3.811-1.536,6.377,1.168c0.567,0.598,0.973,1.367,2.012,1.39
                c1.911-0.545,1.801-2.558,2.64-3.898c0.77-1.232,1.753-2.042,3.261-1.802c1.525,0.243,2.235,1.401,2.515,2.765
                c0.231,1.133,0.177,2.322,0.342,3.473c0.157,1.098,0.053,2.408,2.014,2.811c1.539-3.705,5.958-3.706,8.653-6.388
                c1.873-1.863,4.858-0.53,4.977,2.429c0.227,5.648,4.875,5.68,7.811,7.707c1.775-1.075,1.525-2.622,2.041-3.801
                c0.696-1.591,2.906-1.521,3.365-2.705c1.297-3.347,5.614-4.373,5.854-8.719c0.129-2.352,2.771-3.039,5.137-1.605
                c0,0.666,0,1.334,0,2c-2.02-1.443-3.562-1.08-3.899,1.45c-0.311,2.328-2.013,3.489-3.116,5.157
                c-1.878,2.837-5.643,3.875-6.428,7.723c-0.492,2.406-3.026,3.025-5.266,1.744c-3.684-2.108-7.7-4.031-7.536-9.311
                c0.007-0.202-0.45-0.418-1.326-1.178c-1.449,3.219-5.495,2.584-7.489,5.341c-1.057,1.46-2.242,2.554-4.145,2.031
                c-1.98-0.544-2.023-2.437-2.475-3.997c-0.47-1.627,0.386-3.668-1.296-4.889c-1.151,0.25-1.493,1.005-1.88,1.747
                c-2.545,4.877-3.652,5.102-7.412,1.347c-0.929-0.927-1.745-1.506-3.281-0.578c-0.381,1.301,1.092,2.826-0.171,3.962
                c1.71,1.523,4.698,1.165,4.435,3.935c-0.221,2.308-2.616,2.128-4.406,2.735c1.279,1.665,3.976,1.466,4.412,3.794
                c-2.433,2.382-2.454,2.588-5.453,2.273c-2.186-0.229-4.033,0.308-5.586,1.568c-3.377,2.742-7.085,3.347-11.26,2.575
                c-1.613-0.298-3.517-0.78-5.1,1.178c3.93,1.237,8.214,2.472,10.182,5.359c2.237,3.284,4.576,3.101,7.502,2.988
                c2.824-0.108,5.654-0.226,7.914,2.517c-2.596,1.629-3.511,3.79-2.453,6.902c3.35,1.121,6.969,1.947,9.854,4.95
                c1.801-2.739,4.314-3.672,6.992-3.522c1.219-1.565,0.73-3.186,1.195-4.592c0.491-1.482,1.056-2.834,2.842-3.082
                c1.629-0.227,2.941,0.395,3.977,1.61c1.357,1.596,1.506,3.622,2.026,5.547c0.626,2.313-0.099,5.039,2.161,7.31
                c1.912-1.504,5.164-1.904,4.554-5.141c-0.648-3.438,0.749-5.343,3.329-7.022c1.625-1.058,2.373-2.718,2.674-4.609
                c0.183-1.148,0.473-2.28,0.693-3.423c0.318-1.646,1.277-2.857,2.919-2.98c1.821-0.135,3.278,0.937,3.821,2.652
                c0.596,1.887,0.877,3.883,1.181,5.85c0.92,5.958,1.138,6.161,7.315,6.078c0,0.666,0,1.334,0,2
                c-7.317-0.141-8.26-0.996-9.142-8.137c-0.183-1.481-0.485-2.954-0.827-4.408c-0.289-1.227-0.987-2.094-2.4-2.045
                c-1.039,0.758-1.42,1.838-1.505,3.035c-0.251,3.533-1.568,6.435-4.743,8.3c-1.524,0.896-1.804,2.287-1.398,3.894
                c0.688,2.725-0.746,4.409-2.787,5.851c-4.185,2.956-6.264,2.12-7.107-2.896c-0.358-2.131-0.604-4.29-1.117-6.383
                c-0.446-1.82-1.27-3.478-3.494-3.124c-0.41,0.383-0.759,0.562-0.861,0.836c-0.229,0.613-0.379,1.265-0.488,1.913
                c-0.715,4.259-0.706,4.274-5.021,5.104c-1.054,0.203-1.926,0.513-2.012,1.869c0.521,1.586,2.24,1.72,3.4,2.559
                c1.31,0.945,2.979,1.6,2.795,3.535c-0.692,1.33-1.826,1.767-2.967,1.883c-2.102,0.216-0.113,2.553-2.255,3.119
                c-5.996-0.202-12.593,0.658-19.438-0.328c-0.138,2.629-1.673,2.805-3.752,1.965c-2.466-0.996-4.942-1.973-7.44-2.885
                c-1.1-0.402-2.287-0.717-3.016,0.719c0.684,1.926,1.878,3.462,3.374,4.819c0.879,0.798,1.468,1.784,1.331,3.083
                c-0.538,5.092-0.047,6.274,4.246,7.422c6.1,1.632,11.963,4.089,18.271,5.002c8.412,1.217,16.373,4.217,24.499,6.547
                c1.311,0.377,3.001,0.609,3.311,2.312c0.241,1.49-1.659,1.014-1.728,2.115c1.489,0.915,3.869-0.362,4.756,2.048
                c-1.617,2.965-4.261,2.557-6.979,2.231c-3.087-0.368-6.366-1.049-7.863,2.662c-4.146-0.271-7.903-0.855-11.412-2.552
                c-2.609,3.055-5.65,2.282-8.65,1.249c-2.051-0.705-4.034-1.576-6.274-1.414c-3.935,0.285-7.548-0.906-11.138-2.336
                c-1.617-0.645-3.185-1.962-5.16-0.946c-1.22,1.967-0.64,3.826,0.225,5.709c2.994,1.692,6.432,1.624,9.619,2.502
                c2.99,0.824,6.665,0.265,8.258,3.589c-0.523,2.285-3.195,1.391-3.608,3.499c1.872,0.432,3.666,0.774,5.415,1.272
                c1.277,0.363,2.601,0.846,3.178,2.2c0.719,1.688-1.473,2.136-2.098,3.611c7.82,1.354,15.184,2.64,22.553,3.895
                c1.711,0.292,3.305-0.233,4.83-0.947c5.305-2.483,10.232-0.122,15.398,0.506c-0.408,2.672-2.18,3.063-3.586,3.809
                c-3.266,1.732-6.851,2.025-10.428,2.716c-7.621,1.47-15.146,3.357-22.94,3.748c-2.481,0.125-4.575,1.182-6.629,2.6
                c3.636,3.625,3.649,3.561,7.826,4.439c5.632,1.186,11.276,1.982,16.617,4.706c2.469,1.26,5.909,3.021,9.466,0.976
                c1.605-0.924,3.496,0.062,3.986,2.063c-1.012,2.489-3.835,1.818-5.619,3.671c5.869,1.363,12.188-2.629,16.678,2.57
                c-0.36,1.283-0.793,1.723-1.344,2.15c-3.181,2.473-7.098,2.268-10.688,3.274c-5.965,1.672-11.654,4.156-18.795,2.817
                c1.776,2.146,3.674,1.854,5.078,2.637c5.936,3.307,12.3,4.693,19.107,4.373c4.155-0.195,8.328-0.016,12.492-0.004
                c0,1.334,0,2.666,0,4c-1.77-0.705-3.624-0.408-5.439-0.408c-423.041-0.015-846.081-0.016-1269.121-0.002
                c-1.815,0-3.67-0.296-5.439,0.41c0-311,0-622,0-933c1.999-0.008,3.998-0.022,5.998-0.022
                c295.718-0.001,591.436-0.004,887.154-0.001C1023.101-5.133,1152.051-5.12,1281-5.111z M44.204,81.097
                c3.146-0.339,6.286-0.733,9.438-1c2.222-0.188,4.291-0.711,5.952-2.252c5.804-5.386,12.813-6.691,20.395-6.099
                c1.83,0.143,3.708,0.435,5.445-0.261c3.884-1.556,7.948-2.252,12.238-2.585c0.302-1.568,0.848-2.923-0.148-4.084
                c-3.257-0.393-5.833,3.796-8.998,0.671c3.701-5.52,12.553-8.862,18.505-7.057c0.465,0.141,1.111,0.285,1.303,0.632
                c2.554,4.617,6.105,2.532,9.391,1.736c1.104-1.656-0.539-4.065,1.23-4.795c3.638-1.501,6.519-4.767,10.794-4.744
                c6.649,0.035,13.308-0.363,19.875,1.34c2.59,0.672,5.375,1.174,9.136-0.246c-7.233-2.147-13.648-2.707-20.022-3.358
                c-20.892-2.136-41.635-0.242-62.161,3.437c-18.095,3.243-36.059,3.739-54.132,0.559c-6.047-1.064-12.064-2.296-18.603-3.549
                c-0.011,5.464-0.498,10.684,0.284,15.87c2.18,1.01,4.218,0.068,6.052,0.473c1.438,0.888,0.298,3.342,2.217,3.64
                c3.727,0.578,7.482,2.494,11.243,0.786c3.212-1.459,6.44-1.458,9.77-1.184c3.499,0.288,6.966,0.285,10.456-0.284
                c4.907-0.8,5.895-0.195,8.709,4.63c-1.315,2.819-3.443,4.795-6.288,6.094c-0.785,0.358-2.118,0.274-1.992,1.728
                c-1.564-0.525-2.966-0.635-3.767,1.71C41.96,82.2,43.082,81.648,44.204,81.097z M140.637,630.407
                c-1.095-0.423-1.854,0.325-2.765,0.701c-2.7,1.114-3.973,0.573-5.054-2.074c-0.373-0.913-0.616-1.911-0.731-2.893
                c-0.266-2.271-0.854-4.206-3.335-5.06c-1.435-0.494-1.583-1.89-1.949-3.223c-0.792-2.883-1.753-3.016-3.823-0.771
                c-2.783,3.019-5.765,2.435-6.941-1.402c-0.34-1.109-0.403-2.299-0.695-3.426c-0.296-1.135-0.669-2.285-2.182-2.238
                c-0.804,0.051-1.105,0.663-1.457,1.229c-2.816,4.529-4.191,4.739-7.688,0.829c-1.363-1.524-2.597-2.501-4.681-1.554
                c-1.224,0.556-2.552-1.027-3.916,0.189c-1.089,2.038-0.979,4.977-3.331,6.369c-3.616,2.142-5.675,4.988-5.423,9.346
                c0.066,1.165-0.359,2.396-1.875,2.498c-1.325,0.09-2.219-0.696-2.707-1.834c-0.738-1.719-0.852-3.561-0.68-5.404
                c0.598-6.421,0.317-12.647-3.438-18.27c-0.945-1.415-0.905-3.172-0.921-4.853c-0.013-1.33,0.034-2.687-0.207-3.982
                c-0.317-1.704-1.494-2.446-3.263-2.337c-5.803,0.359-5.79,0.338-7.835-4.934c-0.512-1.318-0.763-2.958-3.087-3.012
                c-1.092,1.858-1.043,4.073-1.431,6.12c-0.356,1.882-0.875,3.62-2.54,4.669c-3.192,2.011-4.862,4.926-5.448,8.562
                c-0.234,1.456-0.995,2.535-2.47,2.787c-1.735,0.296-2.806-0.849-3.588-2.175c-1.663-2.822-1.939-6.014-2.04-9.172
                c-0.142-4.496-0.028-8.999-0.09-13.498c-0.025-1.769,0.121-3.676-1.745-4.814c-1.477,0.195-1.976,1.338-2.688,2.204
                c-0.869,1.057-1.844,2.151-3.282,1.99c-2.085-0.233-2.974,0.907-3.801,2.496c-0.817,1.569-2.297,1.886-3.868,1.376
                c-1.754-0.568-2.057-2.109-1.871-3.66c0.237-1.979,0.799-3.918,1.032-5.896c0.198-1.677,0.893-3.574-1.03-4.9
                c-4.044,5.347-4.044,5.347-7.441,5.37c-0.572,1.586-0.639,3.678-2.942,4.096c-4.203,0.765-8.862-2.574-9.352-6.838
                c-0.247-2.148-0.174-4.33-0.357-6.486c-0.191-2.239-0.181-4.539-1.623-6.702c-0.876,2.786-2.414,5.194-2.401,8.31
                c0.096,22.832,0.079,45.664,0.021,68.496c-0.006,2.332,0.645,4.023,2.383,5.676c1.897,1.805,5.084,3.586,4.988,5.945
                c-0.161,3.979,3.156,5.722,3.673,9.51c2.001-2.469,0.686-5.791,3.813-6.973c3.768,1.619,5.295,5.746,8.417,8.117
                c3.387,2.572,1.775,7.759,5.31,10.193c1.284-0.905,1.418-2.092,1.728-3.212c0.406-1.473,0.888-3.101,2.817-2.897
                c1.881,0.198,2.916,1.656,3.135,3.412c0.247,1.973,0.141,3.988,0.211,5.984c0.053,1.504,0.1,3.031,2.378,3.508
                c0.785-3.225,3.208-4.049,6.125-3.48c0.922-2.209,1.555-4.121,2.488-5.872c1.573-2.951,3.814-2.987,5.679-0.144
                c0.535,0.814,0.929,1.791,1.127,2.747c0.742,3.578,1.188,7.2,4.373,9.755c1.194,0.959,1.029,2.756,1.198,4.235
                c0.193,1.7,0.559,3.281,2.009,4.403c3.704,2.865,4.371,6.847,4.196,11.194c-0.069,1.747-0.606,3.599,0.492,5.307
                c3.158-2.671,4.903-2.412,6.937,0.957c1.023,1.694,1.112,3.913,2.792,5.229c2.521,1.975,2.87,4.759,2.915,7.634
                c0.058,3.666-0.069,7.336,0.055,10.998c0.053,1.553-0.453,3.413,1.403,4.5c1.021-1.304,0.526-2.883,0.885-4.285
                c0.711-2.779,1.475-5.652,3.969-7.301c3.851-2.544,5.186-6.281,5.939-10.512c0.436-2.445,0.727-4.946,1.818-7.244
                c0.715-1.505,1.795-2.338,3.514-2.267c1.53,0.063,2.739,0.906,2.982,2.246c0.937,5.153,5.709,7.577,7.853,11.925
                c3.283-0.402,6.399-0.488,9.13-2.24c-0.411-1.081-1.644-1.588-0.971-3.287c2.268-1.798,5.421-2.102,8.521-2.513
                c4.786-0.636,9.584-1.181,14.364-1.854c1.303-0.184,2.606-0.575,3.822-1.083c0.892-0.371,1.836-0.936,2.408-1.682
                c1.037-1.353,1.572-2.893,0.662-4.646c-0.982-1.892-2.62-2.034-4.275-1.532c-4.299,1.305-8.543,2.791-12.813,4.189
                c-1.586,0.52-3.622,1.408-4.714,0.061c-1.512-1.867-2.862-1.046-4.404-0.668c-4.21,1.033-8.415,2.16-12.811,1.293
                c-1.42-0.28-3.081-0.306-3.396-3.016c9.74-2.797,19.181-6.807,29.499-9.274c-3.267-1.895-6.266-1.37-9.26-1.36
                c-2.647,0.01-5.733,0.595-7.267-2.613c1.269-2.439,3.568-2.107,5.395-2.223c5.398-0.341,10.374-2.303,15.517-3.61
                c0.995-0.253,2.883-0.287,2.244-1.86c-0.932-2.295,2.318-2.141,1.94-3.945c-3.49-0.874-7.018-0.708-10.475-1.137
                c-3.449-0.428-6.851-1.219-8.011-5.18c2.33-3.03,7.197-1.59,8.703-5.643c-2.404-0.756-5.488,1.04-7.065-1.627
                c3.294-6.477,9.573-8.31,15.641-11.241c-3.685-1.332-7.133,0.717-10.325-1.23c2.358-4.146,7.54-3.44,10.516-6.839
                c-2.911-0.177-5.484,0.711-7.905-1.158c2.062-4.697,7.547-3.689,11.141-6.529c-2.264-0.251-3.933-0.326-5.553-0.649
                c-1.366-0.272-2.08-1.075-1.072-2.515c0.542-1.091,2.196-0.593,2.654-1.887c-0.412-1.113-1.026-1.891-2.399-1.43
                C140.234,628.732,140.234,628.732,140.637,630.407z M900.093,767.98c1.208-3.513,5.043-3.963,7.569-6.18
                c1.981-1.74,4.07-0.926,5.152,1.56c0.559,1.283,0.068,3.127,2.254,3.742c1.479-0.216,2.391-2.108,4.176-2.13
                c2.119-0.025,2.263-2.229,3.514-3.211c1.619-1.271,7.933-1.487,9.525-0.007c2.346,2.181,5.715,2.98,7.361,6.273
                c2.324-1.896,4.315-4.548,7.166-2.413c2.468,1.848,4.545,1.27,6.967,0.076c0.137-1.403,0.164-2.736,0.42-4.023
                c0.277-1.394,1.074-2.513,2.649-2.527c1.372-0.014,1.927,1.047,2.388,2.191c0.709,1.763,0.437,3.618,0.667,5.423
                c0.163,1.272-0.55,2.962,1.261,3.8c2.244-5.043,5.816-8.82,9.731-12.246c1.485-1.299,3.429-0.152,4.098,2.023
                c0.463,1.508-0.385,3.42,1.49,4.636c1.432-0.625,2.91-1.271,4.246-1.854c1.016-4.073,1.843-5.339,3.437-4.927
                c2.738,0.709,2.528,3.146,2.715,5.271c0.088,0.994,0.014,2.006,0.135,2.994c0.13,1.066-0.291,2.44,1.31,2.852
                c2.211-5.757,1.79-4.721,7.46-3.836c1.13-1.221,0.79-2.79,0.988-4.241c0.315-2.315-0.789-6.003,2.604-6.087
                c3.893-0.096,2.497,3.883,3.026,6.25c0.277,1.239-0.122,2.658,1.019,3.668c0.221-0.258,0.564-0.473,0.609-0.738
                c0.298-1.785,0.909-3.396,3.062-3.182c2.029,0.203,2.466,1.954,2.799,3.641c0.309,1.567-0.306,3.41,1.67,4.832
                c1.158-0.797,3.026-0.279,4.545-2.527c0-1.184,0.004-3,0-4.816c-0.004-1.51,0.052-3.021,0.816-4.372
                c0.889-1.573,2.529-2.275,3.697-0.974c2.385,2.659,6.299,4.593,5.3,9.271c-0.101,0.475-0.075,1.389,1.37,1.436
                c-0.553-4.524,5.357-6.1,4.805-10.906c-0.104-0.9,1.059-1.639,2.158-1.738c1.344-0.121,2.225,0.559,2.685,1.77
                c0.468,1.23,0.103,2.687,1.222,4.086c1.726-0.273,2.879,1.617,4.801,1.771c3.424,0.274,6.393,1.268,6.668,5.965
                c1.668-1.574,1.316-2.959,1.425-4.197c0.116-1.324,0.043-2.682,0.309-3.972c0.275-1.336,0.941-2.554,2.608-2.553
                c1.688,0.002,2.144,1.271,2.591,2.579c0.544,1.595-0.167,3.527,0.936,5.008c1.665-0.58,1.202-1.771,1.487-2.648
                c0.814-2.514,2.35-3.186,5.97-3.053c2.39,0.087,3.116,1.419,3.347,3.49c0.153,1.373-0.118,3.002,1.668,4.031
                c2.799-2.45,4.165-5.427,4.938-8.809c-4.109-1.105-4.62-1.651-2.821-4.999c2.717-5.058,4.652-10.233,5.082-16
                c0.126-1.683,0.636-3.598,2.694-4.134c1.818,1.16,1.957,2.87,2.233,4.494c0.947,5.58,1.846,11.169,2.816,16.745
                c0.257,1.471,0.696,2.911,1.078,4.358c0.351,1.332,0.845,2.682,2.48,2.697c1.348,0.013,1.963-1.125,2.393-2.24
                c0.479-1.242,0.792-2.551,1.319-3.77c0.964-2.225,1.255-5.254,4.329-5.54c2.576-0.239,3.396,2.225,4.582,4.024
                c1.587,2.41,2.555,2.586,4.901,1.154c0.563-0.345,1.001-0.896,1.564-1.24c3.037-1.856,4.93-1.32,6.264,2.014
                c0.861,2.153,1.354,4.455,2.016,6.688c0.344,1.158,0.838,2.14,2.198,2.428c3.535,0.749,8.682-2.933,8.82-6.535
                c0.068-1.798,0.465-3.38,1.877-4.414c2.442-1.788,4.434-3.784,5.906-6.555c1.34-2.521,3.615-2.119,4.707,0.537
                c0.816,1.986,1.061,4.252,2.756,5.794c2.124,1.934,2.826,4.416,3.066,7.154c0.109,1.244-0.045,2.668,1.32,3.475
                c1.299-0.818,1.381-2.24,1.818-3.469c0.835-2.348,1.413-4.82,2.521-7.029c1.372-2.736,3.547-2.875,5.572-0.646
                c0.442,0.485,0.846,1.06,1.092,1.663c0.994,2.451,2.877,3.513,5.594,3.641c0.499-4.751,5.933-3.999,8.06-7.164
                c2.236-3.329,6.568-3.791,10.235-6.639c-3.18,0.328-5.493,0.759-7.804,0.749c-2.286-0.011-4.86,1.161-7.249-1.262
                c4.242-3.933,9.665-5.79,14.127-9.659c-4.002-1.019-7.816,1.568-11.065-1.158c0.56-3.854,4.146-4.062,6.409-5.804
                c2.465-1.896,5.689-2.091,9.424-4.197c-3.809-0.402-6.404-0.592-8.97-0.985c-1.157-0.178-2.265-0.805-2.247-2.258
                c0.014-1.184,0.962-1.682,1.883-2.09c1.499-0.664,3.71-0.221,4.518-1.773c1.178-2.262,3.412-2.378,6.154-3.353
                c-2.64-0.466-4.615-0.022-5.782-1.989c-0.979-1.65-0.243-3.428-0.231-4.875c-2.31-0.949-4.953,0.487-6.176-2.34
                c1.217-3.157,4.424-2.434,7.039-3.566c-2.32-1.586-2.053-4.726-4.178-6.481c-1.719,1.702-1.201,3.764-2.014,5.382
                c-0.763,1.519-2.223,2.969-3.396,1.781c-2.492-2.521-5.744-2.077-8.58-3.122c-3.014-1.111-5.138-0.021-5.779,2.968
                c-0.276,1.289-0.021,2.889-1.228,3.668c-2.085,1.35-1.912,3.316-1.937,5.312c-0.014,1.166-0.015,2.336-0.131,3.493
                c-0.121,1.194-0.463,2.354-1.891,2.581c-1.398,0.223-2.315-0.621-2.613-1.811c-0.764-3.052-2.809-5.496-3.97-8.14
                c-1.423-3.239-4.395-5.509-4.625-9.312c-4.161,2.729-5.37,2.324-6.674-1.848c-0.645-2.062-0.411-4.477-2.28-6.325
                c-1.882,2.267-1.164,5.47-2.909,7.037c-2.541,2.281-3.603,4.971-4.243,8.081c-0.246,1.193-0.938,2.177-2.226,2.44
                c-1.531,0.313-2.485-0.592-3.303-1.735c-0.812-1.137-0.904-2.913-2.722-3.232c-1.258,1.273-0.48,2.925-1.01,4.275
                c-0.385,0.984-0.966,1.86-2.047,1.965c-1.359,0.131-2.125-0.812-2.481-2.001c-0.236-0.788-0.382-1.644-0.354-2.462
                c0.132-3.909-2.02-5.617-5.773-6.1c-1.816,1.927-1.715,4.585-2.562,6.863c-0.628,1.688-0.961,3.795-3.256,3.93
                c-2.287,0.135-1.99-2.295-2.973-3.478c-0.094-0.112-0.289-0.142-0.534-0.253c-1.814-0.111-2.714,1.611-4.149,2.34
                c-2.584,1.312-4.019,0.688-4.813-2.092c-0.493-1.724-0.112-3.655-1.432-5.727c-0.411,2.34-0.141,4.725-1.201,6.013
                c-2.089,2.538-2.455,5.224-2.53,8.235c-0.033,1.297-0.475,2.847-2.056,3.086c-1.886,0.285-2.943-1.292-2.973-2.709
                c-0.081-3.904-2.991-6.098-5.044-8.676c-1.061-1.332-2.334-2.528-2.971-5.334c-0.943,2.791-0.494,4.42-0.593,6.008
                c-0.071,1.15,0.116,2.683-0.529,3.366c-2.616,2.771-2.203,6.123-2.342,9.419c-0.056,1.324-0.165,2.666-0.458,3.953
                c-0.221,0.971-0.803,1.909-1.947,2.027c-1.492,0.153-2.332-1.076-2.32-2.119c0.055-4.725-1.985-8.406-4.982-11.845
                c-0.98-1.125-0.855-3.116-2.531-4.24c-1.451,1.673-1.62,3.506-2.154,5.198c-0.498,1.575-0.729,3.281-1.849,4.604
                c-0.937,1.105-2.314,2.029-3.387,0.757c-1.729-2.054-4.678-2.966-5.878-4.913c-1.989-3.232-4.907-3.831-7.611-5.041
                c-3.166,2.475-2.051,7.946-6.67,8.721c-3.416-2.007-1.334-5.989-3.934-8.523c-0.164,2.436-1.344,3.622-3.074,4.898
                c-3.526,2.602-7.543,4.688-10.088,8.559c-1.269,1.929-3.218,1.506-4.248-0.624c-0.637-1.315-0.389-2.949-1.637-4.365
                c-1.871,0.878-2.555,2.969-4.069,4.194c-2.193,1.775-3.517-0.457-5.096-1.119c-2.27,5.65-2.599,6.108-6.317,8.951
                c-2.581,1.973-4.34,1.715-6.297-0.854c-0.941-1.236-1.096-3.265-3.592-3.389c-1.695,3.506-5.973,4.638-7.956,8.312
                c-0.973,1.803-3.124,1.437-4.656-0.202c-0.823-0.88-0.933-2.488-3.068-2.828c-0.423,2.783-0.783,5.362-1.219,7.931
                c-0.249,1.469-0.426,2.996-0.979,4.357c-0.989,2.435-2.914,2.665-4.527,0.564c-0.796-1.035-1.229-2.345-1.869-3.507
                c-0.64-1.161-1.083-2.954-2.045-3.315c-3.287-1.232-3.598-4.104-4.472-6.748c-0.203-0.615-0.462-1.272-1.983-0.861
                c0.793,5.244-5.581,7.471-5.689,12.813c-0.018,0.884-1.1,1.698-2.227,1.675c-1.396-0.027-2.014-1.016-2.301-2.23
                c-0.191-0.809-0.301-1.637-0.419-2.461c-0.302-2.1-0.114-4.333-2.509-5.594c-1.082-0.57-1.046-1.994-1.29-3.133
                c-0.249-1.159-0.245-2.461-1.257-3.409c-1.623,1.515-1.113,3.384-1.718,4.896c-0.737,1.843-3.355,3.074-5.878,2.841
                c-2.16-0.2-2.617-1.794-2.835-3.507c-0.21-1.652-0.289-3.32-0.419-4.98c-0.301-3.857-0.598-7.714-0.932-12.025
                c-2.244,1.525-1.751,3.438-2.377,4.877c-1.387,3.188-2.381,3.57-5.639,1.797c-0.418-0.227-0.848-0.43-1.822-0.922
                c-0.843,4.283-0.592,8.807-3.942,12.026c-0.456,0.438-0.17,1.612-0.306,2.432c-0.137,0.819-0.263,1.669-0.585,2.422
                c-0.912,2.132-3.32,2.589-4.135,0.543c-1.37-3.444-5.525-4.017-6.767-7.713c-0.871,4.729-3.506,7.61-7.9,8.339
                c-1.451,0.24-3.176-0.747-4.688-1.383c-2.917-1.226-1.725-4.885-3.832-6.782c-2.309-0.179-4.066,0.081-5.969,2.228
                c-1.736,1.958-3.66,3.594-4.604,6.147c-0.424,1.147-1.467,1.833-2.792,1.663c-1.14-0.147-2.032-1.107-1.915-2.006
                c0.561-4.312-3.391-5.535-5.06-8.312c-3.498,1.634-5.63,3.81-5.166,7.727c0.075,0.638-0.122,1.325-0.278,1.97
                c-0.253,1.049-0.867,1.822-2.005,1.924c-1.156,0.104-1.958-0.61-2.272-1.587c-0.607-1.892-1.019-3.847-1.518-5.774
                c-0.316-1.222,0.025-2.766-1.771-3.417c-1.411,4.305-0.775,9.3-4.354,12.872c-0.47,0.469-0.314,1.598-0.389,2.426
                c-0.165,1.825-0.264,3.656-0.442,5.48c-0.188,1.914,0.229,4.614-2.448,4.693c-2.546,0.075-2.432-2.633-2.977-4.501
                c-0.645-2.211-0.056-4.987-1.939-6.577c-2.543-2.146-2.478-4.859-2.693-7.679c-0.136-1.779,0.455-3.784-1.29-5.918
                c-0.677,2.486-1.847,3.831-3.253,5.282c-2.325,2.4-5.83,3.87-6.777,7.627c-0.354,1.406-1.544,2.77-3.299,2.567
                c-1.705-0.196-2.357-1.71-2.844-3.159c-0.583-1.734-1.088-3.495-1.609-5.248c-0.375-1.258-0.561-2.612-2.435-3.729
                c-1.253,2.629-4.591,1.918-6.585,4.305c-1.736,2.076-3.928,1.122-4.758-1.578c-0.434-1.414,0.135-2.999-0.744-4.372
                c-1.861,0.719-1.31,2.495-1.727,3.831c-0.592,1.898-0.566,3.986-1.643,5.73c-1.129,1.829-2.951,1.842-3.84-0.059
                c-0.552-1.181-0.691-2.555-1.01-3.845c-0.422-1.704,0.297-3.731-1.655-5.304c-2.452,3.049-5.448,2.17-8.675,0.57
                c-0.85,2.817-4.242,3.032-4.796,6.139c-0.262,1.464-2.599,1.417-3.64,0.006c-0.574-0.777-0.854-1.787-1.192-2.719
                c-0.786-2.16-2.872-2.91-4.398-2.186c-1.509,0.716-3.286,1.454-4.245,3.623c-1.209,2.732-3.144,5.105-3.312,8.301
                c-0.042,0.791-0.877,1.375-1.881,1.514c-2.258-0.84-2.376-2.916-2.557-4.891c-0.304-3.318-0.929-6.679-0.667-9.962
                c0.469-5.883-4.146-8.774-6.433-13.36c-2.309,2.749-2.349,5.76-3.554,8.293c-1.654,3.48-3.265,3.813-5.965,1.148
                c-0.888-0.877-1.294-2.426-3.031-2.097c-1.654,1.296-1.275,4.095-3.174,4.927c-2.643,1.16-3.414,3.384-4.424,5.683
                c-0.756,1.723-1.342,3.972-4.223,3.422c-1.818-3.024-1.201-6.442-1.388-9.706c-0.2-3.522-0.325-6.926-3.052-9.724
                c-1.393-1.429-1.68-3.575-2.086-5.545c-0.217-1.05,0.04-2.39-1.727-2.91c-1.371,4.455-5.551,7.86-4.479,13.249
                c0.267,1.341-0.493,2.662-1.481,3.585c-2.302,2.149-3.511,4.804-4.047,7.843c-0.183,1.037-0.645,1.885-1.933,2.062
                c-1.794-0.661-2.174-2.266-2.385-3.965c-0.288-2.314-0.551-4.632-0.842-6.946c-0.145-1.148-0.082-2.482-1.156-3.182
                c-2.363-1.541-2.694-4.043-3.441-6.42c-0.35-1.113-0.041-2.822-2.195-3.104c-1.32,4.901-6.887,7.339-7.43,12.89
                c-0.105,1.089-1.299,1.987-2.654,1.793c-1.522-0.218-2.443-1.417-2.566-2.732c-0.483-5.146-4.84-6.012-8.146-8.113
                c-1.817,1.691-1.893,3.787-2.376,5.676c-0.617,2.416-0.963,4.899-1.971,7.209c-0.485,1.113-1.089,2.154-2.438,2.203
                c-1.335,0.048-2.079-0.89-2.595-1.998c-0.557-1.195-0.754-2.971-1.68-3.508c-2.943-1.707-2.563-4.355-2.686-6.99
                c-0.068-1.475,0.616-3.146-1.222-5.178c0.06,4.161-3.655,5.443-4.255,8.652c-0.182,0.968-1.576,1.032-2.618,0.953
                c-1.126-0.086-2.062-1.221-3.351-0.589c-1.51,1.079-1.284,2.962-1.934,4.448c-0.631,1.443-1.292,2.863-3.117,2.683
                c-1.548-0.153-2.16-1.399-2.339-2.838c-0.145-1.159-0.224-2.349-1.171-3.198c-3.007-2.696-4.268-6.508-6.278-9.785
                c-2.039,4.135-4.389,7.969-7.711,11.382c-3.331,3.423-3.35,9.382-7.86,11.968c-2.618-0.866-2.819-2.744-3.163-4.557
                c-0.397-2.098-1.007-3.812-2.804-5.498c-2.663-2.499-2.908-6.851-5.982-9.385c-0.562-0.465-0.344-1.902-0.442-2.895
                c-0.098-0.988-0.013-2.014-0.804-2.926c-1.025,1.272-0.858,3.257-2.527,4.1c-2.687,1.357-3.517,3.922-3.939,6.594
                c-0.479,3.032-1.191,5.877-3.672,7.961c-0.955,0.803-1.222,1.932-1.386,3.117c-0.211,1.52-1.026,2.573-2.662,2.242
                c-1.36-0.275-1.674-1.525-1.609-2.752c0.201-3.793,0.995-7.713-2.616-10.562c-0.095-0.075-0.106-0.33-0.072-0.482
                c0.888-4.029-3.339-6.029-3.23-10.588c-1.654,2.906-0.9,5.602-2.708,6.959c-1.729,1.299-2.093,2.791-2.092,4.703
                c0,1.388-0.351,2.842-2.08,2.868c-2.061,0.031-2.167-1.651-2.322-3.182c-0.149-1.47,0.734-3.44-0.425-4.315
                c-2.028-1.531-2.451-3.74-3.568-7.133c-0.756,3.932-4.249,4.015-4.978,6.25c-1.221,3.746-4.927,6.409-4.522,10.826
                c0.099,1.082-0.702,1.898-1.883,1.884c-1.125-0.015-1.819-0.769-2.107-1.808c-0.59-2.126-0.28-4.309-0.294-6.467
                c-0.012-1.855-0.272-3.283-1.947-4.707c-2.808-2.391-5.753-4.968-5.941-9.187c-0.008-0.181-0.432-0.342-0.678-0.524
                c-1.433,0.951-1.225,2.912-2.845,3.936c-5.341,3.375-10.222,7.297-12.924,13.306c-0.49,1.089-1.428,1.917-2.759,1.776
                c-1.341-0.142-1.853-1.17-2.283-2.332c-0.557-1.502-0.101-3.204-1.543-4.585c-2.521-2.415-4.992-4.975-5.721-9.042
                c-2.057,1.357-1.814,3.87-3.662,4.492c-1.875,0.632-2.514,1.978-2.69,3.78c-0.123,1.256-0.698,2.294-2.125,2.339
                c-1.622,0.05-2.646-1.121-2.653-2.457c-0.025-4.425-3.774-6.332-5.854-10.042c-0.58,1.581-0.907,2.511-1.26,3.431
                c-1.51,3.928-3.039,4.113-5.855,1.15c-1.971-2.074-3.451-4.428-4.914-7.49c-0.851,2.821-0.634,5.443-3.406,6.447
                c-0.861,0.312-0.735,1.403-0.862,2.206c-0.221,1.395-0.608,2.751-2.335,2.692c-1.957-0.066-2.411-1.557-2.383-3.166
                c0.055-3.087-2.806-5.596-1.708-8.869c-2.652-1.765-2.574-5.09-4.076-7.671c-1.756,1.866-1.177,4.526-2.823,6.044
                c-2.627,2.423-4.72,5.109-5.538,8.68c-0.232,1.012-0.926,1.818-2.121,1.73c-1.137-0.084-1.778-0.892-2.05-1.898
                c-0.706-2.605-0.618-5.286-0.578-7.951c0.042-2.784-0.587-5.195-3.435-6.629c-1.612,2.621-4.614,4.209-4.927,7.907
                c-0.164,1.942-2.964,2.198-3.647,0.321c-0.922-2.532-3.306-4.527-3.658-6.797c-0.646-4.162-3.645-5.919-5.907-8.62
                c-2.543,2.606-1.534,6.913-5.007,8.558c-4.125-0.961-2.193-5.303-4.291-7.299c-4.906,2.701-6.442,1.882-6.758-3.641
                c-0.104-1.828,0.008-3.672-0.151-5.492c-0.096-1.092-0.171-2.403-1.734-2.58c-1.859,0.654-2.097,2.531-2.945,3.893
                c-1.483,2.377-3.227,0.172-4.724,0.314c-2.069,3.311-0.58,8.243-4.887,10.289c-1.849-0.942-1.804-2.564-2.214-3.958
                c-0.385-1.306,0.199-3.046-1.711-3.839c-2.195,1.658-1.976,5.037-4.632,6.345c-1.917,0.944-2.85,2.673-3.213,4.785
                c-0.196,1.141-0.624,2.331-1.679,2.975c-2.128,1.296-2.554,3.337-2.738,5.565c-0.093,1.121,0.025,2.395-1.626,3.146
                c-2.346-0.229-3.452-1.922-3.717-4.251c-0.187-1.644,0.136-3.353-0.115-4.979c-0.867-5.642-2.388-10.991-7.915-14.004
                c-1.134-0.618-1.813-1.412-1.877-2.699c-0.049-0.987,0.274-2.319-0.626-2.811c-3.385-1.844-3.356-5.05-3.696-8.201
                c-0.077-0.715-0.443-1.607-1.569-1.587c-1.479,1.046-0.914,2.702-1.161,4.103c-0.318,1.801-0.547,3.617-0.808,5.429
                c-0.153,1.066-0.792,2.353-1.612,2.313c-2.4-0.118-4.392,1.202-6.606,1.447c-2.021,0.225-3.487-1.111-4.129-3
                c-0.531-1.561-0.638-3.264-1.14-4.838c-0.52-1.628-0.384-3.793-3.015-4.262c-1.998,2.729-2.032,6.584-5.059,8.863
                c-2.839,2.139-3.784,5.343-3.615,8.896c0.067,1.423,0.663,2.983-0.597,4.226c-5.171-0.549-6.643-2.258-5.237-6.967
                c1.087-3.639,0.388-6.57-2.104-9.178c-2.255-2.359-3.721-5.057-4.381-8.262c-0.214-1.037-0.417-2.436-2.29-2.213
                c-0.197,0.727-0.509,1.502-0.608,2.304c-0.51,4.149-2.153,7.535-5.624,10.153c-3.232,2.438-4.346,6.201-4.959,10.066
                c-0.235,1.479-0.275,2.994-0.574,4.457c-0.372,1.816-0.818,3.74-3.316,3.596c-2.386-0.137-4.258-2.289-3.8-4.826
                c0.406-2.248,0.362-4.293-0.715-6.286c-0.959-1.773-2.226-3.329-3.688-4.704c-1.876-1.764-2.823-3.74-1.538-6.229
                c1.458-2.824,0.033-4.825-1.9-6.582c-2.251-2.046-4.202-4.259-5.313-7.142c-0.438-1.135-1.095-2.101-2.739-1.687
                c-0.952,1.29-1.169,2.975-1.754,4.513c-0.813,2.138-2.066,4.006-4.685,3.655c-2.443-0.327-4.388-1.606-5.057-4.198
                c-0.249-0.964-0.319-1.973-0.501-2.955c-0.25-1.356-0.828-2.481-2.054-3.272c-5.116-3.3-10.604-6.129-14.234-11.538
                c-0.488,0.707-0.926,1.071-1.007,1.503c-0.716,3.806-2.355,6.9-6.111,8.542c-0.933,0.408-1.903,1.232-1.815,2.221
                c0.394,4.469-2.368,6.548-5.778,8.371c-0.958,0.512-1.772,1.562-2.302,2.553c-1.439,2.691-3.426,2.674-5.567,1.811
                c-0.592-0.951-0.579-1.611-0.277-2.229c2.059-4.224,0.789-7.917-2.232-10.943c-2.361-2.366-2.847-4.681-1.098-7.41
                c-0.651-0.688-1.019-1.331-1.572-1.618c-3.636-1.888-5.276-4.849-5.234-8.919c0.012-1.223-0.046-2.717-1.807-3.061
                c-1.2,1.146-1.203,2.695-1.52,4.145c-0.32,1.462-0.583,2.966-1.146,4.339c-1.051,2.562-2.682,2.823-4.561,0.851
                c-1.427-1.499-1.91-3.451-2.568-5.346c-0.743-2.135-0.907-4.656-3.293-5.892c-4.442,4.095-6.596,3.682-9.11-1.837
                c-0.965-2.118-1.64-4.369-2.623-6.479c-0.733-1.574-1.052-3.59-3.562-4.127c-1.896,3.18-2.243,6.854-3.008,10.379
                c-0.812,3.738-1.209,7.572-2.536,11.189c-0.46,1.254-1.042,2.602-2.672,2.586c-1.527-0.016-2.307-1.178-2.895-2.414
                c-1.086-2.281-1.45-4.766-1.918-7.216c-0.407-2.126-0.437-4.634-1.967-6.085c-2.294-2.177-3.811-4.733-4.886-7.771
                c-2.077,1.989-2.077,1.989-1.487,4.607c-2.263,2.12-4.407,4.437-7.952,4.655c2.346,0.889,4.603,1.324,6.816,1.92
                c2.066,0.555,4.71,0.329,5.35,3.148c-1.053,1.584-2.997,1.165-3.942,2.471c1.803,0.992,4.845,0.419,4.893,3.896
                c-2.33,0.879-5.301-0.156-7.222,2.948c4.885,0.389,4.409,4.624,6.202,7.315c-2.665,2.876-6.21,0.783-9.606,2.342
                c2.982,1.711,5.594,1.229,8.161,1.145c2.317-0.076,5.049-0.508,6.523,1.502c1.115,1.52,2.936,2.169,4.013,4.271
                c-2.792,1.234-0.506,3.489-0.851,5.736c-6.85,3.355-14.639,2.143-21.806,4.188c5.89,1.37,10.485,6.621,17.112,5.221
                c0.566-0.119,1.302,0.253,1.869,0.561c0.384,0.209,0.61,0.707,0.738,0.865c0.412,1.875-2.133,2.451-1.217,4.493
                c5.499,2.563,12.183,3.349,16.928,8.126c-3.403,3.515-6.999,4.61-11.252,3.98c-2.465-0.365-4.98-0.391-7.472-0.584
                c-0.992-0.078-2.234-0.511-2.898,0.219c-2.174,2.393-4.543,1.229-6.938,0.717c-1.67-0.357-3.283-1.238-5.312-0.936
                c3.897,4.607,3.897,4.607,3.61,7.664c2.811,2.168,6.198,3.094,9.605,3.811c9.608,2.023,19.23,3.99,28.878,5.815
                c3.599,0.681,6.476,2.021,7.822,5.786c-0.288,0.305-0.561,0.867-0.946,0.959c-4.735,1.123-9.897-0.318-14.375,2.394
                c-3.927-1.236-7.862-0.929-11.933-0.724c-3.894,0.195-7.854-1.229-11.806-1.803c-3.391-0.492-6.704-1.867-10.247-1.335
                c0.171,3.229,0.152,3.299,1.563,3.683c0.956,0.262,1.986,0.234,2.962,0.436c2.932,0.603,6.005,0.357,8.849,1.438
                c0.911,0.347,1.782,0.744,2.08,1.923c0.292,1.156-1.639,1.096-1.112,2.346c2.989,0.396,6.218,0.521,5.685,5.278
                c5.251-0.673,10.073-1.073,12.49-6.481c0.815-1.822,2.339-2.91,4.415-2.54c1.906,0.34,2.634,1.854,2.902,3.627
                c0.317,2.1,0.636,4.198,0.975,6.434c4.875-0.083,9.644-1.337,14.429,0.798c-1.715,3.702-5.5,2.899-7.599,4.686
                c-0.256,1.044,0.232,1.742,0.705,2.418c0.378,0.543,0.778,1.107,1.287,1.513c1.438,1.146,3.325,0.873,3.569-0.801
                c0.579-3.967,2.646-5.792,6.555-6.155c0.97-0.091,1.863-1.309,2.736-2.071c1.013-0.884,2.033-1.987,3.34-2.097
                c2.811-0.237,4.236-1.603,5.494-4.147c1.553-3.142,4.938-2.812,6.569,0.327c0.449,0.865,0.677,1.875,0.852,2.847
                c0.758,4.224,1.109,8.649,5.939,10.598c0.272,0.11,0.429,0.498,0.649,0.747c4.575,5.195,7.531,5.887,14.415,3.373
                c-1.021-0.783-1.166-1.568,0.043-2.282c1.599-0.944,3.372-1.427,5.168-1.8c1.319-0.273,3.237-0.406,3.529-1.531
                c0.996-3.838,4.345-3.742,7.022-4.598c3.326-1.062,6.751-1.818,9.96-3.24c0.279-0.124,0.482-0.418,0.784-0.689
                c0.293-1.352,0.961-2.773-0.227-4.166c-2.195-0.459-4.298,0.15-6.417,0.466c-1.292,0.192-2.505,0.635-3.717-0.532
                c-2.281-2.195-5.131-1.912-7.957-1.426c-1.538,0.265-3.068,1.217-4.6,0.268c-0.225-1.654,1.061-2.193,1.636-3.146
                c0.88-1.458,3.412-2.294,3.377-3.297c-0.12-3.453,2.33-3.094,4.263-3.617c3.431-0.928,6.96-0.49,10.444-0.684
                c1.016-0.057,2.196,0.13,2.879-1.312c-2.523-0.706-5.238,1.576-7.036-1.048c0.045-0.899,0.524-1.366,1.073-1.749
                c3.14-2.188,5.39-4.832,5.551-8.955c0.076-1.914,1.248-3.405,3.169-3.82c1.774,0.086,2.085,1.717,3.134,2.492
                c3.053,2.258,3.01,2.316,0.562,5.545c2.122,6.596,9.31,5.814,14.225,9.303c-4.507,3.448-8.873,0.129-12.863,0.503
                c-0.615,1.996,0.735,2.331,1.773,2.669c2.846,0.929,5.731,1.737,8.609,2.564c3.172,0.911,6.083,2.209,8.781,5.343
                c-7.125,1.573-12.835-2.245-18.887-2.448c-1.771,6.957-1.32,7.79,4.821,9.438c1.124,0.303,2.273,0.568,3.333,1.027
                c1.046,0.453,2.146,1.018,1.928,2.664c-1.177,1.896-5.423-0.362-5.253,3.908c3.482-0.234,6.971-0.179,10.139,1.635
                c2.792,1.599,6.715,1.265,8.741,4.559c-1.997,1.51-3.989,0.207-6.028,1.375c2.134,2.131,4.888,2.134,7.297,2.891
                c2.55,0.8,5.561,0.579,7.231,2.668c-0.079,2.128-2.579,1.637-2.25,3.692c3.585,0.812,4.696,3.812,5.145,7.541
                c2.592-1.659,2.593-4.024,3.545-5.844c1.156-2.209,1.199-5.967,4.71-5.472c2.923,0.41,2.815,3.664,3.33,6.074
                c0.438,2.047,0.253,4.362,2.138,5.889c4.945-2.998,6.167-2.449,6.989,3.254c0.403,2.799,0.531,5.637,0.831,8.451
                c0.11,1.033-0.091,2.315,1.724,2.766c1.268-3.332,2.012-6.881,4.626-9.342c1.864-1.754,2.463-3.861,2.555-6.271
                c0.107-2.83,0.182-5.668,0.892-8.436c0.299-1.165,0.728-2.309,2.093-2.5c1.676-0.236,2.382,1.004,2.812,2.294
                c0.956,2.872,1.001,5.888,1.328,8.87c0.439,4.012-0.16,8.417,3.953,11.27c0.783,0.543,0.819,2.109,1.304,3.156
                c0.379,0.82,0.36,2.07,2.193,1.994c0.727-1.705,0.399-3.725,0.809-5.586c0.317-1.436,0.038-3.633,1.399-4.089
                c3.402-1.141,4.147-3.978,5.317-6.69c0.669-1.551,1.567-3.166,3.55-2.996c1.863,0.159,2.889,1.611,3.304,3.326
                c0.59,2.438,1.742,4.107,4.349,4.857c1.154,0.333,2.14,1.555,2.998,2.55c1.76,2.042,3.807,2.569,6.259,1.544
                c3.48-1.455,6.305-0.929,8.912,2.073c2.055,2.366,3.269,2.003,4.937-0.796c1.184-1.987,1.626-4.746,3.802-5.73
                c4.327-1.955,5.182-5.706,6.015-9.676c0.408-1.946,0.99-3.863,1.622-5.75c0.329-0.983,1.105-1.66,2.236-1.713
                c1.401-0.065,2.258,0.836,2.552,1.979c0.453,1.762,0.547,3.611,0.877,5.408c0.233,1.268-0.069,2.892,1.293,3.602
                c3.316,1.727,4.146,5.033,5.479,8.055c0.732,1.66,1.104,3.818,2.895,4.512c2.841,1.102,3.943,3.379,5.048,5.838
                c0.405,0.902,0.983,1.791,2.421,1.596c2.068-2.621,2.332-6.036,3.327-9.164c0.705-2.217,1.071-4.575,2.46-6.506
                c1.335-1.855,3.095-1.867,4.538-0.086c1.183,1.461,1.568,3.287,2.056,5.049c0.646,2.329,1.846,3.752,4.226,5.006
                c3.867,2.036,8.46,3.066,11.341,6.819c0.22,0.286,0.909,0.214,1.262,0.284c0.431-0.238,0.832-0.324,0.989-0.568
                c0.988-1.532,1.927-3.098,2.873-4.656c0.898-1.48,2.007-2.865,3.888-2.627c1.898,0.24,2.635,1.93,3.129,3.546
                c0.583,1.907,0.9,3.897,1.496,5.8c0.375,1.197,0.527,2.697,2.188,2.975c1.117-0.273,1.616-1.171,2.155-2.004
                c0.632-0.979,1.113-2.07,1.839-2.969c2.133-2.639,5.001-2.797,6.461,0.149c1.459,2.944,3.814,3.874,6.472,4.795
                c3.13,1.084,3.202,1.065,4.864-2.16c0.381-0.739,0.654-1.555,1.141-2.214c1.647-2.232,4.215-2.106,5.689,0.283
                c0.43,0.695,0.719,1.511,0.92,2.31c0.861,3.415,4.217,2.794,6.444,3.899c1.861,0.924,2.857-0.725,3.94-2.112
                c1.716-2.197,3.807-2.785,5.578-1.256c1.839,1.588,3.968,2.239,6.037,3.227c8.868,4.231,10.28,2.402,18.289-2.661
                c2.567-1.623,4.537-1.768,6.326,0.764c0.731,1.035,0.807,2.611,2.493,2.846c1.984-0.576,1.919-2.536,2.589-3.959
                c0.645-1.369,1.084-3.031,2.65-3.43c2.576-0.658,3.128-2.594,3.821-4.713c0.569-1.74,0.454-4.079,2.758-4.721
                c1.739,0.723,1.759,2.153,2.028,3.414c0.247,1.156,0.386,2.637,1.438,3.067c3.979,1.627,4.644,5.324,5.973,8.698
                c0.551,1.4,0.74,3.416,3.504,3.277c0.807-2.201,2.061-4.138,4.875-4.812c2.659-0.638,1.947-4.895,5.095-5.67
                c3.082,3.105,1.392,8.482,5.454,11.494c1.167-2.701,0.79-5.071,0.792-7.303c0.002-3.239,3.708-3.127,4.496-5.55
                c0.499-1.534,1.494-2.61,3.279-2.542c2.035,0.078,2.764,1.512,3.337,3.184c0.473,1.376,0.889,2.629,2.549,3.401
                c3.909,1.819,8.189,3.245,10.325,7.689c2.206-1.146,4.066-2.112,5.952-3.092c0.147-0.704,0.283-1.353,0.418-2.001
                c0.17-0.814,0.222-1.677,0.53-2.434c0.877-2.148,3.219-2.57,4.804-0.902c0.953,1.004,1.134,2.38,1.659,3.594
                c0.533,1.233,0.271,3.123,2.366,3.33c1.595-1.657,0.751-3.852,1.319-5.711c0.311-1.014,0.853-1.811,1.928-2.082
                c1.244-0.314,2.382-0.028,3.068,1.075c0.606,0.976,1.012,2.079,1.471,3.142c0.573,1.322,0.51,3.031,2.623,3.758
                c1.433-1.592,4.494-1.504,5.53-3.256c1.343-2.271,3.831-2.383,5.304-4.182c2.76-3.374,4.56-2.912,6.249,1.115
                c0.678,1.615,0.172,3.769,2.149,4.912c2.219-1.346,1.611-4.663,4.277-5.825c3.139-1.368,4.762,1.941,7.59,1.985
                c0.381-0.33,1.232-0.7,1.297-1.177c0.557-4.14,3.695-6.835,5.551-10.236c0.695-1.274,1.641-2.147,3.123-2.073
                c1.357,0.069,1.913,1.178,2.393,2.27c0.449,1.024,0.334,2.844,1.664,2.79c2.914-0.119,4.218,2.431,6.724,3.574
                c2.179-2.768,5.96-3.979,6.981-7.874c0.429-1.632,1.72-2.546,3.328-2.545c1.516,0,2.28,1.191,2.657,2.579
                c0.225,0.826,0.524,1.573,1.532,1.66c1.754-0.889,0.871-3.408,2.344-4.198c2.771-1.484,3.232-3.98,3.676-6.646
                c0.188-1.134,0.496-2.284,1.357-3.171c1.355-1.396,3.514-1.632,4.283-0.078c1.088,2.196,2.511,3.822,4.627,4.974
                c2.371,1.291,2.189,4.276,4.144,6.803c1.61-4.526,6.074-5.85,8.003-9.583c0.727-1.408,2.027-2.424,3.623-2.248
                c1.797,0.197,2.229,1.76,2.367,3.354c0.088,0.997,0.17,2.011,1.217,2.74c0.365,0.047,0.914,0.287,1.328,0.141
                c3.449-1.21,6.727-1.637,9.902,0.906c1.254,1.004,2.953,0.817,4.106-0.435c1.622-1.761,3.575-2.128,5.853-2.173
                c3.103-0.059,6.276,0.26,9.062-1.92c1.352-1.058,3.561-0.961,4.672,0.298c1.988,2.249,4.617,3.044,7.119,4.28
                c4.169,2.061,4.112,2.176,5.942-2.129c0.261-0.612,0.471-1.252,0.78-1.839c1.871-3.556,4.545-3.932,6.822-0.533
                c1.684,2.515,3.424,3.398,6.229,2.437c3.088-1.058,5.537-0.34,7.18,2.66c0.552,1.008,1.233,2.027,2.744,1.844
                c1.639-0.488,1.835-2.15,2.576-3.398c2.337-3.94,4.961-3.945,7.446,0.018c0.765,1.221,1.098,2.824,2.941,2.929
                c2.42-1.39,2.177-4.558,4.25-6.069c2.198-1.604,4.882-0.265,7.44-1.092c0.459-1.53,0.908-3.109,1.411-4.672
                c0.487-1.51,1.294-2.758,3.088-2.792c1.538-0.029,2.46,0.883,2.911,2.275c0.36,1.112,0.609,2.194,1.927,2.688
                c3.366,1.26,6.606,3.206,10.681,1.408c0.341-0.859,0.789-1.916,1.18-2.992c0.559-1.539,1.463-2.621,3.25-2.572
                c1.836,0.051,2.564,1.267,2.961,2.846C898.275,765.799,897.988,767.342,900.093,767.98z M624.265,844.209
                c0.79-5.775,6.643-9.299,5.247-15.319c-0.052-0.222,0.327-0.558,0.529-0.821c1.315-1.719,2.559-3.501,3.978-5.13
                c1.634-1.876,3.338-1.55,4.021,0.771c0.419,1.426,0.627,2.946,0.697,4.436c0.251,5.386,1.715,10.504,3.31,15.594
                c1.045,3.335,2.134,6.658,3.283,9.959c0.926,2.661,1.264,5.121-1.812,6.562c1.379,3.799,2.634,7.234,3.872,10.678
                c0.34,0.945,0.5,1.836-0.068,2.844c-0.768,1.363-2.256-0.205-3.195,1.107c1.959,3.568,4.314,6.961,6.891,10.215
                c0.823,1.038,1.754,2.05,2.312,3.227c1.469,3.095,0.391,5.021-3.013,5.66c-0.632,0.118-1.282,0.133-2.102,0.213
                c0.325,2.793,1.122,5.176,2.633,7.695c5.901-5.152,11.981-9.626,11.181-18.396c-5.114-0.172-5.785-1.173-3.914-5.271
                c1.045-2.291,2.544-4.285,4.166-6.202c1.718-2.028,3.223-4.188,3.602-6.461c-0.52-1.957-2.64-1.501-3.024-3.451
                c0.608-1.696,2.405-2.717,3.88-3.984c3.196-2.744,5.977-5.8,7.698-9.704c0.538-1.219,1.271-2.479-0.086-3.618
                c-1.933-1.625-0.945-3.304-0.103-4.927c0.764-1.472,1.676-2.866,2.484-4.229c-0.566-1.189-2.227-1.189-2.326-3.106
                c2.223-2.483,4.682-5.233,6.932-7.748c1.289,0.136,1.805,0.651,1.996,1.266c2.129,6.826,4.28,13.647,6.254,20.52
                c0.541,1.882-0.291,3.242-2.564,3.271c-0.887,0.011-1.834-1.041-2.779,0.219c2.551,5.439,8.169,7.638,12.533,10.754
                c-1.236,3.277-4.246,2.584-6.262,4.125c2.635,3.998,6.984,5.533,10.377,8.023c-0.541,2.552-3.076,2.461-4.648,3.777
                c0.795,0.732,1.232,1.223,1.754,1.593c0.52,0.369,1.123,0.619,1.633,0.891c3.617-3.127,3.95-3.217,4.162-6.02
                c0.404-5.362,3.062-9.378,6.83-12.907c1.267-1.187,2.75-2.314,3.043-4.203c-5.112-2.419-5.382-3.076-3.32-7.949
                c0.584-1.379,1.253-2.725,1.943-4.055c1.191-2.295,2.953-4.432,0.803-7.207c-0.467-0.602-0.174-1.979,0.077-2.916
                c1.304-4.863,3.289-9.432,6.317-13.488c0.785-1.052,1.668-2.139,4.049-1.547c3.144,6.938,9.032,12.975,11.268,20.409
                c-1.136,2.093-2.664,0.47-3.592,1.852c1.892,3.215,3.891,6.47,5.744,9.806c1.879,3.377,4.655,6.381,4.906,10.303
                c-1.143,1.256-2.633,0.109-3.464,1.206c-0.539,5.899,3.771,15.409,8.332,18.288c0.683,0.431,1.609,1.025,2.261,0.867
                c4.455-1.076,8.955-2.187,11.646-6.417c-1.775-3.657-6.68-4.166-6.854-8.637c-0.154-3.997,2.379-6.813,3.945-10.045
                c-0.561-1.316-2.678-0.774-2.814-3.149c0.382-1.648,2.189-2.955,3.662-4.484c2.654-2.756,5.629-5.224,7.645-8.547
                c0.994-1.641,1.688-3.15-0.201-4.839c-3.064-2.737-3.084-2.991-1.098-6.53c1.465-2.611,3.239-5.046,4.762-7.626
                c0.723-1.224,1.963-2.324,1.455-4.114c-1.566-0.547-3.849,0.666-4.825-1.964c0.819-3.369,3.265-6.001,5.636-8.452
                c2.678-2.768,2.231-6.886,4.653-9.719c2.069,0.318,3.196,1.551,3.772,3.141c1.867,5.161,3.621,10.364,5.355,15.572
                c0.192,0.575,0.219,1.436-0.082,1.906c-1.348,2.111-0.372,4.188-0.149,6.297c0.354,3.354,1.942,6.893-1.337,9.792
                c-0.283,0.251-0.305,0.969-0.229,1.435c0.85,5.22,1.925,10.366,5.034,14.831c3.859-2.225,4.877-4.208,2.757-5.985
                c-2.478-2.078-1.721-3.971-0.88-6.192c1.248-3.3,3.021-6.315,5.121-9.122c1.324-1.771,1.143-4.602,3.684-5.547
                c2.995,3.139,9.816,18.396,9.818,21.789c-0.58,0.934-2.038-0.646-2.474,1.135c1.31,3.044,3.058,6.092,5.409,8.674
                c2.357,2.589,3.436,5.132,1.859,8.564c-0.852,1.855-0.893,4.023,0.504,5.713c3.363,4.066,6.863,8.019,10.56,12.309
                c2.612-3.373,2.971-6.657,2.089-10.185c-0.245-0.979-0.759-1.764-1.66-2.38c-4.072-2.785-4.161-2.858-2.25-7.284
                c1.449-3.358,3.185-6.596,4.852-9.856c1.895-3.705,3.896-7.357,5.767-11.075c0.817-1.626,1.843-3.481,0.447-5.157
                c-2.173-2.608-1.009-4.898,0.128-7.383c1.52-3.323,3.054-6.688,2.195-10.414c-1.098-1.165-2.836,0.188-3.951-1.689
                c-0.24-2.902,1.456-5.652,2.898-8.404c1.715-3.271,3.225-6.494,3.412-10.317c0.088-1.8,0.276-4.034,2.654-5.151
                c1.787,1.404,1.961,3.4,2.196,5.34c1.173,9.664,2.982,19.1,9.06,27.137c0.9,1.189,1.543,2.603,0.595,4.229
                c-0.4,0.193-0.821,0.465-1.282,0.608c-3.857,1.2-4.39,1.901-3.527,5.547c0.417,1.765,1.068,3.521,1.906,5.128
                c2.23,4.281,4.662,8.457,6.928,12.721c0.904,1.701,2.273,3.316,1.816,5.311c-1.584,1.807-4.014,0.146-5.629,1.586
                c2.012,4.711,7.709,6.689,8.334,11.768c-1.628,2.336-4.241,0.177-5.658,2.072c0.541,3.025,2.336,5.512,3.43,8.281
                c0.662,1.68,1.848,2.238,3.541,1.977c1.662-0.258,2.072-1.599,2.262-2.906c0.285-1.973,0.402-3.977,0.49-5.971
                c0.2-4.512,0.342-8.889,4.1-12.378c1.794-1.666,2.02-4.494,2.539-6.946c0.654-3.094,1.252-6.203,2.055-9.26
                c0.373-1.423,0.995-2.852,1.812-4.072c1.647-2.462,3.461-2.402,4.943,0.201c0.896,1.571,1.49,3.343,2.037,5.082
                c1.098,3.492,2.015,7.043,3.119,10.533c0.537,1.696,0.904,3.561,2.623,4.607c2.102-0.396,2.831-2.182,3.949-3.602
                c3.696-4.692,6.635-4.433,9.349,0.763c0.386,0.737,0.68,1.522,1.07,2.257c0.22,0.414,0.552,0.77,0.872,1.206
                c1.174,0.041,2.032-1.483,3.908-0.194c1.781,1.412,1.536,4.957,3.693,6.996c4.949-3.345,7.489-7.861,8.689-13.268
                c0.54-2.437,1.085-4.887,1.862-7.253c1.47-4.47,4.56-4.995,7.718-1.442c1.062,1.195,1.387,3.117,3.392,3.475
                c2.404-0.558,2.86-2.517,3.109-4.539c0.468-3.797,0.727-7.622,1.321-11.396c0.373-2.361,1.381-4.564,4.123-5.075
                c2.588-0.481,3.908,1.326,5.199,3.132c1.154,1.615,2.188,3.92,4.289,3.869c3.74-0.091,5.279,2.083,6.394,5.331
                c4.128-2.718,5.651,1.304,7.923,2.778c3.19-0.668,3.489-2.965,3.856-5.355c0.397-2.589,0.151-5.646,2.652-7.272
                c2.765-1.798,3.942-4.337,4.779-7.29c0.671-2.366,2.062-4.151,4.648-4.687c2.411,1.111,2.595,3.363,2.778,5.493
                c0.672,7.799,1.193,15.609,1.888,23.406c0.284,3.185,0.36,6.502,2.38,9.1c1.187-0.169,1.56-0.73,1.809-1.35
                c1.134-2.815,1.429-5.802,1.736-8.782c0.41-3.974,0.742-7.956,1.842-11.824c0.518-1.821,1.147-3.724,3.149-4.161
                c2.961-0.646,3.882-2.919,4.806-5.242c1.167-2.937,2.09-5.975,3.348-8.871c0.867-1.998,2.141-4.037,4.683-3.889
                c2.413,0.14,2.948,2.387,3.69,4.232c0.979,2.436,1.186,5.195,2.891,7.256c5.064,0.292,5.066,0.291,7.394,5.184
                c0.847,1.779,1.364,4.013,3.91,3.949c3.474-0.087,4.076,2.201,4.395,4.858c0.228,1.903,0.092,3.97,1.981,5.181
                c2.246-0.902,2.597-2.756,2.93-4.537c0.427-2.29,0.708-4.607,1.043-6.914c0.382-2.636,0.729-5.28,1.829-7.744
                c0.729-1.633,1.839-2.733,3.774-2.773c1.904-0.039,3.145,0.947,4,2.501c0.479,0.872,0.871,1.802,1.199,2.742
                c0.677,1.938,1.864,3.144,4.047,2.962c1.344-0.112,2.383,1.205,3.762,0.785c2.344-4.812,2.431-5.066-1.137-8.537
                c-2.094-2.036-1.6-3.838-0.151-5.705c2.658-3.429,5.284-6.877,7.245-10.761c0.857-1.7,2.091-3.358,1.684-5.368
                c-2.109-1.049-4.575,0.557-6.2-1.266c-0.276-2.237,1.39-3.123,2.476-4.27c2.547-2.689,4.811-5.572,6.105-9.054
                c0.59-1.587,1.803-3.256,0.774-5.014c-2.111-0.941-4.16,1.14-6.431-0.854c0.646-4.721,5.058-7.715,6.922-12.428
                c-3.16-1.468-1.707-4.918-3.018-7.371c-1.654-0.664-3.225-0.479-4.75,0.284c-0.312,1.178-0.538,2.325-0.924,3.416
                c-0.433,1.226-1.414,1.925-2.702,1.765c-1.27-0.158-2.062-1.09-2.318-2.316c-0.202-0.971-0.157-1.988-0.292-2.977
                c-0.123-0.909,0.115-2.153-1.579-1.954c-0.674,1.733-0.349,3.587-0.483,5.392c-0.141,1.859-0.277,3.993-2.508,4.361
                c-2.831,0.468-2.955-1.985-3.441-3.9c-0.072-0.285-0.385-0.509-0.587-0.76c-4.702-1.663-11.101-0.911-11.925-8.636
                c0.22,4.897-4.789,6.968-5.188,11.329c-0.117,1.283-1.21,1.954-2.544,1.913c-1.369-0.042-2.128-0.952-2.437-2.138
                c-0.247-0.952-0.275-1.979-0.283-2.973c-0.016-1.773-0.248-3.446-2.334-3.848c-1.397-0.27-1.219-2.402-2.754-2.309
                c-1.049,0.833-0.824,1.9-0.878,2.857c-0.094,1.663,0.024,3.346-0.173,4.993c-0.35,2.909-2.686,4.712-5.994,4.82
                c-2.184,0.072-3.064-1.004-3.662-4.443c-0.215-1.236,0.205-2.681-1.039-3.595c-1.316,0.176-0.957,1.205-1.114,1.908
                c-0.304,1.359-1.259,2.051-2.493,2.016c-1.516-0.045-2.373-1.101-2.572-2.582c-0.178-1.319-0.208-2.659-0.387-3.979
                c-0.158-1.172,0.078-2.546-1.318-3.408c-1.021,2.42-0.41,4.826-0.923,7.094c-0.716,3.168-1.618,3.709-4.933,2.734
                c-0.625-0.184-1.17-0.611-1.865-0.215c-0.166,0.242-0.461,0.488-0.51,0.776c-0.32,1.849-0.59,4.007-3.02,3.823
                c-2.623-0.196-2.639-2.625-2.861-4.583c-0.168-1.484-0.078-2.997-0.167-4.493c-0.047-0.796-0.011-1.701-1.44-2.107
                c-0.096,3.68-2.646,5.258-5.664,6.286c-2.188,0.747-3.391,0.011-4.166-2.202c-0.504-1.438-0.076-3.209-1.158-4.52
                c-1.62,0.703-2.326,1.923-3.219,2.766c-2.127,2.009-4.004,4.406-5.475,6.941c-0.842,1.451-1.008,3.371-3.164,3.117
                c-2.045-0.24-2.436-2.049-2.602-3.749c-0.146-1.489-0.088-2.997-0.172-4.493c-0.062-1.125,0.158-2.402-1.449-3.042
                c-1.312,2.145,0.404,5.773-3.557,6.775c-3.166,0.802-5.811,0.526-7.945-1.425c-1.109,0.119-1.574,0.549-2.113,0.959
                c-3.17,2.414-3.785,2.384-6.475-0.684c-1.654-1.888-4.152-2.39-5.998-4.088c-1.521-1.4-6.014-0.07-7.467,1.775
                c-1.861,2.366-4.854,2.582-7.201,4.027c-1.484,0.914-3.166-0.057-3.895-1.992c-0.498-1.318-0.238-3.044-2.155-4.053
                c-2.059,2.653-5.874,2.853-7.82,6.107c-1.149,1.922-3.712,1.238-4.839-0.84c-0.75-1.379-0.44-3.268-2.609-5.203
                c-0.991,5.649-4.051,7.863-9.109,6.979c-4.462-0.78-8.24-2.539-10.133-7.272c-1.9,1.733-1.809,3.666-2.379,5.34
                c-0.604,1.767-1.422,3.694-3.852,2.843c-3.823-1.339-5.317,0.681-6.774,3.791c-1.837,3.921-4.675,3.942-7.082,0.396
                c-0.876-1.289-1.034-3.343-3.292-3.137c-1.63,0.661-1.83,2.32-2.607,3.554c-2.152,3.41-4.756,3.786-6.986,0.458
                c-1.854-2.766-3.766-3.264-6.722-2.299c-2.868,0.936-5.218,0.001-6.734-2.768c-0.548-1-1.147-2.102-2.806-1.804
                c-2.012,1.148-1.868,3.632-2.898,5.453c-1.47,2.597-3.621,3.75-5.665,2.048c-2.71-2.256-6.829-1.912-8.831-5.301
                c-0.586-0.993-2.146-1.444-3.133-0.599c-3.119,2.675-6.861,2.153-10.465,2.302c-1.572,0.064-2.892,0.364-4.048,1.524
                c-2.265,2.272-4.79,2.134-7.2,0.309c-1.95-1.477-3.867-1.411-6.111-0.801c-4.674,1.272-5.344,0.765-6.512-3.983
                c-0.144-0.582-0.037-1.406-1.56-1.376c-1.323,3.762-5.87,5.01-7.681,8.849c-0.686,1.453-2.09,2.228-3.693,2.119
                c-1.958-0.133-2.049-1.79-2.406-3.274c-0.225-0.937-0.613-2.119-1.326-2.603c-2.531-1.713-4.613-3.87-6.902-6.394
                c-0.416,5.409-3.971,8.986-6.316,13.181c-1.641,2.933-3.743,2.986-5.526,0.149c-0.483-0.769-0.291-2.081-1.634-2.183
                c-0.313,0.183-0.807,0.312-0.835,0.51c-0.585,4.092-4.45,5.566-6.719,8.287c-1.408,1.69-3.58,1.574-5.351-0.041
                c-1.1-1.005-2.062-2.152-3.826-1.99c-1.63,0.15-2.214-1.097-2.82-2.355c-0.447-0.93-0.135-2.508-1.898-2.523
                c-1.191,1.131-0.982,3.227-2.695,4.031c-1.714,0.805-2.07,2.481-2.59,4.064c-1.83,5.572-2.903,6.09-8.121,3.459
                c-2.154-1.087-3.449-0.756-4.236,1.508c-0.109,0.314-0.207,0.633-0.317,0.947c-0.585,1.654-1.495,3.252-3.385,3.211
                c-2.33-0.051-2.641-2.199-3.1-3.95c-0.412-1.573-0.499-3.274-2.071-4.374c-3.627,2.962-6.869,6.247-11.146,8.267
                c-3.825,1.808-5.138,1.528-6.71-2.522c-0.572-1.476-0.631-3.229-2.546-4.303c-1.306,1.934-0.838,4.041-1.46,5.836
                c-0.412,1.189-0.975,2.129-2.304,2.318c-1.326,0.189-2.287-0.508-2.874-1.576c-0.555-1.009-0.824-2.17-1.32-3.216
                c-0.541-1.139-0.455-2.729-2.105-3.128c-1.165,0.406-1.275,1.416-1.367,2.404c-0.123,1.33-0.004,2.953-1.322,3.581
                c-2.067,0.985-3.91,2.509-6.234,2.894c-1.038,0.172-2.377-0.185-2.635-1.052c-1.066-3.603-4.584-4.403-7.138-5.497
                c-3.471-1.486-5.09-3.803-6.311-6.924c-0.076-0.195-0.578-0.225-1.142-0.423c-1.722,1.706-2.479,4.563-5.544,5.259
                c0,2.018,0.082,3.845-0.023,5.662c-0.105,1.832-0.209,3.998-2.48,4.361c-2.538,0.404-3.346-1.785-4.017-3.671
                c-0.692-1.946-1.151-3.976-1.863-6.507c-1.866,2.186-2.888,4.484-5.482,4.846c-0.926,0.129-1.261,0.979-1.749,1.648
                c-2.982,4.098-5.195,3.877-7.267-0.762c-0.677-1.516-1.147-3.123-1.739-4.679c-0.473-1.244-0.868-2.705-2.292-3.084
                c-2.605-0.692-3.012-3.295-4.485-5.148c-1.009,2.204-0.948,5.021-2.945,5.919c-3.098,1.393-4.105,3.9-5.392,6.617
                c-1.49,3.146-3.992,3.126-6.175,0.279c-0.301-0.393-0.496-0.866-0.732-1.307c-1.086-2.03-2.304-1.949-3.945-0.492
                c-4.516,4.013-9.598,6.156-15.771,4.412c-3.34-0.944-6.612-2.13-9.353-4.233c-2.057-1.579-3.501-1.71-4.848,0.599
                c-0.912,1.564-2.229,2.81-4.033,2.303c-3.827-1.076-8.579-0.982-9.477-6.471c-0.134-0.815-0.708-1.494-2.058-1.561
                c-1.536,0.592-1.667,2.453-2.391,3.855c-0.74,1.431-1.78,2.832-3.389,2.432c-3.581-0.891-7.59-0.928-9.83-4.866
                c-1.934-3.4-2.929-3.293-5.147,0.003c-1.356,2.015-2.25,5.042-5.369,4.324c-2.877-0.662-2.933-3.715-3.613-6.076
                c-0.621-2.154-0.556-4.613-2.57-6.209c-2.488,0.936-2.803,3.335-3.95,5.084c-2.173,3.312-4.621,3.848-7.3,1.098
                c-1.592-1.634-3.42-2.391-5.39-3.296c-4.786-2.201-9.768-4.382-10.262-10.823c-0.074-0.97-0.535-2.357-2.161-2.32
                c-1.335,0.939-1.538,2.523-1.979,3.911c-0.908,2.854-1.539,5.796-2.466,8.644c-0.604,1.855-1.098,4.139-3.508,4.402
                c-2.46,0.27-3.307-1.85-4.22-3.631c-0.678-1.321-1.045-2.935-2.611-3.497c-2.865-1.029-3.784-3.536-4.892-5.972
                c-0.892-1.963-1.087-4.748-2.872-5.705c-3.992-2.141-4.004-5.724-4.502-9.307c-0.127-0.912,0.073-2.011-1.272-2.801
                c-1.516,1.836-1.649,4.145-2.17,6.314c-0.697,2.908-0.926,6.405-3.584,8.006c-3.231,1.947-4.928,4.675-6.606,7.792
                c-1.868,3.471-4.595,3.903-7.218,0.943c-2.282-2.575-4.547-3.083-7.504-1.868c-2.981,1.225-5.352,0.35-7.752-1.695
                c-3.545-3.019-7.815-5.299-9.301-10.182c-0.029-0.096-0.288-0.121-0.442-0.186c-0.286,0.162-0.667,0.253-0.844,0.494
                c-2.172,2.975-2.326,7.402-6.61,8.929c-0.536,0.19-0.664,1.788-0.807,2.759c-0.193,1.315-0.157,2.664-0.332,3.982
                c-0.201,1.517-0.573,3.168-2.319,3.471c-1.969,0.342-2.991-1.053-3.62-2.744c-0.567-1.525-0.529-3.368-2.15-4.354
                c-2.292-1.395-2.63-3.704-2.891-6.049c-0.331-2.977-0.64-5.957-0.938-8.938c-0.186-1.858-0.279-3.739-1.467-5.405
                c-1.086,0.932-1.207,1.939-1.294,2.932c-0.117,1.327-0.291,2.668-0.218,3.99c0.424,7.64-6.287,12.131-8.295,18.744
                c-0.551,1.814-2.899,1.307-3.688-0.742c-0.848-2.203-0.89-4.559-1.018-6.893c-0.151-2.757-0.075-5.582-1.565-8.137
                c-4.662,2.988-6.412,2.251-7.579-3.009c-0.324-1.463-0.521-2.954-0.818-4.423c-0.221-1.094-0.231-2.348-2.046-2.828
                c-1.703,1.965-2.077,4.67-3.179,7.01c-0.881,1.871-1.368,4.535-4.292,3.99c-2.595-0.483-2.247-2.927-2.554-4.848
                c-0.051-0.314-0.262-0.604-0.346-0.789c-1.603-1.092-3.182-2.036-4.605-3.174c-1.59-1.271-3.349-1.292-5.49-1.1
                c-0.441,3.646-2.996,4.137-6.156,3.894c-2.31-0.177-4.644-0.034-6.934-0.034c-1.087,2.543-1.087,2.543-7.357,4.949
                c0.283,4.061,8.457,1.861,6.259,7.912c2.166,0.865,4.372,1.699,6.758,1.385c5.533-0.729,10.217,2.414,15.389,3.354
                c1.421,0.259,3.233,1.029,3.8,3.556c-4.177,1.939-8.514,3.053-13.249,2.318c-6.473-1.004-10.625-0.867-14.014,0.486
                c-0.089,1.877,2.557,1.541,2.638,3.26c-0.322,2.42-2.796,1.979-4.32,3.176c0.808,1.016,0.466,2.582,2.061,3.134
                c2.045,0.708,4.077,1.413,5.062,3.69c0.334,0.772,1.405,0.929,2.214,0.904c6.139-0.191,11.548,3.006,17.496,3.725
                c2.106,0.255,4.239,0.805,5.774,2.829c-1.439,1.743-2.237,3.578-0.696,5.623c4.617,1.921,10.196-0.903,14.54,2.349
                c-0.287,1.471-0.865,2.055-1.698,2.293c-2.868,0.82-6.501-0.141-8.13,3.585c-0.106,0.242-0.947,0.297-1.415,0.228
                c-4.948-0.738-10.303-1.194-14.372-3.799c-4.826-3.089-9.531-3.169-14.652-2.65c-0.433,0.044-0.826,0.485-1.2,0.719
                c0.036,1.188,1.88,0.99,1.573,2.3c0.014,1.676-2.301,1.205-2.543,3.139c5.644,1.554,12.241-0.61,17.346,3.361
                c-0.36,1.136-0.928,1.462-1.484,1.84c-0.518,0.352-1.719-0.097-1.531,1.185c6.304,1.268,13.216-0.922,19.044,2.994
                c-0.99,2.963-3.931,2.368-5.297,4.182c9.58,2.506,12.905,1.765,21.945-7.562c-0.845-1.218-3.06-0.434-3.585-3.201
                c-0.028-0.183,0.431-1.054,1.037-1.874c2.681-3.623,4.86-7.561,7.202-11.398c1.154-1.892,1.997-4.802,4.856-4.271
                c2.583,0.479,2.488,3.369,2.844,5.371c1.105,6.217,3.492,11.784,7.474,16.68c0.523,0.645,1.02,1.336,1.38,2.078
                c0.632,1.299,1.928,2.609,0.864,4.13c-1.132,1.617-2.727,0.435-4.137,0.336c-0.256-0.018-0.534,0.28-0.966,0.525
                c2.214,8.804,7.032,16.282,12.605,23.137c-2.563,3.588-5.878-0.419-8.701,1.716c3.136,2.665,6.395,4.646,10.091,5.641
                c5.259,1.415,9.426,4.014,11.952,9.01c0.887,1.755,2.234,2.861,4.467,1.712c2.891-1.489,4.981-4.468,3.793-6.46
                c-1.33-2.229-0.351-3.33,1.103-4.643c0.934-0.843,2.405-1.129,2.765-2.583c-3-2.177-3.108-2.461-1.239-4.931
                c0.9-1.189,2.033-2.201,3.02-3.328c1.941-2.217,4.424-4.005,5.668-6.728c-3.952-4.491-3.942-5.327-0.03-9.685
                c0.445-0.496,0.922-0.963,1.377-1.451c1.273-1.367,2.384-2.62,1.323-4.814c-0.804-1.664,0.521-3.196,1.427-4.557
                c1.853-2.781,4.081-5.275,6.503-7.568c4.154-3.93,6.084-3.434,7.37,2.045c0.886,3.775,2.215,7.32,4.198,10.631
                c1.456,2.43,2.845,4.909,4.467,7.225c1.603,2.29,1.232,4.229-0.684,5.811c2.147,4.123,6.059,6.718,6.931,10.717
                c-0.247,1.782-1.958,1.761-2.211,2.94c1.958,4.472,7.057,7.131,7.447,12.458c-3.292,2.121-7.057-0.673-9.953,1.934
                c0.154,2.637,1.853,4.289,3.443,5.932c1.98,2.046,3.784,4.224,5.292,6.625c0.957,1.522,2.27,3.158,1.115,5.053
                c-1.169,1.918-3.035,0.467-4.582,0.618c-0.224,0.022-0.416,0.368-0.804,0.734c2.114,3.588,4.917,6.524,8.378,8.774
                c3.865-1.593-0.202-3.592,0.403-5.164c0.827-1.517,2.188-2.235,3.393-3.104c2.97-2.142,4.602-5.211,5.965-8.464
                c0.52-1.24,0.988-2.594-0.295-3.676c-2.624-2.215-1.133-4.162,0.436-5.974c1.962-2.264,4.158-4.325,6.111-6.598
                c1.468-1.708,3.173-3.341,3.646-5.856c-2.745-0.787-4.649,2.223-7.169,1.279c-1.011-1.899,0.465-2.922,1.304-4.006
                c4.529-5.859,7.839-12.357,10.412-19.268c0.58-1.559,1.248-3.09,1.973-4.586c0.666-1.376,1.519-2.75,3.303-2.599
                c1.844,0.157,2.379,1.706,2.598,3.212c0.498,3.432,2.19,6.131,4.396,8.76c2.364,2.818,3.748,6.279,4.66,9.865
                c0.286,1.123,0.373,2.271-0.104,3.428c-0.512,1.24-2.342,0.212-2.773,1.937c1.072,1.868,2.485,3.68,4.311,5.157
                c4.255,3.442,8.789,2.536,11.041-2.394c1.559-3.413,2.761-6.987,4.136-10.527c-0.517-0.091-0.84-0.187-1.167-0.198
                c-5.129-0.186-5.643-1.113-3.006-5.439c0.768-1.261,2.338-2.5,1.775-3.963c-1.292-3.359,0.674-5.451,2.498-7.557
                c3.623-4.182,6.771-8.646,9.145-13.657c0.55-1.162,1.03-2.542,3.564-2.378c3.778,7.768,6.736,16.291,10.48,23.809
                c-1.545,2.939-3.982,0.891-5.573,2.596c-0.213,2.31,0.528,4.654,1.337,7.004c1.092,3.17,2.815,6.028,4.318,8.997
                c0.674,1.331,1.538,2.759,0.656,4.245c-1.074,1.811-2.686,0.404-4.062,0.462c-0.261,0.011-0.51,0.328-0.796,0.526
                c0.04,1.966,0.602,3.705,2.124,5.461c3.252-4.072,6.524-7.555,7.474-12.362c-4.831-2.816-5.175-3.497-2.039-7.306
                c3.785-4.599,6.454-9.644,8.026-15.351c0.348-1.264,1.012-2.469,1.69-3.604c0.609-1.018,1.459-1.857,3.525-1.123
                c1.254,4.892,4.075,9.582,6.168,14.604c0.366,0.879,0.623,2.101,0.3,2.913c-0.788,1.979,0.347,3.118,1.347,4.453
                c0.78,1.042,1.691,2.015,1.655,3.52c-0.032,1.333-2.283,1.048-1.785,2.533c1.996,4.621,5.974,8.148,6.669,13.184
                c-3.708,3.521-3.739,3.725-1.349,8.002c4.559-2.406,8.326-7.812,7.088-10.238c-1.102-0.887-3.146,0.165-3.938-2.174
                c0.888-4.652,5.76-7.157,7.835-11.679c-3.036-0.933-2.675-2.731-1.211-4.62c2.448-3.159,5-6.237,7.473-9.378
                c0.275-0.35,0.318-0.883,0.495-1.409c-0.855-1.071-2.803-0.501-3.2-2.356c-0.165-1.104,0.684-1.979,1.401-2.872
                c3.215-4.005,7.676-6.861,10.203-11.563c1.045-1.944,3.044-1.592,4.396,0.466c1.918,2.919,3.591,6,5.545,8.895
                c1.454,2.153,0.918,3.952-0.599,5.462c1.529,3.358,4.379,6.089,1.842,9.603c4.946,4.508,7.578,4.4,11.388-0.317
                c1.554-1.924,3.065-3.942,2.898-6.667C626.24,845.174,625.375,844.751,624.265,844.209z M526.681,147.406
                c-1.709,1.394-3.502,2.451-4.762,3.956c-4.436,5.299-10.209,8.72-15.82,12.578c-8.003,5.504-16.379,10.15-24.984,14.547
                c-8.153,4.166-16.145,8.661-24.094,13.209c-3.509,2.008-7.057,3.542-11.058,4.354c-9.431,1.912-18.341,5.024-24.856,12.73
                c-0.416,0.491-1.023,0.857-1.604,1.166c-11.874,6.309-22.298,15.312-35.519,19.126c-0.444,0.128-0.8,0.558-1.495,1.064
                c0.136,2.639,0.532,5.454,0.367,8.236c-0.287,4.811,1.464,8.74,4.694,12.628c4.583-0.705,9.328-1.456,14.079-2.161
                c13.894-2.062,27.525-5.104,40.195-11.4c6.879-3.419,13.938-6.329,21.216-8.755c4.602-1.534,9.067-3.386,12.715-6.729
                c1.577-1.445,3.535-1.709,5.514-2.073c4.578-0.841,9.314-1.114,13.625-3.015c9.138-4.027,18.198-8.229,27.311-12.316
                c3.392-1.521,5.865-4.382,9.114-6.089c6.781-3.562,13.577-7.097,20.363-10.649c2.517-1.318,4.936-2.781,6.939-4.838
                c4.663-4.786,10.545-7.969,15.88-11.853c3.325-2.421,7.398-3.8,10.613-6.514c1.358-1.146,2.976-1.33,4.674-1.426
                c5.266-0.298,10.12-2.045,14.614-4.682c3.447-2.022,6.873-4.038,10.51-5.726c3.357-1.558,6.138-3.967,8.422-6.923
                c1.465-1.896,2.746-3.774,1.779-6.368c-0.417-1.119-0.589-2.71,0.391-3.285c3.481-2.039,5.096-6.155,8.935-7.737
                c3.977-1.639,6.917-4.537,9.472-7.913c3.905-5.161,4.494-8.982,0.914-14.176c-2.39-3.467-2.892-6.925-2.531-10.836
                c0.344-3.733,0.181-3.949-3.207-4.963c-1.753-0.525-3.533-1.219-5.326-1.304c-6.67-0.317-11.34-4.399-16.128-8.27
                c-1.55-1.252-2.9-2.75-4.35-4.127c-3.484-3.31-7.459-5.603-12.387-5.811c-2.664-0.112-4.726-1.381-6.635-3.096
                c-3.147-2.826-4.951-6.181-3.91-10.373c-4.804-2.26-9.948-2.364-13.856-5.401c-6.265-4.868-13.316-8.272-20.696-10.894
                c-4.257-1.513-6.129-3.845-6.141-8.386c-0.01-3.901-2.056-5.575-5.986-5.955c-1.157-0.112-2.336-0.12-3.497-0.047
                c-6.77,0.424-13.26-0.827-19.605-3.102c-2.663-0.955-5.324-2.035-8.081-2.576c-4.257-0.835-7.826-2.705-10.828-5.779
                c-2.534-2.596-5.571-4.148-9.222-4.519c-3.774-0.384-7.117-1.964-9.829-4.518c-5.548-5.224-12.177-7.026-19.607-7.374
                c-17.824-0.834-35.652-0.437-53.476-0.487c-38.665-0.109-77.33-0.033-115.995-0.034c-41.498,0-82.996-0.007-124.494,0.016
                c-1.675,0.001-3.471-0.381-5.302,0.573c1.804,1.747,4.635,1.366,5.137,3.911c0.387,1.279-1.828,1.466-1.147,2.933
                c5.309,2.262,10.954,2.932,16.518,3.862c7.062,1.181,14.163,2.118,21.234,3.243c1.589,0.253,4.202,0.139,4.374,1.654
                c0.335,2.946,2.24,2.802,4.081,3.086c2.961,0.457,5.935,0.851,8.915,1.162c5.465,0.57,11.123,0.356,16.371,1.728
                c11.014,2.878,22.221,4.238,33.437,5.775c15.836,2.169,31.624,4.661,47.479,6.721c13.532,1.758,27.133,3.134,40.534,5.851
                c6.051,1.227,12.17,1.996,18.351,2.077c13.395,0.176,26.474,2.657,39.408,5.775c6.768,1.631,13.27,4.346,19.931,6.447
                c2.404,0.758,4.642,1.733,6.803,3.036c7.076,4.266,14.625,7.179,22.979,7.705c4.82,0.304,9.643,0.638,14.446,1.14
                c2.215,0.231,4.677-0.042,6.414,1.72c0.11,3.084-4.091,2.527-4.032,5.907c6.53,1.674,12.721,4.849,19.31,6.959
                c3.957,1.268,8.061,2.066,12.064,3.198c1.476,0.417,3.384,0.47,3.746,2.679c-1.106,2.021-3.986,0.822-5.029,2.85
                c0.01,1.986,1.302,3.372,2.475,4.741c2.833,3.31,6.059,6.189,9.882,8.342c1.881,1.06,3.819,2.055,4.832,4.438
                c-2.8,5.876-0.073,10.784,3.441,15.485c-1.667,3.266-2.883,6.533-1.928,10.163c0.416,1.58-0.546,2.467-1.492,3.435
                C528.898,138.586,526.821,142.652,526.681,147.406z M25.021,507.727c-0.656,4.372-4.3,6.01-5.422,9.465
                c-0.583,1.797-2.768,1.519-3.9-0.365c-1.233-2.053-1.319-4.439-1.596-6.745c-0.268-2.231-0.132-4.554-1.451-6.585
                c-2.947,1.805-4.175,4.981-6.418,6.855c-2.209,1.846-2.512,3.597-2.507,5.947c0.016,8.998,0.006,17.997,0.006,26.995
                c0,4.312,0,8.625,0,13.354c4.235-0.43,4.956,2.422,5.321,5.307c0.459,3.631,0.635,7.3,0.889,10.955
                c0.157,2.253,1.073,3.779,3.463,4.197c1.285,0.224,2.465,1.076,3.949,0.584c1.357-1.158,0.618-3.633,2.589-4.509
                c1.169-0.54,2.105,1.116,3.589,0.16c0.321-0.602,0.802-1.466,1.248-2.347c0.685-1.352,1.366-2.976,3.159-2.682
                c2.085,0.34,2.849,2.051,3.032,4.044c0.205,2.22-0.762,4.253-0.96,6.394c-0.137,1.482-1.416,3.252,0.875,4.539
                c1.795-0.847,1.783-3.967,4.011-3.981c2.667-0.019,4.139-1.296,5.715-3.214c1.827-2.223,4.252-1.628,5.49,1
                c0.729,1.546,0.832,3.216,0.841,4.888c0.019,3.499-0.004,6.998,0.012,10.497c0.015,3.168,0.01,6.352,1.133,9.373
                c0.438,1.18,0.835,2.564,2.606,2.533c0.945-0.854,1.061-2.027,1.291-3.167c0.516-2.549,1.694-4.848,3.883-6.19
                c2.751-1.688,3.691-4.11,3.927-7.068c0.079-0.994,0.146-2.006,0.387-2.969c0.339-1.363,1.156-2.508,2.601-2.727
                c1.409-0.213,2.692,0.481,3.384,1.689c0.734,1.281,1.208,2.725,1.688,4.135c0.674,1.978,1.811,2.979,4.068,2.779
                c5.609-0.496,6.874,0.55,6.963,6.031c0.05,3.076,0.478,5.89,2.194,8.539c1.368,2.111,1.874,4.582,2.147,7.107
                c0.344,3.17,0.162,6.32,0.012,9.483c-0.092,1.955-0.042,1.958,1.585,4.346c-0.128-3.852,0.875-7.044,3.849-9.056
                c2.338-1.582,4-3.365,4.543-6.213c0.29-1.52,1.191-2.855,3.002-2.851c3.898,0.011,8.035-0.562,10.321,3.876
                c0.298,0.578,1.128,0.633,1.822,0.565c0.938-0.532,1.253-1.501,1.706-2.388c0.726-1.42,1.756-2.531,3.502-2.272
                c1.814,0.269,2.968,1.42,3.423,3.204c0.288,1.129,0.536,2.27,0.757,3.413c0.278,1.448,0.853,2.536,2.588,2.398
                c0.258-0.206,0.559-0.374,0.768-0.621c3.502-4.142,5.704-3.868,7.576,1.04c0.368,0.964,0.569,1.9,1.658,2.273
                c2.685,0.921,3.002,3.336,3.496,5.609c0.37,1.707,0.105,3.728,2.192,4.459c2.933-0.909,1.941-4.773,4.629-5.592
                c2.518-0.09,3.314,3.777,6.888,2.406c-2.914-0.822-1.82-3.211-2.331-4.657c-1.898-0.665-4.163,0.46-4.949-2.239
                c3.597-3.57,9.739-1.48,13.154-5.35c-1.08-2.143-3.541-1.121-4.406-3.118c-0.299-0.862,0.979-1.856-0.406-3.245
                c-2.618-2.626-1.757-4.521,1.936-5.238c0.956-0.186,2.023,0.092,2.787-0.775c1.784-3.059,1.155-7.389,4.833-9.818
                c1.971,3.084,0.132,6.32,1.35,8.843c1.282,1.753,4.226,1.132,4.456,3.445c-0.028,1.085-0.508,1.512-1.027,1.957
                c-0.539,0.462-1.729,0.41-1.377,1.671c1.31,1.554,3.273,2.197,4.907,3.322c2.288,1.575,2.291,1.57,1.523,4.779
                c2.871,1.301,5.459,2.893,6.313,6.148c-1.223,1.245-2.553,1.305-3.896,1.385c-1.193,0.069-2.459-0.145-4.089,0.863
                c2.519,0.414,4.346,1.132,5.942,2.549c0.978-0.922,1.732-1.801,2.646-2.461c2.426-1.752,3.777-1.303,4.781,1.591
                c0.377,1.089,0.255,2.481,1.26,3.184c4.03,2.815,4.386,7.194,5.198,11.454c0.402,2.11,0.542,4.48,2.736,6.242
                c1.403-2.082,1.748-4.029,2.154-5.953c0.997-4.721,1.912-9.462,3.039-14.152c0.416-1.732,0.985-3.827,3.156-4.074
                c2.439-0.278,3.403,1.837,4.254,3.604c1.153,2.395,1.966,4.949,3.06,7.375c0.79,1.752,1.263,3.869,3.25,4.297
                c2.311-0.281,1.81-3.137,4.208-3.232c3.192,1.033,4.249,3.938,5.212,7.045c0.637,2.053,0.754,4.578,3.322,5.587
                c1.086-1.644,1.251-3.31,1.58-4.94c0.263-1.301,0.549-2.634,1.09-3.833c1.09-2.417,2.946-2.569,4.603-0.519
                c1.12,1.387,1.336,3.027,1.328,4.712c-0.012,2.319,0.624,4.321,2.771,5.485c3.596,1.951,5.018,4.633,2.872,8.309
                c1.916,2.33,3.679,4.314,4.741,6.824c1.108,2.616,0.345,5.348,0.677,8.239c2.01-2.963,4.616-4.889,7.532-6.462
                c1.16-0.626,1.401-1.776,1.396-3.004c-0.011-2.768,1.158-4.803,3.74-5.941c2.337-1.032,3.485-2.953,4.111-5.328
                c0.294-1.115,0.614-2.293,1.235-3.236c1.182-1.793,3.772-1.923,4.455-0.018c1.899,5.3,7.465,6.174,11.044,9.398
                c2.341,2.107,4.474,3.648,4.816,6.896c0.085,0.815,0.304,1.668,0.68,2.389c0.666,1.277,1.926,1.926,3.282,1.939
                c1.307,0.013,1.994-1.074,2.415-2.219c0.402-1.093,0.722-2.216,1.104-3.316c0.509-1.467,1.177-2.879,2.991-2.9
                c1.627-0.021,3.049,0.828,3.58,2.299c1.24,3.438,3.712,5.851,6.191,8.332c1.719,1.719,3.07,3.908,1.91,6.288
                c-1.376,2.82-0.503,4.577,1.511,6.562c3.063,3.02,5.117,6.533,4.027,11.182c-0.417,1.779,0.517,2.83,2.423,2.869
                c1.592-1.423,1.191-3.398,1.467-5.154c0.709-4.516,1.693-9.029,5.448-11.986c3.268-2.574,4.942-5.708,5.357-9.715
                c0.084-0.814,0.266-1.668,0.626-2.393c0.852-1.713,2.678-2.002,4.157-0.768c1.105,0.922,1.338,2.264,1.716,3.533
                c0.619,2.079,1.123,4.255,2.729,5.817c3.809,3.707,5.356,7.899,3.406,13.112c-0.727,1.943,0.156,3.12,2.39,3.498
                c0.129-3.504-0.166-7.047,2.098-9.746c2.678-3.195,5.293-6.265,6.415-10.473c0.556-2.084,2.754-2.312,4.466-0.885
                c1.341,1.117,1.94,2.648,2.244,4.342c0.294,1.639,0.552,3.304,1.079,4.873c0.591,1.764,1.942,3.109,3.758,1.84
                c1.602-1.12,3.579-0.468,4.965-1.441c1.237-2.707,0.735-5.459,1.237-8.057c0.312-1.611,0.636-3.622,2.661-3.743
                c2.153-0.13,3.139,1.563,3.506,3.53c0.183,0.98,0.292,1.976,0.405,2.969c0.135,1.18,0.263,2.442,1.46,3
                c2.588,1.203,2.534,3.662,2.782,5.777c5.262,2.905,9.404,10.129,9.452,16.135c0.018,2.271-0.698,4.678,1.052,6.715
                c4.98-10.985,12.034-20.852,16.016-22.404c2.667,1.404,1.673,4.641,3.179,6.854c1.493-1.495,1.149-3.326,1.712-4.837
                c0.616-1.652,0.395-3.742,1.765-4.395c1.364-0.57,1.922-0.152,2.484,0.189c0.574,0.35,1.125,0.705,1.809,0.225
                c0.714-1.092,1.295-2.338,2.194-3.279c1.996-2.092,4.519-1.488,5.338,1.262c0.375,1.259,0.438,2.624,0.537,3.949
                c0.168,2.246-0.206,4.577,0.688,6.445c0.961,0.382,1.349,0.113,1.796-0.123c3.3-1.74,4.097-1.371,5.213,2.184
                c0.372,1.184,0.139,2.627,1.419,3.585c1.64-1.47,1.51-3.68,2.405-5.415c0.536-1.037,1.204-2.824,2.112-2.494
                c2.889,1.053,4.036-1.801,6.153-2.266c1.348-0.293,1.423,1.918,3.17,1.359c0.333-4.827,5.581-7.497,5.828-12.65
                c0.045-0.945,1.075-1.703,2.196-1.727c1.337-0.029,2.29,0.692,2.477,1.998c0.45,3.126,2.81,4.932,4.577,7.586
                c1.712-2.563,2.113-5.991,5.46-5.769c1.79,0.604,1.353,2.583,3.18,3.214c1.001-1.465,1.545-3.725,3.899-3.811
                c2.605-0.094,2.714,3.065,4.83,3.988c2.03-2.416,2.616-5.168,3.312-8.084c-2.724,1.535-6.084-0.727-8.52,2.213
                c-1.166,1.408-3.17,0.209-3.678-1.166c-1.359-3.687-5.167-5.795-5.497-9.92c-1.601,0.553-1.731,1.676-2.211,2.502
                c-0.917,1.582-1.582,3.613-3.739,3.6c-2.1-0.014-2.966-1.811-3.736-3.551c-0.426-0.965-0.526-2.367-2.202-2.336
                c-0.834,3.828-5.304,4.652-6.19,8.108c-4.065-0.349-2.758-4.55-5.02-5.91c-4.984,1.975-6.207,1.27-6.642-3.956
                c-0.234-2.82-0.187-5.665-0.43-8.484c-0.129-1.498,0.213-3.227-1.348-4.807c-2.702,5.543-6.887,9.379-11.293,14.445
                c-4.532-5.828-7.588-12.146-10.874-19.019c-1.621,2.99-0.05,6.509-3.706,8.243c-4.128-5.572-8.056-11.198-10.101-17.939
                c-3.54,3.412-5.877,6.774-5.388,11.346c-2.242,0.592-4.145,0.304-5.703,1.505c-2.204,1.698-4.056,0.702-5.568-1.22
                c-2.085-2.652-4.138-5.33-5.07-9.165c-3.678,3.891-2.584,9.921-7.615,12.649c-4.611-4.578-2.886-11.328-5.916-16.057
                c-4.189,0.655-6.213,4.098-9.058,7.298c-4.865-6.964-2.504-14.857-5.044-21.636c-2.015,1.436-0.762,4.787-2.972,5.518
                c-2.576,0.854-2.446,2.757-2.981,4.637c-0.596,2.097-0.298,4.764-3.585,6.076c-4.132-5.648-0.916-12.571-3.237-19.379
                c-0.862,3.58-4.559,3.164-5.075,6.111c-0.23,1.314-1.164,2.498-2.788,2.348c-1.793-0.167-2.745-1.629-2.793-3.082
                c-0.131-3.959-2.585-6.51-5.231-9.811c-0.086,3.438-0.699,5.795-3.518,7.66c-2.05,1.355-0.768,5.584-4.001,7.047
                c-2.789-1.15-2.411-3.682-2.859-5.884c-0.471-2.313-0.059-4.851-1.456-7.489c-1.75,2.052-2.461,4.293-5.407,4.426
                c-2.092,0.094-1.546,4.478-4.825,4.289c-1.799-1.543-1.812-3.758-2.05-5.877c-0.313-2.789-1.128-5.203-4.135-6.219
                c-1.928-0.652-2.72-2.26-3.367-4.059c-0.41-1.141-0.106-2.808-2.013-3.19c-2.125,3.365-5.959,5.718-5.968,10.446
                c-0.002,1.129-1.216,2.021-2.549,1.934c-1.449-0.096-1.899-1.217-1.929-2.458c-0.065-2.664,0.015-5.332-0.084-7.993
                c-0.039-1.037,0.563-2.37-1.266-3.188c-0.354,2.763-2.766,3.689-4.409,5.367c-1.548,1.581-0.392,5.102-3.973,5.94
                c-2.503-4.808-1.623-10.126-2.597-15.034c-3.368-0.939-3.332,3.311-5.938,3.801c-2.479-1.285-2.279-3.734-2.617-5.971
                c-0.327-2.163,0.479-4.559-1.106-6.797c-1.604,1.992-0.769,4.875-2.854,5.969c-2.213,1.16-2.173,3.174-2.659,5.046
                c-0.333,1.288-0.472,2.63-0.862,3.897c-0.29,0.941-0.771,1.93-1.92,2.086c-1.407,0.191-2.101-0.803-2.614-1.888
                c-1.147-2.424-1.642-5.054-2.373-7.612c-0.677-2.373-0.767-5.125-2.731-6.856c-1.928-1.698-2.516-3.825-2.977-6.124
                c-0.253-1.262-0.267-2.646-2.146-3.745c-0.847,4.281-2.244,8.138-5.773,10.896c-0.88,0.688-1.049,2.598-2.638,2.48
                c-1.78-0.13-2.29-1.75-2.707-3.206c-0.596-2.078-1.098-4.183-1.643-6.275c-0.32-1.229-0.295-2.632-1.694-3.368
                c-1.619,1.467-1.542,3.642-2.319,5.441c-0.597,1.383-0.986,3.09-2.754,3.171c-2.11,0.097-2.675-1.814-3.113-3.37
                c-0.9-3.203-1.477-6.496-2.383-9.696c-0.354-1.249-0.253-2.978-2.394-3.726c0.114,4.73-4.826,6.416-5.501,10.69
                c-0.24,1.518-2.111,1.189-3.153-0.083c-1.012-1.235-0.834-2.843-1.278-4.256c-0.524-1.671,0.123-3.751-1.733-5.051
                c-0.456,0.14-0.928,0.24-1.364,0.426c-4.177,1.774-5.614,1.176-7.127-2.982c-0.484-1.33-0.225-3.038-2-3.84
                c-1.668,1.385-1.657,3.518-2.334,5.334c-0.638,1.711-0.841,3.85-2.01,5.037c-2.019,2.053-3.691,4.062-4.192,6.979
                c-0.169,0.98-1.016,1.771-2.155,1.779c-1.424,0.009-2.032-1.053-2.321-2.218c-0.52-2.095,0.231-4.331-0.607-6.405
                c-4.584,0.055-5.019-0.303-6.196-4.531c-0.679-2.438-0.335-5.217-2.793-7.458c0.344,5.243-6.068,7.743-4.758,13.369
                c0.232,0.995-0.436,2.068-1.509,2.301c-1.398,0.302-2.055-0.723-2.504-1.899c-1.078-2.826-1.407-5.811-1.938-8.756
                c-0.461-2.553-0.517-5.232-1.981-7.738c-1.9,1.896-3.65,1.571-5.385,0.245c-1.246-0.952-2.329-1.275-4.068-0.645
                c-2.453,0.89-3.742-0.203-4.087-2.75c-0.128-0.942,0.593-1.94-0.216-3.054c-2.02-1.393-4.197-2.895-6.305-4.35
                c-4.541,2.306-5.945,1.702-6.986-2.934c-0.351-1.564-0.436-3.199-1.39-4.624c-1.062,0.714-1.067,2.391-2.421,2.501
                c-3.01,0.246-4.414,2.378-5.504,4.73c-2.755-1.129-2.755-1.129-6.8-9.173c-1.066,2.767-2.193,5.304-5.357,4.181
                c-1.808,2.447-1.062,5.195-2.129,7.465c-0.513,1.093-1.08,2.16-2.433,2.207c-1.46,0.049-1.9-1.121-2.254-2.259
                c-0.196-0.632-0.375-1.283-0.446-1.938c-0.233-2.137-0.151-4.336-2.055-5.938c-0.643-0.541-0.769-1.453-0.418-2.312
                c0.521-1.278,0.038-2.258-0.919-3.094c-1.466-1.279-2.045-2.971-2.363-4.828c-0.196-1.143-0.477-2.314-1.503-2.755
                c-4.833,2.696-5.549,8.202-8.044,12.111c-2.443-0.32-3.46-1.687-3.373-3.162c0.267-4.549-2.809-7.844-3.675-11.867
                c-2.697,2.766-4.55,5.759-5.121,9.44c-2.74-0.339-3.542-1.899-3.311-3.898c0.279-2.406,0.356-4.642-1.452-6.542
                c-1.814-1.907-0.212-5.021-2.345-6.854c-1.826,0.764-1.218,3.622-3.953,3.98c-4.632-2.926-2.006-9.014-5.003-13.006
                c0.264,4.283-5.298,6.113-4.055,10.674c-3.571,2.467-2.943,7.084-4.708,10.358c-3.468-0.686-3.305-3.057-3.363-5.177
                c-0.079-2.835,0.31-5.891-1.533-8.195c-2.506-3.135-3.054-6.529-2.686-10.312C26.431,510.871,26.476,509.674,25.021,507.727z
                M413.594,284.21c2.069,3.744,3.936,6.873,5.558,10.124c1.774,3.557,5.348,4.909,8.283,6.986c2.086,1.477,4.027-0.798,6.093-1.082
                c1.154-0.158,2.294-0.456,3.452-0.537c14.821-1.032,29.216-4.705,43.832-6.997c9.852-1.545,19.813-2.395,29.662-3.952
                c6.571-1.039,13.724-0.205,19.251-5.368c0.979-0.915,2.774-1.063,4.235-1.337c4.44-0.834,8.899-1.022,13.428-0.59
                c7.957,0.758,15.91,1.377,23.944,0.759c4.996-0.385,8.422-2.101,10.351-7.016c2.258-5.754,5.601-7.425,11.816-6.633
                c2.807,0.357,5.578,1,8.387,1.324c2.672,0.308,5.282,0.349,7.189-2.254c1.111-1.516,2.992-2.174,4.79-2.498
                c2.658-0.479,5.042-1.687,7.576-2.446c4.902-1.469,9.888-3.371,14.899-0.105c0.623,0.405,1.708,0.578,2.393,0.334
                c5.479-1.954,11.614-2.263,16.558-5.383c9.93-6.269,21.211-7.566,32.297-9.668c3.431-0.65,6.957-0.832,10.457-0.311
                c4.473,0.666,8.665-0.296,12.492-2.51c5.573-3.223,11.6-4.879,17.854-6.123c10.932-2.174,21.832-4.444,32.379-8.25
                c11.109-4.01,21.867-8.97,33.248-12.245c4.961-1.428,9.717-3.281,14.202-5.989c4.487-2.709,9.697-4.393,14.841-3.104
                c5.323,1.333,9.451-0.388,13.613-3.002c2.822-1.773,5.608-3.554,8.872-4.496c15.351-4.43,30.348-10.013,45.812-14.09
                c2.702-0.712,5.523-1.579,7.437-3.863c2.206-2.635,4.601-5.021,8.368-6.409c-3.129-1.519-5.782-0.454-8.147-1.403
                c-3.491-1.402-7.169-1.542-10.798-1.155c-5.649,0.604-11.231-0.517-16.092-2.649c-7.444-3.266-15.706-4.232-22.715-8.578
                c-1.514-0.939-3.406-1.63-5.168-1.744c-7.737-0.503-13.537-4.947-19.49-9.144c-0.672-0.475-1.322-1.137-2.076-1.314
                c-3.582-0.844-6.699-2.707-9.836-4.62c-5.293,4.566-11.062,7.733-17.555,9.571c-1.29,0.365-2.6,1.055-3.092,2.357
                c-1.83,4.842-6.116,6.875-10.272,8.878c-4.054,1.954-8.112,3.843-11.823,6.412c-2.066,1.429-4.098,2.708-6.909,2.067
                c-1.701-0.388-3.392,0.45-4.015,2.244c-1.043,3.001-3.527,3.828-6.186,4.328c-1.509,0.284-2.968,0.672-4.184,1.532
                c-5.188,3.669-11.223,5.863-16.535,9.131c-10.062,6.191-21.381,8.122-32.322,11.399c-1.434,0.429-3.156,1.367-4.62-0.116
                c-0.259-1.389,0.822-1.849,1.61-2.285c6.116-3.376,12.093-7.045,18.729-9.373c1.112-0.39,2.302-0.679,3.078-1.566
                c3.403-3.888,7.894-5.773,12.626-7.495c4.407-1.603,9.578-1.656,12.831-5.953c0.597-0.789,2.075-1.228,3.165-1.264
                c2.985-0.099,5.5-1.34,7.865-2.899c4.995-3.292,10.273-6.04,15.604-8.734c5.536-2.798,9.907-7.244,14.723-11.052
                c2.247-1.776,4.358-3.574,7.55-3.223c1.791,0.197,3.395-0.793,4.115-2.344c1.033-2.223,3.251-3.268,4.605-5.04
                c-1.203-2.009-2.841-2.853-5-3.039c-4.383-0.376-8.447-1.773-12.057-4.426c-3.541,2.122-6.9,4.269-10.381,6.195
                c-9.756,5.398-19.877,10.126-29.294,16.145c-4.325,2.765-9.194,4.858-11.328,10.091c-0.233,0.574-0.818,1.076-1.354,1.445
                c-7.854,5.411-16.138,10.344-25.399,12.437c-6.227,1.407-11.493,3.937-16.76,7.208c-7.485,4.651-15.205,8.928-22.872,13.282
                c-2.522,1.432-5.242,1.941-8.191,1.251c-2.624-0.614-5.335-0.523-7.924,0.243c-9.9,2.932-19.719,6.115-29.207,10.222
                c-0.938,0.406-2.339,0.49-2.418,2.366c3.862,0.255,7.626-0.104,11.401-0.36c3.63-0.246,7.318-1.445,10.949,0.018
                c-0.283,1.373-1.147,1.628-1.901,1.843c-9.814,2.799-18.917,7.339-28.191,11.46c-4.433,1.97-8.919,3.639-13.882,3.784
                c-1.787,0.052-3.59,0.676-5.324,1.232c-3.173,1.017-6.365,1.837-9.656,2.455c-4.393,0.824-9.02,1.68-12.26,5.096
                c-4.128,4.352-9.257,5.69-14.895,5.745c-2.013,0.02-3.967,0.011-5.918,0.582c-5.431,1.589-10.878,3.122-16.32,4.67
                c-2.422,0.689-4.473,1.923-6.558,3.404c-4.117,2.925-8.662,4.575-14.036,3.464c-4.551-0.941-8.697,0.256-12.375,3.564
                c-3.578,3.219-8.001,4.576-12.908,3.273c-3.936-1.044-7.647-0.317-10.892,1.946c-1.558,1.087-3.117,1.873-4.906,2.283
                c-2.923,0.67-5.753,1.63-8.608,2.534c-2.988,0.946-6.113,2.214-9.255,1.451c-2.585-0.629-4.905-0.256-7.362,0.253
                c-2.438,0.505-4.9,0.761-7.429,0.755c-4.521-0.011-9.144,0.479-13.008,2.902C422.568,281.571,418.653,283.173,413.594,284.21z
                M179.519,833.369c1.146-2.355,3.001-2.527,4.844-2.586c2.501-0.078,4.984,0.131,7.448,0.645
                c5.052,1.055,10.111,2.072,15.181,3.031c2.317,0.438,4.674,0.89,7.871,0.172c-1.927-1.162-3.038-1.82-4.136-2.496
                c-1.662-1.023,0.789-2.306-0.349-3.62c-3.328-0.904-6.877-1.792-10.379-2.839c-3.98-1.191-8.192-1.916-11.712-4.189
                c-2.177-1.407-4.381-2.244-6.867-2.703c-1.624-0.3-3.264-0.758-4.759-1.444c-2.111-0.97-3.519-2.49-2.506-5.229
                c3.95-1.089,7.618,0.366,11.247,1.313c6.025,1.574,12.002,2.591,18.166,0.846c0.965-1.799,0.697-3.203-1.329-3.957
                c-1.716-0.639-3.446-1.255-5.205-1.759c-2.614-0.749-4.239-2.344-4.315-4.79c-2.304-1.313-5.57,0.064-6.315-3.305
                c2.618-2.115,5.753,0.477,8.183-1.277c0.341-1.389-1.522-2.484-0.163-3.9c2.215-1.164,4.821,0.028,7.229-0.725
                c-2.542-2.191-2.739-2.33-6.086-2.54c-4.488-0.282-8.984-0.353-13.453-0.999c-3.416-0.493-6.343-1.925-9.037-3.967
                c-0.858-0.649-1.426-1.414-0.674-2.709c3.577-0.971,7.217,1.689,10.964,0.471c-0.317-1.361-2.173-1.197-2.083-2.789
                c0.708-0.484,1.496-1.021,2.992-2.045c-5.364-0.125-4.237-5.783-8.116-7.05c3.083-2.066,3.455-2.181,6.679-1.667
                c4.933,0.787,9.835,1.847,14.794,2.369c4.219,0.443,8.466,0.193,11.623-3.705c-3.349-2.479-8.138-1.045-10.81-4.758
                c2.697-2.877,5.81-1.276,8.629-1.587c3.12-0.344,6.222-1.066,9.258-1.896c2.624-0.716,5.784,0.111,8.546-2.889
                c-2.757,0.158-4.738,0.23-6.711,0.399c-1.664,0.142-3.304,0.268-4.975-0.028c-2.377-0.422-2.691-1.906-1.716-4.106
                c-5.473,0.442-9.628-2.591-14.446-3.267c-4.756-0.666-9.9-0.762-14.031-4.229c1.536-3.051,4.225-3.148,6.555-3.684
                c7.655-1.759,15.558-1.649,23.283-2.93c1.834-0.304,3.717-0.407,5.109-2.258c-12.25,2.048-24.035-0.204-35.627-2.249
                c-0.399-2.262,1.614-2.045,1.805-3.479c-1.637-0.218-3.264-0.47-4.838-1.021c-2.718-0.953-1.138-2.616-0.318-4.234
                c-2.642-0.297-4.788-0.514-6.928-0.782c-4.596-0.575-7.365-1.203-5.012-8.091c7.395-0.02,14.518,2.855,21.965,3.242
                c0.213-2.609,1.5-3.189,3.686-2.437c2.828,0.974,5.446,2.435,8.372,3.223c2.915,0.786,4.595-1.558,6.906-2.241
                c1.176-0.348,1.08-1.849,0.636-3.322c-7.535-1.447-15.184-3.025-22.876-4.36c-7.135-1.238-13.628-4.047-19.888-7.523
                c-0.632-0.351-0.79-1.022-0.643-1.69c0.37-1.607,2.152-0.359,2.814-1.473c-0.549-1.559-2.698-2.038-2.761-4.162
                c1.322-2.227,3.345-1.876,5.571-1.34c1.916,0.462,4.079,2.084,5.736,1.048c2.904-1.815,5.778-1.185,8.713-1.021
                c5.891,0.328,5.888,0.367,11.804-2.913c-3.225-1.57-6.358-3.055-9.736-3.993c-4.865-1.351-5.28-2.039-4.358-6.943
                c-3.59-0.818-7.824,1.129-10.804-2.311c-2.573-2.971-7.947-1.227-10.092-6.178c7.704-2.792,15.923-1.029,23.426-3.913
                c-1.816-2.411-5.326-1.229-6.341-3.864c-0.162-1.713,2.903-1.58,1.72-3.709c-0.904-1.627-2.314-0.897-3.542-0.973
                c-1.327-0.082-2.674,0.08-3.993-0.053c-3.401-0.346-5.859-2.221-8.354-4.957c4.092-1.676,7.815,0.553,11.264-0.754
                c0.376-3.178-1.018-4.938-3.983-5.865c-1.629-0.511-3.662-0.895-3.998-3.18c1.477-2.62,4.6,0.152,6.261-1.967
                c0.071-1.678-2.415-1.804-2.143-3.517c0.307-1.776,2.441-1.252,3.291-2.479c-2.569-1.277-5.328-1.238-7.84-2.103
                c-2.401-0.827-5.711-0.171-6.697-3.689c1.908-1.21,3.779-0.791,5.578-0.842c1.945-0.056,4.146,0.425,4.529-2.396
                c-1.242-2.566-3.355-3.52-5.965-3.93c-1.608-0.253-3.781,0.036-4.279-2.572c3.054-2.44,6.988-1.161,10.255-2.148
                c-1.509-4.824-7.303-1.946-8.808-5.663c0.454-1.92,2.344-1.449,3.139-2.611c-1.797-1.233-3.63-2.377-5.334-3.688
                c-2.665-2.051-0.323-3.592,1.258-5.49c-3.697-0.041-3.677-3.101-5.479-5.994c0.443,5.989-3.51,6.453-7.193,7.336
                c1.483,2.174,4.007,1.018,5.223,3.002c0.297,1.1-1.315,2.519,0.589,4.08c1.754,1.438,0.35,3.346-1.283,4.514
                c-1.806,1.29-4.054,1.423-6.613,2.348c2.48,1.252-0.11,3.303,1.281,4.975c0.93,0.051,1.918,0.056,2.895,0.168
                c1.002,0.114,2.128,0.306,2.256,1.541c0.165,1.588-1.073,1.904-2.284,1.969c-2.338,0.123-4.018,1.254-5.54,3.404
                c2.427,0.446,5.105-0.18,6.865,2.334c-3.679,3.256-8.856,2.283-12.791,5.458c3.183,0.766,6.189-0.868,8.725,1.323
                c-1.719,3.709-6.256,3.365-8.911,5.951c4.052,1.152,8.076-1.422,11.861,0.727c-0.88,2.514-3.045,2.881-4.858,3.401
                c-3.716,1.065-7.045,2.972-10.587,4.396c-2.39,0.959-2.913,2.558-2.729,4.84c3.044,0.262,5.568-0.988,8.22-1.003
                c2.52-0.014,5.522-1.831,8.109,2.599c-7.978,0.012-14.118,3.367-20.249,7.094c1.413,1.994,3.533,2.397,5.695,2.605
                c2.652,0.255,5.318,0.369,7.973,0.599c2.31,0.2,4.611,0.483,6.056,2.286c0.292,1.873-2.003,1.945-1.832,2.93
                c0.544,3.137-1.623,3.579-3.683,4.086c-5.79,1.425-11.303,4.029-17.413,4.109c-0.51,0.008-0.885,0.35-0.807,1.115
                c2.857,0.997,6.174-1.127,8.712,0.475c2.664,1.681,6.131-1.18,8.314,1.848c-2.127,2.657-2.111,2.755-5.684,3.311
                c-8.362,1.302-15.984,4.773-24.339,7.505c1.37,1.308,2.364,1.083,3.296,0.899c3.102-0.611,6.199-1.245,9.286-1.928
                c1.633-0.361,3.759-1.299,4.838-0.387c2.678,2.266,4.856,0.578,7.212-0.136c2.707-0.819,5.343-1.929,8.094-2.536
                c4.793-1.061,7.711,2.309,6.347,7.062c-0.722,2.515-2.574,3.785-4.888,4.556c-3.338,1.112-6.802,1.557-10.282,1.952
                c-4.294,0.488-8.645,0.623-12.823,1.885c-0.257,0.077-0.44,0.395-0.642,0.586c-0.067,1.046,1.38,1.567,0.618,3.264
                c-3.851,3.115-9.443,2.221-14.266,4.043c0.261,1.477-0.359,2.902,0.307,4.07c9.75,1.377,19.369-1.994,28.412,0.312
                c0.771,1.721-0.81,2.45,0.029,3.456c8.239,0.773,8.239,0.773,10.473,2.81c-0.294,1.149-1.252,1.45-2.216,1.769
                c-8.53,2.817-16.867,6.235-25.619,8.361c-2.308,0.561-4.355,1.248-4.866,3.967c1.716,0.752,3.14,0.175,4.609-0.08
                c2.623-0.453,5.265-0.819,7.911-1.102c1.13-0.12,2.847-0.432,3.26,0.621c1.222,3.113,3.732,1.875,5.818,2.16
                c1.379,0.189,3.047-0.629,4.097,1.162c-0.146,0.414-0.201,1.177-0.474,1.264c-13.428,4.322-27.033,7.888-40.979,9.041
                c-1.271,3.389-1.271,3.389-5.481,5.203c3.436,0.334,6.877,0.098,7.687,3.94c2.632,0.592,5.48-0.491,6.878,2.149
                c-1.251,4.816-7.414,4.383-8.932,8.855c1.825,0.76,3.418,0.155,4.987-0.258c2.574-0.677,5.135-1.415,7.676-2.209
                c6.196-1.938,12.353-4.007,18.579-5.84c1.939-0.57,4.209-1.46,5.921,0.961c-0.741,2.89-3.145,4.241-5.222,5.82
                c-2.25,1.711-4.61,3.277-6.937,4.887c-1.999,1.383-4.951,1.112-6.682,3.464c2.876,0,5.37-0.023,7.863,0.006
                c2.813,0.033,5.626-0.292,8.399,0.709c4.048,1.462,8.079,3.425,12.578,1.663c0.088-0.034,0.261,0.151,0.667,0.402
                c-1.527,2.727-3.88,4.434-6.663,5.619c-2.937,1.25-6.027,1.855-9.212,2.094c-3.987,0.299-7.973,0.645-11.947,1.082
                c-1.758,0.193-3.399,0.723-2.523,2.58c4.635,2.925,9.1,0.317,12.52,0.438c2.336,1.581,0.385,2.664,0.586,3.045
                c3.298,4.294,7.224,4.954,11.589,4.765c1.857-0.08,4.039-0.593,5.77,1.623c-7.732,2.2-15.283,4.351-23.311,6.636
                c1.198,1.092,2.393,1.459,1.842,2.992c-2.882,2.1-6.436,1.76-9.887,1.881c-2.656,0.093-6.056-0.81-7.74,1.006
                c-2.645,2.851-6.054,3.245-9.374,4.666c2.714,0.264,4.313,1.604,4.588,4.381c4.345-1.334,8.267,0.227,12.25,0.035
                c5.999-0.29,11.934-1.134,17.892-1.793c1.899-0.211,3.992-0.927,5.496,1.127c-0.054,1.59-1.204,2.311-2.344,3.002
                c-2.013,1.222-4.199,2.057-6.51,2.455c-6.067,1.049-12.15,2.014-18.224,3.031c-0.917,0.153-2.067,0.029-2.239,1.701
                c2.013,0.791,4.134,0.525,6.24,0.457c1.827-0.059,3.848-0.522,5.411,0.466c4.303,2.72,8.936,2.119,13.579,1.815
                c2.676-0.174,5.865-0.623,7.73,0.992c1.646,1.426,3.082,1.457,4.811,1.703c3.055,0.437,3.827,2.473,1.92,4.893
                c-1.202,1.524-2.948,2.16-4.699,2.686c-2.048,0.615-4.15,1.053-6.491,1.633c1.562,3.033-0.261,4.041-2.642,4.489
                c-3.11,0.587-6.378,0.778-9.06,2.594c-5.521,3.737-11.695,5.517-18.16,6.571c-1.225,0.199-2.482,0.367-3.288,1.614
                c2.214,0.985,4.786-0.314,6.583,1.349c-0.333,1.842-1.591,1.873-2.704,2.145c-3.262,0.798-6.604,0.795-9.922,1.068
                c-4.801,0.394-9.673,0.588-14.028,3.047c-0.367,0.207-0.567,0.713-0.842,1.076c0.716,1.113,2.94,0.553,2.554,2.939
                c-2.396,2.783-6.865,2.493-9.742,5.162c1.632,1.021,4.014-0.291,4.944,2.245c-1.023,3.708-5.804,2.05-8.165,5.2
                c3.928,0.291,7.188,0.129,10.466-0.205c3.261-0.333,6.5-1.596,10.688-0.59c-5.616,3.324-11.686,3.677-17.023,7.766
                c5.226,0.302,9.907-1.163,13.729,2.906c-5.746,5.434-13.859,5.174-21.124,9.279c5.55,0.68,9.936,1.621,14.283-0.438
                c1.2-0.569,2.795-1.309,3.81-0.501c2.099,1.669,3.817,0.324,5.583-0.251c8.545-2.783,16.917-6.102,25.675-8.223
                c2.096-0.508,4.176-1.183,6.083-0.376c1.256,1.674-1.21,2.338-0.578,3.935c2.921,0.215,5.862-0.243,8.817-0.68
                c3.457-0.511,6.925-0.978,10.404-1.286c1.616-0.143,3.607-0.232,4.003,1.957c0.321,1.775-1.278,2.539-2.545,3.331
                c-0.419,0.262-0.883,0.505-1.359,0.612c-5.772,1.303-10.924,5.078-17.408,4.334c4.092,1.056,5.016,5.801,8.805,6.604
                c0.098,1.854-1.471,1.775-2.312,2.725c21.62,0,43.05,0,64.858,0c-1.113-3.408,0.625-5.533,2.588-7.096
                c0.496-1.63-0.233-2.658-0.523-3.771c-0.335-1.287-0.823-2.537-1.126-3.83c-1.184-5.037,0.229-6.6,5.352-5.346
                c6.948,1.701,13.807,3.763,20.706,5.662c7.22,1.986,14.365,4.298,21.874,5.027c1.302,0.127,2.824,0.638,3.615-0.832
                c1.312-2.44,3.982-3.378,5.655-5.248c-0.103-1.115-1.457-1.705-0.926-2.634c1.614-2.165,4.564-0.771,6.822-3.112
                c-4.755-1.244-8.941-0.021-12.916-0.822c-3.913-0.787-7.816-1.701-11.637-2.846c-3.81-1.143-8.103-1.099-11.436-3.672
                c1.525-3.315,6.167-2.341,6.604-6.123c-1.112-1.613-2.718-1.25-4.153-1.281c-1.85-0.041-3.675-0.157-5.303-1.119
                c-0.353-0.209-0.504-0.757-0.658-1.004c0.28-1.828,2.118-1.396,2.895-2.312c-2.931-1.781-6.098-2.809-9.207-3.987
                c-3.422-1.297-6.811-2.686-10.182-4.111c-1.024-0.434-2.25-0.903-1.792-2.484c3.575-0.962,3.645-1.242,11.085,0.633
                c6.01,1.515,11.464-0.909,18.029-0.791c-2.748-2.301-6.199-1.433-7.505-4.682c4.677-3.978,10.014-0.186,14.765-1.294
                c0.171-1.772-0.637-2.44-2.181-2.862c-3.298-0.903-6.594-0.939-9.904-0.37c-16.18,2.786-31.756-0.958-47.363-4.344
                c-1.419-0.308-3.023-0.646-3.062-2.954c3.958-3.215,9.291,0.131,13.809-2.318c-2.38-1.833-4.883-2.212-7.267-2.794
                c-5.173-1.263-10.385-2.37-15.575-3.567c-2.105-0.486-4.29-0.925-4.634-3.641c1.365-2.163,3.517-1.789,5.438-1.813
                c6.003-0.075,11.987-0.427,17.955-1.044c1.027-0.106,2.433,0.361,2.877-1.294c-1.857-1.614-4.351-1.499-6.479-2.368
                C183.123,836.336,180.678,836.03,179.519,833.369z M1088.933,44.946c0.419,0.453,0.709,0.857,1.083,1.157
                c3.691,2.963,5.508,6.61,4.211,11.409c-0.27,0.996-0.08,1.941,0.105,2.943c1.285,6.948-1.504,11.526-8.219,13.781
                c-1.244,0.418-3.001,0.168-3.654,1.367c-1.912,3.503-5.354,5.418-7.917,8.2c-5.611,6.087-11.164,12.188-17.532,17.533
                c-2.607,2.188-4.061,5.303-4.709,9.015c2.597-0.594,4.698-1.526,6.762-1.45c3.987,0.148,7.031-1.632,10.011-3.816
                c6.188-4.535,12.468-8.916,19.53-12.035c1.857-0.82,3.332-2.168,4.806-3.534c6.846-6.343,13.714-12.665,21.026-18.473
                c2.212-1.757,4.49-3.475,6.919-4.904c3.592-2.114,5.408-1.819,8.562,0.858c0.508,0.431,0.969,0.916,1.458,1.368
                c2.033,1.882,4.218,1.865,6.353,0.297c1.197-0.879,2.208-2.015,3.283-3.055c3.258-3.153,6.779-6.028,11.109-7.443
                c2.395-0.783,3.995-2.096,5.473-3.999c4.04-5.2,8.919-9.192,15.115-11.894c7.74-3.375,14.463-8.43,18.504-16.128
                c3.486-6.641,8.224-12.019,14.15-16.559c4.011-3.072,7.661-6.614,11.849-10.274c-6.097-1.243-279.705-1.017-283.22,0.092
                c1.725,2.413,2.469,5.027,1.841,7.982c-1.354,6.372-3.968,12.289-7.351,17.74c-2.16,3.479-5.169,6.545-8.229,9.321
                c-2.979,2.702-6.73,4.497-9.65,7.355c-0.173,0.169-0.627,0.052-0.945,0.068c-0.094-0.149-0.22-0.28-0.263-0.434
                c-0.25-0.893,0.443-1.537,0.632-2.317c1.756-7.233,3.893-14.438-0.629-21.543c-0.989-1.554-1.975-2.957-3.311-4.264
                c-2.465-2.41-4.863-4.823-6.865-7.797c-2.381-3.538-5.358-7.078-10.699-7.052c-26.163,0.125-52.328,0.046-78.492,0.088
                c-1.152,0.002-2.686-0.67-3.456,1.167c2.78,1.597,5.601,3.041,8.233,4.771c4.18,2.747,8.424,5.186,13.148,7.038
                c4.396,1.723,8.613,4.281,9.775,9.801c0.564,2.685,2.797,4.975,5.353,5.481c4.209,0.833,6.519,3.737,9.081,6.523
                c1.119,1.216,2.098,2.56,3.141,3.846c-1.023-4.656-2.34-8.912-3.558-13.209c-0.63-2.221-1.368-5.003-4.854-4.458
                c-0.241,0.038-0.65-0.264-0.805-0.516c-2.201-3.593-4.47-7.164-5.343-11.396c0.741-0.189,0.919-0.3,1.035-0.255
                c7.235,2.79,12.526,8.065,17.372,13.826c4.604,5.471,8.297,11.411,8.381,18.927c0.008,0.637,0.186,1.461,0.604,1.875
                c3.456,3.417,2.113,7.727,2.123,11.708c0.007,2.228-0.142,4.757-3.228,5.476c-1.003,0.234-1.278,1.502-1.62,2.41
                c-0.833,2.219-2.199,4.043-3.654,5.888c-1.301,1.65-3.094,3.398-2.551,5.731c0.597,2.557-0.763,4.138-2.132,5.792
                c-1.132,1.369-2.412,2.615-4.104,4.426c4.767,1.219,8.671,3.347,13.38,3.741c1.419-3.344,2.812-6.518,4.111-9.731
                c0.693-1.717,1.33-3.497,2.869-4.599c2.531-1.812,3.756-4.085,3.388-7.249c-0.089-0.762,0.45-1.583,0.638-2.392
                c0.754-3.261,2.683-5.573,5.641-7.07c2.305-5.914,5.916-11.73,3.573-18.352c-1.694-4.788,0.935-7.322,3.419-10.157
                c5.664,1.319,2.822,7.426,6.207,9.894c0.701,0.511,0.695,1.933,0.312,2.864c-1.159,2.808-0.535,5.617-0.043,8.376
                c0.403,2.261,0.214,4.457-0.88,6.297c-2.66,4.47-3.83,9.53-5.995,14.188c-1.108,2.385-2.771,4.552-2.903,7.377
                c-0.139,2.967-1.612,5.365-3.706,7.421c-1.896,1.86-3.401,3.942-3.736,6.809c0.841,0.311,1.436,0.655,2.066,0.743
                c7.592,1.057,15.13,2.394,22.552,4.327c1.525,0.397,2.916,0.261,4.342-0.458c3.781-1.909,7.866-2.655,12.055-2.932
                c3.49-0.23,6.979-0.514,10.474-0.658c4.525-0.186,8.292-1.553,11.213-5.36c5.575-7.269,11.368-14.374,17.196-21.444
                c2.348-2.849,4.352-5.858,6.049-9.131c2.837-5.466,5.5-11.052,9.253-15.981c6.205-8.153,14.853-11.626,24.733-11.998
                c2.849-0.107,5.113-0.638,7.352-2.227c10.685-7.582,22.814-12.349,34.666-17.645c4.103-1.833,8.107-1.42,11.86,1.157
                c1.38,0.948,1.935,2.737,3.544,3.412c11.606-6.782,19.545-1.966,20.936,8.151c0.271,0.193,0.52,0.437,0.816,0.572
                c4.436,2.003,7.633,4.817,6.859,10.294c-0.124,0.876,0.166,1.654,0.804,2.26C1089.255,36.981,1089.585,40.864,1088.933,44.946z
                M14.393,743.885c-1.748,3.906-5.242,6.438-3.334,10.894c0.37,0.864-0.333,2.254-0.69,3.353c-1.14,3.504-2.698,6.841-4.911,9.777
                c-1.293,1.715-1.747,3.462-1.746,5.54C3.738,822.26,3.729,871.07,3.75,919.881c0,1.27-0.379,2.699,0.943,3.736
                c0.436,0.066,0.921,0.204,1.405,0.204c37.649,0.019,75.299,0.112,112.947-0.095c3.908-0.021,8.099-0.502,12.897-2.654
                c-3.217-0.582-2.751-3.711-5.2-4.221c-2.007-0.419-4.31-0.217-6.208-2.794c4.525-2.014,9.135-1.199,13.295-2.312
                c4.325-1.157,8.645-2.628,12.539-5.116c-8.165-0.975-16.012,2.422-23.843,0.985c-2.082-1.012,1.051-2.561-0.961-3.725
                c-3.894,1.19-8.036,2.397-12.139,3.729c-4.589,1.49-9.336,2.69-13.657,4.762c-4.708,2.257-9.601,1.674-14.411,1.985
                c-4.429,0.286-8.94,0.058-13.318-1.015c-0.399-0.098-0.838-0.568-0.416-1.855c5.683-4.678,14.261-4.253,20.841-8.576
                c-3.774-3.701-9.338,1.621-12.432-3.036c0.564-2.815,3.605-2.823,5.011-4.839c-3.65-0.671-7.458,0.766-10.228-2.473
                c1.265-3.377,4.665-2.814,7.029-4.068c-0.943-1.393-3.747-0.722-3.64-3.49c2.127-2.889,6.098-2.795,8.854-4.74
                c0.248-1.516-2.108-1.266-1.611-3.171c5.304-6.089,13.305-4.967,20.663-6.344c-1.568-1.645-1.548-2.826,0.353-3.693
                c2.436-1.111,4.964-1.858,7.605-2.35c4.083-0.759,8.263-1.332,11.842-3.684c3.956-2.599,8.21-4.36,12.908-5.027
                c0.311-0.044,0.593-0.282,0.884-0.435c0.057-0.946-1.086-1.759-0.312-2.786c3.771-3.292,10.294-0.894,13.428-6.224
                c-1.221-0.762-2.216-0.585-3.175-0.635c-1.635-0.084-3.911,0.787-4.768-0.57c-2.027-3.21-4.863-2.027-7.513-2.1
                c-4.096-0.111-8.32,0.83-12.039-1.932c-1.08-0.803-2.588-0.432-3.914-0.439c-1.997-0.012-4.004,0.125-5.989-0.037
                c-2.442-0.2-4.204-1.395-5.073-4.008c9.703-3.992,20.466-2.119,29.861-7.148c-1.258-0.855-2.091-0.668-2.866-0.508
                c-10.322,2.129-20.815,1.101-31.219,1.698c-0.659,0.038-1.338-0.005-1.988-0.12c-2.216-0.394-2.066-1.742-1.245-3.328
                c-1.402-1.018-3.889,0.145-4.547-2.367c0.573-1.91,2.337-2.164,3.924-2.457c3.123-0.578,5.629-2.035,8.259-3.873
                c2.211-1.547,1.896-4.146,4.087-5.379c-2.315-3.754-4.562-7.251-6.656-10.838c-1.574-2.695-1.486-2.747,0.728-5.503
                c-2.345-4.267-4.711-8.572-7.111-12.939c-3.429,3.101-3.854,3.821-5.757,9.733c1.165,1.271,2.65,2.632,1.947,4.936
                c-0.974,3.193-1.87,6.404-3.796,9.184c-2.901,4.184-6.404,3.922-8.777-0.634c-0.305-0.585-0.601-1.227-0.681-1.87
                c-0.325-2.593-1.612-4.486-3.431-6.4c-3.687-3.881-3.893-8.082-1.18-12.496c-0.822-1.949-1.703-3.926-2.488-5.939
                c-0.477-1.223-1.571-2.458-1.118-3.748c1.891-5.389-2.029-8.873-3.866-14.131c-2.526,3.162-4.441,5.561-6.522,8.165
                c1.056,0.89,2.536,1.026,2.5,2.62c-0.588,1.982-2.121,3.556-3.34,5.33c-1.209,1.76-3,3.147-3.546,5.312
                c3.327,4.573,1.277,11.981-3.844,14.222c-1.54,0.674-2.9,0.424-4.249-0.441c-2.116-1.357-4.017-2.979-5.74-4.793
                c-3.648-3.844-3.421-5.029,1.305-7.582c0.242-0.13,0.324-0.557,0.519-0.916c0.028-2.211-1.217-4.031-2.114-5.979
                c-0.481-1.047-1.511-2.484-1.161-3.156c1.356-2.608-0.54-3.969-1.654-5.67c-0.913-1.393-1.876-2.752-2.769-4.157
                c-0.891-1.403-1.654-2.841-2.038-4.517c-0.458-2,2.117-2.496,1.947-4.234c-1.668-3.348-3.216-6.669-4.961-9.883
                c-1.248-2.297,0.088-3.162,2.076-3.693c-1.916-3.785-4.496-6.801-5.66-10.688c-1.761,3.86-4.932,7.373-1.21,11.688
                c0.531,0.615,0.366,1.929,0.341,2.915c-0.12,4.692-0.964,9.274-2.459,13.713c-0.51,1.513-0.907,3.27-2.779,3.842
                c-3.798-1.958-5.944-4.798-4.497-7.052c1.662-2.586,0.566-4.353-0.407-6.395C17.094,749.112,15.925,746.91,14.393,743.885z
                M283.82,791.387c0.624,1.654-1.305,2.035-0.669,3.01c0.459,0.703,2.02,0.558,1.651,2.013c-1.629,3.194-5.132,1.892-7.898,2.711
                c1.306,1.753,3.669,0.835,4.368,3.099c-1.52,0.994-3.205,0.689-4.778,0.668c-5.024-0.07-9.968,0.582-14.889,1.484
                c-5.75,1.055-11.569,2.059-17.38,1.338c-5.359-0.665-10.14,1.33-15.162,2.234c-0.644,0.117-1.268,0.451-1.29,1.477
                c3.927,1.307,7.824,1.926,11.982,0.464c3.683-1.295,7.566-1.062,11.376-0.423c1.01,0.17,2.476-0.047,2.653,1.699
                c-1.432,2.253-4.768,1.154-6.455,3.438c4.378-0.169,8.555-1.659,13.641,0.364c-5.175,3.807-11.012,3.77-15.462,6.924
                c8.584,0.854,16.621-3.052,24.608-3.303c1.425,1.589,0.035,3.296,1.498,4.158c2.13,1.255,0.642,2.403-0.11,3.566
                c1.305,0.959,3.506-0.27,4.377,2.104c-1.925,3.319-5.53,1.863-8.341,2.557c-0.068,1.146,1.703,1.365,0.971,2.845
                c-2.408,3.196-6.849,1.383-9.941,3.476c-0.504,1.176,1.252,1.938,0.552,3.57c-8.1,7.412-18.719,9.484-29.404,11.422
                c3.359,0.854,7.001-0.486,9.857,1.506c1.977,1.379,3.645,1.233,5.57,0.616c1.422-0.456,2.77-1.189,4.215-1.521
                c4.467-1.027,8.538-3.79,13.504-2.679c6.166,1.379,12.265,3.105,18.565,3.857c0.993,0.119,1.93,0.492,2.505,1.783
                c-0.525,1.663-1.443,3.295-3.542,3.413c-2.995,0.169-5.263,1.348-7.451,3.415c-1.905,1.8-5.001,0.433-7.175,1.518
                c-2.109,1.052-2.864,4.063-5.721,4.405c-2.727,0.325-5.195,0.942-5.824,4.35c-0.249,1.351-1.904,1.649-3.149,2.175
                c-2.5,1.054-5.086,2.058-6.874,4.448c4.864,2.371,10.261,0.284,15.131,3.19c-8.007-0.043-12.271,6.144-18.177,10.032
                c0.449,1.364-1.259,2.73-0.614,4.453c6.271,2.396,12.672,3.026,19.221,2.68c1.496-0.079,3.06-0.05,4.075,1.356
                c1.002,1.389,1.274,2.895,0.159,4.313c1.711,0.99,4.189,0.302,4.32,3.112c-2.308,1.463-4.877,0.955-7.315,1.051
                c-2.489,0.097-5.356,0.716-5.881,2.949c-0.982,4.177-3.841,3.561-6.529,3.465c-5.361-0.191-10.513-1.666-15.642-3.102
                c-2.389-0.669-4.35-0.807-6.326,1.209c-1.987,2.027-4.606,3.139-7.448,3.842c-3.478,0.861-6.687,2.414-9.125,5.738
                c1.433,0,2.866,0,4.3,0c43.16-0.002,86.32-0.002,129.48-0.004c1.167,0,2.333-0.021,3.499-0.026
                c0.865-0.004,1.769-0.133,2.116-0.994c0.892-2.214,2.851-1.922,4.628-2.179c1.345-0.194,2.937,0.244,3.971-1.299
                c-2.603-0.887-5.7-0.577-7.424-2.792c-1.237-1.587-2.697-1.763-4.294-2.018c-1.959-0.313-3.938-0.51-5.879-0.753
                c-1.102-1.333-0.644-3.1-2.108-4.383c-2.218-1.947-1.639-3.568,1.024-4.824c1.428-0.674,2.937-0.77,4.403-0.486
                c4.082,0.785,8.202,1.524,11.798,3.82c2.43,1.551,5.151,3.072,7.917,2.587c5.932-1.042,11.597,0.291,17.361,0.964
                c2.246,0.264,4.512,0.771,7.151,0.215c-1.142-2.222-2.153-3.961-4.761-4.007c-1.51-0.026-3.365-0.181-3.97-1.636
                c-1.515-3.644-4.682-3.985-7.811-4.295c-6.167-0.609-11.629-2.604-15.859-7.321c-0.304-0.339-0.421-0.847-0.485-0.981
                c-0.018-1.916,2.104-1.802,2.293-3.205c-1.416-1.731-4.393-0.705-5.383-3.014c-0.121-1.275,0.551-2.126,1.418-2.566
                c6.398-3.249,13.375-4.336,20.508-5.322c-4.138-1.405-7.492-4.245-11.653-5.338c-1.968-0.518-4.074-1.148-5.21-2.766
                c-2.435-3.466-5.909-4.195-9.642-4.176c-5.824,0.031-11.15-1.578-16.294-4.142c-1.777-0.886-3.699-1.487-5.568-2.184
                c-1.54-0.573-3.168-0.851-4.112-2.536c-0.936-1.674,1.485-2.499,0.858-3.988c-2.368-0.51-2.368-0.51-4.083-2.122
                c0.111-0.304,0.135-0.78,0.346-0.886c3.084-1.549,6.833-1.533,9.525-4.07c0.238-0.225,0.971,0.001,1.45,0.118
                c3.229,0.788,6.52,1.38,8.893,4.114c1.739,2.004,4.052,2.748,6.69,2.551c0.181-3.866,0.576-4.432,3.948-4.941
                c1.637-0.248,3.322-0.184,4.985-0.256c2.468-0.105,4.96-0.064,7.337-1.123c-6.352-3.76-6.352-3.76-6.464-6.564
                c-3.175-1.174-6.539-0.895-9.84-1.152c-3.331-0.262-6.938,0.217-9.322-2.829c0.819-2.836,3.931-1.985,5.296-4.102
                c-3.73-0.921-7.393-0.758-11.025-0.966c-3.622-0.209-7.561,0.56-10.103-2.148c-0.245-2.821,2.78-1.256,2.885-3.611
                c-3.161-0.175-6.311-0.091-9.364-0.59c-3.031-0.496-6.448-0.318-9.234-3.322c2.019-0.683,3.541-1.197,5.065-1.713
                c-0.29-1.127-1.772-1.211-1.768-2.41c4.712-3.803,16.62-4.815,21.647-0.953c4.143,3.184,8.806,3.345,13.837,4.777
                c0.605-2.498,2.411-3.467,4.781-3.652c-2.408-0.41-4.727-0.438-6.999-0.779c-5.5-0.829-6.686-2.97-4.736-8.762
                c-6.751-0.264-12.688-3.989-19.501-3.928c-1.999,0.019-4.015-0.725-5.07-2.674c-0.535-0.988-1.269-1.621-2.231-1.812
                c-3.269-0.652-4.312-3.99-6.939-5.099c0.79-3.309,3.535-2.177,4.878-2.66c0.526-2.497-1.972-3.454-2.096-5.804
                c5.736-1.227,11.354-1.389,16.964-0.391c3.651,0.648,7.059,0.327,10.424-1.113c-0.354-1.226-1.169-1.676-1.957-1.68
                c-2.578-0.01-4.879-0.824-7.097-2.025c-1.057-0.573-2.236-0.752-3.398-0.646c-5.582,0.514-10.838-1.514-16.286-2.035
                c-0.8-0.076-1.681-0.276-1.594-1.667c1.398-1.318,3.69-0.654,5.652-2.049c-2.438-1.596-5.397-2.295-6.718-4.976
                c1.103-2.765,3.578-3.347,6.251-3.657c-1.042-1.416-3.009-0.968-3.18-3.383c5.518-0.77,11.11-0.047,16.69-0.287
                c-0.057-1.601-2.739-1.992-1.554-3.807c1.218-1.864,2.875-0.979,4.487-0.56c1.976,0.513,3.998,0.917,6.8-0.093
                c-5.576-2.33-11.731-1.875-14.704-7.236c0.374-1.719,2.137-0.838,2.778-1.883c-3.218-2.016-6.675-2.753-10.474-2.512
                c-2.538,0.16-5.202-0.011-6.858-3.119c2.589-1.032,5.067-1.665,5.674-4.576c-0.833-0.158-1.659-0.26-2.455-0.477
                c-4.199-1.145-4.543-1.564-4.745-5.749c-0.04-0.831-0.018-1.667,0.029-2.499c0.227-3.963,1.647-5.139,5.423-4.086
                c3.115,0.868,6.172,1.887,9.477,1.818c-3.692-1.47-7.479-2.604-11.218-3.884c-3.996-1.368-4.09-1.685-2.766-6.13
                c1.267-1.141,2.877,0.139,4.649-0.877c-2.071-1.307-4.264-1.912-5.448-4.209c-1.211-2.353-2.266-4.246,1.098-7.232
                c-2.881,0.723-2.82-1.755-4.949-1.188c-0.72,3.766-1.439,7.778-4.802,10.658c1.976,0.83,3.621-1.193,5.132,0.332
                c0.209,3.862-0.23,4.743-3.501,4.998c-3.466,0.271-6.969,0.061-11.06,0.061c1.514,5.693-4.972,2.994-6.054,6.898
                c3.929-0.915,7.519,0.898,11.109-0.573c0.735-0.302,1.982-0.399,2.268,0.386c0.649,1.781,1.89,1.414,3.226,1.416
                c4.374,0.008,4.743,0.441,4.508,4.956c-0.051,0.975,0.089,2.017-0.799,3.055c-3.711,1.771-7.855,3.145-12.081,3.899
                c-4.155,0.742-5.148,5.298-8.798,6.405c0.045,4.277-3.516,3.972-6.031,4.291c-4.581,0.581-7.603,2.394-7.65,7.046
                c-2.473,1.74-6.48-0.611-7.256,3.8c6.375,0.755,12.569,0.079,18.654-1.759c4.322-1.307,8.791-1.857,13.222-2.587
                c1.458-0.24,3.235-0.507,3.641,1.542c0.447,2.253-1.366,2.743-3.007,3.195c-1.714,0.473-3.637-0.002-5.595,1.229
                c1.12,1.569,1.996,2.976,4.068,3.278c1.635,0.238,3.254,0.712,4.823,1.247c1.343,0.458,2.395,1.27,1.593,3.233
                c-4.302,1.686-9.043-0.591-13.456,0.764c-0.68,1.539,1.827,1.532,1.088,3.373c-2.638,1.971-6.103,2.093-9.55,2.223
                c-4.684,0.176-9.024,1.289-12.853,4.152c2.663,2.128,4.963,4.287,8.331,4.391c0.441,0.014,1.048,0.321,1.276,0.684
                c1.738,2.761,4.528,2.365,7.12,2.377C277.182,789.619,280.633,789.764,283.82,791.387z M1138.092,923.855
                c5.927,0,11.423-0.038,16.918,0.015c3.014,0.028,5.92-0.079,8.756-1.445c1.934-0.931,4.416-1.565,6.154,0.071
                c1.768,1.664,3.66,1.338,5.585,1.34c23.829,0.029,47.657,0.027,71.486-0.002c1.247-0.001,2.585,0.387,3.897-0.469
                c-2.141-4.036-7.525-2.075-10.174-5.406c1.646-2.822,4.09-2.968,6.595-2.598c2.865,0.425,5.403-0.206,8.206-0.947
                c3.746-0.991,7.254-3.267,11.482-2.655c2.147,0.312,3.849-1.164,5.478-3.034c-2.333-1.248-4.333-0.793-6.271-0.784
                c-2.662,0.011-5.344,0.286-7.98,0.043c-3.018-0.278-3.291-1.119-2.108-4.739c-3.38-1.635-7.052-2.521-10.501-4.078
                c-3.706-1.672-7.998-2.311-12.104-2.76c-4.679-0.512-7.519-3.572-11.556-6.649c3.949-1.886,7.002-3.908,10.974-4.078
                c4.634-0.198,9.181-0.726,13.776-1.862c3.584-0.887,7.291-2.287,11.098-2.07c3.002,0.172,5.175-1.058,7.721-2.83
                c-5.842-1.706-6.322-1.706-11.078-0.111c-1.742,0.585-3.465,1.102-5.368,0.767c-7.704-1.354-15.429-2.595-23.12-4.018
                c-1.35-0.25-3.056-0.161-3.871-1.676c0.128-2.804,3.822-1.14,4.198-3.501c-2.872-1.953-7.51-0.467-9.214-4.468
                c0.615-2.048,2.796-1.538,3.932-2.706c-1.811-1.512-3.754-1.323-5.502-1.73c-2.754-0.642-5.595-0.961-8.301-1.751
                c-3.117-0.91-4.59-4.712-3.443-8.175c0.764-2.305,2.562-2.768,6.34-1.529c1.422,0.466,2.768,1.163,4.193,1.615
                c1.579,0.501,3.219,1.223,4.814,1.18c4.078-0.111,7.92,0.688,11.695,2.09c0.438,0.162,0.94,0.03,1.362-0.6
                c-0.286-1.045-2.279-1.65-1.039-3.644c2.867-0.479,4.972,1.826,7.441,2.878c2.652,1.129,5.407,1.58,8.796,2.028
                c1.165-3.771,4.805-2.437,7.819-2.948c-0.756-1.219-2.389-1.29-2.222-2.973c-0.064-1.228,2.397-0.385,2.007-2.108
                c-2.518-0.866-4.989-1.845-7.532-2.569c-10.697-3.049-21.779-4.576-32.34-8.191c-2.869-0.981-5.807-1.706-7.09-4.916
                c-0.41-1.029-1.296-1.469-2.303-1.709c-1.529-0.364-3.166-0.87-2.716-2.831c0.504-2.196,2.435-0.999,3.78-1.166
                c0.418-0.052,0.593-0.299,0.479-0.999c-1.947-1.68-3.15-4.2-4.92-6.592c2.022-1.938,4.032-2.451,6.539-1.877
                c2.24,0.514,4.16,2.05,6.496,2.059c0.678-0.859,0.047-2.178,1.531-2.789c5.983,2.75,12.449,2.755,18.922,1.668
                c0.402-0.999-0.375-1.859,0.251-2.621c1.671-1.227,3.833,0.648,5.976-1.122c-4.5-2.62-8.572-5.476-13.031-7.471
                c-5.99-2.681-6.115-2.396-5.973-8.8c0.014-0.559,0.754-1.194-0.26-1.732c-1.861-0.557-3.857,0.085-5.838-0.058
                c-2.023-0.146-4.479-0.282-5.396-2.054c-1.981-3.826-5.523-4.844-9.027-6.197c-1.281-0.494-3.117-0.304-3.521-2.622
                c2.255-2.563,5.258-2.781,8.509-2.386c2.983,0.361,6.002,0.744,8.628-1.326c2.848-2.246,5.931-3.604,9.682-2.734
                c0.416,0.096,0.803-0.483,0.793-1.226c-1.895-0.816-3.891-1.6-5.814-2.532c-1.22-0.592-2.448-1.443-1.736-3.079
                c0.553-1.269,1.789-1.181,3.012-1.125c1.77,0.08,3.311,1.669,5.387,0.808c-1.676-2.775-7.26-1.074-6.477-6.521
                c-7.521,1.673-13.871,0.393-18.391-6.901c4.914-0.639,9.713,1.074,15.043-0.566c-2.575-1.994-2.76-4.156-2.129-6.232
                c-8.094-5.193-8.094-5.193-8.84-7.82c3.439-0.443,6.898-0.877,9.791-2.975c-1.649-1.612-5.05-1.207-5.32-4.279
                c0.798-2.887,4.361-1.494,5.602-4.083c-6.047-0.293-10.539-4.45-16.223-5.185c-1.199-0.155-2.535-0.989-2.143-3.006
                c2.092-1.582,4.674-0.836,7.079-1.027c1.597-0.128,3.35,0.317,3.853-2.059c0.246-1.161,2.199-0.814,2.542-2.286
                c-1.102-0.577-2.975-0.944-3.244-1.811c-0.964-3.103-3.533-3.031-5.841-3.631c-1.861-0.483-4.006-0.524-4.884-3.097
                c3.431-3.738,8.474-1.713,12.317-3.289c0.043-0.404,0.121-0.591,0.07-0.73c-1.349-3.716-4.074-5.473-7.956-5.73
                c-1.231-0.082-2.238-0.805-2.392-2.158c-0.172-1.504,0.879-1.982,2.125-2.231c0.777-0.155,1.691-0.11,1.972-1.147
                c-0.907-0.959-1.711-2.051-2.747-2.836c-1.039-0.789-2.207-1.295-3.096-2.404c-3.119-3.893-3.139-4.242,0.77-7.381
                c0.16-0.129,0.076-0.564,0.137-1.162c-5.343-0.93-4.945-5.457-5.572-9.532c-0.63,2.002-2.346,3.4-2.127,5.555
                c0.262,2.558-1.213,3.591-3.471,3.95c-1.122,0.179-2.373-0.056-3.286,0.998c-0.821,2.855,1.135,3.256,3.224,3.645
                c0.975,0.182,1.998,0.537,2.178,1.737c0.204,1.358-0.497,2.191-1.733,2.661c-0.429,0.162-0.827,0.404-1.257,0.619
                c0.949,1.411,2.973,0.847,3.773,2.235c1.053,3.423,0.091,5.323-3.218,7.232c-2.978,1.718-5.854,2.915-10.017,3.224
                c2.163,1.094,4.156,0.402,5.27,2.238c0.763,1.518-1.834,3.211-0.054,5.062c1.047,0.826,2.447,0.304,3.733,0.495
                c1.225,0.182,2.324,0.556,2.51,1.975c0.195,1.5-0.893,2.19-2.065,2.337c-4,0.499-6.088,4.208-10.397,5.239
                c4.168,1.486,8.198-0.144,11.635,3.035c-4.252,4.862-11.863,3.863-15.89,9.097c3.738,0.859,7.281-0.958,11.112,0.929
                c-2.299,5.657-8.999,5.832-12.084,10.179c5.185,0.921,10.273-1.89,15.683,0.151c-2.733,4.418-7.63,3.86-10.922,6.215
                c-3.358,2.402-6.11,5.662-10.495,7.119c1.329,2.549-0.126,4.059-3.077,5.799c3.613,0.289,5.843-0.121,8.08-0.512
                c2.949-0.516,5.893-1.112,8.864-1.433c1.77-0.19,3.777-0.278,4.36,2.288c-1.71,2.343-4.444,1.914-6.786,2.396
                c-2.117,0.435-4.596,0.257-6.097,1.872c-2.909,3.13-6.024,5.826-10.522,6.957c4.324,5.294,9.667,6.444,15.482,6.162
                c3.293-0.16,6.184,0.324,8.113,2.938c-0.151,2.63-2.808,2.47-3.565,4.047c0.224,1.193,2.062,1.504,1.061,3.879
                c-9.004,2.6-17.627,8.154-27.942,9.277c2.008,1.516,4.213,2,6.254,1.362c5.039-1.574,9.531,2.13,14.467,1.214
                c0.711-0.132,1.6,0.468,1.819,1.426c0.267,1.165-0.333,2.01-1.288,2.418c-1.52,0.649-3.099,1.221-4.706,1.6
                c-10.781,2.547-20.383,7.881-30.446,12.455c1.017,1.336,2.225,1.432,3.362,1.395c4.675-0.151,9.323-0.707,13.599-2.69
                c2.035-0.944,3.929-1.554,5.016-1.325c2.107,0.969,0.654,2.515,2.369,2.958c3.749-1.587,7.529-3.743,11.836-4.515
                c0.747-0.135,1.52-0.736,2.057-1.326c2.217-2.439,4.616-1.209,6.889-0.322c2.424,0.947,2.002,3.117,1.839,5.139
                c-0.107,1.324,0.05,2.671-0.077,3.992c-0.219,2.284-1.168,4.345-3.353,5.206c-3.854,1.521-7.741,3.265-11.777,3.942
                c-5.884,0.989-11.439,3.595-17.53,3.492c-0.412-0.006-0.832,0.492-1.252,0.759c0.09,1.782,2.859,2.003,2.092,4.767
                c-4.919,3.496-11.271,4.666-18.092,6.422c2.774,2.188,1.238,4.223,0.164,6.272c9.574,0.876,18.636,2.584,27.722-0.177
                c2.031-0.617,4.302-0.628,6.333,0.492c0.43,0.237,0.848,0.531,0.809,1.236c0.179,1.559-2.343,1.805-1.945,3.779
                c5.053-0.33,9.386,2.062,14.617,3.317c-2.088,2.548-4.407,3.024-6.533,3.805c-3.123,1.146-6.493,1.851-9.117,3.966
                c-3.07,2.474-6.701,3.539-10.203,5.066c-4.371,1.906-9.945,1.502-12.912,6.374c-0.316,0.52-1.529,0.483-2.32,0.726
                c-1.062,0.326-2.324,0.219-2.863,1.055c7.874,3.712,15.869-1.068,23.719-0.473c0.347,1.492-0.735,2.068-0.004,3.232
                c2.614,0,5.424-0.028,8.232,0.014c1.18,0.018,2.441,0.156,2.753,1.602c0.366,1.693-0.796,2.379-2.147,2.832
                c-0.629,0.211-1.294,0.318-1.922,0.533c-5.694,1.95-12.226,1.354-17.154,5.712c-0.68,0.602-1.857,0.729-2.834,0.893
                c-8.352,1.403-16.319,4.81-24.951,4.762c-0.954-0.005-1.956,0.166-2.684,1.132c0.529,0.122,0.997,0.269,1.477,0.332
                c1.046,0.14,2.062,0.396,2.117,1.658c0.049,1.133-0.719,1.946-1.734,2.173c-2.43,0.542-3.914,3.047-6.68,2.808
                c4.258,1.413,10.349-1.092,11.091,6.155C1137.544,919.284,1137.544,919.284,1138.092,923.855z M682.272,596.431
                c-1.841,1.329-2.188,3.698-3.642,5.293c-2.039,2.24-3.688,2.143-5.573-0.409c-0.39-0.527-0.551-1.197-1.79-1.359
                c-1.672,0.551-4.18-1.09-5.809-0.07c-4.963,3.107-8.663,0.749-12.523-2.017c-1.828,0.483-2.815,2.239-4.439,3.071
                c-1.539,0.789-3.35,1.653-4.665,0.337c-3.067-3.069-7.703-2.645-10.512-5.526c-1.588,0.93-1.135,2.337-1.694,3.312
                c-0.522,0.911-1.222,1.762-2.312,1.633c-3.664-0.438-7.835,0.507-10.108-3.623c-3.585,2.142-7.356-1.189-10.871,0.827
                c-0.724,0.415-1.665-0.865-2.253-1.687c-0.655-0.916-0.955-2.186-2.526-2.377c-1.519,0.955-3.5,1.054-4.944,2.782
                c-1.604,1.919-2.958,1.57-4.439-0.36c-1-1.305-1.01-3.213-2.692-4.299c-2.087,1.109-4.155,2.298-6.197,3.652
                c-2.642,1.751-4.25-2.099-6.799-1.904c-3.007,0.23-5.35-1.267-7.229-3.268c-1.932,0.957-2.231,3.533-4.547,3.23
                c-2.081-0.271-2.761-2.761-4.69-3.348c-2.532,5.009-2.484,4.797-7.781,3.604c-2.205-0.497-4.595-1.924-7.054,0.248
                c-2.771,2.448-3.388,2.392-5.625-0.305c-1.482-1.787-2.978-2.402-5.16-2.071c-1.476,0.225-2.99,0.217-4.488,0.258
                c-1.287,0.033-2.075,0.604-2.758,1.735c-2.538,4.204-2.834,4.208-6.342,0.964c-0.472-0.437-1.015-0.795-1.94-1.509
                c-2.21,2.779-4.799,3.399-7.624,0.884c-0.635-0.566-1.657-0.745-2.351-0.234c-2.953,2.174-5.646,0.714-8.188-0.605
                c-3.908,3.379-3.908,3.379-9.226,0.471c-1.632,0.975-3.654,1.351-4.967,3.043c-1.238,1.598-2.804,1.614-4.403,0.514
                c-0.804-0.553-1.323-1.537-2.623-1.596c-1.456,0.193-2.766,2.136-4.466,1.27c-2.961-1.508-5.163-0.148-8.101,1.437
                c6.163,3.034,10.76,7.751,17.365,8.602c0.633,0.081,1.235,0.479,1.829,0.774c6.068,3.028,12.121,6.158,18.71,7.863
                c4.601,1.189,8.823,2.876,12.493,5.949c2.33,1.951,4.894,3.381,8.175,3.07c2.403-0.227,4.492,0.981,6.631,1.936
                c5.132,2.289,10.312,4.473,15.229,6.594c1.85-1.045,1.527-3.421,3.656-3.693c2.098-0.27,2.837,2.275,4.908,2.119
                c1.523-1.502,1.289-3.688,2.017-5.498c0.781-1.944,0.897-4.311,3.76-5.119c2.176,1.468,2.104,5.473,5.671,5.601
                c1.719-1.12,1.307-4.302,3.854-4.44c4.013,0.674,3.441,5.271,6.403,6.885c0.396-0.688,0.732-1.25,1.048-1.823
                c1.774-3.214,2.933-3.368,5.472-0.64c0.994,1.068,1.499,2.676,3.316,2.806c1.024-0.243,1.581-1.077,2.176-1.884
                c3.537-4.797,3.967-4.764,8.613-1.512c3.201,2.24,6.67,4.099,10.141,6.201c0.765-1.033,1.205-1.729,1.743-2.336
                c2.365-2.672,4.542-2.396,6.107,0.772c0.292,0.591,0.448,1.25,0.658,1.88c1.504,4.52,1.702,4.625,6.434,3.365
                c0.482-0.128,0.962-0.271,1.437-0.426c3.245-1.057,5.883-0.459,7.712,2.67c0.458,0.783,0.92,1.869,2.525,1.299
                c0.278-1.292,0.606-2.732,0.896-4.181c0.318-1.59,1.023-2.958,2.792-3.013c1.496-0.046,2.384,1.143,2.864,2.432
                c0.579,1.555,0.98,3.176,1.435,4.775c0.405,1.423,0.564,2.973,2.096,3.803c1.383,0.369,2.566-0.527,3.85-0.652
                c3.512-0.342,5.134-2.463,5.975-5.636c0.509-1.921,1.186-3.829,2.041-5.622c1.813-3.799,4.053-4.056,6.736-0.873
                c1.031,1.225,1.517,3.063,3.525,3.037c1.605-0.567,2.264-1.998,3.262-3.092c3.148-3.45,5.456-3.053,7.488,1.243
                c2.267,4.789,2.267,4.789,7.289,3.899c3.719-0.658,4.642-0.051,5.98,3.421c0.741,1.921,0.414,4.341,2.535,5.871
                c1.366-0.729,1.713-2.013,2.258-3.202c1.127-2.46,1.257-6.488,4.723-6.209c3.561,0.287,2.922,4.215,3.484,6.883
                c0.32,1.517,0.369,3.184,1.654,4.795c2.129-2.467,2.268-5.193,3.037-7.646c0.797-2.537,1.388-5.141,2.229-7.66
                c0.428-1.281,1.292-2.436,2.872-2.353c1.504,0.078,2.335,1.158,2.869,2.447c0.382,0.922,0.613,1.908,1.019,2.818
                c1.356,3.043,4.599,2.929,7.075,3.986c1.711,0.73,2.592-0.997,3.46-2.209c0.927-1.294,2.085-2.226,3.771-1.917
                c1.973,0.361,2.528,1.949,2.875,3.652c0.133,0.652,0.391,1.302,0.398,1.955c0.044,4.104,2.695,6.158,6.08,8.271
                c0.359-1.602,0.508-2.76,0.885-3.837c0.708-2.019,0.174-5.479,3.526-4.991c2.948,0.43,2.351,3.603,2.573,5.815
                c0.273,2.716-0.107,5.513,0.808,8.405c2.896-2.682,2.896-2.682,9.089-3.242c2.842-4.222,3.717-4.234,7.518-0.068
                c1.504-1.236,0.928-3.086,1.373-4.621c0.416-1.432,0.613-2.949,1.2-4.303c0.577-1.328,2.267-2.451,3.142-1.232
                c1.9,2.645,5.572,4.19,5.328,8.306c-0.096,1.626-0.576,3.547,1.053,5.062c3.787-8.206,4.343-6.582,8.553-3.967
                c1.693-1.74,0.871-4.156,1.64-6.135c0.45-1.16,1.226-2.014,2.569-2.006c1.403,0.01,2.1,0.934,2.312,2.186
                c0.222,1.307,0.259,2.645,0.432,3.963c0.181,1.375-0.364,2.93,0.895,4.17c1.771-0.887,1.085-2.729,1.774-3.951
                c0.604-1.07,1.412-1.9,2.725-1.871c1.385,0.03,1.962,1.008,2.367,2.191c0.479,1.393-0.064,3.04,1.071,4.282
                c1.582-0.596,1.427-2.041,1.644-3.286c0.372-2.133,0.613-4.296,1.111-6.398c0.393-1.658,1.197-3.234,3.194-3.373
                c1.845-0.127,2.449,1.355,3.144,2.729c0.543,1.074,0.344,2.789,2.178,2.877c0.174-0.1,0.385-0.147,0.453-0.271
                c2.148-3.981,2.475-8.949,6.68-11.86c0.76-0.526,0.725-2.153,1.14-3.24c0.353-0.923,0.727-1.863,1.267-2.679
                c0.682-1.028,1.719-1.641,3-1.296c1.082,0.292,1.389,1.267,1.733,2.242c0.269,0.761-0.466,1.972,0.952,2.36
                c0.955-2.664,0.281-5.377,0.667-7.996c0.244-1.654,0.399-3.844,2.436-3.854c4.575-0.019,4.932-3.02,5.158-6.338
                c0.058-0.834,0.146-1.663,1.206-2.13c0.952-0.39,1.203,1.652,2.469,0.913c0.139-0.425,0.508-0.941,0.41-1.344
                c-0.835-3.408-6.145-5.229-8.764-2.998c-0.879,0.748-1.477,1.832-2.37,2.557c-2.341,1.895-4.267,1.252-5.157-1.707
                c-0.327-1.086-0.105-2.381-1.428-2.993c-1.66,0.274-2.261,1.83-3.338,2.818c-2.564,2.351-4.266,2.381-5.942-0.579
                c-1.04-1.837-2.414-2.174-4.036-2.271c-3.307-0.201-5.699-1.514-6.861-4.769c-0.275-0.774-0.865-1.416-1.816-1.465
                c-0.754,0.454-1.09,1.199-1.479,1.948c-1.96,3.77-2.283,4.049-6.092,2.65c-2.226-0.817-3.628-0.331-5.37,1.097
                c-3.338,2.736-4.858,2.205-6.711-1.792c-0.519-1.12-0.48-2.713-2.377-2.91c-0.771,1.24-1.508,2.538-2.354,3.76
                c-1.053,1.523-2.608,2.404-4.308,1.498c-3.975-2.12-8.02-1.607-12.13-0.801c-2.317,0.455-4.286,0.053-5.655-2.101
                c-0.788-1.239-1.548-2.634-3.442-2.155c-0.87,1.643-1.568,3.361-2.612,4.836c-1.381,1.947-3.104,2.105-4.516,0.191
                c-2.221-3.008-5.674-4.15-8.299-6.721c-2.414,2.762-4.502,3.053-7.715,1.578c-3.202-1.469-6.996-1.438-10.025,1.755
                c-3.375,3.558-5.414,2.908-6.996-1.774c-0.575-1.705-0.336-3.859-2.318-5.135c-0.466,0.709-0.791,1.084-0.986,1.518
                c-1.904,4.236-1.92,4.305-6.254,2.865c-1.475-0.49-2.932-0.946-4.369-0.382c-3.007,1.182-5.438,0.304-7.246-1.982
                C686.51,599.499,686.443,599.499,682.272,596.431z M926.056,847.894c-2.045,2.658-2.251,5.744-2.909,8.601
                c-1.273,5.523-3.562,10.279-7.991,14.042c-2.887,2.452-5.524,5.265-7.371,8.634c3.93,3.404,4.187,4.685,1.829,8.975
                c-1.537,2.799-3.635,5.16-5.848,7.445c-1.112,1.148-2.748,1.896-3.267,3.908c1.774,0.454,3.315-0.309,4.885-0.414
                c2.321-0.156,5.021-0.717,6.374,1.658c1.416,2.486-0.63,4.443-2.166,6.133c-4.258,4.684-8.666,9.233-12.97,13.877
                c-0.716,0.771-2.072,1.289-1.438,3.104c1.727,0,3.533,0,5.34,0c33.667,0,67.334-0.001,101,0c10,0,20.001-0.064,30,0.047
                c2.394,0.027,4.048-0.641,5.471-2.618c2.432-3.378,5.153-6.546,7.678-9.858c1.395-1.83,2.854-3.671,3.09-5.865
                c-0.549-1.52-1.716-1.877-2.766-2.348c-4.758-2.132-5.156-3.152-2.521-7.733c1.572-2.734,3.402-5.321,5.109-7.964
                c-0.884-1.111-2.852-0.221-3.305-2.603c0.525-2.455,2.457-4.781,4.1-7.236c2.591-3.876,5.541-7.528,7.527-11.777
                c1.708-3.655,1.045-4.747-2.985-5.159c-1.488-0.152-2.997-0.088-4.494-0.171c-1.313-0.073-2.692-0.05-3.671-1.838
                c1.663-3.95,6.064-6.335,7.458-10.661c1.367-4.244,2.632-8.521,3.92-12.711c-0.552-1.107-1.454-1.539-2.499-1.512
                c-2.982,0.076-4.567-1.602-5.517-4.178c-0.287-0.779-0.629-1.539-0.976-2.295c-0.447-0.971-1.173-1.621-2.28-1.679
                c-1.413-0.073-2.142,0.886-2.535,2.013c-0.769,2.207-1.102,4.514-1.403,6.836c-0.471,3.617-0.639,7.332-2.385,10.657
                c-0.824,1.572-2.148,2.694-4.02,2.339c-1.631-0.311-2.314-1.662-2.738-3.229c-0.561-2.071-0.201-4.352-1.637-6.647
                c-2.238,0.283-4.385-0.373-5.783-2.584c-1.238-1.959-1.563-4.413-3.266-6.081c-5.297-0.394-5.529-0.661-7.285-5.702
                c-0.782-2.246-0.763-4.986-3.183-6.435c-2.425,1.013-3.068,3.14-3.835,5.144c-0.951,2.486-1.789,5.017-2.748,7.5
                c-0.795,2.057-1.826,4.193-4.055,4.803c-3.086,0.846-3.592,3.18-3.959,5.678c-0.701,4.775-1.234,9.576-1.969,14.347
                c-0.251,1.625-0.8,3.227-1.396,4.769c-0.509,1.312-1.549,2.243-3.056,2.164c-1.282-0.068-2.044-0.984-2.567-2.102
                c-0.643-1.372-0.973-2.816-1.205-4.311c-1.207-7.756-1.283-15.602-1.9-23.404c-0.215-2.738-0.317-5.501-1.799-8.055
                c-2.135,0.89-2.634,2.504-3.047,4.096c-0.689,2.656-1.685,5.055-4.246,6.477c-1.812,1.007-2.186,2.855-2.514,4.663
                c-0.268,1.472-0.268,2.997-0.587,4.454c-0.505,2.305-1.97,3.787-4.284,4.406c-2.242,0.6-3.359-0.844-4.471-2.372
                c-0.281-0.387-0.703-0.671-1.015-0.959c-4.808,0.604-4.808,0.604-6.994-3.905c-4.396-0.814-4.865-0.916-6.321-2.461
                c-1.023-1.084-1.801-2.395-2.778-3.526c-2.261-2.619-4.335-2.252-5.358,1.062c-0.484,1.564-0.495,3.277-0.695,4.927
                c-0.36,2.975-0.542,5.982-1.104,8.918c-0.431,2.246-1.874,3.974-4.249,4.423c-2.195,0.416-3.257-1.248-4.279-2.805
                C929.28,849.399,928.477,847.869,926.056,847.894z M4.833,643.619c-0.367,0.109-0.733,0.22-1.1,0.33
                c0,39.646-0.001,79.291,0.009,118.938c0,0.656,0.131,1.314,0.233,1.967c0.018,0.111,0.191,0.226,0.318,0.286
                c0.602,0.287,0.206-0.486,0.791,0.788c0.982-4.584,5.914-7.617,2.599-12.666c-0.143-0.217-0.051-0.688,0.082-0.968
                c1.879-3.95,3.96-7.777,6.879-12.175c2.757,5.533,5.304,9.963,6.929,14.85c0.317,0.955,0.56,1.965-0.245,2.811
                c-2.421,2.543-0.169,3.48,1.438,5.01c1.691-2.257,2.149-4.56,2.449-6.84c0.553-4.203,3.199-8.584-0.872-12.521
                c-0.359-0.348-0.282-1.298-0.188-1.937c0.509-3.473,1.232-6.896,3.093-9.945c0.616-1.009,1.369-1.937,3.097-1.469
                c1.815,2.173,1.686,5.437,3.453,7.91c1.694,2.372,3.485,4.832,3.967,7.43c-0.588,1.592-2.023,1.144-2.654,2.233
                c1.237,2.424,2.46,4.971,3.82,7.442c1.344,2.443,1.698,4.63-1.221,6.036c1.31,3.897,3.868,6.602,5.833,9.67
                c1.322,2.064,4.004,3.824,1.805,6.938c-0.273,0.387,0.431,1.524,0.77,2.273c0.763,1.686,1.367,3.375,1.146,5.287
                c-0.283,2.444-3.058,2.17-4.167,3.586c0.847,2.404,2.594,3.73,4.229,5.126c4.747,4.052,7.024,3.372,9.681-2.185
                c1.557-3.256,0.203-5.202-2.165-6.881c1.624-4.307,5.218-6.9,7.071-10.85c-2.161-0.727-2.837-2.146-1.609-3.9
                c2.341-3.346,4.717-6.693,8.08-9.702c3.718,4.47,6.091,9.312,6.514,14.607c0.241,3.021,0.585,5.754,1.764,8.538
                c0.952,2.252,2.896,4.826-0.505,6.976c-0.662,0.418-0.333,1.576-0.095,2.368c0.577,1.916,1.143,3.887,2.752,5.228
                c1.892,1.575,2.955,3.509,3.296,5.954c0.313,2.244,1.395,4.025,3.827,4.951c3.913-2.494,4.78-6.574,5.914-10.617
                c-1.075-1.149-2.821-1.737-2.227-3.954c1.052-3.916,2.414-7.656,5.112-10.718c2.157-2.447,4.003-2.259,5.364,0.678
                c1.687,3.641,3.681,7.095,5.693,10.544c1.339,2.296,2.07,4.479-0.442,6.325c2.251,3.613,4.36,6.814,6.276,10.127
                c1.575,2.723,1.474,2.781-0.604,5.428c3.35,0,6.61,0,9.896,0c-0.046-1.355-1.737-2.066-0.433-3.689
                c5.286-1.506,10.909-3.109,16.782-4.782c-1.512-1.675-3.33-1.497-4.723-2.513c-4.072-2.967-8.636-5.053-13.751-5.519
                c-1.306-0.12-2.649,0.247-3.976,0.245c-1.413-0.003-2.666-0.383-2.837-2.596c1.559-0.514,2.593-1.838,3.663-3.303
                c1.178-1.613,3.572-1.551,5.545-1.785c1.818-0.217,3.657-0.245,5.481-0.411c2.322-0.212,4.656-0.376,6.955-0.747
                c2.556-0.412,5.237-0.58,7.996-2.896c-3.857,0.942-5.912-3.161-9.282-1.624c-0.04,0.018-0.276-0.226-0.301-0.368
                c-0.513-2.894-2.805-1.802-4.388-1.788c-3.661,0.033-7.318,0.393-10.98,0.541c-1.42,0.057-3.016,0.279-4.072-2.184
                c7.165-4.223,16.021-5.952,21.315-13.36c-2.294-0.645-3.987,0.308-5.694,0.826c-4.459,1.354-8.847,2.94-13.298,4.321
                c-3.813,1.184-7.635,2.4-11.529,3.246c-2.983,0.647-6.143,1.383-9.533-0.766c4.5-2.463,7.387-6.074,11.544-9.533
                c-3.907-0.719-7.284-0.468-7.878-4.212c-3.426-0.646-7.039,0.92-9.468-2.195c1.126-2.16,3.353-1.611,4.876-2.493
                c0.281-1.148-1.551-1.963,0.133-3.833c13.249-1.673,27.286-3.093,40.619-8.215c-1.593-0.823-3.889,0.132-4.79-1.257
                c-1.791-2.76-4.102-1.789-6.167-1.217c-4.291,1.191-8.547,0.419-12.816,0.271c-1.324-0.047-2.746-0.291-2.589-2.209
                c0.969-1.525,2.622-1.268,4.024-1.617c1.266-0.315,2.702-0.133,3.653-1.324c1.373-1.72,3.391-1.902,5.345-2.362
                c1.547-0.363,3.343-0.297,4.505-1.748c-0.31-1.886-1.535-3.277-2.962-3.841c-3.24-1.278-5.068-3.545-5.778-6.842
                c-4.249-0.633-8.521,0.358-13.89-2.373c4.436-0.486,2.875-2.915,2.893-4.229c1.226-1.508,2.963-1.199,3.581-2.623
                c-0.587-2.685-2.851-4.021-4.59-5.748c-1.861-1.848-1.007-5.307-3.869-6.644c-1.33,0.505-1.884,1.491-2.143,2.658
                c-0.433,1.95-0.779,3.92-1.191,5.875c-0.911,4.319-2.321,8.276-6.302,10.92c-2.522,1.675-3.25,4.505-3.611,7.404
                c-0.24,1.927,0.087,4.058-1.902,5.7c-2.994-0.033-3.646-2.393-3.865-4.686c-0.299-3.141-0.229-6.322-0.208-9.486
                c0.028-4.33,0.437-8.703-3.552-11.881c-0.692-0.551-0.886-1.761-1.245-2.691c-0.485-1.26-0.958-2.5-2.766-3.02
                c-0.546,1.136-0.843,3.002-2.798,2.478c-1.835-0.492-2.709-1.895-2.784-3.714c-0.082-1.994-0.025-3.996,0.014-5.994
                c0.043-2.221-0.141-4.583-1.682-6.123c-2.688-2.686-4.784-5.439-4.71-9.46c0.018-0.962-0.671-1.878-1.488-2.52
                c-2.287-1.796-2.8-4.361-3.339-7.026c-0.403-1.99-0.247-4.418-2.652-5.566c-0.989,0.262-1.289,1.074-1.629,1.795
                c-1.383,2.94-1.362,7.283-6.556,6.387c-0.27-0.047-0.699,1.005-1.097,1.511c-2.173,2.763-4.514,2.448-5.856-0.783
                c-0.729-1.756-0.481-3.6-0.507-5.429c-0.024-1.689,0.428-3.627-1.485-4.672c-1.098,0.524-1.134,1.435-1.403,2.188
                c-0.543,1.523-0.563,3.621-2.727,3.688c-2.347,0.074-3.396-1.775-4.161-3.712c-0.183-0.462-0.361-0.944-0.425-1.433
                c-0.785-6.05-5.932-8.715-9.305-13.139c-1.609,2.68-0.618,5.756-3.562,7.223c-3.956-1.402-3.465-6.031-5.903-8.53
                C10.522,647.203,6.423,646.2,4.833,643.619z M760.262,854.219c0.342,2.126-0.848,3.216-1.551,4.494
                c-0.723,1.311-1.586,2.557-2.186,3.919c-1.577,3.589-1.079,5.418,1.947,7.762c0.394,0.305,0.854,0.524,1.24,0.838
                c3.42,2.771,3.364,5.378-0.196,8.084c-2.149,1.634-4.713,2.432-7.15,3.501c-3.431,1.505-6.153,0.481-8.519-2.045
                c-3.486-3.723-5.707-8.149-7.084-13.033c-0.455-1.612-0.793-3.215-0.625-4.917c0.2-2.021,2.109-2.626,3.502-3.671
                c-2.005-3.396-3.799-6.391-5.549-9.411c-1.668-2.879-3.363-5.748-4.895-8.7c-0.489-0.943-1.157-2.009-0.328-3.271
                c0.845-1.285,2.242,0.633,3.189-0.944c-3.203-5.226-6.467-10.552-9.968-16.265c-3.929,4.232-5.77,8.938-7.075,13.907
                c-0.158,0.602-0.217,1.621,0.125,1.899c2.856,2.33,0.771,4.443-0.188,6.58c-1.404,3.134-3.482,6.01-3.886,9.482
                c5.839,2.971,5.975,3.962,1.425,8.629c-0.232,0.239-0.469,0.473-0.713,0.701c-3.38,3.163-5.849,6.782-6.17,11.603
                c-0.147,2.218-1.12,4.073-2.736,5.714c-1.831,1.859-3.642,3.83-4.409,6.572c2.499,0.833,5.096,0.791,7.233,2.242
                c2.848,1.936,3.091,3.71,0.424,5.895c-2.511,2.055-5.258,3.819-7.938,5.738c3.325,2.945,3.406,3.48,0.879,6.457
                c-1.952,2.297-4.346,4.117-6.676,6.006c-1.117,0.905-2.623,1.486-3.076,3.393c1.599,0.576,3.633,0.707,3.758,3.09
                c0.094,1.787-1.646,3.248-1.005,5.165c39.636,0,79.068,0,118.347,0c1.817-2.909,3.49-5.587,5.157-8.255
                c-0.179-1.275-2.202-1.042-1.934-3.283c2.299-5.979,8.545-10.64,10.075-17.896c-1.551-1.685-4.703-2.08-3.629-5.34
                c0.738-2.24,2.518-3.819,3.718-5.818c-2.785-3.852-5.576-7.367-8.733-10.562c-2.839-2.871-4.066-6.055-2.445-9.953
                c0.941-2.262,0.498-4.028-1.173-5.895c-2.899-3.238-5.081-6.992-6.722-11.041c-0.381-0.937-0.732-1.851-0.568-2.892
                c0.219-1.392,2.201-0.651,2.475-2.154c-2.226-4.696-4.488-9.471-6.92-14.601c-2.406,4.444-5.376,8.04-7.066,12.403
                c-0.64,1.653-0.844,2.853,0.589,4.447c1.966,2.19,1.69,4.446,0.005,6.789c-2.396,3.327-5.485,3.172-7.217-0.493
                c-1.926-4.08-3.199-8.398-3.904-12.857c-0.204-1.291-0.623-2.938,0.394-3.85c2.657-2.383,1.525-5.189,1.236-7.898
                c-0.212-1.979-1.187-4.016-0.901-5.887c0.91-5.957-1.914-10.963-3.594-16.266c-0.31-0.979-0.482-2.263-2.214-2.446
                c-0.497,6.237-6.091,9.616-8.458,14.854c0.631,0.274,0.917,0.495,1.215,0.514c4.221,0.258,4.698,1,2.895,4.66
                c-0.439,0.894-1.018,1.719-1.521,2.582c-1.924,3.293-4.461,6.234-5.736,9.89c4.48,4.265,4.727,5.988,1.369,10.441
                c-1,1.325-2.138,2.556-3.285,3.761c-1.723,1.809-3.527,3.537-5.25,5.347c-0.295,0.31-0.35,0.846-0.517,1.281
                C758.607,853.557,759.359,853.858,760.262,854.219z M157.903,141.073c-0.306,13.761-0.761,26.405-0.777,39.049
                c-0.006,4.523-1.492,7.932-4.485,11.163c-8.151,8.8-8.063,8.867-7.486,20.845c0.09,1.873,0.344,3.703,1.494,5.192
                c3.251,4.21,3.79,9.055,3.81,14.161c0.111,28.48,0.458,56.96,0.393,85.44c-0.017,7.305,1.039,14.704-0.071,21.896
                c-2.719,17.619-0.88,35.218-0.664,52.83c0.02,1.631,0.347,2.9,2.048,3.571c2.269,0.895,3.373,2.89,3.488,5.097
                c0.234,4.477,4.109,6.237,6.713,8.515c1.698,1.486,4.549,2.578,7.15,3.251c2.024,0.524,3.672,1.776,4.401,3.76
                c0.922,2.511,2.649,4.119,4.872,5.434c2.585,1.529,4.205,3.749,4.242,6.897c0.017,1.388,0.297,2.761,1.598,3.453
                c1.791,0.952,2.619,2.478,3.086,4.31c0.268,1.047,0.976,1.612,1.878,2.168c2.264,1.395,4.426,2.954,6.646,4.423
                c1.921,1.271,11.298,4.539,13.18,4.619c0.516-0.963,0.16-1.952,0.073-2.931c-0.806-9.118-1.808-18.2-3.26-27.255
                c-1.474-9.193-2.1-18.52-3.303-27.761c-0.662-5.084-0.898-10.29-3.397-14.998c-2.652-4.994-5.077-10.079-7.025-15.423
                c-2.426-6.654-4.217-7.498-11.211-6.615c-0.307,0.039-0.637-0.099-1.38-0.229c-3.001-8.947-5.866-18.08-5.407-27.913
                c0.155-3.326,0.333-6.654,0.344-9.981c0.065-19.654-0.086-39.305-0.506-58.956c-0.295-13.814-0.976-27.65,0.641-41.429
                c2.194-18.694,0.842-37.226-2.312-55.684c-0.3-1.753,0.137-3.8-1.333-5.203C167.293,148.899,163.189,145.084,157.903,141.073z
                M273.706,382.204c3.211,1.69,4.363,3.792,5.894,5.492c1.441,1.601,2.538,3.829,4.677,4.359c2.9,0.719,3.587,2.604,3.788,5.093
                c0.558,6.894,2.581,13.33,5.634,19.541c2.42,4.925,4.555,10.011,6.482,15.151c2.819,7.516,7.426,13.714,13.131,19.224
                c0.901,0.87,2.281,1.482,2.288,3.043c-1.06,1.395-2.991,0.337-4.044,1.56c-0.336,0.865,0.097,1.632,0.349,2.398
                c3.23,9.809,6.638,19.556,11.227,28.831c1.786,3.609,3.05,7.352,4.03,11.248c2,7.951,2.676,16.087,3.675,24.181
                c0.16,1.296-0.014,2.692,1.414,3.507c2.12,1.211,4.206,2.48,6.562,3.877c0.565-2.327,0.219-4.293,0.046-6.217
                c-1.77-19.738-3.084-39.527-6.111-59.13c-1.371-8.878-2.489-17.803-4.455-26.585c-0.37-1.652-0.65-3.325-2.084-4.399
                c-4.344-3.251-6.414-7.984-8.407-12.764c-1.219-2.922-2.406-5.856-4.139-8.525c-5.998-9.24-10.941-19.062-15.886-28.88
                c-1.866-3.705-3.784-7.359-5.271-11.279c-5.904-15.553-10.938-31.477-19.009-46.137c-0.984-1.787-1.471-3.635-1.548-5.698
                c-0.684-18.315-1.424-36.628-2.185-54.94c-0.133-3.199,0.038-6.325,1.268-9.325c1.249-3.048,1.208-6.127,0.456-9.31
                c-0.765-3.238-1.333-6.526-1.877-9.811c-0.439-2.647-1.017-5.175-3.612-6.642c2.705-11.096,1.883-22.039,1.334-33.048
                c-0.301-6.035-1.533-11.037-6.475-14.775c-2.315-1.751-2.904-4.164-2.84-7.043c0.164-7.329-0.078-14.667,0.094-21.996
                c0.153-6.529-0.45-12.979-1.393-19.408c-0.823-5.609-1.717-11.197-0.398-16.803c-3.665-4.693-8.491-2.786-13.538-2.884
                c1.733,5.239,3.118,10.26,6.411,14.266c1.76,2.142,1.925,4.085,1.378,6.421c-0.417,1.782-0.923,3.544-1.423,5.305
                c-1.545,5.443-3.163,10.868-4.644,16.329c-1.055,3.893-0.625,7.728,1.801,10.965c1.443,1.926,1.594,3.839,1.612,6.048
                c0.068,8.068-1.335,15.883-3.363,23.664c-1.115,4.278-2.527,8.218-5.71,11.421c-2.751,2.768-3.652,6.18-3.339,10.079
                c0.506,6.309,0.823,12.634,1.136,18.957c0.065,1.321,0.888,3.021-1.128,3.771c-1.212-0.705-1.25-1.929-1.536-3.045
                c-1.074-4.193-1.819-8.512-3.333-12.541c-2.274-6.05-2.195-12-0.741-18.141c1.037-4.379,1.84-8.815,1.227-13.364
                c-0.308-2.288-0.638-4.558-3.074-6.403c-1.216,5.369-1.609,10.5-2.095,15.597c-0.552,5.802-0.751,11.638-1.262,17.445
                c-0.162,1.838,0.147,4.004-1.704,5.314c-1.989-0.803-1.851-2.435-2.076-3.86c-0.812-5.129-0.783-10.278-0.521-15.456
                c0.431-8.5,1.961-16.872,2.766-25.323c0.48-5.046-2.329-10.974-6.025-13.157c-0.805,4.803-1.752,9.534-2.366,14.307
                c-1.234,9.607-0.948,19.296-1.242,28.952c-0.034,1.137,0.207,2.329,0.552,3.422c1.254,3.968,2.614,7.903,3.917,11.856
                c0.421,1.279,0.961,2.327,2.205,3.153c5.542,3.683,8.32,9.285,10.581,15.323c1.414,3.777,0.442,7.592,0.888,11.363
                c1.351,11.41,2.826,22.809,3.951,34.241c0.449,4.565,1.332,8.901,3.596,12.865c2.584,4.524,4.352,9.348,5.735,14.336
                c0.31,1.117,0.459,2.296,1.886,3.306c-0.959-6.488-0.351-12.817-2.558-18.863c-3.223-8.833-4.826-17.963-4.857-27.391
                c-0.011-3.32-0.271-6.683-0.861-9.945c-0.662-3.658-0.806-7.276-0.418-10.937c1.349-12.753,2.657-25.512,4.126-38.251
                c0.438-3.792,1.2-7.566,2.12-11.273c0.437-1.76,0.804-4.01,3.215-4.264c0.853,0.441,1.247,0.982,1.311,1.673
                c0.728,7.763-1.854,15.153-2.679,23.109c3.816,1.086,3.191,4.583,3.457,7.404c2.201,23.381,4.247,46.776,6.356,70.166
                c0.217,2.401,0.13,4.877,1.526,7.844c0.65-2.249,1.184-3.802,1.543-5.395c1.283-5.683,2.547-11.372,3.73-17.076
                c0.445-2.149,1.776-2.738,3.642-2.271c1.724,0.432,1.631,1.86,1.34,3.246c-0.823,3.909-1.725,7.803-2.467,11.727
                c-1.606,8.502-3.615,16.975-2.115,25.743c0.758,4.431,1.163,8.937,2.17,13.307c1.188,5.151,0.207,9.614-3.281,13.465
                c-2.331,2.573-2.893,5.535-2.614,8.805c0.198,2.324,0.261,4.678,0.687,6.961c0.822,4.404,0.58,9.294,2.981,13.056
                c4.007,6.276,9.04,11.853,14.807,18.08C277.45,393.753,272.926,389.044,273.706,382.204z M644.211,856.278
                c-1.799-5.565-3.488-10.781-5.171-15.999c-1.667-5.174-1.779-10.668-2.913-17.021c-2.083,3.178-4.671,4.607-3.941,8.119
                c0.245,1.178-1.046,2.714-1.729,4.038c-1.137,2.206-2.755,4.16-3.498,6.546c3.411,4.084,1.779,9.948-3.876,14.135
                c-4.75,3.518-8.585,2.209-12.849-4.352c-0.386-1.484,1.665-1.588,1.578-2.861c-3.286-7.247-3.286-7.247-1.605-10.172
                c-2.099-3.219-4.224-6.479-6.863-10.523c-2.161,6.41-7.99,8.789-10.827,13.627c1.113,1.186,3.052,0.604,3.302,3.044
                c-1.85,4.519-6.414,7.767-9.232,12.606c3.168,0.967,2.854,2.701,1.306,4.854c-2.195,3.052-5.277,5.469-6.795,9.065
                c3.921,1.685,4.672,2.609,3.531,5.378c-1.799,4.364-4.301,8.245-8.906,9.97c-2.658-1-3.121-3.131-3.604-5.268
                c-0.63-2.79,1.326-4.54,2.934-6.362c-2.235-3.723-4.297-7.138-6.338-10.565c-0.596-1.001-1.11-2.015-0.976-3.274
                c0.116-1.089,1.77-1.003,1.6-2.212c-1.087-2.097-3.287-3.4-3.928-5.832c0.699-1,2.221-1.664,1.43-3.321
                c-1.956-4.098-3.884-8.209-5.977-12.64c-2.578,3.783-2.96,7.9-4.654,11.488c-1.769,3.748-5.358,6.33-6.775,10.298
                c4.317,2.503,4.698,3.692,2.445,7.95c-1.957,3.697-4.596,6.929-7.198,10.184c-1.413,1.768-3.073,3.453-3.186,5.938
                c4.897,1.188,5.349,2.088,3.176,6.384c-0.824,1.63-1.803,3.192-2.834,4.702c-1.494,2.186-3.099,4.295-4.652,6.429
                c3.225,2.51,3.39,3.021,1.482,6.414c-1.14,2.028-2.561,3.896-3.765,5.892c-2.026,3.358-3.98,6.76-6.232,10.601
                c43.86,0,86.852,0,130.518,0c-3.047-6.143-5.836-11.769-8.628-17.394c-1.63-3.285-3.101-6.63-4.054-10.188
                c-0.367-1.372-0.432-2.627,0.715-3.631c1.342-1.216,3.371,0.676,4.955-1.562c-2.656-6.599-9.543-11.343-10.867-18.391
                c1.422-2.308,2.935,0.076,4.007-1.235c-0.444-3.038-2.011-5.642-2.998-8.445C641.403,860.088,640.533,857.536,644.211,856.278z
                M416.045,862.444c2.266,1.184,4.605-0.722,6.596,1.162c-2.427,3.009-6.248,3.702-8.422,6.429
                c1.406,4.656-0.273,8.425-4.663,10.585c-3.157,1.554-4.631,0.893-7.75-3.902c-2.006-3.085-4.609-5.058-8.205-6.054
                c-4.188-1.158-8.128-3.025-11.5-5.848c-1.337-1.119-3.119-2.115-2.521-4.49c2.601-1.536,5.366,0.377,8.148-0.105
                c-9.567-14.622-11.909-20.133-10.721-24.982c1.444-1.373,3.028,1.037,4.711-0.16c-2.09-4.217-5.322-7.462-7.183-11.605
                c-1.856-4.133-1.848-8.877-4.39-13.615c-3.62,5.831-6.823,10.99-9.992,16.094c1.138,1.418,3.314,0.061,3.995,2.373
                c-1.46,4.842-6.75,6.459-9.974,10.031c1.409,0.77,2.599,1.348,3.715,2.045c1.409,0.879-0.627,2.577,0.763,3.475
                c1.869,1.43,5.108,0.616,6.163,3.953c-5.09,4.457-11.976,1.137-17.303,3.725c0.157,1.863,2.456,2.199,1.97,4.632
                c-4.248,0.265-8.573,0.093-11.817-3.128c-2.866-2.845-6.221-3.531-9.793-4.053c-1.098,1.244-2.078,2.354-3.203,3.627
                c1.737,1.74,0.918,3.486-1.563,5.123c8.839,2.984,16.338,8.039,25.654,7.578c3.496-0.173,6.934,0.802,9.162,4.146
                c0.6,0.899,1.752,1.743,2.795,1.981c4.765,1.09,8.663,4.236,13.374,5.439c1.699,0.434,3.514,1.043,3.985,3.41
                c-3.68,2.506-7.879,1.215-11.676,2.049c-4.06,0.892-7.95,2.198-12.005,3.728c1.656,2.24,5.152,1.47,5.933,4.198
                c-0.033,1.789-2.029,1.797-2.422,2.998c2.686,4.208,6.724,5.75,11.402,6.206c1.978,0.192,3.954,0.589,5.881,1.085
                c2.792,0.717,5.38,1.811,6.038,4.875c4.665,0.695,4.749,0.617,7.839,3.98c1.018,1.109,2.18,1.295,3.417,1.727
                c1.579,0.553,2.238,1.748,1.502,3.779c-6.566-0.834-12.977-1.6-19.37-2.486c-2.653-0.367-5.368-0.912-7.953-0.408
                c-4.582,0.893-8.494-0.376-12.29-2.693c-3.339-2.039-7.103-2.822-10.902-3.349c-1.529-0.212-3.287-0.452-4.514,1.83
                c2.034,0.872,2.88,2.664,3.669,4.454c3.306,0.473,7.11-0.103,9.292,2.039c2.579,2.532,5.489,2.726,8.496,3.365
                c0.941,0.199,2.062,0.119,2.362,1.62c-2.089,3.941-6.917,1.886-10.281,4.138c6.996,0.795,113.203,0.54,117.329-0.288
                c-1.66-2.374-3.372-4.574-4.813-6.94c-2.463-4.042-2.272-4.721,1.46-7.789c-0.926-1.857-2.654-2.949-4.167-4.211
                c-2.446-2.041-4.732-4.209-6.477-6.891c-0.791-1.215-2.233-2.383-1.393-3.986c1.021-1.943,2.882-1.094,4.479-0.852
                c0.614,0.094,0.898-0.217,1.114-0.773c0.185-1.064-0.558-1.848-1.113-2.631c-1.154-1.628-2.413-3.184-3.667-4.736
                c-1.358-1.682-2.974-3.193-4.097-5.014c-2.23-3.612-0.865-5.987,3.422-6.445c1.611-0.172,3.276,0.531,5.188-0.44
                c-1.35-3.116-3.865-5.357-5.713-8.038c-0.838-1.215-1.867-2.315-1.935-3.957c-0.068-1.627,2.428-1.177,2.218-2.751
                c-1.892-4.151-6.071-6.752-7.296-11.617c0.866-0.546,1.697-1.069,2.755-1.735c-4.839-7.217-9.132-14.303-10.635-22.743
                c-3.764,1.981-9.191,7.567-10.437,10.407c-0.347,0.791-0.646,1.545-0.194,2.363c1.379,2.495-0.353,4.04-1.708,5.717
                c-1.433,1.774-3.573,3.055-3.978,5.523c3.851,4.961,3.821,5.009-0.614,9.845C420.219,857.644,417.841,859.512,416.045,862.444z
                M1055.041,14.768c-4.5-6.366-7.895-7.187-15.014-4.07c-12.055,5.277-24.137,10.489-34.891,18.246
                c-1.278,0.923-2.596,1.118-4.195,1.076c-11.301-0.299-20.475,3.833-26.638,13.573c-2.487,3.932-4.562,8.125-6.845,12.187
                c-1.386,2.465-2.553,5.106-4.265,7.323c-5.599,7.248-11.705,14.12-17.038,21.552c-4.827,6.728-10.869,9.766-19.085,9.355
                c-4.809-0.24-9.884-0.114-14.251,1.923c-5.101,2.378-9.882,2.174-14.934,0.566c-0.946-0.301-1.943-0.475-2.932-0.607
                c-15.908-2.13-31.238-6.483-46.298-11.927c-3.907-1.412-7.905-2.598-11.907-3.725c-3.879-1.093-7.912-1.065-10.717,2.073
                c-2.301,2.576-4.451,2.657-7.465,1.952c-7.638-1.786-15.029-4.317-22.449-6.786c-2.675-0.89-5.584-2.382-8.209-0.963
                c-3.382,1.829-6.527,1.46-10.026,0.847c-5.583-0.979-8.726,2.128-8.324,7.933c0.075,1.096,0.018,1.918-0.737,2.701
                c-2.686,2.779-2.357,6.094-1.416,9.371c0.439,1.526,0.457,2.755-0.125,4.278c-1.729,4.528,0.285,8.055,3.458,11.154
                c1.063,1.04,2.731,1.291,3.661,2.911c-0.348,1.094-0.843,2.319-1.121,3.591c-0.781,3.573,0.574,6.646,3.826,8.423
                c1.295,0.707,2.785,1.06,4.198,1.541c1.245,0.424,2.44,0.687,3.034,2.217c1.404,3.617,4.604,5.367,8.051,6.08
                c4.367,0.903,8.791,1.338,12.248,4.575c0.226,0.211,0.606,0.356,0.918,0.361c4.865,0.078,8.951,1.993,12.505,5.215
                c0.235,0.213,0.584,0.356,0.901,0.415c6.271,1.169,10.289,6.4,15.901,8.854c2.591,1.133,4.972,2.677,7.863,2.784
                c1.71,0.063,3.218,0.616,4.8,1.145c4.846,1.618,9.197,4.374,14.291,5.555c2.633,0.611,5.54,1.157,7.982,2.659
                c4.242,2.609,9.144,3.258,13.837,3.717c5.896,0.576,11.8,0.473,17.763,1.377c5.278,0.801,10.166,2.674,15.375,3.307
                c-0.78,4.843-0.863,5.173-3.945,7.151c-4.448,2.854-7.709,6.836-10.741,11.025c-2.337,3.228-2.586,6.796-1.009,10.433
                c2.105,4.855,5.768,8.053,10.734,9.801c3.421,1.204,6.794,1.767,9.98-0.644c0.662-0.5,1.303-0.931,2.252-0.744
                c5.209,1.025,7.932-2.261,10.354-6.049c0.941-1.472,1.097-3.429,3.095-5.164c1.446,3.343,5.201,4.13,6.414,7.034
                c2.089,5,6.597,6.984,10.748,9.177c5.066,2.675,9.425,0.61,11.764-4.643c0.51-1.145,0.18-2.771,2.164-3.538
                c5.146,2.905,10.443,2.823,15.457-1.154c0.454-0.361,1.273-0.415,1.907-0.37c3.511,0.247,6.593-0.7,9.173-3.124
                c0.918-0.862,1.949-1.11,3.196-1.026c1.318,0.089,2.672,0.079,3.976-0.116c5.686-0.851,7.678-7.079,12.836-8.878
                c1.635-0.57,2.805-2.557,3.057-4.377c0.553-4.011,2.463-6.942,5.768-9.229c1.182-0.818,1.799-2.374,2.115-3.883
                c0.24-1.147,0.428-2.361,1.309-3.188c5.115-4.806,3.301-12.178,6.572-17.729c0.93-1.577,0.006-3.905-0.947-5.744
                c-2.029-3.916-5.102-6.497-9.422-7.554c-1.213-0.297-2.748,0.033-3.348-1.562c0.129-1.126,1.028-1.707,1.656-2.456
                c7.897-9.426,17.57-17.194,25.135-26.95c1.443-1.862,2.803-3.641,3.672-5.853c1.51-3.839,3.129-7.518,6.946-9.939
                c2.024-1.284,3.895-3.146,5.517-4.965c4.845-5.432,9.783-10.743,15.309-15.491c0.372-0.32,0.756-0.728,0.918-1.173
                c1.041-2.852,3.356-3.611,6.059-4.32c5.871-1.539,8.166-5.637,6.818-11.719c-0.307-1.385-0.223-2.601,0.169-3.896
                c0.991-3.276-0.409-5.796-2.557-8.117c-0.999-1.08-2.732-1.343-3.333-3.235c1.903-4.634,0.282-8.534-3.488-11.555
                c0.73-7.003,0.297-7.664-7.498-11.697c-0.108-0.297-0.283-0.597-0.324-0.913c-1.164-9.069-7.027-12.127-15.244-7.928
                C1058.521,13.974,1057.172,15.031,1055.041,14.768z M1064.688,780.031c0.509,0.74,0.983,0.66,1.461,0.671
                c4.325,0.1,5.012,0.952,4.108,4.995c-0.818,3.664-2.724,6.749-4.947,9.707c-1.043,1.388-2.752,2.342-3.153,4.277
                c5.726,0.827,6.317,1.871,4.001,7.041c-1.711,3.816-4.018,7.273-6.551,10.596c-1.064,1.394-2.723,2.533-2.509,4.592
                c4.749,4.482,5.076,6.268,2.303,12.231c-1.121,2.411-2.752,4.722-3.261,7.257c-1.256,6.249-3.98,11.58-8.369,16.135
                c-0.133,0.139,0.064,0.595,0.151,1.223c1.095,0,2.234-0.036,3.371,0.011c1.163,0.048,2.353,0.044,3.478,0.296
                c3.485,0.781,4.615,2.879,3.393,6.253c-1.031,2.842-2.75,5.315-4.402,7.812c-2.205,3.33-4.463,6.625-6.666,9.956
                c-0.258,0.391-0.393,0.924,0.131,1.315c1.208,0.282,2.422-1.4,4.012,0.46c-1.979,5.26-7.188,9.168-8.412,14.852
                c0.912,1.413,2.18,1.484,3.197,1.982c3.918,1.92,4.598,3.646,2.318,7.469c-1.612,2.701-3.705,5.115-5.562,7.672
                c-1.52,2.092-3.484,3.891-4.654,6.399c6.076,1.047,69.612,0.828,73.24-0.197c-0.165-1.87-1.283-3.383-1.938-5.065
                c-1.395-3.582-2.631-7.201-2.792-11.084c-0.207-4.985,1.483-7.419,6.188-8.891c1.096-0.342,2.351-0.29,3.134-1.734
                c-1.021-1.965-2.752-3.542-4.307-5.24c-5.464-5.968-9.696-12.691-12.426-20.316c-1.599-4.469-0.667-5.921,3.861-7.203
                c2.154-0.611,4.758,0.178,6.575-1.965c-0.82-2.521-2.773-4.127-4.503-5.898c-1.858-1.903-3.979-3.582-5.38-5.883
                c-1.748-2.873-0.983-4.674,2.206-5.598c1.678-0.486,3.57,0.089,5.482-0.881c-1.774-3.811-4.725-6.348-6.881-9.443
                c-0.853-1.225-1.664-2.435-1.859-4.02c-0.251-2.031,2.481-1.586,2.536-3.213c-1.647-4.182-6.841-5.77-7.771-10.318
                c2.066-1.875,4.537,0.039,6.667-1.535c-1.995-4.654-4.758-8.794-7.133-13.131c-2.479-4.525-5.087-8.984-6.3-14.078
                c-1.443-6.058-0.294-7.96,5.863-9.671c0.246-0.068,0.412-0.429,0.801-0.859c-3.082-6.299-8.053-11.817-9.537-18.973
                c-1.441-6.95-2.633-13.952-3.934-20.931c-1.33,6.487-3.184,12.581-6.852,18.072c1.367,1.068,3.09,0.125,4.09,2.166
                C1075.609,765.998,1068.722,772.379,1064.688,780.031z M475.922-0.715c3.002,1.827,5.605,3.869,8.134,6.066
                c2.286,1.986,4.951,3.394,8.05,3.697c4.519,0.443,8.233,2.452,11.385,5.654c1.769,1.797,3.749,3.381,6.236,4.008
                c10.903,2.748,21.309,7.582,33.09,6.525c5.702-0.512,8.001,2.247,8.494,8.071c0.271,3.205,1.597,5.015,4.699,6.167
                c7.657,2.846,15.083,6.216,21.572,11.351c1.431,1.132,3.156,1.816,4.961,2.282c2.414,0.623,4.803,1.346,7.189,2.072
                c1.54,0.468,2.488,1.271,2.548,3.167c0.221,7.065,2.854,10.098,9.959,11.082c4.325,0.599,7.913,2.418,11.116,5.207
                c1.381,1.202,2.675,2.504,4.023,3.744c4.95,4.549,10.132,8.631,17.2,9.335c1.806,0.18,3.585,0.742,5.345,1.24
                c3.777,1.066,4.533,2.062,4.224,6.101c-0.299,3.893,0.115,7.328,2.407,10.851c2.224,3.418,3.688,7.654,1.317,11.833
                c-2.404,4.234-5.178,8.488-9.642,10.546c-4.743,2.187-7.469,6.388-11.542,9.22c0.702,3.217,0.907,6.131-1.362,8.685
                c-1.548,1.743-2.837,3.781-4.832,5.018c-7.196,4.464-14.429,8.877-22.346,12.004c-0.618,0.244-1.259,0.546-1.904,0.585
                c-6.684,0.407-12.281,3.458-17.697,7.06c-2.203,1.465-4.827,2.234-6.807,4.118c-4.567,4.347-10.647,6.789-14.786,11.696
                c-0.413,0.49-1.055,0.821-1.643,1.125c-11.82,6.104-23.349,12.713-34.832,19.434c-5.994,3.508-12.801,5.595-19.065,8.68
                c-5.146,2.534-10.445,4.291-16.105,5.062c-4.138,0.564-8.046,1.508-11.342,4.48c-1.676,1.512-3.945,2.559-6.122,3.29
                c-10.436,3.502-20.499,7.869-30.429,12.583c-2.247,1.067-4.644,1.825-6.991,2.67c-11.831,4.26-24.221,5.919-36.555,7.836
                c-1.724,0.268-3.585,0.163-4.916,1.497c0.761,2.812,2.09,3.57,4.862,3.018c8.486-1.692,17.008-3.199,25.447-5.139
                c11.524-2.649,23.048-5.265,33.741-10.573c4.481-2.225,9.167-3.909,13.986-5.292c6.899-1.98,13.623-4.436,19.459-8.804
                c1.379-1.032,3.003-1.398,4.649-1.692c13.7-2.447,26.466-7.667,39.173-13.099c6.157-2.632,11.474-6.773,17.458-9.623
                c13.679-6.513,26.948-13.621,39.052-22.836c8.088-6.158,16.715-11.373,27.133-12.734c1.782-0.233,3.567-0.922,5.205-1.706
                c6.596-3.158,13.197-6.318,19.671-9.715c3.711-1.948,6.71-4.919,9.498-8.034c1.799-2.011,3.479-4.336,3.475-7.042
                c-0.006-4.22,2.726-6.584,5.729-7.964c6.652-3.055,11.451-7.593,15.146-13.898c2.014-3.437,2.938-6.588,2.093-10.355
                c-0.765-3.409-1.325-6.913-3.763-9.656c-1.246-1.403-1.172-3.031-1.07-4.709c0.102-1.661,0.207-3.325,0.224-4.988
                c0.019-1.82-0.751-3.585-2.438-3.999c-3.914-0.958-7.18-2.988-10.439-5.182c-0.766-0.515-1.916-0.583-2.9-0.627
                c-4.796-0.212-8.322-2.362-11.466-6.057c-5.671-6.665-12.788-11.101-21.804-11.882c-1.307-0.113-2.6-0.58-3.859-1.001
                c-2.322-0.778-3.729-2.29-3.156-4.888c0.715-3.244-1.139-4.955-3.535-6.441c-2.563-1.59-5.279-2.804-8.184-3.642
                c-2.075-0.599-4.327-1.002-6.11-2.116c-7.236-4.52-15.254-7.429-22.752-11.39c-1.536-0.812-3.913-0.963-3.967-3.253
                c-0.055-2.273,2.271-2.559,3.773-3.473c0.317-0.193,0.334-0.486-0.139-1.128c-7.051-3.543-14.796-6.334-22.89-7.181
                c-11.062-1.157-21.198-5.673-31.972-7.743c-0.405-0.078-0.72-0.622-0.99-0.869c-0.396-1.948,2.192-1.913,2.186-3.66
                C505.37-1.449,490.808-2.329,475.922-0.715z M1058.688,620.075c2.733-7.099,2.733-7.099,9.188-7.744
                c2.52-2.922,0.365-7.634,4.062-10.621c3.722,4.751,1.146,11.021,4.333,15.753c1.278-1.113,1.217-2.521,1.537-3.746
                c0.373-1.437,1.172-2.486,2.662-2.586c1.846-0.123,2.49,1.26,3.016,2.77c0.402,1.156-0.01,2.656,1.522,3.609
                c3.31-4.342,0.64-10.463,4.548-15.806c1.724,5.194,6.298,6.313,8.435,10.118c1.3-0.869,1.261-2.158,1.724-3.139
                c0.546-1.159,1.302-1.928,2.65-1.881c1.649,0.057,2.329,1.205,2.57,2.586c0.372,2.131,0.55,4.295,0.88,6.434
                c0.191,1.24-0.236,2.732,1.593,3.738c0.799-3.295,4.576-3.637,6.051-6.623c0.983-1.994,3.608-1.338,4.754,0.768
                c0.705,1.294,0.234,3.023,1.732,4.252c1.916-0.222,3.17-2.163,5.04-2.262c1.487-4.438,1.487-4.438,8.632-6.955
                c1.05-1.798,0.359-4.354,2.345-5.815c2.636-1.94,3.965,1.243,5.674,1.739c1.558-0.901,1.372-2.225,1.739-3.286
                c0.582-1.678,1.4-3.226,3.445-2.917c1.77,0.268,2.123,1.863,2.364,3.417c0.405,2.602,0.118,5.321,1.166,7.819
                c6.324-1.27,6.324-1.27,8.633,6.016c1.624,0.165,3.319,0.941,5.222,0.133c1.289-1.287,0.517-3.822,2.404-4.668
                c2.539-1.139,2.508-3.279,2.541-5.449c0.034-2.184,0.323-4.329,0.979-6.4c0.377-1.192,1.271-1.987,2.623-1.844
                c1.064,0.111,1.771,0.764,2.205,1.779c0.545,1.273-0.054,3.104,2.148,3.922c0.936-2.903,1.996-5.799,5.068-7.188
                c0.33-0.149,0.363-0.899,0.59-1.342c1.804-3.533,2.299-3.654,5.818-1.465c2.469-3.379,5.584-6.307,6.651-10.436
                c3.76,0.354,2.903,3.967,4.671,5.453c0.322-0.377,0.717-0.633,0.691-0.838c-0.779-6.252,2.511-11.58,3.951-17.32
                c0.385-1.535,0.822-3.863,2.71-3.732c2.174,0.151,2.487-0.976,3.034-2.378c1.014-2.602,2.376-5.024,3.153-7.775
                c0.517-1.826,0.769-4.374,3.35-5.321c3.977,1.793,0.212,7.678,4.794,8.969c2.325-3.324,2.97-7.248,2.682-11.281
                c-0.409-5.734,1.996-10.698,3.924-15.807c0.352-0.932,0.963-1.768,1.74-1.859c2.252-0.068,2.408,2.652,4.905,2.463
                c-0.938-3.127,1.543-5.454,2.058-8.184c-5.617-5.207-13.152-3.585-19.289-6.328c-0.736-0.329-1.934,0.251-2.879,0.533
                c-2.922,0.874-5.957,1.026-8.942,1.942c-4.549,1.396-9.618,2.189-14.204-0.29c-0.992,1.398-0.439,2.588,0.303,2.941
                c3.222,1.535,4.089,4.467,4.908,7.486c0.36,1.33,1.225,2.307,2.061,3.36c2.094,2.64,1.25,6.335,3.087,9.135
                c0.206,0.313-0.264,1.333-0.688,1.751c-1.493,1.468-2.225,3.212-2.637,5.242c-0.312,1.54-1.438,2.59-3.051,2.984
                c-2.035,0.498-3.637,1.611-4.723,3.421c-1.957,3.262-5.6,4.646-8.566,6.187c-2.311,1.2-3.37,2.611-4.35,4.894
                c-5.234-2.034-7.809,1.651-10.9,4.871c1.785,2.894,1.312,6.534,3.119,9.65c-2.186,0.985-3.314,2.68-4.523,3.878
                c-4.561,0.725-8.712-0.93-13.079-0.42c-0.897-3.004-4.095-3.756-5.536-6.316c-1.841-3.271-5.123-4.069-8.681-3.489
                c-1.815,0.296-3.5,0.63-5.366-0.087c-2.018-0.776-5.385,0.428-6.318,2.378c-0.663,1.387-1.699,2.423-2.424,3.71
                c-1.83,3.25-4.268,3.967-6.856,1.636c-1.133-1.02-2.895-0.939-3.604-2.385c0.913-4.693,1.092-9.177-2.759-12.677
                c2.79-4.973,2.79-4.973-0.919-7.809c-3.941,2.359-8.348,1.576-12.633,1.432c-4.176-0.141-7.939,0.609-11.092,3.522
                c-0.736,0.681-1.545,1.344-2.582,1.427c-7.157,0.574-12.198,5.261-16.809,9.795c-2.876,2.83-5.818,4.745-9.387,6.119
                c-3.73,1.436-6.363,3.732-7.926,7.686c-1.442,3.646-3.5,7.123-8.659,6.812c-2.632-0.16-5.301,1.072-6.796,3.574
                c-1.193,2-2.91,2.631-4.982,3.53c-3.407,1.479-6.877,2.235-10.405,3.085c-2.079,0.502-4.118,1.363-5.696,3.357
                c1.338,1.396,1.265,3.504,2.171,5.141c1.263-0.309,1.4-1.16,1.583-1.963c0.371-1.63,1.225-2.822,3.032-2.684
                c1.752,0.134,2.485,1.461,2.875,3.018c0.226,0.906,0.067,2.061,1.594,2.336c2.705-1.754,0.63-6.266,4.114-7.846
                c3.258,3.011,0.929,8.146,4.307,11.385c1.805-2.172,0.389-5.752,3.701-6.414c3.279-0.655,2.938,3.031,5.302,4.676
                c0.88-3.014,2.782-4.818,4.929-6.617c1.941-1.625,0.116-5.545,2.941-7.41c1.965,0.967,2.129,2.645,2.432,4.27
                c0.243,1.308,0.363,3.264,1.451,3.561C1056.898,614.05,1057.087,617.163,1058.688,620.075z M1002.331,395.196
                c0.909,0.831,1.759,0.852,2.562,0.829c6.56-0.188,12.324,2.34,18.062,5.095c5.779,2.774,8.463,7.239,8.014,13.623
                c-0.271,3.84,0.268,7.616,0.687,11.429c0.402,3.668,2.351,5.223,5.591,5.943c1.773,0.395,3.881,0.168,5.233,1.428
                c2.534,2.361,5.728,3.318,8.711,4.745c1.966,0.94,3.728,2.158,5.332,3.648c3.808,3.538,6.978,3.612,11.267,0.248
                c2.014-1.58,4.255-2.732,6.729-3.109c3.026-0.461,5.85-1.51,8.447-2.922c3.359-1.826,6.789-2.589,10.525-2.191
                c3.039,0.324,5.471-0.768,7.639-2.864c1.908-1.846,3.793-3.938,6.322-4.808c4.58-1.575,6.799-4.924,8.146-9.262
                c2.896-9.331,9.71-14.808,18.375-18.422c2.456-1.024,4.919-2.044,7.311-3.208c1.271-0.619,2.895-0.787,3.654-2.368
                c-0.908-1.356-2.098-2.557-1.971-4.414c0.116-1.701-1.022-2.644-2.369-3.503c-3.296-2.104-7.979-2.26-9.568-6.853
                c-0.184-0.53-1.434-0.979-2.186-0.973c-3.299,0.027-6.59-1.312-9.893-0.082c-0.771,0.287-1.551,0.648-2.39,0.244
                c-4.254-2.047-9.13-1.942-13.403-3.716c-3.891-1.614-8.393-1.879-11.555-5.299c-1.232-1.333-3.453-1.67-5.174-1.343
                c-3.105,0.589-6.193,0.625-9.311,0.737c-0.939,0.034-1.872,0.61-2.783,0.996c-2.947,1.25-5.453,3.226-7.506,5.571
                c-1.506,1.722-3.438,2.343-5.256,2.359c-5.01,0.045-9.594-1.816-13.68-4.616c-5.057-3.465-10.17-5.542-16.197-2.493
                c-2.223,1.124-4.626,1.916-6.251,3.97c-0.526,0.664-1.173,1.369-2.063,1.142c-2.639-0.671-4.828,0.847-7.229,1.32
                c-4.373,0.862-6.797,3.574-7.134,8.037c-0.229,3.047-0.829,5.74-3.895,7.46C1004.012,392.198,1002.679,393.316,1002.331,395.196z
                M943.719,349.067c5.863-0.103,11.517-0.655,17.046-1.76c9.45-1.887,18.874-1.428,28.34-0.543
                c2.188,0.205,4.316,0.659,6.108,1.971c5.953,4.359,11.624,8.982,14.358,16.208c0.353,0.93,0.813,1.826,1.295,2.698
                c1.671,3.024,4.663,4.355,7.696,5.064c2.492,0.582,4.891,0.014,7.286-1.887c6.613-5.247,14.05-6.054,21.364-1.512
                c2.363,1.467,4.796,2.688,7.246,3.919c4.316,2.17,8.479,2.731,12.123-1.401c0.754-0.855,1.703-1.582,2.672-2.198
                c3.227-2.053,6.426-4.164,10.558-3.845c6.019,0.465,10.829-1.937,15.115-6.581c-3.72-3.637-7.282-7.132-10.858-10.612
                c-1.585-1.543-3.475-2.719-5.596-3.163c-5.508-1.152-10.163-4.014-14.879-6.849c-1.836-1.104-3.852-2.039-5.908-2.62
                c-8.217-2.319-15.797-6.189-23.615-9.476c-1.391-0.584-2.731-1.361-3.662-2.548c-2.252-2.871-4.402-5.725-8.526-6.045
                c-1.104-0.086-1.829-1.33-2.62-2.202c-3.293-3.623-7.294-5.673-12.325-5.562c-2.827,0.063-5.661-0.149-8.491-0.229
                c-1.333-0.038-2.744,0.079-3.826-0.842c-2.762-2.348-6.927-1.118-9.57-3.857c-0.596-0.617-1.526,0.507-2.275,0.508
                c-4.896,0.003-10.075,1.229-14.131-2.87c-1.07-1.082-2.318-1.19-3.684-0.886c-2.626,0.585-4.505-0.445-5.946-2.609
                c-0.644-0.967-1.462-1.815-2.83-2.303c-3.724,2.712-7.763,2.543-11.735,0.47c-1.422,0.825-2.631,1.936-4.01,2.22
                c-1.6,0.33-3.189,0.667-4.787,1.038c-2.925,0.679-3.605,3.134-4.85,5.248c-1.079,1.834-0.971,3.391,0.402,5.001
                c0.791,0.926,0.979,2.153,0.756,3.322c-0.829,4.344-0.496,8.832-3.013,12.951c-2.771,4.532-1.376,9.135,3.048,11.418
                C936.702,341.132,940.179,344.799,943.719,349.067z M279.966,510.338c1.457,2.074,2.837,2.734,4.155,3.395
                c11.193,5.599,21.746,12.191,29.852,21.853c4.862,5.794,10.829,9.104,17.868,11.161c5.16,1.509,9.142,4.682,11.689,9.525
                c1.601,3.043,3.918,5.437,7.086,6.791c8.109,3.469,15.711,8.078,24.121,10.855c11.609,3.832,22.06,10.146,33.03,15.342
                c3.271,1.551,6.105,4.023,9.143,6.069c2.019,1.36,3.461,3.18,3.951,5.566c0.657,3.201,2.759,5.04,5.292,6.887
                c5.133,3.743,11.189,5.951,16.11,9.997c5.54,4.556,11.409,8.666,17.271,12.785c2.588,1.818,5.308,3.498,7.469,5.839
                c2.441,2.644,5.399,4.376,8.739,5.603c3.754,1.379,7.624,2.689,10.756,5.205c7.631,6.128,16.069,11.256,22.945,18.403
                c1.583-1.179,1.861-4.146,4.771-3.562c2.234,1.243,1.235,5.044,4.525,5.611c0.866-1.801,0.937-3.813,1.371-5.735
                c0.253-1.117,0.616-2.799,1.784-2.704c2.503,0.202,2.48-1.582,3.037-3.019c0.751-1.939,0.842-4.331,3.133-5.012
                c2.792,0.531,1.244,3.918,4.09,4.729c1.226-2.709,1.772-6.535,3.934-8.041c2.788-1.94,2.625-5.974,5.518-7.065
                c1.442,0.418,1.628,1.288,2.031,2c0.364,0.644,0.205,1.699,1.304,1.797c3.282-3.314,3.282-3.314,2.944-7.953
                c-5.951-2.614-12.025-5.312-18.131-7.934c-0.888-0.381-1.958-0.633-2.9-0.535c-3.607,0.375-6.468-1.211-9.194-3.226
                c-1.875-1.385-3.563-3.209-5.806-3.846c-7.22-2.05-14.054-5.12-21.109-7.556c-2.44-0.842-4.042-2.946-6.477-3.279
                c-3.617-0.496-6.565-2.289-9.47-4.258c-5.518-3.741-11.866-6.012-17.353-9.818c-1.132-0.785-2.559-0.82-3.879-0.523
                c-5.039,1.132-9.16-0.508-12.75-3.994c-0.708-0.688-1.654-1.125-2.418-1.764c-2.994-2.502-6.494-3.948-10.194-4.998
                c-2.245-4.616-7.25-4.71-11.05-6.793c-2.065-1.132-4.134-2.057-6.466-2.531c-2.29-0.467-4.584-1.261-5.971-3.331
                c-1.46-2.181-3.535-2.876-5.969-3.016c-2.771-0.157-5.208-1.087-7.074-3.226c-1.254-1.438-2.817-2.32-4.674-2.687
                c-2.537-0.5-4.59-1.554-6.145-3.835c-1.122-1.645-3.131-2.529-5.145-2.829c-5.836-0.87-8.733-7.134-14.616-7.873
                c-0.13-0.017-0.28-0.2-0.349-0.341c-2.053-4.233-6.26-5.455-10.155-6.598c-4.819-1.412-8.737-3.966-12.336-7.268
                c-2.088-1.914-4.157-4.707-6.811-4.859c-6.276-0.358-10.242-4.578-15.159-7.344c-4.483-2.521-8.127-5.727-11.408-9.51
                c-0.759-0.875-1.277-1.963-2.468-2.37C295.126,508.034,287.857,508.209,279.966,510.338z M1272.838,316.375
                c-4.055-3.033-8.017-5.999-11.981-8.96c-1.326-0.99-2.847-1.81-3.397-3.525c-2.42-7.537-7.9-12.555-14.01-17.056
                c-1.48-1.092-2.811-2.228-3.608-4.013c-1.388-3.104-4.04-4.237-7.332-4.087c-1.811,0.083-3.538,0.214-5.368-0.527
                c-7.242-2.934-14.481-5.835-21.29-9.733c-4.042-2.313-8.25-4.329-12.686-5.809c-5.556-1.854-10.759,0.472-16.048,1.496
                c-1.265,0.245-2.131,1.605-2.731,2.798c-1.698,3.371-4.085,6.033-7.457,7.792c-2.157,1.125-2.423,2.817-1.691,5.045
                c1.242,3.788,2.314,7.635,3.329,11.49c0.218,0.824,0.479,1.507,0.969,2.202c5.762,8.156,7.962,17.852,11.391,26.994
                c0.917,2.448,1.324,4.636,4.1,5.587c1.49,0.511,2.098,2.077,2.76,3.417c1.399,2.833,3.018,5.598,4.054,8.562
                c1.884,5.392,5.464,8.361,11.089,9.149c2.005,0.281,3.85,1.237,4.816,3.198c0.832,1.691,2.175,2.432,3.97,2.539
                c3.079,0.183,5.816,1.176,8.449,4.317c3.214-5.486,8.192-4.571,13.017-4.284c2.162,0.128,3.723-0.694,5.035-2.535
                c2.702-3.792,5.604-7.444,8.493-11.099c3.534-4.471,7.259-8.725,13.093-10.307c0.607-0.165,1.247-0.551,1.665-1.02
                C1264.999,324.046,1269.422,320.948,1272.838,316.375z M705.786,558.088c-2.439,0.53-2.939,1.862-3.535,3.025
                c-1.574,3.071-4.576,4.729-6.982,6.917c-2.616,2.38-5.159,0.156-7.391-1.538c-3.077,6.963-5.148,5.664-13.302,3.754
                c-2.587,4.522-5.34,5.028-12.981,2.305c-1.888,2.372-4.466,3.959-7.208,1.943c-1.877-1.381-3.852-1.672-5.982-2.379
                c-1.454,2.401-3.688,2.881-6.393,2.936c-5.819,0.118-11.633-0.604-17.449-0.025c-2.354,0.234-4.111-0.825-5.356-3.364
                c-0.843,1.263-1.259,2.151-1.908,2.809c-1.823,1.846-4.864,2.218-6.143,0.188c-1.776-2.82-4.787-2.99-6.849-4.863
                c-0.499,0.613-0.744,0.846-0.9,1.126c-0.16,0.287-0.184,0.651-0.351,0.933c-1.945,3.266-4.481,3.971-7.311,1.539
                c-1.262-1.084-2.584-1.227-3.906-1.791c-2.456-1.05-5.295-0.6-7.584-2.287c-1.531-1.129-2.916-0.423-3.597,1.244
                c-1.319,3.227-3.997,4.116-7.106,4.684c-1.978,0.359-3.936,0.83-5.927,0.613c-2.968-0.322-5.693,0.672-8.099,1.979
                c-2.078,1.129-4.363,1.546-6.419,2.655c-1.461,0.788-3.568,1.062-4.773-0.096c-2.199-2.112-5.12-1.879-7.375-3.342
                c-3.18,5.056-4.696,5.232-10.762,1.418c-2,1.436-2.75,4.377-5.98,4.682c-3.129,0.295-4.374-2.822-6.766-3.185
                c-0.556,0.315-0.914,0.415-1.116,0.648c-4.493,5.206-6.45,5.143-10.575-0.472c-0.856-1.166-1.808-2.28-3.251-1.479
                c-2.393,1.33-5.157,1.289-7.565,2.303c-5.883,2.475-6.43,2.414-9.308-2.795c-3.201,1.877-6.816,0.436-10.173,1.229
                c-2.218,0.524-4.121,0.25-6.215-0.811c-2.305-1.168-4.911-2.039-7.273,0.389c-0.988,1.016-2.497,0.793-3.825,0.729
                c-4.048-0.197-7.973-1.232-11.34-3.343c-2.352-1.474-4.79-2.005-7.061-1.347c-2.915,0.846-5.049,0.174-7.447-1.301
                c-2.434-1.496-5.208-2.279-7.545,0.286c-1.049,1.151-2.584,0.793-4.425,1.639c4.152,1.224,7.848,1.978,9.563,5.8
                c0.157,0.35,0.815,0.568,1.28,0.674c5.246,1.18,9.155,4.65,13.2,7.815c2.394,1.874,4.816,2.768,7.855,2.146
                c3.056-0.624,5.985-0.268,8.432,1.948c1.19,1.078,2.745,0.909,4.12,1.323c4.629,1.396,9.006-2.418,13.558-0.383
                c3.004-2.379,5.517-0.735,7.782,1.176c5.948-4.906,6.993-5.068,12.83-1.793c1.653-3.465,4.26-3.596,7.284-1.523
                c0.695,0.477,1.689,0.561,2.381,0.022c3.074-2.392,5.591-0.489,8.324,1.343c3.571-4.932,6.475-1.428,9.684,1.158
                c0.956-5.477,5.022-4.688,8.552-5.408c4.226-0.86,6.854,1.213,9.366,4.271c4.319-5.69,9.143-1.704,13.695-1.16
                c2.832-6.16,2.938-6.178,8.626-1.502c0.293-0.123,0.681-0.166,0.865-0.381c2.921-3.421,2.974-3.498,6.13-0.936
                c1.104,0.896,2.19,1.584,3.564,1.48c2.496-0.189,4.445,1.026,6.207,2.378c6.113-4.256,7.016-4.494,11.126-3.017
                c0.989-1.094,1.975-1.994,3.579-2.443c3.226-0.905,6.483-1.486,9.788-1.825c2.222-0.228,4.064-0.831,5.76-2.501
                c3.15-3.099,3.747-2.963,6.946,0c0.473,0.439,1.001,0.818,1.42,1.158c1.181-0.402,1.868-1.135,2.45-1.946
                c5.604-7.802,5.34-5.134,12.467-3.656c0.318,0.065,0.634,0.224,0.911,0.4c1.665,1.06,2.997,0.562,4.54-0.519
                c4.146-2.907,4.33-2.779,6.854,1.945c0.203,0.381,0.744,0.58,1.452,1.104c2.8-3.103,5.769-3.772,9.532-1.047
                c1.584,1.146,4.309,1.71,6.339-0.643c1.364-1.582,3.158-1.67,4.796-0.017c2.296,2.32,4.937,2.515,7.655,0.89
                c1.819-1.089,3.423-0.624,5.214,0.043c4.835,1.801,9.867,2.258,14.607,0.107c2.918-1.324,5.285-1.606,7.756,0.986
                c1.992-2.803,5.203-3.256,7.346-5.387c1.085-1.078,2.652-0.992,3.557,0.213c1.625,2.17,4.11,3.203,5.846,5.186
                c3.881-2.288,7.632-3.721,11.293-0.289c1.443-1.139,0.893-2.66,1.377-3.851c0.908-2.228,2.398-2.813,4.23-1.266
                c1.693,1.431,4.073,2.156,4.568,4.78c2.308-1.154,4.034-2.367,6.439-1.279c3.508,1.585,4.429,1.157,6.705-2.047
                c1.904-2.682,3.291-2.551,4.936,0.526c0.306,0.573,0.342,1.294,1.508,1.644c0.605-1.304,1.4-2.697,3.364-2.577
                s2.061,2.617,3.841,2.805c1.994-0.451,3.471-1.507,5.062-2.803c6.367-5.181,6.471-5.054,10.355,1.652
                c3.285-3.918,3.285-3.918,7.471-1.573c0.244-0.626,0.467-1.239,0.721-1.838c1.448-3.403,1.559-3.512,4.77-1.574
                c1.464,0.883,3.282,1.105,4.251,0.384c3.366-2.507,6.617-2.739,10.229-0.778c0.499,0.271,1.323,0.045,1.971-0.084
                c1.914-0.384,2.159,1.644,3.321,2.386c0.65-0.874,1.095-1.816,1.829-2.38c1.005-0.769,1.39-1.746,1.837-2.85
                c1.342-3.32,2.207-3.428,4.543-0.782c1.887,2.138,4.369,2.894,6.854,1.563c1.256-0.672,2.562-0.262,3.908-0.92
                c0.611-4.139,1.234-8.354,1.842-12.465c-1.954-0.93-3.277,0.272-4.732,0.289c-1.659,0.018-3.596,0.535-4.748-0.86
                c-2.353-2.847-5.854-2.647-8.798-4.103c-1.825,1.611-2.728,3.91-5.512,4.172c-2.975,0.28-4.02-2.715-6.325-3.211
                c-4.275,4.264-5.616,4.43-10.22,1.387c-1.758,4.771-4.561,6.294-9.026,1.306c-2.215,2.586-5.275,2.452-8.25,2.511
                c-1.438,0.028-2.824-0.567-3.098-2.02c-0.507-2.688-2.426-3.246-4.595-3.656c-0.855,0.825-1.458,2.039-2.645,2.255
                c-3.42,0.624-6.784,1.413-10.14,2.338c-2.303,0.634-4.261-0.354-4.967-2.96c-0.213-0.781-0.527-1.535-0.785-2.274
                c-4.351,1.273-7.087,5.276-12.034,5.198c-4.662-0.075-8.378-2.578-12.62-3.694c-1.557,2.112-3.067,4.065-5.795,4.558
                c-2.846,0.514-4.427-1.861-6.671-2.937C715.042,562.127,710.211,562.135,705.786,558.088z M599.967,567.482
                c6.479-3.914,10.017,1.272,14.383,4.199c1.187-1.77,2.322-3.635,4.896-3.563c2.443,0.067,3.436,2.368,4.776,3.204
                c7.096-0.672,13.708,0.804,20.22-0.332c0.66-1.021,1.178-2.072,1.928-2.916c0.821-0.926,2.22-1.533,3.117-0.635
                c2.248,2.25,5.871,1.225,7.676,3.848c1.352-0.979,0.941-2.372,1.644-3.308c1.337-1.783,3.509-2.331,4.562-0.541
                c1.581,2.685,3.892,2.653,6.31,2.905c2.989-5.147,3.094-5.164,8.858-3.035c1.402,0.518,2.886,0.727,4.366,0.401
                c2.616-5.804,4.738-6.526,10.436-2.604c1.443-2.83,4.797-3.594,6.436-6.553c2.549-4.602,5.442-5.234,9.881-2.346
                c2.57,1.672,4.558,1.287,6.83-0.275c3.798-2.61,3.896-2.65,7.55,0.115c2.013,1.524,3.331,0.947,4.532-0.858
                c0.459-0.689,0.747-1.493,1.209-2.181c1.729-2.572,3.077-2.924,5.158-0.829c2.362,2.376,5.543,2.617,8.377,3.691
                c1.27,0.481,3.449,0.774,4.291-0.353c1.652-2.209,4.326-2.272,6.309-3.768c2.951-2.225,5.538-1.193,7.102,2.248
                c0.326,0.717,0.178,1.684,1.457,2.113c2.73-1.515,6.216-1.047,9.233-2.358c2.247-5.235,2.247-5.235,7.327-3.953
                c4.477-4.331,8.855-8.944,12.279-14.255c3.181-4.935,7.678-8.164,12.229-11.566c13.286-9.934,29.42-14.695,43.064-23.953
                c0.936-0.635,2.186-0.785,3.242-1.268c2.073-0.946,4.951-9.753,3.841-11.776c-0.218-0.397-0.737-0.628-1.405-1.167
                c-1.104,3.092-4.15,3.428-6.367,4.965c-2.53,1.755-4.649,2.43-6.903-0.082c-3.341,5.178-3.39,5.189-8.823,2.272
                c-3.966,2.364-8.982,2.146-13.026,4.867c-1.345,0.905-3.138,0.038-3.532-1.688c-0.492-2.16-2.365-1.805-3.586-2.641
                c-2.187-1.498-3.972,1.086-5.703,0.121c-2.744-1.529-4.439,0.144-6.744,1.184c-3.374,1.521-7.154,3.904-10.738,0.357
                c-2.236,1.169-2.959,3.877-5.801,3.951c-4.152,0.109-8.341,0.484-11.356,3.751c-4.115-2.667-4.136-2.688-8.091,0.999
                c-0.816,0.76-1.721,0.859-2.726,0.965c-4.761,0.504-9.079,2.057-12.592,5.461c-0.233,0.227-0.562,0.554-0.817,0.529
                c-3.009-0.288-3.58,2.304-4.971,4.02c-1.764,2.176-2.697,5.803-7.331,3.792c0.241,3.951-1.682,6.324-3.964,8.466
                c-2.141,2.009-4.271,4.001-5.521,6.75c-0.365,0.806-1.617,0.769-2.004,1.275c-2.453,3.223-5.643,4.668-9.631,4.883
                c-1.03,0.057-2.229,0.873-2.967,1.688c-1.845,2.037-3.955,2.073-6.31,1.064c-1.157,2.324-2.238,4.41-5.198,4.099
                c-1.014-0.106-1.871,0.608-2.674,1.218c-3.662,2.781-7.359,3.66-11.161-0.175c-1.29,2.282-3.222,2.145-5.232,2.686
                c-5.692,1.53-11.093,2.909-17.202,2.576c-3.676-0.2-7.338-1.662-11.49-0.441c-1.914,4.292-6.456,4.367-10.664,4.905
                c-4.524,0.58-9.099,1.061-13.82-0.709c-2.638,3.148-6.227,1.914-9.583,1.432c-5.253-0.754-10.356-2.92-15.803-1.667
                c-2.275,0.523-4.733,0.062-7.562,1.243C594.963,561.342,597.434,564.374,599.967,567.482z M1032.465,147.881
                c5.605-2.812,10.803-5.44,16.022-8.026c2.288-1.134,4.208-2.645,5.622-4.803c2.977-4.54,7.454-6.859,12.635-6.937
                c3.979-0.06,7.068-1.575,10.113-3.625c6.225-4.192,12.543-8.2,19.524-11.056c2.03-0.831,3.647-2.283,5.307-3.69
                c8.128-6.898,16.182-13.907,25.081-19.818c5.811-3.86,6.723-3.727,12.316,0.392c2.755,2.029,4.495,2.097,7.246,0.108
                c1.073-0.776,1.979-1.781,2.987-2.65c3.298-2.843,6.774-5.512,10.991-6.723c2.417-0.694,4.078-1.999,5.626-3.828
                c4.149-4.902,9.108-8.554,15.188-10.999c8.527-3.428,15.568-8.894,20.066-17.218c2.248-4.159,5.449-7.519,9.213-10.393
                c15.627-11.936,30.474-24.835,45.762-37.191c0.769-0.622,2.255-0.974,1.635-2.889c-11.735,0-23.548,0.017-35.36-0.018
                c-1.878-0.005-3.044,1.127-4.301,2.221c-4.397,3.828-8.704,7.771-13.245,11.421c-4.613,3.708-8.524,7.892-11.207,13.222
                c-4.223,8.394-11.05,14.309-19.402,18.036c-6.484,2.893-11.711,6.891-15.865,12.477c-1.234,1.661-2.669,2.965-4.673,3.556
                c-4.959,1.463-8.77,4.636-12.322,8.211c-5.055,5.087-7.118,5.24-12.613,0.544c-2.095-1.79-4.118-2.049-6.366-0.69
                c-1.849,1.117-3.68,2.292-5.378,3.622c-7.744,6.066-15.028,12.669-22.209,19.383c-1.593,1.489-3.163,3.008-5.156,3.897
                c-7.202,3.212-13.635,7.652-19.956,12.284c-2.989,2.189-6.095,3.754-10.033,3.874c-5.081,0.155-9.291,2.377-11.896,7.055
                c-0.813,1.461-1.904,2.75-3.303,3.705c-6.244,4.269-10.686,10.449-16.285,15.415c-1.762,1.563-3.613,3.375-4.757,5.897
                C1026.746,143.87,1029.721,145.327,1032.465,147.881z M342.914,574.26c-0.215-2.02-0.958-3.48-3.355-3.348
                c-2.014,0.111-3.608-0.758-4.696-2.349c-1.793-2.621-3.406-5.343-6.877-6.103c-0.512-0.113-1.122-0.894-1.249-1.463
                c-0.799-3.573-4.028-5.179-6.088-7.721c-2.021-2.492-4.479-3.253-7.817-3.359c-4.463-0.141-9.185-0.662-11.087-6.316
                c-2.795-8.307-10.441-11.024-17.552-14.215c-0.953-0.428-1.955-0.353-2.92,0.016c-2.058,0.783-3.697,0.413-5.461-1.098
                c-2.21-1.895-4.971-2.556-7.925-1.704c-1.124,0.323-2.752,0.893-3.33,0.006c-2.169-3.329-6.49-4.337-8.064-8.479
                c-2.031-5.346-3.892-11.109-9.878-13.772c-0.553-0.245-1.115-0.887-1.306-1.465c-1.616-4.888-4.741-8.257-9.644-9.94
                c-0.288-0.1-0.506-0.422-0.74-0.656c-3.107-3.118-7-3.896-11.046-2.994c-2.331,0.519-4.392,0.403-6.18-0.828
                c-2.146-1.477-4.333-1.095-6.651-1.211c-2.195-0.111-3.431,2.082-5.632,1.691c-0.738-1.27-1.193-2.903-2.269-3.752
                c-4.632-3.658-7.595-8.666-10.937-13.319c-1.961-2.732-4.091-4.374-7.41-3.958c-5.658,0.709-11.155,1.965-15.791,5.488
                c0.36,1.121,1.502,1.174,1.863,1.837c1.618,2.966,3.899,4.812,7.357,5.196c0.707,0.08,1.729,0.789,1.926,1.421
                c1.186,3.789,5.372,6.718,2.966,11.509c1.946,1.175,3.649,2.18,5.327,3.226c1.126,0.702,2.508,1.367,2.486,2.886
                c-0.032,2.311,1.173,3.688,2.94,4.842c0.871,0.567,1.658,1.54,1.549,2.453c-0.448,3.788,2.318,7.285,0.618,11.098
                c4.224,3.402,7.195,8.184,13.081,8.726c0.627,0.058,1.263,0.435,1.818,0.784c6.364,4.02,12.308,8.657,17.844,13.688
                c3.855,3.503,7.826,4.05,12.463,3.052c2.938-0.631,5.628-0.775,7.258,2.5c0.86,1.729,2.716,1.857,4.327,2.041
                c3.655,0.42,6.182,2.154,7.57,5.609c0.863,2.147,2.431,3.291,4.848,3.754c3.879,0.744,6.796,4.022,10.863,4.505
                c0.423,0.05,0.983,0.495,1.153,0.896c1.562,3.691,5.201,4.838,8.35,5.578c3.753,0.883,7.678,2.933,11.833,0.499
                c3.15-1.845,6.584-2.474,9.668,0.528c0.741,0.721,1.803,1.082,2.779,0.875c3.829-0.811,6.725,0.953,9.144,3.262
                c3.425-0.453,6.38-2.732,9.528-1.424c3.398,1.414,7.183,0.287,10.504,2.103C340.04,575.382,341.665,575.191,342.914,574.26z
                M286.633,759.953c-12.397,2.201-24.637,5.449-37.538,4.209c4.326-3.377,6.148-8.864,11.817-11.746
                c-4.698-1.122-6.895-4.539-9.936-6.51c-3.675-2.38-4.603-5.238-5.162-8.951c-0.326-2.163-0.242-4.713-2.593-5.931
                c-2.887,0.651-2.423,3.636-4.125,4.956c-1.798,1.396-4.424,0.385-5.997,2.15c-2.296,2.578-5.277,3.852-8.537,4.459
                c-0.258,0.873-0.478,1.494-0.623,2.132c-0.454,1.997-0.922,4.015-3.418,4.347c-2.484,0.33-4.042-1.107-5.347-2.986
                c-0.74-1.066-1.072-2.475-2.867-3.207c-8.334,1.604-17.426,0.863-26.075,3.891c0.618,1.369,1.517,1.389,2.24,1.541
                c4.398,0.924,8.94,1.379,13.184,2.762c4.505,1.469,8.929,2.465,13.668,2.084c1.468-0.118,2.986,0.131,3.552,1.928
                c-0.445,1.486-2.458,1.102-2.914,2.521c4.095,0.021,8.507-2.318,12.337,1.73c-3.427,4.075-8.07,3.429-12.136,4.173
                c-4.179,0.766-8.104,3.049-12.625,1.832c3.644,2.609,8.59,1.513,11.795,4.659c-1.039,2.882-3.528,1.452-4.841,2.407
                c-5.116,3.721-10.725,3.588-16.524,2.579c-3.445-0.599-6.894-1.173-10.345-1.735c-0.45-0.074-0.923-0.011-1.606-0.011
                c1.446,1.442,0.141,2.962,0.454,4.105c1.492,1.395,3.613,1.528,4.634,3.712c-1.894,1.459-0.57,3.505-0.359,5.304
                c-2.988,1.683-6.011-0.052-8.886,0.774c0.674,1.332,1.694,1.479,2.642,1.691c3.262,0.736,6.579,1.234,9.912,1.061
                c5.979-0.311,11.408,1.738,16.888,3.62c0.96,0.33,1.828,0.892,1.435,2.511c-3.189,2.053-6.985,0.051-10.435,0.938
                c-2.327,2.891-1.673,6.172-1.218,9.582c4.08,1.869,10.381,1.639,9.303,9.048c-10.171,3.855-20.015-0.883-30.134-1.261
                c11.613,4.504,22.678,10.352,35.128,12.211c0.751-1.588,1.864-2.936,1.198-4.417c-1.529-3.403,1.035-4.931,2.94-6.591
                c2.054-1.789,3.762-1.434,4.604,1.106c0.987,2.976,2.91,5.194,4.771,7.531c0.717,0.9,1.946,2.062,1.135,3.03
                c-1.622,1.936-0.116,2.951,0.7,4.291c1.252,2.055,3.257,3.82,2.922,6.004c-1.044,2.319-3.557,1.129-4.306,3.041
                c0.758,1.144,1.857,1.879,2.99,2.59c5.384,3.374,8.146,2.665,11.231-2.896c0.569-1.026,1.269-2.042,0.224-3.173
                c-0.682-0.737-0.695-1.604-0.046-2.227c1.87-1.791,2.404-4.729,5.084-6.081c2.859,2.054,2.708,5.698,4.764,8.126
                c2.363,2.791,2.08,3.03-0.475,6.06c0.434,0.476,0.876,0.96,1.33,1.455c2.628-0.626,4.205-2.67,6.222-3.896
                c-1-3.93-0.729-4.515,2.637-5.577c1.552-0.49,3.149-0.84,4.966-1.315c-1.795-1.353-4.494,0.285-5.346-2.629
                c2.357-3.305,6.117-1.416,9.221-2.331c-0.809-1.159-1.855-2.315-0.2-3.595c1.841-1.424,0.902-2.789,0.151-4.095
                c-2.022-0.324-3.588,0.343-5.215,0.646c-4.908,0.917-9.816,1.843-14.751,2.59c-2.429,0.368-4.959,0.451-6.367-2.496
                c2.871-3.376,7.56-2.856,10.954-5.485c-3.904-0.841-7.527,2.019-11.007-0.385c1.426-2.392,1.426-2.392,6.044-5.088
                c-8.88,0.795-16.891,3.9-24.765-1.412c5.146-4.188,11.214-5.533,16.654-5.315c11.315,0.452,22.191-1.87,33.524-3.108
                c0.3-5.045,4.503-3.517,8.566-4.417c-2.886-1.039-1.938-3.138-2.378-4.455c-2.503-0.899-4.683-0.621-6.837-0.754
                c-3.265-0.201-6.424-0.289-9.345-2.628c-2.225-1.782-5.762-1.668-7.743-4.325c-0.267-0.357-1.277-0.125-1.932-0.236
                c-0.881-0.149-2.104,0.298-2.334-1.427c7.236-4.528,15.124-7.045,23.647-6.809c0.593-1.963-2.272-1.926-1.141-4.076
                c4.035-2.312,8.878-1.467,13.751-1.86c-4.202-0.8-7.316-3.204-10.486-5.973C279.736,760.606,283.733,761.921,286.633,759.953z
                M1136.113,884.535c4.617-1.443,8.492-4.33,12.703-6.197c6.777-3.006,14.369-4.092,20.426-8.916
                c1.377-1.097,3.32-1.494,5.018-2.179c1.508-0.608,3.221-0.757,4.767-2.262c-2.233-1.034-4.18-2.118-6.624-2.175
                c-2.186-0.05-4.658,0.613-6.114-1.179c-0.453-2.325,2.067-2.549,1.932-4.478c-6.179-0.671-12.038,2.944-18.151,1.533
                c-5.884-1.357-12.286,0.209-17.854-2.889c0.418-2.349,2.962-1.826,3.675-3.459c0.03-1.44-2.323-1.442-1.771-3.76
                c5.482-3.148,12.879-2.627,18.414-6.762c-0.285-1.739-2.949-1.975-2.289-4.281c1.245-1.873,3.449-2.251,5.558-2.463
                c8.478-0.852,16.521-3.401,24.55-6.034c3.992-1.31,6.216-7.769,4.024-11.615c-1.131-0.675-2.376-1.074-3.382-1.136
                c-5.82,3.537-12.163,5.016-18.102,7.453c-0.587,0.241-1.315,0.129-1.976,0.206c-1.926,0.225-1.437-1.788-1.817-2.128
                c-8.152,1.246-15.783,5.531-24.107-0.246c5.841-3.578,12.172-4.875,17.273-8.711c-0.58-2.465-0.263-5.409-3.08-5.964
                c-3.716-0.731-5.77-2.963-7.491-5.966c-1.003-1.748-2.208-3.486-4.602-3.245c-2.409,0.243-3.065,2.347-3.892,4.126
                c-1.051,2.262-1.852,4.641-2.931,6.887c-1.037,2.159-2.452,4.113-5.19,4.053c-3.086-0.066-4.105-2.416-4.915-4.878
                c-0.208-0.632-0.337-1.293-0.587-1.905c-0.464-1.135-1.162-2.047-2.541-2.047c-3.481-0.003-4.386-2.443-4.97-5.207
                c-0.239-1.137-0.437-2.289-0.784-3.394c-0.579-1.846-1.92-2.077-3.579-1.53c-1.165,0.383-2.071,1.147-2.717,2.111
                c-2.157,3.217-4.604,6.082-8.471,7.369c1.366,4.793,5.646,8.165,5.513,12.896c-1.793,1.959-3.895,0.434-5.681,1.566
                c2.297,3.504,6.368,5.596,7.312,9.338c-0.426,2.07-2.438,1.949-2.754,3.488c0.622,1.905,2.084,3.314,3.281,4.896
                c1.508,1.99,3.225,3.85,4.509,5.973c1.603,2.648,0.828,3.898-2.267,4.307c-1.888,0.25-4.028-0.431-5.719,1.622
                c0.951,2.558,3.006,4.277,4.858,6.136c1.882,1.885,3.964,3.613,5.289,5.962c1.42,2.517,0.783,4.095-1.985,4.94
                c-1.256,0.384-2.626,0.384-3.936,0.611c-1.781,0.31-3.706,0.386-4.719,2.66c2.392,8.316,6.898,15.596,12.776,22.105
                c1.45,1.605,2.981,3.168,4.216,4.931c1.434,2.046,0.983,3.247-1.292,4.31c-0.6,0.279-1.258,0.435-1.894,0.635
                c-4.916,1.543-6.269,3.407-5.523,8.371c0.845,5.638,2.934,10.847,5.976,15.836c5.311,0,10.468-0.057,15.624,0.025
                c2.186,0.035,3.564-0.967,4.961-2.922c-4.141-0.609-9.287,0.873-9.116-5.418c-3.812-1.714-8.274,0.791-11.442-2.325
                c0.85-4.806,6.518-1.411,7.825-5.126c-0.558-1.52-3.104-0.693-3.097-3.033c1.035-2.46,3.56-2.328,5.791-2.406
                c9.086-0.32,17.608-3.421,26.392-5.22c1.769-0.362,3.469-1.062,4.915-2.325c2.086-1.822,4.586-2.524,7.379-2.496
                c2.861,0.029,5.508-0.832,8.915-2.829c-3.429,0-5.747,0.024-8.066-0.009c-1.483-0.021-3.431,0.309-4.151-1.09
                c-1.341-2.606-3.279-1.89-5.027-1.227c-5.408,2.054-10.787,0.309-16.172,0.121
                C1138.038,887.534,1136.982,886.889,1136.113,884.535z M247.953,333.969c-3.601-8.738-4.505-18.128-9.333-26.089
                c-0.475,0.717-0.605,1.331-0.605,1.944c-0.013,17.493-0.009,34.986-0.018,52.479c-0.001,1.51,0.126,2.984,0.659,4.418
                c1.711,4.61,1.977,9.395,1.856,14.263c-0.127,5.162,0.002,10.329-0.043,15.493c-0.019,2.191,0.207,4.305,1.015,6.364
                c3.086,7.867,4.498,16.015,3.821,24.461c-0.679,8.463,0.866,16.525,3.814,24.438c3.079,8.264,5.891,16.628,8.826,24.945
                c0.65,1.841,1.102,3.943,4.179,3.288c0-2.86,0-5.68,0-8.5c0-23.99-0.02-47.981,0.039-71.971c0.004-1.858-0.772-4.004,0.953-5.463
                c1.148-0.186,1.848,0.261,2.328,0.979c3.952,5.921,8.5,11.547,7.88,19.404c-0.247,3.132,0.353,6.322,0.311,9.482
                c-0.045,3.429,0.842,6.51,2.363,9.557c5.545,11.105,10.171,22.482,9.17,35.292c-0.209,2.676,1.028,4.293,3.208,5.673
                c2.667,1.688,5.146,3.678,7.677,5.574c2.809,2.104,4.337,4.922,4.862,8.406c0.571,3.782,1.668,7.505,1.999,11.301
                c0.668,7.699,5.718,12.443,11.001,17.02c1.917,1.659,4.37,2.602,7.422,3.477c-1.434-8.515-2.838-16.353-4.043-24.221
                c-0.593-3.871-2.469-6.938-5.444-9.224c-4.788-3.678-8.666-8.277-13.076-12.327c-1.802-1.656-2.785-3.64-3.497-5.908
                c-4.438-14.139-7.96-28.529-11.85-42.819c-0.301-1.105-0.801-2.223,0.197-3.791c4.812,2.306,9.739,3.916,11.469,10.276
                c1.65,6.068,4.476,11.815,6.743,17.719c0.602,1.567,1.082,3.223,2.632,4.113c2.708,1.555,3.644,4.157,4.416,6.933
                c0.758,2.725,1.657,5.409,2.46,8.121c0.261,0.882,0.983,1.87-0.624,2.743c-5.204-2.995-9.537-7.594-15.988-11.132
                c2.058,4.436,1.925,8.545,4.664,11.455c3.537,3.757,6.955,7.774,11.071,10.801c5.993,4.406,8.804,9.982,9.6,17.229
                c0.78,7.1,2.265,14.128,3.602,21.157c0.706,3.709,0.861,3.68,5.106,3.084c0.676-3.49-0.367-6.903-0.773-10.326
                c-1.219-10.275-3.2-20.277-7.993-29.703c-4.814-9.468-8.016-19.644-10.995-29.406c0.585-1.726,2.542-0.982,2.663-2.081
                c-14.083-17.061-21.459-36.774-26.441-58.108c-3.809-0.777-5.57-4.941-8.981-7.268c0.704,2.782,1.867,5.335,2.651,8
                c0.726,2.467,2.99,4.884,0.872,7.845c-2.293-0.537-3.58-2.134-4.848-3.614c-4.007-4.681-7.865-9.489-11.882-14.161
                c-1.701-1.979-2.509-4.228-3.032-6.719c-1.751-8.352-1.88-16.776-2.151-25.286c-0.48-15.105-0.698-30.239-2.872-45.298
                c-1.971-13.661-2.727-27.498-3.991-41.26c-1.066-11.607-2.087-23.218-3.142-34.826c-0.118-1.306,0.113-3.11-1.23-3.585
                c-3.025-1.068-2.282-3.148-1.915-5.266c0.369-2.131,0.816-4.258,1.027-6.407c0.385-3.925,1.892-7.764,1.012-12.462
                c-1.25,2.31-1.49,4.239-1.799,6.178c-2.206,13.821-3.226,27.777-4.759,41.673c-0.455,4.127-0.893,8.303,0.135,12.429
                c0.772,3.102,0.956,6.28,0.925,9.429c-0.098,9.948,1.683,19.531,5.085,28.849c0.919,2.518,1.323,5.168,1.51,7.819
                c0.467,6.646,0.824,13.3,1.183,19.954C249.084,331.719,249.488,332.755,247.953,333.969z M138.441,563.026
                c3.706-3.067,4.617-2.812,5.951,1.628c0.334,1.109,0.444,2.285,0.741,3.408c0.927,3.516,0.879,7.25,2.505,11.209
                c0.817-3.184,0.401-6.115,3.393-8.168c2.107-1.445,0.591-6.026,4.446-7.098c4.172,3.121,2.295,8.59,4.858,12.199
                c4.63-0.186,5.272,0.363,5.556,4.677c0.078,1.187-0.189,2.458,0.777,4.275c1.404-3.373,1.996-6.303,4.855-7.958
                c1.123-0.649,0.998-2.555-0.122-3.568c-2.188-1.98-3.754-4.508-6.601-5.954c-3.823-1.942-6.244-6.213-10.925-6.914
                c-2.135-3.691-5.53-4.459-9.285-3.853c-3.131-3.426-4.136-9.096-10.343-8.117c-1.074-2.997-2.387-5.416-5.998-5.048
                c-0.503,0.052-1.387-0.54-1.578-1.033c-1.144-2.952-3.634-3.634-6.207-3.781c-2.49-4.413-2.49-4.413-6.842-7.263
                c0.218-4.189-0.194-3.843-3.141-7.336c-2.148-2.547-3.458-5.815-6.443-7.719c-0.105-0.067-0.118-0.305-0.144-0.467
                c-0.938-5.98-4.615-9.272-10.272-10.279c-0.924-2.313,0.099-5.663-2.749-6.215c-3.245-0.629-5.16-2.69-7.429-4.581
                c-2.192-1.826-4.689-3.255-7.688-3.157c-1.778,0.059-3.103-0.377-4.374-1.777c-1.992-2.196-4.857-3.311-7.665-3.029
                c-3.557,0.356-6.477-2.466-10.059-1.471c-0.747,0.207-1.396-0.767-1.893-1.449c-1.88-2.58-4.467-3.466-7.475-2.739
                c-2.018,0.487-3.209-0.457-4.889-1.347c-3.663-1.94-7.66-3.286-11.392-5.178c-0.539-0.273-1.365-0.265-1.968-0.084
                c-4.092,1.228-7.528-2.275-11.532-1.543c-2.737-3.148-6.698-2.169-10.09-3.141c-1.197,5.334-0.941,35.8,0.538,39.538
                c0.889-3.427,3.635-4.861,5.364-7.188c1.58-2.126,3.428-1.775,4.366,0.793c0.856,2.343,1.152,4.846,1.33,7.35
                c0.133,1.869,0.183,3.817,2.118,5.65c1.058-3.886,4.688-5.544,5.332-9.293c0.289-1.68,2.295-1.66,3.507-0.193
                c1.408,1.705,1.24,3.761,1.131,5.736c-0.169,3.064,0.002,5.896,2.216,8.405c1.587,1.8,1.859,4.226,1.863,6.604
                c0.003,1.354,0.001,2.708,0.001,4.062c0.326,0.016,0.651,0.031,0.978,0.047c-0.016-3.927,4.695-5.998,3.667-10.322
                c2.776-2.84,4.034-6.508,4.881-10.497c3.855,1.653,3.98,4.544,4.302,7.333c0.269,2.327,0.077,4.809,1.81,6.861
                c1.596-1.32,1.379-3.625,3.494-3.85c2.52,1.99,5.649,9.499,6.185,14.995c1.779-2.474,4.601-4.038,4.703-7.469
                c0.024-0.796,0.842-1.435,1.746-1.542c0.954-0.113,1.941,0.562,1.834,1.272c-0.715,4.774,3.234,8.284,3.333,12.813
                c0.019,0.865-0.4,2.087,1.356,2.475c1.07-4.998,4.495-8.444,8.407-12.547c5.081,7.549,8.129,15.421,9.811,24.658
                c1.843-2.613,1.655-4.85,2.169-6.861c0.438-1.718,0.98-3.373,3.156-3.11c1.868,0.226,2.229-1.017,2.709-2.255
                c0.429-1.106,0.863-2.182,2.326-2.365c2.187,0.537,2.283,2.568,2.851,4.236c0.58,1.703,0.756,3.588,2.469,4.993
                c0.928-0.841,0.666-2.73,2.133-2.888c3.068-0.33,4.723-2.361,5.855-4.953c5.404,1.613,2.62,7.402,5.917,10.123
                c3.189-2.918,5.406-0.163,8.164,1.3c3.206,1.7,4.295,4.161,3.99,8.304C133.824,559.684,136.173,561.395,138.441,563.026z
                M772.91,323.655c-1.814,3.628-5.271,4.886-9.305,6.17c-3.42,1.089-6.088,4.426-9.92,5.337c-0.318,0.075-0.473,0.827-0.707,1.262
                c-2.469,4.563-8.188,5.47-10.947,9.714c-0.072,0.11-0.3,0.155-0.459,0.166c-3.699,0.238-5.699,2.912-7.572,5.583
                c-3.713-1.504-6.705,0.429-8.408,2.602c-2.332,2.976-5.336,4.855-8.092,7.161c-4.845-1.792-5.579,3.768-8.783,4.904
                c-0.469,0.166-0.978,0.214-1.45,0.37c-7.378,2.445-14.028,6.1-19.612,11.604c-1.295,1.276-2.729,2.546-4.334,3.35
                c-8.473,4.241-16.123,9.983-25.141,13.266c-7.076,2.577-10.296,6.863-12.26,14.821c5.272-1.768,9.711-5.296,15.479-5.56
                c5.511-0.252,10.983,0.998,17.364,0.643c-2.124-2.435-3.977-3.212-6.146-3.189c-2.555,0.026-3.926-1.571-5.057-3.49
                c-0.752-1.277-0.033-2.871,1.006-2.781c3.087,0.267,4.97-2.444,7.859-2.601c4.77-0.259,9.145-1.564,13.619-3.696
                c6.368-3.033,11.408-7.006,15.127-12.868c0.438-0.691,1.033-1.311,1.658-1.843c0.334-0.286,0.887-0.315,1.105-0.385
                c1.836,0.323,1.969,2.563,3.726,2.597c3.19-1.896,4.659-5.594,7.228-7.667c3.957-3.193,6.952-7.041,10.252-10.712
                c1.768-1.967,3.714-2.889,6.571-2.702c2.147,0.141,4.843,0.173,6.583-1.273c1.997-1.659,4.366-2.244,6.385-3.071
                c0.672-1.42,0.723-2.528,0.246-3.676c-0.484-1.165-2.844-0.49-2.466-2.46c0.136-0.175,0.209-0.367,0.341-0.423
                c1.523-0.645,3.084-1.212,4.58-1.914c1.637-0.768,3.232-1.479,4.423-3.08c3.505-4.717,8.657-7.615,14.36-8.437
                c2.227-4.827,2.247-4.751,4.47-5.354c7.232-1.961,14.022-0.728,20.828,2.823c1.983-2.126,4.756-3.297,7.378-4.714
                c1.467-0.792,3.025-1.464,4.095-2.799c4.444-5.546,10.96-7.989,16.929-11.198c2.562-1.377,3.674-0.647,5.088,2.142
                c0.807,1.588,0.731,3.583,2.49,5.238c6.16-4.379,13.535-3.256,21.041-4.861c2.524,10.455,5.387,20.455,9.365,30.298
                c3.506-0.935,6.405-2.545,9.367-3.96c5.996-2.864,11.828-6.122,18.84-5.764c1.438,0.074,2.898-0.71,4.373-0.935
                c7.361-1.12,13.963-5.507,20.912-4.774c6.437-6.643,8.716-14.717,11.848-22.367c0.377-0.922,0.838-1.816,1.316-2.691
                c1.714-3.13,3.886-5.726,7.684-6.323c2.581-0.405,5.102-0.868,7.418-2.279c1.359-0.828,2.907-0.412,4.209,0.583
                c1.498,1.144,3.195,1.012,4.733,0.333c1.62-0.716,2.809-2.483,4.9-2.022c2.001,0.441,2.423,2.637,3.929,3.644
                c0.41,0.275,0.801,0.582,1.332,0.97c3.2-1.256,6.504-2.402,9.674-3.84c3.117-1.414,6.763-1.167,9.889-3.068
                c-2.004-1.173-3.783-1.917-5.922-1.355c-1.841,0.484-3.633,0.102-5.299-0.826c-3.783-2.107-7.324-1.802-10.834,0.785
                c-1.293,0.952-2.229,2.534-3.871,2.694c-2.234-0.345-3.334-2.519-5.179-2.699c-2.4,0.142-3.197,2.791-5.735,2.749
                c-3.201-1.92-6.721-3.489-10.774-1.404c-1.37,0.705-2.562-0.104-3.593-1.04c-1.105-1.001-1.447-2.648-3.121-3.547
                c-2.531,0.7-4.849-0.32-7.295-1.592c-1.852-0.963-4.111-2.87-5.936-2.101c-3.425,1.443-7.767,0.615-10.384,4.179
                c-0.764,1.039-2.185,1.5-3.631,0.955c-0.766-0.289-1.535-0.705-2.378-0.272c-4.473,2.299-9.239,4.185-13.296,7.069
                c-2.39,1.699-4.491,2.477-7.183,1.911c-0.895,1.31-1.588,2.442-2.395,3.487c-2.105,2.725-2.9,2.835-5.639,0.784
                c-0.786-0.589-1.385-1.62-2.501-1.43c-4.503,0.768-9.604-0.961-13.276,3.378c-1.23,1.455-3.109,1.105-4.631,0.27
                c-2.625-1.439-5.594-0.87-7.547,0.318c-2.696,1.642-5.146,1.939-8.135,1.309c-2.122,2.871-4.679,4.251-7.846,1.631
                c-1.743,1.341-2.568,3.678-5.035,3.226c-1.998-0.367-3.549,0.392-4.992,1.656c-1.023,0.896-2.246,1.246-3.621,1.472
                c-4.354,0.713-8.529,1.828-11.029,6.056c-1.247,2.109-3.031,2.452-5.055,0.952c-0.664-0.492-1.268-1.096-2.264-1.075
                C787.632,321.611,780.383,321.582,772.91,323.655z M133.858,227.828c1.345,0.954,1.042,1.85,1.075,2.619
                c0.365,8.718-1.081,17.264-2.442,25.822c-1.254,7.89-2.845,15.756-3.538,23.697c-0.869,9.958-0.895,20.004,0.037,29.966
                c0.505,5.395-0.529,9.782-4.659,13.394c-2.652,2.319-3.694,5.334-3.741,8.891c-0.079,5.995-0.173,12.012-0.768,17.97
                c-0.553,5.54,0.615,10.513,3.132,15.356c1.611,3.099,2.993,6.318,4.447,9.498c0.635,1.389,1.224,2.712,3.052,2.908
                c1.002,0.107,1.997,0.655,2.491,1.527c1.923,3.4,5.77,5.05,7.546,8.992c2.625-0.929,4.885,0.452,7.576,0.923
                c0.2-5.186,0.308-10.188-0.347-15.129c-0.839-6.332-0.502-12.613-0.201-18.95c0.449-9.44,1.55-18.803,1.53-28.325
                c-0.054-25.664-0.206-51.329-0.226-76.993c-0.005-6.999-0.146-13.998-0.249-20.997c-0.047-3.21-0.419-6.421-2.48-9
                c-2.371-2.966-2.776-6.319-2.673-9.886c0.072-2.498,0.281-5.009,0.101-7.49c-0.184-2.533,0.63-4.588,2.213-6.41
                c2.185-2.516,4.474-4.941,6.64-7.472c1.289-1.505,2.608-3.002,2.653-5.214c0.284-13.996,0.605-27.99,0.943-41.984
                c0.034-1.421-0.244-2.6-1.463-3.482c-2.804-2.026-5.58-4.09-10.313-4.741c2.123,1.97,2.045,3.731,1.829,5.542
                c-0.633,5.301-0.97,10.615-0.514,15.954c0.286,3.343-0.582,5.997-4.313,6.738c-2.145,0.426-2.879,1.756-3.233,3.81
                c-0.794,4.601-1.548,9.169-1.481,13.878c0.078,5.496-0.151,10.997-0.304,16.495c-0.024,0.879,0.344,2.117-1.2,2.274
                c-2.071-0.766-1.931-4.615-5.159-3.775c-0.113,0.177-0.28,0.316-0.276,0.451c0.297,11.646,0.214,23.281-2.82,34.626
                c-0.84,3.139,0.475,4.57,2.708,5.958C131.438,233.042,131.61,229.897,133.858,227.828z M544.022,886.071
                c-5.187-1.587-5.635-2.267-3.227-6.489c1.153-2.022,1.173-3.579,0.196-5.54c-0.967-1.942-1.564-4.059-1.057-6.276
                c0.094-0.408,0.593-0.725,0.737-0.891c1.509-0.658,2.717,0.926,4.197-0.142c-0.446-1.908-1.616-3.534-2.511-5.28
                c-1.6-3.117-2.994-6.303-3.571-9.803c-0.274-1.66-0.55-3.254,0.221-4.879c0.92-1.939,3.156-0.353,4.615-2.01
                c-3.495-6.439-6.022-13.454-8.86-20.285c-1.419,0.336-1.639,1.393-2.077,2.266c-2.026,4.039-4.558,7.728-7.534,11.133
                c-1.944,2.225-4.117,4.34-2.956,7.877c0.414,1.259-0.785,2.451-1.424,3.618c-0.58,1.058-2.021,1.846-1.29,3.382
                c2.12,0.936,4.602-1.024,7.118,1.223c-3.32,3.376-4.618,7.639-6.152,11.809c-0.45,1.221-1.195,2.404-2.05,3.393
                c-2.422,2.801-6.295,3.708-9.512,1.953c-5.06-2.761-8.208-7.118-9.504-12.748c-0.071-0.311,0.478-0.766,0.742-1.158
                c1.544-0.174,2.23,1.61,3.891,1.374c0.048-3.126-1.447-5.793-3.082-8.177c-2.691-3.921-5.794-7.556-6.75-12.78
                c-4.689,8.35-7.413,17.401-13.356,24.509c0.957,1.119,1.634,0.709,2.23,0.591c1.173-0.232,2.394-0.511,3.251,0.534
                c1.062,1.296,0.184,2.567-0.442,3.694c-1.627,2.932-3.923,5.354-6.14,7.843c-1.637,1.836-3.888,3.186-4.73,5.658
                c3.701,5.261,1.676,10.416-7.272,18.271c1.999,2.375,0.416,4.511-0.738,6.498c0.394,1.64,2.111,2.496,1.534,5.086
                c-0.134,0.985-2.654,0.163-3.173,2.11c1.207,4.436,5.013,7.414,7.396,11.422c16.885,0,33.696-0.035,50.506,0.043
                c2.286,0.012,3.616-0.766,4.745-2.754c2.627-4.625,5.531-9.094,8.263-13.662c0.647-1.082,1.667-2.088,1.34-3.542
                c-3.423-1.619-3.571-1.908-1.907-4.481c0.812-1.254,1.816-2.382,2.689-3.599C540.501,892.9,542.782,890.025,544.022,886.071z
                M296.007,194.431c0.438,7.486,0.8,14.622,1.218,21.732c0.653,11.107,0.512,22.328,3.688,33.181
                c1.499,5.119,3.026,10.19,5.439,14.984c2.096,4.165,1.333,5.613-3.291,7.252c-0.292,0.104-0.532,0.356-1.078,0.734
                c0.646,4.759-1.186,9.745,0.521,14.667c1.147,3.309,1.883,6.722,2.728,10.113c1.729,6.94,3.009,14.144,6.43,20.421
                c3.092,5.676,4.884,11.576,6.084,17.788c0.726,3.759,1.45,7.518,2.194,11.274c0.836,4.223,2.357,8.174,5.039,12.721
                c0.978-7.162,3.819-12.873,5.389-18.99c0.35-1.365,0.3-3.526,2.877-3.334c1.203,1.433,0.894,3.146,0.931,4.749
                c0.157,6.881-0.695,13.631-2.891,20.173c-5.078,15.128-3.428,30.333-0.711,45.712c1.087-0.393,2.017-0.716,2.937-1.062
                c6.532-2.463,6.539-2.47,10.832,3.217c1.003,1.329,1.876,2.76,2.938,4.037c0.625,0.751,0.749,2.252,2.415,1.938
                c-0.442-4.548-0.957-9.005-1.296-13.476c-0.893-11.78-2.175-23.518-0.727-35.394c1.459-11.969,1.07-23.896-3.421-35.361
                c-0.423-1.08-0.601-2.254-0.989-3.351c-1.368-3.862-0.736-8.875-6.001-10.524c-0.124-0.039-0.21-0.252-0.28-0.4
                c-2.659-5.648-7.028-10.49-7.125-17.333c-0.053-3.781-1.1-7.742-3.48-10.8c-2.77-3.558-3.468-7.435-3.386-11.696
                c0.243-12.67-0.876-25.3-1.223-37.954c-0.17-6.2-2.545-11.88-5.042-17.513c1.189,9.315,1.139,18.567,0.473,27.837
                c-0.192,2.674-0.111,5.436,0.755,7.924c2.021,5.801,1.709,11.79,1.956,17.743c0.038,0.915,0.348,2.058-1.188,2.668
                c-2.91-1.883-1.765-6.038-4.057-8.339c-0.979,0.865-0.379,2.646-2.385,2.708c-1.1-1.406-0.998-3.242-1.266-4.991
                c-0.675-4.392-0.486-9.004-3.515-12.794c-1.046-1.31-0.514-2.886,0.203-4.259c1.99-3.814,2.3-7.92,2.358-12.125
                c0.142-10.152,0.117-20.319,1.827-30.382c0.165-0.967,0.283-2.105-0.065-2.967c-1.684-4.168-0.813-8.236,0.44-12.219
                c0.867-2.755,0.809-5.312-0.75-7.651c-2.831-4.25-3.356-8.925-3.16-13.853c0.106-2.656-0.097-5.328-0.246-7.988
                c-0.148-2.646-3.37-12.919-4.58-14.869c-4.081,5.368-6.042,13.375-5.008,21.264c0.497,3.791,1.354,7.533,1.956,11.312
                c0.674,4.237,0.997,8.494,0.671,12.8C299.349,193.992,298.057,194.162,296.007,194.431z M265.642,525.043
                c2.052-0.195,4.003-1.01,6-0.418c4.441,1.314,8.558,3.922,13.515,3.219c0.681-0.098,1.534,0.585,2.219,1.04
                c1.681,1.117,3.468,1.913,5.431,2.435c2.318,0.615,4.16,2.042,5.657,3.915c1.876,2.349,3.809,4.731,4.898,7.509
                c1.863,4.75,5.811,5.494,9.976,5.365c2.702-0.085,5.125,0.238,6.839,2.197c2.702,3.088,6.684,5.113,7.97,9.398
                c0.266,0.885,0.978,1.377,1.773,1.614c2.585,0.771,4.197,2.583,5.458,4.829c1.044,1.859,2.417,3.098,4.786,2.888
                c2.155-0.191,3.56,1.185,4.103,3.009c0.94,3.162,3.21,4.863,5.825,6.457c1.835,1.119,3.454,2.59,5.181,3.888
                c4.465,3.357,8.172,7.915,14.18,8.837c0.772,0.119,1.766,0.574,2.145,1.189c2.611,4.239,6.882,6.244,10.855,8.801
                c1.601,1.029,2.85,1.854,4.77,1.898c3.329,0.078,6.358,1.424,8.238,4.088c3.232,4.582,7.152,8.207,12.152,10.703
                c0.436,0.217,0.851,0.563,1.153,0.947c3.345,4.252,9.041,5.671,12.206,10.158c0.604,0.855,1.791,0.76,2.753,1
                c1.29,0.322,2.835,0.388,3.788,1.159c4.054,3.286,9.123,3.842,13.652,5.716c6.301,2.607,13.482,3.914,18.586,9.116
                c0.907,0.925,2.126,0.831,3.293,0.79c3.371-0.119,6.13,1.342,8.625,3.396c1.016,0.836,1.783,1.996,3.343,1.858
                c2.97-0.263,4.999,1.36,6.888,3.38c0.779,0.832,1.728,1.661,2.765,2.071c6.073,2.401,11.618,5.425,14.999,11.378
                c0.289,0.509,1.045,0.752,1.765,1.241c1.815-1.601,3.871-2.206,6.95-0.604c-1.327-4.146-3.536-6.25-6.05-8.127
                c-3.334-2.488-6.827-4.791-9.982-7.488c-5.258-4.492-10.987-8.053-17.541-10.308c-2.845-0.979-5.563-2.283-7.676-4.526
                c-2.648-2.812-5.795-4.992-8.919-7.209c-11.118-7.891-21.825-16.381-33.761-23.07c-2.443-1.369-4.265-3.277-4.879-6.026
                c-0.748-3.345-2.917-5.415-5.551-7.351c-9.075-6.666-19.488-10.86-29.57-15.494c-10.104-4.644-20.567-8.546-30.698-13.206
                c-5.293-2.436-9.912-5.378-12.644-10.755c-1.746-3.436-4.792-5.482-8.262-6.9c-2-0.816-4.056-1.508-6.119-2.154
                c-4.893-1.537-9.063-4.106-12.265-8.186c-2.647-3.373-6.959-5.198-8.754-9.402c-0.231-0.541-0.947-0.937-1.517-1.263
                c-6.352-3.642-12.033-8.348-18.692-11.524c-4.813-2.297-9.789-4.877-13.214-8.829c-4.438-5.12-9.986-8.167-15.688-11.222
                c-6.034-3.23-11.891-6.697-17.014-11.303c-2.365-2.126-5.031-3.908-7.979-5.074c-6.661-2.633-13.188-5.523-19.415-9.076
                c-0.192-0.109-0.571,0.107-0.91,0.183c-0.659,1.513,0.333,2.883,0.465,4.328c0.429,4.699,4.004,7.649,6.564,11.116
                c0.856,1.16,2.631,1.532,4.17,1.068c8.976-2.704,14.401,4.088,20.951,7.826c0.645,0.368,1.134,1.278,1.375,2.039
                c1.054,3.321,2.802,5.903,6.149,7.404c1.864,0.836,2.762,2.848,3.662,4.629c1.35,2.672,2.592,5.4,3.843,8.121
                c0.559,1.214,0.977,2.577,2.333,3.103C262.908,521.655,264.069,523.562,265.642,525.043z M429.244,568.899
                c-0.307-1.349-0.363-2.021-0.61-2.614c-7.154-17.205-13.935-34.561-20.922-51.832c-7.265-17.959-17.147-34.578-27.682-50.781
                c-3.956-6.083-7.307-12.329-8.154-19.586c-0.673-5.763-3.615-9.204-8.947-11.428c-6.266-2.613-11.205-6.845-14.367-13.218
                c-2.416-4.87-5.72-9.311-9.567-13.12c-1.864,0.746-3.388,1.341-4.9,1.964c-3.956,1.63-4.32,2.38-3.527,7.194
                c1.962-0.01,2.481-2.792,4.846-2.41c2.143,1.525,2.547,3.979,2.72,6.481c0.286,4.152,0.472,8.311,0.71,12.466
                c0.124,2.156,0.052,4.351,0.971,6.379c1.202,2.652,1.299,5.467,1.392,8.308c0.113,3.443-0.632,6.99,1.328,10.238
                c0.366,0.605,0.267,1.675,0.038,2.426c-1.432,4.697,0.095,8.872,2.19,12.936c2.821,5.471,5.767,10.876,8.556,16.363
                c2.147,4.225,4.913,5.127,9.834,2.839c-3.187-8.507-5.433-17.442-11.783-24.477c-1.094-1.212-1.997-2.709-2.598-4.229
                c-1.239-3.136-2.631-6.33-1.582-9.811c0.771,0,1.175-0.127,1.397,0.02c1.847,1.224,2.676,3.381,4.273,4.814
                c8.315,7.461,12.644,16.903,13.985,27.849c0.464,3.791,2.216,6.832,4.855,9.582c2.867,2.99,5.752,6.043,6.994,10.2
                c2.095,7.011,4.339,13.979,6.305,21.025c0.778,2.79,2.139,5.696,0.288,8.663c-0.365,0.586-0.024,1.258,0.341,1.85
                c2.932,4.748,3.919,10.215,5.695,15.393c0.411,1.197,0.398,2.697,2.085,3.562c3.787-0.715,7.205,0.365,9.996,3.553
                c0.825,0.942,2.216,1.453,3.427,1.98c5.496,2.395,10.588,5.378,14.808,10.194C423.299,568.61,425.949,568.514,429.244,568.899z
                M299.607,380.039c-0.523,1.395-0.046,1.932,0.244,2.496c4.576,8.879,8.93,17.878,14.354,26.294
                c2.26,3.507,4.094,7.22,5.66,11.101c1.806,4.476,3.784,8.91,7.875,11.933c0.645,0.477,1.336,1.191,1.532,1.922
                c0.76,2.836,2.412,5.029,4.443,7.049c2.063,2.053,3.34,4.742,5.48,7.955c0.53-4.8-0.652-8.178-1.674-11.659
                c-1.085-3.696-0.441-7.625-0.801-11.434c-0.329-3.479,0.418-7.215-1.935-10.323c-2.489,0.877-3.308,3.647-5.869,4.048
                c-1.29-1.5-0.717-3.238-0.719-4.815c-0.008-7.169-0.016-14.352-1.4-21.406c-1.473-7.512-1.006-14.93,0.528-22.319
                c0.912-4.394,2.144-8.722,3.215-13.084c1.168-4.755,2.076-9.535,1.464-15.617c-3.005,7.401-4.686,13.997-5.957,20.521
                c-1.74-0.016-2.243-0.73-2.58-1.467c-2.212-4.841-4.407-9.658-5.499-14.952c-2.001-9.708-2.78-19.791-8.335-28.491
                c-0.874-1.369-1.177-3.101-1.749-4.663c-3.271-8.93-5.098-18.272-7.508-27.432c-1.406-5.344-0.327-11.127,0.192-16.701
                c3.15,0.288,4.258,0.175,4.581-0.423c0.864-1.605-0.186-2.856-0.812-4.182c-0.071-0.15-0.162-0.292-0.23-0.443
                c-3.771-8.413-6.243-17.164-7.268-26.355c-1.59-14.253-1.408-28.611-2.711-42.878c-0.059-0.643-0.051-1.337,0.518-1.835
                c1.261-1.301,3.362,0.034,4.656-1.522c0-0.912,0.001-1.891,0-2.87c-0.007-6.712-2.175-13.135-2.642-19.786
                c-0.475-6.777,0.456-13.301,3.713-19.344c0.908-1.685,1.756-3.675,4.453-3.515c0.354,0.527,0.886,1.014,1.045,1.603
                c2.124,7.837,5.157,15.51,4.3,23.893c-0.471,4.603,0.364,8.928,2.94,12.864c1.86,2.843,2.011,5.849,0.935,9.074
                c-0.786,2.354-2.01,4.973-1.23,7.292c1.581,4.702,0.512,9.218,0.018,13.81c-0.839,7.803-0.74,15.638-0.896,23.466
                c-0.063,3.161,0.295,6.486-1.059,9.374c-1.657,3.536-2.33,6.635,0.139,10.146c0.961,1.367,0.824,3.51,1.167,5.304
                c0.079,0.414,0.235,0.623,0.958,0.581c0.505,0.072,0.694-1.362,1.845-0.894c1.506,1.92,0.596,4.878,2.76,6.956
                c0.191-2.674,0.345-5.193-0.575-7.451c-1.427-3.504-1.788-7.131-1.638-10.775c0.423-10.342,0.336-20.644-1.217-30.904
                c-0.211-1.396-0.446-3.004,1.407-3.834c0.425,0.296,0.926,0.495,1.195,0.864c0.484,0.665,0.911,1.397,1.22,2.159
                c3.14,7.754,5.696,15.649,5.7,24.148c0.006,11.83,1.372,23.619,1.08,35.46c-0.062,2.511,0.08,5.093,1.711,7.158
                c3.568,4.518,4.561,9.898,5.434,15.342c1.056,6.583,3.758,12.226,9.285,16.252c0.986,0.718,1.626,1.649,1.871,2.848
                c2.037,10.007,6.655,19.385,7.053,29.987c1.417-0.832,1.67-1.962,1.631-3.135c-0.06-1.827-0.144-3.665-0.407-5.47
                c-0.816-5.599-2.736-11.268-2.285-16.75c0.391-4.755-1.434-8.184-2.759-12.029c-1.741-0.183-1.634,2.447-3.581,1.639
                c-0.943-0.454-1.312-1.646-2.028-2.54c-3.419-4.268-5.104-8.825-3.122-14.37c0.987-2.762,0.21-5.465-1.345-8.088
                c-2.895-4.881-5.136-10-5.648-15.838c-1.383-15.758-3.378-31.47-4.404-47.248c-0.646-9.931-0.988-19.958,0.435-29.92
                c0.447-3.128,0.764-6.421-0.114-9.452c-2.692-9.296-4.051-18.872-6.26-28.266c-0.763-3.246-2.587-6.157-2.886-9.523
                c-0.18-2.021-3.015-1.539-3.194-3.429c-0.301-3.187-2.44-3.976-5.146-4.358c-2.342-0.331-3.953-1.748-4.485-4.118
                c-0.679-3.021-2.983-5-4.508-7.961c-2.597,3.572-3.164,7.335-4.051,10.983c-2.791,11.473-5.731,22.911-8.35,34.423
                c-1.526,6.708-1.702,13.7-0.195,20.311c1.066,4.68,2.317,9.341,3.058,14.092c1.971,12.643,4.915,25.178,4.364,38.119
                c-0.055,1.285,0.339,2.645,0.769,3.885c2.274,6.559,2.833,13.25,2.417,20.177c-0.7,11.643-0.958,23.299,1.999,34.766
                c0.619,2.402,0.965,4.919,1.07,7.399c0.118,2.787,0.966,5.182,2.584,7.404c1.275,1.75,2.42,3.592,3.085,5.684
                c0.76,2.388,0.352,4.199-2.218,5.264c-1.319,0.547-2.969,0.783-3.501,2.28c1.289,9.306,6.71,16.362,12.055,23.351
                c3.883,5.077,6.599,10.576,6.606,16.891c0.014,11.151,0.523,22.332-0.854,33.452c-0.112,0.904,0.287,2.078-1.612,2.649
                C312.329,400.276,308.79,388.634,299.607,380.039z M914.139,179.111c-5.537-0.468-10.14,0.588-13.432,5.225
                c-1.272,1.793-3.104,2.998-5.229,3.69c-2.845,0.927-5.683,1.875-8.526,2.803c-18.133,5.922-37.059,9.639-53.824,19.364
                c-0.143,0.083-0.294,0.156-0.445,0.225c-2.627,1.194-5.178,1.712-8.187,0.925c-5.399-1.414-10.711-0.219-15.389,2.578
                c-4.786,2.861-9.884,4.682-15.147,6.307c-4.766,1.471-9.521,3.057-14.115,4.987c-16.477,6.919-33.393,12.353-51.011,15.436
                c-6.128,1.072-11.962,2.75-17.389,5.89c-3.495,2.023-7.431,3.391-11.448,2.895c-4.879-0.603-9.668-0.494-14.398,0.491
                c-7.8,1.624-16.074,1.964-23.113,5.933c-6.926,3.905-14.396,5.971-21.797,8.426c-1.832,0.607-3.639,0.979-5.256-0.2
                c-2.737-1.996-5.751-1.745-8.647-0.998c-4.816,1.244-9.543,2.835-14.285,4.351c-0.765,0.244-1.636,0.694-2.077,1.318
                c-2.445,3.453-5.913,3.293-9.458,2.806c-2.306-0.317-4.585-0.83-6.881-1.223c-5.865-1.005-8.84,0.378-11.015,5.906
                c-1.97,5.007-5.534,7.311-10.644,7.596c-7.661,0.427-15.32,0.494-22.95-0.532c-3.483-0.468-7.006-0.661-10.472-0.271
                c-4.076,0.459-8.367,0.55-11.382,4.196c-0.974,1.177-2.666,1.281-4.154,1.495c-2.967,0.428-5.934,0.856-8.908,1.229
                c-10.236,1.283-20.508,2.331-30.702,3.887c-6.403,0.978-12.694,2.638-19.155,3.43c-9.066,1.111-17.843,4.086-27.121,4.052
                c-2.235-0.008-4.858,0.323-6.998,2.467c1.898,1.153,2.748,2.957,5.323,2.609c16.323-2.205,32.556-4.957,48.817-7.551
                c10.667-1.702,21.472-2.524,32.186-3.956c5.664-0.757,11.689-0.327,16.466-4.626c0.994-0.896,2.796-0.992,4.263-1.246
                c4.448-0.77,8.911-0.944,13.437-0.552c7.962,0.691,15.925,1.171,23.952,0.739c5.196-0.279,8.771-2.011,10.97-6.979
                c2.212-4.998,5.985-6.686,11.403-5.884c2.633,0.39,5.236,1.003,7.875,1.342c2.992,0.384,5.975,0.661,8.224-2.169
                c1.067-1.343,2.809-1.793,4.432-2.14c2.776-0.592,5.401-1.661,8.109-2.414c4.92-1.369,9.949-3.16,14.934,0.065
                c0.614,0.397,1.712,0.532,2.39,0.273c5.449-2.083,11.476-2.335,16.688-5.059c13.647-7.129,28.322-9.377,43.469-9.2
                c4.278,0.05,8.361-0.382,12.083-2.409c5.807-3.163,12.07-4.601,18.493-5.769c12.609-2.293,25.1-4.995,37.233-9.351
                c10.168-3.65,20.204-7.759,30.74-10.337c3.901-0.955,7.556-2.469,11.077-4.442c4.738-2.654,10.027-4.41,15.329-3.25
                c5.417,1.186,9.817-0.287,14.21-2.843c2.739-1.594,5.456-3.142,8.554-4.019c12.489-3.536,24.949-7.173,37.423-10.763
                c3.021-0.87,6.055-1.701,8.996-2.526c2.138-7.86,7.367-13.125,13.369-17.785C912.424,182.466,914.432,181.923,914.139,179.111z
                M138.114,391.944c-3.392-5.692-7.25-10.07-13.605-11.927c-3.777-1.104-5.88-5.438-10.104-6.09
                c-1.274-3.179-3.998-3.55-6.947-3.82c-3.08-0.283-6.205-0.54-7.978-3.918c-0.695-1.325-2.546-1.429-4.091-1.395
                c-1.521,0.034-3.016-0.116-4.34-0.92c-1.921-1.167-3.853-1.795-6.155-1.095c-1.626,0.494-2.951-0.381-3.814-1.745
                c-1.523-2.404-3.819-3.004-6.446-3.071c-1.154-0.029-2.3-0.295-3.452-0.432c-4.056-0.481-8.045,1.135-12.129,0.388
                c-0.517-0.095-1.367,0.396-1.72,0.87c-1.524,2.044-3.604,2.066-5.835,1.965c-0.97-0.044-2.019,0.167-2.926,0.529
                c-2.82,1.125-3.279,3.331-1.156,5.412c0.581,0.569,1.401,0.885,2.056,1.39c2.872,2.213,3.936,6.167,7.554,7.754
                c0.601,0.264,0.829,1.776,0.911,2.738c0.168,1.983,0.159,3.985,0.148,5.979c-0.007,1.322-0.461,2.724,0.285,3.923
                c2.525,4.06,3.046,8.746,4.13,13.248c2.339,0.694,4.548,1.407,6.791,1.986c0.785,0.203,1.655,0.093,2.486,0.099
                c6.879,0.045,12.28,3.301,17.015,7.92c0.7,0.683,1.162,1.976,2.201,1.798c3.722-0.636,6.442,0.769,8.673,3.617
                c0.542,0.693,1.509,0.632,2.341,0.6c2.483-0.097,4.52,1.083,6.461,2.37c2.071,1.372,4.018,2.933,6.348,4.654
                c2.446-4.93,9.377-2.854,12.029-7.823c0.179-0.335,1.25-0.265,1.905-0.259c1.093,0.011,1.893-0.442,2.579-1.258
                c2.161-2.573,5.098-3.071,8.175-2.72c5.819,0.664,11.526-1.608,17.35-0.566c0.211,0.038,0.5-0.366,0.837-0.629
                c0.315-1.614-0.402-2.81-1.769-3.672c-0.992-0.626-2.344-1.167-2.532-2.291c-0.719-4.289-3.025-7.417-6.464-9.882
                c-0.368-0.264-0.559-0.828-0.74-1.288c-0.797-2.023-2.313-2.655-4.38-2.458C140.658,392.034,139.492,391.944,138.114,391.944z
                M692.409,593.98c2.022,0.596,2.521,3.562,4.703,2.771c3.529-1.279,6.773-0.023,10.171,0.865c0.389-0.848,0.668-1.43,0.924-2.021
                c0.263-0.611,0.416-1.289,0.767-1.842c1.365-2.153,3.398-2.174,4.659,0.092c0.713,1.279,0.986,2.809,1.414,4.238
                c0.371,1.243,0.46,2.642,2.26,3.333c4.688-5.655,10.472-5.922,16.477-2.763c2.186-0.476,2.762-2.814,5.035-3.128
                c2.873,3.061,7.152,4.653,9.895,8.365c2.756-2.057,2.09-6.222,5.199-7.794c1.807,1.248,3.44,2.373,4.449,4.158
                c0.867,1.537,2.197,1.898,3.824,1.628c4.927-0.817,9.827-1.096,14.271,0.993c2.692-1.756,2.564-5.062,4.848-6.605
                c3.928,0.937,3.422,5.402,5.855,7.537c2.403-0.47,3.373-2.943,5.68-3.268c2.225-0.312,4.057,1.547,6.291,0.961
                c0.322-0.451,0.658-0.822,0.881-1.251c2.822-5.445,4.174-5.546,7.513-0.278c0.9,1.42,1.912,2.304,3.514,2.365
                c3.498,0.135,6.377,1.266,7.593,4.645c2.409-0.196,2.93-2.082,4.145-3.128c2.51-2.159,4.443-1.636,5.547,1.486
                c0.362,1.025,0.092,2.37,1.674,3.065c2.018-1.776,4.453-3.365,4.889-6.401c1.184-8.241,2.24-16.501,3.353-24.753
                c0.16-1.188,0.354-2.373-1.51-2.991c-4.177,3.487-7.55,0.417-11.51-2.24c0.873,3.304-2.878,3.886-3.136,6.546
                c-0.125,1.287-1.027,2.131-2.38,2.086c-1.163-0.039-1.644-0.971-1.945-1.973c-0.194-0.646-0.448-1.247-1.376-1.744
                c-2.357,0.406-4.912,0.243-6.777-1.819c-2.163,1.259-3.713,2.787-6.105,2.867c-2.115,0.071-4.018-0.29-5.684-1.597
                c-1.054,1.701-0.727,3.701-2.636,4.469c-1.964,0.79-2.741-1.579-4.419-1.591c-0.297,0.46-0.543,0.884-0.832,1.278
                c-2.276,3.104-3.459,3.027-5.193-0.259c-0.586-1.109-0.498-2.718-2.529-3.206c-0.787,2.086-3.137,2.465-4.748,3.899
                c-3.784,3.369-6.122,3.278-9.723-0.021c-3.743,3.962-4.135,3.902-6.658-0.943c-0.098-0.187-0.488-0.221-0.738-0.324
                c-2.857,4.627-4.167,5.093-9.807,3.371c-0.654-0.199-1.387-0.558-1.901-0.18c-3.223,2.371-5.308,1.018-7.226-1.859
                c-0.574-0.859-1.84-1.259-2.768-1.855c-1.127,0.935-0.767,2.228-1.164,3.269c-1.09,2.859-2.213,3.438-4.696,1.429
                c-1.796-1.451-3.935-1.519-5.041-0.596c-4.057,3.388-6.546,0.201-9.45-1.652c-0.983-0.627-1.097-2.1-2.734-2.502
                c-1.357,1.723-3.896,1.992-5.543,3.547c-1.953,1.846-3.703,2.783-6.038,0.75c-0.839-0.731-1.804-0.565-2.795-0.12
                c-5.946,2.67-11.966,2.336-17.976,0.265c-1.007-0.347-1.902-0.599-2.834-0.012c-4.223,2.656-7.818,0.854-11.516-1.75
                c-4.248,4.697-8.599,2.121-12.883-0.073c-2.193,0.524-3.438,2.897-5.866,2.662c-2.694-0.261-2.164-3.558-4.464-4.671
                c-3.05,3.427-6.626,3.836-10.538,1.438c-0.393-0.24-1.041-0.051-1.457-0.273c-2.835-1.523-4.521-0.166-6.249,2.053
                c-4.378,5.627-4.454,5.568-9.975,1.131c-5.708,3.885-5.717,3.832-13.521,5.055c-1.952,0.306-3.843,1.032-5.746,1.619
                c-1.381,0.426-2.061,1.691-2.749,2.772c-0.447,0.704,0.052,1.59,1.205,2.252c1.559-2.052,3.924-3.105,6.39-3.811
                c3.515-1.006,3.631,3.403,6.015,4.188c1.873-0.325,3.618-2.3,5.261-0.975c2.248,1.812,2.954-1.418,4.959-1.003
                c2.348,3.612,5.726,5.451,10.444,4.244c0.377-1.03,0.747-2.117,1.174-3.183c0.519-1.293,1.87-2.053,2.774-1.319
                c2.895,2.348,6.988,2.151,9.652,4.732c1.866,1.809,3.496,1.427,5.287,0.042c1.648-1.274,2.969-3.135,5.201-3.314
                c3.873,4.623,8.08,5.525,12.502,1.162c1.356,1.684,3.285,1.781,5.332,1.484c2.5-0.363,2.739,2.434,4.115,3.549
                c3.358-1.978,3.255-6.786,7.299-7.5C686.671,597.263,686.671,597.263,692.409,593.98z M707.174,376.444
                c-1.684,4.257-4.982,6.503-7.85,9.239c-2.114,2.019-4.194,3.631-6.931,4.504c-1.584,0.505-3.218,0.993-4.426,2.218
                c-1.261,1.279-2.757,1.543-4.495,1.719c-4.764,0.483-9.717,0.568-13.723,4.362c3.268,3.101,10.513,0.138,10.684,7.096
                c2.725,0.847,5.203,1.074,7.686,1.327c3.979,0.406,8.003,0.827,11.758,2.209c7.998,2.942,13.611,6.62,9.623,17.68
                c-0.567,1.575-1.248,3.316-0.058,4.778c2.56,3.141,1.663,6.645,1.258,10.065c-0.235,1.984-0.872,3.99-0.468,5.945
                c1.773,8.566,4.82,16.831,5.586,26.483c4.641-4.537,9.049-7.811,13.16-11.399c3.059-2.669,4.635-6.499,3.68-10.777
                c-1.379-6.175-2.718-12.365-7-17.42c-2.193-2.591-2.763-5.7-1.516-8.918c1.876-4.843,2.886-9.898,3.915-14.954
                c0.411-2.023,1.25-3.808,2.816-5.166c2.893-2.506,5.697-5.151,9.269-6.715c3.391-1.485,6.268-3.764,9.297-5.822
                c1.799-1.222,2.667-2.743,2.979-4.885c0.744-5.105,1.619-10.194,2.592-15.261c0.555-2.894,0.967-5.903,3.004-8.2
                c4.822,0.562,7.032-0.979,7.816-5.35c0.94-5.243,1.947-10.477,2.754-15.741c0.47-3.071,1.451-6.101,1.338-9.214
                c-1.717-0.883-3.48-0.893-4.516-0.021c-2.275,1.92-5.352,3.094-7.023,5.277c-2.019,2.634-4.514,4.234-7.231,5.771
                c0,1.167,0.019,2.161-0.005,3.153c-0.033,1.487,0.307,3.664-1.175,4.063c-3.663,0.989-6.073,4.518-10.146,4.56
                c-5.754,0.06-10.482,1.859-13.178,7.579c-0.787,1.671-2.644,2.995-4.279,4.065c-1.732,1.133-2.864,2.332-3.734,4.343
                C716.484,378.006,711.59,379.488,707.174,376.444z M1120.166,583.945c2.227-1.697,3.486-3.765,4.688-5.913
                c1.239-2.215,5.481-4.661,7.382-3.819c2.342,1.037,4.535,0.609,6.828,0.418c3.971-0.331,7.207,1.029,9.529,4.313
                c2.271,3.214,5.147,5.384,9.215,5.67c2.875,0.202,6.001,1.455,8.28-1.325c2.116-2.581,0.852-5.414-0.226-7.92
                c-1.037-2.416-0.624-3.643,1.418-5.441c1.808-1.592,3.693-3.115,5.455-4.315c1.335-8.976,1.91-17.563-0.199-26.157
                c-0.469-1.912-0.375-3.972-0.465-5.967c-0.412-9.152-0.736-18.311-1.219-27.459c-0.246-4.652-0.668-9.301-1.839-13.844
                c-0.929-3.606-0.797-7.257-0.407-10.928c0.982-9.26,2.315-18.539-1.215-27.615c-0.354-0.909-0.26-1.981-0.488-2.951
                c-0.795-3.363-0.823-6.908-2.309-10.168c-0.317,0.292-0.68,0.47-0.775,0.741c-0.438,1.25-0.888,2.507-1.18,3.796
                c-3.557,15.741-7.457,31.438-8.454,47.614c-0.368,5.96-2.304,10.896-6.428,15.223c-3.917,4.108-7.313,8.7-9.387,14.052
                c-1.169,3.019-2.821,5.63-4.944,8.021c-2.108,2.375-4.146,4.846-5.06,7.954c-2.626,8.937-6.065,17.634-7.749,26.852
                c-0.596,3.254-1.366,6.453-3.154,9.405C1114.942,578.344,1115.596,580.391,1120.166,583.945z M436.612,308.381
                c0.463,0.547,0.726,1.176,1.105,1.255c4.772,1.006,9.327,3.138,14.507,1.602c6.231-1.847,12.785-2.13,19.191-3.132
                c6.411-1.002,12.798-2.195,19.235-2.976c13.215-1.603,26.461-2.954,39.684-4.5c1.78-0.208,3.986-0.114,5.173-1.518
                c2.482-2.938,5.805-3.011,9.084-3.256c5.666-0.423,11.325-0.03,16.956,0.635c9.586,1.133,18.938-0.125,28.156-2.688
                c1.862-0.518,3.941-0.842,5.021-2.813c0.002-1.776-2.277-2.617-1.604-4.972c3.14-1.914,6.843-2.173,10.673-1.688
                c2.478,0.314,4.938,0.987,7.415,1.026c3.615,0.058,7.608,0.523,10.391-2.287c1.432-1.445,2.882-2.025,4.634-2.515
                c2.393-0.668,4.794-1.409,7.173-2.019c3.652-0.936,7.571-3.189,11.274,0.322c0.846,0.801,2.28,0.331,3.397,0.078
                c8.724-1.977,17.794-2.75,25.7-7.572c2.276-1.387,4.864-2.133,7.512-2.607c5.897-1.057,11.807-2.06,17.674-3.267
                c3.791-0.78,7.555-1.176,11.404-0.677c6.479,0.841,12.604-0.416,18.412-3.285c3.611-1.785,7.357-3.171,11.334-3.745
                c8.571-1.238,17.008-3.158,25.484-4.872c4.566-0.924,9.087-2.01,13.515-3.511c9.601-3.254,19.237-6.407,28.871-9.563
                c7.405-2.426,15.138-3.734,22.126-7.596c4.044-2.235,8.76-3.454,13.527-2.006c4.888,1.484,9.487,0.23,13.362-2.284
                c4.903-3.181,10.237-4.608,15.704-6.087c10.051-2.719,20.038-5.666,30.76-8.719c-2.943-2.913-4.279-6.27-5.469-9.41
                c-0.434-0.217-0.606-0.382-0.723-0.35c-19.147,5.455-38.909,9-56.823,18.297c-1.32,0.685-2.798,1.06-4.182,1.63
                c-1.608,0.662-3.214,0.712-4.886,0.255c-5.777-1.582-11.376-0.61-16.437,2.175c-5.329,2.932-10.999,4.667-16.762,6.297
                c-4.646,1.315-9.235,2.783-13.737,4.549c-16.468,6.461-33.406,11.291-50.879,14.104c-5.979,0.962-11.678,2.529-17.055,5.347
                c-4.217,2.208-8.743,3.167-13.523,2.631c-2.83-0.317-5.647-0.147-8.48,0.029c-6.017,0.373-11.768,2.223-17.702,3.041
                c-3.995,0.55-7.956,1.61-11.347,3.66c-6.425,3.884-13.73,4.956-20.646,7.259c-1.621,0.54-3.372,0.69-4.854-0.239
                c-2.949-1.85-5.959-1.441-9.164-0.927c-4.801,0.771-9.093,3.205-13.893,3.937c-0.902,0.138-1.904,0.813-2.5,1.537
                c-2.227,2.702-5.206,2.741-8.216,2.43c-2.976-0.308-5.91-1.002-8.882-1.362c-5.134-0.622-8.001,0.666-10.063,5.346
                c-2.348,5.327-6.382,7.308-11.835,7.479c-7.499,0.236-14.974,0.243-22.452-0.623c-3.457-0.4-6.995-0.399-10.476-0.212
                c-3.136,0.168-6.702,0.131-9.076,2.212c-3.182,2.79-6.845,3.136-10.596,3.643c-15.321,2.069-30.705,3.574-46,5.965
                c-12.145,1.898-24.154,4.926-36.527,5.321C439.139,307.201,437.959,307.404,436.612,308.381z M190.45,315.654
                c0.426-3.415,0.628-6.054,1.108-8.642c0.743-3.999,0.861-7.869-0.756-11.742c-1.09-2.612-1.571-5.437-1.755-8.276
                c-0.637-9.807-1.351-19.61-1.849-29.425c-1.165-22.96-2.124-45.931-3.365-68.886c-0.522-9.662-1.857-19.234-5.665-28.271
                c-0.812-1.926-1.34-4.066-4.036-5.61c0.547,3.848,1.041,7.065,1.457,10.292c1.771,13.718,3.312,27.405,1.77,41.316
                c-0.952,8.59-1.651,17.25-1.523,25.931c0.255,17.328,0.555,34.655,0.721,51.983c0.128,13.324,0.523,26.646-0.275,39.974
                c-0.535,8.929,2.104,17.32,4.97,25.509c8.166-0.17,9.977,1.046,13.061,8.447c0.128,0.308,0.308,0.599,0.395,0.917
                c1.067,3.887,2.826,7.497,4.823,10.946c2.731,4.717,4.144,9.733,4.688,15.12c0.635,6.292,1.703,12.542,2.29,18.836
                c1.254,13.428,4.325,26.639,4.736,40.165c0.068,2.252,0.407,4.226,3.175,4.95c1.956,0.512,3.33,2.145,4.628,3.689
                c1.159,1.379,2.519,1.96,4.509,1.251c-2.719-17.109-5.177-34.063-8.157-50.926c-3.198-18.095-5.42-36.221-4.595-54.642
                c0.189-4.238,1.13-8.708-2.156-12.395c-0.203-0.228-0.396-0.663-0.317-0.915c1.177-3.757-1.64-6.194-2.928-9.154
                C202.681,319.84,198.558,315.517,190.45,315.654z M208.58,327.829c1.185-3.313,1.144-5.812,1.15-8.306
                c0.033-12.355-0.897-24.635-3.306-36.759c-1.847-9.296-2.092-18.501,0.537-27.682c0.437-1.524,0.436-3.358,3.007-3.761
                c1.439,3.97,1.769,8.081,2.081,12.197c0.667,8.793,1.271,17.562,3.909,26.099c2.408,7.793,4.251,15.753,4.201,24.018
                c-0.008,1.319,0.298,2.646,0.523,3.958c0.194,1.133,0.479,2.25,0.794,3.7c2.63-14.983,1.501-29.303-2.709-43.391
                c-1.019-3.41-1.604-6.889-1.163-10.36c1.524-12.02-0.691-23.957-0.534-35.938c0.021-1.558-0.563-2.93-1.039-4.343
                c-1.698-5.041-3.747-9.991-5.064-15.128c-3.099-12.09-5.988-24.239-8.665-36.429c-0.841-3.833-2.637-6.73-5.528-9.17
                c-4.717-3.979-10.498-5.253-16.804-7.194c1.692,5.495,3.229,10.201,4.095,15.095c1.663,9.396,1.687,18.887,2.117,28.37
                c0.875,19.304,1.988,38.597,3.087,57.889c0.549,9.645,0.328,19.473,2.229,28.858c1.576,7.779,2.752,15.242,1.343,23.047
                c-0.049,0.275,0.208,0.605,0.595,1.64C202.123,313.608,204.502,321.164,208.58,327.829z M729.529,465.888
                c-5.84,3.198-10.475,7.14-14.27,12.065c-6.014,7.805-12.414,15.339-20.23,21.369c-10.418,8.038-21.099,15.746-31.84,23.35
                c-4.869,3.445-9.734,7.062-15.619,8.771c-4.826,1.401-9.406,3.444-13.729,5.935c-6.994,4.028-14.46,6.751-22.194,8.873
                c-6.742,1.85-13.47,3.75-20.203,5.629c-1.07,0.299-2.343,0.233-2.856,1.555c0.398,0.965,1.041,2.192,1.868,2.21
                c2.208,0.048,4.712,0.2,6.574-0.733c2.783-1.397,5.796-1.201,8.188-0.671c3.526,0.782,7.132,1.326,10.662,2.147
                c2.605,0.605,5.551,1.551,8.018-0.857c0.773-0.755,1.834-0.656,2.827-0.271c3.983,1.539,8.069,0.734,12.11,0.433
                c1.685-0.126,3.077-0.737,4.661-1.905c1.881-1.387,4.626-1.891,6.507-3.768c0.198-0.198,0.925,0.223,1.394,0.188
                c2.598-0.201,5.496,1.714,7.285,1.108c2.875-0.974,5.891,1.733,8.309-0.87c4.082,1.105,7.354-1.258,11.005-2.178
                c2.667-0.671,5.195-3.794,8.313-1.335c3.361-3.902,6.199-7.911,11.258-9.22c0.688-0.178,1.215-1.082,1.76-1.699
                c1.017-1.149,2.217-2.258,3.648-2.535c5.367-1.04,8.004-5.01,10.488-9.244c1.264-2.154,2.496-4.34,3.928-6.383
                c3.734-5.33,5.277-11.1,3.416-17.441c-1.564-5.332,0.302-9.792,2.994-14.154c1.132-1.834,2.606-3.505,3.023-5.703
                C727.752,475.668,728.631,470.775,729.529,465.888z M346.877,192.262c-0.722,0.892-1.042,1.104-1.056,1.334
                c-0.106,1.827-0.264,3.661-0.204,5.486c0.818,24.755-1.467,49.542,1.378,74.305c1.212,10.543,0.724,21.303,0.646,31.962
                c-0.033,4.482,0.828,8.562,2.796,12.493c0.679,1.355,1.391,2.712,0.848,4.275c-0.87,2.503-0.193,4.919,0.21,7.386
                c0.804,4.927,1.507,9.872,2.198,14.816c0.428,3.063,0.227,6.014-2.077,8.413c-0.985,1.026-1.361,2.259-1.386,3.637
                c-0.176,9.653-1.737,19.216-1.396,28.931c0.24,6.82,1.482,12.807,7.027,17.277c0.382,0.308,0.708,0.72,0.966,1.142
                c2.021,3.292,4.967,5.381,8.629,6.381c5.715,1.561,10.055,4.91,13.568,9.607c1.946,2.601,4.365,4.794,7.778,6.573
                c0.627-13.604-2.376-26.108-4.055-39.135c-1.353,1.354-0.497,3.618-2.624,3.62c-0.868-0.351-0.971-1.051-1.106-1.683
                c-1.529-7.162-3.099-14.316-4.541-21.495c-3.504-17.449-7.909-34.762-8.321-52.692c-0.05-2.175-1.188-3.316-2.822-4.307
                c-1.6-0.97-2.965-2.071-3.166-4.184c-0.55-5.797-1.923-11.477-2.288-17.317c-1.329-21.279-2.789-42.549-4.256-63.819
                c-0.171-2.477-0.209-4.995-1.062-7.394C349.68,209.763,346.03,201.842,346.877,192.262z M1095.463,779.15
                c-1.303,0.378-1.91,0.604-2.537,0.729c-4.588,0.912-5.761,2.433-4.566,6.822c1.44,5.291,3.694,10.248,6.894,14.549
                c3.16-0.493,5.162-2.08,6.747-4.4c0.93-1.362,1.973-2.732,3.239-3.763c3.445-2.802,6.667-1.782,8.003,2.411
                c0.353,1.105,0.556,2.259,0.8,3.396c0.381,1.771,1.104,3.06,3.238,3.108c2.336,0.055,3.503,1.654,4.192,3.682
                c0.32,0.943,0.495,1.948,0.902,2.85c1.357,3.01,3.668,3.207,5.6,0.434c0.752-1.08,1.258-2.34,1.795-3.555
                c0.672-1.52,1.186-3.112,1.899-4.611c3.112-6.543,7.415-6.932,11.472-1.066c0.283,0.41,0.537,0.842,0.79,1.272
                c1.229,2.095,2.74,3.685,5.417,3.868c2.074,0.144,3.092,1.674,3.79,3.507c0.458,1.2,0.401,2.661,2.147,3.928
                c5.104-2.884,11.036-4.398,16.965-5.931c0.214-0.056,0.322-0.52,0.483-0.802c-5.075-0.782-10.06-2.257-15.313-1.435
                c-1.605,0.251-3.319-0.227-4.76-1.201c-1.146-0.776-2.139-1.638-1.604-3.305c1.462-1.721,3.567-1.406,5.507-1.616
                c1.811-0.196,3.783,0.233,5.4-0.709c5.09-2.961,10.812-4.253,16.205-6.405c0.222-0.088,0.338-0.439,0.49-0.649
                c-0.168-1.156-2.031-1.481-1.303-3.145c0.756-1.544,3.188-1.272,4.021-3.041c-1.026-1.483-2.782-1.88-4.026-1.541
                c-4.021,1.097-7.535-0.601-11.213-1.458c-3.831-0.894-6-3.691-8.549-7.417c6.218-1.548,10.427-5.354,14.639-9.757
                c-2.844,0.868-5.469,1.271-8.135,1.196c-3.588-0.102-4.011-0.776-2.99-4.999c-4.551,0.476-4.233-4.984-7.465-6.199
                c-1.163,0.581-1.559,1.493-1.871,2.457c-0.82,2.528-1.568,5.082-2.471,7.581c-0.567,1.571-1.299,3.29-3.384,3.087
                c-1.968-0.191-2.21-2.021-2.172-3.432c0.171-6.318-5.213-9.967-7.096-15.559c-1.269,3.359-3.516,5.47-6.288,7.154
                c-1.02,0.618-1.748,1.691-1.529,2.955c0.895,5.174-3.021,6.584-6.486,8.083c-4.858,2.102-6.896,0.96-8.605-4.028
                c-0.837-2.441-0.771-5.301-2.74-7.088c-2.067-0.4-2.958,1.055-4.188,1.809c-3.156,1.938-4.954,1.523-7.121-1.531
                c-0.906-1.277-1.153-3.24-3.502-3.365c-3.494,3.536-2.075,10.281-7.835,12.209C1090.34,769.447,1094.732,773.154,1095.463,779.15z
                M29.299,70.911c-1.494,4.042-2.312,7.803-2.367,11.632c-0.177,12.335-1.136,24.624-2.019,36.915
                c-1.061,14.767-1.723,29.58-6.643,43.806c-1.674,4.84-2.758,9.945-3.11,15.129c-0.756,11.138-2.267,22.195-3.673,33.26
                c-2.497,19.65-4.525,39.361-7.668,58.929c-0.226,1.405-0.652,3.18,0.72,4.216c1.645,1.243,2.689,2.873,3.905,4.451
                c1.237,1.607,2.899,2.242,5.243,1.741c3.515-0.751,7.129-0.59,10.044,2.115c0.937-1.618,0.871-2.984,0.973-4.286
                c1.219-15.608,2.636-31.201,3.354-46.845c0.282-6.141,0.373-12.273,0.401-18.422c0.036-7.794,1.167-15.597,2.049-23.371
                c0.599-5.281,0.163-10.697,1.862-15.835c2.794-8.449,4.158-17.104,4.074-26.005c-0.02-2.16,0.309-4.322,0.36-6.485
                c0.189-7.974,0.947-16.004-2.478-23.612c-3.777-8.391-3.625-16.931-1.715-25.831c1.531-7.136,4.601-12.281,11.868-14.424
                c2.583-0.762,4.814-2.139,5.639-4.794c-0.898-2-2.336-3.181-4.05-2.773C40.624,71.713,35.145,71.063,29.299,70.911z
                M362.662,150.344c-1.748-4.244-4.672-7.329-8.219-9.875c-4.515-3.241-9.531-5.488-14.833-7.003
                c-12.375-3.536-24.064-8.776-35.699-14.146c-36.428-16.815-72.088-35.189-107.889-53.274c-9.079-4.586-18.282-8.812-28.086-11.637
                c-2.46-0.709-4.855-1.116-7.338-0.237c-4.082,1.445-8.189,1.079-12.315,0.189c-1.958-0.422-3.934-0.998-6.554-0.515
                c4.456,2.814,4.092,2.754,3.359,7.219c-0.528,3.221-2.979,5.043-4.574,8.025c5.128,3.385,11.298,4.465,15.725,8.605
                c0.781-0.243,1.482-0.311,2.01-0.648c3.635-2.327,7.315-4.008,11.595-1.532c0.377,0.218,0.998,0.134,1.475,0.04
                c4.988-0.983,9.961,0.021,14.938,0.019c6.112-0.002,10.605,2.357,13.425,7.788c0.977,1.881,2.317,3.178,4.503,3.558
                c2.065,0.358,3.99,1.362,4.673,3.355c1.432,4.175,5.401,6.088,7.594,9.59c1.727-1.377,2.216-3.239,3.297-4.695
                c2.272-3.062,4.175-3.395,6.947-0.713c1.606,1.553,3.109,2.793,5.471,3.043c2.427,0.257,2.992,2.248,3.204,4.401
                c0.219,2.218-1.01,4.133-1.305,6.25c4.073,2.871,8.332,4.277,13.226,3.811c2.803-0.267,5.719-0.427,8.211,1.33
                c1.505,1.062,2.863,0.478,4.224-0.143c3.901-1.778,7.429-0.938,10.818,1.363c1.656,1.125,3.404,2.229,5.444,2.357
                c5.574,0.352,10.666,2.276,15.832,4.213c4.391,1.647,8.967,2.08,12.253,6.497c2.368,3.184,6.463,5.162,9.969,7.395
                c2.945,1.877,5.076,4.1,4.989,7.761c7.454,0.735,14.824-3.123,21.701,1.015c-0.13,3.013-0.13,3.013,4.612,8.446
                C350.483,147.12,356.394,148.43,362.662,150.344z M863.007,895.613c0.582-2.099-0.508-3.881-1.278-5.651
                c-1.861-4.272-3.953-8.442-5.824-12.71c-2.115-4.823-1.68-5.562,3.373-6.56c0.171-0.033,0.258-0.493,0.383-0.754
                c-2.146-4.165-7.24-6.33-8.166-11.023c1.664-2.638,4.194-0.756,5.943-2.251c-0.819-2.688-2.502-4.981-3.84-7.433
                c-2.073-3.795-4.391-7.46-5.803-11.591c-2.049-5.99-1.37-7.813,4.413-10.816c-2.3-4.738-5.755-8.912-7.229-14.084
                c-1.478-5.181-1.471-10.69-3.566-15.811c0.076,6.615-4.748,11.341-6.375,17.211c4.08,2.419,4.594,3.751,3.625,8.48
                c-0.709,3.455-2.639,6.445-3.635,9.671c2.677,4.837,2.792,5.052,0.441,9.8c-2.805,5.668-5.848,11.217-8.734,16.845
                c-1.126,2.196-2.367,4.376-2.523,6.894c4.6,2.724,6.087,5.471,5.436,10.089c-0.43,3.044-1.683,5.689-3.805,7.979
                c-1.664,1.795-3.717,3.412-3.934,6.136c4.116,3.488,4.356,4.332,2.199,8.726c-0.73,1.488-1.629,2.903-2.539,4.293
                c-1.55,2.368-3.186,4.68-4.747,7.04c-0.259,0.392-0.329,0.908-0.475,1.335c0.833,0.658,2.103-0.099,2.89,1.446
                c-1.274,3.578-4.771,6.346-6.074,10.633c17.037,0,33.686,0,50.463,0c-0.172-2.902-1.324-5.131-2.201-7.441
                c-1.355-3.577-2.907-7.097-4.013-10.75C855.451,898.836,856.668,896.887,863.007,895.613z M421.927,434.249
                c0.271-0.009,0.542-0.018,0.814-0.027c0.087-0.463,0.242-0.924,0.251-1.389c0.403-21.642,0.773-43.284,1.215-64.925
                c0.046-2.287-0.597-4.143-1.925-5.991c-3.397-4.729-6.791-9.478-9.856-14.422c-1.524-2.458-3.421-3.909-6.094-4.722
                c-2.858-0.87-5.921-1.512-8.416-3.037c-5.164-3.157-11.492-2.543-17.015-6.79c0.292,1.994,0.363,2.8,0.531,3.584
                c4.073,19.023,8.187,38.038,12.226,57.068c2.659,12.528,5.747,24.991,7.37,37.701c1.938,15.179,3.36,30.423,5.008,45.639
                c0.211,1.945,0.167,3.958,1.517,6.004c4.657-0.387,9.422,0.297,14.712-0.648c0-7.165-0.566-14.035,0.122-20.778
                c1.281-12.546-0.502-24.387-5.262-36.055c-3.569-8.75-6.23-17.872-9.241-26.667c-1.83-0.759-3.867,0.991-4.972-0.64
                c-2.002-2.955-5.05-5.778-4.119-9.74c0.802-3.41,0.378-6.614-0.298-9.834c-0.708-3.368,0.033-6.308,1.992-8.954
                c3.216-4.341,7.586-8.002,7.323-14.23c2.656,0.298,4.35,1.132,5.933,2.465c-0.166,3.599-0.362,7.077-0.472,10.558
                c-0.063,1.996-0.321,4.185,0.719,5.886c2.445,4.001,2.595,8.541,3.581,12.896c0.783,3.458-0.585,5.774-3.479,7.495
                c-1.408,0.837-3.083,1.281-4.514,3.14C413.629,409.782,417.778,422.016,421.927,434.249z M1011.127,610.23
                c3.879-2.592,7.895-4.195,12.473-4.762c2.799-0.347,6.322-1.5,7.909-3.543c3.097-3.987,6.774-5.544,11.483-5.947
                c2.551-0.22,3.234-1.508,2.096-3.7c-4.263-8.213-9.347-15.795-17.6-20.572c-5.328-3.085-10.787-5.946-16.192-8.899
                c-11.058-6.043-22.931-8.078-35.261-6.119c-6.357,1.01-12.041,3.791-13.882,11.1c-1.014,4.026-2.457,7.949-3.808,11.885
                c-1.021,2.971-0.781,5.602,1.772,7.656c1.505,1.211,2.381,2.725,2.903,4.535c0.414,1.436,0.834,2.907,2.635,3.641
                c1.566-1.609,2.318-4.434,5.524-4.097c2.944,0.31,2.971,3.821,5.296,4.687c1.756-1.739,0.957-4.905,3.902-5.82
                c3.594,1.139,2.421,5.056,4.007,7.16c1.865,2.476,1.328,5.729,3.425,8.302c1.508-1.14,1.809-3.242,3.832-3.438
                c1.213,0.257,2.293,0.977,3.23,1.973c0.867,0.924,1.242,2.437,2.977,2.473c2.41-1.874,1.064-5.875,4.531-7.951
                C1004.746,602.99,1008.766,605.695,1011.127,610.23z M306.386,625.451c2.23-2.436,1.207-6.01,4.343-7.508
                c2.755-1.316,0.122-5.8,3.661-6.962c3.357,0.417,2.692,4.181,4.82,5.829c2.034,1.576,0.895,5.076,3.709,8.322
                c-0.161-4.7,3.885-5.185,5.026-8.12c0.388-0.996,0.892-1.881,2.051-1.819c1.399,0.074,1.842,1.205,2.169,2.375
                c0.64,2.281,0.509,4.621,0.591,6.947c0.106,3.006,0.258,6.011,0.39,9.016c2.617-2.539,1.174-7.156,4.198-8.279
                c3.832-1.422,1.164-6.463,5.736-7.328c4.291,6.22,1.742,14.465,4.918,21.582c1.803-3.131,1.803-3.131,6.291-5.77
                c2.391-1.405,3.456-1.17,4.624,1.31c1.215,2.581,1.525,5.43,2.131,8.19c0.411,1.871,0.464,3.934,2.285,5.506
                c2.225-3.066,2.52-6.697,4.167-9.727c0.554-1.02,1.106-2.172,2.489-2.16c1.585,0.014,2.573,1.213,2.719,2.496
                c0.44,3.871,3.645,5.703,5.525,8.616c2.562-0.735,4.466-2.292,7.56-2.657c-2.208-2.952-4.825-4.111-7.068-5.675
                c-1.65-1.149-3.418-2.175-4.373-4c-1.612-3.083-3.535-5.616-7.403-5.903c-0.721-0.054-1.571-0.677-2.052-1.283
                c-3.342-4.203-8.637-5.176-12.963-7.701c-2.427-1.417-5.023-2.42-6.948-4.594c-1.529-1.729-2.933-3.643-5.736-3.387
                c-1.267,0.116-2.261-0.771-2.513-2.029c-0.593-2.959-2.948-3.835-5.323-4.662c-1.278-0.445-2.367-1.1-3.463-1.894
                c-3.541-2.563-7.235-4.88-10.353-8.034c-1.503-1.521-3.713-2.359-5.961-2.14c-2.227,0.218-3.522-0.575-5.015-2.304
                c-2.241-2.597-4.834-5.334-8.428-5.86c-3.729-0.546-7.073-3.108-11.136-1.984c-1.229,0.34-2.092-0.827-2.757-1.81
                c-1.131-1.671-2.863-2.727-4.674-2.307c-3.734,0.867-7.171-0.605-10.75-0.76c-1.749-0.075-3.02-0.694-4.429-1.807
                c-2.732-2.156-6.059-3.997-9.407-4.124c-2.559-0.097-4.203-0.679-6.033-2.235c-1.622-1.379-3.669-2.266-5.551-3.328
                c-1.923-1.086-3.867-0.82-5.719,0.16c-1.583,0.84-3.188,1.688-4.647,3.389c4.465,3.525,1.561,8.824,3.249,13.172
                c0.473,1.219,0.834,3.111,0.122,4.825c-2.366,5.694,1.03,10.396,2.42,16.104c2.744-3.446,0.956-8.128,4.175-9.953
                c3.393-1.923,0.743-7.308,5.375-8.157c3.625,3.411,1.679,8.213,2.981,12.108c3.282-0.435,3.671-3.983,6.107-5.378
                c2.419,2.377,1.862,5.379,2.342,8.051c0.483,2.689-0.274,5.598,1.833,9.28c-0.075-5.606,5.068-6.562,6.227-10.56
                c0.402-1.389,2.341-0.635,3.015,0.825c1.009,2.188,0.863,4.547,0.821,6.88c-0.053,2.914-0.049,2.914,1.289,6.038
                c-0.483-5.704,3.885-8.05,5.923-11.716c0.873-1.568,2.724-1.08,3.748,0.342c0.655,0.911,1.01,2.086,1.306,3.193
                c0.409,1.531,1.038,2.91,2.591,3.43c3.81,1.275,4.626,4.4,5.164,7.812c0.164,1.038-0.202,2.305,0.902,3.107
                c1.584-0.449,1.34-2.604,2.503-2.92c2.525-0.688,4.173-2.01,5.468-4.312c0.718-1.277,2.336-1.026,3.328,0.328
                c0.938,1.279,0.936,2.799,1.093,4.291C305.327,620.283,304.994,622.842,306.386,625.451z M878.35,546.932
                c2.804-1.99,4.975-3.973,6.807-6.365c1.856-2.425,4.3-4.158,7.004-5.521c2.42-1.22,4.488-0.688,6.248,1.344
                c2.283,2.637,3.245,5.711,3.409,9.165c0.204,4.3,2.933,8.073,7.093,8.967c4.102,0.881,6.33,3.3,7.76,6.938
                c1.609,4.097,3.303,8.161,5.214,12.864c4.045-4.819,9.034-6.932,14.415-8.316c0.8-0.205,1.534-0.658,2.332-0.879
                c3.949-1.094,6.725-3.396,8.164-7.343c0.812-2.228,2.283-4.022,4.457-5.195c3.525-1.902,5.111-1.456,6.388,2.382
                c0.915,2.75,1.937,5.255,4.677,6.842c3.582-3.137,7.697-5.232,11.873-7.361c-3.373-7.173-8.496-12.234-15.334-15.482
                c-2.332-1.107-3.521-2.631-4.191-5.1c-1.307-4.816-2.943-9.553-5.228-14.014c-1.923-3.755-3.05-4.15-6.565-1.783
                c-2.582,1.738-5.129,2.084-8.045,1.389c-2.587-0.616-5.205-1.143-7.836-1.52c-4.906-0.703-8.72,0.678-11.223,5.408
                c-2.057,3.891-3.326,3.956-6.938,0.975c-1.888-1.56-3.893-2.483-6.334-2.537c-1.662-0.036-3.334-0.08-4.89-0.815
                c-1.646-0.778-0.903-3.112-2.714-4.304c-6.184,4.695-12.384,9.141-16.592,15.752c-0.981,1.542-2.003,3.076-1.646,5.088
                C877.177,540.446,877.006,543.502,878.35,546.932z M509.155,480.394c-1.139-4.47-2.623-8.894-3.328-13.438
                c-0.773-4.981-2.881-9.153-5.958-12.966c-1.987-2.461-3.873-5.029-6.072-7.288c-4.855-4.987-8.18-10.864-10.767-17.249
                c-9.066-22.381-17.239-45.056-22.629-68.646c-0.913-3.994-2.277-7.698-5.871-10.072c-1.413-0.933-1.669-2.401-2.209-3.776
                c-0.911-2.319-1.692-4.706-2.807-6.925c-4.559-9.074-11.278-16.428-18.803-23.099c-0.747-0.662-1.31-1.959-2.985-1.379
                c0.538,3.133,1.204,6.193,2.555,9.062c0.787,1.669,1.587,3.362,1.609,5.207c0.027,2.285,0.978,4.138,2.074,6.035
                c4.081,7.06,8.216,14.093,11.663,21.496c0.771,1.657,1.814,3.351,1.476,5.214c-0.843,4.644,1.317,8.4,3.227,12.281
                c1.534,3.118,3.311,6.101,3.475,9.781c0.662,14.802,1.686,29.591,2.129,44.398c0.252,8.433,1.514,16.485,4.976,24.216
                c0.833,1.859,1.773,3.146,3.73,3.562c4.185,0.887,8.372,1.75,11.904,5.378c0.853-3.712,1.057-6.789,1.077-9.903
                c0.025-3.994,0.025-7.993,0.26-11.977c0.121-2.045,0.294-4.305,2.83-5.405c2.235,2.098,2.464,4.871,3.067,7.36
                c1.451,5.993,2.815,11.963,6.49,17.12c1.268,1.779,1.585,3.933,1.893,6.122c0.62,4.408,0.493,8.956,1.979,13.103
                C499.049,480.376,503.719,482.166,509.155,480.394z M119.388,465.988c8.651,2.193,14.586,7.385,15.873,16.793
                c1.137,0.135,1.979,0.123,2.755,0.344c5.959,1.688,11.61,4.84,18.164,3.696c0.579-0.101,1.264,0.333,1.884,0.56
                c3.128,1.142,6.173,2.206,9.504,0.312c1.023-0.581,2.599-0.191,3.922-0.244c3.727-0.148,6.792,1.91,10.021,3.434
                c0.203-0.695,0.472-1.148,0.356-1.294c-2.342-2.937-2.042-8.293-7.762-8.236c-1.023,0.01-2.467-1.334-3.015-2.41
                c-1.432-2.817-4.244-3.968-6.424-5.85c-1-0.862-2.26-1.448-2.782-2.737c-0.93-2.296-2.287-3.322-4.995-2.877
                c-1.865,0.308-4.221-1.394-4.417-2.656c-0.581-3.748-4.333-4.441-5.829-7.147c-0.964-1.743-2.545-2.55-4.588-2.402
                c-2.595,0.189-3.936-1.011-4.445-3.611c-0.513-2.624-1.963-4.52-4.992-4.764c-2.396-0.194-3.229-1.827-3.565-4.079
                c-0.72-4.828-3.784-7.845-8.395-8.735c-2.856-0.551-4.229-1.992-5.514-4.347c-3.091-5.671-7.529-9.666-14.357-10.179
                c-1.198-0.09-2.095-0.609-3.005-1.494c-3.83-3.72-7.574-3.753-12.062-0.377c-0.642,0.482-1.418,0.843-2.187,1.089
                c-4.802,1.541-9.449,3.423-13.771,6.052c-9.221-2.662-21.208-2.042-30.814,1.783c2.953,3.224,7.867,3.202,10.229,6.77
                c0.254,0.384,1.23,0.394,1.871,0.39c0.82-0.005,1.681-0.077,2.454-0.332c5.073-1.675,9.785,0.504,14.62,1.32
                c1.244,0.21,1.845,1.224,2.591,2.157c1.875,2.341,4.122,3.781,7.338,2.423c1.72-0.727,3.567-0.689,5.405-0.253
                c3.126,0.742,6.647-0.465,9.464,1.982c0.356,0.309,1.282-0.007,1.939-0.08c4.979-0.549,8.655,1.683,11.609,5.438
                c0.922,1.171,1.595,2.631,2.723,3.531C114.344,454.067,117.88,459.157,119.388,465.988z M995.654,544.733
                c0.795-20.392,1.615-40.507,2.336-60.625c0.154-4.306-1.779-8.052-5.276-10.233c-5.603-3.494-7.415-8.551-7.693-14.644
                c-0.06-1.323-0.384-2.633-0.563-3.952c-0.801-5.869-1.279-11.601-5.164-16.787c-4.94-6.596-10.234-12.199-18.489-14.273
                c-1.448-0.364-3.093-0.577-4.233-1.418c-3.016-2.226-7.432-3.699-5.898-8.979c0.286-0.985-0.582-1.445-1.074-2.068
                c-0.713-0.9-1.645-1.698-1.42-3.366c5.502-4.466,11.936,0.244,17.974-1.134c-4.106-5.412-9.843-8.827-13.881-13.75
                c-4.413-5.381-9.568-6.593-15.87-5.526c-0.326,0.055-0.666,0.027-0.997,0.062c-4.462,0.468-6.593,2.776-6.337,7.213
                c0.375,6.481,1.643,12.533,4.775,18.56c3.135,6.028,7.807,10.298,12.662,14.247c6.466,5.261,12.905,10.468,18.713,16.466
                c4.46,4.606,6.913,10.028,7.891,16.324c1.862,11.996,2.763,24.104,4.793,36.1c1.913,11.305,3.435,22.647,7.073,33.561
                c1.208,3.623,2.698,7.159,4.202,10.674C990.308,543.829,992.082,545.658,995.654,544.733z M815.467,495.872
                c6.592-2.31,12.392-6.257,19.407-3.04c1.78-1.129,1.438-4.224,3.73-4.537c2.081-0.106,2.234,2.369,4.289,2.157
                c1.446-2.165,4.624-1.901,6.427-3.945c2.992-3.396,2.986-3.401,6.295-0.991c0.95-0.998,1.206-2.312,1.632-3.553
                c2.114-6.163,3.11-12.596,4.602-18.908c0.771-3.26,0.439-6.071-1.656-8.878c-1.673-2.24-2.802-4.909-4.013-7.463
                c-0.797-1.681-1.711-3.234-3.034-4.535c-4.155-4.085-8.787-6.069-14.888-4.845c-9.655,1.936-17.744,5.747-21.861,15.346
                c-0.75,1.747-2.222,2.463-3.717,3.105c-3.281,1.41-5.331,3.888-6.637,7.088c-1.32,3.236-2.625,6.479-3.896,9.733
                c-1.168,2.991-2.432,5.855-5.791,7.077c-1.109,0.404-2.219,1.16-2.442,2.347c-0.823,4.354-2.998,8.233-4.355,12.679
                c2.159-1.783,5.086,0.549,6.645-1.274c3.462-4.05,8.009-1.552,11.959-2.245c1.436-0.252,3.833,0.225,5.659,0.869
                C814.391,493.381,814.914,494.59,815.467,495.872z M909.252,883.043c-4.314-2.44-4.488-3.143-1.828-6.804
                c0.971-1.337,2.042-2.602,3.194-4.06c-0.903-2.312-1.798-4.607-2.678-6.861c-4.114,0.781-4.088,0.769-5.821-2.586
                c-0.951-1.844-1.199-4.246-3.635-4.956c-1.645,0.367-2.699,1.392-3.582,2.642c-0.48,0.678-0.878,1.428-1.432,2.038
                c-3.043,3.354-5.258,3.104-7.163-0.911c-0.851-1.793-1.438-3.722-2.026-5.625c-0.98-3.177-1.804-6.402-2.816-9.568
                c-0.422-1.32-0.68-2.883-2.279-3.527c-1.8,1.234-2.133,3.082-2.557,4.818c-0.67,2.744-1.116,5.543-1.809,8.279
                c-0.77,3.041-1.057,6.386-3.523,8.646c-2.035,1.866-2.629,4.193-2.88,6.687c-0.233,2.312-0.194,4.651-0.315,6.978
                c-0.203,3.916-0.89,7.537-5.799,8.152c0.841,2.133,1.574,3.811,2.169,5.536c1.665,4.833,1.022,5.788-3.977,6.179
                c-0.454,0.035-0.89,0.309-1.968,0.703c-0.108,9.082,5.49,16.441,7.584,25.047c7.74,0,15.218-0.019,22.695,0.016
                c1.426,0.007,2.537-0.434,3.545-1.456c5.607-5.689,11.291-11.308,16.432-17.434c0.828-0.987,1.834-2.043,1.074-3.406
                c-3.823-2.493-8.206,1.972-12.479-1.5C900.961,894.179,908.215,890.848,909.252,883.043z M129.584,239.028
                c-0.983-1.163-1.528-2.039-2.283-2.66c-2.98-2.449-3.35-5.27-2.037-8.839c1.379-3.751,1.739-7.769,1.827-11.788
                c0.167-7.726,1.848-15.421,0.006-22.929c2.596-1.58,4.849,0.002,7.066-0.313c0.668-3.283,0.55-6.453,0.517-9.593
                c-0.065-6.175,0.258-12.288,1.545-18.354c0.529-2.497,1.325-4.336,4.186-4.752c2.577-0.374,3.596-2.104,3.305-4.66
                c-0.184-1.615,0.322-3.318-0.683-5.002c-2.243-0.186-4.512,0.934-6.826,0.304c-3.716-1.012-7.15,0.048-10.721,1.088
                c-3.29,0.958-4.315,2.687-4.584,5.99c-1.795,22.076-1.866,22.07-11.346,41.847c-0.216,0.45-0.399,0.919-0.651,1.347
                c-1.484,2.517-1.892,5.204-1.796,8.127c0.504,15.304,1.559,30.616,0.987,45.918c-0.307,8.21,1.983,15.404,5.833,22.298
                c2.977,5.329,4.919,10.813,4.978,17.016c0.053,5.583,2.573,10,7.58,13.236c0.163-0.815,0.354-1.271,0.329-1.714
                c-0.997-17.051,0.77-33.866,3.952-50.606c1.176-6.185,2.489-12.414,2.206-18.795c-0.021-0.467,0.007-1.023-0.832-1.086
                C131.474,236.132,130.769,237.212,129.584,239.028z M697.6,873.412c-1.819-1.369-3.686-2.7-5.465-4.137
                c-1.514-1.223-3.719-2.152-3.503-4.5c0.243-2.648,2.925-2.228,4.612-3.041c0.136-0.065,0.241-0.195,0.713-0.588
                c-4.045-1.968-7.405-4.634-10.042-8.092c-1.066-1.397-2.762-2.874-1.601-4.854c1.13-1.926,3.033-0.343,4.572-0.53
                c0.273-0.034,0.512-0.35,1.085-0.767c-2.534-6.161-3.917-12.81-6.017-19.753c-1.866,2.025-3.301,3.583-4.636,5.032
                c3.925,4.53-2.149,6.761-1.83,10.561c2.024,1.239,1.999,3.243,1.083,5.453c-1.225,2.954-2.951,5.583-5.086,7.952
                c-1.752,1.945-3.504,3.89-5.425,6.022c2.651,1.881,1.879,4.444,0.462,6.876c-1.082,1.856-2.49,3.523-3.776,5.258
                c-1.548,2.088-3.065,4.185-3.658,6.849c4.573,0.75,5.421,1.654,4.934,5.409c-0.628,4.831-3.128,8.707-6.608,12
                c-1.914,1.811-4.04,3.418-5.717,5.753c3.312,6.591,6.566,13.069,9.818,19.541c8.094,0,15.746,0.021,23.398-0.007
                c4.834-0.018,5.212-0.421,5.832-5.807c-1.076-0.983-3.02-0.303-4.175-2.424c2.423-5.436,9.607-7.022,12.124-12.637
                c-0.917-1.529-3.521-1.09-3.357-3.635c2.889-3.384,8.068-4.258,10.623-8.387c-2.207-3.898-6.963-1.686-9.451-3.947
                c-0.68-2.526,1.623-3.859,1.562-5.944c-1.257-1.978-4.413-2.515-4.491-5.321C694.703,874.246,696.732,874.949,697.6,873.412z
                M545.971,558.555c-2.186-5.921-4.512-11.619-6.388-17.461c-2.034-6.334-5.281-11.772-10.068-16.392
                c-6.471-6.243-12.808-12.626-19.213-18.937c-3.208-3.161-6.386-6.354-8.604-10.324c-1.016-1.818-2.278-2.667-4.461-2.174
                c-2.231,0.505-4.016-0.558-4.914-2.539c-0.981-2.166-2.701-2.846-4.763-2.992c-2.764-0.197-4.31-1.436-3.861-4.433
                c-3.745-0.59-6.885,0.015-9.847,1.704c-0.107,2.434,1.356,3.404,3.198,3.938c2.543,0.737,3.812,2.131,3.886,4.99
                c0.078,3.027-1.341,4.38-3.828,5.662c-3.518,1.813-7.275,2.363-12.162,2.658c3.651,2.794,4.198,6.068,5.304,9.205
                c1.783,5.06,3.03,10.597,8.406,13.592c4.958,2.762,9.299,6.783,15.364,7.186c0.465,0.031,0.954,0.334,1.352,0.621
                c2.871,2.07,5.171,5.24,9.408,4.633c0.5-0.072,1.245,0.541,1.656,1.023c3.535,4.16,8.467,5.619,13.432,6.966
                c2.658,0.722,4.76,1.991,6.603,4.146c2.049,2.395,4.615,4.196,8.105,4.34c2.911,0.119,5.383,1.57,7.504,3.577
                C543.021,558.434,543.879,559.622,545.971,558.555z M64.67,76.432c1.061,7.851,0.026,15.54-0.303,23.245
                c-0.633,14.807-1.302,29.623-1.955,44.435c-0.146,3.324,0.541,6.255,2.433,8.952c2.87,4.09,5.697,8.21,8.586,12.286
                c1.161,1.637,2.208,3.237,2.564,5.32c2.107,12.325,3.863,24.671,3.847,37.222c-0.023,18.497,0.127,36.994,0.106,55.491
                c-0.023,20.834-0.448,41.65-3.769,62.288c-0.186,1.157-0.385,2.353,1.381,3.222c3.062-1.797,6.588-0.482,10.385-0.066
                c0.031-18.69,1.553-36.984,0.438-55.308c-0.668-10.979-1.34-21.952-1.34-32.959c0-5.496-0.023-11.005-0.381-16.485
                c-0.804-12.299-1.808-24.584-2.759-36.873c-0.82-10.592-0.734-21.278-4.88-31.414c-3.414-8.346-5.553-17.042-5.697-26.185
                c-0.118-7.49-1.227-14.942-1.163-22.453c0.023-2.742,0.479-5.228,2.561-7.299c7.604-7.57,7.131-16.546,4.446-25.989
                C74.28,73.004,69.854,74.234,64.67,76.432z M277.53,121.177c1.987,2.849,1.535,5.191,1.498,7.499
                c-0.309,18.914-3.313,37.744-1.208,56.751c0.971,8.768-0.611,17.61-1.029,26.419c-0.111,2.339-0.432,4.688-1.486,6.791
                c-4.445,8.866-5.251,17.97-2.098,27.409c1.275,3.816,0.75,7.556-0.663,11.232c-0.787,2.046-1.089,4.154-1,6.359
                c0.635,15.801,1.446,31.599,1.745,47.407c0.148,7.848,1.678,15.023,5.786,21.785c2.051,3.376,3.208,7.293,4.81,10.948
                c0.485,1.106,0.428,2.608,2.084,3.125c0-1.779,0.142-3.443-0.021-5.078c-1.69-16.95-1.07-33.89-0.273-50.867
                c0.763-16.281,0.941-32.564-0.281-48.888c-1.038-13.871-2.845-27.891,0.857-41.718c0.316-1.18,0.025-2.247-0.233-3.402
                c-1.728-7.733-1.671-15.504,0.27-23.167c0.661-2.608,1.217-5.146,1.215-7.833c-0.006-9.323,0.02-18.646,0.063-27.969
                c0.047-10.008,0.062-10.009-7.998-15.858C279.306,121.935,278.976,121.843,277.53,121.177z M4.165,68.134
                c-1.079,8.874-0.649,179.701,0.584,183.435c2.469-20.298,5.127-40.086,7.201-59.935c1.388-13.286,3.243-26.417,7.272-39.186
                c0.699-2.214,1.415-4.492,1.612-6.785c1.159-13.439,2.016-26.905,3.275-40.333c0.948-10.111-0.024-20.338,1.97-30.38
                c0.199-1,1.255-2.119,0.054-3.18c-8.171,2.077-15.576,0.52-17.805-3.759C7.032,67.742,5.726,67.812,4.165,68.134z
                M1030.57,428.917c-1.744-6.11-1.579-11.42-1.564-16.737c0.009-3.359-1.447-6.195-4.428-7.926
                c-5.789-3.363-11.727-6.359-18.688-6.313c-2.734,0.018-5.434-0.041-6.287-3.518c-0.259-1.052-1.334-1.404-2.258-1.802
                c-6.116-2.632-12.135-5.532-18.381-7.803c-3.572-1.299-6.635-3.176-9.254-5.708c-3.543-3.423-5.347-8.507-10.176-10.755
                c-0.222-0.103-0.311-0.57-0.392-0.886c-1.009-3.918-4.094-5.125-7.562-5.356c-2.754-0.184-5.139-1.005-7.542-2.271
                c-3.438-1.811-7.127-2.457-10.907-1.591c-0.581,1.496-1.067,2.72-0.2,4.479c3.191-0.153,5.848,1.247,7.563,4.282
                c0.697,1.233,1.975,1.662,3.321,1.994c3.789,0.932,6.381,3.22,8.115,6.779c1.307,2.679,3.234,5.047,4.713,7.652
                c1.478,2.601,2.772,5.29,6.553,5.413c-1.511,8.1,2.153,13.3,8.482,17.135c2.762,1.673,4.684,4.14,6.479,6.809
                c3.579,5.32,8.359,8.886,14.972,9.716c4.095,0.514,8.433,0.715,12.142,2.285c5.348,2.263,11.1,3.284,16.222,6.125
                C1024.036,429.263,1026.485,427.781,1030.57,428.917z M1168.994,375.183c4.922-2.261,9.591-3.981,14.624-4.354
                c3.814-0.283,7.639-0.44,11.46-0.634c1.842-0.093,3.545-0.303,5.031-1.704c3.911-3.686,8.59-6.238,13.333-8.685
                c1.079-0.557,2.691-0.734,2.628-2.525c-0.807-1.016-1.914-1.315-3.107-1.384c-3.316-0.193-6.229-1.054-7.822-4.372
                c-0.363-0.758-1.123-1.271-1.963-1.425c-2.899-0.535-5.567-1.786-8.29-2.668c-2.993-0.97-5.937-1.43-8.963-3.014
                c-5.196-2.719-10.555-4.958-15.104-8.84c-2.883-2.461-6.669-2.636-9.898-1.086c-2.553,1.225-4.925,1.628-7.632,1.535
                c-2.765-0.096-5.544,0.931-7.069,3.031c-2.091,2.878-4.9,3.968-7.978,4.573c-2.68,0.527-4.609,1.636-5.809,4.113
                c-0.736,1.52-1.786,3.084-3.543,3.264c-4.089,0.417-5.086,3.371-6.408,6.741c5.239,0.501,7.984,5.209,12.727,5.983
                c0.525,0.085,1.232,0.811,1.349,1.349c0.704,3.251,3.411,4.222,5.741,5.573c4.804-2.306,9.937-1.454,14.759-2.544
                c3.128-0.707,5.815,0.122,7.735,3.123C1165.743,372.713,1167.413,373.735,1168.994,375.183z M1146.475,248.657
                c2.437-4.771,6.279-7.294,9.312-10.586c2.787-3.025,4.205-3.125,7.755-0.829c2.515,1.627,4.989,3.321,7.54,4.892
                c4.859,2.995,9.438,6.232,12.108,11.548c0.661,1.316,1.745,2.431,2.694,3.59c2.463,3.008,5.154,3.716,8.762,2.277
                c1.233-0.492,2.587-0.917,3.582-1.739c4.547-3.763,9.834-5.369,15.603-5.76c0.295-0.02,0.573-0.284,0.851-0.43
                c0.16-0.954-0.481-1.546-0.977-2.193c-3.457-4.522-8.127-7.315-13.35-9.298c-4.506-1.711-9.127-3.157-13.523-5.114
                c-7.619-3.393-14.744-7.636-21.312-12.86c-4.205-3.345-9.088-5.704-14.518-6.214c-5.554-0.522-10.237-2.739-14.365-6.292
                c-3.438-2.957-7.329-5.165-11.234-7.407c-5.992-3.44-9.566-9.44-14.456-14.057c-0.257-5.981-3.737-9.761-8.411-12.97
                c-2.111-1.449-1.918-3.701-0.201-5.673c1.152-1.324,2.271-2.678,3.643-4.301c-13.163,3.769-18.242,17.068-13.999,25.49
                c3.571-2.3,6.897-1.414,10.061,0.985c0.389,0.294,0.896,0.437,1.354,0.637c3.956,1.724,4.39,2.014,3.358,5.773
                c-1.133,4.13,0.676,6.833,3.184,9.945c1.121-1.651,1.842-2.954,2.793-4.058c1.633-1.894,3.023-1.686,4.221,0.516
                c0.316,0.582,0.62,1.209,0.738,1.852c0.742,4.004,3.071,5.967,7.184,6.156c3.668,0.169,6.021,2.181,7.495,5.638
                c1.03,2.416,2.5,4.761,4.231,6.737c2.854,3.258,4.398,7,5.322,11.121C1142.83,240.094,1144.174,244.007,1146.475,248.657z
                M850.877,500.804c-3.879,0.362-6.439,2.224-8.944,4.058c-4.21,3.082-8.708,5.514-13.746,6.939
                c-1.108,0.312-2.205,0.771-3.206,1.34c-7.965,4.528-15.906,9.102-23.409,14.376c-1.899,1.336-4.024,2.626-5.358,4.442
                c-5.125,6.976-10.514,13.717-17.092,20.275c2.922,0.838,4.146,2.623,4.403,4.967c3.194-0.496,3.194-0.496,5.001-3.129
                c1.443-2.103,3.431-2.617,5.582-1.223c0.779,0.506,1.254,1.48,2.106,2.536c1.085-1.662,2.183-2.427,3.777-2.651
                c2.545-0.359,3.657,1.883,5.382,2.823c4.73-4.751,5.638-4.778,11.789-0.187c1.627-2.932,4.107-5.147,7.572-3.639
                c2.795,1.215,6.451,0.541,8.402,3.77c0.549,0.91,1.801,0.66,2.797,0.538c1.986-0.243,3.847-0.842,5.76-1.882
                C843.824,536.271,847.998,518.816,850.877,500.804z M380.529,553.945c-0.47-3.691-3.083-6.576-1.684-9.734
                c1.714-3.872,0.331-7.115-1.064-10.508c-1.202-2.926-2.275-5.909-3.598-8.777c-2.347-5.086-3.65-10.334-3.161-15.971
                c0.176-2.026-0.132-3.99-1.215-5.77c-1.809-2.971-3.622-5.938-5.271-8.641c-1.915-0.633-3.327,0.309-4.823,0.164
                c-2.883-0.28-5.969-0.613-7.228-3.576c-3.006-7.074-7.464-13.379-10.505-20.417c-1.476-3.417-2.447-6.915-1.44-10.677
                c0.304-1.136,0.6-2.321-0.177-3.366c-2.017-2.709-2.738-6.056-4.45-8.909c-1.175-1.959-2.161-4.083-4.165-5.398
                c-0.698,0.724-0.435,1.344-0.325,1.95c4.919,27.239,7.379,54.762,9.495,82.323c0.492,6.409,2.392,9.139,8.615,10.982
                c2.412,0.715,4.734,1.602,6.985,2.645c5.075,2.353,9.146,6.285,13.77,9.347C373.459,551.713,376.427,553.186,380.529,553.945z
                M476.466,401.729c4.92-4.692,10.416-5.49,16.217-4.759c3.885,0.489,7.014-0.576,10.119-2.996
                c7.873-6.136,16.062-11.866,23.955-17.978c4.478-3.468,9.249-4.873,14.881-4.22c5.336,0.619,9.829-2.398,12.234-7.728
                c-10.972-2.078-22.061-3.603-32.739-6.719c-9.487-2.769-18.097-1.373-26.422,3.229c-8.297,4.586-16.462,9.414-24.683,14.138
                c-0.72,0.414-1.46,0.817-1.771,2C470.625,384.734,473.208,392.958,476.466,401.729z M241.193,879.897
                c2.034-3.446,5.417-4.582,8.437-6.187c1.142-0.607,2.861-0.625,3.109-2.175c0.503-3.144,2.84-3.997,5.346-4.374
                c3.169-0.477,4.945-2.134,5.697-5.149c-1.84-1.581-4.457-1.866-5.566-3.854c-0.312-1.371,1.384-1.66,1.069-2.684
                c-2.652-4.1-6.026-2.058-8.956-0.953c-3.753,1.416-7.532,2.504-11.472,3.154c-1.125-0.652-0.143-2.061-1.511-2.891
                c-1.882-0.123-4.219-0.137-6.51-0.467c-2.219-0.319-5.065,0.637-6.47-2.553c3.587-3.057,7.843-1.761,11.616-2.447
                c4.155-0.755,7.757-2.94,11.867-3.676c-1.527-4.557-1.527-4.557,0.84-7.228c-1.882-1.804-2.766-4.31-4.125-7.181
                c-0.861,2.061-2.905,2.828-2.51,5.025c0.901,5.006-2.605,7.702-5.862,10.128c-3.118,2.323-5.961-0.263-8.728-1.655
                c-0.443-0.223-0.856-0.521-1.24-0.838c-1.396-1.149-3.266-2.125-3.064-4.24c0.212-2.223,2.621-1.604,3.892-2.506
                c0.227-0.16,0.302-0.535,0.495-0.902c-0.383-0.673-0.853-1.366-1.193-2.118c-1.312-2.898-5.575-4.743-3.235-8.979
                c0.084-0.154-0.232-0.628-0.464-0.842c-2.352-2.164-3.368-5.205-4.961-7.979c-1.73,1.157-3.722,2.055-3.304,4.193
                c0.613,3.137-1.508,6.399,0.596,9.443c0.288,0.416-0.996,0.844-0.745,1.629c0.884,1.328,3.259,1.293,3.135,3.521
                c-0.474,1.271-1.555,1.664-2.756,1.737c-3.076,0.188-6.296-0.64-8.995,1.806c-0.68,0.616-1.914,0.062-2.864-0.309
                c-1.575-0.612-2.874-1.646-4.251-2.585c-4.898-3.336-10.329-3.151-15.869-2.427c3.701,3.673,9.989,2.214,13.262,6.825
                c-2.277,2.49-5.041,2.467-7.637,2.664c-5.315,0.404-10.646,0.609-15.969,0.917c-1.187,0.068-2.369,0.225-3.789,0.363
                c1.171,1.757,2.602,1.603,3.846,1.88c6.334,1.411,12.731,2.584,18.891,4.687c1.96,0.669,4.327,0.952,4.754,3.752
                c-3.435,3.595-8.141,1.282-12.09,2.883c6.839,0.652,13.167,3.14,19.768,4.46c0.648,0.129,1.328,0.097,1.992,0.146
                c8.653,0.652,17.288,0.576,25.887-0.689c3.386-0.5,7.142-0.537,9.742,1.193c3.386,2.255,6.991,3.189,10.674,4.301
                c0.195,0.059,0.257,0.562,0.63,1.453c-7.061,2.546-14.166-0.603-21.585-0.136c2.256,2.382,5.889,1.345,7.038,5.229
                c-8.349,1.984-16.498,3.41-25.055,1.956c6.258,2.886,12.473,5.407,18.178,6.961c1.474,1.682,0.014,2.302,0.083,3.525
                c2.103,0.091,3.917,0.915,4.232,3.284c0.322,2.42-1.5,3.482-3.234,4.553c-0.458,0.283-1.37,0.273-0.957,1.65
                c2.018,0.434,4.221,0.905,5.935,1.273c2.708-1.586,1.182-4,2.186-6.031c5.111,0.073,6.543-5.261,10.954-7.233
                C247.23,881.638,243.73,882.92,241.193,879.897z M467.457,675.23c2.224-4.725,6.401-2.863,9.438-3.186
                c3.799-0.403,6.029,0.389,8.192-1.213c2.225-1.647,3.587-1.109,4.849,0.99c0.5,0.832,0.868,1.838,2.332,1.852
                c2.411-1.356,1.508-4.939,4.178-6.216c-0.6-0.839-0.865-1.619-1.384-1.868c-3.545-1.701-6.199-4.894-10.111-5.977
                c-1.635-0.453-3.165-1.301-4.146-2.662c-2.008-2.785-5.077-2.529-7.861-3.43c-2.665-0.862-4.338-4.049-7.481-4.615
                c-0.491-0.09-0.999-0.238-1.481-0.188c-3.087,0.319-5.504-0.755-7.403-3.227c-0.472-0.615-1.32-1.136-2.083-1.302
                c-4.63-1.013-8.631-3.459-12.815-5.482c-1.859-0.898-3.786-1.23-5.817-1.044c-1.719,0.157-3.551,0.127-4.566-1.374
                c-1.973-2.917-4.911-3.794-8.054-4.438c-1.979-0.405-3.776-0.956-4.946-2.93c-0.643-1.084-1.808-2.005-2.943-2.617
                c-2.676-1.444-4.739-3.65-7.151-5.377c-5.521-3.952-10.971-7.803-15.138-13.382c-2.158-2.89-4.18-2.8-7.753-1.711
                c0.226,1.714,1.759,2.298,2.828,3.283c5.3,4.882,9.227,10.543,9.667,17.992c0.113,1.921,1.029,2.956,2.417,4.031
                c5.789,4.482,11.243,9.396,17.543,13.213c2.427,1.471,4.499,3.43,6.246,5.698c0.31,0.402,0.714,0.676,1.328,0.555
                c1.05-0.822,1.354-2.777,3.808-2.318c4.488,5.242,0.091,12.766,3.519,18.521c5.252-2.178,4.832-1.649,8.228,4.583
                c0.568-4.419,5.341-4.898,6.162-8.564c4.376,0.416,3.722,5.113,6.465,6.636c2.212-1.12,2.373-3.581,3.65-5.265
                c1.528-2.014,3.558-2.023,4.047,0.291C462.091,668.613,465.887,670.801,467.457,675.23z M4.003,411.874
                c2.846,0.466,5.402,0.866,7.939,1.81c2.536,0.943,5.15,2.15,8.039,2.098c2.498-0.045,4.825,1.155,6.076,3
                c2.267,3.34,5.521,4.378,9.032,5.306c1.084,0.287,2.061,1.32,3.263,0.553c2.204-1.406,4.799-1.3,7.201-1.869
                c5.403-1.28,10.684-2.703,16.494-0.806c2.555,0.834,6.173,1.429,9.005-0.272c3.272-1.964,6.939-3.014,10.411-4.323
                c2.757-1.04,4.148-2.696,5.746-4.58c-0.479-0.693-0.669-1.29-1.058-1.479c-4.962-2.415-9.271-6.652-15.501-5.604
                c-0.625,0.105-1.382,0.003-1.958-0.262c-3.738-1.724-7.393-1.171-11.151,0.025c-3.53,1.124-6.878,0.722-9.771-1.948
                c-2.636-2.433-5.94-3.685-9.328-4.565c-2.699-0.701-5.501-1.004-7.999-1.439c-1.636-5.999-4.047-11.09-9.241-14.47
                c-1.09-0.709-2.115-1.682-2.846-2.754c-3.157-4.637-8.516-5.265-13.077-7.331c-0.34-0.154-1.006-0.067-1.277,0.694
                C4.003,386.273,4.003,399.04,4.003,411.874z M1235.287,521.021c0.207-0.925,0.438-1.554,0.477-2.194
                c0.945-15.14,3.24-30.087,6.019-44.99c2.685-14.398,4.65-28.894,4.731-43.605c0.025-4.651-0.451-9.304,0.107-13.966
                c1.404-11.731,3.246-23.447,3.857-35.225c0.499-9.602,2.42-18.758,5.047-27.891c1.241-4.313,2.322-8.684,3.206-13.083
                c0.438-2.179,1.479-4.362,0.891-6.76c-3.054,0.434-4.863,2.438-6.662,4.387c-4.3,4.656-8.01,9.786-11.856,14.811
                c-1.992,2.603-3.1,5.31-3.291,8.637c-0.211,3.636-0.99,7.246-1.623,10.849c-0.463,2.628-0.79,5.085,0.533,7.704
                c1.371,2.711,1.268,5.754,0.778,8.806c-1.315,8.213-2.55,16.441-3.63,24.688c-0.451,3.444-1.289,7.409,0.507,10.296
                c3.815,6.133,3.211,12.417,2.056,18.84c-2.699,15.011-2.887,30.158-2.222,45.296c0.243,5.553,0.051,10.93-1.013,16.33
                c-0.902,4.585-1.22,9.225-1.424,13.887C1231.676,520.111,1232.328,521.361,1235.287,521.021z M897.223,377.428
                c10.948-14.246,15.277-30.45,18.139-47.25c-4.395-0.884-4.885-0.797-8.898,0.614c-5.488,1.93-11.079,3.846-16.857,4.222
                c-5.133,0.334-9.727,1.642-14.191,4.011c-3.076,1.633-6.316,2.957-9.468,4.45c-1.169,0.554-2.575,0.812-3.255,2.617
                c4.926,14.504,7.774,29.854,11.167,45.155c2.638-4.277,3.978-9.255,9.028-11.133C887.441,378.42,891.737,375.227,897.223,377.428z
                M821.746,356.624c0.785,2.567,1.295,4.292,1.84,6.005c2.025,6.363,3.427,12.761,3.18,19.562
                c-0.397,10.892,1.309,21.575,4.714,31.931c1.763,5.36,3.228,10.559-1.819,15.637c8.023,0.585,14.057,4.202,20.162,7.557
                c3.726,2.046,6.57,5.008,8.24,9.053c1.129,2.736,2.479,5.396,4.346,7.707c1.27-0.659,1.257-1.721,1.401-2.66
                c0.833-5.423,1.469-10.882,2.495-16.267c0.679-3.566,0.238-6.938-0.666-10.331c-2.624-9.844-6.165-19.442-11.092-28.266
                c-4.244-7.6-9.148-15.158-17.4-19.417c-3.461-1.786-5.418-4.589-6.546-8.344C829.205,364.146,827.052,359.816,821.746,356.624z
                M410.792,510.732c3.151,0.004,5.507,0.676,7.798,1.743c3.475,1.62,7.092,2.471,11.002,2.741
                c4.808,0.332,8.461,3.072,11.262,7.123c2.716,3.928,5.569,7.832,8.872,11.255c1.969,2.042,4.052,3.77,5.227,6.488
                c1.277,2.956,3.252,5.247,6.895,5.644c2.419,0.263,4.266,1.811,5.463,3.977c3.026,5.475,7.01,10.143,12.316,13.443
                c2.681,1.668,5.667,2.775,7.252,5.795c0.417,0.794,1.361,0.99,2.195,0.932c2.765-0.192,4.165,1.533,5.518,3.565
                c0.812,1.219,1.727,2.468,3.375,3.194c2.248-1.059,4.553-2.423,7.416-1.669c3.062,0.806,3.172,4.538,5.799,5.873
                c1.983-1.726,2.941-4.284,5.894-4.564c3.051-0.29,4.542,2.401,7.027,3.409c1.944-1.762,2.753-4.549,5.467-5.096
                c2.715-0.549,4.373,1.722,6.609,3.035c1.168-3.164-0.843-3.579-2.593-3.665c-2.465-0.121-4.963,0.06-7.423,0.334
                c-1.064,0.118-2.101,0.288-2.734-0.591c-2.662-3.693-6.165-5.98-10.653-6.775c-0.296-0.053-0.731-0.309-0.791-0.547
                c-0.907-3.649-4.684-4.465-6.875-6.87c-2.244-2.463-3.839-5.483-6.077-7.687c-4.105-4.045-9.17-7.162-14.789-8.902
                c-4.505-1.395-8.67-3.1-11.708-6.93c-1.696-2.141-4.143-3.689-6.579-4.379c-3.139-0.889-5.9-2.987-9.401-2.826
                c-2.38,0.109-4.048-1.448-5.126-3.451c-2.289-4.248-7.24-6.162-9.25-10.653c-0.163-0.364-0.832-0.497-1.259-0.747
                c-6.14-3.591-12.29-7.058-16.039-13.603c-1.763-3.077-4.583-5.331-8.642-4.746c-1.627,0.234-2.843-0.664-4.186-1.329
                c-1.081-0.535-2.14-1.666-3.848-0.582C409.042,499.17,409.882,504.721,410.792,510.732z M1014.713,373.608
                c-4.727-3.431-4.506-3.548-7.514-9.203c-5.225-9.821-12.58-16.72-24.646-16.387c-0.666,0.018-1.336,0.034-1.998-0.022
                c-12.262-1.047-24.153,2.238-36.244,3.211c-3.077,0.248-6.715,0.765-8.612,4.483c3.375,0.712,6.771,0.873,9.64,2.718
                c1.73,1.114,3.598,1.593,5.646,1.74c4.393,0.314,8.137,1.754,9.93,6.282c0.223,0.564,0.773,1.091,1.305,1.425
                c2.771,1.741,4.494,4.398,6.336,6.976c2.678,3.743,6.063,6.539,10.52,8.028c2.835,0.947,5.582,2.171,8.331,3.357
                c4.707,2.031,9.385,4.128,13.897,6.12c3.637-2.596,6.255-5.327,5.752-9.923c-0.148-1.356,0.823-2.493,1.654-3.547
                C1010.364,376.765,1012.195,374.853,1014.713,373.608z M1189.139,374.027c-0.812,2.465-0.594,4.479-0.636,6.457
                c-0.271,12.807,0.045,25.637-1.667,38.385c-0.42,3.132-0.527,6.29-0.434,9.465c0.421,14.13,1.371,28.227,1.422,42.392
                c0.041,11.377,2.882,22.717,1.217,34.557c3.54,0.475,6.067,2.684,9.585,2.559c0.097-1.441,0.233-2.734,0.263-4.03
                c0.431-18.969,0.836-37.938,1.279-56.907c0.062-2.66,0.223-5.322,0.461-7.972c0.853-9.438,2.334-18.799,2.652-28.308
                c0.396-11.783,0.227-23.585,1.389-35.444C1199.433,372.072,1194.188,374.595,1189.139,374.027z M1155.5,214.524
                c1.95-4.511,4.34-8.803,2.801-14.18c-2.758-9.627-6.725-18.138-17.186-21.574c-0.941-0.31-1.842-0.775-2.722-1.245
                c-4.16-2.218-8.471-3.832-13.29-3.804c-1.028,0.006-2.394,0.127-2.852-1.687c4.114-3.148,8.43-5.724,13.959-5.272
                c3.152,0.258,6.307,0.48,9.457,0.756c4.41,0.386,8.819,0.883,13.121-0.057c0.234-1.478-0.717-1.768-1.377-2.104
                c-9.076-4.626-18.519-8.238-28.562-10.158c-2.008-0.384-3.608,0.36-4.962,1.723c-1.555,1.564-3.376,2.402-5.479,3.181
                c-6.471,2.397-12.037,6.15-15.529,12.263c1.163,2.549,3.103,3.884,5.176,4.929c2.355,1.188,3.444,2.923,3.465,5.541
                c0.023,2.962,1.227,5.369,3.411,7.482c5.938,5.746,11.726,11.642,19.384,15.187c0.741,0.343,1.435,0.881,2.017,1.462
                C1141.561,212.189,1147.976,214.375,1155.5,214.524z M1232.345,424.468c-4.274,3.752-7.837,7.432-10.901,11.583
                c-0.789,1.07-1.295,2.285-1.748,3.557c-2.184,6.126-4.029,12.277-4.006,18.903c0.014,3.81-0.626,7.617-0.84,11.433
                c-0.18,3.217-0.834,6.166-2.8,8.87c-1.851,2.546-3.638,5.312-4.15,8.45c-1.201,7.357-2.093,14.766-3.112,22.167
                c3.332,2.364,5.67,5.407,8.288,8.209c2.664-0.526,5.464-1.647,7.821-0.169c2.841,1.783,5.633,1.113,9.054,1.178
                c-0.103-8.422,1.908-16.327,2.855-24.382c0.115-0.979,0.009-1.994-0.086-2.984c-1.099-11.446-0.991-22.9,0.075-34.332
                c0.664-7.114,1.595-14.207,2.578-21.285C1235.931,431.644,1235.092,428.116,1232.345,424.468z M710.367-0.935
                c5.283,2.417,8.137,2.998,12.856,2.392c3.244-0.417,6.312,0.065,9.2,1.528c1.495,0.757,2.975,1.615,4.641,1.758
                c8.369,0.719,16.119,3.957,24.201,5.84c6.963,1.622,13.848,3.763,20.896,4.792c8.486,1.239,16.024,4.069,22.433,9.785
                c1.293,1.153,2.798,1.648,4.519,1.869c2.961,0.38,5.414,1.757,6.644,4.618c0.855,1.988,2.423,3.102,4.171,4.113
                c1.465,0.848,3.438,1.989,3.271,3.503c-0.413,3.75,2.137,5.738,4.02,8.082c1.687,2.1,2.162,4.012,0.302,6.071
                c3.559,2.865,3.596,2.89,1.17,6.381c-0.445,0.641-0.611,1.106-0.229,1.739c1.213,2.006,0.258,3.465-1.203,4.79
                c-1.729,1.566,1.622,3.356-0.231,4.931c-0.498,0.423-0.863,1.005-1.365,1.424c-3.021,2.516-4.433,6.258-7.446,8.865
                c2.221,1.548,3.504,1.43,5.033-0.445c3.748-4.592,7.287-9.43,13.219-11.723c1.472-0.569,3.995-2.203,3.448-3.854
                c-1.499-4.523,2.319-6.325,4.19-8.773c1.607-2.104,2.74-4.047,1.767-6.229c-2.171-4.866-3.798-10.188-8.755-13.277
                c-1.598-0.996-2.862-2.383-2.998-4.37c-0.125-1.843-1.099-3.151-2.428-4.181c-2.855-2.212-6.266-3.792-7.559-7.633
                c-0.257-0.763-1.464-1.456-2.365-1.713c-7.409-2.112-13.934-6.261-21.062-9.056c-7.176-2.813-15.35-3.33-21.416-8.848
                c-0.338-0.307-0.917-0.402-1.402-0.482c-5.563-0.917-11-2.517-16.736-2.479C745.828-1.413,730.504-1.5,715.18-1.458
                C713.596-1.454,711.906-1.966,710.367-0.935z M255.294,474.987c-1.487-4.239-2.765-7.812-3.993-11.399
                c-3.395-9.917-7.205-19.725-9.213-30.045c-1.233-6.333-3.209-12.382-7.211-17.354c-3.774-4.689-5.014-10.003-5.85-15.624
                c-0.27-1.812-0.587-3.618-0.811-5.435c-0.358-2.907-1.241-5.544-3.729-7.336c-1.213-0.874-1.574-2.035-1.608-3.444
                c-0.132-5.493-0.53-10.992-0.384-16.476c0.1-3.773-0.456-7.325-1.967-10.691c-1.565-3.487-3.374-6.869-5.197-10.231
                c-0.466-0.859-0.587-2.381-2.473-2.039c-0.429,11.315-0.686,22.661,0.719,33.905c1.485,11.887,3.644,23.689,5.515,35.527
                c2.027,12.824,4.06,25.646,6.094,38.469c0.351,2.21,0.856,4.236,3.599,4.706c2.175,0.373,3.51,1.829,4.041,3.952
                c0.261,1.041,1.173,1.574,1.658,2.358c1.86,3.004,5.099,3.874,7.893,5.32C246.319,471.189,249.819,474.24,255.294,474.987z
                M689.441,459.882c-7.037-0.656-10.066,0.99-12.889,6.476c-0.531,1.032-0.889,2.156-1.443,3.174
                c-2.33,4.281-3.953,9.148-9.688,10.389c-1.832,0.396-2.828,2.184-3.498,3.97c-1.99,5.306-2.537,10.538-0.181,15.974
                c1.107,2.555,2.562,4.171,5.302,4.59c4.434,0.678,8.869,1.338,13.293,2.068c1.661,0.275,2.835-0.523,4.088-1.399
                c7.798-5.45,15.128-11.448,21.327-18.694c10.307-12.046,9.028-7.026,6.351-23.849c-0.026-0.164-0.074-0.328-0.074-0.493
                c-0.006-2.569-1.803-3.289-3.857-3.799c-1.696-0.422-3.285-0.027-4.895,0.443C698.443,460.141,693.424,459.07,689.441,459.882z
                M645.292,413.391c-2.026-0.687-2.917-0.096-3.671,0.483c-3.676,2.82-7.755,4.502-12.416,4.614
                c-2.268,0.054-3.608,1.448-4.824,3.105c-3.444,4.692-6.545,9.644-10.531,13.926c-0.946,1.016-1.523,2.291-1.546,3.604
                c-0.068,3.844-1.693,7.435-1.59,11.331c0.056,2.1,0.008,5.389-1.192,6.091c-2.611,1.526-2.565,3.658-2.931,5.856
                c-0.271,1.629-1.291,2.909-2.429,3.646c-4.345,2.818-5.725,7.061-6.423,11.799c-0.188,1.274-0.423,2.632-1.408,3.676
                c-1.1,0.643-2.944-0.584-3.533,1.088c-1.284,3.647-4.846,5.893-5.743,9.795c-0.57,2.479-2.371,3.971-4.993,3.665
                c-1.59-0.186-2.574,0.126-3.184,1.576c-1.774,4.216-5.587,4.96-10.204,5.664c2.329,3.589-3.828,4.347-2.41,8.118
                c2.803,0.579,5.624,0.943,8.297,1.779c2.319,0.727,4.228,0.462,6.031-1.045c6.086-5.086,12.768-9.565,14.895-18.059
                c1.642-6.558,6.04-10.55,12.89-11.58c1.148-0.173,2.291-0.427,3.409-0.74c5.051-1.415,10.092-2.865,15.034-4.273
                c-0.017-2.562-0.816-4.344-1.996-6.006c-3.843-5.414-3.753-8.559,0.52-13.731c1.376-1.666,3.065-3.121,3.708-5.256
                c2.64-8.763,6.733-16.973,9.706-25.597C640.381,422.209,641.301,417.253,645.292,413.391z M769.368,500.676
                c1.808-1.115,2.517-2.496,2.683-4.277c0.439-4.711,2.337-8.951,4.229-13.226c5.768-13.033,8.308-26.934,10.746-40.856
                c0.664-3.786-1.91-5.86-4.224-7.919c-2.122-1.888-4.097,0.012-5.898,0.984c-7.788,4.2-14.492,10.001-21.787,14.935
                c-5.347,3.617-8.101,9.008-10.319,14.895c-2.245,5.958-0.646,11.234,3.576,14.976c6.119,5.422,11.588,10.932,12.741,19.361
                C764.35,499.443,767.416,496.764,769.368,500.676z M1173.938,377.03c-5.362,2.605-9.1,5.462-8.273,11.583
                c0.153,1.134-0.112,2.323-0.164,3.487c-0.274,6.171-0.384,12.303,1.925,18.23c1.054,2.705,0.613,5.586,0.193,8.4
                c-0.516,3.454-0.945,6.924-1.566,10.36c-1.23,6.812,1.252,13.179,2.361,19.682c2.012,11.801,5.966,23.175,8.033,34.981
                c0.339,1.932,0.953,4.025,2.09,5.564c2.372,3.21,3.064,6.521,1.531,10.475c2.803,1.081,3.963,4.041,7.18,4.944
                c0.901-6.553,1.149-12.744,0.284-18.8c-1.182-8.268-1.645-16.497-1.65-24.847c-0.009-11.482-0.749-22.964-1.229-34.444
                c-0.098-2.333-0.646-4.596-1.46-6.807c-4.674-12.691-8.527-25.568-8.75-39.257C1174.428,379.606,1174.174,378.634,1173.938,377.03
                z M852.227,515.184c2.143-1.166,2.818-2.963,3.919-4.374c3.791-4.859,7.425-9.892,12.52-13.488
                c2.018-1.425,3.302-3.135,4.253-5.329c2.643-6.097,4.964-12.367,9.059-17.723c0.518-0.678,0.935-1.383,0.88-2.262
                c-0.358-5.772,0.676-11.609-1.332-17.309c-2.292-6.503-3.12-13.253-2.372-20.159c0.715-6.618,1.467-13.233,2.037-19.864
                c0.615-7.132,2.979-13.554,6.986-19.478c2.783-4.115,5.26-8.437,7.847-12.623c-0.95-1.235-1.798-2.653-3.259-2.193
                c-5.499,1.729-11.355,2.857-13.772,9.378c-3.132,8.449-6.03,16.952-6.927,25.955c-1.501,15.071-3.391,30.084-6.168,44.973
                c-1.736,9.312-3.133,18.768-7.182,27.436c-2.143,4.588-3.617,9.273-4.428,14.219C853.617,506.434,852.973,510.529,852.227,515.184
                z M56.001,82.194c-2.009-0.053-2.525,0.841-3.021,1.695c-1.835,3.169-3.561,6.405-5.481,9.521
                c-1.268,2.057-1.443,4.139-0.299,6.128c2.295,3.99,2.317,8.224,1.927,12.605c-0.384,4.312-0.521,8.645-0.84,12.963
                c-1.64,22.121-1.45,44.259-0.636,66.401c0.191,5.191-0.05,10.335-1.19,15.394c-2.014,8.934-3.83,17.844-3.217,27.124
                c0.284,4.294-0.279,8.635-1.03,12.923c-0.806,4.606-1.013,9.27-0.481,13.94c1.267,11.148,0.918,22.291-0.473,33.385
                c-0.346,2.761,0.298,5.134,1.939,6.87c2.242,2.372,4.775,4.44,6.12,7.677c1.158-8.446,1.228-16.843,2.872-24.921
                c1.825-8.972,1.679-17.86,1.374-26.831c-0.567-16.652-0.715-33.308-0.516-49.964c0.068-5.677-0.084-11.291-0.819-16.928
                c-0.86-6.596-2.086-13.263-1.863-19.856c0.461-13.637,1.13-27.27,1.567-40.914c0.378-11.812,2.952-23.445,3.571-35.262
                C55.711,90.207,56.238,86.284,56.001,82.194z M658.762,489.373c0.291-7.021,5.7-12.033,5.123-18.885
                c-0.04-0.469,0.1-1.051,0.369-1.43c2.164-3.052,1.158-5.851-0.797-8.418c-3.008-3.954-4.245-8.397-4.338-13.29
                c-0.09-4.806-2.465-7.551-7.18-8.557c-3.518-0.75-3.818-1.1-4.032-5.034c-0.072-1.322,0.304-3.043-0.977-3.674
                c-2.312-1.139-3.066-3.733-5.544-5.645c-1.387,7.29-5.454,12.91-7.397,19.419c-1.709,5.728-3.207,11.576-7.857,15.875
                c-0.584,0.541-0.916,1.381-1.285,2.124c-1.371,2.758-1.595,5.44,0.791,7.802c2.013,1.992,2.724,4.558,3.13,7.195
                c0.294,1.907,1.249,2.938,3.033,3.478C640.676,483.021,649.701,485.227,658.762,489.373z M264.498,397.175
                c-0.256,1.49-0.362,1.816-0.362,2.143c-0.015,26.784,0.012,53.569-0.082,80.354c-0.009,2.646,0.874,4.588,3.004,5.841
                c3.811,2.241,6.835,5.809,11.555,6.577c1.375,0.224,2.709,1.281,3.854,2.213c2.854,2.326,5.911,4.282,9.219,5.896
                c1.798,0.877,3.424,2.089,4.676,3.679c1.467,1.866,3.228,2.557,5.739,1.879c-1.207-6.401-2.351-12.592-3.552-18.771
                c-0.359-1.847-1.29-3.425-2.771-4.616c-1.684-1.354-3.226-3.055-5.138-3.924c-6.407-2.914-8.04-7.951-7.667-14.514
                c0.342-6.014-0.73-11.893-3.393-17.442c-2.299-4.793-4.287-9.734-6.461-14.588c-0.684-1.527-1.265-3.013-1.311-4.747
                c-0.119-4.488-0.777-8.993-0.525-13.452C271.642,407.337,268.205,402.835,264.498,397.175z M395.632,627.482
                c-0.02-6.416-2.835-11.934-8.957-17.135c-1.699-1.443-3.176-2.909-3.871-5.058c-0.835-2.581-2.495-4.188-5.222-4.811
                c-2.371-0.54-4.209-1.819-5.105-4.307c-0.665-1.847-2.283-2.966-4.12-3.337c-4.441-0.898-7.781-3.551-11.04-6.422
                c-1.624-1.432-3.221-2.926-5.003-4.14c-3.926-2.675-7.447-5.91-12.938-5.614c-2.899,0.156-6.266-0.521-8.958-1.453
                c-3.227-1.119-5.788-0.721-8.601,0.27c-1.451,0.51-3.078,1.302-4.22,0.055c-2.275-2.484-4.951-3.09-8.106-2.705
                c-1.196,0.146-2.361-0.328-3.232-1.125c-3.522-3.224-6.633-1.463-10.163,0.719c3.618,2.652,7.354,4.447,11.024,6.328
                c3.873,1.984,7.661,4.102,10.821,7.113c4.252,4.055,9.665,6.516,14.021,10.424c1.106,0.992,2.638,1.38,4.121,1.701
                c4.135,0.896,7.843,2.777,11.314,5.12c3.209,2.165,6.657,3.75,10.369,4.826c5.159,1.495,10.062,3.515,14.126,7.186
                c1.517,1.37,3.413,2.054,5.294,2.731c4.73,1.701,9.193,3.871,13.162,7.024C391.881,626.092,393.61,627.083,395.632,627.482z
                M706.804,427.326c3.831-8.185,2.397-12.279-5.175-15.515c-1.975-0.844-4.07-1.603-6.181-1.904
                c-9.56-1.365-19.12-2.791-28.728-3.732c-8.732-0.856-15.975,2.115-21.18,9.583c-3.584,5.143-3.534,7.778,1.503,11.882
                c1.919,1.562,2.657,3.261,2.705,5.529c0.024,1.131-0.316,2.368,1.101,3.473c3.306-0.408,5.602,1.89,8.259,3.862
                c3.601,2.671,0.675,6.677,2.646,9.796c5.578-1.772,8.904-5.956,11.176-10.853c1.829-3.942,3.982-7.263,8.014-9.13
                c2.719-1.259,5.419-2.556,8.115-3.861c1.058-0.512,2.09-1.001,3.324-0.877C697.262,426.069,702.329,424.326,706.804,427.326z
                M372.542,315.057c1.523-2.79,1.342-5.546,1.969-8.118c0.857-3.52,0.616-6.779-1.374-10.033c-1.73-2.83-2.958-6.09-2.604-9.488
                c0.264-2.538,0.074-4.961-0.295-7.435c-0.786-5.265-1.82-10.516-2.204-15.812c-1.297-17.937-3.452-35.774-5.834-53.588
                c-0.561-4.188-0.525-8.256,0.972-12.283c0.867-2.334,1.456-4.757,1.493-7.301c0.111-7.497,0.108-15-0.934-22.43
                c-0.734-5.235-0.567-10.605-2.084-15.708c-3.382-1.732-6.697-2.707-10.677-1.554c2.257,4.986,4.353,9.617,6.221,13.743
                c-0.851,1.994-2.102,0.108-2.927,1.363c1.265,3.219,3.116,6.379,2.758,10.252c-0.643,6.958-1.181,13.929-1.145,20.935
                c0.013,2.51-0.676,4.919-2.806,6.328c-1.579,0.156-2.178-1.613-3.999-1.529c1.646,4.669,3.134,8.879,4.615,13.09
                c0.774,2.2,1.346,4.413,1.497,6.785c1.289,20.266,2.621,40.53,4.027,60.788c0.507,7.304,1.369,14.575,2.641,21.795
                c0.267,1.517,0.5,2.855,1.916,3.828C366.629,310.651,369.395,312.758,372.542,315.057z M508.883,462.6
                c6.024-4.777,11.531-8.712,16.467-13.595c-3.094-4.888-2.75-9.47-0.438-14.115c0.885-1.778,1.304-3.787,2.178-5.571
                c5.268-10.748,5.715-22.71,9.094-34.344c-2.438,0.022-3.909-1.445-5.99-2.441c-3.73,5.309-8.41,9.476-13.84,12.82
                c-0.657,0.404-1.21,1.205-1.498,1.944c-1.625,4.182-5.058,6.462-8.714,8.552c-3.037,1.736-6.387,3.283-8.402,6.15
                c-2.689,3.827-5.763,7.237-9.435,10.336C493.435,443.652,504.587,450.362,508.883,462.6z M52.863,481.579
                c-0.527-3.55-2.71-6.464-2.021-10.049c0.098-0.511-0.543-1.177-0.873-1.755c-0.767-1.345-1.176-2.787-0.858-4.312
                c1.132-5.436-3.561-7.816-5.964-11.373c-1.367-2.024-3.991-1.225-6.143-2.208c0.088-3.258-0.763-6.112-4.386-7.177
                c-1.093-3.941-4.33-3.997-7.297-3.937c-1.45-1.395-1.009-3.573-2.608-4.469c-1.879-1.053-3.635-2.109-4.704-4.138
                c-0.284-0.539-1.488-0.892-2.231-0.835c-2.672,0.203-3.557-1.337-4.676-3.475c-1.432-2.736-2.43-6.422-6.705-6.351
                c-0.848,3.808-0.964,41.296-0.15,46.14c1.602,0.987,3.359,1.366,5.229,1.281c1.547-0.071,2.85,0.41,4.189,1.26
                c3.344,2.121,7.315,2.49,11.061,2.723c2.921,0.182,5.634,0.316,7.981,2.093c2.896,2.191,7.138,1.54,9.603,4.639
                c0.184,0.231,0.958,0.075,1.433-0.02C47.023,478.967,49.967,479.723,52.863,481.579z M7.876,324.106
                c1.944-0.626,3.502-1.892,5.234-2.829c5.042-2.726,12.017-0.533,14.348,4.426c1.337,2.845,3.665,4.116,6.76,3.068
                c3.914-1.326,7.811-1.324,11.814-0.888c3.636,0.396,7.283,0.689,10.931,0.951c1.694,0.122,5.061-2.25,5.209-3.858
                c0.131-1.414,0.164-3.361-1.55-3.815c-3.934-1.042-6.936-4.217-10.864-4.878c-2.951-0.497-3.764-2.045-4.293-4.52
                c-0.583-2.731-1.606-4.95-4.098-6.996c-1.948-1.599-4.337-3.465-5.56-6.077c-1.274-2.722-3.317-4.52-6.446-4.889
                c-0.619-0.073-1.678-0.42-1.722-0.749c-0.532-3.997-4.629-4.398-6.675-6.807c-1.482-1.745-3.365-2.472-5.329-1.794
                c-4.192,1.448-7.682,0.734-10.728-2.453c-0.117-0.122-0.569,0.077-1.163,0.176c0,11.748-0.003,23.546,0.006,35.345
                c0,1.012,0.099,2.042,1.132,2.589C6.451,320.938,6.63,322.817,7.876,324.106z M927.871,314.666
                c-2.359,2.993-2.187,6.589-4.766,8.731c-1.999,1.662-2.965,3.986-3.568,6.453c-0.872,3.556-1.828,7.097-2.524,10.688
                c-2.835,14.613-8.627,27.863-17.286,40.019c-4.917,6.902-9.03,14.38-13.424,21.647c-0.851,1.406-1.68,2.988-1.914,4.578
                c-1.752,11.858-3.69,23.713-3.514,35.753c0.069,4.775,1.527,9.259,3.258,13.626c6.518-3.847,9.402-9.344,9.889-16.236
                c0.012-0.166,0.031-0.332,0.062-0.496c1.946-10.282,2.75-20.829,7.103-30.554c1.163-2.6,1.885-5.289,2.03-8.179
                c0.218-4.33,2.062-7.65,6.445-9.139c0.76-0.258,1.436-0.763,2.441-1.314c0.969-8.061,2.133-16.131,2.828-24.241
                c0.322-3.751,1.084-7.22,2.936-10.455c2.406-4.204,4.179-8.699,6.117-13.119c0.881-2.007,1.207-3.891,0.577-6.227
                c-1.056-3.917-0.749-7.95,1.515-11.554C927.916,321.72,927.984,318.542,927.871,314.666z M569.056,365.394
                c-9.017-3.06-9.016-3.059-15.689,3.048c-0.737,0.675-1.549,1.279-2.223,2.011c-2.708,2.938-5.968,4.055-9.91,3.243
                c-4.72-0.973-8.818,0.338-12.619,3.237c-7.417,5.657-15.081,10.999-22.372,16.811c-4.789,3.816-9.686,6.318-15.977,4.988
                c-1.112-0.235-2.327-0.053-3.488,0.031c-4.062,0.295-7.285,2.004-9.46,5.632c3.104,8.645,6.089,17.244,10.083,25.694
                c2.628-1.286,4.619-3.008,5.81-5.074c3.073-5.332,7.89-8.443,12.982-11.349c2.929-1.672,5.601-3.565,6.839-6.964
                c0.579-1.588,1.839-2.761,3.304-3.645c4.476-2.7,7.774-6.654,11.485-10.232c6.827-6.583,14.301-12.642,25.323-10.333
                C557.037,375.818,565.812,373.53,569.056,365.394z M423.187,548.288c5.495-1.222,9.848,1.179,13.869,3.046
                c4.568,2.122,8.787,4.744,13.915,5.562c2.408,0.385,4.525,1.863,6.359,3.701c1.847,1.851,3.867,3.895,6.582,4.14
                c3.642,0.327,6.418,2.232,9.491,3.839c3.295,1.722,5.103,5.174,8.826,6.749c1.38-1.981,3.323-2.262,5.635-1.543
                c2.546,0.792,1.612,3.698,3.203,5.288c1.218-0.955,2.242-1.758,3.455-2.709c-1.665-1.532-2.075-4.248-4.582-4.405
                c-2.822-0.177-4.616-1.421-5.981-3.774c-0.464-0.8-1.402-1.4-2.239-1.896c-4.473-2.65-9.585-4.507-11.662-10.005
                c-0.241-0.638-1.251-1.026-1.96-1.437c-0.921-0.533-1.605-1.282-1.771-2.291c-0.593-3.599-3.232-4.948-6.307-5.438
                c-2.595-0.414-3.899-1.921-5.369-3.889c-1.681-2.25-2.228-5.115-4.544-7.032c-4.593-3.8-7.765-8.842-11.302-13.577
                c-2.167-2.902-5.169-5.449-8.96-5.513c-4.47-0.075-8.451-1.334-12.414-3.112c-1.911-0.857-3.896-1.926-6.061-0.56
                C412.847,525.838,418.781,536.543,423.187,548.288z M398.455,337.85c0.766-0.807,0.75-1.496,0.623-2.127
                c-1.599-7.972-0.263-15.935,0.165-23.879c0.9-16.686,0.997-16.677-4.994-31.917c-0.549-1.398-1.014-2.802-2.074-3.938
                c-5.04-5.4-8.613-11.819-12.481-18.034c-0.928-1.491-1.895-2.958-3.135-4.889c-0.877,1.804-0.581,3.117-0.511,4.351
                c0.518,9.14,1.069,18.277,1.644,27.413c0.303,4.826,0.285,9.607-0.308,14.443c-0.848,6.918-2.143,13.756-3.441,20.674
                c2.814,2.194,5.138,4.666,5.79,8.068c0.442,2.307,1.976,3.185,3.661,4.342C388.05,335.553,394.005,334.777,398.455,337.85z
                M1080.561,444.988c-4.91,0.935-6.534,2.497-7.568,7.648c-2.153,10.734-4.212,21.489-6.192,32.257
                c-0.643,3.494-1.56,6.774-3.851,9.599c-1.919,2.364-3.068,5.062-3.403,8.17c-0.484,4.505-1.911,8.8-3.903,12.841
                c-2.572,5.219-3.482,10.732-3.462,16.449c0.015,4.008-0.248,7.963-0.946,11.924c-1.277,7.246-1.559,14.522-0.533,21.869
                c0.597,4.278,0.526,8.624-0.283,12.934c-0.412,2.188-0.682,4.443-0.232,7.056c2.635-1.642,4.84-3.017,7.029-4.382
                c0.618-6.113,0.971-12.09,2.376-17.945c0.455-1.896,0.455-4.018,0.159-5.959c-0.953-6.244,0.394-12.268,1.438-18.326
                c0.273-1.585,0.566-3.291,3.604-4.39c-0.426,6.938-1.453,13.565,0.229,19.923c1.757,6.644-1.273,13.49,0.854,20.32
                c2.242-0.568,3.84-1.326,3.857-3.58c0.043-5.488,0.872-10.998-0.062-16.457c-0.543-3.173-0.558-6.27-0.084-9.43
                c0.993-6.637,0.882-13.264-0.222-19.896c-0.655-3.935-1.335-7.9-1.497-11.872c-0.166-4.075-0.337-8.096,1.967-12.052
                c1.708-2.935,1.015-6.833,1.326-10.311c0.429-4.796,0.107-9.792,2.084-14.247c1.047-2.361,1.76-4.582,1.727-7.102
                C1074.859,461.386,1077.371,453.326,1080.561,444.988z M1157.297,215.483c5.096,2.143,9.149,4.742,12.885,7.806
                c4.932,4.043,10.541,6.845,16.557,9.426c1.127-1.662,1.469-3.746,2.906-5.181c1.529-1.527,2.986-3.128,4.484-4.687
                c5.1-5.309,5.28-6.513,1.397-13.003c-2.646-4.423-5.917-8.532-8.095-13.163c-3.689-7.841-9.325-14.118-14.986-20.419
                c-3.049-3.393-6.697-5.987-11.376-6.684c-1.097-0.163-2.341-0.553-3.112,0.974c0.637,0.902,1.26,1.874,1.969,2.776
                c3.354,4.266,4.747,9.046,4.076,14.467c-0.407,3.296-0.782,6.615-0.842,9.93c-0.053,2.904-0.684,5.509-1.98,8.119
                C1159.714,208.794,1158.689,211.965,1157.297,215.483z M742.252,473.37c-4.111,2.883-7.564,5.17-10.872,7.651
                c-6.712,5.035-10.562,11.229-8.679,20.151c0.879,4.163,1.406,8.499-0.942,12.646c-3.119,5.507-6.37,10.922-9.884,16.182
                c-1.344,2.012-3.182,3.283-5.465,4.12c-5.304,1.945-9.309,6.01-14.043,8.902c-1.892,1.155-3.263,3.095-4.297,5.387
                c4.484,0.041,7.094-4.181,11.023-3.681c1.474-1.372,1.407-3.462,2.891-3.989c1.719-0.446,2.672,1.201,4.303,0.665
                c2.337-2.22,5.107-3.985,8.713-3.747c1.1,0.072,1.863-0.418,2.619-1.152c3.918-3.814,7.004-8.33,10.59-12.416
                c1.522-1.734,2.919-3.374,3.468-5.676c0.229-0.961,0.975-1.731,1.714-2.083c1.25-0.424,1.927,0.985,3.194,0.378
                c1.129-1.809,2.899-3.383,3.781-5.61c0.365-0.923,1.015-1.9,2.065-2.013c4.259-0.454,6.852-3.627,10.032-6.229
                c2.681,1.918,4.979,0.175,7.716-0.464c-2.049-6.019-2.796-12.332-8.173-16.394C747.836,482.85,744.324,479.154,742.252,473.37z
                M454.581,525.381c0.764,1.875,2.087,1.408,3.163,1.636c6.233,1.315,12.207,3.351,16.574,8.233
                c2.691,3.008,6.149,4.294,9.663,5.719c7.352,2.98,14.888,5.723,18.792,13.525c0.284,0.566,0.839,1.037,1.356,1.439
                c5.066,3.945,8.855,9.553,15.339,11.678c1.053,0.345,2.232,1.104,2.792,2.016c1.928,3.146,4.921,3.005,7.83,2.637
                c2.397-0.305,4.63-0.358,6.791,0.799c0.733,0.394,1.441,0.773,2.342,0.626c4.51-0.735,8.203,1.464,11.983,3.391
                c3.524-1.349,7.158-2.491,10.563-4.112c3.473-1.653,7.475,0.139,10.798-1.547c-0.102-1.016-0.468-1.282-0.964-1.449
                c-5.416-1.824-11.512-1.67-16.216-5.582c-0.234-0.194-0.622-0.229-0.946-0.29c-5.709-1.088-11.118-2.81-15.628-6.745
                c-1.101-0.96-2.614-1.555-4.163-1.52c-3.851,0.086-6.853-1.905-9.1-4.547c-2.239-2.631-4.967-3.617-8.033-4.552
                c-3.615-1.103-7.453-2.038-10.223-4.807c-1.849-1.849-3.464-3.561-6.389-2.896c-1.053,0.239-1.944-0.441-2.573-1.253
                c-2.653-3.427-6.521-4.349-10.441-5.304c-1.87-0.455-3.73-0.745-5.339-2.354c-3.114-3.113-6.502-6.176-11.616-4.563
                c-0.588,0.186-1.361,0.083-1.965-0.126C464.229,523.781,459.49,524.609,454.581,525.381z M55.634,385.206
                c1.224-3.664,1.366-7.373-0.424-8.338c-3.028-1.632-4.453-3.894-5.819-6.61c-0.188-0.375-0.812-0.546-1.255-0.774
                c-2.793-1.436-3.974-3.844-4.297-6.855c-0.195-1.816-0.262-4.129-1.787-5.032c-4.662-2.759-7.527-7.301-11.413-10.664
                c-3.136-2.714-7.778-4.334-12.221-5.083c-6.538-1.102-6.902-1.366-7.84-8.088c-0.272-1.954-0.041-4.255-1.779-5.544
                c-1.961-1.455-3.263-3.383-4.761-6.271c0,16.816,0,32.563,0,48.553c4.019,0.276,6.63,3.3,10.284,4.024
                c3.959,0.784,4.969,5.475,8.511,7.306c1.225-2.326,2.187-4.245,3.235-6.115c0.954-1.703,2.343-2.85,4.422-2.684
                c5.317,0.426,10.731,0.612,14.528,5.265c0.623,0.764,1.254,1.704,2.389,1.571C51.137,379.434,53.611,381.359,55.634,385.206z
                M338.6,610.533c4.399,0.932,4.376,0.954,7.429,4.01c1.282,1.284,2.303,3.176,4.155,3.467c4.763,0.751,8.155,4.234,12.366,6.1
                c3.374,1.494,6.314,4.394,9.972,5.886c0.811,0.331,1.495,1.374,1.893,2.245c2.092,4.584,6.304,6.546,10.63,9.086
                c1.283-3.291,4.442-4.637,5.821-7.644c4.456,1.572,3.627,5.967,5.532,8.573c2.077,2.844,3.816,5.939,5.978,9.097
                c1.416-2.702,0.062-5.891,2.786-7.626c0.524,0.156,1.236,0.15,1.409,0.453c3.333,5.813,6.604,11.663,9.99,17.688
                c2.625-2.785,4.951-5.701,6.957-9.167c-1.631-2.871-4.039-5.127-6.895-6.883c-9.787-6.021-17.816-14.461-27.973-20.035
                c-3.932-2.158-7.693-4.586-11.923-6.154c-2.027-0.753-4.125-1.407-5.756-2.91c-3.907-3.599-8.674-5.485-13.643-7.004
                c-3.348-1.024-6.638-2.189-9.562-4.188c-4.012-2.744-8.253-4.952-13.058-6.031c-1.421-0.319-2.934-0.963-4.023-1.9
                c-4.677-4.023-10.325-6.72-14.778-11.053c-2.396-2.33-5.349-3.946-8.313-5.481c-4.876-2.526-9.772-5.012-14.37-8.039
                c-1.692-1.113-3.458-2.01-5.888-0.657c2.633,2.457,4.645,5.119,5.087,8.767c0.201,1.66,2.16,2.287,3.529,2.346
                c5.36,0.229,8.697,3.793,12.208,7.047c1.035,0.958,1.887,1.561,3.391,1.576c4.07,0.045,7.292,2.135,10.032,4.93
                c3.241,3.304,7.199,5.568,11.251,7.549C335.668,605.963,338.152,607.294,338.6,610.533z M610.455,440.249
                c-4.64-0.175-6.816,2.558-8.136,5.464c-1.347,2.965-3.421,4.71-6.244,4.972c-3.8,0.352-5.381,4.327-8.972,4.797
                c-0.425,0.056-0.874,0.992-1.068,1.598c-1.717,5.369-5.301,9.92-6.702,15.428c-0.409,1.607-0.987,3.176-1.549,4.739
                c-0.457,1.271-1.244,2.122-2.52,2.865c-3.382,1.97-5.368,6.177-10.01,6.223c-0.235,0.002-0.352,0.966-0.709,1.241
                c-2.865,2.209-4.873,5.711-8.264,6.938c-5.352,1.936-8.385,5.594-10.421,10.453c1.943,3.127,3.683,5.928,5.405,8.699
                c4.506-0.062,8.415-1.359,12.311-2.359c0.392-4.635,4.33-7.137,5.142-11.143c3.968,1.042,6.662-0.09,8.294-3.133
                c1.171-2.185,2.662-3.268,5.146-3.054c1.237,0.106,2.646-0.338,2.834-1.553c0.577-3.713,3.829-5.785,5.156-8.995
                c0.989-2.395,2.311-4.403,5.211-4.133c0.259-0.812,0.461-1.27,0.548-1.748c0.902-4.957,2.075-9.654,6.691-12.812
                c1.273-0.871,2.38-2.665,2.483-4.699c0.065-1.286,0.364-2.85,1.163-3.757C610.391,451.564,608.286,445.566,610.455,440.249z
                M1010.066,431.23c0.003,1.934,1.052,3.45,1.938,4.966c1.715,2.94,2.638,6.126,2.975,9.456c0.47,4.629,0.781,9.277,1.082,13.921
                c1.129,17.46,1.842,34.937,2.156,52.431c0.063,3.51,0.439,6.983,1.347,10.383c2.642,9.892,3.588,19.962,3.329,30.18
                c-0.119,4.654-0.021,9.312-0.021,14.15c3.268,1.982,6.518,3.953,9.713,5.891c1.367-1.135,1.083-2.557,1.252-3.839
                c0.82-6.215-0.544-12.269-1.346-18.368c-1.082-8.242-2.73-16.472-0.57-24.799c1.027-3.959,1.121-7.805-0.947-11.606
                c-0.752-1.385-0.643-3.239-0.924-4.88c-1.375-8.026-2.184-16.186-5.484-23.764c-0.698-1.605-0.493-3.273-0.524-4.924
                c-0.165-8.83-0.249-17.661-0.463-26.489c-0.105-4.347,0.002-8.659,0.869-12.934c0.058-0.282-0.115-0.61-0.204-1.027
                c-1.127-0.881-2.96-0.297-3.965-1.62C1018.865,432.958,1017.014,431.642,1010.066,431.23z M448.875,320.202
                c0.19,0.427,0.219,0.641,0.311,0.672c0.453,0.155,0.944,0.422,1.384,0.363c10.864-1.455,21.862-2.067,32.335-5.827
                c6.7-2.405,13.589-2.754,20.521-0.711c4.093,1.207,6.527,0.294,8.676-3.528c1.01-1.796,2.395-3.291,4.224-2.878
                c4.039,0.911,8.227,0.455,12.214,1.778c4.764,1.582,9.769,1.162,14.693,1.134c1.933-0.011,3.469-1.106,4.554-2.715
                c1.126-1.669,2.774-2.493,4.673-2.452c4.723,0.103,9.495-2.028,14.197,0.525c1.447,0.786,3.315,0.318,4.909-0.174
                c5.272-1.63,10.721-2.375,16.127-3.317c1.875-0.326,3.551-0.858,4.836-2.309c2.281-2.576,5.042-2.938,8.16-1.834
                c0.934,0.331,1.92,0.781,2.872,0.754c6.479-0.18,12.941,0.366,19.407,0.4c4.846,0.026,9.655-1.267,14.017-3.186
                c3.527-1.551,6.72-2.398,10.536-1.664c3.171,0.611,6.411,0.267,8.497-2.775c2.077-3.027,4.932-3.239,7.953-2.156
                c3.399,1.219,6.524,0.574,9.711-0.631c10.297-3.895,21.085-4.246,31.887-4.098c3.994,0.055,7.638-0.225,10.561-3.273
                c2.613-2.726,5.729-3.168,9.163-1.915c1.741,0.635,3.491,1.18,5.36,0.842c6.314-1.141,13.09-0.595,18.511-5.062
                c0.482-0.397,1.229-0.49,1.866-0.683c10.033-3.031,20.223-5.463,30.412-7.898c2.193-0.524,4.254-0.688,6.31,0.348
                c3.589,1.808,7.169,1.388,10.563-0.381c7.122-3.712,14.695-3.525,22.314-2.724c4.012,0.422,7.499,0.188,10.361-3.258
                c3.508-4.222,5.141-4.437,9.741-1.414c2.981,1.958,6,2.363,8.938,0.678c3.783-2.17,7.995-3.288,11.805-5.242
                c4.163-2.136,8.278-3.205,12.944-2.726c4.039,0.415,7.611-0.749,9.945-4.408c1.371-2.151,3.518-2.981,5.849-3.443
                c5.388-1.068,10.816-1.947,16.173-3.148c7.6-1.703,15.56-1.953,22.653-5.764c1.718-0.923,3.755-1.37,5.796-1.362
                c4.779,0.019,9.172-1.264,13.318-3.652c2.99-1.723,6.094-2.264,9.549-0.862c3.48,1.412,15.739-0.277,18.942-2.175
                c1.179-0.699,2.091-1.646,2.871-2.734c0.997-1.39,2.196-2.542,3.863-3.021c2.586-0.742,4.896-2.072,7.302-3.209
                c2.275-1.076,4.718-1.755,7.233-1.759c9.034-0.014,17.636-3.065,26.588-3.697c1.937-0.137,3.734-1.3,5.503-2.269
                c7.135-3.905,14.201-7.942,22.008-10.465c2.76-0.893,5.392-1.515,8.279-0.611c2.978,0.932,5.671-0.092,7.81-2.104
                c1.296-1.218,2.095-2.969,3.101-4.491c1.088-1.648,3.776-1.224,4.766-3.836c-3.882,0.111-6.221,1.822-7.729,4.873
                c-1.543,3.124-4.201,4.482-7.405,3.63c-3.825-1.019-7.149-0.05-10.679,1.149c-7.605,2.585-14.514,6.552-21.507,10.365
                c-1.769,0.964-3.553,1.979-5.569,2.089c-9.135,0.496-17.898,3.695-27.113,3.646c-2.342-0.013-4.543,0.94-6.657,1.977
                c-1.642,0.805-3.265,1.706-4.993,2.26c-2.628,0.843-4.892,2.084-6.432,4.438c-1.175,1.796-2.94,2.732-4.951,3.084
                c-3.762,0.66-7.543,1.241-11.336,1.673c-1.1,0.125-2.391-0.126-3.385-0.624c-3.689-1.848-7.125-0.964-10.516,0.709
                c-2.236,1.104-4.342,2.732-6.865,2.875c-7.248,0.411-13.957,2.747-20.648,5.259c-1.076,0.404-2.271,0.497-3.415,0.715
                c-8.989,1.72-17.983,3.417-26.969,5.164c-2.482,0.482-4.991,1.179-6.413,3.474c-2.348,3.792-5.795,4.693-9.902,4.271
                c-2.843-0.292-5.815-0.501-8.334,0.924c-4.798,2.713-10.369,3.576-15.011,6.668c-2.62,1.745-5.383,1.978-8.391,0.038
                c-5.592-3.606-7.775-3.198-12.403,1.975c-1.885,2.105-4.191,3.174-6.652,2.472c-4.512-1.288-8.906-0.223-13.354-0.175
                c-4.078,0.044-7.903,0.763-11.499,2.804c-3.044,1.728-6.246,2.159-9.542,0.457c-2.167-1.118-4.434-1.097-6.806-0.499
                c-7.42,1.874-14.963,3.321-22.271,5.548c-4.867,1.483-9.741,2.939-14.449,5.266c-5.731,2.833-12.43,3.798-18.936,2.092
                c-4.24-1.112-7.919-0.781-11.037,2.429c-2.009,2.068-4.584,3.268-7.201,2.721c-5.223-1.091-10.326,0.061-15.388,0.477
                c-6.726,0.553-13.63,0.9-19.94,4.016c-2.612,1.29-5.395,1.148-8.251,0.177c-2.964-1.008-6.31-0.994-8.3,1.653
                c-2.663,3.539-5.771,3.859-9.678,3.101c-3.162-0.614-6.294-0.128-9.086,1.788c-0.799,0.548-1.785,0.858-2.721,1.166
                c-7.748,2.544-15.731,2.221-23.689,1.742c-3.934-0.237-7.907,0.211-11.816-1.179c-1.961-0.698-4.603-0.252-6.072,1.447
                c-2.438,2.818-5.639,3.252-8.909,3.833c-3.93,0.697-7.908,1.165-11.723,2.423c-1.604,0.529-3.457,1.105-4.894,0.37
                c-4.884-2.5-9.836-0.567-14.741-0.577c-2.413-0.005-4.55,0.977-6.004,3.062c-1.649,2.364-4.094,2.812-6.655,2.472
                c-7.008-0.933-13.996-2.011-20.99-3.044c-3.573-0.528-6.501,0.023-8.372,3.701c-1.556,3.06-3.295,3.567-6.573,2.518
                c-6.391-2.045-12.906-1.812-19.089,0.159c-6.701,2.136-13.422,3.81-20.386,4.641c-3.801,0.453-7.588,1.021-11.381,1.536
                C451.525,319.273,450.039,319.256,448.875,320.202z M94.285,332.841c0.969-0.902,0.628-1.953,0.642-2.884
                c0.372-25.981-0.612-51.945-1.076-77.916c-0.021-1.16-0.229-2.316-0.337-3.476c-1.482-15.906-2.948-31.814-4.447-47.719
                c-1.118-11.855,0.726-23.592,1.468-35.364c0.817-12.975,1.469-25.921,0.134-38.898c-0.69-6.703-2.698-8.712-9.324-8.901
                c-2.388-1.855-3.534-5.177-7.264-6.228c0.518,7.006,1.022,13.625,1.492,20.245c0.353,4.975,0.542,9.947,2.331,14.719
                c4.395,11.729,7.416,23.657,7.772,36.373c0.555,19.788,3.498,39.459,3.389,59.306c-0.028,5.159-0.101,10.344,0.333,15.477
                c1.504,17.786,1.583,35.578,0.923,53.398c-0.24,6.47-0.039,12.957-0.039,19.578C91.44,331.427,92.62,332.609,94.285,332.841z
                M1114.547,204.768c-0.669,0.914-1.155,1.568-1.632,2.229c-2.296,3.186-1.888,6.994-2.372,10.579
                c-1.921,14.244-2.381,28.47-0.076,42.759c0.65,4.037,1.617,7.758,3.75,11.2c2.246,3.627,3.215,7.503,3.166,11.841
                c-0.056,4.883,0.55,9.774,0.875,14.76c2.995-0.369,4.877-1.586,6.412-3.451c1.48-1.799,2.889-3.675,4.52-5.329
                c2.16-2.192,2.788-4.523,2.373-7.661c-0.828-6.26-1.65-12.696-0.375-18.887c0.875-4.248,0.15-7.84-1.29-11.598
                c-1.253-3.27-2.643-6.49-4.529-9.432c-2.675-4.171-3.78-8.752-4.49-13.59C1119.731,220.373,1119.469,212.283,1114.547,204.768z
                M164.716,534.855c0.169-0.344,0.339-0.687,0.508-1.029c-2.662-1.361-3.307-4.128-4.839-6.034
                c-1.908-2.372-3.009-4.499-3.037-7.474c-0.014-1.545-1.276-2.617-2.494-3.574c-1.566-1.231-3.112-2.504-4.55-3.881
                c-1.638-1.568-3.534-2.529-5.759-2.697c-2.558-0.194-4.897-0.914-7.002-2.373c-1.492-1.033-3.123-0.998-4.8-0.68
                c-2.162,0.411-3.945,1.739-5.959,2.475c-2.34,0.854-3.433-2.562-5.908-1.918c-1.248,0.323-2.655,0.387-3.751-0.713
                c-2.089-2.097-4.347-2.088-6.772-0.57c-2.275,1.424-5.327-1.787-7.362,1.188c-0.172,0.251-0.202,0.6-0.288,0.871
                c0.977,2.439,2.468,4.56,3.415,7.051c0.872,2.295,3.353,4.275,5.121,6.289c2.443,2.783,4.875,5.161,4.646,9.277
                c9.029-1.014,16.949,1.041,24.351,5.314c1.729,0.999,3.478,1.976,5.144,3.074c1.466,0.966,2.705,1.324,4.571,0.576
                C154.786,538.092,159.783,536.553,164.716,534.855z M785.957,373.457c-3.191-0.928-3.916-4.266-6.573-4.848
                c-0.757,0.948-0.404,1.74,0.005,2.452c2.322,4.04,4.34,8.265,7.243,11.953c2.104,2.674,3.459,5.543,3.544,9.112
                c0.066,2.784,0.498,5.71,2.25,8.073c2.214,2.987,2.903,6.318,2.588,9.906c-0.42,4.761-0.092,9.302,3.07,13.234
                c0.89,1.106,1.223,2.374,0.209,3.658c-1.986,2.515-0.916,4.861,0.238,7.534c5.125-2.998,10.842-2.512,15.875-3.879
                c3.707-7.049,0.377-13.154-1.165-19.232c-2.231-0.819-4.075-0.387-5.893-0.457c-1.148-0.044-2.538,0.185-3.177-0.991
                c-2.029-3.733-5.374-6.685-5.635-11.477c-0.262-4.796-0.085-9.774-1.67-14.354c-2.201-6.363-1.895-12.784-1.434-19.269
                c0.119-1.67,0.579-3.455-0.354-4.897c-2.258-3.492-1.783-7.351-1.848-11.149c-0.09-5.332-0.114-10.664-0.195-15.996
                c-0.053-3.377-0.584-4.081-3.764-4.748c-2.438-0.512-4.902-0.902-7.361-1.302c-1.17-0.19-2.327-0.164-3.82,1.327
                c0.779,7.025,0.863,14.598-0.154,22.697c2.455-2.889,1.348-6.775,5.035-9.293c1.328,5.748,2.951,10.925,1.479,16.327
                c-0.742,2.725-0.699,5.187,0.041,7.815C785.152,367.996,786.447,370.242,785.957,373.457z M192.571,582.343
                c0.61-1.776,0.716-4.017,1.635-4.424c3.559-1.577,2.952-5.229,4.68-7.573c-1.326-2.33-2.538-4.46-3.634-6.386
                c0.323-1.919,1.58-3.415,1.102-5.097c-1.015-3.572,0.572-7.734-2.541-10.855c-0.495-0.497-0.487-1.552-0.599-2.363
                c-0.641-4.641-2.657-7.164-6.95-7.654c-4.242-0.486-8.642,0.2-12.583-2.119c-0.376-0.221-1.049-0.226-1.464-0.049
                c-2.878,1.224-5.979,1.158-8.938,1.73c-4.663,0.902-9.08,3.076-14.033,4.869c5.796,7.467,10.54,15.074,15.709,22.377
                c1.998,2.821,5.236,4.765,8.164,7.332c0.556-1.349,0.883-2.255,1.298-3.119c0.631-1.314,0.957-3.076,2.813-3.123
                c2.091-0.053,2.575,1.758,3.219,3.289c0.684,1.627,0.49,3.719,2.688,4.794c0.434-0.099,1.084-0.175,1.684-0.393
                c4.44-1.61,5.415-1.042,6.198,3.739C191.285,578.941,191.157,580.661,192.571,582.343z M443.944,434.283
                c0.249-3.08,0.467-5.891,0.705-8.7c1.196-14.124,0.64-27.967-6.439-40.787c-2.06-3.73-1.663-7.829-1.198-11.744
                c0.755-6.36-0.705-11.87-4.951-16.627c-1.671-1.872-2.081-3.998-2.005-6.453c0.169-5.495,0.129-10.997,0.141-16.496
                c0.004-1.997,0.207-4.095-0.698-5.912c-1.955-3.923-2.895-8.152-3.915-12.343c-0.726-2.985-2.199-5.084-5.364-6.153
                c-0.247,2.031,0.862,3.589,1.419,5.258c1.527,4.578,3.285,9.084,3.456,14.002c0.872,25.152,1.449,50.308,0.187,75.462
                c-0.207,4.116,0.302,7.85,2.45,11.462c2.038,3.426,3.973,6.986,5.364,10.708C434.944,430.899,438.475,433.271,443.944,434.283z
                M350.164,399.721c0.428,5.434,1.265,10.182,1.067,14.886c-0.333,7.936,4.123,12.193,10.432,15.404
                c1.186,0.604,2.333,1.299,3.559,1.805c4.513,1.862,7.183,4.987,7.946,9.982c1.168,7.642,4.032,14.602,8.502,21.043
                c4.356,6.274,8.034,13.02,12.045,19.536c0.537,0.872,0.634,2.277,2.664,2.202c-1.418-14.635-2.354-29.257-4.478-43.705
                c-0.817-5.563-4.385-10.752-8.842-14.688c-2.129-1.88-4.35-3.683-5.999-5.96c-3.072-4.243-7.153-6.804-12.076-8.304
                c-3.75-1.143-6.828-3.232-9.203-6.485C354.374,403.51,352.939,401.436,350.164,399.721z M292.94,506.693
                c-0.121-0.671-0.077-1.173-0.247-1.26c-6.455-3.309-11.495-9.059-18.81-10.891c-1.481-0.371-2.52-1.602-3.809-2.317
                c-5.03-2.796-8.259-8.319-14.379-9.459c-0.435-0.081-1.031-0.504-1.15-0.892c-1.152-3.737-4.74-4.299-7.535-5.886
                c-2.882-1.637-5.597-3.763-8.855-4.446c-1.992-0.416-3.051-1.426-4.389-2.768c-3.234-3.243-4.843-8.146-9.969-9.524
                c-3.675-0.989-7.063-2.628-9.474-5.874c-0.775-1.044-2.262-1.29-3.579-1.611c-7.451-1.82-14.614-4.303-20.718-9.183
                c-0.77-0.615-1.631-1.165-2.9-1.106c-1.245,4.989,3.959,8.354,2.772,13.146c4.995,1.233,8.64,3.358,9.816,7.808
                c2.339,0.663,4.429,0.069,6.097,1.678c5.99,5.774,13.599,8.792,21.079,11.959c4.922,2.084,9.836,4.13,13.889,7.728
                c5.273,4.682,11.299,8.186,17.48,11.494c5.551,2.973,11.26,5.668,15.129,11.109c1.646,2.314,4.195,3.572,7.438,1.818
                c2.174-1.176,4.794-1.265,7.323-1.257C289.739,506.965,291.328,506.789,292.94,506.693z M228.354,545.322
                c0.792-1.77-0.17-2.523-1.073-3.254c-4.049-3.283-7.873-6.937-12.268-9.666c-2.588-1.607-4.809-4.166-8.101-4.543
                c-5.724-0.658-8.17-5.803-12.157-8.832c-0.32-0.244-0.587-1.048-0.427-1.357c1.414-2.732-1.12-5.151-0.578-7.725
                c0.654-3.105-1.486-4.472-3.333-6.131c-1.831-1.646-0.674-4.971-3.632-6.15c-2.956-1.18-5.223-3.447-7.611-5.54
                c-3.892-3.413-9.132-3.781-13.441-1.151c0.921,2.249,0.756,4.757,3.812,6.277c2.094,1.042,3.252,3.781,5.733,5.004
                c1.438,0.708,1.897,2.733,1.29,4.47c-0.476,1.357,0.06,2.546,0.137,3.815c0.274,4.525,1.504,9.264-1.933,13.307
                c-1.241,1.459-1.082,2.951,0.162,4.497c1.961,2.438,3.759,5.011,5.52,7.383c7.417,0.205,7.415,0.208,13.9,5.508
                c0.374,0.306,0.838,0.733,1.25,0.722c2.884-0.082,5.426,1.704,8.264,1.558c2.021-0.104,3.527,0.628,4.925,1.92
                c0.847,0.782,1.676,1.646,3.376,1.61c2.081-1.626,4.555-3.363,7.521-3.923C222.773,542.539,225.013,545.707,228.354,545.322z
                M1266.91,328.352c-3.201,2.125-4.831,4.332-5.436,7.503c-1.277,6.703-2.812,13.354-4.75,19.906
                c-2.508,8.483-4.555,17.031-4.47,25.983c0.03,3.146-0.521,6.302-0.859,9.448c-1.034,9.599-3.327,19.137-3.071,28.784
                c0.549,20.624-2.062,40.863-5.971,61.012c-2.597,13.389-4.117,26.901-4.888,40.572c1.268-0.203,1.392-1.029,1.525-1.841
                c1.024-6.241,2.177-12.466,3.04-18.729c1.228-8.915,2.62-17.748,5.912-26.219c6.407-16.487,8.408-33.903,9.841-51.321
                c1.228-14.935,1.548-29.946,2.167-44.927c0.141-3.383,0.68-6.611,2.07-9.691c1.598-3.535,2.628-7.292,2.669-11.109
                C1264.794,348.095,1265.125,338.497,1266.91,328.352z M476.636,72.105c-1.728-0.127-3.217-0.235-4.706-0.347
                c-8.866-0.664-17.604-2.011-25.434-6.519c-5.535-3.187-11.438-5.296-17.488-7.134c-1.274-0.387-2.558-0.778-3.773-1.311
                c-6.61-2.899-13.504-4.736-20.677-5.434c-6.635-0.646-13.062-2.759-19.757-2.854c-6.507-0.093-12.993-0.369-19.419-1.437
                c-6.076-1.009-12.105-2.38-18.212-3.099c-28.284-3.328-56.397-7.917-84.654-11.411c-13.052-1.614-25.625-5.992-38.89-6.31
                c-3.808-0.091-7.612-0.834-11.392-1.44c-2.787-0.447-5.827-0.763-7.882-2.812c-2.097-2.091-4.574-2.552-7.208-2.937
                c-2.966-0.433-5.953-0.747-8.904-1.262c-6.557-1.144-13.173-1.997-19.596-3.828c-3.929-1.12-7.651-2.543-9.268-6.803
                c-0.57-1.502-1.927-1.767-3.205-2.045c-11.715-2.553-23.498-4.749-35.374-6.417c-1.776-0.249-3.568-0.395-5.22,0.492
                c2.474,0.831,4.911,1.27,7.354,1.663c10.356,1.669,20.702,3.4,30.937,5.723c1.58,0.358,3.325,0.328,4.192,2.211
                c1.933,4.197,5.776,5.844,9.874,6.941c7.249,1.941,14.658,3.072,22.067,4.215c5.185,0.8,10.667,0.634,14.947,4.651
                c0.988,0.928,2.743,1.182,4.199,1.441c4.095,0.728,8.205,1.416,12.335,1.896c5.126,0.597,10.451,0.3,15.383,1.574
                c11.339,2.929,22.868,4.419,34.427,5.919c13.044,1.693,26.009,3.938,39.054,5.66c15.511,2.048,31.092,3.627,46.487,6.54
                c7.858,1.487,15.784,2.501,23.804,2.733c12.362,0.357,24.489,2.533,36.432,5.534c5.946,1.495,11.626,4.025,17.478,5.934
                c2.385,0.778,4.673,1.631,6.864,2.896c8.026,4.631,16.53,7.83,25.908,8.363c2.637,0.149,5.254,0.644,7.974,0.991
                c1.62,3.708,4.046,6.813,7.957,7.803c6.498,1.645,12.324,5.048,18.75,6.754c2.657,0.705,4.311,1.868,5.354,4.496
                c2.145,5.399,5.979,9.562,10.571,13.046c1.319,1.001,2.647,1.989,4.004,3.007c0.065,3.593,0.331,7.116,1.979,10.278
                c1.155,2.216,1.119,4.36,0.785,6.745c-0.401,2.867-1.145,5.442-2.964,7.769c-1.435,1.834-2.348,4.013-2.99,6.283
                c-0.362,1.282-0.86,2.478-1.931,3.418c-4.454,3.911-8.02,8.802-13.741,11.304c-2.847,1.245-5.342,3.381-7.839,5.316
                c-2.256,1.748-4.622,3.275-7.175,4.505c-12.914,6.221-25.432,13.175-37.851,20.315c-1.763,1.013-3.646,1.507-5.632,1.907
                c-8.555,1.727-17.021,4.085-23.778,9.805c-4.868,4.12-10.218,7.354-15.455,10.861c-6.803,4.556-13.617,8.989-21.437,11.651
                c-0.729,0.248-2.2,0.367-1.554,1.925c0.307,0.089,0.638,0.311,0.885,0.239c12.424-3.57,21.967-12.374,33.119-18.261
                c0.141-0.074,0.248-0.221,0.36-0.344c7.561-8.309,17.528-11.822,28.125-13.998c2.825-0.58,5.363-1.626,7.854-3.048
                c10.705-6.107,21.429-12.187,32.537-17.544c4.364-2.105,8.564-4.412,12.376-7.452c3.387-2.702,7.032-5.075,10.971-6.918
                c1.698-0.794,2.839-2.07,4.15-3.353c4.433-4.337,8.197-9.007,10.206-14.979c0.409-1.216,1.64-2.138,2.151-3.341
                c1.564-3.682,2.676-7.736,1.311-11.471c-1.512-4.136-2.683-8.186-2.479-12.751c-7.268-4.494-13.255-10.118-16.049-18.468
                c-0.28-0.838-0.836-1.479-1.71-1.655c-6.251-1.263-11.692-4.821-17.831-6.335C482.857,80.248,478.267,78.016,476.636,72.105z
                M222.399,356.292c4.015-6.366,4.604-12.692,4.654-18.99c0.155-19.994,0.058-39.989,0.058-59.984
                c0-10.997,0.023-21.994-0.01-32.991c-0.015-5.055-3.359-9.985-7.898-11.83c0,3.474-0.278,6.971,0.056,10.409
                c0.775,7.985,1.171,15.936,0.096,23.927c-0.402,2.989,0.021,5.979,0.757,8.916c0.849,3.39,1.692,6.784,2.428,10.2
                c1.332,6.184,2.699,12.349,2.245,18.776c-0.505,7.145-0.72,14.31-1.145,21.46c-0.093,1.575,0.403,3.363-1.216,4.744
                c-2.142-1.368-1.859-3.747-2.361-5.726c-2.014-7.949-1.135-16.334-3.205-24.214c-3.648-13.885-6.683-27.822-7.093-42.229
                c-0.02-0.713-0.347-1.253-1.151-1.333c-2.204,8.033-2.534,11.923-1.06,20.155c3.302,18.44,5.181,36.938,2.826,55.657
                c-0.151,1.197-0.106,2.283,0.613,3.328C215.139,342.59,218.786,348.914,222.399,356.292z M884.506,470.539
                c0.395,0.171,0.791,0.342,1.186,0.512c2.07-1.564,5.457-0.373,6.754-3.414c1.928-4.52,5.123-8.148,8.061-11.96
                c1.863-2.418,2.932-5.063,3.604-8.083c2.173-9.741,5.214-19.248,8.679-28.605c1.639-4.424,3.193-5.06,8.041-4.476
                c3.902,0.471,7.896,0.141,11.814,1.682c0.119-1.458-0.336-2.399-0.955-3.097c-2.713-3.05-3.211-6.835-3.736-10.628
                c-0.25-1.811-0.721-3.629-0.68-5.435c0.067-2.925-1.721-4.092-4.01-5.01c-4.547-1.823-8.72-0.694-12.977,1.229
                c-3.457,1.561-5.159,3.975-5.266,7.667c-0.057,2-0.197,3.98-1.023,5.855c-3.926,8.906-5.941,18.286-7.158,27.905
                c-1.111,8.783-2.508,17.536-10.834,22.963c-0.137,0.089-0.229,0.241-0.354,0.351c-0.802,0.699-1.159,1.573-1.153,2.642
                C884.518,463.938,884.506,467.238,884.506,470.539z M580.536,460.317c-3.843-0.746-6.803,0.437-9.834,1.25
                c-3.691,0.989-6.769,4.4-10.501,4.05c-3.668-0.345-7.199-2.395-10.735-3.83c-4.196-1.704-7.779-0.865-10.956,2.242
                c-4.159,4.068-8.355,8.098-12.485,12.195c-0.748,0.742-1.993,1.291-1.652,3.109c5.526,0.863,8.535,4.995,11.295,9.438
                c1.754,2.823,3.544,5.624,5.393,8.386c0.76,1.135,1.257,2.562,2.956,3.487c1.168-4.669,6.064-5.742,7.932-9.556
                c0.322-0.658,1.41-1.063,2.23-0.748c1.938,0.744,2.905-0.634,3.605-1.818c1.212-2.051,3.785-2.775,4.637-5.085
                c4.493-0.845,7.263-3.979,10.264-7.089c0.971-1.007,2.038-1.761,2.622-3.152C577.026,469.098,577.696,464.596,580.536,460.317z
                M1200.184,543.617c-2.092-2.035-1.04-5.213-2.626-7.287c-1.669-2.182-2.666-4.584-3.603-7.059
                c-0.356-0.941-0.51-1.881-1.545-2.396c-2.107-1.05-2.896-3.075-3.736-5.104c-1.146-2.764-2.279-5.554-4.293-7.855
                c-0.908-1.037-1.32-2.281-1.474-3.644c-0.437-3.899-1.722-7.421-4.871-10.029c0.517-2.728,1.972-5.361,0.172-7.862
                c-3.344-4.643-3.983-10.16-5.179-15.499c-0.342-1.526-0.386-3.138-1.705-4.644c-0.902,8.348-2.053,16.289,0.435,24.176
                c0.294,0.932,0.246,1.98,0.303,2.977c0.661,11.637,1.351,23.271,1.938,34.911c0.151,3.004,0.252,6.017,0.988,8.924
                c1.043,4.117,0.963,8.247,0.619,12.411c-0.219,2.656-0.463,5.312-0.609,7.973c-0.049,0.898-0.432,2.104,1.639,2.377
                c0.77-2.01,2.37-3.208,4.722-4.127c2.421-0.945,4.962-2.586,6.865-4.374c2.166-2.034,3.829-4.382,6.833-5.513
                c3.153-1.188,1.783-5.617,4.563-7.297C1199.742,544.602,1199.78,544.39,1200.184,543.617z M343.341,312.128
                c2.135-4.227,2.696-8.058,2.54-12.144c-0.337-8.814-0.391-17.638-0.607-26.457c-0.033-1.321-0.042-2.753-0.542-3.929
                c-1.092-2.564-0.992-5.195-0.992-7.853c-0.001-34.294-0.042-68.587,0.049-102.881c0.009-3.353-0.528-6.511-2.956-8.622
                c-1.908-1.659-2.542-3.633-2.981-5.696c-5.64-1.686-10.884-0.032-16.121,0.454c-2.338,0.216-2.985,2.232-2.223,4.411
                c1.706,1.052,3.403,0.575,5.03,0.57c4.711-0.014,6.208,0.954,7.926,5.104c0.542,1.309,0.518,2.643,0.287,3.926
                c-0.909,5.061,0.542,9.48,3.314,13.645c1.189,1.785,2.094,3.636,2.171,5.972c0.597,18.133,1.338,36.261,2.004,54.391
                c0.033,0.904,0.36,1.997-0.768,2.606c-1.918,0.222-1.782-2.888-3.855-2.392c-1.622,6.544-1.652,6.55-0.487,12.158
                c1.658,7.979,2.948,16.029,4.515,24.019c2.024,10.329,0.74,20.52-0.614,30.672C338.376,304.99,340.036,308.369,343.341,312.128z
                M84.388,492.395c2.832,4.056,2.789,4.239,5.824,4.955c2.635,0.621,4.221,2.109,4.534,4.637c0.253,2.053,1.313,2.793,3.111,3.197
                c0.942,0.211,1.799,0.807,2.849,1.301c3.763-3.358,8.757-2.346,13.044-2.633c4.179-0.278,8.551,2.338,12.971,3.725
                c1.482-0.764,3.116-1.545,4.912-2.012c1.988-0.518,4.011-0.992,5.805,0.166c2.748,1.772,5.796,2.43,8.917,2.948
                c1.885,0.313,3.373,1.327,4.804,2.506c1.123,0.925,1.85,2.382,3.593,2.683c1.18-1.616,1.409-3.319,0.289-5.041
                c-0.656-1.008-1.413-1.996-0.833-3.195c0.918-1.898-0.419-2.963-1.146-4.441c-1.564-3.178-4.409-5.676-4.732-9.557
                c-0.187-2.243-1.756-3.803-3.943-4.758c-6.208-2.711-12.708-1.684-18.932-0.823c-2.646,0.366-5.844,0.964-8.358,2.518
                c-4.666,2.882-9.187,3.374-14.015,0.319c-3.088-1.953-7.288-1.409-9.946,1.025c-1.292,1.184-2.483,1.916-4.403,1.674
                C87.36,491.415,85.883,492.087,84.388,492.395z M816.562,430.821c4.413-0.349,8.286,1.1,11.521-2.081
                c3.537-3.478,3.89-7.369,2.344-11.904c-3.846-11.278-6.023-22.9-5.559-34.834c0.455-11.741-3.861-22.218-8.212-32.632
                c-1.787-4.278-4.476-8.433-9.323-11.143c-0.602,4.979,0.16,9.361,1.178,13.657c1.233,5.206,2.279,10.373,1.972,15.782
                c-0.14,2.469-0.163,5.163,0.663,7.425c1.96,5.36,1.522,10.787,1.484,16.265c-0.014,1.983-0.281,3.683-1.857,4.886
                c-1.74,1.329-1.793,3.064-1.527,4.94c0.349,2.456,0.729,4.906,1.076,7.234c4.68,1.524,4.596,1.546,5.806,6.006
                C817.564,419.714,819.105,424.966,816.562,430.821z M287.459,205.54c-1.304,5.653-1.821,10.881-1.417,16.252
                c1.936,25.754,2.744,51.516,1.152,77.341c-0.675,10.945-1.163,21.948-0.095,32.924c0.25,2.572,0.048,5.229,1.154,8.029
                c6.027-3.422,10.917-8.039,17.09-10.287c0.03-2.164-0.403-3.819-1.478-5.086c-3.537-4.167-4.391-9.15-4.894-14.35
                c-0.303-3.136-1.106-6.221-1.608-9.342c-0.528-3.278-1.54-6.484-1.456-9.856c0.188-7.488,0.192-14.986,0.599-22.463
                c0.22-4.056-0.163-7.932-1.184-11.835c-0.88-3.366-2.373-6.627-2.281-10.192c0.266-10.377-0.983-20.594-3.307-30.676
                C288.967,212.667,289.412,209.044,287.459,205.54z M1045.89,276.182c-2.702,0.352-4.335,0.56-5.968,0.778
                c-4.816,0.643-9.52,1.474-13.318,4.982c-1.732,1.6-4.117,2.187-6.367,2.772c-6.609,1.719-13.233,3.364-20.154,3.217
                c-3.004-0.063-6.186-0.161-8.871,1.13c-6.793,3.263-14.19,4.661-21.156,7.345c-0.586,0.226-1.123,0.578-1.74,0.902
                c2.614,3.219,5.435,5.438,9.868,4.65c1.571-0.279,3.219,0.281,4.72-0.893c1.162-0.91,2.586-0.576,3.754,0.21
                c4.864,3.273,10.221,4.951,16.064,5.19c3.161,0.13,6.336,0.219,9.473,0.594c3.182,0.38,5.545,2.73,8.471,4.334
                c2.256-5.557,5.986-9.928,8.255-15.129c1.601-3.669,2.944-7.64,8.181-7.279c0.395,0.027,0.799-0.568,1.244-0.774
                C1043.255,285.947,1043.386,280.828,1045.89,276.182z M191.769,160.938c0.264-0.994,0.472-1.461,0.501-1.939
                c0.617-9.97,0.577-19.978,1.857-29.91c0.329-2.549-0.131-5.131-1.882-7.051c-1.689-1.852-1.711-3.788-1.417-6.005
                c0.965-7.257,1.833-14.528,2.787-21.787c0.245-1.859,0.729-4.279-1.222-4.897c-3.339-1.059-2.956-3.531-3.274-5.925
                c-0.236-1.779,0.289-3.685-0.926-5.462c-5.17-0.397-10.39-1.755-15.821-0.275c-0.983,5.393-1.378,10.418,0.932,15.652
                c3.365,7.623,5.481,15.682,6.544,24.005c1.625,12.71,3.482,25.39,5.07,38.104c0.312,2.494,1.107,4.056,3.654,4.537
                C189.533,160.165,190.457,160.539,191.769,160.938z M849.629,605.765c0.387-1.173,0.703-2.099,0.998-3.032
                c0.678-2.146,0.645-5.242,3.72-5.093c2.607,0.127,2.636,3.099,3.347,5.04c0.951,2.6,0.89,5.568,2.763,7.856
                c1.956-1.005,1.591-2.904,1.995-4.444c0.587-2.23,0.229-4.758,1.783-6.672c1.258-1.547,1.386-3.3,1.586-5.13
                c2.096-19.171,4.635-38.309,3.75-57.677c-0.098-2.143,0.242-4.26,0.983-6.363c1.077-3.059-1.112-5.299-4.23-4.256
                c-3.068,1.025-5.807,2.849-6.343,6.385c-0.971,6.408-2.613,12.653-4.086,18.948c-1.135,4.848-1.523,9.866-2.568,14.739
                c-2.447,11.41-4.107,22.918-4.879,34.556C848.341,602.216,847.576,603.936,849.629,605.765z M923.52,416.992
                c-0.985,3.544-0.559,7.239-1.025,10.836c-0.164,1.255,0.609,2.205,1.397,3.087c3.197,3.582,4.878,7.866,5.946,12.478
                c1.651,7.129,3.357,14.224,8.482,19.925c1.184,1.316,1.961,3.122,2.541,4.833c1.096,3.222,2.87,5.962,5.021,8.549
                c2.574,3.096,5.016,6.419,5.265,10.562c0.228,3.797,2.069,5.994,5.274,7.633c3.904,1.995,7.07,5.283,11.819,7.127
                c-0.835-5.836-2.447-10.865-4.2-15.861c-1.291-3.678-3.289-6.791-6.271-9.432c-3.104-2.75-5.661-6.115-8.732-8.909
                c-3.395-3.089-4.534-6.603-3.796-11.111c1.084-6.621,0.436-13.27-0.155-19.921c-0.85-9.554-6.33-15.403-14.66-19.13
                C928.27,416.692,926.037,416.16,923.52,416.992z M290.048,581.268c0.318-2.387-0.629-3.917-2.1-5.477
                c-2.75-2.919-4.552-6.902-9.168-7.562c-0.265-0.037-0.55-0.401-0.703-0.682c-2.865-5.229-8.857-5.141-13.329-7.566
                c-2.4-1.302-4.827-2.057-5.893-4.766c-1.162-2.951-3.339-4.338-6.43-4.766c-2.463-0.341-4.969-0.863-6.089-3.635
                c-0.252-0.625-0.96-1.182-1.587-1.026c-4.329,1.071-8.697,0.411-13.048,0.439c-0.147,0.001-0.295,0.146-0.439,0.228
                c-2.4,1.365-4.809,1.113-7.106-0.16c-3.274-1.814-6.188-1.622-8.991,1.443c4.481,1.428,6.02,6.329,10.229,8.166
                c1.088,0.475,1.345,2.179,1.394,3.578c0.146,4.142,2.059,7.15,6.217,9.027c5.615-5.646,11.241-3.4,16.841,0.518
                c1.057,0.738,1.881,2.199,3.354,1.922c2.736-0.514,4.751,1.114,7.064,1.945c4.103,1.473,7.494,4.634,12.241,4.715
                c1.547,0.026,3.056,0.612,4.779,0.281c3.071-0.592,6.063-0.064,8.124,2.6C286.676,582.131,288.017,582.309,290.048,581.268z
                M1007.827,285.987c0.11-0.443,0.222-0.886,0.332-1.329c-0.8-0.541-1.556-1.168-2.409-1.609
                c-8.564-4.431-17.211-8.673-26.117-12.413c-11.602-4.871-22.686-4.034-33.522,2.084c-1.159,0.654-2.357,1.244-3.479,1.956
                c-5.242,3.331-10.921,5.624-16.9,7.227c-0.892,0.239-2.091,0.187-1.909,2.092c4.579-1.304,7.229,1.571,9.716,5.324
                c4.486-3.804,8.479-1.186,12.55,0.363c4.735-3.205,4.735-3.205,10.193-0.05c1.848-1.274,3.767-2.561,5.641-3.908
                c0.838-0.602,1.711-1.229,2.731-0.864c2.53,0.907,5.562-0.532,7.827,1.938c0.806,0.877,2.377,1.022,3.779,0.5
                c1.264-0.471,2.542-0.302,3.898,0.004c3.629,0.817,7.174,1.519,10.955-0.147c2.85-1.256,6.195-1.096,9.361-1.159
                C1002.924,285.948,1005.376,285.987,1007.827,285.987z M1166.512,290.422c-1.192-4.118-2.064-7.119-2.93-10.122
                c-1.188-4.121-1.101-4.895,2.404-7.064c2.934-1.816,5.162-4.049,6.75-7.109c1.568-3.022,4.223-4.585,7.664-4.833
                c1.6-0.115,3.283,0.039,4.752-0.872c-4.941-11.833-15.426-17.27-25.781-23.277c-3.109,3.066-6.133,5.895-8.973,8.898
                c-2.002,2.118-2.996,4.856-3.185,7.714c-0.251,3.814-0.318,7.664,1.195,11.324c1.156,2.797,1.603,5.722,1.829,8.746
                c0.271,3.614,0.736,7.25,2.422,10.627C1157.484,285.167,1161.288,288.029,1166.512,290.422z M69.744,258.763
                c1.746-6.253,0.605-11.798-2.209-17.108c-0.739-1.394-0.169-2.939-0.013-4.421c0.435-4.131,1.244-8.275,1.161-12.4
                c-0.222-11.15-0.52-22.316-1.387-33.43c-0.635-8.13,0.459-15.55,5.352-22.213c0.171-0.233,0.125-0.625,0.208-1.099
                c-3.378-4.772-6.815-9.63-10.243-14.473c-1.028,0.537-1.137,1.422-1.204,2.165c-0.898,10.08-2.479,20.029-1.661,30.312
                c2.427,30.538,4.217,61.127,3.331,91.798c-0.064,2.237-0.009,4.478-0.009,7.693c3.666-4.239,7.512-6.382,11.795-7.839
                c2.082-0.708,3.205-2.052,3.079-4.501c-0.188-3.641-0.049-7.299-0.049-11.013C75.25,261.107,72.825,260.075,69.744,258.763z
                M576.143,571.184c1.616-3.793-0.587-6.13-1.508-8.582c-3.604-9.604-3.647-9.672-12.839-14.41
                c-3.107-1.602-5.248-3.762-6.97-6.765c-6.368-11.107-12.928-22.104-19.413-33.144c-4.548-7.742-9.104-15.479-13.628-23.235
                c-1.154-1.978-2.37-3.63-5.005-2.326c-0.15,1.931,0.935,3.308,1.536,4.819c4.233,10.646,9.849,20.727,13.033,31.808
                c1.101,3.833,2.454,7.495,5.018,10.728c2.269,2.859,3.546,6.324,4.734,9.828c2.079,6.134,4.597,12.117,6.787,18.215
                c0.828,2.306,2.165,3.602,4.629,3.836c2.16,0.206,4.427,0.52,6.06,2.013c1.463,1.339,3.14,1.53,4.877,2.056
                C567.645,567.29,572.455,566.982,576.143,571.184z M396.012,279.012c0.928,2.628,1.329,4.242,2.042,5.703
                c3.546,7.258,4.014,14.777,3.39,22.796c-0.705,9.065-2.155,18.163-0.684,27.378c0.529,3.315,1.513,5.173,4.776,5.804
                c4.159,0.803,7.253,2.92,9.132,6.935c1.115,2.383,2.849,4.489,4.399,6.651c1.379,1.923,2.485,4.12,5.122,6.138
                c-0.31-10.203-0.832-19.507-0.805-28.809c0.026-8.798-3.507-16.496-6.566-24.384c-0.231-0.597-0.647-1.202-1.141-1.603
                c-2.66-2.162-4.149-5.174-5.875-8.002c-2.426-3.977-4.685-8.056-7.155-12.004C401.174,283.262,399.701,280.814,396.012,279.012z
                M153.845,89.613c4.319,1.783,5.746,5.193,6.684,9.05c1.258,5.172,2.681,10.305,3.995,15.464c0.446,1.75,1.039,3.532,0.034,5.253
                c-1.714,0.96-2.69-1.112-4.187-0.896c-0.528,0.974-0.214,1.953,0.026,2.912c1.781,7.097,3.977,14.102,4.724,21.426
                c0.143,1.397,0.913,2.408,1.841,3.432c3.587,3.955,8.498,6.327,12.122,10.263c0.744,0.808,2.251,1.422,3.699,0.968
                c0.613-1.481,0.204-2.978,0.011-4.436c-1.349-10.232-2.798-20.45-4.123-30.685c-1.049-8.105-2.384-16.129-5.336-23.807
                c-2.503-6.509-5.438-12.961-2.871-20.243c-1.602-0.704-2.537-1.783-4.108-1.271c-0.381,1.715,0.94,4.071-0.887,5.112
                C161.548,84.39,159.345,89.345,153.845,89.613z M708.139,447.215c0.382-3.371,0.848-6.333,1.026-9.312
                c0.319-5.297-3.169-10.285-8.279-10.522c-4.115-0.191-8.524-0.741-12.517,1.383c-2.199,1.17-4.457,2.234-6.726,3.263
                c-3.399,1.541-5.577,4.157-6.688,7.637c-0.712,2.228-2.033,3.816-3.545,5.616c-2.316,2.758-4.805,4.905-7.889,6.572
                c-0.891,0.481-1.521,1.288-1.6,2.762c1.75,1.391,2.92,3.595,3.967,5.722c1.15,2.34,2.9,5.016,1.098,7.763
                c-1.975,3.011-1.531,6.146-1.447,9.372c1.586,0.036,2.935-0.227,3.415-1.277c1.313-2.874,3.817-4.955,4.848-8.045
                c1.647-4.935,4.698-8.616,9.841-10.532c2.254-0.839,4.521-2.126,6.234-3.988C694.701,448.386,700.565,446.07,708.139,447.215z
                M621.986,53.584c9.827,1.037,17.911,5.462,24.513,12.422c3.104,3.273,6.267,5.79,10.967,5.902
                c1.119,0.026,2.329,0.452,3.317,1.013c3.456,1.962,6.955,3.78,10.617,5.37c3.581,1.554,5.531,4.737,5.809,8.708
                c0.127,1.816-0.015,3.654-0.068,5.48c-0.037,1.312-0.237,2.532,0.744,3.757c3.755,4.69,4.109,10.395,4.406,16.055
                c0.114,2.181-0.4,4.335-1.478,6.257c-4.447,7.935-9.468,15.343-18.307,19.064c-1.958,0.824-3.237,2.033-3.678,4.299
                c-0.991,5.096-3.883,9.157-7.652,12.594c-10.183,9.285-22.473,14.946-35.398,18.786c-6.116,1.817-12.025,3.785-17.437,6.949
                c-5.717,3.343-11.344,6.926-16.405,11.262c-2.291,1.962-4.678,3.792-7.353,5.145c-12.171,6.156-24.138,12.706-36.23,18.998
                c-13.412,6.979-27.454,12.74-42.524,15.455c-2.403,0.433-4.827,1.001-6.978,2.519c-4.8,3.385-10.188,5.633-15.787,7.339
                c-6.692,2.039-13.333,4.194-19.604,7.352c-4.928,2.482-10.153,4.162-15.449,5.649c-13.153,3.693-26.519,6.432-39.939,8.929
                c-0.85,0.158-2.161-0.161-2.063,1.627c1.224,0.66,2.515,0.222,3.78-0.023c4.575-0.886,9.159-1.729,13.714-2.706
                c15.959-3.423,31.91-6.872,46.614-14.386c3.121-1.595,6.513-2.507,9.875-3.477c6.579-1.899,13.007-4.224,18.762-8.005
                c2.294-1.507,4.698-2.483,7.337-2.941c12.905-2.241,24.788-7.465,36.761-12.458c6.948-2.897,13.042-7.375,19.761-10.62
                c13.954-6.739,27.545-13.994,39.867-23.491c6.897-5.316,14.377-9.458,23.171-10.956c3.074-0.523,6.107-1.721,8.954-3.049
                c6.177-2.882,12.447-5.676,18.27-9.186c8.244-4.97,15.508-11.085,17.875-21.139c0.357-1.517,1.336-2.214,2.57-2.792
                c7.176-3.356,12.008-8.925,16.545-15.385c6.16-8.771,4.836-17.32,1.253-26.028c-1.167-2.836-2.019-5.351-1.909-8.431
                c0.252-7.091-3.019-11.695-9.614-14.116c-1.556-0.571-3.133-0.96-4.488-2.062c-2.513-2.043-5.5-3.172-8.698-3.471
                c-3.194-0.298-5.482-1.821-7.619-4.139c-5.634-6.11-12.332-10.644-20.478-12.795c-1.585-0.418-3.169-0.84-4.858-1.288
                c-1.014-5.729-4.802-9.074-9.456-11.696c-2.179-1.228-4.383-2.407-6.797-3.112c-7.086-2.07-13.295-6.088-20.009-8.984
                c-1.25-0.539-2.228-1.195-2.567-2.748c-1.307-5.975-5.558-9.202-10.897-11.397c-6.174-2.538-12.488-4.547-19.04-5.904
                c-5.698-1.18-11.576-1.405-17.138-3.303c-1.908-0.651-3.691-1.171-4.644-3.331c-0.685-1.552-1.991-2.889-4.6-1.94
                c0.985,0.827,1.972,1.238,2.172,1.89c1.306,4.243,5.058,4.726,8.476,5.725c2.074,0.606,4.174,1.062,6.34,1.326
                c9.632,1.174,18.965,3.515,27.92,7.331c5.571,2.374,9.589,5.895,9.827,12.494c8.233,3.481,15.792,8.049,24.184,10.945
                C614.308,41.315,620.789,44.826,621.986,53.584z M1138.77,281.873c-5.676,4.91-9.409,10.441-13.877,15.306
                c-1.092,1.188-1.438,2.614-1.614,4.099c-0.968,8.106-1.662,16.214-0.954,24.401c0.177,2.05,0.743,3.423,2.576,4.4
                c3.04,1.619,4.971,4.34,6.79,7.166c0.964,1.497,1.434,3.395,3.161,4.27c3.88-0.623,7.641-1.206,9.75-5.152
                c0.383-0.716,1.672-0.992,2.585-1.371c2.078-0.863,4.183-1.665,6.171-2.45c-0.088-1.231-0.622-1.919-1.33-2.339
                c-5.266-3.12-6.352-9.153-9.577-13.69c-0.602-0.847-0.025-1.937,0.033-2.92c0.348-5.821,0.202-11.705-0.896-17.366
                C1140.709,291.698,1139.222,287.262,1138.77,281.873z M946.119,431.233c1.717,9.509,1.515,19.124,0.854,28.741
                c-0.124,1.804,0.402,3.21,1.629,4.499c2.981,3.137,5.588,6.719,8.918,9.414c5.507,4.461,8.162,10.359,10.006,16.852
                c0.729,2.559,1.767,5.113,1.961,7.722c0.399,5.341,3.889,8.032,7.822,10.572c0.605-0.703,0.427-1.353,0.333-1.974
                c-2.162-14.305-4.585-28.568-5.909-42.996c-0.582-6.346-2.281-12.377-6.914-17.281c-4.932-5.219-10.117-10.124-15.815-14.484
                C948.241,431.714,947.546,430.862,946.119,431.233z M907.347,593.473c1.671-0.991,1.233-2.85,2.159-4.019
                c0.758-0.957,1.676-1.649,2.973-1.479c1.161,0.15,1.518,1.088,1.95,2.023c0.427,0.923-0.251,2.338,1.22,2.902
                c1.88-2.465,1.135-4.764-0.217-6.985c-4.744-7.797-7.707-16.178-8.467-25.307c-0.14-1.683-0.559-3.456-1.652-4.649
                c-3.684-4.027-5.293-8.595-6.14-14.062c-0.963-6.224-4.717-7.178-9.518-3.062c-4.01,3.438-7.245,7.719-11.428,10.835
                c1.4,7.208,1.322,14.277,4.073,21.032c4.024-0.416,7.283,0.604,9.721,3.936c0.818,1.117,2.381,1.838,3.746,2.377
                c3.678,1.451,5.813,4.11,7.24,7.728C904.195,587.751,905.054,591.01,907.347,593.473z M408.239,490.883
                c3.023,0.217,5.07,2.955,8.009,2.764c5.003-0.328,8.09,2.453,10.586,6.165c2.14,3.183,4.505,5.903,7.851,8.045
                c3.051,1.952,6.841,3.311,9.021,5.958c2.838,3.449,6.198,6.265,9.435,9.658c3.038-0.317,6.3-0.744,9.576-0.975
                c3.306-0.234,6.567,2.466,10.334,0.291c-2.418-4.982-4.587-9.794-5.867-14.953c-0.543-2.191-2.281-3.561-4.225-4.697
                c-5.25,1.93-10.909,2.11-15.315-0.701c-3.711-2.368-7.412-5.278-10.782-8.289c1.012-4.77,5.943-4.99,8.497-7.72
                c-4.21-4.444-4.351-4.97-9.565-3.571c-7.704,2.064-15.466,1.662-23.243,1.902c-1.747,0.054-3.635-0.5-5.232,0.717
                C407.087,487.23,407.451,488.828,408.239,490.883z M234.008,589.145c0.968-1.156,0.569-2.041,0.752-2.781
                c0.656-2.646-2.277-4.881-1.401-6.992c2.232-5.381-2.311-8.04-4.553-11.009c-1.61-2.132-3.497-4.223-3.638-6.599
                c-0.219-3.701-1.495-5.918-4.974-7.066c-0.669-0.221-1.342-1.085-1.591-1.8c-0.779-2.237-2.543-2.843-4.579-3.284
                c-1.927-0.419-4.009-0.53-5.553-2.004c-3.704-3.535-8.54-2.249-12.544-3.682c-1.419,2.414-0.271,3.766,0.577,5.16
                c0.78,1.281,1.881,2.584,1.421,4.12c-0.968,3.232,1.442,6.565-0.473,9.758c-0.468,0.78,0.479,1.445,0.876,2.151
                c3.177,5.655,5.865,11.487,6.822,18.011c0.235,1.603,0.356,3.562,2.208,4.47c2.432-2.672,1.295-7.22,5.385-8.848
                c4.401,3.252,2.633,9.258,5.675,13.113c1.396-1.214,1.014-3.438,2.616-3.826c2.547-0.619,2.765-2.52,3.162-4.492
                c0.196-0.974,0.516-1.93,0.859-2.863c0.354-0.967,0.923-1.795,2.076-1.889c1.384-0.111,2.233,0.778,2.642,1.881
                c0.574,1.546,0.914,3.185,1.277,4.801C231.424,587.138,231.919,588.643,234.008,589.145z M826.086,311.152
                c-9.284,4.193-17.465,8.756-21.932,17.944c-1.557,3.201-1.657,4.809,1.484,6.037c5.742,2.245,8.916,6.841,11.78,11.737
                c1.962,3.354,4.212,6.473,7.081,8.979c4.197,3.667,6.702,8.252,8.233,13.426c0.99,3.345,3.106,5.282,6.372,6.579
                c0.079-0.742,0.236-1.222,0.162-1.664c-2.793-16.741-5.416-33.515-8.549-50.192C829.916,319.73,828.598,315.338,826.086,311.152z
                M298.932,335.273c-3.048,2.089-5.578,3.959-8.245,5.607c-2.068,1.277-2.753,2.853-2.695,5.323
                c0.173,7.425,2.032,14.354,4.781,21.218c1.65,4.121,3.552,7.737,7.138,10.482c3.875,2.967,6.674,6.901,9.093,11.121
                c2.316,4.04,4.394,8.189,6.268,12.452c0.404,0.918,0.31,2.401,2.285,2.551c0.102-0.924,0.285-1.854,0.295-2.785
                c0.1-8.807,0.365-17.618,0.18-26.419c-0.121-5.775-2.414-10.875-5.966-15.524c-5.029-6.582-10.111-13.195-12.097-21.519
                C299.822,337.164,299.486,336.595,298.932,335.273z M115.749,426.678c1.328,1.892,1.213,4.773,4.091,5.283
                c7.022,1.242,10.553,5.691,11.599,12.661c3.056,0.426,5.678,1.674,7.066,3.426c6.6,0.43,12.496-0.835,18.323,0.874
                c5.551,1.627,11.174,3.042,16.277,6.012c0.747-2.501,0.827-7.482-0.033-9.253c-0.63-1.296-0.714-2.336-0.234-3.742
                c1.447-4.245-2.037-8.713-6.433-7.68c-3.336,0.785-6.681-0.127-10.088,1.473c-0.424-3.554-2.104-5.554-5.156-6.421
                c-0.731-0.208-1.327-0.968-1.933-1.531c-1.875-1.743-3.989-2.057-6.463-1.512c-5.001,1.103-6.227,0.729-8.653-3.674
                c-1.488-2.701-3.402-3.904-6.593-3.904c-1.539,2.658-4.028,3.933-7.225,4.232C118.387,423.099,117.123,424.589,115.749,426.678z
                M574.282,519.7c3.67-0.077,7.069,1.036,10.402-1.625c2.016-1.609,4.889-2.036,7.532-2.229c2.016-0.148,3.813-1.021,5.642-1.375
                c5.057-0.975,8.718-5.859,14.343-4.893c5.73-3.197,11.366-2.77,17.628-0.982c3.978,1.136,8.483,1.169,12.536,2.513
                c2.837,0.94,5.577,0.679,8.339,0.423c2.113-0.195,4.093-0.982,6.355-0.722c4.023,0.464,8.064,1.755,12.172,0.292
                c0.729-0.26,1.787-0.289,2.443,0.055c3.566,1.863,5.349-0.31,7.496-2.943c-3.588-0.52-6.729-0.875-9.827-1.454
                c-2.421-0.452-5.126-0.417-6.978-2.335c-1.872-1.939-3.667-1.766-5.814-0.613c-2.377,1.276-4.689,1.434-6.84-0.727
                c-1.085-1.089-2.584-1.492-4.173-0.98c-0.771,0.248-1.483,0.662-2.366,0.209c-3.789-1.949-7.853-3.197-11.536-5.555
                c-3.328-2.129-7.327-4.335-11.626-2.713c-5.392,2.033-11.336,2.316-16.405,5.336c-0.823,0.49-1.9,0.854-2.835,0.805
                c-3.914-0.199-6.525,1.953-9.05,4.518c-4.322,4.391-8.703,8.717-14.305,11.561C576.181,516.891,574.707,517.531,574.282,519.7z
                M42.731,212.117c2.169-5.564,3.054-11.248,2.938-17.078c-0.119-5.996-0.36-11.989-0.487-17.985
                c-0.479-22.504,0.401-44.97,2.073-67.408c0.217-2.909-0.03-5.65-1.427-8.228c-1.948-3.593-1.516-6.946,0.667-10.332
                c1.677-2.601,3.038-5.406,4.641-8.301c-9.347-0.468-15.667,4.439-16.95,12.734c-0.229,1.48-0.303,2.998-0.67,4.441
                c-0.975,3.835-0.857,7.549,0.673,11.236c4.437,10.691,5.717,21.81,4.438,33.275c-0.222,1.983-0.185,3.994-0.31,5.989
                c-0.345,5.496-0.42,10.964,0.467,16.444c0.664,4.102,1.52,8.305,1.26,12.398C39.34,190.388,41.778,201.18,42.731,212.117z
                M553.646,460.682c1.404-2.539,2.931-5.282,4.441-8.034c2.897-5.279,6.377-10.132,10.533-14.498
                c2.225-2.337,4.627-4.083,8.075-4.456c2.757-0.298,5.659-1.1,7.479-3.526c2.417-3.223,5.493-5.79,8.22-8.701
                c1.592-1.7,3.304-3.334,4.128-5.58c2.347-6.389,6.895-10.116,13.637-10.66c3.558-0.287,6.345-2.045,7.816-4.663
                c1.633-2.907,3.513-5.816,4.969-9.122c-7.607-1.526-14.111-1.007-19.504,4.894c-3.357,3.672-7.18,6.94-10.953,10.205
                c-3.474,3.006-6.765,6.298-12.157,5.711c-2.669-0.291-5.053,1.46-6.972,3.432c-1.403,1.441-2.511,3.098-3.43,4.885
                c-5.021,9.768-10.476,19.294-15.91,28.835c-1.795,3.152-3.521,6.376-4.419,9.895C550.673,460.445,551.811,460.99,553.646,460.682z
                M1090.833,365.038c2.526,2.871,5.272,4.591,8.567,5.461c5.805,1.533,11.604,3.095,17.382,4.723
                c0.858,0.242,1.583,0.241,2.395-0.021c3.319-1.07,6.589,0.134,9.874,0.316c2.061,0.114,3.871,1.124,4.68,3.212
                c0.461,1.187,1.182,1.662,2.389,2.157c4.042,1.658,8.633,2.872,8.921,8.604c0.073,1.463,1.646,1.877,2.894,2.348
                c7.902-3.322,13.422-9.469,19.205-15.473c-1.768-1.906-3.389-3.553-4.885-5.305c-0.867-1.016-1.935-1.275-3.115-1.166
                c-4.436,0.411-8.943,0.513-13.277,1.425c-5.17,1.088-7.788,0.352-10.427-4.263c-0.503-0.879-0.827-1.958-1.995-1.944
                c-2.269,0.027-3.505-1.569-5.063-2.795c-4.451-3.501-9.265-3.598-14.112-0.279c-1.271,0.87-2.314,1.019-3.654,0.345
                c-3.27-1.643-7.104-0.726-10.463-2.317c-1.308-0.619-2.898,0.462-4.152,1.288C1094.338,362.445,1092.76,363.653,1090.833,365.038z
                M132.496,417.238c1.086,1.383,2.03,2.527,2.911,3.718c2.041,2.76,4.239,4.975,8.128,3.32c1.067-0.454,2.394-0.457,3.416,0.136
                c3.134,1.818,6.797,3.244,9.072,5.853c1.673,1.918,3.366,1.976,5.229,2.604c1.399,0.471,2.592-0.059,3.892-0.287
                c6.523-1.145,10.996,3.44,9.575,9.83c-0.188,0.844-0.312,1.502,0.044,2.346c1.657,3.923,1.274,7.914,0.2,11.946
                c2.119,3.059,4.255,6.142,6.413,9.255c2.019-0.017,4,0.307,6.137-0.406c0.455-4.291,0.631-8.584,0.486-12.893
                c-0.04-1.183-0.318-2.31-0.935-3.333c-1.596-2.646-2.095-5.549-2.269-8.595c-0.112-1.955-0.041-4.559-1.739-5.504
                c-3.962-2.206-2.553-6.752-4.745-9.47c-2.23-2.765-6.157-3.852-7.457-7.773c-0.746-2.251-7.4-5.128-9.719-4.772
                c-7.041,1.081-14.149,1.146-21.245,1.348C137.229,414.637,134.5,414.264,132.496,417.238z M1262.086,184.391
                c-2.631-2.132-5.454-1.423-8.213-1.243c-5.11,0.333-8.561-0.958-11.197-4.518c7.631-2.365,16.006-0.709,23.244-4.845
                c-3.131-5.463-7.112-9.888-12.973-10.578c-6.565-0.773-8.984-4.5-10.262-10.034c-0.111-0.481-0.424-0.922-0.501-1.405
                c-0.399-2.508-1.849-3.687-4.372-3.933c-2.696-0.263-4,1.363-4.928,3.388c-3.933,8.579-5.262,17.605-5.189,27.032
                c0.049,6.396,5.419,10.703,11.383,10.524c3.653-0.109,7.361-0.286,10.961,0.195
                C1254.998,189.637,1258.593,187.555,1262.086,184.391z M1055.764,332.149c2.662-5.671,5.281-11.077,10.649-14.446
                c1.295-0.812,1.079-2.334,1.309-3.622c1.524-8.539,3.487-16.954,7.342-24.798c1.569-3.194,2.368-6.558,2.671-10.077
                c1.15-13.438,2.253-26.882,3.518-40.31c0.388-4.124,0.6-8.327,2.326-12.216c1.848-4.16,2.637-8.586,3.163-13.058
                c0.76-6.45,1.444-12.91,2.092-19.373c0.307-3.055,0.515-6.13-0.497-9.631c-1.918,2.01-2.113,4.115-2.357,6.208
                c-0.25,2.147-0.269,4.363-0.842,6.423c-2.763,9.932-4.051,20.241-7.711,29.946c-0.641,1.696-1.064,3.543-1.201,5.349
                c-0.898,11.964-2.461,23.84-4.365,35.682c-1.133,7.049-1.157,14.358-4.695,20.824c-5.41,9.888-8.485,20.592-11.318,31.397
                c-0.802,3.059-1.619,6.114-2.479,9.157C1052.857,331.409,1053.691,332.065,1055.764,332.149z M755.262,423.572
                c1.564-5.215,0.995-9.407-2.359-13.1c-1.891-2.083-2.297-4.676-2.138-7.456c0.155-2.705,0.034-5.426,0.034-9.104
                c-4.191,3.723-8.5,5.505-12.438,7.907c-5.101,3.112-9.174,6.995-9.491,13.683c-0.147,3.106-1.218,6.202-2.507,9.104
                c-1.59,3.577-1.074,6.753,1.389,9.753c2.914,3.547,4.618,7.651,5.472,12.159c0.229,1.215,0.327,2.574,1.532,3.812
                c3.002-4.901,4.614-10.417,8.121-14.882C746.479,430.862,749.909,426.131,755.262,423.572z M449.399,478.722
                c0.837,0.042,1.04,0.118,1.15,0.048c2.52-1.6,5.023-3.224,7.541-4.826c3.866-2.459,5.082-5.043,4.153-9.602
                c-0.529-2.595-1.284-5.163-2.157-7.667c-5.663-16.245-2.307-12.555-17.896-20.342c-5.602-2.799-9.571-5.971-11.785-11.722
                c-1.144-2.971-2.911-5.703-4.659-9.045c-0.981,2.261-0.501,3.767-0.727,5.201c-0.772,4.904,0.545,9.146,3.882,12.802
                c4.645,5.089,6.148,11.241,6.032,17.941c-0.02,1.161,0.183,2.328,0.132,3.486c-0.166,3.837,0.974,6.652,4.979,8.08
                c3.093,1.104,5.033,3.432,5.898,6.849C446.709,472.944,448.211,475.777,449.399,478.722z M109.014,271.775
                c-0.439,0.881-0.675,1.139-0.682,1.402c-0.584,22.32-1.122,44.642-1.744,66.961c-0.107,3.852,1.612,7.148,3.069,10.422
                c0.977,2.194,4.109,1.819,6.139,2.895c0.608,0.322,1.201,0.508,1.988,0.116c0.338-7.072,0.829-14.216,0.96-21.367
                c0.072-3.9,1.254-7.057,3.95-9.873c6.078-6.349,6.408-9.464,0.006-15.383c-4.225-3.906-5.86-8.77-5.89-14.312
                c-0.02-3.748-0.926-7.266-2.524-10.598C112.713,278.76,110.969,275.562,109.014,271.775z M90.312,464.293
                c2.864,0.235,5.75,0.407,7.69,2.91c0.864,1.114,2.15,0.809,3.252,0.615c6.138-1.076,10.743,2.574,14.578,6.055
                c2.957,2.686,6.713,6.173,4.993,12.197c4.133-3.17,8.106-2.093,12.106-2.43c0.578-4.803-3.229-7.663-4.475-11.469
                c-0.124-0.378-0.766-0.807-1.19-0.832c-3.777-0.222-6.145-3.6-10.248-4.015c0.821-6.332-3.888-9.942-6.795-14.326
                c-1.107-1.669-3.511-2.612-4.776-4.705c-2.42-4.003-6.033-6.087-10.908-5.312c-0.994,0.158-2.143,0.417-2.906-0.252
                c-3.264-2.86-7.338-1.021-10.937-1.967c-1.317-0.346-2.981,0.145-3.981,1.502c0.558,3.254,1.301,6.253,3.766,8.853
                c1.595,1.682,1.732,4.738,4.229,5.832C87.897,458.346,89.341,461,90.312,464.293z M1105.421,206.119
                c-1.36,10.043-2.494,19.265-3.902,28.445c-0.724,4.714-0.796,9.236,1.171,13.691c2.576,5.835,2.619,11.91,1.707,18.125
                c-1.28,8.715-2.892,17.412-2.396,26.294c0.09,1.625-0.326,3.326,1.085,4.688c2.114,2.041,2.239,4.721,2.193,7.427
                c-0.095,5.66-0.188,11.319-0.288,16.979c-0.059,3.37,0.674,6.569,1.903,9.751c1.56-2.924,2.607-5.856,3.243-8.957
                c1.068-5.212,1.499-10.721,3.556-15.523c2.704-6.312,2.458-12.565,2.057-19.014c-0.093-1.496-0.334-3.009-0.2-4.486
                c0.38-4.188-0.983-7.821-3.031-11.367c-1.405-2.432-3.147-4.815-3.438-7.697c-1.217-12.07-3.264-24.1-1.402-36.313
                c0.701-4.598,0.764-9.29,1.331-13.913C1109.387,211.168,1108.473,208.784,1105.421,206.119z M613.684,430.072
                c-0.898-0.516-1.162-0.792-1.442-0.811c-13.555-0.92-26.16,3.298-38.807,7.249c-0.746,0.233-1.393,0.9-1.992,1.465
                c-7.091,6.687-11.775,14.953-15.882,23.548c3.05,2.578,5.703,2.492,8.889,0.751c5.271-2.88,11.006-4.512,17.159-4.215
                c1.344-3.283,2.917-5.669,6.277-7.396c2.538-1.305,4.916-3.005,7.767-3.72c1.814-0.456,2.707-1.724,3.451-3.282
                c1.304-2.729,3.304-5.835,5.815-6.368C609.377,436.347,610.84,432.919,613.684,430.072z M1098.111,191.872
                c-0.248,1.218-0.477,1.856-0.497,2.501c-0.42,13.249-3.615,25.956-7.271,38.606c-1.614,5.583-2.021,11.652-4.803,16.738
                c-3.823,6.986-4.834,14.429-5.491,22.071c-0.199,2.322-0.336,4.65-0.439,6.979c-0.18,4.053-1.129,7.917-2.989,11.506
                c-3.569,6.888-4.882,14.464-6.786,21.858c-0.256,0.997-0.938,2.219,0.247,3.185c6.851-3.549,8.009-5.083,9.201-12.195
                c0.274-1.641,0.542-3.283,0.798-4.927c1.922-12.347,4.15-24.57,9.707-35.985c2.463-5.058,4.232-10.503,5.113-16.129
                c0.273-1.745,0.863-3.271,2.209-4.323c1.608-1.256,1.998-2.89,2.244-4.772c1.184-9.073,2.407-18.142,3.728-27.196
                c0.714-4.897,1.876-9.736,1.913-14.66C1102.693,194.04,1100.607,193.053,1098.111,191.872z M1202.55,371.257
                c0.615,1.261,1.435,1.53,2.203,1.814c4.495,1.661,6.843,4.933,7.68,9.598c0.646,3.603,1.802,7.075,3.556,10.345
                c1.396,2.603,1.342,5.425,0.799,8.287c-2.797,14.721-5.113,29.511-6.359,44.457c-0.855,10.249-0.41,20.487-0.396,31.125
                c2.683-1.357,2.702-3.375,2.838-5.342c0.401-5.814,0.715-11.637,1.234-17.441c0.646-7.213,2.412-14.298,6.755-20.059
                c4.124-5.471,3.978-11.324,2.602-17.006c-1.131-4.669-1.558-9.127-1.019-13.771c0.541-4.664,0.701-9.311-0.407-13.902
                c-1.632-6.764-1.151-13.564-0.532-20.374c0.265-2.904,0.616-5.803,0.318-9.31C1214.84,363.308,1208.07,366.067,1202.55,371.257z
                M1013.498,451.837c-2.275,1.522-2.925,3.284-4.045,4.642c-2.479,3.002-3.258,6.244-2.615,10.168
                c1.272,7.772,1.042,15.565-0.83,23.267c-1.363,5.608-0.906,11.159,0.067,16.817c2.138,12.424,6.655,24.438,6.497,37.262
                c-0.006,0.478,0.199,0.984,0.402,1.434c2.541,5.643,4.436,11.465,5.191,17.641c0.156,1.271,0.902,2.157,2.738,1.328
                c-0.229-12.805,1.172-25.8-2.497-38.545c-1.194-4.15-1.72-8.437-1.855-12.805c-0.468-14.97-1.076-29.937-1.753-44.898
                C1014.562,462.907,1013.976,457.685,1013.498,451.837z M1116.199,572.422c1.222-1.82,1.658-3.234,1.906-4.727
                c1.427-8.561,4.382-16.702,6.771-25.004c1.646-5.721,4.063-10.867,8.178-15.2c0.906-0.954,1.881-2.028,2.302-3.23
                c2.761-7.889,7.897-14.194,13.286-20.341c1.941-2.215,2.954-4.764,3.256-7.665c0.241-2.314,0.755-4.61,0.852-6.928
                c0.156-3.788,1.176-7.554,0.826-12.136c-2.816,2.96-4.377,5.469-4.771,8.823c-0.575,4.892-2.338,9.286-6.344,12.575
                c-4.477,3.676-8.949,7.365-12.85,11.694c-1.111,1.233-1.986,2.523-2.883,3.915c-3.227,5.014-4.932,10.853-8.698,15.56
                c-1.91,2.387-2.508,5.102-2.622,8.309c-0.166,4.691-0.146,9.641-3.202,13.804c-1.386,1.887-1.973,4.167-2.301,6.459
                c5.479,3.807,5.827,4.776,3.794,10.658C1114.416,569.972,1115.168,571.006,1116.199,572.422z M107.249,67.776
                c4.685,3.351,9.576,6.645,15.176,8.948c8.268,3.4,16.958,5.877,24.573,10.767c1.258,0.808,2.539,0.367,3.851,0.372
                c5.253,0.017,8.549-3.759,12.422-6.35c1.963-1.313,1.302-3.051,0.02-4.77c-1.653,0.822-3.101,1.603-4.6,2.27
                c-1.623,0.722-3.314,0.798-4.677-0.478c-2.508-2.347-5.734-3.288-8.711-4.728c-2.506-1.211-5.24-2.069-7.452-4.465
                c2.138-3.913,6.592-6.856,5.333-12.122c-10.688-5.644-11.064-5.639-22.652,0.257c-1.083,1.442,0.95,2.934-0.49,4.57
                c-3.516,1.752-7.515,2.233-11.565,1.385C107.861,64.972,106.919,66.028,107.249,67.776z M903.139,600.656
                c1.766-3.424,2.424-6.059,0.849-9.059c-1.391-2.647-2.517-5.44-3.651-8.213c-0.67-1.636-1.715-2.808-3.283-3.585
                c-3.135-1.554-6.271-3.083-8.479-5.997c-0.868-1.146-2.349-1.098-3.685-1.216c-1.31-0.116-2.642,0.483-4.208-0.259
                c-1.255-3.57-2.135-7.43-2.684-11.375c-1.264-9.07-2.52-18.143-3.818-27.209c-0.149-1.037,0.131-2.479-1.656-2.582
                c-1.409,2.056-1.279,4.437-1.307,6.74c-0.072,6-0.223,12.007-0.005,17.998c0.256,7.047-0.547,13.961-1.719,20.874
                c-1.136,6.702-1.729,13.456-1.938,20.274c1.995,0.483,3.147-0.139,3.683-1.818c0.302-0.947,0.474-1.936,0.72-2.9
                c0.305-1.195,0.837-2.281,2.235-2.312c1.498-0.032,1.696,1.273,2.156,2.348c0.725,1.695-0.146,4.045,1.926,5.234
                c3.624-2.558,6.369-5.424,6.58-10.028c0.054-1.175,0.807-2.185,2.605-2.044c2.963,4.068,0.343,9.649,3.219,14.128
                c1.777-1.534,1.05-3.574,1.562-5.218c0.478-1.534,1.288-2.79,2.989-2.973c1.659-0.18,2.312,1.021,2.646,2.412
                c0.194,0.802,0.229,1.84,1.278,1.855C901.963,595.776,901.645,598.414,903.139,600.656z M605.429,392.146
                c5.832-5.053,12.678-2.828,19.286-2.896c1.831-3.095,4.295-5.864,4.196-10.033c-6.335-0.494-12.451-1.159-18.583-1.401
                c-7.31-0.289-14.084-2.219-20.478-5.667c-1.294-0.698-2.614-1.212-3.118-2.939c-0.987-3.385-3.968-4.795-6.882-6.028
                c-4.288-1.814-6.394-1.167-8.688,2.875c-2.434,4.288-5.57,7.817-9.614,10.624c-1.954,1.355-3.874,2.831-5.633,5.502
                c3.6-0.595,6.074-1.949,8.551-3.198c2.215-1.118,4.269-1.354,6.664-0.4C582.521,383.123,594.598,385.892,605.429,392.146z
                M949.699,490.283c0.115-4.351-1.542-7.952-4.191-10.95c-5.799-6.56-7.585-15.542-13.541-21.992
                c-0.715-0.775-0.799-2.157-1.111-3.275c-1.296-4.643-2.478-9.318-3.877-13.929c-0.995-3.28-2.363-6.411-5.615-8.514
                c-3.423,5.315-6.115,10.454-6.594,16.73c-0.49,6.416-1.793,12.77-2.729,19.071c1.855,1.154,3.708,1.695,4.688,3.381
                c0.446,0.768,1.074,1.237,1.951,1.383c5.039,0.832,9.502,2.791,13.659,5.88c1.62,1.203,2.984,2.298,4.167,3.946
                c1.551,2.164,4.727,2.731,6.233,4.676C944.598,489.088,946.686,489.932,949.699,490.283z M1084.559,565.65
                c6.224-7.116,14.443-3.832,21.756-4.789c2.023-2.789,1.814-6.344,3.721-8.83c3.158-4.117,3.426-9.063,3.57-13.701
                c0.133-4.312,1.344-7.744,3.861-10.956c0.717-0.913,1.372-1.808,1.873-2.895c3.256-7.062,6.709-13.966,12.706-19.287
                c1.894-1.681,3.636-3.628,5.446-5.649c-3.823,0.339-7.436,1.021-10.157,3.881c-2.413,2.533-5.36,4.559-8.323,6.028
                c-3.924,1.946-7.223,3.882-8.305,8.401c-0.146,0.607-0.572,1.252-1.055,1.659c-4.491,3.787-3.408,10.794-8.305,14.336
                c-0.102,0.073-0.1,0.31-0.117,0.473c-0.481,4.337-2.988,7.998-4.05,12.141c-0.212,0.825-0.483,1.84-1.261,2.046
                c-3.174,0.842-4.408,3.894-6.498,5.853C1086.582,557.022,1082.999,559.933,1084.559,565.65z M98.497,84.724
                c2.578,3.249,5.522,5.475,9.311,5.752c5.035,0.368,9.128,2.791,13.404,5.011c2.353,1.222,3.51,3.014,3.594,5.502
                c0.136,4.018,1.346,7.867,1.724,11.822c0.272,2.858,1.497,4.606,4.094,5.604c3.615,1.389,5.929,4.068,7.636,7.485
                c0.788,1.576,1.426,3.533,4.13,4.461c-1.065-4.297-1.887-8.049-1.47-11.871c0.33-3.034-0.418-5.623-2.71-7.635
                c-3.513-3.084-4.634-7.21-5.06-11.579c-0.438-4.501-1.729-8.781-2.783-13.142c-0.866-3.586-2.475-5.962-6.152-6.903
                c-5.404-1.384-9.99-4.432-14.578-7.456c-1.222-0.805-2.278-1.984-4.241-2.048c-1.3,1.175-2.766,2.512-4.248,3.832
                c-0.795,0.708-1.542,1.689-0.974,2.593C102.208,79.387,100.046,81.785,98.497,84.724z M1206.994,376.957
                c-0.93,1.542-0.866,3.207-0.93,4.819c-0.179,4.489-0.295,8.979-0.465,13.469c-0.408,10.803-0.178,21.666-1.812,32.37
                c-1.464,9.59-1.753,19.2-1.862,28.852c-0.071,6.32-0.072,12.651-0.467,18.956c-0.654,10.474-0.576,20.953-0.693,31.434
                c-0.01,0.923-0.257,2.114,1.627,2.193c1.54-4.639,1.819-9.588,2.646-14.424c0.613-3.587,0.3-7.379,2.066-10.707
                c0.989-1.863,1.108-3.754,1.087-5.773c-0.088-7.82-0.348-15.648-0.083-23.459c0.591-17.492,3.381-34.734,6.483-51.924
                c0.536-2.971,1.267-5.94-0.223-8.789c-2.105-4.025-3.146-8.385-4.306-12.718C1209.606,379.547,1209.367,377.644,1206.994,376.957z
                M508.984,465.284c1.256,4.842,1.985,9.272,3.917,12.96c5.418,1.605,9.259-0.039,12.581-4.047
                c2.534-3.058,5.668-5.615,8.509-8.422c3.814-3.771,7.563-7.64,13.527-6.831c3.365-9.026,8.756-16.458,12.842-24.742
                c-1.795-0.337-2.688,0.825-3.771,1.428c-8.112,4.514-15.718,10.025-24.925,12.365c-1.414,0.359-2.843,1.101-3.985,2.015
                C521.463,454.988,515.335,460.076,508.984,465.284z M338.107,230.06c0-2.186,0.062-4.339-0.01-6.488
                c-0.457-13.464-1.168-26.924-1.312-40.391c-0.061-5.655-2.144-10.135-5.296-14.541c-5.794,7.656-5.835,7.667-3.449,16.325
                c1.038,3.768,1.231,7.603,0.639,11.379c-1.506,9.611-1.021,19.285-0.556,28.87c0.831,17.098,2.721,34.133,4.822,51.13
                c0.512,4.14,2.168,7.821,4.931,11.619c1.52-4.22,1.395-7.977,0.836-11.784c-1.592-10.854-3.379-21.674-5.767-32.382
                c-1.166-5.225,0.463-9.986,1.516-14.506C336.197,228.527,336.404,230.323,338.107,230.06z M526.982,447.652
                c3.229-1.196,6.258-2.538,9.415-3.413c2.269-0.629,3.373-1.823,3.681-4.06c0.436-3.164,1.327-6.128,3.519-8.634
                c0.721-0.824,1.043-2.077,1.329-3.19c2.026-7.922,6.113-14.64,11.64-20.6c1.703-1.837,3.275-3.905,3.878-6.878
                c-1.329,0.155-2.156,0.172-2.944,0.357c-4.257,0.999-8.114,0.576-11.743-2.257c-2.031-1.584-4.602-2.477-7.005-3.72
                c-1.845,3.174-2.712,6.39-3.125,9.66c-1.262,9.98-4.227,19.438-8.252,28.611c-0.732,1.669-1.272,3.425-1.859,5.154
                C524.43,441.877,524.825,444.824,526.982,447.652z M781.619,381.114c0,2.116-0.05,4.234,0.012,6.349
                c0.106,3.674-0.055,7.324-0.631,10.958c-0.237,1.494-0.282,3.15-1.913,3.81c-4.393,1.779-4.83,5.569-4.95,9.497
                c-0.193,6.327-0.354,12.651-1.055,18.951c-0.141,1.271-0.539,2.617,0.241,3.899c1.815,0.187,3.04-1.172,4.536-1.79
                c3.743-1.543,4.68-1.371,7.519,1.427c1.276,1.258,1.948,3.132,4.026,3.922c2.163-0.745,4.657-1.083,6.486-3.017
                c0.053-3.489-1.521-7.038,1.41-10.202c-4.15-4.278-4.711-9.284-4.213-14.608c0.317-3.386-0.172-6.608-2.406-9.424
                c-1.619-2.041-2.041-4.637-2.084-7.098C788.51,388.583,786.01,384.65,781.619,381.114z M1133.361,283.845
                c2.916-1.567,3.237-4.677,6.419-4.907c2.118,4.848,0.428,10.787,3.831,15.414c4.662-5.156,8.239-10.305,5.242-17.488
                c-0.557-1.333-0.552-2.94-0.613-4.429c-0.168-4.043-1.587-7.668-4.688-10.123c-3.692-2.921-5.064-6.843-6.194-11.053
                c-1.17-4.358-1.755-8.779-2.021-13.29c-0.375-6.323-1.725-12.417-4.267-18.325c-2.009-4.671-5.38-5.663-10.065-5.6
                c0.146,4.863,1.31,9.221,1.668,13.652c0.416,5.126,2.129,9.677,4.77,14.011c2.17,3.561,3.744,7.423,5.099,11.364
                c0.983,2.863,1.293,5.663,0.613,8.776C1131.611,268.915,1133.145,275.993,1133.361,283.845z M900.418,470.865
                c-10.532-1.253-17.529,2.379-21.632,12.212c-5.365,12.857-11.556,25.371-15.983,38.618c-0.4,1.197-1.329,2.376-0.324,3.908
                c1.961-1.215,4.229-1.599,6.535-2.24c0.476-3.732,2.544-5.863,5.49-8.227c3.621-2.906,8.466-4.729,10.459-9.879
                c0.798-2.062,2.475-3.851,3.185-6.338c0.911-3.191,1.284-7.033,4.331-9.158c6.084-4.242,6.517-11.072,8.33-17.287
                C900.923,472.082,900.599,471.562,900.418,470.865z M857.488,337.993c-2.064,7.216-4.023,14.395-2.294,22.021
                c1.964,8.662,2.461,17.466,1.914,26.333c-0.144,2.327-0.032,4.668-0.161,6.997c-0.123,2.219,0.35,4.25,1.224,6.289
                c2.818,6.577,5.802,13.093,7.845,19.978c0.248,0.838,0.129,2.083,1.693,1.896c0.75-7.473,1.717-14.903,4.172-22.037
                c0.746-2.172,0.381-4.282-0.02-6.403c-1.301-6.87-2.547-13.753-3.996-20.592C865.381,360.749,862.106,349.246,857.488,337.993z
                M1100.198,295.189c-0.476-10.245,0.827-19.962,2.516-29.63c1.399-8.013-0.866-15.247-3.925-22.437
                c-1.387,0.701-1.717,1.609-1.896,2.632c-1.191,6.758-3.466,13.204-6.312,19.384c-3.727,8.091-6.037,16.528-7.307,25.282
                c-0.852,5.87-2.329,11.66-2.654,17.95c4.865-0.647,8.847-1.997,12.016-5.661C1094.88,300.114,1096.59,296.918,1100.198,295.189z
                M1135.287,427.268c-1.861,10.029-1.151,19.723-1.994,29.314c-0.246,2.809-0.21,5.647-0.199,8.471
                c0.016,4.215,0.834,8.244,2.949,11.961c1.857,3.26,2.818,6.694,2.13,10.518c-0.382,2.119-0.427,4.297-0.649,6.445
                c-0.134,1.279-0.779,2.664,0.78,3.554c1.739,0.993,2.798-0.216,3.861-1.267c4.913-4.854,5.763-11.196,5.348-17.471
                c-0.406-6.15-1.317-12.333-0.269-18.383c1.57-9.061,0.557-17.301-4.193-25.359
                C1141.102,431.744,1139.117,429.116,1135.287,427.268z M391.625,434.179c-0.807-13.67-0.332-26.479-0.514-39.284
                c-0.041-2.833-0.301-5.616-0.877-8.418c-3.819-18.582-8.343-37.016-11.721-55.69c-0.356-1.968-0.782-3.954-1.987-5.607
                c-1.899-2.603-4.995-4.355-5.29-8.119c-0.065-0.829-1.249-1.939-2.648-1.794c-1.117,8.38,6.945,55.859,11.281,66.49
                c1.796-1.507-0.155-4.594,2.444-6.118c1.703,3.484,1.697,7.021,2.126,10.409c1.752,13.845,5.189,27.527,4.202,41.648
                C388.481,429.992,389.364,431.861,391.625,434.179z M1004.484,462.455c-4.963,3.23-7.807,11.106-6.014,15.481
                c1.027,2.507,1.66,5.054,1.411,7.767c-1.14,12.436-0.855,24.93-1.53,37.387c-0.44,8.11-0.92,16.245-0.245,24.348
                c2.37,0.875,4.589,1.548,5.501,4.118c1.633,4.603,5.875,6.886,9.238,9.917c0.684,0.616,1.751,1.539,2.975,0.834
                c0.072-0.455,0.184-0.781,0.162-1.1c-0.047-0.66-0.067-1.347-0.265-1.971c-2.841-8.986-5.939-17.913-6.049-27.5
                c-0.002-0.16-0.1-0.317-0.134-0.479c-1.593-7.474-2.99-14.997-4.829-22.41c-1.805-7.273-1.978-14.46-0.048-21.675
                C1006.816,479.096,1005.511,471.105,1004.484,462.455z M987.2,542.227c-0.137-0.618-0.181-1.297-0.435-1.885
                c-2.776-6.427-5.049-12.992-6.358-19.906c-1.028-5.426-3.804-9.988-8.427-13.115c-6.752-4.567-13.627-8.955-20.493-13.352
                c-1.284-0.822-2.615-1.852-4.64-1.67c0.412,3.416,1.227,6.455,1.936,9.386c0.652,2.692,3.007,4.925,4.957,7.151
                c3.425,3.91,9.48,6.252,8.205,13.121c-0.025,0.139,0.066,0.393,0.178,0.449c3.315,1.68,3.143,5.096,4.145,7.986
                c0.568,1.642,1.342,3.415,3.306,4.148c1.161,0.434,1.997,1.473,2.095,2.627c0.295,3.453,2.666,3.947,5.279,3.688
                c2.426-0.24,4.624-0.271,6.525,1.518C984.66,543.49,985.889,543.069,987.2,542.227z M697.617,366.555
                c1.652-1.447,3.125-2.109,5.089-2.276c2.694-0.229,5.536-0.884,7.019-3.953c0.809-1.672,2.609-2.481,4.591-2.562
                c3.189-0.131,5.257-2.093,7.503-4.537c-5.198-5.532-10.887-10.006-17.525-13.252c-3.03-1.482-6.228-2.11-9.635-2.152
                c-8.832-0.109-17.613,0.617-26.402,1.287c-1.263,0.096-2.669-0.056-3.64,1.164c1.226,1.633,3.172,1.813,4.874,2.248
                c3.535,0.902,7.139,1.545,10.729,2.225c1.299,0.246,2.811,0.068,3.672,1.239c1.863,2.535,4.504,4.973,5.149,7.829
                C690.246,359.152,694.145,362.189,697.617,366.555z M36.574,167.261c-1.916,5.211-3.26,9.546-3.433,13.937
                c-0.528,13.426-3.096,26.655-2.613,40.229c0.411,11.574-1.238,23.226-2.069,34.836c-0.687,9.607-1.51,19.204-2.306,29.225
                c3.566,0.264,3.438,6.039,8.325,5.172c0.726-6.415,1.47-12.735,0.828-18.984c-1.251-12.166,0.058-24.272,0.284-36.394
                c0.334-17.927,3.474-35.724,2.618-53.737C37.996,177.089,37.562,172.731,36.574,167.261z M617.352,542.452
                c0.107-4.793,0.174-9.43,0.33-14.063c0.069-2.041-0.922-3.187-2.803-3.471c-6.912-1.042-13.831-2.043-20.76-2.967
                c-2.004-0.268-3.731,0.451-4.204,2.633c-1.244,5.734-3.905,10.966-5.761,16.483c-1.36,4.045-1.382,7.366,2.988,9.952
                C596.99,548.51,607.116,546,617.352,542.452z M1087.896,505.766c0.328,0.146,0.656,0.293,0.984,0.439
                c2.292-4.188,4.338-8.487,6.109-12.951c5.961-15.033,9.563-30.816,14.713-46.111c2.039-6.053,2.407-12.565,3.195-19.723
                c-8.314,4.525-14.454,10.523-21.011,15.841c1.861,5.557,2.433,11.116,0.692,15.737c-1.803,4.787-2.183,9.179-1.547,13.972
                c1.177,0.512,1.9-1.497,3.92,0.009c-0.56,5.063-2.495,10.306-3.726,15.695C1089.938,494.33,1088.992,500.065,1087.896,505.766z
                M162.527,142.019c0.51-1.548,0.208-2.625,0.042-3.726c-0.897-5.932-2.894-11.596-4.196-17.428
                c-0.321-1.438-0.768-2.955,0.375-4.276c1.225-1.416,2.697,0.422,4.353-0.158c-1.531-7.135-3.369-13.996-5.463-20.79
                c-0.408-1.324-1.276-2.365-2.601-2.857c-4.725-1.756-9.477-3.443-14.265-7.317c1.424,5.146,2.357,9.155,3.673,13.035
                c0.769,2.268,0.58,4.125-0.6,6.138c-1.37,2.338-1.884,4.802-1.267,7.634c0.485,2.227,0.24,4.632,0.175,6.954
                c-0.091,3.191,0.467,6.274,1.115,9.376c0.346,1.655,1.038,2.715,2.701,3.143c4.482,1.153,7.977,3.991,11.49,6.795
                C159.454,139.652,160.874,140.732,162.527,142.019z M596.18,498.455c2.103,0.291,4.038,0.426,5.954-0.389
                c4.396-1.867,9.039-3.097,13.49-4.665c7.138-2.517,12.821-1.254,18.633,2.78c1.994,1.383,4.303,2.287,6.728,3.057
                c3.837,1.219,8.393-0.045,11.623,3.385c0.631,0.669,1.91,0.174,2.837-0.275c1.45-0.703,2.903-1.401,4.351-2.1
                c-0.379-1.63-0.797-3.071-1.039-4.542c-0.602-3.646-2.563-5.927-6.188-6.966c-3.993-1.145-7.9-2.586-11.858-3.854
                c-3.797-1.216-7.833-1.938-11.344-3.709c-2.362-1.191-4.275-1.311-6.569-0.623c-4.458,1.335-8.863,2.908-13.488,3.573
                C601.045,485.314,597.624,490.838,596.18,498.455z M242.606,426.373c1.192-7.479,0.814-11.909-2.091-20.076
                c-1.421-3.996-2.332-7.971-1.98-12.198c1.239-14.899-1.148-29.167-7.383-42.777c-0.414-0.905-0.668-1.881-1.034-2.809
                c-0.383-0.972-0.357-2.269-1.838-2.515c-0.429,2.027-0.274,4.399-1.356,5.845c-3.508,4.687-2.998,9.768-2.734,15.088
                c0.256,5.145,0.096,10.32,0.42,15.477c0.105,1.677,0.256,3.223,1.693,4.486c2.503,2.2,3.4,5.211,3.857,8.43
                c0.537,3.79,1.256,7.552,1.853,11.333c0.292,1.846,0.894,3.519,2.062,5.013C237.573,416.142,240.264,421.081,242.606,426.373z
                M118.787,485.125c1.339-3.661-0.878-5.745-3.127-8.134c-4.178-4.438-8.502-8.174-15.173-7.188
                c-1.537,0.228-2.737-0.12-4.022-1.27c-3.548-3.174-8.453-3.396-12.225-0.459c-1.87,1.456-3.416,2.511-5.768,1.274
                c-0.742-0.39-1.962-0.208-2.249,0.519c-0.894,2.266-3.535,1.488-5.667,3.601c3.553,0.54,3.811,2.7,3.848,5.293
                c0.024,1.648,1.518,3.319,3.148,3.504c4.102,0.466,6.934,2.814,9.777,5.415c0.931,0.851,2.217,1.477,3.619,1.269
                c4.814-3.907,8.388-4.188,13.954-1.097c1.626,0.902,3.242,1.195,5.188,1.078C113.57,488.722,116.3,487.264,118.787,485.125z
                M594.438,567.857c0.263-0.664,0.113-1.107-0.21-1.506c-3.988-4.915-7.983-9.824-11.962-14.747
                c-0.762-0.942-1.698-1.696-2.857-1.899c-3.043-0.531-4.846-2.697-6.786-4.771c-3.54-3.784-6.566-7.978-9.566-12.169
                c-1.819-2.542-3.904-4.63-6.823-5.581c-2.332-0.762-3.28-2.412-4.328-4.398c-6.458-12.238-13.87-23.906-21.614-35.365
                c-1.627-2.408-3.649-4.147-6.867-4.188c0.097,2.001,1.327,3.214,2.134,4.596c8.907,15.24,18.133,30.304,26.663,45.752
                c4.097,7.418,8.898,13.493,17.035,16.454c2.367,0.86,3.605,2.718,4.453,5.034c1.366,3.73,2.931,7.389,4.643,11.655
                c3.16-2.301,5.889-1.552,9.003-0.197C589.411,567.42,592,568.219,594.438,567.857z M1069.49,225.589
                c3.484,4.145,2.304,8.751,0.475,13.377c-0.367,0.927-0.619,1.901-1.012,2.816c-4.021,9.387-7.012,18.988-6.789,29.376
                c0.086,4-1.471,7.478-4.479,10.201c-1.8,1.629-2.8,3.588-3.206,6.006c-0.749,4.451-2.481,8.61-4.164,12.775
                c-3.419,8.466-7.186,16.758-12.456,24.126c1.053,1.753,2.498,2.021,4.168,2.417c2.562-3.477,4.571-7.315,6.236-11.24
                c3.484-8.208,8.209-15.545,14.082-22.215c3.753-4.262,5.742-9.514,6.303-14.975c1.141-11.09,3.762-21.962,4.507-33.1
                c0.601-8.971,1.591-17.887,4.872-26.383c0.369-0.955,1.275-2.021,0.342-3.215C1073.592,217.1,1071.467,220.999,1069.49,225.589z
                M754.363,425.51c-16.03,14.909-22.322,33.577-25.524,54.842c3.46-2.375,6.056-4.424,8.9-6.035
                c2.864-1.623,4.232-3.854,4.84-7.057c1.558-8.212,5.809-14.763,12.818-19.473c3.445-2.314,6.792-4.775,10.329-7.272
                c-1.709-2.681-4.34-4.46-5.202-7.437C759.626,429.978,756.729,428.568,754.363,425.51z M230.97,109.808
                c1.016-3.835,3.919-6.617,1.579-10.026c-2.158-0.569-4.178-1.102-6.307-1.663c-0.758,3.56-0.047,6.903-0.382,10.191
                c-0.26,2.551,0.536,4.375,2.585,6.02c2.715,2.179,5.201,4.661,7.657,7.139c2.587,2.61,5.696,8.484,6.166,11.23
                c-2.133,0.18-2.188-2.656-4.189-3.037c-1.283,2.876-2.326,5.654-3.004,8.572c0.933,2.334,3.774,4.027,2.374,7.234
                c-1.094,0.233-2.171,0.463-3.555,0.759c0.387,7.529,0.767,14.926,1.143,22.242c1.079,0.152,1.564,0.236,2.054,0.286
                c5.189,0.536,5.467,0.832,5.432,6.142c-0.038,5.82-0.012,11.638-1.144,17.397c-0.448,2.279-0.546,4.633-0.072,8.039
                c3.346-9.639,4.689-18.435,4.61-27.464c-0.01-1.155,0.118-2.322-0.66-3.344c-4.323-5.683-3.587-11.771-1.565-18.048
                c1.629-5.058,2.989-10.202,4.518-15.292c0.796-2.652,1.073-4.938-1.15-7.349c-1.619-1.755-2.721-4.261-3.33-6.62
                C242.012,115.564,238.381,110.998,230.97,109.808z M270.955,171.491c0.515,2.531,0.194,5.157-0.542,7.736
                c-1.611,5.647-2.007,11.335-1.426,17.207c0.95,9.604,1.629,19.238-0.079,28.861c-0.23,1.296-1.144,2.689-0.292,4.077
                c0.409-0.064,0.668-0.036,0.713-0.122c0.382-0.727,0.722-1.475,1.08-2.214c4.083-8.433,4.966-17.525,5.349-26.685
                c0.25-5.981,1.021-12.105,0.094-17.932c-1.25-7.867-0.524-15.547-0.114-23.324c0.561-10.632,1.018-21.271,1.438-31.911
                c0.174-4.397-2.765-8.394-7.295-10.037c-0.548,2.504-0.109,4.985,0.154,7.384c0.527,4.812-0.921,9.655,0.169,14.38
                c0.612,2.652-0.337,4.042-2.355,5.355c-1.734,1.128-4.092,1.516-5.008,3.705c4.261,4.98,7.396,10.265,7.96,16.481
                c-1.352,1.487-3.717,0.902-3.641,3.461C267.239,170.579,270.045,169.798,270.955,171.491z M50.824,451.43
                c0.631-2.681,0.38-4.886,0.451-6.952c0.118-3.43-0.003-7.145-3.472-9.759c-2.253-1.699-4.099-3.826-7.073-4.348
                c-0.756-0.133-1.661-0.602-2.093-1.207c-2.602-3.639-6.497-4.295-10.582-4.599c-1.628-3.09-3.551-6.087-7.067-6.642
                c-4.331-0.683-8.491-1.593-12.453-3.526c-1.288-0.627-2.896-0.678-4.298,0.206c-0.691,1.449-0.761,2.849,0.224,4.524
                c5.542,0.888,7.259,5.848,9.583,10.015c4.918,0.825,9.518,4.374,12.647,9.79c3.318-0.297,5.115,2,7.581,4.082
                c2.601,2.195,4.217,4.248,4.681,7.249c1.475,0.596,3.027,0.137,4.04,1.116C45.637,453.936,48.129,452.501,50.824,451.43z
                M1225.697,427.561c6.123-4.848,6.068-4.848,6.097-10.955c0.011-2.323,0.202-4.665,0.542-6.964
                c1.073-7.246,2.281-14.471,3.379-21.712c0.324-2.142,0.695-4.429-0.381-6.392c-2.092-3.816-1.261-7.579-0.66-11.56
                c0.652-4.331,2.134-8.686,1.146-13.112c-2.412-0.172-4.563-0.325-6.715-0.479c-3.521-0.252-4.81,0.78-5.118,4.411
                c-0.409,4.813-0.687,9.638-1.011,14.458c-0.234,3.499-0.453,7.031,0.306,10.463c1.348,6.094,1.507,12.201,0.919,18.382
                c-0.268,2.812-0.991,5.846,0.012,8.437C1226.011,417.181,1226.279,421.881,1225.697,427.561z M75.705,448.979
                c0.249-4.12-0.804-7.369-4.757-9.03c-0.79-0.332-1.578-0.68-1.839-1.533c-0.68-2.225-2.639-2.208-4.377-2.651
                c-1.448-0.369-2.949,0.188-4.407-0.495c-2.807-1.312-5.469-0.605-8.281,0.892c1.609,4.782,0.774,9.697,1.138,14.448
                c6.576,0.951,4.489,6.794,6.557,10.666c3.702,0.918,7.722,1.801,10.638,5.21c0.963,1.126,2.969,1.347,4.677,1.371
                c6.208,0.085,7.018-0.186,12.867-4.472c-0.504-1.77-1.332-3.046-3.183-4.04c-1.769-0.951-4.028-2.007-4.53-4.267
                C79.583,452.259,77.909,450.389,75.705,448.979z M57.304,315.524c0.355-1.484,0.611-2.406,0.794-3.342
                c1.666-8.518,2.249-17.166,2.692-25.798c1.556-30.315-0.584-60.559-2.056-90.812c-0.04-0.812-0.308-1.617-0.502-2.417
                c-0.03-0.125-0.225-0.249-0.368-0.299c-0.629-0.22-0.061,0.543-0.441-0.16c-1.602,5.371-2.49,10.716-2.492,16.266
                c-0.008,18.979,0.332,37.949,0.819,56.92c0.111,4.317,0.504,8.701-0.604,12.933c-2.886,11.021-1.812,22.462-4.121,33.615
                C53.099,313.452,54.866,314.322,57.304,315.524z M922.716,576.645c2.008,1.363,2.456,4.184,5.696,3.412
                c1.9-0.453,2.402,1.242,2.768,2.783c0.548,2.314,0.229,4.86,1.5,7.197c2.662-0.949,2.375-3.372,3.367-4.939
                c0.723-1.139,1.324-2.408,2.907-2.345c1.655,0.066,2.025,1.505,2.538,2.712c0.997,2.352,0.743,5.119,2.277,7.602
                c2.004-1.42,2.986-3.264,2.761-5.194c-0.286-2.456,0.96-3.776,2.587-4.956c1.859-1.347,3.02-2.943,3.217-5.314
                c0.088-1.064,0.304-2.357,0.983-2.576c2.501-0.635,2.325,1.508,3.935,2.21c1.246-3.857,2.467-7.595,3.654-11.343
                c0.254-0.801,0.432-1.787-0.229-2.365c-3.101-2.721-4.186-6.637-5.84-10.137c-3.469,0.427-5.403,2.502-6.389,5.218
                c-1.707,4.704-5.16,7.1-9.701,8.425c-2.066,0.602-4.074,1.397-6.126,2.052C928.701,570.336,925.523,572.565,922.716,576.645z
                M101.235,246.025c0.237,2.998,0.373,5.475,0.637,7.939c0.924,8.603,0.503,16.965-4.073,24.662
                c-1.142,1.922-1.304,4.045-1.238,6.27c0.457,15.327,0.479,30.659,0.332,45.99c-0.057,5.907,1.307,7.483,7.783,8.529
                c0.191-8.009,0.272-15.987,0.593-23.954c0.851-21.153,1.137-42.311,0.932-63.478C106.159,247.748,106.145,247.749,101.235,246.025
                z M62.533,78.704c-3.394,1.246-4.584,3.213-4.498,6.309c0.078,2.815-0.416,5.643-0.552,8.469
                c-0.567,11.826-2.99,23.477-3.543,35.28c-0.654,13.968-1.167,27.952-1.534,41.928c-0.184,6.992,1.221,14.025,1.913,21.104
                c1.595-0.354,1.414-1.329,1.669-2.065c1.74-5.001,0.897-10.247,1.47-15.358c0.965-8.605,2.346-17.194,2.738-25.828
                c0.725-15.972,2.268-31.915,1.962-47.932c-0.034-1.816-0.016-3.763,0.577-5.438C64.668,89.705,63.372,84.377,62.533,78.704z
                M942.551,515.203c5.777-0.83,6.56-0.547,8.93,4.708c1.707,3.783,3.099,7.72,4.438,11.654c2.319,6.811,7.018,9.211,14.04,6.922
                c0.046-1.085-0.387-2.03-1.389-2.392c-2.322-0.836-3.251-2.896-4.053-4.823c-1.149-2.763-0.683-6.296-4.179-7.87
                c-0.331-0.149-0.405-1.254-0.322-1.879c0.535-4.076-1.318-6.707-4.905-8.318c-0.813-0.365-1.159-1.092-1.685-1.724
                c-2.072-2.494-4.354-4.759-6.016-7.642c-2.434-4.227-7.184-4.021-11.23-5.016c-1.757-0.431-3.424-0.345-5.295,0.357
                c-3.912,1.466-7.316,4.989-12.135,3.402c-0.471-0.154-1.373,0.303-1.771,0.748c-1.498,1.677-3.097,1.139-4.995,0.814
                c-5.343-0.912-10.835-1.837-14.917,3.303c2.531,0.769,5.332,1.055,7.47,2.397c3.483,2.188,6.804,1.655,9.775-0.12
                c4.118-2.46,7.923-5.047,12.789-2.007c0.118,0.074,0.329,0.032,0.489,0.003C934.457,506.498,938.853,510.268,942.551,515.203z
                M1130.064,405.102c-4.695,1.25-5.449,5.052-5.986,8.848c-1.771,12.499-3.521,25.002-5.121,37.523
                c-1.599,12.515-2.274,25.141-5.359,37.468c-1.121,4.479,0.408,9.05,0.986,13.794c2.479-0.782,4.273-1.518,6.259-2.886
                c-0.163-5.325-0.166-10.907,1.681-16.073c2.811-7.867,2.943-15.949,2.99-24.096c0.059-9.964-0.088-19.901,2.043-29.772
                c1.22-5.65,0.357-11.604,1.386-17.372C1129.371,410.133,1130.506,407.804,1130.064,405.102z M123.373,377.748
                c0.765-0.606,0.739-1.118,0.548-1.539c-2.547-5.593-5.142-11.165-7.678-16.763c-0.899-1.985-2.374-3.216-4.487-3.543
                c-3.984-0.616-6.024-2.975-6.717-6.853c-0.666-3.726-2.966-6.165-6.418-7.688c-2.139-0.945-3.957-2.447-5.61-4.105
                c-2.285-2.292-5.127-3.671-8.049-4.883c-1.479-0.613-3.067-1.366-4.688,0.034c0.109,1.87,1.642,2.85,2.715,3.982
                c3.213,3.389,4.972,7.186,5.406,11.954c0.466,5.104,2.179,9.998,5.126,14.505c3.575-0.207,6.684,0.57,8.94,3.512
                c0.471,0.614,1.31,1.112,2.071,1.309c4.354,1.125,9.5,0.479,12.03,5.553C120.236,372.488,121.616,375.569,123.373,377.748z
                M9.654,326.156c2.912,2.532,2.387,6.231,3.036,9.594c0.386,1.998,1.153,3.798,3.26,3.798c4.735-0.001,8.41,2.909,12.677,4.127
                c2.897,0.828,5.022,2.507,6.319,5.221c0.805,1.683,1.755,3.297,3.549,4.021c3.512,1.419,5.986,3.861,7.23,7.387
                c4.43-1.822,9.236-0.983,12.852-4.189c0.901-0.799,2.543-0.555,3.88-0.227c0.887,0.218,1.841,1.026,2.649-0.162
                c-0.015-0.329,0.048-0.677-0.057-0.964c-0.335-0.915-1.155-1.118-2.001-1.19c-1.197-0.103-2.318-0.329-2.944-1.511
                c-2.006-3.791-4.921-4.488-8.342-2.442c-2.301-0.951-2.404-3.448-4.413-4.751c-4.458,1.527-7.76-2.126-11.769-3.354
                c-0.646-0.198-0.963-0.833-1.225-1.479c-2.482-6.126-2.5-6.144-7.889-11.232c-0.515-0.486-0.779-1.065-0.775-1.747
                c0.013-2.725-1.812-3.45-4.028-4.257C16.98,321.094,13.309,322.47,9.654,326.156z M73.984,171.591
                c-3.14,4.313-5.064,8.137-4.854,12.897c0.433,9.807,0.597,19.625,1.066,29.429c0.39,8.155,0.631,16.29-0.816,24.366
                c-0.318,1.775,0.107,3.339,0.854,4.808c2.095,4.115,3.214,8.327,1.958,12.94c-0.118,0.434,0.125,0.966,0.202,1.458
                c1.56,1.048,3.154,1.842,5.685,1.684C77.065,230.194,80.207,201.083,73.984,171.591z M103.116,91.354
                c-1.417-1.115-2.751-2.098-4.009-3.169c-2.886-2.458-2.938-2.75-1.186-6.312c0.579-1.176,1.654-2.278,0.911-3.681
                c-1.736-3.28-0.04-5.336,2.397-7.169c3.361-2.529,4.953-5.984,5.644-10.222c-3.446-0.952-6.503-1.118-9.609,0.673
                c2.954,2.748,3.76,7.341,1.83,9.322c-1.131,0.808-2.684,0.055-3.866,1.108c-1.581,4.543-0.841,9.472-1.669,14.197
                c-0.393,2.24-0.489,4.47,0.641,6.303c4.552,7.382,3.766,14.722,0.321,22.164c-0.276,0.596-0.354,1.281-0.566,1.911
                c-1.288,3.822-1.812,7.638-1.266,11.744c0.679,5.099,1.411,10.36,0.849,15.41c-0.669,6.02,2.754,9.431,6.276,14.034
                c1.267-7.184,1.539-13.637,0.996-20.054c-0.581-6.865,0.041-13.594,0.886-20.375C102.764,108.653,103.156,100.017,103.116,91.354z
                M424.206,550.633c2.188,5.312,4.229,10.389,6.369,15.422c1.688,3.97,4.281,5.344,8.792,4.99c1.146-0.09,2.316,0.114,3.475,0.174
                c3.921,0.198,7.097,2.447,10.571,3.865c2.059,0.84,4.533,1.712,6.191,0.408c3.646-2.865,7.485-1.975,10.836-0.588
                c2.627,1.088,4.543,0.424,7.28,0.187c-3.084-4.544-7.293-7.181-11.625-7.925c-4.498-0.773-7.496-2.979-10.561-5.73
                c-2.165-1.943-4.719-3.245-7.624-3.289c-2.116-0.031-3.635-0.881-5.438-1.846c-4.463-2.388-9.06-4.609-13.914-6.176
                C427.267,549.709,425.956,549.599,424.206,550.633z M60.033,402.558c0.71-8.154-3.743-13.937-8.272-19.678
                c-0.8-1.015-2.036-1.154-3.189-1.059c-2.16,0.179-3.745-0.394-5.017-2.331c-1.424-2.17-3.764-3.09-6.269-3.703
                c-8.328-2.038-9.315-1.522-12.213,6.802c1.915,4.549,6.115,7.883,6.836,13.148c1.831,0.361,3.621,0.674,5.391,1.072
                c3.912,0.878,7.799,1.987,10.829,4.725C51.939,404.978,55.817,404.651,60.033,402.558z M446.826,479.797
                c0.214-2.566-1.14-4.035-1.533-5.773c-1.174-5.196-3.873-9.058-9.099-10.81c-1.833-0.614-2.527-1.967-2.648-3.786
                c-0.264-3.975-0.758-7.944-0.81-11.92c-0.079-6.089-3.595-10.311-7.398-14.582c-0.855,16.536-1.536,32.986-1.285,50.182
                C431.928,481.783,439.153,479.996,446.826,479.797z M103.508,148.456c1.864,0.792,3.967,1.761,6.125,2.584
                c3.428,1.308,4.378,0.709,4.858-3.067c0.464-3.652,0.386-7.313-0.076-10.966c-1.554-12.273-1.744-24.572-0.74-36.898
                c0.173-2.121,0.401-4.271-0.213-6.664c-2.634-0.596-5.167-1.066-8.34-1.12C105.599,110.984,100.366,129.17,103.508,148.456z
                M1067.254,226.626c-1.382,8.372-5.65,14.983-8.979,22.01c-2.645,5.582-4.904,11.379-5.805,17.475
                c-1.222,8.255-4.889,15.497-8.43,22.825c-0.582,1.205-1.158,2.294-1.303,3.673c-0.434,4.138-1.324,8.249-1.479,12.39
                c-0.201,5.358-1.711,10.083-4.878,14.396c-0.854,1.164-2.23,2.431-1.663,4.232c1.326,0.171,1.742-0.603,2.191-1.273
                c7.112-10.6,12.141-22.172,15.508-34.428c0.866-3.152,1.755-5.983,4.255-8.193c1.931-1.706,3.107-3.896,3.385-6.497
                c0.282-2.646,0.771-5.301,0.722-7.944c-0.093-5.096,1.004-9.877,2.826-14.592c2.037-5.272,3.859-10.628,5.781-15.946
                C1070.436,231.847,1070.023,229.305,1067.254,226.626z M82.047,73.982c-0.171,3.108,0.014,5.574,0.582,8.006
                c1.121,4.8,0.253,9.224-2.327,13.393c-1.406,2.272-3.01,4.379-4.65,6.481c-2.302,2.949-2.05,5.255,0.545,7.856
                c1.527,1.531,3.061,3.058,4.562,4.614c0.569,0.59,1.003,1.606,1.889,1.454c3.42-0.589,5.536,1.71,8.156,3.742
                c1.466-4.528,2.675-8.844,4.281-13.006c0.983-2.546,0.971-4.77-0.268-7.15c-0.765-1.469-1.231-3.09-1.909-4.609
                c-1.391-3.115-1.87-6.25-1.277-9.703c0.724-4.216,0.237-8.556,1.381-12.986C88.808,71.449,85.899,74.21,82.047,73.982z
                M773.873,329.179c-1.596,2.122-1.664,3.776-1.932,5.393c-2.535,15.273-4.963,30.566-7.697,45.804
                c-2.292,12.777-5.174,25.444-6.393,38.41c-0.396,4.212-1.249,8.207,3.029,11.115c0.828,0.562,0.987,2.056,1.562,3.059
                c0.46,0.802,0.577,1.997,2.05,2.012c0.91-1.061,0.484-2.444,0.63-3.674c0.739-6.281,0.685-12.646,1.927-18.875
                c1.147-5.752,1.396-11.556,1.316-17.406c-0.064-4.667,0.301-9.334,1.248-13.913c3.357-16.235,3.163-32.786,4.449-49.204
                C774.112,331.252,773.979,330.591,773.873,329.179z M89.857,360.421c-2.06-5.174-3.279-10.321-3.904-15.621
                c-0.131-1.11-0.567-2.33-1.244-3.203c-2.539-3.275-4.926-6.713-8.548-8.983C72.5,330.318,68.918,327.9,65.523,325
                c-1.967,1.635-3.85,3.197-5.117,5.571c2.088,2.035,2.678,4.541,2.873,7.293c0.223,3.146,0.664,6.287,1.206,9.396
                c1.239,7.109,1.958,8.037,8.991,8.675c4.212,0.382,7.833,1.265,10.006,4.999C85.747,360.751,87.666,360.597,89.857,360.421z
                M587.586,429.816c1.295-0.153,2.264-0.288,3.237-0.379c5.942-0.558,11.814-2.964,17.738-2.024
                c5.797,0.919,8.884-1.498,11.547-6.024c2.221-3.773,5.016-7.113,10.391-6.681c-2.4-5.472-7.545-8.048-10.463-13.469
                c-3.586,4.916-8.337,5.653-13.069,6.543c-3.945,0.742-6.497,3.755-7.905,6.963c-1.744,3.971-4.399,6.956-7.265,9.956
                C590.363,426.202,588.429,427.332,587.586,429.816z M854.857,392.337c0.664-9.075,1.393-17.507-0.442-25.851
                c-2.001-9.102-2.409-18.102,0.437-27.099c0.398-1.263,0.609-2.592,0.834-3.903c0.168-0.978,0.514-2.057,0.271-2.952
                c-1.434-5.292-3.017-10.543-4.789-16.635c-2.655,5.368-4.53,9.839-3.854,15.099c0.467,3.64,0.01,7.316-0.879,10.897
                c-1.17,4.72-1.609,9.49-1.48,14.358c0.194,7.324,0.815,14.641,0.438,21.977c-0.052,0.998-0.172,2.004,0.485,2.879
                C848.623,384.765,851.016,388.716,854.857,392.337z M408.157,510.383c-1.797-15.967-3.942-31.781-5.645-47.652
                c-1.171-10.919-2.041-21.878-3.529-32.753c-1.209-8.839-3.02-17.605-5.627-26.229c-0.243,10.588-0.7,21.15,0.218,31.696
                c1.486,17.081,3.058,34.154,4.637,51.227c0.167,1.812,0.269,3.762,1.008,5.367C402.065,498.215,405.157,504.277,408.157,510.383z
                M1035.465,461.738c-2.13,4.58-3.29,8.836-3.584,13.355c-0.389,5.981-0.588,11.992-1.36,17.928
                c-0.906,6.969,0.518,13.749,2.34,20.168c1.396,4.919,1.58,9.365,0.674,14.139c-1.32,6.959-0.875,14.817,1.351,21.682
                c1.401-9.562,2.852-18.582,3.457-27.713c0.246-3.717,0.467-7.426,4.136-8.54c1.935-5.656,0.349-10.427-0.86-15.257
                c-2.35-9.387-4.178-18.848-3.859-28.607C1037.834,466.596,1037.701,464.238,1035.465,461.738z M1146.751,438.364
                c0.606-1.15,0.925-1.542,1.029-1.984c1.962-8.258,3.263-16.602,3.185-25.121c-0.043-4.659,0.383-9.275,1.186-13.868
                c0.172-0.988,1.197-2.101-0.217-3.296c-5.316,2.929-10.822,5.605-16.536,7.814c-1.759,0.68-2.349,2.028-2.988,3.646
                c-2.158,5.468,0.025,10.4,1.624,15.419c0.726,2.277,1.994,4.241,4.033,5.524c3.356,2.111,5.832,4.831,6.924,8.737
                C1145.244,436.147,1145.931,436.938,1146.751,438.364z M1115.987,462.354c-1.493,1.214-1.742,2.171-2.149,3.067
                c-2.141,4.72-3.401,9.725-4.68,14.705c-1.538,5.997-4.351,10.986-9.388,14.823c-6.641,5.059-9.796,12.442-12.31,20.097
                c-0.926,2.816-0.477,5.747-0.467,9.872c2.479-3.092,4.988-4.116,7.311-5.121c2.476-1.071,3.928-2.712,5.291-4.782
                c2.141-3.248,4.784-5.77,8.641-7.157c4.226-1.521,4.625-2.637,4.034-7.19c-0.84-6.481-0.881-12.874,0.959-19.259
                C1114.936,475.485,1115.105,469.298,1115.987,462.354z M788.403,450.986c-1.124,0.54-1.178,1.235-1.325,1.862
                c-2.933,12.424-5.473,24.961-11.42,36.457c-0.889,1.719-1.128,3.805-1.494,5.754c-0.34,1.807,0.381,2.799,2.424,2.736
                c4.913-0.15,11.455-4.849,13.098-8.759c0.715-1.7,1.311-3.381,1.662-5.179c0.538-2.755,1.841-4.926,4.592-6.054
                c2.762-1.133,4.292-3.476,4.046-6.247c-0.274-3.103-1.215-6.291-3.941-8.311c-2.841-2.106-4.358-4.922-5.137-8.25
                C790.531,453.388,790.346,451.672,788.403,450.986z M1200.885,214.973c4.834,0.791,10.139,1.659,16.142,2.641
                c-2.102-5.56-3.924-10.364-5.732-15.173c-0.54-1.437-1.417-2.644-2.53-3.667c-6.129-5.63-12.267-11.259-18.918-16.277
                c-3.604-2.72-7.785-3.88-12.966-4.261C1186.83,189.415,1192.391,202.569,1200.885,214.973z M1125.941,502.641
                c-1.555-1.134-2.557-1.294-3.52-0.934c-3.269,1.223-6.643,2.313-9.131,4.943c-1.406,1.486-2.947,2.707-4.932,3.246
                c-2.729,0.74-4.858,2.364-6.136,4.783c-2.192,4.149-5.637,6.521-9.845,8.313c-3.152,1.342-5.244,3.808-5.775,7.513
                c-0.471,3.28-1.463,6.484-2.176,9.732c-1.174,5.353-0.568,10.67-0.312,16.487c4.294-3.082,6.143-7.492,10.302-9.49
                c0.608-0.292,0.947-1.317,1.235-2.071c0.711-1.859,1.307-3.763,1.973-5.641c0.931-2.625,1.248-5.825,2.984-7.721
                c2.293-2.504,3.332-5.229,4.135-8.273c0.434-1.646,1.32-3.05,2.535-4.234c0.84-0.818,1.566-1.646,1.863-2.867
                c0.998-4.106,4.225-6.512,7.606-8.129C1119.924,506.781,1122.295,504.313,1125.941,502.641z M72.13,469.205
                c-2.491-0.408-4.015-2.111-5.676-3.701c-1.908-1.825-4.747-1.301-6.98-2.422c-1.438-0.723-2.594-1.65-2.488-3.367
                c0.151-2.448-1.28-4.331-1.824-6.578c-3.1-0.197-5.688,0.453-7.853,2.102c-0.118,0.993-0.069,1.749,0.534,2.103
                c3.03,1.778,3.693,4.507,3.202,7.707c-0.236,1.543-0.034,2.948,0.797,4.296c0.883,1.433,1.16,2.973,1.231,4.713
                c0.291,7.164,3.406,10.313,10.467,11.063c3.173,0.337,6.021,1.411,8.8,3.132c4.194,2.598,8.749,4.088,13.659,1.283
                c-2.097-2.638-4.267-4.645-7.775-5.068c-2.562-0.311-4.81-2.072-5.326-4.674c-0.56-2.818-2.035-4.675-4.19-5.926
                C68.729,471.348,71.16,471.037,72.13,469.205z M375.651,296.945c0.728-5.325,0.282-9.556,0.021-13.772
                c-1.19-19.28-2.49-38.553-3.6-57.837c-1.129-19.629-2.417-39.246-4.18-58.826c-0.382-4.239-0.945-8.55-3.461-12.705
                c-0.521,1.945-0.061,3.404,0.104,4.866c1.193,10.602,2.229,21.211,1.846,31.906c-0.131,3.643-0.425,7.239,0.115,10.938
                c1.557,10.67,2.729,21.396,4.058,32.138c-2.044-0.031-1.508-2.643-3.469-2.775c-0.588,3.829,0.548,7.529,0.927,11.252
                c1.044,10.271,1.228,20.626,3.061,30.821c0.884,4.916,1.568,9.836,1.225,14.894C372.096,290.797,373.299,293.54,375.651,296.945z
                M1122.982,498.943c1.94,1.461,3.432,0.107,4.996,0.007c8.272-0.526,7.428-1.323,8.121-9.265
                c0.319-3.658,0.964-7.281-1.102-10.682c-3.621-5.963-4.224-12.576-3.706-19.286c0.833-10.785,0.49-21.633,1.817-32.383
                c0.428-3.467-0.808-6.33-2.709-9.102c-0.359,5.181-0.444,10.343-1.502,15.346c-1.424,6.735-1.757,13.52-1.662,20.341
                c0.156,11.33,0.053,22.573-3.61,33.532C1122.391,491.148,1122.221,495.217,1122.982,498.943z M565.54,393.708
                c-1.305,6.329-4.417,11.317-8.189,15.988c-2.189,2.711-4.37,5.453-6.277,8.362c-1.697,2.588-3.04,5.417-3.101,8.818
                c2.285-0.611,3.382-2.024,3.795-3.887c0.775-3.501,3.331-5.083,6.272-6.541c4.195-2.08,8.399-2.796,12.702-0.787
                c2.783-3.038,5.883-5.48,9.636-5.304c6.282,0.295,9.269-4.044,13.97-8.05C583.837,400.226,575.323,395.051,565.54,393.708z
                M1087.553,348.241c1.418-1.945,1.217-3.512,1.533-4.942c1.039-4.688,1.268-9.597,3.673-13.92
                c4.476-8.046,6.042-17.233,10.043-25.485c1.364-2.813,0.343-4.185-3.403-6.136c-1.801,2.019-3.516,4.259-5.55,6.157
                c-4.854,4.533-7.353,10.338-8.411,16.606c-0.646,3.83-2.154,6.861-4.507,9.736c-2.647,3.236-4.726,6.776-5.339,11.342
                C1080.024,342.825,1084.217,344.381,1087.553,348.241z M268.74,140.845c-0.921-2.629-0.727-5.23-0.634-7.914
                c0.178-5.143-0.142-10.314-0.493-15.458c-0.19-2.785-1.606-3.655-4.393-3.293c-3.355,0.436-4.401,1.449-5.303,4.926
                c-0.592,2.284-0.654,4.506-0.357,6.889c1.216,9.734,2.869,19.426,2.432,29.325c-0.294,6.65-0.064,13.324-0.05,19.987
                c0.003,1.478-0.227,3.012,0.961,4.245c1.829,1.897,3.372,4.089,5.9,5.877c1.45-4.345,2.699-8.368,2.376-13.271
                c-3.138,0.114-3.62-1.793-3.888-4.464c-0.298-2.972,1.53-3.942,3.352-4.74c-0.062-4.417-4.24-6.71-4.223-10.753
                c0.001-0.294-0.753-0.581-1.143-0.888c-3.322-2.621-3.263-4.404,0.199-6.702C265.124,143.518,267.126,142.946,268.74,140.845z
                M150.061,489.271c0.268,3.063,1.011,5.329,2.73,7.609c3.726,4.938,4.923,10.807,4.306,16.943
                c-0.198,1.963,0.378,3.107,2.072,3.996c4.195,2.204,8.338,4.506,12.484,6.758c2.407-2.289,3.493-4.704,3.496-8.018
                c0.002-2.831-0.923-5.605-0.681-8.217c0.283-3.057,0.675-5.232-3.07-5.869c-0.526-0.09-1.265-0.795-1.35-1.307
                c-0.49-2.971-3.3-2.871-5.301-3.959c0.271-2.678,0.137-5.607-2.548-6.479C158.425,489.506,154.577,487.361,150.061,489.271z
                M1163.705,386.007c-2.525,0.916-3.146,2.568-3.468,4.388c-0.464,2.622-0.833,5.26-1.281,7.884
                c-1.321,7.725-0.954,15.616-2.356,23.338c-0.98,5.405-1.453,10.839-1.351,16.38c0.128,6.975,0.026,13.958-1.02,20.927
                c-0.681,4.535-1.355,9.188-0.726,13.846c2.416-2.926,2.732-6.434,3.445-9.804c1.854-8.764,2.875-17.735,6.653-26.015
                c0.392-0.859,0.374-1.949,0.378-2.932c0.018-5.02,0.781-9.944,1.686-14.867c0.572-3.116,0.906-6.281-0.336-9.361
                c-1.399-3.466-1.683-7.095-1.637-10.789C1163.745,394.843,1163.705,390.683,1163.705,386.007z M950.463,409.398
                c1.165,1.973,3.504,3.659,1.736,6.48c-0.412,0.659,0.631,1.426,1.13,2.072c2.045,2.65,4.907,3.87,8.052,4.593
                c8.675,1.995,14.512,7.653,19.217,14.811c0.871,1.325,1.303,3.054,3.207,3.777c0.922-2.168,1.935-4.256,2.708-6.428
                c1.339-3.761-0.631-10.143-3.835-12.321c-3.357-2.282-6.157-5.042-8.258-8.523c-1.036-1.717-2.143-3.849-4.198-4.006
                c-6.233-0.479-12.427-2.007-18.737-1.017C951.186,408.883,950.92,409.14,950.463,409.398z M211.207,203.147
                c0.45-10.72,1.353-21.293,3.324-31.782c0.534-2.842,0.58-5.764,4.402-6.062c0-8.243,0.003-16.052-0.002-23.861
                c-0.001-1.356-0.029-2.95-1.516-3.386c-2.808-0.825-5.237-2.731-8.393-3.098c0,4.385,0.009,8.529-0.002,12.673
                c-0.028,10.332-1.565,20.553-2.465,30.812C205.786,187.212,209.251,194.948,211.207,203.147z M224.852,95.011
                c-3.792,0.833-4.656,1.838-4.632,5.475c0.028,4.329,0.263,8.656,0.38,12.984c0.041,1.489,0.069,2.947-0.699,4.364
                c-2.804,5.171-2.67,6.077,2.081,9.751c2.321,1.795,3.397,3.859,3.308,6.8c-0.146,4.826-0.06,9.658-0.045,14.488
                c0.003,1.313-0.337,2.78,0.659,3.839c2.418,2.573,2.279,5.75,2.395,8.917c0.084,2.298-0.229,4.655,0.77,6.987
                c1.096,0.308,2.191,0.585,3.354-0.156c0.81-1.707,0.338-3.55,0.266-5.346c-0.202-4.992-0.541-9.979-0.72-14.971
                c-0.136-3.791-0.063-3.793,3.21-5.546c0.015-0.74-0.385-1.309-0.948-1.651c-3.073-1.873-3.936-4.762-3.88-8.121
                c0.022-1.331-0.166-2.677-0.036-3.994c0.302-3.073-0.905-5.452-2.965-7.614c-1.159-1.216-2.804-2.179-3.238-4.42
                c2.496,0.1,3.265,2.001,4.61,3.037c5.537,4.263,2.408,10.89,4.271,16.716c1.734-2.328,1.823-4.754,2.948-6.676
                c1.773-3.03,0.761-5.306-1.591-7.426c-2.844-2.564-5.596-5.229-8.43-7.805c-1.139-1.034-2.142-2.024-2.016-3.766
                C224.271,105.777,223.269,100.617,224.852,95.011z M229.057,250.758c0,4.489,0,8.978,0,13.467
                c0.002,23.329,0.146,46.659-0.072,69.985c-0.076,8.143,3.959,14.755,6.416,21.989c0.446-0.579,0.578-1.057,0.578-1.535
                c0.013-16.83,0.014-33.66,0.011-50.49c0-0.832,0.13-1.729-0.127-2.484c-2.45-7.185-2.483-14.757-3.41-22.158
                C231.257,269.973,229.417,260.455,229.057,250.758z M190.498,78.333c1.383,3.138-0.987,6.507,2.181,8.531
                c3.116,1.991,3.451,4.59,2.902,7.752c-1.224,7.049-2.113,14.143-2.724,21.271c-0.127,1.484-0.442,2.973,0.622,4.303
                c2.956,3.695,3.014,7.966,2.381,12.358c-1.238,8.589-0.993,17.271-1.609,25.899c-0.311,4.352-0.021,4.499,4.692,6.041
                c0.346-2.325,0.757-4.613,1.019-6.917c1.435-12.61,0.618-25.274,0.805-37.914c0.019-1.274-0.403-2.674-1.001-3.817
                c-2.089-3.99-1.816-7.936-0.442-12.066c1.31-3.937,2.529-7.933,2.755-12.126c0.069-1.287,0.424-2.759-0.987-3.618
                c-1.969-1.198-2.75-3.273-3.943-5.055C195.754,80.892,194.125,79.08,190.498,78.333z M1121.46,299.803
                c-3.071,0.494-4.31,2.437-5.138,4.593c-0.83,2.164-1.568,4.416-1.933,6.696c-1.744,10.902-5.272,21.239-9.234,31.501
                c-1.652,4.28-3.63,8.154-6.729,11.504c-0.832,0.899-2.257,1.649-1.425,3.569c2.11,0.917,4.745,0.28,6.979,1.581
                c2.194,1.276,3.729,0.161,4.809-1.928c1.302-2.521,2.286-5.146,3.201-7.83c2.087-6.119,3.367-12.575,6.977-18.086
                c1.559-2.382,1.953-4.828,1.604-7.584c-0.649-5.136-0.642-10.268,0.186-15.412C1121.195,305.682,1121.223,302.89,1121.46,299.803z
                M858.681,526.74c-12.207,24.291-18.293,50.051-19.923,76.949c1.677,1.371,3.885,0.984,5.537,2.027
                c1.904-2.239,2.487-4.749,2.584-7.488c0.088-2.486,0.491-4.959,0.637-7.444c0.612-10.493,3.535-20.606,5.065-30.947
                c0.728-4.923,1.24-9.892,2.886-14.633C857.545,539.214,857.534,532.852,858.681,526.74z M478.835,491.603
                c-3.813-0.644-6.177-2.825-7.741-5.88c-0.787-1.539-2.037-2.207-3.597-2.199c-2.114,0.011-4.266-0.599-6.333,0.561
                c-4.172,2.337-8.63,3.576-13.41,4.326c-3.017,0.473-6.533,1.496-8.343,4.819c0.682,1.143,2.064,1.132,2.971,2.148
                c1.983,2.223,4.357,4.435,7.029,5.628c4.23,1.889,8.783,2.469,13.127-0.335c0.916-0.591,2.3-1.042,3.302-0.816
                c4.822,1.085,8.623-1.356,12.52-3.075C479.213,495.164,478.577,493.673,478.835,491.603z M946.668,498.945
                c-0.488-2.453-1.311-4.416-2.118-5.994c-1.255-2.45-2.339-5.307-5.656-6.518c-2.966-1.082-4.697-4.178-6.972-6.385
                c-2.497-2.423-5.84-3.303-8.809-4.752c-3.412-1.665-7.211-2.161-10.296-4.996c-1.655-1.521-5.038-0.59-7.587-1.453
                c-1.061-0.359-2.225,0.874-2.318,2.14c-0.38,5.07-2.348,9.748-3.972,15.261c2.019-1.596,3.734-2.078,5.227-3.184
                c5.831-4.32,13.282-3.457,18.149,1.857c0.65,0.709,1.07,1.914,2.238,1.746c3.715-0.537,4.404,2.123,5.19,4.723
                c0.522,1.727,0.796,3.547,1.901,5.053C936.627,496.545,941.32,498.142,946.668,498.945z M454.089,479.595
                c12.78,3.43,25.06,4.04,37.577-1.124c-2.41-3.649-4.527-7.028-8.142-9.048c-3.645-2.036-7.073-4.337-10.099-7.236
                c-2.904-2.782-6.579-3.062-10.58-3.425c0.482,2.084,1.021,3.672,1.193,5.3c0.52,4.883,0.026,9.312-5.278,11.688
                C457.163,476.467,455.5,477.379,454.089,479.595z M671.9,513.066c-1.205,0-2.117-0.244-2.814,0.041
                c-3.71,1.52-7.417,0.198-11.089,0.011c-5.313-0.271-10.512-0.476-15.021,2.976c-1.935,1.48-4.452,2.594-6.454,2.147
                c-7.368-1.644-13.139,1.203-18.806,5.338c0.833,1.104,1.859,0.864,2.828,0.984c7.244,0.895,14.643,1.257,21.542,3.703
                c4.664,1.654,8.4,0.614,12.137-1.863c4.847-3.214,9.676-6.455,14.447-9.778C669.82,515.823,671.334,515.26,671.9,513.066z
                M126.384,118.666c-1.239,10.814-3.51,21.034-4.451,32.878c7.074-5.611,14.556-1.612,21.12-3.849
                c0.866-2.916,0.609-5.615,1.028-8.218c0.389-2.408-0.381-4.162-2.17-5.701c-2.432-2.092-4.324-4.69-5.603-7.582
                C134.428,121.941,130.792,120.364,126.384,118.666z M922.314,487.36c-2.219-1.376-2.524-3.812-4.74-4.304
                c-4.409-0.982-8.707-1.588-12.579,1.914c-1.768,1.599-4.005,3.128-6.266,3.638c-4.542,1.024-6.508,3.874-7.749,7.982
                c-1.162,3.847-1.512,8.196-5.479,10.897c3.76,0.604,6.91,1.342,10.327-1.863c3.226-3.025,7.501-5.59,12.673-3.668
                c1.001,0.373,2.311,0.282,3.429-0.548C914.607,496.646,915.688,490.664,922.314,487.36z M909.628,468.288
                c1.86-6.711,2.528-12.845,3.104-18.916c0.586-6.165,2.453-11.722,5.802-16.772c3.427-5.169,2.252-10.7,2.505-16.17
                c-4.933-0.35-5.24-0.285-6.611,3.37c-2.863,7.63-5.526,15.369-7.252,23.315c-1.559,7.18-4.319,13.493-9.229,18.966
                c-1.585,1.766-3.259,3.728-3.292,6.606c2.685-0.009,5.336,0.609,7.521-0.677C904.617,466.576,906.813,466.576,909.628,468.288z
                M808.332,395.562c1.988-0.915,2.416-2.38,2.436-4.436c0.052-5.322,0.418-10.6-1.474-15.781c-0.658-1.802-0.586-3.964-0.466-5.939
                c0.336-5.559-0.553-10.944-1.947-16.274c-1.06-4.056-1.303-8.219-1.637-12.36c-0.166-2.043-0.295-3.754-2.228-5.166
                c-2.343-1.71-2.497-4.146-0.896-6.643c0.411-0.642,1.172-1.232,0.629-2.454c-3.113,1.195-5.9,2.662-7.482,6.57
                c4.619,3.032,3.93,7.887,3.725,12.537c-0.209,4.717,0.787,9.278,1.463,13.879c0.629,4.278,1.38,8.529,1.738,12.854
                c0.453,5.47,1.099,10.932,1.881,16.365C804.475,391.501,805.24,394.291,808.332,395.562z M620.915,400.065
                c0.93,0.99,2.272,1.968,3.117,3.422c2.001,3.443,5.673,5.437,7.804,8.843c0.768,1.227,2.248,1.902,3.944,0.929
                c1.403-0.805,2.38-1.67,2.268-3.468c-0.584-9.329,1.065-18.602,0.726-27.927c-0.066-1.826-0.122-3.653-0.171-5.48
                c-0.053-1.969,1.306-3.563,1.927-5.526c-4.514,0.483-7.543,3.023-8.818,7.746c-1.641,6.076-4.908,11.234-8.414,16.306
                C622.273,396.39,620.844,397.667,620.915,400.065z M77.391,279.234c-2.045,0.077-3.698,1.328-5.558,1.895
                c-2.883,0.878-3.938,2.78-4.151,5.908c-0.669,9.763-1.346,19.545-3.251,29.174c-0.278,1.403-0.439,2.926,0.662,3.705
                c2.859,2.024,5.125,4.961,8.635,6.196C76.077,317.837,78.365,288.321,77.391,279.234z M620.07,330.583
                c-0.052-6.408-5.394-11.663-11.813-11.621c-6.431,0.042-11.627,5.345-11.588,11.827c0.039,6.447,5.354,11.673,11.801,11.604
                C614.944,342.324,620.123,337.052,620.07,330.583z M1121.539,414.048c-3.333,6.085-6.528,12.193-7.501,19.219
                c-0.683,4.927-1.085,10.019-2.687,14.668c-4.648,13.503-7.609,27.526-12.823,40.844c-0.638,1.629-1.55,3.266-1.393,5.408
                c5.593-3.039,8.507-7.686,9.875-13.35c2.114-8.756,5.375-17.092,8.863-25.358c0.386-0.915,0.816-1.852,0.964-2.818
                C1118.791,439.875,1120.707,427.082,1121.539,414.048z M916.613,518.158c-4.01-1.557-5.943-5.893-10.57-5.141
                c-3.656-3.24-8.192-2.883-12.604-3.156c-7.087-0.44-12.068,1.456-17.241,6.805c-0.465,0.48-0.936,0.84-1.525,1.174
                c-4.054,2.291-4.729,5.429-1.986,9.161c1.153,1.569,2.43,3.046,3.775,4.722c4.038-6.157,9.42-10.591,15.019-14.768
                c4.292-3.202,4.913-2.951,7.005,2.025c0.109,0.262,0.492,0.409,1.041,0.84c4.771-0.472,9.166,1.104,12.553,4.998
                C914.354,523.001,915.114,520.818,916.613,518.158z M454.201,440.229c0.992-1.498,0.532-2.833,0.463-4.109
                c-0.872-16.304-1.94-32.599-2.563-48.912c-0.194-5.085-2.039-9.216-5.123-14.352c-2.518,21.612,1.636,42.216-1.349,62.438
                C448.664,437.482,451.281,439.139,454.201,440.229z M167.111,471.314c0.196-0.021,1.061,0.154,1.477-0.2
                c2.49-2.122,5.55-2.562,8.563-3.286c1.585-0.381,2.224-2.061,1.165-3.108c-2.014-1.995-2.986-4.683-5.107-6.612
                c-2.898-2.636-6.584-3.43-9.831-5.117c-6.892-3.581-14.038-2.479-21.105-3.086c-1.141-0.098-2.447,0.265-2.417,1.848
                c0.03,1.53,1.171,1.828,2.437,1.815c1.698-0.017,3.646-0.023,4.522,1.523c1.881,3.32,6.308,4.297,7.333,8.466
                c0.275,1.12,1.892,2.366,3.646,2.042c2.817-0.522,4.385,0.917,5.377,3.31C163.815,470.465,165.018,471.184,167.111,471.314z
                M995.366,473.153c1.356-0.858,1.411-2.429,1.82-3.794c1.177-3.926,2.899-7.223,6.939-9.213c2.525-1.244,4.086-3.859,3.81-7.021
                c-0.582-6.646-1.202-13.272-5.99-18.589c-0.758-0.841-1.358-1.949-1.676-3.038c-1.334-4.57-4.738-5.378-8.762-5.396
                c-0.515,1.713,0.266,2.934,0.756,4.058c3.368,7.724,3.791,15.724,2.846,23.971c-0.587,5.115-0.748,10.279-1.054,15.424
                C993.98,470.836,993.63,472.279,995.366,473.153z M532.527,390.747c1.076,2.234,2.547,2.258,4.078,2.099
                c1.354-0.14,2.558,0.007,3.778,0.823c1.928,1.289,4.271,2.042,6.001,3.528c4.467,3.837,9.312,2.551,14.217,1.588
                c1.542-0.303,2.521-1.249,2.383-2.713c-0.207-2.199,1.007-3.611,1.943-5.37c1.691-3.178,4.309-6.488,1.645-10.559
                c-1.554,0.685-2.941,1.249-4.288,1.899c-4.117,1.986-8.25,3.477-12.939,1.889c-1.723-0.584-3.633-0.542-5.367,0.335
                C540.137,386.21,536.29,388.148,532.527,390.747z M404.23,397.079c3.654-0.992,6.525-2.63,9.372-4.295
                c1.515-0.886,2.585-2.2,2.283-4.078c-0.603-3.754-0.643-7.765-2.423-11.107c-1.917-3.599-2.098-7.238-1.987-11.05
                c0.063-2.153,0.285-4.303,0.31-6.456c0.015-1.317-0.562-2.239-2.436-1.857c-0.329,4.069-2.763,7.103-5.536,10.005
                c-3.173,3.322-4.53,7.186-3.282,11.852c0.722,2.697,1.283,5.487-0.035,8.26c-0.535,1.125-0.104,2.297,0.49,3.326
                C401.969,393.384,403.01,395.055,404.23,397.079z M32.676,331.508c0.193,2.558,2.804,3.356,3.046,5.334
                c0.31,2.531,1.614,3.848,3.95,4.409c2.014,0.484,3.468,2.64,5.979,1.758c2.015-0.707,3.475,0.363,4.608,2.034
                c0.531,0.782,0.709,1.932,2.192,2.174c4.592-2.519,7.191,1.357,10.705,4.299c-0.753-5.822-1.284-11.084-2.17-16.287
                c-0.454-2.669-2.379-4.141-5.167-4.665c-3.457-0.649-6.994-0.176-10.403-0.867C40.992,328.803,36.889,329.531,32.676,331.508z
                M116.033,95.462c-2.492,17.761,0.025,35.34,0.605,53.032c2.477-0.341,3.51-1.661,3.85-3.65c0.363-2.13,0.814-4.251,1.055-6.395
                c1.284-11.462,4.468-22.698,1.835-34.552C122.314,99.109,120.886,96.387,116.033,95.462z M105.934,244.872
                c0-4.448-0.195-8.761,0.06-13.048c0.211-3.547-1.008-6.064-4.021-7.815c-4.485-2.607-6.017-6.72-6.213-11.681
                c-0.289-7.302-0.8-14.595-1.191-21.894c-0.133-2.491-0.581-4.75-3.73-6.143c0,4.183-0.123,7.83,0.023,11.466
                c0.465,11.623,1.774,23.177,2.927,34.745c1.077,10.798,1.039,10.803,11.182,14.539
                C105.104,245.091,105.291,244.992,105.934,244.872z M1152.133,286.363c-2.07,2.346-4.9,4.525-4.67,7.85
                c0.307,4.448,1.231,8.909,2.4,13.227c0.979,3.615,4.977,5.653,8.545,4.75c2.117-0.535,4.287-0.094,5.993-0.463
                c3.89-4.007,3.454-7.949,0.972-12.081c-1.195-1.992-2.303-4.075-3.137-6.238C1160.456,288.788,1156.906,286.917,1152.133,286.363z
                M603.449,393.903c-4.528-3.464-28.173-12.655-34.44-13.37c1.432,4.027-0.858,7.337-2.023,11.16
                c9.875,2.596,18.82,7.078,28.86,9.084C598.316,398.544,600.85,396.253,603.449,393.903z M233.679,227.621
                c0-3.289,0.193-5.96-0.043-8.593c-0.393-4.376,0.418-8.322,3.5-11.559c1.529-1.605,1.772-3.474,1.77-5.515
                c-0.006-4.669,0.414-9.291,1.077-13.928c0.793-5.55,0.991-11.174,0.376-16.817c-4.068-0.633-7.634-0.58-11.196,0.161
                c0,4.231,0.018,8.207-0.01,12.182c-0.008,1.241,0.489,2.225,1.205,3.188c2.292,3.087,2.828,6.641,2.893,10.392
                c0.07,4.052-0.726,7.973-1.623,11.85C230.187,215.209,230.581,221.165,233.679,227.621z M927.018,340.094
                c-2.785,6.614-5.69,12.924-8.854,19.112c-0.75,1.468-1.04,3.11-1.215,4.794c-0.772,7.432-1.67,14.85-2.498,22.276
                c-0.12,1.069-0.78,2.252,0.397,3.139c2.416,0.195,3.133-1.252,3.531-3.262c1.065-5.372,2.312-10.709,3.345-16.087
                c0.445-2.315,1.426-4.233,3.672-4.908c2.559-0.769,3.516-2.502,3.43-4.818c-0.142-3.79,1.821-6.218,4.647-8.277
                c1.583-1.154,3.083-2.421,4.75-3.74C934.955,345.116,931.623,342.24,927.018,340.094z M819.166,444.532
                c-4.051-0.311-5.805,2.34-8.245,3.588c-4.002,2.047-5.3,1.335-5.662-3.121c-0.317-3.896-1.839-5.065-5.509-3.685
                c-3.26,1.227-6.406,2.687-9.832,3.521c-2.104,0.512-2.557,2.912-0.722,4.081c2.419,1.541,3.099,3.747,3.628,6.221
                c0.428,1.995,1.178,4.049,2.789,5.179c2.785,1.952,4.299,4.759,5.986,8.029c0.816-1.854,1.445-3.2,2.006-4.573
                c1.742-4.265,3.977-8.072,8.615-9.802c1.11-0.415,2.081-1.112,2.545-2.262C815.759,449.248,817.361,447.135,819.166,444.532z
                M1191.366,344.512c0.111-3.08-4.899-14.699-6.63-16.321c-0.475-0.444-1.055-0.874-1.662-1.06
                c-2.428-0.741-2.998-2.604-3.625-4.831c-2.385-8.464-5.553-16.659-9.298-24.62c-1.192-2.536-2.979-4.457-5.82-5.593
                c-0.186,1.875,0.744,2.92,1.374,4.021c3.293,5.75,5.58,11.49,0.162,17.276c3.297,5.168,5.502,10.623,10.022,14.534
                c1.708,1.478,2.485,3.432,2.802,5.663c0.229,1.61-0.073,3.355,1.111,4.557C1183.096,341.263,1188.212,340.852,1191.366,344.512z
                M204.943,89.676c-1.162,5.016-2.003,10.062-3.791,14.87c-1.346,3.62-1.396,7.109,0.458,10.597c0.696,1.31,1.22,2.749,1.177,4.293
                c-0.359,12.97,0.996,26.018-1.033,38.894c-0.92,5.836-0.791,11.05,3.022,16.114c1.589-14.18,3.377-28.119,1.546-42.25
                c-0.819-6.32-1.243-12.662,4.488-17.476c1.648-1.385,1.279-3.073-0.831-3.742c-3.155-1-3.942-3.339-4.267-6.07
                c-0.174-1.469,0.046-2.979,0.007-4.469C205.628,96.877,206.517,93.269,204.943,89.676z M502.117,492.957
                c7.852,11.562,18.186,19.512,27.449,28.57c-3.463-13.844-10.401-26.108-15.431-39.199c-3.629,0.306-6.631,0.559-10.647,0.896
                c2.434,1.842,3.891,3.051,5.747,3.79c1.482,0.589,2.588,1.729,2.138,3.464c-0.417,1.607-1.75,2.388-3.379,2.463
                C506.376,493.016,504.753,492.957,502.117,492.957z M587.229,519.31c1.029,1.009,1.985,1.952,3.205,1.39
                c2.661-1.226,5.23-0.362,7.838,0.018c4.281,0.621,8.557,1.285,12.847,1.834c1.917,0.246,3.582-0.023,5.587-0.895
                c3.589-1.558,6.082-5.11,10.467-5.254c2.786-0.092,5.557-0.738,8.394-0.447c2.685,0.274,5.243,0.051,7.008-2.518
                c-4.006-1.498-7.843-2.959-12.145-2.574c-1.598,0.143-3.193-0.36-4.745-1.199c-2.583-1.396-5.253-1.277-8.09-0.037
                c-2.479,1.084-4.983,2.541-7.999,1.951c-1.318-0.258-2.419,0.69-3.589,1.4c-3.63,2.201-7.546,4.015-11.75,4.585
                C591.931,517.879,589.68,518.111,587.229,519.31z M1126.174,171.89c7.266,0.383,12.379,4.126,18.112,6.225
                c4.617,1.689,7.829,4.874,10.325,8.973c2.534,4.162,4.607,8.533,6.143,13.217c0.652-5.194,1.07-10.312,1.403-15.436
                c0.382-5.875-3.174-9.929-6.622-14.012c-0.704-0.834-1.696-0.903-2.717-0.973c-5.641-0.389-11.277-0.833-16.917-1.229
                C1132.646,168.426,1129.655,169.141,1126.174,171.89z M1095.127,355.114c1.97-2.431,4.33-4.494,5.6-7.089
                c2.668-5.455,5.562-10.883,3.569-17.531c-1.731-5.777-1.255-11.86-0.974-17.852c0.047-1.004,0.676-2.185-0.457-3.282
                c-1.396,1.778-1.827,3.746-2.508,5.563c-1.396,3.73-2.163,7.77-4.016,11.24c-3.122,5.851-4.357,12.097-5.685,18.466
                C1089.713,349.164,1090.396,352.419,1095.127,355.114z M92.368,164.089c-0.174,4.473-0.097,8.296-0.539,12.059
                c-0.354,3.005-0.021,5.551,2.176,7.752c1.738,1.742,2.178,3.924,2.294,6.36c0.374,7.8,1.023,15.586,1.428,23.384
                c0.263,5.063,3.089,7.955,7.538,10.052c0-3.7-0.145-7.018,0.031-10.318c0.317-5.95-0.731-11.528-3.745-16.725
                c-2.326-4.01-2.813-8.367-2.589-12.97c0.264-5.449,0.062-10.92,0.062-16.715C97.502,165.958,95.469,164.934,92.368,164.089z
                M208.169,124.297c-0.499,7.139-0.36,7.4,5.368,10.09c0.901,0.423,1.853,0.741,2.788,1.088c4.145,1.538,4.573,2.029,4.611,6.527
                c0.065,7.661,0.038,15.323,0.013,22.984c-0.006,1.848,0.022,3.63,1.068,5.282c3.417,5.394,3.443,11.18,2.493,17.323
                c-1.498,9.685-2.804,19.428-2.037,29.307c0.316-0.899,0.412-1.828,0.543-2.752c1.401-9.846,0.781-19.968,4.059-29.564
                c0.255-0.747,0.09-1.652,0.056-2.481c-0.315-7.82-0.653-15.639-0.96-23.459c-0.054-1.366-0.156-2.783-1.219-3.69
                c-1.96-1.673-1.66-3.839-1.648-5.993c0.027-4.83-0.01-9.66,0.061-14.489c0.031-2.157-0.53-3.98-2.441-5.121
                c-3.179-1.898-5.164-4.523-5.056-8.846C213.028,121.903,210.666,123.067,208.169,124.297z M808.163,447.287
                c4.472-3.375,10.295-4.19,15.38-6.568c5.249-2.453,10.512-4.555,16.851-5.548c-4.669-2.887-6.823-3.44-11.44-3.13
                c-3.656,0.246-7.331,0.934-10.961,0.739c-9.881-0.53-18.602,3.045-27.259,7.04c-1.128,0.521-2.058,1.132-1.231,2.824
                c1.811,0.381,3.475-0.533,5.146-1.097c1.728-0.583,3.314-1.602,5.056-2.115c4.555-1.342,6.949,0.349,7.426,5.058
                C807.209,445.286,806.952,446.151,808.163,447.287z M927.029,391.535c1.91-1.417,2.503-4.168,5.049-4.686
                c2.008-0.408,2.525-1.822,2.899-3.553c0.839-3.888,1.693-7.778,2.729-11.616c0.679-2.518-0.785-4.047-2.541-4.74
                c-2.597-1.024-5.491-1.255-8.289-0.428c-1.483,0.438-2.732,1.248-3.084,2.928c-1.351,6.46-2.696,12.921-4.065,19.484
                C922.428,389.89,924.73,390.713,927.029,391.535z M1176.207,376.505c-0.207,14.174,3.12,27.37,8.309,40.223
                c2.489-14.009,1.964-28.065,2.193-42.665C1182.932,374.639,1179.646,374.713,1176.207,376.505z M1044.392,439.527
                c-0.838,1.349-0.565,2.369-0.558,3.349c0.077,8.824,0.158,17.648,0.271,26.472c0.035,2.848,0.105,5.75-1.195,8.33
                c-3.93,7.799-0.245,14.883,1.71,22.197c1.833-6.482,3.196-12.971,4.479-19.475c0.154-0.783-0.054-1.664-0.211-2.477
                c-2.054-10.604,0.145-20.868,2.478-31.118c0.29-1.271,1.097-2.463,0.349-3.938C1049.6,441.39,1047.17,440.403,1044.392,439.527z
                M190.35,457.062c-0.81,2.258-0.757,4.416-0.789,6.553c-0.02,1.316,0.08,2.577,1.084,3.697
                c5.555,6.197,9.513,13.764,16.345,19.006c2.008-1.553,4.898,0.152,6.858-1.562c-0.021-3.097-3.498-3.86-3.844-6.356
                c-0.34-2.451-0.304-4.952-1.178-7.317c-0.172-0.467-0.286-0.986-0.573-1.373c-1.751-2.358-3.157-5.187-6.82-4.968
                c-2.159,0.13-3.398-1.231-4.105-3.023C196.054,458.49,193.473,457.479,190.35,457.062z M103.478,150.968
                c-0.925,0.62-1.005,1.323-1.033,1.962c-0.51,11.81-1.113,23.617-1.415,35.433c-0.098,3.846,1.969,7.138,4.52,11.346
                c1.893-16.002,3.653-30.889,5.443-46.021C108.09,152.792,105.899,151.282,103.478,150.968z M444.081,391.753
                c0.365-8.809,0.648-17.623,1.14-26.424c0.165-2.94-0.295-5.614-1.682-8.173c-3.013-5.561-5.986-11.145-9.049-16.678
                c-0.489-0.884-0.667-2.327-2.538-2.108c0,3.849,0.112,7.679-0.042,11.498c-0.093,2.307,0.481,4.228,1.984,5.946
                c4.319,4.938,6.08,10.708,4.882,17.133C437.462,380.002,440.581,385.822,444.081,391.753z M1109.985,359.084
                c0.843,1.291,1.794,2.083,2.366,1.673c3.48-2.488,8.453-3.269,9.702-8.396c0.358-1.474,1.315-3.156,2.828-3.202
                c4.659-0.141,5.728-3.779,7.577-6.846c-2.764-4.644-4.915-9.613-10.844-11.624
                C1115.518,339.351,1115.035,350.184,1109.985,359.084z M349.314,152.431c-2.167,0.98-3.913,1.402-3.673,4.21
                c1.087,12.724,2.009,25.462,2.942,38.199c0.219,2.984,1.396,5.206,4.089,6.527c1.402-1.394,1.379-2.926,1.358-4.409
                c-0.078-5.486,0.306-10.946,0.847-16.396c0.45-4.534,0.371-8.968-2.117-12.996c-1.41-2.282-0.944-4.224,1.05-5.685
                C352.231,158.562,350.825,155.607,349.314,152.431z M158.835,559.681c-8.393-16.068-20.232-26.477-39.141-27.054
                c1.495,4.09,1.549,3.969,4.207,5.152c3.307,1.471,5.527,4.848,9.37,5.461c0.245,0.039,0.473,0.46,0.625,0.748
                c0.97,1.83,2.096,3.195,4.517,3.25c1.524,0.033,2.454,1.554,3.404,2.73c1.346,1.665,2.342,3.626,4.007,4.922
                c5.103,0.199,5.162,0.143,8.386,3.52C155.479,559.738,156.676,560.582,158.835,559.681z M390.177,555.031
                c-1.762-5.326-3.005-9.817-4.751-14.104c-1.213-2.979-3.643-5.528-1.413-9.077c0.401-0.639-0.145-1.938-0.382-2.898
                c-1.911-7.74-4.216-15.367-6.85-22.889c-1.506-4.301-6.575-10.386-10.676-12.971c0.973,1.641,1.708,3.142,2.677,4.472
                c3.148,4.321,4.844,9.007,4.242,14.441c-0.356,3.218,0.608,6.218,1.838,9.175c2.104,5.057,4.073,10.169,6.098,15.258
                c0.615,1.547,1.486,3.175,0.704,4.793c-2.151,4.45-0.449,8.35,1.451,12.412C385.147,554.041,387.197,554.445,390.177,555.031z
                M588.334,521.975c-5.52-0.945-10.398,0.074-15.097-0.204c-1.623,2.793-0.987,5.608-1.785,8.114
                c-1.585,4.979-0.3,9.055,3.672,12.383c1.685,1.412,2.672,3.918,6.081,3.434C582.287,537.773,587.117,530.868,588.334,521.975z
                M1028.812,490.274c1.225-10.476,0.894-20.38,4.421-29.683c0.223-0.587,0.067-1.316,0.092-1.979
                c0.136-3.647,0.262-7.294,0.415-10.941c0.236-5.651-0.719-6.742-6.908-7.374c-1.15,3.368-1.724,6.893-1.635,10.546
                c0.228,9.295,0.565,18.588,0.635,27.883C1025.86,482.461,1026.461,485.941,1028.812,490.274z M1054.469,484.717
                c2.085-3.908,2.98-7.848,3.609-11.889c1.244-7.996,0.055-16.326,4.227-24.987c-3.934,1.214-5.871-0.985-7.912-2.523
                c-1.527,1.407-1.654,3.001-1.934,4.406c-1.162,5.865-2.211,11.768-2.498,17.748
                C1049.674,473.467,1050.174,479.35,1054.469,484.717z M983.481,545.756c-1.425-2.908-3.556-4.031-6.583-2.953
                c-0.954,0.34-1.922-0.056-2.905-0.306c-2.063-0.524-4.19-0.798-6.29-1.173c2.423,3.404,5.092,6.226,6.682,9.648
                c1.561,3.363,3.881,4.074,7.17,3.668c7.217-0.895,14.261-0.139,21.066,2.517c0.602,0.234,1.194,0.636,1.846,0.221
                c-2.115-3.688-3.525-7.83-8.527-9.063C991.865,547.312,988.6,543.06,983.481,545.756z M1032.559,322.028
                c1.927-3.533,4.921-6.27,5.645-9.971c1.288-6.585,1.924-13.298,2.938-20.733c-2.535,1.448-4.318,2.821-6.786,1.605
                c-2.638,8.139-8.75,14.227-11.136,22.92C1028.291,315.187,1029.658,319.258,1032.559,322.028z M1040.209,478.325
                c2.109-2.758,1.979-5.296,1.975-7.733c-0.012-8.474-0.117-16.948-0.215-25.422c-0.021-1.827-0.129-3.655-0.248-5.479
                c-0.135-2.05-1.012-3.595-3.264-3.674c-2.97-0.104-5.34-1.62-7.857-2.901c-3.707-1.884-6.879-0.535-9.033,3.812
                c2.732,1.377,5.81,1.2,8.66,1.699c3.244,0.569,4.581,2.226,5.01,5.19c0.215,1.489,0.264,2.95,0.166,4.459
                c-0.19,2.983-0.203,5.979-0.236,8.97c-0.011,0.961-0.213,2.071,0.812,2.702c3.76,2.316,3.426,6.199,3.643,9.78
                C1039.782,472.365,1039.565,475.023,1040.209,478.325z M663.971,388.043c1.988,0.001,2.99-0.974,4.172-1.541
                c3.621-1.739,5.242-4.288,5.045-8.598c-0.263-5.778-0.578-11.632,0.469-17.399c0.869-4.794,0.537-9.575,0.266-14.26
                c-2.619-1.24-2.629-1.195-5.277-0.832C667.494,359.553,666.561,373.736,663.971,388.043z M286.21,425.843
                c-0.133,0.796-0.298,1.143-0.224,1.427c2.395,9.174,4.802,18.346,7.246,27.508c0.21,0.787,0.497,1.726,1.071,2.215
                c4.216,3.597,8.673,6.871,13.664,10.472c-0.678-6.799-1.567-8.991-5.186-12.184c-1.476-1.302-2.107-2.947-2.772-4.638
                c-2.557-6.504-5.208-12.975-7.579-19.547C291.345,428.089,289.479,426.438,286.21,425.843z M758.568,366.874
                c-1.878,4.852-2.301,9.346-3.036,13.758c-1.258,7.547-2.854,15.046-2.915,22.764c-0.027,3.42,1.199,5.986,4.277,8.686
                c1.402-16.03,6.334-30.771,7.777-46.488C762.473,366.14,760.853,367.678,758.568,366.874z M39.232,208.548
                c-0.087,0.982-0.171,1.966-0.262,2.948c-1.32,14.264-1.755,28.573-2.137,42.884c-0.089,3.321-0.64,6.618-0.23,9.968
                c1.036,8.471,0.891,16.948-0.301,25.392c-0.339,2.399,0.125,4.251,2.791,5.693c0.844-8.449,1.988-16.635,1.371-24.953
                c-0.578-7.791-1.798-15.586-0.286-23.389c0.732-3.778,1.049-7.587,1.175-11.409C41.655,226.581,41.28,217.517,39.232,208.548z
                M766.85,438.694c3.001-0.792,4.076-2.104,4.134-4.444c0.138-5.479,1.13-10.901,1.062-16.404
                c-0.041-3.317,0.168-6.631,0.633-9.937c0.372-2.646,1.338-4.897,3.701-6.135c2.055-1.076,2.619-2.67,2.903-4.781
                c0.935-6.949,0.412-13.922,0.53-20.884c0.008-0.47-0.366-0.892-1.297-0.712c-0.039,3.042-0.264,6.164-1.65,9.057
                c-4.632,9.671-7.4,19.858-8.418,30.532C767.709,422.731,766.842,430.467,766.85,438.694z M1157.717,391.083
                c-2.342,0.084-2.672,1.74-3.021,3.413c-0.849,4.076-1.714,8.151-1.694,12.342c0.051,10.537-1.204,20.916-3.657,31.162
                c-0.423,1.764-1.05,3.627-0.332,5.407c1.644,4.073,0.99,8.206,0.505,12.327c-0.763,6.467-1.188,12.936-0.44,19.428
                c0.093,0.805-0.397,2.158,1.571,2.131c0.3-3.42,0.57-6.866,0.908-10.306c0.945-9.602,2.303-19.173,2.07-28.86
                c-0.06-2.495-0.352-5.031-0.025-7.48c1.472-11.053,2.527-22.146,3.565-33.243C1157.363,395.31,1158.373,393.27,1157.717,391.083z
                M1082.422,250.664c2.235-2.037,2.776-4.831,3.48-7.471c1.841-6.903,3.01-14.027,5.371-20.738
                c3.439-9.772,3.371-19.932,4.508-29.97c0.021-0.185-0.408-0.42-0.68-0.682c-1.466,0.071-2.808,0.631-4.087,1.511
                c-0.71,2.413-0.698,4.92-1.026,7.382c-1.098,8.218-0.827,16.731-3.984,24.528C1082.665,233.468,1083.467,242.133,1082.422,250.664
                z M226.59,911.297c-8.582-2.082-16.415-5.516-24.803-6.248c-0.935,2.181-0.125,3.613,0.382,4.925
                c1.565,4.042,2.593,7.88-1.924,10.762c3.292,2.788,4.53,3.118,7.128,1.332c4.151-2.853,8.493-5.12,13.428-6.361
                C222.968,915.162,225.004,913.886,226.59,911.297z M119.763,150.211c-4.894,1.278-7.086,3.941-7.538,8.81
                c-1.091,11.741-2.696,23.434-4.055,35.151c-0.055,0.47-0.482,1.316,0.878,1.525c2.854-5.912,6.502-11.7,7.688-18.172
                C118.371,168.604,118.798,159.459,119.763,150.211z M770.215,393.368c2.045-1.196,2.178-3.127,2.934-4.74
                c2.346-5.002,4.096-10.109,3.562-15.815c-0.178-1.898-0.281-4.034,1.07-5.744c0.287-0.362,0.766-0.572,0.931-0.69
                c1.729-0.31,2.523,1.34,4.194,1.162c-1.088-3.892-1.18-7.668,0.03-11.664c0.716-2.362,0.597-5.361-1.19-7.996
                c-1.782,2.992-0.482,7.039-4.199,8.929c-2.289-4.007-1.082-7.946-0.863-11.866c0.222-3.96,0.049-7.942,0.049-11.915
                c-0.35-0.017-0.699-0.035-1.049-0.052C775.312,353.249,773.112,373.337,770.215,393.368z M845.827,550.014
                c0.296,0.146,0.591,0.294,0.886,0.441c1.809-4.963,3.527-9.961,5.449-14.879c2.119-5.423,3.994-11.076,7.748-15.591
                c4.844-5.824,5.602-13.261,8.799-19.765c-7.524,6.314-13.275,13.924-18.023,22.334c-0.161,0.285-0.283,0.612-0.338,0.935
                C848.834,532.33,847.332,541.172,845.827,550.014z M842.586,315.934c-2.758,5.82-4.307,11.311-2.773,17.58
                c1.789,7.313,1.672,14.855,1.262,22.372c-0.254,4.655-0.314,9.327-0.265,13.989c0.03,2.863,0.298,5.759,1.761,8.448
                c1.589-0.932,1.251-2.022,1.23-2.919c-0.088-3.829-0.215-7.659-0.426-11.484c-0.424-7.664-0.701-15.29,1.374-22.827
                c0.655-2.379,1.157-5.027,0.8-7.414c-0.932-6.229,0.678-11.853,3.074-17.417c0.075-0.174-0.25-0.521-0.454-0.913
                C846.422,315.531,844.625,315.72,842.586,315.934z M988.147,426.512c2.087,5.47-0.377,10.124-2.242,14.882
                c-0.742,1.894-1.006,3.858-0.7,5.886c0.917,6.088,1.869,12.171,2.725,18.269c0.313,2.232,1.428,3.573,3.418,4.158
                c0.861-0.536,0.786-1.233,0.838-1.854c0.52-6.307,1.048-12.613,1.525-18.923C994.316,440.919,992.029,433.592,988.147,426.512z
                M795.156,336.299c0,6.539-0.026,12.496,0.031,18.453c0.008,0.791,0.315,1.651,0.708,2.353c1.367,2.442,1.439,5.041,1.289,7.738
                c-0.249,4.478-1.046,9.143,0.128,13.405c1.873,6.799,2.576,13.665,3.09,20.613c0.303,4.092,3.254,6.758,5.459,9.804
                c0.383,0.529,1.223,0.325,2.109-0.097c0.368-3.36-0.694-6.753-0.875-10.196c-0.029-0.578-0.464-1.279-0.926-1.667
                c-3.221-2.701-3.855-6.467-4.312-10.29c-1.043-8.734-1.835-17.504-3.117-26.202c-0.897-6.085-1.953-12.108-1.626-18.299
                C797.205,340.208,797.616,338.237,795.156,336.299z M574.749,515.376c-7.882-2.908-14.85-1.808-21.923,0.647
                c1.591,4.098,3.143,7.58,7.431,9.119c2.334,0.837,3.976,2.897,5.41,4.989c0.809,1.179,1.342,2.707,3.045,3.042
                C571.169,527.475,568.809,520.322,574.749,515.376z M1189.14,518.107c7.667,3.512,14.261,3.145,20.815-1.026
                c-2.615-3.397-5.065-6.108-9.458-6.178c-1.586-0.024-3.247-0.72-4.72-1.429c-3.581-1.725-7.385-2.387-11.283-2.881
                C1183.741,511.689,1188.014,514.588,1189.14,518.107z M280.597,120.372c0.853,0.732,1.61,1.64,2.574,2.169
                c5.161,2.832,6.658,7.089,6.255,12.868c-0.501,7.177-0.12,14.416-0.12,21.627c4.612-10.202,4.748-21.624,10.605-32.335
                C292.543,124.792,287.238,120.454,280.597,120.372z M640.221,409.909c4.086-5.223,6.408-9.839,5.859-15.523
                c-0.435-4.502,0.289-8.925,1-13.358c0.613-3.824,1.262-7.688,0.315-11.651c-5.942,2.332-7.84,6.346-6.883,12.319
                c0.516,3.22-0.058,6.616-0.146,9.932C640.212,397.357,639.389,403.087,640.221,409.909z M657.986,341.928
                c-0.82,0.533-1.18,0.645-1.318,0.885c-0.24,0.419-0.438,0.906-0.489,1.383c-0.731,6.862-2.854,13.315-5.784,19.519
                c-0.743,1.575-0.819,3.188-0.814,4.862c0.019,5.666-0.231,11.303-1.317,16.896c-0.679,3.491-0.418,7.006-0.054,10.672
                c2.484-0.833,3.057-2.356,3.41-4.028c0.824-3.904,1.828-7.781,2.424-11.72c1.314-8.702,1.391-17.548,3.033-26.228
                C657.811,350.284,658.441,346.328,657.986,341.928z M1176.213,336.198c1.271-3.237,0.209-5.28-1.57-6.9
                c-3.383-3.081-5.697-6.88-7.869-10.818c-0.639-1.159-1.38-2.273-2.173-3.335c-1.628-2.181-3.842-1.538-5.928-1.02
                c-2.158,0.537-1.946,2.376-1.961,4.041c-0.041,4.73,2.847,8.381,4.463,12.905C1167.042,330.06,1171.941,332.134,1176.213,336.198z
                M936.57,385.413c1.154,0.874,2.377,0.751,3.482,0.53c4.773-0.956,8.316,0.785,11.438,4.388c2.188,2.525,4.942,4.56,7.443,6.813
                c0.266-0.191,0.532-0.382,0.799-0.573c-1.275-1.369-0.229-3.336-1.457-4.604c-4.325-4.467-6.768-10.163-10.04-15.304
                c-2.142-3.364-4.927-4.5-8.798-4.47C938.348,376.744,937.104,381.029,936.57,385.413z M619.323,541.866
                c8.734-2.595,15.479-7.683,24.155-11.062c-8.348-3.197-15.91-3.292-23.607-4.484C619.146,531.544,619.274,536.311,619.323,541.866
                z M1156.223,333.753c2.986-2.169,3.064-2.202,2.129-4.464c-1.77-4.279-4.16-8.363-3.534-13.295
                c0.209-1.65-1.261-2.249-2.369-2.886c-3.321-1.912-4.82-4.863-5.306-8.538c-0.346-2.619-0.877-5.214-1.405-8.289
                c-2.057,1.883-1.746,3.625-1.707,5.237c0.081,3.323,0.729,6.697,0.341,9.954c-0.809,6.76,3.572,11.176,6.987,15.806
                C1152.824,329.267,1155.131,330.804,1156.223,333.753z M923.919,585.959c1.919-2.259,1.535-4.002-0.16-5.562
                c-2.806-2.581-4.112-5.915-5.283-9.449c-1.2-3.62-2.896-7.077-4.405-10.593c-1.074-2.502-2.904-3.767-5.766-3.645
                c1.258,10.053,2.539,19.747,8.984,27.756C920.787,582.739,920.787,582.739,923.919,585.959z M480.359,438.43
                c-1.574,4.54-0.908,8.714-0.91,12.802c-0.003,4.306-0.564,8.576-0.844,13.048c4.215,2.691,8.656,4.831,12.39,9.277
                c-0.282-6.673-0.808-12.235-4.423-16.984c-1.549-2.035-2.154-4.408-2.79-6.845C482.835,446.099,482.391,442.282,480.359,438.43z
                M542.364,441.609c6.059-3.544,11.835-7.105,17.791-10.336c3.39-1.839,4.848-4.696,5.9-8.125
                C554.897,421.476,542.39,431.087,542.364,441.609z M912.704,512.78c1.561,2.333,4.071,2.748,5.665,4.677
                c3.044-1.652,6.186-1.762,9.345-1.283c2.465,0.373,4.886,1.033,7.351,1.407c1.874,0.284,3.911,1.071,5.775-1.029
                c-2.733-4.616-6.4-7.447-11.951-7.124c-1.137,0.066-2.271,0.72-3.332-0.123c-3-2.385-5.804-1.166-8.09,0.9
                C916.137,511.405,914.463,511.469,912.704,512.78z M209.212,121.131c2.628-0.48,4.344-1.917,6.351-2.664
                c2.373-0.883,3.293-2.494,3.079-5.026c-0.28-3.306-0.329-6.632-0.476-9.934c-4.551-1.588-5.428-7.174-10.17-8.68
                c-0.199,2.959-0.61,5.78-0.508,8.582c0.082,2.241,0.274,4.499,3.124,5.645c3.893,1.566,4.25,4.613,1.117,7.636
                C210.519,117.856,209.216,118.818,209.212,121.131z M266.03,288.169c-1.613,0.454-1.668,1.513-1.85,2.424
                c-1.562,7.829-2.911,15.702-5.68,23.245c-0.434,1.183-0.265,2.629-0.201,3.945c0.5,10.315,1.033,20.628,1.59,30.94
                c0.056,1.046-0.308,2.253,0.811,3.362c3.543-3.422,3.81-7.4,2.877-11.616c-2.993-13.52-3.616-26.999,0.128-40.516
                C264.753,296.172,265.245,292.235,266.03,288.169z M131.231,82.885c2.446,6.789,3.337,13.197,4.369,19.576
                c0.457,2.826,1.633,5.123,4.097,6.68c3.104-4.879,3.79-9.556,1.786-14.826C139.602,89.368,138.953,83.723,131.231,82.885z
                M118.735,725.099c0.643,3.187,0.544,3.446,1.716,3.892c3.096,1.174,4.887,3.822,7.25,6.348c5.416-1.85,10.755-3.672,16.093-5.494
                c-3.619-1.711-7.408-0.83-10.431-2.441c-0.509-1.336,1.087-2.068-0.057-3.26C128.539,723.981,123.628,724.203,118.735,725.099z
                M325.42,173.485c4.597-4.765,5.924-10.013,5.507-15.838c-0.283-3.957-2.302-5.78-6.379-5.711c-1.395,0.023-2.878-0.234-4.413,0.6
                C323,159.194,323.427,166.212,325.42,173.485z M1051.091,330.541c2.89-10.205,5.667-20.014,8.444-29.822
                c-7.348,7.688-9.752,18.073-15.703,26.831C1046.354,328.85,1048.356,329.922,1051.091,330.541z M486.153,485.258
                c3.432,0.825,6.885,0.918,8.089,4.949c0.341,1.142,1.616,1.559,2.919,1.355c3.53-0.549,7.058-1.043,10.652-0.544
                c0.568,0.079,1.346-0.134,1.484-1.368c-1.847-1.217-4.297-2.156-5.742-3.92c-3.21-3.917-7.235-4.599-11.905-4.641
                C488.585,481.062,487.014,482.175,486.153,485.258z M831.533,319.665c3.634,12.437,4.18,25.186,7.057,37.518
                c1.223-6.822,1.043-13.594-0.107-20.35c-1.092-6.405-2.242-12.803,1.172-18.938c0.221-0.398,0.146-0.98-0.4-1.422
                C836.584,316.598,834.325,317.91,831.533,319.665z M1087.566,309.222c-3.064-0.01-3.744,1.306-4.128,2.829
                c-1.848,7.333-6.382,13.105-10.915,18.88c-1.63,2.076-3.354,4.087-4.701,6.769c1.686,1.239,3.311,2.249,5.724,3.113
                c1.466-5.993,4.958-10.613,8.534-15.185c0.302-0.386,0.631-0.843,0.699-1.304C1083.523,319.246,1084.711,314.293,1087.566,309.222
                z M710.172,456.349c-1.684-7.616-4.343-9.11-11.205-6.686c-4.254,1.503-7.4,4.261-10.071,8.077
                C696.219,458.381,702.966,456.539,710.172,456.349z M1136.067,228.262c0.391,3.101,1.073,5.883,1.022,8.652
                c-0.112,6.11,1.585,11.841,3.123,17.621c0.656,2.461,1.852,4.563,4.828,5.707c0.041-3.646,1.174-7.07-0.229-10.355
                c-2.225-5.214-4.125-10.507-5.434-16.032C1138.906,231.856,1138.594,229.585,1136.067,228.262z M926.664,488.952
                c-5.852-0.93-10.238,4.64-12.533,13.854c2.5-1.906,4.947-2.648,7.535-2.173c1.814,0.333,3.059-0.06,4.309-1.476
                c0.797-0.902,2.189-1.279,3.499-1.993C928.574,494.355,928.217,491.393,926.664,488.952z M94.947,243.213
                c1.416,10.673,0.821,21.165,1.8,31.663c1.811-2.257,2.948-4.665,3.292-7.485c0.83-6.802-0.151-13.526-0.655-20.278
                C99.204,244.699,98.042,243.533,94.947,243.213z M660.685,342.019c-3.162,16.829-3.227,33.888-7.209,50.46
                c1.845,0.097,2.347-0.695,2.57-1.756c1.028-4.882,2.003-9.777,3.072-14.65c1.116-5.086,0.254-10.341,1.312-15.409
                c1.097-5.253,1.447-10.55,1.438-15.887C661.865,343.896,662.2,342.824,660.685,342.019z M281.266,856.082
                c-7.351-1.539-14.431-3.021-22.213-4.65c1.38,2.826,4.358,4.198,2.544,7.085c1.528,0.794,2.695,1.403,3.865,2.009
                c3.067,1.587,5.9,2.077,8.395-1.161c0.913-1.187,2.515-1.493,4.046-1.685C278.97,857.545,280.307,858.038,281.266,856.082z
                M1064.146,335.407c3.302-6.065,6.239-11.67,7.955-18.196c-8.126,3.395-12.65,8.698-13.736,15.934
                C1060.117,333.831,1061.918,334.535,1064.146,335.407z M176.989,534.967c-3.813-8.162-10.271-11.547-18.461-14.541
                c2.258,6.139,6.375,10.207,9.375,15.223C170.463,533.601,173.334,533.399,176.989,534.967z M675.274,381.561
                c3.17-0.564,2.562-2.682,2.597-4.386c0.143-6.786-0.265-13.576,0.987-20.338c0.505-2.724,0.097-5.626,0.021-8.445
                c-0.047-1.665-1.033-2.126-2.799-1.878C676.483,358.3,673.921,369.786,675.274,381.561z M385.427,558.547
                c2.752,1.684,6.158,1.748,8.28,4.371c1.681,2.078,3.963,2.865,6.576,2.922c2.038,0.043,4.2,0.354,5.376,2.087
                c2.824,4.161,7.263,4.346,11.519,5.585c-0.105-1.751-1.106-2.327-2.003-3.09c-5.07-4.312-12.103-5.431-16.786-10.365
                c-0.306-0.322-0.959-0.591-1.365-0.5C393.014,560.452,389.335,558.364,385.427,558.547z M1009.961,451.471
                c2.569-1.21,3.52-2.776,3.256-5.497c-0.564-5.838-3.688-10.601-6.258-15.556c-0.989-1.908-3.047-3.509-5.745-1.991
                c0.815,4.395,3.979,7.375,5.997,10.938C1009.359,443.157,1008.473,447.457,1009.961,451.471z M65.922,286.654
                c-2.42,1.439-3.265,3.075-3.412,4.894c-0.641,7.915-1.493,15.797-2.876,23.62c-0.349,1.975,0.928,2.074,2.79,2.03
                C64.212,307.214,65.15,297.244,65.922,286.654z M364.875,488.523c-0.803-12.854-5.374-23.038-15.5-30.68
                c0.452,3.357,1.793,5.988,3.747,8.294c4.143,4.89,7.045,10.415,8.931,16.532C362.624,484.524,363.125,486.488,364.875,488.523z
                M1081.692,310.884c-3.352-0.015-5.108,1.705-6.081,3.76c-2.73,5.768-4.607,11.899-7.171,17.782
                C1073.916,326.041,1079.679,319.885,1081.692,310.884z M303.91,130.025c0.521,2.889,2.252,4.895,2.832,7.489
                c0.317,1.422,1.528,2.16,2.919,2.336c3.099,0.393,5.601,1.629,6.832,5.378c1.059-4.341-0.248-6.732-3.328-8.622
                C309.945,134.631,306.984,132.236,303.91,130.025z M205.458,728.939c-3.127,0.096-3.288,2.51-4.087,4.142
                c-1.026,2.096-3.446,2.016-5.575,3.736c4.161-0.048,7.451,1.36,11.042,0.771C206.884,734.396,207.267,731.602,205.458,728.939z
                M568.871,417.568c-4.771-2.208-10.561-0.575-14.29,4.218c4.294,0.068,8.236-1.323,12.323-1.272
                C568.324,420.531,568.888,419.01,568.871,417.568z M444.802,482.26c3.619,5.198,9.359,5.254,12.104,0.107
                C452.881,480.951,448.851,480.619,444.802,482.26z M93.15,161.715c1.132,0.572,2.125,1.146,3.176,1.583
                c0.992,0.413,1.909,1.342,3.141,0.696c0.064-3.599-2.207-7.342-6.316-10.177C93.15,156.42,93.15,158.836,93.15,161.715z
                M1090.967,437.752c-6.548,1.669-12.743,4.429-19.701,6.239c1.312,1.047,2.264,1.155,2.916,0.812
                c3.18-1.672,6.643-1.72,10.074-2.198C1086.988,442.225,1089.818,441.424,1090.967,437.752z M1178.413,763.575
                c-0.631-0.466-1.265-0.618-2.009,0C1177.224,763.575,1177.818,763.575,1178.413,763.575z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(0)} fill={props.fillColors[0]} d="M1281,639.889c-1.021,0.15-1.355,1.539-2.633,1.391
                c-1.635-0.775-1.662-2.679-2.525-4.037c-1.188-1.871-2.877-2.531-4.646-1.271c-2.166,1.545-4.495,2.02-7.021,2.146
                c-1.191,0.061-2.57,0.33-3.135,1.312c-2.05,3.564-6.447,4.592-8.636,8.901c-1.128-3.976-4.858-5.291-5.679-9.009
                c-0.506-2.294-3.184-2.114-4.502,0.127c-0.901,1.533-0.77,3.578-2.41,4.986c-2.549-2.689-6.151-1.672-9.211-2.698
                c-1.721-0.577-4.604,0.728-4.998,2.471c-0.865,3.823-4.727,6.056-4.568,10.272c-1.524-1.028-0.777-2.378-1.017-3.486
                c-0.218-1.01-0.424-2.047-1.362-2.548c-2.319-1.239-4.275-2.337-5.057-5.44c-0.538-2.143-2.727-0.682-4.021,0.525
                c-1.615-0.508-1.093-3.469-3.352-3.075c-3.105,0.542-1.949,3.923-3.658,5.729c-0.931-1.433-1.787-3.347-3.945-1.882
                c-1.52,1.033-1.207,3.036-2.193,4.508c-2.925,1.197-6.373,1.594-8.241,5.255c-1.409-1.843-1.135-3.198-1.255-4.44
                c-0.113-1.158,0.105-2.583-0.852-3.294c-1.818-1.352-2.143-3.188-2.443-5.188c-0.15-0.988-0.799-1.866-1.93-2.04
                c-1.138-0.175-1.906,0.48-2.42,1.396c-1.903,3.387-5.078,6.087-5.502,10.286c-0.062,0.617-0.412,1.462-0.894,1.735
                c-3.003,1.707-3.608,5.012-5.238,8.014c-1.801-1.489-1.488-3.392-1.858-5.028c-0.258-1.138-0.461-2.299-0.846-3.393
                c-1.031-2.932-2.321-3.166-4.565-0.881c-0.324,0.33-0.55,0.758-1.126,1.57c-0.23-3.424,0.676-6.909-3.646-7.951
                c-3.408-0.822-3.625,2.049-4.894,4.1c-3.169-2.145-4.859-0.297-6.11,2.405c-2.39-2.8-3.543-2.825-4.579-0.145
                c-0.533,1.376-0.71,2.901-0.96,4.374c-0.275,1.618-0.078,3.365-1.415,4.699c-1.281-1.074-1.096-2.749-1.758-4.049
                c-1.109-2.18-3.353-2.57-4.112-0.459c-1.255,3.486-4.014,5.975-5.535,9.342c-2.429-3.064-4.189-6.946-8.604-8.149
                c-3.297-0.898-3.898-0.833-5.706,1.887c-0.984,1.481-1.165,3.489-3.128,4.646c-1.076-1.297-0.461-2.852-0.709-4.203
                c-0.217-1.186-0.396-2.389-1.934-2.461c-1.129-0.054-1.758,0.636-2.255,1.617c-1.767,3.484-4.674,6.312-5.583,10.686
                c-2.873-1.573-5.617-3.551-7.125,1.314c-1.506-1.933-0.463-4.018-2.426-5.269c-2.879-0.21-3.701,3.015-5.773,4.396
                c-4.194-1.601-4.613-1.554-6.272,2.372c-1.327,3.14-3.929,4.679-6.327,6.325c-0.471-0.176-0.65-0.198-0.772-0.295
                c-1.522-1.201-1.366-3.925-3.844-4.256c-2.528-0.337-3.04,2.386-4.706,3.15c-2.771,1.275-4.949,4.377-8.982,2.219
                c-2.793-1.494-4.059,2.043-6.124,3.87c-1.958-1.982-4.11-3.519-6.833-4.306c-3.596-1.039-4.263-0.961-5.726,2.396
                c-0.265,0.605-0.34,1.294-0.608,1.896c-0.189,0.425-0.559,0.77-1.147,1.543c-1.843-3.507-4.286-3.25-6.813-0.91
                c-1.289-1.748-0.723-3.5-1.153-5.029c-0.361-1.283-0.624-2.791-2.351-2.755c-1.611,0.034-2.143,1.355-2.47,2.716
                c-0.233,0.972-0.565,1.934-0.669,2.919c-0.274,2.595-1.92,3.2-4.303,3.151c-1.964-2.434,0.385-6.622-3.129-8.276
                c-2.111-0.118-2.707,1.87-3.872,2.69c-3.861-1.531-3.534-1.131-7.429,2.053c-2.575,2.104-2.596,4.836-3.912,7.851
                c-1.225-2.647-3.803-3.472-4.998-5.923c-0.98-2.013-3.54-1.816-4.17,0.333c-0.87,2.974-2.588,4.876-5.229,6.205
                c-1.402,0.566-2.331-0.987-3.773-0.708c-0.271-0.784-0.514-1.39-0.689-2.014c-1.249-4.453-5.166-5.795-8.334-2.5
                c-1.883,1.956-4.714,3.147-5.543,6.41c-0.621-3.223-3.68-4.081-5.166-6.345c-0.809-1.231-2.422-1.021-3.611-0.161
                c-2.424,1.756-4.859,3.535-4.914,6.994c-0.01,0.641-0.258,1.278-0.506,2.42c-1.742-2.139-1.108-4.338-1.484-6.277
                c-0.26-1.334-0.453-2.783-2.115-2.961c-1.334-0.143-2.104,0.872-2.567,1.997c-1.285,3.128-5.188,4.658-5.045,8.917
                c-0.729-3.555-3.979-5.002-5.054-7.916c-0.563-1.531-2.57-2.088-3.139-0.795c-2.072,4.711-7.164,6.664-9.498,11.029
                c-0.145,0.271-0.471,0.447-1.022,0.951c-2.037-1.348-4.341-2.172-4.489-5.547c-0.104-2.327-1.924-3.034-3.961-1.713
                c-2.541,1.648-5.734,2.694-6.412,6.557c-1.604-1.499-1.389-3.037-1.625-4.412c-0.252-1.462-0.176-3.329-2.102-3.484
                c-2.266-0.182-2.426,1.973-2.814,3.484c-0.66,2.566-1.06,5.201-1.574,7.838c-1.877-0.367-1.281-1.779-1.392-2.768
                c-0.54-4.812-0.515-4.812-5.12-5.676c-0.652-0.121-1.348-0.172-1.934-0.447c-2.352-1.104-4.198-0.578-5.451,1.748
                c-1.632-0.865-0.946-2.477-1.615-3.525c-1.158-1.818-2.676-1.863-3.844,0.008c-0.588,0.94-0.213,2.595-1.637,2.871
                c-4.107,0.799-5.4,3.746-5.963,7.586c-0.146-3.631-2.658-5.938-4.088-8.725c-1.658-3.232-3.958-6.133-5.948-9.199
                c-1.933-2.977-3.839-2.92-5.462,0.23c-1.276,2.479-1.273,5.506-3.514,8.158c-1.687-2.769-4.951-3.435-6.394-6.518
                c-1.085-2.318-3.213-2.067-4.612,0.225c-0.685,1.123-1.089,2.422-1.576,3.658c-0.474,1.2-0.623,2.578-2.039,3.386
                c-1.689-1.742-0.992-4.128-1.65-6.118c-1.251-3.779-2.262-3.746-5.572-1.583c-4.117,2.69-3.975,6.933-4.986,10.845
                c-1.951-0.379-2.472-2.277-3.976-3.029c-1.716-0.857-3.228,0.572-4.812,0.097c-1.551-1.495-0.918-3.493-1.335-5.218
                c-0.827-3.412-2.12-4.025-5.324-2.41c-0.877,0.441-1.602,1.233-2.758,1.021c-1.008-0.396-1.179-1.477-1.738-2.271
                c-1.82-2.59-3.521-2.533-5.362,0.129c-1.313,1.898-1.649,4.387-3.427,5.867c-1.198,0.515-2.045-0.104-2.959-0.484
                c-2.102-0.876-4.016-0.51-5.332,1.357c-1.613,2.289-3.986,2.709-6.374,3.188c-0.933-1.417,0.11-3.131-1.298-4.694
                c-2.553-0.635-5.229-1.044-6.746,2.492c-1.011-1.379,0.301-4.279-2.301-4.348c-2.721-0.071-2.459,2.635-3.051,4.43
                c-0.154,0.465-0.289,0.938-0.468,1.526c-1.091-1.128-2.105-2.06-3.668-2.39c-2.466-0.521-4.59-1.346-5.037-4.564
                c-0.254-1.826-3.024-1.76-3.618,0.267c-0.676,2.301-2.039,3.69-4.095,5.07c-0.787-1.747-1.878-3.337-3.31-4.647
                c-1.623-1.484-2.889-1.266-3.897,0.671c-0.228,0.438-0.403,0.93-0.479,1.416c-0.496,3.126-0.979,6.247-4.303,7.872
                c-0.895-3.64-2.377-7.574-5.088-10.871c-1.559-1.894-3.178-1.65-4.039,0.613c-0.588,1.545-0.835,3.219-1.262,4.943
                c-1.665,1.132-3.959-0.742-5.284,1.292c-1.17-1.274-0.603-2.516-0.812-3.623c-0.195-1.034-0.535-1.968-1.677-2.206
                c-1.134-0.236-1.989,0.347-2.393,1.349c-0.655,1.631-1.444,2.995-3.653,2.935c-1.331-0.936-0.043-2.877-1.831-4.465
                c-4.866-0.191-6.216,5.479-10.229,7.496c0-1.766,0.041-3.422-0.018-5.074c-0.032-0.918-0.646-1.59-1.501-1.745
                c-0.86-0.156-1.649,0.314-2.086,1.077c-0.323,0.564-0.493,1.23-0.647,1.871c-0.555,2.308-2.352,3.195-4.12,2.217
                c-2.435-1.349-4.386-1.039-6.438,0.895c-5.81-8.252-11.708-4.752-17.311-0.631c-1.319-0.781-1.027-1.95-1.444-2.812
                c-0.841-1.739-2.367-2.186-3.672-1.09c-1.911,1.605-4.87,1.51-5.722,4.176c-1.438-0.592-0.994-1.59-1.312-2.265
                c-1.18-2.499-2.984-2.905-4.383-0.669c-1.273,2.033-2.465,3.508-5.049,2.957c-2.143-0.457-3.889-1.217-5.005-3.475
                c-1.074-2.174-2.83-2.062-4.196-0.096c-0.468,0.672-0.704,1.504-1.066,2.252c-0.28,0.58-0.592,1.145-1.079,2.08
                c-1.846-1.873-3.529-3.389-4.671-5.6c-0.904-1.752-3.079-1.799-3.505-0.186c-0.841,3.187-4.446,4.375-4.65,8.434
                c-2.156-4.439-3.003-8.482-5.04-12.044c-0.53-0.926-0.933-1.798-2.069-1.823c-1.462-0.033-1.946,1.074-2.243,2.251
                c-0.406,1.604-0.759,3.224-1.212,5.174c-2.433-1.273-3.999-2.787-3.656-5.653c0.079-0.656,0.032-1.332,0.002-1.997
                c-0.055-1.243-0.46-2.343-1.741-2.751c-1.382-0.441-2.141,0.463-2.793,1.519c-0.4,0.646-0.292,1.682-1.356,1.842
                c-1.489-3.809-1.489-3.8-5.787-3.378c-0.489,0.049-1.007-0.018-1.488-0.131c-0.474-0.111-0.917-0.348-1.396-0.539
                c-1.118-1.812,0.856-5.098-2.973-5.703c-2.792,2.087-5.587,4.451-7.534,8.369c-1.456-2.887-0.553-6.145-3.468-7.479
                c-2.068-0.412-2.505,1.909-4.324,2.646c-0.705-3.591-3.853-5.741-4.676-9.211c-0.312-1.314-0.957-2.593-2.539-2.737
                c-1.642-0.152-2.237,1.16-2.864,2.349c-0.311,0.588-0.598,1.199-1.339,1.405c-0.751-0.328-0.378-1.096-0.615-1.62
                c-1.073-2.384-2.149-2.687-4.141-1.312c-1.322,0.913-1.826,2.855-4.014,3.039c-1.921-2.042-0.725-5.479-2.827-7.678
                c-2.478,1.131-2.093,4.763-5.051,5.128c-1.072-1.312-0.48-2.865-0.372-4.252c0.224-2.855-1.704-4.635-3.146-6.535
                c-1.124-1.48-2.652-0.824-3.76,0.576c-1.021,1.289-1.334,3.072-3.176,4.29c-1.137-1.456-2.972-2.729-2.612-4.769
                c0.449-2.545,0.876-5.623,3.298-6.938c1.94-1.053,2.278-2.678,3.146-4.324c2.261,0.699,0.942,3.908,3.489,4.511
                c5.543-4.239,5.141-11.67,8.309-17.292c5.596,3.439,6.383,3.107,8.047-3.144c0.342-1.284,0.753-2.552,1.2-3.804
                c0.092-0.257,0.49-0.404,0.747-0.603c2.301,3.132,2.301,3.132,6.339,4.87c2.52-0.35,2.105-3.221,3.89-4.464
                c1.635,0.398,1.912,1.971,2.486,3.239c0.784,1.733,1.727,3.436,3.812,3.503c2.231,0.071,2.113-2.213,2.947-3.536
                c0.163-0.258,0.493-0.41,0.741-0.607c1.349,0.232,1.694,1.496,2.464,2.328c2.592,2.809,4.631,2.744,6.942-0.209
                c0.907-1.158,1.36-2.711,3.26-3.43c2.882,1.729,6.058,3.441,9.025,5.459c5.411,3.68,5.404,3.751,9.707-1.075
                c2.226,0.33,2.006,2.284,2.491,3.779c1.426,4.401,2.906,5.233,7.435,4.152c0.969-0.231,1.901-0.616,2.867-0.861
                c2.108-0.534,3.941-0.31,5.204,1.783c0.504,0.835,1.151,1.667,1.926,2.241c1.851,1.372,3.541,0.809,4.22-1.4
                c0.53-1.727,0.295-3.67,1.489-5.316c1.954,0.318,1.738,2.143,2.227,3.375c0.611,1.539,0.908,3.199,1.449,4.77
                c0.385,1.115,1.034,2.045,2.291,2.442c2.976,0.938,10.197-2.165,11.377-5.066c1.003-2.464,1.75-5.03,2.689-7.522
                c0.349-0.924,0.845-1.833,1.939-2.139c1.303,0.236,1.789,1.359,2.5,2.261c3.088,3.914,4.877,3.919,8.229,0.05
                c0.852-0.983,1.426-2.293,3.02-2.502c1.915,0.51,2.067,2.381,2.738,3.816c1.975,4.223,2.815,4.692,7.323,3.813
                c0.976-0.19,1.938-0.446,2.919-0.601c0.297-0.048,0.633,0.146,1.018,0.247c1.607,1.411,1.541,3.542,2.158,5.403
                c0.576,1.74,1.126,3.766,3.289,3.986c2.266,0.231,2.917-1.779,3.749-3.424c1.021-2.015,1.175-4.519,3.226-6.131
                c2.162,1.867,1.549,4.427,2.134,6.577c0.557,2.044,0.32,4.894,3.007,5.148c3.135,0.298,3.246-2.882,3.935-4.945
                c1.358-4.072,1.681-8.478,3.807-12.329c2.198,0.165,2.044,1.997,2.583,3.261c1.108,2.593,7.401,6.237,10.152,5.815
                c1.219-0.187,2.111-0.896,2.861-1.848c0.707-0.897,1.189-2.045,2.555-2.389c2.032,0.559,2.049,2.522,2.128,3.991
                c0.192,3.609,1.8,6.168,4.556,8.392c2.857,2.307,4.654,1.893,5.649-1.751c0.262-0.959,0.299-1.978,0.521-2.95
                c0.179-0.78,0.481-1.533,0.843-2.65c1.922,1.645,1.492,3.512,1.559,5.201c0.09,2.328-0.224,4.703,0.461,6.961
                c0.812,2.677,3.138,3.311,4.713,1.042c1.191-1.716,2.5-2.085,4.158-1.853c2.752,0.388,4.475-0.686,5.191-3.285
                c0.523,0.158,0.981,0.154,1.112,0.359c0.872,1.364,1.413,3.222,3.532,2.903c2.306-0.347,2.541-2.396,2.887-4.165
                c0.412-2.107,0.289-4.332,1.513-6.383c1.853,0.57,1.251,3.463,3.509,3.673c0.959,0.089,1.064,1.15,1.133,1.981
                c0.07,0.829,0.062,1.664,0.1,2.496c0.1,2.136-0.234,4.882,2.537,5.246c2.882,0.379,3.062-2.426,3.668-4.473
                c0.129-0.438,0.537-0.795,0.76-1.107c1.159-0.394,1.467,0.561,2.107,0.98c2.367,1.551,3.994,0.951,4.936-1.676
                c0.613-1.71,0.267-3.661,1.326-5.357c1.834,1.143,1.088,2.959,1.28,4.486c0.166,1.318,0.15,2.668,0.413,3.963
                c0.213,1.055,1.059,1.742,2.137,1.81c1.057,0.066,1.926-0.493,2.479-1.408c0.853-1.409,0.402-3.343,1.806-4.583
                c2,0.752,1.049,2.538,1.34,3.848c0.312,1.396,0.728,2.657,2.384,2.733c1.58,0.073,2.433-0.997,2.8-2.374
                c0.428-1.602,0.707-3.242,1.017-4.873c0.395-2.08,0.394-4.291,1.868-6.01c2.185,0.288,1.855,2.238,2.575,3.391
                c1.744,2.792,4.018,2.887,5.736,0.092c0.427-0.693,0.688-1.515,0.897-2.311c0.768-2.911,1.542-5.727,4.383-7.51
                c1.106-0.695,1.18-2.311,1.668-3.544c0.502-1.269,0.33-3.065,2.337-3.671c1.296,1.984-0.216,4.764,2.018,6.625
                c3.238-0.8,3.332-3.475,3.59-6.109c0.257-2.617-0.494-5.352,1.001-8.127c3.354-0.07,4.826-2.401,6.887-5.021
                c2.196-2.792,4.602-5.192,5.083-8.69c1.908-0.301,2.19,1.967,4.399,1.415c2.544-1.694,0.721-6.101,3.652-8.464
                c3.221,3.907,1.455,10.089,6.023,13.059c2.643-0.555,3.234-2.479,3.688-4.457c0.334-1.457,0.683-2.938,0.765-4.423
                c0.151-2.745,1.447-4.357,4.179-4.696c3.352-0.415,4.441-2.948,5.039-5.984c2.618,7.801,2.802,7.73,8.944,2.997
                c2.076-1.6,2.639-3.638,3.814-6.58c0.493,3.442,0.837,6.097,1.265,8.738c0.244,1.512,0.677,3.177,2.459,3.346
                c2.07,0.197,2.754-1.513,3.232-3.175c0.445-1.54,0.011-3.311,1.614-5.493c0.441,4.205,4.66,4.574,5.737,7.973
                c0.607,1.916,4.266,1.423,4.557-0.806c0.589-4.527,5.271-6.029,6.054-10.152c1.603,0.781,1.147,1.933,1.296,2.832
                c0.211,1.275,0.688,2.303,2.09,2.378c1.521,0.083,2.583-0.825,2.947-2.214c0.419-1.6,0.522-3.279,0.802-4.918
                c0.16-0.94-0.021-2.035,1.029-2.635c4.079,3.794,4.243,3.732,8.812-3.517c1.482,1.925,1.084,4.131,1.391,6.147
                c0.258,1.709,0.641,3.423,2.667,3.742c1.976,0.311,2.956-1.061,3.786-2.633c0.735-1.393,0.553-3.336,2.639-4.25
                c1.362,2.16,1.625,4.61,2.02,7.018c0.223,1.352,0.555,2.775,2.027,3.171c1.78,0.479,2.723-0.939,3.531-2.183
                c0.628-0.963,1.435-2.096,1.418-3.141c-0.056-3.372,1.691-5.425,4.213-7.285c0.982-0.725,1.449-2.149,2.752-4.201
                c-0.314,7.461,5.533,11.123,7.568,16.836c1.129,3.169,3.73,2.902,5.791-0.102c0.581-0.846,1.081-1.685,2.455-1.822
                c2.597,0.674,2.639,4.504,5.701,5.137c3.137-0.432,1.888-4.266,4.266-5.807c2.317,5.185,3.018,11.109,6.639,15.207
                c1.658-0.105,2.223-0.654,2.679-1.403c0.423-0.694,0.903-1.354,1.306-1.953c2.172,0.21,2.576,2.189,3.82,3.22
                c2.282,1.89,3.698,1.582,5.148-1.023c0.728-1.308,0.462-3.007,2.012-4.373c2.109,3.4,5.822,5.287,7.053,9.643
                c0.588,2.078,3.566,3.298,4.186,6.115c0.322,1.473,3.09,1.197,4.137-0.317c0.365-0.53,0.46-1.241,0.732-1.845
                c0.268-0.591,0.604-1.15,0.889-1.685c1.75,0.342,1.441,1.739,1.731,2.794c0.382,1.39,1.173,2.455,2.661,2.626
                c1.527,0.176,2.466-0.817,3.121-2.06c0.379-0.721,0.65-1.498,1.353-3.148c0.552,2.281,0.808,3.777,1.286,5.197
                c0.535,1.588,1.198,3.262,3.32,3.156c1.912-0.094,2.193-1.705,2.697-3.134c0.357-1.012-0.134-2.415,1.255-2.994
                c1.634,0.202,1.181,1.831,1.87,2.672c1.558,1.899,3.922,2.187,4.919,0.145c1.066-2.185,2.403-3.775,4.42-5.079
                c1.338-0.864,0.859-2.781,1.61-4.476c1.582,2.391,2.023,5.456,5.193,6.378c1.047,0.304,0.863,2.042,1.204,3.139
                c0.38,1.226,1.188,2.094,2.449,2.185c1.524,0.11,2.372-0.897,2.962-2.224c0.666-1.5,0.166-3.342,1.237-4.648
                c7.114-0.147,7.114-0.147,8.912-7.656c1.1,1.84,0.967,3.887,1.284,5.836c0.214,1.309,0.418,2.629,0.777,3.9
                c0.373,1.316,1.036,2.654,2.607,2.638c1.748-0.019,2.582-1.265,2.869-2.916c0.195-1.13,0.185-2.35,1.34-3.333
                c1.746,0.627,1.283,2.347,1.637,3.613c0.382,1.373,1.018,2.643,2.506,2.777c1.811,0.165,2.664-1.289,3.084-2.754
                c0.919-3.199,1.269-6.514,1.485-11.141c1.901,3.816,5.528,3.947,6.963,7.074c0.71,1.549,2.942,1.066,4.257-0.377
                c0.881-0.969,0.188-2.77,2.179-3.422c1.034,1.672,1.13,3.578,1.276,5.485c0.115,1.491,0.201,3.003,0.533,4.452
                c0.301,1.312,0.97,2.573,2.551,2.707c1.307,0.11,2.279-0.666,2.742-1.797c1.114-2.714,4.951-2.697,5.262-6.086
                c2.35,0.771,1.823,2.576,2.27,3.825c0.668,1.868,2.094,3.044,3.802,2.176c2.626-1.335,6.282-1.796,6.575-5.872
                c0.033-0.466,0.389-0.909,0.587-1.344c6.569-1.974,6.569-1.974,9.066-8.238c1.965,0.453,3.168,3.715,5.899,1.818
                c1.95-1.354,1.383-3.985,3.172-5.914c1.416,2.056,1.242,4.033,1.416,5.89c0.566,6.015,0.924,6.286,6.592,4.815
                c0.29-0.076,0.644,0.092,0.941,0.141c0.743,0.586,0.756,1.438,0.951,2.242c1.084,4.469,1.516,4.787,6.043,4.854
                c1.184,0.018,3.022,0.15,3.232-0.745c1.014-4.305,6.316-6.401,5.646-11.583c-0.263-2.037-0.344-4.353,1.217-6.199
                c1.676,0.868,1.154,2.677,1.818,3.941c0.967,1.846,3.149,2.039,4.58,0.579c1.486-1.517,0.303-4.567,3.117-5.282
                c2.356-0.599,1.999-3.265,3.606-4.808c1.649,0.815,1.3,2.414,1.453,3.744c0.381,3.303-0.101,6.673,0.805,9.94
                c0.36,1.304,0.603,2.786,2.222,2.942c1.804,0.174,2.655-1.124,3.057-2.69c0.324-1.273,0.396-2.639,1.506-3.566
                c1.738,0.664,1.285,2.17,1.492,3.396c0.219,1.307,0.324,2.646,0.682,3.914c0.328,1.164,1.064,2.111,2.475,2.066
                c1.344-0.041,2.093-0.928,2.573-2.048c0.706-1.644,0.028-3.729,1.562-5.104c1.727,0.113,1.469,1.562,1.842,2.572
                c0.443,1.197,1.257,2.014,2.563,2.033c1.534,0.023,2.464-0.918,2.892-2.308c0.242-0.786,0.295-1.631,0.42-2.451
                c0.125-0.821,0.066-1.717,0.383-2.454c0.777-1.815,3.402-2.132,3.641-4.518c0.178-1.771-0.121-3.721,1.383-5.252
                c1.806,0.68,1.324,2.398,1.663,3.709c0.439,1.701,1.017,3.337,3.155,3.191c1.748-0.119,2.499-1.409,2.784-3.039
                c0.194-1.107-0.044-2.382,1.261-3.305c2.037,1.169,1.454,3.312,1.699,5.07c0.324,2.33,0.362,4.746,2.931,5.909
                c1.097,0.497,1.241,1.472,1.417,2.446c0.207,1.146,0.388,2.301,0.535,3.457c0.246,1.916,0.714,3.881,3.065,3.787
                c1.993-0.08,2.444-1.967,2.857-3.621c0.39-1.561-0.236-3.372,0.969-4.565c0.674-0.446,1.191-0.323,1.516,0.048
                c0.959,1.094,1.596,2.672,3.491,2.265c2.041-0.438,2.349-2.176,2.648-3.815c0.239-1.304,0.375-2.641,0.404-3.965
                c0.051-2.244,1.107-3.652,2.542-5.467c2.573-3.252,6.86-5.247,7.778-9.812c0.064-0.32,0.78-0.51,1.375-0.87
                c2.045,1.965,2.74,5.394,6.258,5.811c0.477,0.057,0.899,1.674,1.107,2.621c0.355,1.609,0.395,3.312,1.709,4.437
                c1.902,0.501,2.379-1.933,4.058-1.683c1.251,0.144,1.861,1.262,2.79,1.932c2.993,2.154,4.69,1.518,5.67-2.076
                c0.26-0.953,0.596-1.886,0.896-2.828C1281,609.223,1281,624.555,1281,639.889z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M1281,665.889c-1.896,1.412-2.08,3.705-2.789,5.681
                c-0.806,2.243-1.675,4.425-3.633,5.843c-3.886,2.814-5.901,6.669-6.741,11.273c-0.208,1.137-0.679,2.154-1.794,3.174
                c-1.813-2.034-1.174-4.537-1.814-6.639c-0.749-2.461-0.453-6.149-3.822-6.107c-2.965,0.037-2.766,3.627-3.533,5.903
                c-0.682,2.021-0.719,4.274-1.937,5.927c-1.376,0.614-2.096-0.217-2.875-0.769c-1.832-1.298-3.618-1.169-5.642-0.29
                c-3.774,1.638-4.552,1.322-5.758-2.504c-0.846-2.684-1.146-5.535-1.902-8.254c-0.546-1.963-0.452-4.468-3.186-5.101
                c-1.867,0.806-2.105,2.521-2.426,4.132c-0.292,1.467-0.635,2.97-0.6,4.448c0.177,7.315-4.687,10.399-10.56,12.372
                c-2.181,0.732-3.285,2.824-5.423,3.396c-2.428-0.481-3.021-2.458-3.475-4.403c-0.49-2.104-0.735-4.265-1.113-6.396
                c-0.318-1.799-0.535-3.668-2.699-4.625c-3.932,1.328-2.904,5.533-4.805,8.461c-2.612-1.384-5.189-2.844-6.469-5.804
                c0.201-0.772,0.787-1.076,1.301-1.495c2.844-2.318,2.883-3.771-0.438-5.348c-2.459-1.168-2.829-2.795-2.944-5.128
                c-0.167-3.374,0.776-6.94-1.698-10.951c-2.601,5.306-3.452,10.48-5.632,15.942c-1.639,1.137-6.252-0.684-6.258,4.293
                c-0.004,3.396,2.159,4.066,4.997,4.239c-4.882,4.507-3.693,4.734,1.39,6.528c0.279,3.048-0.619,4.417-3.549,5.074
                c-2.086,0.469-3.447-0.602-4.303-2.432c-0.141-0.301-0.234-0.623-0.359-0.932c-0.686-1.683-1.143-3.794-3.564-3.459
                c-2.303,0.318-2.367,2.496-2.817,4.228c-0.286,1.101-0.163,2.365-1.644,3.237c-2.607-3.902-7.355-2.273-11.012-4.047
                c-1.968-0.955-5.119,2.266-5.67,4.868c-0.104,0.487,0.041,1.19-0.243,1.451c-3.308,3.036-2.815,7.14-3.228,11
                c-0.025,0.243-0.44,0.444-1.293,1.249c0.77-5.156-3.578-7.616-4.642-11.809c-0.549-2.161-2.564-4.362-3.953-6.494
                c-1.471-2.258-3.433-0.166-5.064,0.949c-1.823-2.086-1.449-4.8-2.315-7.098c-0.549-1.455-1.077-3.015-2.813-3.094
                c-1.815-0.084-2.595,1.517-2.879,2.972c-0.62,3.18-1.867,5.784-4.396,8.016c-1.729,1.523-0.771,4.554-2.372,6.467
                c-1.842,0.261-1.974-1.407-2.628-2.432c-0.832-1.304-1.698-2.704-3.439-2.425c-2.074,0.332-2.284,2.123-2.41,3.847
                c-0.047,0.64-0.172,1.274-0.345,2.498c-1.539-1.911-1.359-3.712-1.573-5.373c-0.201-1.566-0.717-2.744-2.272-3.567
                c-5.258-2.781-6.791-2.156-8.423,3.703c-0.4,1.439-0.809,2.878-1.253,4.305c-0.094,0.301-0.358,0.55-0.691,1.039
                c-1.631-0.977-1.41-4.27-4.41-3.595c-2.205,0.495-3.376,2.751-5.774,3.024c-2.717-2.232,0.447-7.316-3.951-8.746
                c-2.102,0.642-2.391,2.25-2.444,3.986c-0.061,1.994,0.092,3.979-2.063,5.251c-1.072,0.632-0.99,2.038-1.014,3.206
                c-0.03,1.467-0.008,2.936-0.008,4.337c-1.562,0.245-1.89-0.593-1.922-1.408c-0.142-3.604-2.537-6.097-4.658-8.464
                c-1.212-1.354-2.473-2.414-2.988-4.25c-0.273-0.972-1.223-1.626-2.35-1.441c-1.104,0.182-1.723,1.024-1.851,2.075
                c-0.141,1.151-0.124,2.327-0.112,3.491c0.024,2.312,0.252,4.521-1.617,6.529c-1.22,1.311-1.047,3.441-1.136,5.276
                c-0.095,1.955,0.183,3.951-0.517,5.979c-1.406-1.081-0.924-2.562-1.18-3.789c-0.506-2.42-0.562-4.839-3.379-6.197
                c-1.658-0.801-1.607-3.168-2.415-4.787c-0.604-1.211-1.27-2.464-2.882-2.366c-1.549,0.094-2.221,1.308-2.757,2.562
                c-1.155,2.706-1.202,5.84-3.317,8.947c-1.895-3.804-6.04-4.367-7.967-7.866c-1.148-2.084-4.281-2.213-6.554-2.873
                c-1.654-0.48-2.79,1.355-3.597,2.918c-0.889,1.724-1.041,3.852-2.992,5.573c-1.219-1.901-0.963-3.933-1.479-5.703
                c-0.38-1.304-0.628-2.753-2.247-2.925c-1.643-0.172-2.515,1.08-2.605,2.449c-0.123,1.844-1.463,2.339-2.588,3.312
                c-3.44,2.974-7.555,5.091-10.676,8.494c-1.307-0.907-0.818-2.441-1.272-3.639c-0.875-2.305-2.247-2.691-4.207-1.211
                c-1.462,1.104-1.999,3.098-3.743,3.916c-4.135-2.506-5.092-2.176-6.697,2.283c-1.058,2.941-1.693,3.602-6.321,6.557
                c-1.936-0.374-2.139-2.307-3.079-3.576c-2.011-2.713-3.795-2.905-6.02-0.383c-2.463,2.794-6.039,4.381-7.617,7.731
                c-2.023-0.511-2.032-2.146-2.885-3.094c-1.705-1.894-3.77-1.692-4.58,0.733c-0.783,2.342-0.98,4.875-1.48,7.313
                c-0.323,1.569,0.163,3.383-1.385,4.708c-1.908-0.557-2.017-2.436-2.724-3.811c-0.915-1.777-1.605-3.637-3.661-4.53
                c-1.992-0.866-1.84-2.997-2.449-4.661c-0.524-1.429-0.99-2.964-2.863-2.998c-1.928-0.034-2.611,1.535-2.801,2.984
                c-0.301,2.307-1.33,3.861-3.145,5.316c-1.861,1.492-1.09,4.465-2.683,6.307c-1.205-0.811-1.134-2.04-1.229-3.143
                c-0.205-2.331-0.283-4.49-2.677-6.054c-1.542-1.007-1.208-3.329-1.656-5.082c-0.306-1.194-0.894-2.245-2.237-2.372
                c-1.614-0.152-2.383,0.92-2.762,2.325c-0.216,0.804-0.453,1.616-0.536,2.438c-0.291,2.871-2.039,3.537-4.777,3.104
                c-1.329-1.939-1.111-4.327-1.291-6.579c-0.253-3.154-0.167-6.338-0.479-9.483c-0.184-1.854,0.111-4.515-2.576-4.694
                c-2.328-0.156-2.531,2.296-3.066,3.971c-0.451,1.41-0.465,2.972-1.621,4.093c-5.838-2.774-6.281-2.521-7.229,4.171
                c-0.254,1.791-0.181,3.645-2.081,4.8c-1.265,0.769-1.25,2.249-1.369,3.591c-0.113,1.28,0.129,2.665-1.201,4.234
                c-1.182-3.631-4.856-4.597-6.418-7.762c-1.069-2.168-3.987-1.749-4.432,0.652c-0.611,3.312-2.664,5.043-5.437,6.775
                c-1.486-0.598-3.134-1.262-4.86-1.957c-0.25-0.839-0.473-1.631-0.722-2.415c-1.527-4.817-6.287-5.828-9.816-2.206
                c-2.329,2.391-4.782,4.645-6.381,7.953c0.449-4.546-3.265-6.267-5.33-8.972c-1.221-1.599-2.821-1.485-4.392-0.317
                c-2.419,1.797-4.801,3.56-4.338,7.159c0.118,0.923,0.032,2.017-1.088,3.021c-0.967-1.151-1.034-2.572-1.33-3.875
                c-0.295-1.297-0.425-2.635-0.792-3.908c-0.416-1.443-0.936-3.136-2.835-2.937c-1.773,0.187-2.176,1.859-2.486,3.345
                c-0.238,1.14-0.316,2.312-0.527,3.458c-0.414,2.249-0.383,4.588-2.555,6.249c-1.425,1.088-1.007,3.07-1.217,4.699
                c-0.331,2.562,0.203,5.26-1.092,7.904c-1.285-1.771-1.434-3.607-1.664-5.403c-0.193-1.507-0.262-3.386-1.344-4.2
                c-3.082-2.324-2.609-5.504-2.703-8.609c-0.051-1.668-0.045-3.338-0.729-4.909c-0.347-0.796-0.849-1.489-1.766-1.563
                c-1.105-0.088-2.126,0.451-2.325,1.494c-0.717,3.768-3.801,5.506-6.383,7.632c-1.214,0.999-1.994,2.191-2.698,3.517
                c-0.604,1.134-0.839,2.568-2.499,3.094c-1.811-1.469-1.707-3.801-2.38-5.748c-0.542-1.572-0.902-3.21-1.802-4.632
                c-0.954-1.51-2.546-2.072-3.843-1.013c-2.295,1.875-5.601,1.943-7.436,5.037c-2.099-2.674,0.134-6.792-3.896-8.145
                c-4.54,2.312-2.193,7.861-4.801,11.161c-1.747-1.154-1.193-2.995-1.383-4.563c-0.703-5.838-2.265-6.559-7.201-3.182
                c-1.839,0.134-3.242-1.683-5.535-1.235c-1.246,2.485-5.547,2.398-5.747,6.33c-2.002-0.934-1.271-3.402-3.013-4.494
                c-3.717-2.329-8.609-0.83-10.52,3.37c-1.107,2.434-3.871,4.039-3.427,7.309c-1.409-4.289-1.292-8.59-1.595-12.846
                c-0.201-2.823-0.26-5.641-3.184-7.41c-1.285-0.777-1.41-2.589-2.172-3.863c-1.762-2.944-4.084-2.95-5.535,0.117
                c-0.988,2.089-1.474,4.411-2.271,6.598c-0.273,0.752-0.808,1.408-1.183,2.039c-1.787-0.082-2.178-1.604-3.127-2.462
                c-2.283-2.063-4.271-1.905-5.606,0.757c-0.999,1.993-2.204,3.461-4.116,4.709c-2.109,1.376-2.153,4.36-3.793,6.893
                c-1.318-2.174-0.874-4.147-0.975-5.963c-0.25-4.498-0.24-8.977-3.725-12.582c-1.128-1.166-1.167-3.062-1.561-4.687
                c-0.416-1.716-0.247-3.706-1.926-5.069c-0.443,0.037-1.166-0.107-1.355,0.146c-3.021,4.041-6.441,7.971-6.506,13.37
                c-0.03,2.472-0.366,4.534-2.3,6.267c-1.768,1.584-2.369,3.918-3.121,6.242c-1.165-1.277-1.2-2.622-1.321-3.933
                c-0.334-3.604-0.117-7.243-3.602-9.919c-1.172-0.9-1.107-3.377-1.666-5.115c-0.514-1.597-1.019-3.332-3.121-3.384
                c-1.848-0.046-2.485,1.372-2.963,2.866c-0.151,0.475-0.221,1.15-0.561,1.363c-3.918,2.454-4.77,6.85-6.451,10.898
                c-0.718-0.641-1.346-0.927-1.385-1.277c-0.473-4.286-3.301-6.45-6.818-8.352c-3.834-2.072-5.152-1.787-6.468,2.475
                c-0.638,2.062-0.925,4.234-1.556,6.299c-0.473,1.546-0.47,3.328-2.092,4.85c-2.225-4.547-6.307-8.07-5.076-13.768
                c0.192-0.887-0.203-2.003-0.598-2.894c-0.72-1.626-2.574-1.722-3.566-0.269c-0.997,1.463-0.212,3.842-2.348,4.705
                c-1.856,0.75-1.539,2.79-2.49,4.396c-7.159-3.938-6.609,3.143-8.837,6.876c-1.705-4.979-3.966-8.779-6.946-12.13
                c-0.88-0.989-0.501-2.228-0.96-3.274c-0.438-1-1.148-1.647-2.273-1.627c-1.134,0.02-1.866,0.707-2.191,1.734
                c-1.267,4.014-3.667,7.257-6.459,10.355c-1.409,1.562-2.177,3.709-3.209,5.605c-1.09,2.004-1.696,4.317-3.623,5.887
                c-1.464-0.592-1.365-1.947-1.544-2.932c-0.455-2.496-0.734-4.92-3.457-6.202c-1.101-0.519-1.082-1.995-1.551-3.046
                c-0.471-1.055-0.646-2.537-1.457-3.091c-2.412-1.649-2.8-3.905-2.732-6.488c0.017-0.655-0.099-1.337-0.276-1.972
                c-0.287-1.025-0.912-1.771-2.069-1.847c-1.151-0.074-2.137,0.526-2.252,1.554c-0.287,2.561-1.902,4.034-3.705,5.558
                c-1.761,1.487-2.146,3.763-2.414,5.892c-0.463,3.67-1.943,6.624-5.244,8.76c-0.453-5.471-2.319-10.357-5.131-14.941
                c-0.583-0.95-0.715-2.204-0.945-3.34c-0.299-1.479-1.024-2.521-2.615-2.496c-1.625,0.025-2.492,1.243-2.464,2.631
                c0.059,2.989-1.458,4.883-3.905,6.454c-1.155-2.608-3.896-4.173-4.324-7.453c-0.243-1.866-2.725-2.161-3.91-0.657
                c-2.79,3.541-5.771,6.941-7.968,10.929c-0.74,1.344-0.979,2.78-1.327,4.163c-0.395,0.271-0.678,0.137-0.629-0.344
                c0.58-5.711-1.088-10.279-6.255-13.381c-1.501-0.9-1.169-3.012-1.663-4.57c-0.438-1.38-1.162-2.501-2.684-2.557
                c-1.305-0.047-2.144,0.841-2.447,2.096c-0.337,1.396-1.129,2.102-2.468,2.79c-4.212,2.167-6.575,6.508-10.333,9.297
                c-1.514,1.124-1.121,3.758-3.651,5.604c0.504-6.817-5.829-9.333-7.356-14.681c-0.531-1.859-3.38-2.291-3.94-0.723
                c-1.324,3.707-6.149,4.652-6.623,9.135c-0.794-3.859-2.852-6.74-5.225-9.521c-0.89-1.043-1.419-2.311-3-2.104
                c-1.537,0.201-2.115,1.42-2.534,2.756c-0.344,1.094-0.784,2.158-1.368,3.744c-2.144-2.652-5.055-4.057-5.648-7.508
                c-0.239-1.392-1.37-2.326-2.949-2.102c-1.434,0.203-1.835,1.402-1.845,2.592c-0.015,1.859-0.805,2.929-2.395,3.942
                c-0.937,0.598-1.207,2.242-2.156,3.503c0.562-3.059-3.141-5.053-1.692-8.396c0.178-0.411-0.313-1.339-0.753-1.75
                c-1.785-1.669-2.775-3.626-3.032-6.085c-0.149-1.427-1.222-2.449-2.726-2.439c-1.339,0.01-2.055,1.086-2.287,2.29
                c-0.591,3.075-2.124,5.496-4.491,7.544c-1.724,1.491-2.085,3.932-3.462,6.861c-1.214-3.766-0.833-6.597-0.757-9.324
                c0.084-3.035-1.618-4.963-3.448-6.886c-1.048-1.101-2.756-1.454-3.45-0.098c-1.493,2.919-5.567,4.253-4.946,8.51
                c-3.098-4.895-5.362-9.933-8.548-15.722c5.456,2.354,6.555,1.87,8.042-2.42c0.216-0.624,0.162-1.521,0.582-1.867
                c2.836-2.33,3.837-5.641,4.947-9.107c1,3.736,4.787,5.727,4.732,9.717c4.358,0.327,4.781-3.346,6.617-5.753
                c1.765,0.634,1.938,3.644,4.542,2.947c2.081-0.557,2.171-2.763,3.287-3.749c1.609,0.154,1.805,1.248,2.36,2.037
                c1.816,2.586,3.49,2.608,5.328,0.022c0.562-0.79,0.767-1.824,1.222-2.702c1.469-2.835,2.992-5.632,6.815-5.627
                c1.328,0.002,1.77-1.42,2.807-1.875c2.041,0.974,2.372,4.548,5.459,3.54c2.304-0.753,2.936-2.99,3.634-5.083
                c2.819-0.163,5.313,2.838,8.121,0.271c0.613-0.56,1.499,1.715,3.272,1.157c2.289-1.934,3.278-5.291,5.519-7.795
                c2.516,4.352,2.516,4.352,6.199,6.435c2.508-1.985,1.253-5.37,2.77-7.712c5.514-0.148,2.828-5.762,5.667-8.078
                c1.118,1.715,2.623,3.063,5.066,3.4c2.238,0.309,2.052,3.82,4.621,4.313c2.845-0.507,3.079-3.6,4.986-5.43
                c2.6,1.649,3.76,3.88,3.323,6.954c-0.093,0.654-0.065,1.334-0.024,1.997c0.09,1.472-0.119,3.198,1.724,3.751
                c1.747,0.523,2.102-1.093,2.878-2.129c0.458-0.612,1.323-0.92,2.021-1.375c2.778,6.181,3.003,6.203,9.12,0.914
                c1.052,1.332,0.234,4.043,2.639,4.24c2.813,0.232,2.583-3.139,4.666-4.351c1.244,2.853,2.935,5.426,4.374,8.116
                c2.443,4.57,2.715,4.746,6.772,1.32c2.003,1.972,0.239,5.958,3.792,7.127c2.178-0.168,2.638-2.146,3.349-3.754
                c0.853-1.928,3.188-1.658,4.491-3.553c0.676,1.911,0.01,4.334,2.559,4.883c1.93,0.416,3.826,0.904,5.56-0.086
                c1.262,1.725,0.33,4.321,2.781,4.76c2.352,0.419,2.479-2.293,4.366-3.759c-0.418,5.215,1.343,8.682,5.522,10.71
                c2.789-1.643,2.098-4.559,3.102-6.901c1.353,1.051,2.833,2.064,2.736,3.864c-0.161,3.016,2.748,4.763,2.804,7.787
                c0.03,1.67,3.005,1.99,3.286,0.435c0.633-3.503,3.875-5.226,5.028-8.528c1.421,1.837,2.708,3.636,4.14,5.312
                c1.483,1.736,3.044,1.517,4.44-0.44c0.845-1.185,0.659-2.968,2.169-3.779c3.856,5.843,10.017,6.126,14.142,0.674
                c1.002,0.388,0.561,1.38,0.865,2.053c1.033,2.272,2.589,2.868,4.131,1.032c1.627-1.939,4.059-2.11,5.496-3.707
                c1.404,0.393,1.045,1.391,1.236,2.119c0.565,2.148,2.107,2.762,3.758,1.639c1.641-1.116,3.637-1.363,5.156-2.883
                c2.082-2.082,3.578-1.901,6.018-0.018c1.033,0.798,2.41,1.189,3.025,2.519c0.393,0.847,1.541,1.478,2.012,1.077
                c2.758-2.34,5.554,0.051,8.296-0.273c2.097-0.248,3.735-1.032,4.214-3.66c0.803,1.459,0.477,2.473,0.507,3.457
                c0.042,1.355,0.028,2.823,1.728,3.158c1.388,0.273,1.79-0.979,2.577-1.844c2.132-2.338,3.648-5.435,7.009-6.568
                c0.791,1.404-0.116,3.225,1.848,4.27c2.569,0.328,4.551-1.414,6.556-3.237c0.519,2.212-0.886,4.509,0.849,6.362
                c0.18,0.062,0.359,0.196,0.491,0.159c2.7-0.749,4.466-3.479,7.735-3.327c0.993,0.047,1.186-2.164,1.4-3.488
                c0.102-0.623,0.378-1.217,0.724-2.281c5.149,3.352,4.313,9.479,6.628,13.58c4.316,0.011,2.467-4.084,4.641-5.449
                c2.3-1.443,1.389-4.595,2.434-6.798c2.766,0.207,3.279,1.796,3.022,4.099c-0.126,1.129-0.062,2.488,1.39,2.855
                c1.625,0.411,2.703-0.583,2.857-2.022c0.281-2.62,3.924-2.757,3.807-5.792c1.152,1.256,1.643,2.919,3.385,3.284
                c2.961,0.62,5.475,1.888,6.253,5.155c3.774-0.469,3.173-3.629,4.289-5.972c0.189,0.874,0.204,1.558,0.481,2.11
                c1.18,2.362,2.504,2.525,3.982,0.475c0.648-0.898,0.561-2.344,1.979-2.658c0.478-0.105,0.979-0.105,1.451-0.15
                c0.218,0.301,0.529,0.54,0.568,0.816c0.706,5.13,0.925,5.232,5.893,3.53c1.127-0.386,2.336-0.653,3.003-1.637
                c1.679-2.476,3.534-3.077,6.341-1.601c1.961,1.032,3.402-0.402,4.479-2.091c1.216-1.905,1.489-4.447,3.702-5.733
                c3.428,4.735,3.428,4.735,9.613,2.367c1.465,1.445,0.787,3.446,1.263,5.146c0.83,2.965,1.394,3.572,4.483,2.955
                c2.002-0.4,3.166,0.191,4.228,1.694c0.749,1.06,1.222,2.42,2.928,2.835c1.959-0.808,2.005-2.764,2.076-4.516
                c0.169-4.139,1.99-7.142,5.688-9.053c1.637,1.568,0.958,3.788,1.589,5.627c0.515,1.5,0.454,3.655,2.557,3.863
                c2.048,0.201,2.538-1.713,3.28-3.205c0.932-1.869,0.909-4.242,3.189-5.951c1.08,3.322,4.296,4.309,6.312,6.622
                c1.686,1.934,3.382,1.444,4.703-0.94c1.409-2.544,1.515-5.697,3.492-8.229c4.229,4.757,6.641,10.279,9.955,15.205
                c0.861,1.28,0.717,3.212,1.15,4.813c0.273,1.012,0.943,1.807,2.121,1.748c1.206-0.061,1.645-0.988,1.857-2.004
                c0.137-0.647,0.244-1.33,0.188-1.982c-0.215-2.511,0.853-4.252,3.223-4.925c1.947-0.554,3.111-1.74,3.904-4.772
                c0.849,2.464,0.508,4.428,2.383,4.986c2.098,0.624,2.84-1.294,4.141-2.281c2.279,0.504,4.523,0.998,6.82,1.506
                c1.337,2.317,0.867,4.906,1.316,7.318c0.191,1.021,0.534,2.018,1.662,2.24c1.434,0.281,2.06-0.719,2.521-1.885
                c1.263-3.195,0.922-6.803,2.472-10.074c1.577,1.195,0.984,2.854,1.299,4.227c0.271,1.186,0.581,2.318,1.906,2.656
                c1.363,0.348,2.136-0.55,2.848-1.543c2.178-3.038,2.188-3.031,6.512-5.481c-0.072,3.663,2.135,5.649,4.955,7.13
                c1.303,0.684,2.875,0.752,3.629-0.482c1.94-3.178,4.904-5.221,7.602-7.581c0.997-0.872,0.649-2.615,2.043-3.405
                c1.285,2.984,4.275,4.717,5.066,8.07c0.368,1.561,3.088,1.498,3.471-0.162c0.857-3.727,3.672-6.072,5.715-9.507
                c1.384,2.021,0.986,4.167,1.248,6.141c0.177,1.341,0.311,2.857,1.934,3.11c2.005,0.312,2.703-1.409,2.793-2.825
                c0.223-3.493,1.749-5.952,5.244-7.548c1.335,2.033,3.988,3.044,5.028,5.898c0.667,1.83,2.999,1.771,3.864-0.183
                c0.947-2.144,2.967-3.056,4.151-4.771c1.376-1.992,3.13-2.123,5.493-1.779c-0.113,3.891,1.869,6.715,5.607,6.668
                c4.373-0.057,6.979-3.193,8.285-8.487c1.188,3.566,4.16,4.245,5.238,7.003c0.721,1.847,3.26,1.207,4.154-0.977
                c0.689-1.681,0.307-3.883,1.678-5.145c1.335-1.229,2.574-2.484,3.658-4.406c2.78,2.469,5.227,2.07,7.099-0.889
                c1.321,1.384,0.815,2.789,1.05,4.033c0.34,1.812,0.471,3.791,2.947,3.96c2.789,0.191,5.039-1.288,5.783-3.709
                c0.421-1.367-0.098-3.052,1.631-4.297c1.148,2.271-0.27,5.535,2.592,6.901c1.804,0.559,2.611-1.323,4.041-1.564
                c3.511,3.107,4.249,4.842,8.041-4.068c2.367,0.79,4.879,1.025,6.851,3.091c2.392,2.506,3.288,2.408,5.656-0.067
                c0.68-0.711,1.087-1.688,2.464-2.11c6.443,3.656,9.793-2.335,14.284-5.146c4.285,5.679,6.287,5.681,11.299,0.146
                c1.175-1.299,1.28-3.067,2.511-4.707c1.4-0.29,2.989,1.773,4.783,0.188c1.225-1.083,1.938-2.745,3.604-3.323
                c0.148,0.129,0.303,0.219,0.396,0.352c0.966,1.391,0.048,4.028,2.593,4.311c2.094,0.232,2.23-2.199,3.672-3.205
                c1.442,0.211,2.475,2.318,4.348,1.244c1.246-0.715,2.082-1.81,2.273-3.136c0.464-3.229,3.126-4.903,4.916-7.392
                c0.896,1.835,0.068,4.143,1.957,5.836c4.885-0.051,4.032-5.86,7.477-7.794c5.186,1.359,7.914,5.245,9.337,10.257
                c4.297,0.372,3.06-3.979,4.942-5.246c2.364-1.594,2.322-4.314,3.764-6.48c1.837,1.031,1.395,2.977,2.14,4.311
                c0.493,0.886,1.004,1.828,2.178,1.789c1.126-0.037,1.904-0.846,2.147-1.821c0.641-2.575,1.102-5.194,1.649-7.875
                c1.31-0.407,2.001,1.511,3.487,0.942c1.688-0.645,1.791-2.264,2.748-3.844c3.337,1.594,5.661,0.534,6.012-3.291
                c2.542,0.102,3.707,0.924,3.604,3.053c-0.017,0.332-0.034,0.666-0.023,0.999c0.059,1.778-0.443,4.037,1.91,4.56
                c2.139,0.474,2.245-1.994,3.419-3.372c1.164,1.202,1.151,2.667,1.425,4.037c0.324,1.629,0.545,3.321,1.176,4.835
                c0.922,2.219,2.721,2.303,4.178,0.338c0.39-0.525,0.643-1.162,0.896-1.773c0.822-1.98,1.362-4.09,3.542-5.224
                c0.629-0.327,1.08-1.29,1.302-2.052c1.166-4.009,2.634-7.81,6.711-10.267c0.325,1.808,0.536,3.959,2.605,5.438
                c1.304,0.933,0.469,3.161,0.759,4.785c0.63,3.541,2.342,4.253,5.039,1.975c1.265-1.068,2.334-2.166,4.079-2.668
                c3.825-1.101,3.78-1.256,5.216-4.988c3.509,3.648,3.797,3.521,6.673-2.945c2.265,2.766,2.265,2.766,6.171,0.837
                c-0.178,3.685,2.703,4.46,5.224,5.694c1.013,1.768,0.509,3.885,1.116,5.785c0.417,1.301,0.709,2.773,2.469,2.539
                c1.33-0.178,2.029-1.413,1.838-2.595c-0.692-4.261,3.371-6.584,4.079-10.337c0.241-1.279,2.398-2.538,4.15-1.74
                c2.347,1.068,5.261-0.188,7.344,2.379c1.106,1.363,2.659,0,3.57-1.389c0.987-1.504,0.707-3.633,2.725-5.043
                c0.397,3.852,3.933,5.486,5.436,8.6c0.917,1.902,3.078,1.879,4.076,0.197c1.849-3.114,5.852-3.836,7.378-7.211
                c0.516-1.141,1.822-1.365,3.035-1.388c2.904-0.052,5.605-0.69,7.438-2.71c1.872,0.154,2.172,1.38,2.71,2.383
                c1.961,3.654,1.964,3.652,6.055,3.252C1281,650.555,1281,658.223,1281,665.889z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M1281,154.889c-0.74-0.358-1.471-1.003-2.224-1.032
                c-7.5-0.284-13.483-4.319-19.562-7.978c-2.997-1.804-5.704-1.113-8.793,0.003c-2.726-2.267-5.682-4.126-9.268-1.345
                c-0.303,0.235-0.962,0.026-1.455-0.003c-6.412-0.377-8.482,1.141-10.912,6.824c-3.669,8.585-4.842,17.545-4.799,26.759
                c0.001,0.327-0.109,0.654-0.205,1.193c-3.242,1.393-6.739,0.797-10.176,0.809c-1.33,0.004-2.064-0.912-2.844-1.79
                c-2.191-2.464-5.047-3.634-8.172-4.507c-7.02-1.961-14.565-2.675-20.598-7.209c-5.411-4.066-11.199-6.998-17.874-8.178
                c-0.968-0.171-1.879-0.664-2.819-1c-4.54-1.621-4.54-1.62-8.797,1.452c-3.415-1.284-6.978-2.633-10.547-3.964
                c-3.754-1.399-7.592-2.473-11.559-3.096c-3.477-0.546-6.293,0.348-9.275,2.453c-4.154,2.933-8.924,4.917-13.88,6.418
                c-7.812,2.366-14.794,5.946-18.069,14.196c-0.672,1.694-1.298,3.529-2.433,4.895c-3.392,4.08-4.106,8.884-4.806,13.911
                c-0.754,5.412-2.285,10.716-3.485,16.065c-0.376,1.676-1.821,2.31-3.108,3.036c-3.28,1.849-5.317,4.751-7.615,7.664
                c-3.104,3.932-4.688,8.238-6.104,12.838c-0.777,2.52-2.087,4.904-3.363,7.236c-4.84,8.838-8.314,18.147-10.048,28.09
                c-0.169,0.971-0.418,1.927-0.642,2.942c-3.486,1.66-7.222,1.578-10.78,2.173c-4.188,0.701-8.059,1.806-11.401,4.605
                c-3.324,2.783-7.623,3.152-11.639,4.178c-1.69,0.432-2.823-0.741-4.098-1.454c-8.584-4.802-17.514-8.888-26.445-12.988
                c-14.371-6.597-28.166-5.417-41.445,2.884c-5.997,3.75-12.4,6.432-19.285,7.977c-1.113,0.25-2.231,1.009-3.394,0.514
                c-6.106-2.599-11.115,0.369-16.179,3.105c-0.287,0.155-0.616,0.629-0.773,0.567c-3.683-1.446-6.039,1.573-9.023,2.509
                c-4.348,1.363-7.734,4.256-11.682,6.213c-1.525,0.571-1.936-1.628-4.109-1.272c-2.323,0.856-2.436,4.386-4.683,6.062
                c-7.421-2.819-14.479-2.201-21.694,2.053c-3.73-1.137-7.986-2.049-11.9,1.195c-0.974,0.807-2.111,0.986-3.295,0.729
                c-1.18-0.256-2.404-0.386-3.405,0.256c-1.835,1.177-3.581,2.344-5.827,1.038c-0.124-0.072-0.328-0.004-0.494-0.005
                c-2.418-0.018-3.776,3.128-5.488,2.836c-2.545-0.434-4.101-0.095-5.631,1.832c-0.586,0.738-1.71,1.72-2.399,1.59
                c-2.935-0.555-4.903,1.429-7.296,2.129c-3.182,0.93-5.174,2.644-6.951,5.434c-3.18-2.229-6.102-1.735-8.885,0.418
                c-1.4,1.084-3.12,1.119-4.764,1.265c-2.158,0.191-4.331,0.196-6.258,1.417c-0.412,0.262-1.035,0.641-1.375,0.504
                c-4.625-1.856-6.537,1.262-8.644,4.417c-1.05,1.573-2.931,2.105-4.864,2.09c-1.168-0.009-2.707-0.258-3.318,0.673
                c-1.855,2.828-4.819,3.609-7.629,4.789c-1.258,0.528-1.987,1.625-2.655,2.852c-1.036,1.902-2.345,3.645-4.772,4
                c-0.471,0.069-1.105,0.29-1.316,0.646c-2.186,3.708-6.271,4.679-9.669,6.472c-3.511,1.853-6.899,4.162-10.952,5.14
                c-3.922-3.571-7.789-6.977-12.11-9.822c-5.715-3.762-11.828-5.849-18.804-5.617c-9.832,0.327-19.631,0.958-29.405,2.027
                c-0.823,0.09-1.634,0.302-2.451,0.454c-4.288,0.794-6.731,3.163-7.151,7.692c-0.479,5.177-2.076,10.105-4.156,14.855
                c-0.797,1.821-1.805,3.558-2.812,5.278c-1.262,2.156-2.791,3.412-5.683,2.661c-3.286-0.854-5.711,1.179-7.774,3.487
                c-0.884,0.989-1.663,2.089-2.378,3.209c-0.912,1.428-2.057,1.865-3.78,1.71c-7.131-0.643-14.289-1.01-21.414-1.712
                c-4.868-0.479-9.286-2.527-13.649-4.641c-1.279-0.62-1.979-1.589-2.644-2.82c-2.309-4.277-6.334-6.211-10.803-7.351
                c-1.98-0.505-4.148-0.543-5.767,0.781c-2.28,1.865-4.557,1.516-7.026,0.784c-2.748-0.814-5.531-1.345-8.295-0.038
                c-1.003,0.475-1.934,0.096-2.911-0.084c-11.949-2.209-23.942-4.205-35.688-7.387c-8.601-2.331-16.55-0.149-24.071,3.815
                c-7.51,3.958-14.797,8.339-22.182,12.537c-1.438,0.817-2.871,1.644-4.735,2.712c-1.813-3.586-2.417-7.339-3.1-10.968
                c-1.017-5.397-2.689-10.319-7.128-13.877c-0.998-0.8-1.263-1.919-1.634-3.014c-3.056-9.019-8.414-16.589-14.829-23.481
                c-6.158-6.615-13.021-12.43-20.536-17.429c-2.58-1.716-4.598-3.765-6.127-6.412c-2.5-4.327-5.036-8.633-7.612-12.915
                c-2.339-3.887-4.998-7.451-9.22-9.588c-2.592-1.312-4.285-3.672-5.938-5.978c-4.86-6.779-9.017-14-13.21-21.196
                c-0.771-1.322-1.185-2.692-1.277-4.24c-0.926-15.465-1.829-30.933-2.888-46.39c-0.762-11.132-1.423-22.274-2.749-33.361
                c-1.931-16.152-10.602-26.926-25.836-32.451c-8.601-3.119-17.407-5.63-25.82-9.313c-31.597-13.833-62.285-29.538-93.107-44.968
                c-11.615-5.815-23.184-11.723-34.794-17.547c-15.356-7.703-31.673-12.075-48.697-14.031c-23.573-2.71-46.986-0.827-70.116,3.555
                c-23.198,4.395-45.72,1.747-68.042-3.702c-1.455-1.443-1.167-2.845-0.729-4.065c0.559-0.679,1.081-0.544,1.531-0.429
                c12.455,3.181,25.054,5.322,37.927,5.931c10.403,0.493,20.583-0.927,30.793-2.588c21.09-3.431,42.251-5.618,63.684-3.737
                c20.135,1.767,39.293,6.746,57.393,15.871c37.321,18.817,74.448,38.031,112.425,55.519c11.193,5.154,22.44,10.162,34.319,13.62
                c3.338,0.972,6.569,2.393,9.737,3.853c15.313,7.056,22.449,19.69,24.068,35.907c1.97,19.729,3.098,39.522,4.213,59.315
                c0.318,5.654,0.724,11.304,0.981,16.96c0.094,2.08,0.739,3.892,1.807,5.625c3.319,5.388,6.59,10.807,9.933,16.18
                c2.395,3.849,5.18,7.24,9.331,9.491c3.441,1.866,5.567,5.233,7.553,8.519c2.583,4.275,5.068,8.616,7.42,13.022
                c1.217,2.281,2.831,3.958,4.977,5.409c9.985,6.754,19.095,14.514,26.643,23.989c4.385,5.505,8.074,11.414,10.495,18.064
                c0.451,1.239,0.852,2.505,1.909,3.454c4.581,4.109,6.426,9.591,7.622,15.387c0.294,1.426,0.081,3.048,1.503,4.061
                c1.816-0.041,3.077-1.24,4.5-2.037c5.376-3.013,10.667-6.18,16.092-9.101c10.04-5.405,20.411-7.512,31.756-4.062
                c9.553,2.905,19.513,4.219,29.325,6.08c1.973,0.375,3.945,0.079,5.914,0.076c6.203-0.008,12.307-0.189,18.519-1.009
                c6.011-0.793,11.565,2.52,15.112,7.369c3.67,5.018,8.738,6.518,14.126,7.317c5.915,0.877,11.953,0.903,17.929,1.399
                c2.176,0.18,3.671-0.338,5.057-2.256c2.635-3.648,6.219-6.106,10.958-5.896c2.347,0.104,3.276-1.247,4.077-2.953
                c2.35-5.006,4.182-10.224,4.711-15.723c0.643-6.688,4.523-9.981,10.605-11.562c2.941-0.764,5.938-0.879,8.911-1.104
                c7.972-0.603,15.958-1,23.938-1.488c8.161-0.499,15.467,1.973,22.08,6.568c3.678,2.557,7.166,5.388,10.946,8.251
                c1.983-1.758,4.526-2.526,6.997-3.456c2.984-1.124,5.448-2.994,7.986-4.92c3.194-2.423,6.828-4.241,8.729-8.033
                c5.284-1.766,8.644-7.428,14.876-7.312c1.555,0.029,2.137-1.281,2.918-2.395c2.223-3.171,5.059-4.072,9.169-4.451
                c4.156-0.383,8.9-0.371,12.831-2.829c0.973-0.609,2.103-1.358,3.166-1.367c5.354-0.046,9.607-2.771,13.959-5.339
                c0.703-0.416,1.543-0.917,2.295-0.878c2.713,0.139,4.588-1.311,6.68-2.745c2.723-1.866,6.256-2.727,9.258-3.066
                c3.766-0.427,7.27-1.729,10.967-2.276c3.607-0.533,7.227-1.511,10.858-2.155c8.336-1.477,16.489-4.531,24.944-2.939
                c1.826-0.915,2.344-2.684,3.635-3.731c1.594-1.292,3.098-2.437,5.385-1.38c2.155,0.996,4.424,0.185,6.035-1.086
                c1.891-1.492,4.197-1.968,6.034-3.11c6.331-3.938,13.673-4.547,20.396-7.082c1.285-0.484,2.63-0.913,3.898-0.525
                c6.883,2.102,12.609-1.277,18.467-3.848c2.579-1.132,4.924-2.788,7.413-4.137c4.853-2.63,9.94-4.62,15.345-5.862
                c8.32-1.913,16.225-0.237,23.98,2.593c9.721,3.548,19.057,8.045,27.95,13.252c4.522,2.648,8.753,2.25,12.091-0.258
                c6.209-4.666,13.142-6.162,20.458-7.072c0.635-0.079,1.235-0.433,2.045-0.729c0.596-2.552,1.104-5.156,1.816-7.702
                c1.937-6.914,4.53-13.571,7.901-19.928c1.48-2.791,3.146-5.574,4.036-8.566c1.479-4.969,3.274-9.548,6.616-13.715
                c2.41-3.004,4.414-6.249,7.871-8.142c1.802-0.987,2.559-2.504,2.896-4.385c1.022-5.725,3.233-11.22,3.297-17.132
                c0.005-0.493,0.142-0.989,0.254-1.474c0.888-3.83,9.317-19.455,12.364-21.978c4.853-4.02,10.001-7.476,16.454-8.816
                c4.168-0.866,8.183-2.869,11.588-5.463c4.036-3.077,8.151-3.996,13.085-2.972c5.742,1.191,11.265,2.95,16.664,5.169
                c1.732,0.712,3.329,1.063,5.187,0.021c2.301-1.29,4.826-0.967,7.271-0.117c4.084,1.42,8.109,2.986,12.438,3.637
                c2.146,0.322,4.048,1.492,5.728,2.922c4.768,4.06,10.092,6.966,16.298,8.234c3.093,0.631,6.092,1.709,9.17,2.428
                c2.992,0.7,5.754,1.854,7.89,4.056c1.698,1.751,3.557,2.315,6.212,1.658c0.854-4.198,1.921-8.532,2.13-12.995
                c0.279-6.024,3.04-11.231,5.576-16.401c2.084-4.247,5.882-6.785,10.99-6.38c1.695,0.135,3.266-0.116,4.893-0.607
                c1.235-0.374,2.94-0.774,3.895-0.224c4.411,2.543,9.584-0.007,13.93,2.33c1.901,1.023,3.726,2.19,5.58,3.302
                c4.738,2.84,9.826,4.688,15.314,5.401C1281,151.555,1281,153.222,1281,154.889z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M1281,24.889c-2.699,1.615-5.38,3.261-8.102,4.838
                c-3.76,2.18-7.621,4.018-12.033,4.629c-4.59,0.636-8.254,2.501-8.924,7.855c-0.326,2.602-2.411,3.905-4.711,4.687
                c-8.675,2.951-14.199,8.998-17.128,17.508c-2.126,6.177-6.868,9.684-12.731,10.828c-8.211,1.601-14.223,6.198-19.758,11.881
                c-2.093,2.15-4.182,3.946-7.378,4.625c-2.505,0.532-4.797,2.246-7.081,3.607c-8.44,5.033-16.818,10.171-25.271,15.185
                c-1.667,0.988-3.35,2.113-6.019,2.421c2.238-5.502,7.971-8.274,9.281-13.635c-1.326-1.58-2.465-0.707-3.346-0.16
                c-3.111,1.931-6.229,3.878-9.155,6.071c-6.132,4.597-12.169,9.322-18.192,14.061c-2.243,1.765-4.566,3.455-7.206,4.442
                c-6.297,2.355-11.949,5.839-17.604,9.373c-4.515,2.821-9.025,5.157-14.757,5.368c-4.687,0.173-9.573,1.85-11.726,7.095
                c-0.548,1.336-1.688,2.042-2.886,2.631c-12.997,6.392-25.997,12.78-39.813,17.284c-0.918,0.299-1.862,0.829-3.107,0.014
                c0.733-2.565,2.754-4.728,2.788-7.652c0.035-2.961-1.167-5.492-2.179-8.051c0.51-1.582,1.977-1.778,3.111-2.358
                c3.855-1.969,7.734-3.894,11.623-5.793c2.746-1.341,5.122-3.089,6.822-5.683c2.807-4.28,7-6.1,11.903-6.175
                c3.155-0.048,5.858-1,8.411-2.686c7.091-4.683,14.182-9.355,22.1-12.599c1.864-0.764,3.311-2.207,4.832-3.51
                c6.709-5.745,13.468-11.432,20.593-16.66c2.144-1.573,4.398-3.004,6.668-4.393c2.372-1.452,4.536-1.321,6.858,0.541
                c5.32,4.265,7.493,4.095,12.5-0.503c3.715-3.412,7.687-6.407,12.65-7.733c2.075-0.555,3.416-1.927,4.729-3.477
                c4.149-4.898,9.031-8.587,15.134-11.055c8.653-3.498,15.878-9.076,20.507-17.507c2.027-3.692,4.666-6.897,8.02-9.472
                c16.525-12.689,32.326-26.27,48.46-39.439c2.511-2.05,4.99-3.021,8.164-2.803c4.642,0.317,9.319-0.404,13.952,0.398
                C1281,7.555,1281,16.222,1281,24.889z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M1281,754.889c-2.366-1.434-5.008-0.746-5.137,1.605
                c-0.239,4.346-4.557,5.372-5.854,8.719c-0.459,1.184-2.669,1.114-3.365,2.705c-0.516,1.179-0.266,2.726-2.041,3.801
                c-2.936-2.027-7.584-2.059-7.811-7.707c-0.118-2.959-3.104-4.292-4.977-2.429c-2.695,2.682-7.114,2.683-8.653,6.388
                c-1.961-0.402-1.856-1.713-2.014-2.811c-0.165-1.15-0.11-2.34-0.342-3.473c-0.279-1.363-0.989-2.521-2.515-2.765
                c-1.508-0.24-2.491,0.569-3.261,1.802c-0.839,1.341-0.729,3.354-2.64,3.898c-1.039-0.022-1.444-0.792-2.012-1.39
                c-2.566-2.704-3.184-2.37-6.377-1.168c-4.949,1.864-10.162,2.587-15.216-0.022c-0.58-0.299-1.032-0.854-0.749-1.797
                c3.573-1.397,8.328,1.642,11.243-2.399c0.293-2.495-3.228-3.712-1.549-6.529c0.417-0.7-1.167-1.857-2.177-2.463
                c-1.825-1.096-3.682-2.14-5.941-3.446c1.989-1.276,4.272-0.195,5.67-2.01c1.207-1.57,3.639,0.225,5.289-1.511
                c-0.146-4.488-5.232-3.029-7.042-5.495c1.55-1.903,4.69-0.779,6.071-3.304c-0.041-1.968-1.594-2.934-3.204-2.985
                c-5.028-0.161-8.569-4.121-13.351-5.17c2.393-0.314,5.309,0.096,7.031-1.148c1.762-1.273,3.301-3.381,5.1-5.416
                c-4.271-3.382-7.746-7.541-14.816-7.462c4.703-2.952,9.264-0.792,13.425-3.497c-3.009-3.981-4.271-9.322-10.925-8.662
                c0.296-2.639,3.406-0.893,3.644-4.252c-0.44-2.375,0.508-5.674,2.937-9.834c0.604,3.252,0.955,5.461,1.438,7.639
                c0.357,1.617,0.672,3.299,1.383,4.77c1.627,3.365,4.349,4.001,7.274,1.689c2.863-2.262,6.315-3.445,9.442-5.182
                c2.504-1.389,3.892-2.982,4.399-5.521c0.716-3.58,1.247-7.195,1.859-10.805c1.575-0.177,1.498,1.037,1.673,1.883
                c0.676,3.257,0.99,6.607,2.235,9.718c1.553,3.88,3.62,4.835,7.361,3.069c2.095-0.989,3.571-0.758,5.509,0.4
                c2.8,1.676,4.052,1.016,5.332-2.022c1.217-2.89,1.05-6.229,3.029-9.38c2.243,1.956,1.859,4.568,2.414,6.778
                c0.357,1.429-0.016,2.979,0.846,4.354c1.524,2.438,3.66,2.527,4.909-0.014c0.724-1.471,1.028-3.152,1.491-4.748
                c0.653-2.257,1.26-4.706,3.189-6.098c4.298-3.1,6.458-7.398,7.771-12.305C1281,697.555,1281,726.223,1281,754.889z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M1281,923.889c-4.164-0.012-8.337-0.191-12.492,0.004
                c-6.808,0.32-13.172-1.066-19.107-4.373c-1.404-0.782-3.302-0.49-5.078-2.637c7.141,1.339,12.83-1.146,18.795-2.817
                c3.59-1.007,7.507-0.802,10.688-3.274c0.551-0.428,0.983-0.867,1.344-2.15c-4.489-5.199-10.809-1.207-16.678-2.57
                c1.784-1.853,4.607-1.182,5.619-3.671c-0.49-2.001-2.381-2.987-3.986-2.063c-3.557,2.045-6.997,0.284-9.466-0.976
                c-5.341-2.724-10.985-3.521-16.617-4.706c-4.177-0.879-4.19-0.814-7.826-4.439c2.054-1.418,4.147-2.475,6.629-2.6
                c7.794-0.391,15.319-2.278,22.94-3.748c3.577-0.69,7.162-0.983,10.428-2.716c1.406-0.745,3.178-1.137,3.586-3.809
                c-5.166-0.628-10.094-2.989-15.398-0.506c-1.525,0.714-3.119,1.239-4.83,0.947c-7.369-1.255-14.732-2.541-22.553-3.895
                c0.625-1.476,2.816-1.923,2.098-3.611c-0.577-1.354-1.9-1.837-3.178-2.2c-1.749-0.498-3.543-0.841-5.415-1.272
                c0.413-2.108,3.085-1.214,3.608-3.499c-1.593-3.324-5.268-2.765-8.258-3.589c-3.188-0.878-6.625-0.81-9.619-2.502
                c-0.864-1.883-1.444-3.742-0.225-5.709c1.976-1.016,3.543,0.302,5.16,0.946c3.59,1.43,7.203,2.621,11.138,2.336
                c2.24-0.162,4.224,0.709,6.274,1.414c3,1.033,6.041,1.806,8.65-1.249c3.509,1.696,7.266,2.281,11.412,2.552
                c1.497-3.711,4.776-3.03,7.863-2.662c2.718,0.325,5.361,0.733,6.979-2.231c-0.887-2.41-3.267-1.133-4.756-2.048
                c0.068-1.102,1.969-0.625,1.728-2.115c-0.31-1.703-2-1.936-3.311-2.312c-8.126-2.33-16.087-5.33-24.499-6.547
                c-6.309-0.913-12.172-3.37-18.271-5.002c-4.293-1.147-4.784-2.33-4.246-7.422c0.137-1.299-0.452-2.285-1.331-3.083
                c-1.496-1.357-2.69-2.894-3.374-4.819c0.729-1.436,1.916-1.121,3.016-0.719c2.498,0.912,4.975,1.889,7.44,2.885
                c2.079,0.84,3.614,0.664,3.752-1.965c6.845,0.986,13.441,0.126,19.438,0.328c2.142-0.566,0.153-2.903,2.255-3.119
                c1.141-0.116,2.274-0.553,2.967-1.883c0.184-1.936-1.485-2.59-2.795-3.535c-1.16-0.839-2.879-0.973-3.4-2.559
                c0.086-1.356,0.958-1.666,2.012-1.869c4.315-0.83,4.307-0.846,5.021-5.104c0.109-0.648,0.259-1.3,0.488-1.913
                c0.103-0.273,0.451-0.453,0.861-0.836c2.225-0.354,3.048,1.304,3.494,3.124c0.514,2.093,0.759,4.252,1.117,6.383
                c0.844,5.017,2.923,5.853,7.107,2.896c2.041-1.441,3.475-3.126,2.787-5.851c-0.405-1.606-0.126-2.997,1.398-3.894
                c3.175-1.865,4.492-4.767,4.743-8.3c0.085-1.197,0.466-2.277,1.505-3.035c1.413-0.049,2.111,0.818,2.4,2.045
                c0.342,1.454,0.645,2.927,0.827,4.408c0.882,7.141,1.824,7.996,9.142,8.137C1281,844.555,1281,884.223,1281,923.889z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M1281,587.889c-1.757,1.385-1.578,3.459-1.879,5.352
                c-0.257,1.609,0.05,3.388-1.623,4.713c-3.183,0.127-4.795-6.33-9.037-1.599c-1.153-3.864-1.419-7.466-5.699-8.11
                c-0.609-0.092-0.949-1.65-1.507-2.466c-1.789-2.618-4.767-2.821-5.792-0.001c-1.617,4.447-5.565,6.607-8.404,9.812
                c-1.487,1.679-1.96,3.339-2.085,5.353c-0.141,2.266,0.232,4.65-1.327,6.784c-0.552-0.364-1.127-0.534-1.309-0.899
                c-0.771-1.546-1.908-2.355-3.619-1.863c-1.671,0.48-2.128,1.984-2.463,3.469c-0.353,1.561,0.046,3.318-1.34,5.025
                c-1.196-0.807-1.105-1.981-1.315-2.978c-0.567-2.688-0.272-5.722-3.643-7.098c-1.104-0.45-0.866-2.051-1.058-3.185
                c-0.305-1.803-0.557-3.616-0.941-5.401c-0.268-1.243-1.096-2.128-2.42-2.141c-1.267-0.013-2.124,0.722-2.635,1.929
                c-0.605,1.432-0.299,3.248-1.789,4.684c-1.652-1.38-1.157-3.258-1.742-4.693c-0.53-1.299-1.069-2.542-2.677-2.539
                c-1.544,0.004-2.482,1.028-2.739,2.443c-0.56,3.078-1.008,5.911-3.798,8.234c-1.385,1.152-1.204,4.187-1.709,6.275
                c-1.821,0.016-1.702-1.27-2.007-2.084c-0.517-1.384-1.268-2.375-2.854-2.285c-1.784,0.102-2.454,1.32-2.956,2.887
                c-0.421,1.316,0.008,2.979-1.412,4.023c-1.527-0.869-1.129-2.354-1.283-3.582c-0.165-1.322-0.221-2.643-0.78-3.891
                c-0.434-0.968-1.035-1.756-2.123-1.866c-1.325-0.134-2.175,0.709-2.776,1.759c-0.8,1.397-0.313,3.369-2.145,4.582
                c-3.454-9.106,0.694-15.934,6.314-22.391c1.916,1.992,0.803,5.482,3.303,7.266c1.786-0.137,2.123-1.503,2.302-2.794
                c0.296-2.14,0.449-4.302,0.571-6.46c0.188-3.291,2.693-11.228,4.781-14.883c1.641,1.737,1.311,4.514,3.232,6.072
                c0.241,0.195,0.592,0.256,0.926,0.393c2.477-0.664,2.545-3.274,4.14-5.336c1.513,3.506,5.298,5.094,5.779,9.131
                c0.144,1.207,0.9,2.664,2.665,2.393c1.379-0.211,1.711-1.414,2.028-2.553c1.218-4.369,1.009-8.919,1.69-13.357
                c0.273-1.786,0.131-3.645,0.945-5.568c1.599-0.053,1.785,1.988,3.81,1.971c4.647-3.818,0.854-10.318,3.853-15.159
                c2.861,3.341,6.217,5.912,10.378,7.267c1.478-0.338,2.56-1.47,2.577-2.74c0.069-4.98,2.901-9.075,4.074-13.744
                c4.361,1.962,2.553,6.977,4.594,10.039c2.817-0.266,3.914-1.951,3.904-4.042c-0.028-5.536,2.727-9.837,5.625-14.359
                c1.499,4.259,2.749,6.158,7.339,10.556c0.849,0.812,0.984,1.627,1.087,2.604c0.156,1.471,0.062,3.373,2.114,3.32
                c2.033-0.053,1.726-2.057,2.075-3.447c0.159-0.631,0.514-1.214,0.779-1.818C1281,556.555,1281,572.223,1281,587.889z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M1281,371.889c-1.928,4.133-2.015,8.676-2.733,13.051
                c-1.025,6.238-1.621,12.554-1.716,18.889c-0.228,15.195-2.309,30.188-4.748,45.15c-0.5,3.071-1.371,5.583-4.765,6.815
                c-3.792,1.377-5.366,4.661-6.042,8.523c-1.638,9.339-3.258,18.688-5.67,27.868c-1.436,5.465-2.964,10.904-4.445,16.334
                c-1.438,0.124-1.533-1.846-3.592-1.362c-2.604,4.456-0.845,10.896-4.967,15.159c-1.562-1.418-1.416-3.354-1.062-5.369
                c1.554-8.851,3.115-17.7,4.16-26.631c0.606-5.177,2.537-9.994,4.293-14.828c6.669-18.356,8.945-37.495,10.165-56.8
                c0.894-14.124,1.438-28.269,2.103-42.407c0.087-1.863,0.373-3.697,1.171-5.333c3.015-6.177,3.659-12.772,3.481-19.477
                c-0.186-7.037,0.793-13.956,1.813-20.867c1.049-7.106,5.31-11.279,12.554-12.715C1281,335.889,1281,353.889,1281,371.889z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M1281,276.889c-3.723-0.969-7.138-2.729-10.412-4.623
                c-4.853-2.806-9.969-4.538-15.508-5.072c-2.527-0.244-4.837-1.055-7.124-2.106c-6.494-2.985-13.107-5.718-19.312-9.303
                c-0.575-0.333-1.157-0.683-1.78-0.894c-6.023-2.039-11.15-5.13-15.334-10.21c-3.555-4.317-9.214-5.775-14.341-7.726
                c-2.606-0.992-5.267-1.845-7.87-2.75c-0.456-3.495,1.574-5.335,3.338-7.257c2.793-3.047,6.193-5.598,7.682-10.062
                c7.62,1.288,15.281,1.469,22.166,5.365c6.803,3.85,13.404,7.778,19.068,13.424c4.861,4.846,10.145,9.741,17.578,10.708
                c0.657,0.086,1.299,0.332,1.955,0.374c2.248,0.143,3.167-0.731,2.797-2.926c-0.244-1.453-0.877-2.839-1.496-4.747
                c6.799,0.543,13.194,1.445,18.594,5.803C1281,255.555,1281,266.222,1281,276.889z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M1281,241.889c-10.31-3.962-15.797-5.138-21.694-4.603
                c-0.62,2.842,3.392,4.134,2.38,7.366c-3.456-0.105-6.847-0.702-9.678-2.871c-4.348-3.333-8.783-6.542-12.548-10.625
                s-8.712-6.731-13.658-9.055c-4.85-2.278-8.24-5.353-9.12-10.785c-0.053-0.321-0.238-0.62-0.358-0.93
                c-2.139-5.531-3.483-11.487-9.177-15.709c3.32-0.307,5.832,0.195,7.849,1.339c5.685,3.225,12.005,3.982,18.196,4.821
                c11.059,1.498,22.148,2.167,33.084-1.511c4.705-1.583,9.725-2.166,14.725-2.437C1281,211.889,1281,226.889,1281,241.889z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M1281,802.889c-6.178,0.083-6.396-0.12-7.315-6.078
                c-0.304-1.967-0.585-3.963-1.181-5.85c-0.543-1.716-2-2.787-3.821-2.652c-1.642,0.123-2.601,1.334-2.919,2.98
                c-0.221,1.143-0.511,2.274-0.693,3.423c-0.301,1.892-1.049,3.552-2.674,4.609c-2.58,1.68-3.978,3.585-3.329,7.022
                c0.61,3.236-2.642,3.637-4.554,5.141c-2.26-2.271-1.535-4.996-2.161-7.31c-0.521-1.925-0.669-3.951-2.026-5.547
                c-1.035-1.216-2.348-1.837-3.977-1.61c-1.786,0.248-2.351,1.6-2.842,3.082c-0.465,1.406,0.023,3.026-1.195,4.592
                c-2.678-0.149-5.191,0.783-6.992,3.522c-2.885-3.003-6.504-3.829-9.854-4.95c-1.058-3.112-0.143-5.273,2.453-6.902
                c-2.26-2.742-5.09-2.625-7.914-2.517c-2.926,0.112-5.265,0.296-7.502-2.988c-1.968-2.888-6.252-4.122-10.182-5.359
                c1.583-1.958,3.486-1.476,5.1-1.178c4.175,0.771,7.883,0.167,11.26-2.575c1.553-1.261,3.4-1.797,5.586-1.568
                c2.999,0.314,3.021,0.108,5.453-2.273c-0.437-2.328-3.133-2.129-4.412-3.794c1.79-0.607,4.186-0.428,4.406-2.735
                c0.264-2.77-2.725-2.411-4.435-3.935c1.263-1.136-0.21-2.661,0.171-3.962c1.536-0.928,2.353-0.349,3.281,0.578
                c3.76,3.755,4.867,3.53,7.412-1.347c0.387-0.742,0.729-1.497,1.88-1.747c1.682,1.221,0.826,3.262,1.296,4.889
                c0.451,1.561,0.494,3.453,2.475,3.997c1.902,0.522,3.088-0.571,4.145-2.031c1.994-2.757,6.04-2.122,7.489-5.341
                c0.876,0.76,1.333,0.976,1.326,1.178c-0.164,5.279,3.853,7.202,7.536,9.311c2.239,1.281,4.773,0.662,5.266-1.744
                c0.785-3.848,4.55-4.886,6.428-7.723c1.104-1.668,2.806-2.829,3.116-5.157c0.338-2.53,1.88-2.894,3.899-1.45
                C1281,772.223,1281,787.555,1281,802.889z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M1281,295.889c-9.224-3.526-18.938-5.067-28.569-6.856
                c-4.942-0.918-8.604-3.26-11.113-7.478c-1.665-2.797-4.103-4.864-7.229-4.56c-5.879,0.572-10.733-2.262-15.657-4.329
                c-7.589-3.184-14.761-7.357-22.258-11.187c5.505-4.04,10.815-7.402,17.765-7.561c4.143-0.095,7.94,0.325,11.526,2.321
                c6.989,3.89,14.267,7.179,21.548,10.463c2.595,1.17,5.222,2.051,8.1,2.352c4.832,0.505,9.357,1.947,13.676,4.365
                c3.877,2.17,7.836,4.334,12.213,5.471C1281,284.555,1281,290.222,1281,295.889z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M1281,528.889c-1.786,2.318-0.926,5.141-1.438,7.703
                c-0.386,1.938-0.505,3.93-1.039,6.03c-0.176-4.093-5.111-4.913-5.63-8.713c-2.849-1.135-2.883-3.626-3.013-6.118
                c-0.053-1.014-0.447-1.936-2.189-2.434c-3.666,6.027-7.543,12.046-8.293,19.674c-0.082-4.326-4.029-7.023-3.544-11.523
                c-4.039,1.781-6.249,6.935-8.352,18.215c-4.386-1-7.804-3.438-10.804-7.305c-5.125,4.175-2.291,10.223-4.137,14.742
                c-1.97,0.582-1.468-2.396-3.34-1.851c-2.83,1.011-2.589,3.843-2.961,6.155c-0.605,3.77-0.777,7.608-1.212,11.408
                c-0.163,1.432,0.15,3.052-1.856,4.669c-0.391-5.129-3.386-8.4-6.727-11.567c-3.259,0.01-2.816,3.677-4.93,5.203
                c-0.953-1.237-1.909-2.571-1.357-3.979c1.912-4.869,4.062-9.645,6.416-14.473c0.134,3.611,1.994,6.232,4.279,8.678
                c1.483,0.203,2.047-0.889,2.468-1.951c1.649-4.166,3.166-8.336,2.849-12.982c-0.357-5.254,1.152-10.06,4.033-14.629
                c3.316,1.453,0.492,5.823,4.307,7.223c2.816-5.338,1.107-12.148,5.783-17.447c1.08-0.467,0.557,2.672,3.39,1.575
                c2.042-3.401,3.133-8.278,4.288-12.997c3.726,1.619,2.232,5.713,3.913,7.963c3.155,0.27,2.403-3.055,4.464-3.943
                c1.125,2.363,0.4,5.423,2.912,7.322c3.708-2.434,2.451-7.633,6.326-10.248c0.744,0.994-0.123,3.305,1.87,3.651
                c1.063-1.069,5.954-12.938,7.032-17.185c2.309,1.564,3.615,3.344,3.768,6.23c0.097,1.84-0.643,4.668,2.723,4.902
                C1281,516.889,1281,522.889,1281,528.889z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M1281,194.889c-5.304,0.555-10.621,1.179-15.693,2.849
                c-9.948,3.273-20.123,2.733-30.188,1.503c-6.517-0.796-13.202-1.457-19.249-4.83c-2.523-1.407-5.715-1.854-8.664-2.195
                c-2.855-0.33-4.996-1.58-6.992-3.439c-1.828-1.702-3.738-3.315-5.612-4.967c-4.347-3.829-9.239-6.477-15.089-7.196
                c-3.014-0.371-5.509-1.694-7.959-3.672c-4.264-3.44-8.998-6.296-13.52-9.42c-0.652-0.45-1.555-0.686-1.713-1.764
                c0.236-1.344,0.982-1.956,2.428-1.402c4.201,1.609,8.439,3.061,12.909,3.792c0.905,0.148,1.766,0.903,2.532,1.521
                c5.921,4.78,12.471,8.224,19.986,9.759c2.605,0.532,5.137,1.417,7.734,1.993c2.711,0.601,5.088,1.742,6.646,4.065
                c1.27,1.894,2.919,2.415,5.085,2.415c4.157-0.001,8.306-0.061,12.838-0.636c3.537,6.716,9.795,7.707,16.592,7.485
                c1.83-0.06,3.726-0.273,5.486,0.092c6.786,1.408,11.585-2.556,17.099-5.785c-2.732-3.822-6.328-4.358-10.238-3.939
                c-2.601,0.279-5.171,0.663-8.544-1.474c4.453-0.984,8.122-0.568,11.576-1.542c2.865-0.808,5.955-0.876,8.586-2.465
                c1.744-1.054,3.265-0.157,4.746,0.442c2.974,1.203,5.981,2.039,9.219,1.807C1281,183.555,1281,189.222,1281,194.889z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M1281,492.889c-1.621,2.346-0.173,5.227-1.267,7.651
                c-3.391,0.022-2.397-4.191-5.538-5.345c-2.336,5.163-3.231,10.898-6.248,15.675c-1.288-0.696-0.783-2.495-2.847-2.673
                c-2.636,3.007-2.894,7.599-5.595,11.074c-1.573-2.143-0.57-5.272-2.743-7.041c-2.479-0.191-2.231,2.462-3.623,3.65
                c-1.358-2.219-1.339-4.25-0.667-6.419c4.583-14.811,7.896-29.921,10.412-45.205c0.538-3.266,2.146-5.459,5.02-6.953
                c4.144-2.154,8.254-4.354,13.096-4.416C1281,466.223,1281,479.555,1281,492.889z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M1281,175.889c-4.732,0.854-8.625-2.021-12.957-2.946
                c-0.879-0.188-1.072-1.194-1.537-1.864c-3.746-5.396-8.598-9.309-15.216-10.322c-3.483-0.533-5.204-2.352-5.997-5.56
                c-0.438-1.767-1.066-3.492-1.684-5.208c-0.478-1.326-0.037-2.236,1.021-2.917c0.99-0.236,1.69,0.25,2.237,0.868
                c1.806,2.04,3.788,2.459,6.169,0.984c1.564-0.969,3.008-0.722,4.602,0.331c2.062,1.363,4.553,2.107,6.53,3.564
                c3.76,2.77,7.885,4.097,12.485,4.215c1.49,0.039,2.777,1.169,4.346,0.854C1281,163.889,1281,169.889,1281,175.889z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M1281,313.889c-3.998,1.862-7.571,1.559-10.787-1.771
                c-1.251-1.295-2.868-2.257-4.389-3.265c-3.778-2.504-7.027-5.323-8.555-9.894c-0.934-2.793-3.201-4.96-4.888-7.688
                c9.729,1.657,19.437,2.955,28.618,6.618C1281,303.222,1281,308.555,1281,313.889z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M1281,74.889c-1.375,0.778-2.479,1.997-2.447,3.496
                c0.132,6.063-4.901,8.155-8.491,11.334c-0.723,0.641-1.917,0.724-2.882,0.379c-2.938-1.049-4.84,0.451-6.32,2.621
                c-1.53,2.246-3.566,3.927-5.572,5.672c-2.47,2.149-5.502,3.894-5.371,7.872c0.018,0.54-0.543,1.267-1.039,1.629
                c-3.882,2.832-8.011,5.353-12.805,6.056c-1.869,0.274-3.516,0.513-5.045,1.626c-4.035,2.936-8.004,5.967-12.343,8.463
                c-1.511,0.869-2.299,2.114-2.687,3.93c-0.941,4.395-3.325,6.126-7.775,5.87c-5.26-0.303-9.483,2.434-13.934,4.528
                c-0.424,0.2-0.822,0.619-1.055,1.036c-4.225,7.594-11.539,11.06-19.043,14.257c-0.58,0.247-1.289,0.235-1.939,0.244
                c-0.287,0.004-0.576-0.244-0.88-0.385c0.401-1.089,1.431-1.403,2.327-1.708c5.445-1.85,9.985-5.144,14.378-8.728
                c1.048-0.855,2.183-1.59,2.616-2.907c0.996-3.032,3.416-4.526,6.166-5.327c4.207-1.226,7.946-4.058,12.852-3.097
                c2.131,0.418,4.147-1.255,4.311-3.888c0.189-3.069,2.154-4.625,4.533-6.074c2.837-1.728,5.528-3.695,8.275-5.569
                c3.116-2.125,5.92-4.24,10.221-4.485c3.328-0.19,6.525-2.932,9.742-4.614c0.916-0.479,1.457-1.383,1.447-2.467
                c-0.025-2.86,1.895-4.461,3.822-6.095c2.785-2.36,5.611-4.633,7.777-7.682c1.574-2.217,3.807-3.873,6.859-2.84
                c1.688,0.571,2.984-0.017,4.049-1.152c2.504-2.668,6.16-4.397,5.964-9.138c-0.089-2.16,1.793-4.161,4.288-4.856
                C1281,73.555,1281,74.222,1281,74.889z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M1281,450.889c-2.432,0.418-4.863,0.837-7.641,1.314
                c0.029-4.535,1.133-8.697,1.799-12.889c1.827-11.528,2.764-23.111,3.151-34.776c0.255-7.639,1.214-15.254,1.908-22.876
                c0.056-0.609,0.513-1.183,0.782-1.773C1281,403.555,1281,427.222,1281,450.889z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M900.093,767.98c-2.104-0.639-1.817-2.182-2.122-3.395
                c-0.396-1.579-1.125-2.795-2.961-2.846c-1.787-0.049-2.691,1.033-3.25,2.572c-0.391,1.076-0.839,2.133-1.18,2.992
                c-4.074,1.798-7.314-0.148-10.681-1.408c-1.317-0.494-1.566-1.576-1.927-2.688c-0.451-1.393-1.373-2.305-2.911-2.275
                c-1.794,0.034-2.601,1.282-3.088,2.792c-0.503,1.562-0.952,3.142-1.411,4.672c-2.559,0.827-5.242-0.512-7.44,1.092
                c-2.073,1.512-1.83,4.68-4.25,6.069c-1.844-0.104-2.177-1.708-2.941-2.929c-2.485-3.963-5.109-3.958-7.446-0.018
                c-0.741,1.248-0.938,2.91-2.576,3.398c-1.511,0.184-2.192-0.836-2.744-1.844c-1.643-3-4.092-3.718-7.18-2.66
                c-2.805,0.962-4.545,0.078-6.229-2.437c-2.277-3.398-4.951-3.022-6.822,0.533c-0.31,0.587-0.52,1.227-0.78,1.839
                c-1.83,4.305-1.773,4.189-5.942,2.129c-2.502-1.236-5.131-2.031-7.119-4.28c-1.111-1.259-3.32-1.355-4.672-0.298
                c-2.786,2.18-5.96,1.861-9.062,1.92c-2.277,0.045-4.23,0.412-5.853,2.173c-1.153,1.252-2.853,1.438-4.106,0.435
                c-3.176-2.543-6.453-2.116-9.902-0.906c-0.414,0.146-0.963-0.094-1.328-0.141c-1.047-0.729-1.129-1.743-1.217-2.74
                c-0.139-1.594-0.57-3.156-2.367-3.354c-1.596-0.176-2.896,0.84-3.623,2.248c-1.929,3.733-6.393,5.057-8.003,9.583
                c-1.954-2.526-1.772-5.512-4.144-6.803c-2.116-1.151-3.539-2.777-4.627-4.974c-0.77-1.554-2.928-1.318-4.283,0.078
                c-0.861,0.887-1.169,2.037-1.357,3.171c-0.443,2.665-0.904,5.161-3.676,6.646c-1.473,0.79-0.59,3.31-2.344,4.198
                c-1.008-0.087-1.308-0.834-1.532-1.66c-0.377-1.388-1.142-2.579-2.657-2.579c-1.608-0.001-2.899,0.913-3.328,2.545
                c-1.021,3.895-4.803,5.106-6.981,7.874c-2.506-1.144-3.81-3.693-6.724-3.574c-1.33,0.054-1.215-1.766-1.664-2.79
                c-0.479-1.092-1.035-2.2-2.393-2.27c-1.482-0.074-2.428,0.799-3.123,2.073c-1.855,3.401-4.994,6.097-5.551,10.236
                c-0.064,0.477-0.916,0.847-1.297,1.177c-2.828-0.044-4.451-3.354-7.59-1.985c-2.666,1.162-2.059,4.479-4.277,5.825
                c-1.978-1.144-1.472-3.297-2.149-4.912c-1.689-4.027-3.489-4.489-6.249-1.115c-1.473,1.799-3.961,1.91-5.304,4.182
                c-1.036,1.752-4.098,1.664-5.53,3.256c-2.113-0.727-2.05-2.436-2.623-3.758c-0.459-1.062-0.864-2.166-1.471-3.142
                c-0.687-1.104-1.824-1.39-3.068-1.075c-1.075,0.271-1.617,1.068-1.928,2.082c-0.568,1.859,0.275,4.054-1.319,5.711
                c-2.095-0.207-1.833-2.097-2.366-3.33c-0.525-1.214-0.706-2.59-1.659-3.594c-1.585-1.668-3.927-1.246-4.804,0.902
                c-0.309,0.757-0.36,1.619-0.53,2.434c-0.135,0.648-0.271,1.297-0.418,2.001c-1.886,0.979-3.746,1.946-5.952,3.092
                c-2.136-4.444-6.416-5.87-10.325-7.689c-1.66-0.772-2.076-2.025-2.549-3.401c-0.574-1.672-1.302-3.105-3.337-3.184
                c-1.786-0.068-2.781,1.008-3.279,2.542c-0.788,2.423-4.494,2.311-4.496,5.55c-0.002,2.231,0.375,4.602-0.792,7.303
                c-4.062-3.012-2.372-8.389-5.454-11.494c-3.148,0.775-2.436,5.032-5.095,5.67c-2.814,0.675-4.068,2.611-4.875,4.812
                c-2.765,0.139-2.953-1.877-3.504-3.277c-1.329-3.374-1.994-7.071-5.973-8.698c-1.053-0.431-1.191-1.911-1.438-3.067
                c-0.27-1.261-0.289-2.691-2.028-3.414c-2.304,0.642-2.189,2.98-2.758,4.721c-0.693,2.119-1.245,4.055-3.821,4.713
                c-1.566,0.398-2.005,2.061-2.65,3.43c-0.67,1.423-0.605,3.383-2.589,3.959c-1.687-0.234-1.762-1.811-2.493-2.846
                c-1.789-2.531-3.758-2.387-6.326-0.764c-8.009,5.063-9.421,6.893-18.289,2.661c-2.069-0.987-4.198-1.639-6.037-3.227
                c-1.771-1.529-3.861-0.941-5.578,1.256c-1.083,1.388-2.08,3.036-3.94,2.112c-2.227-1.105-5.583-0.484-6.444-3.899
                c-0.201-0.799-0.491-1.614-0.92-2.31c-1.474-2.39-4.042-2.516-5.689-0.283c-0.486,0.659-0.76,1.475-1.141,2.214
                c-1.662,3.226-1.734,3.244-4.864,2.16c-2.658-0.921-5.013-1.851-6.472-4.795c-1.46-2.946-4.328-2.788-6.461-0.149
                c-0.726,0.898-1.207,1.99-1.839,2.969c-0.539,0.833-1.038,1.73-2.155,2.004c-1.66-0.277-1.813-1.777-2.188-2.975
                c-0.595-1.902-0.913-3.893-1.496-5.8c-0.494-1.616-1.23-3.306-3.129-3.546c-1.88-0.238-2.99,1.146-3.888,2.627
                c-0.946,1.559-1.884,3.124-2.873,4.656c-0.157,0.244-0.558,0.33-0.989,0.568c-0.353-0.07-1.042,0.002-1.262-0.284
                c-2.881-3.753-7.474-4.783-11.341-6.819c-2.38-1.254-3.581-2.677-4.226-5.006c-0.488-1.762-0.873-3.588-2.056-5.049
                c-1.443-1.781-3.203-1.77-4.538,0.086c-1.39,1.931-1.755,4.289-2.46,6.506c-0.995,3.128-1.259,6.543-3.327,9.164
                c-1.438,0.195-2.016-0.693-2.421-1.596c-1.104-2.459-2.207-4.736-5.048-5.838c-1.792-0.693-2.163-2.852-2.895-4.512
                c-1.333-3.021-2.163-6.328-5.479-8.055c-1.363-0.71-1.06-2.334-1.293-3.602c-0.331-1.797-0.425-3.646-0.877-5.408
                c-0.294-1.143-1.15-2.044-2.552-1.979c-1.131,0.053-1.907,0.729-2.236,1.713c-0.631,1.887-1.214,3.804-1.622,5.75
                c-0.833,3.97-1.688,7.721-6.015,9.676c-2.176,0.984-2.619,3.743-3.802,5.73c-1.668,2.799-2.882,3.162-4.937,0.796
                c-2.607-3.002-5.432-3.528-8.912-2.073c-2.452,1.025-4.499,0.498-6.259-1.544c-0.858-0.995-1.844-2.217-2.998-2.55
                c-2.607-0.75-3.759-2.419-4.349-4.857c-0.416-1.715-1.441-3.167-3.304-3.326c-1.983-0.17-2.88,1.445-3.55,2.996
                c-1.17,2.713-1.915,5.55-5.317,6.69c-1.361,0.456-1.082,2.653-1.399,4.089c-0.41,1.861-0.082,3.881-0.809,5.586
                c-1.833,0.076-1.814-1.174-2.193-1.994c-0.484-1.047-0.521-2.613-1.304-3.156c-4.112-2.853-3.513-7.258-3.953-11.27
                c-0.327-2.982-0.373-5.998-1.328-8.87c-0.429-1.29-1.136-2.53-2.812-2.294c-1.365,0.191-1.794,1.335-2.093,2.5
                c-0.71,2.768-0.785,5.605-0.892,8.436c-0.091,2.41-0.69,4.518-2.555,6.271c-2.615,2.461-3.359,6.01-4.626,9.342
                c-1.815-0.45-1.614-1.732-1.724-2.766c-0.3-2.814-0.428-5.652-0.831-8.451c-0.822-5.703-2.044-6.252-6.989-3.254
                c-1.885-1.526-1.7-3.842-2.138-5.889c-0.515-2.41-0.407-5.664-3.33-6.074c-3.512-0.495-3.554,3.263-4.71,5.472
                c-0.953,1.819-0.954,4.185-3.545,5.844c-0.449-3.729-1.56-6.729-5.145-7.541c-0.328-2.056,2.171-1.564,2.25-3.692
                c-1.67-2.089-4.681-1.868-7.231-2.668c-2.409-0.757-5.163-0.76-7.297-2.891c2.04-1.168,4.031,0.135,6.028-1.375
                c-2.026-3.294-5.949-2.96-8.741-4.559c-3.167-1.813-6.657-1.869-10.139-1.635c-0.17-4.271,4.077-2.013,5.253-3.908
                c0.217-1.646-0.882-2.211-1.928-2.664c-1.061-0.459-2.209-0.725-3.333-1.027c-6.142-1.647-6.592-2.48-4.821-9.438
                c6.052,0.203,11.762,4.021,18.887,2.448c-2.698-3.134-5.609-4.432-8.781-5.343c-2.878-0.827-5.763-1.636-8.609-2.564
                c-1.038-0.338-2.388-0.673-1.773-2.669c3.99-0.374,8.356,2.945,12.863-0.503c-4.915-3.488-12.103-2.707-14.225-9.303
                c2.447-3.229,2.49-3.287-0.562-5.545c-1.049-0.775-1.36-2.406-3.134-2.492c-1.921,0.415-3.093,1.906-3.169,3.82
                c-0.162,4.123-2.411,6.768-5.551,8.955c-0.549,0.383-1.028,0.85-1.073,1.749c1.797,2.624,4.513,0.342,7.036,1.048
                c-0.683,1.442-1.863,1.256-2.879,1.312c-3.484,0.193-7.013-0.244-10.444,0.684c-1.933,0.523-4.382,0.164-4.263,3.617
                c0.035,1.003-2.498,1.839-3.377,3.297c-0.575,0.953-1.86,1.492-1.636,3.146c1.531,0.949,3.062-0.003,4.6-0.268
                c2.825-0.486,5.676-0.77,7.957,1.426c1.212,1.167,2.425,0.725,3.717,0.532c2.12-0.315,4.222-0.925,6.417-0.466
                c1.188,1.393,0.52,2.814,0.227,4.166c-0.301,0.271-0.505,0.565-0.784,0.689c-3.209,1.422-6.634,2.179-9.96,3.24
                c-2.678,0.855-6.027,0.76-7.022,4.598c-0.292,1.125-2.21,1.258-3.529,1.531c-1.796,0.373-3.569,0.855-5.168,1.8
                c-1.209,0.714-1.064,1.499-0.046,2.282c-6.881,2.514-9.837,1.822-14.412-3.373c-0.22-0.249-0.376-0.637-0.649-0.747
                c-4.83-1.948-5.181-6.374-5.939-10.598c-0.175-0.972-0.403-1.981-0.852-2.847c-1.63-3.14-5.016-3.469-6.569-0.327
                c-1.257,2.545-2.683,3.91-5.494,4.147c-1.307,0.109-2.327,1.213-3.34,2.097c-0.874,0.763-1.766,1.98-2.736,2.071
                c-3.909,0.363-5.976,2.188-6.555,6.155c-0.244,1.674-2.131,1.946-3.569,0.801c-0.509-0.405-0.909-0.97-1.287-1.513
                c-0.473-0.676-0.961-1.374-0.705-2.418c2.099-1.786,5.884-0.983,7.599-4.686c-4.786-2.135-9.555-0.881-14.429-0.798
                c-0.339-2.235-0.658-4.334-0.975-6.434c-0.268-1.772-0.996-3.287-2.902-3.627c-2.076-0.37-3.6,0.718-4.415,2.54
                c-2.417,5.408-7.239,5.809-12.49,6.481c0.533-4.758-2.696-4.882-5.685-5.278c-0.526-1.25,1.404-1.189,1.112-2.346
                c-0.297-1.179-1.168-1.576-2.08-1.923c-2.844-1.081-5.917-0.836-8.849-1.438c-0.977-0.201-2.007-0.174-2.962-0.436
                c-1.411-0.384-1.392-0.453-1.563-3.683c3.543-0.532,6.856,0.843,10.247,1.335c3.951,0.574,7.912,1.998,11.806,1.803
                c4.071-0.205,8.006-0.513,11.933,0.724c4.479-2.712,9.64-1.271,14.375-2.394c0.385-0.092,0.658-0.654,0.946-0.959
                c-1.346-3.766-4.224-5.105-7.822-5.786c-9.648-1.825-19.27-3.792-28.878-5.815c-3.407-0.717-6.794-1.643-9.605-3.811
                c0.287-3.057,0.287-3.057-3.61-7.664c2.029-0.303,3.643,0.578,5.312,0.936c2.395,0.512,4.764,1.676,6.938-0.717
                c0.664-0.729,1.906-0.297,2.898-0.219c2.492,0.193,5.006,0.219,7.472,0.584c4.253,0.63,7.849-0.466,11.252-3.98
                c-4.746-4.777-11.429-5.562-16.928-8.126c-0.916-2.042,1.629-2.618,1.217-4.493c-0.128-0.158-0.354-0.656-0.738-0.865
                c-0.567-0.308-1.303-0.68-1.869-0.561c-6.627,1.4-11.223-3.851-17.112-5.221c7.167-2.045,14.957-0.832,21.806-4.188
                c0.345-2.247-1.941-4.502,0.851-5.736c-1.077-2.103-2.898-2.752-4.013-4.271c-1.474-2.01-4.206-1.578-6.523-1.502
                c-2.567,0.084-5.179,0.566-8.161-1.145c3.396-1.559,6.941,0.534,9.606-2.342c-1.792-2.691-1.317-6.927-6.202-7.315
                c1.921-3.104,4.892-2.069,7.222-2.948c-0.047-3.478-3.09-2.904-4.893-3.896c0.946-1.306,2.89-0.887,3.942-2.471
                c-0.64-2.819-3.284-2.594-5.35-3.148c-2.213-0.596-4.47-1.031-6.816-1.92c3.544-0.219,5.688-2.535,7.952-4.655
                c-0.59-2.618-0.59-2.618,1.487-4.607c1.075,3.037,2.591,5.594,4.886,7.771c1.53,1.451,1.56,3.959,1.967,6.085
                c0.469,2.45,0.832,4.935,1.918,7.216c0.588,1.236,1.368,2.398,2.895,2.414c1.631,0.016,2.212-1.332,2.672-2.586
                c1.327-3.617,1.724-7.451,2.536-11.189c0.765-3.525,1.113-7.199,3.008-10.379c2.51,0.537,2.829,2.553,3.562,4.127
                c0.983,2.109,1.658,4.36,2.623,6.479c2.514,5.519,4.668,5.932,9.11,1.837c2.386,1.235,2.55,3.757,3.293,5.892
                c0.659,1.895,1.141,3.847,2.568,5.346c1.879,1.973,3.51,1.711,4.561-0.851c0.563-1.373,0.826-2.877,1.146-4.339
                c0.317-1.449,0.32-2.998,1.52-4.145c1.761,0.344,1.819,1.838,1.807,3.061c-0.042,4.07,1.598,7.031,5.234,8.919
                c0.553,0.287,0.921,0.931,1.572,1.618c-1.749,2.729-1.263,5.044,1.098,7.41c3.021,3.026,4.292,6.72,2.232,10.943
                c-0.301,0.617-0.315,1.277,0.277,2.229c2.142,0.863,4.128,0.881,5.567-1.811c0.53-0.99,1.344-2.041,2.302-2.553
                c3.411-1.823,6.172-3.902,5.778-8.371c-0.087-0.988,0.883-1.812,1.815-2.221c3.756-1.642,5.395-4.736,6.111-8.542
                c0.081-0.432,0.52-0.796,1.007-1.503c3.63,5.409,9.119,8.238,14.234,11.538c1.226,0.791,1.804,1.916,2.054,3.272
                c0.182,0.982,0.252,1.991,0.501,2.955c0.668,2.592,2.614,3.871,5.057,4.198c2.619,0.351,3.872-1.518,4.685-3.655
                c0.585-1.538,0.803-3.223,1.754-4.513c1.645-0.414,2.302,0.552,2.739,1.687c1.111,2.883,3.062,5.096,5.313,7.142
                c1.933,1.757,3.358,3.758,1.9,6.582c-1.285,2.489-0.338,4.466,1.538,6.229c1.462,1.375,2.729,2.931,3.688,4.704
                c1.077,1.993,1.121,4.038,0.715,6.286c-0.458,2.537,1.415,4.689,3.8,4.826c2.498,0.145,2.945-1.779,3.316-3.596
                c0.299-1.463,0.339-2.978,0.574-4.457c0.614-3.865,1.728-7.628,4.959-10.066c3.471-2.618,5.113-6.004,5.624-10.153
                c0.099-0.802,0.411-1.577,0.608-2.304c1.873-0.223,2.076,1.176,2.29,2.213c0.66,3.205,2.126,5.902,4.381,8.262
                c2.492,2.607,3.191,5.539,2.104,9.178c-1.406,4.709,0.066,6.418,5.237,6.967c1.26-1.242,0.665-2.803,0.597-4.226
                c-0.169-3.553,0.776-6.757,3.615-8.896c3.027-2.279,3.061-6.135,5.059-8.863c2.631,0.469,2.495,2.634,3.015,4.262
                c0.502,1.574,0.608,3.277,1.14,4.838c0.642,1.889,2.108,3.225,4.129,3c2.214-0.245,4.207-1.565,6.606-1.447
                c0.82,0.039,1.459-1.247,1.612-2.313c0.261-1.812,0.49-3.628,0.808-5.429c0.248-1.4-0.318-3.057,1.161-4.103
                c1.126-0.021,1.492,0.872,1.569,1.587c0.34,3.151,0.311,6.357,3.696,8.201c0.901,0.491,0.577,1.823,0.626,2.811
                c0.063,1.287,0.743,2.081,1.877,2.699c5.526,3.013,7.047,8.362,7.915,14.004c0.25,1.627-0.072,3.336,0.115,4.979
                c0.265,2.329,1.371,4.022,3.717,4.251c1.651-0.752,1.533-2.025,1.626-3.146c0.184-2.229,0.61-4.27,2.738-5.565
                c1.055-0.644,1.483-1.834,1.679-2.975c0.363-2.112,1.296-3.841,3.213-4.785c2.656-1.308,2.438-4.687,4.632-6.345
                c1.91,0.793,1.326,2.533,1.711,3.839c0.41,1.394,0.365,3.016,2.214,3.958c4.307-2.046,2.818-6.979,4.887-10.289
                c1.498-0.143,3.241,2.062,4.724-0.314c0.848-1.361,1.085-3.238,2.945-3.893c1.563,0.177,1.638,1.488,1.734,2.58
                c0.159,1.82,0.047,3.664,0.151,5.492c0.316,5.522,1.853,6.342,6.758,3.641c2.098,1.996,0.166,6.338,4.291,7.299
                c3.473-1.645,2.463-5.951,5.007-8.558c2.262,2.701,5.262,4.458,5.907,8.62c0.352,2.27,2.735,4.265,3.658,6.797
                c0.684,1.877,3.483,1.621,3.647-0.321c0.312-3.698,3.314-5.286,4.927-7.907c2.848,1.434,3.476,3.845,3.435,6.629
                c-0.04,2.665-0.127,5.346,0.578,7.951c0.272,1.007,0.914,1.814,2.05,1.898c1.195,0.088,1.889-0.719,2.121-1.73
                c0.818-3.57,2.911-6.257,5.538-8.68c1.646-1.518,1.067-4.178,2.823-6.044c1.502,2.581,1.424,5.906,4.076,7.671
                c-1.098,3.273,1.763,5.782,1.708,8.869c-0.028,1.609,0.426,3.1,2.383,3.166c1.727,0.059,2.115-1.298,2.335-2.692
                c0.127-0.803,0.001-1.894,0.862-2.206c2.772-1.004,2.555-3.626,3.406-6.447c1.463,3.062,2.943,5.416,4.914,7.49
                c2.816,2.963,4.345,2.777,5.855-1.15c0.354-0.92,0.681-1.85,1.26-3.431c2.08,3.71,5.83,5.617,5.854,10.042
                c0.007,1.336,1.031,2.507,2.653,2.457c1.427-0.045,2.002-1.083,2.125-2.339c0.176-1.803,0.815-3.148,2.69-3.78
                c1.848-0.622,1.605-3.135,3.662-4.492c0.729,4.067,3.199,6.627,5.721,9.042c1.442,1.381,0.986,3.083,1.543,4.585
                c0.431,1.162,0.942,2.19,2.283,2.332c1.331,0.141,2.27-0.688,2.759-1.776c2.702-6.009,7.583-9.931,12.924-13.306
                c1.621-1.023,1.413-2.984,2.845-3.936c0.247,0.183,0.67,0.344,0.678,0.524c0.188,4.219,3.133,6.796,5.941,9.187
                c1.675,1.424,1.935,2.852,1.947,4.707c0.015,2.158-0.295,4.341,0.294,6.467c0.288,1.039,0.982,1.793,2.107,1.808
                c1.181,0.015,1.982-0.802,1.883-1.884c-0.404-4.417,3.302-7.08,4.522-10.826c0.729-2.235,4.222-2.318,4.978-6.25
                c1.117,3.393,1.54,5.602,3.568,7.133c1.159,0.875,0.276,2.846,0.425,4.315c0.155,1.53,0.262,3.213,2.322,3.182
                c1.729-0.026,2.08-1.48,2.08-2.868c0-1.912,0.363-3.404,2.092-4.703c1.808-1.357,1.054-4.053,2.708-6.959
                c-0.109,4.559,4.118,6.559,3.23,10.588c-0.034,0.152-0.022,0.407,0.072,0.482c3.611,2.85,2.817,6.77,2.616,10.562
                c-0.065,1.227,0.249,2.477,1.609,2.752c1.636,0.331,2.451-0.723,2.662-2.242c0.164-1.186,0.431-2.314,1.386-3.117
                c2.48-2.084,3.193-4.929,3.672-7.961c0.422-2.672,1.252-5.236,3.939-6.594c1.669-0.843,1.502-2.827,2.527-4.1
                c0.791,0.912,0.706,1.938,0.804,2.926c0.098,0.992-0.12,2.43,0.442,2.895c3.075,2.534,3.32,6.886,5.982,9.385
                c1.797,1.687,2.407,3.4,2.804,5.498c0.344,1.812,0.545,3.69,3.163,4.557c4.51-2.586,4.529-8.545,7.86-11.968
                c3.322-3.413,5.672-7.247,7.711-11.382c2.01,3.277,3.271,7.089,6.278,9.785c0.948,0.85,1.026,2.039,1.171,3.198
                c0.179,1.438,0.792,2.685,2.339,2.838c1.825,0.181,2.486-1.239,3.117-2.683c0.65-1.486,0.424-3.369,1.934-4.448
                c1.289-0.632,2.225,0.503,3.351,0.589c1.042,0.079,2.437,0.015,2.618-0.953c0.6-3.209,4.314-4.491,4.255-8.652
                c1.838,2.031,1.153,3.703,1.222,5.178c0.122,2.635-0.258,5.283,2.686,6.99c0.926,0.537,1.123,2.312,1.68,3.508
                c0.516,1.108,1.26,2.046,2.595,1.998c1.349-0.049,1.952-1.09,2.438-2.203c1.008-2.31,1.354-4.793,1.971-7.209
                c0.483-1.889,0.559-3.984,2.376-5.676c3.307,2.102,7.663,2.968,8.146,8.113c0.123,1.315,1.044,2.515,2.566,2.732
                c1.355,0.194,2.549-0.704,2.654-1.793c0.543-5.551,6.109-7.988,7.43-12.89c2.154,0.282,1.846,1.991,2.195,3.104
                c0.747,2.377,1.078,4.879,3.441,6.42c1.074,0.699,1.012,2.033,1.156,3.182c0.291,2.314,0.554,4.632,0.842,6.946
                c0.211,1.699,0.591,3.304,2.385,3.965c1.288-0.177,1.75-1.024,1.933-2.062c0.536-3.039,1.745-5.693,4.047-7.843
                c0.988-0.923,1.748-2.244,1.481-3.585c-1.072-5.389,3.107-8.794,4.479-13.249c1.767,0.521,1.51,1.86,1.727,2.91
                c0.406,1.97,0.693,4.116,2.086,5.545c2.727,2.798,2.852,6.201,3.052,9.724c0.187,3.264-0.431,6.682,1.388,9.706
                c2.881,0.55,3.467-1.699,4.223-3.422c1.01-2.299,1.781-4.522,4.424-5.683c1.898-0.832,1.52-3.631,3.174-4.927
                c1.737-0.329,2.144,1.22,3.031,2.097c2.7,2.665,4.311,2.332,5.965-1.148c1.205-2.533,1.245-5.544,3.554-8.293
                c2.286,4.586,6.901,7.478,6.433,13.36c-0.262,3.283,0.363,6.644,0.667,9.962c0.181,1.975,0.299,4.051,2.557,4.891
                c1.004-0.139,1.839-0.723,1.881-1.514c0.168-3.195,2.103-5.568,3.312-8.301c0.959-2.169,2.736-2.907,4.245-3.623
                c1.526-0.725,3.612,0.025,4.398,2.186c0.339,0.932,0.618,1.941,1.192,2.719c1.041,1.411,3.378,1.458,3.64-0.006
                c0.554-3.106,3.946-3.321,4.796-6.139c3.227,1.6,6.223,2.479,8.675-0.57c1.952,1.572,1.233,3.6,1.655,5.304
                c0.318,1.29,0.458,2.664,1.01,3.845c0.889,1.9,2.711,1.888,3.84,0.059c1.076-1.744,1.051-3.832,1.643-5.73
                c0.417-1.336-0.135-3.112,1.727-3.831c0.879,1.373,0.311,2.958,0.744,4.372c0.83,2.7,3.021,3.654,4.758,1.578
                c1.994-2.387,5.332-1.676,6.585-4.305c1.874,1.116,2.06,2.471,2.435,3.729c0.521,1.753,1.026,3.514,1.609,5.248
                c0.486,1.449,1.139,2.963,2.844,3.159c1.755,0.202,2.944-1.161,3.299-2.567c0.947-3.757,4.452-5.227,6.777-7.627
                c1.406-1.451,2.576-2.796,3.253-5.282c1.745,2.134,1.154,4.139,1.29,5.918c0.216,2.819,0.15,5.532,2.693,7.679
                c1.884,1.59,1.295,4.366,1.939,6.577c0.545,1.868,0.431,4.576,2.977,4.501c2.678-0.079,2.261-2.779,2.448-4.693
                c0.179-1.824,0.277-3.655,0.442-5.48c0.074-0.828-0.081-1.957,0.389-2.426c3.579-3.572,2.943-8.567,4.354-12.872
                c1.796,0.651,1.454,2.195,1.771,3.417c0.499,1.928,0.91,3.883,1.518,5.774c0.314,0.977,1.116,1.691,2.272,1.587
                c1.138-0.102,1.752-0.875,2.005-1.924c0.156-0.645,0.354-1.332,0.278-1.97c-0.464-3.917,1.668-6.093,5.166-7.727
                c1.669,2.776,5.62,3.999,5.06,8.312c-0.117,0.898,0.775,1.858,1.915,2.006c1.325,0.17,2.368-0.516,2.792-1.663
                c0.943-2.554,2.867-4.189,4.604-6.147c1.902-2.146,3.66-2.406,5.969-2.228c2.107,1.897,0.915,5.557,3.832,6.782
                c1.512,0.636,3.236,1.623,4.688,1.383c4.395-0.729,7.029-3.61,7.9-8.339c1.241,3.696,5.396,4.269,6.767,7.713
                c0.814,2.046,3.223,1.589,4.135-0.543c0.322-0.753,0.448-1.603,0.585-2.422c0.136-0.819-0.15-1.993,0.306-2.432
                c3.351-3.22,3.1-7.743,3.942-12.026c0.975,0.492,1.404,0.695,1.822,0.922c3.258,1.773,4.252,1.391,5.639-1.797
                c0.626-1.439,0.133-3.352,2.377-4.877c0.334,4.312,0.631,8.168,0.932,12.025c0.13,1.66,0.209,3.328,0.419,4.98
                c0.218,1.713,0.675,3.307,2.835,3.507c2.522,0.233,5.141-0.998,5.878-2.841c0.604-1.512,0.095-3.381,1.718-4.896
                c1.012,0.948,1.008,2.25,1.257,3.409c0.244,1.139,0.208,2.562,1.29,3.133c2.395,1.261,2.207,3.494,2.509,5.594
                c0.118,0.824,0.228,1.652,0.419,2.461c0.287,1.215,0.905,2.203,2.301,2.23c1.127,0.023,2.209-0.791,2.227-1.675
                c0.108-5.343,6.482-7.569,5.689-12.813c1.521-0.411,1.78,0.246,1.983,0.861c0.874,2.645,1.185,5.516,4.472,6.748
                c0.962,0.361,1.405,2.154,2.045,3.315c0.64,1.162,1.073,2.472,1.869,3.507c1.613,2.101,3.538,1.87,4.527-0.564
                c0.554-1.361,0.73-2.889,0.979-4.357c0.436-2.568,0.796-5.147,1.219-7.931c2.136,0.34,2.245,1.948,3.068,2.828
                c1.532,1.639,3.684,2.005,4.656,0.202c1.983-3.675,6.261-4.807,7.956-8.312c2.496,0.124,2.65,2.152,3.592,3.389
                c1.957,2.568,3.716,2.826,6.297,0.854c3.719-2.843,4.048-3.301,6.317-8.951c1.579,0.662,2.902,2.895,5.096,1.119
                c1.515-1.226,2.198-3.316,4.069-4.194c1.248,1.416,1,3.05,1.637,4.365c1.03,2.13,2.979,2.553,4.248,0.624
                c2.545-3.871,6.562-5.957,10.088-8.559c1.73-1.276,2.91-2.463,3.074-4.898c2.6,2.534,0.518,6.517,3.934,8.523
                c4.619-0.774,3.504-6.246,6.67-8.721c2.704,1.21,5.622,1.809,7.611,5.041c1.2,1.947,4.148,2.859,5.878,4.913
                c1.072,1.272,2.45,0.349,3.387-0.757c1.12-1.322,1.351-3.028,1.849-4.604c0.534-1.692,0.703-3.525,2.154-5.198
                c1.676,1.124,1.551,3.115,2.531,4.24c2.997,3.438,5.037,7.12,4.982,11.845c-0.012,1.043,0.828,2.272,2.32,2.119
                c1.145-0.118,1.727-1.057,1.947-2.027c0.293-1.287,0.402-2.629,0.458-3.953c0.139-3.296-0.274-6.647,2.342-9.419
                c0.646-0.684,0.458-2.216,0.529-3.366c0.099-1.588-0.351-3.217,0.593-6.008c0.637,2.806,1.91,4.002,2.971,5.334
                c2.053,2.578,4.963,4.771,5.044,8.676c0.029,1.417,1.087,2.994,2.973,2.709c1.581-0.239,2.022-1.789,2.056-3.086
                c0.075-3.012,0.441-5.697,2.53-8.235c1.061-1.288,0.79-3.673,1.201-6.013c1.319,2.071,0.938,4.003,1.432,5.727
                c0.795,2.779,2.229,3.404,4.813,2.092c1.436-0.729,2.335-2.451,4.149-2.34c0.245,0.111,0.44,0.141,0.534,0.253
                c0.982,1.183,0.686,3.612,2.973,3.478c2.295-0.135,2.628-2.241,3.256-3.93c0.848-2.278,0.746-4.937,2.562-6.863
                c3.754,0.482,5.905,2.19,5.773,6.1c-0.027,0.818,0.118,1.674,0.354,2.462c0.356,1.188,1.122,2.132,2.481,2.001
                c1.081-0.104,1.662-0.98,2.047-1.965c0.529-1.351-0.248-3.002,1.01-4.275c1.817,0.319,1.91,2.096,2.722,3.232
                c0.817,1.144,1.771,2.049,3.303,1.735c1.288-0.264,1.979-1.247,2.226-2.44c0.641-3.11,1.702-5.8,4.243-8.081
                c1.745-1.567,1.027-4.771,2.909-7.037c1.869,1.849,1.636,4.263,2.28,6.325c1.304,4.172,2.513,4.576,6.674,1.848
                c0.23,3.803,3.202,6.072,4.625,9.312c1.161,2.644,3.206,5.088,3.97,8.14c0.298,1.189,1.215,2.033,2.613,1.811
                c1.428-0.227,1.77-1.387,1.891-2.581c0.116-1.157,0.117-2.327,0.131-3.493c0.024-1.996-0.148-3.963,1.937-5.312
                c1.206-0.779,0.951-2.379,1.228-3.668c0.642-2.988,2.766-4.079,5.779-2.968c2.836,1.045,6.088,0.601,8.58,3.122
                c1.174,1.188,2.634-0.263,3.396-1.781c0.812-1.618,0.295-3.68,2.014-5.382c2.125,1.756,1.857,4.896,4.178,6.481
                c-2.615,1.133-5.822,0.409-7.039,3.566c1.223,2.827,3.866,1.391,6.176,2.34c-0.012,1.447-0.748,3.225,0.231,4.875
                c1.167,1.967,3.143,1.523,5.782,1.989c-2.742,0.975-4.977,1.091-6.154,3.353c-0.808,1.553-3.019,1.109-4.518,1.773
                c-0.921,0.408-1.869,0.906-1.883,2.09c-0.018,1.453,1.09,2.08,2.247,2.258c2.565,0.394,5.161,0.583,8.97,0.985
                c-3.734,2.106-6.959,2.301-9.424,4.197c-2.263,1.741-5.85,1.95-6.409,5.804c3.249,2.727,7.063,0.14,11.065,1.158
                c-4.462,3.869-9.885,5.727-14.127,9.659c2.389,2.423,4.963,1.251,7.249,1.262c2.311,0.01,4.624-0.421,7.804-0.749
                c-3.667,2.848-7.999,3.31-10.235,6.639c-2.127,3.165-7.561,2.413-8.06,7.164c-2.717-0.128-4.6-1.189-5.594-3.641
                c-0.246-0.604-0.649-1.178-1.092-1.663c-2.025-2.229-4.2-2.091-5.572,0.646c-1.108,2.209-1.687,4.682-2.521,7.029
                c-0.438,1.229-0.52,2.65-1.818,3.469c-1.365-0.807-1.211-2.23-1.32-3.475c-0.24-2.738-0.942-5.221-3.066-7.154
                c-1.695-1.542-1.939-3.808-2.756-5.794c-1.092-2.656-3.367-3.059-4.707-0.537c-1.473,2.771-3.464,4.767-5.906,6.555
                c-1.412,1.034-1.809,2.616-1.877,4.414c-0.139,3.603-5.285,7.284-8.82,6.535c-1.36-0.288-1.854-1.27-2.198-2.428
                c-0.662-2.233-1.154-4.535-2.016-6.688c-1.334-3.334-3.227-3.87-6.264-2.014c-0.563,0.345-1.001,0.896-1.564,1.24
                c-2.347,1.432-3.314,1.256-4.901-1.154c-1.186-1.8-2.006-4.264-4.582-4.024c-3.074,0.286-3.365,3.315-4.329,5.54
                c-0.527,1.219-0.841,2.527-1.319,3.77c-0.43,1.115-1.045,2.253-2.393,2.24c-1.636-0.016-2.13-1.365-2.48-2.697
                c-0.382-1.447-0.821-2.888-1.078-4.358c-0.971-5.576-1.869-11.165-2.816-16.745c-0.276-1.624-0.415-3.334-2.233-4.494
                c-2.059,0.536-2.568,2.451-2.694,4.134c-0.43,5.767-2.365,10.942-5.082,16c-1.799,3.348-1.288,3.894,2.821,4.999
                c-0.773,3.382-2.14,6.358-4.938,8.809c-1.786-1.029-1.515-2.658-1.668-4.031c-0.23-2.071-0.957-3.403-3.347-3.49
                c-3.62-0.133-5.155,0.539-5.97,3.053c-0.285,0.877,0.178,2.068-1.487,2.648c-1.103-1.48-0.392-3.413-0.936-5.008
                c-0.447-1.309-0.903-2.577-2.591-2.579c-1.667-0.001-2.333,1.217-2.608,2.553c-0.266,1.29-0.192,2.647-0.309,3.972
                c-0.108,1.238,0.243,2.623-1.425,4.197c-0.275-4.697-3.244-5.69-6.668-5.965c-1.922-0.154-3.075-2.045-4.801-1.771
                c-1.119-1.399-0.754-2.855-1.222-4.086c-0.46-1.211-1.341-1.891-2.685-1.77c-1.1,0.1-2.262,0.838-2.158,1.738
                c0.553,4.807-5.357,6.382-4.805,10.906c-1.445-0.047-1.471-0.961-1.37-1.436c0.999-4.679-2.915-6.612-5.3-9.271
                c-1.168-1.302-2.809-0.6-3.697,0.974c-0.765,1.351-0.82,2.862-0.816,4.372c0.004,1.816,0,3.633,0,4.816
                c-1.519,2.248-3.387,1.73-4.545,2.527c-1.976-1.422-1.361-3.265-1.67-4.832c-0.333-1.687-0.77-3.438-2.799-3.641
                c-2.153-0.215-2.765,1.396-3.062,3.182c-0.045,0.266-0.389,0.48-0.609,0.738c-1.141-1.01-0.741-2.429-1.019-3.668
                c-0.529-2.367,0.866-6.346-3.026-6.25c-3.393,0.084-2.288,3.771-2.604,6.087c-0.198,1.451,0.142,3.021-0.988,4.241
                c-5.67-0.885-5.249-1.921-7.46,3.836c-1.601-0.411-1.18-1.785-1.31-2.852c-0.121-0.988-0.047-2-0.135-2.994
                c-0.187-2.125,0.023-4.562-2.715-5.271c-1.594-0.412-2.421,0.854-3.437,4.927c-1.336,0.583-2.814,1.229-4.246,1.854
                c-1.875-1.216-1.027-3.128-1.49-4.636c-0.669-2.176-2.612-3.322-4.098-2.023c-3.915,3.426-7.487,7.203-9.731,12.246
                c-1.811-0.838-1.098-2.527-1.261-3.8c-0.23-1.805,0.042-3.66-0.667-5.423c-0.461-1.145-1.016-2.205-2.388-2.191
                c-1.575,0.015-2.372,1.134-2.649,2.527c-0.256,1.287-0.283,2.62-0.42,4.023c-2.422,1.193-4.499,1.771-6.967-0.076
                c-2.851-2.135-4.842,0.518-7.166,2.413c-1.646-3.293-5.016-4.093-7.361-6.273c-1.593-1.48-7.906-1.265-9.525,0.007
                c-1.251,0.982-1.395,3.186-3.514,3.211c-1.785,0.021-2.697,1.914-4.176,2.13c-2.186-0.615-1.695-2.459-2.254-3.742
                c-1.082-2.485-3.171-3.3-5.152-1.56C905.136,764.018,901.301,764.468,900.093,767.98z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M624.265,844.209c1.11,0.542,1.975,0.965,2.939,1.436
                c0.167,2.725-1.345,4.743-2.898,6.667c-3.811,4.718-6.442,4.825-11.388,0.317c2.537-3.514-0.312-6.244-1.842-9.603
                c1.517-1.51,2.053-3.309,0.599-5.462c-1.954-2.895-3.627-5.976-5.545-8.895c-1.352-2.058-3.351-2.41-4.396-0.466
                c-2.527,4.702-6.988,7.559-10.203,11.563c-0.717,0.893-1.565,1.769-1.401,2.872c0.397,1.855,2.345,1.285,3.2,2.356
                c-0.177,0.526-0.219,1.06-0.495,1.409c-2.473,3.141-5.025,6.219-7.473,9.378c-1.463,1.889-1.824,3.688,1.211,4.62
                c-2.075,4.521-6.947,7.026-7.835,11.679c0.791,2.339,2.835,1.287,3.938,2.174c1.237,2.427-2.53,7.832-7.088,10.238
                c-2.39-4.277-2.359-4.48,1.349-8.002c-0.695-5.035-4.673-8.562-6.669-13.184c-0.498-1.485,1.753-1.2,1.785-2.533
                c0.036-1.505-0.875-2.478-1.655-3.52c-1-1.335-2.135-2.474-1.347-4.453c0.323-0.812,0.066-2.034-0.3-2.913
                c-2.093-5.022-4.914-9.713-6.168-14.604c-2.065-0.734-2.916,0.105-3.525,1.123c-0.679,1.136-1.342,2.341-1.69,3.604
                c-1.572,5.707-4.241,10.752-8.026,15.351c-3.136,3.809-2.792,4.489,2.039,7.306c-0.949,4.808-4.222,8.29-7.474,12.362
                c-1.522-1.756-2.084-3.495-2.124-5.461c0.286-0.198,0.535-0.516,0.796-0.526c1.376-0.058,2.988,1.349,4.062-0.462
                c0.882-1.486,0.018-2.914-0.656-4.245c-1.503-2.969-3.226-5.827-4.318-8.997c-0.81-2.35-1.55-4.694-1.337-7.004
                c1.591-1.705,4.028,0.344,5.573-2.596c-3.744-7.518-6.702-16.041-10.48-23.809c-2.535-0.164-3.014,1.216-3.564,2.378
                c-2.374,5.011-5.521,9.476-9.145,13.657c-1.824,2.105-3.79,4.197-2.498,7.557c0.562,1.463-1.007,2.702-1.775,3.963
                c-2.636,4.326-2.123,5.254,3.006,5.439c0.327,0.012,0.65,0.107,1.167,0.198c-1.375,3.54-2.578,7.114-4.136,10.527
                c-2.252,4.93-6.785,5.836-11.041,2.394c-1.826-1.478-3.239-3.289-4.311-5.157c0.431-1.725,2.261-0.696,2.773-1.937
                c0.477-1.156,0.39-2.305,0.104-3.428c-0.913-3.586-2.296-7.047-4.66-9.865c-2.205-2.629-3.898-5.328-4.396-8.76
                c-0.218-1.506-0.753-3.055-2.598-3.212c-1.784-0.151-2.637,1.223-3.303,2.599c-0.725,1.496-1.393,3.027-1.973,4.586
                c-2.573,6.91-5.883,13.408-10.412,19.268c-0.839,1.084-2.315,2.106-1.304,4.006c2.52,0.943,4.424-2.066,7.169-1.279
                c-0.474,2.516-2.179,4.148-3.646,5.856c-1.953,2.272-4.149,4.334-6.111,6.598c-1.569,1.812-3.06,3.759-0.436,5.974
                c1.283,1.082,0.814,2.436,0.295,3.676c-1.363,3.253-2.995,6.322-5.965,8.464c-1.205,0.869-2.566,1.588-3.393,3.104
                c-0.605,1.572,3.462,3.571-0.403,5.164c-3.461-2.25-6.264-5.187-8.378-8.774c0.388-0.366,0.58-0.712,0.804-0.734
                c1.547-0.151,3.413,1.3,4.582-0.618c1.155-1.895-0.158-3.53-1.115-5.053c-1.509-2.401-3.312-4.579-5.292-6.625
                c-1.59-1.643-3.289-3.295-3.443-5.932c2.896-2.606,6.661,0.188,9.953-1.934c-0.39-5.327-5.489-7.986-7.447-12.458
                c0.254-1.18,1.964-1.158,2.211-2.94c-0.872-3.999-4.784-6.594-6.931-10.717c1.916-1.582,2.287-3.521,0.684-5.811
                c-1.622-2.315-3.011-4.795-4.467-7.225c-1.983-3.311-3.312-6.855-4.198-10.631c-1.286-5.479-3.216-5.975-7.37-2.045
                c-2.423,2.293-4.651,4.787-6.503,7.568c-0.906,1.36-2.231,2.893-1.427,4.557c1.061,2.194-0.05,3.447-1.323,4.814
                c-0.455,0.488-0.932,0.955-1.377,1.451c-3.912,4.357-3.921,5.193,0.03,9.685c-1.244,2.723-3.727,4.511-5.668,6.728
                c-0.987,1.127-2.12,2.139-3.02,3.328c-1.869,2.47-1.76,2.754,1.239,4.931c-0.36,1.454-1.831,1.74-2.765,2.583
                c-1.454,1.312-2.433,2.414-1.103,4.643c1.188,1.992-0.902,4.971-3.793,6.46c-2.233,1.149-3.581,0.043-4.467-1.712
                c-2.526-4.996-6.692-7.595-11.952-9.01c-3.697-0.994-6.956-2.976-10.091-5.641c2.823-2.135,6.138,1.872,8.701-1.716
                c-5.573-6.854-10.392-14.333-12.605-23.137c0.433-0.245,0.71-0.543,0.966-0.525c1.411,0.099,3.005,1.281,4.137-0.336
                c1.063-1.521-0.232-2.831-0.864-4.13c-0.361-0.742-0.857-1.434-1.38-2.078c-3.981-4.896-6.368-10.463-7.474-16.68
                c-0.356-2.002-0.261-4.893-2.844-5.371c-2.86-0.53-3.703,2.38-4.856,4.271c-2.341,3.838-4.521,7.775-7.202,11.398
                c-0.606,0.82-1.065,1.691-1.037,1.874c0.525,2.768,2.74,1.983,3.585,3.201c-9.041,9.326-12.366,10.067-21.945,7.562
                c1.366-1.813,4.307-1.219,5.297-4.182c-5.828-3.916-12.74-1.727-19.044-2.994c-0.188-1.281,1.013-0.833,1.531-1.185
                c0.556-0.378,1.125-0.704,1.484-1.84c-5.105-3.972-11.703-1.808-17.346-3.361c0.242-1.934,2.557-1.463,2.543-3.139
                c0.307-1.31-1.537-1.111-1.573-2.3c0.374-0.233,0.767-0.675,1.2-0.719c5.122-0.519,9.826-0.438,14.652,2.65
                c4.069,2.604,9.424,3.061,14.372,3.799c0.468,0.069,1.309,0.015,1.415-0.228c1.629-3.726,5.263-2.765,8.13-3.585
                c0.833-0.238,1.411-0.822,1.698-2.293c-4.344-3.252-9.923-0.428-14.539-2.352c-1.542-2.042-0.744-3.877,0.695-5.62
                c-1.536-2.024-3.668-2.574-5.774-2.829c-5.948-0.719-11.357-3.916-17.496-3.725c-0.81,0.024-1.88-0.132-2.214-0.904
                c-0.985-2.277-3.017-2.982-5.062-3.69c-1.595-0.552-1.253-2.118-2.061-3.134c1.524-1.196,3.998-0.756,4.32-3.176
                c-0.081-1.719-2.727-1.383-2.641-3.257c3.392-1.356,7.544-1.493,14.017-0.489c4.735,0.734,9.072-0.379,13.249-2.318
                c-0.567-2.526-2.379-3.297-3.8-3.556c-5.171-0.94-9.855-4.084-15.389-3.354c-2.386,0.314-4.593-0.52-6.758-1.385
                c2.197-6.051-5.977-3.852-6.259-7.912c6.27-2.406,6.27-2.406,7.357-4.949c2.291,0,4.625-0.143,6.934,0.034
                c3.16,0.243,5.715-0.247,6.156-3.894c2.141-0.192,3.9-0.172,5.49,1.1c1.423,1.138,3.002,2.082,4.605,3.174
                c0.084,0.186,0.295,0.475,0.346,0.789c0.308,1.921-0.041,4.364,2.554,4.848c2.924,0.545,3.411-2.119,4.292-3.99
                c1.102-2.34,1.476-5.045,3.179-7.01c1.815,0.48,1.825,1.734,2.046,2.828c0.297,1.469,0.494,2.96,0.818,4.423
                c1.167,5.26,2.917,5.997,7.579,3.009c1.491,2.555,1.414,5.38,1.565,8.137c0.128,2.334,0.17,4.689,1.018,6.893
                c0.789,2.049,3.137,2.557,3.688,0.742c2.008-6.613,8.719-11.104,8.295-18.744c-0.073-1.322,0.101-2.663,0.218-3.99
                c0.087-0.992,0.208-2,1.294-2.932c1.188,1.666,1.282,3.547,1.467,5.405c0.297,2.981,0.606,5.962,0.938,8.938
                c0.261,2.345,0.599,4.654,2.891,6.049c1.621,0.985,1.583,2.828,2.15,4.354c0.629,1.691,1.65,3.086,3.62,2.744
                c1.747-0.303,2.118-1.954,2.319-3.471c0.175-1.318,0.139-2.667,0.332-3.982c0.143-0.971,0.271-2.568,0.807-2.759
                c4.284-1.526,4.438-5.954,6.61-8.929c0.176-0.241,0.558-0.332,0.845-0.49c0.153,0.061,0.412,0.086,0.441,0.182
                c1.485,4.883,5.756,7.163,9.301,10.182c2.4,2.045,4.771,2.92,7.752,1.695c2.958-1.215,5.223-0.707,7.504,1.868
                c2.623,2.96,5.35,2.527,7.218-0.943c1.678-3.117,3.375-5.845,6.606-7.792c2.658-1.601,2.887-5.098,3.584-8.006
                c0.521-2.17,0.654-4.479,2.17-6.314c1.345,0.79,1.145,1.889,1.272,2.801c0.498,3.583,0.51,7.166,4.502,9.307
                c1.784,0.957,1.979,3.742,2.872,5.705c1.107,2.436,2.027,4.942,4.892,5.972c1.565,0.562,1.933,2.176,2.611,3.497
                c0.914,1.781,1.76,3.9,4.22,3.631c2.41-0.264,2.903-2.547,3.508-4.402c0.927-2.848,1.558-5.79,2.466-8.644
                c0.441-1.388,0.644-2.972,1.979-3.911c1.626-0.037,2.087,1.351,2.161,2.32c0.494,6.441,5.476,8.622,10.262,10.823
                c1.97,0.905,3.797,1.662,5.39,3.296c2.68,2.75,5.127,2.215,7.3-1.098c1.147-1.749,1.462-4.148,3.95-5.084
                c2.014,1.596,1.949,4.055,2.57,6.209c0.68,2.361,0.735,5.414,3.613,6.076c3.12,0.718,4.013-2.31,5.369-4.324
                c2.218-3.296,3.214-3.403,5.147-0.003c2.24,3.938,6.249,3.976,9.83,4.866c1.609,0.4,2.649-1.001,3.389-2.432
                c0.724-1.402,0.855-3.264,2.391-3.855c1.351,0.066,1.924,0.745,2.058,1.561c0.898,5.488,5.65,5.395,9.477,6.471
                c1.804,0.507,3.121-0.738,4.033-2.303c1.347-2.309,2.792-2.178,4.848-0.599c2.741,2.104,6.012,3.289,9.353,4.233
                c6.174,1.744,11.256-0.399,15.771-4.412c1.641-1.457,2.859-1.538,3.945,0.492c0.236,0.44,0.431,0.914,0.732,1.307
                c2.183,2.847,4.685,2.867,6.175-0.279c1.287-2.717,2.293-5.225,5.392-6.617c1.997-0.897,1.936-3.715,2.945-5.919
                c1.473,1.854,1.88,4.456,4.485,5.148c1.425,0.379,1.82,1.84,2.292,3.084c0.591,1.556,1.062,3.163,1.739,4.679
                c2.072,4.639,4.285,4.859,7.267,0.762c0.488-0.67,0.822-1.52,1.749-1.648c2.595-0.361,3.616-2.66,5.482-4.846
                c0.712,2.531,1.171,4.561,1.863,6.507c0.671,1.886,1.479,4.075,4.017,3.671c2.271-0.363,2.375-2.529,2.48-4.361
                c0.105-1.817,0.023-3.645,0.023-5.662c3.065-0.695,3.822-3.553,5.544-5.259c0.564,0.198,1.065,0.228,1.142,0.423
                c1.22,3.121,2.84,5.438,6.311,6.924c2.554,1.094,6.072,1.895,7.138,5.497c0.258,0.867,1.597,1.224,2.635,1.052
                c2.324-0.385,4.167-1.908,6.234-2.894c1.318-0.628,1.199-2.251,1.322-3.581c0.092-0.988,0.202-1.998,1.367-2.404
                c1.65,0.398,1.564,1.989,2.105,3.128c0.496,1.046,0.766,2.207,1.32,3.216c0.587,1.068,1.548,1.766,2.874,1.576
                c1.329-0.189,1.892-1.129,2.304-2.318c0.622-1.795,0.154-3.902,1.46-5.836c1.915,1.074,1.974,2.827,2.546,4.303
                c1.572,4.051,2.885,4.33,6.71,2.522c4.276-2.02,7.519-5.305,11.146-8.267c1.572,1.1,1.659,2.801,2.071,4.374
                c0.459,1.751,0.77,3.899,3.1,3.95c1.89,0.041,2.8-1.557,3.385-3.211c0.11-0.314,0.208-0.633,0.317-0.947
                c0.787-2.264,2.082-2.595,4.236-1.508c5.218,2.631,6.291,2.113,8.121-3.459c0.52-1.583,0.876-3.26,2.59-4.064
                c1.713-0.805,1.504-2.9,2.695-4.031c1.764,0.016,1.451,1.594,1.898,2.523c0.606,1.259,1.19,2.506,2.82,2.355
                c1.764-0.162,2.727,0.985,3.826,1.99c1.771,1.615,3.942,1.731,5.351,0.041c2.269-2.721,6.134-4.195,6.719-8.287
                c0.028-0.197,0.521-0.327,0.835-0.51c1.343,0.102,1.15,1.414,1.634,2.183c1.783,2.837,3.886,2.783,5.526-0.149
                c2.346-4.194,5.9-7.771,6.316-13.181c2.289,2.523,4.371,4.681,6.902,6.394c0.713,0.483,1.102,1.666,1.326,2.603
                c0.357,1.484,0.448,3.142,2.406,3.274c1.604,0.108,3.008-0.666,3.693-2.119c1.811-3.839,6.357-5.087,7.681-8.849
                c1.522-0.03,1.416,0.794,1.56,1.376c1.168,4.748,1.838,5.256,6.512,3.983c2.244-0.61,4.161-0.676,6.111,0.801
                c2.41,1.825,4.936,1.964,7.2-0.309c1.156-1.16,2.476-1.46,4.048-1.524c3.604-0.148,7.346,0.373,10.465-2.302
                c0.987-0.846,2.547-0.395,3.133,0.599c2.002,3.389,6.121,3.045,8.831,5.301c2.044,1.702,4.195,0.549,5.665-2.048
                c1.03-1.821,0.887-4.305,2.898-5.453c1.658-0.298,2.258,0.804,2.806,1.804c1.517,2.769,3.866,3.703,6.734,2.768
                c2.956-0.965,4.868-0.467,6.722,2.299c2.23,3.328,4.834,2.952,6.986-0.458c0.777-1.233,0.978-2.893,2.607-3.554
                c2.258-0.206,2.416,1.848,3.292,3.137c2.407,3.547,5.245,3.525,7.082-0.396c1.457-3.11,2.951-5.13,6.774-3.791
                c2.43,0.852,3.248-1.076,3.852-2.843c0.57-1.674,0.479-3.606,2.379-5.34c1.893,4.733,5.671,6.492,10.133,7.272
                c5.059,0.884,8.118-1.33,9.109-6.979c2.169,1.936,1.859,3.824,2.609,5.203c1.127,2.078,3.689,2.762,4.839,0.84
                c1.946-3.255,5.762-3.454,7.82-6.107c1.917,1.009,1.657,2.734,2.155,4.053c0.729,1.936,2.41,2.906,3.895,1.992
                c2.347-1.445,5.34-1.661,7.201-4.027c1.453-1.846,5.945-3.176,7.467-1.775c1.846,1.698,4.344,2.2,5.998,4.088
                c2.689,3.067,3.305,3.098,6.475,0.684c0.539-0.41,1.004-0.84,2.113-0.959c2.135,1.951,4.779,2.227,7.945,1.425
                c3.961-1.002,2.244-4.631,3.557-6.775c1.607,0.64,1.387,1.917,1.449,3.042c0.084,1.496,0.025,3.004,0.172,4.493
                c0.166,1.7,0.557,3.509,2.602,3.749c2.156,0.254,2.322-1.666,3.164-3.117c1.471-2.535,3.348-4.933,5.475-6.941
                c0.893-0.843,1.599-2.062,3.219-2.766c1.082,1.311,0.654,3.081,1.158,4.52c0.775,2.213,1.978,2.949,4.166,2.202
                c3.018-1.028,5.568-2.606,5.664-6.286c1.43,0.406,1.394,1.312,1.44,2.107c0.089,1.496-0.001,3.009,0.167,4.493
                c0.223,1.958,0.238,4.387,2.861,4.583c2.43,0.184,2.699-1.975,3.02-3.823c0.049-0.288,0.344-0.534,0.51-0.776
                c0.695-0.396,1.24,0.031,1.865,0.215c3.314,0.975,4.217,0.434,4.933-2.734c0.513-2.268-0.099-4.674,0.923-7.094
                c1.396,0.862,1.16,2.236,1.318,3.408c0.179,1.319,0.209,2.659,0.387,3.979c0.199,1.481,1.057,2.537,2.572,2.582
                c1.234,0.035,2.189-0.656,2.493-2.016c0.157-0.703-0.202-1.732,1.114-1.908c1.244,0.914,0.824,2.358,1.039,3.595
                c0.598,3.439,1.479,4.516,3.662,4.443c3.309-0.108,5.645-1.911,5.994-4.82c0.197-1.647,0.079-3.33,0.173-4.993
                c0.054-0.957-0.171-2.024,0.878-2.857c1.535-0.094,1.356,2.039,2.754,2.309c2.086,0.401,2.318,2.074,2.334,3.848
                c0.008,0.994,0.036,2.021,0.283,2.973c0.309,1.186,1.067,2.096,2.437,2.138c1.334,0.041,2.427-0.63,2.544-1.913
                c0.398-4.361,5.407-6.432,5.188-11.329c0.824,7.725,7.223,6.973,11.926,8.636c0.201,0.251,0.514,0.475,0.586,0.76
                c0.486,1.915,0.61,4.368,3.441,3.9c2.23-0.368,2.367-2.502,2.508-4.361c0.135-1.805-0.19-3.658,0.483-5.392
                c1.694-0.199,1.456,1.045,1.579,1.954c0.135,0.988,0.09,2.006,0.292,2.977c0.256,1.227,1.049,2.158,2.318,2.316
                c1.288,0.16,2.27-0.539,2.702-1.765c0.386-1.091,0.612-2.238,0.924-3.416c1.525-0.763,3.096-0.948,4.75-0.284
                c1.311,2.453-0.143,5.903,3.018,7.371c-1.864,4.713-6.275,7.707-6.922,12.428c2.271,1.994,4.319-0.087,6.431,0.854
                c1.028,1.758-0.185,3.427-0.774,5.014c-1.295,3.481-3.559,6.364-6.105,9.054c-1.086,1.146-2.752,2.032-2.476,4.27
                c1.625,1.822,4.091,0.217,6.2,1.266c0.407,2.01-0.826,3.668-1.684,5.368c-1.961,3.884-4.587,7.332-7.245,10.761
                c-1.448,1.867-1.942,3.669,0.151,5.705c3.567,3.471,3.48,3.726,1.137,8.537c-1.379,0.42-2.418-0.897-3.762-0.785
                c-2.183,0.182-3.37-1.023-4.047-2.962c-0.328-0.94-0.721-1.87-1.199-2.742c-0.855-1.554-2.096-2.54-4-2.501
                c-1.936,0.04-3.045,1.141-3.774,2.773c-1.1,2.464-1.447,5.108-1.829,7.744c-0.335,2.307-0.616,4.624-1.043,6.914
                c-0.333,1.781-0.684,3.635-2.93,4.537c-1.89-1.211-1.754-3.277-1.981-5.181c-0.318-2.657-0.921-4.945-4.395-4.858
                c-2.546,0.063-3.063-2.17-3.91-3.949c-2.327-4.893-2.329-4.892-7.394-5.184c-1.705-2.061-1.911-4.82-2.891-7.256
                c-0.742-1.846-1.277-4.093-3.69-4.232c-2.542-0.148-3.815,1.891-4.683,3.889c-1.258,2.896-2.181,5.935-3.348,8.871
                c-0.924,2.323-1.845,4.596-4.806,5.242c-2.002,0.438-2.632,2.34-3.149,4.161c-1.1,3.868-1.432,7.851-1.842,11.824
                c-0.308,2.98-0.603,5.967-1.736,8.782c-0.249,0.619-0.622,1.181-1.809,1.35c-2.02-2.598-2.096-5.915-2.38-9.1
                c-0.694-7.797-1.216-15.607-1.888-23.406c-0.184-2.13-0.367-4.382-2.778-5.493c-2.587,0.535-3.978,2.32-4.648,4.687
                c-0.837,2.953-2.015,5.492-4.779,7.29c-2.501,1.626-2.255,4.684-2.652,7.272c-0.367,2.391-0.666,4.688-3.856,5.355
                c-2.271-1.475-3.795-5.496-7.923-2.778c-1.114-3.248-2.653-5.422-6.394-5.331c-2.101,0.051-3.135-2.254-4.289-3.869
                c-1.291-1.806-2.611-3.613-5.199-3.132c-2.742,0.511-3.75,2.714-4.123,5.075c-0.595,3.774-0.854,7.6-1.321,11.396
                c-0.249,2.022-0.705,3.981-3.109,4.539c-2.005-0.357-2.329-2.279-3.392-3.475c-3.158-3.553-6.248-3.027-7.718,1.442
                c-0.777,2.366-1.322,4.816-1.862,7.253c-1.2,5.406-3.74,9.923-8.689,13.268c-2.157-2.039-1.912-5.584-3.693-6.996
                c-1.876-1.289-2.734,0.235-3.908,0.194c-0.32-0.437-0.652-0.792-0.872-1.206c-0.391-0.734-0.685-1.52-1.07-2.257
                c-2.714-5.195-5.652-5.455-9.349-0.763c-1.118,1.42-1.848,3.205-3.949,3.602c-1.719-1.047-2.086-2.911-2.623-4.607
                c-1.104-3.49-2.021-7.041-3.119-10.533c-0.547-1.739-1.141-3.511-2.037-5.082c-1.482-2.604-3.296-2.663-4.943-0.201
                c-0.817,1.221-1.439,2.649-1.812,4.072c-0.803,3.057-1.4,6.166-2.055,9.26c-0.52,2.452-0.745,5.28-2.539,6.946
                c-3.758,3.489-3.899,7.866-4.1,12.378c-0.088,1.994-0.205,3.998-0.49,5.971c-0.189,1.308-0.6,2.648-2.262,2.906
                c-1.693,0.262-2.879-0.297-3.541-1.977c-1.094-2.77-2.889-5.256-3.43-8.281c1.417-1.896,4.03,0.264,5.658-2.072
                c-0.625-5.078-6.322-7.057-8.334-11.768c1.615-1.439,4.045,0.221,5.629-1.586c0.457-1.994-0.912-3.609-1.816-5.311
                c-2.266-4.264-4.697-8.439-6.928-12.721c-0.838-1.607-1.489-3.363-1.906-5.128c-0.862-3.646-0.33-4.347,3.527-5.547
                c0.461-0.144,0.882-0.415,1.282-0.608c0.948-1.626,0.306-3.039-0.595-4.229c-6.077-8.037-7.887-17.473-9.06-27.137
                c-0.235-1.939-0.409-3.936-2.196-5.34c-2.378,1.117-2.566,3.352-2.654,5.151c-0.188,3.823-1.697,7.047-3.412,10.317
                c-1.442,2.752-3.139,5.502-2.898,8.404c1.115,1.877,2.854,0.524,3.951,1.689c0.858,3.726-0.676,7.091-2.195,10.414
                c-1.137,2.484-2.301,4.774-0.128,7.383c1.396,1.676,0.37,3.531-0.447,5.157c-1.87,3.718-3.872,7.37-5.767,11.075
                c-1.667,3.261-3.402,6.498-4.852,9.856c-1.911,4.426-1.822,4.499,2.25,7.284c0.901,0.616,1.415,1.4,1.66,2.38
                c0.882,3.527,0.523,6.812-2.089,10.185c-3.696-4.29-7.196-8.242-10.56-12.309c-1.396-1.689-1.355-3.857-0.504-5.713
                c1.576-3.433,0.498-5.976-1.859-8.564c-2.352-2.582-4.1-5.63-5.409-8.674c0.436-1.781,1.894-0.201,2.474-1.135
                c-0.002-3.393-6.823-18.65-9.818-21.789c-2.541,0.945-2.359,3.775-3.684,5.547c-2.1,2.807-3.873,5.822-5.121,9.122
                c-0.841,2.222-1.598,4.114,0.88,6.192c2.12,1.777,1.103,3.761-2.757,5.985c-3.109-4.465-4.185-9.611-5.034-14.831
                c-0.075-0.466-0.054-1.184,0.229-1.435c3.279-2.899,1.691-6.438,1.337-9.792c-0.223-2.108-1.198-4.186,0.149-6.297
                c0.301-0.471,0.274-1.331,0.082-1.906c-1.734-5.208-3.488-10.411-5.355-15.572c-0.576-1.59-1.703-2.822-3.772-3.141
                c-2.422,2.833-1.976,6.951-4.653,9.719c-2.371,2.451-4.816,5.083-5.636,8.452c0.977,2.63,3.259,1.417,4.825,1.964
                c0.508,1.79-0.732,2.891-1.455,4.114c-1.522,2.58-3.297,5.015-4.762,7.626c-1.986,3.539-1.967,3.793,1.098,6.53
                c1.89,1.688,1.195,3.198,0.201,4.839c-2.016,3.323-4.99,5.791-7.645,8.547c-1.473,1.529-3.28,2.836-3.662,4.484
                c0.137,2.375,2.254,1.833,2.814,3.149c-1.566,3.231-4.1,6.048-3.945,10.045c0.174,4.471,5.078,4.979,6.854,8.637
                c-2.691,4.23-7.191,5.341-11.646,6.417c-0.651,0.158-1.578-0.437-2.261-0.867c-4.561-2.879-8.871-12.389-8.332-18.288
                c0.831-1.097,2.321,0.05,3.464-1.206c-0.251-3.922-3.027-6.926-4.906-10.303c-1.854-3.336-3.853-6.591-5.744-9.806
                c0.928-1.382,2.456,0.241,3.592-1.852c-2.235-7.435-8.124-13.471-11.268-20.409c-2.381-0.592-3.264,0.495-4.049,1.547
                c-3.028,4.057-5.014,8.625-6.317,13.488c-0.251,0.938-0.544,2.314-0.077,2.916c2.15,2.775,0.389,4.912-0.803,7.207
                c-0.69,1.33-1.359,2.676-1.943,4.055c-2.062,4.873-1.792,5.53,3.32,7.949c-0.293,1.889-1.776,3.017-3.043,4.203
                c-3.769,3.529-6.426,7.545-6.83,12.907c-0.212,2.803-0.545,2.893-4.162,6.02c-0.51-0.271-1.113-0.521-1.633-0.891
                c-0.521-0.37-0.959-0.86-1.754-1.593c1.572-1.316,4.107-1.226,4.648-3.777c-3.393-2.49-7.742-4.025-10.377-8.023
                c2.016-1.541,5.025-0.848,6.262-4.125c-4.364-3.116-9.982-5.314-12.533-10.754c0.945-1.26,1.893-0.208,2.779-0.219
                c2.273-0.028,3.105-1.389,2.564-3.271c-1.974-6.872-4.125-13.693-6.254-20.52c-0.191-0.614-0.707-1.13-1.996-1.266
                c-2.25,2.515-4.709,5.265-6.932,7.748c0.1,1.917,1.76,1.917,2.326,3.106c-0.809,1.363-1.721,2.758-2.484,4.229
                c-0.843,1.623-1.83,3.302,0.103,4.927c1.356,1.139,0.624,2.399,0.086,3.618c-1.722,3.904-4.502,6.96-7.698,9.704
                c-1.475,1.268-3.271,2.288-3.88,3.984c0.385,1.95,2.505,1.494,3.024,3.451c-0.379,2.272-1.884,4.433-3.602,6.461
                c-1.622,1.917-3.121,3.911-4.166,6.202c-1.871,4.098-1.2,5.099,3.914,5.271c0.801,8.771-5.279,13.244-11.181,18.396
                c-1.511-2.52-2.308-4.902-2.633-7.695c0.819-0.08,1.47-0.095,2.102-0.213c3.403-0.64,4.481-2.565,3.013-5.66
                c-0.559-1.177-1.489-2.188-2.312-3.227c-2.576-3.254-4.932-6.646-6.891-10.215c0.939-1.312,2.428,0.256,3.195-1.107
                c0.568-1.008,0.408-1.898,0.068-2.844c-1.238-3.443-2.493-6.879-3.872-10.678c3.075-1.441,2.737-3.901,1.812-6.562
                c-1.149-3.301-2.238-6.624-3.283-9.959c-1.595-5.09-3.059-10.208-3.31-15.594c-0.07-1.489-0.278-3.01-0.697-4.436
                c-0.683-2.32-2.387-2.646-4.021-0.771c-1.419,1.629-2.662,3.411-3.978,5.13c-0.202,0.264-0.581,0.6-0.529,0.821
                C630.908,834.91,625.055,838.434,624.265,844.209z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M526.681,147.406c0.141-4.753,2.217-8.82,5.592-12.273
                c0.946-0.967,1.907-1.855,1.492-3.435c-0.956-3.63,0.261-6.896,1.928-10.163c-3.514-4.701-6.241-9.609-3.441-15.485
                c-1.013-2.384-2.951-3.379-4.832-4.438c-3.823-2.153-7.048-5.032-9.882-8.342c-1.172-1.369-2.464-2.755-2.475-4.741
                c1.043-2.028,3.923-0.829,5.029-2.85c-0.362-2.209-2.27-2.262-3.746-2.679c-4.003-1.132-8.108-1.93-12.064-3.198
                c-6.589-2.11-12.78-5.286-19.31-6.959c-0.059-3.38,4.142-2.823,4.032-5.907c-1.737-1.762-4.199-1.488-6.414-1.72
                c-4.803-0.502-9.625-0.836-14.446-1.14c-8.354-0.526-15.903-3.439-22.979-7.705c-2.162-1.303-4.399-2.278-6.803-3.036
                c-6.661-2.102-13.164-4.816-19.931-6.447c-12.934-3.118-26.014-5.599-39.408-5.775c-6.18-0.081-12.3-0.85-18.351-2.077
                c-13.401-2.717-27.002-4.093-40.534-5.851c-15.855-2.06-31.644-4.552-47.479-6.721c-11.216-1.537-22.423-2.896-33.437-5.775
                c-5.248-1.372-10.905-1.158-16.371-1.728c-2.98-0.311-5.954-0.705-8.915-1.162c-1.84-0.284-3.745-0.14-4.081-3.086
                c-0.172-1.515-2.785-1.401-4.374-1.654c-7.071-1.125-14.173-2.062-21.234-3.243c-5.563-0.93-11.208-1.601-16.518-3.862
                c-0.681-1.467,1.534-1.654,1.147-2.933c-0.502-2.545-3.333-2.164-5.137-3.911c1.831-0.955,3.627-0.572,5.302-0.573
                c41.498-0.023,82.996-0.017,124.494-0.016c38.665,0,77.33-0.075,115.995,0.034c17.824,0.05,35.652-0.348,53.476,0.487
                c7.431,0.348,14.06,2.15,19.607,7.374c2.712,2.554,6.055,4.134,9.829,4.518c3.65,0.371,6.688,1.923,9.222,4.519
                c3.002,3.074,6.571,4.944,10.828,5.779c2.757,0.541,5.418,1.621,8.081,2.576c6.345,2.275,12.835,3.526,19.605,3.102
                c1.161-0.073,2.34-0.065,3.497,0.047c3.93,0.38,5.976,2.054,5.986,5.955c0.012,4.541,1.884,6.873,6.141,8.386
                c7.38,2.622,14.431,6.026,20.696,10.894c3.909,3.037,9.053,3.141,13.856,5.401c-1.042,4.192,0.762,7.547,3.91,10.373
                c1.91,1.715,3.972,2.984,6.635,3.096c4.927,0.208,8.903,2.501,12.387,5.811c1.45,1.377,2.8,2.875,4.35,4.127
                c4.788,3.87,9.458,7.952,16.128,8.27c1.793,0.085,3.573,0.779,5.326,1.304c3.387,1.015,3.55,1.23,3.207,4.963
                c-0.36,3.912,0.142,7.37,2.531,10.836c3.58,5.194,2.991,9.016-0.914,14.176c-2.556,3.376-5.495,6.274-9.472,7.913
                c-3.839,1.582-5.454,5.699-8.935,7.737c-0.98,0.574-0.809,2.166-0.391,3.285c0.967,2.594-0.313,4.473-1.779,6.368
                c-2.284,2.956-5.065,5.365-8.422,6.923c-3.638,1.688-7.063,3.704-10.51,5.726c-4.495,2.637-9.348,4.384-14.614,4.682
                c-1.698,0.096-3.316,0.279-4.674,1.426c-3.215,2.714-7.288,4.093-10.613,6.514c-5.335,3.884-11.217,7.067-15.88,11.853
                c-2.004,2.057-4.422,3.52-6.939,4.838c-6.786,3.553-13.582,7.087-20.363,10.649c-3.249,1.707-5.723,4.568-9.114,6.089
                c-9.112,4.086-18.172,8.289-27.311,12.316c-4.311,1.9-9.047,2.174-13.625,3.015c-1.979,0.363-3.937,0.628-5.514,2.073
                c-3.648,3.343-8.113,5.194-12.715,6.729c-7.278,2.426-14.336,5.336-21.216,8.755c-12.67,6.297-26.302,9.339-40.195,11.4
                c-4.751,0.705-9.495,1.456-14.079,2.161c-3.23-3.888-4.981-7.818-4.694-12.628c0.166-2.782-0.231-5.597-0.367-8.236
                c0.694-0.506,1.051-0.936,1.495-1.064c13.221-3.813,23.645-12.817,35.519-19.126c0.581-0.309,1.188-0.675,1.604-1.166
                c6.516-7.707,15.426-10.818,24.856-12.73c4.001-0.812,7.549-2.346,11.058-4.354c7.949-4.548,15.94-9.043,24.094-13.209
                c8.605-4.397,16.981-9.043,24.984-14.547c5.611-3.858,11.384-7.279,15.82-12.578C523.178,149.856,524.972,148.799,526.681,147.406
                z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M25.021,507.727c1.455,1.947,1.41,3.145,1.301,4.26
                c-0.368,3.783,0.179,7.178,2.686,10.312c1.843,2.305,1.455,5.36,1.533,8.195c0.058,2.12-0.105,4.491,3.363,5.177
                c1.764-3.274,1.136-7.892,4.708-10.358c-1.243-4.561,4.318-6.391,4.055-10.674c2.997,3.992,0.371,10.08,5.003,13.006
                c2.735-0.358,2.127-3.217,3.953-3.98c2.133,1.834,0.53,4.947,2.345,6.854c1.809,1.9,1.731,4.136,1.452,6.542
                c-0.232,1.999,0.571,3.56,3.311,3.898c0.571-3.682,2.424-6.675,5.121-9.44c0.866,4.023,3.941,7.318,3.675,11.867
                c-0.087,1.476,0.93,2.842,3.373,3.162c2.495-3.909,3.211-9.415,8.044-12.111c1.027,0.44,1.307,1.612,1.503,2.755
                c0.318,1.857,0.897,3.549,2.363,4.828c0.957,0.836,1.44,1.815,0.919,3.094c-0.351,0.859-0.225,1.771,0.418,2.312
                c1.904,1.602,1.822,3.801,2.055,5.938c0.071,0.655,0.25,1.307,0.446,1.938c0.354,1.138,0.794,2.308,2.254,2.259
                c1.353-0.047,1.92-1.114,2.433-2.207c1.067-2.27,0.321-5.018,2.129-7.465c3.164,1.123,4.292-1.414,5.357-4.181
                c4.045,8.044,4.045,8.044,6.8,9.173c1.09-2.353,2.494-4.484,5.504-4.73c1.354-0.11,1.358-1.787,2.421-2.501
                c0.954,1.425,1.039,3.06,1.39,4.624c1.041,4.636,2.445,5.239,6.986,2.934c2.108,1.455,4.285,2.957,6.305,4.35
                c0.809,1.113,0.088,2.111,0.216,3.054c0.346,2.547,1.635,3.64,4.087,2.75c1.739-0.631,2.822-0.308,4.068,0.645
                c1.735,1.326,3.485,1.65,5.385-0.245c1.464,2.506,1.521,5.186,1.981,7.738c0.532,2.945,0.861,5.93,1.938,8.756
                c0.449,1.177,1.105,2.201,2.504,1.899c1.074-0.232,1.741-1.306,1.509-2.301c-1.31-5.626,5.102-8.126,4.758-13.369
                c2.458,2.241,2.114,5.02,2.793,7.458c1.178,4.229,1.612,4.586,6.196,4.531c0.839,2.074,0.088,4.311,0.607,6.405
                c0.289,1.165,0.897,2.227,2.321,2.218c1.139-0.008,1.986-0.799,2.155-1.779c0.501-2.917,2.173-4.926,4.192-6.979
                c1.169-1.188,1.372-3.326,2.01-5.037c0.677-1.816,0.666-3.949,2.334-5.334c1.776,0.802,1.516,2.51,2,3.84
                c1.513,4.158,2.95,4.757,7.127,2.982c0.437-0.186,0.909-0.286,1.364-0.426c1.856,1.3,1.209,3.38,1.733,5.051
                c0.443,1.413,0.266,3.021,1.278,4.256c1.042,1.272,2.914,1.601,3.153,0.083c0.676-4.274,5.615-5.96,5.501-10.69
                c2.14,0.748,2.04,2.477,2.394,3.726c0.907,3.2,1.483,6.493,2.383,9.696c0.438,1.556,1.003,3.467,3.113,3.37
                c1.769-0.081,2.157-1.788,2.754-3.171c0.778-1.8,0.701-3.975,2.319-5.441c1.399,0.736,1.374,2.14,1.694,3.368
                c0.545,2.093,1.047,4.197,1.643,6.275c0.417,1.456,0.927,3.076,2.707,3.206c1.589,0.117,1.757-1.793,2.638-2.48
                c3.529-2.758,4.926-6.614,5.773-10.896c1.879,1.1,1.894,2.483,2.146,3.745c0.461,2.299,1.049,4.426,2.977,6.124
                c1.964,1.731,2.054,4.483,2.731,6.856c0.73,2.559,1.226,5.188,2.373,7.612c0.514,1.085,1.207,2.079,2.614,1.888
                c1.148-0.156,1.63-1.145,1.92-2.086c0.39-1.268,0.529-2.609,0.862-3.897c0.486-1.872,0.445-3.886,2.659-5.046
                c2.085-1.094,1.25-3.977,2.854-5.969c1.584,2.238,0.779,4.634,1.106,6.797c0.338,2.236,0.138,4.686,2.617,5.971
                c2.606-0.49,2.57-4.74,5.938-3.801c0.974,4.908,0.094,10.227,2.597,15.034c3.582-0.839,2.425-4.359,3.973-5.94
                c1.643-1.678,4.055-2.604,4.409-5.367c1.83,0.817,1.228,2.15,1.266,3.188c0.099,2.661,0.019,5.329,0.084,7.993
                c0.03,1.241,0.48,2.362,1.929,2.458c1.333,0.088,2.547-0.805,2.549-1.934c0.009-4.729,3.843-7.081,5.968-10.446
                c1.907,0.383,1.603,2.05,2.013,3.19c0.646,1.799,1.438,3.406,3.367,4.059c3.007,1.016,3.821,3.43,4.135,6.219
                c0.238,2.119,0.251,4.334,2.05,5.877c3.278,0.188,2.733-4.195,4.825-4.289c2.946-0.133,3.657-2.374,5.407-4.426
                c1.396,2.639,0.985,5.176,1.456,7.489c0.448,2.202,0.07,4.733,2.859,5.884c3.233-1.463,1.951-5.691,4.001-7.047
                c2.819-1.865,3.431-4.223,3.518-7.66c2.646,3.301,5.101,5.852,5.231,9.811c0.048,1.453,1,2.915,2.793,3.082
                c1.624,0.15,2.558-1.033,2.788-2.348c0.517-2.947,4.213-2.531,5.075-6.111c2.321,6.808-0.896,13.73,3.237,19.379
                c3.288-1.312,2.989-3.979,3.585-6.076c0.535-1.88,0.405-3.783,2.981-4.637c2.21-0.73,0.957-4.082,2.972-5.518
                c2.541,6.778,0.18,14.672,5.044,21.636c2.845-3.2,4.869-6.643,9.058-7.298c3.03,4.729,1.305,11.479,5.916,16.057
                c5.031-2.729,3.938-8.759,7.615-12.649c0.933,3.835,2.985,6.513,5.07,9.165c1.512,1.922,3.364,2.918,5.568,1.22
                c1.559-1.201,3.461-0.913,5.703-1.505c-0.489-4.571,1.848-7.934,5.388-11.346c2.044,6.741,5.973,12.367,10.101,17.939
                c3.655-1.734,2.085-5.253,3.706-8.243c3.285,6.872,6.341,13.19,10.874,19.019c4.406-5.066,8.591-8.902,11.293-14.445
                c1.561,1.58,1.219,3.309,1.348,4.807c0.243,2.819,0.196,5.664,0.43,8.484c0.435,5.226,1.657,5.931,6.642,3.956
                c2.261,1.36,0.955,5.562,5.02,5.91c0.886-3.456,5.356-4.28,6.19-8.108c1.676-0.031,1.776,1.371,2.202,2.336
                c0.77,1.74,1.636,3.537,3.736,3.551c2.157,0.014,2.822-2.018,3.739-3.6c0.48-0.826,0.61-1.949,2.211-2.502
                c0.33,4.125,4.137,6.233,5.497,9.92c0.507,1.375,2.512,2.574,3.678,1.166c2.435-2.939,5.795-0.678,8.52-2.213
                c-0.696,2.916-1.282,5.668-3.312,8.084c-2.115-0.923-2.224-4.082-4.83-3.988c-2.354,0.086-2.898,2.346-3.899,3.811
                c-1.828-0.631-1.39-2.609-3.18-3.214c-3.347-0.223-3.748,3.205-5.46,5.769c-1.767-2.654-4.127-4.46-4.577-7.586
                c-0.188-1.306-1.14-2.027-2.477-1.998c-1.121,0.023-2.151,0.781-2.196,1.727c-0.247,5.153-5.495,7.823-5.828,12.65
                c-1.747,0.559-1.823-1.652-3.17-1.359c-2.117,0.465-3.265,3.318-6.153,2.266c-0.909-0.33-1.577,1.457-2.112,2.494
                c-0.896,1.735-0.765,3.945-2.405,5.415c-1.281-0.958-1.048-2.401-1.419-3.585c-1.116-3.555-1.913-3.924-5.213-2.184
                c-0.447,0.236-0.835,0.505-1.796,0.123c-0.894-1.868-0.52-4.199-0.688-6.445c-0.099-1.325-0.161-2.69-0.537-3.949
                c-0.819-2.75-3.343-3.354-5.338-1.262c-0.899,0.941-1.479,2.188-2.194,3.279c-0.684,0.48-1.235,0.125-1.809-0.225
                c-0.562-0.342-1.12-0.76-2.484-0.189c-1.371,0.652-1.149,2.742-1.765,4.395c-0.563,1.511-0.219,3.342-1.712,4.837
                c-1.506-2.214-0.512-5.45-3.179-6.854c-3.982,1.553-11.036,11.419-16.016,22.404c-1.75-2.037-1.034-4.443-1.052-6.715
                c-0.048-6.006-4.19-13.229-9.452-16.135c-0.248-2.115-0.194-4.574-2.782-5.777c-1.197-0.558-1.325-1.82-1.46-3
                c-0.113-0.993-0.222-1.988-0.405-2.969c-0.367-1.967-1.354-3.66-3.506-3.53c-2.025,0.121-2.349,2.132-2.661,3.743
                c-0.502,2.598,0,5.35-1.237,8.057c-1.386,0.974-3.363,0.321-4.965,1.441c-1.816,1.27-3.167-0.076-3.758-1.84
                c-0.526-1.569-0.785-3.234-1.079-4.873c-0.304-1.693-0.903-3.225-2.244-4.342c-1.712-1.428-3.911-1.199-4.466,0.885
                c-1.122,4.208-3.737,7.277-6.415,10.473c-2.264,2.699-1.968,6.242-2.098,9.746c-2.234-0.378-3.116-1.555-2.39-3.498
                c1.95-5.213,0.403-9.405-3.406-13.112c-1.606-1.562-2.11-3.738-2.729-5.817c-0.378-1.27-0.611-2.611-1.716-3.533
                c-1.479-1.234-3.305-0.945-4.157,0.768c-0.36,0.725-0.542,1.578-0.626,2.393c-0.415,4.007-2.089,7.141-5.357,9.715
                c-3.755,2.957-4.738,7.471-5.448,11.986c-0.276,1.756,0.125,3.731-1.467,5.154c-1.906-0.039-2.839-1.09-2.423-2.869
                c1.089-4.648-0.964-8.162-4.027-11.182c-2.014-1.984-2.887-3.741-1.511-6.562c1.161-2.38-0.191-4.569-1.91-6.288
                c-2.479-2.481-4.951-4.895-6.191-8.332c-0.531-1.471-1.953-2.319-3.58-2.299c-1.814,0.021-2.482,1.434-2.991,2.9
                c-0.382,1.101-0.702,2.224-1.104,3.316c-0.421,1.145-1.108,2.231-2.415,2.219c-1.356-0.014-2.616-0.662-3.282-1.939
                c-0.376-0.721-0.595-1.573-0.68-2.389c-0.342-3.248-2.475-4.789-4.816-6.896c-3.579-3.225-9.145-4.099-11.044-9.398
                c-0.682-1.905-3.273-1.775-4.455,0.018c-0.621,0.943-0.941,2.121-1.235,3.236c-0.626,2.375-1.774,4.296-4.111,5.328
                c-2.582,1.139-3.751,3.174-3.74,5.941c0.005,1.228-0.236,2.378-1.396,3.004c-2.916,1.573-5.521,3.499-7.532,6.462
                c-0.332-2.892,0.431-5.623-0.677-8.239c-1.062-2.51-2.825-4.494-4.741-6.824c2.146-3.676,0.724-6.357-2.872-8.309
                c-2.147-1.164-2.783-3.166-2.771-5.485c0.008-1.685-0.208-3.325-1.328-4.712c-1.656-2.051-3.513-1.898-4.603,0.519
                c-0.541,1.199-0.827,2.532-1.09,3.833c-0.329,1.631-0.494,3.297-1.58,4.94c-2.568-1.009-2.686-3.534-3.322-5.587
                c-0.963-3.107-2.02-6.012-5.212-7.045c-2.399,0.096-1.898,2.951-4.208,3.232c-1.988-0.428-2.46-2.545-3.25-4.297
                c-1.094-2.426-1.907-4.98-3.06-7.375c-0.851-1.767-1.815-3.882-4.254-3.604c-2.171,0.247-2.74,2.342-3.156,4.074
                c-1.126,4.69-2.042,9.432-3.039,14.152c-0.406,1.924-0.751,3.871-2.154,5.953c-2.193-1.762-2.333-4.132-2.736-6.242
                c-0.812-4.26-1.168-8.639-5.198-11.454c-1.005-0.702-0.883-2.095-1.26-3.184c-1.004-2.894-2.354-3.343-4.781-1.591
                c-0.914,0.66-1.668,1.539-2.646,2.461c-1.597-1.417-3.424-2.135-5.942-2.549c1.63-1.008,2.896-0.794,4.089-0.863
                c1.342-0.08,2.673-0.14,3.896-1.385c-0.854-3.256-3.443-4.848-6.313-6.148c0.768-3.209,0.765-3.204-1.523-4.779
                c-1.634-1.125-3.597-1.769-4.907-3.322c-0.352-1.261,0.838-1.209,1.377-1.671c0.519-0.445,0.999-0.872,1.027-1.957
                c-0.23-2.313-3.174-1.692-4.456-3.445c-1.218-2.522,0.622-5.759-1.35-8.843c-3.678,2.43-3.049,6.76-4.833,9.82
                c-0.764,0.865-1.831,0.588-2.787,0.773c-3.692,0.717-4.554,2.612-1.936,5.238c1.386,1.389,0.107,2.383,0.406,3.245
                c0.865,1.997,3.326,0.976,4.406,3.118c-3.415,3.869-9.557,1.779-13.154,5.35c0.786,2.699,3.05,1.574,4.949,2.239
                c0.51,1.446-0.583,3.835,2.331,4.657c-3.574,1.371-4.371-2.496-6.888-2.406c-2.688,0.818-1.696,4.683-4.629,5.592
                c-2.087-0.731-1.822-2.752-2.192-4.459c-0.494-2.273-0.812-4.688-3.496-5.609c-1.089-0.373-1.291-1.31-1.658-2.273
                c-1.872-4.908-4.074-5.182-7.576-1.04c-0.209,0.247-0.51,0.415-0.77,0.62c-1.733,0.139-2.308-0.949-2.586-2.397
                c-0.221-1.144-0.469-2.284-0.757-3.413c-0.455-1.784-1.609-2.936-3.423-3.204c-1.747-0.259-2.777,0.853-3.502,2.272
                c-0.452,0.887-0.768,1.855-1.706,2.386c-0.693,0.069-1.523,0.015-1.821-0.563c-2.286-4.438-6.423-3.865-10.321-3.876
                c-1.811-0.005-2.713,1.331-3.002,2.851c-0.543,2.848-2.206,4.631-4.543,6.213c-2.974,2.012-3.977,5.204-3.849,9.056
                c-1.627-2.388-1.677-2.391-1.585-4.346c0.15-3.163,0.332-6.313-0.012-9.483c-0.273-2.525-0.779-4.996-2.147-7.107
                c-1.717-2.649-2.145-5.463-2.194-8.539c-0.089-5.481-1.354-6.527-6.963-6.031c-2.258,0.199-3.395-0.802-4.068-2.779
                c-0.48-1.41-0.955-2.854-1.688-4.135c-0.691-1.208-1.975-1.902-3.384-1.689c-1.445,0.219-2.261,1.363-2.601,2.727
                c-0.24,0.963-0.308,1.975-0.387,2.969c-0.235,2.958-1.176,5.38-3.927,7.068c-2.188,1.343-3.367,3.642-3.883,6.19
                c-0.23,1.14-0.346,2.312-1.291,3.167c-1.771,0.031-2.168-1.354-2.606-2.533c-1.123-3.021-1.118-6.205-1.133-9.373
                c-0.016-3.499,0.007-6.998-0.012-10.497c-0.009-1.672-0.112-3.342-0.841-4.888c-1.238-2.628-3.664-3.223-5.49-1
                c-1.576,1.918-3.048,3.195-5.715,3.214c-2.228,0.015-2.215,3.135-4.011,3.981c-2.291-1.287-1.011-3.057-0.875-4.539
                c0.198-2.141,1.165-4.174,0.96-6.394c-0.184-1.993-0.947-3.704-3.032-4.044c-1.793-0.294-2.475,1.33-3.159,2.682
                c-0.446,0.881-0.927,1.745-1.248,2.347c-1.484,0.956-2.42-0.7-3.589-0.159c-1.971,0.875-1.232,3.35-2.589,4.508
                c-1.484,0.492-2.664-0.36-3.949-0.584c-2.391-0.418-3.307-1.944-3.463-4.197c-0.254-3.655-0.43-7.324-0.889-10.955
                c-0.365-2.885-1.086-5.736-5.321-5.307c0-4.729,0-9.042,0-13.354c0-8.998,0.01-17.997-0.006-26.995
                c-0.004-2.351,0.298-4.102,2.507-5.947c2.243-1.874,3.471-5.051,6.418-6.855c1.319,2.031,1.184,4.354,1.451,6.585
                c0.276,2.306,0.362,4.692,1.596,6.745c1.133,1.884,3.317,2.162,3.9,0.365C20.721,513.736,24.365,512.099,25.021,507.727z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M413.594,284.21c5.059-1.038,8.974-2.639,12.716-4.985
                c3.864-2.423,8.486-2.914,13.008-2.902c2.529,0.006,4.992-0.25,7.429-0.755c2.457-0.509,4.777-0.882,7.362-0.253
                c3.142,0.764,6.267-0.505,9.255-1.451c2.855-0.904,5.685-1.864,8.608-2.534c1.79-0.411,3.348-1.196,4.906-2.283
                c3.245-2.264,6.957-2.991,10.892-1.946c4.907,1.302,9.33-0.054,12.908-3.273c3.678-3.309,7.824-4.505,12.375-3.564
                c5.374,1.111,9.919-0.539,14.036-3.464c2.085-1.481,4.136-2.715,6.558-3.404c5.442-1.548,10.89-3.081,16.32-4.67
                c1.952-0.571,3.906-0.562,5.918-0.582c5.637-0.055,10.767-1.393,14.895-5.745c3.24-3.416,7.867-4.272,12.26-5.096
                c3.291-0.618,6.483-1.438,9.656-2.455c1.734-0.556,3.537-1.18,5.324-1.232c4.963-0.145,9.449-1.813,13.882-3.784
                c9.274-4.121,18.377-8.662,28.191-11.46c0.754-0.215,1.618-0.47,1.901-1.843c-3.631-1.463-7.319-0.264-10.949-0.018
                c-3.775,0.256-7.539,0.615-11.401,0.36c0.08-1.876,1.481-1.96,2.418-2.366c9.488-4.107,19.307-7.29,29.207-10.222
                c2.589-0.767,5.3-0.857,7.924-0.243c2.949,0.69,5.669,0.181,8.191-1.251c7.667-4.354,15.387-8.631,22.872-13.282
                c5.267-3.271,10.533-5.801,16.76-7.208c9.262-2.093,17.546-7.026,25.399-12.437c0.536-0.369,1.121-0.872,1.354-1.445
                c2.134-5.233,7.003-7.327,11.328-10.091c9.417-6.019,19.538-10.747,29.294-16.145c3.48-1.926,6.84-4.073,10.381-6.195
                c3.609,2.653,7.674,4.05,12.057,4.426c2.159,0.186,3.797,1.03,5,3.039c-1.354,1.772-3.572,2.817-4.605,5.04
                c-0.721,1.551-2.324,2.541-4.115,2.344c-3.191-0.351-5.303,1.447-7.55,3.223c-4.815,3.808-9.187,8.254-14.723,11.052
                c-5.331,2.694-10.609,5.442-15.604,8.734c-2.365,1.559-4.88,2.801-7.865,2.899c-1.09,0.036-2.568,0.475-3.165,1.264
                c-3.253,4.297-8.424,4.35-12.831,5.953c-4.732,1.721-9.223,3.606-12.626,7.495c-0.776,0.887-1.966,1.176-3.078,1.566
                c-6.637,2.327-12.613,5.997-18.729,9.373c-0.788,0.436-1.869,0.896-1.61,2.285c1.464,1.483,3.187,0.545,4.62,0.116
                c10.941-3.277,22.26-5.208,32.322-11.399c5.312-3.268,11.348-5.461,16.535-9.131c1.216-0.86,2.675-1.248,4.184-1.532
                c2.658-0.5,5.143-1.327,6.186-4.328c0.623-1.794,2.313-2.632,4.015-2.244c2.812,0.641,4.843-0.638,6.909-2.067
                c3.711-2.569,7.77-4.458,11.823-6.412c4.156-2.003,8.442-4.036,10.272-8.878c0.492-1.303,1.802-1.993,3.092-2.357
                c6.492-1.837,12.262-5.004,17.555-9.571c3.137,1.913,6.254,3.775,9.836,4.62c0.754,0.178,1.404,0.84,2.076,1.314
                c5.953,4.197,11.753,8.641,19.49,9.144c1.762,0.114,3.654,0.805,5.168,1.744c7.009,4.346,15.271,5.312,22.715,8.578
                c4.86,2.133,10.442,3.253,16.092,2.649c3.629-0.388,7.307-0.247,10.798,1.155c2.365,0.949,5.019-0.116,8.147,1.403
                c-3.768,1.389-6.162,3.774-8.368,6.409c-1.913,2.284-4.734,3.151-7.437,3.863c-15.464,4.077-30.461,9.661-45.812,14.09
                c-3.264,0.942-6.05,2.723-8.872,4.496c-4.162,2.614-8.29,4.335-13.613,3.002c-5.144-1.289-10.354,0.395-14.841,3.104
                c-4.485,2.708-9.241,4.561-14.202,5.989c-11.381,3.275-22.139,8.235-33.248,12.245c-10.547,3.806-21.447,6.076-32.379,8.25
                c-6.254,1.244-12.28,2.9-17.854,6.123c-3.827,2.213-8.02,3.175-12.492,2.51c-3.5-0.521-7.026-0.339-10.457,0.311
                c-11.086,2.102-22.367,3.399-32.297,9.668c-4.943,3.12-11.078,3.429-16.558,5.383c-0.684,0.244-1.77,0.071-2.393-0.334
                c-5.011-3.266-9.997-1.364-14.899,0.105c-2.534,0.759-4.918,1.967-7.576,2.446c-1.798,0.324-3.679,0.982-4.79,2.498
                c-1.907,2.603-4.517,2.562-7.189,2.254c-2.809-0.324-5.581-0.966-8.387-1.324c-6.215-0.792-9.559,0.879-11.816,6.633
                c-1.929,4.915-5.355,6.631-10.351,7.016c-8.034,0.619-15.987,0-23.944-0.759c-4.529-0.432-8.988-0.244-13.428,0.59
                c-1.461,0.274-3.255,0.422-4.235,1.337c-5.528,5.163-12.68,4.329-19.251,5.368c-9.849,1.557-19.811,2.406-29.662,3.952
                c-14.616,2.292-29.011,5.965-43.832,6.997c-1.158,0.081-2.298,0.378-3.452,0.537c-2.066,0.284-4.007,2.559-6.093,1.082
                c-2.935-2.078-6.508-3.43-8.283-6.986C417.529,291.083,415.663,287.955,413.594,284.21z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M179.519,833.369c1.159,2.661,3.604,2.967,5.715,3.828
                c2.127,0.869,4.622,0.754,6.479,2.368c-0.444,1.655-1.85,1.188-2.877,1.294c-5.967,0.617-11.952,0.969-17.955,1.044
                c-1.92,0.024-4.072-0.35-5.438,1.813c0.345,2.716,2.529,3.154,4.634,3.641c5.189,1.197,10.401,2.305,15.575,3.567
                c2.383,0.582,4.887,0.961,7.267,2.794c-4.518,2.449-9.851-0.896-13.809,2.318c0.039,2.308,1.643,2.646,3.062,2.954
                c15.606,3.386,31.183,7.13,47.363,4.344c3.31-0.569,6.605-0.533,9.904,0.37c1.544,0.422,2.353,1.09,2.181,2.862
                c-4.751,1.108-10.088-2.684-14.765,1.294c1.306,3.249,4.758,2.381,7.505,4.682c-6.565-0.118-12.019,2.306-18.029,0.791
                c-7.44-1.875-7.511-1.595-11.085-0.633c-0.458,1.581,0.769,2.051,1.792,2.484c3.371,1.426,6.76,2.814,10.182,4.111
                c3.109,1.179,6.276,2.206,9.207,3.987c-0.777,0.915-2.615,0.483-2.895,2.312c0.154,0.247,0.306,0.795,0.658,1.004
                c1.627,0.962,3.453,1.078,5.303,1.119c1.435,0.031,3.041-0.332,4.153,1.281c-0.438,3.782-5.079,2.808-6.604,6.123
                c3.333,2.573,7.626,2.529,11.436,3.672c3.821,1.145,7.724,2.059,11.637,2.846c3.975,0.801,8.16-0.422,12.916,0.822
                c-2.258,2.341-5.208,0.947-6.822,3.112c-0.531,0.929,0.823,1.519,0.926,2.634c-1.672,1.87-4.342,2.808-5.655,5.248
                c-0.792,1.47-2.313,0.959-3.615,0.832c-7.509-0.729-14.654-3.041-21.874-5.027c-6.899-1.899-13.758-3.961-20.706-5.662
                c-5.123-1.254-6.536,0.309-5.352,5.346c0.304,1.293,0.792,2.543,1.126,3.83c0.29,1.113,1.019,2.142,0.523,3.771
                c-1.963,1.562-3.701,3.688-2.588,7.096c-21.809,0-43.238,0-64.858,0c0.842-0.949,2.411-0.87,2.312-2.725
                c-3.789-0.803-4.712-5.548-8.805-6.604c6.484,0.744,11.636-3.031,17.408-4.334c0.476-0.107,0.94-0.351,1.359-0.612
                c1.268-0.792,2.866-1.556,2.545-3.331c-0.396-2.189-2.388-2.1-4.003-1.957c-3.479,0.309-6.948,0.775-10.404,1.286
                c-2.955,0.437-5.896,0.895-8.817,0.68c-0.632-1.597,1.833-2.261,0.578-3.935c-1.907-0.807-3.988-0.132-6.083,0.376
                c-8.758,2.121-17.13,5.439-25.675,8.223c-1.766,0.575-3.484,1.92-5.583,0.251c-1.015-0.808-2.609-0.068-3.81,0.501
                c-4.347,2.06-8.733,1.118-14.283,0.438c7.264-4.105,15.377-3.846,21.124-9.279c-3.821-4.069-8.503-2.604-13.729-2.906
                c5.338-4.089,11.407-4.441,17.023-7.766c-4.188-1.006-7.428,0.257-10.688,0.59c-3.277,0.334-6.538,0.496-10.466,0.205
                c2.36-3.15,7.141-1.492,8.165-5.2c-0.93-2.536-3.312-1.224-4.944-2.245c2.877-2.669,7.347-2.379,9.742-5.162
                c0.386-2.387-1.838-1.826-2.554-2.939c0.275-0.363,0.475-0.869,0.842-1.076c4.354-2.459,9.227-2.653,14.028-3.047
                c3.319-0.273,6.661-0.271,9.922-1.068c1.112-0.271,2.37-0.303,2.704-2.145c-1.797-1.663-4.369-0.363-6.583-1.349
                c0.806-1.247,2.063-1.415,3.288-1.614c6.464-1.055,12.639-2.834,18.16-6.571c2.682-1.815,5.95-2.007,9.06-2.594
                c2.381-0.448,4.205-1.456,2.642-4.489c2.341-0.58,4.442-1.018,6.491-1.633c1.75-0.525,3.497-1.161,4.699-2.686
                c1.907-2.42,1.135-4.456-1.92-4.893c-1.729-0.246-3.164-0.277-4.811-1.703c-1.865-1.615-5.054-1.166-7.73-0.992
                c-4.644,0.304-9.276,0.904-13.579-1.815c-1.563-0.988-3.583-0.524-5.411-0.466c-2.106,0.068-4.228,0.334-6.24-0.457
                c0.172-1.672,1.322-1.548,2.239-1.701c6.073-1.018,12.156-1.982,18.224-3.031c2.311-0.398,4.497-1.233,6.51-2.455
                c1.14-0.691,2.29-1.412,2.344-3.002c-1.504-2.054-3.597-1.338-5.496-1.127c-5.958,0.659-11.894,1.503-17.892,1.793
                c-3.983,0.191-7.905-1.369-12.25-0.035c-0.275-2.777-1.874-4.117-4.588-4.381c3.32-1.421,6.729-1.815,9.374-4.666
                c1.685-1.815,5.084-0.913,7.74-1.006c3.451-0.121,7.005,0.219,9.887-1.881c0.55-1.533-0.644-1.9-1.842-2.992
                c8.028-2.285,15.579-4.436,23.311-6.636c-1.731-2.216-3.913-1.703-5.77-1.623c-4.365,0.189-8.291-0.471-11.589-4.765
                c-0.201-0.381,1.75-1.464-0.586-3.045c-3.42-0.12-7.885,2.487-12.52-0.438c-0.876-1.857,0.765-2.387,2.523-2.58
                c3.975-0.438,7.96-0.783,11.947-1.082c3.185-0.238,6.275-0.844,9.212-2.094c2.782-1.186,5.135-2.893,6.663-5.619
                c-0.405-0.251-0.579-0.437-0.667-0.402c-4.499,1.762-8.53-0.201-12.578-1.663c-2.772-1.001-5.586-0.676-8.399-0.709
                c-2.493-0.029-4.987-0.006-7.863-0.006c1.73-2.352,4.683-2.081,6.682-3.464c2.327-1.609,4.687-3.176,6.937-4.887
                c2.077-1.579,4.481-2.931,5.222-5.82c-1.712-2.421-3.982-1.531-5.921-0.961c-6.226,1.833-12.383,3.901-18.579,5.84
                c-2.541,0.794-5.102,1.532-7.676,2.209c-1.569,0.413-3.162,1.018-4.987,0.258c1.518-4.473,7.68-4.039,8.932-8.855
                c-1.397-2.641-4.246-1.558-6.878-2.149c-0.81-3.843-4.251-3.606-7.687-3.94c4.21-1.814,4.21-1.814,5.481-5.203
                c13.946-1.153,27.551-4.719,40.979-9.041c0.272-0.087,0.328-0.85,0.474-1.264c-1.05-1.791-2.718-0.973-4.097-1.162
                c-2.086-0.285-4.597,0.953-5.818-2.16c-0.413-1.053-2.129-0.741-3.26-0.621c-2.646,0.282-5.288,0.648-7.911,1.102
                c-1.469,0.255-2.893,0.832-4.609,0.08c0.51-2.719,2.558-3.406,4.866-3.967c8.752-2.126,17.089-5.544,25.619-8.361
                c0.964-0.318,1.922-0.619,2.217-1.768c-2.234-2.037-2.234-2.037-10.474-2.811c-0.839-1.006,0.742-1.735-0.029-3.456
                c-9.043-2.306-18.662,1.065-28.412-0.312c-0.667-1.168-0.046-2.594-0.307-4.07c4.823-1.822,10.415-0.928,14.266-4.043
                c0.762-1.696-0.685-2.218-0.618-3.264c0.201-0.191,0.385-0.509,0.642-0.586c4.179-1.262,8.529-1.396,12.823-1.885
                c3.48-0.396,6.944-0.84,10.282-1.952c2.313-0.771,4.166-2.041,4.888-4.556c1.364-4.754-1.554-8.123-6.347-7.062
                c-2.75,0.607-5.387,1.717-8.094,2.536c-2.356,0.714-4.534,2.401-7.212,0.136c-1.08-0.912-3.206,0.025-4.838,0.387
                c-3.087,0.683-6.185,1.316-9.286,1.928c-0.932,0.184-1.926,0.408-3.296-0.899c8.355-2.731,15.977-6.203,24.339-7.505
                c3.572-0.556,3.557-0.653,5.684-3.311c-2.183-3.027-5.65-0.167-8.314-1.848c-2.538-1.602-5.855,0.522-8.712-0.475
                c-0.078-0.766,0.297-1.107,0.807-1.115c6.11-0.08,11.624-2.685,17.413-4.109c2.061-0.507,4.227-0.949,3.683-4.086
                c-0.171-0.984,2.124-1.057,1.832-2.93c-1.444-1.803-3.746-2.086-6.056-2.286c-2.655-0.229-5.32-0.344-7.973-0.599
                c-2.162-0.208-4.282-0.611-5.695-2.605c6.131-3.727,12.271-7.082,20.249-7.094c-2.587-4.43-5.59-2.612-8.109-2.599
                c-2.651,0.015-5.175,1.265-8.22,1.003c-0.184-2.282,0.34-3.881,2.729-4.84c3.542-1.424,6.871-3.33,10.587-4.396
                c1.813-0.521,3.978-0.888,4.858-3.401c-3.785-2.148-7.809,0.426-11.861-0.727c2.655-2.586,7.192-2.242,8.911-5.951
                c-2.536-2.191-5.542-0.558-8.725-1.323c3.935-3.175,9.112-2.202,12.791-5.458c-1.76-2.514-4.438-1.888-6.865-2.334
                c1.522-2.15,3.202-3.281,5.54-3.404c1.211-0.064,2.449-0.381,2.284-1.969c-0.128-1.235-1.254-1.427-2.256-1.541
                c-0.977-0.112-1.965-0.117-2.895-0.168c-1.392-1.672,1.199-3.723-1.281-4.975c2.559-0.925,4.807-1.058,6.613-2.348
                c1.632-1.168,3.037-3.076,1.283-4.514c-1.904-1.562-0.292-2.98-0.589-4.08c-1.216-1.984-3.74-0.828-5.223-3.002
                c3.683-0.883,7.636-1.347,7.193-7.336c1.801,2.894,1.782,5.953,5.479,5.994c-1.581,1.898-3.923,3.439-1.258,5.49
                c1.704,1.311,3.537,2.454,5.334,3.688c-0.794,1.162-2.685,0.691-3.139,2.611c1.504,3.717,7.299,0.839,8.808,5.663
                c-3.267,0.987-7.201-0.292-10.255,2.148c0.498,2.608,2.67,2.319,4.279,2.572c2.61,0.41,4.723,1.363,5.965,3.93
                c-0.383,2.821-2.584,2.341-4.529,2.396c-1.799,0.051-3.67-0.368-5.578,0.842c0.985,3.519,4.296,2.862,6.697,3.689
                c2.512,0.864,5.271,0.825,7.84,2.103c-0.85,1.228-2.984,0.703-3.291,2.479c-0.272,1.713,2.213,1.839,2.143,3.517
                c-1.661,2.119-4.784-0.653-6.261,1.967c0.336,2.285,2.369,2.669,3.998,3.18c2.965,0.928,4.359,2.688,3.983,5.865
                c-3.449,1.307-7.171-0.922-11.264,0.754c2.494,2.736,4.953,4.611,8.354,4.957c1.319,0.133,2.666-0.029,3.993,0.053
                c1.228,0.075,2.638-0.654,3.542,0.973c1.183,2.129-1.882,1.996-1.72,3.709c1.015,2.635,4.525,1.453,6.341,3.864
                c-7.502,2.884-15.722,1.121-23.426,3.913c2.145,4.951,7.519,3.207,10.092,6.178c2.98,3.439,7.214,1.492,10.804,2.311
                c-0.922,4.904-0.506,5.593,4.358,6.943c3.378,0.938,6.511,2.423,9.736,3.993c-5.916,3.28-5.914,3.241-11.804,2.913
                c-2.935-0.164-5.809-0.795-8.713,1.021c-1.657,1.036-3.821-0.586-5.736-1.048c-2.226-0.536-4.249-0.887-5.571,1.34
                c0.063,2.124,2.212,2.604,2.761,4.162c-0.662,1.113-2.444-0.135-2.814,1.473c-0.147,0.668,0.011,1.34,0.643,1.69
                c6.26,3.477,12.753,6.285,19.888,7.523c7.692,1.335,15.341,2.913,22.876,4.36c0.443,1.474,0.54,2.975-0.636,3.322
                c-2.311,0.684-3.991,3.027-6.906,2.241c-2.925-0.788-5.544-2.249-8.372-3.223c-2.186-0.753-3.473-0.173-3.686,2.437
                c-7.447-0.387-14.571-3.262-21.965-3.242c-2.353,6.888,0.416,7.516,5.012,8.091c2.14,0.269,4.286,0.485,6.928,0.782
                c-0.82,1.618-2.4,3.281,0.318,4.234c1.574,0.552,3.201,0.804,4.838,1.021c-0.19,1.434-2.204,1.217-1.805,3.479
                c11.592,2.045,23.378,4.297,35.627,2.249c-1.392,1.851-3.275,1.954-5.109,2.258c-7.725,1.28-15.627,1.171-23.283,2.93
                c-2.33,0.535-5.019,0.633-6.555,3.684c4.131,3.468,9.275,3.563,14.031,4.229c4.817,0.676,8.973,3.709,14.446,3.267
                c-0.976,2.2-0.661,3.685,1.716,4.106c1.671,0.296,3.312,0.17,4.975,0.028c1.973-0.169,3.954-0.241,6.711-0.399
                c-2.762,3-5.922,2.173-8.546,2.889c-3.036,0.829-6.138,1.552-9.258,1.896c-2.819,0.311-5.932-1.29-8.629,1.587
                c2.671,3.713,7.461,2.279,10.81,4.758c-3.156,3.898-7.404,4.148-11.623,3.705c-4.959-0.522-9.861-1.582-14.794-2.369
                c-3.224-0.514-3.596-0.399-6.679,1.667c3.879,1.267,2.752,6.925,8.116,7.05c-1.497,1.023-2.284,1.561-2.992,2.045
                c-0.089,1.592,1.766,1.428,2.083,2.789c-3.747,1.219-7.387-1.441-10.964-0.471c-0.752,1.295-0.185,2.06,0.674,2.709
                c2.694,2.042,5.622,3.474,9.037,3.967c4.469,0.646,8.965,0.717,13.453,0.999c3.347,0.21,3.544,0.349,6.086,2.54
                c-2.408,0.753-5.014-0.439-7.229,0.725c-1.359,1.416,0.504,2.512,0.163,3.9c-2.43,1.754-5.565-0.838-8.183,1.277
                c0.745,3.369,4.011,1.991,6.315,3.305c0.077,2.446,1.702,4.041,4.315,4.79c1.759,0.504,3.489,1.12,5.205,1.759
                c2.026,0.754,2.294,2.158,1.329,3.957c-6.164,1.745-12.14,0.729-18.166-0.846c-3.628-0.947-7.296-2.402-11.247-1.313
                c-1.012,2.738,0.396,4.259,2.506,5.229c1.495,0.687,3.135,1.145,4.759,1.444c2.486,0.459,4.69,1.296,6.867,2.703
                c3.52,2.273,7.732,2.998,11.712,4.189c3.501,1.047,7.051,1.935,10.379,2.839c1.138,1.314-1.313,2.597,0.349,3.62
                c1.099,0.676,2.209,1.334,4.136,2.496c-3.197,0.718-5.554,0.267-7.871-0.172c-5.07-0.959-10.129-1.977-15.181-3.031
                c-2.464-0.514-4.946-0.723-7.448-0.645C182.52,830.842,180.665,831.014,179.519,833.369z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M1088.933,44.946c0.652-4.082,0.322-7.965-2.918-11.047
                c-0.638-0.606-0.928-1.384-0.804-2.26c0.773-5.477-2.424-8.291-6.859-10.294c-0.297-0.135-0.545-0.379-0.815-0.573
                c-1.392-10.116-9.33-14.932-20.937-8.149c-1.609-0.674-2.164-2.464-3.544-3.412c-3.753-2.577-7.758-2.99-11.86-1.157
                c-11.852,5.296-23.981,10.063-34.666,17.645c-2.238,1.588-4.503,2.12-7.352,2.227c-9.881,0.372-18.528,3.845-24.733,11.998
                c-3.753,4.929-6.416,10.515-9.253,15.981c-1.697,3.273-3.701,6.282-6.049,9.131c-5.828,7.07-11.621,14.175-17.196,21.444
                c-2.921,3.807-6.688,5.174-11.213,5.36c-3.494,0.144-6.983,0.428-10.474,0.658c-4.188,0.276-8.273,1.023-12.055,2.932
                c-1.426,0.719-2.816,0.855-4.342,0.458c-7.422-1.933-14.96-3.27-22.552-4.327c-0.631-0.088-1.226-0.433-2.066-0.743
                c0.335-2.867,1.841-4.948,3.736-6.809c2.094-2.056,3.567-4.454,3.706-7.421c0.133-2.825,1.795-4.993,2.903-7.377
                c2.165-4.659,3.335-9.718,5.995-14.188c1.094-1.84,1.283-4.036,0.88-6.297c-0.492-2.759-1.116-5.568,0.043-8.376
                c0.384-0.931,0.39-2.353-0.312-2.864c-3.385-2.468-0.543-8.575-6.207-9.894c-2.484,2.835-5.113,5.369-3.419,10.157
                c2.343,6.621-1.269,12.438-3.573,18.352c-2.958,1.497-4.887,3.809-5.641,7.07c-0.188,0.809-0.727,1.63-0.638,2.392
                c0.368,3.164-0.856,5.437-3.388,7.249c-1.539,1.102-2.176,2.882-2.869,4.599c-1.299,3.213-2.692,6.387-4.111,9.731
                c-4.709-0.394-8.613-2.521-13.38-3.741c1.691-1.811,2.972-3.057,4.104-4.426c1.369-1.654,2.729-3.234,2.132-5.792
                c-0.543-2.333,1.25-4.081,2.551-5.731c1.455-1.845,2.821-3.668,3.654-5.888c0.342-0.908,0.617-2.176,1.62-2.41
                c3.086-0.719,3.234-3.249,3.228-5.476c-0.01-3.981,1.333-8.29-2.123-11.708c-0.418-0.414-0.596-1.238-0.604-1.875
                c-0.084-7.517-3.777-13.457-8.381-18.927c-4.846-5.761-10.137-11.036-17.372-13.826c-0.116-0.045-0.294,0.065-1.035,0.255
                c0.873,4.233,3.142,7.804,5.343,11.396c0.154,0.252,0.563,0.554,0.805,0.516c3.486-0.544,4.225,2.237,4.854,4.458
                c1.218,4.297,2.534,8.553,3.558,13.209c-1.043-1.286-2.021-2.63-3.141-3.846c-2.562-2.786-4.872-5.69-9.081-6.523
                c-2.556-0.506-4.788-2.796-5.353-5.481c-1.162-5.52-5.379-8.078-9.775-9.801c-4.725-1.852-8.969-4.291-13.148-7.038
                c-2.633-1.73-5.453-3.174-8.233-4.771c0.771-1.838,2.304-1.166,3.456-1.167c26.164-0.042,52.329,0.038,78.492-0.088
                c5.341-0.025,8.318,3.514,10.699,7.052c2.002,2.975,4.4,5.387,6.865,7.797c1.336,1.307,2.321,2.709,3.311,4.264
                c4.521,7.105,2.385,14.311,0.629,21.543c-0.188,0.78-0.882,1.424-0.632,2.317c0.043,0.154,0.169,0.285,0.263,0.434
                c0.318-0.016,0.772,0.102,0.945-0.068c2.92-2.858,6.672-4.653,9.65-7.355c3.06-2.775,6.068-5.841,8.229-9.321
                c3.383-5.451,5.996-11.369,7.351-17.74c0.628-2.955-0.116-5.569-1.841-7.982c3.515-1.109,277.123-1.335,283.22-0.092
                c-4.188,3.661-7.838,7.202-11.849,10.274c-5.927,4.54-10.664,9.917-14.15,16.559c-4.041,7.698-10.764,12.753-18.504,16.128
                c-6.196,2.701-11.075,6.693-15.115,11.894c-1.478,1.903-3.078,3.216-5.473,3.999c-4.33,1.415-7.852,4.29-11.109,7.443
                c-1.075,1.04-2.086,2.176-3.283,3.055c-2.135,1.568-4.319,1.585-6.353-0.297c-0.489-0.453-0.95-0.938-1.458-1.368
                c-3.153-2.677-4.97-2.972-8.562-0.858c-2.429,1.429-4.707,3.147-6.919,4.904c-7.312,5.809-14.181,12.13-21.026,18.473
                c-1.474,1.366-2.948,2.714-4.806,3.534c-7.062,3.119-13.343,7.5-19.53,12.035c-2.979,2.184-6.023,3.964-10.011,3.816
                c-2.063-0.077-4.165,0.856-6.762,1.45c0.648-3.712,2.102-6.826,4.709-9.015c6.368-5.345,11.921-11.446,17.532-17.533
                c2.563-2.781,6.005-4.697,7.917-8.2c0.653-1.198,2.41-0.949,3.654-1.367c6.715-2.255,9.504-6.833,8.219-13.781
                c-0.186-1.001-0.375-1.947-0.105-2.943c1.297-4.799-0.52-8.446-4.211-11.409C1089.642,45.803,1089.352,45.399,1088.933,44.946z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M14.393,743.885c1.533,3.025,2.702,5.228,3.772,7.477
                c0.973,2.042,2.068,3.809,0.407,6.395c-1.448,2.254,0.699,5.094,4.497,7.052c1.873-0.572,2.27-2.329,2.779-3.842
                c1.495-4.438,2.339-9.021,2.459-13.713c0.025-0.986,0.19-2.3-0.341-2.915c-3.722-4.314-0.551-7.827,1.21-11.688
                c1.165,3.887,3.744,6.902,5.66,10.688c-1.988,0.531-3.324,1.396-2.076,3.693c1.745,3.214,3.293,6.535,4.961,9.883
                c0.17,1.738-2.405,2.234-1.947,4.234c0.383,1.676,1.146,3.113,2.038,4.517c0.892,1.405,1.856,2.765,2.769,4.157
                c1.114,1.701,3.01,3.062,1.654,5.67c-0.35,0.672,0.68,2.109,1.161,3.156c0.896,1.947,2.142,3.768,2.114,5.979
                c-0.195,0.359-0.277,0.786-0.519,0.916c-4.726,2.553-4.953,3.738-1.305,7.582c1.723,1.814,3.624,3.436,5.74,4.793
                c1.349,0.865,2.709,1.115,4.249,0.441c5.121-2.24,7.171-9.648,3.844-14.222c0.546-2.164,2.337-3.552,3.546-5.312
                c1.219-1.774,2.752-3.348,3.34-5.33c0.036-1.594-1.444-1.73-2.5-2.62c2.081-2.604,3.997-5.003,6.522-8.165
                c1.837,5.258,5.757,8.742,3.866,14.131c-0.453,1.29,0.642,2.525,1.118,3.748c0.786,2.014,1.666,3.99,2.488,5.939
                c-2.713,4.414-2.507,8.615,1.18,12.496c1.818,1.914,3.105,3.808,3.431,6.4c0.08,0.644,0.376,1.285,0.681,1.87
                c2.374,4.556,5.876,4.817,8.777,0.634c1.926-2.779,2.822-5.99,3.796-9.184c0.703-2.304-0.783-3.664-1.947-4.936
                c1.904-5.912,2.329-6.633,5.757-9.733c2.4,4.367,4.767,8.673,7.111,12.939c-2.213,2.756-2.301,2.808-0.728,5.503
                c2.094,3.587,4.341,7.084,6.656,10.838c-2.191,1.232-1.876,3.832-4.087,5.379c-2.63,1.838-5.136,3.295-8.259,3.873
                c-1.587,0.293-3.351,0.547-3.924,2.457c0.658,2.512,3.145,1.35,4.547,2.367c-0.821,1.586-0.971,2.935,1.245,3.328
                c0.65,0.115,1.329,0.158,1.988,0.12c10.404-0.598,20.897,0.431,31.219-1.698c0.775-0.16,1.608-0.348,2.866,0.508
                c-9.396,5.029-20.158,3.156-29.861,7.148c0.87,2.613,2.631,3.808,5.073,4.008c1.984,0.162,3.992,0.025,5.989,0.037
                c1.326,0.008,2.834-0.363,3.914,0.439c3.719,2.762,7.943,1.82,12.039,1.932c2.649,0.072,5.485-1.11,7.513,2.1
                c0.857,1.357,3.133,0.486,4.768,0.57c0.959,0.05,1.954-0.127,3.175,0.635c-3.133,5.33-9.657,2.932-13.429,6.224
                c-0.773,1.027,0.369,1.84,0.314,2.789c-0.293,0.149-0.575,0.388-0.886,0.432c-4.698,0.667-8.952,2.429-12.908,5.027
                c-3.579,2.352-7.759,2.925-11.842,3.684c-2.641,0.491-5.169,1.238-7.605,2.35c-1.901,0.867-1.92,2.049-0.353,3.693
                c-7.358,1.377-15.359,0.255-20.663,6.344c-0.497,1.905,1.859,1.655,1.611,3.171c-2.756,1.945-6.727,1.852-8.854,4.74
                c-0.107,2.769,2.696,2.098,3.64,3.49c-2.364,1.254-5.764,0.691-7.029,4.068c2.769,3.238,6.578,1.802,10.228,2.473
                c-1.405,2.016-4.446,2.023-5.011,4.839c3.094,4.657,8.658-0.665,12.432,3.036c-6.581,4.323-15.159,3.898-20.841,8.576
                c-0.422,1.287,0.017,1.758,0.416,1.855c4.378,1.072,8.889,1.301,13.318,1.015c4.81-0.312,9.703,0.271,14.411-1.985
                c4.321-2.071,9.068-3.271,13.657-4.762c4.103-1.332,8.245-2.539,12.139-3.729c2.012,1.164-1.121,2.713,0.961,3.725
                c7.831,1.437,15.678-1.96,23.843-0.985c-3.894,2.488-8.213,3.959-12.539,5.116c-4.16,1.113-8.77,0.299-13.295,2.312
                c1.898,2.577,4.2,2.375,6.208,2.794c2.449,0.51,1.983,3.639,5.2,4.221c-4.798,2.152-8.989,2.633-12.897,2.654
                c-37.648,0.207-75.298,0.113-112.947,0.095c-0.484,0-0.97-0.138-1.405-0.204c-1.323-1.037-0.943-2.467-0.943-3.736
                C3.729,871.07,3.738,822.26,3.711,773.448c-0.001-2.078,0.453-3.825,1.746-5.54c2.213-2.937,3.771-6.273,4.911-9.777
                c0.357-1.099,1.061-2.488,0.69-3.353C9.151,750.323,12.645,747.791,14.393,743.885z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M283.82,791.387c-3.187-1.623-6.638-1.768-10.144-1.781
                c-2.592-0.012-5.382,0.384-7.12-2.377c-0.228-0.362-0.834-0.67-1.276-0.684c-3.368-0.104-5.668-2.263-8.331-4.391
                c3.829-2.863,8.169-3.977,12.853-4.152c3.447-0.13,6.912-0.252,9.55-2.223c0.739-1.841-1.768-1.834-1.088-3.373
                c4.413-1.354,9.153,0.922,13.456-0.764c0.802-1.964-0.25-2.775-1.593-3.233c-1.569-0.535-3.188-1.009-4.823-1.247
                c-2.072-0.303-2.948-1.709-4.068-3.278c1.958-1.231,3.88-0.757,5.595-1.229c1.641-0.452,3.454-0.942,3.007-3.195
                c-0.406-2.049-2.183-1.782-3.641-1.542c-4.432,0.729-8.9,1.28-13.222,2.587c-6.085,1.838-12.279,2.514-18.654,1.759
                c0.775-4.411,4.783-2.06,7.256-3.8c0.047-4.652,3.069-6.465,7.65-7.046c2.515-0.319,6.077-0.014,6.031-4.291
                c3.65-1.107,4.644-5.663,8.798-6.405c4.225-0.755,8.369-2.128,12.081-3.899c0.888-1.038,0.748-2.08,0.799-3.055
                c0.235-4.515-0.134-4.948-4.508-4.956c-1.336-0.002-2.576,0.365-3.226-1.416c-0.286-0.785-1.533-0.688-2.268-0.386
                c-3.59,1.472-7.18-0.342-11.109,0.573c1.082-3.904,7.568-1.205,6.054-6.898c4.091,0,7.594,0.21,11.06-0.061
                c3.271-0.255,3.71-1.136,3.501-4.998c-1.511-1.525-3.157,0.498-5.132-0.332c3.363-2.88,4.082-6.893,4.802-10.658
                c2.128-0.567,2.067,1.91,4.949,1.188c-3.364,2.986-2.31,4.88-1.098,7.232c1.184,2.297,3.377,2.902,5.448,4.209
                c-1.772,1.016-3.383-0.264-4.649,0.877c-1.324,4.445-1.23,4.762,2.766,6.13c3.738,1.28,7.526,2.414,11.218,3.884
                c-3.305,0.068-6.362-0.95-9.477-1.818c-3.775-1.053-5.196,0.123-5.423,4.086c-0.047,0.832-0.069,1.668-0.029,2.499
                c0.201,4.185,0.546,4.604,4.745,5.749c0.795,0.217,1.622,0.318,2.455,0.477c-0.607,2.911-3.085,3.544-5.674,4.576
                c1.656,3.108,4.321,3.279,6.858,3.119c3.799-0.241,7.256,0.496,10.474,2.512c-0.641,1.045-2.404,0.164-2.778,1.883
                c2.973,5.361,9.128,4.906,14.704,7.236c-2.802,1.01-4.824,0.605-6.8,0.093c-1.612-0.419-3.269-1.305-4.487,0.56
                c-1.186,1.814,1.497,2.206,1.554,3.807c-5.581,0.24-11.172-0.482-16.69,0.287c0.171,2.415,2.137,1.967,3.18,3.383
                c-2.673,0.311-5.148,0.893-6.251,3.657c1.321,2.681,4.28,3.38,6.718,4.976c-1.962,1.395-4.254,0.73-5.652,2.049
                c-0.087,1.391,0.793,1.591,1.594,1.667c5.447,0.521,10.704,2.549,16.286,2.035c1.162-0.106,2.341,0.072,3.398,0.646
                c2.217,1.201,4.519,2.016,7.097,2.025c0.787,0.004,1.603,0.454,1.957,1.68c-3.365,1.44-6.773,1.762-10.424,1.113
                c-5.609-0.998-11.228-0.836-16.964,0.391c0.124,2.35,2.622,3.307,2.096,5.804c-1.343,0.483-4.088-0.648-4.878,2.66
                c2.628,1.108,3.67,4.446,6.939,5.099c0.962,0.191,1.697,0.824,2.231,1.812c1.055,1.949,3.071,2.692,5.07,2.674
                c6.813-0.062,12.75,3.664,19.501,3.928c-1.949,5.792-0.763,7.933,4.736,8.762c2.271,0.342,4.59,0.369,6.999,0.779
                c-2.37,0.186-4.176,1.154-4.781,3.652c-5.031-1.433-9.694-1.594-13.837-4.777c-5.027-3.862-16.935-2.85-21.645,0.951
                c-0.007,1.201,1.476,1.285,1.765,2.412c-1.524,0.516-3.046,1.03-5.065,1.713c2.786,3.004,6.203,2.826,9.234,3.322
                c3.053,0.499,6.203,0.415,9.364,0.59c-0.105,2.355-3.13,0.79-2.885,3.611c2.542,2.708,6.48,1.939,10.103,2.148
                c3.632,0.208,7.294,0.045,11.025,0.966c-1.366,2.116-4.478,1.266-5.296,4.102c2.384,3.046,5.991,2.567,9.322,2.829
                c3.301,0.258,6.665-0.021,9.84,1.152c0.113,2.805,0.113,2.805,6.464,6.564c-2.377,1.059-4.869,1.018-7.337,1.123
                c-1.664,0.072-3.349,0.008-4.985,0.256c-3.372,0.51-3.767,1.075-3.948,4.941c-2.638,0.197-4.951-0.547-6.69-2.551
                c-2.373-2.734-5.664-3.326-8.893-4.114c-0.479-0.117-1.212-0.343-1.45-0.118c-2.691,2.537-6.44,2.521-9.525,4.07
                c-0.21,0.105-0.235,0.582-0.346,0.887c1.715,1.611,1.715,1.611,4.083,2.121c0.627,1.489-1.793,2.314-0.858,3.988
                c0.943,1.686,2.572,1.963,4.112,2.536c1.869,0.696,3.791,1.298,5.568,2.184c5.144,2.563,10.47,4.173,16.294,4.142
                c3.733-0.02,7.207,0.71,9.642,4.176c1.137,1.617,3.242,2.248,5.21,2.766c4.161,1.093,7.516,3.933,11.653,5.338
                c-7.133,0.986-14.11,2.073-20.508,5.322c-0.868,0.44-1.54,1.291-1.418,2.566c0.99,2.309,3.967,1.282,5.383,3.014
                c-0.189,1.403-2.311,1.289-2.293,3.205c0.064,0.135,0.181,0.643,0.485,0.981c4.23,4.718,9.692,6.712,15.859,7.321
                c3.129,0.31,6.296,0.651,7.811,4.295c0.605,1.455,2.459,1.609,3.97,1.636c2.607,0.046,3.619,1.785,4.761,4.007
                c-2.639,0.557-4.905,0.049-7.151-0.215c-5.764-0.673-11.43-2.006-17.361-0.964c-2.766,0.485-5.487-1.036-7.917-2.587
                c-3.596-2.296-7.716-3.035-11.798-3.82c-1.466-0.283-2.975-0.188-4.403,0.486c-2.664,1.256-3.243,2.877-1.024,4.824
                c1.464,1.283,1.006,3.05,2.108,4.383c1.941,0.243,3.919,0.439,5.879,0.753c1.597,0.255,3.057,0.431,4.294,2.018
                c1.725,2.215,4.821,1.905,7.424,2.792c-1.035,1.543-2.626,1.104-3.971,1.299c-1.777,0.257-3.736-0.035-4.628,2.179
                c-0.347,0.861-1.251,0.99-2.116,0.994c-1.166,0.005-2.333,0.026-3.499,0.026c-43.16,0.002-86.32,0.002-129.48,0.004
                c-1.434,0-2.867,0-4.3,0c2.438-3.324,5.647-4.877,9.125-5.738c2.841-0.703,5.46-1.814,7.448-3.842
                c1.976-2.016,3.937-1.878,6.326-1.209c5.128,1.436,10.28,2.91,15.642,3.102c2.688,0.096,5.546,0.712,6.529-3.465
                c0.525-2.233,3.392-2.853,5.881-2.949c2.438-0.096,5.007,0.412,7.315-1.051c-0.131-2.811-2.609-2.122-4.32-3.112
                c1.115-1.419,0.844-2.925-0.159-4.313c-1.015-1.406-2.579-1.436-4.075-1.356c-6.549,0.347-12.95-0.283-19.221-2.68
                c-0.645-1.723,1.063-3.089,0.614-4.453c5.907-3.889,10.17-10.075,18.177-10.032c-4.871-2.906-10.267-0.819-15.131-3.19
                c1.788-2.391,4.375-3.395,6.874-4.448c1.245-0.525,2.9-0.824,3.149-2.175c0.629-3.407,3.097-4.024,5.824-4.35
                c2.857-0.342,3.612-3.354,5.721-4.405c2.174-1.085,5.27,0.282,7.175-1.518c2.188-2.067,4.456-3.246,7.451-3.415
                c2.1-0.118,3.018-1.75,3.542-3.413c-0.575-1.291-1.512-1.664-2.505-1.783c-6.3-0.752-12.399-2.479-18.565-3.857
                c-4.966-1.111-9.037,1.651-13.504,2.679c-1.445,0.332-2.793,1.065-4.215,1.521c-1.925,0.617-3.593,0.763-5.57-0.616
                c-2.856-1.992-6.499-0.652-9.857-1.506c10.685-1.938,21.304-4.01,29.404-11.422c0.701-1.633-1.056-2.395-0.552-3.57
                c3.093-2.093,7.533-0.279,9.941-3.476c0.732-1.479-1.039-1.699-0.971-2.845c2.811-0.693,6.416,0.763,8.341-2.557
                c-0.871-2.373-3.072-1.145-4.377-2.104c0.752-1.163,2.24-2.312,0.11-3.566c-1.463-0.862-0.073-2.569-1.498-4.158
                c-7.987,0.251-16.023,4.156-24.608,3.303c4.45-3.154,10.287-3.117,15.462-6.924c-5.086-2.023-9.263-0.533-13.641-0.364
                c1.687-2.284,5.022-1.186,6.455-3.438c-0.178-1.746-1.643-1.529-2.653-1.699c-3.81-0.639-7.693-0.872-11.376,0.423
                c-4.158,1.462-8.056,0.843-11.982-0.464c0.022-1.025,0.646-1.359,1.29-1.477c5.022-0.904,9.803-2.899,15.162-2.234
                c5.811,0.721,11.629-0.283,17.38-1.338c4.921-0.902,9.864-1.555,14.889-1.484c1.573,0.021,3.259,0.326,4.778-0.668
                c-0.699-2.264-3.062-1.346-4.368-3.099c2.767-0.819,6.269,0.483,7.898-2.711c0.369-1.455-1.192-1.31-1.651-2.013
                C282.515,793.422,284.444,793.041,283.82,791.387z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M1138.092,923.855c-0.548-4.571-0.548-4.571-8.419-5.351
                c-0.742-7.247-6.833-4.742-11.091-6.155c2.766,0.239,4.25-2.266,6.68-2.808c1.016-0.227,1.783-1.04,1.734-2.173
                c-0.055-1.262-1.071-1.519-2.117-1.658c-0.479-0.063-0.947-0.21-1.477-0.332c0.728-0.966,1.729-1.137,2.684-1.132
                c8.632,0.048,16.6-3.358,24.951-4.762c0.977-0.164,2.154-0.291,2.834-0.893c4.929-4.358,11.46-3.762,17.154-5.712
                c0.628-0.215,1.293-0.322,1.922-0.533c1.352-0.453,2.514-1.139,2.147-2.832c-0.312-1.445-1.573-1.584-2.753-1.602
                c-2.809-0.042-5.618-0.014-8.232-0.014c-0.731-1.164,0.351-1.74,0.004-3.232c-7.85-0.596-15.845,4.185-23.719,0.473
                c0.539-0.836,1.801-0.729,2.863-1.055c0.791-0.242,2.004-0.206,2.32-0.726c2.967-4.872,8.541-4.468,12.912-6.374
                c3.502-1.527,7.133-2.593,10.203-5.066c2.624-2.115,5.994-2.82,9.117-3.966c2.126-0.78,4.445-1.257,6.533-3.805
                c-5.231-1.255-9.564-3.647-14.617-3.317c-0.397-1.975,2.124-2.221,1.945-3.779c0.039-0.705-0.379-0.999-0.809-1.236
                c-2.031-1.12-4.302-1.109-6.333-0.492c-9.086,2.761-18.147,1.053-27.722,0.177c1.074-2.05,2.61-4.085-0.164-6.272
                c6.82-1.756,13.173-2.926,18.092-6.422c0.768-2.764-2.002-2.984-2.092-4.767c0.42-0.267,0.84-0.765,1.252-0.759
                c6.091,0.103,11.646-2.503,17.53-3.492c4.036-0.678,7.923-2.422,11.777-3.942c2.185-0.861,3.134-2.922,3.353-5.206
                c0.127-1.321-0.03-2.668,0.077-3.992c0.163-2.021,0.585-4.191-1.839-5.139c-2.272-0.887-4.672-2.117-6.889,0.322
                c-0.537,0.59-1.31,1.191-2.057,1.326c-4.307,0.771-8.087,2.928-11.836,4.515c-1.715-0.443-0.262-1.989-2.369-2.958
                c-1.087-0.229-2.98,0.381-5.016,1.325c-4.275,1.983-8.924,2.539-13.599,2.69c-1.138,0.037-2.346-0.059-3.362-1.395
                c10.063-4.574,19.665-9.908,30.446-12.455c1.607-0.379,3.187-0.95,4.706-1.6c0.955-0.408,1.555-1.253,1.288-2.418
                c-0.22-0.958-1.108-1.558-1.819-1.426c-4.936,0.916-9.428-2.788-14.467-1.214c-2.041,0.638-4.246,0.153-6.254-1.362
                c10.315-1.123,18.938-6.678,27.942-9.277c1.001-2.375-0.837-2.686-1.061-3.879c0.758-1.577,3.414-1.417,3.565-4.047
                c-1.93-2.613-4.82-3.098-8.113-2.938c-5.815,0.282-11.158-0.868-15.482-6.162c4.498-1.131,7.613-3.827,10.522-6.957
                c1.501-1.615,3.979-1.438,6.097-1.872c2.342-0.481,5.076-0.053,6.786-2.396c-0.583-2.566-2.591-2.479-4.36-2.288
                c-2.972,0.32-5.915,0.917-8.864,1.433c-2.237,0.391-4.467,0.801-8.08,0.512c2.951-1.74,4.406-3.25,3.077-5.799
                c4.385-1.457,7.137-4.717,10.495-7.119c3.292-2.354,8.188-1.797,10.922-6.215c-5.409-2.041-10.498,0.77-15.683-0.151
                c3.085-4.347,9.785-4.521,12.084-10.179c-3.831-1.887-7.374-0.069-11.112-0.929c4.026-5.233,11.638-4.234,15.89-9.097
                c-3.437-3.179-7.467-1.549-11.635-3.035c4.31-1.031,6.397-4.74,10.397-5.239c1.173-0.146,2.261-0.837,2.065-2.337
                c-0.186-1.419-1.285-1.793-2.51-1.975c-1.286-0.191-2.687,0.331-3.733-0.495c-1.78-1.851,0.816-3.544,0.054-5.062
                c-1.113-1.836-3.106-1.145-5.27-2.238c4.162-0.309,7.039-1.506,10.017-3.224c3.309-1.909,4.271-3.81,3.218-7.232
                c-0.801-1.389-2.824-0.824-3.773-2.235c0.43-0.215,0.828-0.457,1.257-0.619c1.236-0.47,1.938-1.303,1.733-2.661
                c-0.18-1.2-1.203-1.556-2.178-1.737c-2.089-0.389-4.045-0.789-3.224-3.645c0.913-1.054,2.164-0.819,3.286-0.998
                c2.258-0.359,3.732-1.393,3.471-3.95c-0.219-2.154,1.497-3.553,2.127-5.555c0.627,4.075,0.229,8.603,5.572,9.532
                c-0.061,0.598,0.023,1.033-0.137,1.162c-3.908,3.139-3.889,3.488-0.77,7.381c0.889,1.109,2.057,1.615,3.096,2.404
                c1.036,0.785,1.84,1.877,2.747,2.836c-0.28,1.037-1.194,0.992-1.972,1.147c-1.246,0.249-2.297,0.728-2.125,2.231
                c0.153,1.354,1.16,2.076,2.392,2.158c3.882,0.258,6.607,2.015,7.956,5.73c0.051,0.14-0.027,0.326-0.07,0.73
                c-3.844,1.576-8.887-0.449-12.317,3.289c0.878,2.572,3.022,2.613,4.884,3.097c2.308,0.6,4.877,0.528,5.841,3.631
                c0.27,0.866,2.143,1.233,3.244,1.811c-0.343,1.472-2.296,1.125-2.542,2.286c-0.503,2.376-2.256,1.931-3.853,2.059
                c-2.405,0.191-4.987-0.555-7.079,1.027c-0.393,2.017,0.943,2.851,2.143,3.006c5.684,0.734,10.176,4.892,16.223,5.185
                c-1.24,2.589-4.804,1.196-5.602,4.083c0.271,3.072,3.671,2.667,5.32,4.279c-2.893,2.098-6.352,2.531-9.791,2.975
                c0.746,2.627,0.746,2.627,8.84,7.82c-0.631,2.076-0.446,4.238,2.129,6.232c-5.33,1.641-10.129-0.072-15.043,0.566
                c4.52,7.294,10.869,8.574,18.391,6.901c-0.783,5.446,4.801,3.745,6.477,6.521c-2.076,0.861-3.617-0.728-5.387-0.808
                c-1.223-0.056-2.459-0.144-3.012,1.125c-0.712,1.636,0.517,2.487,1.736,3.079c1.924,0.933,3.92,1.716,5.814,2.532
                c0.01,0.742-0.377,1.321-0.793,1.226c-3.751-0.869-6.834,0.488-9.682,2.734c-2.626,2.07-5.645,1.688-8.628,1.326
                c-3.251-0.396-6.254-0.178-8.509,2.386c0.404,2.318,2.24,2.128,3.521,2.622c3.504,1.354,7.046,2.371,9.027,6.197
                c0.918,1.771,3.373,1.908,5.396,2.054c1.98,0.143,3.977-0.499,5.838,0.058c1.014,0.538,0.273,1.174,0.26,1.732
                c-0.143,6.404-0.018,6.119,5.973,8.8c4.459,1.995,8.531,4.851,13.031,7.471c-2.143,1.771-4.305-0.104-5.975,1.122
                c-0.627,0.762,0.15,1.622-0.252,2.621c-6.473,1.087-12.938,1.082-18.922-1.668c-1.484,0.611-0.854,1.93-1.531,2.789
                c-2.336-0.009-4.256-1.545-6.496-2.059c-2.507-0.574-4.517-0.061-6.539,1.877c1.77,2.392,2.973,4.912,4.92,6.592
                c0.114,0.7-0.061,0.947-0.479,0.999c-1.346,0.167-3.276-1.03-3.78,1.166c-0.45,1.961,1.187,2.467,2.716,2.831
                c1.007,0.24,1.893,0.68,2.303,1.709c1.283,3.21,4.221,3.935,7.09,4.916c10.561,3.615,21.643,5.143,32.34,8.191
                c2.543,0.725,5.015,1.703,7.532,2.569c0.391,1.724-2.071,0.881-2.007,2.108c-0.167,1.683,1.466,1.754,2.222,2.973
                c-3.015,0.512-6.654-0.822-7.819,2.948c-3.389-0.448-6.144-0.899-8.796-2.028c-2.47-1.052-4.574-3.357-7.441-2.878
                c-1.24,1.993,0.753,2.599,1.039,3.644c-0.422,0.63-0.924,0.762-1.362,0.6c-3.775-1.401-7.617-2.201-11.695-2.09
                c-1.596,0.043-3.235-0.679-4.814-1.18c-1.426-0.452-2.771-1.149-4.193-1.615c-3.777-1.238-5.576-0.775-6.34,1.529
                c-1.146,3.463,0.326,7.265,3.443,8.175c2.706,0.79,5.547,1.109,8.301,1.751c1.748,0.407,3.691,0.219,5.502,1.73
                c-1.136,1.168-3.316,0.658-3.932,2.706c1.704,4.001,6.342,2.515,9.214,4.468c-0.376,2.361-4.07,0.697-4.198,3.501
                c0.815,1.515,2.521,1.426,3.871,1.676c7.691,1.423,15.416,2.663,23.12,4.018c1.903,0.335,3.626-0.182,5.368-0.767
                c4.756-1.595,5.236-1.595,11.078,0.111c-2.546,1.772-4.719,3.002-7.721,2.83c-3.807-0.217-7.514,1.184-11.098,2.07
                c-4.596,1.137-9.143,1.664-13.776,1.862c-3.972,0.17-7.024,2.192-10.974,4.078c4.037,3.077,6.877,6.138,11.556,6.649
                c4.106,0.449,8.398,1.088,12.104,2.76c3.449,1.557,7.121,2.443,10.501,4.078c-1.183,3.62-0.909,4.461,2.108,4.739
                c2.637,0.243,5.318-0.032,7.98-0.043c1.938-0.009,3.938-0.464,6.271,0.784c-1.629,1.87-3.33,3.346-5.478,3.034
                c-4.229-0.611-7.736,1.664-11.482,2.655c-2.803,0.741-5.341,1.372-8.206,0.947c-2.505-0.37-4.949-0.225-6.595,2.598
                c2.648,3.331,8.033,1.37,10.174,5.406c-1.312,0.855-2.65,0.468-3.897,0.469c-23.829,0.029-47.657,0.031-71.486,0.002
                c-1.925-0.002-3.817,0.324-5.585-1.34c-1.738-1.637-4.221-1.002-6.154-0.071c-2.836,1.366-5.742,1.474-8.756,1.445
                C1149.515,923.817,1144.019,923.855,1138.092,923.855z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M682.272,596.431c4.171,3.068,4.237,3.068,10.001-0.215
                c1.808,2.286,4.239,3.164,7.246,1.982c1.438-0.564,2.895-0.108,4.369,0.382c4.334,1.439,4.35,1.371,6.254-2.865
                c0.195-0.434,0.521-0.809,0.986-1.518c1.982,1.275,1.743,3.43,2.318,5.135c1.582,4.683,3.621,5.332,6.996,1.774
                c3.029-3.192,6.823-3.224,10.025-1.755c3.213,1.475,5.301,1.184,7.715-1.578c2.625,2.57,6.078,3.713,8.299,6.721
                c1.412,1.914,3.135,1.756,4.516-0.191c1.044-1.475,1.742-3.193,2.612-4.836c1.895-0.479,2.654,0.916,3.442,2.155
                c1.369,2.153,3.338,2.556,5.655,2.101c4.11-0.807,8.155-1.319,12.13,0.801c1.699,0.906,3.255,0.025,4.308-1.498
                c0.847-1.222,1.584-2.52,2.354-3.76c1.896,0.197,1.858,1.79,2.377,2.91c1.853,3.997,3.373,4.528,6.711,1.792
                c1.742-1.428,3.145-1.914,5.37-1.097c3.809,1.398,4.132,1.119,6.092-2.65c0.39-0.749,0.726-1.494,1.479-1.948
                c0.951,0.049,1.541,0.69,1.816,1.465c1.162,3.255,3.555,4.567,6.861,4.769c1.622,0.098,2.996,0.435,4.036,2.271
                c1.677,2.96,3.378,2.93,5.942,0.579c1.077-0.988,1.678-2.544,3.338-2.818c1.322,0.612,1.101,1.907,1.428,2.993
                c0.891,2.959,2.816,3.602,5.157,1.707c0.894-0.725,1.491-1.809,2.37-2.557c2.619-2.23,7.929-0.41,8.764,2.998
                c0.098,0.402-0.271,0.919-0.41,1.344c-1.266,0.739-1.517-1.303-2.469-0.913c-1.06,0.467-1.148,1.296-1.206,2.13
                c-0.227,3.318-0.583,6.319-5.158,6.338c-2.036,0.01-2.191,2.199-2.436,3.854c-0.386,2.619,0.288,5.332-0.667,7.996
                c-1.418-0.389-0.684-1.6-0.952-2.36c-0.345-0.976-0.651-1.95-1.733-2.242c-1.281-0.345-2.318,0.268-3,1.296
                c-0.54,0.815-0.914,1.756-1.267,2.679c-0.415,1.087-0.38,2.714-1.14,3.24c-4.205,2.911-4.531,7.879-6.68,11.86
                c-0.068,0.124-0.279,0.172-0.453,0.271c-1.834-0.088-1.635-1.803-2.178-2.877c-0.694-1.373-1.299-2.855-3.144-2.729
                c-1.997,0.139-2.802,1.715-3.194,3.373c-0.498,2.103-0.739,4.266-1.111,6.398c-0.217,1.245-0.062,2.69-1.644,3.286
                c-1.136-1.242-0.593-2.89-1.071-4.282c-0.405-1.184-0.982-2.161-2.367-2.191c-1.312-0.029-2.121,0.801-2.725,1.871
                c-0.689,1.223-0.004,3.064-1.774,3.951c-1.259-1.24-0.714-2.795-0.895-4.17c-0.173-1.318-0.21-2.656-0.432-3.963
                c-0.212-1.252-0.908-2.176-2.312-2.186c-1.344-0.008-2.119,0.846-2.569,2.006c-0.769,1.979,0.054,4.395-1.64,6.135
                c-4.21-2.615-4.766-4.239-8.553,3.967c-1.629-1.515-1.148-3.436-1.053-5.062c0.244-4.115-3.428-5.661-5.328-8.306
                c-0.875-1.219-2.564-0.096-3.142,1.232c-0.587,1.354-0.784,2.871-1.2,4.303c-0.445,1.535,0.131,3.385-1.373,4.621
                c-3.801-4.166-4.676-4.153-7.518,0.068c-6.193,0.561-6.193,0.561-9.089,3.242c-0.915-2.893-0.534-5.689-0.808-8.405
                c-0.223-2.213,0.375-5.386-2.573-5.815c-3.353-0.488-2.818,2.973-3.526,4.991c-0.377,1.077-0.525,2.235-0.885,3.837
                c-3.385-2.113-6.036-4.167-6.08-8.271c-0.008-0.653-0.266-1.303-0.398-1.955c-0.347-1.703-0.902-3.291-2.875-3.652
                c-1.686-0.309-2.844,0.623-3.771,1.917c-0.868,1.212-1.749,2.939-3.46,2.209c-2.477-1.058-5.719-0.943-7.075-3.986
                c-0.405-0.91-0.637-1.896-1.019-2.818c-0.534-1.289-1.365-2.369-2.869-2.447c-1.58-0.083-2.444,1.071-2.872,2.353
                c-0.842,2.52-1.433,5.123-2.229,7.66c-0.77,2.453-0.908,5.18-3.037,7.646c-1.285-1.611-1.334-3.278-1.654-4.795
                c-0.562-2.668,0.076-6.596-3.484-6.883c-3.466-0.279-3.596,3.749-4.723,6.209c-0.545,1.189-0.892,2.474-2.258,3.202
                c-2.121-1.53-1.794-3.95-2.535-5.871c-1.339-3.472-2.262-4.079-5.98-3.421c-5.022,0.89-5.022,0.89-7.289-3.899
                c-2.032-4.296-4.34-4.693-7.488-1.243c-0.998,1.094-1.656,2.524-3.262,3.092c-2.009,0.026-2.494-1.812-3.525-3.037
                c-2.684-3.183-4.923-2.926-6.736,0.873c-0.855,1.793-1.532,3.701-2.041,5.622c-0.841,3.173-2.463,5.294-5.975,5.636
                c-1.283,0.125-2.467,1.021-3.85,0.652c-1.531-0.83-1.69-2.38-2.096-3.803c-0.454-1.6-0.855-3.221-1.435-4.775
                c-0.48-1.289-1.368-2.478-2.864-2.432c-1.769,0.055-2.474,1.423-2.792,3.013c-0.29,1.448-0.618,2.889-0.896,4.181
                c-1.605,0.57-2.067-0.516-2.525-1.299c-1.829-3.129-4.467-3.727-7.712-2.67c-0.475,0.154-0.954,0.298-1.437,0.426
                c-4.731,1.26-4.93,1.154-6.434-3.365c-0.21-0.63-0.366-1.289-0.658-1.88c-1.566-3.169-3.743-3.444-6.107-0.772
                c-0.538,0.607-0.978,1.303-1.743,2.336c-3.47-2.103-6.939-3.961-10.141-6.201c-4.646-3.252-5.076-3.285-8.613,1.512
                c-0.595,0.807-1.151,1.641-2.176,1.884c-1.817-0.13-2.322-1.737-3.316-2.806c-2.539-2.729-3.698-2.574-5.472,0.64
                c-0.316,0.573-0.652,1.136-1.048,1.823c-2.962-1.614-2.39-6.211-6.403-6.885c-2.547,0.139-2.135,3.32-3.854,4.44
                c-3.566-0.128-3.495-4.133-5.671-5.601c-2.863,0.809-2.979,3.175-3.76,5.119c-0.728,1.811-0.494,3.996-2.017,5.498
                c-2.071,0.156-2.811-2.389-4.908-2.119c-2.128,0.272-1.806,2.648-3.656,3.693c-4.917-2.121-10.096-4.305-15.229-6.594
                c-2.139-0.954-4.228-2.162-6.631-1.936c-3.281,0.311-5.845-1.119-8.175-3.07c-3.67-3.073-7.893-4.76-12.493-5.949
                c-6.589-1.705-12.642-4.835-18.71-7.863c-0.594-0.295-1.196-0.693-1.829-0.774c-6.605-0.851-11.202-5.567-17.365-8.602
                c2.938-1.585,5.14-2.944,8.101-1.437c1.7,0.866,3.011-1.076,4.466-1.27c1.3,0.059,1.819,1.043,2.623,1.596
                c1.599,1.101,3.165,1.084,4.403-0.514c1.313-1.692,3.335-2.068,4.967-3.043c5.318,2.908,5.318,2.908,9.226-0.471
                c2.541,1.319,5.234,2.779,8.188,0.605c0.693-0.511,1.716-0.332,2.351,0.234c2.825,2.516,5.414,1.896,7.624-0.884
                c0.925,0.714,1.468,1.072,1.94,1.509c3.508,3.244,3.805,3.24,6.342-0.964c0.683-1.132,1.471-1.702,2.758-1.735
                c1.499-0.041,3.013-0.033,4.488-0.258c2.182-0.331,3.678,0.284,5.16,2.071c2.237,2.696,2.854,2.753,5.625,0.305
                c2.459-2.172,4.849-0.745,7.054-0.248c5.296,1.192,5.249,1.404,7.781-3.604c1.929,0.587,2.609,3.076,4.69,3.348
                c2.315,0.303,2.615-2.273,4.547-3.23c1.88,2.001,4.222,3.498,7.229,3.268c2.549-0.194,4.158,3.655,6.799,1.904
                c2.042-1.354,4.11-2.543,6.197-3.652c1.683,1.086,1.692,2.994,2.692,4.299c1.481,1.931,2.835,2.279,4.439,0.36
                c1.444-1.729,3.425-1.827,4.944-2.782c1.572,0.191,1.871,1.461,2.526,2.377c0.588,0.821,1.529,2.102,2.253,1.687
                c3.515-2.017,7.286,1.314,10.871-0.827c2.273,4.13,6.444,3.186,10.108,3.623c1.09,0.129,1.79-0.722,2.312-1.633
                c0.559-0.976,0.105-2.383,1.694-3.312c2.81,2.882,7.445,2.457,10.512,5.526c1.315,1.316,3.126,0.452,4.665-0.337
                c1.624-0.832,2.611-2.588,4.439-3.071c3.86,2.766,7.561,5.124,12.523,2.017c1.629-1.02,4.137,0.621,5.809,0.07
                c1.239,0.162,1.4,0.832,1.79,1.359c1.885,2.552,3.534,2.649,5.573,0.409C680.084,600.129,680.432,597.76,682.272,596.431z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M140.524,630.348c-1.045,1.406-0.331,2.209,1.035,2.481
                c1.62,0.323,3.289,0.398,5.553,0.649c-3.593,2.84-9.078,1.832-11.141,6.529c2.42,1.869,4.994,0.981,7.905,1.158
                c-2.976,3.398-8.158,2.692-10.516,6.839c3.191,1.947,6.64-0.102,10.325,1.23c-6.068,2.932-12.347,4.765-15.641,11.241
                c1.577,2.667,4.661,0.871,7.065,1.627c-1.506,4.053-6.374,2.612-8.703,5.643c1.16,3.961,4.562,4.752,8.011,5.18
                c3.457,0.429,6.984,0.263,10.475,1.137c0.378,1.805-2.872,1.65-1.94,3.945c0.639,1.573-1.249,1.607-2.244,1.86
                c-5.144,1.308-10.119,3.27-15.517,3.61c-1.827,0.115-4.126-0.217-5.395,2.223c1.534,3.208,4.619,2.623,7.267,2.613
                c2.994-0.01,5.993-0.534,9.26,1.36c-10.317,2.468-19.759,6.478-29.499,9.274c0.315,2.71,1.976,2.735,3.396,3.016
                c4.396,0.867,8.601-0.26,12.811-1.293c1.542-0.378,2.893-1.199,4.404,0.668c1.092,1.348,3.128,0.459,4.714-0.061
                c4.271-1.398,8.514-2.885,12.813-4.189c1.655-0.502,3.293-0.359,4.275,1.532c0.91,1.753,0.375,3.293-0.662,4.646
                c-0.572,0.746-1.517,1.311-2.408,1.682c-1.216,0.508-2.52,0.899-3.822,1.083c-4.78,0.673-9.578,1.218-14.364,1.854
                c-3.1,0.411-6.252,0.715-8.521,2.513c-0.673,1.699,0.56,2.206,0.971,3.287c-2.73,1.752-5.847,1.838-9.13,2.24
                c-2.144-4.348-6.916-6.771-7.853-11.925c-0.244-1.34-1.452-2.183-2.982-2.246c-1.719-0.071-2.799,0.762-3.514,2.267
                c-1.091,2.298-1.383,4.799-1.818,7.244c-0.754,4.23-2.089,7.968-5.939,10.512c-2.494,1.648-3.258,4.521-3.969,7.301
                c-0.358,1.402,0.136,2.981-0.885,4.285c-1.856-1.087-1.351-2.947-1.403-4.5c-0.124-3.662,0.003-7.332-0.055-10.998
                c-0.044-2.875-0.394-5.659-2.915-7.634c-1.68-1.315-1.769-3.534-2.792-5.229c-2.034-3.369-3.779-3.628-6.937-0.957
                c-1.098-1.708-0.561-3.56-0.492-5.307c0.174-4.348-0.492-8.329-4.196-11.194c-1.45-1.122-1.816-2.703-2.009-4.403
                c-0.168-1.479-0.003-3.276-1.198-4.235c-3.185-2.555-3.63-6.177-4.373-9.755c-0.198-0.956-0.592-1.933-1.127-2.747
                c-1.864-2.844-4.105-2.808-5.679,0.144c-0.934,1.751-1.566,3.663-2.488,5.872c-2.917-0.568-5.34,0.256-6.125,3.48
                c-2.278-0.477-2.325-2.004-2.378-3.508c-0.07-1.996,0.035-4.012-0.211-5.984c-0.219-1.756-1.254-3.214-3.135-3.412
                c-1.93-0.203-2.412,1.425-2.817,2.897c-0.309,1.12-0.443,2.307-1.728,3.212c-3.534-2.435-1.922-7.621-5.31-10.193
                c-3.122-2.371-4.649-6.498-8.417-8.117c-3.128,1.182-1.812,4.504-3.813,6.973c-0.517-3.788-3.834-5.531-3.673-9.51
                c0.096-2.359-3.091-4.141-4.988-5.945c-1.738-1.652-2.389-3.344-2.383-5.676c0.058-22.832,0.075-45.664-0.021-68.496
                c-0.013-3.115,1.524-5.523,2.401-8.31c1.442,2.163,1.432,4.463,1.623,6.702c0.184,2.156,0.111,4.338,0.357,6.486
                c0.489,4.264,5.148,7.603,9.352,6.838c2.303-0.418,2.37-2.51,2.942-4.096c3.397-0.023,3.397-0.023,7.441-5.37
                c1.923,1.326,1.228,3.224,1.03,4.9c-0.233,1.978-0.795,3.917-1.032,5.896c-0.187,1.551,0.116,3.092,1.871,3.66
                c1.571,0.51,3.051,0.193,3.868-1.376c0.828-1.589,1.716-2.729,3.801-2.496c1.438,0.161,2.413-0.934,3.282-1.99
                c0.712-0.866,1.211-2.009,2.688-2.204c1.865,1.139,1.72,3.046,1.745,4.814c0.062,4.499-0.052,9.002,0.09,13.498
                c0.1,3.158,0.376,6.35,2.04,9.172c0.782,1.326,1.853,2.471,3.588,2.175c1.475-0.252,2.235-1.331,2.47-2.787
                c0.586-3.636,2.255-6.551,5.448-8.562c1.665-1.049,2.183-2.787,2.54-4.669c0.388-2.047,0.338-4.262,1.431-6.12
                c2.324,0.054,2.575,1.693,3.087,3.012c2.046,5.271,2.032,5.293,7.835,4.934c1.769-0.109,2.946,0.633,3.263,2.337
                c0.241,1.296,0.194,2.652,0.207,3.982c0.016,1.681-0.023,3.438,0.921,4.853c3.755,5.622,4.037,11.849,3.438,18.27
                c-0.172,1.844-0.058,3.686,0.68,5.404c0.487,1.138,1.381,1.924,2.707,1.834c1.517-0.102,1.942-1.333,1.875-2.498
                c-0.251-4.357,1.807-7.204,5.423-9.346c2.352-1.393,2.242-4.331,3.331-6.369c1.364-1.217,2.692,0.366,3.916-0.189
                c2.084-0.947,3.317,0.029,4.681,1.554c3.497,3.91,4.872,3.7,7.688-0.829c0.352-0.565,0.653-1.178,1.457-1.229
                c1.513-0.047,1.886,1.104,2.182,2.238c0.292,1.127,0.355,2.316,0.695,3.426c1.177,3.837,4.159,4.421,6.941,1.402
                c2.069-2.244,3.031-2.111,3.823,0.771c0.366,1.333,0.514,2.729,1.949,3.223c2.48,0.854,3.069,2.789,3.335,5.06
                c0.115,0.981,0.358,1.979,0.731,2.893c1.081,2.647,2.354,3.188,5.054,2.074c0.912-0.376,1.67-1.124,2.765-0.701L140.524,630.348z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M926.056,847.894c2.421-0.024,3.225,1.506,4.14,2.897
                c1.022,1.557,2.084,3.221,4.279,2.805c2.375-0.449,3.818-2.177,4.249-4.423c0.562-2.936,0.744-5.943,1.104-8.918
                c0.2-1.649,0.211-3.362,0.695-4.927c1.023-3.313,3.098-3.681,5.358-1.062c0.978,1.132,1.755,2.442,2.778,3.526
                c1.456,1.545,1.926,1.646,6.321,2.461c2.187,4.51,2.187,4.51,6.994,3.905c0.312,0.288,0.733,0.572,1.015,0.959
                c1.111,1.528,2.229,2.972,4.471,2.372c2.314-0.619,3.779-2.102,4.284-4.406c0.319-1.457,0.319-2.982,0.587-4.454
                c0.328-1.808,0.701-3.656,2.514-4.663c2.562-1.422,3.557-3.82,4.246-6.477c0.413-1.592,0.912-3.206,3.047-4.096
                c1.481,2.554,1.584,5.316,1.799,8.055c0.617,7.803,0.693,15.648,1.9,23.404c0.232,1.494,0.562,2.938,1.205,4.311
                c0.523,1.117,1.285,2.033,2.567,2.102c1.507,0.079,2.547-0.852,3.056-2.164c0.597-1.542,1.146-3.144,1.396-4.769
                c0.734-4.771,1.268-9.571,1.969-14.347c0.367-2.498,0.873-4.832,3.959-5.678c2.229-0.609,3.26-2.746,4.055-4.803
                c0.959-2.483,1.797-5.014,2.748-7.5c0.767-2.004,1.41-4.131,3.835-5.144c2.42,1.448,2.4,4.188,3.183,6.435
                c1.756,5.041,1.988,5.309,7.285,5.702c1.702,1.668,2.027,4.122,3.266,6.081c1.398,2.211,3.545,2.867,5.783,2.584
                c1.436,2.296,1.076,4.576,1.637,6.647c0.424,1.567,1.107,2.919,2.738,3.229c1.871,0.355,3.195-0.767,4.02-2.339
                c1.746-3.325,1.914-7.04,2.385-10.657c0.302-2.322,0.635-4.629,1.403-6.836c0.394-1.127,1.122-2.086,2.535-2.013
                c1.107,0.058,1.833,0.708,2.28,1.679c0.347,0.756,0.688,1.516,0.976,2.295c0.949,2.576,2.534,4.254,5.517,4.178
                c1.045-0.027,1.947,0.404,2.499,1.512c-1.288,4.189-2.553,8.467-3.92,12.711c-1.394,4.326-5.795,6.711-7.458,10.661
                c0.979,1.788,2.357,1.765,3.671,1.838c1.497,0.083,3.006,0.019,4.494,0.171c4.03,0.412,4.693,1.504,2.985,5.159
                c-1.986,4.249-4.937,7.901-7.527,11.777c-1.643,2.455-3.574,4.781-4.1,7.236c0.453,2.382,2.421,1.491,3.305,2.603
                c-1.707,2.643-3.537,5.229-5.109,7.964c-2.635,4.581-2.236,5.602,2.521,7.733c1.05,0.471,2.217,0.828,2.766,2.348
                c-0.236,2.194-1.695,4.035-3.09,5.865c-2.524,3.312-5.246,6.48-7.678,9.858c-1.423,1.978-3.077,2.646-5.471,2.618
                c-9.999-0.111-20-0.047-30-0.047c-33.666-0.001-67.333,0-101,0c-1.807,0-3.613,0-5.34,0c-0.634-1.814,0.723-2.332,1.438-3.104
                c4.304-4.644,8.712-9.193,12.97-13.877c1.536-1.689,3.582-3.646,2.166-6.133c-1.354-2.375-4.053-1.814-6.374-1.658
                c-1.569,0.105-3.11,0.868-4.885,0.414c0.519-2.013,2.154-2.76,3.267-3.908c2.213-2.285,4.311-4.646,5.848-7.445
                c2.357-4.29,2.101-5.57-1.829-8.975c1.847-3.369,4.484-6.182,7.371-8.634c4.429-3.763,6.718-8.519,7.991-14.042
                C923.805,853.638,924.011,850.552,926.056,847.894z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M4.833,643.619c1.59,2.581,5.689,3.584,4.562,7.874
                c2.438,2.499,1.948,7.128,5.903,8.53c2.944-1.467,1.953-4.543,3.562-7.223c3.373,4.424,8.52,7.089,9.305,13.139
                c0.063,0.488,0.242,0.971,0.425,1.433c0.766,1.937,1.814,3.786,4.161,3.712c2.163-0.066,2.183-2.164,2.727-3.688
                c0.27-0.753,0.306-1.663,1.403-2.188c1.913,1.045,1.461,2.982,1.485,4.672c0.026,1.829-0.222,3.673,0.507,5.429
                c1.343,3.231,3.684,3.546,5.856,0.783c0.398-0.506,0.827-1.558,1.097-1.511c5.193,0.896,5.173-3.446,6.556-6.387
                c0.34-0.721,0.64-1.533,1.629-1.795c2.405,1.148,2.249,3.576,2.652,5.566c0.54,2.665,1.052,5.23,3.339,7.026
                c0.817,0.642,1.506,1.558,1.488,2.52c-0.074,4.021,2.022,6.774,4.71,9.46c1.541,1.54,1.725,3.902,1.682,6.123
                c-0.039,1.998-0.096,4-0.014,5.994c0.074,1.819,0.949,3.222,2.784,3.714c1.955,0.524,2.251-1.342,2.798-2.478
                c1.808,0.52,2.28,1.76,2.766,3.02c0.359,0.931,0.553,2.141,1.245,2.691c3.989,3.178,3.581,7.551,3.552,11.881
                c-0.021,3.164-0.091,6.346,0.208,9.486c0.219,2.293,0.871,4.652,3.865,4.686c1.989-1.643,1.662-3.773,1.902-5.7
                c0.361-2.899,1.089-5.729,3.611-7.404c3.98-2.644,5.391-6.601,6.302-10.92c0.412-1.955,0.758-3.925,1.191-5.875
                c0.259-1.167,0.813-2.153,2.143-2.658c2.861,1.337,2.008,4.796,3.869,6.644c1.739,1.727,4.002,3.063,4.59,5.748
                c-0.618,1.424-2.355,1.115-3.581,2.623c-0.018,1.313,1.543,3.742-2.893,4.229c5.368,2.731,9.641,1.74,13.89,2.373
                c0.71,3.297,2.539,5.563,5.778,6.842c1.427,0.563,2.653,1.955,2.962,3.841c-1.162,1.451-2.958,1.385-4.505,1.748
                c-1.955,0.46-3.973,0.643-5.345,2.362c-0.951,1.191-2.387,1.009-3.653,1.324c-1.402,0.35-3.055,0.092-4.024,1.617
                c-0.157,1.918,1.265,2.162,2.589,2.209c4.27,0.147,8.526,0.92,12.816-0.271c2.064-0.572,4.376-1.543,6.167,1.217
                c0.901,1.389,3.197,0.434,4.79,1.257c-13.333,5.122-27.37,6.542-40.619,8.215c-1.684,1.87,0.148,2.685-0.133,3.833
                c-1.523,0.882-3.75,0.333-4.876,2.493c2.429,3.115,6.042,1.55,9.468,2.195c0.594,3.744,3.971,3.493,7.878,4.212
                c-4.157,3.459-7.044,7.07-11.544,9.533c3.391,2.148,6.55,1.413,9.533,0.766c3.895-0.846,7.716-2.062,11.529-3.246
                c4.451-1.381,8.839-2.968,13.298-4.321c1.708-0.519,3.4-1.471,5.694-0.826c-5.295,7.408-14.15,9.138-21.315,13.36
                c1.057,2.463,2.652,2.24,4.072,2.184c3.662-0.148,7.319-0.508,10.98-0.541c1.583-0.014,3.875-1.105,4.388,1.788
                c0.025,0.143,0.261,0.386,0.301,0.368c3.37-1.537,5.425,2.566,9.282,1.624c-2.759,2.315-5.44,2.483-7.996,2.896
                c-2.299,0.371-4.632,0.535-6.955,0.747c-1.825,0.166-3.664,0.194-5.481,0.411c-1.973,0.234-4.367,0.172-5.545,1.785
                c-1.07,1.465-2.104,2.789-3.663,3.303c0.171,2.213,1.424,2.593,2.837,2.596c1.327,0.002,2.67-0.365,3.976-0.245
                c5.116,0.466,9.68,2.552,13.751,5.519c1.393,1.016,3.21,0.838,4.723,2.513c-5.873,1.673-11.497,3.276-16.782,4.782
                c-1.304,1.623,0.387,2.334,0.433,3.689c-3.286,0-6.546,0-9.896,0c2.078-2.646,2.179-2.705,0.604-5.428
                c-1.917-3.312-4.025-6.514-6.276-10.127c2.513-1.846,1.782-4.029,0.442-6.325c-2.013-3.449-4.007-6.903-5.693-10.544
                c-1.36-2.937-3.207-3.125-5.364-0.678c-2.698,3.062-4.06,6.802-5.112,10.718c-0.595,2.217,1.151,2.805,2.227,3.954
                c-1.134,4.043-2.001,8.123-5.914,10.617c-2.432-0.926-3.513-2.707-3.827-4.951c-0.341-2.445-1.404-4.379-3.296-5.954
                c-1.61-1.341-2.176-3.312-2.752-5.228c-0.238-0.792-0.566-1.95,0.095-2.368c3.401-2.149,1.457-4.724,0.505-6.976
                c-1.179-2.784-1.522-5.518-1.764-8.538c-0.423-5.296-2.796-10.138-6.514-14.607c-3.363,3.009-5.738,6.356-8.08,9.702
                c-1.228,1.754-0.552,3.174,1.609,3.9c-1.854,3.949-5.447,6.543-7.071,10.85c2.368,1.679,3.722,3.625,2.165,6.881
                c-2.656,5.557-4.934,6.236-9.681,2.185c-1.636-1.396-3.383-2.722-4.229-5.126c1.108-1.416,3.883-1.142,4.167-3.586
                c0.221-1.912-0.383-3.602-1.146-5.287c-0.339-0.749-1.043-1.887-0.77-2.273c2.199-3.113-0.482-4.873-1.805-6.938
                c-1.966-3.068-4.523-5.772-5.833-9.67c2.919-1.406,2.565-3.593,1.221-6.036c-1.359-2.472-2.583-5.019-3.82-7.442
                c0.63-1.09,2.065-0.642,2.654-2.233c-0.482-2.598-2.273-5.058-3.967-7.43c-1.767-2.474-1.637-5.737-3.453-7.91
                c-1.728-0.468-2.48,0.46-3.097,1.469c-1.86,3.049-2.583,6.473-3.093,9.945c-0.094,0.639-0.171,1.589,0.188,1.937
                c4.07,3.938,1.424,8.318,0.872,12.521c-0.3,2.28-0.758,4.583-2.449,6.84c-1.607-1.529-3.859-2.467-1.438-5.01
                c0.805-0.846,0.562-1.855,0.245-2.811c-1.625-4.887-4.172-9.316-6.929-14.85c-2.918,4.397-5,8.225-6.879,12.175
                c-0.133,0.28-0.225,0.751-0.082,0.968c3.315,5.049-1.616,8.082-2.599,12.666c-0.585-1.274-0.189-0.501-0.791-0.788
                c-0.127-0.061-0.301-0.175-0.318-0.286c-0.103-0.652-0.233-1.311-0.233-1.967c-0.011-39.646-0.009-79.292-0.009-118.938
                C4.1,643.839,4.466,643.729,4.833,643.619z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M760.262,854.219c-0.902-0.36-1.654-0.662-2.513-1.005
                c0.167-0.436,0.222-0.972,0.517-1.281c1.723-1.81,3.527-3.538,5.25-5.347c1.147-1.205,2.285-2.436,3.285-3.761
                c3.357-4.453,3.111-6.177-1.369-10.441c1.275-3.655,3.812-6.597,5.736-9.89c0.504-0.863,1.082-1.688,1.521-2.582
                c1.804-3.66,1.326-4.402-2.895-4.66c-0.298-0.019-0.584-0.239-1.215-0.514c2.367-5.238,7.961-8.617,8.458-14.854
                c1.731,0.184,1.904,1.467,2.214,2.446c1.68,5.303,4.504,10.309,3.594,16.266c-0.285,1.871,0.689,3.907,0.901,5.887
                c0.289,2.709,1.421,5.516-1.236,7.898c-1.017,0.911-0.598,2.559-0.394,3.85c0.705,4.459,1.979,8.777,3.904,12.857
                c1.731,3.665,4.821,3.82,7.217,0.493c1.686-2.343,1.961-4.599-0.005-6.789c-1.433-1.595-1.229-2.794-0.589-4.447
                c1.69-4.363,4.66-7.959,7.066-12.403c2.432,5.13,4.694,9.904,6.92,14.601c-0.273,1.503-2.256,0.763-2.475,2.154
                c-0.164,1.041,0.188,1.955,0.568,2.892c1.641,4.049,3.822,7.803,6.722,11.041c1.671,1.866,2.114,3.633,1.173,5.895
                c-1.621,3.898-0.394,7.082,2.445,9.953c3.157,3.195,5.948,6.711,8.733,10.562c-1.2,1.999-2.979,3.578-3.718,5.818
                c-1.074,3.26,2.078,3.655,3.629,5.34c-1.53,7.257-7.776,11.918-10.075,17.896c-0.269,2.241,1.755,2.008,1.934,3.283
                c-1.667,2.668-3.34,5.346-5.157,8.255c-39.278,0-78.711,0-118.347,0c-0.642-1.917,1.099-3.378,1.005-5.165
                c-0.125-2.383-2.159-2.514-3.758-3.09c0.453-1.906,1.959-2.487,3.076-3.393c2.33-1.889,4.724-3.709,6.676-6.006
                c2.527-2.977,2.446-3.512-0.879-6.457c2.68-1.919,5.427-3.684,7.938-5.738c2.667-2.185,2.424-3.959-0.424-5.895
                c-2.138-1.451-4.734-1.409-7.233-2.242c0.768-2.742,2.578-4.713,4.409-6.572c1.616-1.641,2.589-3.496,2.736-5.714
                c0.321-4.82,2.79-8.439,6.17-11.603c0.244-0.229,0.48-0.462,0.713-0.701c4.55-4.667,4.414-5.658-1.425-8.629
                c0.403-3.473,2.481-6.349,3.886-9.482c0.959-2.137,3.044-4.25,0.188-6.58c-0.342-0.278-0.283-1.298-0.125-1.899
                c1.306-4.97,3.146-9.675,7.075-13.907c3.501,5.713,6.765,11.039,9.968,16.265c-0.947,1.577-2.345-0.341-3.189,0.944
                c-0.829,1.263-0.161,2.328,0.328,3.271c1.531,2.952,3.227,5.821,4.895,8.7c1.75,3.021,3.544,6.015,5.549,9.411
                c-1.393,1.045-3.302,1.649-3.502,3.671c-0.168,1.702,0.17,3.305,0.625,4.917c1.377,4.884,3.598,9.311,7.084,13.033
                c2.365,2.526,5.088,3.55,8.519,2.045c2.438-1.069,5.001-1.867,7.15-3.501c3.561-2.706,3.616-5.312,0.196-8.084
                c-0.386-0.313-0.847-0.533-1.24-0.838c-3.026-2.344-3.524-4.173-1.947-7.762c0.6-1.362,1.463-2.608,2.186-3.919
                C759.414,857.435,760.604,856.345,760.262,854.219z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M157.903,141.073c5.286,4.011,9.39,7.827,13.439,11.696
                c1.469,1.403,1.033,3.45,1.333,5.203c3.155,18.458,4.506,36.99,2.312,55.684c-1.617,13.779-0.937,27.615-0.641,41.429
                c0.42,19.651,0.571,39.302,0.506,58.956c-0.011,3.328-0.189,6.656-0.344,9.981c-0.458,9.833,2.406,18.966,5.407,27.913
                c0.743,0.129,1.073,0.267,1.38,0.229c6.994-0.883,8.785-0.04,11.211,6.615c1.948,5.344,4.374,10.429,7.025,15.423
                c2.5,4.708,2.736,9.913,3.397,14.998c1.203,9.241,1.829,18.567,3.303,27.761c1.452,9.055,2.454,18.137,3.26,27.255
                c0.087,0.979,0.443,1.968-0.073,2.931c-1.882-0.08-11.259-3.348-13.18-4.619c-2.219-1.469-4.382-3.028-6.646-4.423
                c-0.902-0.556-1.611-1.122-1.878-2.168c-0.468-1.832-1.295-3.357-3.086-4.31c-1.301-0.692-1.582-2.065-1.598-3.453
                c-0.038-3.148-1.657-5.368-4.242-6.897c-2.223-1.315-3.949-2.923-4.872-5.434c-0.729-1.983-2.377-3.236-4.401-3.76
                c-2.601-0.673-5.452-1.765-7.15-3.251c-2.604-2.278-6.479-4.038-6.713-8.515c-0.115-2.207-1.22-4.202-3.488-5.097
                c-1.702-0.671-2.028-1.94-2.048-3.571c-0.217-17.611-2.056-35.21,0.664-52.83c1.11-7.192,0.055-14.591,0.071-21.896
                c0.065-28.479-0.282-56.959-0.393-85.44c-0.02-5.106-0.558-9.95-3.81-14.161c-1.15-1.489-1.404-3.319-1.494-5.192
                c-0.577-11.978-0.665-12.045,7.486-20.845c2.993-3.231,4.479-6.64,4.485-11.163C157.143,167.478,157.597,154.834,157.903,141.073z
                "/>
            <path fill-rule="evenodd" onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M273.706,382.204c-0.78,6.84,3.744,11.55,4.297,18.241
                c-5.767-6.227-10.8-11.804-14.807-18.08c-2.401-3.761-2.159-8.651-2.981-13.056c-0.426-2.284-0.489-4.637-0.687-6.961
                c-0.278-3.271,0.284-6.232,2.614-8.805c3.488-3.851,4.469-8.314,3.281-13.465c-1.007-4.37-1.412-8.876-2.17-13.307
                c-1.5-8.769,0.509-17.241,2.115-25.743c0.742-3.924,1.644-7.818,2.467-11.727c0.292-1.385,0.384-2.813-1.34-3.246
                c-1.866-0.468-3.196,0.121-3.642,2.271c-1.183,5.705-2.447,11.393-3.73,17.076c-0.359,1.593-0.894,3.146-1.543,5.395
                c-1.396-2.967-1.31-5.442-1.526-7.844c-2.11-23.389-4.155-46.785-6.356-70.166c-0.266-2.821,0.36-6.317-3.457-7.404
                c0.824-7.956,3.407-15.346,2.679-23.109c-0.064-0.691-0.458-1.232-1.311-1.673c-2.411,0.254-2.778,2.504-3.215,4.264
                c-0.92,3.707-1.683,7.481-2.12,11.273c-1.47,12.74-2.778,25.498-4.126,38.251c-0.387,3.661-0.243,7.279,0.418,10.937
                c0.59,3.263,0.85,6.625,0.861,9.945c0.031,9.428,1.634,18.559,4.857,27.391c2.207,6.046,1.598,12.375,2.558,18.863
                c-1.427-1.009-1.576-2.188-1.886-3.306c-1.384-4.988-3.151-9.812-5.735-14.336c-2.264-3.964-3.146-8.3-3.596-12.865
                c-1.125-11.433-2.6-22.831-3.951-34.241c-0.446-3.771,0.525-7.585-0.888-11.363c-2.261-6.039-5.039-11.64-10.581-15.323
                c-1.244-0.826-1.783-1.875-2.205-3.153c-1.303-3.953-2.664-7.888-3.917-11.856c-0.345-1.093-0.586-2.285-0.552-3.422
                c0.293-9.656,0.007-19.345,1.242-28.952c0.614-4.773,1.561-9.504,2.366-14.307c3.696,2.184,6.505,8.111,6.025,13.157
                c-0.805,8.452-2.335,16.823-2.766,25.323c-0.263,5.178-0.291,10.327,0.521,15.456c0.225,1.425,0.087,3.057,2.076,3.86
                c1.851-1.31,1.542-3.476,1.704-5.314c0.511-5.807,0.71-11.643,1.262-17.445c0.485-5.097,0.879-10.228,2.095-15.597
                c2.436,1.845,2.766,4.115,3.074,6.403c0.613,4.549-0.189,8.985-1.227,13.364c-1.455,6.14-1.534,12.09,0.741,18.141
                c1.515,4.029,2.259,8.348,3.333,12.541c0.286,1.116,0.324,2.34,1.536,3.045c2.017-0.749,1.193-2.449,1.128-3.771
                c-0.312-6.323-0.63-12.648-1.136-18.957c-0.313-3.899,0.588-7.312,3.339-10.079c3.184-3.203,4.596-7.143,5.71-11.421
                c2.028-7.781,3.431-15.596,3.363-23.664c-0.019-2.208-0.169-4.122-1.612-6.048c-2.426-3.237-2.856-7.072-1.801-10.965
                c1.481-5.461,3.098-10.885,4.644-16.329c0.5-1.761,1.006-3.523,1.423-5.305c0.547-2.336,0.382-4.28-1.378-6.421
                c-3.292-4.005-4.678-9.027-6.411-14.266c5.046,0.098,9.873-1.809,13.538,2.884c-1.319,5.606-0.424,11.194,0.398,16.803
                c0.943,6.429,1.546,12.879,1.393,19.408c-0.172,7.329,0.07,14.667-0.094,21.996c-0.064,2.879,0.525,5.292,2.84,7.043
                c4.942,3.739,6.174,8.741,6.475,14.775c0.548,11.01,1.371,21.952-1.334,33.048c2.596,1.467,3.173,3.994,3.612,6.642
                c0.545,3.284,1.113,6.573,1.877,9.811c0.751,3.183,0.792,6.261-0.456,9.31c-1.229,3-1.4,6.126-1.268,9.325
                c0.761,18.312,1.501,36.625,2.185,54.94c0.077,2.063,0.563,3.911,1.548,5.698c8.072,14.661,13.105,30.584,19.009,46.137
                c1.488,3.92,3.405,7.574,5.271,11.279c4.945,9.818,9.888,19.64,15.886,28.88c1.733,2.669,2.92,5.603,4.139,8.525
                c1.994,4.78,4.063,9.512,8.407,12.764c1.434,1.074,1.715,2.747,2.084,4.399c1.966,8.782,3.084,17.707,4.455,26.585
                c3.027,19.603,4.341,39.392,6.111,59.13c0.173,1.924,0.52,3.89-0.046,6.217c-2.356-1.396-4.442-2.666-6.562-3.877
                c-1.428-0.814-1.254-2.211-1.414-3.507c-1-8.094-1.675-16.229-3.675-24.181c-0.98-3.896-2.245-7.639-4.03-11.248
                c-4.589-9.274-7.997-19.021-11.227-28.831c-0.252-0.766-0.686-1.533-0.351-2.395c1.055-1.226,2.986-0.168,4.046-1.563
                c-0.007-1.561-1.387-2.173-2.288-3.043c-5.705-5.509-10.312-11.708-13.131-19.224c-1.928-5.14-4.062-10.226-6.482-15.151
                c-3.053-6.211-5.076-12.647-5.634-19.541c-0.201-2.489-0.888-4.374-3.788-5.093c-2.139-0.53-3.236-2.759-4.677-4.359
                C278.069,385.996,276.917,383.894,273.706,382.204z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M644.211,856.278c-3.678,1.258-2.808,3.81-1.892,6.411
                c0.987,2.804,2.554,5.407,2.998,8.445c-1.072,1.312-2.585-1.072-4.007,1.235c1.324,7.048,8.211,11.792,10.867,18.391
                c-1.584,2.238-3.613,0.347-4.955,1.562c-1.146,1.004-1.082,2.259-0.715,3.631c0.953,3.559,2.424,6.903,4.054,10.188
                c2.792,5.625,5.581,11.251,8.628,17.394c-43.667,0-86.658,0-130.518,0c2.252-3.841,4.206-7.242,6.232-10.601
                c1.204-1.995,2.625-3.863,3.765-5.892c1.908-3.394,1.743-3.904-1.482-6.414c1.553-2.134,3.158-4.243,4.652-6.429
                c1.031-1.51,2.011-3.072,2.834-4.702c2.172-4.296,1.721-5.195-3.176-6.384c0.112-2.484,1.772-4.17,3.186-5.938
                c2.602-3.255,5.241-6.486,7.198-10.184c2.253-4.258,1.872-5.447-2.445-7.95c1.417-3.968,5.006-6.55,6.775-10.298
                c1.693-3.588,2.076-7.705,4.654-11.488c2.093,4.431,4.021,8.542,5.977,12.64c0.792,1.657-0.73,2.321-1.43,3.321
                c0.641,2.432,2.841,3.735,3.928,5.832c0.169,1.209-1.484,1.123-1.6,2.212c-0.135,1.26,0.38,2.273,0.976,3.274
                c2.041,3.428,4.103,6.843,6.338,10.565c-1.608,1.822-3.564,3.572-2.934,6.362c0.483,2.137,0.945,4.268,3.604,5.268
                c4.604-1.725,7.106-5.605,8.906-9.97c1.141-2.769,0.39-3.693-3.531-5.378c1.518-3.597,4.6-6.014,6.795-9.065
                c1.549-2.153,1.863-3.888-1.306-4.854c2.818-4.84,7.382-8.088,9.232-12.606c-0.25-2.44-2.188-1.858-3.302-3.044
                c2.836-4.838,8.666-7.217,10.827-13.627c2.639,4.045,4.764,7.305,6.863,10.523c-1.68,2.925-1.68,2.925,1.605,10.172
                c0.087,1.273-1.963,1.377-1.578,2.861c4.264,6.561,8.099,7.869,12.849,4.352c5.656-4.187,7.287-10.051,3.876-14.135
                c0.743-2.386,2.361-4.34,3.498-6.546c0.682-1.324,1.974-2.86,1.729-4.038c-0.73-3.512,1.859-4.941,3.941-8.119
                c1.134,6.354,1.246,11.848,2.913,17.021C640.723,845.497,642.412,850.713,644.211,856.278z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M416.045,862.444c1.796-2.933,4.173-4.801,6.176-6.984
                c4.436-4.836,4.465-4.884,0.614-9.845c0.405-2.469,2.545-3.749,3.978-5.523c1.354-1.677,3.086-3.222,1.708-5.717
                c-0.453-0.818-0.153-1.572,0.194-2.363c1.246-2.84,6.672-8.426,10.437-10.407c1.502,8.44,5.796,15.526,10.635,22.743
                c-1.058,0.666-1.889,1.189-2.755,1.735c1.225,4.865,5.404,7.466,7.296,11.617c0.21,1.574-2.286,1.124-2.218,2.751
                c0.068,1.642,1.097,2.742,1.935,3.957c1.849,2.681,4.363,4.922,5.713,8.038c-1.911,0.972-3.576,0.269-5.188,0.44
                c-4.288,0.458-5.653,2.833-3.422,6.445c1.124,1.82,2.739,3.332,4.097,5.014c1.254,1.553,2.513,3.108,3.667,4.736
                c0.555,0.783,1.298,1.566,1.113,2.631c-0.216,0.557-0.5,0.867-1.114,0.773c-1.598-0.242-3.459-1.092-4.479,0.852
                c-0.841,1.604,0.602,2.771,1.393,3.986c1.744,2.682,4.031,4.85,6.477,6.891c1.512,1.262,3.241,2.354,4.167,4.211
                c-3.732,3.068-3.923,3.747-1.46,7.789c1.441,2.366,3.153,4.566,4.813,6.94c-4.125,0.828-110.333,1.083-117.329,0.288
                c3.365-2.252,8.192-0.196,10.281-4.138c-0.3-1.501-1.421-1.421-2.362-1.62c-3.007-0.64-5.917-0.833-8.496-3.365
                c-2.181-2.142-5.986-1.566-9.292-2.039c-0.789-1.79-1.635-3.582-3.669-4.454c1.227-2.282,2.985-2.042,4.514-1.83
                c3.8,0.526,7.563,1.31,10.902,3.349c3.795,2.317,7.708,3.586,12.29,2.693c2.585-0.504,5.3,0.041,7.953,0.408
                c6.394,0.887,12.804,1.652,19.37,2.486c0.735-2.031,0.077-3.227-1.502-3.779c-1.237-0.432-2.398-0.617-3.417-1.727
                c-3.09-3.363-3.174-3.285-7.839-3.98c-0.658-3.064-3.247-4.158-6.038-4.875c-1.928-0.496-3.903-0.893-5.881-1.085
                c-4.678-0.456-8.717-1.998-11.402-6.206c0.394-1.201,2.389-1.209,2.422-2.998c-0.78-2.729-4.276-1.958-5.933-4.198
                c4.055-1.529,7.945-2.836,12.005-3.728c3.797-0.834,7.997,0.457,11.676-2.049c-0.471-2.367-2.286-2.977-3.985-3.41
                c-4.711-1.203-8.608-4.35-13.374-5.439c-1.043-0.238-2.196-1.082-2.795-1.981c-2.228-3.344-5.666-4.318-9.162-4.146
                c-9.316,0.461-16.815-4.594-25.654-7.578c2.482-1.637,3.3-3.383,1.563-5.123c1.125-1.273,2.105-2.383,3.203-3.627
                c3.572,0.521,6.927,1.208,9.793,4.053c3.244,3.221,7.569,3.393,11.817,3.128c0.486-2.433-1.813-2.769-1.97-4.632
                c5.327-2.588,12.212,0.732,17.303-3.725c-1.055-3.337-4.294-2.523-6.163-3.953c-1.391-0.897,0.646-2.596-0.763-3.475
                c-1.117-0.697-2.307-1.275-3.715-2.045c3.224-3.572,8.514-5.189,9.974-10.031c-0.681-2.312-2.857-0.955-3.995-2.373
                c3.168-5.104,6.372-10.263,9.992-16.094c2.542,4.738,2.534,9.482,4.39,13.615c1.861,4.144,5.093,7.389,7.183,11.605
                c-1.683,1.197-3.267-1.213-4.711,0.16c-1.188,4.85,1.154,10.36,10.721,24.982c-2.782,0.482-5.547-1.431-8.148,0.105
                c-0.598,2.375,1.184,3.371,2.521,4.49c3.373,2.822,7.313,4.689,11.5,5.848c3.596,0.996,6.199,2.969,8.205,6.054
                c3.119,4.795,4.593,5.456,7.75,3.902c4.39-2.16,6.069-5.929,4.663-10.585c2.175-2.727,5.996-3.42,8.422-6.429
                C420.65,861.723,418.312,863.628,416.045,862.444z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M1055.041,14.768c2.131,0.263,3.48-0.794,4.945-1.543
                c8.217-4.199,14.08-1.141,15.244,7.928c0.041,0.316,0.216,0.616,0.324,0.913c7.795,4.033,8.229,4.693,7.498,11.697
                c3.771,3.021,5.392,6.92,3.488,11.555c0.601,1.892,2.334,2.156,3.333,3.235c2.147,2.321,3.548,4.841,2.557,8.117
                c-0.392,1.295-0.476,2.511-0.169,3.896c1.348,6.083-0.947,10.18-6.818,11.719c-2.702,0.708-5.018,1.468-6.059,4.32
                c-0.162,0.446-0.546,0.854-0.918,1.173c-5.525,4.749-10.464,10.059-15.309,15.491c-1.622,1.819-3.492,3.681-5.517,4.965
                c-3.817,2.421-5.437,6.1-6.946,9.939c-0.869,2.212-2.229,3.991-3.672,5.853c-7.564,9.756-17.237,17.523-25.135,26.95
                c-0.628,0.749-1.527,1.33-1.656,2.456c0.6,1.596,2.135,1.266,3.348,1.562c4.32,1.057,7.393,3.638,9.422,7.554
                c0.953,1.839,1.877,4.167,0.947,5.744c-3.271,5.552-1.457,12.924-6.572,17.729c-0.881,0.828-1.068,2.042-1.309,3.188
                c-0.316,1.509-0.934,3.065-2.115,3.883c-3.305,2.288-5.215,5.218-5.768,9.229c-0.252,1.821-1.422,3.807-3.057,4.377
                c-5.158,1.799-7.15,8.028-12.836,8.878c-1.304,0.195-2.657,0.206-3.976,0.116c-1.247-0.084-2.278,0.164-3.196,1.026
                c-2.58,2.424-5.662,3.371-9.173,3.124c-0.634-0.044-1.453,0.009-1.907,0.37c-5.014,3.978-10.312,4.06-15.457,1.154
                c-1.984,0.766-1.654,2.393-2.164,3.538c-2.339,5.252-6.697,7.318-11.764,4.643c-4.151-2.192-8.659-4.176-10.748-9.177
                c-1.213-2.904-4.968-3.691-6.414-7.034c-1.998,1.735-2.153,3.692-3.095,5.164c-2.423,3.789-5.146,7.074-10.354,6.049
                c-0.949-0.187-1.59,0.244-2.252,0.744c-3.187,2.411-6.56,1.848-9.98,0.644c-4.967-1.748-8.629-4.946-10.734-9.801
                c-1.577-3.637-1.328-7.206,1.009-10.433c3.032-4.189,6.293-8.172,10.741-11.025c3.082-1.978,3.165-2.309,3.945-7.151
                c-5.209-0.633-10.097-2.506-15.375-3.307c-5.963-0.904-11.866-0.801-17.763-1.377c-4.693-0.459-9.595-1.108-13.837-3.717
                c-2.442-1.502-5.35-2.048-7.982-2.659c-5.094-1.181-9.445-3.937-14.291-5.555c-1.582-0.529-3.09-1.082-4.8-1.145
                c-2.892-0.107-5.272-1.65-7.863-2.784c-5.612-2.454-9.63-7.685-15.901-8.854c-0.317-0.059-0.666-0.202-0.901-0.415
                c-3.554-3.222-7.64-5.137-12.505-5.215c-0.312-0.005-0.692-0.15-0.918-0.361c-3.457-3.237-7.881-3.672-12.248-4.575
                c-3.447-0.713-6.646-2.463-8.051-6.08c-0.594-1.53-1.789-1.793-3.034-2.217c-1.413-0.481-2.903-0.834-4.198-1.541
                c-3.252-1.777-4.607-4.85-3.826-8.423c0.278-1.272,0.773-2.497,1.121-3.591c-0.93-1.621-2.598-1.872-3.661-2.911
                c-3.173-3.099-5.188-6.625-3.458-11.154c0.582-1.523,0.564-2.752,0.125-4.278c-0.941-3.277-1.27-6.592,1.416-9.371
                c0.755-0.782,0.812-1.605,0.737-2.701c-0.401-5.805,2.741-8.913,8.324-7.933c3.499,0.614,6.645,0.982,10.026-0.847
                c2.625-1.419,5.534,0.073,8.209,0.963c7.42,2.469,14.812,5,22.449,6.786c3.014,0.705,5.164,0.624,7.465-1.952
                c2.805-3.139,6.838-3.166,10.717-2.073c4.002,1.127,8,2.312,11.907,3.725c15.06,5.444,30.39,9.796,46.298,11.927
                c0.988,0.132,1.985,0.306,2.932,0.607c5.052,1.608,9.833,1.812,14.934-0.566c4.367-2.037,9.442-2.163,14.251-1.923
                c8.216,0.411,14.258-2.628,19.085-9.355c5.333-7.432,11.439-14.304,17.038-21.552c1.712-2.217,2.879-4.858,4.265-7.323
                c2.283-4.062,4.357-8.255,6.845-12.187c6.163-9.739,15.337-13.872,26.638-13.573c1.6,0.042,2.917-0.153,4.195-1.076
                c10.754-7.757,22.836-12.968,34.891-18.246C1047.146,7.581,1050.541,8.402,1055.041,14.768z M1041.703,26.103
                c0.395-1.448,1.152-1.821,1.771-2.329c0.645-0.528,1.341-1.017,1.883-1.637c0.869-0.995,1.838-1.986,2.39-3.156
                c0.903-1.917-0.291-3.334-2.385-2.997c-0.642,0.104-1.257,0.415-1.866,0.673c-13.678,5.786-27.093,12.056-39.033,21.068
                c-0.887,0.67-1.694,1.685-3.109,1.399c-0.834-3.349-3.418-2.644-5.646-2.162c-7.93,1.714-14.168,5.87-17.55,13.44
                c-5.995,13.421-15.47,24.422-24.493,35.742c-2.387,2.993-5.009,5.798-7.524,8.689c-2.761,3.171-6.247,4.767-10.427,5.271
                c-4.146,0.499-8.297,0.709-12.458,0.886c-3.343,0.143-7.03,0.035-9.694,1.914c-4.934,3.479-9.688,2.279-14.679,0.84
                c-1.437-0.414-2.917-0.703-4.396-0.929c-14.84-2.269-29.405-5.604-43.515-10.834c-4.521-1.677-9.164-3.034-13.768-4.484
                c-2.026-0.638-4.082-1.472-6.182-0.416c-1.256,3.432,2.771,3.874,3.34,6.574c-6.939-1.709-13.953-1.715-20.734-3.524
                c-7.09-1.892-14.001-4.297-20.959-6.579c-1.115-0.366-2.221-0.82-3.375-0.232c-1.538,0.784,0.08,2.469-0.871,3.446
                c-2.544,0.338-4.975-2.18-7.747-0.278c0.845,4.081,5.444,4.196,7.296,7.143c-4.784,0.936-9.339-1.638-13.771-0.156
                c-0.649,2.374,0.891,2.959,2.184,3.647c3.1,1.648,6.384,2.842,9.75,3.816c0.994,0.288,2.072,0.444,2.58,1.553
                c-4.832,1.425-10.077,0.147-15.037,3.568c6.641,5.433,14.334,4.625,21.733,6.218c-5.37,3.245-11.772,3.146-16.004,8.327
                c5.364,4.149,11.642,1.429,17.052,3.492c-2.047,1.88-5.062,2.097-5.548,5.384c4.698,3.124,9.868,2.153,14.839,1.754
                c1.254,7.817,7.494,5.9,12.528,6.86c0.912,2.918,3.431,4.076,6.312,4.682c2.645,0.556,4.604,1.903,6.721,3.731
                c3.111,2.688,6.988,5.1,10.924,6.079c5.414,1.347,10.991,1.781,15.547,5.5c0.576,0.469,1.57,0.604,2.367,0.594
                c4.782-0.055,8.686,2.732,12.863,4.33c4.412,1.688,8.893,3.219,13.379,2.966c8.533-0.48,16.715,0.905,24.823,3.027
                c4.456,1.167,8.864,1.823,13.595,1.148c0.292,3.897-0.256,7.48-1.376,10.997c-1.187,3.726-2.767,7.234-6.429,9.159
                c-3.527,1.853-5.869,4.885-8.227,7.888c-2.342,2.983-2.25,4.722,0,7.586c3.123,3.973,5.775,4,10.614,0.109
                c-0.046-1.703-2.187-2.018-2.403-3.691c1.096-1.312,2.388-2.15,4.134-1.609c1.89,2.261,1.905,4.763,1.189,7.369
                c2.656,0.627,4.263-0.226,5.562-1.978c1.963-2.647,2.068-5.452,1.08-8.517c-0.565-1.752-2.156-3.327-1.44-5.494
                c2.911,0.155,2.981,4.374,5.852,3.875c1.8-0.614,1.218-2.388,2.101-3.325c2.471,3.17,11.088,10.618,14.557,11.942
                c0.341,2.071,0.01,4.518,2.369,5.659c1.511,0.731,2.811,1.65,4.119,2.688c1.925,1.527,3.263,0.857,3.742-1.716
                c0.298-1.597-0.1-3.35,1.307-4.961c2.02,0.027,3.881-1.182,5.865-1.796c7.135,3.848,9.223,3.561,14.383-1.933
                c2.286,0.262,4.568,0.951,6.912,0.409c2.779-0.644,2.829-3.938,4.781-5.24c8.859,2.31,9.328,2.113,11.34-4.674
                c0.454-0.069,0.936-0.167,1.423-0.212c5.123-0.478,6.376-1.962,6.028-6.975c-0.1-1.425-1.016-2.89,0.021-4.289
                c7.062-1.702,7.709-2.456,7.08-8.407c2.063-1.171,4.434-2.186,4.936-5.049c0.497-2.833-2.002-4.334-2.997-6.609
                c0.968-0.371,1.735-0.675,2.511-0.959c2.092-0.768,3.402-2.128,3.172-4.487c-0.229-2.33-1.86-3.43-3.908-3.999
                c-1.442-0.4-2.917-0.693-4.389-0.973c-10.304-1.963-20.618-3.87-30.912-5.879c-5.189-1.013-9.979-3.079-14.518-5.73
                c4.883,1.123,9.582,2.739,14.415,3.941c2.929,0.728,5.223,0.247,7.279-1.852c0.569-0.581,1.022-1.332,2.335-1.51
                c0.725,0.356,1.752,0.876,2.793,1.369c7.432,3.521,8.003,3.611,13.926-2.303c6.17-6.162,11.777-12.877,18.131-18.875
                c6.116-5.774,11.299-12.301,14.688-20.014c1.354-3.082,1.911-6.383,6.06-6.892c0.486-0.06,1.011-0.867,1.281-1.439
                c0.678-1.434,1.598-2.437,3.21-2.859c1.174-0.308,2.094-1.232,2.401-2.376c0.584-2.167,2.125-3.213,3.951-4.191
                c0.989-0.53,2.252-1.408,2.475-2.347c0.658-2.768,2.489-4.002,4.869-5.022c1.354-0.58,2.466-1.669,3.016-3.184
                c0.688-1.898-1.434-2.51-2.26-3.848c4.83-1.803,10.085-0.377,14.393-4.183c-4.226-4.102-10.015-2.646-14.767-5.085
                c4.991-1.644,10.62,0.677,14.951-4.105c-7.687-5.117-16.265-3.856-24.56-6.175c2.525-0.532,4.452-0.979,6.395-1.337
                c3.288-0.607,6.513-1.413,9.498-2.965c1.332-0.693,2.735-1.41,2.025-4.027c-6.406-2.088-13.433-0.41-21.326-1.478
                c3.729-1.396,6.503-2.309,9.17-3.471c2.585-1.126,5.894-1.227,7.762-5.469c-7.066-1.643-13.523,0.789-20.368,0.901
                c2.053-1.835,4.235-2.295,6.115-3.325c2.056-1.126,4.11-2.219,5.685-4.002c1.039-1.177,1.905-2.566,0.875-4.044
                c-1.136-1.63-2.708-0.856-4.018-0.122C1056.896,22.85,1049.114,23.275,1041.703,26.103z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M1064.688,780.031c4.033-7.652,10.921-14.033,12.468-22.688
                c-1-2.041-2.723-1.098-4.09-2.166c3.668-5.491,5.521-11.585,6.852-18.072c1.301,6.979,2.492,13.98,3.934,20.931
                c1.484,7.155,6.455,12.674,9.537,18.973c-0.389,0.431-0.555,0.791-0.801,0.859c-6.157,1.711-7.307,3.613-5.863,9.671
                c1.213,5.094,3.821,9.553,6.3,14.078c2.375,4.337,5.138,8.477,7.133,13.131c-2.13,1.574-4.601-0.34-6.667,1.535
                c0.931,4.549,6.124,6.137,7.771,10.318c-0.055,1.627-2.787,1.182-2.536,3.213c0.195,1.585,1.007,2.795,1.859,4.02
                c2.156,3.096,5.106,5.633,6.881,9.443c-1.912,0.97-3.805,0.395-5.482,0.881c-3.189,0.924-3.954,2.725-2.206,5.598
                c1.4,2.301,3.521,3.979,5.38,5.883c1.729,1.771,3.683,3.377,4.503,5.898c-1.817,2.143-4.421,1.354-6.575,1.965
                c-4.528,1.282-5.46,2.734-3.861,7.203c2.729,7.625,6.962,14.349,12.426,20.316c1.555,1.698,3.285,3.275,4.307,5.24
                c-0.783,1.444-2.038,1.393-3.134,1.734c-4.704,1.472-6.395,3.905-6.188,8.891c0.161,3.883,1.397,7.502,2.792,11.084
                c0.655,1.683,1.773,3.195,1.938,5.065c-3.628,1.025-67.164,1.244-73.24,0.197c1.17-2.509,3.135-4.308,4.654-6.399
                c1.857-2.557,3.95-4.971,5.562-7.672c2.279-3.822,1.6-5.549-2.318-7.469c-1.018-0.498-2.285-0.569-3.197-1.982
                c1.225-5.684,6.434-9.592,8.412-14.852c-1.59-1.86-2.804-0.178-4.009-0.462c-0.526-0.39-0.392-0.923-0.134-1.313
                c2.203-3.331,4.461-6.626,6.666-9.956c1.652-2.497,3.371-4.971,4.402-7.812c1.223-3.374,0.093-5.472-3.393-6.253
                c-1.125-0.252-2.314-0.248-3.478-0.296c-1.137-0.047-2.276-0.011-3.371-0.011c-0.087-0.628-0.284-1.084-0.151-1.223
                c4.389-4.555,7.113-9.886,8.369-16.135c0.509-2.535,2.14-4.846,3.261-7.257c2.773-5.964,2.446-7.749-2.303-12.231
                c-0.214-2.059,1.444-3.198,2.509-4.592c2.533-3.322,4.84-6.779,6.551-10.596c2.316-5.17,1.725-6.214-4.001-7.041
                c0.401-1.936,2.11-2.89,3.153-4.277c2.224-2.958,4.129-6.043,4.947-9.707c0.903-4.043,0.217-4.896-4.108-4.995
                C1065.672,780.691,1065.197,780.771,1064.688,780.031z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="M475.922-0.715c14.886-1.613,29.448-0.734,43.555,4.667
                c0.007,1.747-2.581,1.711-2.186,3.66c0.27,0.247,0.585,0.791,0.99,0.869c10.773,2.069,20.91,6.586,31.972,7.743
                c8.094,0.846,15.839,3.637,22.89,7.181c0.473,0.643,0.457,0.936,0.139,1.128c-1.503,0.914-3.828,1.2-3.773,3.473
                c0.055,2.29,2.431,2.442,3.967,3.253c7.498,3.961,15.516,6.871,22.752,11.39c1.784,1.114,4.036,1.517,6.11,2.116
                c2.904,0.838,5.621,2.052,8.184,3.642c2.396,1.486,4.25,3.197,3.535,6.441c-0.573,2.598,0.834,4.11,3.156,4.888
                c1.259,0.422,2.552,0.889,3.859,1.001c9.017,0.781,16.133,5.217,21.804,11.882c3.144,3.695,6.67,5.845,11.466,6.057
                c0.984,0.043,2.135,0.112,2.9,0.627c3.26,2.194,6.525,4.224,10.439,5.182c1.688,0.413,2.457,2.178,2.438,3.999
                c-0.017,1.663-0.122,3.327-0.224,4.988c-0.102,1.679-0.176,3.306,1.07,4.709c2.438,2.743,2.998,6.247,3.763,9.656
                c0.845,3.767-0.079,6.918-2.093,10.355c-3.695,6.305-8.494,10.843-15.146,13.898c-3.003,1.379-5.734,3.744-5.729,7.964
                c0.005,2.707-1.676,5.031-3.475,7.042c-2.787,3.115-5.786,6.086-9.498,8.034c-6.474,3.397-13.075,6.557-19.671,9.715
                c-1.638,0.784-3.423,1.473-5.205,1.706c-10.417,1.362-19.045,6.576-27.133,12.734c-12.104,9.216-25.373,16.323-39.052,22.836
                c-5.985,2.85-11.301,6.991-17.458,9.623c-12.707,5.433-25.473,10.652-39.173,13.099c-1.646,0.294-3.271,0.66-4.649,1.692
                c-5.836,4.369-12.56,6.824-19.459,8.804c-4.819,1.383-9.504,3.067-13.986,5.292c-10.692,5.308-22.216,7.924-33.741,10.573
                c-8.439,1.94-16.961,3.446-25.447,5.139c-2.772,0.553-4.102-0.205-4.862-3.018c1.331-1.333,3.192-1.229,4.916-1.497
                c12.334-1.917,24.725-3.576,36.555-7.836c2.347-0.845,4.744-1.603,6.991-2.67c9.93-4.715,19.994-9.081,30.429-12.583
                c2.177-0.731,4.446-1.778,6.122-3.29c3.295-2.973,7.204-3.917,11.342-4.48c5.66-0.771,10.959-2.527,16.105-5.062
                c6.265-3.084,13.072-5.172,19.065-8.68c11.482-6.721,23.011-13.33,34.832-19.434c0.588-0.304,1.229-0.635,1.643-1.125
                c4.138-4.908,10.218-7.35,14.786-11.696c1.98-1.884,4.604-2.653,6.807-4.118c5.416-3.602,11.014-6.653,17.697-7.06
                c0.645-0.039,1.286-0.341,1.904-0.585c7.917-3.126,15.15-7.54,22.346-12.004c1.995-1.237,3.284-3.275,4.832-5.018
                c2.27-2.554,2.064-5.468,1.362-8.685c4.073-2.833,6.799-7.034,11.542-9.22c4.464-2.058,7.238-6.312,9.642-10.546
                c2.371-4.179,0.906-8.414-1.317-11.833c-2.292-3.523-2.706-6.958-2.407-10.851c0.309-4.039-0.447-5.035-4.224-6.101
                c-1.76-0.498-3.54-1.06-5.345-1.24c-7.068-0.705-12.25-4.786-17.2-9.335c-1.349-1.24-2.643-2.542-4.023-3.744
                c-3.203-2.789-6.791-4.608-11.116-5.207c-7.105-0.984-9.738-4.016-9.959-11.082c-0.06-1.895-1.008-2.698-2.548-3.167
                c-2.386-0.726-4.775-1.449-7.189-2.072c-1.805-0.466-3.53-1.15-4.961-2.282c-6.489-5.135-13.915-8.505-21.572-11.351
                c-3.102-1.152-4.427-2.962-4.699-6.167c-0.493-5.824-2.792-8.583-8.494-8.071c-11.781,1.057-22.187-3.778-33.09-6.525
                c-2.487-0.626-4.467-2.21-6.236-4.008c-3.152-3.202-6.867-5.211-11.385-5.654c-3.1-0.304-5.764-1.711-8.05-3.697
                C481.528,3.153,478.924,1.112,475.922-0.715z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="M1058.688,620.075c-1.601-2.912-1.789-6.025-5.146-6.94
                c-1.088-0.297-1.208-2.253-1.451-3.561c-0.303-1.625-0.467-3.303-2.432-4.27c-2.825,1.865-1,5.785-2.941,7.41
                c-2.146,1.799-4.049,3.604-4.929,6.617c-2.363-1.645-2.022-5.331-5.302-4.676c-3.312,0.662-1.896,4.242-3.701,6.414
                c-3.378-3.238-1.049-8.374-4.307-11.385c-3.484,1.58-1.409,6.092-4.114,7.846c-1.526-0.275-1.368-1.43-1.594-2.336
                c-0.39-1.557-1.123-2.884-2.875-3.018c-1.808-0.139-2.661,1.054-3.032,2.684c-0.183,0.803-0.32,1.654-1.583,1.963
                c-0.906-1.637-0.833-3.744-2.171-5.141c1.578-1.994,3.617-2.855,5.696-3.357c3.528-0.85,6.998-1.606,10.405-3.085
                c2.072-0.899,3.789-1.53,4.982-3.53c1.495-2.502,4.164-3.734,6.796-3.574c5.159,0.311,7.217-3.166,8.659-6.812
                c1.562-3.953,4.195-6.25,7.926-7.686c3.568-1.374,6.511-3.289,9.387-6.119c4.61-4.534,9.651-9.221,16.809-9.795
                c1.037-0.083,1.846-0.746,2.582-1.427c3.152-2.913,6.916-3.663,11.092-3.522c4.285,0.145,8.691,0.928,12.633-1.432
                c3.709,2.836,3.709,2.836,0.919,7.809c3.851,3.5,3.672,7.983,2.759,12.677c0.71,1.445,2.472,1.365,3.604,2.385
                c2.589,2.331,5.026,1.614,6.856-1.636c0.725-1.287,1.761-2.323,2.424-3.71c0.934-1.95,4.301-3.154,6.318-2.378
                c1.866,0.717,3.551,0.383,5.366,0.087c3.558-0.58,6.84,0.218,8.681,3.489c1.441,2.561,4.639,3.312,5.536,6.316
                c4.367-0.51,8.519,1.145,13.079,0.42c1.209-1.198,2.338-2.893,4.523-3.878c-1.807-3.116-1.334-6.757-3.119-9.65
                c3.092-3.22,5.666-6.905,10.9-4.871c0.979-2.282,2.039-3.693,4.35-4.894c2.967-1.541,6.609-2.925,8.566-6.187
                c1.086-1.81,2.688-2.923,4.723-3.421c1.613-0.395,2.739-1.444,3.051-2.984c0.412-2.03,1.144-3.774,2.637-5.242
                c0.425-0.418,0.895-1.438,0.688-1.751c-1.837-2.8-0.993-6.495-3.087-9.135c-0.836-1.054-1.7-2.03-2.061-3.36
                c-0.819-3.02-1.687-5.951-4.908-7.486c-0.742-0.354-1.295-1.543-0.303-2.941c4.586,2.479,9.655,1.686,14.204,0.29
                c2.985-0.916,6.021-1.068,8.942-1.942c0.945-0.282,2.143-0.862,2.879-0.533c6.137,2.743,13.672,1.121,19.289,6.328
                c-0.515,2.729-2.996,5.057-2.058,8.184c-2.497,0.189-2.653-2.531-4.905-2.463c-0.777,0.092-1.389,0.928-1.74,1.859
                c-1.928,5.108-4.333,10.072-3.924,15.807c0.288,4.033-0.356,7.957-2.682,11.281c-4.582-1.291-0.817-7.176-4.794-8.969
                c-2.581,0.947-2.833,3.495-3.35,5.321c-0.777,2.751-2.14,5.174-3.153,7.775c-0.547,1.402-0.86,2.529-3.034,2.378
                c-1.888-0.131-2.325,2.197-2.71,3.732c-1.44,5.74-4.73,11.068-3.951,17.32c0.025,0.205-0.369,0.461-0.691,0.838
                c-1.768-1.486-0.911-5.099-4.671-5.453c-1.067,4.129-4.183,7.057-6.651,10.436c-3.52-2.189-4.015-2.068-5.818,1.465
                c-0.227,0.442-0.26,1.192-0.59,1.342c-3.072,1.389-4.133,4.284-5.068,7.188c-2.202-0.817-1.604-2.648-2.148-3.922
                c-0.435-1.016-1.141-1.668-2.205-1.779c-1.353-0.144-2.246,0.651-2.623,1.844c-0.655,2.071-0.944,4.217-0.979,6.4
                c-0.033,2.17-0.002,4.311-2.541,5.449c-1.888,0.846-1.115,3.381-2.404,4.668c-1.902,0.809-3.598,0.032-5.222-0.133
                c-2.309-7.285-2.309-7.285-8.633-6.016c-1.048-2.498-0.761-5.218-1.166-7.819c-0.241-1.554-0.595-3.149-2.364-3.417
                c-2.045-0.309-2.863,1.239-3.445,2.917c-0.367,1.062-0.182,2.385-1.739,3.286c-1.709-0.496-3.038-3.68-5.674-1.739
                c-1.985,1.462-1.295,4.018-2.345,5.815c-7.145,2.518-7.145,2.518-8.632,6.955c-1.87,0.099-3.124,2.04-5.04,2.262
                c-1.498-1.229-1.027-2.958-1.732-4.252c-1.146-2.105-3.771-2.762-4.754-0.768c-1.475,2.986-5.252,3.328-6.051,6.623
                c-1.829-1.006-1.401-2.498-1.593-3.738c-0.33-2.139-0.508-4.303-0.88-6.434c-0.241-1.381-0.921-2.529-2.57-2.586
                c-1.349-0.047-2.104,0.722-2.65,1.881c-0.463,0.98-0.424,2.27-1.724,3.139c-2.137-3.805-6.711-4.924-8.435-10.118
                c-3.908,5.343-1.238,11.464-4.548,15.806c-1.532-0.953-1.12-2.453-1.522-3.609c-0.525-1.51-1.17-2.893-3.016-2.77
                c-1.49,0.1-2.289,1.149-2.662,2.586c-0.32,1.225-0.259,2.633-1.537,3.746c-3.187-4.731-0.611-11.002-4.333-15.753
                c-3.696,2.987-1.542,7.699-4.062,10.62C1061.421,612.977,1061.421,612.977,1058.688,620.075z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="M1002.331,395.196c0.348-1.879,1.681-2.998,2.795-3.623
                c3.065-1.72,3.665-4.414,3.895-7.46c0.337-4.463,2.761-7.175,7.134-8.037c2.4-0.473,4.59-1.991,7.229-1.32
                c0.891,0.227,1.537-0.478,2.063-1.142c1.625-2.054,4.028-2.846,6.251-3.97c6.027-3.048,11.141-0.972,16.197,2.493
                c4.086,2.8,8.67,4.661,13.68,4.616c1.818-0.016,3.75-0.637,5.256-2.359c2.053-2.345,4.559-4.321,7.506-5.571
                c0.911-0.386,1.844-0.962,2.783-0.996c3.117-0.112,6.205-0.148,9.311-0.737c1.721-0.327,3.941,0.01,5.174,1.343
                c3.162,3.42,7.664,3.685,11.555,5.299c4.273,1.773,9.149,1.668,13.403,3.716c0.839,0.404,1.618,0.043,2.39-0.244
                c3.303-1.23,6.594,0.109,9.893,0.082c0.752-0.006,2.002,0.443,2.186,0.973c1.59,4.593,6.272,4.749,9.568,6.853
                c1.347,0.859,2.485,1.803,2.369,3.503c-0.127,1.857,1.062,3.057,1.971,4.414c-0.76,1.581-2.383,1.749-3.654,2.368
                c-2.392,1.163-4.854,2.183-7.311,3.208c-8.665,3.614-15.479,9.092-18.375,18.422c-1.348,4.338-3.566,7.688-8.146,9.262
                c-2.529,0.869-4.414,2.961-6.322,4.808c-2.168,2.097-4.6,3.188-7.639,2.864c-3.736-0.398-7.166,0.365-10.525,2.191
                c-2.598,1.413-5.421,2.461-8.447,2.922c-2.474,0.377-4.715,1.53-6.729,3.109c-4.289,3.364-7.459,3.29-11.267-0.248
                c-1.604-1.49-3.366-2.708-5.332-3.648c-2.983-1.426-6.177-2.383-8.711-4.745c-1.353-1.26-3.46-1.033-5.233-1.428
                c-3.24-0.721-5.188-2.275-5.591-5.943c-0.419-3.813-0.957-7.589-0.687-11.429c0.449-6.384-2.234-10.849-8.014-13.623
                c-5.738-2.755-11.503-5.283-18.062-5.095C1004.09,396.047,1003.24,396.026,1002.331,395.196z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(43)} fill={props.fillColors[43]} d="M943.719,349.067c-3.54-4.269-7.017-7.935-11.725-10.364
                c-4.424-2.283-5.818-6.886-3.048-11.418c2.517-4.119,2.184-8.606,3.013-12.951c0.224-1.168,0.035-2.396-0.756-3.322
                c-1.373-1.61-1.481-3.167-0.402-5.001c1.244-2.114,1.925-4.569,4.85-5.248c1.598-0.371,3.188-0.708,4.787-1.038
                c1.379-0.284,2.588-1.395,4.01-2.22c3.973,2.074,8.012,2.243,11.735-0.47c1.368,0.488,2.187,1.336,2.83,2.303
                c1.441,2.165,3.32,3.194,5.946,2.609c1.365-0.304,2.613-0.195,3.684,0.886c4.056,4.099,9.235,2.873,14.131,2.87
                c0.749,0,1.68-1.125,2.275-0.508c2.644,2.74,6.809,1.51,9.57,3.857c1.082,0.92,2.493,0.804,3.826,0.842
                c2.83,0.08,5.664,0.292,8.491,0.229c5.031-0.112,9.032,1.938,12.325,5.562c0.791,0.872,1.517,2.116,2.62,2.202
                c4.124,0.32,6.274,3.174,8.526,6.045c0.931,1.187,2.271,1.963,3.662,2.548c7.818,3.287,15.398,7.157,23.615,9.476
                c2.057,0.581,4.072,1.516,5.908,2.62c4.716,2.835,9.371,5.697,14.879,6.849c2.121,0.444,4.011,1.62,5.596,3.163
                c3.576,3.48,7.139,6.975,10.858,10.612c-4.286,4.645-9.097,7.046-15.115,6.581c-4.132-0.319-7.331,1.792-10.558,3.845
                c-0.969,0.616-1.918,1.343-2.672,2.198c-3.645,4.132-7.807,3.571-12.123,1.401c-2.45-1.232-4.883-2.452-7.246-3.919
                c-7.314-4.542-14.751-3.734-21.364,1.512c-2.396,1.901-4.794,2.468-7.286,1.887c-3.033-0.708-6.025-2.04-7.696-5.064
                c-0.481-0.872-0.942-1.769-1.295-2.698c-2.734-7.225-8.405-11.849-14.358-16.208c-1.792-1.312-3.921-1.767-6.108-1.971
                c-9.466-0.885-18.89-1.344-28.34,0.543C955.235,348.412,949.582,348.965,943.719,349.067z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(44)} fill={props.fillColors[44]} d="M279.966,510.338c7.892-2.129,15.16-2.304,22.441,0.183
                c1.191,0.407,1.709,1.495,2.468,2.37c3.28,3.783,6.925,6.988,11.408,9.51c4.917,2.766,8.883,6.985,15.159,7.344
                c2.654,0.152,4.723,2.945,6.811,4.859c3.599,3.302,7.517,5.855,12.336,7.268c3.895,1.143,8.102,2.364,10.155,6.598
                c0.068,0.141,0.218,0.324,0.349,0.341c5.883,0.739,8.78,7.003,14.616,7.873c2.014,0.3,4.023,1.185,5.145,2.829
                c1.555,2.281,3.608,3.335,6.145,3.835c1.857,0.366,3.42,1.248,4.674,2.687c1.866,2.139,4.303,3.068,7.074,3.226
                c2.434,0.14,4.508,0.835,5.969,3.016c1.387,2.07,3.681,2.864,5.971,3.331c2.332,0.475,4.401,1.399,6.466,2.531
                c3.8,2.083,8.805,2.177,11.05,6.793c3.7,1.05,7.201,2.496,10.194,4.998c0.765,0.639,1.71,1.076,2.418,1.764
                c3.59,3.486,7.711,5.126,12.75,3.994c1.32-0.297,2.747-0.262,3.879,0.523c5.487,3.807,11.834,6.077,17.353,9.818
                c2.904,1.969,5.853,3.762,9.47,4.258c2.436,0.333,4.037,2.438,6.477,3.279c7.055,2.436,13.89,5.506,21.109,7.556
                c2.243,0.637,3.931,2.461,5.806,3.846c2.726,2.015,5.586,3.601,9.194,3.226c0.943-0.098,2.013,0.154,2.9,0.535
                c6.106,2.621,12.18,5.319,18.131,7.934c0.338,4.639,0.338,4.639-2.944,7.953c-1.099-0.098-0.939-1.153-1.304-1.797
                c-0.403-0.712-0.589-1.582-2.031-2c-2.892,1.092-2.729,5.125-5.518,7.065c-2.162,1.506-2.708,5.332-3.934,8.041
                c-2.846-0.812-1.299-4.198-4.09-4.729c-2.291,0.681-2.382,3.072-3.133,5.012c-0.557,1.437-0.534,3.221-3.037,3.019
                c-1.168-0.095-1.531,1.587-1.784,2.704c-0.435,1.922-0.505,3.935-1.371,5.735c-3.29-0.567-2.291-4.368-4.525-5.611
                c-2.91-0.583-3.188,2.384-4.771,3.562c-6.876-7.147-15.313-12.275-22.945-18.403c-3.133-2.516-7.002-3.826-10.756-5.205
                c-3.34-1.227-6.298-2.959-8.739-5.603c-2.161-2.341-4.881-4.021-7.469-5.839c-5.862-4.119-11.731-8.229-17.271-12.785
                c-4.921-4.046-10.977-6.254-16.11-9.997c-2.533-1.847-4.635-3.686-5.292-6.887c-0.49-2.387-1.932-4.206-3.951-5.566
                c-3.038-2.046-5.872-4.519-9.143-6.069c-10.97-5.195-21.42-11.51-33.03-15.342c-8.409-2.777-16.011-7.387-24.121-10.855
                c-3.168-1.354-5.486-3.748-7.086-6.791c-2.547-4.844-6.529-8.017-11.689-9.525c-7.039-2.057-13.006-5.367-17.868-11.161
                c-8.106-9.661-18.659-16.254-29.852-21.853C282.803,513.072,281.423,512.412,279.966,510.338z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(45)} fill={props.fillColors[45]} d="M1272.838,316.375c-3.416,4.574-7.839,7.671-11.372,11.632
                c-0.418,0.468-1.058,0.855-1.665,1.02c-5.834,1.583-9.559,5.836-13.093,10.307c-2.89,3.655-5.791,7.307-8.493,11.099
                c-1.312,1.84-2.873,2.663-5.035,2.535c-4.824-0.287-9.803-1.202-13.017,4.284c-2.633-3.142-5.37-4.134-8.449-4.317
                c-1.795-0.107-3.138-0.847-3.97-2.539c-0.967-1.961-2.812-2.917-4.816-3.198c-5.625-0.788-9.205-3.758-11.089-9.149
                c-1.036-2.964-2.654-5.729-4.054-8.562c-0.662-1.34-1.27-2.906-2.76-3.417c-2.775-0.951-3.183-3.14-4.1-5.587
                c-3.429-9.143-5.629-18.838-11.391-26.994c-0.49-0.695-0.751-1.378-0.969-2.202c-1.015-3.855-2.087-7.703-3.329-11.49
                c-0.731-2.229-0.466-3.92,1.691-5.045c3.372-1.759,5.759-4.421,7.457-7.792c0.601-1.193,1.467-2.553,2.731-2.798
                c5.289-1.023,10.492-3.349,16.048-1.496c4.436,1.48,8.644,3.496,12.686,5.809c6.809,3.898,14.048,6.799,21.29,9.733
                c1.83,0.741,3.558,0.61,5.368,0.527c3.292-0.15,5.944,0.983,7.332,4.087c0.798,1.785,2.128,2.921,3.608,4.013
                c6.109,4.501,11.59,9.519,14.01,17.056c0.551,1.715,2.071,2.535,3.397,3.525C1264.821,310.376,1268.783,313.341,1272.838,316.375z
                "/>
            <path fill-rule="evenodd" onClick={() => props.onFill(46)} fill={props.fillColors[46]} d="M705.786,558.088c4.425,4.047,9.256,4.039,14.049-0.28
                c2.244,1.075,3.825,3.45,6.671,2.937c2.728-0.492,4.238-2.445,5.795-4.558c4.242,1.116,7.958,3.619,12.62,3.694
                c4.947,0.078,7.684-3.925,12.034-5.198c0.258,0.739,0.572,1.493,0.785,2.274c0.706,2.605,2.664,3.594,4.967,2.96
                c3.355-0.925,6.72-1.714,10.14-2.338c1.187-0.216,1.789-1.43,2.645-2.255c2.169,0.41,4.088,0.968,4.595,3.656
                c0.273,1.452,1.66,2.048,3.098,2.02c2.975-0.059,6.035,0.075,8.25-2.511c4.466,4.988,7.269,3.465,9.026-1.306
                c4.604,3.043,5.944,2.877,10.22-1.387c2.306,0.496,3.351,3.491,6.325,3.211c2.784-0.262,3.687-2.561,5.512-4.172
                c2.944,1.455,6.445,1.256,8.798,4.103c1.152,1.396,3.089,0.878,4.748,0.86c1.455-0.017,2.778-1.219,4.732-0.289
                c-0.607,4.111-1.23,8.326-1.842,12.465c-1.346,0.658-2.652,0.248-3.908,0.92c-2.484,1.33-4.967,0.574-6.854-1.563
                c-2.336-2.646-3.201-2.538-4.543,0.782c-0.447,1.104-0.832,2.081-1.837,2.85c-0.734,0.563-1.179,1.506-1.829,2.38
                c-1.162-0.742-1.407-2.77-3.321-2.386c-0.647,0.129-1.472,0.354-1.971,0.084c-3.611-1.961-6.862-1.729-10.229,0.778
                c-0.969,0.722-2.787,0.499-4.251-0.384c-3.211-1.938-3.321-1.829-4.77,1.574c-0.254,0.599-0.477,1.212-0.721,1.838
                c-4.186-2.345-4.186-2.345-7.471,1.573c-3.885-6.706-3.988-6.833-10.355-1.652c-1.592,1.296-3.068,2.352-5.062,2.803
                c-1.78-0.188-1.877-2.685-3.841-2.805s-2.759,1.273-3.364,2.577c-1.166-0.35-1.202-1.07-1.508-1.644
                c-1.645-3.077-3.031-3.208-4.936-0.526c-2.276,3.204-3.197,3.632-6.705,2.047c-2.405-1.088-4.132,0.125-6.439,1.279
                c-0.495-2.624-2.875-3.35-4.568-4.78c-1.832-1.548-3.322-0.962-4.23,1.266c-0.484,1.19,0.066,2.712-1.377,3.851
                c-3.661-3.432-7.412-1.999-11.293,0.289c-1.735-1.982-4.221-3.016-5.846-5.186c-0.904-1.205-2.472-1.291-3.557-0.213
                c-2.143,2.131-5.354,2.584-7.346,5.387c-2.471-2.593-4.838-2.311-7.756-0.986c-4.74,2.15-9.772,1.693-14.607-0.107
                c-1.791-0.667-3.395-1.132-5.214-0.043c-2.719,1.625-5.359,1.431-7.655-0.89c-1.638-1.653-3.432-1.565-4.796,0.017
                c-2.03,2.353-4.755,1.789-6.339,0.643c-3.764-2.726-6.732-2.056-9.532,1.047c-0.708-0.523-1.249-0.723-1.452-1.104
                c-2.523-4.725-2.707-4.853-6.854-1.945c-1.543,1.081-2.875,1.578-4.54,0.519c-0.277-0.177-0.593-0.335-0.911-0.4
                c-7.127-1.478-6.864-4.146-12.467,3.656c-0.583,0.812-1.269,1.544-2.45,1.946c-0.419-0.34-0.947-0.719-1.42-1.158
                c-3.199-2.963-3.796-3.099-6.946,0c-1.696,1.67-3.539,2.273-5.76,2.501c-3.305,0.339-6.562,0.92-9.788,1.825
                c-1.604,0.449-2.59,1.35-3.579,2.443c-4.11-1.478-5.013-1.239-11.126,3.017c-1.762-1.352-3.711-2.567-6.207-2.378
                c-1.375,0.104-2.46-0.584-3.564-1.48c-3.156-2.562-3.209-2.485-6.13,0.936c-0.184,0.215-0.572,0.258-0.866,0.38
                c-5.688-4.675-5.794-4.657-8.626,1.503c-4.552-0.544-9.376-4.53-13.695,1.16c-2.511-3.059-5.14-5.132-9.366-4.271
                c-3.529,0.72-7.596-0.068-8.552,5.408c-3.208-2.586-6.112-6.09-9.684-1.158c-2.733-1.832-5.25-3.734-8.324-1.343
                c-0.692,0.538-1.686,0.454-2.381-0.022c-3.023-2.072-5.63-1.941-7.284,1.523c-5.837-3.275-6.882-3.113-12.83,1.793
                c-2.266-1.911-4.778-3.555-7.782-1.176c-4.552-2.035-8.929,1.779-13.558,0.383c-1.375-0.414-2.93-0.245-4.12-1.323
                c-2.446-2.216-5.376-2.572-8.432-1.948c-3.039,0.622-5.461-0.271-7.855-2.146c-4.045-3.165-7.954-6.636-13.2-7.815
                c-0.465-0.105-1.123-0.324-1.28-0.674c-1.716-3.822-5.412-4.576-9.563-5.8c1.841-0.846,3.376-0.487,4.425-1.639
                c2.337-2.565,5.111-1.782,7.545-0.286c2.398,1.475,4.533,2.146,7.447,1.301c2.271-0.658,4.709-0.127,7.061,1.347
                c3.367,2.11,7.292,3.146,11.34,3.343c1.328,0.064,2.836,0.287,3.825-0.729c2.362-2.428,4.968-1.557,7.273-0.389
                c2.094,1.061,3.997,1.335,6.215,0.811c3.357-0.793,6.972,0.648,10.173-1.229c2.878,5.209,3.425,5.27,9.308,2.795
                c2.408-1.014,5.172-0.973,7.565-2.303c1.443-0.802,2.395,0.312,3.251,1.479c4.125,5.614,6.082,5.678,10.575,0.472
                c0.202-0.233,0.56-0.333,1.116-0.648c2.392,0.362,3.637,3.479,6.766,3.185c3.23-0.305,3.98-3.246,5.98-4.682
                c6.066,3.814,7.583,3.638,10.762-1.418c2.255,1.463,5.176,1.229,7.375,3.342c1.205,1.158,3.312,0.884,4.773,0.096
                c2.056-1.109,4.342-1.526,6.419-2.655c2.406-1.308,5.13-2.302,8.099-1.979c1.991,0.217,3.949-0.254,5.927-0.613
                c3.11-0.567,5.788-1.457,7.106-4.684c0.681-1.667,2.066-2.373,3.597-1.244c2.289,1.688,5.128,1.237,7.584,2.287
                c1.321,0.564,2.644,0.707,3.906,1.791c2.83,2.432,5.366,1.727,7.311-1.539c0.167-0.281,0.191-0.646,0.351-0.933
                c0.156-0.28,0.401-0.513,0.9-1.126c2.062,1.873,5.073,2.043,6.849,4.863c1.279,2.029,4.32,1.657,6.143-0.188
                c0.649-0.657,1.065-1.546,1.908-2.809c1.245,2.539,3.002,3.599,5.356,3.364c5.816-0.579,11.629,0.144,17.449,0.025
                c2.705-0.055,4.938-0.534,6.393-2.936c2.131,0.707,4.105,0.998,5.982,2.379c2.742,2.016,5.32,0.429,7.208-1.943
                c7.642,2.724,10.395,2.218,12.981-2.305c8.153,1.91,10.225,3.209,13.302-3.754c2.231,1.694,4.774,3.918,7.391,1.538
                c2.406-2.188,5.408-3.846,6.982-6.917C702.847,559.95,703.347,558.618,705.786,558.088z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(47)} fill={props.fillColors[47]} d="M599.967,567.482c-2.533-3.108-5.003-6.141-7.783-9.553
                c2.83-1.182,5.288-0.72,7.562-1.243c5.447-1.253,10.55,0.913,15.803,1.667c3.356,0.482,6.945,1.717,9.583-1.432
                c4.722,1.77,9.296,1.289,13.82,0.709c4.208-0.538,8.75-0.613,10.664-4.905c4.152-1.221,7.814,0.241,11.49,0.441
                c6.109,0.333,11.51-1.046,17.202-2.576c2.011-0.541,3.942-0.403,5.232-2.686c3.802,3.835,7.499,2.956,11.161,0.175
                c0.803-0.609,1.66-1.324,2.674-1.218c2.96,0.312,4.041-1.774,5.198-4.099c2.354,1.009,4.465,0.973,6.31-1.064
                c0.738-0.814,1.937-1.631,2.967-1.688c3.988-0.215,7.178-1.66,9.631-4.883c0.387-0.507,1.639-0.47,2.004-1.275
                c1.25-2.749,3.381-4.741,5.521-6.75c2.282-2.142,4.205-4.515,3.964-8.466c4.634,2.011,5.567-1.616,7.331-3.792
                c1.391-1.716,1.962-4.308,4.971-4.02c0.256,0.024,0.584-0.303,0.817-0.529c3.513-3.404,7.831-4.957,12.592-5.461
                c1.005-0.105,1.909-0.205,2.726-0.965c3.955-3.688,3.976-3.666,8.091-0.999c3.016-3.267,7.204-3.642,11.356-3.751
                c2.842-0.074,3.564-2.782,5.801-3.951c3.584,3.547,7.364,1.164,10.738-0.357c2.305-1.04,4-2.713,6.744-1.184
                c1.731,0.965,3.517-1.619,5.703-0.121c1.221,0.836,3.094,0.48,3.586,2.641c0.395,1.727,2.188,2.594,3.532,1.688
                c4.044-2.722,9.061-2.503,13.026-4.867c5.434,2.917,5.482,2.905,8.823-2.272c2.254,2.512,4.373,1.837,6.903,0.082
                c2.217-1.537,5.264-1.873,6.367-4.965c0.668,0.539,1.188,0.77,1.405,1.167c1.11,2.023-1.768,10.83-3.841,11.776
                c-1.057,0.482-2.307,0.633-3.242,1.268c-13.645,9.258-29.778,14.02-43.064,23.953c-4.551,3.402-9.048,6.632-12.229,11.566
                c-3.424,5.311-7.803,9.924-12.279,14.255c-5.08-1.282-5.08-1.282-7.327,3.953c-3.018,1.312-6.503,0.844-9.233,2.358
                c-1.279-0.43-1.131-1.396-1.457-2.113c-1.563-3.441-4.15-4.473-7.102-2.248c-1.982,1.495-4.656,1.559-6.309,3.768
                c-0.842,1.127-3.021,0.834-4.291,0.353c-2.834-1.074-6.015-1.315-8.377-3.691c-2.081-2.095-3.429-1.743-5.158,0.829
                c-0.462,0.688-0.75,1.491-1.209,2.181c-1.201,1.806-2.52,2.383-4.532,0.858c-3.653-2.766-3.752-2.726-7.55-0.115
                c-2.272,1.562-4.26,1.947-6.83,0.275c-4.438-2.889-7.332-2.256-9.881,2.346c-1.639,2.959-4.992,3.723-6.436,6.553
                c-5.697-3.922-7.819-3.199-10.436,2.604c-1.48,0.325-2.964,0.116-4.366-0.401c-5.765-2.129-5.869-2.112-8.858,3.036
                c-2.418-0.253-4.729-0.222-6.31-2.906c-1.054-1.79-3.226-1.242-4.562,0.541c-0.702,0.936-0.292,2.329-1.644,3.308
                c-1.805-2.623-5.428-1.598-7.676-3.848c-0.897-0.898-2.296-0.291-3.117,0.635c-0.75,0.844-1.268,1.895-1.928,2.916
                c-6.513,1.136-13.124-0.34-20.22,0.332c-1.34-0.836-2.333-3.137-4.776-3.204c-2.574-0.071-3.709,1.794-4.896,3.563
                C609.983,568.755,606.446,563.568,599.967,567.482z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(48)} fill={props.fillColors[48]} d="M1032.465,147.881c-2.744-2.554-5.719-4.011-8.993-5.234
                c1.144-2.522,2.995-4.334,4.757-5.897c5.6-4.965,10.041-11.146,16.285-15.415c1.398-0.955,2.489-2.244,3.303-3.705
                c2.605-4.678,6.815-6.9,11.896-7.055c3.938-0.12,7.044-1.684,10.033-3.874c6.321-4.632,12.754-9.072,19.956-12.284
                c1.993-0.889,3.563-2.408,5.156-3.897c7.181-6.713,14.465-13.316,22.209-19.383c1.698-1.33,3.529-2.505,5.378-3.622
                c2.248-1.359,4.271-1.1,6.366,0.69c5.495,4.695,7.559,4.543,12.613-0.544c3.553-3.576,7.363-6.748,12.322-8.211
                c2.004-0.591,3.438-1.896,4.673-3.556c4.154-5.586,9.381-9.584,15.865-12.477c8.353-3.727,15.18-9.642,19.402-18.036
                c2.683-5.33,6.594-9.514,11.207-13.222c4.541-3.65,8.848-7.593,13.245-11.421c1.257-1.094,2.423-2.227,4.301-2.221
                c11.812,0.034,23.625,0.018,35.36,0.018c0.62,1.916-0.866,2.268-1.635,2.889c-15.288,12.356-30.135,25.255-45.762,37.191
                c-3.764,2.874-6.965,6.234-9.213,10.393c-4.498,8.324-11.539,13.79-20.066,17.218c-6.08,2.444-11.039,6.096-15.188,10.999
                c-1.548,1.829-3.209,3.134-5.626,3.828c-4.217,1.211-7.693,3.88-10.991,6.723c-1.009,0.869-1.914,1.875-2.987,2.65
                c-2.751,1.988-4.491,1.921-7.246-0.108c-5.594-4.119-6.506-4.252-12.316-0.392c-8.899,5.911-16.953,12.919-25.081,19.818
                c-1.659,1.407-3.276,2.859-5.307,3.69c-6.981,2.855-13.3,6.864-19.524,11.056c-3.045,2.05-6.135,3.565-10.113,3.625
                c-5.181,0.078-9.658,2.396-12.635,6.937c-1.414,2.158-3.334,3.669-5.622,4.803C1043.268,142.44,1038.07,145.069,1032.465,147.881z
                "/>
            <path fill-rule="evenodd" onClick={() => props.onFill(49)} fill={props.fillColors[49]} d="M342.914,574.26c-1.25,0.932-2.875,1.122-3.842,0.593
                c-3.321-1.815-7.105-0.688-10.504-2.103c-3.148-1.309-6.103,0.971-9.528,1.424c-2.419-2.309-5.315-4.072-9.144-3.262
                c-0.977,0.207-2.038-0.154-2.779-0.875c-3.084-3.002-6.519-2.373-9.668-0.528c-4.156,2.434-8.08,0.384-11.833-0.499
                c-3.149-0.74-6.788-1.887-8.35-5.578c-0.17-0.4-0.729-0.846-1.153-0.896c-4.067-0.482-6.984-3.761-10.863-4.505
                c-2.417-0.463-3.984-1.606-4.848-3.754c-1.389-3.455-3.916-5.189-7.57-5.609c-1.611-0.184-3.467-0.312-4.327-2.041
                c-1.629-3.275-4.32-3.131-7.258-2.5c-4.637,0.998-8.608,0.451-12.463-3.052c-5.536-5.031-11.48-9.669-17.844-13.688
                c-0.555-0.35-1.191-0.727-1.818-0.784c-5.886-0.542-8.857-5.323-13.081-8.726c1.7-3.812-1.066-7.31-0.618-11.098
                c0.108-0.913-0.679-1.886-1.549-2.453c-1.767-1.153-2.972-2.531-2.94-4.842c0.021-1.519-1.36-2.184-2.486-2.886
                c-1.678-1.046-3.381-2.051-5.327-3.226c2.406-4.791-1.78-7.72-2.966-11.509c-0.198-0.632-1.219-1.341-1.926-1.421
                c-3.458-0.385-5.739-2.23-7.357-5.196c-0.361-0.663-1.503-0.716-1.863-1.837c4.636-3.523,10.133-4.779,15.791-5.488
                c3.319-0.416,5.449,1.226,7.41,3.958c3.341,4.653,6.304,9.661,10.937,13.319c1.075,0.849,1.53,2.482,2.269,3.752
                c2.201,0.391,3.437-1.803,5.632-1.691c2.318,0.116,4.506-0.266,6.651,1.211c1.789,1.231,3.849,1.347,6.18,0.828
                c4.046-0.902,7.939-0.124,11.046,2.994c0.234,0.234,0.452,0.557,0.74,0.656c4.903,1.684,8.028,5.053,9.644,9.94
                c0.191,0.578,0.753,1.22,1.306,1.465c5.987,2.663,7.848,8.427,9.878,13.772c1.574,4.143,5.895,5.15,8.064,8.479
                c0.578,0.887,2.206,0.317,3.33-0.006c2.955-0.852,5.715-0.19,7.925,1.704c1.764,1.511,3.403,1.881,5.461,1.098
                c0.966-0.368,1.968-0.443,2.92-0.016c7.111,3.19,14.757,5.908,17.552,14.215c1.902,5.654,6.624,6.176,11.087,6.316
                c3.339,0.106,5.797,0.867,7.817,3.359c2.06,2.542,5.289,4.147,6.088,7.721c0.127,0.569,0.737,1.35,1.249,1.463
                c3.472,0.76,5.084,3.481,6.877,6.103c1.088,1.591,2.683,2.46,4.696,2.349C341.956,570.779,342.699,572.24,342.914,574.26z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(50)} fill={props.fillColors[50]} d="M286.633,759.953c-2.899,1.968-6.897,0.653-9.696,3.978
                c3.169,2.769,6.284,5.173,10.486,5.973c-4.873,0.394-9.716-0.451-13.751,1.86c-1.131,2.15,1.734,2.113,1.141,4.076
                c-8.523-0.236-16.412,2.28-23.647,6.809c0.23,1.725,1.453,1.277,2.334,1.427c0.654,0.111,1.665-0.121,1.932,0.236
                c1.981,2.657,5.518,2.543,7.743,4.325c2.921,2.339,6.081,2.427,9.345,2.628c2.154,0.133,4.334-0.146,6.837,0.754
                c0.44,1.317-0.507,3.416,2.378,4.455c-4.062,0.9-8.266-0.628-8.566,4.417c-11.333,1.238-22.209,3.561-33.524,3.108
                c-5.44-0.218-11.508,1.128-16.654,5.315c7.874,5.312,15.885,2.207,24.765,1.412c-4.618,2.696-4.618,2.696-6.044,5.088
                c3.48,2.403,7.103-0.456,11.007,0.385c-3.394,2.629-8.083,2.109-10.954,5.485c1.407,2.947,3.938,2.864,6.367,2.496
                c4.935-0.747,9.844-1.673,14.751-2.59c1.626-0.304,3.193-0.971,5.215-0.646c0.751,1.306,1.69,2.671-0.151,4.095
                c-1.655,1.279-0.609,2.436,0.2,3.595c-3.104,0.915-6.863-0.974-9.221,2.331c0.852,2.914,3.55,1.276,5.346,2.629
                c-1.817,0.476-3.414,0.825-4.966,1.315c-3.366,1.062-3.638,1.647-2.637,5.577c-2.017,1.226-3.594,3.27-6.222,3.896
                c-0.453-0.495-0.896-0.979-1.33-1.455c2.555-3.029,2.838-3.269,0.475-6.06c-2.055-2.428-1.905-6.072-4.764-8.126
                c-2.68,1.353-3.214,4.29-5.084,6.081c-0.649,0.622-0.635,1.489,0.046,2.227c1.045,1.131,0.346,2.146-0.224,3.173
                c-3.086,5.561-5.848,6.27-11.231,2.896c-1.133-0.711-2.232-1.446-2.99-2.59c0.749-1.912,3.262-0.722,4.306-3.041
                c0.335-2.184-1.67-3.949-2.922-6.004c-0.816-1.34-2.322-2.355-0.7-4.291c0.812-0.969-0.418-2.13-1.135-3.03
                c-1.86-2.337-3.784-4.556-4.771-7.531c-0.842-2.54-2.55-2.896-4.604-1.106c-1.905,1.66-4.469,3.188-2.94,6.591
                c0.666,1.481-0.447,2.829-1.198,4.417c-12.451-1.859-23.515-7.707-35.128-12.211c10.119,0.378,19.962,5.116,30.134,1.261
                c1.078-7.409-5.224-7.179-9.303-9.048c-0.455-3.41-1.108-6.691,1.218-9.582c3.449-0.887,7.245,1.115,10.435-0.938
                c0.393-1.619-0.475-2.181-1.435-2.511c-5.479-1.882-10.908-3.931-16.888-3.62c-3.333,0.174-6.65-0.324-9.912-1.061
                c-0.947-0.213-1.968-0.359-2.642-1.691c2.875-0.826,5.898,0.908,8.886-0.774c-0.211-1.799-1.535-3.845,0.359-5.304
                c-1.021-2.184-3.143-2.317-4.634-3.712c-0.313-1.144,0.992-2.663-0.454-4.105c0.684,0,1.156-0.063,1.606,0.011
                c3.451,0.562,6.9,1.137,10.345,1.735c5.799,1.009,11.408,1.142,16.524-2.579c1.313-0.955,3.802,0.475,4.841-2.407
                c-3.205-3.146-8.151-2.05-11.795-4.659c4.521,1.217,8.446-1.066,12.625-1.832c4.066-0.744,8.709-0.098,12.136-4.173
                c-3.83-4.049-8.242-1.71-12.337-1.73c0.456-1.419,2.469-1.034,2.914-2.521c-0.566-1.797-2.084-2.046-3.552-1.928
                c-4.739,0.381-9.163-0.615-13.668-2.084c-4.244-1.383-8.786-1.838-13.184-2.762c-0.723-0.152-1.622-0.172-2.24-1.541
                c8.648-3.027,17.741-2.287,26.075-3.891c1.795,0.732,2.127,2.141,2.867,3.207c1.304,1.879,2.863,3.316,5.347,2.986
                c2.497-0.332,2.964-2.35,3.418-4.347c0.145-0.638,0.365-1.259,0.623-2.132c3.26-0.607,6.241-1.881,8.537-4.459
                c1.572-1.766,4.199-0.754,5.997-2.15c1.702-1.32,1.238-4.305,4.125-4.956c2.351,1.218,2.267,3.768,2.593,5.931
                c0.559,3.713,1.486,6.571,5.162,8.951c3.042,1.971,5.238,5.388,9.936,6.51c-5.668,2.882-7.491,8.369-11.817,11.746
                C261.996,765.402,274.236,762.154,286.633,759.953z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(51)} fill={props.fillColors[51]} d="M1136.113,884.535c0.869,2.354,1.925,2.999,3.094,3.039
                c5.385,0.188,10.764,1.933,16.172-0.121c1.748-0.663,3.687-1.38,5.027,1.227c0.721,1.398,2.668,1.068,4.151,1.09
                c2.319,0.033,4.638,0.009,8.066,0.009c-3.407,1.997-6.054,2.858-8.915,2.829c-2.793-0.028-5.293,0.674-7.379,2.496
                c-1.446,1.264-3.146,1.963-4.915,2.325c-8.783,1.799-17.306,4.899-26.392,5.22c-2.231,0.078-4.756-0.054-5.791,2.406
                c-0.008,2.34,2.539,1.514,3.097,3.033c-1.308,3.715-6.976,0.32-7.825,5.126c3.168,3.116,7.63,0.611,11.442,2.325
                c-0.171,6.291,4.976,4.809,9.116,5.418c-1.396,1.955-2.775,2.957-4.961,2.922c-5.156-0.082-10.313-0.025-15.624-0.025
                c-3.042-4.989-5.131-10.198-5.976-15.836c-0.745-4.964,0.607-6.828,5.523-8.371c0.636-0.2,1.294-0.355,1.894-0.635
                c2.275-1.062,2.726-2.264,1.292-4.31c-1.234-1.763-2.766-3.325-4.216-4.931c-5.878-6.51-10.385-13.789-12.776-22.105
                c1.013-2.274,2.938-2.351,4.719-2.66c1.31-0.228,2.68-0.228,3.936-0.611c2.769-0.846,3.405-2.424,1.985-4.94
                c-1.325-2.349-3.407-4.077-5.289-5.962c-1.853-1.858-3.907-3.578-4.858-6.136c1.69-2.053,3.831-1.372,5.719-1.622
                c3.095-0.408,3.869-1.658,2.267-4.307c-1.284-2.123-3.001-3.982-4.509-5.973c-1.197-1.582-2.659-2.991-3.281-4.896
                c0.316-1.539,2.328-1.418,2.754-3.488c-0.943-3.742-5.015-5.834-7.312-9.338c1.786-1.133,3.888,0.393,5.681-1.566
                c0.133-4.731-4.146-8.104-5.513-12.896c3.866-1.287,6.313-4.152,8.471-7.369c0.646-0.964,1.552-1.729,2.717-2.111
                c1.659-0.547,3-0.315,3.579,1.53c0.348,1.104,0.545,2.257,0.784,3.394c0.584,2.764,1.488,5.204,4.97,5.207
                c1.379,0,2.077,0.912,2.541,2.047c0.25,0.612,0.379,1.273,0.587,1.905c0.81,2.462,1.829,4.812,4.915,4.878
                c2.738,0.061,4.153-1.894,5.19-4.053c1.079-2.246,1.88-4.625,2.931-6.887c0.826-1.779,1.482-3.883,3.892-4.126
                c2.394-0.241,3.599,1.497,4.602,3.245c1.722,3.003,3.775,5.234,7.491,5.966c2.817,0.555,2.5,3.499,3.08,5.964
                c-5.102,3.836-11.433,5.133-17.273,8.711c8.324,5.777,15.955,1.492,24.107,0.246c0.381,0.34-0.108,2.353,1.817,2.128
                c0.66-0.077,1.389,0.035,1.976-0.206c5.938-2.438,12.281-3.916,18.102-7.453c1.006,0.062,2.251,0.461,3.382,1.136
                c2.191,3.847-0.032,10.306-4.024,11.615c-8.028,2.633-16.072,5.183-24.55,6.034c-2.108,0.212-4.312,0.59-5.558,2.463
                c-0.66,2.307,2.004,2.542,2.289,4.281c-5.535,4.135-12.932,3.613-18.414,6.762c-0.553,2.317,1.801,2.319,1.771,3.76
                c-0.713,1.633-3.257,1.11-3.675,3.459c5.567,3.098,11.97,1.531,17.854,2.889c6.113,1.411,11.973-2.204,18.151-1.533
                c0.136,1.929-2.385,2.152-1.932,4.478c1.456,1.792,3.929,1.129,6.114,1.179c2.444,0.057,4.391,1.141,6.624,2.175
                c-1.546,1.505-3.259,1.653-4.767,2.262c-1.697,0.685-3.641,1.082-5.018,2.179c-6.057,4.824-13.648,5.91-20.426,8.916
                C1144.605,880.205,1140.73,883.092,1136.113,884.535z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(52)} fill={props.fillColors[52]} d="M247.953,333.969c1.536-1.214,1.131-2.25,1.082-3.156
                c-0.359-6.653-0.716-13.308-1.183-19.954c-0.187-2.651-0.59-5.301-1.51-7.819c-3.402-9.318-5.183-18.901-5.085-28.849
                c0.031-3.149-0.153-6.327-0.925-9.429c-1.027-4.125-0.59-8.301-0.135-12.429c1.533-13.896,2.553-27.853,4.759-41.673
                c0.31-1.939,0.55-3.868,1.799-6.178c0.88,4.698-0.627,8.537-1.012,12.462c-0.21,2.148-0.658,4.275-1.027,6.407
                c-0.367,2.118-1.11,4.198,1.915,5.266c1.343,0.475,1.112,2.279,1.23,3.585c1.054,11.608,2.076,23.219,3.142,34.826
                c1.264,13.762,2.02,27.599,3.991,41.26c2.173,15.059,2.391,30.193,2.872,45.298c0.271,8.51,0.4,16.934,2.151,25.286
                c0.522,2.492,1.331,4.741,3.032,6.719c4.017,4.672,7.875,9.48,11.882,14.161c1.268,1.48,2.555,3.077,4.848,3.614
                c2.118-2.96-0.146-5.377-0.872-7.845c-0.785-2.665-1.948-5.218-2.651-8c3.411,2.327,5.172,6.491,8.981,7.268
                c4.982,21.334,12.359,41.048,26.441,58.108c-0.121,1.099-2.077,0.355-2.663,2.081c2.979,9.763,6.181,19.938,10.995,29.406
                c4.793,9.426,6.774,19.428,7.993,29.703c0.406,3.423,1.449,6.836,0.773,10.326c-4.245,0.596-4.4,0.625-5.106-3.084
                c-1.337-7.029-2.822-14.058-3.602-21.157c-0.796-7.247-3.607-12.823-9.6-17.229c-4.116-3.026-7.534-7.044-11.071-10.801
                c-2.739-2.91-2.606-7.02-4.664-11.455c6.451,3.538,10.784,8.137,15.988,11.132c1.607-0.873,0.885-1.861,0.624-2.743
                c-0.804-2.712-1.703-5.396-2.46-8.121c-0.772-2.776-1.708-5.378-4.416-6.933c-1.55-0.89-2.03-2.545-2.632-4.113
                c-2.268-5.904-5.093-11.651-6.743-17.719c-1.73-6.361-6.658-7.97-11.469-10.276c-0.998,1.568-0.498,2.686-0.197,3.791
                c3.89,14.29,7.412,28.68,11.85,42.819c0.712,2.269,1.695,4.252,3.497,5.908c4.41,4.05,8.288,8.649,13.076,12.327
                c2.976,2.286,4.851,5.353,5.444,9.224c1.205,7.868,2.609,15.706,4.043,24.221c-3.052-0.875-5.505-1.817-7.422-3.477
                c-5.284-4.576-10.333-9.32-11.001-17.02c-0.33-3.796-1.428-7.519-1.999-11.301c-0.525-3.484-2.054-6.303-4.862-8.406
                c-2.532-1.896-5.01-3.887-7.677-5.574c-2.18-1.38-3.417-2.997-3.208-5.673c1.001-12.81-3.625-24.187-9.17-35.292
                c-1.521-3.046-2.409-6.128-2.363-9.557c0.042-3.16-0.558-6.35-0.311-9.482c0.62-7.857-3.928-13.483-7.88-19.404
                c-0.479-0.718-1.179-1.165-2.328-0.979c-1.726,1.459-0.949,3.605-0.953,5.463c-0.059,23.99-0.039,47.981-0.039,71.971
                c0,2.82,0,5.64,0,8.5c-3.078,0.655-3.529-1.447-4.179-3.288c-2.936-8.317-5.748-16.682-8.826-24.945
                c-2.948-7.912-4.493-15.975-3.814-24.438c0.677-8.446-0.734-16.595-3.821-24.461c-0.808-2.059-1.034-4.173-1.015-6.364
                c0.045-5.164-0.084-10.332,0.043-15.493c0.121-4.868-0.145-9.653-1.856-14.263c-0.532-1.434-0.66-2.908-0.659-4.418
                c0.009-17.493,0.005-34.986,0.018-52.479c0-0.614,0.13-1.227,0.605-1.944C243.447,315.841,244.352,325.231,247.953,333.969z
                M260.097,429.371c0.933-2.976,0.242-5.817,0.542-8.565c0.679-6.212-0.712-11.831-4.396-16.908
                c-2.233-3.078-3.044-6.56-2.689-10.361c0.154-1.648-0.117-3.337,0.05-4.983c0.861-8.521-2.661-15.913-5.936-23.365
                c-0.363-0.825-0.834-1.983-2.332-1.053c-0.065,0.552-0.207,1.201-0.208,1.85c-0.019,10.975,0.007,21.949-0.051,32.924
                c-0.01,1.905,0.526,3.569,1.349,5.23c3.684,7.446,7.317,14.918,11.019,22.356C257.938,427.487,258.141,428.777,260.097,429.371z
                M244.541,337.783c-3.123,13.485,3.152,23.633,9.763,34.633C255.888,361.914,250.873,344.486,244.541,337.783z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(53)} fill={props.fillColors[53]} d="M138.441,563.026c-2.268-1.632-4.617-3.343-8.009-0.653
                c0.305-4.143-0.784-6.604-3.99-8.304c-2.758-1.463-4.975-4.218-8.164-1.3c-3.297-2.721-0.513-8.51-5.917-10.123
                c-1.133,2.592-2.788,4.623-5.855,4.953c-1.467,0.157-1.205,2.047-2.133,2.888c-1.712-1.405-1.889-3.29-2.469-4.993
                c-0.568-1.668-0.664-3.699-2.851-4.236c-1.462,0.184-1.897,1.259-2.326,2.365c-0.479,1.238-0.841,2.48-2.709,2.255
                c-2.176-0.263-2.718,1.393-3.156,3.11c-0.514,2.012-0.326,4.248-2.169,6.861c-1.682-9.237-4.73-17.109-9.811-24.658
                c-3.912,4.103-7.337,7.549-8.407,12.547c-1.757-0.388-1.338-1.609-1.356-2.475c-0.099-4.529-4.048-8.039-3.333-12.813
                c0.107-0.711-0.88-1.386-1.834-1.272c-0.904,0.107-1.722,0.746-1.746,1.542c-0.102,3.431-2.923,4.995-4.703,7.469
                c-0.535-5.496-3.665-13.005-6.186-14.996c-2.113,0.226-1.896,2.53-3.493,3.851c-1.733-2.053-1.541-4.534-1.81-6.861
                c-0.322-2.789-0.447-5.68-4.302-7.333c-0.847,3.989-2.105,7.657-4.881,10.497c1.028,4.324-3.683,6.396-3.667,10.322
                c-0.326-0.016-0.652-0.031-0.978-0.047c0-1.354,0.002-2.708-0.001-4.062c-0.004-2.379-0.276-4.805-1.863-6.604
                c-2.213-2.51-2.385-5.341-2.216-8.405c0.109-1.976,0.277-4.031-1.131-5.736c-1.211-1.467-3.218-1.486-3.507,0.193
                c-0.644,3.749-4.274,5.407-5.332,9.293c-1.936-1.833-1.985-3.781-2.118-5.65c-0.178-2.504-0.474-5.007-1.33-7.35
                c-0.938-2.568-2.786-2.919-4.366-0.793c-1.729,2.327-4.476,3.762-5.364,7.188c-1.479-3.738-1.735-34.204-0.538-39.538
                c3.393,0.972,7.354-0.008,10.09,3.141c4.004-0.732,7.439,2.771,11.532,1.543c0.603-0.181,1.429-0.189,1.968,0.084
                c3.732,1.892,7.729,3.237,11.392,5.178c1.679,0.89,2.871,1.834,4.889,1.347c3.008-0.727,5.595,0.159,7.475,2.739
                c0.497,0.683,1.146,1.656,1.893,1.449c3.582-0.995,6.502,1.827,10.059,1.471c2.807-0.281,5.673,0.833,7.665,3.029
                c1.271,1.4,2.595,1.836,4.374,1.777c2.999-0.098,5.496,1.331,7.688,3.157c2.269,1.891,4.184,3.952,7.429,4.581
                c2.848,0.552,1.825,3.901,2.749,6.215c5.657,1.007,9.334,4.299,10.272,10.279c0.025,0.162,0.039,0.399,0.144,0.467
                c2.985,1.903,4.295,5.172,6.443,7.719c2.947,3.493,3.358,3.146,3.141,7.336c4.353,2.85,4.353,2.85,6.842,7.263
                c2.573,0.147,5.063,0.829,6.207,3.781c0.191,0.493,1.075,1.085,1.578,1.033c3.611-0.368,4.924,2.051,5.998,5.048
                c6.207-0.979,7.212,4.691,10.343,8.117c3.755-0.606,7.15,0.161,9.285,3.853c4.681,0.701,7.102,4.972,10.925,6.914
                c2.846,1.446,4.412,3.974,6.601,5.954c1.12,1.014,1.244,2.919,0.122,3.568c-2.859,1.655-3.451,4.585-4.855,7.958
                c-0.966-1.817-0.699-3.089-0.777-4.275c-0.284-4.313-0.926-4.862-5.556-4.677c-2.563-3.609-0.686-9.078-4.858-12.199
                c-3.855,1.071-2.339,5.652-4.446,7.098c-2.992,2.053-2.576,4.984-3.393,8.168c-1.625-3.959-1.578-7.693-2.505-11.209
                c-0.297-1.123-0.407-2.299-0.741-3.408C143.058,560.215,142.146,559.959,138.441,563.026z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(54)} fill={props.fillColors[54]} d="M772.91,323.655c7.473-2.074,14.722-2.044,20.967-5.66
                c0.996-0.021,1.6,0.583,2.264,1.075c2.023,1.5,3.808,1.157,5.055-0.952c2.5-4.228,6.676-5.342,11.029-6.056
                c1.375-0.226,2.598-0.576,3.621-1.472c1.443-1.265,2.994-2.023,4.992-1.656c2.467,0.452,3.292-1.884,5.035-3.226
                c3.167,2.621,5.724,1.24,7.846-1.631c2.989,0.63,5.438,0.333,8.135-1.309c1.953-1.188,4.922-1.758,7.547-0.318
                c1.521,0.835,3.4,1.185,4.631-0.27c3.673-4.339,8.773-2.61,13.276-3.378c1.116-0.19,1.715,0.841,2.501,1.43
                c2.738,2.051,3.533,1.941,5.639-0.784c0.807-1.045,1.5-2.177,2.395-3.487c2.691,0.566,4.793-0.211,7.183-1.911
                c4.057-2.884,8.823-4.77,13.296-7.069c0.843-0.433,1.612-0.017,2.378,0.272c1.446,0.544,2.867,0.084,3.631-0.955
                c2.617-3.563,6.959-2.736,10.384-4.179c1.824-0.769,4.084,1.138,5.936,2.101c2.446,1.272,4.764,2.292,7.295,1.592
                c1.674,0.899,2.016,2.546,3.121,3.547c1.031,0.935,2.223,1.744,3.593,1.04c4.054-2.085,7.573-0.516,10.774,1.404
                c2.538,0.042,3.335-2.607,5.735-2.749c1.845,0.18,2.944,2.354,5.179,2.699c1.642-0.161,2.578-1.743,3.871-2.694
                c3.51-2.586,7.051-2.892,10.834-0.785c1.666,0.928,3.458,1.31,5.299,0.826c2.139-0.562,3.918,0.182,5.922,1.355
                c-3.126,1.9-6.771,1.654-9.889,3.068c-3.17,1.438-6.474,2.584-9.674,3.84c-0.531-0.388-0.922-0.695-1.332-0.97
                c-1.506-1.007-1.928-3.203-3.929-3.644c-2.092-0.46-3.28,1.307-4.9,2.022c-1.538,0.68-3.235,0.812-4.733-0.333
                c-1.302-0.995-2.85-1.412-4.209-0.583c-2.316,1.411-4.837,1.874-7.418,2.279c-3.798,0.597-5.97,3.193-7.684,6.323
                c-0.479,0.875-0.939,1.769-1.316,2.691c-3.132,7.65-5.411,15.724-11.848,22.367c-6.949-0.733-13.551,3.654-20.912,4.774
                c-1.475,0.225-2.936,1.009-4.373,0.935c-7.012-0.358-12.844,2.899-18.84,5.764c-2.962,1.415-5.861,3.025-9.367,3.96
                c-3.979-9.843-6.841-19.843-9.365-30.298c-7.506,1.605-14.881,0.482-21.041,4.861c-1.759-1.655-1.684-3.649-2.49-5.238
                c-1.414-2.789-2.525-3.519-5.088-2.142c-5.969,3.209-12.484,5.652-16.929,11.198c-1.069,1.335-2.628,2.006-4.095,2.799
                c-2.622,1.417-5.395,2.588-7.378,4.714c-6.806-3.551-13.596-4.784-20.828-2.823c-2.223,0.603-2.243,0.527-4.47,5.354
                c-5.703,0.821-10.855,3.72-14.36,8.437c-1.19,1.602-2.786,2.312-4.423,3.08c-1.496,0.702-3.057,1.269-4.58,1.914
                c-0.132,0.056-0.205,0.248-0.341,0.423c-0.378,1.97,1.981,1.295,2.466,2.46c0.477,1.148,0.426,2.256-0.246,3.676
                c-2.019,0.827-4.388,1.413-6.385,3.071c-1.74,1.446-4.436,1.415-6.583,1.273c-2.857-0.188-4.804,0.735-6.571,2.702
                c-3.3,3.671-6.295,7.519-10.252,10.712c-2.568,2.072-4.037,5.771-7.228,7.667c-1.757-0.033-1.89-2.274-3.726-2.597
                c-0.219,0.07-0.771,0.1-1.105,0.385c-0.625,0.533-1.221,1.152-1.658,1.843c-3.719,5.862-8.759,9.835-15.127,12.868
                c-4.475,2.132-8.85,3.437-13.619,3.696c-2.89,0.157-4.772,2.868-7.859,2.601c-1.039-0.09-1.758,1.503-1.006,2.781
                c1.131,1.919,2.502,3.516,5.057,3.49c2.17-0.022,4.022,0.754,6.146,3.189c-6.381,0.355-11.854-0.895-17.364-0.643
                c-5.769,0.264-10.207,3.792-15.479,5.56c1.964-7.958,5.184-12.245,12.26-14.821c9.018-3.283,16.668-9.025,25.141-13.266
                c1.605-0.803,3.039-2.074,4.334-3.35c5.584-5.503,12.234-9.158,19.612-11.604c0.473-0.156,0.981-0.204,1.45-0.37
                c3.204-1.136,3.938-6.696,8.783-4.904c2.756-2.305,5.76-4.185,8.092-7.161c1.703-2.173,4.695-4.106,8.408-2.602
                c1.873-2.671,3.873-5.345,7.572-5.583c0.159-0.01,0.387-0.056,0.459-0.166c2.759-4.244,8.479-5.15,10.947-9.714
                c0.234-0.435,0.389-1.187,0.707-1.262c3.832-0.911,6.5-4.248,9.92-5.337C767.639,328.542,771.096,327.283,772.91,323.655z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(55)} fill={props.fillColors[55]} d="M133.858,227.828c-2.248,2.069-2.42,5.214-4.43,7.439
                c-2.233-1.389-3.547-2.819-2.708-5.958c3.034-11.345,3.117-22.98,2.82-34.626c-0.004-0.135,0.164-0.274,0.276-0.451
                c3.228-0.84,3.088,3.01,5.159,3.775c1.544-0.158,1.176-1.396,1.2-2.274c0.152-5.498,0.382-10.999,0.304-16.495
                c-0.067-4.709,0.688-9.277,1.481-13.878c0.354-2.053,1.089-3.383,3.233-3.81c3.731-0.741,4.6-3.395,4.313-6.738
                c-0.456-5.339-0.119-10.653,0.514-15.954c0.217-1.811,0.294-3.572-1.829-5.542c4.733,0.651,7.509,2.715,10.313,4.741
                c1.22,0.882,1.498,2.061,1.463,3.482c-0.338,13.994-0.659,27.989-0.943,41.984c-0.045,2.212-1.364,3.709-2.653,5.214
                c-2.167,2.531-4.456,4.956-6.64,7.472c-1.583,1.822-2.397,3.877-2.213,6.41c0.18,2.481-0.028,4.993-0.101,7.49
                c-0.103,3.567,0.303,6.92,2.673,9.886c2.062,2.579,2.434,5.79,2.48,9c0.103,6.999,0.244,13.998,0.249,20.997
                c0.02,25.665,0.171,51.33,0.226,76.993c0.02,9.522-1.081,18.885-1.53,28.325c-0.301,6.337-0.638,12.619,0.201,18.95
                c0.654,4.941,0.547,9.943,0.347,15.129c-2.691-0.471-4.951-1.853-7.576-0.923c-1.776-3.942-5.623-5.592-7.546-8.992
                c-0.494-0.873-1.489-1.42-2.491-1.527c-1.828-0.196-2.417-1.52-3.052-2.908c-1.454-3.179-2.836-6.398-4.447-9.498
                c-2.517-4.843-3.686-9.816-3.132-15.356c0.595-5.958,0.689-11.975,0.768-17.97c0.047-3.556,1.089-6.572,3.741-8.891
                c4.13-3.612,5.164-7.999,4.659-13.394c-0.932-9.961-0.906-20.008-0.037-29.966c0.693-7.941,2.284-15.808,3.538-23.697
                c1.361-8.558,2.807-17.104,2.442-25.822C134.901,229.677,135.203,228.781,133.858,227.828z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(56)} fill={props.fillColors[56]} d="M544.022,886.071c-1.241,3.954-3.521,6.829-5.646,9.789
                c-0.873,1.217-1.878,2.345-2.689,3.599c-1.664,2.573-1.516,2.862,1.907,4.481c0.327,1.454-0.693,2.46-1.34,3.542
                c-2.732,4.568-5.635,9.037-8.263,13.662c-1.129,1.988-2.459,2.766-4.745,2.754c-16.81-0.078-33.621-0.043-50.506-0.043
                c-2.383-4.008-6.189-6.986-7.396-11.422c0.519-1.947,3.039-1.125,3.173-2.11c0.577-2.59-1.14-3.446-1.534-5.086
                c1.154-1.987,2.737-4.123,0.738-6.498c8.948-7.854,10.973-13.01,7.272-18.271c0.842-2.473,3.093-3.822,4.73-5.658
                c2.217-2.488,4.513-4.911,6.14-7.843c0.626-1.127,1.505-2.398,0.442-3.694c-0.857-1.045-2.078-0.767-3.251-0.534
                c-0.596,0.118-1.273,0.528-2.23-0.591c5.943-7.107,8.667-16.159,13.356-24.509c0.957,5.225,4.06,8.859,6.75,12.78
                c1.635,2.384,3.13,5.051,3.082,8.177c-1.661,0.236-2.346-1.548-3.891-1.374c-0.264,0.393-0.813,0.848-0.742,1.158
                c1.296,5.63,4.444,9.987,9.504,12.748c3.217,1.755,7.09,0.848,9.512-1.953c0.854-0.988,1.6-2.172,2.05-3.393
                c1.534-4.17,2.833-8.433,6.152-11.809c-2.516-2.247-4.998-0.287-7.118-1.223c-0.732-1.536,0.71-2.324,1.29-3.382
                c0.639-1.167,1.838-2.359,1.424-3.618c-1.161-3.537,1.012-5.652,2.956-7.877c2.976-3.405,5.507-7.094,7.534-11.133
                c0.438-0.873,0.657-1.93,2.077-2.266c2.838,6.831,5.365,13.846,8.86,20.285c-1.459,1.657-3.695,0.07-4.615,2.01
                c-0.771,1.625-0.495,3.219-0.221,4.879c0.577,3.5,1.971,6.686,3.571,9.803c0.896,1.746,2.065,3.372,2.511,5.28
                c-1.48,1.067-2.688-0.517-4.197,0.142c-0.144,0.166-0.643,0.482-0.737,0.891c-0.507,2.218,0.089,4.334,1.057,6.276
                c0.977,1.961,0.957,3.518-0.196,5.54C538.387,883.805,538.836,884.484,544.022,886.071z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(57)} fill={props.fillColors[57]} d="M296.007,194.431c2.05-0.27,3.342-0.439,5.137-0.675
                c0.326-4.307,0.003-8.563-0.671-12.8c-0.602-3.779-1.459-7.521-1.956-11.312c-1.034-7.888,0.928-15.896,5.008-21.264
                c1.21,1.95,4.432,12.224,4.58,14.869c0.149,2.66,0.352,5.332,0.246,7.988c-0.196,4.927,0.33,9.602,3.16,13.853
                c1.559,2.339,1.617,4.896,0.75,7.651c-1.253,3.982-2.124,8.05-0.44,12.219c0.348,0.861,0.229,2,0.065,2.967
                c-1.71,10.063-1.685,20.229-1.827,30.382c-0.059,4.205-0.369,8.311-2.358,12.125c-0.716,1.374-1.249,2.95-0.203,4.259
                c3.029,3.791,2.84,8.402,3.515,12.794c0.269,1.749,0.167,3.584,1.266,4.991c2.006-0.062,1.406-1.843,2.385-2.708
                c2.292,2.301,1.147,6.456,4.057,8.339c1.535-0.61,1.225-1.753,1.188-2.668c-0.247-5.953,0.065-11.942-1.956-17.743
                c-0.867-2.488-0.948-5.25-0.755-7.924c0.666-9.27,0.716-18.522-0.473-27.837c2.497,5.633,4.872,11.313,5.042,17.513
                c0.347,12.654,1.466,25.284,1.223,37.954c-0.082,4.261,0.616,8.138,3.386,11.696c2.381,3.058,3.427,7.019,3.48,10.8
                c0.097,6.843,4.465,11.686,7.125,17.333c0.07,0.148,0.156,0.362,0.28,0.4c5.265,1.648,4.634,6.662,6.001,10.524
                c0.388,1.097,0.565,2.271,0.989,3.351c4.491,11.465,4.88,23.392,3.421,35.361c-1.448,11.876-0.166,23.613,0.727,35.394
                c0.339,4.471,0.854,8.928,1.296,13.476c-1.666,0.314-1.791-1.187-2.415-1.938c-1.062-1.277-1.935-2.708-2.938-4.037
                c-4.293-5.687-4.3-5.68-10.832-3.217c-0.92,0.347-1.849,0.67-2.937,1.062c-2.717-15.379-4.367-30.584,0.711-45.712
                c2.196-6.542,3.048-13.292,2.891-20.173c-0.037-1.603,0.272-3.316-0.931-4.749c-2.577-0.192-2.527,1.969-2.877,3.334
                c-1.57,6.117-4.412,11.828-5.389,18.99c-2.682-4.546-4.202-8.498-5.039-12.721c-0.744-3.756-1.468-7.515-2.194-11.274
                c-1.2-6.212-2.992-12.112-6.084-17.788c-3.421-6.278-4.702-13.481-6.43-20.421c-0.845-3.391-1.58-6.804-2.728-10.113
                c-1.707-4.923,0.125-9.908-0.521-14.667c0.545-0.378,0.785-0.631,1.078-0.734c4.624-1.639,5.387-3.087,3.291-7.252
                c-2.413-4.794-3.94-9.865-5.439-14.984c-3.177-10.854-3.036-22.074-3.688-33.181C296.807,209.053,296.444,201.917,296.007,194.431
                z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(58)} fill={props.fillColors[58]} d="M265.642,525.043c-1.572-1.481-2.733-3.388-4.851-4.208
                c-1.356-0.525-1.774-1.889-2.333-3.103c-1.251-2.721-2.493-5.449-3.843-8.121c-0.899-1.781-1.798-3.793-3.662-4.629
                c-3.347-1.501-5.095-4.083-6.149-7.404c-0.241-0.761-0.73-1.671-1.375-2.039c-6.55-3.738-11.976-10.53-20.951-7.826
                c-1.539,0.464-3.314,0.092-4.17-1.068c-2.56-3.467-6.135-6.417-6.564-11.116c-0.132-1.445-1.124-2.815-0.465-4.328
                c0.339-0.075,0.718-0.292,0.91-0.183c6.227,3.553,12.753,6.443,19.415,9.076c2.948,1.166,5.614,2.948,7.979,5.074
                c5.124,4.605,10.98,8.072,17.014,11.303c5.702,3.055,11.25,6.102,15.688,11.222c3.425,3.952,8.401,6.532,13.214,8.829
                c6.659,3.177,12.34,7.883,18.692,11.524c0.57,0.326,1.286,0.722,1.517,1.263c1.794,4.204,6.106,6.029,8.754,9.402
                c3.201,4.079,7.372,6.648,12.265,8.186c2.062,0.646,4.119,1.338,6.119,2.154c3.47,1.418,6.516,3.465,8.262,6.9
                c2.732,5.377,7.351,8.319,12.644,10.755c10.13,4.66,20.594,8.562,30.698,13.206c10.082,4.634,20.495,8.828,29.57,15.494
                c2.634,1.936,4.803,4.006,5.551,7.351c0.614,2.749,2.437,4.657,4.879,6.026c11.937,6.689,22.644,15.18,33.761,23.07
                c3.125,2.217,6.271,4.396,8.919,7.209c2.112,2.243,4.831,3.548,7.676,4.526c6.554,2.255,12.283,5.815,17.541,10.308
                c3.156,2.697,6.648,5,9.982,7.488c2.515,1.877,4.723,3.981,6.05,8.127c-3.079-1.602-5.135-0.996-6.95,0.604
                c-0.72-0.489-1.477-0.732-1.765-1.241c-3.38-5.953-8.925-8.977-14.999-11.378c-1.038-0.41-1.986-1.239-2.765-2.071
                c-1.889-2.02-3.917-3.643-6.888-3.38c-1.56,0.138-2.327-1.022-3.343-1.858c-2.495-2.055-5.254-3.516-8.625-3.396
                c-1.167,0.041-2.386,0.135-3.293-0.79c-5.104-5.202-12.285-6.509-18.586-9.116c-4.529-1.874-9.599-2.43-13.652-5.716
                c-0.953-0.771-2.498-0.837-3.788-1.159c-0.962-0.24-2.149-0.145-2.753-1c-3.165-4.487-8.86-5.906-12.206-10.158
                c-0.302-0.384-0.717-0.73-1.153-0.947c-5-2.496-8.92-6.121-12.152-10.703c-1.88-2.664-4.909-4.01-8.238-4.088
                c-1.92-0.044-3.169-0.869-4.77-1.898c-3.974-2.557-8.245-4.562-10.855-8.801c-0.379-0.615-1.372-1.07-2.145-1.189
                c-6.007-0.922-9.715-5.479-14.18-8.837c-1.727-1.298-3.346-2.769-5.181-3.888c-2.615-1.594-4.885-3.295-5.825-6.457
                c-0.542-1.824-1.947-3.2-4.103-3.009c-2.37,0.21-3.742-1.028-4.786-2.888c-1.261-2.246-2.874-4.059-5.458-4.829
                c-0.796-0.237-1.508-0.729-1.773-1.614c-1.286-4.285-5.268-6.311-7.97-9.398c-1.714-1.959-4.137-2.282-6.839-2.197
                c-4.165,0.129-8.113-0.615-9.976-5.365c-1.089-2.777-3.022-5.16-4.898-7.509c-1.497-1.873-3.338-3.3-5.657-3.915
                c-1.962-0.521-3.75-1.317-5.431-2.435c-0.685-0.455-1.538-1.138-2.219-1.04c-4.957,0.703-9.074-1.904-13.515-3.219
                C269.645,524.033,267.693,524.848,265.642,525.043z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(59)} fill={props.fillColors[59]} d="M429.244,568.899c-3.295-0.386-5.945-0.289-7.604,2.775
                c-4.22-4.816-9.312-7.8-14.808-10.194c-1.211-0.527-2.602-1.038-3.427-1.98c-2.791-3.188-6.209-4.268-9.996-3.553
                c-1.687-0.865-1.674-2.365-2.085-3.562c-1.776-5.178-2.763-10.645-5.695-15.393c-0.366-0.592-0.706-1.264-0.341-1.85
                c1.852-2.967,0.491-5.873-0.288-8.663c-1.966-7.046-4.21-14.015-6.305-21.025c-1.242-4.157-4.127-7.21-6.994-10.2
                c-2.639-2.75-4.391-5.791-4.855-9.582c-1.341-10.945-5.67-20.388-13.985-27.849c-1.597-1.433-2.426-3.59-4.273-4.814
                c-0.222-0.147-0.626-0.02-1.397-0.02c-1.049,3.48,0.343,6.674,1.582,9.811c0.601,1.52,1.504,3.017,2.598,4.229
                c6.35,7.034,8.596,15.97,11.783,24.477c-4.921,2.288-7.687,1.386-9.834-2.839c-2.789-5.487-5.735-10.893-8.556-16.363
                c-2.095-4.063-3.623-8.238-2.19-12.936c0.229-0.75,0.328-1.82-0.038-2.426c-1.96-3.248-1.215-6.795-1.328-10.238
                c-0.093-2.841-0.19-5.656-1.392-8.308c-0.919-2.028-0.847-4.223-0.971-6.379c-0.238-4.155-0.425-8.314-0.71-12.466
                c-0.172-2.503-0.577-4.957-2.72-6.481c-2.365-0.382-2.884,2.4-4.846,2.41c-0.793-4.813-0.429-5.563,3.527-7.194
                c1.512-0.623,3.036-1.218,4.9-1.964c3.847,3.809,7.151,8.25,9.567,13.12c3.162,6.374,8.101,10.605,14.367,13.218
                c5.332,2.224,8.274,5.665,8.947,11.428c0.847,7.257,4.198,13.502,8.154,19.586c10.534,16.203,20.417,32.822,27.682,50.781
                c6.987,17.271,13.768,34.627,20.922,51.832C428.881,566.878,428.937,567.551,429.244,568.899z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(60)} fill={props.fillColors[60]} d="M299.607,380.039c9.183,8.595,12.722,20.237,17.788,30.798
                c1.899-0.571,1.5-1.746,1.612-2.649c1.377-11.12,0.868-22.301,0.854-33.452c-0.008-6.314-2.724-11.813-6.606-16.891
                c-5.344-6.989-10.766-14.045-12.055-23.351c0.532-1.497,2.182-1.732,3.501-2.28c2.57-1.065,2.979-2.875,2.218-5.264
                c-0.666-2.092-1.811-3.934-3.085-5.684c-1.619-2.222-2.466-4.617-2.584-7.404c-0.105-2.479-0.451-4.997-1.07-7.399
                c-2.957-11.467-2.699-23.123-1.999-34.766c0.416-6.927-0.143-13.618-2.417-20.177c-0.43-1.24-0.824-2.6-0.769-3.885
                c0.551-12.941-2.393-25.476-4.364-38.119c-0.74-4.751-1.991-9.413-3.058-14.092c-1.506-6.61-1.331-13.603,0.195-20.311
                c2.619-11.512,5.56-22.95,8.35-34.423c0.887-3.648,1.454-7.412,4.051-10.983c1.525,2.961,3.829,4.94,4.508,7.961
                c0.533,2.37,2.143,3.787,4.485,4.118c2.706,0.382,4.846,1.172,5.146,4.358c0.179,1.89,3.014,1.408,3.194,3.429
                c0.298,3.366,2.123,6.278,2.886,9.523c2.209,9.394,3.568,18.969,6.26,28.266c0.877,3.031,0.561,6.324,0.114,9.452
                c-1.423,9.962-1.081,19.99-0.435,29.92c1.026,15.777,3.021,31.49,4.404,47.248c0.513,5.839,2.753,10.958,5.648,15.838
                c1.556,2.623,2.333,5.326,1.345,8.088c-1.982,5.545-0.297,10.102,3.122,14.37c0.717,0.895,1.085,2.086,2.028,2.54
                c1.947,0.808,1.839-1.822,3.581-1.639c1.325,3.845,3.15,7.274,2.759,12.029c-0.451,5.482,1.469,11.152,2.285,16.75
                c0.263,1.806,0.347,3.644,0.407,5.47c0.039,1.173-0.214,2.303-1.631,3.135c-0.397-10.602-5.016-19.98-7.053-29.987
                c-0.244-1.199-0.885-2.13-1.871-2.848c-5.527-4.026-8.229-9.668-9.285-16.252c-0.873-5.444-1.866-10.825-5.434-15.342
                c-1.631-2.065-1.773-4.647-1.711-7.158c0.292-11.842-1.074-23.63-1.08-35.46c-0.004-8.499-2.56-16.394-5.7-24.148
                c-0.309-0.762-0.736-1.495-1.22-2.159c-0.269-0.369-0.77-0.568-1.195-0.864c-1.853,0.83-1.619,2.438-1.407,3.834
                c1.554,10.26,1.641,20.562,1.217,30.904c-0.149,3.644,0.211,7.271,1.638,10.775c0.92,2.258,0.767,4.777,0.575,7.451
                c-2.164-2.078-1.254-5.036-2.76-6.956c-1.15-0.468-1.339,0.966-1.845,0.894c-0.723,0.042-0.879-0.167-0.958-0.581
                c-0.342-1.794-0.206-3.938-1.167-5.304c-2.469-3.511-1.796-6.611-0.139-10.146c1.354-2.887,0.996-6.213,1.059-9.374
                c0.155-7.828,0.056-15.663,0.896-23.466c0.494-4.592,1.563-9.108-0.018-13.81c-0.78-2.318,0.444-4.938,1.23-7.292
                c1.077-3.225,0.926-6.23-0.935-9.074c-2.576-3.936-3.411-8.262-2.94-12.864c0.857-8.383-2.176-16.056-4.3-23.893
                c-0.16-0.588-0.692-1.076-1.045-1.603c-2.697-0.16-3.545,1.83-4.453,3.515c-3.257,6.043-4.188,12.567-3.713,19.344
                c0.466,6.65,2.635,13.074,2.642,19.786c0.001,0.979,0,1.958,0,2.87c-1.294,1.556-3.395,0.222-4.657,1.521
                c-0.568,0.499-0.576,1.194-0.517,1.836c1.303,14.267,1.121,28.625,2.711,42.878c1.025,9.192,3.496,17.942,7.268,26.355
                c0.068,0.151,0.16,0.292,0.23,0.443c0.625,1.326,1.675,2.577,0.812,4.182c-0.322,0.599-1.43,0.711-4.581,0.423
                c-0.519,5.574-1.598,11.357-0.192,16.701c2.41,9.159,4.237,18.502,7.508,27.432c0.572,1.562,0.875,3.294,1.749,4.663
                c5.555,8.7,6.334,18.783,8.335,28.491c1.091,5.294,3.286,10.111,5.499,14.952c0.337,0.737,0.84,1.452,2.58,1.467
                c1.271-6.523,2.952-13.12,5.957-20.521c0.611,6.082-0.296,10.862-1.464,15.617c-1.071,4.362-2.303,8.69-3.215,13.084
                c-1.534,7.39-2.001,14.807-0.528,22.319c1.384,7.054,1.392,14.237,1.4,21.406c0.002,1.578-0.571,3.315,0.719,4.815
                c2.561-0.401,3.38-3.171,5.869-4.048c2.353,3.107,1.606,6.844,1.935,10.323c0.36,3.808-0.285,7.737,0.801,11.434
                c1.022,3.481,2.205,6.858,1.674,11.659c-2.14-3.213-3.417-5.902-5.48-7.955c-2.032-2.021-3.684-4.213-4.443-7.049
                c-0.196-0.731-0.887-1.446-1.532-1.922c-4.091-3.022-6.069-7.457-7.875-11.933c-1.566-3.881-3.4-7.594-5.66-11.101
                c-5.425-8.416-9.778-17.416-14.354-26.294C299.561,381.971,299.084,381.434,299.607,380.039z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(61)} fill={props.fillColors[61]} d="M914.139,179.111c0.293,2.812-1.715,3.355-3.113,4.441
                c-6.002,4.66-11.231,9.925-13.369,17.785c-2.941,0.825-5.975,1.657-8.996,2.526c-12.474,3.59-24.934,7.227-37.423,10.763
                c-3.098,0.876-5.814,2.424-8.554,4.019c-4.393,2.556-8.793,4.029-14.21,2.843c-5.302-1.161-10.591,0.596-15.329,3.25
                c-3.521,1.973-7.176,3.487-11.077,4.442c-10.536,2.579-20.572,6.688-30.74,10.337c-12.134,4.355-24.624,7.057-37.233,9.351
                c-6.423,1.168-12.687,2.606-18.493,5.769c-3.722,2.027-7.805,2.459-12.083,2.409c-15.146-0.178-29.821,2.071-43.469,9.2
                c-5.212,2.724-11.238,2.976-16.688,5.059c-0.678,0.259-1.775,0.125-2.39-0.273c-4.984-3.225-10.014-1.434-14.934-0.065
                c-2.708,0.753-5.333,1.822-8.109,2.414c-1.624,0.346-3.365,0.797-4.432,2.14c-2.249,2.83-5.232,2.553-8.224,2.169
                c-2.639-0.338-5.241-0.952-7.875-1.342c-5.418-0.802-9.191,0.887-11.403,5.884c-2.199,4.968-5.774,6.699-10.97,6.979
                c-8.026,0.432-15.989-0.048-23.952-0.739c-4.526-0.392-8.988-0.218-13.437,0.552c-1.467,0.254-3.269,0.351-4.263,1.246
                c-4.776,4.3-10.802,3.869-16.466,4.626c-10.714,1.432-21.519,2.254-32.186,3.956c-16.261,2.594-32.494,5.347-48.817,7.551
                c-2.575,0.348-3.425-1.456-5.323-2.609c2.139-2.144,4.763-2.475,6.998-2.467c9.277,0.034,18.054-2.941,27.121-4.052
                c6.461-0.792,12.751-2.452,19.155-3.43c10.194-1.556,20.465-2.604,30.702-3.887c2.974-0.373,5.941-0.801,8.908-1.229
                c1.488-0.214,3.18-0.318,4.154-1.495c3.016-3.646,7.306-3.737,11.382-4.196c3.466-0.391,6.989-0.198,10.472,0.271
                c7.629,1.026,15.289,0.959,22.95,0.532c5.11-0.285,8.674-2.588,10.644-7.596c2.174-5.528,5.15-6.911,11.015-5.906
                c2.296,0.394,4.575,0.906,6.881,1.223c3.545,0.487,7.013,0.647,9.458-2.806c0.441-0.624,1.312-1.074,2.077-1.318
                c4.742-1.516,9.469-3.107,14.285-4.351c2.896-0.748,5.91-0.998,8.647,0.998c1.617,1.179,3.424,0.807,5.256,0.2
                c7.4-2.456,14.871-4.521,21.797-8.426c7.039-3.969,15.313-4.31,23.113-5.933c4.73-0.985,9.52-1.094,14.398-0.491
                c4.018,0.496,7.953-0.871,11.448-2.895c5.427-3.14,11.261-4.818,17.389-5.89c17.618-3.083,34.534-8.517,51.011-15.436
                c4.594-1.93,9.35-3.516,14.115-4.987c5.264-1.625,10.361-3.446,15.147-6.307c4.678-2.796,9.989-3.991,15.389-2.578
                c3.009,0.787,5.56,0.269,8.187-0.925c0.151-0.069,0.303-0.142,0.445-0.225c16.766-9.725,35.691-13.442,53.824-19.364
                c2.844-0.929,5.682-1.876,8.526-2.803c2.126-0.693,3.957-1.897,5.229-3.69C903.999,179.699,908.602,178.643,914.139,179.111z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(62)} fill={props.fillColors[62]} d="M138.114,391.944c1.378,0,2.544,0.089,3.691-0.02c2.067-0.197,3.583,0.435,4.38,2.458
                c0.181,0.46,0.372,1.024,0.74,1.288c3.439,2.465,5.745,5.593,6.464,9.882c0.188,1.124,1.541,1.664,2.532,2.291
                c1.366,0.862,2.083,2.058,1.769,3.672c-0.337,0.263-0.626,0.667-0.837,0.629c-5.824-1.042-11.53,1.23-17.35,0.566
                c-3.078-0.351-6.015,0.147-8.175,2.72c-0.686,0.816-1.485,1.269-2.579,1.258c-0.655-0.006-1.726-0.076-1.905,0.259
                c-2.651,4.968-9.583,2.893-12.029,7.823c-2.33-1.721-4.276-3.282-6.348-4.654c-1.942-1.287-3.978-2.466-6.461-2.37
                c-0.832,0.033-1.798,0.093-2.341-0.6c-2.23-2.849-4.951-4.253-8.673-3.617c-1.039,0.178-1.501-1.115-2.201-1.798
                c-4.735-4.619-10.136-7.875-17.015-7.92c-0.831-0.006-1.701,0.104-2.486-0.099c-2.243-0.579-4.452-1.292-6.791-1.986
                c-1.084-4.501-1.605-9.188-4.13-13.248c-0.746-1.199-0.292-2.601-0.285-3.923c0.011-1.994,0.02-3.995-0.148-5.979
                c-0.082-0.962-0.31-2.475-0.911-2.738c-3.618-1.587-4.683-5.541-7.554-7.754c-0.655-0.504-1.475-0.82-2.056-1.39
                c-2.123-2.081-1.664-4.287,1.156-5.412c0.907-0.362,1.957-0.573,2.926-0.529c2.231,0.101,4.311,0.08,5.835-1.965
                c0.354-0.475,1.204-0.965,1.72-0.87c4.084,0.747,8.073-0.869,12.129-0.388c1.152,0.137,2.298,0.403,3.452,0.432
                c2.626,0.066,4.922,0.667,6.446,3.071c0.863,1.364,2.188,2.239,3.814,1.745c2.303-0.7,4.234-0.072,6.155,1.095
                c1.324,0.805,2.819,0.954,4.34,0.92c1.545-0.035,3.396,0.069,4.091,1.395c1.772,3.378,4.897,3.635,7.978,3.918
                c2.949,0.271,5.672,0.642,6.947,3.82c4.224,0.652,6.327,4.986,10.104,6.09C130.864,381.874,134.722,386.252,138.114,391.944z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(63)} fill={props.fillColors[63]} d="M692.409,593.98c-5.738,3.282-5.738,3.282-9.394,0.074
                c-4.044,0.714-3.94,5.522-7.299,7.5c-1.376-1.115-1.615-3.912-4.115-3.549c-2.047,0.297-3.976,0.199-5.332-1.484
                c-4.422,4.363-8.629,3.461-12.502-1.162c-2.232,0.18-3.553,2.04-5.201,3.314c-1.791,1.385-3.421,1.767-5.287-0.042
                c-2.664-2.581-6.757-2.385-9.652-4.732c-0.904-0.733-2.255,0.026-2.774,1.319c-0.427,1.065-0.796,2.152-1.174,3.183
                c-4.718,1.207-8.097-0.632-10.444-4.244c-2.005-0.415-2.711,2.815-4.959,1.003c-1.644-1.325-3.389,0.649-5.261,0.975
                c-2.384-0.785-2.5-5.194-6.015-4.188c-2.465,0.705-4.831,1.759-6.39,3.811c-1.153-0.662-1.652-1.548-1.205-2.252
                c0.688-1.081,1.368-2.347,2.749-2.772c1.903-0.587,3.793-1.313,5.746-1.619c7.804-1.223,7.812-1.17,13.521-5.055
                c5.521,4.438,5.597,4.496,9.975-1.131c1.728-2.219,3.413-3.576,6.249-2.053c0.416,0.223,1.063,0.033,1.457,0.273
                c3.911,2.398,7.488,1.989,10.538-1.438c2.3,1.113,1.77,4.41,4.464,4.671c2.429,0.235,3.673-2.138,5.866-2.662
                c4.284,2.194,8.635,4.771,12.883,0.073c3.697,2.604,7.293,4.406,11.516,1.75c0.932-0.587,1.827-0.335,2.834,0.012
                c6.01,2.071,12.029,2.405,17.976-0.265c0.991-0.445,1.956-0.611,2.795,0.12c2.335,2.033,4.085,1.096,6.038-0.75
                c1.646-1.555,4.186-1.824,5.543-3.547c1.638,0.402,1.751,1.875,2.734,2.502c2.904,1.854,5.394,5.04,9.45,1.652
                c1.106-0.923,3.245-0.855,5.041,0.596c2.483,2.009,3.606,1.431,4.696-1.429c0.397-1.041,0.037-2.334,1.164-3.269
                c0.928,0.597,2.193,0.996,2.768,1.855c1.918,2.877,4.003,4.23,7.226,1.859c0.515-0.378,1.247-0.02,1.901,0.18
                c5.64,1.722,6.949,1.256,9.807-3.371c0.25,0.104,0.641,0.138,0.738,0.324c2.523,4.846,2.915,4.905,6.658,0.943
                c3.601,3.299,5.938,3.39,9.723,0.021c1.611-1.435,3.961-1.813,4.748-3.899c2.031,0.488,1.943,2.097,2.529,3.206
                c1.734,3.286,2.917,3.363,5.193,0.259c0.289-0.395,0.535-0.818,0.832-1.278c1.678,0.012,2.455,2.381,4.419,1.591
                c1.909-0.768,1.582-2.768,2.636-4.469c1.666,1.307,3.568,1.668,5.684,1.597c2.393-0.08,3.942-1.608,6.105-2.867
                c1.865,2.062,4.42,2.226,6.777,1.819c0.928,0.497,1.182,1.098,1.376,1.744c0.302,1.002,0.782,1.934,1.945,1.973
                c1.353,0.045,2.255-0.799,2.38-2.086c0.258-2.66,4.009-3.242,3.136-6.546c3.96,2.657,7.333,5.728,11.51,2.24
                c1.864,0.618,1.67,1.804,1.51,2.991c-1.112,8.252-2.169,16.512-3.353,24.753c-0.436,3.036-2.871,4.625-4.889,6.401
                c-1.582-0.695-1.312-2.04-1.674-3.065c-1.104-3.122-3.037-3.646-5.547-1.486c-1.215,1.046-1.735,2.932-4.145,3.128
                c-1.216-3.379-4.095-4.51-7.593-4.645c-1.602-0.062-2.613-0.945-3.514-2.365c-3.339-5.268-4.69-5.167-7.513,0.278
                c-0.223,0.429-0.559,0.8-0.881,1.251c-2.234,0.586-4.066-1.273-6.291-0.961c-2.307,0.324-3.276,2.798-5.68,3.268
                c-2.434-2.135-1.928-6.601-5.855-7.537c-2.283,1.543-2.155,4.85-4.848,6.605c-4.444-2.089-9.345-1.811-14.271-0.993
                c-1.627,0.271-2.957-0.091-3.824-1.628c-1.009-1.785-2.643-2.91-4.449-4.158c-3.109,1.572-2.443,5.737-5.199,7.794
                c-2.742-3.712-7.021-5.305-9.895-8.365c-2.273,0.313-2.85,2.652-5.035,3.128c-6.005-3.159-11.789-2.893-16.477,2.763
                c-1.8-0.691-1.889-2.09-2.26-3.333c-0.428-1.43-0.701-2.959-1.414-4.238c-1.261-2.266-3.294-2.245-4.659-0.092
                c-0.351,0.553-0.504,1.23-0.767,1.842c-0.256,0.592-0.535,1.174-0.924,2.021c-3.397-0.889-6.642-2.145-10.171-0.865
                C694.931,597.543,694.432,594.576,692.409,593.98z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(64)} fill={props.fillColors[64]} d="M707.174,376.444c4.416,3.044,9.311,1.562,11.461-3.404
                c0.87-2.011,2.002-3.209,3.734-4.343c1.636-1.071,3.492-2.395,4.279-4.065c2.695-5.72,7.424-7.519,13.178-7.579
                c4.072-0.042,6.482-3.57,10.146-4.56c1.481-0.4,1.142-2.576,1.175-4.063c0.023-0.993,0.005-1.986,0.005-3.153
                c2.718-1.537,5.213-3.137,7.231-5.771c1.672-2.184,4.748-3.357,7.023-5.277c1.035-0.873,2.799-0.863,4.516,0.021
                c0.113,3.113-0.868,6.144-1.338,9.214c-0.807,5.264-1.813,10.498-2.754,15.741c-0.784,4.371-2.994,5.912-7.816,5.35
                c-2.037,2.297-2.449,5.306-3.004,8.2c-0.973,5.067-1.848,10.156-2.592,15.261c-0.312,2.142-1.18,3.663-2.979,4.885
                c-3.029,2.059-5.906,4.337-9.297,5.822c-3.571,1.563-6.376,4.209-9.269,6.715c-1.566,1.357-2.405,3.143-2.816,5.166
                c-1.029,5.055-2.039,10.111-3.915,14.954c-1.247,3.218-0.678,6.327,1.516,8.918c4.282,5.056,5.621,11.246,7,17.42
                c0.955,4.278-0.621,8.108-3.68,10.777c-4.111,3.589-8.52,6.862-13.16,11.399c-0.766-9.652-3.812-17.917-5.586-26.483
                c-0.404-1.956,0.232-3.961,0.468-5.945c0.405-3.421,1.302-6.925-1.258-10.065c-1.19-1.461-0.51-3.203,0.058-4.778
                c3.988-11.06-1.625-14.738-9.623-17.68c-3.755-1.382-7.778-1.803-11.758-2.209c-2.482-0.253-4.961-0.48-7.686-1.327
                c-0.171-6.958-7.416-3.995-10.684-7.096c4.006-3.794,8.959-3.879,13.723-4.362c1.738-0.176,3.234-0.44,4.495-1.719
                c1.208-1.225,2.842-1.713,4.426-2.218c2.736-0.874,4.816-2.485,6.931-4.504C702.191,382.947,705.49,380.701,707.174,376.444z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(65)} fill={props.fillColors[65]} d="M1120.166,583.945c-4.57-3.555-5.224-5.602-2.701-9.764
                c1.788-2.952,2.559-6.151,3.154-9.405c1.684-9.218,5.123-17.915,7.749-26.852c0.913-3.108,2.951-5.579,5.06-7.954
                c2.123-2.391,3.775-5.002,4.944-8.021c2.073-5.352,5.47-9.943,9.387-14.052c4.124-4.326,6.06-9.263,6.428-15.223
                c0.997-16.176,4.897-31.873,8.454-47.614c0.292-1.29,0.741-2.546,1.18-3.796c0.096-0.271,0.458-0.449,0.775-0.741
                c1.485,3.26,1.514,6.805,2.309,10.168c0.229,0.969,0.135,2.042,0.488,2.951c3.53,9.076,2.197,18.355,1.215,27.615
                c-0.39,3.671-0.521,7.321,0.407,10.928c1.171,4.543,1.593,9.191,1.839,13.844c0.482,9.148,0.807,18.307,1.219,27.459
                c0.09,1.995-0.004,4.055,0.465,5.967c2.109,8.594,1.534,17.182,0.199,26.157c-1.762,1.2-3.647,2.724-5.455,4.315
                c-2.042,1.799-2.455,3.025-1.418,5.441c1.077,2.506,2.342,5.339,0.226,7.92c-2.279,2.78-5.405,1.527-8.28,1.325
                c-4.067-0.286-6.943-2.456-9.215-5.67c-2.322-3.284-5.559-4.645-9.529-4.313c-2.293,0.191-4.486,0.619-6.828-0.418
                c-1.9-0.842-6.143,1.604-7.382,3.819C1123.652,580.181,1122.393,582.248,1120.166,583.945z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(66)} fill={props.fillColors[66]} d="M436.612,308.381c1.347-0.978,2.527-1.18,3.668-1.216
                c12.374-0.395,24.382-3.422,36.527-5.321c15.294-2.391,30.678-3.896,46-5.965c3.751-0.507,7.415-0.853,10.596-3.643
                c2.374-2.081,5.94-2.044,9.076-2.212c3.481-0.187,7.019-0.188,10.476,0.212c7.479,0.866,14.953,0.859,22.452,0.623
                c5.454-0.172,9.487-2.153,11.835-7.479c2.062-4.68,4.93-5.967,10.063-5.346c2.973,0.36,5.907,1.054,8.882,1.362
                c3.01,0.312,5.989,0.272,8.216-2.43c0.596-0.724,1.599-1.399,2.5-1.537c4.8-0.732,9.092-3.166,13.893-3.937
                c3.204-0.515,6.215-0.923,9.164,0.927c1.481,0.93,3.232,0.779,4.854,0.239c6.916-2.303,14.222-3.375,20.646-7.259
                c3.391-2.05,7.352-3.11,11.347-3.66c5.935-0.817,11.686-2.667,17.702-3.041c2.833-0.176,5.65-0.346,8.48-0.029
                c4.78,0.536,9.307-0.423,13.523-2.631c5.377-2.818,11.075-4.385,17.055-5.347c17.473-2.813,34.411-7.643,50.879-14.104
                c4.502-1.767,9.092-3.234,13.737-4.549c5.763-1.631,11.433-3.366,16.762-6.297c5.061-2.785,10.659-3.756,16.437-2.175
                c1.672,0.458,3.277,0.407,4.886-0.255c1.384-0.57,2.861-0.945,4.182-1.63c17.914-9.296,37.676-12.842,56.823-18.297
                c0.116-0.033,0.289,0.133,0.723,0.35c1.189,3.14,2.525,6.498,5.469,9.41c-10.722,3.053-20.709,6-30.76,8.719
                c-5.467,1.479-10.801,2.906-15.704,6.087c-3.875,2.514-8.475,3.768-13.362,2.284c-4.768-1.448-9.483-0.229-13.527,2.006
                c-6.988,3.862-14.721,5.17-22.126,7.596c-9.634,3.156-19.271,6.309-28.871,9.563c-4.428,1.501-8.948,2.587-13.515,3.511
                c-8.477,1.714-16.913,3.634-25.484,4.872c-3.977,0.574-7.723,1.96-11.334,3.745c-5.808,2.869-11.934,4.125-18.412,3.285
                c-3.85-0.5-7.613-0.104-11.404,0.677c-5.867,1.207-11.776,2.21-17.674,3.267c-2.647,0.474-5.235,1.22-7.512,2.607
                c-7.906,4.821-16.977,5.595-25.7,7.572c-1.117,0.253-2.552,0.723-3.397-0.078c-3.703-3.511-7.622-1.257-11.274-0.322
                c-2.379,0.61-4.78,1.351-7.173,2.019c-1.752,0.489-3.202,1.069-4.634,2.515c-2.782,2.81-6.776,2.344-10.391,2.287
                c-2.476-0.04-4.937-0.712-7.415-1.026c-3.83-0.486-7.533-0.226-10.673,1.688c-0.674,2.355,1.605,3.195,1.604,4.972
                c-1.08,1.971-3.16,2.295-5.021,2.813c-9.218,2.563-18.57,3.821-28.156,2.688c-5.631-0.665-11.29-1.059-16.956-0.635
                c-3.279,0.245-6.602,0.317-9.084,3.256c-1.187,1.404-3.393,1.31-5.173,1.518c-13.223,1.547-26.469,2.897-39.684,4.5
                c-6.437,0.781-12.825,1.974-19.235,2.976c-6.407,1.002-12.96,1.285-19.191,3.132c-5.18,1.536-9.735-0.596-14.507-1.602
                C437.338,309.557,437.075,308.928,436.612,308.381z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(67)} fill={props.fillColors[67]} d="M190.45,315.654c8.108-0.138,12.231,4.186,14.954,10.442
                c1.288,2.96,4.104,5.397,2.928,9.154c-0.079,0.252,0.114,0.687,0.317,0.915c3.287,3.687,2.346,8.157,2.156,12.395
                c-0.825,18.421,1.397,36.547,4.595,54.642c2.979,16.862,5.438,33.816,8.157,50.926c-1.99,0.709-3.35,0.128-4.509-1.251
                c-1.298-1.544-2.672-3.177-4.628-3.689c-2.769-0.724-3.107-2.698-3.175-4.95c-0.412-13.525-3.482-26.736-4.736-40.165
                c-0.587-6.295-1.655-12.544-2.29-18.836c-0.544-5.386-1.957-10.403-4.688-15.12c-1.997-3.449-3.756-7.059-4.823-10.946
                c-0.087-0.318-0.267-0.609-0.395-0.917c-3.084-7.401-4.896-8.617-13.061-8.447c-2.866-8.189-5.505-16.58-4.97-25.509
                c0.798-13.329,0.403-26.65,0.275-39.974c-0.166-17.329-0.465-34.656-0.721-51.983c-0.128-8.682,0.572-17.341,1.523-25.931
                c1.542-13.911,0-27.598-1.77-41.316c-0.417-3.228-0.91-6.445-1.457-10.292c2.696,1.544,3.225,3.684,4.036,5.61
                c3.808,9.037,5.143,18.61,5.665,28.271c1.241,22.956,2.2,45.926,3.365,68.886c0.498,9.814,1.212,19.618,1.849,29.425
                c0.185,2.839,0.665,5.664,1.755,8.276c1.617,3.873,1.499,7.743,0.756,11.742C191.078,309.6,190.876,312.239,190.45,315.654z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(68)} fill={props.fillColors[68]} d="M208.58,327.829c-4.078-6.665-6.457-14.22-15.142-13.59
                c-0.387-1.034-0.644-1.365-0.595-1.64c1.409-7.805,0.233-15.268-1.343-23.047c-1.901-9.386-1.68-19.213-2.229-28.858
                c-1.099-19.292-2.211-38.585-3.087-57.889c-0.431-9.483-0.455-18.974-2.117-28.37c-0.866-4.895-2.402-9.6-4.095-15.095
                c6.306,1.941,12.086,3.215,16.804,7.194c2.891,2.439,4.687,5.337,5.528,9.17c2.676,12.19,5.566,24.339,8.665,36.429
                c1.317,5.138,3.366,10.087,5.064,15.128c0.476,1.413,1.059,2.785,1.039,4.343c-0.158,11.981,2.058,23.918,0.534,35.938
                c-0.44,3.472,0.144,6.95,1.163,10.36c4.211,14.088,5.34,28.408,2.709,43.391c-0.316-1.45-0.601-2.567-0.794-3.7
                c-0.225-1.312-0.531-2.639-0.523-3.958c0.05-8.265-1.793-16.225-4.201-24.018c-2.638-8.538-3.242-17.306-3.909-26.099
                c-0.312-4.116-0.642-8.227-2.081-12.197c-2.571,0.403-2.57,2.237-3.007,3.761c-2.629,9.181-2.384,18.385-0.537,27.682
                c2.409,12.124,3.339,24.403,3.306,36.759C209.724,322.017,209.765,324.516,208.58,327.829z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(69)} fill={props.fillColors[69]} d="M729.529,465.888c-0.898,4.888-1.777,9.78-2.705,14.663
                c-0.417,2.198-1.892,3.869-3.023,5.703c-2.692,4.362-4.559,8.822-2.994,14.154c1.861,6.342,0.318,12.111-3.416,17.441
                c-1.432,2.043-2.664,4.229-3.928,6.383c-2.484,4.234-5.121,8.204-10.488,9.244c-1.432,0.277-2.632,1.386-3.648,2.535
                c-0.545,0.617-1.071,1.521-1.76,1.699c-5.059,1.309-7.896,5.317-11.258,9.22c-3.118-2.459-5.646,0.664-8.313,1.335
                c-3.651,0.92-6.923,3.283-11.005,2.178c-2.418,2.604-5.434-0.104-8.309,0.87c-1.789,0.605-4.688-1.31-7.285-1.108
                c-0.469,0.035-1.195-0.386-1.394-0.188c-1.881,1.877-4.626,2.381-6.507,3.768c-1.584,1.168-2.977,1.779-4.661,1.905
                c-4.042,0.302-8.127,1.106-12.11-0.433c-0.993-0.385-2.054-0.483-2.827,0.271c-2.467,2.408-5.413,1.463-8.018,0.857
                c-3.529-0.821-7.135-1.365-10.662-2.147c-2.392-0.53-5.405-0.727-8.188,0.671c-1.862,0.934-4.366,0.781-6.574,0.733
                c-0.828-0.018-1.47-1.245-1.868-2.21c0.513-1.321,1.787-1.256,2.856-1.555c6.733-1.879,13.461-3.779,20.203-5.629
                c7.734-2.122,15.2-4.845,22.194-8.873c4.323-2.49,8.903-4.533,13.729-5.935c5.885-1.708,10.75-5.325,15.619-8.771
                c10.741-7.604,21.422-15.312,31.84-23.35c7.816-6.03,14.217-13.564,20.23-21.369C719.055,473.027,723.689,469.086,729.529,465.888
                z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(70)} fill={props.fillColors[70]} d="M346.877,192.262c-0.847,9.58,2.803,17.501,5.685,25.611
                c0.853,2.399,0.892,4.917,1.062,7.394c1.468,21.27,2.928,42.54,4.256,63.819c0.365,5.84,1.738,11.52,2.288,17.317
                c0.201,2.113,1.565,3.214,3.166,4.184c1.634,0.991,2.772,2.132,2.822,4.307c0.413,17.931,4.817,35.243,8.321,52.692
                c1.442,7.179,3.012,14.333,4.541,21.495c0.135,0.632,0.238,1.333,1.106,1.683c2.126-0.001,1.271-2.265,2.624-3.62
                c1.679,13.027,4.682,25.531,4.055,39.135c-3.413-1.779-5.832-3.972-7.778-6.573c-3.513-4.697-7.853-8.046-13.568-9.607
                c-3.662-1-6.609-3.089-8.629-6.381c-0.258-0.421-0.584-0.834-0.966-1.142c-5.545-4.47-6.787-10.458-7.027-17.277
                c-0.341-9.715,1.22-19.277,1.396-28.931c0.025-1.377,0.401-2.611,1.386-3.637c2.303-2.399,2.505-5.35,2.077-8.413
                c-0.691-4.944-1.394-9.889-2.198-14.816c-0.403-2.467-1.08-4.883-0.21-7.386c0.543-1.562-0.169-2.92-0.848-4.275
                c-1.968-3.93-2.829-8.011-2.796-12.493c0.078-10.659,0.566-21.419-0.646-31.962c-2.846-24.763-0.56-49.55-1.378-74.305
                c-0.06-1.825,0.098-3.659,0.204-5.486C345.834,193.366,346.155,193.154,346.877,192.262z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(71)} fill={props.fillColors[71]} d="M1095.463,779.15c-0.73-5.996-5.123-9.703-7.114-14.922
                c5.76-1.928,4.341-8.673,7.835-12.209c2.349,0.125,2.596,2.088,3.502,3.365c2.167,3.055,3.965,3.469,7.121,1.531
                c1.229-0.754,2.12-2.209,4.188-1.809c1.969,1.787,1.903,4.646,2.74,7.088c1.71,4.988,3.747,6.13,8.605,4.028
                c3.465-1.499,7.381-2.909,6.486-8.083c-0.219-1.264,0.51-2.337,1.529-2.955c2.772-1.685,5.02-3.795,6.288-7.154
                c1.883,5.592,7.267,9.24,7.096,15.559c-0.038,1.41,0.204,3.24,2.172,3.432c2.085,0.203,2.816-1.516,3.384-3.087
                c0.902-2.499,1.65-5.053,2.471-7.581c0.312-0.964,0.708-1.876,1.871-2.457c3.231,1.215,2.914,6.675,7.465,6.199
                c-1.021,4.223-0.598,4.897,2.99,4.999c2.666,0.075,5.291-0.328,8.135-1.196c-4.212,4.403-8.421,8.209-14.639,9.757
                c2.549,3.726,4.718,6.523,8.549,7.417c3.678,0.857,7.191,2.555,11.213,1.458c1.244-0.339,3,0.058,4.026,1.541
                c-0.833,1.769-3.265,1.497-4.021,3.041c-0.729,1.663,1.135,1.988,1.303,3.145c-0.152,0.21-0.269,0.562-0.49,0.649
                c-5.393,2.152-11.115,3.444-16.205,6.405c-1.617,0.942-3.59,0.513-5.4,0.709c-1.939,0.21-4.045-0.104-5.507,1.616
                c-0.534,1.667,0.458,2.528,1.604,3.305c1.44,0.975,3.154,1.452,4.76,1.201c5.254-0.822,10.238,0.652,15.313,1.435
                c-0.161,0.282-0.27,0.746-0.483,0.802c-5.929,1.532-11.861,3.047-16.965,5.931c-1.746-1.267-1.689-2.728-2.147-3.928
                c-0.698-1.833-1.716-3.363-3.79-3.507c-2.677-0.184-4.188-1.773-5.417-3.868c-0.253-0.431-0.507-0.862-0.79-1.272
                c-4.057-5.865-8.359-5.477-11.472,1.066c-0.714,1.499-1.228,3.092-1.899,4.611c-0.537,1.215-1.043,2.475-1.795,3.555
                c-1.932,2.773-4.242,2.576-5.6-0.434c-0.407-0.901-0.582-1.906-0.902-2.85c-0.689-2.027-1.856-3.627-4.192-3.682
                c-2.134-0.049-2.857-1.338-3.238-3.108c-0.244-1.137-0.447-2.29-0.8-3.396c-1.336-4.193-4.558-5.213-8.003-2.411
                c-1.267,1.03-2.31,2.4-3.239,3.763c-1.585,2.32-3.587,3.907-6.747,4.4c-3.199-4.301-5.453-9.258-6.894-14.549
                c-1.194-4.39-0.021-5.91,4.566-6.822C1093.553,779.754,1094.16,779.528,1095.463,779.15z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(72)} fill={props.fillColors[72]} d="M29.299,70.911c5.846,0.152,11.325,0.803,16.77-0.491
                c1.714-0.407,3.152,0.774,4.05,2.773c-0.824,2.655-3.056,4.032-5.639,4.794c-7.268,2.144-10.337,7.288-11.868,14.424
                c-1.91,8.9-2.062,17.44,1.715,25.831c3.425,7.608,2.667,15.639,2.478,23.612c-0.052,2.164-0.38,4.325-0.36,6.485
                c0.084,8.901-1.28,17.556-4.074,26.005c-1.699,5.138-1.263,10.554-1.862,15.835c-0.882,7.773-2.013,15.576-2.049,23.371
                c-0.028,6.149-0.119,12.282-0.401,18.422c-0.719,15.644-2.136,31.237-3.354,46.845c-0.102,1.302-0.036,2.668-0.973,4.286
                c-2.915-2.705-6.529-2.866-10.044-2.115c-2.344,0.501-4.006-0.134-5.243-1.741c-1.215-1.579-2.26-3.208-3.905-4.451
                c-1.372-1.037-0.946-2.812-0.72-4.216c3.143-19.567,5.171-39.279,7.668-58.929c1.406-11.065,2.917-22.122,3.673-33.26
                c0.352-5.184,1.436-10.289,3.11-15.129c4.92-14.226,5.583-29.039,6.643-43.806c0.883-12.292,1.841-24.58,2.019-36.915
                C26.987,78.713,27.805,74.953,29.299,70.911z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(73)} fill={props.fillColors[73]} d="M362.662,150.344c-6.268-1.914-12.179-3.224-17.317,1.851
                c-4.742-5.433-4.742-5.433-4.612-8.446c-6.877-4.138-14.247-0.279-21.701-1.015c0.087-3.661-2.043-5.884-4.989-7.761
                c-3.505-2.233-7.601-4.211-9.969-7.395c-3.286-4.417-7.862-4.85-12.253-6.497c-5.167-1.938-10.258-3.862-15.832-4.213
                c-2.04-0.129-3.789-1.233-5.444-2.357c-3.389-2.301-6.917-3.142-10.818-1.363c-1.361,0.621-2.719,1.204-4.224,0.143
                c-2.493-1.757-5.409-1.597-8.211-1.33c-4.894,0.466-9.153-0.94-13.226-3.811c0.295-2.116,1.524-4.032,1.305-6.25
                c-0.212-2.153-0.777-4.144-3.204-4.401c-2.362-0.25-3.865-1.491-5.471-3.043c-2.772-2.682-4.675-2.349-6.947,0.713
                c-1.081,1.457-1.57,3.318-3.297,4.695c-2.193-3.502-6.162-5.415-7.594-9.59c-0.684-1.994-2.608-2.997-4.673-3.355
                c-2.186-0.38-3.526-1.676-4.503-3.558c-2.82-5.431-7.312-7.79-13.425-7.788c-4.977,0.002-9.951-1.002-14.938-0.019
                c-0.478,0.094-1.098,0.178-1.475-0.04c-4.28-2.477-7.96-0.795-11.595,1.532c-0.528,0.338-1.229,0.406-2.01,0.648
                c-4.427-4.14-10.597-5.22-15.725-8.605c1.596-2.982,4.046-4.804,4.574-8.025c0.732-4.465,1.097-4.405-3.359-7.219
                c2.62-0.482,4.597,0.093,6.554,0.515c4.126,0.89,8.233,1.256,12.315-0.189c2.482-0.879,4.878-0.472,7.338,0.237
                c9.804,2.826,19.007,7.051,28.086,11.637c35.801,18.085,71.461,36.459,107.889,53.274c11.635,5.371,23.325,10.61,35.699,14.146
                c5.303,1.515,10.319,3.762,14.833,7.003C357.99,143.015,360.914,146.101,362.662,150.344z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(74)} fill={props.fillColors[74]} d="M863.007,895.613c-6.339,1.273-7.556,3.223-5.596,9.701
                c1.105,3.653,2.657,7.173,4.013,10.75c0.877,2.311,2.029,4.539,2.201,7.441c-16.777,0-33.426,0-50.463,0
                c1.304-4.287,4.8-7.055,6.074-10.633c-0.787-1.545-2.057-0.788-2.89-1.446c0.146-0.427,0.216-0.943,0.475-1.335
                c1.562-2.36,3.197-4.672,4.747-7.04c0.91-1.39,1.809-2.805,2.539-4.293c2.157-4.394,1.917-5.237-2.199-8.726
                c0.217-2.724,2.27-4.341,3.934-6.136c2.122-2.289,3.375-4.935,3.805-7.979c0.651-4.618-0.836-7.365-5.436-10.089
                c0.156-2.518,1.397-4.697,2.523-6.894c2.887-5.628,5.93-11.177,8.734-16.845c2.351-4.748,2.235-4.963-0.441-9.8
                c0.996-3.226,2.926-6.216,3.635-9.671c0.969-4.729,0.455-6.062-3.625-8.48c1.627-5.87,6.451-10.596,6.375-17.211
                c2.096,5.12,2.089,10.63,3.566,15.811c1.475,5.172,4.93,9.346,7.229,14.084c-5.783,3.003-6.462,4.826-4.413,10.816
                c1.412,4.131,3.729,7.796,5.803,11.591c1.338,2.451,3.021,4.744,3.84,7.433c-1.749,1.495-4.279-0.387-5.943,2.251
                c0.926,4.693,6.02,6.858,8.166,11.024c-0.125,0.26-0.212,0.72-0.383,0.753c-5.053,0.997-5.488,1.736-3.373,6.56
                c1.871,4.268,3.963,8.438,5.824,12.71C862.499,891.732,863.589,893.515,863.007,895.613z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(75)} fill={props.fillColors[75]} d="M421.927,434.249c-4.149-12.233-8.298-24.466-12.35-36.415
                c1.43-1.858,3.106-2.303,4.514-3.14c2.895-1.72,4.263-4.037,3.479-7.495c-0.986-4.354-1.136-8.894-3.581-12.896
                c-1.04-1.701-0.783-3.89-0.719-5.886c0.11-3.481,0.307-6.959,0.472-10.558c-1.583-1.333-3.277-2.167-5.933-2.465
                c0.263,6.228-4.107,9.89-7.323,14.23c-1.959,2.646-2.7,5.586-1.992,8.954c0.676,3.22,1.1,6.424,0.298,9.834
                c-0.931,3.962,2.117,6.786,4.119,9.74c1.105,1.631,3.142-0.119,4.972,0.64c3.01,8.795,5.671,17.916,9.241,26.667
                c4.759,11.668,6.543,23.508,5.262,36.055c-0.688,6.743-0.122,13.613-0.122,20.778c-5.29,0.945-10.055,0.262-14.712,0.648
                c-1.35-2.046-1.306-4.059-1.517-6.004c-1.648-15.216-3.07-30.46-5.008-45.639c-1.623-12.709-4.711-25.173-7.37-37.701
                c-4.039-19.03-8.153-38.044-12.226-57.068c-0.168-0.785-0.239-1.59-0.531-3.584c5.522,4.247,11.851,3.633,17.015,6.79
                c2.495,1.525,5.558,2.167,8.416,3.037c2.673,0.813,4.57,2.264,6.094,4.722c3.066,4.944,6.459,9.693,9.856,14.422
                c1.328,1.848,1.972,3.704,1.925,5.991c-0.441,21.641-0.812,43.283-1.215,64.925c-0.009,0.464-0.164,0.926-0.251,1.389
                C422.47,434.23,422.199,434.24,421.927,434.249z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(76)} fill={props.fillColors[76]} d="M1011.127,610.23c-2.361-4.535-6.381-7.24-8.746-11.439
                c-3.467,2.076-2.121,6.077-4.531,7.951c-1.734-0.036-2.109-1.549-2.977-2.473c-0.938-0.996-2.018-1.716-3.23-1.973
                c-2.023,0.196-2.324,2.299-3.832,3.438c-2.097-2.572-1.56-5.826-3.425-8.302c-1.586-2.104-0.413-6.021-4.007-7.16
                c-2.945,0.915-2.146,4.081-3.902,5.82c-2.325-0.865-2.352-4.377-5.296-4.687c-3.206-0.337-3.958,2.487-5.524,4.097
                c-1.801-0.733-2.221-2.205-2.635-3.641c-0.522-1.811-1.398-3.324-2.903-4.535c-2.554-2.055-2.793-4.686-1.772-7.656
                c1.351-3.936,2.794-7.858,3.808-11.885c1.841-7.309,7.524-10.09,13.882-11.1c12.33-1.959,24.203,0.076,35.261,6.119
                c5.405,2.953,10.864,5.814,16.192,8.899c8.253,4.777,13.337,12.359,17.6,20.572c1.139,2.192,0.455,3.48-2.096,3.7
                c-4.709,0.403-8.387,1.96-11.483,5.947c-1.587,2.043-5.11,3.196-7.909,3.543C1019.021,606.035,1015.006,607.639,1011.127,610.23z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(77)} fill={props.fillColors[77]} d="M306.386,625.451c-1.392-2.609-1.06-5.168-1.317-7.621
                c-0.157-1.492-0.155-3.012-1.093-4.291c-0.992-1.354-2.61-1.605-3.328-0.328c-1.294,2.303-2.943,3.625-5.468,4.312
                c-1.163,0.316-0.919,2.471-2.503,2.92c-1.104-0.803-0.739-2.069-0.902-3.107c-0.538-3.412-1.354-6.537-5.164-7.812
                c-1.553-0.52-2.182-1.898-2.591-3.43c-0.296-1.107-0.651-2.282-1.306-3.193c-1.024-1.422-2.875-1.91-3.748-0.342
                c-2.039,3.666-6.406,6.012-5.923,11.716c-1.337-3.124-1.342-3.124-1.289-6.038c0.042-2.333,0.188-4.692-0.821-6.88
                c-0.673-1.46-2.612-2.214-3.015-0.825c-1.158,3.997-6.301,4.953-6.227,10.56c-2.107-3.683-1.35-6.591-1.833-9.28
                c-0.48-2.672,0.077-5.674-2.342-8.051c-2.436,1.395-2.825,4.943-6.107,5.378c-1.303-3.896,0.644-8.697-2.981-12.108
                c-4.632,0.85-1.982,6.234-5.375,8.157c-3.219,1.825-1.432,6.507-4.175,9.953c-1.39-5.707-4.786-10.409-2.42-16.104
                c0.712-1.714,0.351-3.606-0.122-4.825c-1.688-4.348,1.216-9.646-3.249-13.172c1.459-1.701,3.064-2.549,4.647-3.389
                c1.852-0.98,3.795-1.246,5.719-0.16c1.882,1.062,3.93,1.949,5.551,3.328c1.83,1.557,3.475,2.139,6.033,2.235
                c3.348,0.127,6.674,1.968,9.407,4.124c1.409,1.112,2.68,1.731,4.429,1.807c3.579,0.154,7.016,1.627,10.75,0.76
                c1.811-0.42,3.542,0.636,4.674,2.307c0.666,0.982,1.528,2.149,2.757,1.81c4.063-1.124,7.406,1.438,11.136,1.984
                c3.593,0.526,6.187,3.264,8.428,5.86c1.492,1.729,2.788,2.521,5.015,2.304c2.248-0.22,4.458,0.618,5.961,2.14
                c3.118,3.154,6.812,5.471,10.353,8.034c1.096,0.794,2.185,1.448,3.463,1.894c2.375,0.827,4.73,1.703,5.323,4.662
                c0.252,1.258,1.246,2.146,2.513,2.029c2.803-0.256,4.207,1.658,5.736,3.387c1.925,2.174,4.521,3.177,6.948,4.594
                c4.327,2.525,9.622,3.498,12.963,7.701c0.481,0.606,1.331,1.229,2.052,1.283c3.868,0.287,5.791,2.82,7.403,5.903
                c0.954,1.825,2.723,2.851,4.373,4c2.243,1.563,4.86,2.723,7.068,5.675c-3.094,0.365-4.999,1.922-7.56,2.657
                c-1.88-2.913-5.085-4.745-5.525-8.616c-0.146-1.283-1.134-2.482-2.719-2.496c-1.383-0.012-1.935,1.141-2.489,2.16
                c-1.646,3.029-1.942,6.66-4.167,9.727c-1.821-1.572-1.875-3.635-2.285-5.506c-0.606-2.761-0.916-5.609-2.131-8.19
                c-1.168-2.479-2.233-2.715-4.624-1.31c-4.488,2.639-4.488,2.639-6.291,5.77c-3.176-7.117-0.627-15.362-4.918-21.582
                c-4.572,0.865-1.904,5.906-5.736,7.328c-3.023,1.123-1.581,5.74-4.198,8.279c-0.132-3.005-0.284-6.01-0.39-9.016
                c-0.082-2.326,0.048-4.666-0.591-6.947c-0.328-1.17-0.771-2.301-2.169-2.375c-1.159-0.062-1.664,0.823-2.051,1.819
                c-1.142,2.936-5.188,3.42-5.026,8.12c-2.814-3.246-1.675-6.746-3.709-8.322c-2.127-1.648-1.462-5.412-4.82-5.829
                c-3.539,1.162-0.905,5.646-3.661,6.962C307.593,619.441,308.616,623.016,306.386,625.451z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(78)} fill={props.fillColors[78]} d="M878.35,546.932c-1.344-3.43-1.173-6.485-1.695-9.424
                c-0.356-2.012,0.665-3.546,1.646-5.088c4.208-6.611,10.408-11.057,16.592-15.752c1.811,1.191,1.068,3.525,2.714,4.304
                c1.556,0.735,3.228,0.779,4.89,0.815c2.441,0.054,4.446,0.978,6.334,2.537c3.611,2.981,4.881,2.916,6.938-0.975
                c2.503-4.73,6.316-6.111,11.223-5.408c2.631,0.377,5.249,0.903,7.836,1.52c2.916,0.695,5.463,0.35,8.045-1.389
                c3.516-2.367,4.643-1.972,6.565,1.783c2.284,4.461,3.921,9.197,5.228,14.014c0.67,2.469,1.859,3.992,4.191,5.1
                c6.838,3.248,11.961,8.31,15.334,15.482c-4.176,2.129-8.291,4.225-11.873,7.361c-2.74-1.587-3.762-4.092-4.677-6.842
                c-1.276-3.838-2.862-4.284-6.388-2.382c-2.174,1.173-3.645,2.968-4.457,5.195c-1.439,3.946-4.215,6.249-8.164,7.343
                c-0.798,0.221-1.532,0.674-2.332,0.879c-5.381,1.385-10.37,3.497-14.415,8.316c-1.911-4.703-3.604-8.768-5.214-12.864
                c-1.43-3.638-3.658-6.057-7.76-6.938c-4.16-0.894-6.889-4.667-7.093-8.967c-0.164-3.454-1.126-6.528-3.409-9.165
                c-1.76-2.032-3.828-2.563-6.248-1.344c-2.704,1.363-5.147,3.097-7.004,5.521C883.324,542.959,881.153,544.941,878.35,546.932z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(79)} fill={props.fillColors[79]} d="M509.155,480.394c-5.436,1.772-10.106-0.018-15.017-1.787
                c-1.486-4.146-1.359-8.694-1.979-13.103c-0.308-2.188-0.625-4.343-1.893-6.122c-3.675-5.157-5.039-11.127-6.49-17.12
                c-0.603-2.489-0.832-5.262-3.067-7.36c-2.536,1.1-2.709,3.36-2.83,5.405c-0.235,3.983-0.235,7.983-0.26,11.977
                c-0.02,3.114-0.224,6.191-1.077,9.903c-3.532-3.628-7.719-4.491-11.904-5.378c-1.958-0.415-2.898-1.702-3.73-3.562
                c-3.462-7.731-4.724-15.783-4.976-24.216c-0.443-14.807-1.467-29.596-2.129-44.398c-0.165-3.68-1.941-6.663-3.475-9.781
                c-1.909-3.88-4.069-7.637-3.227-12.281c0.338-1.863-0.704-3.557-1.476-5.214c-3.447-7.403-7.583-14.437-11.663-21.496
                c-1.097-1.897-2.047-3.75-2.074-6.035c-0.022-1.845-0.822-3.538-1.609-5.207c-1.351-2.869-2.017-5.929-2.555-9.062
                c1.675-0.581,2.238,0.717,2.985,1.379c7.524,6.67,14.244,14.024,18.803,23.099c1.115,2.219,1.896,4.606,2.807,6.925
                c0.541,1.375,0.797,2.843,2.209,3.776c3.594,2.374,4.958,6.078,5.871,10.072c5.391,23.59,13.563,46.265,22.629,68.646
                c2.587,6.385,5.912,12.262,10.767,17.249c2.2,2.259,4.085,4.827,6.072,7.288c3.078,3.813,5.185,7.985,5.958,12.966
                C506.533,471.5,508.016,475.924,509.155,480.394z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(80)} fill={props.fillColors[80]} d="M119.388,465.988c-1.507-6.831-5.043-11.921-10.195-16.03
                c-1.128-0.9-1.801-2.359-2.723-3.531c-2.954-3.754-6.63-5.987-11.609-5.438c-0.657,0.073-1.583,0.389-1.939,0.08
                c-2.817-2.448-6.337-1.241-9.464-1.982c-1.838-0.436-3.686-0.474-5.405,0.253c-3.216,1.358-5.463-0.082-7.338-2.423
                c-0.747-0.933-1.348-1.947-2.591-2.157c-4.835-0.815-9.547-2.995-14.62-1.32c-0.773,0.255-1.633,0.327-2.454,0.332
                c-0.641,0.004-1.617-0.006-1.871-0.39c-2.362-3.567-7.276-3.546-10.229-6.77c9.606-3.825,21.593-4.445,30.814-1.783
                c4.321-2.63,8.968-4.512,13.771-6.053c0.769-0.246,1.545-0.606,2.187-1.089c4.488-3.376,8.233-3.343,12.062,0.377
                c0.91,0.884,1.808,1.403,3.005,1.494c6.828,0.513,11.266,4.507,14.357,10.179c1.284,2.355,2.657,3.796,5.514,4.347
                c4.61,0.89,7.675,3.907,8.395,8.735c0.336,2.252,1.17,3.885,3.565,4.079c3.029,0.244,4.479,2.14,4.992,4.764
                c0.509,2.6,1.85,3.8,4.445,3.611c2.043-0.148,3.624,0.659,4.588,2.402c1.497,2.706,5.248,3.399,5.829,7.147
                c0.196,1.263,2.552,2.964,4.417,2.656c2.708-0.445,4.064,0.581,4.995,2.877c0.522,1.289,1.782,1.875,2.782,2.737
                c2.18,1.882,4.992,3.032,6.424,5.85c0.547,1.076,1.991,2.42,3.015,2.41c5.72-0.057,5.42,5.3,7.762,8.236
                c0.116,0.146-0.153,0.599-0.356,1.294c-3.229-1.523-6.293-3.582-10.021-3.434c-1.323,0.053-2.899-0.337-3.922,0.244
                c-3.331,1.894-6.376,0.829-9.504-0.312c-0.621-0.227-1.306-0.66-1.884-0.56c-6.554,1.144-12.205-2.008-18.164-3.696
                c-0.777-0.221-1.619-0.209-2.755-0.344C133.974,473.373,128.039,468.182,119.388,465.988z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(81)} fill={props.fillColors[81]} d="M995.654,544.733c-3.572,0.925-5.347-0.904-6.479-3.552
                c-1.504-3.515-2.994-7.051-4.202-10.674c-3.639-10.913-5.16-22.256-7.073-33.561c-2.03-11.996-2.931-24.104-4.793-36.1
                c-0.978-6.295-3.431-11.717-7.891-16.324c-5.808-5.998-12.247-11.205-18.713-16.466c-4.855-3.949-9.527-8.218-12.662-14.247
                c-3.133-6.026-4.4-12.079-4.775-18.56c-0.256-4.437,1.875-6.745,6.337-7.213c0.331-0.035,0.671-0.007,0.997-0.062
                c6.302-1.067,11.457,0.145,15.87,5.526c4.038,4.923,9.774,8.338,13.881,13.75c-6.038,1.378-12.472-3.332-17.974,1.134
                c-0.225,1.668,0.707,2.466,1.42,3.366c0.492,0.623,1.36,1.083,1.074,2.068c-1.533,5.279,2.883,6.753,5.898,8.979
                c1.141,0.842,2.785,1.054,4.233,1.418c8.255,2.075,13.549,7.678,18.489,14.273c3.885,5.186,4.363,10.918,5.164,16.787
                c0.18,1.319,0.504,2.629,0.563,3.952c0.278,6.093,2.091,11.15,7.693,14.644c3.497,2.182,5.431,5.928,5.276,10.233
                C997.27,504.227,996.449,524.342,995.654,544.733z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(82)} fill={props.fillColors[82]} d="M815.467,495.872c-0.553-1.282-1.076-2.491-1.646-3.813
                c-1.826-0.645-4.224-1.121-5.659-0.869c-3.95,0.693-8.497-1.805-11.959,2.245c-1.559,1.823-4.485-0.509-6.645,1.274
                c1.357-4.445,3.532-8.324,4.355-12.679c0.224-1.187,1.333-1.942,2.442-2.347c3.359-1.222,4.623-4.086,5.791-7.077
                c1.271-3.255,2.576-6.497,3.896-9.733c1.306-3.2,3.355-5.678,6.637-7.088c1.495-0.643,2.967-1.358,3.717-3.105
                c4.117-9.599,12.206-13.41,21.861-15.346c6.101-1.224,10.732,0.76,14.888,4.845c1.323,1.301,2.237,2.854,3.034,4.535
                c1.211,2.554,2.34,5.223,4.013,7.463c2.096,2.807,2.427,5.619,1.656,8.878c-1.491,6.312-2.487,12.745-4.602,18.908
                c-0.426,1.24-0.682,2.555-1.632,3.553c-3.309-2.41-3.303-2.404-6.295,0.991c-1.803,2.044-4.98,1.78-6.427,3.945
                c-2.055,0.212-2.208-2.264-4.289-2.157c-2.292,0.313-1.95,3.408-3.73,4.537C827.858,489.615,822.059,493.562,815.467,495.872z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(83)} fill={props.fillColors[83]} d="M909.252,883.043c-1.037,7.805-8.291,11.136-11.873,17.026
                c4.272,3.472,8.655-0.993,12.479,1.5c0.76,1.363-0.246,2.419-1.074,3.406c-5.141,6.126-10.824,11.744-16.432,17.434
                c-1.008,1.022-2.119,1.463-3.545,1.456c-7.478-0.034-14.955-0.016-22.695-0.016c-2.094-8.605-7.692-15.965-7.584-25.047
                c1.078-0.395,1.514-0.668,1.968-0.703c4.999-0.391,5.642-1.346,3.977-6.179c-0.595-1.726-1.328-3.403-2.169-5.536
                c4.909-0.615,5.596-4.236,5.799-8.152c0.121-2.326,0.082-4.665,0.315-6.978c0.251-2.493,0.845-4.82,2.88-6.687
                c2.467-2.261,2.754-5.605,3.523-8.646c0.692-2.736,1.139-5.535,1.809-8.279c0.424-1.736,0.757-3.584,2.557-4.818
                c1.6,0.645,1.857,2.207,2.279,3.527c1.013,3.166,1.836,6.392,2.816,9.568c0.588,1.903,1.176,3.832,2.026,5.625
                c1.905,4.016,4.12,4.266,7.163,0.911c0.554-0.61,0.951-1.36,1.432-2.038c0.883-1.25,1.938-2.274,3.582-2.642
                c2.436,0.71,2.684,3.112,3.635,4.956c1.733,3.354,1.707,3.367,5.821,2.586c0.88,2.254,1.774,4.549,2.678,6.861
                c-1.152,1.458-2.224,2.723-3.194,4.06C904.764,879.9,904.938,880.603,909.252,883.043z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(84)} fill={props.fillColors[84]} d="M129.584,239.028c1.185-1.816,1.89-2.896,2.559-3.921
                c0.839,0.063,0.811,0.62,0.832,1.086c0.284,6.382-1.03,12.61-2.206,18.795c-3.182,16.74-4.949,33.555-3.952,50.606
                c0.025,0.443-0.166,0.899-0.329,1.714c-5.007-3.236-7.527-7.654-7.58-13.236c-0.059-6.203-2.001-11.688-4.978-17.016
                c-3.85-6.894-6.14-14.087-5.833-22.298c0.571-15.302-0.483-30.614-0.987-45.918c-0.096-2.923,0.312-5.61,1.796-8.127
                c0.252-0.428,0.436-0.897,0.651-1.347c9.479-19.777,9.551-19.771,11.346-41.847c0.269-3.303,1.294-5.032,4.584-5.99
                c3.57-1.04,7.005-2.101,10.721-1.088c2.313,0.63,4.583-0.49,6.826-0.304c1.005,1.685,0.499,3.387,0.683,5.002
                c0.291,2.556-0.729,4.286-3.305,4.66c-2.86,0.416-3.656,2.255-4.186,4.752c-1.287,6.067-1.61,12.18-1.545,18.354
                c0.033,3.14,0.151,6.31-0.517,9.593c-2.218,0.315-4.471-1.267-7.066,0.313c1.842,7.507,0.161,15.203-0.006,22.929
                c-0.087,4.019-0.447,8.037-1.827,11.788c-1.312,3.57-0.943,6.39,2.037,8.839C128.056,236.989,128.601,237.866,129.584,239.028z
                M123.58,237.191c-2.758,10.437-2.105,32.541,0.969,37.103c1.325-1.269,1.324-2.983,1.539-4.601
                c0.915-6.894,1.587-13.827,2.764-20.675C129.724,243.948,127.834,240.429,123.58,237.191z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(85)} fill={props.fillColors[85]} d="M697.6,873.412c-0.867,1.537-2.896,0.834-4.02,2.336
                c0.078,2.807,3.234,3.344,4.491,5.321c0.062,2.085-2.241,3.418-1.562,5.944c2.488,2.262,7.244,0.049,9.451,3.947
                c-2.555,4.129-7.734,5.003-10.623,8.387c-0.163,2.545,2.44,2.105,3.357,3.635c-2.517,5.614-9.701,7.201-12.124,12.637
                c1.155,2.121,3.099,1.44,4.175,2.424c-0.62,5.386-0.998,5.789-5.832,5.807c-7.652,0.027-15.305,0.007-23.398,0.007
                c-3.252-6.472-6.507-12.95-9.818-19.541c1.677-2.335,3.803-3.942,5.717-5.753c3.48-3.293,5.98-7.169,6.608-12
                c0.487-3.755-0.36-4.659-4.934-5.409c0.593-2.664,2.11-4.761,3.658-6.849c1.286-1.734,2.694-3.401,3.776-5.258
                c1.417-2.432,2.189-4.995-0.462-6.876c1.921-2.133,3.673-4.077,5.425-6.022c2.135-2.369,3.861-4.998,5.086-7.952
                c0.916-2.21,0.941-4.214-1.083-5.453c-0.319-3.8,5.755-6.03,1.83-10.561c1.335-1.449,2.77-3.007,4.636-5.032
                c2.1,6.943,3.482,13.592,6.017,19.753c-0.573,0.417-0.812,0.732-1.085,0.767c-1.539,0.188-3.442-1.396-4.572,0.53
                c-1.161,1.98,0.534,3.457,1.601,4.854c2.637,3.458,5.997,6.124,10.042,8.092c-0.472,0.393-0.577,0.522-0.713,0.588
                c-1.688,0.813-4.369,0.393-4.612,3.041c-0.216,2.348,1.989,3.277,3.503,4.5C693.914,870.712,695.78,872.043,697.6,873.412z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(86)} fill={props.fillColors[86]} d="M545.971,558.555c-2.092,1.067-2.95-0.121-3.89-1.011
                c-2.121-2.007-4.593-3.458-7.504-3.577c-3.49-0.144-6.057-1.945-8.105-4.34c-1.842-2.154-3.945-3.424-6.603-4.146
                c-4.965-1.347-9.897-2.806-13.432-6.966c-0.411-0.482-1.155-1.096-1.656-1.023c-4.237,0.607-6.537-2.562-9.408-4.633
                c-0.398-0.287-0.887-0.59-1.352-0.621c-6.065-0.402-10.406-4.424-15.364-7.186c-5.375-2.995-6.623-8.532-8.406-13.592
                c-1.106-3.137-1.653-6.411-5.304-9.205c4.887-0.295,8.644-0.845,12.162-2.658c2.486-1.282,3.906-2.635,3.828-5.662
                c-0.074-2.859-1.343-4.253-3.886-4.99c-1.842-0.533-3.305-1.504-3.198-3.938c2.961-1.689,6.102-2.294,9.847-1.704
                c-0.449,2.997,1.097,4.235,3.861,4.433c2.062,0.146,3.782,0.826,4.763,2.992c0.897,1.981,2.683,3.044,4.914,2.539
                c2.183-0.493,3.445,0.355,4.461,2.174c2.219,3.971,5.396,7.163,8.604,10.324c6.406,6.311,12.743,12.693,19.213,18.937
                c4.787,4.619,8.034,10.058,10.068,16.392C541.459,546.936,543.785,552.634,545.971,558.555z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(87)} fill={props.fillColors[87]} d="M64.67,76.432c5.184-2.198,9.61-3.428,14.5-2.57
                c2.685,9.442,3.159,18.419-4.446,25.989c-2.081,2.072-2.538,4.558-2.561,7.299c-0.063,7.511,1.045,14.963,1.163,22.453
                c0.144,9.144,2.283,17.839,5.697,26.185c4.146,10.136,4.06,20.822,4.88,31.414c0.951,12.289,1.955,24.574,2.759,36.873
                c0.357,5.48,0.38,10.989,0.381,16.485c0,11.007,0.672,21.98,1.34,32.959c1.116,18.323-0.406,36.618-0.438,55.308
                c-3.797-0.416-7.324-1.731-10.385,0.066c-1.767-0.869-1.567-2.065-1.381-3.222c3.321-20.638,3.746-41.454,3.769-62.288
                c0.021-18.497-0.13-36.994-0.106-55.491c0.016-12.55-1.74-24.896-3.847-37.222c-0.356-2.083-1.403-3.683-2.564-5.32
                c-2.89-4.076-5.717-8.196-8.586-12.286c-1.892-2.697-2.579-5.628-2.433-8.952c0.652-14.812,1.321-29.627,1.955-44.435
                C64.696,91.972,65.73,84.283,64.67,76.432z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(88)} fill={props.fillColors[88]} d="M277.53,121.177c1.446,0.666,1.776,0.758,2.037,0.946
                c8.06,5.849,8.044,5.85,7.998,15.858c-0.043,9.323-0.07,18.646-0.063,27.969c0.001,2.687-0.555,5.225-1.215,7.833
                c-1.941,7.662-1.998,15.433-0.27,23.167c0.258,1.155,0.549,2.222,0.233,3.402c-3.703,13.827-1.896,27.847-0.857,41.718
                c1.222,16.324,1.044,32.607,0.281,48.888c-0.796,16.977-1.417,33.917,0.273,50.867c0.163,1.634,0.021,3.299,0.021,5.078
                c-1.656-0.516-1.6-2.019-2.084-3.125c-1.602-3.655-2.759-7.571-4.81-10.948c-4.107-6.761-5.638-13.937-5.786-21.785
                c-0.299-15.808-1.11-31.606-1.745-47.407c-0.088-2.205,0.214-4.313,1-6.359c1.413-3.676,1.938-7.416,0.663-11.232
                c-3.153-9.439-2.348-18.543,2.098-27.409c1.054-2.102,1.375-4.452,1.486-6.791c0.418-8.809,2-17.651,1.029-26.419
                c-2.104-19.008,0.9-37.837,1.208-56.751C279.065,126.369,279.517,124.026,277.53,121.177z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(89)} fill={props.fillColors[89]} d="M4.165,68.134c1.561-0.321,2.867-0.392,4.163-0.123
                c2.229,4.279,9.634,5.836,17.805,3.759c1.201,1.062,0.146,2.18-0.054,3.18c-1.994,10.042-1.021,20.269-1.97,30.38
                c-1.26,13.429-2.116,26.895-3.275,40.333c-0.197,2.292-0.913,4.57-1.612,6.785c-4.03,12.769-5.885,25.9-7.272,39.186
                c-2.074,19.849-4.732,39.637-7.201,59.935C3.516,247.835,3.086,77.008,4.165,68.134z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(90)} fill={props.fillColors[90]} d="M1030.57,428.917c-4.085-1.137-6.534,0.345-9.077,2.003
                c-5.122-2.841-10.874-3.863-16.222-6.125c-3.709-1.569-8.047-1.771-12.142-2.285c-6.612-0.831-11.393-4.396-14.972-9.716
                c-1.795-2.669-3.717-5.136-6.479-6.809c-6.329-3.835-9.993-9.036-8.482-17.135c-3.78-0.123-5.075-2.812-6.553-5.413
                c-1.479-2.604-3.406-4.973-4.713-7.652c-1.734-3.559-4.326-5.847-8.115-6.779c-1.347-0.332-2.624-0.76-3.321-1.994
                c-1.716-3.036-4.372-4.436-7.563-4.282c-0.867-1.759-0.381-2.983,0.2-4.479c3.78-0.866,7.47-0.22,10.907,1.591
                c2.403,1.267,4.788,2.087,7.542,2.271c3.469,0.231,6.554,1.438,7.562,5.356c0.081,0.316,0.17,0.783,0.392,0.886
                c4.829,2.248,6.633,7.332,10.176,10.755c2.619,2.531,5.682,4.408,9.254,5.708c6.246,2.271,12.265,5.171,18.381,7.803
                c0.924,0.397,1.999,0.75,2.258,1.802c0.854,3.477,3.553,3.536,6.287,3.518c6.961-0.046,12.898,2.95,18.688,6.313
                c2.98,1.731,4.437,4.566,4.428,7.926C1028.991,417.498,1028.826,422.808,1030.57,428.917z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(91)} fill={props.fillColors[91]} d="M1168.994,375.183c-1.581-1.448-3.251-2.469-4.199-3.952
                c-1.92-3-4.607-3.83-7.735-3.123c-4.822,1.09-9.955,0.238-14.759,2.544c-2.33-1.351-5.037-2.321-5.741-5.573
                c-0.116-0.538-0.823-1.263-1.349-1.349c-4.742-0.774-7.487-5.482-12.727-5.983c1.322-3.37,2.319-6.325,6.408-6.741
                c1.757-0.179,2.807-1.744,3.543-3.264c1.199-2.477,3.129-3.586,5.809-4.113c3.077-0.605,5.887-1.694,7.978-4.573
                c1.525-2.1,4.305-3.127,7.069-3.031c2.707,0.094,5.079-0.31,7.632-1.535c3.229-1.55,7.016-1.375,9.898,1.086
                c4.549,3.883,9.907,6.122,15.104,8.84c3.026,1.584,5.97,2.044,8.963,3.014c2.723,0.882,5.391,2.133,8.29,2.668
                c0.84,0.155,1.6,0.667,1.963,1.425c1.594,3.318,4.506,4.178,7.822,4.372c1.193,0.069,2.301,0.369,3.107,1.384
                c0.063,1.791-1.549,1.968-2.628,2.525c-4.743,2.447-9.422,4.999-13.333,8.685c-1.486,1.401-3.189,1.611-5.031,1.704
                c-3.821,0.194-7.646,0.351-11.46,0.634C1178.585,371.202,1173.916,372.922,1168.994,375.183z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(92)} fill={props.fillColors[92]} d="M1146.475,248.657c-2.301-4.65-3.645-8.563-4.557-12.625
                c-0.924-4.121-2.469-7.862-5.322-11.121c-1.731-1.976-3.201-4.321-4.231-6.737c-1.474-3.457-3.827-5.469-7.495-5.638
                c-4.112-0.189-6.441-2.152-7.184-6.156c-0.118-0.642-0.422-1.27-0.738-1.852c-1.197-2.202-2.588-2.41-4.221-0.516
                c-0.951,1.104-1.672,2.407-2.793,4.058c-2.508-3.111-4.316-5.815-3.184-9.945c1.031-3.759,0.598-4.049-3.358-5.773
                c-0.458-0.2-0.965-0.342-1.354-0.637c-3.163-2.399-6.489-3.285-10.061-0.985c-4.243-8.422,0.836-21.721,13.999-25.49
                c-1.372,1.623-2.49,2.977-3.643,4.301c-1.717,1.972-1.91,4.224,0.201,5.673c4.674,3.209,8.154,6.989,8.411,12.97
                c4.89,4.617,8.464,10.617,14.456,14.057c3.905,2.242,7.797,4.45,11.234,7.407c4.128,3.552,8.812,5.769,14.365,6.292
                c5.43,0.51,10.312,2.869,14.518,6.214c6.568,5.225,13.693,9.468,21.312,12.86c4.396,1.957,9.018,3.403,13.523,5.114
                c5.223,1.982,9.893,4.776,13.35,9.298c0.495,0.647,1.137,1.239,0.977,2.193c-0.277,0.146-0.556,0.41-0.851,0.43
                c-5.769,0.391-11.056,1.997-15.603,5.76c-0.995,0.823-2.349,1.247-3.582,1.739c-3.607,1.439-6.299,0.731-8.762-2.277
                c-0.949-1.159-2.033-2.273-2.694-3.59c-2.671-5.316-7.249-8.554-12.108-11.548c-2.551-1.571-5.025-3.265-7.54-4.892
                c-3.55-2.296-4.968-2.197-7.755,0.829C1152.754,241.363,1148.911,243.886,1146.475,248.657z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(93)} fill={props.fillColors[93]} d="M850.877,500.804c-2.879,18.013-7.053,35.467-9.184,53.354
                c-1.913,1.04-3.773,1.639-5.76,1.882c-0.996,0.122-2.248,0.372-2.797-0.538c-1.951-3.229-5.607-2.555-8.402-3.77
                c-3.465-1.509-5.945,0.707-7.572,3.639c-6.151-4.592-7.059-4.564-11.789,0.187c-1.725-0.94-2.837-3.183-5.382-2.823
                c-1.595,0.225-2.692,0.989-3.777,2.651c-0.853-1.056-1.327-2.03-2.106-2.536c-2.151-1.395-4.139-0.88-5.582,1.223
                c-1.807,2.633-1.807,2.633-5.001,3.129c-0.258-2.344-1.481-4.129-4.403-4.967c6.578-6.559,11.967-13.3,17.092-20.275
                c1.334-1.816,3.459-3.106,5.358-4.442c7.503-5.274,15.444-9.848,23.409-14.376c1.001-0.569,2.098-1.027,3.206-1.34
                c5.038-1.426,9.536-3.857,13.746-6.939C844.438,503.027,846.998,501.166,850.877,500.804z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(94)} fill={props.fillColors[94]} d="M380.529,553.945c-4.103-0.76-7.07-2.232-10.242-4.333
                c-4.623-3.062-8.695-6.994-13.77-9.347c-2.25-1.043-4.573-1.93-6.985-2.645c-6.223-1.844-8.123-4.573-8.615-10.982
                c-2.116-27.561-4.575-55.084-9.495-82.323c-0.109-0.605-0.373-1.226,0.325-1.95c2.004,1.315,2.99,3.439,4.165,5.398
                c1.712,2.853,2.433,6.199,4.45,8.909c0.777,1.044,0.481,2.229,0.177,3.366c-1.007,3.762-0.036,7.26,1.44,10.677
                c3.042,7.038,7.5,13.343,10.505,20.417c1.259,2.963,4.345,3.296,7.228,3.576c1.496,0.145,2.909-0.797,4.823-0.164
                c1.649,2.703,3.462,5.67,5.271,8.641c1.083,1.779,1.391,3.743,1.215,5.77c-0.489,5.637,0.814,10.885,3.161,15.971
                c1.323,2.868,2.396,5.852,3.598,8.777c1.395,3.393,2.778,6.636,1.064,10.508C377.447,547.369,380.059,550.254,380.529,553.945z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(95)} fill={props.fillColors[95]} d="M44.308,81.214c-0.143-1.473,1.19-1.389,1.976-1.747
                c2.845-1.298,4.973-3.274,6.288-6.094c-2.814-4.825-3.802-5.43-8.709-4.63c-3.49,0.569-6.958,0.571-10.456,0.284
                c-3.33-0.274-6.558-0.275-9.77,1.184c-3.761,1.708-7.517-0.208-11.243-0.786c-1.919-0.298-0.779-2.752-2.217-3.64
                c-1.834-0.405-3.872,0.537-6.052-0.473c-0.782-5.186-0.295-10.406-0.284-15.87c6.539,1.252,12.556,2.484,18.603,3.549
                c18.074,3.181,36.038,2.685,54.132-0.559c20.525-3.678,41.269-5.572,62.161-3.437c6.375,0.651,12.79,1.21,20.022,3.358
                c-3.761,1.42-6.546,0.918-9.136,0.246c-6.567-1.704-13.226-1.306-19.875-1.34c-4.275-0.022-7.156,3.243-10.794,4.744
                c-1.769,0.73-0.126,3.139-1.23,4.795c-3.285,0.796-6.836,2.88-9.391-1.736c-0.192-0.348-0.838-0.491-1.303-0.632
                c-5.952-1.805-14.804,1.537-18.505,7.057c3.164,3.125,5.74-1.064,8.998-0.671c0.996,1.161,0.45,2.516,0.148,4.084
                c-4.29,0.333-8.354,1.03-12.238,2.585c-1.737,0.696-3.615,0.403-5.445,0.261c-7.581-0.592-14.59,0.713-20.395,6.099
                c-1.662,1.542-3.73,2.064-5.952,2.252c-3.151,0.267-6.292,0.661-9.438,1L44.308,81.214z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(96)} fill={props.fillColors[96]} d="M476.466,401.729c-3.258-8.771-5.841-16.996-8.21-25.033
                c0.311-1.183,1.051-1.586,1.771-2c8.221-4.724,16.386-9.552,24.683-14.138c8.325-4.602,16.936-5.998,26.422-3.229
                c10.678,3.117,21.767,4.642,32.739,6.719c-2.405,5.33-6.897,8.347-12.234,7.728c-5.632-0.653-10.403,0.752-14.881,4.22
                c-7.893,6.111-16.082,11.842-23.955,17.978c-3.104,2.42-6.233,3.485-10.119,2.996
                C486.881,396.239,481.386,397.038,476.466,401.729z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(97)} fill={props.fillColors[97]} d="M241.193,879.897c2.537,3.022,6.037,1.74,9.183,3.307
                c-4.41,1.973-5.843,7.307-10.954,7.233c-1.004,2.031,0.522,4.445-2.186,6.031c-1.714-0.368-3.917-0.84-5.935-1.273
                c-0.413-1.377,0.499-1.367,0.957-1.65c1.734-1.07,3.557-2.133,3.234-4.553c-0.315-2.369-2.129-3.193-4.232-3.284
                c-0.07-1.224,1.391-1.844-0.083-3.525c-5.705-1.554-11.92-4.075-18.178-6.961c8.557,1.454,16.706,0.028,25.055-1.956
                c-1.148-3.885-4.781-2.848-7.038-5.229c7.419-0.467,14.525,2.682,21.585,0.136c-0.374-0.892-0.435-1.395-0.63-1.453
                c-3.683-1.111-7.288-2.046-10.674-4.301c-2.601-1.73-6.356-1.693-9.742-1.193c-8.599,1.266-17.234,1.342-25.887,0.689
                c-0.664-0.05-1.344-0.018-1.992-0.146c-6.601-1.32-12.929-3.808-19.768-4.46c3.949-1.601,8.655,0.712,12.09-2.883
                c-0.427-2.8-2.794-3.083-4.754-3.752c-6.16-2.103-12.556-3.275-18.891-4.687c-1.245-0.277-2.675-0.123-3.846-1.88
                c1.419-0.139,2.602-0.295,3.789-0.363c5.323-0.308,10.653-0.513,15.969-0.917c2.597-0.197,5.36-0.174,7.637-2.664
                c-3.273-4.611-9.561-3.152-13.262-6.825c5.54-0.725,10.971-0.909,15.869,2.427c1.378,0.939,2.677,1.973,4.251,2.585
                c0.95,0.37,2.184,0.925,2.864,0.309c2.698-2.445,5.918-1.618,8.995-1.806c1.201-0.073,2.282-0.467,2.756-1.737
                c0.125-2.228-2.25-2.192-3.135-3.521c-0.251-0.785,1.032-1.213,0.745-1.629c-2.104-3.044,0.018-6.307-0.596-9.443
                c-0.418-2.139,1.573-3.036,3.304-4.193c1.593,2.773,2.609,5.814,4.961,7.979c0.232,0.214,0.548,0.688,0.464,0.842
                c-2.34,4.236,1.922,6.081,3.235,8.979c0.34,0.752,0.81,1.445,1.193,2.118c-0.193,0.367-0.269,0.742-0.495,0.902
                c-1.271,0.901-3.679,0.283-3.892,2.506c-0.202,2.115,1.668,3.091,3.064,4.24c0.384,0.316,0.797,0.615,1.24,0.838
                c2.767,1.393,5.61,3.979,8.728,1.655c3.256-2.426,6.763-5.122,5.862-10.128c-0.395-2.197,1.649-2.965,2.51-5.025
                c1.359,2.871,2.243,5.377,4.125,7.181c-2.367,2.671-2.367,2.671-0.84,7.228c-4.109,0.735-7.712,2.921-11.867,3.676
                c-3.772,0.687-8.029-0.609-11.616,2.447c1.405,3.189,4.251,2.233,6.47,2.553c2.291,0.33,4.628,0.344,6.51,0.467
                c1.368,0.83,0.385,2.238,1.511,2.891c3.94-0.65,7.719-1.738,11.472-3.154c2.93-1.104,6.304-3.146,8.956,0.953
                c0.315,1.023-1.38,1.312-1.069,2.684c1.109,1.988,3.726,2.273,5.566,3.854c-0.752,3.016-2.528,4.673-5.697,5.149
                c-2.505,0.377-4.843,1.23-5.346,4.374c-0.249,1.55-1.968,1.567-3.109,2.175C246.61,875.315,243.228,876.451,241.193,879.897z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(98)} fill={props.fillColors[98]} d="M467.457,675.23c-1.57-4.43-5.366-6.617-6.237-10.74
                c-0.49-2.314-2.52-2.305-4.047-0.291c-1.278,1.684-1.438,4.145-3.65,5.265c-2.743-1.522-2.089-6.22-6.465-6.636
                c-0.821,3.666-5.594,4.146-6.162,8.564c-3.396-6.232-2.976-6.761-8.228-4.583c-3.428-5.755,0.969-13.278-3.519-18.521
                c-2.455-0.459-2.758,1.496-3.808,2.318c-0.613,0.121-1.018-0.152-1.328-0.555c-1.747-2.269-3.818-4.228-6.246-5.698
                c-6.3-3.816-11.754-8.73-17.543-13.213c-1.388-1.075-2.304-2.11-2.417-4.031c-0.44-7.449-4.367-13.11-9.667-17.992
                c-1.069-0.985-2.603-1.569-2.828-3.283c3.573-1.089,5.595-1.179,7.753,1.711c4.167,5.579,9.617,9.43,15.138,13.382
                c2.412,1.727,4.475,3.933,7.151,5.377c1.135,0.612,2.3,1.533,2.943,2.617c1.17,1.974,2.967,2.524,4.946,2.93
                c3.144,0.644,6.082,1.521,8.054,4.438c1.015,1.501,2.847,1.531,4.566,1.374c2.032-0.187,3.958,0.146,5.817,1.044
                c4.184,2.023,8.185,4.47,12.815,5.482c0.762,0.166,1.61,0.687,2.083,1.302c1.898,2.472,4.315,3.546,7.403,3.227
                c0.482-0.05,0.991,0.099,1.481,0.188c3.143,0.566,4.816,3.753,7.481,4.615c2.784,0.9,5.853,0.645,7.861,3.43
                c0.98,1.361,2.51,2.209,4.146,2.662c3.912,1.083,6.566,4.275,10.111,5.977c0.519,0.249,0.784,1.029,1.384,1.868
                c-2.67,1.276-1.768,4.859-4.178,6.216c-1.464-0.014-1.832-1.02-2.332-1.852c-1.262-2.1-2.625-2.638-4.849-0.99
                c-2.163,1.602-4.394,0.81-8.192,1.213C473.858,672.367,469.68,670.506,467.457,675.23z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(99)} fill={props.fillColors[99]} d="M4.003,411.874c0-12.834,0-25.601,0-38.217c0.271-0.761,0.938-0.848,1.277-0.694
                c4.562,2.066,9.92,2.694,13.077,7.331c0.73,1.072,1.755,2.044,2.846,2.754c5.194,3.379,7.605,8.471,9.241,14.47
                c2.498,0.435,5.3,0.738,7.999,1.439c3.388,0.88,6.691,2.132,9.328,4.565c2.893,2.67,6.241,3.072,9.771,1.948
                c3.758-1.197,7.413-1.75,11.151-0.025c0.576,0.266,1.333,0.367,1.958,0.262c6.229-1.048,10.539,3.189,15.501,5.604
                c0.388,0.189,0.578,0.786,1.058,1.479c-1.598,1.883-2.989,3.54-5.746,4.58c-3.471,1.309-7.139,2.359-10.411,4.323
                c-2.832,1.701-6.45,1.106-9.005,0.272c-5.81-1.897-11.091-0.474-16.494,0.806c-2.402,0.569-4.997,0.463-7.201,1.869
                c-1.202,0.767-2.179-0.267-3.263-0.553c-3.511-0.928-6.766-1.966-9.032-5.306c-1.251-1.845-3.578-3.045-6.076-3
                c-2.889,0.053-5.503-1.154-8.039-2.098C9.405,412.739,6.849,412.34,4.003,411.874z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(100)} fill={props.fillColors[100]} d="M1235.287,521.021c-2.959,0.34-3.611-0.91-3.512-3.184
                c0.204-4.662,0.521-9.302,1.424-13.887c1.063-5.4,1.256-10.777,1.013-16.33c-0.665-15.138-0.478-30.285,2.222-45.296
                c1.155-6.423,1.76-12.707-2.056-18.84c-1.796-2.887-0.958-6.852-0.507-10.296c1.08-8.247,2.314-16.476,3.63-24.688
                c0.489-3.051,0.593-6.095-0.778-8.806c-1.323-2.619-0.996-5.076-0.533-7.704c0.633-3.604,1.412-7.213,1.623-10.849
                c0.191-3.328,1.299-6.034,3.291-8.637c3.847-5.024,7.557-10.154,11.856-14.811c1.799-1.949,3.608-3.953,6.662-4.387
                c0.589,2.397-0.453,4.581-0.891,6.76c-0.884,4.399-1.965,8.77-3.206,13.083c-2.627,9.133-4.548,18.289-5.047,27.891
                c-0.611,11.777-2.453,23.494-3.857,35.225c-0.559,4.662-0.082,9.315-0.107,13.966c-0.081,14.711-2.047,29.208-4.731,43.605
                c-2.778,14.903-5.073,29.851-6.019,44.99C1235.725,519.468,1235.494,520.097,1235.287,521.021z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(101)} fill={props.fillColors[101]} d="M897.223,377.428c-5.485-2.201-9.781,0.992-14.336,2.686
                c-5.051,1.878-6.391,6.856-9.028,11.133c-3.393-15.3-6.241-30.651-11.167-45.155c0.68-1.804,2.086-2.063,3.255-2.617
                c3.151-1.494,6.392-2.817,9.468-4.45c4.465-2.369,9.059-3.677,14.191-4.011c5.778-0.376,11.369-2.292,16.857-4.222
                c4.014-1.412,4.504-1.499,8.898-0.614C912.5,346.978,908.171,363.182,897.223,377.428z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(102)} fill={props.fillColors[102]} d="M821.746,356.624c5.306,3.192,7.459,7.521,8.854,12.166
                c1.128,3.755,3.085,6.558,6.546,8.344c8.252,4.26,13.156,11.817,17.4,19.417c4.927,8.823,8.468,18.422,11.092,28.266
                c0.904,3.393,1.345,6.765,0.666,10.331c-1.026,5.385-1.662,10.844-2.495,16.267c-0.145,0.939-0.132,2.001-1.401,2.66
                c-1.867-2.311-3.217-4.971-4.346-7.707c-1.67-4.044-4.515-7.006-8.24-9.053c-6.105-3.355-12.139-6.972-20.162-7.557
                c5.047-5.078,3.582-10.276,1.819-15.637c-3.405-10.355-5.111-21.039-4.714-31.931c0.247-6.802-1.154-13.199-3.18-19.562
                C823.041,360.916,822.531,359.191,821.746,356.624z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(103)} fill={props.fillColors[103]} d="M410.792,510.732c-0.91-6.012-1.75-11.562-2.582-17.061
                c1.708-1.084,2.767,0.047,3.848,0.582c1.342,0.665,2.559,1.563,4.186,1.329c4.059-0.585,6.878,1.669,8.642,4.746
                c3.749,6.545,9.899,10.012,16.039,13.603c0.428,0.25,1.096,0.383,1.259,0.747c2.01,4.491,6.961,6.405,9.25,10.653
                c1.078,2.003,2.746,3.561,5.126,3.451c3.5-0.161,6.262,1.938,9.401,2.826c2.436,0.689,4.882,2.238,6.579,4.379
                c3.037,3.83,7.203,5.535,11.708,6.93c5.618,1.74,10.683,4.857,14.789,8.902c2.237,2.203,3.833,5.224,6.077,7.687
                c2.191,2.405,5.968,3.221,6.875,6.87c0.06,0.238,0.495,0.494,0.791,0.547c4.488,0.795,7.991,3.082,10.653,6.775
                c0.633,0.879,1.669,0.709,2.734,0.591c2.459-0.274,4.958-0.455,7.423-0.334c1.75,0.086,3.761,0.501,2.593,3.665
                c-2.237-1.313-3.894-3.584-6.609-3.035c-2.714,0.547-3.523,3.334-5.467,5.096c-2.485-1.008-3.977-3.699-7.027-3.409
                c-2.952,0.28-3.911,2.839-5.894,4.564c-2.627-1.335-2.737-5.067-5.799-5.873c-2.863-0.754-5.167,0.61-7.416,1.669
                c-1.648-0.727-2.563-1.976-3.375-3.194c-1.354-2.032-2.753-3.758-5.518-3.565c-0.834,0.059-1.778-0.138-2.195-0.932
                c-1.584-3.02-4.571-4.127-7.252-5.795c-5.306-3.301-9.291-7.969-12.316-13.443c-1.197-2.166-3.043-3.714-5.463-3.977
                c-3.643-0.396-5.618-2.688-6.895-5.644c-1.175-2.719-3.258-4.446-5.227-6.488c-3.302-3.423-6.156-7.327-8.872-11.255
                c-2.8-4.051-6.454-6.791-11.262-7.123c-3.911-0.271-7.528-1.121-11.002-2.741C416.299,511.408,413.943,510.736,410.792,510.732z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(104)} fill={props.fillColors[104]} d="M1014.713,373.608c-2.518,1.244-4.349,3.156-6.004,5.255
                c-0.831,1.055-1.803,2.191-1.654,3.547c0.503,4.596-2.115,7.327-5.752,9.923c-4.513-1.991-9.19-4.089-13.897-6.12
                c-2.749-1.186-5.496-2.41-8.331-3.357c-4.456-1.489-7.842-4.285-10.52-8.028c-1.842-2.577-3.565-5.234-6.336-6.976
                c-0.531-0.333-1.082-0.861-1.305-1.425c-1.793-4.527-5.537-5.968-9.93-6.282c-2.049-0.146-3.916-0.626-5.646-1.74
                c-2.869-1.845-6.265-2.005-9.64-2.718c1.897-3.718,5.535-4.235,8.612-4.483c12.091-0.973,23.982-4.258,36.244-3.211
                c0.662,0.057,1.332,0.041,1.998,0.022c12.066-0.333,19.422,6.566,24.646,16.387
                C1010.207,370.06,1009.986,370.178,1014.713,373.608z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(105)} fill={props.fillColors[105]} d="M1189.139,374.027c5.049,0.568,10.294-1.956,15.531,1.153
                c-1.162,11.859-0.992,23.662-1.389,35.444c-0.318,9.509-1.8,18.871-2.652,28.308c-0.238,2.65-0.398,5.312-0.461,7.972
                c-0.443,18.968-0.849,37.938-1.279,56.907c-0.029,1.296-0.166,2.589-0.263,4.03c-3.518,0.125-6.045-2.084-9.585-2.559
                c1.665-11.84-1.176-23.18-1.217-34.557c-0.051-14.165-1.001-28.262-1.422-42.392c-0.094-3.175,0.014-6.333,0.434-9.465
                c1.712-12.748,1.396-25.578,1.667-38.385C1188.545,378.507,1188.326,376.492,1189.139,374.027z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(106)} fill={props.fillColors[106]} d="M1155.5,214.524c-7.524-0.149-13.939-2.335-19.167-7.556
                c-0.582-0.581-1.275-1.12-2.017-1.462c-7.658-3.545-13.445-9.441-19.384-15.187c-2.185-2.114-3.388-4.52-3.411-7.482
                c-0.021-2.618-1.109-4.353-3.465-5.541c-2.073-1.045-4.013-2.38-5.176-4.929c3.492-6.113,9.059-9.866,15.529-12.263
                c2.103-0.779,3.924-1.617,5.479-3.181c1.354-1.363,2.954-2.107,4.962-1.723c10.043,1.92,19.485,5.532,28.562,10.158
                c0.66,0.336,1.611,0.626,1.377,2.104c-4.302,0.94-8.711,0.443-13.121,0.057c-3.15-0.275-6.305-0.498-9.457-0.756
                c-5.529-0.452-9.845,2.125-13.959,5.272c0.458,1.814,1.823,1.693,2.852,1.687c4.819-0.028,9.13,1.585,13.29,3.804
                c0.88,0.469,1.78,0.935,2.722,1.245c10.461,3.436,14.428,11.947,17.186,21.574C1159.84,205.722,1157.45,210.013,1155.5,214.524z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(107)} fill={props.fillColors[107]} d="M1232.345,424.468c2.747,3.648,3.586,7.175,3.028,11.199
                c-0.983,7.079-1.914,14.171-2.578,21.285c-1.066,11.432-1.174,22.886-0.075,34.332c0.095,0.99,0.201,2.006,0.086,2.984
                c-0.947,8.055-2.958,15.96-2.855,24.382c-3.421-0.064-6.213,0.605-9.054-1.178c-2.357-1.479-5.157-0.357-7.821,0.169
                c-2.618-2.802-4.956-5.845-8.288-8.209c1.02-7.401,1.911-14.81,3.112-22.167c0.513-3.139,2.3-5.904,4.15-8.45
                c1.966-2.704,2.62-5.653,2.8-8.87c0.214-3.816,0.854-7.623,0.84-11.433c-0.023-6.626,1.822-12.777,4.006-18.903
                c0.453-1.272,0.959-2.487,1.748-3.557C1224.508,431.9,1228.07,428.22,1232.345,424.468z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(108)} fill={props.fillColors[108]} d="M710.367-0.935c1.539-1.032,3.229-0.52,4.812-0.524
                c15.324-0.042,30.648,0.046,45.971-0.057c5.736-0.039,11.173,1.562,16.736,2.479c0.485,0.08,1.064,0.175,1.402,0.482
                c6.066,5.518,14.24,6.035,21.416,8.848c7.129,2.794,13.653,6.944,21.062,9.056c0.901,0.257,2.108,0.95,2.365,1.713
                c1.293,3.841,4.703,5.421,7.559,7.633c1.329,1.03,2.303,2.338,2.428,4.181c0.136,1.987,1.4,3.374,2.998,4.37
                c4.957,3.089,6.584,8.411,8.755,13.277c0.974,2.182-0.159,4.126-1.767,6.229c-1.871,2.448-5.689,4.25-4.19,8.773
                c0.547,1.65-1.977,3.284-3.448,3.854c-5.932,2.293-9.471,7.131-13.219,11.723c-1.529,1.875-2.812,1.993-5.033,0.445
                c3.014-2.607,4.426-6.349,7.446-8.865c0.502-0.418,0.867-1,1.365-1.424c1.854-1.575-1.498-3.364,0.231-4.931
                c1.461-1.325,2.416-2.784,1.203-4.79c-0.383-0.633-0.217-1.098,0.229-1.739c2.426-3.492,2.389-3.517-1.17-6.381
                c1.86-2.06,1.385-3.972-0.302-6.071c-1.883-2.344-4.433-4.332-4.02-8.082c0.167-1.514-1.806-2.655-3.271-3.503
                c-1.748-1.011-3.315-2.125-4.171-4.113c-1.229-2.86-3.683-4.238-6.644-4.618c-1.721-0.221-3.226-0.716-4.519-1.869
                c-6.408-5.716-13.946-8.546-22.433-9.785c-7.049-1.029-13.934-3.17-20.896-4.792c-8.082-1.883-15.832-5.121-24.201-5.84
                c-1.666-0.144-3.146-1.001-4.641-1.758c-2.889-1.462-5.956-1.945-9.2-1.528C718.504,2.063,715.65,1.482,710.367-0.935z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(109)} fill={props.fillColors[109]} d="M255.294,474.987c-5.475-0.747-8.975-3.798-12.919-5.838
                c-2.794-1.446-6.033-2.316-7.893-5.32c-0.485-0.784-1.397-1.317-1.658-2.358c-0.531-2.124-1.865-3.58-4.041-3.952
                c-2.743-0.47-3.249-2.496-3.599-4.706c-2.034-12.823-4.067-25.646-6.094-38.469c-1.871-11.838-4.03-23.641-5.515-35.527
                c-1.405-11.245-1.147-22.59-0.719-33.905c1.886-0.342,2.006,1.179,2.473,2.039c1.824,3.362,3.632,6.745,5.197,10.231
                c1.512,3.367,2.067,6.918,1.967,10.691c-0.146,5.484,0.252,10.983,0.384,16.476c0.034,1.409,0.396,2.571,1.608,3.444
                c2.488,1.792,3.371,4.429,3.729,7.336c0.224,1.817,0.542,3.623,0.811,5.435c0.836,5.621,2.075,10.935,5.85,15.624
                c4.002,4.973,5.979,11.021,7.211,17.354c2.008,10.321,5.819,20.128,9.213,30.045C252.529,467.176,253.807,470.748,255.294,474.987
                z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(110)} fill={props.fillColors[110]} d="M689.441,459.882c3.982-0.812,9.002,0.259,13.836-1.152
                c1.609-0.47,3.198-0.866,4.895-0.443c2.055,0.511,3.852,1.23,3.857,3.799c0,0.165,0.048,0.329,0.074,0.493
                c2.678,16.822,3.956,11.803-6.351,23.849c-6.199,7.246-13.529,13.244-21.327,18.694c-1.253,0.876-2.427,1.675-4.088,1.399
                c-4.424-0.73-8.859-1.391-13.293-2.068c-2.74-0.419-4.194-2.035-5.302-4.59c-2.356-5.436-1.81-10.668,0.181-15.974
                c0.67-1.786,1.666-3.574,3.498-3.97c5.734-1.24,7.357-6.107,9.688-10.389c0.555-1.018,0.912-2.142,1.443-3.174
                C679.375,460.872,682.404,459.226,689.441,459.882z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(111)} fill={props.fillColors[111]} d="M645.292,413.391c-3.991,3.863-4.911,8.818-6.534,13.529
                c-2.972,8.625-7.065,16.834-9.706,25.597c-0.644,2.135-2.333,3.59-3.708,5.256c-4.273,5.173-4.363,8.317-0.52,13.731
                c1.18,1.662,1.979,3.444,1.996,6.006c-4.941,1.408-9.982,2.858-15.034,4.273c-1.118,0.313-2.261,0.567-3.409,0.74
                c-6.851,1.03-11.248,5.022-12.89,11.58c-2.127,8.493-8.809,12.973-14.895,18.059c-1.803,1.507-3.712,1.771-6.031,1.045
                c-2.673-0.836-5.494-1.2-8.297-1.779c-1.418-3.771,4.739-4.529,2.41-8.118c4.616-0.704,8.43-1.448,10.204-5.664
                c0.61-1.45,1.594-1.762,3.184-1.576c2.623,0.306,4.423-1.187,4.993-3.665c0.897-3.902,4.458-6.147,5.743-9.795
                c0.589-1.672,2.433-0.445,3.533-1.088c0.984-1.044,1.22-2.401,1.408-3.676c0.698-4.738,2.078-8.98,6.423-11.799
                c1.139-0.737,2.159-2.018,2.429-3.646c0.365-2.198,0.319-4.33,2.931-5.856c1.2-0.702,1.249-3.991,1.192-6.091
                c-0.104-3.896,1.521-7.486,1.59-11.331c0.023-1.313,0.601-2.588,1.546-3.604c3.986-4.282,7.087-9.234,10.531-13.926
                c1.216-1.657,2.556-3.051,4.824-3.105c4.661-0.111,8.74-1.793,12.416-4.614C642.375,413.295,643.266,412.704,645.292,413.391z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(112)} fill={props.fillColors[112]} d="M769.368,500.676c-1.952-3.912-5.019-1.232-8.254-1.127
                c-1.153-8.43-6.622-13.939-12.741-19.361c-4.223-3.741-5.821-9.018-3.576-14.976c2.219-5.887,4.973-11.278,10.319-14.895
                c7.295-4.934,13.999-10.735,21.787-14.935c1.802-0.972,3.776-2.873,5.898-0.984c2.313,2.06,4.888,4.133,4.224,7.919
                c-2.438,13.922-4.979,27.823-10.746,40.856c-1.892,4.274-3.789,8.515-4.229,13.226
                C771.885,498.18,771.176,499.561,769.368,500.676z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(113)} fill={props.fillColors[113]} d="M1173.938,377.03c0.235,1.604,0.489,2.577,0.505,3.553
                c0.223,13.688,4.076,26.565,8.75,39.257c0.813,2.211,1.362,4.475,1.46,6.807c0.479,11.48,1.22,22.962,1.229,34.444
                c0.006,8.35,0.469,16.579,1.65,24.847c0.865,6.056,0.617,12.247-0.284,18.8c-3.217-0.903-4.377-3.863-7.18-4.944
                c1.533-3.953,0.841-7.265-1.531-10.475c-1.137-1.539-1.751-3.633-2.09-5.564c-2.067-11.807-6.021-23.181-8.033-34.981
                c-1.109-6.503-3.592-12.87-2.361-19.682c0.621-3.436,1.051-6.906,1.566-10.36c0.42-2.813,0.86-5.695-0.193-8.4
                c-2.309-5.927-2.199-12.059-1.925-18.23c0.052-1.164,0.317-2.353,0.164-3.487C1164.839,382.492,1168.576,379.635,1173.938,377.03z
                "/>
            <path fill-rule="evenodd" onClick={() => props.onFill(114)} fill={props.fillColors[114]} d="M852.227,515.184c0.746-4.654,1.391-8.75,2.061-12.842
                c0.811-4.945,2.285-9.631,4.428-14.219c4.049-8.668,5.445-18.123,7.182-27.436c2.777-14.889,4.667-29.902,6.168-44.973
                c0.896-9.003,3.795-17.506,6.927-25.955c2.417-6.521,8.273-7.649,13.772-9.378c1.461-0.46,2.309,0.958,3.259,2.193
                c-2.587,4.186-5.063,8.508-7.847,12.623c-4.008,5.924-6.371,12.346-6.986,19.478c-0.57,6.631-1.322,13.246-2.037,19.864
                c-0.748,6.906,0.08,13.656,2.372,20.159c2.008,5.7,0.974,11.537,1.332,17.309c0.055,0.879-0.362,1.584-0.88,2.262
                c-4.095,5.355-6.416,11.626-9.059,17.723c-0.951,2.194-2.235,3.904-4.253,5.329c-5.095,3.597-8.729,8.629-12.52,13.488
                C855.045,512.221,854.369,514.018,852.227,515.184z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(115)} fill={props.fillColors[115]} d="M56.001,82.194c0.237,4.09-0.29,8.013-0.496,11.95
                c-0.619,11.817-3.193,23.45-3.571,35.262c-0.437,13.644-1.106,27.277-1.567,40.914c-0.223,6.593,1.003,13.26,1.863,19.856
                c0.735,5.637,0.887,11.251,0.819,16.928c-0.199,16.656-0.051,33.312,0.516,49.964c0.305,8.971,0.451,17.859-1.374,26.831
                c-1.644,8.078-1.713,16.476-2.872,24.921c-1.344-3.237-3.877-5.305-6.12-7.677c-1.641-1.736-2.285-4.109-1.939-6.87
                c1.391-11.095,1.74-22.237,0.473-33.385c-0.531-4.67-0.324-9.334,0.481-13.94c0.751-4.289,1.314-8.629,1.03-12.923
                c-0.613-9.279,1.203-18.189,3.217-27.124c1.14-5.059,1.381-10.203,1.19-15.394c-0.814-22.142-1.004-44.28,0.636-66.401
                c0.32-4.318,0.456-8.651,0.84-12.963c0.39-4.381,0.368-8.615-1.927-12.605c-1.145-1.989-0.969-4.071,0.299-6.128
                c1.92-3.116,3.646-6.352,5.481-9.521C53.476,83.036,53.992,82.141,56.001,82.194z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(116)} fill={props.fillColors[116]} d="M658.762,489.373c-9.061-4.146-18.086-6.353-26.959-9.039
                c-1.784-0.54-2.739-1.57-3.033-3.478c-0.407-2.638-1.117-5.203-3.13-7.195c-2.386-2.361-2.162-5.044-0.791-7.802
                c0.369-0.742,0.7-1.583,1.285-2.124c4.65-4.3,6.147-10.148,7.857-15.875c1.943-6.51,6.01-12.129,7.397-19.419
                c2.478,1.911,3.232,4.505,5.544,5.645c1.28,0.63,0.904,2.352,0.977,3.674c0.214,3.934,0.515,4.284,4.032,5.034
                c4.715,1.006,7.09,3.751,7.18,8.557c0.093,4.893,1.33,9.336,4.338,13.29c1.955,2.567,2.961,5.366,0.797,8.418
                c-0.27,0.379-0.409,0.961-0.369,1.43C664.462,477.34,659.053,482.353,658.762,489.373z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(117)} fill={props.fillColors[117]} d="M264.498,397.175c3.707,5.66,7.144,10.162,6.784,16.527
                c-0.252,4.459,0.407,8.964,0.525,13.452c0.046,1.734,0.626,3.22,1.311,4.747c2.174,4.854,4.162,9.795,6.461,14.588
                c2.663,5.55,3.735,11.429,3.393,17.442c-0.373,6.562,1.26,11.6,7.667,14.514c1.913,0.869,3.455,2.569,5.138,3.924
                c1.481,1.191,2.413,2.77,2.771,4.616c1.201,6.179,2.345,12.369,3.552,18.771c-2.511,0.678-4.272-0.013-5.739-1.879
                c-1.251-1.59-2.878-2.802-4.676-3.679c-3.309-1.613-6.365-3.569-9.219-5.896c-1.144-0.932-2.479-1.989-3.854-2.213
                c-4.719-0.769-7.744-4.336-11.555-6.577c-2.13-1.253-3.013-3.194-3.004-5.841c0.093-26.785,0.067-53.569,0.082-80.354
                C264.136,398.992,264.241,398.666,264.498,397.175z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(118)} fill={props.fillColors[118]} d="M395.632,627.482c-2.022-0.399-3.75-1.391-5.283-2.608
                c-3.969-3.153-8.431-5.323-13.162-7.024c-1.881-0.678-3.777-1.361-5.294-2.731c-4.064-3.671-8.967-5.69-14.126-7.186
                c-3.712-1.076-7.16-2.661-10.369-4.826c-3.472-2.343-7.18-4.224-11.314-5.12c-1.483-0.321-3.015-0.709-4.121-1.701
                c-4.357-3.908-9.769-6.369-14.021-10.424c-3.16-3.012-6.949-5.129-10.821-7.113c-3.67-1.881-7.406-3.676-11.024-6.328
                c3.53-2.182,6.641-3.942,10.163-0.719c0.871,0.797,2.036,1.271,3.232,1.125c3.155-0.385,5.831,0.221,8.106,2.705
                c1.142,1.247,2.769,0.455,4.22-0.055c2.812-0.99,5.374-1.389,8.601-0.27c2.692,0.933,6.059,1.609,8.958,1.453
                c5.49-0.296,9.011,2.939,12.938,5.614c1.782,1.214,3.379,2.708,5.003,4.14c3.259,2.871,6.599,5.523,11.04,6.422
                c1.837,0.371,3.455,1.49,4.12,3.337c0.896,2.487,2.734,3.767,5.105,4.307c2.727,0.622,4.387,2.229,5.222,4.811
                c0.695,2.148,2.172,3.614,3.871,5.058C392.796,615.549,395.612,621.066,395.632,627.482z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(119)} fill={props.fillColors[119]} d="M706.804,427.326c-4.475-3-9.542-1.256-14.421-1.748
                c-1.234-0.124-2.267,0.366-3.324,0.877c-2.696,1.305-5.396,2.602-8.115,3.861c-4.031,1.867-6.185,5.188-8.014,9.13
                c-2.271,4.897-5.598,9.081-11.176,10.853c-1.971-3.119,0.955-7.125-2.646-9.796c-2.657-1.972-4.953-4.27-8.259-3.862
                c-1.417-1.105-1.076-2.342-1.101-3.473c-0.048-2.268-0.786-3.966-2.705-5.529c-5.037-4.104-5.087-6.739-1.503-11.882
                c5.205-7.468,12.447-10.438,21.18-9.583c9.607,0.941,19.168,2.367,28.728,3.732c2.11,0.301,4.206,1.06,6.181,1.904
                C709.201,415.046,710.635,419.141,706.804,427.326z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(120)} fill={props.fillColors[120]} d="M372.542,315.057c-3.147-2.298-5.913-4.406-8.775-6.373
                c-1.416-0.973-1.648-2.312-1.916-3.828c-1.272-7.22-2.134-14.491-2.641-21.795c-1.406-20.258-2.738-40.522-4.027-60.788
                c-0.151-2.372-0.723-4.585-1.497-6.785c-1.481-4.211-2.969-8.421-4.615-13.09c1.821-0.084,2.419,1.686,3.999,1.529
                c2.129-1.409,2.819-3.818,2.806-6.328c-0.036-7.006,0.501-13.977,1.145-20.935c0.358-3.873-1.493-7.033-2.758-10.252
                c0.825-1.254,2.076,0.631,2.927-1.363c-1.868-4.126-3.964-8.757-6.221-13.743c3.979-1.152,7.295-0.178,10.677,1.554
                c1.518,5.103,1.35,10.473,2.084,15.708c1.042,7.43,1.045,14.933,0.934,22.43c-0.038,2.543-0.626,4.967-1.493,7.301
                c-1.497,4.027-1.532,8.095-0.972,12.283c2.382,17.814,4.537,35.651,5.834,53.588c0.383,5.296,1.418,10.547,2.204,15.812
                c0.369,2.474,0.56,4.897,0.295,7.435c-0.354,3.398,0.874,6.658,2.604,9.488c1.99,3.254,2.231,6.514,1.374,10.033
                C373.884,309.51,374.065,312.267,372.542,315.057z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(121)} fill={props.fillColors[121]} d="M508.883,462.6c-4.296-12.237-15.448-18.947-20.577-30.264
                c3.672-3.099,6.746-6.509,9.435-10.336c2.015-2.868,5.365-4.415,8.402-6.15c3.656-2.09,7.088-4.37,8.714-8.552
                c0.287-0.739,0.841-1.54,1.498-1.944c5.43-3.344,10.11-7.511,13.84-12.82c2.081,0.996,3.551,2.464,5.99,2.441
                c-3.379,11.634-3.826,23.596-9.094,34.344c-0.875,1.784-1.293,3.792-2.178,5.571c-2.311,4.645-2.656,9.227,0.438,14.115
                C520.414,453.888,514.907,457.822,508.883,462.6z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(122)} fill={props.fillColors[122]} d="M52.863,481.579c-2.896-1.856-5.839-2.612-9.12-1.963
                c-0.476,0.095-1.249,0.251-1.433,0.02c-2.465-3.099-6.707-2.447-9.603-4.639c-2.347-1.776-5.061-1.911-7.981-2.093
                c-3.746-0.232-7.717-0.602-11.061-2.723c-1.339-0.85-2.642-1.331-4.189-1.26c-1.87,0.085-3.627-0.294-5.229-1.281
                c-0.814-4.844-0.697-42.332,0.15-46.14c4.275-0.071,5.273,3.615,6.705,6.351c1.119,2.138,2.004,3.677,4.676,3.475
                c0.744-0.057,1.948,0.297,2.231,0.835c1.069,2.029,2.825,3.085,4.704,4.138c1.599,0.896,1.158,3.074,2.608,4.469
                c2.967-0.061,6.204-0.004,7.297,3.937c3.623,1.064,4.474,3.919,4.386,7.177c2.151,0.984,4.775,0.184,6.143,2.208
                c2.403,3.557,7.096,5.938,5.964,11.373c-0.318,1.525,0.091,2.968,0.858,4.312c0.33,0.578,0.971,1.244,0.873,1.755
                C50.152,475.115,52.336,478.029,52.863,481.579z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(123)} fill={props.fillColors[123]} d="M7.876,324.106c-1.246-1.289-1.425-3.169-2.993-3.998
                c-1.033-0.546-1.131-1.577-1.132-2.589c-0.009-11.799-0.006-23.598-0.006-35.345c0.594-0.099,1.046-0.298,1.163-0.176
                c3.046,3.187,6.535,3.901,10.728,2.453c1.964-0.678,3.847,0.049,5.329,1.794c2.046,2.409,6.143,2.81,6.675,6.807
                c0.044,0.329,1.103,0.676,1.722,0.749c3.129,0.369,5.172,2.167,6.446,4.889c1.223,2.612,3.612,4.478,5.56,6.077
                c2.492,2.046,3.515,4.265,4.098,6.996c0.529,2.475,1.342,4.022,4.293,4.52c3.928,0.662,6.93,3.837,10.864,4.878
                c1.713,0.454,1.681,2.401,1.55,3.815c-0.149,1.609-3.515,3.98-5.209,3.858c-3.647-0.262-7.295-0.555-10.931-0.951
                c-4.003-0.436-7.9-0.438-11.814,0.888c-3.095,1.048-5.422-0.223-6.76-3.068c-2.331-4.959-9.306-7.152-14.348-4.426
                C11.378,322.214,9.82,323.48,7.876,324.106z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(124)} fill={props.fillColors[124]} d="M927.871,314.666c0.113,3.876,0.045,7.054-1.795,9.983
                c-2.264,3.604-2.57,7.637-1.515,11.554c0.63,2.336,0.304,4.22-0.577,6.227c-1.938,4.419-3.711,8.915-6.117,13.119
                c-1.852,3.234-2.613,6.703-2.936,10.455c-0.695,8.11-1.859,16.181-2.828,24.241c-1.006,0.551-1.682,1.056-2.441,1.314
                c-4.383,1.489-6.228,4.809-6.445,9.139c-0.146,2.891-0.867,5.58-2.03,8.179c-4.353,9.725-5.156,20.271-7.103,30.554
                c-0.031,0.164-0.051,0.33-0.062,0.496c-0.486,6.893-3.371,12.389-9.889,16.236c-1.73-4.367-3.188-8.851-3.258-13.626
                c-0.177-12.04,1.762-23.896,3.514-35.753c0.234-1.59,1.063-3.172,1.914-4.578c4.394-7.268,8.507-14.746,13.424-21.647
                c8.659-12.155,14.451-25.405,17.286-40.019c0.696-3.591,1.652-7.132,2.524-10.688c0.604-2.467,1.569-4.791,3.568-6.453
                C925.685,321.255,925.512,317.659,927.871,314.666z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(125)} fill={props.fillColors[125]} d="M569.056,365.394c-3.244,8.136-12.019,10.425-15.912,17.097
                c-11.022-2.308-18.497,3.75-25.323,10.333c-3.711,3.579-7.009,7.533-11.485,10.232c-1.465,0.884-2.725,2.057-3.304,3.645
                c-1.238,3.399-3.91,5.292-6.839,6.964c-5.093,2.906-9.91,6.018-12.982,11.349c-1.191,2.066-3.182,3.789-5.81,5.074
                c-3.994-8.45-6.979-17.049-10.083-25.694c2.175-3.628,5.397-5.337,9.46-5.632c1.161-0.084,2.376-0.267,3.488-0.031
                c6.292,1.331,11.188-1.171,15.977-4.988c7.292-5.812,14.955-11.154,22.372-16.811c3.801-2.899,7.899-4.21,12.619-3.237
                c3.941,0.812,7.201-0.305,9.91-3.243c0.674-0.732,1.486-1.336,2.223-2.011C560.04,362.335,560.039,362.334,569.056,365.394z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(126)} fill={props.fillColors[126]} d="M423.187,548.288c-4.406-11.745-10.34-22.45-11.818-34.855
                c2.165-1.366,4.15-0.298,6.061,0.56c3.962,1.778,7.943,3.037,12.414,3.112c3.791,0.063,6.793,2.61,8.96,5.513
                c3.537,4.735,6.708,9.777,11.302,13.577c2.317,1.917,2.863,4.782,4.544,7.032c1.47,1.968,2.774,3.475,5.369,3.889
                c3.074,0.489,5.713,1.839,6.307,5.438c0.166,1.009,0.85,1.758,1.771,2.291c0.708,0.41,1.719,0.799,1.96,1.437
                c2.077,5.498,7.189,7.354,11.662,10.005c0.837,0.496,1.775,1.097,2.239,1.896c1.365,2.354,3.159,3.598,5.981,3.774
                c2.507,0.157,2.917,2.873,4.582,4.405c-1.213,0.951-2.236,1.754-3.455,2.709c-1.591-1.59-0.657-4.496-3.203-5.288
                c-2.312-0.719-4.255-0.438-5.635,1.543c-3.724-1.575-5.531-5.027-8.826-6.749c-3.073-1.606-5.849-3.512-9.491-3.839
                c-2.715-0.245-4.735-2.289-6.582-4.14c-1.834-1.838-3.951-3.316-6.359-3.701c-5.128-0.818-9.347-3.44-13.915-5.562
                C433.035,549.467,428.682,547.066,423.187,548.288z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(127)} fill={props.fillColors[127]} d="M398.455,337.85c-4.45-3.073-10.405-2.297-15.061-5.495
                c-1.685-1.157-3.219-2.035-3.661-4.342c-0.652-3.403-2.976-5.874-5.79-8.068c1.298-6.918,2.593-13.755,3.441-20.674
                c0.592-4.835,0.611-9.617,0.308-14.443c-0.574-9.136-1.125-18.273-1.644-27.413c-0.07-1.233-0.367-2.547,0.511-4.351
                c1.24,1.931,2.207,3.398,3.135,4.889c3.868,6.215,7.442,12.634,12.481,18.034c1.06,1.137,1.525,2.541,2.074,3.938
                c5.991,15.24,5.894,15.231,4.994,31.917c-0.428,7.944-1.764,15.908-0.165,23.879C399.205,336.354,399.221,337.043,398.455,337.85z
                "/>
            <path fill-rule="evenodd" onClick={() => props.onFill(128)} fill={props.fillColors[128]} d="M1080.561,444.988c-3.189,8.338-5.701,16.398-5.59,25.042
                c0.033,2.52-0.68,4.74-1.727,7.102c-1.977,4.455-1.655,9.451-2.084,14.247c-0.312,3.478,0.382,7.376-1.326,10.311
                c-2.304,3.956-2.133,7.977-1.967,12.052c0.162,3.972,0.842,7.938,1.497,11.872c1.104,6.632,1.215,13.259,0.222,19.896
                c-0.474,3.16-0.459,6.257,0.084,9.43c0.935,5.459,0.105,10.969,0.062,16.457c-0.018,2.254-1.615,3.012-3.857,3.58
                c-2.127-6.83,0.903-13.677-0.854-20.32c-1.683-6.357-0.655-12.985-0.229-19.923c-3.038,1.099-3.331,2.805-3.604,4.39
                c-1.044,6.059-2.391,12.082-1.438,18.326c0.296,1.941,0.296,4.062-0.159,5.959c-1.405,5.855-1.758,11.832-2.376,17.945
                c-2.189,1.365-4.395,2.74-7.029,4.382c-0.449-2.612-0.18-4.868,0.232-7.056c0.81-4.31,0.88-8.655,0.283-12.934
                c-1.025-7.347-0.744-14.623,0.533-21.869c0.698-3.961,0.961-7.916,0.946-11.924c-0.021-5.717,0.89-11.23,3.462-16.449
                c1.992-4.041,3.419-8.336,3.903-12.841c0.335-3.107,1.484-5.806,3.403-8.17c2.291-2.824,3.208-6.104,3.851-9.599
                c1.98-10.768,4.039-21.522,6.192-32.257C1074.026,447.485,1075.65,445.922,1080.561,444.988z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(129)} fill={props.fillColors[129]} d="M1157.297,215.483c1.393-3.518,2.417-6.688,3.883-9.64
                c1.297-2.61,1.928-5.215,1.98-8.119c0.06-3.315,0.435-6.634,0.842-9.93c0.671-5.42-0.723-10.201-4.076-14.467
                c-0.709-0.903-1.332-1.874-1.969-2.776c0.771-1.527,2.016-1.137,3.112-0.974c4.679,0.697,8.327,3.291,11.376,6.684
                c5.661,6.301,11.297,12.578,14.986,20.419c2.178,4.631,5.449,8.74,8.095,13.163c3.883,6.49,3.702,7.694-1.397,13.003
                c-1.498,1.559-2.955,3.16-4.484,4.687c-1.438,1.435-1.779,3.52-2.906,5.181c-6.016-2.581-11.625-5.382-16.557-9.426
                C1166.446,220.225,1162.393,217.625,1157.297,215.483z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(130)} fill={props.fillColors[130]} d="M742.252,473.37c2.072,5.784,5.584,9.479,9.755,12.63
                c5.377,4.062,6.124,10.375,8.173,16.394c-2.736,0.639-5.035,2.382-7.716,0.464c-3.181,2.602-5.773,5.774-10.032,6.229
                c-1.051,0.112-1.7,1.09-2.065,2.013c-0.882,2.228-2.652,3.802-3.781,5.61c-1.268,0.607-1.944-0.802-3.194-0.378
                c-0.739,0.352-1.484,1.122-1.714,2.083c-0.549,2.302-1.945,3.941-3.468,5.676c-3.586,4.086-6.672,8.602-10.59,12.416
                c-0.756,0.734-1.52,1.225-2.619,1.152c-3.605-0.238-6.376,1.527-8.713,3.747c-1.631,0.536-2.584-1.111-4.303-0.665
                c-1.483,0.527-1.417,2.617-2.891,3.989c-3.93-0.5-6.539,3.722-11.023,3.681c1.034-2.292,2.405-4.231,4.297-5.387
                c4.734-2.893,8.739-6.957,14.043-8.902c2.283-0.837,4.121-2.108,5.465-4.12c3.514-5.26,6.765-10.675,9.884-16.182
                c2.349-4.147,1.821-8.483,0.942-12.646c-1.884-8.923,1.967-15.116,8.679-20.151C734.688,478.54,738.141,476.253,742.252,473.37z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(131)} fill={props.fillColors[131]} d="M454.581,525.381c4.909-0.771,9.648-1.6,14.391,0.051
                c0.604,0.209,1.377,0.312,1.965,0.126c5.114-1.612,8.502,1.45,11.616,4.563c1.608,1.609,3.469,1.899,5.339,2.354
                c3.92,0.955,7.789,1.877,10.441,5.304c0.629,0.812,1.52,1.492,2.573,1.253c2.924-0.664,4.54,1.048,6.389,2.896
                c2.771,2.769,6.608,3.704,10.223,4.807c3.066,0.935,5.793,1.921,8.033,4.552c2.247,2.642,5.249,4.633,9.1,4.547
                c1.549-0.035,3.062,0.56,4.163,1.52c4.511,3.936,9.919,5.657,15.628,6.745c0.325,0.062,0.712,0.096,0.946,0.29
                c4.704,3.912,10.8,3.758,16.216,5.582c0.496,0.167,0.862,0.434,0.964,1.449c-3.323,1.686-7.325-0.106-10.798,1.547
                c-3.406,1.621-7.04,2.764-10.563,4.112c-3.781-1.927-7.474-4.126-11.983-3.391c-0.901,0.147-1.609-0.232-2.342-0.626
                c-2.16-1.157-4.393-1.104-6.791-0.799c-2.909,0.368-5.902,0.51-7.83-2.637c-0.559-0.912-1.738-1.671-2.792-2.016
                c-6.484-2.125-10.273-7.732-15.339-11.678c-0.517-0.402-1.073-0.873-1.356-1.439c-3.903-7.803-11.44-10.545-18.792-13.525
                c-3.513-1.425-6.972-2.711-9.663-5.719c-4.367-4.883-10.34-6.918-16.574-8.233C456.668,526.789,455.345,527.256,454.581,525.381z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(132)} fill={props.fillColors[132]} d="M55.634,385.206c-2.023-3.847-4.498-5.772-8.227-5.337
                c-1.134,0.132-1.766-0.808-2.389-1.571c-3.797-4.653-9.211-4.839-14.528-5.265c-2.079-0.166-3.468,0.981-4.422,2.684
                c-1.049,1.871-2.011,3.79-3.235,6.115c-3.542-1.831-4.552-6.522-8.511-7.306c-3.654-0.724-6.266-3.748-10.284-4.024
                c0-15.99,0-31.737,0-48.553c1.498,2.888,2.8,4.815,4.761,6.271c1.738,1.29,1.506,3.591,1.779,5.544
                c0.938,6.722,1.302,6.986,7.84,8.088c4.442,0.749,9.084,2.369,12.221,5.083c3.885,3.363,6.75,7.905,11.413,10.664
                c1.525,0.903,1.592,3.216,1.787,5.032c0.323,3.011,1.504,5.419,4.297,6.855c0.442,0.228,1.066,0.399,1.255,0.774
                c1.366,2.716,2.791,4.978,5.819,6.61C57,377.833,56.858,381.542,55.634,385.206z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(133)} fill={props.fillColors[133]} d="M338.6,610.533c-0.447-3.239-2.931-4.57-5.765-5.955
                c-4.052-1.98-8.01-4.245-11.251-7.549c-2.741-2.795-5.962-4.885-10.032-4.93c-1.504-0.016-2.355-0.618-3.391-1.576
                c-3.511-3.254-6.848-6.817-12.208-7.047c-1.369-0.059-3.328-0.686-3.529-2.346c-0.442-3.647-2.454-6.31-5.087-8.767
                c2.43-1.353,4.196-0.456,5.888,0.657c4.598,3.027,9.494,5.513,14.37,8.039c2.964,1.535,5.917,3.151,8.313,5.481
                c4.453,4.333,10.101,7.029,14.778,11.053c1.09,0.938,2.602,1.581,4.023,1.9c4.805,1.079,9.046,3.287,13.058,6.031
                c2.923,1.999,6.214,3.164,9.562,4.188c4.969,1.519,9.736,3.405,13.643,7.004c1.63,1.503,3.729,2.157,5.756,2.91
                c4.23,1.568,7.991,3.996,11.923,6.154c10.157,5.574,18.186,14.014,27.973,20.035c2.855,1.756,5.263,4.012,6.895,6.883
                c-2.006,3.466-4.332,6.382-6.957,9.167c-3.386-6.025-6.656-11.875-9.99-17.688c-0.173-0.303-0.885-0.297-1.409-0.453
                c-2.724,1.735-1.37,4.924-2.786,7.626c-2.162-3.157-3.9-6.253-5.978-9.097c-1.905-2.606-1.076-7.001-5.532-8.573
                c-1.379,3.007-4.538,4.353-5.821,7.644c-4.326-2.54-8.539-4.502-10.63-9.086c-0.398-0.871-1.083-1.914-1.893-2.245
                c-3.658-1.492-6.598-4.392-9.972-5.886c-4.211-1.865-7.603-5.349-12.366-6.1c-1.852-0.291-2.873-2.183-4.155-3.467
                C342.976,611.487,342.999,611.465,338.6,610.533z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(134)} fill={props.fillColors[134]} d="M610.455,440.249c-2.168,5.317-0.064,11.316-4.212,16.031
                c-0.798,0.908-1.098,2.472-1.163,3.757c-0.103,2.034-1.209,3.828-2.483,4.699c-4.616,3.158-5.79,7.855-6.691,12.812
                c-0.087,0.479-0.289,0.936-0.548,1.748c-2.901-0.271-4.222,1.738-5.211,4.133c-1.327,3.21-4.579,5.282-5.156,8.995
                c-0.188,1.215-1.597,1.659-2.834,1.553c-2.484-0.214-3.975,0.869-5.146,3.054c-1.632,3.043-4.326,4.175-8.294,3.133
                c-0.812,4.006-4.75,6.508-5.142,11.143c-3.896,1-7.805,2.297-12.311,2.359c-1.722-2.771-3.461-5.572-5.405-8.699
                c2.037-4.859,5.07-8.518,10.421-10.453c3.391-1.227,5.399-4.729,8.264-6.938c0.357-0.275,0.475-1.239,0.709-1.241
                c4.642-0.046,6.627-4.253,10.01-6.223c1.276-0.743,2.063-1.595,2.52-2.865c0.562-1.563,1.14-3.132,1.549-4.739
                c1.401-5.508,4.985-10.059,6.702-15.428c0.194-0.606,0.643-1.542,1.068-1.598c3.591-0.47,5.172-4.445,8.972-4.797
                c2.823-0.261,4.897-2.006,6.244-4.972C603.639,442.807,605.814,440.074,610.455,440.249z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(135)} fill={props.fillColors[135]} d="M1010.066,431.23c6.947,0.412,8.799,1.729,10.211,7.128
                c1.005,1.323,2.838,0.739,3.965,1.62c0.089,0.417,0.262,0.746,0.204,1.027c-0.867,4.274-0.975,8.586-0.869,12.934
                c0.214,8.828,0.298,17.659,0.463,26.489c0.031,1.65-0.174,3.318,0.524,4.924c3.301,7.578,4.109,15.737,5.484,23.764
                c0.281,1.641,0.172,3.495,0.924,4.88c2.068,3.802,1.975,7.647,0.947,11.606c-2.16,8.327-0.512,16.557,0.57,24.799
                c0.802,6.1,2.166,12.153,1.346,18.368c-0.169,1.282,0.115,2.704-1.252,3.839c-3.195-1.938-6.445-3.908-9.713-5.891
                c0-4.838-0.098-9.496,0.021-14.15c0.259-10.218-0.688-20.288-3.329-30.18c-0.907-3.399-1.283-6.873-1.347-10.383
                c-0.314-17.494-1.027-34.971-2.156-52.431c-0.301-4.645-0.612-9.292-1.082-13.921c-0.337-3.329-1.26-6.516-2.975-9.456
                C1011.118,434.68,1010.069,433.164,1010.066,431.23z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(136)} fill={props.fillColors[136]} d="M448.875,320.202c1.164-0.946,2.651-0.929,4.062-1.12
                c3.793-0.515,7.581-1.083,11.381-1.536c6.963-0.831,13.685-2.505,20.386-4.641c6.183-1.971,12.698-2.205,19.089-0.159
                c3.278,1.049,5.017,0.542,6.573-2.518c1.87-3.677,4.798-4.229,8.372-3.701c6.994,1.033,13.982,2.111,20.99,3.044
                c2.562,0.341,5.006-0.107,6.655-2.472c1.454-2.085,3.591-3.067,6.004-3.062c4.905,0.01,9.857-1.923,14.741,0.577
                c1.437,0.736,3.29,0.16,4.894-0.37c3.815-1.258,7.793-1.726,11.723-2.423c3.271-0.58,6.472-1.015,8.909-3.833
                c1.469-1.699,4.11-2.145,6.072-1.447c3.91,1.391,7.882,0.942,11.816,1.179c7.958,0.479,15.942,0.802,23.689-1.742
                c0.937-0.308,1.922-0.618,2.721-1.166c2.792-1.916,5.924-2.402,9.086-1.788c3.906,0.759,7.015,0.438,9.678-3.101
                c1.99-2.647,5.336-2.661,8.3-1.653c2.856,0.972,5.639,1.113,8.251-0.177c6.311-3.116,13.215-3.463,19.94-4.016
                c5.062-0.417,10.165-1.568,15.388-0.477c2.617,0.547,5.192-0.653,7.201-2.721c3.118-3.209,6.797-3.541,11.037-2.429
                c6.506,1.707,13.204,0.741,18.936-2.092c4.708-2.327,9.582-3.783,14.449-5.266c7.309-2.227,14.852-3.674,22.271-5.548
                c2.372-0.599,4.639-0.62,6.806,0.499c3.296,1.702,6.498,1.271,9.542-0.457c3.596-2.041,7.421-2.76,11.499-2.804
                c4.448-0.048,8.843-1.113,13.354,0.175c2.461,0.703,4.768-0.367,6.652-2.472c4.628-5.172,6.812-5.581,12.403-1.975
                c3.008,1.939,5.771,1.707,8.391-0.038c4.642-3.092,10.213-3.955,15.011-6.668c2.519-1.425,5.491-1.216,8.334-0.924
                c4.107,0.422,7.555-0.479,9.902-4.271c1.422-2.295,3.931-2.992,6.413-3.474c8.985-1.747,17.979-3.444,26.969-5.164
                c1.144-0.219,2.339-0.312,3.415-0.715c6.691-2.512,13.4-4.848,20.648-5.259c2.523-0.143,4.629-1.771,6.865-2.875
                c3.391-1.673,6.826-2.557,10.516-0.709c0.994,0.498,2.285,0.749,3.385,0.624c3.793-0.432,7.574-1.014,11.336-1.673
                c2.011-0.353,3.776-1.289,4.951-3.084c1.54-2.354,3.804-3.595,6.432-4.438c1.729-0.554,3.352-1.456,4.993-2.26
                c2.114-1.037,4.315-1.989,6.657-1.977c9.215,0.05,17.979-3.149,27.113-3.646c2.017-0.11,3.801-1.125,5.569-2.089
                c6.993-3.813,13.901-7.78,21.507-10.365c3.529-1.199,6.854-2.167,10.679-1.149c3.204,0.853,5.862-0.506,7.405-3.63
                c1.508-3.051,3.847-4.762,7.729-4.873c-0.989,2.613-3.678,2.188-4.766,3.836c-1.006,1.522-1.805,3.273-3.101,4.491
                c-2.139,2.012-4.832,3.036-7.81,2.104c-2.888-0.904-5.52-0.281-8.279,0.611c-7.807,2.523-14.873,6.561-22.008,10.465
                c-1.769,0.969-3.566,2.132-5.503,2.269c-8.952,0.631-17.554,3.683-26.588,3.697c-2.516,0.004-4.958,0.683-7.233,1.759
                c-2.406,1.137-4.716,2.467-7.302,3.209c-1.667,0.479-2.866,1.631-3.863,3.021c-0.78,1.088-1.692,2.035-2.871,2.734
                c-3.203,1.898-15.462,3.587-18.942,2.175c-3.455-1.402-6.559-0.861-9.549,0.862c-4.146,2.388-8.539,3.671-13.318,3.652
                c-2.041-0.008-4.078,0.439-5.796,1.362c-7.094,3.811-15.054,4.061-22.653,5.764c-5.356,1.201-10.785,2.08-16.173,3.148
                c-2.331,0.462-4.478,1.292-5.849,3.443c-2.334,3.659-5.906,4.822-9.945,4.408c-4.666-0.479-8.781,0.59-12.944,2.726
                c-3.81,1.955-8.021,3.072-11.805,5.242c-2.938,1.685-5.956,1.28-8.938-0.678c-4.601-3.022-6.233-2.808-9.741,1.414
                c-2.862,3.445-6.35,3.68-10.361,3.258c-7.619-0.802-15.192-0.988-22.314,2.724c-3.395,1.769-6.975,2.188-10.563,0.381
                c-2.056-1.036-4.116-0.872-6.31-0.348c-10.189,2.435-20.379,4.867-30.412,7.898c-0.637,0.192-1.384,0.285-1.866,0.683
                c-5.421,4.468-12.196,3.921-18.511,5.062c-1.869,0.338-3.619-0.207-5.36-0.842c-3.435-1.254-6.55-0.811-9.163,1.915
                c-2.923,3.048-6.566,3.328-10.561,3.273c-10.802-0.148-21.59,0.203-31.887,4.098c-3.187,1.206-6.312,1.851-9.711,0.631
                c-3.021-1.083-5.876-0.872-7.953,2.156c-2.086,3.042-5.326,3.386-8.497,2.775c-3.815-0.735-7.009,0.113-10.536,1.664
                c-4.362,1.919-9.171,3.211-14.017,3.186c-6.466-0.035-12.928-0.58-19.407-0.4c-0.951,0.026-1.938-0.424-2.872-0.754
                c-3.118-1.104-5.879-0.742-8.16,1.834c-1.285,1.451-2.962,1.983-4.836,2.309c-5.407,0.942-10.855,1.688-16.127,3.317
                c-1.594,0.493-3.462,0.96-4.909,0.174c-4.702-2.553-9.475-0.422-14.197-0.525c-1.899-0.042-3.547,0.782-4.673,2.452
                c-1.085,1.609-2.622,2.705-4.554,2.715c-4.924,0.027-9.929,0.447-14.693-1.134c-3.987-1.323-8.175-0.867-12.214-1.778
                c-1.829-0.413-3.213,1.082-4.224,2.878c-2.149,3.822-4.583,4.735-8.676,3.528c-6.931-2.043-13.821-1.694-20.521,0.711
                c-10.474,3.76-21.472,4.372-32.335,5.827c-0.439,0.059-0.931-0.208-1.384-0.363C449.094,320.843,449.065,320.629,448.875,320.202z
                "/>
            <path fill-rule="evenodd" onClick={() => props.onFill(137)} fill={props.fillColors[137]} d="M94.285,332.841c-1.666-0.232-2.845-1.415-4.006-2.292
                c0-6.622-0.201-13.108,0.039-19.578c0.66-17.821,0.581-35.613-0.923-53.398c-0.434-5.132-0.361-10.317-0.333-15.477
                c0.109-19.846-2.834-39.518-3.389-59.306c-0.356-12.716-3.377-24.644-7.772-36.373c-1.789-4.772-1.978-9.744-2.331-14.719
                c-0.47-6.62-0.974-13.238-1.492-20.245c3.73,1.051,4.876,4.373,7.266,6.226c6.625,0.19,8.633,2.2,9.323,8.903
                c1.335,12.977,0.684,25.924-0.134,38.898c-0.742,11.772-2.585,23.509-1.468,35.364c1.499,15.905,2.965,31.813,4.447,47.719
                c0.108,1.16,0.316,2.316,0.337,3.476c0.464,25.971,1.448,51.935,1.076,77.916C94.913,330.888,95.254,331.939,94.285,332.841z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(138)} fill={props.fillColors[138]} d="M1114.547,204.768c4.922,7.515,5.185,15.605,6.331,23.42
                c0.71,4.838,1.815,9.419,4.49,13.59c1.887,2.942,3.276,6.162,4.529,9.432c1.44,3.758,2.165,7.35,1.29,11.598
                c-1.275,6.191-0.453,12.626,0.375,18.887c0.415,3.138-0.213,5.469-2.373,7.661c-1.631,1.654-3.039,3.53-4.52,5.329
                c-1.535,1.865-3.417,3.083-6.412,3.451c-0.325-4.986-0.931-9.877-0.875-14.76c0.049-4.337-0.92-8.213-3.166-11.841
                c-2.133-3.442-3.1-7.164-3.75-11.2c-2.305-14.29-1.845-28.515,0.076-42.759c0.484-3.585,0.076-7.394,2.372-10.579
                C1113.392,206.336,1113.878,205.682,1114.547,204.768z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(139)} fill={props.fillColors[139]} d="M164.716,534.855c-4.933,1.697-9.93,3.236-14.768,5.172
                c-1.866,0.748-3.105,0.39-4.571-0.576c-1.666-1.099-3.414-2.075-5.144-3.074c-7.401-4.273-15.321-6.328-24.351-5.314
                c0.229-4.116-2.203-6.494-4.646-9.277c-1.769-2.014-4.249-3.994-5.121-6.289c-0.946-2.491-2.438-4.611-3.415-7.051
                c0.086-0.271,0.116-0.62,0.288-0.871c2.035-2.975,5.087,0.236,7.362-1.188c2.426-1.518,4.684-1.526,6.772,0.57
                c1.097,1.1,2.504,1.036,3.751,0.713c2.476-0.644,3.568,2.772,5.908,1.918c2.014-0.735,3.797-2.063,5.959-2.475
                c1.677-0.318,3.308-0.354,4.8,0.68c2.105,1.459,4.445,2.179,7.002,2.373c2.226,0.168,4.122,1.129,5.759,2.697
                c1.438,1.377,2.984,2.649,4.55,3.881c1.217,0.957,2.479,2.029,2.494,3.574c0.027,2.975,1.129,5.102,3.037,7.474
                c1.533,1.906,2.177,4.673,4.839,6.034C165.055,534.169,164.885,534.512,164.716,534.855z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(140)} fill={props.fillColors[140]} d="M785.957,373.457c0.49-3.215-0.805-5.461-1.465-7.803
                c-0.74-2.628-0.783-5.09-0.041-7.815c1.473-5.402-0.15-10.579-1.479-16.327c-3.688,2.518-2.58,6.404-5.035,9.293
                c1.018-8.1,0.934-15.672,0.154-22.697c1.493-1.491,2.65-1.518,3.82-1.327c2.459,0.4,4.924,0.791,7.361,1.302
                c3.18,0.667,3.711,1.371,3.764,4.748c0.081,5.332,0.105,10.665,0.195,15.996c0.064,3.798-0.41,7.657,1.848,11.149
                c0.933,1.443,0.473,3.228,0.354,4.897c-0.461,6.485-0.768,12.906,1.434,19.269c1.585,4.58,1.408,9.558,1.67,14.354
                c0.261,4.792,3.605,7.743,5.635,11.477c0.639,1.176,2.028,0.947,3.177,0.991c1.817,0.069,3.661-0.363,5.893,0.457
                c1.542,6.078,4.872,12.183,1.165,19.232c-5.033,1.367-10.75,0.881-15.875,3.879c-1.154-2.673-2.225-5.019-0.238-7.534
                c1.014-1.284,0.681-2.551-0.209-3.658c-3.162-3.932-3.49-8.474-3.07-13.234c0.315-3.588-0.374-6.919-2.588-9.906
                c-1.752-2.363-2.184-5.289-2.25-8.073c-0.085-3.569-1.439-6.438-3.544-9.112c-2.903-3.688-4.921-7.913-7.243-11.953
                c-0.409-0.712-0.762-1.504-0.005-2.452C782.041,369.19,782.766,372.529,785.957,373.457z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(141)} fill={props.fillColors[141]} d="M192.571,582.343c-1.415-1.682-1.287-3.401-1.552-5.024
                c-0.783-4.781-1.758-5.35-6.198-3.739c-0.599,0.218-1.25,0.294-1.684,0.393c-2.198-1.075-2.003-3.167-2.688-4.794
                c-0.644-1.531-1.127-3.342-3.219-3.289c-1.856,0.047-2.182,1.809-2.813,3.123c-0.415,0.864-0.742,1.771-1.298,3.119
                c-2.927-2.567-6.166-4.511-8.164-7.332c-5.17-7.303-9.913-14.91-15.709-22.377c4.953-1.793,9.37-3.967,14.033-4.869
                c2.959-0.572,6.06-0.507,8.938-1.73c0.415-0.177,1.087-0.172,1.464,0.049c3.941,2.319,8.341,1.633,12.583,2.119
                c4.293,0.49,6.309,3.014,6.95,7.654c0.112,0.812,0.104,1.866,0.599,2.363c3.112,3.121,1.526,7.283,2.541,10.855
                c0.478,1.682-0.779,3.178-1.102,5.097c1.096,1.926,2.309,4.056,3.634,6.386c-1.728,2.344-1.121,5.996-4.68,7.573
                C193.288,578.326,193.181,580.566,192.571,582.343z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(142)} fill={props.fillColors[142]} d="M443.944,434.283c-5.47-1.013-9-3.384-10.848-8.324
                c-1.392-3.721-3.326-7.281-5.364-10.708c-2.148-3.612-2.657-7.346-2.45-11.462c1.262-25.154,0.685-50.31-0.187-75.462
                c-0.171-4.918-1.929-9.425-3.456-14.002c-0.557-1.668-1.666-3.227-1.419-5.258c3.166,1.069,4.638,3.168,5.364,6.153
                c1.02,4.191,1.959,8.42,3.915,12.343c0.905,1.817,0.702,3.916,0.698,5.912c-0.011,5.499,0.029,11.001-0.141,16.496
                c-0.075,2.456,0.334,4.582,2.005,6.453c4.247,4.757,5.706,10.267,4.951,16.627c-0.465,3.916-0.862,8.014,1.198,11.744
                c7.08,12.82,7.635,26.663,6.439,40.787C444.411,428.392,444.193,431.203,443.944,434.283z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(143)} fill={props.fillColors[143]} d="M350.164,399.721c2.775,1.714,4.21,3.789,5.618,5.717
                c2.375,3.253,5.453,5.343,9.203,6.485c4.922,1.5,9.004,4.061,12.076,8.304c1.649,2.277,3.87,4.08,5.999,5.96
                c4.458,3.936,8.025,9.125,8.842,14.688c2.123,14.448,3.06,29.07,4.478,43.705c-2.03,0.075-2.127-1.33-2.664-2.202
                c-4.011-6.517-7.689-13.262-12.045-19.536c-4.471-6.441-7.334-13.401-8.502-21.043c-0.763-4.995-3.433-8.12-7.946-9.982
                c-1.226-0.506-2.373-1.201-3.559-1.805c-6.309-3.211-10.765-7.468-10.432-15.404C351.428,409.903,350.591,405.155,350.164,399.721
                z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(144)} fill={props.fillColors[144]} d="M292.94,506.693c-1.612,0.096-3.202,0.271-4.79,0.267
                c-2.529-0.008-5.149,0.081-7.323,1.257c-3.243,1.754-5.792,0.496-7.438-1.818c-3.869-5.441-9.578-8.137-15.129-11.109
                c-6.181-3.309-12.207-6.812-17.48-11.494c-4.053-3.598-8.967-5.644-13.889-7.728c-7.479-3.167-15.088-6.185-21.079-11.959
                c-1.667-1.608-3.758-1.015-6.097-1.678c-1.177-4.45-4.821-6.575-9.816-7.808c1.187-4.791-4.018-8.157-2.772-13.146
                c1.269-0.059,2.131,0.491,2.9,1.106c6.104,4.879,13.267,7.363,20.718,9.183c1.317,0.322,2.804,0.567,3.579,1.611
                c2.41,3.246,5.799,4.885,9.474,5.874c5.125,1.378,6.734,6.281,9.969,9.524c1.338,1.342,2.397,2.352,4.389,2.768
                c3.259,0.684,5.974,2.81,8.855,4.446c2.795,1.587,6.383,2.148,7.535,5.886c0.119,0.388,0.715,0.811,1.15,0.892
                c6.12,1.14,9.349,6.663,14.379,9.459c1.289,0.716,2.328,1.946,3.809,2.317c7.315,1.832,12.355,7.582,18.81,10.891
                C292.863,505.521,292.819,506.022,292.94,506.693z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(145)} fill={props.fillColors[145]} d="M228.354,545.322c-3.341,0.385-5.581-2.783-8.663-2.202
                c-2.966,0.56-5.44,2.297-7.521,3.923c-1.701,0.035-2.529-0.828-3.376-1.61c-1.397-1.292-2.904-2.024-4.925-1.92
                c-2.838,0.146-5.38-1.64-8.264-1.558c-0.412,0.012-0.876-0.416-1.25-0.722c-6.485-5.3-6.483-5.303-13.9-5.508
                c-1.761-2.372-3.558-4.944-5.52-7.383c-1.244-1.546-1.403-3.038-0.162-4.497c3.437-4.043,2.208-8.781,1.933-13.307
                c-0.077-1.27-0.613-2.458-0.137-3.815c0.607-1.736,0.147-3.762-1.29-4.47c-2.481-1.223-3.639-3.962-5.733-5.004
                c-3.057-1.521-2.892-4.028-3.812-6.277c4.309-2.63,9.549-2.262,13.441,1.151c2.388,2.093,4.656,4.36,7.611,5.54
                c2.958,1.18,1.801,4.504,3.632,6.15c1.847,1.659,3.988,3.025,3.333,6.131c-0.542,2.573,1.992,4.992,0.578,7.725
                c-0.16,0.31,0.107,1.113,0.427,1.357c3.987,3.029,6.433,8.174,12.157,8.832c3.292,0.377,5.513,2.936,8.101,4.543
                c4.396,2.729,8.219,6.383,12.268,9.666C228.183,542.799,229.146,543.553,228.354,545.322z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(146)} fill={props.fillColors[146]} d="M1266.91,328.352c-1.785,10.146-2.116,19.743-2.22,29.371
                c-0.041,3.818-1.071,7.574-2.669,11.109c-1.391,3.08-1.93,6.309-2.07,9.691c-0.619,14.981-0.939,29.993-2.167,44.927
                c-1.433,17.418-3.434,34.834-9.841,51.321c-3.292,8.471-4.685,17.304-5.912,26.219c-0.863,6.264-2.016,12.488-3.04,18.729
                c-0.134,0.812-0.258,1.638-1.525,1.841c0.771-13.671,2.291-27.184,4.888-40.572c3.908-20.148,6.52-40.388,5.971-61.012
                c-0.256-9.647,2.037-19.186,3.071-28.784c0.339-3.146,0.89-6.302,0.859-9.448c-0.085-8.953,1.962-17.5,4.47-25.983
                c1.938-6.552,3.473-13.204,4.75-19.906C1262.079,332.683,1263.709,330.477,1266.91,328.352z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(147)} fill={props.fillColors[147]} d="M476.636,72.105c1.631,5.911,6.222,8.143,11.684,9.491
                c6.139,1.515,11.58,5.072,17.831,6.335c0.875,0.177,1.43,0.817,1.71,1.655c2.793,8.351,8.781,13.975,16.049,18.468
                c-0.204,4.565,0.968,8.615,2.479,12.751c1.365,3.734,0.254,7.789-1.311,11.471c-0.511,1.203-1.742,2.125-2.151,3.341
                c-2.009,5.972-5.773,10.642-10.206,14.979c-1.311,1.283-2.452,2.559-4.15,3.353c-3.939,1.843-7.584,4.216-10.971,6.918
                c-3.812,3.04-8.013,5.347-12.376,7.452c-11.108,5.357-21.832,11.438-32.537,17.544c-2.491,1.421-5.029,2.468-7.854,3.048
                c-10.596,2.175-20.564,5.689-28.125,13.998c-0.112,0.123-0.219,0.27-0.36,0.344c-11.152,5.887-20.694,14.691-33.119,18.261
                c-0.247,0.071-0.578-0.15-0.885-0.239c-0.646-1.559,0.825-1.677,1.554-1.925c7.82-2.663,14.634-7.096,21.437-11.651
                c5.237-3.507,10.587-6.741,15.455-10.861c6.758-5.719,15.223-8.078,23.778-9.805c1.986-0.4,3.869-0.894,5.632-1.907
                c12.419-7.14,24.937-14.094,37.851-20.315c2.553-1.23,4.919-2.757,7.175-4.505c2.497-1.935,4.992-4.071,7.839-5.316
                c5.721-2.501,9.287-7.393,13.741-11.304c1.071-0.941,1.569-2.137,1.931-3.418c0.642-2.27,1.555-4.449,2.99-6.283
                c1.82-2.327,2.563-4.902,2.964-7.769c0.334-2.384,0.37-4.529-0.785-6.745c-1.648-3.162-1.914-6.685-1.979-10.278
                c-1.356-1.018-2.685-2.006-4.004-3.007c-4.592-3.484-8.427-7.646-10.571-13.046c-1.044-2.628-2.697-3.791-5.354-4.496
                c-6.426-1.706-12.251-5.11-18.75-6.754c-3.91-0.99-6.337-4.095-7.957-7.803c-2.719-0.348-5.337-0.842-7.974-0.991
                c-9.377-0.533-17.882-3.732-25.908-8.363c-2.191-1.264-4.479-2.117-6.864-2.896c-5.851-1.909-11.531-4.439-17.478-5.934
                c-11.943-3.001-24.07-5.177-36.432-5.534c-8.02-0.231-15.945-1.246-23.804-2.733c-15.395-2.913-30.977-4.492-46.487-6.54
                c-13.045-1.723-26.01-3.967-39.054-5.66c-11.559-1.5-23.087-2.99-34.427-5.919c-4.933-1.274-10.257-0.978-15.383-1.574
                c-4.13-0.481-8.24-1.169-12.335-1.896c-1.456-0.259-3.21-0.513-4.199-1.441c-4.281-4.017-9.763-3.851-14.947-4.651
                c-7.409-1.144-14.818-2.274-22.067-4.215c-4.098-1.098-7.941-2.745-9.874-6.941c-0.867-1.883-2.612-1.853-4.192-2.211
                c-10.235-2.322-20.581-4.054-30.937-5.723c-2.444-0.394-4.88-0.832-7.354-1.663c1.651-0.886,3.444-0.741,5.22-0.492
                c11.876,1.667,23.658,3.864,35.374,6.417c1.277,0.278,2.634,0.542,3.205,2.045c1.617,4.259,5.339,5.683,9.268,6.803
                c6.423,1.831,13.039,2.684,19.596,3.828c2.952,0.515,5.938,0.83,8.904,1.262c2.635,0.384,5.112,0.845,7.208,2.937
                c2.056,2.049,5.095,2.364,7.882,2.812c3.78,0.606,7.584,1.349,11.392,1.44c13.265,0.318,25.838,4.696,38.89,6.31
                c28.257,3.494,56.37,8.083,84.654,11.411c6.107,0.719,12.137,2.09,18.212,3.099c6.426,1.068,12.912,1.344,19.419,1.437
                c6.696,0.096,13.122,2.208,19.757,2.854c7.172,0.698,14.067,2.534,20.677,5.434c1.216,0.533,2.5,0.924,3.773,1.311
                c6.05,1.837,11.954,3.947,17.488,7.134c7.83,4.508,16.567,5.855,25.434,6.519C473.419,71.87,474.908,71.978,476.636,72.105z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(148)} fill={props.fillColors[148]} d="M222.399,356.292c-3.613-7.379-7.26-13.703-11.407-19.725
                c-0.719-1.045-0.764-2.131-0.613-3.328c2.354-18.719,0.476-37.216-2.826-55.657c-1.474-8.232-1.144-12.123,1.06-20.155
                c0.804,0.08,1.131,0.619,1.151,1.333c0.411,14.407,3.445,28.344,7.093,42.229c2.07,7.879,1.191,16.265,3.205,24.214
                c0.501,1.979,0.219,4.358,2.361,5.726c1.619-1.38,1.123-3.169,1.216-4.744c0.424-7.151,0.64-14.316,1.145-21.46
                c0.454-6.427-0.913-12.592-2.245-18.776c-0.736-3.416-1.579-6.81-2.428-10.2c-0.736-2.938-1.159-5.927-0.757-8.916
                c1.076-7.991,0.679-15.942-0.096-23.927c-0.333-3.438-0.056-6.935-0.056-10.409c4.539,1.844,7.883,6.775,7.898,11.83
                c0.033,10.997,0.01,21.994,0.01,32.991c0,19.995,0.098,39.991-0.058,59.984C227.003,343.601,226.414,349.927,222.399,356.292z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(149)} fill={props.fillColors[149]} d="M884.506,470.539c0-3.301,0.012-6.602-0.008-9.902
                c-0.006-1.068,0.352-1.942,1.153-2.642c0.125-0.109,0.218-0.262,0.354-0.351c8.326-5.427,9.723-14.18,10.834-22.963
                c1.217-9.619,3.232-18.999,7.158-27.905c0.826-1.875,0.967-3.854,1.023-5.855c0.106-3.692,1.809-6.106,5.266-7.667
                c4.257-1.923,8.43-3.052,12.977-1.229c2.289,0.918,4.077,2.085,4.01,5.01c-0.041,1.806,0.43,3.624,0.68,5.435
                c0.525,3.793,1.023,7.578,3.736,10.628c0.619,0.697,1.074,1.639,0.955,3.097c-3.919-1.542-7.912-1.211-11.814-1.682
                c-4.848-0.584-6.402,0.052-8.041,4.476c-3.465,9.357-6.506,18.864-8.679,28.605c-0.673,3.019-1.741,5.664-3.604,8.083
                c-2.938,3.812-6.133,7.441-8.061,11.96c-1.297,3.041-4.684,1.85-6.754,3.414C885.297,470.881,884.9,470.71,884.506,470.539z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(150)} fill={props.fillColors[150]} d="M580.536,460.317c-2.84,4.278-3.509,8.78-5.23,12.88
                c-0.584,1.392-1.651,2.146-2.622,3.152c-3.001,3.109-5.771,6.244-10.264,7.089c-0.852,2.31-3.425,3.034-4.637,5.085
                c-0.701,1.185-1.668,2.562-3.605,1.818c-0.821-0.315-1.908,0.09-2.23,0.748c-1.867,3.813-6.763,4.887-7.932,9.556
                c-1.699-0.926-2.195-2.353-2.956-3.487c-1.848-2.762-3.639-5.562-5.393-8.386c-2.76-4.443-5.769-8.575-11.295-9.438
                c-0.341-1.818,0.904-2.367,1.652-3.109c4.13-4.098,8.326-8.127,12.485-12.195c3.177-3.107,6.76-3.946,10.956-2.242
                c3.537,1.436,7.067,3.485,10.735,3.83c3.732,0.351,6.81-3.061,10.501-4.05C573.732,460.754,576.692,459.572,580.536,460.317z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(151)} fill={props.fillColors[151]} d="M1200.184,543.617c-0.403,0.772-0.441,0.984-0.563,1.059
                c-2.78,1.68-1.41,6.109-4.563,7.297c-3.004,1.131-4.667,3.479-6.833,5.513c-1.903,1.788-4.444,3.429-6.865,4.374
                c-2.352,0.919-3.952,2.117-4.722,4.127c-2.07-0.273-1.688-1.479-1.639-2.377c0.146-2.661,0.391-5.316,0.609-7.973
                c0.344-4.164,0.424-8.294-0.619-12.411c-0.736-2.907-0.837-5.92-0.988-8.924c-0.588-11.64-1.277-23.274-1.938-34.911
                c-0.057-0.996-0.009-2.045-0.303-2.977c-2.487-7.887-1.337-15.828-0.435-24.176c1.319,1.506,1.363,3.117,1.705,4.644
                c1.195,5.339,1.835,10.856,5.179,15.499c1.8,2.501,0.345,5.135-0.172,7.862c3.149,2.608,4.435,6.13,4.871,10.029
                c0.153,1.362,0.565,2.606,1.474,3.644c2.014,2.302,3.146,5.092,4.293,7.855c0.841,2.028,1.629,4.054,3.736,5.104
                c1.035,0.516,1.188,1.455,1.545,2.396c0.937,2.475,1.934,4.877,3.603,7.059C1199.144,538.404,1198.092,541.582,1200.184,543.617z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(152)} fill={props.fillColors[152]} d="M343.341,312.128c-3.305-3.759-4.964-7.138-4.31-12.045
                c1.354-10.153,2.638-20.343,0.614-30.672c-1.566-7.99-2.857-16.04-4.515-24.019c-1.165-5.607-1.135-5.614,0.487-12.158
                c2.074-0.496,1.938,2.614,3.855,2.392c1.127-0.61,0.801-1.702,0.768-2.606c-0.666-18.13-1.407-36.258-2.004-54.391
                c-0.077-2.336-0.982-4.187-2.171-5.972c-2.773-4.164-4.224-8.583-3.314-13.645c0.23-1.283,0.254-2.617-0.287-3.926
                c-1.718-4.15-3.215-5.118-7.926-5.104c-1.626,0.005-3.324,0.481-5.03-0.57c-0.762-2.179-0.116-4.195,2.223-4.411
                c5.236-0.485,10.481-2.14,16.121-0.454c0.439,2.063,1.073,4.037,2.981,5.696c2.428,2.11,2.965,5.269,2.956,8.622
                c-0.091,34.293-0.05,68.587-0.049,102.881c0,2.658-0.1,5.289,0.992,7.853c0.5,1.176,0.51,2.608,0.542,3.929
                c0.216,8.818,0.27,17.643,0.607,26.457C346.037,304.07,345.476,307.901,343.341,312.128z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(153)} fill={props.fillColors[153]} d="M84.388,492.395c1.495-0.308,2.972-0.979,4.343-0.807
                c1.92,0.242,3.111-0.49,4.403-1.674c2.658-2.435,6.858-2.979,9.946-1.025c4.828,3.055,9.349,2.562,14.015-0.319
                c2.515-1.554,5.712-2.151,8.358-2.518c6.224-0.86,12.724-1.888,18.932,0.823c2.187,0.955,3.756,2.515,3.943,4.758
                c0.323,3.881,3.168,6.379,4.732,9.557c0.727,1.479,2.064,2.543,1.146,4.441c-0.58,1.199,0.177,2.188,0.833,3.195
                c1.121,1.722,0.891,3.425-0.289,5.041c-1.743-0.301-2.47-1.758-3.593-2.683c-1.432-1.179-2.919-2.192-4.804-2.506
                c-3.121-0.519-6.169-1.176-8.917-2.948c-1.794-1.158-3.817-0.684-5.805-0.166c-1.795,0.467-3.429,1.248-4.912,2.012
                c-4.419-1.387-8.792-4.003-12.971-3.725c-4.287,0.287-9.281-0.726-13.044,2.633c-1.05-0.494-1.906-1.09-2.849-1.301
                c-1.798-0.404-2.858-1.145-3.111-3.197c-0.312-2.527-1.899-4.016-4.534-4.637C87.176,496.634,87.22,496.45,84.388,492.395z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(154)} fill={props.fillColors[154]} d="M816.562,430.821c2.543-5.855,1.002-11.107-0.435-16.399
                c-1.21-4.459-1.126-4.482-5.806-6.006c-0.348-2.328-0.728-4.779-1.076-7.234c-0.266-1.876-0.213-3.612,1.527-4.94
                c1.576-1.203,1.844-2.903,1.857-4.886c0.038-5.478,0.476-10.905-1.484-16.265c-0.826-2.262-0.803-4.956-0.663-7.425
                c0.308-5.41-0.738-10.577-1.972-15.782c-1.018-4.296-1.779-8.678-1.178-13.657c4.848,2.71,7.536,6.865,9.323,11.143
                c4.351,10.414,8.667,20.891,8.212,32.632c-0.465,11.935,1.713,23.557,5.559,34.834c1.546,4.535,1.193,8.426-2.344,11.904
                C824.849,431.921,820.976,430.473,816.562,430.821z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(155)} fill={props.fillColors[155]} d="M287.459,205.54c1.953,3.504,1.508,7.127,2.276,10.459
                c2.324,10.082,3.573,20.299,3.307,30.676c-0.091,3.565,1.401,6.826,2.281,10.192c1.021,3.903,1.404,7.779,1.184,11.835
                c-0.407,7.477-0.41,14.975-0.599,22.463c-0.084,3.373,0.928,6.578,1.456,9.856c0.502,3.121,1.305,6.206,1.608,9.342
                c0.502,5.2,1.357,10.183,4.894,14.35c1.075,1.267,1.507,2.922,1.478,5.086c-6.173,2.248-11.063,6.864-17.09,10.287
                c-1.106-2.8-0.904-5.457-1.154-8.029c-1.067-10.976-0.58-21.979,0.095-32.924c1.592-25.825,0.784-51.587-1.152-77.341
                C285.638,216.421,286.155,211.193,287.459,205.54z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(156)} fill={props.fillColors[156]} d="M1045.89,276.182c-2.504,4.646-2.635,9.765-7.546,12.03
                c-0.445,0.206-0.85,0.801-1.244,0.774c-5.236-0.361-6.58,3.61-8.181,7.279c-2.269,5.201-5.999,9.572-8.255,15.129
                c-2.926-1.604-5.289-3.955-8.471-4.334c-3.137-0.375-6.312-0.464-9.473-0.594c-5.844-0.24-11.2-1.917-16.064-5.19
                c-1.168-0.786-2.592-1.12-3.754-0.21c-1.501,1.174-3.148,0.614-4.72,0.893c-4.434,0.788-7.254-1.431-9.868-4.65
                c0.617-0.324,1.154-0.676,1.74-0.902c6.966-2.684,14.363-4.082,21.156-7.345c2.686-1.291,5.867-1.193,8.871-1.13
                c6.921,0.147,13.545-1.498,20.154-3.217c2.25-0.585,4.635-1.172,6.367-2.772c3.799-3.509,8.502-4.339,13.318-4.982
                C1041.555,276.742,1043.188,276.534,1045.89,276.182z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(157)} fill={props.fillColors[157]} d="M191.769,160.938c-1.312-0.399-2.236-0.773-3.196-0.954
                c-2.547-0.48-3.343-2.042-3.654-4.537c-1.588-12.714-3.445-25.394-5.07-38.104c-1.063-8.323-3.179-16.382-6.544-24.005
                c-2.31-5.233-1.915-10.259-0.932-15.652c5.431-1.48,10.651-0.123,15.821,0.275c1.215,1.778,0.69,3.684,0.926,5.462
                c0.318,2.394-0.065,4.866,3.274,5.925c1.951,0.618,1.467,3.038,1.222,4.897c-0.955,7.259-1.822,14.53-2.787,21.787
                c-0.295,2.217-0.272,4.153,1.417,6.005c1.751,1.919,2.211,4.502,1.882,7.051c-1.28,9.932-1.24,19.94-1.857,29.91
                C192.241,159.477,192.033,159.944,191.769,160.938z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(158)} fill={props.fillColors[158]} d="M849.629,605.765c-2.053-1.829-1.288-3.549-1.182-5.143
                c0.771-11.638,2.432-23.146,4.879-34.556c1.045-4.873,1.434-9.892,2.568-14.739c1.473-6.295,3.115-12.54,4.086-18.948
                c0.536-3.536,3.274-5.359,6.343-6.385c3.118-1.043,5.308,1.197,4.23,4.256c-0.741,2.104-1.081,4.221-0.983,6.363
                c0.885,19.368-1.654,38.506-3.75,57.677c-0.2,1.83-0.328,3.583-1.586,5.13c-1.555,1.914-1.196,4.441-1.783,6.672
                c-0.404,1.54-0.039,3.439-1.995,4.444c-1.873-2.288-1.812-5.257-2.763-7.856c-0.711-1.941-0.739-4.913-3.347-5.04
                c-3.075-0.149-3.042,2.946-3.72,5.093C850.332,603.666,850.016,604.592,849.629,605.765z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(159)} fill={props.fillColors[159]} d="M923.52,416.992c2.518-0.832,4.75-0.3,6.907,0.666
                c8.33,3.727,13.811,9.576,14.66,19.13c0.591,6.651,1.239,13.3,0.155,19.921c-0.738,4.509,0.401,8.022,3.796,11.111
                c3.071,2.794,5.628,6.159,8.732,8.909c2.981,2.641,4.979,5.754,6.271,9.432c1.753,4.996,3.365,10.025,4.2,15.861
                c-4.749-1.844-7.915-5.132-11.819-7.127c-3.205-1.639-5.047-3.836-5.274-7.633c-0.249-4.143-2.69-7.466-5.265-10.562
                c-2.151-2.587-3.926-5.327-5.021-8.549c-0.58-1.711-1.357-3.517-2.541-4.833c-5.125-5.702-6.831-12.796-8.482-19.925
                c-1.068-4.612-2.749-8.896-5.946-12.478c-0.788-0.882-1.562-1.833-1.397-3.087C922.961,424.231,922.534,420.536,923.52,416.992z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(160)} fill={props.fillColors[160]} d="M290.048,581.268c-2.031,1.041-3.372,0.863-4.641-0.777
                c-2.06-2.664-5.052-3.191-8.124-2.6c-1.723,0.331-3.232-0.255-4.779-0.281c-4.748-0.081-8.139-3.242-12.241-4.715
                c-2.312-0.831-4.328-2.459-7.064-1.945c-1.473,0.277-2.297-1.184-3.354-1.922c-5.601-3.918-11.226-6.164-16.841-0.518
                c-4.158-1.877-6.071-4.886-6.217-9.027c-0.049-1.399-0.306-3.104-1.394-3.578c-4.209-1.837-5.748-6.738-10.229-8.166
                c2.803-3.065,5.717-3.258,8.991-1.443c2.297,1.273,4.706,1.525,7.106,0.16c0.145-0.082,0.292-0.227,0.439-0.228
                c4.351-0.028,8.72,0.632,13.048-0.439c0.627-0.155,1.335,0.401,1.587,1.026c1.12,2.771,3.626,3.294,6.089,3.635
                c3.091,0.428,5.269,1.814,6.43,4.766c1.066,2.709,3.493,3.464,5.893,4.766c4.472,2.426,10.464,2.338,13.329,7.566
                c0.153,0.28,0.438,0.645,0.703,0.682c4.616,0.66,6.418,4.644,9.168,7.562C289.418,577.351,290.366,578.881,290.048,581.268z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(161)} fill={props.fillColors[161]} d="M1007.827,285.987c-2.451,0-4.903-0.04-7.353,0.01
                c-3.166,0.063-6.512-0.097-9.361,1.159c-3.781,1.666-7.326,0.964-10.955,0.147c-1.356-0.306-2.635-0.475-3.898-0.004
                c-1.402,0.522-2.974,0.377-3.779-0.5c-2.266-2.469-5.297-1.031-7.827-1.938c-1.021-0.365-1.894,0.262-2.731,0.864
                c-1.874,1.347-3.793,2.633-5.641,3.908c-5.458-3.155-5.458-3.155-10.193,0.05c-4.07-1.548-8.063-4.167-12.55-0.363
                c-2.487-3.753-5.137-6.628-9.716-5.324c-0.182-1.905,1.018-1.854,1.909-2.092c5.979-1.603,11.658-3.896,16.9-7.227
                c1.121-0.712,2.319-1.302,3.479-1.956c10.837-6.118,21.921-6.955,33.522-2.084c8.906,3.739,17.553,7.981,26.117,12.413
                c0.854,0.441,1.609,1.068,2.409,1.609C1008.049,285.102,1007.938,285.544,1007.827,285.987z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(162)} fill={props.fillColors[162]} d="M1166.512,290.422c-5.224-2.393-9.027-5.255-13.852-5.968
                c-1.686-3.377-2.15-7.013-2.422-10.627c-0.227-3.024-0.673-5.949-1.829-8.746c-1.514-3.661-1.446-7.51-1.195-11.324
                c0.188-2.857,1.183-5.596,3.185-7.714c2.84-3.003,5.863-5.832,8.973-8.898c10.355,6.007,20.84,11.444,25.781,23.277
                c-1.469,0.91-3.152,0.756-4.752,0.872c-3.441,0.248-6.096,1.811-7.664,4.833c-1.588,3.061-3.816,5.293-6.75,7.109
                c-3.505,2.169-3.592,2.943-2.404,7.064C1164.447,283.303,1165.319,286.304,1166.512,290.422z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(163)} fill={props.fillColors[163]} d="M69.744,258.763c3.081,1.312,5.506,2.345,8.151,3.471
                c0,3.714-0.139,7.372,0.049,11.013c0.126,2.449-0.997,3.793-3.079,4.501c-4.284,1.458-8.13,3.601-11.795,7.839
                c0-3.215-0.056-5.456,0.009-7.693c0.886-30.671-0.904-61.261-3.331-91.798c-0.817-10.284,0.763-20.233,1.661-30.312
                c0.066-0.742,0.176-1.628,1.204-2.165c3.428,4.843,6.865,9.701,10.243,14.473c-0.083,0.473-0.037,0.865-0.208,1.099
                c-4.893,6.663-5.986,14.083-5.352,22.213c0.867,11.113,1.165,22.28,1.387,33.43c0.083,4.125-0.727,8.269-1.161,12.4
                c-0.156,1.482-0.726,3.027,0.013,4.421C70.35,246.964,71.49,252.509,69.744,258.763z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(164)} fill={props.fillColors[164]} d="M576.143,571.184c-3.688-4.201-8.498-3.894-12.688-5.16
                c-1.737-0.525-3.414-0.717-4.877-2.056c-1.633-1.493-3.9-1.807-6.06-2.013c-2.464-0.234-3.802-1.53-4.629-3.836
                c-2.19-6.098-4.708-12.081-6.787-18.215c-1.188-3.504-2.466-6.969-4.734-9.828c-2.563-3.232-3.917-6.895-5.018-10.728
                c-3.184-11.081-8.799-21.161-13.033-31.808c-0.601-1.512-1.686-2.889-1.536-4.819c2.635-1.304,3.852,0.349,5.005,2.326
                c4.524,7.756,9.08,15.493,13.628,23.235c6.485,11.039,13.045,22.036,19.413,33.144c1.722,3.003,3.863,5.163,6.97,6.765
                c9.192,4.738,9.234,4.807,12.839,14.41C575.555,565.054,577.758,567.391,576.143,571.184z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(165)} fill={props.fillColors[165]} d="M396.012,279.012c3.689,1.802,5.163,4.25,6.635,6.604
                c2.47,3.948,4.729,8.028,7.155,12.004c1.726,2.828,3.215,5.84,5.875,8.002c0.493,0.401,0.909,1.006,1.141,1.603
                c3.059,7.888,6.592,15.586,6.566,24.384c-0.027,9.302,0.495,18.605,0.805,28.809c-2.637-2.019-3.743-4.215-5.122-6.138
                c-1.55-2.163-3.284-4.269-4.399-6.651c-1.878-4.015-4.973-6.132-9.132-6.935c-3.264-0.63-4.247-2.488-4.776-5.804
                c-1.471-9.215-0.021-18.312,0.684-27.378c0.624-8.019,0.156-15.538-3.39-22.796C397.34,283.254,396.939,281.64,396.012,279.012z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(166)} fill={props.fillColors[166]} d="M153.845,89.613c5.5-0.268,7.703-5.224,11.624-7.457
                c1.828-1.042,0.506-3.397,0.887-5.112c1.572-0.513,2.507,0.566,4.108,1.271c-2.567,7.282,0.368,13.734,2.871,20.243
                c2.952,7.678,4.287,15.702,5.336,23.807c1.325,10.234,2.774,20.453,4.123,30.685c0.192,1.458,0.602,2.955-0.011,4.436
                c-1.448,0.455-2.955-0.16-3.699-0.968c-3.624-3.936-8.535-6.308-12.122-10.263c-0.929-1.024-1.699-2.034-1.841-3.432
                c-0.748-7.325-2.943-14.33-4.724-21.426c-0.241-0.959-0.554-1.938-0.024-2.911c1.495-0.217,2.471,1.855,4.185,0.895
                c1.005-1.721,0.412-3.503-0.034-5.253c-1.314-5.159-2.737-10.292-3.995-15.464C159.591,94.806,158.164,91.396,153.845,89.613z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(167)} fill={props.fillColors[167]} d="M708.139,447.215c-7.573-1.145-13.438,1.17-18.262,6.416
                c-1.713,1.861-3.98,3.148-6.234,3.988c-5.143,1.916-8.193,5.597-9.841,10.532c-1.03,3.09-3.534,5.171-4.848,8.045
                c-0.48,1.051-1.829,1.313-3.415,1.277c-0.084-3.227-0.527-6.361,1.447-9.372c1.803-2.747,0.053-5.423-1.098-7.763
                c-1.047-2.127-2.217-4.332-3.967-5.722c0.078-1.474,0.709-2.281,1.6-2.762c3.084-1.667,5.572-3.814,7.889-6.572
                c1.512-1.8,2.833-3.388,3.545-5.616c1.111-3.48,3.289-6.096,6.688-7.637c2.269-1.029,4.526-2.093,6.726-3.263
                c3.992-2.125,8.401-1.574,12.517-1.383c5.11,0.237,8.599,5.225,8.279,10.522C708.986,440.883,708.521,443.845,708.139,447.215z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(168)} fill={props.fillColors[168]} d="M621.986,53.584c-1.197-8.758-7.678-12.27-14.762-14.714
                c-8.391-2.896-15.95-7.463-24.184-10.945c-0.238-6.6-4.256-10.121-9.827-12.494c-8.955-3.816-18.288-6.156-27.92-7.331
                c-2.166-0.264-4.266-0.719-6.34-1.326c-3.418-0.999-7.17-1.482-8.476-5.725c-0.201-0.652-1.187-1.063-2.172-1.89
                c2.609-0.949,3.915,0.388,4.6,1.94c0.953,2.159,2.736,2.68,4.644,3.331c5.562,1.898,11.44,2.123,17.138,3.303
                c6.552,1.356,12.866,3.366,19.04,5.904c5.339,2.195,9.591,5.423,10.897,11.397c0.339,1.553,1.317,2.209,2.567,2.748
                c6.714,2.896,12.923,6.914,20.009,8.984c2.415,0.706,4.618,1.885,6.797,3.112c4.653,2.622,8.441,5.967,9.456,11.696
                c1.689,0.448,3.273,0.869,4.858,1.288c8.146,2.151,14.844,6.685,20.478,12.795c2.137,2.318,4.425,3.841,7.619,4.139
                c3.198,0.298,6.186,1.428,8.698,3.471c1.355,1.102,2.933,1.491,4.488,2.062c6.596,2.42,9.866,7.024,9.614,14.116
                c-0.109,3.08,0.742,5.594,1.909,8.431c3.583,8.708,4.907,17.256-1.253,26.028c-4.537,6.46-9.369,12.029-16.545,15.385
                c-1.234,0.577-2.213,1.275-2.57,2.792c-2.367,10.054-9.631,16.169-17.875,21.139c-5.823,3.51-12.092,6.303-18.27,9.186
                c-2.847,1.328-5.88,2.526-8.954,3.049c-8.793,1.498-16.273,5.64-23.171,10.956c-12.322,9.498-25.913,16.752-39.867,23.491
                c-6.72,3.245-12.813,7.722-19.761,10.62c-11.973,4.994-23.856,10.217-36.761,12.458c-2.64,0.458-5.043,1.434-7.337,2.941
                c-5.754,3.781-12.183,6.106-18.762,8.005c-3.362,0.97-6.755,1.882-9.875,3.477c-14.705,7.514-30.655,10.963-46.614,14.386
                c-4.555,0.977-9.14,1.82-13.714,2.706c-1.265,0.245-2.556,0.683-3.78,0.023c-0.097-1.788,1.214-1.469,2.063-1.627
                c13.421-2.498,26.786-5.236,39.939-8.929c5.296-1.487,10.521-3.167,15.449-5.649c6.271-3.158,12.912-5.313,19.604-7.352
                c5.6-1.706,10.987-3.954,15.787-7.339c2.151-1.517,4.575-2.085,6.978-2.519c15.07-2.715,29.112-8.476,42.524-15.455
                c12.092-6.292,24.059-12.842,36.23-18.998c2.675-1.353,5.062-3.182,7.353-5.145c5.061-4.336,10.688-7.919,16.405-11.262
                c5.412-3.165,11.32-5.132,17.437-6.949c12.925-3.84,25.215-9.501,35.398-18.786c3.77-3.437,6.661-7.498,7.652-12.594
                c0.44-2.266,1.72-3.475,3.678-4.299c8.839-3.721,13.859-11.129,18.307-19.064c1.077-1.922,1.592-4.077,1.478-6.257
                c-0.297-5.661-0.651-11.365-4.406-16.055c-0.981-1.226-0.781-2.445-0.744-3.757c0.054-1.827,0.195-3.664,0.068-5.48
                c-0.277-3.972-2.228-7.155-5.809-8.708c-3.662-1.59-7.161-3.408-10.617-5.37c-0.988-0.561-2.198-0.986-3.317-1.013
                c-4.7-0.112-7.862-2.629-10.967-5.902C639.897,59.047,631.812,54.621,621.986,53.584z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(169)} fill={props.fillColors[169]} d="M1138.77,281.873c0.452,5.389,1.939,9.825,2.818,14.354
                c1.098,5.661,1.243,11.544,0.896,17.366c-0.059,0.983-0.635,2.073-0.033,2.92c3.226,4.537,4.312,10.57,9.577,13.69
                c0.708,0.42,1.242,1.108,1.33,2.339c-1.988,0.785-4.093,1.586-6.171,2.45c-0.913,0.379-2.202,0.655-2.585,1.371
                c-2.109,3.946-5.87,4.529-9.75,5.152c-1.728-0.875-2.197-2.772-3.161-4.27c-1.819-2.825-3.75-5.546-6.79-7.166
                c-1.833-0.977-2.399-2.35-2.576-4.4c-0.708-8.188-0.014-16.295,0.954-24.401c0.177-1.485,0.522-2.911,1.614-4.099
                C1129.36,292.314,1133.094,286.783,1138.77,281.873z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(170)} fill={props.fillColors[170]} d="M946.119,431.233c1.427-0.371,2.122,0.481,2.884,1.064
                c5.698,4.361,10.884,9.265,15.815,14.484c4.633,4.904,6.332,10.935,6.914,17.281c1.324,14.428,3.747,28.691,5.909,42.996
                c0.094,0.621,0.272,1.271-0.333,1.974c-3.934-2.54-7.423-5.231-7.822-10.572c-0.194-2.608-1.232-5.163-1.961-7.722
                c-1.844-6.492-4.499-12.391-10.006-16.852c-3.33-2.695-5.937-6.277-8.918-9.414c-1.227-1.289-1.753-2.695-1.629-4.499
                C947.634,450.356,947.836,440.742,946.119,431.233z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(171)} fill={props.fillColors[171]} d="M907.347,593.473c-2.293-2.463-3.151-5.722-4.339-8.731
                c-1.427-3.617-3.562-6.276-7.24-7.728c-1.365-0.539-2.928-1.26-3.746-2.377c-2.438-3.332-5.696-4.352-9.721-3.936
                c-2.751-6.755-2.673-13.824-4.073-21.032c4.183-3.116,7.418-7.397,11.428-10.835c4.801-4.115,8.555-3.161,9.518,3.062
                c0.847,5.468,2.456,10.035,6.14,14.062c1.094,1.193,1.513,2.967,1.652,4.649c0.76,9.129,3.723,17.51,8.467,25.307
                c1.352,2.222,2.097,4.521,0.217,6.985c-1.471-0.564-0.793-1.979-1.22-2.902c-0.433-0.936-0.789-1.873-1.95-2.023
                c-1.297-0.17-2.215,0.522-2.973,1.479C908.58,590.623,909.018,592.481,907.347,593.473z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(172)} fill={props.fillColors[172]} d="M408.239,490.883c-0.789-2.055-1.152-3.652-0.921-5.406
                c1.597-1.217,3.485-0.663,5.232-0.717c7.777-0.24,15.54,0.162,23.243-1.902c5.214-1.398,5.355-0.873,9.565,3.571
                c-2.553,2.729-7.485,2.95-8.497,7.72c3.37,3.011,7.071,5.921,10.782,8.289c4.406,2.812,10.065,2.631,15.315,0.701
                c1.944,1.137,3.682,2.506,4.225,4.697c1.28,5.159,3.448,9.971,5.867,14.953c-3.767,2.175-7.028-0.525-10.334-0.291
                c-3.276,0.23-6.538,0.657-9.576,0.975c-3.237-3.394-6.597-6.209-9.435-9.658c-2.18-2.647-5.97-4.006-9.021-5.958
                c-3.346-2.142-5.71-4.862-7.851-8.045c-2.496-3.712-5.583-6.493-10.586-6.165C413.309,493.838,411.263,491.1,408.239,490.883z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(173)} fill={props.fillColors[173]} d="M234.008,589.145c-2.088-0.502-2.583-2.007-2.958-3.67
                c-0.363-1.616-0.704-3.255-1.277-4.801c-0.409-1.103-1.258-1.992-2.642-1.881c-1.152,0.094-1.721,0.922-2.076,1.889
                c-0.343,0.934-0.663,1.89-0.859,2.863c-0.397,1.973-0.615,3.873-3.162,4.492c-1.602,0.389-1.22,2.612-2.616,3.826
                c-3.042-3.855-1.274-9.861-5.675-13.113c-4.09,1.628-2.953,6.176-5.385,8.848c-1.851-0.908-1.973-2.867-2.208-4.47
                c-0.957-6.523-3.645-12.355-6.822-18.011c-0.397-0.706-1.345-1.371-0.876-2.151c1.916-3.192-0.495-6.525,0.473-9.758
                c0.46-1.536-0.641-2.839-1.421-4.12c-0.848-1.395-1.997-2.746-0.577-5.16c4.005,1.433,8.84,0.146,12.544,3.682
                c1.544,1.474,3.625,1.585,5.553,2.004c2.036,0.441,3.8,1.047,4.579,3.284c0.249,0.715,0.922,1.579,1.591,1.8
                c3.479,1.148,4.755,3.365,4.974,7.066c0.141,2.376,2.028,4.467,3.638,6.599c2.242,2.969,6.785,5.628,4.553,11.009
                c-0.876,2.111,2.057,4.346,1.401,6.992C234.577,587.104,234.976,587.988,234.008,589.145z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(174)} fill={props.fillColors[174]} d="M826.086,311.152c2.512,4.186,3.83,8.578,4.633,12.845
                c3.133,16.678,5.756,33.451,8.549,50.192c0.074,0.442-0.083,0.922-0.162,1.664c-3.266-1.296-5.382-3.234-6.372-6.579
                c-1.531-5.174-4.036-9.759-8.233-13.426c-2.869-2.507-5.119-5.625-7.081-8.979c-2.864-4.896-6.038-9.492-11.78-11.737
                c-3.142-1.228-3.041-2.836-1.484-6.037C808.621,319.908,816.802,315.345,826.086,311.152z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(175)} fill={props.fillColors[175]} d="M298.932,335.273c0.554,1.321,0.89,1.891,1.037,2.506
                c1.985,8.324,7.068,14.937,12.097,21.519c3.552,4.65,5.845,9.75,5.966,15.524c0.185,8.802-0.08,17.613-0.18,26.419
                c-0.01,0.932-0.193,1.861-0.295,2.785c-1.975-0.15-1.881-1.633-2.285-2.551c-1.875-4.263-3.952-8.412-6.268-12.452
                c-2.418-4.219-5.217-8.153-9.093-11.121c-3.585-2.746-5.487-6.362-7.138-10.482c-2.749-6.864-4.608-13.793-4.781-21.218
                c-0.058-2.47,0.627-4.045,2.695-5.323C293.354,339.233,295.884,337.363,298.932,335.273z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(176)} fill={props.fillColors[176]} d="M115.749,426.678c1.374-2.089,2.638-3.58,4.545-3.758
                c3.196-0.299,5.686-1.575,7.225-4.232c3.191,0,5.105,1.203,6.593,3.904c2.426,4.404,3.652,4.777,8.653,3.674
                c2.474-0.545,4.589-0.231,6.463,1.512c0.605,0.563,1.201,1.323,1.933,1.531c3.052,0.867,4.732,2.867,5.156,6.421
                c3.407-1.6,6.751-0.688,10.088-1.473c4.396-1.034,7.88,3.435,6.433,7.68c-0.479,1.406-0.396,2.446,0.234,3.742
                c0.86,1.771,0.78,6.752,0.033,9.253c-5.103-2.97-10.726-4.384-16.277-6.012c-5.828-1.708-11.724-0.443-18.323-0.874
                c-1.388-1.751-4.01-3-7.066-3.426c-1.046-6.97-4.577-11.418-11.599-12.661C116.962,431.452,117.077,428.57,115.749,426.678z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(177)} fill={props.fillColors[177]} d="M574.282,519.7c0.425-2.169,1.899-2.81,3.132-3.437
                c5.602-2.844,9.983-7.17,14.305-11.561c2.524-2.564,5.136-4.717,9.05-4.518c0.936,0.049,2.012-0.314,2.835-0.805
                c5.069-3.02,11.014-3.303,16.405-5.336c4.299-1.622,8.299,0.584,11.626,2.713c3.684,2.357,7.747,3.605,11.536,5.555
                c0.883,0.453,1.596,0.039,2.366-0.209c1.589-0.512,3.088-0.108,4.173,0.98c2.15,2.16,4.463,2.003,6.84,0.727
                c2.147-1.152,3.942-1.326,5.814,0.613c1.852,1.918,4.557,1.883,6.978,2.335c3.099,0.579,6.239,0.935,9.827,1.454
                c-2.147,2.634-3.93,4.807-7.496,2.943c-0.656-0.344-1.714-0.314-2.443-0.055c-4.107,1.463-8.148,0.172-12.172-0.292
                c-2.263-0.261-4.242,0.526-6.355,0.722c-2.762,0.256-5.502,0.518-8.339-0.423c-4.053-1.344-8.558-1.377-12.536-2.513
                c-6.262-1.787-11.898-2.215-17.628,0.982c-5.625-0.967-9.286,3.918-14.343,4.893c-1.829,0.354-3.626,1.227-5.642,1.375
                c-2.643,0.193-5.516,0.62-7.532,2.229C581.351,520.736,577.952,519.623,574.282,519.7z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(178)} fill={props.fillColors[178]} d="M42.731,212.117c-0.953-10.938-3.392-21.729-2.688-32.813
                c0.26-4.093-0.596-8.296-1.26-12.398c-0.887-5.48-0.812-10.948-0.467-16.444c0.125-1.995,0.088-4.006,0.31-5.989
                c1.279-11.465-0.002-22.583-4.438-33.275c-1.53-3.687-1.647-7.401-0.673-11.236c0.367-1.444,0.441-2.961,0.67-4.441
                c1.283-8.295,7.603-13.202,16.95-12.734c-1.603,2.896-2.964,5.7-4.641,8.301c-2.183,3.386-2.615,6.739-0.667,10.332
                c1.397,2.577,1.644,5.319,1.427,8.228c-1.671,22.438-2.551,44.903-2.073,67.408c0.127,5.996,0.369,11.989,0.487,17.985
                C45.785,200.869,44.9,206.553,42.731,212.117z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(179)} fill={props.fillColors[179]} d="M553.646,460.682c-1.834,0.309-2.972-0.236-4.047-1.382
                c0.898-3.518,2.624-6.743,4.419-9.895c5.434-9.541,10.89-19.067,15.91-28.835c0.918-1.787,2.027-3.444,3.43-4.885
                c1.919-1.972,4.303-3.723,6.972-3.432c5.392,0.587,8.683-2.705,12.157-5.711c3.773-3.265,7.596-6.532,10.953-10.205
                c5.393-5.9,11.896-6.419,19.504-4.894c-1.456,3.305-3.336,6.215-4.969,9.122c-1.471,2.618-4.258,4.376-7.816,4.663
                c-6.742,0.544-11.291,4.271-13.637,10.66c-0.824,2.245-2.537,3.88-4.128,5.58c-2.727,2.911-5.802,5.478-8.22,8.701
                c-1.819,2.426-4.721,3.228-7.479,3.526c-3.448,0.373-5.851,2.119-8.075,4.456c-4.156,4.365-7.636,9.218-10.533,14.498
                C556.577,455.4,555.05,458.143,553.646,460.682z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(180)} fill={props.fillColors[180]} d="M1090.833,365.038c1.927-1.384,3.505-2.592,5.161-3.683
                c1.254-0.826,2.845-1.907,4.152-1.288c3.359,1.591,7.193,0.674,10.463,2.317c1.34,0.673,2.383,0.524,3.654-0.345
                c4.848-3.319,9.661-3.223,14.112,0.279c1.559,1.226,2.795,2.822,5.063,2.795c1.168-0.014,1.492,1.064,1.995,1.944
                c2.639,4.615,5.257,5.351,10.427,4.263c4.334-0.912,8.842-1.015,13.277-1.425c1.181-0.109,2.248,0.149,3.115,1.166
                c1.496,1.752,3.117,3.399,4.885,5.305c-5.783,6.004-11.303,12.151-19.205,15.473c-1.247-0.471-2.82-0.884-2.894-2.348
                c-0.288-5.732-4.879-6.947-8.921-8.604c-1.207-0.495-1.928-0.97-2.389-2.157c-0.809-2.088-2.619-3.099-4.68-3.212
                c-3.285-0.182-6.555-1.386-9.874-0.316c-0.812,0.262-1.536,0.263-2.395,0.021c-5.778-1.628-11.577-3.19-17.382-4.723
                C1096.105,369.628,1093.359,367.908,1090.833,365.038z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(181)} fill={props.fillColors[181]} d="M132.496,417.238c2.004-2.974,4.732-2.602,7.395-2.677
                c7.096-0.202,14.204-0.267,21.245-1.348c2.319-0.356,8.974,2.521,9.719,4.772c1.299,3.922,5.227,5.008,7.457,7.773
                c2.192,2.717,0.783,7.264,4.745,9.47c1.698,0.945,1.627,3.549,1.739,5.504c0.174,3.046,0.673,5.95,2.269,8.595
                c0.617,1.023,0.896,2.149,0.935,3.333c0.145,4.309-0.032,8.602-0.486,12.893c-2.137,0.713-4.118,0.39-6.137,0.406
                c-2.158-3.113-4.293-6.196-6.413-9.255c1.074-4.032,1.457-8.023-0.2-11.946c-0.356-0.843-0.231-1.502-0.044-2.346
                c1.421-6.39-3.052-10.975-9.575-9.83c-1.299,0.228-2.493,0.758-3.892,0.287c-1.864-0.628-3.557-0.686-5.229-2.604
                c-2.275-2.609-5.938-4.035-9.072-5.853c-1.022-0.592-2.349-0.59-3.416-0.136c-3.89,1.654-6.088-0.561-8.128-3.32
                C134.526,419.765,133.582,418.621,132.496,417.238z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(182)} fill={props.fillColors[182]} d="M1262.086,184.391c-3.493,3.164-7.088,5.246-12.047,4.583
                c-3.6-0.48-7.308-0.304-10.961-0.195c-5.964,0.179-11.334-4.128-11.383-10.524c-0.072-9.427,1.257-18.453,5.189-27.032
                c0.928-2.025,2.231-3.651,4.928-3.388c2.523,0.246,3.973,1.425,4.372,3.933c0.077,0.482,0.39,0.923,0.501,1.405
                c1.277,5.534,3.696,9.26,10.262,10.034c5.86,0.69,9.842,5.115,12.973,10.578c-7.238,4.135-15.613,2.48-23.244,4.845
                c2.637,3.56,6.087,4.851,11.197,4.518C1256.632,182.968,1259.455,182.259,1262.086,184.391z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(183)} fill={props.fillColors[183]} d="M1055.764,332.149c-2.072-0.084-2.906-0.74-2.396-2.544
                c0.859-3.043,1.677-6.098,2.479-9.157c2.833-10.805,5.908-21.509,11.318-31.397c3.538-6.466,3.562-13.775,4.695-20.824
                c1.904-11.842,3.467-23.718,4.365-35.682c0.137-1.806,0.561-3.653,1.201-5.349c3.66-9.705,4.948-20.014,7.711-29.946
                c0.573-2.06,0.592-4.276,0.842-6.423c0.244-2.094,0.439-4.198,2.357-6.208c1.012,3.501,0.804,6.577,0.497,9.631
                c-0.647,6.462-1.332,12.922-2.092,19.373c-0.526,4.472-1.315,8.898-3.163,13.058c-1.727,3.89-1.938,8.093-2.326,12.216
                c-1.265,13.428-2.367,26.872-3.518,40.31c-0.303,3.52-1.102,6.883-2.671,10.077c-3.854,7.844-5.817,16.259-7.342,24.798
                c-0.229,1.288-0.014,2.81-1.309,3.622C1061.045,321.072,1058.426,326.479,1055.764,332.149z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(184)} fill={props.fillColors[184]} d="M755.262,423.572c-5.353,2.56-8.783,7.29-12.385,11.876
                c-3.507,4.464-5.119,9.981-8.121,14.882c-1.205-1.238-1.303-2.598-1.532-3.812c-0.854-4.507-2.558-8.611-5.472-12.159
                c-2.463-3-2.979-6.176-1.389-9.753c1.289-2.901,2.359-5.998,2.507-9.104c0.317-6.688,4.391-10.571,9.491-13.683
                c3.938-2.402,8.246-4.185,12.438-7.907c0,3.678,0.121,6.399-0.034,9.104c-0.159,2.78,0.247,5.374,2.138,7.456
                C756.257,414.165,756.826,418.356,755.262,423.572z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(185)} fill={props.fillColors[185]} d="M449.399,478.722c-1.188-2.944-2.69-5.777-3.455-8.797
                c-0.865-3.417-2.805-5.745-5.898-6.849c-4.005-1.428-5.145-4.243-4.979-8.08c0.05-1.158-0.152-2.325-0.132-3.486
                c0.116-6.701-1.387-12.852-6.032-17.941c-3.337-3.656-4.655-7.898-3.882-12.802c0.226-1.434-0.255-2.94,0.727-5.201
                c1.749,3.342,3.516,6.074,4.659,9.045c2.213,5.751,6.183,8.923,11.785,11.722c15.588,7.788,12.232,4.097,17.896,20.342
                c0.873,2.504,1.628,5.072,2.157,7.667c0.928,4.559-0.287,7.143-4.153,9.602c-2.518,1.603-5.021,3.227-7.541,4.826
                C450.439,478.84,450.236,478.764,449.399,478.722z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(186)} fill={props.fillColors[186]} d="M109.014,271.775c1.955,3.788,3.699,6.985,5.272,10.264
                c1.599,3.332,2.505,6.85,2.524,10.598c0.029,5.542,1.665,10.405,5.89,14.312c6.402,5.919,6.072,9.034-0.006,15.383
                c-2.696,2.815-3.878,5.972-3.95,9.873c-0.131,7.151-0.623,14.295-0.96,21.367c-0.787,0.392-1.38,0.206-1.988-0.116
                c-2.03-1.076-5.162-0.7-6.139-2.895c-1.457-3.273-3.177-6.57-3.069-10.422c0.623-22.319,1.16-44.641,1.744-66.961
                C108.339,272.914,108.575,272.656,109.014,271.775z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(187)} fill={props.fillColors[187]} d="M90.312,464.293c-0.971-3.293-2.414-5.947-5.603-7.343
                c-2.497-1.093-2.634-4.149-4.229-5.832c-2.465-2.599-3.208-5.598-3.766-8.853c1-1.357,2.664-1.848,3.981-1.502
                c3.599,0.945,7.673-0.893,10.937,1.967c0.763,0.668,1.912,0.41,2.906,0.252c4.875-0.774,8.487,1.309,10.908,5.312
                c1.265,2.093,3.669,3.035,4.776,4.705c2.907,4.384,7.616,7.994,6.795,14.326c4.104,0.415,6.471,3.793,10.248,4.015
                c0.424,0.025,1.067,0.454,1.19,0.832c1.246,3.806,5.052,6.666,4.475,11.469c-4,0.337-7.974-0.74-12.106,2.43
                c1.721-6.024-2.036-9.512-4.993-12.197c-3.834-3.48-8.439-7.131-14.578-6.055c-1.102,0.193-2.388,0.499-3.252-0.615
                C96.062,464.7,93.176,464.528,90.312,464.293z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(188)} fill={props.fillColors[188]} d="M1105.421,206.119c3.052,2.666,3.966,5.05,3.588,8.131
                c-0.567,4.623-0.63,9.315-1.331,13.913c-1.861,12.213,0.186,24.243,1.402,36.313c0.29,2.881,2.032,5.265,3.438,7.697
                c2.048,3.545,3.411,7.179,3.031,11.367c-0.134,1.477,0.107,2.99,0.2,4.486c0.401,6.448,0.647,12.701-2.057,19.014
                c-2.057,4.802-2.487,10.311-3.556,15.523c-0.636,3.1-1.684,6.032-3.243,8.957c-1.229-3.182-1.962-6.381-1.903-9.751
                c0.101-5.66,0.193-11.32,0.288-16.979c0.046-2.706-0.079-5.387-2.193-7.427c-1.411-1.362-0.995-3.062-1.085-4.688
                c-0.495-8.882,1.116-17.579,2.396-26.294c0.912-6.214,0.869-12.29-1.707-18.125c-1.967-4.455-1.895-8.977-1.171-13.691
                C1102.927,225.384,1104.061,216.162,1105.421,206.119z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(189)} fill={props.fillColors[189]} d="M613.684,430.072c-2.844,2.847-4.306,6.275-8.765,7.221
                c-2.511,0.533-4.512,3.64-5.815,6.368c-0.745,1.559-1.637,2.827-3.451,3.282c-2.851,0.715-5.229,2.415-7.767,3.72
                c-3.359,1.727-4.933,4.113-6.277,7.396c-6.153-0.297-11.888,1.334-17.159,4.215c-3.186,1.741-5.838,1.827-8.889-0.751
                c4.107-8.595,8.791-16.861,15.882-23.548c0.599-0.565,1.246-1.232,1.992-1.465c12.647-3.951,25.252-8.169,38.807-7.249
                C612.522,429.28,612.785,429.556,613.684,430.072z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(190)} fill={props.fillColors[190]} d="M1098.111,191.872c2.496,1.181,4.582,2.168,6.883,3.257
                c-0.037,4.924-1.199,9.763-1.913,14.66c-1.32,9.054-2.544,18.123-3.728,27.196c-0.246,1.882-0.636,3.516-2.244,4.772
                c-1.346,1.052-1.936,2.578-2.209,4.323c-0.881,5.626-2.65,11.071-5.113,16.129c-5.557,11.415-7.785,23.638-9.707,35.985
                c-0.256,1.644-0.523,3.286-0.798,4.927c-1.192,7.111-2.351,8.646-9.201,12.195c-1.185-0.965-0.503-2.188-0.247-3.185
                c1.904-7.394,3.217-14.97,6.786-21.858c1.86-3.59,2.81-7.454,2.989-11.506c0.104-2.329,0.24-4.657,0.439-6.979
                c0.657-7.642,1.668-15.084,5.491-22.071c2.782-5.085,3.188-11.155,4.803-16.738c3.656-12.65,6.852-25.357,7.271-38.606
                C1097.635,193.729,1097.863,193.09,1098.111,191.872z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(191)} fill={props.fillColors[191]} d="M1202.55,371.257c5.521-5.19,12.29-7.949,19.271-11.579
                c0.298,3.507-0.054,6.406-0.318,9.31c-0.619,6.81-1.1,13.61,0.532,20.374c1.108,4.591,0.948,9.238,0.407,13.902
                c-0.539,4.645-0.112,9.102,1.019,13.771c1.376,5.683,1.522,11.535-2.602,17.006c-4.343,5.761-6.108,12.846-6.755,20.059
                c-0.52,5.805-0.833,11.627-1.234,17.441c-0.136,1.967-0.155,3.984-2.838,5.342c-0.014-10.638-0.459-20.876,0.396-31.125
                c1.246-14.946,3.562-29.736,6.359-44.457c0.543-2.861,0.598-5.684-0.799-8.287c-1.754-3.27-2.91-6.742-3.556-10.345
                c-0.837-4.665-3.185-7.937-7.68-9.598C1203.984,372.787,1203.165,372.518,1202.55,371.257z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(192)} fill={props.fillColors[192]} d="M1013.498,451.837c0.478,5.848,1.064,11.07,1.301,16.31
                c0.677,14.962,1.285,29.929,1.753,44.898c0.136,4.368,0.661,8.654,1.855,12.805c3.669,12.745,2.269,25.74,2.497,38.545
                c-1.836,0.829-2.582-0.058-2.738-1.328c-0.756-6.176-2.65-11.998-5.191-17.641c-0.203-0.449-0.408-0.956-0.402-1.434
                c0.158-12.824-4.359-24.838-6.497-37.262c-0.974-5.658-1.431-11.209-0.067-16.817c1.872-7.701,2.103-15.494,0.83-23.267
                c-0.643-3.924,0.137-7.166,2.615-10.168C1010.573,455.121,1011.223,453.359,1013.498,451.837z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(193)} fill={props.fillColors[193]} d="M1116.199,572.422c-1.031-1.416-1.783-2.45-2.5-3.434
                c2.033-5.882,1.686-6.852-3.794-10.658c0.328-2.292,0.915-4.572,2.301-6.459c3.056-4.163,3.036-9.112,3.202-13.804
                c0.114-3.207,0.712-5.922,2.622-8.309c3.767-4.707,5.472-10.546,8.698-15.56c0.896-1.392,1.771-2.682,2.883-3.915
                c3.9-4.329,8.373-8.019,12.85-11.694c4.006-3.289,5.769-7.684,6.344-12.575c0.395-3.354,1.955-5.863,4.771-8.823
                c0.35,4.582-0.67,8.348-0.826,12.136c-0.097,2.317-0.61,4.613-0.852,6.928c-0.302,2.901-1.314,5.45-3.256,7.665
                c-5.389,6.146-10.525,12.452-13.286,20.341c-0.421,1.202-1.396,2.276-2.302,3.23c-4.114,4.333-6.532,9.479-8.178,15.2
                c-2.39,8.302-5.345,16.443-6.771,25.004C1117.857,569.188,1117.421,570.602,1116.199,572.422z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(194)} fill={props.fillColors[194]} d="M107.249,67.776c-0.33-1.748,0.612-2.804,1.227-4.344
                c4.05,0.849,8.049,0.367,11.565-1.385c1.439-1.636-0.594-3.128,0.49-4.57c11.587-5.896,11.963-5.901,22.653-0.257
                c1.258,5.266-3.196,8.209-5.334,12.122c2.212,2.396,4.946,3.254,7.452,4.465c2.978,1.439,6.204,2.38,8.711,4.728
                c1.363,1.276,3.054,1.2,4.677,0.478c1.499-0.667,2.947-1.448,4.6-2.27c1.282,1.718,1.943,3.456-0.02,4.77
                c-3.873,2.59-7.168,6.367-12.422,6.35c-1.312-0.004-2.593,0.436-3.851-0.372c-7.615-4.889-16.305-7.366-24.573-10.767
                C116.825,74.421,111.934,71.126,107.249,67.776z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(195)} fill={props.fillColors[195]} d="M903.139,600.656c-1.494-2.242-1.176-4.88-3.982-4.924
                c-1.049-0.016-1.084-1.054-1.278-1.855c-0.335-1.391-0.987-2.592-2.646-2.412c-1.701,0.183-2.512,1.438-2.989,2.973
                c-0.513,1.644,0.215,3.684-1.562,5.218c-2.876-4.479-0.256-10.06-3.219-14.128c-1.799-0.141-2.552,0.869-2.605,2.044
                c-0.211,4.604-2.956,7.471-6.58,10.028c-2.072-1.189-1.201-3.539-1.926-5.234c-0.46-1.074-0.658-2.38-2.156-2.348
                c-1.398,0.031-1.931,1.117-2.235,2.312c-0.246,0.965-0.418,1.953-0.72,2.9c-0.535,1.68-1.688,2.302-3.683,1.818
                c0.208-6.818,0.802-13.572,1.938-20.274c1.172-6.913,1.975-13.827,1.719-20.874c-0.218-5.991-0.067-11.998,0.005-17.998
                c0.027-2.304-0.103-4.685,1.307-6.74c1.787,0.104,1.507,1.545,1.656,2.582c1.299,9.066,2.555,18.139,3.818,27.209
                c0.549,3.945,1.429,7.805,2.684,11.375c1.566,0.742,2.898,0.143,4.208,0.259c1.336,0.118,2.816,0.069,3.685,1.216
                c2.208,2.914,5.344,4.443,8.479,5.997c1.568,0.777,2.613,1.949,3.283,3.585c1.135,2.772,2.261,5.565,3.651,8.213
                C905.562,594.598,904.904,597.232,903.139,600.656z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(196)} fill={props.fillColors[196]} d="M605.429,392.146c-10.831-6.254-22.908-9.023-34.299-13.562
                c-2.395-0.954-4.448-0.718-6.664,0.4c-2.477,1.25-4.951,2.604-8.551,3.198c1.76-2.671,3.679-4.146,5.633-5.502
                c4.043-2.806,7.18-6.336,9.614-10.624c2.294-4.042,4.4-4.69,8.688-2.875c2.914,1.233,5.895,2.643,6.882,6.028
                c0.503,1.727,1.824,2.241,3.118,2.939c6.394,3.449,13.168,5.379,20.478,5.667c6.132,0.242,12.248,0.907,18.583,1.401
                c0.1,4.169-2.365,6.938-4.196,10.033C618.106,389.318,611.26,387.094,605.429,392.146z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(197)} fill={props.fillColors[197]} d="M949.699,490.283c-3.014-0.352-5.102-1.195-6.96-3.594
                c-1.507-1.944-4.683-2.512-6.233-4.676c-1.183-1.648-2.547-2.743-4.167-3.946c-4.157-3.089-8.62-5.048-13.659-5.88
                c-0.877-0.146-1.505-0.615-1.951-1.383c-0.979-1.686-2.832-2.227-4.688-3.381c0.936-6.302,2.238-12.655,2.729-19.071
                c0.479-6.276,3.171-11.415,6.594-16.73c3.252,2.103,4.62,5.234,5.615,8.514c1.399,4.61,2.581,9.286,3.877,13.929
                c0.312,1.118,0.396,2.5,1.111,3.275c5.956,6.45,7.742,15.433,13.541,21.992C948.157,482.331,949.814,485.933,949.699,490.283z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(198)} fill={props.fillColors[198]} d="M1084.559,565.65c-1.56-5.718,2.023-8.628,4.863-11.29
                c2.09-1.959,3.324-5.011,6.498-5.853c0.777-0.206,1.049-1.221,1.261-2.046c1.062-4.143,3.568-7.804,4.05-12.141
                c0.018-0.163,0.016-0.399,0.117-0.473c4.896-3.542,3.813-10.549,8.305-14.336c0.482-0.407,0.908-1.052,1.055-1.659
                c1.082-4.52,4.381-6.455,8.305-8.401c2.963-1.47,5.91-3.495,8.323-6.028c2.722-2.86,6.334-3.542,10.157-3.881
                c-1.811,2.021-3.553,3.969-5.446,5.649c-5.997,5.321-9.45,12.226-12.706,19.287c-0.501,1.087-1.156,1.981-1.873,2.895
                c-2.518,3.212-3.729,6.645-3.861,10.956c-0.145,4.638-0.412,9.584-3.57,13.701c-1.906,2.486-1.697,6.041-3.721,8.83
                C1099.002,561.818,1090.782,558.534,1084.559,565.65z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(199)} fill={props.fillColors[199]} d="M98.497,84.724c1.549-2.939,3.71-5.336,1.677-8.57
                c-0.568-0.904,0.179-1.885,0.974-2.593c1.482-1.319,2.948-2.657,4.248-3.832c1.963,0.063,3.019,1.243,4.241,2.048
                c4.588,3.023,9.174,6.072,14.578,7.456c3.677,0.941,5.286,3.316,6.152,6.903c1.054,4.361,2.345,8.641,2.783,13.142
                c0.426,4.369,1.547,8.495,5.06,11.579c2.292,2.012,3.04,4.601,2.71,7.635c-0.417,3.822,0.405,7.574,1.47,11.871
                c-2.704-0.928-3.342-2.885-4.13-4.461c-1.707-3.417-4.021-6.096-7.636-7.485c-2.597-0.998-3.821-2.747-4.094-5.604
                c-0.378-3.956-1.588-7.805-1.724-11.822c-0.084-2.488-1.242-4.281-3.594-5.502c-4.276-2.221-8.369-4.643-13.404-5.011
                C104.02,90.198,101.075,87.972,98.497,84.724z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(200)} fill={props.fillColors[200]} d="M1206.994,376.957c2.373,0.687,2.612,2.59,3.069,4.298
                c1.159,4.333,2.2,8.693,4.306,12.718c1.489,2.849,0.759,5.818,0.223,8.789c-3.103,17.19-5.893,34.432-6.483,51.924
                c-0.265,7.811-0.005,15.639,0.083,23.459c0.021,2.02-0.098,3.91-1.087,5.773c-1.767,3.328-1.453,7.12-2.066,10.707
                c-0.826,4.836-1.105,9.785-2.646,14.424c-1.884-0.079-1.637-1.271-1.627-2.193c0.117-10.48,0.039-20.96,0.693-31.434
                c0.395-6.305,0.396-12.636,0.467-18.956c0.109-9.652,0.398-19.262,1.862-28.852c1.634-10.704,1.403-21.567,1.812-32.37
                c0.17-4.489,0.286-8.98,0.465-13.469C1206.128,380.163,1206.064,378.498,1206.994,376.957z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(201)} fill={props.fillColors[201]} d="M508.984,465.284c6.352-5.208,12.479-10.296,18.694-15.273
                c1.142-0.915,2.571-1.656,3.985-2.015c9.207-2.339,16.813-7.851,24.925-12.365c1.083-0.603,1.976-1.765,3.771-1.428
                c-4.086,8.284-9.477,15.716-12.842,24.742c-5.964-0.81-9.713,3.06-13.527,6.831c-2.84,2.807-5.975,5.364-8.509,8.422
                c-3.321,4.008-7.163,5.652-12.581,4.047C510.969,474.557,510.24,470.126,508.984,465.284z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(202)} fill={props.fillColors[202]} d="M338.107,230.06c-1.703,0.264-1.91-1.533-3.647-0.769
                c-1.053,4.52-2.681,9.281-1.516,14.506c2.388,10.708,4.175,21.529,5.767,32.382c0.559,3.807,0.684,7.564-0.836,11.784
                c-2.762-3.798-4.419-7.479-4.931-11.619c-2.102-16.997-3.991-34.033-4.822-51.13c-0.466-9.585-0.95-19.259,0.556-28.87
                c0.592-3.776,0.399-7.611-0.639-11.379c-2.386-8.658-2.345-8.669,3.449-16.325c3.152,4.406,5.235,8.886,5.296,14.541
                c0.144,13.467,0.856,26.927,1.312,40.391C338.17,225.721,338.107,227.874,338.107,230.06z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(203)} fill={props.fillColors[203]} d="M526.982,447.652c-2.157-2.828-2.553-5.775-1.468-8.97
                c0.587-1.729,1.127-3.485,1.859-5.154c4.025-9.173,6.991-18.631,8.252-28.611c0.414-3.271,1.281-6.486,3.125-9.66
                c2.403,1.243,4.974,2.135,7.005,3.72c3.629,2.833,7.485,3.256,11.743,2.257c0.788-0.185,1.615-0.202,2.944-0.357
                c-0.603,2.973-2.175,5.042-3.878,6.878c-5.526,5.96-9.613,12.678-11.64,20.6c-0.285,1.113-0.607,2.366-1.329,3.19
                c-2.192,2.505-3.083,5.47-3.519,8.634c-0.308,2.237-1.412,3.431-3.681,4.06C533.24,445.114,530.211,446.456,526.982,447.652z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(204)} fill={props.fillColors[204]} d="M781.619,381.114c4.391,3.536,6.891,7.469,6.979,12.675
                c0.043,2.461,0.465,5.057,2.084,7.098c2.234,2.816,2.724,6.038,2.406,9.424c-0.498,5.324,0.062,10.33,4.213,14.608
                c-2.932,3.164-1.357,6.712-1.41,10.202c-1.829,1.934-4.323,2.272-6.486,3.017c-2.078-0.79-2.75-2.664-4.026-3.922
                c-2.839-2.797-3.775-2.97-7.519-1.427c-1.496,0.617-2.721,1.976-4.536,1.79c-0.78-1.282-0.382-2.628-0.241-3.899
                c0.701-6.3,0.861-12.624,1.055-18.951c0.12-3.928,0.558-7.718,4.95-9.497c1.631-0.66,1.676-2.316,1.913-3.81
                c0.576-3.634,0.737-7.285,0.631-10.958C781.569,385.348,781.619,383.23,781.619,381.114z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(205)} fill={props.fillColors[205]} d="M1133.361,283.845c-0.217-7.852-1.75-14.93-0.208-21.997
                c0.68-3.113,0.37-5.913-0.613-8.776c-1.354-3.941-2.929-7.803-5.099-11.364c-2.641-4.334-4.354-8.885-4.77-14.011
                c-0.358-4.432-1.521-8.789-1.668-13.652c4.686-0.063,8.057,0.929,10.065,5.6c2.542,5.908,3.892,12.001,4.267,18.325
                c0.267,4.511,0.852,8.932,2.021,13.29c1.13,4.21,2.502,8.131,6.194,11.053c3.102,2.454,4.521,6.08,4.688,10.123
                c0.062,1.489,0.057,3.097,0.613,4.429c2.997,7.183-0.58,12.332-5.242,17.488c-3.403-4.626-1.713-10.565-3.831-15.414
                C1136.599,279.168,1136.277,282.278,1133.361,283.845z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(206)} fill={props.fillColors[206]} d="M900.418,470.865c0.181,0.696,0.505,1.217,0.391,1.609
                c-1.813,6.215-2.246,13.045-8.33,17.287c-3.047,2.125-3.42,5.967-4.331,9.158c-0.71,2.487-2.387,4.276-3.185,6.338
                c-1.993,5.15-6.838,6.973-10.459,9.879c-2.946,2.363-5.015,4.494-5.49,8.227c-2.307,0.642-4.574,1.025-6.535,2.24
                c-1.005-1.532-0.076-2.711,0.324-3.908c4.428-13.247,10.618-25.761,15.983-38.618
                C882.889,473.244,889.886,469.612,900.418,470.865z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(207)} fill={props.fillColors[207]} d="M857.488,337.993c4.618,11.253,7.893,22.756,10.377,34.48
                c1.449,6.839,2.695,13.722,3.996,20.592c0.4,2.121,0.766,4.23,0.02,6.403c-2.455,7.134-3.422,14.564-4.172,22.037
                c-1.564,0.188-1.445-1.057-1.693-1.896c-2.043-6.885-5.026-13.401-7.845-19.978c-0.874-2.039-1.347-4.069-1.224-6.289
                c0.129-2.328,0.018-4.67,0.161-6.997c0.547-8.868,0.05-17.671-1.914-26.333C853.465,352.387,855.424,345.209,857.488,337.993z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(208)} fill={props.fillColors[208]} d="M1100.198,295.189c-3.608,1.729-5.318,4.924-7.563,7.521
                c-3.169,3.665-7.15,5.014-12.016,5.661c0.325-6.29,1.803-12.08,2.654-17.95c1.27-8.754,3.58-17.191,7.307-25.282
                c2.847-6.18,5.121-12.626,6.312-19.384c0.18-1.022,0.51-1.931,1.896-2.632c3.059,7.189,5.324,14.423,3.925,22.437
                C1101.025,275.228,1099.723,284.944,1100.198,295.189z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(209)} fill={props.fillColors[209]} d="M1135.287,427.268c3.83,1.848,5.814,4.477,7.764,7.784
                c4.75,8.058,5.764,16.298,4.193,25.359c-1.049,6.05-0.138,12.232,0.269,18.383c0.415,6.274-0.435,12.617-5.348,17.471
                c-1.063,1.051-2.122,2.26-3.861,1.267c-1.56-0.89-0.914-2.274-0.78-3.554c0.223-2.148,0.268-4.326,0.649-6.445
                c0.688-3.823-0.272-7.258-2.13-10.518c-2.115-3.717-2.934-7.746-2.949-11.961c-0.011-2.824-0.047-5.663,0.199-8.471
                C1134.136,446.991,1133.426,437.297,1135.287,427.268z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(210)} fill={props.fillColors[210]} d="M391.625,434.179c-2.261-2.318-3.145-4.188-2.984-6.484
                c0.988-14.122-2.449-27.803-4.202-41.648c-0.429-3.388-0.423-6.925-2.126-10.409c-2.599,1.524-0.648,4.611-2.446,6.118
                c-4.335-10.63-12.396-58.109-11.28-66.489c1.399-0.145,2.583,0.966,2.648,1.794c0.295,3.764,3.391,5.516,5.29,8.119
                c1.206,1.653,1.631,3.639,1.987,5.607c3.378,18.674,7.902,37.108,11.721,55.69c0.576,2.802,0.836,5.585,0.877,8.418
                C391.294,407.7,390.818,420.509,391.625,434.179z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(211)} fill={props.fillColors[211]} d="M1004.484,462.455c1.026,8.65,2.332,16.641,0.174,24.717
                c-1.93,7.215-1.757,14.401,0.048,21.675c1.839,7.413,3.236,14.937,4.829,22.41c0.034,0.162,0.132,0.319,0.134,0.479
                c0.109,9.587,3.208,18.514,6.049,27.5c0.197,0.624,0.218,1.311,0.265,1.971c0.021,0.318-0.09,0.645-0.162,1.1
                c-1.224,0.705-2.291-0.218-2.975-0.834c-3.363-3.031-7.605-5.314-9.238-9.917c-0.912-2.57-3.131-3.243-5.501-4.118
                c-0.675-8.103-0.195-16.237,0.245-24.348c0.675-12.457,0.391-24.951,1.53-37.387c0.249-2.713-0.384-5.26-1.411-7.767
                C996.678,473.562,999.521,465.686,1004.484,462.455z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(212)} fill={props.fillColors[212]} d="M987.2,542.227c-1.312,0.843-2.54,1.264-3.728,0.146
                c-1.901-1.789-4.1-1.758-6.525-1.518c-2.613,0.26-4.984-0.234-5.279-3.688c-0.098-1.154-0.934-2.193-2.095-2.627
                c-1.964-0.733-2.737-2.507-3.306-4.148c-1.002-2.891-0.829-6.307-4.145-7.986c-0.111-0.057-0.203-0.311-0.178-0.449
                c1.275-6.869-4.78-9.211-8.205-13.121c-1.95-2.227-4.305-4.459-4.957-7.151c-0.709-2.931-1.523-5.97-1.936-9.386
                c2.024-0.182,3.355,0.848,4.64,1.67c6.866,4.396,13.741,8.784,20.493,13.352c4.623,3.127,7.398,7.689,8.427,13.115
                c1.31,6.914,3.582,13.479,6.358,19.906C987.02,540.93,987.063,541.608,987.2,542.227z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(213)} fill={props.fillColors[213]} d="M697.617,366.555c-3.473-4.366-7.371-7.403-8.577-12.741
                c-0.646-2.856-3.286-5.294-5.149-7.829c-0.861-1.171-2.373-0.993-3.672-1.239c-3.59-0.68-7.193-1.323-10.729-2.225
                c-1.702-0.434-3.648-0.614-4.874-2.248c0.971-1.22,2.377-1.068,3.64-1.164c8.789-0.67,17.57-1.396,26.402-1.287
                c3.407,0.042,6.604,0.67,9.635,2.152c6.639,3.246,12.327,7.72,17.525,13.252c-2.246,2.444-4.313,4.406-7.503,4.537
                c-1.981,0.082-3.782,0.891-4.591,2.562c-1.482,3.069-4.324,3.725-7.019,3.953C700.742,364.446,699.27,365.108,697.617,366.555z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(214)} fill={props.fillColors[214]} d="M36.574,167.261c0.988,5.471,1.422,9.829,1.634,14.284
                c0.856,18.014-2.283,35.811-2.618,53.737c-0.226,12.121-1.535,24.228-0.284,36.394c0.642,6.249-0.102,12.569-0.828,18.984
                c-4.887,0.867-4.759-4.908-8.325-5.172c0.796-10.021,1.619-19.618,2.306-29.225c0.831-11.611,2.48-23.263,2.069-34.836
                c-0.483-13.574,2.085-26.804,2.613-40.229C33.314,176.807,34.659,172.472,36.574,167.261z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(215)} fill={props.fillColors[215]} d="M617.352,542.452c-10.236,3.548-20.362,6.058-30.21,8.567
                c-4.37-2.586-4.348-5.907-2.988-9.952c1.856-5.518,4.517-10.749,5.761-16.483c0.473-2.182,2.2-2.9,4.204-2.633
                c6.929,0.924,13.848,1.925,20.76,2.967c1.881,0.284,2.873,1.43,2.803,3.471C617.526,533.022,617.459,537.659,617.352,542.452z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(216)} fill={props.fillColors[216]} d="M1087.896,505.766c1.096-5.7,2.041-11.436,3.331-17.091
                c1.23-5.39,3.166-10.632,3.726-15.695c-2.02-1.506-2.743,0.503-3.92-0.009c-0.636-4.793-0.256-9.186,1.547-13.972
                c1.74-4.621,1.169-10.181-0.692-15.737c6.557-5.318,12.696-11.316,21.011-15.841c-0.788,7.158-1.156,13.67-3.195,19.723
                c-5.149,15.294-8.752,31.078-14.713,46.111c-1.771,4.464-3.817,8.764-6.109,12.951
                C1088.553,506.059,1088.225,505.912,1087.896,505.766z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(217)} fill={props.fillColors[217]} d="M162.527,142.019c-1.654-1.287-3.074-2.367-4.467-3.479
                c-3.513-2.804-7.007-5.642-11.49-6.795c-1.663-0.428-2.355-1.487-2.701-3.143c-0.648-3.102-1.206-6.185-1.115-9.376
                c0.065-2.322,0.31-4.728-0.175-6.954c-0.617-2.832-0.104-5.295,1.267-7.634c1.18-2.013,1.369-3.87,0.6-6.138
                c-1.316-3.88-2.25-7.889-3.673-13.035c4.788,3.875,9.54,5.562,14.265,7.317c1.324,0.492,2.193,1.534,2.601,2.857
                c2.094,6.794,3.933,13.655,5.463,20.79c-1.656,0.581-3.128-1.257-4.353,0.158c-1.144,1.321-0.696,2.839-0.375,4.276
                c1.302,5.832,3.298,11.496,4.196,17.428C162.735,139.394,163.038,140.471,162.527,142.019z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(218)} fill={props.fillColors[218]} d="M596.18,498.455c1.444-7.617,4.866-13.141,13.128-14.327
                c4.625-0.665,9.031-2.238,13.488-3.573c2.294-0.688,4.208-0.568,6.569,0.623c3.511,1.771,7.547,2.493,11.344,3.709
                c3.958,1.268,7.865,2.709,11.858,3.854c3.624,1.039,5.586,3.319,6.188,6.966c0.242,1.471,0.66,2.912,1.039,4.542
                c-1.447,0.698-2.9,1.396-4.351,2.1c-0.927,0.449-2.206,0.944-2.837,0.275c-3.23-3.43-7.786-2.166-11.623-3.385
                c-2.424-0.77-4.733-1.674-6.728-3.057c-5.812-4.034-11.496-5.297-18.633-2.78c-4.451,1.568-9.093,2.798-13.49,4.665
                C600.218,498.881,598.283,498.746,596.18,498.455z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(219)} fill={props.fillColors[219]} d="M242.606,426.373c-2.343-5.292-5.033-10.231-8.532-14.701
                c-1.169-1.494-1.771-3.167-2.062-5.013c-0.597-3.781-1.316-7.544-1.853-11.333c-0.457-3.219-1.354-6.23-3.857-8.43
                c-1.437-1.263-1.588-2.809-1.693-4.486c-0.324-5.157-0.164-10.333-0.42-15.477c-0.264-5.32-0.774-10.402,2.734-15.088
                c1.083-1.446,0.928-3.818,1.356-5.845c1.481,0.246,1.455,1.543,1.838,2.515c0.366,0.928,0.62,1.904,1.034,2.809
                c6.235,13.61,8.623,27.877,7.383,42.777c-0.352,4.227,0.559,8.202,1.98,12.198C243.42,414.464,243.798,418.894,242.606,426.373z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(220)} fill={props.fillColors[220]} d="M118.787,485.125c-2.487,2.139-5.217,3.597-8.697,3.805
                c-1.945,0.117-3.562-0.176-5.188-1.078c-5.566-3.092-9.14-2.811-13.954,1.097c-1.402,0.208-2.688-0.418-3.619-1.269
                c-2.844-2.601-5.675-4.949-9.777-5.415c-1.63-0.185-3.124-1.855-3.148-3.504c-0.038-2.593-0.295-4.753-3.848-5.293
                c2.131-2.112,4.773-1.335,5.667-3.601c0.287-0.727,1.507-0.908,2.249-0.519c2.352,1.236,3.898,0.182,5.768-1.274
                c3.771-2.938,8.677-2.715,12.225,0.459c1.285,1.149,2.485,1.497,4.022,1.27c6.671-0.985,10.995,2.751,15.173,7.188
                C117.908,479.38,120.126,481.464,118.787,485.125z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(221)} fill={props.fillColors[221]} d="M594.438,567.857c-2.438,0.361-5.027-0.438-7.083-1.331
                c-3.115-1.354-5.843-2.104-9.003,0.197c-1.712-4.267-3.277-7.925-4.643-11.655c-0.848-2.316-2.086-4.174-4.453-5.034
                c-8.137-2.961-12.938-9.036-17.035-16.454c-8.53-15.448-17.756-30.512-26.663-45.752c-0.808-1.382-2.037-2.595-2.134-4.596
                c3.218,0.04,5.24,1.779,6.867,4.188c7.744,11.459,15.155,23.127,21.614,35.365c1.048,1.986,1.996,3.637,4.328,4.398
                c2.919,0.951,5.004,3.039,6.823,5.581c3,4.191,6.026,8.385,9.566,12.169c1.94,2.074,3.743,4.24,6.786,4.771
                c1.16,0.203,2.096,0.957,2.857,1.899c3.979,4.923,7.974,9.832,11.962,14.747C594.551,566.75,594.701,567.193,594.438,567.857z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(222)} fill={props.fillColors[222]} d="M1069.49,225.589c1.977-4.59,4.102-8.489,8.879-10.031
                c0.934,1.194,0.027,2.26-0.342,3.215c-3.281,8.496-4.271,17.412-4.872,26.383c-0.745,11.138-3.366,22.01-4.507,33.1
                c-0.561,5.461-2.55,10.712-6.303,14.975c-5.873,6.669-10.598,14.007-14.082,22.215c-1.665,3.924-3.674,7.763-6.236,11.24
                c-1.67-0.396-3.115-0.664-4.168-2.417c5.271-7.369,9.037-15.66,12.456-24.126c1.683-4.165,3.415-8.324,4.164-12.775
                c0.406-2.418,1.406-4.376,3.206-6.006c3.008-2.723,4.564-6.201,4.479-10.201c-0.223-10.388,2.768-19.99,6.789-29.376
                c0.393-0.915,0.645-1.889,1.012-2.816C1071.794,234.34,1072.975,229.734,1069.49,225.589z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(223)} fill={props.fillColors[223]} d="M754.363,425.51c2.365,3.059,5.263,4.468,6.161,7.568
                c0.862,2.977,3.493,4.756,5.202,7.437c-3.537,2.497-6.884,4.958-10.329,7.272c-7.01,4.71-11.261,11.261-12.818,19.473
                c-0.607,3.202-1.976,5.434-4.84,7.057c-2.845,1.611-5.44,3.66-8.9,6.035C732.041,459.086,738.333,440.419,754.363,425.51z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(224)} fill={props.fillColors[224]} d="M230.97,109.808c7.412,1.19,11.042,5.757,12.758,12.409
                c0.608,2.358,1.711,4.865,3.33,6.62c2.223,2.411,1.946,4.696,1.15,7.349c-1.529,5.091-2.889,10.235-4.518,15.292
                c-2.022,6.276-2.757,12.365,1.565,18.048c0.777,1.022,0.649,2.189,0.66,3.344c0.08,9.029-1.264,17.826-4.61,27.464
                c-0.474-3.405-0.375-5.76,0.072-8.039c1.131-5.76,1.105-11.578,1.144-17.397c0.035-5.31-0.243-5.606-5.432-6.142
                c-0.489-0.05-0.975-0.134-2.054-0.286c-0.376-7.316-0.756-14.713-1.143-22.242c1.383-0.295,2.461-0.525,3.555-0.759
                c1.4-3.207-1.441-4.899-2.374-7.234c0.678-2.918,1.722-5.696,3.004-8.572c2,0.381,2.056,3.217,4.189,3.037
                c-0.47-2.746-3.579-8.621-6.166-11.23c-2.457-2.478-4.942-4.96-7.657-7.139c-2.049-1.645-2.845-3.468-2.585-6.02
                c0.335-3.288-0.376-6.631,0.382-10.191c2.129,0.561,4.149,1.094,6.307,1.663C234.889,103.19,231.985,105.973,230.97,109.808z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(225)} fill={props.fillColors[225]} d="M270.955,171.491c-0.909-1.692-3.716-0.912-3.795-3.578
                c-0.076-2.559,2.29-1.974,3.641-3.461c-0.564-6.216-3.699-11.5-7.96-16.481c0.916-2.189,3.274-2.577,5.008-3.705
                c2.019-1.312,2.968-2.703,2.355-5.355c-1.09-4.725,0.358-9.568-0.169-14.38c-0.263-2.398-0.702-4.88-0.154-7.384
                c4.53,1.643,7.469,5.64,7.295,10.037c-0.421,10.639-0.877,21.278-1.438,31.911c-0.41,7.777-1.136,15.457,0.114,23.324
                c0.927,5.827,0.156,11.951-0.094,17.932c-0.383,9.16-1.267,18.251-5.349,26.685c-0.358,0.739-0.698,1.488-1.08,2.214
                c-0.045,0.086-0.305,0.058-0.713,0.122c-0.852-1.388,0.062-2.781,0.292-4.077c1.708-9.623,1.029-19.257,0.079-28.861
                c-0.581-5.872-0.185-11.56,1.426-17.207C271.149,176.648,271.47,174.021,270.955,171.491z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(226)} fill={props.fillColors[226]} d="M50.824,451.43c-2.695,1.072-5.188,2.506-7.832-0.051
                c-1.013-0.979-2.565-0.52-4.04-1.116c-0.464-3-2.08-5.054-4.681-7.249c-2.466-2.082-4.263-4.378-7.581-4.082
                c-3.129-5.417-7.729-8.965-12.647-9.79c-2.324-4.167-4.04-9.127-9.583-10.015c-0.984-1.675-0.915-3.075-0.224-4.524
                c1.402-0.884,3.01-0.833,4.298-0.206c3.962,1.933,8.123,2.843,12.453,3.526c3.516,0.555,5.439,3.552,7.067,6.642
                c4.085,0.304,7.979,0.959,10.582,4.599c0.432,0.604,1.337,1.074,2.093,1.207c2.975,0.522,4.82,2.649,7.073,4.348
                c3.468,2.614,3.59,6.329,3.472,9.759C51.204,446.544,51.455,448.749,50.824,451.43z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(227)} fill={props.fillColors[227]} d="M1225.697,427.561c0.582-5.679,0.313-10.38-1.484-15.023
                c-1.003-2.591-0.279-5.624-0.012-8.437c0.588-6.181,0.429-12.288-0.919-18.382c-0.759-3.432-0.54-6.965-0.306-10.463
                c0.324-4.82,0.602-9.645,1.011-14.458c0.309-3.631,1.597-4.664,5.118-4.411c2.151,0.154,4.303,0.307,6.715,0.479
                c0.987,4.426-0.494,8.781-1.146,13.112c-0.601,3.981-1.432,7.744,0.66,11.56c1.076,1.963,0.705,4.25,0.381,6.392
                c-1.098,7.242-2.306,14.467-3.379,21.712c-0.34,2.299-0.531,4.641-0.542,6.964C1231.766,422.713,1231.82,422.713,1225.697,427.561
                z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(228)} fill={props.fillColors[228]} d="M75.705,448.979c2.204,1.411,3.878,3.281,4.503,6.099
                c0.501,2.26,2.761,3.316,4.53,4.267c1.851,0.994,2.679,2.271,3.183,4.04c-5.849,4.286-6.66,4.557-12.867,4.472
                c-1.708-0.024-3.714-0.245-4.677-1.371c-2.916-3.409-6.936-4.292-10.638-5.21c-2.068-3.872,0.019-9.715-6.557-10.666
                c-0.364-4.751,0.472-9.666-1.138-14.448c2.812-1.498,5.475-2.205,8.281-0.892c1.458,0.682,2.959,0.125,4.407,0.495
                c1.738,0.443,3.697,0.427,4.377,2.651c0.261,0.853,1.049,1.201,1.839,1.533C74.901,441.609,75.954,444.858,75.705,448.979z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(229)} fill={props.fillColors[229]} d="M57.304,315.524c-2.438-1.202-4.205-2.073-6.279-3.095
                c2.309-11.153,1.235-22.594,4.121-33.615c1.108-4.232,0.715-8.615,0.604-12.933c-0.487-18.971-0.827-37.941-0.819-56.92
                c0.002-5.549,0.891-10.895,2.492-16.266c0.381,0.703-0.188-0.06,0.441,0.16c0.144,0.05,0.338,0.174,0.368,0.299
                c0.194,0.8,0.462,1.604,0.502,2.417c1.472,30.253,3.612,60.498,2.056,90.812c-0.443,8.632-1.026,17.28-2.692,25.798
                C57.915,313.118,57.659,314.04,57.304,315.524z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(230)} fill={props.fillColors[230]} d="M922.716,576.645c2.808-4.079,5.985-6.309,9.906-7.56
                c2.052-0.654,4.06-1.45,6.126-2.052c4.541-1.325,7.994-3.721,9.701-8.425c0.985-2.716,2.92-4.791,6.389-5.218
                c1.654,3.5,2.739,7.416,5.84,10.137c0.66,0.578,0.482,1.564,0.229,2.365c-1.188,3.748-2.408,7.485-3.654,11.343
                c-1.609-0.702-1.434-2.845-3.935-2.21c-0.68,0.219-0.896,1.512-0.983,2.576c-0.197,2.371-1.357,3.968-3.217,5.314
                c-1.627,1.18-2.873,2.5-2.587,4.956c0.226,1.931-0.757,3.774-2.761,5.194c-1.534-2.482-1.28-5.25-2.277-7.602
                c-0.513-1.207-0.883-2.646-2.538-2.712c-1.583-0.063-2.185,1.206-2.907,2.345c-0.992,1.567-0.705,3.99-3.367,4.939
                c-1.271-2.337-0.952-4.883-1.5-7.197c-0.365-1.541-0.867-3.236-2.768-2.783C925.172,580.828,924.724,578.008,922.716,576.645z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(231)} fill={props.fillColors[231]} d="M101.235,246.025c4.91,1.724,4.924,1.723,4.965,5.958
                c0.205,21.167-0.081,42.325-0.932,63.478c-0.32,7.967-0.401,15.945-0.593,23.954c-6.476-1.046-7.84-2.622-7.783-8.529
                c0.148-15.332,0.125-30.663-0.332-45.99c-0.066-2.225,0.096-4.348,1.238-6.27c4.576-7.697,4.997-16.06,4.073-24.662
                C101.607,251.5,101.472,249.022,101.235,246.025z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(232)} fill={props.fillColors[232]} d="M62.533,78.704c0.839,5.674,2.135,11.001,0.202,16.469
                c-0.592,1.675-0.611,3.622-0.577,5.438c0.306,16.017-1.237,31.96-1.962,47.932c-0.392,8.633-1.773,17.222-2.738,25.828
                c-0.572,5.111,0.271,10.357-1.47,15.358c-0.256,0.736-0.074,1.711-1.669,2.065c-0.692-7.078-2.096-14.111-1.913-21.104
                c0.367-13.976,0.88-27.96,1.534-41.928c0.553-11.803,2.976-23.454,3.543-35.28c0.136-2.827,0.63-5.654,0.552-8.469
                C57.949,81.917,59.14,79.949,62.533,78.704z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(233)} fill={props.fillColors[233]} d="M942.551,515.203c-3.698-4.936-8.094-8.705-14.961-7.48
                c-0.16,0.029-0.371,0.071-0.489-0.003c-4.866-3.04-8.671-0.453-12.789,2.007c-2.972,1.775-6.292,2.309-9.775,0.12
                c-2.138-1.343-4.938-1.629-7.47-2.397c4.082-5.14,9.574-4.215,14.917-3.303c1.898,0.324,3.497,0.862,4.995-0.814
                c0.398-0.445,1.301-0.902,1.771-0.748c4.818,1.587,8.223-1.937,12.135-3.402c1.871-0.702,3.538-0.788,5.295-0.357
                c4.047,0.994,8.797,0.789,11.23,5.016c1.662,2.883,3.943,5.147,6.016,7.642c0.525,0.632,0.871,1.358,1.685,1.724
                c3.587,1.611,5.44,4.242,4.905,8.318c-0.083,0.625-0.009,1.729,0.322,1.879c3.496,1.574,3.029,5.107,4.179,7.87
                c0.802,1.927,1.73,3.987,4.053,4.823c1.002,0.361,1.435,1.307,1.389,2.392c-7.022,2.289-11.721-0.111-14.04-6.922
                c-1.339-3.935-2.73-7.871-4.438-11.654C949.11,514.656,948.328,514.373,942.551,515.203z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(234)} fill={props.fillColors[234]} d="M1130.064,405.102c0.441,2.702-0.693,5.032-1.122,7.434
                c-1.028,5.767-0.166,11.721-1.386,17.372c-2.131,9.871-1.984,19.808-2.043,29.772c-0.047,8.147-0.18,16.229-2.99,24.096
                c-1.847,5.166-1.844,10.748-1.681,16.073c-1.985,1.368-3.78,2.104-6.259,2.886c-0.578-4.744-2.107-9.314-0.986-13.794
                c3.085-12.327,3.761-24.953,5.359-37.468c1.6-12.521,3.351-25.024,5.121-37.523
                C1124.615,410.154,1125.369,406.352,1130.064,405.102z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(235)} fill={props.fillColors[235]} d="M123.373,377.748c-1.757-2.178-3.137-5.259-6.811-4.525
                c-2.53-5.073-7.675-4.428-12.03-5.553c-0.761-0.197-1.6-0.695-2.071-1.309c-2.257-2.942-5.365-3.719-8.94-3.512
                c-2.947-4.507-4.66-9.401-5.126-14.505c-0.435-4.768-2.193-8.565-5.406-11.954c-1.073-1.132-2.606-2.112-2.715-3.982
                c1.621-1.4,3.209-0.647,4.688-0.034c2.922,1.212,5.764,2.591,8.049,4.883c1.654,1.658,3.471,3.16,5.61,4.105
                c3.452,1.524,5.752,3.962,6.418,7.688c0.692,3.878,2.732,6.237,6.717,6.853c2.114,0.327,3.588,1.558,4.487,3.543
                c2.536,5.598,5.13,11.169,7.678,16.763C124.112,376.629,124.138,377.141,123.373,377.748z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(236)} fill={props.fillColors[236]} d="M9.654,326.156c3.654-3.686,7.326-5.062,12.009-3.358
                c2.217,0.807,4.041,1.532,4.028,4.257c-0.003,0.682,0.261,1.261,0.775,1.747c5.389,5.088,5.406,5.106,7.889,11.232
                c0.262,0.646,0.579,1.281,1.225,1.479c4.008,1.228,7.311,4.881,11.769,3.354c2.009,1.303,2.112,3.8,4.413,4.751
                c3.421-2.046,6.336-1.349,8.342,2.442c0.626,1.183,1.747,1.409,2.944,1.511c0.846,0.073,1.666,0.275,2.001,1.19
                c0.105,0.287,0.042,0.635,0.057,0.964c-0.808,1.188-1.763,0.379-2.649,0.162c-1.337-0.328-2.979-0.572-3.88,0.227
                c-3.615,3.207-8.422,2.367-12.852,4.189c-1.245-3.526-3.718-5.968-7.23-7.387c-1.793-0.725-2.744-2.339-3.549-4.021
                c-1.297-2.713-3.422-4.393-6.319-5.221c-4.267-1.218-7.941-4.128-12.677-4.127c-2.107,0-2.875-1.8-3.26-3.798
                C12.041,332.387,12.566,328.688,9.654,326.156z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(237)} fill={props.fillColors[237]} d="M73.984,171.591c6.223,29.491,3.081,58.603,4.095,87.583
                c-2.53,0.158-4.125-0.636-5.685-1.684c-0.076-0.492-0.32-1.024-0.202-1.458c1.256-4.613,0.138-8.825-1.958-12.94
                c-0.747-1.468-1.172-3.032-0.854-4.808c1.447-8.076,1.206-16.21,0.816-24.366c-0.469-9.804-0.633-19.623-1.066-29.429
                C68.92,179.728,70.845,175.905,73.984,171.591z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(238)} fill={props.fillColors[238]} d="M103.116,91.354c0.04,8.663-0.353,17.3-1.421,25.883
                c-0.845,6.781-1.467,13.51-0.886,20.375c0.542,6.417,0.271,12.87-0.996,20.054c-3.522-4.603-6.946-8.014-6.276-14.034
                c0.562-5.05-0.17-10.311-0.849-15.41c-0.547-4.106-0.022-7.922,1.266-11.744c0.212-0.629,0.29-1.314,0.566-1.911
                c3.445-7.442,4.231-14.782-0.321-22.164c-1.13-1.833-1.034-4.063-0.641-6.303c0.829-4.725,0.088-9.654,1.669-14.197
                c1.183-1.053,2.735-0.3,3.866-1.109c1.93-1.979,1.124-6.573-1.83-9.321c3.106-1.792,6.163-1.625,9.609-0.673
                c-0.691,4.237-2.283,7.693-5.644,10.222c-2.437,1.833-4.133,3.889-2.397,7.169c0.743,1.403-0.332,2.505-0.911,3.681
                c-1.752,3.562-1.7,3.854,1.186,6.312C100.365,89.256,101.699,90.238,103.116,91.354z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(239)} fill={props.fillColors[239]} d="M424.206,550.633c1.75-1.034,3.061-0.924,4.353-0.508
                c4.854,1.566,9.45,3.788,13.914,6.176c1.803,0.965,3.322,1.814,5.438,1.846c2.904,0.044,5.459,1.346,7.624,3.289
                c3.064,2.751,6.063,4.957,10.561,5.73c4.332,0.744,8.541,3.381,11.625,7.925c-2.737,0.237-4.652,0.901-7.28-0.187
                c-3.351-1.387-7.19-2.277-10.836,0.588c-1.659,1.304-4.133,0.432-6.191-0.408c-3.475-1.418-6.65-3.667-10.571-3.865
                c-1.159-0.06-2.329-0.264-3.475-0.174c-4.512,0.354-7.104-1.021-8.792-4.99C428.435,561.021,426.395,555.945,424.206,550.633z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(240)} fill={props.fillColors[240]} d="M60.033,402.558c-4.216,2.094-8.094,2.42-11.904-1.023
                c-3.029-2.738-6.917-3.846-10.829-4.725c-1.77-0.397-3.56-0.71-5.391-1.072c-0.721-5.265-4.921-8.6-6.836-13.148
                c2.897-8.325,3.885-8.84,12.213-6.802c2.505,0.613,4.844,1.533,6.269,3.703c1.272,1.937,2.857,2.509,5.017,2.331
                c1.153-0.095,2.389,0.044,3.189,1.059C56.29,388.621,60.743,394.404,60.033,402.558z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(241)} fill={props.fillColors[241]} d="M446.826,479.797c-7.673,0.199-14.897,1.986-22.772,3.311
                c-0.25-17.195,0.43-33.646,1.285-50.182c3.803,4.271,7.32,8.493,7.398,14.582c0.052,3.977,0.546,7.946,0.81,11.92
                c0.121,1.819,0.815,3.171,2.648,3.786c5.226,1.752,7.924,5.613,9.099,10.81C445.686,475.762,447.04,477.23,446.826,479.797z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(242)} fill={props.fillColors[242]} d="M103.508,148.456c-3.142-19.286,2.09-37.472,1.613-56.131
                c3.173,0.054,5.706,0.524,8.34,1.12c0.614,2.393,0.386,4.542,0.213,6.664c-1.004,12.326-0.814,24.625,0.74,36.898
                c0.462,3.653,0.541,7.314,0.076,10.966c-0.48,3.776-1.431,4.375-4.858,3.067C107.475,150.217,105.373,149.248,103.508,148.456z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(243)} fill={props.fillColors[243]} d="M1067.254,226.626c2.77,2.679,3.182,5.221,2.133,8.127
                c-1.922,5.318-3.744,10.674-5.781,15.946c-1.822,4.715-2.919,9.496-2.826,14.592c0.049,2.643-0.439,5.298-0.722,7.944
                c-0.277,2.6-1.454,4.791-3.385,6.497c-2.5,2.21-3.389,5.042-4.255,8.193c-3.367,12.256-8.396,23.828-15.508,34.428
                c-0.449,0.67-0.865,1.444-2.191,1.273c-0.567-1.801,0.809-3.068,1.663-4.232c3.167-4.314,4.677-9.038,4.878-14.396
                c0.154-4.142,1.045-8.252,1.479-12.39c0.145-1.379,0.721-2.468,1.303-3.673c3.541-7.328,7.208-14.569,8.43-22.825
                c0.9-6.096,3.16-11.893,5.805-17.475C1061.604,241.609,1065.872,234.998,1067.254,226.626z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(244)} fill={props.fillColors[244]} d="M82.047,73.982c3.853,0.228,6.761-2.534,10.965-1.907
                c-1.144,4.43-0.657,8.77-1.381,12.986c-0.593,3.452-0.114,6.588,1.277,9.703c0.678,1.519,1.145,3.14,1.909,4.609
                c1.239,2.38,1.251,4.604,0.268,7.15c-1.606,4.162-2.815,8.478-4.281,13.006c-2.62-2.032-4.736-4.331-8.156-3.742
                c-0.886,0.153-1.32-0.864-1.889-1.454c-1.501-1.557-3.035-3.083-4.562-4.614c-2.595-2.601-2.847-4.907-0.545-7.856
                c1.641-2.103,3.245-4.209,4.65-6.481c2.58-4.169,3.447-8.593,2.327-13.393C82.061,79.556,81.876,77.09,82.047,73.982z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(245)} fill={props.fillColors[245]} d="M773.873,329.179c0.105,1.412,0.239,2.074,0.189,2.72
                c-1.286,16.418-1.092,32.969-4.449,49.204c-0.947,4.579-1.312,9.246-1.248,13.913c0.08,5.851-0.169,11.654-1.316,17.406
                c-1.242,6.229-1.188,12.593-1.927,18.875c-0.146,1.23,0.28,2.614-0.63,3.674c-1.473-0.015-1.59-1.21-2.05-2.012
                c-0.574-1.003-0.733-2.497-1.562-3.059c-4.278-2.908-3.425-6.902-3.029-11.115c1.219-12.966,4.101-25.633,6.393-38.41
                c2.734-15.238,5.162-30.531,7.697-45.804C772.209,332.955,772.277,331.3,773.873,329.179z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(246)} fill={props.fillColors[246]} d="M89.857,360.421c-2.191,0.176-4.11,0.33-6.375,0.512
                c-2.173-3.734-5.794-4.617-10.006-4.999c-7.033-0.638-7.752-1.565-8.991-8.675c-0.542-3.109-0.983-6.25-1.206-9.396
                c-0.195-2.752-0.785-5.258-2.873-7.293c1.267-2.374,3.15-3.936,5.117-5.571c3.395,2.901,6.977,5.319,10.638,7.615
                c3.622,2.271,6.009,5.708,8.548,8.983c0.677,0.873,1.112,2.093,1.244,3.203C86.578,350.101,87.797,355.247,89.857,360.421z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(247)} fill={props.fillColors[247]} d="M587.586,429.816c0.843-2.485,2.777-3.614,4.211-5.116
                c2.866-3,5.521-5.984,7.265-9.956c1.409-3.208,3.96-6.222,7.905-6.963c4.732-0.89,9.482-1.628,13.069-6.543
                c2.918,5.421,8.063,7.997,10.463,13.469c-5.375-0.432-8.17,2.908-10.391,6.681c-2.663,4.526-5.75,6.944-11.547,6.024
                c-5.924-0.939-11.796,1.466-17.738,2.024C589.851,429.528,588.882,429.663,587.586,429.816z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(248)} fill={props.fillColors[248]} d="M854.857,392.337c-3.842-3.621-6.234-7.572-8.979-11.23
                c-0.657-0.875-0.537-1.881-0.485-2.879c0.378-7.335-0.243-14.653-0.438-21.977c-0.129-4.868,0.311-9.639,1.48-14.358
                c0.889-3.581,1.346-7.257,0.879-10.897c-0.677-5.26,1.198-9.73,3.854-15.099c1.772,6.092,3.355,11.343,4.789,16.635
                c0.242,0.896-0.104,1.974-0.271,2.952c-0.225,1.311-0.436,2.64-0.834,3.903c-2.846,8.997-2.438,17.997-0.437,27.099
                C856.25,374.83,855.521,383.262,854.857,392.337z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(249)} fill={props.fillColors[249]} d="M408.157,510.383c-3-6.105-6.092-12.168-8.937-18.345
                c-0.74-1.605-0.841-3.555-1.008-5.367c-1.579-17.073-3.151-34.146-4.637-51.227c-0.917-10.545-0.461-21.108-0.218-31.696
                c2.607,8.624,4.417,17.39,5.627,26.229c1.488,10.875,2.358,21.833,3.529,32.753C404.215,478.602,406.36,494.416,408.157,510.383z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(250)} fill={props.fillColors[250]} d="M1035.465,461.738c2.236,2.5,2.369,4.857,2.293,7.154
                c-0.318,9.76,1.51,19.221,3.859,28.607c1.209,4.83,2.795,9.601,0.86,15.257c-3.669,1.114-3.89,4.823-4.136,8.54
                c-0.605,9.131-2.056,18.15-3.457,27.713c-2.226-6.864-2.671-14.723-1.351-21.682c0.906-4.773,0.722-9.22-0.674-14.139
                c-1.822-6.419-3.246-13.199-2.34-20.168c0.772-5.936,0.972-11.946,1.36-17.928
                C1032.175,470.574,1033.335,466.318,1035.465,461.738z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(251)} fill={props.fillColors[251]} d="M1146.751,438.364c-0.82-1.425-1.507-2.217-1.761-3.129
                c-1.092-3.907-3.567-6.626-6.924-8.737c-2.039-1.283-3.308-3.247-4.033-5.524c-1.599-5.02-3.782-9.951-1.624-15.419
                c0.64-1.619,1.229-2.967,2.988-3.646c5.714-2.208,11.22-4.885,16.536-7.814c1.414,1.195,0.389,2.308,0.217,3.296
                c-0.803,4.593-1.229,9.209-1.186,13.868c0.078,8.519-1.223,16.863-3.185,25.121
                C1147.676,436.822,1147.357,437.213,1146.751,438.364z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(252)} fill={props.fillColors[252]} d="M1115.987,462.354c-0.882,6.943-1.052,13.131-2.758,19.055
                c-1.84,6.385-1.799,12.777-0.959,19.259c0.591,4.554,0.191,5.67-4.034,7.19c-3.856,1.388-6.5,3.909-8.641,7.157
                c-1.363,2.07-2.815,3.711-5.291,4.782c-2.322,1.005-4.832,2.029-7.311,5.121c-0.01-4.125-0.459-7.056,0.467-9.872
                c2.514-7.654,5.669-15.038,12.31-20.097c5.037-3.837,7.85-8.826,9.388-14.823c1.278-4.98,2.539-9.985,4.68-14.705
                C1114.245,464.525,1114.494,463.568,1115.987,462.354z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(253)} fill={props.fillColors[253]} d="M788.403,450.986c1.942,0.686,2.128,2.402,2.504,4.01
                c0.778,3.328,2.296,6.144,5.137,8.25c2.727,2.02,3.667,5.208,3.941,8.311c0.246,2.771-1.284,5.114-4.046,6.247
                c-2.751,1.128-4.054,3.299-4.592,6.054c-0.352,1.798-0.947,3.479-1.662,5.179c-1.643,3.91-8.185,8.608-13.098,8.759
                c-2.043,0.062-2.764-0.93-2.424-2.736c0.366-1.949,0.605-4.035,1.494-5.754c5.947-11.496,8.487-24.033,11.42-36.457
                C787.226,452.221,787.279,451.526,788.403,450.986z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(254)} fill={props.fillColors[254]} d="M1200.885,214.973c-8.494-12.403-14.055-25.558-24.005-36.737
                c5.181,0.38,9.361,1.541,12.966,4.261c6.651,5.018,12.789,10.646,18.918,16.277c1.113,1.022,1.99,2.229,2.53,3.667
                c1.809,4.81,3.631,9.614,5.732,15.173C1211.023,216.631,1205.719,215.764,1200.885,214.973z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(255)} fill={props.fillColors[255]} d="M1125.941,502.641c-3.646,1.673-6.018,4.141-9.192,5.658
                c-3.382,1.617-6.608,4.022-7.606,8.129c-0.297,1.222-1.023,2.049-1.863,2.867c-1.215,1.185-2.102,2.588-2.535,4.234
                c-0.803,3.045-1.842,5.77-4.135,8.273c-1.736,1.896-2.054,5.096-2.984,7.721c-0.666,1.878-1.262,3.781-1.973,5.641
                c-0.288,0.754-0.627,1.779-1.235,2.071c-4.159,1.998-6.008,6.408-10.302,9.49c-0.256-5.817-0.861-11.135,0.312-16.487
                c0.713-3.248,1.705-6.452,2.176-9.732c0.531-3.705,2.623-6.171,5.775-7.513c4.208-1.792,7.652-4.164,9.845-8.313
                c1.277-2.419,3.407-4.043,6.136-4.783c1.984-0.539,3.525-1.76,4.932-3.246c2.488-2.63,5.862-3.721,9.131-4.943
                C1123.385,501.347,1124.387,501.507,1125.941,502.641z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(256)} fill={props.fillColors[256]} d="M72.13,469.205c-0.97,1.832-3.401,2.143-3.423,4.664
                c2.155,1.251,3.63,3.107,4.19,5.926c0.517,2.602,2.764,4.363,5.326,4.674c3.509,0.424,5.679,2.431,7.775,5.068
                c-4.91,2.805-9.464,1.314-13.659-1.283c-2.779-1.721-5.627-2.795-8.8-3.132c-7.061-0.75-10.176-3.899-10.467-11.063
                c-0.071-1.74-0.348-3.28-1.231-4.713c-0.831-1.348-1.034-2.753-0.797-4.296c0.491-3.2-0.172-5.929-3.202-7.707
                c-0.603-0.354-0.652-1.11-0.534-2.103c2.165-1.649,4.753-2.299,7.853-2.102c0.544,2.247,1.976,4.129,1.824,6.578
                c-0.105,1.716,1.05,2.644,2.488,3.367c2.233,1.121,5.072,0.597,6.98,2.422C68.115,467.094,69.639,468.797,72.13,469.205z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(257)} fill={props.fillColors[257]} d="M375.651,296.945c-2.352-3.406-3.555-6.148-3.354-9.101
                c0.343-5.057-0.341-9.978-1.225-14.894c-1.833-10.195-2.016-20.55-3.061-30.821c-0.378-3.723-1.515-7.423-0.927-11.252
                c1.961,0.132,1.425,2.744,3.469,2.775c-1.328-10.741-2.501-21.467-4.058-32.138c-0.54-3.699-0.246-7.295-0.115-10.938
                c0.383-10.695-0.653-21.304-1.846-31.906c-0.165-1.462-0.625-2.921-0.104-4.866c2.516,4.154,3.079,8.465,3.461,12.705
                c1.763,19.581,3.051,39.197,4.18,58.826c1.109,19.284,2.409,38.558,3.6,57.837C375.933,287.389,376.379,291.621,375.651,296.945z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(258)} fill={props.fillColors[258]} d="M1122.982,498.943c-0.762-3.727-0.592-7.795,0.644-11.491
                c3.663-10.959,3.767-22.202,3.61-33.532c-0.095-6.821,0.238-13.606,1.662-20.341c1.058-5.003,1.143-10.165,1.502-15.346
                c1.901,2.772,3.137,5.635,2.709,9.102c-1.327,10.75-0.984,21.598-1.817,32.383c-0.518,6.71,0.085,13.323,3.706,19.286
                c2.065,3.4,1.421,7.023,1.102,10.682c-0.693,7.941,0.151,8.738-8.121,9.265C1126.414,499.051,1124.923,500.404,1122.982,498.943z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(259)} fill={props.fillColors[259]} d="M565.54,393.708c9.783,1.343,18.297,6.518,28.808,8.6
                c-4.701,4.006-7.688,8.346-13.97,8.05c-3.753-0.176-6.853,2.266-9.636,5.304c-4.303-2.01-8.507-1.293-12.702,0.787
                c-2.941,1.458-5.497,3.04-6.272,6.541c-0.413,1.863-1.51,3.276-3.795,3.887c0.061-3.401,1.404-6.23,3.101-8.818
                c1.908-2.909,4.088-5.651,6.277-8.362C561.123,405.025,564.235,400.037,565.54,393.708z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(260)} fill={props.fillColors[260]} d="M1087.553,348.241c-3.336-3.86-7.528-5.416-11.961-6.642
                c0.613-4.566,2.691-8.106,5.339-11.342c2.353-2.875,3.86-5.906,4.507-9.736c1.059-6.269,3.557-12.074,8.411-16.606
                c2.034-1.898,3.749-4.139,5.55-6.157c3.746,1.951,4.768,3.323,3.403,6.136c-4.001,8.251-5.567,17.439-10.043,25.485
                c-2.405,4.324-2.634,9.232-3.673,13.92C1088.77,344.729,1088.971,346.296,1087.553,348.241z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(261)} fill={props.fillColors[261]} d="M268.74,140.845c-1.614,2.101-3.616,2.673-5.263,3.765
                c-3.462,2.298-3.521,4.081-0.199,6.702c0.39,0.308,1.144,0.594,1.143,0.888c-0.017,4.043,4.161,6.335,4.223,10.753
                c-1.821,0.798-3.65,1.768-3.352,4.74c0.268,2.67,0.75,4.578,3.888,4.464c0.323,4.903-0.926,8.926-2.376,13.271
                c-2.529-1.788-4.071-3.98-5.9-5.877c-1.188-1.232-0.958-2.767-0.961-4.245c-0.015-6.663-0.244-13.336,0.05-19.987
                c0.438-9.898-1.215-19.59-2.432-29.325c-0.297-2.382-0.235-4.604,0.357-6.889c0.902-3.477,1.947-4.491,5.303-4.926
                c2.787-0.362,4.203,0.509,4.393,3.293c0.351,5.144,0.671,10.315,0.493,15.458C268.013,135.615,267.819,138.216,268.74,140.845z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(262)} fill={props.fillColors[262]} d="M150.061,489.271c4.517-1.91,8.364,0.234,12.138,1.459
                c2.685,0.871,2.819,3.801,2.548,6.479c2.001,1.088,4.811,0.988,5.301,3.959c0.085,0.512,0.824,1.217,1.35,1.307
                c3.746,0.637,3.354,2.812,3.07,5.869c-0.242,2.611,0.683,5.386,0.681,8.217c-0.002,3.313-1.089,5.729-3.496,8.018
                c-4.146-2.252-8.289-4.554-12.484-6.758c-1.694-0.889-2.27-2.033-2.072-3.996c0.617-6.137-0.58-12.005-4.306-16.943
                C151.071,494.601,150.329,492.335,150.061,489.271z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(263)} fill={props.fillColors[263]} d="M1163.705,386.007c0,4.676,0.04,8.836-0.012,12.995
                c-0.046,3.694,0.237,7.323,1.637,10.789c1.242,3.08,0.908,6.245,0.336,9.361c-0.904,4.922-1.668,9.847-1.686,14.867
                c-0.004,0.983,0.014,2.073-0.378,2.932c-3.778,8.28-4.8,17.251-6.653,26.015c-0.713,3.37-1.029,6.878-3.445,9.804
                c-0.63-4.658,0.045-9.311,0.726-13.846c1.046-6.969,1.147-13.952,1.02-20.927c-0.103-5.541,0.37-10.975,1.351-16.38
                c1.402-7.723,1.035-15.613,2.356-23.338c0.448-2.625,0.817-5.263,1.281-7.884C1160.559,388.575,1161.18,386.922,1163.705,386.007z
                "/>
            <path fill-rule="evenodd" onClick={() => props.onFill(264)} fill={props.fillColors[264]} d="M950.463,409.398c0.457-0.258,0.723-0.515,1.021-0.562
                c6.311-0.99,12.504,0.538,18.737,1.017c2.056,0.158,3.162,2.289,4.198,4.006c2.101,3.481,4.9,6.242,8.258,8.523
                c3.204,2.178,5.174,8.56,3.835,12.321c-0.773,2.172-1.786,4.26-2.708,6.428c-1.904-0.723-2.336-2.453-3.207-3.777
                c-4.705-7.158-10.542-12.816-19.217-14.811c-3.145-0.723-6.007-1.943-8.052-4.593c-0.499-0.646-1.542-1.414-1.13-2.072
                C953.967,413.057,951.628,411.371,950.463,409.398z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(265)} fill={props.fillColors[265]} d="M211.207,203.147c-1.956-8.199-5.421-15.935-4.652-24.704
                c0.9-10.26,2.437-20.481,2.465-30.812c0.011-4.144,0.002-8.288,0.002-12.673c3.156,0.367,5.584,2.273,8.393,3.098
                c1.487,0.437,1.514,2.03,1.516,3.386c0.006,7.809,0.002,15.618,0.002,23.861c-3.822,0.297-3.869,3.219-4.402,6.062
                C212.56,181.854,211.657,192.428,211.207,203.147z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(266)} fill={props.fillColors[266]} d="M224.852,95.011c-1.583,5.606-0.581,10.766-0.95,15.867
                c-0.126,1.741,0.877,2.731,2.016,3.766c2.834,2.575,5.586,5.241,8.43,7.805c2.352,2.12,3.364,4.396,1.591,7.426
                c-1.125,1.922-1.214,4.348-2.948,6.676c-1.863-5.826,1.266-12.453-4.271-16.716c-1.345-1.036-2.114-2.937-4.61-3.037
                c0.434,2.242,2.079,3.204,3.238,4.42c2.06,2.163,3.268,4.542,2.965,7.614c-0.129,1.317,0.059,2.663,0.036,3.994
                c-0.056,3.359,0.807,6.248,3.88,8.121c0.563,0.343,0.962,0.912,0.948,1.651c-3.274,1.753-3.346,1.756-3.21,5.546
                c0.179,4.993,0.518,9.979,0.72,14.971c0.072,1.796,0.544,3.639-0.266,5.346c-1.163,0.741-2.258,0.463-3.354,0.156
                c-0.999-2.332-0.686-4.689-0.77-6.987c-0.116-3.167,0.024-6.345-2.395-8.917c-0.996-1.059-0.655-2.526-0.659-3.839
                c-0.015-4.83-0.101-9.663,0.045-14.488c0.089-2.941-0.987-5.005-3.308-6.8c-4.751-3.675-4.885-4.58-2.081-9.751
                c0.769-1.417,0.74-2.875,0.699-4.364c-0.118-4.329-0.353-8.656-0.38-12.984C220.196,96.849,221.061,95.844,224.852,95.011z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(267)} fill={props.fillColors[267]} d="M229.057,250.758c0.359,9.697,2.2,19.215,3.396,28.774
                c0.926,7.401,0.959,14.974,3.41,22.158c0.257,0.755,0.127,1.652,0.127,2.484c0.003,16.83,0.002,33.66-0.011,50.49
                c0,0.478-0.132,0.956-0.578,1.535c-2.457-7.233-6.492-13.846-6.416-21.989c0.218-23.327,0.074-46.657,0.072-69.985
                C229.057,259.736,229.057,255.247,229.057,250.758z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(268)} fill={props.fillColors[268]} d="M190.498,78.333c3.627,0.747,5.256,2.559,6.651,4.643
                c1.193,1.782,1.974,3.856,3.943,5.055c1.411,0.859,1.057,2.331,0.987,3.618c-0.227,4.193-1.445,8.189-2.755,12.126
                c-1.375,4.131-1.647,8.077,0.442,12.066c0.598,1.143,1.021,2.542,1.001,3.817c-0.187,12.64,0.63,25.304-0.805,37.914
                c-0.262,2.304-0.673,4.592-1.019,6.917c-4.713-1.542-5.003-1.688-4.692-6.041c0.616-8.628,0.371-17.31,1.609-25.899
                c0.633-4.392,0.574-8.663-2.381-12.358c-1.064-1.331-0.75-2.819-0.622-4.303c0.611-7.128,1.5-14.222,2.724-21.271
                c0.549-3.163,0.214-5.762-2.902-7.752C189.511,84.84,191.881,81.471,190.498,78.333z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(269)} fill={props.fillColors[269]} d="M1121.46,299.803c-0.237,3.087-0.265,5.879-0.703,8.604
                c-0.827,5.144-0.835,10.276-0.186,15.412c0.349,2.757-0.046,5.203-1.604,7.584c-3.609,5.511-4.89,11.967-6.977,18.086
                c-0.915,2.684-1.899,5.309-3.201,7.83c-1.08,2.089-2.614,3.204-4.809,1.928c-2.234-1.301-4.869-0.664-6.979-1.581
                c-0.832-1.92,0.593-2.669,1.425-3.569c3.1-3.35,5.077-7.224,6.729-11.504c3.962-10.263,7.49-20.6,9.234-31.501
                c0.364-2.28,1.103-4.532,1.933-6.696C1117.15,302.239,1118.389,300.296,1121.46,299.803z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(270)} fill={props.fillColors[270]} d="M858.681,526.74c-1.146,6.111-1.136,12.474-3.214,18.464
                c-1.646,4.741-2.158,9.71-2.886,14.633c-1.53,10.341-4.453,20.454-5.065,30.947c-0.146,2.485-0.549,4.958-0.637,7.444
                c-0.097,2.739-0.68,5.249-2.584,7.488c-1.652-1.043-3.86-0.656-5.537-2.027C840.388,576.791,846.474,551.031,858.681,526.74z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(271)} fill={props.fillColors[271]} d="M478.835,491.603c-0.258,2.07,0.378,3.562-0.475,5.177
                c-3.897,1.719-7.698,4.16-12.52,3.075c-1.002-0.226-2.386,0.226-3.302,0.816c-4.345,2.804-8.897,2.224-13.127,0.335
                c-2.672-1.193-5.046-3.405-7.029-5.628c-0.907-1.017-2.289-1.006-2.971-2.148c1.811-3.323,5.326-4.347,8.343-4.819
                c4.78-0.75,9.238-1.989,13.41-4.326c2.067-1.159,4.219-0.55,6.333-0.561c1.56-0.008,2.81,0.66,3.597,2.199
                C472.658,488.777,475.022,490.959,478.835,491.603z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(272)} fill={props.fillColors[272]} d="M946.668,498.945c-5.348-0.804-10.041-2.4-15.021-2.502
                c-1.105-1.506-1.379-3.326-1.901-5.053c-0.786-2.6-1.476-5.26-5.19-4.723c-1.168,0.168-1.588-1.037-2.238-1.746
                c-4.867-5.314-12.318-6.178-18.149-1.857c-1.492,1.105-3.208,1.588-5.227,3.184c1.624-5.513,3.592-10.19,3.972-15.261
                c0.094-1.266,1.258-2.499,2.318-2.14c2.549,0.863,5.932-0.068,7.587,1.453c3.085,2.835,6.884,3.331,10.296,4.996
                c2.969,1.449,6.312,2.329,8.809,4.752c2.274,2.207,4.006,5.303,6.972,6.385c3.317,1.211,4.401,4.067,5.656,6.518
                C945.357,494.529,946.18,496.492,946.668,498.945z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(273)} fill={props.fillColors[273]} d="M454.089,479.595c1.411-2.216,3.074-3.128,4.672-3.845
                c5.304-2.376,5.797-6.806,5.278-11.688c-0.173-1.628-0.711-3.216-1.193-5.3c4.001,0.362,7.676,0.643,10.58,3.425
                c3.026,2.899,6.454,5.2,10.099,7.236c3.615,2.02,5.732,5.398,8.142,9.048C479.148,483.635,466.869,483.024,454.089,479.595z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(274)} fill={props.fillColors[274]} d="M671.9,513.066c-0.566,2.193-2.08,2.757-3.23,3.559
                c-4.771,3.323-9.601,6.564-14.447,9.778c-3.736,2.478-7.473,3.518-12.137,1.863c-6.9-2.446-14.299-2.809-21.542-3.703
                c-0.969-0.12-1.995,0.12-2.828-0.984c5.667-4.135,11.438-6.981,18.806-5.338c2.001,0.446,4.519-0.667,6.454-2.147
                c4.51-3.451,9.708-3.246,15.021-2.976c3.672,0.188,7.379,1.509,11.089-0.011C669.783,512.822,670.695,513.066,671.9,513.066z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(275)} fill={props.fillColors[275]} d="M126.384,118.666c4.408,1.698,8.043,3.276,9.925,7.529
                c1.279,2.892,3.171,5.49,5.603,7.582c1.789,1.539,2.559,3.292,2.17,5.701c-0.419,2.603-0.162,5.302-1.028,8.218
                c-6.564,2.236-14.046-1.763-21.12,3.849C122.875,139.7,125.145,129.479,126.384,118.666z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(276)} fill={props.fillColors[276]} d="M922.314,487.36c-6.627,3.304-7.707,9.286-10.384,14.049
                c-1.118,0.83-2.428,0.921-3.429,0.548c-5.172-1.922-9.447,0.643-12.673,3.668c-3.417,3.205-6.567,2.468-10.327,1.863
                c3.967-2.701,4.316-7.051,5.479-10.897c1.241-4.108,3.207-6.958,7.749-7.982c2.261-0.51,4.498-2.039,6.266-3.638
                c3.872-3.502,8.17-2.896,12.579-1.914C919.79,483.549,920.096,485.984,922.314,487.36z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(277)} fill={props.fillColors[277]} d="M909.628,468.288c-2.814-1.712-5.011-1.712-7.452-0.278
                c-2.186,1.286-4.837,0.668-7.521,0.677c0.033-2.879,1.707-4.841,3.292-6.606c4.91-5.473,7.671-11.786,9.229-18.966
                c1.726-7.946,4.389-15.685,7.252-23.315c1.371-3.655,1.679-3.719,6.611-3.37c-0.253,5.471,0.922,11.001-2.505,16.17
                c-3.349,5.05-5.216,10.607-5.802,16.772C912.156,455.443,911.488,461.577,909.628,468.288z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(278)} fill={props.fillColors[278]} d="M808.332,395.562c-3.092-1.271-3.857-4.061-4.258-6.848
                c-0.782-5.434-1.428-10.895-1.881-16.365c-0.358-4.324-1.109-8.575-1.738-12.854c-0.676-4.601-1.672-9.162-1.463-13.879
                c0.205-4.649,0.895-9.504-3.725-12.537c1.582-3.908,4.369-5.375,7.482-6.57c0.543,1.222-0.218,1.812-0.629,2.454
                c-1.602,2.497-1.447,4.932,0.896,6.643c1.933,1.412,2.062,3.122,2.228,5.166c0.334,4.141,0.577,8.304,1.637,12.36
                c1.395,5.33,2.283,10.716,1.947,16.274c-0.12,1.975-0.192,4.137,0.466,5.939c1.892,5.181,1.525,10.459,1.474,15.781
                C810.748,393.182,810.32,394.646,808.332,395.562z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(279)} fill={props.fillColors[279]} d="M620.915,400.065c-0.071-2.398,1.358-3.675,2.382-5.156
                c3.506-5.072,6.773-10.23,8.414-16.306c1.275-4.723,4.305-7.263,8.818-7.746c-0.621,1.963-1.98,3.558-1.927,5.526
                c0.049,1.827,0.104,3.654,0.171,5.48c0.34,9.325-1.31,18.599-0.726,27.927c0.113,1.798-0.864,2.663-2.268,3.468
                c-1.696,0.973-3.177,0.298-3.944-0.929c-2.13-3.406-5.803-5.399-7.804-8.843C623.188,402.034,621.845,401.055,620.915,400.065z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(280)} fill={props.fillColors[280]} d="M77.391,279.234c0.975,9.087-1.313,38.603-3.664,46.877
                c-3.51-1.235-5.776-4.172-8.635-6.196c-1.101-0.779-0.94-2.302-0.662-3.705c1.906-9.628,2.582-19.411,3.251-29.174
                c0.214-3.127,1.269-5.029,4.151-5.908C73.693,280.562,75.346,279.311,77.391,279.234z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(281)} fill={props.fillColors[281]} d="M620.07,330.583c0.053,6.469-5.126,11.741-11.6,11.811
                c-6.447,0.069-11.762-5.157-11.801-11.604c-0.039-6.482,5.157-11.786,11.588-11.827
                C614.677,318.92,620.019,324.175,620.07,330.583z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(282)} fill={props.fillColors[282]} d="M1121.539,414.048c-0.832,13.033-2.748,25.826-4.701,38.613
                c-0.147,0.967-0.578,1.904-0.964,2.818c-3.488,8.266-6.749,16.602-8.863,25.358c-1.368,5.664-4.282,10.311-9.875,13.35
                c-0.157-2.143,0.755-3.779,1.393-5.408c5.214-13.317,8.175-27.341,12.823-40.844c1.602-4.649,2.004-9.741,2.687-14.668
                C1115.011,426.242,1118.206,420.134,1121.539,414.048z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(283)} fill={props.fillColors[283]} d="M916.613,518.158c-1.499,2.66-2.26,4.843-4.535,6.66
                c-3.387-3.895-7.782-5.47-12.553-4.998c-0.549-0.431-0.932-0.578-1.041-0.84c-2.092-4.977-2.713-5.228-7.005-2.025
                c-5.599,4.177-10.98,8.61-15.019,14.768c-1.346-1.676-2.622-3.152-3.775-4.722c-2.742-3.732-2.067-6.87,1.986-9.161
                c0.59-0.334,1.061-0.693,1.525-1.174c5.173-5.349,10.154-7.245,17.241-6.805c4.412,0.273,8.948-0.084,12.604,3.156
                C910.67,512.266,912.604,516.602,916.613,518.158z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(284)} fill={props.fillColors[284]} d="M454.201,440.229c-2.92-1.09-5.537-2.747-8.571-4.934
                c2.985-20.222-1.169-40.826,1.349-62.438c3.084,5.135,4.929,9.266,5.123,14.352c0.623,16.312,1.691,32.608,2.563,48.912
                C454.733,437.396,455.193,438.731,454.201,440.229z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(285)} fill={props.fillColors[285]} d="M167.111,471.314c-2.093-0.131-3.296-0.85-3.94-2.405
                c-0.992-2.393-2.56-3.832-5.377-3.31c-1.754,0.324-3.371-0.922-3.646-2.042c-1.026-4.169-5.453-5.146-7.333-8.466
                c-0.876-1.546-2.824-1.54-4.522-1.523c-1.266,0.013-2.407-0.285-2.437-1.815c-0.031-1.583,1.276-1.946,2.417-1.848
                c7.068,0.607,14.214-0.495,21.105,3.086c3.247,1.687,6.933,2.48,9.831,5.117c2.122,1.929,3.093,4.617,5.107,6.612
                c1.059,1.048,0.42,2.728-1.165,3.108c-3.013,0.724-6.073,1.164-8.563,3.286C168.172,471.469,167.307,471.294,167.111,471.314z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(286)} fill={props.fillColors[286]} d="M995.366,473.153c-1.736-0.874-1.386-2.317-1.311-3.599
                c0.306-5.145,0.467-10.309,1.054-15.424c0.945-8.247,0.522-16.247-2.846-23.971c-0.49-1.124-1.271-2.345-0.756-4.058
                c4.023,0.019,7.428,0.826,8.762,5.396c0.317,1.089,0.918,2.197,1.676,3.038c4.788,5.317,5.408,11.943,5.99,18.589
                c0.276,3.162-1.284,5.777-3.81,7.021c-4.04,1.99-5.763,5.287-6.939,9.213C996.777,470.725,996.723,472.295,995.366,473.153z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(287)} fill={props.fillColors[287]} d="M532.527,390.747c3.763-2.599,7.61-4.537,11.452-6.481
                c1.734-0.877,3.644-0.919,5.367-0.335c4.689,1.588,8.822,0.098,12.939-1.889c1.346-0.65,2.733-1.214,4.288-1.899
                c2.664,4.071,0.046,7.381-1.645,10.559c-0.936,1.759-2.15,3.171-1.943,5.37c0.138,1.464-0.841,2.411-2.383,2.713
                c-4.905,0.963-9.75,2.25-14.217-1.588c-1.731-1.487-4.073-2.239-6.001-3.528c-1.22-0.816-2.424-0.963-3.778-0.823
                C535.074,393.005,533.603,392.981,532.527,390.747z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(288)} fill={props.fillColors[288]} d="M404.23,397.079c-1.22-2.024-2.261-3.695-3.244-5.4
                c-0.594-1.029-1.024-2.201-0.49-3.326c1.318-2.772,0.756-5.563,0.035-8.26c-1.249-4.666,0.109-8.53,3.282-11.852
                c2.772-2.903,5.207-5.936,5.536-10.005c1.875-0.382,2.451,0.54,2.436,1.857c-0.024,2.153-0.247,4.303-0.31,6.456
                c-0.111,3.812,0.07,7.451,1.987,11.05c1.78,3.342,1.821,7.353,2.423,11.107c0.302,1.877-0.768,3.191-2.283,4.078
                C410.755,394.449,407.884,396.087,404.23,397.079z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(289)} fill={props.fillColors[289]} d="M32.676,331.508c4.213-1.977,8.316-2.705,12.74-1.809
                c3.409,0.69,6.946,0.218,10.403,0.867c2.788,0.523,4.713,1.996,5.167,4.665c0.886,5.202,1.417,10.465,2.17,16.287
                c-3.514-2.942-6.113-6.818-10.705-4.299c-1.482-0.242-1.661-1.392-2.192-2.174c-1.133-1.671-2.593-2.741-4.608-2.034
                c-2.512,0.881-3.965-1.274-5.979-1.758c-2.336-0.561-3.64-1.878-3.95-4.409C35.479,334.864,32.869,334.065,32.676,331.508z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(290)} fill={props.fillColors[290]} d="M116.033,95.462c4.853,0.925,6.281,3.647,7.345,8.435
                c2.633,11.854-0.551,23.09-1.835,34.552c-0.24,2.144-0.691,4.265-1.055,6.395c-0.34,1.99-1.373,3.31-3.85,3.65
                C116.059,130.802,113.541,113.223,116.033,95.462z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(291)} fill={props.fillColors[291]} d="M105.934,244.872c-0.643,0.12-0.831,0.219-0.963,0.17
                c-10.143-3.736-10.104-3.741-11.182-14.539c-1.153-11.567-2.462-23.122-2.927-34.745c-0.146-3.637-0.023-7.284-0.023-11.466
                c3.15,1.392,3.597,3.651,3.73,6.143c0.391,7.298,0.902,14.591,1.191,21.894c0.196,4.96,1.728,9.074,6.213,11.681
                c3.013,1.751,4.231,4.268,4.021,7.815C105.739,236.111,105.934,240.424,105.934,244.872z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(292)} fill={props.fillColors[292]} d="M1152.133,286.363c4.773,0.553,8.323,2.424,10.104,7.045
                c0.834,2.163,1.941,4.246,3.137,6.238c2.482,4.132,2.918,8.074-0.972,12.081c-1.706,0.37-3.876-0.072-5.993,0.463
                c-3.568,0.902-7.566-1.135-8.545-4.75c-1.169-4.317-2.094-8.778-2.4-13.227C1147.232,290.888,1150.062,288.709,1152.133,286.363z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(293)} fill={props.fillColors[293]} d="M603.449,393.903c-2.599,2.35-5.133,4.641-7.603,6.874
                c-10.041-2.006-18.985-6.488-28.86-9.084c1.165-3.823,3.455-7.133,2.023-11.16C575.275,381.248,598.921,390.438,603.449,393.903z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(294)} fill={props.fillColors[294]} d="M233.679,227.621c-3.099-6.456-3.493-12.411-2.053-18.637
                c0.897-3.877,1.693-7.798,1.623-11.85c-0.065-3.751-0.601-7.305-2.893-10.392c-0.716-0.963-1.213-1.948-1.205-3.188
                c0.028-3.976,0.01-7.951,0.01-12.182c3.562-0.741,7.127-0.794,11.196-0.161c0.615,5.643,0.417,11.267-0.376,16.817
                c-0.663,4.637-1.083,9.258-1.077,13.928c0.002,2.041-0.241,3.909-1.77,5.515c-3.082,3.237-3.893,7.183-3.5,11.559
                C233.872,221.66,233.679,224.332,233.679,227.621z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(295)} fill={props.fillColors[295]} d="M927.018,340.094c4.605,2.146,7.938,5.022,11.206,8.229
                c-1.667,1.319-3.167,2.586-4.75,3.74c-2.826,2.059-4.789,4.487-4.647,8.277c0.086,2.315-0.871,4.049-3.43,4.818
                c-2.246,0.675-3.227,2.593-3.672,4.908c-1.033,5.378-2.279,10.715-3.345,16.087c-0.398,2.01-1.115,3.458-3.531,3.262
                c-1.178-0.887-0.518-2.069-0.397-3.139c0.828-7.426,1.726-14.845,2.498-22.276c0.175-1.685,0.465-3.326,1.215-4.794
                C921.327,353.018,924.232,346.708,927.018,340.094z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(296)} fill={props.fillColors[296]} d="M819.166,444.532c-1.805,2.603-3.407,4.716-4.4,7.178
                c-0.464,1.15-1.435,1.847-2.545,2.262c-4.639,1.729-6.873,5.537-8.615,9.802c-0.561,1.373-1.189,2.719-2.006,4.573
                c-1.688-3.271-3.201-6.077-5.986-8.029c-1.611-1.13-2.361-3.185-2.789-5.179c-0.529-2.474-1.209-4.68-3.628-6.221
                c-1.835-1.169-1.382-3.569,0.722-4.081c3.426-0.835,6.572-2.294,9.832-3.521c3.67-1.381,5.191-0.211,5.509,3.685
                c0.362,4.456,1.66,5.167,5.662,3.121C813.361,446.872,815.115,444.221,819.166,444.532z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(297)} fill={props.fillColors[297]} d="M1191.366,344.512c-3.154-3.661-8.271-3.25-11.563-6.374
                c-1.185-1.201-0.883-2.947-1.111-4.557c-0.316-2.231-1.094-4.186-2.802-5.663c-4.521-3.911-6.726-9.366-10.022-14.534
                c5.418-5.787,3.131-11.527-0.162-17.276c-0.63-1.1-1.56-2.145-1.374-4.021c2.841,1.137,4.628,3.058,5.82,5.593
                c3.745,7.961,6.913,16.156,9.298,24.62c0.627,2.227,1.197,4.09,3.625,4.831c0.607,0.186,1.188,0.615,1.662,1.06
                C1186.467,329.813,1191.478,341.433,1191.366,344.512z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(298)} fill={props.fillColors[298]} d="M204.943,89.676c1.574,3.593,0.685,7.201,0.778,10.76c0.039,1.49-0.182,3-0.007,4.469
                c0.324,2.731,1.111,5.07,4.267,6.07c2.11,0.669,2.479,2.357,0.831,3.742c-5.731,4.814-5.307,11.155-4.488,17.476
                c1.831,14.13,0.042,28.069-1.546,42.25c-3.813-5.064-3.942-10.277-3.022-16.114c2.029-12.875,0.674-25.923,1.033-38.894
                c0.043-1.545-0.481-2.984-1.177-4.293c-1.855-3.488-1.804-6.977-0.458-10.597C202.94,99.738,203.781,94.692,204.943,89.676z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(299)} fill={props.fillColors[299]} d="M502.117,492.957c2.636,0,4.26,0.059,5.877-0.016c1.63-0.075,2.962-0.855,3.379-2.463
                c0.45-1.735-0.656-2.875-2.138-3.464c-1.856-0.739-3.313-1.948-5.747-3.79c4.016-0.338,7.018-0.591,10.647-0.896
                c5.03,13.091,11.968,25.355,15.431,39.199C520.302,512.469,509.969,504.519,502.117,492.957z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(300)} fill={props.fillColors[300]} d="M587.229,519.31c2.451-1.198,4.702-1.431,7.03-1.746
                c4.204-0.57,8.12-2.384,11.75-4.585c1.17-0.71,2.271-1.658,3.589-1.4c3.015,0.59,5.52-0.867,7.999-1.951
                c2.837-1.24,5.506-1.358,8.09,0.037c1.552,0.839,3.146,1.342,4.745,1.199c4.302-0.385,8.139,1.076,12.145,2.574
                c-1.766,2.568-4.323,2.792-7.008,2.518c-2.837-0.291-5.608,0.355-8.394,0.447c-4.385,0.144-6.878,3.696-10.467,5.254
                c-2.006,0.871-3.67,1.141-5.587,0.895c-4.291-0.549-8.566-1.213-12.847-1.834c-2.608-0.38-5.177-1.243-7.838-0.018
                C589.214,521.262,588.258,520.318,587.229,519.31z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(301)} fill={props.fillColors[301]} d="M1126.174,171.89c3.481-2.749,6.473-3.464,9.728-3.235
                c5.64,0.397,11.276,0.841,16.917,1.229c1.021,0.07,2.013,0.139,2.717,0.973c3.448,4.083,7.004,8.137,6.622,14.012
                c-0.333,5.124-0.751,10.242-1.403,15.436c-1.535-4.684-3.608-9.055-6.143-13.217c-2.496-4.099-5.708-7.284-10.325-8.973
                C1138.553,176.016,1133.439,172.272,1126.174,171.89z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(302)} fill={props.fillColors[302]} d="M1095.127,355.114c-4.73-2.694-5.414-5.95-4.47-10.485
                c1.327-6.369,2.562-12.615,5.685-18.466c1.853-3.471,2.619-7.51,4.016-11.24c0.681-1.818,1.111-3.786,2.508-5.563
                c1.133,1.097,0.504,2.278,0.457,3.282c-0.281,5.991-0.758,12.074,0.974,17.852c1.993,6.648-0.901,12.077-3.569,17.531
                C1099.457,350.62,1097.097,352.683,1095.127,355.114z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(303)} fill={props.fillColors[303]} d="M92.368,164.089c3.101,0.845,5.134,1.869,6.655,2.879
                c0,5.795,0.202,11.266-0.062,16.715c-0.224,4.603,0.264,8.959,2.589,12.97c3.013,5.197,4.062,10.775,3.745,16.725
                c-0.176,3.3-0.031,6.618-0.031,10.318c-4.449-2.097-7.275-4.989-7.538-10.052c-0.404-7.798-1.054-15.584-1.428-23.384
                c-0.116-2.436-0.556-4.618-2.294-6.36c-2.197-2.201-2.53-4.747-2.176-7.752C92.271,172.386,92.194,168.562,92.368,164.089z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(304)} fill={props.fillColors[304]} d="M208.169,124.297c2.497-1.23,4.859-2.394,7.7-3.793
                c-0.107,4.323,1.877,6.948,5.056,8.846c1.911,1.141,2.472,2.964,2.441,5.121c-0.071,4.829-0.034,9.659-0.061,14.489
                c-0.012,2.154-0.312,4.32,1.648,5.993c1.062,0.907,1.165,2.324,1.219,3.69c0.307,7.82,0.645,15.639,0.96,23.459
                c0.034,0.829,0.199,1.734-0.056,2.481c-3.278,9.596-2.658,19.718-4.059,29.564c-0.131,0.924-0.227,1.853-0.543,2.752
                c-0.768-9.879,0.539-19.623,2.037-29.307c0.951-6.143,0.924-11.929-2.493-17.323c-1.046-1.652-1.075-3.435-1.068-5.282
                c0.025-7.661,0.052-15.323-0.013-22.984c-0.038-4.499-0.466-4.989-4.611-6.527c-0.935-0.347-1.887-0.666-2.788-1.088
                C207.809,131.697,207.67,131.436,208.169,124.297z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(305)} fill={props.fillColors[305]} d="M808.163,447.287c-1.211-1.136-0.954-2.001-1.034-2.796
                c-0.477-4.709-2.871-6.4-7.426-5.058c-1.741,0.513-3.328,1.532-5.056,2.115c-1.671,0.564-3.335,1.478-5.146,1.097
                c-0.826-1.692,0.104-2.304,1.231-2.824c8.657-3.995,17.378-7.569,27.259-7.04c3.63,0.194,7.305-0.493,10.961-0.739
                c4.617-0.311,6.771,0.243,11.44,3.13c-6.339,0.993-11.602,3.095-16.851,5.548C818.458,443.096,812.635,443.912,808.163,447.287z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(306)} fill={props.fillColors[306]} d="M927.029,391.535c-2.299-0.822-4.602-1.645-7.303-2.61
                c1.369-6.562,2.715-13.023,4.065-19.484c0.352-1.681,1.601-2.49,3.084-2.928c2.798-0.827,5.692-0.596,8.289,0.428
                c1.756,0.692,3.22,2.222,2.541,4.74c-1.035,3.838-1.89,7.728-2.729,11.616c-0.374,1.731-0.892,3.145-2.899,3.553
                C929.532,387.366,928.939,390.118,927.029,391.535z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(307)} fill={props.fillColors[307]} d="M1176.207,376.505c3.439-1.792,6.725-1.866,10.502-2.441
                c-0.229,14.6,0.296,28.655-2.193,42.665C1179.327,403.875,1176,390.68,1176.207,376.505z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(308)} fill={props.fillColors[308]} d="M1044.392,439.527c2.778,0.876,5.208,1.863,7.323,3.341
                c0.748,1.475-0.059,2.667-0.349,3.938c-2.333,10.25-4.531,20.514-2.478,31.118c0.157,0.812,0.365,1.693,0.211,2.477
                c-1.283,6.504-2.646,12.992-4.479,19.475c-1.955-7.314-5.64-14.398-1.71-22.197c1.301-2.58,1.23-5.482,1.195-8.33
                c-0.113-8.824-0.194-17.648-0.271-26.472C1043.826,441.896,1043.554,440.876,1044.392,439.527z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(309)} fill={props.fillColors[309]} d="M190.35,457.062c3.123,0.417,5.704,1.428,6.977,4.656
                c0.707,1.792,1.947,3.153,4.105,3.023c3.664-0.219,5.069,2.609,6.82,4.968c0.287,0.387,0.401,0.906,0.573,1.373
                c0.874,2.365,0.838,4.866,1.178,7.317c0.346,2.496,3.823,3.26,3.844,6.356c-1.96,1.714-4.85,0.009-6.858,1.562
                c-6.832-5.242-10.79-12.809-16.345-19.006c-1.004-1.12-1.104-2.381-1.084-3.697C189.593,461.479,189.54,459.32,190.35,457.062z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(310)} fill={props.fillColors[310]} d="M103.478,150.968c2.421,0.314,4.612,1.825,7.515,2.72
                c-1.79,15.132-3.551,30.019-5.443,46.021c-2.55-4.208-4.618-7.5-4.52-11.346c0.302-11.815,0.905-23.623,1.415-35.433
                C102.473,152.291,102.553,151.587,103.478,150.968z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(311)} fill={props.fillColors[311]} d="M444.081,391.753c-3.5-5.931-6.619-11.751-5.304-18.807
                c1.198-6.426-0.562-12.196-4.882-17.133c-1.503-1.718-2.078-3.639-1.984-5.946c0.154-3.819,0.042-7.648,0.042-11.498
                c1.87-0.219,2.048,1.224,2.538,2.108c3.062,5.534,6.036,11.117,9.049,16.678c1.387,2.559,1.846,5.233,1.682,8.173
                C444.729,374.131,444.446,382.944,444.081,391.753z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(312)} fill={props.fillColors[312]} d="M1109.985,359.084c5.05-8.9,5.532-19.733,11.63-28.394
                c5.929,2.01,8.08,6.98,10.844,11.624c-1.85,3.067-2.918,6.706-7.577,6.846c-1.513,0.045-2.47,1.728-2.828,3.202
                c-1.249,5.127-6.222,5.908-9.702,8.396C1111.779,361.167,1110.828,360.375,1109.985,359.084z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(313)} fill={props.fillColors[313]} d="M349.314,152.431c1.511,3.176,2.917,6.131,4.497,9.451
                c-1.994,1.461-2.459,3.403-1.05,5.685c2.488,4.028,2.567,8.462,2.117,12.996c-0.541,5.45-0.925,10.91-0.847,16.396
                c0.021,1.482,0.043,3.015-1.358,4.409c-2.693-1.321-3.871-3.542-4.089-6.527c-0.934-12.737-1.855-25.475-2.942-38.199
                C345.401,153.833,347.147,153.412,349.314,152.431z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(314)} fill={props.fillColors[314]} d="M158.835,559.681c-2.159,0.901-3.356,0.058-4.624-1.271
                c-3.224-3.377-3.283-3.32-8.386-3.52c-1.666-1.296-2.662-3.257-4.007-4.922c-0.95-1.177-1.88-2.697-3.404-2.73
                c-2.421-0.055-3.547-1.42-4.517-3.25c-0.152-0.288-0.381-0.709-0.625-0.748c-3.842-0.613-6.063-3.99-9.37-5.461
                c-2.658-1.184-2.712-1.062-4.207-5.152C138.603,533.204,150.442,543.612,158.835,559.681z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(315)} fill={props.fillColors[315]} d="M390.177,555.031c-2.98-0.586-5.03-0.99-7.062-1.389
                c-1.9-4.062-3.603-7.962-1.451-12.412c0.782-1.618-0.089-3.246-0.704-4.793c-2.025-5.089-3.995-10.201-6.098-15.258
                c-1.23-2.957-2.195-5.957-1.838-9.175c0.602-5.435-1.094-10.12-4.242-14.441c-0.969-1.33-1.705-2.831-2.677-4.472
                c4.102,2.585,9.17,8.67,10.676,12.971c2.634,7.521,4.939,15.148,6.85,22.889c0.237,0.961,0.783,2.26,0.382,2.898
                c-2.23,3.549,0.2,6.098,1.413,9.077C387.172,545.214,388.415,549.705,390.177,555.031z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(316)} fill={props.fillColors[316]} d="M588.334,521.975c-1.217,8.894-6.047,15.799-7.129,23.727
                c-3.409,0.484-4.396-2.021-6.081-3.434c-3.972-3.328-5.257-7.404-3.672-12.383c0.798-2.506,0.162-5.321,1.785-8.114
                C577.937,522.049,582.814,521.029,588.334,521.975z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(317)} fill={props.fillColors[317]} d="M1028.812,490.274c-2.352-4.333-2.952-7.813-2.98-11.548
                c-0.069-9.295-0.407-18.588-0.635-27.883c-0.089-3.653,0.484-7.178,1.635-10.546c6.189,0.631,7.145,1.723,6.908,7.374
                c-0.153,3.646-0.279,7.294-0.415,10.941c-0.024,0.663,0.131,1.393-0.092,1.979
                C1029.706,469.895,1030.037,479.799,1028.812,490.274z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(318)} fill={props.fillColors[318]} d="M1054.469,484.717c-4.295-5.367-4.795-11.25-4.508-17.245
                c0.287-5.979,1.336-11.883,2.498-17.748c0.279-1.405,0.406-2.999,1.934-4.406c2.041,1.539,3.979,3.737,7.912,2.523
                c-4.172,8.661-2.982,16.991-4.227,24.987C1057.449,476.869,1056.554,480.809,1054.469,484.717z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(319)} fill={props.fillColors[319]} d="M983.481,545.756c5.118-2.696,8.384,1.556,12.458,2.559
                c5.002,1.233,6.412,5.375,8.527,9.063c-0.651,0.415-1.244,0.014-1.846-0.221c-6.806-2.655-13.85-3.411-21.066-2.517
                c-3.289,0.406-5.609-0.305-7.17-3.668c-1.59-3.423-4.259-6.244-6.682-9.648c2.1,0.375,4.227,0.648,6.29,1.173
                c0.983,0.25,1.951,0.646,2.905,0.306C979.926,541.725,982.057,542.848,983.481,545.756z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(320)} fill={props.fillColors[320]} d="M1032.559,322.028c-2.9-2.771-4.268-6.842-9.34-6.18
                c2.386-8.693,8.498-14.781,11.136-22.92c2.468,1.216,4.251-0.157,6.786-1.605c-1.014,7.435-1.649,14.148-2.938,20.733
                C1037.479,315.758,1034.485,318.495,1032.559,322.028z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(321)} fill={props.fillColors[321]} d="M1040.209,478.325c-0.644-3.302-0.427-5.96-0.588-8.597
                c-0.217-3.581,0.117-7.464-3.643-9.78c-1.025-0.631-0.823-1.741-0.812-2.702c0.033-2.991,0.046-5.987,0.236-8.97
                c0.098-1.509,0.049-2.97-0.166-4.459c-0.429-2.964-1.766-4.621-5.01-5.19c-2.851-0.5-5.928-0.322-8.66-1.699
                c2.154-4.348,5.326-5.697,9.033-3.812c2.518,1.281,4.888,2.796,7.857,2.901c2.252,0.079,3.129,1.624,3.264,3.674
                c0.119,1.824,0.228,3.652,0.248,5.479c0.098,8.474,0.203,16.948,0.215,25.422C1042.188,473.029,1042.318,475.567,1040.209,478.325
                z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(322)} fill={props.fillColors[322]} d="M663.971,388.043c2.59-14.308,3.523-28.491,4.674-42.629
                c2.648-0.364,2.658-0.408,5.277,0.832c0.271,4.685,0.604,9.466-0.266,14.26c-1.047,5.767-0.731,11.621-0.469,17.399
                c0.197,4.31-1.424,6.858-5.045,8.598C666.961,387.069,665.959,388.045,663.971,388.043z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(323)} fill={props.fillColors[323]} d="M286.21,425.843c3.27,0.594,5.135,2.246,6.22,5.253
                c2.371,6.572,5.022,13.043,7.579,19.547c0.665,1.69,1.296,3.336,2.772,4.638c3.619,3.192,4.508,5.385,5.186,12.184
                c-4.99-3.601-9.447-6.875-13.664-10.472c-0.574-0.49-0.861-1.429-1.071-2.215c-2.444-9.162-4.851-18.333-7.246-27.508
                C285.912,426.986,286.077,426.64,286.21,425.843z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(324)} fill={props.fillColors[324]} d="M758.568,366.874c2.284,0.804,3.904-0.733,6.104-1.281
                c-1.443,15.718-6.375,30.458-7.777,46.488c-3.078-2.699-4.305-5.265-4.277-8.686c0.061-7.717,1.657-15.216,2.915-22.764
                C756.268,376.219,756.69,371.725,758.568,366.874z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(325)} fill={props.fillColors[325]} d="M39.232,208.548c2.047,8.969,2.423,18.033,2.122,27.134
                c-0.126,3.822-0.443,7.631-1.175,11.409c-1.512,7.803-0.292,15.599,0.286,23.389c0.617,8.318-0.527,16.504-1.371,24.953
                c-2.667-1.442-3.13-3.294-2.791-5.693c1.191-8.444,1.336-16.921,0.301-25.392c-0.41-3.351,0.141-6.647,0.23-9.968
                c0.382-14.311,0.816-28.62,2.137-42.884C39.062,210.514,39.146,209.53,39.232,208.548z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(326)} fill={props.fillColors[326]} d="M766.85,438.694c-0.008-8.227,0.859-15.963,1.598-23.708
                c1.018-10.673,3.786-20.861,8.418-30.532c1.387-2.894,1.611-6.016,1.65-9.057c0.931-0.18,1.305,0.242,1.297,0.712
                c-0.118,6.961,0.404,13.935-0.53,20.884c-0.284,2.111-0.849,3.705-2.903,4.781c-2.363,1.238-3.329,3.489-3.701,6.135
                c-0.465,3.306-0.674,6.619-0.633,9.937c0.068,5.503-0.924,10.925-1.062,16.404C770.926,436.59,769.851,437.902,766.85,438.694z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(327)} fill={props.fillColors[327]} d="M1157.717,391.083c0.656,2.187-0.354,4.226-0.55,6.32
                c-1.038,11.097-2.094,22.19-3.565,33.243c-0.326,2.449-0.034,4.985,0.025,7.48c0.232,9.688-1.125,19.258-2.07,28.86
                c-0.338,3.439-0.608,6.886-0.908,10.306c-1.969,0.027-1.479-1.326-1.571-2.131c-0.748-6.492-0.322-12.961,0.44-19.428
                c0.485-4.121,1.139-8.253-0.505-12.327c-0.718-1.779-0.091-3.643,0.332-5.407c2.453-10.246,3.708-20.625,3.657-31.162
                c-0.02-4.191,0.846-8.266,1.694-12.342C1155.045,392.823,1155.375,391.168,1157.717,391.083z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(328)} fill={props.fillColors[328]} d="M1082.422,250.664c1.045-8.531,0.243-17.196,3.582-25.441
                c3.157-7.796,2.887-16.31,3.984-24.528c0.328-2.461,0.316-4.969,1.026-7.382c1.279-0.88,2.621-1.44,4.087-1.511
                c0.271,0.261,0.701,0.497,0.68,0.682c-1.137,10.038-1.068,20.198-4.508,29.97c-2.361,6.711-3.53,13.835-5.371,20.738
                C1085.198,245.833,1084.657,248.626,1082.422,250.664z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(329)} fill={props.fillColors[329]} d="M226.59,911.297c-1.586,2.589-3.623,3.865-5.789,4.409
                c-4.935,1.241-9.277,3.509-13.428,6.361c-2.599,1.786-3.836,1.456-7.128-1.332c4.517-2.882,3.489-6.72,1.924-10.762
                c-0.508-1.312-1.317-2.744-0.382-4.925C210.176,905.781,218.008,909.215,226.59,911.297z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(330)} fill={props.fillColors[330]} d="M119.763,150.211c-0.965,9.248-1.392,18.392-3.026,27.314
                c-1.186,6.472-4.834,12.26-7.688,18.172c-1.36-0.209-0.933-1.055-0.878-1.525c1.358-11.717,2.964-23.41,4.055-35.151
                C112.677,154.152,114.869,151.49,119.763,150.211z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(331)} fill={props.fillColors[331]} d="M770.215,393.368c2.897-20.031,5.098-40.12,5.469-60.39
                c0.35,0.018,0.699,0.035,1.049,0.052c0,3.973,0.173,7.955-0.049,11.915c-0.219,3.92-1.426,7.859,0.863,11.866
                c3.717-1.891,2.417-5.937,4.199-8.929c1.787,2.634,1.906,5.633,1.19,7.996c-1.21,3.996-1.118,7.772-0.03,11.664
                c-1.671,0.178-2.465-1.472-4.194-1.162c-0.165,0.118-0.644,0.328-0.931,0.69c-1.352,1.71-1.248,3.846-1.07,5.744
                c0.533,5.706-1.217,10.812-3.562,15.815C772.393,390.241,772.26,392.172,770.215,393.368z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(332)} fill={props.fillColors[332]} d="M845.827,550.014c1.505-8.842,3.007-17.684,4.521-26.524
                c0.055-0.322,0.177-0.649,0.338-0.935c4.748-8.41,10.499-16.02,18.023-22.334c-3.197,6.504-3.955,13.94-8.799,19.765
                c-3.754,4.515-5.629,10.168-7.748,15.591c-1.922,4.918-3.641,9.916-5.449,14.879C846.418,550.308,846.123,550.16,845.827,550.014z
                "/>
            <path fill-rule="evenodd" onClick={() => props.onFill(333)} fill={props.fillColors[333]} d="M842.586,315.934c2.039-0.214,3.836-0.402,5.583-0.585
                c0.204,0.392,0.529,0.739,0.454,0.913c-2.396,5.564-4.006,11.188-3.074,17.417c0.357,2.386-0.145,5.035-0.8,7.414
                c-2.075,7.538-1.798,15.164-1.374,22.827c0.211,3.825,0.338,7.655,0.426,11.484c0.021,0.897,0.358,1.987-1.23,2.919
                c-1.463-2.689-1.73-5.585-1.761-8.448c-0.05-4.662,0.011-9.334,0.265-13.989c0.41-7.516,0.527-15.059-1.262-22.372
                C838.279,327.244,839.828,321.754,842.586,315.934z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(334)} fill={props.fillColors[334]} d="M988.147,426.512c3.882,7.08,6.169,14.408,5.563,22.417
                c-0.478,6.31-1.006,12.616-1.525,18.923c-0.052,0.621,0.023,1.318-0.838,1.854c-1.99-0.585-3.104-1.926-3.418-4.158
                c-0.855-6.097-1.808-12.181-2.725-18.269c-0.306-2.027-0.042-3.992,0.7-5.886C987.771,436.635,990.234,431.981,988.147,426.512z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(335)} fill={props.fillColors[335]} d="M795.156,336.299c2.46,1.938,2.049,3.909,1.959,5.615
                c-0.327,6.191,0.729,12.214,1.626,18.299c1.282,8.698,2.074,17.468,3.117,26.202c0.456,3.823,1.091,7.589,4.312,10.29
                c0.462,0.388,0.896,1.088,0.926,1.667c0.181,3.443,1.243,6.836,0.875,10.196c-0.887,0.422-1.727,0.626-2.109,0.097
                c-2.205-3.046-5.156-5.711-5.459-9.804c-0.514-6.948-1.217-13.814-3.09-20.613c-1.174-4.262-0.377-8.927-0.128-13.405
                c0.15-2.697,0.078-5.296-1.289-7.738c-0.393-0.702-0.7-1.562-0.708-2.353C795.13,348.795,795.156,342.837,795.156,336.299z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(336)} fill={props.fillColors[336]} d="M574.749,515.376c-5.94,4.946-3.58,12.099-6.037,17.798
                c-1.703-0.335-2.236-1.863-3.045-3.042c-1.435-2.092-3.076-4.152-5.41-4.989c-4.289-1.539-5.84-5.021-7.431-9.119
                C559.899,513.568,566.867,512.468,574.749,515.376z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(337)} fill={props.fillColors[337]} d="M1189.14,518.107c-1.126-3.52-5.398-6.418-4.646-11.514
                c3.898,0.494,7.702,1.156,11.283,2.881c1.473,0.709,3.134,1.404,4.72,1.429c4.393,0.069,6.843,2.78,9.458,6.178
                C1203.4,521.252,1196.807,521.619,1189.14,518.107z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(338)} fill={props.fillColors[338]} d="M280.597,120.372c6.642,0.082,11.947,4.42,19.314,4.329
                c-5.857,10.711-5.993,22.133-10.605,32.335c0-7.211-0.381-14.45,0.12-21.627c0.403-5.779-1.094-10.037-6.255-12.868
                C282.207,122.012,281.449,121.104,280.597,120.372z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(339)} fill={props.fillColors[339]} d="M640.221,409.909c-0.832-6.822-0.009-12.552,0.146-18.282
                c0.089-3.316,0.662-6.712,0.146-9.932c-0.957-5.973,0.94-9.987,6.883-12.319c0.946,3.963,0.298,7.827-0.315,11.651
                c-0.711,4.433-1.435,8.856-1,13.358C646.629,400.07,644.307,404.687,640.221,409.909z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(340)} fill={props.fillColors[340]} d="M657.986,341.928c0.455,4.4-0.176,8.356-0.91,12.24
                c-1.643,8.68-1.719,17.526-3.033,26.228c-0.596,3.939-1.6,7.816-2.424,11.72c-0.354,1.672-0.926,3.195-3.41,4.028
                c-0.364-3.666-0.625-7.181,0.054-10.672c1.086-5.593,1.336-11.229,1.317-16.896c-0.005-1.674,0.071-3.287,0.814-4.862
                c2.93-6.204,5.053-12.657,5.784-19.519c0.051-0.477,0.249-0.964,0.489-1.383C656.807,342.573,657.166,342.461,657.986,341.928z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(341)} fill={props.fillColors[341]} d="M1176.213,336.198c-4.271-4.064-9.171-6.139-15.038-5.127
                c-1.616-4.523-4.504-8.174-4.463-12.905c0.015-1.665-0.197-3.504,1.961-4.041c2.086-0.519,4.3-1.161,5.928,1.02
                c0.793,1.062,1.534,2.176,2.173,3.335c2.172,3.938,4.486,7.737,7.869,10.818C1176.422,330.918,1177.483,332.961,1176.213,336.198z
                "/>
            <path fill-rule="evenodd" onClick={() => props.onFill(342)} fill={props.fillColors[342]} d="M936.57,385.413c0.534-4.384,1.777-8.669,2.867-13.22
                c3.871-0.03,6.656,1.106,8.798,4.47c3.272,5.142,5.715,10.837,10.04,15.304c1.229,1.268,0.182,3.234,1.457,4.604
                c-0.267,0.191-0.533,0.382-0.799,0.573c-2.501-2.253-5.255-4.289-7.443-6.813c-3.121-3.603-6.664-5.344-11.438-4.388
                C938.947,386.165,937.725,386.287,936.57,385.413z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(343)} fill={props.fillColors[343]} d="M619.323,541.866c-0.048-5.556-0.176-10.322,0.548-15.546
                c7.697,1.192,15.259,1.287,23.607,4.484C634.801,534.184,628.057,539.271,619.323,541.866z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(344)} fill={props.fillColors[344]} d="M1156.223,333.753c-1.092-2.95-3.398-4.487-4.864-6.475
                c-3.415-4.63-7.796-9.046-6.987-15.806c0.389-3.257-0.26-6.63-0.341-9.954c-0.039-1.612-0.35-3.354,1.707-5.237
                c0.528,3.075,1.06,5.669,1.405,8.289c0.485,3.675,1.984,6.625,5.306,8.538c1.108,0.637,2.578,1.236,2.369,2.886
                c-0.626,4.932,1.765,9.016,3.534,13.295C1159.287,331.552,1159.209,331.584,1156.223,333.753z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(345)} fill={props.fillColors[345]} d="M923.919,585.959c-3.132-3.22-3.132-3.22-6.63-1.492
                c-6.445-8.009-7.727-17.703-8.984-27.756c2.861-0.122,4.691,1.143,5.766,3.645c1.51,3.516,3.205,6.973,4.405,10.593
                c1.171,3.534,2.478,6.868,5.283,9.449C925.454,581.957,925.838,583.7,923.919,585.959z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(346)} fill={props.fillColors[346]} d="M480.359,438.43c2.031,3.852,2.476,7.668,3.423,11.298
                c0.635,2.437,1.24,4.81,2.79,6.845c3.616,4.749,4.142,10.312,4.423,16.984c-3.734-4.446-8.174-6.586-12.39-9.277
                c0.28-4.472,0.841-8.742,0.844-13.048C479.452,447.144,478.785,442.97,480.359,438.43z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(347)} fill={props.fillColors[347]} d="M542.364,441.609c0.025-10.522,12.533-20.134,23.691-18.462
                c-1.052,3.429-2.51,6.287-5.9,8.125C554.2,434.504,548.423,438.065,542.364,441.609z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(348)} fill={props.fillColors[348]} d="M912.704,512.78c1.759-1.312,3.433-1.375,4.763-2.575
                c2.286-2.066,5.09-3.285,8.09-0.9c1.061,0.843,2.195,0.189,3.332,0.123c5.551-0.323,9.218,2.508,11.951,7.124
                c-1.864,2.101-3.901,1.313-5.775,1.029c-2.465-0.374-4.886-1.034-7.351-1.407c-3.159-0.479-6.301-0.369-9.345,1.283
                C916.775,515.528,914.265,515.113,912.704,512.78z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(349)} fill={props.fillColors[349]} d="M209.212,121.131c0.004-2.313,1.307-3.275,2.516-4.442
                c3.133-3.023,2.775-6.07-1.117-7.636c-2.85-1.146-3.042-3.403-3.124-5.645c-0.102-2.802,0.309-5.623,0.508-8.582
                c4.743,1.506,5.62,7.092,10.17,8.68c0.147,3.302,0.196,6.628,0.476,9.934c0.214,2.532-0.706,4.144-3.079,5.026
                C213.556,119.214,211.84,120.651,209.212,121.131z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(350)} fill={props.fillColors[350]} d="M266.03,288.169c-0.785,4.065-1.276,8.002-2.324,11.785
                c-3.745,13.517-3.122,26.996-0.128,40.516c0.933,4.216,0.666,8.194-2.877,11.616c-1.118-1.109-0.754-2.316-0.811-3.362
                c-0.557-10.312-1.09-20.625-1.59-30.94c-0.064-1.316-0.233-2.762,0.201-3.945c2.769-7.543,4.117-15.417,5.68-23.245
                C264.362,289.682,264.417,288.624,266.03,288.169z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(351)} fill={props.fillColors[351]} d="M131.231,82.885c7.721,0.838,8.371,6.483,10.252,11.43
                c2.004,5.27,1.319,9.946-1.786,14.826c-2.464-1.557-3.64-3.854-4.097-6.68C134.568,96.082,133.678,89.674,131.231,82.885z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(352)} fill={props.fillColors[352]} d="M118.735,725.099c4.893-0.896,9.804-1.117,14.571-0.956
                c1.144,1.191-0.453,1.924,0.057,3.26c3.023,1.611,6.812,0.73,10.431,2.441c-5.338,1.822-10.677,3.645-16.093,5.494
                c-2.363-2.525-4.154-5.174-7.25-6.348C119.279,728.545,119.378,728.285,118.735,725.099z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(353)} fill={props.fillColors[353]} d="M325.42,173.485c-1.993-7.273-2.42-14.291-5.284-20.95
                c1.534-0.834,3.018-0.577,4.413-0.6c4.077-0.068,6.096,1.755,6.379,5.711C331.345,163.472,330.017,168.72,325.42,173.485z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(354)} fill={props.fillColors[354]} d="M1051.091,330.541c-2.734-0.618-4.737-1.691-7.259-2.991
                c5.951-8.758,8.355-19.143,15.703-26.831C1056.758,310.527,1053.98,320.335,1051.091,330.541z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(355)} fill={props.fillColors[355]} d="M486.153,485.258c0.861-3.083,2.433-4.195,5.497-4.168
                c4.67,0.042,8.695,0.724,11.905,4.641c1.445,1.764,3.895,2.703,5.742,3.92c-0.139,1.234-0.916,1.447-1.484,1.368
                c-3.594-0.499-7.122-0.005-10.652,0.544c-1.303,0.203-2.578-0.214-2.919-1.355C493.038,486.176,489.585,486.083,486.153,485.258z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(356)} fill={props.fillColors[356]} d="M831.533,319.665c2.792-1.755,5.051-3.067,7.721-3.191
                c0.546,0.442,0.621,1.023,0.4,1.422c-3.414,6.134-2.264,12.532-1.172,18.938c1.15,6.756,1.33,13.528,0.107,20.35
                C835.713,344.851,835.167,332.102,831.533,319.665z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(357)} fill={props.fillColors[357]} d="M1087.566,309.222c-2.855,5.072-4.043,10.024-4.787,15.102
                c-0.068,0.461-0.397,0.918-0.699,1.304c-3.576,4.572-7.068,9.192-8.534,15.185c-2.413-0.864-4.038-1.874-5.724-3.113
                c1.348-2.681,3.071-4.692,4.701-6.769c4.533-5.774,9.067-11.546,10.915-18.88C1083.822,310.528,1084.502,309.212,1087.566,309.222
                z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(358)} fill={props.fillColors[358]} d="M710.172,456.349c-7.206,0.189-13.953,2.032-21.276,1.391
                c2.671-3.815,5.817-6.574,10.071-8.077C705.829,447.239,708.488,448.733,710.172,456.349z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(359)} fill={props.fillColors[359]} d="M1136.067,228.262c2.526,1.324,2.839,3.595,3.312,5.592
                c1.309,5.525,3.209,10.818,5.434,16.032c1.402,3.285,0.27,6.709,0.229,10.355c-2.977-1.144-4.172-3.246-4.828-5.707
                c-1.538-5.78-3.235-11.51-3.123-17.621C1137.141,234.145,1136.458,231.362,1136.067,228.262z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(360)} fill={props.fillColors[360]} d="M926.664,488.952c1.553,2.44,1.91,5.403,2.81,8.213
                c-1.31,0.714-2.702,1.091-3.499,1.993c-1.25,1.416-2.494,1.809-4.309,1.476c-2.588-0.476-5.035,0.267-7.535,2.173
                C916.426,493.592,920.812,488.022,926.664,488.952z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(361)} fill={props.fillColors[361]} d="M94.947,243.213c3.095,0.32,4.256,1.486,4.437,3.9
                c0.504,6.752,1.485,13.476,0.655,20.278c-0.344,2.82-1.48,5.228-3.292,7.485C95.769,264.378,96.363,253.886,94.947,243.213z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(362)} fill={props.fillColors[362]} d="M660.685,342.019c1.516,0.805,1.181,1.876,1.183,2.758
                c0.01,5.337-0.341,10.634-1.438,15.887c-1.058,5.067-0.195,10.322-1.312,15.409c-1.069,4.874-2.044,9.768-3.072,14.65
                c-0.224,1.061-0.726,1.854-2.57,1.756C657.458,375.907,657.522,358.848,660.685,342.019z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(363)} fill={props.fillColors[363]} d="M281.266,856.082c-0.959,1.956-2.296,1.463-3.363,1.598
                c-1.531,0.191-3.133,0.498-4.046,1.685c-2.494,3.238-5.328,2.748-8.395,1.161c-1.17-0.605-2.337-1.215-3.865-2.009
                c1.813-2.887-1.164-4.259-2.544-7.085C266.834,853.061,273.915,854.543,281.266,856.082z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(364)} fill={props.fillColors[364]} d="M1064.146,335.407c-2.229-0.872-4.029-1.577-5.781-2.262
                c1.086-7.236,5.61-12.54,13.736-15.934C1070.386,323.737,1067.448,329.342,1064.146,335.407z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(365)} fill={props.fillColors[365]} d="M176.989,534.967c-3.655-1.567-6.526-1.366-9.087,0.682
                c-3-5.016-7.116-9.084-9.375-15.223C166.718,523.42,173.176,526.805,176.989,534.967z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(366)} fill={props.fillColors[366]} d="M675.274,381.561c-1.354-11.774,1.209-23.261,0.806-35.047
                c1.766-0.248,2.752,0.214,2.799,1.878c0.076,2.819,0.484,5.721-0.021,8.445c-1.252,6.762-0.845,13.552-0.987,20.338
                C677.836,378.879,678.444,380.997,675.274,381.561z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(367)} fill={props.fillColors[367]} d="M385.427,558.547c3.908-0.183,7.587,1.905,11.598,1.01
                c0.406-0.091,1.059,0.178,1.365,0.5c4.684,4.935,11.716,6.054,16.786,10.365c0.897,0.763,1.898,1.339,2.003,3.09
                c-4.256-1.239-8.695-1.424-11.519-5.585c-1.176-1.733-3.338-2.044-5.376-2.087c-2.613-0.057-4.895-0.844-6.576-2.922
                C391.585,560.295,388.179,560.23,385.427,558.547z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(368)} fill={props.fillColors[368]} d="M1009.961,451.471c-1.488-4.014-0.602-8.314-2.75-12.106
                c-2.018-3.563-5.182-6.543-5.997-10.938c2.698-1.518,4.756,0.083,5.745,1.991c2.569,4.956,5.693,9.718,6.258,15.556
                C1013.48,448.695,1012.53,450.26,1009.961,451.471z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(369)} fill={props.fillColors[369]} d="M65.922,286.654c-0.772,10.59-1.71,20.561-3.498,30.544
                c-1.862,0.043-3.139-0.055-2.79-2.03c1.383-7.823,2.235-15.706,2.876-23.62C62.657,289.729,63.502,288.093,65.922,286.654z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(370)} fill={props.fillColors[370]} d="M364.875,488.523c-1.75-2.035-2.25-3.999-2.822-5.854
                c-1.886-6.117-4.788-11.643-8.931-16.532c-1.954-2.306-3.295-4.937-3.747-8.294C359.501,465.485,364.071,475.67,364.875,488.523z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(371)} fill={props.fillColors[371]} d="M1081.692,310.884c-2.014,9-7.776,15.156-13.252,21.542
                c2.563-5.883,4.44-12.014,7.171-17.782C1076.584,312.589,1078.341,310.869,1081.692,310.884z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(372)} fill={props.fillColors[372]} d="M303.91,130.025c3.074,2.211,6.036,4.606,9.255,6.582
                c3.08,1.89,4.387,4.28,3.328,8.622c-1.23-3.749-3.732-4.985-6.832-5.378c-1.391-0.176-2.603-0.914-2.919-2.336
                C306.162,134.919,304.431,132.914,303.91,130.025z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(373)} fill={props.fillColors[373]} d="M205.458,728.939c1.809,2.662,1.425,5.457,1.379,8.648
                c-3.591,0.59-6.881-0.818-11.042-0.771c2.129-1.721,4.549-1.641,5.575-3.736C202.17,731.449,202.332,729.035,205.458,728.939z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(374)} fill={props.fillColors[374]} d="M568.871,417.568c0.017,1.442-0.547,2.963-1.967,2.945
                c-4.087-0.051-8.029,1.341-12.323,1.272C558.31,416.993,564.101,415.359,568.871,417.568z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(375)} fill={props.fillColors[375]} d="M444.802,482.26c4.049-1.641,8.079-1.309,12.104,0.107
                C454.161,487.514,448.42,487.458,444.802,482.26z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(376)} fill={props.fillColors[376]} d="M93.15,161.715c0-2.879,0-5.294,0-7.897c4.109,2.835,6.38,6.578,6.316,10.177
                c-1.231,0.646-2.149-0.283-3.141-0.696C95.275,162.861,94.282,162.287,93.15,161.715z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(377)} fill={props.fillColors[377]} d="M1090.967,437.752c-1.148,3.672-3.979,4.473-6.711,4.854
                c-3.432,0.479-6.895,0.526-10.074,2.198c-0.652,0.343-1.604,0.234-2.916-0.812
                C1078.224,442.181,1084.419,439.421,1090.967,437.752z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(378)} fill={props.fillColors[378]} d="M140.637,630.407c-0.403-1.675-0.403-1.675,0.105-3.409
                c1.373-0.461,1.987,0.316,2.399,1.43c-0.458,1.294-2.113,0.796-2.636,1.902C140.524,630.348,140.637,630.407,140.637,630.407z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(379)} fill={props.fillColors[379]} d="M1178.413,763.575c-0.595,0-1.189,0-2.009,0
                C1177.148,762.957,1177.782,763.109,1178.413,763.575z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(380)} fill={props.fillColors[380]} d="M44.204,81.097c-1.122,0.551-2.244,1.103-3.68,1.808
                c0.801-2.345,2.202-2.235,3.775-1.7C44.308,81.214,44.204,81.097,44.204,81.097z"/>
            <path fill-rule="evenodd" d="M1041.703,26.103c7.411-2.828,15.193-3.253,21.982-7.059c1.31-0.734,2.882-1.508,4.018,0.122
                c1.03,1.478,0.164,2.867-0.875,4.044c-1.574,1.783-3.629,2.875-5.685,4.002c-1.88,1.03-4.062,1.49-6.115,3.325
                c6.845-0.112,13.302-2.544,20.368-0.901c-1.868,4.242-5.177,4.343-7.762,5.469c-2.667,1.162-5.441,2.074-9.17,3.471
                c7.894,1.068,14.92-0.61,21.326,1.478c0.71,2.618-0.693,3.334-2.025,4.027c-2.985,1.552-6.21,2.358-9.498,2.965
                c-1.942,0.359-3.869,0.806-6.395,1.337c8.295,2.318,16.873,1.058,24.56,6.175c-4.331,4.782-9.96,2.461-14.951,4.105
                c4.752,2.439,10.541,0.983,14.767,5.085c-4.308,3.806-9.562,2.38-14.393,4.183c0.826,1.338,2.948,1.95,2.26,3.848
                c-0.55,1.514-1.662,2.604-3.016,3.184c-2.38,1.021-4.211,2.255-4.869,5.022c-0.223,0.939-1.485,1.817-2.475,2.347
                c-1.826,0.979-3.367,2.024-3.951,4.191c-0.308,1.144-1.228,2.068-2.401,2.376c-1.612,0.423-2.532,1.426-3.21,2.859
                c-0.271,0.572-0.795,1.379-1.281,1.439c-4.148,0.509-4.705,3.81-6.06,6.892c-3.39,7.713-8.572,14.24-14.688,20.014
                c-6.354,5.997-11.961,12.713-18.131,18.875c-5.923,5.914-6.494,5.824-13.926,2.303c-1.041-0.493-2.068-1.013-2.793-1.369
                c-1.312,0.179-1.766,0.929-2.335,1.51c-2.057,2.099-4.351,2.58-7.279,1.852c-4.833-1.202-9.532-2.818-14.415-3.941
                c4.538,2.651,9.328,4.717,14.518,5.73c10.294,2.01,20.608,3.917,30.912,5.879c1.472,0.28,2.946,0.573,4.389,0.973
                c2.048,0.569,3.68,1.668,3.908,3.999c0.23,2.358-1.08,3.719-3.172,4.487c-0.775,0.284-1.543,0.588-2.511,0.959
                c0.995,2.275,3.494,3.775,2.997,6.609c-0.502,2.863-2.872,3.878-4.936,5.049c0.629,5.951-0.019,6.705-7.08,8.407
                c-1.037,1.399-0.121,2.864-0.021,4.289c0.348,5.013-0.905,6.498-6.028,6.975c-0.487,0.045-0.969,0.143-1.423,0.212
                c-2.012,6.787-2.48,6.983-11.34,4.674c-1.952,1.301-2.002,4.596-4.781,5.24c-2.344,0.542-4.626-0.147-6.912-0.409
                c-5.16,5.493-7.248,5.78-14.383,1.933c-1.984,0.614-3.846,1.823-5.865,1.796c-1.406,1.611-1.009,3.364-1.307,4.961
                c-0.479,2.574-1.817,3.244-3.742,1.716c-1.309-1.038-2.608-1.957-4.119-2.688c-2.359-1.141-2.028-3.588-2.369-5.659
                c-3.469-1.324-12.086-8.772-14.557-11.942c-0.883,0.937-0.301,2.711-2.101,3.325c-2.87,0.499-2.94-3.72-5.852-3.875
                c-0.716,2.167,0.875,3.742,1.44,5.494c0.988,3.065,0.883,5.87-1.08,8.517c-1.299,1.752-2.905,2.605-5.562,1.978
                c0.716-2.606,0.7-5.108-1.189-7.369c-1.746-0.542-3.038,0.296-4.134,1.609c0.217,1.674,2.357,1.989,2.402,3.692
                c-4.838,3.891-7.49,3.863-10.613-0.11c-2.25-2.864-2.342-4.603,0-7.586c2.357-3.003,4.699-6.035,8.227-7.888
                c3.662-1.924,5.242-5.433,6.429-9.159c1.12-3.517,1.668-7.1,1.376-10.997c-4.73,0.675-9.139,0.019-13.595-1.148
                c-8.108-2.122-16.29-3.507-24.823-3.027c-4.486,0.252-8.967-1.279-13.379-2.966c-4.178-1.598-8.081-4.384-12.863-4.33
                c-0.797,0.009-1.791-0.125-2.367-0.594c-4.556-3.719-10.133-4.153-15.547-5.5c-3.936-0.979-7.812-3.39-10.924-6.079
                c-2.117-1.829-4.076-3.175-6.721-3.731c-2.881-0.605-5.399-1.763-6.312-4.682c-5.034-0.96-11.274,0.957-12.528-6.86
                c-4.971,0.399-10.141,1.37-14.839-1.754c0.485-3.287,3.501-3.503,5.548-5.384c-5.41-2.063-11.688,0.657-17.052-3.492
                c4.231-5.182,10.634-5.083,16.004-8.327c-7.399-1.593-15.093-0.785-21.733-6.218c4.96-3.421,10.205-2.143,15.037-3.568
                c-0.508-1.108-1.586-1.265-2.58-1.553c-3.366-0.974-6.65-2.167-9.75-3.816c-1.293-0.688-2.833-1.273-2.184-3.647
                c4.433-1.481,8.987,1.092,13.771,0.156c-1.852-2.947-6.451-3.062-7.296-7.143c2.772-1.902,5.203,0.617,7.747,0.278
                c0.951-0.977-0.667-2.662,0.871-3.446c1.154-0.588,2.26-0.134,3.375,0.232c6.958,2.281,13.869,4.687,20.959,6.579
                c6.781,1.809,13.795,1.814,20.734,3.524c-0.569-2.7-4.596-3.142-3.34-6.574c2.1-1.057,4.155-0.223,6.182,0.416
                c4.604,1.451,9.246,2.808,13.768,4.484c14.109,5.23,28.675,8.565,43.515,10.834c1.479,0.226,2.96,0.515,4.396,0.929
                c4.991,1.438,9.745,2.639,14.679-0.84c2.664-1.879,6.352-1.771,9.694-1.914c4.161-0.177,8.312-0.387,12.458-0.886
                c4.18-0.503,7.666-2.099,10.427-5.271c2.516-2.891,5.138-5.696,7.524-8.689c9.023-11.32,18.498-22.321,24.493-35.742
                c3.382-7.57,9.62-11.726,17.55-13.44c2.229-0.482,4.812-1.188,5.646,2.162c1.415,0.286,2.223-0.729,3.109-1.399
                c11.94-9.012,25.355-15.282,39.033-21.068c0.609-0.258,1.225-0.569,1.866-0.673c2.094-0.338,3.288,1.08,2.385,2.997
                c-0.552,1.169-1.521,2.161-2.39,3.156c-0.542,0.62-1.238,1.109-1.883,1.637C1042.855,24.282,1042.098,24.655,1041.703,26.103z
                M938.996,184.49c1.281-2.509,4.014-4.73,2.775-8.015c-1.893-5.018,1.17-9.059,2.312-13.437c0.654-2.507,2.798-2.789,4.559-0.829
                c3.204,3.565,6.61,7.014,5.959,12.551c-0.267,2.271,0.26,4.644,0.507,6.962c0.265,2.48,0.622,4.953,1.483,7.314
                c0.61,1.669,1.774,2.563,3.45,1.6c2.382-1.37,5.032-1.412,7.628-1.678c1.76-3.111,1.404-6.319,1.351-9.439
                c-0.096-5.499-0.102-10.961,0.704-16.442c0.58-3.958,1.498-7.117,6.92-6.543c-1.553-2.305-1.251-4.179-1.188-6.201
                c0.103-3.274,0.418-7.06-4.15-8.452c-2.686-0.818-4.091-3.292-5.247-5.745c-0.852-1.806-1.549-3.688-2.455-5.465
                c-4.317-8.466-8.778-16.84-15.536-23.718c-4.238-4.313-8.959-6.14-14.729-3.229c-0.904,2.172,0.206,4.427,1.225,5.595
                c2.087,2.392,1.879,5.213,2.786,7.801c0.925,2.639,0.046,4.47-2.379,5.066c-2.569,0.632-2.883,2.722-3.631,4.689
                c-4.161-0.806-7.628,1.582-11.979,0.972c2.02,4.561,4.1,8.315,6.77,11.725c3.461,4.422,5.224,9.28,3.796,14.901
                c-2.886,11.363-0.769,23.639-6.864,34.645c3.213,0.733,6.357-1.48,8.64,1.598C932.989,184.88,932.989,184.88,938.996,184.49z
                M1000.465,195.819c1.72-2.207,0.042-4.638,1.357-6.582c2.258-1.19,4.468,1.531,6.97-0.071c2.382-3.358-1.413-6.683-0.849-10.117
                c2.783-1.833,6.656,0.329,8.433-2.267c0.585-2.649-2.255-3.91-1.169-6.004c0.97-0.858,2.021-0.685,2.991-0.841
                c1.389-0.224,2.659-0.755,3.054-2.199c0.42-1.539-0.841-2.357-1.77-3.302c-1.228-1.251-3.402-1.468-3.953-3.457
                c0.902-1.92,2.817-1.68,4.258-2.354c1.427-0.667,3.434-0.35,4.215-2.467c-0.91-1.611-2.664-1.738-4.219-2.062
                c-13.785-2.875-27.783-4.702-41.912-8.624c1.15,2.938-0.421,5.75,0.632,8.374c1.83,4.562,1.851,4.701-2.854,5.63
                c-0.795,0.157-1.674,0.031-2.388,0.846c-1.042,2.368-1.022,5.044-1.352,7.678c-0.726,5.82,0.521,11.66-0.28,17.459
                c-0.72,5.216-1.443,5.859-6.771,6.48c-1.77,0.207-3.583,0.617-3.211,2.86c0.438,2.628-0.022,5.258,0.098,7.883
                c2.644,0.575,4.129-1.797,6.668-1.815c1.711,1.328,3.449,3.129,6.242,3.104c3.441-0.03,3.015-3.451,4.596-5.081
                c0.822,0.081,1.486,0.081,2.119,0.219c6.651,1.451,6.645,1.455,9.549-4.69c0.118-0.25,0.533-0.359,0.953-0.625
                C994.693,194.156,997.234,196.327,1000.465,195.819z M965.332,127.539c3.302-1.701,6.034-3.799,8.586-6.132
                c3.056-2.793,5.773-5.942,9.578-7.878c1.977-1.006,3.252-2.857,3.551-5.162c0.432-3.327,2.129-5.814,4.684-7.928
                c2.063-1.706,3.93-3.728,3.855-6.683c-0.068-2.7,1.069-4.76,2.83-6.678c1.463-1.595,3.148-3.426,2.905-5.627
                c-0.464-4.201,1.545-7.312,3.563-10.583c1.293-2.097,2.721-4.324,1.676-6.996c-1.09-2.788-0.436-5.146,1.335-7.43
                c1.17-1.509,1.706-3.339,0.823-5.161c-1.25-2.579-0.762-4.637,1.021-6.838c1.687-2.083,1.881-4.422-0.317-6.368
                c-3.949,1.306-5.993,5.187-9.497,5.768c-2.42-0.886-0.995-3.41-2.697-4.276c-2.175,0.251-4.217,0.789-6.178,2.228
                c1.284,3.381,1.74,6.864,0.752,10.279c-0.725,2.511-0.871,4.771,0.147,7.242c1.087,2.638,0.865,5.293-1.464,7.297
                c-1.316,1.132-1.568,2.432-1.321,4.011c0.404,2.576-0.792,4.478-2.624,6.122c-1.093,0.981-2.695,1.81-2.493,3.512
                c0.604,5.08-2.61,7.971-5.938,10.809c-1.558,1.329-2.887,2.704-3.16,4.875c-0.312,2.477-1.822,4.292-3.763,5.658
                c-2.741,1.929-5.323,3.815-6.19,7.397c-0.443,1.825-2.32,2.727-3.831,3.77c-1.035,0.714-2.585,0.842-2.944,2.461
                C960.531,119.227,962.844,123.23,965.332,127.539z M956.709,112.956c3.641-1.768,6.922-3.211,7.738-7.479
                c0.363-1.896,2.131-2.863,3.672-3.893c1.938-1.292,4.242-2.548,4.663-4.97c0.659-3.789,3.067-6.101,5.736-8.42
                c2.163-1.88,4.004-3.978,3.575-7.256c-0.285-2.178,0.85-3.81,2.59-5.066c2.051-1.48,3.102-3.364,2.564-6.014
                c-0.352-1.731,0.117-3.224,1.671-4.375c2.04-1.51,2.29-3.552,1.221-5.776c-1.226-2.548-1.04-5.036-0.271-7.729
                c0.862-3.024,1.152-6.192-1.029-9.121c-2.648,0.435-3.95,2.435-5.312,4.244c-2.612,3.468-4.533,7.356-6.555,11.182
                c-2.019,3.82-3.711,7.869-6.529,11.186c-8.708,10.246-16.047,21.634-26.244,31.076
                C949.52,103.683,953.119,108.247,956.709,112.956z M832.903,96.608c0.394,2.798,2.073,3.823,4.089,4.146
                c2.268,0.363,3.67,1.402,4.135,3.654c0.496,2.4,2.082,3.806,4.348,4.308c2.521,0.558,4.203,1.892,4.98,4.35
                c0.662,2.092,2.204,3.297,4.164,3.999c4.223,1.51,8.568,2.792,11.092,7c0.153,0.256,0.518,0.454,0.822,0.542
                c4.752,1.363,9.6,2.527,13.027,6.53c6.246,0.163,11.678,3.754,17.599,4.554c6.546,0.885,13.215,1.526,19.868,1.746
                c1.211,0.04,2.701,0.695,3.606-1.01c-1.714-3.158-3.446-6.352-5.122-9.44c-4.099-0.515-7.801-1.304-11.7-0.561
                c-4.021,0.767-6.95,3.327-9.845,5.984c-2.163-2.842-1.709-5.645-2.126-8.226c-3.308-0.967-6.979-0.612-9.205-3.597
                c-1.413-1.896-3.278-2.581-5.528-2.939c-2.631-0.418-5.441-1.027-7.003-3.409c-1.696-2.587-4.124-3.642-6.839-4.615
                c-2.84-1.019-5.672-2.398-6.753-5.499c-0.766-2.197-2.144-3.092-4.274-3.605c-2.279-0.549-4.827-1.47-5.439-3.929
                c-1.283-5.157-5.644-4.651-9.534-6.192c1.2,2.72,5.031,2.662,4.529,6.051C838.973,97.945,836.189,96.18,832.903,96.608z
                M1006.823,71.963c0.806,1.165,1.86,1.295,2.781,1.528c15.524,3.933,31.302,6.268,47.277,7.202
                c6.096,0.356,6.579-0.167,8.015-6.189c1.627-1.399,4.723-0.375,5.63-3.165c-4.599-2.659-9.391-3.748-14.75-3.569
                c-10.64,0.355-21.298,0.236-31.93-0.669c-4.782-0.407-9.58-0.62-14.536-0.932C1008.43,68.221,1007.595,70.167,1006.823,71.963z
                M930.631,103.656c-12.685-2.577-22.004,3.44-31.018,10.302c-0.34,0.258-0.433,0.841-0.603,1.194
                c0.521,1.4,2.429,0.259,2.858,2.185c-0.219,2.146-0.801,4.792,0.784,7.193c5.797-0.861,11.386,0.212,16.997,0.789
                c1.148,0.118,2.686,0.947,3.363,0.169c1.846-2.116,3.991-0.907,6.188-0.859c0.111-4.305,4.842-2.791,6.576-5.623
                c-1.285-1.841-0.059-4.758-1.545-6.342C931.75,110.018,931.616,106.737,930.631,103.656z M903.467,108.13
                c-2.293-1.474-4.979-1.614-7.502-2.251c-9.807-2.48-20.074-2.69-29.708-6.066c-4.696-1.645-9.442-3.153-14.199-4.613
                c-1.181-0.363-2.346-1.316-3.897-0.556c0.436,2.827,2.676,3.558,4.865,4.21c1.953,0.581,4.023,1.054,4.659,3.255
                c1.013,3.504,3.741,4.847,6.779,5.976c2.155,0.801,4.534,1.3,5.845,3.502c1.695,2.848,4.443,3.89,7.488,4.305
                c2.235,0.305,3.973,1.253,5.65,2.796c5.377,4.947,10.665,4.654,13.092-2.967c0.713-2.238,2.044-3.691,3.857-4.964
                C901.453,110.017,902.832,109.62,903.467,108.13z M1065.506,32.426c-17.165,2.046-33.755,6.115-50.293,10.435
                c-3.342,0.873-4.578,3.232-5.231,6.874c21.664-0.818,42.909-1.411,63.596-7.272c-0.966-0.519-1.907-0.618-2.852-0.654
                c-8.833-0.333-17.606,0.18-26.324,1.705c-1.458,0.255-3.221,1.191-4.837-1.475C1048.301,38.487,1057.323,36.417,1065.506,32.426z
                M1057.977,82.617c-18.148-0.955-35.615-3.918-52.657-8.225c-3.194,5.56-2.8,6.806,2.251,8.472c1.58,0.521,3.164,1.033,4.76,1.5
                c12.662,3.709,25.549,6.021,39.195,6.414C1051.768,86.724,1057.556,87.659,1057.977,82.617z M1077.48,63.143
                c-1.296-0.271-2.58-0.622-3.889-0.801c-7.09-0.968-13.898-3.563-21.112-3.752c-12.995-0.341-25.992-0.585-38.988-0.871
                c-0.667-0.015-1.333-0.05-1.999-0.056c-2.274-0.021-3.371,1.283-3.459,3.388c-0.082,1.927,0.875,3.136,2.938,3.284
                c1.163,0.083,2.316,0.281,3.479,0.37c15.134,1.166,30.3,1.735,45.456,1.067C1065.814,65.511,1071.85,65.345,1077.48,63.143z
                M832.668,143.558c0.648,1.815,1.747,2.413,3.141,2.621c1.449,0.216,2.777,0.286,4.015,1.537
                c3.977,4.024,8.167,3.671,11.636-0.835c2.953-3.835,5.299-8.062,7.492-12.365c1.285-2.519,2.584-5.038,3.704-7.631
                c0.935-2.165,0.085-4.191-1.933-5.502c-1.922-1.249-3.032-0.024-4.246,1.211c-2.799,2.85-5.621,5.678-8.485,8.463
                C843.252,135.665,838.28,140.008,832.668,143.558z M1035.426,111.116c-13.871-2.191-26.584-6.831-39.286-12.654
                c-1.571,2.819-4.622,3.949-5.937,7.01c11.641,6.192,23.812,10.386,36.432,13.375c0.965,0.229,2.189,0.606,2.867-0.153
                C1031.527,116.423,1034.814,115.14,1035.426,111.116z M865.038,126.484c-4.354,8.67-8.362,17.394-15.216,25.379
                c3.945,0.358,6.859,1.445,9.441,3.685c1.225,1.061,3.57,0.657,5.064-0.718c0.965-0.888,1.856-1.903,2.588-2.991
                c4.032-6.004,6.066-12.836,8.018-19.696c0.645-2.266-0.457-3.486-2.526-4.002C870.166,127.581,867.899,127.122,865.038,126.484z
                M877.02,132.25c-2.881,8.812-5.309,17.291-11.297,24.312c3.256,3.365,8.129,2.313,11.311,5.154
                c0.949,0.848,3.157,0.825,4.725,0.035c2.733-5.717,4.809-18.785,4.324-27.698C883.222,133.932,880.318,133.458,877.02,132.25z
                M997.275,96.752c13.08,6.408,26.342,10.91,40.508,12.728c1.43-2.489,4.737-3.7,3.719-7.567
                c-14.891-1.236-29.031-5.692-42.466-12.322c-0.56,0.89-0.972,1.272-1.046,1.712C997.715,92.927,997.551,94.57,997.275,96.752z
                M1011.414,55.611c22.639,1.052,44.988,1.749,67.277-1.152c-22.291-4.809-44.707-4.879-66.979-2.608
                C1010.97,53.241,1011.027,54.208,1011.414,55.611z M1000.951,88.019c9.768,5.652,24.162,10.183,36.684,11.632
                c6.299,0.729,6.302,0.73,8.33-5.387c0.148-0.448,0.436-0.929-0.525-1.865c-6.704-0.547-13.898-1.643-21.045-3.063
                c-7.137-1.419-14.011-3.847-20.887-5.638C1002.233,85.078,1001.35,86.231,1000.951,88.019z M883.605,163.419
                c3.854,0.259,6.938-0.892,10.061-0.152c2.492,0.59,3.979-0.625,4.57-3.251c1.632-7.23,1.062-14.412,0.32-21.854
                c-3.906,0.003-6.656-2.49-10.192-3.104C887.575,144.569,886.934,153.808,883.605,163.419z M950.479,200.375
                c1.113-1.286,1.248-3.199,2.01-4.828c1.303-2.787,1.611-5.429,0.775-8.589c-1.006-3.81-1.387-7.903-1.29-11.855
                c0.114-4.656-1.679-7.833-5.63-10.418c-0.457,3.698-3.269,6.493-2.175,10.321c0.769,2.692,1.223,5.342-0.827,8.038
                c-1.693,2.227-3.018,4.612-6.105,5.166c1.292,2.537,1.856,5.225,4.207,6.461c2.485,1.308,4.945,2.369,6.078,5.288
                C947.819,200.726,949.225,200.863,950.479,200.375z M785.537,120.051c9.946,1.534,19.271,0.421,28.441-2.032
                c15.816-4.232,23.83-7.445,26.336-10.178C832.727,105.353,795.404,113.528,785.537,120.051z M848.837,113.773
                c-3.305-3.857-4.792-4.308-8.585-2.742c-7.826,3.232-15.793,6.077-23.959,8.283c-6.298,1.701-12.048,4.62-17.92,7.296
                c-0.814,0.371-2.07,0.531-1.49,2.325C815.438,128.813,831.734,120.98,848.837,113.773z M1013.627,135.617
                c1.922-2.446,4.633-3.994,5.295-7.354c-11.859-2.534-22.435-7.589-32.301-14.147c-1.746,0.862-3.305,1.767-4.814,3.512
                C991.19,125.836,1001.578,131.993,1013.627,135.617z M987.876,112.675c10.538,6.979,21.429,12.234,34.091,14.036
                c0.584-2.656,3.959-2.813,4.65-5.829c-12.839-3.236-25.121-7.518-36.73-13.345c-0.463,0.718-0.701,0.969-0.803,1.268
                C988.715,109.898,988.391,111.008,987.876,112.675z M785.234,97.376c15.227,4.736,30.852,3.716,46.752,3.519
                c-0.649-2.069-0.74-3.726-2.066-5.35c-7.354-0.969-14.961-1.834-22.27-4.181c-0.414-0.133-1.203-0.157-1.34,0.063
                c-1.882,3.036-4.418,1.16-6.707,0.941c-2.668-0.255-5.191-1.654-8.736-1.183c5.5,3.575,11.591,3.385,16.905,5.603
                c-0.499,0.817-0.644,1.319-0.843,1.342C799.654,98.945,792.549,96.502,785.234,97.376z M1059.668,24.25
                c-7.359,1.636-14.631,3.264-21.573,6.074c-3.696,1.496-7.447,2.86-11.187,4.25c-1.188,0.442-2.444,1-3.981-0.814
                c6.054-4.53,12.899-7.58,18.387-12.519c-10.597,3.886-20.821,8.38-29.865,15.277c0.794,1.696,1.413,3.02,2.011,4.294
                C1021.812,40.13,1051.296,29.694,1059.668,24.25z M808.284,133.493c6.497,2.02,12.05,2.417,17.362,0.074
                c8.883-3.917,16.521-9.807,23.854-16.089c0.393-0.336,0.411-0.836,0.062-1.281c-0.643-0.434-1.277-0.205-1.88,0.066
                c-10.298,4.64-20.83,8.651-31.619,12.005C813.386,129.1,811.168,130.802,808.284,133.493z M820.8,137.459
                c6.493,4.786,10.955,4.904,16.249,0.755c6.692-5.245,12.773-11.154,18.499-17.434c0.282-0.31,0.322-0.584-0.275-1.242
                c-0.183-0.097-0.736-0.437-1.327-0.692c-0.734-0.318-1.441-0.893-2.674-0.234C842.212,125.8,832.918,133.362,820.8,137.459z
                M838.748,104.068c-5.407-2.584-46.076-0.617-56.47,2.733C791.303,110.626,829.958,108.756,838.748,104.068z M907.929,165.245
                c3.144,0.844,5.646,1.516,8.572,2.301c2.819-9.594,2.009-18.816,0.768-28.062c-2.769-0.487-4.854-0.672-7.305-0.36
                C910.012,147.913,911.547,156.597,907.929,165.245z M976.035,122.519c0.379,1.578,1.567,2.014,2.434,2.732
                c7.352,6.104,15.67,10.57,24.402,14.331c4.563,1.966,4.684,1.941,8.689-2.467c-11.761-4.21-22.312-10.295-31.51-18.587
                C978.258,119.984,976.923,121.145,976.035,122.519z M973.902,124.258c-2.743,1.971-5.128,3.683-7.538,5.414
                c0.788,2.188,1.336,4.099,3.499,4.934c6.217,2.4,12.482,4.649,18.989,6.144c2.472,0.568,4.017-0.481,5.017-2.986
                C986.688,133.977,980.447,129.279,973.902,124.258z M899.351,162.958c5.092,2.868,7.233,2.148,8.29-2.685
                c1.541-7.047,1.051-14.125-0.043-21.198c-2.258-1.105-4.385-1.633-6.801-0.529C900.953,146.745,902.104,154.871,899.351,162.958z
                M919.38,139.964c0.993,8.996,1.279,18.172-0.383,27.296c4.267,0.379,6.206-0.807,6.79-4.07c0.613-3.424,0.793-6.942,1.611-10.311
                c1.268-5.224-1.387-8.938-4.287-12.622C922.207,139.107,920.988,138.728,919.38,139.964z M910.295,203.344
                c1.67-0.203,2.306-1.278,3.189-1.982c1.029-0.82,1.939-1.824,3.053-2.499c3.839-2.328,6.246-1.577,8.139,2.392
                c0.348,0.729,0.623,1.583,1.74,1.258c0.32-3.701,0.227-4.039-3.064-11.061C917.516,191.536,910.74,197.559,910.295,203.344z
                M958.078,193.072c-3.557,2.535-5.414,8.469-4.646,14.348c1.386,0.283,2.533,1.412,4.314,1.279
                C957.898,203.514,960.566,198.432,958.078,193.072z M899.104,118.518c-3.656,2.305-5.859,5.198-4.687,10.033
                c1.976-1.193,3.749-2.265,5.262-3.179C900.505,122.83,898.962,120.859,899.104,118.518z"/>
            <path fill-rule="evenodd" d="M260.097,429.371c-1.957-0.594-2.16-1.884-2.652-2.875c-3.701-7.438-7.334-14.91-11.019-22.356
                c-0.822-1.661-1.359-3.325-1.349-5.23c0.059-10.975,0.033-21.949,0.051-32.924c0.001-0.649,0.143-1.298,0.208-1.85
                c1.497-0.93,1.969,0.228,2.332,1.053c3.275,7.452,6.797,14.844,5.936,23.365c-0.167,1.646,0.104,3.335-0.05,4.983
                c-0.355,3.801,0.456,7.283,2.689,10.361c3.684,5.077,5.075,10.696,4.396,16.908C260.339,423.554,261.03,426.395,260.097,429.371z
                M247.112,371.443c0,4.983,0.396,10.005-0.083,14.942c-0.982,10.148,1.201,19.383,6.609,27.967
                c1.636,2.597,2.461,5.674,4.677,8.054c0.134-4.221,1.672-8.597-1.034-12.38c-5.004-6.997-6.478-14.735-5.582-23.103
                c0.053-0.493-0.026-1,0.012-1.496C252.115,380.257,249.732,375.875,247.112,371.443z"/>
            <path fill-rule="evenodd" d="M244.541,337.783c6.332,6.703,11.347,24.13,9.763,34.633
                C247.693,361.416,241.418,351.269,244.541,337.783z M246.5,344.582c-1.884,7.375,1.631,12.614,5.09,17.869
                C251.146,356.359,250.512,350.321,246.5,344.582z"/>
            <path fill-rule="evenodd" d="M123.58,237.191c4.254,3.238,6.144,6.756,5.272,11.828c-1.177,6.848-1.849,13.781-2.764,20.675
                c-0.215,1.618-0.214,3.332-1.539,4.601C121.475,269.732,120.821,247.628,123.58,237.191z M124.711,265.231
                c1.501-7.212,1.947-13.788,2.355-20.359c0.1-1.593-0.642-2.884-2.848-3.047C124.147,249.413,122.255,256.914,124.711,265.231z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(381)} fill={props.fillColors[381]} d="M938.996,184.49c-6.007,0.391-6.007,0.391-7.294,6.226
                c-2.282-3.078-5.427-0.864-8.64-1.598c6.096-11.006,3.979-23.281,6.864-34.645c1.428-5.622-0.335-10.479-3.796-14.901
                c-2.67-3.41-4.75-7.165-6.77-11.725c4.352,0.61,7.818-1.777,11.979-0.972c0.748-1.967,1.062-4.058,3.631-4.689
                c2.425-0.596,3.304-2.427,2.379-5.066c-0.907-2.588-0.699-5.409-2.786-7.801c-1.019-1.168-2.129-3.423-1.225-5.595
                c5.77-2.911,10.49-1.085,14.729,3.229c6.758,6.878,11.219,15.252,15.536,23.718c0.906,1.777,1.604,3.659,2.455,5.465
                c1.156,2.453,2.562,4.927,5.247,5.745c4.568,1.392,4.253,5.178,4.15,8.452c-0.063,2.022-0.365,3.896,1.188,6.201
                c-5.422-0.574-6.34,2.585-6.92,6.543c-0.806,5.48-0.8,10.943-0.704,16.442c0.054,3.12,0.409,6.328-1.351,9.439
                c-2.596,0.267-5.246,0.308-7.628,1.678c-1.676,0.964-2.84,0.069-3.45-1.6c-0.861-2.361-1.219-4.834-1.483-7.314
                c-0.247-2.318-0.773-4.692-0.507-6.962c0.651-5.538-2.755-8.986-5.959-12.551c-1.761-1.959-3.904-1.678-4.559,0.829
                c-1.143,4.378-4.205,8.419-2.312,13.437C943.01,179.759,940.277,181.98,938.996,184.49z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(382)} fill={props.fillColors[382]} d="M1000.465,195.819c-3.23,0.507-5.771-1.664-8.594-2.024
                c-0.42,0.266-0.835,0.375-0.953,0.625c-2.904,6.145-2.897,6.142-9.549,4.69c-0.633-0.138-1.297-0.138-2.119-0.219
                c-1.581,1.63-1.154,5.051-4.596,5.081c-2.793,0.025-4.531-1.776-6.242-3.104c-2.539,0.018-4.024,2.39-6.668,1.815
                c-0.12-2.625,0.34-5.255-0.098-7.883c-0.372-2.243,1.441-2.654,3.211-2.86c5.328-0.621,6.052-1.264,6.771-6.48
                c0.801-5.8-0.445-11.64,0.28-17.459c0.329-2.634,0.31-5.31,1.352-7.678c0.714-0.814,1.593-0.688,2.388-0.846
                c4.705-0.929,4.685-1.068,2.854-5.63c-1.053-2.624,0.519-5.436-0.632-8.374c14.129,3.922,28.127,5.749,41.912,8.624
                c1.555,0.324,3.309,0.451,4.219,2.062c-0.781,2.117-2.788,1.801-4.215,2.467c-1.44,0.673-3.355,0.434-4.258,2.354
                c0.551,1.988,2.726,2.206,3.953,3.457c0.929,0.945,2.189,1.763,1.77,3.302c-0.395,1.444-1.665,1.975-3.054,2.199
                c-0.97,0.156-2.021-0.018-2.991,0.841c-1.086,2.094,1.754,3.355,1.169,6.004c-1.776,2.596-5.649,0.434-8.433,2.267
                c-0.564,3.434,3.23,6.758,0.849,10.117c-2.502,1.602-4.712-1.119-6.97,0.071C1000.507,191.181,1002.185,193.613,1000.465,195.819z
                "/>
            <path fill-rule="evenodd" onClick={() => props.onFill(383)} fill={props.fillColors[383]} d="M965.332,127.539c-2.488-4.309-4.801-8.312-7.111-12.311
                c0.359-1.619,1.909-1.747,2.944-2.461c1.511-1.043,3.388-1.945,3.831-3.77c0.867-3.583,3.449-5.468,6.19-7.397
                c1.94-1.366,3.45-3.181,3.763-5.658c0.273-2.17,1.603-3.546,3.16-4.875c3.328-2.838,6.543-5.729,5.938-10.809
                c-0.202-1.702,1.4-2.531,2.493-3.512c1.832-1.645,3.028-3.546,2.624-6.122c-0.247-1.58,0.005-2.879,1.321-4.011
                c2.329-2.004,2.551-4.659,1.464-7.297c-1.019-2.471-0.872-4.731-0.147-7.242c0.988-3.416,0.532-6.898-0.752-10.279
                c1.961-1.439,4.003-1.976,6.178-2.228c1.702,0.867,0.277,3.391,2.697,4.276c3.504-0.581,5.548-4.462,9.497-5.768
                c2.198,1.945,2.004,4.284,0.317,6.368c-1.783,2.201-2.271,4.259-1.021,6.838c0.883,1.822,0.347,3.652-0.823,5.161
                c-1.771,2.284-2.425,4.642-1.335,7.43c1.045,2.672-0.383,4.899-1.676,6.996c-2.019,3.271-4.027,6.383-3.563,10.583
                c0.243,2.202-1.442,4.032-2.905,5.627c-1.761,1.918-2.898,3.978-2.83,6.678c0.074,2.955-1.792,4.977-3.855,6.683
                c-2.555,2.114-4.252,4.601-4.684,7.928c-0.299,2.305-1.574,4.155-3.551,5.162c-3.805,1.936-6.522,5.085-9.578,7.878
                C971.366,123.74,968.634,125.838,965.332,127.539z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(384)} fill={props.fillColors[384]} d="M956.709,112.956c-3.59-4.709-7.189-9.273-12.51-12.41
                c10.197-9.442,17.536-20.831,26.244-31.076c2.818-3.317,4.511-7.366,6.529-11.186c2.021-3.825,3.942-7.714,6.555-11.182
                c1.362-1.809,2.664-3.81,5.312-4.244c2.182,2.929,1.892,6.097,1.029,9.121c-0.77,2.693-0.955,5.182,0.271,7.729
                c1.069,2.224,0.819,4.266-1.221,5.776c-1.554,1.151-2.022,2.644-1.671,4.375c0.537,2.65-0.514,4.534-2.564,6.014
                c-1.74,1.256-2.875,2.888-2.59,5.066c0.429,3.278-1.412,5.376-3.575,7.256c-2.669,2.32-5.077,4.631-5.736,8.42
                c-0.421,2.422-2.726,3.678-4.663,4.97c-1.541,1.029-3.309,1.996-3.672,3.893C963.631,109.745,960.35,111.188,956.709,112.956z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(385)} fill={props.fillColors[385]} d="M832.903,96.608c3.286-0.428,6.069,1.337,8.891-0.159
                c0.502-3.389-3.329-3.332-4.529-6.051c3.891,1.541,8.251,1.035,9.534,6.192c0.612,2.459,3.16,3.38,5.439,3.929
                c2.131,0.514,3.509,1.409,4.274,3.605c1.081,3.1,3.913,4.48,6.753,5.499c2.715,0.974,5.143,2.028,6.839,4.615
                c1.562,2.382,4.372,2.991,7.003,3.409c2.25,0.358,4.115,1.043,5.528,2.939c2.226,2.984,5.897,2.629,9.205,3.597
                c0.417,2.581-0.037,5.384,2.126,8.226c2.895-2.657,5.823-5.217,9.845-5.984c3.899-0.743,7.602,0.046,11.7,0.561
                c1.676,3.088,3.408,6.282,5.122,9.44c-0.905,1.705-2.396,1.05-3.606,1.01c-6.653-0.219-13.322-0.861-19.868-1.746
                c-5.921-0.8-11.353-4.391-17.599-4.554c-3.428-4.003-8.275-5.167-13.027-6.53c-0.305-0.088-0.669-0.286-0.822-0.542
                c-2.523-4.208-6.869-5.49-11.092-7c-1.96-0.701-3.502-1.906-4.164-3.999c-0.777-2.458-2.459-3.792-4.98-4.35
                c-2.266-0.501-3.852-1.908-4.348-4.308c-0.465-2.252-1.867-3.291-4.135-3.654C834.977,100.431,833.297,99.406,832.903,96.608z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(386)} fill={props.fillColors[386]} d="M1006.823,71.963c0.771-1.796,1.606-3.742,2.487-5.792
                c4.956,0.312,9.754,0.524,14.536,0.932c10.632,0.905,21.29,1.024,31.93,0.669c5.359-0.179,10.151,0.91,14.75,3.569
                c-0.907,2.789-4.003,1.765-5.63,3.165c-1.436,6.022-1.919,6.545-8.015,6.189c-15.976-0.934-31.753-3.27-47.277-7.202
                C1008.684,73.258,1007.629,73.127,1006.823,71.963z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(387)} fill={props.fillColors[387]} d="M930.631,103.656c0.985,3.081,1.119,6.362,3.602,9.008
                c1.486,1.584,0.26,4.501,1.545,6.342c-1.734,2.832-6.465,1.317-6.576,5.623c-2.196-0.048-4.342-1.257-6.188,0.859
                c-0.678,0.778-2.215-0.051-3.363-0.169c-5.611-0.577-11.2-1.649-16.997-0.789c-1.585-2.401-1.003-5.047-0.784-7.193
                c-0.43-1.925-2.337-0.784-2.858-2.185c0.17-0.353,0.263-0.936,0.603-1.194C908.627,107.096,917.946,101.079,930.631,103.656z
                M908.348,115.963c-1.934,0.589-3.246,0.989-4.557,1.389C905.76,119.152,907.104,118.86,908.348,115.963z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(388)} fill={props.fillColors[388]} d="M903.467,108.13c-0.635,1.49-2.014,1.887-3.07,2.628
                c-1.813,1.272-3.145,2.726-3.857,4.964c-2.427,7.621-7.715,7.914-13.092,2.967c-1.678-1.543-3.415-2.491-5.65-2.796
                c-3.045-0.416-5.793-1.458-7.488-4.305c-1.311-2.202-3.689-2.701-5.845-3.502c-3.038-1.129-5.767-2.472-6.779-5.976
                c-0.636-2.201-2.706-2.674-4.659-3.255c-2.189-0.652-4.43-1.383-4.865-4.21c1.552-0.761,2.717,0.193,3.897,0.556
                c4.757,1.46,9.503,2.968,14.199,4.613c9.634,3.375,19.901,3.586,29.708,6.066C898.487,106.516,901.174,106.656,903.467,108.13z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(389)} fill={props.fillColors[389]} d="M1065.506,32.426c-8.183,3.991-17.205,6.062-25.941,9.613
                c1.616,2.666,3.379,1.729,4.837,1.475c8.718-1.525,17.491-2.038,26.324-1.705c0.944,0.036,1.886,0.135,2.852,0.654
                c-20.687,5.862-41.932,6.454-63.596,7.272c0.653-3.641,1.89-6.001,5.231-6.874C1031.751,38.541,1048.341,34.472,1065.506,32.426z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(390)} fill={props.fillColors[390]} d="M1057.977,82.617c-0.421,5.042-6.209,4.107-6.451,8.162
                c-13.646-0.393-26.533-2.705-39.195-6.414c-1.596-0.468-3.18-0.979-4.76-1.5c-5.051-1.667-5.445-2.912-2.251-8.472
                C1022.361,78.699,1039.828,81.663,1057.977,82.617z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(391)} fill={props.fillColors[391]} d="M1077.48,63.143c-5.631,2.203-11.666,2.369-17.574,2.629
                c-15.156,0.667-30.322,0.099-45.456-1.067c-1.163-0.089-2.316-0.287-3.479-0.37c-2.062-0.147-3.02-1.356-2.938-3.284
                c0.088-2.104,1.185-3.408,3.459-3.388c0.666,0.006,1.332,0.042,1.999,0.056c12.996,0.286,25.993,0.53,38.988,0.871
                c7.214,0.189,14.022,2.785,21.112,3.752C1074.9,62.521,1076.185,62.871,1077.48,63.143z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(392)} fill={props.fillColors[392]} d="M832.668,143.558c5.612-3.55,10.584-7.893,15.323-12.502
                c2.864-2.785,5.687-5.613,8.485-8.463c1.214-1.235,2.324-2.46,4.246-1.211c2.018,1.311,2.867,3.337,1.933,5.502
                c-1.12,2.594-2.419,5.112-3.704,7.631c-2.193,4.304-4.539,8.53-7.492,12.365c-3.469,4.506-7.659,4.859-11.636,0.835
                c-1.237-1.251-2.565-1.321-4.015-1.537C834.415,145.971,833.316,145.374,832.668,143.558z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(393)} fill={props.fillColors[393]} d="M1035.426,111.116c-0.611,4.024-3.898,5.308-5.924,7.578
                c-0.678,0.759-1.902,0.381-2.867,0.153c-12.619-2.989-24.791-7.182-36.432-13.375c1.314-3.062,4.365-4.191,5.937-7.01
                C1008.842,104.285,1021.555,108.925,1035.426,111.116z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(394)} fill={props.fillColors[394]} d="M865.038,126.484c2.861,0.638,5.128,1.097,7.369,1.656
                c2.069,0.516,3.171,1.737,2.526,4.002c-1.951,6.859-3.985,13.691-8.018,19.696c-0.731,1.088-1.623,2.103-2.588,2.991
                c-1.494,1.375-3.84,1.779-5.064,0.718c-2.582-2.24-5.496-3.327-9.441-3.685C856.676,143.877,860.685,135.154,865.038,126.484z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(395)} fill={props.fillColors[395]} d="M877.02,132.25c3.299,1.208,6.202,1.682,9.062,1.802
                c0.484,8.914-1.591,21.981-4.324,27.698c-1.567,0.791-3.775,0.813-4.725-0.035c-3.182-2.84-8.055-1.789-11.311-5.154
                C871.711,149.541,874.139,141.062,877.02,132.25z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(396)} fill={props.fillColors[396]} d="M997.275,96.752c0.275-2.182,0.439-3.825,0.715-5.449
                c0.074-0.44,0.486-0.823,1.046-1.712c13.435,6.629,27.575,11.085,42.466,12.322c1.019,3.867-2.289,5.078-3.719,7.567
                C1023.617,107.662,1010.355,103.16,997.275,96.752z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(397)} fill={props.fillColors[397]} d="M1011.414,55.611c-0.387-1.403-0.444-2.37,0.299-3.76
                c22.271-2.271,44.688-2.201,66.979,2.608C1056.402,57.359,1034.053,56.663,1011.414,55.611z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(398)} fill={props.fillColors[398]} d="M1000.951,88.019c0.398-1.788,1.282-2.941,2.557-4.321
                c6.876,1.792,13.75,4.219,20.887,5.638c7.146,1.42,14.341,2.516,21.045,3.063c0.961,0.936,0.674,1.417,0.525,1.865
                c-2.028,6.117-2.031,6.116-8.33,5.387C1025.113,98.202,1010.719,93.671,1000.951,88.019z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(399)} fill={props.fillColors[399]} d="M883.605,163.419c3.328-9.611,3.97-18.85,4.759-28.361
                c3.536,0.614,6.286,3.108,10.192,3.104c0.742,7.442,1.312,14.623-0.32,21.854c-0.592,2.627-2.078,3.841-4.57,3.251
                C890.543,162.527,887.46,163.678,883.605,163.419z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(400)} fill={props.fillColors[400]} d="M950.479,200.375c-1.254,0.488-2.659,0.35-2.957-0.417
                c-1.133-2.918-3.593-3.98-6.078-5.288c-2.351-1.236-2.915-3.924-4.207-6.461c3.088-0.554,4.412-2.939,6.105-5.166
                c2.05-2.696,1.596-5.346,0.827-8.038c-1.094-3.828,1.718-6.624,2.175-10.321c3.951,2.586,5.744,5.763,5.63,10.418
                c-0.097,3.952,0.284,8.045,1.29,11.855c0.836,3.16,0.527,5.803-0.775,8.589C951.727,197.176,951.592,199.089,950.479,200.375z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(401)} fill={props.fillColors[401]} d="M785.537,120.051c9.867-6.523,47.189-14.699,54.777-12.21
                c-2.506,2.733-10.52,5.946-26.336,10.178C804.809,120.473,795.483,121.585,785.537,120.051z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(402)} fill={props.fillColors[402]} d="M848.837,113.773c-17.103,7.207-33.399,15.04-51.954,15.162
                c-0.58-1.794,0.676-1.954,1.49-2.325c5.872-2.676,11.622-5.595,17.92-7.296c8.166-2.206,16.133-5.05,23.959-8.283
                C844.045,109.465,845.532,109.917,848.837,113.773z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(403)} fill={props.fillColors[403]} d="M1013.627,135.617c-12.049-3.624-22.437-9.78-31.82-17.989
                c1.51-1.745,3.068-2.65,4.814-3.512c9.866,6.558,20.441,11.613,32.301,14.147C1018.26,131.623,1015.549,133.17,1013.627,135.617z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(404)} fill={props.fillColors[404]} d="M987.876,112.675c0.515-1.667,0.839-2.776,1.208-3.87
                c0.102-0.298,0.34-0.55,0.803-1.268c11.609,5.827,23.892,10.108,36.73,13.345c-0.691,3.016-4.066,3.173-4.65,5.829
                C1009.305,124.909,998.414,119.653,987.876,112.675z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(405)} fill={props.fillColors[405]} d="M785.234,97.376c7.314-0.875,14.42,1.569,21.695,0.755
                c0.199-0.022,0.344-0.525,0.843-1.342c-5.314-2.218-11.405-2.028-16.905-5.603c3.545-0.471,6.068,0.928,8.736,1.183
                c2.289,0.219,4.825,2.095,6.707-0.941c0.137-0.221,0.926-0.196,1.34-0.063c7.309,2.346,14.915,3.211,22.27,4.181
                c1.326,1.624,1.417,3.281,2.066,5.35C816.086,101.092,800.461,102.113,785.234,97.376z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(406)} fill={props.fillColors[406]} d="M1059.668,24.25c-8.372,5.444-37.855,15.88-46.209,16.562
                c-0.598-1.274-1.217-2.599-2.011-4.294c9.044-6.896,19.269-11.391,29.865-15.277c-5.487,4.939-12.333,7.989-18.387,12.519
                c1.537,1.814,2.794,1.256,3.981,0.814c3.739-1.39,7.49-2.754,11.187-4.25C1045.037,27.514,1052.309,25.886,1059.668,24.25z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(407)} fill={props.fillColors[407]} d="M808.284,133.493c2.884-2.69,5.102-4.393,7.779-5.226
                c10.789-3.354,21.321-7.366,31.619-12.005c0.603-0.271,1.237-0.5,1.878-0.066c0.352,0.445,0.333,0.945-0.06,1.281
                c-7.333,6.282-14.972,12.172-23.854,16.089C820.334,135.909,814.781,135.513,808.284,133.493z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(408)} fill={props.fillColors[408]} d="M820.8,137.459c12.118-4.097,21.412-11.66,30.472-18.847
                c1.232-0.658,1.939-0.083,2.674,0.234c0.591,0.255,1.145,0.595,1.327,0.692c0.598,0.658,0.558,0.932,0.275,1.242
                c-5.726,6.279-11.807,12.189-18.499,17.434C831.755,142.363,827.293,142.246,820.8,137.459z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(409)} fill={props.fillColors[409]} d="M838.748,104.068c-8.79,4.688-47.445,6.559-56.47,2.733
                C792.672,103.451,833.341,101.483,838.748,104.068z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(410)} fill={props.fillColors[410]} d="M907.929,165.245c3.618-8.648,2.083-17.332,2.035-26.122
                c2.45-0.312,4.536-0.127,7.305,0.36c1.241,9.246,2.052,18.469-0.768,28.062C913.574,166.761,911.072,166.089,907.929,165.245z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(411)} fill={props.fillColors[411]} d="M976.035,122.519c0.888-1.374,2.223-2.534,4.016-3.991
                c9.198,8.292,19.749,14.377,31.51,18.587c-4.006,4.408-4.126,4.433-8.689,2.467c-8.732-3.761-17.051-8.227-24.402-14.331
                C977.603,124.532,976.414,124.097,976.035,122.519z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(412)} fill={props.fillColors[412]} d="M973.902,124.258c6.545,5.021,12.785,9.719,19.967,13.505
                c-1,2.505-2.545,3.554-5.017,2.986c-6.507-1.495-12.772-3.744-18.989-6.144c-2.163-0.835-2.711-2.745-3.499-4.934
                C968.774,127.941,971.159,126.229,973.902,124.258z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(413)} fill={props.fillColors[413]} d="M899.351,162.958c2.754-8.086,1.603-16.212,1.446-24.412
                c2.416-1.104,4.543-0.576,6.801,0.529c1.094,7.073,1.584,14.151,0.043,21.198C906.584,165.105,904.442,165.825,899.351,162.958z"
                />
            <path fill-rule="evenodd" onClick={() => props.onFill(414)} fill={props.fillColors[414]} d="M919.38,139.964c1.608-1.236,2.827-0.856,3.731,0.293
                c2.9,3.685,5.555,7.398,4.287,12.622c-0.818,3.369-0.998,6.887-1.611,10.311c-0.584,3.263-2.523,4.45-6.79,4.07
                C920.659,158.136,920.373,148.959,919.38,139.964z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(415)} fill={props.fillColors[415]} d="M910.295,203.344c0.445-5.786,7.221-11.808,13.057-11.892
                c3.291,7.021,3.385,7.359,3.066,11.059c-1.119,0.326-1.395-0.528-1.742-1.257c-1.893-3.969-4.3-4.719-8.139-2.392
                c-1.113,0.675-2.023,1.679-3.053,2.499C912.601,202.066,911.965,203.141,910.295,203.344z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(416)} fill={props.fillColors[416]} d="M958.078,193.072c2.488,5.36-0.18,10.442-0.332,15.627
                c-1.781,0.133-2.929-0.996-4.314-1.279C952.664,201.541,954.521,195.606,958.078,193.072z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(417)} fill={props.fillColors[417]} d="M899.104,118.518c-0.142,2.341,1.401,4.312,0.575,6.854
                c-1.513,0.914-3.286,1.986-5.262,3.179C893.244,123.716,895.447,120.823,899.104,118.518z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(418)} fill={props.fillColors[418]} d="M247.112,371.443c2.62,4.432,5.003,8.814,4.6,13.984
                c-0.039,0.496,0.041,1.002-0.012,1.496c-0.896,8.368,0.577,16.106,5.582,23.103c2.706,3.784,1.167,8.159,1.034,12.38
                c-2.215-2.379-3.041-5.457-4.677-8.054c-5.408-8.584-7.591-17.819-6.609-27.967C247.508,381.448,247.112,376.426,247.112,371.443z
                "/>
            <path fill-rule="evenodd" onClick={() => props.onFill(419)} fill={props.fillColors[419]} d="M246.5,344.582c4.012,5.739,4.646,11.778,5.09,17.869
                C248.131,357.196,244.616,351.957,246.5,344.582z"/>
            <path fill-rule="evenodd" onClick={() => props.onFill(420)} fill={props.fillColors[420]} d="M124.711,265.231c-2.456-8.317-0.563-15.818-0.492-23.406
                c2.206,0.163,2.947,1.454,2.848,3.047C126.658,251.443,126.212,258.019,124.711,265.231z"/>
            <path fill-rule="evenodd" d="M908.348,115.963c-1.244,2.897-2.588,3.189-4.557,1.389
                C905.102,116.952,906.414,116.553,908.348,115.963z"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M908.5,177.889c-3.633,1.291-6.664,3.249-9.069,6.448c-2.763,3.674-7.421,4.365-11.462,5.436
                c-11.998,3.181-23.68,7.31-35.515,10.969c-5.956,1.842-11.726,3.8-16.936,7.176c-3.936,2.55-7.728,3.268-12.566,2.184
                c-5.542-1.242-11.153,0.753-15.89,3.881c-3.17,2.093-6.551,3.374-10.073,4.368c-8.554,2.413-16.737,5.808-24.994,9.025
                c-10.132,3.947-20.347,7.68-31.001,9.984c-6.133,1.326-12.383,2.134-18.473,3.618c-3.453,0.841-6.84,2.295-9.987,3.975
                c-5.255,2.804-10.374,4.486-16.577,3.131c-3.43-0.75-7.433-0.044-10.927,0.917c-6.636,1.826-13.608,2.136-20.067,4.798
                c-2.232,0.92-4.482,1.7-6.46,3.096c-5.712,4.031-12.815,4.253-19.037,6.915c-0.916,0.392-2.303,0.354-3.485-0.389
                c-4.62-2.905-9.402-1.702-13.946,0.06c-2.484,0.962-4.952,1.958-7.546,2.331c-1.805,0.26-3.658,1.051-4.361,2.161
                c-2.499,3.941-6.462,3.559-9.552,2.66c-2.688-0.782-5.444-0.509-8.149-1.548c-3.703-1.423-9.02,2.231-10.335,6.833
                c-1.453,5.083-5.053,6.52-9.591,6.969c-8.877,0.88-17.661,0.11-26.517-0.831c-6.813-0.724-13.788-0.401-19.557,4.751
                c-2.343,2.093-5.937,1.562-8.939,1.984c-9.499,1.337-19.011,2.668-28.514,3.879c-11.062,1.409-21.972,3.582-32.948,5.365
                c-6,0.975-11.973,1.855-18.02,2.393c-2.957,0.263-5.917,0.788-8.503,2.462"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M474.5-0.111c3.132,1.803,6.363,3.487,8.98,6.021c2.546,2.464,5.551,3.703,9.02,3.978c2.956,0.235,6.231,1.111,7.846,3.125
                c4.523,5.641,11.033,6.687,17.14,8.916c7.943,2.898,16.001,4.44,24.518,4.042c6.028-0.282,7.85,1.75,8.494,7.919
                c0.303,2.907,1.237,4.893,4.458,6.118c8.182,3.112,15.97,6.988,23.177,12.199c3.38,2.443,8.174,2.929,12.927,4.499
                c-0.516,3.947,0.277,7.723,3.86,10.779c2.483,2.118,5.252,2.426,8.086,2.87c4.431,0.694,7.898,2.954,11.012,6.017
                c1.596,1.569,3.25,3.09,4.918,4.589c4.925,4.423,10.534,7.003,17.044,8.05c1.29,0.208,2.7,0.202,4.055,0.799
                c2.676,1.18,4.014,2.427,3.398,5.063c-1.015,4.346,0.258,7.737,2.376,11.62c3.147,5.771,3.341,9.259-1.2,14.491
                c-3.086,3.555-5.917,7.003-10.587,8.475c-0.754,0.238-1.545,0.853-2.125,1.871c-1.342,2.352-3.232,4.39-5.322,6.105
                c-1.089,2.098,0.368,4.716-1.457,7.53c-2.957,4.562-7.137,7.671-11.732,9.661c-2.573,1.115-5.063,2.439-7.31,3.882
                c-5.129,3.295-10.505,5.274-16.577,5.369c-1.132,0.018-2.012,0.209-3.047,0.945c-4.264,3.034-9.29,5.113-13.281,8.283
                c-4.652,3.695-10.465,5.804-14.241,10.731c-2.218,2.895-5.803,4.373-8.979,5.957c-9.565,4.773-18.865,9.972-27.978,15.554
                c-6.576,4.027-14.136,6.078-20.918,9.648c-6.528,3.437-13.417,4.954-20.56,5.864c-1.106,0.142-2.057,0.085-3.033,0.989
                c-5.517,5.111-12.557,7.2-19.46,9.548c-4.817,1.639-9.464,3.675-14.013,5.971c-10.276,5.188-21.115,8.653-32.484,10.556
                c-6.326,1.059-12.585,2.481-19.001,3.019c-1.696,0.142-3.338,0.946-5.004,1.446"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M898,202.389c-13.186,3.007-26.033,7.213-39.026,10.908c-6.997,1.99-13.688,4.431-20.003,8.041
                c-2.396,1.37-4.984,2.082-7.994,1.124c-5.523-1.758-11.184-0.264-15.855,2.161c-5.379,2.792-10.932,4.521-16.6,6.336
                c-11.241,3.599-22.397,7.479-33.509,11.465c-10.24,3.674-20.904,5.497-31.532,7.352c-5.928,1.035-11.709,2.376-16.922,5.221
                c-3.723,2.031-7.522,3.082-11.555,2.829c-11.281-0.708-22.145,1.67-32.988,4.134c-3.104,0.705-6.354,1.77-8.936,3.546
                c-3.74,2.574-7.918,3.266-12.046,4.498c-1.832,0.547-3.854,0.452-5.649,1.721c-0.82,0.579-2.813,1.39-4.398,0.182
                c-4.346-3.313-8.973-1.562-13.478-0.48c-3.378,0.811-6.674,1.968-10.01,2.96c-1.567,0.466-2.989,1.065-4.023,2.485
                c-1.476,2.026-3.764,2.218-5.971,1.976c-3.019-0.331-6.032-0.833-8.999-1.484c-5.697-1.251-8.813-0.226-11.428,5.065
                c-2.889,5.846-7.414,7.399-13.573,7.606c-8.4,0.282-16.675-0.411-25.014-1.023c-6.293-0.462-12.74-0.226-18.015,4.351
                c-1.506,1.306-3.656,1.214-5.486,1.452c-8.979,1.167-18.021,1.862-26.981,3.14c-7.861,1.121-15.758,1.892-23.562,3.712
                c-4.978,1.162-10.382,0.978-15.435,2.274c-7.076,1.814-14.384,1.669-21.522,2.897c-0.884,0.152-1.458,0.453-1.991,1.053"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M578,27.889c3.985,4.28,9.618,5.754,14.436,8.609c5.742,3.402,12.204,4.879,18.113,7.792c4.064,2.003,6.847,4.685,7.366,9.109
                c0.323,2.748,2.024,3.763,4.09,3.919c8.9,0.674,15.81,5.107,21.994,11.072c0.509,0.491,1.08,0.942,1.488,1.508
                c2.84,3.943,7.175,4.406,11.45,5.697c5.588,1.687,11.086,4.246,15.51,8.35c0.799,0.741,1.294,2.009,1.114,3.451
                c-0.245,1.969-0.076,3.992-0.058,5.992c0.016,1.654,0.069,3.061,1.398,4.585c2.631,3.018,2.888,7.084,3.633,10.908
                c1.172,6.016-2.322,10.429-5.109,14.961c-2.723,4.427-6.689,7.72-11.414,10.07c-3.496,1.74-5.926,3.688-6.839,8.412
                c-1.161,6.013-6.295,10.73-11.307,13.849c-8.766,5.453-17.953,10.442-27.882,13.665c-2.559,0.831-5.315,1.063-7.986,1.538
                c-0.485,0.086-1.172-0.201-1.472,0.044c-4.52,3.701-10.421,5.036-14.984,8.523c-10.953,8.371-22.145,16.205-35.007,21.53
                c-8.405,3.479-15.62,9.28-23.994,13.007c-12.012,5.345-23.999,10.555-37.031,12.961c-3.531,0.652-6.928,1.852-10.039,3.904
                c-6.088,4.016-12.879,6.412-19.966,8.067c-4.771,1.114-9.049,3.571-13.472,5.55c-10.123,4.531-20.765,7.054-31.498,9.577
                c-7.002,1.646-14.218,2.397-21.051,4.806c-0.89,0.313-1.989-0.019-2.984,0.054c-3.359,0.245-6.229-0.083-7.62-3.969
                c-0.411-1.147-1.024-2.574-1.919-3.505c-4.653-4.844-5.604-10.694-4.958-17.037c0.29-2.838-2.005-5.981,0.996-8.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M380,331.389c-1.43-4.242-2.468-8.732-7.026-10.768c0.17-2.1,0.186-3.871,0.879-5.785c1.197-3.308,1.64-6.926,2.088-10.454
                c0.276-2.171,0.345-4.457-0.934-6.497c-2.495-3.979-3.699-7.953-3.312-13.019c0.356-4.667-0.578-9.779-1.851-14.434
                c-1.232-4.506-1.169-9.106-1.57-13.567c-0.489-5.457-1.46-10.946-1.267-16.475c0.09-2.564-1.519-4.86-1.024-7.503
                c1.519-8.115-0.396-16.016-1.349-24.013c-0.485-4.07-0.329-8.296,0.273-12.497c0.997-6.952,0.521-13.968,0.265-20.993
                c-0.177-4.874-1.581-9.601-1.213-14.497c0.099-1.313,0.032-2.83-0.542-3.955c-0.714-1.397-0.814-3.481-0.312-3.921
                c3.934-3.441,0.395-5.993-1.217-8.033c-4.728-5.982-11.239-9.618-18.392-12.08c-8.376-2.883-16.891-5.375-25-9.001
                c-3.815-1.707-7.715-3.231-11.488-5.026c-13.058-6.214-25.992-12.698-39.069-18.854c-13.43-6.321-26.63-13.083-39.921-19.666
                c-12.756-6.319-25.23-13.218-38.063-19.371c-8.562-4.105-17.356-7.561-26.905-9.364c-6.164-1.164-12.245-3.004-18.55-3.743
                c-11.143-1.305-22.266-1.59-33.508-1.124c-16.01,0.664-31.706,3.466-47.485,5.702c-13.771,1.952-27.399,0.817-40.999-1.132
                c-6.393-0.917-12.677-2.595-19.01-3.929"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M110.5-1.111c1.6-1.867,3.723-1.977,5.999-2.035c10.898-0.278,21.65,1.631,32.495,2.158c1.63,0.079,3.342,0.182,5.006,0.372
                c4.161,0.475,6.681,2.123,8.948,6.531c1.246,2.423,3.38,3.768,5.583,4.359c5.07,1.361,10.287,2.209,15.473,3.09
                c6.483,1.1,13,1.997,19.491,3.058c0.796,0.13,1.56,0.354,2.08,1.43c1.242,2.566,4.266,3.188,6.438,3.325
                c3.697,0.233,7.386,0.89,10.953,1.434c7.515,1.148,15.512-0.245,22.534,3.777c4.936-0.078,9.673,1.369,14.48,2.127
                c8.335,1.315,16.868,1.263,25.051,3.772c2.335,0.716,4.984,0.581,7.492,0.943c7.321,1.061,14.649,2.333,21.993,2.975
                c6.198,0.542,12.351,1.457,18.459,2.35c9.693,1.417,19.465,2.28,29.078,4.614c6.725,1.633,13.941,1.341,20.949,1.68
                c5.605,0.271,11.113,0.853,16.511,2.495c2.241,0.682,4.885-0.912,6.987,1.044c10.97-0.048,20.262,5.938,30.514,8.443
                c4.789,1.17,8.623,4.121,12.965,6.102c5.442,2.483,11.086,3.715,16.984,4.678c4.194,0.685,8.427,0.202,12.544,1.247
                c2.825,0.717,5.381-0.672,7.992-1.47"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M569.5,27.889c2.803-0.679,5.727,0.681,8.594-0.54c0.656-4.137-1.803-6.29-5.091-7.967c-4.455-2.272-9.219-3.662-14.005-4.987
                c-5.729-1.586-11.721-1.912-17.451-3.213c-5.446-1.237-11.474-2.034-15.581-6.764c-0.061-0.07-0.828,0.009-0.936-0.535
                c-1.015-5.155-5.545-4.838-9.045-5.401c-13.244-2.131-26.645-1.876-39.979-1.411C471.965-2.788,468-3.149,464-3.147
                c-99,0.045-198,0.035-297,0.04c-2.67,0-5.474-0.449-7.5,1.996"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M3.5,278.889c-1.508,1.62-0.999,3.633-0.979,5.5c0.304,29.166,0.245,58.339,0.229,87.5c-0.016,30.662-0.042,61.333-0.214,92
                c-0.165,29.332-0.024,58.666-0.046,88c-0.004,5.334,0.7,10.622,0.132,16.013C2.065,573.176,2.5,578.555,2.5,583.889
                c0,5.5,0,11,0,16.5c0,5.334,0,10.666,0,16s0,10.666,0,16s0,10.666,0,16c0,5.5,0,11,0,16.5c0,5.334,0,10.666,0,16s0,10.666,0,16
                s0,10.666,0,16c0,5.5,0,11,0,16.5c0,5.334,0,10.666,0,16s0,10.666,0,16c0,52.834-0.003,105.666,0.011,158.5
                c0,1.924-0.311,3.889,0.578,6c1.575,0,3.243,0,4.911,0c35.333,0,70.667-0.024,106,0.026c5.354,0.008,10.671-0.435,16-0.776"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M615.5,789.389c-2.264,1.719-2.68,5.242-6.468,5.742c-1.33,0.176-2.292,3.141-3.495,4.941c-4.094-1.322-4.113-5.263-5.459-8.219
                c-0.361-0.794-0.459-1.665-1.009-2.51c-1.048-1.609-3.352-1.34-4.176-3.412c-0.611-1.537-0.491-3.637-2.394-4.544
                c-0.33,1.085-1.69,1.647-1.501,3.001c0.357,2.567-1.282,4.92-3.042,5.283c-3.034,0.627-3.402,2.717-3.871,4.736
                c-0.439,1.896-1.639,2.696-3.085,3.599c-1.446-0.902-2.553-1.755-3.116-3.583c-0.542-1.76-3.305-2.363-4.293-0.971
                c-2.061,2.902-5.636,2.885-8.136,4.879c-1.689,1.347-4.096,0.885-5.926,0.441c-3.913-0.949-7.813-2.195-11.04-4.871
                c-1.767-1.466-4.265-1.503-5.448,0.018c-0.994,1.277-1.215,3.131-3.542,3.019c-2.395-0.115-4.43-1.179-6.452-2.145
                c-0.931-0.444-2.092-1.296-2.1-2.905c-0.008-1.754-1.196-2.734-2.945-3.005c-1.97-0.305-2.735,1.724-2.838,2.526
                c-0.31,2.42-2.259,4.248-3.581,3.758c-2.691-0.999-6.111-0.46-8.098-3.268c-1.094-1.547-0.985-4.334-3.985-4.015
                c-2.723,0.29-3.242,2.804-4.447,4.54c-0.719,1.035-1.352,1.997-2.789,2.612c-5.465-2.669-1.733-10.269-6.87-13.158
                c-3.807,1.669-3.902,6.923-8.025,8.812c-3.99-3.82-8.745-6.086-13.826-7.92c-1.759-0.635-2.749-2.621-3.159-4.861
                c-0.408-2.23-0.792-4.854-3.356-6.152c-3.434,0.75-3.091,4.035-3.878,6.186c-0.871,2.381-1.481,4.949-2.081,7.462
                c-0.202,0.847-0.589,1.363-1.047,1.999c-1.506,2.092-2.652,2.215-3.98-0.04c-1.677-2.847-3.381-5.478-6.487-7.08
                c-0.899-0.464-1.11-2.372-1.703-3.3c-1.779-2.787-1.804-6.674-5.787-8.257c-2.253-0.896-0.235-4.496-2.016-6.384
                c-0.333-0.353-0.075-1.29-0.042-1.955c0.06-1.183,0.038-2.338-1.507-2.486c-1.416-0.135-2.545,0.812-2.403,1.975
                c0.25,2.043-0.874,3.662-1.218,5.488c-0.642,3.409-1.246,7.041-5.34,8.621c-1.455,0.561-2.412,2.253-3.071,3.889
                c-0.498,1.234-1.167,2.453-1.995,3.49c-1.57,1.968-3.366,2.29-4.912,0.473c-2.79-3.279-5.781-3.941-9.589-2.008
                c-0.795,0.403-2.379,0.488-2.904-0.012c-3.349-3.191-8.597-4.167-10.549-8.937c-0.664-1.622-0.989-3.431-3.149-4.03
                c-1.078,0.723-1.771,1.668-2.533,2.938c-1.3,2.166-0.948,5.526-4.785,6.335c-1.699,0.358-2.105,3.497-2.074,5.765
                c0.024,1.786,0.7,4.247-1.507,4.893c-3.12,0.911-2.198-2.645-3.423-3.936c-0.389-0.409,0.266-1.437-0.543-1.96
                c-4.878-3.159-3.96-8.418-4.386-13.008c-0.293-3.161-0.782-6.138-1.906-9.274c-2.727,1.042-2.783,3.357-3.148,5.297
                c-0.303,1.609-0.16,3.329-0.033,4.986c0.114,1.49-0.194,2.854-1.051,3.986c-3.047,4.027-5.221,8.543-6.978,13.346
                c-3.209-1.549-3.029-3.937-3.018-6.33c0.027-5.487-0.542-7.836-2.463-10.967c-0.992-0.311-1.412,0.471-2.032,0.951
                c-2.145,1.664-4.166,1.34-4.338-0.996c-0.144-1.946-1.416-3.521-1.172-5.491c0.252-2.036-0.558-3.601-2.309-4.554
                c-1.782,0.111-2.325,0.959-2.757,2.532c-0.666,2.425-1.976,4.667-2.893,7.032c-0.775,2-2.001,1.889-3.513,1.037
                c-0.672-4.493-0.645-4.543-6.022-8.523c-2.146-1.589-4.574-1.298-6.991-0.542c-0.52,0.163-1.14,0.126-1.494-0.479"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M312,265.389c-1.488-3.779-0.655-8.327-3.917-11.583c-0.371-0.37-0.323-1.87,0.362-2.951c2.504-3.951,2.774-8.434,2.551-12.965
                c-0.428-8.697,0.251-17.372,1.165-25.983c0.364-3.438,0.79-6.588-0.048-10.044c-0.806-3.324,0.154-6.753,1.393-9.97
                c0.87-2.261,0.807-4.632-0.565-6.458c-3.756-5-4.679-10.671-3.854-16.532c0.974-6.914-2.724-12.907-3.225-19.502
                c-0.051-0.666-0.79-2.416-2.474-2.528c-3.649,3.337-4.32,8.036-5.366,12.523c-1.754,7.526,0.194,14.721,1.842,22.025
                c0.787,3.489,0.135,7.303,0.135,11.295c-1.345,0.707-3.19-0.513-5,1.123c0,1.361-0.41,3.324,0.081,5.028
                c1.241,4.314,0.72,8.677,0.938,13.022c0.419,8.352,1.239,16.68,1.82,25.012c0.732,10.494,4.271,19.989,8.184,29.48
                c0.166,0.402,0.645,0.675,0.973,1.003c-1.28,1.602-1.936,4.456-4.988,2.505c-1.339,0.667-0.89,1.914-1.017,3
                c-1.174,10.086,2.124,19.47,4.5,29.003c1.189,4.771,2.881,9.373,4.378,14.04c0.325,1.014,0.63,2.158,1.188,2.917
                c5.165,7.031,5.074,15.562,6.983,23.532c1.402,5.855,2.273,11.938,5.961,17.009"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M279,511.389c7.017,5.017,14.989,8.467,22.017,13.477c2.634,1.877,4.886,4.04,7.191,6.312c4.677,4.609,8.727,10.081,14.775,13.243
                c2.566,1.341,5.311,2.115,8.03,2.924c4.826,1.433,8.386,4.229,11.026,8.521c3.12,5.073,7.488,8.271,13.387,10.244
                c5.354,1.791,10.108,5.399,15.574,7.28c12.543,4.315,24.139,10.693,36.01,16.479c3.617,1.763,6.652,4.36,9.979,6.536
                c1.603,1.048,2.548,2.793,2.93,4.502c0.766,3.421,2.596,5.822,5.58,7.483c12.413,6.912,23.426,15.864,35.003,23.996
                c0.983,0.69,2.228,1.146,2.96,2.034c4.498,5.451,10.579,8.168,17.003,10.561c3.033,1.13,5.942,2.939,8.481,4.974
                c4.634,3.713,9.082,7.682,14.089,10.878c2.716,1.732,4.072,4.614,6.463,6.557"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1260-2.111c0.152,1.027-0.115,1.839-0.992,2.51c-17.142,13.115-32.975,27.858-50.481,40.526c-3.457,2.501-5.271,6.47-7.487,9.988
                c-5.289,8.392-12.842,13.689-22.023,17.017c-2.386,0.865-4.648,2.149-6.511,3.963c-1.688,1.644-3.382,3.288-4.995,5.005
                c-2.389,2.544-4.848,4.703-8.48,5.608c-3.021,0.753-5.59,2.864-8.016,4.898c-1.08,0.905-1.94,2.072-3.026,2.968
                c-4.071,3.358-5.8,3.334-9.987,0.016c-4.065-3.222-5.261-3.215-9.51-0.515c-9.67,6.146-18.236,13.704-26.931,21.085
                c-1.304,1.106-2.647,2.259-4.093,2.849c-8.463,3.453-15.906,8.602-23.484,13.555c-1.612,1.054-3.373,1.714-5.486,1.578
                c-5.331-0.342-9.639,1.622-13.035,5.917c-4.237,5.357-10.428,7.85-16.445,10.565c-2.311,1.043-4.798,1.903-6.516,3.966"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M279,120.389c2.333,1.833,4.65,3.688,7.005,5.494c2.001,1.535,2.633,3.52,2.527,6.008c-0.36,8.494-0.261,17.021-0.209,25.497
                c0.028,4.678,0.837,9.685-0.563,13.922c-2.275,6.884-1.452,13.713-1.741,20.581c-0.072,1.717-0.267,3.454,0.909,5.052
                c0.34,0.463,1.059,0.965,1.025,1.443c-0.514,7.231,2.794,13.855,3.25,20.99c0.264,4.129,1.639,8.308,2.335,12.507
                c0.443,2.671,0.63,5.346,0.422,8.003c-0.471,5.987,0.459,11.815,2.121,17.479c1.082,3.686,1.226,7.209,1.05,11.03
                c-0.412,8.981-0.841,18.068,0.095,26.97c0.735,7.002,2.672,13.921,3.342,21.018c0.209,2.218,1.619,4.17,2.94,6
                c1.631,2.259,2.705,4.721,3.021,7.784c-6.64,2.841-12.271,7.535-18.529,11.222"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M81,73.889c0.081,3.028,0.221,5.984,0.857,9.03c1.22,5.837-0.804,11.16-4.892,15.438c-5.256,5.5-3.995,12.048-3.458,18.532
                c0.111,1.338,0.572,2.69,0.45,3.997c-0.593,6.396,0.862,12.672,1.277,18.988c0.323,4.911,2.746,9.688,4.27,14.514
                c1.476,4.674,3.006,9.331,4.476,14.007c0.143,0.455,0.059,0.998,0.013,1.494c-0.708,7.547,1.492,14.968,0.988,22.5
                c-0.217,3.242,1.331,6.207,1.123,9.508c-0.187,2.971-0.883,6.142,0.864,9.012c0.222,0.365,0.012,0.982,0.033,1.481
                c0.497,11.995,1.359,23.974,1.036,36.001c-0.248,9.214,0.658,18.378,1.943,27.501c0.138,0.981-0.02,2,0.024,2.998
                c0.708,16.022-1.633,31.981-0.975,47.999c0.062,1.501-0.401,2.742-1.031,4.001"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M52.5,81.889c-1.423,4.403-4.565,7.887-6.427,12.033c-0.656,1.461-1.318,2.848-0.254,5.054c2.883,5.974,2.962,16.505,1.195,23.917
                c-0.113,0.473,0.014,0.999-0.019,1.496c-1.368,20.65-0.979,41.33-0.945,62c0.005,3.163,1.473,6.259,0.937,9.498
                c-1.084,6.549-1.042,13.285-3.896,19.544c-1.161,2.547-1.109,5.581-1.097,8.458c0.019,4.333-0.02,8.667,0.011,13
                c0.042,5.917-0.682,11.738-1.976,17.507c-0.214,0.952-0.449,2.436,0.051,2.908c1.345,1.271,0.81,2.706,0.908,4.086
                c0.263,3.673-0.499,7.384,0.918,11.035c0.562,1.449,0.563,3.482-0.001,4.928c-1.362,3.487-0.886,7.026-0.854,10.536
                c0.031,3.368-0.682,6.482-2.053,9.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1184.5,700.389c-1.94-1.06-4.331-1.464-4.604-4.49c-0.118-1.314-0.903-3.002-3.327-3.069c-2.671,1.825-0.219,6.715-4.242,7.955
                c-1.518-1.925-3.368-3.69-6.321-2.878c-2.015,0.555-2.472-1.75-4.241-2.104c-1.449,0.695-3.067,1.472-4.579,2.196
                c-1.245,2.197,0.166,5.327-3.104,7.042c-1.294,0.678-0.51,3.173-0.572,4.848c-0.072,1.916,0.414,4.035-1.936,5.062
                c-0.977-0.861-1.424-1.756-1.675-3.043c-0.367-1.885-0.099-4.406-2.89-5.053c-0.39-0.091-1.018-0.726-0.957-0.953
                c1.178-4.384-4.268-6.061-3.959-9.93c-2.148-0.671-3.212,0.979-3.621,1.431c-1.305,0.651-1.609,0.464-1.976-0.011
                c-1.823-2.362-1.366-5.412-2.376-8.05c-0.401-1.05-1.049-1.663-1.693-2.579c-1.601,0.933-2.075,2.329-2.535,4.097
                c-0.6,2.307-0.438,5.187-3.85,6.172c-1.525,0.44-0.482,3.813-1.145,4.157c-1.73,0.897,0.28,3.553-2.388,3.796
                c-2.287,0.208-2.933-1.546-3.36-2.653c-0.566-1.471-1.035-2.389-2.561-2.484c-3.189,1.474,0.028,5.615-3.097,7.035
                c-2.513-1.725-1.562-4.692-2.417-7.021c-0.81-2.205-4.412-4.768-6.555-3.924c-0.917,0.361-1.217,1.4-1.607,2.418
                c-1.096,2.855-0.629,6.365-3.42,8.54c-1.669-0.134-1.155-1.518-1.568-2.474c-0.745-1.725-2.176-2.107-3.938-1.057
                c-1.471,0.879-2.63,2.332-4.4,2.602c-2.307-0.807-2.195-2.901-2.53-4.59c-0.315-1.596,0.36-3.46-1.614-4.52
                c-1.051,0.24-1.53,0.886-1.45,2.031c0.197,2.833,0.095,5.532-2.466,7.533c-1.099,0.858-0.422,2.297-0.506,3.469
                c-0.123,1.718,0.355,3.54-1.125,5.043c-1.887-0.053-2.715-1.757-2.784-3.051c-0.177-3.276-1.767-5.67-4.157-7.431
                c-2.373-1.749-2.798-4.772-4.484-6.831c-1.939,1.109-1.422,2.537-1.455,3.768c-0.089,3.296,0.502,6.676-1.99,9.521
                c-1.784,2.037-0.854,4.626-1.012,6.98c-0.121,1.794,0.484,3.781-1.662,5.09c-2.413-2.217-0.674-5.677-2.317-8.112
                c-0.231-0.344,0.153-1.31-0.06-1.43c-3.628-2.031-4.412-6.226-6.643-9.638c-4.496,3.292-2.16,9.671-7.175,12.439
                c-1.284-3.173-5.413-3.772-6.446-6.432c-1.549-3.989-5.996-2.071-7.434-5.345c-3.857,2.404-3.043,7.557-6.894,9.664
                c-2.448-2.693-0.909-6.746-3.382-9.238c-0.334-0.166-0.846-0.041-0.973,0.506c-1.265,5.504-6.941,6.758-10.027,10.494
                c-2.85-0.183-2.689,2.643-3.953,4.117c-3.537-0.68-2.354-4.356-3.906-6.164c-3.645-0.025-3.698,4.389-6.738,5.068
                c-1.347-0.021-1.458-2.021-3.439-2.054c-2.356,3.951-3.897,9.157-9.463,10.532c-3.114-0.886-2.438-5.562-6.459-6.057
                c-1.818,3.446-6.491,4.305-8.148,8.515c-0.69,1.757-2.676,1.628-3.82-0.009c-0.338-0.482-0.926-0.908-1.037-1.456
                c-0.311-1.519-1.432-1.8-2.512-1.425c-0.918,0.319-1.668,1.203-1.525,2.432c0.338,2.911-1.627,5.515-1.076,8.514
                c0.168,0.908-0.59,1.986-0.891,2.885c-2.312,0.783-3.35-0.984-3.947-2.434c-1.113-2.696-2.242-5.113-5.057-6.52
                c-1.657-0.828-1.355-2.977-1.912-4.488c-0.458-1.244-0.238-2.598-2.115-2.455c-1.551,0.117-1.96,1.115-2.023,2.497
                c-0.039,0.839,0.462,1.834-0.479,2.499c-3.457,2.443-3.815,6.586-5.345,10.398c-3.776-2.219-1.684-7.055-3.823-8.672
                c-3.225-2.438-1.952-6.355-4.27-8.771c-2.672,2.02-2.672,2.02-3.061,6.045c-1.614,1.825-3.744,2.103-6.051,1.999
                c-2.547-6.77-1.191-14.106-2.669-21.271c-2.618,0.881-3.003,2.58-3.374,4.758c-0.666,3.918-2.129,4.346-5.383,1.984
                c-0.52-0.377-0.957-0.503-1.787-0.439c-0.971,1.963-1.795,4.08-1.738,6.471c0.016,0.666,0.253,1.768-0.035,1.935
                c-4.141,2.392-1.904,7.729-5.275,10.899c-1.718-3.117-4.486-5.24-6.586-7.914c-0.828-1.053-1.498-1.727-2.578-1.352
                c-0.918,0.318-1.578,1.202-1.553,2.432s0.501,3.33-0.973,3.344c-2.273,0.022-2.822,2.891-4.471,2.529
                c-2.462-0.538-6.085-1.007-6.348-3.89c-0.482-5.301-3.894-4.466-7.248-4.464c-1.119,1.296-1.773,3.219-3.454,3.927
                c-2.374,1.001-2.074,3.326-3.004,5.04c-0.373,0.687-1.015,1.2-1.976,1.027c-0.895-0.16-1.403-0.608-1.498-1.514l-3.5-6.5
                c-0.613-1.246-1.617-2.766-2.93-1.894c-1.714,1.14-4.258,1.282-4.734,4.368c-0.36,2.335,0.248,5.317-2.359,7.012
                c-3.426-3.045-0.984-8.664-4.78-11.739c-2.634,2.013-1.765,4.974-2.522,7.31c-0.655,2.023-0.012,4.375-2.656,5.484
                c-1.033,0.432-0.404,1.619-0.508,2.46c-0.46,3.712-0.885,7.429-1.385,11.673c-3.41-2.017-3.064-4.902-3.517-7.195
                c-0.425-2.149-0.915-4.029-2.605-5.481c-1.905-1.638-1.387-3.891-1.519-5.996c-0.149-2.398,0.725-5.034-1.623-7.73
                c-1.88,3.822-3.93,7.409-6.984,8.993c-3.582,1.855-3,6.678-7.129,8.021c-3.154-3.563-2.42-8.74-5.235-12.288
                c-1.363,0.341-7.236,3.152-7.986,4.026c-0.714,0.833-1.585,1.325-2.502,0.923c-0.913-0.4-1.505-1.227-1.569-2.443
                c-0.079-1.498,0.679-3.115-0.461-4.498c-0.298-0.361-0.66-0.67-1.222-1.23c-3.568,3.34-1.499,8.896-4.755,12.527
                c-2.232-1.506-2.666-3.582-2.485-5.799c0.179-2.205-0.409-4.017-2.047-5.516c-1.554,0.545-2.322,2.01-3.589,3.104
                c-1.595-0.678-3.525-0.684-5.203-2.322c-1.881,2.547-5.77,3.281-5.753,7.123c-2.134,0.518-2.591-1.053-2.854-1.917
                c-0.807-2.632-2.366-4.035-5.086-3.952c-2.674,0.082-4.029,2.055-5.539,3.947c-2.024,2.541-3.203,5.314-3.502,8.527
                c-0.033,0.361-0.626,0.67-0.975,1.02c-0.406-0.741-1.559-0.955-1.477-2.016c0.365-4.719-1.727-9.258-1.01-14
                c0.331-2.19-0.162-4.451-2.06-5.373c-1.767-0.857-2.106-2.461-2.794-3.707c-0.59-1.068-0.279-2.355-2.141-2.475
                c-2.016-0.128-2.882,2.098-2.816,2.526c0.252,1.664-1.009,2.637-1.263,4.015c-0.331,1.797-0.834,3.74-2.968,4.604
                c-2.322-0.939-2.628-4.307-5.646-4.609c-2.993,1.095-1.781,5.684-5.748,6.752c-3.061,0.824-2.035,5.951-4.8,7.95
                c-1.49-1.038-1.896-2.157-1.804-3.685c0.15-2.491-0.08-5.006,0.08-7.496c0.126-1.964-0.125-4.267-1.164-5.386
                c-2.997-3.229-3.669-7.249-4.825-11.131c-0.212-0.71-0.735-0.973-1.123-1.638c-2.823,1.593-2.103,5.33-4.03,7.035
                c-2.463,2.181-1.715,4.645-1.898,7.117c-0.092,1.232,0.256,2.428-0.956,3.545c-2.902,2.676-4.487,6.193-5.333,10.753
                c-1.965-3.542-2.702-6.554-2.734-9.798c-0.014-1.377,0.555-2.907-0.963-4.016c-2.854-2.082-3.242-5.377-4.036-8.479
                c-0.248-0.969-0.636-1.972-1.976-2.02c-1.121-0.039-1.645,0.414-2.041,1.499c-0.754,2.068-1.279,4.21-3.922,5.124
                c-1.646,0.568-1.772,2.869-1.924,4.402c-0.195,1.976-1.182,3.029-2.123,3.996c-1.847,0.016-2.349-1.359-2.398-2.015
                c-0.43-5.718-5.352-6.733-9.137-9.368c-4.784,4.211-2.316,11.451-7.199,16.092c-1.829-4.949-6.535-7.979-5.349-13.736
                c0.319-1.547-0.492-3.328-0.796-5.064c-1.135,0.857-6.167,8.046-7.111,10.084c-0.666,0-1.663,0.277-1.939-0.051
                c-0.88-1.047-2.149-1.377-3.001-0.852c-0.93,0.572-1.843,1.852-1.964,2.913c-0.238,2.09-0.926,3.741-2.625,5.011
                c-0.231-0.867-1.458-0.743-1.554-2.016c-0.152-2.01,0.147-4.577-2.916-5.006c0.237-3.697-4.69-5.083-4.07-9.012
                c0.039-0.244-0.624-1.114-1.959-0.912c-0.58,0.133-1.024,0.779-0.992,1.924c0.035,1.217,0.449,2.717-1.478,2.999
                c-1.168,0.171-1.446,0.896-1.55,1.996c-0.082,0.857-0.374,2.03-0.989,2.447c-4.347,2.939-5.172,8.138-7.917,12.088
                c-0.719,1.035-1.342,2.004-2.505,2.502c-0.942-0.831-1.331-1.763-1.64-3.007c-0.649-2.616-0.685-5.767-3.898-7.025
                c-0.954-5.418-7.401-8.63-6.069-15.015c0.045-0.216-0.625-1.111-1.96-0.909c-0.58,0.133-1.024,0.779-0.992,1.924
                c0.035,1.217,0.333,2.404-1.452,3.071c-2.812,1.051-3.819,3.707-3.971,6.432c-0.254,4.547-3.222,7.581-5.556,10.997"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M4,38.389c-1.855,1.26-1.495,3.195-1.495,5c-0.006,68.5-0.14,137,0.184,205.499c0.036,7.673,0.301,15.354-0.271,22.995
                c-0.453,6.055,4.3,8.207,7.663,11.422c0.393,0.376,1.875,0.046,2.955-0.306c3.214-1.049,6.47-1.241,8.98,1.877
                c2.008,2.495,6.544,2.278,7.021,6.509c0.06,0.531,0.658,0.486,0.959,0.532c5.922,0.901,7.673,6.637,11.492,9.986
                c2.788,2.445,4.991,5.249,6.125,8.952c0.482,1.577,1.673,2.832,3.812,3.716c8.302,3.429,15.273,9.192,22.502,14.42
                c1.516,1.097,3.784,1.318,4.573,3.398"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M303,508.389c-0.048-2.867,0.121-5.711-0.958-8.516c-1.664-4.327-1.363-9.085-2.863-13.545c-0.797-2.371-1.622-4.096-3.657-5.473
                c-2.757-1.866-5.19-4.261-8.068-5.886c-2.986-1.686-3.452-3.968-3.558-7.077c-0.143-4.163,0.039-8.296-0.603-12.548
                c-0.987-6.541-4.148-12.054-6.505-17.87c-2.011-4.961-3.866-9.556-3.984-15.082c-0.096-4.467-0.957-8.968-0.806-13.504
                c0.017-0.5,0.21-1.155-0.029-1.477c-2.881-3.861-4.19-8.765-7.878-12.438c-1.458,2.06-1.08,4.033-1.081,5.914
                c-0.013,25.333-0.011,50.667-0.004,76c0.001,2.062,0.164,4.139-1.005,6"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1033,167.389c-1.005-1.167-1.039-1.98-0.102-3.56c4.454-7.503,1.757-15.25-6.426-18.879c-1.5-0.666-2.833-1.708-5.131-1.559
                c5.346-7.247,11.642-12.543,17.314-18.349c3.716-3.803,7.179-7.818,10.874-11.623c0.743-0.765,1.296-1.643,1.421-2.536
                c0.95-6.835,6.216-10.579,10.551-14.991c4.049-4.12,8.297-8.035,12.037-12.47c2.654-3.148,6.953-4.782,8.459-9.033
                c0.421-1.186,1.342-0.281,2.008-0.479c4.349-1.29,8.108-3.104,9.591-7.992c0.429-1.413,0.729-2.975,0.281-3.975
                c-0.977-2.177-0.314-3.954-0.086-6.077c0.392-3.645-1.219-7.029-4.783-8.993c-0.397-0.218-0.676-0.651-1.149-1.125
                c0.256-4.233,0.913-8.772-3.365-11.851c-0.78-0.561-0.6-1.371-0.461-2.001c0.995-4.527-1.173-7.297-5.049-8.97
                c-2.108-0.91-2.891-2.062-3.111-4.526c-0.547-6.11-7.542-9.784-14.361-6.981c-1.712,0.704-3.274,1.774-4.687,2.556
                c-2.436-0.642-2.694-3.08-4.335-4.07c-3.645-2.2-7.582-2.921-11.471-0.978c-12.139,6.066-25.045,10.695-36,18.989
                c-1.436,1.087-2.951,0.96-4.52,0.972c-12.826,0.094-22.646,5.245-28.495,17.004c-7.332,14.738-17.919,27.192-28.019,39.986
                c-0.726,0.918-1.809,1.572-2.459,2.53c-2.568,3.784-6.632,4.268-10.53,4.398c-7.892,0.264-15.665,0.356-23.058,3.959
                c-3.078,1.5-6.727-0.345-9.898-1.06c-4.992-1.125-9.955-2.298-15.045-2.768c-2.086-0.193-3.748-1.146-5.496-2.049"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M568,727.389c0.131-3.645-0.368-7.012-3.478-9.527c-1.552-1.255-0.43-4.262-3.463-5.094c-1.019,1.995-2.235,3.904-4.444,5.303
                c-1.735,1.098-1.521,4.271-3.551,5.898c-0.817,0.654-1.478,1.996-1.085,3.426c0.408,1.488-0.251,2.639-1.521,3.523
                c-0.827-0.16-1.418-0.605-1.449-1.529c-0.039-1.166-0.172-2.361,0.021-3.495c0.637-3.745-0.549-6.628-3.498-9.044
                c-2.326-1.906-4.454-4.101-4.54-7.461c-0.023-0.924-0.623-1.369-1.849-1.606c-1.97,1.028-0.936,4.855-4.138,5.154
                c-2.023,0.188-2.468,3.402-5.004,2.952c-0.826,4.389-6.379,5.486-6.976,10.004c-0.137,1.041-1.103,1.271-1.568,2.121
                c-1.877-1.021-2.565-2.574-2.467-4.625c0.145-3.05-3.686-3.699-3.989-6.5c-2.393,0.15-1.835-1.592-2.049-2.992
                c-0.148-0.969-0.61-1.974-1.95-2.021c-1.122-0.039-2.087,0.379-1.932,1.504c0.165,1.197-1.155,1.508-1.068,2.51
                c-3.794,0.227-3.593,3.784-4.702,5.984c-1.41,0.109-2.416-0.353-2.249-1.477c0.683-4.585-3.646-6.529-5.185-9.946
                c-0.629-1.397-2.242-0.776-2.891,0.929c-0.61,1.602-0.458,3.861-2.489,4.453c-2.803,0.818-2.479-2.732-4.435-3.559
                c-2.076-0.879-0.966-4.453-3.715-5.568c-2.49,1.355-0.269,5.592-2.861,6.018c-3.55,0.585-1.168,4.295-4.209,5.219
                c-0.699,0.05-1.282-0.621-1.283-2.053c-0.002-1.791,0.514-3.745-1.466-5.021c-0.835-0.538-0.797-1.352-0.003-1.965
                c0.55-0.425,0.744-1.23,0.454-1.471c-3.042-2.504-3.032-6.723-5.236-9.696c-2.328,0.694-1.554,3.007-2.682,4.196
                c-0.389,0.41,0.108,1.301-0.517,1.983c-2.97,3.243-6.028,6.459-6.633,11.878c-3.308-3.326-3.052-6.698-2.838-9.9
                c0.207-3.105-0.55-5.512-2.977-7.598c-0.682-0.586-1.154-0.019-1.617-0.498c-1.432,1.472-1.372,3.854-3.895,4.778
                c-1.677,0.614,0.599,4.208-2.104,5.328c-1.3-0.312-1.685-1.369-1.965-2.515c-0.393-1.607-0.519-3.332-2.481-4.05
                c-0.452-0.165-1.153-0.642-1.018-1.45c0.875-5.197-4.01-6.234-6.291-9.391c-2.859,2.815-2.646,6.672-4.714,9.609
                c-3.296-1.902-1.645-6.061-3.996-8.221c-2.06-0.332-2.915,3.757-5.843,1.066c-0.821-3.834,1.105-8.698-2.134-12.563
                c-3.831-0.141-3.253,4.505-5.829,5.558c-1.376-0.289-1.831-1.43-2.718-2.074c-0.778-0.12-0.825,0.791-0.908,1.038
                c-0.939,2.798-1.775,5.633-2.542,8.484c-0.237,0.88-0.656,1.362-1.471,1.521c-3.434-1.726,0.731-6.725-4.405-7.764
                c-1.733,4.203-6.863,6.788-8.197,12.225c-0.554,2.262-3.354,3.04-4.031,5.49c-0.469,1.694-0.383,3.703-1.977,5.111
                c-2.177-1.057-2.82-3.068-2.378-5.076c1.305-5.936-2.396-10.21-4.539-15.025c-0.442-0.994-0.965-1.68-2.023-1.999
                c-2.209-0.665-3.311-1.933-3.071-4.495c0.243-2.602-3.088-2.56-3.966-4.641c0.326-2.872-0.047-5.683-2.688-8.053
                c-4.345,3.331-0.621,9.116-3.881,12.666c-1.318,1.59-3.119-0.441-5.013,0.865c-2.598,1.791-4.846,0.379-5.324-2.841
                c-0.365-2.461-0.882-4.777-2.003-7.02c-0.5-1.001-0.937-1.493-2.057-1.492c-1.419,0-1.683,0.728-2.08,1.995
                c-0.952,3.036-2.278,5.857-4.859,8.096c-2.427,2.104-3.42,5.085-3.098,8.434c0.116,1.207,0.508,2.654-1.026,3.531
                c-2.322,0-2.545-2.435-3.885-3.494c2.303-5.131,2.143-9.742-2.545-13.548c-2.362-1.917-2.411-4.987-3.384-7.54
                c-0.574-1.506-0.859-2.623-2.62-2.436c-1.935,0.208-1.301,1.791-1.528,2.978c-0.652,3.401-1.502,6.636-4.44,9.046
                c-4.496,3.689-6.738,8.635-6.456,14.455c0.101,2.081-1.379,3.018-1.822,4.602c-4.069-1.547-3.945-1.527-3.304-5.605
                c0.372-2.363-1.181-4.367-2.009-6.47c-0.316-0.805-1.31-1.325-1.937-2.028c-2.197-2.463-4.242-4.881-2.101-8.549
                c0.459-0.787,0.641-2.508-0.424-3.424c-3.599-3.094-6.295-6.845-8.533-10.994c-0.291-0.539-1.278-0.702-2.256-1.198
                c-2.847,2.364-1.799,7.124-5.457,9.163c-3.628-0.1-5.108-2.574-5.755-5.988c-0.44-2.32-0.463-5.085-3.981-5.004
                c-3.21-4.184-9.544-4.867-11.569-10.475c-0.545-1.512-1.823-1.271-2.957-0.049c-1.736,1.871-0.794,4.558-2.385,6.594
                c-1.567,2.006-4.192,2.162-5.723,4.336c-1.39,1.972-0.288,3.805-1.25,6.014c-2.728,1.765-6.269,3.396-8.188,7.043
                c-0.529,1.005-1.928,0.779-3.234-0.172c1.729-4.684,1.173-9.131-2.699-12.785c-2.165-2.043-1.746-4.23-0.371-6.244
                c-0.534-1.615-1.604-2.697-2.662-3.18c-3.604-1.643-4.465-4.543-4.484-8.082c-0.009-1.683-0.114-1.682-2.736-4.254
                c-3.094,3.163-1.421,8.127-4.198,11.281c-3.121,0.023-3.233-2.545-4.052-4.523c-1.309-3.166-1.353-7.162-5.434-8.676
                c-1.155,1.15-1.167,2.904-2.653,3.724c-4.208-0.411-4.652-4.12-5.878-7.063c-1.282-3.08-2.59-6.107-4.678-8.884
                c-3.598,1.635-3.692,4.729-4.315,7.403c-1.12,4.806-2.097,9.647-2.993,14.5c-0.247,1.336-0.504,2.277-2.037,2.516
                c-1.676,0.262-2.053-1.124-2.443-2.049c-0.863-2.046-1.639-4.177-1.558-6.475c0.08-2.254-1.102-4.074-1.995-6.004
                c-1.193-2.577-4.599-3.831-4.139-7.513c0.135-1.079-1.993-1.437-2.809-0.894c-1.23,0.82-2.937,2.267-2.907,3.406
                c0.123,4.664-2.724,5.055-6.153,5.002c-0.563-0.009-1.146-0.104-1.5,0.502"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M127,117.889c8.014,1.653,10.403,8.93,14.367,14.533c3.619-0.882,6.837,0.157,9.597,2.021c7.772,5.248,14.635,11.636,21.505,17.98
                c2.16,1.995,0.967,4.998,1.401,7.489c1.353,7.772,2.738,15.627,2.956,23.482c0.211,7.633,0.75,15.297-0.602,23.034
                c-0.931,5.331-0.967,10.973-1.121,16.464c-0.335,11.991-0.532,24.016-0.006,35.993c0.856,19.501,0.866,38.999,0.917,58.504
                c0.013,4.833-1.027,9.514-0.268,14.538c0.966,6.393,3.045,12.346,4.368,17.883c0.719,1.079,1.036,1.165,1.388,1.09
                c6.701-1.406,10.132,2.291,11.951,8.003c1.397,4.387,3.415,8.557,5.706,12.39c3.506,5.867,3.797,12.272,4.812,18.6
                c1.25,7.797,1.911,15.681,2.868,23.515c1.137,9.309,3.356,18.52,3.158,27.98c-0.056,2.683,1.248,5.276,0.504,8"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1267,326.389c0.334,0.667,1.083,1.445,0.938,1.983c-2.948,11.007-1.219,22.401-2.796,33.537
                c-0.533,3.763-2.903,6.737-3.267,10.468c-0.065,0.677-0.958,1.48-0.888,2.513c0.572,8.367-1.281,16.618-1.092,25.001
                c0.181,7.99-0.629,16.011-1.131,23.983c-0.409,6.486-1.299,13.06-2.561,19.555c-1.053,5.424-1.186,11.083-2.738,16.45
                c-2.373,8.203-4.752,16.418-7.582,24.469c-0.998,2.84-1.427,5.727-1.771,8.555c-0.977,8.036-2.312,16.014-3.733,23.965
                c-0.484,2.703-0.224,4.664,1.619,6.521"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1260.5,331.389c1.644,3.101-0.174,6.176-0.724,8.956c-1.536,7.748-3.899,15.347-5.92,23.006
                c-1.187,4.497-2.06,8.981-2.293,13.542c-0.542,10.581-2.047,21.029-3.333,31.525c-0.638,5.197-0.855,10.636-1.045,15.96
                c-0.379,10.657,0.198,21.337-1.828,32.079c-1.704,9.038-2.824,18.312-4.481,27.41c-1.605,8.81-2.242,17.732-3.825,26.532
                c-0.348,1.937-0.086,3.991-0.041,5.99c0.047,2.148,0.011,4.256-1.51,6"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M157.5,140.389c-0.5,0.333-0.535,0.661-0.519,1.001c0.636,13.688-1.586,27.313-0.932,40.997c0.115,2.394-0.7,4.303-2.082,5.976
                c-2.554,3.093-5.353,5.982-7.946,9.045c-1.305,1.542-2.404,3.24-1.555,5.494c0.22,0.583,0.137,1.34,0.017,1.984
                c-1.119,5.996-0.296,11.466,3.406,16.583c1.201,1.66,1.38,4.232,1.554,6.425c0.355,4.485,0.527,8.996,0.534,13.496
                c0.044,28.667-0.076,57.334,0.076,86c0.053,9.907-1.023,19.684-1.937,29.511c-0.521,5.607-0.595,11.362,0.413,16.984
                c0.955,5.33,0.22,10.674,0.41,16.007c0.023,0.651-0.773,1.332-1.19,1.998"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1280.5,25.389c0,2.5,0,5,0,7.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1012,207.389c-3.562,1.386-7.124,2.521-10.982,3.113c-8.925,1.368-17.299,4.713-25.516,8.391
                c-3.677,1.645-7.307,3.395-11.013,4.972c-2.229,0.949-4.56,1.508-6.992,0.532c-3.524-1.415-7.341-1.287-10.997-2.007"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M616,291.389c-6.114,1.13-11.981,0.42-17.484-2.53c-1.293-0.693-2.417-1.678-4.016-1.47"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M415,277.389c-3.546,4.788-1.766,9.113,0.964,13.522c2.622,4.233,5.601,7.818,9.951,10.609c5.898,3.785,10.914,8.86,17.049,12.43
                c2.62,1.524,5,2.097,8.051,1.519c7.454-1.412,14.964-2.581,22.495-3.5c3.112-0.38,6.025-1.351,8.962-2.179
                c2.994-0.844,6.058-1.431,9.021-1.276c4.525,0.236,8.996-1.518,13.52-0.171c0.567,0.169,1.648-0.066,1.934-0.49
                c2.001-2.972,5.199-2.308,8.007-3.123c4.389-1.274,8.803,0.672,13.046-0.842c4.718,2.615,9.592,0.552,13.367-1.274
                c6.334-3.065,12.717-3.171,19.12-2.583c8.712,0.799,17.129,0.113,25.521-2.113c2.361-0.626,10.309-4.112,12.992-6.029"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M28,70.889c-1.662,6.229-1.762,12.594-2.163,18.99c-0.521,8.333-0.792,16.695-1.605,25.033
                c-0.563,5.771-1.047,11.632-1.292,17.475c-0.319,7.604-1.096,15.158-3.39,22.518c-2.298,7.372-4.463,14.857-5.38,22.505
                c-0.532,4.442-0.625,8.997-1.178,13.479c-0.926,7.509-1.447,15.059-2.87,22.524c-0.586,3.078-0.627,6.317-1.036,9.488
                c-0.986,7.669-1.595,15.381-3.039,22.998c-0.468,2.465,0.549,5.16-1.047,7.491"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1218.5-2.111c-0.186,0.741-0.103,1.294-0.891,2.105c-5.789,5.955-12.792,10.498-18.629,16.375
                c-2.498,2.516-4.376,5.394-5.994,8.513c-4.6,8.867-11.721,14.838-20.965,18.559c-5.315,2.14-9.663,5.731-13.029,10.442
                c-1.856,2.599-4.07,4.239-7.416,5.255c-3.354,1.019-6.375,3.653-9.1,6.226c-1.283,1.212-2.396,2.561-3.931,3.593
                c-3.666,2.467-4.831,2.611-8.046-0.068c-4.67-3.893-6.611-4.156-11.467-0.457c-7.525,5.732-14.65,11.971-21.5,18.492
                c-3.895,3.708-8.005,6.797-12.964,9.113c-4.981,2.327-9.654,5.533-13.99,8.952c-3.524,2.779-7.171,4.963-11.573,4.77
                c-2.943-0.129-5.102,2.395-8.006,1.63"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M232.5,569.889c1.211-1.163,2.734-2.004,4.086-2.863c2.356-1.498,5.239-1.923,7.916-0.141c2.694,1.795,5.786,3.008,7.522,4.994
                c2.185,0.25,3.686-0.419,5.546,0.912c2.578,1.844,6.333,1.708,8.427,4.6c0.352,0.486,0.653,0.61,1.005,0.506
                c3.794-1.121,6.996,2.446,11.04,1.119c3.372-1.105,6.358,0.406,7.755,3.836c8.77-0.323,16.188,2.428,22.111,8.625
                c0.869,0.908,1.408,1.47,2.591,1.416c4.086-0.184,7.383,1.59,9.966,4.526c3.357,3.815,7.894,6.073,12.17,8.194
                c2.934,1.456,4.325,3.146,5.304,5.726c1.114,1,2.417,0.282,3.554,0.57c1.623,0.411,2.389,1.936,3.484,3.001
                c2.322,2.258,4.593,4.473,8.012,5.031c0.892,0.146,1.791,0.824,2.485,1.473c1.58,1.475,3.185,2.395,5.514,2.59
                c2.736,0.229,3.449,3.785,5.587,4.703c1.871,0.804,4.396,0.564,5.85,2.244c1.08,1.25,1.83,2.932,2.711,4.352
                c1.907,3.068,5.568,4.366,8.39,6.549c1.96,1.518,2.079,1.364,2.525,4.399c-2.561,0.618-5.13,1.446-7.762,3.067
                c-2.274-3.379-6.117-5.367-6.396-9.924c-0.021-0.346-0.603-1.579-1.895-1.579s-1.621,1.046-1.918,1.616
                c-0.895,1.715-1.754,3.581-1.992,5.469c-0.264,2.099-1.531,3.435-2.546,5.262c-3.018-1.467-3.056-4.362-3.458-6.787
                c-0.456-2.752-1.142-5.396-2.105-7.979c-0.797-2.138-1.97-2.362-3.526-0.546c-0.287,0.335-0.135,1.2-0.955,1.036
                c-2.858-0.57-3.479,1.495-3.95,3.514c-0.173,0.741-0.736,0.976-1.239,1.84c-3.139-3.527-2.93-7.848-3.225-11.857
                c-0.272-3.701-0.84-7.211-2.144-10.641c-1.553,0.355-1.838,1.038-1.995,2.139c-0.285,2.013,0.015,4.251-2.907,5.144
                c-1.503,0.46-1.984,2.451-2.155,4.354c-0.169,1.871-0.348,3.993-1.89,5.607c-2.169-0.928-1.993-2.805-1.996-4.598
                c-0.004-3.166-0.002-6.334,0-9.5c0.001-2.076,0.104-4.189-2.493-5.965c0.083,4.238-5.383,3.883-5.588,7.961
                c-0.048,0.965-1.442,1.299-2.404,0.959c-0.8-0.282-1.581-1.073-1.476-1.95c0.589-4.929-3.734-7.324-5.143-11.28
                c-1.624,1.131-2.069,2.248-1.933,3.779c0.154,1.744,0.184,3.418-2.455,3.615c-0.915,0.069-1.38,1.535-1.642,2.854
                c-0.334,1.689-0.329,3.708-2.089,5.263c-2.84-2.342-2.172-5.783-2.735-8.744c-0.331-1.744,0.485-3.784-1.545-4.992
                c-1.212,0.453-2.161,1.22-2.482,2.504c-0.41,1.637-1.416,2.285-3.015,1.984c-1.669-0.313-2.373,1.236-2.375,2.012
                c-0.005,1.69-1.433,1.688-2.127,2.5c-1.116-1.295-1.389-2.791-1.581-4.49c-0.331-2.932-0.678-5.985-4.402-7.07
                c-2.239-0.652-3.202-2.825-3.44-4.948c-0.202-1.798-0.652-2.901-3.012-3.075c-1.044,2.551-3.579,4.025-5.255,6.453
                c-1.485,2.15,0.868,5.7-3.051,6.807c-2.048-4.934,0.742-10.537-2.203-15.659c-2.47,2.069-2.91,5.378-5.97,6.684
                c-1.36,0.58-0.67,3.739-2.375,5.618c-1.843-2.77-2.199-5.729-2.227-8.818c-0.02-2.203,0.466-4.506-0.984-6.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M300.5,127.889c-0.386-3.125-1.479-4.829-4.502-4.878c-3.76-0.061-6.967-1.518-9.907-3.275c-1.751-1.047-3.992-1.423-5.032-1.134
                c-5.618,1.565-9.352-2.272-13.466-4.395c-2.675-1.38-4.688-1.34-7.091-0.312c-2.399,1.027-3.371,2.646-3.65,5.479
                c-0.477,4.816,0.475,9.354,0.923,14.036c0.381,3.982,1.338,7.922,1.266,11.979c-0.14,7.831-0.041,15.666-0.041,23.499
                c0,3-0.59,6.155,0.147,8.961c0.954,3.631,4.067,6.171,6.929,8.443c1.906,1.513,1.821,3.522,1.795,5.093
                c-0.13,8.035,1.668,15.949,1.191,24.006c-0.281,4.75-1.17,9.362-2.111,14.253c4.368,1.416,3.014,5.679,3.918,8.781
                c0.851,2.917,1.172,6.001,2.02,8.994c0.926,3.271,0.084,6.841-1.055,9.907c-0.907,2.439-1.648,4.554-0.854,7.068
                c0.144,0.455,0.044,0.995,0.017,1.494c-0.734,13.693,1.264,27.312,1.096,41.001c-0.061,4.981,1.264,9.951,0.927,15
                c-0.045,0.673,0.017,1.296,0.425,2.029c4.39,7.897,7.886,16.226,11.555,24.469"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M363,152.389c-2.69-1.321-5.325-2.445-8.493-2.627c-3.487-0.201-5.83,1.76-8.471,3.194c-1.118,0.606-1.361,1.718-1.207,3.449
                c0.589,6.629,0.479,13.319,0.682,19.984c0.106,3.502,0.717,7.034,0.426,10.495c-2.118,25.151-0.98,50.35-0.667,75.502
                c0.157,12.631,0.149,25.38,1.673,38.01c0.316,2.625-0.186,5.344-0.292,7.999c-0.104,2.594-1.959,3.443-3.151,4.994"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M842.5,33.389c-4.304-1.932-6.305-6.165-8.859-9.604c-3.537-4.764-7.566-8.498-13.15-10.364
                c-5.864-1.96-11.037-5.373-16.351-8.286c-4.292-2.353-8.372-3.844-13.133-4.315c-3.776-0.375-7.369-1.933-11.044-2.766
                c-4.511-1.022-9.295-1.089-13.963-1.118c-18-0.113-36-0.032-54-0.063c-2.411-0.004-4.764-0.049-6.5,2.017"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1181.5,762.889c-2.5-1.95-5-0.814-7.502,0.001c-0.131,0.81,0.686,0.987,1.159,1.792c-3.354,1.001-6.303,2.59-8.176,5.695
                c-1.101,1.826-3.212,2.108-4.999,2.402c-1.328,0.218-2.221,0.635-3.106,1.948c4.842,5.292,10.708,7.915,18.129,6.691
                c1.682-0.277,3.532,0.808,6.028,2.999c-0.763,0.644-1.396,1.295-2.49,1.616c-0.582,0.171-2.01,0.554-2.079,1.769
                c0.267,1.269,1.406,1.723,2.035,2.585c-1.234,1.697-3.253,1.841-5.003,2.491c-4.065,1.512-8.324,2.471-12.041,4.941
                c-1.512,1.006-3.514,1.082-5.452,1.152c-1.993,0.073-4.315-1.291-5.925,1.298c-0.279,0.665-0.061,1.703,0.938,2.078
                c1.906,0.717,4.049,1.503,5.951,0.931c2.232-0.672,4.624-0.821,6.477-0.217c2.898,0.945,5.57,1.703,8.576,0.897
                c0.183-0.049,1.106,0.622,0.855,2.283c-6.508,2.776-14.188,3.659-20.935,7.733c-0.763-2.195-1.553-3.854-1.876-5.6
                c-0.252-1.363-0.854-2.473-2.062-2.42c-3.783,0.166-5.481-2.244-7.019-5.06c-0.395-0.724-1.042-1.308-1.475-2.015
                c-1.896-3.101-7.064-2.748-8.576,0.979c-0.891,2.197-1.104,4.736-2.912,6.549c-0.166,0.168,0.109,0.73-0.042,0.965
                c-1.253,1.943-1.95,4.528-4.979,4.514c-3.396-0.017-3.202-2.848-4.039-4.984c-0.858-2.195-1.611-4.637-4.969-4.061
                c-2.068,0.354-2.266-1.706-2.959-2.973c-0.211-0.385-0.045-0.982-0.031-1.482c0.066-2.535-0.696-5.155-3.051-5.828
                c-2.18-0.623-4.793,0.66-5.875,2.865c-1.856,3.784-5.279,5.488-8.576,7.463"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M174,153.889c3.261,0.384,4.116,2.97,4.995,5.502c2.704,7.782,4.489,15.69,5.226,23.979c0.416,4.682,0.5,9.348,0.795,14.019
                c0.663,10.5,0.478,21.054,1.979,31.502c0.047,0.327,0.027,0.667,0.003,0.999c-0.716,9.537,0.558,19.047,1.308,28.476
                c0.609,7.661,0.331,15.374,1.449,23.06c0.857,5.89,0.874,11.82,3.129,17.511c0.742,1.873,0.746,4.551-0.028,6.393
                c-1.216,2.894-0.541,5.725-0.917,8.642c1.708,1.098,3.491,1.722,5.552,1.99c2.582,0.336,4.178,2.043,5.622,4.359
                c1.899,3.047,3.044,6.632,5.888,9.07"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M527.5,106.889c0.029,4.557-0.561,9.22,2.902,13.087c0.306,0.342,0.367,1.859,0.319,2.923c-0.14,3.062-1.978,5.869-1.222,8.99
                c0.321,1.325-0.932,1.706-1.497,2.502c-2.058,2.901-4.005,5.858-4.497,9.499c-0.044,0.327,0.134,0.852-0.026,0.972
                c-5.017,3.779-7.62,10.142-13.943,12.619c-2.878,1.127-5.638,2.933-7.982,4.97c-5.539,4.812-12.196,7.583-18.542,10.959
                c-9.876,5.253-19.835,10.383-29.432,16.111c-3.726,2.224-7.717,2.785-11.608,3.75c-9.095,2.256-17.138,5.896-23.571,13.027
                c-2.395,2.654-6.401,3.426-9.429,5.549c-5.388,3.778-10.802,7.556-16.968,10.05c-2.769,1.12-5.503,2.326-8.253,3.492"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M248,333.389c0-0.667,0.119-1.359-0.019-1.996c-1.243-5.786-0.1-11.673-0.857-17.52c-0.602-4.646-0.963-9.088-2.921-13.573
                c-1.932-4.426-2.397-9.548-3.153-14.419c-0.897-5.78-0.932-11.642-1.196-17.485c-0.181-3.984-1.166-7.971-0.828-12.005
                c0.476-5.686,0.839-11.369,1.852-17.024c0.67-3.745,0.592-7.657,0.952-11.494c0.58-6.185,1.616-12.308,2.329-18.466
                c0.266-2.301,0.384-5.086,2.617-7.289c2.025,2.411,1.417,5.289,1.124,7.759c-0.619,5.216-1.803,10.365-2.832,15.96
                c2.803,1.45,3.875,4.187,3.958,7.551c0.224,9.029,1.409,17.998,2.139,26.987c0.88,10.836,1.679,21.71,3.032,32.489
                c0.69,5.502-0.087,11.198,1.844,16.51c1.142,3.138,0.399,6.435,1.099,9.483c0.735,3.201,0.953,6.341,0.833,9.531
                c-0.315,8.39,1.028,16.669,1.455,25.005c0.186,3.64-1.118,7.182-0.582,11.018c0.608,4.352,1.453,8.668,1.983,13
                c0.463,3.779,1.974,6.719,4.196,9.459c3.575,4.408,7.324,8.674,10.962,13.031c0.696,0.833,1.467,1.19,2.518,0.955
                c0.395-3.212,0.073-3.106-1.38-7.507c-0.828-2.507-3.248-4.895-1.623-7.961c2.16-0.159,2.294,2.042,3.485,3.018
                c1.938,1.588,2.576,4.594,6.01,4.651c1.229,0.02,2.024,1.955,2.103,3.829c0.127,3.016,0.631,6.081,1.451,8.99
                c2.364,8.385,6.621,16.013,9.958,24.011c2.28,5.464,4.331,11.002,8.423,15.564c2.449,2.73,5.088,5.254,7.558,7.948
                c0.401,0.438,0.676,0.658,1.01,0.491"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1206,376.389c-0.487,1.466-1.096,2.91-0.997,4.5c0.69,11.198-1.149,22.318-1.009,33.5c0.072,5.75-1.314,11.354-1.801,17.017
                c-0.371,4.322-1.275,8.606-1.223,12.984c0.101,8.332,0.522,16.703-0.086,24.992c-0.918,12.504-0.826,25-0.907,37.508
                c-0.005,0.832-0.335,1.661-0.6,2.891c-6.839-3.281-15.196-3.161-20.481-10.162c1.716-3.379,1.178-6.398-1.221-9.851
                c-1.991-2.866-2.426-6.794-3.215-10.37c-1.88-8.525-4.031-16.992-5.933-25.514c-0.995-4.464-1.733-8.987-2.491-13.5
                c-0.129-0.766-0.709-0.99-1.037-1.494"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M3.5,641.889c3.124,1.21,4.443,4.216,6.579,6.424c1.497,1.547-1.687,3.436,0.895,4.633c1.714,0.795,2.221,2.47,2.149,4.447
                c-0.02,0.549,1.046,1.826,2.38,1.487c2.622-1.685,0.401-5.108,2.8-7.139c3.896,2.615,6.75,6.441,9.655,10.181
                c0.713,0.918,1.166,2.058,1.14,3.469c-0.03,1.618,1.389,3.034,3.456,4.536c2.86-1.02,1.45-4.522,3.444-6.038
                c2.633-0.23,2.985,1.33,3.058,3.498c0.072,2.163-0.515,4.336,0.379,6.529c0.562,1.376,0.487,2.945,2.565,2.992
                c1.879,0.043,1.592-1.31,2.133-2.457c0.654-1.391,2.182-3.214,3.621-0.221c3.927-2.222,2.565-7.462,6.186-9.51
                c3.278,1.663,3.195,5.105,3.858,7.719c0.575,2.268,0.897,4.35,2.7,5.953c0.923,0.82,1.987,1.699,1.965,2.996
                c-0.065,3.836,1.865,6.467,4.457,9.08c1.863,1.879,2.05,4.691,2.154,7.417c0.104,2.69-1.262,5.747,1.396,8.01
                c2.039-0.028,1.064-2.534,2.503-2.996c0.594-0.045,1.087-0.038,1.541,0.478c2.658,3.02,3.89,6.992,6.549,9.955
                c1.213,1.352,0.895,2.68,0.915,4.057c0.062,4.166,0.083,8.334-0.002,12.5c-0.045,2.178,0.359,3.999,2.412,5.078
                c1.34-0.934,1.731-2.054,1.651-3.58c-0.191-3.617,1.013-6.696,3.956-9.006c3.877-3.043,5.476-7.078,6.28-11.947
                c0.303-1.836-0.098-4.283,1.659-6.109c1.694-1.761,4.725-2.064,4.894,0.078c0.439,5.568,6.126,7.391,7.68,12.543
                c4.298-0.336,8.483-0.594,12.178-3.371c-0.853-0.852-1.519-1.52-2.185-2.184c1.131-1.904,3.473-2.332,4.998-2.32
                c3.917,0.031,7.602-1.268,11.501-1.285c3.808-0.017,7.685-0.637,10.997-2.902c2.497-1.709,3.2-4.074,2.004-6.494
                c-1.29-2.609-2.641-3.02-6.004-2.014c-2.829,0.846-5.765,1.587-8.426,2.685c-2.867,1.183-5.269,1.757-8.086,0.36
                c-1.1-0.545-4.037,0.155-7.495,0.92c-2.768,0.612-5.573,1.791-8.489,0.549"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M287,204.389c-1.762,2.757-1.568,6.073-1.803,9.016c-0.396,4.943-0.994,10.076,0.774,14.995c0.212,0.59,0.048,1.324,0.026,1.99
                c-0.338,10.208,1.746,20.377,1.28,30.49c-0.537,11.668,0.943,23.415-1.249,35.016c-0.213,1.126-0.028,2.328-0.028,3.495
                c0,9.5-0.01,19,0.006,28.5c0.005,3.363-0.011,6.687,0.883,10.03c0.49,1.833,0.531,3.868,0.253,5.989
                c-0.952,7.275-0.032,11.785,3.39,19.968c2.069,4.949,2.981,10.478,6.968,14.513c1.785,8.152,6.82,14.754,10.519,21.99
                c2.955,5.781,7.102,10.924,9.448,17.022c2.4,6.238,5.407,11.976,10.479,16.547c1.742,1.57,2.296,4.316,2.412,6.448
                c0.443,8.138,2.579,15.991,3.467,24.012c0.403,3.64,0.749,7.383,1.34,10.953c1.295,7.816,1.655,15.723,2.595,23.557
                c0.589,4.914,1.496,9.956,1.182,14.967c-0.276,4.419,1.317,8.609,1.116,13.007c-0.085,1.848-0.319,3.574-1.058,5.247"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M63.5,76.889c0.203,6.509,3.403,13.062-0.43,19.542C62.559,97.294,63,98.722,63,99.889c0,5,0.063,10.001-0.014,15
                c-0.131,8.506-1.143,16.967-1.21,25.498c-0.025,3.283-0.359,6.638-0.415,10c-0.066,3.99-1.33,7.907-1.621,11.984
                c-0.174,2.433-0.774,5.004-0.688,7.517c0.11,3.243-1.813,6.252-1.029,9.497c1.194,4.939-1.301,10.346,1.948,15.027
                c0.234,0.337,0.066,0.981,0.026,1.478c-0.735,9.041,0.647,18.019,1.172,26.991c1.182,20.175,0.604,40.341,0.829,60.51
                c0.075,6.738-1.219,13.347-1.837,20.015c-0.31,3.342-1.127,6.626-1.135,9.985c-0.002,1.027-0.086,1.908-1.024,2.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M315.5,216.389c0,0.667-0.248,1.472,0.037,1.979c5.932,10.59,7.534,16.157,7.444,27.521c-0.093,11.848,1.7,23.638,1.026,35.5
                c-0.119,2.098,0.697,3.911,2,5.494c3.471,4.22,4.201,9.344,5.05,14.496c0.487,2.955,0.606,6.061,2.902,8.548
                c2.484,2.691,2.793,7.149,7.026,8.509c1.202,0.386,1.192,1.89,1.496,2.958c2.093,7.356,4.094,14.733,6.341,22.049
                c1.03,3.354,0.599,7.301,0.574,10.944c-0.056,8.176-1.342,16.3-1.443,24.5c-0.074,6.039,0.469,12.009,1.012,18.003
                c0.647,7.153,1.156,14.325,1.486,21.5c0.177,3.858,2.08,6.312,5,8.561c2.707,2.085,5.446,4.253,8.585,5.33
                c5.969,2.047,8.146,6.602,8.906,12.115c0.839,6.088,3.15,11.399,6.482,16.541c5.08,7.838,9.892,15.857,14.653,23.905
                c5.15,8.706,9.347,17.833,13.489,27.016c0.223,0.494,0.185,1.778,1.62,1.493c0.405-0.561,1.544-1.122,1.258-1.943
                c-1.866-5.361-2.075-11-2.906-16.525c-0.999-6.642-1.538-13.354-2.585-19.986c-1.102-6.977-1.666-14.002-2.456-21.008
                c-0.489-4.334-1.293-8.657-1.43-13.002c-0.208-6.654-1.977-13.029-2.841-19.528c-0.849-6.383-3.138-12.49-3.269-18.969
                c-0.007-0.34-0.625-0.667-0.958-1.001"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M3.5,66.889c1.833,0,3.667,0,5.536,0c1.307,1.18,1.187,3.995,3.461,3.92c3.582-0.118,7.226,2.504,10.422,0.916
                c4.167-2.071,8.349-1.518,12.577-1.88c3.335-0.286,6.617,0.45,10.092-0.688c2.037-0.667,5.132,1.104,5.852,4.14
                c-0.99,3.243-3.735,4.461-6.453,5.557c-3.059,1.233-6.598,1.759-7.005,6.032c-0.017,0.181-0.29,0.376-0.485,0.496
                c-4.403,2.703-3.805,7.44-4.357,11.524c-0.275,2.035-1.06,3.896-1.255,5.97c-0.525,5.561,1.575,10.667,3.363,15.418
                c3.771,10.022,2.997,20.13,1.765,29.594c-0.085,0.656-0.014,1.332-0.011,1.999c0.04,7.18-0.706,14.472,1.132,21.465
                c1.069,4.069,0.673,8.027,0.844,12.036c0.256,5.996-0.114,12.062,0.68,17.978c0.583,4.335,0.76,8.832,2.343,13.021"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1079,212.889c-3.9,1.653-6.432,4.653-9.141,7.882c-5.754,6.862-6.883,15.738-11.254,23.18
                c-4.942,8.412-7.426,17.843-9.063,27.445c-0.166,0.972-0.541,1.727-1.306,3.023c-3.285-0.375-6.734,0.486-10.232,0.997
                c-4.01,0.585-7.728,1.456-11.128,4.326c-3.501,2.954-8.319,3.822-12.885,4.597c-0.849,0.144-1.683,0.261-2.431,0.6
                c-6.651-5.41-14.561-8.357-22.069-12.032c-3.281-1.605-6.591-3.194-10.004-4.485c-10.435-3.947-20.814-3.878-30.981,0.98
                c-5.582,2.667-10.586,6.388-16.463,8.602c-3.502,1.319-7.411,1.533-10.584,3.829c-0.547,0.396-2.092,0.393-2.378-0.002
                c-1.192-1.652-3.388-2.139-4.522-1.765c-2.824,0.929-5.672,1.66-8.553,2.35c-1.874,0.449-2.547,2.45-4.018,3.469
                c-1.93-1.024-3.898-1.931-5.476,0.513c-1.274,1.975-3.78,0.054-4.97,1.527c-2.251,2.79-6.143,2.553-8.555,4.953
                c-0.418,0.415-0.723,1.266-1.48,0.993c-5.293-1.909-6.117,3.001-8.676,5.802c-5.323-4.776-11.425-2.182-17.331-1.771
                c-2.032,0.141-1.978,3.544-4.5,2.984c-1.66-0.369-3.342-1.816-4.957-0.917c-2.095,1.166-5.335-1.183-6.702,2.36
                c-0.174,0.448-2.514-0.012-3.84,0.083c-1.172,0.083-2.338-0.293-3.523,0.444c-3.226,2.009-3.266,1.945-6.872,0.922
                c-1.86,1.685-3.599,4.801-7.108,2.608c-1.793,1.005-3.037,2.625-4.676,4.17c-3.111-1.991-5.23,1.395-7.84,1.638
                c-3.967,0.37-4.327,3.849-6.482,5.695c-1.926,0.291-3.104-1.1-4.357-2.364c-4.244,2.392-8.357,4.041-13.145,3.991
                c-2.624-0.027-4.859,2.704-8.044,1.493c-1.153-0.438-2.452,1.401-2.853,2.42c-1.426,3.621-4.446,4.063-7.604,4.448
                c-0.656,0.08-1.859-0.155-1.92,0.036c-1.271,4.031-5.107,3.781-8.072,4.991c-1.746,0.713-1.611,2.826-2.923,4.072
                c-2.751,2.616-6.575,3.849-8.708,7.336c-0.818,1.335-3.294,0.955-4.955,1.942c-1.793,1.067-3.261,1.795-2.92,4.134"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M365.5,151.389c3.073,5.071,3.916,10.832,4.416,16.507c1.407,15.979,2.479,31.988,3.561,47.994c0.629,9.325,1.085,18.663,1.48,28
                c0.098,2.314,0.464,4.964,1.682,6.379c5.066,5.889,7.876,13.193,12.742,19.216c2.738,3.39,5.286,6.927,9.174,8.791
                c3.27,1.567,4.115,4.637,5.94,7.117c2.759,3.748,4.648,8.013,7.043,11.973c2.613,4.322,6.343,7.576,10.429,10.569
                c12.017,8.807,23.198,18.427,29.609,32.419c0.536,1.168,0.941,2.386,1.499,3.498c1.176,2.345,1.374,4.966,4.286,6.765
                c3.101,1.915,4.464,6.148,5.024,9.79c1.402,9.118,4.415,17.757,7.171,26.465c3.738,11.811,7.91,23.473,12.335,35.059
                c3.822,10.008,8.532,19.314,16.152,26.916c2.04,2.035,3.49,4.483,5.346,6.639c2.577,2.992,3.908,6.827,4.111,10.903
                c0.202,4.043,2.238,7.609,2.973,11.506c0.396,2.104,1.671,2.584,3.529,2.507c1.498-0.062,3.002-0.071,4.499-0.003
                c6.256,0.289,11.559,2.035,15.066,7.951c5.539,9.344,11.266,18.582,17.324,27.613c0.91,1.355,1.44,3.261,2.094,4.932
                c0.948,2.424,2.609,3.975,5.001,5.029c4.976,2.191,7.072,7.245,10.505,10.975c2.487,2.701,4.274,5.979,6.992,8.51
                c1.172,1.093,2.109,2.219,4.026,2.107c0.622-0.036,1.781,0.471,2.533,1.338c5.204,6.006,10.574,11.865,15.005,18.505
                c0.6,0.899,0.287,1.697,0.455,2.53"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M325,174.389c-1.376-2.842-1.371-6.009-2.125-8.968c-1.155-4.533-1.686-9.286-4.243-13.601c3.374-1.793,6.726-0.366,9.759-1.073
                c2.748,2.96,3.973,6.167,3.685,10.147c-0.252,3.491-0.811,6.813-3.111,9.463c-3.611,4.161-4.294,8.198-2.182,13.616
                c1.856,4.761,2.28,10.223,0.273,15.437c-0.391,1.016-0.056,2.312-0.056,3.479c0,5.833,0.017,11.667-0.004,17.5
                c-0.028,7.894,1.144,15.684,1.805,23.517c0.419,4.976,0.765,10.02,1.333,14.968c1.007,8.757,0.414,17.857,4.798,26.052
                c0.879,1.643,1.491,3.349,3.068,4.463"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M424,361.889c-4.587-4.325-7.639-9.796-10.923-15.048c-1.649-2.638-3.783-4.06-6.572-4.969c-4.271-1.393-8.456-3.011-12.536-4.918
                c-0.789-0.369-1.29-1.218-2.474-1.123c-4.062,0.324-7.31-1.784-10.3-4.068c-1.775,1.147-1.452,2.858-1.131,4.109
                c3.608,14.071,6.04,28.401,9.434,42.517c1.682,6.998,3.98,14.199,3.207,21.217c-0.861,7.814-0.475,15.536-0.618,23.284
                c-0.142,7.695,0.635,15.353,1.67,23.031c0.659,4.89,1.242,9.929,1.333,14.965c0.1,5.508,0.714,11.029,1.48,16.492
                c0.475,3.387,0.592,6.676-0.071,10.01"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M36,165.889c-2.373,5.422-3.64,11.153-3.926,17.003c-0.281,5.735-0.831,11.394-2.039,17.004c-0.24,1.115,0.04,2.332-0.05,3.492
                c-0.207,2.68,0.216,5.316-0.799,8.069c-0.661,1.792-0.826,4.34,0.314,6.432c1.05,1.926,1,4.306,0.335,5.933
                c-1.12,2.739-0.645,5.387-0.824,8.068c-0.577,8.674-0.538,17.394-2.001,26.001c-0.083,0.486-0.052,1.002-0.005,1.498
                c0.604,6.384-1.488,12.609-1.069,19.005c0.169,2.594-1.144,5.029-1.937,7.496"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M57,191.389c-3.391,3.402-2.824,7.938-2.892,12.002c-0.265,15.996-0.513,32.014,0.101,47.99c0.229,5.983,0.397,12.018,0.87,18.001
                c0.49,6.196-2.44,11.892-2.079,18.006c-1.821,1.369-1.069,3.351-0.937,4.995c0.461,5.736-1.671,11.28-1.11,17.009
                c0.029,0.298-0.62,0.662-0.953,0.996"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M555,1.389c-3.136-0.621-6.31-0.856-9.499-1.02c-3.797-0.194-7.514-0.607-11.03-2.424c-3.382-1.748-6.899-2.003-9.971,0.944"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M435,691.889c0.813-1.016,1.627-2.031,2.442-3.05c1.591,0.317,2.143,2.857,4.033,1.993c1.294-0.591,1.85-1.907,1.539-3.446
                c-0.11-0.546-0.041-1.055,0.474-1.512c3.007-2.673,4.149-6.394,5.412-10.908c3.38,3.595,4.285,7.927,7.182,10.902
                c2.946-0.846,1.892-5.113,4.917-5.98c0.335,0.001,0.845-0.141,0.976,0.022c2.962,3.701,2.941,3.697,5.539-0.012
                c0.451-0.645,1.015-1.287,2.61-0.789c0.871,1.139,2.09,2.734,3.292,4.308c2.062-0.653,2.708-2.544,3.523-4.062
                c1.715-3.199,3.064-6.625,7.562-6.449c0.826,0.032,0.7-0.725,0.95-1.056c1.276-1.687,2.463-1.315,3.524,0.058
                c0.8,1.037,0.951,2.599,2.524,2.982c4.263,0.259,2.216-5.788,6.076-6.076c-0.462-2.99-1.84-5.392-5.082-5.869
                c-2.758-0.407-3.199-4.099-5.99-4.008c-2.872,0.094-4.333-1.863-5.983-3.566c-1.676-1.731-3.51-2.91-6.017-2.473
                c-1.761,0.307-2.465-1.109-3.504-2.007c-2.294-1.981-4.817-3.394-7.997-2.997c-1.756,0.22-3.609-0.162-4.414-1.557
                c-1.54-2.664-4.128-3.152-6.598-3.914c-2.457-0.758-4.759-1.822-6.977-3.059c-2.977-1.658-5.986-3.137-9.506-2.442
                c-1.444,0.285-2.9-0.271-3.415-1.092c-2.277-3.626-5.79-4.601-9.598-4.858c-1.117-0.076-2.001-0.703-2.417-1.154
                c-5.395-5.86-11.749-10.478-18.615-14.362c-2.618-1.48-3.781-4.007-5.363-6.141c-2.869-3.866-6.413-5.638-11.098-5.407
                c-0.174,0.01-0.334,0.314-0.501,0.48"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M910,40.889c0.5-0.5,1.206-0.911,1.467-1.514c1.91-4.412,5.837-7.682,7.033-12.486"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M904,0.889c-6.34-2.97-12.912-4.178-20.001-4.103c-24.831,0.264-49.665,0.104-74.499,0.1c-2.53,0-5.126-0.271-7,2.003"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1070,768.389c-3.167-1.333-1.975-4.846-3.68-7.25c-1.82,0-3.82,0-5.695,0c-1.432,1.868,0.688,5.044-2.612,5.755
                c-1.734-0.569-2.159-1.788-2.061-3.508c0.065-1.149,0.521-2.468-0.466-3.484c-0.411-0.425-0.507-1.098-1.486-1.098
                s-1.172,0.634-1.471,1.104c-1.091,1.709-0.455,3.651-0.486,5.482c-0.037,2.188-0.605,3.743-2.904,4.064
                c-2.319-1.126-0.022-3.894-2.118-5.101c-1.614-0.93-3.359-0.357-5.05-0.867c-3.967-1.195-6.265-3.578-6.541-7.738
                c-2.282,0.291-2.926,1.469-2.924,3.141c0.002,1.872-0.02,3.495-2.484,4.087c-1.577,0.379-0.971,2.181-1.007,3.413
                c-0.04,1.385-0.464,2.381-2.015,2.498c-1.877,0.143-2.131-1.047-1.995-2.498c0.277-2.954,0.069-5.501-3.472-6.607
                c-0.891-0.279-0.839-2.244-2.149-2.955c-2.3,1.176-2.416,3.268-2.413,5.562c0.009,6.294-0.046,6.294-5.618,8.561
                c-2.018-1.062-2.438-2.883-2.397-5.062c0.019-0.981,0.56-2.112-0.443-3.011c-0.595-0.533-1.053-1.175-2.014-1.002
                c-0.895,0.16-1.451,0.606-1.487,1.514c-0.054,1.375,0.294,2.893-1.599,3.528c-3.42-2.95-0.617-7.88-3.262-11.013
                c-2.581-0.191-2.119,1.566-2.148,2.984c-0.059,2.82,0.455,5.781-2,8c-1.87,0.217-3.543-2.23-5.486-0.483
                c-1.32,1.187,0.596,4.249-2.979,4.519c-3.844-3.119,0.609-8.758-3.084-12.084c-1.879,0.139-2.611,1.207-2.457,3.049
                c0.15,1.802-0.702,2.457-2.461,3.098c-4.226,1.541-4.967,1.314-5.521-3.1c-0.152-1.207,0.405-2.67-1.512-3.011
                c-1.661-0.295-1.717,1.479-2.554,1.913c-2.936,1.521-3.312,5.312-6.417,6.671c-1.442,0.631,0.326,2.995-1.498,3.989
                c-0.859,0.468-1.248,0.611-2.059-0.029c-1.639-1.297-0.847-3.007-0.943-4.533c-0.16-2.504,0.498-5.197-2.129-6.968
                c-3.176,1.101-1.416,3.909-2.084,6.302c-2.566,1.283-5.217,3.17-8.357,0.209c-1.232-1.162-2.717-0.857-3.984,0.436
                c-0.737,0.752-1.268,1.826-2.098,1.998c-3.406,0.234-2.863-4.914-6.379-3.975c-2.373-4.199-6.434-2.778-10.354-3.047
                c-0.552,4.041-4.91,3.668-7.232,5.96c-1.208,1.192-3.328-0.36-4.414-2.913c-1.574-3.7-2.477-4.074-4.944-1.447
                c-1.723,1.834-5.021,1.671-5.68,4.922c-0.198,0.975-1.969,0.914-2.846-0.004c-0.654-0.686-1.3-1.587-1.468-2.482
                c-0.259-1.385,0.325-2.966-2.061-3.042c-1.975-0.063-2.637,1.712-2.832,2.591c-0.348,1.561-0.383,3.297-2.174,3.371
                c-2.31,0.094-4.959,1.002-6.934-0.49c-2.141-1.617-6.197-0.976-6.215-5.418c-0.006-1.387-2.965-1.412-3.814,0.02
                c-0.22,0.369,0.147,1.316-0.061,1.429c-2.132,1.147,0.072,3.788-1.934,5.11c-1.85,1.219-3.381-0.594-5.036-0.051
                c-1.415,0.465-2.886,0.764-3.54,2.478c-0.559,1.461-1.228,2.931-2.46,4.019c-0.353,0.311-0.669,0.663-1.003,0.996
                c-1.93-0.736-3.015-2.215-4.057-3.967c-1.783-2.995-3.48-2.881-5.477-0.057c-0.369,0.521-0.261,1.098-0.503,1.502
                c-2.022,3.369-4.009,3.553-6.442,0.504c-1.92-2.406-3.619-3.961-7.117-2.17c-1.464,0.749-4.756,1.408-5.5-2.293
                c-0.194-0.963-1.439-2.033-2.444-2.413c-0.852-0.321-2.335-0.705-2.974,0.888c-0.887,2.217-2.486,4.108-2.68,6.907
                c-4.94,0.567-8.362-2.236-11.746-4.491c-3.213-2.142-3.805-2.828-6.5-0.83c-2.285,1.694-4.641,1.389-7.061,1.401
                c-2.394,0.013-4.752-0.151-6.519,2.003c-1.425,1.738-3.474,1.543-4.933,0.449c-3.36-2.521-6.619-2.203-9.788-0.383
                c-3.934-0.777-2.636-4.564-4.532-7.281c-4.297,4.299-8.396,8.396-12.965,12.967c-0.898-4.359-2.555-7.473-6.221-9.32
                c-1.564-0.789-1.424-3.329-2.793-4.248c-4.535,1.191-2.475,6.076-4.833,8.242c-2.407,2.211-2.515,5.874-5.655,7.549
                c-2.354-0.643-1.599-4.34-4.723-5.062c-2.315,4.461-5.396,8.527-10.023,10.988c-2.403-0.641-3.177-4.186-6.544-3.531
                c-0.585,0.113-2.21-1.307-1.951-2.85c0.226-1.346-0.95-1.713-1.69-2.766c-4.809,3.754-6.715,9.439-9.562,14.646
                c-2.499-1.25-4.635-2.318-6.77-3.386c-4.034,0.604-1.859,6.087-6.211,6.804c-3.229-1.51-0.493-7.666-5.709-9.062
                c-0.724,2.953-3.942,3.503-5.388,5.404c-1.996,2.627-5.374,2.467-6.793,4.947c-4.082-0.454-4.104-3.847-4.743-6.135
                c-0.772-2.768-2.104-1.855-4.157-1.692c-0.014,2.841,0.287,5.72-2.078,7.806c-2.976-0.359-3.025-3.16-3.821-5.11
                c-0.507-1.243-0.153-2.831-2.099-2.954c-2.109-0.134-3,1.869-2.865,2.978c0.601,4.948-4.213,3.938-6.19,5.962
                c-0.282,0.291-1.618,0.436-1.914-0.455c-1.378-4.137-5.428-4.735-8.567-6.422c-2.226-1.195-3.746-2.562-4.529-5.048
                c-0.754-2.393-3.808-2.943-4.247-0.979c-0.651,2.921-3.264,3.222-4.688,4.958c0,2.166-0.053,4.335,0.021,6.499
                c0.042,1.231-0.626,2.114-1.544,2.433c-1.08,0.374-2.027,0.023-2.534-1.411c-0.704-1.992-2.092-3.772-1.916-6.019
                c0.075-0.963-0.427-1.469-1.027-2.002"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1163,760.889c0.131-0.81-0.623-1.389-1.008-1.437c-2.562-0.317-4.402-1.108-5.141-4.026c-0.262-1.033-1.504-2.657-3.638-2.536
                c-4.36,3.087-2.604,9.622-6.541,13.093c-0.507,0.072-0.765-0.17-1.185-0.582c-2.702-2.646-0.225-6.873-2.977-9.523
                c-2.542-2.449-3.834-5.577-4.482-8.948c-1.076-0.241-2.08-0.017-2.5,0.972c-1.172,2.763-3.055,4.873-5.533,6.481
                c-1.666,1.081-2.001,2.614-2.041,4.506c-0.09,4.148-7.777,8.798-11.445,6.981c-2.013-0.996-1.576-3.377-2.362-5.05
                c-0.524-1.114-0.524-2.649-1.13-3.938c-1.721-3.663-2.17-3.811-5.518-1.492c-3.601,2.493-5.136,2.251-7.457-1.527
                c-0.801-1.304-1.693-2.322-2.98-3.061c-3.118,0.377-3.398,3.153-3.928,5.124c-0.589,2.188-1.843,3.946-2.666,5.951
                c-0.414,1.011-1.441,1.165-2.469,1.013"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1014,451.389c0.334,0.833,1.013,1.695,0.945,2.496c-0.575,6.89,0.896,13.659,1.188,20.499c0.635,14.834,0.428,29.705,1.832,44.51
                c0.37,3.902,1.499,7.657,2.246,11.454c0.756,3.841,1.472,7.704,1.683,11.548c0.375,6.812,0.132,13.661,0.083,20.494
                c-0.01,1.334-0.312,2.666-0.478,4"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M235,358.389c-2.489-5.031-3.631-10.605-6.382-15.565c-0.696-1.255-0.679-3.206-0.675-4.935c0.08-28.667,0.055-57.333,0.062-86
                c0-1.566,0.628-3.087,0.827-4.523c0.781-5.637-4.672-15.133-9.832-16.477"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M347,190.389c0.807,3.792,1.511,7.577,1.035,11.504c-0.217,1.795,0.802,3.422,1.512,4.974c4.077,8.918,5.142,18.31,5.633,28.012
                c0.362,7.156,0.633,14.364,1.646,21.534c0.833,5.899,0.71,11.992,0.97,17.984c0.267,6.18,1.196,12.295,1.318,18.489
                c0.088,4.512,1.341,8.99,1.821,13.509c0.167,1.566,1.207,2.236,2.101,2.949c3.075,2.449,6.298,4.711,9.465,7.044"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M208,256.389c-2.57,6.133-2.475,12.657-1.859,18.986c0.735,7.558,3.251,14.973,3.68,22.524c0.565,9.958,1.454,19.991,0.175,29.989
                c-0.518,4.047-1.791,8.165,1.959,11.55c0.685,0.618,0.665,1.885,0.555,2.951c-1.118,10.817-0.527,21.672-0.378,32.497
                c0.074,5.344,1.537,10.668,2.368,16.002c1.168,7.5,2.334,15,3.5,22.5s2.264,15.012,3.521,22.497
                c1.067,6.35,1.853,12.752,3.417,19.019c0.137,0.549-0.604,1.318-0.938,1.984"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1056.5,833.889c-1.445-2.33-4.293,0.1-6.081-1.929c-1.497-1.699-1.509-3.776-2.437-5.562c-0.845-1.63-2.434-1.599-3.982-1.506
                c-2.194,0.131-2.668,1.865-2.969,3.503c-0.762,4.149-1.364,8.328-2.031,12.494c-0.418,2.609-1.09,5.051-4.355,6.116
                c-2.146-1.806-2.737-4.321-2.641-7.116c0.073-2.096-0.764-3.171-3.005-3.016c-3.154,0.219-4.351-2.484-4.847-4.521
                c-1.025-4.199-3.466-5.068-6.742-4.445c-2.332-1.956-2.798-4.59-3.294-7.041c-0.307-1.514-0.907-2.793-1.674-3.938
                c-1.108-1.655-2.637-1.566-3.937-0.034c-2.57,3.032-3.185,6.92-4.498,10.497c-1.047,2.85-1.959,5.738-5.504,6.516
                c-2.617,0.574-3.271,3.256-3.316,4.987c-0.092,3.404-1.583,6.56-1.232,10c0.296,2.912-1.191,5.632-1.414,8.498
                c-0.158,2.054-2.104,2.276-3.068,3.533c-2.938-3.13-3.138-7.156-3.429-11.04c-0.612-8.172-0.903-16.363-1.957-24.508
                c-0.218-1.688-1.21-2.441-2.087-3.525c-1.354,0.447-2.705,1.595-2.877,2.559c-0.799,4.471-3.067,7.691-6.495,10.628
                c-1.437,1.23-1.724,4.054-1.62,6.351c0.093,2.037-0.678,3.375-2.449,4.586c-1.609,1.1-3.23,1.627-3.954-0.129
                c-1.53-3.711-3.755-3.508-6.293-1.854c-2.013-0.679-0.705-2.861-2.257-3.705c-0.453-0.246-0.672-1.48-1.551-1.367
                c-3.741,0.48-5.667-1.932-7.502-4.533c-1.102-1.562-1.816-3.277-4.526-2.594c-2.974,0.749-3.085,3.203-3.353,5.113
                c-0.485,3.466-0.335,6.98-0.953,10.512c-0.549,3.132-2.052,4.553-4.833,5.746c-1.809-1.518-2.505-3.875-4.314-5.305
                c-2.203-1.74-3.871-1.249-5.044,1.52c-1.369,3.234-1.691,6.743-2.773,10.076c-1.219,3.749-2.987,6.758-5.692,9.446
                c-3.207,3.187-6.867,6.025-8.665,10.344c3.922,3.706,4.312,6.118,1.104,10.104c-2.807,3.485-6.037,6.631-9.002,9.842
                c0.522,2.318,2.491,2.074,3.493,1.58c1.691-0.834,3.359-0.67,5.049-0.938c1.444-0.229,3.046,0.356,3.35,1.118
                c0.452,1.13,0.511,2.685-0.792,3.985c-2.502,2.498-4.633,5.362-7.07,7.932c-3.08,3.248-6.279,6.387-9.502,9.495
                c-0.781,0.754-1.129,1.497-0.977,2.524"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M57,81.889c0.715,7.381-0.698,14.696-1.274,21.979c-0.499,6.309-1.094,12.762-2.604,19.051c-0.841,3.503-0.832,7.485-0.387,10.94
                c0.941,7.31-1.051,14.341-0.812,21.533c0.133,3.989,0.155,7.961-0.743,12.037c-1.149,5.216,1.523,10.562,0.82,15.96
                c1.897,1.926,0.646,4.336,0.985,6.502c0.191,1.218-0.45,2.654,1.015,3.498"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M276.5,119.389c0,0.333,0.023,0.668-0.004,1c-0.179,2.172,2.116,3.6,1.588,6.019c-0.417,1.909-0.084,3.981-0.084,5.981
                s0.462,4.149-0.123,5.96c-0.655,2.03-0.752,4.204-0.707,6.035c0.271,10.872-2.228,21.686-1.015,32.487
                c0.696,6.197,0.759,12.339,0.832,18.518c0.072,6.019-0.892,11.988-1.233,17.986c-0.261,4.571-2.587,9.082-4.366,13.469
                c-0.471,1.162,0.496,2.972-1.388,3.545"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1201,371.889c1.083,0.813,2.041,1.637,3.475,2.083c4.99,1.554,6.802,5.751,7.449,10.428c0.326,2.345,1.127,4.468,2.137,6.458
                c2.088,4.112,2.083,7.991,1.193,12.58c-0.947,4.894-1.568,9.975-2.407,14.925c-1.018,5.999-1.658,12.059-2.647,18.059
                c-0.748,4.543-0.926,9.311-1.104,13.971c-0.332,8.654-0.111,17.33-0.074,25.996c0.005,1,0.312,2,0.479,3"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M235,589.389c0.328-0.504,1.317-0.784,0.964-1.482c-0.976-1.928,0.241-4.232-1.012-5.983c-0.748-1.046-1.486-1.745-0.479-3.056
                c0.949-1.234,0.902-2.683,0.016-3.971c-1.705-2.475-2.381-5.885-5.05-7.398c-2.834-1.607-2.659-4.248-3.401-6.621
                c-0.237-0.759,0.312-1.986-0.104-2.426c-1.206-1.275-1.571-3.35-3.922-3.652c-1.633-0.209-2.621-1.582-3.076-3.395
                c-0.461-1.838-2.132-2.18-3.914-2.608c-3.039-0.732-6.36-1.17-8.517-3.911c-1.364-1.734-3.429,0.618-3.857-0.086
                c-1.085-1.785-3.231-0.02-4.306-1.816c-0.357-0.596-2.551-0.094-3.759-0.094c-1.094,2.725-0.582,4.672,0.899,6.514
                c0.603,0.75,1.372,1.621,1.451,2.492c0.242,2.657-0.199,5.328,0.372,8.035c0.123,0.582,0.57,2.882-1.447,3.502
                c4.458,5.779,6.957,12.293,8.302,19.427c0.385,2.042-0.245,5.44,3.217,6.119c3.158-2.328,2.029-6.882,5.109-9.519
                c3.934,3.877,2.178,9.943,6.273,14.371c0.847-3.5,3.366-4.652,5.182-6.498c1.963-1.996,0.087-6.019,3.43-7.463
                c2.156,0.333,2.394,2.35,2.497,3.531c0.272,3.127,0.685,5.836,4.125,7.007c1.12,0.382-0.012,1.085,0.076,1.466
                c0.886,3.859,1.777,7.734,3.047,11.477c0.29,0.855,0.812,2.444,2.556,2.562c2.738-2.547,0.614-7.478,3.453-9.331
                c3.281-2.144,2.061-6.242,4.802-8.636c4.093,4.009,0.081,9.737,3.776,13.661c1.106-2.46,3.727-3.203,5.39-5.137
                c0.952-1.107-0.433-1.414-0.093-2.08"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1280.5,640.389c0.564,1.818-1.545,1.497-1.996,2.49c-2.771-1.538-2.769-5.062-5.209-7.166c-2.816,1.638-5.545,3.868-9.309,3.265
                c-0.734-0.118-2.003,0.71-2.428,1.445c-2.174,3.769-6.857,4.787-9.025,9.384c-2.478-4.293-5.899-6.935-7.281-11.098
                c-4.158,0.884-2.432,6.015-5.589,7.27c-1.429-0.785-1.782-3.245-3.634-2.516c-2.707,1.066-5.418-2.891-8.154,0.319
                c-0.555,0.651-1.533,0.842-1.977,2.069c-0.645,1.786-1.024,3.793-2.866,5.083c-2.202,1.542,0.03,4.717-2.263,6.647
                c-2.193-2.225-1.713-5.031-1.777-8.002c-3.059-1.11-6.11-2.543-5.857-6.671c-1.831-0.28-2.821,0.694-3.166,1.992
                c-2.477-0.034-1.502-2.54-2.963-2.999c-0.981-0.299-1.574,0.343-1.982,1.001c-0.954,1.536-0.485,3.667-1.959,4.969
                c-2.645,0.665-1.463-3.074-3.221-2.999c-0.746,0.613-1.823,1.164-1.76,1.5c0.488,2.592-0.951,3.748-3.09,3.961
                c-3.207,0.318-4.629,3.104-6.795,4.74c-4.129-1.885-0.447-6.601-2.812-7.992c-3.12-1.835-1.507-5.458-4.146-7.808
                c-1.585,4.472-5.268,6.879-6.611,11.507c-1.131,3.893-5.346,6.952-6.869,11.875c-3.648-3.651-1.871-8.884-4.852-11.865
                c-2.653,0.497-1.457,3.479-3.408,4.098c-1.916-1.028-1.438-2.853-1.502-4.5c-0.141-3.607-0.494-3.959-4.497-4.5
                c-1.636,0.832-0.469,3.416-3,3.822c-3.148-1.121-3.148-1.121-5.497,3.171c-1.958,0.319-2.077-1.572-2.712-2.347
                c-0.626-0.312-0.962-0.148-1.292,0.354c-1.805,2.747-0.169,6.205-1.908,9.057c-0.472,0.772-0.482,1.734-1.648,2.002
                c-2.383-1.76-1.083-5.598-4.096-6.939c-2.119,1.662-1.371,5.988-3.43,6.607c-3.48,1.049-0.544,5.424-4.404,5.824
                c-1.615-4.949-4.502-9.062-10.052-10.537c-3.788,1.347-3.153,6.611-6.696,8.101c-3.484-1.497-0.051-5.729-3.553-7.454
                c-0.865,4.196-5.152,6.227-5.388,10.831c-0.106,2.099-1.856,1.966-4.825-0.491c-2.374,0.35-2.452,3.283-4.406,4.121
                c-1.628-1.441-1.269-3.518-2.256-5.435c-2.755,0.271-3.44,3.576-5.838,4.314c-1.585-0.599-3.52-2.687-4.451,0.513
                c-1.121,3.85-4.193,5.776-7.05,7.987c-3.523,0.19-2.892-3.774-5.213-5.108c-3.116,0.977-4.746,4.24-7.716,5.745
                c-1.262,0.64-2.644,1.279-3.518,0.771c-3.689-2.146-5.688-0.146-7.552,2.591c-0.339,0.497-0.667,0.666-1.824,0.088
                c-1.819-2.251-5.036-3.822-8.693-4.682c-3.206,1.315-1.714,5.905-5.021,7.053c-3.225-3.574-3.225-3.574-6.504-0.926
                c-3.966-1.457-0.205-6.279-3.272-8.062c-2.214,0.343-2.649,2.521-2.534,3.512c0.484,4.158-2.14,4.908-5.522,5.475
                c-3.905-1.748,0.515-6.92-3.282-9.012c-1.865,3.318-4.502,4.102-7.747,1.799c-2.831,3.59-5.885,6.732-6.206,12.115
                c-3.092-2.984-5.555-5.361-8.322-8.032c-1.412,3.459-3.422,6.584-7.103,8.181c-1.475-0.493-2.974-0.994-4.587-1.534
                c-0.884-1.686,0.15-4.138-2.668-5.983c-5.442-0.165-7.431,5.52-11.413,8.683c-1.318-3.236-4.034-5.261-6.476-7.886
                c-2.017,1.277-3.754,2.933-5.296,4.738c-1.429,1.673,0.612,4.633-2.354,6.139c-3.012-2.738-0.53-7.348-3.463-10.604
                c-2.146,4.48-6.223,7.496-6.803,12.598c-3.118-1.06-2.037-4.899-3.494-5.422c-2.647-0.949-1.979-3.446-3.828-5.32
                c-3.184,5.467-8.014,9.162-11.847,13.394c-3.635-1.403-5.937-3.749-5.536-7.86c-3.561,0.887-5.642,2.421-9.808,7.168
                c-3.321-1.738-0.292-6.587-4.054-8.327c-2.353,4.014-1.133,9.159-4.153,13.306c-2.885-3.078-0.93-6.854-2.962-10.02
                c-1.996-0.384-4.498,0.281-6.764-1.015c-2.426-1.388-2.176,3.024-4.465,2.438c-2.471-1.089,0.841-4.807-3.177-5.275
                c-1.401,1.871-1.948,5.492-5.812,5.882c-0.95,0.097-1.625,1.624-1.495,2.892c0.18,1.754-0.419,3.201-1.633,4.642
                c-2.937-1.759-1.387-5.337-3.121-7.822c-1.375-1.972-4.883-3.34-3.762-6.817c-3.222-1.286-3.275-5.287-5.993-7.397
                c-3.935,3.024-1.991,9.119-6.655,11.251c-2.713-2.676-5.352-5.75-8.543-8.186c-3.172,2.667-2.421,7.01-5.373,9.383
                c-4.51-2.001-0.434-7.844-4.619-9.982c-6.054,2.316-6.385,8.199-7.49,13.84c-3.139-1.626-3.924-7.767-8.607-3.715
                c-2.927-1.412-2.682-3.805-2.757-6.192c-0.091-2.839-1.651-3.732-3.962-2.501c-3.71,1.979-3.779,2.027-5.944-1.039
                c-0.46-0.652-0.687-1.33-1.749-1.535c-3.001,0.521-3.124,3.707-4.123,5.668c-1.272,2.496-2.423,2.854-4.689,1.921
                c-2.166-0.891-4.092-1.238-5.553,1.454c-1.428,2.629-4.619,2.005-6.746,3.177c-2.036-1.511-0.869-3.478-1.242-5.165
                c-3.907-1.552-5.01,1.512-6.315,4.082c-3.331-0.699,0.04-4.918-2.927-5.519c-3.073,1.491-0.324,5.432-3.639,7.182
                c-0.669-2.358-2.211-4.418-4.582-4.616c-3.468-0.289-4.014-2.521-4.49-5.109c-2.575-0.184-2.357,2.181-2.91,3.537
                c-0.646,1.581-2.526,1.526-3.09,2.963"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1109,559.889c-0.311-3.317,0.948-6.233,2.529-8.982c1.931-3.357,3.272-9.091,3.002-13.02c-0.185-2.673,0.277-5.193,1.934-7.523
                c3.863-5.437,7.014-11.298,9.482-17.496c0.36-0.904,0.953-1.479,1.578-1.945c4.565-3.411,7.732-8.351,12.49-11.51
                c5.24-3.479,7.568-8.473,7.967-14.524c0.271-4.117,3.414-6.488,5.355-9.805c2.058,1.116,1.912,2.881,1.605,4.294
                c-1.182,5.45-1.434,10.995-1.914,16.514c-0.281,3.251-1.58,6.005-3.541,8.489c-2.789,3.535-5.85,6.873-8.412,10.562
                c-1.463,2.104-3.064,4.199-3.684,6.923c-0.446,1.959-1.491,4.145-2.962,5.446c-6.393,5.655-7.981,13.588-10.374,21.096
                c-2.135,6.699-4.332,13.422-5.031,20.484c-0.098,0.984-0.791,2.037-1.137,2.389c-2.721,2.758-2.915,6.119-2.887,9.52
                c1.412,1.52,2.78,2.991,4.145,4.458c1.774-0.846,3.081-1.243,3.916-2.835c1.324-2.521,3.076-4.848,5.465-6.493
                c0.973-0.67,2.172-1.716,3.451-1.001c2.484,1.389,4.926,0.768,7.529,0.572c3.496-0.263,6.851,0.812,8.451,4.406
                c1.166,2.617,4.488,2.869,5.541,5.482c3.536-0.592,6.943,1.013,10.506,0.548c2.335-0.305,2.674-2.794,4.512-3.288
                c-0.221-3.751-1.336-7.014-2.686-10.153c3.338-2.933,5.712-7.353,11.283-5.413c1.194-1.973,1.702-4.748,4.887-4.201
                c0.323,0.057,0.951,0.047,0.957,0.002c0.557-4.118,6.237-2.26,7.007-6.001c0.208-1.009,1.017-1.346,1.548-1.981
                c1.71-2.047,5.812-1.342,6.275-4.048c0.462-2.694,1.76-4.505,3.558-6.308c-1.519-2.871-1.748-5.967-2.691-8.529
                c-0.907-2.464-3.351-4.586-3.629-7.629c-0.028-0.316-0.009-0.694-0.529-0.993c-2.738-1.574-4.039-3.962-5.17-6.939
                c-1.108-2.921-2.482-6.095-5.327-8.065c0.775-2.562-2.079-4.884-0.5-7.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M839.5,573.889c-1.301,0.773-1.668-1.771-3.576-0.627c-4.134,2.477-6.078,1.832-8.873-1.911c-0.325-0.437-0.346-1-1.553-0.946
                c-0.839,3.285-3.557,5.744-5.021,9.039c-2.292-0.073-1.947-2.752-3.478-3.555c-2.49-0.115-5.302,0.891-6.697-2.365
                c-2.812,1.787-4.979,4.098-8.306,3.379c-0.478-0.103-1.022,0.089-1.493-0.029c-3.693-0.936-3.686-0.947-5.016,2.512
                c-0.077,0.201-0.322,0.338-0.412,0.428c-2.234,1.059-2.354-2.107-4.074-1.91c-2.35,0.271-1.479,3.046-3.08,4.035
                c-3.723-0.27-1.738-5.312-5.253-6.185c-3.387,2.659-6.479,6.21-11.057,7.134c-2.173,0.045-1.401-3.047-3.612-3.046
                c-2.209,0.001-2.033,2.397-3.521,3.058c-1.551-0.104-1.708-1.693-2.37-2.59c-0.44-0.597-0.122-1.848-1.165-2.412
                c-1.999,0.254-1.892,2.373-2.882,3.545c-1.421,1.68-4.124,2.406-4.922,1.771c-3.015-2.399-5.474-0.986-7.977,0.358
                c-3.202-3.587-4.421-4.544-7.163-5.683c-1.58,1.021-0.738,2.645-1.022,3.995c-0.427,2.029-1.917,2.804-3.419,1.44
                c-2.554-2.318-5.15-1.973-7.504-0.355c-2.129,1.465-3.507,1.137-5.066-0.57c-1.514-1.656-3.121-3.227-4.905-5.058
                c-2.917,2.796-6.577,3.806-9.005,7.118c-2.377-2.414-4.618-3.268-7.611-1.632c-5.213,2.849-10.684,1.496-15.931-0.061
                c-1.263-0.375-2.313-1.431-3.517-0.356c-3.23,2.885-6.139,1.521-9.006-0.539c-0.633-0.455-1.042-1.26-1.502-1.115
                c-2.604,0.245-3.234,2.549-5.342,3.207c-1.281-0.686-3.947-0.572-4.489-1.225c-3.042-3.667-5.522-1.037-8.171,0.172
                c-0.402,0.183-0.676,0.645-0.967,0.936c-1.885-1.151-2.943-2.893-2.957-4.848c-2.539-0.464-3.893,0.584-5.076,1.899
                c-1.385,1.537-2.989,1.276-4.494,0.462c-2.373-1.285-5.179-1.15-7.43-2.714c-3.451,2.212-4.853,6.144-8.09,8.243
                c-1.636-1.368-3.273-2.74-4.778-4c-0.552,0-1.074-0.139-1.19,0.021c-2.877,3.92-6.852,4.882-11.522,5.104
                c-4.091,0.195-7.958,1.846-11.006,4.875"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M917,328.889c-4.792-0.212-9.403,0.221-14.039,1.894c-5.432,1.96-11.029,3.544-16.959,3.703c-2.578,0.069-5.08,0.888-7.58,2.262
                c-5.407,2.974-11.381,4.856-16.904,7.983c0.215,4.559,2.405,9.016,3.732,13.585c2.453,8.442,3.684,17.09,5.806,25.559
                c0.709,2.829,0.222,5.929,1.944,8.514"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M287,570.889c3.134-0.189,5.926,0.33,8.563,2.42c2.367,1.878,5.183,3.27,7.953,4.546c10.067,4.638,17.937,12.438,26.999,18.513
                c1.805,1.21,3.582,2.35,5.971,2.638c2.631,0.317,5.329,1.306,7.479,2.931c5.136,3.883,10.828,6.37,17.012,8.04
                c2.091,0.564,4.187,1.51,6.047,2.88c4.292,3.162,9.059,5.426,13.921,7.654c7.361,3.373,14.158,7.854,20.561,12.871
                c4.775,3.742,9.217,7.955,14.516,10.969c3.823,2.174,6.337,5.677,9.714,8.727c-3.42,2.848-5.808,6.559-8.758,10.271
                c-3.569-2.43-3.714-6.783-6.311-9.611c-2.208-2.407-2.316-6.506-4.865-9.055c-3.434,2.155-0.282,6.067-3.225,8.298
                c-4.564-5.354-7.638-11.839-11.044-18.481c-0.578,3.646-4.569,3.987-5.532,6.891"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M411,511.889c2.682-0.014,5.101,0.153,7.608,1.84c2.145,1.442,5.193,1.748,7.901,2.09c2.785,0.352,5.678-0.321,8.023,2.038
                c1.921,1.933,5.645,2.189,5.468,6.032c4.374,3.035,6.146,8.487,10.527,11.459c2.612,1.772,3.127,4.82,4.869,7.119
                c1.415,1.868,2.404,3.107,5.054,3.661c2.897,0.604,6.695,1.782,6.55,6.261c4.483,4.372,7.933,9.888,13.992,12.518
                c1.668,0.725,3.089,1.707,4.073,3.445c0.993,1.756,2.56,3.094,4.938,2.553c0.476-0.108,1.343-0.189,1.444,0.011
                c1.315,2.618,4.713,3.978,4.553,7.474"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1068,225.889c4.055,3.556,3.181,7.728,1.5,12c-2.166,5.5-4.528,10.935-6.406,16.531c-0.781,2.327-1.55,4.822-1.126,7.474
                c0.674,4.218-1.047,8.278-1.063,12.494c-0.01,2.073-1.237,4.229-2.953,5.43c-3.076,2.151-4.07,5.457-4.856,8.594
                c-3.185,12.696-8.62,24.286-16.069,34.994c-0.366,0.527-0.358,1.315-0.525,1.982"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M219,166.889c-1.219,0.062-2.784-0.434-2.987,1.501c-0.809,7.692-3.385,15.192-2.949,22.995c0.375,6.705-0.428,13.363-0.86,19.984
                c-0.202,3.1,1.427,5.085,2.077,7.442c1.018,3.688,1.949,7.574,3.755,11.059c0.242,0.467,0.535,0.67,0.479,1.021
                c-1.898,11.831,2.137,23.639,0.052,35.509c-0.923,5.253,0.775,10.412,1.992,15.475c1.822,7.586,4.229,15.131,3.346,23.003
                c-0.729,6.498-0.159,13.103-1.829,19.53c-0.487,1.873,1.109,3.98-0.075,5.98"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M708.5,448.889c0.211-3.048,1.141-5.971,1.605-8.984c0.664-4.312-1.836-7.669-2.689-11.342c-3.771-1.839-7.629-3.476-11.916-1.674
                c-6.43-1.843-10.699,3.287-15.938,5.174c-2.229,0.803-3.841,3.437-5.074,5.82c-3.502,6.772-3.541,6.783-11.488,13.006
                c-2.552,1.998-2.633,3.282-0.508,6.006c1.839,2.359,3.511,4.681,3.711,7.982c0.065,1.078,1.116,1.938-0.172,3.048
                c-0.521,0.448-1.027,1.321-0.98,1.96c0.523,7.045-4.295,12.504-5.566,19.001c-0.038,0.192-0.318,0.337-0.484,0.503"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M903.5,226.389c0.333-2.167,0.579-4.352,1.02-6.496c0.773-3.767,0.312-6.73-2.33-10.15c-4.412-5.708-4.758-8.362-0.654-14.832
                c2.746-4.328,6.002-8.463,10.547-10.869c2.432-1.288,2.788-3.324,4.217-5.638c-4.893-0.091-8.879-3.381-13.801-2.531
                c-0.648,0.111-1.508,0.281-1.971-0.026c-4.221-2.8-8.958-1.938-13.527-1.917c-6.341,0.03-12.258-1.336-18.039-3.957
                c-2.988-1.354-6.199-2.338-9.441-3.163c-2.797-0.712-5.565-1.461-8.124-3.274c-2.308-1.635-5.312-2.932-8.388-2.58
                c-2.042,0.234-4.322-0.423-5.371-1.242c-4.133-3.224-9.629-4.191-13.19-8.279c-0.659-0.757-1.599-1.648-2.947-1.564
                c-2.674,0.166-4.754-1.298-6.446-3.034c-1.761-1.806-3.435-1.608-5.567-1c-3.318,0.947-6.585,1.91-8.968,4.567
                c-1.771,1.973-4.028,2.941-6.522,3.182c-2.282,0.22-4.332,1.379-5.373,2.887c-2.872,4.159-6.997,6.521-11.144,8.878
                c-5.053,2.872-9.678,6.658-15.5,7.94c-1.002,0.221-1.877,0.716-2.414,1.174c-3.408,2.91-7.215,4.667-11.49,6.138
                c-3.904,1.343-6.988,5.072-11.104,6.179c-5.852,1.574-10.944,4.479-15.956,7.63c-4.511,2.836-9.771,3.74-14.584,5.817
                c-3.274,1.414-6.521,3.02-9.931,4.16"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M787,450.389c-1.666,6.333-3.381,12.654-4.984,19.004c-1.222,4.834-2.672,9.535-4.935,14.037
                c-1.74,3.462-2.933,7.238-4.022,10.977c-0.875,2.998,1.299,4.604,4.452,4.043c3.521-0.627,6.946-1.146,8.798-4.875
                c1.818,0.949,2.668,3.27,5.24,1.906c2.547-1.352,6.391,0.119,7.986-3.576c0.053-0.121,0.657-0.098,0.961-0.004
                c3.93,1.222,8.057-0.094,12.017,0.939c0.731,0.19,0.849,0.842,0.918,1.07c0.959,3.169,1.949,4.021,5.229,1.705
                c1.646-1.163,4.317-2.222,6.84-2.227"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1233,423.889c2.894,3.526,3.622,7.39,3.127,12.014c-0.86,8.025-2.678,16-2.913,23.992c-0.312,10.635-1.33,21.373,0.751,32.001
                c0.658,3.365-0.589,6.759-1.198,9.948c-1.021,5.346-1.932,10.604-1.792,16.045c0.018,0.662-0.308,1.334-0.475,2"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1171,470.389c-0.644,1.711-2.447,16.969-1.974,18.492c2.948,9.476,2.091,19.345,3.243,28.976
                c1.137,9.503-0.738,19.224,2.567,28.59c1.046,2.964,0.702,6.726,0.013,9.909c-0.694,3.205-0.773,6.34-0.85,9.533"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M74,170.389c-3.893,3.855-6.434,8.479-5.924,13.993c1.062,11.495,0.182,23.061,1.191,34.483
                c0.596,6.751-0.076,13.062-1.211,19.533c-0.161,0.916-0.277,1.944,0.372,3.033c1.097,1.843,1.892,3.91,2.528,5.971
                c0.597,1.936,2.148,3.786,0.165,6.09c-0.742,0.861-0.12,2.896-0.12,4.617c2.185,1.128,4.4,2.401,7,2.78"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M543,875.389c-1.612-2.062-2.359-4.373-2.52-6.74c1.992-1.201,3.32-0.994,4.226,0.491c1.489-1.271,1.508-2.319,0.841-3.772
                c-1.526-3.32-3.784-6.215-4.81-9.905c-0.458-1.646-0.484-3.441-1.119-5.118c-1.045-2.764,0.065-3.674,3.382-3.459
                c0.558,0.037,1.154,0.134,1.762-1.029c-3.178-7.478-6.508-15.312-9.813-23.092c-1.317,0.256-1.614,0.826-1.989,1.605
                c-2.735,5.67-6.513,10.654-10.455,15.523c-0.614,0.758-1.972,1.229-1.477,2.485c1.589,4.036-1.502,6.508-3.01,9.52
                c-0.133,0.267-0.018,0.657-0.018,1.04c1.797,2.053,4.566-0.057,6.568,1.854c-2.025,2.725-3.516,6.026-4.622,9.58
                c-2.286,7.343-8.492,8.837-13.938,3.509c-2.108-2.062-4.654-3.938-4.008-7.492"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M140.5,110.889c0.95-2.52,1.221-5.344,3.032-7.473c0.859-1.01,0.664-2.188,0.391-3.001c-1.107-3.308-1.354-6.849-2.892-10.041
                c-0.842-1.749-1.332-3.665-2.057-5.475c-0.161-0.402-0.625-0.946-0.978-0.968c-2.742-0.168-4.66-2.72-7.604-2.535
                c0.381,4.653,2.307,8.943,3.159,13.482c0.41,2.182,0.041,4.421,0.53,6.49c0.857,3.631,2.312,7.22,5.481,9.429
                c1.625,1.132,2.4,2.448,2.381,4.09c-0.057,5.038,0.128,10.035,1.016,15.008c0.171,0.959,0.406,2.109-0.858,3.204
                c4.219,2.375,3.035,6.335,2.814,9.783c-0.409,6.382-3.079,8.203-9.399,6.446c-5.05-1.404-9.184,0.984-13.491,2.625
                c-2.06,0.785-1.887,2.947-2.091,4.927c-0.79,7.69-0.476,15.579-3.004,22.983c-2.22,6.499-4.594,12.955-8.833,18.597
                c-1.714,2.281-2.063,5.375-2.159,8.425c-0.342,10.864,1.495,21.636,1.109,32.504c-0.254,7.157-0.048,14.332-0.048,21.498
                c0,1.167-0.295,2.433,0.059,3.48c0.862,2.56,0.862,4.979,0,7.539c-0.353,1.048-0.094,2.315-0.054,3.48
                c0.72,20.851-1.592,41.652-1.01,62.5c0.035,1.26,0.213,2.583-0.995,3.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M260,354.389c4.406-4.181,5.565-8.749,4.254-15.053c-1.655-7.953-3.133-16.182-2.199-24.441c0.531-4.701,1.567-9.348,2.459-14.004
                c0.768-4.012,1.647-8.003,2.49-12.001c0.201-0.953-0.388-1.476-1.145-2.099c-2.737,0.638-2.445,3.049-2.874,5.095
                c-1.293,6.175-2.639,12.339-4.018,18.496c-0.087,0.389-0.634,0.674-0.968,1.007"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M582.5,459.389c-6.605-1.945-12.43,1.11-17.881,3.746c-3.317,1.605-5.881,1.727-8.567,0.165c-2.415-1.404-5.102-1.762-7.581-2.845
                c-3.147-1.376-6.323-0.714-8.951,1.457c-5.736,4.74-10.764,10.232-15.993,15.504c-2.013,2.028-2.029,5.434-0.535,7.978
                c9.683,16.49,19.342,32.995,29.008,49.495c1.17,1.998,2.427,3.952,3.479,6.011c1.752,3.427,4.596,5.456,8,7.034
                c2.768,1.281,6.163,2.356,7.824,4.601c2.285,3.085,3.256,7.165,4.666,10.866c0.541,1.418,1.095,2.789,2.03,3.988"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1113,925.389c0.146-3.382-2.18-5.928-3.095-8.972c-1.241-4.128-3.073-7.984-1.98-12.546c0.703-2.936,2.042-4.868,5.076-4.916
                c2.044-0.033,2.895-1.367,4.432-2.331c-1.851-3.2-4.471-5.204-6.447-7.724c-4.664-5.947-8.579-12.207-11.214-19.429
                c-1.09-2.989,0.194-3.801,1.78-4.458c1.485-0.613,3.133-1.602,4.946-1.117c2.064,0.551,3.02-1.303,4.606-1.757
                c-1.679-4.022-4.591-6.654-7.575-9.284c-1.902-1.677-4.473-3.628-3.387-6.411c1.06-2.719,4.141-1.33,6.361-1.514
                c0.902-0.075,1.644-0.08,2.153-1.701c-0.72-1.981-2.41-3.914-4.138-5.858c-1.104-1.241-2.06-2.621-3.012-3.988
                c-2.362-3.391-2.268-3.714,1.309-5.883c-1.842-4.266-5.502-7.096-8.508-10.354c1.351-1.819,2.855-0.71,4.191-0.731
                c2.152-0.033,3.438-1.334,2.441-2.99c-2.08-3.46-3.957-7.031-6.002-10.5c-2.91-4.938-5.656-9.833-7.093-15.496
                c-0.567-2.237-1.077-3.895,0.085-6.076c1.441-2.708,3.697-2.52,6.056-2.446c1.154-1.514,0.146-2.769-0.5-4.011
                c-2.101-4.034-4.692-7.76-6.703-11.901c-2.153-4.438-2.744-9.023-3.764-13.608c-1.071-4.818-1.111-9.851-2.521-14.375
                c-1.787-0.449-2.401,1.095-2.395,1.377c0.166,7.21-2.87,13.382-6.178,19.43c0.406,0.405,0.681,0.908,1.082,1.046
                c3.561,1.226,3.547,1.197,1.995,4.528c-2.063,4.432-5.05,8.305-7.509,12.496c-1.403,2.395-3.508,4.34-4.496,7.002
                c1.747,2.718,5.387-1.185,7.049,2.305c-1.262,6.453-4.988,11.809-9.635,16.28c0.141,1.417,1.337,1.267,1.603,1.308
                c5.329,0.819,5.034,0.665,3.074,5.643c-1.698,4.314-4.516,7.783-7.119,11.445c-0.894,1.257-2.244,2.217-1.925,4.328
                c0.6,1.711,2.963,2.016,3.385,2.735c2.098,5.743-1.943,8.901-2.967,12.947c-0.268,1.059-1.324,1.979-1.406,3.014
                c-0.574,7.129-4.34,12.546-9.103,17.038c0.171,2.134,1.403,1.962,2.544,1.959c1.834-0.006,3.674-0.102,5.498,0.02
                c3.389,0.225,4.34,2.209,3.029,5.489c-2.379,5.952-6.594,10.725-10.111,15.933c-0.784,1.159-0.576,2.774-2.153,3.188
                c1.258,1.952,2.864,1.194,4.37,1.392c-1.323,5.361-7.049,8.558-7.208,14.365c1.817,2.697,6.238,1.873,7.167,5.396
                c-2.632,5.887-6.971,10.302-10.628,15.188c-0.94,1.257-2.21,2.237-1.964,4.027"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M62,151.389c4.073,4.878,7.31,10.354,11.068,15.45c1.839,2.494,2.34,5.562,2.667,8.084c0.839,6.461,1.88,12.921,2.434,19.452
                c1.725,20.342,0.353,40.683,0.793,61.015c0.213,9.829-0.02,19.666,0.065,29.499c0.034,3.881-1.391,7.551-1.133,11.507
                c0.248,3.81,0.091,7.637-0.692,11.534c-0.742,3.69-0.684,7.635-1.086,11.471c-0.33,3.142-0.852,6.121-2.117,8.988"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M556.5,363.389c-6.333-1.333-12.656-2.719-19.003-3.985c-6.189-1.234-12.435-2.172-18.502-3.998
                c-7.66-2.304-15.018-0.959-21.976,2.521c-9.288,4.646-18.264,9.868-27.027,15.449c-0.938,0.597-1.992,1.013-2.992,1.513"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M896.5,379.889c-1.91-1.237-3.503-1.28-6.052-0.75c-10.771,2.24-13.798,10.152-16.597,19.204
                c-2.697,8.724-3.327,17.755-5.111,26.594c-0.823,4.078-0.077,8.751-0.907,12.918c-1.23,6.178-2.425,12.451-3.456,18.597
                c-1.146,6.841-2.332,13.663-3.845,20.444c-0.778,3.492-1.905,6.793-3.497,10.01c-4.59,9.272-5.26,19.544-6.793,29.52
                c-0.357,2.326-1.396,4.254-1.76,6.46c-1.457,8.841-3.826,17.632-4.218,26.515c-0.319,7.226-2.345,13.977-3.271,20.987
                c-1.477,11.17-2.994,22.335-4.494,33.501"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M242,113.889c0.319,4.999,2.395,9.786,5.254,13.292c3.419,4.192,2.341,7.558,1.316,11.725c-1.364,5.545-3.317,10.914-4.809,16.418
                c-1.35,4.981-0.66,9.107,1.877,12.973c1.045,1.591,1.259,2.942,1.371,4.591c0.562,8.257-1.297,16.176-3.635,23.963
                c-0.447,1.489,0.613,3.534-1.375,4.538"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M841,315.889c0.027,2.532-1.051,4.818-2.102,6.95c-0.926,1.877-1.227,3.756-0.826,5.534c1.3,5.782,2.232,11.693,2.209,17.515
                c-0.019,4.616,0.481,9.429-0.826,13.988c-0.928,3.233,0.379,6.33,0.225,9.521c-0.057,1.168,0.314,2.684,0.25,3.988
                c-0.07,1.417,2.571,2.399,0.021,3.958c-3.931-1.72-7.637-3.697-8.674-9.415c-0.776-4.276-3.523-8.273-7.299-11.011
                c-2.729-1.979-4.484-4.498-6.084-7.473c-2.898-5.396-6.281-10.331-12.32-13.212c-4.742-2.263-3.91-3.215-1.716-8.404
                c3.842-9.083,12.376-12.174,19.677-16.885c2.091-1.349,3.268-0.575,4.473,1.941c2.66,5.554,3.795,11.524,5.029,17.496
                c1.883,9.11,2.326,18.445,4.443,27.512c0.124,0.529-0.086,1.142,0.52,1.496"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1158,389.389c1.319,1.281,1.297,3.014,0.942,4.486c-1.507,6.263-1.794,12.603-2.058,19.009
                c-0.214,5.175-1.359,10.329-2.107,15.473c-0.424,2.924-0.381,5.663-0.327,8.533c0.14,7.364-0.528,14.677-1.206,22.021
                c-0.498,5.413-2.53,10.919-0.744,16.478"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M927,338.389c-2.088,2.29-2.987,5.409-3.761,8.069c-1.525,5.249-5.599,9.122-6.831,14.409c-0.152,0.659-0.377,1.336-0.397,2.022
                c-0.253,8.589-1.626,17.044-2.983,25.504c-0.104,0.649-0.357,1.495,0.895,2.24c1.391,0.151,2.929-0.826,4.795-1.251
                c1.01-5.814,2.643-11.548,3.307-17.49c0.463-4.145,3.219-6.519,6.994-7.429c3.268-0.789,6.657-0.389,8.932,2.467
                c1.775,2.23,3.832,3.899,6.561,4.406c2.791,0.518,4.464,2.325,5.42,4.583c1.74,4.11,5.339,7.107,6.584,11.466
                c0.057,0.198,0.414,0.31,0.472,0.508c0.588,2.039,3.841,1.862,3.808,3.491c-0.033,1.646,0.387,3.003,0.602,4.52
                c0.855,5.997,5.137,9.086,9.646,11.919c1.932,1.214,3.434,2.494,4.5,4.544c2.562,4.93,7.254,7.73,11.413,11.078
                c1.019,0.82,2.179,0.617,3.075,0.778c2.674,0.48,5.514-0.392,8.039,1.578c0.725,0.566,2.27,0.022,3.431,0.104
                c1.339,0.094,3.317-0.176,3.884,0.571c2.034,2.684,4.81,2.521,7.604,2.987c2.975,0.496,5.641,2.002,8.013,3.925"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1188,373.389c-0.377,12.166-1.164,24.317-1.051,36.5c0.023,2.498-0.688,5.017-1.114,7.471c-0.56,3.227-0.566,6.291-0.423,9.532
                c0.7,15.825,2.045,31.634,1.575,47.496c-0.101,3.388,0.694,6.726,1.216,9.967c1.119,6.941,0.878,13.699-0.203,20.533"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M39,206.389c-0.333,0.833-1.002,1.689-0.947,2.497c0.458,6.717-0.59,13.4-1.286,19.979c-1.095,10.354-0.637,20.703-1.053,31.033
                c-0.327,8.112,0.251,16.322,0.283,24.491c0.009,2.378,0.186,4.788-0.996,7"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1161.5,167.889c-3.368-3.335-6.854-6.514-11.502-7.995c-6.178-1.967-11.988-4.949-18.481-6.098
                c-3.932-0.696-7.265-0.544-10.015,2.595c-2.125,2.428-5.562,1.997-8.105,3.856c-2.148,1.571-4.601,3.821-7.896,2.642
                c-5.706,3.154-12.375,5.217-14.484,12.504c-0.742,2.566-1.889,5.119-3.631,6.881c-2.229,2.254-3.314,5.137-3.177,7.603
                c0.306,5.495-2.368,10.235-2.868,15.497c-0.33,3.484-1.639,6.832-1.469,10.521c0.115,2.498-1.076,5.117-2.009,7.439
                c-1.902,4.736-2.444,9.548-2.983,14.542c-0.902,8.356-1.285,16.793-3.309,25.031c-0.916,3.731-1.328,7.637-1.513,11.485
                c-0.313,6.519-2.39,12.484-5.675,17.926c-1.541,2.553-2.799,5.355-5.745,7.282c-2.646,1.73-4.163,5.184-5.691,8.262
                c-3.349,6.742-6.322,13.688-10.446,20.027"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M911,467.889c0.834-5.666,1.562-11.352,2.536-16.994c0.667-3.861,0.312-7.939,2.437-11.522c0.832-1.402,1.016-3.32,2.09-4.423
                c3.782-3.883,4.031-8.635,3.942-13.561c-0.03-1.683-0.005-3.367-0.005-5.175c8.547-1.505,14.16,2.541,18.97,8.201
                c2.19,2.578,4.645,4.647,7.465,6.57c7.453,5.082,14.144,11.104,19.561,18.407c1.878,2.531,3.085,5.343,3.517,8.495
                c0.458,3.34,1.09,6.662,1.629,9.979c1.383,8.495,1.464,17.112,3.545,25.601c1.346,5.494,2.48,11.486,1.838,17.425
                c-0.072,0.665,0.06,1.301,0.41,2.029c2.273,4.723,2.551,10.033,4.144,14.943c1.657,5.111,3.435,10.221,5.923,15.025
                c-0.927,2.312-4.426-0.52-5,2.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1280.5,666.389c-1.203,5.221-2.328,10.286-7.41,13.637c-2.416,1.592-3.898,4.683-4.046,7.865
                c-0.099,2.139-1.484,3.444-2.384,5.062c-2.086-0.154-2.895-1.232-2.646-3.062c0.333-2.445-1.147-4.562-1.13-7.003
                c0.008-1.133-0.81-2.617-2.775-3.1c-3.34,3.433-1.787,9.089-5.109,12.602c-1.146,0.104-2.346-0.306-2.942-1.048
                c-1.794-2.227-3.57-1.496-5.565-0.469c-3.629,1.866-5.201,1.369-6.438-2.502c-1.25-3.92-2.104-7.972-3.011-11.992
                c-0.169-0.745-0.729-0.978-1.056-1.537c-1.773,0.574-2.053,2.154-1.928,3.541c0.266,2.945-0.995,5.627-1.271,8.485
                c-0.235,2.442-1.377,4.894-4.694,6.252c-3.838,1.571-7.972,3.183-11.28,6.446c-7.256-2.953-3.961-10.924-7.341-16.064
                c-3.161,2.543-2.652,6.547-4.474,9.387"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M222,219.389c-1.937-4.435-1.165-8.993-0.433-13.489c0.247-1.513,0.44-2.996,0.425-4.511c-0.051-5.099,1.253-10.017,1.87-15.017
                c0.597-4.842,1.045-10.162-1.986-14.395c-2.024-2.827-1.921-5.563-1.896-8.588c0.061-7.333-0.023-14.667,0.043-22
                c0.022-2.54-0.462-4.068-3.49-4.668c-2.033-0.402-4.073-1.926-6.093-2.671c-2.77-1.022-3.413-2.607-3.467-5.161
                c-0.126-6.023,0.953-11.523,5.899-14.881c0.478-2.367-0.779-3.379-1.905-4.067c-3.625-2.215-5.29-4.612-4.397-8.033
                c0.869-3.333-0.073-6.722,0.873-10.035c0.647-2.268-1.725-2.815-2.632-4.082c-1.181,0.229-1.873,0.688-1.79,1.596
                c0.528,5.79-1.461,11.183-3.216,16.438c-1.063,3.184-0.969,6.018,0.337,8.489c1.49,2.821,1.914,5.559,1.87,8.576
                c-0.056,3.833-0.132,7.671,0.009,11.499c0.378,10.242-0.471,20.398-2.108,30.487c-0.179,1.103-0.739,2.034,0.09,3.01
                c2.219,2.612,2.561,6.049,3.995,9.004"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1130,415.889c-0.6,0.533-1.131,1.037-1.017,2.002c0.966,8.13-1.618,15.969-2.101,23.991c-0.119,1.985-0.318,4.004-0.366,6.007
                c-0.206,8.499-0.371,17-0.505,25.5c-0.041,2.687-1.242,5.021-1.967,7.514c-1.799,6.188-2.462,12.539-2.045,18.986"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1016.5,373.889c-4.764-2.514-7.399-6.834-9.469-11.514c-2.781-6.288-7.973-10.151-13.588-13.389
                c-3.236-1.867-7.221-1.867-10.945-2.05c-4.157-0.204-8.333-0.116-12.497-0.011c-1.521,0.039-3.161,0.588-4.526,0.739
                c-7.311,0.81-14.55,2.535-21.975,2.241c-1.785-0.071-3.475,0.144-3.586-2.513c-0.042-1.002-4.818-4.444-7.412-6.006
                c-0.515-0.31-0.92-0.739-1.467-1.058c-5.153-3.017-6.967-8.298-3.917-13.368c2.14-3.558,2.198-7.331,2.837-11.079
                c0.386-2.258,0.045-2.609-2.455-5.492c0.86-1.589,1.227-3.4,1.842-5.059c1.828-4.932,4.971-7.578,10.155-7.37
                c2.269,0.092,2.748-2.197,4.8-2.664c3.779,1.766,7.843,3.105,11.521-0.71c2.825,1.196,4.148,3.675,6.379,5.367
                c1.15-0.247,2.477-0.531,3.803-0.815"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M467,598.889c4.589,3.25,9.186,6.443,14.486,8.534c6.391,2.521,12.754,5.142,18.971,8.058c1.99,0.934,4.02,1.354,6.053,1.869
                c4.226,1.073,8.172,2.585,11.505,5.522c2.683,2.365,5.743,4.139,9.473,3.448c1.669-0.309,2.787,0.662,3.975,1.158
                c5.916,2.471,11.704,5.244,17.538,7.91"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M630,377.889c-9.161-0.9-18.381-1.095-27.505-2.469c-4.264-0.642-7.913-2.931-11.969-4.122c-0.956-0.28-2.121-1.163-2.522-2.411
                c-1.645-5.107-5.989-6.278-10.471-7.609c-2.001-0.594-3.885-0.328-4.933,0.711c-2.326,2.308-4.748,1.709-7.057,0.79
                c-1.196-0.477-2.408-0.361-3.59-0.749c-1.619-0.531-3.828-0.572-4.918,1.378c-1.22,2.182-3.542,3.168-5.006,5.003
                c-3.149,3.949-7.251,5.106-12.028,4.465c-4.971-0.667-9.134,1.034-13.016,3.993c-7.953,6.062-16.085,11.892-23.932,18.086
                c-2.792,2.204-5.549,3.339-9.057,2.966c-1.813-0.192-3.668,0.056-5.496-0.052c-4.126-0.244-7.684,0.843-10.522,3.999
                c-0.64,0.712-1.154,1.838-2.479,1.519"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1017.5,172.389c-0.676,1.585,1.045,2.572,1.068,4.184c-0.906,1.477-2.232,2.569-4.072,2.786
                c-1.549,0.183-3.215-0.288-4.547,1.082c0.135,1.623,0.479,3.267,0.623,4.942c0.43,4.959-0.426,6.148-5.072,5.986
                c-0.671-0.023-1.334-0.313-2-0.48c-0.905,0.81-0.933,1.87-1.032,2.997c-0.272,3.063-2.253,4.663-4.896,3.357
                c-1.676-0.828-3.597-0.71-4.712-1.933c-2.79,1.442-1.13,6.212-5.36,6.079c-2.371-0.074-4.832,0.495-6.873-1.258
                c-3.768,7.633-7.792,6.137-13.162,2.232c-2.166,1.153-4.281,2.584-6.965,2.025"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1280.5,75.389c0,4.5,0,9,0,13.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1185,155.889c-5.285-1.056-10.838,0.032-16-2"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1280.5,372.389c0.214,6.262-1.439,12.324-2.141,18.484c-0.456,4.013-0.29,8.02-0.734,12.03
                c-0.916,8.288-0.845,16.682-1.709,24.978c-0.768,7.375-2.162,14.691-3.498,21.994c-0.234,1.273-0.734,2.349,0.082,3.515"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M210.5,470.389c-0.109,3.725-0.655,7.52,2.473,10.528c0.846,0.813,1.429,1.934,2.016,2.979c2.633,4.687,3.283,4.772,9.021,4.073
                c3.904-0.477,7.82,0.15,10.981,2.93c1.494,1.314,2.804,2.573,4.971,3.137c2.447,0.638,4.562,3.09,4.829,5.378
                c0.329,2.822,2.096,4.585,3.788,5.286c3.3,1.366,4.682,3.902,5.885,6.705c2.086,4.863,3.83,9.883,9.012,12.531
                c0.656,0.336,0.981,1.316,1.488,2.039c4.497-0.723,8.922-1.357,12.565,2.386c0.976,1.003,2.291,0.825,3.475,0.033
                c1.459-0.976,2.697-0.199,4.005,0.479c3.056,1.584,6.423,2.596,9.416,4.16c4.259,2.226,6.29,6.764,8.58,10.854
                c0.768,1.371,1.127,3.893,2.486,3.822c2.782-0.147,4.935,2.502,7.479,1.592c3.075-1.099,5.309,0.1,6.959,2.146
                c2.382,2.953,6.71,4.377,7.133,8.936c0.05,0.542,0.873,1.336,1.45,1.451c3.516,0.704,5.218,3.355,7.032,6.025
                c0.754,1.109,1.727,2.429,3.454,2.016c2.694-0.644,4.681,1.204,4.797,3.027c0.181,2.851,2.053,3.869,3.749,4.918
                c5.102,3.152,9.567,7.064,13.922,11.107c1.775,1.648,4.117,2.356,6.079,2.744c2.901,0.572,4.871,1.938,5.85,4.262
                c1.051,2.494,2.912,3.344,5.114,3.93c2.303,0.613,4.415,1.893,4.871,4.051c0.747,3.533,3.354,5.342,5.652,7.443
                c4.201,3.843,6.386,8.764,7.917,14.047c0.312,1.076,0.264,2.396-0.447,3.484"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1005,462.389c0.166,0.166,0.438,0.309,0.484,0.504c1.422,5.826,1.338,18.549,0.036,24.5c-1.536,7.02-2.724,14.068,0.479,20.996
                c0.687,12.011,5.872,23.267,5.713,35.503c-0.047,3.648,2.656,7.272,3.722,11.016c0.822,2.889,2.509,5.784,1.065,8.981"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1280.5,756.389c-0.772-2.924-2.516-1.146-3.411-0.395c-0.607,0.511-1.566,1.559-1.122,2.905c0.321,0.972,0.012,2.319-0.975,2.413
                c-3.572,0.343-2.559,5.679-6.492,5.695c-0.861,0.004-1.769,1.41-1.533,2.887c0.284,1.79-1.256,2.309-2.502,3.143
                c-2.478-0.673-3.963-2.988-6.433-3.752c-1.47-0.455-2.08-2.25-2.009-3.896c0.064-1.462-0.047-2.884-1.541-3.456
                c-1.451-0.557-2.801,0.197-3.418,1.486c-0.486,1.016-0.954,1.629-2.062,1.456c-1.738-0.271-2.843,0.392-3.518,2.006
                c-0.535,1.28-1.332,2.405-2.98,1.989c-1.756-0.443-2.211-1.977-2.473-3.488c-0.168-0.971-0.092-1.998-0.016-2.993
                c0.093-1.231-0.621-2.113-1.539-2.433c-1.08-0.374-1.773,0.271-2.596,1.341c-1.154,1.505-0.703,4.267-3.322,4.636
                c-1.638,0.156-2.207-1.232-3.031-2.071c-2.833-2.883-3.324-2.837-6.027,1.026"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M804.5,324.889c-4.387-0.081-6.303,4.311-9.975,5.573c-0.354,0.122-0.5,1.26-0.504,1.927c-0.035,7.167-0.021,14.333-0.021,21.5
                c0,0.833-0.358,2.001,0.057,2.446c3.088,3.306,2.275,7.202,1.947,11.054c-0.552,6.459,0.252,12.842,2.135,18.957
                c0.848,2.753,0.711,5.359,0.845,8.044c0.218,4.389,0.665,8.716,4.053,11.961c1.083,1.038,0.685,2.721,2.263,3.368
                c1.217,0.14,2.746-0.197,4.203-0.227c3.834-0.076,4.565,0.833,5.511,4.894c0.771,3.314,1.965,6.487,2.106,9.998
                c0.094,2.354-1.645,4.206-1.119,6.505"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M852,499.889c-3.77-0.471-6.621,1.666-9.477,3.535c-4.016,2.628-8.027,5.191-12.516,6.985
                c-10.201,4.076-23.754,12.183-31.54,18.439c-2.181,1.751-3.891,3.737-5.542,5.985c-3.916,5.331-8.088,10.492-12.922,15.059
                c-1.152,1.09-2.189,2.352-4.004,2.246"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M408,491.889c1.946-0.033,3.423,1.051,5.034,1.939c0.875,0.483,1.612,1.38,2.976,1.106c5.145-1.032,8.21,2.164,10.422,5.993
                c3.313,5.736,9.039,8.402,14.147,11.845c0.576,0.389,2.103,0.808,2.317,1.177c2.442,4.209,6.755,6.732,9.452,10.547
                c1.597,2.262,3.11,2.979,5.63,3.5c5.953,1.232,11.908,3.255,15.876,8.015c2.475,2.968,5.724,4.943,8.708,5.601
                c6.741,1.485,11.86,5.279,16.87,9.361c2.621,2.137,3.253,6.105,6.549,7.951c4.172,2.339,6.253,7.342,11.491,8.588
                c3.284,0.781,5.585,3.568,7.066,5.911c4.665,0.356,9.077-2.37,12.963,1.466"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M870,498.889c0.766,2.742-1.305,4.78-2.177,6.928c-1.985,4.891-3.493,10.025-6.286,14.595c-0.987,1.615-2.183,3.25-1.088,5.503
                c1.158,2.386-1.277,4.166-1.596,6.454c-0.727,5.226-1.321,10.519-3.273,15.552c-0.964,2.486-1.27,5.283-1.542,7.973
                c-0.631,6.234-1.698,12.409-3.127,18.475c-1.674,7.109-2.39,14.297-2.862,21.525c-0.246,3.776-0.432,7.458-3.049,10.496"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M927,311.889c-2.088,1.457-1.601,4.17-2.93,6.049c-0.547,0.771-0.255,2.252-1.095,2.92c-5.845,4.645-6.128,11.631-7.398,18.046
                c-2.577,13.016-7.482,25.058-14.077,36.485c-4.921,2.946-4.719,8.977-7.869,13.101c-3.002,3.93-5.245,8.533-7.855,12.76
                c-2.627,4.253-3.057,8.809-3.566,13.164c-1.087,9.272-2.77,18.55-2.223,27.977c0.316,5.444,1.375,10.669,4.014,15.499"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M134,228.389c-0.305,2.167-0.443,4.254-0.11,6.516c0.741,5.037-0.599,10.013-1.39,14.984c-1.167,7.333-2.05,14.727-3.577,21.984
                c-1.644,7.81-0.909,15.704-1.191,23.524c-0.226,6.272,0.006,12.662,0.286,18.991c0.096,2.156-0.068,3.973-1.98,5.546
                c-4.817,3.965-6.354,9.322-6.645,15.45c-0.26,5.506-0.344,11.001-0.386,16.505c-0.014,1.778,0.196,3.592-1.257,5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M765.5,437.389c0.5-5,1.344-9.996,1.398-15.001c0.051-4.598,1.234-8.986,1.584-13.5c0.373-4.823,1.178-9.557,0.705-14.516
                c-0.404-4.248,0.891-8.642,1.293-12.984c0.576-6.205,1.895-12.296,2.662-18.48c1.279-10.325,0.299-20.743,1.882-31.014
                c0.183-1.186,0.701-2.319,1.056-3.451c-1.397-0.942-2.484-1.849-3.684,0.392c-0.627,1.174,0.487,2.981-1.396,3.555"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1135.5,821.389c1.133-0.066,2.014,0.24,3.061,0.903c1.717,1.087,3.745,2.173,5.932,1.567c4.482-1.241,9.379-0.764,13.534-3.43
                c1.403-0.9,2.98-1.254,3.027,1.458c0.017,0.966,1.128,1.138,1.94,0.972c1.025-0.211,2.047-0.548,2.998-0.987
                c2.931-1.354,5.689-3.075,9.004-3.516c2.298-0.305,3.732-2.205,5.504-3.468c5.568,1.768,5.568,1.768,5.5,7.5
                c-0.058,4.817-1.045,5.573-5.46,7.588c-6.13,2.798-13.108,2.566-19.089,5.822c-1.158,0.629-2.952,0.127-4.451,0.081
                c-2.257-0.068-4.387,0.182-6.514,2.596c1.137,0.942,2.325,1.928,3.771,3.126c-5.234,4.744-12.381,4.491-18.631,7.147
                c0.706,1.372,1.29,2.506,1.874,3.642c-1.556,1.184-2.378,3.591-5.001,2.998"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M721,694.389c-2.301-1.602,0.48-5.159-2.395-6.667c-2.484,3.952-2.484,3.952-6.605,5.167c-1.028-1.527,0.028-3.473-1-5
                c-3.854,2.002-7.412,5.372-9.503,9.006c-2.118-0.478-1.307-2.195-1.509-3.504c-0.242-1.578,0.879-3.625-1.47-4.607
                c-0.688,0.382-0.83,0.976-1.034,1.6c-1.203,3.672-2.513,4.348-5.972,2.974c-0.833-0.331-1.937-1.481-2.442-0.905
                c-3.568,4.062-5.055-1.139-7.555-1.643c-0.768-0.154-1.344-1.258-2.002-1.92c-4.498,0.17-7.518,3.709-11.258,5.1
                c-1.242-0.672-1.427-1.588-1.244-2.598c0.173-0.957-0.396-1.477-1.179-2.12c-3.001,1.434-6.278,2.581-8.2,5.649
                c-2.977-0.693-0.459-4.035-2.755-5.088c-2.453,0.148-2.196,3.072-3.733,3.965c-4.58,0.39-8.961,0.573-10.432-5.432
                c-2.57,1.881-2.761,4.862-4.981,6.744c-2.286-2.194-4.728-4.271-6.149-8.42c-1.925,4.877-5.862,7.087-6.696,11.598
                c-1.678-2.67-3.057-5.263-3.558-7.473c-0.667-2.947-2.269-4.789-3.465-7.189c-3.064,2.293-0.653,6.238-3.332,8.199
                c-5.645-1.885-3.868-7.688-5.393-11.682c-2.719,1.082-1.442,4.903-4.75,4.748c-1.566-1.246-0.316-3.955-2.398-4.998
                c-5.17,1.845-5.439,1.677-7.995-5.016c-4.25,1.091-5.864,4.811-7.996,8.352c-3.097-2.125-0.846-6.584-4.261-8.321
                c-1.645,0.119-1.418,2.745-3.75,3.05c-2.295-3.978-4.702-8.148-7.131-12.357c-2.694,1.154-2.437,4.093-4.343,5.279
                c-2.681-0.741-0.464-3.933-2.68-5.074c-2.413,0.395-3.043,3.383-5.88,4.253c-2.073-2.405-3.756-5.025-2.956-8.669"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1006,72.889c4.512,2.501,9.621,2.867,14.511,3.951c4.783,1.06,9.646,1.787,14.493,2.527c3.982,0.608,7.99,1.347,11.998,1.427
                c3.722,0.075,7.336,0.773,10.988,1.177c3.59,0.397,6.771-0.371,7.295-3.617c0.438-2.717,2.031-3.296,3.742-3.884
                c1.908-0.657,2.988-1.732,3.586-3.37c-3.731-4.521-8.636-4.326-13.613-4.246c-8.165,0.13-16.34,0.23-24.498-0.021
                c-7.675-0.236-15.338-0.915-23-1.464c-1.822-0.13-3.822-0.721-4.389-2.515c-0.49-1.551-0.481-3.508,0.889-4.962
                c0.485-0.514,0.998-1.002,1.529-1.534c0.808,0.174,1.644,0.53,2.468,0.505c13.354-0.401,26.667,0.731,40.001,1.02
                c3.666,0.08,7.363-0.844,10.988,0.055c2.031,0.503,4.547-0.307,6.009,1.954c0.522,0.809,1.337,0.334,2,0.508
                c4.032,1.057,8.351,1.069,12.037,3.437c0.59,0.379,1.632,0.053,2.466,0.053"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1280.5,195.889c-5.066,0.67-10.077,1.43-15.033,2.887c-4.654,1.368-9.482,2.072-14.462,2.246
                c-10.032,0.351-19.742-1.412-29.513-3.091c-1.358-0.233-2.33-1.038-3.482-1.562c-3.826-1.743-7.697-3.266-12.009-2.964
                c-1.473,0.103-2.476-0.579-3.517-1.497c-3.458-3.049-6.889-6.14-10.508-8.988c-4.65-3.658-9.951-5.711-15.977-5.53"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M312.5,453.889c-0.822,0.363-1.643,0.726-2.618,1.156c2.805,8.289,5.498,16.725,9.086,24.857
                c3.496,7.924,7.583,15.645,8.563,24.482c0.667,6.019,1.694,11.996,2.421,18.01c0.157,1.294-0.286,2.66-0.453,3.994"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M842,792.389c-1.714,1.955,1.027,2.835,1.104,4.495c0.145,3.183,0.937,6.333,1.389,9.506c0.947,6.655,3.641,12.553,7.484,18.016
                c1.866,2.654,1.565,3.651-1.496,4.407c-1.871,0.462-2.338,1.304-2.535,3.07c-0.478,4.281,1.148,8.117,3.198,11.416
                c2.687,4.325,4.774,9.013,7.856,13.09c-0.825,3.906-5.654-0.861-6.272,3.476c1.606,4.232,7.02,6.405,8.559,11.298
                c-1.794,1.354-3.774,0.121-5.604,1.275c-0.501,2.422,0.843,4.391,1.814,6.453c1.94,4.118,4.526,7.932,5.609,12.473
                c0.185,0.771,1.061,1.5,0.89,2.524c-0.141,0.838,0.421,1.817-0.518,2.527c-0.479,0.804-1.293,0.396-1.979,0.469
                c-2.92,0.312-4.386,1.505-3.914,3.988c0.547,2.88,0.827,5.782,2.282,8.585c2.023,3.895,3.021,8.311,4.7,12.404
                c0.506,1.234,0.244,2.363,0.432,3.527"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M464,501.889c2.441,3.131,4.751,6.047,5.271,10.469c0.349,2.972,2.851,5.793,3.841,8.996c0.492,1.594,2.405,2.51,3.792,3.652
                c2.301,1.895,5.691,1.861,7.334,5.268c1.002,2.077,4.835,1.529,7.336,2.4c2.006,0.7,3.882,1.2,5.444,2.695
                c0.592,0.565,0.984,1.309,1.971,1.56c4.026,1.026,8.172,1.927,10.51,5.96c3.665-0.105,6.11,3.264,10.001,3.246
                c1.946-0.008,4.363,1.717,6.056,3.705c2.545,2.99,5.378,5.94,9.934,5.007c1.35-0.276,2.389,0.767,2.896,1.181
                c2.592,2.119,5.446,3.325,8.521,4.587c2.669,1.095,5.322,2.051,8.092,2.773c0.665,0.173,1.584,0.111,1.961,0.536
                c3.644,4.095,9.054,3.307,13.596,4.79c2.26,0.737,3.743,1.214,3.943,3.675"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M821.5,355.889c-0.16,1-0.58,1.753-0.109,3.04c3.117,8.517,5.189,17.218,4.676,26.464c-0.555,9.982,1.431,19.595,4.826,29.035
                c1.905,5.297,2.598,10.965-2.393,15.461"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M945.5,429.889c-1.062,3.559,0.585,6.972,0.678,10.495c0.133,5.019,0.367,9.979-0.088,15.013
                c-0.373,4.128-0.713,8.33,3.335,11.585c3.368,2.709,5.732,6.697,9.172,9.277c3.438,2.581,4.771,6.188,6.306,9.672
                c1.754,3.981,2.885,8.258,4.029,12.476c0.378,1.395,0.317,3.046-0.432,4.481"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M208.5,133.889c-1.023,4.3-0.443,8.668-0.461,13c-0.032,7.403-0.809,14.695-1.871,22.024c-0.576,3.972-1.179,8.011-1.462,11.99
                c-0.246,3.452,1.255,7.025,2.244,10.5c1.215,4.272,2.055,8.651,3.048,12.986c0.23,1.003,0.715,1.839,1.503,2.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M933.5,355.889c0.861,0.75,1.839,1.168,3,0.997c2.365-0.348,4.461,0.672,6.475,1.561c2.911,1.285,5.671,2.391,9.012,2.626
                c3.421,0.242,6.908,1.74,8.003,5.819c0.083,0.311-0.078,0.925,0.023,0.959c5.183,1.767,6.769,6.924,10.017,10.511
                c3.732,4.123,9.193,5.248,13.986,7.493c4.595,2.153,9.334,3.996,13.975,6.055c1.49,0.661,3.084,1.167,3.132,3.476
                c0.021,1.021,1.497,1.647,2.88,1.519c7.611-0.703,14.029,2.91,20.451,6.079c3.795,1.873,5.703,5.329,5.622,9.906
                c-0.076,4.332-0.704,8.727,0.845,13.027c0.48,1.33,0.252,3.027-0.42,4.471"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1133,284.389c-1.113-0.944-0.893-2.607-0.844-3.491c0.4-7.166-1.492-14.342,0.67-21.561c0.908-3.036-1.15-6.404-2.387-9.423
                c-1.406-3.439-3.032-6.841-4.994-9.99c-3.091-4.958-3.657-10.578-4.276-16.054c-0.374-3.317-1.72-6.561-1.151-9.978
                c0.031-0.189,0.316-0.336,0.482-0.503c-3.377-2.297-2.597-7.219-6.125-10.002c-2.612,3.472-4.658,6.544-4.494,11.007
                c0.159,4.309-0.555,8.695-1.057,12.975c-0.988,8.403-1.023,16.64-0.005,25.042c0.44,3.624,1.267,7.285,1.142,10.977
                c-0.064,1.91,0.677,3.686,1.627,4.935c5.158,6.782,4.859,14.754,5.412,22.566c0.211,2.998-0.499,6.046,0.5,9"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M604.5,393.389c-5.003-3.592-10.29-6.385-16.482-7.591c-5.749-1.119-10.724-4.609-16.479-6.061
                c-2.837-0.716-5.061-0.609-7.551,0.626c-4.558,2.262-9.133,4.007-14.518,2.647c-2.647-0.668-5.3,0.289-8.064,1.691
                c-5.924,3.005-11.574,6.02-15.442,11.661c-0.917,1.338-2.153,2.567-3.489,3.487c-4.167,2.867-8.221,5.668-10.582,10.485
                c-1.076,2.194-3.628,3.371-5.878,4.578c-4.789,2.567-9.069,5.719-12.05,10.453c-1.762,2.798-4.067,5.144-7.463,6.023"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1107.5,163.889c0.4,1.45-0.676,2.147-1.492,3.007c-1.572,1.654-3.537,3.032-4.184,5.657c1.146,2.303,2.941,4.167,5.21,5.266
                c2.49,1.206,3.425,2.743,3.589,5.563c0.201,3.42,1.736,6.526,4.867,8.522c0.895,0.57,1.947,1.155,2.466,2.012
                c3.306,5.457,8.806,7.989,14.004,11.042c3.053,1.793,5.646,4.352,8.557,6.408c3.116,2.201,6.541,3.396,10.479,3.607
                c5.42,0.29,10.272,2.837,14.43,6.014c7.46,5.699,15.357,10.469,24.076,13.897c4.32,1.699,8.762,3.126,12.975,5.057
                c5.752,2.637,10.84,6.218,14.023,11.948"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1135.5,497.889c-0.166,0.166-0.309,0.375-0.504,0.494c-2.146,1.31-5.068,1.074-6.837,2.246c-1.858,1.232-2.875,4.078-5.183,5.213
                c-2.217,1.091-4.129,2.648-6.371,3.759c-2.993,1.483-6.63,3.313-6.627,7.788c0,1.253-1.344,1.64-1.986,2.494
                c-1.473,1.956-3.226,3.842-2.935,6.5c0.13,1.191-0.677,2.004-1.13,2.428c-4.109,3.84-5.008,9.147-6.31,14.109
                c-1.249,4.761-5.362,6.707-8.124,9.963c-0.456,0.538-1.15,0.922-1.469,1.52c-2.318,4.342-6.219,4.869-10.525,4.486"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M987.5,424.389c-0.158,2.027-1.217,3.904,0.335,6.116c0.696,0.993,0.529,3.195-0.712,4.97c-1.371,1.958-1.65,4.644-2.672,6.892
                c-0.693,1.528-0.594,3.068-0.396,4.514c0.87,6.351,2.103,12.656,2.853,19.019c0.236,2.007,1.452,2.896,2.615,3.964
                c1.914,1.76,4.165,3.182,5.938,5.062c3.713,3.94,4.224,9.008,3.443,13.947c-1.318,8.336-0.442,16.693-0.968,25.012
                c-0.632,9.998-1.161,19.984-0.961,30.004c0.017,0.83-0.31,1.666-0.476,2.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M812,925.389c0.026-1.568-0.182-3.128,0.977-4.52c1.78-2.137,3.369-4.425,4.672-7.131c-0.914-0.474-1.777-0.92-2.922-1.514
                c2.803-6.282,8.242-11.113,10.17-17.935c-4.85-4.634-4.77-4.574-0.441-10.435c1.418-1.922,3.384-3.543,4.026-5.972
                c0.522-1.976,1.001-3.97,0.034-6.003c-0.871-1.834-0.622-4.351-4.025-4.178c-1.228,0.062-1.857-1.937-1.054-3.842
                c3.288-7.791,7.804-14.938,11.501-22.504c1.154-2.363,2.364-5.053-0.396-7.514c-1.182-1.053-0.155-2.21,0.344-3.5
                c1.25-3.228,3.213-6.301,3.106-9.955c-0.062-2.094,0.704-4.656-2.993-4.58c-1.192,0.025-1.383-1.789-0.922-2.358
                c1.354-1.673,1.092-3.921,2.402-5.579c2.527-3.197,3.881-6.834,3.514-10.981c-0.073-0.815,0.68-0.997,1.008-1.501"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M171,77.889c-0.239,2.844-0.084,5.649-0.737,8.559c-0.495,2.202,1.258,5.001,2.263,7.43c3.458,8.355,5.596,16.96,6.637,25.992
                c0.673,5.841,1.113,11.789,2.752,17.543c0.723,2.538,0.684,5.419,0.8,7.989c0.194,4.326,2.646,8.576,0.784,12.987"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M447,371.389c3.877,4.817,5.931,10.136,6.109,16.497c0.29,10.343,0.415,20.717,1.844,31.009c0.318,2.29,0.034,4.661,0.05,6.994
                c0.03,4.356-0.276,8.734,0.98,13.005c0.301,1.024-0.111,2.029-0.483,2.995"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M213,342.389c4.682,4.041,5.332,10.634,9.5,15c-1.743,2.159,0.7,3.927,0.78,5.989c0.073,1.884-0.215,3.69-0.32,5.508
                c-0.312,5.387,0.935,10.657,1.005,16.003c0.013,0.947,0.407,1.502,1.04,1.994c4.285,3.334,4.107,8.426,4.9,13.022
                c0.462,2.682,1.152,5.311,1.553,7.99c0.261,1.747,1.023,3.178,2.057,4.482c3.818,4.82,6.388,10.288,8.486,16.011"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1222,358.389c1.992,2.815,1.385,6.159,0.852,8.972c-1.574,8.29-1.129,16.392,0.642,24.53c0.578,2.657,0.401,5.272,0.132,8.011
                c-0.575,5.85-1.998,11.857,1.293,17.535c0.578,0.998,0.02,2.623,0.096,3.952c0.143,2.501-0.477,5.054,0.486,7.501"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M44,774.889c0.59-2.17-1.213-3.419-2.065-4.964c-0.972-1.761-2.277-3.388-3.509-4.979c-2.229-2.879-1.78-5.203,0.628-7.369
                c-0.948-3.342-3.341-5.72-4.491-8.712c-0.433-1.125-2.166-2.972,0.922-3.553c1.021-0.192,0.373-1.586,0.488-2.427
                c0.02-0.141-0.341-0.307-0.469-0.5c-2.37-3.574-4.553-7.241-5.383-11.776c-3.18,1.386-3.324,4.255-4.149,6.771
                c-0.893,2.721-1.838,5.107,0.942,7.605c1.85,1.662,0.006,4.244-0.02,6.402c-0.054,4.469-1.271,8.748-3.766,12.592
                c-4.942-3.47-5.149-4.299-2.142-8.074c-1.581-4.791-4.128-9.108-6.381-13.838c-1.956,2.612-3.368,5.173-4.583,7.833
                c-0.581,1.272-1.808,2.272-0.173,4.122c1.108,1.255-0.101,3.653-0.884,5.352c-1.366,2.959-1.581,6.759-5.465,8.016"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M300,335.389c2.03,8.551,6.781,15.703,12.062,22.451c4.793,6.124,7.276,12.737,6.99,20.551c-0.25,6.824-0.062,13.665-0.047,20.498
                c0.005,2.224,0.035,4.442-1.005,6.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M912.5,390.889c-0.92,1.098-2.289,1.462-3.515,1.965c-3.419,1.405-4.961,4.403-4.836,7.529c0.2,5.021-2.197,9.137-3.788,13.455
                c-1.758,4.768-2.664,9.594-3.389,14.547c-0.883,6.017-1.677,12.077-3.129,17.965c-0.525,2.129-1.248,4.607-3.763,6.169
                c-2.534,1.574-4.415,4.203-6.698,6.487c1.381,4.144-0.142,8.621,1.117,12.882"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M367.5,313.889c-0.749,1.435-0.586,2.998-0.477,4.499c0.834,11.453,2.83,22.755,5.073,33.982
                c1.934,9.677,4.244,19.282,5.932,29.014c0.122,0.704,0.639,1.338,0.972,2.005"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1113,426.389c1.208,0.917,1.183,2.272,0.971,3.495c-1.945,11.224-4.9,22.16-8.265,33.068c-2.983,9.674-6.763,19.146-8.984,28.986
                c-1.273,5.642-5.577,9.47-6.812,14.93c-0.497,2.198-3.097,2.175-4.92,2.446c-2.12,0.316-4.323,0.074-6.489,0.074"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M186.5,800.889c2.392,1.144,5.098-0.07,7.838,0.938c-0.436,4.165,1.824,6.009,5.654,6.609c1.604,0.251,2.613,2.173,4.003,1.562
                c1.421,1.074,0.821,2.061,1.008,2.89c0.473,2.102-0.25,2.843-2.497,3.069c-7.39,0.744-14.467-0.732-21.496-2.609
                c-2.153-0.574-4.11-0.819-6.341,0.504c2.946,4.891,8.758,3.007,12.255,5.637c3.839,2.887,8.312,4.018,12.625,5.221
                c4.25,1.187,8.503,2.669,12.95,3.18"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1099,241.889c-4.221,0.683-3.143,4.704-3.795,7.057c-1.66,5.99-3.646,11.797-6.195,17.447
                c-3.807,8.437-5.737,17.373-7.037,26.492c-0.784,5.506-2.174,10.923-2.473,16.504"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1161.5,886.889c-0.137,1.879,1.25,1.946,2.5,1.98c2.498,0.067,5,0.023,7.5,0.018c1.141-0.002,2.373-0.175,2.545,1.974
                c-3.28,1.489-6.882,2.648-10.555,2.874c-1.833,0.113-3.548-0.188-4.984,1.16c-3.383,3.174-7.896,3.708-12.048,4.84
                c-1.874,0.51-4.058,0.545-5.938,1.209c-5.526,1.947-11.352,1.969-17.033,2.854c-1.355,0.211-2.166,0.732-2.986,1.591"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1154.5,160.389c1.447-1.916,3.125-2.646,5.514-1.53c3.321,1.551,6.824,2.551,10.479,3.079c1.377,0.199,3.022,0.595,3.952,1.507
                c7.404,7.271,16.957,9.712,26.57,11.873c4.395,0.987,8.055,2.675,10.553,6.68c4.663-0.71,9.555,0.98,14.676-1.29
                c-2.106-5.96,0.585-11.796,0.807-17.817c0.18-4.865,2.607-9.177,4.595-13.434c1.59-3.405,5.03-4.542,9.132-2.747
                c2.545,3.411,3.04,8.151,5.086,12.248c0.723,1.449,1.237,1.736,2.628,1.981c8.149,1.437,14.699,5.262,18.509,12.95"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M190,455.889c3.759,0.688,7.074,1.999,8.503,5.999c0.589,1.649,1.771,2.346,3.495,1.993c1.538-0.314,2.539,0.419,3.516,1.494
                c2.869,3.157,6.712,4.73,10.442,6.601c3.618,1.815,7.309,3.386,10.997,5.018c4.767,2.108,9.578,4.212,13.595,7.842
                c6.056,5.473,13.305,9.224,20.463,13.032c3.955,2.105,7.822,4.173,10.544,7.982c1.65,2.309,4.02,3.959,6.944,4.539"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M333,341.389c0.839,8.132-1.499,15.752-3.392,23.526c-2.188,8.987-3.603,18.023-1.82,27.514c1.215,6.47,2.105,13.222,1.252,19.965
                c-0.208,1.643-0.355,3.393,0.798,5.062c1.763-0.612,3.22-2.078,4.736-3.666c2.091,1.1,2.185,2.989,2.509,5.086
                c0.823,5.326,0.99,10.652,0.934,16.013c-0.013,1.23,0.365,2.34,0.821,3.561c1.289,3.449,2.431,7.127,1.161,10.939"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M870,525.389c0.864,0.973,1.729,1.945,2.695,3.035c-2.676,3.981-2.537,8.562-2.613,12.967c-0.109,6.326-0.184,12.656,0.301,19.007
                c0.436,5.688-1.089,11.341-1.895,16.989c-0.971,6.803-2.15,13.584-1.488,20.502"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M840,610.389c0.949,2.214,0.038,3.98-0.867,6.059c-1.053,2.418-2.26,3.139-4.659,2.549c-0.091-0.023-1.208,0.824-1.591,1.85
                c-0.996,2.67-0.834,5.34-0.885,8.043c-0.03,1.654,0.392,3.463-1.744,4.629c-1.771-1.123-1.204-3-1.23-4.629
                c-0.019-1.117-0.361-1.798-1.521-2.012c-0.961-0.178-1.556,0.355-1.982,1.023c-0.777,1.213-0.707,2.729-1.421,4.042
                c-0.396,0.729-0.242,1.758-1.084,2.464c-2.945,2.469-4.421,5.729-5.049,9.477c-0.214,1.272-1.265,2.04-2.444,2.545
                c-1.293-0.674-2.67-1.232-2.518-3.039c0.157-1.886-1.592-2.39-2.502-3.499c-3.101,2.248-3.116,5.757-3.428,9.006
                c-0.206,2.138-0.409,3.992-2.73,5.213c-2.816-1.708,0.252-5.784-3.31-7.245c-3.585,0.656-1.297,5.814-4.778,6.985
                c-3.301-2.822,1.023-8.546-3.783-10.965c-1.649,0.132-1.52,1.366-1.464,2.505c0.071,1.445-0.368,2.719-0.984,4.012
                c-0.981,2.059-2.063,2.385-3.524,0.488s-2.252-1.322-3.609,0.415c-1.17,1.499-0.123,4.72-3.418,4.595
                c-2.223-1.572-1.307-3.93-1.467-6.01c-0.137-1.786,0.601-3.793-2.492-4.127c-1.665-0.18-0.638-3.571-3.287-3.908
                c-1.362,2.268-2.408,4.701-2.223,7.535c0.122,1.865-0.135,3.43-2.9,3.56c-1.273-1.057-1.508-3.487-3.951-4.1
                c-0.682,3.655-3.268,4.579-6.65,4.029c-1.678-0.272-2.405,0.757-2.996,2.013c-0.415,0.883-1.061,1.173-2.008,1.008
                c-1.162-0.203-1.537-0.889-1.512-2.01c0.047-2.166,0.061-4.334,0.001-6.5c-0.067-2.441-0.397-4.816-2.468-6.504
                c-3.318,2.527-1.066,7.549-4.542,10.016c-1.661-0.328-1.731-2.383-3-2.963c-2.497-1.141-2.868-3.036-3.1-5.537
                c-0.225-2.423-0.366-5.338-3.408-6.481c-0.304,0.304-0.584,0.831-0.979,0.938c-2.196,0.597-2.057,3.761-4.491,4.028
                c-2.449,0.271-3.92-2.34-6.51-2.092c-1.531,0.146-1.986-1.704-2.451-2.919c-0.717-1.869-1.273-3.778-3.315-5.159
                c-2.896,2.355-2.778,6.008-3.711,9.178c-0.729,2.481-1.229,5.038-2.104,7.463c-0.189,0.525-0.6,1.733-1.913,1.57
                c-1.32-0.162-1.931-1.109-1.925-2.037c0.012-1.939-1.55-3.516-1.072-5.5c0.483-2.006-1.084-3.109-1.818-4.564
                c-4.793,1.801-3.359,7.771-7.047,10.33c-4.913-1.931-1.941-8.247-6.99-10.427c-0.785-0.148-2.055,1.198-3.643,1.166
                c-2.555-0.051-5.207,0.557-5.672-3.485c-0.168-1.464-1.344-3.343-2.83-4.519c-4.426-0.349-4.279,5.401-7.999,6
                c-1.415-0.711-2.983-1.308-3.478-3.007c-0.467-1.605-2.146-1.87-3.059-3.042c-4.094,1.884-4.122,6.3-5.223,9.627
                c-1.023,3.093-2.088,4.581-5.24,4.415c-1.003-0.053-1.376,1.025-2.494,1.101c-2.246,0.152-3.781,0.765-4.731-2.53
                c-0.829-2.873-0.956-6.43-3.909-8.592c-3.52,1.533-1.462,5.896-3.826,8.037c-3.612,0.236-3.145-3.961-6.701-5.155
                c-2.265-0.421-5.184,1.419-8.336,2.144c-2.38-1.072-4.077-2.516-4.062-5.498c0.011-2.031-1.592-3.016-3.532-3.723
                c-1.705,1.005-2.01,3.093-3.417,4.229c-0.672-0.118-1.297,0.037-2.024-0.458c-3.095-2.105-6.507-3.703-9.482-6.029
                c-3.061-2.392-3.957-2.23-6.464,0.996c-1.032,1.328-2.157,2.515-3.155,3.219c-3.189-0.412-3.968-3.544-6.083-4.797
                c-2.487,0.524-2.078,3.601-4.597,4.131c-3.422-0.914-2.399-5.838-6.061-6.968c-2.119,0.638-1.633,3.461-2.792,4.888
                c-4.486,0.164-5.361-3.236-6.43-6.64c-4.382,3.238-2.424,9.064-6.107,11.786c-2.262-0.291-2.942-2.161-4.296-3.133
                c-1.453,0.721-1.787,2.245-2.499,3.498"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1105,205.389c-1.975,2.891-1.216,6.394-1.777,9.54c-1.325,7.425-1.821,15.012-3.211,22.462
                c-0.896,4.797,0.789,9.224,2.562,13.467c2,4.78,2.059,9.813,1.252,14.501c-1.742,10.135-3.34,20.214-2.733,30.475
                c-5.394,3.215-8.357,8.807-13.093,12.555c-0.468,4.914-3.76,9.044-3.949,14.002c-0.066,1.739-0.387,3.253-1.561,4.488
                c-4.088,4.299-6.571,9.492-8.49,15.01"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M210,256.389c0.333,0.833,1.018,1.7,0.944,2.495c-0.537,5.734,1.344,11.284,1.112,17.008c-0.203,5.016,1.85,9.721,3.095,14.458
                c1.956,7.438,3.891,14.758,3.948,22.539c0.026,3.515,0.564,7.128,1.9,10.501"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M841,612.389c0.666-0.34,1.087,1.266,2.064,0.052c1.141-1.417,1.488-2.868,1.428-4.785c-2.633-1.4-5.23-3.141-8.733-3.315
                c-1.989,1.805-4.092,3.712-6.173,5.6c-2.766-1.615-0.643-5.924-4.334-6.834c-1.203,0.999-2.628,2.172-3.828,3.724
                c-1.494,1.931-2.939,1.546-4.383-0.964c-0.627-1.088-1.118-2.036-2.541-1.979c-4.543,0.185-7.553-2.055-9.518-5.989
                c-0.2-0.399-0.649-0.675-0.982-1.009c-3.186,0.734-2.621,4.593-4.963,5.977c-1.282,0.425-2.064-0.592-3.027-0.998
                c-2.426-1.021-4.19-0.137-6.045,1.48c-2.883,2.516-3.387,2.221-5.004-1.441c-0.668-1.51-0.934-3.455-3.201-4.092
                c-2.454,1.285-2.276,5.129-5.582,6.177c-4.183-2.7-8.854-2.42-13.695-1.171c-1.541,0.398-3.242,0.301-4.543-1.385
                c-1.133-1.467-1.926-3.395-4.131-4.123c-2.67,1.779-1.416,6.227-5.117,7.791c-2.799-3.164-6.473-5.405-9.699-8.221
                c-2.126,0.055-2.714,2.424-4.86,3.094c-2.532-2.018-5.835-1.813-9.115-2.22c-4.518-0.56-4.816,4.938-8.954,4.788
                c-2.959-2.125-1.166-7.188-4.67-9.703c-2.723,1.049-1.804,4.863-4.432,6.039c-2.761-0.609-5.036-3.209-8.554-1.151
                c-2.04,1.192-4.433-0.267-5.407-2.842c-1.353-0.204-2.008,0.999-2.984,1.53c-3.852,2.098-3.828,2.14-7.1-1.626
                c-3.47,1.775-3.569,6.692-7.664,7.988c-1.504-0.651-0.893-2.888-2.5-3.722c-1.938-0.379-4.254,0.264-6.828-0.904
                c-0.818,0.65-2.124,1.688-3.59,2.854c-2.683-1.922-7.439,0.092-8.543-4.744c-2.201,0.943-3.744,2.304-5.275,3.643
                c-1.732,1.513-3.369,1.59-5.011-0.022c-2.546-2.499-6.702-1.916-9.127-4.896c-0.55-0.675-2.817-0.402-3.355,1.407
                c-0.287,0.964,0.361,2.112-0.826,3.487c-4.042,0.039-8.22,0.113-10.727-4.552c-1.833-0.103-3.307,3.542-5.526,0.601
                c-0.242-0.32-1.432-0.298-1.917-0.001c-2.892,1.769-4.684,1.218-6.127-1.996c-0.725-1.612-3.688-1.464-5.881-0.021
                c-1.276,0.84-1.992,2.393-3.655,3.139c-2.4-1.385-2.181-4.675-4.266-6.096c-2.109-0.762-2.597,1.523-4.083,1.973
                c-2.545,0.771-5.011,4.012-7.528-0.031c-0.046-0.075-0.323-0.036-0.484-0.006c-3.559,0.667-6.302-1.103-8.414-2.924
                c-2.906-0.395-2.563,2.84-4.588,2.922c-2.161-0.716-2.921-3.183-4.968-4.041c-0.365-0.127-0.592,0.189-1.05,0.513
                c-1.792,1.262-0.076,5.312-3.465,4.892c-1.97-0.245-4.281-0.822-5.888-1.623c-2.032-1.012-3.285-0.627-4.597,0.303
                c-3.102,2.197-3.031,2.286-5.536-0.541c-0.468-0.528-0.996-1.004-1.496-1.504c-1.667,0-3.334-0.025-5,0.006
                c-3.052,0.057-6.254-0.601-7.161,3.962c-0.338,1.702-2.844,1.717-3.764-0.008c-1.595-2.987-3.652-2.269-5.524-0.89
                c-1.583,1.165-2.795,1.494-4.03-0.087c-1.287-1.647-3.489-1.462-4.433-0.847c-3.098,2.018-5.221,0.189-7.746-0.993
                c-0.515,0.519-1.408,1.077-1.794,1.88c-0.74,1.539-2.514,1.322-2.969,0.892c-3.732-3.533-6.702-1.371-9.551,1.119
                c-1.501,1.312-2.743,1.508-4.011-0.05c-0.695-0.854-1.508-1.209-2.516-0.973c-4.648,1.091-9.796-0.197-14.045,2.931
                c-0.166,0.122-0.958,1.075-1.458,0.058"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M804.5,147.389c-0.247-6.464-5.137-7.614-10.014-8.417c-2.186-0.359-4.215-0.7-5.99-2.079c-4.062-3.155-8.554-4.059-13.496-2.504"
                />
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M262.5,753.389c-0.167-0.166-0.313-0.361-0.503-0.496c-3.773-2.696-7.819-4.865-11.203-8.302c-2.083-2.115-3.932-3.681-3.763-6.7
                c0.084-1.494-1.073-2.664-0.97-3.997c0.165-2.12-1.7-2.69-2.436-4.005c-0.46,0-0.811-0.07-1.124,0.011
                c-3.12,0.808-1.982,5.889-5.503,5.888c-3.364,0-4.942,2.27-7.019,4.08c-0.544,0.475-1.326,0.994-1.979,0.971
                c-3.577-0.133-4.958,1.49-5.113,5.046c-0.087,2.003-3.653,2.845-5.381,1.494c-1.621-1.267-2.437-3.065-3.17-5.639
                c-9.914,1.859-20.358,0.862-30.644,5.04c4.199,3.225,8.437,3.172,12.333,3.982c2.491,0.518,5.043,0.915,7.458,1.676
                c4.906,1.546,9.87,2.492,15.016,2.451"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M165.5,489.889c1.53-1.008,3.018-2.427,4.99-1.959c3.657,0.867,7.846,0.99,10.41,4.043c2.037,2.425,4.43,3.988,7.073,5.463
                c0.769,0.429,2.28,0.632,1.98,1.942c-0.568,2.484,1.188,3.615,2.551,5.007c1.651,1.684,2.727,3.182,2.216,6.043
                c-0.406,2.27,2.144,4.826-0.082,7.441c2.495,3.219,6.442,4.587,8.447,7.969c0.153,0.258,0.923,1.027,1.412,1.005
                c7.16-0.317,10.791,6.122,16.502,8.546c2.258,4.075,7.644,5.022,9.5,9.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M332,166.889c-0.087,1.001,0.737,1.96,1.111,2.407c4.85,5.782,4.983,12.759,4.799,19.591c-0.341,12.701,1.671,25.312,1.096,38.003
                c-0.083,1.843,0.177,3.713-0.506,5.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M44,775.889c-0.362,1.926,0.802,3.388,1.492,5.004c1.687,3.949,1.572,4.188-2.484,6.51c-0.395,0.227-0.675,0.652-1.031,1.01
                c1.05,4.434,5.089,6.227,8.055,8.941c1.134,1.038,2.654,0.886,3.964,0.029c3.062-2.002,3.926-5.377,4.923-8.52
                c0.68-2.145-1.343-3.073-3-4.172c1.43-4.096,5.594-6.598,7.179-10.521c-0.47-1.351-2.184-1.346-2.061-3.4
                c1.244-3.199,4.664-5.354,7.047-9.354c3.345,5.388,7.044,9.879,5.242,15.921c-0.489,1.642,0.578,2.479,0.854,2.944
                c1.068,1.789,1.467,3.789,2.371,5.582c0.493,0.978,0.726,1.689-0.051,2.525c-2.95,3.172-1.588,6.154,0.519,8.985
                c1.948,2.616,4.163,4.993,4.555,8.507c0.154,1.377,1.201,2.625,2.427,3.505c1.157,0.83,2.455,0.949,3.475-0.021
                c3.133-2.981,4.21-7.049,5.517-10.978c0.05-0.15,0.03-0.336,0.003-0.498c-1.588-9.49-1.589-9.49,4.934-15.39
                c2.687,5.061,5.316,10.013,8.087,15.233c-0.018,1.101-2.018,1.212-2.05,3.217c2.462,3.92,5.169,8.229,8.028,12.779
                c-0.028,1.441-3.885,1.523-1.996,4.16"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M922.5,430.889c4.844,4.633,5.819,10.952,7.54,16.988c1.356,4.755,2.194,9.649,5.831,13.63c1.9,2.08,3.402,4.75,4.352,7.813
                c1.097,3.539,3.6,6.704,6.221,9.619c3.108,3.459,4.474,7.723,4.377,13.08c-3.264-1.411-7.15-0.304-8.32-4.631
                c-5.497-2.059-8.224-7.643-13.063-10.389c-3.206-1.818-6.68-3.489-10.441-4.079c-1.399-0.22-2.413,0.071-3.212-1.946
                c-0.723-1.828-3.242-2.964-5.793-2.115c-0.601,0.199-1.568,0.313-1.951-0.016c-2.959-2.545-4.691-0.337-6.514,1.52
                c0.987,1.253,0.908,3.238,0.359,3.935c-2.724,3.454-1.386,8.34-4.363,11.61c-0.166,0.182-0.021,0.646-0.021,0.98"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M367,491.389c1.167,1.334,2.194,2.83,3.525,3.971c5.133,4.404,7.535,10.355,9.438,16.541c1.676,5.445,3,10.999,4.598,16.471
                c0.143,0.488,0.335,0.984,0.46,1.513c0.585,2.483-1.915,5.245-0.801,6.853c1.481,2.135,2.151,4.47,3.246,6.669
                c1.103,2.216,1.533,4.716,2.344,7.05c0.593,1.709,2.203,3.793,0.689,5.934"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1089.5,365.889c2.633,1.033,4.312,3.409,6.909,4.685c2.173,1.068,5.082-0.229,6.591,2.315c3.769-0.744,6.656,3.141,10.5,2
                c2.9,2.858,6.309,0.936,9.5,0.99c4.279,0.072,8.916-0.743,10.546,4.997c0.197,0.696,1.014,1.282,1.952,1.006
                c2.569-0.753,3.446,2.022,5.474,2.605c2.433,0.7,3.155,2.88,3.069,5.403c-0.068,1.975,3.176,1.752,2.959,3.999"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M788.5,443.889c-0.371-1.5-1.078-3-0.026-4.53c-1.141-1.303-2.297-2.646-3.476-3.968c-2.686-3.012-3.48-3.221-6.99-1.487
                c-2.95,1.457-5.92,2.789-8.562,4.918c-4.641,3.739-9.558,7.146-14.46,10.548c-7.205,5-10.875,12.003-11.955,20.523
                c-0.1,0.784-0.717,0.983-1.04,1.538c0.825,1.467,1.74,2.927,2.493,4.466c1.339,2.738,2.904,5.103,5.445,7.082
                c5.317,4.142,9.434,9.27,10.109,16.406c0.033,0.361,0.627,0.67,0.961,1.004"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M134.5,483.389c0.297-4.121-3.118-6.79-4.183-10.447c-0.518-1.777-2.959-2.572-5.219-3.342c-3.025-1.029-7.014-2.099-7.696-6.696
                c-0.372-2.502-1.262-4.966-3.84-6.613c-2.03-1.298-1.725-5.019-5.055-5.46c-0.776-0.103-1.117-1.15-1.543-1.922
                c-2.805-5.083-6.925-7.84-12.967-7.035c-0.819,0.109-1.743,0.237-2.484-0.023c-3.167-1.114-9.71-2.369-12.005-1.919
                c-1.378,0.271-2.675,0.958-4.008,1.458"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M795,334.389c2.527,1.462,3.21,3.658,3.042,6.502c-0.511,8.657,1.281,17.051,2.75,25.534c0.669,3.862,1.263,7.969,1.118,11.96
                c-0.137,3.763,0.791,7.345,1.219,10.988c0.389,3.314,2.233,6.245,5.371,8.015"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M294.5,459.889c-0.255,4.675,1.12,9.061,4.101,12.41c3.98,4.474,8.166,8.828,12.885,12.607c4.925,3.944,6.813,9.284,7.66,15.463
                c0.688,5.017,1.108,10.127,2.695,15.07c0.704,2.193,0.692,5.007-0.341,7.449"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1147,249.389c2.973-5.282,7.786-8.864,12.045-13.658c8.112,5.661,17.729,8.77,22.859,17.713c0.925,1.613,2.455,2.672,3.12,4.436
                c1.302,3.455,5.114,2.398,7.53,3.921c0.447,0.281,1.674-0.475,2.434-0.931c2.695-1.618,5.203-3.627,8.04-4.919
                c6.957-3.168,14.048-5.052,21.487-1.092c5.92,3.15,11.931,6.145,18,8.996c5.537,2.601,11.271,4.616,17.48,5.088
                c2.471,0.188,4.77,1.319,7.008,2.437c4.402,2.197,8.83,4.342,13.246,6.509"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1089,183.389c1.211,3.22,1.393,8.683,0.521,12.505c-0.297,1.304-0.38,2.659-0.517,3.996c-0.963,9.459-1.24,19.002-4.91,28.038
                c-1.256,3.094-2.132,6.671-1.935,9.952c0.325,5.402-1.793,10.622-0.72,16.021c0.18,0.903-0.118,1.991-0.322,3.012
                c-1.277,6.415-2.031,12.905-2.16,19.476c-0.113,5.827-2.07,11.202-4.396,16.527c-3.127,7.158-5.166,14.681-6.032,22.477
                c-0.053,0.476,0.306,0.997,0.472,1.497"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M182.5,857.889c2.47,2.211,5.726,0.466,8.596,1.793c2.203,1.018,4.956,1.525,7.441,2.022c2.902,0.58,5.821,1.565,8.969,1.25
                c5.31-0.532,10.636,1.089,16.005-0.011c2.293-0.469,4.631-0.186,7.035-0.9c3.562-1.06,7.323,0.296,10.938,0.938
                c1.429,0.254,1.837,2.131,2.516,3.408"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M331,440.389c4.336,3.808,8.007,8.038,9.008,13.999c0.255,1.521,2.047,2.236,2.039,4.001c-0.007,1.326-1.055,2.119-1.153,3.492
                c-0.246,3.449,0.784,6.586,2.186,9.469c3.128,6.432,7.303,12.323,9.941,19.031c1.14,2.9,3.943,3.033,6.48,3.506
                c1.637,0.305,3.007-0.631,4.5-0.998"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1097.5,191.389c-1.149,3.749-1.186,7.634-1.621,11.486c-0.628,5.535-1.038,11.1-2.766,16.55
                c-2.758,8.699-4.824,17.619-7.088,26.47c-0.732,2.865-1.76,5.491-4.025,7.494"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M723.5,352.889c-5.458-5.604-11.324-10.646-18.488-14.025c-3.355-1.583-6.779-2.363-10.509-2.541
                c-8.381-0.399-16.646,1.302-25.003,1.072c-4.029-0.111-7.385,2.548-11.494,2.68c-2.34,0.075-3.365,2.888-3.486,5.314
                c-0.373,7.416-3.579,13.937-6.626,20.449c-0.896,1.912-3.069,3.225-4.6,4.756c-3.22-1.951-5.881-1.623-8.296,0.792
                c-2.78,2.781-5.244,5.502-5.176,10.005c0.033,2.151-1.519,4.55-2.891,6.448c-2.303,3.186-4.023,6.807-6.932,9.549
                c0.718,3.429-2.401,4.508-4.018,6.485c-1.257,1.537-2.982,1.888-4.975,2.088c-6.077,0.607-10.787,3.168-13.114,9.387
                c-0.84,2.246-2.546,4.242-4.328,6.104c-2.69,2.81-5.752,5.192-7.759,8.83c-1.604,2.906-5.669,4.145-8.824,4.373
                c-3.634,0.262-5.892,1.907-7.953,4.261c-3.884,4.434-7.556,9.019-10.082,14.45c-1.379,2.967-3.282,5.69-4.949,8.524"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M395.5,277.389c0.167,1.333-0.722,2.395,0.317,4.111c1.9,3.14,2.703,6.939,4.083,10.428c1.765,4.463,1.538,9.259,0.592,13.959
                c-0.499,2.483-0.479,4.988-0.509,7.501c-0.04,3.357,0.033,6.668-0.848,10.036c-0.651,2.493-0.365,5.34,0.373,7.962
                c0.707,2.512,0.74,5.002-0.009,7.502"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M690,925.389c-0.492-1.402,0.521-2.383,1.01-3.496c1.587-3.613,1.585-3.574-1.979-5.56c-0.31-0.172-1.167-0.142-0.938-1.638
                c3.119-4.366,9.654-6.359,12.049-12.078c-0.967-1.542-2.559-1.78-3.896-2.95c3.614-2.784,7.757-4.823,11.008-8.109
                c-1.88-5.106-6.92-3.837-10.368-5.415c1.466-3.252,3.204-6.025,5.649-8.215c1.576-1.41,2.385-3.342,2.338-5.035
                c-0.125-4.541,2.474-7.494,5.144-10.488c1.83-2.053,4.164-3.697,4.877-6.65c-1.183-1.494-3.604-1.236-5.065-3.082
                c0.893-4.852,4.084-8.822,6.078-13.77c-0.774-1.1-2.655-2.254-1.914-4.516c1.644-5.016,3.299-10.016,7-14.005
                c0.719-0.773,1.45-0.88,1.99,0.018c3.316,5.51,7.123,10.724,9.992,16.502c0.198,0.401,0.025,0.987,0.025,1.487
                c-3.396,1.071-3.82,1.967-1.98,4.988c3.342,5.494,6.799,10.918,10.221,16.391c-5.893,2.951-4.51,3.082-3.273,8.129
                c0.894,3.641,2.277,6.984,4.516,10.005c1.611,2.174,3.542,3.892,6.701,4.903c3.105-1.312,7.279-1.708,10.358-4.875
                c2.323-2.391,2.757-3.689,0.411-5.48c-1.93-1.473-4.492-2.242-4.937-5.062c-0.548-3.482,1.203-6.228,2.902-9.047
                c0.416-0.689,1.634-1.264,1.56-1.41c-1.065-2.327-2.893-1.754-3.468-3.018c-0.045-0.59,0.002-1.059,0.472-1.541
                c3.533-3.635,7.197-7.165,10.454-11.035c2.144-2.548,1.407-5.4-1.482-7.381c-0.489-0.335-0.941-0.752-1.459-1.054
                c2.335-5.723,6.675-10.303,9.005-16.013c-1.41-2.438-4.676,0.693-5.993-2.013c3.104-5.784,9.927-9.398,8.989-17.825
                c4.518,2.812,4.727,6.699,5.901,9.875c1.58,4.273,3.391,8.678,2.095,13.461c-0.34,1.259,0.378,2.331,0.549,3.496
                c0.434,2.956,1.44,5.82-0.986,8.554c-1.321,1.487-1.004,3.894-0.416,5.397c1.363,3.493,1.613,7.334,3.79,10.602
                c1.987,2.985,3.317,3.267,5.603,0.479c0.762-0.932,0.036-2.492,2.025-2.612c-1.117-1.491-1.735-2.555-2.58-3.39
                c-1.288-1.272-1.096-2.451-0.531-4.043c1.801-5.068,5.51-9.07,7.703-14.342c3.01,1.405,2.666,4.599,3.867,6.854
                c1.264,2.37,2.858,4.584,3.923,7.035c0.958,2.206,1.396,4.639,2.062,6.973"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1046,18.139c-0.5,0.417-0.963,1.139-1.506,1.205c-11.298,1.379-20.512,7.616-29.925,13.162
                c-4.702,2.771-9.428,5.707-13.351,9.553c-2.072-0.757-1.666-2.312-1.733-4.043c-6.804,0.592-12.656,2.781-17.009,8.354
                c-2.56,3.278-4.658,6.875-6.373,10.566c-3.051,6.564-7.121,12.37-11.609,17.948c-4.976,6.186-10.104,12.253-14.965,18.527
                c-5.14,6.634-11.982,8.731-20.03,8.521c-7.229-0.188-14.525-0.422-20.591,4.85c-0.652,0.566-2.285,0.856-3.957,0.236
                c-8.67-3.219-17.839-4.336-26.949-5.645c-6.361-0.914-12.364-3.243-18.479-5.065c-7.267-2.165-14.338-4.98-21.546-7.353
                c-1.005-0.331-2.312-1.311-3.478-0.068"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1280.5,803.889c-6.1,0-6.699-0.688-7.581-6.488c-0.358-2.355-0.167-4.942-1.895-7.031c-1.689-2.043-2.957-1.99-3.966,0.543
                c-0.26,0.654-1.072,1.023-1.006,1.469c0.753,5.047-2.42,7.654-6.25,9.928c1.062,4.896-0.229,8.529-5.316,10.084
                c-1.842-0.699-2.666-1.941-2.52-4.006c0.201-2.841-0.803-5.406-1.848-8.045c-0.682-1.724-1.316-2.578-3.119-2.455
                c-2.193,0.15-2.995,1.928-2.901,3.496c0.211,3.544-1.066,4.973-4.602,4.527c-2.135-0.268-2.226,1.75-2.997,2.979"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M4,558.889c1.668-1.006,3.75-1.387,3.866,1.006c0.179,3.703,1.34,7.236,1.207,10.996c-0.233,6.574,2.27,8.407,9.477,7.492
                c1.106-1.275-0.438-3.457,1.617-4.435c3.078,1.097,3.078,1.097,7.438-5.212c3.316,2.131,2.624,5.133,1.897,8.152
                c-0.613,2.549-1.697,5.135,0.383,7.493c1.614,0.09,2.531-0.582,3.141-1.981c0.827-1.894,2.192-3.014,4.477-2.529
                c1.59,0.338,2.214-1.139,2.939-2.029c0.855-1.049,1.435-2.433,3.044-1.915c1.818,0.585,2.544,2.057,2.523,3.962
                c-0.038,3.333-0.062,6.668,0.004,10c0.065,3.336-0.39,6.702,0.485,10c0.616,2.322,1.446,4.518,3.597,5.971
                c1.886-0.566,2.784-2.266,2.812-3.973c0.043-2.645,1.47-4.761,3.177-5.865c4.078-2.637,4.821-6.426,4.861-10.633
                c0.013-1.326,0.982-1.709,1.549-2.515c3.323,0.044,3.571,2.963,4.449,5.038c1.844,4.359,1.718,4.413,6.055,3.477
                c2.558-0.553,4.837,1.004,4.981,3.501c0.097,1.661-0.021,3.333,0.032,4.999c0.04,1.233,0.054,2.303,0.894,3.562
                c3.419,5.13,4.151,10.85,3.172,16.95c-0.432,2.691-0.469,5.673,2.01,7.527c2.088-0.167,1.991-1.405,1.898-2.539
                c-0.262-3.187,0.411-5.821,3.495-7.534c2.854-1.584,4.663-3.942,4.588-7.469c-0.01-0.488,0.93-0.998,1.43-1.498
                c1.5-0.377,2.625,1.394,4.63,0.223c1.37-0.8,3.529,0.842,4.969,2.701c2.46,3.18,3.919,3.052,5.771-0.492
                c0.82-1.57,1.356-2.719,3.122-2.389c1.809,0.339,1.568,2.09,2.174,3.379c0.87,1.855-0.105,4.854,2.841,5.547
                c1.088,0.257,2.428,0.278,2.947-0.988c0.448-1.094,1.33-1.688,2.131-2.575c2.047,0.629,4.446,0.933,3.9,4.093
                c-0.231,1.34,0.894,2.358,1.521,2.396c3.545,0.211,2.947,3.015,3.476,5.109c0.119,0.471-0.182,1.137,0.054,1.471
                c0.827,1.17,0.406,3.307,2.466,3.504c2.103,0.201,3.043-1.06,3.541-2.967c0.236-0.908,0.927-1.697,1.456-2.615
                c2.191,0.383,3.495,1.803,4.501,3.604"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M610,438.389c1.319,1.281,1.36,3.054,0.928,4.479c-1.513,4.996-0.109,10.875-4.443,15.006c-0.494,0.471-0.574,0.964-0.474,1.514
                c0.581,3.223-1.324,5.367-3.557,6.937c-3.76,2.642-5.091,6.356-5.382,10.57c-0.103,1.499-0.75,3.037-1.617,3.384
                c-5.587,2.232-6.843,7.657-9.498,12.086c-0.926,1.543-1.535,3.852-4.493,2.611c-1.756-0.736-3.163,0.818-3.385,2.425
                c-0.395,2.853-3.21,2.485-4.623,3.946c-1.112,1.149-2.622,0.774-4.327-0.091c0.034,4.132-5.15,6.054-4.129,10.634"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M976.5,144.389c-0.308,2.667,1.296,5.278,0.042,8.019c-0.864,1.89,1.69,2.448,1.996,3.981c-0.295,1.387-1.786,2.019-2.52,1.892
                c-3.867-0.669-4.562,1.87-4.996,4.612c-1.023,6.469-0.3,12.999-0.554,19.496c-0.059,1.497,0.297,2.953-0.381,4.539
                c-0.938,2.19-1.695,3.591-4.588,3.575c-4.613-0.026-5.253,1.075-5.007,5.888c0.261,5.118-1.044,10.037-1.987,14.973
                c-2.277,0.873-2.777-2.084-4.994-2.199c-1.807-0.094-2.859-5.274-1.984-7.765c0.141-0.401,0.795-0.616,0.949-1.018
                c2.109-5.515,3.67-11.112,1.494-16.98c-0.619-1.672-0.436-3.336-0.461-5.011c-0.133-8.938-0.121-8.938-7.01-16
                c-2.699,3.563-4.297,9.169-3.491,12.498c0.704,2.91,0.501,5.315-1.319,8.125c-1.736,2.68-3.721,3.537-6.471,3.881
                c-0.957,1.963-0.969,4.378-2.719,5.996c-2.458,0.081-3.711-5.461-7.154-0.711c-0.375,1.886,1.711,3.818,2.387,6.144
                c0.966,3.325,0.822,5.399-2.225,7.082c-0.722,0.399-1.342,0.985-2.008,1.485"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1226,181.389c2.615,3.565,5.159,7.108,9.979,8.104c1.199,0.248,2.354,0.342,3.521,0.37c3.166,0.075,6.393-0.364,9.487,0.108
                c6.162,0.941,10.541-2.309,15.403-5.322c-3.189-3.229-6.869-2.908-10.389-2.714c-4.552,0.251-6.242-0.052-9.502-2.546"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1280.5,451.889c-5.641,0.399-10.354,3.129-14.996,6.007c-3.927,2.435-3.812,6.803-4.398,10.51
                c-2.014,12.704-4.885,25.192-8.721,37.447c-0.468,1.493,0.604,3.532-1.385,4.536"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M213.5,547.889c6.648-5.334,4.804-5.174,14.057-0.975c2.899-2.362,5.864-2.492,9.5-1.182c2.779,1.002,5.823-1.602,9.187-0.66
                c1.372,3.166,4.129,4.799,7.757,4.779c2.625-0.014,4.034,1.764,4.908,3.582c1.511,3.144,3.142,5.68,7.092,5.455
                c3.145,4.264,10.481,1.949,12.546,7.984c0.153,0.447,0.656,0.984,1.452,1.043c4.448,0.322,5.833,4.437,8.507,6.968
                c2.03,1.923,2.755,4.281,2.495,7.005"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1280.5,588.389c0.657,2.791-0.818,5.307-1.097,7.99c-0.29,2.795-2.366,3.506-4.333,1.443c-2.275-2.387-4.223-2.621-6.569-0.434
                c-0.868-0.816-1.282-1.741-1.624-2.965c-0.538-1.925-0.175-4.99-3.889-4.682c-0.578,0.048-1.957-1.264-2.07-2.848
                c-0.104-1.463-1.318-2.079-2.919-2.047c-1.874,0.039-1.505,1.477-2.135,2.453c-1.05,1.631-1.853,3.375-3.684,4.817
                c-3.36,2.649-7.308,5.734-6.232,11.28c0.396,2.041-0.288,4.018-1.948,5.488c-1.693,0.41-1.821-1.199-2.468-2.023
                c-0.848-1.082-2.032-1.334-2.996-0.895c-1.021,0.465-1.806,1.448-1.566,2.925c0.371,2.289-0.033,4.419-2.13,6.077
                c-2.973-1.502-1.515-4.862-2.739-7.137c-0.602-1.117,0.146-2.541-2.054-3.125c-1.7-0.451-2.23-2.774-2.068-4.822
                c0.191-2.396-0.488-4.585-1.978-6.626c-2.563,0.586-2.991,2.417-3.021,4.628c-0.039,2.951-1.929,1.898-3.998,1.734
                c0.034-2.639,0.387-5.431-2.399-6.817c-2.472,0.719-1.94,3.187-2.381,4.644c-0.589,1.951-0.429,3.815-2.646,5.041
                c-1.149,0.635-1.958,2.223-1.558,3.898c0.539,2.258-1.269,3.221-2.498,4.5c-2.277-1.146-0.781-5.037-4.412-5.559
                c-1.118,1.377-2.27,2.881-2.091,5.059c0.097,1.181,0.048,2.376-1.499,2.477c-1.665,0.109-2.503-0.805-2.498-2.477
                c0.002-0.834-0.074-1.675,0.015-2.498c0.22-2.045-0.552-3.653-2.052-5.035c-3.258,1.246-1.851,5.145-3.98,7.116
                c-1.835-0.233-2.208-1.46-2.528-3.073c-0.996-5.026-1.132-10.098,1.671-14.43c1.91-2.952,4.939-5.188,5.576-8.832
                c3.67,1.159,1.099,4.54,3.299,5.752"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1065.5,447.889c-0.166-0.167-0.306-0.427-0.504-0.486c-3.896-1.169-7.995-1.386-11.105-4.917
                c-2.925-3.321-7.846-3.932-11.371-6.621c-0.937-0.714-1.775-1.325-3.014-1.515c-3.087-0.474-5.924-1.365-8.59-3.348
                c-2.633-1.96-8.4,0.081-9.916,2.888c-1.233,2.283-0.124,4.191,2.48,5.061c1.994,0.666,4.129-0.996,6.111,0.84
                c0.822,0.761,3.098-0.576,3.854,1.124c0.9,2.024,2.014,4.274,1.473,6.455c-1.082,4.356-1.205,8.633-0.453,13.026
                c0.364,2.131-0.992,4.064-1.641,5.934c-1.152,3.327-1.645,6.59-1.896,10.055c-0.413,5.709-0.208,11.49-2.252,17.07
                c-0.496,1.354-0.112,3.709,0.395,5.414c1.911,6.418,1.635,13.275,4.326,19.566c1.188,2.776,0.037,6.149-0.607,8.906
                c-1.312,5.608-1.117,11.11-0.094,16.512c0.439,2.317-1.064,5.104,1.305,7.037"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M92.5,163.389c-0.833,1.208-1.597,2.317-1.551,4.001c0.311,11.347-1.434,22.631-1.065,34.002c0.095,2.927,1.352,5.547,1.199,8.5
                c-0.146,2.824-0.779,5.81,0.886,8.515c0.223,0.362,0.073,0.985,0.024,1.48c-0.542,5.555,0.477,11.109,1.242,16.467
                c1.809,12.673,1.732,25.334,1.744,38.034c0.001,1.163,0.271,2.139,1.021,3"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M806,337.889c-0.053,5.259,0.477,10.484,2.055,15.482c1.973,6.244,1.178,12.695,1.831,19.029c0.229,2.219,0.336,4.502,1.192,6.454
                c2.035,4.634,0.235,9.384,0.814,14.047c0.158,1.281-1.273,2.558-2.406,3.469c-1.975,1.588-1.783,3.426-1.012,5.527
                c0.826,2.252,0.494,4.647,0.525,6.99"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M921.5,415.889c-5.074-1.568-5.496-1.283-7.521,3.491c-3.975,9.372-6.857,19.068-8.984,29.008c-0.59,2.76-1.622,5.305-3.479,7.514
                c-3.108,3.696-6.133,7.477-7.991,11.997c-0.688,1.674-2.118,2.865-3.525,2.881c-4.735,0.049-7.013,3-9.012,6.603
                c-2.249,4.052-4.573,8.041-6.04,12.489c-0.693,2.102-2.107,3.956-2.905,6.033c-0.927,2.414-3.505,2.455-5.075,3.949
                c-4.486,4.27-8.289,9.089-11.988,14.019c-1.064,1.419-1.473,3.69-3.979,3.517"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1076,188.389c1.469,1.17,1.335,3.009,0.417,3.916c-2.083,2.057-3.466,4.52-4.328,7.114c-1.386,4.168-4.333,6.393-8.089,7.97"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1131,403.389c1.827,2,0.288,4.45-0.256,5.904c-1.055,2.812-0.372,4.803,0.742,7.103c2.705,5.584,3.547,11.407,2.02,17.495
                c-0.207,0.823-0.495,1.667-0.482,2.498c0.134,8.188-0.261,16.342-0.956,24.506c-0.537,6.312,0.625,12.391,3.876,18.027
                c1.374,2.383,1.894,4.957,1.211,8.002c-0.721,3.209,0.166,6.73-1.23,9.923c0.904,0.716,1.74,1.379,2.576,2.042"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M783,369.389c-1.469-0.617-2.685-1.638-3.832-2.841c-2.002,2.341-1.505,4.608-0.096,6.279c2.222,2.634,2.31,5.32,2.041,8.571
                c-0.468,5.649,0.173,11.359-1.023,17.01c-0.332,1.569-0.32,2.939-2.09,3.482c-4.254,1.307-4.77,5.261-4.844,8.502
                c-0.17,7.518-0.415,15.015-1.167,22.496c-0.107,1.066,0.14,2.035,0.511,3.001"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M104,91.889c0,3.5,0.239,7.02-0.054,10.496c-0.492,5.847-1.344,11.715-1.676,17.52c-0.291,5.092-1.688,10.07-0.624,15.553
                c1.104,5.684,1.261,11.985-0.567,17.956c-0.566,1.852,0.134,4.021-0.58,5.976"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M95,241.889c2.287-0.165,3.538,1.95,5.676,2.121c-1.423,3.134,0.479,5.682,0.352,8.381c-0.126,2.661-0.192,5.344,0.006,7.996
                c0.464,6.222,0.128,12.275-4.604,16.931c-1.764,1.736-0.863,3.592-0.784,5.064c0.886,16.669,0.031,33.343,0.394,50.007
                c0.03,1.362-0.826,2.3-1.039,3.501"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M3.5,371.889c1.924,0.169,3.643,0.452,5.592,1.284c5.308,2.266,9.947,5.073,13.007,10.532c2.543-2.276,3.348-4.656,4.365-6.833
                c1.263-2.699,3.342-3.194,6.037-3.016c5.343,0.352,9.813,2.588,13.498,7.033c1.667,0,3.333,0,4.938,0
                c6.406,5.778,9.742,13.025,10.562,21.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M230,247.889c0.328,0.503,1.018,0.69,1.018,1.5c-0.001,6.516-0.136,13.02,0.932,19.508c1.063,6.462,1.771,12.985,2.532,19.494
                c0.534,4.562,0.71,9.187,2.53,13.493c1.279,3.027-0.103,6.002-0.074,9.006c0.138,14.166,0.555,28.357-0.149,42.489
                c-0.347,6.95,0.92,13.425,2.366,19.977c0.231,1.049,0.44,1.907,0.418,3.036c-0.143,7.331-0.095,14.666-0.062,21.999
                c0.012,2.656,1.075,5.085,2.018,7.489c2.938,7.493,2.836,15.176,2.051,23.019c-0.384,3.842,0.354,7.643,1.246,11.532
                c2.516,10.963,7.453,21.099,10.272,31.935c0.273,1.051,1.103,1.629,0.497,3.065c-1.055,2.501,0.115,5.134,2.445,5.825
                c4.863,1.442,7.439,5.714,11.382,8.256c2.297,1.482,4.479,2.977,7.067,3.908c1.889,0.681,4.133,1.205,5.417,2.56
                c2.763,2.913,6.411,4.27,9.6,6.401c3.924,2.623,7.09,6.596,12.492,6.656c0.769,0.008,1.842,1.631,2.566,2.814
                c2.37,3.869,5.834,6.623,9.94,8.529c2.656,1.232,5.024,2.832,6.999,5.006c0.798,0.879,1.523,1.76,3,1.53
                c5.044-0.781,9.114,2.177,12.048,5.168c4.076,4.155,8.365,7.036,13.936,8.356c1.717,0.408,4.116,1.094,4.844,2.074
                c3.469,4.667,9.279,5.861,13.292,9.746c3.192,3.091,8.021,4.119,12.365,3.902c2.821-0.142,5.29,0.924,8.013,0.891
                c1.584-0.019,3.146,1.125,5.021,0.393c1.531-0.599,3.12,0.578,3.886,1.518c2.308,2.831,5.637,3.807,8.61,5.391
                c4.386,2.338,8.893,4.445,11.481,9.035"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M424,548.889c5.312-0.042,9.943,2.032,14.521,4.462c3.125,1.659,5.999,3.728,9.98,3.698c2.929-0.021,5.716,1.905,7.952,3.893
                c2.942,2.615,5.765,4.379,9.962,5.322c5.017,1.129,10.374,4.062,12.917,9.207c1.155,2.336,2.066,2.161,3.655,1.393
                c2.499-1.207,4.285-2.013,5.179,1.988c0.542,2.425,2.302,2.466,4.832,1.036c3.423-1.935,7.733-1.077,10.494-4
                c2.411,1.493,3.385,3.263,4.571,4.956c1.612,2.303,3.964,2.168,5.936,0.045c3.337-3.592,3.337-3.592,7.5-0.5
                c2.181,1.619,2.926,1.357,5.042-0.962c1.102-1.209,1.999-2.873,4.865-3.261c0.425-0.107,0.834,1.111,1.589,1.729
                c2.772,2.268,3.132,2.149,5.533-0.984c1.113-1.453,2.259-3.053,4.518-1.074c1.925,1.688,5.093,0.754,7.062,2.956
                c0.424,0.474,2.189,0.092,3.422-0.312c2.722-0.89,5.077-2.516,7.907-3.314c2.37-0.668,4.656-1.496,7.055-1.213
                c2.658,0.314,4.955-1.01,7.504-1.135c1.562-0.076,2.329-1.497,3.047-2.907c1.723-3.382,2.369-3.376,5.974-2.07
                c3.356,1.216,6.837,2.206,10.481,2.024c1.482-0.074,2.627,0.34,3.487,1.533c2.19-0.448,3.224-2.52,5.044-3.938
                c2.568,2.204,6.289,1.895,8.077,5.374c0.962,1.872,2.787,1.47,4.393-0.445c2.628-3.135,2.785-3.28,5.435-0.439
                c1.343,1.441,2.513,0.941,4.046,1.114c6.022,0.681,12.053-1.049,18.037,0.329c2-0.653,2.555-2.765,4.15-4.149
                c3.336,1.481,6.76,3.003,9.963,4.427c1.692-1.286,1.462-3.068,2.39-4.281c0.313,0,0.849-0.134,0.95,0.02
                c1.959,2.965,5.259,2.316,8.043,2.914c1.163,0.25,1.999-0.299,2.619-1.881c1.204-3.072,1.525-2.946,4.867-1.553
                c4.953,2.064,6.971,1.28,9.074-3.674c1.504-0.357,3.029-0.898,4.442,0.658c1.425,1.569,3.12,1.627,4.452-0.01
                c2.09-2.572,5.442-3.84,6.671-7.427c0.775-2.265,4.561-3.12,6.28-1.464c4.342,4.18,7.68,2.249,11.101-1.062
                c0.173-0.166,0.646-0.021,0.979-0.021c6.932,4.377,6.932,4.377,12.15-2.704c4.238,2.571,8.507,5.319,13.849,4.694
                c0.841-0.099,1.875,0.474,2.489-0.498c1.296-2.047,3.711-2.275,5.509-3.496c3.356-2.279,3.905-2.148,5.488,1.51
                c0.27,0.623,0.349,1.328,0.688,2.686c5.178-1.304,11.063-0.299,14.85-5.675c0.15-0.213,0.643-0.183,0.977-0.267"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M970,137.889c0.789,4.167,4.66,4.234,7.52,5.453c8.131,3.463,16.898,4.39,25.479,6.06c6.512,1.268,12.979,2.788,19.516,3.908
                c2.062,0.354,2.938,0.939,3.043,3.076c0.1,2.012-1.277,2.685-2.571,2.925c-2.571,0.478-4.647,2.677-7.485,2.078"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1280.5,314.389c-0.412,0.627,0.965,1.97-0.998,2.042c-3.205,0.116-6.118,1.367-7.944,3.999c-2.487,3.583-6.247,5.778-9.026,8.987
                c-0.864,0.998-1.938,1.693-3.043,1.913c-4.875,0.966-7.709,4.454-10.485,8.063c-3.557,4.624-7.525,8.935-10.524,13.985
                c-0.74,1.246-1.933,1.753-3.482,1.54c-1.984-0.272-3.987,0.109-6.023-0.423c-1.17-0.306-2.904-0.793-3.944,0.42
                c-3.356,3.912-8.448,4.82-12.584,7.386c-4.102,2.543-8.399,4.813-11.87,8.167c-1.48,1.43-2.924,1.329-4.573,1.437
                c-2.684,0.175-5.368,0.336-8.04,0.685c-2.398,0.312-4.947,0.257-7.473,0.742c-6.773,1.3-12.52,3.88-17.107,9.462
                c-3.791,4.613-8.576,8.337-14.346,10.683c-3.57,1.452-6.793,3.692-10.511,4.985c-3.292,1.146-6.315,3.015-9.492,4.499
                c-3.396,1.586-9.239,7.987-11.542,14.427c-0.604,1.689-1.369,3.32-1.979,5.008c-0.717,1.982-2.006,3.346-4.013,3.992
                c-3.331,1.072-6.261,2.827-8.462,5.535c-1.412,1.738-3.472,2.496-5.096,3.901c-0.832,0.72-2.607,1.371-3.387,0.963
                c-4.496-2.354-8.181,0.076-12.057,1.602c-2.454,0.966-4.858,2.076-7.497,2.509c-3.991,0.656-6.159,2.751-7.114,6.968
                c-1.913,8.446-3.289,16.944-4.488,25.512c-0.826,5.895-1.455,12.029-5.398,17.014"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1049,513.889c0.47,1.154-1.087,1.498-1,2.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1043.5,577.889c1.252,4.646,4,8.868,3.551,14.004c-0.295,3.375-1.056,4.627-4.051,4.994c-4.07,0.498-8.153,0.872-10.065,5.475
                c-0.321,0.775-1.11,1.849-2.435,1.527c-1.675-0.407-2.758,0.721-4.009,1.485c-1.37,0.837-2.794,1.563-4.491,1.522
                c-3.756-0.091-6.852,1.427-9.5,3.992"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M323,852.889c0.5,0.5,1,1,1.502,1.502c0.027,1.717-2.768,1.082-2.502,2.998c-0.167,0.334-0.039,0.762,0.506,0.985
                c6.157,2.522,12.219,5.354,18.541,7.368c3.066,0.977,6.644-0.18,9.958,0.629c2.125,0.518,4.022,1.197,5.54,2.98
                c1.647,1.937,3.76,2.921,6.376,3.781c4.672,1.535,9.077,4.095,13.878,5.7c-1.203,2.199-2.757,2.073-4.298,2.062
                c-6.145-0.045-11.878,1.697-17.521,3.939c-0.909,0.361-1.253,1.133-2.252,1.679c1.765,2.308,5.219,1.548,6.772,3.874
                c-0.637,1.619-3.138,1.062-3.538,2.959c4.739,5.188,10.625,7.281,17.527,7.727c2.376,0.152,5.792,0.609,6.58,4.302
                c0.192,0.9,1.207,1.687,2.431,1.512c3.931-0.561,5.587,2.049,7,5.003"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M427,313.389c-0.156,2.488,0.82,9.015,2.04,10.975c2.453,3.943,2.238,8.161,2.018,12.528c-0.251,4.987,0.06,10.002-0.137,14.994
                c-0.052,1.323,0.537,2.34,1.141,2.941c6.268,6.242,6.67,14.064,5.822,22.049c-0.293,2.759,0.186,5.139,1.159,7.495
                c1.251,3.031,1.845,6.364,3.958,9.017"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M335,232.389c-2.415,4.518-1.92,9.415-0.868,13.97c1.61,6.968,2.823,13.973,4.121,20.983c0.55,2.97,0.472,6.086,1.516,9.126
                c0.96,2.795,0.729,6.338-0.316,9.404c-0.746,2.188-0.888,4.2-0.602,6.535c0.274,2.236,0.071,4.583-0.634,7.043
                c-1.443,5.034,0.48,9.706,4.284,13.438"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M458.5,326.889c-2.596-0.96-4.834-2.621-7.476-3.567c-3.953-1.416-4.136-2.112-3.524-6.933"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M246.5,363.389c-0.307,2.053,0.221,3.653,1.358,5.583c2.766,4.696,4.586,9.899,5.12,15.418c0.144,1.486,0.168,3.015-0.002,4.495
                c-0.891,7.733,1.106,14.526,5.391,21.09c3.032,4.646,2.199,10.19,0.634,15.413"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1195,261.889c6.326,3.346,12.693,6.642,19.078,9.844c6.489,3.254,13.168,6.803,20.922,6.152c2.078-0.174,3.726,1.033,4.408,2.546
                c2.115,4.69,6.723,6.66,10.224,10.051c4.239-1.292,8.253,0.589,12.36,1.444c5.697,1.186,11.338,2.644,17.012,3.948
                c0.88,0.203,1.401,0.61,1.496,1.515"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M782,378.889c2.689,2.347,4.318,5.405,6.083,8.452c1.687,2.911,0.304,6.217,1.837,9.091c0.455,0.854,0.176,2.001,1.029,3.001
                c2.801,3.282,3.51,7.221,3.065,11.458c-0.544,5.179,0.253,10.018,4.482,14.008c-3.642,3.349-2.034,6.933-0.997,10.491"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M51,435.389c5.333-3.026,10.682-0.771,15.994,0.039c2.966,0.452,4.03,3.613,6.437,5.075c2.285,1.389,3.798,3.811,3.074,6.887
                c-0.037,0.158-0.071,0.436,0.005,0.484c4.165,2.646,3.758,9.241,8.562,10.791c2.656,0.857,3.218,2.609,3.779,4.275
                c0.536,1.587,1.376,2.25,2.654,2.401c2.419,0.286,4.743,0.396,6.04,3.024c0.582,1.179,1.993,0.566,2.95,0.479
                c8.28-0.759,10.026,2.682,16.12,7.91c3.181,2.728,3.629,5.586,3.384,9.135"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M3.5,320.889c3.739,2.659,6.854,7.266,7.511,10.998c0.267,1.512,0.658,3.001,0.989,4.502c0.608,2.757,2.226,4.432,5.001,4.454
                c5.302,0.042,9.287,3.512,14.006,5.024c1.394,0.447,2.545,1.558,2.881,2.559c1.139,3.39,3.245,5.388,6.596,6.512
                c2.943,0.987,3.083,4.238,4.517,6.451"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M246.5,368.889c-0.749,1.435-0.489,2.988-0.492,4.5c-0.017,8.167,0.055,16.334-0.049,24.5c-0.028,2.204,0.485,4.112,1.525,6.009
                c4.086,7.454,6.474,15.803,11.951,22.544c0.489,0.603,0.065,1.947,0.065,2.947"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M528,925.389c-0.498-2.525,0.871-4.526,2.035-6.479c2.724-4.566,5.583-9.054,8.514-13.489c0.482-0.729,0.34-1.352,0.461-2.04
                c-0.515-1.482-2.113-0.785-3.514-1.146c2.821-6.374,8.602-10.568,9.75-17.068c-1.603-1.808-4.297,1.115-5.243-1.812
                c1.323-4.859,5.29-7.992,7.994-11.967c1.094-1.608,2.324-3.07,3.075-4.97c1.463-3.698,1.501-4.008-2.064-6.043
                c-0.395-0.226-0.675-0.652-1.189-1.167c2.305-4.18,6.258-7.387,8.105-11.85c1.635-3.951,2.012-8.463,5.864-12.273
                c1.165,5.391,3.451,9.607,5.775,13.77c1.224,2.191-0.473,3.695-0.656,5.643c1.354,1.615,2.725,3.252,4.13,4.93
                c-0.241,1.655-3.498,2.129-1.209,5.096c2.404,3.115,4.083,6.918,6.238,10.236c-0.072,2.628-3.699,3.139-3.157,6.146
                c0.37,2.05,1.165,3.613,2.775,5.16c3.76-2.04,6.182-5.262,7.812-9.177c1.062-2.548,0.825-2.88-2.488-4.025
                c-0.401-0.139-0.676-0.64-1.204-1.168c1.54-4.559,6.024-7.126,8.204-11.037c-0.031-2.276-2.537-1.302-3.062-2.938
                c3.431-4.296,6.926-8.673,10.388-13.007c-0.759-1.145-1.465-1.318-2.335-1.353c-0.672-0.026-1.334-0.306-2.226-0.528
                c2.875-6.023,9.823-8.664,12.082-15.519c4.745,3.154,5.618,8.652,9.18,12.113c-0.241,1.654-4.033,2.511-1.158,5.097
                c0.34,0.306,0.903,2.422,1.244,2.729c2.292,2.062-0.224,3.162-0.624,4.555c0,0.248-0.073,0.489,0.011,0.571
                c2.381,2.343,4.538,5.696,8.457,4.411c3.965-1.3,6.197-4.884,7.907-8.461c0.541-1.131,0.873-3.287-0.882-4.428
                c-1.565-1.018-1.403-2.29-0.467-3.492c1.349-1.73,1.809-3.984,3.448-5.548c0.507-0.484,1.109-1.421,0.961-1.954
                c-1.294-4.672,2.332-7.015,4.451-10.049c2.2,0.582,2.547,2.653,2.507,4.028c-0.275,9.4,3.577,17.847,6.375,26.416
                c1.178,3.61-0.338,4.213-2.197,5.519c1.671,4.659,3.325,9.273,4.871,13.582c-1.468,1.633-2.979-0.611-4.182,1.386
                c1.639,4.428,4.76,8.237,7.773,12.074c1.561,1.983,3.076,3.813,2.273,6.251c-1.774,1.604-3.839-0.053-5.818,1.736
                c0.659,2.729,1.539,5.688,2.984,8.553c3.089,6.122,5.925,12.373,9.099,18.449c0.804,1.538,0.902,2.969,0.929,4.537"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M730,463.889c-6.218,3.293-11.201,8.01-15.496,13.503c-6.569,8.399-13.342,16.683-22.049,22.935
                c-12.729,9.142-24.863,19.146-38.458,27.058c-2.784,1.62-5.394,3.695-9.011,3.088c-0.566-0.095-1.258,1.049-1.996,1.396
                c-7.353,3.46-14.283,7.831-22.003,10.483c-4.933,1.695-9.97,3.098-14.992,4.52c-4.978,1.411-10.01,2.633-14.987,4.046
                c-1.718,0.487-3.342,1.306-5.008,1.972"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M115,94.889c-1.312,9.298-1.167,18.661-0.934,27.999c0.175,7.007,1.544,13.957,1.438,21.001c-0.032,2.166-0.005,4.333-0.005,6.5"
                />
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M38.5,425.889c2.052-2.178,5.025-2.27,7.504-2.327c3.64-0.084,6.772-2.625,10.499-1.684c4.266,1.077,8.666,1.329,13.559,1.646
                c3.571-2.461,8.324-3.895,12.948-5.607c0.488-0.181,1.257,0.118,1.47-0.534c1.371-4.204,5.528-1.866,8.063-3.423
                c1.065-0.654,2.878,0.633,3.317,1.069c2.461,2.448,5.15,3.299,8.549,4.203c4.805,1.277,9.188,4.733,11.557,9.673
                c1.165,2.429,2.479,3.487,5.447,4.301c4.475,1.226,8.862,4.167,8.586,10.183c-0.065,1.423,0.774,2.442,2.001,2.444
                c5.474,0.008,6.344,4.13,7.585,8.514c7.026-1.002,9.968,4.445,13.373,9.074c1.581,2.148,2.977,3.721,6.065,3.083
                c1.516-0.313,2.327,1.229,3.1,2.825c1.605,3.312,5.573,4.374,8.247,1.919c1.959-1.8,4.07-1.757,6.146-2.294
                c2.066-0.533,3.56-2.419,5.99-2.119c2.134,0.263,4.327,0.055,6.764,0.055c5.211,7.149,11.02,14.035,17.099,21.093
                c2.11-2.259,5.463-0.72,8.13-2.093"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1151.5,285.389c-3.158,3.309-4.704,7.791-8.036,11.123c-3.646-4.656-2.771-10.512-4.312-16.523
                c-5.726,4.859-9.517,10.545-14.064,15.481c-1.523,1.653-2.879,2.802-5.059,3.508c-4.389,1.422-4.994,5.753-5.957,9.43
                c-1.598,6.098-2.309,12.449-4.185,18.446c-2.185,6.984-5.14,13.735-7.923,20.521c-1.38,3.364-3.958,5.941-6.465,8.514"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M923,487.889c-1.01-2.367-2.949-4.038-4.334-5.922c-1.65-0.381-3.207,0.466-4.682-0.555c-2.062-1.426-4.326-0.288-5.917,0.598
                c-4.116,2.29-8.155,4.617-12.521,6.488c-4.271,1.829-5.895,6.308-6.325,10.414c-0.334,3.188-1.618,5.127-3.735,6.961
                c-0.666,0.577-1.25,1.151-1.574,2.074c3.207,2.41,6.755,1.135,10.076,0.82c1.547-0.146,1.808-2.547,3.465-3.465
                c4.834-2.677,9.078-3.702,15.512-2.26c1.242,0.279,1.932,1.137,3.061-0.131c1.447-1.623,3.629-1.963,5.442-1.416
                c2.29,0.689,3.584,0.148,5.091-1.556c1.613-1.824,4.344-1.571,6.514-2.354c1.219-0.44,3.32-0.031,4.916,0.353
                c7.564,1.822,7.603,1.846,12.983,8.971c1.883,2.494,4.097,4.563,6.514,6.497c2.361,1.89,4.103,4.142,3.534,7.485
                c-0.189,1.112-0.267,2.423,0.994,2.966c2.516,1.085,2.938,3.122,3.018,5.529c0.08,2.441,1.568,4.109,3.458,5.516
                c1.64,1.22,3.086,2.614,2.511,4.985"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M873,529.389c3.982,2.006,2.272,5.994,2.872,9.025c1.338,6.763,2.075,13.656,2.959,20.496c0.554,4.287,1.476,8.384,2.506,12.395
                c1.391,1.097,2.833,0.478,4.165,0.547c1.541,0.082,2.871,0.12,4.042,1.5c2.135,2.513,4.7,4.396,7.916,5.641
                c3.34,1.291,4.219,4.954,5.457,7.932c1.121,2.694,2.319,5.34,3.583,7.965"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1163.5,290.889c5.01,0.656,6.82,4.645,8.452,8.52c3.296,7.826,6.899,15.557,8.573,23.975c0.215,1.083,0.518,2.354,1.976,2.493
                c3.074,0.293,3.864,2.816,4.997,5.013c2.333,4.523,4.537,9.103,6.002,13.999"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M419.5,306.889c-0.82,1.333-0.586,2.578-0.039,4.015c2.937,7.715,4.886,15.507,4.738,23.989
                c-0.124,7.126,0.864,14.308,0.817,21.497c-0.064,9.833,0.112,19.669-0.049,29.5c-0.192,11.669-0.544,23.337-1.005,34.999
                c-0.224,5.669,1.402,11.454-0.962,17.001c1.801,15-1.033,30,0,45"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M989.5,41.889c2.712,3.365,2.254,7.546,1.227,10.917c-0.835,2.742-0.619,5.091,0.43,6.997c1.314,2.386,0.578,4.115-0.733,5.514
                c-1.724,1.838-2.872,3.291-2.055,6.11c0.585,2.014-1.255,3.755-2.896,4.922c-2.182,1.551-2.896,3.551-2.451,6.036
                c0.302,1.693-0.574,2.896-1.546,3.98c-1.571,1.752-3.307,3.358-4.975,5.022c-1.393,1.389-2.525,2.751-2.583,4.999
                c-0.069,2.726-2.376,4.241-4.453,5.439c-2.503,1.442-4.351,3.075-5.011,6.053c-0.797,3.607-4.569,4.069-6.953,6.01"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1135,425.389c7.274,4.321,10.564,11.269,13.114,18.962c1.37,4.135,0.831,8.016,0.38,12.037
                c-0.805,7.168-0.826,14.334,0.024,21.499c0.021,0.184,0.315,0.336,0.481,0.502"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M3.5,419.889c5.647,0.977,7.716,5.353,9.625,10.068c2.117,1.147,5.766-0.641,6.831,2.945c0.815,2.746,5.295,1.925,5.034,5.486
                c-0.06,0.815,0.855,1.479,0.986,1.432c5.165-1.856,5.818,4.967,10.02,5.177c1.511,0.076,1.26,2.275,1.398,3.405
                c0.287,2.358,0.977,3.324,3.587,3.65c2.155,0.27,4.845,0.978,5.09,4.332c0.159,2.163,2.862,2.406,3.876,3.927
                c0.421,3.799-1.093,7.862,2.054,11.078c-1.377,4.341,2.904,7.738,2,12"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M501,859.389c0.997,0.927,2.183,1.192,3.501,0.997c1.355-5.024-1.215-9.011-4.128-12.388c-2.273-2.635-3.392-5.528-4.31-8.628
                c-0.315-1.064-0.02-2.306-1.564-2.472c-1.448-0.154-2.019,0.342-2.617,1.946c-1.661,4.46-3.825,8.738-5.901,13.034
                c-1.533,3.172-2.89,6.467-5.472,9.02c-0.81,0.801-1.165,1.827-1.009,2.99"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M385.5,211.889c-0.56,2.494-2.32,4.351-3.563,6.463c-1.085,1.845-1.325,3.371,0.063,5.037"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M149,488.889c0.183,5.871,3.992,9.992,7.06,14.884c-0.015,1.044-2.134,2.456-0.982,3.532c2.923,2.731,1.333,5.35,0.2,8.119
                c-1.946-0.033-2.937-1.81-4.271-3.044c-1.306-1.208-2.622-2.068-4.467-2.627c-3.408-1.03-7.112-1.058-10.175-3.709
                c-1.727-1.495-4.514,0.628-6.853,0.942c-1.039,0.14-1.935,1.342-3.135,2.241c-1.97-1.849-4.219-2.593-6.882-2.394
                c-2.142,0.16-3.211-2.351-5.489-2.532c-2.301-0.184-3.834,1.729-6.007,1.596c-3.425-0.21-3.807-0.098-6.5,1.491"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M966.5,297.389c-1.824,2.661,1.281,2.105,2.05,2.955c4.031,4.458,9.243,4.083,14.433,2.965c1.283-0.276,2.336-0.714,3.52,0.077
                c5.297,3.535,11.303,5.034,17.496,4.899c7.174-0.157,13.322,1.226,17.527,7.588c0.306,0.462,0.984,0.986,1.473,0.97
                c4.739-0.159,6.286,3.983,9.006,6.543c2.133,2.008,4.899,2.868,7.503,3.988c8.34,3.589,16.709,7.112,25.495,9.51
                c0.154,0.042,0.424-0.064,0.484,0.016c3.76,5,9.525,5.897,14.978,7.606c1.571,0.492,3.255,1.493,4.558,2.863
                c3.814,4.017,8.104,7.581,11.479,12.021"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M641,424.389c-2.138,5.747-4.583,11.38-7.122,16.944c-1.208,2.646-1.572,5.478-2.656,8.146c-1.251,3.083-2.327,6.04-4.709,8.423
                c-0.75,0.75-1.431,1.6-1.999,2.497c-2.241,3.538-2.146,6.645,0.512,9.969c1.914,2.395,3.139,4.887,2.474,8.021"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M537,394.889c0.414,3.087-1.692,5.536-2.092,8.487c-0.969,7.137-2.165,14.271-4.914,21.01c-1.636,4.013-3.413,7.969-4.971,12.011
                c-1.687,4.377-1.488,8.62,1.477,12.491"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M258.5,908.889c-1.584,1.749-4.847,1.507-5.188,4.981c-0.12,1.22-2.578,1.798-4.311,1.509c-4.838-0.805-9.834-1.175-14.412-2.75
                c-1.62-0.557-4.028,0.44-5.264-2.104c-3.346,3.85-7.425,6.254-12.313,7.414c-3.394,0.807-6.172,2.992-9.017,4.943
                c-0.793,0.544-0.395,1.322-0.496,2.006"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M659,340.889c-0.014,7.959-0.6,15.832-2.934,23.521c-0.463,1.526-0.146,3.317-0.053,4.979c0.338,6.008-1.178,11.73-2.463,17.512
                c-0.576,2.593-1.82,5.232-0.551,7.989"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1164,384.389c1.724,2.135,1.209,4.721,0.908,6.988c-0.838,6.332-0.608,12.491,1.603,18.508c1.091,2.968,0.979,6.208,0.315,8.962
                c-1.28,5.325-2.034,10.603-1.807,16.041c0.045,1.064-0.007,2.123-0.543,2.986c-2.958,4.76-3.125,10.3-4.549,15.495
                c-2,7.299-2.395,14.986-5.428,22.02"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M172.5,525.889c4.197-6.409,4.373-7.619,2.518-16.504c-0.133-0.639-0.302-1.572,0.027-1.957c2.69-3.152-0.056-4.251-2.058-5.518
                c-2.509-1.588-5.772-5.402-6.982-8.523c-1.8-4.645-6.264-4.452-10.018-5.446c-1.246-0.329-2.657-0.116-3.985-0.038
                c-2.329,0.139-4.327-0.486-6.582-1.285c-4.032-1.429-8.175-4.445-12.952-1.788c-0.764,0.425-1.967,0.043-2.967,0.063
                c-2.661,0.051-5.514-0.598-7.5,1.996c-3.1-1.312-4.81,1.647-7.072,2.205c-3.204,0.789-7.485,2.413-10.3-0.336
                c-2.949-2.879-6.096-1.346-9.128-1.861c-0.324-0.056-0.92-0.104-0.962,0.006c-1.564,4.107-5.532,3.165-8.564,3.869
                c-1.65,0.384-2.846,1.037-3.974,2.119c-0.805,0.124-0.982-0.697-1.501-0.998c-2.358-1.361-4.961-0.943-7.497-1.004
                c-1.275-3.146-4.4-3.374-6.962-4.58c-0.916-0.431-1.713-0.43-2.536-0.391c-4.423,0.213-8.293-1.652-11.946-3.633
                c-3.165-1.715-6.073-2.898-9.637-1.125c-2.271-1.64-3.781-5.116-7.418-3.271c-2.969-4.426-7.544-3.882-11.997-4.075
                c-2.155-0.094-4.063-2.31-6.514-0.944c-0.254,0.143-0.863,0.15-0.959,0c-2.792-4.409-7.803-2.342-11.53-3.98"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M767,439.889c-2.464-2.506-4.691-5.04-6.168-8.427c-1.119-2.567-3.761-4.471-5.896-6.86c-6.171,4.037-10.441,9.501-13.982,15.76
                c-3.994,7.056-7.736,14.345-9.697,22.091c-1.5,5.926-3.561,12.047-2.756,18.436"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M315.5,219.889c-0.483,3.366,0.071,6.837,0.717,9.955c1.195,5.771,0.956,11.458,0.102,17.017
                c-0.672,4.375,0.316,8.468,0.923,12.492c0.662,4.386,2.432,8.405,0.973,13.103c-0.47,1.515,0.151,3.931,0.286,5.934"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1101.5,296.889c3.404,3.208,2.934,6.556,1.115,10.552c-1.886,4.144-4.268,8.124-4.834,12.922
                c-0.195,1.662-1.137,3.787-2.228,5.559c-3.468,5.634-4.972,11.925-5.563,18.466c-0.167,1.848-1.038,3.448-1.99,5.001"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M425,430.389c3.206,2.996,5.276,6.87,7.334,10.592c1.131,2.046,1.732,4.789,1.675,7.408c-0.044,1.999,0.158,4.017-0.042,5.997
                c-0.461,4.567,1.192,7.732,5.523,9.529c3.07,1.273,5.005,3.342,5.642,6.951c0.521,2.947,2.202,5.689,3.369,8.523"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M349,455.389c4.628,4.255,8.321,9.345,12.472,14.025c1.711,1.93,1.669,4.717,2.315,7.034c0.812,2.917,1.308,5.987,2.163,8.955
                c0.311,1.077,0.186,2.337,0.026,3.481c-0.687,4.924-0.698,4.906,2.018,9.008c2.705,4.084,4.747,8.338,4.041,13.501
                c-0.479,3.501,0.354,6.767,1.89,10.03c2.131,4.527,3.596,9.363,5.628,13.941c1.277,2.878,2.014,5.521,0.53,8.564
                c-0.384,0.786-1.614,1.299-0.73,3.034c1.633,3.204,2.455,6.821,3.233,10.406c0.452,2.082,1.692,3.794,4.402,4.119
                c2.029,0.243,4.229,0.853,5.532,2.887c0.373,0.582,0.945,1.056,1.486,1.505c2.494,2.07,5.789,0.988,8.467,2.075
                c3.233,1.312,4.555,5.553,8.527,5.931c2.94,0.279,5.588,1.445,7.952,3.072c0.989,0.68,2.04,1.391,3.044,1.344
                c1.98-0.092,3.261,0.645,4.539,2.055c1.858,2.052,3.968,3.375,6.876,4.309c3.701,1.188,6.92,4.191,10.038,6.784
                c2.602,2.164,5.04,3.24,8.575,2.563c2.947-0.562,6.124-0.567,8.562,2.302c1.057,1.244,3.055,1.702,4.914,1.073"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1235.5,815.389c1.032,0.795,3.184,0.247,2.979,2.763c-2.586,1.515-5.885,1.224-8.447,0.95c-3.305-0.353-6.947,1.051-10.056-1.179
                c-0.384-0.274-1.028-0.055-1.491,0.452c-2.467,2.701-4.889,1.205-7.514,0.082c-2.249-0.962-4.844-2.622-7.004,0.617
                c0.51,2.998,3.534,4.361,4.824,6.089c0.564,2.072-0.726,2.432-1.362,3.329c1.126,4.412,4.179,6.041,8.491,7.614
                c9.352,3.412,19.31,4.14,28.667,7.543c4.288,1.56,9.051,1.802,12.962,4.672c0.65,0.478,1.951,0.067,2.951,0.067"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M756,424.389c0.729-1.762,0.924-3.603,1.023-5.499c0.515-9.814,2.529-19.4,4.445-29.007c1.324-6.636,2.785-13.24,3.607-19.985
                c0.818-6.709,2.251-13.344,3.426-20.009c0.822-4.668,1.707-9.327,2.457-14.007c0.127-0.788,0.458-1.811-0.806-2.766
                c-2.438-0.653-4.636,0.078-7.24,1.626c-4.38,2.604-6.743,7.542-11.845,9.342c-1.451,0.512-3.081,1.109-4.568,1.804"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M749.5,345.889c0.749,1.435,0.442,2.988,0.5,4.5c0.021,0.563-0.16,0.999-0.453,1.526c-1.176,2.114-3.736-0.023-5.135,1.91
                c-0.917,1.266-1.754,3.075-4.467,2.244c-2.047-0.627-4.486,1.909-6.751,0.142c-2.95,2.284-5.612,4.484-7.159,7.695
                c-0.1,0.206-0.007,0.707-0.539,0.975c-4.738,2.386-7.061,7.282-10.98,10.527c-3.495,2.894-3.437,2.965-6.741-0.237
                c-0.993,1.388-3.132,1.868-2.774,4.219c-4.76,3.601-8.216,9.011-14.504,10.48c-0.529,0.124-1.053-0.006-1.511,0.506
                c-2.818,3.15-6.896,2.693-10.501,3.438c-2.129,0.439-4.475-0.376-6.031,2.045c-1.01,1.568-3.264-0.702-4.58,1.217
                c1.064,2.742,2.969,3.787,6.122,3.928c4.199,0.188,4.79,1.142,5.005,4.886"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M284.5,795.889c-1.703,1.559-3.592,2.365-6.007,2.053c-1.448-0.188-3.272-0.686-3.769,1.661c0.368,0.323,1.045,0.817,0.718,1.89
                c-11.94,0.928-23.679,5.062-35.942,2.896c-4.395,1.816-9.678,1.02-13.478,4.523c-0.831,0.767-1.689,0.309-2.522,0.477"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M269,115.889c0,7.833-0.018,15.667,0.042,23.5c0.004,0.504,1.083,0.694,0.89,1.974c-1.348,2.169-4.122,3.143-6.492,4.416
                c-1.615,0.867-1.867,2.662-1.359,2.984c2.883,1.832,3.106,5.078,4.684,7.451c1.664,2.502,2.223,5.104,3.316,7.413
                c-0.657,2.14-2.717,0.555-4.018,2.373c0.665,1.499-2.198,4.92,2.936,4.973c0.944,0.01,0.994,1.395,1.028,2.416
                c0.154,4.538-0.819,8.836-2.527,13.001"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M847.5,92.889c-1.376,3.188,0.749,5.084,3.044,5.872c3.568,1.226,6.226,2.573,7.654,6.557c0.863,2.407,4.433,2.822,6.83,4.015
                c1.769,0.88,3.903,1.209,4.941,3.073c1.784,3.202,4.802,4.14,8.036,4.405c1.604,0.131,3.295,0.707,3.883,1.648
                c2.539,4.07,6.763,3.996,10.611,4.93"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1026,439.889c-1.684,2.953-1.994,6.505-1.799,9.487c0.721,11.006,0.667,22.005,0.869,33.011c0.058,3.172,1.353,6.194,2.93,9.002"
                />
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M83.5,894.889c-1.479,2.493-4.805,2.319-6.806,4.766c4.232,3.318,10.041-1.572,13.8,2.953c-6.025,5.041-14.394,4.847-22.195,9.235
                c5.228,1.091,9.31,2.597,13.675,1.444c0.865-0.229,1.707-0.424,2.524-0.368c8.1,0.555,15.131-3.185,22.497-5.547
                c4.472-1.435,8.975-2.755,13.47-4.103c1.557-0.466,2.703,0.222,4.034,0.127c0.67,1.227-1.192,1.448-1,2.491
                c-0.165,0.501,0.057,0.435,0.493,0.538c3.35,0.793,6.658,0.518,10.013-0.009c3.981-0.624,7.914-1.63,11.996-1.563
                c0.943,0.015,1.474,0.419,2.003,1.062c0.249,1.877-1.623,2.668-2.529,2.844c-5.264,1.023-9.758,4.884-15.478,4.148
                c-1.575-0.203-3.032,0.493-4.498,0.98"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1258,903.889c-0.456,0.699-1.49,0.966-1.541,2.461c1.594,1.828,4.08,1.264,6.018,0.909c2.375-0.435,4.691-0.18,7.023-0.368
                c2.048-0.165,3.604,0.577,4.654,2.507c-3.08,2.324-6.234,3.503-10.146,3.667c-3.203,0.135-6.389,1.699-9.491,2.869
                c-2.02,0.761-3.925,1.354-6.018,0.457c-2.208-0.946-4.245-0.814-6.193,1.381c3.404,2.004,7.709,2.301,10.694,5.117"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1188,594.889c-0.868-0.623-1.291-1.803-2.857-2.125c-1.941,1.494-1.76,4.97-5.101,5.795c-2.32,0.572,0.218,5.343-3.954,5.885
                c-2.226-1.419-1.006-5.156-3.605-6.563c-2.261,2.224-2.51,5.04-2.527,8.009c-0.008,1.517,0.895,3.593-0.984,4.435
                c-2.975,1.334-2.674,4.376-3.971,6.565c-1.166,0-2.351-0.129-3.496,0.025c-2.769,0.372-3.961-0.998-4.532-3.52
                c-0.878-3.881-0.95-3.837-4.98-2.533c-0.604,0.195-1.325,0.027-2.325,0.027c-1.418-3.829-0.372-8.475-2.973-11.955
                c-3.812,0.895-1.368,6.164-5.627,7.045c-0.752-1.063-1.652-2.334-2.188-3.09c-2.418,0-3.785,2.736-3.879,5.999
                c-0.575,1.702-2.781,1.683-3.524,1.758c-2.913,0.296-4.682,0.811-5.104,4.228c-0.25,2.018-5.029,3.785-7.873,3.516
                c-0.948-1.055-0.284-2.342-0.508-3.498c-0.211-1.086-0.902-1.82-1.993-1.981c-1.169-0.172-2.399-0.065-2.472,1.48
                c-0.108,2.346-2.24,2.137-3.525,2.999c-1.324,0.889-0.895,3.281-3,3.5c-1.722-0.564-2.178-1.781-2.025-3.502
                c0.229-2.554-0.275-5.025-0.9-7.517c-0.33-1.314-0.654-2.032-2.074-1.991c-1.341,0.038-1.936,1.084-1.926,2.009
                c0.018,1.637-0.451,2.639-2.529,3.103c-1.288-4.025-6.416-4.563-8.079-9.559c-2.851,5.36-0.007,11.542-4.618,15.593
                c-3.156-1.306-0.791-6.124-4.564-7.197c-2.57,1.564-1.193,5.446-3.903,7.136c-2.045-1.075-2.064-3.004-2.493-5.051
                c-0.701-3.35,0.153-7.104-2.342-10.062c-0.178-0.21,0.288-0.962,0.455-1.462"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1236.5,355.389c1.449,5.282-0.413,10.35-1,15.5c-0.326,2.862-1.297,5.429,0.268,8.614c1.367,2.786,1.475,6.235,0.555,9.965
                c-1.18,4.781-1.539,9.938-2.338,14.918c-0.983,6.138-1.273,12.302-0.871,18.419c-3.079,2.265-5.596,4.938-8.094,7.602
                c-6.969,7.429-9.682,16.472-10.075,26.479c-0.203,5.167-1.328,10.283-0.968,15.504c0.133,1.918-0.877,3.725-2.047,4.93
                c-3.992,4.111-5.367,9.307-5.789,14.58c-0.417,5.23-1.732,10.295-2.092,15.492c-0.179,2.59-1.379,3.067-3.549,2.496"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M216.5,120.389c0.539,3.372,1.278,6.483,4.972,8.065c2.443,1.046,2.672,3.427,2.576,5.937c-0.171,4.493-0.025,8.998-0.058,13.498
                c-0.018,2.394-0.169,4.762,1.981,6.535c0.526,0.434,0.977,1.296,0.983,1.966c0.045,4.724,0.633,9.356,1.25,14.039
                c0.429,3.253-0.064,6.963-0.155,10.462c-0.081,3.103,0.035,6.2-1.592,8.974c-0.309,0.527-0.526,0.967-0.451,1.524
                c0.643,4.739-0.793,9.335-1.187,13.986c-0.451,5.329-0.038,10.857-1.902,15.985c-0.639,1.756,0.213,3.41-0.418,5.03"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1218.5,218.889c3.5,2,6.987,4.023,10.503,5.994c4.403,2.469,8.608,5.173,12.009,8.996c2.929,3.293,6.531,5.874,10.059,8.413
                c3.27,2.354,7.176,3.71,11.066,3.601c2.338-3.241-0.954-4.764-1.24-6.958c0.616-0.359,0.787-1.171,1.604-1.047
                c6.123,0.936,12.276,1.779,17.725,5.044c0.281,0.169,0.192,0.958,0.275,1.458"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1122.5,410.889c1.464,4.89-0.337,9.718-0.73,14.481c-0.76,9.201-2.513,18.349-4.01,27.48c-0.482,2.948-0.917,6.328-3.511,9.26
                c-2.487,2.812-3.227,7.373-4.142,11.305c-0.625,2.687-1.545,5.288-2.234,7.941c-1.652,6.354-5.347,11.439-11.373,14.533"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M842,108.889c-10.174,5.101-20.965,8.375-31.99,11.042c-6.056,1.464-11.999,3.359-16.025,8.696
                c-0.492,0.651-1.055,0.265-1.484-0.238"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M968,407.889c-3.5,1.357-7.235,0.713-10.436-0.221c-3.391-0.988-5.768,0.582-8.717,0.793c0.309,2.394,2.179,3.075,3.307,4.652
                c-0.919,1.694-1.748,3.197,0.217,5.392c2.59,2.895,5.881,4.59,9.162,5.207c8.748,1.646,13.527,7.923,18.424,14.21
                c1.314,1.688,1.682,4.123,4.043,4.966"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M118,532.889c1.636,2.576,2.446,6.025,6.5,6.014c0.905-0.002,1.31,0.605,1.535,1.478c0.142,0.552,0.724,0.896,1.031,1.474
                c1.245,2.352,4.63-0.318,5.998,2.504c0.926,1.91,1.709,4.146,4.958,3.686c1.671-0.238,2.787,2.062,3.932,3.386
                c1.165,1.347,1.957,3.017,2.957,4.616c3.771-0.742,6.976-0.088,9.087,3.346c1.372,2.23,3.585,1.305,5.502,1.498"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M93.5,71.389c1.713,2.784-1.044,5.188-0.64,8.02c0.393,2.751,0.551,5.67-0.755,8.528c-0.896,1.959,0.488,4.188,1.47,5.909
                c3.433,6.015,4.099,11.729,0.604,18.141c-1.392,2.553-1.751,5.929-2.689,8.899c-0.311,0.985-0.853,2.016-0.459,2.99
                c1.651,4.085,0.625,8.348,0.994,12.511c0.295,3.335,1.617,6.608,0.945,9.996c-0.92,4.64-0.991,9.315-0.956,14.006
                c0.005,0.683-0.344,1.5,0.443,2.099c2.423,1.326,4.838,2.673,7.543,3.401"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M975.5,554.889c-2.173-5.06-5.475-9.406-9.604-12.877c-2.066-1.74-4.666-3.166-7.367-4.199c-1.518-0.582-2.165-1.99-2.701-3.875
                c-1.508-5.307-3.682-10.445-5.888-15.523c-1.161-2.673-4.37-3.854-6.323-1.907c-4.24,4.229-8.846,1.973-13.095,1.249
                c-9.729-1.656-11.044-1.396-16.54,6.621c-0.39,0.566-1.035,0.959-1.537,1.412c-1.493-1.006-3.072-2.035-4.515-3.322
                c-1.117-0.996-2.654-1.6-4.429-1.639c-5.588-0.122-5.586-0.227-7.65-5.306c-8.156,4.327-14.23,10.837-19.852,17.866"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M160.5,410.889c-1.556-1.616-1.608-4.53-4.49-5.057c-0.572-0.104-1.471-0.975-1.439-1.438c0.33-4.809-3.674-6.862-5.821-10.167
                c-2.526-3.887-5.402-4.533-9.107-4.319c-2.93-3.227-5.666-6.495-8.683-9.478c-2.188-2.163-5.212-1.473-7.983-1.547
                c-1.986-1.721-2.315-5.879-6.475-4.487c-0.35,0.117-0.791,0.036-0.983-0.514c-1.725-4.937-6.233-4.132-9.995-5.087
                c-1.655-0.42-3.809-0.117-4.737-2.833c-0.498-1.458-2.982-2.619-5.293-2.101c-0.793,0.178-2.076,0.384-2.428-0.028
                c-2.888-3.376-6.646-1.705-10.191-1.958c-2.106-3.856-5.567-4.627-9.841-5.225c-4.647-0.65-9.222-0.198-13.476,0.562
                c-3.616,0.646-6.862,3.237-11.075,2.895c-0.979-0.079-3.485,1.386-3.497,3.783c-0.009,1.905,0.753,3.241,2.514,4.002
                c2.443,1.057,4.299,2.635,4.474,5.5c0.049,0.799,0.686,1.423,1.029,1.435c5.28,0.168,3.766,4.131,3.974,7.066
                c0.106,1.505,0.197,3.054-0.476,4.498"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1054,443.389c-3.218,3.941-3.095,8.972-3.811,13.53c-0.795,5.06-1.898,10.246-1.177,15.468c0.792,5.74,1.402,11.529,3.517,16.99
                c0.289,0.748,0.971,1.346,1.471,2.012"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M680.5,507.889c-3.069-1.988-6.702-1.072-10.037-1.842c-3.029-0.7-5.988-0.999-8.493-3.122c-1.673-1.418-3.455-1.595-5.519-0.104
                c-2.195,1.584-4.557,1.719-6.546-0.859c-0.331-0.43-1.317-1.018-2.395-1.154c-9.086-1.163-8.856-1.637-15.589-5.273
                c-4.472-2.414-9.623-4.168-14.917-1.635c-0.537,0.257-0.706,1.17-1.502,0.982c-4.068-0.957-7.034,1.895-10.502,3.007
                c-2.125,0.683-4.062,1.683-6.502,1.636c-2.187-0.041-4.385,0.855-6.048,2.822c-2.124,2.511-4.713,4.621-6.92,7.068
                c-2.038,2.26-4.486,4.446-7.088,5.291c-6.062,1.965-6.696,6.826-7.355,11.695c-0.419,3.094-0.85,6.115-2.085,8.988"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M144,149.389c1.214,1.673,0.517,3.697,0.842,5.528c0.521,2.934-1.364,5.766-4.345,5.898c-2.252,0.101-2.714,1.24-3.025,3.068
                c-0.76,4.476-1.634,8.849-1.698,13.502c-0.07,5.133,0.542,10.457-0.893,15.469c-0.622,2.173-0.039,3.746,0.619,5.534"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1006,558.389c-0.834,0.166-1.851,0.729-2.473,0.439c-7.871-3.662-15.934-4.462-24.562-3.168
                c-3.146,0.472-5.995,0.954-9.021,2.082c-6.344,2.363-8.277,7.595-9.891,13.162c-0.801,2.769-1.449,5.488-2.984,8.026
                c-1.521,2.513-0.582,5.488,1.049,7.354c2.416,2.764,4.346,5.594,5.449,9.082c0.199,0.631,1.295,0.979,2.252,1.649
                c1.778-1.362,2.193-4.28,4.816-5.078c2.797,1.017,3.488,4.325,5.923,5.903c2.227-1.742,1.255-5.393,4.036-6.846
                c1.263,1.297,3.092,2.438,2.43,4.899c-0.168,0.622-0.248,1.802,0.008,1.922c3.904,1.833,0.986,7.356,4.785,9.122
                c2.386-0.477,1.957-3.205,3.681-4.041c1.452,1.206,2.864,2.395,4.077,3.93c1.678,2.123,3.962,1.391,4.33-1.451
                c0.274-2.123,0.998-3.703,2.592-4.986c1.711,3.924,6.063,5.868,7.502,9.998"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M200,571.389c-1.434,0.273-1.109,1.577-1.548,2.477c-0.784,1.602-0.034,4.659-3.452,4.023c-0.143,1.063-1.5,1.67-1.021,3.008
                c0.51,1.426-0.893,2.035-1.145,4.089c-2.306-3.023-2.72-5.454-2.88-8.094c-0.188-3.109-1.656-3.979-4.454-3.003
                c-3.677,1.283-4.688,0.787-5.448-3.01c-0.192-0.959-0.353-1.72-0.974-2.549c-0.471-0.628-0.709-1.311-1.675-1.498
                c-2.405,1.04-2.236,3.724-3.403,5.557"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1058.5,48.389c-4.643,0.843-9.338,0.625-13.998,0.443c-4.732-0.185-9.252,1.423-14.006,1.128
                c-4.652-0.29-9.344-0.242-13.998,0.415c-1.82,0.257-3.664,0.348-5.498,0.515"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M3.5,510.889c1.154,0.47,2.316-0.836,2.378-1.037c1.059-3.448,4.468-5.037,6.281-8.244c2.727,2.24,2.669,5.324,2.823,8.282
                c0.149,2.868,0.51,5.603,3.214,8.19c1.935-4.455,5.33-7.315,6.812-11.567c2.741,1.938,1.982,4.301,1.949,6.376
                c-0.048,2.996,0.3,5.616,2.461,8.071c2.002,2.274,2.627,5.554,1.919,8.389c-0.55,2.206,0.557,3.428,1.382,5.636
                c1.809-3.822,2.406-7.835,5.28-10.596c-1.517-2.979,1.015-4.242,2.479-6.018c1.288-1.562,0.487-3.795,1.907-5.586
                c2.713,0.204,2.647,2.433,2.608,4.604c-0.06,3.361,0.778,6.409,2.637,9.188c2.543-0.904,1.792-3.572,3.472-4.754
                c3.64,1.766,1.452,6.449,3.96,8.488c3.192,2.595-0.769,6.737,2.873,9.529c0.609-4.639,4.993-7.005,5.598-11.631
                c2.32,5.774,4.342,11.496,6.398,17.493c2.201-4.956,4.726-9.333,8.813-12.995c3.581,2.654,1.913,8.527,6.207,10.184
                c0.158,1.646-0.52,2.531-1.062,3.744c5.363,1.646,1.422,7.478,4.794,10.441c3.387-2.412,1.914-6.761,3.835-9.691
                c2.861-0.518,3.407-0.938,6.707-5.295c2.799,2.775,1.524,7.742,5.163,9.945c1.496-1.971,1.496-1.971,1.611-3.146
                c2.705-1.146,6.493-0.777,6.589-5.157c3.676,1.278,2.523,4.507,3.285,6.701c1.458,4.2,1.539,4.425,5.126,1.457
                c1.606,0.841,3.12,1.754,4.56,2.927c2.026,1.649,5.014,2.267,3.579,6.123c-0.41,1.102,1.601,2.663,2.789,1.843
                c2.128-1.468,3.398-0.956,5.117,0.557c1.991,1.751,3.194-0.234,4.811-1.814c4.021,6.134,2.153,13.823,5.62,19.907
                c1.394-0.355,1.532-1.388,1.531-2.543c-0.001-2.755-0.558-5.674,2.945-7.119c0.497-0.205,1.446-1.531,1.093-2.893
                c-0.455-1.755,0.875-2.861,2.119-4.159c2.645,1.729,2.271,4.708,2.755,7.187c0.343,1.758,0.685,3.466,1.616,4.963
                c0.528,0.849,1.2,1.502,2.5,0.57c2.306-1.652,2.663,0.533,2.913,1.961c0.377,2.155-0.401,4.496,0.495,6.353
                c2.25,0.546,2.724-1.032,2.965-1.888c0.639-2.266,0.616-4.625,3.57-5.547c2.067-0.645,0.269-3.179,1.463-4.358
                c-4.102-4.415-10.104-7.157-12.769-12.652c-2.271-4.685-5.065-8.882-8.204-12.892c-8.737-11.162-19.828-17.462-34.468-16.026
                c-1.575,0.154-2.799-0.646-4.217-1.375c1.814-1.79,1.489-3.61,0.175-5.087c-1.833-2.059-4.972-3.078-4.958-6.537
                c-4.062-1.625-4.146-5.896-6.101-8.936c-1.406-2.186-3.431-4.352-6.402-4.518c-2.163-0.121-2.634-1.24-2.497-3.047
                c0.2-2.625-1.966-4.816-3.493-4.766c-3.92,0.128-5.104-3.073-7.508-4.734"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M911.5,871.389c-1-2.334-2-4.667-3-7c-1.454-1.846-2.322,0.414-3.497,0.509c-1.396-0.524-1.586-1.672-2.112-2.964
                c-2.848-6.984-5.375-6.338-8.896-1.549c-1.162,1.58-2.318,3.206-4.697,3.658c-2.689-2.25-3.482-5.542-4.22-8.672
                c-0.804-3.414-2.065-6.652-3.063-9.986c-0.55-1.835-1.036-3.641-3.738-3.482c-3.132,3.677-3.149,8.543-4.221,13
                c-0.914,3.801-1.647,7.529-4.52,10.52c-2.361,2.459-1.869,5.929-2.485,8.977c-0.291,1.441-0.024,2.99-0.055,4.49
                c-0.101,5.025-0.609,5.617-5.495,6.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M708,427.389c4.373-8.14,1.854-13.988-8.511-17.467c-6.624-2.224-13.691-2.144-20.516-3.387c-2.052-0.374-4.197-1.162-6.485-0.706
                c-2.188,0.436-4.346-0.814-6.49-0.862c-6.904-0.157-13.495,1.574-18.355,6.561c-2.434,2.496-4.866,5.48-5.779,9.329
                c-0.406,1.71,0.076,2.008,1.015,3.134c1.513,1.815,2.431,3.983,4.558,5.49c2.491,1.765,1.391,4.843,1.566,7.375
                c0.981-0.007,1.332,1.422,2.502,1.045c3.946-1.274,5.068,3.121,7.971,4.066c1.195,0.389,0.322,1.928,0.517,2.924
                c0.421,2.152-0.889,4.572,1.009,6.499"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M284,422.389c0.574,0.611-0.1,1.862,1.492,2.064c4.111,0.521,6.632,2.905,8.074,6.913c2.251,6.256,5.258,12.253,7.28,18.573
                c0.846,2.647,2.637,4.075,4.157,5.949c2.962,3.649,4.502,7.747,3.998,12.502"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M376,251.389c-1.915,3.175-1.229,6.747-0.839,9.98c0.463,3.845,0.942,7.661,0.816,11.519c-0.236,7.2,1.695,14.282,1.054,21.504
                c-0.136,1.53,0.058,3.072-1.531,3.997"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M990,424.889c-0.414,3.087,1.294,5.756,2.174,8.443c2.014,6.142,3.026,11.988,2.087,18.594c-0.718,5.04-1.143,10.281-1.28,15.463
                c-0.035,1.336-0.314,2.666-0.48,4"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1269.5,877.889c-2.385-0.012-3.236,2.426-5.418,3.224c-4.955,1.812-9.936,2.509-15.082,2.772
                c-0.499,0.025-1.166-0.207-1.475,0.035c-4.357,3.431-9.7,1.978-14.547,2.846c-3.228,0.577-6.158,1.212-8.479,3.623"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M850.5,314.889c-1.471,3.055-2.844,6.087-3.662,9.461c-0.552,2.277-0.886,4.295-0.354,6.543c0.835,3.53,0.103,7.267-0.716,10.436
                c-2.117,8.204-1.727,16.299-1.412,24.565c0.17,4.452,1.744,9.066-0.355,13.495"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M954.5,576.389c-1.528,3.461-3.447,6.656-6.518,8.977c-0.828,0.625-0.552,1.222-0.531,2.024c0.076,3.066-0.871,5.664-3.98,6.97
                c-2.64-1.648-0.935-4.812-2.354-7.045c-0.701-1.104,0.156-3.223-2.118-3.412c-2.104-0.176-2.692,1.701-2.872,3.004
                c-0.202,1.454-0.9,2.481-1.678,3.441c-0.768,0.946-1.949,0.783-3.473-0.326c0.169-2.926,0.048-6.279-1.77-9.129
                c-4.459-0.158-1.879,5.38-4.896,5.979c-1.423,0.11-1.887-0.432-2.362-1.46c-1.175-2.537-2.614-1.563-3.948-0.023"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1153,392.389c1.861,2.667,0.049,5.443-0.263,7.968c-0.286,2.316-0.726,4.672-0.714,7.032c0.042,8.794-0.34,17.521-2.991,26.01
                c-0.502,1.608,0.789,3.621-1.032,4.99"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1156.5,169.889c-0.166,0.333,0.102,0.578,0.484,1.013c5.916,6.729,7.759,14.36,5.027,22.991
                c-0.096,0.303-0.037,0.665-0.008,0.996c0.688,7.622-3.35,13.859-6.004,20.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1126.5,501.389c-5.093-2.142-8.833,0.855-12.449,3.567c-2.252,1.688-4.049,3.763-7.029,4.52
                c-3.771,0.959-5.639,4.351-7.469,7.444c-0.991,1.678-1.67,2.927-4.038,3.135c-1.741,0.152-3.955,1.445-4.894,2.912
                c-2.241,3.5-5.443,2.9-8.621,2.921c-1,0.007-2,0.001-3,0.001"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M91,182.389c5.303,2.935,4.383,8.315,4.931,13.008c0.365,3.124,0.241,6.337,0.026,9.489c-0.267,3.914,1.317,7.582,1.206,11.507
                c-0.039,1.393,0.657,3.419,2.326,4.514c2.202,1.444,3.93,3.582,6.512,4.482"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1183,700.889c-0.716,1.186-1.873,1.119-2.998,0.988c-1.756-0.206-3.207,0.396-4.621,1.613c1.438,2.609,4.362,0.502,5.712,1.504
                c1.14,1.061,0.78,1.586,0.476,2.42c-1.234,3.367-0.602,4.191,2.932,4.48c1.32,0.108,3.028-0.627,3.615,1.981
                c-5.216,0.422-8.204,5.253-14.16,6.212c4.347,3.34,9.267,0.173,12.707,3.479c-1.576,3.314-5.308,1.864-7.652,3.338
                c-2.857,1.795-6.016,3.127-8.838,5.725c2.646,2.124,5.65,1.252,8.32,1.109c1.214-0.065,2.342-0.326,3.646,0.207
                c-0.941,3.389-4.502,3.574-6.639,5.443c-1.995,1.748-5.018,2.011-6.99,4.672c3.25,1.245,6.07,0.887,9.002,0.396
                c1.949-0.325,4.138-1.531,5.988,0.432"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M741.5,472.889c-4,2.666-8.24,5.039-11.941,8.072c-4.939,4.049-8.664,9.409-8.268,15.91c0.176,2.871,0.291,6.057,1.101,9.047
                c0.511,1.887,0.239,3.976-0.824,6.006c-3.167,6.048-6.505,11.963-10.522,17.498c-0.737,1.016-1.307,2.025-2.543,2.471
                c-5.01,1.805-8.717,5.822-13.51,7.979c-3.553,1.599-5.388,4.95-7.992,7.517"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M662,340.389c2.615,1.854,2.93,4.482,3.089,7.495c0.313,5.945-0.986,11.704-1.776,17.479c-0.412,3.018-0.395,6.087-0.576,9.04
                c-0.305,4.949-2.438,9.5-2.346,14.487c0.032,1.771-1.684,3.025-3.357,4.052c-2.951,1.808-5.699,3.946-8.533,5.946"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1003.5,82.389c15.592,6.123,32.057,8.011,48.646,10.15c0.809-4.438,6.802-4.527,7.354-9.15"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1187.5,233.389c1.453-3.22,3.207-6.208,5.983-8.52c1.263-1.051,2.413-2.258,3.504-3.492c2.565-2.901,2.821-6.182,0.979-9.47
                c-2.191-3.913-4.656-7.671-6.962-11.521c-5.052-8.438-10.054-16.908-17.007-23.995c-2.627-2.677-5.543-4.846-8.889-6.699
                c-1.975-1.094-3.729-1.475-5.602-1.237c-4.857,0.619-9.661,0.509-14.511-0.038c-7.067-0.797-14.253-2.054-20.474,3.051
                c0.36,1.652,1.739,1.361,2.977,1.427c3.025,0.163,5.768,1.239,8.519,2.451c2.8,1.233,5.281,3.052,8.446,3.712
                c1.759,0.367,3.68,1.678,5.048,3.319c4.449,5.333,8.976,10.6,9.524,18.008c0.052,0.688,0.629,1.336,0.963,2.003"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1194.5,663.389c-0.916,4.21,1.061,8.305,0.498,12.5c-0.215,1.598,0.628,2.385,1.985,3.035c1.409,0.675,3.106,0.955,3.935,2.543
                c-4.975,4.752-4.963,4.736,1.072,9.434c1.396,1.086,2.677,2.321,4.01,3.488c-0.583,2.688-4.417,1.312-5.105,4.479
                c4.893,0.903,9.605,2.383,11.245,8.485c-4.156,1.159-8.885-0.18-12.922,2.756c2.345,2.393,5.827,1.598,8.167,2.975
                c2.196,1.293,3.232,4.434,6.293,4.971c-1.086,2.035-4.215,1.922-3.834,4.854c0.035,0.276-1.928,1.117-3.347,1.064
                c-1.999-0.073-4.227-0.794-5.997,0.915"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M134,194.389c-1.906-1.288-3.812-2.588-6.021-0.575c2.028,7.149,0.417,27.562-2.985,38.096c1.276,2,2.772,3.837,4.507,5.479
                c1.45-1.469,1.507-3.912,3.5-5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M409.5,356.889c3.46,1.496,4.281,2.413,3.428,5.48c-1.089,3.916-1.762,7.86,0.157,11.475c2.285,4.302,2.382,9.1,3.851,13.566
                c0.572,1.741,0.07,3.635-1.441,4.973c-3.171,2.808-6.741,4.846-11.284,5.596c-2.061-1.229-2.242-4.079-4.091-5.725
                c-0.579-0.515-1.473-2.425-0.614-3.862c1.801-3.011,0.756-6.037-0.04-8.994c-0.845-3.139-0.364-5.776,1.405-8.59
                c0.972-1.546,2.043-3.243,3.24-4.292c2.887-2.528,3.763-5.69,4.389-9.127"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1180.5,865.389c-0.709,0.443-1.002,1.291-1.99,1.539c-4.929,1.236-9.299,3.541-13.588,6.342
                c-4.453,2.906-9.996,3.578-14.949,5.551c-0.834,0.332-1.82,0.176-2.484,1.061c-2.115,2.816-4.982,4.045-8.488,4.076
                c-0.504,0.005-1,0.932-1.517,1.447c0.742,0.406,0.963,1.596,2.015,1.471c4.504-0.536,8.949,1.879,13.521,0.062
                c3.002-1.194,6.27-1.136,9.48-1.048"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M120,148.389c-1.007,0.21-1.713,1.216-2.521,1.418c-5.117,1.282-5.78,5.582-6.377,9.597c-0.465,3.131-0.739,6.355-0.938,9.495
                c-0.456,7.202-2.09,14.251-2.318,21.485c-0.078,2.472-1.355,4.927-0.346,7.505"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1266.5,877.889c-4.554-1.288-9.124-2.016-13.477,0.539c-1.371,0.805-2.769,0.598-4.013,0.395
                c-7.355-1.198-14.661-2.703-22.023-3.852c-1.558-0.242-2.539-2.766-4.487-1.082"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M354.5,163.389c-0.917,1-2.174,1.685-1.046,3.528c3.243,5.299,3.118,11.137,2.006,16.964c-0.796,4.172-0.315,8.341-0.435,12.509
                c-0.068,2.391-0.033,4.74-2.027,6.499c-1.996,0.228-2.286-2.274-3.999-2.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M228.5,186.389c2.708,2.599,3.674,5.722,3.573,9.502c-0.136,5.102-0.789,10.025-1.843,15.046c-0.824,3.927-1.41,8.61,0.903,12.37
                c1.243,2.02-0.19,4.545,1.867,6.082"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1174.5,375.389c0.639,4.819,1.773,9.593,1.486,14.499c-0.206,3.526,1.197,6.722,2.086,9.981c1.705,6.25,2.672,12.761,5.928,18.52
                "/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M422.5,863.389c-2.6,1.33-5.129,1.605-7.687-0.587c2.22-5.12,7.787-7.499,10.11-12.142c0.407-1.603-0.692-2.209-1.453-2.729
                c-2.061-1.406-1.761-2.674-0.562-4.6c0.909-1.461,2.285-2.416,3.187-3.885c1.042-1.695,3.28-2.804,0.961-5.115
                c-0.25-0.248,0.127-1.367,0.467-1.93c2.808-4.64,5.874-9.054,11.088-11.852c3.758,3.197,2.86,8.227,5.168,11.974
                c2.416,3.92,4.855,7.883,7.202,11.989c-0.196,1.327-1.601,1.59-2.561,2.445c1.475,4.62,6.079,7.305,7.61,12.034
                c-1.013,0.593-2.048,1.198-3.258,1.906c2.897,3.988,5.724,7.878,8.607,11.846c-2.331,1.874-4.699,0.96-6.881,1.117
                c-3.585,0.259-4.021,1.192-2.547,4.548c1.36,3.096,4.092,4.961,5.974,7.533c1.547,2.115,4.13,4.149,2.777,7.341
                c-1.944,1.265-4.091-2.016-5.699,0.603c2.361,6.602,9.072,9.303,13.095,14.797c-0.824,1.566-3.406,1.305-4.085,3.232
                c1.492,3.452,3.133,6.883,6.02,9.437c1.394,1.232,0.804,2.66,0.968,4.036"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M24,383.389c2.657,4.243,6.502,7.8,7.103,13.239c7.111,0.912,13.562,2.591,18.941,7.21c2.31,1.984,5.395,1.553,7.918,0.464
                c3.484-1.504,6.759-1.859,10.003,0.144c0.908,0.561,1.746,0.536,2.528,0.402c5.635-0.964,9.886,2.368,14.502,4.552
                c1.896,0.896,3.083,2.411,3.505,4.49"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M383,385.389c2.339,2.249-0.269,5.734,2,8c-0.216,6.098,1.535,11.972,2.13,17.987c0.564,5.697,1.061,11.279,0.497,17.025
                c-0.316,3.222,1.957,5.836,4.373,7.987"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M190,77.889c0,1.667-0.028,3.334,0.007,5c0.047,2.222-0.449,4.292,2.939,5.2c2.492,0.668,2.55,4.014,1.974,6.279
                c-1.676,6.589-2.224,13.312-2.888,20.023c-0.296,2.997,0.022,5.788,1.865,8.565c2.376,3.584,1.354,8.025,0.818,11.892
                c-0.246,1.776-0.688,3.726-0.649,5.538c0.156,7.227-0.309,14.388-1.565,21.501"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1000.5,88.889c10.396,6.01,21.699,9.259,33.499,11.005c3.171,0.469,6.335,0.995,9.501,1.495"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M961.5,562.889c-3.694-2.555-4.135-7.123-6.24-10.985c-3.561,1.483-6.681,2.687-7.766,6.483
                c-1.439,5.039-5.821,7.279-10.043,8.299c-6.4,1.547-11.631,4.402-15.951,9.293c1.015,2.141,2.83,3.715,4.5,5.41"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1116.5,460.889c0.371,0.966,0.675,1.939,0.502,3c-1.337,8.166-2.586,16.348-4.032,24.494c-0.909,5.123-0.267,10.07,1.03,15.006"
                />
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M765,363.889c-2.072,0.912-3.803,3.3-6.5,1.5c-2.678,3.187-2.687,7.325-3.289,11.035c-0.477,2.93-1.09,5.927-1.32,8.958
                c-0.303,3.991-1.729,7.2-5.753,9.728c-4.688,2.945-9.964,4.971-14.167,8.748c-3.703,3.328-5.403,4.696-5.796,9.547
                c-0.385,4.75-1.973,9.094-3.415,13.406c-0.712,2.129-0.35,4.114,0.373,4.967c4.865,5.739,6.305,12.911,8.867,19.613"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1205.5,193.889c7.426,6.303,9.354,15.469,12.542,24.114c-2.542,2.142-5.248-0.022-7.494-0.398
                c-3.491-0.584-7.072-0.919-10.548-1.715"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1043.5,437.389c-0.684,1.786-0.492,3.655-0.495,5.5c-0.011,9.667-0.054,19.334,0.026,29c0.023,2.74-0.586,5.207-2.031,7.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M191,722.889c-4.314,0.732-8.333-1.008-12.498-1.516c-3.659-0.445-7.192-2.221-10.929-1.236c-0.805,1.586-1.152,3.059-0.03,4.21
                c1.005,1.032,2.266,1.71,3.96,1.593c3.47-0.24,6.695,1.125,10.082,1.627c-0.315,1.505-1.456,1.959-2.076,2.812
                c1.269,3.286,6.053-0.448,6.991,3.512c-0.346,2.642-2.978,0.712-4,1.999"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M515,481.389c1.075,4.809,3.986,8.877,5.479,13.507c1.799,5.577,5.292,10.392,7.032,15.989c0.938,3.02,1.705,6.146,3.104,8.946
                c0.745,1.491-0.365,2.392-0.115,3.558"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M163.5,143.389c1.309-3.656-0.379-7.086-1.125-10.473c-0.855-3.883-1.719-7.828-3.3-11.56c-0.486-1.148-0.076-2.676-0.076-3.435
                c2.065-2.224,2.9,1.934,4.524-0.354c1.397-1.656-0.101-3.375-0.531-5.177c-1.313-5.505-2.508-11.044-4.044-16.487
                c-1.088-3.858-5.03-5.171-8.045-6.245c-2.732-0.973-5.232-1.767-7.503-3.65c-0.575-0.477-2.02-1.951-3.401-0.62"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1063.5,535.389c-0.479,4.669-1.605,9.236-1.651,13.998c-0.021,2.1,0.292,4.053,0.704,5.99c1.047,4.915-0.523,9.674-0.687,14.508
                c-0.036,1.086-0.332,2.34-0.339,3.504c-0.027,4.423,0.014,4.424-2.277,7.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M595.5,499.889c-0.613-6.478,4.434-14.674,10.522-15.89c5.943-1.187,11.678-2.982,17.478-4.612
                c2.161-0.607,3.968-0.846,6.09,0.893c2.745,2.249,6.624,2.488,9.963,3.42c6.495,1.813,12.948,3.546,18.972,7.049
                c0.848,2.63,0.675,5.891,2.423,8.674c0.279,0.445,0.375,1.464-0.447,1.967"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M849,114.889c-3.169,0.352-5.645,2.392-8.471,3.57c-9.996,4.167-20.239,7.622-30.551,10.863c-3.832,1.205-7.949,1.02-11.978,1.118
                c-1.538,0.038-2.354-1.003-3.501-1.551"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M789,438.889c3.775,0.097,6.732-2.238,9.959-3.596c6.15-2.589,12.43-3.566,19.042-3.478c3.179,0.042,6.294-0.17,9.595-1.08
                c3.904-1.077,8.615,0.329,12.36,2.723c1.622,1.037,3.13,1.896,5.001,2.553c5.917,2.08,10.576,5.929,13,11.896
                c1.312,3.228,2.705,6.299,5.543,8.482"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M939,371.389c-2.336,4.557-2.031,9.842-4.041,14.674c2.511,1.734,5.379,0.687,8.043,0.743c2.478,0.053,4.318,0.502,6.072,2.518
                c2.543,2.92,5.533,5.467,8.461,8.025c0.922,0.805,1.934,1.889,3.465,1.54"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M839.5,105.389c1.318-1.946,0.24-3.284-1.507-3.386c-1.971-0.116-3.812-1.193-6.052-0.255c-1.702,0.712-3.941,0.117-5.941,0.145
                c-11.167,0.158-22.334,0.33-33.5,0.496"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M831,318.889c3.999-2.42,8.234-4.068,13-4.001c0.666,0.009,1.344,0.078,1.998-0.01c5.827-0.784,5.438-0.676,7.141,4.97
                c1.106,3.673,1.932,7.452,3.326,11.055c0.77,1.986,0.736,3.677-0.189,6.092c-1.913,4.983-3.158,10.585-3.014,15.888
                c0.129,4.774,1.484,9.665,2.186,14.515c0.67,4.622,1.381,9.22,0.663,14.009c-0.663,4.423,0.075,8.982-0.521,13.495
                c-0.309,2.342,1.507,4.386,2.486,6.453c3.47,7.326,5.482,15.212,8.924,22.536"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M267.5,747.889c0.5,0,1.311,0.193,1.451-0.031c1.131-1.814,4.172,2.4,4.549-1.469c2.167-0.708,4.145-1.369,5.108-3.959
                c0.696-1.871,3.1-2.123,4.903-2.486c4.334-0.872,8.347-2.644,12.239-3.878c0-2.022,0-3.866,0-5.989
                c-3.033-0.45-5.983,0.208-8.75-0.688"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M168.5,657.889c1.224,2.552,3.349,3.247,6.001,3.02c1.324-0.113,2.685,0.125,3.995-0.049c2.895-0.387,4.524,1.092,5.486,3.536
                c0.877,2.229,1.286,4.664,4.033,6.452c-8.548,3.109-16.852,2.155-24.957,3.928c1.677,2.31,3.683,2.498,5.454,3.076
                c1.003,0.328,2.276,0.229,2.958,1.062c2.995,3.665,7.098,2.979,11.031,2.956c1.019-0.006,1.928,0.032,2.521,1.129
                c-0.022,1.334-2.021,1.445-2.051,3.27c1.226,1.077,2.161,2.346,4.496,2.795c4.391,0.845,8.438,3.017,12.464,5.517
                c-5.181,4.416-10.946,3.273-16.428,3.164c-0.874-0.017-2.356-0.646-2.948-0.27c-3.939,2.504-7.866,1.182-11.498-0.234
                c-1.968-0.768-2.119,0.631-3.085,1.162c0.547,2.502,5.76,2.489,3.471,6.588c2.225,4.229,6.812,4.735,10.6,5.735
                c7.185,1.897,14.708,2.501,21.911,4.343c2.495,0.638,5.387-0.125,7.546,1.82"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M280.5,762.889c2.56-1.764,6.206,0.167,8.757-2.509c-1.534-2.532-4.036-1.717-5.734-1.376c-6.855,1.373-13.57,3.5-20.531,4.318
                c-3.288,0.387-6.659,0.052-9.992,0.074c-1.183,0.008-2.411-0.219-3.5,0.492"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M432,335.389c5.538,7.237,9.265,15.513,13.557,23.469c0.396,0.733,0.432,1.348,0.43,2.031c-0.028,7.003,0.305,14.012-0.476,21.001
                c-0.782,6.995-1.395,13.983-0.034,21.003c1.219,6.287,0.49,12.668,0.434,18.995c-0.04,4.504-0.911,9.001-1.411,13.501"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1049.5,605.389c1.689,3.52,1.135,8.311,5.963,10.101c0.766,0.284,1.264,1.854,1.47,2.913c0.188,0.956,0.081,1.914,1.321,2.586
                c1.789-0.651,2.586-1.977,2.804-4.094c0.324-3.144,1.182-3.732,3.942-3.506c3.883,0.318,4.688-0.265,4.965-4.002
                c0.106-1.429,0.287-2.769,1.035-3.998"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1186.5,260.889c-1.191,1.722-2.976,0.881-4.502,0.971c-3.559,0.211-6.79,1.285-8.422,4.567c-1.639,3.3-3.91,5.771-7.086,7.442
                c-2.678,1.408-3.084,3.383-2.01,6.028c0.99,2.44,0.922,5.195,2.428,7.55c0.521,0.814,0.303,2.353-0.408,3.441"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M109,269.889c3.677,6.188,6.817,12.629,8.954,19.514c0.33,1.064,0.09,2.321,0.038,3.485c-0.234,5.35,1.456,9.916,5.518,13.489
                c1.153,1.014,1.889,2.519,3.49,3.011"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M917.5,138.889c-0.354-0.605-1.04-0.315-1.482-0.534c-2.627-1.301-5.428,0.363-8.006-0.502c-2.665-0.894-5.205-0.963-8.031-0.618
                c-2.645,0.323-5.602-0.122-7.971-1.858c-2.554-1.871-5.371-2.486-8.508-2.552c-2.881-0.061-5.713-0.521-7.561-3.397
                c-1.048-1.632-2.83-2.393-4.936-2.595c-3.233-0.311-6.176-1.278-8.072-4.402c-0.891-1.466-2.229-2.478-4.422-2.673
                c-3.734-0.332-6.328-2.707-8.518-6.364c-1.163-1.942-2.636-3.54-4.996-3.995c-0.321-0.062-0.826,0.136-0.975-0.03
                c-3.094-3.462-6.545-3.548-11.061-2.667c-9.21,1.796-18.698,2.145-27.938,3.83c-5.535,1.01-10.697-0.332-16.025-0.652
                c-3.949-0.237-7.874-0.654-10.992-3.498c-1.213-1.106-2.342,0.646-3.508,0.008"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1234,540.389c0.534-3.601-0.711-6.311-4.379-8.291c-2.285,5.488-5.18,10.68-4.574,16.787c0.537,5.432-1.561,9.988-4.047,14.504"
                />
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M350,398.389c3.102,0.631,4.434,3.539,5.829,5.615c2.942,4.377,6.992,6.412,11.666,7.901c2.599,0.829,5.241,1.794,6.976,4.007
                c3.672,4.683,7.985,8.688,12.53,12.477"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M623.5,390.889c-3.85-2.13-8.019-0.872-12-0.859c-2.828,0.009-4.808,2.585-7.045,4.301c-5.182,3.972-9.383,8.987-14.438,13.08
                c-2.666,2.159-5.081,3.746-9.019,3.722c-4.881-0.029-8.354,3.366-11.102,7.691c-3.904,6.144-6.66,12.861-10.379,19.076
                c-2.261,3.777-4.166,7.778-6.573,11.454c-2.166,3.309-2.996,7.171-4.945,10.536"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1008.5,65.389c-1.324,3.088-2.353,6.369-4.539,8.968c-2.021,2.402-2.654,4.762-0.805,7.732
                c-2.885,4.443-6.851,8.488-7.185,14.781c2.805,2.365,6.639,3.388,10.074,4.916c8.157,3.626,16.888,5.65,25.462,8.077
                c1.626,0.46,3.623-0.795,4.992,1.026"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M394.5,913.389c-5.771-1.227-11.707-0.328-17.512-1.439c-3.602-0.69-7.319-1.828-10.971-0.984
                c-4.671,1.079-8.261-0.604-12.084-2.969c-3.067-1.897-6.828-2.488-10.435-3.1c-2.187-0.37-4.013,0.369-5.499,1.992
                c-0.265,2.426,2.941,2.602,3.144,4.988c0.094,1.107,2.105,1.162,3.308,1.635c1.994,0.784,4.414-0.587,6.048,1.377"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1122,329.389c-2.949,1.865-3.807,5.048-4.99,8.004c-2.512,6.267-3.665,13.019-6.911,19.049c-0.519,0.963-0.901,2.216-1.657,2.881
                c-1.381,1.214-2.702,2.312-5.029,0.692c-2.643-1.839-5.749-1.779-8.49,0.791c-0.738,0.691-1.67,1.353-2.398,2.106
                c-3.043,3.149-7.266,3.976-11.012,3.767c-6.291-0.352-10.666,2.696-14.984,6.245c-3.964,3.258-7.847,3.7-12.061,1.53
                c-3.184-1.64-6.399-3.242-9.441-5.105c-6.467-3.962-12.697-2.367-17.85,1.76c-2.744,2.197-5.105,2.644-8.172,2.834
                c-6.633,0.412-10.156,4.215-11.093,10.935c-0.257,1.842,0.224,4.325-2.867,5.173c-1.425,0.391-2.378,2.506-3.544,3.839"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M782.5,87.389c1.105,0.773,2.795,0.526,3.5,2c1-0.486,2.043,0.969,2.992-0.008c3.362-3.461,7.307-1.375,10.434-0.28
                c4.335,1.519,8.816,0.111,13.169,2.078c3.869,1.749,8.443,2.149,12.901,2.315c3.021,0.113,5.994,1.277,9.012,1.854
                c0.612,0.117,1.326-0.292,1.992-0.459"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M907,555.889c1,5.834,1.52,11.807,3.165,17.452c1.142,3.917,3.733,7.442,5.86,11.032c2.682,4.525,2.711,5.562-0.538,10.535
                c-2.299-0.674-1.141-2.62-1.515-4.012c-0.261-0.969-0.639-1.926-1.971-2.038c-0.963-0.081-1.942,0.446-1.923,1.028
                c0.103,3.074-2.147,4.342-4.009,6.078c-0.76,0.709-0.799,2.244-1.053,3.428c-0.246,1.152-0.898,1.52-2.018,1.52
                s-2.005-0.36-1.961-1.521c0.07-1.887-0.654-2.611-2.541-2.541c-1.161,0.045-1.68-0.855-1.479-1.957
                c0.26-1.435-0.545-2.242-1.484-2.875c-4.368,1.39-0.768,7.316-5.428,9.051c-1.535-2.504-1.97-5.312-2.088-8.181
                c-0.088-2.123,0.38-4.329-1.477-6.046c-0.159-0.147,0.291-0.953,0.457-1.453"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M648,367.389c1.512,2.41,0.77,4.899,0.611,7.507c-0.387,6.349-2.215,12.571-1.621,18.994c0.076,0.826-0.336,1.931,0.066,2.456
                c2.506,3.267,0.316,6.248-1.242,8.41c-1.711,2.376-2.233,5.397-5.176,7.343c-3.392,2.243-6.628,3.904-10.635,4.323
                c-3.293,0.344-5.666,1.725-7.654,4.873c-3.346,5.299-7.043,10.463-11.388,15.058c-0.535,0.566-0.782,1.368-1.941,1.627
                c-4.079,0.914-6.485,3.905-8.63,7.341c-0.717,1.148-1.31,2.955-3.387,3.11c-3.957,0.296-6.525,3.602-10.023,4.903
                c-0.921,0.343-1.712,0.969-1.908,1.578c-1.322,4.099-4.011,7.52-5.544,11.488c-1.139,2.946-1.519,6.312-3.16,8.905
                c-1.92,3.037-5.287,5.023-7.367,8.084c-4.835-0.145-7.294,3.574-9.895,6.591c-1.78,2.064-4.833,1.649-5.904,3.062
                c-2.181,2.88-6.091,4.066-7.257,7.83c-0.294,0.949-1.278,1.684-1.944,2.518"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M213.5,770.389c-4.108-2.252-8.945-2.61-14.122-5.346c5.256-1.525,9.867,0.01,14.213-1.953c3.989-1.803,9.149-0.406,13.126-3.619
                c-2.963-2.698-6.161-1.642-9.205-1.023c-1.504,0.307-2.842,0.914-4.012-0.559"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1280.5,156.389c-2.435-0.829-4.851-1.515-7.497-1.599c-1.718-0.055-3.483-0.4-5.075-1.821c-3.135-2.8-7.637-3.335-10.957-6.044
                c-2.496-2.036-4.588,0.977-6.657,1.005c-2.305-0.304-3.017-2.475-4.812-3.048c-2.011-0.642-2.449,1.78-4.002,2.006"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M409,397.889c0.623,5.503,3.331,10.334,4.983,15.505c2.342,7.331,5.224,14.488,7.047,21.987c0.095,0.391,0.636,0.674,0.969,1.007"
                />
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M989.5,106.389c7.086,3.194,14.045,6.704,21.505,8.983c6.109,1.867,12.263,3.586,18.276,5.335
                c5.624-3.99,8.381-10.12,13.219-14.319c0.069-4.113,4.013-6.635,4.393-10.511c0.141-1.447,1.685-1.701,1.607-2.989"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M229,169.889c3.167,0,6.333,0.02,9.5-0.006c2.385-0.02,3.627,1.126,3.476,3.505c-0.117,1.834-0.108,3.659-0.356,5.518
                c-0.816,6.124-1.13,12.316-1.608,18.485c-0.077,0.994-0.346,2.164,0.05,2.969c1.401,2.85,0.419,5.032-1.566,7.024
                c-3.361,3.374-4.228,7.229-3.675,12.026c0.408,3.544,1.006,7.348-0.683,11.042c-0.665,1.453-0.413,4.036,0.507,5.336
                c1.37,1.936,0.458,3.78,0.856,5.602"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1074.5,30.889c-6.365,0.101-12.789-0.552-19,1.5c-7.648-2.073-14.014,2.685-20.959,4.188c-6.721,1.455-13.268,3.872-20.044,5.297
                c-2.921,0.614-6.443,4.891-5.358,7.456c0.95,2.25,0.691,4.367,0.861,6.559"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M478,866.389c0.333-0.666,0.55-1.804,1.02-1.92c2.273-0.564,4.583-1.305,7.125-0.536c-1.271,4.56-4.88,7.477-7.671,10.935
                c-1.177,1.46-2.607,2.725-3.986,4.01c-0.984,0.916-0.699,2.726-0.42,2.907c4.582,2.98,0.737,5.853-0.131,8.585
                c-1.188,3.74-4.704,5.572-7.698,8.168c1.461,1.965,3.22,4.126-0.239,5.852"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M675,345.889c0,4.333,0.693,8.809-0.158,12.968c-1.374,6.706-0.416,13.37-0.85,20.032c-0.115,1.771,0.072,3.454,1.008,5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M808,843.389c-3.291-0.333-2.314,2.796-1.866,3.4c2.03,2.735,2.826,6.211,5.356,8.609c2.398,2.273,3.261,4.566,1.703,8.076
                c-0.977,2.2-0.492,5.146,1.738,7.479c3.427,3.585,6.402,7.601,9.568,11.435"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M476.5,463.389c3.068-3.043,1.211-7.036,1.816-10.532c0.787-4.542,0.04-9.313,0.634-13.975c0.115-0.903,0.433-1.468,1.458-2.311
                c2.407,3.058,2.618,6.858,3.554,10.327c0.985,3.651,1.583,7.459,4.061,10.471c3.52,4.278,3.573,9.565,3.748,14.526
                c0.093,2.659,0.225,5.082,1.229,7.492"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M773,498.389c-1.286,1.047-1.586,3.082-3.5,3.5c-1.517-0.15-1.491-1.842-2.93-2.78c-1.604,1.39-4.639,0.31-5.716,3.734
                c-0.522,1.661-5.589,1.987-8.042,0.968c-3.114,2.425-5.68,5.662-10.279,6.314c-2.111,0.3-3.165,3.824-4.322,5.883
                c-1.488,2.648-2.972,1.599-5.108,1.297c-0.896,6.746-7.515,9.8-10.103,15.584c-2.633,0.787-3.973,2.992-5.508,4.994
                c-0.486,0.636-1.041,1.236-1.992,1.007c-4.277-1.03-6.955,1.943-9.771,3.972c-1.578,0.226-1.426-2.578-3.709-0.259
                c-0.529,1.956-2.854,3.09-5.977,3.945c-2.565,0.703-4.784,2.729-7.77,3.393c-1.33-1.234-2.96-2.296-4.775-3.054
                c-1.572,0.385-1.827,1.866-2.5,3c-1.277,0.507-1.925-1.764-2.959-0.949c-3.178,2.501-6.548,3.686-10.539,2.451
                c-1.692-0.524-2.386,2.921-4.534,1.039c-1.282-1.124-2.741-0.876-4.003,0.425c-0.242,0.25-1.379,0.13-1.402,0.022
                c-1.033-4.561-4.906,0.439-6.609-1.951c-0.201-0.282-1.004-0.054-1.471,0.447c-0.992,1.066-1.7,2.324-2.562,3.456
                c-0.573,0.753-1.682-1.091-2.978-0.009c-1.699,1.418-4.761,1.995-7.441,2.062c-1.833,0.046-3.794-0.403-5.468,0.113
                c-2.083,0.643-3.475-0.03-5.069-1.046c-0.44-0.281-1.378-0.314-1.978,0.43c-2.214,2.744-5.607,2.014-7.917,1.293
                c-3.66-1.142-7.466-1.846-11.121-2.477c-2.24-0.387-5.527-0.774-7.968,1.17c-0.836,0.666-1.655-0.132-2.495-0.431
                c-0.96-0.342-1.893,0.019-2.484,0.956"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M132.5,784.889c-3.455-1.104-7.052-0.663-10.484-0.333c-2.251,0.216-4.349,0.234-6.593-0.183c0.306-2.785,2.879-2.807,4.603-3.408
                c6.284-2.191,11.138-6.239,15.8-10.895c-3.303-2.447-6.093-0.438-8.811,0.372c-6.715,2.003-13.304,4.436-20.037,6.373
                c-2.861,0.823-5.84,1.839-9.009,0.655c-0.97-0.361-2.303,0.252-3.47,0.418"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M479,576.889c-3.428,0.61-6.657,1.615-10.153-0.775c-1.203-0.823-4.599-1.997-6.259-0.146c-3.433,3.828-7.08,0.997-10.577,0.768
                c-0.769-0.05-2.056-0.783-2.997-1.367c-3.467-2.151-6.896-3.222-11.076-1.645c-1.298,0.489-3.438-0.297-5.01-1.756
                c-2.483-2.305-6.669-2.395-8.82,0.018c-1.774,1.99-5.482,0.508-6.107,3.904"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M123.5,237.389c0.21,1.007,0.664,1.91,1.509,2.487c3.095,2.118,3.327,5.24,2.976,8.511c-0.61,5.672-0.718,11.5-2.217,16.937
                c-0.85,3.083-1.206,5.991-1.267,9.064"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M606,217.889c6.135,3.682,12.656,4.38,19.499,2.497c5.272-1.451,10.527-2.972,16.001-3.497"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M135.5,747.889c-6,1.666-11.986,3.385-18.004,4.986c-6.223,1.654-12.712,1.703-18.996,3.014"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1049,608.389c-1.266,2.484-0.156,6.234-4.459,7.187c-1.279,0.283-0.764,3.665-3.04,4.312c-3.362,0.369-1.765-5.323-5.685-4.393
                c-1.848,1.6,0.178,4.867-2.435,6.411c-2.658,0.059-3.143-1.976-3.339-4.021c-0.15-1.568-0.033-3.146-1.043-4.496"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M3.5,412.889c4.662-1.065,8.204,2.494,12.483,3.118c0.713,0.104,1.49,1.034,2.517,0.882c4.384-0.646,7.361,2.045,9.29,5.131
                c1.033,1.653,1.812,2.041,3.202,1.819c1.714-0.273,2.765,1.811,4.508,1.05c4.045,4.589,10.47,5.897,14.496,10.504
                c2.396,2.741,2.525,6.219,2.375,9.49c-0.102,2.198-0.652,4.339,0.129,6.506"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M131,417.889c1.25-3.229,3.64-4.468,7-3.999c5.07,0.708,9.983-1.479,14.99-0.917c2.661,0.299,5.048,0.798,7.034-1.564
                c0.596-0.708,1.787-0.859,2.446-0.469c3.618,2.145,8.573,2.266,10.555,6.938c0.464,1.095,0.807,2.342,1.975,3.011
                c3.878,2.22,6.831,5.036,6.508,10c-0.069,1.052,0.899,1.482,1.518,1.968c3.368,2.642,3.388,3.889,3.011,8.535
                c-0.152,1.865-0.336,3.777,0.94,5.515c2.425,3.301,2.43,7.12,2.026,10.982c-0.295,2.826,0.613,5.723-0.501,8.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1121,212.889c5.043,0.171,8.901,1.765,11.157,6.931c1.185,2.711,2.096,5.771,5.222,7.76c2.693,1.714,2.97,5.621,3.606,8.812
                c1.045,5.24,3.179,10.01,5.94,14.733c-1.965,3.221-0.09,7.211-1.426,10.764"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M848,72.889c-4.166,0.833-8.305,1.874-12.508,2.443c-4.412,0.598-7.497,3.107-10.516,6.034c-4.1,3.977-7.104,3.32-9.977-1.977"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M840.5,435.889c-6.266,1.65-12.859,2.435-18.027,6.969c-0.459,0.403-1.344,0.595-1.967,0.493
                c-3.424-0.559-5.943,1.124-8.544,2.984c-1.538,1.1-3.368,2.151-4.958,1.542c-1.422-1.363-0.938-2.483-0.986-3.49
                c-0.162-3.363-1.945-5.164-5.006-4.446c-4.102,0.962-7.764,3.186-11.969,4.137c-2.772,0.626-2.619,3.679-0.031,5.796
                c1.623,1.329,2.767,3.012,2.922,5.019c0.172,2.244,1.226,4.189,2.65,5.396c2.992,2.531,4.485,5.93,6.416,9.1"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M249,844.889c-1.896-2.238-1.035-3.977,1.647-5.308c-2.898-3.052-4.077-6.475-5.54-10.182c-2.08,1.645-2.76,3.835-4.127,5.473
                c-0.369,0.442-0.474,0.7-0.505,1.015c-0.677,6.717-0.829,6.771-4.428,9.562c-1.021,0.791-2.12,1.373-3.01,0.873
                c-3.228-1.814-7.411-2.364-9.047-6.421c1.299-1.642,3.721-1.384,5.01-3.013c-1.25-3.211-3.901-5.516-5.563-8.646
                c0.487-0.736,1.022-1.547,1.805-2.73c-4.087-2.373-4.564-7.418-7.553-10.683c-1.323,1.098-2.731,1.917-3.595,3.126
                c-0.444,0.622-1.762,1.422-1.056,2.406c2.121,2.961-1.691,6.118,0.365,9.095c0.097,0.14-1.235,1.267-1.94,1.972
                c1.696,0.977,3.38,1.945,5.077,2.922c-0.372,0.373-0.696,0.984-1.04,0.997c-4.84,0.167-9.559,2.141-14.554-0.347
                c-5.384-2.681-11.346-3.664-17.444-3.106c-0.687,0.063-1.469-0.308-2.013,0.487c-0.262,0.382-0.654,0.675-0.962,0.982
                c3.044,3.889,8.315,2.947,11.974,5.525"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1280.5,529.389c-0.021,5.414,0.459,10.891-1.936,16.009c-1.797-0.393-1.553-1.77-1.562-3.009c-0.013-1.759,0.16-3.662-2.5-3.48
                c-0.51,0.034-0.459-0.422-0.533-0.461c-1.887-0.983,0.127-4.432-3.449-4.766c-2.174-0.202-0.95-3.601-1.983-5.314
                c-0.103-0.171-0.036-0.645-0.671-1.068c-4.758,5.692-6.514,12.662-7.907,20.179c-3.095-1.933-1.186-4.769-2.44-6.601
                c-1.227-1.791-2.631-3.477-3.361-6.006c-2.887,2.896-1.535,7.064-4.145,9.529c-2.611,2.467,0.696,6.648-2.742,9.088
                c-3.963-1.827-7.928-3.891-10.65-7.85c-4.836,4.482-0.484,11.349-4.211,15.864c-2.041-0.448-1.759-2.378-2.908-3.114
                c-1.939,0.885-2.433,2.973-2.371,4.494c0.165,4.089-1.291,7.961-1.143,12.006c0.086,2.35-0.013,4.684-2.867,6.476
                c-1.233-5.029-4.034-8.841-7.225-12.517c-2.938,0.883-1.243,5.279-4.395,6.041c-2.438-1.697-1.65-5.008-3.5-7"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1225.5,890.889c1.973,2.734,4.499,4.494,8,4.497c4.176,0.002,7.824,2.516,12,2.5c0.563-0.002,1.02,0.042,1.521,0.479
                c2.846,2.482,6.603,2.53,9.888,3.766c2.142,0.805,3.794-0.309,5.591,0.258"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1135.5,226.889c-0.166,0.5-0.557,1.044-0.467,1.493c1.615,8.123,1.031,16.519,3.743,24.582c1.166,3.464,2.685,6.181,5.257,8.386
                c3.291,2.821,5.029,6.199,4.977,10.539c-0.047,3.949,1.468,7.646,1.943,11.506c0.174,1.413,1.265,1.56,2.07,1.945
                c1.416,0.676,3.029,0.932,4.465,1.575c2.659,1.191,5.099,2.254,5.686,5.947c0.473,2.97,2.326,5.789,3.872,8.501
                c1.587,2.785,2.005,5.176,0.104,8.123c-0.696,1.081-1.938,1.977-1.839,2.678c-0.811,1.791-1.318,0.69-1.81,0.677
                c-7.683-0.195-9.764,3.198-6.522,10.557c1.297,2.943,2.355,5.991,3.521,8.991"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M890.5,29.389c-0.46,4.282,1.02,8.376,1.305,12.514c0.491,7.154-1.828,13.954-5.881,19.934c-0.998,1.472-2.095,2.689-2.466,4.544
                c-1.308,6.543-5.056,11.976-8.519,17.47c-2.176,3.452-8.333,5.466-11.908,3.966c-3.467-1.455-7.281-1.937-10.531-3.927"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M409.5,513.889c2.653,11.091,7.527,21.393,11.659,31.938c1.315,3.357,2.492,6.858,4.251,10.111
                c1.589,2.938,2.215,6.385,3.677,9.408c0.726,1.501,1.412,2.877,1.412,4.543"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M93,151.389c3.723,1.98,5.195,5.925,7.336,9.11c1.852,2.757,1.184,6.793,0.455,9.84c-1.253,5.239-0.417,10.385-0.774,15.551
                c-0.429,6.213,2.079,11.38,5.983,15.999"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M472,484.389c4.404-1.317,8.662-3.617,13.496-1.975c-0.662,3.652-0.628,3.53,2.991,4.525c1.864,0.512,3.97,0.67,4.655,3.414
                c0.397,1.592,2.4,2.916,4.327,1.971c2.26-1.106,3.608,0.231,4.471,1.602c6.917,10.99,17.225,18.81,26.19,27.834
                c5.593,5.629,9.776,11.768,12.362,19.131c2.012,5.728,3.909,11.495,6.481,17.01c0.22,0.473,0.131,1.135-0.474,1.488"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M842,7.389c1.095,3.853,4.195,6.243,7.096,8.37c3.057,2.242,5.602,4.483,6.962,8.109c0.857,2.283,2.486,4.271,3.394,6.541
                c1.396,3.496,2.902,7.061,1.549,10.98"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1183.5,864.889c-0.5,0-1.057,0.146-1.491-0.022c-4.7-1.833-9.44-3.498-14.806-4.264c1.271-0.899,2.541-1.799,3.748-2.654
                c-2.314-2.868-4.595-2.007-6.959-1.607c-4.135,0.697-8.348,2.55-12.457,1.42c-5.312-1.462-10.882,0.235-16.035-1.872"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M698.5,872.889c-3.154-2.516-6.771-4.445-9.492-7.525c0.967-3.244,5.023-1.508,6.492-3.975c-11.961-9.134-11.961-9.134-12-13.057
                c1.785-0.639,3.885,2.127,5.66-0.698c-2.752-6.996-4.548-14.501-6.822-22.628c-2.64,2.908-4.7,5.18-6.836,7.534
                c0.088,1.426,2.162,1.019,2.506,2.38c-1.294,2.494-2.6,5.01-4.092,7.887c3.15,1.718,2.561,4.603,1.002,7.029
                c-2.162,3.368-4.293,6.848-7.867,9.133c-1.094,0.699-2.741,1.522-2.551,3.42"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1160,98.389c-1.674,4.095-5.494,6.221-8.584,8.903c-2.646,2.297-4.435,4.845-5.416,8.097"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M66.5,284.889c0.167,0.667,0.604,1.4,0.458,1.99c-1.338,5.428-0.284,11.099-1.87,16.536c-0.738,2.531-0.347,5.309-0.833,8.021
                c-0.507,2.829-1.271,5.618-1.755,8.454"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M142,843.889c2.29,2.174,5.713-0.186,8.108,1.962c-0.376,3.427-3.062,4.255-5.623,4.989c-1.775,0.508-3.534,1.367-5.486,1.049
                c-0.096,1.202-4.313,0.215-1.594,3.089c0.416,0.44-0.818,1.809-1.908,1.885c-5.261,0.368-9.542,3.11-14.02,5.486
                c-3.718,1.974-7.869,2.823-11.988,3.479c-2.552,0.405-4.84,1.217-7.021,2.531c0.53,0.53,1.03,1.03,1.53,1.53"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M306,795.889c1.257,1.41,1.59,3.895,4.001,3.938c1.319,0.024,2.211,0.845,2.412,1.585c0.797,2.939,3.039,2.455,5.087,2.463
                c2.064,0.008,4.082-0.033,6.012,0.992c3.733,1.981,7.986,2.062,12.012,2.908c1.319,0.277,1.695,1.676,3.331,1.191
                c-1.726,2.053-2.216,3.521-1.854,4.922c0.448,1.736,0.514,1.57,2.979,2.555c1.865,0.744,3.688,0.285,5.523,0.425
                c2.256,0.171,4.676-0.553,6.545,1.478c0.146,0.159,0.954-0.291,1.454-0.457"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M285,424.889c-0.8,4.088,1.299,7.727,2.19,11.455c1.442,6.035,3.213,12.026,4.835,18.039c0.616,2.284,1.703,4.135,3.919,5.592
                c5.175,3.4,10.056,7.249,15.056,10.915"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1041.5,289.389c1.188,4.775-0.836,9.319-1.096,13.995c-0.062,1.125-0.492,2.337-0.377,3.502c0.334,3.354-0.808,6.333-2.588,8.962
                c-1.687,2.49-2.628,5.471-4.939,7.541"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M642,370.889c-2.046,2.876-3.01,5.738-2.181,9.54c0.646,2.964,0.305,6.27-0.23,9.475c-1.046,6.268-0.502,12.649-0.624,18.985
                c-0.023,1.205,0.977,1.909,1.035,3"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M298.5,378.889c2.933-0.179,4.159,2.371,5.396,4.076c4.569,6.299,8.197,13.196,11.055,20.444c0.936,2.372,3.623,3.973,3.049,6.98"
                />
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M348,453.389c0.409,1.167,0.128,2.378,0.184,3.491c0.127,2.571,0.565,5.474,2.366,7.464c6.405,7.075,9.989,15.506,12.479,24.537
                c0.109,0.395,0.638,0.674,0.971,1.008"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M987,112.889c8.484,6.735,18.496,10.378,28.524,13.932c1.618,0.573,3.766-0.454,4.976,1.568"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M725.5,337.389c-0.979,2.275-0.302,4.669-0.5,7"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M480.5,69.889c0.748,4.585,3.227,7.368,7.997,8.021c5.913,0.81,10.792,4.52,16.466,6.111c1.3,0.364,2.89-0.267,4.176,1.251
                c0.71,0.838,2.154,1.137,2.365,2.617c1.37,9.589,9.419,13.423,16.045,18.436c0.636,0.481,1.951,0.064,2.951,0.064"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M418.5,864.889c-0.917,2.676-4.35,2.582-5.392,5.382c1.606,5.662,1.245,6.324-6.108,10.618c-1.93-0.736-3.035-2.198-4.05-3.972
                c-2.095-3.659-4.881-6.234-9.422-7.169c-4.687-0.964-9.278-4.27-12.7-7.521c2.445-3.508,6.132,0.832,8.937-1.453
                c-6.025-7.055-10.272-15.014-12.768-23.884c0.867-0.979,1.808-1.562,3-0.498c1.223,1.092,2.151,0.554,3.048-0.604
                c-2.66-4.43-5.527-8.82-7.994-13.426c-2.25-4.201-1.29-9.561-4.528-13.737c-4.947,5.382-7.52,12.044-11.669,17.327
                c0.355,1.547,1.011,2.115,2.143,1.924c0.958-0.162,1.477,0.397,1.993,1.024c-9.561,10.989-14.266,11.867-25.651,8.44
                c0.975-3.064,4.631-1.913,6.343-4.21c-3.926-1.801-7.998-2.417-12.18-2.212c-4.111,0.201-8.09-0.316-12.001-1.529"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1060.5,298.889c1.664,2.666-1.014,4.337-1.555,6.486c-1.857,7.369-4.041,14.656-5.869,22.033
                c-0.391,1.581-1.408,2.871-1.576,4.481"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M98.5,828.889c3.333,0.309,6.522,1.822,10.071,0.151c1.415-0.667,3.475,0.913,5.429,0.896c6.574-0.062,13.074-0.573,19.511-1.992
                c1.431-0.315,3.053-0.302,4.911,0.66c-1.73,2.88-4.535,3.735-7.427,4.26c-5.984,1.084-11.98,2.131-18.004,2.961
                c-1.809,0.25-3.302,1.139-5.042,1.578c0.141,1.912,1.208,2.582,3.052,2.507c2.163-0.088,4.333-0.036,6.499-0.016
                c2.115,0.021,4.018,0.144,6.079,1.36c3.103,1.832,6.914,1.338,10.414,1.047c2.27-0.189,4.457-1.09,6.507,0.588"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1094.5,437.389c-0.303,1.856-1.831,2.812-3.016,3.984c-2.191,2.168-3.968,4.583-4.984,7.516"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M117,724.889c1.26,2.525,1.494,5.562,5.462,6.227c1.832,0.306,3.42,2.92,4.243,5.356c6.297-1.755,12.604-4.001,18.795-6.583"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M334.5,785.389c-1.413-1.341-2.531-3.141-5.013-2.554c-0.751,0.178-1.628,0.232-2.519-0.401
                c-3.429-2.442-7.389-2.801-11.469-2.557c-2.616,0.157-5.328-0.568-7.393-1.686c-2.143-1.16-3.769-0.186-5.607-0.303"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M981,117.889c8.602,8.33,18.883,13.885,30.034,17.906c1.07,0.386,1.683,1.012,2.466,1.594"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M344,575.389c-2.721,0.706-5.409,1.011-8.005-0.492c-0.918-0.531-2.011-1.22-2.956-0.459c-0.867,0.698-2.084,0.722-2.479,0.382
                c-3.108-2.677-5.959-1.366-9.112-0.055c-1.198,0.499-3.218,0.817-4.458-0.869c-1.651-2.247-4.097-2.032-6.491-2.026
                c-1.231,0.004-2.435,0.283-3.543-0.941c-2.886-3.189-6.203-2.246-9.451-0.528c-1.061,0.562-2.342,0.819-3.006,1.989"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M320.5,729.889c-2.705-1.428-5.645,0.293-8.572-0.809c-1.823-0.686-3.983-1.043-5.91-1.741c-1.589-0.576-3.018-0.562-4.768-0.029
                c0,2.893,0,5.735,0,8.872c1.724,2.022,4.945,1.22,7.25,2.707"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M319,150.389c-0.574-2.929-3.97-3.913-4.645-6.968c-0.29-1.31-2.029-2.613-3.854-2.506c-2.955,0.173-4.509-1.37-4.967-4.032
                c-0.558-3.241-4.922-4.615-3.459-8.266c-3.046-1.222-3.437,0.868-4.043,2.285c-2.188,5.106-2.835,10.618-4.055,15.981
                c-1.006,4.425-2.032,8.86-3.977,13.005"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M933.5,417.389c1.711-1.568-0.06-3.194-0.618-3.907c-4.59-5.861-4.018-12.887-5.145-19.881c-2.024-2.207-5.333-2.531-8.237-3.712"
                />
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M922,137.889c-1.399,0.535-2.963,0.741-3.582,1.492c0.631,9.863,1.652,19.218-0.918,28.508"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M644.5,513.389c-1.443-2.168-4.064-1.467-6.034-2.43c-2.288-1.119-4.958-1.61-7.448-0.997c-3.035,0.747-4.861-1.843-7.51-2.142
                c-3.068-0.347-5.692,0.982-8.501,1.598c-1.382,0.302-2.375,1.832-4.004,1.461c-2.719-0.619-4.792,0.955-6.996,2.023
                c-4.135,2.006-8.113,4.419-13.008,4.009c-1.809-0.151-3.82,0.545-4.905,1.575c-4.041,3.842-8.6,2.276-13.093,1.902"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M426,651.389c0.663-0.836,1.326-1.672,1.991-2.512c1.402,0.527,1.788,1.502,2.098,2.993c0.865,4.157-0.035,8.378,0.792,12.542
                c0.25,1.258,0.696,2.336,1.125,3.458c1.875,0.659,2.915-1.22,4.495-1.484c0.805-0.123,1.064,0.604,1.487,1.015
                c1.536,1.498,0.055,4.393,3.037,5.475c1.622-0.996,0.926-3.428,1.994-4.992c0.793-0.127,0.971,0.689,1.494,0.998
                c1.75-1.195,1.749-3.278,2.555-5.131c4.379,0.355,2.901,6.073,6.518,6.853c3.246-0.907,2.747-5.383,6.104-6.921
                c2.029,4.507,4.645,8.541,7.817,12.901c1.181-1.668,2.08-2.938,2.799-3.954c1.992,0.723,3.088,2.123,4.716,0.784
                c1.633-1.343,2.885-0.312,3.98,0.976"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M208.5,122.389c3.092,0.133,5.415-1.913,7.945-3.116c3.704-1.761,4.397-4.403,3.024-8.875c-1.013-3.297-0.153-6.688-0.853-10.034
                c-0.484-2.312,1.419-4.377,2.974-5.882c1.531-1.483,3.535-0.888,4.9,0.895c-3,5.63-0.685,11.705-1.585,17.082
                c5.912,5.375,12.253,9.842,15.143,17.412c0.307,0.804,1.285,1.352,1.952,2.019"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M347,312.389c1.167,2.5,2.291,5.021,3.538,7.481c0.237,0.467,0.595,0.673,0.467,1.021c-2.923,7.976,1.565,15.314,1.947,23
                c0.155,3.123,0.974,6.697-2.952,8.498"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M851,117.389c-7.617,7.12-16.236,12.86-25.557,17.384c-4.797,2.329-10.155,2.41-15.465,0.684c-2.76-0.897-2.977-2.328-1.979-4.567
                "/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M210.5,770.889c-4.221,3.703-9.078,4.755-14.5,3.503c-4.625-1.067-9.42-1.091-14.021-2.43c-1.722-0.501-3.497,1.894-5.479,0.427"
                />
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M859.5,524.389c-3.784,1.808-4.055,5.842-5.409,9.039c-2.485,5.865-4.509,11.933-6.53,17.981c-0.49,1.468-1.029,2.185-2.561,1.979
                "/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M921.5,282.389c-0.037,2.078,1.658,2.233,3.008,2.447c1.464,0.232,2.992,0.053,4.525,0.053c1.443,1.552,2.174,3.701,4.215,5.146
                c4.145-2.636,8.311-2.299,12.369,1.036c1.758-1.1,3.528-2.208,5.204-3.256c1.992,0.83,3.622,1.892,5.003,3.173
                c2.271-0.205,2.911-2.472,4.67-3.114c1.798-0.655,2.834-3.004,5.586-1.648c1.326,0.653,3.575-1.182,4.957,1.141
                c0.735,1.237,2.832,1.012,3.41,0.843c3.451-1.005,6.413,0.587,9.553,1.178c2.438,0.458,3.541,0.411,5.494-0.512
                c3.179-1.501,6.539-1.875,10.004-2.03c3.341-0.149,6.822,0.909,10.002-0.958"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M147.5,785.389c-3.435,3.721-7.375,7.09-12.508,7.336c-4.366,0.209-8.63,1.293-12.991,1.144c-3.442-0.117-5.99,0.964-6.877,4.739
                c1.206,2.126,3.776,1.184,5.363,1.084c4.209-0.268,7.525,1.451,10.451,3.775c4.43,3.519,9.067,4.931,14.573,3.465
                c0.581-0.154,1.321,0.291,1.988,0.457"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M472,484.889c0.65,2.684,2.243,4.302,4.983,5.061c1.781,0.493,3.271,1.449,3.094,3.945c-0.161,2.277-0.788,3.475-2.982,4.67
                c-2,1.089-4.51,2.251-6.065,2.068c-4.713-0.555-8.61,1.977-13.021,2.359c-5.515,0.477-8.885-0.357-13.042-4.066
                c-2.129-1.898-4.608-3.404-7.368-5.406c3.116-4.169,7.553-5.893,12.412-6.559c2.731-0.375,5.512-0.143,7.496-2.568
                c1.168-1.428,2.788-1.568,4.495-1.516c3.234,0.102,6.654-1.021,9.5,1.512"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M942,100.889c8.309,5.324,14.033,12.746,18.666,21.412c2.242,4.193,3.823,8.765,6.746,12.654c1.303,1.732,2.488,2.439,4.587,2.487
                c4.265,0.096,7.892,2.641,11.986,3.516c0.462,0.099,1.034,0.262,1.501,0.462c4.009,1.721,7.404,1.201,10.014-2.53"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1250.5,290.889c2.484,4.176,6.809,7.178,7.637,12.479c0.162,1.037,1.555,2.205,2.805,3.103c4.247,3.05,8.627,5.934,12.631,9.333
                c0.651,0.552,0.927,0.864,0.928,1.585"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M108,62.389c-1.944,1.731-1.056,4.698-2.967,6.535c-2.037,1.957-4.69,3.308-6.22,6.091c1.386,2.626,1.845,5.279-0.284,7.898
                c-1.081,1.331-0.803,2.693,0.45,3.996c3.028,3.15,6.78,4.516,11.018,5.001c4.705,0.54,8.51,3.288,12.45,5.57
                c0.907,0.525,1.699,1.863,1.592,3.411c-0.286,4.131,0.793,8.052,1.811,12.035c0.449,1.755,0.822,3.961-0.693,6.075
                c-0.868,1.211,0.519,3.962-0.293,5.305c-1.866,3.084-0.762,6.456-1.583,9.654c-1.067,4.154-1.124,8.719-2.414,12.887
                c-0.638,2.062,0.381,3.383,0.634,5.042"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M225,117.889c2.467,0.866,3.038,3.639,5.008,4.988c1.141,0.781,1.019,1.877,1,3.012c-0.032,2,0.005,4-0.012,6
                c-0.024,2.778,0.43,5.284,2.984,7.028c1.699,1.16,2.381,3.088,3.093,4.732c-0.65,2.117-2.71,0.532-3.753,1.99
                c-1.56,7.624,1.297,15.4,0.68,23.25"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1122.5,298.889c-0.109,7.028-2.044,13.924-1.512,21.001c0.087,1.16-0.115,2.349,0.035,3.496c0.463,3.511,1.251,6.442,4.861,8.689
                c3.963,2.468,6.23,6.937,8.115,11.314"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M347,854.389c-3.108,1.059-6.317,0.823-8.406-1.582c-2.915-3.354-6.693-4.092-10.966-5.12c-1.962,3.513-5.208,4.656-9.128,4.202"
                />
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M888,133.889c-0.682,1.77-1.02,3.561-1.045,5.5c-0.086,6.788-1.545,13.39-2.873,20.017c-0.302,1.505-2.091,2.272-1.582,3.983"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M110.5,870.389c-4.324-0.317-8.29,1.566-12.495,2.046c-2.504,0.286-5.101,0.024-7.479,0.569c-3.351,0.769-6.345,2.645-9.026,4.885
                "/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M711,457.889c-3.395-1.064-6.609-0.616-10.033,0.387c-4.004,1.173-8.327,0.907-12.459,0.53c-5.872-0.537-9.862,1.026-12.408,6.628
                c-1.75,3.85-3.896,7.415-6.164,10.913c-1.148,1.772-2.835,3.485-5.436,2.042"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M173.5,785.389c6.68,4.941,8.458,5.502,17.5,5.499c4.409-0.002,8.826-0.051,12.5,3.001"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1225.5,872.889c0.588-1.078,2.405-0.928,2.51-2.482c-1.038-1.934-3.261-0.875-4.414-1.658c-1.629-1.107-3.881-0.623-5.137-2.471
                c0.615-2.277,3.92-0.57,4.652-3.303c-1.566-1.251-3.387-1.934-5.605-2.143c-2.855-0.268-5.76-0.675-8.471-1.551
                c-1.471-0.475-3.843,0.32-4.211-2.872c-0.306-2.649-0.773-4.804,1.819-6.618c1.542,0.718,3.255,1.414,4.905,1.945
                c3.672,1.182,7.351,2.952,11.453,2.166c2.197-0.421,3.998,0.989,5.998,1.488c2.271,0.568,4.637,1.543,6-1.502"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M884,509.389c-1.297,0.651-1.465,2.451-3.492,2.604c-3.814,0.288-5.145,4.508-8.402,6.111c-1.395,0.686-2.51,3.424-2.13,5.789
                c0.022,0.137-0.309,0.33-0.475,0.499c-1.299,1.241-3.146-0.186-4.52,0.975c-1.116,0.942-2.308,1.932-3.981,1.522"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M78.5,330.889c3.745-2.238,7.344-0.789,10.444,1.091c5.009,3.038,9.126,7.397,14.521,9.98c2.002,0.958,2.61,3.374,2.979,5.438
                c0.688,3.851,2.374,6.401,6.547,7.04c2.993,0.458,4.432,2.647,5.582,5.419c2.599,6.264,5.593,12.364,8.426,18.531"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M115,425.889c2.113-2.059,3.935-4.552,7.517-4.154c0.333,0.037,1.952-0.492,2.454-1.362c1.034-1.79,2.235-2.881,4.535-2.524
                c2.61,0.405,4.828,1.757,5.319,4.078c0.408,1.93,1.946,1.554,2.648,2.482c1.429,1.889,3.14,1.872,5.021,0.971
                c2.747-1.314,4.972-0.114,6.974,1.546c1.224,1.015,2.159,1.971,4.024,2.078c2.34,0.134,2.046,3.114,3.506,4.386"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M79,333.389c4.36,4.33,8.316,8.763,8.592,15.496c0.151,3.688,1.059,7.375,3.353,10.544c0.396,0.547,0.056,1.626,0.056,2.459"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M192.5,840.889c-0.503,0.328-0.686,1.059-1.5,1.012c-7.034-0.415-13.958,1.639-20.997,0.956c-1.562-0.151-2.605,0.404-3.509,1.54
                c2.721,2.926,6.645,2.442,10.023,3.432c4.26,1.249,8.656,2.033,12.979,3.073c1.957,0.471,3.705,1.373,5.387,3.017
                c-2.413,2.404-5.309,2.345-7.858,1.839c-2.214-0.438-3.874-0.2-5.526,1.132"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M123.5,686.889c0.667,0.334,1.46,1.09,1.98,0.934c4.761-1.429,9.341,0.743,14.061,0.649c-0.095,1.53-0.853,2.298-2.539,2.444
                c-6.683,0.58-12.73,3.444-18.985,5.525c-2.189,0.729-4.61,0.939-6.518,2.447"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M227.5,910.889c-7.667-2.334-15.311-4.746-23.019-6.932c-1.521-0.432-3.444-0.458-4.539,1.548
                c1.029,3.558,2.086,7.21,3.123,10.794c-0.496,2.067-3.319,1.951-3.563,4.09c-0.402,1.663,1.394,2.838,0.998,4.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M425,413.389c3.723,4.251,5.371,9.649,7.951,14.526c1.6,3.024,3.406,5.053,7,6.138c5.268,1.591,9.724,5.159,14.587,7.766
                c2.242,1.202,2.146,3.298,2.956,5.073c2.354,5.152,4.015,10.537,5.589,15.974c0.716,2.474,0.159,4.747-0.182,7.008
                c-0.389,2.58-3.09,3.482-4.775,5.143c-6.31,6.219-14.388,5.813-22.126,6.864c-0.491,0.067-1.048-0.128-1.492,0.029
                c-8.991,3.187-18.324,1.54-27.507,1.979"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M614.5,428.889c-5.739-2.293-11.341-0.191-17.004,0.468c-3.654,0.425-7.239,1.694-10.996,1.032"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M566.5,392.389c9.159,2.328,17.595,6.949,26.994,8.534c0.379,0.064,0.672,0.632,1.006,0.966"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M820.5,443.889c-3.303,4.803-5.596,10.297-11.428,13.147c-2.988,1.459-3.953,5.21-5.047,8.362c-0.5,1.438-1.09,2.792-2.025,3.991"
                />
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M568,379.889c1.524,4.638-0.374,8.728-2.611,12.433c-1.654,2.741-1.653,5.913-3.318,8.61c-1.901,3.081-3.618,6.218-6.023,8.998
                c-3.595,4.156-7.167,8.401-8.599,13.945c-0.112,0.434-0.525,0.664-0.463,1.016c0.685,3.883-1.722,6.265-3.834,9.11
                c-1.756,2.365-3.104,5.562-1.651,8.888"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M253.5,810.889c-1.502-0.148-2.829,0.604-4.574-0.367c-3.319-1.848-7.177-0.645-10.368,0.526
                c-4.448,1.632-8.13,0.038-12.058-1.159"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M251.5,883.889c-1.512,1.389-4.006,1.482-4.971,3.514c-1.236,2.602-3.838,2.939-6.54,4.217c-0.001,2.061,0.41,4.705-2.182,6.266
                c-2.833-1.546-6.74-0.701-9.316-3.488c1.979-1.793,5.187-1.788,6.493-5.031c-2.848-3.453-7.262-3.469-11.483-3.977"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M874.5,591.389c-1.568,0.111-1.744,1.795-1.873,2.522c-0.535,3.04-2.041,4.428-5.125,4.521c-2.373,0.07-3.369,2.075-3.389,3.958
                c-0.02,1.978-1.369,3.562-1.11,5.499c0.272,2.034-1.151,2.99-2.569,4.662c-1.944-2.959-2.947-5.752-3.363-8.672
                c-0.236-1.662-1.001-3.084-1.689-4.43c-0.619-1.213-2.277-0.996-2.828,0.459c-0.611,1.615-1.684,3.172-1.514,4.977
                c0.127,1.348-0.969,1.713-1.531,2.512c-0.842-0.842-1.674-1.674-2.508-2.508"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M843,556.889c-1.728-0.439-3.373,0.258-4.985,0.576c-2.667,0.525-4.799,0.777-7.12-1.467c-2.127-2.057-5.512-1.943-8.395-2.611
                c-0.953-0.221-1.391,0.498-2.012,0.986c-4.049,3.182-4.056,3.16-8.506-0.463c-0.199-0.164-0.648-0.021-1.687-0.021
                c-1.606,0.246-2.333,2.757-4.227,3.539c-2.784,0.843-3.479-3.123-6.425-2.458c-1.383,0.453-1.133,2.69-2.645,3.419
                c-2.867,0.458-3.263-2.969-5.713-3.551c-1.706,3.713-4.82,4.982-9.251,4.443c0.127-3.325-2.378-4.924-5.036-6.393"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M268.5,826.389c2.886-1.574,1.175-3.915,1.525-5.771c-3.633-2.006-6.714-0.19-9.474,0.48c-4.497,1.094-9.041,1.512-13.553,2.281
                c-1.295,0.221-2.517-0.008-3.555-1.047c0.122-1.899,1.674-1.984,3.046-2.475c2.68-0.959,5.545-1.433,8.011-2.969"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M909,138.889c-0.545,5.833,1.064,11.618,0.118,17.519c-0.531,3.313-1.141,6.484-2.618,9.481"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M64,351.889c-0.667-5.667-0.999-11.401-2.139-16.972c-0.584-2.859-2.455-4.666-6.326-5.26
                c-10.277-1.578-16.147-1.781-24.036,1.231"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M777,330.389c1.48,5.292,1.324,10.656,0.457,15.993c-0.461,2.835-1.051,5.528,0.984,8.054c0.26,0.323-0.275,1.287-0.441,1.953"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1120.5,357.889c1-1.833,2.149-3.605,2.934-5.527c0.367-0.898,1.142-1.889,1.563-1.876c4.056,0.119,5.824-2.634,7.518-5.589
                c0.227-0.395,0.61-0.737,0.992-0.999c2.867-1.963,7.105-1.058,9.368-3.621c2.332-2.642,4.938-4.253,8.091-5.478
                c0.905-0.352,1.7-0.378,2.534-0.386c2.451-0.022,4.652,0.056,7.102-1.35c2.963-1.702,6.646-0.997,9.889,0.348
                c2.124,0.881,3.48,2.847,5.436,4.094c3.518,2.245,6.906,4.522,11.055,5.469c0.408,0.093,1.602,0.165,1.602,1.414
                c0,0.5,1.107,1.523,1.39,1.421c4.124-1.497,7.062,1.721,10.564,2.389c2.829,0.54,5.027,1.135,6.472,3.686
                c1.091,1.925,2.875,2.594,4.992,2.5c3.249-0.146,5.34,1.219,6,4.505"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1000,426.889c-0.227,2.991,1.42,5.303,3.043,7.467c3.038,4.05,5.355,8.292,4.93,13.531c-0.168,2.073,1.549,3.526,1.527,5.502"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M9.5,324.889c1-0.667,2.154-1.184,2.974-2.026c3.731-3.835,7.673-1.058,11.518-0.42c0.729,0.121,1.861,0.886,1.928,1.455
                c0.586,5.051,5.704,6.858,7.684,10.941c0.817,1.686,1.63,3.163,1.292,5.356c4.442,1.016,7.861,5.319,13.024,3.571
                c1.558,1.674,2.288,3.823,4.125,5.124c1.537,0.056,1.415-2.866,3.846-1.289c1.49,1.251,4.937,1.507,5.61,4.787
                c2.623-0.448,4.345,0.762,5.491,3.004c0.067,0.132,0.009,0.329,0.009,0.496"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M314.5,719.389c-4.331-0.271-8.662-0.541-12.968-0.811c-0.346,3.33-0.284,3.209,1.952,4.341c2.871,1.452,5.873,2.36,9.008,3.005
                c1.95,0.402,3.805,1.191,5.007,2.965"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M864.5,125.389c-2.993,6.613-6.392,12.968-10.312,19.12c-1.717,2.692-4.321,4.538-5.688,7.38"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1201.5,785.889c2.577,1.512,5.426,2.463,8.074,3.86c1.338,0.706,2.129,0.965,2.521,2.617c0.564,2.378,2.744,2.601,4.904,2.54
                c2.707-0.074,5.416-0.018,8.403,0.559c-1.845,2.239-1.435,4.377-1.384,6.424c0.056,2.195,1.91,2.423,3.475,3.016
                c5.883,2.228,11.09,5.615,16.135,9.281c-1.313,2.074-3.416,1.652-5.129,2.203"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M333.5,785.889c-3.917,1.328-7.577,2.204-12.056,1.266c-5.108-1.068-10.683-0.742-15.944,0.734"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1104,307.389c0,4.833-0.113,9.67,0.036,14.499c0.12,3.903,0.651,7.78,1.964,11.501"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M265,862.889c-1.997,2.421-3.34,5.525-7.516,5.229c-0.829-0.058-4.477,0.354-3.586,3.798c0.273,1.057-1.068,1.694-2.38,2.041
                c-3.488,0.922-6.298,3.14-9.268,5.534c5.486,3.334,11.411,2.871,17.25,2.898"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1047.5,274.889c-1.047,7.305-4.082,12.496-8.982,15.528c-0.465,0.288-0.771,0.414-1.005,0.505
                c-4.979,1.927-4.913,1.971-7.027,5.959c-3.109,5.864-6.319,11.674-9.485,17.508"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M394,260.389c0.263,1.516-0.588,3.158,0.966,4.538c1.929,1.712,4.018,3.241,6.538,3.393c5.317,0.32,10.1,2.347,14.996,4.069"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1115,573.389c-0.879-1.58-1.276-3.482-3.037-4.505c0.305-1.528,2.346-1.97,2.127-4.005c-0.249-2.309-1.717-3.548-3.301-5.213
                c-3.613,3.033-8.045,2.357-12.286,2.162c-5.593-0.258-10.558,0.817-14.253,5.225c-0.75-0.498-1.25-0.831-1.75-1.164"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M661,389.889c2.297,1.52,4.646,0.641,6.441-0.586c3.233-2.208,6.305-4.52,10.009-6.035c1.975-0.808,3.624-2.661,4.569-4.871
                c1.084-2.536,2.428-4.963,3.445-7.522c0.832-2.097,1.471-4.087,0.176-6.56c-1.03-1.968-0.988-4.584-1.097-6.928
                c-0.149-3.23-2.06-5.898-2.567-8.994c-0.086-0.527-0.453-1.42-0.73-1.436c-1.739-0.099-2.875-2.204-4.223-1.947
                c-3.953,0.752-7.572-3.173-11.523-0.621"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1168,375.889c-1.166-1.167-2.66-2.149-3.44-3.534c-2.599-4.617-6.583-4.027-10.529-2.863c-1.411,0.416-2.728,0.418-4.026,0.338
                c-2.938-0.182-5.602,0.336-8.134,2.4c-1.849-2.528-5.972-2.687-5.938-6.842c0.004-0.496-1.122-1.522-1.402-1.42
                c-3.389,1.233-4.74-2.094-6.964-3.21c-6.412-3.217-9.397-3.249-15.082,1.143c-1.593,0.096-2.45-1.112-3.483-2.013"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M857.5,120.389c-8.092,8.316-16.315,16.484-26,23"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M899.5,137.889c0.637,8.732,1.619,17.484-1.5,26"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M139.5,449.889c0.167-0.167,0.32-0.35,0.502-0.498c1.39-1.13,3.207-0.819,4.461-0.395c3.99,1.352,8.224-1.604,12.033,0.897
                c1.836,1.206,4.474-0.506,5.971,1.519c1.525,2.063,3.901,0.761,5.467,1.599c2.577,1.379,5.24,3.171,6.934,5.474
                c1.978,2.69,4.433,5.159,5.632,8.403"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M643.5,529.889c-4.212-1.525-8.475-2.6-12.985-3.125c-5.037-0.586-10.075-1.005-15.106-2.584
                c-4.647-1.458-9.892-1.755-14.911-2.272c-1.502-0.154-3.059,0.164-4.497-0.521c-2.103-1.003-4.083-0.646-6.001,0.502"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M257.5,852.389c1.174,0.99,2.349,1.981,3.505,2.957c-0.049,1.15-0.789,1.84-1.505,2.543c-0.167,0.334-0.021,0.705,0.504,0.993
                c1.708,0.934,3.419,1.813,5.038,2.947c2.737,1.917,6.091,1.546,8.399-0.993c1.48-1.629,3.041-2.227,5.057-1.935
                c2.447,0.354,3.553-1.187,4.763-3.273c-5.711-1.274-11.209-2.651-16.773-3.669c-2.304-0.422-4.564-1.848-6.976-1.037
                c-3.804,1.277-7.969,1.482-11.463,3.549c-1.169,0.691-2.696-0.283-3.732,1.297c-0.281,0.43-3.031,0.957-4.361-0.846
                c-1.066-1.445-2.887-1.039-4.455-1.026c-2.714,0.022-5.4-0.142-8-1.007"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M229.5,868.889c2.04,1.754,5.252,1.162,7.286,3.882c-10.196,2.646-20.235,1.813-30.286,0.618"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M876.5,131.389c-1.334,3.833-2.702,7.655-3.991,11.503c-1.743,5.203-5.009,9.521-8.009,13.997"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M544,645.389c-0.669-0.998-1.338-1.997-2.323-3.467c-1.978,5.025-7.44,7.572-7.754,13.463c-0.085,1.584-2.568,2.244-3.335,0.951
                c-0.808-1.361-2.739-1.998-1.863-4.487c-3.904,2.505-2.337,6.703-4.724,9.04c-0.168,0.165-0.334,0.334-0.5,0.5
                c-0.167-0.166-0.333-0.334-0.5-0.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M801.5,470.389c-0.908,3.604-1.596,7.207-5.972,8.589c-2.048,0.646-3.319,3.076-3.373,4.915c-0.112,3.882-2.524,6.455-4.155,9.496
                "/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M202.5,87.889c-2.306-0.941-3.479-3.148-4.381-5.057c-1.909-4.038-5.208-5.787-9.12-5.813c-4.233-0.029-8.25-1.765-12.501-1.147
                c-0.816,0.119-1.978-0.337-2.444,0.077c-2.284,2.027-4.723,1.285-6.468-0.163c-2.187-1.813-4.083-0.383-5.502,0.279
                c-1.851,0.864-3.656,1.995-5.663,3.154c-2.518-2.396-5.449-3.892-8.416-5.338c-2.882-1.405-6.035-2.304-9.027-4.771
                c2.923-3.461,5.922-6.927,6.029-11.901c-1.849-1.529-4.011-3.04-6.095-4.153c-4.275-2.286-8.995-1.717-13.404,0.349
                c-2.023,0.948-3.373,3.254-5.73,2.49c-1.333,1.996-0.683,3.496-0.276,4.995c-1.913,0.928-3.749,1.995-6,2.031
                c-1.873,0.03-3.996,0.147-4.918-1.574c-1.585-2.956-4.109-2.47-6.582-2.442c-2.724,0.03-5.239,0.759-6.98,3.002
                c-1.682,2.167-3.843,3.265-6.52,3.484"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M262.5,904.889c1.37-1.609,0.933-3.586-1.009-3.954c-1.287-0.243-2.665-0.134-3.99-0.031c-6.255,0.483-12.179-0.947-18.001-3.015"
                />
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M167,472.889c1.826,2.649,4.306,4.691,6.501,6.999c0.325,0.341,0.728,1.001,0.99,0.952c5.701-1.057,5.458,4.605,7.951,7.107
                c1.521,1.526,0.588,3.207-0.941,4.441"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M265,831.889c0.5,0.5,1,1,1.542,1.542c-2.678,2.565-6.824,1.073-9.583,3.798c-0.083,1.095,1.17,1.938,1.365,2.593
                c-4.717,6.613-12.769,6.327-18.864,10.001c-1.21,0.729-2.969,0.221-4.479,0.41c-3.574,0.446-7.056,1.093-10.48,2.156"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1082.5,310.389c-0.547,7.226-5.309,12.35-9.01,17.993c-2.029,3.092-4.982,5.348-7.49,8.007"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M585,519.889c1.18,0.975,3.001,0.664,4.378,2.506c-1.71,8.412-5.644,16.516-8.378,24.994"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1009,429.889c-0.172,2.692,1.184,4.812,2.469,7.018c1.6,2.748,2.491,5.744,2.555,8.981c0.047,2.418,0.104,4.535-2.438,6.137
                c-1.084,0.683-1.962,2.396-2.793,3.735c-1.487,2.396-2.54,5.422-6.24,6.34c-2.356,0.584-3.314,3.754-4.055,6.288
                c-0.653,2.235-0.857,4.645-2.498,6.501"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M208,737.889c0.048-3.254,0.192-6.521-1.525-9.543c-3.48-2.022-4.54,1.147-5.311,3.107c-0.621,1.578-2.177,3.356-2.619,3.249
                c-2.653-0.644-3.87,2.271-6.042,2.129c-1.84-0.12-3.959,0.981-5.504-0.942"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1059.5,48.889c0.783,0.583,1.163,1.614,2.502,1.536c5.605-0.33,10.956,1.611,16.493,2.026c1.423,0.107,3.047,0.613,3.505,2.438"
                />
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1110,209.389c-1.525-0.716-1.859-2.437-2.977-3.523c-1.904-1.853-2.96-3.783-1.135-6.55c0.689-1.045,0.111-2.926,0.111-4.426
                c-9.461-4.753-9.461-4.753-15-3"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M932,102.889c-0.122,3.374,0.308,6.604,3.025,8.971c1.029,0.896,1.13,1.91,0.953,3.026c-0.262,1.663,0.562,3.031,1.168,4.38
                c-0.758,1.734-2.059,2.441-3.648,2.603c-1.589,0.161-2.818,0.448-2.533,2.525c0.119,0.876-0.631,1.668-1.452,1.448
                c-3.176-0.853-5.898,1.539-9.017,1.072c-2.982-0.446-6.222-0.37-8.938-1.212c-6.956-2.155-12.053,0.687-17.329,4.58
                c-1.808-2.893-1.848-5.764,0.202-8.938c1.398-2.165,3.905-3.816,3.607-6.959c-0.149-1.576,1.659-1.663,2.519-2.434
                c1.577-1.417,4.03-1.788,4.943-4.062"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M561,399.889c-1.321,0.268-2.618-0.188-4.033,0.424c-3.049,1.32-6.359,1.127-8.901-1.002c-2.484-2.082-5.914-2.453-8.048-4.937
                c-0.62-0.721-1.41-0.626-2.005-0.443c-2.685,0.827-4.649-0.391-6.513-2.042"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1165,313.889c3.428,5.336,6.115,11.132,10.918,15.588c2.133,1.979,3.143,4.946,1.082,7.912"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M782,342.389c0.181,2.08,0.602,4.062,1.451,6.021c1.172,2.701,1.171,5.908,0.383,8.426c-1.855,5.924-0.149,11.172,1.666,16.552"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M246,342.389c5.56,6.708,6.982,14.585,6.5,23"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M280.5,720.889c3.528-1.854,7.329-0.828,11.002-0.94c2.323-0.071,3.967-0.606,4.633-3.016c-2.144-2.26-4.625,1.663-6.655-1.062
                c0.652-2.374,3.91-3.41,4.661-6.447c0.55-2.221-0.027-5.239,2.791-6.781c2.035,0.813,2.389,3.453,4.516,3.334
                c0.253,1.99-1.282,2.412-1.949,3.414c0.938,3.473,3.018,5.842,6.48,7.057c0.675,0.236,2.019,0.526,1.521,1.941"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M878,548.389c2.67-1.607,5.086-3.617,6.938-6.047c2.678-3.513,5.966-5.971,10.271-7.529c3.956,2.994,5.988,6.961,5.812,12.077
                c-0.041,1.226-0.516,2.711,0.984,3.487c2.476,1.279,2.673,5.207,6.496,5.086c4.197-0.131,6.118,2.963,7.499,6.426
                c1.663,4.168,3.333,8.334,4.999,12.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1143,296.889c-0.808,6,1.457,11.998,0.016,18.003c-0.114,0.473-0.232,1.204,0.017,1.465c3.741,3.925,4.417,10.021,9.431,13.092
                c1.424,0.873,2.915,2.252,2.037,4.439"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1066,18.389c0.164,2.207-0.528,3.961-3,3.963c-6.478,0.005-12.275,2.956-18.479,4.145c-1.488,0.285-2.936,1.013-4.52,0.88
                c-2.307-0.193-4.093,0.911-6.032,1.955c-3.348,1.803-6.635,3.838-10.469,4.558"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M350,418.389c-2.501-1.014-3.04-3.64-4.473-5.521c-2.014-2.643-3.49-5.733-6.756-7.611c-2.831,1.333-5.801,2.732-8.771,4.132"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M748.5,696.389c0.646-4.072-0.354-7.323-5.062-9.229c-1.67,4.529-2.705,9.428-6.172,13.508c-2.242-4.959-1.393-11.42-7.366-14.394
                c-1.513,0.974-1.78,2.608-1.849,4.116c-0.116,2.553-0.811,3.568-3.575,2.565c-1.953-0.708-2.718,1.478-3.976,2.433"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M245,338.389c1.315,3.5-0.299,6.991-0.042,10.503c0.196,2.666,0.29,5.061,1.885,7.596c2.005,3.188,3.038,7.026,5.685,9.875
                c1.392,1.499,1.155,3.304,1.472,5.026"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M187.5,439.389c1.412-0.071,2.531,0.806,3.457,1.553c3.756,3.028,7.901,5.042,12.493,6.596c3.162,1.07,6.225,2.317,9.542,2.9
                c1.056,0.185,2.537,0.695,2.922,1.493c2.378,4.922,7.644,4.849,11.642,6.847c1.81,0.904,3.104,1.705,4.001,3.584
                c1.77,3.707,4.481,5.521,8.407,7.596c4.813,2.546,9.482,5.359,14.537,7.432"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M332.5,338.389c-0.304,3.335-2.629,5.848-3.656,8.948c-1.43,4.318-1.624,8.992-3.844,13.052"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M46.5,454.389c3.097-1.85,6.348-2.93,10.043-2.338c-0.517,3.187,2.111,5.359,1.568,8.357c-0.266,1.466,1.737,2.348,2.892,2.379
                c3.42,0.093,5.734,1.817,8.062,4.033c1.12,1.065,2.935,1.402,4.435,2.068"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M106.5,68.389c6.192,4.404,12.355,8.809,19.966,10.641c0.864,0.208,2.42,0.614,3.034,1.859"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M715.5,474.889c-1.616-3.837-1.643-8.018-2.361-12.025c-0.574-3.208-1.717-6.241-2.331-9.438
                c-0.986-5.127-5.366-6.782-10.808-5.035c-3.68,1.181-7.256,2.521-9.508,5.993c-0.993,1.532-3.072,1.866-3.992,3.505"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M328.5,818.389c2.609,2.171,5.9,2.76,9.026,3.364c1.673,0.324,3.251,1.081,5.424,1.208c1.193-3.451,4.678-3.148,7.549-4.072"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M157.5,592.389c0,0.5,0.01,1-0.001,1.5c-0.104,4.516,0.223,8.805,5.558,10.178c-0.343,2.035-2.412,1.966-3.159,3.538
                c1.826,2.755,5.267,3.578,7.604,5.782c-0.514,3.145-3.333,1.674-5.002,2.502"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M259.5,785.889c2.451,1.425,5.406,1.637,7.64,3.859c1.005,0.999,3.529,0.447,5.359,0.648c1.666,0.184,3.31-0.014,5.027,0.377
                c2.215,0.505,5.194-0.338,3.065,3.664c-0.06,0.113,1.242,0.951,1.908,1.451"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M926,168.389c1.211,7.429-1.01,14.11-4.522,20.488c-0.49,0.891-1.586,1.109-2.42,1.608c-3.784,2.267-6.971,5.315-9.521,8.93
                c-0.905,1.283-1.515,2.685-0.607,4.509c1.011,2.034,2.38,1.667,4.075,1.517c2.16-0.191,4.366-0.197,6.496,0.448"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M975,123.889c6.028,4.779,12.293,9.231,19.021,12.96c3.711,2.056,7.604,3.801,11.501,5.488c1.338,0.579,2.862-0.083,3.951-0.98
                c2.091-1.724,4.382-3.375,5.954-5.521c3.526-4.815,7.466-9.168,12.047-12.979c0.524-0.437,0.691-1.302,1.025-1.969"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M778,373.389c-0.318,7.975-3.314,15.097-7,22"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M137.5,661.889c-2.279,3.406-6.194,4.22-9.571,5.75c1.146,3.785,3.91,3.972,7.055,4.375c4.189,0.537,8.684-0.134,12.523,2.154
                c-0.071,1.855-4.371,1.373-2.008,4.221c-0.784,0.582-1.327,1.273-2.474,1.593c-5.462,1.522-10.647,4.108-16.527,3.93
                c-1.064-0.032-2.108,0.315-2.999,0.978"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M552,664.889c-1.65,0.805-2.681,2.317-4.255,3.824c-2.836-3.928,1.217-9.818-5.095-13.045c-3.374-0.55-1.686,5.626-5.612,5.875
                c-1.115-0.835-1.238-3.146-3.038-4.154"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M345,153.389c-2.412-1.787-3.594-4.682-5.92-6.598c-0.446-0.367,2.155-1.838-0.493-3.087c-1.822-0.86-3.399-0.66-5.089-0.792
                c-4.913-0.386-9.653,1.094-14.498,1.477"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1040.5,42.889c4.334-1,8.679-1.954,12.983-3.065c0.478-0.123,0.997-0.588,1.51-0.415c5.917,1.997,12.014,0.53,18.007,0.985
                c1.469,0.111,3.257-0.578,4,1.495"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M665.5,862.889c1.287,1.095,2.032,2.17,1.051,4.027c-1.199,2.268-2.311,4.604-4.084,6.44c-2.355,2.439-3.215,5.567-4.834,8.165
                c0.533,0.534,0.738,0.677,0.861,0.871c1.139,1.803,2.973-2.033,4.004,0.496c0.814,1.997,0.686,3.961,0.029,6.009
                c-1.112,3.47-2.748,6.681-5.586,8.917c-1.977,1.56-3.542,3.48-5.441,5.074"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M276.5,775.889c-1.229,0.748-2.554,1.065-4,1.008c-6.224-0.249-11.88,1.463-17,4.992"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M673,512.889c-1.518-1.662-3.336-1.361-4.971-0.447c-1.206,0.676-2.764,0.83-3.459,0.346c-2.623-1.832-5.44,0.361-8.103-0.826
                c-2.406-1.072-4.881,0.701-7.474,0.631c-1.91-0.053-4.393,0.234-6.039,2.26c-1.902,2.342-4.735,2.073-7.455,2.068
                c-3.188-0.004-6.38-0.369-9.536,0.878c-1.438,0.568-3.11-0.132-4.069,2.045c-1.019,2.312-4.923,1.172-5.896,4.046"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1044,501.389c-2.441-5.754-2.926-11.934-4.188-17.961c-1.294-6.184,0.23-12.496-1.741-18.562
                c-0.595-1.833-0.782-3.609-3.071-3.977"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M302.5,128.889c2.589-0.178,4.052,1.987,5.927,3.121c1.9,1.149,3.55,2.777,5.559,3.905c4.179,2.347,5.21,6.01,4.015,10.474"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1146,294.889c2.233,2.082-0.178,5.403,2,7.5c-0.406,5.729,2.622,9.218,7.5,11.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1280.5,176.889c-2.195-0.145-4.385,0.339-6.533-0.944c-3.035-1.813-5.826-2.687-9.621-0.3c-2.905,1.828-7.043,2.302-10.846,2.253
                c-2.378-0.031-4.788-0.191-7,0.991"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M88.5,464.889c-1.839,1.442-4.651,1.178-6.017,3.49c-0.583,0.986-1.656,0.512-2.482,0.48c-3.95-0.145-7.582,1.377-10.622,4.66
                c2.929,0.63,3.597,2.907,4.143,5.364c0.531,2.388,2.078,4.126,4.485,4.448c4.358,0.585,7.241,3.052,9.492,6.557"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M570,364.389c-0.438,4.349-3.307,6.969-6.466,9.542c-3.289,2.677-7.232,4.76-9.034,8.958"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M989.5,543.889c0.834,0.666,1.82,2.03,2.475,1.886c3.932-0.868,5.554,3.563,9.021,3.706c0.693,0.029,1.832,1.179,1.927,1.918
                c0.888,6.979,6.765,9.666,11.706,12.242c4.607,2.403,9.639,4.394,13.73,7.438c4.584,3.411,8.853,3.09,13.641,1.811"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M87.5,879.389c-4.396,1.207-8.036,3.928-12.261,5.434c1.279,2.697,3.917,1.588,5.57,3.219c-2.461,2.21-6.126,1.547-8.314,3.854
                c0.522,1.387,1.396,2.06,3.007,2.025c6.066-0.129,12.261,1.01,18.033-1.965c0.06-0.031,0.633,0.6,0.966,0.434"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1224.5,774.889c1.033,1.4,3.324,1.123,4.021,2.959c-0.54,2.053-2.339,1.858-3.49,1.454c-2.656-0.933-4.58,0.069-6.536,1.579
                c-3.582,2.77-7.586,3.95-11.979,2.463c-2.689-0.911-4.359,0.346-6.016,2.045"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M751.5,393.389c1.114,2.777,0.592,5.698,0.414,8.495c-0.296,4.678,0.968,8.501,4.586,11.505"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M160,52.889c-3.757,1.93-7.635,1.04-11.5,0.502c-2.826-0.393-5.578-1.425-8.5-1.002"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M210.5,874.389c1.512,3.553,5.312,3.088,8.043,4.41c3.829,1.854,7.957,3.09,11.957,4.59"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M98,776.889c1.583-4.084,6.746-4.588,8.612-8.418c-0.693-1.947-2.256-1.568-3.612-1.568c-1.133,0-2.563,0.255-2.945-1.029
                c-0.984-3.311-3.466-3.285-6.051-2.953c-1.989,0.256-3.313-2.664-5.504-1.031"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M583,735.389c-1.277-1.098-1.106-2.605-0.971-3.997c0.297-3.065-0.375-5.664-3.093-7.405c0.39-0.769,1.14-1.909,1.003-2.027
                c-2.637-2.283-2.871-5.773-4.444-8.567c-0.294-0.522-0.661-0.669-1.649-0.176c-1.028,2.927-1.767,6.728-4.846,9.173"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M951,202.389c-2.587,0.018-5.18,0.053-5.52-3.498c-0.018-0.181-0.286-0.429-0.484-0.488c-4.768-1.418-6.773-5.891-9.996-9.014"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M561.5,432.389c-3.454,0.015-5.796,2.511-8.447,4.089c-6.763,4.025-13.478,8.049-21.048,10.427
                c-2.837,0.891-5.241,2.451-7.563,4.415c-5.266,4.45-10.338,9.199-16.441,12.569"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1186.5,744.389c-0.166,0-0.36-0.057-0.496,0.008c-4.172,1.99-8.727,3.463-12.382,6.158c-2.987,2.201-5.812,4.596-9.036,6.021
                c-0.59,1.425,1.036,2.254,0.391,3.299c-0.791,1.282-2.707,1.124-3.664,2.861c4.071,2.604,8.167,0.485,12.188,0.153"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1028,610.389c0.124,2.436-1.414,5.705-4,8.5c-3.645-0.352-0.125-6.674-5.109-5.949c-1.43,0.671-0.784,2.32-1.325,3.479
                c-0.478,1.023-0.948,1.595-2.065,1.464c-1.172-0.139-1.702-0.895-1.477-1.989c0.305-1.47-0.665-2.185-1.523-3.005"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M157,596.389c-1.292,0.072-1.308,1.207-1.467,2.007c-0.668,3.356-1.545,6.325-6.04,5.528c-0.862-0.152-1.356,0.566-1.505,1.463
                c-0.158,0.947,0.172,1.524,0.999,2.023c1.271,0.768,3.831,0.893,2.013,3.479"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M569.5,416.889c-1.747-1.427-4.177-2.815-5.912-1.843c-3.572,2.001-8.365,2.156-10.534,6.371
                c-0.236,0.459,0.279,1.306,0.446,1.972"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M238,305.389c3.423,2.242,3.59,6.23,4.919,9.532c1.117,2.773,1.547,5.823,3.081,8.468"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1235,535.389c1.428-3.205,0.365-7.157,3.584-10.056c-4.003-3.046-7.606-5.688-12.585-5.471c-1.784,0.078-3.79,0.55-4.998-1.474
                c-0.359-0.604-0.956-0.607-1.498-0.486c-4.149,0.923-8.355,0.533-12.627,2.161c-4.093,1.561-9.231,3.048-13.858,0.295
                c-0.958-0.57-1.684-0.75-2.518,0.03"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M942,516.889c-0.854-1.887-1.678-3.963-3.539-4.924c-1.863-0.963-2.888-3.613-5.451-3.031c-1.72,0.39-3.287-1.43-5.053-0.103
                c-0.389,0.292-1.312,0.233-1.981-0.417c-3.12-3.033-6.499-1.447-8.828,0.639c-1.782,1.597-4.608,1.41-5.647,3.836"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M135.5,723.889c-2.757-1.467-5.563-1.064-8.509-0.555c-6.117,1.059-12.322,0.502-18.491,0.51c-0.666,0.001-1.333-0.621-2-0.955"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M210.5,720.889c-1.688,1.623-4.505,0.6-6.178,2.869c-0.542,0.736-3.19,0.372-4.829-0.354c-2.377-1.051-4.771-2.185-7.493-1.266
                c0.167-0.584,0-1-0.5-1.25"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1206,759.889c2.284,4.879,6.567,5.072,10.993,4.449c3.127-0.439,3.208-0.744,6.459,3.092c1.545,1.822,4.312,1.352,5.535,3.509
                c-0.887,3.425-4.251,0.983-5.987,2.45"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M327.5,817.889c-6.068-2.298-12.104-3.406-18.253,0.247c0.92,0.919,1.586,1.586,2.253,2.253c-0.5,0.5-1,1-1.5,1.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M819.5,136.889c0.672,2.919,2.518,4.107,5.482,4.606c2.896,0.488,6.138,0.917,7.585,4.365c0.325,0.775,1.095,1.763,2.437,1.549
                c1.748-0.278,3.025,0.149,4.082,1.928c1.101,1.853,3.309,1.828,5.396,2.162c4.611,0.735,9.371,1.437,13.017,4.89
                c0.342,0.324,0.766,1.011,0.986,0.948c4.462-1.266,7.697,2.003,11.541,2.944c1.594,0.39,3.239,0.433,4.458,1.624
                c1.441,1.41,3.143,1.581,5.017,1.512c1.226-0.045,2.44-0.292,3.565,0.909c0.942,1.005,2.589,0.617,3.933,0.546
                c4.919-0.26,9.964-1.853,14.501,1.517c5.652-1.882,10.391,3.215,16.018,2.088c1.386-0.278,2.828,1.333,4.48,0.906
                c3.316-0.859,5.193-3.003,5.443-6.998c0.223-3.562,1.177-6.983,1.53-10.499c0.304-3.016,0.048-5.942-1.956-8.509
                c-3.843-4.922-6.858-10.352-9.516-15.988"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1073,316.389c0.064,4.175-2.481,7.394-3.922,11.031c-0.881,2.225-2.793,4.241-2.078,6.969"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M174,455.389c0.938-0.592,1.185-1.479,0.993-2.499c-0.347-1.838-1.068-3.543-0.081-5.545c0.666-1.351-1.247-1.956-1.911-2.957
                c2.717-6.235-0.308-11.816-6.489-11.343c-1.94,0.148-4.104,1.358-5.97,0.721c-2.098-0.715-2.779,0.182-3.542,1.623"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M353,838.889c1.144,1.475,3.362,1.015,4.457,2.443c-2.045,2.332-0.376,2.832,1.517,3.619c1.56,0.648,3.49,0.769,4.634,2.612
                c-3.807,2.106-7.928,2.346-12.108,2.329c-3.051-0.012-3.051,0.003-5.512,1.504c-0.096,1.602,1.111,2.459,2.012,3.492"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M301.5,744.389c6.013,2.047,12.743,0.828,18.466,4.06c0.514,0.29,1.782,0.192,1.534,1.44"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1078.5,41.389c-3.989,3.564-9.021,4.279-14.008,4.938c-1.811,0.239-3.463,0.605-4.992,1.562"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M951,492.389c6.055,3.576,11.469,8.104,17.914,11.18c3.48,1.66,6.086,5.154,9.086,7.82"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1201.5,725.889c2.279,2.033,5.761,0.631,8.041,2.959c1.99,2.032,4.748,2.089,7.459,2.111c0.504,0.005,1,0.93,1.507,1.437
                c-1.349,2.692-4.767,1.536-6.725,3.658c1.811,2.847,5.487,2.386,8.33,4.669c-4.381,1.186-7.902,3.414-12.331,3.676
                c1.764,4.236,7.676,3.242,9.262,7.608c-3.252,2.728,1.482,3.405,1.266,5.573c-0.309,0.309-0.794,1.225-1.311,1.243
                c-4.168,0.145-8.346-0.087-12.506,0.507c-0.607,0.087-1.326-0.607-1.992-0.941"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1274,498.389c-2.166,5.666-4.333,11.334-6.5,17"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M984.5,289.889c-1.384,1.825-3.266,2.743-5.5,2.998c-4.136,0.471-7.969,1.799-11.5,4.002"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1087.5,308.389c-0.834-0.78-1.646-0.757-2.504-0.005c-1.281,1.124-2.748,1.67-4.498,1.521c-2.342-0.199-3.965,1.383-4.898,3.04
                c-1.324,2.35-3.555,2.955-5.612,3.918c-7.319,3.426-11.456,9.217-12.987,17.026"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M798.5,111.889c-1.562,2.288-4.152,2.685-6.504,3.49c-3.695,1.267-7.43,2.433-10.496,5.01"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M74,109.389c1.812,0.62,2.661,2.342,3.982,3.521c2.194,1.957,3.756,4.542,7.534,4.122c2.35-0.262,3.333,2.96,5.484,3.857"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1280.5,493.389c-0.084,5.666-0.166,11.334-0.25,17"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M161.5,633.889c5.514,2.305,11.411,3.428,17.211,4.932c-1.132,2.893-3.974,1.375-5.249,3.12c0.071,1.249,1.698,0.955,2.038,1.948"
                />
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M95.5,62.889c3.644,1.828,3.644,1.828,2.991,6.568c-4.229,1.452-8.769,1.59-13.075,3.208c-3.067,1.152-6.918,0.297-10.416,0.207
                c-5.336-0.138-10.06,1.532-13.959,5.063c-2.967,2.687-6.185,3.089-10.038,3.167c-2.939,0.059-6.288,0.39-9.016,2.271
                c-0.871,0.601-1.754,1.755-2.988,0.517"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M194,542.389c-2.734-4.131-6.477-6.073-11.505-5.545c-1.301,0.136-2.683-0.191-3.991-0.474c-3.559-0.767-7.176-3.271-10.504,0.519
                c-3.512-2.943-6.275,1.211-9.5,1.004c-0.498-0.031-1.27-0.214-1.458,0.028c-2.183,2.804-5.71,2.63-8.542,3.968"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M937,204.389c1.91,5.404,0.911,10.985,1,16.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M794,329.389c-0.803-1.728-2.698-1.825-4.006-1.887c-4.162-0.198-8.215-3.257-12.494-0.613"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M841,378.389c5.206,3.633,8.193,9.167,12.029,13.976c0.492,0.617,0.915,1.542,1.971,1.524"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M322.5,760.889c0.543,1.292,1.167,2.885-1.001,2.946c-5.662,0.159-11.332,0.054-16.999,0.054"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M296.25,506.889c-2.794,1.407-5.805,1.029-8.75,0.972c-2.882-0.056-5.585,0.354-8,2.028"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1263.5,852.389c-4.206,0.172-8.191,2.217-12.499,1.494c-2.45-0.412-1.557,2.45-2.698,2.889
                c-5.617-0.875-10.832-2.205-15.803-4.383"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M144.5,649.889c-2.5,1.166-4.929,2.533-7.518,3.451c-3.489,1.237-5.618,3.506-6.482,7.049c3.63,0.964,7.373,0.807,10.984,0.369
                c2.359-0.285,4.685-0.171,7.016-0.369"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M775,300.889c1.766,4.394,0.717,8.996,1,13.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1075,565.389c-1.282,1.25-1.975,2.732-2.594,4.467c-1.606,4.502-4.873,7.128-9.906,7.033"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M920.5,260.889c0,4.167,0,8.333,0,12.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M138.5,808.889c-3.955,1.994-8.408,2.424-12.568,3.792c-1.507,0.495-2.715,1.858-4.432,1.708"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M925,204.389c-0.5-0.667-1.184-1.265-1.462-2.014c-0.587-1.582-0.865-3.434-3.038-3.457c-2.512-0.026-4.109,1.741-5.447,3.51
                c-1.047,1.384-1.724,3.092-1.553,4.96"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M782,96.389c3.48-1.207,9.619-0.876,13.01-0.644c4.223,0.289,8.241,1.832,12.49,1.644"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M620.5,400.889c3.218,5.481,9.156,8.71,12,14.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M827.5,50.389c-4.581-0.231-9.035,0.681-13.5,1.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M124,240.389c-0.333,0.667-0.955,1.333-0.956,2c-0.007,7.667-0.827,15.334-0.006,22.996c0.039,0.365,0.629,0.67,0.962,1.004"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M227,96.389c1.217-0.14,1.387,1.263,2.482,1.564c5.337,1.468,6.005,2.757,3.912,7.893c-0.34,0.836,0.204,2.016-0.931,2.548
                c0.762,0.416,1.257,1.088,1.99,1.566c4.497,2.935,9.458,3.496,14.537,2.855c3.367-0.425,5.784,1.372,8.509,2.574"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M732.5,348.889c-3.78-0.061-7.092,2.249-8.711,4.691c-1.766,2.664-4.623,3.475-6.289,5.809c-4.385-0.826-6.018,3.534-9.081,4.805
                c-5.931,2.46-11.927,6.273-18.937,3.236c-0.701-0.304-1.648-0.041-2.482-0.041"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1069.5,31.889c-2.457,2.997-6.293,3.083-9.488,4.526c-1.521,0.688-3.987,0.229-4.012,2.974"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M895.5,508.389c4.725,0.303,9.052,2.335,13.416,3.758c3.234,1.055,6.881,3.037,8.584,6.742"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M661,190.889c1.766,4.394,0.717,8.996,1,13.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1256,512.389c0.166,0.5,0.553,1.395,0.468,1.441c-1.836,1.023-1.964,3.32-3.386,5.101c-1.822-3.501-1.924-7.37-5.34-10.157
                c-1.235,5.74-2.229,11.048-5.242,15.615"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M783,120.389c4.047,1.493,7.874,1.94,13,1.495c1.02-0.089,1.752,0.459,2.5,1.005"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M619,526.389c-0.333,5.166-0.667,10.334-1,15.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M252.5,867.389c-6.326-0.451-12.681,0.859-19.013-0.435c-1.703-0.348-3.777-0.588-4.987,1.435"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M151.5,633.889c-0.333,0.166-0.952-0.443-0.96-0.438c-4.376,2.816-10.148,2.602-14.04,6.438"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M139.5,746.389c-3.106,0.623-6.169-0.104-8.963-1.104c-4.018-1.436-6.489-1.963-11.038-0.9c-1.716,0.401-3.497,1.318-4.999-0.496"
                />
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M931,497.889c-1.153-3.172-2.307-6.344-3.452-9.494c-5.307-0.655-8.503,1.264-11.591,6.469c-1.43,2.409-1.674,5.312-3.457,7.525"
                />
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1216,553.389c-2.347,2.74-2.119,6.55-3.975,9.516c-1.153,1.844-1.743,4.043-3.525,5.484"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M275.5,827.889c-4.829,1.899-10.001,2.326-15,3.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M164.5,75.889c0.825,1.027-0.039,2.778,1.5,3.5c-1.128,4.205-6.142,4.417-8.103,7.943c-0.986,1.772-4.015-0.599-5.397,1.557"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M856.5,487.389c-1.898,0.19-2.278-1.744-3.521-2.502c-2.938-0.213-2.622,3.947-5.478,4.046c-2.226,0.077-3.479,2.304-5.502,2.956
                c-1.916,0.266-1.281-2.529-2.998-2.502c-4.723,5.19-4.723,5.19-8.502,2.502c-1.888-0.236-2.946,2.291-5,1.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M382,376.389c-0.462,2.963,1.763,6.407-1.864,8.945c-0.473,0.331,0.759,3.305-0.136,5.055"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M462.5,457.389c5.489,0.704,10.583,2.339,14.479,6.52c1.443,1.548,3.146,2.599,5.017,3.488c3.633,1.729,6.204,4.753,9.004,7.492"
                />
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M126.5,816.389c-2.722-0.607-4.704,2.064-7.513,1.572c-2.103-0.367-4.409-0.452-6.465,0.025c-2.516,0.584-5.56,0.119-7.202,3.311
                c-0.406,0.789-2.6,1.699-4.321,1.604c-1.812-0.101-3.485,0.228-4.499,1.987"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1082.5,444.889c-1.666-1.119-3.018-1.057-5.074-0.174c-3.025,1.299-5.501,3.796-8.926,4.174"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1125.5,907.389c-2.631,3.072-6.56,3.547-10.192,4.715c1.743,2.582,4.131,1.517,6.19,1.795c1.686,0.229,3.521-0.727,5.069,0.928
                c0.47,0.502,1.588,0.717,1.39,1.553c-0.92,3.875,1.699,3.484,4.043,3.515c1.725,0.022,3.542-0.38,5.015,1.071
                c0.353,1.827-2.615,2.16-2.015,4.174"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M207.5,795.389c-4.072-0.527-7.98,0.902-12.042,1.002c0.481,1.565,1.497,3.197-0.458,4.498"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M310.5,823.889c1.021,1.581,2.62,0.92,4.001,0.983c3.354,0.154,6.775-0.415,9.999,1.017"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1152.5,813.889c-4.402,2.861-9.307,4.721-14,7"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M575.5,514.889c-8.167-3.662-16.137-2.615-24,1"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1063,22.889c-0.339,3.327-3.266,3.471-5.494,4.513c-2.08,0.973-4.959,0.885-5.506,3.987"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1265.5,508.389c-0.803,4.886-4.492,8.439-5.28,13.244c-3.655-1.369-0.967-4.836-3.22-6.244"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M194.5,736.889c0.167,0.166,0.326,0.471,0.501,0.48c4.673,0.254,9.189,1.977,14.011,1.083c3.799-0.704,7.688-1.448,11.533,0.341
                c0.652,0.304,1.897-0.589,2.956-0.404"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M318.5,751.889c1.09,3.084,4.067,3.174,6.515,3.954c2.158,0.688,4.536,0.673,6.485,2.046"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M550.5,634.389c-0.447,0.553-1.591,0.6-1.472,1.496c0.589,4.431-0.345,8.139-4.486,10.576c-0.167,0.099,0.291,1.262,0.458,1.928"
                />
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M289.5,770.889c-1.5,1.137-3.002,0.054-4.5,0.063c-2.846,0.019-5.831-0.785-8.5,0.937"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1085.5,54.889c-5.532,1.763-11.477,1.188-17,3"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M156,516.389c0.667,0.666,1.866,1.299,1.911,2.006c0.236,3.697,0.789,7.156,4.089,9.494c-0.499,2.499,1.398,3.376,2.988,4.518
                c1.072,0.77,1.641,1.767,0.512,2.982"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M228,343.889c-0.333,0.333-0.977,0.69-0.954,0.997c0.353,4.589-1.526,8.399-4.046,12.003"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M159,518.889c2.622,1.423,5.277,2.771,7.96,4.08c5.455,2.662,9.527,6.764,11.04,12.92"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1193,669.389c-0.559,2.693-3.733,4.68-2.167,8.077c0.104,0.228-1.06,2.958-3.347,2.493c-0.9-0.184-2.115-0.6-2.993,0.424
                c-0.46,0.535-0.993,1.006-1.493,1.506c0.223,3.772,3.952,3.719,6.09,5.331c-0.528,2.362-3.432,1.269-4.195,3.499
                c1.115,1.816,3.547,1.12,5.105,2.184c0,1.32,0,2.652-0.001,3.984c-1.176,1.486-3.158,1.764-4.499,3.002"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1019,161.389c-0.131,0.81,0.557,1.23,1.02,1.46c2.128,1.054,3.512,3.059,2.824,4.984c-0.91,2.551-3.047,4.487-6.344,3.556"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M152.5,611.889c0.629,0.863,2.151,1.177,1.951,2.492c-0.194,1.281-1.214,2.062-2.449,2.514c-2.133,0.779-4.598,0.474-6.502,1.994"
                />
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M886,588.389c-2.217,5.805-2.217,5.805-8.006,10.506c-1.387-0.521-2.127-1.394-2.029-3.008c0.072-1.221,0.5-2.654-0.965-3.498"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M78,277.389c-1.486,0.093-2.52,1.221-3.962,1.633c-4.511,1.286-7.855,4.535-11.038,7.867"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M566.5,422.389c-6.061-1.086-11.663-0.75-16.105,4.409c-0.511,0.593-1.382,2.116-2.895,1.591"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M966.5,540.889h6c1.963,0.604,3.77,1.341,6.035,0.609c1.971-0.637,3.648,0.856,4.965,2.391"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M256.5,905.889c3.905,1.289,7.923,2.037,12,2.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M208,92.389c2.651,2.848,5.843,5.188,7.997,8.502c0.525,0.808,1.32,0.397,2.003,0.498"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M286,729.389c-4.833-1.271-9.667-2.193-14.5,0"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M791.5,102.889c-1.648,1.18-3.482,1.613-5.5,1.514c-2.291-0.112-4.516,0.195-6.5,1.486"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1080.5,63.889c-0.82,0.858-1.561,1.535-2.998,1.577c-2.917,0.085-5.863,0.427-8.502,1.923"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M125.5,736.889c-1.451,1.902-3.702,0.771-5.54,1.379c-2.256,0.746-3.973,1.911-5.46,3.621"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M166.5,616.889c2.421,1.132,5.369,1.504,6.525,4.565c-0.156,0.804-0.602,1.407-1.525,1.425c-3.077,0.059-6.251-0.498-9,1.51
                c-0.333-0.5-0.667-0.666-1-0.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M179.5,655.889c-1.654,1.156-3.438,1.649-5.502,1.538c-2.825-0.152-5.665-0.038-8.498-0.038"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M775.5,94.389c2.433,1.16,5.651,0.806,7.156,3.924c0.162,0.335,2.027,1.007,3.319,1.221c1.905,0.316,4.21,0.38,5.524,2.354"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M453,524.889c4.954-1.554,10.002-2.193,14.975-0.429c2.413,0.856,4.125-0.143,6.025-1.071"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M350,152.389c2.824,4.361,4.724,9.152,6.5,14"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M827,286.389c0.604,3.642,1.329,7.273,1,11"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M842,283.389c0.604,3.642,1.329,7.273,1,11"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M859.5,50.889c3.529,1.85,7.332,0.679,11,1"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M146.5,729.389c-0.884-1.16-1.956-1.642-3.503-1.547c-2.531,0.154-5.113,0.252-7.497-0.953"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M314.5,262.389c-0.188,1.164,0.442,2.442-0.5,3.5c-1.735,1.95-1.833,4.153-1,6.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1058,581.889c-2.065,2.042-5.164,2.592-7.033,4.975c-0.861,1.098-2.303,0.338-3.467,0.525"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M280.5,764.889c1.487,1.71,3.078,3.24,5.499,3.508c1.701,0.188,3.408,0.41,4.501,1.992"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M143.5,788.389c-3.635,0.007-6.396-2.904-10-3"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M259.5,814.889c-1.833,0.053-3.592,1.809-5.555,0.062c-0.465-0.414-1.682-0.272-2.433-0.024c-3.311,1.09-6.677,0.342-10.012,0.463
                "/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M138.5,639.889c1.667,0.793,3.292,0.686,5.013,0.035c1.354-0.512,2.224,0.49,2.882,1.465c-2.666,2.857-7.262,2.394-9.896,5.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M827.5,69.889c-3.562,0.473-6.662-1.355-10-2"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M238,127.889c-2.117,2.837-2.063,6.587-4,9.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M502.5,482.889c1.751,3.582,5.836,4.112,8.661,6.465c-1.137,3.965-4.772,3.085-6.551,2.289c-2.24-1.002-2.238,0.529-3.11,1.246"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1216.5,552.389c1.292,0.072,1.543,1.318,1.428,1.987c-0.534,3.09,1.258,5.028,3.072,7.013"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M486.5,481.889c3.776-3.024,7.885-2.646,11.988-0.971c3.903,1.594,9.302,1.483,12.512-0.529"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M996,97.889c-0.834,0.833-1.553,1.843-2.521,2.468c-3.319,2.143-4.985,5.148-5.563,9.02c-0.182,1.216-0.806,2.608-1.918,3.51
                c-3.682,2.983-7.846,5.372-11,9.002c-2.748,3.164-6.562,4.814-9.998,7.001"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M112,153.389c-2.999-1.169-5.561-3.463-9-3.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M168.5,647.889c0.667-0.166,1.666-0.694,1.944-0.439c1.363,1.258,2.632,2.564,4.556,2.943c2.83,0.557,1.206,3.807,3,4.996"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M946,491.889c0.263,1.516-0.439,3.068,0.869,4.611c0.566,0.669,1.66,2.555,0.131,3.889"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1274.5,495.389c0.041,3.271,2.58,4.937,4.5,7"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M153.5,626.389c-4.658-0.514-7.659,2.612-11.015,4.979c-0.223,0.157-0.652,0.021-0.985,0.021"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M351.5,915.389c1.385,1.818,3.167,2.779,5.5,2.496c1.031-0.126,1.909,0.066,2.5,1.004"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M140.5,619.889c1.083,0.823,2.163,0.068,3.251,0.041c1.424-0.033,2,0.92,2.234,3.461c0.147,1.59,1.97,1.376,2.515,2.498"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M129.5,916.889c-5.752-2.469-5.752-2.469-8-2"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1204,509.889c2.764,2.419,5.957,4.436,7.5,8"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M184.5,785.889c-2.797,0.787-5.739-0.904-8.5,0.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M133.5,647.389c3.866-0.041,7.73,0.221,11.479,1.094c1.312,0.305,2.282,0.055,2.971-0.644c1.028-1.043,1.718,0.035,2.551,0.05"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M172.5,628.389c-2.378-1.826-5.237-2.554-8-3.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1044,19.889c0.086,2.148-1.5,3.1-3.018,3.97c-1.336,0.766-2.146,1.686-1.982,3.28"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M940,349.389c-0.997,0.963-2.452,1.396-3.397,2.131c-3.759,2.926-6.681,6.58-6.103,11.869"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M179.5,158.389c7.229,0.888,13.429,4.287,19.5,8"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M934.5,386.889c-3.049,0.791-5.207,2.634-6.5,5.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M270.5,749.889c-0.333,0.334-0.731,1-0.99,0.95c-3.98-0.771-6.41,1.815-9.01,4.05"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M307.5,788.389c0.516,1.697,1.787,2.826,3.117,4.107c-2.495,1.65-5.165,2.474-8.117,2.393"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M893.5,469.389c2.442,1.033,5.005,0.262,7.5,0.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M279.5,774.889c-1.546,1.305-2.173-0.147-3-1s-1.454-2.305-3-1"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M858,336.389c2.057,1.88,0.943,5.12,3,7"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M308.5,764.889c0.933,1.422,0.889,2.761-1.006,2.929c-1.837,0.163-3.627,0.069-5.059,1.643c0.691,3.921,5.222,3.356,7.066,5.928
                c-0.501,0.501-1.001,1.001-1.501,1.501"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M859.5,74.389c-2.344-0.015-4.701,0.144-7-0.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M869,283.889c0.432,2.151,1.385,4.223,1,6.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M64.5,323.389c-0.878,2.975-3.743,4.269-5.5,6.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M94.5,824.889c1.667,0.334,3.32,0.799,5.003,0.967c2.056,0.205,2.146,1.489,1.997,3.033"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1276.5,79.889c-2.277,0.384-4.349-0.568-6.5-1"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M130.5,917.389c0.721,3.252,3.076,4.053,6,4"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M846,63.889c-2.125-0.713-4.323-0.463-6.5-0.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M524,4.889c-2.077,0.959-3.783,3.123-6.5,2"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M700.5,872.889c-1.84,1.442-3.855,2.582-6.158,3.479c1.62,1.398,3.139,2.71,4.658,4.021"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M781,346.389c-2.021,1.548,0.054,4.462-2,6"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M368,228.389c1.472,1.734,1.013,4.272,2.5,6"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M898.5,116.389c1.242,0.838,3.482,0.776,2.119,3.559c-0.779,1.592,0.433,3.629,0.881,5.441"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1180.5,763.889c-1.656,1.309-3.614,0.956-5.5,1"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M276,801.389c1.833-0.505,3.667,1.348,5.5,0"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M832,95.389c-0.465,2.097,0.653,3.761,1.5,5.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M256.5,757.889c-1.877,1.231-3.298,2.828-4,5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M179.5,773.389c0.216,1.039,3.069,1.123,1.051,3.054c-0.36,0.345-0.1,1.165,0.92,1.527c1.447,0.513,2.697,1.583,4.053,2.43
                c-0.023,1.032-1.088,1.274-1.623,2.2c0.576,1.21,0.831,2.79,2.599,3.289"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M453,479.889c1.392,1.881,4.067,0.232,5.5,2"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M278.5,723.889c-1.31,1.547-4.342,1.156-4.5,4"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M442.5,481.889c1.145,1.986,3.714,2.691,4.5,5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M823.5,39.889c-1.886,0.044-3.844-0.309-5.5,1"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M106,246.889c-1.736-0.493-3.006-2.152-5-2"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M148.5,632.889c-1.467-1.33-3.533,0.33-5-1"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M312.5,740.889c-1-0.334-2-0.668-3-1.001c-1.994,1.138-3.351,2.981-5,4.501"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M335,229.389c0.574,0.611,0.032,1.672,1.45,2.148c1.368,0.459,2.921,1.766,2.55,3.852"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M830.5,88.389c1.918-0.103,3.064,0.35,4.182,2.4c1.109,2.033,2.662,4.641,5.818,4.6"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M219.5,739.889c-0.638,2.057-3.362-0.057-4,2"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M317.5,751.389c2.668-0.144,5.225-2.062,8-0.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M92.5,756.889c1.111,0.943,2.57,0.147,3.73,0.74c-0.51,0.473,0.089,1.42-0.73,1.76"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M188.5,665.889c-2.682-0.079-5.281,1.172-8,0.5"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M322.5,829.389c1.414-2.426,3.664-2.342,6-2"/>
            
            <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M109.5,716.889c-0.701,1.316-4.438,0.891-2.186,4.129c0.371,0.533-2.223,1.951-3.814,2.371"/>
        
            </g>
            </svg>
        </div>
    )
}