import React from 'react';
import './Yosemite.css';

export default function Yosemite(props) {
    
    return (
		<div className={`yosemite-container ${props.size}`}>
			<svg id={props.id} xmlns="http://www.w3.org/2000/svg" >
			<g className="yosemite-image">

			<path onClick={() => props.onFill(0)} fill={props.fillColors[0]} d="M15.48,179.08c0-54.547,0.008-109.094-0.04-163.64c-0.001-1.557,0.194-2.01,1.919-2.01
				c192.833,0.053,385.667,0.053,578.5-0.001c1.763-0.001,1.897,0.513,1.897,2.029c-0.042,79.981-0.037,159.961-0.037,239.942
				c-0.592,0.708-1.323,1.085-2.271,1.157c-3.353,0.251-6.671,0.77-9.931,1.588c-1.682,0.422-2.962,0.007-4.024-1.33
				c-5.393-6.778-11.641-12.763-17.897-18.695c-10.331-9.794-20.938-19.304-33.998-25.49c-13.469-6.38-27.462-11.385-41.68-15.775
				c-11.005-3.397-21.836-7.279-32.624-11.298c-8.937-3.329-18.078-5.991-27.582-7.034c-6.911-0.759-13.707-0.09-20.225,2.679
				c-5.671,2.409-11.477,4.5-17.63,5.297c-7.64,0.989-15.32,0.462-22.984,0.338c-3.486-0.056-6.857,0.225-9.929,2.095
				c-3.103,1.89-5.097,4.738-5.081,8.231c0.036,8.307-3.117,15.635-6.503,22.862c-3.596,7.674-7.976,14.939-12.87,21.875
				c-2.319,3.288-5.037,5.079-9.517,4.304c-6.587-1.139-11.533,2.179-15.435,7.257c-4.518,5.88-7.524,12.609-10.806,19.187
				c-2.141,4.29-4.298,8.574-6.518,12.824c-0.677,1.297-1.506,2.433-3.27,2.385c-1.003-0.027-1.482,0.841-1.884,1.64
				c-0.54,1.07-1.116,2.124-1.549,3.245c-0.276,0.714-0.685,1.286-1.508,1.36c-0.91,0.082-1.169-0.589-1.495-1.271
				c-0.484-1.016-0.779-1.914-0.522-3.154c0.304-1.467-0.432-2.941-1.064-4.318c-0.061-0.225-0.106-0.456-0.136-0.688
				c-0.061-0.907,0.129-1.871-0.181-2.714c-0.308-0.837-0.258-2.268-1.271-2.301c-0.902-0.029-1.964,1.015-2.203,1.881
				c-0.739,2.677-1.801,5.237-1.894,8.163c-0.052,1.628-0.472,3.446-0.761,5.173c-0.12,0.712-0.326,1.4-0.148,2.125
				c0.268,1.097-0.24,1.66-1.272,1.885c-1.073,0.233-1.65-0.272-1.971-1.244c-0.806-2.446-1.202-5.087-1.009-7.517
				c0.222-2.79-1.891-4.63-1.911-7.275c-1.171,2.242-2.752,4.07-2.944,6.751c-0.167,2.334-0.643,4.71-1.271,7.006
				c-0.562,2.052-1.949,1.506-3.215,0.836c-4.906-2.596-10.189-4.205-15.402-6.013c-6.803-2.36-13.816-3.063-20.979-3.317
				c-7.771-0.276-15.456,0.264-23.16,1.057c-5.495,0.565-10.354-1.696-15.143-3.919c-10.657-4.946-21.162-10.226-32.038-14.692
				c-2.66-1.092-5.332-2.175-8.087-3.02c-2.292-0.703-4.373-1.605-4.832-4.36c-0.089-0.537-0.374-1.117-1.074-1.107
				c-0.566,0.009-0.804,0.481-0.959,0.939c-0.438,1.29-1.386,1.7-2.635,1.52c-1.242-0.178-2.049-1.013-2.328-2.144
				c-0.364-1.477-0.995-2.87-1.241-4.38c-0.155-0.946-0.447-1.87-0.92-2.716c-0.281-0.504-0.645-0.958-1.314-0.833
				c-0.528,0.099-0.828,0.517-0.922,0.998c-0.26,1.331-0.783,2.587-0.941,3.958c-0.21,1.822-1.413,2.259-2.821,1.098
				c-1.927-1.589-2.188-4.007-2.802-6.185c-0.388-1.376-0.618-2.804-1.221-4.112c-0.296-0.643-0.684-1.363-1.536-1.334
				c-0.737,0.024-1.066,0.674-1.294,1.269c-0.407,1.063-0.704,2.201-2.187,2.124c-1.465-0.075-1.558-1.401-2.106-2.324
				c-0.391-0.656-0.347-1.383-0.445-2.089c-0.36-2.594-0.73-5.073-2.188-7.474c-0.979-1.614-1.143-3.939-1.242-5.977
				c-0.132-2.726-1.869-4.833-2.474-7.367c-0.222-0.93-1.205-0.961-1.898-0.16c-0.834,0.964-1.567,2.012-2.387,2.988
				c-0.819,0.975-1.828,1.704-3.129,1.02c-1.231-0.648-2.327-1.57-2.091-3.173c0.276-1.878,0.168-3.652-0.831-5.311
				c-0.083-0.137-0.187-0.319-0.142-0.45c1.213-3.516-2.087-6.415-1.347-9.881c0.129-0.607-0.245-1.25-0.569-1.805
				c-0.778-1.331-1.322-1.256-2.172,0.032c-1.464,2.216-1.863,4.89-3.206,7.154c-0.314,0.531-0.5,1.213-0.303,1.858
				c0.391,1.284,0.408,2.427-1.109,2.932c-1.711,0.57-3.504-0.223-4.208-1.794c-0.229-0.51-0.408-1.041-0.568-1.579
				c-0.185-0.621-0.386-1.297-1.167-1.343c-0.738-0.043-1.106,0.555-1.458,1.095c-0.707,1.084-1.35,2.227-1.228,3.563
				c0.152,1.655-1.063,2.262-2.157,2.835c-1.162,0.608-2.486-0.265-2.752-1.807c-0.178-1.033-0.773-2.056-0.765-2.962
				c0.022-2.414-1.31-4.346-1.823-6.542c-0.169-0.725-0.313-1.459-1.156-1.649c-0.741-0.167-1.26,0.345-1.567,0.889
				c-1.185,2.096-3.768,2.138-5.358,3.773c-1.097,1.127-3.25-0.309-3.325-2.105c-0.182-4.348-1.53-8.523-1.831-12.852
				c-0.043-0.623-0.123-1.4-0.866-1.562c-0.768-0.167-1.056,0.624-1.42,1.101c-1.094,1.432-1.735,3.257-1.85,4.868
				c-0.269,3.764-2.071,7.262-1.708,11.094c0.22,2.323-1.553,2.265-3.042,2.406c-1.494,0.142-2.189-0.716-2.272-2.146
				c-0.131-2.243-0.874-4.306-1.501-6.444c-0.618-2.107-1.126-4.52-1.051-6.715c0.103-2.977-1.327-5.589-1.29-8.478
				c0.007-0.53-0.278-1.138-0.94-1.243c-0.586-0.093-0.787,0.423-1.042,0.826c-0.631,0.997-1.579,1.837-1.478,3.184
				c0.066,0.878-0.603,1.452-0.982,2.14c-0.339,0.613-0.893,0.923-1.603,0.803c-0.835-0.142-1.16-0.743-1.294-1.518
				c-0.437-2.52-0.67-5.067-0.959-7.604c-0.165-1.442-0.458-2.836-0.979-4.488c-0.388,4.203-1.043,8.105-1.575,12.025
				c-0.141,1.039-0.449,2.027-0.842,2.994c-0.42,1.032-0.993,1.891-2.295,1.624c-1.198-0.246-1.713-1.021-1.642-2.263
				c0.096-1.643-0.673-3.114-1.088-4.647c-0.341-1.261-1.112-1.296-1.802-0.202c-0.423,0.673-0.803,1.381-1.115,2.122
				c-0.502,1.189-0.983,2.642-2.658,2.097c-1.542-0.501-2.547-1.859-2.542-3.452c0.012-3.807-0.551-7.509-1.339-11.215
				C16.162,184.438,16.646,181.63,15.48,179.08z"/>
			<path fill-rule="evenodd" d="M279.698,284.916c0.993,1.865,2.117,3.618,0.869,5.932c-0.416,0.772,1.249,1.468,1.292,2.699
				c0.84-2.229,1.675-4.356,2.69-6.417c1.214-2.464,1.531-2.586,3.347-0.276c1.785-2.305,2.791-4.981,4.083-7.483
				c3.337-6.459,6.596-12.959,9.899-19.436c1.386-2.718,3.241-5.124,5.029-7.583c4.321-5.944,10.107-8.521,17.31-7.217
				c2.988,0.541,4.911-0.7,6.362-2.729c8.216-11.49,15.225-23.631,19.213-37.305c0.558-1.912,0.705-3.912,0.714-5.919
				c0.033-7.766,4.675-12.685,12.386-13.441c4.797-0.47,9.57-0.013,14.332,0.19c10.025,0.43,19.6-1.325,28.697-5.39
				c9.926-4.435,20.054-4.029,30.316-1.882c11.694,2.446,22.659,7.146,33.882,11.058c14.399,5.019,29.021,9.366,43.342,14.647
				c17.255,6.363,32.635,15.519,45.976,28.243c7.98,7.612,16.033,15.124,23.074,23.637c0.706,0.854,1.287,1.097,2.414,0.797
				c4.183-1.113,8.452-1.702,12.795-1.64c0,0.96,0,1.92,0,2.88c-0.636,0.812-1.471,1.183-2.501,1.17
				c-0.479-0.006-0.961,0.019-1.434,0.097c-6.297,1.049-6.357,0.234-8.064,7.209c-3.322,13.574-6.813,27.14-13.363,39.646
				c-1.408,2.689-2.85,5.362-4.455,7.939c-0.738,1.185-1.279,2.379-0.575,4.142c2.354-5.159,4.814-9.935,7.571-14.564
				c3.459-5.807,7.798-10.915,12.335-15.853c2.539-2.764,5.082-5.522,7.574-8.33c0.738-0.832,1.37-2.161,2.912-1.295
				c0,0.8,0,1.6,0,2.4c-0.614,1.859-1.633,3.474-2.762,5.073c-3.317,4.698-4.615,10.088-5.186,15.725
				c-0.801,7.925-1.351,15.899-3.81,23.556c-1.013,3.155-1.821,6.409-3.769,8.927c1.591-2.733,4.162-5.031,5.985-7.829
				c1.712-2.625,3.826-5.032,4.987-8.016c0.648-1.667,1.128-3.325,0.643-5.124c-0.062-0.229-0.045-0.476-0.05-0.715
				c-0.024-1.201,0.291-2.243,1.507-2.73c1.343-0.539,1.793,0.631,2.453,1.374c0,5.44,0,10.88,0,16.32
				c-0.494,0.521-0.997,1.153-1.785,1.018c-2.197-0.374-2.934,1.025-3.487,2.749c0.473,0.608,1.157,0.283,1.74,0.34
				c1.199,0.117,2.685,0.079,2.854,1.551c0.181,1.57,0.076,3.239-1.72,4.224c-1.234,0.676-2.927,0.667-3.677,2.315
				c1.668-2.172,4.424-2.884,6.075-1.637c0,2.24,0,4.48,0,6.72c-0.282,0.19-0.769,0.319-0.878,0.104
				c-1.189-2.345-2.936-1.088-4.524-0.731c-1.032,0.232-2.058,0.402-2.779,0.23c1.807,0.175,3.841,0.85,5.914,1.341
				c1.105,0.262,1.805,0.948,2.268,1.936c0,0.64,0,1.28,0,1.92c-0.704,0.945-1.597,1.081-2.715,0.816
				c-1.032-0.244-2.075-0.734-3.621-0.346c2.055,2.18,5.458,2.316,6.336,5.29c0,2.56,0,5.12,0,7.68
				c-0.692,0.528-1.344,0.402-1.942-0.151c-2.936-2.716-6.944-4.264-8.813-8.147c-0.487-1.012-1.86-0.897-2.306-1.552
				c3.256,1.175,3.794,4.044,3.666,7.289c-0.067,1.705-0.589,2.966-2.498,3.21c-1.393,0.178-1.537,0.645-0.674,1.847
				c2.008,2.793,3.531,5.882,4.985,8.997c0.169,0.362,0.323,0.729,0.417,1.12c0.502,2.087-0.093,4.374-1.31,5.024
				c-1.437,0.769-4.479,0.273-5.802-0.979c-0.293-0.278-0.582-0.54-0.939-0.724c0.615,0.274,1.22,0.727,1.222,1.368
				c0.012,3.234,1.588,6.343,0.672,9.658c-0.614,2.227-1.567,2.877-3.786,2.299c-0.526-0.137-1.04-0.446-1.696-0.096
				c0.483,2.33,1.812,3.717,4.233,4.116c1.573,0.259,3.107,0.74,4.657,1.127c2.569,0.642,5.06,0.553,7.485-0.591
				c0.771-0.364,1.548-0.964,2.429-0.216c0,1.12,0,2.24,0,3.36c-3.811,2.746-8.135,2.69-12.124,1.178
				c-5.018-1.902-9.21-0.142-13.518,1.737c-1.907,0.832-2.317,2.994-1.301,5.381c1.22,2.863,2.657,5.628,3.819,8.517
				c0.298,0.74,0.659,1.45,0.626,2.285c-0.069,1.766-1.314,2.613-2.92,1.891c-1.156-0.521-2.213-1.231-3.121-2.137
				c-0.004,0.029,0.135,0.148,0.112,0.318c-0.436,3.31,1.703,4.831,4.175,6.213c2.271,1.269,4.734,1.913,7.232,2.433
				c4.247,0.882,8.05,2.5,10.927,5.913c1.134,1.345,2.521,2.232,4.418,1.634c0.719-0.227,1.228,0.134,1.674,0.638
				c0,0.96,0,1.92,0,2.88c-1.904,1.565-4.991,1.798-6.685-0.201c-3.936-4.647-9.092-6.523-14.784-7.535
				c-0.624-0.111-1.24-0.332-1.818-0.597c-1.896-0.866-3.365-0.453-4.993,0.922c-1.533,1.295-2.017,1.992-1.115,4.176
				c1.034,2.507,3.177,3.476,4.803,5.106c0.985,0.988,2.258,1.736,3.2,2.834c0.646,0.754,1.502,1.14,2.462,1.379
				c2.635,0.654,3.211,1.805,2.218,4.31c-1.323,3.335-1.298,3.265,2.136,4.517c3.225,1.175,6.639,1.689,9.801,3.116
				c2.194,0.989,3.941,2.221,4.776,4.534c0,0.8,0,1.6,0,2.399c-0.586,0.438-1.219,0.506-1.748-0.011
				c-3.382-3.301-7.889-4.117-12.145-5.391c-3.864-1.156-7.596-2.48-10.816-4.979c-1.608-1.248-3.678-1.548-5.354-2.604
				c0.653,1.425,0.805,2.968,0.028,4.299c-0.94,1.612-0.176,2.531,0.901,3.495c3.007,2.688,2.704,4.947-0.89,6.664
				c-1.961,0.937-2.349,1.98-1.602,4.067c0.703,1.967,2.42,2.897,3.957,4.031c2.815,2.075,3.267,3.286,2.307,6.043
				c-0.936,2.686-2.67,3.887-5.701,3.694c-1.265-0.08-2.107,0.148-2.762,1.321c-0.674,1.209-0.236,1.945,0.693,2.648
				c0.189,0.144,0.321,0.353,0.439,0.564c0.542,0.976,0.837,2.263,1.76,2.809c1.266,0.749,1.729-1.015,2.754-1.361
				c1.653-0.562,3.015-0.077,3.263,1.621c0.404,2.773,0.017,5.404-2.187,7.769c7.396-0.129,14.646,0.138,20.766-5.084
				c2.005-1.711,2.774-3.803,3.569-6.053c0.465-1.315,1.065-2.451,2.767-1.463c0,1.28,0,2.56,0,3.84
				c-0.118,0.11-0.29,0.235-0.286,0.371c0.116,3.91-2.486,5.901-5.371,7.747c-1.796,1.148-3.536,2.277-4.73,4.254
				c-0.798,1.319-2.307,2.332-4.004,2.714c-0.672,0.151-1.358,0.31-1.73,0.87c0.98-1.021,2.088-1.214,3.121,0.001
				c1.429,1.682,3.744,2.298,4.852,4.474c0.608,1.193,2.361,0.684,3.577,0.63c1.021-0.046,1.171-1.16,1.381-2.009
				c0.115-0.464,0.09-0.955,0.198-1.42c0.446-1.908,1.258-2.13,2.993-0.831c0,2.88,0,5.76,0,8.64c-1.133,1.211-2.821,1.315-4.18,2.081
				c-0.264,0.148-0.759,0.154-0.925-0.039c-1.52-1.769-3.649-1.729-5.682-2.178c-1.229-0.272-2.58-0.932-3.185-2.325
				c-0.351-0.807-1.04-0.827-1.792-0.89c-1.831-0.149-3.625-0.577-5.441-0.854c-2.27-0.346-3.062-2.664-1.644-4.516
				c1.337-1.745,3.448-2.509,5.049-3.941c-2.616-0.418-5.128,0.337-7.688,0.303c-2.006-0.026-2.593,1.739-2.977,3.271
				c-0.578,2.308-1.752,4.397-2.528,6.613c-0.639,1.821-2.681,3.38-3.305,4.691c1.319-1.373,3.222-3.277,5.128-5.178
				c0.982-0.979,2.082-1.774,3.397-2.179c1.677-0.516,3.12,0.529,2.712,2.206c-0.838,3.441-0.486,7.218-2.791,10.27
				c-1.545,2.045-2.687,4.428-4.875,5.735c1.729-0.835,2.658-2.882,4.583-3.723c1.261-0.551,2.55-1.325,3.79-0.222
				c1.247,1.11,1.331,2.535,0.869,4.165c-1,3.522-1.665,7.158-4.312,10.281c0.905-0.86,1.32-1.725,1.653-2.587
				c0.728-1.877,2.104-3.112,3.84-3.916c2.167-1.004,4.166,0.176,3.525,2.346c-1.072,3.628-0.042,7.023-0.013,10.521
				c0.039,4.785,0.143,9.789-2.046,14.344c-0.971,2.02-0.479,4.26-1.092,6.905c0.384-2.025,1.311-2.736,2.749-2.456
				c1.413,0.274,1.556,1.5,1.456,2.653c-0.24,2.762-1.388,5.228-2.661,7.642c-0.36,0.683-1.021,1.253-0.813,2.364
				c1.492-0.634,2.892-0.461,4.228,0.432c0.829,0.555,1.756,0.456,2.387-0.318c0.862-1.061,1.747-1.945,3.228-1.345
				c1.567,0.636,1.207,2.161,1.51,3.517c-0.071-0.158-0.113-0.204-0.104-0.234c1.729-5.342,1.291-10.661-0.126-15.98
				c-0.289-1.085-0.093-2.165,0.888-2.772c0.929-0.574,2.002-0.394,2.979,0.093c2.172,1.081,3.688,2.703,4.208,5.139
				c0,1.12,0,2.24,0,3.36c-1.272,0.692-1.627-0.492-2.218-1.04c1.225,2.559,0.963,5.438,1.234,8.214
				c0.098,0.996,0.22,1.788,0.983,2.426c0,2.4,0,4.8,0,7.2c-2.847,0.942-3.312-1.718-4.776-2.969c1.602,2.412,3.889,4.411,4.776,7.289
				c0,0.319,0,0.64,0,0.96c-1.358,0.922-2.02-0.273-2.817-0.958c-1.905-1.633-3.363-3.705-6.038-4.511
				c-1.603-0.483-2.036-0.105-2.435,1.164c-0.396,0.295-0.878,0.544-1.328,0.343c-3.863-1.728-7.828-0.589-11.752-0.436
				c-0.913,0.036-1.814,0.183-2.22-0.852c-0.363-0.929,0.259-1.5,0.96-1.943c1.474-0.932,0.612-2.041,0.307-3.146
				c-0.364-1.313-1.295-0.873-2.12-0.762c-0.872,0.118-1.736,0.266-2.54-0.267c-1.848-2.2-3.606-4.528-7.426-4.717
				c1.342,0.737,2.342,0.834,3.253,1.165c0.967,0.351,2.062,0.628,1.91,2.047c-0.413,0.963-1.131,1.275-2.13,0.996
				c-2.455-0.686-4.903-1.213-7.506-0.838c-1.235,0.178-2.348-1.018-3.484-0.732c1.552-0.196,3.062,1.116,4.867,0.824
				c0.581-0.095,1.004,0.516,0.867,1.169c-0.17,0.813-0.755,1.211-1.582,1.141c-1.588-0.135-3.121-0.636-4.479-0.647
				c0.267,0.078,0.796,0.118,1.313,0.215c0.553,0.104,0.856,0.438,0.873,0.962c0.017,0.528-0.319,0.901-0.795,1.088
				c-0.602,0.235-1.249,0.238-1.89,0.263c-0.556,0.021-1.371-0.218-1.621,0.169c-1.653,2.565-5.133,1.959-6.982,4.125
				c-0.521,0.61-1.098,0.491-1.659,0.026c-0.625-1.253-1.97-2.371-0.745-3.971c0.513-0.787,1.666-0.582,2.145-1.409
				c1.431-1.176-0.022-2.109-0.135-2.867c0.255,0.442,0.456,1.165,0.007,1.931c-0.896,0.593-1.285,1.63-2.03,2.36
				c-0.992,0.754-1.22,2.035-2.043,2.918c-1.107,1.188-2.192,1.437-3.512,0.312c-1.967-1.676-3.61-3.572-4.621-5.989
				c-0.773-1.851-2.153-2.036-3.46-0.516c-0.417,0.484-0.69,1.069-1.05,1.596c-2.052,3.011-4.382,3.54-7.186,1.258
				c-1.004-0.816-2.108-1.35-3.125-2.023c-0.103-0.112-0.19-0.173-0.246-0.158c0.049,0.135,0.092,0.271,0.136,0.426
				c0.339,1.707-1.244,2.259-1.964,3.321c-0.307,0.454-0.911,0.88-0.638,1.445c0.382,0.79,1.119,0.33,1.688,0.18
				c3.062-0.812,6.267-0.89,9.286-1.996c2.376-0.87,4.867-0.471,7.306,0.025c1.256,0.255,2.091,0.791,1.729,2.266
				c-0.791,0.943-1.809,0.836-2.829,0.554c-1.352-0.373-2.752-0.332-4.114-0.595c-0.326,0.032,0.097,0.363,0.001,0.059
				c-0.028-0.087,0.038-0.117,0.199-0.1c2.382,0.255,4.674,0.783,6.784,1.998c1.173,0.676,2.394,0.864,3.462-0.281
				c1.562-0.289,2.631-2.566,4.613-1.146c0.702,0.784,0.639,1.582-0.107,2.252c-1.282,1.152-0.517,1.803,0.522,2.423
				c0.393,0.848,0.854,1.451,1.96,0.979c0.934-0.398,1.927-0.655,2.901-0.952c0.892-0.271,1.702-0.301,2.309,0.563
				c0.542,1.627-0.569,2.163-1.768,2.517c-1.788,0.526-2.672,1.348-2.143,3.455c0.422,1.684-0.47,2.178-2.421,1.642
				c-1.209-3.667-4.184-5.724-7.31-7.345c-7.6-3.943-15.483-5.395-23.664-1.599c-0.804,0.372-1.649,0.708-2.444-0.066
				c-0.299-0.252-0.466-0.578-0.523-0.961c0.445-1.415,0.173-2.551-1.163-3.405c-0.799-0.511-1.191-1.27-0.607-2.217
				c0.291-0.607,0.108-0.747-0.495-0.473c-1.177,0.422-2.627-0.341-3.607,0.867c-1.461,1.5-3.02,1.617-4.668,0.322
				c-0.507-0.398-1.04-0.744-1.668-0.907c-1.513-0.392-2.273-1.022-1.273-2.703c0.702-1.182,0.077-1.724-1.232-1.72
				c-2.619,0.009-5.241-0.49-7.856,0.122c-1.058,0.247-2.058,0.032-2.073-1.251c-0.014-1.231,0.958-1.197,1.914-1.091
				c1.905,0.213,3.604-1.14,5.17-0.85c-2.048-0.037-4.317,0.969-6.789,0.88c-1.538-0.055-3.051,0.91-2.545,3.155
				c0.141,0.624,0.155,1.44-0.079,2.119c-0.37,1.068-0.967,1.872-2.279,1.347c-0.096-0.082-0.168-0.138-0.195-0.153
				c0.085,0.088,0.166,0.181,0.26,0.294c0.583,1.362-0.569,1.996-1.181,2.831c-0.876,1.198-1.771,2.392-2.952,3.325
				c-2.678-0.033-5.055-0.865-7.073-2.655c-0.089-1.404,0.732-1.969,1.937-1.66c1.76,0.452,3.262-0.096,4.775-0.797
				c1.299-2.256-1.378-2.573-1.972-3.913c-0.379-0.854-1.203-0.331-1.735,0.229c-3.894,3.812-7.995,1.241-12.001,0.315
				c-1.316-0.304-1.378-1.444-0.31-2.292c0.343-0.271,0.986-0.031,1.214-1.096c-3.454,1.453-7.876-0.062-9.707,4.518
				c-1.982,0.049-3.879,0.299-5.346,1.887c-0.462,0.5-1.123,0.535-1.742,0.144c-1.202-1.323,0.017-2.102,0.675-3.02
				c0.593-0.828,1.803-1.578,0.407-2.712c-0.57-0.969,0.156-2.63-1.578-2.938c0.008,0.02,0.008-0.011-0.016,0.003
				c0.217,0.235-0.088-0.09,0.144,0.165c0.442,1.37-0.049,2.086-1.469,2.152c-0.217-0.097-0.408-0.278-0.63-0.332
				c-1.634-0.398-3.322,0.89-4.98-0.298c-1.312-0.938-2.934-0.354-4.258-0.524c3.148-0.345,6.227,1.499,9.542,0.998
				c0.64,0.381,0.818,0.93,0.6,1.627c-0.191,0.606-0.3,1.276-0.664,1.776c-0.646,0.885-0.194,2.579-1.75,2.729
				c-1.621,0.155-1.773-1.496-2.201-2.437c-0.652-1.436-1.678-1.907-3-1.801c-2.73,0.222-5.563-0.029-7.992,1.762
				c-1.472,1.085-2.657-0.299-3.943-0.743c-0.57-0.918-1.21-1.484-2.28-0.601c-0.853,0.704-1.948,0.961-2.87,1.623
				c-1.775,1.272-4.453,0.257-5.218-1.81c-0.292-0.792-0.624-1.45-1.66-1.76c0.409,0.928,0.893,2.098-0.306,2.348
				c-2.899,0.607-5.892,1.46-8.678-0.493c-0.441-0.969-1.087-1.431-2.214-1.076c-0.96,0.302-1.985,0.639-2.757-0.438
				c-0.464-1.444,0.651-1.741,1.179-1.994c-1.62,0.839-3.785,1.562-5.75,2.702c-0.916,0.531-1.76,0.717-2.572-0.15
				c-0.706-1.593,0.597-2.147,1.549-2.73c2.103-1.289,4.492-1.95,6.738-2.918c-4.532,0.604-8.123,3.174-11.728,5.726
				c-1.292-0.059-1.883-0.772-1.897-2.024c1.181-2.137,3.447-2.896,5.322-4.157c0.888-0.597,2.11-0.729,2.78-2.153
				c-1.704,0.114-2.676,1.155-3.849,1.84c-1.132,0.66-1.876,2.386-3.657,1.567c-0.427-0.294-0.682-0.752-0.543-1.211
				c0.252-0.829,0.576-1.328-0.709-1.771c-1.253-0.434-0.533-1.688-0.127-2.636c-2.013,0.834-1.996,2.935-2.964,4.388
				c-2.624-0.011-5.303,0.188-7.572-1.57c-0.188-0.146-0.351-0.328-0.551-0.457c-0.692-0.445-1.548-0.955-2.053,0.005
				c-0.521,0.992,0.426,1.444,1.186,1.657c3.577,1.003,6.741,2.795,9.812,4.833c1.478,0.979,2.664,2.27,4.028,3.365
				c0.974,0.78,1.864,1.164,3.033,0.608c0.656-0.104,1.223,0.047,1.663,0.564c0.678,0.849,0.357,1.604-0.179,2.39
				c-0.719,1.054-1.41,2.127-1.966,3.077c1.775-1.779,3.46-3.912,5.781-5.354c0.942-0.585,1.914-1.297,3.027-0.281
				c0.633,1.08,0.012,1.729-0.759,2.4c-1.604,1.396-3.362,2.616-4.811,4.195c-1.104,1.204-2.104,2.454-2.435,4.109
				c-0.812,1.083-1.737,0.832-2.735,0.276c-6.375-3.555-13.188-4.674-20.311-3.207c-4.519,0.93-8.967,2.25-12.536,5.461
				c-0.42,0.379-1.283,0.615-0.575,1.457c0.147,0.157,0.276,0.313,0.423,0.464c0.5,0.647,1.773,0.798,1.435,1.957
				c-0.348,1.187-1.47,1.079-2.396,1.11c-2.386,0.077-4.775,0.111-7.162-0.033c-0.816-0.05-1.624-0.216-1.904-1.142
				c-0.235-0.778,0.089-1.468,0.699-1.895c1.111-0.775,0.836-1.291-0.154-1.879c-6.975-4.146-14.089-5.274-21.476-1.054
				c-2.034,0.021-3.071-1.006-3.184-3.008c0.148-0.492,0.489-0.926,0.979-0.913c2.756,0.069,5.292-0.998,7.925-1.525
				c6.899-1.384,12.816,1.038,18.577,4.342c0.976,0.56,1.913,1.612,3.21,0.616c0.93-0.664,1.916-1.271,2.371-2.403
				c0.51-1.888-1.154-2.571-2.073-3.618c-0.822-0.938-1.402-1.735-0.164-2.73c0.639-0.535,1.344-0.392,2.054-0.219
				c1.169,0.984,2.391,0.945,3.784,0.411c1.412-0.543,2.733-1.337,4.246-1.621c0.899-0.169,1.629,0.073,1.949,0.955
				c0.345,0.944-0.231,1.466-1.023,1.872c-1.508,0.774-3.323,0.992-4.685,2.442c1.104,0.542,1.886,0.003,2.646-0.292
				c6.815-2.654,13.771-3.205,20.884-1.431c1.501,0.374,2.792,0.709,2.277-1.696c-0.222-1.035,0.81-1.357,1.813-1.63
				c-3.611-3.746-7.748-6.046-12.354-7.604c-2.14-0.724-4.272,0.209-6.417-0.048c-0.695-0.083-1.079,0.628-1.197,1.298
				c-0.157,0.889-0.52,1.664-1.048,2.388c-1.234,1.688-1.975,1.837-3.773,0.676c-0.525-0.339-0.99-0.935-1.694-0.552
				c-0.595,0.324-0.433,0.938-0.419,1.503c0.053,2.247-0.871,3.019-3.407,2.909c-1.814-1.823-2.745,0.108-3.937,0.931
				c-1.993,1.712-2.981-0.431-3.994-1.297c-1.335-1.141-1.793-0.706-2.312,0.549c-2.36,0.741-4.67,0.45-6.948-0.371
				c-2.854-1.028-4.758-3.303-6.902-5.261c-0.351-0.751-0.822-0.986-1.711-0.75c-1.625,0.432-3.27,0.794-4.83,1.432
				c-0.689,0.281-1.35,0.785-1.955-0.112c-0.853-1.338,0.122-1.898,1.082-2.328c1.79-0.802,3.688-1.27,5.628-1.553
				c0.318-0.039-0.051,0.346,0.021,0.034c0.013-0.057-0.048-0.075-0.18-0.048c-1.899,0.396-3.801,0.789-5.578,1.603
				c-0.921,0.422-1.76,0.906-1.077,2.145c0.541,0.619,0.741,1.435,1.216,2.095c0.285,0.765,0.084,1.457-0.513,1.934
				c-2.015,1.609-3.27,3.862-4.95,5.755c-1.811,0.916-3.246-0.203-4.559-1.088c-1.017-0.685-0.602-1.66,0.393-2.283
				c1.068-0.671,2.329-0.921,3.409-1.555c0.964-2.332-0.924-3.291-2.335-3.916c-1.8-0.799-2.979-1.899-3.927-3.561
				c-1.051-1.842-2.861-2.602-5.312-2.922c0.785,1.557,2.089,1.529,3.071,1.984c1.27,0.587,2.843,0.815,2.976,2.668
				c-0.541,1.438-1.604,1.407-2.725,0.863c-2.238-1.086-4.321-1.953-6.504,0.318c-0.811,0.844-2.122,0.313-3.002-0.557
				c-1.398-1.384-2.962-1.313-4.637-0.594c-1.624,0.699-3.15,1.562-4.481,2.718c-1.322,1.147-2.76,2.265-1.993,4.417
				c0.19,0.536-0.149,1.084-0.682,1.416c-1.885,0.199-2.973-0.937-3.916-2.325c-0.125-0.982,0.725-1.464,1.157-2.153
				c0.545-0.869,0.829-1.649-0.134-2.401c-0.498-0.74-0.353-1.461,0.095-2.15c0.625-0.962,0.365-1.729-0.473-2.392
				c-0.104-0.105-0.21-0.211-0.316-0.315c0.237,0.231,0.468,0.468,0.698,0.715c0.393,1.996-0.649,3.917-2.679,4.967
				c-3.117-1.722-6.394-2.019-9.898-1.433c-1.163,0.194-3.372,1.007-3.482-1.15c-0.104-2.017,2.056-1.498,3.389-1.818
				c0.244-0.059,0.475,0.176,0.647-0.044c-2.36,0.039-4.66,0.582-6.973,1.078c-1.055,0.226-2.251,0.611-2.919-0.783
				c-0.188-0.979,0.311-1.671,1.194-1.707c2.225-0.09,4.041-1.559,5.329-1.604c-1.865,0.581-4.514,1.712-7.414,1.876
				c-0.788,0.044-1.521,0.681-1.785,1.461c-0.708,2.093-2.137,1.966-3.85,1.512c-4.466-1.185-8.947-1.389-13.475-0.199
				c-1.635,0.43-3.19,0.985-4.711,1.702c-0.996,0.469-1.935,1.155-3.132,1.059c-1.217-1.021-2.918-0.696-4.215-1.498
				c-0.519-1.028-0.938-2.348-2.33-0.774c-2.902,0.671-3.288,0.554-6.559-1.919c0.562,0.584,1.994,0.895,1.195,2.4
				c-2.629-0.21-4.716,0.095-6.463,2.767c-0.806,1.233-3.699,1.746-5.628-0.084c-0.924-0.876-2.248-0.672-3.436-0.773
				c-0.866-0.074-1.91-0.053-2.099-1.174c-0.202-1.199,0.783-1.399,1.668-1.581c0.9-0.186,1.818-0.432,1.754-1.58
				c-0.073-1.293-1.056-1.685-2.167-1.801c-1.655-0.173-3.19,0.035-4.347,1.43c-0.826,0.994-1.929,1.587-3.178,1.179
				c-2.179-0.714-3.871,0.172-5.586,1.296c-1.746,1.143-3.394,2.385-4.78,4.021c0.807-0.243,1.455-0.722,2.182-1.027
				c0.756-0.317,1.47-0.228,1.857,0.533c0.394,0.773-0.07,1.3-0.716,1.738c-0.65,0.441-1.483,0.514-1.848,0.965
				c1.597-0.721,3.208-1.979,5.207-2.417c0.917-0.2,1.862-0.303,2.195,0.793c0.312,1.028-0.375,1.59-1.287,1.969
				c-1.899,0.789-4.019,0.92-5.565,1.833c4.276-2.074,9.09-2.062,13.943-1.56c1.274,0.132,2.559,0.415,3.812,0.197
				c4.657-0.811,9.222-0.438,13.745,0.822c1.189,0.331,1.741,0.182,1.616-1.351c-0.143-1.752,0.769-2.19,2.386-1.218
				c0.734,0.44,1.261,0.588,1.905,0.014c0.569-0.377,1.064-0.953,1.868-0.695c1.589,1.219,0.104,2.291-0.201,3.431
				c0.923-0.708,1.708-2.051,3.043-0.975c1.021,0.822,0.276,1.709-0.409,2.606c1.084-1.012,2.121-1.835,3.342-2.382
				c0.806-0.361,1.582-0.337,2.092,0.488c0.45,0.729,0.132,1.367-0.408,1.923c-0.556,0.572-1.242,0.986-1.836,1.516
				c-1.097,0.976-1.25,1.964-0.198,3.058c0.5,0.98,1.477,1.433,2.286,2.065c0.553,0.433,0.878,0.952,0.653,1.649
				c-0.25,0.777-0.837,1.149-1.653,1.08c-0.943-0.081-1.918-0.032-2.686-0.781c-6.784-6.612-14.987-9.152-24.296-8.285
				c-3.046,0.283-6.05-0.07-9.07-0.15c-7.999-0.211-14.957,2.244-20.542,8.045c-1.318,1.369-2.541,0.919-3.589,0.224
				c-1.106-0.732,0.016-1.257,0.616-1.702c0.525-0.442,0.753-0.942,0.33-1.591c-0.316-0.484-0.411-0.981-0.01-1.477
				c1.481-0.914,1.741-1.822,0.081-2.868c-0.736-0.464-1.438-0.999-2.14-1.522c-0.774-0.578-1.352-1.252-0.767-2.202
				c0.589-0.958,1.492-0.694,2.34-0.327c0.512,0.222,1,0.485,1.476,0.782c1.089,0.681,2.052,1.508,2.938,2.432
				c-1.43-1.465-2.815-2.973-4.427-4.251c-0.816-0.647-1.665-1.344-1.038-2.6c1.165-1.131,2.332-0.581,3.073,0.359
				c1.389,1.76,3.413,2.807,4.897,4.808c-0.431-1.167-1.339-2.095-0.082-2.989c1.052-1.343,1.766-2.937,3.104-4.068
				c-1.343,1.091-2.055,2.643-3.015,4.015c-2.609,0.946-5.618-0.812-5.472-3.574c0.071-1.344-0.539-2.368-0.585-3.521
				c-0.014,0.05-0.027-0.002-0.037,0.042c0.597,1.633,0.557,2.971-1.313,3.702c-0.572,0.223-0.569,0.929-0.884,1.38
				c-1.903,2.721-3.193,2.827-5.374,0.389c-0.562-0.629-1.167-1.24-2.021-1.013c-1.479,0.394-2.817,0.222-4.146-0.489
				c-0.342-0.184-0.805-0.191-1.127,0.117c-0.366,0.352-0.161,0.725,0.073,1.062c0.09,0.131,0.284,0.243,0.288,0.373
				c0.09,3.537,2.447,3.788,5.215,3.875c1.636,0.05,3.25,0.734,4.641,1.758c0.84,0.617,1.464,1.263,0.904,2.39
				c-0.925,1.022-1.894,0.444-2.814,0.021c-1.387-0.638-2.811-1.08-4.349-1.067c-0.587,0.005-1.174,0.149-1.267,0.82
				c-0.114,0.812,0.485,1.151,1.166,1.194c1.502,0.095,3.011,0.016,4.516-0.004c1.901-0.024,3.572,0.595,5.122,1.625
				c0.717,0.476,1.445,0.971,1.063,2.034c-0.756,0.964-1.704,0.71-2.602,0.333c-1.595-0.669-3.26-0.688-4.935-0.642
				c-0.688,0.019-1.648-0.228-1.903,0.583c-0.27,0.859-0.457,1.897,0.304,2.709c0.38,0.405,0.891,0.741,0.922,1.342
				c0.046,0.903-0.021,1.771,1.336,1.67c1.23-0.092,2.404-0.082,2.782-1.641c0.461-1.897,2.026-2.266,3.551-1.931
				c1.486,0.326,3.74,0.097,3.534,2.719c-0.093,1.182,0.738,1.262,1.499,1.444c2.336,0.562,4.707,1.009,6.978,1.787
				c1.041,0.355,2.682,0.357,2.358,2.257c-0.721,1.194-1.744,0.762-2.758,0.535c-2.592-0.58-5.171-1.273-7.872-1.166
				c2.097,1.26,4.528,1.338,6.742,2.136c0.821,0.295,1.691,0.474,2.48,0.856c0.86,0.417,1.47,1.051,1.077,2.089
				c-0.358,0.944-1.184,0.974-2.009,0.77c-2.474-0.615-4.912-1.368-7.523-2.026c0.685,0.376,1.398,0.328,1.888,0.775
				c0.62,0.566,0.776,1.723,0.195,1.823c-1.594,0.274-2.241,1.821-3.584,2.241c-0.782,0.245-1.501,0.224-1.673,1.301
				c-0.183,1.14,0.743,1.236,1.303,1.699c0.134,0.155,0.236,0.326,0.244,0.538c-0.022-0.236-0.152-0.418-0.305-0.597
				c-0.662-0.416-1.492-0.694-1.157-1.772c0.328-1.055,1.167-0.859,1.953-0.881c1.551-0.044,3.152-0.534,4.318,1.142
				c0.515,0.74,1.501,0.353,2.259,0.231c2.894-0.465,5.75-0.217,8.6,0.324c2.627,0.498,3.773-0.513,3.632-3.296
				c-0.036-0.701-0.359-1.39-0.012-2.093c0.855-1.229-0.067-1.868-0.902-2.391c-3.649-2.286-7.521-4.105-11.682-5.255
				c-0.257-0.071-0.616-0.032-0.898-0.125c-0.868-0.282-1.773-0.675-1.498-1.799c0.294-1.199,1.224-1.333,2.28-0.967
				c3.51,1.218,6.935,2.628,10.195,4.424c2.99,1.646,5.978,3.291,8.437,5.717c1.612,1.591,2.899,0.535,3.979-0.36
				c-2.427,1.619-2.408,1.596-4.829-0.429c-3.679-3.077-7.891-5.271-12.237-7.219c-0.524-0.234-1.067-0.465-1.614-0.664
				c-0.875-0.32-1.72-0.782-1.372-1.875c0.382-1.197,1.387-1.075,2.396-0.851c0.871,0.194,1.687,0.473,2.431,0.97
				c1.612,1.078,3.364,1.182,5.272,1.057c3.285-0.213,6.567-0.867,9.887-0.224c1.266,0.245,2.177-0.338,2.47-1.678
				c0.086-0.392,0.077-0.8,0.174-1.186c0.352-1.396,0.578-2.969,2.528-2.918c1.127,0.028,2.706,2.158,2.669,3.571
				c-0.021,0.795-0.384,1.691,0.656,2.028c0.88,0.285,1.216-0.487,1.614-1.099c0.595-0.914,1.438-1.212,2.493-0.84
				c0.688,0.242,1.398,0.33,2.126,0.27c0.899-0.075,1.634,0.187,1.775,1.171c0.137,0.948-0.493,1.425-1.297,1.768
				c-1.534,0.655-3.225,0.841-4.64,1.576c0.932-0.229,2.271-1.422,2.868,0.114c0.804,2.071-1.566,1.647-2.399,2.424
				c-0.357,0.06,0.168,0.303-0.031,0.052c0.694-0.436,1.301-0.41,1.771,0.337c0.874,1.658-0.642,2.034-1.5,2.699
				c-1.831,1.418-1.486,5.005,0.618,5.998c0.603,0.284,1.046-0.115,1.555-0.334c1.378-0.593,5.956,1.371,6.444,2.756
				c0.107,0.302-0.077,0.621-0.252,0.893c-0.168,0.219-0.321,0.449-0.441,0.701c0.116-0.194,0.235-0.387,0.358-0.585
				c0.339-0.463,0.644-0.985,0.521-1.536c-0.482-2.181,0.586-2.706,2.489-2.482c2.005,0.236,4.195-0.767,5.924,1.354
				c0.475,0.582,1.929-0.081,2.698-0.915c0.927-1.004,2.016-1.168,3.22-0.478c0.613,0.358,0.893,1.718,1.82,0.979
				c0.826-0.657,1.496-1.728,0.378-2.833c-3.182-1.233-6.448-0.469-9.68-0.4c-0.87,0.018-1.742,0.222-2.606,0.392
				c-1.007,0.197-1.801-0.157-2.054-1.157c-0.29-1.142,0.55-1.55,1.471-1.767c4.155-0.979,8.359-1.256,12.607-0.834
				c0.474,0.047,0.938,0.199,1.421,0.09c-2.059-0.907-5.912-1.055-9.184-0.387c-4.228,0.861-8.446,1.764-12.61,2.902
				c-0.385,0.105-0.766,0.247-1.165,0.276c-0.803,0.061-1.437-0.23-1.671-1.039c-0.208-0.72,0.196-1.234,0.771-1.601
				c1.515-0.965,3.269-1.294,4.965-1.707c2.949-0.718,5.945-1.246,8.902-1.927c4.516-1.04,8.911-0.429,13.301,0.647
				c0.929,0.229,1.825,0.595,2.688,0.652c-9.188-2.93-18.455-2.15-27.707,0.208c-1.226,0.312-2.494,0.98-3.325-0.657
				c0.981-2.541,3.397-2.756,5.517-3.176c8.113-1.605,16.245-1.76,24.371,0.046c2.18,0.484,4.35,0.944,6.337,1.996
				c1.222,0.798,2.177,0.485,3.168-0.544c1.571-1.63,3.61-2.612,5.622-3.584c0.986-0.477,2.156-0.69,2.727,0.495
				c0.664,1.379-0.553,1.784-1.527,2.232c-1.137,0.523-2.266,1.065-3.349,1.697c3.361-1.941,6.876-3.431,10.83-3.506
				c0.914-0.018,2.246-0.399,2.351,1.092c0.084,1.191-0.974,1.512-1.964,1.685c-2.436,0.426-4.816,0.977-7.153,1.871
				c-1.519,0.581-1.972,1.223-1.423,2.673c0.224,0.591,0.505,1.195,0.039,1.81c-1.549,1.052-2.837-0.017-4.117-0.604
				c-3.365-1.545-6.694-1.749-10.042-0.03c-0.835,0.429-1.782,0.829-1.546,2.077c0.073,0.299,0.073,0.787,0.316,0.833
				c0.952,0.18,1.653,1.864,2.514,1.018c1.028-1.013-0.702-1.584-1.042-2.434c-0.461-1.174,0.364-1.601,1.196-1.932
				c1.974-0.784,3.973-1.037,6.064-0.34c1.33,0.442,1.902,1.123,1.253,2.473c-0.475,0.988-0.515,2.01,0.568,2.432
				c1.181,0.459,1.564-0.502,1.937-1.5c0.682-1.829,1.004-1.816,3.96-0.255c0.74,1.054-0.146,1.76-0.54,2.585
				c-0.754,1.582-0.389,2.264,1.31,2.331c0.634,0.025,1.308-0.175,1.83,0.282c1.557,1.364,3.186,1.671,5.028,0.564
				c0.606-0.364,1.234-0.141,1.774,0.289c0.18,0.151,0.325,0.331,0.44,0.535c0.65,2.31,1.262,2.62,3.524,1.715
				c0.949-0.38,1.87-0.989,2.816-0.009c0.233,0.337,0.294,0.712,0.255,1.117c-0.05,0.513-0.509,1.071,0.07,1.521
				c0.287,0.224,0.618,0.066,0.812-0.216c0.418-0.604,0.289-1.19-0.141-1.732c-0.193-0.245-0.47-0.419-0.622-0.705
				c0.305-2.087,4.943-4.594,6.698-3.622c0.826,0.457,0.659,1.392,0.864,1.785c0.519-2.354-1.954-4.417-1.301-7.224
				c0.271-1.163-1.095-1.343-2.109-1.217c-1.832,0.229-3.59,0.734-5.263,1.523c-0.94,0.444-1.944,0.65-2.516-0.421
				c-0.615-1.153,0.314-1.721,1.177-2.216c1.607-0.922,3.352-1.501,5.141-1.908c1.451-0.331,2.396-0.849,2.598-2.582
				c0.199-1.709,1.914-2.529,3.164-1.324c3.501,3.376,7.946,2.68,12.083,3.381c2.278,0.386,4.559,0.77,6.781,1.42
				c3.202,0.938,3.509,0.769,4.28-2.364c2.232-0.531,3.23-2.555,4.766-3.935c0.787-0.706,1.479-1.923,2.82-1.072
				c1.45,0.921,0.888,2.202,0.464,3.425c-0.153,0.44-0.416,0.828-0.589,1.26c0.976-0.569,1.839-1.72,3.143-0.61
				c0.174,0.17,0.292,0.373,0.373,0.601c0.242,1.183-0.738,1.65-1.374,2.308c-0.936,0.967-2.011,1.798-2.807,2.897
				c-0.909,0.745-0.636,1.682-0.371,2.579c0.221,0.743,0.665,1.462-0.097,2.146c-0.132,0.109-0.242,0.243-0.366,0.364
				c0.097-0.092,0.192-0.186,0.3-0.296c0.796-0.894,1.662-0.876,2.434-0.083c1.098,1.129,1.347,0.117,1.739-0.609
				c1.219-1.122,2.275-0.978,3.038,0.492c0.72,1.387,1.672,1.543,2.895,0.714c0.595-0.402,1.257-0.668,1.909-0.938
				c1.872-0.777,2.789-0.161,2.363,1.8c-0.379,1.746,1.03,1.16,1.665,1.586c0.205,0.088,0.408,0.182,0.613,0.271
				c1.597,0.809,3.113,0.705,4.79,0.009c3.179-1.321,6.534-2.162,9.981-2.249c2.168-0.056,1.538-1.64,1.771-2.693
				c0.306-1.387-0.916-1.451-1.814-1.687c-0.62-0.163-1.273-0.107-1.909-0.18c-0.889-0.102-1.908-0.186-1.92-1.356
				c-0.011-1.177,0.999-1.322,1.896-1.372c4.152-0.229,8.158,0.346,11.711,2.646c0.915,0.593,1.459,0.622,2.27-0.021
				c7.044-5.599,14.952-9.543,23.52-12.212c0.535-0.167,1.061-0.343,1.636-0.338c0.887,0.009,1.613,0.286,1.799,1.23
				c0.187,0.949-0.342,1.494-1.227,1.755c-1.151,0.339-2.337,0.542-3.496,0.841c-8.332,2.151-15.595,6.192-21.676,12.315
				c-0.63,0.636-1.378,1.188-0.718,2.22c0.125,2.476,1.438,4.207,3.452,5.502c0.201,0.928-0.556,1.145-1.114,1.508
				c-0.926,1.096,0.479,1.5,0.511,1.98c-0.216-0.445-1.443-0.955-0.437-2.042c1.72-0.962,2.69-2.789,4.508-3.697
				c5.105-2.549,10.259-3.244,15.625-0.768c-5.791-2.641-11.273-1.742-16.553,1.35c-0.526,0.308-0.991,0.511-1.524,0.074
				c-0.926-1.198-0.187-1.917,0.778-2.491c5.01-2.983,10.354-3.964,16.077-2.627c0.714,0.167,1.663,0.842,2.041-0.298
				c0.345-1.038,1.663-1.765,0.839-3.24c-1.053-1.885-0.776-2.449,1.314-2.943c2.484-0.586,5.013-0.984,7.586-0.78
				c1.009,0.08,2.349-0.045,2.333,1.417c-0.017,1.503-1.379,1.056-2.302,1.199c-0.21,0.033-0.377-0.155-0.577-0.025
				c1.207,0.06,2.405-0.058,3.603,0.019c0.843,0.053,1.744,0.021,1.702,1.263c-1.185,1.67-3.059,0.562-4.276,1.083
				c1.161,0.251,2.961-1.122,3.873,1.066c-0.202,0.956-0.294,1.994-1.572,2.164c-0.719-0.178-0.906-1.37-1.763-1.029
				c0.385-0.03,0.849,0.082,1.139,0.589c0.997,1.061,1.505,0.438,1.923-0.55c0.341-0.808,0.363-1.619,0.094-2.447
				c0.074-0.348,0.324-0.574,0.553-0.815c0.177-0.54,0-1.065-0.501-1.17c-2.368-0.494-2.206-1.821-1.019-3.518
				c-2.299-0.565-4.313,0.095-6.301,0.496c-3.592,0.727-7.177,1.512-10.704,2.526c-0.535,0.153-1.067,0.331-1.634,0.346
				c-0.778,0.021-1.462-0.188-1.697-1.01c-0.249-0.87,0.339-1.319,1.018-1.66c1.583-0.797,3.312-1.175,5.006-1.591
				c4.56-1.122,9.096-2.455,11.708-2.328c-3.281-0.224-8.643,0.35-13.944,1.61c-0.993,0.236-2.058,0.549-2.851-0.507
				c-0.526-1.528,0.801-1.843,1.645-2.095c4.12-1.231,8.309-2.129,12.641-2.286c3.342-0.121,6.696,0.446,10.027-0.213
				c0.476-0.094,0.976-0.033,1.381,0.263c1.89,1.381,4.169,1.492,6.601,2.105c-1.198-0.782-2.359-0.675-3.373-1.062
				c-0.927-0.354-2.321-0.363-2.008-1.84c0.326-1.538,1.658-1.188,2.754-1.027c1.885,0.274,3.743,0.729,5.853,0.978
				c-0.416-0.79-1.057-0.856-1.582-1.067c-2.293-0.923-2.736-1.869-1.31-3.974c4.363-6.435,10.555-9.688,18.298-9.788
				c4.161-0.054,8.061,1.312,11.848,2.926c0.939,0.399,1.957,0.906,1.625,2.286c-0.678,1.277-1.678,0.88-2.692,0.533
				c-3.633-1.241-7.227-2.553-11.108-2.956c-5.8-0.603-9.901,2.427-13.751,6.075c-0.513,0.485-1.178,1.263-0.156,2.006
				c1.955,0.203,3.609,1.503,5.639,1.457c0.678-0.016,1.186,0.459,1.337,1.161c0.155,0.726-0.209,1.218-0.824,1.553
				c-1.465,0.797-2.907,1.627-3.646,2.337c1.06-0.976,2.959-1.771,4.848-2.589c0.622-0.27,1.228-0.312,1.804,0.123
				c0.179,0.163,0.304,0.36,0.387,0.586c0.198,1.074-0.447,1.597-1.302,1.996c-1.583,0.741-3.034,1.737-3.906,2.118
				c4.065-2.962,9.357-4.334,15.159-3.967c0.942,0.06,1.923,0.039,2.829,0.226c2.348,0.485,4.62,0.331,6.359-0.523
				c-0.646-0.182-1.78-0.612-2.877-1.132c-0.858-0.407-1.518-0.95-0.975-2.049c2.887-2.779,5.673-5.596,9.432-7.347
				c6.73-3.138,13.513-3.732,20.526-1.277c1.174,0.411,2.543,0.77,2.35,2.515c-0.739,1.623-1.95,0.979-3.051,0.57
				c-5.688-2.113-11.429-1.846-17.122-0.187c-2.324,0.677-4.372,2.107-6.534,3.217c0.731-0.271,1.273-1.455,2.201-0.617
				c0.884,0.799,0.172,1.589-0.361,2.299c-0.495,0.659-0.706,1.626,0.207,1.732c1.923,0.225,2.953,2.218,4.942,2.195
				c0.824-0.01,1.683,0.324,1.63,1.359c-0.06,1.17-1.057,1.286-1.945,1.325c-2.876,0.127-5.713,0.56-8.555,0.98
				c-5.625,0.833-10.764,2.912-15.541,5.95c-0.93,0.591-1.841,1.418-3.009,0.435c-0.516-0.526-0.447-1.147-0.189-2.004
				c-1.229,0.715-1.173,2.557-2.728,2.573c-1.385,0.016-2.265-1.079-3.7-1.733c0.837,1.478-0.692,2.674,0.09,4.149
				c1.15-0.103,2.281-1.378,3.54-0.333c0.283,5.011,4.619,4.449,7.712,5.472c1.038,0.343,2.105,0.441,3.049,1.142
				c1.405,1.044,2.966,1.074,4.495,0.184c0.934-0.543,1.844-1.03,2.827-0.112c0.156,0.184,0.375,0.459,0.29,0.624
				c-1.126,2.197,0.271,3.472,1.692,4.745c0.596,0.526,1.35,0.512,2.046,0.477c2.55-0.126,3.543,1.716,4.527,3.53
				c0.48,0.887,0.096,1.859-0.867,1.701c-2.744-0.45-4.68,1.786-7.212,1.883c1.132,0.467,2.009-0.072,2.872-0.69
				c2.001-1.433,3.923-1.817,5.971,0.057c1.49,1.363,3.529,0.717,5.312,0.979c1.472,0.217,3.015-0.066,4.402,0.777
				c-0.279-0.424-0.682-0.475-1.055-0.578c-0.771-0.214-1.296-0.662-1.319-1.492c-0.023-0.888,0.599-1.23,1.354-1.416
				c1.111-0.273,2.063-0.656,1.377-2.104c-0.245-1.211-1.593-1.331-2.188-2.198c-0.266-1.763-1.383-2.269-3.05-2.308
				c-3.148-0.073-6.216,0.181-9.154,1.419c-0.964,0.406-1.969,0.727-2.939-0.037c-0.733-1.501,0.409-2.003,1.396-2.519
				c3.282-1.717,6.82-2.067,10.45-1.874c1.577,0.085,3.138,0.818,4.067-1.584c0.621-1.602,2.177-0.335,3.152,0.161
				c-0.584-0.221-1.104-0.881-1.97-0.82c-5.201,0.363-10.547-0.791-15.561,1.537c-0.694,0.321-1.413,0.211-1.973-0.407
				c-0.781-1.979,0.878-2.346,2.033-2.811c4.659-1.872,9.46-2.082,14.329-0.908c2.25,0.542,4.521,1.007,6.604,2.089
				c0.546,0.283,1.12,0.661,1.929,0.229c-0.071-1.071-1.057-1.192-1.747-1.563c-0.837-0.449-1.572-0.965-1.118-2.034
				c0.404-0.954,1.197-1.108,2.194-0.889c3.048,0.671,3.379,0.321,2.874-2.916c-0.076-0.491-0.213-1.147,0.161-1.354
				c1.716-0.944,2.779-3.185,5.227-2.698c1.456,1.733,2.233,3.631,1.434,5.906c-0.564,1.548,0.384,2.599,1.194,3.689
				c-0.492-1.104-1.639-1.935-1.408-3.355c0.904-1.238,1.913-0.578,2.683,0.034c1.41,1.124,1.853,0.202,2.315-0.891
				c1.762-0.218,3.531-0.761,5.297,0.032c0.243,0.215,0.418,0.485,0.415,0.812c-0.028,2.946-0.032,2.946,2.635,3.234
				c0.144,0.029,0.245-0.024,0.278-0.225c0.722-1.445,1.8-1.362,2.982-0.699c0.727,0.407,1.444,1.344,2.282,0.483
				c0.78-0.802-0.006-1.614-0.416-2.353c-0.295-0.53-0.342-1.042-0.028-1.575c0.237-0.34,0.651-0.675,0.967-0.528
				c1.604,0.742,3.48,0.516,5.024,1.451c0.551,0.902,0.075,1.676-0.322,2.496c1.728-1.59,3.707-2.784,5.67-3.995
				c0.85-0.523,1.854-0.899,2.539,0.166c0.696,1.081-0.1,1.764-0.933,2.343c-0.196,0.137-0.395,0.274-0.574,0.432
				c-0.815,0.715-2.557,1.127-2.268,2.164c0.363,1.309,2.136,0.924,3.336,1.146c0.468,0.086,1.009-0.048,1.419,0.165
				c3.669,1.899,6.915,0.494,10.484-0.73c5.658-1.941,11.566-2.217,17.315,0.155c0.992,0.409,1.962,0.844,1.643,2.229
				c-0.716,1.189-1.742,0.815-2.756,0.552c-2.469-0.643-4.929-1.452-7.507-1.344c-1.266,0.053-3.39-0.15-3.368,1.383
				c0.021,1.566,2.055,0.864,3.219,0.933c1.419,0.084,2.878,0.219,4.253-0.381c0.22-0.096,0.459-0.144,0.699-0.14
				c1.546,0.021,6.559,3.945,6.977,5.457c0.934,1.601-0.494,1.995-1.384,2.582c-0.945,0.624-2.197,0.887-2.368,2.296
				c-0.239,0.947,0.384,1.393,1.089,1.785c-0.598-0.384-1.187-0.774-1.192-1.586c0.194-1.168,1.084-1.552,2.019-2.073
				c1.034-0.579,2.714-0.979,1.938-2.878c-0.345-0.5-0.438-1.242-0.005-1.478c1.51-0.818,1.118-2.405,1.655-3.606
				c0.744-0.805,1.577-0.482,2.437-0.224c1.432,0.432,2.674,0.404,3.323-1.312c0.438-1.159,1.337-1.536,2.52-1.037
				c2.685,1.132,4.961-0.608,7.392-1.128c1.133-0.241,0.924-1.329,0.365-2.159c-0.629-0.934-1.511-1.648-2.247-2.489
				c-0.625-0.714-1.417-1.381-0.898-2.512c1.855-0.643,0.365-1.958,0.575-2.932c0.445-0.854,1.289-1.062,2.051-0.849
				c1.532,0.431,2.809-0.086,4.118-0.809c-1.219-0.489-2.325-1.204-3.426-1.929c-0.746-0.492-1.204-1.138-0.772-2.01
				c0.439-0.887,1.249-0.898,2.066-0.554c2.063,0.87,4.096,1.827,5.614,3.53c0.825,0.927,1.638,1.106,2.671,0.472
				c0.514-0.133,0.984-0.045,1.412,0.267c0.495,0.925,1.829,1.322,1.685,2.655c-0.68,1.256-1.91,0.978-2.981,0.986
				c-2.216,0.018-4.278,0.633-6.218,1.599c-1.604,0.797-1.717,1.834-0.301,2.894c1.463,1.094,2.258,3.179,4.503,3.139
				c0.317-0.006,0.63-0.104,0.952-0.109c-0.249-0.031-0.511-0.016-0.735-0.138c-0.405-0.218-0.552-0.568-0.393-1
				c0.221-0.602,0.732-0.812,1.316-0.879c1.792-0.204,3.498,0.233,5.164,0.8c0.91,0.311,1.689,0.906,2.28-0.513
				c0.296-0.712,1.18-0.776,1.911-0.451c1.3,0.576,2.051,0.038,2.754-1.068c1.231-1.938,2.272-2.072,4.029-0.561
				c0.91,0.784,2.275,1.078,2.452,2.716c0.088,0.81,3.29,0.717,3.753-0.104c0.301-0.534,0.679-1.021,1.272-0.903
				c1.782,0.35,3.677-0.251,5.385,0.653c0,0.319,0,0.64,0,0.96c-0.781,0.971-1.9,1.521-2.842,2.293
				c-0.57,0.467-1.414,0.793-1.142,1.776c0.599,0.033,0.156-0.504,0.354-0.683c1.038-0.915,1.536-3.191,3.63-1.467
				c0,7.2,0,14.399,0,21.6c-1.316,0.624-1.636-0.48-2.146-1.243c-1.545-2.311-1.562-2.317-4.104-1.117
				c-1.631,0.771-3.009,0.137-3.887-1.943c-0.618-1.466-1.018-3.021-1.546-4.525c-0.321-0.911-0.681-1.809-1.479-2.409
				c-0.964-0.725-2.038-1.196-2.923,0.011c-0.811,1.104-0.753,2.277,0.446,3.143c1.162,0.839,2.548,1.322,3.68,2.223
				c0.883,0.703,1.875,1.358,1.16,2.741c-1.128,1.09-2.076,0.206-2.999-0.335c-1.856-1.09-3.545-2.463-5.81-0.549
				c-0.812,0.687-2.179,0.359-3.058-0.562c-0.96-1.006-1.613-1.179-2.573,0.199c-1.436,2.061-2.602,2.04-4.543,0.384
				c-0.396-0.338-0.672-1.06-1.349-0.688c-0.526,0.289-0.685,0.895-0.554,1.474c0.194,0.857,0.889,0.756,1.538,0.704
				c11.695-0.944,20.53,4.482,28.216,12.574c0.955,1.007,0.738,2.565,1.929,3.36c0,0.479,0,0.96,0,1.439
				c-1.68,2.591-4.514,3.883-6.684,5.926c-1.497,1.409-3.075,2.73-4.462,4.262c-1.691-0.003-3.156-0.363-3.614-2.298
				c0.155-0.467,0.45-0.831,0.861-1.108c2.066-1.395,2.168-2.062,0.044-3.28c-5.91-3.394-12.229-4.591-19.145-3.062
				c3.149,0.176,6.116,0.353,8.954,1.327c1.285,0.441,2.57,0.972,1.306,2.697c-1.054,0.676-2.165,0.372-3.246,0.156
				c-3.912-0.778-7.886-1.056-11.845-1.411c-2.771-0.25-5.559-0.229-8.303,0.521c-1.014,0.277-2.18,0.787-3.081-0.355
				c-0.93-1.467,0.068-2.06,1.215-2.408c2.06-0.628,4.13-1.283,6.331-1.19c1.636,0.068,3.132-0.354,4.617-1.051
				c8.593-4.029,16.803-2.263,24.826,1.771c0.849,0.427,1.591,1.068,2.384,1.607c0.801,0.545,1.73,0.724,2.395-0.069
				c0.822-0.98,1.63-1.831,2.83-2.433c0.983-0.493,0.478-1.526,0.018-2.318c-1.117-1.922-2.867-3.219-4.587-4.511
				c-6.456-4.854-13.446-7.989-21.815-6.688c-1.82,0.282-3.345,0.923-4.559,2.345c-1.056,1.238-2.227,1.942-3.968,1
				c-1.549-0.838-2.905,0.299-4.289,0.855c-1.057,0.426-2.032,1.474-3.298,0.474c-0.365-0.517-0.496-1.1,0.134-1.437
				c1.734-0.93,2.814-2.707,4.643-3.505c0.519-0.226,0.979-0.569,1.252-1.079c0.399-0.745,0.664-1.641-0.174-2.142
				c-0.988-0.592-1.487,0.324-1.737,1.06c-0.641,1.887-1.064,3.866-2.958,5.009c-1.157,0.699-2.215,1.553-3.695,0.568
				c-1.25-0.832-2.103,0.08-2.799,1.008c-1.199,1.598-2.271,3.286-3.442,4.905c-0.632,0.872-1.269,1.439-2.386,0.898
				c-1.039-0.905-0.575-1.862-0.069-2.8c1.442-2.677,3.041-5.24,5.264-7.363c1.442-1.377,1.764-2.909,0.474-4.868
				c-1.255,2.028-1.438,4.214-2.238,6.162c-0.521,1.268-1.122,2.337-2.641,2.385c-1.775,0.055-2.466,1.224-2.938,2.674
				c-0.485,1.484,0.034,2.648,1.132,3.64c0.298,0.269,0.675,0.425,0.983,0.688c0.784,1.137,2.073,2.213,0.567,3.701
				c-1.199,0.693-1.922-0.079-2.627-0.873c-3.755-4.23-8.361-6.939-13.934-8.083c-1.098-0.225-2.98-0.124-2.527-2.291
				c0.667-1.246,1.746-1.246,2.892-0.903c2.669,0.799,5.411,1.37,7.969,2.525c0.891,0.402,1.8,0.774,2.615-0.165
				c0.561-1.191,1.717-2.525,0.637-3.698c-1.388-1.509-3.498-2.209-5.454-1.615c-1.792,0.543-3.01,0.24-4.318-0.905
				c-0.994-0.869-2.253-0.905-3.521-0.624c2.568,0.365,4.825,1.688,7.323,2.285c1.271,0.304,2.937,0.612,2.871,2.595
				c-0.564,1.17-1.42,1.258-2.553,0.812c-1.834-0.722-3.783-1.087-5.66-1.664c-1.589-0.488-2.494-0.121-2.896,1.48
				c-0.386,0.8-0.972,1.22-1.896,1.038c-3.543-0.695-7.052-0.292-10.571,0.26c-0.882,0.138-1.911,0.303-2.677-0.506
				c-0.915-1.22-0.054-1.825,0.918-1.978c1.424-0.225,2.787-0.89,3.817-0.732c-5.662,1.06-11.68,2.385-17.22,5.847
				c1.99-0.774,3.481-2.114,5.444-2.478c0.896-0.166,1.726-0.375,2.384,0.48c0.619,1.689-0.754,2.094-1.783,2.576
				c-3.344,1.568-6.399,3.613-9.48,5.611c-3.525,2.287-6.318,5.37-8.669,8.165c6.494-4.724,12.939-10.482,21.051-13.536
				c12.257-4.615,23.544-2.619,33.684,5.682c1.05,0.859,2.17,1.642,3.131,2.608c0.917,0.924,1.36,0.21,1.808-0.51
				c1.723-0.323,2.908-1.514,4.157-2.598c3.222-2.797,6.864-4.942,10.636-6.89c0.571-0.294,1.181-0.486,1.789-0.687
				c1.027-0.338,2.109-0.678,2.67,0.63c0.523,1.223-0.494,1.757-1.323,2.189c-4.401,2.297-8.703,4.742-12.392,7.932
				c0.604-0.048,1.478-0.412,1.966,0.575c0.639,1.348-0.224,1.849-1.258,2.299c-4.034,1.757-7.599,4.194-11.018,6.982
				c-3.444,2.809-5.911,6.364-8.601,9.782c-3.588,0.097-7.081-0.754-10.619-1.126c-4.011-0.422-7.907,0.206-11.854,0.433
				c-1.308,0.075-2.854,1.03-3.888-0.662c-0.781-1.052-0.24-1.659,0.769-1.923c2.376-0.621,4.28-2.09,6.334-3.313
				c5.192-3.095,10.762-3.085,16.456-1.834c1.013,0.223,1.996,0.594,3.035,0.667c0.669,0.048,1.335-0.087,1.49-0.876
				c0.128-0.645-0.323-1.055-0.845-1.305c-1.518-0.729-3.113-1.26-4.77-1.542c-1.102-0.188-2.191-0.439-3.296-0.6
				c-0.991-0.144-2.038-0.356-1.936-1.636c0.107-1.344,1.179-1.664,2.293-1.477c3.141,0.53,6.321,0.903,9.289,2.195
				c3.563,1.55,4.177,1.277,5.725-2.383c0.856-0.808,1.598-1.544,0.251-2.645c-4.598-3.756-9.242-7.378-15.314-8.484
				c-8.51-1.552-16.312,0.209-23.554,4.833c-4.037,2.578-8.131,5.066-11.882,8.064c-1,0.799-2.005,1.595-2.68,2.706
				c-0.308,0.506-0.419,1.077,0.102,1.521c0.424,0.361,0.907,0.195,1.342-0.041c0.495-0.269,0.873-0.694,1.318-1.03
				c5.228-3.956,10.56-7.762,16.715-10.172c4.77-1.867,9.717-2.11,14.821-1.757c4.842,0.335,9.466,1.283,13.956,3.034
				c1.096,0.428,2.394,0.702,2.251,2.345c-0.635,1.382-1.691,1.206-2.82,0.753c-5.492-2.206-11.255-2.797-17.11-3.023
				c-5.315-0.205-10.059,1.49-14.596,3.98c-3.858,2.117-7.455,4.634-10.861,7.422c-0.186,0.152-0.382,0.293-0.543,0.47
				c-0.556,0.604-1.161,1.284-0.516,2.09c0.66,0.825,1.496,0.415,2.111-0.119c1.331-1.156,2.711-2.257,4.214-3.154
				c6.646-3.968,13.524-7.255,21.573-6.794c0.239,0.014,0.481-0.013,0.719,0.019c1.058,0.139,2.598-0.184,2.658,1.343
				c0.062,1.573-1.48,1.426-2.542,1.445c-8.393,0.158-15.577,3.753-22.556,7.904c-1.052,0.625-2.64,1.438-2.105,2.83
				c0.594,1.544,2.209,0.638,3.391,0.422c1.412-0.258,2.808-0.626,4.175-1.067c1.343-0.435,2.35-0.192,2.932,1.189
				c0.044,1.754-1.459,1.613-2.49,2.015c-4.401,1.712-9.001,1.892-13.632,1.619c-4.059-0.237-8.098,0.53-12.15,0.15
				c-0.897-1.148-2.442-1.953-2.046-3.803c0.577-0.742,1.312-0.829,2.085-0.385c2.295,1.322,4.929,0.759,7.34,1.378
				c0.795,0.205,1.254-0.017,1.695-0.577c0.88-0.715,1.834,0.018,3.243-0.142c-3.743-3.655-6.652-7.666-11.291-9.825
				c-1.082-0.504-2.872-0.517-2.567-2.523c0.409-1.027,1.175-1.193,2.145-0.898c0.687,0.209,1.37,0.455,2.104,0.425
				c0.788-0.031,1.389-0.392,1.681-1.134c0.305-0.774,0.003-1.4-0.602-1.907c-0.245-0.206-0.508-0.395-0.799-0.525
				c-7.314-3.296-14.764-6.336-22.944-6.073c-8.172,0.262-15.707,3.168-23.039,6.598c1.419,0.001,2.688-0.598,4.02-0.928
				c1.229-0.304,2.502-0.765,3.539,0.476c0.369,1.329-0.343,1.892-1.507,2.203c-7.897,2.115-15.118,5.566-21.375,10.903
				c-0.696,0.594-1.4,1.104-2.358,0.543c-0.134-3.76-3.441-4.562-5.815-6.248c-1.435-1.019-3.364-1.496-3.937-3.496
				c0.011-0.24,0.085-0.462,0.199-0.671c1.025-1.237,2.105-0.778,3.183-0.084c2.285,1.474,4.649,2.819,6.966,4.242
				c0.656,0.403,1.479,1.004,2.157,0.199c0.667-0.793-0.03-1.504-0.542-2.099c-0.888-1.03-1.776-2.112-2.894-2.864
				c-4.916-3.308-10.032-6.307-15.679-8.169c-3.972-1.311-8.09-0.107-12.133,0.013c-2.69,0.08-5.411,0.514-8.024,1.317
				c-2.211,0.681-2.401,1.469-0.896,3.173c1.37,1.547,2.912,2.106,4.921,1.329c1.414-0.547,2.883-0.985,4.418-1.045
				c0.845-0.032,1.667,0.17,1.843,1.153c0.166,0.919-0.341,1.505-1.216,1.787c-0.76,0.245-1.509,0.493-2.314,0.597
				c-1.31,0.167-2.423,0.596-3.01,2.005c-0.702,1.685-1.939,1.562-3.019,0.421c-2.418-2.561-4.948-4.985-7.724-7.155
				c-0.84-0.656-1.015-1.407-0.167-2.213c1.72-1.967,4.103-2.781,6.48-3.34c6.736-1.585,13.617-1.982,20.486-1.434
				c3.968,0.317,7.307,2.71,10.771,4.517c4.12,2.148,8.133,4.473,11.184,8.115c0.394,0.471,0.937,1.156,1.596,0.756
				c0.885-0.539,0.198-1.266-0.103-1.866c-0.313-0.624-0.386-1.208,0.011-1.81c0.814-0.813,1.548-0.461,2.323,0.113
				c0.599,0.442,1.014,1.281,2.394,0.984c-2.027-3.988-3.87-7.904-7.358-10.725c-14.757-11.934-35.612-13.282-51.77-3.365
				c-0.923,0.566-1.861,1.14-2.826,2.535c2.479-1.047,4.075-2.574,6.095-3.251c1.159-0.389,2.446-1.189,3.291,0.533
				c0.284,0.963-0.208,1.489-1.013,1.892c-0.787,0.394-1.604,0.729-2.368,1.164c-1.922,1.096-1.98,1.751-0.195,3.034
				c0.934,0.673,1.734,1.274,0.714,2.466c-1.013,0.985-1.942,0.215-2.854-0.169c-5.165-2.175-10.423-3.914-16.022-4.725
				c-8.146-1.179-15.541,0.351-22.253,5.146c-2.032,1.451-4.147,2.786-5.682,4.216c4.413-3.82,9.569-7.123,15.903-7.974
				c9.865-1.326,19.429-0.401,28.318,4.459c1.419,0.775,3.435,1.29,3.177,3.643c-0.797,1.128-1.81,0.903-2.74,0.3
				c-5.97-3.867-12.727-5.292-19.615-5.656c-7.195-0.382-14.197,0.829-20.203,5.364c-0.867,0.655-1.782,1.249-2.245,1.562
				c3.04-2.138,4.006-2.314,5.776-0.832c0.39,1.117-0.359,1.571-1.161,2.008c-0.974,0.531-2.008,0.961-2.885,1.654
				c-0.497,0.393-1.122,0.864-0.714,1.563c0.407,0.696,1.143,0.448,1.7,0.135c3.496-1.966,7.396-2.829,11.154-4.063
				c1.381-0.453,3.116-1.399,4.064,0.83c0.062,1.282-0.934,1.521-1.838,1.837c-2.859,0.999-5.774,1.836-8.604,2.917
				c-0.941,0.359-2.028,0.533-2.584,1.745c0.895,0.421,1.57-0.113,2.207-0.449c8.362-4.412,16.798-3.503,25.2-0.246
				c4.149,1.608,8.401,3.107,11.734,6.275c1.015,0.966,2.722,0.421,3.612,1.662c0.309,1.745-1.186,1.805-2.184,2.324
				c-0.616,0.321-1.545,0.452-1.387,1.385c0.169,1.001,1.06,1.318,1.972,1.455c0.482,0.072,0.955-0.042,1.417-0.176
				c6.541-1.884,13.26-3.193,19.956-3.978c11.529-1.35,23.108-1.303,34.224,3.077c4.161,1.64,8.518,2.742,12.623,4.562
				c2.642,1.171,5.159,2.562,7.597,4.082c1.07,0.669,2.502,1.473,1.421,3.227c-1.279,0.981-2.27,0.021-3.262-0.548
				c-9.031-5.182-18.727-8.618-28.873-10.746c-8.782-1.841-17.614-1.936-26.411,0.078c-4.132,0.946-8.303,1.693-12.458,2.52
				c-1.144,0.227-3.072,0.262-2.624,2.007c0.421,1.637,2.104,0.94,3.3,0.605c10.321-2.893,20.843-4.33,31.536-3.588
				c7.885,0.547,15.756,1.529,23.282,4.22c0.98,0.351,1.917,0.804,2.793,1.373c0.806,0.524,1.38,1.202,0.841,2.188
				c-0.532,0.974-1.424,0.92-2.297,0.512c-5.225-2.446-10.883-3.13-16.478-4.054c-10.048-1.657-20.059-1.381-30.027,0.678
				c-2.112,0.437-4.28,0.584-6.353,1.218c-0.456,0.14-0.985,0.341-1.005,0.895c-0.021,0.576,0.531,0.744,0.976,0.909
				c1.243,0.461,2.506,0.523,3.753,0.031c9.539-3.759,19.227-3.438,28.985-1.059c1.476,0.36,2.989,0.57,4.424,1.078
				c0.82,0.291,1.841,0.584,1.541,1.756c-0.278,1.084-1.296,1.094-2.18,0.994c-2.859-0.322-5.701-0.771-8.51-1.415
				c-5.547-1.272-11.113-1.074-16.665-0.081c-3.368,0.604-3.356,0.669-3.481,3.85c-0.885,1.271-1.93,0.679-2.96,0.242
				c-2.924-1.235-5.75-2.631-8.998-3.052c-4.922-0.637-9.457,0.486-13.912,2.341c-0.911,0.379-1.775,1.267-2.78,0.192
				c-1.423-1.878-0.442-3.041,1.654-4.111c-8.384-5.195-17.07-7.455-26.562-6.522c2.822,1.124,5.618,2.269,8.253,3.746
				c2.059,1.154,4.195,1.511,6.457,0.965c1.631-0.395,3.164,0.021,4.723,0.336c0.88,0.178,1.495,0.639,1.409,1.612
				c-0.075,0.866-0.743,1.078-1.5,1.27c-0.847,0.215-1.726-0.148-2.579,0.197c2.511,2.639,3.027,2.829,5.918,1.637
				c1.084-0.446,1.648-0.095,2.142,0.772c0.53,0.683-0.059,1.361-0.037,2.09c1.128,0.072,1.961-0.646,2.891-1.008
				c9.521-3.704,18.306-1.819,26.614,3.493c3.084,1.972,6.186,3.975,8.626,6.756c0.879,1.001,2.168,2.163,0.665,3.703
				c-1.778,0.452-3.436,0.343-4.814-1.039c0.452-1.226,0.23-2.093-0.949-2.944c-4.947-3.57-9.959-7.031-16.076-8.216
				c-4.326-0.837-8.596-0.266-12.695,1.396c-0.519,0.21-1.035,0.432-1.464,0.805c-0.174,0.151-0.37,0.31-0.202,0.835
				c2.452-0.688,4.841-1.707,7.447-2.045c4.018-0.521,7.798,0.154,11.504,1.506c4.293,1.565,8.256,3.77,11.897,6.545
				c0.659,0.502,1.075,0.979,0.408,1.757c-2.22,1.539-2.721,1.509-4.224-0.249c0.192-0.637,0.302-1.184-0.579-1.471
				c-4.746-1.544-9.076-4.293-14.175-4.832c-3.546-0.376-6.862,0.228-10.271,1.801c0.922,0.828,1.52,0.383,2.08,0.23
				c5.131-1.395,10.118-0.624,15.001,1.088c1.522,0.534,3.029,0.886,4.621,1.096c1.246,0.163,2.83-0.106,3.423,1.584
				c-0.28,1.159-1.016,1.373-2.151,1.313c-3.825-0.199-7.657-0.3-11.481,0.08c-0.48,0.048-0.949,0.015-1.421-0.074
				c-0.91-0.173-2.15-0.042-2.087-1.416c0.066-1.46,1.22-1.354,2.286-1.227c0.492,0.06,0.982,0.202,1.486,0.108
				c0.319,0.007-0.203-0.342-0.063-0.053c0.05,0.102-0.022,0.168-0.198,0.13c-2.581-0.572-5.096-0.048-7.609,0.462
				c-1.196,0.243-2.195,0.682-2.901,1.856c-0.95,1.584-2.368,1.7-3.878,0.656c-3.181-8.88-10.438-13.607-18.159-17.888
				c-10.039-5.564-21.054-7.143-32.248-8.285c-7.083-0.723-14.033-0.165-20.977,0.981c-12.505,2.063-22.812,7.992-30.784,17.881
				c-1.204,1.492-2.637,2.795-3.664,4.43c-0.458,0.729-1.071,1.351-1.089,2.333c-0.028,1.597-1.207,1.802-2.373,1.51
				c-1.354-0.34-1.314-1.432-0.885-2.504c0.684-1.707,1.624-3.295,2.357-4.978c0.79-1.015,2.058-1.488,2.823-2.56
				c0.865-1.213,0.945-2.303-0.085-3.454c-1.954-2.186-4.262-3.845-7.017-4.864c-0.449-0.167-0.929-0.238-1.378-0.408
				c-1.985-0.75-3.847-2.126-6.154-1.276c-4.187,1.541-8.532,2.797-11.507,6.501c-0.88,1.095-1.069,2.159,0,3.183
				c1.2,1.148,2,0.197,2.788-0.638c2.274-2.411,5.08-4.116,7.852-5.855c1.516-0.952,3.092-1.122,4.739-0.141
				c2.606,1.555,5.22,3.084,7.245,5.412c0.708,0.813,1.389,1.622,0.505,2.729c-1.052,0.757-1.766,0.032-2.53-0.564
				c-1.703-1.325-3.371-2.704-5.298-3.709c-0.933-0.486-1.927-0.986-2.977-0.617c-3.437,1.208-6.085,3.507-8.037,6.501
				c-0.901,1.383-0.115,2.837,1.008,3.865c1.138,1.043,2.225,0.41,3.196-0.425c1.037-0.892,2.119-1.737,3.406-2.207
				c1.906-0.694,3.21,0.174,3.355,2.133c-0.926,1.066-0.926,1.066-2.649,1.62c1.982,0.77,2.067-1.162,2.934-1.923
				c2.185-0.291,3.411,1.479,4.853,2.522c0.919,0.666,0.419,2.133-0.949,2.221c-2.617,0.169-5.188,0.793-7.86,0.516
				c-4.123-0.429-8.251-0.933-12.415-0.772c-0.778,0.029-1.58-0.056-2.768,0.701c2.714,0.379,4.988,0.656,7.22,0.675
				c5.333,0.044,10.619,0.674,16.01,0.224c7.873-0.657,15.805-0.305,23.581,1.508c0.7,0.163,1.407,0.302,2.132,0.286
				c0.24-0.005,0.48-0.028,0.867-0.391c-1.048-1.005-2.431-1.019-3.658-1.308c-1.396-0.33-2.829-0.504-4.237-0.796
				c-0.798-0.166-1.701-0.335-1.68-1.36c0.024-1.177,1.036-1.283,1.917-1.359c1.916-0.166,3.827-0.071,5.727,0.225
				c2.012,1.406,4.339,1.336,6.595,1.172c1.181-0.086,2.317-0.465,3.53-0.115c1.065,0.307,2.047-0.107,2.994-0.572
				c1.221-0.6,2.469-1.13,3.745-1.605c1.147-0.427,2.31-0.699,2.837,0.694c0.566,1.497-0.757,1.881-1.777,2.318
				c-0.807,0.347-1.667,0.568-2.456,0.96c-0.571,0.282-1.231,0.552-1.332,1.267c-0.115,0.817,0.662,0.978,1.162,1.338
				c1.892,1.363,3.804,1.482,6.103,0.837c3.837-1.078,7.802-1.604,11.832-0.986c3.146,0.481,3.855,0.039,5.1-2.898
				c0.967-2.282,2.017-2.734,4.364-1.785c1.604,0.647,3.048,1.622,4.875,1.784c1.153,0.103,1.614,1.169,1.705,2.309
				c0.083,1.038-0.002,2.083,0.108,3.115c0.233,2.18,1.66,3.151,3.695,2.333c1.35-0.544,3.425-0.797,3.541-2.419
				c0.109-1.539-1.897-1.934-3.123-2.573c-3.539-1.848-7.259-3.303-11.011-4.647c-1.825-0.653-3.822-0.548-5.73-0.867
				c-0.932-0.155-1.98-0.035-2.364-1.23c-0.037-0.236-0.009-0.466,0.073-0.69c1.551-1.498,3.422-0.907,5.179-0.691
				c7.02,0.863,13.288,3.729,19.2,7.448c1.526,0.961,2.964,1.303,4.719,0.635c2.95-1.122,3.007-1.13,1.469-3.976
				c-0.24-0.444-0.308-0.895-0.144-1.378c1.551-1.899,2.749-0.438,3.738,0.573c1.571,1.605,3.213,1.862,5.21,1.013
				c1.902-0.811,2.106-1.507,0.636-2.901c-1.165-1.104-2.518-1.99-3.927-2.733c-5.512-2.906-10.833-6.156-16.38-8.998
				c-0.355-0.182-0.707-0.371-1.054-0.571c-0.686-0.395-1.158-0.925-0.836-1.754c0.346-0.887,1.055-1.119,1.956-0.892
				c1.959,0.494,3.742,1.412,5.482,2.386c4.321,2.419,8.696,4.741,12.952,7.279c2.141,1.276,4.188,2.637,5.866,4.503
				c1.021,1.135,2.195,1.777,3.825,1.392c1.087-0.257,2.051,0.104,2.62,1.179c0.131,1.794-1.371,1.735-2.433,2.022
				c-8.49,2.291-16.782,5.094-24.588,9.214c-2.893,1.525-5.67,3.253-8.554,4.794c-0.423,0.226-0.831,0.486-1.144,0.855
				c-0.369,0.437-0.738,0.937-0.254,1.479c0.368,0.413,0.875,0.264,1.33,0.071c0.369-0.156,0.726-0.352,1.032-0.6
				c3.912-3.179,8.797-4.432,13.184-6.67c0.861-0.44,1.662-0.614,2.279,0.377c1.122,1.503,0.01,2.135-1.09,2.743
				c-2.592,1.434-5.562,2.025-8.084,3.615c-0.846,0.534-1.928,0.76-2.47,1.844c2.007,1.047,3.73,0.588,5.329-0.745
				c1.922-1.603,4.133-2.716,6.378-3.771c1.119-0.526,2.155-0.708,2.849,0.648c0.256,1.357-0.782,1.779-1.678,2.295
				c-1.44,0.831-3.019,1.439-4.53,2.814c1.645-0.12,2.645-0.937,3.619-1.664c3.156-2.356,6.888-2.887,10.606-3.428
				c0.999-0.146,2.152-0.145,2.403,1.322c0.261,1.531-0.83,1.655-1.872,1.896c-2.18,0.503-4.404,0.826-6.514,1.613
				c-1.192,0.444-2.315,0.996-3.493,2.231c3.2-0.546,5.963-1.252,8.794-1.584c1.928-0.226,2.977-1.934,3.118-3.46
				c0.127-1.368,0.195-2.449,1.771-3.217c-1.279-0.722-2.08-0.219-2.849-0.007c-2.004,0.552-4.04,0.975-6.04,1.541
				c-0.932,0.265-1.875,0.552-2.672-0.331c-0.649-1.421,0.101-2.039,1.355-2.425c3.201-0.983,6.33-2.193,9.562-3.08
				c1.259-0.346,2.403-0.877,3.1-2.067c0.201-0.344,0.46-0.739,0.203-1.106c-0.337-0.482-0.873-0.267-1.314-0.153
				c-2.629,0.678-5.226,1.474-7.695,2.601c-2.031,0.928-4.119,1.729-6.115,2.729c-1.229,0.617-2.145,0.381-2.713-0.92
				c-0.24-1.42,0.944-1.736,1.806-2.185c7.436-3.865,15.317-6.4,23.632-7.404c8.593-1.037,17.193-0.752,25.766,0.41
				c0.316,0.043,0.671,0.176,0.946,0.089c3.771-1.191,6.793,1.239,10.145,2.061c0.485,0.118,0.965,0.321,1.196,0.732
				c1.894,3.372,5.391,4.7,8.484,6.385c6.16,3.354,11.166,7.899,15.312,13.479c0.525,0.707,1.037,1.425,1.833,1.857
				c0.578,0.313,1.213,0.565,1.805,0.139c0.653-0.47,0.422-1.121,0.176-1.734c-0.32-0.8-0.873-1.569-0.345-2.489
				c0.705-0.849,1.529-0.774,2.276-0.139c1.204,1.023,2.147,0.598,3.065-0.385c1.447-1.549,3.189-2.823,4.328-4.661
				c0.244-0.21,0.533-0.323,0.841-0.417c0.619-0.188,1.294-0.422,1.216-1.221c-0.061-0.625-0.676-0.838-1.19-1.043
				c-3.678-1.466-7.412-2.227-11.38-1.213c-1.286,0.328-2.798,1.112-3.86-0.572c-0.271-1.956,1.291-2.156,2.537-2.496
				c4.702-1.284,9.282-0.692,13.748,1.106c1.031,0.416,2.048,0.881,3.118,1.175c1.114,0.308,2.217,0.355,2.96-0.807
				c0.139-0.223,0.318-0.4,0.538-0.542c1.08-0.36,2.022-0.074,2.884,0.605c0.876,1.596-0.192,2.306-1.363,2.973
				c-3.965,2.258-7.385,5.238-10.739,8.276c-2.385,2.159-4.849,4.391-4.169,8.17c-0.848,0.938-1.797,0.701-2.833,0.32
				c-2.986-1.095-5.995-2.13-9.053-3.011c-0.736-0.211-1.687-0.602-2.14,0.326c-0.411,0.842,0.383,1.235,1.03,1.586
				c0.812,0.44,1.213,1.032,0.61,1.93c-0.31,0.466-0.615,0.912-1.212,1.079c-0.617,0.173-1.299,0.389-1.199,1.222
				c0.087,0.732,0.729,1.027,1.331,1.225c3.402,1.112,6.716,2.442,9.956,3.96c0.289,0.135,0.613,0.188,0.932,0.175
				c0.97-0.039,2.224-0.121,2.5-1.017c0.357-1.158-1.064-1.103-1.756-1.458c-2.843-1.461-5.977-2.154-8.935-3.312
				c-0.808-0.316-2.02-0.381-1.656-1.814c0.49-1.303,1.387-1.49,2.665-1.158c3.771,0.98,7.44,2.22,10.949,3.918
				c0.928,0.448,1.83,0.952,2.782,1.348c2.358,0.979,3.001,0.737,4.292-1.578c2.485,0.078,4.91,0.679,7.483,0.188
				c2.078-0.397,4.026-0.816,5.772-2.035c1.243-0.866,2.529-1.657,4.018-2.073c0.964-0.27,1.95-0.553,2.499,0.579
				c0.59,1.218-0.414,1.688-1.235,2.187c-0.407,0.248-0.825,0.48-1.335,0.822c5.557-0.336,10.883,0.46,16.095,1.923
				c2.61,0.732,5.104,0.985,7.734,0.179c3.316-1.019,6.588-1.057,9.593,1.056c0.571,0.809,0.243,1.491-0.275,2.175
				c-0.761,1.005-1.835,1.978-0.528,3.319c1.275,1.309,3.31,1.592,4.817,0.576c1.124-0.76,2.202-1.593,3.27-2.433
				c6.883-5.419,14.663-7.868,23.451-8.472c11.11-0.765,21.392,1.679,31.402,5.971c1.476,0.633,3.736,1.118,3.042,3.738
				c-1.176,1.206-2.209,0.216-3.245-0.244c-8.994-3.984-18.313-6.694-28.234-6.397c-9.358,0.281-18.208,2.324-25.403,8.921
				c-1.396,1.28-3.409,1.812-4.389,3.603c-1.083,0.758-1.942,0.048-2.864-0.411c-4.86-2.417-9.563-5.152-14.695-7.015
				c-10.669-3.871-21.558-4.103-32.584-2.063c-5.85,1.082-11.392,3.111-16.668,5.846c-0.657,0.341-1.7,0.597-1.518,1.594
				c0.145,0.785,1.13,0.763,1.758,1.06c3.041,1.437,5.804,1.476,8.646-0.834c2.678-2.176,5.97-3.359,9.366-4.106
				c1.012-0.223,2.028-0.506,3.044-0.003c0.205,0.139,0.357,0.323,0.467,0.544c0.322,1.594-0.646,2.058-1.96,2.369
				c-2.871,0.681-5.739,1.398-8.173,3.207c-0.748,0.556-1.689,0.93-1.928,2.115c0.975,0.535,1.67-0.136,2.34-0.57
				c2.514-1.629,5.304-2.537,8.164-3.295c1.293-0.342,2.382-0.268,2.964,1.162c0.136,1.28-0.815,1.585-1.733,1.864
				c-1.739,0.53-3.427,1.193-5.117,1.859c-1.913,0.753-3.297,1.828-3.13,4.127c-0.116,0.223-0.285,0.395-0.495,0.53
				c-1.234,0.577-2.157-0.201-3.083-0.78c-4.722-2.954-9.736-5.185-15.222-6.272c-3.221-0.64-6.482-0.915-9.76-0.993
				c-0.855-0.021-1.777-0.098-2.461,0.665c0.074,0.845,0.765,0.723,1.261,0.751c10.686,0.62,20.057,5.033,29.208,10.087
				c0.209,0.116,0.408,0.251,0.614,0.375c1.019,0.608,2.024,1.345,1.229,2.649c-0.744,1.221-1.814,0.608-2.726,0.066
				c-3.168-1.885-6.514-3.427-9.852-4.962c-6.67-3.069-13.571-5.254-21.056-4.894c-1.143,0.055-2.242,0.153-3.127,0.97
				c-0.543,0.501-1.069,1.076-0.762,1.869c0.284,0.735,1.014,0.696,1.654,0.631c3.519-0.36,6.998-0.168,10.495,0.386
				c4.621,0.731,8.97,2.313,13.337,3.872c1.008,0.36,1.79,0.979,1.332,2.132c-0.418,1.053-1.418,0.872-2.288,0.617
				c-2.686-0.786-5.322-1.747-8.041-2.414c-4.424-1.084-8.854-2.163-13.483-1.443c-1.627,0.253-2.91,0.752-3.234,2.625
				c-0.151,0.874-0.855,1.336-1.765,1.322c-0.801-0.012-1.261-0.472-1.644-1.176c-1.068-1.968-1.233-1.973-2.742-0.298
				c-0.376,0.417-0.71,0.874-1.198,1.163c-0.604,0.358-1.22,0.361-1.781-0.104c-0.516-0.429-0.462-0.971-0.27-1.534
				c0.448-1.312,1.271-2.385,2.151-3.429c4.027-4.772,7.826-9.718,11.093-15.046c1.979-3.227,3.305-6.742,4.215-10.414
				c0.212-0.858,0.365-1.724,0.354-2.609c-0.009-0.716,0.074-1.52-0.71-1.899c-0.783-0.379-1.377,0.157-1.924,0.624
				c-5.397,4.603-9.64,10.022-11.697,16.92c-1.233,4.135-2.477,8.281-2.525,12.659c-0.016,1.401-0.84,2.562-1.197,3.863
				c-0.193,0.706-0.729,1.042-1.461,0.947c-0.697-0.092-1.183-0.495-1.349-1.189c-0.32-1.342-0.248-2.69-0.057-4.05
				c1.117-7.975,3.287-15.628,6.864-22.864c0.495-1.002,0.939-2.042,0.977-3.191c0.019-0.565,0.027-1.203-0.578-1.461
				c-0.638-0.271-1.072,0.235-1.391,0.68c-1.773,2.468-3.348,5.067-4.988,7.624c-4.305,6.71-7.247,14.052-10.082,21.447
				c-0.143,0.374-0.268,0.759-0.46,1.107c-0.603,1.093-1.047,2.665-2.741,1.926c-1.588-0.692-1.165-2.124-0.587-3.309
				c1.203-2.463,1.514-5.064,1.208-7.721c-0.44-3.817-1.154-7.597-2.497-11.213c-0.578-1.557-1.055-3.164-1.978-5.09
				c-0.932,3.65-1.606,6.872-0.997,10.237c0.714,3.938,1.611,7.833,2.73,11.677c0.312,1.075,0.647,2.152,0.707,3.274
				c0.09,1.658-0.609,2.635-2.398,2.442c-0.564-0.061-1.11-0.002-1.663-0.006c-1.865-0.013-2.726-1.213-1.801-2.821
				c1.301-2.266,1.021-4.427,0.075-6.954c-1.442,2.204-1.849,4.636-2.864,6.791c-0.606,1.289-0.753,3.48-2.874,2.661
				c-1.99-0.77-0.926-2.601-0.513-3.982c0.767-2.562,1.931-4.978,2.936-7.452c-1.427,3.518-2.83,7.05-5.072,10.156
				c-0.692,0.959-1.34,2.141-2.855,1.615c-1.526-0.528-1.227-1.767-1.167-3.001c0.395-8.203-0.812-16.261-2.422-24.263
				c-0.854-4.236-1.52-8.494-2.063-12.777c-0.168-1.322,0.17-2.541,0.454-3.782c0.854-3.727,1.771-7.439,2.454-11.406
				c-0.908,0.321-1.008,0.982-1.229,1.518c-1.278,3.105-2.662,6.166-3.984,9.25c-1.527,3.56-0.928,7.381-0.559,11.117
				c0.322,3.259,0.812,6.501,1.147,9.755c0.436,4.235-1.098,8.208-2.14,12.219c-0.804,3.093-1.925,6.101-2.668,9.214
				c-0.361,1.512-1.222,2.334-2.841,2.396c-1.687,0.064-2.962-0.338-3.532-2.138c-1.545-4.879-3.112-9.748-5.449-14.332
				c-3.486-6.839-6.92-13.706-10.646-20.418c-0.579-1.041-1.161-2.711-2.702-2.212c-1.448,0.469-1.1,2.15-1.113,3.385
				c-0.031,2.841,0.7,5.543,1.738,8.163c3.236,8.168,7.392,15.772,13.58,22.134c0.836,0.859,1.493,1.877,2.231,2.822
				c0.425,0.545,0.637,1.229,0.153,1.767c-1.448,1.611-3.31,0.332-4.966,0.512c-1.024,0.111-1.483-1.032-1.299-2.146
				c0.08-0.478,0.062-0.958-0.083-1.425c-0.222-0.721-0.599-1.338-1.37-1.54c-0.843-0.221-1.366,0.301-1.759,0.943
				c-0.626,1.022-1.244,2.05-1.883,3.066c-0.559,0.889-1.224,1.492-2.343,1.251c-1.145-0.248-1.462-1.117-1.534-2.15
				c-0.202-2.9-0.048-5.753,1.103-8.479c0.427-1.011,0.914-2.029,0.148-3.412c-1.637,1.852-1.582,3.967-1.784,5.941
				c-0.23,2.246-0.596,4.424-1.538,6.495c-0.521,1.145-1.239,1.95-2.574,1.544c-1.408-0.429-1.461-1.51-1.038-2.717
				c0.132-0.377,0.22-0.771,0.253-1.171c0.039-0.474,0.169-1.064-0.305-1.321c-0.674-0.365-0.974,0.318-1.32,0.709
				c-0.768,0.863-1.104,1.927-1.346,3.035c-0.217,0.993-0.862,1.531-1.89,1.46c-1.04-0.072-1.532-0.74-1.66-1.729
				c-0.146-1.116-0.208-2.245-0.08-3.354c0.538-4.67-0.341-9.328-0.066-13.994c0.022-0.389-0.019-0.806-0.499-1.244
				c-1.351,0.781-1.944,2.205-2.457,3.534c-1.528,3.969-2.868,7.999-1.929,12.372c0.134,0.624,0.057,1.277,0.089,1.916
				c0.057,1.129-0.101,2.161-1.464,2.327c-1.384,0.168-1.74-0.897-1.998-1.938c-0.601-2.42-0.865-4.875-0.608-7.369
				c0.271-2.623,0.578-5.242,0.934-8.271c-2.802,5.05-5.338,9.784-6.82,15.006c-0.131,0.461-0.254,0.926-0.462,1.361
				c-0.421,0.883-1.058,1.433-2.09,1.17c-0.921-0.234-1.206-0.964-1.236-1.833c-0.006-0.159-0.038-0.331,0.01-0.478
				c1.755-5.335-0.214-10.429-0.993-15.601c-0.243-1.609-0.502-3.218-0.613-4.931c1.063,7.002-0.671,13.652-1.932,20.348
				c-0.104,0.55-0.208,1.096-0.513,1.586c-0.416,0.668-0.964,1.077-1.781,0.94c-0.814-0.137-1.225-0.67-1.358-1.466
				c-0.135-0.806-0.051-1.592,0.111-2.382c0.402-1.958,0.597-3.959,1.073-5.898c1.404-5.726,0.529-11.102-2.332-16.197
				c-0.486-0.866-0.792-1.788-1.048-2.74c1.393,4.684,0.709,9.452,0.417,14.164c-0.231,3.736-0.71,7.479-1.376,11.174
				c-0.249,1.381-0.117,3.483-2.278,3.316c-2.313-0.179-1.655-2.12-1.462-3.566c0.765-5.701,1.6-11.419,1.713-17.156
				c0.157-7.992-1.232-15.768-5.085-22.916c-0.707-1.312-1.54-2.883-3.381-2.589c-1.965,0.313-2.466,2.065-2.905,3.723
				c-0.594,2.243-0.105,4.475,0.249,6.664c0.498,3.081,1.3,6.127,2.768,8.905c2.195,4.155,3.463,8.582,4.269,13.178
				c0.648,3.701,1.36,7.392,1.985,11.097c0.2,1.184,0.289,2.354-1.181,2.695c-1.418,0.329-2.047-0.666-2.336-1.84
				c-0.726-2.949-1.26-5.943-1.78-8.933c-0.622-3.574-1.447-7.098-2.091-9.894c0.994,5.479,1.87,11.728,2.388,18.022
				c0.098,1.192,0.058,2.573-1.568,2.79c-1.614,0.214-1.938-0.985-2.187-2.256c-0.57-2.91-0.812-5.854-1.01-8.745
				c-0.687,3.064-1.506,6.156-2.207,9.276c-0.29,1.287-0.976,2.126-2.471,2.127c-1.483,0-2.419-0.616-2.895-2.012
				c-0.774-2.272-1.263-4.625-2.021-6.906c-2.793-8.401-7.242-16.016-11.29-23.815c-1.249-2.407-2.304-4.938-3.954-7.125
				c-0.517-0.685-1.014-1.604-2.078-1.272c-0.905,0.282-0.951,1.244-1.055,2.009c-0.434,3.199,0.513,6.226,1.579,9.131
				c3.343,9.113,8.179,17.332,15.183,24.167c0.86,0.839,1.636,1.75,2.394,2.684c0.633,0.78,0.89,1.567,0.135,2.348
				c-0.711,0.736-1.669,0.813-2.366,0.106c-1.245-1.263-2.329-1.488-3.643-0.106c-0.535,0.562-1.349,0.488-1.996,0.016
				c-0.732-0.534-0.916-1.276-0.608-2.129c0.325-0.901,0.752-1.787,0.56-2.783c-0.161-0.833-0.555-1.55-1.46-1.706
				c-0.845-0.146-1.304,0.471-1.678,1.112c-0.541,0.926-0.805,1.925-0.608,2.993c0.217,1.181,0.308,2.363-1.154,2.715
				c-1.574,0.377-1.688-1.039-2.151-2.003c-2.343-4.876-4.74-9.728-6.669-14.789c1.818,4.718,3.983,9.278,6.212,13.812
				c0.476,0.968,1.412,2.11-0.07,2.88c-1.473,0.766-2.189-0.456-2.723-1.562c-4.845-10.057-9.126-20.284-9.409-31.698
				c-0.015-0.613,0.048-1.28-0.559-1.941c-0.874,3.513-1.404,6.976-1.714,10.438c-0.511,5.718-1.234,11.468,0.426,17.153
				c0.449,1.536,0.907,3.066,1.454,4.57c0.51,1.403,0.765,2.726-1.149,3.227c-2.407,0.63-4.126-0.278-4.451-2.284
				c-0.206-1.265,0.452-2.495,0.153-3.86c-0.714,1.537-1.081,3.103-1.686,4.58c-0.469,1.146-1.118,2.045-2.506,1.543
				c-1.284-0.465-1.178-1.529-0.888-2.613c0.748-2.791,1.735-5.505,3.018-8.08c1.063-2.132,1.196-4.397,1.394-6.008
				c-0.132,0.752-0.695,2.026-1.333,3.294c-1.91,3.788-3.4,7.762-5.025,11.675c-0.469,1.129-1.069,2.124-2.452,1.67
				c-1.567-0.514-1.282-1.777-0.917-2.995c0.791-2.64,2.4-4.897,3.226-6.932c-1.312,2.699-2.924,5.837-4.786,8.848
				c-0.965,1.562-2.288,2.05-3.87,1.379c-1.583-0.671-1.167-2.106-0.79-3.337c1.706-5.566,1.616-11.188,0.651-16.856
				c-0.407-2.391-0.358-4.79,0.158-7.572c-0.289,1.916-0.928,3.302-1.531,4.704c-0.646,1.504-0.774,3.052-0.575,4.694
				c0.584,4.835,1.508,9.654,1.147,14.551c-0.094,1.269,0.516,3.346-1.613,3.408c-1.97,0.057-1.434-1.904-1.713-3.137
				c-0.793-3.492-0.023-7.178-1.315-10.824c-1.304,0.901-1.391,2.171-1.498,3.402c-0.225,2.58,0.414,5.088,0.69,7.627
				c0.14,1.285,0.429,2.862-1.108,3.3c-1.639,0.466-3.543,0.683-5.032-0.527c-1.025-0.834-0.673-2.016-0.156-3.086
				c0.385-0.797,1.22-1.258,1.42-2.208c-1.16,1.08-2.043,2.408-3.056,3.616c-0.567,0.676-1.091,1.396-1.808,1.911
				c-1.764,1.266-3.63,0.474-3.787-1.669c-0.105-1.428-0.318-2.906,0.024-4.304c0.557-2.268-0.108-4.454-0.195-6.676
				c-0.018-0.469-0.055-1.032-0.639-1.153c-0.54-0.111-0.822,0.372-1.171,0.694c-2.985,2.761-4.818,6.109-5.273,10.184
				c-0.07,0.632-0.147,1.276-0.364,1.881c-0.378,1.052-1.171,1.512-2.249,1.354c-1.132-0.167-1.613-0.93-1.602-2.038
				c0.01-0.98,0.362-1.882,0.668-2.788c1.315-3.898,1.692-7.935,2.079-12.008c0.622-6.554,2.303-12.893,4.845-18.979
				c0.678-1.622,1.354-3.248,2.108-4.838c0.415-0.874,0.677-1.798,0.634-2.781c-0.018-0.411-0.107-0.815-0.518-1.003
				c-0.432-0.198-0.774,0.056-1.059,0.348c-0.786,0.807-1.399,1.754-2.008,2.688c-4.691,7.181-8.854,14.621-11.75,22.751
				c-2.064,5.798-4.741,11.366-6.795,17.176c-0.406,1.148-1.212,1.905-2.315,1.597c-1.481-0.414-2.812-0.124-4.228,0.125
				c-0.924,0.163-2.09,0.213-2.418-0.975c-0.315-1.14,0.592-1.68,1.5-2.123c1.122-0.548,2.483-0.637,3.553-1.937
				c-1.703-0.173-2.741,0.533-3.625,1.583c-0.551,0.654-1.247,1.189-1.673,1.944c-0.446,0.79-1.048,1.287-1.947,0.828
				c-0.985-0.504-0.961-1.372-0.574-2.274c0.664-1.544,1.354-3.08,2.251-4.502c1.451-2.3,2.653-4.747,4.227-6.974
				c1.029-1.456,1.279-3.07,0.792-4.802c-0.259-0.922-0.589-1.83-0.63-2.664c-2.486,6.3-4.402,12.937-6.886,19.374
				c-0.115,0.298-0.212,0.604-0.351,0.893c-0.517,1.071-1.312,1.678-2.502,1.163c-1.089-0.472-1.178-1.391-0.802-2.425
				c2.075-5.702,3.565-11.609,5.981-17.192c0.513-1.185,1.04-2.358,1.423-3.591c0.09-0.309-0.328-0.027-0.008-0.032
				c0.065-0.001,0.077,0.078,0.014,0.229c-1.413,3.435-2.754,6.904-4.823,10.025c-0.814,1.229-1.14,2.619-1.363,4.059
				c-0.379,2.449-0.806,4.894-1.677,7.229c-0.382,1.026-0.918,1.98-2.245,1.637c-1.448-0.375-1.494-1.53-1.196-2.695
				c0.973-3.804,1.45-7.675,1.947-11.566c0.723-5.659-0.201-11.16-0.994-16.676c-0.796-5.536-1.82-11.049-2.984-16.518
				c-0.878-4.127,0.221-7.928,1.09-11.812c0.497-2.221,1.146-4.413,1.329-6.969c-1.126,0.83-1.226,1.844-1.613,2.687
				c-0.467,1.017-0.607,2.198-1.194,3.133c-3.856,6.15-3.508,12.865-2.283,19.506c1.28,6.932,0.262,13.477-1.833,20.039
				c-1.384,4.332-2.862,8.671-2.685,13.338c0.067,1.763,0.462,3.481,0.552,5.245c0.065,1.296-0.232,2.233-1.514,2.622
				c-1.304,0.395-2.121-0.325-2.743-1.377c-0.49-0.827-0.816-1.745-1.346-2.544c-0.922-1.391-1.652-1.345-2.381,0.144
				c-0.386,0.788-0.588,1.659-0.972,2.452c-0.447,0.926-1.212,1.29-2.176,0.976c-0.996-0.324-1.306-1.121-1.098-2.105
				c0.182-0.86,0.477-1.693,0.747-2.53c0.294-0.914,0.664-1.81,0.846-2.751c0.192-0.995,0.619-2.349-0.728-2.646
				c-1.315-0.29-1.497,1.149-1.852,2.085c-0.228,0.6-0.253,1.252-0.275,1.893c-0.027,0.8-0.014,1.601-0.026,2.4
				c-0.062,3.817-1.429,4.822-5.108,3.7c-1.579-0.481-1.531-1.653-1.067-2.903c1.002-2.704,2.41-5.224,3.613-7.836
				c0.403-0.875,0.847-1.729,0.974-2.694c0.134-1.021-0.053-2.008-1.063-2.455c-1.166-0.518-1.807,0.383-2.262,1.233
				c-2.075,3.875-4.25,7.702-5.613,11.919c-0.441,1.366-0.629,3.447-2.791,2.747c-2.155-0.698-1.015-2.546-0.683-3.9
				c0.457-1.862,1.273-3.624,2.062-5.369c1.236-2.738,2.052-5.534,1.929-8.574c-0.056-1.39,0.543-2.647,1.026-3.919
				c0.399-1.05,0.673-2.11-0.146-3.374c-1.39,1.904-1.651,3.995-1.79,5.993c-0.277,4.012-1.529,7.661-3.456,11.127
				c-1.281,2.304-2.458,4.665-3.866,6.902c-0.903,1.435-2.116,1.979-3.662,1.416c-1.516-0.553-1.421-1.873-1.313-3.227
				c0.433-5.424,1.041-10.882,0.945-16.282c-0.07-3.9,0.348-7.935-0.685-12.399c-4.106,5.76-5.225,11.647-4.231,18.032
				c0.894-1.663,1.196-3.601,2.176-5.249c0.481-0.811,0.908-1.952,2.089-1.563c1.323,0.435,0.744,1.62,0.632,2.571
				c-0.383,3.246-1.771,6.216-2.586,9.344c-0.431,1.654-0.673,3.262-0.402,4.968c0.219,1.378,0.764,3.156-1.312,3.493
				c-2.048,0.332-2.139-1.569-2.446-2.901c-1.898-8.223-3.164-16.487-1.244-24.906c0.088-0.388,0.099-0.792,0.116-1.147
				c-3.755,6.712-7.218,13.579-8.415,21.345c-0.292,1.896-0.448,3.808-0.803,5.696c-0.232,1.235-0.651,2.233-2.104,2.054
				c-1.484-0.184-1.641-1.368-1.528-2.544c0.375-3.906,0.895-7.809,2.122-11.536c0.837-2.542,0.831-4.982,0.333-7.547
				c-0.782-4.035-1.873-8.022-1.996-11.709c0.914,5.259-0.24,10.817-1.298,16.372c-0.912,4.79-2.06,9.538-2.263,14.438
				c-0.056,1.359-0.32,2.732-2.118,2.505c-1.625-0.206-1.556-1.56-1.486-2.799c0.248-4.392,0.64-8.766,1.787-13.03
				c2.228-8.281,2.448-16.339-2.718-23.821c0.528,0.959,1.106,5.729,1.05,7.064c-0.37,8.797-1.629,17.492-2.974,26.178
				c-0.21,1.359-0.234,2.702-0.013,4.058c0.175,1.07-0.104,1.969-1.259,2.24c-1.122,0.263-1.818-0.348-2.193-1.38
				c-1.505-4.146-2.598-8.401-3.389-12.735c-1.103-6.045-2.216-12.086-3.452-17.308c1.018,5.824,1.728,12.469,2.425,19.12
				c0.333,3.181,0.34,6.383,0.756,9.557c0.148,1.127,0.222,2.389-1.037,2.929c-1.282,0.551-2.694,0.508-3.904-0.263
				c-0.93-0.592-1.094-1.564-0.588-2.544c1.811-3.513,0.963-7.193,0.729-10.837c-0.324-5.025-1.26-9.981-1.771-14.883
				c-2.714,6.904-5.303,14.002-8.229,20.966c-0.75,1.783-1.196,3.641-1.716,5.486c-0.215,0.763-0.425,1.587-1.375,1.797
				c-1.085,0.24-1.6-0.465-2.124-1.457c-0.484,1.029-1.069,1.874-2.272,1.708c-1.217-0.167-1.232-1.228-1.592-2.102
				c-1.336-3.24-2.941-6.367-4.563-9.462c-2.96-5.65-5.563-11.438-7.181-16.083c1.543,5.095,4.614,11.114,7.638,17.14
				c1.212,2.414,2.251,4.923,3.653,7.247c0.584,0.969,1.075,2.127-0.234,2.806c-1.183,0.613-1.937-0.305-2.436-1.294
				c-6.379-12.651-12.749-25.299-14.73-39.598c-0.316-2.28-0.054-4.595-0.595-6.854c-1.792,8.723-2.944,17.493-1.672,26.379
				c0.893,6.243,3.888,11.629,7.439,16.73c0.365,0.525,0.687,1.081,1.067,1.596c0.711,0.961,1.326,2.028,0.102,2.923
				c-1.262,0.922-2.081-0.192-2.781-1.031c-1.025-1.229-1.786-2.652-2.696-3.966c-0.976-1.408-1.673-1.309-2.285,0.289
				c-0.458,1.193-0.636,2.48-1.07,3.685c-0.319,0.884-0.877,1.576-1.944,1.429c-1.212-0.168-1.563-1.083-1.461-2.115
				c0.545-5.504-0.719-11.007,0.008-16.516c-1.563,5.215-3.388,10.373-2.698,15.977c0.114,0.923,0.14,2.156-1.01,2.436
				c-1.102,0.268-2.494,0.365-3.417-0.199c-1.047-0.642-0.51-2.076-0.444-3.139c0.352-5.635,2.243-10.896,4.12-16.146
				c1.158-3.239,3.26-6.182,2.948-9.536c-1.618,4.409-3.866,8.896-5.699,13.558c-0.293,0.746-0.661,1.763-1.898,1.246
				c0-1.28,0-2.56,0-3.84c0.55-2.405,0.99-4.854,2.645-6.665c-1.029,0.5-0.656,2.692-2.645,2.345c0-0.8,0-1.6,0-2.399
				c1.918-3.36,0.885-6.721,0-10.08c0-1.12,0-2.24,0-3.36c0.899-0.478,1.287,0.16,1.689,0.772c0.782,1.19,1.479,2.431,2.254,3.637
				c-1.392-2.553-3.122-4.938-3.943-7.77c0-0.64,0-1.279,0-1.92c0.331-0.213,0.652-0.449,1.056-0.501
				c1.101-0.142,2.492,0.335,2.829-1.385c0.325-1.659-0.551-4.355-1.828-5.001c-0.861-0.437-1.395-1.144-2.057-1.753
				c0-0.32,0-0.64,0-0.96c0.342-0.323,0.638-0.739,1.16-0.765c0.993-0.048,2.003-0.152,2.202-1.354
				c0.221-1.328-0.161-2.571-1.251-3.398c-0.826-0.627-1.677-1.176-2.11-2.163c0-0.16,0-0.32,0-0.48
				c0.526-0.695,1.337-1.012,2.176-1.605c-0.83,0.251-1.483,0.796-2.176,0.166c0-0.48,0-0.96,0-1.44
				c0.535-0.772,0.638-1.752,1.103-2.525c0.867-1.443,0.06-1.904-1.103-2.274c0-7.36,0-14.721,0-22.08
				c0.393-0.237,0.895-0.213,1.058,0.136c1.275,2.71,3.91,3.801,6.193,5.311c0.748,0.494,1.504,0.974,1.033,2.069
				c-0.612,1.275-1.523,1.531-2.72,0.788c-0.543-0.338-1.082-0.688-1.664-0.953c-0.545-0.249-1.184-0.366-1.447,0.297
				c-0.41,1.034,0.543,0.668,0.969,0.786c0.8,0.22,1.587,0.416,1.491,1.507c-0.377,2.2,0.647,4.111,1.367,6.047
				c0.812,2.184,2.148,4.125,3.334,6.13c-0.444-0.61-0.724-1.298-0.55-2.042c0.711-3.06-0.954-5.144-2.82-7.196
				c-0.693-0.763-1.99-1.245-1.54-2.687c0.374-0.49,0.796-1.378,1.377-1.06c1.863,1.021,1.502-0.82,2.075-1.447
				c0.354-0.47,0.792-0.705,1.408-0.604c3.379,0.554,3.379,0.549,4.962-2.262c0.116-0.113,0.255-0.191,0.386-0.279
				c0.77-0.519,2.221,0.356,2.483-0.908c0.276-1.328-1.107-1.863-1.953-2.463c-3.867-2.738-8.107-4.792-12.528-6.473
				c-1.123-0.428-2.137-0.967-2.916-1.896c0-0.32,0-0.64,0-0.96c1.924-1.882,3.718-0.401,5.545,0.211
				c1.063,1.556,3.034,1.259,4.445,2.188c2.777,1.829,5.772,2.8,9.246,2.243c2.279-0.365,4.588,0.312,6.821,0.932
				c1.007,0.278,2.002,0.587,3.041,0.196c0.925-0.348,1.823-0.805,1.823-1.95c0-1.051-0.84-1.489-1.694-1.769
				c-2.359-0.773-4.805-1.313-7.268-1.288c-4.519,0.045-9.068-0.742-13.562,0.447c-0.927,0.245-1.981,0.235-2.653-0.76
				c-0.492-1.164,0.293-1.6,1.144-1.957c1.259-0.53,2.581-1.005,3.929-1.024c6.063-0.089,12.162-0.607,18.13,0.963
				c1.39,0.365,2.259,0.429,3.035-1.181c0.842-1.745,3.91-1.305,5.388,0.619c0.533,0.692,0.814,1.57,1.455,2.189
				c0.544,0.525,1.323,0.997,1.883,0.329c0.702-0.835-0.251-1.265-0.824-1.584c-4.408-2.457-9.159-3.935-14.11-4.793
				c-4.883-0.849-9.708-0.715-14.43,0.96c-0.883,0.313-1.732,0.372-2.366-0.517c-0.484-1.384,0.472-1.894,1.47-2.279
				c2.168-0.836,4.39-1.347,6.738-1.558c8.502-0.764,16.285,1.633,23.865,5.094c1.322,0.604,2.385,1.607,3.669,2.263
				c0.786,0.4,1.546,0.859,2.473,0.806c0.574-0.034,1.144-0.147,1.399-0.742c0.274-0.641-0.143-1.076-0.585-1.432
				c-1.821-1.465-3.883-2.512-6.013-3.451c-4.748-2.096-9.808-2.721-14.886-3.435c-5.651-0.794-11.244-1.305-16.897-0.075
				c-2.598,0.565-5.069,1.438-7.516,2.431c-0.862,0.35-1.725,0.976-2.695,0.243c0-0.48,0-0.96,0-1.44
				c1.436-2.481,4.076-2.9,6.462-3.501c6.576-1.659,13.239-1.858,19.976-0.908c4.58,0.646,9.193,0.872,13.642,2.421
				c4.449,1.548,8.42,3.823,12.115,6.678c0.845,0.653,2.036,1.982,2.808,0.94c0.814-1.1-0.891-1.797-1.721-2.374
				c-9.524-6.625-19.852-10.91-31.694-10.713c-2.558,0.043-5.119,0.078-7.674-0.043c-1.049-0.05-2.572,0.173-2.517-1.452
				c0.047-1.395,1.458-1.289,2.45-1.306c8.628-0.142,17.296-0.612,25.576,2.645c0.218,0.085,0.458,0.13,0.906,0.048
				c-0.42-0.562-0.932-0.879-1.47-1.099c-7.792-3.176-15.629-6.146-24.254-5.998c-3.721,0.063-7.286,0.83-10.813,1.933
				c-1.254,0.392-2.604,1.053-3.791-0.23c0-0.32,0-0.64,0-0.96c0.844-1.146,1.993-1.759,3.364-2.111
				c9.885-2.54,19.619-2.143,29.195,1.438c3.406,1.273,6.896,2.371,9.574,4.34c-0.425-0.162-1.112-0.632-1.753-1.134
				c-6.484-5.075-13.957-7.651-22.006-8.804c-0.631-0.091-1.26-0.217-1.89-0.326c-0.98-0.171-1.71-0.643-1.501-1.749
				c0.179-0.948,0.928-1.2,1.834-1.104c6.8,0.724,13.427,2.055,19.474,5.465c2.375,1.34,4.538,2.983,6.735,4.585
				c0.621,0.452,1.443,1.61,2.188,0.684c0.618-0.767-0.457-1.547-1.016-2.183c-5.035-5.731-11.077-10.088-18.031-13.216
				c-6.78-3.049-13.716-4.213-20.988-1.972c-0.844,0.26-1.664,0.57-2.47,0.921c-0.943,0.41-1.85,0.479-2.709-0.194
				c0-0.319,0-0.64,0-0.96c0.626-1.394,1.732-2.111,3.171-2.598c8.312-2.809,16.309-1.884,24.178,1.714
				c6.981,3.192,13.23,7.36,18.438,13.047c0.627,0.685,1.37,2.113,2.427,1.266c0.877-0.703-0.016-1.94-0.47-2.837
				c-2.468-4.865-6.123-8.718-10.377-12.055c-0.743-0.582-1.63-1.1-1.45-2.283c0.114-0.492,0.409-0.948,0.838-1.102
				c4.525-1.609,6.541-6.062,10.041-8.828c0.417-0.328,0.9-0.865,0.476-1.43c-0.5-0.663-1.223-0.335-1.701,0.049
				c-3.403,2.734-6.925,5.361-9.221,9.205c-0.403,0.673-0.63,1.478-1.442,1.834c-0.88,0.298-1.533-0.237-2.229-0.606
				c-6.899-3.65-14.065-6.111-22.046-5.001c-0.929,0.129-1.773-0.018-2.129-1.072c-0.461-1.498,0.567-1.767,1.651-1.994
				c5.924-1.244,11.584-0.141,17.115,1.971c1.558,0.595,3.048,1.365,5.069,1.993c-0.72-1.274-1.629-1.716-2.505-2.092
				c-4.708-2.021-9.531-3.776-14.589-4.61c-4.296-0.709-8.132,1.131-11.935,2.859c-1.024,0.466-2.02,1.486-3.309,0.57
				c0-0.479,0-0.96,0-1.439c1.427-2.388,3.932-3.152,6.29-4.058c4.817-1.851,9.701-1.591,14.551-0.1
				c4.505,1.385,8.983,2.854,13.128,5.166c0.677,0.378,1.585,0.999,2.139,0.132c0.647-1.012-0.403-1.583-1.097-2.044
				c-2.929-1.945-5.96-3.717-9.13-5.251c-0.755-0.365-1.805-0.671-1.599-1.808c0.235-1.299,1.475-0.836,2.299-1.077
				c0.721-0.212,1.682,0.774,2.297-0.544c-0.695-0.533-1.152,0.105-1.66,0.295c-2.158,0.808-3.289,0.307-4.074-1.812
				c-1.175-3.17-1.373-3.273-4.423-2.072c-0.778,0.306-1.638,0.539-2.327,0.162c-4.194-2.29-8.231-0.715-12.301,0.366
				c-1.35,0.359-2.683,1.25-4.092,0.164c0-0.319,0-0.64,0-0.96c0.794-1.602,1.993-2.424,3.841-1.996
				c0.316,0.072,0.71,0.164,0.941-0.003c2.166-1.565,4.767-1.644,7.23-2.198c0.487-0.109,0.897-0.434,0.854-1.004
				c-0.041-0.544-0.441-0.902-0.943-0.935c-1.719-0.111-3.352,0.03-4.47,1.645c-2.369,3.421-3.945,3.479-6.529,0.237
				c-0.292-0.366-0.642-0.662-0.925-1.025c0-3.36,0-6.721,0-10.08c1.08-0.63,0.801-1.952,1.429-2.812
				c0.729-0.768,1.901-0.973,2.464-1.953c0.87-0.663,2.183-0.5,2.848-1.538c1.275-0.748-0.085-2.337,0.997-3.15
				c1.824-0.438,3.429-1.517,5.298-1.827c0.725,2.097,2.692,2.934,4.253,4.185c0.307,1.482-1.102,1.279-2.08,2.066
				c2.627-0.328,4.952-0.384,7.256-0.568c4.346-0.349,8.378,0.707,12.337,2.291c0.297,0.119,0.573,0.285,0.841,0.459
				c0.852,0.551,1.217,1.31,0.708,2.241c-0.479,0.875-1.33,0.859-2.097,0.479c-2.237-1.11-4.664-1.601-6.603-2.255
				c0.651,0.026,1.562,0.54,2.458,1.074c0.673,0.4,1.127,0.973,0.802,1.782c-0.306,0.763-0.984,1.058-1.768,0.879
				c-1.095-0.25-2.162-0.438-2.404,1.054c-0.214,1.317-0.064,2.483,1.385,3.221c1.981,1.007,1.99,1.341,0.449,3.384
				c9.389-4.578,18.764-3.119,28.131-0.937c1.159,0.27,2.881,0.474,2.309,2.528c-0.665,1.312-1.709,0.836-2.701,0.566
				c-5.777-1.571-11.597-2.794-17.099-2.23c5.017-0.297,10.474,0.096,15.719,2.198c1.556,0.624,3.226,1.386,4.296-0.809
				c1.816-0.879,3.455-0.339,4.793,1.569c-1.222,1.873,1.154,2.417,1.361,3.647c0.013-1.207-2.287-1.423-1.516-3.115
				c1.042-1.511,0.837-2.686-0.701-3.828c-1.603-1.19-1.557-1.79,0.212-2.962c2.008-1.332,0.653-3.109,0.54-4.534
				c-0.089-1.121-1.678-0.45-2.626-0.415c-0.891,0.033-1.702,0.811-2.654,0.286c-0.191-0.139-0.333-0.316-0.431-0.53
				c-0.036-2.4,2.361-1.937,3.496-2.962c-2.297-1.114-9.662,1.453-11.984,4.18c0.233,0.452,0.668,0.323,1.046,0.363
				c0.917,0.097,1.507,0.564,1.511,1.522c0.003,0.896-0.458,1.571-1.352,1.733c-1.899,0.345-3.937,0.623-5.685-0.087
				c-1.963-0.797-1.882-2.824-1.637-4.672c4.974-2.228,9.463-5.562,14.989-6.533c0.757-0.134,1.601-0.139,2.158-0.862
				c-0.428-0.811-1.278-0.534-1.903-0.793c-1.014-0.42-1.496-1.23-0.723-2.032c0.929-0.964,0.611-1.378-0.311-1.907
				c-1.122-0.644-1.373-2.129-2.639-2.703c-0.653-0.297-1.333-0.655-1.588,0.146c-0.675,2.116-2.558,2.79-4.201,3.657
				c-2.317,1.223-4.078,3.039-5.908,4.82c-0.567,0.553-0.331,0.957-0.248,1.618c0.194,1.558,2.117,3.132,0.152,4.75
				c-0.821,0.362-1.354-0.187-1.938-0.595c-5.87-4.105-12.386-6.111-19.55-6.126c-1.463-0.003-3.057,0.442-4.35-0.71
				c-0.801-0.981-0.406-1.606,0.637-1.966c0.979-0.337,1.979-0.658,3.039-0.556c4.981,0.479,10.035,0.511,14.708,2.735
				c1.191,0.566,2.821,1.519,2.637-1.3c-0.066-1.017,0.973-1.322,1.95-1.283c1.044,0.041,1.928-0.313,2.331-1.322
				c0.443-1.112-0.396-1.773-1.065-2.45c-0.563-0.57-1.221-1.025-1.896-1.453c-1.159-0.736-2.204-0.683-2.667,0.74
				c-0.388,1.194-1.292,1.769-2.217,2.382c-1.385,0.918-2.584,0.831-3.837-0.407c-2.244-2.22-2.614-2.142-4.502,0.315
				c-0.988,1.285-4.374,1.928-5.777,1.08c-0.673-0.407-1.028-1.03-0.875-1.814c0.129-0.659,0.153-1.26-0.288-1.809
				c-0.458-0.807,0.323-1.189,0.545-1.65c-0.387,0.584-1.115,1.211-0.336,2.112c-1.104,3.175-2.359,3.657-6.343,2.45
				c-0.454-0.418-0.556-1.507-1.612-0.381c-1.03,1.098-2.488,1.715-4.126,1.109c-0.344-0.357-0.425-0.792-0.341-1.268
				c0.026-0.142,0.024-0.278,0.051-0.395c0.017-0.073,0.054,0.033-0.121,0.175c-0.246,0.2,0.138-0.026-0.043,0.237
				c-0.133,0.194-0.288,0.365-0.466,0.524c-2.806,0.825-3.868,0.41-5.099-2.072c-0.549-1.108-1.172-2.189-1.495-3.396
				c0.164-0.963,1.007-2.012-0.339-2.794c0-0.32,0-0.64,0-0.96c0.277-0.285,0.81-0.577,0.766-0.881
				c-0.538-3.676,0.654-7.397-0.315-11.051c-0.059-0.222-0.283-0.379-0.451-0.549c0-2.56,0-5.12,0-7.68
				c0.395-0.391,0.778-0.889,1.377-0.852c5.175,0.321,10.196-1.195,15.337-1.267c0.715-0.009,1.429-0.019,2.144,0.005
				c0.766,0.025,1.488,0.2,1.587,1.09c0.109,0.984-0.556,1.451-1.426,1.562c-1.888,0.243-3.755,0.657-5.671,0.678
				c-0.718,0.007-1.42,0.147-2.096,0.385c-1.757,0.867-3.738,0.191-5.593,0.88c-1.459,0.542-2.235,1.191-2.007,2.714
				c0.499,3.318,0.53,6.679,0.978,10.004c0.469,3.481,2.915,5.146,5.745,6.494c0.457,0.218,0.961,0.215,1.292-0.25
				c0.256-0.362,0.167-0.784-0.062-1.128c-0.485-0.729-1.136-1.362-1.391-2.225c-0.259-0.881-0.343-1.795,0.594-2.342
				c0.903-0.526,1.481,0.077,2.003,0.744c0.397,0.508,0.868,0.957,1.441,1.248c0.975,0.492,1.999,1.372,3.092,0.543
				c0.988-0.749,0.927-1.958,0.719-3.098c-0.386-2.108,0.325-3.135,2.378-3.43c1.502-0.216,2.61,1.072,2.637,3.065
				c-0.393,1.669,0.83,3.026,0.859,4.689c0.029,1.632,0.146,3.244-0.134,4.759c0.094-1.049,0.217-2.218,0.572-3.304
				c0.644-1.971,0.875-3.727-1.256-4.982c-0.347-0.204-0.318-0.698-0.09-1.084c0.376-0.67,0.91-1.053,1.716-1.078
				c0.544-0.018,1.297-0.073,1.269-0.77c-0.036-0.853-0.895-0.715-1.493-0.814c-0.234-0.038-0.477-0.016-0.713-0.041
				c-4.03-0.426-5.262-2.11-4.595-6.277c0.213-0.288,0.463-0.531,0.785-0.696c1.154-0.3,1.913,0.185,2.151,1.29
				c0.368,1.701,1.279,2.676,3.069,2.815c1.981,0.154,3.606-0.295,4.696-2.146c0.979-1.663,2.001-3.289,3.43-4.666
				c0.917-0.884,1.783-1.027,2.786-0.564c1.1,0.508,0.932,1.558,0.79,2.519c-0.07,0.473-0.232,0.93-0.368,1.39
				c-0.412,1.398-0.088,2.36,1.5,2.624c0.82,0.137,1.573,0.391,1.71,1.387c-0.296,1.446-1.343,1.378-2.497,1.359
				c-1.021-0.016-2.135-0.028-2.835,1.011c-1.282,1.9-0.738,3.53,1.438,4.091c0.423,0.109,0.822,0.248,1.469,0.492
				c-1.702-0.797-4.208-0.517-3.396-3.372c0.401-1.409,1.125-2.336,2.773-1.489c1.219,1.956,3.672,2.725,6.209,1.676
				c1.899-0.785,3.226-0.859,4.321,1.272c0.786,1.529,3.349,1.13,4.866-0.688c0.873-1.045,1.627-1.833,2.842-0.499
				c1.589,2.544,1.318,3.354-1.458,4.335c-1.435,0.508-2.944,0.743-4.391,1.209c-1.111,0.358-1.794,1.041-1.692,2.283
				c0.061,0.734,0.227,1.473,0.964,1.815c0.842,0.391,1.341-0.223,1.801-0.787c1.06-1.298,1.931-2.751,3.206-3.871
				c1.672-1.469,2.854-1.374,4.245,0.347c0.1,0.123,0.17,0.274,0.28,0.388c0.679,0.703,0.914,2.175,2.22,1.827
				c1.284-0.342,1.295-1.731,1.434-2.804c0.216-1.668,0.371-3.347-1.256-4.589c-0.718-0.549-0.949-1.698,0.082-1.887
				c1.846-0.337,3.81-1.092,5.662,0.097c1.062,0.682,1.716,1.627,1.891,2.913c0.089,0.657,0.398,1.265,1.18,1.307
				c0.732,0.039,1.19-0.443,1.466-1.051c0.61-1.346,0.901-2.757,0.384-4.191c-0.28-0.776-0.581-1.496-0.046-2.263
				c0.48-0.57,0.692-1.192,0.323-1.971c0.233,0.684,0.281,1.292-0.215,1.824c-0.801,1.263-1.599,1.019-2.41,0.005
				c-0.576-0.72-0.729-1.761-1.826-2.314c-0.571,1.849-0.612,3.997-2.659,4.947c-0.702,0.326-1.389,0.176-1.966-0.374
				c-0.665-0.902-0.089-1.607,0.311-2.402c1.067-2.117,0.301-3.599-2.097-3.867c-1.819-0.204-3.651-0.253-5.466-0.576
				c-1.372-0.245-2.516-0.719-2.816-2.168c-0.289-1.39,0.668-2.19,1.702-2.891c0.528-0.357,1.043-0.736,1.521-1.163
				c1.07-0.957,1.49-2.023,0.743-3.398c-0.344-0.633-0.56-1.341-0.07-2.04c1.172-0.898,2.426-0.696,3.693-0.276
				c1.491,2.561,0.779,4.852-0.866,7.035c-0.365,0.484-1.166,0.875-0.805,1.584c0.328,0.643,1.14,0.392,1.756,0.471
				c2.534,0.324,5.069,0.566,7.631,0.473c2.742-0.102,3.172-0.674,2.258-3.174c-0.083-0.225-0.198-0.438-0.275-0.664
				c-0.43-1.261-0.73-2.556,0.62-3.435c1.357-0.883,2.196,0.207,3.085,0.999c2.341,2.084,3.639,5.033,5.964,7.134
				c0.524,1.807,0.034,3.812,1.105,5.536c0.402,0.646,0.354,1.772,1.364,1.74c1.127-0.036,1.123-1.175,1.379-1.98
				c0.315-0.989,0.556-2.002,0.809-3.008c0.32-1.275,0.841-2.335,2.355-2.201c1.679,0.147,1.941,1.447,1.952,2.803
				c0.02,2.549,0.124,5.091,0.502,7.729c1.373-3.326,3.983-5.817,5.734-8.875c0.826-1.442,0.234-2.384-1.564-2.568
				c-1.817-0.186-3.687,0.058-5.414-0.771c-1.035-0.496-2.101-0.954-1.736-2.442c0.679-0.802,1.21-1.55,0.39-2.657
				c-0.518-0.699-0.29-1.502,0.455-2.065c1.525-0.637,2.279,0.336,2.773,1.485c0.935,2.172,2.484,3.228,4.859,3.11
				c0.727-0.036,1.42,0.232,2.053,0.588c1.332,0.748,2.553,0.694,3.761-0.315c0.899-0.752,1.935-1.219,3.054-0.41
				c1.1,0.795,0.932,1.94,0.706,3.058c-0.89,4.387,0.207,5.656,4.674,5.035c3.57-0.498,7.126-0.317,10.694-0.215
				c0.062,0.006,0.094,0.008,0.094,0.009c0.006,0.048-0.068,0.051-0.222,0.049c-2.619-0.021-4.937-0.971-7.099-2.352
				c-1.033-0.66-2.098-1.476-1.654-2.871c0.443-1.395,1.434-2.295,3.098-1.919c1.123,3.38,4.036,3.76,6.958,4.218
				c1.667,0.262,3.118,1.041,4.39,2.211c1.203,1.105,2.425,2.255,4.485,2.759c-0.712-1.18-1.974-2.166-0.246-3.06
				c2.095-0.855,3.661,0.482,5.232,1.411c1.7,1.003,7.375,1.662,8.959,0.645c1.09-0.701,2.304-1.397,3.195-0.311
				c0.96,1.172,2.29,2.491,1.43,4.372c-0.869,0.932-0.133,2.017-0.226,2.771c0.159-0.699-0.571-1.692,0.078-2.618
				c0.691-1.325,1.704-1.683,3.25-1.781c2.481-0.157,4.901-0.254,7.38,0.097c2.638,0.373,5.914-2.652,5.867-5.171
				c-0.017-0.948-1.678-1.57-2.712-0.829c-4.958,3.552-10.478,3.15-16.127,2.693c-3.372-0.273-6.496-1.3-9.65-2.248
				c-2.604-0.782-4.857-0.643-6.934,1.161c-0.864,0.438-1.398,0.01-1.682-0.745c-0.302-0.806-0.094-1.557,0.496-2.209
				c1.928-2.134,4.292-2.547,6.977-1.849c4.152,1.081,8.256,2.317,12.286,3.771c-1.469-0.607-3.718-0.478-2.894-3.301
				c-2.535,0.306-5.054,0.924-7.659,0.015c-2.572-0.897-3.166-2.047-1.865-4.573c0.79-0.67,1.597-0.45,2.08,0.263
				c1.289,1.902,3.144,1.721,4.961,1.414c1.994-0.338,3.981-0.37,5.973-0.233c2.043,0.14,2.566,0.759,2.034,2.753
				c-0.268,1.001,0.128,1.121,0.797,1.242c1.339,0.243,2.63,0.679,3.955,0.963c1.324,0.284,2.411,0.084,3.203-1.354
				c0.951-1.726,2.999-1.991,4.636-2.752c1.695-0.787,2.996-1.787,2.978-3.854c3.694-1.687,6.957-4.439,11.365-4.367
				c1.328,0.021,2.682-0.383,3.969-0.827c1.842-0.638,3.21-1.687,3.554-3.786c0.336-2.062,1.717-2.884,3.733-2.959
				c2.41-0.091,4.78,0.1,7.123,0.653c1.554,0.368,2.997,0.295,4.259-0.851c1.327-1.203,3.39-0.696,4.917-1.994
				c-1.323-1.06-2.761-1.09-4.142-1.102c-0.963-0.008-1.732-0.146-2.216-1.045c-0.433-2.196,1.113-3.383,2.524-4.436
				c1.628-1.214,3.776-1.627,5.213-3.229c0.322-0.358,0.901-0.159,1.291,0.197c1.704,1.224,0.682,2.317-0.19,3.387
				c-0.355,0.436-0.904,0.646-1.378,0.938c-0.91,0.563-1.255,1.272,0.067,1.564c3.071,0.679,4.752,2.682,5.774,5.477
				c0.506,1.383,1.539,2.277,3.024,2.391c4.769,0.363,8.871,2.256,12.68,5.074c1.864,1.38,2.745,0.953,3.539-1.241
				c0.707-1.951,1.496-3.868,2.301-5.779c0.461-1.095,1.224-1.674,2.43-1.438c1.097,0.214,1.563,0.994,1.667,2.056
				c0.165,1.671-0.009,3.354,0.14,5.021c0.173,1.931,0.765,2.211,2.606,1.359c2.261,1.024,4.904,1.622,5.305,4.731
				c-0.763,1.207-1.688,0.733-2.704,0.34c-1.412-0.547-3.177-1.261-4.145,0.291c-1.045,1.677,0.608,2.719,1.777,3.647
				c3.174,2.523,4.579,2.013,5.492-1.979c0.11-0.481,0.24-0.916,0.561-1.289c2.508-1.012,3.238-0.675,4.99,0.99
				c3.469,3.296,7.803,5.186,12.221,6.84c1.032,0.387,1.291-0.348,0.987-1.219c-0.445-1.278-1.091-2.498-1.081-3.898
				c0.015-2.169,1.036-2.825,3.244-2.101c0.289,2.629,1.191,5.039,2.43,7.379c0.787,1.486,1.892,2.332,3.482,2.614
				c2.28,0.404,4.559,0.857,7.051,0.558c-1.428-0.444-2.765-0.729-4.094-1.044c-1.099-0.261-1.658-0.973-1.536-2.083
				c0.117-1.061,0.915-1.407,1.872-1.503c1.195-0.12,2.395-0.096,3.592-0.09c1.606,0.009,2.599-0.578,2.632-2.35
				c0.018-0.97,0.149-1.963,1.28-2.342c1.235-0.415,2.057,0.323,2.6,1.217c1.496,2.465,3.561,3.2,6.32,2.545
				c1.077-0.256,2.191-0.431,3.297-0.594c1.979-0.29,3.702-0.598,2.965-3.462c-0.433-1.682,1.159-2.676,2.405-3.544
				c2.358-1.643,4.969-2.914,7.203-4.729c0.772-0.628,2.407-1.135,1.717-2.46c-0.56-1.074-1.896-0.289-2.869-0.112
				c-1.256,0.228-2.471,0.684-3.741,0.822c-1.619,0.177-3.257,0.213-4.294-1.393c-0.948-1.467-0.163-2.828,0.531-4.04
				c1.389-2.425,3.107-4.637,4.837-6.835c0.568-0.721,1.382-1.345,1.454-2.57c-3.534,0.005-5.929,2.085-8.287,4.199
				c-1.125,1.009-2.033,2.253-3.397,3c-1.052,0.575-1.965,0.674-2.642-0.531c-0.123-0.396-0.065-0.777,0.158-1.26
				c-3.069,1.16-6.266,0.839-9.36,1.322c-1.392,0.217-2.66,0.042-1.577,2.126c0.553,1.065,0.079,2.313-1.117,2.776
				c-1.219,0.472-2.546,0.576-3.017-1.182c0.104-0.768,0.25-1.403-0.386-2.19c-1.927-2.382-1.036-4.422,2.105-4.69
				c2.464-0.21,4.933-0.405,7.4-0.599c5.867-0.459,11.349-1.904,15.875-5.939c0.416-0.371,0.901-0.669,1.257-1.105
				c0.357-0.439,0.727-0.915,0.34-1.504c-0.303-0.462-0.822-0.457-1.302-0.403c-1.507,0.17-2.942,0.742-4.487,0.735
				c-3.395-0.015-4.366-1.482-2.979-4.534c0.868-1.912,2.094-3.587,3.413-5.199c0.569-0.695,1.211-1.314,2.249-0.975
				c1.695,1.265,0.716,2.463-0.073,3.698c-0.617,0.965-2.129,1.934-1.476,2.972c0.644,1.021,2.218,0.184,3.376,0.057
				c0.633-0.068,1.253-0.24,1.897-0.245c1.388-0.011,2.88,0.025,3.659,1.311c0.806,1.325,0.482,2.764-0.508,4.023
				c1.929-0.154,2.593-1.637,3.465-2.985c0.781-1.207,1.543-2.953,3.467-1.288c0.478,0.42,0.62,0.929,0.335,1.505
				c-0.963,1.942,0.211,2.199,1.722,2.209c0.721,0.006,1.459,0.006,2.127,0.299c4.965,2.172,8.746-0.724,12.687-2.941
				c0.496-1.92,2.117-1.191,2.997-1.409c-1.043,0.018-2.64-0.441-3.135,1.554c-0.928,0.568-1.536,0.063-1.971-0.675
				c-0.479-0.813-0.44-1.683,0.077-2.493c0.836-1.31,2.135-1.498,3.552-1.545c6.086-0.2,6.085-0.216,10.766-3.624
				c-2.04,1.125-4.203,1.713-6.554,1.644c-1.513-0.044-2.69-0.646-3.226-2.039c-0.5-1.299-0.109-2.51,0.962-3.462
				c0.298-0.265,0.574-0.557,0.838-0.855c0.309-0.349,0.791-0.677,0.579-1.216c-0.205-0.518-0.764-0.48-1.233-0.549
				c-3.663-0.534-7.446,1.45-8.879,5.069c-0.951,2.404-2.467,3.822-4.898,4.407c-1.722,0.415-2.959,1.376-3.849,2.961
				c-0.547,0.974-1.29,2.19-2.819,1.237c-0.857-1.826,0.556-5.339,2.595-6.315c0.931-0.445,1.887-0.848,2.882-1.146
				c1.03-0.309,1.878-0.888,2.383-1.857c0.437-0.836,1.098-1.728,0.521-2.675c-0.63-1.036-1.683-0.561-2.611-0.437
				c-2.226,0.298-4.425,0.292-6.616-0.354c-2.176-0.642-4.415-0.543-6.63-0.206c-1.413,0.215-2.429,0.743-2.68,2.5
				c-0.214,1.498-1.035,2.944-3.118,2.198c-0.944-1.915-2.325-2.232-4.31-1.507c-1.552,0.566-3.503,1.043-4.639-0.587
				c-1.153-1.657-0.248-3.46,0.832-4.877c1.798-2.361,3.483-4.779,4.794-7.441c0.354-0.721,0.874-1.104,1.626-1.244
				c1.744-0.711,1.984,0.32,1.735,1.622c-0.387,2.016-1.641,3.607-2.773,5.26c-0.648,0.946-2.184,2.068-1.431,2.999
				c0.879,1.086,2.319-0.149,3.454-0.62c0.963-0.398,1.951-0.716,2.928-1.071c1.098-0.399,2.066-0.998,2.459-2.129
				c1.192-3.434,3.882-5.179,7.074-6.312c2.496-0.885,4.241-2.434,5.2-4.934c0.495-1.29,0.123-1.718-1.18-1.845
				c-3.987-0.39-7.927-0.724-11.768,0.979c-2.179,0.967-4.597,0.279-7.229,0.584c1.034,0.972,1.307,2.202,2.039,3.199
				c0.604,0.823,0.717,1.61-0.348,2.188c-1.053,0.167-1.566,0.898-2.065,1.771c-2.538,4.437-6.061,7.999-10.08,11.098
				c-1.078,0.831-2.236,1.568-3.551,1.957c-0.947,0.28-1.923,0.285-2.639-0.583c-0.639-0.776-0.514-1.616-0.154-2.48
				c0.812-1.945,2.176-3.526,3.379-5.214c0.642-0.899,1.47-1.693,1.664-2.856c-0.494-0.574-0.911-0.112-1.254,0.115
				c-2.544,1.688-5.182,3.163-8.213,3.782c-1.208,0.248-1.499,0.786-1.077,2.04c1.199,3.563,0.098,5.583-3.483,6.757
				c-3.414,1.12-3.609,1.966-1.042,4.641c1.604,1.671,3.279,3.274,4.887,4.943c0.91,0.944,1.421,2.001,0.81,3.236
				c-0.683,1.382-1.94,1.259-3.123,0.812c-1.667-0.632-2.938,0.144-4.22,0.997c-1.724-0.429-2.393-1.464-1.925-2.978
				c0.653-2.113,2.239-2.651,4.555-1.662c-2.186-1.594-4.271-3.521-5.801-5.966c-1.99-3.182-0.965-5.765,2.601-6.741
				c0.231-0.062,0.465-0.121,0.694-0.19c1.192-0.356,2.749-0.354,2.839-2.059c0.084-1.606-0.896-2.939-2.287-3.363
				c-4.611-1.404-8.959-3.392-13.288-5.456c-1.16-0.554-1.437,0.021-1.247,1.088c0.056,0.313-0.009-0.079,0.058,0.232
				c0.319,1.481,0.957,3.164-0.708,4.124c-1.712,0.988-3.105-0.399-4.256-1.373c-2.748-2.323-5.145-5.029-7.714-7.551
				c-2.183-2.142-2.209-2.144-4.858-0.525c-1.721-0.513-2.145-2.427-0.781-4.162c0.825-1.051,1.821-1.977,2.713-2.983
				c1.8-2.03,1.829-2.777,0.013-4.858c-0.679-0.777-1.376-1.526-1.889-2.435c-0.484-0.858-0.63-1.629,0.302-2.277
				c2.548-1.219,3.019,1.218,3.811,2.463c0.782,1.23,1.312,2.597,2.368,3.705c1.148,1.205,0.99,2.568-0.001,3.893
				c-1.646,2.197-1.629,2.409,0.295,4.378c2.234,2.287,4.525,4.519,6.885,6.926c-0.065-0.713-0.33-1.246-0.379-1.814
				c-0.235-2.686,1.146-3.858,3.823-3.224c1.256,0.297,2.396,0.891,3.531,1.477c2.845,1.466,5.774,2.723,8.767,3.865
				c4.972,1.898,9.578,0.958,14.036-1.51c1.816-1.006,3.523-2.204,5.431-3.049c1.074-0.476,2.146-0.729,3.145,0.127
				c1.017,0.872,0.867,1.983,0.519,3.095c-0.997,3.183-3.116,5.657-4.36,7.354c2.241-1.999,5.295-4.808,7.566-8.353
				c1.639-2.558,1.628-2.917-0.633-4.813c-1.254-1.052-1.257-2.341-0.613-3.675c0.385-0.797,1.065-1.438,1.345-2.201
				c-0.951,1.757-2.175,3.372-3.938,4.568c-4.624,3.138-9.463,5.688-15.253,5.63c-3.535-0.035-4.345-0.844-3.936-4.293
				c0.203-1.71-0.557-1.803-1.756-1.513c-2.568,0.619-5.133,1.021-7.803,1.08c-4.003,0.09-7.344-1.49-10.427-3.69
				c-2.196-1.566-4.702-2.687-6.584-4.731c-2.054-2.23-1.985-2.759,0.92-3.742c-1.632-3.395-3.968-4.134-6.57-2.078
				c-1.083,0.749-1.934,0.263-2.308-0.729c-0.512-1.356-0.631-2.813,0.371-4.084c0.285-0.36,0.814-0.439,1.319-0.832
				c-3.674-2.783-7.554-4.768-11.83-5.874c-2.561-0.663-3.357-0.124-4.378,2.27c-0.969,2.271-2.581,3.047-5.008,2.411
				c-3.138-0.821-5.858-2.486-8.539-4.23c-0.462-0.3-0.963-0.807-1.503-0.516c-0.766,0.414-0.215,1.143-0.158,1.728
				c0.109,1.106,0.704,1.931,1.641,2.51c1.86,1.15,2.827,2.782,2.629,4.998c-0.173,1.933,0.945,2.69,2.596,2.595
				c3.229-0.188,5.187,1.648,6.911,3.974c0.57,0.769,1.131,1.553,1.604,2.381c1.563,2.735,3.636,4.643,6.805,5.582
				c2.734,0.811,5.164,2.486,7.507,4.162c0.575,0.41,1.03,1.089,1.845,0.467c0.765-0.364,1.182,0.088,1.553,0.657
				c3.007,4.626,7.017,8.373,10.799,12.319c0.886,0.924,1.914,1.843,1.077,3.303c-0.818,1.426-2.117,1.101-3.389,0.834
				c-0.701-0.147-1.385-0.371-2.093-0.474c-0.693-0.102-1.502-0.366-1.937,0.429c-0.405,0.74,0.145,1.342,0.513,1.956
				c0.815,1.361,2.539,2.307,2.001,4.304c-0.554,0.729-1.443,1.118-2.062,0.522c-2.011-1.938-4.625-1.873-7.062-2.421
				c-0.961-1.221-0.442-2.082,0.75-2.647c0.751-0.356,1.372-0.597,1.425-1.641c0.179-3.494,1.261-4.427,4.757-4.305
				c0.314,0.011,0.626,0.109,0.942,0.034c0.131-0.261-0.047-0.451-0.177-0.621c-5.213-6.823-11.708-11.873-20.002-14.423
				c-1.516-0.467-2.663-1.378-3.538-2.693c-1.327-1.993-2.632-4.005-4.143-5.868c-1.079-1.33-2.089-1.478-3.324-0.068
				c-1.968,2.248-2.157,3.122-0.607,5.678c1.188,1.959,0.516,3.72-0.556,5.369c-0.716,1.103-1.003,1.921-0.221,3.226
				c0.883,1.474,0.651,3.244,0.055,4.873c-0.274,0.75-0.686,1.442-1,2.177c-1.869,4.372-1.633,4.891,1.278,6.95
				c0.643,0.455,1.145,1.11,1.545,1.814c0.589,1.035,1.43,1.651,2.667,1.772c2.844,0.281,5.097,1.721,7.041,3.731
				c0.721,0.746,1.457,1.48,2.234,2.169c1.519,1.345,3.202,2.106,5.271,1.456c2.388-0.752,4.032,0.371,5.297,2.212
				c0.848,1.234,0.885,2.404-0.828,3.021c-5.896-3.01-11.779-0.783-17.483,0.471c-3.946,0.868-7.334,0.543-10.763-1.253
				c-3.942-1.127-6.588-3.784-8.837-7.098c-1.242-1.829-3.315-2.595-5.575-2.743c-1.505-0.1-2.938-0.412-2.42-2.521
				c1.706-0.897,2.151-2.239,1.433-4.011c-1.086-2.677-1.082-5.406-0.691-8.245c0.372-2.703-0.118-5.439-0.822-8.051
				c-0.534-1.983-2.222-3.426-4.171-3.472c-2.438-0.057-5.456-0.373-6.636,2.649c-1.365,3.493-3.813,4.75-7.326,4.228
				c-3.414-0.508-6.351,0.432-9.328,2.132c-2.418,1.382-3.224,3.896-5.024,5.657c-0.298,0.292-0.271,0.833,0.038,1.105
				c1.054,0.93,1.198,2.424,2.213,3.383c1.3,1.23,1.251,2.874,0.518,4.205c-0.866,1.573-1.602,3.645-4.106,3.361
				c-0.737-0.49-1.184-1.376-0.71-1.985c1.292-1.665,0.113-2.232-1.036-2.908c-0.259-1.322-0.151-2.66-2.245-2.876
				c-1.32-0.136-1.627-1.635-0.819-2.891c0.519-0.807,1.194-1.521,1.933-2.125c2.338-1.907,3.658-4.59,5.407-6.946
				c1.864-0.881,3.702-1.821,5.562-2.707c1.886-0.898,3.87-1.312,6.051-0.857c2.617,0.548,4.862,0.051,6.145-2.788
				c0.939-2.08,2.781-3.286,5.059-3.585c1.821-0.239,2.474-1.212,2.32-2.943c-0.063-0.715-0.14-1.467,0.027-2.154
				c1.341-5.553-0.421-5.255-5.022-8.358c-1.104-0.745-1.884-0.331-2.505,0.819c-1.903,3.528-2.931,3.854-6.368,1.867
				c-2.356-1.361-4.483-3.064-6.474-4.931c-0.521-0.488-1.082-1.064-1.886-0.794c-0.84,0.281-0.787,1.097-0.896,1.805
				c-0.755,4.892-3.352,8.698-7.139,11.715c-0.732,0.583-0.88,1.257-1.136,1.987c-0.494,0.849-1.657,0.884-2.124,1.768
				c-1.3,0.467-2.406,0.246-2.968-1.113c-0.543-1.316,0.358-2.079,1.292-2.771c0.256-0.19,0.504-0.393,0.746-0.602
				c1.671-1.438,1.672-1.657-0.413-2.344c-1.622-0.535-1.938-1.402-1.357-2.646c-0.451,0.335-0.555,0.889-0.336,1.769
				c0.544,2.192-0.198,3.08-2.467,3.269c-0.717,0.059-1.437,0.034-2.154-0.027c-0.601-0.052-0.979,0.076-1.307,0.72
				c-1.14,2.242-2.031,2.532-4.278,1.598c-0.964-0.401-1.759-1.098-2.634-1.513c0.821,0.572,1.963,1.012,1.615,2.455
				c-0.552,1.049-1.388,1.245-2.388,0.706c-0.811-0.438-1.765-0.524-2.525-1.07c1.208,0.915,2.253,2.001,3.26,3.127
				c0.839,0.938,0.998,2.008,0.375,3.074c-0.631,1.081-1.731,1.012-2.771,0.794c-2.181-0.458-3.981-2.021-6.275-2.172
				c-2.291-1.924-3.556-4.25-2.4-7.267c0.714-1.862,0.257-3.038-1.318-4.269c-2.39-1.864-4.46-4.074-4.921-7.32
				c-0.222-1.564-1.037-1.743-2.349-0.951c-0.996,0.601-1.948,1.905-3.344,0.658c-0.848-1.059-0.077-1.786,0.571-2.465
				c0.993-1.038,1.428-2.046,0.431-3.359c-0.679-0.895-0.544-1.963-0.233-2.98c0.187-0.613,0.524-1.284,0.271-1.856
				c-1.046-2.359-2.481-4.574-2.286-7.333c0.026-0.366-0.464-0.658-0.886-0.671c-2.452-0.077-4.935-0.428-6.783,1.903
				c-0.962,1.215-2.089,2.545-4.021,1.704c-0.557-0.423-1.231-0.713-1.572-1.382c-0.113-0.406-0.122-0.956,0.211-1.103
				c2.681-1.177,4.136-4.485,7.655-4.276c0.938,0.055,2.057-0.058,3.162-0.747c-1.309-1.405-2.911-1.331-4.436-1.447
				c-3.053-0.232-3.436-0.743-2.607-3.699c0.474-1.692-0.073-2.806-1.738-3.359c-0.606-0.2-1.227-0.354-1.829-0.568
				c-2.843-1.01-3.373-1.942-2.613-4.833c0.376-1.429,0.269-2.688-0.878-3.727c-1.039-0.94-1.534-1.934-0.822-3.376
				c0.586-1.188-0.064-2.162-1.035-2.894c-0.997-0.751-2.152-1.013-3.383-0.996c-3.909,0.057-5.24-1.431-4.963-5.542
				c0.109-0.219,0.267-0.394,0.459-0.541c1.059-0.569,1.809-0.009,2.547,0.677c2.505,2.327,2.509,2.322,5.916,2.76
				c-0.486-3.342-1.424-6.521-2.21-9.726c-2.894-11.81-6.472-23.391-11.307-34.576c-6.7-15.503-15.384-29.77-26.156-42.773
				c-2.65-3.199-5.286-6.408-8.318-9.264c-0.938-0.884-1.886-1.811-3.16-2.097c-2.038-0.458-3.81-1.005-4.314-3.467
				c-0.208-1.018-1.473-1.088-2.401-1.312c-3.673-0.885-4.123-1.512-3.974-4.541c0.071-1.433-0.891-2.296-1.914-3.136
				c-8.044-6.6-16.579-12.473-25.751-17.396c-1.252-0.672-2.222-1.523-2.836-2.79c0-12.64,0-25.28,0-37.92
				c0.598-0.431,1.14-0.345,1.762,0.037c2.035,1.248,3.872,2.786,4.365,5.167c0.836,4.039,3.243,7.462,4.179,11.445
				c0.358,1.524,1.147,1.531,2.113,0.377c0.593-0.709,1.335-1.159,2.3-0.755c-1.194-2.26-1.521-4.817-2.274-7.233
				c-1.831-5.869-4.502-11.262-8.482-15.967c-1.569-1.855-3.007-3.78-3.963-6.031c0-0.96,0-1.92,0-2.88
				c1.381-0.87,2.043,0.242,2.769,1.045c9.495,10.505,14.654,23.094,17.6,36.735c0.323,1.496,0.468,2.999,2.325,3.506
				c0.754,0.207,0.984,1.035,1.115,1.742c0.433,2.343,1.291,4.605,1.208,7.049c-0.072,2.111,0.492,4.097,1.419,6.003
				c1.596,3.28,3.389,6.431,5.702,9.372c0.762-3.167,2.531-5.91,1.471-9.458c-1.645-5.5-2.564-11.206-3.73-16.837
				c-2.692-13.006-8.625-24.457-16.539-35.011c-3.744-4.993-7.698-9.784-12.024-14.274c-0.763-0.792-0.612-2.012-1.315-2.833
				c0-0.64,0-1.28,0-1.92c2.119-0.948,2.849,0.987,3.792,2.034c3.422,3.795,6.806,7.625,9.924,11.689
				c6.186,8.064,12.308,16.167,16.307,25.605c2.621,6.188,4.785,12.534,5.835,19.211c0.944,6.01,2.328,11.923,4.073,17.75
				c0.298,0.997,0.575,1.99,0.372,2.645c-0.125-2.515,1.417-4.888,2.549-7.402c0.375-0.833,0.779-1.613,1.853-1.61
				c1.132,0.002,1.78,0.658,2.031,1.691c0.803,3.31,2.071,6.564,1.448,10.061c-0.242,0.321,0.207,0.108-0.111,0.075
				c-0.048-0.005-0.063-0.08-0.001-0.204c1.304-2.596,1.26-5.277,0.7-8.064c-2.085-10.377-4.656-20.641-9.552-30.067
				c-5.193-9.999-10.845-19.761-17.785-28.707c-1.958-2.525-3.921-5.045-5.898-7.554c-1.454-1.845-3.11-2.905-5.389-3.076
				c-1.423-0.106-2.407-0.634-2.683-2.2c-0.24-1.36-1.231-1.942-2.562-2.119c-3.589-0.476-3.603-0.492-4.206-3.857
				c-0.093-0.519-0.366-0.846-0.695-1.181c0-12,0-24,0-36c1.383,1.727,2.634,3.488,2.072,5.91c-0.282,1.212,0.719,2.133,0.863,3.319
				c0.118,0.975,0.718,2.679,0.274,3.689c-0.12,0.273-0.19,0.568-0.284,0.853c2.428,0.429-0.184,2.86,1.532,3.819
				c0.67,0.375-1.483,1.525-0.27,2.774c0.476,0.491,1.104,1.302,1.482,1.248c0.626-0.091,0.681-1.002,0.871-1.679
				c0.543-1.94,1.918-3.449,2.984-5.113c0.276-0.43,0.729-0.2,0.962,0.182c0.575,0.946,1.491,1.822,1.405,2.975
				c-0.097,1.294,0.281,2.257,1.118,3.186c0.517,0.574,0.59,1.453,0.346,2.102c-0.202,0.538-1.126,0.888,0.066,1.316
				c1.029,0.37,0.978-0.566,1.201-1.05c1.219-2.647,0.863-5.571,1.408-8.337c0.592-3.002,0.532-6.134,1.798-9.006
				c0.194-0.441,0.331-0.862,1.099-1.266c1.512,5.7,1.464,11.458,2.501,17.236c0.618-0.525,0.014-1.349,0.667-1.809
				c0.905-0.636-0.8-0.958-0.385-1.414c0.717-0.786,1.957-1.174,1.697-2.591c1.294-0.167,0.929-1.349,1.344-2.064
				c0.36-0.622,0.734-1.587,1.632-1.591c0.824-0.004,1.064,0.958,1.353,1.519c0.623,1.21,1.725,2.406,1.025,3.941
				c-0.523,1.149,1.27,1.705,0.617,2.826c1.708,0.27,0.682,1.892,1.345,2.766c0.833,1.099-1.225,0.969-1.137,2.05
				c0.803,0.539,1.534,0.664,1.288,2.494c-0.267,1.985,0.016,4.901,1.946,6.76c0.538,0.518,0.112,0.75-0.067,1.176
				c-0.941,2.24-0.384,3.136,1.855,3.106c0.312-0.004,0.626-0.001,0.916-0.001c0.556-1.052-3.548-2.209,0.221-3.061
				c-0.57-0.833-2.933-1.549-0.172-2.47c0.399-0.133,0.114-0.464-0.011-0.764c-0.719-1.725-0.305-2.873,2.348-3.053
				c-2.748-1.095-1.77-2.668-0.918-3.691c0.933-1.121,0.475-1.565-0.572-2.207c3.071-1.123,2.8-4.633,5.118-6.338
				c1.205,1.787,2.23,3.463,2.145,5.792c-0.042,1.117,0.051,2.673,1.576,3.42c0.893,0.437-1.1,1.005-0.114,1.681
				c1.651,1.131-1.5,1.362-0.463,2.258c0.737,0.636,1.922,0.723,0.797,2.233c-0.341,0.458,1.137,2.036-0.109,3.112
				c0.31,0.237,0.588,0.559,0.938,0.69c0.49,0.184,0.931,0.046,1.1-0.551c0.389-1.372,1.367-1.847,2.715-1.807
				c1.369,0.041,1.561-1.389,2.217-2.096c0.737-0.795,1.4-2.202,2.355-1.703c1.214,0.635,2.59,1.97,2.379,3.494
				c-0.199,1.437,0.649,2.199,1.227,3.008c1.029,1.438,1.168,2.131-0.138,3.098c0.976,0.492,1.924,2.752,1.392,3.792
				c-0.302,0.59,0.089,0.673,0.284,0.915c0.396,0.491,0.643,0.219,0.805-0.224c0.246-0.668,2.268-1.016,0.375-2.071
				c1.268-0.772,0.706-2.612,1.589-3.244c0.78-0.558,0.98-1.407,1.615-1.997c0.612-0.568,0.977-2.017,1.854-1.724
				c1.026,0.342,1.83,1.54,2.073,2.708c0.718,3.439,0.666,3.45,4.221,3.496c0.341-0.883-0.585-0.766-0.906-1.109
				c-0.625-0.668-2.62-1.105-0.191-2.179c0.175-0.078,2.236-1.967,0.556-3.511c-0.735-0.676,0.185-1.084,0.62-1.022
				c1.455,0.207,1.056-0.892,1.506-1.571c1.088-1.646,1.537-3.731,3.218-4.985c0.315-0.235,0.632-0.243,0.799,0.073
				c1.015,1.919,2.957,3.517,2.436,5.995c-0.402,1.91,0.747,3.062,1.807,4.24c0.455,0.505,1.15,0.698,0.191,1.336
				c-0.502,0.333-0.569,0.703,0.053,1.055c0.981,0.556,0.873,0.997-0.15,1.399c-1.379,0.542-1.478,1.195,0.016,1.678
				c1.35,0.437,1.433,1.021,0.48,1.986c-0.374,0.379-0.395,0.924,0.127,1.228c1.267,0.738,1.223,1.047,0.063,2.152
				c-0.719,0.685,0.563,1.983,1.671,2.388c1.218-0.634-2.027-1.145,0.029-1.45c1.896-0.282,2.36-1.835,3.125-2.929
				c0.642-0.918,1.462-1.67,2.251-1.832c1.041-0.213,1.488,1.178,2.195,1.875c1.358,1.339,0.694,3.364,1.651,4.946
				c0.32,0.529,2.506,0.657,1.021,2.097c1.708,1.29-0.207,1.415-0.75,1.989c1.954,1.159,2.346,2.705,1.318,4.586
				c-0.406,0.743,0.369,0.417,0.522,0.656c0.918,1.432,2.071,2.542,1.795,4.69c-0.252,1.968,0.669,4.084,1.054,6.139
				c0.071,0.379,0.252,0.549,0.625,0.558c0.562,0.014,0.756-0.515,0.674-0.794c-0.426-1.434,0.867-2.223,1.195-3.376
				c0.527-1.856,1.271-1.879,2.564-0.327c1.796,2.156,2.427,4.756,3.169,7.391c0.578,2.049,0.371,4.688,2.972,5.884
				c-0.032-2.793,0.773-5.244,1.925-7.614c0.657-1.352,1.248-1.368,2.238-0.211c2.118,2.476,2.289,5.634,3.208,8.528
				c0.218,0.685,0.241,1.507,0.871,1.921c0.864,0.569,1.821,1.247,2.279-0.545c0.31-1.212,0.347-3.201,1.836-3.102
				c1.313,0.087,2.081,1.814,2.323,3.279c0.335,2.032,1.508,2.941,3.461,3.604c12.35,4.188,24.001,10.007,35.765,15.566
				c4.965,2.347,9.869,4.872,15.342,5.985c3.317,0.676,6.531-0.109,9.774-0.364c10.051-0.79,20.115-1.083,30.073,0.591
				c8.705,1.463,16.975,4.547,24.824,8.569c1.742,0.893,2.156-0.078,2.077-0.976c-0.313-3.539,1.011-6.836,1.419-10.253
				c0.196-1.648,1.52-3.484,2.406-5.197c0.345-0.667,0.978-0.566,1.336,0.009c0.373,0.599,0.71,1.279,0.832,1.965
				c0.759,4.265,1.921,8.475,1.921,12.848c0,0.565-0.167,1.157,0.245,1.59c0.465,0.489-0.049,2.003,1.295,1.486
				c1.188-0.458,0.139-1.131-0.092-1.692c-0.511-1.245,0.426-2.616,0.714-3.359c0.792-2.042,0.617-4.164,0.984-6.151
				c0.563-3.043,1.956-5.655,2.986-8.448c0.314-0.852,0.722-2.141,1.665-2.16c1.05-0.02,1.116,1.377,1.549,2.191
				c1.096,2.061,0.487,4.362,0.89,6.523c-0.658-0.242-1.394,0.028-2.037-0.308C278.309,284.966,279.038,284.707,279.698,284.916z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M444.096,766.624c-2.018-2.372-1.921-2.633,1.07-4.287
				c2.867-1.587,6.109-2.147,9.079-3.44c0.063-0.105,0.126-0.212,0.188-0.317c2.911-1.127,5.972-1.02,8.971-0.81
				c9.351,0.654,17.904,3.414,24.926,9.965c1.099,1.025,2.44,1.742,3.361,3.022c0.624,0.868,1.768,0.999,2.848,0.801
				c0.47-0.087,0.936-0.21,1.38-0.392c10.636-4.362,21.844-3.979,32.982-3.748c3.312,0.068,6.691,0.222,9.994,0.846
				c3.449,0.651,6.837,1.452,9.296,3.489c-0.945-0.285-1.562-1.139-1.115-2.584c0.2-1.229,1.408-1.552,2.095-2.343
				c2.386-1.447,4.879-2.519,7.628-3.245c3.925-1.038,7.795-2.273,11.841-2.754c3.898-0.464,7.764-0.114,11.665,0.306
				c3.448,0.371,6.667,1.2,9.86,2.487c3.113,1.254,5.896,2.795,7.555,5.86c0,3.119-0.056,6.241,0.032,9.358
				c0.029,1.036-0.199,1.416-1.233,1.211c-0.309-0.062-0.639-0.01-0.959-0.01c-192.64,0-385.28-0.003-577.919,0.045
				c-1.8,0-2.249-0.399-2.209-2.206c0.131-5.997,0.049-11.999,0.049-17.999c2.273-5.254,4.535-10.512,6.827-15.757
				c0.413-0.944,0.948-1.836,1.733-2.719c0,2.991,0.938,5.996-0.556,8.914c-3.26,6.368-5.084,13.217-6.725,20.126
				c-0.055,0.233-0.172,0.47-0.159,0.699c0.046,0.818-0.569,2.195,0.391,2.309c1.171,0.137,0.459-1.378,0.843-2.073
				c0.14-0.254,0.139-0.585,0.229-1.005c0.744,1.066-0.244,2.613,1.701,3.486c-0.81-7.981,0.864-15.266,4.873-22.164
				c0,7.229,0,14.46,0,21.754c1.313-0.162,1.173-1.018,1.354-1.661c0.561-1.997,1.109-3.996,1.69-5.987
				c0.152-0.521,0.179-1.25,0.793-1.385c0.837-0.185,0.938,0.724,1.253,1.193c1.424,2.121,2.756,4.304,4.168,6.434
				c0.366,0.552,0.571,1.362,1.993,1.366c-5.409-7.562-10.163-15.165-10.441-24.568c-0.266-8.975,0.695-17.811,2.948-26.93
				c1.527,1.865,2.171,3.772,2.142,5.535c-0.155,9.079,2.641,17.458,5.809,25.743c2.542,6.646,5.939,12.906,9.076,19.282
				c0.241,0.489,0.417,1.062,1.654,1.115c-4.954-10.422-10.42-20.388-13.157-31.447c2.109,4.283,3.609,8.824,5.758,13.091
				c2.908,5.774,5.723,11.595,8.554,17.406c0.303,0.623,0.629,1.292,1.366,0.988c0.863-0.355,0.073-0.937-0.062-1.386
				c-0.457-1.519,0.903-3.219,2.439-3.012c0.697,0.095,0.579,0.552,0.562,1.057c-0.037,1.091-0.521,2.2,0.319,3.807
				c3.179-11.145,8.062-21.162,12.158-31.509c1.715,8.315,2.52,16.615,3.218,24.929c0.105,1.253-0.442,2.317-0.92,3.411
				c-0.367,0.842-1.055,1.604-0.752,2.743c0.812-0.008,1.508-1.532,2.489-0.033c0.369,0.562,1.214-0.186,1.1-1.133
				c-0.978-8.074-1.221-16.225-2.576-24.26c-0.549-3.256-0.937-6.533-0.675-10.344c2.387,3.601,3.019,7.286,3.722,10.906
				c1.4,7.205,2.4,14.495,4.605,21.526c0.361,1.151,0.456,2.488,1.613,3.288c0.393-10.771,3.141-21.174,3.197-31.824
				c0.018-3.484-0.195-6.997-0.855-10.475c4.289,6.633,8.162,13.288,6.219,21.801c-1.38,6.047-2.939,12.097-3.037,18.37
				c-0.004,0.238-0.097,0.477-0.087,0.712c0.026,0.59-0.267,1.45,0.719,1.449c0.829-0.001,0.586-0.794,0.623-1.298
				c0.074-1.033,0.076-2.072,0.145-3.106c0.459-6.864,2.776-13.41,3.455-20.248c0.286-2.884,0.044-5.75,0.221-8.618
				c0.076-1.23,0.173-2.46,0.517-3.698c1.755,6.622,3.52,13.24,4.059,20.004c0.235,2.944-1.364,6.016-2.002,9.057
				c-0.442,2.107-0.681,4.259-0.974,6.396c-0.071,0.521-0.271,1.28,0.466,1.408c0.978,0.169,0.868-0.698,0.897-1.303
				c0.525-11.14,5.187-20.796,10.782-30.117c0.065-0.109,0.249-0.146,0.578-0.326c0.41,2.447,0.097,4.761-0.327,7.072
				c-1.237,6.734-0.3,13.343,1.154,19.923c0.292,1.323,0.521,2.66,0.779,3.99c0.083,0.426,0.32,0.789,0.748,0.813
				c0.532,0.029,0.673-0.424,0.553-0.828c-1.803-6.031,1.874-11.062,2.995-16.559c0.091-0.443,0.517-0.852,0.06-1.527
				c-1.904,3.31-2.812,6.921-4.211,10.487c-2.916-8.768-1.182-19.508,5.557-25.506c0.778,0.484,0.461,1.273,0.488,1.929
				c0.429,10.165,0.153,20.315-0.675,30.453c-0.052,0.634-0.217,1.374,0.603,1.608c0.727,0.208,1.474,0.073,1.883-0.673
				c3.052-5.563,6.919-10.786,7.342-17.457c0.178-2.814,1.006-5.475,1.986-8.089c0.405-1.08,0.893-1.432,1.704-0.15
				c1.171,1.851,2.403,3.541,0.658,5.759c-0.562,0.714-1.047,1.808-0.956,2.662c0.589,5.496-2.463,9.981-3.918,14.905
				c-0.287,0.968-1.311,2.381-0.16,2.851c0.746,0.304,0.933-1.496,1.219-2.439c1.638-5.405,4.777-10.076,7.381-15.008
				c0.207-0.392,0.384-0.894,1.054-0.649c2.117,0.772,3.7,4.751,2.714,6.809c-1.586,3.312-3.15,6.636-4.722,9.955
				c-0.202,0.428-0.408,0.859-0.152,1.5c1.5-0.405,1.35-2.031,2.332-2.912c0.699,0.684,0.392,1.605,0.573,2.377
				c0.085,0.364,0.335,0.566,0.669,0.547c0.35-0.02,0.55-0.29,0.552-0.648c0.001-0.319-0.015-0.641-0.059-0.956
				c-0.559-4.062,0.447-7.734,2.784-11.062c3.546,2.292,3.768,3.038,2.453,7.013c-0.604,1.824-1.655,3.558-1.534,6.285
				c1.739-2.2,2.024-4.335,2.785-6.257c0.246-0.621,0.048-1.673,0.923-1.784c0.813-0.104,1.019,0.887,1.432,1.421
				c1.228,1.589,2.127,3.375,2.904,5.223c0.245,0.583,0.645,0.894,1.301,0.806c0.818-0.109,0.798-0.709,0.659-1.264
				c-2.135-8.492,1.042-16.291,3.43-24.092c1.898-6.201,0.584-12.186-0.034-18.257c-0.292-2.872-0.383-5.739-0.357-8.616
				c0.006-0.632,0.01-1.366,0.312-1.876c2.539-4.275,3.653-9.171,5.959-13.54c0.258-0.487,0.629-0.928,1.004-1.338
				c0.776-0.852,1.32-0.518,1.379,0.464c0.066,1.104,0.019,2.244-0.187,3.33c-0.459,2.432-0.839,4.91-1.621,7.245
				c-2.256,6.736-0.489,13.281,0.717,19.848c1.126,6.132,2.349,12.256,2.661,18.488c0.302,6.029-0.769,11.933-2.248,17.763
				c-0.141,0.554-0.778,1.364,0.083,1.66c0.866,0.299,1.006-0.753,1.056-1.255c0.743-7.486,4.372-13.886,7.564-20.46
				c0.833-1.715,1.654-3.359,3.776-4.424c-1.92,5.458-3.746,10.617-5.548,15.785c-1.05,3.012-2.076,6.032-3.078,9.061
				c-0.146,0.44-0.782,1.083,0.122,1.372c0.586,0.188,0.804-0.437,0.958-0.862c0.756-2.104,1.47-4.221,2.204-6.332
				c1.981-5.7,3.966-11.399,6.432-17.12c0.683,2.853,1.389,5.701,2.037,8.562c0.195,0.862-0.421,1.51-0.844,2.178
				c-2.345,3.705-4.654,7.432-6.644,11.342c-0.339,0.668-0.944,1.285-0.743,2.323c1.575-0.185,1.578-1.632,2.091-2.543
				c0.871-1.545,5.759-3.947,7.472-3.592c0.759,0.157,0.585,0.607,0.437,1.051c-0.433,1.299-0.864,2.52-2.482,2.925
				c-1.673,0.419-3.28,1.104-4.867,1.942c1.027,0.258,1.946,0.396,2.97-0.241c0.986-0.614,2.02-1.859,3.22,0.173
				c0.484,0.818,0.908-0.52,1.143-1.114c3.598-9.128,6.752-18.444,11.084-27.263c2.926-5.958,6.545-11.53,10.424-16.923
				c0.415-0.577,0.802-1.317,1.67-1.07c0.784,0.223,0.967,0.993,1.149,1.697c0.479,1.855,0.249,3.664-0.558,5.359
				c-4.441,9.334-7.132,19.102-7.619,29.467c-0.132,2.806-1.201,5.446-2.108,8.092c-0.204,0.595-0.582,1.203-0.191,1.818
				c0.242,0.38,0.72,0.316,1.091,0.158c0.802-0.342,0.466-1.087,0.535-1.681c0.565-4.871,2.768-8.892,6.282-12.261
				c0.903-0.865,1.683-1.858,2.761-3.063c1.133,5.199,1.076,10.086,0.907,14.98c-0.026,0.744-0.589,1.695,0.473,2.068
				c0.983,0.346,1.596-0.373,2.147-1.127c1.555-2.126,3.155-4.22,4.73-6.331c0.369-0.493,0.581-1.145,1.555-1.297
				c0.236,2.021,0.468,4.018,0.736,6.312c-1.329-0.552-2.557-0.974-2.944,0.756c-0.136,0.609-0.346,1.466,0.498,1.599
				c1.164,0.183,0.288-1.246,1.002-1.538c0.804,0.099,0.682,0.997,1.166,1.386c0.491,0.396,1.056,0.434,1.577,0.203
				c0.542-0.24,0.424-0.8,0.352-1.266c-0.206-1.341-0.367-2.694-0.667-4.016c-1.046-4.599,0.125-8.609,3.308-12.408
				c1.427,5.934,1.004,11.905,2.001,17.902c1.153-0.729,0.854-1.671,0.838-2.451c-0.102-4.959-0.538-9.898-1.383-14.78
				c-0.389-2.244-0.107-4.203,0.889-6.226c1.071-2.174,1.88-4.476,3.011-6.691c-0.056,5.601,0.553,11.101,1.208,16.607
				c0.47,3.947-0.533,7.781-1.482,11.593c-0.141,0.563-1.001,1.432,0.151,1.795c0.839,0.264,1.674,0.07,2.238-0.887
				c1.825-3.097,3.706-6.161,5.619-9.204c0.8-1.272,1.735-2.461,2.761-3.607c-0.276,2.361-1.653,4.288-2.426,6.44
				c-0.593,1.651-1.356,3.245-1.904,4.91c-0.221,0.673-1.17,1.691-0.242,2.1c1.062,0.467,1.058-0.945,1.29-1.57
				c1.891-5.091,4.198-9.997,6.539-14.887c0.567-1.184,0.987-2.501,2.339-3.779c-0.569,2.701-0.304,4.994-0.369,7.281
				c-0.061,2.103-1.608,3.6-2.205,5.493c-0.646,2.049-1.516,4.026-2.216,6.06c-0.173,0.502-0.859,1.324,0.133,1.591
				c0.901,0.241,0.861-0.823,1.021-1.323c0.864-2.726,1.757-5.43,3.326-8.174c1.489,3.214-0.003,6.519,0.896,9.784
				c1.174-0.774,0.629-1.742,1.081-2.508c1.024,0.409,0.781,1.864,2.518,2.558c-5.613-13.406-2.603-26.35-0.277-39.817
				c1.741,1.831,2.179,3.701,2.208,5.522c0.16,9.782,3.292,18.772,7.109,27.596c0.728,1.682,1.509,3.339,2.292,4.995
				c0.287,0.604,0.484,1.359,1.804,1.376c-2.521-6.173-5.542-11.919-7.086-18.213c2.587,5.487,5.091,11.002,7.907,16.367
				c0.223,0.425,0.411,0.871,0.667,1.274c0.262,0.414,0.64,0.812,1.159,0.482c0.444-0.282,0.114-0.694-0.011-1.062
				c-0.57-1.677,0.997-6.893,2.336-7.961c0.441-0.352,0.718-0.176,0.999,0.143c1.003,1.138,2.129,2.196,2.96,3.45
				c1.075,1.62-0.615,2.761-1.03,4.127c-0.139,0.458-0.381,0.874-0.014,1.438c1.259-0.289,1.374-1.471,1.728-2.332
				c0.562-1.367,1.031-1.146,2.043-0.365c1.098,0.846,1.468,2.684,3.789,2.701c-2.89-3.191-5.682-5.906-8.043-8.953
				c-5.959-7.688-10.827-15.965-12.444-25.769c-0.396-2.399-0.004-4.752,0.69-7.076c0.411-1.373,0.964-1.366,2.014-0.575
				c1.578,1.188,2.701,2.765,3.581,4.427c4.294,8.11,8.78,16.128,12.474,24.544c1.64,3.736,2.838,7.628,3.8,11.592
				c0.251,1.037,0.471,1.978,1.944,1.927c1.519-0.052,1.303-1.197,1.466-2.03c0.837-4.278,1.966-8.474,3.726-12.657
				c0.405,3.178,0.813,6.355,1.214,9.534c0.149,1.188,0.263,2.384,0.429,3.569c0.082,0.583-0.109,1.501,0.83,1.431
				c0.954-0.07,0.637-0.983,0.579-1.533c-0.344-3.253-0.705-6.507-1.152-9.747c-0.588-4.261-1.426-8.495-1.126-13.302
				c2.122,2.837,2.685,5.841,3.301,8.776c0.985,4.691,1.798,9.418,2.721,14.122c0.125,0.64,0.03,1.789,0.965,1.627
				c1.042-0.181,0.382-1.147,0.251-1.769c-1.367-6.473-1.784-13.182-4.167-19.391c-1.652-4.307-3.979-8.355-4.775-12.978
				c-0.46-2.671-0.971-5.335-0.386-8.069c0.221-1.035,0.542-1.999,1.155-2.857c2.314-3.24,4.609-3.247,6.667,0.14
				c3.643,5.994,5.277,12.621,5.865,19.551c0.674,7.947-0.734,15.747-1.623,23.584c-0.073,0.641-0.659,1.63,0.439,1.755
				c0.849,0.097,0.645-0.943,0.744-1.513c0.86-4.962,1.362-9.969,1.761-14.985c0.392-4.928,0.174-9.834-0.626-14.769
				c2.843,4.024,5.003,8.358,6.335,13.074c0.991,3.508-0.177,6.942-0.638,10.384c-0.273,2.044-0.835,4.047-1.192,6.082
				c-0.098,0.561-0.521,1.464,0.301,1.673c0.885,0.224,0.83-0.778,0.945-1.318c1.503-7.028,2.793-14.085,2.503-21.327
				c-0.028-0.712,0.052-1.43,0.107-2.143c0.023-0.303,0.022-0.652,0.729-0.847c1.068,5.286,2.083,10.558,3.214,15.804
				c0.57,2.646,0.705,5.194-0.419,7.744c-0.278,0.63-0.882,1.796,0.089,2.049c1.033,0.269,0.92-1.072,1.116-1.755
				c1.959-6.824,5.474-12.887,9.27-19.413c0.784,3.245,0,5.931-0.347,8.588c-0.5,3.819-0.771,7.606,0.044,11.404
				c0.107,0.497-0.073,1.384,0.674,1.312c0.948-0.091,0.583-0.97,0.486-1.55c-1.014-6.139-0.023-11.913,3.105-17.304
				c0.879-1.515,1.832-2.977,3.251-4.204c0.946,0.908,0.781,2.032,0.783,3.094c0.01,6.104-0.008,12.208,0.021,18.312
				c0.003,0.613-0.447,1.677,0.704,1.653c0.874-0.018,0.545-0.979,0.66-1.559c0.531-2.674,2.886-4.093,4.299-6.16
				c0.343-0.503,0.819-0.112,0.873,0.521c0.159,1.88,0.814,3.746-0.064,5.64c-0.21,0.452-1.109,1.15-0.137,1.574
				c0.981,0.427,1.094-0.606,1.368-1.224c0.854-1.917,1.285-3.924,1.369-6.027c0.128-3.229,1.039-6.248,2.521-9.164
				c2.715,2.398,3.109,3.955,1.606,6.915c-1.411,2.779-0.907,5.791-1.261,8.698c-0.053,0.436,0.079,0.801,0.59,0.845
				c0.557,0.047,0.869-0.304,1.118-0.747c1.018-1.813,2.137-3.577,3.031-5.447c0.759-1.587,1.389-1.482,2.219-0.143
				c0.042,0.067,0.097,0.128,0.152,0.185c1.624,1.689,3.801,3.203,1.659,6.376c1.737-2.415,3.224-1.569,4.703,0.311
				c0.083-0.286,0.165-0.573,0.248-0.859c-1.601-1.793-3.229-3.562-4.796-5.386c-6.013-6.993-10.324-14.938-12.979-23.755
				c-1.055-3.501-1.129-7.052,0.149-10.595c0.469-1.299,0.839-1.26,1.801-0.508c1.151,0.9,2.089,1.954,2.803,3.201
				c5.202,9.079,10.182,18.279,14.237,27.937c1.108,2.639,1.748,5.475,2.619,8.216c0.16,0.502,0.172,1.259,0.923,1.194
				c0.815-0.07,0.683-0.713,0.513-1.282c-0.157-0.525-0.118-1.05,0.545-1.021c0.619,0.027,0.615,0.615,0.603,1.106
				c-0.015,0.518-0.327,1.234,0.604,1.215c0.602-0.013,0.68-0.619,0.778-1.064c1.381-6.229,3.829-12.198,4.761-18.522
				c0.599-4.067-0.626-8.094-1.061-12.132c-0.352-3.268-0.549-6.531-0.375-9.801c0.141-2.65,2.156-4.567,3.048-6.961
				c1.08-2.898,1.874-5.949,3.645-8.56c0.385-0.568,0.764-1.44,1.601-1.148c0.853,0.298,0.583,1.232,0.538,1.912
				c-0.176,2.643-0.592,5.279-1.449,7.762c-2.254,6.529-1.562,12.984-0.169,19.548c1.626,7.658,3,15.376,3.038,23.25
				c0.007,1.434-0.177,2.866-0.255,4.301c-0.024,0.457-0.237,1.028,0.293,1.302c0.739,0.38,0.96-0.361,1.265-0.76
				c2.108-2.749,3.388-5.949,4.879-9.035c0.879-1.819,1.398-3.916,4.02-5.086c-1.542,5.253-3.845,9.864-4.978,14.804
				c0.271,0.097,0.542,0.193,0.813,0.289c1.666-4.438,3.332-8.876,5.201-13.854c0.849,3.324,1.516,6.024,2.233,8.711
				c0.263,0.983-0.234,1.653-0.685,2.426c-0.447,0.769-1.271,1.408-1.21,2.544c1.437-0.021,1.376-1.557,2.497-2.012
				c0.758,0.352,0.295,1.895,1.879,2.032c-1.202-5.052-2.399-9.936-3.521-14.837c-1.165-5.093,0.079-9.944,1.705-14.851
				c2.696,4.55,4.143,9.486,5.313,14.548c1.192,5.15,1.246,10.178-1.593,15.16c1.763-0.143,1.799-1.194,2.06-1.937
				c3.876-11.066,9.187-21.391,15.975-30.95c0.698-0.983,1.178-2.709,2.707-2.263c1.545,0.45,1.293,2.236,1.396,3.52
				c0.152,1.896-0.813,3.538-1.606,5.187c-3.528,7.339-5.641,15.085-6.559,23.154c-0.12,1.052-0.809,2.93,0.581,3.087
				c1.125,0.127,0.788-1.803,0.895-2.854c0.689-6.78,1.805-13.421,4.963-19.602c2.903-5.684,7.152-10.122,12.094-14.009
				c1.648-1.298,2.256-0.46,2.471,1.158c0.523,3.93-0.494,7.601-2.021,11.152c-3.192,7.428-8.197,13.655-13.255,19.842
				c-1.146,1.4-2.34,2.76-3.311,4.34c2.37-1.059,3.47-3.497,5.464-5.162c0.943,1.269,0.617,2.758,0.94,4.083
				c0.134,0.544-0.049,1.472,0.966,1.277c0.875-0.169,0.482-0.873,0.341-1.353c-0.596-2.016,0.626-2.403,2.209-2.73
				c5.992-1.237,11.748-0.081,17.455,1.598c2.582,0.759,5.132,1.628,7.762,2.244c-4.103-2.021-8.45-3.345-12.906-4.248
				c-4.236-0.858-8.523-1.059-12.816-0.185c-0.665,0.135-1.648,0.951-2.077-0.202c-0.342-0.919-0.471-1.88,0.073-2.969
				c1.798-3.598,4.899-3.411,8.169-3.242c10.534,0.546,19.659,5.062,28.656,9.98c0.591,0.323,1.044,1.04,2.433,0.601
				c-11.321-6.371-22.407-11.936-35.544-11.244c0.833-3.069,1.673-3.734,4.485-3.834c10.06-0.354,19.282,2.21,27.648,7.834
				c0.505,0.34,0.979,0.87,1.712,0.691c2.613,1.465,4.31,4.021,6.761,5.684c0.515,0.349,0.821,0.872,1.641,0.6
				c3.263-1.086,6.585-0.848,8.565-0.442c-1.985-0.345-5.311-0.672-8.582,0.445c-0.679,0.231-1.018-0.077-1.463-0.444
				c-0.859-0.708-1.757-1.344-2.537-2.167c-2.717-2.864-2.786-2.959,0.975-4.628c5.593-2.482,11.376-2.124,17.104-0.574
				c4.391,1.188,8.615,2.886,11.775,4.626c-4.554-2.304-10.1-4.735-16.165-5.492c-5.213-0.651-10.401-0.536-14.917,2.751
				C446.048,767.843,445.096,767.422,444.096,766.624z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M15.48,507.88c0-4.64,0-9.279,0-13.92c2.274-0.345,2.589,1.059,2.449,2.868
				c0.087,0.264,0.12-0.02,0.109-0.043c-0.571-1.291,0.04-1.657,0.861-1.662c-0.904-0.412-2.142-1.07-3.419-1.643
				c0-4.32,0-8.641,0-12.961c2.85,0.399,5.714,0.052,8.565,0.398c0.28-0.393-0.269-0.497-0.023-0.292
				c0.102,0.085,0.062,0.146-0.104,0.218c-0.065,0.028-0.117,0.175-0.153,0.167c-2.731-0.585-5.637,0.313-8.285-0.971
				c0-4.641,0-9.28,0-13.92c0.726-1.886,2.517-1.589,3.985-1.915c1.158-0.257,2.339-0.432,3.519-0.066
				c-1.585-0.436-3.176-0.171-4.664,0.284c-1.42,0.436-2.281,0.147-2.84-1.183c0-2.88,0-5.76,0-8.641
				c1.727-1.748,3.238,0.424,4.899,0.219c-0.782-0.761-1.697-1.356-2.67-1.875c-0.878-0.394-1.574-1.021-2.229-1.703
				c0-1.44,0-2.88,0-4.32c0.112-0.112,0.228-0.222,0.337-0.337c3.337-3.481,6.406-3.077,7.357,2.018
				c0.118,0.632,0.373,1.251,0.837,1.69c3.075,2.912,4.192,7.017,6.442,10.41c1.013,1.527,1.735,3.602,3.902,4.151
				c2.347,1.303,4.353,3.142,6.807,4.291c0.726,0.339,1.274,0.955,1.235,1.878c-1.252,1.884-0.6,3.312,1.773,3.193
				c3.205-0.16,6.5,0.624,9.613-0.672c1.609-0.671,3.501-0.972,4.229-2.89c-0.112-0.143-0.188-0.292-0.297-0.428
				c-1.403-1.177-2.504-2.528-2.699-4.433c0.125-0.832,0.84-1.156,1.39-1.621c1.489-1.289,3.8-1.605,4.495-3.813
				c-0.054-0.178-0.086-0.354-0.161-0.509c-1.18-1.055-2.73-0.651-4.064-1.063c-1.998-0.618-2.239-1.147-0.833-2.63
				c0.429-0.452,1.041-0.748,1.134-1.602c-1.283-0.478-1.595,0.943-2.408,1.301c-0.193,0.148-0.415,0.234-0.646,0.302
				c-2.146,0.088-4.34,0.346-5.838-0.91c0.366,1.506,1.513,2.936,3.306,3.875c0.564,0.296,0.86,0.948,0.557,1.662
				c-0.258,0.605-0.787,0.754-1.373,0.756c-1.827,0.005-3.656,0.167-5.444-0.474c-0.937-0.336-1.993-0.797-2.796,0.317
				c0.031,0.21,0.002,0.397-0.032,0.592c-0.063,0.481-0.183,0.95,0.117,1.399c0.417,0.625,0.782,1.297,0.082,1.929
				c-0.644,0.581-1.405,0.211-2.048-0.014c-1.821-0.636-3.465-1.628-5.054-2.715c-1.844-1.263-3.619-2.62-5.432-3.927
				c-1.068-0.77-2.095-1.672-1.49-3.172c0.617-1.53,1.897-0.641,2.925-0.628c0.391,0.005,0.773,0.203,1.259,0.021
				c-0.317-0.751-1.094-0.815-1.657-1.166c-0.197-0.152-0.367-0.327-0.515-0.526c-0.715-0.964-1.255-2.041-2.05-2.953
				c-0.58-0.666-1.157-1.483-0.695-2.381c0.491-0.955,1.541-0.673,2.387-0.664c1.866,0.018,3.647,0.312,4.831,1.999
				c1.642,1.953,4.031,1.412,6.134,1.809c1.083,0.204,2.207-0.064,3.786,0.2c-8.684-0.865-8.684-0.865-10.243-2.254
				c-1.523-3.145-0.633-4.692,2.798-4.623c1.863,0.038,3.808-0.517,5.58,0.521c1.065,0.994,2.196,0.987,3.37,0.189
				c3.542-1.347,7.48-0.913,10.99-2.452c0.718-0.315,1.68-0.507,2.208,0.262c0.641,0.933-0.368,1.388-0.874,1.925
				c-1.648,1.746-3.424,3.348-5.076,4.341c2.265-1.806,5.197-3.562,6.729-6.854c0.675-1.452,2.458-1.145,3.171,0.37
				c1.816,3.854,2.259,4.171,6.745,3.387c2.176-0.38,4.07-0.303,5.824,1.163c1.516,1.269,3.353,1.784,5.297,1.895
				c1.518,0.087,2.377,0.619,1.58,2.248c-0.525,1.072-0.274,2.022,0.238,2.965c0.747,1.376,0.908,2.507-0.839,3.316
				c-0.688,0.319-0.949,1.267-1.62,1.729c-0.318,0.306-0.604,0.639-0.854,1.004c-0.846,1.528-2.09,2.427-3.808,2.928
				c-2.126,0.621-4.068,1.737-5.939,2.938c-0.883,0.856-1.567,1.729-2.761,0.091c-0.536-0.735-2.052-0.352-3.132-0.226
				c3.349-0.38,3.675-0.179,3.336,2.08c0.953-0.672,2.011-1.229,2.787-2.15c2.762-1.869,6.044-2.651,8.939-4.27
				c0.975-0.545,2.346-0.054,3.524-0.161c1.474-0.135,1.833,0.807,2.246,1.921c0.259,0.7,1.06,0.884,1.794,0.948
				c2.51-0.347,4.507-1.799,6.601-3.044c1.234-0.427,2.486,0.09,3.769-0.12c1.24-0.203,1.609,0.812,1.187,1.934
				c-0.844,2.238-0.765,4.897-2.996,6.518c-0.374,0.271-0.399,0.833-0.516,1.304c-0.656,2.671-1.938,3.862-4.526,3.421
				c-2.239-0.381-3.472,0.676-4.664,2.115c-1.117,1.348-2.108,2.797-3.175,4.185c-0.573,0.743-1.007,1.654-1.867,1.899
				c1.117-0.414,1.605-1.64,2.373-2.583c0.602-0.74,1.147-1.527,1.721-2.291c3.533-4.7,5.587-4.515,8.691,0.765
				c0.815,1.858,0.036,3.452-0.818,5.032c-0.043,0.232-0.193,0.552-0.082,0.694c1.87,2.369,0.566,4.97,0.583,7.472
				c0.509,1.723,1.282,3.343,1.92,5.014c0.301,1.979-1.423,2.844-2.345,4.12c-0.726,1.004-1.807,1.736-2.467,2.824
				c-0.131,0.206-0.314,0.359-0.522,0.48c-0.796,0.369-1.66,0.689-2.512,0.673c-1.755-0.034-3.021,0.68-3.971,2.09
				c-1.404,1.281-2.833,2.522-4.714,3.062c-0.86,0.099-1.727,0.022-2.59,0.062c-3.455,0.161-4.55,1.083-5.329,4.467
				c-0.125,0.542-0.281,1.063-0.643,1.501c-1.733,1.656-3.111,3.629-4.737,5.382c-0.555,0.598-1.015,1.247-0.775,2.135
				c0.456,0.763,0.971,1.505,1.84,1.767c2.15,0.645,1.379,1.706,0.402,2.728c-2.186,2.285-4.98,3.886-7.097,6.256
				c-2.12,1.046-4.077,2.394-6.251,3.341c-1.567,0.078-3.155-0.074-4.569,0.933c-0.57,0.406-1.384,0.277-2.104,0.286
				c-1.371,0.017-2.008-0.385-1.792-1.995c0.562-4.191,0.487-4.207-3.642-5.671c-0.922-0.326-1.727-0.708-2.117-1.738
				c-1.147-3.025-3.342-4.68-6.594-5.024c-1.418-0.149-2.788-0.652-4.178-0.999c-1.463-0.363-2.637-1.105-3.162-2.62
				c-0.315-1.011,0.557-2.294-0.726-3.087c-0.38-0.398-0.778-0.781-0.999-1.301c-1.077-2.547-1.077-2.547-3.93-3.604
				c-0.235-0.062-0.435-0.183-0.623-0.329C17.879,510.877,16.172,509.76,15.48,507.88z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M15.48,289c6.263,3.124,12.284,6.63,18.013,10.678c4.36,3.082,8.55,6.37,12.783,9.612
				c1.131,0.866,1.7,1.56,1.105,3.159c-0.817,2.2,0.346,3.384,3.642,3.346c2.085-0.023,3.216,0.167,2.856,2.594
				c-0.148,0.997,0.53,1.772,1.566,1.703c2.414-0.16,3.897,1.326,5.35,2.845c12.499,13.073,22.691,27.742,30.763,43.94
				c8.713,17.486,13.795,36.131,18.097,55.048c0.062,0.271-0.023,0.575-0.072,1.416c-1.653-2.252-3.653-2.369-5.775-2.268
				c-0.986,0.047-2.289,0.446-1.988-1.635c0.145-1.003-1.021-2.045-2.344-2.261c-3.111-0.037-3.791-0.459-4.852-3.203
				c-5.297-13.702-11.9-26.751-19.615-39.245c-7.901-12.794-16.99-24.672-27.566-35.375c-4.827-4.884-10.168-9.205-15.466-13.567
				c-3.815-3.141-7.683-6.217-11.479-9.381c-1.705-1.42-3.806-2.38-5.018-4.367C15.48,304.36,15.48,296.68,15.48,289z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M15.48,349.96c0-5.92,0-11.84,0-17.76c1.028-0.634,1.62,0.188,2.3,0.695
				c4.543,3.385,8.656,7.294,13.028,10.888c1.791,1.472,3.579,3.107,5.201,4.833c4.644,4.941,9.723,9.406,14.909,13.759
				c2.261,3.07,4.865,5.855,7.297,8.783c2.842,3.42,5.292,7.138,8.031,10.641c0.388,2.638,2.371,4.411,3.506,6.599
				c2.457,4.734,5.465,9.201,7.339,14.24c1.347,3.619,2.992,7.116,4.675,10.584c0.698,1.437,0.766,2.657-0.056,3.955
				c-0.993,1.566-1.808,3.207-2.126,5.051c-0.205,1.187-1.192,1.679-2.023,2.241c-1.976,1.338-3.079,2.991-2.42,5.468
				c0.103,0.387,0.092,0.793,0.08,1.193c-0.019,0.65-0.032,1.371-0.801,1.566c-0.826,0.211-1.246-0.317-1.583-1.035
				c-0.944-2.013-1.624-4.131-2.525-6.159c-4.846-10.915-9.499-21.937-16.122-31.945c-3.617-5.466-7.463-10.782-11.675-15.812
				c-6.313-7.54-13.385-14.361-20.375-21.255c-1.639-1.617-3.581-2.892-5.221-4.537C16.3,351.33,15.99,350.598,15.48,349.96z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M64.904,381.844c-3.43-3.758-5.912-8.256-9.396-11.979
				c-1.898-2.028-3.632-4.21-5.439-6.322c-1.582-2.326-3.739-4.136-5.644-6.162c-4.366-4.644-8.802-9.254-13.572-13.469
				c-4.39-3.88-8.84-7.72-13.46-11.337c-0.852-0.667-1.32-1.497-1.913-2.296c0-5.6,0-11.2,0-16.8c2.268-0.421,3.399,1.516,4.806,2.568
				c9.344,6.99,18.226,14.57,26.587,22.69c6.935,6.735,12.957,14.317,18.745,22.094c6.963,9.354,12.85,19.356,18.396,29.553
				c3.444,6.333,6.332,12.978,9.07,19.664c0.212,0.519,0.318,1.073,0.486,1.607c0.421,1.34,0.096,2.118-1.44,2.339
				c-1.347,0.194-2.674,0.501-4.03,0.191c-0.64-0.146-1.267-0.124-1.905-0.07c-4.019,0.34-4.049,0.352-5.571-3.341
				c-3.903-9.47-8.78-18.405-14.634-26.806C65.524,383.301,65.212,382.58,64.904,381.844z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M597.72,565c-1.394-1.175-2.053-3.019-3.637-4.013
				c-0.815-0.513-1.73-1.104-2.675-0.603c-1.12,0.594-0.588,1.662-0.411,2.579c0.317,1.645,0.451,3.352,0.99,4.92
				c1.217,3.548-0.921,10.472-2.615,14.578c-0.386-2.079-0.687-3.716-0.997-5.352c-0.084-0.445,0.007-1.006-0.646-1.126
				c-0.704-0.129-0.929,0.358-1.236,0.864c-1.523,2.51-2.16,2.548-4.879,0.775c-1.55-1.011-2.494,0.596-3.654,1.147
				c-0.453,0.216-0.712,1.021-1.335,0.742c-0.736-0.331-0.404-1.131-0.419-1.728c-0.029-1.193,0.776-2.071,1.321-3.028
				c1.39-2.438,2.561-4.966,3.263-7.685c0.191-0.742,0.468-1.789-0.286-2.216c-0.921-0.521-1.29,0.575-1.805,1.125
				c-0.762,0.814-1.278,2.021-3.354,1.858c1.906-0.995,2.066-2.338,1.694-3.62c-0.56-1.932-0.011-3.638,0.688-5.339
				c1.325-3.227,2.171-6.517,1.535-10.029c-0.11-0.607,0.181-1.079,0.298-1.612c0.135-0.615,0.504-1.328,0.323-1.841
				c-1.333-3.793-1.396-7.502-0.016-11.333c0.52-1.44-0.715-1.523-1.724-1.023c-1.449,0.717-2.68,1.726-3.562,3.119
				c-0.981,1.549-1.979,3.089-2.985,4.62c-0.331,0.504-0.623,1.399-1.313,1.076c-0.555-0.261-0.964-1.042-1.221-1.68
				c-0.386-0.956,0.605-0.945,1.095-1.188c1.39-0.688,2.363-1.647,2.374-3.288c0.01-1.503,1.747-2.086,1.758-3.723
				c0.01-1.484-0.596-3.159,0.864-4.539c0.484-0.458,0.347-1.708-0.427-2.383c-0.78-0.682-1.5-0.156-2.201,0.205
				c-2.673,1.377-4.534,3.753-6.851,5.569c-0.513,0.401-0.904,1.354-1.757,0.68c-0.815-0.646-0.052-1.312,0.182-1.926
				c0.525-1.378,1.25-2.57,2.904-2.837c0.37-0.059,0.964-0.292,1.014-0.534c0.599-2.958,3.638-4.5,4.267-7.438
				c0.131-0.615,0.476-1.192,0.37-1.851c-0.261-1.634-0.136-3.177,0.884-4.569c0.291-0.398,0.398-0.918,0.02-1.323
				c-0.392-0.418-0.891-0.226-1.325-0.028c-1.172,0.534-2.172,1.285-3.113,2.186c-2.479,2.367-4.703,5.027-7.671,6.848
				c-0.454,0.278-0.957,0.883-1.437,0.266c-0.422-0.541,0.06-1.1,0.527-1.392c1.691-1.052,2.02-2.953,2.995-4.441
				c1.257-1.918,2.074-3.918,3.025-5.919c0.909-1.911,1.363-4.117,2.654-5.909c0.426-0.591,0.552-1.276,1.439-1.266
				c0.319,0.004,0.641-0.062,0.957-0.038c3.896,0.294,7.77-0.416,11.864-0.007c-1.67,1.224-3.346,2.438-5.007,3.673
				c-0.832,0.617-1.61,1.311-2.467,1.89c-0.649,0.438-0.745,1.011-0.593,1.665c0.184,0.783,0.694,1.135,1.533,1.24
				c1.566,0.196,3.171,0.041,4.696,0.678c1.47,0.613,1.045-1.284,2.291-1.741c0.266,0.374,0.993,0.935,0.936,1.398
				c-0.25,2.023,0.904,3.093,2.473,3.666c2.017,0.737,4.066,1.237,6.008,2.319c0.984,0.55,1.969-1.26,2.611-2.425
				c0.637,0.43,0.823,1.408,1.764,1.383c0,0.96,0,1.92,0,2.88c-0.646,0.835-1.438,1.074-2.508,0.889
				c-1.722-0.299-3.444-0.551-5.101-1.143c-0.879-0.314-1.778-0.564-2.976-0.056c1.706,1.556,3.591,2.015,5.485,2.514
				c1.872,0.492,3.982,0.591,5.099,2.596c0,1.761,0,3.521,0,5.28c-1.888,1.6-0.484,3.2,0,4.8c0,1.28,0,2.56,0,3.84
				c-0.777,0.753-0.961,1.64-0.817,2.712c0.221,1.645-0.545,2.315-1.942,1.513c-1.336-0.767-2.499-0.812-3.827-0.272
				c-0.492,0.2-1.26,0.081-1.319,0.79c-0.06,0.716,0.584,1.143,1.144,1.403c1.053,0.491,2.075,1.183,3.141,1.479
				c3.237,0.899,3.293,3.398,3.101,5.884c-0.129,1.656-0.163,3.297,0.097,4.896c0.282,1.735-0.063,2.965-2.001,3.572
				c1.091,0.801,1.972,1.46,2.426,2.504C597.72,562.44,597.72,563.72,597.72,565z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M597.72,485.8c-1.997,0.688-1.863,2.647-2.301,4.153
				c-0.374,1.289-0.884,2.359-1.867,3.238c-7.389,6.605-16.174,7.161-25.385,5.86c-0.311-0.044-0.613-0.186-0.901-0.32
				c-0.2-0.094-0.368-0.254-0.865-0.609c1.663-0.465,3.46-0.042,4.352-1.659c0.8-1.449,1.057-3.021,1.019-4.628
				c-0.018-0.773,0.312-2.061-1.053-1.975c-1.279,0.081-2.219,0.873-2.275,2.328c-0.024,0.646,0.936,1.495-0.046,1.885
				c-1.061,0.423-1.474-0.729-1.919-1.413c-0.824-1.267-1.589-2.583-2.249-3.941c-0.348-0.716-0.752-1.121-1.53-1.325
				c-1.75-0.459-2.191-1.739-0.851-2.912c0.954-0.834,1.308-1.608,1.284-2.833c-0.021-1.072,0.547-1.258,1.591-0.711
				c3.655,1.915,5.573,0.997,6.753-2.921c0.387-1.285,0.206-2.096-0.938-2.826c-1.14-0.728-2.077-1.819-3.272-2.401
				c-2.411-1.174-2.537-3.5-3.119-5.591c-0.285-1.021,0.178-1.895,1.197-2.35c0.873-0.391,1.739-0.834,2.654-1.087
				c1.161-0.322,1.542-1.114,1.452-2.148c-0.082-0.954-0.195-2.077-1.719-1.446c-0.122,0.051-0.529-0.228-0.519-0.262
				c0.501-1.568-0.979-2.043-1.682-2.841c-1.025-1.162-1.373-1.729,0.447-2.449c1.713-0.677,1.16-2.176,0.474-3.373
				c-0.461-0.806-1.157-1.478-2.219-2.791c3.782,1.296,7.031,2.012,9.727,4.07c2.582,1.973,5.497,3.213,8.57,4.143
				c2.521,0.763,5.052,1.497,7.572,2.263c2.974,0.902,5.46,2.589,7.617,4.793C597.72,471.08,597.72,478.44,597.72,485.8z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M15.48,370.6c0-6.24,0-12.48,0-18.72c0.978-0.554,1.489,0.201,2.123,0.717
				c5.884,4.787,11.058,10.306,16.378,15.685c8.792,8.889,16.429,18.705,22.812,29.394c6.577,11.012,11.572,22.852,16.353,34.742
				c1.013,2.52-0.391,5.13-1.263,7.557c-0.268,0.745-1.387,1.019-2.339,0.826c-1.584-0.32-3.132-0.245-4.739,0.048
				c-2.478,0.452-3.197-0.104-3.886-3.023c-1.028-4.355-2.381-8.613-4.136-12.704c-3.977-9.268-8.547-18.235-14.816-26.209
				c-1.221-1.553-1.383-2.899-0.334-4.501c0.568-0.869,1.093-1.766,1.423-2.765c0.475-1.437,0.142-2.207-1.515-1.885
				c-1.385,0.375-2.665,1.13-4.133,1.244c-2.604-1.144-2.636-1.263-1.059-3.993c1.384-2.396,1.118-2.875-1.678-2.965
				c-0.875-0.028-1.804-0.194-2.617,0.034c-2.179,0.61-3.875,0.021-5.199-1.88c1.326,1.858,2.99,2.515,5.172,1.881
				c0.809-0.235,1.742-0.06,2.616-0.034c1.021,0.031,2.33-0.092,2.592,1.124c0.254,1.182-1.112,1.333-1.878,1.817
				c-0.196,0.102-0.399,0.188-0.606,0.265c-1.989,0.459-3.587,1.541-4.895,3.085c-0.187,0.126-0.382,0.241-0.577,0.356
				c-1.62,0.537-3.473,0.647-4.413,2.428c-0.151,0.185-0.341,0.317-0.55,0.427c-2.733,0.476-4.416,2.681-6.608,4.043
				c-0.727,0.452-1.304,1.303-2.226,0.368c0-4.64,0-9.28,0-13.92c2.74-3.933,5.948-7.45,9.505-10.653
				c0.288-0.26,0.665-0.371,1.019-0.513c0.158-0.021,0.415-0.056,0.451,0.027c0.124,0.291,0.371-0.22,0.24,0.071
				c-0.09-0.083-0.211-0.11-0.318-0.112c-1.199-0.018-1.864,1.155-2.977,1.34c-1.133,0.253-2.207,0.778-3.404,0.746
				C17.969,374.042,15.996,373.076,15.48,370.6z"/>
			<path fill-rule="evenodd" d="M39.836,389.009c1.539-0.772,3.644-1.547,4.671-0.388c1.13,1.276-0.292,3.175-1.225,4.468
				c-1.451,2.009-2.05,3.427-0.202,5.881c8.731,11.595,14.849,24.564,18.999,38.449c0.682,2.281,1.416,3.714,4.045,2.467
				c0.2-0.095,0.512,0.038,0.705-0.063c1.552-0.81,4.731,2.443,4.854-1.971c0.057-2.034,1.095-3.763,0.153-6.147
				c-4.502-11.406-9.398-22.613-15.576-33.207c-4.214-7.225-9.105-14.018-14.506-20.417c-3.171-3.758-6.246-7.641-9.77-11.049
				c-5.365-5.189-10.477-10.677-16.504-15.152c0-0.64,0-1.28,0-1.92c6.386,4.561,11.532,10.458,17.089,15.907
				c2.326,2.281,4.435,4.86,6.842,6.975c2.683,2.355,4.368,5.356,6.615,7.963c2.363,2.741,4.323,5.833,6.414,8.805
				c8.841,12.566,15.084,26.451,20.65,40.682c0.24,0.612,0.378,1.415,1.265,1.701c0.402-1.526-0.581-2.891-0.418-4.403
				c0.132-1.226,0.405-2.096,1.542-2.71c2.042-1.103,3.875-2.423,3.978-5.083c0.012-0.3,0.128-0.671,0.33-0.876
				c2.451-2.49,1.171-4.949,0.154-7.568c-3.771-9.712-8.703-18.807-14.333-27.542c-0.402-0.624-0.775-1.199-0.704-1.966
				c6.821,9.308,12.471,19.294,17.128,29.839c0.692,1.567,1.191,2.286,3.212,1.824c2.389-0.547,4.918-1.527,7.818-0.007
				c-2.306-6.314-4.83-12.046-7.506-17.704c-8.834-18.674-20.197-35.686-34.146-50.914c-10.799-11.79-22.528-22.569-35.932-31.404
				c0-0.48,0-0.96,0-1.44c3.378,1.985,6.341,4.536,9.369,6.983c8.749,7.071,17.624,14.007,25.5,22.079
				c12.593,12.905,22.531,27.739,31.403,43.35c5.535,9.739,10.502,19.752,14.313,30.287c0.717,1.979,2.746,1.021,3.41,2.438
				l0.03-0.038c-2.12,1.763-5.211,1.662-6.839-0.223c-2.217-2.567-6.843-2.599-9.072,0.046c-2.455,2.914-3.317,6.294-2.653,10.083
				c0.263,1.497,0.999,2.307,2.518,2.356c0.97,0.031,1.89,0.289,2.823,0.521c1.014,0.252,1.769,0.735,1.943,1.845
				c0.177,1.123-0.451,1.802-1.321,2.338c-0.827,0.51-1.564,1.07-1.91,2.059c-0.372,1.062-1.205,1.558-2.273,1.16
				c-1.197-0.444-1.622-1.43-1.474-2.705c0.076-0.649,0.816-0.997,0.691-1.862c-3.958,0.362-4.313-2.562-4.52-5.752
				c-1.338,0.946-1.126,1.913-0.983,2.807c0.295,1.835-0.032,3.473-1.226,4.948c-0.874,1.079-1.249,2.373-1.454,3.753
				c-0.45,3.02-1.519,3.839-4.521,3.546c-1.052-0.103-2.075-0.125-3.066,0.29c-0.545,0.228-1.016,0.567-0.955,1.227
				c0.061,0.652,0.577,0.959,1.155,1.046c1.751,0.263,3.517,0.264,5.253-0.065c1.859-0.353,3.446-0.234,4.66,1.51
				c0.776,1.114,2.078,1.443,3.396,1.523c1.355,0.082,2.261-0.1,2.151-1.948c-0.122-2.059,1.336-3.523,2.8-4.784
				c1.161-1.001,2.352-1.877,2.595-3.571c0.142-0.99,0.966-1.348,1.938-1.473c1.197-0.153,2.396-0.104,3.591-0.188
				c2.01-0.141,4.229-1.9,4.873-3.744c0.328-0.938-0.355-1.062-0.91-1.361c-2.257-1.214-2.469-1.737-1.637-4.136
				c0.341-0.98,0.723-1.952,1.22-2.865c0.324-0.596,0.842-1.009,1.572-0.831c0.825,0.2,1.312,0.889,1.067,1.617
				c-0.844,2.516,0.608,3.219,2.605,3.786c1.138,0.324,2.262,0.974,2.431,2.366c0.172,1.424-1.068,1.835-1.96,2.45
				c-1.26,0.869-2.46,1.777-3.229,3.152c-0.861,1.541-2.354,2.255-3.966,2.331c-2.051,0.096-3.685,0.555-4.884,2.403
				c-0.584,0.901-1.8,1.302-2.619,2.068c-0.573,0.536-1.378,1.107-0.929,2.001c0.405,0.804,1.257,0.515,1.941,0.303
				c0.851-0.265,1.574-0.189,2.14,0.564c0.725,1.11,0.111,1.788-0.8,2.301c-1.036,0.584-2.113,1.095-3.16,1.662
				c-2.31,1.252-2.68,2.069-1.862,4.626c0.379,1.188,0.866,2.423-0.396,3.219c-1.521,0.957-2.989,2.557-5.108,1.27
				c0.359-1.165,1.394-1.347,2.342-1.778c0.545-0.248,1.727-0.053,1.574-1.04c-0.124-0.799-0.458-1.941-1.303-2.138
				c-2.701-0.628-0.728-1.457-0.187-2.242c0.345-0.498,1.221-0.77,0.979-1.498c-0.266-0.8-1.098-0.323-1.674-0.439
				c-2.447-0.495-5.344,0.125-6.78-2.857c-0.322-0.669-1.187-0.579-1.751-0.402c-2.426,0.758-4.909,0.222-7.349,0.446
				c-1.838,0.169-2.38-0.611-2.335-2.345c0.019-0.744,0.629-1.929-0.538-2.298c-1.364-0.431-1.599,0.888-2.181,1.707
				c-1.68,2.364-4.082,3.949-6.406,5.584c-0.654,0.46-1.228,0.962-1.811,1.488c-0.416,0.375,0.766,1.194-0.392,1.232
				c-0.173,0.006-0.432-0.245-0.519-0.437c-0.274-0.61,0.149-1.187,0.491-1.468c1.451-1.194,3.006-2.263,4.516-3.386
				c1.335-0.994,2.453-2.193,3.644-3.706c-2.062-0.265-3.543,0.799-5.181,1.076c-0.606,0.103-1.201,0.653-1.76,0.597
				c-2.238-0.227-4.227,0.285-6.023,1.613c-1.015-1.39-0.008-2.278,0.891-3.026c1.649-1.371,3.338-2.724,5.334-3.344
				c-1.971,0.745-3.959,1.82-6.305,1.693c-3.14-0.17-4.241-2.084-2.804-4.849c0.859-1.653,2.53-2.34,4.102-3.678
				c-2.054,0.52-3.937,1.424-5.091-0.904c-0.262-1.037,0.139-1.727,1.096-2.128c0.153-0.036,0.316-0.059,0.454,0.002
				c1.433,0.624,3.088,0.112,3.365-0.996c0.29-1.163-1.616-0.623-2.408-1.159c-0.706-0.328-1.197,0.134-1.758,0.438
				c-2.694,1.459-4.628,0.359-4.5-2.756c0.102-2.468,0.457-4.927,0.382-7.411c-0.042-1.369-0.008-2.741-0.194-3.669
				c-0.512,3.057-0.221,6.559-0.359,10.049c-0.025,0.646-0.219,1.258-0.38,1.877c-0.225,0.859-0.391,1.771-1.472,1.967
				c-0.933,0.168-1.434-0.463-1.983-1.08c-0.715-0.804-1.365-1.753-3.083-2.032c2.295,3.5,4.773,6.024,8.535,6.982
				c0.749,0.19,1.632-0.067,2.151,0.763c0.627,1.343-0.121,2.195-1.273,2.163c-3.402-0.098-7.033,1.137-9.792-1.291
				c-0.569-0.317-0.01-0.391-0.246-0.174c-0.037,0.035,0.01,0.095,0.147,0.167c2.305,1.207,4.617,2.411,6.58,4.147
				c0.966,0.854,1.973,1.79,1.163,3.278c-0.729,1.339-1.931,1.133-3.161,0.911c-2.292-0.414-4.354-1.441-6.455-2.358
				c-0.769-0.336-1.788-0.63-1.642-1.716c0.155-1.154,1.217-1.062,2.086-1.141c0.235-0.021,0.473-0.04,0.755-0.134
				c-1.237-0.442-2.877-0.089-3.344-1.826c-0.465-2.34-4.233-4.398-6.421-3.507c-0.88,0.358-0.5,0.997-0.352,1.587
				c0.269,1.337,1.336,1.7,2.437,2.074c1.128,0.383,1.987,0.968,1.189,2.355c-3.076,0.656-5.991,0.336-8.666-1.433
				c0-0.96,0-1.92,0-2.88c1.866-0.961,0.366-1.921,0-2.881c0-0.479,0-0.96,0-1.439c1.635-0.801,0.604-1.6,0-2.4c0-0.32,0-0.64,0-0.96
				c0.869-0.908,1.341-2.577,3.165-1.654c1.487,4.467,5.478,4.077,8.984,4.612c0.39,0.059,0.86-0.01,0.999-0.453
				c0.176-0.565-0.262-0.869-0.693-1.079c-1.012-0.492-2.135-0.535-3.213-0.771c-1.955-0.428-4.167-0.147-5.7-1.873
				c-0.395-0.688-0.927-1.013-1.721-0.652c-0.757,0.344-1.336,0.068-1.82-0.529c0-0.32,0-0.641,0-0.96c1.451-0.96,1.375-1.921,0-2.881
				c0-0.159,0-0.319,0-0.479c1.504-1.985,3.669-2.417,5.663-2.486c-1.681-0.503-4.008,0.609-5.663-1.354c0-0.16,0-0.32,0-0.48
				c1.302-1.882,1.916-2.002,5.094-0.733c-2.176-1.055-4.038-1.77-5.094-3.586c0-0.32,0-0.641,0-0.96
				c0.874-1.159,1.99-0.979,2.941-0.291c3.557,2.575,6.7,1.286,9.802-0.88c1.495-1.044,2.949-2.136,4.338-3.321
				c0.711-0.606,1.464-1.356,2.474-0.437c0.343,2.821,2.679,3.16,4.759,3.832c1.14,0.369,2.283,0.737,3.268,1.466
				c1.298,0.96,1.839,2.054,0.519,3.374c-1.522,1.521-1.122,3.072-0.025,4.519c1.368,1.804,3.008,3.206,5.341,3.82
				c3.047,0.803,3,0.936,2.985,4.195c-0.006,1.319,0.912,2.174,1.688,3.076c1.396,1.622,3.245,2.866,4.036,4.966
				c-2.058-5.607-4.103-11.214-6.812-16.55c-2.574-5.069-5.359-10.007-8.709-14.605c-1.343-1.846-1.331-1.737-2.771-0.02
				c-1.042,1.242-1.95,3.131-4.196,2.011c-0.571-0.644-1.184-0.665-1.894-0.195c-0.533,0.352-1.15,0.563-1.778,0.672
				c-0.897,0.154-1.84,0.185-2.215-0.865c-0.352-0.983,0.39-1.428,1.138-1.799c0.28-0.14,0.588-0.217,0.664-0.261
				c-4.267-1.274-8.76-1.367-13.269-0.433c-1.001,0.207-1.69-0.189-2.282-0.915c0-0.32,0-0.64,0-0.96
				c2.748-1.756,5.81-2.186,8.985-2.109c1.108,0.026,2.222,0.204,3.282-0.277c1.775,0.021,3.219-0.711,4.665-1.725
				c1.95-1.366,3.909-2.725,5.86-4.092C39.178,391.042,40.159,390.392,39.836,389.009z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M549.699,768.317c-0.627,0.297-1.253,0.595-1.88,0.892
				c-0.638,1.373-1.677,1.476-2.991,1.055c-2.202-0.706-4.42-1.411-6.72-1.689c-1.583-0.191-2.038-0.752-1.11-2.141
				c0.267-0.399,0.506-0.814,0.691-1.256c0.066-0.242,0.155-0.479,0.247-0.713c0.496-0.893,1.344-1.384,2.262-1.6
				c1.859-0.438,3.575-1.189,5.254-2.057c0.723-0.333,1.284-0.901,1.914-1.358c-2.884-1.986-6.221-2.774-9.609-3.42
				c-4.335-0.537-8.664-1.183-12.367-0.935c3.735-0.345,8.08,0.426,12.439,0.979c2.657,0.351,5.188,1.151,7.621,2.234
				c1.466,0.653,1.83,1.522,0.12,2.41c-3.192,2.711-6.471,0.912-9.661,0.075c-7.245-1.901-14.399-1.625-21.333,1.076
				c-2.772,1.08-4.988,3.339-7.83,4.513c-1.596,0.66-2.204-0.016-2.887-1.081c-0.714-1.112-1.406-2.247,0.102-3.298
				c1.699-1.186,3.467-2.256,5.31-3.198c-1.813,0.914-3.554,1.963-5.216,3.129c-1.657,1.161-2.703,1.165-3.584-0.63
				c-0.572-1.167-1.552-1.403-2.381-2.021c-0.062-0.095-0.123-0.189-0.184-0.283c0.046-0.651-0.504-1.334,0.32-1.934
				c5.23-3.806,10.184-8.112,16.682-9.689c6.458-1.568,12.923-2.333,19.663-1.569c7.467,0.847,14.509,2.806,21.319,5.813
				c1.364,0.604,2.649,1.388,3.972,2.088c1.083-0.57,1.68-0.117,1.991,0.941c-0.021,1.094-0.885,1.107-1.643,1.347
				c-1.483,0.467-3.062,0.568-4.392,1.165c4.605-1.303,9.271-2.841,14.3-2.338c4.1,0.41,8.212-0.51,12.343,0.21
				c2.676,0.467,5.284,0.94,7.659,2.315c0.699,0.404,1.5,0.514,2.267,0.716c2.109,0.282,4.264,0.489,5.332,2.773c0,2.56,0,5.12,0,7.68
				c-3.803-2.106-7.711-3.965-11.853-5.337c-10.148-3.361-20.067-2.654-29.795,1.582C553.839,765.737,551.35,766.285,549.699,768.317z
				"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M53.624,536.18c1.764-0.699,3.106-2.218,5.038-2.589
				c2.157-1.992,4.761-3.363,7.188-4.951c2.346-1.534,6.233-0.226,7.265,2.033c-0.53,2.591-2.77,3.694-4.538,5.206
				c-0.119,0.099-0.242,0.198-0.367,0.294c-2.756,2.107-2.749,2.166,0.441,3.523c1.812,0.772,2.241,1.661,1.188,3.284
				c-1.065,1.641-2.068,3.287-2.771,5.115c-2.466,2.338-5.19,4.27-8.453,5.354c-3.325-0.345-6.374,1.082-9.581,1.491
				c-1.405,0.18-2.808,0.573-4.132-0.288c-0.531-0.776-0.365-1.466,0.224-2.149c0.456-0.528,1.021-1.053,0.651-1.873
				c-0.671-0.61-1.409-1.264-2.311-1.146c-3.617,0.477-6.357-1.561-9.292-3.071c-2.249-1.157-4.795-0.904-7.167-1.5
				c-2.554-0.641-5.052-1.414-7.401-2.618c-1.495-0.768-1.776-1.899-1.082-3.398c0.493-1.062,1.229-2.177-0.046-3.234
				c-0.477-0.81-1.438-1.112-1.962-1.867c-0.218-0.441-0.255-0.937,0.049-1.301c0.936-1.119,0.527-2.104-0.204-3.09
				c-0.299-0.613-0.457-1.293-0.879-1.846c0-2.079,0-4.16,0-6.239c1.403-0.659,1.803-1.667,1.099-3.085
				c-0.429-0.864-0.366-1.934-1.099-2.676c0-1.76,0-3.52,0-5.279c1.935-0.952,2.85,1.146,4.362,1.458l-0.068-0.056
				c2.368,1.388,3.866,3.76,6.06,5.344c2.047,1.645,2.085,2.177,0.214,3.056c-0.525,1.062,0.047,1.573,0.922,2.068
				c2.208,1.249,4.651,1.701,7.092,2.004c2.781,0.345,4.938,1.14,5.214,4.396c0.107,1.263,0.979,1.909,2.211,2.146
				c1.254,0.241,2.498,0.446,3.786,0.493c1.655,0.061,2.404,1.312,1.747,2.813c-0.661,1.509-1.856,2.774-1.89,4.571
				c-0.012,0.679-0.761,0.687-1.285,0.855c-3.488,1.126-7.083,0.875-10.656,0.797c-0.648-0.015-1.538-0.274-1.577-1.168
				c-0.038-0.849,0.786-0.843,1.382-1.032c0.688-0.218,1.371-0.431,2.126-0.434c0.267,0.115-0.033-0.347-0.058-0.029
				c-0.003,0.041-0.296,0.027-0.456-0.006c-0.657-0.14-0.629-1.172-1.444-1.105c-0.226-0.228-0.043,0.293,0.097,0.006
				c0.075-0.154,0.107-0.088,0.243,0.037c0.821,0.756,0.539,1.194-0.384,1.497c-0.644,0.211-1.63,0.153-1.48,1.18
				c0.121,0.821,0.987,1.062,1.672,1.076c3.736,0.078,7.486,0.242,11.12-0.951C47.504,538.286,50.573,537.26,53.624,536.18z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M597.72,686.92c0,3.04,0,6.08,0,9.12c-0.906,0.9-2.001,0.75-3.137,0.645
				c-4.292-0.396-8.339-2.188-12.614-2.418c-3.604-0.194-7.15-0.781-10.855-0.532c-5.308,0.358-10.436,1.73-15.753,2.019
				c-3.798,0.205-7.682,1.178-10.881,3.716c-1.763,1.398-3.957,2.06-5.915,3.137c-3.653,2.011-6.753,4.636-9.903,8.242
				c5.675,0.325,10.872,0.822,16.027,1.731c5.241,0.925,9.737,3.845,14.854,5.016c0.232,0.116,0.458,0.246,0.688,0.369
				c3.844,4.405,9.683,6.34,13.453,10.824c0.876,1.042,2.739,1.729,2.191,3.391c-0.545,1.652-2.47,1.109-3.79,1.464
				c-1.425,0.382-2.671-0.106-3.554-1.358c-1.132-1.605-2.66-2.724-4.359-3.678c-3.479-1.952-6.895-4.008-10.263-6.15
				c-6.332-4.027-13.608-4.938-20.821-5.948c-9.212-1.29-18.332-0.126-27.432,1.225c-2.312,0.343-4.437,1.344-6.596,2.178
				c-1.382,0.535-2.667,0.39-3.946-0.192c-1.805-0.82-1.9-1.791-0.224-2.884c3.471-2.262,7.371-3.463,11.375-4.297
				c4.07-0.847,8.188-1.468,12.365-1.36c1.448,0.038,2.429-0.316,2.893-1.85c0.644-2.121,2.751-3.3,3.612-5.311
				c2.033-1.577,3.752-3.44,5.082-5.646c0.502-0.832,1.113-1.561,1.855-2.179c8.719-7.269,18.823-11.222,30.039-12.429
				c6.843-0.736,13.687-0.374,20.553-0.15c3.724,0.122,7.311,1.216,11.044,0.969C595.466,684.465,596.578,685.797,597.72,686.92z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M597.72,435.88c-0.628-0.21-1.084,0.173-1.526,0.491
				c-1.534,1.105-3.069,0.762-4.051-0.54c-3.753-4.975-9.134-6.588-14.865-7.664c-3.304-0.62-5.892-2.727-8.635-4.484
				c-1.056-0.676-0.705-1.648-0.573-2.588c0.153-1.091,0.84-2.143-1.326-2.365c-1.325-0.137,0.128-1.868,0.024-3.089
				c1.765,1.267,3.519,2.548,5.299,3.791c0.548,0.383,1.096,1.157,1.866,0.512c0.625-0.523,0.238-1.2-0.063-1.747
				c-1.85-3.354-2.361-7.415-5.355-10.165c-0.406-0.374-0.105-0.704,0.035-1.054c0.208-0.516,0.689-1.132,0.556-1.54
				c-1.029-3.125,1.25-3.522,3.287-4.222c2.11-0.725,4.194-1.529,6.278-2.331c0.923-0.355,1.767-0.445,2.764-0.103
				c1.5,0.516,3.075,0.826,4.632,1.16c3.04,0.654,6.071,1.359,9.13-0.06c0.748-0.347,1.685-0.586,2.524-0.003
				C597.72,411.88,597.72,423.88,597.72,435.88z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M48.146,549.427c0.979,0.66,0.574,1.307-0.067,1.877
				c-0.92,0.82-1.884,1.591-2.83,2.382c-0.12,2.114,1.55,1.287,2.298,1.136c3.199-0.646,6.417-1.264,9.53-2.274
				c0.605-0.196,1.232-0.303,1.826-0.528c2.569-1.152,5.134-2.31,6.934-4.616c1.453-1.763,3.062-3.079,5.529-1.923
				c0.084,0.086,0.168,0.173,0.252,0.26c0.03,0.073,0.041,0.168,0.093,0.218c2.76,2.677,2.636,4.143-0.57,6.509
				c-0.501,0.37-0.999,0.771-1.557,1.027c-0.662,0.304-1.089,0.722-0.826,1.431c0.189,0.511,0.604,0.929,1.275,0.889
				c1.741-0.104,3.383,0.691,5.155,0.502c2.163-0.231,3.924,0.493,4.867,2.608c0.915,2.054-0.734,3.013-1.829,4.207
				c-0.213,0.232-0.39,0.497-0.583,0.747c-2.046,1.215-3.447,3.949-6.512,2.761c-0.792,0.548-3.031-0.438-2.09,1.982
				c0.059,0.151-0.291,0.66-0.485,0.681c-1.883,0.196-3.592,1.195-5.542,1.113c-1.554-0.065-1.779-0.996-1.772-2.244
				c0.004-0.949-0.224-1.707-1.373-0.854c-3.163,2.349-6.221,0.625-9.384,0.072c0.321-1.35,1.505-1.468,2.012-2.256
				c0.326-1.256,1.994-0.754,2.436-1.858c-1.093-1.288-2.539-0.773-3.752-0.494c-1.623,0.373-1.564-0.179-1.167-1.367
				c0.445-1.333,0.77-2.706,1.19-4.048c0.211-0.675,0.604-1.424-0.233-1.84c-0.797-0.396-1.231,0.199-1.632,0.876
				c-1.121,1.894-2.318,3.742-3.48,5.611s-7.953,2.226-9.09,0.399c-0.337-0.542-0.178-1.386-0.274-2.088
				c-0.085-0.617,0.349-1.547-0.866-1.531c-2.399,0.359-4.794,0.184-7.189-0.045c-1.147-0.839-3.146-1.154-2.073-3.362
				c-0.535-1.873,0.104-2.739,2.102-2.484c2.301,0.294,4.458-0.988,6.77-0.611c-4.447,0.207-9.006-0.377-13.272,1.191
				c-2.37,0.872-4.331-0.354-6.487-0.481c0-6.72,0-13.44,0-20.16c0.75-0.44,1.204,0.116,1.706,0.508
				c0.704,0.219,1.407,0.438,2.11,0.656c0.468,0.58,0.406,1.397,0.798,1.973c0.979,1.438,1.11,2.427-0.778,3.316
				c-1.592,0.749-0.85,1.879,0.235,2.489c2.087,1.176,4.371,1.895,6.728,2.329c2.265,0.417,4.554,0.708,6.798,1.231
				c1.55,0.361,2.974,0.937,4.291,1.945c2.168,1.662,4.812,1.743,7.436,1.73C45.929,549.013,47.134,548.469,48.146,549.427z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M15.48,215.08c1.395-0.206,2.013,0.552,1.793,1.784
				c-0.292,1.646,0.367,2.151,1.871,2.042c0.95-0.069,1.905-0.152,2.854-0.117c1.094,0.041,1.78,0.991,2.177,1.719
				c0.329,0.603-0.435,2.001,0.292,2.392c0.974,0.522,2.383,0.842,3.392,0.539c1.345-0.402,1.794,0.388,2.34,1.059
				c9.848,12.11,18.812,24.82,25.621,38.917c4.69,9.708,7.897,19.902,9.745,30.567c0.619,3.572,0.108,6.716-1.367,9.777
				c-0.378,0.785-0.067,1.296-0.32,2.013c-1.6-4.825-1.224-10.102-3.509-14.912c-0.897,0.887-1.001,1.974-1.525,2.788
				c-1.198,1.862-0.714,4.33-2.307,6.006c-0.055,0.058-0.109,0.117-0.163,0.176c1.851,1.059,0.241,2.15-0.046,3.632
				c-1.502-3.861-2.39-7.54-3.439-11.172c-2.251-7.788-2.721-15.965-5.249-23.692c-3.117-9.529-7.961-18.159-13.75-26.272
				c-4.277-5.995-8.718-11.874-13.859-17.184c-1.53-1.581-2.608-3.591-4.551-4.782C15.48,218.6,15.48,216.84,15.48,215.08z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M15.48,222.28c5.049,4.536,9.224,9.84,13.437,15.127
				c10.934,13.72,17.041,29.456,19.792,46.636c0.239,1.495,0.512,3.016,1.03,4.429c1.389,3.787,1.205,7.466-0.145,11.227
				c-0.511,1.425-0.51,3.035-0.792,4.885c-7.006-7.961-10.539-17.064-10.523-27.363c-1.227-0.011-1.189,0.516-1.376,0.815
				c-0.206,0.328-0.313,0.717-0.672,1.584c-0.587-2.349-1.111-4.266-1.542-6.204c-2.504-11.251-6.281-21.965-13.277-31.301
				c-1.815-2.422-3.668-4.83-5.931-6.875C15.48,230.92,15.48,226.6,15.48,222.28z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M597.72,697c0,2.72,0,5.439,0,8.16c-1.601,1.254-3.47,0.679-5.044,0.307
				c-3.302-0.779-6.601-1.482-9.921-2.144c-4.132-0.821-8.081-1.001-12.059-0.146c-3.7,0.796-7.421,1.436-11.08,2.651
				c-3.663,1.218-7.41,2.235-11.026,3.654c-0.984,0.387-1.969,0.775-2.709,1.496c-2.085,2.029-4.486,1.979-7.114,1.466
				c-2.655-0.519-5.341-0.961-8.079-0.816c-0.856,0.046-1.945,0.237-2.298-0.798c-0.347-1.019,0.37-1.885,1.129-2.461
				c2.475-1.877,4.811-3.922,7.365-5.708c8.651-6.046,18.458-8.252,28.754-9.323c9.975-1.037,19.706-0.14,29.342,2.403
				C595.954,695.999,597.043,696.068,597.72,697z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M582.562,661.866c1.158,1.21,2.618,1.932,4.143,2.549
				c0.112-0.012,0.225-0.024,0.337-0.036c5.166,2.342,8.894,6.02,10.678,11.501c0,0.16,0,0.32,0,0.48
				c-0.85,1.391-1.728,1.708-2.968,0.32c-6.244-6.985-10.039-9.611-17.552-12.538c6.093,1.945,11.756,6.108,16.622,11.447
				c0.678,0.744,1.706,1.451,0.686,2.619c-0.885,1.014-2.78,1.04-3.567-0.223c-4.46-7.144-11.878-8.805-19.254-10.19
				c-4.542-0.854-9.146-0.21-13.723,1.054c-4.492,1.239-8.805,2.762-12.99,4.772c-3.184,1.53-6.203,3.312-9.053,5.371
				c-1.017,0.734-2.006,1.26-3.267,1.221c-0.721-0.297-1.361-0.7-1.79-1.375c-0.452-1.222-0.229-2.164,0.751-3.168
				c2.817-2.883,5.702-5.67,9.022-7.973c4.548-3.412,9.641-5.403,15.402-5.649c-5.765,0.244-10.857,2.24-15.408,5.653
				c-3.474,2.415-6.527,5.295-9.379,8.402c-0.758,0.826-1.474,1.862-2.703,0.608c-0.118-0.217-0.132-0.441-0.058-0.674
				c-1.367-0.664-0.963-1.456-0.326-2.478c2.817-4.516,6.933-7.688,11.038-10.883c2.328-1.811,4.969-3.031,7.683-4.128
				c0.442-0.18,1.214,0.035,1.221-0.821l-0.067,0.059c4.255-0.967,8.544-1.686,12.92-1.18c4.937,0.571,9.905,0.959,14.735,2.245
				c0.275-0.293,0.667-0.639,1.001-0.476C578.746,659.376,581.528,659.166,582.562,661.866z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M15.48,636.52c12.544-6.181,23.82-2.529,34.665,4.473
				c4.667,3.013,8.654,6.768,12.102,11.106c0.388,0.488,0.968,0.929,0.915,1.63c-0.068,0.907-0.479,1.778-1.163,2.295
				c-0.895,0.676-1.432-0.342-1.967-0.812c-8.148-7.16-17.638-10.991-28.797-11.585c1.334,0.831,2.595,0.7,3.737,0.971
				c1.163,0.276,2.352,0.443,3.53,0.65c8.446,1.484,15.501,5.659,21.835,11.25c0.665,0.587,1.208,1.098,0.51,1.969
				c-0.616,0.769-0.958,0.001-1.326-0.297c-4.779-3.863-10.573-5.473-16.291-7.119c-8.643-2.489-17.316-2.193-25.906,0.477
				c-0.599,0.187-1.169,0.556-1.845,0.354C15.48,646.76,15.48,641.641,15.48,636.52z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M570.874,606.226c-0.486,0.028-0.972,0.057-1.458,0.085
				c-2.598-0.765-4.404-2.44-5.576-4.848c0.385-2.116,2.312-2.375,3.825-3.035c0.604-0.264,1.33-0.479,2.042-0.519
				c0.231,0.046,0.465,0.101,0.692,0.16c1.418,0.616,2.687-0.334,4.05-0.328c-1.279-0.147-2.41,0.946-3.74,0.378
				c-1.471-0.189-1.724-1.725-2.713-2.446c-0.11-0.116-0.247-0.22-0.312-0.359c-2.266-4.812-6.9-5.544-11.347-6.644
				c-4.438-1.098-8.537,0.03-12.546,1.79c-0.757,0.333-1.515,0.444-2.312,0.459c-0.117-0.011-0.234-0.021-0.352-0.033
				c-1.002-0.136-1.443,0.854-2.154,1.113c-1.055,0.042,0.604-1.6-0.87-1.217c-0.525,0.138-1.138,0.483-1.367-0.274
				c-0.197-0.651,0.605-0.842,0.925-1.232c0.966-1.18,2.715-1.547,3.351-3.117l-0.075,0.082c2.451-0.792,4.246-2.929,7.007-3.087
				c-0.439-1.12-1.087-0.089-1.564-0.673c1.815-2.437,4.629-1.221,6.937-2.204c-1.359,0-2.721,0.04-4.077-0.02
				c-0.567-0.024-1.433,0.151-1.396-0.848c0.032-0.839,0.802-0.574,1.29-0.702c2.821-0.74,5.609,0.236,8.689,0.176
				c-2.548-1.335-5.054-0.734-7.473-0.896c-0.76-0.05-1.969,0.431-2.036-0.963c-0.079-1.619,1.256-1.07,2.077-1.127
				c1.578-0.11,3.218-0.32,4.418,1.138c0.345,0.419,1.012,0.554,1.304,0.28c2.669-2.498,5.256-0.227,7.863,0.087
				c0.372,0.044,0.714,0.328,1.114,0.094c1.094-0.305,1.775,0.079,2.013,1.203c0.3,1.531-0.938,2.003-1.888,2.598
				c-0.976,0.611-2.148,0.898-2.937,1.777c2.028,1.757,4.585,2.491,6.891,3.687c0.339,0.176,0.75,0.215,1.038,0.507
				c0.477,0.601,1.017,1.107,1.823,1.215c4.308-0.09,8.617-0.56,12.907,0.22c0.867,0.157,1.747,0.237,2.504,0.749
				c1.887,1.756,4.708,2.281,6.136,4.642c0.124,1.379-0.802,2.104-1.822,2.77c0.935-0.525,1.408-1.435,2.058-2.195
				c0.991-1.162,2.095-2.4,3.908-1.346c0,3.68,0,7.359,0,11.04c-0.708,1.206-1.645,1.714-3.099,1.298
				c-1.107-0.316-2.301-0.282-3.438-0.536c-3.854-0.571-7.663-0.186-11.457,0.518c-0.236,0.066-0.477,0.119-0.717,0.163
				c-2.606,0.073-5.113,1.583-7.799,0.546C571.099,606.309,570.986,606.268,570.874,606.226z"/>
			<path fill-rule="evenodd" d="M525.7,704.668c-0.417,1.354-1.82,2.056-2.295,3.29c-0.363,0.946-2.772,1.873-0.298,3.187
				c0.381,0.201-0.297,0.408-0.438,0.488c-0.805,0.455-1.713,0.236-2.583,0.258c-7.687,0.185-15.24,1.083-22.342,4.285
				c-1.193,0.538-2.298,1.271-3.683,2.049c1.983,1.13,3.477,1.656,5.512,0.602c4.274-2.212,9.073-2.672,13.768-2.941
				c4.522-0.26,9.041-0.774,13.598-0.58c11.445,0.489,22.498,2.283,32.051,9.244c3.737,2.724,8.354,4.228,11.243,8.14
				c0.422,0.572,4.79-0.037,5.142-0.661c0.449-0.8-0.354-1.129-0.756-1.464c-3.657-3.04-7.313-6.084-11.278-8.73
				c-1.364-0.91-2.704-1.857-4.056-2.789c0.138-0.294,0.319-0.627,0.628-0.677c9.036-1.446,18.076-2.68,27.116-0.024
				c0.997,0.292,1.923,0.76,2.839,1.239c0.631,0.33,0.905,0.863,0.812,1.571c-0.706,1.631-1.95,1.052-3.052,0.66
				c-5.452-1.936-11.09-2.219-16.794-1.979c-0.802,0.033-1.587,0.191-2.364,0.377c-0.669,0.159-1.597,0.113-1.567,1.11
				c0.024,0.817,0.789,1.145,1.447,1.441c0.897,0.405,1.865,0.397,2.826,0.405c2.398,0.018,4.797-0.062,7.192,0.052
				c1.019,0.049,2.158,0.006,2.493,1.364c-0.163,1.32-1.222,1.305-2.161,1.388c-1.354,0.119-2.718,0.021-4.076,0.077
				c-0.394,0.016-0.862,0.002-1.056,0.415c-0.206,0.44,0.187,0.718,0.439,1.016c3.854,4.546,11.126,3.804,13.185-0.7
				c0.355-0.481,0.902-0.612,1.429-0.796c1.776-0.62,3.132-1.438,1.701-3.601c-0.169-0.256,0.014-0.616,0.193-0.892
				c0.378-0.545,1.022-1.147,1.527-0.742c1.694,1.363,3.861,0.901,5.677,1.691c0,0.319,0,0.64,0,0.96
				c-0.738,1.932-2.577,2.595-4.14,3.565c-1.142,0.71-2.334,1.342-3.397,2.17c-0.415,0.323-1.079,0.65-0.808,1.282
				c0.254,0.593,0.963,0.403,1.499,0.416c1.065,0.024,2.014-0.323,2.873-0.951c0.776-0.567,1.562-0.795,2.303,0.073
				c0.129,1.554,0.387,2.024,1.67,3.044c0,0.32,0,0.64,0,0.96c-0.668,0.896-1.563,0.97-2.571,0.756
				c-12.709-2.695-24.887,0.291-37.041,3.489c-2.556,0.672-4.854,2.042-7.382,2.787c-0.699,0.207-0.82,0.952-1.357,1.295
				c-1.384,0.46-2.493,0.134-3.27-1.138c-0.19-1.205,0.799-1.358,1.55-1.777c1.723-0.961,1.76-1.705,0.022-2.644
				c-3.246-1.754-6.718-2.973-10.279-3.894c-6.204-1.604-12.518-2.478-18.935-2.458c-0.975,0.003-2.656-0.265-2.649,0.849
				c0.009,1.41,1.721,1.052,2.744,1.034c3.528-0.059,7.013,0.179,10.464,0.957c1.256,0.282,2.553,0.517,3.612,1.219
				c2.876,1.908,6.131,2.564,9.421,3.273c1.352,0.291,3.494,0.182,3.109,2.699c-0.542,1.144-1.445,0.928-2.447,0.767
				c-3.773-0.606-7.488-1.58-11.339-1.712c-4.648-0.158-9.248,0.078-13.732,1.418c-1.257,0.376-2.358,0.407-3.171-0.816
				c0.59-6.476-4.462-9.522-8.378-12.918c-5.939-5.149-13.312-7.524-20.994-8.749c-9.683-1.543-18.903,0.569-27.933,3.888
				c-0.976,0.357-1.942,0.739-2.903,1.137c-0.921,0.381-1.717,0.297-2.32-0.573c-2.042-4.526-5.891-6.832-10.394-8.144
				c-7.123-2.074-13.786-0.671-20.064,3.045c6.619-3.201,13.456-2.768,20.355-1.218c2.31,0.519,4.304,1.722,6.111,3.18
				c0.789,0.637,1.979,1.378,0.95,2.75l0.043-0.044c-0.725,0.893-1.487,0.704-2.295,0.108c-4.326-3.195-9.43-3.746-14.537-4.086
				c-2.406-0.159-4.714,0.718-6.945,1.616c-0.729,0.293-1.626,0.543-1.606,1.537c0.017,0.879,0.882,1.026,1.5,1.359
				c0.209,0.113,0.37,0.301,0.544,0.465c0.991,2.183,2.655,2.659,4.86,2.036c1.777-0.502,3.623-0.213,5.424-0.118
				c1.497,0.078,2.429,0.823,1.527,2.521c-1.066,0.722-2.291,0.521-3.465,0.565c-1.996,0.075-3.953,0.296-5.763,1.229
				c-0.973,0.542-1.64-0.042-2.332-0.611c-3.618-2.975-6.875-6.363-10.595-9.227c-0.842-0.648-1.8-1.455-1.161-2.812
				c4.972-1.562,9.249-4.919,14.469-5.823c9.293-1.61,17.78-0.157,24.327,6.594c-2.275-3.062-6.066-4.596-9.847-6.142
				c-0.591-0.241-1.228-0.376-1.802-0.646c-0.933-0.44-2.557-0.493-2.021-2.077c0.459-1.356,1.827-1.001,2.918-0.763
				c5.537,1.211,10.331,3.717,13.988,8.153c1.056,1.28,2.223,2.081,3.928,1.244c0.755-0.484,1.625-0.636,2.469-0.863
				c1.993-0.538,2.271-1.198,0.852-2.738c-2.65-2.877-5.393-5.678-8.321-8.272c-4.186-3.708-8.553-7.188-13.364-10.074
				c-0.946-0.568-1.789-1.314-1.094-2.507c0.642-1.101,1.678-0.69,2.55-0.259c4.622,2.29,8.564,5.532,12.395,8.925
				c4.242,3.758,8.474,7.528,12.016,11.99c0.287,0.362,0.61,0.75,1.38,0.742c0.031-1.771-2.916-2.651-1.544-4.739
				c1.061-0.89,1.894-0.343,2.719,0.414c0.89,0.815,1.545,1.827,2.334,2.726c0.811,0.922,1.69,1.518,2.827,0.445
				c4.428,1.685,9.216,1.314,13.752,2.391c0.934,0.221,1.879,0.412,2.832,0.484c0.978,0.073,2.133,0.01,2.326-1.148
				c0.171-1.021-0.995-1.066-1.688-1.352c-1.477-0.607-3.007-1.073-4.52-1.586c-0.801-0.271-1.56-0.659-1.313-1.676
				c0.235-0.968,1.103-1.123,1.922-1.03c3.285,0.374,6.384,1.357,9.319,2.899c2.216,1.164,4.317,0.559,5.443-1.465
				c0.672-1.497,1.743-0.923,2.839-0.612c3.386,0.96,6.694,0.317,9.974-0.605c4.693-0.208,4.709-0.221,5.818-4.943
				c0.076-0.321,0.297-0.553,0.517-0.787c1.156-0.867,2.116-0.326,3.045,0.425C525.595,704.439,525.647,704.554,525.7,704.668z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M597.72,461.32c-3.937-3.586-8.978-4.643-13.857-6.089
				c-2.593-0.767-5.202-1.544-7.58-3.378c1.854-0.903,3.479-1.885,3.957-3.945c0.234-1.017,0.184-1.679-1.18-1.685
				c-1.856-0.008-3.398-0.682-4.229-2.543c-0.289-0.646-0.828-1.353-1.565-1.356c-1.373-0.007-2.053-0.53-2.097-1.913
				c-0.006-0.184-0.296-0.527-0.428-0.515c-2.99,0.289-2.562-2.365-3.318-3.921c-0.573-1.175-0.865-2.302-2.623-2.159
				c-1.464,0.119-1.152-1.156-1.126-2.355c2.786,1.453,4.618-0.011,6.006-2.084c1.077-1.609,2.028-1.702,3.662-0.79
				c2.6,1.45,5.599,1.773,8.46,2.454c2.525,0.601,5.053,1.401,6.651,3.496c2.396,3.14,5.324,4.688,9.268,4.223
				C597.72,446.28,597.72,453.8,597.72,461.32z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M597.72,278.44c-7.27,8.141-15.192,15.732-21.103,25.019
				c-3.478,5.464-6.465,11.177-8.721,17.261c-0.206,0.557-0.205,1.267-1.072,1.677c-0.545-1.786-0.209-3.823-1.653-5.278
				c-0.371-0.374,0.14-0.859,0.4-1.259c10.383-15.906,15.217-33.894,19.582-52.068c1.265-5.264,0.295-3.975,5.193-5.009
				c2.396-0.506,4.881-0.86,7.373-0.501C597.72,265,597.72,271.72,597.72,278.44z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M597.72,396.52c-2.489,2.478-5.589,2.831-8.798,2.244
				c-3.046-0.557-6.03-1.452-9.052-2.153c-0.98-0.229-1.608-0.577-1.374-1.753c0.134-0.668-0.088-1.302-0.8-1.564
				c-1.361-0.503-1.178-1.799-1.428-2.823c-0.266-1.085,0.207-1.536,1.31-1.011c1.224,0.581,2.441,1.175,3.673,1.737
				c0.744,0.339,1.498,0.366,1.682-0.618c0.274-1.475,0.898-3.129,0.49-4.429c-0.589-1.876-1.176-3.578-0.567-5.542
				c0.202-0.656-0.227-1.582-0.952-1.184c-2.722,1.494-2.169-1.115-3.366-2.422c2.179,0.248,3.448,1.399,4.96,1.872
				c1.803,0.564,3.838,1.739,5.401,0.25c1.801-1.715,0.349-3.732-0.515-5.492c-1.021-2.077-2.154-4.098-3.246-6.139
				c-0.414-0.774-0.78-1.558-1.855-1.657c-1.642-0.151-1.439-1.51-1.399-2.545c0.059-1.497,1.342-0.793,2.042-0.819
				c3.402-0.128,3.486-0.167,3.129-3.562c-0.1-0.951-0.296-1.892-0.373-2.844c-0.102-1.256-0.708-1.822-1.886-1.272
				c-1.615,0.753-1.961,0.172-1.797-1.377c0.196-1.846,0.067-2.146-0.857-4.322c4.803,5.062,10.052,9.605,15.579,13.827
				C597.72,374.12,597.72,385.32,597.72,396.52z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M15.48,652.84c7.805-2.816,15.779-3.951,23.947-1.979
				c6.267,1.513,12.528,3.135,18.051,6.694c0.467,0.301,0.954,0.592,1.354,0.97c0.577,0.546,1.287,1.179,0.708,2.048
				c-0.571,0.857-1.282,0.191-1.904-0.07c-8.201-3.44-16.761-4.491-25.577-3.877c-1.416,0.099-2.834,0.148-4.247,0.483
				c3.625,0,7.25-0.071,10.874,0.013c13.178,0.302,24.436,5.461,34.411,13.775c0.458,0.381,1.151,0.831,1.018,1.529
				c-0.169,0.881-1.063,0.423-1.612,0.627c-2.134,0.792-3.656,0.292-5.473-1.343c-6.353-5.714-14.146-8.2-22.548-8.729
				c-3.819-0.242-7.551-1.301-11.416-1.035c-6.056,0.416-11.988,1.364-17.585,3.854c0-1.76,0-3.52,0-5.28
				c2.362-1.155,4.924-1.741,7.011-2.503c-2.169,0.068-4.236,2.398-7.011,1.543C15.48,657.32,15.48,655.08,15.48,652.84z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M549.626,741.792c-0.682-1.588,0.775-1.704,1.57-2.116
				c5.67-2.938,11.88-4.076,18.038-5.457c9.498-2.13,18.969-2.466,28.485-0.259c0,1.92,0,3.84,0,5.76
				c-2.4,0.84-4.692-0.862-6.676-0.272c1.999,0.143,4.473-0.072,6.676,1.232c0,1.44,0,2.88,0,4.32
				c-1.275,0.982-2.619,0.193-3.916,0.103c-2.578-0.179-5.167-0.285-7.687-1.029c-1.381-0.408-2.854-0.284-4.293-0.164
				c-5.897,0.491-11.66,1.521-17.141,3.906c-2.407,1.048-6.582-0.485-8.04-2.677c-0.896-1.348,0.178-1.598,1.052-2.01
				c4.229-1.996,8.711-2.985,13.341-3.406c1.823-0.166,3.651-0.253,5.474-0.453c1.107-0.121,2.235-0.024,3.354-0.029
				c-1.073,0.003-2.149-0.066-3.217,0.018c-6.43,0.501-12.866,0.934-18.836,3.792c-1.144,0.548-2.092,1.498-3.411,1.688
				c-1.344-0.165-2.428-1.064-3.766-1.312C549.89,743.287,549.482,742.635,549.626,741.792z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M23.271,624.708c3.866-0.007,7.688-0.605,11.61,0.019
				c5.297,0.842,9.975,2.979,14.493,5.652c0.433,0.256,0.832,0.44,1.319,0.461c0.637-0.231,1.086-0.135,1.14,0.652
				c2.474,2.3,5.053,4.498,7.394,6.926c2.822,2.926,4.932,6.367,6.506,10.131c0.68,1.625-0.486,2.77-1.368,3.491
				c-0.911,0.747-1.37-0.688-1.961-1.356c-4.229-4.778-8.899-9.065-14.537-12.141c-4.104-2.239-8.325-4.255-12.941-5.268
				c-6.747-1.479-13.186-0.358-19.446,2.284c0-2.72,0-5.439,0-8.159c1.427-2.226,3.844-2.673,6.112-3.358
				C22.243,623.846,22.849,624.138,23.271,624.708z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M15.48,609.64c4.639-2.238,9.521-3.26,14.672-2.948
				c0.547,0.033,1.025,0.02,1.417,0.624c0.717,1.105,1.601,1.107,2.532,0.113c1.33-1.42,2.822-0.642,4.344-0.332
				c1.57,0.321,0.824,1.446,1.018,2.25c0.23,0.951,0.067,2.184,1.195,2.591c1.2,0.433,2.098-0.34,2.874-1.202
				c0.373-0.415,0.777-0.805,1.184-1.188c0.512-0.48,1.177-0.689,1.745-0.297c0.659,0.457-0.003,1.057-0.174,1.476
				c-0.651,1.604-0.203,3.315-0.581,5.001c-1.903-0.351-3.378-1.893-5.435-1.64c3.425,1.49,6.671,3.281,9.777,5.326
				c0.788,0.519,1.647,0.942,2.388,1.519c2.287,1.777,2.215,2.646-0.119,4.454c-0.638,0.495-0.958,0.432-1.616,0.016
				c-6.175-3.889-12.98-6.059-20.141-7.23c-3.594-0.588-6.863,0.589-10.116,1.839c-1.678,0.645-3.091,1.918-4.964,2.109
				C15.48,617.96,15.48,613.8,15.48,609.64z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M597.72,311.08c-0.81,0.001-1.416-1.36-2.341-0.417
				c-0.84,0.856-0.867,1.954-0.202,2.852c0.759,1.025,0.491,1.888,0.066,2.884c-0.53,1.243-1.052,2.502-1.418,3.8
				c-0.608,2.16-2.305,3.718-3.465,5.44c-2.727,4.048-6.381,7.471-9.894,11.409c0.213-2.117,1.154-3.623,1.816-5.188
				c4-9.472,5.363-19.473,6.146-29.605c0.633-8.201,3.152-15.597,9.291-21.415c0,5.12,0,10.24,0,15.36
				c-1.124,0.929-0.951,2.173-0.726,3.365c0.213,1.122,0.318,2.273,0.726,3.355C597.72,305.64,597.72,308.36,597.72,311.08z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M597.72,706.12c0,2.88,0,5.76,0,8.64c-1.615,0.878-3.155,0.288-4.511-0.497
				c-2.243-1.299-4.611-2.046-7.137-2.575c-4.061-0.851-8.189-1.668-12.271-1.464c-3.255,0.162-6.548,1.061-9.829,1.539
				c-3.416,0.497-6.146,2.587-9.346,3.554c-1.065,0.321-1.961,0.445-2.989,0.086c-2.257-0.789-4.588-1.314-6.919-1.823
				c-0.573-0.125-1.183-0.179-1.306-0.819c-0.122-0.633,0.448-0.96,0.878-1.316c2.952-2.441,6.466-3.735,10.031-4.936
				c7.062-2.377,14.271-4.205,21.741-4.294C583.488,702.125,590.811,703.108,597.72,706.12z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M590.456,721.507c-2.023-2.081-4.765-2.425-7.387-2.833
				c-6-0.935-12.074-1.395-17.987,0.29c-2.059,0.586-3.844,1.042-5.798,0.08l0.088,0.074c-1.491-0.916-3.08-1.65-4.636-2.443
				c-1.304-0.666-1.215-1.237-0.07-2.003c2.279-1.526,4.809-2.482,7.412-3.212c7.424-2.084,14.965-3.115,22.562-1.205
				c4.598,1.156,9.193,2.516,13.079,5.465c0,2.24,0,4.48,0,6.721c-1.865,1.45-3.659,1.741-5.399-0.233
				c-0.483-0.547-1.159-0.846-1.938-0.762L590.456,721.507z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M597.72,615.4c-1.797,1.48-3.776,1.12-5.825,0.655
				c0.649,0.319,1.298,0.637,1.984,0.975c-1.033,1.093-1.983,1.553-3.443,0.931c-1.052-0.447-2.323-0.385-3.499-0.525
				c-0.299-0.036-0.668-0.078-0.901,0.058c-0.387,0.227,0.562,1.64-0.677,1.02c-0.657-0.33-1.298-1.523,0.188-2.104
				c-0.238-0.409,0.931-1.411-0.477-1.292c-1.582,0.135-1.228-2.072-2.252-1.986c-1.617,0.137,0.411,2.201-0.935,2.516
				c-1.232,0.287-1.449,0.287-0.163,0.92c0.322,0.158,0.074,0.744-0.278,1.003c-0.597,0.437-1.172,0.389-1.718-0.124
				c-0.859-0.807-1.741-1.585-3.02-2.017c0.235,0.964,1.648,0.699,1.61,1.82c-3.41,1.206-6.345-1.881-9.718-1.077
				c1.33-0.106,2.35,0.883,3.651,1.115c-2.934,0.308-5.866,0.615-8.827,0.926c-0.099-1.045,0.915-0.772,1.371-1.144
				c-0.096-0.122-0.189-0.327-0.262-0.319c-1.552,0.158-1.288,0.056-1.894-1.432c-0.243-0.598-1.368-1.351-2.158-1.851
				c-1.783-1.13-0.95-1.812,0.191-2.585c3.569-2.416,7.472-3.363,11.747-2.647c0.354-0.735,1.021-0.77,1.705-0.765
				c4.984-1.04,10.104-0.905,15.117-1.699c1.62,0.346,3.276,0.484,4.899,0.857c1.431,0.329,2.649,0.907,3.58,2.052
				C597.72,610.92,597.72,613.16,597.72,615.4z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M15.48,667.24c3.64-2.502,7.782-3.861,12.066-4.266
				c10.774-1.016,21.309,0.327,31.397,4.391c2.971,1.197,5.621,3.008,8,5.178c0.352,0.32,0.836,0.634,0.737,1.151
				c-0.111,0.582-0.749,0.465-1.174,0.592c-1.145,0.342-2.301,0.644-3.45,0.972c-0.903,0.257-1.619,0.062-2.437-0.498
				c-8.719-5.966-18.328-8.806-28.918-7.879c-2.519,0.22-5.019,0.742-7.136,2.316c-0.045,0.107-0.09,0.214-0.134,0.32
				c-2.389,1.431-5.073,2.091-7.683,2.943c-0.519,0.17-0.918-0.06-1.268-0.421C15.48,670.44,15.48,668.84,15.48,667.24z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M15.48,238.12c5.187,4.621,9.069,10.188,11.764,16.562
				c2.042,4.828,3.409,9.867,4.402,15.309c-2.029-2.486-2.706-2.329-4.187,0.898c-0.307,0.668-0.264,1.431-1.284,1.3
				c-0.89-0.113-0.842-0.911-0.952-1.466c-1.084-5.473-3.842-10.338-5.624-15.558c-0.662-1.938-2.98-2.476-4.12-4.084
				C15.48,246.76,15.48,242.44,15.48,238.12z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M24.564,669.197c4.111-1.168,8.184-2.39,12.574-2.049
				c8.028,0.622,15.379,3.136,22.142,7.452c0.386,0.246,0.981,0.395,0.829,1.009c-0.133,0.536-0.66,0.614-1.146,0.694
				c-0.231,0.038-0.444,0.176-0.672,0.247c-1.41,0.441-3.052,1.518-4.162,1.118c-0.938-0.337-1.291-2.374-1.849-3.682
				c-0.565-1.325-1.582-2.091-2.955-1.894c-1.637,0.235-0.978,1.717-1.152,2.727c-0.04,0.229-0.12,0.449-0.234,0.864
				c-6.932-2.895-14.206-2.161-21.39-2.106c-1.795,0.015-3.753,0.399-5.256,1.727l0.065-0.079c-2.031,1.275-3.9-0.555-5.878-0.306
				c0-0.64,0-1.28,0-1.92c1.937-1.489,4.206-2.21,6.519-2.846c0.915-0.25,1.862-0.453,2.655-1.032L24.564,669.197z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M597.72,745.96c0,1.6,0,3.2,0,4.8c-1.492,1.002-3.267,0.442-4.621,0.17
				c-5.368-1.083-10.312,0.278-15.285,1.847c-0.849,0.268-1.541,0.846-2.323,1.224c-5.814,2.812-9.42,0.907-11.668-4.754
				c-0.528-1.33,0.465-1.688,1.32-2.103c3.932-1.901,8.132-2.764,12.44-3.269c6.26-0.733,12.425-0.177,18.551,1.145
				C596.772,745.157,597.38,745.328,597.72,745.96z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M597.72,653.32c-0.624,0.375-0.999,0.323-1.487-0.378
				c-4.85-6.965-11.482-11.486-19.604-13.731c-4.222-1.168-8.565-0.901-13.207,0.089c0.924-2.486,1.74-4.648,2.527-6.822
				c0.204-0.562,0.572-0.676,1.107-0.634c0.594,0.046,0.837,0.365,0.861,0.92c0.015,0.318-0.059,0.681,0.067,0.948
				c0.457,0.977,0.728,2.351,2.118,2.219c1.178-0.111,1.394-1.192,1.673-2.285c0.183-0.714-0.527-1.928,0.727-2.206
				c1.25-0.276,1.761,0.808,2.384,1.588c0.39,0.487,0.644,1.106,1.094,1.519c0.837,0.767,1.871,0.887,2.242-0.353
				c0.564-1.887,1.423-1.613,2.744-0.812c1.871,1.136,3.837,2.116,5.763,3.162c1.949-0.392,3.038,1.23,4.221,2.145
				c2.997,2.316,5.581,5.103,6.77,8.872C597.72,649.48,597.72,651.4,597.72,653.32z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M597.72,506.44c-1.429-0.528-2.242-0.067-1.958,1.481
				c0.384,2.093-0.941,3.617-1.556,5.375c-0.117,0.335-0.323,0.777-0.822,0.743c-0.526-0.036-0.568-0.48-0.615-0.871
				c-0.164-1.389-0.995-1.365-2.084-1.103c-1.938,0.469-2.942-0.341-2.922-2.362c0.003-0.276-5.142-4.173-5.389-4.063
				c-0.201,0.089-0.51,0.275-0.513,0.424c-0.058,3.131-2.192,1.586-4.165,1.836c1.819-1.383,3.194-2.425,4.565-3.473
				c1.916-1.463,4.431-2.297,5.109-5.063c0.102-0.414,0.809-0.729,1.29-0.991c2.18-1.186,4.183-2.592,6.07-4.219
				c1.035-0.893,1.773-1.831,1.959-3.143c0.1-0.703,0.261-1.23,1.029-1.372C597.72,495.24,597.72,500.84,597.72,506.44z"/>
			<path fill-rule="evenodd" d="M532.879,679.163c1.277,0.42,2.072-0.239,3.086-0.991c7.525-5.582,15.923-9.205,25.173-10.841
				c7.979-1.411,15.577-0.273,22.763,3.413c2.982,1.529,5.755,3.426,7.416,6.487c0.812,1.495,1.876,0.686,2.621,0.235
				c1.057-0.64-0.029-1.197-0.424-1.635c-5.644-6.248-12.462-10.583-20.949-12.659c1.161-0.82,2.118-0.161,2.948,0.1
				c7.088,2.228,13.172,6.058,18.171,11.585c1.068,1.183,2.142,2.479,4.036,1.503c0,0.319,0,0.64,0,0.96
				c-1.096,1.312-2.628,1.882-4.151,2.5c-1.97,0.801-4.024,1.45-5.951,3.008c1.12,0.42,1.834-0.093,2.532-0.439
				c1.568-0.776,3.161-1.469,4.839-1.99c1.129-0.35,2.015-0.189,2.731,0.762c0,0.16,0,0.32,0,0.479
				c-0.514,1.438-1.82,1.818-3.385,2.352c1.271,0.729,2.66,0.849,3.385,1.969c0,0.32,0,0.64,0,0.96
				c-10.487-2.397-21.111-3.647-31.843-2.748c-11.51,0.965-22.522,3.658-31.418,11.726c-2.357,2.139-4.459,4.561-6.669,6.862
				c-0.655,0.683-1.146,1.543-2.09,1.908l0.049,0.035c-0.75-0.645-1.104-1.409-0.407-2.262c1.341-1.641,0.632-2.891-0.648-4.099
				c-0.746-0.704-1.439-1.467-2.215-2.14c-0.842-0.731-1.064-1.533-0.354-2.483c0.928-0.749,1.803-0.341,2.649,0.162
				c0.553,0.328,0.981,0.817,1.444,1.258c3.018,2.873,3.453,2.702,6.314-0.162c3.101-3.104,6.792-5.355,10.865-7.196
				c4.607-2.081,9.313-3.588,14.223-4.58c6.938-1.403,13.959-1.528,21.001-1.214c0.681,0.03,1.564,0.507,1.937-0.343
				c0.313-0.718-0.593-1.006-0.984-1.467c-0.532-0.626-0.633-1.292-0.096-1.953c0.524-0.646,1.218-0.717,1.952-0.417
				c1.098,0.447,2.106,1.11,3.253,1.439c0.574-0.636,0.133-1.506,0.673-2.058c0.695-0.834,1.618-0.352,2.818-0.317
				c-0.814-1.521-1.918-2.326-3.111-3.055c-9.989-6.101-20.431-6.496-31.228-2.495c-3.219,1.193-6.373,2.524-9.472,4.007
				c-3.33,1.592-6.514,3.339-8.702,6.46C533.854,681.845,532.728,681.178,532.879,679.163z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M15.48,400.84c6.683-1.772,13.245-0.857,20.489,0.941
				c-2.427,0.824-4.24,1.44-6.053,2.057c0.025,0.1,0.052,0.2,0.078,0.3c2.084-0.517,4.168-1.034,6.252-1.552
				c-0.638,0.745-1.136,1.347-1.184,2.23l0.088-0.084c-2.194,0.539-3.272,2.555-4.936,3.799c-2.108,1.575-4.45,2.637-6.789,3.725
				c-0.842,0.392-1.722,0.227-2.654-0.362c-1.632-1.033-3.301-2.114-5.292-2.413C15.48,406.6,15.48,403.72,15.48,400.84z"/>
			<path fill-rule="evenodd" d="M597.72,715.72c-5.981-3.086-12.15-5.772-18.968-6.008c-8.453-0.291-16.674,0.896-24.088,5.423
				c-0.25,0.153-0.452,0.384-0.835,0.714c1.921,1.206,4.087,1.669,5.543,3.27c-3.273-0.942-6.067-3.022-9.373-3.91
				c-6.947-1.868-13.936-3.44-21.19-3.338c-0.633,0.009-1.406,0.187-1.713-0.48c-0.113-0.246,0.425-0.82,0.708-1.216
				c3.052-4.265,7.459-6.901,11.764-9.61c8.642-5.439,18.424-6.896,28.332-7.747c10.141-0.872,20.053,0.559,29.82,3.224
				c0,0.32,0,0.64,0,0.96c-10.362-3.489-20.968-4.61-31.841-3.409c-9.023,0.997-17.837,2.645-25.76,7.433
				c-3.493,2.111-6.7,4.615-9.879,7.154c-0.791,0.632-1.874,1.472-1.553,2.487c0.309,0.973,1.633,0.697,2.562,0.727
				c2.956,0.093,5.887,0.396,8.793,0.946c1.46,0.277,2.691,0.137,3.923-1.012c3.368-3.143,7.719-4.374,11.992-5.697
				c6.953-2.153,13.995-3.778,21.334-3.775c6.197,0.003,12.199,1.185,18.118,2.896c0.763,0.22,1.479,0.611,2.311,0.411
				c0,0.32,0,0.64,0,0.96c-7.725-2.473-15.594-4.09-23.747-3.413c-9.537,0.79-18.734,2.962-27.251,7.495
				c-1.096,0.583-2.752,1.236-2.665,2.478c0.081,1.158,1.893,0.66,2.892,1.07c1.463,0.601,3.063,0.855,4.585,1.324
				c0.822,0.254,1.468,0.137,2.248-0.28c14.198-7.587,28.482-7.745,42.832-0.397c0.341,0.175,0.736,0.245,1.106,0.363
				C597.72,715.08,597.72,715.399,597.72,715.72z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M23.271,624.708c-2.597,0.897-5.194,1.795-7.792,2.692c0-1.28,0-2.561,0-3.841
				c5.8-3.727,11.776-6.263,18.919-3.951c5.027,1.627,10.119,3.015,14.726,5.706c1.264,0.739,2.249,1.386,0.977,3.069
				c-0.649,0.859-0.916,0.834-1.777,0.386c-6.034-3.134-12.288-5.526-19.264-5.147C27.088,623.729,25.137,624.006,23.271,624.708z"/>
			<path fill-rule="evenodd" d="M43.522,446.329c-2.334-2.485-5.384-1.539-7.964-1.1c-1.484,0.252-1.359,2.454-0.186,3.846
				l0.038-0.032c-2.316-0.415-4.51-1.644-6.94-1.154c-0.184,2.451,2.82,3.081,2.829,5.349c-2.575-0.774-3.577-3.088-4.643-5.146
				c-0.416-0.804,0.285-2.062,1.698-2.245c0.93-0.119,1.912,0.163,2.808-0.312c-1.051-1.775-1.051-1.775-8.771-2.402
				c0.672,0.795,1.504,1.071,2.186,1.539c1.205,0.828,1.863,1.684,2.218,3.385c0.442,2.118,1.893,4.336,4.436,5.115
				c2.409,0.029,2.847,2.743,4.872,3.361c-2.14,0.615-3.72-1.002-5.632-0.996c-0.42,0.001-0.858-0.627-1.252-0.005
				c-0.26,0.409,0.032,0.847,0.274,1.078c3.687,3.53,7.517,6.849,13.188,8.572c-1.025-1.509-1.667-2.454-2.31-3.4
				c1.151-1.341,1.98-0.329,2.733,0.449c0.985,1.019,1.655,2.247,0.917,3.666c-0.596,1.148-1.639,1.369-2.871,0.972
				c-1.683-0.542-3.21-1.374-4.718-2.276c-0.785-0.471-1.432-1.277-2.521-1.22c-0.123,1.011,1.486,1.711,0.456,2.817
				c-2.42,0.518-2.696-1.719-3.506-2.97c-1.96-3.029-3.915-6.1-4.946-9.624c-0.042-0.144-0.156-0.298-0.28-0.38
				c-2.634-1.729-3.556-4.471-4.42-7.286c-0.322-1.047-1.072-1.03-2.108-0.639c-1.25,0.473-2.155,1.699-3.627,1.63
				c0-0.16,0-0.32,0-0.479c0.673-0.96,1.374-1.921,0-2.881c0-1.279,0-2.56,0-3.84c0.593-0.758,1.293-1.066,2.29-0.782
				c1.454,0.414,2.998,0.409,4.458,0.76c1.591,0.383,2.974,0.138,4.295-0.753c1.924,0.161,3.432,1.346,5.052,2.181
				c2.695,1.389,5.411,2.328,8.489,1.85c0.963-0.149,1.919,0.043,2.802,0.458C44.298,444.107,44.898,445.003,43.522,446.329z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M597.72,751.72c0,1.761,0,3.521,0,5.28c-0.662,0.752-1.564,0.62-2.436,0.727
				c-4.532,0.559-8.808-0.861-13.176-1.606c-2.627-0.448-5.264-0.854-7.936-0.854c-0.025-1.15,0.609-1.596,1.203-1.919
				c6.658-3.621,13.637-4.719,21.004-2.55C596.922,750.958,597.439,751.172,597.72,751.72z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="M597.72,647.56c-3.469-3.866-6.791-7.878-10.99-11.017
				c-1.677-2.062-4.11-2.961-6.412-3.989c-1.063-0.476-1.346-0.922-0.885-1.927c0.165-0.36,0.239-0.765,0.419-1.118
				c0.62-1.221,0.048-3.309,2.005-3.525c1.843-0.204,4.544,2.117,5.118,3.969c0.521,1.676,1.078,3.344,1.514,5.042
				c0.214,0.834,0.514,1.565,1.341,1.819c0.961,0.296,1.421-0.525,1.905-1.137c1.694-2.138,1.601-2.074,3.363,0.072
				c1.058,1.287,1.685,2.797,2.622,4.131C597.72,642.439,597.72,645,597.72,647.56z"/>
			<path fill-rule="evenodd" d="M597.72,751.72c-6.981-3.394-19.051-1.25-23.325,3.126c7.875,0.877,15.458,2.909,23.325,2.154
				c0,0.16,0,0.32,0,0.48c-0.665,0.811-1.448,1.113-2.553,0.982c-0.667-0.079-1.596-0.329-2.101,0.558
				c-2.15,0.059-3.948-1.173-5.96-1.629c-11.462-2.598-22.858-2.892-34.154,0.89c-0.405,0.136-0.852,0.543-1.298,0.031
				c2.095-2.113,5.054-1.419,7.487-2.374c0.759-0.298,2.409,0.085,1.827-1.726c0.793-0.94,1.809-0.196,2.708-0.347
				c0.553-0.093,1.184-0.053,1.439-0.655c0.256-0.605-0.18-1.057-0.542-1.485c-1.581-1.875-3.686-3.033-5.787-4.186
				c-1.398-0.768-2.84-1.454-4.208-2.285c-0.872-0.53-1.35-1.115-0.804-2.135c1.046,0.742,2.063,0.57,3.145,0.011
				c4.84-2.504,10.086-3.456,15.446-3.856c4.033-0.302,8.077-0.549,12.153,0.032c-9.459,0.328-19.005,0.128-28.101,4.842
				c2.214,1.222,4.129,2.257,6.021,3.334c0.619,0.352,1.082-0.005,1.56-0.229c10.948-5.15,22.27-4.659,33.721-2.253
				c0,0.32,0,0.64,0,0.96c-10.919-3.107-21.688-3.008-32.276,1.44c-1.394,0.586-1.767,1.054-0.417,2.182
				c1.023,0.855,1.974,1.883,2.688,3.004c1.542,2.419,3.69,3.078,6.004,1.47c4.832-3.358,10.242-4.669,16.028-4.681
				c2.729-0.006,5.348,0.736,7.973,1.385C597.72,751.08,597.72,751.399,597.72,751.72z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="M15.48,675.88c3.965,0.393,7.299,2.446,10.766,4.13
				c2.863,1.391,5.529,3.103,7.926,5.212c0.654,0.575,2.083,1.213,1.177,2.071c-0.898,0.85-1.959,2.208-3.63,1.723
				c-0.515-0.15-0.981-0.465-1.471-0.704c-1.303,0.915-2.296-0.074-3.234-0.664c-1.219-0.767-2.444-1.479-3.794-1.974
				c-2.558-1.541-5.783-1.941-7.739-4.514C15.48,679.399,15.48,677.641,15.48,675.88z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="M29.26,397.647c-1.054,1.494-2.26,1.946-4.216,1.497
				c-2.448-0.561-4.985,0.251-7.466,0.598c-0.708,0.099-1.388,0.223-2.098,0.138c0-0.16,0-0.32,0-0.479
				c2.463-2.801,5.415-4.971,8.684-6.732l-0.071,0.046c1.4-1.46,3.312-2.193,4.869-3.429l-0.103,0.06
				c1.88-1.128,4.066-1.647,5.86-2.947l-0.093,0.059c1.513-0.356,2.441-0.145,1.57,1.689c-0.904,1.902-1.567,3.89-2.181,5.899
				C32.555,395.409,31.4,397.18,29.26,397.647z"/>
			<path fill-rule="evenodd" d="M597.72,561.16c-1.675-0.641-3.342-1.303-5.029-1.908c-0.509-0.184-1.204-0.1-1.057-0.94
				c0.109-0.624,0.536-0.945,1.18-0.948c0.319-0.002,0.639,0.013,0.958,0.006c3.595-0.084,3.744-0.236,2.781-3.754
				c-0.223-0.815-0.271-1.534-0.062-2.32c0.975-3.663-1.334-6.993-5.188-6.938c-2.498,0.036-3.061-2.151-4.482-3.242
				c-0.895-0.687,0.431-1.535,1.516-1.503c2.734,0.081,5.611-1.347,8.209,1.027c-1.219-2.024-0.986-3.312,1.174-3.959
				c0,2.08,0,4.16,0,6.24c-0.481,0.342-0.973,0.644-1.514,0.393c0.589,0.124,1.07,0.558,1.514,1.048
				C597.72,549.96,597.72,555.56,597.72,561.16z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(43)} fill={props.fillColors[43]} d="M573.457,608.664c-0.347-0.144-0.692-0.286-1.039-0.43
				c-0.592-0.609-2.535-0.181-1.544-2.009l-0.09,0.088c2.632-1.183,5.502-1.368,8.271-1.979l-0.11,0.045
				c4.406-0.895,8.841-0.996,13.298-0.49c1.815,0.274,3.595,0.979,5.477,0.472c0,1.439,0,2.88,0,4.319
				c-2.7-0.062-5.149-1.506-7.875-1.428c-4.72-0.396-9.452-0.872-13.892,1.417C575.088,609.115,574.288,608.952,573.457,608.664z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(44)} fill={props.fillColors[44]} d="M24.133,684.554c2.038,1.253,4.077,2.506,6.115,3.758
				c1.196,0.993,1.052,1.554-0.159,2.724c-2.725,2.636-4.625,0.819-6.716-0.701c0.108,0.085,0.217,0.17,0.325,0.255
				c-0.108-0.082-0.217-0.164-0.328-0.248c-1.033-0.658-2.012-1.429-3.109-1.95c-2.355-1.119-4.33-2.51-4.781-5.311
				c0-0.48,0-0.96,0-1.44c2.227-0.071,4.127,1.022,6.113,1.77C22.454,683.733,23.31,684.121,24.133,684.554z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(45)} fill={props.fillColors[45]} d="M597.72,337.96c-1.186,0.127-2.369,0.275-3.557,0.373
				c-0.562,0.046-1.364-0.025-1.409,0.604c-0.259,3.6-3.181,2.029-4.94,2.714c1.101-1.934,3.231-2.445,4.835-3.668
				c0.629-0.48,1.377-0.817,1.959-1.343c0.948-0.857,2.141-1.942,1.654-3.199c-0.428-1.109-1.97-0.664-3.063-0.727
				c-1.341-0.078-2.679-0.188-4.324-0.473c2.902-1.426,4.036-3.831,3.973-7.302c1.541,1.708,2.752,3.174,4.873,2.46
				C597.72,330.92,597.72,334.44,597.72,337.96z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(46)} fill={props.fillColors[46]} d="M15.48,603.4c1.198-0.213,2.092-0.045,2.702,1.271c0.316,0.684,0.99,1.532,2.014,1.477
				c1.265-0.069,0.965-1.304,1.258-2.004c0.419-1.004,0.511-2.304,1.769-2.505c2.005-0.321,4.147-1.291,6.026-0.512
				c1.468,0.608,1.257,2.779,1.494,4.224c0.14,0.854-1.448,0.461-2.239,0.501c-4.502,0.229-8.793,1.38-13.024,2.827
				C15.48,606.92,15.48,605.16,15.48,603.4z"/>
			<path fill-rule="evenodd" d="M15.48,330.28c5.781,4.125,10.96,8.969,16.216,13.71c6.039,5.447,11.558,11.419,17.047,17.413
				c0.572,0.625,1.428,1.091,1.326,2.141c-4.895-4.887-9.654-9.92-14.718-14.625c-6.342-5.893-12.67-11.831-19.87-16.717
				C15.48,331.56,15.48,330.92,15.48,330.28z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(47)} fill={props.fillColors[47]} d="M596.206,729.597c-2.445,2.492-5.426,2.946-8.704,2.305
				c-0.444-0.087-1.04,0.237-1.315-0.264c-0.366-0.668,0.297-0.993,0.672-1.349c3.167-3.008,6.969-5.022,10.861-6.889
				c0,1.92,0,3.84,0,5.76C597.276,729.518,596.941,730.249,596.206,729.597z"/>
			<path fill-rule="evenodd" d="M597.72,522.76c-4.858-1.177-9.711-2.37-14.16-5.235c2.223-1.567,4.444-1.953,6.928-0.949
				c2.312,0.935,4.774,1.17,7.232,1.385C597.72,519.56,597.72,521.16,597.72,522.76z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(48)} fill={props.fillColors[48]} d="M20.309,373.694c0.78-0.141,1.56-0.282,2.34-0.422c0.626-0.837,1.66-0.742,2.496-0.92
				c0.973-0.208,1.493,0.351,0.88,1.394c-2.625,2.781-5.241,5.571-7.88,8.338c-0.767,0.804-1.496,1.688-2.665,1.957
				c0-2.24,0-4.48,0-6.72C15.993,374.651,18.321,374.399,20.309,373.694z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(49)} fill={props.fillColors[49]} d="M597.72,681.16c-2.257,0.178-4.454,0.797-6.248,2.087
				c-2.453,1.764-4.859,1.121-7.452,0.376c4.229-2.854,8.588-5.375,13.7-6.303C597.72,678.601,597.72,679.88,597.72,681.16z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(50)} fill={props.fillColors[50]} d="M586.521,585.989c-0.551-1.025-2.431-1.451-1.112-3.307
				c0.982-1.384,1.764-1.308,2.438-0.096c0.625,1.124,1.211,1.763,2.555,1.356c0.865-0.262,1.267,0.517,1.748,0.981
				c1.879,1.815,3.717,3.674,5.57,5.516c0,0.64,0,1.279,0,1.92c-0.638,0.975-1.563,1.034-2.576,0.809
				C592.474,590.533,588.854,589.034,586.521,585.989z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(51)} fill={props.fillColors[51]} d="M15.48,721.96c0.757-0.315,1.357,0.353,2.072,0.329
				c2.253-0.071,3.045,1.027,2.783,3.229c-0.196,1.645,0.667,3.231,0.47,4.932c-0.119,1.023,0.121,1.812-1.518,1.278
				c-1.241-0.404-2.599-0.523-3.807-1.129C15.48,727.72,15.48,724.84,15.48,721.96z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(52)} fill={props.fillColors[52]} d="M26.226,438.511c0.273,0.894,0.605,1.755,1.656,2.6
				c-4.504,0.673-8.442-0.472-12.402-1.391c0-1.119,0-2.24,0-3.359c2.935,0.125,5.879,0.169,8.715,1.084
				c1.282-1.034,2.049-0.577,2.47,0.858c0.09,0.308,0-0.087-0.053,0.215C26.536,438.952,26.359,438.572,26.226,438.511z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(53)} fill={props.fillColors[53]} d="M597.72,347.56c-2.792-0.76-5.58-1.533-8.378-2.271
				c-0.748-0.198-1.412-0.061-1.812,0.737c-0.431,0.859-1.074,0.715-1.756,0.338c1.19-3.295,4.191-3.012,6.765-3.437
				c1.067-0.176,1.888-0.271,1.821-1.614c-0.085-1.707,1.208-1.104,2.022-1.091c1.362,0.021,0.99,1.199,0.768,1.762
				c-0.442,1.119-0.401,1.959,0.57,2.697C597.72,345.64,597.72,346.6,597.72,347.56z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(54)} fill={props.fillColors[54]} d="M597.72,355.24c-2.306-1.265-4.556-2.651-6.939-3.749
				c-1.404-0.646-1.546-1.522-1.454-2.839c0.071-1.019,0.429-0.934,1.191-0.751c2.39,0.571,4.8,1.059,7.202,1.579
				C597.72,351.4,597.72,353.32,597.72,355.24z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(55)} fill={props.fillColors[55]} d="M597.72,577.96c-1.293,0.072-1.952-0.232-1.998-1.832
				c-0.069-2.414-0.465-4.84-0.948-7.215c-0.278-1.366-1.627-2.357-1.682-3.895c-0.024-0.671-0.513-1.538,0.287-1.89
				c0.664-0.291,1.033,0.538,1.423,1.01c1.093,1.323,1.553,3.086,2.918,4.222C597.72,571.561,597.72,574.76,597.72,577.96z"/>
			<path fill-rule="evenodd" d="M549.699,768.317c-0.107-1.067,0.789-1.309,1.452-1.658c15.878-8.37,31.407-7.797,46.568,1.86
				c0,0.32,0,0.641,0,0.961c-12.502-8.548-25.834-10.475-40.093-5.029C554.87,765.505,552.175,766.685,549.699,768.317z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(56)} fill={props.fillColors[56]} d="M15.48,713.32c0.078,0.017,0.158,0.026,0.232,0.051
				c4.131,1.381,4.826,2.478,4.612,6.99c-0.056,1.173-0.174,1.835-1.628,1.273c-1.004-0.389-2.14-0.436-3.217-0.635
				C15.48,718.44,15.48,715.88,15.48,713.32z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(57)} fill={props.fillColors[57]} d="M23.408,583.895c0.389,0.938,0.856,1.854,1.136,2.822
				c0.159,0.551,0.375,1.35-0.286,1.717c-0.894,0.496-1.183-0.398-1.602-0.919c-0.88,0.285-1.518-0.14-2.097-0.724
				c-1.936-1.951-3.845-3.925-5.079-6.431c0-1.28,0-2.561,0-3.841c0.104-0.015,0.207-0.029,0.311-0.044
				c1.441,1.093,2.713,2.242,2.664,4.311c-0.023,0.986,0.811,1.196,1.683,1.194c0.733,0,1.503-0.657,2.199,0.105
				c0.832-0.118,0.971,0.715,1.462,1.065C23.86,583.5,23.775,583.771,23.408,583.895z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(58)} fill={props.fillColors[58]} d="M15.48,580.36c3.079,1.696,4.799,4.754,7.176,7.154
				c-0.151,0.769,0.515,1.688-0.653,2.273c-1.133,0.568-1.565-0.259-2.197-0.785c-2.209,0.766-2.486-1.408-3.534-2.37
				c-0.522-0.479-0.408-1.345-0.792-1.953C15.48,583.24,15.48,581.8,15.48,580.36z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(59)} fill={props.fillColors[59]} d="M586.705,664.415c0.051-0.534-0.214-1.127,0.278-1.58
				c3.312-3.048,6.608-6.113,10.736-8.075c0,0.96,0,1.92,0,2.88c-2.697,2.445-5.401,4.884-8.104,7.321
				c-1.098,0.989-2.049-0.122-2.986-0.64L586.705,664.415z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(60)} fill={props.fillColors[60]} d="M597.72,589.48c-2.563-1.362-3.588-4.315-6.047-5.831
				c-0.74-0.456-0.309-1.668,0.046-2.176c0.745-1.066,0.598-2.262,0.903-3.368c1.2,0.035,2.116,0.545,1.878,1.804
				c-0.53,2.802,1.275,4.039,3.22,5.251C597.72,586.6,597.72,588.04,597.72,589.48z"/>
			<path fill-rule="evenodd" d="M586.629,664.321c1.479,0.79,2.435,0.39,3.481-0.979c1.972-2.576,4.397-4.705,7.609-5.702
				c0,0.16,0,0.32,0,0.48c-0.762,2.622-3.405,3.376-4.999,5.009c1.499-0.816,2.908-2.356,4.999-1.169c0,0.32,0,0.64,0,0.96
				c-0.724,1.15-1.722,1.99-2.876,2.697c-0.599,0.367-1.197,0.951-0.785,1.748c0.376,0.726,1.1,0.455,1.683,0.24
				c0.693-0.256,1.349-0.452,1.979,0.114c0,0.32,0,0.64,0,0.96c-1.008,1.601-1.568,3.2,0,4.801c0,0.8,0,1.6,0,2.399
				C594.957,671.131,590.898,667.625,586.629,664.321z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(61)} fill={props.fillColors[61]} d="M597.72,667.72c-0.586,0.399-1.266,0.71-1.741,1.214
				c-1.777,1.88-2.812,0.521-3.93-0.785c-0.58-0.677-0.505-1.163,0.084-1.821c1.529-1.71,3.404-2.797,5.587-3.407
				C597.72,664.52,597.72,666.12,597.72,667.72z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(62)} fill={props.fillColors[62]} d="M15.48,732.52c2.553,2.29,4.083,5.349,6.024,8.093c1.241,1.753-0.688,2.504-1.59,3.111
				c-1.125,0.758-0.989-0.728-1.262-1.315c-1.017-2.196-1.791-4.509-3.172-6.528C15.48,734.76,15.48,733.64,15.48,732.52z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(63)} fill={props.fillColors[63]} d="M597.72,661.96c-2.234,1.009-4.579,1.839-6.149,3.9
				c-0.396,0.52-0.834,1.04-1.555,0.429c-0.753-0.638-0.427-1.244,0.06-1.854c1.443-1.81,3.025-3.463,5.039-4.659
				c0.885-0.525,1.738-1.103,2.605-1.656C597.72,659.4,597.72,660.68,597.72,661.96z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(64)} fill={props.fillColors[64]} d="M15.48,560.68c2.512-0.155,1.946,2.5,1.79,3.03c-0.553,1.875,0.354,3.511,0.351,5.252
				c-0.002,1.306-0.695,0.82-1.61,1.007c2.294,0.715,2.043,2.223,1.827,3.764c-0.912,0.333-1.536-0.561-2.357-0.572
				C15.48,569,15.48,564.84,15.48,560.68z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(65)} fill={props.fillColors[65]} d="M15.48,415.24c3.011-0.254,5.945,0.028,9.391,1.35
				c-3.518,0.933-6.455,1.712-9.391,2.49C15.48,417.8,15.48,416.521,15.48,415.24z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(66)} fill={props.fillColors[66]} d="M15.48,410.44c3.081,0.703,5.513,2.55,8.458,4.659
				c-3.204,0.219-5.802-1.479-8.458-0.34C15.48,413.32,15.48,411.88,15.48,410.44z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(67)} fill={props.fillColors[67]} d="M597.72,685.96c-2.39,0.19-4.549-0.922-7.215-1.101c2.2-2.14,4.843-2.38,7.215-3.22
				C597.72,683.08,597.72,684.52,597.72,685.96z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(68)} fill={props.fillColors[68]} d="M15.48,584.68c1.442,1.441,2.884,2.882,4.326,4.323
				c-0.058,0.705,0.998,1.316,0.146,2.097c-0.945,0.866-1.712-0.237-2.579-0.192c-1.097,0.614-1.438-0.278-1.893-0.947
				C15.48,588.2,15.48,586.44,15.48,584.68z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(69)} fill={props.fillColors[69]} d="M15.48,739.24c1.423,1.659,2.11,3.776,2.64,5.742c0.476,1.769-1.397,3.082-2.64,4.338
				C15.48,745.96,15.48,742.6,15.48,739.24z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(70)} fill={props.fillColors[70]} d="M15.48,705.16c0.992-0.479,1.292-1.569,2.022-2.277
				c0.268-0.26,0.508-0.668,0.988-0.513c0.526,0.171,0.324,0.647,0.372,1.008c0.392,2.973-1.174,4.942-3.381,6.582
				C15.48,708.36,15.48,706.76,15.48,705.16z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(71)} fill={props.fillColors[71]} d="M15.48,515.56c1.636,0.792,2.265,2.441,3.135,3.839
				c0.946,1.516,0.322,2.401-1.516,2.32c-0.545-0.024-1.08-0.26-1.62-0.398C15.48,519.4,15.48,517.48,15.48,515.56z"/>
			<path fill-rule="evenodd" d="M20.309,373.694c-1.178,1.784-3.373,2.213-4.829,3.626c0-2.24,0-4.479,0-6.72
				C17.068,371.666,18.07,373.646,20.309,373.694z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(72)} fill={props.fillColors[72]} d="M597.72,618.28c-1.788-0.56-1.596,1.811-2.902,1.963
				c-0.086,1.496-0.897,1.478-1.958,0.938c-0.612,0.148-1.359,0.498-1.751-0.174c-0.422-0.725,0.367-1.188,0.806-1.54
				c1.731-1.396,3.497-2.773,5.806-3.107C597.72,617,597.72,617.641,597.72,618.28z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(73)} fill={props.fillColors[73]} d="M15.48,711.4c1.448-0.96,1.928-2.871,3.561-3.709c1.036,5.345,0.778,5.711-3.561,5.148
				C15.48,712.36,15.48,711.88,15.48,711.4z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(74)} fill={props.fillColors[74]} d="M593.066,759.021c-0.527-0.301-1.055-0.603-1.858-1.062
				c2.298-1.209,4.449-0.337,6.512-0.479c0,1.119,0,2.239,0,3.359C596.169,760.233,594.618,759.627,593.066,759.021z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(75)} fill={props.fillColors[75]} d="M15.48,451.24c0.866,0.153,1.731,0.307,2.597,0.461
				c0.383,0.119,0.744,0.292,1.034,0.57c0.79,0.758,2.085,1.652,1.561,2.666c-0.635,1.228-1.811,0.047-2.668-0.3
				c-0.831-0.336-1.751-0.04-2.523-0.518C15.48,453.16,15.48,452.2,15.48,451.24z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(76)} fill={props.fillColors[76]} d="M15.48,751.72c1.354-1.789,2.708-3.579,4.062-5.368
				c-1.058,3.378-2.26,6.663-4.062,9.688C15.48,754.6,15.48,753.16,15.48,751.72z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(77)} fill={props.fillColors[77]} d="M15.48,419.56c2.001-0.396,3.688,0.171,5.271,1.721
				c-1.762,0.862-3.436,1.345-5.271,1.16C15.48,421.48,15.48,420.52,15.48,419.56z"/>
			<path fill-rule="evenodd" d="M595.379,592.481c0.813,0.597,1.584,0.368,2.341-0.121c0,0.319,0,0.64,0,0.96
				c-2.156,0.871-4.466,1.562-5.022,4.304c-0.08,0.395-0.102,0.442-0.645,0.359c-0.854-0.133-1.698-0.34-2.593-0.525
				c0.522-0.63,1.449-0.103,1.896-0.801c-0.068-1.002-1.282-0.777-1.616-1.468c0.8-1.062,3.138,2.942,3.062-0.955
				C593.23,593.017,594.265,592.69,595.379,592.481z"/>
			<path fill-rule="evenodd" d="M15.48,480.04c2.112,0.422,4.278,0.386,6.39,0.687c1.888,0.27,1.89-0.321,1.329-1.726
				c-0.235-0.588-0.816-1.202-0.176-2.021c0.583,1.428,1.125,2.788,1.696,4.136c0.247,0.581,0.267,1.177-0.295,1.472
				c-1.265,0.662-0.113-0.765-0.751-0.93c-0.837-0.217-1.645-0.459-2.521-0.532c-1.894-0.157-3.825-0.004-5.672-0.606
				C15.48,480.36,15.48,480.2,15.48,480.04z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(78)} fill={props.fillColors[78]} d="M15.48,426.76c2.001,0.304,3.167,1.967,4.964,2.797
				c-1.981,1.688-3.521,1.084-4.964-0.396C15.48,428.359,15.48,427.561,15.48,426.76z"/>
			<path fill-rule="evenodd" d="M15.48,463.24c1.18,0.737,2.357,0.854,3.803,0.664c2.291-0.299,4.651-0.079,7.019,0.303
				c-3.688,0.311-7.57-0.631-10.822,1.913C15.48,465.16,15.48,464.2,15.48,463.24z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(79)} fill={props.fillColors[79]} d="M15.48,527.56c0.414,0.31,0.827,0.619,1.24,0.929c0.265,0.18-0.042-0.084,0.213,0.108
				c1,0.75,2.651,1.475,2.123,2.801c-0.499,1.248-2.238,1.089-3.576,0.963C15.48,530.76,15.48,529.16,15.48,527.56z"/>
			<path fill-rule="evenodd" d="M15.48,493.48c2.422,0.002,4.069,1.987,6.447,2.365c-0.206,0.197-0.277,0.324-0.371,0.344
				c-1.165,0.245-2.458-1.776-3.278-0.282c-0.591,1.077,0.751,2.37,1.365,3.511c0.133,0.246,0.522,0.385,0.205,0.94
				c-1.299-1.141-2.326-2.54-2.308-4.141c0.018-1.683-0.858-1.981-2.06-2.258C15.48,493.8,15.48,493.64,15.48,493.48z"/>
			<path fill-rule="evenodd" d="M24.164,392.668c-2.895,2.244-5.789,4.488-8.684,6.732c0-0.48,0-0.96,0-1.44
				c2.303-0.669,3.757-2.595,5.666-3.849C22.098,393.485,22.886,392.589,24.164,392.668z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(80)} fill={props.fillColors[80]} d="M596.206,729.597c0.505-0.146,1.01-0.291,1.514-0.437c0,1.28,0,2.56,0,3.84
				c-1.216,0.174-2.319-0.243-3.538-0.652C594.566,731.175,596.063,730.856,596.206,729.597z"/>
			<path fill-rule="evenodd" d="M15.48,454.12c1.685-0.403,3.131,0.504,4.891,1.141c-0.079-1.907-1.591-2.476-2.294-3.56
				c1.254-0.196,2.042,0.69,2.419,1.54c0.61,1.374,1.538,1.826,2.944,1.895c-1.761,0.487-3.46,1.108-5.212,0.012
				c-0.835-0.522-1.8-0.525-2.749-0.548C15.48,454.44,15.48,454.28,15.48,454.12z"/>
			<path fill-rule="evenodd" d="M597.72,302.92c-0.991-1.253-1.252-2.826-1.725-4.275c-0.427-1.311,0.215-2.287,1.725-2.444
				C597.72,298.44,597.72,300.68,597.72,302.92z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(81)} fill={props.fillColors[81]} d="M597.72,673.48c-1.311-0.245-2.323-1.177-2.479-2.287
				c-0.216-1.532,1.507-1.791,2.479-2.514C597.72,670.28,597.72,671.88,597.72,673.48z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(82)} fill={props.fillColors[82]} d="M15.48,423.4c1.506-0.316,3.013-0.633,4.539-0.953c0.341,1.164-1.171,1.015-1.129,1.87
				l0.083-0.081c-1.371,0.059-2.178,1.378-3.492,1.563C15.48,425,15.48,424.2,15.48,423.4z"/>
			<path fill-rule="evenodd" d="M597.72,532.84c-0.523-0.676-1.095-1.321-1.562-2.034c-1.232-1.874-0.798-2.62,1.562-2.766
				C597.72,529.64,597.72,531.24,597.72,532.84z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(83)} fill={props.fillColors[83]} d="M15.48,430.6c1.827,0.911,2.873,2.473,3.986,4.621
				c-1.621-0.708-2.803-1.225-3.986-1.74C15.48,432.52,15.48,431.56,15.48,430.6z"/>
			<path fill-rule="evenodd" d="M24.653,669.122c-2.795,1.914-6.293,2.166-9.173,3.878c0-0.32,0-0.64,0-0.96
				C18.539,671.07,21.43,669.574,24.653,669.122z"/>
			<path fill-rule="evenodd" d="M15.48,659.56c2.337-0.068,4.357-1.329,6.599-1.755c1.154-0.22,2.25-0.808,3.785-0.467
				c-3.632,1.113-7.008,2.147-10.384,3.182C15.48,660.2,15.48,659.88,15.48,659.56z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(84)} fill={props.fillColors[84]} d="M597.72,544.36c-2.297,0.119-3.776-1.029-4.985-3.283
				c1.876,0.693,3.432,1.269,4.985,1.843C597.72,543.4,597.72,543.88,597.72,544.36z"/>
			<path fill-rule="evenodd" d="M597.72,740.68c-3.207-0.987-6.62-0.697-9.851-1.565c3.323-0.364,6.564,0.489,9.851,0.605
				C597.72,740.04,597.72,740.359,597.72,740.68z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(85)} fill={props.fillColors[85]} d="M15.48,443.56c1.197-0.163,2.328,0.006,3.477,0.736
				c-0.905,1.161-2.261,1.547-3.477,2.145C15.48,445.48,15.48,444.52,15.48,443.56z"/>
			<path fill-rule="evenodd" d="M15.48,532.36c0.146-0.056,0.29-0.121,0.439-0.164c3.526-1.023,3.526-1.022,0.801-3.708
				c0.79,0.004,1.28,0.604,1.815,1.02c1.516,1.182,1.384,1.991-0.454,2.679c-0.633,0.236-0.91,0.53-0.896,1.161
				c-0.568-0.169-1.137-0.339-1.706-0.508C15.48,532.68,15.48,532.52,15.48,532.36z"/>
			<path fill-rule="evenodd" d="M19.842,511.738c-1.454-0.485-2.908-0.973-4.362-1.458c0-0.801,0-1.601,0-2.4
				C16.934,509.166,18.388,510.452,19.842,511.738z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(86)} fill={props.fillColors[86]} d="M15.791,576.476c-0.104,0.016-0.207,0.03-0.311,0.044c0-0.8,0-1.6,0-2.399
				c2.167-0.145,2.826,0.903,2.316,2.961C17.099,576.871,16.445,576.673,15.791,576.476z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(87)} fill={props.fillColors[87]} d="M15.48,589.96c0.631,0.315,1.262,0.631,1.893,0.947
				c1.282,2.304-0.134,2.493-1.893,2.413C15.48,592.2,15.48,591.08,15.48,589.96z"/>
			<path fill-rule="evenodd" d="M24.133,684.554c-2.942-0.799-5.603-2.437-8.653-2.914c0-0.159,0-0.319,0-0.479
				C18.552,681.812,21.5,682.782,24.133,684.554z"/>
			<path fill-rule="evenodd" d="M38.663,86.471c-2.296-1.167-4.319-2.102-5.986-3.623c-2.722-2.484-3.093-5.582-0.562-8.235
				c4.305-4.515,9.581-6.177,15.715-4.526c1.59,0.428,3.041,1.315,2.346-1.722c-0.577-2.519,1.46-4.96,4.369-5.779
				c4.261-1.201,8.427-0.809,12.255,1.463c1.169,0.693,1.8,0.666,2.899-0.134c4.546-3.308,9.4-6.06,15.034-7.063
				c0.98-0.175,1.444-0.454,1.293-1.505c-1.555-10.83,7.962-14.162,15.733-11.879c0.459,0.135,0.956,0.197,1.374,0.412
				c1.311,0.675,1.546,0.187,1.626-1.131c0.498-8.213,4.964-13.941,12.34-16.718c17.942-6.755,34.493-3.857,49.304,8.445
				c3.762,3.125,7.433,6.336,9.578,10.873c0.465,0.985,0.887,0.808,1.636,0.33c2.193-1.398,4.497-2.368,7.192-1.468
				c0.839,0.279,1.322-0.127,1.507-1.029c1.067-5.222,5.613-6.683,10.703-4.312c1.531,0.713,2.753,0.942,4.46,0.34
				c4.671-1.648,9.547,1.622,10.195,6.898c0.3,2.438,0.781,2.316,2.725,1.311c9.803-5.067,20.934,1.026,23.066,12.468
				c0.318,1.706,0.412,3.42,0.286,5.542c2.771-1.902,5.085-3.801,7.732-5.247c9.18-5.013,18.92,2.098,21.877,8.081
				c1.189,2.405,1.408,5.224,2.737,7.747c1.354,2.572,0.014,5.295-2.594,6.306c-1.596,0.619-1.158,0.995-0.311,1.84
				c0.9,0.897,1.826,1.825,2.48,2.899c1.486,2.441,1.652,5.055,0.021,7.468c-1.651,2.442-4.304,2.622-6.886,2.219
				c-3.33-0.52-5.74-2.629-7.76-5.208c-0.424-0.541-0.806-1.116-1.242-1.724c-0.205,0.229-0.373,0.33-0.394,0.455
				c-1.324,7.875-6.23,12.071-14.594,12.063c-2.548-0.003-4.01,0.68-5.265,2.992c-1.827,3.369-4.42,6.252-7.323,8.804
				c-5.929,5.212-13.612,5.281-19.57-0.022c-5.701-5.075-8.584-11.419-7.823-19.167c0.161-1.637,0.01-2.566-2.02-2.847
				c-3.606-0.496-6.46-2.646-9.054-5.086c-0.614-0.578-1.047-1.349-1.857-1.858c-0.111,1.982-0.133,3.742-0.613,5.477
				c-0.956,3.451-2.809,5.161-6.253,5.373c-0.983,0.061-0.982,0.557-1.049,1.195c-0.508,4.848-3.569,7.141-8.06,7.735
				c-1.561,0.207-1.381,1.002-1.215,1.958c0.848,4.884,0.056,9.539-2.016,13.983c-3.018,6.472-10.792,10.921-17.354,10.072
				c-3.785-0.489-6.815-2.18-8.913-5.392c-0.525-0.805-0.756-1.396-1.951-0.669c-3.409,2.072-5.31,1.64-7.868-1.373
				c-1.434-1.689-2.29-3.648-2.824-5.75c-0.342-1.348-0.733-1.925-2.253-1.118c-3.328,1.766-6.695,2.09-9.856-0.48
				c-0.757-0.616-1.335-0.083-1.96,0.229c-7.312,3.644-14.905,0.876-17.938-6.624c-0.51-1.262-0.958-1.264-2.076-0.745
				c-5.4,2.509-9.365,1.809-13.427-2.435c-0.956-0.999-1.421-1.007-2.485-0.148c-2.738,2.21-5.797,3.485-9.208,1.479
				c-1.131-0.665-1.305,0.025-1.776,0.684c-3.336,4.668-8.397,4.972-11.536-0.27c-0.725-1.211-1.521-0.735-2.447-0.499
				c-5.514,1.403-10.775,0.939-15.623-2.26c-6.658-4.394-7.17-11.599-1.206-16.914C36.771,87.96,37.599,87.337,38.663,86.471z"/>
			<path fill-rule="evenodd" d="M407.704,84.843c2.237-6.989,5.223-9.204,12.638-9.356c1.199-0.024,1.898-0.4,2.488-1.404
				c1.334-2.272,2.897-4.38,4.745-6.278c6.285-6.452,15.094-5.359,19.765,2.434c2.076,3.463,3.177,7.129,2.689,11.192
				c-0.083,0.689-0.449,1.587,0.613,1.736c3.743,0.525,6.533,2.686,9.258,5.523c0.192-1.757,0.166-3.198,0.69-4.528
				c0.793-2.011,1.787-3.847,4.358-3.742c0.871,0.036,1.088-0.311,1.171-1.079c0.371-3.418,2.438-5.274,5.71-5.736
				c1.385-0.195,1.434-0.87,1.342-1.941c-0.335-3.893,0.117-7.623,2.059-11.13c3.195-5.775,10.287-8.482,15.985-5.984
				c0.438,0.191,0.956,0.341,1.257,0.673c1.729,1.905,3.222,3.646,6.378,2.14c1.429-0.681,3.05,1,4.002,2.484
				c0.344,0.535,0.717,1.1,0.862,1.703c0.647,2.684,1.863,3.875,4.681,2.297c1.233-0.691,2.971-0.443,3.939,0.248
				c1.791,1.278,3.12,0.53,4.778-0.039c4.693-1.608,9.731,0.61,11.733,5.21c0.564,1.297,0.892,1.541,2.177,0.949
				c3.778-1.743,7.163-1.14,9.912,2.106c0.586,0.692,0.961,0.745,1.75,0.179c2.235-1.606,4.568-3.002,7.412-1.198
				c0.86,0.546,1.022-0.428,1.396-0.841c3.1-3.432,5.634-3.455,8.418,0.185c0.866,1.132,1.632,0.683,2.488,0.462
				c3.927-1.014,7.602-0.426,11.071,1.622c5.889,3.476,6.29,9.69,0.895,13.866c-0.371,0.287-0.747,0.567-1.29,0.978
				c1.674,1.167,3.404,1.963,4.711,3.352c1.947,2.067,2.055,4.352-0.036,6.293c-3.935,3.654-8.505,4.41-13.694,2.428
				c0.015,2.256,0.34,4.515-2.098,5.697c-3.437,1.667-6.881,1.39-10.14-0.262c-1.365-0.692-2.147-0.663-3.316,0.243
				c-3.122,2.415-6.621,4.162-10.499,4.988c-1.087,0.231-1.479,0.507-1.341,1.678c0.847,7.162-5.626,10.466-11.027,8.945
				c-2.369-0.667-3.398-0.709-3.727,2.292c-0.652,5.987-4.783,9.504-10.108,11.328c-13.354,4.574-25.632,2.32-36.588-6.629
				c-2.956-2.415-5.993-4.81-7.634-8.437c-0.371-0.821-0.895-0.768-1.529-0.33c-0.262,0.181-0.545,0.333-0.821,0.492
				c-1.558,0.9-3.475,0.137-4.842,0.707c-1.294,0.54-1.156,2.99-2.644,3.976c-1.944,1.292-3.98,0.969-5.922,0.117
				c-1.436-0.63-2.642-0.828-4.261-0.307c-3.316,1.066-6.554-1.235-7.325-5.029c-0.361-1.776-0.73-2.209-2.56-1.256
				c-5.992,3.125-13.188,0.42-16.079-5.829c-1.114-2.408-1.711-4.928-1.608-7.959c-1.951,1.312-3.546,2.535-5.275,3.523
				c-7.235,4.133-14.222-0.433-17.287-5.742c-1.104-1.912-1.505-4.367-2.379-6.535c-0.728-1.803,0.173-3.453,1.797-4.222
				c1.565-0.743,0.909-1.157,0.197-1.957c-2.831-3.184-3.202-5.99-1.135-8.045c1.966-1.954,6.409-1.589,8.983,0.795
				C405.86,82.788,406.723,83.806,407.704,84.843z"/>
			<path fill-rule="evenodd" d="M155.806,206.3c-0.804-0.912-1.951-1.143-2.716-2.007c-1.575-1.778-1.465-3.616,0.585-5
				c2.46-1.662,5.118-2.178,7.967-1.012c0.382-3.727,2.864-5.363,6.635-3.983c2.046,0.749,3.562,0.629,5.152-0.675
				c0.366-0.3,0.817-0.5,1.242-0.724c3.324-1.753,3.972-2.43,5.374-5.636c1.078-2.465,3.155-3.411,6.904-2.751
				c1.553,0.273,1.977,0.017,2.266-1.566c0.857-4.705,5.079-7.872,11.101-8.583c9.814-1.157,17.626,2.439,23.728,10.05
				c0.963,1.202,5.003,0.939,5.688-0.506c1.102-2.323,2.826-2.632,4.952-1.747c0.854,0.356,1.637,0.456,2.547,0.304
				c2.725-0.456,4.195,0.424,5.097,3.075c0.269,0.79,0.203,1.578,1.456,1.018c6.146-2.748,10.823,1.583,11.558,7.045
				c0.062,0.462,0.124,0.924,0.193,1.439c1.314-0.12,2.011-1.11,2.951-1.64c4.884-2.755,10.598-0.319,12.013,5.15
				c0.332,1.279,1.816,2.665,0.978,3.754c-1.533,1.993-0.745,3.412,0.213,5.16c0.558,1.017,0.26,2.282-0.702,3.062
				c-1.102,0.893-2.417,1.132-3.821,0.676c-1.516-0.491-2.448-1.715-3.762-3.065c-1.05,4.374-3.907,6.04-8.031,5.909
				c-1.08-0.034-1.629,0.807-2.167,1.659c-1.197,1.896-2.521,3.678-4.459,4.94c-2.731,1.779-6.168,1.7-8.648-0.387
				c-2.848-2.397-4.421-5.477-4.289-9.218c0.047-1.317-0.306-1.844-1.521-2.102c-1.845-0.392-3.138-1.755-4.536-2.952
				c-1.021,1.266,0.042,3.446-1.514,3.952c-3.053,0.993-3.071,5.324-6.661,5.644c-0.897,0.08-0.371,1.217-0.372,1.884
				c-0.009,4.151-1.132,7.725-5.026,9.911c-3.116,1.75-6.128,1.456-8.797-0.941c-0.537-0.482-0.927-1.084-1.819-0.736
				c-2.538,0.988-3.769-0.536-4.494-2.585c-0.541-1.527-1.051-1.718-2.832-1.523c-2.915,0.319-5.936,0.205-8.906,0.226
				c-1.938,0.013-3.527-1.446-4.432-3.268c-0.496-0.997-0.98-1.183-2.046-0.767c-2.088,0.814-4.075,0.504-5.76-1.095
				c-0.172-0.164-0.445-0.503-0.5-0.471c-2.097,1.238-4.786,0.413-6.708,1.571c-2.492,1.502-3.107,1.879-5.152-0.073
				c-0.853-0.815-1.633-0.805-2.597-0.604c-2.575,0.536-4.95-0.001-7.034-1.554c-3.239-2.413-3.352-5.529-0.384-8.278
				C155.069,206.957,155.425,206.644,155.806,206.3z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(88)} fill={props.fillColors[88]} d="M360.958,380.831c0.629-0.512,1.117-0.929,0.782-1.979
				c-2.062-6.465-3.438-13.094-4.361-19.812c-0.333-2.428-0.446-4.886-0.625-7.333c-0.062-0.838,0.073-1.836,1.142-1.703
				c1.566,0.197,3.123,0.36,4.7,0.441c0.897,0.046,0.697,0.938,0.729,1.528c0.264,4.906,0.882,9.769,1.85,14.581
				c0.482,2.397,1.164,4.753,1.768,7.125c0.253,0.995,0.547,1.976,1.771,2.429c-0.961-4.232-2.048-8.342-2.795-12.513
				c-0.624-3.487-0.828-7.049-1.266-10.571c-0.199-1.602,0.266-2.306,2.032-2.206c1.255,0.071,2.484,0.267,3.757,0.535
				c-0.627-3.221-1.117-3.536-5.833-3.738c-1.662-0.072-3.304-0.121-4.972-0.384c-1.57-0.248-1.617-0.957-1.406-2.062
				c1.509-7.941,4.07-15.534,7.192-22.992c4.504-10.76,9.8-21.218,12.81-32.571c1.237-4.668,2.109-9.434,2.266-14.224
				c0.321-9.865,2.553-19.226,6.412-28.253c0.515-1.204,0.986-2.427,1.904-3.532c-0.546,4.93-1.071,9.861-1.642,14.786
				c-0.462,3.99-0.935,7.977-1.496,11.957c-0.915,6.487-2.107,12.914-3.453,19.317c-1.804,8.576-4.785,16.856-6.38,25.492
				c-1.129,6.117-2.109,12.242-2.841,18.422c-0.276,2.341-0.032,4.741-0.755,7.056c-0.296,0.949-0.117,2.064-0.054,3.098
				c0.029,0.473,0.358,0.945,0.935,0.853c0.535-0.085,0.354-0.614,0.395-0.973c0.478-4.314,0.978-8.625,1.412-12.943
				c0.895-8.888,2.664-17.607,5.029-26.21c3.013-10.961,5.521-22.02,7.07-33.299c1.139-8.284,2.255-16.558,2.876-24.906
				c0.559-7.496,1.381-14.971,1.897-22.477c0.53-7.716,1.519-15.4,2.275-23.102c0.065-0.672,0.327-1.098,0.746-1.631
				c1.97-2.508,4.55-4.439,6.431-7.043c0.904-1.253,6.068-1.353,7.183-0.375c0.45,0.395,0.104,0.898,0.092,1.334
				c-0.141,4.987-0.729,9.932-1.271,14.891c-0.48,4.391-0.918,8.783-1.5,13.166c-1.015,7.646-2.39,15.22-4.05,22.75
				c-2.102,9.531-5.403,18.771-6.934,28.437c-0.84,5.301-1.691,10.591-2.126,15.963c-0.706,8.739-1.834,17.443-2.752,26.166
				c-0.053,0.499-1.167,1.375,0.368,1.519c1.17,0.109,0.925-0.748,1.011-1.436c1.145-9.177,1.991-18.385,2.885-27.589
				c1.136-11.702,3.64-23.17,6.865-34.473c2.875-10.074,4.778-20.332,6.111-30.716c0.915-7.127,1.778-14.252,2.423-21.411
				c0.205-2.278,0.176-4.586,0.567-6.859c0.245-1.414,0.806-1.913,2.309-1.919c1.701-0.007,3.275,0.539,5.027,0.616
				c-1.082,5.663-2.252,11.245-3.197,16.864c-1.058,6.287-1.905,12.606-2.508,18.958c-0.726,7.646-1.548,15.281-2.239,22.93
				c-0.406,4.502-0.623,9.022-0.958,13.532c-0.456,6.15-1.067,12.283-1.75,18.415c-0.497,4.473-0.959,8.949-1.534,13.416
				c-0.895,6.947-2.137,13.83-3.59,20.676c-0.7,3.296-1.496,6.572-2.264,9.925c1.471,0.043,1.759-0.846,1.979-1.803
				c1.912-8.27,3.56-16.58,4.822-24.986c1.325-8.824,2.133-17.699,3.185-26.549c0.56-4.713,0.621-9.482,1.019-14.217
				c0.53-6.309,1.323-12.6,1.709-18.916c0.305-4.988,0.939-9.93,1.445-14.892c0.616-6.048,1.478-12.06,2.562-18.037
				c0.396-2.184,0.33-4.488,1.479-6.933c0.276,10.247,2.883,19.718,4.316,29.366c1.18,7.933,1.456,15.915,0.671,23.964
				c-0.767,7.861-1.773,15.686-2.998,23.474c-1.394,8.863-3.097,17.684-5.247,26.402c-1.721,6.973-3.32,13.99-5.436,20.846
				c-1.41,4.572-2.916,9.118-4.184,13.734c-0.163,0.593-1.047,1.443,0.024,1.808c0.938,0.32,1.187-0.501,1.423-1.405
				c1.415-5.415,3.072-10.754,5.112-15.977c3.223-8.251,7.761-15.715,13.289-22.599c0.815-1.016,1.279-2.376,2.91-2.985
				c-0.353,3.848-0.809,7.561-1.01,11.287c-0.378,6.97-4.61,12.495-6.884,18.75c-2.507,6.898-5.366,13.669-8.098,20.485
				c-0.511,1.275-0.477,2.734-0.491,3.933c-0.043,3.644-3.31,5.34-4.299,8.293c-0.264,0.784-0.525,0.87-1.152,0.499
				c-0.597-0.353-1.201-0.69-2.032-1.166c0,1.635,0,3.085,0,4.622c1.846-0.341,3.276-1.848,5.225-1.947
				c0.389-0.021,0.53-0.503,0.688-0.89c1.596-3.897,4.408-5.708,8.722-5.993c4.34-0.288,8.518-1.764,12.491-3.595
				c1.108-0.511,1.854-0.462,2.773,0.342c0.575,0.503,1.64,0.747,1.356,1.645c-0.258,0.812-1.27,0.296-1.938,0.409
				c-7.387,1.253-14.202,3.847-20.115,8.555c-0.206,0.165-0.268,0.511-0.12,1.103c1.929-1.242,3.812-2.561,5.794-3.71
				c5.557-3.223,11.647-4.848,17.983-5.361c1.252-0.101,2.621,1.255,4.487,2.226c-4.769,1.46-9.211,2.317-13.265,4.317
				c-4.094,2.02-7.938,4.375-11.541,7.471c1.932,0.745,2.707-0.883,3.58-1.57c6.135-4.829,13.201-7.382,20.705-8.966
				c2.397-0.505,4.309,0.774,6.615,2.056c-5.096,1.251-9.887,2.469-14.411,4.52c-4.938,2.239-9.644,4.826-13.646,8.54
				c-0.636,0.589-1.521,1.574-0.945,2.209c0.838,0.923,1.092,2.775,2.979,2.471l-0.057-0.068c1.005,4.149,3.828,7.555,4.763,11.745
				c0.48,2.157,1.124,4.276,1.121,6.66c2.83-0.644,4.338-2.932,6.374-4.417c5.011-3.654,10.53-6.07,16.646-7.021
				c0.906-0.141,2.482-0.776,2.872,0.674c0.59,2.199-0.972,4.624-2.638,4.84c-6.374,0.827-12.26,2.951-17.455,6.816
				c-0.304,0.227-1.108,0.21-0.646,0.93c0.51,0.793,0.872,0.12,1.31-0.17c3.487-2.319,7.107-4.369,11.216-5.407
				c1.379-0.349,2.734-0.799,4.123-1.097c0.989-0.211,2.286-0.915,2.721,0.713c0.438,1.638-0.939,3.624-2.359,3.819
				c-5.635,0.771-10.821,2.723-15.449,6.063c-0.33,0.238-0.851,0.504-0.499,1.353c3.266-2.058,6.431-4.259,10.235-5.13
				c1.686-0.386,3.348-0.882,5.033-1.27c0.558-0.129,1.387-0.495,1.559,0.422c0.146,0.781-0.135,1.564-1.074,1.835
				c-1.512,0.436-3.024,0.865-4.535,1.305c-5.927,1.723-10.479,5.465-14.545,9.915c-0.326,0.356-0.312,0.369-0.413,1.95
				c4.552-5.729,10.148-9.511,17.005-11.381c0.694-0.189,1.464-0.713,2.001-0.128c0.497,0.54-0.328,1.105-0.494,1.685
				c-0.857,2.993-3.778,3.44-5.993,4.629c-4.838,2.598-8.222,6.604-11.313,10.948c-0.133,0.186,0.014,0.57,0.071,1.61
				c3.889-6.069,8.441-10.479,14.987-13.132c-0.858,2.302-1.916,4.1-3.532,5.455c-6.383,5.354-9.7,12.276-10.75,20.429
				c-0.143,1.11-0.841,2.182,0.308,3.985c0.879-8.41,3.402-15.526,9.144-21.203c-1.31,2.81-2.684,5.593-3.909,8.439
				c-1.244,2.886-1.895,5.941-2.316,9.269c2.57-1.093,4.026-2.649,4.364-5.354c0.485-3.879,1.175-4.253,5.129-3.854
				c1.121,0.113,1.257,0.482,0.646,1.275c-0.725,0.94-1.522,1.826-2.309,2.758c1.5,0.604,1.492-0.868,2.051-1.461
				c0.655-0.695,1.148-1.544,1.845-2.51c2.016,2.207,2.208,4.62,3.548,6.43c0.481,0.649-0.534,1.152-1.317,1.136
				c-1.24-0.028-2.446,0.003-3.628,1.027c1.297,0.514,2.304-0.492,3.345-0.16c0.421,0.134,0.949-0.583,1.198,0.061
				c0.15,0.388,0.111,1.077-0.144,1.358c-1.979,2.184-3.031,4.979-4.835,7.457c-0.898-1.213-1.688-2.278-2.477-3.344
				c0.105,1.603,0.529,3.062,1.546,4.206c-0.726,0.701-1.206,0.163-1.72,0.125c-1.221-0.091-2.665-0.809-3.139,1.284
				c-0.427,1.886,0.256,3.016,1.88,3.772c0.356,0.167,0.718,0.386,1.095,0.433c0.82,0.102,1.305,0.511,1.527,1.293
				c0.282,0.989-0.549,1.198-1.104,1.574c-1.05,0.712-2.126,1.387-3.19,2.076c-0.299-0.88,0.202-1.465,0.742-2.081
				c-1.406,1.704-4.092-0.507-5.246,1.725c-0.577,1.116-0.96,2.378-0.188,3.628c-1.771,2.339-4.395,3.166-7.031,3.979
				c-0.728,0.225-0.798-0.22-0.799-0.736c-0.002-1.306,0.046-2.624,1.599-3.091c2.811-0.845,4.014-3.162,4.681-5.62
				c0.565-2.08,1.425-3.294,3.651-3.157c0.999,0.062,1.182-0.439,1.207-1.289c0.04-1.331-0.207-2.671,0.402-3.988
				c0.28-0.605,0.424-1.17,1.133-1.362c1.644-0.447,2.821-1.083,1.429-3.017c-0.309-0.429-0.025-0.802,0.545-1.059
				c2.919-1.312,3.074-4.112,3.591-6.746c0.239-1.222-0.166-1.138-1.127-0.949c-2.431,0.475-4.334,1.802-6.115,3.405
				c-3.133,2.82-6.336,5.525-10.564,6.605c-1.76,0.45-3.496,0.962-5.351,0.864c-2.383-0.126-4.737-2.791-4.517-5.163
				c0.058-0.618,0.367-0.961,0.932-1.247c4.922-2.499,5.068-3.099,1.902-7.754c3.91-0.95,7.782-1.865,11.636-2.847
				c0.8-0.203,2.085,0.016,1.831-1.55c-0.212-1.316-0.989-1.099-2.053-1.195c-1.77-0.16-3.54-0.104-5.602-0.24
				c3.362-0.689,2.66-3.111,2.604-5.144c-0.054-1.996,0.267-3.941,0.461-5.909c0.14-1.425,1.13-2.01,2.149-2.647
				c1.068-0.669,2.05-1.354,0.966-2.804c-0.226-0.304-0.651-0.856,0.167-1.091c1.545-0.441,0.677-1.386,0.321-2.032
				c-0.563-1.028-1.373-1.893-1.93-2.965c-0.662-1.276-1.537-0.604-2.287,0.155c-2.472,2.501-5.014,4.934-7.429,7.488
				c-1.234,1.308-2.641,1.838-4.395,1.932c-1.327,0.071-2.64,0.46-3.951,0.743c-0.8,0.173-1.574,0.212-2.345-0.111
				c-0.832-0.349-1.693-0.834-1.912-1.682c-0.238-0.918,0.895-1.007,1.417-1.366c3.192-2.194,6.389-4.399,9.722-6.366
				c1.48-0.872,1.189-2.015,0.914-3.063c-0.373-1.421-1.543-0.467-2.458-0.418c0.158-3.017,1.165-5.613,2.685-8.191
				c1.137-1.93,0.378-4.37,0.312-6.569c-0.025-0.837-0.932-0.529-1.463-0.153c-1.094,0.774-2.172,1.569-3.247,2.369
				c-0.376,0.28-0.69,0.748-1.235,0.534c-0.697-0.273-0.526-0.957-0.484-1.459c0.167-1.968,1.363-3.521,2.229-5.203
				c0.801-1.555,1.616-3.104,2.447-4.643c0.231-0.43,0.579-0.899,0.297-1.33c-0.358-0.546-1.025-0.371-1.548-0.257
				c-2.564,0.558-4.554,2.091-6.525,3.74c-2.955,2.472-5.82,5.119-9.751,6.087c-0.72,0.177-1.37,0.298-2.47,0.118
				c1.646-2.712,3.135-5.26,6.208-6.372c0.048,1.142-0.4,2.036-1.109,2.499c0.624-0.502,1.204-1.327,1.111-2.491
				c1.033-2.153,3.585-2.935,4.594-5.188c0.406-0.907,1.021-1.79,1.263-2.75c0.159-0.634,0.928-1.37,0.299-1.99
				c-0.685-0.675-1.664-0.457-2.469-0.129c-1.669,0.677-3.335,1.383-4.93,2.217c-1.117,0.584-1.404,0.283-1.35-0.845
				c0.038-0.798,0.012-1.599,0.012-2.399c0.004-4.545,0.206-4.808,4.656-5.214c2.09-0.191,2.734-1.388,2.44-3.251
				c-0.143-0.906-0.712-1.761,0.59-2.355c0.547-0.25,0.488-1.069,0.202-1.512c-0.336-0.521-0.993-0.397-1.527-0.177
				c-1.813,0.748-3.617,1.521-5.432,2.267c-0.396,0.162-0.813,0.695-1.251,0.146c-0.401-0.505,0.134-0.98,0.357-1.247
				c0.971-1.158,1.941-2.381,3.143-3.265c1.842-1.355,2.665-3.216,3.314-5.261c0.213-0.67,0.189-1.247-0.285-1.773
				c-0.644-0.712-1.218-0.013-1.69,0.206c-4.149,1.918-8.265,3.909-12.919,6.131c1.906-3.161,2.381-6.243,3.065-9.277
				c0.409-1.813,2.137-2.729,3.309-4.007c1.555-1.694,3.435-3.111,4.312-5.346c0.306-0.78,1.103-1.674,0.206-2.367
				c-0.683-0.527-1.406,0.113-2.1,0.603c-3.744,2.646-7.55,5.204-11.328,7.801c-0.268,0.183-0.504,0.377-1.182,0.08
				c1.15-1.922,2.275-3.824,3.422-5.714c1.319-2.175,2.675-4.329,3.975-6.517c0.302-0.509,0.877-1.155,0.281-1.707
				c-0.503-0.467-1.188-0.171-1.787,0.125c-1.186,0.586-2.393,1.127-3.597,1.689c-0.068-0.21-0.187-0.377-0.145-0.474
				c1.912-4.404,1.914-4.404-2.776-4.405c-0.64,0-1.28,0.003-1.92,0.007c-2.505,0.013-3.799,2.085-2.692,4.309
				c0.071,0.143,0.145,0.288,0.192,0.439c0.769,2.424,2.116,3.48,4.788,2.059c-0.656,1.099-1.267,2.229-1.98,3.289
				c-0.93,1.379-1.997,0.082-2.984,0.016c-0.657-0.044-1.402-0.796-1.908,0.014c-0.365,0.585,0.307,1.144,0.637,1.667
				c0.837,1.328,2.16,2.58,2.389,4.006c0.304,1.902-2.126,1.859-3.186,2.907c-4.509,4.458-4.768,5.258-2.025,10.931
				c0.906,1.875-1.86,1.851-1.59,3.337c-1.478,0.157-3.034,0.655-4.161-0.848c-0.847-1.131-1.631-2.308-2.496-3.424
				c-0.517-0.667-1.163-1.162-2.027-0.471c-0.755,0.604-1.016,1.167-0.512,2.231c1.38,2.913,2.619,5.898,3.779,8.907
				c1.444,3.747,4.506,6.122,7.202,8.817c0.164,0.164,0.539,0.208,0.592,0.377c0.36,1.161,2.782,1.458,1.763,2.852
				c-0.631,0.862-1.838-0.693-2.735-1.185c-3.186-1.746-6.275-3.663-9.426-5.473c-0.893-0.513-1.785-0.922-2.812-0.002
				c-1.256,1.123,0.152,1.665,0.432,2.377c0.466,1.188,1.642,1.906,2.444,2.881c0.759,0.922,1.287,1.708,1.242,2.875
				c-0.025,0.673,0.091,1.55,0.797,1.54c2.264-0.034,1.414,1.247,1.088,2.306c-0.256,0.83-0.506,1.682-0.918,2.438
				c-0.435,0.799,0.033,2.284-1.511,2.28c-1.448-0.003-3.073,0.438-3.981-1.327c-0.21-0.406-0.65-0.695-0.988-1.034
				c-0.813-0.812-1.742-1.548-2.936-1.316c-1.378,0.268-0.827,1.564-0.89,2.38c-0.282,3.687-1.926,5.975-5.758,6.69
				c-1.169,0.218-3.085,1.094-2.906,2.297c0.239,1.615,2.12,0.586,3.27,0.741c2.518,0.341,5.048,0.614,7.602,0.486
				c0.659-0.033,1.476,0.121,1.593,0.767c0.126,0.697-0.725,0.926-1.268,1.242c-2.874,1.671-6.176,2.483-8.908,4.438
				c-0.382,0.273-0.898,0.458-1.125,0.825c-0.566,0.917-1.693,1.979-1.191,2.917c0.583,1.088,1.895,0.305,2.96,0.165
				c2.579-0.338,5.175-0.515,7.74-1.025c0.909-0.181,1.201-0.485,1.223-1.35c0.066-2.795,2.208-1.266,3.404-1.498
				c1.591-0.308,2.025,1.135,1.662,2.126c-0.777,2.128,0.458,3.382,1.487,4.83c0.584,0.82,1.069,1.71,1.812,2.912
				c-3.367-0.19-6.269-1.116-9.229-1.635c-1.939-0.339-3.853-0.846-5.8-1.119c-1.3-0.183-1.581,0.117-1.86,1.854
				c-0.792,4.934,2.681,5.483,5.702,6.753c2.96,1.245,5.919,2.491,8.954,3.768c-1.814,2.298-3.544,2.707-5.896,1.434
				c-3.368-1.824-6.057-4.584-9.13-6.821c-2.682-1.951-5.17-4.166-7.775-6.226c-0.474-0.375-1.032-0.867-1.723-0.599
				c-0.759,0.295-0.699,1.067-0.629,1.66c0.197,1.659-0.659,3.396,1.175,4.95c0.825,0.7,0.477,2.845,0.561,4.339
				c0.066,1.177,0.414,1.695,1.605,1.045c0.137-0.074,0.313-0.153,0.453-0.129c1.004,0.177,2.211-1.259,2.841-0.203
				c0.635,1.065,0.549,2.572,0.725,3.893c0.093,0.688-0.298,1.022-0.992,1.019c-0.879-0.005-1.762-0.034-2.638,0.021
				c-1.41,0.088-3.244-0.454-3.709,1.533c-0.695,2.978,0.191,4.804,2.678,5.495c1.396,0.388,2.044,1.296,2.801,2.338
				c1.848,2.545,3.702,5.081,5.221,7.842c0.38,0.691,0.828,1.452,0.195,2.213c-0.598,0.719-1.352,0.501-2.177,0.341
				c-3.763-0.73-7.287-2.159-10.818-3.566c-0.863-0.345-1.659-0.679-2.383,0.146c-0.698,0.797-0.387,1.587,0.085,2.387
				c0.808,1.371,1.57,2.769,2.351,4.156c0.194,0.345,0.404,0.855,0.847,0.674c2.627-1.073,1.977,0.825,1.976,2.135
				c-0.001,0.83,0.297,1.621,0.81,2.176c1.354,1.464,0.23,2.865,0.002,4.264c-0.103,0.633-0.699,0.413-1.173,0.228
				c-2.047-0.805-4.131-1.524-6.148-2.396c-1.632-0.703-3.335-0.835-4.95-0.398c-1.511,0.41-2.537-0.244-3.274-1.163
				c-0.896-1.119-0.477-2.368,0.576-3.332c1.341-1.227,2.446-2.632,3.361-4.599c0.89,2.48,1.72,4.671,2.442,6.896
				c0.202,0.619,0.239,1.483,1.007,1.294c0.729-0.179,0.474-1.006,0.27-1.602c-2.093-6.115-4.283-12.188-6.787-18.156
				c-4.104-9.787-9.589-18.725-15.888-27.218c-0.602-0.811-2.394-1.542-1.525-2.546c0.805-0.931,1.883,0.651,2.728,1.171
				c3.023,1.857,6.021,3.751,9.155,5.423c1.114,0.595,1.544,1.762,2.15,2.785c4.833,8.156,9.31,16.483,12.333,25.515
				c0.19,0.568,0.597,0.933,1.584,0.665c-3.189-9.358-7.606-18.092-12.421-26.855c2.611,1.198,5.446,2.075,7.767,3.691
				c2.046,1.425,2.384,4.271,3.5,6.475c0.81,1.602,1.529,3.249,2.505,4.8c0.199-3.77-2.598-6.572-3.342-10.025
				c3.37,0.562,3.704,0.936,4.517,5.07c0.121,0.614-0.306,1.747,0.856,1.493c0.851-0.187,0.535-1.223,0.418-1.884
				c-0.211-1.187-0.843-2.249-0.506-3.591c0.193-0.774-0.864-1.098-1.494-1.314c-12.467-4.29-23.663-10.9-34.187-18.722
				c-8.054-5.985-15.41-12.727-22.099-20.23c-2.967-3.328-5.624-6.87-8.36-10.367c-1.55-1.981-2.137-4.288-2.157-6.646
				c-0.038-4.515-1.132-8.859-1.832-13.818c2.564,5.722,5.134,10.844,8.45,15.572c5.302,7.56,11.375,14.376,18.212,20.619
				c8.296,7.576,17.347,14.052,26.992,19.703c4.228,2.477,8.814,4.34,13.231,6.498c0.533,0.26,1.091,0.567,1.384-0.172
				c0.265-0.669-0.372-0.663-0.81-0.843c-7.415-3.053-14.346-7.026-21.071-11.346c-4.936-3.17-9.612-6.71-14.058-10.556
				c-3.363-2.909-6.859-5.676-9.897-8.936c-7.626-8.181-14.723-16.758-19.464-27.016c-1.029-2.226-1.819-4.562-2.804-6.809
				c-0.517-1.18-0.183-1.41,1.02-1.624c4.639-0.821,4.57-0.855,6.535,3.545c3.812,8.536,9.084,16.078,15.283,23.078
				c8.624,9.735,18.71,17.661,29.58,24.632c6.679,4.284,13.845,7.685,21.288,10.476c0.474,0.177,0.872,0.375,1.586-0.209
				c-1.694-1.421-3.8-1.886-5.651-2.656c-9.923-4.123-19.009-9.643-27.516-16.142c-5.562-4.249-10.949-8.729-15.741-13.845
				c-7.73-8.254-14.351-17.273-18.585-27.877c-0.703-1.761-0.634-2.886,1.63-2.901c0.215-0.001,0.423-0.203,0.644-0.29
				c2.126-0.834,3.254-0.552,4.307,1.981c3.642,8.771,9.166,16.329,15.646,23.301c10.689,11.501,23.141,20.537,37.093,27.581
				c4.041,2.04,8.296,3.599,12.628,4.939c0.729,0.225,1.34,0.363,2.155-0.151c-1.163-1.463-2.98-1.297-4.401-1.771
				c-8.773-2.927-16.697-7.389-24.361-12.501c-7.235-4.825-13.974-10.209-20.035-16.405c-7.535-7.704-13.953-16.217-18.114-26.28
				c-0.521-1.257-0.419-1.804,0.902-2.312c4.22-1.62,4.366-1.666,6.233,2.386c5.125,11.126,13.253,19.776,22.311,27.771
				c8.273,7.304,17.409,13.208,27.296,18.011c2.053,0.998,4.217,1.765,6.266,2.769c0.886,0.434,1.411-0.199,2.161-0.455
				c-2.082-0.835-4.152-1.7-6.25-2.496c-1.905-0.723-3.087-2.127-3.996-3.873c-4.769-9.153-8.34-18.748-10.731-28.787
				c-0.124-0.522-0.635-1.027-0.114-1.519c0.563-0.534,0.992,0.187,1.481,0.318c4.234,1.138,6.266,3.796,7.452,8.197
				c2.599,9.632,6.801,18.658,12.848,26.726c0.754,1.005,1.21,1.16,1.883,0.069L360.958,380.831z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(89)} fill={props.fillColors[89]} d="M577.393,379.019c-1.94-0.141-1.237-2.382-2.891-3.366
				c0.192,3.155-2.773,4.396-3.412,7.115c-0.438,1.866,0.158,2.429,1.602,2.569c1.887,0.183,3.765,0.125,5.572-0.773
				c2.31-1.148,3.557-0.211,3.652,2.546c0.055,1.586-0.33,2.329-2.009,1.28c-1.001-0.626-2.152-1.01-3.171-1.614
				c-1.511-0.895-2.354,0.061-2.166,1.208c0.343,2.101-0.407,4.526,1.505,6.258c0.766,0.694,0.954,1.608,0.257,2.317
				c-0.848,0.86-1.825,0.566-2.793-0.106c-0.673-0.468-1.047-2.173-2.104-1.115c-0.533,0.534-0.784,2.043,0.613,2.836
				c0.781,0.444,0.546,1.042-0.365,1.263c-0.382,0.092-0.731,0.355-1.114,0.408c-2.532,0.347-3.817,1.58-3.124,4.27
				c0.403,1.565-0.938,2.457-1.461,3.649c-0.623,1.423-0.469,2.251,1.177,2.168c2.293-0.116,2.059,1.856,2.463,3.062
				c0.178,0.531-0.615,2.031-1.896,0.854c-0.703-0.646-1.429-1.228-2.216-1.775c-0.732-0.508-1.403-1.049-2.006-0.165
				c-0.519,0.761-1.443,1.765-0.212,2.679c1.749,1.298,1.485,2.988,1.106,4.741c-0.083,0.383-0.16,0.768-0.239,1.151
				c2.888,0.186,1.307,3.766,3.496,4.667c0.408,0.168,2.688,0.198,1.493,2.095c-1.016,1.613-1.939,3.228-4.263,2.021
				c-0.486-0.251-1.022-0.409-1.501-0.673c-1.436-0.791-1.865-0.152-1.681,1.208c0.17,1.244,0.21,2.498,0.422,3.75
				c0.269,1.577,0.663,2.387,2.322,2.179c0.95-0.119,1.23,0.581,1.512,1.231c0.535,1.234,1.041,2.481,1.521,3.738
				c0.574,1.505,2.633,3.206,4.253,3.31c0.995,0.064,1.654,0.484,2.088,1.326c0.704,1.365,1.795,2.192,3.311,2.459
				c0.365,0.064,0.867-0.032,0.861,0.559c-0.004,0.46-0.359,0.713-0.742,0.831c-1.524,0.47-3.018,0.893-4.632,0.148
				c-2.429-1.119-4.932-2.078-7.559-2.643c-0.766-0.165-1.577-0.267-2.353-0.204c-1.411,0.113-1.895,0.806-0.907,2.026
				c0.501,0.619,1.157,1.114,1.646,1.741c1.797,2.301,1.795,2.344-0.897,3.56c-1.088,0.491-1.332,1.038-0.452,1.92
				c0.452,0.453,0.907,0.901,1.362,1.351c1.117,1.101,2.861,2.263-0.329,2.889c-0.506,0.1-0.838,0.53-0.846,1.035
				c-0.067,4.324,0.053,8.701,2.151,12.546c0.43,0.785,2.521,1.262,3.8,2.044c1.704,1.042,1.992,2.272,0.911,3.713
				c-1.099,1.466-2.35,0.714-3.488-0.174c-1.249-0.974-2.068-2.305-3.033-3.52c-1.057-1.329-2.38-2.368-4.086-2.638
				c-1.123-0.178-2.619-0.476-2.225,1.59c0.072,0.377,0.101,0.627-0.316,0.658c-0.542,0.041-0.706-0.36-0.607-0.78
				c0.213-0.91,0.344-1.896,0.797-2.684c0.93-1.617,1.057-3.308,0.942-5.089c-0.046-0.707,0.201-1.543-0.651-1.878
				c-0.763-0.299-1.094,0.488-1.539,0.891c-0.35,0.314-0.609,0.727-1.259,1.525c-0.187-2.324,1.205-3.507,1.486-5.027
				c0.098-0.529,0.456-1.008,0.565-1.536c0.165-0.799,1.148-1.787,0.194-2.371c-0.893-0.548-1.997,0.1-2.745,0.803
				c-1.572,1.479-3.062,3.044-4.582,4.578c-0.778,0.784-1.58,1.51-2.949,1.444c0.812-2.088,2.213-3.528,3.581-4.955
				c1.239-1.293,2.182-2.662,2.44-4.479c0.097-0.677,0.769-1.383,0.177-2.003c-0.715-0.749-1.475,0.15-2.219,0.233
				c-0.766,0.085-1.394,1.712-2.234,0.578c-0.935-1.261,0.138-3.852,1.507-4.271c1.526-0.466,3.191-3.727,2.631-5.246
				c-0.311-0.841-0.816-0.921-1.675-0.498c-0.807,0.397-1.329,1.348-2.594,1.251c0.899-1.779,2.668-2.893,3.1-4.88
				c0.208-0.957,0.632-1.984-0.107-2.644c-0.829-0.74-1.581,0.215-2.294,0.678c-1.442,0.938-2.889,1.869-4.768,3.084
				c0.697-3.086,0.705-5.867,2.864-8.058c1.087-1.103,1.957-2.426,2.351-4.034c0.179-0.733,0.574-1.368-0.199-1.885
				c-0.788-0.527-1.097,0.316-1.529,0.685c-1.675,1.425-3.324,2.879-5.514,4.784c1.19-2.736,2.146-4.85,3.022-6.995
				c0.268-0.654,1.169-1.244,0.389-2.116c-0.806-0.897-1.414,0.169-2.135,0.233c-0.511-0.706,0.779-1.937-0.526-2.253
				c-1.376-0.334-2.949-0.6-4.186,0.151c-0.782,0.476-1.218,1.663-0.676,2.734c0.473,0.934,0.011,2.471,1.824,2.589
				c0.766,0.049,0.061,0.738,0.056,1.12c-0.007,0.616-0.312,0.786-0.941,0.468c-0.483-0.244-1.148-0.945-1.598-0.041
				c-0.323,0.65-0.211,1.357,0.338,1.992c1.197,1.386,0.989,2.406-0.445,3.759c-1.946,1.836-3.111,4.265-1.874,7.069
				c0.52,1.179,0.62,2.242-0.622,2.779c-1.251,0.54-1.37-0.812-1.949-1.48c-0.541-0.623-0.697-2.151-1.945-1.593
				c-1.178,0.528-0.82,1.702-0.479,2.729c1.376,4.146,2.294,8.499,5.379,11.944c-1.446-1.012-2.925-1.981-4.324-3.055
				c-0.639-0.49-1.32-0.858-1.927-0.337c-0.699,0.6-0.456,1.486,0.009,2.17c1.144,1.683,2.025,3.479,2.594,5.43
				c0.107,0.37,0.402,0.785,0.313,1.094c-0.269,0.935-0.055,2.221-1.002,2.71c-1.184,0.61-1.784-0.521-2.443-1.307
				c-1.016-1.21-2.018-2.364-3.936-1.93c-0.935,0.212-1.099,0.826-1.278,1.475c-0.173,0.619,0.236,1.015,0.776,1.322
				c0.572,0.326,1.726-1.188,1.762,0.453c0.028,1.298-0.027,2.768-1.563,3.414c-0.837,0.353-1.508,0.895-2.093,1.561
				c-0.351,0.398-0.952,0.719-0.618,1.399c0.302,0.617,0.914,0.555,1.469,0.564c1.489,0.026,3.012-0.162,4.637,0.844
				c-1.208,0.751-2.284,1.487-3.424,2.107c-0.864,0.471-1.633,1.056-2.182,1.83c-0.493,0.696-0.981,1.554-0.578,2.44
				c0.487,1.071,1.615,0.463,2.091,0.248c1.628-0.736,4.491,0.867,4.83-2.441c0.043-0.421,0.661-0.334,1.052-0.31
				c0.536,0.033,1.14,0.52,1.019,0.813c-0.763,1.859,0.789,3.197,1.075,4.902c-2.042,0.151-3.835-0.566-5.573-1.105
				c-2.138-0.662-3.03-0.325-2.807,2.007c0.041,0.44-0.086,0.896-0.136,1.346c-0.27,0.2-0.539,0.4-0.809,0.602
				c-0.971-1.237-1.919-2.493-2.92-3.706c-0.599-0.725-1.442-0.745-1.593,0.203c-0.207,1.313-0.4,2.895,0.153,3.977
				c0.412,0.807,0.687,1.498,0.671,2.335c-0.025,1.396-0.121,2.705,1.999,1.817c0.898-0.376,0.768,0.536,0.793,1.01
				c0.039,0.752,0.283,1.607-1.027,1.685c-0.973,0.058-2.513-0.618-2.645,1.153c-0.125,1.677-0.336,3.284,1.537,4.601
				c2.258,1.587,3.148,4.321,4.101,6.862c0.26,0.694,0.365,1.192-0.809,1.005c-1.542-0.247-2.817-1.011-4.159-1.664
				c-0.453-0.221-0.442-0.419-0.162-1.021c0.78-1.681-0.291-3.049-2.125-2.546c-5.296,1.456-10.672,1.068-16.006,0.833
				c-3.994-0.176-8.146-0.048-11.888-1.792c-2.241-1.045-4.412-2.336-6.688-4.354c2.888-0.479,5.485-0.407,7.921-1.229
				c2.56-0.864,5.145-0.271,7.757-0.094c5.894,0.401,11.699-0.499,17.288-2.373c1.012-0.339,2.767,0.433,3.094-1.347
				c0.386-2.099-2.095-1.049-2.71-2.33c-0.464-0.967-1.697-0.043-2.598-0.14c-2.775-0.299-4.775-2.472-7.512-2.726
				c-0.519-0.047-1.056-0.291-1.617-0.251c-1.154,0.082-1.842-0.061-1.924-1.599c-0.122-2.298-2.228-2.916-3.95-2.962
				c-1.676-0.046-2.626-0.703-3.101-1.995c-0.759-2.066-2.507-2.907-4.923-3.978c4.63-1.031,8.499-2.228,12.586-2.198
				c4.463,0.032,8.889-0.38,13.308-0.937c0.854-0.108,1.923-0.157,1.931-1.28c0.01-1.471-1.259-0.773-2.004-0.918
				c-0.387-0.075-0.81,0.036-1.195-0.04c-0.938-0.184-2.205-0.386-2.139-1.488c0.07-1.176,1.372-0.799,2.176-0.799
				c2.699-0.001,5.19-1.048,7.799-1.507c1.624-0.285,1.308-1.869,1.028-2.977c-0.377-1.496-1.578-0.207-1.84-0.058
				c-1.164,0.665-2.2,1.221-3.515,0.454c-0.185-0.107-0.566-0.163-0.688-0.054c-2.554,2.312-5.259,1.365-8.013,0.493
				c-0.42-0.134-1.023,0.319-1.545,0.495c-0.333,0.112-0.933,0.553-0.965,0.019c-0.141-2.324-1.854-1.469-2.993-1.499
				c-1.653-0.046-2.813-1.027-3.914-1.945c-1.851-1.546-3.499-3.312-5.925-4.021c-0.754-0.221-1.704-0.757-1.527-1.602
				c0.179-0.852,1.227-0.407,1.892-0.469c1.262-0.115,2.523,0.071,3.796-0.316c1.103-0.335,2.71,0.245,2.441-1.883
				c-0.061-0.483,0.534-0.493,0.925-0.507c3.723-0.134,7.355-1.03,11.018-1.557c3.41-0.49,6.732-1.93,10.091-3.544
				c-1.411-1.348-1.98-3.186-3.075-4.756c-1.572,1.346-3,2.62-5.275,2.372c-1.398-0.153-1.866-1.721-2.72-1.788
				c-1.944-0.154-1.646-1.297-1.639-2.46c0.008-1.087-0.451-1.206-1.347-0.76c-2.022,1.006-3.832,1.632-4.628-1.543
				c-0.361-1.438-2.739-0.711-3.407-2.464c1.471-0.504,2.949-0.199,4.388-0.279c0.629-0.036,1.74,0.022,1.809-0.207
				c0.958-3.232,2.942-0.934,3.958-0.465c2.675,1.235,5.118,0.455,7.634-0.133c0.637-0.148,1.445-0.41,1.162-1.429
				c-0.226-0.812-0.779-0.946-1.577-1.056c-1.945-0.266-3.76-1.311-5.824-0.947c-0.397,0.069-1.036-0.219-0.937-0.531
				c0.812-2.526-1.1-2.326-2.516-3.206c-1.17-0.729-1.831-2.427-3.383-3.294c-1.091-0.608,0.124-1.271,0.836-1.554
				c1.536-0.61,3.154-0.123,4.727-0.276c0.724-0.071,1.723,0.324,1.79-0.968c0.109-2.071,1.354-1.34,2.333-0.871
				c5.259,2.519,10.389,1.822,15.518-0.431c1.039-0.457,0.958-0.701,0.3-1.489c-1.389-1.661-3.58-2.361-4.815-4.181
				c-0.308-0.455-1.07-0.892-1.022-1.256c0.504-3.796-2.052-1.58-3.349-1.723c-0.758-0.082-2.062,0.923-1.636-1.051
				c0.076-0.357-0.422-0.715-0.866-0.591c-1.425,0.396-2.51-0.973-3.33-1.031c-3.231-0.233-4.54-3.431-7.326-4.065
				c-0.76-0.174-1.574-0.604-1.514-1.297c0.094-1.063,1.144-0.509,1.761-0.546c1.355-0.083,2.718-0.041,4.078-0.022
				c0.718,0.009,1.103-0.159,1.31-0.996c0.387-1.565,1.777-1.96,3.09-0.802c1.162,1.026,2.626,1.41,3.883,2.211
				c0.286,0.183,0.789,0.708,1.251,0.198c0.352-0.388,0.323-0.883,0.106-1.339c-0.501-1.053-0.566-2.319-1.586-3.124
				c-0.451-0.355-0.785-0.83-0.516-1.456c0.256-0.593,0.829-0.401,1.251-0.372c2.133,0.149,4.295-0.394,6.408,0.352
				c0.8,0.281,1.757,0.2,1.818-0.888c0.055-0.973-0.311-1.874-1.641-1.794c-0.793,0.047-2.265,0.078-2.292-0.082
				c-0.376-2.166-2.471-1.962-3.622-2.951c-0.539-0.465-0.786-1.209-1.685-1.141c-2.156,0.165-2.159-1.391-2.23-2.831
				c-0.088-1.797,1.302-1.71,2.466-1.776c1.115-0.063,2.269-0.156,3.349,0.054c0.957,0.186,1.341,0.257,1.905-0.824
				c0.641-1.229,2.032-0.607,3.215-0.309c1.255,0.317,2.296,1.054,3.473,1.459c0.137-0.107,0.326-0.178,0.35-0.286
				c0.691-3.228,0.926-3.625,3.75-1.939c4.263,2.545,7.892,1.864,11.697-0.719c1.604-1.088,3.461-1.806,5.21-2.678
				c0.53-0.264,0.98-0.553,0.889-1.246c-0.104-0.779-0.778-0.924-1.331-1.222c-1.012-0.544-0.692,0.411-0.936,0.777
				c-1.012,1.531-2.773,0.864-4.131,1.166c-1.95,0.435-3.848,0.732-5.446,2.02c-0.582,0.468-1.439,1.037-1.55-0.171
				c-0.145-1.585-0.739-1.035-1.632-0.74c-1.301,0.43-2.932,1.564-3.175-1.238c-0.1-1.142-1.094-0.585-1.875-0.429
				c-0.988,0.197-2.954,1.856-2.388-1.214c0.078-0.424-0.453-0.907-0.708-0.824c-1.35,0.439-2.353-0.756-3.67-0.641
				c-0.909,0.079-2.28,0.704-2.783-0.53c-0.46-1.131,1.413-1.12,1.568-2.385c-2.418-0.439-4.857-0.816-7.258-1.363
				c-0.66-0.15-1.845-0.058-1.801-1.172c0.044-1.138,1.128-0.796,1.852-0.985c1.081-0.282,2.191-0.308,3.265-0.497
				c0.973-0.171,2.366-0.052,2.693-1.532c0.568-2.576,2.365-1.826,3.902-1.327c1.598,0.518,2.638,2.079,4.128,2.695
				c1.106,0.457,2.726,2.837,3.804-0.404c0.446-1.34,2.22-1.065,3.171-0.432c5.143,3.429,10.905,2.279,16.495,2.46
				c1.202,0.039,1.784-0.583,1.919-1.67c0.086-0.697,0.224-1.579-0.665-1.842c-0.908-0.269-1.748-0.308-2.477,0.751
				c-0.98,1.425-3.029,1.366-4.374,0.117c-0.59-0.549-1.267-1.016-2.08-0.991c-1.069,0.032-1.724-0.229-1.549-1.451
				c0.173-1.206-0.444-1.505-1.527-1.456c-2.629,0.119-5.198-0.782-7.847-0.486c-0.894,0.1-1.573-0.192-1.698-1.225
				c-0.124-1.025,0.246-1.533,1.311-1.835c1.193-0.34,2.575-0.077,3.652-1.105c-0.525-0.624-1.21-1.125-1.404-2.05
				c-0.207-0.98-0.845-1.604-2.021-1.703c-0.718-0.061-1.785-0.318-1.863-1.209c-0.097-1.1,1.043-1.21,1.825-1.518
				c0.277-0.108,0.655-0.014,0.802-0.622c-0.509-0.542-1.067-1.191-1.684-1.781c-0.66-0.634-0.844-1.389-0.458-2.163
				c0.448-0.9,1.376-0.78,2.129-0.588c2.107,0.536,3.873,1.742,5.655,2.956c1.505,1.024,3.102,2.065,4.769-0.046
				c0.865-1.096,1.933-1.788-0.11-2.728c-0.973-0.447-0.856-1.798-0.477-2.358c0.997-1.466,0.655-3.256,1.581-4.802
				c0.66-1.103,0.817-3.095-0.865-4.234c-0.312-0.211-0.648-0.527-0.762-0.865c-0.324-0.965-1.471-1.947-0.57-2.952
				c0.871-0.973,2.113-0.488,3.041,0.117c1.465,0.955,2.609,1.116,3.853-0.431c0.982-1.221,1.993-0.74,2.434,0.62
				c0.335,1.039,0.807,2.069,0.604,3.25c-0.128,0.744,0.093,1.561,1.008,1.613c1.104,0.062,1.226-0.815,1.195-1.666
				c-0.03-0.887,0.212-1.705,0.417-2.555c0.183-0.761,0.352-1.651-1.072-1.869c-1.21-0.184-3.457-4.349-3.383-5.686
				c0.021-0.387-0.057-0.874,0.4-1.026c0.686-0.229,1.4,0.065,1.68,0.614c0.827,1.626,2.362,3.173,3.771,2.547
				c1.07-0.475,0.958-2.891,1.033-4.537c0.086-1.881,0.388-3.752,0.609-5.759c2.389,0.588,2.317,2.509,2.41,4.266
				c0.054,1.022-0.133,2.056-0.14,3.084c-0.003,0.501-0.24,1.19,0.581,1.353c0.688,0.137,1.271-0.05,1.646-0.681
				c0.283-0.478,0.427-1.146,0.838-1.42c1.026-0.684,2.496-1.141,3.253-0.136c0.651,0.865-0.62,1.769-1.424,2.106
				c-0.84,0.352-1.221,0.876-1.593,1.597c-1.315,2.547-3.887,4.217-4.803,7.104c-0.35,1.101-0.498,2.032,0.518,2.375
				c0.841,0.284,2.044,0.099,2.32-1.245c0.248-1.207,1.025-2.164,1.743-3.066c0.537-0.676,0.63-2.086,1.895-1.86
				c1.187,0.212,0.235,1.615,0.985,2.23c1.921-0.87,3.852-1.78,5.816-2.612c0.642-0.271,1.68-0.5,1.946,0.145
				c0.362,0.874-0.65,0.92-1.283,1.243c-0.892,0.455-1.62,1.241-2.397,1.908c-0.605,0.519-0.081,0.858,0.283,1.088
				c0.39,0.247,0.996,0.231,1.284,0.539c0.53,0.569,1.836,0.597,1.652,1.511c-0.213,1.053-1.345,0.396-2.057,0.527
				c-1.747,0.32-3.559,1.04-5.018-0.804c-0.263-0.332-0.934-0.112-1.245,0.343c-0.358,0.524-0.045,0.937,0.358,1.212
				c0.98,0.668,1.981,1.311,3.007,1.908c0.809,0.471,1.529,1.029,1.363,2.041c-0.182,1.113-1.301,1.085-2.067,1.189
				c-1.046,0.143-2.059,0.356-2.952,0.855c-1.879,1.048-2.608,0.558-2.354-1.552c0.047-0.389,0.007-0.788,0.007-1.212
				c-3.24,0.923-4.182,2.127-4.213,5.167c-0.028,2.727,0.43,5.479-0.435,8.17c-0.141,0.436-0.15,1.085,0.518,1.108
				c0.889,0.031,1.933,0.616,2.625-0.333c0.26-0.356,0.268-0.857,0.807-1.057c4.521-1.672,6.236-5.792,8.288-9.597
				c0.764-1.417,2.219-1.58,2.89-0.685c1.345,1.798,2.46,0.839,3.299-0.048c0.882-0.933,2.071-1.115,2.997-1.835
				c0.652-0.506,1.285-0.362,1.417,0.488c0.21,1.358-1.281,1.718-1.744,2.682c-0.534,1.114-0.787,0.042-1.169-0.075
				c-1.062-0.325-1.486-0.242-1.855,1.153c-0.893,3.368,1.735,6.198,1.071,9.417c-0.196,0.95,1.987,0.611,0.896,1.797
				c-1.261,1.371-2.733,2.06-4.548,0.901c-0.441-0.281-1.063-0.837-1.436-0.196c-0.372,0.643-0.524,1.509-0.486,2.264
				c0.028,0.575,0.687,0.803,1.305,0.783c0.791-0.025,1.642-0.151,2.261,0.464c0.714,0.707,1.499,2.076,0.133,2.241
				c-2.336,0.284-1.641,2.167-1.668,3.003c-0.045,1.405,1.57,0.847,2.513,0.902c1.718,0.101,3.908-0.59,3.519,2.471
				c2.255,0.564,2.697,2.595,3.484,4.334c0.316,0.701,0.945,1.464,0.299,2.21c-0.643,0.741-1.326-0.085-1.97-0.225
				c-2.117-0.458-3.905-1.707-5.857-2.564c-0.744-0.327-1.522-1.121-2.356-0.223c-0.763,0.821-1.012,1.771-0.401,2.828
				C577.395,377.875,577.865,378.31,577.393,379.019z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(90)} fill={props.fillColors[90]} d="M502.266,424.389c2.785,1.326,2.785,1.326,1.351,4.101
				c-0.342,0.662-0.342,0.662,1.456,2.229c-1.704,1.732-1.704,1.771-4.979,1.752c-1.36-0.009-2.547,0.039-2.984,1.658
				c-0.455,1.68,0.78,2.325,1.852,2.932c1.16,0.659,2.331,1.12,2.764,2.617c0.161,0.56,0.958,1.251,1.535,1.322
				c2.571,0.314,3.973,2.571,6.07,3.477c2.387,1.03,5.081,1.825,7.706,0.337c0.289-0.164,0.515-0.363,0.953,0.023
				c-0.233,0.809-0.992,0.877-1.66,1.162c-4.248,1.812-8.842,1.952-13.29,2.773c-1.229,0.227-2.437,0.736-3.741,0.521
				c-0.396-0.064-0.842,0.034-1.037,0.439c-1.327,2.749-3.909,2.054-6.169,2.312c-0.997,0.113-2.445-0.679-2.832,0.944
				c-0.353,1.478-0.109,3.231,1.333,3.795c3.722,1.456,6.272,4.567,9.698,6.378c0.753,0.397,2.347,0.638,2.432,0.743
				c1.594,1.994,3.553,1.706,5.657,1.34c0.52-0.09,0.74,0.725,0.73,1.326c-0.015,0.996-0.181,2.018,0.938,2.927
				c-7.03,1.01-13.8,0.312-20.225,2.45c-1.045,0.348-2.247,0.349-2.491,1.758c-0.254,1.464,0.62,2.19,1.804,2.718
				c0.435,0.194,0.998,0.335,1.243,0.683c1.957,2.781,4.004,5.372,7.864,5.382c0.197,0,0.38,0.387,0.377,0.776
				c-0.026,2.576,1.361,3.503,3.8,3.156c0.732-0.104,1.508-0.02,2.069,0.424c2.082,1.65,4.619,2.154,7.797,2.993
				c-2.88,0.988-5.146,1.449-7.487,1.809c-4.126,0.634-8.214,0.042-12.319-0.018c-3.964-0.058-7.685,1.739-11.691,1.645
				c-0.883-0.021-2.146,0.428-2.331,1.684c-0.168,1.141,0.882,1.749,1.597,2.214c4.377,2.85,8.754,5.598,14.34,5.6
				c3.307,0.001,6.592,0.964,9.942,0.856c2.222-0.071,4.404-0.748,6.879-0.366c-1.536,1.164-3.298,1.319-4.898,1.771
				c-5.412,1.523-10.876,2.09-16.51,1.106c-2.365-0.411-4.88-0.422-7.209,0.808c-1.563,0.825-1.671,1.755-0.589,2.948
				c1.251,1.379,2.39,2.873,4.479,3.185c2.217,0.33,3.616,1.593,4.228,3.899c0.71,2.676,3.124,1.897,5.064,2.234
				c-5.709,1.104-10.923,4.046-17.011,4.487c0.517-1.882,1.693-3.103,2.577-4.463c0.745-1.148,1.36-2.313,1.783-3.606
				c0.222-0.678,0.735-1.493-0.063-1.896c-0.641-0.324-1.5-0.244-2.22,0.383c-1.778,1.549-3.636,3.007-5.333,4.669
				c0.202-1.104,1.111-2.177,1.674-3.35c1.414-2.948,1.559-5.962,0.6-9.045c-0.359-1.158-1.059-1.948-2.451-1.758
				c-1.409,0.192-1.304,1.29-1.542,2.293c-0.476,2.007-0.305,4.07-0.624,6.723c-0.582-2.549,0.05-4.598-0.813-6.479
				c-0.37-0.807-0.531-1.795-1.709-1.529c-1.032,0.234-1.491,0.957-1.552,2.086c-0.165,3.061-0.451,6.114-1.252,9.263
				c-0.474-0.841-0.976-1.666-1.412-2.526c-0.368-0.728-0.659-1.587-1.712-1.199c-0.973,0.357-0.895,1.329-0.752,2.063
				c0.509,2.645,1.378,5.195,2.395,7.684c0.576,1.412,1.214,2.815,0.701,4.331c-0.333,0.981-1.03,1.647-1.893,2.42
				c-2.648,2.369-6.184,3.148-8.712,5.529c-0.758,0.714-1.54,1.352-2.436,1.899c-1.717,1.05-2.683,0.79-3.424-1.22
				c-0.668-1.808,0.233-3.799-0.967-5.549c-0.292-0.425,0.863-0.848,1.527-1.063c0.808-0.262,1.766-0.453,1.551-1.681
				c-0.222-1.262-1.221-0.973-2.062-1.057c-1.566-0.156-3.096,0.246-4.959,0.587c1.229-2.273,1.288-4.112-0.202-5.852
				c-0.732-0.854-0.407-1.349,0.662-1.589c1.539-0.345,3.049-0.817,4.582-1.188c0.469-0.112,0.966-0.065,1.025-0.73
				c0.048-0.538-0.089-1.033-0.653-1.094c-1.203-0.131-1.909-1.369-3.471-1.014c-1.96,0.447-3.283-1.048-3.542-2.897
				c-0.321-2.299-0.895-3.848-3.69-3.67c-1.104,0.07-2.265-0.571-3.383-0.938c-0.786-0.258-1.296-0.437-1.164-1.698
				c0.396-3.809,0.252-3.83,4.076-3.821c3.421,0.008,6.807-0.328,10.186-0.863c0.912-0.145,1.318-0.405,1.134-1.332
				c-0.204-1.021-0.885-0.557-1.478-0.505c-1.36,0.117-3.515-0.007-3.473-1.096c0.059-1.505,2.125-0.805,3.195-0.686
				c2.45,0.271,4.22-1.691,6.585-1.655c0.901,0.014-0.021-1.526,0.862-1.938c0.31,0.083-0.075-0.042,0.23,0.051
				c-0.09,0.07-0.186-0.001-0.277,0.01c-0.084-1.107-0.756-0.926-1.499-0.721c-3.999,1.104-7.986,1.874-12.2,1.027
				c-3.061-0.615-6.116-1.055-8.994-2.402c-1.791-0.838-2.762-2.78-4.784-3.264c-0.281-0.067-0.562-0.792-0.565-1.215
				c-0.004-0.672,0.688-0.506,1.1-0.568c0.861-0.13,1.734-0.212,2.604-0.236c0.898-0.025,1.526-0.067,2.06-1.124
				c0.581-1.151,2.096-0.975,3.302-1.118c6.131-0.729,12.263-1.465,18.246-4.105c-1.428-0.857-2.369-1.946-2.593-3.501
				c0.461-0.107,0.869-0.397,1.363-0.392c-0.478,0.022-0.879,0.256-1.29,0.464c-0.801-0.667-1.519-0.103-2.155,0.249
				c-2.476,1.364-6.16,1.096-7.763-0.861c-0.444-0.543-0.193-1.655-0.263-2.527c-0.778-0.14-1.284,0.553-1.938,0.694
				c-1.303,0.28-2.714,0.66-3.399-1.145c-0.593-1.562-2.322-1.599-3.646-2.408c1.097-1.075,2.233,0.062,3.389-0.312
				c1.195-0.389,2.936,0.938,3.633-1.294c0.014-0.043,1.507,0.173,2.129,0.56c2.504,1.558,5.074,0.814,7.619,0.321
				c0.547-0.105,1.566-0.019,1.49-1.159c-0.066-0.995-0.604-1.187-1.527-1.399c-1.753-0.403-3.433-1.114-5.291-0.852
				c-0.153,0.021-0.456-0.026-0.456-0.039c0.024-2.58-3.189-1.76-3.836-3.672c-0.434-1.28-1.919-1.485-3.098-2.444
				c2.166-0.983,4.144-1.062,6.081-0.712c1.035,0.187,1.551,0.129,1.601-0.965c0.073-1.629,1.359-1.399,2.035-0.97
				c4.56,2.899,9.153,1.828,13.784,0.354c0.814-0.259,1.624-0.53,2.729-0.892c-1.312-1.099-2.283-2.12-3.581-2.897
				c-1.265-0.756-2.991-1.359-2.873-3.411c0.011-0.188-0.457-0.61-0.612-0.571c-2.55,0.647-4.799-0.656-6.811-1.624
				c-1.959-0.943-4.656-0.479-5.936-2.827c-0.383-0.702-1.224-0.66-1.847-0.977c-0.774-0.394-1.876-0.289-2.55-1.739
				c2.001,0,3.707,0.032,5.41-0.02c0.561-0.017,1.37,0.356,1.588-0.587c0.514-2.231,1.945-1.466,3.046-0.78
				c1.355,0.843,2.853,1.286,4.27,1.941c0.326,0.151,0.653,0.599,1.033,0.181c0.296-0.325,0.235-0.772-0.029-1.097
				c-0.704-0.859-0.45-2.241-1.525-2.858c-0.418-0.239-1.037-0.42-0.817-0.925c0.297-0.684,1.13-0.675,1.704-0.479
				c1.956,0.665,3.955,0.274,5.93,0.407c0.982,0.066,1.64-0.378,1.665-1.276c0.029-1.021-0.631-1.53-1.731-1.487
				c-1.203,0.048-2.639,0.431-2.906-1.47c-0.017-0.123-0.434-0.321-0.6-0.271c-1.931,0.582-2.757-2.015-4.804-1.584
				c-0.748,0.157-1.048-1.374-0.981-2.362c0.06-0.884,0.591-1.258,1.428-1.256c0.96,0.002,1.964-0.156,2.868,0.073
				c1.439,0.366,2.725,1.024,3.395-1.122c0.236-0.759,1.28-0.445,1.928-0.218c1.481,0.519,2.928,1.134,4.396,1.713
				c0.906-3.282,1.129-3.376,4.444-1.954c0.144,0.062,0.327,0.048,0.452,0.13c2.481,1.632,4.944,1.945,7.689,0.431
				c2.258-1.247,4.72-2.122,7.087-3.173c0.566-0.251,1.25-0.423,1.275-1.227c0.021-0.638-0.362-0.964-0.914-1.25
				c-0.738-0.384-0.938,0.05-1.468,0.478c-1.672,1.347-3.912,0.764-5.858,1.339c-0.913,0.27-1.824,0.409-2.626,0.975
				c-0.728,0.513-1.507,1.018-2.116-0.18c-0.389-0.761-1.027-0.296-1.464-0.177c-1.273,0.347-2.574,1.252-2.937-1.086
				c-0.113-0.728-1.111-0.306-1.707-0.406c-0.959-0.162-2.892,1.966-2.723-1.162c0.009-0.179-1.981-0.521-3.057-0.683
				c-0.705-0.106-1.438-0.049-2.156-0.024c-0.845,0.028-1.455-0.207-1.319-1.191c0.256-1.858-0.785-2.029-2.281-2.025
				c-1.788,0.005-3.504-0.547-5.541-1.204c1.988-1.716,4.24-0.735,6.069-1.5c0.563-0.234,1.181-0.188,1.635-0.779
				c1.507-1.957,2.977-2.163,5.148-0.861c1.408,0.845,2.701,1.938,4.334,2.327c0.913,0.219,1.948,0.837,2.316-0.961
				c0.296-1.446,1.899-0.893,2.571-0.512c5.026,2.857,10.497,2.062,15.861,2.142c0.678,0.011,1.461,0.252,1.971-0.509
				c0.521-0.776,0.908-1.825,0.253-2.406c-0.749-0.663-2.391-0.76-2.801,0.041c-1.001,1.953-2.588,1.024-3.75,0.914
				c-1.294-0.124-2.269-1.701-3.886-1.357c-0.152,0.032-0.389-0.137-0.277-0.487c0.701-2.229-0.908-1.799-2.13-1.835
				c-2.388-0.07-4.717-0.88-7.136-0.564c-0.824,0.107-1.154-0.384-1.21-1.103c-0.054-0.678,0.39-1.047,0.979-1.16
				c1.228-0.236,2.468-0.405,3.814-0.619c-1.243-2.138-2.505-4.092-5.292-4.147c0.415-1.446,1.753-1.295,2.488-2.087
				c-0.2-0.253-0.367-0.511-0.581-0.725c-0.778-0.777-2.071-1.629-1.269-2.737c0.711-0.983,2.082-0.242,3.066,0.17
				c1.521,0.638,2.99,1.405,4.449,2.18c1.584,0.841,3.118,1.854,4.35-0.506c0.233-0.447,1.049-0.385,1.258-1
				c-2.761-2.26-2.833-2.316-0.729-6.109c1.039-1.872,1.033-3.373-0.623-4.823c-0.719-0.628-1.646-1.619-1.089-2.41
				c0.572-0.812,1.859-0.604,2.827-0.052c1.219,0.698,2.315,0.656,3.5-0.225c1.602-1.192,2.447-0.822,2.954,1.083
				c0.123,0.459,0.383,0.901,0.413,1.362c0.057,0.856,0.064,1.572,1.274,1.507c1.335-0.072,0.913-1.051,1.032-1.75
				c0.12-0.701,0.109-1.431,0.289-2.113c0.188-0.715,0.008-0.924-0.711-1.135c-2.009-0.591-3.701-3.333-3.543-5.576
				c1.621,0.094,2.342,1.457,3.402,2.346c0.592,0.496,1.335,1.162,2.102,0.866c0.844-0.326,0.679-1.313,0.732-2.112
				c0.117-1.741,0.316-3.477,0.46-5.217c0.056-0.671,0.148-1.437,0.944-1.412c0.729,0.023,1.327,0.583,1.36,1.403
				c0.054,1.357,0.069,2.72,0.016,4.077c-0.031,0.813,0.205,1.321,0.993,1.628c0.888,0.347,1.092-0.236,1.372-0.836
				c0.878-1.874,2.609-1.152,4.137-1.339c-1.9,1.806-3.573,3.721-5.31,5.55c-1.074,1.13-1.733,2.33-2.434,3.6
				c-0.327,0.594-0.979,1.322,0.004,1.802c0.859,0.419,1.848,0.583,2.535-0.455c0.701-1.057,1.386-2.125,2.128-3.152
				c0.408-0.567,0.8-1.262,1.68-1.144c1.098,0.147,0.294,1.287,0.887,1.716c2.446-0.441,4.51-2.277,7.369-2.167
				c-0.698,1.285-2.003,1.416-2.891,2.088c-1.055,0.798-1.577,1.211,0.146,1.713c0.728,0.212,1.37,0.717,2.351,1.253
				c-1.533,0.691-2.938,0.118-4.173,0.669c-0.599,0.268-1.2,0.421-1.411,1.079c-0.265,0.827,0.493,1.061,0.971,1.379
				c0.667,0.444,1.389,0.798,1.471,1.869c-1.659,0.241-3.348,0.322-4.911,0.983c-0.711,0.301-1.521,0.919-2.229,0.379
				c-0.88-0.671-0.055-1.572,0.053-2.375c-3.528,0.272-4.986,1.695-4.517,4.992c0.263,1.845-0.284,3.587-0.062,5.361
				c0.078,0.618-0.929,1.549,0.227,1.807c0.859,0.19,2.092,0.562,2.704-0.324c1.081-1.564,3.008-1.604,4.301-2.719
				c0.161-0.139,0.562-0.29,0.578-0.264c0.875,1.388,1.982,2.417,3.633,2.867c1.993,0.545,1.614,2.287,1.597,3.713
				c-0.015,1.174,0.409,1.444,1.522,1.509c1.448,0.085,2.879,0.479,4.532,0.778c-1.402,1.036-1.405,1.117-2.359,1.084
				c-1.206-0.042-1.911,0.114-1.895,1.646c0.017,1.462,0.601,1.793,1.865,1.679c0.713-0.064,1.447,0.071,2.156-0.017
				c1.666-0.206,1.936,0.6,1.542,1.991c-0.87,0.182-1.445-0.491-2.143-0.767c-1.392-0.552-3.182-0.564-3.733,0.518
				c-1.012,1.981-2.498,1.485-3.972,1.529c-3.305,0.101-4.361,1.158-4.426,4.49C501.566,424.439,501.727,424.622,502.266,424.389z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(91)} fill={props.fillColors[91]} d="M62.53,522.961c1.986-2.459,3.973-4.918,5.96-7.378
				c1.341-1.383,2.186-3.098,3.149-4.73c0.736-1.247,1.628-1.768,3.162-1.328c1.596,0.458,3.255,0.454,4.926,0.021
				c2.246-0.582,4.073,1.182,6.21,1.306c4.613,0.269,9.192,0.616,12.405-3.872c1.716-2.398,3.766-4.582,7.12-4.57
				c1.644,1.096,2.544,0.008,3.215-1.232c0.653-1.209,1.309-2.376,2.459-3.164c1.528-1.047,3.617-0.707,4.653,0.722
				c-0.782-1.346-2.987-1.778-4.483-0.83c-1.176,0.744-1.889,1.862-2.53,3.091c-0.626,1.199-1.411,2.451-3.154,1.545
				c-1.178-0.205-1.569-0.861-1.299-2.033c1.137-4.925,2.112-9.866,1.402-14.97c-0.075-0.538,0.126-1.098,0.396-1.599
				c0.2-0.339,0.498-0.595,0.75-0.889c4.563-1.142,4.823-4.534,4.385-8.327c-0.091-0.788-0.048-1.595-0.174-2.388
				c0.046-1.061,0.85-1.476,1.648-1.839c1.315-0.599,2.531-1.401,3.6-2.313c1.238-1.057,2.356-1.144,3.673-0.376
				c1.85,3.25,2.229,3.441,4.703,2.359c0.604-0.189,1.184-0.231,1.63,0.339c1.556-0.84,2.868-1.979,4.157-3.182
				c1.315-1.229,3.101-0.666,3.004,1.016c-0.269,4.649,2.437,7.372,5.894,9.565c1.716,1.089,1.803,2.001,0.788,3.644
				c-0.995,1.608-1.918,3.422,0.375,4.987c0.891,0.607,0.146,2.035,0.927,2.845c1.82,0.831,2.164,3.087,3.893,4.01
				c0.934,2.473,3.198,2.928,5.364,3.361c1.172,0.235,2.286-0.434,2.644-1.589c0.501-1.622,1.614-1.495,2.837-1.345
				c0.157,0.02,0.314,0.061,0.462,0.116c3.419,1.284,6.526,0.519,9.468-1.415c0.463-0.305,0.95-0.581,1.433-0.854
				c2.825-1.594,2.838-1.595,4.737,0.969c0.392,0.528,0.989,0.79,1.427,1.254c0.316,0.442,0.397,0.92,0.218,1.434
				c-1.645,2.192-3.619,4.078-5.653,5.896c-0.693,0.619-1.505,1.156-2.524,1.13c-0.8-0.165-1.165-0.691-1.24-1.479
				c-0.114-1.18-0.881-0.791-1.55-0.622c-2.069,0.592-4.023,1.496-6.065,2.148c-0.776,0.438-2.014-0.998-2.437,0.435
				c-0.377,1.275,0.564,2.227,1.501,2.954c0.724,0.562,1.811,0.559,2.386,1.413c0.5,1.523-0.203,2.67-1.124,3.793
				c-0.527,0.645-1.467,0.903-1.649,1.825c0.805,0.868,1.298,1.919,1.891,2.896c0.165,0.082,0.31,0.183,0.475,0.257
				c1.247,0.492,3.395,0.036,2.746,2.554c-0.109,0.352-0.337,0.621-0.58,0.881c-2.366,1.642-5.297,1.578-7.878,2.588
				c-0.487,0.19-1.286,0.16-1.553-0.347c-1.261-2.387-3.716-1.932-5.689-2.297c-3.385-0.626-6.379-1.868-8.959-4.124
				c-0.873-0.764-1.502-1.768-1.302-2.888c0.159-0.894,1.185-0.525,1.744-0.604c-0.203,0.013,0.292-0.09,0.138-0.045
				c-0.751,0.216-2.455-0.024-1.874,0.964c0.867,1.474-0.912,3.225,0.835,4.318c0.193,0.121,0.285,0.379,0.398,0.592
				c0.527,1.1,1.485,1.881,2.127,2.894c0.178,3.953-0.705,3.769-3.315,3.104c-0.919-0.234-1.868-0.469-2.827-0.494
				c-1.451-0.039-2.236-0.719-2.261-2.185c-0.029-1.757-1.69-2.414-2.274-3.495c2.425,1.855,2.569,5.164,3.962,7.827
				c0.24,0.457-0.032,0.899-0.159,1.364c-0.518,1.898-0.102,3.598,1.111,4.978c1.378,1.567,1.198,3.348-0.464,4.609
				c-1.867,1.417-1.951,2.082-0.486,3.858c1.518,1.84,1.206,2.7-1.028,3.23c-5.461,1.298-10.188,0.092-14.12-3.978
				c-0.412-0.427-0.885-0.66-1.479-0.664c-1.878-0.014-3.565-0.73-5.258-1.424c1.69,0.686,3.371,1.403,5.245,1.429
				c0.493,0.007,0.992,0.104,1.195,0.615c0.25,0.632-0.14,1.066-0.593,1.43c-1.739,1.396-3.464,2.713-5.738,3.296
				c-4.274,1.098-8.772,1.261-12.859,3.146c-2.534,1.169-4.924-0.229-7.152-0.968c-2.25-0.746-4.719-0.897-6.776-2.189
				c-0.934-0.586-2.512-0.723-2.154-2.412c0.322-1.516,2.558-2.936,4.096-2.607c0.774,0.165,1.477,0.539,2.309,0.202
				c0.973-0.392,1.339-1.063,1.151-2.063c-1.77-1.299-1.866-3.942-4.02-4.92c-1.216-1.15-2.857-1.711-4.043-2.885
				c-1.307-1.294-1.228-1.999,0.322-2.983c2.599-1.651,5.503-2.342,8.534-2.538c1.081-0.07,2.044-0.255,2.626-1.281
				c0.271-0.393,0.629-0.769,0.733-1.226c0.368-1.623,1.09-2.026,2.482-0.917c0.901,0.717,2.045,0.864,3.147,0.983
				c0.878,0.095,1.568,0.039,2.039,1.164c0.6,1.436,2.077,0.767,3.178,0.609c2.124-0.304,4.218-0.777,6.383-0.807
				c1.376-0.019,2.322,0.215,2.375,1.897c0.069,2.206,2.055,2.613,3.538,3.452c0.532,0.301,1.396,0.315,1.313,1.148
				c-0.073,0.735-0.882,0.846-1.442,1.102c-1.639,0.749-3.229,0.043-4.491-0.273c2.252,1.079,4.452,0.441,6.842-0.952
				c1.388-0.81,3.425-0.13,5.121,0.395c-2.835-0.86-5.976-0.232-8.647-2.062c-1.201-0.823-2.234-1.516-2.275-3.062
				c-0.033-1.249-0.729-1.603-1.869-1.64c-2.254-0.073-4.425,0.471-6.632,0.748c-1.396,0.175-3.051,1.047-3.713-1.213
				c-0.153-0.522-0.785-0.501-1.257-0.468c-0.91,0.062-1.712-0.403-2.576-0.48c-1.034-0.092-1.558-1.687-2.737-1.009
				c-0.939,0.54-0.722,1.911-1.526,2.629c-0.547,0.933-1.417,1.178-2.428,1.253c-4.048,0.305-7.687,1.395-10.825,4.3
				c-1.793,1.659-4.304,2.526-6.841,0.946c-0.792-0.492-1.669-0.46-2.548-0.455c-1.225,0.007-2.344,0.009-1.657-1.832
				c0.333-0.894-0.5-1.009-1.158-1.03c-0.804-0.026-1.597-0.152-2.343-0.414C62.812,524.874,61.934,524.325,62.53,522.961z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(92)} fill={props.fillColors[92]} d="M92.942,263.805c7.775,5.547,15.217,10.535,22.935,15.113
				c9.416,5.585,19.087,10.668,29.335,14.516c16.921,6.352,32.708,14.685,47.229,25.499c6.403,4.769,12.875,9.462,19.015,14.542
				c9.572,7.92,18.093,16.902,25.897,26.623c8.46,10.539,16.525,21.36,24.22,32.447c4.493,6.474,8.539,13.276,12.208,20.277
				c1.286,2.452,2.625,4.876,3.895,7.336c0.244,0.473,0.526,1.166,0.351,1.562c-0.313,0.71-0.942,0.082-1.45,0.019
				c-1.125-0.14-2.285-0.24-2.979,0.947c-0.811,1.389,0.568,1.906,1.231,2.461c1.687,1.41,3.519,2.657,5.365,3.86
				c0.542,0.353,1.054,0.699,1.458,1.185c0.722,0.867,1.479,1.806,0.834,2.989c-0.639,1.173-1.94,0.893-2.817,0.641
				c-2.715-0.778-5.354-1.819-8.031-2.736c-0.444-0.152-0.531-0.476-0.7-0.851c-2.417-5.339-4.986-10.604-7.794-15.751
				c-5.686-10.422-11.989-20.424-19.495-29.667c-4.868-5.994-9.907-11.813-15.437-17.196c-6.931-6.746-14.283-13.011-21.903-18.964
				c-10.354-8.089-21.26-15.402-32.241-22.588c-7.022-4.595-14.609-8.229-22.056-12.096c-12.924-6.711-26.056-13.042-38.592-20.475
				c-4.218-2.501-8.438-5.052-12.026-8.497c-1.999-1.918-3.33-4.078-4.052-6.793c-1.053-3.958-2.462-7.822-3.705-11.731
				C93.414,265.769,93.26,265.035,92.942,263.805z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(93)} fill={props.fillColors[93]} d="M99.93,286.736c4.168,2.687,7.796,5.323,11.622,7.666
				c10.692,6.548,21.957,11.997,33.087,17.719c14.707,7.561,29.381,15.213,43.168,24.396c8.374,5.577,16.578,11.384,24.266,17.934
				c6.056,5.159,12.02,10.402,17.448,16.196c10.262,10.952,19.514,22.7,27.264,35.6c3.587,5.971,6.87,12.092,9.885,18.362
				c0.719,1.494,1.322,3.045,2.01,4.555c0.325,0.713,0.61,1.196-0.523,1.556c-1.848,0.586-1.603,1.742-0.375,2.806
				c0.651,0.564,1.433,1.008,2.214,1.386c1.312,0.636,2.177,1.547,2.37,3.319c-4.13-1.093-8.21-1.855-11.87-3.871
				c-1.506-0.829-1.856-2.673-2.657-4.069c-6.542-11.41-13.886-22.247-22.124-32.516c-5.276-6.577-10.834-12.899-16.752-18.859
				c-11.834-11.917-25.129-21.987-39.716-30.375c-8.056-4.632-16.403-8.677-24.646-12.94c-12.933-6.688-25.706-13.676-37.604-22.113
				c-3.674-2.605-7.323-5.28-10.563-8.477c-2.255-2.224-4.063-4.514-4.422-7.807C101.642,293.821,100.143,290.625,99.93,286.736z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(94)} fill={props.fillColors[94]} d="M275.375,709.992c-2.511,2.521-5.567,0.407-8.996,0.973
				c3.585,0.719,6.513,1.3,9.438,1.894c0.685,0.139,1.424,0.196,2.026,0.508c0.595,0.308,2.135,0.107,1.283,1.544
				c-0.531,0.895-0.299,2.469-2.44,1.936c-8.909-2.217-18.029-3.29-27.151-2.352c-5.699,0.586-11.265-0.265-16.895-0.205
				c-2.582,0.028-5.158-0.748-7.767,0.009c-0.946,0.274-1.501-0.172-1.458-1.262c0.045-1.128-0.152-2.486,1.495-2.459
				c3.818,0.064,7.629-0.247,11.461,0.228c4.5,0.559,9.042,0.527,13.621-0.197c-2.652,0.086-3.199-2.933-5.376-3.48l0.233-0.001
				l-0.232,0.014c-1.067-1.367-2.256-1.185-3.513-0.287c-1.1,0.784-2.122,1.645-3.1,2.589c-1.488,1.438-3.476,0.964-4.404-0.916
				c-1.976-4-1.494-5.879,2.185-8.551c0.572-0.416,1.12-0.865,1.686-1.289c4.307-3.228,6.61-3.253,10.794,0.051
				c1.668,1.317,3.192,2.816,4.782,4.232c0.315,0.01,0.48,0.175,0.508,0.485c1.273,3.025-1.973,4.457-2.351,7.054
				c0.577-0.265,1.933,0.886,1.463-1.168c-0.127-0.557,0.401-1.389,0.835-1.928c4.946-6.148,10.055-12.182,16.774-16.48
				c6.6-4.224,13.975-6.382,21.712-7.429c4.799-0.649,9.617-1.312,14.446-0.92c12.832,1.04,25.642,2.331,37.171,8.854
				c6.164,3.487,12.139,7.238,16.055,13.425c0.466,0.735,0.846,1.523,1.285,2.275c0.85,1.455,0.658,2.565-1.028,3.2
				c-0.364,0.464-0.76,0.487-1.185,0.083c-0.391-0.06-0.873-0.299-1.157-0.152c-2.909,1.498-4.622-0.029-6.519-2.093
				c-3.544-3.859-8.48-5.736-12.9-8.33c-3.22-1.89-6.434-3.818-9.981-5.077c-0.489-0.174-0.951-0.622-1.579,0.016
				c7.021,3.213,13.601,7.068,20.228,10.842c1.978,1.125,3.411,2.86,5.028,4.394c1.03,0.977,0.583,1.396-0.524,1.726
				c-2.052,0.612-4.104,1.234-6.126,1.94c-0.849,0.297-1.23,0.033-1.768-0.626c-1.29-1.587-2.671-3.132-4.806-3.635
				c-1.131,0.902-2.019-0.118-3.005-0.372c-6.298-5.359-14.2-6.031-21.841-7.412c-8.783-1.588-17.126,0.838-25.466,3.044
				c-2.512,0.665-5.21,1.067-6.984,3.33C279.345,708.646,277.706,710.371,275.375,709.992z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(95)} fill={props.fillColors[95]} d="M235.481,399.48c-2.968,0.46-5.388-1.112-8.092-1.002
				c-1.293,0.053-2.624,0.19-3.473,1.204c-1.059,1.265-1.769,1.064-2.812-0.025c-9.863-10.3-21.783-17.849-33.868-25.161
				c-11.766-7.119-23.583-14.156-35.353-21.27c-8.999-5.438-17.867-11.087-26.496-17.094c-4.988-3.472-9.79-7.189-14.399-11.185
				c-4.758-4.124-6.294-9.419-6.785-15.302c-0.04-0.474,0.025-0.992-0.147-1.412c-0.255-0.62-0.599-1.391-0.091-1.777
				c0.647-0.492,1.353,0.192,1.849,0.618c11.081,9.491,23.905,16.249,36.475,23.427c9.274,5.295,18.686,10.341,28.208,15.147
				c12.342,6.229,23.847,13.671,34.508,22.418c7.535,6.182,14.482,12.978,20.964,20.291
				C229.221,392.028,232.186,395.911,235.481,399.48z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(96)} fill={props.fillColors[96]} d="M67.947,326.881c3.317,1.717,5.617,3.863,7.967,5.833
				c5.234,4.386,10.996,8.011,16.52,11.978c8.917,6.405,18.116,12.418,26.478,19.591c4.891,4.195,9.493,8.699,14.344,12.924
				c4.673,4.07,9.18,8.292,13.538,12.69c6.072,6.129,10.962,13.135,15.347,20.511c2.913,4.899,5.427,10.016,7.742,15.227
				c0.24,0.54,0.542,1.052,0.999,1.486c0.318-2.447-1.267-4.271-1.958-6.366c-0.469-1.421-1.206-2.753-1.781-4.143
				c-0.159-0.384-0.789-0.791-0.235-1.227c0.52-0.409,0.972-0.001,1.478,0.303c4.546,2.726,9.085,5.465,13.699,8.073
				c3.386,1.914,6.881,3.633,10.325,5.444c0.78,0.41,1.387,0.583,0.687,1.876c-0.902,1.666-0.71,3.731-0.062,5.537
				c0.408,1.135,0.078,1.311-0.901,1.484c-2.017,0.357-4.003,0.9-6.023,1.23c-2.052,0.334-3.374-1.143-4.812-2.229
				c-3.41-2.575-6.533-5.486-9.887-8.938c0.104,3.321,1.554,5.65,2.299,8.161c1.659,5.59,3.506,11.123,5.287,16.677
				c0.437,1.365,0.233,2.694-0.305,3.948c-0.436,1.017-0.676,1.997,0.032,2.91c1.731,2.234,0.033,3.496-1.446,4.506
				c-2.458,1.678-4.178,3.494-3.71,6.795c0.212,1.494-0.803,2.787-1.949,4.424c-0.6-4.173-1.177-7.869-1.655-11.58
				c-0.988-7.676-2.94-15.104-5.723-22.312c-6.381-16.536-15.756-31.369-26.273-45.504c-3.216-4.323-7.057-8.18-10.648-12.221
				c-8.287-9.326-17.936-17.044-27.85-24.477c-3.42-2.564-6.938-5.016-10.546-7.306c-3.917-2.485-6.798-5.845-9.246-9.702
				c-3.229-5.09-7.139-9.67-10.964-14.311C68.529,327.952,68.412,327.673,67.947,326.881z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(97)} fill={props.fillColors[97]} d="M321.364,593.099c-0.399-1.821,1.235-1.948,2.176-2.227
				c8.173-2.423,15.57-0.633,22.315,4.378c0.641,0.476,1.492,0.852,1.087,1.836c-0.435,1.056-1.429,0.812-2.288,0.725
				c-0.307-0.031-0.593-0.226-0.898-0.31c-0.437-0.121-0.854,0.021-0.958,0.45c-0.174,0.713,0.409,0.744,0.88,0.746
				c2.944,0.013,5.889,0.006,9.099,0.006c-0.615-0.726-1.027-1.212-1.44-1.699c2.125-0.516,3.79-1.935,5.612-2.996
				c5.309-3.094,10.944-3.961,16.931-3.028c1.801,0.28,3.646,0.249,5.112,0.692c-6.28-1.02-12.857-2.023-19.264,0.916
				c-2.02,0.927-3.903,2.097-5.818,3.214c-0.809,0.472-1.494,1.222-2.524,1.257c-1.138,0.474-2.193,1.38-3.731,0.581
				c1.6-2.651,3.961-4.815,6.468-6.046c10.63-5.219,21.465-6.145,32.15,0.104c0.457,0.267,0.793,0.477,1.277,0.209
				c0.108,0.018,0.217,0.035,0.326,0.054c1.409,0.707,2.753,1.498,3.499,2.629c-0.462-0.581-0.115-1.317,0.282-2.055
				c2.01-1.401,4.162-2.584,6.14-4.037c2.929-0.713,5.85-1.488,8.915-1.015c1.258,0.193,2.511,0.52,3.796,0.229
				c1.701,0.307,3.438,0.761,4.615-1.125c1.062-0.347,2.171-0.617,2.701-1.774c0.119-0.207,0.283-0.369,0.469-0.513
				c2.149-0.546,4.28-1.186,6.162-1.815c0.571,0,0.133-0.314,0.19,0c0.007,0.038-0.066,0.067-0.213,0.046
				c-0.38-0.056-0.77,0-1.147-0.113c-0.907-0.382-1.481-0.898-0.816-1.937c2.55,0.661,4.112-1.701,6.202-2.072
				c2.913-0.516,5.979-0.767,8.923,0.199c0.597,0.196,1.664-0.273,1.697,0.841c0.031,1.045-1.149,0.598-1.753,1.191
				c1.303,0.387,1.691,1.224,1.206,2.56c0.451-2.109,1.487-3.879,2.111-5.805l-0.083,0.086c0.772-0.676,1.411-1.428,1.463-2.521
				l-0.08,0.089c1.19-0.461,1.418-2.002,2.677-2.388c1.84,2.271,0.466,4.161-0.725,6.072c0.671,1.436-0.528,2.179-1.207,3.057
				c-0.69,0.893-0.993,1.629,0.123,2.364c0.979,0.327,1.909,0.743,2.676,1.462c0.177,0.151,0.354,0.302,0.534,0.449
				c0.759,0.528,0.857,1.475,1.379,2.164c1.869,2.471,1.356,3.566-1.874,4.122c-0.344-0.186-0.532-0.469-0.537-0.865
				c-2.456-2.859-6.063-3.313-9.354-4.133c-7.809-1.945-14.559,0.089-19.908,6.267c-0.156,0.181-0.336,0.342-0.492,0.523
				c-2.631,3.078-2.468,3.642,1.351,4.659c0.302,0.081,0.6,0.184,0.906,0.242c0.975,0.189,2.474,0.377,2.087,1.521
				c-0.363,1.076-1.594,2.075-3.204,1.678c-2.81-0.692-5.635-1.32-8.556-1.538c2.431,0.554,4.873,1.064,7.284,1.691
				c0.65,0.169,1.956,0.358,1.309,1.396c-0.472,0.756-1.109,2.283-2.495,1.567c-1.954-1.009-4.258-0.805-6.102-1.728
				c-3.05-1.527-6.184-0.768-9.27-0.975c-3.331-0.224-6.704-0.19-9.968,0.572c-2.793,0.652-5.884,0.358-8.281,2.394
				c-0.614,1.707-2.286,2.103-3.618,2.921c-2.635,0.085-4.184,1.946-5.86,3.6c-1.882,1.158-3.492,2.649-5.136,4.107
				c-0.704,0.625-1.414,1.684-2.574,0.625c-0.575,0.37-0.974,1.53-1.878,0.467c-0.693-0.815-0.228-1.478,0.388-2.061
				c8.013-7.59,17.181-13.025,28.656-15.062c-1.865-0.775-3.009,0.229-4.216,0.516c-9.005,2.146-16.657,6.771-23.489,12.9
				c-0.694,0.623-1.367,2.214-2.33,1.442c-1.285-1.03,0.567-1.818,0.883-2.771c-3.88-2.47-8.839-3.018-12.59-1.891
				c1.729,0,3.459,0.051,5.185-0.015c1.721-0.064,1.775,1.318,2.135,2.375c0.531,1.566-1.288,0.669-1.59,1.435
				c0.78,0.573,2.778-0.22,2.221,1.828c-0.198,0.728-0.706,1.149-1.775,1.089c-4.151-0.238-8.335,0.267-12.088,1.94
				c-2.294,1.022-4.051,0.832-6.051-0.109c0.699-1.637,2.632-1.124,3.645-2.214c-1.034,1.033-2.966,0.557-3.596,2.266
				c-1.619-0.203-3.239-0.405-4.858-0.611c-0.442-0.057-1.042,0.099-1.146-0.543c-0.09-0.548,0.372-0.835,0.794-1.049
				c0.825-0.419,1.651-0.826,2.4-1.798c-1.263,0.614-2.34,1.01-3.259,1.775c-2.214,1.847-3.715,1.411-4.228-1.438
				c-0.218-1.213-0.637-1.092-1.423-0.895c-2.398,0.244-4.867-2.308-4.525-4.678c-0.333-0.44-0.823-0.993-0.201-1.413
				c1.538-1.037,2.513-2.828,4.453-3.311c0.044-1.559,1.562-1.558,2.416-2.244c1.053-0.847,2.376-1.243,3.316-1.796
				c-1.4,0.699-3.035,1.496-4.547,2.517c-0.57,0.384-1.35,0.577-1.18,1.523c-1.049-1.174-1.436,0.109-2.052,0.551
				c-0.828,0.591-1.66,1.177-2.57,1.821c-0.923-0.962,0.112-1.854,0.228-2.732c0.107-0.827,0.612-1.609,0.985-2.391
				c0.272-0.57,0.352-1.076-0.245-1.464c-0.591-0.384-0.859,0.182-1.168,0.479c-0.919,0.887-2.07,1.671-2.635,2.754
				c-0.736,1.413-1.701,1.452-2.944,1.214c-0.058-0.098-0.115-0.195-0.174-0.294c-0.006-0.113-0.013-0.227-0.021-0.341
				c0.036-0.108,0.073-0.216,0.107-0.324c0.568-0.739,0.427-1.773,1.028-2.5c0.24-0.249,0.542-0.404,0.848-0.551
				c1.301-0.957,2.677-1.823,3.734-3.077c0.495-0.211,0.987-0.428,1.365-0.831c0.852-0.718,0.623-1.533,0.187-2.342
				C321.175,594.394,320.727,593.795,321.364,593.099z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(98)} fill={props.fillColors[98]} d="M442.86,429.736c-0.721,2.759-0.859,5.137-3.864,5.646
				c-1.176,0.199-1.526,1.078-0.553,2.087c0.622,0.644,0.708,1.346-0.191,1.704c-2.126,0.845-2.31,2.646-2.246,4.485
				c0.05,1.473-0.437,2.202-1.943,2.326c-1.632,0.134-2.542,1.128-2.804,2.729c-0.474,2.887-2.058,4.892-4.703,6.147
				c-1.469,0.698-2.37,4.175-1.604,5.699c0.353,0.702,0.83,0.358,1.372,0.301c4.21-0.445,7.476-2.844,10.763-5.165
				c2.134-1.507,4.273-3.038,6.208-4.782c1.312-1.182,2.776-1.085,3.97-0.571c1.587,0.682,0.583,2.596,1.013,3.923
				c0.285,0.883-0.932,1.683-1.848,2.431c-2.252,1.838-4.948,1.594-7.438,2.287c-1.037,0.289-2.139,0.604-2.084,2.148
				c0.048,1.355,0.379,2.293,1.862,2.589c0.709,0.142,1.386,0.669,1.327,1.429c-0.057,0.737-0.812,0.86-1.49,0.889
				c-3.455,0.146-4.825,2.95-6.402,5.295c-0.899,1.337-2.097,2.555-2.408,4.321c-0.173,0.978-1.493,0.852-2.22,0.945
				c-4.065,0.524-7.994,1.664-11.966,2.58c-1.351,0.312-1.86,0.28-2.371-1.096c-0.733-1.973-3.116-2.856-5.247-2.487
				c-2.277,0.395-4.797-0.038-6.336,2.372c-0.241,0.377-0.705-0.134-1.059-0.248c-2.623-0.849-5.245-1.687-7.958-2.228
				c-1.783-0.355-3.218-1.233-3.998-3.008c-1.4-3.188-4.265-4.388-7.298-5.52c-3.087-1.152-5.931-2.613-7.827-5.707
				c-1.023-1.669-0.115-2.032,0.664-2.889c3.785-4.153,8.847-6.396,13.592-9.104c2.568-1.466,4.635-3.788,7.775-4.289
				c6.598-1.054,13.203-2.088,19.758-3.375c3.788-0.744,7.667-0.756,11.418-1.756c3.767-1.004,7.011-2.862,9.87-5.447
				C438.39,432.774,440.244,431.242,442.86,429.736z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(99)} fill={props.fillColors[99]} d="M426.815,611.17c5.204-3.512,10.613-6.552,16.942-7.558
				c3.737-0.594,7.454-1.338,11.259-1.536c-1.054,0-2.109,0.046-3.159-0.012c-1.257-0.069-1.886-0.568-0.943-2.096
				c-2.251,1.991-4.364-0.766-4.425-1.143c-0.326-2.032,2.011-2.458,3.409-4.025c-1.934,0.763-3.143,1.775-4.057,3.172
				c-0.344,0.526-0.783,0.87-1.373,0.403c-0.756-0.599,0.003-1.021,0.312-1.337c3.766-3.844,8.328-6.186,13.607-7.209
				c5.391-1.047,10.516-0.253,15.455,2.037c0.88-0.967,1.646-0.518,2.501,0.108c1.548,1.134,2.953,2.451,4.774,3.493
				c-0.5-0.916-1.196-1.812-0.471-2.84c1.317-1.952,3.507-2.179,5.487-2.507c4.896-0.812,9.838-0.725,14.688,0.352
				c1.672,0.371,3.702,0.822,3.745,3.28c0.001,0.406-0.123,0.773-0.36,1.1c-0.301,0.74-1.633,0.704-1.357,1.816
				c2.292-0.851,3.483-2.988,5.333-4.314c6.35-3.215,13.048-4.329,20.062-3.091c2.079,0.367,3.743,2.149,6.034,1.983
				c0.253,0.05,0.477,0.168,0.683,0.317c1.409,1.495,2.816,2.975,4.688,3.967c1.09,0.578,1.854,1.663,1.821,2.894
				c-0.033,1.26-1.396,1.306-2.258,1.812c-1.682,0.988-3.588,1.439-5.243,2.588c-1.337,0.927-3.455,0.722-4.866,0.314
				c-3.053-0.883-5.59,0.442-8.321,1c-0.388,0.08-0.762,0.226-1.139,0.355c-0.215,0.101-0.441,0.146-0.674,0.171
				c-2.228-0.35-4.238,0.031-5.855,1.749c-1.894,0.817-3.689,1.803-5.318,3.079c-1.688,0.423-3.342-0.071-4.378-1.21
				c-2.578-2.835-6.016-3.94-9.371-5.271c-1.271-0.605-2.49-0.472-3.665,0.271c-3.068,0.522-5.639,1.89-7.803,4.177
				c-1.928,2.038-3.486,2.168-5.315,0.791c-0.162-0.152-0.312-0.315-0.446-0.493c-0.652-1.248-2.723-1.623-2.232-2.999
				c0.038-0.909-0.34-0.543-0.028-0.616c0.115-0.026,0.106,0.047,0.116,0.21c0.092,1.435-0.595,1.396-1.614,0.914
				c-2.517-1.191-5.235-1.763-7.068-2.271c3.011,1.049,7.235,1.387,10.396,4.34c-0.027,0.107-0.054,0.216-0.08,0.324
				c-1.233-0.154-1.392,0.884-1.705,1.637c-0.491,1.183-1.257,1.512-2.437,1.022c1.078,1.273,1.216,4.721-0.322,5.986
				c-1.659,1.365-3.259-1.056-5.336-0.982c1.057,1.061,2.396,1.067,3.45,1.616c1.184,0.617,2.018,1.013,1.145,2.603
				c-0.847,1.544-2.036,1.803-3.272,1.045c-4.563-2.798-9.587-3.674-14.772-3.434c-3.194,0.147-6.498,0.521-9.113,2.814
				c-0.165,1.093-0.668,1.382-1.591,0.667c-0.536,0.121-1.133,0.131-1.599,0.381c-3.707,1.995-3.495,1.523-7.556-0.187
				c-2.454-1.034-5.221-1.89-7.985-2.367c-3.401-0.588-3.388-0.665-1.567-3.539c0.204-0.322,0.261-0.739,0.386-1.112
				C423.883,611.767,424.482,611.197,426.815,611.17z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(100)} fill={props.fillColors[100]} d="M349.053,402.904c5.557,4.168,10.261,8.654,15.737,12.107
				c5.335,3.365,10.626,6.75,16.824,8.545c3.773,1.093,7.287,3.086,9.991,6.127c2.25,2.53,5.401,3.143,8.39,4.145
				c5.879,1.969,11.66,4.239,18.38,5.635c-3.812,1.298-7.185,1.557-10.461,2.194c-4.859,0.944-9.77,1.63-14.653,2.449
				c-0.373,0.062-0.775,0.237-1.062,0.481c-4.377,3.709-9.83,5.69-14.439,9.019c-1.472,1.062-3.111,2.024-4.256,3.385
				c-3.435,4.081-8.019,3.992-12.668,3.923c-5.421-0.082-10.499-2.016-15.777-2.853c-0.99-0.157-1.553-0.189-0.937-1.38
				c0.275-0.529,0.125-1.263,0.32-1.853c1.095-3.311,0.957-3.619-2.56-4.16c-0.91-0.141-1.066-0.544-0.933-1.252
				c0.196-1.032,0.749-0.413,1.223-0.235c2.136,0.804,4.279,1.585,6.414,2.391c1.094,0.412,1.819,0.575,2.134-1.041
				c0.448-2.306,1.271-4.478-0.524-6.632c-1.021-1.227,0.003-2.603,0.238-3.879c0.187-1.009-0.007-1.234-1.039-1.199
				c-3.283,0.11-3.285,0.065-5.078-3.998c2.655,0.992,5.113,2.007,7.637,2.819c1.64,0.528,3.359,0.911,5.07,1.095
				c2.601,0.279,3.969-1.904,2.737-4.434c-1.8-3.697-4.63-6.725-6.87-10.141c-0.319-0.487-0.825-0.599-1.429-0.681
				c-1.696-0.23-2.796-1.952-2.55-3.588c0.137-0.906,0.71-0.934,1.361-0.94c1.678-0.017,3.359-0.091,5.032,0.001
				c1.241,0.068,1.58-0.498,1.416-1.559c-0.297-1.934-0.643-3.861-0.958-5.792c-0.275-1.688-1.363-0.813-2.15-0.644
				c-2.91,0.628-2.879,0.648-2.958-2.337c-0.032-1.232,0.463-2.671-1.539-3.147C348.527,405.336,348.949,404.064,349.053,402.904z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(101)} fill={props.fillColors[101]} d="M213.13,407.787c-6.916-6.805-14.726-11.443-22.679-15.854
				c-18.541-10.283-37.366-20.064-55.298-31.414c-7.818-4.948-15.305-10.369-22.444-16.258c-2.004-1.653-3.523-3.65-4.251-6.215
				c-1.209-4.26-1.843-8.619-2.346-13.001c-0.066-0.579-0.318-1.382,0.426-1.726c0.793-0.366,1.092,0.487,1.5,0.881
				c8.283,8.016,17.471,14.824,27.098,21.154c9.796,6.441,20.018,12.108,30.207,17.857c15.415,8.698,30.824,17.419,44.875,28.268
				c3.876,2.993,7.443,6.388,11.117,9.638c0.424,0.375,1.219,0.792,0.844,1.532c-0.327,0.646-1.109,0.516-1.743,0.507
				c-2.149-0.03-4.306-0.274-5.954,1.683C213.803,405.641,213.168,406.374,213.13,407.787z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(102)} fill={props.fillColors[102]} d="M476.094,218.563c-0.924-1.177-2.214-1.549-3.603-1.405
				c-2.567,0.265-4.361-1.452-4.503-3.936c-0.088-1.548-0.115-3.367-1.771-4.393l0.085,0.081c-0.796-2.261-2.854-3.133-4.773-3.844
				c-5.048-1.868-9.828-4.139-14.182-7.358c-1.55-1.146-3.504-1.743-5.352-2.628c5.909-1.389,6.515-4.12,5.573-6.972
				c-2.335-3.72-6.039-4.594-10.119-4.683c4.064,0.122,7.748,1.049,10.187,4.62c-0.36,0.254-0.425,0.628-0.449,1.036
				c-0.26,4.194-3.983,6.389-8.258,4.711c1.204-1.348,3.585-0.885,4.232-2.844c-1.267-0.368-1.104-1.268-0.824-2.211
				c-2.378-2.4-5.317-3.847-8.579-4.351c-1.862-0.287-3.387,2.594-2.698,5.421c0.468,1.919-0.582,1.389-1.413,1.238
				c-2.226-0.406-4.413-1.094-6.651-1.322c-2.193-0.223-4.349-0.885-6.612-0.664c2.023-1.333,4.432-1.218,6.633-1.76
				c2.229-0.548,3.626-1.666,4.644-3.609c1.364-2.602,3.391-3.577,6.156-2.882c4.663,1.173,9.317,2.391,13.932,3.74
				c3.517,1.029,5.034,4.584,3.622,8.033c-1.258,3.073-0.509,5.302,2.274,6.768c7.432-0.873,11.787,3.625,15.653,8.972
				c0.646,0.894,1.146,1.891,1.748,2.508c-4.236-5.906-8.295-12.532-17.447-11.419c0.256-0.596-0.215-0.894-0.548-1.187
				c-1.697-1.494-2.129-3.252-1.129-5.296c1.008-2.061,1.093-4.057-0.29-5.987c-0.196-0.274-0.366-0.537-0.123-0.843
				c0.239-0.3,0.492-0.13,0.813-0.058c2.032,0.456,3.46,2.162,3.285,4.948c-0.203,3.217,0.982,5.116,3.993,6.158
				c4.889,1.691,7.552,5.888,10.474,9.726c0.788,1.034,1.66,1.973,2.65,2.719c1.047,0.787,2.316,1.384,3.75,0.706
				c2.237-1.058,3.422-2.984,3.562-5.269c0.203-3.305,0.567-6.779-2.572-9.451c4.902,0.368,6.071,1.631,5.59,5.976
				c-0.216,1.948-0.602,3.892-1.088,5.792c-0.715,2.787-1.895,5.316-4.761,6.634c2.438,0.066,3.515-1.682,4.221-3.464
				c1.589-4.014,2.88-8.132,2.041-12.57c-0.055-0.289,0.019-0.646,0.497-0.781c1.002,0.332,0.77,1.899,1.932,2.135
				c0.112-0.032,0.226-0.063,0.338-0.095c3.244,0.524,6.487,1.047,8.55,4.06c0.073,0.232,0.067,0.462-0.005,0.693
				c0.134,1.346,0.902,2.174,2.198,2.374c1.524,0.234,2.512-0.785,3.418-1.789c0.546-0.604,0.795-1.931,1.847-1.338
				c1.081,0.609,0.269,1.673-0.062,2.499c-0.79,1.97-1.507,3.972-1.797,6.072c-0.578,4.175,0.951,7.354,4.639,9.51
				c1.419,0.83,2.872,1.686,3.764,3.171c1.372-0.073,1.889,0.755,2.235,1.939c0.653,2.225,0.346,4.539,0.757,6.777
				c-0.065,2.287,0.362,4.156,2.967,5c2.796,0.908,5.116,0.366,7.212-1.556c0.29-0.265,0.493-0.623,0.829-0.85
				c1.304-0.655,2.271-1.699,3.181-2.799c0.572-0.692,1.115-1.404,2.158-0.801c1.826-2.132,3.03-4.643,4.242-7.133
				c0.905-1.859,2.19-3.449,3.28-5.173c0.936-1.479,1.669-1.427,2.965-0.462c1.13,0.841,0.721,1.075-0.066,1.852
				c-2.904,2.865-4.974,6.271-5.975,10.27c-1.666,1.028-2.425,2.882-3.876,4.166c-2.237,1.979-4.945,3.179-7.288,4.96
				c-1.006,0.765-1.568,1.524-1.444,2.601c-0.03-0.915,0.271-1.699,1.229-2.456c2.314-1.828,5.067-2.949,7.297-4.951
				c1.505-1.352,2.445-3.143,4.018-4.379c1.454-1.228,1.773-3.12,2.708-4.655c1.146-1.884,2.194-3.844,4.053-5.133
				c1.55-1.075,3.172-0.313,3.388,1.577c0.178,1.564,0.215,3.145,0.314,4.718c-0.544,1.661-0.688,3.376-0.698,4.813
				c-0.048-1.458,0.252-3.169,0.642-4.869c1.322-1.251,0.036-2.944,0.916-4.398c1.004,0.186,1.188,0.993,1.221,1.977
				c0.038,1.186-0.059,2.433,0.274,3.543c1.062,3.535,4.605,5.206,8.162,3.999c2.472-0.839,3.361-0.204,3.722,2.656
				c0.024,0.225-0.02,0.438-0.12,0.64c-0.753,1.051-1.853,1.396-3.061,1.676c-3.306,0.768-4.094,1.742-4.302,5.01
				c-0.014,0.109-0.028,0.219-0.044,0.329c1.09,2.96,2.477,5.833,2.053,9.161c-0.168,1.323,0.046,2.042-1.979,1.658
				c-3.15-0.598-6.425-0.668-9.637-1.1c-0.982-0.132-1.797-0.431-2.645-0.901c-3.282-1.822-6.486-3.497-10.299-0.838
				c-1.328,0.927-3.319,0.568-4.486-1.037c-1.341-1.842-3.199-2.703-5.402-3.056c1.651,1.088,3.744,1.624,4.781,3.891
				c-4.315-0.574-8.438-1.122-12.799-1.702c1.992-2.713,4.743-1.919,7.369-2.243c-1.724-0.854-3.582-0.704-5.509-0.216
				c-1.789,0.452-2.646,3.093-5.126,2.005c1.031-2.74,1.689-5.542,0.827-8.516c-0.89,2.827,0.878,6.738-3.194,8.238
				c1.982-4.946,1.688-10.367,3.331-15.351c0.331-1.001,0.387-2.06-0.157-3.008c-0.297-0.52-0.579-1.484-1.491-0.574l0.045-0.03
				c-2.369,0.335-2.215,2.52-2.521,3.989c-0.431,2.069-0.961,3.618-3.091,4.562c-1.498,0.663-2.479,1.833-1.018,3.792
				c1.134,1.521,1.122,3.589,1.111,5.501c-0.002,0.361,0.625,0.934-0.218,1.111c-0.67,0.141-0.701-0.35-0.827-0.849
				c-0.547-2.181-1.321-4.25-3.422-5.565c1.08,2.032,2.677,3.855,2.692,6.309c-5.96-4.351-7.998-11.99-14.115-16.015
				c2.505-2.245,4.078-0.28,5.854,0.475c1.594-0.403,2.129,1.525,3.514,1.583c3.028,0.647,3.653,0.299,4.691-2.458
				c0.7-1.859,1.358-3.737,2.032-5.602c1.12-3.101-0.006-5.925-1.634-8.628c-1.515-2.514-3.613-4.678-4.551-7.538
				c-0.567-2.027-1.602-3.73-3.404-4.898c-2.483-1.048-2.804-0.918-4.242,1.33c-1.625,2.538-2.79,5.361-4.771,7.677
				c-0.107,0.023-0.217,0.045-0.325,0.068c-2.008,2.245-1.62,4.723-0.368,7.04c0.892,1.654,1.041,3.301,0.906,5.048
				c-0.04,0.525,0.332,1.507-0.672,1.441c-0.727-0.047-0.827-0.87-0.829-1.524c-0.005-1.752-0.042-3.491-1.291-4.914
				C477.01,221.458,475.852,220.639,476.094,218.563z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(103)} fill={props.fillColors[103]} d="M359.716,332.562c-0.254-2.117,0.228-3.964,0.432-5.744
				c0.748-6.523,0.878-13.093,1.731-19.61c0.606-4.625,0.582-9.33,1.017-13.983c0.515-5.503,1.159-10.997,1.856-16.48
				c0.936-7.354,2.122-14.672,3.592-21.94c1.73-8.562,3.93-16.995,7.118-25.145c3.66-9.357,8.508-18.022,15.137-25.619
				c0.437-0.5,0.491-1.375,1.519-1.574c0.243,1.265-0.1,2.409-0.248,3.625c-0.488,3.998-0.767,8.026-1.008,12.05
				c-0.652,10.892-1.652,21.632-6.567,31.722c-2.791,5.73-4.118,12.052-4.69,18.475c-0.855,9.579-1.617,19.179-5.07,28.301
				c-3.073,8.12-6.735,15.991-10.068,24C362.859,324.497,361.368,328.402,359.716,332.562z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(104)} fill={props.fillColors[104]} d="M273.358,357.401c-4.806-3.309-9.099-6.396-13.523-9.281
				c-7.03-4.586-14.502-8.43-21.722-12.697c-13.355-7.893-26.051-16.733-38.183-26.416c-7.011-5.596-13.633-11.614-20.368-17.519
				c-4.327-3.795-8.092-8.093-12.01-12.396c4.368,2.104,8.688,4.316,13.118,6.283c4.593,2.04,9.066,4.334,14.212,5.035
				c3.008,0.41,5.168,3.018,7.564,4.773c16.522,12.104,33.763,23.146,50.741,34.577c5.57,3.75,11.049,7.636,16.643,11.35
				c1.528,1.015,1.542,2.426,1.747,3.908C272.125,348.982,272.713,352.941,273.358,357.401z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(105)} fill={props.fillColors[105]} d="M256.013,604.817c-3.286-0.411-6.435-1.457-9.702-2.011
				c-6.189-1.048-12.321-0.977-18.479,0.012c-2.86,0.459-5.789,0.707-8.267,2.457l0.082-0.082c-1.378,0.166-2.561,0.816-3.69,1.554
				c-0.549,0.358-1.164,0.904-1.615,0.188c-0.465-0.737-0.214-1.479,0.721-1.908c1.491-0.685,2.939-1.461,4.82-2.407
				c-2.435,0.191-4.052,1.228-5.684,2.233c-0.633-1.853-0.639-1.863,0.726-2.479c2.518-1.138,5.042-2.26,7.913-3.544
				c-1.798-0.14-3.421,1.325-4.821-0.671c0.476,1.277,0.356,2.115-0.807,2.465c-1.199,0.361-2.356,1.968-3.588,0.764
				c-0.81-0.791-1.426-2.516-0.734-3.481c1.303-1.821-1.254-2.059-1.062-3.354c0.034-0.229-0.782,0.072-0.832,0.402
				c-0.14,0.92-0.715,1.657-0.649,2.715c0.215,3.44-0.346,3.986-3.787,3.419c-3.497-0.576-6.982-0.261-10.38,0.217
				c-1.873,0.262-3.329-0.019-4.646-0.967c-1.337-0.963-2.79-1.217-4.312-1.479c3.49,1.845,7.116,3.419,10.446,5.536
				c1.722,1.094,3.474,2.195,4.992,3.542c1.351,1.199,2.43,1.102,3.703,0.071c1.014-0.822,1.958-1.771,3.325-2.204
				c-0.262,1.889-3.582,5.84-4.952,5.715c-1.3-0.119-2.105-1.305-3.079-2.116c-5.645-4.704-12.14-7.659-19.287-9.234
				c5.423,1.894,10.823,3.811,15.58,7.191c0.946,0.672,1.038,1.364,0.783,2.179c-0.289,0.922-0.886,0.106-1.349,0.055
				c-0.112,0.012-0.223,0.022-0.334,0.034c-2.893-1.161-5.827-2.246-8.176-4.416c-0.031-0.108-0.06-0.219-0.088-0.33
				c-2.296-1.797-5.151-1.731-7.808-2.271c-1.224-0.249-2.443-0.604-3.748-0.564c-0.82,0.023-1.681-0.327-1.816-1.466
				c-0.139-1.162,0.622-1.458,1.535-1.664c-0.168-0.202-0.24-0.369-0.362-0.424c-2.86-1.254-2.858-1.251-3.989,1.793
				c-0.255,0.686-0.553,1.226-1.471,1.308c-1.732,0.154-3.467,0.111-5.21,0.423c-1.939,0.347-1.522-1.555-1.892-2.778
				c0.644-0.077,1.314-0.158,2.466-0.297c-1.32-0.653-2.812,0.248-3.166-1.489c-0.242-1.188,0.701-1.385,1.312-2.067
				c-1.26-0.139-2.307-0.646-2.091-1.858c0.189-1.064,1.394-0.853,2.231-0.846c3.532,0.027,7.228-0.727,10.223,2.024l-0.09-0.077
				c0.908,0.242-0.677,1.462,0.747,1.687c1.578,0.249,0.685-1.062,1.148-1.454c1.021-0.864,1.987-1.828,3.116-2.522
				c5.015-3.084,10.38-5.317,16.3-5.794c1.854-0.148,3.721,0.525,5.652,0.462c3.853-0.129,7.718-0.149,11.569-0.002
				c7.528,0.288,13.828,3.514,19.143,8.662c1.025,0.992,1.765,0.521,2.739,0.411c-1.129-0.135-1.617-1.229-2.676-1.551
				c-1.217-0.371-0.658-1.167-0.02-1.78c-0.274-1.138,0.586-1.538,1.328-1.971c1.032-0.604,1.991-1.294,2.85-2.126
				c3.343-1.661,6.917-2.129,10.562-1.693c1.962,0.234,3.906,0.494,5.88,0.438c0.731-0.104,1.432-0.375,1.841-0.969
				c1.184-1.715,3.017-2.47,4.749-3.383c0.908-0.235,2.09,0.12,2.504-1.166c-0.658-1.557-2.498-1.217-3.513-2.219
				c-0.729-0.72-1.362-1.444-0.907-2.5c0.478-1.108,1.309-1.583,2.504-0.953c3.148-0.139,6.128-1.255,9.322-1.421
				c2.156-0.112,3.301,0.785,5.116,2.048c-2.95,0.496-5.587-0.142-8.54,1.22c4.025-0.346,7.46-1.075,10.959-0.298
				c1.589,0.354,2.556,0.759,2.125,2.569c1.153,0.074,0.885,1.29,1.465,1.823c0.195,0.259,0.275,0.534,0.119,0.843
				c1.169,1.409,2.488,2.617,4.285,3.179c0.123,0.034,0.245,0.067,0.367,0.102c0.816,0.486,1.616,0.99,2.271,1.695
				c1.214,1.307,1.181,2.357-0.602,3.069c-0.719,0.154-1.339-0.38-1.573-0.758c-1.784-2.888-4.983-3.245-7.687-4.476
				c-2.941-1.196-6.033-0.605-9.068-0.767c-0.902,0.548-2.065,0.068-2.94,0.721c-4.854,0.133-7.647,3.615-10.749,6.601
				c-0.931,0.997-1.379,2.306-2.187,3.382c-1.055,0.149-2.765,2.284-2.612-1.051c0.051-1.099-1.568-1.354-2.481-1.437
				c-2.129-0.19-4.231-0.669-6.007-0.667c1.847,0.039,4.044,0.403,6.22,0.73c0.978,0.147,2.776,0.231,2.244,2.306
				c-0.197,0.768,0.483,0.829,1.105,0.748c1.458-0.19,2.338-1.189,3.265-2.188c1.665-1.793,3.092-3.84,5.271-5.108
				c5.068-3.938,10.77-3.72,16.577-2.43c2.68,0.596,5.382,1.189,7.91,2.343c0.583,0.702,1.326,1.295,1.686,2.179
				c-0.845,2.67-1.573,2.928-4.054,1.928c-3.375-1.359-6.774-2.449-10.493-2.895c-2.522-0.302-4.436,0.205-6.48,1.212
				c-0.2,0.118-0.407,0.229-0.61,0.34c-2.747,0.518-4.396,2.371-5.66,4.681c-0.207,0.337-0.353,0.676-0.065,1.049
				c0.35,0.458,0.866,0.691,1.302,1.028c0.889,0.689,0.826,1.428,0.026,2.188c-1.292,1.227-2.55,2.45-4.43,0.921
				c-0.286-0.231-0.741-0.261-1.127-0.349c3.188,1,3.418,1.485,1.645,3.479C256.23,604.768,256.122,604.792,256.013,604.817z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(106)} fill={props.fillColors[106]} d="M356.109,283.449c-0.56-9.67-2.66-19.049-4.041-28.53
				c-1.428-9.813,0.402-19.106,4.478-28.138c3.611-8.003,7.778-15.46,14.119-21.736c3.844-3.804,7.386-8.023,10.328-12.647
				c0.382-0.601,0.655-1.081,1.628-1.031c3.106,0.16,6.224,0.139,9.336,0.125c1.3-0.005,1.977,0.155,1.361,1.743
				c-0.356,0.922-0.279,2.041-0.273,3.07c0.02,3.662-2.848,5.672-4.804,8.088c-9.952,12.297-15.693,26.478-19.464,41.671
				c-1.916,7.721-2.729,15.689-5.397,23.233C361.601,274.329,358.994,278.927,356.109,283.449z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(107)} fill={props.fillColors[107]} d="M585.324,677.123c0.352,0.43,0.703,0.858,1.055,1.288c0.468,0.571,2.1,0.868,1.2,1.701
				c-0.868,0.802-2.163,2.323-3.856,0.653c-1.063-1.047-2.221-2.246-4.43-2.068c1.354,1.106,2.789,1.386,3.744,2.409
				c0.889,0.952,0.882,1.512-0.346,2.137c-0.93,0.475-1.865,0.468-2.79,0.368c-12.004-1.285-23.567,0.222-34.874,4.623
				c-4.057,1.579-7.243,4.329-10.812,6.514c-1.389,0.851-2.638,2.817-3.888,4.328c-0.702,0.85-1.093,1.098-2.083-0.065
				c-1.827-2.146-3.638-4.507-6.627-5.27c-0.784,1.009-1.756,0.574-2.581,0.196c-1.364-0.625-2.793-1.084-4.167-1.692
				c-5.555-3.946-11.959-5.555-18.519-6.683c-10.565-1.817-21.106-2.326-31.455,1.232c-2.995,1.03-6.097,1.976-8.159,4.692
				c-0.29,0.381-0.788,0.53-1.268,0.627c-0.412,0.054-0.783,0.253-1.191,0.324c-1.053,0.369-1.899-0.074-2.711-0.672
				c-0.505-0.512-0.833-1.164-1.298-1.712c-0.774-0.914-0.682-1.844,0.198-2.52c6.216-4.772,12.912-8.518,20.869-9.461
				c0.235-0.027,0.472,0.067,0.699,0.144c0.117,0.045,0.233,0.09,0.351,0.135c6.646-0.676,13.328-0.138,19.968-0.541
				c2.787-0.169,5.369-1.958,8.246-2.335c0.034-0.004,0.076-0.138,0.07-0.207c-0.008-0.072-0.068-0.141-0.106-0.21l-0.055,0.045
				c3.172,0.014,6.284-0.664,9.429-0.947c5.552-0.498,10.867,0.317,16.21,1.453c0.777,0.165,1.562,0.287,2.345,0.429l0.081,0.05
				c0.826,1.072,2.277,1.247,3.18,2.21c0.375,0.288,0.751,0.575,1.126,0.864c0.944,0.738,1.612,1.876,2.951,2.045
				c0.109-0.01,0.219-0.021,0.327-0.031c3.3,1.508,4.737-1.438,6.765-2.892c0.611-0.438,0.964-1.241,1.747-1.535
				c2.024-0.718,3.722-2.086,5.71-2.882c5.245-0.741,10.247-2.858,15.683-2.592c2.149,0.105,4.297,0.229,6.376,0.682
				c2.026,0.44,4.024,0.79,6.098,0.663c1.63,1.014,3.425,1.701,5.156,2.516C584.545,675.539,585.581,675.804,585.324,677.123z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(108)} fill={props.fillColors[108]} d="M457.179,378.581c0.442-1.875-0.86-4.361,2.415-5.26
				c1.422-0.39,1.153-2.029,0.028-3.117c-1.236-1.197-0.907-4.651,0.596-5.566c8.102-4.935,16.231-9.82,24.335-14.751
				c6.211-3.779,12.359-7.663,18.603-11.388c7.402-4.417,14.908-8.663,22.312-13.079c7.353-4.386,14.364-9.253,20.92-14.795
				c2.765-2.336,6.382-2.406,9.846-2.567c0.261-0.012,0.781,0.308,0.765,0.372c-0.227,0.92-0.569,1.812-0.794,2.732
				c-0.286,1.17-0.795,1.389-2.036,1.096c-1.204-0.285-2.262,0.431-2.754,1.741c-0.492,1.312,0.005,2.509,0.945,3.116
				c1.406,0.908,1.232,1.774,0.663,3.004c-0.764,1.651-1.47,3.338-2.062,5.058c-0.809,2.343-2.59,3.599-4.639,4.74
				c-10.869,6.047-21.686,12.188-32.644,18.082c-8.331,4.48-16.896,8.473-25.306,12.785
				C477.762,366.222,467.283,371.885,457.179,378.581z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(109)} fill={props.fillColors[109]} d="M276.414,379.209c-5.127-4.525-10.036-8.495-15.174-12.172
				c-11.825-8.461-24.458-15.707-36.329-24.094c-6.995-4.942-13.659-10.302-19.863-16.24c-3.968-3.798-7.985-7.521-11.646-11.637
				c-6.944-7.812-13.646-15.81-19.776-24.28c-1.176-1.625-2.375-3.232-3.273-5.135c1.26,1.008,2.6,1.931,3.766,3.037
				c6.801,6.455,13.804,12.701,21.05,18.631c10.688,8.746,22.044,16.584,33.781,23.896c14.349,8.939,28.863,17.625,42.811,27.2
				c1.476,1.013,2.078,2.324,2.3,4.045C274.76,367.873,275.571,373.271,276.414,379.209z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(110)} fill={props.fillColors[110]} d="M467.594,709.931c0.751,1.896,2.385,3.14,3.613,4.66
				c1.149,1.422,0.463,1.573-0.683,1.947c-1.484,0.484-2.48,0.103-3.445-1.193c-4.005-5.381-9.087-9.69-14.1-14.089
				c-2.873-2.521-5.993-4.643-9.162-6.726c-0.836-0.549-1.739-0.997-2.757-1.253c2.562,2.275,5.586,3.869,8.257,5.971
				c6.309,4.963,12.187,10.351,17.323,16.534c0.752,0.905,0.728,1.285-0.503,1.501c-2.005,0.351-3.898,1.432-6.036,0.884
				c-1.006-0.225-1.841-0.683-2.312-1.658c-0.898-2.262-3.094-3.164-4.799-4.581c-1.588-1.408-3.283-2.653-5.256-3.484
				c-2.698-2.837-6.282-3.693-9.923-4.211c-3.729-0.53-7.181-1.83-10.655-3.162c-7.29-2.793-14.833-4.368-22.645-4.829
				c-4.562-0.269-9.075-0.578-13.5,0.923c-0.761,0.258-1.57,0.037-2.357,0.001c-0.562-0.142-0.891-0.495-0.967-1.074
				c-0.553-1.914-2.76-1.343-3.787-2.537c2.183-2.137,8.945-3.354,16.921-3.508c5.91-0.113,11.411,2.158,17.209,2.51
				c-9.961-2.955-20.007-4.651-30.313-1.842c-0.379,0.104-0.797,0.193-1.104,0.416c-3.457,2.516-6.909,0.895-10.372,0.04
				c-0.584-0.144-1.131-0.317-1.188-0.968c-0.062-0.693,0.58-0.83,1.062-1.11c3.25-1.891,6.979-1.974,10.515-2.621
				c4.964-0.907,9.954-1.636,15.076-1.607c11.32,0.064,22.369,1.41,32.92,5.428c-1.089-1.245-2.865-2.092-5.929-2.849
				c-10.521-2.6-21.211-4.079-32.018-3.229c-6.714,0.528-13.426,1.647-19.941,3.615c-1.086,0.327-2.118,1.726-2.866,1.278
				c-1.301-0.777-2.211-2.223-3.25-3.419c-0.824-0.948-0.187-1.276,0.667-1.679c3.038-1.431,6.347-1.821,9.578-2.296
				c3.604-0.53,7.072-1.637,10.679-2.111c5.519-0.726,10.995-1.035,16.586-0.449c5.221,0.548,10.347,1.36,15.313,2.979
				c7.502,2.444,15.061,4.792,21.732,9.221c0.784,0.52,1.707,0.83,2.565,1.238c1.245-0.861,2.02-0.076,2.775,0.785
				c2.645,2.666,5.443,5.135,8.837,6.832c0.238,0.062,0.443,0.181,0.638,0.324c1.284,2.338,3.71,3.56,5.418,5.492
				c0.95,1.075,1.937,2.088,2.208,3.565C467.612,709.703,467.603,709.816,467.594,709.931z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(111)} fill={props.fillColors[111]} d="M359.278,217.619c-5.211,9.224-7.948,19.019-10.87,28.732
				c-2.045,6.796-3.989,13.622-6.364,20.312c-2.62,7.38-2.605,14.85-0.588,22.312c2.517,9.309,1.09,18.259-1.905,27.169
				c-2.21,6.575-3.945,13.266-4.315,20.255c-0.169,3.182-0.41,3.271-3.088,1.708c-2.451-1.431-4.911-2.832-7.419-4.166
				c-2.298-1.222-1.069-3.589-0.981-5.363c0.217-4.324,0.909-8.624,1.277-12.944c0.563-6.609,1.477-13.172,2.277-19.752
				c0.752-6.192,1.716-12.336,2.949-18.438c2.459-12.163,5.281-24.224,11.048-35.357c4.166-8.043,9.244-15.445,15.49-22.025
				c0.375-0.396,0.8-0.724,0.978-1.317C357.919,218.241,358.358,217.846,359.278,217.619z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(112)} fill={props.fillColors[112]} d="M452.231,722.543c3.065-0.407,5.658-2.164,8.597-2.982
				c6.571-1.83,13.208-3.44,20.046-2.897c12.205,0.97,23.163,4.926,31.42,14.541c1.179,1.373,2.247,2.849,3.286,4.332
				c1.96,2.801,1.936,2.818-0.943,4.193c-0.041,0.104-0.081,0.209-0.122,0.313c-0.976,0.788-2.152,0.961-3.343,1.119
				c-0.62,0.083-1.084-0.04-1.396-0.628c-1.815-3.421-5.27-4.969-8.199-7.099c-2.479-1.802-5.197-3.216-7.989-4.485
				c5.105,2.332,9.731,5.418,14.104,8.91c1.031,0.823,2.214,1.848,1.828,3.069c-0.457,1.456-1.96,2.345-3.561,2.419
				c-1.339,0.062-2.708,0.063-4.057-0.123c-0.965-0.612-2.128-0.669-3.145-1.134c-4.72-3.924-10.383-5.463-16.285-6.082
				c-9.056-0.95-17.974-0.239-26.598,2.981c-1.06,0.396-1.63,1.221-2.389,1.897c-1.967,0.556-3.812,1.393-5.547,2.483
				c-1.007,0.104-2.016,0.179-2.795,0.979c-1.632,1.673-3.538,1.763-5.599,0.937c-0.231-0.105-0.421-0.267-0.588-0.453
				c-1.481-3.046-1.54-3.62,1.637-6.433c6.433-5.697,13.624-10.114,20.491-11.563c-7.567,1.895-15.502,6.399-22.06,13.356
				c-0.815,0.864-0.803,2.586-2.51,2.567c-0.448-0.013-0.802-0.162-0.919-0.649c-0.189-0.254-0.328-0.651-0.577-0.74
				c-3.099-1.108-1.545-2.638-0.383-4.106c4.347-5.494,9.762-9.737,15.699-13.364c0.465-0.284,1.069-0.395,1.23-1.037
				C451.718,722.61,451.937,722.5,452.231,722.543z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(113)} fill={props.fillColors[113]} d="M34.052,225.893c1.84,0.62,3.148,0.449,4.332-0.879
				c-0.179,2.083,0.037,2.823,2.369,1.977c1.829-0.664,4.125-0.057,6.212,0.015c0.567,0.02,0.44,0.258,0.297,0.741
				c-0.448,1.511,0.445,2.383,1.81,2.37c1.356-0.013,1.812,0.907,2.332,1.71c3.603,5.565,7.137,11.174,10.324,16.992
				c5.554,10.139,10.928,20.36,14.235,31.517c1.948,6.57,3.322,13.237,3.982,20.074c0.793,8.231,1.399,16.525,3.879,24.472
				c1.427,4.577,3.444,8.953,5.831,13.138c0.41,0.719,0.731,1.49,1.375,2.82c-4.803-1.743-8.172-4.37-10.52-7.912
				c-5.442-8.213-9.481-17.151-11.132-26.897c-0.905-5.338-1.3-10.767-2.423-16.097c-2.555-12.132-6.87-23.597-12.831-34.442
				c-5.571-10.136-12.074-19.646-19.543-28.487C34.438,226.833,34.379,226.591,34.052,225.893z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(114)} fill={props.fillColors[114]} d="M413.51,313.273c3.465-13.2,6.119-26.06,8.064-39.07
				c0.921-6.161,1.72-12.33,2.355-18.527c0.746-7.269,1.186-14.54,3.901-21.542c3.065-7.902,6.94-15.44,9.939-23.354
				c1.311-3.456,2.589-6.928,3.446-10.537c0.306-1.282,0.691-1.929,2.35-1.121c3.511,1.713,6.636,3.966,9.771,6.226
				c0.887,0.64,1.308,1.336,0.965,2.758c-0.979,4.077-2.221,7.839-4.745,11.382c-7.885,11.074-12.313,23.697-15.47,36.815
				c-2.291,9.523-4.086,19.13-5.142,28.872c-0.271,2.508-0.59,5.012-0.881,7.518c-0.266,2.287-2.286,3.411-3.523,4.979
				c-3.727,4.722-7.273,9.534-10.177,14.809C414.259,312.675,414.029,312.8,413.51,313.273z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(115)} fill={props.fillColors[115]} d="M379.927,191.197c-2.981,4.693-6.616,8.663-10.2,12.671
				c-7.011,7.842-14.807,14.988-21.067,23.495c-5.602,7.611-10.218,15.781-13.345,24.737c-3.732,10.695-6.014,21.734-7.889,32.882
				c-1.387,8.238-2.491,16.501-3.33,24.813c-0.705,7.002-1.49,13.995-1.826,21.025c-0.068,1.436-0.638,1.617-1.78,1.241
				c-0.073-0.024-0.134-0.086-0.205-0.119c-2.024-0.909-4.857-1.304-5.848-2.867c-0.991-1.564,0.654-4.125,1.156-6.244
				c1.908-8.046,3.21-16.195,4.307-24.387c1.208-9.023,2.162-18.068,2.827-27.148c0.277-3.787,0.934-7.546,0.889-11.358
				c-0.015-1.285,0.58-2.392,1.348-3.219c7.744-8.349,12.877-18.316,18.075-28.306c3.74-7.188,7.193-14.438,9.526-22.206
				c0.408-1.361,0.851-2.718,0.837-4.127c-0.057-5.46,3.641-9.051,6.846-12.569c1.662-1.824,5.119-1.898,7.71-1.172
				C371.872,189.436,375.826,190.324,379.927,191.197z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(116)} fill={props.fillColors[116]} d="M507.758,608.184c1.319-1.184,2.641-2.363,4.492-2.638
				c2.035-1.443,4.392-1.874,6.796-2.152l-0.089,0.035c3.602-1.183,7.328-1.167,11.035-0.97c2.345,0.125,4.689-0.029,7.055,0.395
				c2.314,0.415,4.587,1.017,6.902,1.411c0.223,0.038,0.43,0.203,0.658,0.276c-0.117-0.172-0.278-0.32-0.24-0.564
				c0.014-0.084,0.121-0.249,0.081-0.22c-1.27,0.955-2.449-0.286-3.687-0.141c-0.574,0.067-1.186-0.152-1.239-0.838
				c-0.054-0.676,0.533-0.848,1.073-1.074c2.336-0.979,4.79-1.059,7.259-1.008c1.264,0.026,2.525-0.027,3.394-0.059
				c-2.507,0.376-5.459-0.328-8.334,0.403c-1.283,0.326-2.692,0.428-3.637,1.576c-0.279,0.339-0.733,0.415-1.096,0.383
				c-1.002-0.092-2.488,0.298-2.849-0.673c-0.454-1.223,1.186-1.164,1.89-1.662c1.488-1.053,3.251-1.523,4.961-2.088
				c0.109,0.026,0.219,0.054,0.327,0.08c2.678-0.507,0.279-2.335,0.233-2.368c-2.216-1.564-0.445-2.211,0.674-2.837
				c1.545-0.866,3.533-0.743,4.875-2.111c0.081-0.101,0.162-0.2,0.241-0.303c1.378-1.121,3.027-1.181,4.689-1.214
				c2.577-0.052,5.162-0.393,7.714,0.259c2.499,1.78,5.308,3.173,7.146,5.778c0.453,0.644,1.04,1.208,1.014,2.078
				c-0.611,1.253-1.708,1.64-3.011,1.855c-0.892,0.148-2.279,0.198-1.458,1.836c1.996,1.15,3.279,3.03,4.788,4.68
				c-0.33,1.215-0.936,1.408-2.036,0.455c-2.209-1.911-4.688-3.329-7.582-4.51c2.349,2.006,5.479,2.563,7.098,5.194
				c-1.464,1.12-2.613,0.396-3.702-0.772c-0.514,1.104,1.389,1.105,0.604,1.957c-0.711,0.771-1.598,0.435-2.359,0
				c-1.287-0.734-2.716-0.775-4.121-0.956c-0.103,0.041-0.205,0.083-0.308,0.125c-1.995,1.09-3.816,0.447-5.601-0.573
				c1.78,1.042,4.07,1.15,5.406,2.989c0.025,0.107,0.049,0.216,0.074,0.324c1.58,2.253,4.092,3.706,5.253,6.361
				c0.508,1.162,0.183,1.487-0.795,1.752c-2.641,0.713-5.308,1.344-7.913,2.171c-1.472,0.469-1.722-1.081-2.742-1.332
				c-0.08,0.821,1.529,1.602,0.799,1.859c-1.532,0.539-2.77,2.287-4.715,1.47c-0.932-0.392-1.864-0.784-2.796-1.177
				c-2.229,0.814-3.042,0.405-3.646-1.831c-4.154-2.592-8.729-2.505-13.38-2.22c-3.646,0.224-6.921,1.354-9.919,3.383
				c-0.727,0.492-1.379,0.08-2.029-0.17c-3.417-1.316-6.965-1.876-10.614-1.938c-0.574-0.01-1.371,0.285-1.686-0.498
				c-0.343-0.853,0.322-1.336,0.896-1.754c1.891-1.381,3.808-2.726,5.61-4.223c-2.678,1.596-5.552,2.934-7.452,5.566
				c-0.812,1.125-1.963,1.327-2.859,0.521c-0.849-0.761,0.063-1.545,0.703-2.098c1.05-0.907,1.712-2.068,2.312-3.282
				c-0.207-0.323-0.21-0.661-0.067-1.008c0.672-1.123,1.461-2.12,2.764-2.561C505.705,609.191,506.722,608.664,507.758,608.184z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(117)} fill={props.fillColors[117]} d="M111.407,345.816c6.413,5.834,13.795,10.119,20.916,14.768
				c8.752,5.713,17.944,10.676,26.983,15.906c13.586,7.86,28.062,14.163,40.893,23.335c3.91,2.796,7.924,5.472,11.54,8.913
				c-4.188,0.675-7.786-0.151-11.431-1.822c-12.286-5.63-24.922-10.402-37.809-14.466c-5.062-1.596-10.063-3.326-15.071-5.073
				c-2.998-1.046-4.896-3.274-6.919-5.271c-6.333-6.25-13.408-11.78-19.119-18.603C117.035,358.3,113.33,352.523,111.407,345.816z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(118)} fill={props.fillColors[118]} d="M90.806,445.416c-1.657,0.702-3.213,1.825-5.144,1.505
				c-2.002-0.333-2.455-1.499-1.142-3.037c0.922-1.081,1.861-2.178,3.163-2.878c0.55-0.297,1.376-0.691,1.458-1.151
				c0.523-2.959,2.687-2.407,4.695-2.447c1.847-0.036,3.388-0.735,4.167-2.613c0.958-2.31,3.485-2.741,5.328-4.268
				c-1.606-0.765-2.77-1.939-4.774-1.623c-1.414,0.223-2.298-0.722-1.507-2.459c0.432-0.949,1.03-1.948,0.831-3.15
				c-0.55,0.309-0.557,0.813-0.73,1.251c-1.709,4.315-1.72,4.328,2.854,5.019c0.611,0.093,1.139,0.267,1.306,0.834
				c0.192,0.652-0.317,0.997-0.864,1.208c-1.577,0.608-2.575,1.765-3.29,3.265c-0.605,1.271-1.725,1.803-3.116,1.844
				c-1.271,0.037-2.546,0.077-3.809,0.213c-0.847,0.091-1.826-0.286-1.472,1.571c0.3,1.573-1.901,2.333-3.109,3.168
				c-1.142,0.79-1.736,1.835-2.352,2.879c-1.097,1.859,0.944,2.355,1.923,3.432c-3.061,1.332-5.988,0.9-8.914,0.359
				c-0.284-0.054-0.698-0.273-0.766-0.502c-0.792-2.652-2.568-2.287-4.706-1.893c-1.932,0.357-3.983,0.022-5.979,0.087
				c-1.575,0.051-1.469-1.058-1.259-1.929c0.241-0.994-1.254-2.801,1.144-2.894c1.772-0.069,3.431-0.863,5.356-0.19
				c1.363,0.476,2.335-0.76,2.367-2.252c0.042-1.98,0.567-3.627,2.081-5.073c1.093-1.045,0.948-2.582,0.489-4.038
				c-0.683-2.166-0.111-3.815,2.015-4.944c1.283-0.68,1.793-0.288,1.913,0.947c0.107,1.11,0.168,2.245,0.066,3.351
				c-0.2,2.16,0.976,2.854,2.744,2.355c1.53-0.43,2.647,0.482,4.508,0.141c-1.404,1.896-3.743,1.827-3.942,3.828
				c-0.078,0.786,0.64,1.187,1.88,1.924c-1.958-2.925,0.123-3.3,1.584-4.104c0.571-0.313,1.619-0.361,1.435-1.313
				c-0.133-0.691-1.016-0.532-1.564-0.765c-1.395-0.589-2.808-0.366-4.22-0.423c-0.685-0.027-1.499,0.201-1.626-0.895
				c-0.544-4.688-0.73-9.286,2.39-13.343c1.699-2.208,6.861-3.743,9.491-2.658c0.429,0.177,1.113,0.378,1.183,0.683
				c0.773,3.357,3.396,2.572,5.658,2.62c0.318,0.007,0.636,0.055,0.954,0.084c0.182,1.334,1.759,2.048,1.357,3.794
				c-0.232,1.007,1.149,0.706,1.892,0.623c2.803-0.312,5.141,0.399,7.042,2.648c0.803,0.95,0.901,1.477,0.042,2.366
				c-1.02,1.056-1.01,1.827,0.574,2.62c2.04,1.02,1.58,3.114,0.893,4.658c-0.954,2.141-0.375,3.145,1.705,3.675
				c0.609,0.155,1.203,0.369,1.815,0.511c3.243,0.75,4.034,2.7,2.112,5.568c-0.658,0.982-0.333,1.408,0.564,1.324
				c2.285-0.213,4.389,0.516,6.552,0.972c0.824,0.173,2.084-0.438,1.981,1.242c-0.089,1.45-0.813,2.291-2.268,2.147
				c-1.86-0.183-3.609,0.518-5.438,0.509c-0.373-0.002-1.031,0.114-1.076,0.295c-0.634,2.559-3.049,2.656-4.846,3.599
				c-0.053,0.098-0.106,0.196-0.159,0.296c-0.536,0.484-1.2,0.393-1.822,0.343c-1.4-0.111-1.847-0.833-1.165-2.101
				c0.337-0.628,0.9-1.129,1.114-1.831c0.298-0.979,0.216-1.816-1.054-1.865c-2.481-0.094-3.782-1.76-4.997-3.597
				c-1.395-2.11-2.69-2.213-4.62-0.497c-1.077,0.958-2.015,2.031-2.964,3.106c-0.522,0.592-1.16,1.204-2.067,0.686
				c-0.788-0.45-0.611-1.178-0.49-1.911c0.098-0.589,0.446-1.263-0.271-1.715C92.906,445.036,92.303,447.005,90.806,445.416z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(119)} fill={props.fillColors[119]} d="M429.983,421.323c-3.637,0.887-7.281,1.74-10.902,2.688
				c-0.774,0.202-1.969,0.117-2.163,1.059c-0.184,0.893,0.976,1.178,1.58,1.537c1.265,0.752,1.698,1.78,1.633,3.138
				c-0.065,1.381-1.26,1.481-2.176,1.805c-3.112,1.096-3.238,1.104-2.371,4.334c0.438,1.625,0.082,1.555-1.26,1.205
				c-6.276-1.637-12.258-4.151-18.438-6.071c-2.052-0.638-3.174-2.582-4.803-3.833c-2.612-2.006-5.379-3.633-8.475-4.638
				c-4.243-1.379-8.29-3.124-12.188-5.747c1.91-0.091,2.821-1.657,4.312-2.414c5.364-2.722,11.357-3.107,16.888-5.171
				c4.186-1.562,8.741-2.12,13.112-3.201c1.058-0.262,1.729-0.178,2.452,0.755c1.221,1.573,2.983,1.974,4.906,1.644
				c2.328-0.399,4.642-0.905,6.981-1.207c1.203-0.155,1.811-0.933,2.423-1.733c1.901-2.491,4.333-4.461,6.465-6.725
				c0.529-0.562,1.045-0.824,1.495,0c0.332,0.607,1.475,1.442,0.429,1.928c-1.493,0.692-0.799,1.237-0.241,1.925
				c0.863,1.063,0.633,1.845-0.544,2.434c-1.315,0.659-1.963,1.615-2.036,3.194c-0.115,2.473-1.382,4.851-0.426,7.434
				c0.412,1.113-0.46,1.677-1.398,2.151c-1.434,0.727-3.019,1.243-4.802,2.922c3.646,0,6.574,0,9.503,0
				C429.953,420.931,429.969,421.127,429.983,421.323z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(120)} fill={props.fillColors[120]} d="M250.925,524.801c-1.081-3.541-2.39-4.332-5.666-3.282
				c-2.479,0.794-4.008-0.806-5.57-2.166c-1.617-1.407-2.928-3.153-4.777-4.317c-1.355-0.853-2.726-1.488-4.389-1.004
				c-1.323,0.384-1.878-0.522-2.31-1.514c-0.782-1.796-1.875-3.135-3.827-3.917c-1.9-0.762-1.064-2.593-0.742-3.933
				c0.366-1.524,1.128-2.953,1.715-4.425c1.593-3.989,1.422-4.474-2.391-6.549c1.269-1.159,3.087-1.62,3.723-3.485
				c0.482-1.414,0.604-2.462-0.564-3.634c-1.784-1.79-1.718-3.535,0.031-5.381c0.825-0.871,1.564-1.759,2.022-2.888
				c0.552-1.361,1.269-1.565,2.443-0.452c2.159,2.046,3.774,4.488,5.377,6.942c1.204,1.844,2.604,3.215,4.872,3.801
				c4.474,1.156,8.404,3.746,11.99,6.37c2.807,2.053,4.892,5.35,7.368,8.034c0.953,1.033,1.823,2.142,2.743,3.231
				c-1.138,0.797-2.04-0.148-3.004-0.252c-1.604-0.172-3.391-1.225-4.61,0.408c-1.284,1.72,0.386,3.147,1.162,4.895
				c-1.151,0-2.208,0.02-3.262-0.005c-1.019-0.024-1.747,0.244-1.688,1.451c0.06,0.108,0.12,0.216,0.182,0.322
				c0.783,0.591,1.452,1.304,1.683,2.263c0.744,3.075,2.696,5.339,4.813,7.54c1.488,1.546,3.245,2.847,4.419,4.685
				c1.362,2.136,0.973,3.52-1.465,4.079c-2.771,0.637-5.64,1.076-8.319-0.538C251.95,529.079,249.957,527.399,250.925,524.801z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(121)} fill={props.fillColors[121]} d="M199.107,291.051c6.598-1.487,12.784-1.212,18.954-1.178
				c0.233,0.001,0.5,0.043,0.694,0.16c8.172,4.896,16.347,9.789,24.502,14.715c4.943,2.986,9.905,5.946,14.759,9.072
				c2.901,1.869,5.718,3.908,8.368,6.117c1.898,1.582,1.641,4.233,2.23,6.406c0.862,3.182,1.301,6.482,1.832,9.747
				c0.117,0.72,0.417,1.717-0.325,2.198c-0.605,0.392-1.119-0.406-1.629-0.743c-8.569-5.638-17.258-11.093-25.836-16.71
				c-14.155-9.268-28.338-18.513-41.854-28.719C200.43,291.836,200.012,291.617,199.107,291.051z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(122)} fill={props.fillColors[122]} d="M164.472,470.367c0.299-0.112,0.666-0.149,0.886-0.348
				c0.914-0.826,2.643-1.525,2.096-2.866c-0.667-1.638-2.195-0.15-3.341-0.131c-0.841,0.016-1.588,0.746-2.567,0.394
				c0.985-1.132,2.476-1.343,3.618-2.124c0.431-0.295,1.065-0.639,0.893-1.288c-0.155-0.583-0.712-0.764-1.328-0.769
				c-1.565-0.012-2.956-1.617-4.673-0.508c-0.142,0.092-0.619-0.136-0.793-0.34c-0.267-0.313,0.036-0.707,0.277-0.782
				c2.641-0.831,2.378-4.004,4.358-5.361c0.367-0.252,0.418-0.672,0.089-1.036c-0.344-0.38-0.667-0.547-1.224-0.206
				c-0.945,0.579-1.961,1.053-2.977,1.503c-0.546,0.242-1.199,0.939-1.726,0.337c-0.548-0.627-0.468-1.543,0.107-2.212
				c0.94-1.092,1.868-2.211,2.249-3.601c0.206-0.752,1.113-1.623,0.145-2.396c-0.743-0.595-1.855-0.555-2.512-0.141
				c-1.849,1.166-2.22,0.07-2.594-1.364c-0.751-2.888-1.36-5.831-2.378-8.625c-0.796-2.188-2.015-4.226-2.973-6.375
				c-4.981-11.176-11.058-21.674-18.905-31.166c-8.972-10.852-19.403-19.943-31.251-27.465c-2.882-1.83-5.209-4.005-6.604-7.267
				c-1.717-4.014-3.822-7.861-5.823-11.907c2.574-0.466,4.193,1.301,5.876,2.39c6.786,4.39,12.997,9.531,18.939,15.022
				c3.968,3.666,7.98,7.286,11.628,11.263c7.876,8.584,15.186,17.613,21.653,27.338c8.014,12.049,14.263,24.918,18.548,38.712
				c2.316,7.453,4.016,15.074,4.4,22.923c0.023,0.472,0.082,0.948,0.188,1.408c0.958,4.152,0.685,4.471-3.72,4.19
				c0.073-0.221,0.077-0.509,0.217-0.614c0.931-0.694,2.524-1.242,2.182-2.486c-0.308-1.12-2.049-0.574-3.092-1.02
				c-0.671-0.287-1.828,0.394-2.083-0.616c-0.238-0.94,0.87-1.127,1.503-1.554c0.305-0.206,0.475-0.612,0.706-0.928
				c0.12-0.037,0.239-0.074,0.358-0.111C164.708,470.283,164.59,470.324,164.472,470.367z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(123)} fill={props.fillColors[123]} d="M259.554,548.058c-1.173-2.143-3.293-3.259-5.105-4.708
				c-0.019-0.111-0.038-0.222-0.057-0.333c0.614-1.329,1.956-0.812,2.951-1.096c2.713-0.773,3.497-2.591,2.045-5.043
				c-0.703-1.187-1.791-2.025-2.685-3.038c-0.86-0.976-0.826-1.718,0.555-2.054c1.147-0.279,2.266-0.595,3.498-0.58
				c2.314,0.027,2.985-1.279,1.795-3.242c-1.511-2.492-3.866-4.22-5.796-6.332c-2.151-2.355-4.22-4.766-5.194-7.901
				c-0.125-0.403-0.206-0.76,0.103-1.103l-0.094,0.103c2.822-1.269,5.376-0.743,7.52,1.578c0.363,0.394,0.771,0.5,1.264,0.451
				c1.181-0.473,1.988,0.282,2.79,0.911c0.815,0.64,1.534,1.401,2.502,1.831c2.614,2.227,4.432,5.188,7.003,7.478
				c3.524,3.138,7.313,5.747,11.823,7.28c2.375,0.808,4.655,1.887,6.84,3.142c0.042,0.105,0.084,0.212,0.126,0.317
				c0.808,0.785,1.808,0.827,2.491,0.084c0.876-0.953-0.201-1.697-0.652-2.47c-0.414-0.709-1.78-1.059-1.355-1.956
				c0.433-0.914,1.681-0.601,2.623-0.651c4.844-0.26,9.701-0.426,14.576-1.92c-0.52,1.695-2.369,1.718-2.806,3.083
				c-0.034,0.113-0.067,0.228-0.101,0.341c-1.008,1.127-1.415,2.428-0.397,3.658c1.236,1.494,0.477,2.334-0.775,3.018
				c-2.555,1.396-5.385,1.915-8.223,2.338c-1.104,0.165-2.197,0.377-3.294,0.581c-1.747,0.325-2.798,1.417-3.4,3.02
				c-1.854,4.934-2.181,5.157-7.411,5.033c-0.477-0.011-0.958,0.012-1.414-0.176c-0.111-0.041-0.223-0.083-0.334-0.124
				c-2.061-0.057-2.225,1.352-1.786,2.726c0.543,1.705,1.417,3.311,2.215,4.925c0.658,1.331,0.141,1.819-1.124,1.513
				c-1.354-0.328-2.671-0.849-3.966-1.378c-3.811-1.559-7.524-3.426-10.71-5.969c-1.474-1.176-2.656-2.332-4.596-2.353
				C260.104,549.322,259.643,548.965,259.554,548.058z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(124)} fill={props.fillColors[124]} d="M171.471,514.647c1.331-0.812,2.279-1.997,2.989-3.339
				c0.669-1.266,0.976-2.566-0.789-3.31c-0.284-0.119-0.697-0.173-0.824-0.389c-1.404-2.388-1.392-2.395-3.708-2.649
				c0.58-1.324,1.783-2.011,2.62-2.989c0.569-0.665,1.379-1.219,1.7-1.982c1.696-4.025,5.544-5.061,9.053-6.217
				c1.88-0.62,4.195-0.065,6.294,0.153c2.341,0.244,3.577-0.888,4.245-2.932c0.809-2.475,2.341-3.926,5.103-3.707
				c0.316,0.025,0.718,0.103,0.944-0.048c2.016-1.349,3.252-0.352,5.13,0.804c2.571,1.58,2.562,3.77,2.916,5.992
				c0.419,2.64,0.729,5.283,0.082,8.001c-0.634,2.666,0.846,5.074,1.505,7.574c0.198,0.751,1.382,1.539,0.22,2.253
				c-1.01,0.622-2.248,0.877-3.323-0.033c-0.283-0.24-0.419-0.653-0.624-0.986c-1.898,0.285-2.074-1.687-3.19-2.429
				c-0.286-0.28-0.601-0.526-0.935-0.744c-0.829-0.542-1.536-1.646-2.703-1.007c-1.139,0.622-1.453,1.748-1.514,3.044
				c-0.132,2.815-0.465,3.11-3.245,3.223c-2.597,0.104-5.046,1.505-7.754,0.8c-0.515-0.135-1.352,0.226-1.455,0.972
				c-0.084,0.607,0.543,0.939,0.974,1.261c0.451,0.337,1.066,0.297,1.598,0.47c0.227,0.067,0.434,0.176,0.635,0.293
				c1.015,0.452,1.871,1.169,2.953,1.548c1.332,0.466,1.596,1.688,1.452,2.957c-0.15,1.323-1.19,1.309-2.172,1.294
				c-2.28-0.033-3.655,0.795-4.089,3.294c-0.355,2.046-1.845,3.693-3.03,5.378c-1.053,1.496-1.947,3.083-2.91,4.626
				c-1.725,2.763-3.641,3.178-6.032,1.103c-0.121-0.104-0.25-0.197-0.378-0.293c-2.792-2.093-2.789-2.171-0.193-4.78
				c0.505-0.508,1.176-0.842,1.548-1.481c0.167-0.287,0.286-0.808,0.105-0.895c-2.078-0.996-1.751-3.835-3.291-4.788
				c-2.109-1.307-0.167-3.274-1.314-4.414c-1.39-1.383-1.44-2.605-0.088-3.992c0.462-0.474,0.271-1.436,1.15-1.643
				C171.242,514.642,171.357,514.645,171.471,514.647z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(125)} fill={props.fillColors[125]} d="M59.762,239.294c0.504,0.328,1.54-1.801,1.732,0.412
				c0.042,0.478,0.961,0.355,1.482,0.124c1.078-0.479,2.193-0.128,3.278-0.212c3.609-0.279,4.533,2.58,5.693,4.896
				c7.264,14.5,13.235,29.504,16.675,45.395c1.703,7.868,3.01,15.826,4.33,23.771c2.036,12.261,4.921,24.249,10.626,35.4
				c0.094,0.184,0.09,0.419,0.242,1.184c-3.523-2.029-6.888-3.75-8.892-6.806c-3.763-5.736-7.379-11.591-8.966-18.453
				c-0.977-4.221-1.725-8.472-2.315-12.747c-1.197-8.674-2.294-17.35-4.232-25.911c-2.722-12.023-7.063-23.429-12.647-34.375
				C64.575,247.674,62.105,243.516,59.762,239.294z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(126)} fill={props.fillColors[126]} d="M509.134,535.352c-0.948-0.687-1.898-1.371-2.845-2.061
				c-0.611-0.446-1.138-1.259-2.041-0.596c-0.96,0.705-0.645,1.602-0.101,2.32c0.899,1.188,1.173,2.381,0.579,3.775
				c-0.238,0.558-0.252,1.185,0.581,1.204c1.045,0.024,1.155,0.675,1.095,1.449c-0.063,0.802,0.222,1.308,0.936,1.737
				c0.752,0.452,1.761,0.872,0.869,2.157c-0.793,1.144-1.742,1.694-3.016,1.131c-0.723-0.32-1.495-1.124-2.141-0.551
				c-0.747,0.664-0.278,1.728-0.125,2.669c0.24,1.475,0.503,2.465,2.342,2.924c1.322,0.329,1.338,2.39,1.89,3.676
				c0.708,1.65,1.779,2.568,3.514,2.835c0.525,0.08,1.118,0.111,1.317,0.812c0.445,1.562,1.773,2.094,3.345,2.543
				c-1.347,1.492-2.719,1.323-4.188,0.715c-1.465-0.607-2.912-1.301-4.432-1.719c-1.826-0.502-3.571-1.062-4.948,1.33
				c-1.288,2.237-2.268-0.984-3.824-0.371c0.604-1.012,0.95-0.345,1.318-0.286c0.983,0.156,1.71-0.578,1.793-1.226
				c0.098-0.759-0.39-1.582-1.311-2.026c-2.192-1.056-4.57-1.738-6.512-3.312c-0.699-0.566-1.407-1.043-1.459-2.042
				c0.426-0.331,0.756,0.031,1.081,0.168c1.15,0.485,2.254,0.905,3.174-0.413c0.902-1.292-0.21-1.91-0.923-2.542
				c-0.729-0.646-0.727-1.233-0.46-2.087c0.847-2.705,0.088-3.79-2.734-4.14c-1.481-0.185-2.972-0.116-4.471-0.308
				c-2.065-0.264-3.425-1.309-5.354-2.896c2.593,0.715,4.524-0.286,6.601-0.271c1.931,0.015,2.529-1.218,1.627-2.976
				c-0.341-0.663-0.653-1.062,0.272-1.41c0.661-0.249,1.047-0.73,0.826-1.492c-0.248-0.854-0.943-0.949-1.696-1.031
				c-1.56-0.172-3.112-0.408-4.838-0.641c0.674-1.668,2.54-2.341,2.913-4.144c-2.235-0.568-4.198-2.672-6.896-1.312
				c-0.288,0.146-0.58-0.111-0.652-0.495c-0.092-0.489,0.239-0.628,0.551-0.901c0.818-0.716,1.85-0.449,2.737-0.605
				c3.251-0.574,6.215-2.027,9.378-2.876c3.372-0.905,6.681-1.27,10.105-1.215c1.047,0.017,2.4-0.049,2.46-1.238
				c0.06-1.191-1.273-1.301-2.325-1.532c-2.883-0.635-5.773-1.06-8.725-0.826c-1.189,0.095-1.669-0.461-2.045-1.515
				c-0.753-2.11-2.116-3.572-4.435-4.194c-1.673-0.449-3.543-0.903-4.188-2.94c2.928-1.605,5.97,0.168,8.951-0.263
				c1.078-0.156,1.15,0.746,1.021,1.444c-0.285,1.52,0.562,1.694,1.578,1.271c0.871-0.363,1.73-0.199,2.57-0.369
				c1.358-0.275,2.986,0.047,3.483-2.048c0.295-1.241,1.917-0.978,2.898-1.177c2.944-0.597,5.797-1.456,8.633-2.408
				c0.407-0.137,1.002-0.764,1.223-0.157c1.027,2.827,3.646,4.836,4.129,7.872c0.194,1.227-0.384,1.995-1.961,1.214
				c-3.54-1.754-3.537-1.683-3.461,2.376c0.019,1.042,0.476,1.73,0.995,2.562c0.767,1.229,1.028,2.581-1.055,3.031
				c-0.998,0.215-2.001,0.54-2.911,0.998c-1.554,0.782-3.973,1.081-2.562,3.892c0.134,0.268-0.062,0.801-0.257,1.117
				c-0.88,1.438-2.915,2.926,0.795,3.572c0.768,0.134,1.197,2.206,1.783,3.386C509.449,535.065,509.292,535.209,509.134,535.352z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(127)} fill={props.fillColors[127]} d="M313.147,492.77c0.126,2.556-1.569,4.334-2.741,6.321
				c-1.042,1.767-2.314,3.405-3.261,5.217c-0.699,1.339-0.283,2.256,1.505,2.132c1.13-0.079,2.116-0.535,3.164-0.844
				c1.344-0.396,2.908-1.235,4.069-0.276c1.137,0.939-0.709,2.066-0.498,3.237c0.132,0.311-0.009,0.419-0.305,0.413
				c-2.094,2.06-3.808,4.397-5.148,7.01c-1.227,2.391-0.768,3.193,1.972,2.945c1.951-0.177,3.871-0.724,5.797-1.147
				c1.008-0.222,2-0.188,2.55,0.726c0.479,0.797-0.157,1.576-0.688,2.126c-4.255,4.406-9.088,7.729-15.419,8.394
				c-3.695,0.388-7.365,0.986-11.099,0.939c-0.971-0.013-2.113,0.301-2.334,1.27c-0.198,0.866,0.319,1.807,1.357,2.255
				c0.659,0.284,1.141,0.835,1.324,1.706c-0.663,0.179-1.309,0.353-1.954,0.526l0.081,0.041c-2.601-1.096-5.347-1.778-7.9-3.04
				c-3.501-1.729-6.732-3.845-9.847-6.168c-0.451-0.337-0.95-0.736-0.627-1.373c0.378-0.749,1.033-0.559,1.658-0.318
				c1.637,0.63,3.165,1.489,4.752,2.223c2.333,1.078,2.834,0.833,3.461-1.633c0.141-0.348,0.381-0.604,0.726-0.755
				c-0.631-4.24-0.492-4.373,3.42-3.236c0.377,0.109,0.748,0.241,1.121,0.362c0.551,0.18,1.203,0.894,1.636,0.058
				c0.342-0.66-0.007-1.332-0.6-1.899c-1.961-1.875-3.866-3.809-5.791-5.722c-0.339-0.338-0.711-0.653-0.998-1.032
				c-2.163-2.857-1.568-4.586,1.945-4.855c4.487-0.343,6.102-3.322,2.914-7.107c-0.357-0.423-0.785-0.81-0.659-1.698
				c6.224,0.376,10.818-3.878,16.574-6.537c-1.107,3.746-2.961,6.272-4.868,8.781c-0.764,1.006-1.446,2.084-2.067,3.185
				c-0.28,0.495-0.943,1.118-0.316,1.69c0.51,0.466,1.169,0.155,1.765-0.163c3.485-1.857,6.358-4.448,8.948-7.385
				c1.518-1.72,2.835-3.576,4.013-5.549c0.489-0.82,1.247-1.535,2.378-0.823c0.082,0.112,0.163,0.225,0.244,0.337
				C313.316,492.99,313.231,492.88,313.147,492.77z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(128)} fill={props.fillColors[128]} d="M150.013,390.693c5.063,1.508,10.047,2.909,14.977,4.478
				c9.924,3.159,19.646,6.891,29.367,10.615c3.24,1.241,6.355,2.806,9.566,4.13c1.343,0.555,0.879,0.865,0.081,1.52
				c-2.922,2.394-2.999,2.483-1.032,5.562c0.526,0.823,0.181,0.833-0.252,1.159c-0.651,0.491-1.972,0.236-1.945,1.449
				c0.042,1.871-1.106,3.656-0.207,5.616c0.389,0.849,1.908,1.887,0.464,2.845c-1.422,0.944-3.092,2.068-4.921,1.118
				c-5.96-3.098-11.853-6.324-17.799-9.45c-3.305-1.737-6.489-3.64-9.555-5.785c-3.569-2.499-6.335-5.586-8.721-9.25
				c-2.704-4.155-5.981-7.938-9.04-11.86C150.547,392.261,150.117,391.717,150.013,390.693z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(129)} fill={props.fillColors[129]} d="M357.292,385.093c4.817-1.116,7.877-3.478,7.365-8.871
				c1.202,0.417,1.7,1.25,2.346,1.867c1.059,1.009,2.08,1.799,3.718,1.19c0.792-0.295,1.737-0.171,2.385,0.646
				c0.406,0.511,0.781,0.654,1.591,0.456c1.887-0.46,3.479,0.42,4.252,2.167c2.25,5.08,6.022,7.714,11.614,7.872
				c1.764,0.05,3.545,0.718,4.743,2.015c2.398,2.596,5.5,3.062,8.67,3.036c4.307-0.035,8.482,0.558,13.336,1.778
				c-5.822,2.87-9.909,7.503-16.27,8.291c-6.043,0.748-11.842,2.824-17.561,4.798c-6.571,2.269-12.513,1.744-18.396-1.707
				c-1.295-0.76-2.712-1.384-4.151-1.798c-1.107-0.317-1.309-0.902-1.357-1.885c-0.146-2.89-0.109-2.905,2.75-2.32
				c3.944,0.809,7.91,1.52,11.859,2.308c0.902,0.181,1.725,0.232,2.368-0.538c0.79-0.946-0.304-1.375-0.578-2.036
				c-0.92-2.218-2.961-3.827-2.626-6.774c0.221-1.94-0.986-3.968-3.834-3.305c-0.681,0.158-1.437,0.062-2.151,0.005
				c-1.654-0.132-2.92,0.164-2.709,2.247c0.089,0.871-0.373,1.016-1.132,1.089c-2.33,0.224-4.651,0.544-7.205,0.854
				c1.831-2.768,4.709-3.57,7.354-4.667c2.104-0.873,3.992-1.979,5.479-3.728c0.32-0.376,0.658-0.735,0.479-1.259
				c-0.211-0.615-0.784-0.395-1.225-0.445c-2.846-0.33-5.695-0.611-8.566-0.48C359.023,385.935,358.245,385.87,357.292,385.093z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(130)} fill={props.fillColors[130]} d="M149.878,453.062c-0.682-0.618-0.891-0.868-1.152-1.034
				c-0.935-0.593-1.696-2.569-2.759-1.521c-0.856,0.843-1.743,2.585-0.818,4.273c0.272,0.496,1.094,0.79,0.693,1.709
				c-1.252-0.026-1.117-1.041-1.281-1.816c-1.725-8.155-4.366-16.026-7.978-23.516c-3.572-7.407-7.996-14.313-13.442-20.531
				c-5.628-6.424-11.79-12.262-18.393-17.689c-2.082-1.71-2.321-4.813-3.348-7.299c-1.257-3.042-2.412-6.125-3.595-9.197
				c-0.207-0.537-0.96-1.053-0.369-1.664c0.6-0.62,1.242,0.037,1.722,0.311c3.578,2.042,6.939,4.432,10.056,7.102
				c4.584,3.927,9.181,7.86,13.274,12.319c8.275,9.012,15.836,18.53,21.554,29.441c2.012,3.84,3.785,7.768,5.61,11.686
				c0.761,1.634,0.756,3.023,0.358,4.759c-0.759,3.308-1.066,6.709-0.157,10.1C150.028,451.145,149.878,451.882,149.878,453.062z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(131)} fill={props.fillColors[131]} d="M69.697,235.834c1.417-0.045,2.803-0.944,3.51,1.17
				c0.355,1.063,1.71,0.561,2.617,0.659c2.345,0.254,4.68,2.97,4.333,5.407c-0.103,0.724-0.074,1.414,0.33,1.736
				c3.986,3.193,5.146,8.027,7.1,12.339c4.624,10.206,8.078,20.839,10.712,31.728c1.89,7.809,3.049,15.745,4.13,23.704
				c1.228,9.041,1.94,18.176,4.59,26.984c1.766,5.868,4.217,11.431,7.107,16.817c0.283,0.528,0.824,0.999,0.536,1.911
				c-3.068-0.972-4.96-3.339-6.605-5.754c-6.139-9.01-9.66-19.127-11.371-29.785c-2.779-17.31-5.599-34.583-11.61-51.15
				c-4.347-11.981-9.184-23.746-15.182-35.009C69.824,236.46,69.814,236.295,69.697,235.834z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(132)} fill={props.fillColors[132]} d="M564.556,281.821c-0.646,5.91-3.292,10.734-7.248,15.189
				c-8.145,9.168-17.326,17.09-27.595,23.687c-10.826,6.956-21.904,13.516-32.882,20.235c-0.302,0.186-0.7,0.214-1.329,0.395
				c1.488-5.435,4.32-9.785,7.502-13.979c4.889-6.446,11.576-10.612,18.282-14.809c7.088-4.435,13.665-9.555,19.268-15.789
				c4.054-4.51,7.598-9.399,10.72-14.646c4.076-6.851,7.341-14.076,10.75-21.246c1.067-2.245,3.933-3.293,5.867-1.667
				c2.512,2.114,4.763,3.046,7.604,0.874c0.159-0.122,0.563,0.076,1.141,0.172c-1.903,1.894-4.039,3.189-5.353,5.327
				c-0.604,0.983-1.204-0.066-1.773-0.35c-1.707-0.852-3.345-1.776-5.315-0.469c-1.679,1.113-2.354,2.637-1.879,4.445
				c0.488,1.854,1.406,3.719,3.676,3.829c1.709,0.083,1.583,0.683,1.052,1.895c-0.775,1.766-0.973,3.749-1.963,5.445
				c-0.889-0.25-0.77-1.131-0.965-1.733c-0.338-1.042-0.962-1.599-1.99-1.808c-1.342-0.273-2.829-0.69-3.756,0.621
				c-0.811,1.148-0.329,2.555,0.365,3.612c0.82,1.248,2.235,1.403,3.648,1.217C563.117,282.173,563.662,281.158,564.556,281.821z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(133)} fill={props.fillColors[133]} d="M159.503,518.167c0.06-0.064,0.119-0.129,0.177-0.194
				c3.04-2.403,6.042-4.847,8.54-7.846c0.549-0.421,1.148-0.4,1.767-0.193c1.311-0.337,2.896-1.993,3.843-0.721
				c1.01,1.354-0.619,2.715-1.692,3.781c-0.471,0.468-0.912,0.913-0.667,1.653l0.056-0.08c-0.832,0.364-1.14,1.215-1.637,1.86
				c-1.596,2.072-1.556,2.093,0.952,3.021c1.432,0.531,1.099,1.461,0.286,2.172c-1.672,1.465-1.362,2.488,0.513,3.34
				c0.217,0.1,0.581,0.192,0.604,0.348c0.261,1.748,1.046,2.478,2.99,2.707c1.017,0.121,0.695,1.875-0.257,2.753
				c-1.053,0.971-2.274,1.745-3.319,2.719c-0.847,0.788-1.023,1.635,0.331,2.161c2.837,1.104,2.874,1.183,1.83,4.03
				c-0.137,0.374-0.203,0.771-0.364,1.14c-0.628,0.754-1.098,1.633-1.837,2.296c-3.944,1.852-8.051,3.234-12.367,3.84
				c-2.2,0.31-2.423-0.112-2.173-2.758c-0.125-0.179-0.185-0.374-0.244-0.567c-0.164-0.512-0.516-0.809-1.036-0.874
				c-5.204-0.664-9.918-3.196-15.184-3.798c-3.22-0.368-3.274-0.911-3.003-4.065c0.076-0.877-0.191-1.596-0.693-2.219
				c-0.542-0.671-0.797-1.365-0.652-2.208c-0.045-0.207-0.085-0.413-0.158-0.612c-0.382-1.135-1.229-1.813-2.22-2.386
				c-1.319-0.761-2.335-1.816-3.099-3.151c-0.608-1.061-0.213-1.409,0.833-1.556c1.463-0.205,2.812,0.416,4.23,0.504
				c0.601,0.037,1.277,0.524,1.771-0.133c0.565-0.753-0.062-1.321-0.436-1.911c-0.127-0.201-0.207-0.429-0.283-0.654
				c-0.627-0.618-1.255-1.236-1.882-1.854c-0.252-0.892-0.981-1.423-1.561-2.068c-0.316-0.354-0.713-0.721-0.554-1.239
				c0.203-0.663,0.784-0.303,1.212-0.344c2.896-0.28,4.831,1.658,6.932,3.113c0.874,0.605,1.519,1.558,2.365,2.261
				c0.682,0.567,0.717,1.205-0.099,1.737c-1.028,1.258,0.167,1.464,0.9,1.716c1.962,0.676,4.024,0.949,6.067,1.257
				c1.15,0.173,2.123,0.66,2.23,1.808c0.231,2.459,1.91,2.832,3.835,3.008c2.053,0.188,2.428,0.906,1.423,2.711
				c-1.686,3.028-6.375,4.669-9.698,3.384c-0.348-0.135-0.778-0.217-0.602-0.805c0.475-1.589,0.462-1.593-1.254-1.688
				c1.831,0.109,1.87,0.124,1.323,1.564c-0.201,0.529-0.018,0.683,0.384,0.881c3.292,1.627,8.452-0.267,9.991-3.674
				c0.767-1.698,0.461-2.189-1.454-2.376c-1.875-0.183-3.687-0.362-3.909-2.899c-0.1-1.136-0.993-1.732-2.158-1.9
				c-1.889-0.272-3.791-0.535-5.613-1.098c-0.77-0.238-2.099-0.149-1.749-1.668c1.121-0.653,1.178-1.367,0.165-2.198
				c-0.52-0.427-1.491-0.798-1.045-1.609c0.462-0.841,1.395-0.386,2.132-0.177c1.627,0.459,3.33,0.006,4.919,0.356
				c0.696,0.154,3.178-0.549,1.683,1.976c-0.595,1.006,0.51,1.137,1.209,0.929C154.738,520.881,157.24,519.77,159.503,518.167z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(134)} fill={props.fillColors[134]} d="M454.245,758.896c-4.283-0.063-8.007,1.517-11.408,3.962
				c-1.505,1.083-3.671,0.879-4.733-0.573c-0.589-0.804,0.236-0.839,0.6-1.17c3.552-3.229,7.683-5.353,12.396-6.166
				c1.011-0.175,1.029-0.8,1.431-1.304c0.773,0.558,1.631,0.498,2.51,0.365c5.957-0.896,11.914-0.129,17.871,0.013
				c0.224,0.006,0.433,0.198,0.136,0.089c-1.984-0.052-4.492-0.426-7.002-0.436c-3.822-0.015-7.666-0.364-11.471,0.397
				c-0.693,0.139-1.431,0.042-2.053-0.405c-1.158,0.714-2.518,0.691-3.778,1.029c-3.918,1.05-7.312,3.107-10.36,5.686
				c-1.082,0.915-1.89,1.064-3.159,0.586c-2.881-1.086-5.822-2.01-8.885-3.049c1.49-2.23,3.73-3.406,5.903-4.378
				c6.344-2.837,12.949-4.79,19.893-5.748c16.419-2.264,31.222,1.873,44.932,10.752c0.267,0.173,0.56,0.304,0.841,0.454l-0.075-0.097
				c2.349,0.984,3.8,2.995,5.415,4.799c0.732,0.819,1.22,1.847,1.949,2.682c1.669,1.91,1.58,2.24-0.782,2.892
				c-0.917,0.252-1.873,0.379-2.757,0.718c-1.418,0.544-2.271,0.089-2.703-1.312c-1.24-1.555-2.176-3.36-3.695-4.688
				c-1.018-0.889-1.678-2.142-2.693-2.705c2.399,2.091,4.549,4.688,6.469,7.489c0.71,1.269,0.525,2.088-1.077,2.295
				c-0.464,0.06-0.925,0.252-1.362,0.438c-2.441,1.036-4.007,1.196-6.309-1.392c-6.164-6.933-14.37-10.463-23.651-11.467
				c-4.189-0.453-8.338-0.766-12.475,0.298L454.245,758.896z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(135)} fill={props.fillColors[135]} d="M370.557,362.903c3.868,2.197,7.755,4.363,11.593,6.612
				c1.005,0.589,1.58,0.833,2.841,0.078c2.487-1.49,5.365-2.387,8.356-1.846c4.384,0.792,8.454-0.082,12.215-2.241
				c1.181-0.678,2.367-1.086,3.886-1.735c-0.749,3.42-2.91,5.352-5.202,7.251c-2.636,2.183-5.208,4.458-6.868,7.54
				c-0.709,1.317-0.872,2.24,1.201,2.107c4.734-0.306,8.502-2.616,11.962-5.582c1.839-1.577,3.575-3.281,6.635-4.396
				c-1.551,2.87-2.519,5.316-4.056,7.329c-1.71,2.238-0.061,4.069,0.167,6.05c0.076,0.659,0.938,0.335,1.399,0.012
				c1.028-0.722,2.028-1.485,3.043-2.226c0.358-0.261,0.615-0.882,1.173-0.546c0.47,0.283,0.306,0.862,0.28,1.31
				c-0.049,0.854,0.146,1.702-0.363,2.551c-1.888,3.146-3.346,6.45-2.96,10.271c-4.287-0.553-8.325-1.277-12.454-1.266
				c-2.295,0.006-4.584-0.292-6.335-2.094c-2.504-2.578-5.835-2.95-9.134-3.352c-3.333-0.405-5.404-2.414-6.76-5.275
				c-1.164-2.458-2.614-4.375-5.703-4.177c-1.113,0.072-1.093-0.592-0.801-1.415c0.658-1.86,1.288-3.731,1.913-5.603
				c0.388-1.161,0.658-2.286-1.222-2.198c-0.653,0.03-1.289-0.36-1.234-0.881c0.241-2.274-0.952-3.616-2.684-4.74
				c-0.452-0.293-0.766-0.799-1.143-1.208C370.388,363.124,370.472,363.013,370.557,362.903z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(136)} fill={props.fillColors[136]} d="M506.337,535.859c1.943,0.446,3.139,1.572,4.334,2.688
				c0.377,0.351,0.785,0.645,1.269,0.431c0.551-0.244,0.509-0.897,0.307-1.248c-1.473-2.56-1.637-5.785-3.986-7.886
				c-0.371-0.332-0.416-0.617-0.174-1.016c0.205-0.336,0.608-0.742,0.54-1.024c-0.817-3.348,1.946-3.234,3.805-4.189
				c3.129-1.606,6.182-1.599,9.411-0.423c2.211,0.806,4.521,0.758,6.756-0.017c0.927-0.321,1.621-0.524,2.063,0.665
				c0.183,0.487,0.671,0.879,1.065,1.268c2.572,2.534,6.477,3.577,7.996,7.368c0.287,0.717,1.707,1.172,2.683,1.38
				c1.818,0.39,3.462,1.142,5.087,1.965c2.615,1.322,2.679,2.015,0.175,3.627c-1.446,0.932-3.025,1.685-4.604,2.382
				c-1.784,0.787-3.287,1.798-4.38,3.486c-2.229,3.443-5.941,4.84-9.414,6.541c-1.301,0.639-2.58,0.507-3.404-0.688
				c-2.299-3.336-5.714-4.766-9.448-5.312c-3.164-0.462-5.536-2.217-8.053-3.84c-0.066-0.043-0.193-0.068-0.202-0.117
				C507.823,539.83,507.447,537.772,506.337,535.859z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(137)} fill={props.fillColors[137]} d="M321.75,260.31c0.021,2.688,0.168,5.295-0.039,7.871
				c-0.508,6.295-1.098,12.587-1.852,18.856c-0.858,7.134-1.865,14.252-2.935,21.359c-0.858,5.7-2.064,11.34-3.447,16.939
				c-0.837,3.394-0.843,3.384-3.89,1.93c-1.71-0.816-3.421-1.653-5.196-2.305c-1.622-0.595-1.903-1.701-1.69-3.193
				c0.753-5.257,0.903-10.565,1.356-15.847c0.533-6.222,1.32-12.423,1.771-18.649c0.211-2.919,0.556-5.817,0.804-8.727
				c0.172-2.01,1.298-3.366,2.51-4.677c3.339-3.61,6.779-7.125,10.156-10.699C320.16,262.258,320.936,261.265,321.75,260.31z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(138)} fill={props.fillColors[138]} d="M126.336,470.504c-0.258,0.128-0.517,0.256-0.775,0.385
				c-0.563,0.428-1.163,0.784-1.823,1.053c-1.38,0.562-2.435,0.562-2.764-1.255c-0.171-0.949-0.671-1.753-1.608-2.187
				c-0.921-0.177-1.612,0.166-2.342,0.759c-1.508,1.226-3.055,2.444-5.049,2.827c-0.412,0.885-0.615,1.776-0.332,2.763
				c1.204,4.196-0.002,7.364-4.036,9.295c-0.061,0.064-0.122,0.128-0.182,0.192c-1.18,2.807-1.321,5.77-1.029,8.72
				c0.253,2.562-0.193,4.901-1.294,7.185c-0.705,1.463-1.724,2.268-3.419,1.935c-0.395-0.108-0.7-0.36-0.993-0.625
				c-1.176-1.523-1.036-3.318-0.804-5.026c0.329-2.425-0.124-4.735-1.62-6.486c-1.363-1.595-3.528-2.369-5.352-3.487
				c-1.295-0.795-2.738-1.69-1.803-3.661c1.139-1.01,1.979-2.134,1.438-3.782c-0.675-1.056-1.582-1.947-2.213-3.027
				c-0.646-1.103-0.691-1.886,0.985-2.087c1.63-0.196,3.254-0.567,2.226-3.02c-0.543-1.296,0.418-2.611,1.533-2.871
				c1.898-0.441,2.873-1.893,4.216-2.949c1.158-0.368,2.214-0.935,3.192-1.653c2.815-0.855,5.59-0.898,7.824,0.357
				c-3.635-0.888-3.813-1.13-2.467-3.88c0.281-0.216,0.564-0.433,0.916-0.525c3.328,0.098,3.916-0.405,4.428-3.816
				c0.048-0.323,0.135-0.618,0.374-0.853c0.1-0.046,0.2-0.092,0.3-0.139c1.714-0.215,2.876-1.01,3.454-2.758
				c0.688-2.078,2.715-1.487,4.208-1.699c1.774-0.252,3.675,0.187,5.116-1.442c0.472,1.17,0.771,2.057,0.501,3.293
				c-0.414,1.897-0.476,3.86,1.346,5.458c1.097,0.963,1.378,2.61,0.453,3.927c-1.045,1.487-0.478,2.417,0.792,3.099
				c1.826,0.98,1.342,1.608-0.032,2.621C128.438,468.072,127.447,469.368,126.336,470.504z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(139)} fill={props.fillColors[139]} d="M446.656,272.619c0.183-2.816,0.308-5.637,0.562-8.447
				c0.377-4.174,0.893-8.335,1.264-12.51c0.693-7.787,1.694-15.533,3.029-23.233c0.615-3.554,1.456-7.07,2.005-10.632
				c0.464-3.019,2.854-4.643,4.507-6.803c0.53-0.693,1.135-0.251,1.646,0.184c3.891,3.318,7.449,6.984,11.09,10.565
				c0.612,0.602,0.538,1.071,0.331,1.862c-2.925,11.186-6.745,22.023-12.259,32.221C455.552,261.888,451.466,267.386,446.656,272.619z
				"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(140)} fill={props.fillColors[140]} d="M237.581,448.79c-0.72,1.205-1.613,2.272-2.23,3.585
				c-0.832,1.768-0.173,2.546,1.312,3.056c1.14,0.392,2.229,0.849,3.228,1.518c1.227,0.821,1.627,2.072,1.163,3.341
				c-0.419,1.146-1.664,0.436-2.532,0.584c-0.296,0.051-0.623-0.088-0.937-0.136c-3.095-0.468-3.853,0.223-3.792,3.391
				c0.028,1.497-0.369,2.143-2.17,1.664c-2.413-0.641-4.542-1.762-6.579-3.076c-3.559-2.298-3.77-2.442-9.001-3.116
				c0.749,1.591,1.42,3.056,2.126,4.505c0.335,0.689,0.488,1.453,0.052,2.042c-0.525,0.708-1.231,0.261-1.938,0.043
				c-3.476-1.068-6.313-3.227-9.025-5.476c-1.875-1.555-3.734-2.857-6.186-3.138c-1.799-0.206-2.809-0.909-1.765-2.846
				c0.74-1.375,0.444-2.601-0.586-3.954c1.702,0.107,3.22,0.259,4.741,0.287c1.938,0.035,1.961-0.958,1.074-2.276
				c-1.219-1.812-3.072-3.208-3.665-5.494c1.403-0.744,2.609,0.354,3.932,0.277c2.254-0.132,3.881-0.844,4.215-3.292
				c0.271-1.995,1.367-2.402,3.315-3.034c2.909-0.944,4.083-1.34,4.951,2.245c0.526,2.173,2.707,3.994,4.883,5.479
				c1.033-2.707-0.669-5.349,0.131-8.395c2.979,4.583,7.685,5.834,11.857,7.919c0.803,0.401,1.46,0.677,1.578,1.799
				c0.116,1.103,0.856,2.03,1.925,2.569L237.581,448.79z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(141)} fill={props.fillColors[141]} d="M402.703,663.056c3.022,1.867,6.009,3.797,9.082,5.577
				c1.581,0.916,2.438,1.706,0.532,3.127c-0.218,0.163-0.272,0.545-0.402,0.825c-0.104,0.048-0.208,0.097-0.312,0.146
				c-0.62,0.06-1.046-0.351-1.49-0.663c-5.492-3.854-11.602-4.479-18.02-3.439c-2.197,0.354-4.426,0.479-6.604,0.997
				c-1.505,0.359-2.437,1.106-2.42,2.754c0.003,0.276-0.064,0.528-0.183,0.773c-1.771,2.185-2.139,2.274-4.435,1.085
				c-1.018-0.831-1.282-2.171-2.114-3.128c-0.609-4.064-3.161-6.754-6.386-8.949c-0.45-0.307-1.021-0.438-1.322-0.954
				c-0.104-0.213-0.146-0.439-0.129-0.677c-1.523-2.874-4.586-3.503-7.167-4.699c-4.55-2.107-9.455-2.673-14.451-3.027
				c-8.703-0.616-16.551,1.283-23.57,6.564c-1.249,0.94-2.264,1.985-3.03,3.288c-0.797,1.352-1.729,0.871-2.579,0.213
				c-1.251-0.968,0.092-1.24,0.562-1.689c3.668-3.502,7.75-6.478,12.07-9.101c3.302-2.005,7.054-2.781,10.845-3.037
				c8.896-0.601,17.215,1.652,25.163,5.497c0.639,0.31,1.217,0.787,1.988,0.747l-0.095-0.085c2.422,2.249,4.807,4.538,7.284,6.725
				c0.938,0.827,1.311,2.169,2.589,2.76c-0.819-2.334-0.825-2.354,1.684-3.158c1.77-0.567,3.56-1.072,5.307-1.757
				c-2.177-0.447-4.133,0.503-6.043,1.214c-1.806,0.673-3.085,0.588-4.354-0.956c-0.897-1.092-2.02-2.032-3.157-2.885
				c-1.165-0.875-1.015-1.318,0.173-2.017c2.448-1.438,5.191-1.881,7.881-2.253c4.072-0.564,8.196-0.896,12.307-1.005
				c3.994-0.106,7.586,1.57,10.962,3.509c4.235,2.432,9.003,4.121,12.112,8.225c0.475,0.628,1.129,1.171,1.422,1.87
				c0.298,0.708,2.08,1.102,0.708,2.212c-1.163,0.941-2.175,2.256-4.107,0.832c-2.666-1.963-5.602-3.565-8.463-5.252
				c-0.526-0.31-1.21-0.962-1.899-0.141c-2.134-2.694-5.305-2.987-7.64-3.588C397.387,660.025,400.45,660.577,402.703,663.056z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(142)} fill={props.fillColors[142]} d="M213.714,422.04c1.903,1.824,3.927,3.436,5.292,5.869
				c1.293,2.305,3.988,2.395,6.452,2.135c1.684-0.178,2.922,0.088,3.047,2.11c0.107,1.743,1.773,2.125,2.827,2.952
				c1.079,0.847,2.052,1.738,2.877,2.816c0.695,0.906,1.57,2.055,0.788,2.992c-0.889,1.064-2.333,1.626-3.888,1.137
				c-3.109-0.978-5.683-2.72-7.654-5.329c-0.501-0.664-0.839-1.886-1.809-1.528c-0.955,0.352-0.362,1.573-0.512,2.4
				c-0.317,1.745,0.837,3.394,0.254,5.655c-1.752-2.002-3.915-3.299-3.931-6.048c-0.012-2.128-1.283-2.247-2.71-1.688
				c-1.479,0.579-2.854,1.246-4.525,1.25c-0.991,0.003-1.51,0.845-1.708,1.968c-0.644,3.653-1.74,4.286-5.439,3.595
				c-0.972-0.182-2.26-0.646-2.897,0.339c-0.658,1.016,0.148,2.026,0.821,2.908c0.767,1.006,1.423,2.1,2.232,3.067
				s0.905,1.573-0.578,1.58c-2.327,0.012-4.53-0.563-6.573-1.669c-0.397-0.214-0.96-0.691-0.94-1.014
				c0.129-1.981-1.415-2.218-2.632-2.292c-2.099-0.127-4.085-0.993-6.321-0.479c-1.855,0.427-2.137-1.644-2.53-2.836
				c-0.407-1.235,0.632-1.613,1.843-1.704c2.916-0.22,5.735-1.188,8.733-0.952c1.219,0.096,1.513-0.74,0.561-1.688
				c-0.971-0.966-1.294-2.124-1.236-3.485c0.076-1.784,0.333-3.122,2.646-3.244c1.517-0.08,3.049-0.703,4.483-1.302
				c1.931-0.805,2.308-2.2,1.291-3.983c-1.386-2.431-0.834-4.873,1.5-6.559c1.133-0.817,1.35-1.708,0.286-2.747
				c-1.538-1.502-0.644-2.271,0.432-3.814c2.53-3.628,6.256-2.354,9.362-2.677c1.507-0.156,4.17,1.26,4.807,3.776
				c0.115,0.457,0.301,0.896,0.425,1.353c0.953,3.539,2.216,3.952-2.894,4.082c-1.79,0.046-2.727,1.351-4.048,2.2
				c0.551,0.653,1.225,0.823,1.93,0.929L213.714,422.04z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(143)} fill={props.fillColors[143]} d="M275.375,709.992c1.167-1.492,2.959-1.905,4.561-2.647
				c6.766-3.299,14.023-4.934,21.393-5.985c12.053-1.721,23.379,0.966,34.197,6.234c0.435,0.211,0.923,0.244,1.353,0.462
				c0.59,0.461,1.18,0.922,1.761,1.376c0.288,0.253,0.585,0.514,0.882,0.775c1.312,1.157,2.623,2.315,4.075,3.599
				c-2.549,1.657-4.992,2.268-7.25,3.277c-0.801,0.358-1.602,0.017-2.433-0.49c-6.334-3.864-12.938-7.085-20.328-8.472
				c-1.608-0.303-3.172,0.119-4.736-0.188c-1.766-0.575-3.413,0.407-5.137,0.39c1.737-0.034,3.402-0.898,5.182-0.447
				c1.809,1.279,4.004,0.79,5.995,1.288c6.912,1.729,13.264,4.635,19.408,8.681c-2.814,1.534-5.428,3.079-8.153,4.392
				c-1.298,0.625-4.304-2.413-4.079-3.864c0.156-1.016,0.116-2.063,0.134-3.099c0.031-1.796-0.8-2.441-2.355-1.449
				c-0.888,0.566-1.269,0.425-1.56-0.453c-0.372-1.122-1.383-1.439-2.277-1.955c-1.141-0.658-1.792-0.114-2.346,0.788
				c-0.118,0.192-0.068,0.484-0.178,0.686c-0.633,1.164-0.202,3.18-1.304,3.569c-1.564,0.552-3.48,0.38-5.202,0.162
				c-4.47-0.565-8.775-0.076-12.976,1.503c-0.714,0.268-1.309,0.705-2.21,0.189c-2.309-1.321-4.711-2.478-7.268-3.797
				c2.643-2.344,5.777-3.2,8.88-4.274c-1.401-0.381-2.818-0.017-5.441,1.249c-1.574,0.76-3.084,2.256-4.983,0.591
				c-0.166-0.146-0.692-0.099-0.903,0.059c-2.08,1.538-4.07,0.283-6.129-0.062C273.939,711.741,274.24,711.05,275.375,709.992z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(144)} fill={props.fillColors[144]} d="M194.863,469.442c1.339-0.028,2.505-0.518,3.711-0.754
				c1.179-0.23,2.555-0.866,2.639-2.136c0.271-4.109,4.344-2.502,6.146-4.266c0.311-0.304,0.804,0.352,1.193,0.61
				c3.563,2.367,7.307,4.329,11.446,5.543c2.439,0.716,3.251,2.478,2.616,4.948c-0.518,2.013,0.384,3.21,2.844,3.686
				c0.684,0.132,1.396,0.114,2.134,0.168c-0.068,1.427-0.982,2.204-1.784,3.058c-2.17,2.308-2.414,4.352-0.384,6.733
				c1.579,1.853,0.654,3.044-0.664,4.188c-1.347,1.169-2.989,1.881-4.802,1.797c-1.224-0.057-2.05,0.522-2.595,1.389
				c-1.934,3.068-4.035,6.014-2.737,10.112c0.683,2.154-0.079,4.42-1.851,6.067c-1.18,1.097-2.361,1.094-2.984-0.568
				c-1.157-3.089-2.193-6.097-1.488-9.627c0.699-3.5-0.477-7.065-1.014-10.578c-0.136-0.888-0.969-1.378-1.774-1.805
				c-3.775-2.006-5.067-4.906-4.134-9.083c0.206-0.919,0.422-1.858,0.436-2.791c0.026-1.754-0.809-3.029-2.515-3.592
				C197.553,471.965,196.217,470.798,194.863,469.442z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(145)} fill={props.fillColors[145]} d="M370.769,487.722c-0.09-3.852-0.851-6.968-0.591-10.155
				c0.039-0.473,0.094-1.004-0.073-1.422c-0.377-0.944-0.746-2.106-1.934-2.187c-1.369-0.091-1.349,1.34-1.464,2.104
				c-0.294,1.955-0.254,3.961-0.358,6.156c-0.974-2.53-0.951-4.997-0.731-7.387c0.365-3.95,0.338-7.857-0.319-11.762
				c-0.06-0.361-0.065-0.717,0.285-0.759c1.951-0.231,3.748-1.342,5.796-1.006c0.324,0.053,0.591,0.104,0.75,0.448
				c1.818,3.918,5.628,5.199,9.193,6.585c2.505,0.973,4.504,2.305,5.706,4.647c1.771,3.45,5.175,3.76,8.339,4.574
				c0.228,0.059,0.509-0.033,0.711,0.061c1.447,0.672,4.377,0.688,4.112,1.924c-0.417,1.95-0.183,4.648-2.781,5.884
				c-1.435,0.682-3.05,1.551-2.893,3.69c0.044,0.607-0.484,0.899-1.082,1.092c-2.64,0.846-4.71,2.562-6.483,4.603
				c-2.983,3.43-6.848,5.313-11.079,6.602c-1.871,0.57-3.594-0.835-3.809-2.793c-0.301-2.73,0.675-5.129,1.698-7.511
				c0.852-1.98,1.019-4.112,1.614-6.146c0.241-0.823-0.232-1.463-0.897-1.771c-0.651-0.303-1.031,0.276-1.499,0.733
				C372.059,484.83,371.9,486.145,370.769,487.722z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(146)} fill={props.fillColors[146]} d="M469.437,648.133c4.595,1.358,9.016,3.037,12.231,6.846
				c0.647,0.767,1.922,1.414,0.622,2.709c-1.088,1.084-1.854,2.021-3.74,0.911c-9.44-5.552-19.682-8.482-30.616-7.003
				c-7.57,1.025-14.961,3.245-21.401,7.745c-0.363,0.255-0.626,1.154-1.298,0.349c-0.512-0.612-0.42-1.185,0.177-1.658
				c4.087-3.247,8.08-6.66,13.055-8.524c5.218-1.955,10.578-3.299,16.2-3.334c0.286-0.002,0.64,0.035,0.782-0.592
				c-3.944-0.506-7.71,0.466-11.43,1.297c-6.701,1.497-12.441,4.939-17.621,9.378c-0.693,0.595-1.402,1.744-2.465,0.82
				c-1.127-0.98-1.394-2.16-0.264-3.519c3.992-4.8,8.385-9.118,13.993-12.016c1.608-0.831,2.898-2.409,5.146-1.839
				c0.619,0.157,1.715-0.784,2.31-1.468c1.229-1.409,2.874-1.632,4.494-1.765c1.374-0.114,1.703,1.283,2.108,2.221
				c1.381,3.197,1.876,3.339,4.312,0.937c0.963-0.948,1.831-2.453,2.944-2.673c1.409-0.277,1.527,1.915,2.648,2.675
				c0.845,0.573,1.506,1.425,2.221,2.179c0.523,0.553,1.133,1.066,1.874,0.723c0.648-0.301,0.94-1.011,0.944-1.768
				c0.004-0.957,0.001-1.915,0.001-3.021c2.793,0.195,4.921,1.781,6.968,3.174c4.463,3.036,8.679,6.427,12.44,10.343
				c0.685,0.712,1.223,1.558,0.606,2.289c-0.83,0.983-1.992,1.697-3.078,2.43c-0.067,0.046-0.742-0.73-1.077-1.169
				c-3.006-3.941-7.371-5.599-11.852-7.049c-0.468-0.151-0.938,0.089-1.307,0.439c-1.595-1.461-3.731-1.321-5.635-1.816
				c-0.823-0.215-1.67-0.477-2.338-0.561C463.888,646.777,466.945,646.455,469.437,648.133z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(147)} fill={props.fillColors[147]} d="M556.38,562.226c-5.15-1.076-5.615-5.248-6.204-9.32
				c-0.183-1.268-0.062-3.298-1.34-3.304c-1.829-0.008-1.09,2.197-1.395,3.396c-0.334,1.32-0.076,2.792-0.076,4.487
				c-4.961-2.408-8.969-6.391-14.723-5.225c-0.027-0.357-0.077-0.593-0.042-0.606c3.553-1.344,5.793-4.177,8.254-6.876
				c1.675-1.838,4.195-2.752,6.479-3.832c0.938-0.443,1.826-0.938,2.487-1.765c3.49-4.36,8.533-4.815,13.531-5.33
				c0.949-0.098,1.595-0.55,2.256-1.129c2.213-1.935,4.435-3.86,6.682-5.754c0.386-0.324,0.917-0.476,1.36-0.696
				c0.505,0.848-0.173,1.028-0.51,1.179c-1.562,0.698-1.335,1.847-0.694,2.946c0.748,1.284,1.161,2.192-0.945,2.265
				c-1.03,0.035-0.726,0.757-0.528,1.406c0.626,2.064,0.095,3.34-2.271,3.695c-1.79,0.269-1.44,1.681-0.906,2.763
				c0.357,0.726,0.99,1.11,0.102,1.982c-0.782,0.767-0.79,2.078,0.007,2.77c0.907,0.786,1.651-0.256,2.348-0.837
				c2.613-2.184,3.842-5.385,5.866-8.004c0.606-0.784,1.149-1.511,2.239-1.812c0.517,1.301-0.855,2.139-0.742,3.319
				c0.193,2.032-0.232,4.063,0.762,6.087c0.91,1.853-0.271,3.319-1.987,4.459c-1.673,1.111-3.639,2.255-3.904,4.695
				c-0.046,0.422-0.385,0.886-0.716,1.19c-2.373,2.183-3.825,5.551-7.69,5.823c-1.615,0.113-1.623-1.165-1.793-2.006
				c-0.608-3.001-1.021-6.041-1.504-9.067c-0.038-0.236,0.021-0.505-0.071-0.71c-0.253-0.561-0.347-1.483-1.062-1.417
				c-0.707,0.065-1.032,0.786-1.141,1.597c-0.6,4.462-1.265,8.915-1.92,13.369C556.566,562.094,556.399,562.202,556.38,562.226z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(148)} fill={props.fillColors[148]} d="M306.315,531.889c2.158-0.04,3.3-1.706,4.505-3.055
				c3.38-3.782,7.843-4.899,12.915-5.809c-1.538,3.443-4.095,5.629-5.911,8.33c-1.185,1.764-2.726,3.27-3.424,5.391
				c-0.561,1.699,0.152,2.127,1.585,2.295c2.041,0.238,3.896-0.617,5.807-1.039c0.94-0.207,1.85-0.41,2.792-0.358
				c0.907,0.05,2.294-0.489,2.56,0.599c0.238,0.974-0.55,2.088-1.417,2.848c-2.323,2.033-4.91,3.692-7.532,5.302
				c-0.881,0.541-1.743,1.112-2.61,1.675c-0.894,0.579-1.691,1.214-1.569,2.457c0.132,1.354,1.425,1.05,2.168,1.521
				c0.289,0.183,0.723,0.136,1.724,0.292c-3.68,1.881-7.076,2.58-10.518,3.128c-0.233,0.037-0.514-0.085-0.713,0.002
				c-3.5,1.541-5.456-0.479-7.149-3.089c-0.347-0.535-0.637-1.315-1.386-1.028c-0.863,0.331-0.505,1.25-0.444,1.895
				c0.301,3.176,0.327,3.174-2.956,3.173c-1.438-0.001-2.875-0.014-4.313-0.003c-0.453,0.004-1.117-0.11-1.107,0.543
				c0.007,0.454,0.509,0.709,1.05,0.792c2.778,0.429,5.509,1.048,8.604,2.301c-3.434,1.571-6.557,1.101-9.65,0.91
				c-0.075-0.004-0.146-0.065-0.221-0.089c-2.141-0.671-5.187,0.129-6.254-2.127c-0.96-2.028-1.953-4.106-2.833-6.204
				c-0.562-1.339,0.458-2.035,0.952-2.962l-0.102,0.093c1.298-0.564,2.662-0.203,3.992-0.256c3.367-0.133,4.331-0.994,4.71-4.36
				c0.229-2.026,1.338-3.164,3.557-3.555c3.187-0.561,6.431-0.813,9.512-1.92c0.672-0.242,1.367-0.437,2.01-0.741
				c2.114-1.003,2.204-1.779,0.226-3.002c-1.27-0.785-1.317-1.423-0.182-2.322c0.624-0.493,1.143-1.112,1.71-1.673L306.315,531.889z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(149)} fill={props.fillColors[149]} d="M283.742,524.695c0.006,0.098,0.015,0.196,0.024,0.294
				c0.317,0.296,0.312,0.711,0.415,1.091c0.59,2.167-0.176,2.969-2.421,2.299c-2.062-0.615-3.88-1.959-5.893-2.585
				c-4.569-1.423-7.487-5.261-11.547-7.37c-1.321-1.222-2.644-2.443-3.965-3.664c-1.292-1.773-2.618-3.524-3.86-5.332
				c-0.549-0.798-0.891-1.84-0.252-2.652c0.588-0.748,1.669-0.44,2.482-0.313c1.459,0.227,2.916,0.592,4.308,1.085
				c0.685,0.242,1.22,0.42,1.66-0.214c0.555-0.799-0.296-1.085-0.676-1.497c-3.43-3.723-7.13-7.195-10.098-11.345
				c-0.635-0.888-0.779-2.021-1.628-2.745l0.049,0.054c0-0.399-0.016-0.8,0.003-1.198c0.042-0.85-0.237-1.9,0.863-2.262
				c0.967-0.318,1.828-0.018,2.594,0.761c2.802,2.852,5.69,5.617,8.474,8.486c0.852,0.878,1.86,1.492,2.831,2.178
				c0.433,0.306,0.95,0.753,1.519,0.339c0.617-0.448,0.504-1.127,0.299-1.75c-0.394-1.2-0.756-2.419-1.261-3.573
				c-0.263-0.6-0.724-1.205-0.105-1.676c0.514-0.393,1.188-0.125,1.804,0.171c2.792,1.339,5.62,2.603,8.41,3.943
				c1.792,0.861,3.625,1.533,5.6,1.861c1.942,0.323,4.686,3.427,4.897,5.423c0.098,0.923-0.117,1.576-1.062,2.153
				c-1.293,0.789-2.73,0.825-4.084,1.223c-2.728,0.801-3.481,2.324-1.961,4.637c0.733,1.116,1.648,2.132,2.585,3.09
				c1.607,1.642,3.296,3.203,5.018,4.863c-1.205,0.861-1.956,0.07-2.71-0.133c-1.12-0.303-2.228-0.691-3.089,0.309
				c-0.944,1.096-0.378,2.201,0.307,3.228C283.444,524.136,283.585,524.42,283.742,524.695z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(150)} fill={props.fillColors[150]} d="M567.995,501.591c-0.021,0.379,0.112,0.911-0.087,1.114
				c-2.189,2.232-1.844,5.919-3.544,7.806c-2.054,2.278-1.799,5.831-4.732,7.309c-0.132,0.066-0.227,0.224-0.314,0.356
				c-0.784,1.19-1.375,2.672-0.504,3.78c0.79,1.005,2.174,0.028,3.015-0.472c2.943-1.752,5.368-4.163,7.649-6.701
				c0.376-0.419,0.646-1.053,1.276-0.866c0.726,0.215,0.379,0.95,0.406,1.454c0.164,3.021-0.999,5.208-3.198,7.486
				c-2.206,2.284-4.938,4.455-5.878,7.814c-0.378,1.351-1.312,2.021-2.7,2.284c-2.581,0.49-5.081,1.337-7.426,2.491
				c-1.082,0.532-1.819,0.194-2.652-0.216c-2.275-1.119-4.549-2.229-7.055-2.764c-1.244-0.267-2.122-0.962-2.638-2.174
				c-0.805-1.89-2.27-3.052-4.064-4.086c-6.115-3.523-5.029-4.713-0.669-8.851c5.037-4.778,11.219-7.048,17.947-8.075
				c1.715-0.262,3.347-0.838,5.095-1.041c2.832-0.33,5.105-1.867,6.915-4.091c0.834-1.024,1.762-1.973,2.646-2.955
				C567.654,501.327,567.824,501.459,567.995,501.591z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(151)} fill={props.fillColors[151]} d="M508.91,253.046c1.919-1.269,3.85,0.1,5.785,0.032
				c1.293-0.045,0.644,1.269,0.445,1.821c-1.89,5.27-3.198,10.688-4.3,16.165c-2.079,10.335-4.1,20.682-6.281,30.996
				c-2.36,11.16-4.752,22.31-9.879,32.659c-3.172,6.4-7.564,11.705-13.177,16.049c-0.358,0.278-0.84,0.398-1.744,0.81
				c5.068-10.54,9.603-20.84,12.484-31.77c-1.759-1.394-0.116-3-0.282-4.494c0.335-2.738,2.228-4.843,2.854-7.544
				c2.38-10.243,4.636-20.518,7.221-30.712c1.411-5.56,2.893-11.103,4.413-16.635c0.339-1.231,0.775-2.45,0.66-3.745
				c-0.126-1.428,0.762-2.373,1.479-3.413C508.695,253.19,508.803,253.118,508.91,253.046z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(152)} fill={props.fillColors[152]} d="M105.932,396.352c5.02,3.989,9.771,7.937,13.959,12.626
				c6.401,7.169,11.225,15.284,15.278,23.899c3.194,6.788,5.354,13.949,7.077,21.242c0.224,0.947,0.005,1.451-1.116,1.576
				c-1.91,0.214-1.862,1.252-0.718,2.403c0.774,0.779,1.627,1.534,2.573,2.075c1.294,0.74,0.944,2.064,0.776,2.874
				c-0.162,0.78-1.366,0.594-2.278,0.275c-1.827-0.639-3.641-1.475-5.683-1.09c-0.872,0.164-0.762-0.505-0.851-1.058
				c-1.401-8.702-4.255-16.934-8.314-24.729c-3.266-6.272-7.306-12.051-12.188-17.175c-2.793-2.932-4.867-6.064-5.615-10.168
				c-0.609-3.338-1.782-6.583-2.857-9.818C105.659,398.334,105.501,397.533,105.932,396.352z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(153)} fill={props.fillColors[153]} d="M237.657,448.861c0.04-0.069,0.099-0.135,0.117-0.209
				c0.279-1.202-1.969-1.971-0.821-3.004c0.635-0.571,2.185,0.005,3.248-0.248c2.511-0.597,5.717,0.211,6.904-3.301
				c0.198-0.586,0.817-0.907,1.771-0.633c3.008,0.865,6.138,0.682,9.235,0.646c2.581-0.03,2.952-0.5,2.664-2.948
				c-0.128-1.086-0.306-2.551,0.965-2.866c1.184-0.294,1.996,0.578,2.522,1.928c1.027,2.632,3.668,3.883,5.641,5.699
				c1.335,1.229,2.813,2.302,4.205,3.472c0.285,0.24,0.638,0.538,0.487,1.003c-0.162,0.502-0.627,0.432-1,0.414
				c-2.958-0.134-5.634,1.395-8.56,1.437c-0.4,0.005-0.786,0.067-0.881,0.587c-0.155,0.839,0.274,1.393,0.907,1.801
				c1.567,1.011,3.157,1.986,5.159,3.24c-3.004,0.6-5.553,0.02-8.01,0.736c-2.646,0.772-3.123,2.073-1.226,4.172
				c2.402,2.659,5.445,4.54,8.998,6.978c-3.293,0.103-5.729-0.821-8.237-1.013c-1.806-0.137-3.595-0.48-5.399-0.659
				c-0.529-0.053-1.451-0.017-1.566,0.25c-1.133,2.622-1.984,0.6-2.954-0.1c-2.507-1.807-5.232-3.248-8.146-4.236
				c-1.184-0.401-1.735-0.85-1.663-2.111c0.147-2.538-1.266-3.909-3.542-4.692c-0.913-0.313-2.451-0.469-2.271-1.653
				c0.15-0.988,0.063-2.921,2.211-2.565c0.297,0.049,0.801-0.361,0.718-0.814c-0.148-0.813-0.463-1.558-1.548-1.378
				C237.581,448.79,237.657,448.861,237.657,448.861z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(154)} fill={props.fillColors[154]} d="M252.29,491.746c-0.408,0.684-0.538,1.69-1.805,0.868
				c-3.593-2.331-7.27-4.495-11.482-5.606c-1.307-0.345-1.755-1.779-2.494-2.782c-1.553-2.106-2.855-4.39-4.651-6.329
				c-1.099-1.187-2.315-1.833-3.971-1.665c-1.101,0.111-2.172-0.049-3.267-0.407c-1.301-0.426-1.531-1.07-1.214-2.303
				c0.742-2.879,0.257-4.011-2.393-5.394c-1.159-0.604-1.854-1.203-1.678-2.676c0.082-0.696-0.487-1.47-0.76-2.208
				c-0.27-0.732-0.539-1.464-0.83-2.255c2.948-0.276,5.199,1.423,7.477,2.861c1.962,1.239,4.011,2.159,6.236,2.75
				c2.246,0.597,3.283-0.029,3.178-2.361c-0.089-1.99,0.47-2.599,2.507-2.54c7.46,0.214,13.09,3.959,18.515,9.024
				c-1.752,0.266-3.555-0.95-4.407,0.559c-0.635,1.123-0.317,2.668,1.245,3.375l-0.068-0.065c0.458,2.502,2.696,3.788,3.981,5.73
				c1.196,1.809,1.517,2.956-0.684,4.194c-1.569,0.884-1.921,3.007-3.664,4.031c-0.894,0.526-1.156,2.253,0.273,3.246
				C252.338,491.8,252.29,491.746,252.29,491.746z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(155)} fill={props.fillColors[155]} d="M358.734,710.42c0.401-0.025,0.804-0.053,1.195-0.081
				c1.235-0.13,2.481-0.258,4.362-0.451c-2.231-0.94-1.546-1.565-0.452-2.131c3.053-1.581,6.343-2.176,9.687-1.641
				c1.967,0.314,4.113,0.105,6.243,1.479c-2.629,0.738-5.063,0.027-7.382,0.462c0,0.241-0.001,0.482-0.001,0.724
				c5.605,0,11.212,0,16.817,0l-0.04-0.036c1.382,0.38,2.781,0.549,4.212,0.362l-0.072-0.063c1.617,0.85,3.391,0.88,5.149,0.984
				c0.438-0.889,1.109-0.917,1.895-0.519c3.961,1.87,8.209,3.13,11.896,5.594c0.731,0.489,1.527,0.876,1.997,1.669
				c0.027,0.114,0.054,0.228,0.081,0.342c3.288,3.265,7.223,5.809,10.322,9.292c0.996,1.12,2.189,2.062,3.294,3.086
				c2.234,0.03,3.191,1.095,3.104,3.453c-0.02,1.292,1.088,1.906,1.746,2.755c0.895,1.149,0.779,2.011-0.1,3.111
				c-0.885,1.108-1.695,0.705-2.738,0.39c-1.93-0.583-3.133-1.765-4.282-3.439c-4.204-6.126-9.924-10.555-16.492-13.952
				c-1.749-0.905-3.377-2.045-5.082-3.037c-0.491-0.286-1.117-0.519-1.172-1.093c-0.203-2.129-1.113-2.364-2.837-1.329
				c-0.749,0.45-1.09-0.463-1.381-0.944c-0.992-1.64-2.12-2.422-4.074-1.352c-1.6,0.877-3.304-0.068-4.879-0.304
				c-7.132-1.064-14.324-1.215-21.431-0.664c-9.892,0.767-19.274,3.802-28.091,8.438c-0.068,0.118-0.135,0.236-0.203,0.353
				c0.066-0.117,0.134-0.235,0.2-0.354c-5.52,2.716-11.472,4.612-16.306,8.623c-1.135,0.942-2.435,0.495-3.667,0.391
				c-0.522-0.045-0.621-0.502-0.483-1.09c0.417-1.799,0.969-3.33,2.796-4.347c10.729-5.964,21.748-11.181,33.788-13.981
				C357.135,710.937,358.18,711.418,358.734,710.42z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(156)} fill={props.fillColors[156]} d="M215.627,660.783c2.582,2.348,5.166,4.693,7.747,7.041
				c1.419,1.291,2.609,2.764,3.571,4.428c0.407,0.703,1.441,1.376,0.181,2.129c-0.889,0.53-1.854,1.397-2.884,0.089
				c-0.434-0.552-1.018-0.985-1.58-1.517c-0.526,0.903,0.483,1.086,0.589,1.651c0.121,0.646,1.712,1.115,0.5,1.854
				c-1.135,0.69-2.275,2.55-3.93,0.956c-4.089-3.938-9.268-5.973-14.281-8.274c-5.966-2.738-12.314-4.275-18.846-4.092
				c-7.728,0.218-14.918,2.473-20.907,7.681c-0.579,0.504-0.965,1.226-1.46,1.831c-1.401,1.713-1.405,1.71-3.963,0.785
				c5.609-5.928,11.734-10.749,19.696-13.089c-0.83-0.832-1.497-0.315-2.21-0.07c-6.377,2.184-11.651,5.996-16.237,10.86
				c-0.944,1.001-2.146,2.758-3.478,1.432c-1.14-1.137,0.667-2.242,1.442-3.257c6.828-8.938,16.414-13.454,27.009-15.304
				c8.795-1.535,17.925-2.014,26.055,3.361c0.132,0.087,0.439-0.091,0.664-0.145c0.099,0.01,0.196,0.02,0.294,0.028
				c0.695,0.131,1.166,0.72,1.835,0.9C215.666,660.258,215.832,660.471,215.627,660.783z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(157)} fill={props.fillColors[157]} d="M403.305,327.225c-1.182,2.504-2.872,3.837-4.562,5.211
				c-2.168,1.764-3.513,4.061-3.83,6.879c-0.341,3.014-1.673,5.552-3.541,7.888c-0.278,0.348-1.304,0.732-0.532,1.482
				c0.564,0.549,1.137,0.146,1.729-0.145c3.133-1.541,6.285-3.04,9.433-4.55c1.621-0.777,3.246-1.547,5.131-2.444
				c-0.219,2.605-1.752,4.173-3.429,5.339c-1.909,1.327-2.748,3.138-3.477,5.144c-0.173,0.476-0.272,0.977,0.053,1.351
				c0.424,0.487,0.992,0.333,1.531,0.1c1.296-0.561,2.632-1.04,3.892-1.672c1.118-0.562,1.67-0.486,1.982,0.887
				c0.243,1.066,0.781,2.007-0.79,2.391c-1.849,0.452-4.113-0.041-5.353,1.85c-1.115,1.701-0.539,3.744-0.505,5.623
				c0.031,1.735-0.706,2.217-2.134,1.688c-1.502-0.557-3.001-0.217-4.497-0.297c-0.353-0.019-0.978,0.139-1.042,0.349
				c-1.264,4.11-5.325,1.496-7.615,3.136c-0.104,0.075-0.817-0.647-1.207-1.038c-2.2-2.206-4.325-4.493-6.6-6.619
				c-2.145-2.003-2.62-4.838-3.916-7.259c-0.457-0.854-0.066-1.291,0.936-1.175c0.552,0.064,1.16-0.141,1.67,0.013
				c3.182,0.959,4.386-1.86,6.278-3.29c0.768-0.581-0.07-1.334-0.393-1.876c-3.017-5.075-0.696-10.506,5.162-12.009
				c0.491-0.126,1.017-0.188,1.322,0.233c1.395,1.923,2.726,0.867,4.05-0.05C396.342,332.086,399.621,329.792,403.305,327.225z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(158)} fill={props.fillColors[158]} d="M493.066,316.156c-0.275,1.224-0.551,2.448-0.824,3.662
				c-1.288,1.086-1.597,2.685-2.075,4.173c-2.641,8.208-6.13,16.071-9.51,23.988c-1.677,3.926-4.418,6.377-8.688,8.117
				c1.434-2.869,2.791-5.369,3.871-7.996c5.009-12.184,9.33-24.547,11.976-37.514c3.03-14.856,5.72-29.818,10.571-44.239
				c1.406-4.177,3.175-8.238,4.906-12.297c1.075-2.524,3.318-2.854,5.617-1.005l0.036-0.083c-4.049,13.851-7.591,27.829-10.686,41.925
				C496.697,302.014,494.832,309.075,493.066,316.156z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(159)} fill={props.fillColors[159]} d="M250.925,524.801c0.903,1.661,1.829,3.312,2.366,5.141
				c2.68,2.013,4.649,4.717,6.704,7.279c2.131,2.658,1.509,3.865-1.765,4.869c-0.868,0.267-1.707,0.307-2.576,0.367
				c-0.587,0.041-1.387-0.067-1.115,0.984l-0.089-0.092c0.142,0.605,0.285,1.212,0.431,1.834c-4.27,0.314-4.27,0.314-4.303-3.844
				c-0.012-1.517-0.033-3.034-0.031-4.552c0-0.575,0.041-1.201-0.684-1.32c-0.676-0.111-0.921,0.402-1.131,0.974
				c-0.973,2.642-2.292,5.186-2.76,7.972c-0.244,1.455-0.87,1.443-1.937,1.15c-1.156-0.318-2.19-0.9-3.064-1.664
				c-3.565-3.114-7.875-4.319-12.418-4.674c-1.714-0.134-2.794-0.718-3.209-2.219c-1.119-4.049-3.676-6.087-7.879-6.238
				c-0.553-0.021-1.62-0.087-1.767-0.884c-0.164-0.892,0.836-1.047,1.427-1.358c0.766-0.405,1.594-0.698,2.413-0.992
				c1.601-0.573,2.485-1.65,2.488-3.386l-0.079,0.072c1.402-1.045,2.764-0.198,3.981,0.293c1.959,0.791,3.765,0.769,5.715,0.04
				c2.053-0.767,4.185-1.377,6.406-1.353c2.612,0.028,5.209-0.208,7.669-0.998c2.029-0.653,3.298-0.103,4.247,1.642
				C250.193,524.265,250.377,524.71,250.925,524.801z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(160)} fill={props.fillColors[160]} d="M172.953,303.724c-0.363,0.066-0.644,0.215-0.818,0.134
				c-3.979-1.857-8.278-3.178-11.536-6.307c-6.481-6.226-12.663-12.734-18.313-19.747c4.016,6.276,9.105,11.741,13.506,17.819
				c-4.968-1.88-10.146-3.412-14.004-7.139c-8.288-8.007-15.701-16.791-22.074-26.42c-0.215-0.325-0.333-0.715-0.595-1.293
				c3.301,1.292,6.412,2.541,9.546,3.725c2.427,0.917,4.935,1.187,6.964,3.492c1.087,1.236,4.113,1.433,6.336,1.207
				c2.529-0.256,6.118,1.323,7.497,3.494c5.485,8.637,11.5,16.873,18.292,24.548C169.561,299.278,171.526,301.247,172.953,303.724z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(161)} fill={props.fillColors[161]} d="M550.649,645.225c2.13-1.028,4.253-2.071,6.396-3.074
				c0.433-0.202,1.039-0.854,1.382,0.021c0.915,2.334,2.253,1.322,2.865,0.097c1.435-2.875,4.126-2.731,6.503-2.896
				c11.421-0.79,20.166,4.297,27.189,12.872c0.542,0.661,1.466,1.555,0.59,2.499c-0.727,0.784-1.471,1.895-2.86,1.418
				c-0.415-0.143-0.707-0.449-0.639,0.328c0.167,1.915-1.849,1.968-2.687,3.043c-0.431,0.553-0.985,0.238-1.381-0.281
				c-3.34-4.388-8.338-5.322-13.282-6.335c-5.25-1.075-10.218-0.395-14.804,2.512c-0.95,0.603-2.032,0.329-3.044,0.321
				c-3.114-0.021-6.061,0.595-8.833,2.036c-0.005,0.004,0.074-0.064,0.067-0.06c-1.196,0.352-2.399,0.662-3.571,1.064
				c-0.449,0.154-0.948,0.557-1.297,0.146c-0.497-0.585,0.107-1.019,0.495-1.38c3.011-2.81,6.303-5.243,9.909-7.229
				c1.966-1.082,3.993-2.054,5.921-3.228c-3.269,0.437-5.896,2.303-8.651,3.895c-2.609,1.509-5.003,3.354-7.135,5.423
				c-1.146,1.112-1.832,0.577-2.786,0.221c-0.062-0.097-0.123-0.194-0.186-0.291c0.486-3.412,3.181-5.393,5.239-7.7
				c1.18-1.322,1.894-3.499,4.255-3.46C550.42,645.197,550.535,645.211,550.649,645.225z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(162)} fill={props.fillColors[162]} d="M514.32,564.313c0.791-0.731,1.553-1.307,2.152-2.019
				c1.226-1.457,0.941-2.21-0.85-2.578c-1.019-0.209-1.711-0.775-2.481-1.557c-1.291-1.311-2.31-3.111-4.396-3.498
				c-0.224-0.042-0.437-0.444-0.551-0.722c-0.765-1.848-1.134-3.979-3.565-4.555c-0.173-0.041-0.2-0.695-0.383-1.403
				c2.535,1.317,3.748-0.361,4.975-1.777c0.577-0.665,0.969-1.283,1.915-0.707c2.565,1.562,5.57,1.665,8.35,2.519
				c1.964,0.603,3.826,1.554,4.808,3.322c1.248,2.25,3.425,2.727,5.252,2.314c6.448-1.451,11.411,1.702,16.52,4.632
				c0.521,0.298,0.814,0.719,0.812,1.35c-0.015,2.479,0.036,4.959-0.04,7.436c-0.029,0.967-0.312,1.891-1.646,1.874
				c-1.414-0.018-1.157-1.022-1.173-1.854c-0.018-0.897-0.052-2.001-1.017-2.15c-1.062-0.166-1.062,1.129-1.153,1.765
				c-0.314,2.181-1.51,3.325-3.491,4.195c-1.622,0.712-2.201,3.461-4.192,3.249c-1.729-0.184-2.938-1.996-4.304-3.195
				c-2.263-1.987-5.061-2.93-7.906-3.653C519.346,566.638,516.853,565.745,514.32,564.313z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(163)} fill={props.fillColors[163]} d="M535.155,661.149c0.604,0.773,2.015,1.518,1.402,2.456
				c-0.834,1.276-2.237,2.21-3.495,3.16c-0.402,0.305-0.775-0.401-1.168-0.638c-3.685-2.222-7.869-2.729-11.988-3.45
				c-0.362-0.062-0.796-0.086-1.188,0.54c3.276,0.546,6.55,0.75,9.563,2.042c0.215,0.092,0.491,0.053,0.69,0.162
				c1.031,0.563,2.874,0.929,2.748,1.95c-0.149,1.216-1.309,2.43-2.414,3.356c-0.441,0.371-0.753,0.235-1.253,0.017
				c-5.824-2.551-11.744-2.718-17.728-0.563c-0.298,0.107-0.627,0.184-0.874,0.366c-2.726,2.014-5.734,3.465-8.938,4.553
				c-0.006,0.004,0.062-0.053,0.055-0.048c-3.126-0.028-5.938,1.444-8.98,1.858c-1.502,0.203-2.641,0.321-3.312-1.294
				c-0.624-1.5,0.466-2.098,1.396-2.804c7.879-5.978,16.482-9.998,26.62-9.906c0.215,0.002,0.431-0.114,1.166-0.322
				c-3.909-0.69-7.316-0.349-10.501,0.646c-6.977,2.176-13.832,4.743-19.014,10.285c-0.746,0.797-1.153,0.629-1.885-0.021
				c-2.868-2.549-3.058-2.719-0.413-4.851c6.699-5.401,13.435-10.889,22.337-12.234c5.716-0.864,11.389-0.19,17.023,0.992
				c2.728,0.571,5.183,1.882,7.844,2.608C534.293,659.025,535.056,659.416,535.155,661.149z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(164)} fill={props.fillColors[164]} d="M319.207,659.388c-1.198-3.357-4.155-4.818-7.097-5.854
				c-5.234-1.842-11.018-3.601-16.164-1.358c-5.427,2.365-11.196,2.218-16.601,4.026c-1.885,0.63-3.597,1.889-6.372,1.84
				c5.626-5.726,11.599-9.845,18.753-12.269c-2.312-0.418-4.28,0.739-6.077,1.758c-4.183,2.369-8.424,4.726-11.749,8.334
				c-0.384,0.417-0.72,0.783-1.383,0.273c-0.899-0.69-0.578-1.464-0.144-2.067c3.263-4.544,6.929-8.652,11.924-11.427
				c6.375-3.54,13.234-4.925,20.383-4.199c5.627,0.571,10.467,3.422,14.96,6.757c2.43,1.804,4.578,3.903,6.392,6.352
				c0.869,1.173,0.718,1.853-0.527,2.642c-0.989,0.626-1.4,1.683-2.75,0.097c-2.529-2.974-5.326-5.779-8.984-7.493
				c2.716,2.124,5.013,4.68,7.514,7.015c1.593,1.486,1.807,2.443-0.081,3.812c-0.68,0.492-1.831,0.638-2.002,1.762
				C319.206,659.383,319.205,659.388,319.207,659.388z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(165)} fill={props.fillColors[165]} d="M554.702,507.481c-2.457,0.552-4.391,1.149-6.369,1.397
				c-5.087,0.641-9.048,3.356-12.827,6.529c-1.628,1.367-3.262,2.646-4.528,4.432c-1.874,2.642-4.742,2.867-7.667,2.356
				c-1.776-0.31-3.513-0.848-5.268-1.276c-1.724-0.422-3.745-2.992-3.874-4.766c-0.082-1.125,0.106-1.591,1.25-0.91
				c0.201,0.119,0.428,0.213,0.653,0.269c1.013,0.246,2.431,1.475,2.882,0.747c0.74-1.193,1.253-2.854,0.508-4.428
				c-0.343-0.723-0.544-1.451-0.522-2.275c0.027-1.074,1.413-3.103-1.589-2.278c-0.69,0.189-0.803-1.189-1.74-2.305
				c2.336,0.892,4.009,1.608,5.733,2.168c2.138,0.692,3.239-0.115,2.76-2.244c-0.735-3.268-2.058-6.337-4.75-8.536
				c-0.645-0.527-0.963-0.999-0.842-1.784c0.153-0.991,1.008-0.513,1.533-0.718c0.873-0.341,2.559,0.3,2.523-0.983
				c-0.045-1.669-0.076-4.877-0.711-4.845c-3.533,0.177-1.787-3.174-3.228-4.195c-0.025-0.018,0.025-0.142,0.242-1.183
				c1.766,2.031,3.093,3.75,4.615,5.272c3.754,3.75,7.654,7.317,12.833,9.091c0.806,0.275,1.723,0.711,2.208,1.358
				C542.462,503.627,548.761,504.602,554.702,507.481z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(166)} fill={props.fillColors[166]} d="M173.401,495.213c-0.071-0.25-0.142-0.5-0.212-0.75
				c-2.334-2.111-2.334-2.111-0.544-4.906c1.354-2.114-0.28-5.327-2.775-5.448c-0.787-0.038-1.587,0.118-2.353-0.183
				c-0.104-0.038-0.207-0.075-0.31-0.113c-0.389-0.067-1.08-0.062-1.116-0.214c-0.182-0.763,0.537-0.955,1.008-1.348
				c3.354-2.799,6.142-6.01,7.476-10.297c0.295-0.948,0.553-1.843,0.145-2.782c-0.592-1.363,0.527-1.895,1.283-2.48
				c0.838-0.65,1.504,0.055,2.039,0.646c1.926,2.129,4.262,3.763,6.522,5.49c0.891,0.682,1.858,1.212,2.961,1.459
				c1.483,0.333,2.685,0.239,2.855-1.701c0.062-0.702,0.181-1.477,0.536-2.06c0.689-1.13,1.406-2.111,2.908-0.615
				c1.204,1.199,2.67,2.383,4.242,2.913c3.007,1.015,2.965,3.237,2.585,5.528c-0.303,1.827-0.452,3.514,0.012,5.364
				c0.449,1.796-0.566,2.937-2.531,2.79c-2.312-0.173-3.965,0.667-5.124,2.717c-2.24,3.963-3.47,4.689-7.916,3.738
				c-2.614-0.56-4.544,0.486-6.607,1.552c-1.368,0.707-2.581,2.029-4.337,0.973C173.812,495.627,173.51,495.684,173.401,495.213z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(167)} fill={props.fillColors[167]} d="M295.147,467.463c3.938,0.014,6.999-2.087,10.226-4.693
				c-0.009,2.257-0.971,3.595-1.79,4.969c-1.525,2.56-3.669,4.595-5.756,6.676c-0.678,0.677-1.396,1.328-1.98,2.08
				c-0.509,0.655-1.296,1.387-0.792,2.303c0.578,1.051,1.715,0.8,2.595,0.562c3.009-0.814,5.721-2.258,8.244-4.099
				c0.712-0.519,1.558-1.577,2.498-0.838c0.879,0.692,0.516,1.97,0.281,2.913c-0.971,3.901-3.276,6.993-6.428,9.369
				c-3.985,3.005-8.52,4.749-13.552,4.912c-2.206,0.071-2.418-0.383-1.287-2.304c0.639-1.085,1.178-2.325,0.517-3.367
				c-0.732-1.154-2.039-0.627-3.257-0.475c-2.753,0.343-5.33,1.411-8.15,1.555c-4.369,0.224-7.88-1.509-11.178-4.024
				c-1.553-1.185-3.095-2.38-5.038-3.177c1.258-0.797,2.708,1.071,3.425-0.361c0.747-1.491-0.998-2.316-1.68-3.323
				c-1.014-1.496-2.48-2.687-3.76-4c-0.613-0.63-1.358-1.169-1.828-1.889c-0.578-0.886-1.904-1.801-1.166-2.889
				c0.66-0.974,2.036-0.426,3.124-0.25c3.331,0.536,6.667,1.049,10.317,1.62c-1.454,1.209-2.982,0.84-4.247,1.387
				c-1.994,0.861-2.321,1.97-0.565,3.261c3.327,2.446,7.021,4.284,11.088,5.094c4.644,0.924,9.27,0.769,13.637-1.352
				c0.78-0.378,2.085-0.565,1.775-1.871c-0.309-1.299-1.54-0.806-2.423-0.886c-0.707-0.065-1.424-0.014-2.602-0.014
				c2.77-2.078,5.854-2.934,7.801-5.377C293.094,467.5,293.782,467.043,295.147,467.463z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(168)} fill={props.fillColors[168]} d="M429.961,291.373c2.925-25.003,6.603-49.695,20.872-71.446
				c0.575,1.651,0.567,1.649-0.01,4.332c-1.293,6.005-2.156,12.075-2.79,18.184c-0.717,6.915-1.597,13.814-2.249,20.734
				c-0.354,3.768-0.394,7.562-0.919,11.322c-0.118,0.846-0.393,1.471-0.982,2.081c-3.581,3.694-7.136,7.415-10.675,11.149
				C432.089,288.909,431.042,290.156,429.961,291.373z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(169)} fill={props.fillColors[169]} d="M203.173,508.155c0.613,0.904,1.227,1.808,1.836,2.7
				c0.579,1.92,1.617,2.395,3.815,2.344c2.216-0.051,4.669,1.274,5.784,3.502c1.358,2.714,3.308,4.64,5.958,5.942
				c0.711,0.35,1.052,0.938,1.376,1.579c0.003-0.002,0.075-0.071,0.078-0.073c-1.399,2.187-3.74,2.91-5.936,3.92
				c-1.401,0.644-1.957,2.199-2.894,3.345c-0.082,0.112-0.163,0.225-0.244,0.337c-4.322,0.482-8.649,0.992-12.997,1.012
				c-1.42,0.007-2.845-0.165-4.264,0.068c-0.268,0.048-0.531,0.012-0.786-0.07c-1.666-0.873-3.531-1.34-4.956-2.75
				c-1.018-1.008-2.444-0.272-3.685-0.294c-2.179-0.037-2.447-0.369-1.601-2.351c0.657-1.538,0.984-3.163,1.44-4.756
				c0.425-1.485,1.197-2.312,2.704-0.998c0.693,0.604,1.46,1.047,2.365,0.551c0.568-0.312,0.524-0.971,0.427-1.509
				c-0.172-0.949-0.443-1.838-1.592-2.124c-1.063-0.264-2.174-0.237-3.215-0.596l0.103,0.055c-0.385-0.381-0.879-0.244-1.342-0.317
				c-1.799-0.282-2.973-3.5-1.819-4.915c0.553-0.679,1.174-0.325,1.72-0.031c1.773,0.957,3.459,1.415,5.367,0.139
				c0.825-0.552,2.04-0.237,3.062-0.162c1.783,0.132,2.444-0.794,2.55-2.382c0.016-0.238-0.051-0.498,0.019-0.715
				c0.393-1.208-0.979-3.034,1.188-3.613C200.413,505.25,201.527,505.713,203.173,508.155z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(170)} fill={props.fillColors[170]} d="M270.676,683.977c0.012,0.158-0.01,0.328,0.041,0.473
				c0.479,1.371,2.389,2.454,1.733,3.847c-0.519,1.102-2.208,1.674-3.411,2.425c-1.562,0.975-2.617,2.836-4.785,2.759l0.027,0.029
				c-1.7-2.905-4.628-4.502-7.167-6.441c-3.703-2.829-7.709-5.249-12.125-6.894c-1.181-0.439-2.265-0.846-3.588-0.318
				c-6.834,2.723-12.968,6.55-18.356,11.548c-2.098,1.946-1.976,1.971-3.417-0.522c-1.139-1.972-2.335-3.938-3.758-5.705
				c-0.733-0.912-0.51-1.312,0.147-1.889c5.895-5.164,12.008-9.445,20.386-10.069c7.885-0.587,15.45,0.071,22.968,2.239
				c0.102-0.012,0.205-0.023,0.307-0.035c0.977,0.272,1.841,0.941,2.931,0.828c0.63,0.274,1.229,0.605,1.818,0.958
				c1.933,2.22,4.342,3.941,6.36,6.076C270.815,683.524,270.774,683.756,270.676,683.977z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(171)} fill={props.fillColors[171]} d="M339.198,473.645c1.563-1.46,2.817-2.462,3.859-3.649
				c1.297-1.479,2.338-3.04,0.426-4.945c-1.46-1.456-0.469-4.054,1.481-4.772c1.54-0.568,2.912-0.284,4.397,0.025
				c3.847,0.801,7.638,1.967,11.624,1.939c0.688-0.004,1.098,0.319,0.895,1.042c-1.04,3.702-0.764,7.549-1.196,11.317
				c-0.504,4.395,0.686,8.634,2.58,12.636c0.343,0.725,0.744,1.428,0.445,2.498c-2.202-3.117-3.892-6.573-7.59-8.339
				c-0.648,1.696-0.016,3.08,0.616,4.395c1.885,3.92,3.918,7.759,6.613,11.208c0.409,0.524,1.136,1.15,0.585,1.854
				c-0.524,0.67-1.176-0.05-1.717-0.259c-2.657-1.026-4.995-2.778-8.272-2.011c-1.255,0.295-3.579-0.243-3.409-3.059
				c0.131-2.172-1.506-2.765-3.679-1.852c-0.966,0.406-2.096,1.82-2.861,1.028c-0.92-0.954,0.141-2.382,0.694-3.468
				c0.841-1.649,1.33-3.215,0.254-4.954c-0.435-0.702-0.445-1.567-0.118-2.319C346.755,477.524,345.605,474.203,339.198,473.645z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(172)} fill={props.fillColors[172]} d="M213.78,422.117c-0.211-1.938,1.063-2.361,2.53-2.223
				c3.776,0.359,4.775-0.758,3.601-4.225c-0.348-1.028-0.753-2.045-1-3.098c-0.261-1.113-0.754-1.855-1.95-2.103
				c-2.005-0.415-2.708-2.19-2.46-3.706c0.27-1.645,1.895-2.635,3.696-2.766c0.862-0.062,1.744,0.094,2.607,0.16
				c1.089,0.084,2.495,0.132,2.589-1.641c0.139-2.597,3.955-3.721,6.04-3.004c0.518,0.179,0.999,0.675,1.496,0.674
				c6.907-0.017,7.75,5.541,9.198,10.264c0.975,3.178-0.124,6.573,0.086,9.877c0.085,1.34-0.756,1.523-1.942,1.147
				c-2.177-0.689-4.236,0.126-6.271,0.752c-1.332,0.409-1.122,1.204-0.158,1.843c1.124,0.743,2.302,1.411,3.49,2.047
				c1.181,0.633,0.947,1.325,0.152,2.042c-1.04,0.938-2.471,1.669-3.665,1.407c-2.869-0.631-5.725-0.109-8.562-0.395
				c-2.163-0.217-3.736-1.147-4.536-3.479c-0.751-2.188-2.816-3.15-5.012-3.656C213.714,422.04,213.78,422.117,213.78,422.117z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(173)} fill={props.fillColors[173]} d="M556.693,320.431c-0.276-1.433,0.437-2.884,0.936-4.36
				c4.661-13.798,8.23-27.925,12.617-41.806c1.706-5.397,4.633-9.791,9.816-12.424c0.761-0.387,1.548-0.698,2.341-1.013
				c2.075-0.825,2.026,0.331,1.688,1.678c-1.341,5.331-2.634,10.677-4.137,15.963c-2.76,9.71-5.828,19.32-10.688,28.236
				c-1.475,2.706-3.091,5.337-4.705,7.964c-0.399,0.65-0.908,1.291-1.888,1.247c-1.753-0.079-2.381,0.915-2.326,2.56
				c0.046,1.374-0.292,2.654-1.264,3.695c-0.568,0.608-0.959,1.615-2.092,1.094c-1.005-0.462-0.171-1.271-0.292-1.906
				C556.656,321.127,556.693,320.881,556.693,320.431z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(174)} fill={props.fillColors[174]} d="M280.002,401.603c-5.103-5.869-10.657-11.229-16.428-16.356
				c-3.422-3.041-7.179-5.71-10.535-8.817c-5.652-5.234-9.825-11.747-14.712-17.645c-2.177-2.626-4.426-5.194-6.632-7.796
				c-0.582-0.686-1.444-1.183-1.737-2.49c11.147,6.868,21.806,13.89,32.27,21.221c4.882,3.42,9.407,7.241,13.863,11.17
				c1.439,1.27,1.188,3.13,1.369,4.65C278.1,390.908,279.402,396.15,280.002,401.603z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(175)} fill={props.fillColors[175]} d="M424.629,238.613c-1.066-10.213-3.343-20.17-4.97-30.233
				c-0.726-4.488-0.496-9.043-0.522-13.575c-0.011-1.835,0.798-2.64,2.637-2.338c5.984,0.982,11.83,2.485,17.5,4.652
				c0.939,0.36,0.938,0.918,0.722,1.884c-1.211,5.403-3.286,10.492-5.43,15.561C431.192,222.539,427.193,230.254,424.629,238.613z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(176)} fill={props.fillColors[176]} d="M252.495,474.657c-0.655-3.478-0.241-3.911,3.226-2.596
				c3.213,1.22,5.332,3.75,6.944,6.787c-1.402-0.436-2.862,0.098-4.16-0.62c-0.291-0.16-0.848-0.187-0.928,0.363
				c-0.038,0.258,0.109,0.729,0.294,0.805c5.006,2.046,8.62,6.555,13.993,7.932c2.66,0.681,5.328,0.657,8.005,0.013
				c1.845-0.444,3.716-0.78,5.569-1.195c0.61-0.137,1.271-0.548,1.711,0.183c0.355,0.591,0.162,1.242-0.173,1.822
				c-0.198,0.344-0.422,0.672-0.618,1.017c-1.135,1.993-0.542,3.121,1.709,3.307c3.335,0.275,6.392-0.749,9.368-2.054
				c5.151-2.259,9.416-5.512,11.753-11.553c1.104,2.704-0.179,4.41-0.842,6.078c-1.133,2.854-1.276,2.825,1.496,4.079
				c1.127,0.509,1.529,1.013,0.989,2.372c-1.189,2.993-3.173,5.442-5.211,7.789c-2.393,2.753-5.292,5.013-8.797,7.04
				c0.249-1.87,1.573-2.743,2.321-3.914c1.538-2.408,3.478-4.567,4.42-7.333c0.245-0.721,0.854-1.49-0.031-2.151
				c-0.768-0.573-1.53-0.165-2.233,0.226c-1.108,0.615-2.26,1.179-3.287,1.914c-7.534,5.394-15.127,4.288-22.78,0.304
				c-1.824-0.949-3.674-1.853-5.544-2.706c-0.926-0.422-2.029-0.959-2.873-0.02c-0.704,0.785-0.179,1.751,0.012,2.696
				c0.25,1.244,1.368,2.224,1.045,3.677c-0.964,0.077-1.577-0.516-2.173-1.113c-3.219-3.225-6.393-6.497-9.666-9.667
				c-1.308-1.267-1.02-2.088,0.292-3.04c2.484-1.804,2.646-2.812,0.752-5.185c-1.466-1.837-2.563-4.011-4.648-5.317
				C252.426,474.592,252.495,474.657,252.495,474.657z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(177)} fill={props.fillColors[177]} d="M500.071,741.18c0.656,0.421,1.475,0.625,1.858,1.405
				c1.114,0.678,0.549,1.189-0.105,1.818c-1.035,0.994-2.558,0.899-3.631,1.717c-1.588,1.21-2.428-0.489-3.593-0.863
				c-0.743-0.238-1.447-0.648-2.185-1.139c4.612,2.583,4.612,2.583,2.013,4.69c-0.11-0.005-0.222-0.011-0.333-0.016
				c-0.634,0.004-1.391,0.236-1.879-0.028c-2.656-1.438-5.303-0.804-7.798,0.157c-3.347,1.287-6.227-0.269-9.305-0.929
				c-5.824-1.247-11.73-1.81-18.178-1.311c1.924-2.185,4.262-2.704,6.108-4.109c-1.583,0.376-3.259,0.631-4.507,1.799
				c-3.351,3.137-7.816,2.438-11.756,3.529c-1.655,0.459-1.628-2.134-3.209-1.999c-0.018-0.107-0.035-0.215-0.054-0.322
				c1.118-1.515,2.715-2.448,4.232-3.476c1.405-0.911,2.901-1.628,4.504-2.119c4.293-2.377,9.051-3.284,13.789-4.04
				c7.843-1.251,15.695-1.205,23.434,0.917C493.182,737.878,496.633,739.515,500.071,741.18z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(178)} fill={props.fillColors[178]} d="M553.775,743.12c1.353,1.543,3.312,2.065,5.056,2.91
				c3.206,1.554,5.827,3.812,8.27,6.331c0.508,0.524,0.957,1.171,0.729,1.886c-0.258,0.812-1.065,0.877-1.808,0.794
				c-1.697-0.189-3.548,0.543-5.053-0.827c-0.372-0.169-0.742-0.338-1.11-0.505c-1.56-2.486-4.254-3.335-6.735-4.197
				c-6.456-2.24-12.954-4.193-19.932-4.494c-7.594-0.328-14.938,0.127-22.109,2.861c-4.54,1.73-8.207,4.713-11.92,7.636
				c-1.656,1.305-2.947,2.22-4.89,0.489c-1.202-1.072-3.827-1.025-3.979-2.558c-0.142-1.421,2.041-2.441,3.122-3.707
				c0.251-0.294,0.453-0.629,0.679-0.946l-0.098,0.089c3.184-3.125,7.157-4.806,11.268-6.298c3.107-1.128,6.297-1.955,9.455-2.9
				l-0.084,0.046c2.344,0.498,4.372-0.822,6.574-1.15c6.887-1.027,13.688-0.615,20.433,1.049c1.295,0.32,2.602,0.591,3.902,0.884
				l0.036,0.025c1.255,0.721,2.617,1.091,4.044,1.255C551.009,742.235,552.392,742.678,553.775,743.12z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(179)} fill={props.fillColors[179]} d="M155.244,640.784c0.913-1.011,1.768-2.053,1.916-3.483
				c-0.113-0.557,0.166-0.791,0.682-0.832c1.599-1.965,3.608-3.613,4.663-6.001c-0.389-0.681-0.084-1.201,0.386-1.683
				c1.26-0.841,2.16-2.614,4.138-1.606c4.049-1.996,8.043-4.12,12.58-4.878c1.423-0.238,2.823-0.503,4.159,0.2
				c0.742,0.39,1.317,0.198,1.976-0.169c2.684-1.495,4.797-0.889,6.526,1.708c0.521,0.782,1.221,1.493,1.969,2.067
				c1.89,1.449,3.136,1.225,3.908-1.004c0.499-1.438,1.098-1.008,1.851-0.587c8.957,5.009,16.01,11.746,19.534,21.658
				c0.523,1.474-0.507,3.638-1.995,4.051c-0.879,0.244-1.166-0.566-1.509-1.151c-1.605-2.728-3.149-5.524-5.253-7.889
				c-3.621-4.066-8.092-7.028-13.037-9.273c-0.213-0.098-0.586,0.156-0.885,0.247c-0.11-0.014-0.22-0.028-0.33-0.042
				c-5.064-1.523-10.231-1.599-15.438-1.098c-1.783,0.261-3.525,0.702-5.264,1.159c-3.257-0.357-5.678,1.477-7.794,3.443
				c-2.937,2.728-6.254,5.067-8.539,8.522c-1.034,1.564-1.307,3.632-3.026,4.743c-0.107,0.033-0.215,0.065-0.324,0.097
				c-0.65,0.418-0.996,1.423-2.133,0.843c-0.927-0.474-0.835-1.202-0.885-1.991c-0.17-2.661,1.507-4.738,2.204-7.123L155.244,640.784z
				"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(180)} fill={props.fillColors[180]} d="M221.531,695.344c5.409,16.282-8.256,61.93-22.472,75.988
				c-0.396-0.291-0.233-0.715-0.24-1.084c-0.075-3.57,0.486-7.247-0.576-10.674c-1.139-3.678,0.573-6.148,2.562-8.75
				c4.483-5.87,8.076-12.135,9.764-19.482c0.803-3.494,2.891-6.642,4.066-10.096c2.584-7.593,4.247-15.417,6.062-23.206
				C220.872,697.288,221.151,696.561,221.531,695.344z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(181)} fill={props.fillColors[181]} d="M109.056,460.633c-0.06,0.062-0.12,0.126-0.18,0.188
				c-2.007,1.351-3.717,3.154-6.071,3.974c-0.818,0.601-1.708,1.053-2.7,1.291c-0.861,0.402-1.866,0.916-2.641,0.219
				c-0.932-0.838,0.037-1.733,0.335-2.59c0.22-0.633,0.98-1.229,0.368-1.95c-0.53-0.623-1.187,0.063-1.79,0.02
				c-1.107-0.079-1.959,0.574-2.89,1.005c-1.757,1.256-3.592,2.354-5.733,2.833c-2.659,0.822-2.913,0.72-3.13-1.913
				c-0.109-1.314-0.693-1.853-1.918-1.893c-0.636-0.021-1.272-0.035-1.907-0.004c-2.455,0.12-2.512,0.047-1.928-2.611
				c0.094-0.129,0.185-0.261,0.272-0.394c0.641-0.29,1.266-0.628,1.926-0.864c3.073-1.098,3.323-1.623,2.034-4.439
				c-0.886-1.934,0.033-3.849,1.983-4.787c1.979-0.951,4.386-1.219,5.72-3.301c1.268-0.27,1.878-1.455,2.896-2.072
				c0.689-0.929,1.056-0.527,1.011,0.341c-0.056,1.11,0.115,2.237-0.198,3.335c-0.111,0.39-0.127,0.801,0.311,1.04
				c0.427,0.233,0.783,0.055,1.048-0.271c1.124-1.386,2.792-2.41,2.896-4.473c0.077-1.546,1.023-1.625,2.174-1.151
				c1.025,0.423,1.97,0.997,3.163,0.955c0.971-0.033,1.277,0.693,1.454,1.569c0.57,2.839,1.701,3.607,4.437,3.168
				c0.726-0.117,1.612-0.837,2.14,0.171c0.453,0.866-0.454,1.425-0.83,2.088c-0.157,0.276-0.453,0.472-0.582,0.756
				c-0.407,0.901-1.917,1.59-1.28,2.565c0.609,0.932,1.984,0.555,3.045,0.181c0,0-0.085,0.074-0.085,0.074
				c0.697-0.007,1.41-0.039,1.454,0.952l0.061-0.086c-0.756,0.805-0.694,1.814-0.345,2.669c0.678,1.655-0.198,1.983-1.513,2.424
				C111.065,459.983,109.751,459.419,109.056,460.633z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(182)} fill={props.fillColors[182]} d="M74.493,603.899c-2.462-1.765-5.427-1.997-8.241-2.41
				c-6.452-0.949-12.953-1.549-19.247,0.989c-1.396,0.563-2.713,1.33-4.124,1.851c-0.585,0.217-1.31,1.535-1.903,0.244
				c-0.413-0.901-0.425-2.054,0.832-2.647c0.922-0.437,1.811-0.943,2.769-1.446c-0.533-0.649-0.898-0.219-1.271-0.102
				c-1.594,0.505-2.487,0.024-2.469-1.749c0.011-1.105,0.074-2.214,0.017-3.316c-0.17-3.233,0.268-3.52,3.431-2.147
				c0.345,0.149,0.689,0.301,1.095,0.319c-0.807-1.063-2.066-1.438-3.214-1.474c-1.728-0.054-1.53-1.44-1.448-2.165
				c0.086-0.76,1.07-0.247,1.726-0.161c3.07,0.404,5.965,1.381,8.831,2.499c-2.775-1.912-5.966-2.638-9.209-3.078
				c-3.995-0.542-7.95,0.11-11.891,0.734c-1.044,0.165-2.105,0.768-2.591-0.957c-0.48-1.704,0.994-1.358,1.629-1.684
				c0.95-0.487,2.077-0.629,3.129-0.918l-0.08,0.007c9.942-0.993,19.193,0.479,26.88,7.533l-0.065-0.066
				c0.642,0.704,1.237,1.461,1.939,2.099c0.785,0.712,0.577,1.155-0.451,1.661c2.312-0.066,4.545,1.051,6.849-0.888
				c-1.589-0.394-2.646,0.214-3.748,0.336c-0.658,0.072-1.28,0.035-1.606-0.7c-0.294-0.664,0.155-1.042,0.526-1.447
				c1.757-1.914,4.128-2.875,6.401-3.912c2.959-1.351,6.021-2.254,9.409-2.044c1.951,0.121,1.613,1.575,1.822,2.461
				c0.3,1.271-0.982,0.427-1.504,0.68c-1.485,0.722-3.515,0.13-4.57,1.897c-0.044,0.324-1.46,0.338-0.242,0.927
				c0.837,0.403,1.783,0.082,2.479,0.176c-2.195-0.003-2.195-0.003-2.305-1.165c1.773-0.366,3.396-1.429,5.309-1.152
				c0.684,0.099,1.387-0.004,1.458,1.1c0.153,2.378,0.469,4.745,0.721,7.146c-1.139,0.211-1.868-0.862-2.999-0.84
				c0.445,0.865,1.316,0.994,1.979,1.405c1.052,0.653,1.884,3.475,1.104,4.338c-0.795,0.88-1.677-0.264-2.55-0.231l0.06,0.058
				c-1.484-0.873-2.974-1.729-4.752-1.838L74.493,603.899z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(183)} fill={props.fillColors[183]} d="M449.852,693.854c-0.702-0.422-1.405-0.843-2.107-1.264
				c-4.081-3.637-8.961-5.864-14.03-7.63c-6.236-2.173-12.372-4.645-18.977-5.71c-9.792-1.58-19.483-1.562-29.168,0.691
				c-3.751,0.874-7.557,1.509-11.339,2.246c-0.232,0.045-0.547-0.07-0.707,0.047c-4.097,3.014-6.395-0.06-9.019-2.738
				c2.169-1.354,4.938-1.209,6.821-2.963c-0.009-0.229,0.056-0.433,0.207-0.607c0.994-0.443,1.857-1.296,3.092-0.992
				c0.805,0.183,1.538-0.07,2.266-0.387c0.248-0.085,0.497-0.1,0.755-0.082c2.63,1.069,4.975-0.56,7.453-0.85
				c9.397-1.099,18.738-0.679,28.056,0.711c2.53,0.377,5.062,0.469,7.602,0.557c0.986,0.204,1.93,0.549,2.872,0.896
				c1.625,1.506,3.793,1.881,5.759,2.591c5.771,2.086,11.199,4.906,16.709,7.545c2.43,1.164,3.91,3.651,6.224,5.046
				c0.648,0.286,1.297,0.573,1.945,0.86c0.675,0.256,1.407,0.223,2.098,0.408c1.019,0.409,1.652,1.234,2.204,2.12
				c0.339,0.543,0.605,1.147,1.143,1.519c5.512,3.81,9.189,9.434,13.88,14.057c0.935,0.921,1.946,1.949,1.771,3.501
				c-0.034,0.108-0.068,0.216-0.102,0.324c-0.198,0.801,1.12,1.911-0.401,2.412c-1.075,0.354-1.75-0.11-2.519-1.088
				c-1.433-1.822-2.438-4.109-4.745-5.143l0.08,0.077c-2.712-3.103-5.588-6.043-8.721-8.725l0.09,0.081
				C456.176,698.62,452.984,696.273,449.852,693.854z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(184)} fill={props.fillColors[184]} d="M260.808,657.649c1.224,1.469,3.203,1.64,4.668,2.749
				c2.213,1.676,2.271,2.029,0.108,3.795c-1.415,1.155-2.563,1.573-4.397,0.392c-2.776-1.788-6.123-2.357-8.607-1.953
				c3.038,0.76,5.67,0.997,7.99,2.291c2.347,1.309,2.335,1.298,0.608,3.209c-1.192,1.319-1.956,1.717-3.994,0.896
				c-4.054-1.63-8.499-1.71-12.87-0.885c-1.027,0.193-2.041,0.116-3.079-0.031c-4.595-0.653-9.155-0.722-13.555,1.099
				c-1.097,0.454-3.538-0.903-4.058-2.43c-0.471-1.383,0.846-1.678,1.674-2.132c2.067-1.133,4.363-1.564,6.636-2.079
				c-2.858-0.298-5.421,0.708-7.904,1.941c-1.685,0.837-4.916-0.465-5.366-2.314c-0.243-0.997,0.66-1.403,1.287-1.781
				c4.097-2.472,8.551-3.985,13.257-4.801c3.717-0.644,7.466-0.427,11.206-0.461c5.111-0.046,10.101,0.699,14.94,2.366
				c0.515,0.178,0.973,0.568,1.551,0.212L260.808,657.649z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(185)} fill={props.fillColors[185]} d="M528.123,232.163c-1.351,1.499-2.702,2.997-4.053,4.496
				c-1.811,0.95-3.445,2.21-5.626,2.413c-4.415,0.412-7.191-1.502-8.094-5.786c-0.036-3.033,0.193-6.112-1.567-8.836
				c0.007-1.708-1.307-2.697-2.478-3.237c-6.329-2.914-5.919-8.484-4.029-14.106c0.361-1.074,0.414-3.021,1.912-2.946
				c1.64,0.082,3.963,0.574,4.677,1.711c1.036,1.65,2.166,0.247,3.201,0.837c1.401,0.797,1.937,1.155,0.998,2.659
				c-1.909,3.062-1.614,5.741,0.748,8.362c1.383,1.533,2.725,3.104,4.146,4.6c3.001,3.158,5.266,2.722,7.202-1.341
				c-0.767-0.976-0.189-1.376,0.703-1.642c1.237-1.047,1.94-2.467,2.662-3.866c1.177-2.283,2.435-0.488,3.48,0.034
				c1.538,0.769,0.315,1.526-0.306,2.105c-3.642,3.395-6.837,7.035-8.458,11.927c-0.855,2.585-2.887,4.033-5.878,3.525
				c-0.307-0.053-0.685,0.312-1.03,0.483c-2.058-2.71-1.556-6.246-2.955-9.167c-1.533-1.829-3.438-3.213-5.419-4.508
				c-1.179-0.821-2.255-1.72-2.492-3.267c0.24,1.523,1.286,2.43,2.454,3.245c2.004,1.298,3.922,2.7,5.473,4.546
				c0.558,1.153,0.937,2.381,1.009,3.64c0.117,2.047,0.964,3.791,1.96,5.501c3.977,0.604,5.815-0.272,7.549-3.745
				c0.886-1.775,1.576-3.646,2.446-5.428c1.586-3.249,5.188-4.595,7.208-7.495c0.465-0.667,1.5,0.307,2.126,0.831
				c0.818,0.687-0.012,1.086-0.355,1.515C532.21,223.135,530.153,227.641,528.123,232.163z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(186)} fill={props.fillColors[186]} d="M295.147,467.463c-0.659,0.512-1.317,1.022-1.964,1.523
				c-2.817,1.209-5.463,2.765-8.061,4.408c-0.472,0.299-1.054,0.573-0.762,1.274c0.214,0.513,0.744,0.532,1.236,0.532
				c1.239-0.002,2.478-0.001,3.79-0.001c-0.223,1.159-1.048,1.268-1.655,1.473c-8.453,2.848-16.153,1.166-23.242-3.979
				c-0.967-0.702-1.124-1.376,0.312-1.699c1.31-0.295,2.619-0.591,3.927-0.89c0.858-0.195,1.887-0.342,2.09-1.317
				c0.208-1.001-0.452-1.706-1.281-2.354c-2.437-1.904-5.327-3.237-7.311-5.711c-0.566-0.706-1.808-1.168-1.374-2.291
				c0.329-0.851,1.418-0.771,2.23-1.037c2.127-0.699,4.281-0.217,6.415-0.361c0.623-0.042,1.416,0.157,1.684-0.579
				c0.32-0.878-0.478-1.231-1.067-1.622c-1.628-1.08-3.259-2.155-5.262-3.479c1.579-0.335,2.616-0.448,3.727,0.246
				c2.645,1.654,5.481,2.688,8.689,2.367c1.218-0.122,0.979,0.818,0.827,1.454c-0.315,1.315-1.429,1.814-2.587,2.147
				c-1.086,0.312-2.178,1.059-2.01,2.01c0.183,1.029,1.445,1.58,2.619,1.7c2.848,0.293,5.451-0.714,8.104-1.504
				c1.15-0.343,2.571-1.684,3.468-0.486c0.871,1.161-0.735,2.282-1.283,3.409c-0.034,0.069-0.016,0.168-0.056,0.231
				c-0.626,1.014-2.407,1.982-1.383,3.135c1.112,1.249,2.613-0.176,3.908-0.66c3.116-1.165,5.559-3.598,8.75-4.63
				c0.755-0.245,1.498-0.78,2.106,0.147c0.511,0.779-0.166,1.285-0.5,1.905C298.226,464.696,296.324,465.756,295.147,467.463z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(187)} fill={props.fillColors[187]} d="M282.131,619.94c-0.563,0.271-1.23,0.427-1.674,0.829
				c-2.643,2.396-4.812,0.574-7.36-0.648c0.942-0.624,1.645-1.089,2.347-1.555c-0.046-1.76,1.659-1.676,2.539-2.46
				c-0.881,0.777-2.583,0.696-2.519,2.461c-0.501-0.417-0.928-0.356-1.484,0.018c-2.107,1.421-4.047,1.297-5.768-0.398
				c-0.352-0.347-0.256-0.575-0.925-0.238c-1.444,0.728-2.874-0.346-4.288-0.661c-0.749-0.168-0.333-1.058,0.033-1.601
				c0.884-1.31,1.775-2.614,2.664-3.921c-0.219-0.351-0.209-0.712-0.05-1.08c0.803-1.201,1.899-2.054,3.185-2.685
				c4.314-2.857,9.259-4.191,14.075-5.833c2.218-0.757,4.568-0.657,6.848-0.752c6.936-0.287,13.916-0.803,20.641,1.655
				c1.502,0.549,3.035,1.042,4.152,2.29l-0.107-0.078c1.869,1.146,0.47,2.303,0.066,3.517c-0.491,1.48-1.581,1.196-2.497,0.853
				c-5.377-2.02-10.939-2.898-16.656-2.811c-1.958,0.029-2.53-1.727-3.781-2.601c-0.667-0.467-1.043-1.669-2.122-1.147
				c-1.163,0.562-0.4,1.717-0.175,2.306c0.516,1.351,0.363,1.673-1.12,1.98c-3.049,0.633-6.237,0.997-8.805,3.145
				c2.896-1.188,5.758-2.498,8.961-2.49c0.703,0.002,1.778-0.982,1.812,0.759c0.026,1.359,1.594,2.937-0.757,3.975
				c2.028,0.734,2.315,1.905,1.92,3.98c-0.127,0.666,0.38,2.539,0.869,3.768c0.222,0.559,0.521,1.158,0.024,1.494
				c-0.611,0.413-1.189-0.081-1.65-0.566c-0.549-0.579-1.113-1.144-1.666-1.719c-1.477-1.537-1.476-1.538,0.465-2.371
				c-2.521,0.062-4.725,0.966-6.643,2.623C282.471,620.403,282.301,620.175,282.131,619.94z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(188)} fill={props.fillColors[188]} d="M268.209,651.201c-2.808-2.331-2.308-4.879-0.792-7.759
				c2.727-5.175,6.054-9.794,10.689-13.447c1.086-0.856,1.592-0.673,2.201,0.342c0.327,0.545,0.645,1.115,1.073,1.575
				c1.199,1.288,2.699,1.061,3.329-0.573c0.509-1.322,0.888-2.714,1.154-4.107c0.602-3.148,2.866-4.374,5.718-2.97
				c1.072,0.528,2.094,1.156,2.279-0.799c0.027-0.279,0.148-0.641,0.353-0.799c1.496-1.155,7.182-0.68,8.417,0.765
				c0.921,1.076,1.688,1.067,2.76,0.202c1.097-0.885,2.377-1.511,2.424,0.825c0.025,1.298,0.537,2.127,1.79,2.434
				c1.283,0.314,1.683-0.666,1.911-1.561c0.342-1.341,1.271-0.493,1.629-0.346c3.171,1.308,6.492,2.548,5.816,7.081
				c-0.163,1.093,0.535,1.795,1.589,2.043c1.114,0.264,1.874-0.21,2.08-1.324c0.222-1.194,0.776-1.112,1.385-0.389
				c3.676,4.377,6.952,9.018,8.773,14.5c0.363,1.095,0.905,2.519-0.652,3.374c-0.938,0.515-1.611,0.865-2.001-0.731
				c-0.53-2.177-1.721-4.073-3.648-5.386c-0.108-0.025-0.218-0.05-0.327-0.075c-2.996-2.335-5.254-5.533-8.392-7.63
				c-2.612-1.745-5.007-4.292-8.639-3.915c-0.233-0.06-0.446-0.166-0.648-0.292c-2.472-1.917-5.379-0.771-8.065-1.038
				c-2.119-0.209-4.436-0.669-6.411,0.815c-0.214,0.113-0.443,0.169-0.679,0.199c-4.19-0.587-7.214,2.215-10.452,3.967
				c-4.347,2.353-7.857,5.85-10.401,10.237c-0.749,1.292-0.69,2.771-1.42,4.011c-0.505,0.661-1.075,1.233-1.945,1.374
				C268.659,651.826,268.215,651.841,268.209,651.201z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(189)} fill={props.fillColors[189]} d="M354.312,289.678c0.337,5.468-0.759,10.072-1.562,14.71
				c-1.063,6.142-2.052,12.29-2.601,18.51c-0.631,7.153-1.496,14.285-2.171,21.435c-0.133,1.42-0.727,1.585-1.85,1.207
				c-3.065-1.033-6.169-1.99-8.773-4.02c-0.524-0.409-1.276-0.637-1.149-1.616c0.769-5.921,1.469-11.831,3.072-17.63
				c2.588-9.363,6.657-18.03,11.53-26.37C351.886,294.059,352.905,292.181,354.312,289.678z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(190)} fill={props.fillColors[190]} d="M183.197,443.758c1.13,1.535,2.078,2.195,4.086,1.851
				c1.98-0.339,4.168,0.48,6.257,0.838c0.845,0.145,0.793,1.12,0.808,1.541c0.066,1.945,1.434,2.906,2.833,3.419
				c1.903,0.697,1.875,1.64,1.306,3.238c-1.02,2.868-0.951,2.944,1.941,3.645c1.758,0.427,3.46,0.772,4.937,2.019
				c1.377,1.163,0.879,1.587-0.407,2.038c-0.225,0.079-0.451,0.266-0.664,0.247c-2.475-0.212-3.277,1.127-3.884,3.363
				c-0.712,2.62-3.492,1.885-5.447,2.388c-0.748,0.193-1.604-0.07-2.381,0.058c-1.811,0.299-2.714,1.5-2.906,3.275
				c-0.207,1.919-1.191,2.129-2.771,1.376c-3.429-1.637-6.09-4.228-8.742-6.846c-0.694-0.686-0.269-1.09,0.225-1.562
				c0.693-0.661,1.346-1.364,2.048-2.016c0.712-0.661,0.806-1.257,0.099-2.025c-1.57-1.707-1.243-3.538,0.068-5.142
				c1.98-2.423,3.146-4.821,1.523-7.973C181.451,446.178,182.491,444.926,183.197,443.758z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(191)} fill={props.fillColors[191]} d="M363.716,273.053c-0.792,6.715-1.592,12.714-2.185,18.733
				c-0.582,5.91-0.863,11.85-1.446,17.759c-0.652,6.614-1.011,13.25-1.744,19.861c-0.417,3.767-0.855,7.555-1.017,11.344
				c-0.072,1.681-0.664,3.26-0.577,4.955c0.051,1.006-0.384,1.578-1.678,1.12c-1.479-0.524-3.138,0.034-4.7-0.589
				c-0.782-0.312-0.843-0.43-0.765-1.258c0.713-7.583,1.152-15.196,2.013-22.761c0.852-7.493,2.089-14.94,3.491-22.36
				c0.511-2.703,0.936-5.484,0.679-8.285c-0.229-2.513,0.318-4.76,1.662-6.979C359.594,281.048,361.426,277.314,363.716,273.053z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(192)} fill={props.fillColors[192]} d="M128.714,662.387c-5.225,1.743-9.179,5.147-12.417,9.492
				c-0.614,0.823-1.378,1.558-1.868,2.446c-0.993,1.802-2.067,0.398-3.002,0.091c-1.463-0.481-0.288-1.238,0.017-1.8
				c4.745-8.742,12.375-13.594,21.856-15.531c6.841-1.397,13.471,0.396,19.936,2.568c2.896,0.973,5.912,1.618,8.453,3.608
				c1.506,1.18,1.802,1.992,0.81,3.649c-1.105,1.848-2.135,0.991-3.242,0.243c-5.112-3.454-11.152-4.215-16.892-5.839
				c-1.111-0.315-2.252-0.851-3.789-0.027c5.456,0.979,10.436,2.537,15.419,4.086c1.754,0.545,3.42,1.302,4.876,2.42
				c0.597,0.459,1.792,0.926,0.774,2.01c-0.75,0.799-1.269,2.006-2.892,0.988c-4.635-2.908-9.703-4.697-15.237-4.364
				c-4.984,0.301-9.755,1.676-14.265,3.858c-3.332,1.612-7.06,2.634-9.158,6.124c-0.121,0.2-0.729,0.108-1.111,0.153
				c-0.613,0.539-0.84,0.069-1.065-0.407c-1.553-0.523-0.814-1.256-0.275-2.121c2.788-4.475,6.672-7.72,11.225-10.258
				c0.671-0.374,1.523-0.507,1.94-1.277c1.588-0.952,3.414-1.148,5.154-1.617c0.234-0.063,0.308-0.084-0.259,0.07
				C132.319,661.203,130.494,661.714,128.714,662.387z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(193)} fill={props.fillColors[193]} d="M445.016,294.756c-0.479-2.442,0.377-4.59,0.388-6.792
				c0.02-3.696,0.543-7.387,0.787-11.085c0.103-1.546,1.383-2.351,2.19-3.286c10.209-11.815,16.884-25.474,21.416-40.288
				c0.945-3.092,1.891-6.184,2.81-9.19c1.757,1.624,3.872,3.17,5.467,5.139c3.313,4.09,3.081,4.076-0.536,8.105
				c-10.345,11.523-17.887,24.608-21.972,39.644c-1.089,4.005-2.211,8.005-3.179,12.039c-0.263,1.091-0.762,1.581-1.638,2.089
				C448.849,292.233,447.026,293.472,445.016,294.756z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(194)} fill={props.fillColors[194]} d="M529.253,303.823c4.796-7.836,7.728-15.833,9.507-24.284
				c0.932-4.423,1.295-8.905,1.672-13.394c0.244-2.913,0.562-5.82,0.903-8.723c0.167-1.415,1.323-1.177,2.236-1.091
				c2.825,0.263,5.639,0.68,8.469,0.87c1.272,0.086,1.683,0.494,1.982,1.769c0.945,4.027,0.026,7.851-0.979,11.655
				c-3.025,11.458-9.627,20.682-18.143,28.686c-1.396,1.312-2.719,2.702-4.116,4.012C530.543,303.549,530.098,303.559,529.253,303.823
				z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(195)} fill={props.fillColors[195]} d="M413.944,509.879c0.212-1.688,0.42-2.925,0.501-4.17
				c0.045-0.681,0.153-1.616-0.744-1.8c-0.677-0.138-1.005,0.612-1.382,1.136c-1.575,2.187-5.104,3.483-7.145,2.941
				c-1.642-0.436-1.63-1.188-0.937-2.398c0.525-0.918,1.351-1.475,2.133-2.145c0.998-0.854,2.701-1.183,2.675-3.057
				c-0.01-0.698,0.646-0.627,1.28-0.526c2.968,0.474,5.381,0.024,7.014-3.083c0.66-1.258,2.646-1.819,4.131-2.763
				c-1.087-1.004-2.026-2.197-3.871-1.964c-0.774,0.099-1.981-0.479-1.912-1.075c0.188-1.59,0.46-3.335,1.856-4.43
				c2.387-1.87,8.534-1.068,10.283,2.247c0.073,0.139,0.216,0.361,0.167,0.427c-2.124,2.858,1.287,4.544,1.438,6.904
				c0.027,0.443-0.163,0.919-0.32,1.354c-0.976,2.7-0.628,3.4,2.2,4.368c4.239,1.45,4.236,1.448,2.473,5.888
				c-0.28,0.703,0.049,0.944,0.752,1.086c2.063,0.418,4.104,0.946,6.164,1.388c0.884,0.189,1.86,0.354,1.822,1.463
				c-0.039,1.158-0.769,1.899-2.007,1.936c-1.038,0.03-2.078-0.017-3.115,0.017c-1.487,0.047-2.837-0.248-3.964,1.572
				c-0.972,1.569-3.281,1.317-5.117,1.136c-0.837-0.083-0.763-0.674-0.315-1.204c0.359-0.427,0.775-0.806,1.143-1.227
				c0.748-0.857,1.549-1.82,0.919-2.976c-0.633-1.161-1.638-0.335-2.537-0.184c-1.789,0.299-2.675-0.684-3.292-2.302
				c-0.312-0.814,0.083-2.021-1.076-2.456c-1.605-0.603-3.186-1.322-4.842-1.718c-0.953-0.228-1.293,0.787-1.4,1.587
				C416.69,507.565,415.707,508.685,413.944,509.879z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(196)} fill={props.fillColors[196]} d="M270.676,683.977c-0.096-0.069-0.188-0.144-0.274-0.225
				c-1.931-1.886-1.984-2.047-0.097-3.942c0.99-0.994,1.507-2.473,2.976-2.985c1.481-0.238,2.607-2.066,4.385-0.99
				c3.262-1.185,6.492-2.469,9.792-3.534c7.657-2.474,15.13-1.37,22.48,1.398c2.294,0.864,4.565,1.794,6.515,3.312
				c0.688,0.536,2.188,0.783,1.702,1.921c-0.355,0.833-1.62,1.391-2.66,1.312c-0.838-0.062-1.108-0.199-1.333,0.859
				c-0.252,1.183-1.556,1.12-2.617,1.119c-4.877-0.004-9.761-0.165-14.628,0.058c-7.445,0.341-14.65,1.9-21.467,5.037
				c-0.637,0.293-1.47,1.162-2.059,0.142c-0.422-0.73,0.212-1.447,0.78-2.023c4.47-4.528,9.938-7.148,16.112-8.393
				c0.644-0.13,1.273-0.126,1.612-0.8c3.371-0.008,6.686-0.988,8.768-0.55c-2.064-0.264-5.385,0.387-8.733,0.604
				c-6.536,0.273-12.096,2.881-16.815,7.276C273.597,684.984,272.428,686.687,270.676,683.977z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(197)} fill={props.fillColors[197]} d="M413.305,505.087c0.856,1.745-0.539,3.124-0.13,4.577
				c0.139,0.494-0.229,1.184,0.491,1.432c0.53,0.183,0.91-0.234,1.222-0.583c1.191-1.331,2.731-2.45,2.818-4.48
				c0.018-0.396,0.03-0.795,0.519-0.957c0.476-0.158,4.766,2.104,4.957,2.634c1.363,3.784,2.246,4.318,6.126,3.642
				c0.144,1.777-1.447,2.508-2.22,3.688c-0.964,1.47-0.825,2.063,1.061,2.169c0.802,0.045,2.421-1,2.303,1.235
				c-0.096,1.796,1.004,3.977-2.33,3.978c-2.351,0.001-3.812,2.056-5.657,3.188c-1.36,0.834-2.866,1.225-4.261,1.916
				c-0.488,0.242-1.351,0.287-1.777-0.284c-0.377-0.504,0.133-1.022,0.309-1.538c0.367-1.079,1.768-2.189,1.104-3.108
				c-0.617-0.856-1.987,0.384-3.128,0.317c-1.481-0.085-2.604,1.069-3.901,1.646c-0.932,0.414-1.837,0.863-2.83,1.182
				c-1.84,0.591-2.952,0.809-2.879-1.813c0.044-1.605-1.422-2.06-2.923-2.036c-0.921,0.015-2.193,0.352-2.504-0.798
				c-0.347-1.284,0.896-1.885,1.802-2.522c0.064-0.045,0.146-0.063,0.218-0.1c2.337-1.194,2.603-1.95,1.483-4.23
				c-0.769-1.564-0.069-3.247,1.43-4.046c2.158-1.149,4.643-1.51,6.689-2.961C412.07,506.682,412.602,505.955,413.305,505.087z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(198)} fill={props.fillColors[198]} d="M213.193,531.414c2.461-0.475,4.803,0.272,7.177,0.695
				c0.916,0.163,1.809,0.637,1.978,1.46c0.185,0.896-0.861,1.193-1.539,1.582c-0.845,0.485-1.869,0.474-2.695,0.806
				c-1.149,0.461-2.917-0.454-3.517,1.903c-0.232,0.917-3.07,0.57-4.625,0.118c-2.496-0.726-5.008-0.685-7.543-0.698
				c-1.236-0.007-1.915,0.529-2.122,1.749c-0.515,3.047-2.176,4.737-5.464,5.264c-1.395,0.224-2.792,0.487-4.212,0.636
				c-2.394,0.25-4.881,0.428-6.624,2.664c-0.779,1.001-2.286,0.293-3.438,0.532c-0.124,0.035-0.246,0.07-0.37,0.105
				c-2.009,0.019-2.762-0.786-2.559-2.946c0.193-2.049-0.879-4.028,0.053-6.266c1.363-3.274,2.739-6.465,5.188-9.087
				c0.11-0.117,0.217-0.287,0.363-0.3c1.856-0.164,3.646-0.665,5.46-1.001c1.4-0.26,2.664,0.922,3.706,1.904
				c0.775,0.73,1.523,1.367,2.641,1.372l-0.107-0.059c1.446,0.584,3.042,0.506,4.413,0.2c4.287-0.958,8.561-0.354,12.841-0.318
				c0.405,0.004,0.786-0.053,1.081-0.367L213.193,531.414z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(199)} fill={props.fillColors[199]} d="M231.914,422.958c2.618-0.405,4.436-1.287,6.478-0.632
				c1.911,0.614,2.654-0.337,2.706-2.161c0.068-2.366-0.402-4.778,0.594-7.073c0.12,0.021,0.289,0.012,0.421,0.078
				c3.522,1.739,5.13,4.246,3.863,8.335c-0.551,1.778,0.116,3.518,1.63,4.947c1.376,1.299,1.61,3.165,1.601,5.045
				c-0.013,2.59,0.68,3.224,3.23,3.287c2.301,0.057,4.621-0.255,6.896,0.433c0.611,0.185,1.141,0.293,0.867,1.041
				c-0.457,1.248-0.42,2.438-0.078,3.729c0.289,1.091-0.642,1.246-1.493,1.271c-3.187,0.091-6.396,0.137-9.484-0.692
				c-1.281-0.344-2.262-0.049-2.653,0.871c-1.25,2.941-3.936,2.695-6.311,3.101c-2.314,0.396-4.459-0.422-6.88-1.289
				c2.017-1.047,3.717-2.334,2.446-4.581c-0.704-1.242-1.626-2.463-2.926-3.396c-1.332-0.955-2.892-1.825-3.493-3.613
				c-0.454-1.35-0.29-1.864,1.207-1.332c2.036,0.725,3.76-0.23,5.384-1.192c1.5-0.887,1.34-2.886-0.163-3.811
				C234.686,424.663,233.615,424.004,231.914,422.958z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(200)} fill={props.fillColors[200]} d="M260.425,576.204c-2.101,1.646-2.066,1.914,0.212,3.139
				c1.103,0.593,2.143,1.302,3.212,1.958c1.104,0.939,0.251,1.316-0.52,1.517c-1.154,0.301-2.241,0.693-3.196,1.416
				c-2.495,0.905-4.047,2.97-5.83,4.749c-1.277,0.606-2.239-0.356-3.324-0.681c-4.887-1.462-9.608-1.804-13.761,1.934
				c-1.53,0.837-3.06,1.673-4.591,2.51c-0.985,0.337-2.045,0.639-2.771-0.354c-0.878-1.2,0.607-1.551,1.012-2.284
				c0.909-1.646,2.717-2.329,3.924-3.676c-1.939,0.79-3.593,1.921-4.741,3.758c-0.569,0.911-1.346,1.669-2.479,0.733
				c-1.067-0.88-0.153-1.453,0.382-2.113c0.685-0.846,1.297-1.751,1.859-2.718c-1.201,0.728-2.126,1.713-2.873,2.952
				c-0.314,0.521-0.793,1.359-1.694,0.646c-0.756-0.598-0.457-1.16-0.029-1.849c1.036-1.669,1.973-3.4,2.95-5.107
				c-0.314-0.245-0.326-0.556-0.197-0.898c1.183-0.759,1.568-3.22,3.784-1.771c7.625-4.992,15.745-5.804,24.577-2.757
				c-0.892-1.635,0.161-2.567,1.172-3.008c1.166-0.509,2.124-2.248,3.809-1.005c0.78,0.574,1.762,0.57,2.373-0.131
				c1.283-1.474,3.124-1.458,4.749-1.93c0.867-0.252,2.115-0.52,2.67,0.805c0.238,0.566,0.714,1.227,0.584,1.711
				c-0.2,0.744-1.091,0.412-1.672,0.427C266.689,574.264,263.5,574.953,260.425,576.204z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(201)} fill={props.fillColors[201]} d="M344.037,305.564c-0.745-1.889,0.207-3.288,0.232-4.79
				c0.096-5.58-1.027-10.989-2.137-16.387c-0.991-4.826-0.837-9.564,0.532-14.229c2.098-7.146,4.378-14.236,6.586-21.35
				c0.13-0.421,0.295-0.831,0.616-1.218c0.367,4.585,1.053,9.08,1.722,13.577c0.938,6.304,1.999,12.595,2.696,18.925
				c0.376,3.411,0.498,6.806-1.754,10.024C349.232,294.831,346.667,300.023,344.037,305.564z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(202)} fill={props.fillColors[202]} d="M429.562,528.905c0.444,2.37,2.63,1.855,4.248,2.542
				c-0.201,1.111-1.21,1.306-1.996,1.678c-2.732,1.293-3.267,2.219-2.684,5.07c0.863,4.22-0.529,5.121-3.129,6.615
				c-2.286,1.314-1.759,3.682-1.993,5.682c-0.373,3.191,0.539,6.485-0.903,9.62c-0.348,0.754-0.645,1.689-1.36,1.712
				c-0.806,0.023-1.534-0.644-1.849-1.583c-0.47-1.404-0.93-2.646-0.429-4.292c0.946-3.113-1.858-7.625-4.979-8.601
				c-3.681-1.148-4.172-2.166-2.887-5.972l-0.085,0.089c1.305-1.129,0.593-2.261-0.144-3.222c-0.734-0.958-1.471-1.86-1.671-3.104
				c0.561-0.509,1.227-0.241,1.818-0.317c1.422-0.184,2.311-0.683,1.723-2.339c-0.567-1.598,0.043-2.51,1.624-3.087
				c3.166-1.157,6.529-1.849,9.341-3.886c0.512-0.37,4.43,1.765,4.78,2.566c0.158,0.364,0.177,0.778,0.649,0.896L429.562,528.905z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(203)} fill={props.fillColors[203]} d="M194.792,545.559c1.363-0.717,2.768-1.412,4.128-2.185
				c1.377-0.782,2.381-1.808,2.215-3.601c-0.132-1.425,0.794-1.62,1.995-1.696c3.375-0.214,6.58,0.924,9.9,1.125
				c1.34,0.08,2.824,0.751,2.755-1.555c-0.019-0.636,0.505-0.416,0.847-0.484c2.005-0.405,4.083-0.54,5.791-1.916
				c1.066-0.859,1.486,0.175,1.633,0.884c0.773,3.749,3.665,4.137,6.685,4.331c3.021,0.195,5.624,1.417,8.132,3.02
				c1.427,0.91,0.993,1.812-0.01,2.358c-4.147,2.263-8.446,4.188-13.354,3.454c-1.869-0.278-3.25-0.505-4.738,1.402
				c-1.563,2.004-4.318,1.144-6.541,0.773c-3.953-0.658-7.438-2.58-10.807-4.655c-0.913-0.562-1.728-0.756-2.679-0.374
				C198.369,547.393,196.859,547.172,194.792,545.559z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(204)} fill={props.fillColors[204]} d="M304.771,278.634c0.569,2.257-0.152,4.119-0.335,5.94
				c-1.114,11.059-1.301,22.237-4.047,33.102c-1.201,4.752-1.087,4.696-5.22,2.266c-2.648-1.558-5.216-3.222-7.786-4.897
				c-1.313-0.855-1.638-1.667-1.142-3.152c2.562-7.67,5.728-15.023,10.215-21.812C298.986,286.251,301.707,282.587,304.771,278.634z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(205)} fill={props.fillColors[205]} d="M176.077,630.746c1.396-0.384,2.75-0.959,4.236-0.934
				c5.769-0.697,11.354-0.125,16.648,2.428l-0.109-0.081c2.164,1.265,4.329,2.529,6.493,3.794c5.991,3.5,9.493,9.172,12.854,14.919
				c0.945,1.616-1.204,1.864-1.734,3.038c-1.848-2.388-3.364-4.78-5.387-6.812c-7.877-7.918-17.533-10.979-28.388-9.927
				c-6.006,0.582-11.33,3.341-15.561,7.856c0.028,0.654-0.264,0.973-0.936,0.893c-2.504,0.427-3.229,2.852-4.827,4.296
				c-0.542,0.489-0.227,1.926-1.651,1.447c-1.324-0.445-1.783-1.355-1.581-2.679l-0.046,0.076c1.55-3.98,4.407-6.998,7.441-9.833
				c3.085-2.882,6.132-5.846,10.072-7.628C174.411,631.234,175.218,630.934,176.077,630.746z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(206)} fill={props.fillColors[206]} d="M268.644,651.437c0.407-0.401,0.813-0.803,1.221-1.204
				c3.223-6.842,8.261-11.952,14.839-15.61c2.766-1.538,5.473-3.2,8.754-3.402l-0.1,0.057c3.369-1.394,6.919-1.468,10.436-1.092
				c1.831,0.196,3.825-0.051,5.495,1.092l-0.1-0.053c4.843,1.076,8.287,4.345,11.853,7.465c1.976,1.729,3.664,3.721,5.521,5.555
				l-0.075-0.092c0.456,2.229,2.534,3.531,3.15,5.766c0.475,1.717-0.678,1.991-1.5,2.476c-1.166,0.688-0.918-0.618-1.311-1.122
				c-2.651-3.408-5.863-6.208-9.463-8.597c-5.729-3.801-11.968-5.83-18.856-5.125c-10.593,1.085-19.36,5.547-25.626,14.425
				c-0.754,1.069-1.458,3.423-3.269,0.838C268.222,652.668,268.1,652.495,268.644,651.437z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(207)} fill={props.fillColors[207]} d="M419.109,660.001c0.005,1.474,1.692,1.877,2.007,3.144
				c0.158,0.634,1.88,1.288,0.139,1.9c-1.167,0.411-2.184,2.656-3.739,0.508c-5.292-7.302-13.205-10.678-21.206-13.701
				c-3.254-1.23-6.977-0.502-10.446-0.282c-3.441,0.218-6.926,0.449-10.319,1.291c-1.468,0.364-2.859,0.869-4.151,1.616
				c-0.998,0.577-1.993,0.966-3.156,0.723c0,0.001,0.094,0.086,0.095,0.086c-0.742-1.393-2.243-1.748-3.494-2.249
				c-2.261-0.905-1.47-1.88-0.182-2.705c1.583-1.015,3.302-1.843,5.031-2.589c0.594-0.257,1.065-0.626,1.598-0.938
				c-0.146-1.066,0.63-1.457,1.397-1.604c6.309-1.208,12.624-2.38,19.033-2.938c0.562-0.049,1.103,0.133,1.648,0.244
				c3.291,0.541,6.047,2.729,9.416,3.039c0.808,0.581,1.743,0.899,2.661,1.253c1.781,1.988,3.836,3.57,6.455,4.309
				c0.219,0.102,0.423,0.227,0.626,0.356c1.572,1.768,3.174,3.512,4.878,5.159c0.87,0.841,1.494,1.832,1.738,3.036
				C419.128,659.772,419.119,659.887,419.109,660.001z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(208)} fill={props.fillColors[208]} d="M75.963,767.192c-1.172-3.542-1.767-6.688-2.255-9.853
				c-1.301-8.431-3.552-16.54-7.021-24.386c-1.754-3.968-2.399-8.39-1.784-12.883c0.245-1.785,1.091-3.141,2.21-4.31
				c1.641-1.714,4.426-0.977,5.817,1.674c2.617,4.987,4.299,10.279,5.204,15.887c0.802,4.967,0.68,9.908,0.329,14.837
				c-0.381,5.352-1.192,10.679-1.979,15.991C76.36,764.994,76.468,765.888,75.963,767.192z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(209)} fill={props.fillColors[209]} d="M121.991,649.434c0.369,0.593,0.738,1.187,1.148,1.846
				c-1.971,0.97-4.091,1.646-5.838,3.031c-0.584,0.464-1.016,0.11-1.611-0.249c-3.387-2.05-7.206-2.525-11.051-3.151
				c-3.083-0.501-6.168-0.112-9.245-0.727c-2.712-0.54-5.56-0.007-8.085,1.215c-4.045,1.957-8.026,4.054-11.968,6.212
				c-2.473,1.354-4.443,3.372-6.083,5.638c-1.292,1.785-2.527,2.097-4.291,0.65c-0.765-0.628-0.941-0.902-0.327-1.797
				c2.829-4.118,6.566-7.359,10.168-10.728c2.338-2.185,5.243-3.534,7.985-5.116c-0.226-0.867,0.283-1.316,0.96-1.652
				c2.24-1.108,4.408-2.4,6.947-2.774c1.501,0.588,3.059,0.8,4.667,0.689c4.53-0.312,9.002,0.502,13.506,0.702
				c1.069,0.246,2.17,0.332,3.225,0.646c3.494,1.319,7.007,2.604,9.81,5.223C121.936,649.205,121.963,649.318,121.991,649.434z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(210)} fill={props.fillColors[210]} d="M580.583,331.745c-0.272-2.788-2.159-3.636-5.13-2.629
				c-2.813,0.954-2.813,0.954-5.959-0.31c1.725-0.216,2.247-1.455,3.032-2.339c0.901-1.016,0.882-2.596,0.718-3.805
				c-0.156-1.144-1.62-0.562-2.48-0.575c-2.333-0.034-1.133-1.471-0.823-2.223c2.471-6.017,5.356-11.816,9-17.236
				c2.917-4.34,6.391-8.229,9.599-12.332c0.223-0.283,0.572-0.466,1.324-1.061c-3.206,8.815-2.771,17.639-4.242,26.173
				C584.668,320.944,583.255,326.396,580.583,331.745z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(211)} fill={props.fillColors[211]} d="M197.384,559.612c-0.06-0.731,0.41-0.839,1.043-0.907
				c2.934-0.317,5.53-1.792,8.356-2.51c1.523-0.387,2.473-1.642,3.277-2.973c0.667-1.103,1.586-1.687,2.945-1.136
				c1.991,0.809,4.084,0.415,6.131,0.411c1.195-0.002,2.417-0.305,2.811-1.731c0.139-0.501,0.346-1.023,0.889-0.95
				c2.386,0.321,4.913,0.03,7.056,1.484c1.293,0.878,0.921,1.554-0.227,2.259c-1.228,0.755-3.65,0.89-2.788,3.051
				c0.789,1.979,1.063,4.684,4.169,5.005c1.605,0.166,0.717,1.655,0.863,2.545c0.174,1.052-0.142,1.939-1.446,1.981
				c-0.957,0.031-1.916,0-2.873,0.025c-1.217,0.032-2.233-0.1-3.15,1.364c-0.939,1.501-3.022,1.241-4.644,0.694
				c-2.173-0.731-4.291-1.639-6.396-2.552c-0.872-0.379-1.731-0.839-2.676-0.79c-1.71,0.09-2.806-0.374-2.695-2.334
				c0.022-0.391-0.002-0.819-0.536-0.904c-0.657-0.104-1.022,0.349-1.396,0.76c-1.802,1.979-3.447,2.112-5.455,0.319
				c-0.722-0.645-1.444-1.224-2.348-1.577C197.626,560.886,197.328,560.365,197.384,559.612z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(212)} fill={props.fillColors[212]} d="M559.726,495.042c-1.32,0.439-2.643,0.876-3.963,1.319
				c-1.103,0.369-1.654,0.748-0.567,1.93c1.656,1.801,1.542,2.466-0.735,3.454c-2.199,0.955,0.001,2.544-0.92,3.931
				c-4.519-2.666-10.136-3.144-13.764-7.71c-1.988-2.501-5.478-2.996-8.098-4.786c-1.973-1.349-1.404-1.856,0.176-2.605
				c5.098-2.416,10.31-4.536,15.776-5.983c0.585-0.155,1.002-0.339,1.653,0.198c2.251,1.855,6.141,1.316,7.956-0.985
				c1.027-1.302,2.003-2.643,2.977-3.985c0.219-0.302,0.24-0.821,0.788-0.657c0.206,0.061,0.361,0.288,0.54,0.439
				c-1.529,1.115,0.869,2.825-0.864,4.294c-1.53,1.296-1.307,3.783-1.231,5.818c0.05,1.354-0.424,2.269-1.427,3.062
				c-0.494,0.39-1.573,0.496-1.093,1.5c0.47,0.979,1.226,0.184,1.864,0.141c0.297-0.021,0.602,0.07,0.904,0.111
				C559.707,494.698,559.717,494.87,559.726,495.042z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(213)} fill={props.fillColors[213]} d="M226.155,584.222c-0.024,1.685-0.809,1.871-2.103,1.072
				c-0.568-0.351-0.821-1.191-1.902-1.101c1.06,0.988,2.149,1.756,2.862,3.2c-1.086,0.046-1.422-0.904-2.056-1.103
				c-0.435,0.368-0.237,0.544,0.065,0.721c0.577,0.336,1.231,0.837,0.789,1.485c-0.451,0.661-1.074-0.065-1.608-0.244
				c-4.954-1.661-9.97-2.61-15.213-1.567c-2.551,0.508-5.064-0.331-7.605-0.437c-4.434-0.183-8.693,0.478-12.831,2.021
				c-0.454,0.17-0.868,0.412-1.655,0.016c2.495-3.125,6.304-3.486,9.496-5.006c-3.548,0.872-7.053,1.826-10.234,3.797
				c1.485-2.162,3.75-3.287,5.995-5.009c-2.354,0.384-3.519,2.068-5.136,2.976c-0.599,0.335-1.124,1.095-1.81,0.201
				c-0.467-0.608-0.265-1.192,0.219-1.719c3.194-3.477,6.657-6.595,11.048-8.532c0.724-0.319,1.103-0.139,1.603,0.374
				c1.143,1.169,2.428,0.782,2.921-0.453c0.74-1.851,2.051-1.907,3.597-1.835c0.398,0.019,0.799-0.02,1.199-0.022
				c3.377-0.025,4.739,1.397,4.67,4.82c-0.015,0.723-0.401,1.01-1.016,1.073c-1.018,0.105-2.042,0.163-2.796,0.221
				c1.701,0.151,3.676-0.354,5.429,1.517c0.607,0.648,2.883-0.267,4.397-0.467c-1.263-1.231,0.44-1.804,0.623-2.717
				c0.344-1.728,1.372-1.577,2.562-0.8c0.925,0.604,1.864,1.186,2.796,1.776c0.116-0.009,0.231-0.019,0.346-0.028
				c3.037,0.571,4.423,2.771,5.354,5.422C226.158,583.99,226.157,584.106,226.155,584.222z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(214)} fill={props.fillColors[214]} d="M470.216,662.906c7.229,2.062,11.666,7.901,16.958,12.575
				c0.35,0.309,0.784,0.797,0.673,1.549c-2.389,0.42-4.612,0.005-6.68-1.303c-0.368,0.198-0.744,0.2-1.127,0.05
				c-3.782-2.674-7.572-5.344-11.818-7.258c-7.502-3.382-15.239-4.734-23.535-3.717c-5.976,0.732-11.867,1.355-17.426,3.873
				c-3.231,1.463-6.189,3.305-8.901,5.56c-1.178,0.98-5.829,0.996-6.854-0.089c-0.715-0.756-0.274-1.281,0.5-1.648
				c0,0-0.091,0.087-0.09,0.087c1.915-0.29,2.693-2.104,4.079-3.112c6.081-4.427,12.763-7.454,20.028-9.281
				c0.516-0.13,0.978-0.477,1.464-0.723c0.067-0.711,0.48-1.143,1.116-1.4c2.662-0.044,5.304,0.421,8.012-0.031
				c1.329-0.221,2.555-0.82,3.903-0.867c0.235,0.002,0.468,0.041,0.694,0.099c3.858,1.023,7.754,1.759,11.783,1.464
				c1.714,0.948,3.547,1.603,5.399,2.229C469.305,661.268,470.5,661.429,470.216,662.906z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(215)} fill={props.fillColors[215]} d="M241.427,522.103c-4.028,0.095-7.824,0.552-11.462,2.086
				c-0.682,0.288-1.652,0.279-2.366,0.04c-4.788-1.607-10.144-2.312-12.285-8.096c-0.459-1.24-1.791-2.203-3.292-2.514
				c-0.602-0.124-1.115-0.427-1.083-1.06c0.033-0.657,0.756-0.384,1.146-0.577c2.812-1.392,4.125-4.619,3.173-8.313
				c-1.019-3.954,1.264-6.922,3.449-9.417c1.345-1.536,3.844,0.328,5.699,1.103c1.5,0.625,1.056,2.048,0.75,3.129
				c-0.449,1.591-1.14,3.119-1.802,4.642c-0.581,1.338-0.894,2.697-0.779,4.158c0.079,1.001,0.32,1.711,1.487,2.081
				c1.704,0.542,2.903,1.691,3.421,3.566c0.369,1.336,1.496,2.2,2.923,1.843c1.934-0.485,3.485,0.325,4.804,1.359
				C237.441,517.882,239.141,520.232,241.427,522.103z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(216)} fill={props.fillColors[216]} d="M70.377,626.355c3.612-1.587,7.204-3.222,10.842-4.747
				c4.498-1.886,9.247-2.084,14.045-1.965c4.188,0.104,8.402-0.196,12.5,0.997c0.604,0.176,1.218,0.341,1.791,0.594
				c1.662,0.732,1.937,1.22,0.282,2.713c-2.53,2.284-4.371,4.251-8.565,3.144c-4.006-1.057-8.506-0.351-12.754,0.572
				c-1.074,0.232-1.775,1.007-2.784,1.192c-0.821,1.619-1.912,2.035-3.381,0.757c-6.845,1.04-13.028,3.696-18.475,7.924
				c-2.491,1.934-2.293,2.044-4.441-0.175c-0.821-0.85-0.745-1.399-0.08-2.157c1.354-1.545,2.694-3.103,4.04-4.655
				c-0.24-0.467-0.25-0.998,0.113-1.325c1.65-1.489,3.455-2.75,5.636-3.35C69.638,625.738,70.076,625.915,70.377,626.355z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(217)} fill={props.fillColors[217]} d="M535.155,661.149c-0.774-0.384-1.549-0.767-2.313-1.145
				c-0.965-1.632-2.794-1.677-4.312-2.182c-7.704-2.564-15.572-3.418-23.558-1.561c-5.916,1.376-10.73,4.95-15.575,8.361
				c-2.322,1.635-4.75,3.185-6.475,5.518c-3.679-3.097-3.835-3.272-0.44-6.288c6.691-5.943,13.951-11.311,22.512-14.021
				c10.429-3.305,20.32-1.521,28.994,5.57c1.728,1.412,3.369,2.899,4.934,4.482c0.607,0.614,1.116,1.201,0.21,1.977
				c-0.666,0.568-1.315,1.42-2.101,0.201C536.567,661.342,535.73,661.549,535.155,661.149z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(218)} fill={props.fillColors[218]} d="M321.494,468.391c1.961,2.33,4.834,1.711,7.32,2.051
				c1.744,0.239,1.713,0.685,1.255,2.024c-0.519,1.521-1.751,2.088-3.044,2.292c-2.178,0.345-3.069,1.209-3.474,3.601
				c-0.742,4.386-2.974,7.324-8.406,7.252c-2.019-0.026-4.045-0.169-6.105,0.553c0.053-1.592,0.817-2.59,1.211-3.66
				c1.087-2.956-0.969-5.455-0.851-8.299c0.061-1.451-1.964-1.123-3.159-0.306c-2.687,1.84-5.397,3.62-8.595,4.504
				c-0.564,0.156-1.065,0.219-1.637,0.171c-0.082-0.948,0.474-1.519,1.016-2.069c3.125-3.173,6.234-6.318,8.572-10.204
				c0.888-1.475,3.164-0.926,4.819-1.238c4.104-0.775,7.322,1.571,10.767,3.112C321.35,468.157,321.453,468.229,321.494,468.391z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(219)} fill={props.fillColors[219]} d="M321.364,593.099c0.431,0.467,0.858,0.935,1.292,1.397
				c1.824,1.954,1.645,2.669-0.911,3.627c-0.84,0.084-1.481-0.203-2.057-0.857c-1.676-1.904-3.807-3.233-5.955-4.528
				c-2.83-2.836-6.669-4.047-10.214-3.634c-3.536,0.41-7.215,0.817-10.762,2.42c-3.705,1.675-6.239,4.584-9.51,6.613
				c-0.238,0.027-0.469-0.007-0.692-0.09c-1.015-1.507-0.749-2.663,0.711-4.007c3.602-3.312,7.749-5.629,12.352-7.267
				c3.197-1.137,6.506-1.267,9.044-1.615c-4.288,0.071-9.096,1.154-13.542,3.572c-1.481,0.806-2.569,1.006-3.559-0.69
				c-0.51-0.875-1.73-1.11-2.362-1.965l0.077,0.092c-0.023-0.235,0.046-0.581-0.083-0.69c-2.253-1.905-2.245-1.885-0.039-3.993
				c2.35-2.245,4.81-4.244,7.984-5.326c1.721-0.587,2.914-0.562,4.051,0.967c1.188,1.597,2.334,1.823,3.234-0.377
				c0.588-1.438,1.808-0.802,2.847-0.618c1.953,0.346,3.529,1.766,5.542,1.94c2.33,0.816,4.6,1.752,6.003,3.946
				c0.479,0.749,1.236,1.541,0.417,2.542c-0.402,1.077-0.402,1.077-1.866,0.751c-0.138,0.746,0.814,0.883,0.839,1.518
				c-0.041,0.052-0.075,0.136-0.13,0.151c-0.969,0.272-1.638-0.905-2.865-0.518c1.512,0.71,2.843,1.335,4.175,1.96
				c0.54-1.205,1.229-0.662,1.917-0.167c0.31,0.618,0.863,1.1,1.021,1.805c-0.008,0.115-0.017,0.23-0.025,0.346
				C318.878,591.806,320.234,592.323,321.364,593.099z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(220)} fill={props.fillColors[220]} d="M376.611,607.205c1.362-0.372,2.378-1.594,3.896-1.583
				c7.041-2.428,14.332-2.512,21.654-2.313c0.908,0.024,1.723-0.031,1.693,1.342c-0.031,1.436-1.042,1.294-1.816,1.23
				c-6.605-0.539-12.804,1.52-19.092,2.965c-0.808,0.186-1.763,0.246-2.602,1.25c4.369-1.371,8.6-2.215,12.817-3.119
				c2.579-0.553,5.149-0.842,7.775-0.805c1.248,0.019,2.455-0.098,2.43,1.754c-0.027,1.985-1.211,1.555-2.398,1.312
				c-0.28-0.058-0.598,0.069-0.945,0.118c0.644,1.228,2.652-0.31,2.821,1.554c0.095,1.043-0.631,1.36-1.492,1.497l0.081,0.085
				c-2.27-1.015-4.664-0.186-6.979-0.483c-0.672-0.086-1.708,0.492-1.846-0.824c-0.136-1.292,1.013-0.653,1.519-0.938
				c1.119-0.63,2.443-0.162,3.751-0.4c-3.411-0.168-6.716-0.203-10.006,0.947c0.69,0.854,2.916-0.86,2.396,1.355
				c-0.284,1.211,1.121,3.795-2.307,3.296c2.255,0.584,1.555,1.935,1.238,3.325c-4.299-2.113-8.728-2.859-13.416-1.75
				c-2.946,0.697-5.742,1.59-7.766,4.021c0.249,0.782,0.028,1.257-0.849,1.306c-0.976-0.1-1.833,1.992-2.496,0.719
				c-0.956-1.836-3.438-3.608-1.356-6.188c2.202-1.928,4.404-3.854,6.607-5.781C372.279,610.015,374.339,608.428,376.611,607.205z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(221)} fill={props.fillColors[221]} d="M132.791,741.05c-1.97-4.007-4.959-7.352-5.067-12.249
				c-0.078-3.515-0.709-7.174-0.573-10.628c0.246-6.272-0.39-12.653,1.678-18.846c2.105-6.305,2.436-12.853,1.409-19.459
				c-0.215-1.383-0.031-2.828-0.031-4.381c1.595,0.388,1.837,1.726,2.232,2.715c1.943,4.868,2.808,9.977,3.026,15.2
				c0.307,7.315-0.676,14.544-1.325,21.804c-0.281,3.142-0.557,6.335-0.499,9.489C133.739,730.154,132.718,735.533,132.791,741.05z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(222)} fill={props.fillColors[222]} d="M230.363,628.015c0.796,0.011,1.633,0.181,2.378-0.007
				c1.01-0.255,1.528-0.048,2.142,0.842c1.556,2.254,2.138,2.216,3.362-0.148c0.358-0.691,0.729-1.118,1.403-0.91
				c2.94,0.907,5.851,0.153,8.76-0.1c2.286-0.198,3.28,0.668,3.07,2.983c-0.062,0.689,0.025,1.457,0.277,2.098
				c0.471,1.199,2.095,1.273,2.834,0.149c0.298-0.452,0.5-1.015,0.583-1.553c0.301-1.95,0.943-3.389,3.533-2.517
				c-0.909-0.933-2.722-0.651-2.626-2.259c0.069-1.168-0.784-3.101,0.79-3.305c1.359-0.176,3.444-0.09,4.214,1.798
				c0.615,1.507,1.916,2.773,1.359,4.782c-0.119,0.429,1.053,2.439,2.662,2.375c2.622-0.104,3.734,2.191,5.543,3.366
				c0.749,0.487,0.088,1.032-0.292,1.337c-0.85,0.681-0.847,2.581-2.833,1.679c-3.384-1.535-7.034-2.249-10.663-2.997
				c-6.614-1.363-13.306-0.972-19.978-0.917c-6.364,0.051-12.583,1.146-18.462,3.603c-1.919,0.803-2.015-0.561-2.79-1.417
				c-1.048-1.159-0.371-1.851,0.486-2.556c2.157-1.774,4.437-3.328,7.175-4.105c0.514-0.146,0.956-0.546,1.431-0.829l-0.06,0.066
				c2.343,0.338,3.507,0.03,5.772-1.522L230.363,628.015z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(223)} fill={props.fillColors[223]} d="M262.474,571.746c0.535-5.355,1.029-10.299,1.522-15.243
				c0.023-0.237,0.037-0.475,0.059-0.712c0.351-3.802,0.367-3.829,3.541-1.938c5.736,3.415,11.816,5.991,18.352,7.375
				c3.656,0.773,7.384,1.184,11.106,0.499c1.07-0.197,1.299-0.009,1.569,1.021c0.948,3.601,0.573,3.375-2.649,4.956
				c-3.649,1.789-5.187-0.089-6.977-2.426c-0.447-0.585-0.646-1.605-1.746-1.258c-1.004,0.318-0.881,1.18-0.884,1.985
				c-0.006,1.434-0.733,2.777-0.526,4.248c0.101,0.711-0.202,1.277-0.941,1.444c-0.792,0.179-1.378-0.116-1.754-0.878
				c-0.563-1.143-1.143-2.279-1.779-3.381c-0.861-1.491-1.714-1.832-2.595,0.046c-0.835,1.78-1.752,3.508-2.308,5.422
				c-0.196,0.675-0.62,2.204-0.919,1.919c-0.749-0.716-2.035-0.625-2.437-1.926c-0.531-1.714-1.41-3.329-2.21-4.95
				c-1.444-2.925-1.459-2.907-3.658-0.363C265.964,569.062,264.899,570.778,262.474,571.746z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(224)} fill={props.fillColors[224]} d="M351.409,584.056c0.497-0.234,0.988-0.486,1.495-0.699
				c1.127-0.473,1.935-1.035,1.495-2.523c-0.216-0.732-0.132-1.607,0.015-2.38c0.177-0.934,0.945-1.411,1.817-1.688
				c0.835-0.265,1.111,0.457,1.409,0.924c0.555,0.869,1.077,2.163,2.18,1.771c1.228-0.438,1.698-1.782,1.662-3.182
				c-0.056-2.167,1.131-3.014,3.154-2.174c0.945,0.393,1.65,0.488,2.662-0.019c1.682-0.844,3.398,0.134,4.913,0.731
				c4.854,1.916,9.167,4.708,12.567,8.757c0.255,0.304,0.601,0.575,0.734,0.926c0.23,0.599,0.326,1.283-0.219,1.78
				c-0.641,0.585-0.987-0.187-1.398-0.437c-0.775-0.47-1.489-1.043-2.473-1.275c0.798,0.658,1.592,1.321,2.395,1.974
				c0.413,0.336,1.182,0.639,0.753,1.245c-0.494,0.699-0.938-0.076-1.351-0.365c-0.308-0.216-0.589-0.471-1.187-0.955
				c0.231,1.404,1.854,1.515,1.582,2.849c-1.544,0.195-2.763-0.858-4.158-1.217c-7.938-2.036-15.619-1.389-23.112,1.916
				c-0.936,0.413-1.965,1.465-3.022-0.079c-0.94-1.373-1.179-2.161,0.692-3.002c2.037-0.917,4.147-1.667,6.138-2.708
				c-2.333,0.64-4.661,1.284-6.8,2.458c-0.646,0.354-1.225,1.174-2.061,0.429c-0.843-0.75-0.928-1.619-0.198-2.523
				C350.883,584.225,351.095,584.11,351.409,584.056z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(225)} fill={props.fillColors[225]} d="M268.895,609.42c-0.942,0.557-1.886,1.113-2.828,1.67
				c-0.903-0.044-1.763-0.132-1.452-1.418c-0.76-0.854-0.781-2.727-2.738-1.943c-0.274,0.109-0.646-0.135-0.598-0.538
				c0.062-0.519,0.452-0.763,0.895-1.006c3.48-1.916,7.03-3.609,11.094-3.839c0.292-0.017,0.58-0.154,0.861-0.257
				c0.051-0.019,0.067-0.138,0.218-0.478c-4.307,0.525-8.262,1.556-11.877,3.76c-1.218,0.743-2.835,2.512-3.824,1.054
				c-0.803-1.185,1.517-2.079,2.596-2.829c1.527-1.061,3.301-1.768,4.866-2.81c-3.113,1.18-6.121,2.606-8.517,4.971
				c-0.754,0.744-1.529,0.771-2.069,0.397c-0.62-0.429,0.485-0.805,0.49-1.337l-0.094,0.074c1.22-1.442,2.095-3.187,3.761-4.243
				c0.444-0.281,1.057-0.557,0.902-1.251c-0.148-0.664-0.884-0.572-1.321-0.875c-0.572-0.396-1.496,0.221-1.894-0.663
				c-0.881-0.53-0.564-1.053,0.01-1.573c1.456-2.892,4.186-4.313,6.787-5.884l-0.091,0.066c2.375-1.402,4.966-1.774,7.599-1.19
				c3.341,0.741,6.723,1.449,9.827,2.954c0.865,0.42,2.738,0.444,1.783,2.348c-0.967,1.927-1.932,0.272-2.892-0.037
				c-1.994-0.738-3.888-1.854-5.806-1.725c1.738-0.3,3.394,1.047,5.294,1.398c1.83,0.428,2.114,2.202,3.003,3.449l-0.1-0.036
				c0.478,0.356,0.916-0.082,1.377-0.066c0.249,0.005,0.485,0.058,0.713,0.156c1.206,0.589,2.418,1.165,3.596,1.81
				c0.688,0.377,1.571,0.804,1.249,1.746c-0.342,1.002-1.458,0.891-2.249,0.848c-1.358-0.072-2.319-1.267-3.628-1.578
				c-3.594-1.514-7.359-0.537-10.599-0.73c3.141,0.118,6.77-0.609,10.307,0.516c0.633,0.676,2.304,0.423,2.155,1.466
				c-0.174,1.212-1.743,0.694-2.654,1.065c-1.705,0.691-3.044-0.583-4.487-0.857c0.646,0.311,1.63,0.193,2.093,1.45
				c-3.091,1.027-6.088,2.217-9.006,3.604c-1.007,0.479-2.25-0.641-3.283-0.294c2.68,0.038,2.834,0.315,0.991,2.123
				C269.187,609.054,268.993,609.193,268.895,609.42z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(226)} fill={props.fillColors[226]} d="M140.949,768.482c-7.537-8.674-15.509-16.463-20.097-26.82
				c-2.294-5.178-4.246-10.351-3.663-16.165c0.146-1.452,0.206-3.244,1.352-3.839c0.998-0.518,1.763,1.261,2.64,2.005
				c2.137,1.813,3.079,4.464,4.279,6.832c3.844,7.588,8.313,14.885,11.308,22.876C138.554,758.136,139.878,763.066,140.949,768.482z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(227)} fill={props.fillColors[227]} d="M128.83,552.486c-1.001,0.177-1.959,0.605-2.131,1.641
				c-0.196,1.184,0.987,1.454,1.758,1.97c2.095,1.405,4.496,1.78,6.915,2.095c0.879,0.114,1.693,0.303,2.28,1.354
				c-2.164,0.312-4.152,0.772-6.273,0.721c-2.525-0.06-5.082-0.316-7.591,0.396c-0.665,0.188-1.429,0.06-2.146,0.026
				c-5.108-0.238-5.542-0.866-3.986-5.729c0.119-0.37,0.21-0.75,0.346-1.114c0.234-0.628,0.352-1.312-0.204-1.751
				c-0.634-0.503-1.119,0.161-1.593,0.479c-0.394,0.263-0.765,0.592-1.063,0.958c-1.105,1.355-2.082,2.13-3.433,0.133
				c-0.778-1.149-2.254-1.113-3.532-0.966c-2.172,0.25-3.37-0.68-4.101-2.708c-0.395-1.098-0.602-2.582-2.219-2.816
				c-0.118,0.039-0.236,0.08-0.354,0.12c-0.457,0.186-0.938,0.184-1.417,0.172c-0.638-0.017-1.434,0.045-1.599-0.725
				c-0.186-0.866,0.75-0.894,1.254-1.162c2.003-1.065,4.119-1.48,6.427-1.529c2.887-0.061,5.624-0.899,7.862-2.955
				c1.413-1.297,2.815-1.726,4.579-0.217c1.313,1.124,3.165,1.823,4.886,1.716c2.463-0.152,4.224,1.043,6.086,2.232
				c0.156,0.195,0.25,0.424,0.297,0.666c0.105,0.731,0.027,1.404-0.646,1.878c-1.761,1.239-1.784,2.553-0.204,3.979
				c0.233,0.211,0.147,0.589,0.028,0.894C128.981,552.324,128.905,552.405,128.83,552.486z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(228)} fill={props.fillColors[228]} d="M219.565,605.275c4.34-1.146,8.698-2.044,13.183-2.615
				c5.164-0.657,10.095,0.018,15.076,0.933c2.209,0.406,4.49,0.673,6.68,2.139c-2.665,1.461-4.862,1.318-7.47,0.356
				c-6.394-2.359-12.771-0.592-19.07,0.896c-2.477,0.585-5.002,1.065-7.311,2.229c8.735-1.837,17.298-5.28,26.463-2.532
				c0.747,0.224,1.537,0.411,1.897,1.238c-2.264,1.14-4.467,1.384-7.082,0.957c-4.186-0.684-8.423,0.206-12.572,1.229
				c4.007-0.879,8.061-0.659,12.104-0.679c0.718-0.003,1.469-0.073,2.146,0.107c1.207,0.323,1.006,1.014,0.264,1.718
				c0.344,2.059-0.832,2.377-2.475,2.159c-0.68,0.7-2.779-0.271-2.231,1.994c0.109,0.45-0.983,0.567-1.624,0.641
				c-1.094,0.124-2.229,0.186-3.252,0.545c-1.022,0.358-1.941,0.552-2.451-0.526c-0.742-1.571,1.358-0.678,1.611-1.787
				c-2.088,0.335-4.05,1.205-6.081,0.37c0.451,0.232,0.882,0.527,1.358,0.685c1.067,0.353,0.541,0.952,0.434,1.647
				c-0.198,1.288-0.943,0.36-1.375,0.404c-0.568,0.058-1.117-0.646-1.763-0.141l0.076,0.063c-0.146-1.24-1.305-0.921-1.997-1.166
				c-0.741-0.262-1.449-0.251-1.883-1.137c-0.522-1.066-1.04-1.236-1.688,0.13c-0.753,1.587-2.393,0.977-3.593,0.714
				c-1.07-0.235-1.191-1.156-0.28-1.963c-1.004-0.781-0.984-2.072-1.531-3.053c-1.044-1.874-0.436-2.921,1.288-3.751
				c1.122-0.541,2.371-0.88,3.229-1.886C219.647,605.193,219.565,605.275,219.565,605.275z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(229)} fill={props.fillColors[229]} d="M392.637,643.695c-3.43-0.146-6.778,0.579-10.139,1.051
				c-3.76,0.529-7.479,1.359-11.214,2.059c-2.825,0.104-5.411,1.196-7.311,3.073c-2.005,1.98-3.663,1.686-5.784,0.319
				c4.741-4.169,10.104-7.05,16.056-8.979c6.012-1.948,12.19-2.199,18.377-1.84c6.074,0.353,11.755,2.341,17.153,5.163
				c7.184,3.755,12.185,9.461,15.425,16.819c0.379,0.861,0.798,1.519-0.434,2.021c-1.03,0.421-2.038,1.233-2.801-0.311
				c-0.649-1.314-1.507-2.417-2.856-3.07l0.089,0.088c-2.386-2.793-4.972-5.384-7.768-7.766l0.092,0.077
				c-2.105-1.73-4.154-3.55-6.797-4.464c-0.708-0.276-1.416-0.554-2.125-0.83C399.373,645.697,396.205,644.113,392.637,643.695z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(230)} fill={props.fillColors[230]} d="M111.452,644.894c-0.781,0.017-1.562,0.033-2.343,0.049
				c-5.377-1.378-10.806-2.4-16.391-2.145c-0.814,0.038-1.726,0.036-2.389,0.713c-2.739,0.313-5,1.941-7.547,2.75
				c-4.274,0.553-7.237,3.456-10.252,6.071c-3.167,2.747-6.121,5.789-8.452,9.319c-0.923,1.397-1.792,0.859-2.813,0.356
				c-1.317-0.648-0.798-1.365-0.245-2.29c2.219-3.715,3.804-7.85,7.15-10.809c1.171-1.035,2.448-1.95,3.678-2.921l-0.012,0.007
				c3.927-2.031,7.579-4.578,11.917-5.825c15.563-4.473,29.772-1.942,42.616,7.989c0.248,0.192,0.553,0.311,0.81,0.493
				c0.187,0.134,0.339,0.315,0.559,0.526c-1.883,1.841-3.805,1.259-5.746,0.255l0.093,0.083c-2.832-2.293-6.103-3.562-9.674-4.107
				C112.005,645.348,111.624,645.343,111.452,644.894z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(231)} fill={props.fillColors[231]} d="M26.368,555.386c0.484,0.813,1.111,1.479,1.966,1.909
				c2.11,1.376,4.668,1.118,6.94,1.903c0.084,0.135,0.21,0.259,0.247,0.406c1.072,4.292,1.274,4.444,5.975,4.504
				c0.637,0.008,1.273,0.035,1.91,0.045c0.401,0.006,0.765-0.013,0.861,0.545c0.308,1.789-1.961,3.977-3.706,3.565
				c-0.691-0.163-1.397-0.262-2.096-0.39l0.08,0.06c-0.584-0.514-1.143-0.99-1.578-1.706c-0.758-1.247-1.495,0.236-2.186,0.399
				c-0.895,0.21,0.306,0.322,0.383,0.687c0.263,1.245-0.188,2.344-0.428,3.493c-0.223,1.066-0.431,2.136-0.682,3.389
				c-2.56-1.572-5.987-1.519-7.329-5.097c-0.32,1.817,0.543,2.796,1.717,3.332c2.668,1.219,0.344,2.399-0.001,3.487
				c-0.543,1.71-2.379,1.238-2.92,0.613c-1.662-1.922-4.375-2.481-5.728-4.744c-0.332-0.556-0.635-1.025-0.705-1.683
				c-0.502-4.742-0.991-9.488-1.585-14.22c-0.155-1.232,0.222-1.604,1.366-1.502c1.2,0.107,2.348,0.027,3.467-0.573
				C24.232,552.792,25.662,553.229,26.368,555.386z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(232)} fill={props.fillColors[232]} d="M336.928,436.206c-1.255,0-2.31-0.279-3.114,0.05
				c-2.875,1.179-3.825-0.338-4.748-2.717c-3.82-9.855-8.892-19.054-15.163-27.562c-2.254-3.058-4.626-6.061-7.477-8.605
				c-1.128-1.007-1.159-1.722,0.013-2.628c0.497-0.384,0.93-0.882,1.305-1.393c1.564-2.128,2.406-2.077,4.15-0.034
				c5.191,6.083,10.057,12.392,14.167,19.269C330.538,420.079,333.956,428.042,336.928,436.206z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(233)} fill={props.fillColors[233]} d="M330.429,543.277c-0.312-0.113-1.183,0.42-0.769-0.543
				c0.559-1.302,1.188-2.586,1.926-3.792c0.365-0.595,0.716-1.034,0.2-1.68c-0.703-0.881-1.45-0.194-1.842,0.178
				c-0.963,0.919-1.365,1.169-2.479-0.098c-1.155-1.311-3.186-0.559-4.802-0.411c-1.552,0.142-3.063,0.711-4.597,1.075
				c-0.913,0.217-2.001,0.617-2.628-0.268c-0.573-0.809,0.106-1.708,0.614-2.402c1.919-2.624,3.893-5.209,5.851-7.804
				c0.192-0.255,0.501-0.451,0.612-0.73c1.637-4.112,5.086-4.293,8.735-4.068c0.479,0.03,1.017-0.054,1.336,0.37
				c1.836,2.44,3.876,1.332,5.923,0.383c0.854-0.396,1.701-0.827,2.594-1.107c0.656-0.205,1.341-0.327,1.833,0.545
				c1.003,1.775,2.473,1.486,3.883,0.579c1.332-0.856,2.538-1.909,3.876-2.754c0.478-0.302,1.128-1.345,1.731-0.328
				c0.383,0.643,0.417,1.758-0.456,2.133c-3.31,1.422-5.891,4.046-9.197,5.451c-0.722,0.307-1.408,0.787-2.158,0.919
				c-1.293,0.228-2.487,0.726-2.383,2.105c0.124,1.645,1.741,1.082,3.086,1.254c-0.566,0.714-0.986,1.244-1.407,1.774l0.146-0.068
				c-1.679,0.05-2.73,1.121-3.131,2.521c-0.634,2.218-2.284,3.318-4.013,4.461c-0.98,0.647-2.115,1.16-2.564,2.39L330.429,543.277z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(234)} fill={props.fillColors[234]} d="M345.551,518.312c0.07,0.379,1.007,0.769,0.219,1.097
				c-1.491,0.623-2.417,2.274-4.382,2.12c-0.933-0.073-1.989,0.467-2.875,0.942c-1.955,1.049-3.532,1.729-5.776-0.015
				c-1.856-1.44-4.567-0.547-7.143-0.234c0.477-2.013,1.479-3.608,2.755-5.065l-0.041,0.06c0.783-0.56,1.792-1.123,1.893-2.067
				c0.261-2.441,1.928-3.047,3.896-3.4c2.414-0.434,3.952-1.642,4.881-4.096c1.449-3.825,5.389-5.863,9.421-5.428
				c0.868,0.094,1.759-0.032,2.633,0.032c0.762,0.058,1.892-0.349,2.198,0.38c0.396,0.938-0.802,1.405-1.362,2.002
				c-0.644,0.684-1.476,1.186-2.19,1.807c-0.805,0.7-1.55,1.508-1.047,2.672c0.447,1.034,1.436,1.106,2.439,1.112
				c2.932,0.019,5.487-1.098,7.938-2.556c0.434-0.258,0.817-1.064,1.356-0.494c0.42,0.444,0.401,1.238-0.146,1.734
				c-3.453,3.134-6.87,6.358-12.124,5.659c-0.549-0.073-1.118,0.021-1.675-0.015c-1.115-0.07-2.206,0.085-2.495,1.315
				c-0.291,1.243,0.289,2.161,1.63,2.446l-0.221-0.04L345.551,518.312z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(235)} fill={props.fillColors[235]} d="M215.627,660.783l0.035,0.001c-0.632-0.614-0.892-1.284-0.362-2.106
				c1.066-1.656,1.88-3.493,3.463-4.776c0.928-0.394,1.771-1.301,2.902-0.488c2.993-1.156,5.312-3.572,8.497-4.414
				c5.754-1.522,11.464-2.102,17.498-1.524c8.76,0.838,15.396,5.114,21.361,11.014c0.642,0.635,1.657,1.246,0.564,2.319
				c-0.959,0.942-1.727,0.95-2.755-0.133c-1.606-1.689-3.703-2.644-6.024-3.026c0.002,0.001,0.094,0.08,0.096,0.082
				c-2.793-1.946-6.105-2.442-9.312-2.834c-4-0.488-8.053-0.81-12.111-0.564c-7.561,0.455-14.903,1.658-21.161,6.371
				C217.32,661.455,216.518,660.768,215.627,660.783z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(236)} fill={props.fillColors[236]} d="M309.715,659.21c0.912,1.478,2.549,1.784,3.968,2.422
				c5.266,2.367,9.321,6.173,13.013,10.52c1.506,1.773,2.087,2.994-0.424,4.143c-0.407,0.316-0.797,0.234-1.181-0.049
				c-1.035-0.865-2.238-1.514-3.195-2.481c-0.996-2.345-2.38-4.247-5.049-4.932c-1.349-0.345-2.06-1.682-3.187-2.433
				c-3.691-2.458-7.715-3.625-12.134-3.492c-1.035-0.246-2.071-0.479-3.117-0.668c-3.944-0.484-7.887-1.067-11.802,0.185
				c-1.777,0.023-3.464,0.559-5.178,0.934c-3.389-0.891-5.944,0.553-8.287,2.83c-1.754,1.705-3.941,2.934-5.517,4.855
				c-0.194,0.134-0.393,0.257-0.593,0.38c-1.878,0.822-3.595,1.854-4.931,3.469c-0.764,0.923-1.697,1.431-2.825,0.474l0.096,0.092
				c-1.729-1.155-1.402-2.269-0.207-3.729c6.51-7.959,14.228-14.452,24.606-15.887c7.414-1.025,15.076-0.392,22.311,2.341
				c0.722,0.272,1.427,0.725,2.268,0.552C308.943,658.494,309.409,658.622,309.715,659.21z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(237)} fill={props.fillColors[237]} d="M315.081,508.97c0.102-0.138,0.203-0.275,0.305-0.413
				c1.534-0.743,2.138-2.144,1.519-3.538c-0.576-1.296-0.053-1.476,0.872-1.666c3.441-0.711,6.889-1.124,10.375-0.239
				c2.421,0.613,4.84,0.912,7.229-0.216c1.501-0.708,2.679,0.298,3.679,1.079c1.203,0.939-0.456,1.561-0.616,2.396
				c-0.608,3.178-2.823,4.425-5.797,4.932c-3.25,0.554-3.072,3.791-4.334,5.907c-0.004,0.005,0.048-0.067,0.042-0.062
				c-2.047-0.213-2.354,1.572-2.908,2.782c-0.738,1.611-1.434,2.744-3.519,2.53c-1.003-0.104-2.078,0.498-3.401,0.856
				c0.787-1.1,1.333-2.021,2.036-2.801c0.739-0.819,0.957-1.761,0.479-2.621c-0.488-0.878-1.514-1.172-2.516-0.99
				c-1.936,0.353-3.851,0.813-5.784,1.179c-0.825,0.155-1.929,0.478-2.429-0.178c-0.586-0.769,0.272-1.598,0.707-2.335
				C312.331,513.347,314.392,511.58,315.081,508.97z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(238)} fill={props.fillColors[238]} d="M117.137,627.907c3.614-1.314,7.209-2.673,10.953-3.611
				c6.066-1.521,12.109-1.183,18.058,0.393c4.636,1.227,9.349,2.43,13.198,5.587c0.599,0.491,1.917,0.59,1.214,1.811
				c-0.614,1.067-1.58,1.393-2.873,0.965c-2.556-0.846-5.053-1.895-7.751-2.286c-5.279-0.767-10.537-0.92-15.876-0.409
				c-4.032,0.386-8.028,0.65-11.935,1.854c-3.647,1.124-6.82,2.864-9.334,5.79c-1.183,1.376-5.138,1.264-6.362-0.074
				c-0.943-1.03,1.077-1.504,0.478-2.478c-0.553-1.049-0.064-1.858,0.649-2.563c2.106-2.082,4.359-3.982,7.082-5.22
				C115.472,627.286,116.417,626.695,117.137,627.907z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(239)} fill={props.fillColors[239]} d="M117.137,627.907c-4.199,1.444-7.442,4.184-10.23,7.541
				c-0.205,0.122-0.54,0.204-0.594,0.374c-0.535,1.693-1.433,2.531-3.308,1.719c-0.469-0.202-1.101-0.03-1.894-0.03
				c1.104-3.619,3.111-6.541,5.442-9.256c2.668-3.108,5.812-5.597,9.667-7.121c1.517-0.6,2.863-1.557,4.447,0.194
				c0.776,0.859,1.809,0.207,2.365-0.992c1.145-2.466,5.418-3.39,7.369-1.647c0.549,0.49,1.108,0.766,1.695,0.328
				c4.531-3.371,9.091-1.055,13.551,0.043c5.372,1.322,9.953,4.318,14.22,7.74c0.89,0.713,2.032,0.934,2.791,1.784
				c-0.053-0.092-0.494-0.229-0.045-0.269c0.616-0.056,0.875,0.365,0.797,0.974c-0.302,0.394-0.604,0.785-0.905,1.179
				c-0.915,0.671-1.504-0.152-2.098-0.545c-8.697-5.744-18.337-7.946-28.603-7.205c-4.465,0.322-8.685,2.101-12.848,3.758
				C118.268,626.749,117.739,627.42,117.137,627.907z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(240)} fill={props.fillColors[240]} d="M412.477,499.171c-2.506-0.18-4.692-0.287-6.866-0.515
				c-0.936-0.098-2.001-0.358-2.235-1.456c-0.277-1.301,0.962-1.628,1.762-1.812c0.977-0.228,1.808-0.61,1.799-1.526
				c-0.008-0.992-1.239-0.731-1.888-1.097c-0.597-0.338-1.375-0.71-1.989-0.599c-3.224,0.582-3.078-1.35-3.116-3.618
				c-0.052-3.094-0.03-5.999,1.729-8.803c1.426-2.272,3.316-2.812,5.727-3.081c2.642-0.295,4.42,0.344,5.466,2.944
				c0.558,1.385,2.045,1.33,3.327,1.285c1.579-0.056,2.604,0.698,2.988,2.155c0.402,1.524-0.697,2.067-1.892,2.534
				c-0.802,0.313-3.009,4.788-2.595,5.546c0.676,1.232,1.983,1.733,3.368,1.819c0.683,0.043,1.375,0.097,1.531,0.823
				c0.189,0.88-0.771,0.931-1.246,1.149c-1.136,0.521-1.912,1.23-2.479,2.38C415.043,498.972,414.382,499.198,412.477,499.171z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(241)} fill={props.fillColors[241]} d="M82.099,662.254c-3.442,1.104-6.303,3.302-9.31,5.166
				c-2.102,1.304-2.79-0.538-3.885-1.251c-1.389-0.903,0.271-1.392,0.643-1.911c2.204-3.076,4.918-5.563,8.393-7.226
				c2.979-1.425,5.806-3.171,8.789-4.589c3.519-1.674,7.231-1.796,11.096-1.322c3.672,0.448,7.389,0.542,11.054,1.167
				c1.973,0.335,3.785,1.07,5.565,1.917c0.571,0.271,1.397,0.951,0.687,1.382c-2.451,1.488-3.66,4.461-6.557,5.352
				c-0.12,0.036-0.24,0.073-0.36,0.109c-1.731-0.031-3.311-0.519-4.987-1.028c-4.747-1.445-9.68-2.909-14.466,0.104
				c-1.34,0.324-2.304,1.373-3.553,1.87C84.202,662.393,83.211,663.086,82.099,662.254z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(242)} fill={props.fillColors[242]} d="M264.281,693.509c0,0-0.025-0.028-0.026-0.029c-0.547,2.689-3.296,3.457-4.608,5.615
				c-3.552-5.318-8.94-7.483-14.292-9.807c-1.939-0.843-3.548-0.632-5.308,0.094c-4.862,2.003-9.73,4.008-13.143,8.287
				c-0.844,1.059-1.395,0.815-2.29,0.006c-1.239-1.121-1.409-3.103-3.377-3.741c4.146-3.694,8.242-7.137,13.008-9.684
				c1.741-0.931,3.579-1.694,5.265-2.713c2.342-1.415,4.469-1.076,6.832,0.064c5.901,2.849,11.269,6.447,16.111,10.839
				C263.008,692.943,263.603,693.271,264.281,693.509z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(243)} fill={props.fillColors[243]} d="M349.73,495.165c-0.41,2.306-1.193,4.968-4.447,5.979
				c-0.449,0.14-0.904,0.367-1.265,0.665c-2.051,1.7-4.098,2.139-6.411,0.395c-0.863-0.651-1.991-0.336-2.97,0.089
				c-1.912,0.83-3.848,0.804-5.776,0.108c-1.928-0.696-3.92-0.487-5.892-0.466c-1.311,0.015-1.828-0.088-1.318-1.704
				c0.834-2.648,2.357-4.279,5.023-5.062c3.134-0.919,5.862-2.43,6.921-5.871c0.135-0.438,0.404-1.029,0.763-1.173
				c2.979-1.191,5.56-2.963,7.911-5.117c0.447-0.409,0.853-0.173,1.123,0.355c0.828,1.616,1.703,3.156,0.607,5.071
				c-0.651,1.138-0.912,2.492-1.451,3.703c-0.388,0.871,0.08,1.332,0.608,1.839c0.565,0.542,1.123,0.437,1.726,0.048
				c0.662-0.428,1.346-0.827,2.037-1.208C348.961,491.694,349.78,492.203,349.73,495.165z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(244)} fill={props.fillColors[244]} d="M196.095,645.713c3.919,2.528,8.252,4.38,11.86,7.437
				c1.523,1.291,2.918,2.661,4.38,4.306c-1.79,0.693-2.963-0.582-4.201-1.044c-3.297-1.229-6.582-2.286-10.149-2.053
				c-1.308,0.086-2.379-0.307-3.528-0.987c-5.914-3.511-12.199-2.761-18.443-1.197c-3.338,0.836-6.466,2.303-8.662,5.114
				c-0.787,1.007-1.318,0.934-2.077,0.142c-0.547-0.57-1.095-1.112-1.839-1.477c-0.501-0.245-0.819-0.775-0.232-1.363
				c4.153-4.158,7.936-8.852,14.131-10.136c4.926-1.021,9.819-0.258,14.673,0.583c1.038,0.18,2.065,0.055,3.074,0.287
				c-0.337-0.01-0.315-0.242-0.106-0.314C195.592,644.796,195.987,645.054,196.095,645.713z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(245)} fill={props.fillColors[245]} d="M189.51,762.509c0.47-6.174,1.164-11.707,2.741-17.147
				c1.747-6.029,5.287-10.911,9.419-15.413c1.644-1.79,3.752-3.149,5.593-4.768c1.113-0.979,1.383-0.364,1.76,0.659
				c1.135,3.076-0.085,5.911-0.85,8.765c-1.881,7.023-6.074,12.778-10.45,18.388C195.264,756.145,192.806,759.323,189.51,762.509z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(246)} fill={props.fillColors[246]} d="M159.504,543.19l-0.002-0.062c-0.423,0.793-1.125,1.338-1.736,1.966
				c-0.313,0.323-0.922,0.524-0.712,1.11c0.244,0.679,0.907,0.607,1.436,0.502c4.443-0.887,8.98-1.542,12.542-4.757
				c0.462-0.547,0.924-1.094,1.386-1.641c1.471-1.679,2.915-0.808,4.105,0.255c1.198,1.069,0.935,2.287-0.113,3.521
				c-1.313,1.545-1.09,2.103,0.691,3.023c1.127,0.583,2.431,0.54,3.548,1.115l-0.081-0.099c1.114,2.494,0.872,3.236-1.607,4.43
				c-0.913,0.439-2.017,0.541-2.841,1.089c-0.906,0.601-2.822,0.175-2.331,2.539c0.17,0.819-2.091,1.274-3.25,1.146
				c-3.176-0.349-6.197-1.541-9.575-1.747c0.759-1.66,2.809-1.437,3.226-3.199c-2.29-0.386-4.502-0.355-6.673,0.031
				c-1.438,0.256-2.862,0.322-4.288,0.227c-1.258-0.085-2.688-0.187-2.483-2.09c0.116-1.08-0.959-0.861-1.405-1.325
				c-0.224,1.256-1.217,1.481-2.167,1.31c-1.618-0.294-3.316-0.564-4.389-2.078c-0.56-2.863-0.337-3.124,2.461-3.433
				c3.227-0.356,6.43-0.777,9.488-2.036C156.258,542.36,157.976,541.78,159.504,543.19z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(247)} fill={props.fillColors[247]} d="M438.562,659.09c-0.36,0.127-0.721,0.254-1.078,0.38
				c-3.212-0.161-6.012,1.296-8.93,2.292c-0.725,0.247-1.595,0.891-2.147,0.016c-0.552-0.874,0.389-1.45,0.93-1.976
				c1.633-1.586,3.678-2.452,5.782-3.225c6.74-2.475,13.518-4.89,20.822-4.716c8.642,0.205,16.514,3.25,24.131,7.057
				c1.523,0.761,1.815,1.814,0.629,3.172c-0.415,0.477-0.753,1.033-1.062,1.59c-0.592,1.07-1.222,1.405-2.44,0.702
				c-1.504-0.867-3.248-1.176-4.974-1.469c-2.457-0.875-4.905-1.743-7.353-2.611c-3.395-1.275-6.979-1.189-10.512-1.463
				c-0.538-0.042-1.115,0.15-1.593-0.262l0.122,0.036C446.762,658.264,442.663,658.694,438.562,659.09z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(248)} fill={props.fillColors[248]} d="M324.724,438.44c-1.438,0.263-2.044-0.379-2.587-1.731
				c-4.223-10.518-9.532-20.436-16.738-29.251c-1.355-1.658-2.832-3.223-4.323-4.763c-0.687-0.709-0.828-1.22-0.077-1.979
				c0.827-0.835,1.673-1.639,2.33-2.671c0.757-1.191,1.563-0.411,2.32,0.301c4.42,4.152,7.995,8.984,11.271,14.045
				c4.737,7.318,8.477,15.156,11.863,23.172c0.567,1.344,0.374,2.251-1.28,2.43C326.563,438.094,325.698,438.648,324.724,438.44z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(249)} fill={props.fillColors[249]} d="M442.158,598.1c2.334,1.289,5.14,1.665,7.174,3.741
				c-0.346,0.211-0.682,0.599-0.991,0.577c-3.422-0.232-6.661,1.321-10.325,0.048c-3.867-1.344-8,0.125-11.796,1.594
				c-1.898,0.736-3.69,1.789-5.444,2.843c-0.942,0.566-1.833,0.201-3.016,0.488c1.453-2.921,4.336-3.434,6.406-4.985
				c0.662-0.498,1.673-0.559,1.856-1.606c3.487-0.782,7.041-0.428,9.295-0.5c-2.25,0.112-5.806-0.307-9.29,0.496
				c-2.473,2.096-5.944,2.62-8.132,5.172c-0.306,0.356-0.751,0.399-0.989-0.033c-0.229-0.414-0.738-0.822-0.085-1.415
				c2.085-1.893,3.691-4.382,7.153-5.262c-1.625-0.323-2.706,1.143-3.844,0.041c-0.549-0.532-0.975-1.387-2.165-0.726
				c-0.458,0.253-1.502-0.521-0.945-1.608c-0.975-1.736,0.422-2.663,1.396-3.535c1.579-1.416,3.357-2.607,5.217-3.654
				c2.373-0.958,4.86-0.535,7.288-0.466c3.789,0.107,7.248,1.649,10.7,3.027c2.306,0.921,2.396,2.174,0.677,3.904
				c-0.267,0.268,0.107,1.014-0.111,1.524C442.177,597.876,442.167,597.988,442.158,598.1z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(250)} fill={props.fillColors[250]} d="M480.879,675.418c0.101,0.106,0.199,0.212,0.293,0.313
				c0.709,0.762,0.566,1.312-0.483,1.395c-1.959,0.156-3.922,0.091-5.895,0.295c-1.326,0.137-2.257-1.835-3.813-2.089
				c-0.123,0.032-0.247,0.065-0.371,0.098c-3.355-0.918-6.719-1.783-9.761-3.588c-3.554-2.108-7.482-2.456-11.539-2.475
				c-6.093-0.028-11.764,1.568-17.308,3.874c-1.625,0.676-3.18,1.437-4.63,2.463c-1.358,0.962-2.823,1.895-4.645,1.122
				c-0.541-0.067-1.082-0.135-1.623-0.203c-0.425-0.646-1.207-0.242-1.709-0.564c-0.565-0.364-1.61-0.029-1.722-0.969
				c-0.094-0.788,0.608-1.289,1.16-1.721c5.367-4.202,11.309-7.106,18.132-8.074c4.333-0.615,8.709-1.22,13.043-1.305
				c7.957-0.156,15.413,2.032,22.296,6.209C475.164,671.934,477.806,674.036,480.879,675.418z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(251)} fill={props.fillColors[251]} d="M196.095,645.713c-0.342-0.131-0.685-0.261-1.021-0.39
				c-1.75-1.039-3.741-1.243-5.691-1.483c-4.135-0.509-8.328-0.833-12.421-0.006c-4.936,0.997-8.596,4.24-12.006,7.738
				c-0.47,0.482-0.81,1.088-1.269,1.582c-1.585,1.703-2.594,1.627-4.176-0.142c-0.803-0.897-0.042-1.562,0.347-2.059
				c1.363-1.743,2.879-3.365,4.337-5.034c0.317-0.303,0.634-0.604,0.943-0.9c3.211-1.52,5.897-3.937,9.253-5.285
				c7.544-3.032,15.034-2.425,22.41,0.268c6.787,2.477,12.128,6.928,15.983,13.089c0.207,0.33,0.381,0.684,0.609,0.997
				c0.508,0.698,0.498,1.355-0.2,1.881c-0.815,0.614-1.144-0.271-1.512-0.651c-3.286-3.411-7.205-5.927-11.325-8.178
				C199.012,646.405,197.619,645.878,196.095,645.713z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(252)} fill={props.fillColors[252]} d="M526.904,474.799c0.75-0.857,1.405-1.639,2.098-2.385
				c0.405-0.438,0.697-0.881,0.475-1.497c-0.27-0.747-0.875-0.804-1.555-0.788c-1.269,0.027-2.538,0.008-3.957-0.185
				c1.924-1.171,2.522-3.065,3.105-5.37c1.115,2.222,3.094,2.08,4.676,2.246c1.613,0.17,2.297,0.901,2.701,2.178
				c0.878,2.771,2.704,4.331,5.598,4.685c0.849,0.104,1.725,0.156,2.322,0.992c1.489,2.088,3.611,2.648,6.065,2.472
				c1.01-0.072,2.038-0.157,3.035,0.377c0.649,0.348,1.928-0.287,2.104,0.629c0.188,0.983-1.048,1.346-1.717,1.967
				c-5.485,5.094-12.939,5.446-19.419,8.127c-0.766,0.316-1.621-0.043-2.442-0.577c-1.458-0.947-3.081-1.655-4.673-2.38
				c-1.079-0.491-1.441-1.322-1.105-2.324c0.385-1.151,1.255-0.503,1.984-0.32c1.76,0.442,3.549,0.784,5.281,1.312
				c0.612,0.188,1.06,0.254,1.51-0.175c0.479-0.454,0.451-0.966,0.123-1.515c-1.069-1.782-1.747-3.636-1.821-5.79
				c-0.044-1.295-0.666-2.664-2.601-1.715C528.205,475.001,527.502,474.799,526.904,474.799z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(253)} fill={props.fillColors[253]} d="M422.468,580.48c0.331,0.351,0.661,0.702,0.991,1.053
				c0.75,0.764,0.942,1.441-0.338,1.804c-1.662,0.471-3.17,1.219-4.483,2.347l0.091-0.061c-1.571,0.204-1.729,0.302-2.768,1.763
				c-0.089,0.125-0.13,0.28-0.184,0.426c-1.203,1.726-2.853,2.001-4.752,1.462c-3.774-2.336-9.592-1.907-13.009,0.96
				c-2.033,0.643-3.605,2.105-5.459,3.068c-0.884,0.25-1.568,1.411-2.733,0.307c-0.809-0.766-1.02-1.275-0.279-2.165
				c1.12-1.347,2.213-2.706,3.746-3.627c-1.524,0.916-2.638,2.245-3.716,3.614c-0.58,0.738-1.209,1.373-2.131,0.411l0.104,0.065
				c-0.961-0.562-1.242-1.038-0.363-2.116c2.589-3.182,5.513-5.951,8.971-8.155c0.5-1.871,2.492-2.955,4.249-2.305
				c0.013,0.004,0.009,0.115-0.02,0.152c-0.047,0.061-0.119,0.104-0.182,0.153c3.011-0.485,6.048-0.237,9.489-0.252
				c-1.031-0.646-1.781-0.291-2.464-0.386c-1.312-0.181-1.05-1.177-1.052-2.024c-0.001-1.298,0.723-0.816,1.431-0.63
				c0.525,0.14,1.122,0.139,1.665,0.055c2.032-0.317,3.284,0.566,3.475,2.537c0.188,1.938,1.709,1.497,2.79,1.887
				c0.074,0.026,0.158,0.024,0.237,0.035c-0.438-2.903,2.582-1.505,3.469-2.784c0.71-1.024,1.841-1.541,2.904-1.011
				c0.866,0.432,1.097,1.484,0.426,2.482C422.42,579.777,422.497,580.164,422.468,580.48z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(254)} fill={props.fillColors[254]} d="M307.858,256.638c-0.342,2.808-0.652,5.62-1.033,8.423
				c-0.413,3.039-1.009,6.058-1.286,9.107c-0.126,1.401-0.72,2.452-1.551,3.379c-8.804,9.821-15.084,21.061-19.03,33.625
				c-0.436,1.388-0.94,1.743-2.369,0.803c-3.171-2.087-3.279-2.009-1.502-5.394c3.431-6.538,6.988-13.012,10.356-19.581
				c4.083-7.961,7.992-16.012,12.049-23.986c1.138-2.238,2.531-4.346,3.807-6.514C307.486,256.546,307.672,256.592,307.858,256.638z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(255)} fill={props.fillColors[255]} d="M313.156,492.764c0.192-0.073,0.385-0.146,0.577-0.22
				c-2.442-0.939-1.499-5.397-5.435-4.857c1.714-1.053,3.363-1.294,5.061-1.294c2.708-0.001,5.31-0.048,7.79-1.633
				c1.567-1.003,3.664-0.219,5.499-0.15c3.127,0.116,6.295,0.212,9.51,1.467c-2.595,0.41-3.194,2.408-3.943,4.296
				c-0.821,2.072-2.76,2.895-4.587,3.491c-3.488,1.14-6.049,3.045-6.978,6.717c-0.321,1.272-1.08,1.536-2.318,1.85
				c-2.884,0.732-5.665,1.883-8.47,2.912c-0.635,0.232-1.338,0.481-1.798,0.022c-0.591-0.588,0.159-1.161,0.428-1.646
				c1.682-3.046,4.054-5.686,5.346-8.958c0.289-0.734,1.15-1.889-0.688-1.99C313.147,492.77,313.156,492.764,313.156,492.764z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(256)} fill={props.fillColors[256]} d="M144.137,548.472c1.696,0.569,3.389,1.158,5.214,0.754
				c0.177,0.241,0.416,0.459,0.543,0.729c0.437,0.924-0.419,2.526,1.057,2.813c1.581,0.307,3.141,1.007,4.862,0.532
				c2.199-0.605,4.528-0.175,6.802-0.967c-0.024,1.468-1.167,1.36-1.778,1.849c-0.363,0.29-0.936,0.682-0.918,0.999
				c0.029,0.497,0.494,0.941,1.08,1.112c1.06,0.312,2.113,0.545,3.242,0.517c0.79-0.019,1.818-0.406,2.256,0.888
				c-5.281,0.755-10.145-0.921-15.04-2.331c-1.518-0.438-2.995-0.908-4.616-0.829c-1.432,0.071-2.362,0.918-3.174,1.881
				c-0.507,0.602-0.744,1.443,0.418,1.807l-0.082-0.085c0.023,1.668,1.542,1.43,2.473,1.938c0.671,0.367,1.829,0.432,1.775,1.323
				c-0.051,0.826-1.177,0.503-1.79,0.765c-3.044,1.306-5.032-0.57-6.953-2.346c-1.586-1.466-3.269-2.368-5.467-2.479
				c-1.553-0.077-3.019-0.699-4.426-1.413c-2.382-1.207-2.43-1.335-0.788-3.443l-0.101,0.08c0.609-0.821,0.299-1.271-0.589-1.535
				c-0.598-0.18-1.211-0.271-1.795-0.551c-1.415-0.678-1.296-1.452-0.182-2.236c0.765-0.538,1.613-0.97,2.553-1.178
				c0.801-0.178,1.113-0.575,0.492-1.307l0.082,0.079c-0.675-0.778-2.353-0.652-2.117-2.212c0.097-0.646,3.312-1.073,4.246-0.437
				c0.992,0.677,2.125,1.097,3.063,1.83c0.812,0.635,1.614,0.913,2.64,0.531c1.483-0.553,2.997-0.173,4.481,0.048
				C143.207,545.839,143.37,547.442,144.137,548.472z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(257)} fill={props.fillColors[257]} d="M540.997,656.636c0.348,0.621,0.698,1.241,1.042,1.864
				c0.304,0.55,0.801,1.133-0.13,1.565c-0.678,0.314-1.194,0.846-2.023-0.114c-3.226-3.732-7.014-6.83-11.474-8.988
				c-10.421-5.044-20.766-4.493-30.677,1.198c-6.509,3.736-12.959,7.684-18.136,13.304c-0.327,0.354-0.728,1.061-1.36,0.538
				c-0.855-0.708-0.269-1.496,0.142-2.137c3.247-5.07,7.442-9.199,12.632-12.247c3.612-2.122,7.334-4.059,11.007-6.077
				c-0.067,0.038-0.139,0.071-0.203,0.116c-0.15,0.104-0.285,0.022-0.219-0.193c0.457-1.499,1.526-1.833,3.128-1
				c5.395-1.048,10.799-1.894,16.29-0.702l-0.092-0.07c0.377,1.009,1.336,0.64,2.038,0.827c6.271,1.683,11.967,4.283,15.935,9.736
				c0.173,0.239,0.499,0.367,0.753,0.546c0.117,0.003,0.233,0.007,0.351,0.01c1.156,0.186,0.773,1.251,1.095,1.913L540.997,656.636z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(258)} fill={props.fillColors[258]} d="M277.888,449.592c3.154-0.939,5.644-1.12,8.151-1.369
				c2.762-0.274,5.923,0.387,8.147-1.583c2.289-2.028,4.704-2.853,7.625-2.685c0.399,0.023,0.856-0.633,1.193,0.058
				c0.185,0.38-0.03,0.736-0.227,1.107c-1.672,3.156-4.323,5.434-6.951,7.718c-0.883,0.768-1.908,1.367-2.454,2.682
				c1.863,0.055,3.314-0.83,4.884-1.208c0.684-0.164,1.305-0.62,2.076-0.438c0.506,0.121,1.231-0.313,1.498,0.338
				c0.233,0.571-0.316,1.025-0.691,1.42c-2.845,2.991-6.205,5.32-9.709,7.453c-1.783,1.086-3.514,2.324-5.905,2.496
				c1.078-1.615,2.074-3.015,2.96-4.48c0.38-0.631,0.951-1.307,0.331-2.178c-0.663-0.933-1.617-1.09-2.543-0.841
				c-1.671,0.449-3.311,1.039-4.926,1.666c-1.748,0.679-3.547,0.725-5.371,0.664c-0.609-0.021-1.115-0.28-1.217-0.904
				c-0.09-0.552,0.359-0.866,0.816-1.025c2.288-0.795,3.662-1.796,3.76-4.798C279.386,452.124,280.535,450.836,277.888,449.592z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(259)} fill={props.fillColors[259]} d="M267.722,432.149c3.696-0.592,6.494,0.862,9.341,1.947
				c1.535,0.585,3.064,0.74,4.653,0.675c1.803-0.073,2.637-1.364,1.989-3.042c-1.149-2.977-3.814-4.343-6.315-5.848
				c-1.102-0.663-2.143-1.328-3.016-2.775c1.504,0,2.964-0.386,4.067,0.078c2.575,1.081,5.737,0.64,7.823,3.002
				c0.279,0.316,0.922,0.596,1.298,0.502c4.503-1.129,9.537,1.117,13.72-2.193c0.67-0.53,1.513-1.19,2.801-0.946
				c-3.991,3.215-7.845,6.315-11.695,9.422c-0.428,0.345-0.901,0.666-1.225,1.096c-0.267,0.354-0.583,0.951-0.452,1.262
				c0.197,0.466,0.763,0.543,1.389,0.502c2.821-0.188,5.28-1.575,7.943-2.262c1.51-0.389,2.88-1.441,4.941-0.932
				c-2.372,2.78-5.414,4.236-8.379,5.739c-2.486,1.26-5.278,1.307-7.926,0.95c-2.255-0.303-3.707,0.294-4.67,2.229
				c-0.676,1.356-1.704,1.602-2.606,0.532c-3.466-4.104-8.027-6.628-12.647-9.098C268.502,432.854,268.299,432.624,267.722,432.149z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(260)} fill={props.fillColors[260]} d="M137.85,699.944c1.885,4.251,2.505,8.484,3.039,12.672
				c1.354,10.631-0.205,21.229-0.576,31.846c-0.124,3.533,0.137,7.124,0.181,10.689c0.015,1.25,0.002,2.5-0.225,3.791
				c-1.367-3.844-2.609-7.694-4.498-11.269c-0.967-1.829-1.39-3.575-1.398-5.737c-0.025-6.297,0.38-12.576,0.686-18.843
				c0.351-7.163,0.735-14.353,1.776-21.474C136.923,701.013,137.255,700.649,137.85,699.944z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(261)} fill={props.fillColors[261]} d="M474.283,244.222c-1.005,3.882-1.654,7.545-2.098,11.264
				c-0.314,2.64-0.087,5.245-0.24,7.86c-0.052,0.895-0.041,1.687-0.853,2.334c-3.458,2.758-5.05,6.819-7.098,10.526
				c-2.262,4.094-4.104,8.428-7.638,11.692c-0.655,0.605-1.344,1.076-2.162,1.466C454.418,278.387,466.822,250.312,474.283,244.222z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(262)} fill={props.fillColors[262]} d="M531.69,456.325c0.629,0.368,1.242,0.602,1.696,1.013
				c1.841,1.663,3.65,3.213,6.078,0.906c0.452-0.429,1.071-0.306,1.608-0.253c3.332,0.326,6.368-0.342,9.181-3.026
				c-0.3,2.416-1.455,3.802-2.771,4.995c-1.505,1.364-2.509,3.045-3.346,4.818c-0.239,0.508-1.02,1.28-0.215,1.926
				c0.59,0.475,1.451,0.406,2.065,0.138c1.955-0.854,3.868-1.849,5.117-3.676c0.772-1.128,1.799-1.935,3.087-2.634
				c-0.438,1.136-0.867,2.273-1.314,3.404c-0.146,0.368-0.343,0.716-0.508,1.076c-0.665,1.453-0.462,3.085,0.543,3.994
				c0.911,0.825,1.502-1.081,2.728-1.296c0.526,1.875-0.899,3.254-1.217,4.86c-0.238,1.199-0.044,2.722-0.974,3.488
				c-0.966,0.797-2.236-0.377-3.443-0.315c-1.81,0.09-3.849,0.467-5.307-0.692c-1.021-0.812-1.816-2.185-3.12-2.335
				c-3.631-0.42-5.63-2.53-6.792-5.756c-0.103-0.285-0.33-0.667-0.575-0.736c-3.026-0.849-1.653-2.978-1.244-4.646
				c0.312-1.271,0.552-2.15-0.862-2.929C531.583,458.362,531.168,457.366,531.69,456.325z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(263)} fill={props.fillColors[263]} d="M473.341,263.548c0.191-9.88,1.025-19.317,7.169-27.379
				c0.873-1.146,1.65-2.047,2.864,0.023c2.321,3.962,4.645,7.892,6.085,12.281c0.458,1.396,0.451,2.326-1.166,3.176
				c-2.04,1.073-3.493,2.979-4.461,5.091c-0.674,1.47-1.685,2.259-3.146,2.907C478.249,260.729,475.957,262.136,473.341,263.548z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(264)} fill={props.fillColors[264]} d="M150.394,271.591c3.62,1.486,6.545,2.677,9.462,3.887
				c1.113,0.461,1.927,1.153,2.643,2.233c6.323,9.525,12.793,18.947,20.33,27.583c2.554,2.926,5.016,5.933,7.522,8.9
				c0.257,0.305,0.553,0.59-0.03,1.411C173.428,304.435,161.653,288.707,150.394,271.591z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(265)} fill={props.fillColors[265]} d="M197.665,676.942c-2.833-1.963-6.203-1.586-9.357-2.05
				c-1.978-0.291-4.033-0.054-6.064,0.202c1.215,0.99,2.704,0.264,4.127,0.823c-0.617,1.225-1.26,2.382-1.794,3.587
				c-0.935,2.108-2.146,2.716-4.156,1.844c-0.995-0.432-1.855-0.856-2.771,0.149c-0.208,0.229-0.752,0.234-1.133,0.21
				c-1.763-0.109-4.366,0.764-4.883-1.265c-0.396-1.555,2.248-2.036,3.643-2.83c1.875-1.066,4.056-1.191,6.795-1.902
				c-2.096-0.728-3.457,0.05-4.742,0.32c-2.269,0.477-4.375,1.488-6.028,3.195c-1.415,1.46-2.304-0.106-3.322-0.561
				c-1.473-0.657,0.285-1.191,0.185-1.83c0.042-0.122,0.085-0.245,0.128-0.368c0.695-1.021,1.688-1.605,2.784-2.153
				c7.772-3.885,15.906-5.451,24.538-3.993c1.73,0.292,3.404,0.813,5.039,1.438c3.311,1.264,6.418,3.079,9.982,3.65
				c0.725,0.839,2.362,0.991,2.006,2.649c0.169,0.914,1.152,1.05,1.585,1.631c0.615,0.824,2.676,1.168,1.258,2.876
				c-1.379,1.66-3.411,2.017-4.717,0.724c-2.588-2.563-5.633-4.318-8.997-5.661c-1.086-0.434-2.222-0.146-3.237-0.643
				C198.21,677.614,197.924,677.536,197.665,676.942z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(266)} fill={props.fillColors[266]} d="M40.499,734.201c-1.329-4.577-2.002-9.317-3.028-13.981
				c-1.103-5.013,0.321-9.661,1.227-14.458c0.368-1.943,1.396-4.368-0.956-6.096c-0.247-0.182-0.255-0.938-0.123-1.346
				c0.956-2.934,2.009-5.82,3.938-8.31c0.468-0.604,0.927-1.186,1.662-1.12c0.852,0.076,1.045,0.976,1.219,1.621
				c0.642,2.381,0.99,4.844,0.958,7.289c-0.073,5.648,0.156,11.296-0.16,16.955c-0.116,2.086-0.335,3.777-1.242,5.696
				c-1.092,2.311-1.379,5.025-1.26,7.663C42.834,730.374,42.84,732.563,40.499,734.201z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(267)} fill={props.fillColors[267]} d="M49.67,672.729c2.616,3.03,3.414,6.323,4.193,9.565
				c1.521,6.331,1.227,12.82,0.88,19.214c-0.334,6.149-1.292,12.271-1.334,18.453c-0.007,1.041-0.655,1.512-1.538,1.924
				c-1.02-1.987-1.857-4.004-4.526-4.326c-1.392-0.168-1.044-1.646-0.988-2.606c0.434-7.318,0.747-14.618,2.821-21.774
				c1.417-4.887,1.201-10.036,0.524-15.119C49.494,676.494,49.67,674.877,49.67,672.729z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(268)} fill={props.fillColors[268]} d="M221.666,653.413c-0.559,0.371-1.117,0.742-1.676,1.113
				c-1.46,1.422-2.982,2.792-4.287,4.354c-0.868,1.04-1.601,1.106-2.501,0.162l0.102,0.091c-0.63-0.803-0.362-1.406,0.154-2.251
				c2.798-4.578,6.595-8.122,11.003-11.079c-0.263-1.146,0.521-1.536,1.369-1.817c1.335-0.442,2.578-1.108,3.907-1.572
				c1.815,0.236,3.576-0.004,5.39-0.304c5.188-0.856,10.398-0.908,15.604,0.085c2.55,0.486,5.232,0.063,7.732,1.017
				c1.329,1.001,3.509,1.09,3.692,3.355c2.093,1.437,3.776,3.41,6.048,4.627c0.15,0.085,0.294,0.164,0.439,0.242
				c0.332,0.467,0.663,0.933,0.981,1.387c0.878,1.48,1.818,2.947,2.632,4.48c0.267,0.501,1.111,1.142,0.002,1.78
				c-0.865,0.499-1.267,0.254-1.962-0.443c-5.067-5.079-10.519-9.647-17.834-11.06c-8.011-1.547-16.016-1.584-23.801,1.168
				C226.007,649.688,223.461,651.057,221.666,653.413z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(269)} fill={props.fillColors[269]} d="M283.393,423.371c-2.58,0.034-3.54-1.249-4.367-3.222
				c-3.888-9.277-9.333-17.688-14.875-26.035c-2.693-4.056-5.384-8.113-8.384-12.632c3.071,1.133,4.96,3.056,6.94,4.71
				c6.294,5.259,11.903,11.218,17.155,17.515c2.188,2.622,2.077,6.055,3.259,9.03c0.171,0.429-0.082,0.896-0.479,1.266
				c-1.635,1.519-0.845,3.361-0.525,5.073C282.376,420.457,282.911,421.787,283.393,423.371z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(270)} fill={props.fillColors[270]} d="M70.377,626.355c-2.492,1.124-4.788,2.575-6.982,4.194
				c-1.975,0.588-3.033,2.186-4.22,3.694c-0.418,0.53-0.962,1.964-1.935,0.731c-0.689-0.871-3.052-1.077-1.386-3.191
				c3.06-3.885,6.689-7.156,10.429-10.339c0.732-0.624,1.325-0.027,1.688,0.257c0.954,0.746,1.978,1.331,3.012,0.62
				c1.203-0.828,0.519-2.118,0.036-3.588c1.999,1.426,3.707,1.451,4.283-0.733c0.82-3.111,3.375-3.634,5.745-4.105
				c2.215-0.439,3.459-0.985,2.604-3.483c-0.147-0.429-0.024-0.951-0.024-1.43c0.003-2.451,1.216-3.497,3.723-3.189
				c0.295,0.036,0.668,0.087,0.851,0.277c0.812,0.851,0.051,4.92-1.065,5.752c0.4,0.611,1.225,0.589,1.667,1.229
				c1.057,1.535,2.203,1.331,3.188-0.134c0.366-0.545,0.669-1.203,1.454-0.863c0.636,0.275,0.383,0.966,0.428,1.491
				c0.077,0.886-0.252,1.718-0.37,2.567c-0.109,0.787,0.312,1.649,0.551,2.702c-4.436-0.169-8.75,0.291-12.797,1.935
				C77.474,622.284,73.544,623.6,70.377,626.355z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(271)} fill={props.fillColors[271]} d="M101.856,547.174c0.936,1.701,2.047,3.319,2.528,5.291
				c-1.159,0.055-1.992-0.799-3.034-0.996c-1.524,0.856-2.814,2.002-4.089,3.185c-0.652,0.605-1.274,1.541-2.42,0.862
				c-1.53,0.662-2.578,0.753-3.438-1.237c-0.825-1.909-2.372-3.518-3.666-5.208c-0.412-0.538-1.008-1.354-1.74-0.922
				c-0.786,0.466-0.553,1.454-0.164,2.133c0.798,1.391,1.262,2.891,1.576,4.428c0.168,0.821,0.301,1.757-1.208,1.626
				c-4.092-0.354-8.195-0.581-12.295-0.845c-0.635-0.041-1.285,0.064-1.908-0.029c-0.815-0.123-1.946-0.16-2.186-0.964
				c-0.283-0.947,0.949-1.133,1.546-1.516c2.153-1.379,3.6-3.014,3.077-5.816c-0.078-0.416,0.467-0.948,0.724-1.428
				c-0.151-0.415-0.128-0.823,0.05-1.225c2.285-2.225,4.457-2.591,7.211-1.244c1.798,0.88,3.639,1.553,5.689,1.649
				c1.697,0.08,3.465-0.014,4.801,1.515c0.568,0.649,1.587,0.71,2.484,0.526c2.208-0.454,4.362,0.119,6.536,0.304L101.856,547.174z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(272)} fill={props.fillColors[272]} d="M326.25,453.851c1.266,1.107,2.811,0.839,4.285,0.836
				c1.258-0.003,1.281,0.437,0.732,1.412c-1.292,2.297-3.771,3.592-6.296,3.102c-1.14-0.222-1.511,0.709-1.995,1.304
				c-2.242,2.755-4.655,4.637-8.635,3.814c-2.004-0.414-4.253-0.495-6.366,0.24c-0.6,0.21-1.533,0.385-1.611-0.762
				c-0.175-2.566-1.516-1.641-2.667-0.847c-1.663,1.146-3.142,2.596-5.648,3.057c3.036-3.768,5.209-7.639,6.862-11.776
				c0.462-1.156,1.26-0.93,1.911-0.782c0.855,0.193,1.699,0.263,2.56,0.364c2.579,0.303,4.946,0.249,7.556-0.721
				c2.967-1.103,6.228,0.376,9.354,0.8L326.25,453.851z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(273)} fill={props.fillColors[273]} d="M96.99,585.886c-2.825,0.385-3.427-0.446-2.384-3.292
				c-0.101-0.218-0.113-0.441-0.038-0.67c1.294-1.174,2.677-2.273,3.291-4.011c0.171-0.196,0.38-0.335,0.616-0.439
				c0.679-0.201,1.397-0.271,2.008-0.697c5.605-3.912,11.52-2.899,17.484-1.093c0.073,0.1,0.145,0.2,0.218,0.301
				c-0.04,0.762,0.648,0.815,1.087,1.082c1.354,0.824,2.6,1.667,3.08,3.362c0.262,0.928,0.684,1.809,2.147,1.752
				c1.4-0.054,1.504,1.105,0.625,1.933c-1.545,1.454-3.487,2.447-4.904,4.081c-0.667,0.771-1.374,0.071-1.921-0.193
				c-2.335-1.134-4.655-2.261-7.189-2.921c-0.593-0.154-1.06-0.126-1.586,0.034c-0.104,0.035-0.208,0.07-0.312,0.106
				c-1.138,0.167-2.43,0.65-3.146-0.667c-0.506-0.932-0.815,0.037-0.861,0.085c-0.869,0.922-2.004,1.156-3.171,1.338
				C100.358,585.73,98.645,587.377,96.99,585.886z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(274)} fill={props.fillColors[274]} d="M336.613,485.115c-3.761-1.145-8.024-1.385-12.328-1.401
				c-1.075-0.004-1.599-0.565-0.995-1.88c0.645-1.4,1.363-2.804,1.348-4.496c-0.012-1.143,1.009-1.684,2.204-1.676
				c1.984,0.014,3.594-0.995,3.958-2.802c0.583-2.884,2.753-3.705,4.95-4.517c1.413-0.521,2.52-1.162,3.297-2.502
				c0.577-0.992,1.54-1.83,2.754-0.942c1.234,0.904,2.244,1.94,1.053,3.72c-1.042,1.556-2.451,2.714-3.736,4.008
				c-1.164,1.173-0.64,1.738,0.636,2.088c1.33,0.365,2.78,0.299,3.837,1.487c1.858,2.09,1.358,4.631-1.114,5.891
				c-1.18,0.601-2.265,1.437-3.28,2.298C338.525,484.959,337.849,485.191,336.613,485.115z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(275)} fill={props.fillColors[275]} d="M519.361,626.382c0.504,1.019,1.915,2.127-0.273,2.887
				c-0.771,0.267-0.147,0.455-0.015,0.762c0.766,1.758,0.045,2.234-1.699,1.785c0.271,0.676,1.685,0.908,0.774,1.949
				c-0.604,0.692-1.246,0.368-1.91,0.007c-1.717-0.933-4.067-1.243-3.506-4.225c0.056-0.296-0.438-3.272-2.749-1.712
				c-0.372,0.251-1.069,0.021-1.617,0.013c-0.61,0.387-1.014,0.14-1.31-0.431c-1.967-1.066-4.092-1.399-6.389-1.521
				c0.872,1.608,1.163,2.914-1.118,3.643c0.965,0.434,2.011,0.597,1.713,1.879c-0.497,2.148-2.313-0.268-3.061,0.941
				c0.846,0.807,2.899-0.532,2.893,1.018c-0.007,1.605-1.87,0.725-2.893,0.959c-2.629,0.604-2.64,0.558-2.481-1.77
				c0.009-0.126,0.005-0.254,0.007-0.381c-0.228,0.015-0.455,0.031-0.683,0.045c-1.263,0.073-1.508-1.09-1.883-1.818
				c-0.372-0.724,0.947-0.277,1.1-1.034c-1.148-0.198-2.266-0.295-3.596,0.075c2.305,0.808,1.02,2.163,0.216,2.28
				c-1.933,0.281-0.854,1.286-0.81,2.075l0.08-0.081c-2.849,1.923-3.895,1.211-4.282-2.93c-0.03-0.313-0.022-0.651,0.062-0.951
				c0.328-1.166-1.626-2.538-0.011-3.377c1.026-0.532,2.5-0.241,3.773-0.251c3.085-0.024,6.17-0.009,9.255-0.009
				c0.003-0.234,0.005-0.47,0.008-0.705c-3.245,0-6.494,0.088-9.734-0.043c-1.266-0.051-3.505,0.858-3.56-0.886
				c-0.039-1.282,2.296-0.606,3.522-0.608c6.374-0.015,12.781-0.35,19.042,1.397c-2.588-1.016-5.242-1.674-7.999-1.706
				c-4.391-0.052-8.783,0.001-13.175-0.036c-0.614-0.006-1.609,0.354-1.62-0.811c-0.013-1.331,0.963-0.797,1.687-0.818
				c4.533-0.134,9.082-0.178,13.602-0.215c4.642-0.037,9.46,0.433,13.865,2.467c0.464,0.214,0.927,0.727,1.528,0.266
				C517.805,624.08,519.13,624.281,519.361,626.382z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(276)} fill={props.fillColors[276]} d="M436.253,531.146c-2.104-1.116-4.792-0.491-6.688-2.24
				c-0.003-0.001,0.078,0.071,0.075,0.069c0.604-1.909-0.443-2.778-2.069-3.251c-0.674-0.196-1.719-0.358-1.638-1.015
				c0.099-0.798,1.084-1.232,1.913-1.479c0.372-0.11,0.796-0.033,1.187-0.1c2.008-0.342,3.791-0.815,2.306-3.507
				c-0.26-0.471-0.151-1.096,0.451-1.45c1.322-0.775,2.94-1.188,3.049-3.238c0.032-0.631,1.074-0.408,1.691-0.496
				c2.015-0.287,4.061,0.43,6.105-0.568c1.105-0.54,1.421,0.914,1.141,1.62c-1.065,2.686-0.383,4.826,1.355,7.006
				c0.651,0.817,1.148,2.203-0.202,3.391c-1.128,0.991,0.048,1.952,0.715,2.542c1.426,1.262,0.938,2.233-0.309,3.005
				c-1.674,1.035-2.883,2.721-4.905,3.282c-1.287,0.358-2.063,0.47-2.266-1.23c-0.124-1.047-0.546-2.203-1.941-2.382L436.253,531.146z
				"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(277)} fill={props.fillColors[277]} d="M65.586,614.977c1.489,0.871,3.31,0.95,3.5,2.938c0.045,0.474,0.313,0.966-0.203,1.252
				c-0.332,0.185-0.761,0.133-1.12-0.152c-1.743-1.381-4.026-1.724-5.815-3.071c-1.205-0.907-2.458-1.943-4.077-0.195
				c-0.608,0.656-2.253,0.64-3.179,0.021c-0.75-0.501-0.396-1.591-0.153-2.435c0.022-0.075,0.099-0.138,0.11-0.212
				c0.665-4.597,0.66-4.571,5.497-3.798c1.796,0.287,3.616,0.422,5.426,0.626c-4.603-1.639-10.796-1.576-15.929,0.128
				c-0.386,0.128-0.834,0.66-1.254,0.062c-0.25-0.358-0.077-0.706,0.06-1.104c1.23-3.595,3.31-5.017,7.111-5.274
				c6.71-0.454,12.772,1.734,18.813,4.198c1.109,0.453,1.521,1.069,1.364,2.154c-0.186,1.279,0.464,2.943-1.514,3.438
				c0.688,1.165,0.438,2.261-0.064,3.447c-0.449,1.062-0.98,0.71-1.666,0.401c-1.34-0.604-1.855-1.934-2.667-2.989
				c-0.592-0.771-1.544-1.229-1.802-0.299C67.598,615.653,66.604,614.835,65.586,614.977z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(278)} fill={props.fillColors[278]} d="M75.7,545.35c-0.18,0.13-0.36,0.259-0.54,0.388c-1.18,0.726-2.36,0.165-3.541,0.002
				c0,0,0.065,0.07,0.065,0.07c-0.287-0.21-0.607-0.059-0.91-0.09c-2.419-0.256-2.618-0.644-1.417-2.702
				c1.101-1.888,0.907-2.396-1.179-3.027c-0.604-0.183-1.199-0.39-1.844-0.443c-0.87-0.074-1.893-0.153-2.09-1.201
				c-0.188-0.994,0.711-1.573,1.397-2.045c0.776-0.535,1.477-1.202,2.368-1.568c1.639-0.862,2.771-2.312,4.1-3.534
				c0.19-1.237,0.975-1.53,2.117-2.025c3.249-1.409,6.039-1.135,8.778,1.019c0.727,0.57,1.839,0.347,2.587,0.989
				c1.253,0.363,2.271,0.667,1.801,2.516c-0.361,1.418,1.631,1.122,2.217,1.997c1.526,1.872-0.227,3.105-1.059,4.297
				c-0.82,1.176-1.997,0.394-2.886-0.337c-0.851-0.7-1.937-0.942-2.579-0.136C81.062,542.057,77.804,542.965,75.7,545.35z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(279)} fill={props.fillColors[279]} d="M470.98,675.332c-0.002,0.892,0.727,1.103,1.336,1.402
				c1.032,0.509,0.43,1.062,0.069,1.618l0.104-0.087c-1.043,0.206-2.077,0.081-3.166,0.515c-1.3,0.516-2.861,0.702-4.327,0.18
				c-1.989-0.451-3.913-1.187-5.965-1.38c-5.215-0.741-10.426-1.119-15.63,0.046c-1.929,0.465-3.955,0.653-5.58,1.979
				c-1.896,1.222-3.831,1.298-5.853,0.282c-1.713-0.86-3.624-1.166-5.411-1.818c-1.418-0.519-1.521-1.193-0.315-2.106
				c1.977-1.499,4.195-2.539,6.472-3.528c7.098-3.086,14.464-4.18,22.124-3.236c1.813,0.224,3.538,0.873,5.2,1.68
				c3.578,1.738,7.271,3.21,11.018,4.546L470.98,675.332z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(280)} fill={props.fillColors[280]} d="M436.224,531.104c0.337,1.201,1.134,2.165,1.329,3.512
				c0.276,1.908,1.984,1.171,3.082,0.856c2.182-0.625,3.761-2.307,5.557-3.597c0.625-0.448,1.045-1.335,2.003-1
				c1.142,0.399,0.805,1.445,0.798,2.264c-0.014,1.49,0.534,2.798,1.226,4.038c0.642,1.15,1.443,2.115,0.229,3.461
				c-0.652,0.724-0.257,1.344,0.805,1.079c0.735-0.183,1.444-0.475,2.8-0.932c-2.759,3.204-6.043,3.611-9.189,3.983
				c-2.936,0.347-5.438,1.051-6.856,3.898c-0.682,1.368-1.375,0.6-1.575-0.195c-0.712-2.833-2.588-4.151-5.326-4.591
				c-1.073-0.172-1.54-1.046-1.186-1.961c0.529-1.365,0.237-2.582-0.014-3.925c-0.325-1.739-0.386-3.473,2.088-4.007
				c1.706-0.367,2.757-2.008,4.268-2.833C436.253,531.146,436.224,531.104,436.224,531.104z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(281)} fill={props.fillColors[281]} d="M243.152,722.281c7.992-0.227,16.378-0.031,24.475,2.745
				c1.203,0.413,2.459,0.768,3.54,1.404c0.662,0.389,2.535-0.189,1.779,1.638c-0.53,1.28-0.806,2.786-3.064,2.103
				c-7.41-2.242-15.089-3.22-22.756-2.632c-4.409,0.338-8.707-0.29-13.064-0.151c-3.972,0.127-7.902,0.475-11.796,1.169
				c-1.409,0.251-1.677,0.163-1.321-1.34c0.858-3.623,1.424-4.239,6.041-4.416c2.618-0.101,5.183-0.699,7.828-0.552
				C237.439,722.396,240.08,722.281,243.152,722.281z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(282)} fill={props.fillColors[282]} d="M82.352,629.612c1.07-0.559,2.222-0.744,3.396-0.756
				c5.139-1.147,10.363-1.351,15.621-1.232c0.837,0.019,1.54,0.339,2.295,0.53c0.966,0.245,1.385,0.797,0.548,1.69
				c-0.054,0.058-0.121,0.113-0.151,0.184c-1.26,2.932-2.824,4.265-6.641,3.887c-10.308-1.019-19.8,2.235-28.626,7.432
				c-1.254,0.739-2.646,1.462-3.452,2.819l0.075-0.076c-2.163-0.177-2.288-2.258-3.045-3.57c-0.585-1.013,0.998-1.32,1.628-1.94
				c4.267-4.204,9.884-5.745,15.257-7.771C80.292,630.418,81.321,630.012,82.352,629.612z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(283)} fill={props.fillColors[283]} d="M374.497,676.147c-0.968-0.144-1.896,0.653-2.881,0.124
				c-1.533-0.433-2.493-1.521-3.356-2.791c-1.745-2.564-3.888-4.733-6.634-6.247c-1.661-1.427-2.974-3.487-5.64-3.081
				c-0.521-0.218-1.014-0.501-1.578-0.6c-2.522-1.036-5.086-0.818-7.662-0.251c-1.782,0.091-3.651,1.337-5.292-0.4
				c-3.299-0.085-6.216,1.423-9.278,2.29c-1.788,0.506-3.652,0.974-5.117,2.285c-1.33,1.192-2.364,1.321-3.868-0.175
				c-1.259-1.252-1.006-1.726,0.015-2.698c1.8-1.714,4.283-2.203,6.233-3.627c0.05-0.118,0.099-0.235,0.146-0.354
				c1.111-1.285,2.498-1.885,4.217-1.891c2.575-0.009,4.956-1.121,7.517-1.383c5.256-0.537,10.485-0.654,15.722,0.121
				c0.269,0.029,0.509,0.135,0.733,0.276c0.645,0.45,1.056,1.222,1.86,1.453c4.896,1.404,8.102,5.165,11.705,8.369
				c1.821,1.619,2.947,3.729,4.077,5.839C376.101,674.685,375.9,675.602,374.497,676.147z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(284)} fill={props.fillColors[284]} d="M478.643,582.816c0.265-0.658,3.166-0.22,0.89-2.088
				c-0.073-0.06-0.611-1.537-0.487-2.358c0.346-2.28,3.985-3.972,6.138-2.805c0.867,0.471,1.781,0.805,2.366,0.08
				c1.672-2.071,3.83-0.919,5.775-1.07c1.02-0.079,0.928,1.116,2.003,1.688c-3.989,0.801-7.667-0.357-11.464,1.131
				c2.271,0.252,4.076-0.848,5.946-0.45c1.969,0.419,3.912,0.596,5.869-0.009c0.146-0.046,0.317-0.021,0.477-0.016
				c0.774,0.034,1.889-0.23,2.055,0.73c0.219,1.273-1.329,0.35-1.752,1.094c0.499,0.73,2.379,0.145,1.918,1.838
				c-0.545,2.002-2.286,0.033-3.309,1.093c1.632,0.031,3.18,0.009,3.922,1.83c0.209,0.513,1.154,0.328,1.771,0.057
				c0.936-0.412,1.706-0.123,2.378,0.571c0.277-0.451,0.695-0.665,1.208-0.61c1.6,0.17,4.234,3.138,4.384,4.915
				c0.043,0.621-0.035,1.205-0.503,1.672c0.188,0.93-0.467,0.843-1.038,0.858c-0.35-0.112-0.698-0.224-1.056-0.306
				c-2.44-1.524-4.967-2.817-7.757-3.649c-1.423-0.425-2.771-0.732-4.211-0.453c-3.481,0.675-7.148-0.079-10.533,1.504
				c-1.171,0.548-2.209,1.266-3.321,1.883c-0.788,0.445-1.563,0.914-2.432,1.195c-1.679-0.023-2.878-0.557-2.709-2.546
				c-0.277-1.662,1.024-2.536,1.83-3.655c0.546-0.762,1.545-1.168,1.734-2.21L478.643,582.816z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(285)} fill={props.fillColors[285]} d="M300.955,410.977c-0.943-0.035-1.793,0.977-2.565,0.051
				c-1.108-1.327-2.118-2.729-3.507-3.809c-0.224-0.174-0.016-0.718,0.413-0.967c0.402-0.233,0.752-0.567,1.097-0.888
				c3-2.796,3.077-2.867,5.778,0.179c4.73,5.332,8.73,11.193,12.162,17.44c2.278,4.15,4.262,8.442,6.138,12.787
				c0.507,1.175,0.475,1.804-1.026,1.79c-1.265-0.012-2.533-0.105-3.778,0.372c-0.861,0.33-1.296-0.352-1.611-1.038
				c-2.074-4.51-4.432-8.888-6.544-13.362c-0.699-1.479-1.277-2.596-2.432-3.792c-1.659-1.72-3.533-3.676-2.498-6.668
				C302.987,411.896,302.437,410.827,300.955,410.977z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(286)} fill={props.fillColors[286]} d="M126.22,728.781c-2.393-3.519-3.965-7.231-8.028-8.901
				c-1.155-0.474-0.82-2.474-0.868-3.649c-0.25-6.156-0.457-12.365,1.858-18.26c0.687-1.75,1.616-3.418,2.577-5.038
				c0.419-0.708,1.029-1.514,2.089-1.34c1.131,0.185,1.128,1.13,1.293,1.999c0.725,3.828,0.948,7.686,0.913,11.578
				c-0.057,6.326,0.042,12.654-0.063,18.979C125.966,725.635,126.903,727.039,126.22,728.781z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(287)} fill={props.fillColors[287]} d="M107.911,671.166c-0.032,0.073-0.045,0.17-0.1,0.217
				c-0.859,0.737-1.271,2.682-2.842,1.616c-1.127-0.765-0.209-2.118,0.162-3.094c2.955-7.779,8.487-13.192,15.836-16.83
				c6.084-3.012,12.638-4.71,19.334-4.927c7.026-0.227,13.84,1.288,19.485,6.04c2.598,2.186,5.526,3.963,7.917,6.402
				c0.545,0.556,1.369,1.158,0.203,1.857c-0.707,0.424-1.357,0.946-2.211-0.126c-4.715-5.93-10.934-9.045-18.445-9.958
				c-8.937-1.085-17.484-0.105-25.581,3.971c-0.975,0.491-1.997,0.965-2.611,1.958c0.542,1.352-0.593,1.91-1.338,2.479
				c-3.289,2.51-5.939,5.524-7.795,9.232C109.523,670.811,109.047,671.588,107.911,671.166z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(288)} fill={props.fillColors[288]} d="M325.569,675.877c0.235,0.14,0.471,0.278,0.704,0.417
				c5.567-1.296,11.195-1.791,16.922-1.612c3.514,0.11,6.913,0.712,10.249,1.65c1.359,0.382,2.729,0.491,4.082,0.783
				c1.118-1.021,1.402-0.208,1.625,0.74c6.425,2.354,10.382,7.561,14.647,12.479c-2.353,1.463-4.086,0.536-5.67-1.095
				c-1.707,0.628-2.25-0.069-1.954-1.719c-6.05-4.403-12.317-8.428-20.13-8.503c-5.476-0.053-10.981-0.55-16.436,0.378
				c-2.402,0.408-4.875,0.858-6.901,2.229c-2.611,1.769-5.221,1.738-8.228,0.701c1.739-1.282,3.475-2.284,5.302-3.173
				c0.803-0.39,1.899-0.523,2.097-1.701c0.006-0.237,0.091-0.444,0.242-0.626C323.12,675.966,324.172,675.294,325.569,675.877z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(289)} fill={props.fillColors[289]} d="M470.359,407.473c-1.306,0.637-2.879,0.953-3.021,3.016
				c-0.073,1.06-0.161,1.906,0.745,2.488c0.751,0.483,1.366,0.746,0.415,1.693c-0.956,0.952-1.549,0.243-2.176-0.291
				c-0.277-0.235-0.439-0.659-0.55-1.028c-2.924-9.739-5.288-19.584-6.557-29.707c-0.325-2.595,0.278-3.939,2.365-5.04
				c0.624-0.329,1.104-0.929,1.725-1.264c0.954-0.515,1.807-1.859,2.831-1.443c1.221,0.496,0.418,2.004,0.6,3.052
				c0.91,5.262,1.311,10.61,2.655,15.8c0.268,1.032,0.483,1.965-0.801,2.576c-0.642,0.306-0.82,1.014-0.977,1.783
				c-0.354,1.742,0.768,3.581-0.776,5.348c-0.631,0.721,0.855,1.841,1.914,2.256C469.236,406.902,469.9,406.568,470.359,407.473z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(290)} fill={props.fillColors[290]} d="M428.264,308.738c-0.436-3.125,0.462-5.833,0.495-8.586
				c0.005-0.386,0.305-0.793,0.243-1.152c-0.692-4.029,1.694-6.659,4.274-9.068c3.909-3.65,7.185-7.887,11.329-11.881
				c-0.303,4.392-0.949,8.495-0.773,12.563c0.217,5.025-2.681,7.553-6.269,10.123C434.364,303.03,431.556,305.869,428.264,308.738z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(291)} fill={props.fillColors[291]} d="M355.11,662.461c0.378,0.075,0.756,0.151,1.134,0.228
				c2.284,1.021,4.365,2.41,6.552,3.608c3.641,2.706,6.551,6.058,8.82,9.975c-0.097,0.087-0.193,0.174-0.29,0.262
				c-2.522,1.218-3.901-0.236-5.846-1.766c-4.11-3.233-9.125-5.095-14.154-6.403c-6.389-1.664-12.853-1.787-19.032,1.292
				c-1.139,0.566-2.266,1.11-3.308,1.847c-0.669,0.474-1.245,0.569-2.078-0.102c-1.515-1.22-1.227-2.065,0.123-3.045
				c2.62-1.903,5.752-2.521,8.736-3.502c1.904-0.626,3.893-1.004,5.687-1.953c1.522-0.563,3.141-0.462,4.714-0.681
				C349.173,661.37,352.158,661.326,355.11,662.461z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(292)} fill={props.fillColors[292]} d="M177.602,616.22c-0.892-0.097-2.324,1.051-2.508-0.57
				c-0.105-0.925,0.189-2.106,0.436-3.11c0.375-1.529,1.886,0.197,2.646-0.703c-0.082-0.067-0.17-0.195-0.286-0.229
				c-0.931-0.265-2.737,0.426-2.308-1.421c0.33-1.413,1.705-0.326,2.627-0.332c0.855-0.006,1.633,0.646,3.031,0.119
				c-1.613-0.304-2.765-0.662-3.928-0.705c-1.511-0.056-2.011-0.83-1.843-2.144c0.21-1.636,1.217-0.608,1.955-0.516
				c3.604,0.454,7.046,1.605,10.564,2.449c-3.416-1.572-7.066-2.382-10.709-3.09c-1.628-0.316-2.025-1.033-1.835-2.397
				c0.249-1.79,1.402-1.093,2.518-0.966c3.686,0.416,7.259,1.307,10.822,2.275l-0.078-0.076c3.135,1.228,5.848,3.214,8.746,4.867
				l-0.069-0.079c0.967,1.235,2.299,2.434,0.408,3.963c0.601,0.724-0.577,1.556,0.125,2.255c0.509,0.508,0.325,0.939,0.031,1.481
				c-0.45,0.829-1.084,0.238-1.424,0.104c-1.928-0.766-3.928-1.149-5.953-1.547c-2.596-0.509-5.051-0.229-7.486,0.665
				c-1.879,0.689-2.334,0.398-2.19-1.614c0.067-0.95-0.436-0.687-0.766-0.435c-1.159,0.889-2.225,0.217-3.681-0.314
				c0.439,0.923,1.603,1.187,1.109,2.11L177.602,616.22z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(293)} fill={props.fillColors[293]} d="M505.094,561.672c4.01,0.64,6.943,2.832,10.076,4.624
				c2.84,1.625,6.125,2.031,9.145,3.175c0.593,0.225,1.195,0.426,1.775,0.678c2.643,1.148,2.735,1.419,1.134,3.702
				c-1.316,1.877-2.716,3.696-4.011,5.587c-0.75,1.095-1.53,1.152-2.23,0.118c-1.103-1.63-2.6-2.977-3.421-4.804
				c-0.671-1.493-1.491-1.534-2.366-0.182c-1.111,1.717-2.395,3.362-3.221,5.21c-0.839,1.876-1.159,1.235-2.13,0.114
				c-1.243-1.436-2.21-3.275-4.561-3.492c-1.175-0.108-0.766-1.043-0.17-1.702c0.683-0.755,1.729-0.728,2.542-1.214
				c1.482-0.885,1.466-1.858,0.125-3.017c-0.926-0.799-1.62-1.865-2.418-2.812c-0.219-0.261-0.396-0.699-0.115-0.837
				C508.73,565.111,506.299,563.477,505.094,561.672z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(294)} fill={props.fillColors[294]} d="M157.841,636.469c-0.227,0.277-0.454,0.555-0.682,0.832
				c-0.761,0.328-1.332,0.76-2.396,0.387c-4.898-1.715-9.99-2.31-15.181-2.068c-6.665,0.309-13.185,1.343-19.418,3.822
				c-4.547,1.81-4.542,1.823-7.635,0.053c3.78-4.656,8.854-6.739,14.583-7.765c5.993-1.071,12.021-0.978,18.049-0.852
				c4.306,0.089,8.371,1.475,12.392,2.913C159.46,634.474,157.383,635.614,157.841,636.469z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(295)} fill={props.fillColors[295]} d="M347.479,377.17c-3.537-1.709-6.497-3.304-9.271-5.242
				c-1.282-0.895-1.47-2.403-1.993-3.691c-3.605-8.868-5.944-18.105-7.825-27.467c-0.074-0.369,0.04-0.775,0.086-1.455
				c2.332,1.546,4.512,2.957,6.653,4.424c0.946,0.648,0.859,1.819,1.048,2.763c1.452,7.276,3.999,14.191,6.852,21.001
				C344.315,370.576,345.809,373.56,347.479,377.17z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(296)} fill={props.fillColors[296]} d="M405.676,494.152c-0.49,0.304-0.781,0.436-1.012,0.636
				c-0.858,0.744-2.85,0.66-2.411,2.308c0.441,1.659,2.127,2.14,3.752,2.403c0.709,0.114,1.727-0.494,2.069,0.603
				c0.388,1.242-0.726,1.57-1.48,2.097c-0.699,0.486-1.448,0.93-2.051,1.519c-2.146,2.097-2.129,3.24-0.027,5.273
				c-3.715,0.96-3.975,0.963-7.11-0.13c-0.361-0.126-0.878-0.324-0.975-0.607c-0.987-2.868-3.327-2.201-5.457-2.21
				c-1.182-0.005-2.397-0.164-3.549-0.155c-2.993,0.021-2.162-2.27-2.552-3.78c-0.223-0.861,0.642-0.738,1.127-0.752
				c1.677-0.049,3.356-0.067,5.033-0.032c1.33,0.027,2.117-0.353,2.108-1.885c-0.009-1.553,0.563-3.01,1.606-4.138
				c1.299-1.403,1.42-2.919,0.949-4.635c-0.146-0.529-0.239-1.093-0.233-1.64c0.015-1.252,1.92-3.152,3.015-2.849
				c1.311,0.363,0.489,1.61,0.661,2.441c0.158,0.766,0.057,1.592,0.023,2.39c-0.062,1.493,0.509,2.261,2.112,2
				C402.846,492.754,404.204,493.383,405.676,494.152z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(297)} fill={props.fillColors[297]} d="M173.401,495.213c0.248,0.091,0.497,0.182,0.745,0.272
				c0.125,2.746-1.654,4.466-3.296,6.312c-1.227,1.379-3.905,2.362-3.386,4.143c0.424,1.454,2.905-0.835,4.498,0.275
				c0.095,1.545-1.452,2.375-1.974,3.718c-0.253,0.264-0.507,0.528-0.761,0.792c-1.705,1.622-3.736,2.826-5.531,4.336
				c-2.059,1.732-4.745,1.992-7.145,2.925l0.096-0.032c-1.572,0.026-1.049-0.91-0.794-1.727c0.451-1.443,1.147-2.953-1.597-2.43
				c-0.791,0.151-1.11-0.523-0.267-1.14c1.202-1.658,3.385-2.608,3.592-4.984c-0.176-0.892-0.905-0.989-1.61-1.13
				c-1.163-0.231-2.574-0.324-2.639-1.867c-0.067-1.591,1.044-2.562,2.412-3.187c0.351-0.159,0.767-0.187,1.133-0.341
				c1.794-0.81,3.787-1.144,5.443-2.279c0.283-0.365,0.717-0.212,1.076-0.302c2.346-0.585,2.338-0.578,1.819,1.761
				c-0.101,0.454-0.647,1.037,0.235,1.331c0.048,0.017,0.137-0.03,0.181-0.073c0.053-0.053,0.079-0.133,0.117-0.201
				c2.364-0.944,3.599-3.233,5.558-4.661C172.002,496.218,172.703,495.717,173.401,495.213z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(298)} fill={props.fillColors[298]} d="M321.494,468.391c-0.074-0.039-0.183-0.115-0.317-0.223
				c-0.806-1.078-1.913-1.815-3.363-2.587c2.333-1.584,5.093-2.261,6.245-4.89c0.088-0.2,0.596-0.4,0.819-0.328
				c3.112,1.009,4.969-1.054,6.493-3.033c1.516-1.968,3.166-3.283,5.63-3.381c1.253-0.051,2.241-0.694,3.333-1.12
				c0.451-0.442,0.451-0.442,0.849,0.207c0.847-0.195,2.065-0.483,2.348,0.295c0.304,0.835-0.628,1.683-1.416,2.257
				c-0.376,0.274-0.806,0.482-1.15,0.789c-2.39,2.133-2.427,2.206,0.229,3.868c1.345,0.843,1.231,2.066,0.307,2.55
				c-2.522,1.318-4.022,3.748-6.493,5.209c-3.842,2.273-7.587,1.478-11.462,0.999C322.847,468.917,322.177,468.602,321.494,468.391z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(299)} fill={props.fillColors[299]} d="M82.099,662.254c1.86-1.055,3.721-2.109,5.582-3.163
				c7.234-1.984,14.168-0.728,20.974,1.934c0,0-0.083-0.086-0.082-0.086c0.708,1.392-0.756,1.915-1.183,2.839
				c-0.82,1.776-1.423,4.102-2.865,5.01c-1.338,0.842-3.264-1.109-5.13-1.351c-5.629-0.727-11.247-1.766-16.91-0.552
				c-2.264,0.485-4.567,1.142-6.045,3.219l0.086-0.089c-0.889-0.255-1.368,1.318-2.314,0.57c-0.612-0.484-1.374-1.051-1.566-1.724
				c-0.254-0.89,0.903-0.853,1.415-1.256C76.597,665.607,79.825,664.646,82.099,662.254z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(300)} fill={props.fillColors[300]} d="M307.208,273.493c0.408-3.558,0.72-6.73,1.15-9.885
				c0.373-2.73,0.947-5.433,1.315-8.163c0.464-3.445,3.864-6.83,7.814-7.631c5.8-1.176,5.372-1.139,5.388,4.774
				c0.012,4.241-2.058,6.695-4.613,9.415C314.732,265.76,311.249,269.556,307.208,273.493z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(301)} fill={props.fillColors[301]} d="M322.175,677.144c-0.101,0.101-0.199,0.204-0.296,0.31
				c-1.438,0.897-2.613,0.623-4.021-0.372c-5.168-3.65-10.965-5.59-17.25-6.544c-7.9-1.2-14.969,0.86-21.822,4.394
				c-0.413,0.213-0.738,0.592-1.112,0.898c-1.071,0.655-2.134,1.306-3.196,1.956c-1.705,0.72-3.269,1.648-4.517,3.047
				c-1.716,1.925-2.695-0.15-3.743-0.844c-1.283-0.848-0.311-1.77,0.388-2.56c1.449-1.64,3.207-2.911,5.131-3.873
				c7.2-3.602,14.383-7.17,22.659-7.812c6.273-0.487,12.313,0.154,17.952,2.892c3.182,1.545,6.839,2.351,9.298,5.22
				C321.919,674.936,323.442,675.815,322.175,677.144z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(302)} fill={props.fillColors[302]} d="M234.827,728.223c6.696,0,12.957-0.189,19.201,0.055
				c5.679,0.223,11.266,1.341,16.769,2.803c0.777,0.206,0.923,0.539,0.734,1.357c-0.211,0.913-0.297,1.958-0.065,2.85
				c0.521,2.005-0.005,2.248-1.933,1.803c-2.118-0.488-4.039-1.018-5.963-2.229c-2.804-1.765-6.166-2.35-9.521-2.25
				c-2.113,0.062-3.823-0.406-5.34-2.025c-0.998-1.065-2.404-2.476-3.821-0.239c-0.096,0.15-0.521,0.296-0.585,0.232
				c-2.709-2.682-3.082,0.67-4.052,1.742c-0.909,1.005-1.605,0.304-2.346,0.228c-6.163-0.628-12.119-0.249-17.466,3.335
				c-0.413,0.277-0.822,0.633-1.27,0.311c-0.443-0.319-0.316-0.836-0.133-1.312c0.141-0.367,0.207-0.762,0.325-1.139
				c1.427-4.559,1.433-4.639,6.17-5.012C228.769,728.477,231.996,727.861,234.827,728.223z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(303)} fill={props.fillColors[303]} d="M412.051,554.137c1.021,5.039-1.849,7.979-7.104,7.266
				c0.331-0.524,0.923-0.114,1.3-0.359c0.714-0.463,1.865-0.66,1.92-1.607c0.056-0.953-0.805-1.516-1.585-2.146
				c-2.918-2.351-5.795-4.774-8.466-7.396c-0.863-0.848-1.611-2.055-0.68-3.806c0.693-1.304-0.762-2.882-1.664-4.383
				c1.414-0.332,1.791,0.745,2.378,1.331c0.611,0.61,1.06,1.381,1.635,2.033c0.747,0.849,1.847,0.827,2.643,0.414
				c0.764-0.396,0.622-1.637,0.262-2.168c-0.948-1.397-0.205-2.221,0.576-3.244c1.096-1.439,2.106-2.933,3.45-4.173
				c0.711-0.657,1.52-0.935,2.017-0.081c1.055,1.811,2.822,3.299,2.783,5.651c0.002-0.003,0.083-0.088,0.085-0.09
				c-2.892,2.097-2.831,2.799,0.713,6.429c-1.02,0.464-2.064,1.097-2.023,2.248c0.044,1.238,0.226,2.565,1.398,3.406
				C412.066,553.549,412.159,553.788,412.051,554.137z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(304)} fill={props.fillColors[304]} d="M107.911,671.166c2.375-5.452,6.271-9.588,11.148-12.873
				c2.811-0.813,5.136-2.684,7.987-3.486c8.928-2.512,17.836-3.11,26.752-0.111c4.404,1.481,7.986,4.235,11.027,7.7
				c0.49,0.559,1.097,1.219,0.214,2.035c-0.998,0.924-1.259-0.242-1.609-0.619c-3.718-4.011-9.043-4.42-13.76-6.128
				c-4.104-1.487-8.557-1.887-12.811-1.767c-5.692,0.161-11.218,1.934-15.94,5.305c-4.189,2.99-8.17,6.291-10.351,11.204
				c-0.348,0.783-0.458,1.827-1.971,1.183C107.071,672.957,107.508,672.152,107.911,671.166z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(305)} fill={props.fillColors[305]} d="M377.479,670.627c0.448,0.859,0.896,1.718,1.345,2.576
				c1.024,2.211-0.688,2.014-1.948,2.216l0.107-0.023c-1.146,0.395-1.328-0.573-1.617-1.23c-2.518-5.733-7.194-9.397-12.139-12.836
				c-1.856-1.292-4.214-1.426-6.027-2.77l0.093,0.057c-2.509-0.369-4.843-1.493-7.459-1.483c-7.08,0.025-13.896,1.318-20.479,3.896
				l0.085-0.052c-2.966-0.042-5.198,1.441-7.121,3.498c-0.438,0.468-0.708,1.189-1.479,0.662c-0.8-0.549-0.52-1.182-0.022-1.832
				c3.402-4.454,7.957-7.259,13.291-8.543c12.23-2.943,23.801-1.364,34.393,5.766c0.001,0.002-0.018-0.021-0.018-0.021
				C372.599,662.887,375.281,666.542,377.479,670.627z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(306)} fill={props.fillColors[306]} d="M212.64,678.059c-0.752-0.316-1.505-0.633-2.257-0.949
				c-4.705-3.19-9.803-5.451-15.408-6.509c-8.794-1.659-16.834,0.775-24.628,4.543c-0.875,0.423-1.592,1.049-2.268,1.731l0.083-0.039
				c-0.067-0.043-0.145-0.134-0.2-0.121c-1.186,0.259-2.624,1.61-3.482,0.015c-0.712-1.324,0.955-2.31,1.729-3.071
				c3.699-3.64,8.378-5.64,13.321-6.884c9.293-2.339,18.122-0.278,26.603,3.512c3.669,1.639,7.391,3.283,10.66,5.714
				c0.634,0.472,1.328,0.92,1.808,1.526c0.41,0.518,1.855,0.904,0.535,1.957c-0.945,0.754-1.685,2.097-3.152,0.646
				C215.05,679.206,214.111,678.168,212.64,678.059z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(307)} fill={props.fillColors[307]} d="M32.342,586.281c-1.213-0.427-2.267,0.248-3.388,0.476
				c-0.687,0.14-1.567,0.991-2.02-0.133c-0.366-0.909,0.438-1.4,1.199-1.755c0.19-0.088,0.351-0.241,0.564-0.392
				c-0.959-0.113-1.65,0.976-2.778,0.492c0.534-1.493,2.108-1.836,2.968-2.875l-0.102,0.083c0.871-0.313,1.655-0.856,2.702-0.468
				c1.097,0.408,1.981,0.14,2.325-1.181c0.172-0.662,0.885-0.854,1.395-1.21l-0.084,0.06c0.768,0.243,1.799-0.526,2.334,0.622
				c0.421,0.907-0.182,1.45-0.773,2.01c3.069,0.849,3.833,0.55,4.568-2.276c0.274-1.057,0.859-1.051,1.524-1.013
				c1.397,0.077,3.058-0.73,4.052,0.92c0.569,0.945,0.88,2.366,2.318,1.833c0.992-0.368,2.155-0.896,2.203-2.444
				c0.022-0.708,0.693-1.391,1.046-2.097c0.436-0.872,0.888-1.131,1.851-0.504c2.212,1.44,4.279,3.029,6.061,4.979
				c0.812,0.889,0.854,1.658-0.29,2.326c-0.735,0.43-1.387,0.923-1.859,1.715c-0.977,1.639-2.061-0.089-3.099-0.056
				c0.067,1.047,2.562,0.97,1.049,2.86c-0.955,1.196-1.628,1.544-2.904,0.822c-5.204-2.943-10.995-3.33-16.756-3.685
				c-1.365-0.083-2.775,0.574-4.175,0.894C32.263,586.288,32.342,586.281,32.342,586.281z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(308)} fill={props.fillColors[308]} d="M546.066,431.85c-4.201,2.994-3.087,8.25-5.547,11.943
				c-0.236,0.354-0.227,0.831,0.162,1.06c0.464,0.272,0.874-0.008,1.301-0.294c2.159-1.444,4.188-3.099,6.578-4.188
				c0.431,0.704,0.076,1.174-0.385,1.69c-0.734,0.823-1.602,1.622-2.026,2.599c-0.447,1.028-1.343,2.385-0.435,3.335
				c0.661,0.692,1.609-0.537,2.466-0.812c0.61-0.197,1.228-0.68,1.496,0.297c0.181,0.66,0.067,1.282-0.874,1.315
				c-2.417,0.082-3.061,1.578-2.876,3.692c0.142,1.607-0.017,2.811-2.194,2.275c-0.786-0.193-1.543-0.13-2.119,0.967
				c-0.82,1.564-3.549,1.486-4.671,0.125c-1.795-2.178-4.009-4.024-4.754-6.974c-0.36-1.427-0.357-1.997,1.355-2.034
				c2.038-0.045,3.398-2.481,2.623-4.343c-1.391-3.338-0.928-4.996,1.991-7.128c0.256-0.187,0.655-0.562,0.776-0.491
				c3.326,1.949,3.831-1.785,5.669-2.799C544.925,431.908,545.097,431.43,546.066,431.85z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(309)} fill={props.fillColors[309]} d="M521.081,367.097c-1.934,0.262-3.333,0.445-4.729,0.644
				c-1.633,0.232-2.493,1.095-2.127,2.772c-1.126,0.402-1.513-0.943-2.543-0.791c0.611,3.645,1.127,7.275,1.86,10.86
				c0.37,1.809-0.105,2.583-1.945,2.511c-0.638-0.025-1.374-0.205-1.901,0.04c-2.736,1.272-3.024-0.714-3.222-2.474
				c-0.432-3.854-0.592-7.74-1.05-11.591c-0.453-3.806-0.424-7.611-0.437-11.427c-0.005-1.536,0.479-1.637,1.618-0.824
				C511.276,360.154,515.963,363.466,521.081,367.097z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(310)} fill={props.fillColors[310]} d="M253.557,703.454c-0.176-0.168-0.352-0.336-0.518-0.495
				c-1.329-3.823-5.046-5.03-7.928-7.148c-1.45-1.066-2.923-0.995-4.509-0.038c-3.344,2.02-6.659,4.091-8.809,7.442
				c-0.897,1.399-1.391,0.793-2.056,0.031c-0.883-1.012-1.653-2.131-2.599-3.077c-0.963-0.965-0.039-1.348,0.411-1.924
				c3.77-4.828,9.272-6.798,14.694-8.889c0.333-0.128,0.809-0.134,1.134,0.003c5.251,2.206,10.78,3.931,14.519,8.705
				c0.907,1.157,1.618,1.924-0.112,2.995c-0.898,0.557-1.479,1.621-2.215,2.444C254.889,704.266,254.21,704.203,253.557,703.454z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(311)} fill={props.fillColors[311]} d="M108.442,258.559c3.059,0.647,5.883,0.784,8.282,1.716
				c1.625,0.632,2.63,3.023,3.76,4.728c4.378,6.606,9.772,12.38,14.889,18.383c0.891,1.045,2.6,2.723,2.108,3.279
				c-1.03,1.165-2.248-0.642-3.457-1.013c-6.272-1.922-10.33-6.62-14.5-11.32C115.311,269.586,111.93,264.339,108.442,258.559z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(312)} fill={props.fillColors[312]} d="M118.195,566.396c0.103-0.955-1.82-1.338-1.027-2.085
				c1.276-1.202,2.653-2.652,4.536-2.807c5.444-0.447,10.902-0.721,16.353-1.101c0.418-0.029,0.781-0.054,1.122,0.179
				c2.053,1.399,4.102,2.806,6.151,4.21c0.162,1.598-0.649,1.429-1.653,0.911c-3.6,1.345-6.371,4.048-9.657,5.908
				c-0.555,0.314-1.057,0.903-1.688,0.515c-2.878-1.776-6.435-1.468-9.353-3.139c-1.604-0.918-2.873-2.461-4.864-2.676
				L118.195,566.396z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(313)} fill={props.fillColors[313]} d="M71.134,566.638c2.17-0.92,4.341-1.841,6.512-2.761
				c3.255-1.486,3.803-3.066,2.392-6.888c2.091-0.727,3.283,0.089,4.203,2.045c0.505,1.075,0.363,3.068,2.511,2.77l-0.072-0.062
				c2.383,2.635,1.352,7.775-1.697,9.441c-1.755,0.959-2.32,0.088-2.693-1.247c-1.248-4.463-1.217-4.472-5.878-4.134
				c1.643,0.243,2.171,1.061,2.115,2.51c-0.076,1.973-0.041,3.943-0.483,5.888c-0.112,0.493-0.302,0.866-0.676,1.206
				c-2.026,1.84-2.746,1.722-4.103-0.718c-2.128-3.827-2.55-3.851-5.066-0.302c-0.816,1.151-1.642,2.296-2.479,3.433
				c-0.496,0.672-1.099,1.07-2.022,0.999c-0.999-0.076-1.094-0.853-1.271-1.492c-0.419-1.514-0.768-3.051-1.079-4.592
				c-0.184-0.911,0.072-1.565,1.206-1.538c1.6,0.038,3.14-0.414,4.589-0.97c1.267-0.486,3.916,0.205,2.911-2.806
				C69.955,567.124,70.626,566.719,71.134,566.638z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(314)} fill={props.fillColors[314]} d="M319.7,334.942c2.515,1.256,4.535,2.313,6.599,3.281
				c1.273,0.597,0.836,1.876,0.985,2.725c1.746,9.883,4.527,19.455,8.409,29.152c-2.291-0.812-3.665-2.481-5.429-3.489
				c-3.838-2.191-4.984-5.948-6.072-9.86C322.25,349.767,320.908,342.676,319.7,334.942z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(315)} fill={props.fillColors[315]} d="M445.065,586.325c-0.026-0.078-0.053-0.155-0.079-0.232
				c-0.167-2.352,1.717-4.144,4.363-4.15c0.468-2.41,1.358-4.481,4.038-5.179c0.442-0.115,0.886-0.534,1.262,0.024
				c0.719,1.069,1.489,0.688,2.137,0.022c1.137-1.168,2.373-0.84,3.751-0.602c1.596,0.275,1.521,1.273,1.469,2.421
				c-0.078,1.729-1.3,0.521-1.952,0.759c-0.351,0.127-0.772,0.057-1.19,0.229c2.528,0.379,4.962,1.045,7.285,2.059
				c0.885,0.385,1.097-0.27,1.482-0.748c0.831-1.029,1.783-1.597,3.064-0.71c2.326-0.292,4.238,1.648,4.048,4.108
				c0.897,1.698,0.547,2.321-1.345,1.492c-0.286,0.804,0.858,0.953,0.54,1.818c-1.918-0.603-3.746-1.66-5.849-1.531
				c-0.107,0.033-0.214,0.067-0.32,0.101c-2.402,0.761-4.248-0.417-5.64-1.591c1.173,0.438,1.339,0.906-0.273,1.909
				c-2.213,1.375-4.827,0.938-7.092,1.931c-0.421,0.185-0.935,0.146-1.381,0.35C449.239,590.696,447.52,590.181,445.065,586.325z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(316)} fill={props.fillColors[316]} d="M144.087,558.226c0.218-2.654,2.361-3.743,5.7-2.712
				c3.681,1.138,7.335,2.317,11.162,2.913c1.985,0.309,3.885,0.378,5.85-0.188c2.139-0.614,4.512,0.354,6.601-0.872
				c0.762-0.448,2.538,0.032,1.439-1.988c-0.267-0.492,1.072-1.243,1.857-0.752c1.324,0.828,2.719,0.741,4.139,0.714
				c0.68-0.014,1.316,0.107,1.591,0.781c0.274,0.676-0.343,1.092-0.761,1.325c-1.952,1.093-1.829,3.549-3.413,4.939
				c-1.421,1.248-2.515,2.309-4.712,1.582c-3.228-1.066-6.538-0.304-9.787,0.361c-0.368,0.075-0.783-0.074-1.176-0.119l0.026,0.022
				c-0.345-1.07-1.485-1.762-1.273-3.149c0.093-0.609-0.851-1.32-1.275-0.551c-1.526,2.771-4.093,1.753-6.007,1.374
				c-1.596-0.315-2.684-0.482-3.98,0.537c-0.36,0.283-0.993,0.892-1.867,0.372c2.205-1.693,0.929-2.406-0.814-3.076
				c-1.159-0.446-2.253-1.057-3.379-1.597C144.005,558.141,144.087,558.226,144.087,558.226z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(317)} fill={props.fillColors[317]} d="M427.205,560.762c-0.947,0.067-0.968,0.969-1.316,1.535
				c-0.489,0.793-1.119,0.851-1.826,0.497c-0.69-0.344-0.847-1.029-0.472-1.631c1.782-2.856,1.191-6.024,1.173-9.085
				c-0.011-1.773,0.371-3.467,0.57-5.192c0.166-1.444,3.544-2.648,4.993-2.549c3.989,0.273,5.381,3.534,6.516,6.227
				c0.548,1.3,1.126,3.516,0.644,5.439c-0.329,1.309-0.13,2.821,1.491,2.979c2.332,0.228,1.22,1.503,0.674,2.151
				c-0.784,0.928-1.911,1.558-2.793,2.462c-0.559,0.573-1.468,0.4-2.252,0.086c-1.016-0.405-1.154-1.206-1.05-2.136
				c0.18-1.605,0.051-3.059-2.011-3.196c-1.97-0.131-3.934-0.119-4.412,2.472L427.205,560.762z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(318)} fill={props.fillColors[318]} d="M455.69,187.35c3.755,1.381,7.259,2.768,10.687,4.373
				c1.963,0.919,4.133,1.431,6.245,1.994c6.919,1.845,8.217,8.103,6.127,13.491c-1.136,2.926-3.894,3.486-6.077,1.231
				c-2.251-2.323-4.319-4.827-6.415-7.296c-1.852-2.18-4.108-3.757-6.754-4.797c-2.152-0.845-3.503-2.203-3.048-4.71
				C456.722,190.171,455.721,188.942,455.69,187.35z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(319)} fill={props.fillColors[319]} d="M268.693,450.427c1.827-0.299,3.267-0.58,4.719-0.755
				c0.872-0.105,1.808-0.098,2.147-1.064c0.361-1.031-0.483-1.62-1.133-2.173c-2.652-2.254-5.359-4.443-7.991-6.72
				c-0.552-0.477-1.635-1.003-1.225-1.813c0.45-0.888,1.391-0.19,2.122,0.02c2.429,0.698,4.872,1.351,7.289,2.091
				c0.633,0.194,1.474,0.528,1.774,0.042c0.819-1.328,1.299-0.296,1.787,0.148c0.943,0.858,1.771,1.843,2.714,2.7
				c1.654,1.502,2.762,1.39,3.713-0.58c0.771-1.598,1.808-2.258,3.562-2.102c1.426,0.128,2.872,0.018,4.309,0.047
				c1.074,0.022,2.417-0.276,2.676,1.262c0.256,1.52,0.516,3.03-1.168,4.19c-2.465,1.698-5.294,1.301-7.965,1.624
				c-2.345,0.283-4.718,0.348-7.021,0.939c-1.846,0.475-1.987,1.565-0.664,2.684c1.367,1.155,0.517,1.708-0.472,1.879
				C274.61,453.408,271.579,452.865,268.693,450.427z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(320)} fill={props.fillColors[320]} d="M132.961,466.026c-1.124-0.878-1.844-1.722-2.836-2.248
				c-0.454-0.24-1.186-0.701-0.621-1.52c1.467-2.128,0.841-4.103-0.707-5.767c-1.029-1.107-1.31-2.44-0.847-3.519
				c1.146-2.666-0.784-4.7-1.057-7.047c-0.061-0.517-0.541-0.933-1.01-0.852c-2.224,0.387-4.144-1.089-6.299-0.96
				c-1.116,0.065-1.188-0.362-1.019-1.332c0.176-1.01-0.021-2.079,0.074-3.11c0.095-1.046-0.343-1.896-1.256-1.99
				c-1.425-0.146-1.609-1.15-1.867-2.112c-1.32-4.931-2.597-9.873-3.869-14.816c-0.05-0.192,0.099-0.436,0.157-0.663
				C119.328,423.148,133.748,454.248,132.961,466.026z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(321)} fill={props.fillColors[321]} d="M66.993,679.09c-3.478-0.395-6.357,1.505-9.441,2.551
				c-1.633,0.554-2.319,0.336-2.792-1.379c-0.381-1.379,0.189-1.895,1.26-2.226c6.405-1.978,12.843-3.759,19.515-4.702
				c5.533-0.783,11.066-1.046,16.628-0.906c4.185,0.104,8.32,0.752,12.447,1.419c0.456,0.074,1.179,0.095,0.955,0.724
				c-0.473,1.327-1.176,2.571-1.736,3.87c-0.896,2.079-2.324,0.644-3.389,0.402c-4.649-1.052-9.317-1.834-14.099-1.783l0.028,0.049
				c-1.462,0.139-1.674-1.171-2.303-1.993c-0.48-0.627-0.89-1.403-1.868-1.158c-0.889,0.223-0.833,0.977-0.917,1.709
				c-0.171,1.488-1.919,2.017-3.364,1.007c-0.579-0.404-1-1.027-1.562-1.461c-1.778-1.37-2.541-1.083-2.858,1.118
				c-0.259,1.801-1.689,1.543-2.834,1.653c-1.352,0.131-2.705,0.121-3.744,1.185L66.993,679.09z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(322)} fill={props.fillColors[322]} d="M59.143,593.821c0.306-0.842-0.354-1.079-0.865-1.459
				c-1.104-0.821-1.827-1.731,0.381-3.134c-1.507,0.206-1.741,1.297-2.894,1.13c1.718-3.647,4.272-6.371,7.697-8.173
				c1.126-0.592,2.008-1.521,3.199-1.976c0.262-0.101,0.592-0.354,0.653-0.597c0.637-2.527,1.288-3.09,3.857-3.134
				c0.424-0.007,1.076-0.265,1.219,0.35c0.182,0.78-0.819,0.493-1.146,0.942c1.592,0.132,1.375,0.322,2.097,2.302
				c0.368,1.009,1.913,1.695,3.054,2.271c0.613,0.31,1.601,0.207,1.518,1.123c-0.114,1.265-1.132,0.176-1.706,0.438
				c-0.541,0.248-1.28-0.156-1.957,0.614c1.049,0.062,1.943,0.185,2.833,0.153c1.901-0.067,1.989,1.546,2.097,2.625
				c0.137,1.376-1.231,0.711-1.938,0.8c-5.104,0.638-9.866,2.229-13.922,5.426c-1.538,1.212-2.744,1.582-4.242,0.231
				C59.078,593.755,59.143,593.821,59.143,593.821z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(323)} fill={props.fillColors[323]} d="M359.36,695.392c-1.688-0.242-2.281,1.445-3.516,2.021
				c-0.547,0.255-0.942,0.975-1.622,0.287c-2.062-2.087-4.662-3.474-7.018-5.234c2.4-1.892,5.096-0.134,8.176-0.833
				c-3.258-0.955-5.905-0.887-8.495-0.321c-1.051,0.229-1.823,0.076-2.689-0.368c-4.354-2.23-8.764-4.332-13.548-5.563
				c4.803-4.211,23.367-1.193,33.625,6.84c-0.556,0.741-1.382,1.203-2.181,1.163c-1.603-0.08-2.333,0.748-2.792,2.071L359.36,695.392z
				"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(324)} fill={props.fillColors[324]} d="M195.667,548.017c-1.282-0.532-1.003,0.457-1.104,1.114
				c-0.451,2.952-1.099,5.89-1.316,8.858c-0.153,2.092-1.136,3.185-2.92,3.854c-3.743,1.403-7.624,1.411-11.617,1.264
				c2.705-1.194,2.521-4.226,4.189-6.036c1.251-1.357,0.035-2.301-1.423-2.503c-1.091-0.15-2.22-0.029-3.449-0.029
				c0.211-1.147,1.13-1.157,1.735-1.549c0.725-0.47,1.836-0.667,2.049-1.533c0.401-1.629,1.625-2.25,2.704-3.293
				c2.189-2.118,4.729-2.087,7.245-2.449c1.737-0.25,3.219,0.502,3.907,2.311c0.118-0.026,0.176-0.04,0.176-0.04
				C195.904,547.97,195.846,547.979,195.667,548.017z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(325)} fill={props.fillColors[325]} d="M90.524,599.625c-0.442-0.762-0.249-1.212,0.526-1.706
				c0.974-0.621,1.792-1.485,2.677-2.244c0.053-0.112,0.106-0.226,0.158-0.339c2.601-2.306,5.944-3.009,9.125-3.692
				c3.902-0.839,7.928-1.409,11.967-0.85c2.073,1.074,4.268,1.981,6.112,3.399c0.738,0.567,2.291,1.124,1.529,2.719
				c0.574,2.163-1.681,1.793-2.503,2.419c-3.105,2.36-6.905,1.167-10.44,1.298c-0.944,0.034-0.868-0.784-0.806-1.407
				c0.071-0.718-0.328-1.656,0.893-1.904c0.739-0.15,1.562,0.132,2.268-0.42c-0.879-0.687-2.878,0.443-2.725-1.812
				c0.13-1.905,1.705,0.048,2.11-0.853c-2.425-2.385-4.893-0.104-7.569-0.256c0.907,0.961,1.727,0.035,2.708,0.487
				c-1.003,1.578-1.803,3.204-4.599,3.079c1.164,0.592,1.812,0.481,1.231,1.789c-1.409,3.174-4.511,2.719-6.798,3.974
				c-0.52-1.018,0.023-1.744,0.523-2.529c-1.589-0.812-2.905,3.2-4.536,0.241C90.918,601.669,90.313,601.189,90.524,599.625z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(326)} fill={props.fillColors[326]} d="M399.928,710.18c-0.491-0.051-0.983-0.102-1.475-0.151
				c-4.295-4.971-9.544-8.649-15.48-11.381c-8.117-3.735-16.094-3.442-23.766,1.164c-1.342,0.806-1.966,0.449-2.803-0.431
				c-1.253-1.317,0.502-1.465,0.856-2.12c0.452-0.831,1.714-0.869,2.097-1.866c0.003-0.003-0.067,0.068-0.062,0.063
				c3.37-1.461,6.725-2.825,10.382-3.567c4.619-0.938,8.723,0.312,12.843,1.964c1.741,0.698,3.446,1.488,5.168,2.236
				c0.241,0.12,0.483,0.239,0.725,0.358c3.025,0.757,5.147,2.997,7.635,4.641c0.208,0.121,0.411,0.241,0.625,0.352
				c2.383,1.822,3.718,4.385,4.764,7.078C401.847,709.574,401.703,710.784,399.928,710.18z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(327)} fill={props.fillColors[327]} d="M387.869,518.021c-0.674-0.951-1.797-1.541-2.24-2.675
				c0.35-0.441,0.597-0.028,0.863,0.015c0.783,0.127,1.6,1.409,2.224,0.532c0.652-0.916-0.199-1.98-0.953-2.692
				c-2.336-2.206-5.074-3.96-7.065-6.571c-0.646-0.849-1.434-1.301-0.001-1.988c0.067-0.032,0.099-0.134,0.165-0.17
				c1.099-0.61,0.959-2.895,2.511-2.477c1.619,0.437,0.61,2.155,0.765,3.322c0.106,0.808,0.813,0.96,1.578,1.137
				c2.352,0.548,4.724,0.437,7.094,0.418c1.117-0.009,1.846,0.221,2.479,1.334c1.145,2.009,3.52,1.904,5.406,2.343
				c1.745,0.407,2.236,0.572,0.73,1.953c-0.83,0.762-1.67,1.631,0.366,2.007c0.97,0.179,0.885,1.234,1.039,1.919
				c0.26,1.15-0.922,0.92-1.464,1.075c-1.458,0.416-2.686,0.977-2.968,2.641c-0.168,0.989-0.635,0.976-1.483,0.701
				c-2.836-0.917-5.706-1.726-8.562-2.575C388.156,518.254,387.993,518.175,387.869,518.021z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(328)} fill={props.fillColors[328]} d="M359.151,677.855c-0.551-0.249-1.102-0.496-1.639-0.742
				c-2.868-1.852-6.234-2.062-9.456-2.681c-3.201-0.613-6.475-0.438-9.727-0.507c-2.641-0.057-5.211,0.277-7.777,0.763
				c-0.861,0.163-1.408,0.249-1.838-0.82c-0.416-1.04-0.065-1.313,0.727-1.803c8.193-5.051,16.742-5.035,25.475-1.765
				c3.224,1.207,6.395,2.587,9.335,4.422c1.078,0.672,2.78,1.828,2.315,2.629c-0.713,1.226-2.595,1.852-4.195,1.326
				C361.304,678.326,360.188,678.261,359.151,677.855z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(329)} fill={props.fillColors[329]} d="M219.708,644.011c-2.234-4.574-2.221-4.537,2.483-6.194
				c4.349-1.532,8.89-1.777,13.401-2.406c5.04-0.702,9.972,0.066,14.94,0.174c5.763,0.125,11.351,1.484,16.688,3.749
				c0.919,0.391,1.244,0.766,0.576,1.751c-0.657,0.97-0.786,2.553-1.634,3.076c-1.242,0.766-1.811-1.074-2.793-1.612
				c-6.298-3.451-12.964-4.07-19.993-3.391c-2.356,0.228-4.857-0.146-7.294-0.2c-5.42-0.118-10.42,1.179-14.89,4.336
				C220.75,643.606,220.204,643.775,219.708,644.011z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(330)} fill={props.fillColors[330]} d="M453.656,324.446c-0.138,0.185-0.342,0.427-0.511,0.691
				c-8.276,12.964-20.096,20.843-34.96,24.342c-0.38,0.09-0.871,0.175-1.182,0.014c-0.976-0.509-2.172,1.413-2.874-0.169
				c-0.47-1.058,1.833-3.88,3.17-4.122c4.47-0.809,8.721-2.226,12.789-4.271c8.229-4.137,14.571-10.284,19.604-17.917
				c0.395-0.599,0.414-1.844,1.66-1.651C451.751,321.426,453.66,323.964,453.656,324.446z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(331)} fill={props.fillColors[331]} d="M140.281,608.686c0.552,1.144,1.804,1.519,2.627,2.384
				c1.007,1.06,1.646,2.126,1.097,3.698c-2.188-1.171-3.304-3.375-4.864-5.104c-1.439-1.593-2.218-1.247-2.785,0.471
				c-0.486,1.471-2.316,2.125-2.171,4c0.059,0.768-0.417,2.283-1.025,2.104c-1.06-0.312-2.652-0.649-2.896-1.986
				c-0.304-1.664-2.08-2.855-1.397-4.836c0.16-0.465-0.729-0.644-1.184-0.879c-1.779-0.917-3.855-0.86-5.653-1.628
				c-3.404-1.454-6.904-0.171-10.321-0.602c-1.161-0.146-3.047,1.183-3.288-1.353c-0.333-3.517,0.024-4.202,2.8-4.063
				c8.33,0.414,16.625,1.117,24.226,5.04c1.677,0.865,3.276,1.882,4.912,2.828L140.281,608.686z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(332)} fill={props.fillColors[332]} d="M405.135,631.048c1.544,1.463,3.646,1.708,5.5,2.483
				c2.404,1.006,5.228,1.244,6.602,3.985c0.28,0.559,0.997,0.374,1.544,0.417c2.285,0.18,3.562,2.027,4.832,3.455
				c0.865,0.971-0.881,2.116-1.479,3.158c-0.554,0.966-1.438,1.206-2.412,0.451l-0.164-0.165c-4.473-4.598-10.442-6.251-16.23-8.269
				c-3.847-1.341-7.901-1.332-11.874-1.816c-1.214-0.148-1.742-0.455-1.605-1.69c0.06-0.552,0.086-1.129-0.002-1.673
				c-0.33-2.025,0.485-3.01,2.545-2.942l-0.077-0.065c1.583,0.729,3.438,1.007,4.214,2.985c0.185,0.471,1.362,0.743,1.65,0.148
				c1.124-2.326,2.911-1.005,4.463-0.991c-0.113-0.926,0.563-1.045,1.173-0.979C404.633,629.631,405.336,629.986,405.135,631.048z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(333)} fill={props.fillColors[333]} d="M127.79,676.54c-0.471,0.049-1.042-0.058-1.396,0.172
				c-2.425,1.572-4.913,1.191-7.437,0.367l0.058,0.072c0.82-2.66,3.179-3.692,5.356-4.698c4.776-2.205,9.576-4.299,14.928-4.983
				c2.904-0.37,5.726-0.368,8.427,0.392c2.909,0.819,5.906,1.614,8.439,3.469c0.679,0.496,1.381,0.826,1.022,1.849
				c-0.326,0.927-0.839,0.983-1.804,0.81c-7.546-1.364-15.066-1.269-22.515,0.721c-0.149,0.04-0.342,0.112-0.458,0.055
				c-2.194-1.09-3.542,0.006-4.639,1.772c-0.031,0.133-0.062,0.267-0.092,0.4C127.717,676.805,127.753,676.672,127.79,676.54z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(334)} fill={props.fillColors[334]} d="M595.379,592.481c0.098,1.991-1.067,2.124-2.576,1.753
				c-1.873-1.017-3.745-2.034-5.618-3.05c-3.88-3.348-8.239-3.06-12.755-1.709c-1.253,0.374-2.466,1.235-3.864,0.47
				c-0.367-0.468-0.735-0.936-1.104-1.403c-1.891-2.431-4.652-3.438-7.357-4.566c-0.674-0.282-2.734-0.357-0.591-1.718
				c1.694-1.11,3.389-2.221,5.083-3.33c0.205-0.292,0.479-0.341,0.799-0.227c1.998-0.459,4.001-0.897,5.993-1.384
				c1.168-0.284,1.791,0.26,1.335,1.265c-0.515,1.135-0.364,2.037,0.088,3.068c1.376,3.135-1.282,2.73-3.053,3.096
				c5.068,0.03,10.088-1.67,14.763,1.243C590.187,587.183,593.086,589.419,595.379,592.481z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(335)} fill={props.fillColors[335]} d="M242.04,718.17c-3.599,0-7.203,0.118-10.795-0.042
				c-2.332-0.104-4.566,0.703-6.867,0.468c-0.592-0.062-1.9,0.616-1.69-0.401c0.197-0.953-0.471-2.478,1.21-3.009
				c2.671-0.844,5.38-0.479,8.062-0.364c5.036,0.217,9.992,1.03,15.124,0.522c6.752-0.668,13.589-0.883,20.349,0.594
				c2.759,0.604,5.616,0.727,8.341,1.585c0.804,0.253,2.213,0.277,1.551,1.354c-0.752,1.221-0.123,4.074-2.963,3.335
				c-2.898-0.754-5.704-1.875-8.615-2.566c-5.815-1.381-11.748-1.641-17.708-1.475c-1.998,0.056-3.999,0.009-5.999,0.009
				C242.04,718.176,242.04,718.173,242.04,718.17z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(336)} fill={props.fillColors[336]} d="M375.437,727.227c-1.765-1.363-3.967-0.506-5.887-1.143
				c-0.576-0.191-1.269-0.022-1.907-0.031c-1.32-0.018-3.684,0.241-3.781-0.116c-0.448-1.638,0.447-3.318,0.753-5.003
				c0.206-1.142,1.205-1.267,1.979-1.381c8.417-1.237,16.579,0.038,24.842,2.541c-1.637,2.317-3.196,4.381-5.066,6.228
				c-0.94,0.928-1.753,0.976-2.98,0.612c-2.629-0.776-5.182-1.999-8.037-1.781L375.437,727.227z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(337)} fill={props.fillColors[337]} d="M420.964,377.603c-2.128-3.665-2.198-3.764,1.409-6.311
				c7.727-5.454,16.194-9.163,25.658-10.31c2.936-0.355,5.315,1.715,8.117,2.076c0.619,0.08,0.403,0.933,0.378,1.474
				c-0.049,1.006-0.835,0.818-1.421,0.803c-4.474-0.117-8.786,0.67-13.102,1.849c-7.022,1.918-13.077,5.497-18.603,10.094
				c-0.835,0.695-1.503,1.57-2.49,0.262C420.907,377.535,420.964,377.603,420.964,377.603z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(338)} fill={props.fillColors[338]} d="M491.66,204.792c1.81,1.085,2.506,2.953,3.327,4.732
				c0.713,3.227,2.279,6.057,4.143,8.73c3.382,4.853,1.505,13.074-2.841,17.218c-1.228,1.172-2.804,1.48-4.19-0.025
				c-0.911-0.666-1.676-1.471-2.291-2.418c-0.906-1.85-2.126-2.702-4.365-2.05c-2.269,0.661-3.978-1.023-3.335-3.369
				c0.641-2.34,0.17-4.306-0.852-6.342c-1.096-2.186-0.647-4.458-0.139-6.706l-0.076,0.081c1.938-3.07,3.614-6.3,5.651-9.31
				C488.26,203.019,489.349,202.908,491.66,204.792z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(339)} fill={props.fillColors[339]} d="M415.489,354.217c-0.399,0-0.8-0.011-1.199,0.002
				c-0.739,0.024-1.975,0.426-1.765-0.742c0.167-0.928,0.095-2.464,1.829-2.626c7.035-0.658,13.528-3.029,19.711-6.357
				c6.429-3.46,11.961-7.969,16.337-13.85c1.084-1.456,2.175-2.908,3.336-4.3c0.351-0.42,0.53-1.682,1.509-0.728
				c0.671,0.654,1.918,1.194,0.949,2.521c-1.669,2.286-3.143,4.742-5.017,6.844c-7.203,8.08-15.79,14.181-26.181,17.504
				C421.921,353.471,418.781,354.319,415.489,354.217z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(340)} fill={props.fillColors[340]} d="M143.868,493.933c-0.971-1.431-2.652-2.491-2.382-4.562
				c-0.022-1.322,0.183-2.099,1.729-1.045c1.29,0.88,2.719,1.541,4.255,1.977c0.638,0.181,1.341,0.527,1.727-0.03
				c0.436-0.629,0.092-1.396-0.487-1.906c-1.669-1.47-3.167-3.09-4.482-4.885c-0.279-0.381-0.657-0.702-0.305-1.428
				c2.363,1.015,4.737,2.033,7.11,3.052c1.089-1.391,1.894-0.016,2.812,0.283c0.186,0.146,0.309,0.353,0.469,0.521
				c0.786,0.833-0.22,2.492,1.107,2.905c1.705,0.531,3.583,0.651,5.311,0.312c1.575-0.309,3.598-0.205,4.259-2.331
				c0.101-0.389,0.25-0.758,0.474-1.092c-0.17-1.322,1.621-0.839,1.743-1.893c0,0-0.1,0.094-0.1,0.094
				c1.55-0.046,3.071-0.375,4.612-0.481c1.249-0.088,1.539,0.443,0.936,1.6c-0.454,0.87-1.171,1.486-1.755,2.227
				c-0.442,0.559-1.239,0.965-1.316,1.661c-0.352,3.174-3.012,4.069-5.226,5.286c-2.19,1.203-4.61,0.909-6.969,0.349
				c-0.387-0.093-0.781-0.141-1.169-0.232c-2.301-0.547-2.379-0.463-1.893,1.904c0.264,1.283-0.542,1.827-2.336,1.46
				C148.998,497.067,146.215,495.963,143.868,493.933z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(341)} fill={props.fillColors[341]} d="M332.195,570.713c0.559,2.619,2.398,1.478,3.699,1.075
				c2.131-0.659,3.501-2.371,4.719-4.18c0.564-0.839,1.218-1.616,1.993-2.637c0.638,2.061-0.198,3.732-0.515,5.421
				c-0.119,0.639-0.412,1.448-0.099,1.837c1.79,2.216-0.202,1.758-1.307,1.979c-1.372,0.274-2.724,0.704-4.107,0.854
				c-4.116,0.448-6.075-1.462-5.26-5.552c0.409-2.054-0.076-4.123,0.595-6.128c0.305-0.91-0.433-1.474-1.245-1.725
				c-1.195-0.37-2.4-0.757-3.63-0.947c-0.847-0.131-1.488-0.078-1.528-1.263c-0.039-1.159,0.328-1.67,1.442-1.952
				c3.123-0.791,6.308-1.42,9.176-2.997c1.126-0.618,1.926-2.067,3.639-1.318c-1.056,1.066-2.423,1.939-2.569,3.628
				c-0.127,1.466,0.147,2.717,1.986,1.864c1.56-0.723,2.87-1.26,4.138,0.432c0.198,0.267,0.868,0.18,1.483,0.283
				c-0.715,1.504-1.843,2.315-3.081,3.034c-1.21,0.703-2.588,1.206-3.25,2.598c-0.012,0.108-0.023,0.217-0.033,0.326
				c-0.624,2.041-2.354,3.004-3.988,4.035c-0.654,0.411-1.457,0.565-2.082,1.058C332.312,570.53,332.254,570.622,332.195,570.713z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(342)} fill={props.fillColors[342]} d="M373.512,640.368c-2.564,1.636-4.726,1.903-7.66,0.091
				c-3.647-2.252-8.127-2.759-12.415-3.438c-4.118-0.651-8.247-0.35-12.372-0.467c-3.294-0.094-6.391,0.835-9.507,1.688
				c-1.567,0.43-4.171-1.353-4.002-2.935c0.129-1.215,1.139-0.814,1.75-0.39c1.483,1.032,2.177,0.893,2.939-0.967
				c0.985-2.398,7.212-2.518,8.666-0.364c0.307,0.454,0.668,0.872,1.011,1.302c1.254,1.573,2.299,1.528,3.08-0.411
				c0.264-0.654,0.479-1.329,0.75-1.98c0.336-0.807,0.743-1.288,1.812-0.92c0.794,0.273,1.746,0.268,2.599,0.161
				c3.111-0.392,5.654,0.142,6.566,3.646c0.257,0.986,0.946,1.256,1.812,1.071c0.843-0.179,1.521-0.783,1.466-1.647
				c-0.156-2.413,1.363-1.888,2.603-1.466c1.596,0.542,3.782,0.502,3.55,3.254c-0.066,0.79,1.097,1.108,1.707,0.805
				c2.037-1.014,3.094,0.312,4.151,1.581C372.462,639.517,373.333,639.578,373.512,640.368z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(343)} fill={props.fillColors[343]} d="M39,749.782c-4.226-7.59-5.722-15.48-7.207-23.374
				c-0.269-1.426-0.693-2.855-1.617-3.944c-1.812-2.137-1.518-4.615-0.726-6.755c1.675-4.522,3.808-8.878,5.802-13.279
				c0.334-0.736,0.626-1.879,1.784-1.517c1.029,0.321,0.533,1.374,0.515,2.074c-0.106,4.292-1.556,8.348-2.398,12.506
				c-0.75,3.696-0.305,7.251,0.28,10.835c1.137,6.968,2.383,13.919,3.551,20.882C39.101,747.906,39,748.64,39,749.782z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(344)} fill={props.fillColors[344]} d="M302.738,327.511c-7.027,2.677-13.861,4.609-20.807,6.16
				c-2.362,0.527-4.748,0.947-7.124,1.41c-0.454,0.088-1.202,0.357-1.324,0.188c-1.161-1.599-0.642-3.597-1.154-5.366
				c-0.36-1.246,0.63-0.992,1.231-1.124c4.065-0.898,8.148-1.719,12.197-2.683c2.816-0.67,5.628-1.412,8.356-2.365
				c1.077-0.376,1.649,0.162,2.367,0.518C298.448,325.226,300.38,326.274,302.738,327.511z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(345)} fill={props.fillColors[345]} d="M94.841,555.516c1.107-1.115,2.215-2.23,3.323-3.346
				c0.911-0.916,1.932-1.34,3.186-0.701c1.818,2.396,4.442,1.981,6.938,1.97c1.455-0.007,2.831-0.065,3.706,1.515
				c0.405,0.73,0.559,1.107-0.025,1.834c-3.127,3.884-5.892,8.015-8.129,12.482c-0.252,0.503-0.457,1.123-1.15,0.895
				c-0.576-0.19-0.343-0.82-0.346-1.256c-0.026-3.449-0.015-6.897-0.033-10.347c-0.004-0.767,0.238-1.852-0.733-2.029
				c-1.115-0.204-0.995,0.892-1.162,1.655c-0.417,1.914-0.983,3.798-1.356,5.72c-0.229,1.18-0.965,1.344-1.908,1.298
				c-0.824-0.041-1.681,0.223-1.933-1.146c-0.37-2.01-1.029-3.962-1.981-5.812C92.412,556.641,94.399,556.489,94.841,555.516z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(346)} fill={props.fillColors[346]} d="M292.198,419.336c2.181-2.527,3.945-4.776,6.407-6.275
				c0.889-0.542,1.968-1.247,2.845-0.466c0.865,0.771,0.035,1.8-0.411,2.581c-2.698,4.734-6.368,8.364-11.576,10.351
				c-1.629,0.621-2.785,0.375-3.745-0.952c-1.958-2.708-2.947-5.737-3.05-9.143c1.707-0.171,1.704,1.376,2.48,2.017
				c0.772,0.636,1.312,1.805,2.561,1.437c1.407-0.414,0.879-1.693,0.936-2.647c0.204-3.421-0.666-6.788-0.5-10.227
				c0.119-2.465,0.609-4.848,1.078-7.239c0.116-0.594,0.302-1.637,1.179-1.534c0.744,0.088,0.571,0.979,0.651,1.608
				c0.805,6.316,1.017,12.66,0.942,19.019C291.992,418.162,292.074,418.46,292.198,419.336z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(347)} fill={props.fillColors[347]} d="M118.605,749.002c-2.685-4.427-4.396-9.105-5.626-13.981
				c-0.23-0.913-0.196-1.915-0.543-2.771c-0.888-2.19-0.286-3.98,1.12-5.701c0.544-0.666,1.181-1.565,0.69-2.392
				c-0.604-1.017-1.375,0.148-2.078,0.147c-2.136-0.002-1.412-1.666-1.44-2.754c-0.111-4.257-0.366-8.468-2.598-12.269
				c-0.241-0.411-0.533-0.793-0.768-1.207s-0.493-0.895-0.125-1.32c0.354-0.409,0.935-0.351,1.342-0.151
				c2.584,1.264,4.808,3.056,6.959,4.937c0.196,0.171,0.272,0.586,0.256,0.879c-0.251,4.546,0.95,9.011-0.239,13.688
				c-1.152,4.53,1.004,8.956,2.754,13.197c0.121,0.293,0.259,0.593,0.451,0.841C120.998,743.031,120.609,745.906,118.605,749.002z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(348)} fill={props.fillColors[348]} d="M366.175,687.521c0.657,0.577,1.313,1.154,1.963,1.725
				c0.509,0.579,1.431,1.487,0.608,1.941c-1.12,0.618-2.547,1.273-3.966,0.34c-2.577-1.696-5.19-3.362-8.04-4.546
				c-8.885-3.688-17.878-6.24-27.469-2.444c-1.804,0.714-3.673-0.319-5.479-0.634c-0.746-0.13-1.585-0.053-2.477-0.544
				c2.174-1.955,4.9-2.681,7.482-2.978c7.879-0.908,15.841-1.529,23.657,0.363c3.99,0.966,7.437,3.36,10.931,5.486
				C364.28,686.776,365.149,687.296,366.175,687.521z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(349)} fill={props.fillColors[349]} d="M214.848,715.966c-1.752-8.052-4.854-15.358-4.02-23.362
				c0.237-2.271,0.762-4.488,1.666-6.576c0.636-1.468,1.682-1.566,2.637-0.234c1.994,2.78,3.396,5.889,4.841,8.97
				c0.128,0.275,0.258,0.646,0.177,0.907C218.126,702.192,217.244,709.02,214.848,715.966z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(350)} fill={props.fillColors[350]} d="M316.418,588.731c-0.305-0.239-0.588-0.549-1.034-0.312
				c-0.702-2.804,1.892-3.892,3.13-5.589c0.671-0.921,1.766-1.758,2.7-2.598c0.092-1.248,1.169-1.339,2.001-1.581
				c1.734-0.504,3.419-1.644,5.338-0.628c1.679,0.343,4.113-0.346,3.782,2.774c-0.008,0.073,0.509,0.25,0.794,0.293
				c0.389,0.061,1.054-0.102,1.147,0.073c1.747,3.269,5.314,1.107,7.631,2.602c0.108-0.012,0.218-0.022,0.326-0.034
				c2.005,0.867,4.236,1.235,5.964,2.714c1.205,1.527,3.211,2.426,3.589,4.596c0.115,1.997-0.685,2.95-2.774,2.432
				c-1.832,0.37-2.872-1.07-4.208-1.836c-2.511-3.575-5.882-5.189-10.315-5.354c-4.955-0.184-9.729-0.274-14.17,2.459
				C319.247,589.401,317.705,590.19,316.418,588.731z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(351)} fill={props.fillColors[351]} d="M211,726.987c-0.835-1.663-1.407-3.085-3.289-3.443
				c-0.929-0.177-0.988-1.513-1.073-2.444c-0.31-3.382-0.736-6.765-0.81-10.153c-0.082-3.734-0.819-7.434-0.527-11.181
				c0.08-1.021-0.127-2.061,0.391-3.044c0.331-0.626-0.383-1.797,0.814-1.948c1.024-0.129,1.092,1.023,1.538,1.609
				c2.396,3.15,2.049,7.168,3.246,10.714c1.023,3.034,1.612,6.22,2.326,9.354c0.674,2.961-0.661,5.572-1.594,8.24
				C211.791,725.357,211.451,725.987,211,726.987z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(352)} fill={props.fillColors[352]} d="M453.107,366.138c0.861,0.642,2.832-1.021,2.899,1.207
				c0.105,3.474-0.503,3.957-3.29,4.126c-9.893,0.602-18.771,3.836-26.387,10.312c-0.593,0.504-1.272,2.156-2.136,0.748
				c-0.694-1.132-2.595-2.54-0.603-4.202c5.196-4.333,10.84-7.82,17.386-9.831C444.932,367.284,448.936,366.589,453.107,366.138z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(353)} fill={props.fillColors[353]} d="M444.476,625.854c5.474-3.615,11.298-3.571,17.309-1.994
				c-0.077,1.537-1.738,1.467-1.843,2.638c-0.071,0.087-0.144,0.174-0.214,0.262c-1.852-0.024-3.702-0.142-5.311-0.074
				c1.25,0.135,2.769-0.259,4.249,0.199c1.116,1.217,0.084,1.953-0.562,2.715c1.367-0.141,2.341-2.146,4.101-0.983
				c0.555-0.067,1.288-0.776,1.58,0.204c0.216,0.722,0.377,1.623-0.461,2.177c-1.004,0.664-2.031,1.302-3.373,1.3
				c1.308,0.436,2.559,0.826,3.795,1.186c1.629,0.472,1.176,1.633,0.973,2.453c-0.326,1.323-1.299,0.425-1.896,0.161
				c-3.153-1.391-6.512-1.205-9.82-1.359c-1.258-0.059-2.097-0.27-2.761-1.599c-1.096-2.192-1.777-2.111-3.231-0.052
				c-1.616,2.288-5.87,2.857-7.114,0.926c-0.544-0.843-0.997-1.504,0.657-1.938c1.536-0.402,3.106-0.393,4.634-0.815
				c1.489-0.411,3.107-0.356,4.69-0.509c-0.435-1.078-2.761-1.358-0.991-3.297c-2.021,0.603-3.526,0.251-4.32-1.677L444.476,625.854z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(354)} fill={props.fillColors[354]} d="M198.592,698.896c0.742,2.373-0.312,4.27-0.241,6.264
				c0.236,6.655,0.706,13.288,1.325,19.921c0.207,2.219,1.191,4.973-1.671,6.952c-0.354-2.294,0.233-5.137-2.329-5.917
				c-2.941-0.896-3.965,1.887-5.545,4.261c-0.251-2.58,0.52-4.548,0.919-6.521c1.218-6.018,2.967-11.905,3.131-18.15
				C194.251,703.045,195.919,700.598,198.592,698.896z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(355)} fill={props.fillColors[355]} d="M448.351,481.785c-3.949,0.542-7.44,1.037-10.937,1.494
				c-1.775,0.232-3.294,0.656-4.943,1.721c-1.68,1.085-4.265,1.267-6.524,0.575c-0.371-0.114-0.735-0.483-1.071-0.445
				c-3.232,0.358-4.566-1.857-5.974-4.155c-0.669-1.093-0.437-1.284,0.67-1.501c3.536-0.693,7.032-1.891,10.588-2.085
				c2.451-0.134,4.806-0.729,7.147-0.833c2.247-0.101,4.597,1.338,6.381,2.994C444.933,480.706,446.847,479.966,448.351,481.785z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(356)} fill={props.fillColors[356]} d="M121.113,278.371c-1.948,0.379-3.23-0.691-4.466-1.518
				c-8.938-5.978-15.083-14.504-21.002-23.228c-0.301-0.443-0.501-0.984-0.207-1.292c0.257-0.268,1-0.406,1.311-0.226
				c1.186,0.689,2.262,0.338,3.416,0.007c0.093-0.026,2.294-1.47,1.337,0.891c-0.14,0.344,0.359,0.481,0.665,0.438
				c1.736-0.245,2.324,1.009,2.974,2.215c3.717,6.898,8.11,13.331,13.244,19.257C119.304,275.976,120.129,277.119,121.113,278.371z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(357)} fill={props.fillColors[357]} d="M516.139,263.254c7.325,1.379,14.49,3.323,21.393,6.199
				c1.264,0.526,0.672,1.307,0.596,2.092c-0.4,4.169-0.363,4.028-4.584,3.106c-6.396-1.396-12.48-3.73-18.683-5.725
				c-2.017-0.648-1.391-1.815-1.026-2.831C514.245,264.946,513.797,262.884,516.139,263.254z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(358)} fill={props.fillColors[358]} d="M533.981,281.887c-5.054-0.56-10.375-1.981-15.616-3.739
				c-1.788-0.599-3.553-1.266-5.353-1.827c-1.052-0.328-1.463-0.68-1.216-1.989c0.957-5.056,0.911-5.16,5.556-3.508
				c6.233,2.218,12.523,4.218,19.03,5.447c1.08,0.204,1.238,0.664,1.261,1.581C537.723,281.108,537.13,281.893,533.981,281.887z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(359)} fill={props.fillColors[359]} d="M194.596,692.572c-0.898-0.97-2.68-0.715-3.168-2.22
				c-0.723-2.231-0.695-4.541-0.584-6.849c0.036-0.754,0.364-0.726,1.088-0.643c5.825,0.666,11.439,2.044,16.884,4.26
				c1.344,0.547,1.399,1.146,1.115,2.219c-0.513,1.934-0.542,3.912-0.456,6.798c-2.424-3.931-5.788-1.983-8.604-2.67
				C199.181,694.988,196.412,694.594,194.596,692.572z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(360)} fill={props.fillColors[360]} d="M416.345,344.21c0.175-3.155,1.942-4.225,4.521-5.012
				c12.002-3.663,20.919-11.17,26.84-22.236c0.183-0.341,0.413-0.658,0.637-0.975c0.026-0.038,0.152-0.005,0.164-0.005
				c2.413,2.785,1.54,5.179-0.487,7.817c-5.441,7.083-11.691,13.144-19.835,17.053c-3.355,1.611-6.94,2.506-10.509,3.452
				C417.249,344.417,416.748,344.245,416.345,344.21z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(361)} fill={props.fillColors[361]} d="M343.029,725.949c-3.216,0.762-6.148,2.235-8.683,4.254
				c-1.984,1.582-3.88,2.409-6.248,1.347c-1.057-0.475-2.164-0.3-3.433-0.582c1.265-1.647,2.952-2.487,4.581-3.321
				c3.034-1.552,6.147-2.947,9.215-4.437c0.749-0.364,1.729-0.542,1.764-1.684c0-0.003,0.004,0.003,0.004,0.001
				c0.566,1.38,1.239,0.178,1.692-0.033c5.437-2.531,11-4.696,16.83-6.151c0.645-0.16,1.242-0.153,1.968-0.011
				c-0.854,1.591-1.715,3.129-2.511,4.701c-0.497,0.982-1.433,1.146-2.327,1.435c-3.69,1.189-7.37,2.415-11.078,3.546
				c-0.642,0.195-1.109,0.564-1.643,0.884L343.029,725.949z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(362)} fill={props.fillColors[362]} d="M567.944,623.878c3.249,0.708,3.411,0.747,2.712,3.712
				c-0.469,1.992-0.224,3.973-0.447,6.082c-1.435-1.611-0.857-3.975-2.406-5.377c-0.492-0.445-0.614-1.688-1.424-1.268
				c-0.644,0.334-1.364,1.095-1.435,2.06c-0.011,0.149,0.127,0.315,0.104,0.459c-0.341,2.042-0.745,4.023-3.458,2.859
				c0.042,1.366,2.291-0.007,1.988,1.657c-0.132,0.722,0.011,2.295-1.417,1.949c-0.994-0.241-2.673,0.323-2.771-1.733
				c-0.099-2.062-0.917-3.965-2.01-5.711c-0.71-1.135-0.414-1.679,0.835-2.015c1.025-0.275,2-0.74,2.882-1.458
				c-1.165,0.37-2.335,0.729-3.495,1.112c-1.344,0.442-1.954-0.426-2.307-1.428c-0.439-1.25,0.819-1.071,1.39-1.291
				c7.132-2.741,14.498-3.032,21.978-1.978c0.836,0.118,2.137-0.327,1.642,1.423c-0.356,1.258-0.624,2.594-2.378,2.086
				c-1.43-0.413-2.949-0.572-4.301-1.054C571.655,623.266,569.822,623.959,567.944,623.878z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(363)} fill={props.fillColors[363]} d="M324.018,361.636c-2.719-2.529-5.674-4.856-8.075-7.657
				c-1.628-1.899-1.48-4.753-1.933-7.187c-0.849-4.568-1.702-9.144-2.137-13.795c-0.157-1.695,0.201-1.418,1.214-0.973
				c1.45,0.637,2.869,1.353,4.343,1.928c0.652,0.254,0.98,0.462,1.099,1.26C319.843,344.068,321.511,352.85,324.018,361.636z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(364)} fill={props.fillColors[364]} d="M55.979,760.72c-2.964-10.289-7.689-20.377-2.829-31.528
				c1.813,2.435,2.505,5.242,3.479,7.907c1.357,3.712,2.085,7.602,2.747,11.472C60.139,753.025,57.896,756.83,55.979,760.72z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(365)} fill={props.fillColors[365]} d="M420.77,332.088c1.668-3.164,1.941-6.461,5.33-8.507
				c7.972-4.814,14.504-11.262,19.515-19.176c0.326-0.515,0.719-0.986,1.292-1.399c-0.324,3.028,0.16,6.033-1.768,8.881
				c-5.875,8.683-13.401,15.35-22.872,19.826C421.945,331.864,421.567,331.893,420.77,332.088z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(366)} fill={props.fillColors[366]} d="M171.594,589.529c-2.257-2.374-5.182-3.019-8.236-2.737
				c-2.598,0.239-3.363-1.031-3.441-3.2c-0.064-1.787-2.078-2.687-1.944-4.663c1.805-0.021,3.701-1.555,5.434,0.536
				c0.577,0.696,1.458,0.138,1.908-0.299c1.433-1.388,2.574-0.598,3.132,0.633c0.898,1.982,1.565,3.639,3.852,1.352
				c0.146-0.146,0.602,0.018,0.913,0.036c0.062-0.31,0.179-0.467,0.459-0.163c2.542,2.579,5.545,4.691,7.618,7.756
				c0.56,0.828,1.119,1.379,0.075,2.106c-0.902,0.628-1.68,0.217-2.123-0.576c-1.434-2.57-3.884-3.763-6.408-4.884
				c1.054,1.297,2.777,1.685,3.945,2.877c0.596,0.607,1.42,0.999,1.709,1.879c0.214,0.65,1.537,0.859,0.739,1.916
				c-0.757,1.001-1.586,0.736-2.503,0.263c-0.555-0.288-1.177-0.447-1.769-0.665C172.869,592.042,172.113,591.556,171.594,589.529z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(367)} fill={props.fillColors[367]} d="M447.234,310.726c0.528,2.18,0.754,3.754-0.19,5.515
				c-5.936,11.064-14.929,18.393-26.784,22.34c-0.653,0.218-1.315,0.418-1.786-0.013c-0.518-0.474,0.221-0.963,0.319-1.469
				c0.546-2.776,2.552-3.916,4.972-5.029c6.94-3.193,12.827-7.883,17.733-13.737C443.446,316.008,445.162,313.489,447.234,310.726z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(368)} fill={props.fillColors[368]} d="M392.989,533.668c2.182-0.469,3.962,0.244,5.438,1.659
				c0.782,0.749,1.635,1.244,2.541,0.795c1.076-0.532,1.007-1.694,0.795-2.75c-0.076-0.384-0.428-0.877-0.292-1.113
				c1.925-3.369-0.196-6.207-1.224-9.178c3.189-0.724,3.12-0.71,3.685,2.231c0.292,1.522,1.508,2.042,2.915,1.641
				c1.818-0.518,3.726-0.966,5.251-2.129c1.387-1.059,2.811-1.371,4.515-1c-0.457,1.271-1.191,2.428-1.174,3.812
				c0.005,0.404-0.075,0.786-0.595,0.878c-2.384,0.422-2.821,2.068-2.463,4.101c0.166,0.942-0.15,1.541-1.027,1.418
				c-4.45-0.622-6.664,2.459-8.761,5.433c-0.95,1.348-1.373,1.076-2.324,0.253c-0.823-0.712-1.688-1.475-2.832-1.646
				C394.931,537.697,393.89,535.862,392.989,533.668z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(369)} fill={props.fillColors[369]} d="M154.548,587.794c0.36,1.674,2.576,2.788,1.407,5.152
				c-1.03-1.327-1.969-2.538-2.909-3.748c-0.107,0.066-0.214,0.133-0.322,0.199c0.471,0.676,0.945,1.349,1.41,2.027
				c0.488,0.712,0.963,1.432,1.452,2.144c0.587,0.854,0.738,1.686-0.175,2.711c-1.962-3.486-4.437-6.295-7.797-8.26
				c-5.115-2.99-10.507-3.097-15.986-1.413c-2.84,0.872-5.836,1.389-8.237,3.486c-1,0.875-1.381-1.44-2.748-0.836
				c1.954-2.309,3.99-4.084,6.354-5.46c1.292-0.752,2.085-1.519,0.931-3.086c-0.765-1.039-0.706-2.221,0.395-3.245
				c0.658,0.312,0.923,0.931,0.931,1.511c0.034,2.577,2.077,2.842,3.832,3.383c1.36,0.419,1.766-0.868,2.306-1.633
				c0.769-1.089,4.019-1.399,3.868-0.893c-0.627,2.111,1.33,1.818,2.012,2.564c1.15,1.258,2.693-0.959,4.001,0.191
				c0.054,0.048,0.151,0.047,0.228,0.068c0-0.001-0.055-0.03-0.055-0.03c2.963,1.068,5.7,2.542,8.143,4.542
				C154.086,587.107,154.498,587.173,154.548,587.794z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(370)} fill={props.fillColors[370]} d="M21.292,675.305c5.189-1.61,10.522-1.07,15.815-1.018
				c3.259,0.032,6.375,0.877,9.508,1.767c1.892,0.537,1.49,2.04,1.922,3.163c0.474,1.23-0.955,1.189-1.513,1.741
				c-2.88,2.846-5.911,0.749-8.829,0.163c-1.768-0.354-3.612-0.626-5.198-0.135c-2.228,0.689-3.854-0.018-5.537-1.146
				c-1.724-1.157-3.541-2.146-5.509-2.797c-1.137-0.376-0.904-1.043-0.597-1.815C21.358,675.226,21.292,675.305,21.292,675.305z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(371)} fill={props.fillColors[371]} d="M536.523,283.459c-0.065,0.204-0.12,0.355-0.163,0.508
				c-1.362,4.854-0.261,4.834-5.901,4.18c-6.334-0.734-12.224-2.806-18.132-4.935c-0.837-0.302-2.188-0.573-2.163-1.529
				c0.038-1.533,0.451-3.129,1.061-4.545c0.294-0.682,1.323,0.011,1.915,0.242c4.267,1.657,8.626,3.03,13.071,4.083
				C529.567,282.258,532.986,282.785,536.523,283.459z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(372)} fill={props.fillColors[372]} d="M236.195,718.422c3.593,0.958,7.127,0.745,10.599,0.515
				c9.031-0.6,17.739,0.933,26.343,3.428c2.337,0.678,2.616,1.383,1.604,3.243c-0.828,1.522-1.894,0.584-2.625,0.267
				c-2.994-1.296-6.116-2.153-9.24-3.016c-3.988-1.103-8.127-1.054-12.198-1.3c-4.934-0.298-9.898-0.078-14.85-0.097
				c-4.043-0.015-8.048,0.449-12.034,1.047c-0.833,0.125-1.62,0.617-1.703-0.698c-0.067-1.066,0.029-2.145,1.448-2.295
				c1.808-0.192,3.616-0.429,5.43-0.499C231.427,718.922,233.905,719.228,236.195,718.422z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(373)} fill={props.fillColors[373]} d="M330.349,543.362c1.329,0.035,2.006-1.144,3.044-1.63
				c1.334-0.625,2.253-2.164,4.252-1.418c0.87,0.325,1.953-0.905,2.558-1.84c0.523-0.81,0.995-1.598,1.839-1.923
				c1.837,2.928,0.173,4.311-2.254,5.577c-0.986,0.515-2.116,1.101-2.646,2.294c-0.331,0.747-0.763,1.479-0.071,2.186
				c0.548,0.56,1.199,0.453,1.972,0.252c2.72-0.709,4.51-2.84,7.271-4.614c-1.213,2.745-3.703,3.371-5.081,5.086
				c-0.2,0.006-0.267,0.006-0.2-0.002c0.088-0.009,0.154-0.014,0.199-0.014c-2.402,1.401-5.282,2.37-5.225,5.956
				c0.009,0.549-0.593,0.592-1.015,0.809c-2.765,1.424-5.873,1.663-9.027,2.943c0.293-5.222,1.637-9.715,4.463-13.745
				C330.429,543.277,330.349,543.362,330.349,543.362z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(374)} fill={props.fillColors[374]} d="M499.916,377.865c-0.451-2.521-0.857-4.788-3.356-5.837
				c-0.681-0.287-0.531-1.095-0.532-1.725c-0.009-3.432,0.025-6.864-0.031-10.295c-0.016-1.004,0.326-1.568,1.2-2.076
				c6.347-3.686,6.218-3.7,6.484,3.6c0.166,4.574,0.678,9.141,1.199,13.692c0.151,1.318-0.09,1.765-1.389,1.706
				C502.276,376.876,501.007,376.66,499.916,377.865z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(375)} fill={props.fillColors[375]} d="M364.287,714.457c7.329-1.468,14.398-0.895,21.46-0.406
				c2.2,0.152,4.392,0.438,6.582,0.702c1.276,0.153,1.907,0.544,1.37,2.138c-1.485,4.41-1.38,4.365-5.973,3.304
				c-4.529-1.047-9.145-1.718-13.776-1.944c-2.805-0.137-5.641,0.395-8.614,0.639C364.81,717.562,365.667,715.831,364.287,714.457z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(376)} fill={props.fillColors[376]} d="M355.539,642.857c-7.483-2.025-15.03-2.166-22.581-0.54
				c-2.065,0.443-1.992-1.34-2.449-2.17c-0.604-1.097,0.958-1.001,1.49-1.173c6.674-2.155,13.488-1.8,20.352-1.136
				c4.775,0.462,9.351,1.479,13.677,3.549c0.537,0.257,1.349,0.389,1.337,1.19c-0.01,0.754-0.809,0.869-1.277,1.269
				c-2.04,1.735-4.066,1.497-6.307,0.354c-1.312-0.671-2.836-0.93-4.265-1.373L355.539,642.857z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(377)} fill={props.fillColors[377]} d="M465.521,628.475c1.362,1.006,1.472,2.488,1.666,3.921
				c0.163,1.2,0.229,2.524,1.797,2.612c1.535,0.085,1.963-0.959,1.987-2.351c0.023-1.414,0.781-1.79,1.986-1.045
				c7.081,4.381,13.101,9.714,15.955,17.873c0.354,1.013,0.74,2.099-0.413,2.759c-1.176,0.673-1.403-0.69-1.917-1.228
				c-5.501-5.746-11.418-10.924-18.896-13.979c-1.745-0.713-0.902-2.368-1.361-3.526C465.713,631.969,465.758,630.166,465.521,628.475
				z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(378)} fill={props.fillColors[378]} d="M155.322,640.713c-1.51,1.757-3.473,0.807-5.02,0.458
				c-7.761-1.749-15.312-0.721-22.632,1.763c-3.477,1.18-6.433,1.91-9.277-1.208c1.927-2.059,4.817-2.52,7.481-3.3
				c6.373-1.866,12.948-2.181,19.53-1.919c3.05,0.121,6.002,1.086,8.97,1.842c1.812,0.462,0.457,1.685,0.866,2.438
				C155.244,640.784,155.322,640.713,155.322,640.713z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(379)} fill={props.fillColors[379]} d="M170.984,732.467c1.855,3.449,2.958,7.068,3.966,10.703
				c0.732,2.643,1.328,5.333,1.815,8.032c0.841,4.659-1.342,8.535-3.312,12.717C168.422,751.865,167.311,737.895,170.984,732.467z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(380)} fill={props.fillColors[380]} d="M282.702,398.308c-0.611-1.029,0.392-1.382,0.844-1.926
				c2.285-2.748,5.174-4.887,7.566-7.556c2.01-2.243,4.172-4.366,5.964-6.787c0.76-1.028,1.43-0.914,2.141-0.312
				c1.092,0.924,2.067,1.985,3.078,3.003c0.507,0.511,0.307,0.965-0.117,1.451c-3.024,3.476-5.917,7.069-9.309,10.21
				c-0.559,0.516-0.936,0.998-1.573-0.105c-0.685-1.186-1.591-0.824-2.119,0.311c-0.361,0.775-0.952,1.447-0.989,2.382
				c-0.019,0.453-0.26,0.897-0.405,1.346c-0.551,1.714-1.83,3.256-3.384,3.307c-1.655,0.054-0.858-2.133-1.42-3.215
				C282.621,399.726,282.808,399.006,282.702,398.308z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(381)} fill={props.fillColors[381]} d="M361.498,530.588c0.354-0.152,0.755-0.492,1.051-0.417
				c0.542,0.137,0.255,0.612,0.129,0.964c-0.215,0.596-0.309,1.286-0.674,1.768c-1.503,1.977-1.943,3.811-0.259,6.035
				c0.821,1.086,0.107,2.576-0.901,3.419c-3.448,2.882-6.112,6.307-8.208,10.297c-0.799,1.522-2.211,2.927-4.397,2.858
				c-1.062-0.033-1.504,1.034-2.107,1.755c-0.774,0.926-1.771,1.51-2.865,0.721c-1.104-0.794-0.312-1.873,0.216-2.493
				c1.092-1.28,1.218-2.686,1.198-4.229c-0.014-1.016,0.342-1.626,1.412-0.727c1.711,1.439,2.449,0.201,2.901-1.196
				c0.433-1.338,0.61-2.76,0.9-4.143c0.153-0.012,0.364-0.09,0.432-0.023c0.695,0.691,1.033,2.151,2.265,1.756
				c1.409-0.452,0.646-1.921,0.818-2.945c0.23-1.371-1.08-2.66-0.119-4.139c0.395-0.605,0.21-1.778,1.288-1.609
				c0.811,0.128,0.825,1.045,0.786,1.789c-0.017,0.318-0.022,0.642,0.013,0.958c0.097,0.879,0.371,2.063,1.144,2.118
				c1.043,0.075,1.988-1.006,2.004-1.997c0.046-2.925,0.494-5.873-0.27-8.79c-0.197-0.752-0.476-1.428-1.12-1.73
				c-2.031-0.955-0.782-2.363-0.507-3.578c0.07-0.31,0.672-1.249,1.187-0.508c0.405,0.582,1.832,0.05,1.413,1.416
				c-0.157,0.512-0.202,1.177-0.007,1.653c0.421,1.027,0.852,2.437,2.349,0.941L361.498,530.588z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(382)} fill={props.fillColors[382]} d="M465.03,319.903c-6.188-2.109-9.35-9.772-7.318-16.232
				c0.744-2.365,1.757-4.525,3.18-6.543c1.929-2.737,3.234-3.316,6.475-2.625c0.536,0.114,1.064,0.306,1.605,0.352
				c2.307,0.199,2.517,0.284,2.285,0.763c-0.562,1.168-0.083,2.854-2.376,3.15c-3.577,0.462-6.367,2.542-7.115,6.279
				c-0.684,3.424,0.553,6.374,3.353,8.563c0.982,0.768,1.397,1.583,0.888,2.877C465.584,317.558,465.725,318.818,465.03,319.903z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(383)} fill={props.fillColors[383]} d="M341.183,453.036c-0.286-0.068-0.572-0.138-0.854-0.207
				c-3.48,0.011-6.843,1.098-10.345,1.146c-1.265,0.018-2.479-1.251-3.74-0.133c0.007,0.008,0.039,0.039,0.044,0.044
				c-3.631-2.271-7.473-2.479-11.443-1.075c-0.561,0.198-1.108,0.991-1.633,0.274c-0.423-0.577,0.175-1.229,0.573-1.685
				c0.945-1.081,1.945-2,3.534-2.236c2.657-0.396,5.169-1.319,7.527-2.661c1.54-0.876,3.239-1.175,5.031-1.085
				c1.213,0.062,2.178,0.057,2.982,1.371c0.808,1.32,2.426,1.803,4.012,1.337c1.644-0.481,2.662-0.261,2.634,1.702
				c-0.03,2.079,1.874,1.464,3.002,2.102C341.803,452.089,341.212,452.258,341.183,453.036z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(384)} fill={props.fillColors[384]} d="M340.057,533.99l-0.146,0.067c0.309-0.068,0.619-0.128,0.925-0.208
				c0.982-0.257,1.578-0.927,1.4-1.907c-0.184-1.015-1.151-0.521-1.792-0.632c-0.448-0.077-0.971,0.146-1.354-0.389
				c0.142-0.82,0.84-0.938,1.494-1.169c2.999-1.054,5.785-2.509,8.29-4.481c1.279-1.008,2.658-1.604,4.365-1.626
				c2.576-0.033,3.366-2.262,4.437-4.056c0.277-0.464,0.262-1.122,0.579-1.539c0.593-0.777-0.102-2.538,1.333-2.42
				c1.033,0.086,0.545,1.615,0.83,2.459c0.693,2.055-0.028,3.94-1.655,5.326c-1.896,1.612-3.469,3.378-3.819,5.944
				c-0.176,1.293-1.414,1.842-1.83,3.119c-0.296-0.319-0.485-0.445-0.563-0.621c-0.332-0.746,0.449-2.002-0.618-2.281
				c-1.06-0.278-1.645,0.853-2.094,1.647c-1.223,2.159-2.939,3.938-4.388,5.92c-0.412,0.563-0.893,1.279-1.741,0.952
				c-0.789-0.305-0.333-1.112-0.463-1.688c-0.11-0.488,0.312-1.185-0.493-1.402c-0.656-0.179-1.051,0.3-1.407,0.717
				c-0.666,0.776-1.241,1.634-1.935,2.384c-0.521,0.565-1.186,1.452-1.986,0.881c-0.775-0.552-0.246-1.371,0.201-2.092
				C338.304,535.803,339.153,534.881,340.057,533.99z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(385)} fill={props.fillColors[385]} d="M532.806,585.482c-3.25-1.996-6.722-2.086-10.118-0.701
				c-3.477,1.417-7.371,0.967-10.738,2.687c-1.019,0.521-0.712-1.115-1.688-1.024c-0.44,0.04,0.02-0.776,0.206-1.164
				c1.064-2.201,3.19-3.529,4.544-5.495c0.159-0.173,0.356-0.276,0.59-0.31c1.664-0.436,3.478-1.632,3.95,1.518
				c0.191,1.274,3.482,1.467,4.312,0.338c0.742-1.009,1.473-2.051,2.025-3.168c0.867-1.753,2.322-1.984,3.956-1.685
				c1.607,0.294,1.424,1.87,1.927,2.965c1.005,2.183,1.934,4.562,3.998,5.864c1.124,0.708,2.183-0.608,2.282-1.788
				c0.176-2.083,1.137-2.057,2.596-1.301l-0.059-0.055c0.412,1.332,2.007,2.562,0.349,4.075c-0.002,0.002,0.079-0.085,0.076-0.083
				c-0.764-0.043-1.299,0.204-1.691,0.937c-0.621,1.16-1.573,1.074-2.567,0.458c-1.794,0.233-3.229-0.389-4.315-1.822
				c-0.192-0.255,0.125,0.065,0.028-0.238C532.441,585.404,532.621,585.381,532.806,585.482z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(386)} fill={props.fillColors[386]} d="M355.516,642.827c0.468,1.819,2.441,0.975,3.453,1.947
				c0.719,0.692,2.643-0.077,2.51,1.678c-0.078,1.032-3.343,2.32-4.373,1.899c-3.069-1.254-6.255-2.095-9.489-2.789
				c-0.944-0.203-1.794-0.13-2.643,0.322c4.276,0.63,8.392,1.617,12.156,3.957c-1.686-0.259-3.389-0.432-5.053-0.793
				c-5.591-1.211-11.158-1.147-16.726,0.116c-0.526,0.12-1.295,0.833-1.446-0.327c-0.104-0.796-0.441-1.848,0.867-2.115
				c1.222-0.25,2.449-0.469,4.106-0.783c-1.416-0.747-2.399-0.471-3.44-0.149c-1.302,0.402-2.42,0.446-2.781-1.378
				c-0.419-2.11,1.5-1.546,2.138-1.65c6.358-1.052,12.73-1.086,19.045,0.438c0.636,0.154,1.332,0.831,1.707-0.329
				C355.539,642.857,355.516,642.827,355.516,642.827z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(387)} fill={props.fillColors[387]} d="M452.906,372.376c0.474,0.196,1.751-0.712,2.085,0.57
				c0.434,1.67-1.026,3.86-2.426,4.009c-7.882,0.836-15.275,2.988-21.61,7.995c-0.684,0.54-1.271,1.227-2.012,1.661
				c-0.832,0.487-1.189,2.327-2.51,1.34c-1.218-0.911-1.108-3.93-0.062-4.859c2.435-2.163,5.085-4.075,7.948-5.581
				c5.265-2.77,10.937-4.398,16.817-5.275C451.599,372.167,452.089,372.304,452.906,372.376z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(388)} fill={props.fillColors[388]} d="M463.214,329.747c-7.962-3.725-12.808-9.799-14.692-18.154
				c-1.258-5.579-0.522-10.986,2.265-16.062c1.167-2.125,2.971-3.162,5.319-3.361c0.597-0.051,1.184,0.179,1.348,0.763
				c0.15,0.537-0.456,0.718-0.801,1.025c-4.603,4.092-6.444,9.392-5.645,15.3c0.961,7.094,5.181,12.23,11.4,15.601
				c1.485,0.804,1.806,1.466,1.174,2.895C463.341,328.298,463.351,328.954,463.214,329.747z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(389)} fill={props.fillColors[389]} d="M453.734,379.388c-0.015,3.307-0.741,3.804-4.082,4.226
				c-6.635,0.838-12.514,3.63-17.858,7.594c-0.509,0.378-0.942,0.926-1.508,1.148c-0.92,0.361-1.762,2.455-2.388,1.265
				c-0.621-1.181-1.627-3.212,0.114-4.827c5.38-4.988,11.579-8.354,18.731-10.094c1.947-0.474,3.933-0.128,5.847-0.793
				C453.819,377.481,453.721,378.597,453.734,379.388z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(390)} fill={props.fillColors[390]} d="M361.018,380.767c-2.581-2.313-3.976-5.469-5.847-8.283
				c-4.321-6.495-5.464-13.734-4.997-21.374c0.087-1.407,0.57-1.861,1.86-1.659c1.414,0.223,3.727-0.623,3.718,1.693
				c-0.039,10.156,2.413,19.826,5.256,29.445c0.021,0.068-0.026,0.156-0.046,0.238C360.958,380.831,361.018,380.767,361.018,380.767z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(391)} fill={props.fillColors[391]} d="M152.967,486.266c-0.646-0.387-1.291-0.773-1.936-1.16
				c-1.234-1.814-3.465-2.723-4.565-4.908c2.068-0.445,3.329,0.981,4.846,1.6c0.354,0.144,0.62,0.521,0.976,0.636
				c0.952,0.307,2.112,1.308,2.855,0.588c0.979-0.948-0.668-1.455-0.983-2.243c-0.142-0.357-0.261-0.725-0.392-1.09
				c1.069-0.482,1.956-0.116,2.839,0.242c1.212,0.492,2.467,0.517,3.753,0.453c0.704-0.034,1.29-0.134,1.424-0.918
				c0.134-0.78-0.301-1.188-1.042-1.301c-0.781-0.117-1.482-0.611-1.41-1.306c0.104-1.006,1.03-0.167,1.582-0.378
				c1.777-0.678,3.607-1.216,5.623-1.875c-0.193,0.397-0.219,0.559-0.313,0.627c-0.977,0.706-2.961,1.089-2.667,2.202
				c0.339,1.286,2.183,0.641,3.421,0.718c0.683,0.043,1.706,0.126,1.962,0.942c0.256,0.818-0.542,1.153-1.166,1.56
				c-0.989,0.645-1.904,1.41-2.804,2.179c-0.565,0.482-1.705,0.565-1.547,1.575c0.162,1.041,1.303,0.94,2.042,1.297
				c0.637,0.022,1.274,0.046,1.91,0.068c0.646,0.434,0.232,0.849-0.111,1.102c-3.962,2.924-8.361,3.591-13.065,2.365
				c-1.053-0.274-1.225-0.764-0.557-1.315C155.355,486.506,153.606,486.642,152.967,486.266z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(392)} fill={props.fillColors[392]} d="M396.886,528.865c0.11,1.232,1.14,1.674,1.99,2.225
				c0.912,0.592,1.495,1.409,1.929,2.364c0.285,0.629,0.34,1.267-0.299,1.715c-0.633,0.442-1.036-0.107-1.438-0.458
				c-1.695-1.482-3.638-2.243-5.907-2.082c-1.611,0.114-2.556-1.068-3.691-1.885c-2.513-1.806-4.961-3.34-6.154-6.735
				c-0.837-2.38-3.78-3.958-6.65-4.415c-2.453-0.39-3.003-0.986-2.947-3.898c1.102,0.082,1.353,1.498,2.562,1.521
				c1.463,0.027,1.401-1.345,2.152-2c4.082,4.109,9.03,6.927,14.175,9.438c1.119,0.548,1.902,1.413,2.517,2.434
				c0.467,0.775,1.05,1.386,1.826,1.828L396.886,528.865z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(393)} fill={props.fillColors[393]} d="M124.329,597.788c1.527-0.364,3.054-0.729,4.582-1.093
				c-0.243-0.915-1.418-0.095-1.443-1.015c-0.02-0.771,0.684-0.997,1.219-1.313c3.906-2.308,8.028-2.545,12.271-1.168
				c1.845,0.6,2.641,1.909,1.296,3.539c-0.561,0.679,0.032,0.554,0.164,0.65c1.02,0.753,1.403,1.602,0.057,2.272
				c-0.84,0.419-0.076,0.686,0.011,0.721c1.188,0.483,0.525,1.656,1.304,2.677c-3.894-1.02-7.361-2.092-11.042-1.933
				c-1.28,0.055-2.529,0.087-3.75,0.531c-1.792,0.652-3.572,0.643-5.335-0.298c0.784-1.077,2-1.335,3.196-1.688
				c-2.642-0.395-4.636,2.533-7.52,1.154c1.325-1.624,3.591-1.744,5.046-3.098L124.329,597.788z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(394)} fill={props.fillColors[394]} d="M478.345,378.605c-0.178-2.93-0.628-5.846-0.43-8.798
				c0.047-0.691-0.165-1.381,0.704-1.822c1.681-0.851,3.318-1.79,4.943-2.743c1.02-0.598,1.354-0.364,1.382,0.806
				c0.132,5.671,0.971,11.269,1.845,16.861c0.214,1.37-1.501,2.544-2.864,2.038c-0.436-0.162-0.875-0.345-1.329-0.424
				c-1.226-0.214-3.144,0.617-3.558-0.34c-0.573-1.323-0.932-3.009-0.7-4.622C478.383,379.249,478.345,378.924,478.345,378.605z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(395)} fill={props.fillColors[395]} d="M149.187,710.186c-1.004-1.328-1.927-2.416-2.7-3.604
				c-1.076-1.652,0.01-3.395,0.136-5.033c0.474-6.175,1.739-12.357-2.227-18.261c2.509,1.161,3.383,2.017,5.651,5.315
				c2.761,4.015,5.025,8.252,6.013,13.086c0.142,0.692,0.663,1.631-0.526,1.525c-3.443-0.307-4.116,2.331-5.207,4.581
				C149.982,708.512,149.643,709.23,149.187,710.186z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(396)} fill={props.fillColors[396]} d="M343.029,725.949c0,0,0.137-0.053,0.134-0.052c4.127-0.441,7.852-2.367,11.866-3.231
				c0.681-0.146,1.333-0.427,2.416-0.783c-0.968,2.288-0.677,4.951-3.811,5.343c-0.076,0.841,0.973,0.356,0.937,0.751
				c-0.135,1.47-1.332,2.631-1.284,4.243c0.007,0.244-1.645,0.731-2.545,0.773c-3.408,0.159-6.731,0.63-9.803,2.2
				c-1.272,0.651-1.83-0.521-2.954-0.779c3.947-4.801,9.336-5.674,15.092-6.402c-2.561-0.94-8.708,0.348-11.444,2.206
				c-1.103,0.748-2.116,1.655-3.287,2.266c-1.526,0.795-3.302,0.445-4.899,0.362c-0.987-0.051-0.15-0.9,0.312-1.239
				c1.976-1.449,4.023-2.779,6.227-3.87C341.037,727.216,342.017,726.55,343.029,725.949z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(397)} fill={props.fillColors[397]} d="M425.354,640.966c0.697-0.106,1.408-1.288,2.055,0.24
				c0.661,1.558,1.712,1.121,2.587,0.106c1.406-1.63,2.381-3.515,3.137-5.523c0.247-0.658,0.513-1.332,0.899-1.911
				c1.068-1.598,2.518-1.634,3.67-0.149c3.735,4.808,3.734,4.807-1.509,7.663c-4.898,2.669-8.698,6.64-12.476,10.621
				c-0.609,0.643-1.109,2.218-1.879,1.565c-0.949-0.806-2.27-1.954-1.398-3.846c1.233-2.677,2.555-5.265,4.439-7.548
				c0.287-0.349,0.372-0.862,0.552-1.3L425.354,640.966z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(398)} fill={props.fillColors[398]} d="M452.231,722.543c-0.222,0.107-0.442,0.215-0.664,0.321
				c-0.956,0.307-1.827,0.058-2.668-0.402c-2.255-3.553-6.003-4.524-9.704-5.19c-6.565-1.182-13.097-1.297-18.877,2.885
				c-1.17,0.847-1.637-0.316-2.187-0.706c-1.028-0.729,0.134-1.256,0.555-1.591c3.269-2.601,7.139-4.022,11.126-4.862
				c5.708-1.202,11.268-0.357,16.543,2.239c2.565,1.263,4.341,3.415,6.371,5.3C453.929,721.652,452.414,721.886,452.231,722.543z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(399)} fill={props.fillColors[399]} d="M240.896,563.538c-2.711-1.526-4.556-3.423-5.63-5.99
				c-0.851-2.035-1.24-4.226-3.418-5.556c-1.464-0.894-1.602-1.987,0.561-2.534c2.927-0.74,5.698-1.812,8.137-3.779
				c1.235-0.996,2.601,0.454,3.841,0.933c0.963,0.372,0.595,1.371,0.405,2.106C243.54,553.566,242.25,558.405,240.896,563.538z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(400)} fill={props.fillColors[400]} d="M366.844,506.68c-1.176-0.588-0.788-1.414-0.82-2.05
				c-0.16-3.165-0.656-3.711-3.894-3.661c-1.95,0.031-3.837-0.145-5.45-1.291c-0.659-0.468-1.766-1.125-1.229-2.061
				c0.534-0.933,1.532-0.26,2.318,0.075c1.812,0.773,3.604,1.603,5.459,2.257c0.662,0.234,1.627,0.61,2.145-0.356
				c0.389-0.729-0.031-1.322-0.474-1.945c-2.837-4.003-5.436-8.152-7.493-12.62c-0.288-0.626-0.887-1.175-0.43-2.204
				c2.85,2.271,4.524,5.292,6.301,8.249c0.313,0.521,0.27,1.661,1.292,1.371c1.109-0.314,0.581-1.366,0.513-2.043
				c-0.111-1.084-0.304-2.239-0.794-3.192c-3.154-6.136-2.627-12.604-1.987-19.126c0.162-1.652,0.26-3.307,0.535-4.948
				c0.083-0.498,0.107-1.022,0.719-1.034c0.641-0.012,0.572,0.552,0.703,1.016c0.796,2.824,0.432,5.709,0.416,8.556
				c-0.031,5.411,0.6,10.681,1.549,16.033C367.31,493.837,367.341,500.197,366.844,506.68z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(401)} fill={props.fillColors[401]} d="M453.189,414.996c-0.816,0.245-1.549,0.547-2.311,0.673
				c-0.89,0.146-1.953-0.238-1.92,1.359c0.03,1.429-0.025,2.753,1.707,3.21c0.375,0.099,0.755,0.294,1.124,0.275
				c3.657-0.19,4.717,2.645,6.024,5.162c0.877,1.69,0.412,2.414-1.453,2.557c-1.642,0.126-3.308-0.342-4.938,0.372
				c-0.765,0.335-1.214-0.251-1.588-0.997c-1.952-3.895-3.837-7.812-5.221-11.954c-0.188-0.563-0.229-1.021,0.086-1.575
				c1.44-2.542,2.828-5.113,4.239-7.673c0.188-0.343,0.381-0.78,0.837-0.708c0.448,0.07,0.489,0.529,0.615,0.917
				C451.287,409.356,452.215,412.088,453.189,414.996z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(402)} fill={props.fillColors[402]} d="M403.67,725.868c-0.179-0.896-1.696-0.451-1.603-1.436
				c0.154-1.63,0.481-3.248,0.815-4.854c0.139-0.664,0.682-0.232,0.917-0.087c5.426,3.352,11.28,6.061,15.986,10.496
				c2.288,2.156,4.163,4.667,6.376,7.635c-4.45-1.111-8.776-1.024-11.613-4.711c-1.096-1.423-2.671-2.184-4.095-3.159
				C407.793,730.166,403.978,727.981,403.67,725.868z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(403)} fill={props.fillColors[403]} d="M366.45,770.061c1.036-2.849,2.466-5.551,3.041-8.569
				c0.933-4.898,1.525-9.742,0.844-14.796c-0.501-3.71-0.579-7.6,0.762-11.282c0.391-1.073,0.583-2.309,2.115-2.447
				c1.396-0.125,1.836,0.866,2.485,1.796c1.39,1.988,1.365,4.245,1.097,6.401c-0.938,7.552-3.543,14.638-6.517,21.588
				C369.2,765.267,368.237,767.853,366.45,770.061z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(404)} fill={props.fillColors[404]} d="M451.812,306.369c0.412-4.776,2.237-9.155,6.333-12.388
				c1.022-0.808,2.202-0.73,3.043,0.096c0.904,0.889-0.392,1.304-0.774,1.864c-3.63,5.307-5.77,10.893-3.39,17.364
				c1.217,3.309,3.64,5.584,6.693,7.061c1.815,0.877,1.199,2.077,0.785,3.119c-0.646,1.621-1.725,0.512-2.513,0.041
				C455.62,319.712,452.342,314.005,451.812,306.369z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(405)} fill={props.fillColors[405]} d="M158.065,758.764c0.9-2.816,2.423-5.4,2.95-8.377c0.78-4.412,1.601-8.726,1.03-13.327
				c-0.471-3.805-0.56-7.771,0.302-11.637c0.374-1.671,0.802-3.715,2.63-3.748c1.937-0.035,2.646,2.056,3.081,3.754
				c0.717,2.804,0.073,5.592-0.462,8.346C165.873,742.642,162.463,750.884,158.065,758.764z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(406)} fill={props.fillColors[406]} d="M282.055,395.699c-0.406-2.218-0.719-4.084-1.1-5.936
				c-0.177-0.86-0.025-1.513,0.615-2.156c3.53-3.551,7.111-7.059,10.094-11.108c0.618-0.838,1.172-1.083,1.99-0.112
				c1.024,1.217,2.738,2.123,3.187,3.482c0.495,1.501-1.522,2.365-2.434,3.489C290.779,387.832,286.79,391.958,282.055,395.699z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(407)} fill={props.fillColors[407]} d="M393.376,709.107c-5.105-2.821-9.805-6.398-15.666-7.777
				c-5.706-1.343-11.14-0.945-16.228,1.98c-1.438,0.827-1.731,0.123-2.443-0.778c-0.979-1.238-0.114-1.688,0.714-2.182
				c6.361-3.802,13.175-4.473,20.103-2.202c6.078,1.992,11.181,5.82,16.062,9.885c0.16,0.133,0.216,0.392,0.393,0.729
				c-1.072,0.098-2.043,0.188-3.011,0.279C393.304,709.044,393.376,709.107,393.376,709.107z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(408)} fill={props.fillColors[408]} d="M273.949,286.339c2.35-1.145,4.209-0.075,6.064,1.016
				c-0.692,0.349-1.64,0.217-2.224,0.925c0.348,0.744,0.974,1.179,1.804,1.253c-0.273,1.259-1.522,0.428-1.934,1.17
				c0.01,0.453,0.335,0.67,0.632,0.945c0.629,0.583,2.067,0.236,2.089,1.177c0.027,1.188-1.293,0.388-1.887,0.744
				c-0.832,0.499-0.199,1.011,0.23,1.24c0.66,0.354,1.533,0.291,2.206,1.162c-1.812,0.672-1.982,1.628-0.583,2.87
				c0.852,0.757,0.627,1.554,0.079,2.509c-1.136,1.983-1.929,4.141-3.228,6.064c-0.663,0.981-0.988,0.932-1.756,0.269
				c-0.586-0.506-1.4-0.756-1.964-1.279c-1.004-0.932-2.867-1.97-0.052-3.031c0.139-0.052,0.168-0.393,0.247-0.593
				c-0.775-0.747-1.97,0.199-2.647-0.789c0.68-0.458,1.759,0.454,2.125-0.426c0.521-1.253-1.152-1.554-1.445-2.771
				c1.075-0.043,1.754-0.771,2.439-1.461c-0.252-0.885-0.963-0.437-1.417-0.631c-0.382-0.164-0.712-0.391-0.711-0.832
				c0.001-0.403,0.366-0.495,0.682-0.518c1.521-0.112,1.18-0.921,0.979-1.993c-0.121-0.645-1.37-1.882,0.645-2.322
				c0.76-0.166-0.134-1.206-0.471-1.726c-0.297-0.457-0.572-0.796-0.008-1.153C274.956,287.453,274.967,287.471,273.949,286.339z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(409)} fill={props.fillColors[409]} d="M375.352,727.152c2.161,1.138,4.593,1.407,6.885,2.126
				c1.693,0.531,2.428,1.124,0.683,2.539c-0.677,0.548-1.24,1.234-1.851,1.863c-2.451,2.528-2.466,2.485-4.582-0.235
				c-1.92-2.466-4.956-2.425-6.235,0.81c-0.657,1.663-1.355,1.708-2.62,0.786c-1.174-0.855-2.62-1.308-3.971-1.412
				c-2.064-0.16-1.781-1.013-1.374-2.471c1.209-4.322,1.153-4.22,5.612-4.383c2.55-0.094,5.016,0.568,7.541,0.453
				C375.437,727.227,375.352,727.152,375.352,727.152z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(410)} fill={props.fillColors[410]} d="M292.39,400.753c-0.74-1.474,0.346-2.461,1.464-3.545
				c3.141-3.043,6.04-6.321,8.709-9.778c1.034-1.338,1.64-1.235,2.803-0.212c3.637,3.2,3.596,3.079,0.307,6.638
				c-3.513,3.802-7.007,7.617-10.766,11.179c-0.452,0.428-0.822,1.203-1.477,1.099c-0.896-0.142-0.297-1.092-0.568-1.595
				C292.254,403.411,292.475,402.205,292.39,400.753z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(411)} fill={props.fillColors[411]} d="M65.342,644.166c1.144,0.473,1.81-0.498,2.49-1.001
				c6.229-4.603,13.252-7.02,20.853-8.193c3.194-0.494,6.344-0.28,9.513-0.371c0.996-0.028,2.494-0.002,2.53,1.22
				c0.041,1.355-1.184,1.805-2.588,1.713c-8.918-0.583-17.014,1.923-24.518,6.622c-0.756,0.474-1.546,0.85-1.777,1.828
				c-0.009,0.012,0.023-0.022,0.013-0.009c-1.153-0.748-1.674,0.488-2.347,0.882c-0.962,0.564-2.356,2.04-2.792,1.489
				c-0.854-1.08-1.867-2.545-1.302-4.255C65.417,644.09,65.342,644.166,65.342,644.166z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(412)} fill={props.fillColors[412]} d="M179.229,681.766c1.588,3.953,1.813,7.713,2.329,11.408
				c0.754,5.4,0.189,10.916,1.409,16.282c0.22,0.969-2.546,5.337-3.498,5.648c-0.723,0.236-0.75-0.274-0.792-0.781
				c-0.658-8.134-1.948-16.244-1.333-24.432C177.539,687.3,177.557,684.565,179.229,681.766z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(413)} fill={props.fillColors[413]} d="M40.874,754.378c1.917-3.352,2.884-6.864,3.363-10.518
				c0.579-4.427,0.501-8.856-0.002-13.277c-0.402-3.532,0.182-6.935,1.237-10.267c0.236-0.745,0.532-1.716,1.696-1.76
				c1.155-0.043,1.739,0.779,2.266,1.526c1.807,2.565,1.456,5.614,1.013,8.323c-1.442,8.83-4.453,17.199-8.47,25.197
				C41.816,753.92,41.7,754.35,40.874,754.378z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(414)} fill={props.fillColors[414]} d="M341.232,547.332c-0.076,0.727,0.319,0.573,0.671,0.322
				c1.22-0.874,2.772-1.537,3.561-2.71c2.144-3.19,5.208-5.76,6.565-9.463c0.604-1.646,2.001-2.65,2.896-4.054
				c0.563-0.882,1.3-0.771,1.916,0.061c0.095,0.128,0.208,0.243,0.297,0.376c1.075,1.617,0.827,8.315-0.476,10.161
				c-0.662-0.789-0.255-1.695-0.437-2.467c-0.229-0.973-0.308-2.183-1.664-2.257c-1.227-0.067-1.635,0.835-2.115,1.855
				c-0.918,1.945,0.354,3.731,0.12,5.593c-0.039,0.312-0.058,0.626-0.09,0.976c-1.119,0.08-1.213-0.85-1.606-1.453
				c-0.323-0.494-0.374-1.447-1.225-1.159c-0.587,0.199-0.814,0.969-0.772,1.611c0.104,1.55-0.378,2.981-0.764,4.439
				c-0.254,0.957-0.502,1.4-1.585,0.608c-1.88-1.374-2.62-1.086-2.623,1.315c-0.004,2.908-1.538,4.619-3.599,6.218
				c-0.651,0.505-1.373,0.81-1.94,0.393c-0.92-0.677-0.104-1.567,0.302-2.044c0.94-1.104,2.115-2.01,3.493-3.269
				c-1.916-0.395-3.44-0.196-5.056-0.073c0.316-2.614,3.495-2.758,4.127-4.99C341.231,547.316,341.232,547.332,341.232,547.332z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(415)} fill={props.fillColors[415]} d="M243.332,292.959c3.414,1.207,6.381,2.254,9.346,3.306
				c0.908,0.322,1.588,0.803,2.181,1.682c1.361,2.017,2.891,3.935,4.5,5.765c3.163,3.598,4.599,8.037,6.337,12.403
				C261.671,314.541,247.24,298.688,243.332,292.959z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(416)} fill={props.fillColors[416]} d="M324.738,745.182c0.973,3.355,2.913,6.396,3.902,9.699
				c0.665,2.222-1.361,4.451-2.734,6.354c-1.836,2.545-3.94,4.895-6.169,7.256C319.97,760.463,318.599,752.145,324.738,745.182z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(417)} fill={props.fillColors[417]} d="M293.43,322.727c-6.708,2.19-13.255,3.491-19.808,4.758
				c-1.084,0.21-1.946,0.038-2.226-1.436c-0.793-4.177-0.87-4.189,3.248-5.196c3.503-0.857,7.028-1.627,10.532-2.482
				c0.765-0.187,1.421-0.088,2.047,0.316C289.194,319.958,291.155,321.244,293.43,322.727z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(418)} fill={props.fillColors[418]} d="M262.155,646.567c-1.174-0.624-2.348-1.247-3.521-1.871
				c-9.622-3.339-19.313-4.082-29.113-0.776c-1.689,0.629-3.378,1.259-5.063,1.886c-0.74,0.254-1.502,0.469-2.222,0.78
				c-0.539,0.234-1.013,0.988-1.598,0.141c-0.5-0.724-0.562-1.459,0.121-2.162c1.183-1.22,2.582-2.197,4.152-2.715
				c4.85-1.598,9.657-3.108,14.988-2.186c2.814,0.488,5.867-0.17,8.755-0.152c5.865,0.037,11.566,1.411,16.347,5.214
				c0.744,0.592,0.578,1.349,0.221,2.158c-0.485,1.1-0.982,0.205-1.486,0.103C263.199,646.879,262.606,646.997,262.155,646.567z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(419)} fill={props.fillColors[419]} d="M461.346,541.525c0.172,2.593,0.733,2.733,4.523,0.884
				c-0.887,1.88-0.325,3.92-3.8,3.69c-2.412-0.159-5.852,0.999-6.901,4.419c-0.375,1.228-1.361,1.951-2.355,2.569
				c-1.472,0.915-3.062,1.637-4.563,2.509c-2.052,1.192-4.237,0.158-6.347,0.429c-0.558,0.072-1.107-0.245-1.181,0.626
				c-0.085,1.016-0.978,1.197-1.687,1.446c-0.262,0.092-0.661-0.368-0.706-0.809c-0.093-0.919,0.069-2.004,0.853-2.328
				c4.174-1.728,6.325-6.214,11.104-7.392c4.036-0.994,7.735-3.383,11.134-5.968L461.346,541.525z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(420)} fill={props.fillColors[420]} d="M236.839,668.123c1-0.166,1.891,0.24,2.804,0.493c2.823,0.781,5.644,0.12,8.449-0.076
				c3.097-0.217,6.066,0.28,9.003,1.109c0.831,0.234,1.853,0.751,1.107,1.838c-0.586,0.854-0.267,2.631-2.442,2.118
				c-4.7-1.108-9.526-1.552-14.352-1.497c-3.549,0.04-7.135,0.288-10.576,1.235c-2.16,0.596-2.948-0.772-3.58-2.175
				c-0.508-1.126,0.708-1.34,1.485-1.628C231.354,668.57,234.146,668.621,236.839,668.123z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(421)} fill={props.fillColors[421]} d="M425.718,723.395c-2.104,1.466-3.304-0.348-4.687-1.341
				c-0.713-0.511-0.457-1.136,0.208-1.526c4.012-2.359,8.208-3.779,13.003-3.173c3.286,0.415,6.515,0.817,9.624,2.084
				c1.863,0.759,3.087,2.505,5.076,2.979c0.564,1.788-1.033,1.985-1.953,2.729c-0.957,0.773-1.341-0.134-2-0.468
				c-6.28-3.182-12.679-4.795-19.391-1.216L425.718,723.395z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(422)} fill={props.fillColors[422]} d="M163.089,611.761c1.244,0.171,2.672-0.738,3.644,1.001
				c0.455,0.815,1.441,0.303,1.931-0.444c0.435-0.663,0.733-1.414,1.165-2.08c0.74-1.143,2.035-1.361,3.132-1.17
				c1.103,0.193,0.443,1.425,0.603,2.185c0.497,2.353-0.541,3.483-2.809,4.226c-4.208,1.375-7.903,3.752-11.214,6.71
				c-0.411,0.367-0.282,0.655-0.097,1.013l0.082-0.084c-1.381,0.685-5.133-0.27-6.126-1.579c-0.578-0.762-0.016-1.287,0.41-1.865
				c2.183-2.963,5.092-5.06,8.177-6.962c0.42-0.259,1.132-0.249,1.151-0.994L163.089,611.761z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(423)} fill={props.fillColors[423]} d="M529.146,352.856c0.041-3.137-1.125-5.74-1.652-8.461
				c-0.326-1.679,1.091-3.708,2.736-3.761c0.662-0.021,0.696,0.383,0.821,0.89c0.754,3.037,1.618,6.047,2.313,9.096
				c0.202,0.889,0.135,1.856-0.251,2.808c-0.395,0.975-0.391,2.054,0.705,2.733c1.664,1.031,0.521,2.016-0.123,2.88
				c-0.956,1.285-1.738,0.366-2.537-0.333c-3.752-3.288-7.916-6.08-11.503-9.569c-0.601-0.583-2.122-0.814-1.264-1.946
				c0.721-0.95,2.023-1.654,3.327-0.588c2.141,1.749,4.254,3.53,6.376,5.301C528.387,352.149,528.659,352.415,529.146,352.856z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(424)} fill={props.fillColors[424]} d="M419.558,644.833c0.061,0.065,0.116,0.121,0.165,0.166c1,1.145,0.899,3.862-0.18,4.786
				c-0.681,0.583-0.962-0.148-1.368-0.432c-5.851-4.077-11.722-8.1-18.877-9.63c-3.231-0.691-6.454-1.412-9.766-1.275
				c-0.071-2.558,1.097-3.195,3.334-2.959c8.745,0.926,17.009,3.242,24.383,8.179C417.997,644.169,418.9,644.234,419.558,644.833z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(425)} fill={props.fillColors[425]} d="M229.889,290.087c4.707,0.993,9.247,0.08,12.393,4.619
				c2.911,4.2,6.536,7.906,9.829,11.843c0.501,0.599,0.91,1.277,1.413,1.992C248.848,307.875,235.901,297.839,229.889,290.087z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(426)} fill={props.fillColors[426]} d="M516.3,348.151c1.51,1.125,3.08,2.239,4.59,3.432c3.039,2.4,6.017,4.877,9.072,7.255
				c2.27,1.767,2.275,2.807,0.229,4.837c-1,0.992-1.767,0.488-2.479-0.014c-2.39-1.683-4.77-3.393-7.028-5.245
				c-2.63-2.156-5.297-4.251-8.092-6.187c-1.337-0.927-1.526-1.649,0.231-2.327C513.987,349.454,515.065,348.783,516.3,348.151z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(427)} fill={props.fillColors[427]} d="M480.974,328.624c-3.536-4.479-6.767-8.562-9.977-12.663
				c-0.174-0.222-0.171-0.584-0.251-0.886c4.529-0.663,9.016-1.323,13.504-1.97c0.686-0.099,1.178-0.193,0.947,0.943
				C484.21,318.907,482.794,323.625,480.974,328.624z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(428)} fill={props.fillColors[428]} d="M64.267,681.061c2.128,1.636,4.029,3.461,5.538,5.668
				c2.42,3.54,4.434,7.263,5.544,11.448c0.498,1.879,1.042,3.628-1.689,4.203c-0.328-0.451-0.766-1.004-1.146-1.593
				c-0.74-1.146-1.854-1.432-3.09-1.312c-1.219,0.118-1.271,1.261-1.565,2.099c-0.229,0.648-0.61,1.258-0.544,2.028
				c0.043,0.508-0.357,1.279-0.787,1.156c-0.561-0.161-1.034-0.588-0.896-1.601c0.533-3.891,0.876-7.81,1.184-11.727
				C67.104,687.732,66.007,684.354,64.267,681.061z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(429)} fill={props.fillColors[429]} d="M98.024,733.223c-6.658-18.302-9.503-37.871-15.171-56.743
				c1.661,1.883,2.625,4.136,3.665,6.346c3.314,7.052,3.884,14.841,5.882,22.246c2.231,8.272,3.804,16.724,5.593,25.112
				C98.22,731.245,98.024,732.398,98.024,733.223z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(430)} fill={props.fillColors[430]} d="M89.268,245.377c-0.512,1.086-0.922,2.11,0.768,2.229
				c0.544,0.039,1.003,0.474,1.238,1.031c3.029,7.198,8.56,12.689,12.932,18.971c0.33,0.473,0.595,0.99,1.225,2.049
				c-5.306-3.086-9.764-6.258-13.961-9.737c-1.232-1.021-1.828-2.961-2.395-4.592c-0.598-1.723-1.468-3.292-2.234-4.918
				C85.07,246.653,84.277,247.17,89.268,245.377z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(431)} fill={props.fillColors[431]} d="M412.051,554.137c-0.121-0.225-0.242-0.45-0.363-0.675
				c-0.02-0.393-0.008-0.79-0.063-1.177c-0.186-1.313-1.22-2.785,1.009-3.488c2.021-0.637,3.677-0.742,4.668,1.592
				c0.822,1.936,2.102,3.698,1.078,6.025c-0.351,0.799-0.35,1.937,0.195,2.779c1.55,2.398,0.148,4.595-0.52,6.755
				c-0.592,1.914-3.611,3.061-5.121,2.192c-0.986-0.567-1.868-1.19-0.181-2.125c1.746-0.968,1.605-2.263,0.202-3.421
				c-1.484-1.224-2.13-2.295-0.033-3.53c0.708-0.418,0.778-1.195,0.484-1.92C412.992,556.126,412.506,555.138,412.051,554.137z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(432)} fill={props.fillColors[432]} d="M253.857,737.407c5.754-1.503,11.323-0.24,16.922,0.724
				c0.969,0.166,1.112,0.709,1.319,1.362c0.453,1.427,0.783,2.898,1.331,4.285c0.502,1.269,0.051,1.37-1.027,1.16
				c-4.045-0.788-8.107-1.508-12.253-1.146c-2.242,0.195-3.761-0.438-4.577-2.736C255.139,739.835,254.027,738.869,253.857,737.407z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(433)} fill={props.fillColors[433]} d="M387.869,518.021c0.161,0.083,0.322,0.165,0.483,0.248
				c1.803,2.182,4.555,2.261,6.959,2.998c3.275,1.005,6.244,4.662,6.178,8.089c-0.012,0.598-0.15,1.116-0.76,1.354
				c-0.646,0.251-1.242,0.126-1.779-0.316c-0.658-0.543-1.087-1.399-2.06-1.524c-0.005-0.003,0.07,0.056,0.064,0.051
				c-0.878-2.428-2.319-4.353-4.777-5.353c-3.831-1.559-6.936-4.328-10.519-6.305c-0.604-0.333-1.347-0.938-1.459-1.526
				c-0.249-1.301-4.231-2.75,0.017-4.053c0.276-0.085,0.347-0.843,0.512-1.287c-0.15-0.128-0.229-0.288-0.228-0.486
				c0.206-1.408-1.583-1.7-1.433-3.21c2.751,2.544,5.518,5.064,8.519,7.464c-1.082,0.769-2.711-0.896-2.771,0.913
				C384.756,516.836,386.045,517.867,387.869,518.021z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(434)} fill={props.fillColors[434]} d="M78.675,693.396c2.677,1.986,3.485,4.872,3.994,7.791
				c1.213,6.969,2.256,13.966,3.372,20.951c0.195,1.219-0.137,2.248-1.181,2.919c-0.981,0.631-1.93,1.279-2.889,2.273
				C82.585,715.808,79.488,704.756,78.675,693.396z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(435)} fill={props.fillColors[435]} d="M475.17,588.595c0.884,0.631,1.768,1.262,2.651,1.893
				c0.684,0.217,1.447,0.107,2.087,0.508c0.835,0.636,0.818,1.675,1.196,2.528c0.547,0.568,1.154,1.091,1.625,1.716
				c0.587,0.78,1.606,1.666,0.366,2.611c-1.054,0.804-1.786,0.073-2.589-0.753c-1.976-2.035-4.119-3.899-6.663-5.23
				c-0.855-1.935-2.914-1.81-4.404-2.145c-9.279-2.087-17.569-0.103-24.516,6.642c-0.793,0.77-1.545,1.634-2.766,1.735l0.075,0.053
				c-0.685-0.668-0.406-1.444,0.118-1.866c0.941-0.756,1.104-1.873,1.654-2.805c0.152-0.202,0.333-0.372,0.544-0.511
				c3.399-1.897,6.627-4.128,10.35-5.395c4.311-1.466,8.711-2.164,13.262-1.433l-0.071-0.039
				C470.449,586.935,472.81,587.765,475.17,588.595z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(436)} fill={props.fillColors[436]} d="M457.486,359.303c0.046,2.285-0.753,2.899-2.918,2.275
				c-6.906-1.991-13.147-5.339-18.978-9.465c-0.707-0.5-2.251-1.076-1.502-2.392c0.669-1.176,4.157-1.938,4.875-1.352
				c4.958,4.051,10.564,6.911,16.58,9.017C457.457,358.055,457.461,358.046,457.486,359.303z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(437)} fill={props.fillColors[437]} d="M364.009,528.965c-1.405-0.343-2.508-0.216-2.51,1.621
				c-0.001,0.002,0.071-0.079,0.07-0.077c-2.021-0.463-1.749-1.742-1.024-3.076c0.444-0.817,1.317-1.464,0.744-2.569
				c-0.138-0.015-0.291-0.059-0.437-0.042c-0.862,0.103-1.252,2.088-2.242,0.904c-0.791-0.947,0.88-1.327,1.257-2.092
				c1.391-2.819,4.916-4.059,5.316-8.304c0.846,1.598,1.26,2.476,1.765,3.299c0.347,0.565,0.884,0.984,1.608,0.733
				c0.635-0.219,0.697-0.807,0.691-1.39c-0.003-0.32,0.005-0.641-0.007-0.96c-0.021-0.596,0.092-1.296,0.681-1.383
				c0.704-0.104,0.637,0.67,0.85,1.168c0.771,1.796,0.021,3.813,0.898,5.581c0.277,0.56,0.339,1.254,0.698,1.739
				c1.141,1.538,2.238,2.861-0.996,3.055c-1.288,0.077-1.244,1.155-0.857,2.208c0.298,0.81,0.68,1.76-0.823,1.885
				c-1.324,0.109-1.453-0.71-1.362-1.666c0.109-1.151-0.199-2.224-0.617-3.242c-0.313-0.768-0.964-1.263-1.904-1.136
				c-0.914,0.123-1.033,0.74-1.136,1.528c-0.101,0.768-0.467,1.5-0.717,2.248L364.009,528.965z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(438)} fill={props.fillColors[438]} d="M87.597,722.837c-1.145-1.721-0.836-3.582-1.002-5.132
				c-0.596-5.564-1.478-11.082-2.585-16.546c-0.626-3.093-1.75-6.028-3.899-8.684c-1.712-2.114-2.374-5.118-3.339-7.786
				c-0.855-2.364-1.485-4.811-2.215-7.22c-0.162-0.536-0.388-1.2,0.157-1.517c0.615-0.357,0.973,0.345,1.352,0.71
				c1.975,1.901,3.197,4.311,4.393,6.716c2.847,5.73,5.188,11.654,6.576,17.925c0.066,0.299,0.432,0.727,0.342,0.837
				c-3.919,4.808,0.068,9.556,0.038,14.322C87.401,718.49,88.531,720.452,87.597,722.837z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(439)} fill={props.fillColors[439]} d="M469.319,542.929c0.721,1.158-0.472,3.334,1.55,3.577c1.784,0.214,2-1.709,2.932-3.418
				c0.723,3.954,3.895,5.643,5.91,8.254c0.344,0.446,1.295,0.835,0.69,1.51c-0.539,0.603-1.133-0.016-1.624-0.442
				c-0.215-0.188-0.517-0.274-0.974-0.504c0.047,1.751,1.033,3.142,0.902,4.692c-0.249,0.059-0.414,0.156-0.53,0.116
				c-0.781-0.274-1.27-1.755-2.136-1.053c-0.79,0.642-0.469,1.957-0.146,2.926c0.27,0.812,0.789,1.545,1.235,2.291
				c0.552,0.924,0.5,1.835-0.087,2.682c-0.251,0.363-0.646,0.888-1.146,0.661c-0.916-0.415-1.045-1.473-1.106-2.245
				c-0.183-2.272-0.109-4.564-0.147-6.849c-0.009-0.556,0.069-1.158-0.118-1.654c-0.327-0.867-0.641-1.934-1.93-1.718
				c-1.113,0.187-1.57,0.96-1.572,2.049c-0.001,0.638,0.028,1.283-0.054,1.913c-0.112,0.865-0.707,1.157-1.538,1.146
				c-1.009-0.013-0.935-0.767-0.855-1.351c0.213-1.55-0.178-2.451-1.918-2.723c-1.348-0.211-1.02-1.296-0.42-1.994
				c1.551-1.809,1.84-3.948,1.854-6.183C468.096,543.73,468.266,543.044,469.319,542.929z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(440)} fill={props.fillColors[440]} d="M525.863,219.347c0.131,0.708-0.49,1.093-0.707,1.646
				c-0.507,0.368-1.05,0.703-1.521,1.119c-2.612,2.308-3.188,2.432-5.313-0.228c-1.815-2.272-4.18-4.105-5.462-6.817
				c-0.889-1.882,0.019-5.783,1.742-6.851c0.924-0.573,1.511,0.522,2.351,0.587c0.813,0.062,0.543,0.706,0.456,1.28
				c-0.491,3.232,1.211,6.226,3.573,6.405c1.896,0.143,3.322-0.317,3.536-2.533c0.097-1.004,0.457-1.828,1.664-1.127
				c0.857,0.498,2.694,0.291,1.775,2.268C527.291,216.531,526.145,217.727,525.863,219.347z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(441)} fill={props.fillColors[441]} d="M435.598,742.112l0.088-0.002c1.604,0.194,2.985,0.864,4.159,1.969l-0.098-0.065
				c1.312,0.648,2.778,0.99,3.916,1.982l-0.09-0.094c0.422,0.651,0.844,1.303,1.352,2.087c-2.272,1.274-4.327,1.628-6.748,0.176
				c-4.231-2.539-9.001-3.83-13.705-5.209c-0.507-0.148-1.096-0.019-1.646-0.019l0.079,0.076c-0.023-0.146-0.054-0.422-0.07-0.421
				c-3.618,0.179-3.165-2.691-3.598-4.848c-0.203-1.011,0.322-0.873,1.12-0.71c4.114,0.841,8.022,2.294,11.915,3.808
				C433.379,741.272,434.489,741.689,435.598,742.112z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(442)} fill={props.fillColors[442]} d="M338.475,565.021c3.868-1.194,6.515-3.795,8.512-7.226
				c0.908-1.56,3.951-2.407,5.627-1.677c0.983,0.429,1.677,0.141,2.317-0.591c0.601-0.687,1.214-1.361,1.877-2.103
				c0.592,2.743-1.088,6.009-4.092,8.12c-0.58,0.408-1.172,0.87-1.83,1.087c-3.4,1.117-5.104,3.56-5.71,6.962
				c-0.105,0.599-0.398,1.204-0.749,1.707c-0.292,0.418-0.696,1.102-1.312,0.766c-0.591-0.32-0.178-0.904-0.07-1.447
				c0.339-1.698,0.916-3.382,0.673-5.154c-0.069-0.504,0.268-1.185-0.462-1.449c-0.639-0.232-1.03,0.263-1.447,0.622
				c-1.645,1.416-2.39,3.538-4.035,4.991c-1.711,1.51-3.431,2.188-5.577,1.084l0.098,0.09c-0.172-0.204-0.21-0.53-0.01-0.606
				c2.698-1.024,4.155-3.528,6.279-5.231L338.475,565.021z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(443)} fill={props.fillColors[443]} d="M76.439,670.104c1.308,0.051,2.114-0.985,3.162-1.489
				c4.528-2.174,9.331-1.597,14.021-1.212c2.836,0.231,5.815,0.403,8.474,1.689c0.825,0.399,2.258,0.25,1.957,2.043
				c-0.305,1.812-1.435,1.493-2.572,1.377c-5.429-0.554-10.855-1.191-16.328-0.912c-2.366,0.121-4.738,0.254-7.084,0.56
				c-0.844,0.11-1.891,0.769-2.436,0.09c-0.68-0.849,0.92-1.324,0.892-2.232C76.526,670.015,76.439,670.104,76.439,670.104z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(444)} fill={props.fillColors[444]} d="M444.476,625.854l0.091-0.077c-2.317,0.43-3.667-1.527-5.484-2.313
				c-0.762-0.329,0.034-0.832,0.485-1.136c0.903-0.606,2.077-0.767,2.785-1.69c0.53-0.223,1.061-0.445,1.591-0.668
				c4.103-1.933,8.442-2.624,12.924-2.243c3.774,0.32,7.415,1.197,10.686,3.433c-2.416,1.337-2.386,1.31-4.095-0.596
				c-0.493-0.551-1.033-1.61-1.871-1.167c-0.992,0.523-0.302,1.477-0.033,2.264c0.14,0.411,0.143,0.869,0.217,1.366
				c-4.866-0.549-9.698-0.997-14.401,0.666C446.227,624.097,444.848,624.348,444.476,625.854z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(445)} fill={props.fillColors[445]} d="M582.562,661.866c-2.411-0.728-4.645-1.859-6.867-3.013
				c-2.083-1.459-4.535-1.903-6.942-2.093c-2.516-0.197-4.979-0.973-7.598-0.706c1.798-1.589,3.994-2.582,6.174-2.708
				c6.233-0.361,12.318,0.439,17.694,4.045c0.652,0.438,1.296,0.925,1.824,1.499c0.538,0.584,1.739,1.285,0.769,2.107
				c-0.881,0.747-1.482,2.555-3.289,1.565C583.777,662.262,583.152,662.095,582.562,661.866z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(446)} fill={props.fillColors[446]} d="M155.326,677.859c3.327,2.215,4.699,5.784,6.268,9.024
				c2.496,5.153,4.525,10.536,5.766,16.179c0.211,0.96,0.176,1.419-0.517,2.225c-1.297,1.51-0.693,3.485-0.444,5.247
				c0.471,3.341,1.126,6.655,1.673,9.985c0.055,0.338-0.107,0.71-0.679,1.105c-0.406-2.642-0.795-5.286-1.221-7.926
				c-0.958-5.944-0.986-12.217-4.366-17.439c-3.183-4.918-4.783-10.363-6.546-15.802C154.983,679.609,154.873,678.806,155.326,677.859
				z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(447)} fill={props.fillColors[447]} d="M363.772,539.946c0.247,3.376-0.242,6.562-1.639,9.727
				c-1.945,4.409-2.034,9.128-1.265,13.841c0.15,0.916-0.032,1.67-0.766,2.013c-0.672,0.314-1.397,0.252-2.128-0.445
				c-2.049-1.956-1.253-4.23-0.918-6.433c0.186-1.222,0.745-2.382,1.034-3.595c0.239-0.999-0.105-2.063-0.915-2.518
				c-0.84-0.472-1.224,0.577-1.695,1.144c-0.356,0.428-0.764,0.815-1.171,1.196c-0.406,0.379-0.807,0.817-1.414,0.253
				c-0.581-0.539-0.124-0.884,0.088-1.409c2.208-5.479,7.566-8.618,10.198-13.775c0.038-0.073,0.432,0.035,0.659,0.058
				L363.772,539.946z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(448)} fill={props.fillColors[448]} d="M346.221,591.196c0.984,0.694,2.254,1.039,2.792,2.278
				c1.024,0.883,0.325,1.494-0.323,2.134c-0.733,0.724-1.223,0.22-1.812-0.256c-4.127-3.33-8.869-5.409-14.073-6.156
				c-3.209-0.461-6.445,0.349-9.501,1.363c-1.788,0.594-3.369,1.249-5.005-0.156l0.096,0.082c-0.53-0.695-0.528-1.265,0.298-1.768
				C326.144,584.187,338.557,583.13,346.221,591.196z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(449)} fill={props.fillColors[449]} d="M313.551,351.179c-5.019-5.469-9.308-10.452-9.078-17.747
				c0.037-1.164-0.325-2.335-0.417-3.511c-0.097-1.228,0.275-1.937,1.646-1.16c0.136,0.077,0.293,0.12,0.443,0.17
				c2.839,0.958,4.751,2.09,4.867,5.902C311.173,340.086,312.573,345.3,313.551,351.179z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(450)} fill={props.fillColors[450]} d="M547.871,630.255c-1.961-0.745-1.731-1.756-0.198-3.024
				c-0.676-0.354-1.76,0.342-1.909-1.193c-0.137-1.407,0.562-1.672,1.356-2.035c5.526-2.523,11.244-4.424,17.282-5.315
				c3.51-0.518,7.021-0.892,10.569-0.992c1.918-0.054,3.703,0.841,5.639,0.599c0.792-0.1,0.987,0.592,0.676,1.116
				c-0.398,0.669-0.032,2.013-1.672,1.729c-2.877-0.499-5.79-0.74-8.727-0.784c-5.944-0.09-11.493,1.758-17.096,3.33
				c-0.806,0.226-1.518,0.697-0.836,2.011c0.653,1.259-0.432,2.179-1.562,2.778C550.756,630.126,549.703,630.96,547.871,630.255z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(451)} fill={props.fillColors[451]} d="M197.665,676.942c0.294,0.014,0.589,0.027,0.875,0.042
				c2.478,1.213,4.966,2.42,7.45,3.637c1.823,0.894,3.2,2.318,4.571,3.771c0.621,0.659,0.922,1.175,0.339,2.03
				c-0.63,0.924-1.174,0.353-1.801,0.072c-5.302-2.367-10.865-3.739-16.635-4.275c-1.031-0.096-1.678-0.437-1.665-1.566
				c0.02-1.604-0.609-3.071-1.128-4.835C192.454,676.221,195.098,676.238,197.665,676.942z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(452)} fill={props.fillColors[452]} d="M309.715,659.21c-0.581,0.203-0.955-0.19-1.365-0.477
				c-3.578-2.322-7.649-2.978-11.768-3.443c-0.787-0.09-1.593,0.001-2.389-0.023c-0.687-0.021-1.699,0.293-1.884-0.534
				c-0.167-0.747,0.881-0.838,1.443-1.133c3.327-1.746,6.915-1.423,10.449-1.155c1.858,0.141,3.686,0.681,5.526,1.052
				c3.892,0.783,6.655,3.386,9.479,5.892l-0.005-0.002c-2.139,2.48-4.461,2.525-7.055,0.669
				C311.479,659.575,310.533,659.48,309.715,659.21z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(453)} fill={props.fillColors[453]} d="M507.525,590.432c0.239-0.113,0.479-0.228,0.71-0.332
				c10.961-7.249,23.92-2.994,31.709,3.93c1.157,1.028,3.397,2.183,2.566,4.628l0.062-0.067c-0.41,0.312-0.787,0.104-1.023-0.19
				c-2.012-2.499-4.741-4.138-7.22-6.076l0.075,0.047c-3.41-1.319-6.812-2.605-10.521-2.905c-5.255-0.426-10.129,0.505-14.486,3.601
				c-0.388,0.274-0.811,0.522-0.979,1.013c-1.568,0.701-2.47,2.226-3.87,3.138c-0.645,0.421-1.12,1.195-2.069,0.918
				c-0.941-0.273-1.188-0.798-0.752-1.725c0.399-0.848,0.911-1.578,1.633-2.166c0.086-0.058,0.171-0.117,0.254-0.178
				c1.013-0.726,0.857-2.078,1.584-2.958C505.904,590.646,506.716,590.546,507.525,590.432z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(454)} fill={props.fillColors[454]} d="M485.697,311.411c-3.674-4.042-6.97-7.69-10.294-11.311
				c-0.744-0.81-0.512-1.303,0.433-1.458c3.869-0.635,7.792-0.917,11.613-1.866c0.888-0.22,1.436,0.321,1.241,1.22
				C487.758,302.327,486.767,306.645,485.697,311.411z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(455)} fill={props.fillColors[455]} d="M364.738,509.062c2.238-3.164,2.163,0.531,3.088,1.166
				c1.821-2.801,2.79-2.762,4.823-0.019c0.982,1.326,2.046,2.597,3.142,3.831c0.524,0.591,0.818,1.146,0.518,1.908
				c-0.354,0.249-0.585,0.008-0.809-0.197c-0.746-0.686-1.807-1.959-2.461-1.395c-0.884,0.764-0.585,2.319-0.063,3.516
				c0.631,1.448,1.368,2.855,1.911,4.334c0.219,0.593,0.516,1.559-0.305,1.902c-0.946,0.396-1.339-0.453-1.724-1.189
				c-0.941-1.799-0.809-3.825-1.175-5.732c-0.259-1.344-0.398-2.822-1.867-2.755c-1.407,0.064-1.474,1.604-1.41,2.848
				c0.017,0.306-0.057,0.616-0.102,1.05c-1.324-0.403-1.135-1.634-1.599-2.431c-0.271-0.466-0.354-1.194-1.01-1.223
				c-0.711-0.03-1.167,0.57-1.346,1.167c-0.461,1.541-1.194,2.886-2.441,4.178c-1.099-1.838-0.459-4.126-2.044-5.719
				c-0.362-0.363,0.981-1.404,1.827-1.882c1.39-0.785,2.793-1.585,3.072-3.389L364.738,509.062z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(456)} fill={props.fillColors[456]} d="M122.618,596.912c-1.751-2.978-5.021-3.604-7.776-5.028
				c-7.611-1.747-14.496,0.49-21.174,3.862c0,0,0.059-0.071,0.059-0.071c-1.191,0.403-2.325,0.891-3.271,1.774
				c-0.382,0.357-0.911,1.304-1.436,0.438c-0.357-0.59-1.182-1.506-0.254-2.305c0.721-0.62,1.332-1.305,1.835-2.099
				c0.346-2.307,1.416-3.082,3.434-2.49c3.81-1.802,7.775-3.247,12.024-2.993c6.632,0.396,12.701,2.388,17.472,7.345
				c0.67,0.696,1.407,1.337,0.8,2.44c-0.002,0.003,0.059-0.064,0.056-0.061C123.795,597.456,123.207,597.185,122.618,596.912z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(457)} fill={props.fillColors[457]} d="M94.036,590.993c-1.147,0.832-2.294,1.664-3.438,2.493
				c-0.852,0.361-1.696,0.747-2.306,1.411c-1.982-2.248,0.839-2.997,1.175-4.491c-0.164-1.024,0.297-1.546,1.31-1.621
				c0.116,0.002,0.232,0.004,0.348,0.005c2.252-0.359,3.796-2.147,5.859-2.902c1.4-0.605,2.778-1.285,4.376-0.949
				c0.533-0.89,1.4-0.45,2.112-0.479c2.084-0.082,4.115,0.395,6.157,0.728l-0.105-0.073c4.042,1.172,7.875,2.784,11.495,4.978
				c1.542,0.936,2.483,2.437,3.944,3.387c0.098,0.1,0.194,0.201,0.294,0.299c-0.096-0.102-0.192-0.203-0.288-0.304
				c0.508,0.988,2.242,2.132,1.423,2.842c-1.362,1.183-1.712-1.03-2.487-1.676c-5.833-4.857-12.386-7.576-20.054-6.929
				C100.359,588.006,96.761,588.274,94.036,590.993z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(458)} fill={props.fillColors[458]} d="M193.775,654.122c-0.496,0.451-1.093,0.722-1.843,0.673
				c-7.42-0.485-13.865,2.548-20.306,5.617c-0.854,0.406-1.605,1.186-2.771,0.167c-1.493-1.305-1.819-2.117-0.16-3.646
				c3.507-3.233,7.763-4.631,12.271-5.003C185.34,651.569,189.908,651.113,193.775,654.122z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(459)} fill={props.fillColors[459]} d="M489.117,295.124c-3.388-4.674-6.542-9.026-9.829-13.562
				c4.367-1.119,8.586-0.748,12.667-1.494c0.613-0.112,0.778,0.282,0.626,0.896C491.449,285.525,490.344,290.092,489.117,295.124z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(460)} fill={props.fillColors[460]} d="M424.853,322.997c1.06-3.669,2.591-6.399,5.589-8.531
				c5.514-3.921,10.088-8.835,13.501-14.772c1.137-1.976,3.427-2.708,5.165-4.108C447.686,302.656,435.09,317.578,424.853,322.997z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(461)} fill={props.fillColors[461]} d="M354.994,752.502c-4.545-4.643-9.243-7.68-14.467-9.835
				c-2.145-0.885-2.135-2.407-2.002-4.184c0.226-3.02,0.219-3.013,3.099-1.694c3.818,1.749,7.316,4.062,10.92,6.188
				c0.799,0.471,1.331,1.043,1.527,2.162C354.461,747.364,354.617,749.585,354.994,752.502z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(462)} fill={props.fillColors[462]} d="M145.662,673.771c5.021,0.042,9.879,0.992,14.678,2.455
				c1.999,0.609,1.592,2.006,1.533,3.361c-0.032,0.73-0.487,0.526-1.005,0.423c-1.568-0.312-2.678-1.246-3.728-2.369
				c-1.119-1.195-2.332-1.844-3.392,0.098c-0.269,0.491-0.653,0.682-1.167,0.635c-5.206-0.473-10.401,0.338-15.605,0.122
				c-0.395-0.017-0.845-0.073-1.18,0.083c-2.339,1.084-2.357-1.146-2.751-2.18c-0.618-1.624,1.122-1.149,1.81-1.32
				C138.393,674.201,141.995,673.7,145.662,673.771z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(463)} fill={props.fillColors[463]} d="M403.648,556.337c-2.45-0.63-4.662-2.229-7.354-1.912
				c-0.481,0.058-0.815-0.582-0.887-1.234c-0.416-3.791-3.799-5.191-6.144-7.39c-1.203-1.128-1.057,0.757-1.992,1.35
				c0.102-5.975-5.818-8.115-7.536-12.961c-0.662-1.867,0.034-3.385,0.041-5.041c0.005-0.95,0.966-0.463,1.347,0.085
				c0.822,1.182,1.451,2.469,1.83,3.868c0.926,3.421,3.856,5.459,5.694,8.25c1.293,1.961,2.897,3.498,5.192,4.224
				c0.875,0.276,1.668,0.91,1.914,1.705c1.053,3.403,3.91,5.061,6.445,7.07C402.799,554.826,403.142,555.624,403.648,556.337z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(464)} fill={props.fillColors[464]} d="M89.736,735.1c-0.851-0.614-0.887-1.976-2.088-1.549
				c-1.069,0.378-1.199,1.383-1.098,2.343c0.155,1.466-0.368,2.808-0.647,4.348c-1.427-2.767-2.799-5.429-4.174-8.089
				c-0.472-0.914-0.424-1.604,0.271-2.55c2.146-2.922,5.015-4.983,7.813-7.161c1.071-0.833,1.468-0.445,1.841,0.646
				c0.785,2.3,0.275,4.574-0.178,6.799C91.122,731.636,90.354,733.303,89.736,735.1z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(465)} fill={props.fillColors[465]} d="M401.15,717.367c0.06,5.031-0.955,9.808-2.182,14.532
				c-1.032,3.973-1.863,7.964-2.12,12.065c-0.105,1.691-1.478,2.735-2.175,4.13c-0.872,1.744-1.976,3.373-3.17,5.37
				c-0.439-2.005,0.477-3.452,0.758-4.959c1.463-7.805,3.013-15.593,4.618-23.369C397.495,722.157,399.611,719.931,401.15,717.367z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(466)} fill={props.fillColors[466]} d="M442.913,629.709c0.98,1.077,0.185,1.409-0.715,1.667
				c-2.622,0.75-4.194-1.256-6.038-2.419c-1.008-0.636-1.511-1.684-2.269,0.352c-0.263,0.707-1.191,1.105-0.822,2.27
				c0.235,0.737-0.645,0.767-1.173,0.73c-1.737-0.119-3.473-0.283-5.205-0.466c-0.285-0.03-0.658-0.169-0.808-0.385
				c-0.69-0.992-1.13-2.022,0.104-2.966c-1.339-0.257-2.025-1.166-2.02-2.428c0.004-0.994,0.782-0.754,1.402-0.503
				c1.378,0.556,2.711,1.275,4.809,1.161c-2.279-0.842-4.037-1.689-5.892-2.119c-1.641-0.38-1.618-1.811-1.433-2.564
				c0.266-1.077,1.351-0.179,2.108-0.071c2.462,0.348,4.604,1.675,6.998,2.219c0.708-0.815,1.497-0.727,2.351-0.243
				c2.423,1.373,4.937,2.568,7.45,3.761C442.621,628.11,443.29,628.604,442.913,629.709z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(467)} fill={props.fillColors[467]} d="M231.878,577.024c-1.044-2.165-2.465-3.899-3.742-5.733
				c-0.884-1.27-1.388-1.239-2.18,0.039c-3.049,4.925-3.101,4.942-7.331,0.85c-1.339-1.295-2.136-1.521-3.015,0.455
				c-0.822,1.849-2.976,2.187-4.485,3.27c-0.34,0.244-0.834,0.164-1.062-0.186c-0.112-0.172,0.055-0.578,0.179-0.839
				c0.671-1.414,1.323-2.84,2.071-4.214c0.535-0.984,1.499-1.325,2.587-1.327c2.065-0.005,4.131-0.015,6.195-0.007
				c1.776,0.007,3.487-0.078,4.663-1.742c0.358-0.506,5.286-0.966,5.702-0.564c0.255,0.246,0.439,0.687,0.451,1.046
				c0.091,2.76-0.176,5.535,0.418,8.272C232.358,576.488,232.104,576.695,231.878,577.024z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(468)} fill={props.fillColors[468]} d="M451.823,540.051c2.603-2.248,5.862-3.551,8.16-6.231
				c0.352,1.27-0.745,1.285-1.07,1.951c-0.658,1.351-0.813,2.756,0.795,3.087c1.966,0.406,0.976,1.255,0.505,1.888
				c-1.001,1.345-2.252,2.528-3.833,3.121c-3.401,1.272-6.376,3.475-9.947,4.389c-1.509,0.387-2.353,1.855-3.488,2.847
				c-0.893,0.779-1.77,1.598-2.76,2.234c-0.51,0.328-1.116,1.037-1.901,0.367c-0.668-0.568-0.517-1.326-0.392-2.003
				c0.72-3.895,2.974-5.753,7.094-6.058c2.86-0.213,5.584-1.03,8.047-2.493c0.795-0.473,1.994-1.12,1.433-2.406
				C453.953,539.57,452.846,540.238,451.823,540.051z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(469)} fill={props.fillColors[469]} d="M569.453,252.327c-1.831,3.754-1.866,3.079-5.961,3.291
				c-3.729,0.192-4.749-2.515-6.862-4.12c-2.38,0.048-2.896-2.009-3.428-3.524c-1.217-3.478-0.197-7.65,2.938-9.779
				c0.699-0.475,1.53-1.194,2.353-0.089c0.722-0.733,1.565-1.244,2.452-0.405c0.967,0.914-0.064,1.505-0.53,2.179
				c-2.31,3.342-2.311,9.6-0.055,12.783c1.577,2.226,4.822,2.776,6.071-0.501c0.777-2.041,1.695-4.053,3.242-5.691
				c2.086,0.95,2.349,2.302,0.947,4.076c-0.438,0.555-0.784,1.183-1.172,1.777c0.103-0.085,0.203-0.174,0.307-0.257
				C569.655,252.153,569.555,252.24,569.453,252.327z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(470)} fill={props.fillColors[470]} d="M195.667,548.024c1.132-0.919,2.407,0.409,3.417-0.093
				c4.058-2.019,6.489,1.585,9.653,2.624c1.49,0.489,1.288,1.442,0.618,2.399c-0.636,0.909-1.376,1.763-2.49,2.156
				c-2.447,0.864-4.869,1.81-7.345,2.579c-1.715,0.534-2.686,0.004-2.665-2.074c0.022-2.287-0.286-4.578-0.45-6.867
				c-0.033-0.458-0.254-0.729-0.738-0.734C195.667,548.017,195.667,548.024,195.667,548.024z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(471)} fill={props.fillColors[471]} d="M525.765,363.434c-1.583,0.369-2.922,0.349-3.073,2.301
				c-0.066,0.851-0.584,0.924-1.329,0.388c-5.124-3.684-10.271-7.336-15.435-10.962c-0.828-0.582-0.881-0.871-0.039-1.542
				c2.572-2.048,3.642-2.195,6.207-0.32C516.676,356.646,521.211,360.053,525.765,363.434z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(472)} fill={props.fillColors[472]} d="M224.92,743.775c-1.917,0-3.843-0.115-5.746,0.046
				c-1.024,0.087-1.127-0.127-0.858-0.977c0.094-0.296,0.062-0.634,0.076-0.952c0.22-4.963,0.218-5.012,5.226-5.149
				c2.934-0.08,5.88-0.181,8.786,0.45c1.335,0.289,2.117,0.651,1.619,2.398c-1.16,4.071-1.054,4.104-5.264,4.133
				c-1.28,0.009-2.559,0.001-3.839,0.001C224.92,743.742,224.92,743.759,224.92,743.775z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(473)} fill={props.fillColors[473]} d="M192.299,706.294c0.496,4.619-0.719,8.948-1.74,13.276
				c-1.047,4.443-2.056,8.881-2.476,13.435c-0.035,0.379-0.168,0.779-0.362,1.106c-1.642,2.781-3.308,5.548-5.259,8.229
				c0.671-3.03,1.312-6.067,2.018-9.09c1.431-6.126,2.102-12.395,3.561-18.522C188.801,711.532,190.728,709.063,192.299,706.294z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(474)} fill={props.fillColors[474]} d="M324.98,445.386c-3.008,1.527-5.583,2.739-8.549,3.036
				c-1.667,0.167-2.679,1.534-3.555,2.936c-1.033,1.653-4.927,2.298-6.29,0.972c-0.82-0.798,0.104-1.931,0.609-2.178
				c3.428-1.669,3.796-5.065,4.933-8.077c0.496-1.314,1.302-2.221,3.057-1.22C318.188,442.568,321.326,444.048,324.98,445.386z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(475)} fill={props.fillColors[475]} d="M389.204,708.781c-0.227-0.601-0.697-0.923-1.3-0.82
				c-3.776,0.64-7.114-1.082-10.63-1.888c-4.685-1.073-9.368-1.524-13.79,1.055c-0.896,0.523-1.517,0.581-2.047-0.547
				c-0.513-1.09-0.687-1.816,0.572-2.55c5.243-3.053,10.76-3.455,16.434-1.667c3.461,1.091,6.878,2.352,9.922,4.453
				c0.81,0.559,1.131,1.019,0.79,1.918C389.164,708.745,389.204,708.781,389.204,708.781z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(476)} fill={props.fillColors[476]} d="M108.408,682.958c1.213,5.801,3.132,10.708,4.598,15.75
				c0.379,1.305-0.887,6.756-1.992,7.347c-1.232,0.66-2.274-0.147-2.912-1.186c-2.405-3.913-2.271-8.176-1.745-12.54
				C106.723,689.304,106.943,686.278,108.408,682.958z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(477)} fill={props.fillColors[477]} d="M387.396,581.469c0.15,0.96,0.36,2.029-0.767,2.49
				c-1.051,0.43-1.536-0.651-2.034-1.229c-4.212-4.865-9.454-8.052-15.698-9.426c-1.927-0.424-1.504-1.301-1.235-2.522
				c0.306-1.394,0.296-2.997,2.479-2.426c0.076,0.02,0.176,0.026,0.22,0.077c2.458,2.859,6.63,2.021,9.339,4.333
				c1.812-1.347,2.549,0.219,3.472,1.279c0.456,0.725,1.298,1.334,0.715,2.372c0.547,0.917,1.009,1.91,2.1,2.34
				c0.713-0.138,1.178,0.227,1.555,0.771C387.938,580.208,388.259,580.882,387.396,581.469z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(478)} fill={props.fillColors[478]} d="M470.145,313.68c1.12-4.436,2.154-8.544,3.202-12.649
				c0.169-0.66,0.628-1.094,1.154-0.424c3.047,3.877,6.627,7.27,9.866,10.971c0.028,0.033-0.038,0.148-0.122,0.439
				C479.665,312.556,475,313.106,470.145,313.68z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(479)} fill={props.fillColors[479]} d="M472.301,535.246c-0.676-0.726-0.556-1.582-0.307-2.252
				c0.438-1.179,0.426-2.362,0.33-3.541c-0.507-6.245,0.541-12.306,2.147-18.294c0.363-1.355,0.148-2.689,0.189-4.031
				c0.021-0.706,0.146-1.407,0.849-1.523c0.672-0.111,1.195,0.536,1.391,1.112c0.698,2.061,0.807,4.12,0.171,6.283
				c-0.823,2.798-3.055,5.065-2.966,8.212c0.017,0.613,0.033,1.206,0.507,1.407c0.484,0.205,0.745-0.406,1.033-0.791
				c1.518-2.027,2.812-4.235,4.735-5.931c0.422-0.373,0.8-1.266,1.508-0.681c0.533,0.438-0.024,0.984-0.311,1.462
				c-1.993,3.325-3.919,6.692-6.693,9.47c-0.748,0.749-1.434,1.763-0.887,2.762c0.518,0.947,1.771,0.579,2.6,0.42
				c1.771-0.339,3.508-0.914,5.216-1.515c0.735-0.26,1.502-0.592,1.833,0.248c0.307,0.773-0.609,1.234-1.132,1.546
				c-0.863,0.513-1.856,0.809-2.925,0.757c-1.195-0.057-2.402,0.068-3.591-0.038c-1.876-0.169-2.926,0.344-2.664,2.481
				C473.446,533.718,473.39,534.711,472.301,535.246z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(480)} fill={props.fillColors[480]} d="M474.339,297.454c1.399-4.802,2.397-9.689,4.386-14.646
				c2.889,4.025,5.589,7.778,8.274,11.542c0.481,0.674,0.736,1.275-0.498,1.466c-3.812,0.589-7.614,1.239-11.424,1.844
				C474.869,297.694,474.631,297.541,474.339,297.454z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(481)} fill={props.fillColors[481]} d="M94.654,582.551c0,0-0.047,0.044-0.047,0.043c-0.596,0.39-0.971,1.476-1.936,0.753
				c-0.958-0.718-0.215-1.429,0.178-2.061c2.19-3.526,4.575-6.928,8.63-8.491c3.155-1.217,6.139-3.153,9.797-2.643
				c1.042,0.146,1.719,0.19,1.769,1.576c0.072,2.014,0.892,2.421,2.51,1.256c1.323-0.952,2.323-1.425,3.951-0.434
				c1.72,1.047,1.286,2.736,1.808,4.125c0.219,0.583-0.293,0.835-0.861,0.545c-0.766-0.392-1.511-0.822-2.266-1.236l0.057,0.072
				c-4.285-1.734-8.643-1.863-13.115-0.767c-2.462,0.604-4.365,2.269-6.656,3.168l0.083-0.065
				C97.315,579.835,95.78,581.001,94.654,582.551z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(482)} fill={props.fillColors[482]} d="M151.354,595.316c-2.652-5.136-9.869-8.112-15.851-6.448
				c-3.323,0.924-6.765,1.543-9.759,3.419c-0.659,0.413-1.187,0.223-1.67-0.342c-0.608-0.71-0.109-1.162,0.34-1.621
				c1.81-1.852,4.307-2.22,6.603-2.976c5.612-1.847,11.167-2.094,16.353,1.268c2.503,1.622,5.023,3.31,6.451,6.102
				c0.293,0.573,1.143,1.002,0.443,1.858c-0.424,0.519-0.036,0.768,0.426,0.601c1.435-0.521,1.164,0.488,1.211,1.257
				c0.188,3.023-2.688,2.166-4.1,3.006c-1.203,0.717-1.854-1.131-2.644-1.924c-0.448-0.449,0.054-0.71,0.596-0.888
				c0.952-0.312,2.005-0.489,2.679-1.368C151.782,596.773,150.608,596.577,151.354,595.316z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(483)} fill={props.fillColors[483]} d="M422.826,742.938c1.76,1.292,3.957,1.318,5.925,2.015
				c3.64,1.287,7.187,2.743,10.896,4.785c-2.385,1.011-4.495,1.892-6.592,2.806c-0.374,0.163-0.612-0.004-0.866-0.178
				c-3.705-2.538-7.988-3.688-12.213-4.89c-1.945-0.554-1.627-1.623-1.233-2.886c0.375-1.203,0.683-2.575,2.54-1.611
				c0.434,0.226,1.081,0.039,1.626,0.039C422.905,743.014,422.826,742.938,422.826,742.938z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(484)} fill={props.fillColors[484]} d="M201.28,728.824c-0.536-6.296-1.044-12.118-1.514-17.944
				c-0.083-1.03-0.147-2.1,0.037-3.104c0.204-1.105,0.37-2.524,1.846-2.654c1.437-0.125,1.648,1.167,2.029,2.223
				c0.732,2.032,1.062,4.15,1.101,6.271c0.055,2.933,0.395,5.812,0.897,8.691c0.249,1.424,0.258,2.781-1.368,3.7
				C203.253,726.604,202.472,727.685,201.28,728.824z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(485)} fill={props.fillColors[485]} d="M142.324,738.175c0.241-3.119-0.301-6.207,0.115-9.214
				c0.66-4.776,0.284-9.549,0.334-14.319c0.02-1.93,0.436-3.778,0.57-5.676c0.048-0.686,0.34-1.485,1.022-1.81
				c0.867-0.411,1.268,0.479,1.738,0.934c2.338,2.257,2.845,4.403,1.129,7.15c-2.989,4.784-2.783,9.871-1.74,15.006
				c0.502,2.474-1.195,3.956-1.88,5.884C143.364,736.83,142.578,737.318,142.324,738.175z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(486)} fill={props.fillColors[486]} d="M128.786,647.981c-1.888,0.312-2.835-1.21-3.92-2.217
				c-0.938-0.872,0.334-1.144,0.858-1.358c3.438-1.413,7.067-2.076,10.706-2.754c5.146-0.96,10.205-0.245,15.279,0.481
				c0.906,0.129,1.836,0.271,1.473,1.541c-0.274,0.959-0.426,2.169-2.01,1.492c-0.909-0.39-1.882-0.169-2.829-0.04
				c-0.103,0.039-0.205,0.077-0.308,0.116c-4.399,0.06-8.638,1.35-13.002,1.677c-0.2,0.098-0.403,0.186-0.609,0.271
				c-1.881-0.421-3.561,0.458-5.319,0.831C128.999,648.009,128.892,647.995,128.786,647.981z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(487)} fill={props.fillColors[487]} d="M475.129,538.455c0.353,1.747,2.146,1.937,3.203,2.924
				c0.466,0.435,1.364,0.897,0.712,1.413c-1.602,1.267-0.846,3.098-1.349,4.737c-1.809-0.585-1.92-2.299-2.722-3.465
				c-0.42-0.61-0.446-1.641-1.554-1.555c-0.994,0.077-0.819,1.015-1.16,1.584c-0.346,0.578-0.409,1.43-1.384,1.559
				c-0.701-0.481-0.426-1.267-0.396-1.898c0.05-1.024-0.395-1.905-1.226-2.081c-0.978-0.206-1.501,0.789-1.697,1.636
				c-0.352,1.514-0.592,3.059-0.79,4.602c-0.085,0.663-0.217,1.294-0.707,1.716c-0.398,0.344-0.922,0.747-1.504,0.462
				c-0.553-0.271-0.283-0.816-0.258-1.277c0.065-1.163,0.597-2.159,1.235-3.056c0.618-0.868,0.951-1.801,1.087-2.813
				c0.082-0.617,0.172-1.317-0.521-1.703c-0.708-0.394-0.978,0.299-1.342,0.644c-0.481,0.457-1.188,1.013-1.618,0.625
				c-0.578-0.523-0.213-1.311,0.358-1.876c1.246-1.236,2.341-2.682,3.758-3.668c0.912-0.636,2.183-2.35,3.403,0.287
				c1.242,2.683,1.405-2.675,2.979-0.68c0.514,0.65,0.454,1.768,1.558,1.94L475.129,538.455z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(488)} fill={props.fillColors[488]} d="M540.333,619.817c1.215,0.61,2.432,1.221,3.646,1.831
				c0.348,1.107,1.748,1.043,2.278,2.069c-1.355,0.927-3.096,1.289-4.102,2.719l0.06-0.048c-4.454-1.892-5.86-2.795-7.097-4.669
				c-0.87,1.118-1.965,1.268-3.328,1.042c-1.476-0.244-3.103-0.377-4.518-0.004c-2.593,0.684-2.462-1.122-2.723-2.583
				c-0.221-1.233,0.381-1.757,1.67-2.05c4.345-0.983,8.435-0.168,12.483,1.34C539.219,619.656,539.789,619.703,540.333,619.817z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(489)} fill={props.fillColors[489]} d="M314.617,439.388c3.014-0.978,5.396-1.559,7.838-0.093
				c1.685,1.011,3.382,0.095,5.014-0.286c2.151-0.503,4.239-1.274,6.373-1.86c0.761-0.21,1.649-0.338,2.165,0.497
				c0.561,0.906-0.137,1.447-0.72,2.019c-1.132,1.11-2.364,2.152-2.857,3.751c-0.321,1.041-1.123,1.035-2.046,1.169
				c-4.093,0.593-7.709-0.81-11.247-2.528C317.729,441.371,316.428,440.466,314.617,439.388z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(490)} fill={props.fillColors[490]} d="M539.649,654.802c0.357-1.312-1.653-2.402-0.357-3.803
				c0.017-0.179,0.111-0.3,0.273-0.37c1.827-1.171,2.354-3.291,3.544-4.927c1.131-1.556,2.348-2.991,3.768-4.276
				c0.707-0.641,1.187-1.133,1.518,0.337c0.194,0.861,0.596,1.887,1.88,1.791c1.143-0.085,1.668-0.961,1.989-1.824
				c0.516-1.391,0.885-2.849,1.169-4.308c0.118-0.607,0.288-1.075,0.807-1.346c1.096-0.572,2.324-2.153,3.297-1.367
				c1.132,0.915,0.93,2.835,0.993,4.407c0.058,1.412-0.719,1.78-1.84,2.309c-2.148,1.011-4.683,1.505-6.04,3.8l0.08-0.096
				c-3.483,2.291-6.357,5.161-8.416,8.816c-0.636,1.13-1.382,2.037-2.72,0.773L539.649,654.802z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(491)} fill={props.fillColors[491]} d="M291.395,724.812c-2.022-1.381-4.056-2.745-6.062-4.148
				c-1.344-0.94-1.668-3.377-0.712-4.578c0.486-0.61,0.844-0.117,1.209,0.05c6.22,2.833,11.602,6.949,16.964,11.096
				c0.793,0.613,0.709,1.235-0.027,1.694c-0.842,0.523-1.131,2.497-2.761,1.143c-1.769-1.47-3.679-2.715-5.808-3.604
				C292.422,727.344,291.49,726.789,291.395,724.812z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(492)} fill={props.fillColors[492]} d="M278.668,374.02c-0.695-4.803-1.389-9.607-2.086-14.41
				c-0.916-6.312-1.839-12.621-2.748-18.934c-0.136-0.939-0.09-1.92-0.344-2.82c-0.25-0.89-0.254-1.345,0.828-1.417
				c0.818-0.055,1.606-0.6,1.852,0.867c0.52,3.099,1.392,6.142,1.851,9.247c0.975,6.589,1.255,13.246,1.381,19.894
				C279.45,368.951,278.85,371.464,278.668,374.02z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(493)} fill={props.fillColors[493]} d="M163.824,678.291c1.826,3.992,4.583,7.827,5.289,12.009
				c1.581,9.366,4.431,18.404,6.241,27.69c0.225,1.149-0.196,1.978-1.495,2.462c-4.596-13.765-5.783-28.317-11.126-41.819
				C163.097,678.519,163.46,678.405,163.824,678.291z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(494)} fill={props.fillColors[494]} d="M29.542,696.937c-0.494,0.249-1.089,0.397-1.462,0.77
				c-0.717,0.715-1.361,1.183-2.144,0.166c-0.689-0.896-1.385-1.715-0.183-2.861c2.5-2.384,5.484-4.063,8.343-5.912
				c4.568-2.955,9.256-5.715,14.315-7.884c0.585,3.626,0.586,4.087-3.883,5.319c-2.979,0.821-4.527,3.267-6.984,4.563
				c0.577,0.646,0.286,1.202-0.256,1.627c-1.938,1.517-3.989,2.88-5.997,4.301C30.758,697.403,30.098,697.496,29.542,696.937z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(495)} fill={props.fillColors[495]} d="M98.641,679.67c1.756,2.98,1.778,6.046,2.219,8.951
				c0.305,2.009,0.785,4.047,0.59,6.146c-0.097,1.046-0.037,1.762-1.482,1.403c-1.811-0.447-2.642,0.301-2.4,2.273
				c0.663,5.399,1.286,10.805,1.835,16.217c0.288,2.838,0.519,5.695,0.541,8.545c0.033,4.124,0.198,8.219,0.949,12.286
				c0.25,1.356-0.403,2.529-1.424,3.578c0-0.61-0.008-1.222,0.001-1.832c0.132-8.2-0.503-16.381-1.057-24.543
				c-0.476-7.021-1.785-14.011-1.639-21.061C96.855,687.768,96.952,683.771,98.641,679.67z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(496)} fill={props.fillColors[496]} d="M485.158,264.725c4.569-0.559,8.705-1.064,13.12-1.604
				c-1.655,4.887-3.237,9.557-4.955,14.627C490.544,273.314,487.948,269.174,485.158,264.725z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(497)} fill={props.fillColors[497]} d="M280.614,386.665c-0.406-2.292-0.767-4.282-1.108-6.275
				c-0.123-0.716-0.083-1.35,0.474-1.976c2.262-2.537,4.458-5.131,6.686-7.699c0.197-0.228,0.55-0.635,0.65-0.586
				c1.622,0.784,2.535,2.282,3.658,3.602c0.796,0.935,0.218,1.375-0.25,2.007C287.806,379.672,284.415,383.163,280.614,386.665z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(498)} fill={props.fillColors[498]} d="M481.292,279.912c-1.171,0.134-1.8,0.002-1.251-1.521
				c1.271-3.532,2.318-7.146,3.534-10.699c0.144-0.419,0.211-1.218,0.937-1.172c0.49,0.031,0.671,0.56,0.928,0.97
				c2,3.197,4.017,6.385,6.082,9.54c0.93,1.418,0.846,2.003-1.06,2.086C487.399,279.251,484.349,279.634,481.292,279.912z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(499)} fill={props.fillColors[499]} d="M368.193,475.396c1.812,3.513,0.54,7.284,1.595,10.81
				c0.11,0.368-0.102,0.877,0.08,1.17c0.439,0.709,0.158,2.056,1.197,2.109c0.893,0.047,0.835-1.129,1.137-1.817
				c0.409-0.937,0.927-1.825,1.517-2.964c0.964,2.249-0.235,4.064-0.84,5.689c-1.244,3.345-2.4,6.578-1.689,10.213
				c0.221,1.133,0.031,2.132-1.299,2.673c-1.29,0.525-0.829,2.261-2.17,3.409C368.539,496.022,366.552,485.678,368.193,475.396z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(500)} fill={props.fillColors[500]} d="M459.753,347.907c-0.173,0.982-0.351,1.827-0.466,2.681
				c-0.195,1.46-1.066,1.648-2.196,1.116c-4.122-1.94-8.101-4.127-11.689-6.971c-1.138-0.901-1.131-1.622-0.045-2.66
				c2.429-2.322,2.876-2.403,5.54-0.375c2.326,1.771,4.887,3.159,7.497,4.455C459.199,346.553,460.037,346.878,459.753,347.907z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(501)} fill={props.fillColors[501]} d="M293.693,749.812c-3.101-5.131-5.722-10.076-3.282-16.097
				c0.569-1.404,0.782-3.229,2.666-3.036c1.727,0.176,2.234,1.899,2.735,3.414c1.191,3.602-0.269,7.013-0.727,10.483
				C294.859,746.291,294.204,747.948,293.693,749.812z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(502)} fill={props.fillColors[502]} d="M560.453,492.944c0.146-0.384,0.248-0.642,0.341-0.903
				c0.334-0.937-0.714-2.463,0.895-2.797c1.535-0.318,1.394,1.456,2.034,2.224c1.036,1.242,1.727,2.772,2.584,4.215
				c-0.842,0.495-2.308-0.313-2.576,1.115c-0.245,1.298,0.503,2.308,1.495,3.053c0.54,0.404,1.548,0.495,0.594,1.451
				c-2.341,2.348-4.521,4.871-8.108,5.462c-1.092,0.18-1.763,0.105-2.029-0.927c-0.247-0.955-1.196-2.083,0.2-2.895
				c1.673-0.972,2.116-2.358,1.37-4.041c-0.505-1.142-0.216-1.515,0.926-1.787c1.289-0.307,2.495-1.029,3.791-1.229
				c1.204-0.187,1.671-0.616,1.664-1.811c-0.009-1.571-1.169-0.892-1.895-1.044C561.36,492.951,560.96,492.975,560.453,492.944z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(503)} fill={props.fillColors[503]} d="M102.582,737.266c-1.672-10.344-0.812-20.215-2.496-29.913
				c2.361,3.611,3.661,7.608,4.624,11.734c1.019,4.364,1.173,8.756,0.483,13.197C104.911,734.097,103.695,735.28,102.582,737.266z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(504)} fill={props.fillColors[504]} d="M458.423,354.716c-0.138,1.553-0.493,2.535-2.513,1.833
				c-5.713-1.987-10.946-4.816-15.764-8.447c-0.375-0.283-1.002-0.583-0.781-1.058c0.64-1.379,2.232-1.646,3.299-2.555
				c0.639-0.545,1.174-0.036,1.652,0.417c3.662,3.473,8.102,5.705,12.641,7.725C458.024,353.107,458.517,353.593,458.423,354.716z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(505)} fill={props.fillColors[505]} d="M20.152,704.209c5.831,10.556,6.749,21.624,3.125,33.406
				c-0.593-2.947-0.85-5.65-1.19-8.365c-0.349-2.781-0.319-5.54-0.41-8.313c-0.094-2.848-0.237-5.697-0.497-8.534
				C20.929,709.663,20.502,706.939,20.152,704.209z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(506)} fill={props.fillColors[506]} d="M507.219,299.524c5.289,2.495,10.602,5.12,16.613,5.693
				c0.781,0.075,1.481,0.471,0.934,1.107c-1.068,1.239-1.673,3.247-3.87,3.052c-5.114-0.452-9.768-2.332-14.257-4.692
				c-0.349-0.184-0.803-0.539-0.598-0.921C506.761,302.418,505.902,300.676,507.219,299.524z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(507)} fill={props.fillColors[507]} d="M181.989,564.411c-0.03,1.389-1.215,1.34-1.808,1.892
				c-0.613,0.569-1.805,0.803-1.737,1.735c0.069,0.953,1.224,1.009,2.008,1.312c0.797,0.309,1.576,0.671,2.34,1.055
				c0.63,0.317,1.285,0.62,1.155,1.562c-0.132,0.959-0.969,1.062-1.562,1.146c-1.266,0.18-2.365,0.723-3.506,1.224
				c-0.396-1.82-0.745-3.642-1.211-5.434c-0.141-0.54-0.258-1.373-1.091-1.325c-0.705,0.04-0.939,0.612-1.192,1.277
				c-1.342,3.521-2.78,7.008-4.196,10.501c-0.144,0.354-0.282,0.752-0.912,0.843c-0.873-3.574-1.779-7.159-2.609-10.762
				c-0.269-1.168,0.734-1.053,1.381-1.012c1.696,0.107,3.331-0.172,4.925-0.665c1.333-0.412,2.419-0.918,3.185-2.448
				C178.173,563.287,180.294,564.578,181.989,564.411z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(508)} fill={props.fillColors[508]} d="M311.83,746.199c2.068-8.495,2.066-17.421,5.319-25.625
				c0.769-1.938,1.448-4.131,3.839-5.901c-0.459,3.312-0.657,6.114-1.266,8.825c-1.067,4.756-2.445,9.441-3.153,14.28
				C316.077,741.141,313.437,743.348,311.83,746.199z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(509)} fill={props.fillColors[509]} d="M480.544,329.957c-1.439,4.046-2.877,8.086-4.435,12.461
				c-2.121-2.659-4.113-5.124-6.067-7.617c-0.768-0.98-1.455-2.024-2.174-3.042c-0.254-0.359-0.845-0.6-0.517-1.182
				c0.237-0.42,0.711-0.301,1.077-0.277C472.408,330.555,476.335,329.312,480.544,329.957z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(510)} fill={props.fillColors[510]} d="M403.67,725.868c2.207,1.41,4.831,2.09,6.796,3.894
				c1.609,1.716,3.229,3.441,5.116,5.45c-1.976,0.323-3.614-0.112-5.094-0.357c-2.871-0.475-5.713-0.285-8.564-0.339
				c-1.521-0.028-2.271-0.265-1.666-2.055c0.552-1.634,0.867-3.363,1.131-5.074C401.612,725.931,402.605,725.912,403.67,725.868z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(511)} fill={props.fillColors[511]} d="M92.004,733.732c2.958,1.952,4.872,4.868,7.103,7.484
				c0.614,0.721,0.309,1.318-0.044,1.96c-1.323,2.408-2.651,4.812-3.985,7.235C90.928,746.016,89.56,738.634,92.004,733.732z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(512)} fill={props.fillColors[512]} d="M374.33,526.795c1.603,3.21-0.479,5.798-1.016,8.568
				c-0.437,2.256,0.383,3.62,2.226,3.728c1.936,0.114,0.821-1.74,1.776-2.338c1.29,2.067,1.115,4.182,0.276,6.237
				c-0.116,0.285-0.913,2.396-2.008,0.4c-0.325-0.591-1.153-0.636-1.48-0.352c-0.693,0.6-1.067,1.499-0.971,2.475
				c0.074,0.75,0.814,1.424,0.184,2.335c-0.938-0.637-0.931-1.697-1.231-2.611c-0.342-1.037-0.52-2.222-1.152-3.046
				c-0.996-1.298-1.411-2.65-1.204-4.195c0.166-1.239-0.249-2.38-0.57-3.486c-0.424-1.46-0.71-2.414,1.388-2.447
				c1.204-0.02,1.283-1.246,0.992-2.025c-0.543-1.457-0.562-2.295,1.331-2.084C373.68,528.043,373.789,527.194,374.33,526.795z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(513)} fill={props.fillColors[513]} d="M150.113,472.199c2.003,1.228,4.348,0.387,6.467,1.071
				c1.01,0.326,2.378-0.494,1.61,1.838c-0.365,1.108,0.183,3.595,2.621,4.074c-1.178,0.578-2.278,0.497-3.376,0.203
				c-0.76-0.203-1.487-0.591-2.255-0.683c-0.744-0.089-1.704-0.391-2.197,0.458c-0.429,0.739,0.311,1.283,0.654,1.862
				c0.224,0.379,0.734,0.625,0.427,1.56c-2.236-1.047-4.362-2.185-6.077-3.821c-0.774-0.738-2.833-1.051-2.145-2.296
				c0.538-0.974,2.116,0.201,3.221,0.516c0.442,0.125,0.923,0.112,1.821,0.209c-1.628-1.494-2.971-2.718-4.304-3.952
				c-0.698-0.646-1.742-1.626-1.371-2.285c0.629-1.116,1.802,0.109,2.758,0.08c0.979-0.031,1.271,1.155,2.19,1.226L150.113,472.199z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(514)} fill={props.fillColors[514]} d="M313.389,438.612c-1.484,1.277-1.819,3.741-4.096,4.481
				c-1.039,0.338-2.082,0.665-3.131,0.969c-0.748,0.217-1.447,0.86-1.827-0.687c-0.291-1.187-1.795-0.912-2.712-0.578
				c-1.188,0.432-2.353,0.252-3.528,0.315c-1.55,0.085-1.464-1.31-1.713-2.16c-0.269-0.924,0.667-0.952,1.375-1.164
				c4.136-1.237,8.249-2.547,12.368-3.84C311.732,435.444,313.504,436.792,313.389,438.612z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(515)} fill={props.fillColors[515]} d="M162.142,718.235c-0.752-4.828-1.5-9.657-2.259-14.484
				c-0.379-2.41-0.784-4.817-1.162-7.229c-0.076-0.485-0.054-1.002,0.505-1.207c0.658-0.24,0.773,0.406,1.033,0.748
				c2.374,3.134,2.825,6.937,3.536,10.611c0.761,3.934,1.23,7.922,1.911,11.872c0.413,2.396-1.5,2.28-2.792,2.539
				c-1.759,0.354-0.675-1.218-0.987-1.865c-0.125-0.26-0.021-0.629-0.021-0.949C161.984,718.26,162.063,718.247,162.142,718.235z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(516)} fill={props.fillColors[516]} d="M471.463,329.018c-1.506-0.09-3.51,0.372-4.387-0.417
				c-1.023-0.921,0.414-2.693,0.604-4.136c0.314-2.401,1.192-4.705,1.447-7.141c0.081-0.777,0.642-1.111,1.271-0.315
				c2.693,3.398,5.366,6.813,8.072,10.2c0.615,0.771,0.908,1.566-0.417,1.471C475.833,328.52,473.678,329.38,471.463,329.018z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(517)} fill={props.fillColors[517]} d="M471.087,534.397c-0.812-1.113-1.096-2.256-2.418-2.931
				c-0.887-0.453-1.38-1.695-0.979-2.735c1.042-2.705,0.068-5.11-0.921-7.508c-0.866-2.097-1.59-4.209-1.794-6.826
				c1.16,1.219,1.597,2.497,2.145,3.688c0.229,0.497,0.23,1.237,0.873,1.254c0.796,0.02,0.897-0.825,1.03-1.383
				c0.603-2.537,0.962-5.108,0.972-7.728c0.005-1.24-0.314-2.573,0.78-3.661c1.104,1.076,1.225,2.458,1.174,3.891
				C471.663,518.438,471.375,526.418,471.087,534.397z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(518)} fill={props.fillColors[518]} d="M110.509,733.275c1.385,1.077,1.396,2.525,1.724,3.841
				c1.077,4.321,2.989,8.29,5.036,12.202c0.555,1.062,0.975,2.061,0.482,3.282c-0.747,1.851-0.353,3.875-0.901,5.885
				c-1.934-3.523-5.488-5.982-5.372-10.749C111.595,742.927,110.867,738.097,110.509,733.275z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(519)} fill={props.fillColors[519]} d="M386.508,536.271c-1.921-0.047-1.961-1.462-2.274-2.345
				c-0.588-1.655-1.196-3.295-2.085-4.797c-0.466-0.788-1.101-1.749-2.237-1.493c-1.181,0.266-1.128,1.354-1.168,2.309
				c-0.017,0.389,0.042,0.81-0.867,1.41c-0.232-2.67-2.282-4.522-2.02-7.239c0.062-0.631,0.163-1.354-0.071-1.899
				c-0.905-2.121,0.274-2.161,1.594-1.484c2.513,1.289,5.521,2.12,6,5.677c0.147,1.103,0.951,1.896,1.955,2.565
				c2.611,1.74,5.347,3.359,7.221,6.008c0.445,0.63,1.354,1.433,0.81,1.976c-0.833,0.833-1.728,0.065-2.402-0.7
				c-0.995-1.129-1.991-2.26-3.026-3.353c-0.58-0.611-1.21-1.602-2.166-0.871c-0.996,0.762-0.767,1.784-0.012,2.725
				C386.046,535.116,386.181,535.596,386.508,536.271z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(520)} fill={props.fillColors[520]} d="M284.831,317.08c-4.731,1.679-9.148,2.294-13.524,3.078
				c-0.895,0.161-1.533-0.21-1.697-1.213c-0.113-0.69-0.295-1.372-0.482-2.048c-0.835-3.028-0.84-3.027,2.433-3.404
				c0.392-0.045,0.825,0,1.167-0.155C277.663,311.104,280.792,314.596,284.831,317.08z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(521)} fill={props.fillColors[521]} d="M442.913,629.709c-3.65-1.841-7.301-3.682-10.951-5.522
				c-3.759-2.363-7.975-3.321-12.292-3.888c-1.175-0.154-2.199-0.339-1.856-1.767c0.301-1.254,1.242-0.629,2.092-0.498
				c8.95,1.372,17.141,4.752,24.905,9.284c1.284,0.75,2.675,1.388,3.42,2.768C446.299,631.395,444.617,630.426,442.913,629.709z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(522)} fill={props.fillColors[522]} d="M145.921,459.566c2.201,2.388,5.026,1.283,7.518,1.475
				c1.748,0.135,3.686-1.478,5.703-1.911c0.637-0.137,1.166-0.256,2.024,0.134c-1.556,1.141-2.938,2.106-4.264,3.145
				c-0.708,0.556-1.275,1.278-0.916,2.273c0.402,1.111,1.404,0.739,2.237,0.88c1.466,0.246,2.561-0.731,3.84-1.068
				c0.865-0.228,1.617-0.455,2.895-0.024c-2.992,1.226-5.37,2.815-7.429,4.859c-0.609,0.605-1.188,0.353-1.616-0.223
				c-0.752-1.012-1.747-1.295-2.938-1.159c-3.593,0.41-6.365-1.292-9.147-3.557c2.445-0.088,4.233,1.494,6.397,1.658
				c0.643,0.048,1.425,0.782,1.782-0.27c0.295-0.87-0.576-1.141-1.123-1.552c-1.748-1.312-3.483-2.642-5.223-3.964
				C145.749,460.03,145.835,459.798,145.921,459.566z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(523)} fill={props.fillColors[523]} d="M488.181,644.384c2.158-0.859,3.656-2.725,5.723-3.739
				c1.017-0.499,1.717-0.91,2.922,0.029c2.453,1.911,5.931,0.42,6.287-2.478c0.067-0.545-0.02-1.31,0.771-1.186
				c1.312,0.205,2.66-1.499,3.935,0.138c0.957,1.23,1.951,2.395,2.953-0.062c0.186-0.455,1.041-0.895,1.578-0.884
				c0.624,0.011,0.732,0.847,0.937,1.407c0.979,2.695,0.968,2.538-1.952,2.77c-7.552,0.6-14.982,1.676-20.842,7.207
				c-0.934,0.882-1.442,0.387-1.692-0.52c-0.248-0.897-0.756-1.762-0.559-2.755L488.181,644.384z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(524)} fill={props.fillColors[524]} d="M51.833,631.492c-0.38-0.217-0.76-0.435-1.14-0.652
				c-0.64-1.619,0.302-2.797,1.258-3.82c2.821-3.02,5.812-5.868,9.179-8.288c1.018-0.731,4.384,0.41,4.571,1.56
				c0.121,0.747-0.606,1.059-1.045,1.428c-3.295,2.766-6.551,5.636-8.928,9.203C54.343,633.001,53.164,631.654,51.833,631.492z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(525)} fill={props.fillColors[525]} d="M194.596,692.572c2.163-0.201,4.142,0.895,6.275,0.896
				c3.595,0.745,3.404,3.37,3.064,6.106c-0.097,0.786,0.002,1.595-0.024,2.393c-0.074,2.201-1.206,2.961-3.176,2.183
				c-0.487-0.192-0.488-0.564-0.496-0.951c-0.019-0.878,0.054-1.763-0.039-2.631c-0.104-0.972,0.563-2.347-0.497-2.819
				c-1.022-0.457-2.209,0.473-2.87,1.212c-1.889,2.109-2.436,1.215-2.857-0.941c-0.224-1.149-0.724-2.252-1.167-3.348
				C192.044,692.781,192.416,691.924,194.596,692.572z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(526)} fill={props.fillColors[526]} d="M311.657,717.936c-0.412,0.852-0.776,1.627-1.16,2.392
				c-0.942,1.88-2.326,2.717-4.562,2.58c-2.109-0.13-4.288-0.429-6.394,0.269c-0.321,0.106-5.399-3.056-5.545-3.379
				c-0.241-0.531,0.161-0.607,0.499-0.836c1.216-0.819,2.612-1.17,3.997-1.324C302.808,717.159,307.142,716.606,311.657,717.936z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(527)} fill={props.fillColors[527]} d="M338.348,742.448c0.59,0.206,0.747,0.244,0.891,0.313
				c5.229,2.498,10.315,5.223,14.49,9.35c0.372,0.368,1.156,0.757,0.871,1.278c-0.235,0.43-1.047,0.52-1.601,0.75
				c-3.541,1.469-5.361-1.645-7.853-2.877c-2.105-1.042-4.074-2.362-6.103-3.561c-1.644-0.971-2.153-2.707-1.25-4.35
				C338.001,742.978,338.076,742.345,338.348,742.448z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(528)} fill={props.fillColors[528]} d="M530.902,289.374c4.035,0.046,4.11,0.137,2.441,3.414
				c-1.827,3.588-3.749,7.116-6.057,10.438c-1.29,1.857-2.996,1.031-4.522,0.982c-0.922-0.028-0.272-0.928-0.06-1.282
				c2.384-3.968,4-8.277,5.374-12.649C528.812,287.947,530.378,290.33,530.902,289.374z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(529)} fill={props.fillColors[529]} d="M539.565,650.629c-0.089,0.133-0.184,0.261-0.278,0.377
				c-0.511,0.713-1.186,1.283-1.912,0.42c-0.803-0.954-2.538-1.435-1.964-3.195c0.441-1.352,0.969-2.68,1.54-3.981
				c0.486-1.11,0.796-2.426,2.629-1.263c0.842,0.533,1.924,0.091,2.238-1.336c0.455-2.062,1.199-4.058,1.747-6.101
				c0.232-0.87,0.545-1.548,1.346-2.096c1.01-0.69,1.413-0.536,1.494,0.647c0.011,0.158-0.034,0.343,0.031,0.474
				c1.61,3.234,1.129,5.662-1.896,8.113C542.149,644.627,540.501,647.524,539.565,650.629z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(530)} fill={props.fillColors[530]} d="M558.493,238.105c-3.888,1.674-5.7,6.165-3.928,9.916
				c0.574,1.214,1.372,2.324,2.065,3.479c0.065,1.26,0.987,2.082,1.784,3.095c-2.046,0.955-3.838-0.144-5.65-0.215
				c-1.152-0.045-0.695-1.251-0.718-1.946c-0.087-2.771,0.266-5.599-1.391-8.108c-0.271-0.41-0.498-0.807-0.977-0.987l0.082,0.106
				c-1.802-3.132-1.011-4.946,2.554-5.562c1.843-0.319,3.581-0.717,4.876-2.187c0,0,0.017-0.015,0.016-0.014
				c0.588-0.515,1.093-0.409,1.631,0.13C559.786,236.761,558.469,237.323,558.493,238.105z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(531)} fill={props.fillColors[531]} d="M474.811,281.82c-2.645-0.795-5.345-1.671-7.438-3.741
				c-0.261-0.258-0.653-0.806-0.567-0.93c1.353-1.947,1.981-4.335,3.641-6.077c0.485-0.51,1.014,0.127,1.445,0.333
				c1.713,0.819,3.365,1.779,5.362,1.686c1.049-0.049,1.244,0.626,1.002,1.407c-0.68,2.19-1.48,4.345-2.145,6.54
				C475.895,281.754,475.509,281.894,474.811,281.82z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(532)} fill={props.fillColors[532]} d="M568.239,598.079c-1.604-3.136-4.616-4.72-7.316-6.649
				c-3.559-1.824-7.192-1.477-10.829-0.316c-0.622,0.198-1.258,0.085-1.855,0.297l0.055-0.069c-2.032,0.547-4.06,1.13-5.888,2.194
				c-0.702,0.408-1.182,0.95-1.988,0.1c-0.917-0.966-0.276-1.519,0.327-2.17c0.155-0.168,0.257-0.385,0.383-0.579l-0.095,0.075
				c4.875-2.604,10.143-4.14,15.585-3.52c5.288,0.602,9.653,3.466,12.807,7.912c0.491,0.698,1.271,1.25,1.144,2.261
				c0.709,0.537,0.047,0.729-0.254,1.012l0.105-0.037C569.53,599.112,568.836,598.806,568.239,598.079z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(533)} fill={props.fillColors[533]} d="M469.367,356.187c-2.037-3.388-3.839-6.52-5.793-9.553
				c-0.662-1.028-0.641-1.314,0.594-1.443c3.375-0.353,6.74-0.794,10.115-1.143c1.442-0.15,0.785,0.733,0.572,1.354
				C473.567,349.159,471.462,352.493,469.367,356.187z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(534)} fill={props.fillColors[534]} d="M425.718,723.395c0,0-0.117,0.067-0.118,0.068c2.922-0.072,5.615-1.434,8.631-1.28
				c3.57,0.181,6.732,1.472,9.915,2.811c1.042,0.438,1.519,0.987,0.212,1.95c-1.253,0.925-2.148,2.465-4.056,1.498
				c-1.316,0.663-2.161,0.26-2.569-1.135c-1.946-1.558-4.192-0.57-6.295-0.676c-0.975-0.049-2,0.334-2.958,0.651
				c-1.657,0.55-2.443-0.608-3.382-1.553C423.943,724.568,425.708,724.223,425.718,723.395z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(535)} fill={props.fillColors[535]} d="M55.268,719.345c-0.166-7.279,0.064-14.325,1.658-21.902
				c1.994,3.41,2.356,6.598,2.961,9.701c0.332,1.705,0.497,3.443,0.828,5.149c0.17,0.872,0.025,1.517-0.623,2.151
				C58.454,716.047,56.873,717.707,55.268,719.345z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(536)} fill={props.fillColors[536]} d="M512.765,300.457c0.604-5.206,1.975-9.555,2.94-13.993
				c0.092-0.423,0.345-0.813,1.012-0.563c1.241,0.467,2.778,0.511,3.731,1.292c1.224,1.005-0.215,2.261-0.43,3.415
				c-0.631,3.386-2.169,6.538-2.585,10.001c-0.057,0.473,0.055,2.291-1.252,1.739C514.931,301.82,512.853,301.998,512.765,300.457z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(537)} fill={props.fillColors[537]} d="M367.278,542.815c-0.249-1.201-1.085-2.295-0.592-3.946
				c1.008,2.294,3.809,2.728,4.244,5.292c0.516,3.04,1.894,5.8,3.136,8.635c-2.748,0.261-2.769,0.298-1.641,2.601
				c0.755,1.54,1.479,3.095,2.271,4.614c0.241,0.461,0.473,0.935,0.095,1.304c-0.474,0.463-0.855-0.049-1.178-0.358
				c-0.555-0.535-1.246-1.313-1.921-0.889c-1.062,0.669-0.005,1.587,0.229,2.293c0.561,1.69,1.863,3.146,1.553,5.538
				c-2.439-5.237-3.942-10.377-5.458-15.509c-0.1-0.338-0.063-0.84,0.125-1.118C370.23,548.195,368.494,545.535,367.278,542.815
				c-0.244-0.209,0.07,0.06-0.174-0.148C367.162,542.717,367.221,542.766,367.278,542.815z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(538)} fill={props.fillColors[538]} d="M423.702,590.938c-2.408,1.808-5.217,3.172-6.682,6.026
				c-0.669,0.834-1.598,1.346-2.459,0.622c-0.854-0.717,0.161-1.502,0.524-2.052c2.782-4.213,6.884-6.532,11.6-8.041
				c3.693-1.182,7.235-0.134,10.657,0.772c2.684,0.711,5.474,1.559,7.852,3.218c0.126,0.017,0.24,0.06,0.35,0.128
				c0.603,1.151-0.721,1.461-0.979,2.236l0.083-0.072c-0.852,0.706-1.438-0.025-2.138-0.352c-4.362-2.035-8.87-3.514-13.744-3.732
				C426.912,589.609,425.364,590.424,423.702,590.938z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(539)} fill={props.fillColors[539]} d="M188.616,685.562c1.291,5.587,3.458,10.876,5.069,16.34
				c0.511,1.731-0.84,2.288-1.935,3.021c-1.408,0.942-1.242-0.622-1.768-1.08c-0.409-0.357-0.767-0.957-1.224-1.043
				c-2.414-0.456-2.021-2.214-2.048-3.913C186.639,694.379,186.787,689.913,188.616,685.562z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(540)} fill={props.fillColors[540]} d="M519.361,626.382c-1.082-0.623-2.164-1.247-3.246-1.87
				c-2.803-2.01-6.135-2.256-9.374-2.766c-4.251-0.668-8.505-0.77-12.78-0.047c-2.537,0.43-5.029-0.652-7.584-0.502
				c-0.466,0.027-0.987-0.054-0.967-0.753c0.016-0.534,0.269-1,0.756-1.084c0.857-0.146,1.779-0.324,2.608-0.159
				c4.755,0.95,9.487-0.467,14.232-0.034c5.99,0.547,11.843,1.591,17.242,4.424c0.866,0.455,1.238,0.914,0.773,2.054
				C520.605,626.668,520.271,626.915,519.361,626.382z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(541)} fill={props.fillColors[541]} d="M428.453,491.722c1.394,0.183,2.557,0.801,3.639,1.774
				c1.573,1.418,3.646,1.92,5.515,2.797c1.887,0.886,3.554,0.732,5.42,0.793c1.41,0.045,0.692,1.662,0.956,2.567
				c0.315,1.083-0.39,1.226-1.171,1.232c-3.656,0.029-7.32,0.191-10.966,0.003c-2.317-0.12-2.606-0.916-1.874-3.085
				C430.749,495.502,430.124,493.54,428.453,491.722z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(542)} fill={props.fillColors[542]} d="M151.354,595.316c0.331,0.664,1.411,0.912,1.077,1.943
				c-1.388,0.577-2.551,0.338-3.297-1.08c-1.091,0.712,1.172,1.597-0.272,2.04c-1.47,0.451-1.704-0.899-2.065-1.769
				c-0.994-2.391-2.116-4.094-5.304-3.753c-1.921,0.205-3.962-0.688-6.035-0.76c-2.306-0.082-4.428,0.479-6.296,1.563
				c-1.561,0.904-2.781,1.103-4.183-0.021c-0.009-0.007,0.004,0.016-0.005,0.007c3.639-2.604,7.953-3.541,12.169-4.414
				c5.382-1.114,9.606,1.554,13.208,5.306C150.672,594.714,150.861,595.169,151.354,595.316z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(543)} fill={props.fillColors[543]} d="M565.461,245.914c1.129,0.093,1.041-3.031,2.915-1.077
				c0.604,0.629,0.686,1.052,0.215,1.826c-1.066,1.754-1.958,3.614-2.979,5.397c-1.361,2.376-2.312,2.476-4.111,0.504
				c-2.825-3.093-2.925-9.624-0.205-12.821c0.376-0.443,0.779-0.971,1.364-0.481c0.67,0.562,1.547,0.996,1.078,2.285
				c-0.62,1.708-1.259,3.604,1.224,4.559C565.05,245.844,565.158,245.627,565.461,245.914z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(544)} fill={props.fillColors[544]} d="M184.03,707.832c-0.764-6.245-0.759-12.024-0.239-17.869
				c0.333-3.735,1.089-7.327,2.169-10.863c0.338-1.105,0.759-2.989,2.178-2.735c1.572,0.28,1.271,2.125,1.285,3.461
				c0.009,0.878,0.003,1.756-0.011,2.635c-0.017,0.994,0.142,1.802-1.314,2.311c-1.134,0.397-1.5,2.116-1.838,3.433
				c-1.286,5.004-0.904,10.113-0.926,15.193C185.327,704.863,185.051,706.19,184.03,707.832z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(545)} fill={props.fillColors[545]} d="M386.186,631.088c-0.263,0.163-0.585,0.52-0.779,0.46
				c-2.434-0.749-3.271,0.591-3.522,2.657c-0.088,0.722-0.427,0.987-1.088,0.889c-0.797-0.119-1.096-0.758-0.92-1.386
				c0.233-0.832,0.902-1.684-0.79-1.296c-0.097,0.021-0.367-0.478-0.473-0.766c-0.855-2.334-2.99-2.505-4.96-2.959l0.086,0.053
				c-0.905-1.28-0.709-1.977,0.902-2.556c2.89-1.038,5.722-1.389,8.607-0.206c0.532,0.219,1.759,0.07,1.255,1.22
				c-0.302,0.688-0.952,1.306-1.944,0.9c-0.345-0.142-0.739-0.165-1.602-0.345c0.815,0.885,2.51,0.865,1.589,2.343
				c1.614-1.653,3.213-3.319,4.853-4.945c0.31-0.307,0.692-0.826,1.215-0.53c0.53,0.299,0.226,0.806,0.1,1.254
				c-0.416,1.488-0.206,3.21-1.631,4.316C387.23,630.938,387.063,631.369,386.186,631.088z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(546)} fill={props.fillColors[546]} d="M485.367,263.474c1.362-2.947,2.657-5.663,3.872-8.414
				c0.628-1.422,2.053-1.639,3.174-2.316c0.694-0.419,0.999,0.108,1.274,0.621c1.199,2.234,2.308,4.525,3.626,6.686
				c0.962,1.576,0.012,1.964-1.142,2.145c-2.578,0.404-5.176,0.685-7.769,0.998C487.486,263.305,486.562,263.365,485.367,263.474z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(547)} fill={props.fillColors[547]} d="M262.289,744.507c3.349-0.123,6.601,0.727,9.907,1.023
				c0.636,0.057,1.527,0.252,1.537,1.192c0.015,1.429-0.036,2.861-0.169,4.281c-0.025,0.265-0.525,0.028-0.819-0.048
				c-3.912-1.006-7.856-1.766-11.931-1.638c-0.893,0.027-3.256-3.478-3.051-4.325c0.133-0.551,0.575-0.469,0.933-0.477
				C259.892,744.49,261.091,744.507,262.289,744.507z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(548)} fill={props.fillColors[548]} d="M509.84,283.332c5.068,1.936,5.014,1.923,3.781,6.891
				c-0.681,2.746-1.303,5.526-1.652,8.329c-0.251,2.002-0.982,1.797-2.286,1.004c-2.771-1.686-2.806-1.84-1.726-6.844
				C508.615,289.661,509.188,286.591,509.84,283.332z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(549)} fill={props.fillColors[549]} d="M465.998,331.032c3.228,4.146,6.012,7.723,9.041,11.614
				c-3.658,0.927-7.111,1.149-10.589,1.163c-1.102,0.004-0.938-0.599-0.75-1.446C464.479,338.865,465.138,335.34,465.998,331.032z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(550)} fill={props.fillColors[550]} d="M211.576,630.006c-0.891,0.762-1.729,0.609-2.528-0.15
				c-0.289-0.274-0.578-0.549-0.855-0.835c-1.021-1.048-3.256-1.552-3.039-2.918c0.171-1.077,2.021-2.105,3.328-2.736
				c2.438-1.178,4.598-2.962,7.388-3.412c0.168,0.416,0.35,0.908,0.563,1.387c1.516,3.401,1.504,3.383-1.355,5.543
				c-0.675,0.51-1.225,1.184-1.833,1.782C213.42,630.023,212.949,630.577,211.576,630.006z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(551)} fill={props.fillColors[551]} d="M127.773,676.536c1.818,2.239,1.455,4.092-0.952,5.604
				c-3.858,2.425-8.159,4.195-11.22,7.823c-0.607,0.721-2.061,0.593-2.955,0.156c-1.195-0.585-0.063-1.16,0.32-1.604
				c4.061-4.697,8.056-9.483,14.285-11.494c0.207-0.066,0.353-0.316,0.533-0.48C127.79,676.54,127.773,676.536,127.773,676.536z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(552)} fill={props.fillColors[552]} d="M264.615,609.672c0.484,0.473,0.968,0.945,1.452,1.418
				c-0.124,0.226-0.248,0.45-0.371,0.676c-1.027,0.5-1.781-0.292-2.95-0.633c3.123,2.078-1.169,2.859-0.513,4.563
				c0.077,0.2-4.301-0.935-4.826-1.609c-0.761-0.979,0.051-1.324,0.953-1.852c-1.132-0.381-2.886-0.306-2.526-1.613
				c0.47-1.706,2.157-0.022,3.631-0.631c-3.291-0.893-6.081-1.192-8.599,0.982c-0.601,1.183-1.339,1.749-2.473,0.555
				c-1.263-1.235-1.182-1.988,0.52-2.93c4.506-2.491,8.89-2.374,13.346-0.019C263.024,608.983,263.83,609.31,264.615,609.672z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(553)} fill={props.fillColors[553]} d="M502.286,612.387c-0.124,0.24-0.249,0.481-0.372,0.72
				c-0.536,0.451-1.101,0.874-1.603,1.361c-0.58,0.562-1.077,0.618-1.461-0.153c-0.65-1.307-1.92-1.73-3.116-2.27
				c-0.36,0.346-0.829,0.582-1.272,0.382c-1.497-0.675-2.872-0.41-4.242,0.347c-0.583,0.321-1.193,0.352-1.71-0.188
				c-1.894,0.724-3.817,2.667-5.624-0.139c-0.047-0.105-0.094-0.212-0.142-0.317c-0.007-0.237,0.051-0.458,0.164-0.665
				c2.055-2.835,4.704-4.852,8.079-5.876c2.099-0.834,3.826,0.208,5.589,1.092c1.549,0.777,3.154,1.477,4.479,2.611
				C501.97,610.074,502.666,611.042,502.286,612.387z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(554)} fill={props.fillColors[554]} d="M519.988,303.443c-1.435-0.057-2.104-0.191-1.624-1.978
				c1.081-4.029,2.113-8.066,3.281-12.076c0.483-1.662,1.262-1.647,2.494-1.367c0.614,0.14,1.223,0.319,1.843,0.421
				c0.972,0.159,1.377,0.502,1.021,1.613c-1.383,4.319-3.196,8.451-5.268,12.473C521.384,303.213,520.908,304.03,519.988,303.443z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(555)} fill={props.fillColors[555]} d="M460.445,556.076c-0.074-0.125-0.182-0.242-0.219-0.378
				c-0.284-1.048,0.614-2.714-1.135-2.985c-1.639-0.253-2.129,1.332-2.855,2.449c-1.068,1.642-0.972,4.02-3.047,5.061
				c-0.772-0.678-0.226-1.065,0.171-1.41c0.836-0.729,1.23-1.64,0.404-2.463c-0.961-0.959-1.806-0.273-2.646,0.499
				c-1.104,1.013-1.852,2.434-3.357,2.999c-0.605,0.227-1.209,1.135-1.823,0.434c-0.636-0.726,0.241-1.232,0.673-1.781
				c1.612-2.052,3.837-3.29,6.049-4.511c1.621-0.894,3.054-1.725,3.51-3.803c0.361-1.646,5.118-3.622,6.762-3.078
				c0.209,0.069,0.486,0.148,0.58,0.312C464.275,548.74,461.986,555.165,460.445,556.076z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(556)} fill={props.fillColors[556]} d="M247.928,416.959c3.082,5.223,5.98,10.104,8.836,15.011
				c0.292,0.502,0.942,1.135,0.42,1.69c-0.275,0.292-1.065,0.044-1.594,0.164c-4.246,0.964-5.456-1.297-5.787-5.023
				c-0.128-1.445-0.772-3.008-2.249-4.021c-0.998-0.686-0.907-2.01-0.726-3.107C247.068,420.217,247.49,418.793,247.928,416.959z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(557)} fill={props.fillColors[557]} d="M427.134,560.82c0.523-0.31,1.046-0.62,1.78-1.055
				c-0.57,5.474-1.594,10.671-2.969,15.8c-0.143,0.531-0.08,1.283-1.037,1.366c-1.155,0.101-1.08-0.868-1.231-1.408
				c-0.913-3.242-1.75-6.507-2.536-9.782c-0.307-1.278,1.101-2.444,2.567-2.242c2.801,0.387,3.05,0.191,3.502-2.741
				C427.205,560.762,427.134,560.82,427.134,560.82z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(558)} fill={props.fillColors[558]} d="M532.324,643.928c-3.407-2.938-7.612-3.52-11.848-4.044
				c-0.715-0.089-2.114,0.557-1.876-0.926c0.173-1.076,0.082-3.12,2.174-2.451c2.136,0.683,4.959-0.399,6.347,2.348
				c0.104,0.205,0.696,0.419,0.777,0.34c2.563-2.523,4.81-0.004,7.117,0.531c0.854,0.198,2.559,0.272,2.429,1.447
				c-0.148,1.34-1.024,2.666-1.812,3.845c-0.523,0.783-1.262,0.268-1.789-0.303C532.948,645.202,532.45,644.924,532.324,643.928z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(559)} fill={props.fillColors[559]} d="M211.576,630.006c0.565-0.452,1.13-0.905,1.683-1.35
				c2.349-1.226,4.539-2.836,7.217-3.394c0.942-0.195,2.123-1.389,2.759-0.428c0.868,1.31,2.384,2.668,1.428,4.636
				c-0.002,0.003,0.063-0.07,0.061-0.066c-3.525,0.074-6.098,2.136-8.672,4.171c-0.775,0.612-1.555,1.868-2.497,0.613
				C212.644,632.978,209.745,632.541,211.576,630.006z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(560)} fill={props.fillColors[560]} d="M150.989,612.871c-1.889,0.133-2.645,1.698-3.449,2.988
				c-1.22,1.958-2.778,2.575-4.745,1.514c-1.83-0.986-3.842-0.146-5.658-0.87c-0.534-0.213-1.479,0.326-1.595-0.567
				c-0.14-1.069,0.612-1.942,1.183-2.784c0.419-0.618,1.118-0.554,1.732-0.25c0.347,0.172,0.767,0.391,0.932,0.704
				c0.901,1.722,2.92,1.646,4.254,2.714c1.441,1.153,2.298,0.625,2.409-1.218c0.038-0.637,0.158-1.332-0.038-1.906
				c-1.26-3.708-0.097-6.549,3.125-8.782c0.773,1.968,1.426,4.047,2.401,5.961c0.626,1.23-0.699,1.67-0.627,2.582L150.989,612.871z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(561)} fill={props.fillColors[561]} d="M506.899,673.273c4.745-4.008,10.104-4.026,15.546-3.567
				c1.088,0.092,2.149,0.489,3.224,0.744c1.108,0.264,2.805,0.329,2.299,1.894c-0.389,1.2-0.902,2.836-3.366,2.188
				c-3.554-0.937-7.315-1.168-11.007-1.474C511.483,672.883,509.376,673.854,506.899,673.273z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(562)} fill={props.fillColors[562]} d="M50.151,745.519c0.43,1.479,0.674,2.218,0.86,2.972
				c1.763,7.157,1.761,7.158-3.076,14.382c-0.435,0.649-0.888,1.286-1.445,2.091c-1.282-1.796-2.182-3.352-1.22-5.715
				C47.008,754.977,48.384,750.56,50.151,745.519z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(563)} fill={props.fillColors[563]} d="M437.732,727.307c1.013,0.025,1.826,0.502,2.569,1.135
				c0.951,0.773,0.699,1.486-0.167,2.081c-1.894,1.299-3.529,2.832-4.758,4.798c-0.67,1.072-1.367,0.952-2.167,0.004
				c-0.688-0.816-0.958-2.038-2.169-2.379c-1.034-1.151-2.069-2.302-3.104-3.453c0.265-2.486,2.409-1.965,3.847-2.163
				C433.731,727.062,435.747,727.291,437.732,727.307z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(564)} fill={props.fillColors[564]} d="M488.51,612.585c4.074-2.854,4.189-2.862,7.231-0.533
				c0.667,0.652,1.286,1.382,2.024,1.958c1.897,1.48,0.998,3.004-0.084,4.252c-1.182,1.36-2.241,0.278-3.157-0.636
				c-0.95-0.949-1.929-1.698-3.267-0.639l0.072-0.067c-0.171,0.154-0.34,0.312-0.514,0.463c-0.941,0.826-1.846,2.44-3.229,0.965
				c-1.214-1.295-1.826-2.981-0.241-4.598C487.729,613.358,488.121,612.974,488.51,612.585z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(565)} fill={props.fillColors[565]} d="M259.554,548.058c0.482,0.328,0.964,0.656,1.447,0.983
				c0.961,1.076,1.108,2.001-0.007,3.222c-1.087,1.188-1.812,2.708-2.742,4.155c-2.189-1.842-4.228-3.59-6.309-5.288
				c-0.593-0.483-1.076-0.953-0.921-1.774c0.195-1.031-0.576-2.605,0.737-2.983c1.796-0.519,3.786-0.623,5.564,0.368
				C258.076,547.16,258.644,547.896,259.554,548.058z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(566)} fill={props.fillColors[566]} d="M466.092,571.062c-0.546-4.657-1.94-6.289-4.88-5.346
				c-0.028-1.426-0.464-2.837,0.491-4.431c0.271,0.649,0.414,1.196,0.708,1.646c0.412,0.63,1.039,1.058,1.828,0.868
				c0.835-0.2,1.362-0.766,1.472-1.663c0.188-1.534-0.116-3.065-0.779-4.359c-0.831-1.621-0.517-3.173-0.613-5.331
				c1.374,1.522,4.429,0.941,3.025,4.059c-0.161,0.357,0.675,1.188,1.311,1.171c2.268-0.059,1.596,1.438,1.324,2.532
				c-0.354,1.428-1.303,2.729-0.564,4.318c0.305,0.656-0.393,0.955-0.715,1.369C467.504,567.436,466.837,569.251,466.092,571.062z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(567)} fill={props.fillColors[567]} d="M364.765,509.032c-1.721,1.268-3.511,2.451-5.137,3.831
				c-1.1,0.932-1.708,2.24-1.889,3.742c-0.253,2.116-1.353,3.852-2.731,5.415c-0.299,0.339-0.628,0.821-1.172,0.677
				c-0.637-0.17-0.39-0.77-0.432-1.207c-0.26-2.741-0.831-2.988-3.217-1.574c-1.508,0.894-2.552,2.41-4.273,3.012
				c-0.759,0.266-1.605,0.821-2.086-0.089c-0.478-0.9,0.438-1.523,1.091-1.861c4.188-2.164,8.17-4.641,11.581-7.902
				c1.871-1.79,4.801-2.602,5.271-5.807c0.107-0.729,1.108,0.513,1.782,0.252l-0.083-0.083c0.226,0.692,0.268,1.525,1.26,1.631
				C364.738,509.062,364.765,509.032,364.765,509.032z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(568)} fill={props.fillColors[568]} d="M385.734,757.316c-1.004-3.132-1.835-5.812-2.74-8.468
				c-0.156-0.46-0.04-0.702,0.167-1.057c2.307-3.955,4.589-7.925,6.897-11.878c0.101-0.172,0.384-0.237,0.464-0.283
				C390.927,743.142,387.826,749.92,385.734,757.316z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(569)} fill={props.fillColors[569]} d="M176.994,745.73c-0.549-1.945-0.82-3.933-1.66-5.641
				c-1.204-2.448-0.393-4.294,0.894-6.328c1.938-3.063,3.678-6.253,5.464-9.322C183.236,727.973,178.979,740.67,176.994,745.73z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(570)} fill={props.fillColors[570]} d="M140.133,678.696c4.388,0.402,8.228,0.457,12.078,0.2
				c1.233-0.082,2.846,2.719,2.266,3.766c-0.364,0.655-0.808,0.223-1.219,0.083c-3.059-1.037-6.285-0.491-9.423-0.891
				c-2.019-0.257-4.242,0.824-6.332,1.446c-2.83,0.844-2.179-1.662-2.577-2.81c-0.563-1.623,1.018-1.157,1.751-1.146
				C138.059,679.368,139.275,678.406,140.133,678.696z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(571)} fill={props.fillColors[571]} d="M355.51,565.114c1.442,1.463,1.271,3.049,0.359,4.689
				c-0.477,0.857-0.663,2.137-2.066,0.774c-0.657-0.639-1.28-0.055-1.727,0.605c-0.699,1.034-1.47,2.02-2.153,3.062
				c-0.322,0.49-0.572,0.704-1.265,0.716c-0.983,0.018-2.469,0.148-2.853,0.774c-1.82,2.967-4.837,3.479-7.76,4.132
				c-0.16-2.83,0.522-3.931,3.061-4.748C346.908,573.25,351.436,569.565,355.51,565.114z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(572)} fill={props.fillColors[572]} d="M90.074,690.359c3.701,2.089,5.686,4.15,5.77,8.273
				c0.138,6.75,1.116,13.483,1.729,20.224c0.043,0.47,0.006,0.948-0.185,1.452C95.08,710.514,92.909,700.699,90.074,690.359z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(573)} fill={props.fillColors[573]} d="M305.06,754.846c-1.019-3.163-1.903-5.866-2.739-8.584
				c-0.076-0.244,0.111-0.616,0.263-0.878c2.441-4.201,4.897-8.394,7.278-12.466C310.384,740.489,307.019,747.243,305.06,754.846z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(574)} fill={props.fillColors[574]} d="M291.395,724.812c0.935,0.551,1.87,1.102,2.804,1.652
				c0.889,1.872,2.963,2.271,4.352,3.518c0.509,0.457,1.311,0.842,1.234,1.642c-0.064,0.676-0.904,0.487-1.364,0.734
				c-0.749,0.4-1.707,0.36-2.095-0.499c-0.885-1.962-2.481-2.284-4.331-2.131c-1.099,0.092-1.892-0.311-2.798-0.965
				c-1.601-1.156-3.422-2.01-5.166-2.964c-0.81-0.443-1.437-0.829-1.192-2.011c0.607-2.928,0.855-3.092,3.49-1.545
				C287.967,723.205,289.428,724.505,291.395,724.812z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(575)} fill={props.fillColors[575]} d="M301.605,764.185c-1.326-6.216-2.7-12.423-3.939-18.657
				c-0.268-1.348-0.454-3.396,0.913-3.814c1.167-0.357,1.263,1.715,1.675,2.738c1.648,4.084,2.941,8.288,3.772,12.621
				c0.288,1.5,0.251,2.913-0.792,4.24C302.594,762.126,302.143,763.135,301.605,764.185z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(576)} fill={props.fillColors[576]} d="M521.217,310.511c-2.162,1.527-3.579,3.697-5.93,3.433
				c-3.546-0.398-7.012-1.539-9.849-3.774c-1.27-1-0.049-2.773,0.254-3.987c0.31-1.238,1.386,0.093,2.077,0.384
				C511.972,308.331,516.199,310.008,521.217,310.511z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(577)} fill={props.fillColors[577]} d="M308.856,565.817c-0.339-0.18-0.83-0.271-0.993-0.554
				c-1.546-2.668-3.035-5.369-4.627-8.217c2.986-0.695,5.969-0.724,8.783-1.616c0.431-0.137,1.011-0.176,1.315,0.274
				c0.28,0.413-0.185,0.688-0.369,1.021c-1.545,2.792-1.532,2.785,1.311,4.494c0.481,0.29,1.364,0.386,0.955,1.331
				c-0.265,0.609-0.675,1.137-1.366,1.222c-1.064,0.132-0.826-0.728-0.863-1.305c-0.061-0.933-0.646-1.377-1.761-1.873
				c-0.021,2.207,0.301,4.6-2.46,5.302L308.856,565.817z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(578)} fill={props.fillColors[578]} d="M381.509,544.138c0.016-1.812-1.282-1.678-2.654-1.546
				c0.533-2.105,0.344-4.019-0.454-5.862c-0.295-0.68-0.668-1.372-1.534-1.272c-0.794,0.091-0.876,0.776-0.91,1.447
				c-0.027,0.51,0.194,1.222-0.68,1.255c-1.042,0.04-0.749-0.812-0.846-1.374c-0.277-1.617,0.205-3.062,0.85-4.54
				c0.462-1.06,0.537-2.288,0.783-3.43c2.3,4.32,4.037,8.974,7.752,12.373c2.077,1.902,2.312,4.604,3.153,7.025
				c0.391,1.122-0.4,1.341-1.395,0.944c-1.116-0.444-1.648-1.424-2.344-2.294c-0.688-0.86-0.786-2.071-1.735-2.746L381.509,544.138z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(579)} fill={props.fillColors[579]} d="M179.782,567.826c1.329-1.208,3.15-1.619,3.681-3.444
				c0.17-0.586,0.729-0.568,1.191-0.553c2.581,0.094,5.004-0.529,7.615-1.792c-0.751,5.017-2.608,9.394-4.152,14.395
				c-1.284-2.579-1.055-4.953-1.385-7.197c-0.131-0.892-0.025-2.039-0.998-2.177c-1.066-0.152-1.164,1.05-1.29,1.858
				c-0.104,0.665-0.246,1.197-0.99,0.742C182.328,568.97,180.871,568.956,179.782,567.826z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(580)} fill={props.fillColors[580]} d="M395.022,492.521c0.037,1.778-1.976,2.142-2.054,3.725
				c-0.188,3.773-2.236,4.712-5.741,3.913c-1.687-0.385-3.388,0.649-5.093,0.987c-0.652,0.13-1.107,0.488-1.146,1.306
				c-0.059,1.23-1.135,1.505-2.041,1.625c-0.869,0.115-2.119,0.498-2.474-0.759c-0.396-1.398,1.075-1.251,1.721-1.407
				c5.215-1.262,8.576-5.19,12.35-8.495c0.919-0.805,1.891-1.361,2.95-1.883C394.903,490.838,395.152,491.484,395.022,492.521z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(581)} fill={props.fillColors[581]} d="M104.779,698.381c-2.657-1.282-2.09-3.36-1.913-5.043
				c0.479-4.534,0.549-9.142,1.89-13.55c0.483-1.587,1.098-3.157,1.852-4.634c0.565-1.105,1.537-1.264,2.128,0.021
				c0.941,2.042,0.696,4.282,0.365,6.366c-0.149,0.936-1.99,1.174-2.416,2.362c-0.72,2.006-1.42,4.053-1.469,6.189
				C105.154,692.786,104.328,695.411,104.779,698.381z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(582)} fill={props.fillColors[582]} d="M363.842,540.002c1.968-2.151,1.074-4.812,1.286-7.273
				c0.154-1.8-0.374-3.642,0.309-5.412c0.182-0.472,0.045-1.212,0.881-1.178c0.804,0.032,0.731,0.769,0.847,1.241
				c0.577,2.363,0.095,4.888,1.082,7.197c0.707,1.655,0.783,3.374,0.479,5.119c-0.162,0-0.351,0.059-0.45-0.01
				c-0.735-0.507-0.291-2.529-1.881-1.777c-1.308,0.619-0.887,2.027-0.496,3.146c0.248,0.709,0.362,1.609,1.378,1.758
				c0.002,0.003-0.001-0.004,0-0.001c-1.524,0.417-0.275,1.232-0.116,1.661c0.716,1.92,1.544,3.776,0.525,5.844
				c-0.502,1.018-0.906,1.798-1.814,0.23c-0.998-1.724-1.712-3.291-1.386-5.504c0.229-1.552,1.238-3.664-0.709-5.094
				C363.772,539.946,363.842,540.002,363.842,540.002z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(583)} fill={props.fillColors[583]} d="M177.428,713.992c-0.476,1.265,0.409,3.301-0.358,3.302
				c-1.244,0.001-0.955-1.859-1.224-2.963c-1.577-6.468-3.14-12.938-4.722-19.405c-0.15-0.611-0.584-1.364,0.042-1.747
				c0.565-0.346,1.202,0.232,1.682,0.638c1.847,1.562,3.109,3.169,3.155,5.979C176.082,704.587,176.924,709.365,177.428,713.992z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(584)} fill={props.fillColors[584]} d="M538.402,268.527c-1.883-0.069-3.378-1.425-5.178-1.923
				c-1.11-0.308-0.396-1.081-0.294-1.477c0.719-2.811,1.386-5.628,2.027-8.458c0.451-1.992,2.354-0.589,3.564-0.767
				c1.27-0.186,0.962,1.05,0.907,1.731C539.135,261.253,538.757,264.865,538.402,268.527z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(585)} fill={props.fillColors[585]} d="M400.204,579.637c-1.179,1.019-2.757,1.229-4.052,2.001
				c-3.938,1.181-6.205,4.448-8.903,7.175c-0.436,0.44-0.728,1.035-1.195,1.429c-0.46,0.386-1.005,0.293-1.509-0.112
				c-0.604-0.485-0.09-0.945,0.103-1.222c1.606-2.301,2.548-5.064,4.789-6.923c-0.307-0.421-0.354-0.89-0.133-1.341
				c0.51-1.044,4.785-3.215,5.92-3.012c0.348,0.062,0.483,0.367,0.546,0.692c1.313-0.789,2.798-1.085,4.249-1.484
				c1.119-0.308,2.169-0.591,2.604,0.986c0.468,1.698-1.171,0.768-1.615,1.336C400.828,579.39,400.477,579.482,400.204,579.637z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(586)} fill={props.fillColors[586]} d="M571.167,560.012c-1.218,4.905-2.773,9.75-3.359,15.172
				c-1.72-2.631-2.3-5.37-3.212-7.968c-0.051-0.145-0.001-0.322-0.037-0.475c-0.389-1.657-1.64-4.018-0.986-4.81
				c1.112-1.349,3.49-1.532,5.432-1.765C569.656,560.09,570.3,559.528,571.167,560.012z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(587)} fill={props.fillColors[587]} d="M466.055,278.698c2.346,2.154,5.011,3.308,7.841,3.979
				c1.211,0.287,1.392,0.745,1.161,1.771c-0.773,3.461-0.97,3.528-4.573,2.717c-2.335-0.526-4.359-1.617-6.374-2.833
				c-0.778-0.47-0.751-0.883-0.388-1.521C464.465,281.507,465.203,280.2,466.055,278.698z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(588)} fill={props.fillColors[588]} d="M380.501,509.909c0.076,0.164,0.152,0.328,0.229,0.489
				c-0.22,0.215-0.48,0.651-0.649,0.618c-2.49-0.486-2.919,1.004-2.733,3.461c-1.498-1.842-2.72-3.263-3.846-4.755
				c-0.781-1.036-1.6-2.138-2.837-2.441c-1.799-0.441-1.214-1.52-0.775-2.396c1.927-3.85,3.788-3.91,6.189-0.36
				C377.381,506.451,378.087,508.89,380.501,509.909z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(589)} fill={props.fillColors[589]} d="M249.529,564.723c0.812,0.771,0.74,1.859,0.971,2.826
				c0.242,1.013-0.261,1.539-1.288,1.395c-4.631-0.649-9.046,0.359-13.426,1.688c-0.736,0.223-1.488,0.394-2.317,0.611
				c-0.229-2.268-0.793-4.505,2.314-5.043c0.112-0.326,0.296-0.445,0.602-0.207c1.467-0.425,1.426,0.965,1.771,1.642
				c0.846,1.658,2.045,1.505,2.537,0.166c1.771-4.827,5.605-3.016,8.891-3.052L249.529,564.723z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(590)} fill={props.fillColors[590]} d="M451.654,569.6c-1.217-2.023-1.802-2.017-3.837-0.159
				c-0.479,0.437-0.819,1.438-1.631,0.963c-0.43-0.251-0.607-1.077-0.729-1.678c-0.201-0.991,0.718-0.944,1.281-1.325
				c3.436-2.321,6.414-5.148,8.77-8.574c0.615-0.894,0.964-1.969,1.44-2.958c0.441-0.919,0.82-2.312,1.888-2.167
				c0.999,0.137,0.293,1.522,0.53,2.312c1.098,3.667-1.736,5.65-3.81,7.42C453.518,565.176,452.142,566.953,451.654,569.6z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(591)} fill={props.fillColors[591]} d="M389.263,546.741c1.54,1.426,2.93,2.733,4.345,4.014c0.559,0.506,1,1.077,0.949,1.842
				c-0.15,2.263,1.025,2.801,3.083,2.829c3.019,0.042,5.533,2.035,8.468,2.563c0.424,0.076,1.064,0.641,0.959,1.232
				c-0.121,0.683-0.936,0.931-1.453,0.905c-2.198-0.105-4.599,0.854-6.425-1.428c-0.639-0.799-1.851-1.283-2.895-1.587
				c-3.326-0.966-5.47-3.05-6.464-6.336C389.442,549.493,387.52,548.461,389.263,546.741z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(592)} fill={props.fillColors[592]} d="M475.196,538.511c0.294-0.865-0.23-2.126,1.239-2.429
				c0.407-0.083,0.656-0.255,0.969,0.224c3.543,5.426,8.91,8.749,14.155,12.208c0.597,0.395,1.217,0.752,1.834,1.115
				c0.793,0.468,2.115,0.931,1.417,1.977c-0.489,0.733-1.669-0.143-2.538-0.408c-1.575-0.48-1.856-2.357-3.606-2.864
				c-3.168-0.918-3.104-1.134-3.359,2.194c-1.916-0.863-3.311-2.418-3.548-4.209c-0.553-4.178-3.203-6.265-6.625-7.86
				C475.129,538.455,475.196,538.511,475.196,538.511z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(593)} fill={props.fillColors[593]} d="M102.993,612.168c-0.027,0.075-0.053,0.15-0.08,0.226
				c3.902,0.221,3.559,3.343,4.011,5.901c0.163,0.921-0.457,1.061-1.178,1.003c-2.691-0.214-5.368-0.649-8.082-0.498
				c-0.704,0.04-0.762-0.32-0.589-0.892c0.182-0.603,0.31-1.223,0.446-1.838c0.849-3.829,0.848-3.829,4.571-3.832
				c0.32-0.001,0.64-0.01,0.959-0.015L102.993,612.168z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(594)} fill={props.fillColors[594]} d="M544.12,332.506c3.176,5.105-0.864,8.822-2.298,13.044
				c-0.19,0.561-0.507,1.053-1.153,1.039c-0.834-0.018-0.623-0.717-0.721-1.238c-0.446-2.393-0.975-4.773-1.371-7.174
				c-0.243-1.468-0.133-2.741,1.689-3.396C541.646,334.283,542.845,333.28,544.12,332.506z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(595)} fill={props.fillColors[595]} d="M159.443,623.2c3.418-3.23,7.507-5.374,11.769-7.224
				c0.728-0.315,1.78-1.145,2.179,0.128c0.339,1.084,0.713,2.429-0.969,3.221c-2.899,1.363-5.939,2.521-8.341,4.77
				c-0.068,0.117-0.134,0.235-0.205,0.351c0.066-0.118,0.133-0.235,0.199-0.354c-0.511,0.485-1.117,0.904-1.512,1.471
				c-0.843,1.208-1.651,0.938-2.705,0.268c-1.318-0.839-1.025-1.716-0.334-2.716C159.524,623.116,159.443,623.2,159.443,623.2z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(596)} fill={props.fillColors[596]} d="M377.944,742.871c-0.334-3.885,1.257-6.604,4.396-8.909
				c2.58-1.894,4.749-4.39,6.881-6.887c-2.112,5.22-4.25,10.401-7.359,15.118c-0.488,0.74-0.955,1.319-1.812,0.421
				C379.257,741.784,378.653,742.965,377.944,742.871z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(597)} fill={props.fillColors[597]} d="M61.876,692.465c-1.243,0.243-2.485,0.495-3.73,0.727
				c-0.462,0.085-1.196,0.338-1.359,0.142c-1.188-1.425-0.366-3.15-0.532-4.742c-0.073-0.697-0.341-1.557,0.948-1.771
				c1.932-0.322,3.81-0.959,5.735-1.34c1.147-0.228,1.928,0.118,2.094,1.562c0.473,4.101,0.531,4.094-3.223,5.462L61.876,692.465z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(598)} fill={props.fillColors[598]} d="M180.492,715.993c-1.914,5.131-4.142,10.081-6.93,14.764
				c-0.338,0.567-1.126,1.622-1.49,1.067c-0.703-1.072-3.224,0.175-2.679-2.445c0.331-1.589,0.48-2.867,1.819-4.111
				C174.406,722.302,177.565,719.271,180.492,715.993z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(599)} fill={props.fillColors[599]} d="M394.432,565.82c-0.52-1.794-0.022-3.402-0.731-4.883
				c4.627,0.514,9.127,1.014,13.628,1.517c0.369,0.041,0.818-0.099,1.026,0.364c0.271,0.605-2.406,4.707-2.99,4.492
				c-1.104-0.407-1.493,0.064-2.021,0.944c-0.723,1.207-2.272,0.921-2.812-0.47c-0.398-1.029-0.664-2.112-0.971-3.177
				c-0.429-1.483-1.039-2.622-2.45-0.811C396.364,564.754,394.342,563.719,394.432,565.82z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(600)} fill={props.fillColors[600]} d="M504.726,644.465c-1.012,0.096-1.641,1.159-2.714,1.078
				c-4.217,0.993-7.932,3.094-11.543,5.438c-0.874-1.245-0.652-1.979,0.499-2.959c5.926-5.045,13.069-6.275,20.458-6.793
				c0.882-0.062,1.705-0.256,2.553-0.42c0.546-0.105,0.982,0.26,1.062,0.747c0.126,0.76-0.551,0.582-0.971,0.633
				C510.864,642.574,507.634,642.854,504.726,644.465z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(601)} fill={props.fillColors[601]} d="M426.815,611.17c-1.165,0.632-1.961,1.652-2.784,2.643
				c-0.653,0.467-1.849,0.325-1.618,1.766c0.092,0.576-0.544,1.09-1.366,0.739c-0.649-0.277-1.323-0.534-2.011-0.682
				c-0.747-0.162-1.282-0.274-1.334-1.284c-0.065-1.265,1.047-0.585,1.496-1.132c-0.592-0.571-2.163-0.549-1.571-1.854
				c0.588-1.295,1.837,0.054,2.727-0.37c-0.283-0.512-0.78-0.171-1.077-0.339c-0.941-0.533-2.573-0.734-1.94-2.347
				c0.626-1.597,1.779-0.166,2.594-0.039c1.761,0.275,3.205,1.465,5.13,2.374c-2.481-1.854-1.39-2.285,0.547-2.714
				c1.553-0.345,3.024-0.54,4.798-0.114C429.188,609.178,427.588,609.747,426.815,611.17z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(602)} fill={props.fillColors[602]} d="M173.988,682.657c2.156-0.38,3.495-0.135,2.456,2.606
				c-0.333,0.881-0.371,1.981-0.53,2.982c-0.427,2.681-1.587,3.515-4.184,2.866c-0.316-0.079-0.61-0.113-0.756-0.446
				c-0.942-2.147-1.911-4.284-2.813-6.449c-0.236-0.565-0.104-1.209,0.698-1.127C170.621,683.271,172.249,682.234,173.988,682.657z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(603)} fill={props.fillColors[603]} d="M217.935,746.293c0.001-0.996,0.317-1.729,1.476-1.73
				c3.58-0.008,7.161-0.017,10.741-0.01c0.424,0.001,0.951-0.168,0.936,0.643c-0.034,1.828-2.879,3.996-4.77,3.771
				c-1.798-0.214-3.551,0.537-5.398,0.425C218.625,749.252,217.901,748.65,217.935,746.293z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(604)} fill={props.fillColors[604]} d="M470.876,565.159c-0.795-0.86-0.999-1.61-0.762-2.565
				c0.719-2.888,1.939-5.676,1.768-8.748c-0.034-0.612,0.266-1.149,0.928-1.161c0.674-0.012,1.001,0.559,0.964,1.142
				c-0.108,1.694,0.676,3.31,0.199,5.071c-0.435,1.609-0.137,3.269,0.308,4.929c0.733,2.744,0.249,5.606,0.369,8.412
				c0.072,1.692-2.246,1.04-3.031,2.115c-0.623-0.783-0.752-1.678-0.689-2.654c0.054-0.838,0.58-1.442,0.879-2.158
				c0.517-1.236,1.104-2.465,1.419-3.755c0.175-0.716,0.725-1.829-0.551-2.233C471.467,563.169,470.994,563.971,470.876,565.159z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(605)} fill={props.fillColors[605]} d="M526.741,262.968c0.577-2.203,1.287-4.778,1.912-7.374
				c0.195-0.812,0.747-0.696,1.271-0.705c3.386-0.06,4.14,0.786,3.463,4.037c-0.4,1.922-0.912,3.824-1.45,5.712
				c-0.278,0.976-0.8,1.812-1.979,0.919C528.934,264.782,526.646,265.846,526.741,262.968z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(606)} fill={props.fillColors[606]} d="M297.345,740.616c-0.212-3.758,0.904-6.632,4.083-8.739
				c1.83-1.213,3.372-2.888,4.653-4.751c0.506-0.735,1.129-1.466,2.541-1.949c-2.583,5.225-4.338,10.361-7.508,14.808
				c-0.362,0.507-0.66,1.232-1.423,0.458C298.895,739.633,298.131,740.265,297.345,740.616z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(607)} fill={props.fillColors[607]} d="M111.208,771.025c0-6.255,0-11.931,0-17.606c0.24-0.028,0.48-0.056,0.721-0.084
				c1.322,2.13,2.646,4.259,3.964,6.392c0.295,0.479,0.55,0.965,0.247,1.559C114.546,764.418,112.963,767.556,111.208,771.025z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(608)} fill={props.fillColors[608]} d="M164.468,470.353c-1.712-0.032-3.008,0.994-3.615,2.298
				c-0.598,1.283,0.89,0.988,1.628,1.193c0.519,0.144,1.006,0.399,1.655,0.665c-1.201,0.937-2.553,1.186-3.902,1.244
				c-0.846,0.038-2.034-0.008-0.963-1.464c0.467-0.634,0.292-1.241-0.599-1.357c-2.847-0.372-5.653-1.228-8.567-0.745
				c0.008,0.013,0.035,0.049,0.043,0.061c-0.19-0.416-0.426-0.831-0.579-1.274c-0.465-1.347-0.354-2.372,1.447-2.266
				c1.652,0.097,3.512-0.658,4.708,1.297c0.463,0.757,1.374,0.59,1.904,0.094c2.408-2.248,5.768-1.276,8.75-3.117
				c-0.314,1.771-1.811,2.182-1.906,3.38C164.472,470.367,164.468,470.353,164.468,470.353z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(609)} fill={props.fillColors[609]} d="M246.179,569.242c0.722,0,1.36,0.012,1.998-0.003c0.972-0.021,2.08-0.123,2.269,1.149
				c0.169,1.145-1.825,3.022-2.869,2.778c-3.938-0.919-7.626,0.292-11.379,1.152c-0.715,0.164-1.675,1.666-2.411-0.084
				c-0.723-1.722-0.062-2.669,1.646-2.993C239.039,570.556,242.459,568.858,246.179,569.242z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(610)} fill={props.fillColors[610]} d="M59.762,742.304c-1.326-2.49-1.664-5.185-2.574-7.676
				c-0.24-0.655,0.198-1.256,0.495-1.773c1.646-2.869,3.355-5.702,5.048-8.556C64.564,727.308,63.65,732.909,59.762,742.304z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(611)} fill={props.fillColors[611]} d="M348.262,622.42c3.354-1.62,6.419-0.772,9.367-0.167
				c1.105,0.228,0.726,2.04,0.972,3.111c0.331,1.444-1.39,0.736-1.919,1.405c1.763,0.677,2.744,3.07,1.951,4.9
				c-0.447,1.034-1.273,0.83-1.833,0.153c-1.101-1.332-2.145-2.721-3.085-4.167c-0.681-1.047,0.534-0.752,1.151-1.105
				C351.507,626.739,350.634,623.745,348.262,622.42z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(612)} fill={props.fillColors[612]} d="M62.516,713.908c-1.298,3.929-2.907,7.724-4.865,11.373
				c-0.224,0.418-0.617,0.784-0.718,1.224c-0.636,2.774-2.71,2.065-4.558,1.948c-0.479-0.029-0.41-0.373-0.424-0.657
				c-0.106-2.155-0.25-4.253,1.912-5.737C57.144,719.804,59.575,716.627,62.516,713.908z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(613)} fill={props.fillColors[613]} d="M107.965,613.609c0-1.808,0.051-3.454-0.016-5.096
				c-0.055-1.355,0.571-1.865,1.847-1.853c2.226,0.024,4.452,0.003,6.678,0.024c0.442,0.005,1.074-0.131,1.149,0.501
				c0.035,0.297-0.308,0.691-0.563,0.963c-2.102,2.237-4.2,4.481-6.355,6.668c-0.63,0.64-1.192,1.919-2.226,1.436
				C107.369,615.734,108.199,614.395,107.965,613.609z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(614)} fill={props.fillColors[614]} d="M171.594,589.529c1.122,0.723,2.242,1.444,3.363,2.166
				c0.236,0.204,0.498,0.386,0.706,0.615c0.45,0.496,1.553,0.67,1.387,1.35c-0.225,0.921-1.208,1.106-2.142,0.967l0.09,0.077
				c-0.842-2.358-3.15-2.308-4.95-2.622c-1.726-0.302-3.623-0.443-5.426,0.21c-0.887,0.32-2.128,0.443-2.553-0.488
				c-0.558-1.222-1.019-2.741-0.768-3.979c0.138-0.682,1.818-0.996,2.995-0.831C166.9,587.358,169.206,588.559,171.594,589.529z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(615)} fill={props.fillColors[615]} d="M107.932,711.609c-3.194-2.446-6.026-5.008-7.708-8.674
				c-0.723-1.576-0.567-3.22-1.174-4.761c-0.329-0.834,0.288-1.291,1.258-1.017c2.085,0.591,6.776,5.657,6.102,7.547
				c-0.814,2.279-0.396,3.78,0.806,5.482C107.517,710.616,107.696,711.133,107.932,711.609z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(616)} fill={props.fillColors[616]} d="M74.405,603.819c1.443,0.859,0.628,2.453,1.699,4.059
				c-8.46-2.899-16.213-6.69-24.871-3.757c-0.021-2.601,0.089-2.618,2.733-2.725c6.986-0.28,13.872,0.15,20.529,2.504
				C74.493,603.899,74.405,603.819,74.405,603.819z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(617)} fill={props.fillColors[617]} d="M301.978,565.67c-1.137-0.623-2.695-1.128-0.672-2.494
				c0.144-0.098,0.517-0.298,0.093-0.484c-0.636-0.278-1.361-0.741-0.75-1.346c0.896-0.887,1.92,0.224,2.919,0.27
				c0.738,0.033,1.114,0.716,1.113,1.359c-0.002,0.968-0.989-0.361-1.169,0.467c0.634,0.637,2.123,0.604,2.187,1.506
				c0.098,1.404,0.829,2.215,1.565,3.252c0.922,1.299,1.235,2.974,1.564,4.869c-2.471-0.826-4.646-1.81-6.954-2.422
				c-1.85-0.491-0.7-2.525-1.29-3.773C300.101,565.853,301.659,566.327,301.978,565.67z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(618)} fill={props.fillColors[618]} d="M269.466,763.596c-1.839,0.343-3.417,0.109-3.696-2.127
				c-0.275-2.21-1.748-3.801-2.709-5.651c-0.606-1.167-0.137-1.486,0.921-1.3c2.637,0.464,5.256,1.031,7.893,1.497
				c2.018,0.355,1.042,1.733,0.858,2.671c-0.207,1.046-0.854,1.603-2.098,0.954c-1.356-0.706-2.88-0.75-4.438-0.443
				c1.396,0.37,2.79,0.752,4.192,1.104c0.539,0.136,1.187,0.191,1.281,0.837c0.088,0.604-0.625,0.609-0.961,0.906
				c-0.532,0.467-1.127,0.891-1.32,1.635L269.466,763.596z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(619)} fill={props.fillColors[619]} d="M115.918,676.156c0.356,0.137,0.713,0.272,1.068,0.407
				c0.651,0.298,1.023,1.549,2.037,0.596c-0.007-0.008-0.054-0.066-0.06-0.073c0.267,1.296,1.789,0.709,2.457,1.679
				c-1.516,1.339-3.049,2.676-4.561,4.036c-0.457,0.411-0.819,0.246-1.266-0.014c-0.538-0.313-1.11-0.725-1.695-0.774
				c-3.595-0.308-3.281-3.125-3.692-5.531c-0.21-1.233,0.332-1.43,1.294-1.091C112.936,675.895,114.411,676.1,115.918,676.156z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(620)} fill={props.fillColors[620]} d="M414.423,736.139c-2.685,2.603-5.902,4.436-8.089,7.485
				c0.463-1.724,0.588-3.226-0.475-4.762c-1.346-1.948-2.829-2.146-4.721-0.601c-0.667,0.546-0.91,2.181-2.031,1.536
				c-1.12-0.645,0.191-1.811,0.105-2.808c-0.084-0.966,0.522-1.746,1.789-1.673c3.323,0.191,6.676-0.439,9.981,0.413
				C412.021,735.998,413.169,735.509,414.423,736.139z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(621)} fill={props.fillColors[621]} d="M315.232,584.558c-1.4-2.895-4.222-4.383-6.42-6.488
				c-1.808-1.835-4.346-2.077-6.601-2.77c-2.164-0.666-1.364-2.152-1.247-3.236c0.167-1.564,1.31-0.735,2.186-0.497
				c1.62,0.438,3.117,1.12,4.588,1.916c1.173,0.634,2,1.373,2.013,2.842c0.014,1.479,0.955,2.369,2.318,1.883
				c1.302-0.464,1.69,0.177,2.255,0.98c0.822,1.17,1.669,2.319,2.187,3.67C316.992,584.113,316.604,584.701,315.232,584.558z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(622)} fill={props.fillColors[622]} d="M268.213,291.975c-0.101,0.766-2.72,0.998-0.332,1.992
				c0.322,0.134,0.601,0.416,0.261,0.703c-1.724,1.456,1.411,2.47,0.323,3.845c1.413,0.38-0.584,2.178,1.029,2.475
				c-0.171,0.772-0.512,0.996-1.349,0.699c-1.857-0.659-3.137-2.068-4.468-3.365c-0.421-0.411-0.788-0.928-1.27-1.258
				c-0.242-0.907,3.326-1.229,0.334-2.493c0.66-0.782,1.244-1.383,0.984-2.725c-0.246-1.269,0.931-2.621,0.675-4.14
				c-0.034-0.198,1.081-0.974,1.821-0.48c0.368,0.245,1.318,0.114,0.73,1.271C266.256,289.868,265.808,291.573,268.213,291.975z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(623)} fill={props.fillColors[623]} d="M409.975,183.84c0.882,1.643,2.562,1.944,4.066,1.867
				c2.804-0.143,5.589-0.657,8.377-1.057c1.799-0.257,2.656-1.675,3.289-3.104c0.732-1.656,1.676-1.937,3.141-0.962
				c0.088,0.466-0.478,0.52-0.627,0.852c-3.298,7.363-10.29,5.891-16.278,6.752c-0.976,0.141-4.502-3.374-4.711-4.384
				c-0.078-0.375,0.032-0.638,0.383-0.732c0.407-0.109,0.775-0.722,1.25-0.182c0.355,0.404,0.513,1.02,1.198,1.039L409.975,183.84z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(624)} fill={props.fillColors[624]} d="M156.201,455.42c0.265,1.949-0.644,3.103-1.358,4.292
				c-0.48,0.8-1.25,0.865-2.098,0.509c-3.317-1.396-5.744-3.657-7.236-7.2c2.57,0.28,3.906,1.97,5.737,3.968
				c-0.05-4.489,0.375-8.396,0.996-12.285c1.529,3.088,0.88,6.302,0.958,9.46c0.019,0.763-0.411,1.746,0.328,2.246
				C154.5,457.067,155.21,456.001,156.201,455.42z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(625)} fill={props.fillColors[625]} d="M422.966,757.717c-2.425-0.733-5.39-0.261-8.328-0.389
				c-0.485-0.021-1.207,0.169-1.181-0.759c0.07-2.426,3.135-4.622,5.564-4.112c2.105,0.442,4.141,1.042,6.035,2.047
				c0.648,0.345,1.896,0.554,1.635,1.372c-0.278,0.865-1.069,1.791-2.281,1.839C424.091,757.727,423.771,757.717,422.966,757.717z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(626)} fill={props.fillColors[626]} d="M156.526,616.218c-1.18-5.258,1.61-9.92,7.117-11.782
				c0.23,1.068,0.274,2.219,0.73,3.173c0.893,1.869,0.833,3.275-1.278,4.148c-0.005,0.004,0.055-0.054,0.048-0.047
				c-2.772,0.673-5.033,2.086-6.627,4.485c-0.099,0.052-0.148,0.078-0.148,0.078C156.318,616.303,156.372,616.283,156.526,616.218z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(627)} fill={props.fillColors[627]} d="M587.692,619.721c2.024,1.223,4.013,2.511,6.09,3.639
				c0.925,0.502,0.908,1.029,0.643,1.862c-0.435,1.36-1.537,2.411-1.489,4.033c0.035,1.185-0.762,1.315-1.813,0.655
				c-2.156-1.352-4.372-2.515-3.514-5.785c0.079-0.304-1.115-1.465-1.48-2.4c-0.667-1.705-0.448-2.604,1.646-1.931L587.692,619.721z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(628)} fill={props.fillColors[628]} d="M66.527,320.411c1.059-0.898,2.879,0.61,3.545-0.905
				c0.432-0.983-1.589-2.069-0.18-3.62c2.428,5.277,4.861,10.358,7.884,15.655C70.341,327.031,68.027,324.704,66.527,320.411z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(629)} fill={props.fillColors[629]} d="M223.882,698.442c2.994,2.903,5.98,5.532,8.175,8.847
				c0.43,0.649,1.189,1.359,0.812,2.105c-0.341,0.676-1.312,0.411-1.986,0.291c-1.146-0.204-2.891,0.729-3.35-0.443
				c-0.955-2.439-4.413-3.443-3.695-6.805C224.11,701.161,223.882,699.777,223.882,698.442z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(630)} fill={props.fillColors[630]} d="M405.135,631.048c-0.878,0.034-1.571-0.808-2.497-0.528
				c-0.461-1.371-3.004-0.261-2.806-2.22c0.124-1.225,0.63-2.453,1.172-3.58c0.472-0.979-0.011-2.861,2.047-2.567
				c2.519,0.359,4.082,1.785,4.097,3.672c0.009,1.038-0.092,2.087,0.029,3.11C407.369,630.556,406.992,631.478,405.135,631.048z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(631)} fill={props.fillColors[631]} d="M486.371,559.658c-1.03-1.134-2.261-2.014-2.518-3.729
				c-0.303-2.015-1.479-3.679-3.15-4.823c-2.23-1.529-2.073-3.702-1.915-5.939c0.043-0.609,0.232-1.157,0.896-1.194
				c0.69-0.038,0.521,0.68,0.668,1.064c0.731,1.922,1.374,3.862,3.014,5.293c0.853,0.744,1.575,1.488,2.863,1.37
				c0.899-0.082,1.872,0.257,1.938,1.313c0.078,1.234,0.581,3.378-0.243,3.566C485.754,557.077,486.466,558.332,486.371,559.658z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(632)} fill={props.fillColors[632]} d="M86.341,677.06c0.1,0.887,0.736,0.921,1.407,0.869c3.266-0.247,6.41,0.515,9.59,1.078
				c-0.396,1.337-0.803,2.6-1.135,3.882c-0.157,0.604-0.318,0.977-1.076,0.893c-2.04-0.225-4.05-0.621-6.116-0.74
				c-1.707-0.099-3.118-3.611-2.635-5.924C86.369,677.108,86.341,677.06,86.341,677.06z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(633)} fill={props.fillColors[633]} d="M20.46,693.371c2.133,1.815,4.259,3.635,5.201,6.42
				c0.224,0.662,0.921,1.615,0.702,1.904c-1.874,2.474,0.539,4.27,1.097,6.809c-4.076-3.034-6.274-6.781-7.661-11.077
				c-0.262-0.811-0.384-1.676-0.479-2.526c-0.091-0.827-0.091-1.688,1.18-1.475L20.46,693.371z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(634)} fill={props.fillColors[634]} d="M345.646,625.317c-0.08,1.037,0.379,2.385-1.203,2.943
				c-0.154,0.055-0.253,0.271-0.377,0.412c2.27,1.365-0.041,2.708-0.089,4.051c-0.041,1.129-0.905,1.215-1.575,0.312
				c-1.536-2.071-3.019-4.183-4.513-6.285c-0.582-0.821,0.059-1.118,0.651-1.391c1.422-0.653,2.839-1.319,4.281-1.923
				C345.643,622.256,345.648,622.271,345.646,625.317z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(635)} fill={props.fillColors[635]} d="M375.703,558.653c-1.543-1.14-1.976-2.989-3.328-4.742
				c2.486,0.529,3.141-0.127,2.175-2.057c-1.309-2.612,0.677-5.201-0.495-7.818c1.684-0.076,1.39,2.224,3.08,1.396
				c0.463-0.226,0.217,0.739,0.204,1.119c-0.059,1.789,0.145,3.607,1.133,5.036c1.077,1.557,0.64,2.932,0.241,4.433
				c-0.08,0.302-0.146,0.841-0.438,0.7C376.174,555.706,376.666,558.051,375.703,558.653z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(636)} fill={props.fillColors[636]} d="M463.429,553.451c-0.25,2.368,0.516,4.136,1.083,5.92
				c0.225,0.706,0.331,1.365,0.342,2.073c0.01,0.59-0.098,1.242-0.723,1.355c-0.774,0.141-1.102-0.462-1.18-1.167
				c-0.073-0.682,0.12-1.517-1.043-1.438c-0.94,0.062-1.034,0.748-1.307,1.435c-0.308,0.774-0.306,1.535-0.348,2.328
				c-0.061,1.115,0.532,2.523-1.762,2.34c-1.304-0.104-1.289,1.987-2.219,2.854c-0.374,0.35-0.492,1.171-1.111,1.054
				c-0.903-0.171-0.417-1.038-0.516-1.598c-0.344-1.968,0.169-3.326,1.871-4.791C459.594,561.169,461.433,557.47,463.429,553.451z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(637)} fill={props.fillColors[637]} d="M280.482,360.631c1.979,2.637,3.572,4.868,5.294,6.996
				c0.661,0.816,0.523,1.492,0.014,2.132c-1.762,2.213-3.587,4.375-5.647,6.467C280.795,371.212,280.159,366.254,280.482,360.631z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(638)} fill={props.fillColors[638]} d="M461.419,541.602c-0.052-1.574,1.106-3.628-1.783-4.044
				c-0.571-0.083-0.521-1.059,0.02-1.359c1.838-1.021,2.591-2.943,3.889-4.405c0.796-0.896,2.217-2.122,2.971-1.354
				c1.069,1.088,2.566,2.01,3.033,3.646c0.126,0.441-0.151,0.692-0.569,0.89c-2.954,1.391-5.042,3.75-6.979,6.279
				c-0.123,0.161-0.432,0.182-0.653,0.269C461.346,541.525,461.419,541.602,461.419,541.602z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(639)} fill={props.fillColors[639]} d="M410.062,183.93c0.209-0.619-0.916-1.401,0.144-1.871
				c0.816-0.361,1.721-1.173,2.623-0.435c1.711,1.398,3.617,0.764,5.923,0.879c-1.634-1.398-3.363-0.277-4.868-1.181
				c0.483-0.611,1.101-0.875,1.738-0.941c1.549-0.16,3.14-0.354,4.643-0.364c1.32-0.009,2.594,0.333,3.991-0.086
				c1.073-0.322,1.414,0.405,0.799,1.388c-0.795,1.271-1.499,2.534-3.288,2.745c-3.95,0.466-7.896,1.863-11.792-0.224L410.062,183.93z
				"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(640)} fill={props.fillColors[640]} d="M301.466,437.03c1.205-0.869,2.468-1.669,3.596-2.63
				c0.758-0.646,1.688-1.575,1.084-2.599c-0.485-0.821-1.498-0.633-2.544-0.349c-2.741,0.744-5.457,1.545-8.107,2.552
				c-0.721,0.273-1.558,0.642-1.803-0.261c-0.227-0.835,0.785-0.689,1.338-0.885c3.933-1.394,8.05-2.231,11.865-3.986
				c1.086-0.5,1.833-0.209,2.484,0.974c2.429,4.402,2.481,4.372-2.269,5.883c-1.794,0.57-3.585,1.147-5.378,1.721
				C301.644,437.311,301.554,437.17,301.466,437.03z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(641)} fill={props.fillColors[641]} d="M469.344,294.009c-3.322-0.611-7.172-0.381-10.775-1.817
				c-1.152-0.459-1.569-0.674-0.538-1.872c1.72-1.996,3.212-3.126,5.909-1.309c1.838,1.239,4.171,1.751,6.294,2.556
				c0.76,0.288,2.007,0.164,1.95,1.24C472.105,294.304,470.737,293.931,469.344,294.009z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(642)} fill={props.fillColors[642]} d="M29.542,696.937c2.667-1.946,5.335-3.893,8.002-5.839
				c1.172-0.175,1.483-0.008,1.044,1.358c-1.191,3.706-4.208,5.702-6.965,7.958c-0.979,0.8-2.004,1.522-2.594,2.685l0.024-0.034
				c-2.235-1.64-2.385-3.112-0.531-4.843C28.917,697.853,29.205,697.367,29.542,696.937z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(643)} fill={props.fillColors[643]} d="M532.324,643.928c0.513,0.267,1.024,0.533,1.527,0.793
				c0.636,0.504,1.127,1.138,1.101,1.98c-0.016,0.527,0.009,1.126-0.483,1.54c-0.695,0.588-0.975-0.217-1.373-0.446
				c-3.787-2.183-7.758-3.796-12.173-4.104c0.001,0.001,0.09,0.07,0.092,0.07c-0.469-0.981-1.643-0.292-2.286-0.971
				c-0.305-0.321-1.29,0.029-1.043-0.871c0.154-0.562,0.392-1.224,1.25-1.182c1.341,0.064,2.74-0.153,4.014,0.16
				C526.138,641.685,529.356,642.436,532.324,643.928z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(644)} fill={props.fillColors[644]} d="M181.53,449.86c-3.738-3.957-4.698-9.049-6.667-14.213
				c3.085,0.937,5.217,2.597,7.263,4.224c1.187,0.944,0.53,2.889-0.21,3.702c-1.612,1.77-1.069,3.346-0.404,5.119
				C181.64,449.035,181.53,449.469,181.53,449.86z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(645)} fill={props.fillColors[645]} d="M121.673,569.006c2.853,1.577,5.587,2.234,8.354,2.85
				c0.824,0.184,2.529,0.656,0.996,2.206c-0.245,0.248-0.574,0.698-0.932,0.145c-1.509-2.338-1.73-0.274-2.263,0.658
				c-0.823,1.439-1.604,2.903-2.45,4.328c-0.298,0.504-0.417,1.445-1.223,1.203c-0.59-0.177-0.674-0.969-0.83-1.612
				C122.592,575.741,122.12,572.66,121.673,569.006z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(646)} fill={props.fillColors[646]} d="M386.817,763.473c-1.264-3.854,0.381-6.873,1.385-9.778
				c2.417-6.991,3.67-14.113,4.074-21.468c0.13-2.365,0.744-4.801,3.085-6.995C392.812,738.238,391.079,750.891,386.817,763.473z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(647)} fill={props.fillColors[647]} d="M186.597,714.504c-2.37,12.779-4.168,25.371-8.476,37.395
				c-0.84-3.207,0.354-6.177,1.311-9.021c2.249-6.686,4-13.388,4.075-20.515C183.547,718.461,184.631,716.352,186.597,714.504z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(648)} fill={props.fillColors[648]} d="M24.151,590.851c-1.935,1.832-0.351,5.45-3.162,7.238
				c-0.322,0.204-0.155,0.344,0.011,0.521c0.991,1.066,0.261,2.247-0.544,2.31c-1.039,0.082-2.625,0.214-3.076-1.462
				c-0.338-1.259-0.704-2.512-1.079-3.762c-0.111-0.37-0.298-0.613,0.134-1.008c2.01-1.832,4.195-3.408,6.482-4.874
				C24.02,589.106,24.113,589.627,24.151,590.851z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(649)} fill={props.fillColors[649]} d="M319.673,552.663c-2.394,6.596-2.739,12.868-2.566,19.216
				c-0.196,0.008-0.394,0.017-0.59,0.025c-0.802-1.773-1.638-3.532-2.394-5.325c-0.425-1.009-0.069-1.737,0.838-2.448
				c1.92-1.506,1.761-2.791-0.488-3.837c-1.629-0.758-1.512-1.429-0.932-3.08C314.558,554.317,316.932,553.909,319.673,552.663z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(650)} fill={props.fillColors[650]} d="M363.863,548.795c1.271,2.507,3.096,4.427,2.646,7.259
				c-0.147,0.931-0.181,1.869-1.145,2.263c-0.792,0.322-0.503,0.739-0.139,0.994c1.323,0.925,0.671,2.118,0.555,3.409
				c-0.564-1.355-1.217-2.534-1.519-3.797c-0.166-0.698-0.738-1.593,0.247-2.372c0.467-0.37,0.12-1.13-0.453-1.364
				c-0.824-0.337-0.921,0.566-1.029,0.946c-0.521,1.825,0.668,3.341,1.072,5.006c0.155,0.639,0.931,1.225,0.155,1.921
				c-0.527,0.474-1.182,0.45-1.813,0.312c-0.895-0.194-0.908-0.903-0.992-1.658C360.913,556.925,361.771,551.953,363.863,548.795z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(651)} fill={props.fillColors[651]} d="M457.935,397.588c0.89,0.256,2.271-1.541,2.653,0.412
				c0.264,1.348,1.38,3.143-1.17,3.988c-1.342,0.446-2.441,1.625-3.649,2.473c-0.206,2.319-0.907,2.812-3.396,2.387
				c-2.136-2.228-1.97-3.589,0.28-5.398c1.737-1.398,3.995-2.074,5.368-3.948L457.935,397.588z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(652)} fill={props.fillColors[652]} d="M431.237,752.888c-0.049,0.878-2.949,2.194-3.831,1.771
				c-3.127-1.5-6.402-2.556-9.752-3.392c-0.897-0.225-0.99-0.683-0.688-1.37c0.387-0.878,0.412-2.437,1.836-2.006
				c4.058,1.228,8.205,2.257,11.927,4.403C430.972,752.435,431.118,752.744,431.237,752.888z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(653)} fill={props.fillColors[653]} d="M173.672,581.023c-0.154,0.055-0.308,0.109-0.461,0.163
				c-0.706-2.231-0.328-2.934,1.706-3.112c-0.974-1.354-1.609-2.578,1-2.425l-0.086-0.081c1.122,1.072,2.801,1.785,1.625,4.01
				c-0.209,0.397,0.009,3.317,2.491,2.891c0.738-0.127,0.972,0.764,1.618,0.836l-0.085-0.085c0.244,1.632,1.696,2.64,2.15,4.159
				c0.199,0.664,1.281,1.367,0.397,1.979c-0.77,0.533-1.457-0.08-2.138-0.842C179.413,585.748,176.947,582.931,173.672,581.023z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(654)} fill={props.fillColors[654]} d="M351.991,509.414c-1.116-0.129-2.268,0.278-2.567-0.803
				c-0.268-0.968,0.781-1.45,1.423-1.989c2.397-2.015,4.699-4.027,8.053-4.587c2.119-0.353,1.812,0.731,1.553,1.744
				c-0.42,1.646-1.47,2.888-3.021,3.745C355.627,508.52,353.725,509.102,351.991,509.414z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(655)} fill={props.fillColors[655]} d="M148.343,645.127c0.777,0.908,1.949,0.519,2.893,0.88
				c0.885,0.34,1.211,1.013,1.019,1.891c-0.23,1.049-1.078,0.757-1.729,0.617c-6.687-1.431-13.349-1.143-19.988,0.285
				c-0.891,0.191-1.758,0.737-1.753-0.818l-0.073,0.095c1.825-1.04,3.811-1.602,5.86-1.977l-0.093,0.056
				c4.592-1.421,9.269-1.606,14.003-0.97L148.343,645.127z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(656)} fill={props.fillColors[656]} d="M32.37,597.351c-1.13-0.144-2.084,1.334-2.419-0.385
				c-0.214-1.099-1.321-2.33,0.498-3.155c1.886-0.854,3.879-1.297,5.901-1.569c1.409-0.189,2.239,0.402,1.44,1.907
				c-0.936,1.766-1.553,3.646-2.019,5.554c-0.353,1.446-1.023,1.321-2.012,0.801c-0.556-0.292-1.122-0.567-1.689-0.837
				C30.624,598.978,30.853,598.252,32.37,597.351z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(657)} fill={props.fillColors[657]} d="M106.865,730.27c0.718-6.545-0.928-12.687-3.121-18.738
				c-0.175-0.484-0.846-1.023-0.305-1.489c0.721-0.62,1.07,0.293,1.516,0.642c3.451,2.701,3.807,6.6,4.38,10.566
				C109.84,724.75,109.716,727.802,106.865,730.27z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(658)} fill={props.fillColors[658]} d="M440.177,603.547c-2.738,1.208-5.323,2.322-7.884,3.488
				c-1.895,0.861-3.782-0.771-5.813,0.196c-1.102,0.525-2.568,0.59-3.788,1.121c-0.646,0.281-1.15-0.011-1.449-0.368
				c-0.463-0.554,0.173-0.732,0.556-0.993c4.914-3.348,10.372-4.562,16.237-4.114C438.713,602.929,439.365,603.285,440.177,603.547z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(659)} fill={props.fillColors[659]} d="M387.004,579.96c-0.339-0.401-0.679-0.802-1.018-1.202
				c-0.694-1.087-0.346-1.669,0.664-2.512c3.382-2.82,7.549-3.779,11.498-5.279c1.505-0.571,1.615,0.456,2.061,1.453
				c0.633,1.42-0.496,1.532-1.187,1.712c-3.638,0.95-7.28,1.847-10.186,4.457C389.017,580.104,388.211,580.3,387.004,579.96z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(660)} fill={props.fillColors[660]} d="M273.758,753.664c0.181,1.53-0.302,1.966-1.905,1.558
				c-2.437-0.621-4.904-1.229-7.472-1.148c-0.603,0.02-1.24-0.226-1.82-0.454c-1.427-0.561-2.129-2.094-2.002-3.166
				c0.165-1.394,1.555-0.121,2.398-0.186c3.113-0.237,6.086,0.695,9.086,1.363C273.18,751.884,274.001,752.312,273.758,753.664z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(661)} fill={props.fillColors[661]} d="M157.49,606.938c-0.597-3.369,0.235-6.409,0.338-9.477
				c0.051-1.523,1.705-1.882,2.755-2.197c1.347-0.404,0.993,1.262,1.123,1.861c0.455,2.099,2.146,4.443,1.163,6.203
				C161.962,604.951,159.473,605.689,157.49,606.938z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(662)} fill={props.fillColors[662]} d="M505.317,229.75c1.247,0.836,1.473,2.153,1.109,3.376
				c-1.118,3.765-1.74,7.613-2.286,11.484c-0.022,0.157,0.007,0.349-0.073,0.466c-0.85,1.241-0.826,3.73-2.644,3.458
				c-1.383-0.207-0.787-2.559-1.276-3.839c-1.226-3.21-1.344-5.713,2.298-7.465c0.716-0.345,0.92-2.126,1.026-3.276
				c0.155-1.667,0.792-3.021,1.885-4.23C505.362,229.72,505.317,229.75,505.317,229.75z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(663)} fill={props.fillColors[663]} d="M469.864,299.569c-0.191,1.074-0.561,1.73-1.374,2.374
				c-2.563,2.023-2.797,3.507-1.444,6.631c0.522,1.208,0.418,2.104-0.554,4.741c-2.119-1.004-4.358-4.762-3.94-7.036
				C463.183,302.849,464.646,300.479,469.864,299.569z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(664)} fill={props.fillColors[664]} d="M129.163,683.829c0.389,1.682-0.232,2.389-1.366,2.979
				c-2.167,1.131-4.291,2.354-6.356,3.66c-0.883,0.558-1.625,0.889-2.609,0.334c-0.591-0.332-1.645,0.26-1.901-0.527
				c-0.289-0.887,0.716-1.326,1.279-1.805c2.902-2.468,6.316-4.153,9.619-5.971C129.986,681.312,128.949,683.488,129.163,683.829z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(665)} fill={props.fillColors[665]} d="M533.34,346.894c-0.467-2.054-0.952-4.103-1.395-6.162
				c-0.335-1.557,0.995-1.74,1.925-2.213c3.178-1.618,3.18-1.609,3.908,1.871c0.081,0.386,0.263,0.754,0.315,1.142
				c0.159,1.172,0.666,2.483,0.319,3.494c-0.487,1.42-2.124,0.314-3.152,0.75C534.704,346.011,534.003,346.1,533.34,346.894z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(666)} fill={props.fillColors[666]} d="M460.153,345.825c-3.917-1.688-7.111-3.842-10.269-6.087
				c-0.985-0.701-0.653-1.334-0.005-1.589c1.034-0.406,0.888-3.265,2.595-1.653c2.231,2.106,4.713,3.684,7.348,5.159
				C461.901,342.818,459.726,344.258,460.153,345.825z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(667)} fill={props.fillColors[667]} d="M351.388,531.099c0.375,4.092-1.062,7.267-4.552,9.56
				c-2.028,1.332-3.913,2.886-5.858,4.346c-0.252,0.189-0.505,0.621-0.709,0.592c-0.898-0.128-1.991,1.1-2.532,0.163
				c-0.6-1.04,0.622-1.538,1.432-2.03c4.848-2.953,8.319-7.227,11.367-11.904c0.166-0.253,0.386-0.474,0.596-0.694
				C351.172,531.086,351.284,531.109,351.388,531.099z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(668)} fill={props.fillColors[668]} d="M283.223,715.444c-0.619,3.024-1.282,6.325-1.978,9.619
				c-0.144,0.682-0.59,0.752-1.266,0.598c-3.336-0.762-3.314-0.727-2.023-4.288c0.85-2.345,1.524-4.764,2.915-6.871
				c0.408-0.618,0.739-1.626,1.728-1.271C283.426,713.53,283.088,714.468,283.223,715.444z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(669)} fill={props.fillColors[669]} d="M520.334,383.137c-2.115,0.523-2.093-0.896-1.312-2.491
				c0.846-1.73,1.393-4.103,4.375-2.887c0.817,0.333,1.901-0.053,2.84,0.069c1.18,0.153,2.434,0.417,2.425,2.015
				c-0.005,0.861,0.204,1.361,1.178,1.318c0.655-0.029,0.798,0.44,0.663,0.965c-0.146,0.567-0.512,0.364-0.918,0.234
				c-2.398-0.771-4.778-1.539-6.976,0.621C522.271,383.313,521.383,383.085,520.334,383.137z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(670)} fill={props.fillColors[670]} d="M134.609,467.484c1.598-0.006,2.829,1.076,4.172,1.035
				c3.86-0.117,5.537,2.286,6.707,5.58c-0.677,0.572-1.893,0.013-2.653,0.936c-1.052,1.278,0.757,1.527,0.687,2.393
				c-2.214,1.8-3.271-0.427-4.758-1.117c0.158-0.636,0.512-0.59,0.808-0.598c0.729-0.021,1.509,0.021,1.768-0.868
				c0.253-0.87-0.471-1.336-0.978-1.775c-1.562-1.353-3.206-2.611-4.791-3.939C135.157,468.783,134.694,468.419,134.609,467.484z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(671)} fill={props.fillColors[671]} d="M499.112,261.371c-1.668-3.308-3.096-6.14-4.615-9.154
				c2.486-0.243,4.663-0.475,6.845-0.66c1.363-0.115,1.911,0.325,1.247,1.771C501.453,255.797,500.425,258.316,499.112,261.371z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(672)} fill={props.fillColors[672]} d="M91.125,588.79l-0.028,0.009c-1.386,0.124-2.497-0.301-3.168-1.596
				c-0.147-0.285,0.114,0.067,0.035-0.241c-0.026-0.103,0.15-0.127,0.339-0.023c-0.513-0.61-1.069-1.203-1.876-1.359
				c-2.88-0.558-4.214-2.604-4.978-5.162c-0.184-0.616-0.628-0.613-1.043-0.788c-0.684-0.287-1.489-0.763-1.287-1.489
				c0.266-0.957,1.172-0.875,1.993-0.505c0.398,0.18,0.778,0.045,1.125-0.188l-0.083-0.063c1.253,0.698,2.425,1.434,1.996,3.198
				c-0.29,1.191,0.856,0.858,1.353,1.18c0.848,0.549,1.764,0.994,2.651,1.483c1.863-0.646,3.701,0.838,3.96,3.2
				C92.748,587.632,91.864,588.181,91.125,588.79z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(673)} fill={props.fillColors[673]} d="M349.126,738.723c-2.842,0.328-4.705-1.408-6.787-2.426
				c-1.165-0.57,0.027-1.043,0.444-1.149c1.896-0.481,3.821-0.861,5.743-1.237c0.461-0.09,0.974,0.06,1.428-0.047
				c0.914-0.215,2.165-0.948,2.665-0.195c0.632,0.952,0.615,2.357,0.799,3.579c0.108,0.724-0.412,0.93-1.046,0.973
				C351.197,738.299,350.115,739.052,349.126,738.723z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(674)} fill={props.fillColors[674]} d="M299.451,612.661c-2.495-0.662-3.001-3.221-4.475-4.845
				c-0.496-0.546,0.639-0.684,1.094-0.629c1.493,0.178,2.965,0.406,4.486,0.419c3.885,0.034,4.852,1.379,3.975,5.134
				c-0.162,0.693-0.468,0.959-1.125,0.882c-1.019-0.119-2.037-0.255-3.055-0.384C299.43,614.018,299.443,613.334,299.451,612.661z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(675)} fill={props.fillColors[675]} d="M278.35,623.73c-0.074-0.029-0.203-0.098-0.215-0.08
				c-1.741,2.391-3.444,0.929-5.215,0.009c-3.659-1.902-7.61-2.969-11.61-3.852c-0.753-0.166-1.361-0.332-1.581-1.17
				c-0.094-0.358-0.527-0.687-0.202-1.067c0.271-0.316,0.667-0.264,1.09-0.179c5.712,1.138,11.232,2.887,16.562,5.219
				c0.447,0.196,1.399,0.174,1.123,1.171L278.35,623.73z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(676)} fill={props.fillColors[676]} d="M319.48,752.276c-0.754-3.465-0.504-6.222-0.486-8.953
				c0.018-2.781,2.388-4.513,3.374-6.88c0.119-0.285,0.436-0.518,0.769-0.352c0.379,0.188,0.55,0.564,0.516,0.996
				c-0.18,2.257,0.426,4.524-0.736,6.769C321.636,746.329,320.782,749.021,319.48,752.276z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(677)} fill={props.fillColors[677]} d="M452.372,406.848c1.14-0.801,2.28-1.601,3.407-2.395
				c1.16-0.483,2.324-0.991,3.504-1.459c0.709-0.281,1.451-1.548,2.176-0.345c0.628,1.043,1.306,2.594-0.065,3.455
				c-2.54,1.595-5.121,3.115-7.51,4.935c-0.595,0.454-0.961,0.029-1.039-0.398C452.615,409.39,451.621,408.219,452.372,406.848z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(678)} fill={props.fillColors[678]} d="M527.459,255.681c-0.643,2.515-1.206,5.053-1.951,7.536
				c-0.607,2.023-1.984,0.272-2.96,0.337c-0.594,0.04-2.05-0.073-1.544-1.696c0.537-1.72,0.818-3.519,1.232-5.277
				c0.486-2.062,3.131-3.013,4.88-1.792C527.472,255.037,527.748,255.239,527.459,255.681z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(679)} fill={props.fillColors[679]} d="M367.171,622.347c0.282-0.435,0.565-0.87,0.849-1.306
				c3.511-2.779,7.711-3.82,11.988-4.246c3.177-0.316,6.104,1.266,8.973,2.512c0.87,0.378,0.39,1.287,0.075,2.049
				c-0.559,1.348-0.969,0.104-1.321-0.062c-5.361-2.542-10.744-3.488-16.346-0.66c-1.213,0.612-2.354,1.345-3.037,2.394
				c-0.758,1.161-1.6,1.047-2.625,0.863l0.073,0.075C366.257,623.426,366.714,622.886,367.171,622.347z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(680)} fill={props.fillColors[680]} d="M472.77,563.978c-0.205,2.395-1.154,3.919-2.046,5.435
				c-0.827,1.408-1.081,3.074-0.495,4.338c0.72,1.551,0.193,2.196-0.815,3.112c-0.812,0.737-1.401,1.717-2.282,2.833
				c-0.599-1.564,1.333-3.597-1.563-3.421c-0.439,0.027-0.27-0.908,0.018-1.43c1.173-2.135,1.923-4.438,2.702-6.736
				c0.346-1.018,0.906-2.083,2.336-1.998C471.782,566.179,471.668,564.915,472.77,563.978z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(681)} fill={props.fillColors[681]} d="M47.775,308.452c-6.897-4.917-9.302-7.704-7.627-13.799
				C42.45,296.617,48.11,306.667,47.775,308.452z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(682)} fill={props.fillColors[682]} d="M345.522,572.358c0-7.16,5.651-8.77,10.106-11.557
				c0.711,1.854-0.566,2.794-1.363,4.071C352.111,568.322,348.36,569.689,345.522,572.358z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(683)} fill={props.fillColors[683]} d="M255.557,570.612c-0.23-0.033-0.622-0.001-0.666-0.109
				c-0.541-1.335,1.783-6.951,3.105-7.376c0.539-0.173,0.74-0.179,0.177-0.549c-1.478-0.971-0.178-1.927,0.278-2.438
				c0.554-0.621,1.295,0.145,1.874,0.64c2.253,1.929,1.539,6.646-1.182,7.786c-0.497,0.208-0.938,0.548-1.406,0.828
				C257.608,570.87,256.785,571.102,255.557,570.612z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(684)} fill={props.fillColors[684]} d="M515.034,314.725c-1.154,0.961-1.828,1.6-2.577,2.132
				c-2.36,1.676-5.575,1.578-7.805-0.193c-0.765-0.607-0.736-4.494,0.081-5.13c0.182-0.141,0.617-0.123,0.85-0.005
				C508.453,312.972,511.348,314.342,515.034,314.725z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(685)} fill={props.fillColors[685]} d="M128.473,602.613c2.622-1.124,4.725-1.686,7.045-0.955
				c2.157,0.679,4.476,0.679,6.587,1.635c1.036,0.469,1.875,0.891,1.584,2.193c-0.356,1.597-1.371,0.273-2.026,0.452
				C136.827,607.257,133.237,603.793,128.473,602.613z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(686)} fill={props.fillColors[686]} d="M382.772,574.687c-1.026-0.642-2.053-1.284-3.075-1.923
				c-1.435-1.447-1.075-2.799,0.272-4.043c1.047-0.966,2.118-1.911,3.24-2.786c0.461-0.36,0.99-1.565,1.616-0.536
				c0.402,0.66,1.255,1.795-0.077,2.68c-0.838,0.556-1.643,1.208-2.255,2.803c0.905-0.745,1.345-1.245,1.896-1.52
				c0.502-0.249,0.844-1.535,1.629-0.488c0.537,0.716,1.324,1.5,0.001,2.432c-0.94,0.663-1.866,1.508-2.179,2.749
				C383.734,574.686,383.332,574.818,382.772,574.687z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(687)} fill={props.fillColors[687]} d="M163.409,629.289c-0.256-0.238-0.513-0.477-0.76-0.71
				c-0.866-0.907-0.473-1.734,0.205-2.512c0.52-0.595,1.174-1.094,1.222-1.976c0,0,0.005,0.004,0.005,0.003
				c0.174,1.55,0.758,0.397,1.086,0.128c2.282-1.873,4.958-3.009,7.57-4.257c1.65,2.782,0.164,3.887-2.168,4.71
				c-1.381,0.487-2.929,0.87-3.541,2.503C165.814,627.869,164.795,628.894,163.409,629.289z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(688)} fill={props.fillColors[688]} d="M93.527,723.242c-0.844-1.315-1.609-2.585-3.385-2.227
				c-1.035,0.209-0.754-0.538-0.83-0.984c-0.859-5.023-2.006-10.003-2.324-15.108c-0.017-0.266,0.042-0.537,0.109-1.288
				C89.817,710.329,91.771,716.751,93.527,723.242z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(689)} fill={props.fillColors[689]} d="M381.495,544.118c-0.204,1.2,0.764,3.348,2.222,4.914
				c1.343,1.443,2.583,2.948,3.467,4.739c0.365,0.74,0.502,1.325-0.173,1.896c-0.702,0.592-1.279-0.093-1.673-0.385
				c-1.346-1.001-2.331-2.286-2.58-4.058c-0.105-0.75-0.306-1.78-0.826-2.113c-0.917-0.585-1.238,0.656-1.788,1.127
				c-0.78,0.669-1.292,0.995-1.474-0.427c-0.197-1.546-0.541-3.003-0.024-4.633c0.594-1.873,1.411-2.038,2.86-1.044
				C381.509,544.138,381.495,544.118,381.495,544.118z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(690)} fill={props.fillColors[690]} d="M260.867,620.57c3.744,0.298,6.951,1.568,10.146,2.866
				c0.735,0.299,1.542,0.56,2.127,1.059c0.924,0.788,2.711,0.337,2.961,1.989c0.174,1.151-1.873,1.307-1.337,2.791
				c0.139,0.384-0.458,0.471-0.881,0.259c-0.924-0.461-1.795-1.019-1.917-2.101c-0.015-0.134,0.643-0.483-0.02-0.801
				c-0.354-0.17-0.846-0.184-1.083-0.443c-1.071-1.171-1.316-3.556-3.996-1.405C265.541,625.849,261.858,623.071,260.867,620.57z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(691)} fill={props.fillColors[691]} d="M105.329,570.211c1.046-2.705,2.851-4.855,4.294-7.183
				c0.797-1.286,2.53-0.724,3.706-0.065c0.977,0.548,0.845,1.329-0.186,1.935c-0.763,0.448,0.129,0.731,0.221,1.096
				c0.646,2.565,0.046,3.422-2.53,3.491c-0.956,0.026-1.885,0.064-2.822,0.353C107.207,570.084,106.324,570.083,105.329,570.211z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(692)} fill={props.fillColors[692]} d="M469.877,388.081c2.398-0.669,4.127-2.564,6.423-3.472
				c1.261-0.498,1.469-0.272,1.609,0.943c0.236,2.039,0.477,3.902-2.281,4.43c-0.812,0.155-1.487,0.921-2.293,1.226
				c-1.07,0.406-2.278,2.078-3.003,1.263c-0.929-1.043-1.265-2.875-0.4-4.454L469.877,388.081z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(693)} fill={props.fillColors[693]} d="M521.752,254.09c-0.616,2.479-1.381,4.81-1.723,7.2
				c-0.224,1.565-0.848,1.619-2.032,1.273c-0.523-0.153-1.078-0.266-1.62-0.271c-1.326-0.009-1.391-0.688-1.049-1.714
				c0.594-1.78,1.208-3.557,1.691-5.368c0.399-1.498,0.997-2.256,2.646-1.437C520.196,254.037,520.891,253.972,521.752,254.09z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(694)} fill={props.fillColors[694]} d="M469.932,388.017c-1.907-2.205-1.758-4.021,0.471-5.483
				c1.71-1.122,3.428-2.231,5.159-3.318c1.477-0.928,1.823-0.303,1.941,1.229c0.15,1.956-0.396,3.052-2.314,3.914
				c-1.938,0.87-4.201,1.541-5.308,3.718C469.877,388.081,469.932,388.017,469.932,388.017z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(695)} fill={props.fillColors[695]} d="M515.546,414.097c1.224-0.12,1.203-2.38,2.818-1.615l5.016,2.219
				c1.026,1.104,2.054,2.208,3.127,3.361c-1.246,0.673-2.567,1.086-4.017,0.097c-0.741-0.506-1.606-0.591-2.507-0.612
				c-1.083-0.026-1.979-0.302-2.004-1.756C517.953,414.288,516.611,414.367,515.546,414.097z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(696)} fill={props.fillColors[696]} d="M167.723,706.445c2.07,5.093,3.602,9.669,4.956,14.308
				c0.282,0.966-1.558,3.533-2.458,3.536c-0.5,0.002-0.711-0.339-0.703-0.764c0.104-5.3-2.07-10.32-1.905-15.626
				C167.62,707.662,167.648,707.426,167.723,706.445z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(697)} fill={props.fillColors[697]} d="M489.632,538.446c-4.514,0.534-9.229-1.487-10.551-4.452
				c-0.276-0.619-0.722-1.575-0.468-1.931c0.482-0.673,1.406-0.337,2.193-0.116C483.994,532.838,487.716,535.505,489.632,538.446z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(698)} fill={props.fillColors[698]} d="M103.052,612.224c-0.395-1.018-1.305-0.738-2.054-0.777
				c-1.703-0.088-2.121-0.853-1.513-2.427c1.201-3.107,3.704-4.136,6.426-2.188c1.521,1.088,0.66,3.125,0.53,4.683
				c-0.103,1.234-1.248,0.812-2.087,0.501c-0.468-0.175-0.938-0.125-1.367,0.147C102.993,612.168,103.052,612.224,103.052,612.224z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(699)} fill={props.fillColors[699]} d="M306.295,754.866c1.122-3.473,2.248-6.942,3.364-10.417
				c1.487-4.631,1.261-9.497,1.942-14.238c0.347-2.404,0.731-4.821,2.79-6.587c-2.804,10.422-2.196,21.664-7.437,31.486
				C306.735,755.029,306.515,754.947,306.295,754.866z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(700)} fill={props.fillColors[700]} d="M382.772,574.687c0.356-0.211,0.712-0.422,1.068-0.633
				c2.078-2.155,4.503-3.848,7.108-5.291c0.726-0.401,2.001-1.11,2,0.156c-0.001,1.026,2.127,2.479-0.318,3.347
				c-2.375,0.843-4.629,1.984-6.537,3.662c-0.704,0.619-1.403,0.637-2.207,0.49C383.516,575.841,383.145,575.264,382.772,574.687z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(701)} fill={props.fillColors[701]} d="M478.827,393.241c0.508,0.807-0.2,1.026-0.714,1.334
				c-1.356,0.812-2.788,1.521-4.059,2.451c-1.312,0.959-2.403,0.276-2.967-0.6c-0.566-0.882-1.402-2.26,0.405-3.146
				c1.271-0.622,2.399-1.525,3.651-2.19C478.552,389.278,478.708,389.385,478.827,393.241z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(702)} fill={props.fillColors[702]} d="M468.319,382.723c-0.502-2.462-0.474-4.266,1.938-5.579
				c1.422-0.774,2.924-1.521,4.279-2.486c0.601-0.429,1.813-1.378,2.212-0.448c0.455,1.062,0.972,2.784-0.385,3.691
				C473.837,379.59,471.152,381.044,468.319,382.723z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(703)} fill={props.fillColors[703]} d="M145.843,735.092c1.196,4.728,0.847,9.214-0.847,13.638
				C140.758,745.126,142.141,739.908,145.843,735.092z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(704)} fill={props.fillColors[704]} d="M119.67,618.562c-2.209,1.705-4.837,2.131-7.071,3.341
				c-0.794,0.429-2.29-1.333-3.506-2.02c-0.609-0.345,0.618-1.276,1.147-1.735c0.941-0.815,1.209-2.883,2.582-2.522
				C115.122,616.23,117.463,617.027,119.67,618.562z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(705)} fill={props.fillColors[705]} d="M341.104,620.478c4.015-1.88,7.811-3.016,11.92-2.72
				c0.69,0.05,2.056-0.457,1.963,0.512c-0.11,1.146-0.442,2.825-2.159,2.921c-2.186,0.121-4.784-0.714-6.477,0.204
				C344.025,622.656,343.016,621.169,341.104,620.478z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(706)} fill={props.fillColors[706]} d="M579.598,342.351c2.516-2.745,4.729-5.111,6.88-7.531
				c1.054-1.184,2.427-0.861,3.564-0.624c1.173,0.246,2.349,0.259,4.153,0.546C589.392,337.729,585.279,340.872,579.598,342.351z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(707)} fill={props.fillColors[707]} d="M377.069,635.172c0.878,0.094,1.186,0.722,1.631,1.143
				c0.691,0.652,1.479,1.423,1.161,2.415c-0.291,0.904-1.354,0.553-2.095,0.746c-1.103,0.287-2.396,1.122-3.285,0.5
				c-1.722-1.206-3.539-2.61-4.564-4.37c-0.87-1.492,0.348-3.495,0.532-5.284c0.056-0.535,0.98,0.021,1.155,0.537
				c0.476,1.401,0.772,2.867,1.295,4.249c0.409,1.082,0.267,2.714,1.992,2.797C376.712,637.992,376.753,636.376,377.069,635.172z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(708)} fill={props.fillColors[708]} d="M142.223,683.173c1.451,0.329,1.637,1.745,2.217,2.788
				c0.872,1.563,0.987,2.91-1.255,3.409c-0.52,0.115-1.037,0.45-1.446,0.81c-1.037,0.911-1.577,0.28-1.988-0.604
				c-0.403-0.864-0.898-1.654-1.411-2.458c-1.758-2.75-1.549-3.138,1.717-3.576C140.76,683.447,141.5,683.615,142.223,683.173z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(709)} fill={props.fillColors[709]} d="M64.421,713.458c0.781-1.797,1.5-3.624,2.36-5.383
				c0.972-1.987,1.983-3.944,2.453-6.134c0.125-0.583,0.335-1.407,0.913-1.477c0.758-0.09,1.153,0.702,1.494,1.33
				c1.025,1.893,0.454,4.537-1.473,6.767c-1.618,1.872-3.254,3.728-4.882,5.591C64.997,713.921,64.709,713.689,64.421,713.458z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(710)} fill={props.fillColors[710]} d="M197.384,561.824c2.319,0.588,3.291,2.931,5.572,2.996
				c0.468,0.014,0.524,0.547,0.215,0.915c-2.189,2.602-4.038,5.441-5.997,8.37c0.312-2.025,0.212-3.984,0.211-5.942
				C197.383,566.104,197.384,564.045,197.384,561.824z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(711)} fill={props.fillColors[711]} d="M310.195,444.051c-0.467,2.096-1.113,3.664-2.946,4.726
				c-1.7,0.985-2.525,2.864-3.132,4.669c-0.666,1.978-1.849,3.68-2.626,5.583c-0.226,0.554-0.465,1.123-1.16,0.801
				c-0.661-0.305-0.485-1.106-0.251-1.464c0.557-0.853,1.389-1.519,1.997-2.344c0.782-1.061,1.913-2.198,0.104-3.359
				c-0.622-0.399-0.643-0.904-0.239-1.404C304.211,448.447,305.703,444.761,310.195,444.051z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(712)} fill={props.fillColors[712]} d="M455.564,414.814c-1.069,0.201-1.237-0.484-1.567-1.288
				c-0.406-0.988-0.347-1.591,0.586-2.17c2.071-1.286,4.078-2.675,6.163-3.936c0.592-0.358,1.658-1.229,1.991-0.246
				c0.368,1.083,1.557,2.49,0.021,3.658c-0.688,0.522-1.428,1.163-2.226,1.312c-1.656,0.31-3.465,0.144-3.871,2.433
				C456.637,414.717,455.946,414.738,455.564,414.814z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(713)} fill={props.fillColors[713]} d="M537.622,638.99c-0.07,0.685-0.3,0.995-1.077,0.622
				c-0.916-0.439-1.877-0.798-2.84-1.124c-3.649-1.237-3.691-1.245-2.087-4.627c0.334-0.706,0.178-2.125,1.359-1.934
				c1.189,0.193,2.616,0.725,3.133,1.866C536.845,635.416,537.137,637.237,537.622,638.99z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(714)} fill={props.fillColors[714]} d="M23.373,690.334c0.046,1.057,0.975,1.301,1.602,1.867
				c0.43,0.39,1.743,0.733,0.785,1.702c-0.597,0.604-1.135,1.777-2.465,0.58c-0.673-0.605-1.414-2.046-2.841-1.122
				c0.007,0.01,0.037,0.051,0.042,0.058c-0.167-0.985-0.917-1.043-1.64-0.87c-1.253,0.302-0.758,1.57-1.052,2.393
				c-0.916-0.129-1.375-0.473-1.33-1.375c0.053-1.034-0.049-2.079,0.025-3.111c0.071-0.989-0.543-2.383,0.365-2.865
				c0.845-0.45,1.722,0.814,2.655,1.204c1.062,0.443,1.963,1.264,2.958,1.882c0.367,0.228,0.901,0.613,0.893-0.33
				C23.37,690.341,23.373,690.334,23.373,690.334z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(715)} fill={props.fillColors[715]} d="M158.148,692.98c0.058,0.966-0.675,1.229-1.318,1.516
				c-0.679,0.304-1.436,0.492-1.871-0.354c-1.125-2.188-2.388-4.286-3.877-6.247c-0.376-0.496-0.275-0.85,0.326-1.073
				c0.663-0.246,1.293-0.623,1.974-0.771c1.239-0.27,2.482-0.951,2.706,1.44C156.264,689.379,157.432,691.175,158.148,692.98z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(716)} fill={props.fillColors[716]} d="M477.303,272.172c-0.899,0.217-1.828-0.183-2.651-0.547
				c-1.128-0.5-2.867-0.696-3.022-2.074c-0.153-1.372,3.364-3.153,4.998-2.794c0.076,0.017,0.148,0.054,0.222,0.081
				c1.063,0.381,2.603-0.646,3.215,0.664c0.5,1.071-0.678,2.21-0.728,3.347C479.271,272.349,478.359,272.209,477.303,272.172z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(717)} fill={props.fillColors[717]} d="M422.949,632.191c0.542,0.655,1.069,1.322,1.628,1.963
				c1.825,2.095,2.187,4.348,0.778,6.81c-0.001,0.002,0.077-0.082,0.076-0.081c-4.704-1.419-5.503-3.125-4.248-9.188
				c0.62,0.195,1.235,0.389,1.851,0.582L422.949,632.191z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(718)} fill={props.fillColors[718]} d="M61.81,692.504c0.277,0.113,0.552,0.314,0.832,0.321
				c0.934,0.02,2.44-1.159,2.425,0.863c-0.01,1.349,0.609,3.129-1.948,3.576c-2.114,0.37-4.034,1.691-5.988-0.357
				c-0.441-0.463-1.024-0.693-0.89-1.478c0.125-0.734,0.599-0.992,1.25-1.25c1.452-0.577,3.206-0.393,4.376-1.709
				C61.876,692.465,61.81,692.504,61.81,692.504z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(719)} fill={props.fillColors[719]} d="M156.515,616.195c-1.431,0.47-2.449,1.619-3.062,2.774
				c-0.932,1.761-1.892,1.603-3.376,0.927c-3.767-1.716-3.837-1.944-0.971-4.902c0.656-0.677,1.253-1.411,1.88-2.121
				c0.002-0.002-0.079,0.088-0.076,0.085c0.532-0.024,1.212-0.284,1.381,0.491c0.507,2.334,1.99,3.101,4.228,2.762
				C156.526,616.218,156.515,616.195,156.515,616.195z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(720)} fill={props.fillColors[720]} d="M181.982,716.356c1.267-2.844,2.55-5.68,3.79-8.534
				c0.376-0.866,0.715-1.767,0.917-2.686c0.156-0.71,0.235-1.698,0.911-1.747c0.973-0.071,1.421,0.935,1.658,1.781
				c0.744,2.66-0.308,4.936-1.977,6.821c-1.485,1.68-3.337,3.035-5.027,4.533C182.162,716.469,182.072,716.413,181.982,716.356z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(721)} fill={props.fillColors[721]} d="M468.457,356.945c-2.292,0-4.107-0.049-5.919,0.016
				c-1.47,0.052-1.557-0.791-1.336-1.858c0.458-2.222,0.95-4.437,1.495-6.965C464.886,350.875,466.557,353.701,468.457,356.945z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(722)} fill={props.fillColors[722]} d="M397.561,560.699c-1.29-0.418-2.825-0.42-4.075-1.374
				c-0.686-0.523-1.38-1.084-2.159-1.425c-1.597-0.699-3.899-0.635-3.036-3.533c0.152-0.51-0.541-1.242-0.765-1.899
				c-0.244-0.719-1.705-1.34-0.501-2.184c1.618-1.134,1.823,0.655,2.157,1.457c1.154,2.768,3.037,4.749,5.763,5.937
				c1.079,0.471,2.23,0.781,3.285,1.295c0.445,0.217,0.979,0.697,0.725,1.343C398.73,560.886,398.124,560.566,397.561,560.699z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(723)} fill={props.fillColors[723]} d="M416.815,632.935c0.348,2.108-0.282,2.373-2.118,1.456
				c-4.027-2.009-4.354-2.824-2.583-7.086c0.409-0.985,0.799-0.858,1.592-0.598C416.904,627.759,416.551,630.514,416.815,632.935z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(724)} fill={props.fillColors[724]} d="M351.786,591.042c-1.291-1.153-2.582-2.307-3.874-3.46
				c-2.15-1.11-3.884-2.869-6.084-3.899l0.079,0.084c-0.005-0.653-0.502-0.684-0.967-0.808c-0.684-0.183-0.899-0.562-0.458-1.221
				c0.804-1.198,4.092-1.62,5.124-0.657c0.233,0.218,0.513,0.364,0.457,0.742c-0.267,1.81,0.138,2.251,2.563,2.798l-0.085-0.076
				c0.916,2.104,3.122,3.242,4.023,5.351C352.873,590.612,353.741,591.815,351.786,591.042z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(725)} fill={props.fillColors[725]} d="M532.806,585.482c1.591,0.165,2.879,0.907,3.949,2.067
				c0.339,2-0.484,2.979-2.024,2.186c-3.997-2.062-8.023-3.899-12.705-3.915C525.59,584.116,529.177,584.275,532.806,585.482z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(726)} fill={props.fillColors[726]} d="M66.918,679.168c1.733,0.095,3.388-0.317,5.06-0.697
				c0.625-0.142,1.577-0.331,1.851,0.567c0.381,1.251,0.703,2.558,0.755,3.853c0.021,0.521-5.634,0.633-6.248,0.085
				c-0.473-0.421-0.85-0.96-1.352-1.336c-1.175-0.881-1.402-1.696,0.006-2.547C66.993,679.09,66.918,679.168,66.918,679.168z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(727)} fill={props.fillColors[727]} d="M275.114,311.377c-2.312,0.945-4.523,0.994-6.665,1.386
				c-0.771,0.141-0.995-0.257-1.218-0.789c-0.307-0.729-0.633-1.451-0.892-2.197c-0.336-0.972-1.617-2.186-0.4-2.909
				c1.005-0.598,2.542-0.566,3.771,0.474C271.328,308.712,273.104,309.893,275.114,311.377z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(728)} fill={props.fillColors[728]} d="M387.004,579.96c0.611-0.457,1.222-0.914,1.833-1.371
				c3.151-1.401,6.213-3.03,9.613-3.799c0.382-0.086,0.762-0.215,1.147-0.236c0.719-0.04,1.585-0.059,1.709,0.841
				c0.124,0.897-0.919,0.807-1.388,0.964c-1.472,0.497-3.092,0.605-4.149,1.966c-2.361,0.794-4.54,1.901-6.336,3.663
				c-0.796,0.296-1.417-0.11-2.036-0.519C387.266,580.966,387.135,580.463,387.004,579.96z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(729)} fill={props.fillColors[729]} d="M237.093,733.043c-0.53,0.398-1.15,0.622-1.208,0.946
				c-0.714,3.989-3.787,2.567-5.877,2.25c-2.769-0.42-5.452-0.281-8.232-0.4C226.501,733.305,231.614,732.718,237.093,733.043z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(730)} fill={props.fillColors[730]} d="M390.625,728.14c1.57-4.363,3.906-8.336,4.935-12.837
				c0.241-1.058,0.881-0.604,1.384-0.141c1.318,1.213,1.625,2.754,1.027,4.345c-0.75,1.995-1.769,3.838-3.551,5.198
				C393.069,725.737,391.885,726.987,390.625,728.14z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(731)} fill={props.fillColors[731]} d="M319.812,571.236c-1.527-0.5-1.841-2.003-0.72-3.303
				c0.124-0.144,0.947,0.182,0.363-0.508c-1.543-1.819-1.386-2.471,0.877-3.883c0.247-0.392,0.584-0.477,0.999-0.292
				c1.674-0.42,2.922-0.04,2.255,1.927c-0.361,1.064-0.5,1.789,0.014,2.819c0.342,0.686,0.058,1.728-0.949,1.826
				c-0.995,0.099-1.415,0.906-2.15,1.294C320.343,571.573,320.11,571.596,319.812,571.236z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(732)} fill={props.fillColors[732]} d="M251.337,551.996c1.985,1.59,3.787,3.002,5.546,4.467
				c0.39,0.324,1.444,0.37,0.795,1.329c-0.453,0.669-0.369,1.771-1.496,1.905c-0.394,0.048-0.803,0.019-1.186,0.107
				c-3.798,0.88-3.85,0.854-3.97-3.13c-0.038-1.272,0.004-2.546,0.033-3.818C251.064,552.646,251.189,552.438,251.337,551.996z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(733)} fill={props.fillColors[733]} d="M380.146,622.148c1.885,0.44,3.976,0.495,5.759,1.65
				c0.453,0.294,1.21,0.463,0.872,1.122c-0.296,0.576-0.767,1.241-1.598,1.234c-0.219-0.002-0.441-0.168-0.654-0.272
				c-3.403-1.666-6.778-1.698-10.245-0.081c-0.461,0.215-1.625,1.28-2.115-0.139c-0.42-1.22,0.333-1.774,1.441-2.229
				C375.691,622.579,377.917,622.559,380.146,622.148z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(734)} fill={props.fillColors[734]} d="M411.387,613.902c-0.617,0.056-1.239,0.078-1.851,0.174
				c-1.674,0.262-1.673,0.273-1.345-1.465c0.014-0.071,0.142-0.163,0.125-0.191c-2.743-4.575,2.018-3.317,3.569-4.393
				c0.724-0.501,2.336-1.486,2.96-0.106c0.83,1.836-1.019,2.088-2.242,2.528c-0.432,0.155-0.889,0.239-1.334,0.503
				c1.024-0.077,2.049-0.155,3.085-0.233c0.419,2.31-2.024,1.925-2.717,3.118c0.291-0.134-0.056-0.35,0.18-0.133
				c0.083,0.077,0.069,0.19-0.058,0.292C411.635,614.095,411.501,614.051,411.387,613.902z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(735)} fill={props.fillColors[735]} d="M442.338,188.735c0.733,0.578,0.797,1.401,0.821,2.226
				c-2.97,1.259-5.835,3.486-9.226,1.017c0.598-0.785,1.562-0.647,2.355-0.94c0.643-0.236,1.566-0.328,1.305-1.311
				c-0.209-0.781-0.815-1.356-1.747-1.318c-0.874,0.036-1.522,0.358-1.623,1.351c-0.077,0.763,0.318,1.569-0.206,2.291
				c-2.109-0.572-3.062-2.711-2.259-5.071c0.672-1.974,2.119-2.5,4.297-1.348C438.123,186.726,440.117,187.956,442.338,188.735z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(736)} fill={props.fillColors[736]} d="M461.558,338.304c-0.214,2.288-0.867,2.677-2.755,1.598
				c-1.715-0.979-3.335-2.105-4.906-3.333c-1.622-1.269-0.846-2.261-0.022-3.309c1.07-1.363,1.545,0.133,2.237,0.56
				c1.412,0.871,2.624,2.06,4.215,2.64C461.282,336.808,461.753,337.438,461.558,338.304z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(737)} fill={props.fillColors[737]} d="M255.557,570.612c0.727-0.406,1.453-0.812,2.18-1.219
				c0.391-0.065,0.781-0.182,1.172-0.185c0.773-0.006,1.662-1.567,2.235-0.14c0.457,1.141,0.104,2.567-1.175,3.178
				c-1.666,0.795-3.092,1.798-4.391,3.122c-1.281,1.307-2.9,0.787-3.823-1.012c-0.115-0.285,0.013-0.459,0.263-0.576
				C253.231,572.762,254.76,572.096,255.557,570.612z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(738)} fill={props.fillColors[738]} d="M73.229,715.539c-0.2-0.124-0.383-0.292-0.597-0.359
				c-3.198-1.008-2.278-3.18-1.516-5.312c0.361-1.008,3.148-5.71,3.823-6.439C75.514,705.687,74.482,713.055,73.229,715.539z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(739)} fill={props.fillColors[739]} d="M379.079,619.632c3.073-0.373,5.834,1.062,8.574,2.454
				c0.431,0.219,1.423,0.67,0.886,1.568c-0.529,0.888-1.174,0.118-1.623-0.127c-4.725-2.578-9.474-2.402-14.247-0.209
				c-0.557,0.256-0.857,0.287-1.033-0.354c-0.205-0.751-0.499-1.58,0.441-1.999c1.415-0.631,2.824-1.338,4.437-1.335
				C377.312,619.633,378.109,619.632,379.079,619.632z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(740)} fill={props.fillColors[740]} d="M457.859,327.946c1.692,1.066,3.727,1.815,4.846,3.268
				c0.588,0.763-0.39,2.768-0.73,4.195c-0.244,1.023-0.861,0.321-1.265,0.149c-1.827-0.78-3.25-2.169-4.828-3.321
				c-0.3-0.219-0.88-0.481-0.406-1.096C456.276,330.103,456.771,328.83,457.859,327.946z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(741)} fill={props.fillColors[741]} d="M306.452,608.671c2.148,0.312,4.064,0.89,5.958,1.643
				c0.991,0.395,1.229,0.799,0.734,1.731c-0.289,0.548-0.293,1.307-0.687,1.728c-0.677,0.726,0.181,2.622-1.262,2.352
				c-1.528-0.287-3.441-0.676-3.812-2.842C307.126,611.774,306.781,610.281,306.452,608.671z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(742)} fill={props.fillColors[742]} d="M27.212,722.371c-1.017-5.47-2.096-10.877-4.452-16.629
				c3.161,2.203,5.458,4.584,5.544,7.567C28.387,716.228,28.025,719.324,27.212,722.371z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(743)} fill={props.fillColors[743]} d="M458.021,397.501c-2.372,0.332-3.836,2.378-6.188,3.246
				c1.084-3.319,1.629-6.782,5.498-7.993c0.63-0.197,1.433-1.268,1.962,0.006c0.478,1.144,1.235,2.438-0.045,3.56
				c-0.461,0.403-1.171,0.527-1.312,1.267C457.935,397.588,458.021,397.501,458.021,397.501z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(744)} fill={props.fillColors[744]} d="M524.265,629.072c0.508,1.958,1.032,3.912,1.518,5.876
				c0.305,1.235-0.18,1.989-1.448,1.479c-1.49-0.599-3.603,0.436-4.444-0.985c-0.686-1.158,0.852-2.672,0.93-4.114
				c0.131-2.411,1.704-2.426,3.454-2.258c-0.075-0.127-0.112-0.19-0.112-0.19C524.121,628.813,524.156,628.878,524.265,629.072z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(745)} fill={props.fillColors[745]} d="M309.668,725.649c2.101-3.951,3.802-8.064,5.164-12.312
				c0.117-0.367,0.094-0.85,0.615-0.867c0.736-0.024,1.141,0.52,1.464,1.067c1.471,2.489,0.32,4.686-1.126,6.562
				c-1.658,2.15-3.704,4.001-5.582,5.981C310.024,725.937,309.846,725.793,309.668,725.649z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(746)} fill={props.fillColors[746]} d="M563.619,268.693c-0.018-2.038,1.057-3.2,2.923-3.198
				c1.754,0.002,2.93,0.875,3.179,2.551c0.183,1.225-2.211,4.133-3.103,4.132C564.961,272.177,563.636,270.636,563.619,268.693z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(747)} fill={props.fillColors[747]} d="M228.229,582.15c-0.021,0.194-0.042,0.39-0.063,0.584
				c-0.67,0.495-1.34,0.991-2.011,1.487l0.075,0.029c-1.451-2.439-3.276-4.503-5.834-5.834l0.065,0.064
				c-0.282-1.65-1.991-1.644-2.971-2.483c-0.846-0.725-1.447-1.485-0.668-2.31c0.938-0.994,1.233,0.447,1.729,0.845
				c1.715,1.373,2.94,3.717,5.816,2.53c0.339-0.14,0.963,0.413,1.455,0.644c1.122-0.419,1.569,0.407,2.075,1.124
				C228.901,579.848,229.005,580.955,228.229,582.15z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(748)} fill={props.fillColors[748]} d="M486.594,371.325c2.423-0.365,4.208-2.221,6.536-2.797
				c0.667-0.166,1.694-1.021,1.888,0.276c0.142,0.952,0.795,2.48-0.548,2.982c-2.595,0.97-4.957,2.402-7.66,3.664
				c0.225-1.542-0.566-2.782-0.211-4.129L486.594,371.325z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(749)} fill={props.fillColors[749]} d="M38.465,567.874c3.619,1.726,3.597,1.72,2.435,6.209
				c-0.527,2.037-0.945,4.103-1.827,6.154c-0.511-3.788-1.034-7.574-1.521-11.366c-0.085-0.664,0.245-1.067,0.993-0.938
				L38.465,567.874z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(750)} fill={props.fillColors[750]} d="M545.845,224.979c3.094,1.875,5.36,4.043,7.755,6.042
				c1.032,0.863,0.126,1.127-0.514,1.409c-3.308,1.458-6.932-0.729-7.23-4.384C545.784,227.179,545.845,226.301,545.845,224.979z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(751)} fill={props.fillColors[751]} d="M572.604,569.731c0.414,0.575,0.83,1.148,1.241,1.726
				c1.872,2.628,1.126,4.918-1.787,5.64c-1.049,0.26-2.091,0.497-2.552-0.739c-0.391-1.044-0.504-2.29,0.347-3.151
				c0.876-0.886,1.166,1.026,2.224,0.583c-1.021-1.23-2.952-2.024-1.854-4.201c0.425-0.842,0.793-1.071,1.601-0.82
				C572.329,568.89,572.598,569.204,572.604,569.731z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(752)} fill={props.fillColors[752]} d="M49.173,744.71c-1.777,4.908-3.555,9.815-5.402,14.916
				c-1.628-1.708-2.08-3.209-0.903-5.217c1.148-1.96,1.987-4.109,2.875-6.212C46.415,746.603,47.513,745.426,49.173,744.71z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(753)} fill={props.fillColors[753]} d="M567.396,578.701c-0.267,0.075-0.532,0.151-0.799,0.227
				c-0.502-0.478-1.006-0.955-1.508-1.433c-1.925-1.073-4.104-1.402-6.198-1.801c-1.52-0.29-1.213-1.109-1.264-2.018
				c-0.075-1.343,1.039-1.238,1.641-1.042c2.417,0.789,5.723-0.18,6.838,3.339c0.203,0.639,0.954,0.809,1.564,0.886
				c0.538,0.069,1.304-0.663,1.545,0.335c0.242,1.001-0.921,0.603-1.273,1.064C567.803,578.44,567.579,578.556,567.396,578.701z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(754)} fill={props.fillColors[754]} d="M494.901,565.643c-0.694-3.256-1.376-6.45-2.124-9.964
				c2.631,1.22,4.861,2.254,7.104,3.294c-0.45,0.978-1.101,0.632-1.593,0.76c-0.565,0.146-1.394-0.393-1.673,0.421
				c-0.292,0.849,0.503,1.258,1.052,1.688c0.307,0.239,0.69,0.39,1.057,0.54c0.618,0.255,1.199,0.615,1.271,1.293
				c0.029,0.271-0.393,0.71-0.712,0.88c-0.957,0.508-0.9-0.6-1.326-0.94c-0.558-0.447-1.108-1.109-1.835-0.821
				c-0.668,0.266-0.513,1.086-0.542,1.72C495.564,564.88,495.683,565.316,494.901,565.643z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(755)} fill={props.fillColors[755]} d="M368.888,740.558c-0.206,1.105,0.421,2.374-0.385,2.896
				c-1.304,0.843-2.876,1.275-4.351,1.843c-0.15,0.058-0.577-0.139-0.583-0.236c-0.099-1.56-0.955-3.049-0.551-4.637
				c0.176-0.689,4.623-2.755,5.279-2.414C369.5,738.635,368.398,739.87,368.888,740.558z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(756)} fill={props.fillColors[756]} d="M486.599,371.32c-0.652-3.421-0.48-3.861,2.621-5.402
				c1.827-0.907,3.527-2.155,5.727-2.401c0.435,2.113,0.465,3.612-2.243,4.456c-2.145,0.668-4.627,1.248-6.109,3.351
				C486.594,371.325,486.599,371.32,486.599,371.32z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(757)} fill={props.fillColors[757]} d="M288.374,734.301c-1.936-2.006-4.75-2.699-5.622-5.552
				c-0.261-0.853-0.836-1.726-0.271-2.396c0.507-0.602,1.291,0.157,1.892,0.437c0.934,0.435,1.853,0.926,2.71,1.495
				C290.431,730.509,290.421,730.523,288.374,734.301z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(758)} fill={props.fillColors[758]} d="M482.915,612.376c0,0-0.029,0.078-0.029,0.074c0.165,1.589,2.532,3.011,0.304,4.75
				c-2.021-1.204-3.603-3.191-5.967-4.013c0.047,0.145,0.094,0.29,0.141,0.435c-0.038-0.147-0.076-0.295-0.114-0.443
				c-0.313-1.839-0.628-3.678-0.942-5.517l-0.048,0.09c0.341-0.333,0.682-0.329,1.022,0l-0.094-0.067
				c1.01,0.936,2.223,1.468,3.544,1.785C481.81,610.176,482.63,611.074,482.915,612.376z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(759)} fill={props.fillColors[759]} d="M385.95,532.291c3.889,3.569,6.938,6.899,7.587,12.331
				C388.677,541.876,387.112,537.601,385.95,532.291z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(760)} fill={props.fillColors[760]} d="M392.391,628.442c-0.229-1.122-2.152-0.159-2.088-1.792
				c0.073-1.898-0.102-3.831,0.618-5.624c0.313-0.781,2.42-0.46,2.695,0.43c0.532,1.721,1.057,3.467,1.331,5.241
				c0.356,2.298-1.718,1.164-2.637,1.679C392.313,628.377,392.391,628.442,392.391,628.442z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(761)} fill={props.fillColors[761]} d="M228.08,749.989c-0.636,2.657-1.974,4.07-4.725,3.634
				c-0.529-0.084-1.108,0.046-1.648,0.155c-1.854,0.376-3.163-0.955-2.807-2.847c0.093-0.494,0.376-0.711,0.801-0.67
				C222.466,750.528,225.171,749.185,228.08,749.989z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(762)} fill={props.fillColors[762]} d="M467.627,376.207c-0.199-1.208,0.382-2.024,1.381-2.53
				c1.977-1.001,3.832-2.186,5.663-3.428c0.595-0.403,1.57-1.231,2.026-0.284s0.838,2.485-0.576,3.13
				c-2.615,1.193-5.079,2.589-7.327,4.376c-0.216,0.171-0.646,0.337-0.825,0.236C467.367,377.367,467.721,376.715,467.627,376.207z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(763)} fill={props.fillColors[763]} d="M216.666,568.58c-4.652-0.974-5.802,2.452-7.483,5.418
				c-0.228-2.16-0.425-4.323-0.693-6.479c-0.249-1.996,1.007-2.205,2.357-1.809c1.942,0.571,3.802,1.424,5.698,2.157
				C216.584,568.105,216.625,568.343,216.666,568.58z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(764)} fill={props.fillColors[764]} d="M494.778,203.953c-0.081-0.037-0.16-0.076-0.237-0.12
				c-1.188-2.089-3.046-3.161-5.369-3.504c-1.187-0.175-2.275-0.619-3.346-1.128l0.069,0.094c-0.13-0.435-0.259-0.869-0.487-1.635
				c4.841,1.565,9.507,3.052,14.152,4.597c0.674,0.224,1.022,0.795,0.373,1.472c-0.948,0.989-1.691,2.525-3.479,1.517
				C495.847,204.902,495.334,204.388,494.778,203.953z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(765)} fill={props.fillColors[765]} d="M344.543,756.251c2.726-0.499,5.453-0.986,8.175-1.505
				c0.661-0.126,1.437-0.705,1.637,0.501c0.135,0.81,0.174,1.703-0.837,1.977c-2.817,0.764-5.646,1.487-8.486,2.161
				c-1.014,0.24-0.975-0.819-1.377-1.335c-1.021-1.311,0.779-1.164,0.944-1.857L344.543,756.251z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(766)} fill={props.fillColors[766]} d="M249.677,766.128c-2.954-3.86-2.593-7.405-0.25-10.979
				c0.817,0.482,0.877,1.472,1.425,2.124c1.176,1.401,1.653,2.781,0.278,4.404C250.154,762.83,250.195,764.445,249.677,766.128z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(767)} fill={props.fillColors[767]} d="M396.446,545.792c-2.497-0.607-2.021-2.917-2.765-4.394
				c-0.415-0.823-1.393-2.003-0.484-2.952c0.915-0.957,2.085-0.037,3.125,0.177c2.221,0.457,3.922,1.756,5.019,3.668
				c0.349,0.608,1.229,1.701,0.28,2.343c-0.921,0.623-1.571-0.452-2.087-1.091c-0.644-0.797-1.085-1.791-1.987-2.331
				c-0.568-0.34-1.201-0.968-1.953-0.418c-0.876,0.643-0.357,1.366-0.076,2.116C395.839,543.772,396.666,544.457,396.446,545.792z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(768)} fill={props.fillColors[768]} d="M22.502,601.007c0.691-2.005,1.375-3.696,1.836-5.446
				c0.254-0.967,0.462-1.597,1.644-1.515c1.039,0.072,1.708,0.259,2.099,1.442c1.486,4.496,1.543,4.482-2.926,5.202
				C24.398,600.812,23.631,600.874,22.502,601.007z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(769)} fill={props.fillColors[769]} d="M56.309,572.656c0.286,0.221,0.602,0.444,0.895,0.694
				c2.402,2.048,4.049,4.589,4.948,7.616c0.084,0.285,0.372,0.568,0.067,0.846c-0.345,0.313-0.813,0.256-1.095-0.011
				c-2.233-2.11-4.294-4.407-6.886-6.118c-0.624-0.411-0.028-0.921,0.147-1.217C54.817,573.739,55.341,573.023,56.309,572.656z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(770)} fill={props.fillColors[770]} d="M54.402,571.884c-2.369,2.098-3.489,5.119-6.133,7.404
				c0-2.534-0.045-5.071,0.029-7.604c0.021-0.718,0.652-0.59,1.255-0.547C51.142,571.247,52.754,571.068,54.402,571.884z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(771)} fill={props.fillColors[771]} d="M54.935,563.274c-0.092,1.565-1.319,1.644-2.438,1.858
				c-1.008,0.484-2.775,0.296-2.693,1.821c0.074,1.387,1.796,1.26,2.829,1.656c0.705,0.271,1.561,0.152,2.35,0.192
				c0.64,0.031,1.449,0.064,1.427,0.833c-0.017,0.562-0.578,1.066-1.323,1.17c-2.014,0.278-3.917-0.494-5.895-0.571
				c-0.627-0.023-0.569-0.696-0.527-1.146c0.147-1.571,0.311-3.143,0.535-4.704c0.111-0.773,0.757-0.954,1.439-0.978
				C52.07,563.358,53.502,563.318,54.935,563.274z"/>
			<path fill-rule="evenodd" d="M434.091,455.523c-0.566,0.007-1.1-0.018-1.352-0.677c-0.442-1.162,0.636-5.738,1.571-6.512
				c0.505-0.416,1.503-0.834,1.527-0.06c0.115,3.685,2.202,0.833,3.312,1.35c0-0.393,0.058-0.578-0.009-0.687
				c-0.418-0.675-1.366-1.185-0.786-2.166c0.22-0.371,0.638-0.024,0.735,0.204c0.455,1.065,2.158,1.68,1.282,3.214
				c-0.427,0.745-1.052,1.377-1.588,2.062c-0.001,0-0.153-0.037-0.153-0.037l-0.158,0.005c-0.652-0.552-1.359-0.271-1.539,0.315
				c-0.477,1.564-1.957,2.046-2.935,3.058L434.091,455.523z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(772)} fill={props.fillColors[772]} d="M466.218,208.829c0.338,1.211,0.736,2.41,0.986,3.639
				c0.122,0.599-0.036,1.608-0.933,0.819c-3.289-2.898-6.465-5.924-10.149-9.333c4.355,0.764,7.334,2.723,10.179,4.955
				C466.303,208.91,466.218,208.829,466.218,208.829z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(773)} fill={props.fillColors[773]} d="M383.546,555.44c2.656,1.19,4.969,2.229,7.284,3.265
				c0.757,0.338,1.087,0.722,0.638,1.636c-0.512,1.043-0.785,2.205-1.387,3.979C388.303,560.79,384.328,559.638,383.546,555.44z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(774)} fill={props.fillColors[774]} d="M432.643,563.535c0.754,0.601,1.54,1.18,2.548,1.176
				c0.908-0.003,1.148,0.267,1.126,1.244c-0.016,0.736,0.303,1.874,1.642,1.237c0.49-0.233,0.86,0,0.728,0.484
				c-0.565,2.073-0.316,4.39-1.986,6.502c-1.496-3.662-3.3-6.959-3.982-10.704L432.643,563.535z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(775)} fill={props.fillColors[775]} d="M247.968,622.055c1.807,0.018,2.938,1.385,2.887,4.071
				c-0.04,2.083-1.91,1.251-2.927,1.208c-1.065-0.044-3.002,0.956-3.005-1.421C244.919,622.756,245.535,622.031,247.968,622.055z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(776)} fill={props.fillColors[776]} d="M298.436,576.162c-1.576-1.927-3.152-3.854-4.729-5.779
				c-0.272-0.332-0.469-0.712,0.016-0.972c1.463-0.783,3.076-1.137,4.697-1.342c0.488-0.062,0.598,0.634,0.598,1.127
				c0,2.267,0,4.533,0,6.799C298.823,576.051,298.63,576.106,298.436,576.162z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(777)} fill={props.fillColors[777]} d="M320.583,609.775c1.216,1.842,3.041,3.095,4.525,4.678
				c-0.042,1.304,1.341,1.91,1.552,3.266c-1.608,0.378-2.973,0.486-4.007-1.275c-0.471-0.804-1.169-1.795-2.466-1.511l0.063,0.061
				c-0.757-1.388-1.917-2.651-0.917-4.46C319.663,609.938,319.952,609.682,320.583,609.775z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(778)} fill={props.fillColors[778]} d="M297.666,621.701c-0.636,0-1.139-0.102-1.583,0.017
				c-2.62,0.694-2.294-1.55-2.587-2.802c-0.39-1.664,1.221-0.911,1.952-1.194c1.5-0.584,3.144-1.019,4.306,0.43
				c0.687,0.856,1.772,2.221,1.288,3.069C300.388,622.362,298.71,621.432,297.666,621.701z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(779)} fill={props.fillColors[779]} d="M518.121,209.507c1.959,0.127,3.328,1.391,5.016,1.832
				c0.763,0.199,1.17,0.843,0.867,1.69c-0.401,1.129-0.14,2.736-1.857,2.978c-1.143,0.161-2.262-0.152-2.926-1.171
				C518.194,213.262,517.675,211.532,518.121,209.507z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(780)} fill={props.fillColors[780]} d="M75.266,718.353c1.151-1.747-0.063-4.431,2.402-5.746
				c1.397-0.746,2.008-0.39,2.195,1.093c0.176,1.392,0.193,2.825,0.257,4.195c0.089,1.888-2.198,2.069-3.521,2.54
				C75.502,720.824,75.508,719.297,75.266,718.353z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(781)} fill={props.fillColors[781]} d="M564.677,346.231c2.404,1.863,4.486,0.356,7.236-0.783
				c-1.638,3.466-3.628,5.815-6.333,7.59c-0.789,0.518-1.195-0.109-0.953-0.639C565.556,350.375,564.291,348.375,564.677,346.231z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(782)} fill={props.fillColors[782]} d="M143.816,701.037c-0.197-3.405,0.107-6.818-2.472-9.469
				c0.101-0.135,0.165-0.299,0.282-0.361c3.988-2.136,3.99-2.135,3.985,2.511c-0.001,1.677,0.012,3.354-0.024,5.03
				C145.564,699.825,145.319,700.797,143.816,701.037z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(783)} fill={props.fillColors[783]} d="M160.506,718.929c-0.281,0.909,0.521,2.626-0.521,2.646
				c-1.251,0.023-2.539-1.089-3.779-1.769c-0.312-0.171-0.066-0.54-0.004-0.845c0.268-1.301,0.485-2.613,0.713-3.922
				c0.227-1.303,1.257-1.981,2.316-2.026c1.408-0.061,0.663,1.27,0.788,1.976C160.25,716.286,160.778,717.559,160.506,718.929z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(784)} fill={props.fillColors[784]} d="M92.384,568.06c-0.31-0.604-0.833-0.949-1.452-1.151
				c-1.52-0.498-2.05-1.403-1.227-2.887c0.469-0.844,0.149-1.737,0.054-2.566c-0.257-2.221,1.012-1.596,2.087-1.17
				c0.683,0.271,1.179,0.892,1.126,1.681c-0.145,2.16,1.355,3.863,1.586,5.917c0.05,0.446,0.438,0.971-0.235,1.132
				c-0.268,0.063-0.649-0.345-0.978-0.539C92.854,568.729,92.408,568.881,92.384,568.06z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(785)} fill={props.fillColors[785]} d="M268.691,626.845c2.204,1.21,2.568,3.766,4.054,5.412
				c0.839,0.931,0.646,1.149,0.014,1.869c-0.852,0.97-1.598,1.268-2.493,0.126c-0.049-0.062-0.114-0.111-0.175-0.162
				C266.59,631.16,266.59,631.16,268.691,626.845z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(786)} fill={props.fillColors[786]} d="M42.057,683.324c-5.055,3.102-5.055,3.102-10.883-1.637
				C35.238,681.654,38.819,680.921,42.057,683.324z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(787)} fill={props.fillColors[787]} d="M249.584,564.748c-1.521-1.332-3.381-0.349-5.036-0.743
				c-0.664-0.157-1.991,0.328-1.777-0.817c0.208-1.116-0.191-2.944,1.823-3.103c0.711-0.057,1.431-0.03,2.146-0.021
				c3.357,0.039,4.179,1.421,2.778,4.651C249.529,564.723,249.584,564.748,249.584,564.748z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(788)} fill={props.fillColors[788]} d="M112.16,690.689c2.468,0.046,4.822,0.401,7.119,1.158
				c0.663,0.219,1.183,0.548,0.655,1.356c-0.683,1.046-0.752,2.693-2.679,2.468C113.136,695.189,113.133,695.219,112.16,690.689z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(789)} fill={props.fillColors[789]} d="M390.377,729.811c-0.249,2.404-0.695,4.21-1.58,5.843
				c-1.767,3.259-3.615,6.475-5.526,9.65c-0.212,0.352-0.278,1.56-1.23,0.921c-0.503-0.338-0.907-1.199-0.461-1.691
				C385.372,740.349,386.577,734.604,390.377,729.811z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(790)} fill={props.fillColors[790]} d="M344.599,756.192c-1.814-0.072-2.675-1.132-3.274-2.77
				c-0.555-1.516-1.484-2.893-2.005-4.662c2.638,1.56,5.318,3.053,7.893,4.711c1.076,0.693,0.785,1.283-0.449,1.712
				c-0.741,0.258-1.882-0.109-2.215,1.062C344.543,756.251,344.599,756.192,344.599,756.192z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(791)} fill={props.fillColors[791]} d="M486.068,366.735c-0.621-2.016-0.051-3.167,1.887-3.967
				c2.321-0.958,4.486-2.293,6.727-3.47c0.852,2.854,0.799,2.81-1.708,3.943C490.631,364.3,488.378,365.557,486.068,366.735z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(792)} fill={props.fillColors[792]} d="M316.95,576.155c0.609,1.454,1.203,2.915,1.834,4.36
				c0.319,0.733,0.005,1.204-0.54,1.631c-0.551,0.43-1.045,0.34-1.426-0.201c-2.062-2.938-4.241-5.838-3.646-10.186
				c1.417,1.652,2.63,3.064,3.842,4.478L316.95,576.155z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(793)} fill={props.fillColors[793]} d="M143.676,565.704c0.551-0.304,1.102-0.607,1.653-0.911
				c1.289-1.111,2.936-0.948,4.5-1.433c-1.266,4.875-2.428,6.85-9.111,4.729C141.678,567.16,143.057,566.862,143.676,565.704z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(794)} fill={props.fillColors[794]} d="M347.716,624.316c1.722,1.631,3.425,3.243,5.125,4.857
				c0.41,0.39,0.861,0.846,0.618,1.423c-0.211,0.501-0.824,0.313-1.274,0.346c-5.791,0.418-5.817,0.387-4.695-5.389
				C347.573,625.125,347.263,624.603,347.716,624.316z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(795)} fill={props.fillColors[795]} d="M148.409,578.699c0.536-0.144,1.115-0.202,1.599-0.447
				c0.875-0.442,0.819,0.401,1.14,0.727c-1.803,1.677,0.929,2.239,0.894,3.532c-0.015,0.553,0.633,1.081,0.038,1.723
				c-0.726,0.781-1.266,0.056-1.883-0.146c-1.462-0.478-2.542-1.798-4.197-1.872c-0.369-0.017-0.598-0.063-0.566,0.4
				c0.013,0.012,0.048,0.028,0.059,0.035c-0.999-2.135,0.241-2.98,2.034-3.507C147.71,578.777,147.984,578.59,148.409,578.699z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(796)} fill={props.fillColors[796]} d="M176.051,565.538c-4.101,2.997-10.31,2.603-11.65-0.581
				C168.215,564.484,172.002,563.893,176.051,565.538z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(797)} fill={props.fillColors[797]} d="M61.417,681.065c1.826,0.018,2.008,1.551,2.368,2.605
				c0.455,1.336-1.127,1.217-1.658,1.267c-1.505,0.14-2.901,0.586-4.342,0.93c-0.606,0.146-1.616,1.019-1.917-0.265
				c-0.216-0.923-0.625-2.245,0.534-2.788C58.02,682.057,59.786,681.617,61.417,681.065z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(798)} fill={props.fillColors[798]} d="M117.477,697.617c-0.811,3.634-1.913,7.144-1.672,10.882
				c0.062,0.965-0.244,1.242-1.28,0.691c-1.954-1.037-2.586-2.199-1.531-4.379C114.237,702.246,115.168,699.538,117.477,697.617z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(799)} fill={props.fillColors[799]} d="M56.603,732.626c-1.207-1.272-1.45-2.169-0.178-3.488
				c2.279-2.364,3.336-5.514,4.724-8.43c0.699-1.468,1.468-2.865,2.677-4.023C62.952,722.659,59.335,727.361,56.603,732.626z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(800)} fill={props.fillColors[800]} d="M309.859,727.451c-1.177,6.753-5.189,11.375-7.993,16.84
				c-0.885-0.983-1.412-1.727-0.549-2.678c2.752-3.034,4.022-6.914,5.946-10.419C307.9,730.033,308.583,728.947,309.859,727.451z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(801)} fill={props.fillColors[801]} d="M445.706,566.543c0.588-4.79,4.04-7.142,7.434-9.64
				c-1.909,1.548-0.121,4.333-2.212,5.83C449.214,563.961,448.271,566.289,445.706,566.543z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(802)} fill={props.fillColors[802]} d="M336.105,743.251c-1.218-2.193-2.272-4.114-3.349-6.022
				c-0.524-0.931,0.33-1.271,0.754-1.745c0.717-0.802,1.558-1.722,2.64-1.653c0.776,0.049,1.055,1.011,1.013,1.899
				C337.051,738.127,337.471,740.59,336.105,743.251z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(803)} fill={props.fillColors[803]} d="M143.127,571.312c1.352-0.793,1.679,0.317,1.9,1.168
				c0.255,0.98-0.021,2.014-1.225,2.093c-0.985,0.064-1.906,0.343-2.703,0.749c-1.888,0.962-1.375-1.66-2.524-1.561
				c-1.118,0.097-1.262,2.189-2.844,1.468c-0.357-0.163-0.766-0.277-1.046-0.529c-0.512-0.46-2.113-0.18-1.309-1.551
				c0.559-0.952,1.413-1.536,2.75-0.939c3.628,1.621,5.185,1.352,7.09-0.986L143.127,571.312z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(804)} fill={props.fillColors[804]} d="M560.765,258.399c-1.536,3.437-3.028,6.776-4.521,10.116
				c0.28-3.423-0.101-6.7-0.803-9.978c-0.209-0.975,0.788-0.856,1.346-0.797C558.031,257.873,559.261,258.14,560.765,258.399z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(805)} fill={props.fillColors[805]} d="M92.444,574.728c0.758-0.787,1.694-1.406,2.099-2.429
				c0.297,0.094,0.483,0.095,0.561,0.187c1.74,2.042,1.413,3.71-0.708,5.35c-0.813,0.629-1.132,1.874-1.747,2.792
				c-0.371,0.556-0.726,1.476-1.59,1.062c-0.895-0.427-1.045-1.361-0.724-2.277c0.366-1.042,0.804-2.059,1.211-3.086l-0.061,0.05
				c0.73-0.381,0.734-1.181,1.041-1.806L92.444,574.728z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(806)} fill={props.fillColors[806]} d="M407.202,621.746c-0.119,0.23-0.226,0.701-0.396,0.726
				c-1.415,0.203-2.44-0.882-3.711-1.205c-1.021-0.259-0.783-1.055-0.625-1.732c0.232-0.986,0.584-1.944,0.814-2.932
				c0.161-0.691-0.239-1.81,0.956-1.719c1.205,0.092,2.299,0.622,2.44,2.107C406.83,618.549,406.308,620.172,407.202,621.746z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(807)} fill={props.fillColors[807]} d="M52.897,756.979c1.276,1.959,1.382,3.972,2.123,5.743
				c0.324,0.773-0.29,0.926-0.844,1.016c-1.911,0.308-3.826,0.597-6.07,0.943C49.724,762.08,51.2,759.707,52.897,756.979z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(808)} fill={props.fillColors[808]} d="M353.532,759.083c-0.273,1.593-0.513,2.824-2.397,2.87
				c-1.062,0.026-2.126,0.498-3.163,0.845c-1.243,0.416-2.021,0.571-2.58-1.147c-0.619-1.904,0.693-1.818,1.604-2.095
				c1.728-0.525,3.507-0.88,5.266-1.302C353.145,758.041,353.945,757.934,353.532,759.083z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(809)} fill={props.fillColors[809]} d="M181.741,718.546c-0.276,4.811-2.711,8.078-4.628,11.551
				c-0.795,1.44-1.732,2.803-2.566,4.223c-0.199,0.339-0.249,0.794-0.777,0.693c-0.629-0.119-0.641-0.647-0.598-1.12
				c0.034-0.375,0.09-0.819,0.31-1.094C176.811,728.646,178.31,723.437,181.741,718.546z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(810)} fill={props.fillColors[810]} d="M441.564,301.577c-3.588,4.999-7.653,9.3-12.715,12.753
				C429.805,311.01,437.331,303.457,441.564,301.577z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(811)} fill={props.fillColors[811]} d="M473.292,289.744c-0.352,0.638-0.346,1.664-1.732,1.189
				c-3.056-1.046-6.057-2.203-8.975-3.586c-0.822-0.39-0.978-0.804-0.589-1.669c0.382-0.849,0.956-0.9,1.495-0.495
				c2.647,1.989,5.8,2.731,8.857,3.711C472.776,289.031,473.372,288.926,473.292,289.744z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(812)} fill={props.fillColors[812]} d="M395.427,322.357c-1.865,3.069-3.704,6.155-5.625,9.19
				c-0.306,0.483-0.34,1.298-1.338,1.396c0.766-2.396-2.165-2.158-2.619-3.775c0.271-0.453,0.698-0.316,0.995-0.173
				c1.52,0.731,2.26,0.477,2.792-1.338C390.425,324.947,392.607,323.316,395.427,322.357z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(813)} fill={props.fillColors[813]} d="M205.501,604.802c-1.169-1.99-2.128-0.005-3.13,0.236
				c-0.685,0.165-1.114,1.025-2.078,0.41c-1.062-0.68-1.565-1.169-0.114-1.904c0.138-0.07,0.241-0.208,0.371-0.299
				c4.19-2.92,5.839-2.677,9.25,1.368c-1.41,2.074-2.914,0.329-4.379,0.108L205.501,604.802z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(814)} fill={props.fillColors[814]} d="M200.797,571.777c1.59-1.101,1.056-4.07,4.028-4.043
				c0.648,0.006-0.136-0.526-0.14-0.829c-0.013-1.048,0.889-1.381,1.735-2.247c0.29,2.501,0.551,4.727,0.803,6.954
				c0.113,1-0.709,0.647-1.117,0.662c-1.669,0.06-3.343,0.024-5.015,0.024C200.994,572.125,200.896,571.951,200.797,571.777z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(815)} fill={props.fillColors[815]} d="M160.292,728.338c-1.622-0.946-3.087-1.707-4.45-2.62
				c-1.928-1.289-0.357-2.964-0.104-4.303c0.18-0.954,1.314,0.086,1.922,0.382C161.026,723.433,161.013,723.459,160.292,728.338z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(816)} fill={props.fillColors[816]} d="M69.858,684.669c0.789-0.233,1.494-0.549,2.228-0.642
				c2.711-0.343,4.625,1.41,4.589,4.139c-0.006,0.485-0.164,1.229-0.652,1.036c-1.078-0.429-2.231,1.247-3.197-0.234
				C71.896,687.541,70.893,686.161,69.858,684.669z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(817)} fill={props.fillColors[817]} d="M561.506,568.558c2.803,0.212,2.448,2.808,3.531,4.568
				c-1.983,0.002-3.626-0.898-5.455-0.906c-2.118-0.008-2.162-1.709-1.939-3.259c0.303-2.109,1.642-0.717,2.562-0.65
				c0.467,0.033,0.924,0.19,1.385,0.291L561.506,568.558z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(818)} fill={props.fillColors[818]} d="M88.821,683.96c1.727-0.026,3.291,0.501,4.768,0.428
				c2.535-0.125,2.201,1.388,2.111,2.854c-0.131,2.145-1.525,1.141-2.74,1.226C89.457,688.713,89.509,686.162,88.821,683.96z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(819)} fill={props.fillColors[819]} d="M166.965,767.56c-0.555-0.012-1.066-0.144-0.501-1.091
				c1.042-1.748,2.026-3.529,3.048-5.288c0.233-0.402,0.504-0.938,1.046-0.757c0.297,0.1,0.521,0.561,0.675,0.903
				c0.124,0.276,0.071,0.627,0.136,0.936c0.299,1.415,1.763,3.401,0.955,4.027C171.053,767.274,168.935,767.164,166.965,767.56z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(820)} fill={props.fillColors[820]} d="M251.08,732.897c4.479,0.633,8.93,0.497,13.022,3.021
				c-0.814,0.592-1.333,0.449-1.947,0.26c-1.613-0.497-3.301-0.437-4.916-0.109C253.449,736.836,253.175,736.756,251.08,732.897z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(821)} fill={props.fillColors[821]} d="M295.357,574.688c-2.628,1.186-4.992,2.252-7.372,3.324
				c-0.618-3.352-0.112-4.325,3.27-5.774C293.742,571.174,293.646,574.199,295.357,574.688z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(822)} fill={props.fillColors[822]} d="M501.678,325.292c0.495-2.374,0.951-4.757,1.513-7.116
				c0.156-0.658,0.678-1.1,1.454-0.601c0.132,0.084,0.276,0.153,0.423,0.211c3.648,1.446,3.644,1.44,0.761,4.093
				c-0.646,0.595-1.242,1.245-1.872,1.859c-0.619,0.604-1.25,1.194-1.876,1.79C501.946,325.45,501.812,325.37,501.678,325.292z"/>
			<path fill-rule="evenodd" d="M564.556,281.821c-1.628,1.359-3.334,1.955-5.379,0.813c-1.667-0.93-2.306-2.321-2.008-4.112
				c0.292-1.748,1.412-2.805,3.182-3.036c1.845-0.241,3.26,0.563,4.116,2.148c0.435,0.804,0.751,1.741,0.613,2.725
				C564.905,280.847,564.73,281.333,564.556,281.821z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(823)} fill={props.fillColors[823]} d="M319.949,578.816c-1.406,0.314-1.577-0.797-1.799-1.677
				c-0.245-0.977,0.586-1.054,1.269-1.306c2.97-1.095,6.031-1.71,9.188-1.782c0.584-0.014,1.536-0.335,1.629,0.821
				c0.107,1.347-0.762,0.829-1.516,0.955c-3.104,0.516-6.371,0.672-8.838,3.061L319.949,578.816z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(824)} fill={props.fillColors[824]} d="M194.107,569.156c0.947-0.295,1.958-0.72,1.435,1.051
				c-0.131,0.442-0.099,0.964-0.026,1.431c0.253,1.629-0.289,2.596-1.965,3.103c-1.16,0.351-2.188,1.139-3.903,2.073
				c1.399-3.116,1.055-6.421,4.503-7.671L194.107,569.156z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(825)} fill={props.fillColors[825]} d="M319.713,552.768c-0.965-1.526-2.314-1.443-3.519-1.773
				c-0.454-0.125-1.202-0.056-1.288-0.834c-0.066-0.59,0.401-0.79,0.783-1.072c2.146-1.585,4.398-2.989,7.242-4.349
				C321.996,547.674,320.243,549.816,319.713,552.768z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(826)} fill={props.fillColors[826]} d="M486.793,571.943c-0.957-1.779,0.184-2.928,0.015-4.19
				c-0.232-1.745,0.168-3.831-2.576-3.973c-0.517-0.027-2.421-4.771-2.088-5.171c0.372-0.448,0.8-0.863,1.571-0.402
				c2.394,1.432,4.058,5.043,3.656,8.037C487.138,567.989,487.652,569.83,486.793,571.943z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(827)} fill={props.fillColors[827]} d="M365.227,633.241c-4.944-1.294-5.225-1.672-5.156-6.354
				c0.016-1.129,0.601-1.015,1.409-0.879c2.877,0.481,2.836,3.023,3.55,5C365.255,631.63,365.16,632.368,365.227,633.241z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(828)} fill={props.fillColors[828]} d="M233.909,578.04c-0.385-1.616,0.156-2.368,1.633-2.902
				c3.624-1.309,7.321-1.838,11.141-1.576c0.551,0.038,1.451-0.283,1.52,0.655c0.081,1.093-0.899,0.996-1.542,0.941
				C242.125,574.768,237.998,576.208,233.909,578.04z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(829)} fill={props.fillColors[829]} d="M476.094,218.563c1.019,0.946,2.087,1.846,2.771,3.091
				c0.253,1.456,0.506,2.913,0.792,4.559c-2.712-2.783-5.274-5.412-7.879-8.085C473.202,217.429,474.621,218.485,476.094,218.563z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(830)} fill={props.fillColors[830]} d="M297.753,430.141c2.543-2.085,5.083-4.173,7.64-6.241
				c0.124-0.1,0.529-0.098,0.578-0.013c0.583,1.014,1.407,2.003,1.593,3.097c0.168,0.98-1.178,0.802-1.819,1.047
				c-2.542,0.968-5.148,1.766-7.731,2.626C297.926,430.484,297.839,430.312,297.753,430.141z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(831)} fill={props.fillColors[831]} d="M343.275,622.324c-0.89,1.042-1.65,0.799-2.537,0.488
				c-3.412-1.193-6.921-1.95-10.551-2.039c-0.78-0.02-1.402-0.115-1.34-1.165c0.059-0.996,0.762-0.921,1.354-0.868
				C334.727,619.144,339.163,619.892,343.275,622.324z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(832)} fill={props.fillColors[832]} d="M569.449,252.323c1.125,0.023,1.167-1.121,1.772-1.66
				c0.485-0.433,0.542-1.543,1.403-1.294c0.795,0.23,1.466,0.925,1.666,1.818c0.135,0.6,0.091,1.24,0.126,1.862
				c0.052,0.185-0.012,0.313-0.189,0.386c-1.162,1.068-1.241,3.299-3.725,2.979c-1.574-0.203-1.928-0.518-1.308-1.995
				c0.253-0.602,1.461-1.256,0.26-2.091C569.453,252.327,569.449,252.323,569.449,252.323z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(833)} fill={props.fillColors[833]} d="M508.729,588.437c-1.633-1.747-3.416-3.3-5.603-4.309
				c-1.139-1.491-1.587-2.697,1.096-2.563c0.469,0.023,0.572-0.143,0.333-0.51c-0.468-0.72-0.412-1.284,0.555-1.175
				c0.71,0.079,1.088,0.747,0.721,1.418c-0.551,1.01,0.354,1.175,0.787,1.631c0.048,0.064,0.096,0.13,0.145,0.195
				c0.896,1.478,2.234,2.626,2.999,4.216C510.472,588.814,509.539,588.515,508.729,588.437z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(834)} fill={props.fillColors[834]} d="M483.641,628.626c0.086,0.16,0.179,0.366,0.299,0.554c1.376,2.151,0,6.752-2.31,7.529
				c-1.013,0.341-1.404-0.654-1.617-1.257c-0.473-1.337,1.522-5.639,2.796-6.393C483.007,628.941,483.218,628.845,483.641,628.626z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(835)} fill={props.fillColors[835]} d="M38.946,590.4c-0.263,0.666-0.409,1.585-1.597,1.541
				c-2.475-0.093-4.842,0.401-7.124,1.347c-1.191,0.493-1.364-0.26-1.76-1.174c-0.537-1.241,0.327-1.443,0.993-1.673
				c2.799-0.967,5.803-0.272,8.65-0.974C38.612,589.344,39.058,589.591,38.946,590.4z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(836)} fill={props.fillColors[836]} d="M487.556,236.583c1.433,1.028,2.021,2.445,2.564,3.761
				c0.949,2.305,2.586,3.951,4.438,5.469c0.245,0.201,0.617,0.324,0.744,0.576c0.407,0.815,1.661,1.541,1.052,2.452
				c-0.624,0.931-2.046,0.373-3.101,0.679c-0.843,0.244-0.72-0.939-0.947-1.58c-1.032-2.901-2.16-5.766-3.637-8.481
				C488.211,238.616,487.609,237.812,487.556,236.583z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(837)} fill={props.fillColors[837]} d="M549.573,307.82c2.245-1.483,3.357-4.391,6.417-4.675
				c0.897-0.083,1.903-0.827,2.418,0.372c0.455,1.059-1.181,3.617-2.227,3.577C553.946,307.01,551.778,307.298,549.573,307.82z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(838)} fill={props.fillColors[838]} d="M136.883,686.907c0.831,1.706,1.672,3.408,2.486,5.122
				c0.376,0.791-0.457,6.049-1.052,6.553c-0.219,0.186-0.568,0.307-0.855,0.302c-0.521-0.009-0.572-0.465-0.58-0.861
				c-0.022-1.037-0.024-2.076-0.023-3.114C136.863,692.242,136.916,689.573,136.883,686.907z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(839)} fill={props.fillColors[839]} d="M573.293,624.637c0.739,0.065,1.273,0.132,1.81,0.157
				c4.133,0.194,5.026,1.819,2.92,5.321c-0.54,0.898-2.571,0.443-3.193-0.857C574.154,627.848,573.503,626.397,573.293,624.637z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(840)} fill={props.fillColors[840]} d="M533.781,623.65c-0.12,2.416-1.439,2.966-3.344,2.836
				c-1.025-0.069-2.128-0.245-3.076,0.033c-2.211,0.649-2.03-1.087-2.113-2.145c-0.131-1.659,1.354-0.9,2.072-0.927
				C529.446,623.372,531.593,623.228,533.781,623.65z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(841)} fill={props.fillColors[841]} d="M248.394,611.528c0.824-0.185,1.648-0.37,2.473-0.555
				c1.019-0.304,2.482-1.321,2.981-0.528c0.609,0.969,0.65,2.739,0.175,3.832c-0.611,1.407-2.521,1.395-3.866,1.553
				c-1.126,0.133-1.241-0.96-0.519-1.857C248.806,613.371,247.983,612.764,248.394,611.528z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(842)} fill={props.fillColors[842]} d="M357.023,501.203c-1.193,0.794-2.321,1.227-3.621,0.186
				c-1.179-0.943-2.43-0.148-3.66-0.021c-0.497,0.052-1.254,0.297-1.484-0.349c-0.112-0.315,0.27-0.872,0.532-1.246
				c2.222-3.168,3.472-3.212,6.279-0.3C355.613,500.037,356.243,500.518,357.023,501.203z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(843)} fill={props.fillColors[843]} d="M330.895,246.396c-1.976,2.917-3.67,6.019-6.725,8.592
				c0.172-2.394,0.276-4.437,0.484-6.469c0.094-0.919,0.956-0.735,1.525-0.695C327.922,247.944,329.468,247.564,330.895,246.396z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(844)} fill={props.fillColors[844]} d="M514.713,380.031c-0.517-2.793-1.023-5.53-1.536-8.298
				c0.993-0.083,1.273,0.604,1.746,0.979C519.165,376.074,519.162,376.077,514.713,380.031z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(845)} fill={props.fillColors[845]} d="M46.555,576.007c-0.418,0.36,1.298,2.534-0.947,2.105
				c-1.221-0.233-3.671,0.988-3.613-1.244c0.04-1.495-0.228-4.225,2.243-4.547C46.293,572.054,47.401,573.376,46.555,576.007z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(846)} fill={props.fillColors[846]} d="M54.671,764.271c-0.904,3.331-2.329,4.631-5.567,4.901
				c-1.486,0.123-1.358-1.392-1.76-2.239c-0.346-0.729,0.004-1.218,0.999-1.363C50.395,765.269,52.416,764.748,54.671,764.271z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(847)} fill={props.fillColors[847]} d="M491.078,569.486c-2.493,0.018-2.866-0.323-1.726-2.481
				c0.642-1.215-0.706-1.84-0.227-2.867c0.272-0.583-0.079-1.653,1.031-1.426c0.932,0.191,2.281-0.489,2.683,1.21
				c0.231,0.981-0.238,1.897,0.186,2.916C493.963,569.093,493.612,569.468,491.078,569.486z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(848)} fill={props.fillColors[848]} d="M320.164,737.042c-3.483,5.311-6.006,11.024-8.139,17.076
				c-1.061-3.258-1.054-3.253,0.859-6.451C315.097,743.97,317.231,740.223,320.164,737.042z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(849)} fill={props.fillColors[849]} d="M325.062,568.567c0-1.418-0.001-2.293,0-3.168c0.003-4.11,0.072-4.173,3.923-3.403
				c0.78,0.156,1.701,0.316,1.857,1.204c0.129,0.732-0.471,1.386-1.096,1.741C328.164,565.843,326.818,567.051,325.062,568.567z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(850)} fill={props.fillColors[850]} d="M330.478,625.363c1.191,0.571,3.568-0.311,3.955,0.814
				c0.57,1.656-1.98,2.536-2.571,4.21c-0.298,0.842-1.391,1.399-2.145,0.771c-0.451-0.376-1.288-1.597,0.173-2.226
				c0.132-0.057,0.233-0.186,0.349-0.28c-0.238-0.141-0.465-0.305-0.716-0.417c-1.031-0.458-0.835-1.519-0.833-2.241
				C328.691,625.175,329.688,625.527,330.478,625.363z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(851)} fill={props.fillColors[851]} d="M462.205,628.616c-1.502,0.879-2.795,2.13-4.503,2.633
				c-0.77,0.228-1.781,0.348-2.203-0.386c-0.532-0.925,0.572-1.307,1.104-1.797c0.578-0.534,1.265-0.949,1.903-1.416
				c0.215-0.76,1.022-0.8,1.503-1.237l-0.067,0.085c0.221-0.082,0.435-0.204,0.662-0.238c0.773-0.119,1.138-1.801,2.06-0.814
				C463.616,626.467,463.358,627.699,462.205,628.616z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(852)} fill={props.fillColors[852]} d="M119.689,617.328c-2.296-0.938-4.179-1.706-6.546-2.673
				c1.837-1.044,1.96-3.994,4.357-3.515C120.996,611.841,119.216,614.923,119.689,617.328z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(853)} fill={props.fillColors[853]} d="M559.798,561.591c0.057,0.057,0.132,0.103,0.167,0.17
				c0.386,0.75,1.985,0.372,1.697,1.612c-0.232,0.999-1.45,0.308-2.305,1.032c2.353,0.018,3.604,1.168,3.791,3.294
				c0.133,1.493-1.401-0.031-1.654,0.85c0.012,0.009,0.073,0.039,0.085,0.046c-0.163-0.156-0.333-0.455-0.513-0.462
				c-3.341-0.129-3.271-2.222-3.059-4.763c0.123-1.475,0.347-2.098,1.863-1.724L559.798,561.591z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(854)} fill={props.fillColors[854]} d="M292.906,415.832c0-2.129-0.013-4.258,0.011-6.387
				c0.005-0.434-0.184-1.002,0.407-1.232c0.498-0.194,0.92,0.02,1.272,0.393c0.164,0.173,0.338,0.336,0.51,0.5
				c2.739,2.634,2.717,2.809-0.088,5.273c-0.58,0.51-0.97,1.235-1.447,1.862C293.35,416.104,293.127,415.968,292.906,415.832z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(855)} fill={props.fillColors[855]} d="M318.296,736.366c0.248-1.478,0.438-2.854,0.724-4.212
				c0.139-0.658,0.483-1.088,1.358-0.865c0.833,0.212,1.741,0.111,2.593,0.271c0.729,0.137,2.135-0.572,1.995,0.792
				c-0.105,1.038-0.332,2.767-2.113,2.559C321.111,734.708,319.885,735.818,318.296,736.366z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(856)} fill={props.fillColors[856]} d="M232.6,296.424c-3.344-2.049-6.688-4.098-10.189-6.244
				C228.042,289.108,229.945,290.2,232.6,296.424z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(857)} fill={props.fillColors[857]} d="M482.608,750.04c2.832-0.791,5.101-1.434,7.513-1.263
				c0.687,0.049,1.392,0.289,1.689,0.758c0.46,0.727-0.516,1.012-0.835,1.508c-1.182,1.84-2.706,2.118-4.571,1.018
				C485.339,751.432,484.22,750.894,482.608,750.04z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(858)} fill={props.fillColors[858]} d="M531.437,574.489c1.506,1.493,4.158,1.298,5.096,3.668
				c0.549,1.386,0.747,2.473,0.254,3.806c-0.166,0.45-0.185,1.068-0.67,1.17c-0.627,0.132-0.99-0.461-1.215-0.948
				C533.722,579.631,532.587,577.057,531.437,574.489z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(859)} fill={props.fillColors[859]} d="M557.586,479.812c-0.033,2.635-4.506,5.719-6.631,4.735
				c-1.082-0.501-0.81-1.206-0.06-1.821c1.701-1.395,3.416-2.772,5.13-4.152c0.286-0.23,0.524-0.617,0.994-0.278
				C557.637,478.742,557.551,479.396,557.586,479.812z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(860)} fill={props.fillColors[860]} d="M319.812,571.236c0.229-0.039,0.459-0.079,0.688-0.119
				c3.621-2.202,2.513,1.644,3.837,2.767c-2.72,0.585-5.075,1.363-7.39,2.267c0.003,0.005,0.062,0.077,0.064,0.082
				C315.878,573.413,318.553,572.721,319.812,571.236z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(861)} fill={props.fillColors[861]} d="M369.303,748.256c0,1.495,0.015,2.991-0.004,4.487
				c-0.027,2.179-2.104,2.61-3.348,3.684c-0.508,0.438-0.706-0.549-0.795-1.079C364.595,752.012,367.263,750.363,369.303,748.256z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(862)} fill={props.fillColors[862]} d="M446.115,439.675c1.226-2.01,2.328-3.817,3.479-5.702
				c0.734,1.081,1.438,1.959,2.864,1.791c0.801-0.095,1.454,0.551,1.553,1.231c0.112,0.769-0.902,0.313-1.276,0.717
				c-0.203,0.22-0.704,0.245-0.8,0.473C450.694,441.141,448.396,439.736,446.115,439.675z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(863)} fill={props.fillColors[863]} d="M440.854,577.395c-1.114,1.068-2.586,0.774-3.744,0.531
				c-2.759-0.579-5.43-0.586-8.154,0.045c-0.656,0.152-1.589,0.22-1.678-0.783c-0.071-0.802,0.28-1.799,1.244-1.677
				c2.576,0.326,5.492-1.329,7.721,1.332c0.351,0.418,1.181,0.883,1.99-0.069c1.07-1.262,2.028-0.758,2.536,0.708
				C440.771,577.48,440.854,577.395,440.854,577.395z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(864)} fill={props.fillColors[864]} d="M231.917,557.098c-0.351,1.021,0.934,3.074-1.096,3.433
				c-1.741,0.307-1.909-1.8-2.591-2.955c-0.363-0.614-1.171-1.495-0.355-2.125c1.035-0.798,2.293-1.344,3.521-1.838
				c0.431-0.174,0.518,0.453,0.521,0.853C231.921,555.264,231.917,556.063,231.917,557.098z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(865)} fill={props.fillColors[865]} d="M203.897,621.526c-3.701-0.421-4.507-1.598-3.957-5.783
				c4.52,1.077,5.872,2.649,4.515,5.247C204.457,621.363,204.275,621.548,203.897,621.526z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(866)} fill={props.fillColors[866]} d="M505.127,569.623c-3.501,2.581-1.048,5.993-1.712,9.031
				c-0.782-0.859-1.51-1.635-2.211-2.435c-0.806-0.919-2.079-1.895,0.469-2.212c0.664-0.083,0.959-0.871,0.55-1.64
				c-0.331-0.621-0.715-1.239-0.901-1.907c-0.303-1.084,0.107-1.933,1.054-2.597c1.215-0.853,1.189,0.521,1.715,0.887
				C504.476,569.02,504.813,569.356,505.127,569.623z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(867)} fill={props.fillColors[867]} d="M488.24,644.312c-0.511-0.344-1.188-0.474-1.424-1.166
				c-0.33-0.968-2.101-1.417-1.2-2.782c0.915-1.386,1.536,0.438,2.396,0.404c1.704-0.064,3.713,0.521,3.562-2.389
				c-0.038-0.718,0.421-1.461,0.691-2.32c0.335,0.215,0.612,0.35,0.837,0.544c2.337,2.021,2.28,1.948-0.158,3.729
				c-1.677,1.225-3.974,1.762-4.761,4.048C488.181,644.384,488.24,644.312,488.24,644.312z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(868)} fill={props.fillColors[868]} d="M542.427,580.176c-1.135-1.589-2.92-2.852-1.688-5.34
				c0.68-1.373,1.548,0.135,2.349-0.532c0.491-0.409,0.321,0.728,0.382,1.195c0.261,2.022,0.325,4.102,1.602,5.842
				c0.438,0.601,1.405,1.217,0.219,1.869c-0.989,0.544-1.314-0.227-1.607-1.078c-0.158-0.462-0.716-0.786-1.092-1.173
				C542.281,580.75,542.265,580.48,542.427,580.176z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(869)} fill={props.fillColors[869]} d="M282.131,619.94c0.184,0.012,0.368,0.024,0.552,0.036
				c2.99-0.901,2.986-0.902,2.354,1.996c-0.235,1.079,0.417,2.81-1.109,2.997c-1.734,0.213-3.193-1.159-4.692-1.982
				c-0.566-0.311-0.08-0.721,0.36-1.015C280.497,621.373,281.612,621.021,282.131,619.94z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(870)} fill={props.fillColors[870]} d="M157.082,745.488c-1.128-3.757,1.294-5.924,3.127-8.41
				C162.064,740.259,160.996,743.126,157.082,745.488z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(871)} fill={props.fillColors[871]} d="M94.146,724.682c0.965,3.919,1.93,7.838,2.868,11.649
				C91.089,733.785,93.093,729.07,94.146,724.682z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(872)} fill={props.fillColors[872]} d="M110.299,684.662c1.132-0.45-0.744-2.708,1.336-2.507
				c1.481,0.144,4.11,0.902,3.903,1.799c-0.373,1.612-1.972,3.69-3.462,4.097C110.445,688.496,110.955,685.913,110.299,684.662z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(873)} fill={props.fillColors[873]} d="M141.61,693.669c0.867,4.515,1.182,8.864,0.042,13.221
				C139.243,700.847,139.181,696.624,141.61,693.669z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(874)} fill={props.fillColors[874]} d="M416.362,627.211c-1.333-0.668-3.242-0.815-3.442-1.976
				c-0.305-1.764,0.43-3.654,1.143-5.391c0.172-0.416,0.466-0.696,0.881-0.319c0.593,0.539,1.526,0.742,1.45,1.916
				C416.275,623.244,416.362,625.062,416.362,627.211z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(875)} fill={props.fillColors[875]} d="M64.72,732.328c-0.705,5.615-2.153,11.055-3.932,16.417
				C60.346,742.859,62.785,737.652,64.72,732.328z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(876)} fill={props.fillColors[876]} d="M456.985,386.57c1.43-0.179,1.781,0.867,1.835,1.879
				c0.059,1.092,0.612,2.426-0.828,3.169c-1.184,0.611-2.364,1.228-3.727,1.936c0.096-2.802,0.56-5.272,2.801-7.066L456.985,386.57z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(877)} fill={props.fillColors[877]} d="M468.562,358.008c-3.163,1.992-5.658,3.697-8.583,5.028
				C460.574,357.85,459.456,357.757,468.562,358.008z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(878)} fill={props.fillColors[878]} d="M136.545,463.22c2.433-0.103,4.41,0.93,6.367,1.911
				c1.409,0.706,2.722,1.605,4.054,2.456c0.398,0.255,1.173,0.314,0.753,1.169c-0.265,0.54-0.548,0.94-1.223,0.925
				c-0.709-0.016-1.442,0.138-2.014-0.471c-2.148-2.287-4.718-3.968-7.56-5.264C136.764,463.873,136.715,463.56,136.545,463.22z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(879)} fill={props.fillColors[879]} d="M318.186,626.635c-1.79-0.86-3.557-1.725-5.336-2.56
				c-1.424-0.668-0.837-1.97-0.672-2.862c0.192-1.038,1.101-0.325,1.723-0.14C317.504,622.146,317.833,622.567,318.186,626.635z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(880)} fill={props.fillColors[880]} d="M141.717,761.051c0.633-4.605,0.003-9.134,0.271-13.977
				c2.087,2.084,2.859,3.969,1.835,6.582C142.892,756.031,142.401,758.579,141.717,761.051z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(881)} fill={props.fillColors[881]} d="M40.652,751.613c-0.267-2.026-0.299-3.994-0.704-5.949
				c-0.157-0.759-0.485-1.895,0.399-2.411c0.864-0.507,1.417-2.09,2.389-1.577c0.943,0.496,0.234,1.814,0.098,2.819
				C42.495,746.992,41.759,749.342,40.652,751.613z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(882)} fill={props.fillColors[882]} d="M495.165,635.821c1.496-0.521,2.902-0.896,4.423-0.865
				c0.738,0.016,1.907-0.883,1.621,0.775c-0.194,1.119,1.181,2.946-1.346,3.249C497.418,639.273,495.981,638.252,495.165,635.821z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(883)} fill={props.fillColors[883]} d="M476.174,557.086c2.479,0.389,3.06,1.419,3.271,3.474
				c0.219,2.144,0.083,4.272,0.29,6.396c0.049,0.492-0.01,1.017-0.552,1.194c-0.563,0.185-0.853-0.334-1.137-0.677
				c-0.836-1.01-1.593-1.986-0.33-3.304c1.011-1.055,0.707-2.352,0.158-3.558C477.393,559.555,476.866,558.517,476.174,557.086z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(884)} fill={props.fillColors[884]} d="M43.086,734.114c0.205,3.206,0.852,6.55-3.216,7.998
				C38.676,738.87,39.462,736.886,43.086,734.114z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(885)} fill={props.fillColors[885]} d="M474.743,584.326c-1.154-1.562-2.401-3.033-4.048-4.109
				c-0.738-1.098-0.67-2.101,0.497-2.817c1.307-0.803,1.898,0.735,2.046,1.194c0.619,1.925,2.682,2.824,3.14,4.793
				c0.132,0.567,0.332,1.131-0.252,1.501C475.471,585.305,475.137,584.688,474.743,584.326z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(886)} fill={props.fillColors[886]} d="M181.565,583.305c-0.438-1.533,2.38-7.137,3.752-7.516
				c0.396-0.109,0.734,0.085,0.72,0.334c-0.063,1.056,1.443,1.932,0.966,2.544c-1.333,1.708-3.026,3.139-4.62,4.634
				c-0.146,0.137-0.594-0.047-0.902-0.082C181.48,583.22,181.565,583.305,181.565,583.305z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(887)} fill={props.fillColors[887]} d="M158.124,755.036c-0.539-2.525-0.45-5.108-0.662-7.635
				c-0.096-1.142,1.573-1.96,2.936-3.07C160.67,748.395,159.467,751.702,158.124,755.036z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(888)} fill={props.fillColors[888]} d="M30.2,711.499c-0.83-2.177-1.702-4.169-2.967-5.994
				c-0.427-0.616-0.758-1.421-0.192-1.954c0.257-0.242,1.172,0.071,1.717,0.304c3.018,1.284,3.31,1.992,2.264,5.2
				C30.755,709.872,30.474,710.685,30.2,711.499z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(889)} fill={props.fillColors[889]} d="M369.214,755.67c-0.384,3.654-1.076,6.99-2.454,10.235
				C364.754,761.777,365.756,757.477,369.214,755.67z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(890)} fill={props.fillColors[890]} d="M477.515,576.278c-1.16-3.015-2.424-6.008-1.899-9.365
				c0.056-0.358,0.104-0.719,0.154-1.067c3.338,3.093,4.31,6.491,1.734,10.425L477.515,576.278z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(891)} fill={props.fillColors[891]} d="M475.927,625.811c0.192,1.8,0.399,3.597,0.564,5.398
				c0.035,0.38-0.099,1.113-0.135,1.112c-1.73-0.059-2.911-1.343-4.337-2.078c-0.911-0.47-0.019-1.04,0.358-1.48
				c0.978-1.142,1.964-2.274,2.947-3.41C475.525,625.505,475.727,625.658,475.927,625.811z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(892)} fill={props.fillColors[892]} d="M493.558,373.059c-0.545,4.393-2.029,5.668-5.386,4.831
				c-0.531-0.133-0.804-0.315-1.03-0.799c-0.222-0.475-0.104-0.796,0.279-0.98C489.459,375.13,491.209,373.544,493.558,373.059z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(893)} fill={props.fillColors[893]} d="M203.897,621.526c0.186-0.179,0.373-0.358,0.559-0.536
				c0.492-0.25,1.047-0.422,1.464-0.764c1.195-0.98,1.343,0.118,1.86,0.78c0.608,0.778,1.099,1.372-0.058,2.066
				c-1.081,0.648-2.186,1.286-3.161,2.075c-1.102,0.892-1.836,0.065-2.453-0.501c-0.865-0.795,0.337-1.127,0.635-1.642
				C203.052,622.469,203.506,622.016,203.897,621.526z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(894)} fill={props.fillColors[894]} d="M240.246,738.881c1.221,0.906,2.676,0.118,3.144,1.153
				c0.702,1.556,1.131,3.276,1.388,4.97c0.106,0.698-0.904,0.989-1.607,0.993c-0.632,0.004-1.438,0.312-1.625-0.844
				C241.235,743.233,240.766,741.34,240.246,738.881z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(895)} fill={props.fillColors[895]} d="M575.611,564.865c-1.218-4.631-2.335-9.243,2.453-12.951
				C578.039,556.483,574.786,560.306,575.611,564.865z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(896)} fill={props.fillColors[896]} d="M368.868,736.758c-1.951,0.747-3.75,1.509-5.599,2.12
				c-1.341,0.443-0.741-0.874-1-1.354c-0.55-1.021-0.727-2.384-0.028-3.155c0.466-0.516,1.732,0.175,2.624,0.453
				C366.223,735.245,367.605,735.589,368.868,736.758z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(897)} fill={props.fillColors[897]} d="M527.426,350.122c-1.608-1.375-2.977-2.548-4.349-3.715
				c-1.001-0.852-0.89-1.379,0.288-2.077c2.759-1.634,2.735-1.709,3.535,1.469C527.23,347.11,528.152,348.348,527.426,350.122z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(898)} fill={props.fillColors[898]} d="M230.434,627.951c-2.946-0.996-0.697-3.019-0.827-4.536
				c-0.11-1.289,1.156-0.944,1.791-0.816c1.986,0.403,1.789,2.447,2.235,3.759c0.422,1.24-1.146,0.827-1.911,0.948
				c-0.546,0.087-1.147-0.012-1.354,0.706C230.363,628.015,230.434,627.951,230.434,627.951z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(899)} fill={props.fillColors[899]} d="M158.114,564.741c-0.421,1.531-1.372,2.632-3.069,2.73
				c-0.881,0.051-1.994-0.054-2.279-1.145c-0.247-0.948,2.266-3.756,3.162-3.865C157.67,562.249,157.729,563.592,158.114,564.741z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(900)} fill={props.fillColors[900]} d="M408.609,575.43c0.305-0.729,0.572-1.371,0.911-2.183
				c-1.029,0.292-2.206,1.078-2.757-0.064c-0.46-0.954-0.322-2.282,0.542-3.104c0.69-0.655,1.617-0.447,2.393,0.129
				c0.126,0.095,0.277,0.155,0.417,0.231c-0.778,1.405,1.131,1.705,1.135,2.74c0.003,0.835,0.397,1.798-0.393,2.484
				C410.082,576.335,409.438,575.465,408.609,575.43z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(901)} fill={props.fillColors[901]} d="M90.524,599.625c0.496,0.625,1.099,1.108,1.851,1.393
				c0.107,0.293,0.14,0.655,0.335,0.869c1.733,1.906,0.671,3.127-0.993,4.218c-0.005,0.438-0.185,0.668-0.655,0.558
				c-0.832-0.155-1.197-0.839-1.224-1.503c-0.064-1.564-0.116-3.11-0.482-4.648C89.129,599.561,89.895,599.611,90.524,599.625z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(902)} fill={props.fillColors[902]} d="M328.553,578.024c-2.45,0.737-4.9,1.474-7.345,2.21
				c-0.548,0.284-1.169,0.624-1.65,0.016c-0.422-0.534,0.188-0.966,0.39-1.433c0.002-0.001-0.071,0.075-0.068,0.073
				c3.458-1.441,6.853-3.221,11.132-2.151C330.195,577.779,329.03,577.211,328.553,578.024z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(903)} fill={props.fillColors[903]} d="M123.519,612.971c-1.739-0.602-2.652-1.47-2.664-3.66
				c-0.011-1.896,0.718-1.926,1.905-1.624c1.087,0.276,3.054,0.229,2.976,1.308c-0.1,1.366-0.953,2.931-2.293,3.896L123.519,612.971z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(904)} fill={props.fillColors[904]} d="M92.384,568.06c0.327,0.143,0.654,0.284,0.971,0.421
				c0.384,0.45,0.603,1.001,1.083,1.441c0.729,0.669,0.101,1.346-0.347,2.01c-0.672,0.997-1.359,0.769-2.131,0.215
				c-0.682,0.147-1.159-0.018-1.229-0.805c-0.108-0.115-0.222-0.226-0.323-0.348c-0.829-1.002-1.701-2.283-0.929-3.388
				C90.32,566.402,91.344,568.157,92.384,568.06z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(905)} fill={props.fillColors[905]} d="M241.398,613.411c0.565-1.017,1.704-1.378,2.475-2.159
				c0.305-0.073,0.616-0.225,0.915-0.204c0.879,0.062,0.77,1.069,0.579,1.215c-1.452,1.111,0.558,3.454-1.418,4.128
				c-0.849,0.289-2.122,0.099-2.913-0.367C239.461,615.098,240.767,614.228,241.398,613.411z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(906)} fill={props.fillColors[906]} d="M244.618,707.127c0.132,1.232,1.406,1.651,1.933,2.611
				c-2.303,1.269-3.346,1.305-7.636,0.225c1.583-1.748,2.739-4.014,5.651-2.4c0.003,0.002,0.085-0.101,0.094-0.159
				c0.011-0.076-0.014-0.157-0.033-0.25C244.619,707.14,244.618,707.127,244.618,707.127z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(907)} fill={props.fillColors[907]} d="M100.808,602.555c-0.8,1.779-1.53,3.41-2.266,5.039
				c-0.439,0.972-1.592,1.474-2.241,1.335c-1.019-0.219-0.54-1.451-0.504-2.267C95.933,603.571,98.109,602.853,100.808,602.555z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(908)} fill={props.fillColors[908]} d="M280.866,582.759c-1.795-0.029-1.433-1.35-1.459-2.438
				c0.762-1.822,2.12-3.361,2.548-5.344l-0.089,0.089c2.424-0.397,1.69,1.477,1.662,2.556c-0.019,0.691-0.701,1.365-1.087,2.047
				C283.449,581.479,282.674,582.383,280.866,582.759z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(909)} fill={props.fillColors[909]} d="M154.408,729.171c0.526-0.906-0.533-2.125,0.811-2.526
				c0.792-0.235,5.438,3.051,5.13,3.415c-1.28,1.52-3.303,1.12-4.946,1.345C153.67,731.641,155.262,729.73,154.408,729.171z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(910)} fill={props.fillColors[910]} d="M443.337,416.53c0.98,0.884,1.029,1.808,1.367,2.591
				c0.456,1.056,1.554,2.632,0.878,3.166c-1.112,0.879-2.898,0.34-4.392,0.241c-0.974-0.063-0.52-0.814-0.31-1.245
				C441.641,419.728,442.465,418.203,443.337,416.53z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(911)} fill={props.fillColors[911]} d="M152.521,575.118c-1.062-2.513,0.902-4.33,0.708-6.468
				c-0.057-0.626,0.587-0.895,0.959-0.558c0.892,0.808,2.078-1.041,2.695,0.333c0.208,0.464-0.015,1.181-0.825,1.473
				c-0.832,0.299-0.805,1.275-0.985,1.979C154.699,573.329,153.938,574.372,152.521,575.118z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(912)} fill={props.fillColors[912]} d="M514.934,385.698c0.367,2.785-0.795,5.264-1.395,7.833
				c-0.316,1.353-3.003,1.592-3.607,0.393c-0.409-0.811,0.139-1.518,1.095-1.626c1.332-0.151,1.864-1.181,1.72-2.166
				C512.446,388.076,513.976,387.083,514.934,385.698z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(913)} fill={props.fillColors[913]} d="M52.842,619.873c-0.604,0.257-0.803-0.343-1.168-0.533
				c-3.562-1.862-3.699-3.201-0.628-5.859c0.392-0.339,0.731-0.981,1.385-0.444c0.481,0.395,0.496,0.858,0.306,1.488
				C52.205,616.293,52.303,618.091,52.842,619.873z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(914)} fill={props.fillColors[914]} d="M442.654,584.621c-1.863-0.685-1.958-1.777-0.776-3.217
				c0.738-0.899,1.157-2.062,2.231-2.676c0.563-0.692,1.276-1.953,2.067-1.336c1.057,0.826,0.458,2.084-0.373,3.076
				c-0.995,1.188-2.328,2.087-3.023,3.529C442.935,584.245,442.963,584.467,442.654,584.621z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(915)} fill={props.fillColors[915]} d="M240.325,732.969c1.406-0.754,1.453-2.696,2.694-2.247
				c1.5,0.543,0.688,2.067,0.521,3.264c-0.141,1.017,0.011,2.07-0.044,3.104c-0.031,0.568,0.272,1.413-0.673,1.522
				c-0.767,0.089-1.632,0.266-2.253-0.424c-0.675-0.748,0.054-1.302,0.359-1.886c0.252-0.485,0.599-0.92,0.879-1.391
				C242.497,733.757,243.194,732.584,240.325,732.969z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(916)} fill={props.fillColors[916]} d="M235.452,623.961c-1.896,0.098-1.4-1.742-1.992-2.642
				c-0.44-0.669,0.639-1.024,1.214-1.401c0.504-0.331,1.185-0.454,1.453-1.101l-0.082,0.066c0.916-0.174,1.831-0.347,2.746-0.521
				c0.642,2.968-1.808,4.072-3.424,5.68L235.452,623.961z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(917)} fill={props.fillColors[917]} d="M491.004,574.008c-2.081,0.128-3.306-1.707-2.188-3.598
				c0.626-1.058,1.925-0.03,2.925-0.2c1.458-0.248,2.117,0.414,2.033,1.975C493.687,573.832,493.782,573.837,491.004,574.008z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(918)} fill={props.fillColors[918]} d="M88.303,586.938c1.314,0.046,2.025,0.999,2.795,1.861
				c-0.292,0.79-0.998,1.161-1.63,1.606c-1.725,0.546-2.809-0.281-3.581-1.708c-0.481-0.889-0.908-1.806-1.477-2.944
				C86.033,585.743,87.093,586.608,88.303,586.938z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(919)} fill={props.fillColors[919]} d="M477.223,613.188c1.532,1.232,3.058,2.475,4.601,3.693
				c0.462,0.365,0.834,0.755,0.683,1.382c-0.186,0.772-0.87,0.53-1.366,0.58c-3.984,0.399-4.426-0.036-4.125-4.05
				c0.041-0.539,0.169-1.073,0.245-1.611C477.249,613.179,477.223,613.188,477.223,613.188z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(920)} fill={props.fillColors[920]} d="M442.654,584.621c0.042-0.207,0.084-0.415,0.126-0.623
				c0.722-0.701,1.527-1.335,2.148-2.115c1.542-1.939,2.98-1.531,4.432,0.055c-1.756,1.083-2.976,2.714-4.374,4.156
				C443.997,585.938,443.31,585.305,442.654,584.621z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(921)} fill={props.fillColors[921]} d="M247.033,749.686c-0.767,1.624-2.424,1.918-3.233,1.769
				c-1.598-0.297-1.509-2.263-1.644-3.52c-0.21-1.966,1.979-1.251,2.642-1.263C246.684,746.64,245.615,749.104,247.033,749.686z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(922)} fill={props.fillColors[922]} d="M143.217,571.224c-1.907,0.76-3.713,2.069-5.932,0.819
				c-0.905-0.51-1.146-0.799-0.292-1.68c1.853-1.911,4.085-1.306,6.145-0.926c1.398,0.258-0.304,1.22-0.01,1.875L143.217,571.224z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(923)} fill={props.fillColors[923]} d="M29.029,703.099c2.026-0.96,3.44-2.718,5.206-4.019
				c0.553-0.407,1.227-1.218,1.719-0.739c0.59,0.572,0.16,1.46-0.491,2.063c-0.402,0.373-0.817,0.769-1.096,1.233
				c-0.669,1.107-1.261,2.263-1.884,3.398c-1.135-0.665-2.27-1.33-3.417-1.983C29.054,703.064,29.029,703.099,29.029,703.099z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(924)} fill={props.fillColors[924]} d="M414.582,606.458c-2.457,0.954-4.434,1.844-6.929,0.501
				c-1.224-0.66-1.15-1.524-1.117-2.198c0.041-0.843,1.155-0.587,1.698-0.444C410.251,604.845,412.418,604.958,414.582,606.458z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(925)} fill={props.fillColors[925]} d="M92.114,586.445c-1.335-1.059-2.347-2.52-3.97-3.204
				c-0.76-0.61-1.924-1.064-0.774-2.353c1.113-1.247,1.785-0.27,2.5,0.447c1.279,1.283,2.707,2.43,3.596,4.073
				c0.28,0.517,0.657,1.007,0.093,1.444C93.006,587.281,92.559,586.73,92.114,586.445z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(926)} fill={props.fillColors[926]} d="M247.045,556.283c0.753,0.474,2.621-0.943,2.594,1.437
				c-0.017,1.496-0.171,2.63-2.129,1.794c-0.413-0.177-0.942-0.057-1.388-0.181c-0.817-0.229-2.346,0.628-2.147-1.241
				c0.148-1.397,0.794-2.316,2.435-1.814C246.554,556.322,246.724,556.283,247.045,556.283z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(927)} fill={props.fillColors[927]} d="M363.554,507.521c-0.097-0.937-0.841-1.268-1.535-1.653
				c-1.855-1.032-0.622-2.637-0.282-3.646c0.314-0.933,1.889-0.581,2.734-0.001c1.397,0.957,0.449,2.49,0.644,3.753
				c0.187,1.217-0.696,1.422-1.645,1.462C363.471,507.438,363.554,507.521,363.554,507.521z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(928)} fill={props.fillColors[928]} d="M392.005,318.208c-0.34,1.901-1.396,3.08-2.67,4.005
				c-0.528,0.384-1.489,1.014-2.056,0.189c-0.411-0.599-0.676-1.217-0.269-2.382C388.11,316.875,390.28,318.812,392.005,318.208z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(929)} fill={props.fillColors[929]} d="M287.268,407.921c-0.402,1.22,0.921,3.511-1.043,3.914
				c-0.984,0.201-0.769-2.372-1.382-3.595c-0.308-0.615-0.608-1.416-0.47-2.034c0.269-1.195,1.297-2.339,2.336-2.348
				c1.073-0.009,0.396,1.416,0.54,2.194C287.347,406.59,287.268,407.16,287.268,407.921z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(930)} fill={props.fillColors[930]} d="M537.744,630.911c-1.022-0.525-0.683-1.046,0.024-1.528
				c1.475-1.007,2.961-1.996,4.445-2.994c0.002,0-0.063,0.05-0.061,0.049c0.604,0.002,1.146-0.88,1.722-0.291
				c0.562,0.574-0.068,1.204-0.289,1.751c-0.758,1.874-3.283,3.142-5.854,3.015c0.019,0.119,0.037,0.239,0.056,0.358
				C537.773,631.151,537.759,631.031,537.744,630.911z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(931)} fill={props.fillColors[931]} d="M301.213,416.95c2.966,3.718,2.966,3.718-1.173,4.955
				c-1.171,0.35-2.342,0.699-4.05,1.21C298.053,420.896,299.905,419.274,301.213,416.95z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(932)} fill={props.fillColors[932]} d="M103.467,742.99c-0.241-1.105-1.028-2.503-0.623-3.263
				c1.333-2.489,2.649-5.049,4.827-7.076C106.863,736.323,104.731,739.467,103.467,742.99z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(933)} fill={props.fillColors[933]} d="M397.182,623.521c-2.01,0.064-1.498-1.603-1.815-2.678
				c-0.369-1.25,0.789-1.084,1.391-1.383c0.556-0.276,1.162-0.584,1.76-0.614c0.802-0.042,1.875-1.235,2.278-0.192
				c0.368,0.949-0.084,2.253-1.185,3.026c-0.86,0.604-2.173,0.637-2.513,1.922L397.182,623.521z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(934)} fill={props.fillColors[934]} d="M136.035,576.269c-0.613,0.886-1.088,1.604-1.595,2.298
				c-0.959,1.313-1.982,1.235-2.731-0.227c-0.25-0.486-0.338-1.056-0.586-1.543c-0.332-0.652-0.793-1.301-0.022-1.904
				c0.703-0.552,1.488-0.529,2.176,0.061C134.011,575.584,134.917,575.837,136.035,576.269z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(935)} fill={props.fillColors[935]} d="M317.748,620.957c0.247,0.765-0.288,0.785-0.882,0.544
				c-1.171-0.476-2.312-1.021-3.475-1.52c-1.712-0.734-0.213-1.915-0.285-2.835c-0.095-1.191,0.942-0.455,1.579-0.379
				C317.129,617.059,318.068,618.49,317.748,620.957z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(936)} fill={props.fillColors[936]} d="M377.909,564.701c-0.018-0.307-0.099-0.626-0.041-0.917
				c0.53-2.682,0.976-2.854,3.239-1.327c0.184,0.124,0.447,0.129,0.673,0.189l-0.085-0.087c0.308,1.012,2.326,1.463,1.222,2.841
				c-0.892,1.113-2.174,0.906-3.333,0.015c-0.469-0.36-1.121-0.48-1.689-0.71c0.036,0.129,0.072,0.258,0.109,0.387
				C377.972,564.962,377.94,564.832,377.909,564.701z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(937)} fill={props.fillColors[937]} d="M211.455,618.731c-0.604-2.586,1.104-3.436,2.427-3.412
				c1.526,0.026,1.629,2.192,1.548,3.155c-0.114,1.352-1.932,1.551-3.151,1.701C210.738,620.365,211.549,618.965,211.455,618.731z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(938)} fill={props.fillColors[938]} d="M323.309,552.6c-1.427,0.24-2.139-0.337-1.481-1.34
				c0.887-1.354,1.234-2.911,2.038-4.284c0.548-0.936,1.29-0.91,2.002-0.72c1.319,0.354,0.176,1.187,0.088,1.665
				c-0.236,1.281-1.097,2.347-0.93,3.842C325.194,553.269,323.635,552.157,323.309,552.6z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(939)} fill={props.fillColors[939]} d="M299.451,612.661c0.304,0.193,0.609,0.386,0.907,0.578
				c0.441,0.149,0.878,0.382,1.336,0.438c2.742,0.333,3.292,1.969,2.641,4.345C300.69,617.339,299.042,615.531,299.451,612.661z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(940)} fill={props.fillColors[940]} d="M473.107,620.342c0.874,0.871,1.79,1.696,2.602,2.614
				c0.778,0.883,0.114,1.377-0.607,1.868c-0.454,0.31-0.777,0.806-1.217,1.143c-0.946,0.726-1.833,0.464-1.881-0.669
				c-0.062-1.464,0.278-2.948,0.491-4.418C472.52,620.706,472.832,620.574,473.107,620.342z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(941)} fill={props.fillColors[941]} d="M155.201,684.539c-5.22,2.322-6.103,2.119-8.167-1.813
				C149.75,683.188,152.494,682.977,155.201,684.539z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(942)} fill={props.fillColors[942]} d="M35.293,605.717c0.506-1.286,0.971-2.451,1.423-3.62
				c0.329-0.849,0.905-1.154,1.829-1.058c1.121,0.117,0.886,0.899,0.922,1.584C39.653,606.195,39.654,606.195,35.293,605.717z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(943)} fill={props.fillColors[943]} d="M363.955,528.998c0.725,2.804,0.219,5.651,0.233,8.475
				c0.007,1.295-1.019,1.353-1.832,0.666c-1.007-0.85-1.17-1.909-0.466-3.164c1.046-1.867,1.821-3.862,2.118-6.01L363.955,528.998z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(944)} fill={props.fillColors[944]} d="M211.211,606.947c2.521,2.203,2.232,4.733,2.756,6.902
				c0.158,0.652-1.073,1.058-1.843,1.145c-0.887,0.101-1.209-0.525-1.283-1.34C210.656,611.587,211.636,609.634,211.211,606.947z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(945)} fill={props.fillColors[945]} d="M118.114,566.312c0.923,1.104,0.824,2.039-0.405,2.862
				c-0.949,0.637-1.387,1.964-2.819,2.109c-0.346-2.139,0.202-4.14,0.54-6.199c1.186-0.116,1.466,1.712,2.766,1.313
				C118.195,566.396,118.114,566.312,118.114,566.312z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(946)} fill={props.fillColors[946]} d="M434.265,574.481c-1.555,0.45-3.137,0.085-4.695,0.186
				c-0.695,0.045-1.489,0.247-1.459-0.926c0.024-0.931,0.213-1.732,1.216-2.043C431.779,570.938,433.429,571.859,434.265,574.481z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(947)} fill={props.fillColors[947]} d="M64.717,698.99c-0.257,1.806-2.348,3.63-3.827,3.249
				c-1.456-0.374-0.585-1.982-1.36-2.742c1.389-1.021,3.083-1.254,4.675-1.69C64.742,697.659,65.142,698.303,64.717,698.99z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(948)} fill={props.fillColors[948]} d="M317.479,616.744c-0.366,0-0.537,0.042-0.675-0.007
				c-3.187-1.139-3.688-3.088-1.53-5.731c0.434-0.532,0.936-0.506,1.482-0.493c0.62,0.015,0.928,0.495,0.755,0.948
				C316.844,613.21,317.462,614.99,317.479,616.744z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(949)} fill={props.fillColors[949]} d="M229.646,750.215c1.63,5.611-3.415,6.95-4.857,10.264
				c-0.206-0.111-0.412-0.222-0.617-0.332C225.896,757.015,227.623,753.884,229.646,750.215z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(950)} fill={props.fillColors[950]} d="M287.494,573.806c0.021-1.96-1.088-4.303,1.391-5.142
				c1.757-0.596,1.826,1.75,3.097,2.286C290.714,572.426,288.789,572.547,287.494,573.806z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(951)} fill={props.fillColors[951]} d="M572.604,569.731c-0.262-0.323-0.523-0.646-0.783-0.966
				c-0.751-0.74-1.62-1.411-0.115-2.343c0.79-0.49-0.938-0.436-0.362-1.177c0.678-0.871,0.318-2.377,1.863-3.043
				c0.389,2.416,0.89,4.693,1.083,6.996C574.446,571.059,573.278,569.868,572.604,569.731z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(952)} fill={props.fillColors[952]} d="M484.461,573.766c-0.455,1.652-2.474,1.002-3.777,1.618
				c-0.577,0.273-0.843-0.008-0.841-0.633c0.004-1.851,1.657-3.921,3.199-3.976C483.795,570.749,484.441,571.893,484.461,573.766z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(953)} fill={props.fillColors[953]} d="M223.142,754.39c0.623,0.483,1.992-0.718,2.183,0.788
				c0.154,1.204-2.1,2.81-3.459,2.983c-1.947,0.248-1.962-1.435-1.913-2.266C220.066,753.976,221.844,754.607,223.142,754.39z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(954)} fill={props.fillColors[954]} d="M455.508,575.825c-1.589-4.156,2.001-5.837,3.07-8.538
				c0.165-0.416,0.675-0.225,1.037-0.005c0.46,0.278,0.382,0.686,0.19,1.026C458.447,570.72,457.062,573.116,455.508,575.825z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(955)} fill={props.fillColors[955]} d="M307.245,723.85c-2.525,4.177-3.625,4.139-7.058,0.023
				C302.546,723.529,304.793,723.612,307.245,723.85z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(956)} fill={props.fillColors[956]} d="M154.331,455.538c-0.351-2.682,2.105-6.028,4.37-6.239
				c0.858-0.08,0.99,0.51,0.818,1.043c-0.436,1.353-1.107,3.008-2.369,3.285C155.865,453.91,155.165,454.621,154.331,455.538z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(957)} fill={props.fillColors[957]} d="M421.965,575.263c0.135,0.721-0.454,0.786-0.843,0.971
				c-0.701,0.333-1.442,0.58-2.149,0.901c-0.596,0.271-1.077,1.221-1.829,0.545c-0.727-0.653-0.148-1.429,0.095-2.165
				c0.562-1.699,2.182-1.976,3.49-2.264C422.246,572.916,421.073,574.879,421.965,575.263z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(958)} fill={props.fillColors[958]} d="M148.285,489.422c-3.112-0.494-5.332-2.551-7.794-4.167
				c-0.344-0.225-0.51-0.639-0.356-1.057c0.268-0.726,0.74-0.187,1.158-0.191C145.059,483.965,145.772,487.843,148.285,489.422z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(959)} fill={props.fillColors[959]} d="M308.78,565.897c1.79,0.056,2.52,0.761,2.446,2.754
				c-0.094,2.553,0.667,5.107,0.276,8.417c-0.871-3.787-1.584-6.93-2.848-9.902c-0.151-0.355,0.122-0.892,0.2-1.346
				C308.856,565.817,308.78,565.897,308.78,565.897z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(960)} fill={props.fillColors[960]} d="M302.979,336.799c-0.86-1.904-1.761-3.791-2.546-5.726
				c-0.16-0.396-0.734-1.087,0.04-1.442c0.69-0.316,1.501-0.629,2.216-0.551c0.605,0.066,0.493,0.933,0.409,1.432
				C302.743,332.616,303.952,334.649,302.979,336.799z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(961)} fill={props.fillColors[961]} d="M351.571,762.593c0.414,0.021,0.96-0.102,0.864,0.48
				c-0.135,0.823-0.132,1.883-0.935,2.316c-1.025,0.553-2.19,0.873-3.323,1.196c-1.012,0.289-1.028-0.57-1.329-1.211
				c-0.348-0.738-0.293-1.285,0.441-1.535C348.695,763.36,350.142,763.002,351.571,762.593z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(962)} fill={props.fillColors[962]} d="M83.63,595.706c0.386,0.082,0.773,0.241,1.157,0.231
				c0.846-0.021,2.064-0.844,2.145,0.781c0.045,0.909,1.3,2.089-0.125,2.744c-1.053,0.483-2.567,0.7-3.399-0.114
				c-0.614-0.602-1.293-1.897-0.332-3.011c0.208-0.241,0.433-0.469,0.65-0.702L83.63,595.706z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(963)} fill={props.fillColors[963]} d="M384.919,560.179c2.412,1.007,2.943,3.185,4.59,4.35
				c0.82,0.58-0.681,1.672-0.714,2.684c-0.013,0.398-0.529,0.872-1.07,0.643c-0.667-0.282-0.601-0.983-0.774-1.591
				C386.394,564.33,385.688,562.439,384.919,560.179z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(964)} fill={props.fillColors[964]} d="M62.108,711.643c0.387-2.252,0.674-3.944,0.97-5.635
				c0.148-0.848,0.354-1.668,1.517-1.126c0.961,0.448,1.712,1.356,1.147,2.245C64.832,708.556,64.15,710.228,62.108,711.643z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(965)} fill={props.fillColors[965]} d="M364.938,750.751c-1.505-3.523-1.023-4.671,2.409-5.897
				c0.438-0.156,0.909-0.491,1.29-0.034c0.337,0.405,0.171,0.862-0.135,1.289C367.373,747.688,366.363,749.36,364.938,750.751z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(966)} fill={props.fillColors[966]} d="M401.353,612.406c-0.028,0.43-0.056,0.858-0.083,1.287
				c0.163,0.275,0.227-0.094,0.109,0.2c-0.082,0.207-0.253,0.177-0.359-0.029c-2.134,0.726-4.406,0.216-6.573,0.73
				c-0.638,0.151-1.284-0.06-1.251-0.98c0.033-0.896,0.666-0.983,1.327-0.998c2.304-0.051,4.607-0.083,6.911-0.123
				C401.434,612.491,401.353,612.406,401.353,612.406z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(967)} fill={props.fillColors[967]} d="M554.088,634.841c0.565-2.429,0.206-4.857,0.396-7.708
				c1.987,1.812,2.636,3.962,2.818,5.985C557.395,634.132,555.342,634.224,554.088,634.841z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(968)} fill={props.fillColors[968]} d="M321.331,563.251c-0.333,0.097-0.666,0.194-0.999,0.292
				c-1.042,0.041-1.444-0.518-1.353-1.488c0.084-0.889,0.239-1.746,1.333-1.967c1.062-0.214,2.118-0.445,3.134-0.66
				C324.368,561.662,323.172,562.643,321.331,563.251z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(969)} fill={props.fillColors[969]} d="M249.368,621.173c-0.915,0-1.777,0.008-2.639-0.004
				c-0.412-0.005-0.989-0.18-0.798-0.599c0.785-1.723-0.185-3.805,1.278-5.583C248.38,616.922,249.144,618.862,249.368,621.173z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(970)} fill={props.fillColors[970]} d="M256.496,616.182c1.158,2.041,2.197,3.751,3.104,5.527
				c0.429,0.842,0.496,1.415-0.956,1.113c-1.872-0.389-2.82-1.171-2.608-3.144C256.145,618.685,255.842,617.651,256.496,616.182z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(971)} fill={props.fillColors[971]} d="M537.731,630.912c0.784,1.1,1.969,0.817,3.036,0.822
				c0.973,0.005,1.906,0.188,1.803,1.307c-0.093,0.999-0.235,2.13-1.857,2.176c-2.727,0.076-3.266-0.593-2.967-4.307
				C537.744,630.911,537.731,630.912,537.731,630.912z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(972)} fill={props.fillColors[972]} d="M555.225,574.762c-0.547-2.166-1.226-4.31-1.595-6.505
				c-0.234-1.4,1.567-1.287,2.189-2.718c1.032,3.468-0.755,6.315,0.116,9.228C555.698,574.765,555.461,574.763,555.225,574.762z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(973)} fill={props.fillColors[973]} d="M156.225,578.63c0.32-2.584,3.002-4.285,5.093-3.331
				c0.641,0.292,0.569,0.882,0.597,1.44c0.039,0.76-0.233,1.188-1.053,1.277C159.322,578.185,157.79,578.419,156.225,578.63z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(974)} fill={props.fillColors[974]} d="M428.101,632.103c0.967,1.225,3.696-0.715,4.003,1.954
				c0.123,1.068-1.209,1.596-2.087,1.344c-1.421-0.406-3.812,0.396-3.718-2.391C426.336,631.914,427.219,632.154,428.101,632.103z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(975)} fill={props.fillColors[975]} d="M574.228,253.435c0.058-0.131,0.121-0.259,0.189-0.386
				c1.624-1.288,1.935,0.634,2.729,1.2c0.688,0.491,1.352,1.641,0.81,2.233c-0.876,0.957-2.295,0.176-3.477,0.284
				c-0.992,0.091-0.893-0.584-0.707-1.269C573.954,254.819,574.078,254.123,574.228,253.435z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(976)} fill={props.fillColors[976]} d="M107.092,596.768c0.559,0.648,0.386,1.197,0.028,1.627
				c-1.35,1.617-1.974,3.562-2.594,5.514c-0.267,0.84-0.512,1.906-1.676,1.344c-0.965-0.467-0.703-1.574-0.283-2.269
				c0.973-1.608,2.114-3.116,3.214-4.646C106.174,597.791,106.644,597.3,107.092,596.768z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(977)} fill={props.fillColors[977]} d="M168.423,602.332c0.777,0.489,2.542-0.752,2.668,0.773
				c0.145,1.751-1.546,1.871-2.975,1.797c-0.477-0.025-0.967-0.088-1.43-0.008c-1.198,0.207-1.558-0.508-1.632-1.459
				c-0.089-1.157,0.783-1.095,1.557-1.103C167.169,602.328,167.726,602.332,168.423,602.332z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(978)} fill={props.fillColors[978]} d="M464.526,568.102c-0.098,2.217,0.987,4.334,0.099,6.613
				c-0.362,0.927-1.331,2.583,1.022,2.737c0.487,0.031,0.32,0.341,0.224,0.668c-0.216,0.726-0.746,0.699-1.318,0.668
				c-0.625-0.034-1.111-0.239-1.106-0.959c0.014-2.068-0.201-4.183,0.172-6.187C463.833,570.494,463.489,569.13,464.526,568.102z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(979)} fill={props.fillColors[979]} d="M401.16,569.945c1.271-0.337,2.923,0.431,3.259-1.673
				c0.106-0.671,0.794-0.094,1.057,0.219c0.503,0.597,0.122,1.179-0.159,1.751c-0.513,1.04,0.307,2.973-1.835,2.913
				C401.368,573.098,401.633,571.282,401.16,569.945z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(980)} fill={props.fillColors[980]} d="M28.785,582.178l0.102-0.083c-1.387,0.3-2.286,1.375-3.331,2.184
				c-0.965,0.749-1.558,0.468-2.148-0.38c0.039-0.256,0.078-0.508,0.117-0.76l0.051-0.053c0.156-0.177,0.319-0.515,0.465-0.507
				c3.391,0.176,4.22-2.562,5.392-5.021C30.615,580.07,30.615,580.07,28.785,582.178z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(981)} fill={props.fillColors[981]} d="M381.78,562.646c-0.104-0.854-0.908-1.022-1.45-1.41
				c-0.994-0.711-1.508-1.401-0.85-2.704c0.772-1.532,1.58-0.02,2.342-0.181l-0.083-0.084c0.936,1.795,2.651,3.271,2.357,5.634
				c-1.116,0.01-1.429-1.257-2.401-1.342L381.78,562.646z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(982)} fill={props.fillColors[982]} d="M246.507,751.686c1.528-0.129,1.894,0.78,1.853,1.777
				c-0.082,1.984-1.95,1.622-3.045,1.827c-1.374,0.257-1.534-1.398-1.502-2.181C243.886,751.25,245.991,752.701,246.507,751.686z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(983)} fill={props.fillColors[983]} d="M503.122,633.16c0.346-0.649-0.886-1.796,0.396-2.179
				c1.265-0.379,1.42,0.94,1.892,1.697c0.166,0.268,0.383,0.541,0.432,0.836c0.119,0.707,1.193,1.271,0.522,2.033
				c-0.691,0.786-1.793,0.701-2.676,0.445c-1.045-0.303-0.415-1.339-0.559-2.043C503.083,633.723,503.122,633.478,503.122,633.16z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(984)} fill={props.fillColors[984]} d="M209.361,612.147c0.33,2.099-0.77,4.105,0.26,5.99
				c0.277,0.505,0.011,0.798-0.328,0.968c-0.54,0.271-1.086-0.135-1.129-0.539c-0.129-1.205-0.931-1.949-1.592-2.813
				c-0.535-0.699-0.431-1.32,0.333-1.816C207.691,613.426,208.143,612.473,209.361,612.147z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(985)} fill={props.fillColors[985]} d="M550.912,575.233c-0.883-0.438-2.631,0.776-2.621-1.355
				c0.009-1.855,1.557-1.573,2.716-1.733c1.382-0.19,2.416,0.319,2.428,1.817C553.449,575.888,551.836,574.869,550.912,575.233z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(986)} fill={props.fillColors[986]} d="M83.725,595.636c-1.937-1.272-2.194-3.237-0.497-4.102
				c0.497-0.253,1.263-0.974,1.631-0.393c0.646,1.02,1.134,2.24,1.29,3.429c0.081,0.612-0.971,0.584-1.6,0.604
				c-0.403,0.012-0.797,0.039-0.919,0.533C83.63,595.706,83.725,595.636,83.725,595.636z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(987)} fill={props.fillColors[987]} d="M326.722,618.703c-0.073,1.475-0.729,2.069-1.994,2.046
				c-1.276-0.023-2.554,0.018-3.83-0.012c-0.695-0.016-1.513,0.094-1.438-1.058c0.058-0.907,0.665-0.971,1.332-0.974
				C322.765,618.698,324.738,618.703,326.722,618.703z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(988)} fill={props.fillColors[988]} d="M507.057,627.417c0.437,0.144,0.873,0.287,1.31,0.431
				c0.239,0.203,0.446,0.5,0.722,0.593c1.011,0.341,1.141,1.144,0.853,1.917c-0.337,0.902-1.084,0.18-1.651,0.186
				c-0.394,0.005-0.787,0.033-1.18,0.051l0.078,0.031c-1.32-0.989-2.926-1.719-3.358-3.665
				C504.96,627.12,506.008,627.269,507.057,627.417z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(989)} fill={props.fillColors[989]} d="M475.493,620.793c-2.812-1.158-3.365-0.836-0.883-7.068
				C476.011,616.066,475.624,618.402,475.493,620.793z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(990)} fill={props.fillColors[990]} d="M189.557,616.467c-1.559,2.387-4.099,2.055-6.127,2.788
				c-0.79,0.286-1.081-0.549-1.274-1.123c-0.307-0.914,0.563-0.659,0.979-0.905C185.103,616.059,187.201,616.295,189.557,616.467z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(991)} fill={props.fillColors[991]} d="M452.9,458.046c-0.818-0.01-1.324-0.236-0.97-1.145c0.56-1.435,1.363-2.702,2.633-3.62
				c0.567-0.412,1.261-0.536,1.748-0.036c0.524,0.538,0.292,1.274-0.233,1.688C454.901,455.857,454.809,457.909,452.9,458.046z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(992)} fill={props.fillColors[992]} d="M528.19,629.151c-0.381-0.486-1.104-0.959-0.874-1.571
				c0.301-0.807,1.22-0.466,1.89-0.529c0.395-0.038,0.798-0.022,1.194,0.004c0.692,0.047,1.869-0.103,1.865,0.565
				c-0.006,1.026-0.428,2.254-1.104,3.011c-0.56,0.626-1.107-0.561-1.711-0.847c-0.358-0.17-0.681-0.413-1.02-0.623L528.19,629.151z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(993)} fill={props.fillColors[993]} d="M365.727,623.89c0.208,0.222,0.379,0.568,0.632,0.645
				c2.458,0.743,3.583,2.102,2.177,4.656c-2.813,0.033-3.149-2.262-3.739-4.29c-0.246-0.847,0.49-0.814,1.007-0.933
				C365.8,623.965,365.727,623.89,365.727,623.89z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(994)} fill={props.fillColors[994]} d="M293.994,613.341c-2.831,0.248-0.944-2.405-1.936-3.377
				c-0.421-0.412,0.2-1.166,0.933-1.312c0.499-0.101,3.163,2.976,3.287,3.761C296.4,613.187,296.4,613.187,293.994,613.341z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(995)} fill={props.fillColors[995]} d="M340.263,624.02c-3.734-0.469-7.469-0.939-11.203-1.405
				c-0.36-0.045-0.657-0.226-0.56-0.582c0.071-0.255,0.399-0.646,0.586-0.632C332.889,621.693,336.789,621.532,340.263,624.02z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(996)} fill={props.fillColors[996]} d="M345.554,518.321c-1.446-2.527-1.218-2.959,1.599-2.98
				c1.49-0.012,2.98-0.002,4.807-0.002c-1.83,2.095-3.701,2.994-6.14,2.463c-0.054-0.012-0.183,0.323-0.271,0.503
				C345.551,518.312,345.554,518.321,345.554,518.321z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(997)} fill={props.fillColors[997]} d="M359.799,618.279c1.896,2.449,2.413,4.853,3.414,7.114
				C359.797,625.126,359.281,624.14,359.799,618.279z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(998)} fill={props.fillColors[998]} d="M298.387,616.152c-1.165,0.847-2.511,0.793-3.856,0.795
				c-1.332,0.002-1.546-0.947-1.595-1.919c-0.058-1.129,0.897-0.916,1.559-0.947C296.178,614.004,297.93,613.866,298.387,616.152z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(999)} fill={props.fillColors[999]} d="M447.813,575.59c-1.559-3.572-1.292-4.272,2.374-6.698
				C451.149,571.809,448.474,573.16,447.813,575.59z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1000)} fill={props.fillColors[1000]} d="M86.751,561.804c-0.83-0.855-1.498-1.824-1.887-2.951
				c-0.214-0.62-1.148-1.309-0.513-1.821c0.496-0.399,1.507-0.201,2.289-0.194c0.883,0.008,1.39,0.623,1.253,1.392
				c-0.216,1.214,1.004,2.991-1.22,3.509C86.68,561.742,86.751,561.804,86.751,561.804z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1001)} fill={props.fillColors[1001]} d="M205.551,617.226c-5.656-2.292-5.73-2.387-5.039-6.63
				C202.516,612.642,204.124,614.77,205.551,617.226z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1002)} fill={props.fillColors[1002]} d="M157.783,588.572c1.574,0.592,3.53,4.04,3.031,5.214
				c-0.206,0.486-0.799,0.905-1.326,0.718c-0.778-0.277-1.717-0.55-1.714-1.686c0.001-0.396,0.008-0.79,0.008-1.184
				C157.784,590.637,157.783,589.64,157.783,588.572z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1003)} fill={props.fillColors[1003]} d="M371.533,567.847c-1.467,0.307-3.2,0.077-3.431-0.716
				c-0.461-1.588-0.194-3.418-0.052-5.132c0.045-0.543,0.924-0.547,1.097-0.105c0.336,0.859,1.178,1.941,0.613,2.66
				c-0.68,0.865-0.747,0.932,0.32,1.184C371.091,565.975,371.01,567.026,371.533,567.847z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1004)} fill={props.fillColors[1004]} d="M278.303,623.781c2.307-0.145,3.8,1.591,5.598,2.556c0.726,0.39,0.314,1.67-0.078,2.32
				c-0.43,0.713-0.876-0.24-1.34-0.354c-2.427-0.603-2.832-2.985-4.125-4.58C278.35,623.73,278.303,623.781,278.303,623.781z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1005)} fill={props.fillColors[1005]} d="M144.717,596.589c1.811,2.372,2.953,4.538,3.942,7.025
				C144.822,602.624,144.582,602.204,144.717,596.589z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1006)} fill={props.fillColors[1006]} d="M150.598,577.252c-0.551-0.957-1.599-1.212-2.43-1.779
				c-0.584-0.399-2.075-0.135-1.535-1.536c0.315-0.817,0.648-2.074,1.885-1.678c0.924,0.296,2.291,0.448,2.297,1.916
				c0.005,1.021-0.14,2.045-0.218,3.067l-0.183-0.018L150.598,577.252z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1007)} fill={props.fillColors[1007]} d="M550.534,571.844c-1.176-0.117-2.308,0.289-2.313-1.44
				c-0.006-1.895,1.33-1.884,2.496-2.017c1.618-0.183,1.64,1.292,1.765,2.244C552.734,572.559,550.971,571.287,550.534,571.844z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1008)} fill={props.fillColors[1008]} d="M557.316,607.679c1.41,0.486,2.821,0.974,4.504,1.555
				c-1.524,1.532-2.763,3.043-4.932,1.311l0.082,0.094c-0.119-0.1-0.26-0.178-0.37-0.283c-0.697-0.669-2.348-0.879-2.021-1.909
				c0.302-0.95,1.798-0.82,2.87-0.714L557.316,607.679z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1009)} fill={props.fillColors[1009]} d="M226.024,617.243c1.169,0.84,3.561,0.803,2.292,3.487
				c-1.396-0.6-2.768-1.374-4.241-1.766c-1.252-0.333-1.542-1.317-1.342-2.064c0.252-0.939,1.183,0.031,1.836-0.053
				c0.485-0.062,1.023,0.293,1.534,0.461C226.1,617.307,226.024,617.243,226.024,617.243z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1010)} fill={props.fillColors[1010]} d="M586.041,626.281c-1.377,0.448-2.136-0.732-3.229-0.857
				c-0.509-0.059-1.567-0.231-1.272-1.266c0.23-0.811,0.461-1.926,1.048-2.239c0.503-0.269,1.729,0.088,2.141,0.959
				C585.243,623.964,585.603,625.123,586.041,626.281z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1011)} fill={props.fillColors[1011]} d="M542.427,580.176c0.055,0.261,0.108,0.522,0.163,0.783
				c0.26,0.39,0.585,0.753,0.767,1.177c0.303,0.71,0.74,1.585-0.188,2.079c-0.907,0.483-1.115-0.593-1.598-1.022
				c-0.353-0.313-0.657-0.683-0.983-1.027c0.001-0.002,0.06,0.056,0.06,0.054c-0.364-0.406-0.704-0.836-1.096-1.212
				c-0.857-0.819-0.355-1.797,0.103-2.318c0.667-0.758,1.142,0.307,1.669,0.645C541.711,579.583,542.061,579.893,542.427,580.176z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1012)} fill={props.fillColors[1012]} d="M547.759,633.566c1.297,0.467,2.289,0.807,3.268,1.181
				c0.833,0.318,1.416,1.022,0.978,1.795c-0.344,0.605,0.02,1.917-1.048,1.766c-0.726-0.102-1.829-0.139-2.163-1.173
				C548.447,636.062,548.162,634.97,547.759,633.566z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1013)} fill={props.fillColors[1013]} d="M197.204,623.029c-1.49-0.596-2.801-1.06-4.057-1.645
				c-0.888-0.413-1.286-1.463-1.077-2.188c0.284-0.983,1.179-0.008,1.706,0.061C196.939,619.67,197.523,620.405,197.204,623.029z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1014)} fill={props.fillColors[1014]} d="M475.711,531.274c2.216-0.365,2.182,1.658,2.225,2.613
				c0.074,1.667-1.952,1.514-2.955,1.745c-1.183,0.271-0.716-1.274-0.731-1.992C474.227,532.611,473.629,530.991,475.711,531.274z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1015)} fill={props.fillColors[1015]} d="M508.227,399.376c-0.546-0.41-2.217,0.726-1.991-0.667
				c0.15-0.935,0.915-2.581,2.569-2.67c1.302-0.07,2.266,0.71,2.305,1.858C511.173,399.786,509.598,399.401,508.227,399.376z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1016)} fill={props.fillColors[1016]} d="M129.298,689.02c0.048,1.427-0.408,2.399-1.935,2.543
				c-1.463,0.137-2.065-1.274-1.888-1.957c0.341-1.315,1.89-1.871,3.104-2.135C129.691,687.229,129.079,688.502,129.298,689.02z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1017)} fill={props.fillColors[1017]} d="M338.1,624.483c-0.902,1.202-1.731,1.05-2.467,0.812
				c-2.035-0.656-4.108,0.077-6.144-0.257c-0.467-0.076-0.968-0.11-1.042-0.737c-0.076-0.652,0.382-0.85,0.871-0.867
				C332.232,623.334,335.082,623.711,338.1,624.483z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1018)} fill={props.fillColors[1018]} d="M165.14,568.45c0.358,3.477-0.346,6.274-1.022,9.267
				C161.978,574.409,163.292,571.647,165.14,568.45z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1019)} fill={props.fillColors[1019]} d="M17.197,732.162c1.309,0.71,3.352-0.159,4.004,1.621
				c0.521,1.422,0.586,3.01,0.937,4.997C19.905,736.731,18.837,734.248,17.197,732.162z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1020)} fill={props.fillColors[1020]} d="M487.057,544.552c2.396-0.653,4.176,0.505,6.059,0.24
				c0.576-0.08,1.017,0.428,1.04,1.067c0.026,0.703-0.551,0.944-1.071,1.08C490.699,547.558,489.15,546.039,487.057,544.552z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1021)} fill={props.fillColors[1021]} d="M236.385,565.992c-0.209,0.073-0.418,0.146-0.614,0.213
				c-0.802,0.207-1.98,0.439-2.279-0.219c-0.385-0.847-0.179-2.012-0.061-3.02c0.07-0.595,0.769-0.752,1.281-0.89
				c0.622-0.168,1.056,0.207,1.32,0.749C236.524,563.836,236.916,564.864,236.385,565.992z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1022)} fill={props.fillColors[1022]} d="M485.124,634.675c-0.232,1.692,2.549,3.606-0.274,4.973
				c-1.147,0.555-1.943-0.742-2.101-1.364C482.304,636.526,483.715,635.575,485.124,634.675z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1023)} fill={props.fillColors[1023]} d="M18.368,699.085c-0.198,0.521,0.594,1.889-0.872,1.972
				c-1.542,0.087-1.708-1.144-1.438-2.311c0.237-1.024-0.887-2.861,0.871-2.926C18.496,695.765,18.486,697.506,18.368,699.085z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1024)} fill={props.fillColors[1024]} d="M517.621,621.443c0.23-0.755,0.939-0.876,1.472-1.157c0.957-0.506,2.24-1.436,3-0.889
				c0.897,0.646-0.22,1.946-0.264,2.975c-0.055,1.278-0.692,1.335-1.48,0.739C519.482,622.456,518.262,622.388,517.621,621.443z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1025)} fill={props.fillColors[1025]} d="M246.211,414.562c-3.933-1.87-5.841-4.274-5.768-8.328
				C242.339,408.971,244.076,411.479,246.211,414.562z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1026)} fill={props.fillColors[1026]} d="M589.038,353.179c2.778,0.613,5.118,1.994,7.451,3.386
				c0.412,0.246,0.829,0.541,0.537,1.118c-0.239,0.474-0.691,0.577-1.19,0.635c-0.743,0.085-1.175-0.434-1.645-0.816
				C592.454,356.084,590.753,354.623,589.038,353.179z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1027)} fill={props.fillColors[1027]} d="M227.182,579.433c-0.454-0.575-0.908-1.15-1.361-1.726
				c-0.412-1.997,1.414-2.264,2.493-2.862c0.536-0.297,1.16,0.796,1.408,1.529c0.507,1.501-1.254,1.445-1.645,2.338
				C228.081,579.328,227.842,579.643,227.182,579.433z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1028)} fill={props.fillColors[1028]} d="M148.409,578.698c-0.295,0.148-0.59,0.297-0.884,0.446
				c-0.871-0.03-1.359-0.726-1.956-1.194c-0.583-0.458-0.422-1.03-0.13-1.691c0.354-0.801,0.874-0.863,1.504-0.611
				c1.235,0.494,2.438,1.064,3.655,1.604c0,0.001-0.001-0.01-0.001-0.009C149.867,577.728,149.138,578.213,148.409,578.698z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1029)} fill={props.fillColors[1029]} d="M399.4,191.245c-1.631,1.504-3.261,3.01-4.834,4.461
				c-0.31-1.004,0.165-2.722,0.725-3.731C395.944,190.796,398.05,191.916,399.4,191.245z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1030)} fill={props.fillColors[1030]} d="M338.799,630.654c-1.711,0.914-3.435,0.814-5.195,1.146
				C335.51,627.091,335.894,627.008,338.799,630.654z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1031)} fill={props.fillColors[1031]} d="M227.182,579.433c0.298-0.24,0.596-0.48,0.895-0.721
				c2.476-0.956,3.402-0.618,3.682,1.351c-1.181,0.699-2.356,1.396-3.53,2.09C227.88,581.244,227.531,580.339,227.182,579.433z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1032)} fill={props.fillColors[1032]} d="M90.73,571.342c0.411,0.27,0.823,0.539,1.231,0.807
				c0.784,0.741,0.549,1.676,0.481,2.579c0,0,0.082-0.156,0.082-0.157c-2.481,0.614-3.784-0.273-3.703-2.447
				C88.889,570.348,89.615,570.431,90.73,571.342z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1033)} fill={props.fillColors[1033]} d="M44.081,607.598c-0.581-0.647-1.162-1.295-1.829-2.039
				c2.316-1.264,4.424-2.745,7.589-3.287c-2.207,2.021-3.99,3.652-5.773,5.285c-0.112,0.043-0.168,0.064-0.168,0.064
				C43.845,607.647,43.905,607.64,44.081,607.598z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1034)} fill={props.fillColors[1034]} d="M365.062,564.304c-0.049,2.032-1.539,2.921-2.998,3.856
				c-0.763,0.49-1.175,0.005-1.613-0.473c-0.568-0.62,0.052-0.816,0.324-1.285C361.749,564.728,363.007,563.88,365.062,564.304z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1035)} fill={props.fillColors[1035]} d="M77.577,608.293c0.747,1.212,1.36,2.088,1.849,3.028
				c0.858,1.647,0.139,2.769-1.75,2.813c-0.402,0.009-0.981-0.073-0.788-0.572C77.495,612.002,76.767,610.289,77.577,608.293z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1036)} fill={props.fillColors[1036]} d="M546.406,579.606c-1.345-2.565-0.998-5.474-1.849-8.156
				c-0.194-0.613,0.089-1.076,0.825-1.146c0.919-0.088,1.031,0.461,1.028,1.182C546.398,574.192,546.406,576.899,546.406,579.606z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1037)} fill={props.fillColors[1037]} d="M154.548,587.794c-0.32-0.209-0.64-0.418-0.959-0.625
				c-0.397-0.729-0.797-1.458-1.224-2.237c1.053-0.454,2.287,0.154,3.097-0.997c0.335-0.477,0.791,0.026,0.803,0.613
				c0.017,0.788-0.008,1.582,0.084,2.362C156.596,588.984,155.601,588.425,154.548,587.794z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1038)} fill={props.fillColors[1038]} d="M461.812,572.896c0.528,2.303-0.223,2.95-1.734,2.731
				c-0.707-0.104-2.178,0.481-1.887-0.856c0.287-1.324,0.775-3.026,2.762-3.067C462.475,571.674,461.808,572.881,461.812,572.896z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1039)} fill={props.fillColors[1039]} d="M48.597,687.419c0.398,1.996-0.822,2.655-2.093,2.749
				c-1.292,0.096-1.262-1.465-1.308-2.173c-0.1-1.532,1.556-1.653,2.548-1.928C49.192,685.667,48.321,687.142,48.597,687.419z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1040)} fill={props.fillColors[1040]} d="M156.958,732.131c0.483,1.517,0.803,2.709,1.244,3.854
				c0.535,1.387-0.443,2.174-1.124,3.062c-0.396,0.514-0.766,0.344-1.031-0.239C155.228,737.009,155.521,734.248,156.958,732.131z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1041)} fill={props.fillColors[1041]} d="M78.791,581.066c1.908,1.416,3.039,3.443,4.146,5.494
				c0.561,1.038-0.252,0.927-0.836,1.137c-0.633,0.227-1.079,0.634-1.292-0.499C80.414,585.105,79.619,583.123,78.791,581.066z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1042)} fill={props.fillColors[1042]} d="M479.536,400.609c-1.354-0.746-2.309-1.441-3.375-1.824
				c-2.355-0.846-1.279-1.463,0.054-2.14c0.211-0.107,0.443-0.188,0.625-0.332c0.619-0.49,1.391-1.317,2.01-1.025
				c0.901,0.425-0.162,1.288-0.126,2.023C478.774,398.351,479.396,399.182,479.536,400.609z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1043)} fill={props.fillColors[1043]} d="M76.395,689.828c1.386-0.284,1.553,1.24,1.424,1.397
				c-0.84,1.025-0.208,2.663-1.418,3.396c-0.465,0.281-2.547-2.787-2.399-3.695C74.254,689.374,75.875,690.65,76.395,689.828z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1044)} fill={props.fillColors[1044]} d="M321.159,556.708c-0.766-0.249-0.85-0.822-0.751-1.495
				c0.296-2.026,2.078-1.904,3.406-2.137c1.124-0.196,0.862,1.007,0.757,1.586C324.225,556.558,322.31,556.005,321.159,556.708z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1045)} fill={props.fillColors[1045]} d="M45.803,571.923c-1.148-0.223-2.421,0.163-2.051-1.125
				c0.374-1.305,0.317-3.105,2.097-3.521c0.524-0.123,1.833,0.336,1.285,1.151C46.402,569.517,47.91,571.788,45.803,571.923z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1046)} fill={props.fillColors[1046]} d="M150.52,449.004c-0.432-3.992-0.048-7.174,1.173-10.558
				c1.035,2.914,0.888,3.001-0.268,5.599C150.853,445.333,150.88,446.889,150.52,449.004z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1047)} fill={props.fillColors[1047]} d="M165.418,678.225c2.192,1.285,3.977,2.331,6.138,3.599
				C166.979,682.636,166.979,682.636,165.418,678.225z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1048)} fill={props.fillColors[1048]} d="M428.796,571.289c0.525-1.484-0.483-3.779,2.005-3.717
				c1.505,0.037,1.918,2.231,1.698,2.877C431.902,572.203,430.145,570.966,428.796,571.289z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1049)} fill={props.fillColors[1049]} d="M470.872,621.059c0.036,2.625-1.841,3.074-3.391,3.805
				c-0.761,0.359-1.083-0.213-1.455-0.779c-0.515-0.782-0.105-1.249,0.499-1.495C467.881,622.038,469.286,621.607,470.872,621.059z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1050)} fill={props.fillColors[1050]} d="M540.435,571.242c-0.697,2.005-1.072,3.357-1.649,4.618
				c-0.271,0.59-0.057,2.09-1.152,1.546c-0.742-0.368-1.786-1.758-1.182-2.708C537.244,573.456,538.354,572.26,540.435,571.242z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1051)} fill={props.fillColors[1051]} d="M578.834,382.399c-1.444,0.482-2.446,0.939-3.496,1.119
				c-0.584,0.1-1.497,0.416-1.822-0.546c-0.214-0.63-0.179-1.387,0.516-1.63c0.689-0.241,0.914-1.699,2.063-0.772
				C576.809,381.144,577.621,381.597,578.834,382.399z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1052)} fill={props.fillColors[1052]} d="M36.26,720.14c0.514,5.537,2.478,10.79,3.111,16.262
				c-0.217,0.042-0.434,0.084-0.65,0.126c-0.554-2.714-1.177-5.415-1.643-8.144C36.611,725.651,35.875,722.94,36.26,720.14z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1053)} fill={props.fillColors[1053]} d="M280.866,582.759c0.525-1.03,1.049-2.06,1.574-3.09
				c0.79-0.378,1.534-1.319,2.462-0.279c1.131,1.268-0.486,1.509-0.89,2.157c-0.667,1.071-1.248,2.571-3.018,1.445
				C280.956,582.91,280.914,582.834,280.866,582.759z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1054)} fill={props.fillColors[1054]} d="M547.871,630.255c1.175-0.595,2.35-1.188,3.523-1.783
				c0.569,0.154,1.389-0.039,1.556,0.741c0.173,0.805-0.7,0.645-1.101,0.932c-0.695,0.5-1.361,1.043-2.052,1.55
				c-0.666,0.49-1.347,1.523-2.187,0.897C546.76,631.956,547.625,631.034,547.871,630.255z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1055)} fill={props.fillColors[1055]} d="M325.311,621.227c-0.593,1.514-3.97,2.894-5.166,2.15
				c-0.296-0.184-0.543-0.569-0.645-0.912c-0.183-0.617,0.317-0.704,0.776-0.904C321.461,621.043,322.052,620.956,325.311,621.227z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1056)} fill={props.fillColors[1056]} d="M166.125,608.621c0.758-1.124-1.706-2.686,0.761-3.26
				c0.913-0.213,2.721-0.74,2.894,0.516C170.077,608.042,168.037,608.231,166.125,608.621z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1057)} fill={props.fillColors[1057]} d="M123.68,758.98c-0.707-2.211-0.166-3.892,0.451-5.594
				c0.266-0.734,0.495-1.471,1.393-1.004c0.809,0.421,1.309,1.161,0.725,2.162C125.461,755.894,124.683,757.247,123.68,758.98z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1058)} fill={props.fillColors[1058]} d="M80.337,725.064c-0.779-0.837-1.193-1.271-1.595-1.715
				c-0.584-0.646-2.254-0.185-1.962-1.582c0.247-1.185,1.762-1.193,2.593-1.918c0.68-0.594,0.956-0.041,0.961,0.635
				C80.345,721.83,80.337,723.177,80.337,725.064z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1059)} fill={props.fillColors[1059]} d="M190.813,616.396c2.269,0.35,4.358,0.858,6.347,1.807
				c0.542,0.259,0.746,0.472,0.688,1.003c-0.042,0.39-0.371,0.611-0.654,0.524c-1.985-0.607-3.968-1.232-5.909-1.962
				C191.064,617.685,190.556,617.141,190.813,616.396z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1060)} fill={props.fillColors[1060]} d="M85.953,600.008c1.143,0.108,2.313,0.094,1.912,1.213
				c-0.602,1.675-2.497,1.175-3.804,1.649c-0.943,0.343-0.977-0.57-0.898-1.188C83.419,599.661,85.422,600.906,85.953,600.008z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1061)} fill={props.fillColors[1061]} d="M181.962,621.035c-1.745,0.362-3.316,0.688-5.071,1.052
				c0.645-1.727,1.784-2.925,2.783-4.45C180.463,618.808,181.139,619.813,181.962,621.035z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1062)} fill={props.fillColors[1062]} d="M536.184,626.018c0.071-0.88-0.08-1.782,0.889-1.533
				c1.233,0.318,2.934,0.626,3.147,1.923c0.164,0.994-1.433,1.682-2.442,1.759C536.148,628.292,536.781,626.538,536.184,626.018z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1063)} fill={props.fillColors[1063]} d="M555.629,301.663c1.558-1.792,2.928-3.371,4.289-4.938
				C560.786,299.889,559.01,302.164,555.629,301.663z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1064)} fill={props.fillColors[1064]} d="M209.437,610.043c0.037,1.064-2.881,3.836-3.872,3.823
				c-0.403-0.005-0.515-0.293-0.677-0.55c-0.298-0.472-0.242-0.853,0.217-1.223c1.099-0.888,2.128-1.866,3.263-2.703
				C209.28,608.717,209.392,609.462,209.437,610.043z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1065)} fill={props.fillColors[1065]} d="M254.38,565.714c-1.055-0.894-1.589-1.681-1.874-2.593
				c-0.186-0.596-0.313-1.314,0.458-1.669c0.857-0.395,1.734-0.908,2.697-0.743c0.481,0.082,0.243,0.618,0.105,0.998
				C255.312,562.97,254.887,564.242,254.38,565.714z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1066)} fill={props.fillColors[1066]} d="M480.474,619.119c0.607,0.277,1.805-0.436,1.924,0.625
				c0.114,1.017-0.707,1.633-1.869,1.482c-0.209-0.027-0.441,0.12-0.662,0.193c-0.956,0.316-1.973,0.837-2.436-0.654
				c-0.584-1.88,1.382-0.815,1.85-1.548C479.403,619.023,480.023,619.146,480.474,619.119z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1067)} fill={props.fillColors[1067]} d="M26.076,766.297c0-2.528,0-5.057,0-7.713
				C28.483,761.174,27.739,763.723,26.076,766.297z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1068)} fill={props.fillColors[1068]} d="M351.409,584.056c-0.107,0.183-0.214,0.366-0.317,0.541
				c-0.861,0.882-1.708,0.62-2.549-0.05c-0.001-0.002,0.085,0.079,0.084,0.076c-1.144-1.479,0.229-2.008,1.018-2.803
				c0.723-0.729,1.165-0.326,1.786,0.171C352.387,582.756,352.306,583.376,351.409,584.056z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1069)} fill={props.fillColors[1069]} d="M311.177,618.199c0.262,1.095-0.405,2.126-1.663,1.911
				c-1.396-0.238-0.818-1.907-1.202-2.905c-0.189-0.492-0.153-1.536,0.803-1.239C310.009,616.244,311.38,616.357,311.177,618.199z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1070)} fill={props.fillColors[1070]} d="M477.504,576.271c-0.156,0.142-0.401,0.256-0.454,0.43
				c-0.253,0.843,0.772,2.353-0.869,2.413c-1.33,0.049-1.07-1.534-1.393-2.403c-0.412-1.114-0.795-2.385,0.514-3.33
				c1.032,0.797,0.807,2.47,2.211,2.897C477.515,576.278,477.504,576.271,477.504,576.271z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1071)} fill={props.fillColors[1071]} d="M281.955,574.978c-0.207,0.109-0.439,0.344-0.616,0.307
				c-1.147-0.237-2.6,0.191-3.253-1.196c-0.256-0.543-0.205-1.33,0.473-1.671c1.326-0.668,2.4,0.355,3.463,0.806
				c1.001,0.424-0.24,1.195-0.156,1.846C281.866,575.066,281.955,574.978,281.955,574.978z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1072)} fill={props.fillColors[1072]} d="M79.097,605.6c2.2,1.312,3.534,3.008,2.423,5.809c-1.07-1.562-2.176-3.118-3.217-4.717
				c-0.763-1.17,0.559-0.693,0.86-1.028C79.157,605.657,79.097,605.6,79.097,605.6z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1073)} fill={props.fillColors[1073]} d="M205.42,604.722c1.802,1.795,1.738,2.69-0.318,3.333
				c-1.232,0.385-2.232-0.34-2.755-1.296c-0.696-1.271,0.815-1.148,1.352-1.637c0.52-0.474,1.191-0.284,1.805-0.318
				C205.501,604.802,205.42,604.722,205.42,604.722z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1074)} fill={props.fillColors[1074]} d="M397.098,623.602c0.806-0.32,1.611-0.641,2.611-1.038
				c0.029,0.933-0.302,1.652-0.53,2.424c-0.314,1.06-0.78,1.913-1.891,2.215c-0.432,0.117-0.772-0.152-0.832-0.624
				c-0.14-1.105-0.632-2.303,0.725-3.058C397.182,623.521,397.098,623.602,397.098,623.602z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1075)} fill={props.fillColors[1075]} d="M487.08,635.165c1.1,0.701,3.197-0.45,3.28,1.666c0.02,0.488,0.263,2.178-1.312,2.083
				C486.606,638.767,487.816,636.431,487.08,635.165z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1076)} fill={props.fillColors[1076]} d="M408.236,617.516c1.076-0.409,2.195-0.117,3.29-0.187
				c0.629-0.04,1.096,0.132,1.109,0.887c0.013,0.688-0.154,1.229-0.876,1.512C409.952,620.436,408.708,619.677,408.236,617.516z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1077)} fill={props.fillColors[1077]} d="M411.387,613.902c0.085-0.021,0.17-0.044,0.253-0.066
				c0.701-0.218,1.532-0.865,1.872,0.403c0.246,0.919-0.416,1.272-1.147,1.486c-1.35,0.395-3.434,1.755-3.939,0.489
				C407.737,614.494,410.27,614.615,411.387,613.902z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1078)} fill={props.fillColors[1078]} d="M251.296,768.467c-0.615-2.394,0.165-4.043,0.625-5.74
				c0.211-0.78,0.441-1.797,1.404-1.328c0.615,0.3,1.227,1.28,0.704,2.24C253.22,765.121,252.367,766.58,251.296,768.467z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1079)} fill={props.fillColors[1079]} d="M157.422,712.602c0.598-2.714,0.117-5.257,0.888-7.647
				C159.95,712.738,160.189,711.496,157.422,712.602z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1080)} fill={props.fillColors[1080]} d="M162.577,564.211c1.446,2.257,1.821,4.565,0.146,7.512
				c-0.439-2.835-1.7-5.094-0.116-7.483C162.603,564.233,162.577,564.211,162.577,564.211z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1081)} fill={props.fillColors[1081]} d="M140.359,608.761c-0.007-0.94-1.271-0.893-1.46-2.121c1.045,0,2.036-0.066,3.012,0.023
				c0.635,0.06,1.6-0.293,1.762,0.726c0.118,0.747,0.356,1.655-0.317,2.26c-0.946,0.851-1.308-0.406-1.958-0.655
				c-0.36-0.137-0.746-0.209-1.119-0.309C140.281,608.686,140.359,608.761,140.359,608.761z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1082)} fill={props.fillColors[1082]} d="M397.053,186.486c2.92-0.458,5.229-0.165,7.407,1.403
				C400.937,188.608,399.967,188.444,397.053,186.486z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1083)} fill={props.fillColors[1083]} d="M35.207,579.319c-1.023,0.057-0.658-0.621-0.572-1.115
				c0.275-1.562,0.575-3.12,1.385-4.668c1.725,3.838,1.569,4.734-0.897,5.844C35.123,579.379,35.207,579.319,35.207,579.319z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1084)} fill={props.fillColors[1084]} d="M252.574,619.323c-0.394-0.018-0.792-0.002-1.18-0.062
				c-1.168-0.177-1.155-1.29-1.179-1.985c-0.049-1.432,0.992-0.287,1.518-0.435c0.293-0.083,0.628-0.045,0.941-0.021
				c0.892,0.068,2.026-0.229,1.931,1.324C254.539,619.222,254.377,619.337,252.574,619.323z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1085)} fill={props.fillColors[1085]} d="M483.768,621.438c0.011,1.786-0.065,3.534,0.184,5.294
				c0.197,1.386-1.577,1.109-2.036,2.132C481.866,626.161,482.765,623.799,483.768,621.438z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1086)} fill={props.fillColors[1086]} d="M254.643,625.201c-0.188,0.803,0.475,2.409-1.003,2.348
				c-2.087-0.086-0.949-2.28-1.593-3.415c-0.449-0.791,0.408-1.103,1.227-1.136C254.972,622.928,254.599,624.132,254.643,625.201z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1087)} fill={props.fillColors[1087]} d="M152.322,603.528c-0.505-2.188,1.229-2.329,2.533-2.473
				c1.398-0.154,1.062,1.127,0.99,1.92c-0.125,1.378-1.053,1.595-2.181,1.187c-0.493-0.178-0.768-0.834-1.429-0.718L152.322,603.528z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1088)} fill={props.fillColors[1088]} d="M530.453,632.57c-0.659,0.82-0.152,2.433-1.911,2.591
				c-1.525,0.138-1.535-1.161-1.606-1.848c-0.178-1.714,1.642-0.938,2.436-1.542C529.648,631.562,530.529,631.648,530.453,632.57z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1089)} fill={props.fillColors[1089]} d="M410.384,567.754c0.262,1.142,0.083,1.876-1.307,1.662
				c-0.652-0.101-1.753,0.152-1.369-0.979c0.374-1.097,0.252-2.924,1.789-2.975C410.801,565.42,410.203,566.942,410.384,567.754z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1090)} fill={props.fillColors[1090]} d="M85.056,605.5c-0.86-0.333-2.155,0.16-1.8-0.937c0.546-1.682,2.45-1.19,3.76-1.627
				c0.537-0.179,1.472-0.102,1.334,0.823C88.008,606.057,85.655,604.118,85.056,605.5z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1091)} fill={props.fillColors[1091]} d="M155.425,607.554c0.126,0.994-0.208,1.612-1.376,1.661
				c-1.335,0.056-1.755-0.699-1.969-1.843c-0.263-1.402,0.741-1.11,1.443-1.12C154.403,606.239,155.593,605.893,155.425,607.554z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1092)} fill={props.fillColors[1092]} d="M331.904,769.801c-0.508-2.301-0.235-4.008,0.587-5.699
				c0.283-0.584,0.344-1.188,1.146-1.199c-0.012,0.872,1.525,1.103,0.951,2.173C333.794,766.56,332.93,768.005,331.904,769.801z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1093)} fill={props.fillColors[1093]} d="M457.066,386.487c-0.807-0.053-1.792,0.21-1.493-1.267
				c0.279-1.384,0.326-2.829,2.066-3.653c0.413,1.844,0.993,3.53-0.653,5C456.985,386.57,457.066,386.487,457.066,386.487z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1094)} fill={props.fillColors[1094]} d="M262.737,624.538c1.229-0.194,1.997,0.283,2.786,0.568
				c0.603,0.218,1.494,0.278,1.319,1.34c-0.1,0.609-0.389,1.043-0.993,1.201c-0.811,0.212-1.654,0.25-2.114-0.584
				C263.326,626.324,263.101,625.483,262.737,624.538z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1095)} fill={props.fillColors[1095]} d="M320.188,614.933c0.363,0.298,0.706,0.624,1.094,0.886
				c0.649,0.439,1.629,0.922,1.242,1.729c-0.397,0.829-1.584,0.205-2.392,0.506c-0.837,0.312-1.184-0.283-1.144-0.996
				c0.05-0.854-0.451-1.855,0.491-2.501c0.107-0.074,0.509,0.281,0.772,0.438C320.251,614.993,320.188,614.933,320.188,614.933z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1096)} fill={props.fillColors[1096]} d="M253.025,622.629c-1.694,0.521-1.676-1.011-1.899-1.93
				c-0.327-1.342,0.877-0.98,1.573-1.044c1.104-0.103,2.052-0.062,1.98,1.543C254.627,622.357,254.209,622.797,253.025,622.629z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1097)} fill={props.fillColors[1097]} d="M576.466,573.694c-1.565-2.894-0.491-4.612,2.445-5.413
				C578.63,570.263,577.571,571.989,576.466,573.694z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1098)} fill={props.fillColors[1098]} d="M408.559,622.146c-0.267-1.311,0.486-1.721,1.618-1.543
				c0.583,0.092,1.625-0.559,1.674,0.747c0.05,1.364-0.348,2.299-1.996,2.267C408.794,623.597,408.403,623.188,408.559,622.146z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1099)} fill={props.fillColors[1099]} d="M373.739,628.74c0,0-0.085-0.053-0.086-0.053c1.185,1.562,3.623,2.746,1.592,5.275
				C373.317,632.271,372.73,630.243,373.739,628.74z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1100)} fill={props.fillColors[1100]} d="M351.382,739.11c2.822-0.485,1.772,1.823,2.483,2.777
				c0.213,0.285-0.057,0.764-0.577,0.781c-0.377,0.014-3.986-2.668-3.883-2.895C349.894,738.698,350.961,739.33,351.382,739.11z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1101)} fill={props.fillColors[1101]} d="M536.187,252.384c-2.802,0.562-5.149-0.717-7.79-0.27
				C530.265,249.987,531.736,250.045,536.187,252.384z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1102)} fill={props.fillColors[1102]} d="M507.109,630.594c1.266,0.791,3.478,0.695,2.934,3.179
				c-0.124,0.562-0.14,1.108-0.952,1.023c-1.299-0.137-2.51-2.785-1.894-4.165C507.188,630.625,507.109,630.594,507.109,630.594z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1103)} fill={props.fillColors[1103]} d="M490.072,633.809c0.227-0.215,0.481-0.406,0.675-0.647
				c1.851-2.299,2.865-2.129,4.072,0.676c0.154,0.356,0.484,0.715,0.13,1.065c-0.418,0.412-0.884,0.188-1.286-0.1
				c-0.954-0.687-1.878-2.862-2.863,0.023c-0.154,0.449-0.431,0.329-0.645-1.102C490.152,633.728,490.072,633.809,490.072,633.809z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1104)} fill={props.fillColors[1104]} d="M503.869,397.677c-0.81,1.313-1.481,2.348-2.09,3.419
				c-0.275,0.483-0.511,1.175-1.116,0.965c-0.553-0.191-0.836-0.901-0.836-1.524c-0.001-0.559-0.371-1.586,0.474-1.55
				C501.633,399.043,502.227,397.721,503.869,397.677z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1105)} fill={props.fillColors[1105]} d="M418.683,573.552c-0.643,0.131-1.078-0.182-0.867-0.549
				c0.673-1.17,0.144-3.166,1.839-3.528c1.658-0.354,1.141,1.539,1.283,2.196C421.304,573.361,419.103,572.584,418.683,573.552z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1106)} fill={props.fillColors[1106]} d="M359.448,577.5c-1.189-1.589-1.844-3.078-3.124-4.105
				c0.755-0.739,1.836-1.666,2.43-1.048C359.965,573.606,359.746,575.4,359.448,577.5z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1107)} fill={props.fillColors[1107]} d="M351.321,766.709c-0.837,1.154,0.239,3.403-1.938,3.604
				c-1.167,0.107-1.521-0.969-1.422-1.804C348.193,766.538,350.074,767.187,351.321,766.709z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1108)} fill={props.fillColors[1108]} d="M443.187,571.558c0.883,0.97,0.653,1.541-0.635,1.625
				c-2.263,0.148-3.096-0.959-2.509-3.312c1.295,0.193,2.15,1.188,3.229,1.769L443.187,571.558z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1109)} fill={props.fillColors[1109]} d="M19.334,576.16c-0.058-1.391,0.734-1.628,1.698-1.628
				c0.626-0.001,1.608,0.07,1.418,0.782c-0.302,1.132-0.535,2.76-1.92,2.952C18.721,578.517,20.454,576.406,19.334,576.16z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1110)} fill={props.fillColors[1110]} d="M476.59,265.128c1.761-0.985,3.229-1.807,4.832-2.704
				C480.979,266.366,479.897,266.99,476.59,265.128z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1111)} fill={props.fillColors[1111]} d="M451.043,571.952c-0.388,2.357-0.772,4.714-2.348,7.105
				C447.071,575.494,450.528,574.244,451.043,571.952z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1112)} fill={props.fillColors[1112]} d="M21.998,279.629c0.547-0.369,1.286-0.001,1.773-0.57
				c0.295-0.344,0.726-0.332,0.999,0.068c0.416,0.608,0.053,0.813-0.519,0.888c-0.208,0.026-0.533,0.108-0.297,0.391
				c0.562,0.674,1.718,1.129-0.141,1.865c-0.63,0.25,1.182,0.718,1.176,1.59c-2.106,0.159-3.441-0.428-2.213-2.757
				C23.377,279.959,22.258,280.105,21.998,279.629z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1113)} fill={props.fillColors[1113]} d="M82.491,681.866c0.214,0.32,0.138,0.615-0.229,0.703
				c-0.383,0.092-0.754,0.071-0.991-0.37c-0.516-0.961-1.077-1.898-1.598-2.857c-0.248-0.455-0.738-1.14,0.103-1.289
				c0.574-0.102,1.596-0.893,1.936,0.421C82.002,679.597,82.234,680.734,82.491,681.866z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1114)} fill={props.fillColors[1114]} d="M461.173,424.434c0.986-0.096,1.87,0.205,1.754,1.627
				c-0.036,0.442,0.166,1.405-0.749,0.996c-1.086-0.485-3.027,0.419-3.172-1.565C458.919,424.297,460.125,424.461,461.173,424.434z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1115)} fill={props.fillColors[1115]} d="M91.062,606.662c0.219-0.187,0.438-0.373,0.657-0.559
				c1.253-0.349,1.411,0.171,0.964,1.201c-0.619,1.429-1.221,2.863-2.076,4.257C90.21,609.877,89.983,608.226,91.062,606.662z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1116)} fill={props.fillColors[1116]} d="M452.032,635.584c1.841,0,3.483,0,5.248,0c-0.607,1.667-1.714,1.977-3.212,1.953
				C452.719,637.515,452.242,636.865,452.032,635.584z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1117)} fill={props.fillColors[1117]} d="M326.652,573.508c0.671-1.678,1.376-3.029,2.556-4.145
				C330.553,572.504,329.823,573.676,326.652,573.508z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1118)} fill={props.fillColors[1118]} d="M399.012,616.299c-1.246,0.175-2.5,0.309-3.736,0.538
				c-0.799,0.148-1.27-0.165-1.379-0.884c-0.129-0.845,0.512-1.059,1.189-1.062c1.106-0.007,2.215,0.032,3.322,0.066
				c1.34,0.043,0.76,0.805,0.527,1.433L399.012,616.299z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1119)} fill={props.fillColors[1119]} d="M84.383,572.356c1.301,2.151,2.99,4.191,1.742,7.536
				c-0.868-2.719-1.602-5.02-2.336-7.32C83.987,572.501,84.186,572.429,84.383,572.356z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1120)} fill={props.fillColors[1120]} d="M241.606,618.958c-0.507-2.382,1.624-1.32,2.473-1.918
				c0.566-0.399,0.875,0.119,0.804,0.643c-0.19,1.413-0.92,2.374-2.441,2.513C241.409,620.29,241.669,619.475,241.606,618.958z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1121)} fill={props.fillColors[1121]} d="M549.649,564.781c-0.987-2.285-1.124-4.66-0.745-7.675
				c1.098,2.479,1.32,4.626,1.763,6.708c0.178,0.837-0.69,0.653-1.041,0.979c0.074,0.14,0.147,0.278,0.222,0.418
				C549.781,565.067,549.715,564.925,549.649,564.781z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1122)} fill={props.fillColors[1122]} d="M328.369,632.597c0.041,1.206-0.874,1.533-1.357,1.457
				c-0.903-0.144-1.823-0.896-1.804-1.824c0.022-1.028,1.187-1.625,2.024-1.613C328.58,630.637,327.317,632.405,328.369,632.597z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1123)} fill={props.fillColors[1123]} d="M565.461,245.914c-0.171,0.066-0.342,0.133-0.506,0.197
				c-1.096-0.558-0.877-1.59-0.889-2.524c-0.008-0.701-0.191-1.518,0.594-1.926c0.404-0.21,0.685,0.246,0.977,0.503
				c0.629,0.552,1.179,1.106,0.674,2.038C566.007,244.762,565.743,245.342,565.461,245.914z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1124)} fill={props.fillColors[1124]} d="M326.742,626.86c0.684,1.966-0.433,3.922-2.308,4.395
				c-0.47,0.118-0.762-0.069-1.02-0.386c-0.442-0.544-0.283-1.066,0.186-1.467C324.557,628.586,325.554,627.817,326.742,626.86z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1125)} fill={props.fillColors[1125]} d="M535.887,632.173c-3.469-1.246-3.469-1.246-1.212-5.486
				C535.515,628.347,535.318,630.195,535.887,632.173z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1126)} fill={props.fillColors[1126]} d="M528.19,629.151c0.067-0.041,0.147-0.038,0.241,0.011
				c0.412,0.619,1.469,1.187,0.892,1.933c-0.36,0.467-1.674,0.734-2.183,0.44c-1.266-0.732-1.012-2.185-1.173-3.719
				C526.88,628.364,527.535,628.758,528.19,629.151z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1127)} fill={props.fillColors[1127]} d="M149.903,459.977c-1.813,0.241-3.138-0.709-4.575-1.374
				c-1.032-0.478-1.95-1.5-3.298-0.938c-0.416,0.172-0.876,0.138-0.91-0.384c-0.042-0.668,0.483-0.765,0.98-0.579
				C144.706,457.675,147.73,457.646,149.903,459.977z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1128)} fill={props.fillColors[1128]} d="M433.999,455.593c0.886-1.156,2.305-1.94,2.037-3.831
				c-0.116-0.823,0.949-1.12,1.744-1.109c1.268,0.017,0.012,1.236,0.692,1.567C437.312,453.781,436.08,455.25,433.999,455.593z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1129)} fill={props.fillColors[1129]} d="M127.808,616.982c-0.936-0.13-2.106-0.127-2.125-1.342
				c-0.017-1.086,1.075-1.692,1.974-1.714c1.408-0.034,1.192,1.463,1.431,2.296C129.383,617.254,128.299,616.8,127.808,616.982z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1130)} fill={props.fillColors[1130]} d="M182.371,687.841c-0.251-1.49-0.66-2.898-1.128-4.292
				c-0.152-0.451-0.339-0.936,0.129-1.28c0.486-0.358,1.087-0.235,1.594-0.074c0.708,0.225,0.554,0.929,0.456,1.411
				C183.13,685.03,182.729,686.431,182.371,687.841z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1131)} fill={props.fillColors[1131]} d="M423.034,632.277c-1.444-1.686-2.224-3.485-0.859-6.083
				c0.816,2.333,1.507,4.306,2.234,6.384c-0.608-0.161-1.034-0.275-1.46-0.388C422.949,632.191,423.034,632.277,423.034,632.277z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1132)} fill={props.fillColors[1132]} d="M240.416,622.246c0.238,1.19,0.523,2.374,0.692,3.574
				c0.069,0.494,0.224,1.209-0.526,1.38c-0.429,0.098-0.997,0.096-1.355-0.113c-0.63-0.367-0.239-0.933-0.086-1.468
				c0.318-1.114,0.541-2.256,0.802-3.387C240.1,622.236,240.258,622.241,240.416,622.246z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1133)} fill={props.fillColors[1133]} d="M412.525,568.759c1.365,0.926,3.122,1.178,3.089,2.825
				c-0.016,0.769-1.517,1.508-2.306,1.005C412.125,571.834,412.093,570.422,412.525,568.759z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1134)} fill={props.fillColors[1134]} d="M368.194,634.713c-1.512-1.086-1.224-2.506-1.599-3.645
				c-0.139-0.42-0.69-1.458,0.36-1.483c0.599-0.014,1.672,0.23,1.59,1.36C368.461,632.112,368.331,633.275,368.194,634.713z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1135)} fill={props.fillColors[1135]} d="M386.186,631.088c0.304-0.304,0.606-0.608,0.903-0.904
				c0.447-0.241,1.074-0.787,1.266,0.087c0.189,0.87,0.5,1.961-0.405,2.649c-0.596,0.452-1.575,1.01-2.115,0.475
				C385.212,632.778,385.555,631.791,386.186,631.088z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1136)} fill={props.fillColors[1136]} d="M589.561,629.578c0.989,0.397,2.109,0.85,2.392,1.98
				c0.173,0.687-0.164,1.613-1.23,1.65c-1.904,0.067-1.254-1.686-1.77-2.593C588.731,630.228,588.816,629.662,589.561,629.578z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1137)} fill={props.fillColors[1137]} d="M247.408,549.68c1.142-0.09,1.798-0.029,1.802,1.152
				c0.004,1.234-0.659,1.538-1.628,1.361c-0.638-0.115-1.784,0.696-1.814-0.864C245.739,549.934,246.489,549.562,247.408,549.68z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1138)} fill={props.fillColors[1138]} d="M214.216,574.935c-0.419,1.701-1.042,2.812-2.037,3.74
				c-0.435,0.406-0.921,0.82-1.432,0.406c-0.392-0.317-0.686-0.897-0.782-1.406c-0.167-0.888,0.605-0.702,1.114-0.77
				C212.248,576.75,212.987,575.881,214.216,574.935z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1139)} fill={props.fillColors[1139]} d="M201.055,601.594c-2.177,2.734-2.474,2.768-5.334,0.768
				C197.254,601.16,197.261,601.159,201.055,601.594z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1140)} fill={props.fillColors[1140]} d="M194.702,565.759c-0.438-0.325-1.723,0.873-1.404-0.585
				c0.252-1.151,0.049-2.966,1.912-3.055c1.301-0.062,0.658,1.144,0.757,1.797c0.148,0.98-0.266,1.676-1.304,1.889L194.702,565.759z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1141)} fill={props.fillColors[1141]} d="M98.939,573.012c0.789-1.809,0.037-3.886,1.498-5.617
				C101.768,571.668,101.663,572.074,98.939,573.012z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1142)} fill={props.fillColors[1142]} d="M145.851,481.747c-2.249-1.104-2.779-2.444-1.494-3.609
				c0.46-0.417,0.926-0.688,1.421-0.106c0.329,0.387,1.155,1.017,0.567,1.299C144.97,479.991,146.313,481.029,145.851,481.747z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1143)} fill={props.fillColors[1143]} d="M232.461,745.872c0.642,2.012,0.424,3.797-1.004,5.366
				C229.605,748.897,231.749,747.534,232.461,745.872z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1144)} fill={props.fillColors[1144]} d="M348.688,349.684c0.118,2.508,0.301,4.586-0.169,7.078
				c-1.171-1.871-1.488-3.636-1.854-5.326C346.332,349.89,347.972,350.606,348.688,349.684z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1145)} fill={props.fillColors[1145]} d="M377.895,564.705c0.552,0.444,1.076,0.929,1.664,1.32
				c0.62,0.414,1.332,0.762,0.679,1.635c-0.503,0.674-1.204,1.224-2.007,0.563c-0.582-0.478-1.803-0.713-1.206-1.948
				c0.262-0.542,0.592-1.052,0.888-1.575C377.909,564.701,377.895,564.705,377.895,564.705z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1146)} fill={props.fillColors[1146]} d="M224.426,619.703c1.203,0.587,2.492,1.033,3.487,1.97
				c0.3,0.282,0.324,0.75,0.094,1.108c-0.315,0.49-0.671,0.131-1.016-0.023c-1-0.449-2.019-0.861-3.008-1.334
				c-0.481-0.23-0.661-0.667-0.502-1.203C223.623,619.736,224.015,619.7,224.426,619.703z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1147)} fill={props.fillColors[1147]} d="M481.528,622.635c-0.414,1.734-1.935,1.514-3.017,1.874
				c-0.771,0.257-1.087-0.449-1.02-1.192c0.141-1.565,1.393-1.302,2.352-1.296C480.422,622.023,481.32,621.729,481.528,622.635z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1148)} fill={props.fillColors[1148]} d="M58.996,619.001c0.04,0.678-3.242,3.369-3.835,3.081
				c-0.456-0.222-1.236-0.817-0.576-1.201c1.245-0.723,2.27-1.677,3.41-2.504C58.406,618.078,58.913,618.38,58.996,619.001z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1149)} fill={props.fillColors[1149]} d="M419.134,620.609c0.779,0.184,1.954,0.141,1.881,1.443
				c-0.038,0.685-0.326,1.483-1.426,1.564c-1.621,0.12-1.482-1.212-1.768-2.047C417.493,620.607,418.394,620.685,419.134,620.609z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1150)} fill={props.fillColors[1150]} d="M537.668,365.62c5.116-0.581,6.121-0.199,6.615,2.457
				C541.838,367.738,540.195,365.434,537.668,365.62z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1151)} fill={props.fillColors[1151]} d="M552.189,561.856c0.95,0.988,3.265,0.531,3.087,2.581
				c-0.052,0.603-1.121,1.708-1.646,1.454C552.09,565.15,552.737,563.292,552.189,561.856z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1152)} fill={props.fillColors[1152]} d="M404.986,608.403c1.049,1.733,0.787,3.354,1.19,4.804
				c0.095,0.34,0.382,1.256-0.661,1.038c-0.722-0.15-1.728-0.097-1.528-1.272C404.219,611.602,404.575,610.252,404.986,608.403z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1153)} fill={props.fillColors[1153]} d="M100.514,680.029c2.796,0.581,2.796,0.581,1.481,4.895
				C101.01,683.359,101.376,681.534,100.514,680.029z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1154)} fill={props.fillColors[1154]} d="M320.854,724.756c1.174-0.982-0.593-3.025,1.324-3.479
				c0.469-0.11,2.293,0.781,1.977,1.571C323.714,723.949,322.294,724.509,320.854,724.756z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1155)} fill={props.fillColors[1155]} d="M581.386,581.558c0.084,1.354,0.239,2.503-1.402,2.305
				c-0.503-0.061-1.565,0.302-1.209-0.76c0.387-1.156,0.728-2.75,1.897-3.084C581.608,579.752,581.285,581.159,581.386,581.558z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1156)} fill={props.fillColors[1156]} d="M418.201,625.759c0.121-0.748-0.38-1.875,1.109-1.771
				c0.7,0.049,1.509,0.577,1.14,1.057c-0.638,0.83,0.49,2.521-1.228,2.616C417.8,627.739,418.354,626.506,418.201,625.759z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1157)} fill={props.fillColors[1157]} d="M95.601,616.175c-0.454-4.786-0.048-6.165,2.036-6.986
				C96.924,611.634,96.263,613.904,95.601,616.175z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1158)} fill={props.fillColors[1158]} d="M408.927,629.704c0-1.935,0.09-3.26-0.027-4.565c-0.102-1.139,0.704-1.065,1.32-1.131
				c1.167-0.123,0.743,0.763,0.607,1.253C410.463,626.575,410.532,628.062,408.927,629.704z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1159)} fill={props.fillColors[1159]} d="M524.273,629.069c-2.475-1.336-2.622-2.114-0.93-5.311
				c0.781,1.743,0.702,3.556,0.92,5.313C524.265,629.072,524.273,629.069,524.273,629.069z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1160)} fill={props.fillColors[1160]} d="M262.238,556.377c-0.135,1.638,0.418,3.202-1.314,3.749
				c-0.585,0.185-1.763-0.377-1.709-0.893C259.375,557.706,260.682,557.062,262.238,556.377z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1161)} fill={props.fillColors[1161]} d="M116.001,563.737c-0.388-1.273-0.484-2.605,0.442-3.197
				c0.881-0.562,1.928,0.468,3.046,0.734C118.561,562.611,117.311,563.152,116.001,563.737z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1162)} fill={props.fillColors[1162]} d="M442.234,574.959c-0.616,1.004-1.275,1.26-2.516,0.751
				c-0.961-0.396-0.992-0.883-0.857-1.585c0.139-0.716,0.792-1.26,1.375-0.915c0.733,0.435,2.635-0.128,1.918,1.84
				C442.154,575.048,442.234,574.959,442.234,574.959z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1163)} fill={props.fillColors[1163]} d="M252.018,573.781c-0.088,0.192-0.176,0.384-0.263,0.576
				c-0.835,0.691-1.758,0.977-2.956,0.565c0.713-1.08,0.415-2.812,2.105-3.088C251.146,571.796,252.97,572.364,252.018,573.781z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1164)} fill={props.fillColors[1164]} d="M33.813,297.31c-2.097-0.271-2.791-1.675-3.742-2.714
				c-0.26-0.284-0.349-0.784,0.158-0.966c0.695-0.25,1.48-0.271,1.912,0.415C32.721,294.964,33.145,295.981,33.813,297.31z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1165)} fill={props.fillColors[1165]} d="M150.779,570.737c-0.16,0.368,0.54,1.446-0.663,1.415
				c-0.78-0.02-1.965-0.26-1.868-1.236c0.08-0.79,0.391-2.096,1.411-2.203C150.707,568.601,150.957,569.59,150.779,570.737z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1166)} fill={props.fillColors[1166]} d="M444.151,556.71c0.603,0.317,1.186,0.624,1.769,0.931
				c-0.742,0.744-0.546,2.253-1.801,2.502c-1.143,0.227-1.371-0.802-1.54-1.631C442.336,557.318,443.332,557.16,444.151,556.71z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1167)} fill={props.fillColors[1167]} d="M372.988,562.122c3.461,1.33,3.461,1.33,1.673,5.401
				C374.859,565.599,373.397,564.221,372.988,562.122z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1168)} fill={props.fillColors[1168]} d="M396.607,570.66c0.12-1.162,0.188-2.239,0.362-3.3
				c0.071-0.438,0.297-0.979,0.843-1.004c0.562-0.026,0.719,0.452,0.838,0.944C399.06,568.99,399.755,570.806,396.607,570.66z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1169)} fill={props.fillColors[1169]} d="M138.385,474.762c-1.338,0.204-2.256,0.023-1.996-1.486
				c0.084-0.488-0.394-1.589,0.645-1.273c1.145,0.35,2.475,1.102,2.794,2.229C140.088,475.15,138.784,474.591,138.385,474.762z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1170)} fill={props.fillColors[1170]} d="M352.459,574.598c-0.22,1.733,1.086,3.035-0.814,3.656
				c-0.745,0.244-1.909,0.318-2.069-0.541C349.219,575.806,351.337,576.153,352.459,574.598z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1171)} fill={props.fillColors[1171]} d="M106.283,740.697c0.165-2.574,2.024-4.349,2.502-6.81
				C110.387,737.133,108.3,738.822,106.283,740.697z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1172)} fill={props.fillColors[1172]} d="M23.577,583.086c0,0-0.051,0.054-0.051,0.054c-0.397-0.352-0.793-0.703-1.189-1.055
				c-0.406-1.76,0.259-3.166,1.643-4.877C24.171,579.503,24.333,581.312,23.577,583.086z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1173)} fill={props.fillColors[1173]} d="M279.698,284.916c-1.921,0.67-3.624-0.036-5.737-0.825
				c1.262-0.473,2.139,0.531,2.962-0.112c0-0.128,0.063-0.333-0.01-0.416c-0.39-0.438-1.241-0.475-1.166-1.308
				c0.038-0.419,0.445-0.482,0.72-0.403c0.73,0.208,1.9,0.525,1.655,1.285c-0.573,1.783,1.142,1.153,1.58,1.799
				C279.704,284.936,279.698,284.916,279.698,284.916z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1174)} fill={props.fillColors[1174]} d="M200.046,620.664c0.425,0.95,2.104,0.789,1.885,2.125
				c-0.087,0.529-0.705,1.422-1.57,1.042c-0.582-0.255-1.953-0.049-1.451-1.286C199.149,621.957,198.924,620.908,200.046,620.664z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1175)} fill={props.fillColors[1175]} d="M400.775,185.659c2.528-1.532,4.084,0.284,5.814,1.26
				c0.366,0.207,0.83,0.672,0.342,1.124c-0.351,0.324-0.903,0.255-1.279-0.131C404.367,186.591,402.728,185.999,400.775,185.659z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1176)} fill={props.fillColors[1176]} d="M441.274,567.388c-0.348-0.434-0.638-0.88-0.578-1.483
				c0.091-0.919,0.2-2.277,1.052-2.299c1.318-0.033,0.955,1.567,1.167,2.4c0.3,1.178-0.522,1.683-1.726,1.303L441.274,567.388z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1177)} fill={props.fillColors[1177]} d="M47.044,696.502c-0.79-1.502-0.714-2.999-0.686-4.488c0.016-0.8,0.64-1.24,1.223-1.194
				c0.998,0.078,0.561,0.928,0.45,1.47C47.75,693.668,47.394,695.033,47.044,696.502z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1178)} fill={props.fillColors[1178]} d="M470.202,625.531c-0.111,0.866-0.218,2.162-1.764,2.19
				c-0.882,0.017-1.321-1.039-1.137-1.488c0.416-1.009,1.453-1.556,2.554-1.728C470.3,624.436,470.561,624.772,470.202,625.531z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1179)} fill={props.fillColors[1179]} d="M530.079,489.531c-2.89,0.872-4.149-1.135-5.487-3.026
				C526.421,487.514,528.25,488.522,530.079,489.531z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1180)} fill={props.fillColors[1180]} d="M173.158,603.047c0.976,4.183,0.729,4.803-2.172,5.461
				C171.138,606.466,172.249,604.923,173.158,603.047z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1181)} fill={props.fillColors[1181]} d="M483.02,555.835c0.051,0.816-0.268,1.475-0.992,1.431
				c-1.492-0.089-1.11-1.657-1.635-2.505c-0.512-0.826,0.737-1.1,1.093-1.112C483.023,553.597,482.319,555.261,483.02,555.835z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1182)} fill={props.fillColors[1182]} d="M140.683,467.896c-1.714-0.488-3.046-0.79-4.313-1.262
				c-0.57-0.213-1.149-0.808-0.85-1.506c0.451-1.052,1.211-0.141,1.514,0.086C138.11,466.022,139.697,466.121,140.683,467.896z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1183)} fill={props.fillColors[1183]} d="M158.303,570.44c0.455,0.926,0.933,1.84,1.354,2.78
				c0.229,0.511,0.884,1.173-0.286,1.43c-0.726,0.16-1.264,0.246-1.701-0.7c-0.65-1.411,0.081-2.388,0.64-3.477L158.303,570.44z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1184)} fill={props.fillColors[1184]} d="M106.784,600.658c0.746,1.547-0.319,2.848-0.244,4.235
				c0.023,0.427,0.112,1.016-0.655,0.97c-0.811-0.047-0.852-0.685-0.781-1.205C105.3,603.208,105.596,601.771,106.784,600.658z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1185)} fill={props.fillColors[1185]} d="M91.545,576.326c-1.143-0.154-0.588,2.254-2.167,1.438
				c-0.668-0.346-1.15-0.793-0.988-1.656c0.086-0.456,0.086-0.929,0.135-1.507c1.276,0.256,2.316,0.667,2.952,1.781
				C91.485,576.376,91.545,576.326,91.545,576.326z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1186)} fill={props.fillColors[1186]} d="M392.357,563.186c0.68,4.122,0.569,4.344-2.369,5.157
				C390.752,566.682,391.452,565.157,392.357,563.186z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1187)} fill={props.fillColors[1187]} d="M235.367,624.043c0.738-0.25,1.476-0.501,2.542-0.864
				c-0.607,1.388,0.035,2.858-1.306,3.747c-0.402,0.266-0.767,0.1-0.998-0.3c-0.497-0.857-1.261-1.708-0.154-2.664
				C235.452,623.961,235.367,624.043,235.367,624.043z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1188)} fill={props.fillColors[1188]} d="M247.58,549.071c-0.479-0.208-1.449,0.012-0.998-1.057
				c0.313-0.742-0.072-2.007,1.502-1.854c1.013,0.099,1.053,0.632,1.098,1.404C249.25,548.751,248.605,549.071,247.58,549.071z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1189)} fill={props.fillColors[1189]} d="M84.59,589.719c-0.262,1.086-1.334,1-1.991,1.13c-1.01,0.199-1.248-0.91-1.255-1.571
				c-0.013-1.187,1.234-0.98,1.928-1.157C84.534,587.798,83.877,589.314,84.59,589.719z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1190)} fill={props.fillColors[1190]} d="M506.763,583.124c-0.059-0.06-0.107-0.126-0.146-0.197
				c-0.085-0.22-0.237-0.439-0.243-0.664c-0.025-0.943-0.135-2.137,1.027-2.319c0.719-0.113,1.623,0.832,1.536,1.533
				C508.812,582.494,508.363,583.748,506.763,583.124z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1191)} fill={props.fillColors[1191]} d="M409.713,187.9c-2.695,0.272-2.931-3.237-5.284-3.49
				C407.339,183.808,407.941,186.752,409.713,187.9z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1192)} fill={props.fillColors[1192]} d="M555.173,314.354c-0.514,0.808-0.242,2.25-1.344,2.312
				c-0.778,0.043-1.392-0.887-1.297-1.937c0.099-1.097,0.818-1.235,1.675-1.207C554.757,313.54,555.386,313.517,555.173,314.354z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1193)} fill={props.fillColors[1193]} d="M489.668,560.602c-0.151-0.91,0.048-1.469,1.184-1.437
				c1.186,0.034,1.333,0.887,1.48,1.688c0.165,0.89-0.38,1.235-1.23,1.291C489.962,562.22,489.514,561.715,489.668,560.602z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1194)} fill={props.fillColors[1194]} d="M114.848,558.354c0.236,1.303-0.146,2.026-1.569,1.908
				c-0.698-0.057-1.456-0.247-1.182-1.024c0.357-1.011,1.051-1.926,2.352-1.931C115.5,557.304,114.974,557.945,114.848,558.354z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1195)} fill={props.fillColors[1195]} d="M443.187,571.558c0,0,0.087,0.081,0.086,0.082c-0.115-1.316-1.335-1.428-2.152-1.987
				c-1.207-0.827-1.58-1.624,0.155-2.264c-0.001-0.001-0.085-0.077-0.085-0.079c0.34,1.367,1.692,1.692,2.603,2.459
				c0.566,0.477,0.719,1.081,0.581,1.616C444.232,571.934,443.597,571.494,443.187,571.558z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1196)} fill={props.fillColors[1196]} d="M549.626,564.793c0.956,0.407,1.915,0.87,1.936,2.08
				c0.016,0.884-0.927,0.707-1.425,0.949c-0.583,0.283-1.481,0.588-1.779-0.347c-0.215-0.679-0.394-1.611,0.746-1.89
				c0.408-0.1,0.731-0.289,0.553-0.806C549.649,564.781,549.626,564.793,549.626,564.793z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1197)} fill={props.fillColors[1197]} d="M401.02,613.864c0.1-0.029,0.183-0.086,0.25-0.171c0.864,1.439,0.405,3.49-0.958,4.044
				c-1.657,0.672-1.276-0.585-1.299-1.438c-0.001,0-0.075,0.09-0.076,0.091C400.221,616.035,400.312,614.694,401.02,613.864z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1198)} fill={props.fillColors[1198]} d="M481.552,570.716c-0.773,0.124-0.97-0.279-0.812-0.816
				c0.281-0.959,0.476-2.134,1.674-2.306c0.926-0.132,0.98,0.732,1.094,1.462C483.783,570.831,482.098,570.119,481.552,570.716z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1199)} fill={props.fillColors[1199]} d="M194.15,569.143c-0.619-1.02-1.06,0.826-1.754-0.187
				c-0.455-1.683,1.416-2.12,2.301-3.192c0.004-0.005-0.046,0.054-0.041,0.047c3.422,1.678-0.127,2.254-0.537,3.338
				C194.107,569.156,194.15,569.143,194.15,569.143z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1200)} fill={props.fillColors[1200]} d="M391.893,615.196c0.387,1.13,0.779,2.257,1.154,3.389
				c0.126,0.377,0.22,0.875-0.229,1.009c-0.502,0.148-1.103,0.186-1.604,0.055c-0.452-0.118-0.23-0.542-0.156-0.944
				c0.208-1.131,0.251-2.293,0.359-3.442C391.575,615.239,391.734,615.218,391.893,615.196z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1201)} fill={props.fillColors[1201]} d="M444.734,565.483c-0.377-1.202-0.593-2.019-0.891-2.804
				c-0.196-0.516-0.624-1.067,0.026-1.454c0.584-0.347,1.201-0.079,1.78,0.264c0.618,0.365,0.671,0.836,0.4,1.402
				C445.688,563.649,445.291,564.392,444.734,565.483z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1202)} fill={props.fillColors[1202]} d="M301.8,618.292c3.174,0.104,2.845,1.943,2.548,4.131
				C302.792,621.356,302.556,619.82,301.8,618.292z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1203)} fill={props.fillColors[1203]} d="M587.773,619.794c-0.249-0.618-1.455-0.827-1.004-1.639
				c0.495-0.893,1.329-0.33,2.049-0.059c0.352,0.133,0.775,0.062,1.158,0.131c0.685,0.123,1.235,0.47,1.028,1.249
				c-0.195,0.732-0.812,0.879-1.509,0.701c-0.603-0.154-1.206-0.307-1.807-0.459C587.692,619.721,587.773,619.794,587.773,619.794z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1204)} fill={props.fillColors[1204]} d="M400.014,366.167c-1.66,1.069-3.277,0.507-4.812,0.569
				c-0.472,0.019-0.644-0.371-0.612-0.827c0.026-0.382,0.271-0.598,0.607-0.581C396.748,365.408,398.402,364.813,400.014,366.167z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1205)} fill={props.fillColors[1205]} d="M90.008,555.038c0.572,1.104,0.943,1.872,1.363,2.613
				c0.427,0.753,0.084,1.241-0.565,1.503c-0.748,0.303-1.316-0.174-1.391-0.836C89.301,557.308,88.944,556.185,90.008,555.038z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1206)} fill={props.fillColors[1206]} d="M533.584,363.956c0.661,0.535,1.32,1.068,2.207,1.784
				c-1.473-0.371-1.273,3.178-3.349,1.034C531.926,366.239,531.908,366.255,533.584,363.956z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1207)} fill={props.fillColors[1207]} d="M152.235,603.444c0.299,0.791,1.491,1.036,1.307,2.085
				c-0.026,0.149-0.522,0.31-0.798,0.306c-1.236-0.016-1.74-0.919-2.128-1.898c-0.278-0.702-0.493-1.43-0.907-2.648
				c1.133,0.97,1.874,1.604,2.614,2.238C152.322,603.528,152.235,603.444,152.235,603.444z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1208)} fill={props.fillColors[1208]} d="M366.013,566.491c0,1.406,0.005,2.812-0.002,4.219
				c-0.003,0.647-0.068,1.405-0.899,1.343c-0.753-0.058-0.809-0.791-0.868-1.456C364.1,568.992,365.165,567.828,366.013,566.491z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1209)} fill={props.fillColors[1209]} d="M242.725,624.836c-0.4-1.491-1.32-2.81-0.204-4.091
				c0.221-0.253,1.04,0.014,1.583,0.041C243.909,622.158,244.298,623.717,242.725,624.836z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1210)} fill={props.fillColors[1210]} d="M515.602,579.475c-0.197,0.104-0.394,0.207-0.591,0.31
				c-0.363-0.121-1.54,0.928-0.958-0.495c0.486-1.188,1.454-2.182,2.208-3.261C518.68,577.826,516.621,578.531,515.602,579.475z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1211)} fill={props.fillColors[1211]} d="M314.642,751.271c0.755-1.985,1.173-4.076,2.567-5.878
				C318.466,748.367,316.466,749.81,314.642,751.271z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1212)} fill={props.fillColors[1212]} d="M517.444,394.496c-0.744,2.582-2.133,3.402-4.508,2.258
				C514.201,395.755,515.531,394.937,517.444,394.496z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1213)} fill={props.fillColors[1213]} d="M155.514,714.177c-0.532,2.684-1.064,5.367-1.596,8.052
				C153.673,719.394,154.379,716.743,155.514,714.177z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1214)} fill={props.fillColors[1214]} d="M276.398,576.876c0.881-2.133,2.23-1.582,3.684-0.985
				c0.675,0.277,0.693,0.643,0.328,1.02C279.134,578.225,277.811,577.178,276.398,576.876z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1215)} fill={props.fillColors[1215]} d="M596.598,627.916c0.946,2.62-1.287,3.178-2.66,4.279
				C594.454,630.554,594.266,628.467,596.598,627.916z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1216)} fill={props.fillColors[1216]} d="M249.171,540.293c0,1.531-0.006,2.798,0.002,4.064
				c0.006,0.793-0.131,1.511-1.148,1.363c-1.164-0.17-0.408-0.866-0.343-1.407C247.851,542.933,248.255,541.657,249.171,540.293z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1217)} fill={props.fillColors[1217]} d="M293.626,425.138c2.385-0.694,4.788-1.394,7.428-2.162
				C298.624,425.156,295.103,426.109,293.626,425.138z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1218)} fill={props.fillColors[1218]} d="M34.7,601.998c0.017,0.925-0.499,1.079-0.956,0.916
				c-1.175-0.419-2.421-0.84-2.743-2.284c-0.108-0.486,0.223-0.81,0.687-0.692C33.001,600.271,34.014,601.051,34.7,601.998z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1219)} fill={props.fillColors[1219]} d="M349.673,580.639c-0.527,1.199-1.277,2.117-2.481,2.47
				c-0.426,0.124-0.732-0.495-0.521-0.83c0.604-0.956,0.289-2.565,1.743-2.861C349.024,579.293,349.424,580.047,349.673,580.639z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1220)} fill={props.fillColors[1220]} d="M236.127,618.817c-0.935,0.101-2.002-0.007-2.034-1.072
				c-0.026-0.873,1.056-0.863,1.811-0.951c0.385-0.045,0.771-0.156,1.133-0.299c0.5-0.195,0.946-0.237,1.183,0.334
				c0.219,0.529,0.011,0.859-0.536,1.097c-0.573,0.248-1.088,0.629-1.633,0.956C236.045,618.884,236.127,618.817,236.127,618.817z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1221)} fill={props.fillColors[1221]} d="M179.412,575.77c0.679,0.913,2.548,0.986,1.801,2.771
				c-0.25,0.597-0.845,1.078-1.192,0.856C178.652,578.522,179.257,577.081,179.412,575.77z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1222)} fill={props.fillColors[1222]} d="M175.917,575.649c-0.304-0.424-0.887-0.872-0.854-1.268
				c0.065-0.768,0.079-1.998,0.978-2.072c1.32-0.109,0.9,1.361,1.183,2.096c0.485,1.263-0.434,1.354-1.395,1.161
				C175.832,575.568,175.917,575.649,175.917,575.649z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1223)} fill={props.fillColors[1223]} d="M301.801,560.69c-1.563,0.22-1.965-0.443-1.862-1.624
				c0.057-0.643,0.007-1.32,0.954-1.279c1.444,0.064,1.717,1.412,2.025,2.296C303.212,560.924,302.071,560.616,301.801,560.69z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1224)} fill={props.fillColors[1224]} d="M356.98,617.128c1.723,1.738-0.163,2.719-0.837,3.909
				c-0.092,0.162-0.802,0.164-1.069-0.007c-0.479-0.304-0.354-0.849-0.068-1.255C355.634,618.883,356.309,618.022,356.98,617.128z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1225)} fill={props.fillColors[1225]} d="M415.623,614.113c0.27,1.382,0.539,2.508,0.688,3.65
				c0.062,0.474,0.316,1.298-0.46,1.334c-0.678,0.032-1.405-0.355-1.315-1.301C414.653,616.573,414.855,615.369,415.623,614.113z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1226)} fill={props.fillColors[1226]} d="M324.609,545.825c1.12-1.914,2.193-3.43,3.735-4.602
				C326.837,542.446,328.359,545.793,324.609,545.825z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1227)} fill={props.fillColors[1227]} d="M269.388,763.679c0.735,0.355,1.164-0.55,1.984-0.543
				c-0.324,0.918-0.688,1.689-0.856,2.501c-0.146,0.7-0.25,1.249-1.148,1.047c-0.453-0.102-1.264-0.114-1.301-0.295
				c-0.129-0.615,0.195-1.179,0.716-1.623c0.342-0.291,0.751-0.606,0.683-1.168C269.466,763.596,269.388,763.679,269.388,763.679z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1228)} fill={props.fillColors[1228]} d="M223.945,706.521c1.299,0.859,2.278,1.551,2.526,2.915
				C223.849,709.997,223.849,709.997,223.945,706.521z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1229)} fill={props.fillColors[1229]} d="M77.158,710.103c0.133-0.967-0.218-2.227,1.13-2.083
				c1.064,0.113,0.746,1.414,1.014,2.155c0.292,0.807-0.332,1.305-1.216,1.32C76.85,711.518,77.345,710.54,77.158,710.103z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1230)} fill={props.fillColors[1230]} d="M158.31,570.474c0.141-0.907,0.753-1.352,1.586-1.454
				c0.762-0.094,0.432,0.66,0.658,0.985c0.553,0.795,0.598,1.667,0.216,2.462c-0.183,0.38-0.708,0.08-0.977-0.23
				c-0.508-0.587-0.993-1.193-1.489-1.794C158.303,570.44,158.31,570.474,158.31,570.474z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1231)} fill={props.fillColors[1231]} d="M552.609,631.822c0.261,1.994-1.601,1.25-2.398,1.852
				c-0.261,0.197-1.104,0.071-0.871-0.628c0.404-1.217,1.635-1.611,2.652-2.082C552.632,630.669,552.607,631.397,552.609,631.822z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1232)} fill={props.fillColors[1232]} d="M323.867,623.504c-0.103,2.45-2.145,1.75-3.337,2.302
				c-0.328,0.151-0.565-0.426-0.612-0.848c-0.061-0.554,0.094-0.901,0.713-0.982C321.704,623.834,322.812,624.095,323.867,623.504z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1233)} fill={props.fillColors[1233]} d="M247.202,552.354c0.901,0.071,1.909-0.11,1.94,1.228
				c0.013,0.569-0.263,1.079-0.807,0.724c-0.911-0.595-2.543,0.56-2.843-0.954C245.26,552.178,246.558,552.546,247.202,552.354z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1234)} fill={props.fillColors[1234]} d="M159.247,735.475c0.309-1.653-1.417-2.432-0.416-3.704
				c0.304-0.386,1.246-0.466,1.44,0.031C160.693,732.886,160.813,734.077,159.247,735.475z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1235)} fill={props.fillColors[1235]} d="M363.516,572.82c-2.629,0.959-2.976,0.602-3.373-3.154
				c0.585,0.622,0.994,1.104,1.452,1.533C362.11,571.68,362.672,572.113,363.516,572.82z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1236)} fill={props.fillColors[1236]} d="M435.764,468.735c1.89-1.387,3.734-1.497,6.202-1.886
				C439.848,468.518,438.385,469.998,435.764,468.735z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1237)} fill={props.fillColors[1237]} d="M336.284,576.079c-0.72,1.748-1.721,2.106-3.024,1.647
				c-0.604-0.212-0.949-1.021-0.619-1.347C333.646,575.389,334.894,576.169,336.284,576.079z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1238)} fill={props.fillColors[1238]} d="M82.237,577.447c-0.184-0.126-0.363-0.35-0.553-0.359c-0.86-0.048-1.823,0.058-2.128-1
				c-0.149-0.519,0.437-0.524,0.797-0.562c1.188-0.123,2.554-0.257,3.062,1.052c0.468,1.201-0.634,0.828-1.261,0.807
				C82.155,577.384,82.237,577.447,82.237,577.447z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1239)} fill={props.fillColors[1239]} d="M31.701,573.645c0.833,0.396,1.828,0.799,1.96,1.826
				c0.098,0.763-0.883,0.953-1.379,0.954c-1.113,0.001-1.232-1.006-1.344-1.856C30.874,574.078,30.962,573.591,31.701,573.645z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1240)} fill={props.fillColors[1240]} d="M461.178,636.739c1.495-0.685,2.299,0.367,3.275,0.751
				c0.546,0.215,0.961,0.587,0.338,1.223c-0.475,0.485-0.9,0.947-1.546,0.245C462.607,638.265,461.96,637.578,461.178,636.739z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1241)} fill={props.fillColors[1241]} d="M381.822,558.352c-1.366-1.318-2.522-2.658-0.331-4.491
				c0.41,1.248,0.786,2.398,1.165,3.548c0.313,0.952-0.075,1.148-0.918,0.858C381.739,558.268,381.822,558.352,381.822,558.352z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1242)} fill={props.fillColors[1242]} d="M431.312,635.99c-0.542,1.106-0.899,1.891-1.308,2.648
				c-0.298,0.552-0.448,1.62-1.377,1.002c-0.815-0.542-0.741-1.404-0.091-2.235C429.227,636.521,430.165,636.429,431.312,635.99z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1243)} fill={props.fillColors[1243]} d="M539.598,635.55c0.376,0.733,2.045-0.2,1.893,1.285
				c-0.04,0.394,0.184,1.713-1.002,1.417c-0.847-0.212-1.947-0.338-1.935-1.677C538.56,635.924,538.702,635.484,539.598,635.55z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1244)} fill={props.fillColors[1244]} d="M596.517,631.62c0.003,1.267,0.925,2.672-0.271,3.388
				c-0.936,0.56-1.341-0.796-1.548-1.331C594.087,632.094,596.243,632.698,596.517,631.62z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1245)} fill={props.fillColors[1245]} d="M57.081,234.349c-1.078-0.322-1.438-1.259-1.868-2.133
				c-0.365-0.74,0.445-1.022,0.716-1.287c0.574-0.56,1.083,0.198,1.51,0.551c0.39,0.323,0.56,0.708-0.212,0.969
				C55.856,232.911,57.728,233.669,57.081,234.349z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1246)} fill={props.fillColors[1246]} d="M126.641,693.035c2.693,1.971,1.098,3.915,1.051,6.144
				C126.701,697.054,127.191,695.161,126.641,693.035z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1247)} fill={props.fillColors[1247]} d="M456.532,457.951c1.39-2.562,1.39-2.562,3.913-0.791
				C459.391,458.476,458.033,457.918,456.532,457.951z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1248)} fill={props.fillColors[1248]} d="M322.581,626.14c-0.386,1.047,0.15,2.655-1.51,2.786
				c-0.965,0.076-1.016-1.079-1.029-1.586C319.99,625.492,321.715,626.687,322.581,626.14z"/>
			<path fill-rule="evenodd" d="M35.274,559.198c-2.427-0.221-4.961-0.05-6.94-1.903c2.432,0.499,4.864,0.999,7.296,1.498
				C35.511,558.928,35.393,559.062,35.274,559.198z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1249)} fill={props.fillColors[1249]} d="M18.812,288.074c1.39,0,2.674,0,4.003,0C21.272,290.577,21.079,290.58,18.812,288.074z
				"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1250)} fill={props.fillColors[1250]} d="M59.634,569.733c0.444,0.558,0.43,1.121,0.016,1.548
				c-0.58,0.598-0.971,1.572-2.054,1.38c-0.264-0.047-0.705-0.429-0.675-0.578c0.166-0.825,0.875-1.271,1.491-1.742
				C58.767,570.07,59.223,569.933,59.634,569.733z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1251)} fill={props.fillColors[1251]} d="M141.314,579.888c-0.343-1.095-0.553-1.938-0.875-2.734
				c-0.24-0.594-0.043-0.922,0.472-1.102c0.514-0.179,1.126-0.492,1.568-0.35c0.471,0.151,0.313,0.792,0.112,1.256
				C142.192,577.877,142.372,579.026,141.314,579.888z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1252)} fill={props.fillColors[1252]} d="M26.116,589.739c0.21,1.201,1.669,2.163,0.737,3.437
				c-0.255,0.349-1.379,0.361-1.51-0.038C24.963,591.97,25.314,590.8,26.116,589.739z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1253)} fill={props.fillColors[1253]} d="M218.701,618.329c-0.965,0.067-1.978-0.035-1.815-1.017
				c0.115-0.688,1.189-0.929,1.997-0.992c0.864-0.066,1.35,0.352,1.292,1.234C220.099,618.702,219.146,618.18,218.701,618.329z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1254)} fill={props.fillColors[1254]} d="M177.602,616.22c0,0-0.046,0.04-0.046,0.041c0.293-0.066,0.587-0.132,0.88-0.199
				c-0.463,1.413-1.366,2.543-2.383,3.582c-0.076,0.076-0.568-0.161-0.792-0.344c-0.397-0.324-0.262-0.714,0.057-1.013
				C176.065,617.584,176.838,616.907,177.602,616.22z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1255)} fill={props.fillColors[1255]} d="M123.442,612.89c-0.087,1.434-0.55,2.72-1.45,3.961
				c-1.068-3.023-0.795-3.67,1.527-3.879C123.519,612.971,123.442,612.89,123.442,612.89z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1256)} fill={props.fillColors[1256]} d="M453.165,571.888c1.243,3.8,1.243,3.8-1.391,4.656
				C452.243,574.976,452.654,573.598,453.165,571.888z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1257)} fill={props.fillColors[1257]} d="M519.5,393.401c-0.971-0.623-2.004-0.736-3.082-0.667
				c-0.65,0.042-0.779-0.37-0.777-0.919c0.003-0.833,0.539-0.425,0.951-0.493C518.165,391.062,518.974,391.942,519.5,393.401z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1258)} fill={props.fillColors[1258]} d="M449.215,456.86c0.388-1.468,1.17-2.823,0.34-4.348
				c-0.227-0.415,0.026-0.756,0.518-0.828c0.487-0.071,0.743,0.165,0.771,0.643C450.943,454.047,451.086,455.783,449.215,456.86z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1259)} fill={props.fillColors[1259]} d="M223.333,758.806c-0.66,1.326-1.185,2.379-1.767,3.55
				C220.349,759.279,220.349,759.279,223.333,758.806z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1260)} fill={props.fillColors[1260]} d="M544.693,345.882c0.299,0.699,0.514,1.28,0.791,1.829
				c0.296,0.588,0.965,1.168,0.504,1.827c-0.462,0.659-0.989-0.063-1.381-0.292c-0.783-0.459-1.364-1.077-0.593-2.066
				C544.29,346.826,544.441,346.375,544.693,345.882z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1261)} fill={props.fillColors[1261]} d="M193.306,622.12c1.234,0.2,2.102,0.876,3.069,1.349
				c0.878,0.429,0.307,0.772,0.162,1.277c-0.324,1.122-0.968,0.271-1.183,0.127C194.414,624.242,193.542,623.492,193.306,622.12z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1262)} fill={props.fillColors[1262]} d="M415.862,574.068c0.107,1.229-0.835,1.588-1.611,1.795
				c-0.773,0.206-0.992-0.526-1.072-1.217c-0.162-1.406,1.126-1.077,1.77-1.405C415.59,572.914,415.946,573.426,415.862,574.068z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1263)} fill={props.fillColors[1263]} d="M44.068,607.557c-0.689,1.139-1.381,2.006-2.739,0.654
				c-0.704-0.7-0.698-1.551-0.291-2.091c0.597-0.793,0.977,0.246,1.442,0.513c0.537,0.308,1.062,0.635,1.596,0.959
				C44.081,607.598,44.068,607.557,44.068,607.557z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1264)} fill={props.fillColors[1264]} d="M432.717,563.475c-1.252-1.069-1.293-2.869-2.569-4.094
				c1.79-0.539,2.636-0.026,2.573,1.781c-0.028,0.792-0.055,1.585-0.08,2.375C432.643,563.535,432.717,563.475,432.717,563.475z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1265)} fill={props.fillColors[1265]} d="M485.891,570.037c-1.098-1.687-1.213-2.994-1.484-4.245
				c-0.072-0.339-0.365-0.771,0.238-0.946c0.507-0.148,0.649,0.205,0.856,0.568C486.223,566.684,485.812,568.023,485.891,570.037z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1266)} fill={props.fillColors[1266]} d="M112.048,562.152c-0.114-0.01-1.277,0.378-1.188-0.799
				c0.076-1.002,1.246-0.758,2-0.752c0.635,0.005,1.628-0.274,1.478,0.93C114.198,562.645,113.314,562.185,112.048,562.152z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1267)} fill={props.fillColors[1267]} d="M281.606,572.04c-0.978-0.388-2.546,0.145-2.49-1.524
				c0.015-0.451,0.696-1.305,0.852-1.257C281.236,569.647,281.254,570.938,281.606,572.04z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1268)} fill={props.fillColors[1268]} d="M39.462,595.624c0,1.454-0.023,2.453,0.007,3.451c0.026,0.858-0.387,1.121-1.179,1.03
				c-1.025-0.118-0.561-0.683-0.379-1.177C38.274,597.938,38.337,596.804,39.462,595.624z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1269)} fill={props.fillColors[1269]} d="M418.524,632.844c-0.531-1.31-0.234-2.425-0.29-3.51
				c-0.017-0.32-0.167-0.872,0.487-0.806c0.4,0.041,0.835,0.199,0.872,0.666C419.691,630.43,419.94,631.727,418.524,632.844z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1270)} fill={props.fillColors[1270]} d="M560.826,558.04c0.002,0.726-0.066,1.044-0.793,0.859
				c-0.849-0.216-1.546-0.537-1.478-1.485c0.041-0.563,0.382-1.492,0.924-1.382C560.493,556.238,560.612,557.338,560.826,558.04z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1271)} fill={props.fillColors[1271]} d="M592.859,621.182c0.73-0.148,1.396-0.438,1.958-0.938
				c0.705-0.157,1.203,0.137,0.937,0.83c-0.259,0.67-0.1,1.612-0.926,1.974c-0.466,0.204-0.903-0.051-1.255-0.433
				C593.191,622.2,592.827,621.795,592.859,621.182z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1272)} fill={props.fillColors[1272]} d="M35.479,292.091c-2.113-1.014-2.652-3.366-1.407-4.426
				c0.15-0.128,0.469-0.151,0.678-0.098c0.591,0.151,0.262,0.539,0.246,0.915C34.946,289.607,34.23,290.75,35.479,292.091z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1273)} fill={props.fillColors[1273]} d="M444.598,635.856c-0.196,1.164-0.91,1.694-1.777,1.936
				c-0.943,0.263-1.1-0.817-1.066-1.17c0.086-0.945,1.004-1.084,1.794-1.118C443.935,635.487,444.555,635.255,444.598,635.856z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1274)} fill={props.fillColors[1274]} d="M491.33,616.92c0,0-0.071,0.066-0.071,0.067c1.421-0.344,2.433,0.197,3.302,1.661
				c-1.311,0.351-2.485,0.397-3.63-0.062C490.854,618.555,491.188,617.499,491.33,616.92z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1275)} fill={props.fillColors[1275]} d="M375.523,752.579c0.546,2.13,0.707,4.206-1.892,5.354
				C374.293,756.062,374.908,754.32,375.523,752.579z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1276)} fill={props.fillColors[1276]} d="M33.617,278.773c1.507,1.308,1.418,3.163,2.2,5.162
				C33.329,282.578,34.102,280.443,33.617,278.773z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1277)} fill={props.fillColors[1277]} d="M227.213,623.843c-0.275,0.83-0.801,1.505-1.294,1.479
				c-1.238-0.062-1.045-1.382-1.421-2.17c-0.219-0.46,0.369-0.752,0.701-0.592C225.928,622.911,226.578,623.426,227.213,623.843z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1278)} fill={props.fillColors[1278]} d="M398.265,617.438c-0.605,1.396-1.616,1.299-2.374,1.643
				c-0.542,0.245-1.126,0.371-1.269-0.454c-0.116-0.669-0.055-1.337,0.865-1.334C396.329,617.296,397.171,617.378,398.265,617.438z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1279)} fill={props.fillColors[1279]} d="M219.785,763.728c0.86,2.59-0.053,4.182-1.121,5.746
				C218.919,767.713,218.495,765.833,219.785,763.728z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1280)} fill={props.fillColors[1280]} d="M267.143,570.611c0.796-0.859,0.941-2.101,2.136-2.672
				c0.171,0.936,1.242,1.749,0.619,2.59C269.289,571.352,268.139,570.68,267.143,570.611z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1281)} fill={props.fillColors[1281]} d="M269.123,772.5c-0.399-1.578-0.669-2.556-0.886-3.545
				c-0.092-0.419-0.6-0.902-0.077-1.268c0.266-0.186,0.782-0.17,1.124-0.058c0.607,0.2,0.949,0.834,0.644,1.327
				C269.355,769.882,269.728,770.955,269.123,772.5z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1282)} fill={props.fillColors[1282]} d="M440.124,562.234c-1.214,1.671-0.049,3.863-2.582,4.09
				C436.87,564.374,438.206,563.526,440.124,562.234z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1283)} fill={props.fillColors[1283]} d="M517.93,634.792c-0.787,3.154-0.787,3.154-3.162,1.559
				C515.764,635.626,516.748,635.081,517.93,634.792z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1284)} fill={props.fillColors[1284]} d="M286.699,623.388c0.065-0.847,0.131-1.692,0.205-2.65
				c1.147,0.159,2.035,0.951,2.074,1.727C289.034,623.573,287.547,622.912,286.699,623.388z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1285)} fill={props.fillColors[1285]} d="M433.112,472.881c0.907,0.66,0.955,2.215,2.416,1.639
				c0.436-0.173,0.389,0.172,0.363,0.326c-0.102,0.635-0.617,1.031-1.149,0.959c-0.899-0.122-1.32-1.232-2.385-1.316
				C432.16,474.473,432.688,473.472,433.112,472.881z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1286)} fill={props.fillColors[1286]} d="M164.455,689.898c-0.766-1.612-1.515-3.233-2.309-4.832
				c-0.196-0.395-0.371-0.759,0.14-0.934c0.437-0.148,0.783,0.231,0.903,0.581c0.569,1.667,1.079,3.355,1.61,5.035
				C164.685,689.799,164.57,689.849,164.455,689.898z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1287)} fill={props.fillColors[1287]} d="M558.661,475.156c2.65,1.307,2.65,1.307,0.544,3.221
				C559.109,477.251,558.291,476.467,558.661,475.156z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1288)} fill={props.fillColors[1288]} d="M81.478,575.157c-0.658-0.092-1.463,0.132-1.569-0.935
				c-0.085-0.854,0.338-1.023,1.078-1.104c1.035-0.114,1.438,0.544,1.596,1.292C82.768,575.288,81.935,575.025,81.478,575.157z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1289)} fill={props.fillColors[1289]} d="M455.687,207.694c3.469,2.292-0.625,2.807-0.367,4.153
				C454.753,210.497,455.72,209.352,455.687,207.694z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1290)} fill={props.fillColors[1290]} d="M287.948,739.544c-0.688-0.967-2.098-1.623-1.369-3.015
				c0.166-0.315,1.07-0.643,1.175-0.53C288.761,737.064,287.881,738.325,287.948,739.544z"/>
			<path fill-rule="evenodd" d="M502.266,424.389c-0.027,0.155-0.074,0.312-0.078,0.467c-0.015,0.816-0.319,1.408-1.225,1.4
				c-0.617-0.006-1.096-0.358-1.087-1.007c0.017-1.213,0.979-1.246,1.853-1.348C501.907,424.063,502.086,424.226,502.266,424.389z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1291)} fill={props.fillColors[1291]} d="M478.688,626.85c-0.699-0.045-1.246-0.247-1.11-0.822
				c0.246-1.048,1.166-1.153,2.069-1.133c0.486,0.011,1.11,0.097,0.938,0.737C480.316,626.622,479.225,626.46,478.688,626.85z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1292)} fill={props.fillColors[1292]} d="M559.871,561.646c-0.769-0.51-1.897-0.986-1.194-2.113
				c0.481-0.77,1.276,0.121,1.921,0.249c0.479,0.096,0.766,0.591,0.684,1.126c-0.143,0.941-0.86,0.732-1.484,0.683
				C559.798,561.591,559.871,561.646,559.871,561.646z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1293)} fill={props.fillColors[1293]} d="M491.739,557.933c0.191,0.777-0.361,0.823-0.938,0.868
				c-0.738,0.059-1.24-0.152-1.156-1.008c0.054-0.557-0.289-1.305,0.715-1.43C491.589,556.21,491.354,557.297,491.739,557.933z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1294)} fill={props.fillColors[1294]} d="M306.472,618.159c0.189,0.407,0.312,0.602,0.373,0.814
				c0.292,1.006,0.982,2.011,0.153,3.043c-0.104,0.129-0.795,0.041-0.825-0.062C305.81,620.73,305.589,619.491,306.472,618.159z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1295)} fill={props.fillColors[1295]} d="M478.734,582.729c-0.642-0.185-1.304,1.321-1.926-0.019
				c-0.225-0.483-0.819-1.061-0.446-1.473c0.339-0.373,1.135-0.569,1.668-0.48c1.419,0.236,0.597,1.305,0.612,2.059L478.734,582.729z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1296)} fill={props.fillColors[1296]} d="M144.306,705.853c-0.655-1.432-1.02-2.827,0.399-4.203
				C145.729,703.34,144.41,704.539,144.306,705.853z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1297)} fill={props.fillColors[1297]} d="M147.391,604.571c-0.674,0.643-1.335,1.272-2.168,2.067
				c-0.088-1.187-0.49-2.354,0.258-3.021C145.919,603.226,146.907,603.861,147.391,604.571z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1298)} fill={props.fillColors[1298]} d="M91.563,689.554c1.459,0.429,4.283-0.964,3.52,2.651
				C93.839,691.268,92.701,690.41,91.563,689.554z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1299)} fill={props.fillColors[1299]} d="M165.196,746.86c0.597-1.778,1.125-3.352,1.654-4.926
				C167.159,743.787,167.684,745.711,165.196,746.86z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1300)} fill={props.fillColors[1300]} d="M469.147,302.586c-0.502,1.992-0.854,3.388-1.289,5.118
				C466.504,305.606,467.362,304.31,469.147,302.586z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1301)} fill={props.fillColors[1301]} d="M49.285,738.786c0.205,1.667,0.924,3.6-1.687,4.847
				C48.247,741.769,48.766,740.277,49.285,738.786z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1302)} fill={props.fillColors[1302]} d="M180.128,574.968c1.021-0.015,1.829-1.572,2.768-0.471
				c0.433,0.508-0.114,1.421-0.547,1.669C181.28,576.776,180.974,575.343,180.128,574.968z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1303)} fill={props.fillColors[1303]} d="M324.749,627.109c-0.363-0.939,0.175-1.444,0.456-1.963
				c0.225-0.414,0.227-1.236,1.026-1.065c0.26,0.055,0.542,0.377,0.659,0.645c0.263,0.601-0.029,1.082-0.534,1.416
				C325.844,626.481,325.302,626.778,324.749,627.109z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1304)} fill={props.fillColors[1304]} d="M522.521,477.479c0.868-1.096,1.704-1.609,2.591-2.051
				c0.528-0.264,1.089-0.455,1.174,0.438c0.049,0.52,0.222,1.166-0.606,1.272C524.77,477.256,523.854,477.338,522.521,477.479z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1305)} fill={props.fillColors[1305]} d="M505.284,381.653c0.097,0.904,0.79,1.909-0.03,2.556
				c-0.811,0.64-1.291-0.983-2.308-0.821C504.055,383.256,503.999,381.763,505.284,381.653z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1306)} fill={props.fillColors[1306]} d="M218.81,621.152c-1.223,0.045-0.916-1.113-1.162-1.692
				c-0.341-0.8,0.525-0.717,1.02-0.742c1.016-0.053,1.052,0.713,1.095,1.421C219.803,620.787,219.517,621.177,218.81,621.152z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1307)} fill={props.fillColors[1307]} d="M56.8,617.051c-0.649,0.628-1.115,1.621-1.951,1.613c-0.849-0.007-0.523-1-0.468-1.531
				c0.125-1.191,1.093-0.811,1.816-0.799C56.66,616.341,56.877,616.598,56.8,617.051z"/>
			<path fill-rule="evenodd" d="M498.981,768.38c0.71,0.397,0.662,1.812,1.854,1.505c1.573-0.404,3.157-0.828,4.649-1.451
				c0.659-0.275-0.115-0.744-0.297-1.085c-1.802-3.378-4.447-6.027-7.357-8.444c1.771,0.886,3.179,0.028,4.47-1.195
				c0.386,0.865-0.284,0.988-0.482,1.355c-0.317,0.592-2.208,0.393-0.978,1.715c0.739,0.794,1.248,2.191,3.015,0.875
				c2.555-1.905,5.315-3.648,8.688-3.986c-2.507,1.354-5.053,2.64-7.507,4.085c-1.562,0.921-1.463,3.201,0.037,4.155
				c0.57,0.363,0.856-0.047,1.222-0.331c6.298-4.896,13.521-6.721,21.385-6.512c4.786,0.127,9.521,0.46,13.936,2.551
				c1.484,0.704,2.387-1.221,3.792-0.767l-0.043-0.051c-2.145,1.677-4.438,3.128-6.808,4.462c-0.726-3.204-0.722-3.23-4.47-3.825
				c-7.848-1.247-15.45-0.629-22.617,3.1c-0.746,0.388-1.679,0.594-2.099,1.93c4.002-0.134,7.86-0.022,11.495-1.676
				c4.655-2.117,9.459-2.877,14.487-1.691c1.319,0.311,2.698,0.588,3.288,2.079c0.026,1.141-1.525,1.428-1.379,2.782
				c2.605,0.632,5.391,0.913,7.942,2.03c1.104,0.482,1.781-0.395,2.614-0.78c-0.252,0.992-0.532,1.896,0.882,2.385
				c1.018,0.353,1.911,1.063,2.571,1.446c-19.847-7.724-39.883-6.427-60.068,0.539c0.586-1.875-0.646-2.992-1.82-4.057
				c-9.062-8.214-19.745-11.66-31.891-10.76c-1.109,0.082-2.223,0.126-3.334,0.188c1.01-1.548,2.814-0.994,4.042-1.049
				c10.071-0.45,19.514,1.714,27.785,7.785c2.239,1.644,4.373,3.457,5.948,5.802c0.492,0.733,1.054,0.84,1.841,0.538
				c1.038-0.397,2.084-0.833,3.167-1.04c1.549-0.298,2.485-0.922,2.104-2.669L498.981,768.38z"/>
			<path fill-rule="evenodd" d="M444.096,766.624c1.415,0.64,2.51,0.203,3.83-0.606c4.688-2.877,9.939-2.82,15.117-2.062
				c6.062,0.889,11.621,3.4,17.007,6.239c0.863,0.454,1.771,1.001,2.526,2.298c-2.306-1.279-4.208-2.515-6.252-3.436
				c-5.99-2.7-12.091-5.096-18.836-4.892c-3.392,0.103-6.609,0.914-9.565,2.669c-1.228,0.729-1.446,1.396-0.138,2.225
				c0.066,0.042,0.125,0.101,0.176,0.162c2.102,2.574,4.537,3.637,8.04,2.876c3.279-0.712,6.64,0.115,9.916,1.128
				c-0.582,0.765-1.16,0.213-1.682,0.154c-0.629-0.07-1.231-0.372-1.86-0.447c-3.312-0.396-6.648-1.019-9.854,0.523
				c-0.434,0.208-0.778,0.16-1.086-0.123c-2.438-2.245-4.863-4.503-7.293-6.757L444.096,766.624z"/>
			<path fill-rule="evenodd" d="M591.646,771.719c-6.341-2.363-12.571-5.156-19.566-4.63c-6.688,0.503-13.287,1.314-18.646,6.034
				C558.109,765.308,577.477,764.555,591.646,771.719z"/>
			<path fill-rule="evenodd" d="M583.479,773.306c-17.325-2.518-17.438-2.518-23.869,0.131
				C561.744,769.846,575.841,769.914,583.479,773.306z"/>
			<path fill-rule="evenodd" d="M313.17,773.548c0.436-3.172,1.583-5.999,3.177-8.653C315.699,767.956,314.852,770.941,313.17,773.548
				z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1308)} fill={props.fillColors[1308]} d="M34.368,466.188c-1.008-1.118-2.055-2.204-3.005-3.369
				c-0.381-0.466-0.847-1.167-0.381-1.727c0.418-0.502,1.146-0.332,1.789-0.15c2.171,0.615,3.7,2.329,5.68,3.266
				c0.286,0.136,0.572,0.27,0.854,0.413c1.198,0.609,3.024,1.985,3.617,0.757c0.648-1.345-0.831-2.966-2.55-3.598l0.081,0.065
				c-0.079-2.549,0.066-2.687,2.357-2.104c1.545,0.393,3.006,1.058,4.575,1.389c1.032,0.218,2.06,0.232,3.091,0.13
				c0.555-0.055,1.176-0.194,1.304-0.851c0.13-0.671-0.368-0.984-0.926-1.201c-0.445-0.173-0.904-0.317-1.316-0.567
				c-0.858-0.52-1.645-1.131-1.313-2.276c0.306-1.058,1.247-0.788,2.013-0.748c1.275,0.066,2.452-0.462,3.698-0.556l-0.098,0.061
				c1.258-0.806,2.377-1.942,4.038-1.819c0.503,0.037,1.213-0.181,1.37,0.599c0.114,0.568-0.388,0.834-0.772,1.095
				c-0.79,0.536-1.672,0.934-2.417,1.534c-0.408,0.329-1.058,0.618-0.762,1.307c0.237,0.549,0.829,0.336,1.281,0.358
				c1.588,0.076,3.219-0.252,4.751,0.29c0.836,0.296,2.292,0.278,1.662,1.926l0.053-0.094c-1.565,2.193-3.63,3.762-6.074,4.857
				c-0.383,0.223-0.767,0.446-1.15,0.669c-0.467,2.08-0.07,2.708,1.84,2.916c0.522,0.056,1.089-0.156,1.55,0.261l-0.097-0.044
				c1.636,0.963,1.637,1.589-0.12,2.707c-4.396,2.798-9.213,3.775-14.363,3.181c-1.419-0.163-2.858-0.278-4.13-1.044
				c-0.465-0.279-1.13-0.426-1.098-1.11c0.036-0.759,0.754-0.686,1.261-0.805c0.519-0.122,1.168,0.014,1.28-0.768
				c0.007-0.046-0.054-0.119-0.102-0.152c-0.063-0.045-0.146-0.063-0.219-0.093C39.092,469.492,36.633,467.989,34.368,466.188z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1309)} fill={props.fillColors[1309]} d="M101.674,500.703c0.062,0.062,0.123,0.125,0.185,0.188
				c2.066,1.545,2.055,1.53-0.112,3.105c-0.578,0.42-1.21,0.9-1.483,1.546c-2.005,4.742-6.492,5.403-10.672,6.572
				c-0.636,0.178-1.242-0.025-1.862-0.242c-2.417-0.844-4.879-1.467-7.479-1.366c-0.8,0.031-1.613-0.075-2.01-0.959
				c-0.012-0.027,0.061-0.107,0.109-0.145c0.062-0.048,0.139-0.078,0.209-0.115c1.207-1.018,2.413-2.034,3.62-3.052
				c1.744-2.731,4.659-2.295,7.268-2.688l-0.072,0.062c0.704-1.813,1.621-3.387,3.597-4.16c1.379-0.539,1.12-1.722,0.724-2.833
				c-0.624-1.449-1.249-2.899-1.873-4.349c-0.568-3.112,2.239-5.74,5.222-4.904c0.513,0.144,0.759,0.525,1.041,0.916
				c1.745,2.43,3.195,4.922,2.317,8.118C99.947,498.059,100.349,499.534,101.674,500.703z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1310)} fill={props.fillColors[1310]} d="M26.226,438.511c-0.575-0.55-1.25-0.909-2.031-1.066
				c-0.757-1.007-2.032-1.084-3.014-1.684c-0.846-0.516-1.316-1.287-1.369-2.292c0.051-0.102,0.103-0.204,0.154-0.306
				c2.131-0.564,4.208,0.711,6.343,0.181c0.639,0.382,1.349,0.697,1.522,1.54c1.897,1.059,4.307,1.021,5.893,2.762
				c0.26,0.285,0.651,0.457,0.647,1.075c-1.998,0.688-3.938-0.709-6.447-0.164c3.255,1.112,6.074,2.093,8.907,3.028
				c0.551,0.182,1.151,0.071,1.451-0.514c0.354-0.69-0.275-1.028-0.67-1.333c-2.556-1.976-5.136-3.921-7.724-5.854
				c-0.75-0.562-1.448-1.145-2.228-2.463c1.832,0.911,3.148,1.579,4.478,2.22c0.494,0.238,1.023,0.594,1.53,0.583
				c2.493-0.052,4.982-0.355,7.478,0.023c0.868,0.132,1.173-0.689,1.687-1.146c-0.158-0.116-0.175-0.251-0.051-0.402
				c0.013-0.016,0.093-0.02,0.228-0.02c2.123,0.414,3.967-0.658,5.938-1.074c0.79-0.167,1.981-0.563,2.355,0.325
				c0.328,0.779-0.737,1.185-1.422,1.591c-1.432,0.847-2.873,1.699-4.198,2.699c-1.12,0.845-2.027,2.066-1.473,3.515
				c0.537,1.402,2.088,1.187,3.167,0.964c2.457-0.506,4.864-1.272,7.267-2.012c0.706-0.217,1.339-0.405,2.41,0.138
				c-3.59,2.692-8.21,3.72-10.551,7.692c-1.056,0.883-2.062,0.984-2.981-0.191c-0.082-2.123-1.714-2.504-3.146-2.141
				c-4.573,1.157-8.033-1.119-11.498-3.391C27.924,440.172,26.737,439.695,26.226,438.511z"/>
			<path fill-rule="evenodd" d="M53.624,536.18c-6.247,3.206-12.852,4.752-19.889,4.268c-0.975-0.067-2.12-0.255-2.299-1.305
				c-0.277-1.63,1.667-1.005,2.361-1.897c-1.172-1.328-2.771-0.257-4.078-0.883c3.204-0.223,6.295,0.712,9.469,0.237
				c-1.928,1.322-4.157,1.098-6.14,1.834c-0.418,0.155-1.067,0.029-1.072,0.687c-0.005,0.641,0.636,0.646,1.058,0.72
				c3.678,0.639,7.296,0.239,10.885-0.67c0.54-0.137,0.836-0.432,0.912-0.957c0.177-1.223,0.895-2.194,1.45-3.251
				c1.305-2.488,0.715-3.507-2.058-3.687c-4.336-0.282-4.355-0.275-5.701-4.408c-0.593-1.821-2.167-1.92-3.635-2.158
				c-2.679-0.437-5.297-1.062-7.804-2.121c-1.177-0.498-2.005-1.128-1.035-2.506c0.099,2.101,1.812,2.46,3.3,2.838
				c2.285,0.581,4.615,1.02,6.951,1.347c1.56,0.22,2.471,1.137,2.939,2.425c1.368,3.76,1.689,4.063,5.693,4.309
				c2.838,0.174,3.331,1.14,2.068,3.814c-0.49,1.038-1.806,2.434-1.119,3.124c0.989,0.992,2.475-0.22,3.741-0.583
				C50.958,536.973,52.108,535.947,53.624,536.18z"/>
			<path fill-rule="evenodd" d="M78.559,509.287c0.557,1.104,1.585,1.097,2.598,1.074c1.506-0.032,3.045-0.135,4.445,0.486
				c4.503,1.997,8.24-0.164,11.98-2.171c0.734-0.394,1.5-1.125,1.788-1.878c0.761-1.986,2.222-3.141,4.105-3.964
				c-0.417-0.85-1.762-0.792-1.617-1.944c1.836,1.085,2.765,0.265,3.263-1.53c0.644-2.322,1.113-4.51,0.827-7.097
				c-0.279-2.52-0.424-5.562,1.476-7.928c-0.617,3.586-1.222,7.15-0.818,10.843c0.187,1.708-0.292,3.488-1.222,5.017
				c-0.581,0.955-0.409,1.716,0.287,2.469l0.036-0.051c-2.588,1.107-5.324,1.994-6.295,5.136c-0.353,1.142-1.598,1.396-2.501,1.977
				c-0.979,0.63-1.902,1.322-1.849,2.76c-0.724-0.435-0.731-1.671-1.93-1.017c-3.583,1.956-7.024-0.036-10.518-0.522
				c-2.89-0.401-5.781-0.799-8.666-1.235c-1.5-0.228-1.789,1.039-2.414,1.874c-1.003,1.342-1.265,3.245-3.043,3.998
				c0.152-0.278,0.314-0.552,0.455-0.836c1.681-3.393,3.174-7.05,8.199-5.393C77.562,509.493,78.084,509.317,78.559,509.287z"/>
			<path fill-rule="evenodd" d="M85.592,531.181c-1.473-0.051-2.948-0.508-3.966-1.409c-2.772-2.453-5.379-0.61-8.064-0.045
				c-0.849,0.179-1.023,0.929-1.452,1.472c0.128-1.012,0.029-2.09-1.172-2.169c-2.126-0.14-4.291-0.814-6.326,0.765
				c-1.851,1.437-3.957,2.544-5.95,3.797c1.264-2.304,4.014-2.666,5.753-4.398c0.938-0.935,1.972-1.767,2.776-2.634
				c-1.431-1.574-5.262,0.46-4.661-3.597c-0.027,1.871,1.381,2.089,2.72,2.257c0.931,0.117,1.889,0.022,2.775,0.022
				c0.425,1.099-0.625,1.734-0.444,2.756c3.016-0.459,5.946,2.246,9.109,0.188c0.63-0.41,1.467-0.573,2.09-1.074
				c2.997-2.412,6.51-3.476,10.261-3.704c1.137-0.069,1.906-0.59,2.773-1.093l-0.058-0.051c-0.008,1.909-1.47,1.809-2.686,1.816
				c-2.733,0.018-5.221,0.872-7.614,2.06c-0.592,0.294-1.547,0.475-1.497,1.323c0.049,0.819,0.87,1.186,1.509,1.667
				C82.729,530.079,84.588,529.771,85.592,531.181z"/>
			<path fill-rule="evenodd" d="M93.487,462.789c1.504-1.845,3.892-1.19,5.784-2.198c0.376,1.21-0.466,1.881-0.843,2.655
				c-1.349,2.769-1.361,2.784,1.677,2.84c-1.087,1.234-2.707,1.273-4.104,1.795c-1.642,0.613-2.636,1.622-2.013,3.416
				c0.742,2.133-0.378,2.718-2.127,2.945c-0.585,0.077-1.356-0.251-1.595,0.621c-0.201,0.734,0.367,1.184,0.8,1.62
				c0.743,0.745,1.375,1.528,1.48,2.623c-1.239-0.872-2.175-2.025-2.866-3.346c-0.696-1.331-1.359-1.202-2.361-0.29
				c-1.954,1.776-3.443,3.938-4.969,6.035c-1.038,1.428-2.325,1.632-3.825,1.791c3.626-0.676,4.545-4.12,6.494-6.427
				c1.525-1.805,2.938-3.103,5.486-3.044c1.801,0.041,3.65-0.392,2.624-3.107c-0.337-0.892,0.195-1.911,1.029-2.21
				c3.135-1.121,2.605-4.312,4.255-6.804C96.479,462.131,94.983,462.46,93.487,462.789z"/>
			<path fill-rule="evenodd" d="M53.935,455.062c-1.214,0.764-2.563,0.978-3.967,1.002c-0.459,0.009-0.985-0.054-1.24,0.487
				c-0.31,0.657,0.132,1.098,0.559,1.418c0.555,0.416,1.174,0.825,1.83,1.006c0.698,0.192,1.231,0.433,1.205,1.198
				c-0.026,0.74-0.624,1.003-1.245,1.249c-1.915,0.757-3.711,0.061-5.523-0.405c-0.764-0.196-1.54-0.416-2.248-0.757
				c-1.749-0.842-2.815-0.588-2.853,1.586c-0.765-0.744-0.595-1.665-0.135-2.361c0.555-0.843,1.399-0.266,2.173-0.109
				c2.622,0.526,5.059,2.02,7.879,1.535c0.447-0.077,1.081,0.084,1.067-0.594c-0.006-0.309-0.383-0.814-0.672-0.881
				c-1.783-0.409-3.134-1.101-3.04-3.275c0.031-0.737,0.37-3.375,0.777-4.208c0.652,0.308,0.51,0.71,0.119,1.115
				c-1.781,1.841-0.113,1.871,1.123,2.214C51.178,455.678,52.512,454.588,53.935,455.062z"/>
			<path fill-rule="evenodd" d="M41.617,470.893c0.937-0.135,1.494,0.269,1.742,1.23c-1.213,0.593-2.516-0.021-3.855,0.42
				c1.705,2.193,4.272,2.021,6.449,2.216c4.655,0.414,9.021-0.893,13.013-3.353c1.184-0.729,1.528-1.535,0.144-2.43
				c0.669,0.061,1.585-0.346,1.848,0.587c0.202,0.716-0.259,1.346-0.911,1.768c-3.343,2.162-6.818,3.95-10.953,3.829
				c-2.818-0.083-5.704,0.416-8.398-0.827c-0.804-0.37-2.128-0.433-2.017-1.627c0.093-0.995,1.333-0.83,2.086-1.115
				C41.155,471.443,41.505,471.34,41.617,470.893z"/>
			<path fill-rule="evenodd" d="M66.458,483.838c1.211-1.75,0.857-2.77-0.727-3.641c-1.347-0.741-0.983-2.089-0.282-3.099
				c1.075-1.55,2.352-2.959,3.508-4.454c0.433-0.559,0.717-1.235,1.166-1.778c0.395-0.479-0.88-1,0.093-1.337
				c0.145-0.05,0.438,0.152,0.583,0.312c0.501,0.554,0.122,1.094-0.188,1.513c-1.36,1.831-2.674,3.713-4.199,5.402
				c-1.303,1.443-1.065,2.626,0.404,3.44c1.415,0.785,1.875,2.363,3.237,3.206c0.942,0.584,0.394,3.827-0.609,4.441
				c-0.812,0.498-0.759,1.77-2.028,1.977c0.266-1.506,1.715-2.316,1.951-3.758c0.136-0.83,0.848-1.63,0.062-2.436
				C68.604,482.783,67.711,483.259,66.458,483.838z"/>
			<path fill-rule="evenodd" d="M101.674,500.703c-2.829-1.346-1.496-3.874-1.505-5.891c-0.008-1.768-1.262-6.164-2.725-6.873
				c-1.489-0.721-2.92,0.206-4.332,0.695c-1.953,0.678-1.125,2.329-1.289,3.634c-1.123-2.14-0.634-3.704,1.983-4.654
				c-1.515-0.987-2.991-1.729-3.279-3.516c-0.099-0.615-0.212-1.091,0.582-1.21c0.187,2.556,1.576,3.914,4.562,4.043
				c1.552,0.068,2.344,0.689,3.171,1.961c1.637,2.516,2.845,4.932,1.739,8.062C100.125,498.25,101.073,499.514,101.674,500.703z"/>
			<path fill-rule="evenodd" d="M48.055,515.466c1.125-0.821,2.251-1.642,3.856-2.813c-3.286,0.268-5.466,2.719-8.406,1.813
				c5.687-0.658,9.977-4.247,14.832-6.751c-3.139,2.377-5.563,5.572-9.005,7.592c-0.435,0.255-0.869,0.396-1.337,0.088L48.055,515.466
				z"/>
			<path fill-rule="evenodd" d="M78.871,459.201c-0.295,1.964,0.27,2.674,2.454,2.268c2.062-0.385,3.897,0.025,3.749,2.998
				c-0.104,2.081,1.757,0.797,2.681,1.155c-2.293,1.433-3.536,0.966-3.524-1.544c0.008-1.707-0.705-1.92-2.118-1.934
				c-3.738-0.038-6.854,1.721-10.067,3.245c-0.655,0.311-1.227,1.19-2.115,0.439l0.042,0.036c0.975-0.53,1.945-1.068,2.924-1.588
				C75.259,463.021,78.396,462.68,78.871,459.201z"/>
			<path fill-rule="evenodd" d="M62.865,516.771c-2.127,0.088-2.855-0.88-1.889-2.513c1.52-2.568,3.985-4.3,5.952-6.477
				c1.529-1.691,3.031-3.357,2.972-6.181c1.031,1.176,0.616,2.047,0.216,2.858c-1.098,2.226-2.738,4.033-4.516,5.734
				c-1.207,1.156-2.366,2.36-3.533,3.556C60.711,515.14,60.737,516.188,62.865,516.771z"/>
			<path fill-rule="evenodd" d="M62.986,460.411c-0.875-2.177-2.892-1.749-4.604-2.022c-1.286-0.205-3.358,0.648-3.713-0.397
				c-0.546-1.604,1.773-1.729,2.708-2.662c0.378-0.378,0.885-0.626,1.535-1.072c-2.113-1.259-3.415,0.907-5.074,0.865
				c1.354-1.473,2.716-2.893,5.035-2.169c0.54,0.168,1.157,0.167,1.246,0.881c0.07,0.567-0.402,0.873-0.794,1.099
				c-1.359,0.778-2.802,1.427-4.265,3.157c2.323,0,4.188-0.08,6.042,0.022C63.045,458.22,63.451,458.801,62.986,460.411z"/>
			<path fill-rule="evenodd" d="M41.581,485.711c0.363-1.026-0.238-1.823-0.678-2.59c-0.44-0.766-0.638-1.649-0.208-2.236
				c0.482-0.658,1.502-0.398,2.26-0.264c2.237,0.4,4.384,1.231,6.687,1.345c2.534,0.125,4.984-0.173,7.32-1.217
				c-2.687,1.657-5.538,2.354-8.693,1.7c-1.85-0.384-3.612-1.129-5.556-1.165c-1.518-0.028-2.166,0.203-1.19,1.785
				C41.999,483.842,42.581,484.71,41.581,485.711z"/>
			<path fill-rule="evenodd" d="M36.006,490.129c6.14,2.323,11.538-0.158,17.006-2.405c-3.407,2.724-8.185,2.555-11.673,5.575
				c0.331-1.281,0.204-2.151-1.435-1.851C38.472,491.711,37.221,490.991,36.006,490.129z"/>
			<path fill-rule="evenodd" d="M69.93,465.829c-0.833,2.349-2.532,3.893-4.691,5.471c-0.151-1.291,0.785-1.505,0.978-2.092
				c0.287-0.872,1.673-1.498,1.007-2.46c-0.547-0.79-1.768-0.285-2.679-0.336c-1.102-0.062-2.2,0.884-3.648-0.033
				c1.718-0.275,3.134-0.597,4.567-0.701c0.951-0.068,1.973-0.381,2.802,0.72c0.494,0.656,1.316,0.269,1.716-0.521
				C69.972,465.865,69.93,465.829,69.93,465.829z"/>
			<path fill-rule="evenodd" d="M44.555,510.277c-1.224-3.525-4.068,0.075-5.939-1.444c1.36-1.771,2.918-3.296,5.229-4.638
				c-1.216,1.724-2.518,2.806-4.134,3.931C44.8,508.709,44.8,508.709,44.555,510.277z"/>
			<path fill-rule="evenodd" d="M61.131,496.457c-0.298,0.528-0.63,1.111,0.444,0.969c1.001-0.133,1.986-0.393,2.988-0.523
				c2.5-0.328,2.962,0.258,2.184,3.18c0.446-3.753-1.923-2.355-3.534-2.286c-0.619,0.026-1.23,0.265-1.849,0.28
				c-0.557,0.015-1.458,0.537-1.581-0.385c-0.096-0.719,0.503-1.293,1.407-1.164L61.131,496.457z"/>
			<path fill-rule="evenodd" d="M55.99,486.433c1.917-0.012,1.917-0.012,0.922,5.115c1.304,0.047,1.663-1.59,2.894-1.716
				c-0.028,0.28,0.013,0.484-0.064,0.548c-0.941,0.793-2.011,2.403-2.994,1.879c-1.265-0.675,0.02-2.202,0.238-3.335
				C57.188,487.879,58.063,486.789,55.99,486.433z"/>
			<path fill-rule="evenodd" d="M35.373,449.075c1.857,1.65,4.229,1.421,6.421,1.58c1.892,0.137,3.724,0.42,5.544,0.954
				c-3.502-0.277-7.031-0.201-10.489-0.958c-0.855-0.188-1.832-0.279-1.444-1.603C35.41,449.043,35.373,449.075,35.373,449.075z"/>
			<path fill-rule="evenodd" d="M25.833,517.026c-2.508-1.228-4.254-3.319-6.06-5.344c1.938,0.986,3.723,2.312,6.006,2.621
				c-0.654,0.798-1.367-0.175-2.126,0.227C23.851,515.841,25.517,515.891,25.833,517.026z"/>
			<path fill-rule="evenodd" d="M25.528,478.096c-2.813-3.335-3.101-5.176-1.209-6.971C23.211,473.717,24.66,475.82,25.528,478.096z"
				/>
			<path fill-rule="evenodd" d="M62.08,477.571c-0.593-0.449,1.291-1.585-0.593-1.44c-0.467,0.035-1.141-0.026-0.846-0.689
				c0.432-0.97,0.546,0.221,0.902,0.255c1.239,0.121,2.394-0.91,3.79-0.12C64.521,476.792,63.141,476.974,62.08,477.571z"/>
			<path fill-rule="evenodd" d="M22.772,492.101c-1.213-1.828-2.979-3.421-2.522-5.962C20.575,488.346,22.274,489.967,22.772,492.101z
				"/>
			<path fill-rule="evenodd" d="M47.994,515.395c-1.905,2.372-4.755,2.89-7.502,3.608c2.523-1.177,5.046-2.354,7.566-3.533
				C48.055,515.466,47.994,515.395,47.994,515.395z"/>
			<path fill-rule="evenodd" d="M93.696,496.617c0.886,1.04,0.875,2.31-0.25,2.836c-1.946,0.912-2.926,2.571-4.072,4.157
				c0.274-1.833,0.995-3.392,2.876-4.032C93.799,499.052,93.519,497.749,93.696,496.617z"/>
			<path fill-rule="evenodd" d="M59.205,469.021c-0.876,0.187-1.715-0.008-2.569-0.199c-2.138-0.48-2.313-1.079-0.821-2.978
				C56.211,467.687,57.737,468.322,59.205,469.021z"/>
			<path fill-rule="evenodd" d="M89.447,503.548c-2.514,0.649-5.269,0.646-7.268,2.688C83.444,503.885,85.904,502.975,89.447,503.548z
				"/>
			<path fill-rule="evenodd" d="M61.19,496.527c0.861-2.339,3.233-3.342,4.639-5.198c-1.109,2.124-2.552,3.945-4.702,5.124
				C61.131,496.457,61.19,496.527,61.19,496.527z"/>
			<path fill-rule="evenodd" d="M56.965,465.175c2.025-1.619,4.049-3.238,6.074-4.857C61.855,462.987,59.707,464.452,56.965,465.175z"
				/>
			<path fill-rule="evenodd" d="M31.91,471.729c1.117,0.72,2.172,1.2,2.547,2.443c0.143,0.475,0.292,1.014-0.133,1.318
				c-0.271,0.194-1.04,0.215-1.11,0.075c-0.272-0.542,0.687-0.261,0.649-0.77C33.77,473.497,32.275,473.112,31.91,471.729z"/>
			<path fill-rule="evenodd" d="M39.793,495.168c0.424,1.644,1.24,2.824,3.077,3.025C40.718,498.297,39.486,497.466,39.793,495.168z"
				/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1311)} fill={props.fillColors[1311]} d="M29.26,397.647c1.213-1.217,2.346-2.498,3.229-3.982
				c1.504-1.612,2.733-3.567,5.107-4.095c0.752-0.188,1.504-0.376,2.249-0.562c0.471-0.017,0.951-0.071,1.427-0.042
				c0.517,0.03,1.241-0.182,1.378,0.55c0.095,0.505-0.498,0.722-0.854,1.025c-3.629,3.079-7.461,5.846-11.846,7.769
				C29.128,398.669,29.114,398.637,29.26,397.647z"/>
			<path fill-rule="evenodd" d="M37.596,389.57c-1.726,1.336-3.156,3.041-5.107,4.095c1.063-1.809,2.084-3.645,3.204-5.419
				c0.888-1.407,0.313-1.803-1.065-1.789c0.501-0.785,1.669-0.44,2.292-1.626c-3.484-1.088-6.75-0.277-10.514,0.896
				c1.126-1.073,1.037-1.837,0.133-2.408c-1.473-0.93-1.082,1.339-2.246,1.024c0.117-1.544,1.648-2.753,1.73-4.988
				c0.498,0.798,0.683,1.261,1.007,1.585c1.629,1.627,3.074,3.496,5.929,2.736c1.326-0.353,2.818-0.11,4.192,0.381
				c1.039,0.371,1.543,0.716,0.671,1.889c-0.827,1.112-1.34,2.457-2.131,3.967C36.564,389.755,37.08,389.663,37.596,389.57z"/>
			<path fill-rule="evenodd" d="M26.025,373.745c-0.812-2.388-2.225-0.499-3.376-0.474c0.827-1.522,2.521-0.987,3.765-1.526
				c0.672-0.291,0.711,0.675,0.924,1.129c0.43,0.915,0.107,1.863,0.055,2.854C26.409,375.375,27.453,373.709,26.025,373.745z"/>
			<path fill-rule="evenodd" d="M34.719,386.398c-1.564,1.756-3.715,2.347-5.86,2.947C30.645,388.03,32.508,386.869,34.719,386.398z"
				/>
			<path fill-rule="evenodd" d="M28.961,389.285c-1.366,1.508-3.148,2.426-4.869,3.429C25.324,391.015,27.078,390.059,28.961,389.285z
				"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1312)} fill={props.fillColors[1312]} d="M35.062,404.816c0.805-0.646,1.745-1.185,2.375-1.971
				c0.571-0.713,2.58-1.185,0.838-2.751c-0.085-0.077,0.876-1.318,1.493-2.229c9.806,11.455,15.544,24.811,19.544,39.078
				c-3.149-3.522-6.286-7.059-9.457-10.562c-0.917-1.013-0.416-1.325,0.602-1.483c0.535-0.084,0.911-0.397,0.879-0.923
				c-0.032-0.527-0.588-0.401-0.941-0.521c-0.37-0.125-0.726-0.387-1.096-0.402c-3.647-0.147-5.436-2.998-7.482-5.311
				c-1.793-2.026-0.971-4.725,1.675-5.985c-1.984-1.972-4.699-2.06-6.992-3.054c-0.206-0.09-0.481-0.002-0.711-0.055
				c-3.292-0.744-3.289-0.745-0.961-3.308c0.15-0.165,0.218-0.407,0.322-0.61C35.151,404.732,35.062,404.816,35.062,404.816z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1313)} fill={props.fillColors[1313]} d="M43.008,432.68c-0.086,0.13-0.146,0.271-0.177,0.422
				c-3.37,2.093-5.652-0.338-7.936-2.093c-1.875-1.441-3.432-3.3-4.755-5.287c-0.46-0.691-1.07-1.609-0.336-2.407
				c0.777-0.844,1.651-0.141,2.375,0.273c1.858,1.062,3.354,2.543,4.576,4.495c1.012-2.955,0.858-5.795,0.717-8.584
				c-0.122-2.412,0.755-4.706,0.527-7.101c-0.054-0.567,0.062-1.233,0.737-1.255c0.504-0.016,0.589,0.598,0.757,1.065
				c1.439,4.003,0.918,8.114,0.729,12.207c-0.021,0.463-0.215,0.916-0.264,1.38c-0.097,0.907,0.047,1.856,0.892,2.273
				c0.962,0.476,1.571-0.361,2.222-0.964c0.558-0.516,0.532-1.582,1.686-1.651c0.265,1.206,0.606,2.406,0.259,3.68
				c0.033,0.21-0.024,0.396-0.159,0.558C44.039,430.562,43.194,431.417,43.008,432.68z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1314)} fill={props.fillColors[1314]} d="M23.704,420.406c-1.79,0.567-3.164-0.088-4.361-1.397
				c0.373-0.798,1.146-0.709,1.675-0.768c4.766-0.529,8.423-4.299,13.277-4.507c0.919-0.039,2.143-1.312,2.683,0.078
				c0.438,1.127-0.119,2.562-1.156,3.358c-1.688,1.295-3.438,2.441-5.787,2.415c-1.94-0.022-4.008-0.506-5.821,0.717
				C24.087,420.552,23.946,420.729,23.704,420.406z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1315)} fill={props.fillColors[1315]} d="M23.704,420.406c0.174-0.035,0.348-0.07,0.516-0.104
				c1.978-0.271,4.001-0.431,5.95-0.068c2.646,0.492,4.487-0.661,6.05-2.597c0.226,0.303,0.311,0.41,0.389,0.521
				c0.786,1.128-1.502,1.71-0.647,2.484c1.154,1.047,0.549,2.172,0.653,3.257c0.125,1.308-0.186,1.879-1.509,0.815
				c-0.978-0.784-2.058-1.52-3.209-1.99c-1.373-0.561-3.055-1.282-3.181,1.373c-0.05,1.062-0.956,1.001-1.619,0.904
				c-1.794-0.261-3.574-0.631-5.354-0.989c-0.611-0.123-1.187-0.566-0.387-1.009C22.481,422.381,22.522,420.895,23.704,420.406z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1316)} fill={props.fillColors[1316]} d="M18.89,424.317c3.25,0.488,6.526,0.854,9.741,1.516
				c1.651,0.34,2.359,2.008,2.905,3.421c0.211,0.547-0.84,0.971-1.562,0.926c-3.972-0.25-7.955-0.262-11.454-2.761
				c-1.455-1.04-1.944-1.511-0.076-2.413c0.241-0.116,0.352-0.503,0.525-0.768C18.972,424.236,18.89,424.317,18.89,424.317z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1317)} fill={props.fillColors[1317]} d="M22.983,413.245c2.138-0.745,4.175-1.645,5.743-3.008
				c2.595-2.257,5.199-1.554,7.905-0.722c0.42,0.129,1.332,0.122,1.111,1.059c-0.175,0.744-0.156,1.609-1.198,1.815
				c-3.396,0.672-6.677,1.755-9.967,2.803C24.771,415.768,24.281,413.852,22.983,413.245z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1318)} fill={props.fillColors[1318]} d="M43.458,390.317c-0.673,2.653-2.308,4.697-3.829,6.815
				c-2.191,3.052-4.935,4.287-8.602,2.816c-0.341-0.137-0.766-0.064-1.921-0.139C34.649,396.99,39.161,393.857,43.458,390.317z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1319)} fill={props.fillColors[1319]} d="M57.916,437.753c-2.811-0.483-5.185,0.323-7.429,1.281
				c-1.636,0.697-2.375,0.015-3.051-1.15c-0.798-1.377,0.622-1.475,1.261-2.009c1.127-0.94,2.309-1.822,3.371-2.83
				c0.696-0.66,1.28-0.668,1.742,0.08C54.862,434.825,56.472,436.029,57.916,437.753z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1320)} fill={props.fillColors[1320]} d="M27.831,434.884c-0.585-0.12-1.169-0.24-1.754-0.36
				c-2.164,0.035-4.034-1.742-6.309-1.021l0.044-0.034c-0.758-0.337-1.486-0.837-1.341-1.717c0.139-0.842,1.122-0.699,1.746-0.954
				c3.359-1.371,5.855-0.033,9.102,4.086C28.745,434.884,28.288,434.884,27.831,434.884z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1321)} fill={props.fillColors[1321]} d="M44.858,429.691c0.055-0.19,0.11-0.382,0.162-0.565
				c0.25-0.567,0.529-1.133,0.751-1.721c0.62-1.636,1.212-1.531,2.241-0.205c0.849,1.094,2.065,1.902,3.587,3.253
				c-2.312,0.18-3.712,0.592-5.123,1c-0.527,0.152-1.185,0.796-1.718,0.235C44.186,431.088,44.517,430.347,44.858,429.691z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1322)} fill={props.fillColors[1322]} d="M58.506,438.397c2.491,3.204,2.493,3.21-1.144,4.182
				c-1.354,0.361-2.708,0.732-4.082,1.007c-0.388,0.078-1.039,0.225-1.204-0.388c-0.146-0.544,0.323-0.831,0.755-1.053
				C54.822,441.125,57.159,440.654,58.506,438.397z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1323)} fill={props.fillColors[1323]} d="M48.667,423.854c-2.361,0.767-4.485,0.803-6.562,1.055
				c-0.597,0.073-1.612-0.025-0.971-0.925c0.458-0.643-0.78-2.496,1.345-1.982C44.449,422.477,46.45,422.831,48.667,423.854z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1324)} fill={props.fillColors[1324]} d="M35.989,433.334c-2.626,0.539-4.539-0.903-6.806-1.914
				C32.427,429.949,32.827,430.069,35.989,433.334z"/>
			<path fill-rule="evenodd" d="M548.404,754.747c-14.637-5.447-28.98-4.826-43.556,2.063
				C511.321,749.708,535.901,748.667,548.404,754.747z"/>
			<path fill-rule="evenodd" d="M538.036,755.684c2.962,0.604,5.923,1.32,8.504,2.913c1.609,0.992,2.961-0.23,4.462-0.003
				c-1.864,0.827-3.561,1.995-5.637,2.205c-0.001,0,0.042,0.052,0.042,0.052c1.34-1.243,1.028-1.981-0.665-2.459
				c-1.819-0.514-3.585-1.219-5.412-1.696c-0.678-0.176-1.228-0.237-1.28-1.054c-0.129-0.032-0.194-0.048-0.194-0.048
				C537.79,755.582,537.85,755.612,538.036,755.684z"/>
			<path fill-rule="evenodd" d="M538.05,755.642c-1.725,1.411-3.571,0.176-5.266,0.024c-3.654-0.325-7.256-0.538-10.886,0.085
				c-0.422,0.072-0.883-0.077-1.306-0.288c5.838-1.133,11.632-0.567,17.433,0.234C538.036,755.684,538.05,755.642,538.05,755.642z"/>
			<path fill-rule="evenodd" d="M48.146,549.427c-2.229-0.781-4.102,0.558-6.537,1.312c0.678-0.643,0.996-0.943,1.099-1.041
				c-6.242-2.627-12.611-5.256-19.543-6.021c-0.988-0.109-1.943-0.673-2.875-1.109c-2.75-1.292-2.859-2.156-0.375-3.91
				c0.834-0.589,0.759-1.113,0.344-1.767c-0.566-0.891-0.988-1.809-0.964-2.887c2.181,1.44,2.442,3.974,0.461,5.219
				c-1.697,1.066-1.186,1.747,0.22,2.489c3.055,1.61,6.39,2.194,9.742,2.715c2.966,0.46,5.775,1.034,8.345,2.954
				c2.129,1.59,5.023,1.139,7.641,1.233C46.549,548.645,47.707,548.064,48.146,549.427z"/>
			<path fill-rule="evenodd" d="M68.01,534.732c-0.591,0.995-1.74,1.333-2.502,2.121c-0.424,0.438-1.155,0.776-0.952,1.518
				c0.194,0.705,0.854,0.833,1.539,0.835c0.385,0.001,0.774,0.105,1.153,0.199c3.583,0.887,3.601,0.904,1.951,4.285
				c-0.451,0.923-0.369,1.505,0.751,1.643c0.604,0.075,1.294-0.19,1.734,0.477c-2.132-0.144-4.242-0.203-5.846,1.593
				c1.135-1.598,2.312-3.169,3.39-4.804c0.652-0.987,0.813-1.902-0.802-2.179c-1.06-0.182-2.111-0.468-3.133-0.807
				c-1.695-0.562-2.035-1.729-0.681-2.847C65.611,535.942,66.526,534.811,68.01,534.732z"/>
			<path fill-rule="evenodd" d="M58.904,552.019c-4.121,1.932-8.519,2.806-12.997,3.301c-1.693,0.188-1.845-0.521-0.657-1.634
				c0.847,1.599,2.242,0.869,3.366,0.646C52.063,553.647,55.478,552.802,58.904,552.019z"/>
			<path fill-rule="evenodd" d="M540.638,667.748c2.355-2.934,5.816-4.047,9.166-4.951c2.973-0.802,6.043-1.876,9.31-1.047
				c-2.293,0.407-4.591,0.783-6.876,1.229c-3.332,0.65-6.531,1.659-9.311,3.706c-0.702,0.518-1.379,1.082-2.332,1.016L540.638,667.748
				z"/>
			<path fill-rule="evenodd" d="M531.753,678.299c-1.438-0.191-2.65-0.71-3.18-2.21c1.089,0.61,1.817,0.193,2.644-0.653
				c2.535-2.601,5.052-5.236,8.109-7.264c0.417-0.276,0.809-0.482,1.312-0.424l-0.044-0.047c-1.754,2.378-4.553,3.613-6.418,5.882
				c-0.793,0.965-1.693,1.854-2.626,2.687C530.61,677.105,531.384,677.656,531.753,678.299z"/>
			<path fill-rule="evenodd" d="M19.951,644.512c3.144-0.968,6.362-1.026,9.589-1.016C26.344,643.89,23.131,644.125,19.951,644.512z"
				/>
			<path fill-rule="evenodd" d="M569.424,595.354c-3.271-3.963-7.412-6.571-12.401-7.736c-2.684-0.627-5.357,0.146-7.94,0.71
				c-2.743,0.6-5.371,1.733-8.05,2.634c1.899-2.235,4.769-2.205,7.249-2.9c7.907-2.216,14.816-0.483,20.301,5.885
				C568.934,594.354,569.146,594.881,569.424,595.354z"/>
			<path fill-rule="evenodd" d="M561.514,582.258c-0.146,1.832,0.41,1.977,2.974,0.771c-0.443,1.611,1.685,3.5,3.418,2.979
				c0.798-0.24,1.543-0.659,2.552-0.884c-2.751,0.611-2.064,1.969-0.995,3.418c-3.002-2.463-6.402-4.12-10.207-4.964
				C559.592,582.326,560.602,582.379,561.514,582.258z"/>
			<path fill-rule="evenodd" d="M570.565,589.945c2.746,0.082,5.154-1.492,7.865-1.595c3.268-0.123,6.46-0.039,8.754,2.834
				c-4.446-3.104-9.143-2.867-13.958-1.05C572.312,590.48,571.367,591.203,570.565,589.945z"/>
			<path fill-rule="evenodd" d="M559.386,585.802c-4.946-1.264-9.858-1.177-15.097,0.935
				C547.323,584.523,553.008,584.201,559.386,585.802z"/>
			<path fill-rule="evenodd" d="M569.355,591.692c-2.326-2.669-5.592-3.941-8.6-5.614
				C564.268,586.897,567.181,588.677,569.355,591.692z"/>
			<path fill-rule="evenodd" d="M592.243,603.889c-4.445-0.183-8.876,0.026-13.298,0.49
				C583.337,603.106,587.783,603.311,592.243,603.889z"/>
			<path fill-rule="evenodd" d="M568.239,598.079c0.727,0.17,1.453,0.34,2.18,0.51c-1.306,0.734-2.762,1.023-4.173,1.455
				c-0.762,0.232-1.766,0.364-1.618,1.587c-0.551-0.377-1.101-0.753-1.605-1.099C564.671,599.375,566.953,599.842,568.239,598.079z"/>
			<path fill-rule="evenodd" d="M578.897,600.866c3.43-1.174,6.959-0.439,10.44-0.631C585.856,600.429,582.35,600.12,578.897,600.866z
				"/>
			<path fill-rule="evenodd" d="M570.313,598.626c0.097-0.334,0.614-0.562,0.255-1.012c1.271,0.736,2.443-0.149,3.576-0.404
				c0.998-0.226,1.848-0.01,2.796,0.143C574.713,597.719,572.62,598.684,570.313,598.626z"/>
			<path fill-rule="evenodd" d="M579.056,604.334c-2.758,0.66-5.515,1.319-8.271,1.979
				C573.325,604.75,576.216,604.648,579.056,604.334z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1325)} fill={props.fillColors[1325]} d="M522.79,704.178c1.947,2.758-0.714,4.647-1.738,6.416
				c-0.88,1.519-3.284,0.44-4.916-0.424c-1.59,0.732-2.501-0.708-3.724-1.157c-5.118-4.018-10.508-7.63-16.81-9.452
				c-6.866-1.984-13.868-3.105-21.059-1.806c-2.388,0.432-4.642,1.207-6.775,2.317c-1.875,0.976-3.494,0.928-4.858-0.839
				c-0.097-0.125-0.223-0.229-0.338-0.339c-3.259-3.122-3.26-3.123,0.148-6.139c0.962-0.852,2.018-1.566,3.166-2.147
				c0.233-0.125,0.473-0.232,0.719-0.329c8.976-3.05,18.051-3.679,27.235-1.045c1.283,0.368,2.519,0.163,3.778,0.098
				c2.584,0.638,4.894,2.036,7.465,2.711c5.607,3.467,12.317,5.164,16.809,10.435c0.356,0.418,0.831,0.753,0.919,1.353
				C522.805,703.946,522.797,704.062,522.79,704.178z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1326)} fill={props.fillColors[1326]} d="M557.41,735.877c-1.97,1.65-3.681,1.811-6.112,0.276
				c-5.519-3.482-11.794-5.244-18.188-6.368c-4.439-0.78-8.922-1.413-13.453-1.426c-1.341-0.004-2.652,0.04-4.012,0.354
				c-3.36,0.773-5.29-0.252-9.187-4.425c6.641-2.066,13.314-0.63,19.903-0.927c-1.39-0.734-2.865-0.444-4.298-0.457
				c-4.075-0.036-8.151-0.02-12.228-0.026c-0.796-0.001-1.575-0.034-2.353,0.289c-1.891,0.788-3.496,0.364-4.867-1.358
				c1.584-1.164,3.473-1.152,5.172-1.323c11.445-1.148,22.805-0.932,33.866,2.772c0.676,0.226,1.391,0.333,2.087,0.496
				c1.345-1.549,2.925-0.503,4.116-0.019c1.8,0.733,4.266,0.681,5,3.167c2.862,2.026,5.729,4.048,8.586,6.082
				c0.607,0.434,1.185,0.908,2.181,1.676c-2.449,0.223-4.195,1.386-6.263,1.258c-1.324-2.183-3.729-2.895-5.742-4.083
				c-3.977-2.347-8.32-3.898-11.705-5.027c4.855,1.543,10.43,3.985,15.621,7.282C556.306,734.579,556.897,735.164,557.41,735.877z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1327)} fill={props.fillColors[1327]} d="M459.012,716.475c0.421,0.528,1.199,0.826,1.09,1.693
				c-1.233,1.911-2.7,2.106-4.008,0.197c-3.492-5.103-8.708-7.393-14.272-9.22c-0.784-0.258-1.52-0.468-2.492,0.125
				c7.018,1.793,13.259,4.549,17.075,10.981c-1.333,0.994-2.162,0.857-3.356-0.473c-7.094-7.907-16.05-9.259-25.899-6.827
				c-3.459,0.854-6.777,2.205-9.437,4.66c-1.525,1.408-2.224-0.679-3.391-0.498l0.087,0.082c-3.242-2.385-3.242-2.385,0.438-4.228
				c0.214-0.107,0.407-0.254,0.597-0.294c-1.071,0.459-2.149,1.025-3.163,1.688c-1.245,0.814-2.214,0.108-2.981-0.635
				c-0.956-0.925-0.098-1.703,0.55-2.433c3.271-3.688,7.664-5.358,12.188-6.77c9.491-2.958,18.466-1.188,27.156,3.048
				c1.5,1.026,3,2.052,4.5,3.078C455.445,712.612,457.7,714.112,459.012,716.475z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1328)} fill={props.fillColors[1328]} d="M504.327,693.145c-2.056-0.791-4.111-1.583-6.166-2.375
				c-3.747-0.625-7.336-1.915-11.12-2.454c-6.611-0.941-12.976-0.706-18.893,2.796c-0.483,0.287-1.075,0.279-1.523,0.633l0.098-0.064
				c-2.073,1.344-4.433,2.176-6.459,3.597c-0.731,0.512-1.287,0.207-1.972-0.121c-1.094-0.523-1.745-1.656-2.933-2.015
				c-0.455-0.366-0.965-0.686-1.097-1.32c0.132-0.104,0.263-0.209,0.393-0.313c5.167-3.831,11.03-6.165,17.329-6.913
				c14.239-1.693,28.152-0.341,41.38,5.648c0.927,0.419,1.985,0.538,2.792,1.224c2.038,0.241,3.548,1.749,5.464,2.276
				c1.901,1.788,3.755,3.635,5.727,5.346c1.226,1.064,1.286,1.838,0.072,2.988c-0.723,0.686-2.029,1.186-1.67,2.627
				c-1.153,0.77-2.061,0.148-2.959-0.525l0.089,0.076c-2.535-4.005-6.592-6.037-10.611-8.069
				C509.735,694.903,506.956,694.206,504.327,693.145z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1329)} fill={props.fillColors[1329]} d="M513.746,708.312c0.798,0.62,1.595,1.24,2.391,1.859
				c0.836,1.062,0.27,1.409-0.765,1.385c-2.391-0.057-4.725,0.3-7.037,0.854c-0.698,0.167-1.253-0.186-1.781-0.514
				c-1.228-0.761-2.532-1.229-3.983-1.257c-1.633,1.321-2.883-0.579-4.37-0.514c-5.229-2.896-10.933-4.27-16.757-5.282
				c-1.432-0.249-2.791-0.332-4.241,0.061c-2.471,0.67-5.059-0.22-7.577,0.384c-0.586,0.141-3.876-3.223-4.021-3.861
				c-0.17-0.747,0.375-0.942,0.871-1.213c3.615-1.98,7.432-3.13,11.598-3.237c6.911-0.179,13.742,0.359,20.168,2.997
				C503.674,702.204,508.72,705.267,513.746,708.312z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1330)} fill={props.fillColors[1330]} d="M499.603,709.011c0.99,0.543,1.981,1.087,2.97,1.63
				c1.028,0.561,2.058,1.121,3.403,1.854c-2.291,0.811-4.235,1.479-6.164,2.189c-0.812,0.299-1.155-0.176-1.768-0.625
				c-3.189-2.343-7.045-2.794-10.842-3.358c2.313,0.72,4.642,1.395,6.933,2.178c1.028,0.352,2.01,0.87,2.964,1.401
				c0.849,0.473,0.885,0.897-0.062,1.485c-2.812,1.748-5.582,2.247-8.888,1.142c-2.483-0.83-5.264-0.959-7.921-1.022
				c-2.053-0.049-3.836-0.188-4.97-2.135l0.093,0.101c-1.686-4.13-1.686-4.13,2.908-4.13c0.24,0,0.479,0,0.72,0
				c-0.879,0-1.833-0.225-2.624,0.04c-3.021,1.011-4.463-0.988-6.061-2.886c-1.004-1.191-0.723-1.75,0.679-2.166
				c4.443-1.321,8.839-0.675,13.238,0.219C489.423,705.985,494.488,707.59,499.603,709.011z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1331)} fill={props.fillColors[1331]} d="M552.858,726.902c-3.04-1.051-6.081-2.101-9.119-3.149
				c-3.731-2.237-7.963-2.86-12.123-3.681c-5.724-1.13-11.538-1.171-17.318-0.886c-4.14,0.205-8.373,0.345-12.296,1.939
				c-1.391,0.565-1.989-0.526-2.991-1.043c10.595-4.503,21.817-4.274,32.943-3.817c14.567,0.599,26.513,7.541,37.736,16.606
				c-4.628,1.942-5.104,1.846-9.675-1.774C557.825,729.363,555.527,727.818,552.858,726.902z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1332)} fill={props.fillColors[1332]} d="M545.582,740.537c0,0-0.039-0.026-0.038-0.025c-2.712-2.123-6.118-1.905-9.223-2.706
				c-0.521-0.135-1.098,0.071-1.589-0.349c-5.079-4.335-11.278-4.263-17.286-3.735c-2.829,0.249-3.115-1.598-4.324-2.843
				c-0.245-0.252-0.573-0.589-0.29-1.298c14.002-1.37,27.242,1.211,40.059,8.334C550.275,739.251,548.382,741.086,545.582,740.537z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1333)} fill={props.fillColors[1333]} d="M590.382,721.445c3.974,2.931,3.968,2.92-0.305,5.399
				c-0.875,0.507-1.728,0.729-2.676,0.274c-0.3,0.397-0.725,0.75-1.188,0.587c-1.938-0.681-4.146-0.812-5.561-2.611
				c-0.705-0.812-1.646-0.852-2.623-0.834c-1.915,0.034-3.866-0.192-5.737,0.103c-3.363,0.532-5.848-0.995-8.315-2.883
				c-1.08-0.827-0.669-1.148,0.245-1.602c1.959-0.973,4.1-1.173,6.196-1.293c6.865-0.394,13.637,0.059,20.039,2.921
				C590.456,721.507,590.382,721.445,590.382,721.445z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1334)} fill={props.fillColors[1334]} d="M580.653,725.095c2.551-0.329,4.516,1.294,6.748,2.024
				c0.279,1.595-1.945,4.338-3.732,4.5c-1.954,0.177-3.951-0.272-5.877,0.456c-0.306,0.116-0.948-0.004-1.098-0.234
				c-1.748-2.695-4.958-3.834-7.138-6.72C573.473,724.654,577.066,724.988,580.653,725.095z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1335)} fill={props.fillColors[1335]} d="M533.178,737.255c-4.578,0.233-9.13-0.298-13.569,0.943
				c-1.071,0.299-3.406-2.007-3.294-3.052c0.058-0.544,0.487-0.775,1.011-0.783c5.086-0.073,10.188-0.271,15.014,1.824
				C532.722,736.354,533.068,736.47,533.178,737.255z"/>
			<path fill-rule="evenodd" d="M573.457,608.664c0.898,0.139,1.702-0.104,2.545-0.44c4.501-1.802,9.142-1.883,13.843-0.972
				c-4.197-0.229-8.432-0.561-12.451,0.983C576.044,608.753,574.926,609.835,573.457,608.664z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1336)} fill={props.fillColors[1336]} d="M585.324,677.123c-2.256-0.976-4.512-1.951-6.768-2.927
				c-9.678-3.694-19.158-3.578-28.383,1.471c-1.73,0.216-3.025,1.358-4.501,2.118c-1.88,0.874-3.742,1.837-5.223,3.277
				c-1.649,1.604-3.113,1.568-4.7,0.059l0.08,0.087c-0.835-1.282,0.104-1.975,0.919-2.676c3.044-2.619,6.61-4.4,10.251-5.945
				c4.696-1.994,9.472-3.78,14.569-4.633c7.893-1.321,15.25-0.101,22.306,3.696c2.383,1.282,4.311,2.942,6.095,4.864
				c0.554,0.598,1.496,1.361,0.684,2.185c-0.817,0.828-1.978,1.075-2.929-0.063C587.087,677.873,586.205,677.499,585.324,677.123z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1337)} fill={props.fillColors[1337]} d="M31.231,453.17c0.342,0.68,1.301,0.915,1.358,1.938
				c-1.507-0.009-2.365-1.041-3.059-2.013c-0.939-1.316-2.155-2.352-3.151-3.589c-0.459-0.572-1.964-0.854-0.711-2.15
				c0.37-0.383-0.435-0.634-0.786-0.896c-2.037-1.524-4.594-2.406-6.134-4.901c3.384,0.18,6.584,0.288,9.771,0.54
				c1.961,0.154,3.384,1.598,5.047,2.46c1.039,0.539,0.142,1.422,0.174,2.13c0.045,1.004-0.873,0.752-1.431,0.717
				c-1.096-0.069-2.18-0.303-3.274-0.412c-1.796-0.182-1.873,0.717-1.18,2.003c0.878,1.628,2.003,3.064,3.443,4.241
				C31.299,453.237,31.231,453.17,31.231,453.17z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1338)} fill={props.fillColors[1338]} d="M23.207,449.023c2.045,1.321,3.62,3.052,5.024,4.962
				c0.355,0.484,0.299,0.882-0.013,1.273c-0.092,0.115-0.568,0.124-0.591,0.068C26.628,452.905,24.245,451.423,23.207,449.023z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1339)} fill={props.fillColors[1339]} d="M251.008,79.862c0.475,3.771,0.981,7.453,0.103,11.156
				c-1.474,6.214-8.05,10.406-14.268,9.013c-1.034-0.231-2.055-0.189-3.068-0.368c-3.374-0.597-4.623-3.23-5.634-6.021
				c-0.32-0.883-0.498-2.327-1.412-2.112c-1.228,0.287-1.112,1.817-0.86,2.868c0.67,2.796,1.973,5.088,4.849,6.334
				c3.209,1.392,3.223,1.572,1.062,4.342c-2.497,3.201-5.184,6.24-8.576,8.559c-4.318,2.952-8.814,2.779-12.927-0.04
				c-5.917-4.055-8.953-9.919-9.086-17.133c-0.07-3.773,0.169-4.074,3.608-5.143c1.189-0.37,2.176-1.001,3.126-1.678
				c1.807-1.287,1.953-3.644,0.463-5.219c-1.282-1.356-3.445-1.314-4.93,0.162c-1.202,1.195-2.047,2.669-2.643,4.226
				c-0.593,1.548-1.499,1.55-2.962,1.367c-5.591-0.702-9.003-4.251-11.86-8.627c-0.216-0.331-0.352-0.713-0.543-1.062
				c-0.456-0.828-0.91-2.013-2.028-1.543c-0.907,0.381-0.406,1.592-0.343,2.39c0.208,2.636,1.232,5.128,0.484,7.938
				c-0.632,2.375-2.422,3.157-4.158,3.672c-1.455,0.431-2.441-0.941-2.838-2.494c-0.105-0.409-0.537-1.075-1.238-0.871
				c-0.765,0.223-0.923,0.935-0.761,1.557c0.312,1.208,0.836,2.359,1.172,3.562c1.206,4.324-2.925,8.989-7.135,8.088
				c-0.838-0.179-0.679-1.012-0.865-1.527c-0.981-2.702-2.124-5.258-4.302-7.27c-2.254-2.082-4.522-2.332-6.539-0.543
				c-1.443,1.28-1.482,4.412-0.128,6.356c1.89,2.714,4.23,4.656,7.697,4.943c1.35,0.112,2.092,0.996,2.187,2.37
				c0.48,6.995-0.759,13.48-6.649,17.983c-5.819,4.449-11.624,5.249-17.033-0.791c-1.076-1.202-1.575-2.252-0.369-3.91
				c1.271-1.745,1.175-3.899-0.08-5.812c-0.459-0.7-1.2-1.21-1.992-1.183c-0.887,0.031-1.408,0.877-1.511,1.653
				c-0.194,1.456-1.089,2.826-0.482,4.396c0.754,1.948-0.425,2.947-1.988,3.665c-1.585,0.728-2.92,0.326-3.951-1.041
				c-1.718-2.278-2.36-4.982-2.836-7.707c-0.378-2.164-1.023-2.597-2.941-1.47c-1.847,1.085-3.536,2.386-5.897,2.259
				c-3.276-0.177-3.763-0.567-3.72-3.693c0.012-0.889,0.101-1.838-1.185-1.934c-0.938-0.07-1.339,0.342-1.66,1.282
				c-1.103,3.23-2.922,4.445-6.49,4.31c-6.413-0.243-10.265-4.212-10.291-10.312c-0.003-0.935,0-1.869,0-2.859
				c-1.075,0.145-1.445,0.886-1.599,1.497c-0.928,3.693-3.978,4.218-6.966,4.61c-3.617,0.474-6.121-1.606-8.324-4.153
				c-0.633-0.732,0.289-1.334,0.096-2.025c-0.296-1.057,0.771-2.795-0.821-3.104c-1.973-0.382-1.541,1.767-1.757,2.742
				c-0.634,2.858-2.258,4.695-5.144,5.128c-2.71,0.407-3.491-0.081-4.282-2.84c-0.174-0.606-0.015-1.31-0.958-1.34
				c-0.984-0.032-0.823,0.687-0.972,1.299c-0.409,1.691-0.984,3.306-2.325,4.549c-2.293,2.124-5.023,2.053-7.116-0.189
				c-1.017-1.089-0.988-1.867,0.423-2.583c2.307-1.17,4.089-3.01,5.865-4.842c0.428-0.442,1.123-0.903,0.354-1.553
				c-0.632-0.535-1.316-0.453-1.869,0.231c-1.517,1.879-3.57,3.074-5.893,4.775c0.505-3.092,2.35-4.989,3.575-7.3
				c-2.845,1.609-4.494,3.696-4.956,6.721c-0.157,1.033-0.702,1.395-1.423,1.607c-5.494,1.623-10.815,1.565-15.555-2.123
				c-2.501-1.946-4.137-4.504-3.597-7.834c0.666-4.1,3.594-6.418,7.213-7.626c1.52-0.507,3.439,0.249,5.181,0.311
				c0.616,0.022,1.61,0.473,1.812-0.555c0.198-1.006-0.762-1.219-1.546-1.597c5.322-1.713,10.649-1.925,16.082-0.864
				c3.41,0.666,6.298,2.462,9.357,3.917c0.934,0.444,2.302,1.783,3.076,0.334c0.766-1.433-0.98-1.883-1.968-2.42
				c-2.769-1.507-5.846-2.496-7.736-5.345c-2.917-4.397-2.368-8.674,1.741-12.229c0.675,0.768-0.122,1.296-0.337,1.882
				c-0.25,0.681-0.713,1.445,0.12,1.878c0.803,0.417,1.342-0.362,1.715-0.944c0.782-1.219,1.491-2.569,1.066-4.037
				c-0.337-1.163,0.125-1.827,0.918-2.401c4.195-3.037,8.595-5.667,13.741-6.782c1.539-0.334,2.957-0.415,4.099,1.04
				c1.152,1.466,3.056,1.091,4.601,1.604c0.489,0.162,0.974-0.168,1.156-0.65c0.172-0.456,0.04-0.904-0.296-1.324
				c-1.038-1.293-2.375-2.439-3.935-2.518c-2.261-0.113-2.612-1.698-2.428-3.054c0.798-5.895,4.079-10.811,11.923-8.786
				c0.984,0.254,1.963,0.531,2.956,0.745c1.655,0.355,2.703,1.206,3.32,2.9c0.692,1.902,2.043,3.384,4.174,3.881
				c0.498,0.116,0.945,0.706,1.519,0.143c0.575-0.564,0.247-1.164,0.129-1.778c-0.348-1.817-1.725-2.979-3.047-3.863
				c-2.765-1.847-2.137-4.329-1.652-6.767c1.678-8.441,7.96-12.036,15.377-14.382c4.43-1.401,9.084-1.755,13.611-1.736
				c8.015,0.034,15.562,2.347,22.45,6.619c3.566,2.212,7.02,4.614,10.079,7.439c3.365,3.109,6.71,6.433,6.703,11.592
				c0,0.379,0.208,0.761,0.332,1.138c0.194,0.59,0.259,1.44,1.094,1.329c1.018-0.136,0.901-1.144,0.699-1.731
				c-0.573-1.664-0.674-3.155,0.993-4.199c2.032-1.271,4.136-2.285,6.637-1.242c0.787,0.328,1.013,0.817,1.067,1.64
				c0.088,1.342,0.59,2.547,1.908,3.57c0.282-1.305,0.371-2.322-0.079-3.422c-1.034-2.531-0.129-5.502,2-6.881
				c1.707-1.106,6.289-0.718,7.724,0.6c0.719,0.66,0.437,1.474,0.518,2.24c0.093,0.882-0.307,2.22,1.167,2.106
				c1.194-0.091,1.757-1.373,1.468-2.312c-0.737-2.389,0.789-2.255,2.236-2.306c5.015-0.178,7.714,2.62,7.666,7.68
				c-0.023,2.367-2.17,3.103-3.204,4.68c-2.869,4.377-3.305,9.71,0.932,13.214c3.93,3.25,9.884,1.765,10.926-2.895
				c0.908-4.06-2.803-9.455-7.902-8.118c-0.697,0.183-1.261,0.438-1.178,1.216c0.108,1.014,0.892,0.627,1.468,0.561
				c1.768-0.205,3.41,0.044,4.423,1.664c0.98,1.568,1.815,3.283,0.654,5.113c-1.062,1.674-4.003,2.514-6.306,1.687
				c-1.908-0.685-3.16-2.055-3.218-4.271c-0.148-5.594,3.918-11.224,10.388-12.892c9.149-2.359,16.761,3.306,17.841,12.741
				c0.289,2.529-0.09,4.868-0.374,7.284c-0.082,0.696-0.498,1.562,0.235,1.972c0.893,0.501,1.117-0.514,1.569-1.023
				c3.783-4.261,7.901-7.976,13.743-9.253c2.074-0.453,3.998-0.213,5.768,0.633c4.01,1.916,7.29,4.716,9.359,8.754
				c0.292,0.571,0.743,1.426,0.166,1.758c-2.446,1.409-0.355,1.64,0.467,2.364c1.229,1.083,2.521,2.116,2.509,4.081
				c-0.023,3.721-1.545,4.887-5.044,3.601c-0.67-0.246-1.251-0.434-1.685,0.226c-0.529,0.803,0.32,1.143,0.71,1.53
				c1.812,1.8,3.688,3.551,4.857,5.862c0.77,1.52,2.058,3.229,0.544,4.811c-1.542,1.61-3.723,2.267-5.902,1.494
				c-5.086-1.806-7.996-5.682-9.377-10.733C252.856,82.479,252.18,81.082,251.008,79.862z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1340)} fill={props.fillColors[1340]} d="M63.546,82.882c-5.256-1.07-10.35-1-15.419,0.08c-0.696,0.148-1.441,0.215-2.068,0.516
				c-5.136,2.469-8.904-0.184-12.523-3.32c-1.683-1.458-1.409-2.903,0.076-4.438c4.479-4.632,10-5.81,16.072-3.312
				c0.802,0.33,1.56,0.862,2.389,1.009c0.983,0.174,1.87,0.188,1.434-1.495c-1.504-5.801,1.91-9.187,7.958-8.043
				c0.463,0.087,0.909,0.256,1.369,0.366c3.8,0.907,3.924,1.397,1.893,4.443C61.848,73.002,60.365,76.685,63.546,82.882z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1341)} fill={props.fillColors[1341]} d="M165.399,102.774c-3.343-0.823-6.234-1.667-7.659-4.886
				c-0.375-0.845-0.474-1.657-0.447-2.539c0.035-1.131,0.881-1.709,1.686-2.108c1.104-0.547,1.288,0.724,1.638,1.262
				C162.3,97.095,164.391,99.479,165.399,102.774z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1342)} fill={props.fillColors[1342]} d="M202.827,89.882c0.381-0.829,0.498-1.108,0.634-1.376
				c0.629-1.24,1.645-1.88,3.033-1.87c0.172,0.001,0.506,0.28,0.49,0.39c-0.198,1.345-1.483,1.465-2.374,2.024
				C204.229,89.289,203.791,89.438,202.827,89.882z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1343)} fill={props.fillColors[1343]} d="M410.1,92.576c-0.309-2.284-0.1-4.54-0.192-6.788
				c-0.199-4.833,3.036-7.658,7.159-8.432c3.18-0.596,6.602-0.958,9.128,1.996c0.84,0.982,1.275,2.175,1.672,3.392
				c0.126,0.385,0.285,1.009,0.952,0.795c0.441-0.142,0.458-0.596,0.531-0.992c0.41-2.227-1.337-4.965-4.321-6.177
				c-1.729-0.702-1.712-1.045-0.542-2.46c2.077-2.512,3.884-5.248,6.71-7.021c5.145-3.229,9.533-1.7,13.346,2.475
				c2.25,2.464,3.387,5.663,3.973,8.98c0.449,2.541-0.625,4.447-2.989,5.441c-0.963,0.406-1.794,0.986-2.572,1.711
				c-1.134,1.055-0.91,2.107-0.242,3.219c0.763,1.269,1.878,1.284,3.134,0.841c0.834-0.294,1.103-1.096,1.653-1.631
				c0.99-0.963,0.668-2.955,2.528-3.185c2.106-0.261,3.922,0.638,5.573,1.702c1.928,1.243,3.441,2.966,4.529,5.034
				c0.425,0.807,0.93,2.045,1.928,1.657c0.8-0.311,0.331-1.507,0.199-2.381c-0.244-1.607-0.441-3.208-0.32-4.885
				c0.14-1.95,1.367-2.598,2.788-3.142c1.576-0.604,2.031,0.691,2.6,1.74c0.229,0.42,0.529,0.851,1.125,0.538
				c0.508-0.266,0.56-0.604,0.343-1.172c-0.418-1.1-0.735-2.246-1.014-3.392c-0.655-2.696,2.58-6.033,5.308-5.514
				c0.86,0.164,1.023,0.795,1.245,1.533c0.671,2.236,1.865,4.137,3.604,5.764c1.515,1.417,2.987,0.974,4.297,0.063
				c1.265-0.879,1.527-2.207,0.836-3.782c-1.256-2.862-3.487-4.524-6.364-5.228c-1.35-0.33-1.517-0.893-1.571-2.076
				c-0.23-4.939,0.532-9.488,4.37-13.065c4.297-4.006,10.894-3.746,13.852,0.538c0.646,0.936,0.81,1.472,0.131,2.626
				c-0.896,1.523-1.389,3.502,0.353,4.994c0.873,0.748,1.711,1.099,2.246-0.585c0.38-1.196,0.479-2.354,0.354-3.512
				c-0.192-1.756,0.666-2.719,2.169-3.092c1.575-0.391,2.547,0.623,3.141,1.936c0.673,1.491,1.364,3.003,1.422,4.679
				c0.069,2.024,0.965,2.38,2.579,1.341c1.293-0.833,2.643-1.433,4.223-1.434c1.784,0,3.253,0.261,2.903,2.623
				c-0.114,0.776,0.051,1.504,1.058,1.485c0.9-0.017,1.239-0.598,1.195-1.447c-0.073-1.404,0.823-1.86,1.989-2.254
				c5.639-1.902,10.634,1.596,10.668,7.52c0.004,0.692,0.062,1.384,0.105,2.27c0.953-0.377,1.122-1.083,1.335-1.684
				c0.878-2.475,3.04-3.149,5.222-3.404c2.443-0.286,4.447,0.995,6.073,2.748c0.665,0.717,0.343,1.592,0.127,2.471
				c-0.203,0.828,0.091,2.011,0.852,2.131c0.916,0.145,0.983-1.005,1.092-1.776c0.344-2.466,2.488-4.6,4.884-4.625
				c1.009-0.011,2.074,0.391,2.252,1.64c0.109,0.767,0.173,1.517,1.126,1.52c1.05,0.004,0.571-0.929,0.748-1.453
				c1.097-3.245,3.636-4.707,6.104-3.508c1.931,0.938,2.062,1.996,0.292,3.13c-1.367,0.875-2.448,2.041-3.578,3.162
				c-0.48,0.477-1.354,1.132-0.682,1.764c0.824,0.773,1.399-0.302,1.874-0.823c1.041-1.142,2.109-2.208,3.627-3.065
				c0.044,2.337-1.514,3.601-2.555,5.276c2.162-0.769,3.404-2.044,3.971-4.783c0.254-1.227,0.847-1.574,1.778-1.813
				c4.801-1.228,9.051-0.486,12.482,3.409c1.688,1.916,1.96,4.35,0.584,6.931c-1.245,2.337-4.044,3.946-6.267,3.453
				c-0.86-0.19-1.688-0.398-2.579-0.375c-0.516,0.013-1.022,0.046-1.178,0.57c-0.132,0.444,0.387,0.535,0.638,0.788
				c0.845,0.853-0.286,0.851-0.596,0.909c-5.638,1.056-11.154,0.901-16.383-1.808c-0.907-0.47-1.819-0.933-2.742-1.373
				c-0.592-0.282-1.246-0.524-1.666,0.231c-0.51,0.918,0.399,1.069,0.876,1.353c2.02,1.202,4.349,1.824,6.017,3.694
				c2.78,3.117,2.562,7.398-0.696,10.28c-0.493-0.425-0.262-0.846,0.039-1.227c0.457-0.579,1.089-1.279,0.159-1.776
				c-0.791-0.424-1.18,0.581-1.544,1.068c-0.405,0.544-0.907,1.461-0.707,1.938c0.936,2.226-0.853,3.008-2.064,3.764
				c-2.945,1.836-5.974,3.546-9.491,4.214c-0.923,0.175-1.473,0.168-2.087-0.472c-1.038-1.081-2.449-1.344-3.834-1.603
				c-0.423-0.079-1.025-0.183-1.203,0.442c-0.154,0.54-0.231,1.163,0.362,1.505c1.011,0.581,2.007,1.349,3.104,1.576
				c1.484,0.306,1.817,1.123,1.661,2.366c-0.357,2.84-1.626,5.302-4.221,6.503c-3.057,1.415-6.171,0.419-8.791-1.31
				c-1.102-0.728-1.318-2.647-2.633-3.531c-0.849-0.57-1.904-1.498-2.746-0.689c-0.917,0.88,0.189,1.857,0.693,2.715
				c0.039,0.067,0.119,0.11,0.162,0.176c0.983,1.51,3.399,1.969,3.097,4.44c-0.393,3.214-1.309,6.151-3.739,8.455
				c-3.351,3.177-7.457,4.608-11.916,5.295c-6.961,1.072-13.795,0.648-20.219-2.357c-6.585-3.08-12.349-7.269-16.269-13.618
				c-0.734-1.188-0.476-2.471-0.761-3.688c-0.126-0.537-0.346-1.382-1.241-1.104c-0.727,0.226-0.603,1.051-0.403,1.423
				c0.874,1.628,0.026,2.551-1.194,3.365c-1.379,0.92-2.921,0.737-4.404,0.448c-0.688-0.134-0.591-1.052-0.891-1.591
				c-0.386-0.692-0.242-2.14-1.454-1.69c-0.886,0.328-0.255,1.392,0.081,2.091c0.705,1.466,0.347,2.667-0.557,3.994
				c-1.243,1.827-2.877,1.805-4.713,1.561c-1.675-0.223-2.57-0.988-2.463-2.814c0.033-0.562-0.312-1.317-1.059-1.392
				c-0.624-0.063-1.084,0.398-1.096,1.022c-0.022,1.226,0.03,2.486-1.603,2.657c-1.636,0.172-3.179-0.104-4.353-1.421
				c-2.101-2.355-2.061-5.114,0.132-7.394c3.852-4.003,3.112-10.663-2.366-11.966c-2.033-0.484-4.037-0.299-5.387,1.561
				c-1.367,1.884-0.984,3.869,0.07,5.758c0.987,1.768,2.537,2.554,4.56,2.328c0.77-0.086,1.875-0.202,1.781-1.092
				c-0.11-1.043-1.022-0.42-1.7-0.32c-1.839,0.273-3.646-1.424-3.781-3.428c-0.134-1.96,1.067-3.162,3.184-3.186
				c3.554-0.04,5.116,1.925,4.257,5.401c-1.491,6.026-7.009,8.598-12.067,8.212c-5.191-0.396-8.874-4.599-9.339-9.995
				c-0.155-1.812,0.054-3.57,0.07-5.354c0.006-0.57,0.897-1.207,0.064-1.684c-0.98-0.561-1.483,0.424-1.896,0.991
				c-1.782,2.447-4.093,4.191-6.805,5.458c-5.564,2.6-12.688-0.305-14.924-6.087c-0.087-0.224-0.311-0.595-0.252-0.645
				c2.711-2.343-0.723-2.258-1.299-3.279c-0.81-1.436-1.375-3.012-0.402-4.436c0.829-1.214,2.2-1.503,3.539-0.46
				c0.397,0.31,0.734,0.161,1.043-0.153c0.469-0.476,0.2-0.869-0.164-1.221c-1.147-1.107-2.204-2.276-3.197-3.54
				c-1.242-1.579-2.064-3.201-0.798-4.91c1.152-1.555,4.745-1.476,6.371,0.073c2.21,2.106,4.697,4.069,5.145,7.446
				C408.615,90.745,409.162,91.76,410.1,92.576z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1344)} fill={props.fillColors[1344]} d="M554.146,90.464c1.417-0.63,2.662,0.276,3.991,0.317
				c3.775,0.115,7.384-0.628,10.985-1.587c2.556-0.681,6.862,1.348,8.109,3.647c0.85,1.568,0.459,2.765-0.87,3.714
				c-3.634,2.594-7.481,3.762-11.776,1.591c-0.764-0.386-1.651-1.106-2.418-0.517c-0.783,0.603-0.233,1.546-0.017,2.403
				c0.479,1.895-0.437,3.401-2.066,4.128c-1.413,0.63-3.028,0.9-4.666,0.5c-3.827-0.934-4.036-1.283-1.82-4.371
				C555.891,97.098,555.858,93.849,554.146,90.464z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1345)} fill={props.fillColors[1345]} d="M475.971,74.922c2.83,0.644,5.019,1.567,5.918,4.227
				c0.407,1.206,0.542,2.5-0.799,3.095c-1.17,0.519-1.471-0.716-1.992-1.463C477.892,79.049,476.71,77.315,475.971,74.922z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1346)} fill={props.fillColors[1346]} d="M185.776,214.31c-1.347,1.269-2.557,2.247-4.257,2.616
				c-2.124,0.462-3.914-0.84-4.25-3.229c-0.076-0.541,0.062-1.317-0.658-1.339c-0.707-0.021-0.712,0.723-0.699,1.283
				c0.036,1.524-1.033,2.114-2.22,2.429c-1.123,0.297-2.093,0.243-2.625-1.258c-0.322-0.91-0.927-0.447-1.109,0.404
				c-0.318,1.487-1.156,2.398-2.799,2.346c-0.748-0.024-1.542-0.242-1.778-0.884c-0.245-0.668,0.519-1.066,1.041-1.421
				c1.002-0.683,2.815-1.715,2.121-2.525c-0.821-0.958-1.627,1.071-2.59,1.562c-0.141,0.072-0.271,0.166-0.484,0.298
				c-0.419-1.374,1.179-1.941,1.209-3.348c-1.251,0.576-2.219,1.469-2.248,2.56c-0.077,2.825-2.542,2.179-3.7,2.216
				c-2.342,0.075-4.756-0.67-6.149-2.961c-0.938-1.543-0.642-3.078,0.385-4.457c1.162-1.56,2.672-2.151,4.614-1.505
				c0.759,0.253,1.966,0.735,1.306-0.985c-0.255-0.664,0.171-0.578,0.547-0.662c3.512-0.785,6.843-0.355,10.085,1.249
				c0.82,0.406,2.1,1.767,2.636,0.712c0.688-1.354-1.247-1.45-2.076-1.931c-2.771-1.609-3.844-3.871-2.763-6.264
				c0.233-0.516,0.231-0.466,1.019-0.089c0.988,0.473,1.229-0.287,1.417-1.001c1.018-3.887,6.061-6.5,9.761-5.059
				c0.436,0.17,0.927,0.22,1.334,0.436c0.482,0.256,0.945,0.44,1.157-0.193c0.147-0.439-0.037-0.925-0.489-1.21
				c-0.199-0.126-0.396-0.281-0.617-0.344c-1.756-0.499-2.694-1.333-1.852-3.363c0.752-1.813,1.589-3.196,3.889-3.087
				c2.217,0.105,4.311,0.109,5.205,2.755c0.287,0.848,1.3,1.503,2.141,0.95c0.9-0.592,0.125-1.769-0.328-2.025
				c-1.632-0.925-1.871-2.282-1.556-3.846c0.402-1.996,1.267-3.775,2.938-5.056c2.192-1.68,4.763-2.518,7.421-2.843
				c9.208-1.126,16.554,2.465,22.502,9.289c1.079,1.238,1.009,3.002,1.638,4.564c0.152-1.825,0.827-3.027,2.594-3.737
				c2.711-1.091,1.639,2.469,3.315,2.508c0.388-0.767-0.221-1.372-0.292-2.048c-0.146-1.388,0.404-2.67,1.722-3.092
				c1.35-0.432,2.706-0.045,3.364,1.471c0.177,0.408,0.012,1.045,0.681,1.087c0.491,0.03,0.53-0.404,0.691-0.788
				c0.718-1.708,3.014-1.982,4.037-0.388c0.956,1.489,1.557,3.16-0.125,4.67c-1.437,1.29-1.849,3.003-1.513,4.764
				c0.419,2.193,1.976,3.034,4.204,3.083c1.598,0.035,2.459-0.602,2.693-2.042c0.316-1.949-0.473-3.407-2.32-4.211
				c-0.876-0.381-1.683-0.389-2.23,0.53c0.167,0.752,0.748,0.579,1.169,0.579c1.725,0.001,2.464,1.146,2.369,2.545
				c-0.085,1.259-1.38,1.722-2.503,1.562c-1.538-0.218-2.828-1.166-2.443-2.864c0.857-3.778,3.22-6.194,7.813-5.984
				c3.244,0.148,5.936,3.108,6.086,6.775c0.057,1.399,0.009,2.803,0.009,4.79c2.369-2.508,4.333-4.432,7.091-5.228
				c3.416-0.985,7.832,1.94,7.886,5.381c0.011,0.691,0.363,0.734,0.66,1.026c0.906,0.891,1.478,1.937,0.899,3.192
				c-0.566,1.229-1.69,0.799-2.887,0.299c0.321,1.746,1.65,2.341,2.132,3.484c0.466,1.106,1.795,2.205,0.222,3.29
				c-1.27,0.875-2.683,0.805-3.942-0.289c-1.398-1.215-2.468-2.594-2.972-4.441c-0.22-0.807-0.178-1.943-1.368-2.268
				c0.921,5.261-0.159,8.474-3.239,9.686c-3.673,1.445-5.878,0.563-8.55-3.421c-0.668,1.813-0.062,3.377,1.783,4.279
				c0.555,0.271,1.578,0.312,1.006,1.123c-1.686,2.394-3.381,4.94-6.308,5.933c-2.583,0.876-5.769-0.651-7.631-3.334
				c-1.162-1.674-1.667-3.627-1.897-5.594c-0.128-1.087,0.347-2.146,1.605-2.647c0.718-0.287,1.426-0.749,1.964-1.302
				c0.667-0.687,0.741-1.688,0.069-2.353c-0.635-0.628-1.552-0.863-2.291,0.069c-0.148,0.187-0.33,0.348-0.496,0.521
				c-2.726,2.823-3.862,2.835-6.638-0.092c-0.967-1.02-1.769-2.196-2.757-3.442c-0.616,1.053-0.638,2.185-0.188,3.104
				c0.569,1.164,0,2.14-0.425,2.898c-1.176,2.103-2.511,0.786-3.907-0.581c0.133,1.596,0.277,2.733,0.312,3.873
				c0.052,1.727-1.25,2.382-2.575,2.699c-1.395,0.334-1.319-1.042-1.646-1.866c-0.459-1.157-1.352-1.96-2.202-2.806
				c-0.546-0.543-1.078-0.884-1.893-0.444c-0.734,0.396-1.296,0.75-1.342,1.744c-0.09,1.939,1.177,3.894,3.364,4.306
				c1.375,0.259,1.645,0.901,1.718,2.077c0.306,4.896-1.085,7.454-5.286,9.357c-2.063,0.935-3.994,0.719-5.696-0.914
				c-0.862-0.828-1.521-1.626-0.604-2.817c0.426-0.554,0.351-1.237,0.271-1.852c-0.1-0.773-0.675-1.314-1.4-1.45
				c-0.984-0.185-0.732,0.822-1.039,1.295c-0.427,0.658-0.252,1.391-0.257,2.102c-0.006,0.863-0.173,1.619-1.221,1.734
				c-0.928,0.102-1.504-0.433-1.901-1.176c-0.142-0.265-0.101-0.621-0.195-0.919c-0.353-1.116-0.409-2.612-1.173-3.24
				c-1.008-0.828-1.916,1.06-3.096,0.962c-1.185-0.098-3.205,1.057-2.803-1.707c0.067-0.464-0.31-0.835-0.659-0.867
				c-0.307-0.028-0.893,0.279-0.938,0.516c-0.495,2.659-2.553,2.381-4.295,2.096c-2.166-0.355-3.352-1.954-3.689-4.107
				C185.695,215.577,185.776,214.938,185.776,214.31z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1347)} fill={props.fillColors[1347]} d="M169.923,196.134c-2.516,3.247-2.751,4.406-1.592,7.907
				c-3.493,0.103-6.951-0.062-10.351,0.908c-1.856,0.529-3.216-1.058-4.501-2.188c-0.757-0.665-0.271-1.417,0.307-2.072
				c1.777-2.017,4.751-2.611,7.803-1.413c1.425,0.56,2.299,1.01,1.792-1.323c-0.472-2.167,1.681-3.63,4.125-2.988
				C168.325,195.179,169.225,195.356,169.923,196.134z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1348)} fill={props.fillColors[1348]} d="M219.662,214.063c-2.529-0.263-3.954-0.959-4.085-3.157
				c-0.022-0.371-0.11-0.838,0.341-1.043c0.469-0.213,0.7,0.232,1.007,0.456C218.139,211.203,218.248,212.754,219.662,214.063z"/>
			<path fill-rule="evenodd" d="M368.646,399.795c-0.357-1.6-1.76-2.827-0.93-4.856c1.127,2.209,2.149,4.22,3.18,6.227
				c0.145,0.282,0.368,0.578,0.02,0.818c-0.159,0.11-0.561,0.154-0.64,0.056c-1.916-2.376-4.745-0.965-7.079-1.664
				c-1.814-0.544-3.622-1.205-5.545-1.488C361.367,398.078,364.822,399.727,368.646,399.795z"/>
			<path fill-rule="evenodd" d="M421.878,401.54c0.384-4.887-0.499-9.664-1.438-14.438C422.461,391.78,423.878,396.509,421.878,401.54
				z"/>
			<path fill-rule="evenodd" d="M425.324,437.635c-0.639-4.806,0.525-9.282,2.562-13.588
				C426.993,428.563,425.858,433.031,425.324,437.635z"/>
			<path fill-rule="evenodd" d="M404.943,372.766c0.033,1.002,0.939,1.605,1.234,2.645c-2.262,0.062-3.014,3.079-5.518,2.631
				c0.553-2.47,3.467-3.025,4.281-5.283C404.941,372.758,404.943,372.766,404.943,372.766z"/>
			<path fill-rule="evenodd" d="M349.307,427.886c-0.125,1.213-0.651,3.048-0.503,4.953c0.037,0.472-0.128,1.063-0.771,0.923
				c-0.714-0.157-0.681-0.913-0.541-1.371c0.617-2.024,0.35-4.09,0.445-6.14c0.023-0.491-0.297-1.308,0.493-1.368
				c0.878-0.068,0.854,0.747,0.874,1.389C349.314,426.589,349.307,426.906,349.307,427.886z"/>
			<path fill-rule="evenodd" d="M371.426,353.413c1.465,2.466,1.27,5.232,1.306,7.962
				C371.303,358.913,371.227,356.197,371.426,353.413z"/>
			<path fill-rule="evenodd" d="M347.478,443.111c-0.325,1.442-0.628,2.893-0.998,4.326c-0.061,0.233-0.427,0.555-0.642,0.547
				c-0.442-0.017-0.709-0.405-0.626-0.846c0.292-1.532,0.632-3.056,0.948-4.584c0.077-0.371,0.363-0.59,0.669-0.546
				C347.435,442.095,347.485,442.609,347.478,443.111z"/>
			<path fill-rule="evenodd" d="M416.188,405.129c0.691-1.643-0.415-3.728,1.144-5.159
				C416.839,401.679,417.914,403.694,416.188,405.129z"/>
			<path fill-rule="evenodd" d="M419.812,438.259c-0.023-1.725-0.587-3.501,0.632-5.61c0,2.224,0,3.936,0,5.647
				C420.233,438.283,420.023,438.271,419.812,438.259z"/>
			<path fill-rule="evenodd" d="M530.57,397.948c0.072-0.802-0.286-1.778,0.624-2.171c0.682-0.294,1.319,0.275,1.863,0.697
				c1.582,1.226,3.207,1.569,4.983,0.406c0.914-0.599,1.612-0.424,2.059,0.633c0.527,1.25-0.654,1.476-1.207,1.558
				c-2.401,0.359-4.831,0.56-7.257,0.721C530.145,399.891,530.727,398.654,530.57,397.948z"/>
			<path fill-rule="evenodd" d="M557.227,353.493c-1.068,1.266-1.902,2.253-2.979,3.527c-0.195-1.411-0.57-2.411-1.973-2.618
				c-0.546-0.08-0.769-0.792-0.512-1.387c0.262-0.607,0.94-0.913,1.36-0.516C554.207,353.523,555.622,353.178,557.227,353.493z"/>
			<path fill-rule="evenodd" d="M552.198,342.585c-0.091,1.479-0.163,2.645-0.259,4.21c-1.143-1.26-3.45-1.243-3.021-3.203
				C549.25,342.078,551.083,343.379,552.198,342.585z"/>
			<path fill-rule="evenodd" d="M524.055,441.046c0.062,1.354-0.97,1.259-1.465,1.034c-1.02-0.462-2.632,1.702-3.072-0.615
				c-0.297-1.562,1.284-1.429,2.099-1.548C522.531,439.783,523.653,439.995,524.055,441.046z"/>
			<path fill-rule="evenodd" d="M565.137,360.638c0.72,0.037,1.302,0.354,1.272,1.115c-0.036,0.919-0.924,0.995-1.559,1.069
				c-0.731,0.086-1.209-0.405-1.098-1.186C563.859,360.884,564.402,360.597,565.137,360.638z"/>
			<path fill-rule="evenodd" d="M569.28,379.363c0.067,0.753-0.327,1.131-0.907,1.228c-0.877,0.146-1.289-0.339-1.344-1.196
				c-0.04-0.608,0.038-1.072,0.797-1.139C568.726,378.175,569.017,378.776,569.28,379.363z"/>
			<path fill-rule="evenodd" d="M466.931,442.698c-0.947-0.228-1.954,0.304-2.044-0.786c-0.074-0.906-0.64-1.965,0.116-2.747
				c0.663-0.685,1.558-0.244,2.155,0.217c1.657,1.279,3.369,1.221,5.069,0.292c1.042-0.569,1.396-0.075,1.802,0.725
				c0.67,1.319-0.498,1.23-1.125,1.443C470.865,442.532,468.622,441.63,466.931,442.698z"/>
			<path fill-rule="evenodd" d="M453.642,477.545c0.718-1.765,2.875-2.389,4.269-1.295c0.471,0.369,0.474,0.752,0.394,1.225
				c-0.084,0.499-0.461,0.595-0.871,0.579c-1.307-0.048-2.681,0.341-3.861-0.579C453.568,477.473,453.642,477.545,453.642,477.545z"/>
			<path fill-rule="evenodd" d="M485.984,394.73c-0.815,1.161-0.498,2.296-0.716,3.623c-0.938-1.228-3.441-0.88-3.005-2.803
				C482.646,393.874,484.496,394.858,485.984,394.73z"/>
			<path fill-rule="evenodd" d="M489.153,404.798c0.014,0.745-0.354,1.167-0.997,1.487c-1.009,0.502-0.736-0.301-0.86-0.756
				c-0.203-0.741-0.906-0.461-1.381-0.657c-0.639-0.266-1.021-0.86-0.846-1.49c0.205-0.733,0.992-0.981,1.54-0.552
				C487.451,403.491,489.156,403.088,489.153,404.798z"/>
			<path fill-rule="evenodd" d="M456.893,494.7c0.831-0.966,1.861-1.327,3.138-1.183c0.64,0.073,1.079,0.188,1.012,0.933
				c-0.047,0.515,0.128,1.12-0.638,1.326c-1.417,0.381-2.928,1.119-3.46-1.143C456.939,494.64,456.893,494.7,456.893,494.7z"/>
			<path fill-rule="evenodd" d="M499.294,410.508c0.083,1.246-1.096,1.058-1.677,1.212c-0.734,0.195-1.004-0.778-0.96-1.296
				c0.09-1.047,1.215-0.93,1.888-1.096C499.162,409.176,499.297,409.933,499.294,410.508z"/>
			<path fill-rule="evenodd" d="M75.7,545.35c0.417-1.179,1.084-2.023,2.387-2.425c1.941-0.6,3.102-2.347,4.645-3.535
				c0.951-0.733,1.879-1.021,2.803-0.337c0.842,0.623,1.764,1.729,2.626,0.83c1.008-1.052,2.284-2.352,1.449-4.189
				c0.27,0.141,0.711,0.223,0.782,0.432c0.656,1.938-0.979,3.115-1.8,4.421c-0.674,1.075-2.038,0.212-2.988-0.328
				c-1.579-0.898-3.549-0.286-4.217,1.308c-0.369,0.879,0.38,0.876,0.774,1.16c2.429,1.748,5.364,1.635,8.119,2.182
				c0.463,0.092,1.198-0.108,1.384,0.141c1.953,2.626,4.445,1.808,6.566,0.716c3.279-1.688,6.787-1.84,10.273-2.273
				c2.924-0.364,4.869-2.374,7.537-4.142c-3.825-0.503-6.914-1.926-10.089-3.127c3.302,0.384,6.239,2.135,9.62,2.163
				c0.519,0.005,1.075,0.592,1.541,0.992c4.049,3.49,8.847,3.67,13.795,3.114c1.205-0.136,0.884-0.91,0.409-1.542
				c-0.622-0.824-1.319-1.569-2.273-2.07c-0.642-0.336-1.185-0.917-1.019-1.741c0.169-0.837,0.949-0.984,1.632-1.179
				c0.302-0.086,0.665-0.019,0.933-0.153c0.885-0.446,2.583-0.318,1.894-2.047c-0.494-1.239-0.847-2.977-2.912-2.386
				c-0.857,0.245-1.684,0.39-2.664,0.002c0.291-0.578,0.816-0.411,1.184-0.409c1.714,0.012,3.543,0.181,1.792-2.401
				c-0.154-0.229-0.149-0.648-0.07-0.939c1.297-4.761-0.57-8.383-4.137-11.339c-0.835-0.692-0.162-1.624-0.521-2.422
				c1.119,2.991,4.778,4.195,5.131,7.729c0.053,0.535,0.688,0.845,1.323,0.818c1.046-0.042,2.035,0.25,3.05,0.451
				c2.791,0.553,2.8,0.506,2.245-2.257c0.866,0.655,1.681,1.485,1.11,2.583c-0.532,1.02-1.766,0.586-2.592,0.446
				c-1.472-0.249-2.905-0.581-4.59-0.237c1.476,2.335,3.671,3.639,5.88,4.919c0.742,0.431,1.605,0.711,1.829,1.688
				c0.014,0.062-0.036,0.168-0.089,0.209c-0.051,0.04-0.146,0.023-0.222,0.032c-1.254-0.147-2.445-0.527-3.636-0.935
				c-1.349-0.462-1.848,0.047-1.418,1.409c0.193,0.61,0.489,1.185,0.788,1.748c0.925,1.743,0.728,3.218-0.961,4.38
				c-0.464,0.318-1.182,0.807-0.855,1.279c1.146,1.66,0.727,3.798,1.707,5.399c0.94,1.534,2.911,1.75,4.336,1.339
				c4.11-1.184,8.392-0.933,12.84-1.854c-1.797-0.979-3.394-1.546-5.152-1.762c-2.542-0.313-5.085-0.732-7.434-1.797
				c-1.689-0.767-3.116-1.82-2.105-4.076c0.335-0.748,0.087-1.414-0.522-1.933c-0.96-0.816-1.9-1.701-0.938-3.022
				c0.915-1.257,2.001-0.421,3.014-0.02c0.14,0.056,0.268-0.015,0.371-0.18c-0.336,0.423-0.671,0.845-1.087,1.369
				c0.313,0.12,0.672,0.151,0.844,0.347c0.498,0.564,1.87,0.664,1.767,0.724c-1.425,0.818,0.595,2.542-1.016,3.319
				c-1.964,0.949-0.515,1.437,0.422,1.816c2.877,1.169,5.997,1.219,8.987,1.875c1.167,0.255,2.233,0.532,3.188,1.335
				c1.661,1.396,3.726,0.983,5.677,1.053c0.968,0.035,2.189-0.368,2.461,1.17c-1.319-0.59-2.593-0.496-3.85,0.207
				c-2.883,1.612-6.112,1.896-9.287,2.204c-3.069,0.299-3.083,0.155-2.229,2.87c-1.287-2.353-3.017-2.546-5.479-1.661
				c-2.574,0.926-5.953-0.681-7.46-2.846c-0.908-1.304-2.271-0.652-3.431-0.466c-0.492,0.079-0.237,0.623,0.035,0.927
				c0.457,0.511,1.368,0.544,1.487,1.413c-1.186-0.387-2.464-0.551-2.816-2.15c-0.218-0.989-1.206-0.859-2.001-0.824
				c-2.457,0.106-4.695-0.55-6.617-2.069c-1.409-1.115-2.646-0.321-3.538,0.486c-2.651,2.401-5.746,3.072-9.168,2.998
				c-2.284-0.05-4.233,0.99-6.244,2.104c0.941,0.912,2.333-0.35,3.026,0.879c-2.857,0.697-6.25,0.627-8.379-0.479
				c-3.822-1.986-8.281-1.011-11.798-3.488c-1.735-1.222-3.142,0.135-4.525,0.974C76.704,544.588,76.391,545.238,75.7,545.35z"/>
			<path fill-rule="evenodd" d="M143.868,493.933c2.578,0.854,4.666,2.775,7.448,3.247c2.314,0.393,2.573,0.254,2.089-1.994
				c-0.338-1.575,0.567-1.801,1.796-1.511c1.609,0.38,3.211,0.701,4.869,0.887c3.031,0.339,5.286-1.159,7.571-2.71
				c0.254-0.173,0.653-0.562,0.6-0.697c-1.297-3.256,2.372-3.565,3.257-5.514c0.268-0.59,1.447-0.88,0.899-1.683
				c-0.414-0.605-1.333-0.364-2.007-0.104c-1.099,0.425-2.184,0.339-3.282,0.052c1.665-0.998,3.547-0.884,5.373-0.985
				c0.295-0.017,0.912,0.476,0.88,0.635c-0.286,1.449,0.141,2.192,1.716,2.251c0.329,0.012,0.3,0.67,0.105,1.048
				c-0.364,0.704-0.59,1.576-1.147,2.066c-1.884,1.656-3.112,3.3-0.846,5.542c-1.307-0.317-2.399-0.785-2.316-2.468
				c0.052-1.052-0.579-0.912-1.238-0.524c-0.811,0.477-1.693,0.86-2.428,1.434c-3.262,2.542-6.86,3.013-10.733,1.836
				c-1.592-0.483-3.058-1.066-1.813,1.645c0.682,1.485-0.854,1.758-1.553,1.629C149.728,497.395,146.203,496.963,143.868,493.933z"/>
			<path fill-rule="evenodd" d="M91.813,522.313c0.163-1.735,1.021-3.092,2.53-4.614c-0.042,1.747,0.539,2.546,2.028,2.978
				c1.552,0.45,3.227,0.155,4.684,1.936c1.003,1.226,3.895-0.142,5.917-0.456c1.12-0.174,2.214-0.18,3.324-0.22
				c1.015-0.037,1.853,0.514,1.988,1.391c0.653,4.25,4.111,4.195,7.13,4.742c2.354,0.426,4.949,0.163,6.36,2.799
				c-1.882-1.352-3.9-2.023-6.255-2.131c-1.083-0.049-1.833,0.294-2.589,0.688c-1.69,0.882-3.344,1.421-5.187,0.573
				c-1.7-0.781-2.644,1.148-4.715,1.287c2.099-1.261,3.426-2.724,5.667-1.53c1.441,0.768,2.669-0.489,4.067-0.933
				c-0.862-1.187-2.289-1.165-3.286-1.888c-0.831-0.604-1.884-1.1-1.759-2.33c0.174-1.703-0.703-1.895-2.121-1.954
				c-1.932-0.08-3.775,0.395-5.657,0.585c-1.778,0.181-3.239,1.116-4.87-1.144c-0.814-1.129-3.662-1.215-5.555-1.966
				c-0.817-0.324-0.501,1.911-1.757,2.137C91.756,522.263,91.813,522.313,91.813,522.313z"/>
			<path fill-rule="evenodd" d="M159.503,518.167c-2.386,2.499-5.522,3.462-8.76,4.019c-1.298,0.223-1.227-0.626-0.42-1.404
				c0.277-0.269,0.505-0.589,0.816-0.957c-2.757-1.988-5.92,0.092-8.61-1.404c2.017,3.199,2.017,3.199,0.654,3.889l0.061,0.056
				c0.38-0.913,0.472-1.539-0.652-2.213c-1.457-0.874-2.6-2.253-3.98-3.276c-1.598-1.185-3.445-1.729-5.489-1.581
				c0.239,1.377,2.103,1.828,1.897,3.417c-1.278-1.002-2.268-2.21-2.769-3.942c0.853-0.115,1.662-0.225,2.423-0.327
				c0.222-1.06-1.384-2.228-0.046-2.877c1.809-0.877,3.578,0.162,5.399,0.873c-1.209,0.376-2.149-0.241-3.157-0.335
				c-0.598-0.056-1.451-0.332-1.739,0.32c-0.301,0.681,0.225,1.401,0.791,1.827c2.622,1.972,5.415,3.729,8.761,3.95
				c1.661,0.109,3.297,0.389,4.956,0.473c0.756,0.038,1.69-0.297,2.193,0.551c0.552,0.93-0.863,1.167-0.876,2.243
				C154.255,521.313,156.703,519.245,159.503,518.167z"/>
			<path fill-rule="evenodd" d="M139.779,493.036c1.046,0.091,0.471-1.247,1.244-1.448c0.657,0.652,0.247,1.674,0.983,2.347
				c0.628,0.575,0.384,1.512-0.457,1.695c-1.947,0.427-1.202,1.481-0.652,2.42c1.571,2.685,3.625,4.908,6.708,6.757
				c-0.34-1.99-2.534-2.53-2.188-4.567c1.267,1.883,3.148,3.146,3.525,5.695c-4.554-1.147-6.548-4.973-9.704-8.311
				c0.125,2.6,1.531,3.78,2.318,5.265c0.366,0.69,1.399,1.442,0.622,2.286c-0.54,0.587-1.444,0.308-2.235,0.008
				c-0.774-0.294-1.631-0.37-2.497-0.55c-0.25,1.221,0.793,1.922,1.004,3.335c-1.304-0.938-2.047-2.184-1.745-3.019
				c0.455-1.257,1.859-0.776,2.934-0.44c0.544,0.171,1.199,0.375,1.569-0.089c0.497-0.623-0.214-1.154-0.446-1.701
				c-0.118-0.278-0.431-0.467-0.62-0.722c-1.413-1.911-2.816-4.062-0.35-5.926c1.758-1.329,0.423-2.069,0.033-3.085L139.779,493.036z"
				/>
			<path fill-rule="evenodd" d="M156.551,517.986c3.247-1.674,6.615-3.104,9.637-5.223c0.988-0.693,1.538-2.11,3.039-2.038
				c-2.959,2.709-5.665,5.752-9.545,7.247c0.401-0.398,1.056-1.035-0.21-0.752C158.501,517.438,157.643,518.149,156.551,517.986z"/>
			<path fill-rule="evenodd" d="M105.671,502.664c1.514-0.21,2.423-1.047,3.266-2.369c1.03-1.616,2.03-3.385,4.683-3.156
				c2.417,0.209,2.913,1.251,2.604,3.306c-0.09,0.599-0.014,1.223-0.014,2.004c-1.18-0.66-0.741-1.648-0.788-2.42
				c-0.126-2.078-1.492-2.973-3.46-2.163c-1.595,0.656-2.353,2.026-3.026,3.499c-0.652,1.424-1.564,2.354-3.228,1.248
				C105.707,502.613,105.671,502.664,105.671,502.664z"/>
			<path fill-rule="evenodd" d="M126.151,505.995c-0.876,0.978-2.036,1.307-3.264,1.321c-0.747,0.009-1.38,0.29-2.063,0.478
				c-1.167,0.319-1.167,1.381-0.912,2.016c0.355,0.88,1.029-0.01,1.548-0.176c0.371-0.118,0.707-0.349,1.052-0.54
				c0.61-0.336,1.324-0.15,1.371,0.406c0.111,1.318-0.725,0.065-1.087,0.283c-0.333,0.2-0.719,0.314-1.084,0.463
				c-0.843,0.342-1.934,1.026-2.371-0.215c-0.435-1.234-0.188-2.524,1.479-2.98C122.554,506.576,124.421,506.728,126.151,505.995z"/>
			<path fill-rule="evenodd" d="M107.606,484.144c1.172-1.578,3.344-2.08,3.931-4.466c0.412-1.673,0.105-3.061-0.141-4.552
				c-0.186-1.124-0.506-2.192,0.579-3.04c-0.372,2.132,0.599,4.169,0.427,6.294C112.138,481.631,110.962,483.074,107.606,484.144z"/>
			<path fill-rule="evenodd" d="M139.826,492.986c-3.635-2.555-8.414-2.468-11.884-5.439c3.406,2.447,7.974,1.784,11.266,4.508
				c0.274,0.227,0.385,0.649,0.571,0.981L139.826,492.986z"/>
			<path fill-rule="evenodd" d="M156.876,501.149c-0.466,0.297-1,0.526-1.384,0.906c-0.804,0.796-2.155,1.306-1.881,2.772
				c0.252,1.352,1.54,1.044,2.447,1.377c0.671,0.247,1.646,0.335,1.522,1.469c-0.473-0.267-0.917-0.663-1.424-0.779
				c-3.573-0.819-3.551-0.793-2.606-4.215c0.441-1.597-1.285-0.488-1.641-1.371C153.553,500.717,155.222,501.036,156.876,501.149z"/>
			<path fill-rule="evenodd" d="M153.988,512.658c-0.141,0.428-0.486,0.893,0.452,0.791c2.355-0.255,2.531,0.02,1.931,2.316
				c-0.187,0.716-1.023,1.619,0.276,2.188c-1.176,0.581-1.587,0.201-1.375-1.058c0.474-2.822,0.465-2.818-2.206-2.53
				c-0.195,0.021-0.409-0.128-0.729-0.237C152.65,513.289,153.104,512.748,153.988,512.658z"/>
			<path fill-rule="evenodd" d="M119.366,468.5c1.147-0.132,1.541,0.617,1.692,1.536c0.418,2.532,1.72,2.337,3.415,1.185
				c0.3-0.204,0.723-0.227,1.089-0.332c-0.568,1.444-2.044,1.422-3.177,1.792c-0.981,0.321-1.754-0.528-1.712-1.474
				C120.727,469.98,119.909,469.344,119.366,468.5z"/>
			<path fill-rule="evenodd" d="M165.749,501.386c-0.11,0.518-0.354,0.902-0.956,0.727c-0.5-0.146-0.467-0.546-0.334-0.95
				c0.288-0.87,0.575-1.739,0.856-2.59c-1.175-0.493-1.98,0.727-2.995,0.298c0.239-0.648,0.928-0.468,1.363-0.744
				c0.729-0.462,1.613-0.553,2.2-0.104c0.66,0.504-0.057,1.259-0.143,1.913C165.679,500.393,165.105,500.92,165.749,501.386z"/>
			<path fill-rule="evenodd" d="M120.104,487.527c-2.275-1.256-2.129-5.229-6.415-4.692
				C118.599,481.457,118.326,485.754,120.104,487.527z"/>
			<path fill-rule="evenodd" d="M336.878,708.056c-2.023,0.052-3.56-1.273-5.289-1.983c-8.757-3.594-17.859-5.451-27.263-4.711
				c-7.759,0.61-15.335,2.503-22.576,5.513c-0.571,0.237-1.208,0.317-1.814,0.471c0.637-1.573,2.303-1.645,3.512-2.034
				c8.729-2.809,17.573-5.472,26.862-4.822C319.641,701.14,328.734,703.037,336.878,708.056z"/>
			<path fill-rule="evenodd" d="M286.176,694.062c-9.763,1.599-17.415,6.661-24.682,12.438c-1.12,0.891-2.493,1.747-1.949,3.65
				c0.129,0.453-0.545,0.337-0.866,0.344c-1.342,0.025-2.686,0.011-4.103,0.011c0.347-1.033,1.047-0.741,1.556-0.697
				c1.273,0.112,1.944-0.426,2.65-1.511c1.703-2.613,4.488-4.103,6.901-5.947c5.499-4.204,11.623-7.158,18.408-8.68
				C284.638,693.548,285.143,693.311,286.176,694.062z"/>
			<path fill-rule="evenodd" d="M309.989,691.631c-4.917,0.513-9.858,0.021-14.764,0.665
				C297.301,691.271,306.225,690.847,309.989,691.631z"/>
			<path fill-rule="evenodd" d="M319.69,692.991c-2.962,0.262-5.779-0.836-8.698-0.979C313.991,691.41,316.834,692.267,319.69,692.991
				z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1349)} fill={props.fillColors[1349]} d="M316.652,601.98c-0.064,0.061-0.13,0.121-0.195,0.181
				c-0.401,0.81-0.654,1.818-1.951,1.399c-0.079-0.025,0.036,0.093,0.061,0.121c0.362,0.432,0.338,0.828-0.086,1.2l0.069-0.076
				c-3.002-0.4-5.683-1.906-8.668-2.434c-4.5-0.796-8.998-1.144-13.546-0.715c-0.836,0.079-1.561-0.004-2.225-0.567
				c-0.972-0.823-2.083-1.471-3.229-1.982c-1.363-0.608-1.373-1.369-0.596-2.415c0.625-0.841,1.428-1.487,2.238-2.144
				c2.554-2.072,5.733-3.214,8.727-3.48c3.329-0.298,6.945,0.073,10.185,1.591c1.306,0.611,2.666,0.945,4.104,1.19
				c3.05,0.519,5.347,2.334,7.143,4.813c0.598,0.826,0.491,1.555-0.405,1.965C317.582,600.945,317.27,601.627,316.652,601.98z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1350)} fill={props.fillColors[1350]} d="M315.023,592.144c2.603,1.586,5.178,3.204,6.723,5.979
				c-0.238,0.314-0.476,0.629-0.713,0.943c-0.942,0.408-1.724,0.368-2.631-0.331c-5.657-4.368-12.21-6.717-19.238-6.855
				c-4.69-0.094-9.211,1.564-12.66,5.197c-0.59,0.622-1.011,1.823-2.277,1.024l0.087,0.052c-0.75-0.962,0.169-1.508,0.625-2.019
				c6.479-7.243,19.609-9.768,28.267-5.473C313.922,591.016,314.639,591.379,315.023,592.144z"/>
			<path fill-rule="evenodd" d="M284.228,598.102c1.003-0.111,1.641-0.734,2.374-1.396c4.285-3.872,9.243-5.508,15.102-4.852
				c6.354,0.712,11.963,3.08,17.103,6.707c0.77,0.543,1.5,0.297,2.227,0.506c-1.439,1.004-2.714,2.254-4.381,2.914
				c0.438-0.355-0.493-0.974,0.373-1.367c1.822-0.827,1.854-1.506,0.048-2.682c-4.093-2.664-8.587-4.36-13.363-5.372
				c-5.415-1.148-10.362-0.006-15.006,2.837c-0.136,0.083-0.292,0.135-0.418,0.229c-1.994,1.478-2.034,2.082-0.2,3.556
				c1.757,1.412,3.495,2.284,6.037,2.107c7.041-0.491,13.863,0.916,20.428,3.518c-0.001,0.186-0.002,0.37-0.003,0.556
				c-3.078-0.843-5.946-2.354-9.131-2.861c-5.187-0.827-10.366-0.863-15.594-0.253c-2.977,0.347-5.908,0.729-8.823,1.479
				c-4.418,1.137-8.43,3.028-12.104,5.694c-0.036-0.95,1.023-1.134,1.448-2.124c-1.911-0.385-3.708-0.267-5.58-0.158
				c1.057-0.257,2.199-0.91,3.155-0.703c4.663,1.008,8.152-2.115,12.334-3.121c-1.013-1.234-2.556-0.853-3.753-1.697
				c3.118-0.088,6.09,2.379,9.092,0.168c-0.351-0.715-1.634-0.285-1.635-1.383l-0.045,0.034c1.852,0.198,3.406,2.357,5.569,0.755
				C288.02,599.684,285.733,599.533,284.228,598.102z"/>
			<path fill-rule="evenodd" d="M357.733,599.133c1.49,0.06,2.888-0.108,4.354-1.138c-0.905,1.007-1.315,1.598,0.164,2.224
				c2.415,1.021,4.75,2.035,7.471,1.463c0.759-0.16,1.642-0.229,2.344,0.222c2.097,1.347,3.69-0.285,5.434-0.89
				c1.121-0.389,0.125-1.163,0.023-1.979c1.249,0.504,2.237,1.752,3.636,0.712c-5.316,2.451-10.427,5.113-15.499,9.085
				c1.164-1.894,0.359-2.539-0.845-3.163c-3.578-1.853-7.367-2.924-11.338-3.423c4.459-0.194,8.512,1.186,12.365,3.304
				c0.479,0.264,0.845,0.713,1.453,0.762c0.78,0.063,1.571-0.179,1.884-0.78c0.34-0.655-0.399-1.173-0.904-1.5
				C365.021,601.925,361.669,600.015,357.733,599.133z"/>
			<path fill-rule="evenodd" d="M444.564,593.848c0.326-0.746,0.653-1.491,0.979-2.236c1.288,0.541,1.404-1.433,2.554-1.189
				c0.069-1.049-0.729-1.469-1.422-1.966c-0.758-0.543-1.607-1.017-1.61-2.131c1.012,1.281,3.065,1.334,3.646,3.172
				c0.191,0.604,0.798,0.313,1.205,0.079c3.944-2.28,8.245-3.426,13.114-3.88c-1.514-1.29-2.918-1.738-4.436-1.754
				c3.425-0.454,6.2,2.152,9.565,2.201c-8.279-0.316-15.941,1.35-22.395,6.937C445.41,593.388,444.968,593.594,444.564,593.848z"/>
			<path fill-rule="evenodd" d="M351.385,597.059c4.131-2.753,8.325-5.345,13.326-6.283c2.495-0.469,4.981-0.52,7.473-0.199
				c3.64,0.468,7.399,0.418,10.719,2.433c-3.421-1.067-6.942-1.424-10.469-1.903c-5.826-0.793-10.941,0.58-15.932,3.364
				c-1.662,0.927-2.984,2.635-5.162,2.539C351.336,597.004,351.385,597.059,351.385,597.059z"/>
			<path fill-rule="evenodd" d="M387.444,591.842c0.931,0.142,1.519-0.104,2.128-0.991c1.685-2.452,4.179-3.878,7.003-4.767
				c-1.965,1.33-4.213,2.308-5.616,4.322c-0.521,0.747-2.059,1.021-1.317,2.407c0.728,1.357,1.901,0.565,2.916,0.488
				c-0.931,0.529-1.06,1.311-0.162,1.812c2.47,1.38,0.809,1.641-1.011,2.408c0.867-1.216,0.843-1.694-0.071-2.462
				C390.035,593.987,388.256,593.465,387.444,591.842z"/>
			<path fill-rule="evenodd" d="M423.194,586.511c0.769-1.889,2.593-1.582,4.047-1.788c4.731-0.669,9.446-0.788,13.881,1.525
				c-2.019-0.479-4.016-1.077-6.058-1.414c-4.107-0.677-8.081-0.104-11.867,1.665C422.899,586.416,423.232,586.518,423.194,586.511z"
				/>
			<path fill-rule="evenodd" d="M418.638,585.684c0.159-0.85,0.96-1.037,1.549-1.296c1.115-0.49,0.605-1.147,0.235-1.903
				c2.289,1.388,2.99,1.138,3.037-0.951c0.681,0.256,1.338,0.367,2.044,0.002c1.075-0.558,1.293,0.386,1.847,1.081
				C424.218,583.312,421.383,584.377,418.638,585.684z"/>
			<path fill-rule="evenodd" d="M337.22,603.982c2.336-1.399,5.02-1.487,7.59-1.802c2.224-0.273,4.513,0.022,6.785-0.172
				c-4.853,0.373-9.647,1.089-14.424,1.93L337.22,603.982z"/>
			<path fill-rule="evenodd" d="M398.017,590.233c0.562-1.614,2.036-1.737,3.387-2.144c2.848-0.855,5.651-0.312,8.464,0.128
				c0.58,0.091,1.021,0.432,1.158,1.056C406.55,587.702,402.16,587.3,398.017,590.233z"/>
			<path fill-rule="evenodd" d="M334.393,608.75c4.051-1.593,8.01-3.587,12.504-3.784C342.624,605.872,338.59,607.532,334.393,608.75z
				"/>
			<path fill-rule="evenodd" d="M375.818,594.203c3.635,0.561,6.84,2.193,9.93,4.1c-0.804,0.831-1.973,0.654-3.106,1.139
				c1.381-0.95,1.302-1.639-0.027-2.347C380.436,595.933,378.117,595.126,375.818,594.203z"/>
			<path fill-rule="evenodd" d="M423.197,586.499c-2.905,0.646-5.213,2.304-7.345,4.278c1.384-2.479,3.916-3.459,6.22-4.744
				c0.637-0.355,0.799,0.203,1.122,0.477C423.194,586.511,423.197,586.499,423.197,586.499z"/>
			<path fill-rule="evenodd" d="M336.827,619.012c-0.298-0.576,0.018-0.864,0.503-1.078c2.115-0.932,4.243-1.82,6.594-2.086
				c-2.34,1.15-4.94,1.692-7.152,3.104C336.778,618.96,336.827,619.012,336.827,619.012z"/>
			<path fill-rule="evenodd" d="M409.125,597.136c1.41-1.955,1.41-1.955-3.345-3.62c1.769,0.116,3.648-0.036,4.88,1.614
				C410.751,595.252,411.536,597.562,409.125,597.136z"/>
			<path fill-rule="evenodd" d="M324.841,605.052c-0.422-0.629-0.108-1.065,0.416-1.38c2.061-1.231,4.049-2.621,6.956-3.349
				c-2.812,1.694-5.599,2.44-7.378,4.729H324.841z"/>
			<path fill-rule="evenodd" d="M415.777,587.812c0.003-0.769,0.589-1.261,1.012-1.894c-1.114-0.536-2.139-1.029-3.145-1.513
				c1.832-0.257,3.234,1.435,5.084,1.218C417.745,586.353,416.762,587.082,415.777,587.812z"/>
			<path fill-rule="evenodd" d="M337.171,603.938c-2.693,1.108-5.385,2.218-8.077,3.326c2.536-1.548,5.107-3.01,8.123-3.284
				C337.22,603.982,337.171,603.938,337.171,603.938z"/>
			<path fill-rule="evenodd" d="M376.611,607.205c-2.089,1.538-4.177,3.076-6.686,3.891
				C372.057,609.632,374.02,607.878,376.611,607.205z"/>
			<path fill-rule="evenodd" d="M374.62,593.499c-2.706-0.002-5.375-0.014-8.048,0C369.255,593.499,371.938,593.499,374.62,593.499z"
				/>
			<path fill-rule="evenodd" d="M314.48,604.882c0.007-1.719-2.012-2.08-2.445-3.562c1.66-0.456,2.343,3.87,4.422,0.842
				C316.261,603.404,315.39,604.157,314.48,604.882z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1351)} fill={props.fillColors[1351]} d="M482.915,612.376c-0.926-0.66-1.852-1.319-2.778-1.979
				c-0.23-0.658-0.715-1.329,0.083-1.942c3.152-2.424,6.114-5.141,9.966-6.491c1.132-0.973,2.475-0.878,3.823-0.756
				c3.187,1.718,6.477,3.31,9.096,5.865c0.896,0.874,2.775,1.368,1.96,3.323c-0.926,0.664-1.852,1.328-2.778,1.991
				c-2.022-2.847-5.139-4.086-8.106-5.556c-1.011-0.5-1.99-0.348-2.995-0.079c-3.074,1.354-6.362,2.391-8.206,5.56L482.915,612.376z"
				/>
			<path fill-rule="evenodd" d="M534.33,592.323c3.354,1.292,5.332,4.393,8.243,6.267c-2.38,1.174-5.075,1.732-7.35,3.639
				c1.966,0.851,3.404,0.153,5.022-0.541c4.695-2.012,9.673-1.381,14.597-1.111c-4.991,0.916-10.338-0.763-15.267,2.034
				c1.338,1.185,2.785,1.101,4.222,0.982c1.43-0.118,2.717-1.173,4.27-0.661c-1.05,0.599-2.499,0.016-3.33,1.237
				c1.258,0.559,2.78,0.596,4.254,1.967c-10.132-3.395-19.974-4.357-30.034-2.707c1.921-1.456,4.261-0.893,6.39-1.193
				c2.338-0.331,4.762-0.032,7.146-0.059c0.7-0.008,1.686,0.511,2-0.545c0.28-0.946-0.957-0.936-1.284-1.561
				c-0.077-0.148-0.367-0.186-0.624-0.303c0.206-0.127,0.391-0.279,0.42-0.253c2.262,1.975,4.021,1.42,5.977-0.7
				c1.507-1.632,1.675-1.305-0.34-2.977C537.214,594.655,535.389,593.952,534.33,592.323z"/>
			<path fill-rule="evenodd" d="M469.667,609.65c-4.629-0.893-9.275-1.698-14.054-1.683c-4.81,0.017-9.511,0.541-14.095,2.031
				C447.648,607.226,457.593,606.111,469.667,609.65z"/>
			<path fill-rule="evenodd" d="M508.418,594.078c1.812-2.815,4.834-3.73,7.75-4.366c6.343-1.381,12.489-0.505,18.237,2.658
				c-2.681-0.172-4.892-1.81-7.545-2.263C520.113,588.954,513.92,589.745,508.418,594.078z"/>
			<path fill-rule="evenodd" d="M506.196,591.812c-0.486,1.18-1.713,1.512-2.583,2.253c0.635-1.173-0.059-1.727-1.007-2.164
				c-2.494-1.151-5.218-1.272-7.864-1.526c-3.646-0.349-7.308-0.021-10.861,0.949c-1.175,0.32-2.546,0.595-2.776,2.199
				c-0.782-0.618-1.565-1.237-2.348-1.855c2.257-1.636,4.893-2.434,7.563-2.643c6.045-0.475,12.137-0.881,17.95,1.555
				C504.984,590.878,505.882,590.891,506.196,591.812z"/>
			<path fill-rule="evenodd" d="M477.281,607.752c-0.341,0-0.682,0-1.022,0c-2.76-2.752-6.476-3.399-9.986-4.438
				c-1.396-0.414-2.857-0.791-4.343-0.781c4.251-0.064,8.198,1.418,12.383,2.761c-0.242-1.422-1.47-1.888-2.1-3.437
				c1.502,1.376,3.505,1.423,3.03,3.88C475.051,606.735,477.144,606.481,477.281,607.752z"/>
			<path fill-rule="evenodd" d="M490.186,601.964c-3.248,1.778-6.333,3.777-9.138,6.227c-0.777,0.678-1.045,1.289-0.911,2.207
				c-0.83-1.07-2.257-1.492-2.949-2.713c2.172,1.468,3.547,0.128,5.153-1.221C484.661,604.516,487.02,602.498,490.186,601.964z"/>
			<path fill-rule="evenodd" d="M505.064,610.396c-0.232-2.97-3.01-3.648-4.801-5.126c-1.904-1.572-4.155-2.727-6.255-4.062
				c3.798,1.639,7.454,3.542,10.247,6.67c1.215,1.359,2.34,0.253,3.502,0.306C506.859,608.921,505.962,609.658,505.064,610.396z"/>
			<path fill-rule="evenodd" d="M520.243,593.254c3.309-0.877,6.613-1.276,9.907,0.061
				C526.851,592.898,523.547,593.016,520.243,593.254z"/>
			<path fill-rule="evenodd" d="M463.2,592.767c-3.649-0.643-7.162,0.147-10.67,1.01C455.95,591.979,459.613,592.634,463.2,592.767z"
				/>
			<path fill-rule="evenodd" d="M486.03,593.971c4.412,0,8.825,0,13.237,0c0,0.089,0,0.179,0,0.268c-4.412,0-8.825,0-13.237,0
				C486.03,594.149,486.03,594.06,486.03,593.971z"/>
			<path fill-rule="evenodd" d="M505.114,600.314c2.329-0.923,3.743-3.519,6.454-3.741
				C509.157,597.346,507.627,599.728,505.114,600.314z"/>
			<path fill-rule="evenodd" d="M474.377,596.786c2.683,0.588,3.793,2.505,5.704,3.034c-0.376,0.441-0.745,1.501-1.261,0.663
				C477.864,598.931,476.323,598.173,474.377,596.786z"/>
			<path fill-rule="evenodd" d="M519.046,603.394c-2.311,0.575-4.609,1.187-6.796,2.152
				C514.214,603.876,516.503,603.233,519.046,603.394z"/>
			<path fill-rule="evenodd" d="M503.359,594.243c-0.421,0.742-0.823,1.495-1.269,2.223c-0.431,0.703-0.538,1.296,0.388,1.668
				c-0.755-0.168-1.51-0.336-2.438-0.543c0.87-0.922,2.83-1.626,0.561-3.021C501.582,594.769,502.508,594.742,503.359,594.243z"/>
			<path fill-rule="evenodd" d="M492.099,235.448c2.085,1.142,3.658,0.58,5.026-1.305c3.286-4.528,5.332-10.867,0.581-17.65
				c-1.459-2.084-2.482-4.384-2.719-6.968c1.369,4.058,4.179,7.41,5.688,11.374c1.886,4.956-1.089,12.454-4.208,15.061
				C495.073,237.125,493.128,236.894,492.099,235.448z"/>
			<path fill-rule="evenodd" d="M537.99,235.773c-0.399,1.289,0.579,2.319,1.314,3.415c0.594,0.885,0.769,1.831,0.083,2.633
				c-0.632,0.739-1.646,0.677-2.436,0.263c-1.675-0.878-3.295-1.293-5.1-0.356c-0.479,0.249-1.127,0.071-1.46-0.493
				c-0.343-0.581-0.484-1.219,0.088-1.734c0.871-0.784,1.761-1.551,2.69-2.261c1.218-0.93,2.788-1.52,2.783-3.473
				c-0.001-0.425,0.513-0.797,1.062-0.757c0.67,0.049,0.974,0.454,0.974,1.109C537.99,234.596,537.99,235.072,537.99,235.773z"/>
			<path fill-rule="evenodd" d="M453.599,199.409c7.579-1.319,12.074,3.059,15.972,8.598c1.218,1.73,2.256,3.625,3.993,4.969
				c-1.648-0.425-2.597-1.678-3.523-2.978c-2.169-3.04-4.438-5.959-7.49-8.234c-2.17-1.615-4.451-2.363-7.114-2.138
				c-0.595,0.05-1.281,0.354-1.788-0.283C453.645,199.348,453.599,199.409,453.599,199.409z"/>
			<path fill-rule="evenodd" d="M524.005,242.164c0.087-1.312,0.994-1.797,1.871-2.309c4.709-2.75,8.869-5.863,10.353-11.629
				c0.745-2.897,2.018-3.187,4.032-1.578c-2.796-1.282-3.396,0.981-3.788,2.501c-1.208,4.688-4.178,7.736-8.195,10.083
				C526.797,240.096,525.17,240.806,524.005,242.164z"/>
			<path fill-rule="evenodd" d="M532.505,231.455c-1.965,4.203-5.853,6.255-9.542,8.529c-3.094,1.906-3.25,2.35-2.024,5.78
				c-2.055-1.575-1.959-3.841,0.181-5.401c2.937-2.143,6.363-3.575,8.882-6.353c0.785-0.865,0.93-2.488,2.565-2.497
				C532.569,231.515,532.505,231.455,532.505,231.455z"/>
			<path fill-rule="evenodd" d="M531.812,242.862c4.204-1.351,6.878,1.453,9.865,3.406c0.066,0.043,0.112,0.115,0.176,0.162
				c1.168,0.849,2.281,2.699,3.797,1.618c1.248-0.891-0.286-2.42-0.503-3.786c1.243,0.449,1.06,1.651,1.488,2.451
				c0.407,0.756,0.141,1.677-0.5,2.166c-0.639,0.488-1.474,0.41-2.209-0.123c-1.412-1.024-2.935-1.912-4.269-3.028
				C537.367,243.809,534.96,242.374,531.812,242.862z"/>
			<path fill-rule="evenodd" d="M548.167,243.907c0.992,1.684,1.854,3.061,1.056,4.991c-0.946,2.286-2.191,3.045-4.583,2.383
				c-2.922-0.809-5.308-2.752-8.392-3.796c0.946-0.682,1.532-0.128,2.083,0.135c1.651,0.786,3.27,1.641,4.91,2.449
				c1.56,0.768,3.218,1.255,4.61-0.141c1.323-1.327,1.356-2.969,0.401-4.599C548.059,244.995,547.778,244.675,548.167,243.907z"/>
			<path fill-rule="evenodd" d="M510.351,233.286c0.782,1.3,1.187,2.844,2.387,3.875c2.158,1.854,6.212,2.127,8.766,0.554
				c0.812-0.5,1.576-1.028,2.567-1.056c-4.891,4.503-11.455,3.625-13.69-1.8C510.141,234.278,510.164,233.813,510.351,233.286z"/>
			<path fill-rule="evenodd" d="M491.66,204.792c-2.982-1.453-3.271-1.406-5.075,1.427c-1.486,2.333-2.873,4.727-4.295,7.099
				c-0.322,0.537-0.491,1.205-1.249,1.326c1.337-3.725,3.677-6.896,5.775-10.181C488.141,202.392,490.292,202.652,491.66,204.792z"/>
			<path fill-rule="evenodd" d="M493.8,239.428c2.15-1.729,5.055-1.858,6.09-4.548c0.845-2.192,0.692-4.781,2.541-6.557
				C500.935,232.901,500.981,238.597,493.8,239.428z"/>
			<path fill-rule="evenodd" d="M447.637,188.044c-3.227-4.034-8.229-3.639-12.517-5.027c-0.326-0.105-0.824,0.087-0.963-0.91
				c3.699,1.446,7.686,0.89,11.107,2.852c1.217,0.698,1.971,1.784,2.301,3.152C447.569,188.107,447.637,188.044,447.637,188.044z"/>
			<path fill-rule="evenodd" d="M557.191,235.696c-0.635,1.843-2.342,2.188-3.849,2.273c-3.249,0.185-5.157,1.546-3.581,5.475
				c-1.267-0.741-1.623-1.96-1.195-3.197c0.457-1.321,1.188-2.487,2.965-2.588C553.562,237.543,555.475,236.841,557.191,235.696z"/>
			<path fill-rule="evenodd" d="M509.526,241.178c-0.356-1.042-0.323-1.983,0.715-1.971c2.01,0.023,3.785,0.966,5.296,2.24
				c0.859,0.725,0.357,1.482-0.653,2.391c0.803-2.882-1.464-2.787-2.763-3.584C510.999,239.565,510.151,239.925,509.526,241.178z"/>
			<path fill-rule="evenodd" d="M485.826,199.201c1.593,0.382,3.212,0.68,4.772,1.167c1.792,0.56,3.653,1.149,3.942,3.465
				c-1.798-1.757-3.709-3.187-6.42-3.285C487.347,200.521,486.371,200.027,485.826,199.201z"/>
			<path fill-rule="evenodd" d="M523.226,247.41c2.969,1.29,5.709,0.086,8.479-0.714C528.974,248.223,526.187,249.094,523.226,247.41z
				"/>
			<path fill-rule="evenodd" d="M542.911,227.906c-0.165,2.819-0.331,5.638-0.497,8.458c-0.503-2.86-0.913-5.711,0.556-8.399
				C542.968,227.962,542.911,227.906,542.911,227.906z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1352)} fill={props.fillColors[1352]} d="M252.619,594.965c0.543-1.02,1.086-2.039,1.629-3.059
				c2.48-2.614,5.055-5.076,8.546-6.364c1.062-0.393,2.112-0.694,3.226-0.82c0.752-0.452,1.564-0.363,2.378-0.26
				c3.432-0.942,6.836-0.752,10.223,0.242c3.28,1.152,6.372,2.575,8.28,5.714c0.731,0.574-0.011,0.714-0.309,0.842
				c-0.251,0.108-0.742,0.842-0.886-0.127c-0.467-0.3-0.934-0.601-1.401-0.901c-8.4-4.529-17.041-5.771-24.274-0.504
				c-2.128,1.79-4.4,3.44-5.521,6.15c-0.287,0.693-0.897,1.268-1.702,0.927C251.811,596.381,252.422,595.611,252.619,594.965z"/>
			<path fill-rule="evenodd" d="M285.705,591.133c0.613,0.122,1.079-0.005,1.195-0.715c1.486-0.236,1.611-0.795,0.657-1.97
				c-0.717-0.883-2.215-0.961-2.358-2.373c1.159,0.732,2.876,0.592,3.309,2.492c0.175,0.77,1.129,0.434,1.854,0.134
				c3.983-1.651,7.898-3.562,12.969-3.453c-0.934-0.7-1.377-1.033-1.821-1.366c1.973,0.427,3.809,1.209,5.841,1.344
				c-3.368,0.529-6.804,0.313-10.021,1.29c-5.169,1.567-10.009,3.835-13.565,8.137c-0.692,0.838-1.923,1.623-0.882,3.014
				c-0.567-0.163-2.063,0.807-1.166-1.101c0.645-1.371-1.701-1.409-1.501-2.723c-0.105,0.069-0.209,0.139-0.314,0.208
				c0.104-0.065,0.209-0.132,0.316-0.199c0.309,0.607,0.9,0.804,1.468,1.078c0.815,0.395,1.251-0.142,1.572-0.654
				c0.451-0.72-0.233-0.996-0.724-1.2c-5.924-2.475-11.815-5.229-18.46-2.606c2.604-2.342,5.709-1.955,8.657-1.24
				c3.643,0.883,7.349,1.66,10.68,3.592C285.096,593.798,285.276,592.168,285.705,591.133z"/>
			<path fill-rule="evenodd" d="M182.812,593.499c3.337-2.226,7.113-3.1,11.025-3.413c3.378-0.271,6.727-0.453,10.17-0.172
				c3.483,0.284,7.066-0.322,10.604,0.159c3.151,0.429,6.364,0.637,9.122,2.536c-4.81-1.853-9.854-2.451-14.906-2.229
				c-6.369,0.281-12.791-0.718-19.121,0.843C187.334,591.809,185.148,592.861,182.812,593.499z"/>
			<path fill-rule="evenodd" d="M187.29,595.446c5.033-2.666,10.307-3.849,15.925-2.407c0.969,0.249,1.795,0.197,2.767-0.006
				c3.206-0.671,6.45-1.07,10.008,0.293c-4.003-0.084-7.609-0.684-11.24,0.596c-1.246,0.438-2.948-0.734-4.57-0.848
				C195.647,592.758,191.424,593.771,187.29,595.446z"/>
			<path fill-rule="evenodd" d="M252.619,594.965c0.108,0.498-0.37,1.225,0.34,1.463c0.743,0.25,1.142-0.376,1.433-0.959
				c1.262-2.53,3.279-4.347,5.553-5.924c0.253-0.028,0.362,0.075,0.302,0.331c-1.779,1.552-3.714,2.95-4.925,5.058
				c-0.467,0.812-0.9,1.62-0.18,2.771c-1.293-0.317-2.269-0.658-3.272-0.777c-1.208-0.143-2.16-0.102-1.651-1.915
				c0.276-0.983-1.039-1.247-1.865-1.396c-3.345-0.602-6.702-1.132-10.122-0.985c3.709-0.228,7.406-0.243,11.038,0.722
				c0.988,0.263,2.189,0.361,1.54,1.941c-0.139,0.338-0.014,0.654,0.36,0.729C252.011,596.191,252.029,595.176,252.619,594.965z"/>
			<path fill-rule="evenodd" d="M260.247,589.876c-0.093-0.117-0.193-0.228-0.302-0.331c5.277-3.884,11.125-4.317,17.158-2.474
				c2.479,0.758,5.375,0.823,7.202,3.16c-3.891-1.688-7.846-3.228-12.091-3.682C267.826,586.08,263.866,587.437,260.247,589.876z"/>
			<path fill-rule="evenodd" d="M257.367,597.859c0.93,0.18,1.868-0.086,2.755,0.777c0.766,0.745,2.025,0.328,3.169,0.053
				c-3.297,1.155-4.785,4.32-7.373,6.202c1.455-2.54,1.362-2.799-1.51-3.454c-1.095-0.25-2.105-0.829-3.296-0.723
				c1.369-0.374,2.708-0.134,3.848,0.434c2.318,1.153,3.958,0.548,5.283-1.559C259.553,598.579,257.895,599.131,257.367,597.859z"/>
			<path fill-rule="evenodd" d="M237.217,590.235c1.463-2.289,3.836-2.83,6.252-3.003c2.985-0.213,5.974-0.117,8.769,1.268
				c0.616,0.306,1.373,0.329,2.064,0.482c-0.516,0.707-0.854,0.804-1.849,0.333c-3.427-1.62-7.069-2.668-10.866-1.291
				C240.064,588.577,238.67,589.487,237.217,590.235z"/>
			<path fill-rule="evenodd" d="M266.02,584.722c-3.956,1.125-7.505,2.93-10.299,6.046c-0.408,0.454-0.978,0.763-1.473,1.139
				C257.353,588.17,260.813,585.016,266.02,584.722z"/>
			<path fill-rule="evenodd" d="M197.451,609.672c-2.915-1.622-5.831-3.245-8.746-4.867
				C192.078,605.605,194.804,607.567,197.451,609.672z"/>
			<path fill-rule="evenodd" d="M260.133,584.233c0.79-0.668,0.83-2.63,2.735-1.448c0.328,0.204,1.585-0.34,0.981-1.484
				c0.65,0.032,1.521-0.283,1.611,0.775c0.069,0.794-0.717,0.686-1.176,0.842C262.911,583.388,261.518,583.799,260.133,584.233z"/>
			<path fill-rule="evenodd" d="M264.164,590.402c-2.455,1.738-4.928,3.457-6.787,5.884
				C258.643,593.176,261.17,591.52,264.164,590.402z"/>
			<path fill-rule="evenodd" d="M278.62,584.704c-3.399-0.447-6.793-1.098-10.223-0.242
				C271.838,583.13,275.242,583.383,278.62,584.704z"/>
			<path fill-rule="evenodd" d="M464.513,677.924c2.581,1.945,5.357-0.67,7.978,0.342c-2.997,1.309-6.271,1.659-9.334,2.84
				c-4.219,1.626-7.983,3.979-11.737,6.388c-1.061,0.681-1.675,1.64,0.128,2.348c0.451,0.177,0.739,0.591,0.772,1.119
				c-2.776-0.974-4.996-2.915-7.478-4.392c-6.326-3.767-13.035-6.575-20.058-8.716c-0.704-0.214-1.616-0.206-2.057-1.026
				c1.359,0.068,2.412-0.65,3.477-1.348c8.625-5.646,18.133-7.621,28.301-6.695c2.461,0.224,4.735,1.235,6.906,2.367
				c3.128,1.632,6.749,2.132,9.646,4.272c-3.683-0.664-7.001-2.375-10.373-3.833c-7.02-3.035-14.107-3.214-21.29-1.01
				c-4.284,1.315-8.667,2.494-12.334,5.291c-0.433,0.33-1.174,0.445-1.172,1.082c0.003,0.875,0.933,0.716,1.452,0.958
				c0.788,0.369,1.745,0.422,2.473,0.866c2.806,1.717,5.341,1.387,7.728-0.771c0.633,1.79,1.342,3.425,3.428,4.104
				c1.336,0.436,2.561,0.728,3.937,0.304c3.003-0.925,6.041-1.352,9.171-0.591c1.185,0.288,2.366,0.116,3.468-0.498
				C459.804,680.066,462.52,679.736,464.513,677.924z"/>
			<path fill-rule="evenodd" d="M516.156,691.466c-2.46-0.38-4.59-1.671-6.859-2.56c-5.622-2.199-11.461-3.24-17.418-4.024
				c-4.531-0.597-9.039-0.312-13.555-0.408c-8.208-0.176-15.633,2.426-22.591,6.59c-0.33,0.197-0.717,0.299-1.078,0.443
				c1.504-2.637,4.367-3.337,6.812-4.375c9.381-3.981,19.226-4.013,29.199-3.121c6.255,0.56,12.313,1.843,18.164,3.985
				C511.343,688.916,514.124,689.458,516.156,691.466z"/>
			<path fill-rule="evenodd" d="M550.174,675.667c0.1-0.897,0.893-1.029,1.506-1.316c4.472-2.094,9.229-3.228,14.12-3.139
				c4.058,0.073,8.115,0.745,11.969,2.236c0.415,0.161,0.686,0.327,0.788,0.748c-2.987-0.329-5.799-1.412-8.777-1.9
				c-6.578-1.077-12.557,0.582-18.49,2.986C550.925,675.429,550.546,675.539,550.174,675.667z"/>
			<path fill-rule="evenodd" d="M506.796,679.31c8.933,1.382,16.407,5.755,23.044,11.782
				C522.846,685.823,515.403,681.454,506.796,679.31z"/>
			<path fill-rule="evenodd" d="M561.695,677.641c-6.927,1.489-13.22,3.571-18.039,8.74c-0.514,0.551-0.896,2.295-1.722,1.507
				c-1.285-1.227,0.774-1.498,1.296-2.074c4.559-5.037,10.65-7.152,17.05-8.607c0.222-0.05,0.477,0.001,0.708,0.048
				C561.131,677.283,561.258,677.395,561.695,677.641z"/>
			<path fill-rule="evenodd" d="M535.75,681.121c1.841,1.436,3.273,0.47,4.869-0.622c1.567-1.074,2.887-2.723,5.054-2.714
				c-1.318,1.523-3.291,2.109-4.812,3.354c-0.893,0.729-1.956,1.274-1.941,2.932C537.924,682.805,536.403,682.477,535.75,681.121z"/>
			<path fill-rule="evenodd" d="M540.469,327.483c-1.929-0.08-2.793-1.065-2.825-3.035c-0.031-1.898,1.112-2.358,2.594-2.332
				c1.944,0.034,2.804,1.208,2.856,3.101C543.145,327.071,542.081,327.559,540.469,327.483z"/>
			<path fill-rule="evenodd" d="M519.969,334.454c0.144-1.166,0.973-2.113,2.548-2.19c1.616-0.079,3.461,1.678,3.237,3.188
				c-0.215,1.449-1.239,2.499-2.684,2.712C521.569,338.386,519.997,336.559,519.969,334.454z"/>
			<path fill-rule="evenodd" d="M490.513,354.35c0.14,1.321-0.552,1.971-1.756,2.01c-1.37,0.044-2.266-0.684-2.332-2.082
				c-0.066-1.405,0.858-1.86,2.133-1.948C490.082,352.224,490.371,353.2,490.513,354.35z"/>
			<path fill-rule="evenodd" d="M474.67,363.178c0.079,1.022-0.398,1.554-1.644,1.636c-1.735,0.114-1.733-1.383-1.862-2.226
				c-0.164-1.079,0.913-1.971,1.963-1.874C474.245,360.817,474.761,361.842,474.67,363.178z"/>
			<path fill-rule="evenodd" d="M504.901,347.998c-1.585-0.159-2.487-1.13-2.427-2.623c0.049-1.21,1.026-1.678,2.254-1.523
				c1.444,0.182,2.46,0.935,2.481,2.394C507.229,347.628,506.203,348.107,504.901,347.998z"/>
			<path fill-rule="evenodd" d="M449.193,707.574c-3.336-0.895-6.367-2.637-9.831-3.244c-10.158-1.782-19.447,0.158-27.771,6.259
				c-0.908,0.665-2.86,1.487-1.837,3.077c0.997,1.549,2.399,0.199,3.584-0.312c1.645-0.71,3.127-1.844,5.712-2.1
				c-2.527,1.654-4.973,2.113-6.785,3.718c0.603,0.929,1.896,1.081,2.144,2.224c-1.801-0.448-3.177-1.683-4.745-2.56
				c-2.757-1.542-5.654-2.757-8.677-3.661c-0.495-0.148-0.891-0.281-1.06-0.796c1.613-0.05,1.011-0.895,0.694-1.762
				c-1.024-2.806-3.491-4.479-5.197-6.746c0.517-0.125,0.97-0.437,1.57-0.061c2.548,1.598,5.461,1.235,8.251,1.339
				c3.07,0.114,6.031,0.631,8.915,1.654c1.579,0.56,3.082,0.515,4.848-0.62c-3.672-1.188-7.13-1.672-10.61-1.921
				c-4.078-0.292-8.21,0.446-12.256-0.623c-0.19-0.051-0.434,0.174-0.646,0.282c-2.361-1.758-4.723-3.516-7.083-5.273
				c1.632,0.166,3.152-0.081,4.751-0.607c2.994-0.984,6.172-0.681,9.263-0.484c7.27,0.46,14.489,1.317,21.441,3.67
				c2.242,0.759,4.59,1.323,6.684,2.381c4.038,2.04,8.623,1.7,12.745,3.261C445.356,705.449,447.611,705.826,449.193,707.574z"/>
			<path fill-rule="evenodd" d="M426.466,694.666c7.015,1.771,13.773,4.157,19.792,8.339
				C439.915,699.608,433.327,696.806,426.466,694.666z"/>
			<path fill-rule="evenodd" d="M449.852,693.854c3.382,2.115,6.684,4.327,9.191,7.511
				C455.797,699.084,452.483,696.886,449.852,693.854z"/>
			<path fill-rule="evenodd" d="M458.953,701.283c3.584,2.231,6.527,5.104,8.721,8.725C464.536,707.33,461.64,704.411,458.953,701.283
				z"/>
			<path fill-rule="evenodd" d="M459.012,716.475c-1.755-1.957-4.027-3.441-5.318-5.822
				C455.897,712.199,458.242,713.617,459.012,716.475z"/>
			<path fill-rule="evenodd" d="M514.721,739.684c-0.742,1.251-2.147,1.004-3.245,1.325c-5.987,1.751-11.864,3.692-16.782,7.749
				c-0.158,0.131-0.461,0.087-0.695,0.124c0.216-1.12,1.281-1.51,2.037-2.323c-2.024-1.511-4.376-2.3-6.578-3.396
				c1.501,0.179,3.034,0.439,4.192,1.366c2.838,2.268,5.133,0.548,7.589-0.713c0.642-0.33,1.229-0.372,0.691-1.23
				c1.885,0.342,3.55-0.426,5.248-1.034c0.723-0.259,1.798-0.231,1.852-1.187c0.05-0.876-0.653-1.575-1.375-2.135
				c-2.676-2.07-5.445-4.001-8.305-5.812c-3.003-1.902-6.252-3.324-9.429-4.969c0.52-0.725,1.011-0.175,1.434,0.013
				c6.091,2.708,12.127,5.785,16.746,10.511C510.447,740.373,512.306,739.969,514.721,739.684z"/>
			<path fill-rule="evenodd" d="M500.071,741.18c-2.987-0.387-5.365-2.309-8.194-3.198c-7.998-2.511-15.996-3.114-24.31-1.882
				c-5.277,0.782-10.266,2.373-15.312,3.886c1.202-2.047,3.484-2.021,5.354-2.605c7.835-2.445,15.939-2.891,24.041-2.308
				c6.017,0.433,11.79,2.206,17.196,5.009C499.373,740.354,499.757,740.71,500.071,741.18z"/>
			<path fill-rule="evenodd" d="M439.845,744.079c-1.576-0.254-2.88-1.086-4.159-1.969c1.038-0.163,1.908-0.517,2.619-1.412
				c4.898-6.169,11.444-9.992,18.637-12.822c2.752-1.083,5.584-1.983,8.623-1.966c-4.107,1.073-8.168,2.278-11.938,4.207
				c-5.065,2.593-9.892,5.531-13.782,9.842C438.417,741.54,438.031,742.7,439.845,744.079z"/>
			<path fill-rule="evenodd" d="M443.663,745.996c-1.476-0.324-2.79-0.968-3.916-1.982c2.541,1.252,4.67,0.672,6.498-1.413
				c0.371-0.424,0.896-0.664,1.507-0.496C446.779,743.812,444.531,744.179,443.663,745.996z"/>
			<path fill-rule="evenodd" d="M481.644,725.267c-3.918,0-7.837,0-11.755,0c0.001-0.129,0.002-0.259,0.003-0.388
				c3.934,0,7.869,0,11.803,0C481.677,725.008,481.66,725.138,481.644,725.267z"/>
			<path fill-rule="evenodd" d="M517.95,609.155c5.316-1.74,10.788-1.2,13.506-1.251C528.706,607.474,523.343,608.53,517.95,609.155z"
				/>
			<path fill-rule="evenodd" d="M557.449,607.731c-0.913,0.168-1.826,0.336-2.871,0.527c0.403,1.271,1.871,1.373,2.393,2.379
				c-2.578-1.163-4.712-3.11-6.443-3.742C552.009,608.265,554.842,607.296,557.449,607.731z"/>
			<path fill-rule="evenodd" d="M548.238,591.41c2.969-1.215,6.065-1.475,9.24-1.36c1.362,0.049,2.37,0.739,3.444,1.38
				c-3.959-1.449-7.882-1.342-11.79,0.227C548.904,591.748,548.54,591.5,548.238,591.41z"/>
			<path fill-rule="evenodd" d="M549.166,602.97c3.219-0.512,6.148,0.584,9.109,1.526C555.23,604.055,552.295,602.984,549.166,602.97z
				"/>
			<path fill-rule="evenodd" d="M112.491,453.617c-0.77,1.086-1.902,0.546-2.851,0.534c-1.037-0.014-1.436-0.551-0.636-1.545
				c0.89-1.106,1.683-2.29,2.519-3.439c0.226-0.311,0.649-0.613,0.396-1.024c-0.316-0.513-0.808-0.211-1.267-0.131
				c-3.712,0.652-4.223,0.347-5.449-3.412c-0.367-1.123-1.388-0.775-2.062-1.156c-2.512-1.415-4.167-1.475-4.844,1.901
				c-0.278,1.389-1.711,2.229-2.693,3.254c-0.268,0.28-0.551,0.648-0.992,0.391c-0.428-0.251-0.54-0.591-0.479-1.177
				c0.157-1.498,0.951-3.106-0.431-4.468c-0.227-1.063,0.291-1.609,1.357-1.981c0.25,2.175,0.288,4.203-0.204,6.697
				c1.84-1.396,3.389-2.702,3.625-4.591c0.277-2.222,1.423-1.896,2.771-1.476c0.821,0.256,1.54,0.801,2.479,0.691
				c1.583-0.184,2.027,0.896,2.264,2.149c0.487,2.584,1.448,3.212,4.05,2.633c0.308-0.068,0.691-0.023,0.919-0.193
				c0.77-0.571,1.149,0.026,1.605,0.449c0.614,0.567,0.101,1.059-0.181,1.466c-0.893,1.296-1.856,2.543-2.856,3.895
				C110.447,453.802,111.568,453.239,112.491,453.617z"/>
			<path fill-rule="evenodd" d="M254.539,543.441c-1.495-1.463-0.002-1.412,0.801-1.401c1.537,0.021,2.942-0.28,4.331-0.937
				c1.104-0.521,1.352-1.064,0.784-2.218c-0.53-1.076-1.085-2.061-1.992-2.864c-2.009-1.779-3.415-4.081-5.172-6.08
				c2.246,2.056,4.886,1.047,7.388,0.897c2.136-0.128,2.517-1.092,1.317-2.976c-1.302-2.043-3.216-3.521-4.856-5.252
				c-2.359-2.494-4.664-5.013-5.727-8.391c-0.188-0.596-0.649-1.188,0.251-1.593c1.055,4.695,4.387,7.81,7.663,10.939
				c1.384,1.322,2.709,2.679,3.67,4.332c1.199,2.063,0.409,3.689-1.963,3.734c-1.527,0.028-2.9,0.592-4.637,0.629
				c0.507,1.32,1.534,1.956,2.273,2.809c0.722,0.834,1.549,1.605,2.13,2.53c1.565,2.491,0.782,3.974-2.036,4.829
				C257.362,542.856,255.737,542.283,254.539,543.441z"/>
			<path fill-rule="evenodd" d="M264.319,518.424c2.554,0.551,4.183,2.643,6.36,3.824c3.151,1.71,6.095,3.781,9.506,5.035
				c3.718,1.366,3.877,1.335,3.58-2.294c1.295,0.843,1.514,2.146,0.842,3.299c-0.676,1.161-2.091,0.767-3.056,0.385
				c-2.687-1.063-5.289-2.337-8.424-3.753c0.964,1.74,2.345,2.349,3.458,3.121c4.182,2.902,8.573,5.426,13.549,6.769
				c0.532,0.144,1.405-0.06,1.384,0.95c-4.367-0.446-8.079-2.599-11.741-4.729c-3.943-2.293-7.654-4.893-10.464-8.666
				C268.069,520.69,265.647,520.186,264.319,518.424z"/>
			<path fill-rule="evenodd" d="M306.4,531.841c-0.179,0.338-0.283,0.76-0.55,1c-1.297,1.164-2.422,2.078,0.205,3.185
				c1.484,0.624,0.993,2.305-0.915,3.145c-2.755,1.212-5.674,1.853-8.65,2.253c-1.244,0.168-2.527,0.223-3.715,0.582
				c-1.657,0.5-2.828,1.384-2.804,3.505c0.032,2.777-1.425,4.071-4.33,4.216c-1.587,0.078-3.184-0.031-4.776-0.056
				c0.78-1.263,2.019-0.608,3.027-0.631c3.928-0.082,4.607-0.595,5.317-4.437c0.465-2.517,2.541-3.729,6.615-4.04
				c3.077-0.235,6.041-1.04,8.884-2.227c1.401-0.585,1.502-1.003,0.626-1.683c-0.75-0.582-2.259-0.715-1.875-2.017
				C303.859,533.28,304.803,532.122,306.4,531.841z"/>
			<path fill-rule="evenodd" d="M180.649,548.224c-1.79-0.087-3.585-0.115-5.367-0.288c-0.775-0.076-1.638-0.374-1.909-1.231
				c-0.299-0.948,0.408-1.437,1.127-1.845c0.069-0.039,0.161-0.057,0.209-0.112c0.832-0.943,2.715-1.553,1.86-3.13
				c-0.794-1.465-2.428-1.938-4.152-1.308c0.096-0.297,0.143-0.624,0.297-0.888c1.499-2.552,1.5-2.539-1.134-3.485
				c-0.632-0.228-1.515-0.194-1.547-1.193c-0.024-0.742,0.537-1.111,1.044-1.498c1.132-0.861,2.292-1.688,3.391-2.589
				c0.541-0.443,1.248-0.993,0.887-1.786c-0.361-0.794-1.238-0.843-2.051-0.913c-0.893-0.076-1.626,0.753-2.556,0.371
				c-0.053-1.001,1.46-0.711,1.35-1.896c0.029,0.061-0.003-0.157-0.102-0.192c-0.985-0.354-2.413,0.103-2.861-1.207
				c-0.496-1.45,0.243-2.589,1.392-3.505c0.344-0.274,0.86-0.594,0.664-1.145c-0.2-0.561-0.78-0.447-1.243-0.452
				c-0.887-0.008-1.86-0.001-2.224-0.963c-0.375-0.991,0.417-1.647,1.081-2.205c0.892-0.748,1.425-1.948,2.72-2.196
				c-0.831,1.251-0.939,2.851-2.084,3.924c0.708,1.053,2.425,0.107,2.68,1.683c0.22,1.359-1.125,1.801-1.506,2.799
				c3.183,0.737,3.183,0.737,2.719,4.453c0.967,0.09,2.283-1.084,2.842,0.484c0.507,1.42-0.018,2.635-1.266,3.53
				c-0.707,0.508-1.434,1.017-2.033,1.638c-0.967,1.004-2.404,2.224,0.564,2.36c0.588,0.027,1.108,0.486,1.268,0.991
				c0.594,1.871,1.991,0.645,2.96,0.739c1.032,0.102,1.088-0.918,1.083-1.754c-0.011-1.704,1.371-2.604,2.265-3.754
				c1.939-2.493,3.757-4.996,4.387-8.211c0.579-2.956,1.236-3.212,3.875-1.909c0.718,0.354,1.619,0.812,1.94-0.282
				c0.277-0.939-0.116-1.845-1.203-2.273c-1.058-0.418-2.361-0.073-3.23-1.051c3.61-0.501,5.354,0.595,5.338,3.383
				c-0.009,1.576-0.643,2.251-2.212,1.415c-0.489-0.26-1.038-0.462-1.438-0.825c-1.308-1.185-1.717-0.275-2.051,0.825
				c-0.609,2.01-1.197,4.025-1.802,6.067c0.455,0.316,1.13,0.37,1.51,0.106c2.781-1.936,4.81-0.339,6.648,1.479
				c0.7,0.691,1.773,0.652,2.271,1.522c-0.913,0.319-1.642,0.158-2.37-0.57c-1.71-1.713-3.262-2.454-6.195-1.837
				c-4.461,0.939-5.955,3.544-6.961,7.258c-0.236,0.873-0.557,1.915-0.975,2.555c-1.615,2.466,1.054,4.82-0.107,7.288
				C178.127,547.271,180.31,547.064,180.649,548.224z"/>
			<path fill-rule="evenodd" d="M203.173,508.155c-1.403-1.233-2.638-2.561-4.932-1.833c-1.065,0.338-1.464,0.57-1.375,1.628
				c0.059,0.714-0.003,1.437,0.004,2.155c0.021,2.043-0.809,3.127-3.027,2.952c-1.322-0.104-2.622-0.136-3.872,0.762
				c-1.199,0.861-2.524,0.171-3.809-0.295c-0.668-0.242-1.716-1.828-2.154-0.199c-0.321,1.194-1.048,3.089,1.056,3.89
				c0.618,0.235,1.521-0.194,1.828,0.774c-3.464,0.159-5.008-2.014-3.81-5.366c0.37-1.035,1.14-1.129,1.875-0.544
				c3.34,2.658,6.688-0.654,10.044,0.038c0.594,0.122,0.871-0.854,0.957-1.455c0.113-0.782-0.052-1.608,0.085-2.383
				c0.183-1.029-0.888-2.621,1.466-2.724C201.384,505.387,202.662,505.838,203.173,508.155z"/>
			<path fill-rule="evenodd" d="M492.772,527.582c0.562,0.613,2.002-1.181,2.152,0.485c0.117,1.296-0.452,2.658-2.382,2.264
				c-0.521-0.106-1.442-0.131-1.59,0.135c-0.56,1.008-1.119,0.737-1.856,0.321c-0.605-0.341-0.668-0.697-0.455-1.35
				c0.317-0.974,0.878-1.651,1.855-1.831C491.19,527.479,491.926,527.582,492.772,527.582z"/>
			<path fill-rule="evenodd" d="M546.341,302.685c-0.013,2.484-1.516,3.975-3.979,3.947c-1.745-0.02-3.328-1.582-3.34-3.296
				c-0.015-2.153,1.909-4.135,4.06-4.182C544.925,299.112,546.351,300.657,546.341,302.685z"/>
			<path fill-rule="evenodd" d="M530.022,313.705c0.073,2.389-0.607,3.805-2.654,4.011c-1.593,0.161-3.716-2.027-3.741-3.652
				c-0.026-1.62,1.514-3.256,3.125-3.319C528.848,310.663,530.007,311.886,530.022,313.705z"/>
			<path fill-rule="evenodd" d="M531.503,308.944c0.177-1.272,1.124-2.441,2.861-2.688c1.759-0.251,3.482,1.326,3.559,3.035
				c0.07,1.567-1.542,3.242-3.123,3.244C533.249,312.536,531.55,310.933,531.503,308.944z"/>
			<path fill-rule="evenodd" d="M518.117,323.148c-2.359,0.266-2.387-1.875-2.261-2.958c0.186-1.595,1.062-3.234,3.261-3.338
				c1.733-0.082,2.741,0.721,3.022,2.278c0.253,1.402,0.268,2.939-1.27,3.758c-0.87,0.463-1.816,1.509-2.843,0.177
				c0.352-0.949,1.675-1.438,1.435-2.935C518.051,320.898,518.938,322.389,518.117,323.148z"/>
			<path fill-rule="evenodd" d="M558.321,287.879c0.014,2.116-1.424,3.893-3.086,3.754c-1.984-0.165-2.837-1.608-3.06-3.34
				c-0.246-1.917,1.507-3.562,3.559-3.564C557.951,284.727,558.602,285.983,558.321,287.879z"/>
			<path fill-rule="evenodd" d="M551.838,292.43c0.321,0.345,0.718,0.646,0.95,1.042c1.02,1.733,0.65,3.783-0.876,4.893
				c-1.578,1.148-3,0.537-4.178-0.627c-1.455-1.439-1.77-3.196-0.432-4.857c1.285-1.596,2.912-1.569,4.55-0.422
				c0.307,1.187-0.497,1.42-1.37,1.632c-0.577,0.141-1.01,0.473-0.959,1.144c0.034,0.449,0.318,0.733,0.777,0.7
				c0.665-0.048,0.972-0.574,1.022-1.118C551.399,293.991,552.257,293.345,551.838,292.43z"/>
			<path fill-rule="evenodd" d="M511.666,323.238c2.089,0.327,3.052,1.469,2.929,3.155c-0.118,1.627-1.305,2.623-2.952,2.419
				c-1.859-0.23-2.669-1.507-2.508-3.395C509.289,323.619,510.592,323.405,511.666,323.238z"/>
			<path fill-rule="evenodd" d="M143.183,522.309c-0.024,1.08,0.857,1.274,1.583,1.444c1.682,0.395,3.381,0.794,5.095,0.95
				c1.633,0.149,2.701,0.717,2.987,2.423c0.288,1.712,1.426,2.288,3.053,2.365c2.953,0.141,3.263,0.716,2.036,3.504
				c-0.438,0.993-1.59,2.295,1.283,1.775c-3.563,1.506-6.869,2.138-10.305,1.892c-0.735-0.053-1.717-0.287-2.101-0.801
				c-0.591-0.791,0.968-0.851,0.933-1.883c-1.312-0.069-2.668-0.142-4.024-0.213c2.992-0.98,5.889-0.024,8.848,0.098
				c-1.488,0.739-3.165,0.714-4.693,1.533c3.94,1.312,7.54,0.29,9.469-2.717c0.702-1.092,1.143-2.209-0.97-2.446
				c-1.992-0.224-4.035-0.291-4.254-3.34c-0.119-1.659-2.37-1.575-3.783-1.739c-1.584-0.185-3.079-0.632-4.56-1.108
				c-0.55-0.177-2.1-0.583-0.54-1.684C143.244,522.364,143.183,522.309,143.183,522.309z"/>
			<path fill-rule="evenodd" d="M171.032,541.95c-1.236,2.081-3.424,2.525-5.47,3.305c-2.288,0.873-4.685,1.209-7.039,1.749
				c-0.622,0.143-1.508,0.404-1.913-0.424c-0.355-0.726,0.346-1.153,0.767-1.574c0.668-0.666,1.413-1.255,2.125-1.877
				c0.098,1.494-1.468,1.908-2.179,3.316c4.056-0.665,7.822-1.283,11.265-3.2C169.39,542.798,169.969,541.903,171.032,541.95z"/>
			<path fill-rule="evenodd" d="M452.521,753.669c4.2-0.231,8.395-0.786,12.605-0.484c4.284,0.307,8.597,0.481,12.747,1.928
				c-2.672-0.279-5.347-0.529-8.015-0.843c-4.839-0.569-9.676-0.511-14.513-0.026c-1.019,0.103-2.065,0.504-2.816-0.595
				C452.529,753.646,452.521,753.669,452.521,753.669z"/>
			<path fill-rule="evenodd" d="M499.047,768.317c-0.304,0.186-0.628,0.209-0.828-0.103c-2.393-3.718-5.492-6.711-9.165-9.128
				c4.509,1.801,7.643,5.087,9.922,9.299C498.981,768.38,499.047,768.317,499.047,768.317z"/>
			<path fill-rule="evenodd" d="M102.805,464.795c1.947-1.442,3.602-3.33,6.071-3.974c-1.665,2.02-1.677,2.178,0.747,2.414
				c2.294,0.225,2.431,1.776,2.788,4.14c-1.029-1.928-1.481-3.301-3.458-3.628c-1.741-0.289-3.161-0.671-4.572,0.678
				C104.021,464.77,103.399,464.964,102.805,464.795z"/>
			<path fill-rule="evenodd" d="M109.056,460.633c0.114-1.312,1.124-1.043,1.965-1.156c1.504-0.203,3.139-0.249,2.133-2.654
				c-0.241-0.576-0.81-1.973,0.766-2.265c-0.021,0.556-0.082,1.113-0.058,1.667c0.161,3.727,0.166,3.72-3.677,4.09
				C109.802,460.351,109.432,460.523,109.056,460.633z"/>
			<path fill-rule="evenodd" d="M376.875,675.419c0.472-0.895,2.471-0.446,1.948-2.216c0.536,0.506,0.851,1.344,1.866,1.086
				c1.019-0.259,0.972-1.04,1.002-1.81c2.487-0.521,5.044-0.44,7.545-0.552c5.81-0.257,11.644-0.309,17.438,0.083
				c-3.485-3.74-17.951-3.432-24.908,0.559c-0.148-0.188-0.35-0.354-0.437-0.565c-0.422-1.028-1.072-1.548,0.913-2.239
				c4.809-1.672,9.767-1.843,14.674-2.343c5.671-0.577,10.631,1.677,15.089,5.076c-0.132,0.364-0.476,0.826-0.359,1.073
				c0.395,0.841,4.956,1.43,5.649,0.767c6.951-6.658,15.382-9.516,24.858-9.856c2.595-0.093,5.166-0.767,7.761-0.889
				c3.649-0.171,7.229,0.616,10.77,1.399c7.64,1.69,13.997,5.915,20.195,10.426c-0.961,0.184-1.619-0.376-2.389-0.834
				c-4.863-2.894-9.412-6.352-14.938-8.052c-6.679-2.055-13.364-2.656-20.325-1.443c-3.621,0.631-7.395,0.48-10.95,1.645
				c-5.276,1.729-10.302,3.923-14.233,7.976c0.709,1.392,2.634,0.372,3.062,1.914c-8.182-1.822-16.399-3.003-24.846-2.922
				c-6.02,0.058-11.914,0.592-17.775,1.811C377.915,675.63,377.396,675.726,376.875,675.419z"/>
			<path fill-rule="evenodd" d="M377.479,670.627c-1.858-1.999-3.202-4.396-5.092-6.402c-1.243-1.321-2.833-2.245-3.904-3.718
				c0.565-0.081,1.102-0.085,1.565,0.35c2.901,2.716,5.562,5.633,7.559,9.097C377.692,670.104,377.527,670.398,377.479,670.627z"/>
			<path fill-rule="evenodd" d="M402.703,663.056c-3.705-2.411-7.814-3.617-12.182-4.073c4.252-0.069,8.28,0.693,11.865,3.115
				c0.343,0.231,0.533,0.585,0.259,1.021C402.641,663.123,402.703,663.056,402.703,663.056z"/>
			<path fill-rule="evenodd" d="M308.894,707.876c-2.716,0.439-5.437,0.749-8.189,0.395c2.734,0.436,5.371-1.538,8.142-0.333
				C308.849,707.934,308.894,707.876,308.894,707.876z"/>
			<path fill-rule="evenodd" d="M469.437,648.133c-3.917-1.026-7.823-2.107-11.934-2.251c1.597-0.544,3.148-0.604,4.773-0.15
				c2.177,0.608,4.391,1.094,6.604,1.553c0.667,0.139,0.802,0.345,0.49,0.912C469.365,648.2,469.437,648.133,469.437,648.133z"/>
			<path fill-rule="evenodd" d="M493.066,316.156c-0.046-3.39,1.336-6.479,2.101-9.69c3.162-13.269,5.796-26.662,9.407-39.824
				c1.189-4.333,2.422-8.653,3.611-12.985c0.121-0.438,0.261-0.731,0.761-0.694c0.527,2.025-0.738,3.673-1.229,5.46
				c-4.217,15.38-7.746,30.922-11.316,46.459C495.528,308.679,494.92,312.62,493.066,316.156z"/>
			<path fill-rule="evenodd" d="M211.136,664.789c-3.164-2.445-7.09-2.813-10.685-4.319c0.794,0.112,1.589,0.227,2.384,0.335
				c2.542,0.348,4.894,1.308,7.211,2.332c0.627,0.277,1.64,0.548,1.099,1.692c0.117-0.033,0.234-0.066,0.351-0.1
				C211.376,664.749,211.256,664.77,211.136,664.789z"/>
			<path fill-rule="evenodd" d="M213.202,659.043c1.074,0.296,1.812-0.14,2.596-0.897c1.323-1.281,2.331-2.956,4.192-3.619
				c-0.542,1.748-2.209,2.54-3.287,3.834c-0.608,0.729-1.316,1.345-1.042,2.424C214.574,660.582,213.779,659.968,213.202,659.043z"/>
			<path fill-rule="evenodd" d="M215.386,667.479c1.624-0.87,2.132,0.927,3.165,1.417c0.955,0.454,1.648,1.46,2.169,2.543
				c-1.765-1.329-3.53-2.658-5.296-3.987L215.386,667.479z"/>
			<path fill-rule="evenodd" d="M196.146,660.124c-2.005,0-4.011,0-6.016,0c-0.004-0.139-0.009-0.277-0.012-0.416
				c2.01,0,4.021,0,6.032,0C196.148,659.847,196.147,659.985,196.146,660.124z"/>
			<path fill-rule="evenodd" d="M211.145,664.829c0.133-0.192,0.345-0.568,0.386-0.55c1.595,0.686,3.523,0.99,3.86,3.196
				c-0.005,0.004,0.045-0.037,0.04-0.03c-1.423-0.905-2.669-2.091-4.293-2.664C211.136,664.789,211.145,664.829,211.145,664.829z"/>
			<path fill-rule="evenodd" d="M539.594,654.719c0.968,0.562,1.563,0.399,2.18-0.665c1.953-3.369,4.43-6.308,7.656-8.562
				c0.414-0.288,0.78-0.544,1.3-0.362c-1.669,2.371-4.363,3.693-6.03,6.146c-1.225,1.801-2.454,3.599-3.604,5.449
				C540.32,656.261,540.017,655.445,539.594,654.719z"/>
			<path fill-rule="evenodd" d="M567.409,645.181c2.979,0,5.96,0,8.939,0c0.004,0.104,0.008,0.21,0.012,0.315
				c-2.966,0-5.931,0-8.896,0C567.445,645.391,567.428,645.285,567.409,645.181z"/>
			<path fill-rule="evenodd" d="M307.415,644.668c-3.344,0-6.688,0-10.033,0c-0.001-0.124-0.003-0.248-0.005-0.372
				c3.322,0,6.644,0,9.965,0C307.367,644.42,307.392,644.544,307.415,644.668z"/>
			<path fill-rule="evenodd" d="M213.279,531.363c-0.041,0.998-0.948,0.903-1.425,0.846c-5.037-0.606-10.04-0.052-15.052,0.3
				c-0.658,0.046-1.486,0.245-1.858-0.66C201.048,531.385,207.155,531.069,213.279,531.363z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1353)} fill={props.fillColors[1353]} d="M322.175,677.144c-0.212-0.834-0.817-1.384-1.401-1.95
				c-1.004-2.166-3.034-3.271-4.914-4.374c-8.203-4.812-17.022-6.017-26.256-3.888c-7.379,1.701-14.192,4.799-20.6,8.812
				c-0.958,0.601-1.812,1.313-2.569,2.143c-0.906,0.995-1.831,1.144-2.817,0.128c-0.381-0.226-0.762-0.452-1.143-0.679
				c-1.397-0.495-2.118-1.361-0.772-2.52c1.724-1.484,2.959-3.451,4.859-4.739l-0.092,0.076c2.75-2.12,5.505-4.24,8.493-6.015
				c2.056-1.223,4.345-1.861,6.716-2.186c1.348-0.167,2.597-0.905,4.011-0.688c3.122-0.866,6.283-1.448,9.498-0.667
				c1.418,0.344,2.886-0.182,4.257,0.414c0.653,0.495,1.398,0.047,2.084,0.204c1.398,1.017,3.136,0.822,4.693,1.294
				c6.668,2.019,12.146,5.853,16.966,10.755c0.795,0.871,1.589,1.741,2.384,2.612C324.635,676.83,323.551,677.376,322.175,677.144z"/>
			<path fill-rule="evenodd" d="M263.618,678.015c0.917,0.294,1.657,0.458,2.563-0.391c5.331-4.987,12.078-7.365,18.746-9.822
				c4.093-1.508,8.466-2.298,12.742-2.258c8.118,0.075,15.755,2.202,22.146,7.608c0.608,0.514,1.191,1.103,0.959,2.041
				c-6.57-6.319-14.608-8.997-23.518-8.865c-6.027,0.089-11.865,1.658-17.358,4.211c-3.492,1.623-7.187,2.876-10.289,5.267
				c-0.754,0.581-1.582,1.111-2.197,1.819c-0.549,0.631-2.219,1.146-0.73,2.358c0.985,0.802,1.977,1.935,3.386,0.281
				c1.111-1.303,2.436-2.557,4.41-2.479c-0.852,1.432-2.597,1.659-3.727,2.806c-1.103,1.118-1.242,1.992-0.349,3.161
				C268.106,681.88,265.466,680.416,263.618,678.015z"/>
			<path fill-rule="evenodd" d="M266.562,670.077c-0.962,1.845-2.581,3.123-4.065,4.474c-1.178,1.071-1.173,1.846-0.021,2.785
				c-1.529,0.095-2.52-0.685-3.198-1.971c1.3,0.142,1.94-0.837,2.786-1.55C263.552,672.56,264.475,670.61,266.562,670.077z"/>
			<path fill-rule="evenodd" d="M176.077,630.746c-7.535,3.584-13.438,9.072-18.332,15.73c-0.604,0.823-1.106,1.721-1.655,2.584
				c0.14-3.365,2.678-5.386,4.634-7.542c3.774-4.163,7.844-8.089,13.123-10.429C174.579,630.766,175.247,630.313,176.077,630.746z"/>
			<path fill-rule="evenodd" d="M196.961,632.24c-2.575-0.294-4.919-1.454-7.472-1.913c-3.062-0.55-6.126-0.231-9.176-0.515
				c2.558-1.206,5.216-0.798,7.833-0.37c2.38,0.389,4.732,0.969,7.082,1.527C195.938,631.14,196.836,631.203,196.961,632.24z"/>
			<path fill-rule="evenodd" d="M74.076,593.835c-0.07,1.197,0.869,0.925,1.498,0.959c1.169,0.063,2.345,0.019,3.526,0.261
				c-2.784,0.166-5.563,0.252-8.421,0.496c1.514,0.082,1.808-2.078,3.464-1.655C74.145,593.897,74.076,593.835,74.076,593.835z"/>
			<path fill-rule="evenodd" d="M499.603,709.011c-2.956,0.229-5.488-1.308-8.211-2.025c-4.713-1.243-9.442-2.351-14.295-2.85
				c-2.161-0.222-4.223,0.442-6.3,0.907c-0.402,0.09-0.786,0.152-0.714,0.717c0.103,0.798,3.401,3.532,4.335,3.537
				c2.665,0.015,5.33,0.006,7.995,0.006c0.003,0.248,0.006,0.497,0.009,0.746c-1.934,0-3.867-0.006-5.802,0.002
				c-2.627,0.011-2.632,0.021-1.545,2.435c0.196,0.438,0.435,0.858,0.276,1.364c-3.506-4.977-7.445-9.588-11.892-13.738
				c-2.602-2.43-5.395-4.654-8.101-6.971c0.078-0.014,0.197-0.066,0.229-0.034c3.339,3.409,6.19,0.78,9.147-0.714
				c0.624-0.315,1.216-0.782,1.986-0.713c-1.227,1.824-3.492,1.968-5.149,3.143c-1.446,1.024-1.518,1.592-0.111,2.504
				c0.198,0.129,0.359,0.316,0.531,0.482c2.972,2.864,2.992,2.904,6.582,0.993c2.423-1.289,5.064-1.943,7.704-2.072
				c7.231-0.354,14.411,0.094,21.312,2.632c5.293,1.948,9.913,5.208,14.952,7.651c0.46,0.224,1.282,0.433,1.203,1.299
				c-4.34-2.286-8.681-4.569-13.019-6.859c-5.282-2.789-11.036-3.705-16.88-3.946c-4.493-0.185-9.042-0.471-13.403,1.293
				c-1.599,0.647-3.068,1.479-4.604,2.224c0.607,1.213,1.906,1.765,2.402,3.019c0.256,0.646,0.994,0.805,1.661,0.609
				c7.075-2.073,13.896-0.537,20.692,1.42C493.633,706.944,496.773,707.499,499.603,709.011z"/>
			<path fill-rule="evenodd" d="M357.199,658.56c4.368,0.737,7.664,3.361,11.014,6.019c3.276,2.598,5.739,5.735,7.486,9.484
				c0.283,0.606,0.714,1.024,1.283,1.333c-0.677,0.753-1.589,0.724-2.485,0.752c1.332-1.733,0.015-3.048-0.817-4.305
				c-3.846-5.807-9.01-10.017-15.564-12.452C357.653,659.219,357.17,659.213,357.199,658.56z"/>
			<path fill-rule="evenodd" d="M248.397,661.883c-3.851,0-7.702,0-11.554,0c-0.001-0.116-0.002-0.233-0.004-0.35
				c3.851,0,7.702,0,11.554,0C248.395,661.649,248.396,661.767,248.397,661.883z"/>
			<path fill-rule="evenodd" d="M516.363,233.545c-1.326-0.838-1.835-2.264-1.963-3.617c-0.187-1.973-0.826-3.827-1.104-5.761
				l-0.06,0.054c1.266,0.741,1.334,2.054,1.37,3.257c0.066,2.192,1.313,4.009,1.729,6.078
				C516.334,233.556,516.363,233.545,516.363,233.545z"/>
			<path fill-rule="evenodd" d="M507.969,219.863c-2.861-1.065-3.743-3.739-2.066-6.842c-1.555,3.012,0.609,4.831,2.022,6.891
				L507.969,219.863z"/>
			<path fill-rule="evenodd" d="M513.296,224.167c-1.867-1.321-3.792-2.571-5.327-4.304l-0.043,0.048
				c2.406,0.652,4.221,2.034,5.311,4.31L513.296,224.167z"/>
			<path fill-rule="evenodd" d="M275.464,618.567c-0.585-1.35,0.593-1.602,1.339-2.04c0.996-0.585,2.061-1.055,3.587-1.819
				c-1.493,2.146-4.136,1.786-4.949,3.857C275.444,618.566,275.464,618.567,275.464,618.567z"/>
			<path fill-rule="evenodd" d="M293.457,631.22c-4.024,1.529-7.956,3.153-11.58,5.614c-4.559,3.097-8.04,7.019-10.703,11.756
				c-0.355,0.63-0.65,1.271-1.31,1.643c0.322-2.924,2.266-5.062,3.938-7.158c4.53-5.679,10.429-9.515,17.372-11.734
				C291.966,631.087,292.672,630.844,293.457,631.22z"/>
			<path fill-rule="evenodd" d="M326.562,644.243c-0.637-0.011-0.97-0.504-1.338-0.894c-4.667-4.941-9.625-9.493-16.036-12.126
				c3.282-0.036,5.813,1.839,8.153,3.683c3.091,2.436,5.995,5.146,8.659,8.078C326.34,643.358,326.559,643.74,326.562,644.243z"/>
			<path fill-rule="evenodd" d="M309.287,631.276c-1.484-0.155-3.019-0.125-4.444-0.503c-3.429-0.909-6.731-0.282-10.06,0.449
				c-0.458,0.1-0.951,0.039-1.427,0.054c1.746-1.91,4.207-1.168,6.309-1.522c3.102-0.523,6.222,0.367,9.3,0.939
				C309.104,630.719,309.182,631.074,309.287,631.276z"/>
			<path fill-rule="evenodd" d="M128.714,662.387c2.624-1.989,5.616-2.083,8.715-1.634c-2.981,0.076-5.709,1.35-8.622,1.747
				C128.809,662.5,128.714,662.387,128.714,662.387z"/>
			<path fill-rule="evenodd" d="M291.928,676.297c3.581-1.196,7.266-1.113,10.956-0.837c0.932,0.069,1.849,0.344,2.813,0.531
				c-0.319,0.878-1.047,0.606-1.583,0.509c-3.153-0.579-6.287-0.607-9.445,0.004c-0.899,0.174-1.896,0.321-2.774-0.259
				C291.894,676.242,291.928,676.297,291.928,676.297z"/>
			<path fill-rule="evenodd" d="M237.108,580.617c-2.166,0.932-3.624,2.834-5.666,3.89
				C232.994,582.717,234.758,581.225,237.108,580.617z"/>
			<path fill-rule="evenodd" d="M411.431,652.323c2.484,0.764,3.671,3.068,5.438,4.67c0.934,0.846,2.298,1.514,2.329,3.096
				C416.314,657.794,413.763,655.169,411.431,652.323z"/>
			<path fill-rule="evenodd" d="M404.726,647.937c2.895,0.531,5.034,2.211,6.797,4.464
				C409.063,651.208,406.965,649.465,404.726,647.937z"/>
			<path fill-rule="evenodd" d="M392.637,643.695c3.761-0.146,6.907,1.503,9.964,3.411C399.28,645.97,395.958,644.832,392.637,643.695
				z"/>
			<path fill-rule="evenodd" d="M90.329,643.511c1.966-2.029,4.532-1.378,6.79-1.184c3.672,0.315,7.297,1.173,10.945,1.779
				c0.537,0.089,0.854,0.36,1.044,0.836c-3.417-0.099-6.66-1.269-10.062-1.601C96.095,643.054,93.227,642.938,90.329,643.511z"/>
			<path fill-rule="evenodd" d="M111.452,644.894c3.375,0.441,6.466,1.691,9.432,3.302c0.555,0.302,1.104,0.605,1.201,1.321
				c-3.362-1.528-6.619-3.325-10.305-4.035C111.638,645.454,111.56,645.098,111.452,644.894z"/>
			<path fill-rule="evenodd" d="M220.395,578.417c2.74,1.149,4.618,3.16,5.834,5.834C224.425,582.166,222.627,580.073,220.395,578.417
				z"/>
			<path fill-rule="evenodd" d="M438.562,659.09c4.078-0.968,8.17-1.579,12.328-0.477C446.781,658.772,442.672,658.931,438.562,659.09
				z"/>
			<path fill-rule="evenodd" d="M450.769,658.577c3.615,0.012,7.232-0.04,10.781,0.884c0.584,0.152,1.028,0.325,1.323,0.841
				C458.888,659.38,454.734,659.631,450.769,658.577z"/>
			<path fill-rule="evenodd" d="M315.023,592.144c-7.795-4.712-15.728-4.083-23.667-0.483c-2.301,1.043-4.344,2.569-6.029,4.506
				c-0.503,0.579-1.286,1.014-1.013,1.987c-0.593,0.067-1.115-0.076-1.533-0.523c1.356-1.184,2.701-2.378,4.068-3.547
				c6.804-5.818,18.042-7.787,26.611-3.534C314.176,590.903,314.943,591.18,315.023,592.144z"/>
			<path fill-rule="evenodd" d="M346.221,591.196c-3.245-2.181-6.31-4.697-10.355-5.345c-5.959-0.953-11.572-0.092-16.819,2.993
				c-0.759,0.446-0.512,1.068-0.652,1.641c-0.831-0.39-1.675-0.767-1.976-1.754c0.937,0.637,1.587-0.035,2.364-0.435
				c6.565-3.375,13.422-4.419,20.501-1.98C341.98,587.246,344.578,588.622,346.221,591.196z"/>
			<path fill-rule="evenodd" d="M283.956,600.401c-4.709-0.588-9.461-0.325-14.18-0.709c4.721,0.021,9.506-1.177,14.131,0.746
				C283.911,600.436,283.956,600.401,283.956,600.401z"/>
			<path fill-rule="evenodd" d="M280.215,593.843c-0.258,0.173-0.623,0.556-0.758,0.487c-2.65-1.346-5.553-1.273-8.414-1.462
				c3.245-1.188,6.155,0.365,9.175,0.983C280.217,593.852,280.215,593.843,280.215,593.843z"/>
			<path fill-rule="evenodd" d="M129.207,545.76c0.823,0.019,1.293,0.314,0.698,1.134c-0.204,0.28-0.666,0.414-1.036,0.526
				c-1.206,0.363-3.028,0.654-3.083,1.774c-0.068,1.419,1.899,1.141,2.961,1.643c1.281,0.607,1.12,1.166-0.019,1.729
				c0.009-1.378-1.189-1.21-1.988-1.593c-0.766-0.366-1.758-0.666-1.76-1.717c-0.001-0.933,0.975-1.126,1.559-1.635
				C127.371,546.896,129.047,547.396,129.207,545.76z"/>
			<path fill-rule="evenodd" d="M323.187,673.265c-3.254-2.316-6.091-5.191-9.644-7.116c-2.976-1.612-6.065-2.872-9.351-3.713
				c-0.908-0.232-2.14-0.044-2.665-1.22c8.242,1.37,15.343,4.93,21.112,11.026C322.896,672.514,323.008,672.921,323.187,673.265z"/>
			<path fill-rule="evenodd" d="M281.678,661.953c-5.297,1.726-10.037,4.441-14.307,8.008c-0.209,0.174-0.597,0.133-0.902,0.192
				C270.809,666.067,275.293,662.248,281.678,661.953z"/>
			<path fill-rule="evenodd" d="M299.443,661.012c-1.848,0.371-3.638-0.161-5.453-0.329c-2.793-0.259-5.533,0.396-8.301,0.582
				c0.777-1.683,2.427-0.825,3.596-1.051C292.688,659.558,296.124,659.884,299.443,661.012z"/>
			<path fill-rule="evenodd" d="M108.655,661.024c-2.563-0.067-4.765-1.454-7.223-1.963c-3.994-0.826-7.95-0.978-11.914-0.026
				c-0.646,0.154-1.217,0.249-1.837,0.056c4.261-1.966,8.677-1.688,13.093-0.788C103.506,658.859,106.315,659.289,108.655,661.024z"/>
			<path fill-rule="evenodd" d="M423.702,590.938c4.239-2.602,8.536-1.311,12.771-0.072c2.771,0.812,5.453,1.93,8.175,2.91
				c-0.481,1.637-2.675,2.329-2.414,4.377c-0.307-0.05-0.613-0.1-0.92-0.148c1.022-2.386-1.28-2.431-2.66-3.431
				c1.203-0.342,1.772,0.471,2.501,0.673c0.589,0.163,1.236,0.705,1.722-0.017c0.554-0.825-0.249-1.297-0.704-1.8
				c-0.201-0.223-0.524-0.382-0.822-0.468c-5.534-1.585-10.899-4.563-16.938-2.091C424.201,590.957,423.939,590.918,423.702,590.938z"
				/>
			<path fill-rule="evenodd" d="M426.027,600.795c4.417-1.136,8.893-1.107,13.391-0.613c-4.456,0.375-8.946,0.087-13.395,0.617
				C426.022,600.799,426.027,600.795,426.027,600.795z"/>
			<path fill-rule="evenodd" d="M168.08,676.875c0.007-0.754,0.559-1.158,1.101-1.438c7.743-3.996,15.678-6.695,24.69-5.396
				c5.644,0.813,10.503,3.242,15.459,5.657c0.612,0.299,1.11,0.635,1.053,1.411c-3.208-1.144-6.047-3.052-9.224-4.307
				c-8.588-3.394-17.011-2.896-25.469,0.39C173.045,674.22,170.644,675.706,168.08,676.875z"/>
			<path fill-rule="evenodd" d="M229.521,643.92c1.745-1.51,4.005-1.457,6.068-1.899c7.965-1.71,15.632-0.533,23.045,2.676
				c-2.191,0.234-4.183-0.745-6.169-1.307c-6.941-1.965-13.776-1.578-20.624,0.207C231.058,643.801,230.313,643.942,229.521,643.92z"
				/>
			<path fill-rule="evenodd" d="M98.472,578.458c4.988-4.187,14.364-5.91,19.771-2.401c-2.266-0.068-4.331-1.097-6.645-1.172
				c-4.272-0.14-8.164,0.731-11.673,3.173C99.458,578.382,99.1,578.836,98.472,578.458z"/>
			<path fill-rule="evenodd" d="M109.629,585.188c-2.769,0.353-5.486-1.044-8.269-0.248c2.374-0.621,4.734-1.289,7.129-1.981
				c-0.706,0.318-1.412,0.637-2.172,0.979C107.059,585.422,108.701,584.386,109.629,585.188z"/>
			<path fill-rule="evenodd" d="M94.654,582.551c0.8-1.856,2.025-3.312,3.901-4.158C97.725,580.219,96.072,581.274,94.654,582.551z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1354)} fill={props.fillColors[1354]} d="M464.513,677.924c1.108,0.689,1.338,1.357-0.087,1.911
				c-2.647,1.028-5.343,1.959-7.752,3.498c-1.161,0.742-2.251-0.018-3.182-0.27c-3.175-0.861-6.139-0.188-9.032,0.852
				c-1.166,0.419-2.034,0.488-3.056-0.117c-0.747-0.442-1.547-0.8-2.336-1.17c-1.133-0.531-2.812-0.751-3.286-1.629
				c-0.663-1.223,1.564-1.702,1.758-2.992c1.635-0.542,3.271-1.084,4.906-1.627c5.931-1.223,11.863-0.839,17.795-0.009
				C461.707,676.773,463.291,676.849,464.513,677.924z"/>
			<path fill-rule="evenodd" d="M460.241,676.371c-2.555,0.591-5.02-0.55-7.582-0.348c-3.393,0.267-6.808,0.251-10.213,0.356
				c4.393-1.616,8.948-1.176,13.432-0.797C457.288,675.703,458.965,675.219,460.241,676.371z"/>
			<path fill-rule="evenodd" d="M329.354,661.028c2.729-1.968,6.02-2.596,9.147-3.239c3.893-0.799,7.912-1.434,11.937-1.069
				c2.37,0.214,4.739,0.656,6.854,1.896c-0.384,0.066-0.82,0.288-1.142,0.177c-7.654-2.656-15.079-0.946-22.488,1.13
				C332.233,660.322,330.924,661.181,329.354,661.028z"/>
			<path fill-rule="evenodd" d="M362.796,666.297c-2.493-0.64-4.606-1.972-6.552-3.608
				C358.704,663.391,360.902,664.568,362.796,666.297z"/>
			<path fill-rule="evenodd" d="M355.11,662.461c-2.972-0.42-5.95-0.609-8.942-0.239C347.998,660.804,352.926,660.936,355.11,662.461z
				"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1355)} fill={props.fillColors[1355]} d="M506.196,591.812c-1.035,0.271-1.901,0.218-3.046-0.158
				c-7.117-2.337-14.33-2.562-21.448,0.077c-1.097,0.406-1.969,0.253-2.944-0.062c-0.313-0.395-0.626-0.788-0.938-1.182
				c0.438-0.405,0.877-0.81,1.314-1.215c2.699-2.191,5.986-2.82,9.269-3.168c5.825-0.618,11.574-0.294,16.905,2.554
				c0.699,0.374,1.332,0.852,1.964,1.328c0.084,0.148,0.169,0.297,0.253,0.445C507.082,590.892,506.639,591.353,506.196,591.812z"/>
			<path fill-rule="evenodd" d="M507.272,589.986c-4.625-2.793-9.7-3.948-15.038-3.967c-4.619-0.017-9.018,1.022-13.1,3.253
				c2.182-2.575,5.393-3.034,8.323-3.294c5.646-0.5,11.401-0.747,16.751,1.862C505.329,588.388,506.631,588.732,507.272,589.986z"/>
			<path fill-rule="evenodd" d="M185.023,613.441c2.331-1.295,4.167-1.057,6.56-0.06C189.201,613.74,187.349,613.578,185.023,613.441z
				"/>
			<path fill-rule="evenodd" d="M462.277,195.478c4.463,1.137,6.529,4.721,8.539,8.362c1.136,2.059,2.699,3.19,5.201,2.318
				c-2.471,1.449-4.614,0.905-6.159-2.285c-1.688-3.487-3.543-6.488-7.522-7.569c-0.089-0.024-0.043-0.546-0.059-0.836
				c-0.13,0.026-0.26,0.053-0.39,0.08C462.017,195.525,462.146,195.502,462.277,195.478z"/>
			<path fill-rule="evenodd" d="M472.391,199.164c1.717,0.401,1.712,1.805,1.742,3.119c0.021,0.863-0.495,1.508-1.354,1.664
				c-0.929,0.168-1.146-0.515-1.327-1.271c-0.232-0.969-1.116-1.778-0.854-2.862c0.204-0.842,0.381-2.139,1.609-0.663
				C472.248,199.427,472.31,199.438,472.391,199.164z"/>
			<path fill-rule="evenodd" d="M462.277,195.468c-1.465,1.017-2.499,0.068-3.246-0.947c-0.895-1.215-1.661-2.893-0.782-4.186
				c1.091-1.604,2.142-0.298,3.172,0.879c-1.005,0.139-1.893-1.455-2.468-0.268c-0.577,1.191-0.236,2.581,0.754,3.518
				c0.66,0.625,1.514,1.188,2.569,1.018C462.277,195.478,462.277,195.468,462.277,195.468z"/>
			<path fill-rule="evenodd" d="M469.82,195.109c-2.448,0.33-6.246-1.193-8.008-3.344C464.636,193.447,467.085,194.636,469.82,195.109
				z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1356)} fill={props.fillColors[1356]} d="M472.391,199.164c0.203,0.414,0.432,0.817,0.603,1.244
				c0.344,0.856,0.816,1.773-0.002,2.57c-0.299,0.291-0.898-0.08-0.91-0.424c-0.039-1.131-1.891-2.359,0.125-3.402L472.391,199.164z"
				/>
			<path fill-rule="evenodd" d="M72.604,584.74c-3.967,0.729-6.75,3.648-10.361,5.742C64.469,587.826,69.064,585.252,72.604,584.74z"
				/>
			<path fill-rule="evenodd" d="M93.668,595.746c6.586-3.872,13.465-6.142,21.174-3.862c-2.166,0.298-4.28-0.345-6.468-0.276
				c-4.718,0.146-8.96,1.667-13.134,3.616C94.743,595.456,94.193,595.574,93.668,595.746z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1357)} fill={props.fillColors[1357]} d="M489.445,229.463c-0.539-0.225-1.078-0.45-1.617-0.675
				c-1.92-1.171-2.848-2.852-2.811-5.128c0.012-0.684-0.109-1.41-0.456-2.078c-1.897-3.674-0.677-6.867,1.588-9.883
				c0.469-1.052,1.312-1.799,2.105-2.587c1.432-1.403,2.529-0.843,3.441,0.61c0.59,0.94,0.966,1.989,1.408,3.003l-0.045-0.094
				c0.979,2.741,2.456,5.294,3.064,8.176c0.484,2.296,0.593,4.648,1.224,6.934c0.502,1.82-0.951,3.063-1.892,4.407
				c-0.062,0.064-0.122,0.128-0.182,0.193c-1.476,0.779-2.615,0.631-3.498-0.98C491.294,230.48,490.462,229.803,489.445,229.463z"/>
			<path fill-rule="evenodd" d="M495.456,232.149c2.973-5.126,0.746-9.994-0.812-14.861c-0.498-1.558-1.462-2.963-1.585-4.656
				c1.961,3.49,3.69,7.05,3.871,11.169c0.037,0.841,0.347,1.679,0.591,2.5C498.304,228.929,497.766,230.483,495.456,232.149z"/>
			<path fill-rule="evenodd" d="M486.149,211.699c-3.581,6.103-1.905,11.689,1.679,17.089c-1.747-0.652-2.933-1.633-2.899-3.767
				c0.024-1.563-0.6-3.038-1.226-4.502C482.543,217.805,483.888,213.248,486.149,211.699z"/>
			<path fill-rule="evenodd" d="M493.104,212.726c-0.488-0.793-1.047-1.553-1.448-2.388c-0.753-1.564-1.801-2.109-3.401-1.226
				c1.104-1.516,2.361-1.69,3.276-0.292C492.298,209.99,493.088,211.221,493.104,212.726z"/>
			<path fill-rule="evenodd" d="M489.445,229.463c1.126-0.081,2.042,0.36,2.44,1.394c0.657,1.707,1.897,1.818,3.389,1.485
				c-1.289,1.294-2.459,1.764-3.343-0.408C491.459,230.772,490.232,230.33,489.445,229.463z"/>
			<path fill-rule="evenodd" d="M152.967,486.266c1.341-0.126,2.266,0.409,2.886,1.813c-0.849,0.089-1.536,0.161-2.284,0.239
				c3.737,2.493,12.021,0.933,13.805-2.545c1.215,0.319,0.877,0.909,0.214,1.462c-3.588,2.991-10.652,3.79-14.378,1.952
				c-0.513-0.253-1.146-0.528-1.148-1.082c-0.003-0.701,0.809-0.524,1.242-0.769C154.379,486.728,152.86,486.709,152.967,486.266z"/>
			<path fill-rule="evenodd" d="M338.562,564.965c-1.336,2.756-3.816,4.282-6.27,5.838c-1.098-0.53-0.271-1.21,0.104-1.319
				c2.416-0.705,3.604-2.824,5.295-4.361C337.877,564.954,338.268,565.012,338.562,564.965z"/>
			<path fill-rule="evenodd" d="M341.828,583.683c2.287,0.896,4.349,2.143,6.084,3.899
				C345.522,586.847,343.678,585.262,341.828,583.683z"/>
			<path fill-rule="evenodd" d="M460.009,626.413c-0.268,0.695-1.056,0.759-1.503,1.237c-1.031-1.729-2.711-0.753-4.096-0.933
				c-0.905-0.118-1.85,0.075-2.784-0.024C454.429,626.756,457.223,626.661,460.009,626.413z"/>
			<path fill-rule="evenodd" d="M145.453,584.929c-3.825-1.203-7.749-1.441-12.048-1.169
				C136.416,582.595,141.928,583.161,145.453,584.929z"/>
			<path fill-rule="evenodd" d="M130.736,599.104c2.923,0,5.845,0,8.768,0c0.001,0.095,0.002,0.188,0.002,0.283
				c-2.922,0-5.845,0-8.767,0C130.739,599.293,130.738,599.199,130.736,599.104z"/>
			<path fill-rule="evenodd" d="M148.482,645.186c-4.687,0.047-9.366,0.207-14.003,0.97c1.536-1.668,3.739-1.22,5.607-1.528
				c2.463-0.407,5.044-0.106,7.575-0.104C148.101,644.523,148.417,644.708,148.482,645.186z"/>
			<path fill-rule="evenodd" d="M134.573,646.1c-1.785,1.157-3.766,1.733-5.86,1.977C130.392,646.604,132.406,646.125,134.573,646.1z"
				/>
			<path fill-rule="evenodd" d="M482.979,612.312c0.079-1.843,1.624-2.438,2.854-3.232c1.645-1.064,3.342-2.074,5.353-2.327
				C488.137,608.145,485.238,609.758,482.979,612.312z"/>
			<path fill-rule="evenodd" d="M503.489,243.042c0.011-0.053,0.019,0.545-0.233,0.997c-0.385,0.69-0.37,1.925-1.557,1.769
				c-1.053-0.139-0.837-1.14-0.921-1.901c-0.042-0.382-0.248-0.746-0.308-1.129c-0.155-1.007-0.385-2.021,0.63-2.802
				c0.497-0.383,1.079-0.562,1.527-0.275C503.609,240.33,503.571,241.41,503.489,243.042z"/>
			<path fill-rule="evenodd" d="M434.018,192.05c0.07-0.576-0.404-0.804-0.722-1.132c-0.849-0.875-0.625-1.783-0.253-2.819
				c0.411-1.146,1.216-1.256,2.216-1.294c2.099-0.08,3.37,1.244,4.355,2.757c0.48,0.739,0.723,2.012-1.031,1.939
				c-1.566-0.064-3.051,0.76-4.647,0.478C433.934,191.978,434.018,192.05,434.018,192.05z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1358)} fill={props.fillColors[1358]} d="M563.696,279.5c0.101,1.881-1.238,2.72-2.633,2.749
				c-1.624,0.033-2.902-1.279-2.907-2.875c-0.004-1.413,0.832-2.74,2.711-2.646C562.664,276.817,563.6,277.727,563.696,279.5z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1359)} fill={props.fillColors[1359]} d="M538.642,765.177c-1.637,0.201-3.05-0.7-4.585-1.007
				c-4.663-0.933-9.021-0.133-13.079,2.027c-2.024,1.076-4.157,1.038-6.2,1.268c-2.26,0.253-4.551,0.561-6.847,0.629
				c-0.645,0.019-1.282,0.256-1.692-0.435c-0.487-0.821,0.321-1.148,0.715-1.528c2.75-2.655,6.244-4.028,9.78-5.085
				c7.177-2.146,14.451-1.837,21.655-0.014c3.109,0.787,3.099,1.012,0.843,3.255c-0.274,0.272-0.45,0.645-0.673,0.972
				C538.557,765.261,538.642,765.177,538.642,765.177z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1360)} fill={props.fillColors[1360]} d="M521.012,767.039c4.065-2.644,8.054-2.646,12.071-2.266
				c0.671,0.063,1.358,0.334,2.075,0.335c0.812,0.001,1.89,0.242,1.877,0.987c-0.019,1.004-0.997,1.501-2.079,1.496
				c-2.072-0.01-4.182,0.248-6.207-0.058C526.355,767.172,523.942,767.237,521.012,767.039z"/>
			<path fill-rule="evenodd" d="M19.768,433.503c0.566-1.076,1.56-0.556,2.244-0.4c1.391,0.315,2.714,0.93,4.064,1.421
				c-1.39,0.387-2.558-0.354-3.822-0.681C21.465,433.639,20.672,432.993,19.768,433.503z"/>
			<path fill-rule="evenodd" d="M466.625,691.744c5.533-3.967,11.604-4.95,18.311-3.958c4.05,0.6,8.064,1.159,11.971,2.396
				c0.451,0.143,0.944,0.159,1.255,0.588c-2.605,0.284-4.883-1.054-7.346-1.494c-6.202-1.111-12.358-1.871-18.532,0.252
				C470.358,690.189,468.8,691.758,466.625,691.744z"/>
			<path fill-rule="evenodd" d="M504.327,693.145c5.426,1.006,10.245,3.383,14.637,6.638c1.601,1.188,3.564,2.15,3.915,4.472
				c-4.802-5.298-11.11-8.065-17.628-10.474C504.86,693.637,504.523,693.522,504.327,693.145z"/>
			<path fill-rule="evenodd" d="M557.41,735.877c-6.044-4.498-13.061-7.052-20.008-9.743c0.078-0.195,0.156-0.391,0.233-0.587
				c2.832,1.077,5.704,2.056,8.483,3.254c3.413,1.47,6.743,3.13,9.885,5.137c0.736,0.471,1.576,0.859,1.358,1.98L557.41,735.877z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1361)} fill={props.fillColors[1361]} d="M138.261,530.183c-0.936,0.353-2.128-0.081-2.851,0.931
				c0.267,0.959,1.198,0.908,1.882,1.256c1.471,0.746,1.767,2.226,0.549,3.315c-1.359,1.217-0.654,1.867,0.492,2.441
				c2.372,1.188,5.007,1.471,7.563,1.787c2.023,0.252,3.953,0.668,5.461,1.914c0.95,0.784,2.312-0.032,3.079,1.317
				c-2.425,0.978-4.886,1.574-7.426,1.688c-2.621,0.115-5.151,0.329-7.683,1.251c-2.033,0.741-4.188-0.218-6.23-0.783
				c-1.091-0.303-1.103-1.269-1.016-2.163c0.2-2.049-0.133-3.772-2.305-4.68c-0.443-0.186-1.029-0.627-0.894-1.28
				c0.144-0.696,0.92-0.562,1.386-0.732c3.661-1.333,3.718-1.427,1.953-4.9c-0.36-0.709-0.879-1.4-0.997-2.151
				c-0.113-0.723-1.127-1.511-0.278-2.151c0.691-0.521,1.708-0.354,2.535-0.037c1.772,0.679,3.224,1.886,4.73,3.01
				C138.228,530.206,138.261,530.183,138.261,530.183z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1362)} fill={props.fillColors[1362]} d="M174.296,486.54c-0.723,3.045-2.927,3.521-5.697,3.435
				C170.096,488.062,171.352,486.1,174.296,486.54z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1363)} fill={props.fillColors[1363]} d="M373.085,602.951c-1.554,1.479-3.038,1.948-4.642,0.442
				c-0.253-0.238-0.817-0.414-0.602-0.847c0.153-0.306,0.6-0.574,0.959-0.646C370.362,601.584,371.647,602.363,373.085,602.951z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1364)} fill={props.fillColors[1364]} d="M536.967,233.753c0.239,2.146,0.089,4.218,1.838,5.806
				c0.574,0.521,0.399,1.302-0.104,1.943c-0.591,0.753-1.171,0.224-1.655-0.058c-1.503-0.874-3.021-1.283-4.712-0.581
				c-0.542,0.225-1.2,0.235-1.486-0.328c-0.463-0.907,0.549-0.864,0.939-1.237C533.59,237.575,536.493,236.885,536.967,233.753z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1365)} fill={props.fillColors[1365]} d="M454.664,684.229c-0.992,0.65-1.734,1.076-2.411,1.591
				c-3.273,2.485-4.368,2.506-7.304,0.017c-0.648-0.55-0.996-0.955,0.233-1.425C447.916,683.366,451.341,683.167,454.664,684.229z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1366)} fill={props.fillColors[1366]} d="M542.196,325.033c-0.271,0.962-0.837,1.557-1.837,1.577
				c-1.259,0.024-1.644-0.981-1.689-1.891c-0.042-0.831,0.601-1.386,1.588-1.342C541.377,323.428,541.98,323.99,542.196,325.033z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1367)} fill={props.fillColors[1367]} d="M521.293,335.08c-0.155-1.06,0.254-1.542,1.426-1.512
				c1.244,0.033,1.921,0.364,1.854,1.787c-0.051,1.089-0.434,1.58-1.523,1.643C521.655,337.079,521.219,336.354,521.293,335.08z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1368)} fill={props.fillColors[1368]} d="M504.986,347.133c-0.79-0.006-1.387-0.286-1.391-1.178
				c-0.004-0.711,0.394-1.225,1.126-1.191c0.817,0.038,1.191,0.601,1.242,1.411C506.01,346.897,505.64,347.143,504.986,347.133z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1369)} fill={props.fillColors[1369]} d="M431.911,702.681c-3.717,0.568-6.839,1.563-10.187,0.086
				c-4.284-1.893-8.936-2.566-13.551-2.727c-4.19-0.146-8.41-0.371-12.636,0.261c-1.688,0.252-2.812-1.738-4.46-2.726
				c1.447-0.999,2.954-1.382,4.345-1.434C407.859,695.681,419.904,697.577,431.911,702.681z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1370)} fill={props.fillColors[1370]} d="M395.496,701.723c0.381-0.504,0.723-0.939,1.503-0.943
				c8.002-0.04,16.036-0.459,23.755,2.349c0.66,0.239,1.32,0.478,2.383,0.862c-3.673,1.103-6.505,3.504-10.531,1.486
				c-3.196-1.601-6.983-1.504-10.606-1.247c-1.788,0.126-3.511,0.082-4.714-1.603c-0.423-0.592-1.062-0.964-1.854-0.951
				C395.425,701.672,395.496,701.723,395.496,701.723z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1371)} fill={props.fillColors[1371]} d="M415.102,707.115c-2.151,1.696-4,2.728-6.511,1.591
				c-1.816-0.822-3.839-0.975-5.815-0.744c-1.732,0.203-2.15-1.089-2.713-2.161c-0.474-0.904,0.431-1.113,0.979-1.143
				C405.814,704.405,410.387,705.278,415.102,707.115z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1372)} fill={props.fillColors[1372]} d="M404.127,708.813c1.791-0.329,2.971,0.003,4.027,0.489
				c0.682,0.314,2.453-0.003,1.787,1.422c-0.493,1.053-1.175,3.087-3.292,1.637c-0.693-0.476-1.58-0.671-2.383-0.982
				c-1.011-0.394-2.511-0.649-2.244-1.969C402.274,708.158,403.781,708.956,404.127,708.813z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1373)} fill={props.fillColors[1373]} d="M177.574,543.529c0.285,2.627-0.544,3.93-2.261,3.604
				c-0.356-0.067-0.797-0.265-0.977-0.544c-0.239-0.374,0.135-0.707,0.425-0.975c0.058-0.054,0.111-0.113,0.174-0.159
				C175.804,544.82,176.673,544.187,177.574,543.529z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1374)} fill={props.fillColors[1374]} d="M174.35,539.001c1.062-0.404,2.051-1.595,3.189-0.361
				c0.324,0.353,0.286,0.828-0.085,1.015C176.31,540.231,175.336,539.602,174.35,539.001z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1375)} fill={props.fillColors[1375]} d="M543.106,300.479c1.315,0.111,2.365,0.812,2.335,2.438
				c-0.027,1.499-0.917,2.643-2.386,2.743c-1.732,0.119-2.578-1.327-2.533-2.756C540.559,301.741,541.275,300.473,543.106,300.479z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1376)} fill={props.fillColors[1376]} d="M529.187,314.098c-0.027,1.484-0.689,2.256-2.001,2.293
				c-1.562,0.044-2.228-0.768-2.193-2.362c0.029-1.408,0.521-2.392,1.979-2.335C528.466,311.751,529.089,312.841,529.187,314.098z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1377)} fill={props.fillColors[1377]} d="M536.729,309.35c0.223,1.509-0.806,2.173-2.058,2.284
				c-1.065,0.094-1.734-0.717-1.769-1.825c-0.044-1.424,0.614-2.235,2.128-2.255C536.237,307.538,536.812,308.074,536.729,309.35z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1378)} fill={props.fillColors[1378]} d="M518.117,323.148c-0.774-1.097-1.563-2.131-0.755-3.627
				c0.686-1.266,1.658-2.15,2.965-1.486c1.418,0.72,1.405,2.229,0.69,3.516c-0.61,1.099-1.656,1.679-2.991,1.515
				C518.027,323.066,518.117,323.148,518.117,323.148z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1379)} fill={props.fillColors[1379]} d="M553.439,288.558c-0.05-1.688,0.738-2.72,2.424-2.958
				c1.504-0.213,1.971,0.873,2.146,2.017c0.189,1.236-1.557,3.215-2.69,3.229C554.242,290.86,553.474,289.925,553.439,288.558z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1380)} fill={props.fillColors[1380]} d="M551.838,292.43c0.171,1.088,0.808,2.019,0.843,3.191
				c0.048,1.62-0.786,2.373-2.146,2.571c-1.361,0.198-3.404-1.893-3.097-3.258c0.295-1.312,0.984-2.451,2.75-2.445
				c0.54,0.002,1.176,1.224,1.675-0.017C551.853,292.458,551.838,292.43,551.838,292.43z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1381)} fill={props.fillColors[1381]} d="M513.312,325.792c0.099,1.351-0.82,1.624-1.785,1.724
				c-1.102,0.115-1.526-0.537-1.539-1.575c-0.015-1.206,0.602-1.688,1.748-1.663C512.775,324.3,513.36,324.748,513.312,325.792z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1382)} fill={props.fillColors[1382]} d="M381.766,672.569c-0.173-2.3,1.778-2.57,3.17-2.835
				c4.89-0.931,9.804-1.884,14.831-1.601c3.634,0.204,6.874,1.533,9.799,3.642c0.479,0.346,1.221,0.829,1.019,1.487
				c-0.269,0.871-1.018,0.315-1.612,0.246c-6.509-0.759-13.048-0.501-19.575-0.46c-1.643,0.011-3.391,0.143-4.923,0.558
				c-1.537,0.416-2.077-0.216-2.782-1.127L381.766,672.569z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1383)} fill={props.fillColors[1383]} d="M490.705,215.347c1.6,1.794,2.245,4.101,3.324,6.175
				c0.301,1.469,0.771,2.894,1.087,4.363c0.192,0.9-0.179,1.549-0.521,2.241c-0.404,0.818-1.153,1.024-1.711,0.316
				c-2.123-2.693-5.025-4.859-5.721-8.54c-0.263-1.391-0.288-2.705,0.478-3.893C488.396,214.839,489.378,214.144,490.705,215.347z"/>
			<path fill-rule="evenodd" d="M490.705,215.347c-2.323-0.29-3.639,0.809-3.463,3.175c0.199,2.681,1.419,5.006,3.355,6.829
				c0.754,0.709,1.588,1.28,2.057,2.245c0.229,0.471,0.425,1.154,1.188,0.844c0.555-0.226,0.885-0.723,0.876-1.327
				c-0.016-1.102,0.248-2.242-0.327-3.285c-0.402-0.729-0.572-1.485-0.361-2.306c1.038,2.141,2.103,4.263,0.973,6.733
				c-0.48,1.048-1.323,1.797-2.137,0.795c-2.521-3.104-5.813-5.767-6.266-10.178c-0.187-1.829,1.138-4.403,2.511-4.506
				C489.829,214.312,490.601,214.33,490.705,215.347z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1384)} fill={props.fillColors[1384]} d="M501.793,245.008c-0.992-1.319-0.506-2.627-0.567-3.854
				c-0.023-0.472,0.275-0.727,0.761-0.673c0.46,0.052,0.738,0.361,0.728,0.795C502.682,242.519,503.084,243.865,501.793,245.008z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1385)} fill={props.fillColors[1385]} d="M435.533,191.109c-1.103,0.095-1.938-0.195-2.011-1.747
				c-0.068-1.415,0.93-1.785,1.834-2.059c1.024-0.31,3.925,2.08,3.849,2.979C439.168,190.73,437.784,191.055,435.533,191.109z"/>
			<path fill-rule="evenodd" onClick={() => props.onFill(1386)} fill={props.fillColors[1386]} d="M492.101,221.454c-0.088,0.813-0.04,1.998-1.015,1.853
				c-1.688-0.25-1.497-1.96-1.745-3.19c-0.146-0.725,0.171-1.58,1.108-1.401C491.927,218.996,491.953,220.321,492.101,221.454z"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M15.96,312.28c2.332,2.885,5.562,4.697,8.425,6.929c15.61,12.174,29.816,25.721,41.498,41.789
				c6.781,9.326,12.739,19.186,18.143,29.333c3.863,7.254,7.6,14.694,9.993,22.565c1.017,3.346,2.982,3.704,5.461,4.265"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M106.44,270.76c-1.201,0.019-2.126-0.433-3.161-1.141c-3.039-2.081-6.212-3.97-9.068-6.312c-0.608-0.5-1.243-0.893-1.912-0.438
				c-0.719,0.488-0.714,1.473-0.458,2.115c0.973,2.432,1.578,5.009,2.503,7.401c1.264,3.269,1.862,6.752,3.432,9.907
				c0.749,1.503,1.598,2.596,3.068,3.69c13.656,10.165,28.882,17.494,43.956,25.176c14.721,7.502,29.43,15.125,42.97,24.685
				c8.349,5.895,16.729,11.726,24.612,18.315c8.382,7.007,16.395,14.369,23.569,22.52c9.584,10.889,18.18,22.568,25.087,35.421
				c3.053,5.683,5.952,11.427,8.586,17.308c0.193,0.43,0.175,0.954,0.255,1.434"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M412.2,179.8c-0.801,0.24-1.6,0.48-2.4,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M404.76,182.56c-0.399,0.12-0.8,0.24-1.2,0.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M403.32,183.16c-1.2,0.399-2.4,0.8-3.601,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M394.44,190.84c-0.677,3.09-0.303,6.264-1.276,9.411c-0.717,2.319-0.331,5.071-0.608,7.633c-0.744,6.869-1.512,13.746-1.884,20.64
				c-0.232,4.291-1.451,8.509-1.361,12.713c0.114,5.357-0.676,10.579-1.183,15.836c-0.426,4.414-1.116,8.82-1.754,13.22
				c-0.794,5.482-1.971,10.875-2.919,16.316c-1.229,7.058-3.197,13.931-4.944,20.848c-1.585,6.279-2.691,12.613-3.808,18.973
				c-0.882,5.023-0.911,10.127-1.828,15.136c-0.168,0.916-0.228,1.943,0.205,2.874"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M417.48,198.76c-1.72,1.655-1.501,3.996-1.841,6.014c-1.167,6.925-2.127,13.892-2.92,20.871
				c-0.671,5.898-0.958,11.839-1.491,17.754c-0.591,6.564-1.391,13.112-1.846,19.685c-0.172,2.478-0.321,4.959-0.539,7.437
				c-0.516,5.854-1.194,11.689-1.825,17.53c-0.458,4.245-1.029,8.477-1.595,12.702c-0.636,4.753-1.668,9.455-2.595,14.165
				c-0.779,3.962-1.525,7.937-2.869,11.763"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M86.52,352.84c3.318,0.01,5.639,2.281,8.117,3.905c9.21,6.038,17.62,13.176,25.407,20.891
				c14.057,13.927,26.032,29.497,35.157,47.182c4.963,9.62,9.231,19.444,11.767,30.01c1.012,4.216,1.688,8.465,2.468,12.71
				c0.354,1.927,0.298,3.87,0.73,5.808c0.75,3.356,0.293,6.694-3.256,8.597c-0.87,0.466-1.522,1.046-1.911,1.938"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M406.2,182.92c0.146,0.891,0.896,1.331,1.442,1.918c3.007,3.231,3.156,3.266,2.104,7.429c-0.646,2.557-0.519,5.145-0.821,7.703
				c-0.664,5.597-1.274,11.201-1.908,16.801c-0.62,5.482-1.613,10.88-2.445,16.317c-1.235,8.076-3.379,15.914-5.568,23.753
				c-1.848,6.62-3.09,13.356-4.126,20.152c-1.337,8.767-2.095,17.582-2.862,26.404c-0.328,3.773-0.966,7.519-1.389,11.285
				c-0.087,0.768-0.692,0.442-0.986,0.717"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M563.88,734.68c1.749,0.246,3.333-0.619,5.036-0.763c1.021-0.086,1.011-0.939,0.474-1.629c-1.405-1.803-3.335-2.932-5.257-4.108
				c-5.474-3.353-10.625-7.398-16.832-9.276c-9.442-2.857-19.151-3.826-29.022-2.958c-4.739,0.417-9.523,0.438-14.156,1.703
				c-2.125,0.579-4.282,1.116-6.002,2.632"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,696.76c-4.379-2.205-9.217-2.7-13.931-3.281c-11.875-1.464-23.597-0.395-35.028,3.285
				c-6.323,2.036-11.743,5.643-16.789,9.852c-1.444,1.205-3.472,1.982-3.612,4.305"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M96.6,373.24c2.845,0.031,4.899,1.94,6.902,3.437c7.41,5.537,14.626,11.357,20.921,18.178
				c11.169,12.102,20.274,25.549,26.177,41.024c3.799,3.986,2.564,9.594,4.56,14.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M278.04,339.4c1.92,3.6,3.747,7.253,5.778,10.79c5.564,9.692,12.845,17.939,21.043,25.514c5.631,5.203,11.527,10.08,17.896,14.218
				c6.552,4.258,13.111,8.681,20.558,11.412c0.834,0.306,1.604,0.787,2.404,1.187"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M381,188.68c0.202,1.667-0.391,2.958-1.393,4.354c-5.848,8.152-12.604,15.599-19.634,22.62
				c-9.752,9.74-17.131,20.792-22.661,33.263c-2.765,6.235-4.374,12.801-5.759,19.441c-1.251,6.006-2.708,11.974-3.785,18.01
				c-0.878,4.917-1.344,9.909-1.964,14.872c-0.659,5.278-1.547,10.545-1.845,15.844c-0.252,4.491-0.965,8.938-1.038,13.436
				c-0.019,1.093,0.151,2.226-0.722,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M583.08,683.8c-1.634,0.6-3.201-0.16-4.801-0.225c-9.771-0.395-19.428,0.308-28.797,3.354c-6.703,2.179-12.694,5.562-17.516,10.798
				c-2.916,3.166-5.782,6.38-8.639,9.601c-0.958,1.082-1.735,2.287-1.688,3.833"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M451.56,218.68c-3.08,2.355-4.475,5.858-6.248,9.115c-5.838,10.717-9.044,22.371-11.921,34.102
				c-1.369,5.58-1.957,11.349-2.962,17.021c-0.691,3.897-1.053,7.829-1.502,11.753c-0.074,0.652,0.076,0.995,0.313,1.449"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M392.52,200.92c-3.394,2.815-5.725,6.506-8.137,10.096c-6.861,10.214-11.366,21.46-14.447,33.338
				c-1.955,7.532-3.327,15.174-4.789,22.804c-0.31,1.617-0.787,3.203-1.187,4.802"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M388.44,242.44c-3.975,7.124-6.643,14.708-7.648,22.804c-0.724,5.82-1.007,11.695-1.755,17.511
				c-0.673,5.227-2.526,10.17-4.263,15.13c-3.218,9.191-7.838,17.788-11.29,26.876c-0.907,2.39-1.855,4.771-2.594,7.214
				c-0.253,0.834-0.65,1.39-1.251,1.905"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M480.36,674.92c-3-0.895-5.285-3.051-7.879-4.628c-6.837-4.16-14.241-6.645-22.115-6.273c-9.231,0.436-18.672,0.792-26.891,6.096
				c-2.264,1.46-4.595,2.822-6.235,5.046"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M418.92,191.32c-0.265,1.532-1.066,2.956-0.956,4.56c0.227,3.281,0.4,6.568,0.731,9.839c0.818,8.089,2.67,16.007,4.051,24.003
				c1.304,7.55,1.697,15.184,0.909,22.792c-0.607,5.855-1.436,11.699-2.251,17.538c-0.935,6.682-2.294,13.274-3.393,19.917
				c-1.246,7.528-3.571,14.801-4.935,22.297c-0.166,0.911-0.349,1.615,0.083,2.415"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M540.12,660.76c-5.924-6.369-12.514-11.43-21.593-12.538c-8.267-1.009-15.701,0.913-22.553,5.36
				c-5.943,3.858-12.052,7.533-16.814,12.938"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M284.52,334.12c3.389,9.062,8.196,17.317,14.417,24.706c11.21,13.315,24.744,23.77,40.079,31.905
				c4.555,2.416,9.255,4.573,14.145,6.269"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M594.84,677.56c-1.859,1.11-2.892,1.393-4.48-1.098c-2.791-4.376-7.732-6.185-12.558-7.786c-9.99-3.313-19.514-1.287-28.786,2.682
				c-5.165,2.211-10.462,4.312-14.416,8.603"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M515.4,691.24c-7.568-3.785-15.572-5.873-23.992-6.798c-5.779-0.634-11.582-0.734-17.275-0.248
				c-6.774,0.578-13.218,2.946-18.977,6.801C455.102,691.032,455,691,454.92,691"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M434.52,690.28c-4.502-2.72-9.627-3.347-14.642-4.309c-7.135-1.369-14.352-1.649-21.598-1.456
				c-5.126,0.137-10.085,1.418-15.115,2.198c-3.158,0.489-6.431,0.871-8.885,3.326"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M327.24,653.32c-2.251-5.223-6.759-8.112-11.271-11.055c-14-9.13-34.139-2.974-42.737,9.85c-0.667,0.995-1.433,1.925-2.153,2.885"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M177,51.64c-1.105-9.583-8.086-14.645-15.116-19.686c-4.811-3.449-10.079-5.808-15.836-7.227
				c-5.98-1.475-11.922-1.538-18.017-0.758c-6.076,0.777-11.569,2.664-16.303,6.56c-3.682,3.03-5.536,7.028-5.739,11.752
				c-0.07,1.634-0.741,3.469,0.931,4.799"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M220.44,678.76c-2.93-3.404-6.903-5.239-10.797-7.207c-5.038-2.546-10.204-4.618-15.837-5.549
				c-10.19-1.682-19.409,0.191-27.347,7.018c-2.511,2.16-4.254,4.843-4.099,8.379"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M159.96,681.04c-1.339-0.754-2.272-1.965-3.352-3.009c-0.575-0.556-1.165-1.083-1.911-0.45c-0.572,0.485-0.388,1.307-0.228,1.891
				c1.094,3.979,2.454,7.874,3.727,11.796c1.118,3.441,4.175,5.65,4.966,9.314c1.152,5.337,2.085,10.697,2.75,16.104
				c0.184,1.493,1.08,2.988,0.287,4.555"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M15.72,350.2c0.525,1.646,1.954,2.58,3.153,3.56c6.917,5.65,12.851,12.267,18.952,18.737c8.041,8.528,14.792,17.975,20.598,28.113
				c3.632,6.342,6.631,13.008,9.601,19.678c1.691,3.8,3.08,7.739,4.892,11.477c0.62,1.278,0.555,2.402,0.092,3.361
				c-0.58,1.196-0.949,2.334-0.887,3.635c0.064,1.346-1.18,2.134-1.877,1.823c-2.196-0.975-4.279-0.221-6.262,0.194
				c-2.244,0.469-3.166-0.907-3.29-2.266c-0.563-6.215-3.173-11.721-5.646-17.256c-3.331-7.455-7.21-14.622-12.183-21.152
				c-0.823-1.082-1.618-2.956-2.834-2.457c-1.222,0.502-2.864,1.673-2.74,3.676c0.007,0.104-0.448,0.237-0.688,0.357"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M508.68,253.96c-2.938,9.84-5.518,19.778-7.902,29.764c-1.848,7.739-3.479,15.53-5.328,23.269
				c-0.689,2.885-1.711,5.691-2.129,8.647"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M69,259.96c4.292,7.524,6.941,15.734,8.991,24.032c2.07,8.385,3.218,16.995,4.044,25.641c0.798,8.354,2.666,16.542,6.198,24.232
				c0.818,1.781,2.116,3.337,3.058,5.071c0.461,0.85,0.846,1.849,0.27,2.864"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M350.04,245.56c-2.025,1.879-2.133,4.697-2.705,7.003c-1.336,5.39-3.418,10.503-5.007,15.787
				c-1.875,6.233-1.948,12.424-0.477,18.736c1.156,4.957,1.943,9.974,1.728,15.115c-0.244,5.812-2.342,11.126-4.058,16.572
				c-1.866,5.927-3.476,11.94-3.654,18.227c-0.042,1.487-0.437,2.901-1.427,4.081"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M336.36,707.8c-5.288-2.588-10.814-4.579-16.57-5.708c-7.573-1.484-15.133-1.843-22.818,0.076
				c-5.18,1.292-10.407,2.29-15.346,4.398c-0.346,0.147-0.689,0.226-0.945,0.514"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M278.04,717.88c-3.911-1.663-8.132-1.914-12.245-2.613c-6.783-1.151-13.588-0.748-20.398-0.055
				c-4.242,0.433-8.504-0.262-12.703-0.622c-3.407-0.292-6.738,0.166-10.094-0.07"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M320.52,674.68c-3.944-4.548-9.26-6.596-14.887-7.89c-9.61-2.209-18.628,0.059-27.351,4.054c-4.679,2.144-9.456,4.144-12.962,8.156
				"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.36,705.64c-6.234-1.837-12.521-3.225-19.079-3.407c-6.766-0.189-13.26,1.141-19.67,2.968
				c-4.979,1.42-10.05,2.744-14.191,6.176c-0.542,0.448-1.15,0.774-1.42,1.464"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M539.88,218.68c-1.521-0.96-3.04-1.92-4.56-2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M535.08,215.56c-3.244-1.598-6.337-3.522-9.84-4.56"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M509.88,204.4c-0.88-0.28-1.76-0.56-2.64-0.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M503.16,202c-6.08-1.96-12.161-3.917-18.239-5.882c-1.686-0.544-3.361-1.118-5.041-1.678"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M462.84,188.56c-7.279-3.059-14.801-5.466-22.355-7.68c-3.13-0.917-6.422-1.839-9.805-1.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M276.12,723.16c-6.12-1.96-12.213-3.957-18.717-4.363c-3.447-0.216-6.885-0.274-10.322-0.168c-4.884,0.15-9.751-0.325-14.645-0.102
				c-3.534,0.162-7.042,0.479-10.557,0.793"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M292.2,332.44c1.678,5.875,4.946,10.944,8.44,15.811c8.084,11.262,18.164,20.387,29.639,28.228
				c8.04,5.493,16.395,10.087,25.545,13.25c1.387,0.48,2.848,1.618,4.536,0.792"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M68.52,673.96c-3.513-4.538-8.498-6.726-13.675-8.653c-5.199-1.937-10.734-1.704-16.078-2.666
				c-5.481-0.987-10.809-0.156-16.068,1.079c-2.466,0.579-5.217,1.005-6.979,3.28"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M331.8,244.6c0.473,1.065,0.378,1.903-0.44,2.912c-2.363,2.913-4.284,6.155-7.179,8.678c-1.066,0.929-1.632,2.377-1.388,4.267
				c0.366,2.819-0.153,5.774-0.487,8.607c-0.302,2.558-0.402,5.145-0.749,7.707c-0.767,5.66-1.289,11.358-1.907,17.04
				c-0.612,5.622-1.468,11.212-2.364,16.771c-1.048,6.495-1.887,13.119-4.686,19.219"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M365.64,687.16c-3.051-1.659-5.968-3.538-9.077-5.123c-3.776-1.923-7.624-2.868-11.803-2.806c-2.959,0.044-5.92,0.031-8.88,0.003
				c-5.5-0.052-10.687,0.886-15.396,4.031c-1.731,1.157-3.845-0.044-5.712-0.275c-5.529-0.686-11.029-0.718-16.573-0.416
				c-5.494,0.298-10.808,1.159-16.112,2.797c-5.746,1.774-11.157,4.029-15.773,7.828c-4.778,3.932-8.899,8.56-12.722,13.426
				c-0.571,0.727-1.237,1.521-1.253,2.614c-0.012,0.862-0.686,1.02-1.418,0.961"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M272.04,731.08c-9.191-2.604-18.47-4.212-28.085-2.922c-2.537,0.341-5.092-0.451-7.674-0.493
				c-5.351-0.088-10.598,0.605-15.841,1.495"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M268.44,639.4c-6.751-2.853-13.795-4.107-21.118-4.38c-6.691-0.248-13.322-0.131-19.922,1.019c-3.53,0.614-6.935,1.596-10.08,3.361
				"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M363.48,272.08c1.282,1.458,1.337,1.469,0.705,4.917c-0.786,4.291-0.857,8.658-1.469,12.957c-0.841,5.904-1.047,11.862-1.77,17.779
				c-0.522,4.279-0.692,8.631-1.056,12.948c-0.27,3.206-0.37,6.422-0.953,9.602c-0.378,2.062,0.637,4.084,0.037,6.252
				c-1.169,4.234-1.229,8.68-2.369,12.967c-1.049,3.944,0.161,7.987,0.614,11.979c0.66,5.828,2.154,11.453,3.859,17.038
				c0.256,0.835,0.874,1.824-0.259,2.361c-0.93,0.44-1.277-0.686-1.624-1.187c-3.396-4.906-6.692-9.878-8.678-15.574"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M368.76,187.12c-1.844-0.132-3.695-0.633-5.52,0.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M327.96,246.4c-1.697,0.119-3.334,0.104-5.059-0.274c-2.202-0.483-4.501,0.025-6.701,0.634"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M369.72,684.52c2.447-1.99,5.517-2.387,8.413-2.789c3.968-0.55,7.811-1.618,11.746-2.255c6.141-0.993,12.259-1.121,18.491-0.594
				c5.094,0.431,10.048,1.157,14.865,2.771c8.146,2.73,16.376,5.291,23.524,10.307"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M212.28,658.12c2.324-3.559,2.304-3.543-0.476-7.203c-7.872-10.366-18.556-14.329-31.199-13.375
				c-5.381,0.406-9.874,3.32-14.165,6.418"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M458.28,209.8c-2.072,2.363-4.731,4.147-5.86,7.405c-0.762,2.199-0.3,4.356-0.743,6.539c-1.433,7.056-2.605,14.188-3.397,21.345
				c-0.616,5.578-1.381,11.156-1.559,16.79c-0.104,3.287-0.629,6.56-0.902,9.843c-0.074,0.891-0.277,1.863,0.777,2.665
				c3.292-3.787,6.424-7.909,9.304-12.254c7.148-10.784,11.328-22.824,15.122-35.046c0.426-1.372,0.28-2.958,1.419-4.086"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M116.04,655.24c-2.702-3.008-6.496-3.251-10.087-3.792c-3.567-0.538-7.19-0.721-10.793-1c-4.998-0.388-9.185,1.911-13.45,4.05
				c-5.475,2.744-10.38,6.162-14.149,11.062"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M169.08,285.16c0.624,2.624,2.675,4.362,4.12,6.452c5.381,7.782,11.662,14.859,17.725,22.104c0.426,0.509,1.023,0.902,0.955,1.684"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M196.68,320.44c9.68,8.16,19.36,16.32,29.04,24.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M229.56,347.56c3.219,0.981,5.605,3.355,8.383,5.067c10.151,6.257,20.038,12.909,29.559,20.103
				c2.505,1.893,4.899,3.893,7.151,6.057c0.87,0.836,1.771,1.193,2.906,1.173"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M417.96,666.76c-5.192-7.841-13.436-11.278-21.64-14.54c-3.93-1.562-8.288-0.381-12.438-0.072
				c-4.186,0.31-8.421,0.548-12.236,2.623c-0.6,0.326-1.125,0.79-1.686,1.189"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M547.8,307.96c-11.193,10.756-24.949,17.766-37.931,25.903c-4.01,2.512-8.213,4.718-12.195,7.271
				c-1.398,0.897-2.604,1.275-4.114,0.667"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M105.48,321.64c-1.131,2.519-0.082,4.983,0.271,7.435c0.562,3.903,1.514,7.73,2.588,11.531c1.01,3.574,4.377,4.83,6.805,6.877
				c7.498,6.323,15.983,11.286,24.191,16.612c11.435,7.42,23.712,13.378,35.528,20.142c8.552,4.894,17.498,9.103,25.648,14.694
				c4.28,2.937,8.661,5.76,12.248,9.588"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M513.48,707.8c-4.48-2.319-8.977-4.609-13.437-6.968c-4.964-2.625-10.39-3.436-15.849-3.765c-4.665-0.282-9.429-0.52-13.91,1.385
				c-1.711,0.727-3.365,1.588-5.045,2.388"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M198.12,290.2c-0.159,1.302,0.857,1.813,1.686,2.391c11.43,7.971,22.849,15.956,34.536,23.557
				c11.364,7.391,22.593,14.99,33.835,22.567c1.084,0.73,2.139,1.113,3.383,1.165"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M166.44,663.64c-5.997-7.603-13.896-11.199-23.52-11.293c-7.177-0.07-14.154,0.723-20.647,4.079
				c-0.89,0.46-1.704,0.976-2.393,1.694"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M418.92,439.96c-3.722,1.368-7.669,1.659-11.523,2.384c-4.626,0.869-9.351,1.337-13.885,2.537c-2.186,0.579-4.044,2.42-6.029,3.723
				c-4.582,3.007-9.992,4.816-13.659,9.136c-2.656,3.129-6.104,3.302-9.849,3.713c-5.395,0.591-10.308-1.006-15.372-2.066
				c-1.602-0.335-3.202-0.159-4.803-0.226"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,769.24c-2.836-3.355-6.974-4.309-10.811-5.732c-10.57-3.923-20.977-2.97-31.184,1.428c-1.903,0.819-3.876,1.54-5.526,2.864
				"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M471,675.4c-4.354-1.487-8.389-3.856-12.75-5.183c-7.457-2.269-14.952-1.794-22.306,1.062c-3.812,1.48-7.676,2.757-10.786,5.558
				c-0.575,0.518-1.284,0.115-1.918,0.242"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M33,225.88c1.261,3.119,3.865,5.268,5.813,7.88c7.793,10.451,14.619,21.464,19.834,33.414c3.955,9.061,6.704,18.512,8.19,28.306
				c0.17,1.119,0.322,2.24,0.481,3.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M104.76,395.32c-0.711,2.784,0.684,5.175,1.447,7.678c1.258,4.124,1.6,8.492,3.353,12.482"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M111.96,418.84c2.545,0.737,3.776,3.031,5.218,4.85c7.498,9.454,12.434,20.232,15.354,31.888c0.954,3.807,2.051,7.612,1.662,11.758
				c-0.218,2.319,0.774,4.962,1.867,7.135c1.043,2.073,3.073,0.723,5.199,0.418c-1.758-1.918-2.955-3.796-5.54-3.728"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M370.68,487.72c-0.73-2.427-0.94-4.916-0.98-7.439c-0.019-1.117,0.132-2.225-0.162-3.375c-0.262-1.024-0.097-2.461-1.483-2.278
				c-0.894,0.118-1.227,1.455-1.218,2.533c0.045,5.12-0.37,10.242,0.227,15.361c0.575,4.944,0.146,9.919,0.258,14.879"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M164.04,665.32c-2.228-4.085-6.536-4.619-10.326-5.983c-5.035-1.812-10.306-2.596-15.597-3.1c-4.03-0.383-7.865,1.028-11.483,2.448
				c-7.8,3.062-13.475,8.605-17.113,16.216c-0.41,0.857,0.003,1.525,0.115,2.165c0.269,1.539,0.309,3.095,0.094,4.563
				c-0.251,1.724-0.519,3.616,0.169,5c1.588,3.194,1.322,7.054,3.74,9.89"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M69.72,238.84c4.889,7.948,8.3,16.59,11.741,25.208c4.227,10.587,7.55,21.484,9.757,32.653c1.046,5.294,1.79,10.688,2.605,16.044
				c1.902,12.496,4.406,24.889,10.922,36.024c0.195,0.333,0.174,0.792,0.254,1.191"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M107.88,354.04c1.686,2.055,4.001,3.36,5.972,5.073c0.743,0.646,1.462,0.314,2.188,0.447"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M283.08,523.48c-0.152-3.254,0.166-3.5,3.361-2.644c0.942,0.252,1.854,0.953,3.385-0.101c-1.899-1.772-3.725-3.472-5.544-5.178
				c-0.413-0.387-0.84-0.768-1.195-1.204c-1.088-1.339-2.612-2.685-2.094-4.534c0.557-1.993,2.705-1.539,4.248-1.937
				c3.747-0.962,4.115-2.441,2.625-5.276c-1.155-2.198-2.754-3.357-5.458-4.035c-4.178-1.048-8.05-3.324-12.043-5.101
				c-0.586-0.261-1.093-0.785-1.693-0.904c-0.507-0.102-1.236-0.369-1.653,0.205c-0.422,0.582-0.354,1.265-0.019,1.908
				c0.822,1.578,1.53,3.186,1.438,5.09c-2.124-0.472-3.283-1.902-4.556-3.172c-2.804-2.796-5.553-5.649-8.424-8.374
				c-1.169-1.109-1.192-1.839,0.005-2.926c2.903-2.636,2.979-3.837,0.226-6.667c-1.375-1.416-2.371-3.072-3.602-4.563
				c-0.74-0.896-0.791-1.901-0.377-2.63c0.537-0.947,1.701-0.723,2.556-0.25c0.64,0.354,1.136,0.167,1.696-0.028"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M169.32,284.2c2.704,0.396,3.919,2.813,5.72,4.367c6.82,5.884,13.296,12.176,20.24,17.905
				c13.417,11.071,27.785,20.874,42.738,29.711c11.615,6.864,23.07,13.94,34.253,21.471c0.463,0.312,0.837,0.73,1.45,0.706"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M199.92,97c0.465,6.804,2.85,12.578,8.5,16.827c5.294,3.982,10.82,4.405,16.282,0.138c3.985-3.111,7.158-7.146,9.879-11.457
				c0.145-0.229,0.019-0.628,0.019-0.948"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M236.04,69.4c2.248-6.295,0.895-12.133-2.954-17.224c-3.367-4.454-8.142-6.526-13.841-5.312
				c-6.952,1.481-11.692,5.353-12.965,12.697"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M590.52,288.52c0.611,1.99-0.919,3.496-1.325,5.251c-1.326,5.718-1.615,11.534-2.237,17.313c-0.777,7.23-2.932,14.075-5.35,20.864
				c-0.146,0.411-0.768,0.651-1.167,0.972"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M166.92,342.76c7.387,3.75,14.613,7.798,21.583,12.266c9.964,6.385,19.077,13.869,27.617,22.054
				c7.211,6.912,13.678,14.502,20.17,22.071c0.415,0.483,0.111,0.976,0.229,1.449"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M453.72,721.24c-7.154-9.497-19.439-11.312-29.993-6.704c-1.569,0.686-3.427,1.07-4.523,2.419c-1.913,2.35-3.501,1.543-5.337-0.019
				c-3.028-2.574-6.535-4.315-10.307-5.537c-1.182-0.383-2.123-0.842-2.207-2.396c-0.082-1.528-1.697-2.392-2.112-3.843"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M355.08,397.24c3.04-0.32,6.089-0.579,9.111-1.018c0.538-0.078,1.902-0.086,1.428-1.375c-0.669-1.816,0.355-1.968,1.701-1.931
				c0.639,0.018,1.28,0.003,1.92,0.003c2.477,0,3.857,1.246,3.255,3.573c-0.377,1.458,0.233,2.172,0.812,3.155
				c0.473,0.805,1.182,1.43,1.501,2.376c0.219,0.65,1.275,1.201,0.852,1.898c-0.446,0.733-1.661,0.572-2.326,0.447
				c-3.946-0.738-7.865-1.64-11.768-2.593c-1.793-0.438-2.849-0.491-2.712,1.946c0.113,2.013,0.289,3.017,2.625,4.024
				c4.05,1.748,8.455,2.884,12.067,5.591c0.583,0.438,0.993,0.105,1.451-0.028c9.95-2.905,19.847-6.007,30.009-8.12
				c0.689-0.144,1.614-0.343,2.146,0.455c2.082,3.125,4.913,1.547,7.688,1.552c5.814,0.013,8.033-4.733,11.501-7.818
				c3.649-3.246,2.266-2.86,4.805,0.751c0.113,0.161,0.015,0.47,0.015,0.71"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M359.88,216.52c0.332,0.969-0.023,1.804-0.476,2.642c-4.171,7.738-7.282,15.888-9.057,24.492c-0.461,2.238,0.241,4.65,0.504,6.936
				c0.878,7.627,1.99,15.238,3.334,22.798c0.694,3.914,1.377,7.96,1.167,11.76c-0.368,6.644-0.368,13.347-2.009,19.977
				c-1.144,4.624-1.865,9.489-2.156,14.351c-0.216,3.616-1.134,7.191-1.328,10.807c-0.229,4.252-0.569,8.485-0.916,12.725
				c-0.123,1.515-0.633,2.863-0.823,4.312"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M368.04,659.8c-7.111-5.081-15.316-6.694-23.762-6.876c-7.846-0.17-15.094,2.252-21.324,7.159
				c-1.353,1.065-2.274,2.677-3.395,4.037"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M60.12,659.32c-1.083-1.402-2.533-2.352-4.087-3.106c-5.36-2.604-10.949-4.572-16.794-5.771c-7.942-1.627-15.69-0.799-23.279,1.917
				"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M374.52,691c-2.699,0.592-5.542,0.363-8.164,1.43c-0.557,0.227-0.965-0.112-1.452-0.445c-7.038-4.823-14.818-7.806-23.27-8.855
				c-3.365-0.418-6.947-0.434-10.017,1.031c-2.176,1.039-3.961,1.002-6.065,0.39c-1.484-0.431-3.121-0.246-4.552-0.99"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,715.48c-7.888-4.537-16.165-7-25.45-5.838c-6.577,0.822-12.925,1.945-18.471,5.838"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M259.32,699.16c-3.881-5.744-10.185-7.718-16.09-10.297c-0.469-0.205-0.954,0.187-1.452,0.406c-2.592,1.147-5.27,2.102-7.895,3.178
				c-3.429,1.406-5.403,4.496-8.08,6.815c2.649,3.488,6.294,5.952,7.657,10.198c0.366,1.14,3.752,1.351,4.737,0.495
				c0.897-0.78,1.794-1.565,2.636-2.403c1.156-1.151,2.33-1.321,3.607-0.232"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M99.24,285.64c-0.508,1.28-0.268,2.581,0.016,3.836c0.998,4.41,2.064,8.804,3.104,13.204"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M102.72,305.8c0.422,4.44,1.072,8.845,2.04,13.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M106.68,321.88c5.274,5.106,10.847,9.864,16.805,14.153c10.876,7.828,22.167,15.012,33.833,21.609
				c7.931,4.486,15.702,9.245,23.552,13.867c9.982,5.877,19.833,11.903,29.042,18.969c4.164,3.194,7.961,6.747,11.737,10.352
				c0.329,0.314,0.791,0.49,1.19,0.729"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M471.48,537.88c-0.757-2.428,0.16-4.811,0.316-7.194c0.211-3.21,0.058-6.406,0.151-9.606c0.04-1.38,0.161-2.72,0.448-4.089
				c0.655-3.125,0.104-6.242-0.396-9.356c-0.143-0.893-0.508-1.627-1.239-1.606c-0.949,0.026-1.194,1.046-1.179,1.853
				c0.044,2.102-0.474,4.192-0.396,6.236c0.072,1.87-1.18,3.281-1.067,5.044"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M162.12,94.36c-1.262-0.736-2.035-2.769-4.123-1.01c-1.883,1.584-1.823,3.223-0.903,5.083c1.705,3.447,4.722,5.042,8.389,5.211
				c1.454,0.067,1.719,0.334,1.981,1.744c1.131,6.087,0.086,11.722-2.944,17.052"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M160.68,126.04c-3.967,3.251-8.309,4.887-13.44,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M158.28,635.56c0.32-0.56,0.555-1.196,0.974-1.667c2.354-2.644,2.451-2.765-0.511-4.545c-6.259-3.76-13.128-5.627-20.381-6.287
				c-6.689-0.608-12.852,1.319-18.957,3.633c-0.359,0.136-0.646,0.467-0.965,0.707"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M305.88,532.36c-0.32,0.319-0.594,0.714-0.968,0.947c-1.722,1.073-1.512,1.802,0.23,2.688c1.828,0.929,1.735,1.543-0.226,2.599
				c-2.693,1.449-5.637,2.046-8.637,2.397c-1.554,0.182-3.097,0.375-4.559,0.969c-1.4,0.567-2.102,1.555-2.182,3.118
				c-0.163,3.192-1.316,4.241-4.539,4.317c-0.959,0.022-1.923-0.043-2.879,0.013c-2.513,0.147-3.154,1.188-2.145,3.584
				c0.554,1.316,1.323,2.542,1.883,3.856c0.348,0.816,0.835,1.691,0.262,2.631"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M126.6,730.12c0.785-0.856,0.574-1.937,0.449-2.876c-1.136-8.567-0.731-17.13,0.071-25.681c0.203-2.156,2.084-3.913,1.874-6.243
				c-0.282-3.108,1.24-5.96,1.071-9.126c-0.162-3.039-0.849-6.053-0.584-9.114c0.067-0.775-0.435-1.817,0.726-2.135
				c0.892-0.243,1.266,0.294,1.727,1.146c2.086,3.855,3.585,7.759,3.879,12.263c0.383,5.865,0.57,11.669-0.058,17.53
				c-1.232,11.481-1.504,23.025-2.123,34.547c-0.159,2.95,0.39,5.269,1.608,7.688c1.695,3.37,2.813,6.932,3.958,10.522
				c0.919,2.886,2.67,5.577,2.679,8.679c0.005,1.704-0.239,3.347,0.264,5.057c0.268,0.913,0.762,0.623,1.258,0.703"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M548.04,754.6c-9.109-3.406-18.346-4.838-28.101-2.985c-5.013,0.951-9.719,2.522-14.38,4.426"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M321.48,678.04c-1.312,0.677-2.294,0.606-3.65-0.413c-5.009-3.763-10.779-5.63-16.98-6.612c-7.833-1.242-14.899,0.5-21.61,4.386"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M260.76,657.64c-7.131-2.71-14.574-3.203-22.078-2.846c-7.333,0.35-14.4,1.838-20.365,6.493c-0.905,0.707-1.317,0.183-1.957-0.047"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M210.12,676.6c-4.148-2.052-8.181-4.376-12.721-5.516c-7.383-1.853-14.593-1.116-21.585,1.709
				c-2.479,1.002-5.114,1.766-7.213,3.567"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M553.32,738.04c-5.032-3.725-10.844-5.692-16.811-7.161c-6.047-1.488-12.221-2.255-18.47-2.183
				c-0.635,0.007-1.273,0.045-1.931,0.166c-2.582,0.476-5.012,0.927-7.257-1.562c-4.021-4.46-9.689-6.387-15.053-8.701"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M228.12,477.16c-0.201,1.512-1.217,2.51-2.139,3.617c-2.024,2.431-2.223,4.084-0.227,5.946c1.467,1.368,1.104,2.696,0.163,3.841
				c-1.374,1.67-2.987,3.235-5.476,2.904c-1.329-0.177-2.216,0.29-2.894,1.443c-1.792,3.052-3.897,5.969-2.433,9.865
				c0.316,0.842,0.318,2.009-0.007,2.842c-0.845,2.165-1.933,4.25-4.747,4.341"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M348.12,415c-0.204-3.792-2.488-4.796-5.931-5.766c-2.649-0.745-5.33-2.354-7.987-3.599c-5.639-2.639-10.77-6.154-16.065-9.383
				c-0.651-0.396-1.351-0.946-1.888-0.404c-0.537,0.543-0.466,1.381,0.176,2.124c8.955,10.357,15.131,22.309,19.903,35.039
				c0.519,1.384,1.917,2.566,1.231,4.309"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M228.84,347.8c-0.151,0.988,0.443,1.802,0.993,2.369c8.135,8.389,14.292,18.425,22.341,26.86c2.814,2.949,3.913,6.786,6.301,9.985
				c6.619,8.867,12.438,18.304,17.396,28.23c1.089,2.181,2.576,4.198,2.889,6.716"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M158.04,672.04c-6.619-4.348-13.569-6.367-21.627-4.432c-5.007,1.203-9.526,3.342-14.121,5.419
				c-1.994,0.901-3.21,2.712-4.333,4.532"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M370.92,677.08c-2.4,1.122-3.616-0.303-5.374-1.811c-3.589-3.078-8.203-4.565-12.645-6.056c-7.28-2.443-14.554-2.168-21.569,1.172
				c-1.127,0.537-2.092,1.414-3.132,2.134"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M15.72,220.6c1.951,3.553,5.19,6.017,7.703,9.102c7.553,9.273,14.841,18.72,19.636,29.788c3.391,7.827,5.304,16.083,6.775,24.465
				c0.68,3.875,1.726,7.686,2.606,11.526"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M254.52,605.32c-3.649-2.066-7.792-2.427-11.769-2.783c-9.425-0.843-18.74-0.361-27.125,4.92c-0.531,0.334-1.266,0.343-1.906,0.503
				"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M588.6,660.52c-6.657-7.854-19.994-10.132-28.292-4.758c-0.608,0.394-1.117,0.442-1.708,0.43
				c-9.027-0.186-16.557,3.368-23.303,9.102c-3.159,2.685-6.1,5.515-8.096,9.17c-0.421,0.77-0.944,0.828-1.474,0.671
				c-3.845-1.14-7.859-1.002-11.775-1.606c-2.607-0.401-5.244,1.03-7.912,0.192"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M292.2,469.72c-2.489,1.425-4.978,2.851-7.663,4.389c1.316,1.167,2.777,0.451,4.065,0.634c0.529,0.075,1.189-0.105,1.413,0.507
				c0.277,0.759-0.501,0.98-0.945,1.168c-3.683,1.56-7.333,2.414-11.529,1.847c-4.689-0.633-8.794-1.968-12.518-4.87
				c-0.782-0.609-2.027-1.083-1.755-1.96c0.309-0.994,1.803-1.491,2.757-1.362c0.871,0.117,1.471-0.419,2.171-0.322
				c1.217,0.167,1.601-0.772,1.866-1.49c0.284-0.771-0.493-1.207-1.122-1.685c-2.625-1.996-5.546-3.623-7.708-6.207
				c-1.607-1.921-1.272-2.636,1.195-3.408c2.708-0.849,5.492,0,8.338-0.721c-1.567-2.305-4.452-2.799-6.637-5.09
				c3.33-0.477,6.094-1.663,9.112-1.825c0.721-0.039,1.581,0.187,1.903-0.729c0.295-0.838-0.465-1.234-0.952-1.663
				c-2.766-2.439-5.872-4.481-8.396-7.206c-0.643-0.693-1.515-1.244-1.195-2.404"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M584.76,259.24c0.856,3.13-0.57,6.014-1.296,8.855c-2.361,9.241-5.008,18.413-8.283,27.392
				c-2.812,7.711-6.938,14.707-11.301,21.593"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M542.76,723.16c-9.541-2.98-19.321-4.155-29.276-3.547c-3.372,0.206-6.777,0.501-10.091,1.359c-0.656,0.17-1.116,0.422-1.433,0.987
				"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M396.84,709.24c-0.809-2.515-3.253-3.323-5.052-4.786c-4.621-3.754-9.813-6.859-15.615-7.764
				c-6.204-0.968-12.48-0.035-17.732,4.149"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M205.8,409.96c-11.713-5.449-23.853-9.819-36.009-14.133c-5.625-1.996-11.243-4.27-17.062-5.378
				c-5.176-0.986-8.527-3.966-11.991-7.345c-7.169-6.995-14.852-13.451-21.817-20.664"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M116.04,358.36c-1.53-3.635-3.906-6.846-5.287-10.557c-0.408-1.095-1.12-2.127-0.473-3.363"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M229.56,185.08c-4.685,0-4.696,0.012-8.637-3.603c-5.794-5.314-12.626-7.521-20.399-6.685c-2.684,0.289-5.226,1.279-7.426,2.872
				c-2.006,1.453-3.066,3.595-3.61,5.978c-0.509,2.23,1.249,3.371,2.393,4.798"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M592.2,771.64c-4.082-0.895-7.62-3.396-11.763-4.063c-9.591-1.544-18.839-1.187-26.878,5.264"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M368.04,643c-1.463-3.014-4.589-3.222-7.217-4.025c-7.189-2.2-14.638-2.267-22.061-1.919c-2.985,0.14-5.926,0.959-8.643,2.345"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M155.88,638.68c-3.205-1.797-6.819-2.349-10.325-2.548c-7.982-0.453-15.917,0.231-23.503,3.06
				c-1.676,0.625-3.406,1.327-4.572,2.849"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M15.72,235.48c1.348,3.761,4.529,6.205,6.768,9.326c5.365,7.481,7.998,16.029,10.25,24.76c0.092,0.356-0.138,0.795-0.218,1.194"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M497.16,690.76c-6.224-2.138-12.616-3.072-19.2-2.901c-4.073,0.105-7.426,2.271-11.01,3.698c-2.635,1.049-4.99,2.804-7.47,4.243"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M66.36,736.6c2.417,1.379,2.863,3.972,3.548,6.256c2.233,7.454,2.747,15.299,4.909,22.769c0.762,2.634,1.222,5.34,2.103,7.936"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M492.36,772.6c-5.415-7.699-13.326-11.769-22.114-13.51c-8.294-1.644-16.833-1.684-24.703,2.68
				c-1.283,0.712-2.556,1.372-3.583,2.431"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M40.2,86.2c-1.367,2.153-3.896,3.037-5.345,5.237c-2.967,4.502-2.229,8.187,0.59,11.039c4.808,4.863,10.742,5.859,17.237,4.131
				c0.787-0.209,1.599-0.327,2.398-0.486"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M561.96,257.56c-1.388,4.529-3.354,8.784-6,12.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M550.92,280.12c-4.617,9.213-11.359,16.712-18.959,23.521c-0.709,0.634-1.442,1.224-2.401,1.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M524.04,309.4c-1.755,2.042-3.756,0.303-5.509,0.128c-4.536-0.452-8.57-2.707-12.729-4.451c-0.683-0.286-0.69-0.648-0.536-1.447
				c1.687-8.704,3.143-17.452,4.888-26.144c1.582-7.883,3.603-15.669,6.247-23.272c0.21-0.604,0.071-1.141,0.199-1.694"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M29.64,712.84c1.598-1.201,1.694-3.163,2.372-4.812c0.984-2.393,1.985-4.769,3.402-6.938c0.418-0.642,1.003-1.217,1.904-0.963
				c1.156,0.324,0.896,1.297,0.973,2.152c0.305,3.358-1.171,6.395-1.673,9.604c-0.336,2.145-1.328,4.688-0.562,6.398
				c1.518,3.391,1.341,7.018,2.447,10.404c0.734,2.249,0.72,4.716,1.937,6.834"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M541.8,762.28c-2.898-2.264-6.463-2.449-9.848-2.811c-5.846-0.625-11.688-0.369-17.271,1.852c-3.298,1.312-6.465,2.854-9.121,5.278
				"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M51.24,626.44c-6.609-4.752-14.129-7.355-22.093-8.011c-4.89-0.402-9.576,1.534-13.427,4.891"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M409.56,762.04c13.134-0.479,24.516,4.674,35.515,11.05c0.129,0.075,0.166,0.311,0.246,0.47"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,653.56c-0.623,0.435-1.046,0.379-1.438-0.241c-4.488-7.103-11.265-11.137-18.987-13.602
				c-4.183-1.334-8.614-1.11-12.94-0.096c-0.285,0.066-0.635-0.141-0.955-0.221"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M482.28,772.12c-7.773-4.925-15.999-8.411-25.44-8.172c-3.917,0.1-7.441,1.234-10.56,3.611"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M101.16,634.84c-8.136-1.759-15.885-0.265-23.532,2.608c-4.427,1.663-8.32,4.198-12.228,6.752"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M15.72,636.28c2.769-2.093,6.075-2.633,9.363-3.101c9.374-1.336,17.269,2.636,24.949,7.194c5.505,3.268,9.587,8.139,13.928,12.706"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M15.96,330.52c-0.078,0.787,0.325,1.212,0.94,1.705c9.31,7.435,18.199,15.339,26.416,23.979c2.106,2.215,4.164,4.476,6.244,6.716"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,278.68c0,0.4,0.173,0.97-0.026,1.174c-2.557,2.61-4.289,6.159-7.071,8.242c-3.196,2.396-5.304,5.501-7.731,8.387
				c-5.883,6.995-10.196,14.952-13.534,23.46c-0.573,1.462-0.262,3.788-2.837,3.856"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M426.36,662.68c-5.27-13.557-16.239-19.906-29.528-23.251c-2.651-0.668-5.491-0.164-8.152-0.988"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M447.72,84.76c2.113-2.468,1.767-5.401,1.14-8.146c-1.108-4.852-3.385-8.813-8.294-11.149c-4.285-2.039-7.825-0.884-11.027,1.609
				c-3.03,2.358-5.29,5.554-7.019,9.045"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M307.8,254.68c1.683,1.383,0.869,3.299,0.628,4.785c-0.813,5.025-1.585,10.016-1.89,15.125c-0.13,2.167-0.751,4.443-0.935,6.728
				c-0.354,4.41-1.006,8.795-1.385,13.204c-0.599,6.979-1.003,13.973-2.237,20.889c-0.18,1.005,0.021,2.107-0.422,3.109"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M444.36,590.68c-4.251-2.083-8.697-3.736-13.44-3.78c-7.768-0.072-13.327,3.806-17.091,10.557c0.931-0.331,1.812,2.081,2.69-0.056"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M273.72,727c-7.057-3.378-14.595-4.808-22.322-4.983c-7.834-0.178-15.68,0.056-23.518,0.225c-1.526,0.033-3.154,0.359-4.521,1.003
				c-0.906,0.427-1.101-0.66-1.72-0.324"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M559.08,752.92c-9.31-5.257-19.397-7.781-30.001-7.846c-7.698-0.046-15.347,0.998-22.051,5.25
				c-3.519,2.23-6.748,4.916-10.108,7.396"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M513.6,489.88c-2.396,2.02-5.396,2.228-8.284,2.605c-3.902,0.511-7.827,0.873-11.758,0.048c-2.646-0.556-5.123,0.68-7.654,1.089
				c-1.304,0.211-2.6,0.294-3.862,0.267c-0.908-0.02-1.631,0.594-1.949,0.89c-0.771,0.717,0.305,1.765,0.781,1.991
				c3.594,1.709,6.579,4.432,10.992,4.805c4.393,0.37,8.849,0.48,13.213,1.277c0.821,0.15,1.428-0.408,2.144-0.571
				c2.667-0.609,5.132,0.512,7.698,0.56"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M319.32,599.56c-5.807-5.2-12.935-7.578-20.404-7.707c-5.066-0.087-10.179,1.821-13.436,6.508"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M337.08,343.72c-0.929,0.832-0.676,1.966-0.442,2.87c2.533,9.801,5.854,19.32,10.543,28.318c0.448,0.859,0.973,1.643,0.939,2.651"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M455.4,186.28c-0.152,2.675,1.146,5.192,0.816,7.932c-0.073,0.612,0.908,1.598,1.827,1.895c5.257,1.699,8.736,5.486,11.774,9.84
				c0.675,0.967,1.543,1.812,2.389,2.646c2.587,2.546,4.371,2.151,6.516-0.923c1.84-2.636,1.336-8.731-0.782-11.294
				c-0.551-0.667-1.086-1.249-0.94-2.176"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M103.56,304.6c3.05,0.874,4.762,3.57,7.165,5.328c8.62,6.307,17.542,12.12,26.919,17.224c9.18,4.997,18.389,9.94,27.622,14.838
				c0.337,0.178,1.597,1.492,1.654-0.43"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M154.92,596.44c-2.124-5.951-10.754-11.932-18.957-10.539c-4.641,0.788-9.261,1.751-12.963,5.019"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M499.32,740.68c-4.821-2.032-9.601-4.055-14.871-4.858c-8.81-1.343-17.416-0.536-25.913,1.797
				c-1.874,0.515-4.034,0.518-5.536,2.102"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M314.52,591.4c-8.662-4.933-20.405-3.131-27.589,2.893c-1.036,0.869-2.09,1.798-2.842,2.9c-0.647,0.949-1.127,0.492-1.727,0.203
				c-0.965-0.466-0.218-1.055,0.021-1.422c2.877-4.413,7.21-6.76,11.948-8.725c2.907-1.206,5.952-1.359,8.909-2.09"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M103.8,673c1.154-2.767,0.742-4.207-2.173-4.723c-6.249-1.104-12.556-2.495-18.939-1.008c-3.184,0.741-6.311,1.568-7.788,5.21
				c-0.538,1.324-2.588,1.051-3.983,1.207c-3.366,0.378-6.443,1.986-9.836,2.193"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M141,692.92c2.608,0.812,1.428,3.29,1.751,4.836c0.593,2.835,0.865,6.099,0.032,8.802c-2.077,6.744,0.059,13.524-0.888,20.193
				c-0.524,3.689,0.002,7.409-0.717,11.037c-0.199,1.003,0.094,1.323,0.782,1.692"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M177.48,466.12c2.725,2.798,5.492,5.562,9.125,7.188c2.758,1.234,3.251,1.032,3.532-1.914c0.152-1.599,1.266-2.002,2.252-2.583
				c0.408-0.241,1.161-0.215,1.667,0.416c1.382,1.722,3.213,2.799,5.251,3.643c1.884,0.78,2.828,3.372,1.846,5.938
				c-0.551,1.441-0.537,2.852-0.262,4.084c0.201,0.9,0.619,2.129,0.256,2.598c-0.719,0.928-1.945,1.581-3.267,1.498
				c-2.856-0.18-4.447,1.356-5.264,3.859c-0.668,2.049-1.917,2.684-4.095,2.724c-2.631,0.048-5.244-1.02-7.966,0.298
				c-1.848,0.895-3.357,2.732-5.717,2.516c-0.144-0.014-0.436,0.281-0.46,0.46c-0.536,3.858-4.38,5.338-6.018,8.397"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M266.88,72.04c1.22-2.646-0.634-4.283-2.054-5.988c-3.855-4.629-8.375-7.977-14.861-6.706c-3.718,0.728-6.81,2.766-9.6,5.26
				c-0.901,0.805-1.618,1.844-2.885,2.154"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M411,673.96c0.713-1.356-0.391-1.773-1.193-2.408c-3.96-3.134-8.576-4.151-13.442-3.77c-3.369,0.265-6.751,0.627-10.094,1.334
				c-4.653,0.984-4.681,0.854-4.311,5.084"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M22.68,705.4c3.068,1.731,5.293,4.196,6.774,7.415c0.299,0.65,0.044,1.101-0.098,1.446c-1.29,3.121-1.568,5.944,0.685,8.957
				c1.754,2.346,1.163,5.548,1.737,8.346c0.505,2.458,0.912,4.945,1.207,7.438c1.397,11.801,7.463,21.776,12.488,32.161
				c0.374,0.774,0.647,1.597,0.967,2.396"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M417.72,747.16c4.77,1.515,9.812,2.38,13.89,5.559c0.772,0.603,1.149,0.168,1.711-0.038c5.335-1.959,10.603-3.958,16.3-4.92
				c5.957-1.007,11.835-0.865,17.78-0.616c5.039,0.211,9.773,1.911,14.61,3.022c1.888,0.435,3.673,1.856,5.539,2.772
				c7.16,3.517,14.356,7.028,18.235,14.628c0.698,1.367,1.832,1.08,2.893,0.937c7.025-0.949,14.026-1.031,21.131-0.618
				c5.503,0.319,10.824,1.071,16.052,2.62c0.917,0.271,1.016-0.279,1.46-0.546"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M51.72,71.56c-3.092-0.008-5.924-1.643-9.125-1.242c-4.547,0.57-8.168,2.513-10.797,6.28c-0.891,1.277-0.618,2.568,0.268,3.58
				c3.28,3.745,7.131,6.382,12.455,5.782"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M99.96,705.88c-1.852,2.423-0.106,4.989-0.077,7.438c0.033,2.76,0.66,5.601,0.742,8.403c0.132,4.502,0.217,8.982,0.962,13.447
				c0.29,1.734,0.226,3.495-0.596,5.304c-1.486,3.269-3.377,6.313-4.904,9.552c-0.425,0.901-0.899,1.964-2.127,2.176"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M255,309.88c-2.561,0.449-4.398-1.319-6.179-2.492c-4.647-3.06-9.359-6.034-13.866-9.336c-2.472-1.812-5.84-2.723-8.467-4.56
				c-1.876-1.312-4.47-1.401-5.808-3.532"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M195.96,289.36c-4.253-0.552-8.137-2.225-12-3.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M160.92,274.48c-1.52-0.6-3.04-1.2-4.56-1.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M144.12,267.76c-0.953,0.217-1.987-1.536-2.88,0.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M286.92,740.68c-0.237,0.454-0.44,0.768-0.3,1.452c1.604,7.837,1.066,15.666-0.121,23.517c-0.36,2.389-0.435,4.827-1.019,7.191"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M74.52,671.56c-6.355-6.471-14.385-9.905-22.791-12.746c-8.029-2.712-16.297-1.667-24.489-1.894"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M441.24,197.08c-1.51,7.841-4.662,15.117-7.971,22.297c-2.991,6.491-5.846,13.003-7.65,19.938
				c-0.074,0.284-0.459,0.485-0.699,0.726"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M273.48,438.28c-2.366,1.068-4.265-0.946-6.479-0.979c-2.627-0.04-4.775-1.654-7.19-2.414c-0.551-0.174-1.184-0.669-1.418-1.182
				c-2.572-5.631-5.79-10.92-9.247-16.001c-2.458-3.611-4.284-7.72-7.401-10.808c-2.325-2.304-3.49-6.245-7.159-6.646
				c-2.936-0.32-5.696-2.262-8.894-0.917c-1.51,0.636-2.692,1.253-2.832,2.949c-0.142,1.72-1.352,1.54-2.417,1.414
				c-2.06-0.245-3.949-0.378-5.585,1.406c-0.585,0.639-0.943,1.266-1.107,1.984c-0.324,1.417-1.384,2.511-2.907,2.36
				c-2.543-0.252-4.459,0.071-6.356,2.338c-2.393,2.86-2.749,2.562,0.008,6.095c-0.315,0.139-0.742,0.255-1.089,0.488
				c-2.825,1.9-3.385,4.445-1.694,7.437c0.375,0.662,0.739,1.303,0.273,2.169c-0.687,1.277-5.694,2.887-7.215,1.892
				c-6.853-4.482-14.427-7.664-21.389-11.954c-2.401-1.479-4.707-3.095-7.35-4.271c-1.663-0.74-2.816-2.927-3.877-4.66
				c-3.634-5.936-7.961-11.349-12.257-16.792c-0.479-0.607-1.13-1.216-0.977-2.147"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M258.84,670.36c-3.139-2.159-6.705-2.18-10.32-2.188c-3.279-0.009-6.499,0.448-9.863-0.056c-3.938-0.59-8.096-0.24-11.737,2.004"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M594.36,724.36c-4.014-4.349-9.442-5.447-14.89-5.894c-4.194-0.345-8.496-0.538-12.713,0.363c-1.474,0.314-2.935,0.603-4.078,1.689
				"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M383.4,593.08c-6.185-1.983-12.433-3.128-18.965-1.946c-4.208,0.762-7.705,2.98-11.244,5.118c-0.562,0.339-1.181,0.537-1.711,0.908
				"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M25.8,520.6c0.433,2.112,2.356,2.159,3.853,2.599c2.192,0.644,4.45,1.142,6.714,1.433c1.493,0.191,2.262,1.14,2.557,2.23
				c0.319,1.178,0.11,2.572,1.294,3.311c1.284,0.801,2.805,0.935,4.303,0.986c2.797,0.097,3.16,1.008,1.994,3.635
				c-0.525,1.184-1.794,2.239-1.274,3.807"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M371.88,460.36c2.445,4.36,6.478,6.492,11.034,7.938c1.576,0.5,3.084,1.138,3.863,2.854c1.714,3.78,5.011,5.227,8.863,5.766
				c1.969,0.275,3.715,1.179,5.52,1.922"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M284.04,589.72c-5.095-1.992-10.232-4.166-15.831-3.299c-5.791,0.896-10.438,3.841-13.463,9.051
				c-0.565,0.975-1.143,2.307-2.626,0.969"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M467.64,517.72c-0.479-0.88-0.958-1.761-1.44-2.64c-0.322-0.587-0.562-1.553-1.41-1.14c-0.573,0.28-0.545,1.069-0.317,1.873
				c0.69,2.443,1.451,4.856,2.464,7.18c0.61,1.401,0.997,2.875,0.646,4.312c-0.283,1.155,0.023,2.895-2.347,2.786
				c-1.059-0.048-2.188,1.301-2.893,2.497c-0.549,0.935-1.168,1.862-1.922,2.632c-1.57,1.606-1.51,1.441,0.219,2.946
				c0.962,0.839,0.801,1.991-0.174,3.095c-3.431,3.887-8.369,5.042-12.79,7.08c-0.996,0.459-2.035,0.747-2.832,1.541
				c-1.748,1.739-3.389,3.593-5.757,4.571c-1.416,0.585-1.437,1.979-1.151,3.092c0.478,1.862,2.012,0.555,3.065,0.734"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M78.84,692.2c3.663,3.07,4.267,7.48,5.014,11.765c1.141,6.542,2.106,13.115,3.146,19.675"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M450.84,723.4c-0.479,0.055-0.857,0.9-1.457,0.012c-2.856-4.231-7.402-5.485-11.993-6.181c-5.585-0.846-11.127-0.47-16.064,2.816
				c-0.517,0.344-0.965,0.792-1.445,1.192"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M531.72,659.56c-10.407-4.438-21.111-5.819-31.617-1.062c-6.454,2.923-12.407,7.041-17.343,12.343"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M67.08,328.12c4.836,5.426,9.274,11.156,13.211,17.273c0.804,1.249,1.749,2.407,2.629,3.607"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M86.28,354.52c3.349,6.525,6.981,12.917,9.815,19.69c2.717,6.495,4.789,13.249,7.705,19.669"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M105.96,395.32c8.891,6.681,16.647,14.494,22.76,23.787c5.939,9.028,10.376,18.805,13.221,29.259
				c0.534,1.964,0.691,4.032,1.263,5.982c0.399,1.359,0.129,1.827-1.244,1.692c-0.787-0.077-1.536,0.175-1.637,0.966
				c-0.113,0.884,0.741,1.665,1.392,1.607c2.279-0.202,2.76,1.387,3.366,2.946"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M125.4,596.92c-5.701-6.591-13.03-9.434-21.598-9.069c-3.291,0.14-6.486,1.076-9.362,2.829"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M345.24,559.24c-0.388,1.726-1.656,2.546-3.094,3.404c-2.343,1.401-4.887,2.614-6.296,5.22c-0.456,0.842-1.449,1.143-2.356,1.493
				c-0.554,0.214-1.585,0.278-1.215,1.402c0.319,0.967,1.074,1.052,1.921,0.96c2.678-0.29,4.362-1.997,5.752-4.084
				c0.799-1.2,1.705-2.281,2.856-3.3c1.29,2.023,0.013,3.869-0.041,5.702c-0.026,0.892-0.995,1.988,0.105,2.581
				c1.202,0.647,2.652,0.502,3.8-0.428c3.13-2.535,6.464-4.837,9.127-7.911"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M219.72,644.92c6.374-5.426,13.913-6.378,21.834-5.476c3.391,0.386,6.69-0.588,10.093-0.101c5.318,0.761,10.223,2.319,14.392,5.816
				"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M43.56,472.12c-1.561-0.693-3.059-0.197-4.737,0.232c1.645,2.336,4.144,2.402,6.421,2.601c4.768,0.414,9.317-0.479,13.436-3.074
				c0.244-0.153,0.523-0.279,0.712-0.486c0.464-0.51,1.503-0.87,1.134-1.637c-0.246-0.51-1.18-0.752-1.853-0.975
				c-0.683-0.227-1.483,0.274-2.153-0.262"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M569.16,595c-3.622-4.585-8.213-7.45-14.161-7.656c-4.538-0.158-8.778,1.341-12.934,2.924c-1.133,0.432-2.338,1.264-2.905,2.572"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M515.4,624.52c-3.263-2.226-7.145-2.425-10.811-2.777c-6.444-0.621-12.95,0.378-19.43-0.103"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M222.12,653.08c4.891-4.233,10.773-5.915,17.042-6.195c7.543-0.336,14.99,0.098,21.609,4.498c3.724,2.477,7.44,4.924,10.068,8.657"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M254.52,487.48c-4.3,0.84-1.943,3.985-2.16,6.322c-2.028-0.682-3.708-1.949-5.52-2.963c-2.294-1.285-4.514-2.72-7.198-3.136
				c-1.113-0.172-1.807-0.934-2.429-1.887c-1.188-1.82-2.692-3.446-3.776-5.319c-1.6-2.763-3.729-4.233-6.999-3.829
				c-1.079,0.134-2.005-0.32-2.889-0.934c-0.639-0.443-1.015-0.702-0.762-1.708c0.932-3.718-0.14-5.752-4.044-6.635
				c-4.509-1.019-8.254-3.352-11.682-6.148c-2.086-1.701-4.057-3.271-6.783-3.508c-2.177-0.188-2.288-1.294-1.433-2.893
				c0.89-1.665,0.517-2.997-0.966-4.084"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M447,310.12c-3.25,4.464-6.287,9.066-10.526,12.758c-4.465,3.889-9.24,7.198-14.663,9.586c-0.867,0.381-1.492,0.188-2.171-0.264"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M260.76,435.88c-0.247,1.36-0.806,2.59-0.093,4.123c0.525,1.128-0.866,1.58-1.828,1.611c-3.287,0.107-6.625,0.266-9.81-0.566
				c-0.961-0.252-1.634-0.388-1.904,0.368c-1.002,2.811-3.504,3.242-5.814,3.508c-2.994,0.346-6.085,0.298-8.631-1.844"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M397.8,709.72c-1.431-0.802-3.07-0.566-4.563-0.64c-3.702-0.184-5.57-3.391-8.586-4.752c-7.338-3.314-14.541-5.004-22.122-1.072
				c-0.7,0.363-1.288,0.944-1.929,1.424"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M387.48,591.88c-3.32-1.664-6.649-3.314-10.321-4.075c-7.219-1.495-14.115-0.281-20.891,2.365c-3.37,1.315-5.818,3.73-8.102,6.312
				c-1.271,1.437-2.469,2.376-4.353,1.604c-0.599-0.245-0.774,0.393-1.215,0.274"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M327.48,694.84c7.381,3.215,14.266,7.346,21.093,11.563c1.463,0.903,2.928,2.157,3.776,3.609c0.957,1.637,1.873,1.525,3.212,0.911
				c0.924-0.425,1.916-0.198,2.879-0.244"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M182.28,743.56c2.615-3.366,4.73-7.032,6.779-10.768c1.195-2.178,2.79-4.228,4.566-5.966c1.252-1.225,3.316,0.478,3.489,3.057
				c0.126,1.888,0.117,3.699-1.081,5.564c-3.684,5.738-5.43,12.246-6.535,18.916c-0.485,2.93-0.999,5.881-0.499,8.877"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M388.44,635.08c10.893-0.056,20.939,2.746,30,8.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M31.32,643.72c3.95,0.654,7.887,1.266,11.779,2.331c6.255,1.712,11.381,5.217,16.303,9.187c0.623,0.501,1.277,0.963,1.917,1.442"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M150.12,270.76c-0.188,0.997,0.122,1.749,0.667,2.671c6.009,10.148,13.597,19.061,21.39,27.829c0.845,0.951,2.024,1.808,1.942,3.34
				"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M473.4,591.4c-8.925-4.364-21.069-2.168-28.364,4.994c-0.733,0.721-1.024,2.625-2.836,1.726"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M153.72,642.28c-9.463-2.469-18.728-1.6-27.832,1.702c-0.657,0.238-1.564,0.064-1.928,0.938"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M187.32,713.8c0.374,2.454-0.807,4.64-1.213,6.958c-1.042,5.934-1.801,11.923-3.347,17.762c-1.127,4.261-1.535,8.711-3.538,12.751
				c-0.358,0.723-0.739,1.325-1.262,1.89"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M391.08,697c3.611-1.8,7.421-1.395,11.282-1.254c7.379,0.27,14.589,1.349,21.591,3.674c2.86,0.95,5.865,1.574,8.407,3.34"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M521.16,445.96c-0.801-0.12-1.465-0.829-2.429-0.034c-2.058,1.697-4.829,2.083-7.227,2.701c-2.47,0.639-5.232,0.781-7.868,1.144
				c-1.354,0.187-2.66,0.784-4.079,0.526c-0.219-0.04-0.637,0.082-0.694,0.232c-1.203,3.147-3.945,2.174-6.261,2.415
				c-0.849,0.088-2.104-0.661-2.405,0.936c-0.243,1.284,0.483,2.269,1.461,2.582c2.669,0.857,4.586,2.733,6.719,4.355
				c1.457,1.107,2.902,2.419,5.026,1.958c0.672-0.146,1.031,0.29,1.206,0.942c0.343,1.289,1.3,1.231,2.133,0.695
				c1.455-0.936,2.425-0.272,3.378,0.747"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M495.24,210.04c0.436,2.789,1.756,5.229,3.393,7.415c4.362,5.824,1.632,13.718-1.974,17.767c-1.151,1.293-2.667,1.65-4.299,0.738"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M249.72,607.72c-4.492-2.773-9.502-2.97-14.38-2.053c-5.09,0.958-10.232,1.934-15.14,3.733"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M449.88,294.04c-0.07,0.801,0.318,1.513-0.191,2.427c-3.032,5.447-3.044,11.171-1.29,17.025c0.114,0.384,0.45,0.791,0.083,1.451
				c-6.354,11.43-15.093,20.016-27.859,24.047c-1.364,0.431-2.422,0.374-3.622-0.07"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,751.48c-4.318-2.216-8.833-2.058-13.443-1.219c-3.711,0.675-7.18,1.773-10.11,4.3c-0.557,0.479-1.384,0.639-2.369,0.607
				c-6.648-0.21-13.159,0.735-19.438,3.031"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M585,739.24c-5.536-0.134-11.074-0.006-16.555,0.762c-4.365,0.611-8.625,1.763-12.485,4.038"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M425.64,660.76c3.492-3.815,8.381-4.833,12.958-6.485c6.618-2.387,13.477-3.524,20.386-2.303
				c7.605,1.346,15.028,3.545,21.377,8.309"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M390.84,734.2c0.602-2.963,0.388-6.21,3.099-8.427c0.467-0.381,0.796-1.092,1.452-0.901c1.055,0.307,0.45,0.887,0.333,1.66
				c-1.284,8.466-3.139,16.814-4.777,25.208c-0.646,3.316-1.668,6.433-2.735,9.584c-0.389,1.149-0.421,2.496-1.931,2.876"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M67.08,596.44c-2.256,1.493-4.061,1.257-5.762-0.719c-5.684-6.603-13.08-9.428-21.598-9.854c-4.122-0.207-8.172-0.145-12.015,1.664
				c-0.458,0.215-1.106,0.028-1.666,0.028"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M224.04,592.6c-6.14-2.297-12.43-2.895-18.969-2.252c-3.063,0.302-6.191-0.507-9.356-0.204c-4.534,0.436-8.917,1.205-12.955,3.416"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M95.64,689.32c-1.828,0.366-3.752-1.502-5.616,0.375c4.659,1.803,6.712,5.029,6.452,10.19c-0.192,3.811,0.629,7.681,1.101,11.513
				c0.383,3.112,0.048,6.271,0.689,9.364c0.123,0.592-0.285,1.048-0.706,1.438"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M105.96,673.96c-4.535-1.403-9.254-1.59-13.923-1.863c-5.369-0.314-10.754-0.06-16.08,0.887c-0.296,0.052-0.637-0.144-0.957-0.224"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M420.36,676.36c-6.586-1.898-13.298-2.916-20.16-2.882c-5.125,0.025-10.26-0.282-15.359,0.486
				c-1.365,0.206-2.804,0.269-4.058,0.772c-1.29,0.519-1.386-0.273-1.703-1.018"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M469.8,609.64c-5.29-1.228-10.615-2.087-16.08-1.938c-4.355,0.118-8.521,1.22-12.72,2.178"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M462.6,294.28c-3.25,3.468-5.535,7.548-5.917,12.247c-0.534,6.576,2.36,11.458,8.558,14.154"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M455.16,377.8c-5.49-0.833-10.644,0.683-15.569,2.714c-4.388,1.809-8.439,4.358-11.798,7.839c-0.321,0.333-0.557,0.88-1.193,0.727"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M491.4,204.28c-1.991-1.164-3.564-1.16-4.836,1.18c-1.695,3.119-3.651,6.095-5.482,9.141c-1.246,2.073-1.556,4.499-0.385,6.419
				c1.581,2.593,1.52,5.057,0.862,7.739"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M562.2,562.12c1.439,3.973,2.312,8.13,4.01,12.03c0.378,0.867,0.776,1.813,1.509,1.807c1.23-0.013,0.775-1.269,0.987-2.07
				c1.152-4.355,1.273-9.021,3.813-12.967"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M578.04,549.16c1.064-2.286,1.605-4.393,0.372-7.022c-0.887-1.891-0.79-4.381,0.584-6.421c0.419-0.622,0.492-1.367-0.019-1.896
				c-0.777-0.806-1.443,0.128-1.863,0.498c-1.904,1.678-3.209,3.871-4.556,5.985c-0.777,1.22-1.4,2.453-2.919,2.855"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M194.76,654.28c-3.824-3.19-8.417-3.198-12.955-2.824c-4.745,0.391-9.323,1.473-13.013,4.933c-0.738,0.692-1.1,1.609-1.872,2.211"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M319.56,333.88c-1,2.906,0.319,5.621,0.74,8.397c0.954,6.293,2.541,12.442,4.565,18.474c0.208,0.622,0.366,1.128-0.025,1.688"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M423.72,704.2c-3.045-2.056-6.583-2.521-10.08-3.119c-6.066-1.037-12.147-1.085-18.239-0.241"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M390.84,754.6c1.551-0.2,1.569-1.674,2.145-2.649c1.856-3.145,4.05-6.062,5.412-9.545c0.696-1.779,2.371-3.461,4.035-4.49
				c1.406-0.869,2.795,0.484,2.994,2.052c0.275,2.166,0.578,4.475-0.684,6.462c-3.833,6.038-5.51,12.853-6.824,19.714
				c-0.453,2.366-0.925,4.906-0.598,7.417"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M182.76,718.36c0.033,7.433-0.204,14.85-3.166,21.82c-0.908,2.136-0.821,4.61-2.354,6.499"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M318.84,659.08c-2.268-3.844-6.073-5.337-10.091-6.188c-4.672-0.989-9.434-1.923-14.146-0.046
				c-1.204,0.479-2.517,0.795-3.363,1.913"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M261.72,677.08c-3.367-2.955-7.596-3.464-11.764-4.053c-2.218-0.313-4.474-0.385-6.716-0.501c-11.437-0.593-21.01,3.4-28.8,11.754"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M491.4,320.92c-0.892,4.77-2.958,9.147-4.552,13.683c-2.121,6.036-5.29,11.604-7.745,17.494c-0.746,1.79-2.923,2.224-4.531,3.091
				c-1.083,0.583-1.88,2.329-3.572,1.252"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M309.24,478.84c2.278,1.297,0.285,3.046,0.421,4.565c0.048,0.527-0.574,1.119-0.902,1.674c-0.982,1.664-1.336,3.257,1.206,3.562
				c2.981,0.358,1.724,2.92,1.315,3.563c-3.441,5.415-7.396,10.439-13.092,13.735c-0.464,0.269-1.03,1.014-1.657,0.252
				c-0.592-0.72,0.229-1.188,0.499-1.654c1.534-2.651,3.863-4.787,5.025-7.691c0.049-0.124,0.429-0.145,0.447-0.249
				c0.189-1.132,1.463-2.42,0.654-3.276c-1.006-1.062-2.059,0.359-3.034,0.882c-3.191,1.713-6.07,3.955-9.815,4.69
				c-1.327,0.261-2.621-0.093-3.866,0.348"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M568.92,500.68c-0.154,1.527-0.439,3.036-1.694,4.063c-1.025,0.84,0.497,2.763-1.426,3.137c0.192,2.812-2.283,4.395-3.092,6.729
				c-0.479,1.383-1.024,2.743-2.226,3.533c-0.646,0.425-0.995,1.006-1.339,1.539c-0.215,0.331-0.295,1.104,0.165,1.648
				c0.53,0.629,1.126,0.097,1.424-0.062c1.109-0.591,2.142-1.344,3.141-2.117c3.146-2.432,5.42-5.903,9.127-7.671"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M441,693.4c10.58,5.988,19.346,14.051,26.88,23.52"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M331.56,404.92c0.616,2.893,2.495,5.154,3.85,7.676c3.258,6.067,6.398,12.189,8.583,18.74c0.22,0.659,0.551,0.762,1.008,0.944"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M78.84,734.2c0.703,5.451,0.546,10.873-0.2,16.325c-0.741,5.415-1.17,10.873-1.699,16.316c-0.042,0.441-0.212,0.702-0.5,0.958"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M506.76,589.72c-5.532-3.974-11.804-4.141-18.241-3.619c-3.241,0.263-6.223,1.282-9.118,2.659"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M221.16,695.08c-1.774,4.067-2.11,8.51-3.306,12.735c-1.74,6.144-3.393,12.303-5.805,18.229c-0.302,0.742-0.396,1.607-1.449,1.676"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M389.88,600.04c-9.147,1.863-17.38,5.637-24.477,11.764c-1.544,1.333-3.043,2.717-4.563,4.076"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M121.08,619.48c-4.447,0.292-8.217,2.348-11.469,5.101c-3.668,3.105-6.71,6.866-8.421,11.47c-0.56,1.507-1.528,1.723-2.911,1.687
				c-9.34-0.25-17.863,2.375-25.679,7.423"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M565.56,414.52c-0.052,0.883-0.003,1.754-0.195,2.649c-0.224,1.038-0.242,2.325,0.944,2.798c0.505,0.201,0.755,0.477,0.907,0.805
				c0.489,1.052-0.917,2.441,0.509,3.341c3.064,1.934,5.925,4.267,9.596,5.046c1.918,0.408,3.866,0.705,5.754,1.223
				c3.51,0.962,6.082,3.324,8.397,5.986c1.496,1.72,3.255,1.758,5.3,1.236c1.159-0.296,0.574-0.926,0.589-1.483"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M104.28,502.6c-2.525,0.878-3.879,2.885-5.343,4.996c-2.356,3.399-8.489,4.725-12.674,3.719c-4.078-0.98-8.302-1.357-12.463-1.991
				c-0.797-0.121-1.287,0.153-1.711,0.939c-0.937,1.735-2.048,3.377-3.088,5.058"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M347.64,596.68c-7.67-7.251-18.001-9.367-26.594-4.716c-0.742,0.401-0.846-0.21-1.246-0.084c-0.267-1.052-1.589-1.165-1.92-2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M332.04,445c-2.001,0.154-3.992-0.12-6.03,0.349c-0.906,0.208-1.072,0.97-1.878,1.146c-2.044,0.449-3.73,1.811-6.003,1.986
				c-1.84,0.143-3.951,0.88-4.792,3.005c-0.735,1.857-2.106,2.218-3.856,1.913c-1.36-0.236-2.721-0.479-4.081-0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M451.08,320.44c-3.697,4.901-7.216,9.929-11.979,13.944c-6.655,5.609-14.192,9.102-22.81,10.372
				c-0.645,0.095-1.131-0.344-1.691-0.076"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M283.8,593.8c-1.455-1.889-3.719-2.221-5.771-2.845c-3.2-0.975-6.476-2.434-9.812-1.857c-4.358,0.753-8.108,2.947-10.577,6.862"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M425.4,677.56c5.4,1.866,10.809,3.712,15.841,6.479c0.47,0.258,0.98,0.446,1.435,0.728c2.169,1.347,4.373,3.85,6.471,3.759
				c2.142-0.093,4.174-2.725,6.254-4.244"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M284.64,412.84c-0.52-1.76-1.041-3.52-1.56-5.28"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M280.68,403c-2.007-1.193-3.278-3.122-4.8-4.8c-5.576-6.147-11.646-11.753-18.211-16.837c-0.863-0.668-1.643-1.005-2.668-0.923"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M421.32,100.12c-0.125,2.238-0.628,4.417-0.293,6.728c0.739,5.095,3.091,10.06,9.176,11.257c7.31,1.438,15.31-4.371,13.911-11.744
				c-0.446-2.353-4.03-4.321-6.475-3.601c-1.951,0.576-3.01,2.799-2.399,5.04c0.766,2.811,2.719,3.83,5.52,2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M196.2,676.12c-2.32-0.16-4.659-0.19-6.954-0.525c-1.592-0.231-2.167,0.358-2.957,1.684c-1.884,3.161-2.227,6.699-3.037,10.124
				c-1.624,6.87-0.214,13.758,0.017,20.637c0.011,0.328,0.451,0.641,0.69,0.961"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M447.24,301.72c-2.282,1.83-3.437,4.544-5.204,6.78c-4.587,5.799-9.978,10.577-16.107,14.653c-1.141,0.758-1.648,0.467-2.448-0.073
				"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M363.72,490.6c-2.007-2.911-3.771-6.023-6.811-8.286c-1.29,1.087-0.312,2.144,0.123,2.99c2.054,3.993,4.094,8.005,6.922,11.541
				c0.606,0.759,1.375,1.736,0.667,2.587c-0.666,0.8-1.849,0.239-2.553-0.233c-1.434-0.963-3.193-1.155-4.572-2.141
				c-0.509-0.363-1.124-0.351-1.433-0.176c-1.705,0.961-3.229,0.298-4.836-0.244c-0.566-0.191-1.48-0.303-1.654,0.685
				c-0.272,1.551-1.734,2.399-2.173,3.838"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M336.6,486.52c-1.714,0.846-3.262,1.656-3.918,3.816c-0.719,2.366-2.929,3.419-5.203,4.1c-3.116,0.934-5.289,2.613-6.365,5.964
				c-0.534,1.665,0.145,2.02,1.078,1.896c3.601-0.476,6.996,1.222,10.573,0.829c0.503-0.056,0.898,0.037,1.462-0.227
				c2.113-0.991,4.097-0.618,5.733,1.143"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M577.8,673.96c-8.821-3.462-17.535-2.92-26.153,0.735c-0.29,0.122-0.486,0.465-0.727,0.705"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M156.84,637.96c-2.356,3.409-4.368,6.95-4.225,11.305c-19.468-4.958-41.245,3.703-47.855,20.136"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M391.56,721.96c-8.761-3.027-17.691-4.161-26.88-2.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M105.48,45.16c-1.992,0.552-3.7-0.66-5.5-1.054c-6.343-1.391-10.442,0.849-12.5,6.094c-1.115,2.84-0.47,6.272,1.439,7.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M448.68,339.4c4.086,3.037,8.244,5.946,13.199,7.445c0.41,0.124,0.982,0.198,1.173,0.493c2.045,3.171,4.047,6.371,6.015,9.59
				c0.471,0.771-0.088,1.273-0.706,1.672c-2.48,1.599-4.975,3.178-7.431,4.814c-0.648,0.432-1.418,0.454-1.907,0.185
				c-2.956-1.621-6.399-1.774-9.402-3.28c-1.938-0.971-3.872,0.378-5.722,0.768c-8.546,1.798-16.193,5.727-23.281,10.737
				c-3.026,2.14-2.92,2.703,0.223,5.495"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M87.48,735.64c-0.619,2.686-1.27,5.385-0.943,8.158c0.637,5.42-0.724,10.644-1.806,15.819c-0.936,4.473-1.578,8.925-1.811,13.463"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M81,612.76c-0.617-0.927-1.023-1.967-1.609-2.924c-0.92-1.501-1.55-2.279-3.689-2.049c-1.831,0.196-3.85-1.034-5.736-1.762
				c-4.333-1.671-8.748-2.831-13.446-2.636c-1.509,0.062-2.979,0.047-4.345,0.932c-0.569,0.369-1.415,0.512-2.135,0.039"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M528.84,471.16c-1.377,2.251-3.583,3.531-5.778,4.767c-0.979,0.551-1.273,1.416-0.956,2.147c0.154,0.359,1.385,0.585,2.182,0.058
				c0.959-0.635,3.157,0.486,2.737-2.188c-0.116-0.739,1.327-0.345,2.056-0.46c1.11-0.176,1.697,0.81,1.604,1.426
				c-0.27,1.792,0.514,3.273,1.073,4.797c0.543,1.476-0.334,1.714-1.22,1.402c-1.814-0.64-3.707-0.904-5.519-1.453
				c-1.273-0.386-1.262,0.162-1.477,1.021c-0.823,3.289,0.298,5.654,3.122,7.467c3.42,2.195,6.237,5.266,10.312,6.531
				c1.755,0.545,3.24,1.995,4.305,3.553c1.098,1.607,2.738,1.961,4.336,2.665c3.306,1.456,6.9,2.304,9.863,4.509"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M297.48,466.36c2.598,0.479,4.05-1.658,5.983-2.671c0.713-0.373,1.26-1.591,2.131-0.646c0.655,0.71,0.325,1.696-0.171,2.609
				c-2.161,3.975-5.182,7.229-8.427,10.304c-0.787,0.746-1.974,1.794-1.566,2.587c0.73,1.42,2.17,0.282,3.242,0.024
				c2.824-0.678,5.102-2.478,7.408-4.104c0.917-0.647,1.826-0.922,2.655-0.601c0.995,0.387,0.506,1.543,0.477,2.336
				c-0.144,3.977-2.361,7.015-5.06,9.55c-3.461,3.252-7.805,4.996-12.419,6.102c-0.755,0.18-1.457,0.164-2.172,0.172
				c-3.728,0.043-4.034-0.366-2.356-3.56c0.507-0.964,0.708-1.855,0.15-2.629c-0.499-0.69-1.382-0.157-2.115-0.048
				c-3.045,0.455-5.956,1.534-9.119,1.509c-3.479-0.028-6.578-0.709-9.386-2.9c-2.268-1.77-4.773-3.234-7.173-4.835"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M580.92,621.64c-8.956-1.775-17.706-1.299-26.16,2.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M438.6,436.6c1.188,1.138,2.551,2.146-0.22,3.174c-1.717,0.638-1.796,2.437-1.677,4.024c0.114,1.516-0.086,2.517-1.939,2.683
				c-1.729,0.155-2.584,1.177-2.975,3.061c-0.479,2.314-1.452,4.495-4.211,5.356c-2.26,0.705-2.024,2.857-1.933,4.742
				c0.039,0.785,0.722,0.812,1.187,0.691c1.223-0.315,2.587-0.52,3.573-1.221c2.479-1.761,5.312-3.006,7.488-5.217
				c1.527-1.552,2.051-4.647,0.946-6.493"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M151.08,595c-1.727-2.894-4.732-4.192-7.478-5.691c-1.846-1.008-4.074-1.065-6.202-0.546c-4.223,1.03-8.58,1.689-12.24,4.317"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M523.8,463c1.421-1.407,2.478,0.008,3.051,0.772c1.337,1.787,3.362,2.214,5.128,2.444c1.735,0.228,2.369,1.058,2.84,2.312
				c0.945,2.521,2.677,4.156,5.309,4.495c1.512,0.195,2.616,0.793,3.319,1.996c0.589,1.01,1.792,1.308,2.435,1.312
				c2.996,0.018,6.102,1.198,8.835,0.621c3.147-0.664,3.12,1.218,3.884,2.848"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M402.6,718.6c8.483,4.595,17.102,8.984,22.858,17.24c0.676,0.97,0.719,2.317,2.082,2.961c3.712,1.754,4.07,1.736,6.739-1.722
				c4.144-5.367,9.44-9.386,15.121-12.959"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M159,633.88c-7.717-3.643-16.01-3.918-24.233-3.476c-5.915,0.318-11.948,0.956-17.312,4.154c-2.113,1.26-3.749,3.019-5.393,4.701
				c-0.705,0.721-1.145,0.483-1.528,0.342c-3.187-1.177-6.521-1.514-9.854-1.882"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M304.68,396.52c10.66,10.435,18.113,22.912,23.586,36.694c0.544,1.368,1.072,2.812,1.854,4.106"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M236.52,209.08c-0.641,4.155,0.703,7.543,3.829,10.333c2.704,2.413,5.739,2.504,8.663,0.242c2.102-1.627,3.655-3.641,4.789-6.014"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M167.64,748.12c-2.312,3.989-3.378,8.474-5.008,12.732c-1.355,3.54-2.428,7.188-3.635,10.786c-0.227,0.676-0.315,1.465-1.197,1.682
				"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M104.76,351.16c-1.086,0.264-2.146-0.191-2.841-0.767c-2.762-2.291-6.084-3.876-8.439-6.673"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M90.6,342.04c-3.376-1.49-6.291-3.721-9.087-6.04c-3.65-3.028-4.468-7.826-6.725-11.736c-1.77-3.066-2.678-6.569-4.588-9.583"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M278.52,453.64c0.881,2.041-0.294,3.003-1.906,3.866c-0.948,0.509-2.278,0.562-2.708,2.083c2.254,2.295,4.973,1.193,7.481,0.709
				c1.585-0.306,3.02-1.326,4.589-1.805c0.627-0.191,1.42-0.225,2.182,0.38c1.043,0.829,0.132,1.523-0.102,1.931
				c-0.713,1.241-1.209,2.643-2.32,3.653c-0.367,0.335-1.208,0.623-0.505,1.431c0.448,0.515,1.121,0.356,1.428,0.193
				c5.415-2.88,11.103-5.385,15.132-10.288c1.34-1.631,1.045-2.203-1.431-3.113"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M293.4,631.24c-4.553,0.349-8.105,2.975-11.743,5.306c-5.09,3.263-8.874,7.746-11.537,13.174"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M167.4,557.32c-1.999,1.691-4.274,0.931-6.479,0.715c-3.83-0.374-7.369-1.883-11.049-2.84c-1.664-0.433-3.375-0.639-5.059,0.399
				c-1.047,0.646-1.258,1.402-1.172,2.445"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M257.16,511.96c-1.36-0.08-2.722-0.245-4.08-0.216c-1.356,0.029-1.898,0.725-1.454,2.14c0.992,3.168,3.113,5.561,5.298,7.911
				c1.927,2.074,4.138,3.878,5.773,6.232c0.542,0.78,0.437,1.586,0.462,2.413c-0.917-0.15-1.218,1.104-2.422,0.807
				c-1.626-0.402-3.252,0.415-4.884,0.997c0.879,1.699,2.361,2.663,3.456,3.964c0.991,1.178,2.306,2.566,1.825,4.038
				c-0.61,1.867-2.765,1.867-4.459,2.17c-0.783,0.141-1.611,0.024-2.42,0.024c0.025,1.381,1.328,1.812,1.464,2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M299.64,402.04c7.31,7.468,13.231,15.892,17.519,25.44c1.498,3.335,3.406,6.475,4.385,10.062c0.382,1.396,1.965,1.716,3.288,1.423
				c2.745-0.607,5.443-1.424,8.17-2.118c1.371-0.349,2.775-0.69,3.838,0.712"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M449.88,602.2c-3.672,0.334-7.07,1.807-11.089,0.648c-5.256-1.515-10.48,0.243-15.303,2.729c-1.019,0.524-1.929,1.263-2.889,1.903"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M305.4,277.72c-0.696-0.192-1.084,0.278-1.434,0.725c-7.872,10.052-14.979,20.524-18.267,33.11
				c-0.157,0.603-0.619,1.125-0.939,1.686"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M21,534.52c-0.828-0.006-1.664-0.04-0.966,1.204c0.402,0.718,0.743,1.469,1.126,2.236c0,0-0.1,0.068-0.157,0.162
				c-0.648,1.058-2.84,0.817-2.551,2.381c0.269,1.455,1.994,1.631,3.245,2.226c3.447,1.641,7.247,1.605,10.836,2.543
				c1.831,0.478,3.574,0.896,5.041,2.193c1.697,1.501,3.999,1.091,5.987,1.695"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M222.84,534.28c-1.466,1.889-3.82,1.648-5.761,2.396c-0.704,0.271-2.591-0.754-1.545,1.494c0.086,0.187-1.349,0.515-2.293,0.518
				c-3.492,0.012-6.804-1.44-10.324-1.078c-1.079,0.11-2.171,0.19-2.121,1.469c0.168,4.28-3.291,4.602-6.03,5.773
				c-1.554,0.665-3.29,0.709-4.797,0.597c-3.254-0.241-4.876,2.807-7.686,3.248c-0.259,0.04-0.699,0.315-0.691,0.462
				c0.217,4.013-3.303,3.635-5.512,4.872c-0.555,0.311-3.242,0.034-1.27,2.119c0.006,0.006-0.412,0.593-0.696,0.668
				c-2.042,0.54-4.072,1.731-6.227,0.491c-2.026-1.165-4.396-0.592-6.506-1.377c-0.509-0.189-0.801-0.498-1.245-0.811
				c1.319-0.879,2.611-1.739,3.904-2.602"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M47.4,616.12c0.52,2.956,3.658,2.874,5.393,4.436c0.34,0.306,2.179-0.915,3.231-1.582c0.803-0.509,1.379-1.402,2.207-1.837
				c0.474-0.248,1.311-1.057,1.867-0.044c0.334,0.607,0.143,1.294-0.213,1.909c-0.802,1.389-2.214,2.14-3.397,3.076
				c-2.447,1.937-4.605,4.11-6.161,6.789c-0.641,1.103-1.32,0.799-1.953,0.43c-4.339-2.539-8.975-4.23-13.935-5.008
				c-2.712-0.425-5.446-0.429-8.153,0.023c-0.676,0.113-1.285,0.298-1.925-0.032"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M212.76,658.84c-5.619-3.025-11.631-4.663-17.997-4.27c-7.88,0.486-15.424,2.313-22.601,5.873
				c-5.464,2.709-9.819,6.531-13.624,11.132c-0.677,0.819-1.489,1.649-1.458,2.865"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M62.52,441.64c1.132,0.93,0.672,2.402,0.573,3.345c-0.154,1.465,0.343,1.516,1.347,1.448c2.719-0.182,5.471,0.423,8.173-0.433
				c0.568-0.18,1.546-0.397,1.886,0.448c1.36,3.385,4.371,2.606,6.98,2.881c0.481,0.052,1.031-0.271,1.441,0.23"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M275.64,748.36c2.952,4.434,3.547,9.599,4.57,14.638c0.715,3.521,1.046,7.12,2.149,10.562"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M137.64,660.76c-7.825-0.252-13.734,3.596-18.95,8.891c-0.874,0.888-1.851,1.797-2.364,2.892c-1.343,2.863-3.232,3.681-6.047,2.138
				"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M442.2,300.52c-0.058,2.833-2.361,4.343-3.903,6.188c-2.543,3.042-5.751,5.449-8.796,7.998c-0.883,0.739-1.759,0.416-2.661,0.455"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M162.12,676.84c-2.937-2.132-6.472-2.299-9.846-2.842c-6.04-0.973-12.045-0.589-18.012,0.846c-0.844,0.203-1.478,0.903-2.382,0.796
				"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M584.04,773.56c-7.594-2.389-15.229-3.844-23.036-0.95c-0.667,0.247-1.284,0.631-1.924,0.95"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M568.44,87.88c2.954-0.555,8.689,2.356,9.83,5.044c0.82,1.936-0.334,3.252-1.687,4.295c-3.541,2.731-7.506,3.827-11.68,1.808
				c-0.758-0.367-1.807,0.736-2.104-0.587"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M296.28,745.96c0.24-3.12,0.549-6.236,0.691-9.361c0.07-1.535,0.319-2.517,1.897-3.447c3.49-2.061,5.548-5.877,8.763-8.214
				c3.705-2.693,4.479-6.741,6.172-10.416c0.462-1.005-0.057-2.847,1.913-2.605c1.419,0.174,2.873,3.092,2.396,4.521
				c-0.192,0.576-0.418,1.153-0.721,1.678c-2.875,4.98-2.986,10.655-4.071,16.085c-0.72,3.6-1.52,7.187-2.129,10.805
				c-0.341,2.018-0.82,4.088-0.966,6.016c-0.093,1.224,0.16,3.423,1.414,4.779"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M174.6,601.96c8.526,0.677,16.624,2.528,23.301,8.375c0.312,0.273,0.938,0.185,1.418,0.265"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M430.44,476.2c-3.225,1.722-6.902,1.683-10.348,2.529c-1.604,0.395-2.936,1.512-4.778,1.412c-1.206-0.066-1.968-0.205-2.389-1.304
				c-0.96-2.511-2.724-3.134-5.292-2.673c-5.565,0.999-6.896,2.107-8.095,7.967c-0.276,1.352-1.195,1.262-1.977,1.673
				c-2.574,1.355-2.778,2.211-1.979,5.29c0.309,1.187,0.298,2.575-0.89,3.602c-1.213,1.047-2.012,2.526-1.866,4.058
				c0.205,2.161-0.857,2.4-2.466,2.155c-2.128-0.324-4.148-0.292-6.272,0.398c-1.281,0.416-2.497,0.435-2.856,2.011
				c-0.256,1.119-1.545,0.979-2.153,1.682"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M534.36,592.36c-3.076-1.038-6.041-2.263-9.348-2.73c-5.934-0.841-11.207,0.373-16.093,3.69"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M156.24,708.52c0.45-1.741-0.046-3.677,1.08-5.279"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M155.64,695.8c-1.276-0.543-1.595-1.812-2.154-2.883c-1.821-3.495-4.087-6.652-6.966-9.357c-0.743-0.697-1.471-1.435-2.64-0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M48.36,676.36c-4.228-1.748-8.64-2.463-13.2-2.404c-4.433,0.057-8.919-0.653-13.219,1.16c-0.636,0.269-1.142,0.613-1.662,1.004"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M87.96,703.24c-2.4,0.2-1.738,2.097-1.624,3.354c0.271,2.981,1.141,5.879,1.421,8.882c0.204,2.179,0.759,4.324,1.184,6.627
				c0.866-0.133,1.638-1.145,2.625-0.393c0.813,0.62,1.083,1.566,1.148,2.412c0.216,2.784,0.008,5.527-1.094,8.183
				c-0.257,0.618-0.637,1.24-0.688,2.126c-0.109,1.896-1.171,2.059-2.733,0.729"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M369.24,416.68c2.891,2.866,6.56,4.347,10.332,5.479c4.369,1.312,8.526,2.978,11.717,6.31c2.815,2.939,6.505,4.005,10.132,5.187
				c1.206,0.393,2.312,1.067,3.579,1.265"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M84.36,247c2.303,5.405,4.343,10.937,7.68,15.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M443.88,429.16c-2.919,0.338-4.972,2.197-6.946,4.094c-4.577,4.394-10.003,6.657-16.334,6.691
				c-2.165,0.012-5.557-4.764-4.753-6.686c0.157-0.376,0.917-0.494,1.393-0.74c1.39-0.722,3.511-0.404,3.63-2.878
				c0.123-2.54-1.472-3.629-3.389-4.562"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M458.76,366.04c-3.36,0.036-6.682-0.419-10.093,0.167c-6.974,1.198-13.472,3.492-19.409,7.3c-2.108,1.352-3.983,3.062-6.03,4.516
				c-1.329,0.943-1.304,1.909-0.476,3.143c2.777,4.141,3.927,8.863,4.881,13.811c2.658-1.14,4.473-3.455,6.848-4.997
				c5.462-3.545,11.349-5.618,17.801-6.163c0.55-0.046,1.118,0.143,1.679,0.223"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M194.76,701.32c1.414-0.507,1.898-2.126,3.344-2.683c1.223-0.471,1.521-0.238,1.471,1.003c-0.098,2.405-0.294,4.814-0.388,7.203
				c-0.154,3.904,0.335,7.84,0.625,11.755c0.267,3.604,0.832,7.199,0.863,10.803c0.016,1.793-1.45,3.056-3.274,3.599"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,557.8c0-2.08-0.169-4.177,0.037-6.236c0.355-3.556,0.223-6.728-4.124-7.653c-0.532-0.113-0.505-0.682-0.953-0.75"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M65.4,740.92c1.119,1.42,0.921,3.19,1.161,4.806c0.746,5.024,1.371,10.068,1.937,15.117c0.285,2.546,0.282,5.122,0.521,7.676
				c0.198,2.12-1.679,3.267-2.179,5.041"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M574.92,451.48c1.154,2.071,3.312,2.545,5.274,3.373c4.302,1.814,9.084,2.244,13.196,4.563c1.71,0.965,3.812,1.62,4.09,4.063"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M314.76,722.44c-3.378,1.693-4.434,4.864-4.232,8.154c0.418,6.813-1.167,13.161-3.431,19.451c-0.591,1.641-0.913,3.377-1.494,5.021
				c-0.18,0.509-0.636,1.148-0.169,1.388c0.5,0.256,1.201,0.148,1.685-0.63c0.854-1.374,1.314-3.008,2.602-4.104"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M390.36,734.32c1.376,0.438,0.97,1.611,0.938,2.519c-0.207,5.837-1.933,11.341-3.823,16.799c-0.54,1.56-0.381,3.419-1.915,4.562"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M356.28,385.72c0.3-1.111,1.122-1.265,2.149-1.485c3.787-0.812,5.528-3.502,5.668-7.159c0.027-0.705,0.133-1.41,0.502-1.6
				c0.396-0.205,1.219,0.092,1.505,0.652c1.993,3.91,6.137,2.298,9.185,3.464c1.852,0.708,3.586,0.857,4.556,2.988
				c2.162,4.744,5.802,7.417,11.235,7.231c2.264-0.078,4.039,1.311,5.45,2.7c1.999,1.966,4.403,2.239,6.79,2.258
				c3.727,0.031,7.401,0.413,11.023,1.113c1.222,0.237,2.257-0.056,3.412-0.103c0.737,1.759-0.735,2.216-1.722,2.892
				c-3.131,2.146-6.506,3.938-9.354,6.489"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M556.68,235.96c-1.044,1.77-2.923,1.581-4.562,1.911c-3.408,0.686-4.392,2.686-2.362,5.504c2.736,3.802,2.024,8.076,2.124,12.266"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M380.04,511.72c-0.74,0.069-1.817-0.712-2.098,0.495c-0.15,0.65-0.091,1.375,0.58,2.207c3.743,4.648,9.006,7.017,14.037,9.705
				c1.014,0.541,1.709,1.222,2.337,2.012c1.636,2.059,3.071,4.228,5.495,5.589c0.78,0.438,1.35,1.854,0.981,3.024
				c-0.443,1.406-1.416,1.355-2.364,0.479c-1.86-1.718-3.928-2.623-6.489-1.91"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M108.6,256.12c-1.88,2.582,0.099,4.652,1.28,6.426c3.347,5.032,6.83,10.003,10.981,14.433c0.813,0.868,1.344,1.705,1.178,2.9"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M583.8,367.48c1.401,2.708,3.449,5.037,4.585,7.91c0.389,0.983,0.821,1.961,0.187,2.869c-0.76,1.086-1.908,0.461-2.846,0.237
				c-2.53-0.605-4.584-2.451-7.206-2.856"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M465.48,725.56c-3.138,1.49-6.686,1.65-9.849,3.103c-6.218,2.856-11.938,6.388-16.566,11.524c-0.601,0.666-1.034,1.395-1.426,2.174
				"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M418.44,692.44c-4.48-0.801-8.945-1.698-13.444-2.377c-6.408-0.968-12.71,0.056-18.945,1.463c-1.034,0.233-1.931,1.073-2.891,1.634
				"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M572.52,663.16c4.614,0.086,8.416,2.463,12.208,4.618c3.82,2.171,6.973,5.311,9.806,8.639c1.218,1.432,1.947,0.583,2.947,0.183"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M284.28,598.12c1.041,0.399,2.299,0.553,3.08,1.246c2.42,2.148,5.246,2.234,8.201,2.122c7.538-0.288,14.622,1.492,21.359,4.792"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M504.6,592.84c-2.27-1.728-4.936-2.188-7.68-2.4c-5.408-0.418-10.832-0.832-15.819,1.958c-0.821,0.459-1.146-0.166-1.7-0.277"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M455.4,645.4c-2.427,0.736-4.97,0.564-7.44,0.958c-7.913,1.261-14.85,4.585-20.884,9.837c-0.771,0.672-1.596,1.285-2.396,1.925"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M108.36,713.32c1.037-1.68-0.25-2.845-0.989-4.062c-0.559-0.921-1.434-2.019-0.852-2.842c0.902-1.276,2.278-0.33,3.26,0.221
				c2.068,1.159,4.009,2.541,6.03,3.788c0.408,0.251,0.729,0.554,0.693,0.974c-0.302,3.562,1.397,7.255,0.566,10.518
				c-1.936,7.602,0.275,14.158,3.548,20.675c0.657,1.309,1.204,2.65,2.079,3.895c1.662,2.361,3.211,4.811,0.414,7.739
				c-0.797,0.835-0.528,2.98-0.274,4.442c0.235,1.36-0.056,2.46-0.556,3.612c-1.598,3.682-3.199,7.36-4.799,11.041"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M442.2,414.04c-0.925-0.463-1.724-0.292-2.4,0.479c-5.124,5.85-7.979,12.661-8.627,20.401c-0.02,0.231,0.147,0.479,0.228,0.719"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M454.2,324.76c-3.942,5.293-7.875,10.605-13.206,14.633c-7.715,5.829-16.323,9.596-25.912,11.061
				c-0.895,0.137-1.582-0.436-2.402-0.493"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M362.52,462.28c-0.741,3.966-0.96,7.987-1.176,12.001c-0.238,4.41,0.461,8.651,2.301,12.753c0.697,1.552,1.964,3.485,0.075,5.246"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M94.68,618.76c-0.619,0.615-1.408,0.498-2.16,0.476c-7.832-0.239-14.617,2.798-21.12,6.725"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M368.28,655.24c-8.379-4.909-17.459-7.077-27.119-6.681c-3.761,0.153-7.578,0.993-10.744,2.934
				c-5.044,3.091-9.938,6.509-13.977,10.947"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M404.28,603.4c-7.384-1.085-14.667-0.574-21.827,1.486c-0.512,0.146-0.979,0.075-1.453,0.193"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M189,675.88c1.405,2.948,0.723,6.04,0.806,9.118c0.149,5.48,2.644,10.445,4.304,15.579c0.172,0.534,0.727,1.507,0.595,1.652
				c-2.285,2.51-1.021,5.658-1.597,8.456c-1.242,6.028-2.625,12.021-4.027,18.014c-0.139,0.592-0.255,1.777,0.64,2.381"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M351.72,601.96c-4.511,0.31-9.065,0.399-13.432,1.709c-3.243,0.974-6.408,2.211-9.608,3.331"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M182.04,689.08c-1.134-1.84-0.816-4.062-1.591-6.035c-0.357-0.907-0.509-1.753-1.52-1.589c-0.831,0.135-1.358,0.914-1.455,1.863
				c-0.237,2.331-1.024,4.592-0.939,6.96c0.03,0.845-0.469,1.449-0.735,2.161"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M175.56,692.68c0.232,2.891,0.957,5.688,1.095,8.634c0.207,4.397,1.16,8.77,1.307,13.206c0.021,0.648-0.193,1.407,0.718,1.681"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M142.68,522.76c2.022,2.22,4.934,1.714,7.455,2.339c1.256,0.312,2.258,0.6,2.367,1.982c0.196,2.488,1.987,2.734,3.859,2.87
				c1.961,0.144,2.493,1.174,1.439,2.889c-2,3.253-6.182,4.369-10.953,2.83c1.562-1.555,3.455-1.83,5.433-1.87"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M46.68,698.2c1.813-1.702,1.584-4.153,2.077-6.26c0.916-3.914,0.68-7.981,0.501-11.978c-0.082-1.831-0.544-3.658-0.418-5.522
				c0.048-0.713-0.337-1.675,0.726-1.89c0.826-0.168,1.31,0.65,1.642,1.188c2.039,3.314,3.332,6.909,3.882,10.779
				c0.805,5.666,0.755,11.382,0.373,17.035c-0.369,5.44-1.11,10.865-1.332,16.327c-0.036,0.889-0.177,1.814,0.35,2.639"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M104.76,699.4c-1.366-1.07-2.53-2.501-4.325-2.854c-1.392-0.273-2.557,0.011-2.152,1.894c0.648,3.02,0.919,5.97,2.978,8.744
				c2.557,3.443,3.263,7.938,4.176,12.146c0.802,3.697,1.703,7.447,0.557,11.281c-0.097,0.323,0.286,0.79,0.446,1.189"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M408.6,85.96c-1.923-0.386-2.506-2.299-3.834-3.367c-1.837-1.475-3.727-2.675-6.246-1.917c-3.108,0.937-4.041,3.003-2.369,5.745
				c1.122,1.84,2.594,3.449,4.29,4.819"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M481.8,233.8c-2.534,2.397-4.186,5.536-6.481,8.049c-1.945,2.129-3.832,4.236-5.554,6.565c-7.116,9.62-11.152,20.589-14.37,31.945
				c-0.764,2.693-1.316,5.445-2.063,8.12c-0.294,1.053,0.282,1.641,0.149,2.441"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M528.36,671.56c-1.968-2.098-4.749-2.064-7.209-2.31c-5.626-0.562-10.996,0.234-15.389,4.431c-1.298,1.24-3.294,0.96-5.003,1.239"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M504.12,354.52c0.682,0.91,0.24,1.928,0.218,2.879c-0.144,5.949,0.639,11.844,1.062,17.755c0.081,1.134-0.089,2.097-0.319,3.125"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M436.44,531.4c-1.109-0.791-1.768,0.258-2.365,0.762c-0.955,0.807-1.871,1.369-3.137,1.708c-2.283,0.612-1.715,2.864-1.354,4.223
				c0.757,2.856,0.004,5.144-2.312,6.419c-2.77,1.524-2.647,3.901-2.824,6.329c-0.209,2.876,0.451,5.809-0.493,8.638
				c-0.981,2.938-1.3,3.272-3.114,3.602"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M559.56,661.72c-9.318-0.099-17.1,3.532-23.753,9.848c-1.886,1.791-3.687,3.673-5.526,5.513"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M499.08,708.76c-4.24-1.119-8.46-2.323-12.726-3.339c-5.482-1.306-11.001-2.47-16.555-0.261"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M263.88,692.92c-5.409-4.044-10.624-8.264-16.704-11.462c-4.177-2.197-7.092-0.767-10.412,0.91
				c-5.952,3.006-11.14,7.091-16.084,11.512"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M135.96,684.52c1.933-1.401,4.474-0.949,6.502-2.121c0.336-0.193,0.877,0.09,1.206,0.666c1.751,3.064,2.978,6.33,2.797,9.853
				c-0.209,4.088-0.114,8.22-1.111,12.251c-0.147,0.596-0.167,1.273,0.418,1.937c4.581,5.196,4.054,4.434,1.396,9.78
				c-1.42,2.856-1.737,5.626-1.404,8.679c0.217,1.99-0.042,4.014,0.483,6.005c0.277,1.05-0.071,2.006-0.904,2.894
				c-4.079,4.344-4.25,9.846-4.334,15.338c-0.05,3.279-0.009,6.561-0.009,9.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M357.48,501.64c-1.44,0.653-2.646,1.609-4.423,0.122c-0.847-0.709-2.835,0.134-4.206-0.174c-2.971-0.669-5.154,0.659-7.488,2.155
				c-1.653,1.06-2.891,2.573-3.189,4.162c-0.448,2.381-2.031,2.872-3.744,3.294c-2.143,0.527-4.097,1.112-4.543,3.642
				c-0.355,2.012-2.679,2.272-3.407,4.062c-2.211,5.431-6.112,9.781-9.556,14.42c-1.058,1.424-2.784,3.071-1.791,4.717
				c0.996,1.65,3.105,0.257,4.657,0.016c1.761-0.275,3.438-0.883,5.287-0.966c1.975-0.087,2.815,0.717,3.123,2.47"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M475.08,242.92c0.639,2.353-0.936,4.285-1.245,6.474c-0.643,4.547-1.161,9.088-1.177,13.687c-0.003,0.931,0.623,1.457,0.981,2.16"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M567.48,500.44c-1.36,1.359-2.723,2.718-4.08,4.08c-1.987,1.995-4.465,2.636-7.181,3.214c-4.737,1.01-9.426,2.286-14.151,3.387
				c-1.462,0.341-2.55,2.167-3.877,3.229c-2.482,1.987-5.474,3.34-7.238,6.222c-0.555,0.906-0.574,1.624-0.17,2.417
				c0.277,0.542,0.736,1.014,1.184,1.444c2.73,2.635,6.729,3.862,8.419,7.676c0.407,0.922,1.521,1.033,2.38,1.18
				c2.858,0.489,5.308,1.831,7.675,3.392"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M296.28,443.8c-1.387,2.591-3.588,3.387-6.468,3.705c-3.521,0.39-7.122,0.183-10.589,1.28c-1.476,0.467-1.6,0.943-0.696,1.969
				c1.334,1.514,1.281,1.892-0.731,2.388c-3.513,0.864-6.64-0.006-9.355-2.382"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M487.32,652.6c-2.636-3.797-6.191-6.633-9.848-9.35c-1.798-1.337-3.626-2.644-5.522-3.834c-3.506-2.2-7.454-2.893-11.51-3.136"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M471.72,299.56c-4.653-1.574-8.803,2.264-9.619,5.515c-0.91,3.628,1.854,8.208,5.779,9.365"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M295.8,746.44c-1.049,2.123-1.696,4.334-1.191,6.718c0.841,3.969-0.095,7.861-0.534,11.757c-0.328,2.906-1.074,5.765-1.634,8.645"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M93.96,251.8c1.402,3.919,4.244,6.886,6.741,10.064c2.021,2.572,4.473,4.82,5.739,7.936"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M111.72,274.84c2.674,2.033,5.438,3.912,8.64,5.04"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M127.56,283.96c1.76,0.88,3.52,1.76,5.28,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M143.16,291.4c3.52,1.52,6.918,3.519,10.587,4.452c6.125,1.558,10.747,5.937,16.524,8.05c1.108,0.405,2.123,1.056,3.368,0.938"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M176.76,307c4.08,2.88,8.152,5.771,12.247,8.631c0.843,0.588,1.705,1.326,2.874,0.729"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M362.76,539.32c-0.239,2.032-1.448,3.543-2.902,4.773c-2.637,2.232-4.791,4.772-6.254,7.93c-0.417,0.897-1.276,1.591-1.927,2.371
				c1.958,2.045,2,2.062,4.104-0.451c0.42-0.501,0.678-1.362,1.429-0.893c0.524,0.328,0.652,1.164,0.507,1.868
				c-0.898,4.352-3.677,7.103-7.689,8.604c-2.153,0.806-3.396,2.295-3.769,4.368c-0.312,1.734-1.348,3.114-1.785,4.776
				c-0.514,1.946-2.673,2.154-4.045,2.27c-2.205,0.184-3.014,1.28-3.588,3.023"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M333.72,579.28c-2.989-2.127-2.524-5.526-3.091-8.526c-0.199-1.056,0.121-2.219-0.749-3.114"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M257.64,644.68c-3.506-1.955-7.476-2.312-11.292-2.779c-5.339-0.653-10.685,0.052-15.828,1.819"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M175.08,630.76c-6.773,3.431-12.191,8.431-16.824,14.382c-1.207,1.55-1.872,3.308-2.616,5.059"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M253.56,295.12c-3.36-1.16-6.72-2.32-10.08-3.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M239.16,290.32c-0.4-0.12-0.8-0.24-1.2-0.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M234.36,289.36c-0.4-0.12-0.8-0.24-1.2-0.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M506.28,712.84c-4.38,1.422-9.022,2.293-12.675,5.335c-0.941,0.783-1.441-0.001-1.947-0.127c-5.86-1.444-11.678-2.087-17.79-1.49
				c-6.976,0.681-13.474,2.673-19.903,5.176c-0.358,0.14-0.645,0.467-0.965,0.707"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M247.56,416.2c-0.665,2.029-1.062,4.105-1.214,6.239c-0.081,1.126,0.174,2.16,0.993,2.859c2.109,1.8,2.503,4.232,2.591,6.742
				c0.059,1.694,0.742,2.591,2.428,2.376c1.997-0.256,4.068,0.527,6.002-0.457"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M357.24,658.6c-2.654-0.402-5.133-1.566-7.921-1.475c-6.524,0.217-12.819,1.292-18.991,3.55c-3.208,1.174-6.369,2.131-8.608,4.885"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M129.72,546.04c0.107,0.748-0.283,0.825-0.947,1.009c-1.409,0.392-2.828,0.929-3.702,2.349c0.946,1.672,2.887,1.294,4.21,1.994
				c-0.279,1.561-1.936,1.661-2.395,3.097c1.804,1.609,4.011,2.829,6.674,3.06c2.32,0.2,4.494,0.848,5.969,2.677
				c2.579,3.197,6.104,3.251,9.624,3.001c1.067-0.076,1.917,0.374,2.887,0.334"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M196.92,632.2c3.701,1.2,6.84,3.338,9.852,5.745c3.794,3.031,6.35,7.025,8.843,11.07c0.493,0.801,1.061,1.49,1.705,2.145"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M561.48,532.84c-2.862,1.562-6.297,1.403-9.143,3.082c-0.824,0.486-1.659,0.762-2.164,1.704c-0.822,1.536-2.221,2.485-3.814,3.132
				c-3.23,1.31-6.198,3.076-8.109,6.034c-1.39,2.149-3.479,3.058-5.589,4.006c-0.776,0.351-1.439,0.607-1.661,1.482"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M591.72,603.64c-6.336-0.302-12.669,0.069-18.681,2.041c-3.055,1.002-6.074,2.236-9.142,3.29c-0.913,0.314-1.533,0.416-2.435-0.016
				c-2.183-1.045-4.493-1.601-6.943-0.997c-2.116,0.521-3.472-0.982-5.039-1.918"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M503.64,203.08c-1.261,3.192-2.839,6.242-2.933,9.838c-0.087,3.322,1.11,5.678,3.867,7.48c1.312,0.857,2.877,1.521,3.626,3.081"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M285.96,693.88c-5.692,0.587-10.812,2.751-15.592,5.773c-3.482,2.201-6.844,4.581-9.853,7.423
				c-0.289,0.272-0.841,0.428-0.924,0.732c-0.814,2.983-3.198,2.369-5.312,2.392"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M67.56,72.28c2.221-3.78,1.569-6.357-2.418-8.12c-3.906-1.726-7.915-1.996-11.742,0.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M51.6,68.68c0.582,1.332-0.941,3.782,2.04,3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M540.36,226.84c-1.318-1.224-2.469-1.487-3.372,0.475c-0.543,1.179-1.043,2.358-1.453,3.598c-1.46,4.417-5.247,6.588-8.868,8.867
				c-1.046,0.659-2.077,1.258-2.627,2.421"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M93.96,519.64c1.042,1.105,2.431,1.438,3.842,1.668c0.967,0.157,2.029-0.444,2.319,1.428c0.152,0.981,2.031,0.472,3.203,0.29
				c2.303-0.354,4.592-0.872,6.957-0.767c0.794,0.035,1.686,0.106,1.636,0.979c-0.191,3.333,2.555,3.705,4.604,4.802
				c0.315,0.168,0.64,0.321,0.959,0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M375.96,675.4c-2.566-6.325-7.391-10.581-12.957-14.165c-1.701-1.095-3.612-1.617-5.522-2.155"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M538.2,276.04c-4.996-0.28-9.649-2.067-14.385-3.415c-3.836-1.091-7.535-2.666-11.296-4.025"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M48.6,455.56c2.579,0.208,5.088,0.035,7.19-1.691c1.269-1.042,2.546-0.523,3.979-0.201c-1.167,1.995-3.756,2.191-5.025,4.062
				c0.768,0.793,1.685,0.402,2.497,0.459c1.359,0.095,2.733-0.128,4.081,0.248c2.26,0.631,2.459,1.189,0.956,2.882
				c-1.353,1.523-2.95,2.733-4.797,3.603"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M517.32,662.68c-11.115-0.438-20.586,3.51-28.792,10.81c-0.462,0.41-0.809,0.951-1.208,1.431"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M303,385.96c-3.282,4.134-6.717,8.132-10.583,11.734c-0.255,0.238-0.728,0.319-0.684,0.744c0.282,2.717-0.056,5.512,0.772,8.142
				c0.257,0.817,0.878,0.51,1.667-0.158c5.568-4.709,10.056-10.436,15.068-15.662"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M571.8,512.92c0.655,3.951,0.362,7.584-3.356,10.085c-0.58,0.39-0.852,0.934-0.735,1.677c0.109,0.691-0.072,1.071-0.94,1.238
				c-2.155,0.416-2.818,2.336-3.588,4.049c-0.351,0.779-0.843,1.691,0.221,2.391c0.883,0.581,1.481,0.159,2.176-0.462
				c2.197-1.968,4.427-3.904,6.732-5.741c0.515-0.409,1.35-1.458,2.107-0.261c0.542,0.859,0.396,1.624-0.68,2.175
				c-0.947,0.485-0.802,1.16-0.332,2.165c0.651,1.392,0.694,2.996-1.364,3.564"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M280.68,661.96c-6.643,1.64-11.594,6.003-16.543,10.34c-1.313,1.15-2.417,2.54-3.617,3.82"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M94.2,518.44c-0.572,1.081-1.639,1.766-1.992,3.101c-0.385,1.456-1.556,2.191-3.288,2.227c-3.514,0.072-6.796,1.134-9.584,3.334
				c-1.659,1.309-3.597,2.177-5.541,2.241c-1.348,0.044-1.448,0.346-1.436,1.337"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M425.88,178.84c-0.034,3.237-1.414,4.962-4.562,5.505c-2.307,0.397-4.63,0.741-6.959,0.953c-2.87,0.261-4.622-1.045-5.759-4.058"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M311.64,330.76c-0.164,0.711-0.395,1.314-0.322,2.167c0.472,5.597,1.637,11.067,2.762,16.545c0.188,0.912,0.304,1.658-0.279,2.408"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M389.88,619c-1.283,0.251-2.305-0.583-3.336-1.018c-6.397-2.697-12.175-1.057-17.784,2.218"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M389.4,622.6c-3.709-1.86-7.388-3.72-11.764-3.399c-1.856,0.136-3.58,0.614-5.264,1.273c-0.77,0.302-1.539,0.762-1.944,1.641
				c-1.282,2.778-1.61,2.898-4.549,1.926"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M79.32,104.32c1.351,0.394,1.851,1.746,2.85,2.557c3.288,2.67,6.778,3.151,10.587,1.396c3.12-1.438,3.179-1.492,4.507,1.71
				c0.286,0.689,0.897,1.156,1.016,1.896"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M341.88,464.2c2.888,2.916,2.89,3.317-0.001,6.239c-1.034,1.045-2.079,2.08-3.111,3.112c0.427,0.796,1.188,0.635,1.908,0.751
				c4.229,0.683,5.507,3.271,3.986,7.139c-0.384,0.977-0.896,1.82-0.348,2.655c1.249,1.898,0.78,3.577-0.264,5.31
				c-0.312,0.518-0.719,1.132-0.677,1.67c0.062,0.797-0.878,1.667,0.178,2.415c0.955,0.676,1.47-0.273,2.162-0.511
				c0.67-0.23,1.273-0.65,1.929-0.935c1.493-0.648,2.396,0.008,2.406,1.674c0.004,0.883-0.174,1.79,0.231,2.641"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M118.44,259.96c-0.45,1.068,0.231,1.914,0.749,2.619c5.76,7.844,11.837,15.436,18.47,22.563c0.928,0.997,1.593,1.978,1.421,3.377"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M449.88,400.36c-1.291-1.242-2.76-0.616-4.07-0.21c-6.587,2.044-12.071,5.798-16.569,11.01"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M114.12,591.88c-9.29-1.874-17.172,1.375-24.48,6.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M361.56,715c-3.425-0.484-6.516,0.872-9.58,1.973c-4.078,1.465-8.02,3.308-12.02,4.987"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,757.24c-8.004,1.062-15.67-1.553-23.521-2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M438.36,554.44c-2.012-2.826-0.038-7.592,3.601-8.636c3.097-0.888,6.419-0.684,9.412-2.306c1.157-0.627,1.991-1.373,3.08-2.208
				c-1.373-1.576-2.493,0.026-3.613-0.051"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M314.04,493.48c-1.183,3.304-3.373,6.009-5.297,8.868c-0.366,0.545-0.748,1.021-1.012,1.663c-0.256,0.62-0.654,1.099-0.162,1.7
				c0.453,0.553,1.218,0.376,1.653,0.205c2.129-0.833,4.477-0.98,6.497-2.117"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M89.4,736.6c0.352,4.455,1.663,8.697,2.88,12.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M93.12,754.6c-0.016,4.097-2.289,7.698-2.552,11.759c-0.145,2.251-0.608,4.481-0.928,6.722"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M446.28,274.6c-3.34,4.54-7.434,8.4-11.286,12.474c-7.482,7.911-14.207,16.355-20.021,25.62c-4.34,6.916-5.78,14.738-8.514,22.154
				c-0.637,1.729-0.789,3.624-1.7,5.272"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M451.32,184.6c-0.503,0.933-0.423,1.69,0.188,2.672c1.052,1.69,1.018,3.616,0.29,5.488c-1.572,4.04,0.141,6.898,4.318,6.668
				c5.458-0.301,8.412,3.319,11.502,6.791c1.4,1.573,2.662,3.289,3.826,5.044c0.576,0.868,1.229,1.414,2.194,1.657"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M533.4,216.04c-0.867,1.807-2.396,3.097-3.882,4.268c-2.529,1.993-3.886,4.555-5.063,7.466c-1.591,3.932-2.625,6.128-7.855,5.546"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M305.64,676.12c-2.325,0.062-4.603-0.39-6.962-0.316c-10.144,0.317-19.324,2.707-26.158,10.876"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M65.16,382.36c3.222,5.683,7.104,10.961,10.115,16.782c2.193,4.24,3.839,8.715,5.924,12.998c0.422,0.866,0.763,1.723,1.48,2.38"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M502.68,249.88c0-0.32-0.121-0.711,0.019-0.949c2.708-4.6,2.296-9.992,3.789-14.9c0.442-1.455,1.104-3.72-0.727-4.141
				c-1.893-0.435-2.303,1.954-2.54,3.44c-0.375,2.342-0.757,4.324-3.385,5.361c-1.382,0.546-1.904,1.681-0.662,3.603
				c1.355,2.097,1.133,4.997,1.346,7.586"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M447.72,360.04c-0.367-1.178-1.552-1.264-2.415-1.646c-4.196-1.854-8.232-3.911-11.523-7.216c-0.753-0.755-1.531-1.219-2.892-0.561
				c-4.825,2.337-9.845,4.137-15.33,4.15c-2.574,0.005-4.563,1.264-6.239,3.112"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M407.64,360.76c-2.651,1.404-2.651,1.404-3.84-1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M190.44,682.12c4.007,0.767,8.059,1.242,12.015,2.349c2.908,0.813,5.584,2.134,8.385,3.171"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M490.68,727.48c6.215,2.776,11.981,6.285,17.283,10.556c0.137,0.109,0.427,0.132,0.461,0.249c1.109,3.803,3.846,2.284,5.977,1.559
				c0.94-0.32,2.407,0.39,2.92-1.084"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M307.56,658.6c-3.916-2.015-8.091-2.966-12.479-2.858c-2.03,0.05-3.925-0.614-6-0.617c-9.923-0.012-17.923,4.169-24.942,10.695
				c-2.67,2.482-5.439,4.934-6.978,8.381"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M349.08,402.28c2.261,0.878,3.872,2.681,5.744,4.101c3.494,2.648,6.798,5.557,10.366,8.096c2.921,2.079,4.791,3.153,8.13-0.677"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M202.92,507.64c-1.304-2.32-3.394-1.97-5.523-1.711c-0.893,0.109-0.992,0.531-0.962,1.231c0.026,0.64,0.005,1.28,0.005,1.92
				c0,3.6,0,3.574-3.6,3.616c-0.991,0.012-1.914-0.105-2.936,0.625c-1.363,0.975-2.89,0.276-4.281-0.615
				c-1.555-0.997-2.233-0.552-2.183,1.415c0.086,3.35,0.1,3.409,4.355,4.107c1.306,0.214,3.033-0.308,3.618,1.647
				c0.259,0.865,0.583,1.725-0.264,2.391c-0.839,0.66-1.495-0.038-2.148-0.469c-2.029-1.34-2.609-1.196-3.083,1.21
				c-0.435,2.206-1.046,4.302-2.336,6.219c1.375,0.131,2.501,0.598,3.788-0.402c1.496-1.164,3.371-0.025,4.177,0.972
				c2.386,2.951,5.632,2.793,8.461,2.178c3.834-0.833,7.555-0.095,11.314-0.274c0.308-0.015,0.914-0.481,1.197,0.181
				c2.651-2.421,5.473-0.388,8.15-0.136c1.977,0.188,3.066,2.205,3.551,3.992c0.951,3.497,3.519,4.207,6.539,4.289
				c3.433,0.094,6.256,1.562,8.892,3.6c0.821,0.635,1.025,1.146,0.236,1.943c-1.409,1.424-3.21,2.044-5.045,2.64
				c-1.64,0.533-3.321,0.976-4.803,1.912"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M406.2,769.72c5.36-1.219,10.704-1.284,16.085-0.021c4.254,0.999,8.378,2.377,12.475,3.861"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M272.04,336.04c0.56,0,1.132,0.077,1.678-0.013c3.136-0.514,4.277-0.008,3.954,2.878c-0.37,3.309,1.084,6.206,1.181,9.372
				c0.048,1.574,0.506,3.2,0.363,4.801c-0.538,6.014,3.814,9.646,6.819,13.868c1.04,1.46,0.977,2.226-0.058,3.43
				c-1.668,1.941-3.235,3.971-4.811,5.99c-0.702,0.899-1.622,1.035-2.648,0.954"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M126.84,448.6c0.777,1.118,1.115,2.096,0.609,3.643c-0.453,1.383-0.541,3.148,1.012,4.582c1.482,1.368,2.083,3.318,0.524,5.203
				c-0.342,0.413-0.322,1.092-0.198,1.171c1.761,1.118,2.713,3.456,5.253,3.42c1.648-0.024,2.985,1.156,4.564,1.39
				c0.788,0.116,1.545,0.642,2.396,0.274c2.185-0.944,2.743,0.466,3.122,2.157c0.375,1.671,2.29,2.496,2.397,4.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M247.56,751c-1.04,0.24-2.1,0.418-3.116,0.732c-2.315,0.718-3.222,2.442-2.388,4.782c2.074,5.814,4.553,11.463,7.184,17.045"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M251.16,80.2c2.769,6.191,2.022,14.3-2.885,17.994c-3.794,2.856-8.052,3.438-12.723,2.955c-4.744-0.491-8.492-3.646-8.833-8.231
				c-0.034-0.458,0.241-0.957-0.039-1.437"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M172.68,494.2c-0.492-0.838-1.762-1.095-1.31-2.672c0.298-1.037-0.686-1.68-1.818-0.696c-0.517,0.448-1.144,0.771-1.667,1.214
				c-3.515,2.97-7.411,3.713-11.776,2.186c-0.64-0.224-1.437-0.657-2.147-0.029c-0.842,0.744,0.251,1.258,0.296,1.914
				c0.025,0.368,0.775,0.692,0.177,1.198c-0.699,0.592-1.623,0.686-2.385,0.45c-2.608-0.806-5.358-1.348-7.451-3.324"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M358.92,568.36c2,0.12,3.41,1.064,4.339,2.87c0.773,1.504,2,2.08,3.815,2.24c6.641,0.583,11.966,3.926,16.566,8.568
				c0.796,0.804,1.6,1.601,2.399,2.401"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M206.76,724.36c-1.061-2.701-1.186-5.584-1.384-8.404c-0.238-3.374-0.606-6.729-0.889-10.087c-0.231-2.738-0.257-5.656,0.281-8.355
				c0.22-1.1-0.889-3.5,1.273-3.514c1.874-0.013,2.956,1.979,3.766,3.519c0.612,1.165,0.5,2.693,0.803,4.039
				c1.133,5.021,2.312,10.032,4.071,14.883"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M141.96,482.44c2.438,1.791,4.285,4.196,6.479,6.241c0.36,0.335,0.817,0.666,0.242,1.199c-0.402,0.372-0.737,0.202-1.211,0.027
				c-2.74-1.013-5.136-2.607-7.407-4.376c-1.031-0.804-0.406-1.781,0.218-2.612"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M233.28,555.88c-0.744-3.735-3.106-5.745-7.08-5.991c-0.54-0.033-1.01-0.304-1.458-0.404c-0.81-0.183-2.222-0.532-2.546,0.189
				c-1.611,3.585-4.82,2.598-7.25,2.211c-2.766-0.44-4.412-0.48-6.16,2.229c-1.663,2.576-5.362,2.361-8.019,3.698
				c-1.698,0.854-3.572,0.125-5.286,0.708"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M222.6,493.96c3.579,2.034,4.016,3.245,2.115,6.938c-0.569,1.106-0.679,2.418-1.432,3.353c-0.903,1.118-0.089,2.269-0.332,3.413
				c-0.28,1.317,1.348,1.245,2.266,1.702c1.216,0.605,1.965,1.722,2.456,3.062c0.535,1.46,1.354,2.476,3.342,1.997
				c1.76-0.423,3.412,0.451,4.778,1.623c1.806,1.548,3.325,3.382,5.232,4.862c1.534,1.191,2.863,1.786,4.61,0.882
				c2.593-1.343,3.782,0.472,5.044,2.168"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M531,107.68c2.557,0.982,5.334,1.682,5.058,5.401c-0.427,5.738-5.551,7.813-9.627,7.27c-4.049-0.539-6.194-3.449-8.751-5.951"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M483.48,195.88c-0.757,2.604,0.364,5.269-0.42,7.938c-0.869,2.959-1.109,6.127-3.195,8.61c-0.719,0.856-1.56,1.663-2.865,1.452"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M81.48,646.84c-2.73,0.553-4.721,2.437-6.673,4.134c-4.241,3.688-8.482,7.439-11.327,12.427"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M230.28,585.88c-0.64,0.561-1.512,0.999-1.877,1.702c-1.194,2.301-2.639,3.084-5.096,1.681c-5.566-3.179-11.669-2.883-17.744-2.391
				c-2.193,0.178-4.343-0.158-6.477-0.342c-7.246-0.621-13.596,1.852-19.357,5.723c-1.925,1.293-2.809,0.708-4.169-0.133"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M451.8,390.76c-1.439-0.977-2.888-0.356-4.318,0.005c-5.469,1.382-10.72,3.248-15.121,6.955"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M466.92,114.52c2.38,10.873,11.08,15.792,20.015,19.448c7.621,3.119,16.162,2.977,24.115,0.147
				c6.387-2.272,10.56-6.751,10.6-14.075c0.002-0.24,0.15-0.48,0.23-0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M566.28,408.76c1.114-1.319,2.135-2.752,1.886-4.555c-0.279-2.019,0.5-3.073,2.435-3.357c1.317-0.193,2.306-0.995,3.359-1.688"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M578.28,397.72c2.568-0.608,4.873,0.59,7.18,1.269c3.864,1.137,7.552,0.952,11.309-0.283c1.238-0.406,0.489-1.298,0.712-1.945"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M418.44,369.88c-5.031,0.585-7.874,4.747-11.75,7.216c-2.235,1.425-4.542,2.59-7.212,2.848c-0.581,0.056-1.299,0.259-1.672-0.468
				c-0.311-0.607,0.238-1.043,0.501-1.417c1.707-2.436,3.808-4.479,6.192-6.284c2.751-2.082,5.401-4.326,5.78-8.134"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M179.88,662.2c-3.417,0.072-6.137,1.977-8.849,3.65c-4.174,2.576-7.929,5.731-11.071,9.55"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M141.96,277.24c4.214,6.1,9.223,11.541,14.163,17.037c0.57,0.634,1.342,1.14,1.197,2.163"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M241.8,522.28c-2.203,1.168-4.677,0.047-6.985,0.891c-1.55,0.566-3.175,1.22-4.784,1.438c-3.659,0.498-12.589-2.589-14.393-6.888
				c-0.789-1.878-2.124-3.127-4.066-3.87c-2.127-0.812-1.841-3.163-2.582-4.802c-1.289-2.852-1.796-5.802-1.157-9.113
				c0.617-3.2-0.432-6.547-0.919-9.803c-0.054-0.359-0.262-1.296-1.332-1.543c-1.488-0.345-2.568-1.646-3.621-2.79"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M511.08,276.04c7.584,2.735,15.137,5.565,23.275,6.295c0.879,0.079,1.768,0.07,2.645,0.185"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M525.96,711.16c8.533,0.537,17.023,1.368,25.196,4.089c5.509,1.834,10.397,4.888,15.074,8.221c1.457,1.038,2.556,1.439,4.381,1.23
				c3.159-0.361,6.389-0.101,9.589-0.101"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M87.48,57.16c-5.776,1.069-11.165,3.046-15.846,6.713c-1.082,0.848-2.234,1.607-3.354,2.407"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M275.52,439.24c0.363-1.506,1.487-0.835,2.002-0.432c1.238,0.97,2.298,2.172,3.394,3.316c1.624,1.694,2.447,1.645,3.58-0.498
				c0.813-1.537,1.885-1.989,3.626-1.967c3.562,0.048,7.151,0.479,10.593-1.315c2.527-1.317,4.479-3.177,6.448-5.103
				c0.275-0.27,0.734-0.709,0.444-0.914c-0.423-0.299-0.885-0.511-1.662-0.377c-3.019,0.521-5.774,1.76-8.613,2.761
				c-0.951,0.336-1.771,0.506-2.651-0.032"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M441.24,638.92c-5.635,3.344-11.28,6.671-15.603,11.759c-1.027,1.21-2.077,2.401-3.118,3.602"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M55.08,682.84c2.753-1.028,5.549-1.909,8.382-2.703c0.909-0.255,1.172,0.059,1.711,0.527c4.222,3.667,7.201,8.247,9.504,13.254
				c0.408,0.887,0.956,1.46,1.522,2.122"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M79.08,707.08c1.292,1.628,1.138,3.746,1.304,5.532c0.376,4.049,0.964,8.13,0.589,12.226c-0.039,0.418-0.453,0.803-0.693,1.202"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M440.04,600.28c-2.24,0-4.493,0.157-6.718-0.03c-6.427-0.541-11.303,2.54-15.842,6.51"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M109.56,732.52c0.16,0.4,0.466,0.804,0.458,1.2c-0.123,6.025,1.134,12.078,0.731,17.983c-0.37,5.436,0.151,10.941-0.901,16.347
				c-0.206,1.058-0.294,2.261,0.386,3.378c0.347,0.569,0.077,1.31-0.915,1.172"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M441,357.16c-8.62,1.625-16.514,4.842-23.295,10.542c-0.446,0.375-1.104,0.498-1.665,0.738"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M426.12,694.84c5.056,0.609,9.544,2.915,14.143,4.841c2.101,0.88,4.018,2.199,6.018,3.319"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M48.36,451.96c0.08,0.24,0.299,0.55,0.22,0.71c-1.429,2.863-0.711,4.785,2.412,6.507c0.437,0.24,1.107,0.381,0.979,1.185
				c-0.142,0.886-0.954,0.856-1.455,0.916c-1.714,0.202-3.398-0.068-5.04-0.663c-1.092-0.396-2.245-0.623-3.351-0.985
				c-0.706-0.23-1.86-0.423-1.782,0.234c0.076,0.635-0.526,1.487,0.493,2.285c0.84,0.657,2.783,1.802,1.634,3.139
				c-0.653,0.76-2.329-0.525-3.465-1.095c-2.947-1.477-5.44-3.715-8.606-4.893c-1.113-0.413-1.797-1.979-2.677-3.02"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M201,729.4c1.839-0.607,2.809-2.325,4.303-3.385c1.053-0.745,1.841-2.479,3.335-1.586c1.532,0.917,2.083,2.748,1.684,4.481
				c-1.032,4.478-2.228,8.872-4.447,13.009c-2.183,4.07-4.74,7.851-7.581,11.428c-1.144,1.44-1.443,2.847-1.093,4.363
				c1.029,4.447,0.889,8.947,0.937,13.449c0.005,0.471-0.771,1.161,0.226,1.423c0.744,0.195,1.055-0.36,1.462-0.924
				c1.535-2.127,3.172-4.181,4.775-6.258c0.312-0.406,0.639-0.802,0.959-1.201"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M297.72,380.44c-4.334,5.868-9.775,10.697-14.912,15.808c-0.336,0.334-0.987,0.704-1.648,0.512"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M296.76,746.44c1.55,3.269,1.667,6.903,2.631,10.322c0.78,2.766,0.655,5.688,1.798,8.452c0.529,1.281-0.189,3.191-0.64,4.73
				c-0.352,1.198-0.733,2.376-0.909,3.614"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M76.44,685.24c-1.397-2.459-1.445-5.324-2.356-7.936c-0.215-0.617-0.143-1.721,0.259-2.073c0.551-0.483,1.274,0.252,1.857,0.648
				c2.17,1.475,2.942,3.97,4.246,6.047c0.565,0.9,1.018,1.691,1.994,2.113"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M85.68,692.2c1.4,3.899,2.298,7.919,3.019,11.996c1.05,5.936,3.718,11.408,5.054,17.276c0.865,3.8,2.027,7.533,3.095,11.285
				c0.596,2.093,1.426,4.131,1.854,6.254c0.26,1.288,0.887,1.623,1.978,1.668"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M238.44,733.96c0.114-0.914-0.365-1.144-1.199-1.217c-5.215-0.452-10.267,0.217-15.1,2.229c-0.447,0.187-1.411,0.417-1.222,1.389"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M312.12,717.4c-6.351-0.679-12.72-1.55-18.72,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M24.6,740.44c0.021,3.902,1.047,7.702-1.319,11.593c-2.88,4.737-3.678,10.379-4.903,15.771c-0.095,0.418-0.137,0.806-0.498,1.076"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M463.56,623.8c-3.336-0.563-6.634-1.184-10.083-1.033c-3.145,0.138-5.927,1.062-8.637,2.474"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M44.76,564.04c-1.741-0.493-3.528-0.063-5.278-0.256c-1.84-0.202-3.713-0.438-3.376-3.106c0.172-1.361-0.535-2.008-1.906-1.918
				c-1.955,0.129-3.731-0.564-5.52-1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M216.36,459.88c4.244,0.463,7.657,2.88,11.279,4.802c0.885,0.47,1.717,1.081,2.649,1.416c3.131,1.126,4.049,0.524,4.025-2.857
				c-0.011-1.567,0.438-1.835,1.96-1.986c5.729-0.568,10.438,2.101,14.812,4.979c4.475,2.944,7.966,7.194,11.556,11.202
				c0.363,0.405,1.229,1.129,0.488,1.854c-0.448,0.439-1.027,0.173-1.686-0.131c-0.976-0.45-2.271,0.697-3.324-0.318"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M384.6,368.44c-0.442,0.948-0.831,1.214-1.949,0.528c-3.601-2.208-7.36-4.158-10.986-6.328c-0.759-0.455-1.701-0.78-2.125-0.377
				c-0.844,0.803,0.119,1.457,0.652,2.104c1.36,1.652,3.732,2.709,3.178,5.522c-0.081,0.411,0.541,0.962,1.15,0.938
				c2.199-0.084,1.239,1.22,0.951,2.169c-0.609,2.01-1.271,4.003-1.911,6.003"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M116.28,539.56c-3.692,3.049-7.757,4.85-12.723,4.65c-2.041-0.082-3.863,1.446-5.805,2.287c1.229,1.279,2.861-0.022,4.128,0.743"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M463.8,546.76c-3.791-0.497-6.075,0.806-8.202,4.295c-0.793,1.302-2.132,2.247-3.562,2.896c-2.512,1.139-4.825,2.515-6.482,4.804
				c-0.395,0.546-0.187,1.121-0.232,1.687"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M35.64,719.32c-0.771,1.955-0.064,3.854,0.247,5.759c0.616,3.764,1.25,7.525,1.938,11.276c0.102,0.553,0.535,1.045,0.815,1.564"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M489.96,214.6c-2.293,0.569-3.177,1.892-2.88,4.32c0.34,2.764,1.462,5.16,3.633,6.919c0.561,0.453,0.979,0.974,1.381,1.501
				c0.543,0.71,0.863,2.146,1.887,1.556c0.807-0.465,1.633-1.585,1.065-3.034c-0.466-1.191-0.526-2.542-0.766-3.822"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M544.2,740.2c-1.002-0.734-2.271-0.688-3.32-1.071c-2.565-0.937-5.229-0.896-7.868-2.529c-5.08-3.145-11.154-3.59-17.132-1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,745.72c-2.383-1.37-5.067-1.35-7.68-1.685c-8.702-1.118-17.15-0.373-25.198,3.37c-0.421,0.196-0.803,0.475-1.202,0.715"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M373.08,349.96c-0.662,1.173-0.093,2.304,0.268,3.35c1.679,4.861,5.397,8.186,8.77,11.846c2.11,2.292,3.91,3.794,7.048,2.334
				c3.063-1.425,6.238,0.61,9.365-0.213c1.057-0.278,2.255-0.072,2.63-1.478"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M129.48,681.4c-4.38,1.767-8.615,4.062-11.851,7.347c-2.125,2.159-3.906,1.843-6.149,1.533"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M301.8,661.72c6.835,0.729,12.567,3.861,17.758,8.163c1.136,0.941,2.242,1.917,3.362,2.877"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M111,731.56c1.582,2.493,1.548,5.502,2.618,8.169c1.35,3.364,2.708,6.716,4.586,9.829c0.206,0.341,0.752,0.763,0.674,0.944
				c-1.235,2.844-0.109,6.182-1.878,8.898"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M531,339.64c-2.56,1.28-5.264,2.339-7.646,3.892c-3.624,2.361-7.575,4.073-11.346,6.129c-7.41,4.041-14.983,7.787-22.534,11.566
				c-7.832,3.919-15.36,8.347-22.854,12.874c-2.893,1.748-5.615,3.674-8.31,5.66c-0.85,0.626-1.631,0.277-2.431,0.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M118.2,576.04c-3.824-1.497-7.699-1.819-11.769-1.001c-4.737,0.953-8.311,3.624-11.512,7.001"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M344.04,539.08c-1.164,2.041-3.365,2.889-5.104,4.236c-0.609,0.473-1.097,0.99-1.624,1.515c-0.458,0.454-0.114,0.975-0.231,1.449
				c0.556,0.11,0.894,0.528,1.701,0.321c2.424-0.622,4.046-2.376,5.962-3.706c0.616-0.428,1.026-1.156,1.937-0.936"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M571.08,267.4c-0.8-0.72-1.497-1.646-2.426-2.109c-1.258-0.628-2.637-0.982-4.069-0.074c-1.694,1.074-2.052,2.691-1.598,4.324
				c0.427,1.534,1.287,3.069,3.293,3.131c0.835,0.026,1.661,0.02,2.399,0.488"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M183,442.84c-1.588,1.973-1.952,4.177-0.918,6.461c0.415,0.918,0.124,1.764-0.323,2.129c-2.029,1.658-1.733,4.275-2.811,6.306
				c-0.198,0.374-0.234,1.126,0.003,1.397c2.813,3.2-0.207,4.688-2.003,6.205c-1.758,1.485-3.294,2.494-2.766,5.375
				c0.271,1.482-0.5,3.653-1.981,5.007"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M32.04,705.64c-1.244-0.857-2.227-2.089-3.834-2.425c-0.919-0.192-1.024-1.022-1.254-1.882c-0.826-3.089-2.469-5.695-5.239-7.44
				c-0.782-0.493-1.533-1.492-2.622-0.717c-1.052,0.748-0.602,2.007-0.421,2.85c0.644,3,2.144,5.742,3.239,8.529
				c1.95,4.961,3.43,10.262,4.263,15.705c0.89,5.822-0.953,11.219-1.365,16.818c-0.055,0.744-0.278,1.617-0.936,2.149
				c-0.758,0.615-1.506,1.294-2.408,0.025c-1.724-2.425-3.554-4.775-5.4-7.236c1.863-0.835,3.471,0.749,5.178,0.264"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M477.96,754.84c-6.126-0.929-12.281-1.353-18.479-1.208c-8.25,0.191-15.854,2.126-22.08,7.928"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M211.32,755.32c1.473,3.291,1.131,6.882,1.623,10.328c0.371,2.599,0.217,5.271,0.298,7.911"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M478.44,525.4c-1.23-1.62,0.022-2.853,0.749-4.062c1.003-1.671,2.318-3.159,3.251-4.861c0.218-0.397,0.965-1.575-0.172-2.091
				c-0.673-0.306-1.188,0.193-1.679,0.685c-2.014,2.015-3.854,4.173-5.51,6.489"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M263.64,551.32c3.149,0.876,5.532,3.172,8.406,4.548c7.281,3.482,14.797,6.046,23.035,5.8c1.391-0.041,2.657-0.871,4.079-0.508"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M451.32,396.52c-3.15-1.442-6.047,0.289-8.844,1.089c-3.413,0.976-6.696,2.647-9.396,5.151"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M172.2,602.44c-0.757,2.568-1.405,5.189-3.36,7.199"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M166.2,610.84c-5.205,1.248-9.354,4.256-12.374,8.477c-1.467,2.052-2.709,1.692-4.149,0.821c-0.948-0.573-2.084-0.646-2.918-1.378"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M263.64,81.88c1.977,1.721,3.867,3.602,5.196,5.811c0.977,1.624,2.063,3.647,0.787,5.699c-1.267,2.037-4.14,2.898-6.708,2.44
				c-3.104-0.554-5.13-2.514-6.953-4.831c-0.776-0.987-1.537-1.984-2.642-2.639"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M168.36,705.64c-2.829,1.208-1.325,3.495-1.139,5.273c0.346,3.303,1.084,6.562,1.6,9.85c0.114,0.731,0.172,1.542-0.461,2.157"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M156.6,206.44c-2.844,2.127-3.824,4.331-2.88,6.48c1.182,2.692,4.824,4.161,8.166,3.386c0.759-0.176,1.594-0.025,2.394-0.025"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M405.72,648.28c5.2,2.923,9.306,7.104,13.194,11.524c1.238,1.407,2.406,2.875,3.605,4.315"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M255,196.12c-0.24-1.521-0.36-3.071-0.744-4.554c-0.963-3.729-3.511-5.437-7.656-5.286c-3.375,0.122-5.916,2.549-6.777,5.986
				c-0.446,1.781,0.41,2.767,1.53,3.569c1.049,0.753,2.496,0.412,3.534-0.033c1.203-0.515,1.227-1.863,0.745-3.038
				c-0.724-1.764-2.038-2.296-3.831-1.684"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M336.36,744.04c2.313-2.75,1.346-6.048,1.321-9.119c-0.007-0.807-0.786-2.991-2.83-1.282c-0.338,0.283-1.318,0.64-2.092,0.562"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M329.88,733.24c-3.731-2.064-4.86-1.556-5.705,2.65c-0.032,0.156,0.119,0.625,0.188,0.949c1.326,6.237-2.816,10.943-4.578,16.315
				c-0.039,0.119-0.306,0.165-0.465,0.245"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M404.28,556.6c-1.041,0.727-1.8-0.241-2.628-0.517c-1.58-0.522-3.014-1.431-4.816-1.191c-1.306,0.174-2.087-0.348-1.697-1.896
				c0.182-0.721-0.021-1.371-0.681-1.936c-1.418-1.213-2.913-2.402-4.054-3.854c-1.406-1.793-1.813-0.492-2.444,0.515"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M497.64,519.4c-2.281,1.415-5.01,1.461-7.433,2.417c-3.669,1.449-7.579,2.049-11.287,3.343"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M140.04,532.6c-0.991-0.013-2.001,0.158-1.154,1.409c0.575,0.851,0.185,1.227-0.29,1.707c-1.303,1.315-1.269,1.792,0.494,2.374
				c1.634,0.541,3.35,1.162,5.03,1.163c3.696,0.001,6.741,1.946,10.05,3.036c1.758,0.579,3.456-0.468,5.07,0.391"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M109.32,682.84c-1.278-0.37-1.533,0.356-1.974,1.418c-1.416,3.412-1.461,7.025-1.845,10.585c-0.414,3.838,0.085,7.529,2.378,10.797
				"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M380.28,707.32c-6.011-1.994-11.968-2.653-17.761,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M515.88,639.88c-4.607,1.462-9.518,1.113-14.163,2.388c-4.398,1.206-8.429,3.033-11.772,6.236
				c-1.108,1.062,0.123,1.937,0.016,2.896"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M513.24,503.08c-2.26,2.472-5.528,2.493-8.424,3.272c-1.627,0.438-3.256,0.782-4.776,1.527"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M291.72,645.88c-3.413,0.083-6.14,1.981-8.844,3.658c-3.577,2.218-7.09,4.607-9.876,7.862"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M342.84,521.8c-2.034-0.079-3.738,0.889-5.537,1.639c-1.017,0.425-2.073,1.48-3.354,0.294c-2.298-2.127-4.971-1.694-7.675-0.996
				c-0.355,0.092-0.794-0.136-1.193-0.217"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M545.16,244.12c0.16,0.32,0.323,0.638,0.479,0.96c0.526,1.087,1.394,2.273,0.212,3.327c-1.171,1.045-2.235-0.183-3.049-0.75
				c-1.216-0.847-2.532-1.631-3.602-2.622c-2.177-2.016-4.737-2.373-7.481-2.356"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M356.52,676.6c-3.461-0.525-6.761-1.837-10.319-1.94c-6.045-0.178-12.116-0.941-18.02,1.167c-0.089,0.032-0.612-0.138-0.94-0.187"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M138.12,559.72c-1.978,0.984-4.164,0.706-6.246,0.903c-2.944,0.28-5.922-0.067-8.882,0.499c-2.442,0.468-4.682-0.686-6.953-1.402"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M551.4,292.36c-2.13-0.911-3.48,0.167-4.279,1.938c-0.674,1.493,0.308,2.708,1.425,3.546c0.959,0.72,2.132,1.149,3.338,0.28
				c1.28-0.922,1.396-2.284,1.137-3.593c-0.141-0.711-0.412-1.658-1.381-1.932"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M428.52,83.8c0.116-5.018-4.129-8.582-9.352-7.639c-2.513,0.454-5.146,0.746-7.215,2.591c-3.828,3.417-3.248,7.682-2.38,12.005
				c0.118,0.59-0.044,1.341,0.707,1.683"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M521.64,367.72c0.192-0.696-0.265-1.107-0.727-1.431c-5.029-3.535-10.07-7.054-15.121-10.557c-0.254-0.176-0.632-0.172-0.952-0.252
				"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M242.52,292.12c-0.094,1.031,0.165,1.811,0.919,2.675c3.847,4.404,7.561,8.925,11.32,13.405"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M261,314.2c1.6,1.2,3.2,2.4,4.8,3.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M338.04,741.64c4.437,2.145,8.776,4.431,12.752,7.397c1.56,1.164,2.423,3.185,4.528,3.643"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M394.2,537.16c-1.081-3.797-4.394-5.577-7.257-7.6c-1.864-1.317-3.225-2.602-3.835-4.866c-0.667-2.472-5.159-4.909-7.868-4.574"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M323.88,523c-3.294-0.354-6.618,0.241-9.042,2.254c-4.307,3.578-9.313,4.2-14.478,4.705c-2.475,0.242-4.933,0.646-7.442,0.515
				c-0.647-0.034-1.591-0.012-1.871,0.705c-0.266,0.683,0.501,1.16,0.922,1.653c0.181,0.211,0.506,0.293,0.704,0.494
				c0.591,0.606,1.641,1.146,1.161,2.131c-0.508,1.044-1.559,0.133-2.354,0.263"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M353.88,503.32c-1.359,1.199-2.732,2.386-4.074,3.606c-0.551,0.502-1.328,1.04-0.934,1.898c0.378,0.82,1.179,1.02,2.128,1.002
				c3.189-0.061,5.932-1.209,8.402-3.144c0.366-0.286,0.773-0.521,1.266-0.849c0.773,1.695,1.051,3.091-1.016,3.993
				c-0.483,0.211-0.842,0.747-1.208,1.176c-3.002,3.523-6.75,4.736-11.294,3.887c-0.944-0.177-2.294-0.358-2.828,0.927
				c-0.187,0.449-0.085,1.337,0.237,1.617c0.847,0.733,1.861,1.556,3.058,1.175c0.44-0.14,1.021-0.088,1.182-0.22
				c1.275-1.046,3.069-1.453,3.881-3.069"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M531.96,231.88c-2.464,3.876-6.351,6.023-10.059,8.433c-2.537,1.648-2.614,3.125-0.741,5.727"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M352.68,711.4c-4.706,2.014-9.698,3.227-14.415,5.244c-6.308,2.698-12.23,6.145-18.12,9.576c-1.546,0.901-1.886,4.057-1.975,6.301
				c-0.219,5.536-2.4,10.19-5.793,14.412c-0.285,0.354-0.552,0.607-0.977,0.706"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M447,406.12c-0.96-0.814-1.905-0.503-2.883-0.005c-5.22,2.659-9.584,6.275-12.726,11.279c-0.176,0.28-0.472,0.485-0.712,0.726"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M548.04,244.12c0.32,0.56,0.748,1.085,0.939,1.687c0.53,1.667,0.184,3.261-0.964,4.532c-1.133,1.257-2.754,0.989-4.033,0.448
				c-2.398-1.014-4.662-2.347-6.982-3.546"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M92.04,478.84c-0.08-1.698-2.063-2.262-2.282-4.064c-0.125-1.027-1.581-0.54-2.258,0.009c-1.965,1.593-3.315,3.748-4.784,5.762
				c-1.188,1.627-2.452,2.811-4.595,2.614"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M221.88,444.76c-0.634-1.967-2.694-2.76-3.684-4.512c-0.548-0.969-1.192-1.769-1.116-2.928c0.113-1.729-0.994-1.945-2.138-1.395
				c-1.479,0.712-2.88,1.298-4.584,1.198c-1.093-0.064-1.294,0.852-1.478,1.868c-0.715,3.964-1.888,4.582-5.969,3.65
				c-0.728-0.167-1.851-0.459-2.327,0.241c-0.434,0.639,0.159,1.475,0.652,2.119c0.973,1.271,1.97,2.526,2.87,3.847
				c0.741,1.088,0.439,1.769-0.945,1.919c-2.116,0.229-4.115-0.637-6.244-0.577c-1.062,0.029-1.975-1.206-2.104-2.317
				c-0.187-1.611-1.118-2.175-2.453-2.122c-2.017,0.08-3.855-1.379-6.036-0.346c-1.262,0.598-3.544-2.388-3.269-4.238
				c0.058-0.385,0.208-0.82,0.162-1.206c-0.097-0.839-7.024-5.543-7.898-5.544c-1.084,0-1.256,1.154-1.135,1.434
				c2.307,5.334,2.854,11.401,6.655,16.108"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M537.48,765.64c-5.917-2.313-11.622-2.129-17.04,1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M513,563.8c1.531,2.333,4.18,2.503,6.478,3.368c3.289,1.237,6.97,1.588,9.624,4.291c0.372,0.38,0.739,0.652,0.698,1.221"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M527.64,575.32c-2.284,0.674-2.499,3.088-3.801,4.594c-1.441,1.666-2.312,1.908-3.614,0.188c-1.39-1.837-2.584-3.821-3.864-5.741"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M193.8,545.32c2.179,2.039,4.47,3.429,7.456,1.464c0.283-0.187,0.655-0.423,1.207-0.058c2.599,1.723,5.636,2.684,8.138,4.594"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M173.64,736.12c0.417,3.404,1.968,6.499,2.662,9.835c0.902,4.339,2.304,8.617-0.007,12.973c-2.205,4.157-3.234,8.827-5.513,12.965
				c-0.169,0.307-0.022,0.787-0.022,1.188"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M371.64,487.48c0.867-0.669,0.402-2.023,1.417-2.677c0.34-0.218,0.364-1.037,0.977-0.896c0.755,0.174,0.621,0.657,0.572,1.417
				c-0.133,2.054-0.781,3.893-1.513,5.76c-1.207,3.079-1.688,6.219-1.567,9.6c0.102,2.83-2.453,4.725-3.966,6.955"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M39.24,536.92c-2.48-0.526-4.608,0.981-6.96,1.204c-0.563,0.053-0.75,0.418-0.724,0.956c0.055,1.126,1.095,1.112,1.687,1.147
				c2.232,0.131,4.53,0.361,6.706-0.013c4.401-0.755,8.735-1.894,12.731-4.015"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M506.76,679.48c5.977,0.809,11.271,3.378,16.299,6.515c2.366,1.477,4.501,3.325,6.741,5.005"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M411.48,552.28c-1.119-1.44-1.096-2.804,0.492-3.821c1.895-1.216,4.077-0.84,5.03,0.699c1.511,2.442,2.922,4.874,1.701,7.932
				c-0.385,0.964-0.015,2.191,0.519,2.817c1.642,1.928,1.095,3.823,0.417,5.812"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M417,571c0.475,1.595-0.942,1.362-1.69,1.652c-0.916,0.354-1.909,0.508-2.869,0.748"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M193.8,644.92c-3.634-0.814-7.341-1.007-11.041-1.184c-6.124-0.294-11.314,1.869-15.595,6.228
				c-1.625,1.655-3.204,3.356-4.804,5.036"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M510.36,351.16c4.72,3.6,9.438,7.203,14.161,10.797c0.911,0.693,2.075,1.125,2.398,2.402"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M273.96,745.48c-5-0.893-10.004-1.765-15.12-1.436c-0.643,0.042-1.28,0.155-1.92,0.235"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M526.44,723.16c-4.881,0-9.761-0.007-14.641,0.003c-1.969,0.005-3.979-0.199-5.76,0.957"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M485.64,211.96c-1.513,3.269-3.333,6.475-0.771,10.116c0.44,0.626,0.286,1.725,0.275,2.604c-0.023,1.679,0.653,2.881,2.176,3.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M459.72,676.12c-5.6-0.452-11.199-0.812-16.8,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M476.28,343.48c1.493-1.053,1.249-2.952,2.092-4.361c0.796-1.331,0.961-3.024,1.52-4.515c2.501-6.673,4.763-13.412,6.253-20.399
				c0.427-1.999,0.229-2.383-1.971-1.779c-2.854,0.784-5.895,0.887-8.854,1.295c-2.241,0.31-4.48,0.638-6.721,0.958"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M241.32,412.36c-1.041,2.484-0.676,5.103-0.714,7.68c-0.025,1.712-0.253,2.474-2.441,1.795c-2.089-0.646-4.437-0.135-6.552,1.155
				c1.012,1.391,2.674,1.882,3.998,2.731c1.354,0.869,1.089,1.583,0.413,2.466c-1.065,1.392-2.654,2.177-4.291,1.868
				c-2.81-0.53-5.61-0.367-8.413-0.392c-1.998-0.017-3.995-0.998-4.405-2.467c-0.82-2.934-3.19-3.787-5.194-5.237"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M482.52,416.92c2.648,0.271,5.348,0.896,7.681-1.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M528.12,75.64c1.094-3.202,0.548-6.284-1.75-8.57c-2.259-2.247-5.342-3.099-8.561-2.195c-1.801,0.506-3.995,0.844-3.85,3.564"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M350.28,412.12c0.56,0,1.192,0.169,1.665-0.034c1.086-0.466,2.606-1,3.019,0.078c0.671,1.756,0.668,3.792,0.775,5.719
				c0.021,0.386-0.834,0.491-1.379,0.481c-1.04-0.016-2.103-0.136-3.114,0.033c-1.015,0.168-2.613-0.913-2.889,1.162
				c-0.37,2.78,0.231,4.102,2.414,4.499c0.842,0.153,1.459,0.397,1.898,1.028c2.103,3.024,4.194,6.057,6.234,9.124
				c0.56,0.841,0.923,2.122,0.408,2.803c-0.605,0.801-1.85,1.229-3.031,1.02c-3.666-0.653-7.134-1.939-10.563-3.349
				c-0.609-0.251-1.224-0.983-1.905-0.233c-0.678,0.747-0.112,1.464,0.233,2.146c0.209,0.415,0.513,0.784,0.708,1.205
				c1.091,2.36,2.789,2.85,5.046,1.678"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M170.76,542.2c-3.679,3.084-8.225,3.757-12.725,4.532c-0.261,0.045-0.641,0.271-0.984-0.19c-0.46-0.619-0.094-0.89,0.272-1.219
				c0.548-0.492,1.117-0.963,1.677-1.443"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M69.96,501.88c0.874,1.794-0.334,3.155-1.254,4.275c-2.151,2.619-4.539,5.052-6.934,7.457c-0.738,0.74-1.215,1.521-0.887,2.411
				c0.386,1.045,1.494,0.698,2.355,0.736"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M339.72,722.44c-5.392,2.354-11.058,4.136-15.634,8.121c-1.546,1.346-3.485,0.305-5.246,0.038"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M431.88,401.08c-1.526,0.717-1.567,0.733-1.187,1.675c0.301,0.746,0.96,1.675-0.031,2.125c-4.027,1.829-3.232,5.662-3.163,8.676
				c0.065,2.867-0.501,4.644-3.059,5.765"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M68.04,67.24c-4.613,3.298-6.782,8.985-3.94,14.214c1.664,3.063,4.571,3.859,7.085,5.427c1.198,0.747,2.645,1.1,3.815,1.959"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M559.08,647.08c-6.086,2.831-11.769,6.267-16.561,11.04"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M38.28,690.52c1.289,0.667,2.177,0.031,2.867-0.969c0.607-0.881,1.297-2.022,2.383-1.594c1.275,0.503,1.757,1.933,1.94,3.284
				c1.028,7.588,0.398,15.192,0.355,22.799c-0.016,2.84-0.927,5.305-1.542,7.921c-0.915,3.887-0.863,7.821-0.476,11.758
				c0.57,5.798,0.206,11.501-1.717,17.031c-0.353,1.015-0.728,2.225-2.13,2.41"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M310.2,731.32c-2.297,2.851-3.524,6.331-5.513,9.365c-0.767,1.169-1.246,2.606-2.223,3.536c-1.017,0.966-0.976,1.841-0.665,2.938"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M435,508.12c2,0.56,3.973,1.278,6.01,1.623c1.322,0.225,2.504,0.58,2.306,1.965c-0.17,1.192-0.921,2.435-2.556,2.414
				c-1.039-0.013-2.08,0.021-3.12-0.007c-1.632-0.045-3.33-0.315-3.87,1.918c-0.104,0.435-0.675,0.572-1.152,0.769
				c-1.605,0.658-2.594,1.643-1.511,3.588c0.249,0.448,0.497,1.794-0.429,1.829c-4.171,0.158-6.712,3.596-10.3,4.91
				c-1.432,0.525-2.849,0.93-4.214,1.133c-1.347-1.956,1.197-3.212,0.836-5.021"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M578.04,550.36c1.585,1.84,0.438,3.722-0.036,5.511c-0.775,2.929-2.487,5.66-1.654,8.892c0.077,0.3,0.148,0.795-0.01,0.934
				c-2.453,2.146-1.315,4.698-0.988,7.229c0.182,1.405,0.613,2.823,0.083,4.326c-0.759,2.148,1.183,3.764,1.405,5.748"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M562.92,99.16c0.281,2.37,0.669,4.715-2.392,5.783c-3.043,1.062-7.896,0.37-9.623-1.449c-0.97-1.021-0.258-1.347,0.478-1.955
				c3.044-2.517,4.529-7.011,1.551-11.324c-0.86-1.247-2.005-1.417-3.002-2.051c-1.601-1.018-3.175-2.258-5.252-2.205"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M497.4,250.12c-0.578-2.184-1.503-4.039-3.573-5.324c-2.24-1.39-2.93-3.995-4.114-6.193c-0.816-1.514-1.494-3.401-3.833-1.923"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M539.88,619.48c-5.752-2.368-11.608-2.407-17.517-0.707c-1.906,0.549-3.821,1.151-5.283,2.627"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M509.88,544.36c3.259,1.641,6.815,2.272,10.305,3.18c1.955,0.508,3.714,1.854,4.75,3.579c1.14,1.9,2.741,1.645,4.386,1.634
				c3.954-0.026,7.935-0.553,11.546,1.888c2.095,1.416,4.454,2.439,6.693,3.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M276.6,625.96c-4.556-2.477-9.178-4.757-14.392-5.571c-0.699-0.109-1.288-0.183-1.928,0.052"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M81,630.04c-6.023,1.633-11.924,3.602-16.745,7.744c-0.955,0.82-2.346,1.091-2.935,2.336"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M293.64,630.76c2.571-0.122,5.077-0.82,7.68-0.737c6.442,0.205,12.263,1.789,17.084,6.451c2.519,2.437,5.397,4.503,7.636,7.246"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M183,600.28c5.86,1.666,11.361,4.097,16.32,7.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M128.88,116.68c0.635,1.814,1.042,3.679,2.247,5.304c2.395,3.229,3.91,3.6,7.239,1.425c0.354-0.23,0.794-0.329,1.194-0.489"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M285.48,317.32c-3.684,1.93-7.833,2.109-11.766,3.099c-1.486,0.374-3.034,0.501-4.554,0.742"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M586.2,517.48c-0.88,0-1.761,0-2.641,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M153,649.48c5.979,3.361,11.438,7.416,16.32,12.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M322.2,259.24c-4.787,4.584-9.134,9.587-13.688,14.393c-0.427,0.45-0.953,0.807-1.433,1.207"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M345.72,590.44c-3.521-2.648-7.279-5.025-11.763-5.209c-5.561-0.228-11.05,0.538-15.8,3.822c-0.626,0.433-0.957,0.345-1.478,0.187"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M472.44,716.2c-0.489-2.043-2.129-3.311-3.398-4.768c-3.123-3.582-6.423-7.012-10.042-10.112"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M408.84,713.32c2.371-4.442,6.798-6.008,11.054-7.645c9.803-3.769,19.431-3.101,28.786,1.645"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M569.4,246.04c-1.487,1.706-2.256,3.836-3.312,5.786c-0.649,1.2-1.148,2.355-2.688,2.359c-1.605,0.003-2.444-1.226-3.092-2.4
				c-2.075-3.758-1.953-9.857,1.411-12.945"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M423,743.08c6.031,1.35,11.875,3.174,17.04,6.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M376.44,528.28c2.139,4.626,3.993,9.459,7.944,12.932c2.168,1.905,1.692,4.907,3.32,6.999c1.238,1.592,2.027,3.452,2.934,5.249
				c1.308,2.594,3.996,3.494,6.454,4.55c1.3,0.558,2.486,1.051,2.627,2.67"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M472.92,298.6c4.88-0.88,9.772-1.7,14.633-2.677c1.439-0.289,2.498-0.177,2.124,1.467c-1.134,4.975-1.766,10.073-3.557,14.89"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M439.56,190.6c-0.259-1.98-1.693-2.752-3.353-3.38c-1.04-0.394-1.905-0.192-2.633,0.514c-0.742,0.72-0.733,1.833-0.421,2.595
				c0.335,0.812,1.155,1.501,2.329,1.244c1.348-0.296,2.718-0.493,4.077-0.733"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M328.44,337.96c-1.8,2.759-0.016,5.384,0.573,7.898c1.521,6.501,2.529,13.185,5.639,19.236c0.167,0.325-0.258,0.66-0.692,0.706"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M269.4,468.52c-4.32-0.64-8.646-1.245-12.957-1.938c-1.45-0.233-2.02,0.397-2.164,1.699"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M443.64,343.72c3.866,3.979,8.755,6.397,13.72,8.55c2.073,0.899,3.107,1.62,2.271,3.941c-0.36,1.001-0.445,2.096-0.821,3.127
				c-0.192,0.53-0.471,1.649,0.91,1.421"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M323.64,544.36c-3.555,0.498-6.005,3.057-8.837,4.867c-0.582,0.373-0.753,1.509,0.665,1.896c1.652,0.452,3.212,1.236,4.812,1.876"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M50.04,745.24c-2.176,2.941-2.618,6.601-4.048,9.854c-0.564,1.284-1.172,2.592-1.26,4.064c-0.039,0.651-0.292,1.361-1.172,1.441"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M517.08,347.56c3.795,3.777,8.174,6.867,12.303,10.244c1.092,0.894,1.966,2.104,3.297,2.716"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M362.04,709.24c-0.008-1.921-1.255-3.301-2.178-4.789c-3.955-6.384-10.105-10.088-16.536-13.463
				c-4.244-2.229-8.657-4.024-13.206-5.508"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M394.44,506.44c3.213,4.067,6.54,4.853,11.513,2.624c1.417-0.635,2.968-0.837,4.348-1.628c1.267-0.727,2.2-1.655,2.862-3.114
				c2.315,1.948-0.68,4.337,0.79,6.265c1.599-1.38,3.391-2.594,3.307-5.107c-0.017-0.513,0.472-1.035,0.932-0.924
				c1.646,0.398,3.021,1.543,4.806,1.683c1.034,0.081,0.646,1.351,0.843,1.923c0.876,2.557,1.982,3.488,4.431,2.804
				c0.546-0.152,1.006-0.386,1.441,0.044c0.631,0.623,0.026,1.175-0.253,1.659c-0.397,0.688-0.875,1.357-1.439,1.911
				c-0.446,0.438-1.075,0.687-1.551,0.976c1.474,1.9,3.191,0.701,4.451,1.445"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M167.88,486.04c-3.454,3.931-10.406,5.034-15.327,2.284c0.796-0.316,1.461-0.58,2.127-0.844"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M508.68,625.24c-7.271-1.836-14.692-1.388-22.08-1.429c-0.719-0.004-1.48-0.219-1.978,0.103c-0.138,5.002,0.957,9.665,2.367,14.313
				c0.403,1.328,0.779,1.678,2.011,1.678s2.042-0.193,1.949-1.707c-0.044-0.717,0.679-1.352,0.451-2.158"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M514.68,206.68c-0.479,0.96-0.898,1.957-1.451,2.873c-1.735,2.88-1.663,4.99,0.506,7.676c1.28,1.584,2.689,3.066,4.069,4.569
				c3.07,3.342,3.589,3.317,6.716-0.237"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M444.36,619.48c6.148-2.622,12.369-2.801,18.73-0.756c1.931,0.621,3.699,1.416,5.27,2.676"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M116.28,554.2c-0.394,0.726-0.764,1.445-1.677,1.688c-1.655,0.441-2.469,1.789-3.382,3.099c-2.534,3.632-4.929,7.346-6.917,11.305
				c-0.403,0.804-0.914,1.095-1.702,0.936c-0.899-0.182-0.968-0.869-0.985-1.667c-0.024-1.149,0.415-2.428-0.697-3.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M66.6,706.12c1.552-1.034,1.29-2.876,1.908-4.325c0.331-0.773,0.444-1.722,1.455-1.897c0.926-0.162,1.369,0.626,1.92,1.18
				c1.473,1.478,1.656,3.691,0.222,5.993c-0.879,1.411-1.905,2.729-2.865,4.09"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M65.16,716.44c-0.56,1.92-1.349,3.81-1.612,5.769c-0.233,1.736,0.568,3.485,0.795,5.252c0.221,1.716,0.198,3.023-0.565,4.607
				c-1.566,3.246-2.275,6.812-3.137,10.302c-0.14,0.565-0.488,0.811-0.986,1.519c-1.476-3.24-2.197-6.504-3.127-9.691
				c-0.064-0.221-0.112-0.57,0.007-0.704c2.803-3.131,3.796-7.365,6.465-10.573"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M387.48,624.52c-5.174-3.693-10.477-3.068-15.841-0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M253.32,589.48c-5.325-2.903-10.61-3.147-15.84,0.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M212.76,656.92c-4.219-3.94-8.616-7.639-13.924-10.069c-0.524-0.239-1.115-0.331-1.675-0.49"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M461.16,191.32c-0.506-1.056-1.385-1.773-2.36-0.915c-1.34,1.178-0.11,2.625,0.285,3.765c0.495,1.429,2.349,1.871,3.535,2.071
				c3.593,0.606,5.336,2.931,6.703,5.877c0.785,1.695,1.665,3.396,3.38,4.277c1.021,0.524,2.266,0.565,3.337-0.195"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M488.52,208.6c1.502-0.668,2.728-0.214,3.332,1.212c1.682,3.965,3.646,7.889,4.632,12.035c0.419,1.765,0.583,3.803,0.904,5.715
				c0.282,1.673-0.466,2.982-1.668,4.078"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M331.8,734.68c-0.295,1.11-0.221,2.043,0.434,3.148c3.175,5.358,5.845,10.992,8.743,16.502c2.653,5.048,4.973,10.194,6.652,15.633
				c0.314,1.018,0.491,2.077,0.731,3.117"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M537.48,726.04c6.548,1.841,12.625,4.754,18.463,8.188c0.602,0.354,0.977,1.093,1.457,1.652"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M170.28,509.56c1.192-0.708,2.525-2.216,3.762-0.657c1.042,1.312-0.076,2.65-1.101,3.797c-1.288,1.44-2.368,3.066-3.635,4.528
				c-1.336,1.541-0.671,2.128,0.975,2.147c1.576,0.02,1.833,0.674,1.195,1.942c-0.449,0.896-2.254,1.173-1.406,2.625
				c0.841,1.438,2.372,0.49,3.571,0.737"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M429.72,180.76c-0.485,0.635-1.379,0.844-1.684,1.679c-1.521,4.167-5.172,4.751-8.874,5.298c-1.225,0.181-2.533,0.132-3.603,0.944"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M533.88,737.56c-1.66,0.501-3.36,0.159-5.04,0.229c-1.357,0.056-2.722,0.059-4.079,0c-2.229-0.097-4.522,1.742-6.407,0.821
				c-2.166-1.06-2.621-4.077-4.374-5.869c-0.911-0.932-1.619-2.062-2.42-3.102"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M455.88,693.4c6.835,4.633,12.146,10.895,17.663,16.889c1.278,1.39,2.396,3.459,2.497,5.671"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M43.56,411.64c-3.505-0.088-2.746-0.073-2.808,3.357c-0.109,5.974,4.111,6.863,8.088,8.403"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M256.2,577.24c-4.776-2.385-9.799-2.219-14.88-1.438c-2.576,0.396-4.95,1.359-7.193,2.651c-0.529,0.305-0.98,0.387-1.444-0.017
				c-0.591-0.516-0.128-0.868,0.241-1.192c0.485-0.427,0.307-1.016,0.206-1.438c-0.699-2.932-0.133-5.952-0.689-8.887"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M414.6,735.88c-3.869-0.939-7.807-1.019-11.76-0.975c-1.199,0.014-2.399-0.146-3.6-0.226"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M279.96,359.8c-0.08,4.08-0.17,8.16-0.233,12.24c-0.017,1.102,0.039,2.201-0.727,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M57,480.76c-3.206,1.693-6.582,1.997-10.078,1.193c-1.21-0.277-2.387-0.73-3.607-0.918c-0.771-0.118-1.675-0.675-2.394,0.205
				c-0.754,0.922,0.545,1.431,0.447,2.155c-0.097,0.722,2.018,1.683-0.208,2.164"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M565.32,86.68c0.861,0.634,2.109-0.28,2.881,0.716c-1.552,1.711-3.245,2.365-6.721,2.386c-2.24,0.014-4.5,0.001-6.71-0.299
				c-0.789-0.107-1.312,0.059-1.931,0.316"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M586.92,590.68c-4.374-2.966-9.022-2.445-13.646-0.861c-0.523,0.179-1.3,0.04-1.714,0.622"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M352.68,727.72c-5.263,0.303-10.186,1.517-14.165,5.275c-0.338,0.319-0.559,0.878-1.194,0.725"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M518.04,208.84c-0.794,2.05-0.204,3.857,0.653,5.79c0.717,1.618,1.991,1.567,3.188,1.596c1.214,0.029,2.172-0.533,2.202-2.104
				c0.019-0.987,0.974-1.795,0.916-2.881"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M540.84,255.4c-0.915,3.543-0.847,7.198-1.174,10.802c-0.719,7.926-1.438,15.77-4.453,23.331
				c-1.625,4.073-3.508,7.926-5.624,11.723c-0.646,1.158-1.722,2.265-1.229,3.824"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M75.96,604.6c-5.628-2.66-11.553-3.707-17.761-3.385c-1.671,0.087-3.38-0.325-5.031-0.143c-0.96,0.106-2.21,0.049-2.888,1.128"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M512.04,300.76c0.691-4.616,1.797-9.145,2.867-13.683c0.366-1.55,0.933-2.094,2.67-1.486c4.173,1.461,4.133,1.337,2.886,5.575
				c-1.146,3.889-2.103,7.833-3.143,11.753"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M200.28,91.72c-4.368-0.761-8.548-1.903-11.778-5.262c-1.018-1.058-1.545-2.614-3.102-3.138"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M419.64,380.44c0.452,2.351,0.525,4.6-0.931,6.74c-1.109,1.629-1.636,3.511-2.04,5.481c-0.248,1.211,0.131,1.986,0.571,2.899"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M308.76,724.36c0.327,1.313,0.127,2.348-0.635,3.649c-2.164,3.697-3.857,7.667-5.895,11.443c-0.482,0.895-0.934,2.038-2.11,2.427"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M234.12,566.44c-1.28,0.862-2.446-0.486-3.867-0.101c-2.047,0.554-4.489-0.52-6.044,1.976c-0.448,0.718-1.832,0.71-2.804,0.502
				c-2.016-0.432-4.004,0.006-6.005,0.004c-1.985-0.001-3.304,0.752-4.118,2.643c-0.564,1.31-0.7,2.991-2.362,3.616"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M163.56,199.24c-3.312-1.246-6.561-2.066-9.613,0.706c-1.765,1.603-1.976,2.714-0.197,4.054c1.372,1.035,2.793,2.378,4.764,1.931
				c1.094-0.248,1.589,1.16,2.646,0.75"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M341.16,663.16c-3.586,0.019-6.673,1.919-10.068,2.691c-2.306,0.524-4.171,2.083-6.012,3.549"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M436.2,660.04c-3.961,0.196-7.522,1.893-10.997,3.456c-4.62,2.079-9.129,4.55-12.548,8.522c-0.313,0.364-0.771,0.829-1.415,0.741"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M389.88,726.28c0.269,1.695-0.439,3.129-1.195,4.562c-1.854,3.513-3.661,7.05-5.539,10.55c-0.579,1.081-1.33,2.069-2.386,2.768"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M471.48,575.08c-1.393-1.783-1.237-3.605-0.258-5.528c0.702-1.375,1.413-2.732,1.781-4.292c0.204-0.867-0.089-1.237-0.58-1.416
				c-0.61-0.221-1.06,0.145-0.949,0.917c0.076,0.533-0.152,0.937-0.715,0.943c-0.584,0.009-0.533-0.64-0.771-0.896
				c-1.281-1.381-0.08-2.694,0.146-3.869c0.45-2.322,1.435-4.551,1.325-6.978c-0.045-0.997,0.324-1.734,1.227-1.88
				c1.099-0.178,1.504,0.894,1.623,1.646c0.247,1.574,0.546,3.263,0.229,4.779c-1.043,4.981,1.908,7.919,5.101,10.813"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M36.12,686.44c-3.399-3.551-7.64-5.822-11.997-7.927c-2.705-1.307-5.35-2.756-8.403-3.113"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M103.08,430.6c-2.173,1.117-4.46,1.931-5.596,4.527c-0.745,1.704-2.594,2.078-4.486,1.984c-1.639-0.081-3.696-0.752-3.913,2.123
				c-0.039,0.52-1.042,0.971-1.607,1.444c-0.957,0.804-2.016,1.511-2.859,2.42c-1.887,2.032-1.723,2.801,0.7,4.302"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M545.88,224.2c-1.236,3.594-0.205,5.956,1.688,7.67c1.834,1.661,4.295,1.628,7.191,0.009"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M184.68,81.64c-0.07,3.676,1.074,7.541-1.68,10.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M285.96,601.96c-2.787,1.184-5.841,1.453-8.654,2.606c-2.876,1.179-5.707,2.383-8.146,4.354"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M478.2,280.6c-1.541,2.069-1.881,4.594-2.641,6.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M355.8,530.44c0.944,0.073,1.657,0.698,1.88,1.452c0.904,3.072,0.646,6.22,0.262,9.345c-0.094,0.757-0.694,1.408-1.415,1.355
				c-0.301-0.022-0.75-0.859-0.758-1.593c-0.014-1.339,0.573-3.18-1.651-3.31c-1.012-0.059-2.248,3.074-1.628,4.487
				c0.709,1.615,0.469,3.062,0.122,4.669c-1.292-0.526-1.682-1.623-2.331-2.486"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M498.6,262.12c-4.915,0.984-9.876,1.615-14.88,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M215.16,593.32c-2.46-0.69-4.996-0.987-7.427-0.197c-3.367,1.094-6.554-0.45-9.855-0.346c-3.843,0.121-7.333,1.271-10.798,2.703"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M248.52,573.64c-5.215-1.335-10.051,0.265-14.88,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M264.6,736.36c-0.629-1.843-2.462-1.934-3.859-2.336c-3.419-0.984-6.961-1.391-10.541-1.265"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M150.12,549.88c0.606,2.6,2.429,3.6,5.759,3.115c2.389-0.347,4.749-1.101,7.201-0.476"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M531.72,584.68c-2.39-0.391-5.055-0.895-7.148-0.104c-4.965,1.874-10.617,1.292-15.171,4.424"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M376.2,527.8c-0.862,1.453-2.746,8.22-2.361,9.104c0.281,0.647-0.134,1.84,1.404,1.753c1.424-0.081,1.152-0.896,1.218-1.734
				c0.026-0.334,0.285-0.648,0.435-0.965c1.621,0.223,1.253,1.703,1.692,2.648c0.771,1.66,0.001,3.387-0.119,5.025
				c-0.106,1.451-2.323,1.307-3.848-0.386c-0.664,1.064-1.522,1.845-0.648,3.315c0.367,0.618,0.32,1.779-0.652,2.359"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M322.92,469.24c1.997,0.956,4.169,0.331,6.251,0.649c1.756,0.269,2.267,1.027,1.625,2.45c-0.541,1.198-1.271,2.511-2.837,2.643
				c-2.562,0.215-4.136,0.986-3.961,4.104c0.079,1.399-1.158,2.873-1.798,4.313"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M515.88,469.72c-6.285,0.952-12.639,1.135-18.956,1.721c-2.503,0.232-4.97,0.895-7.439,1.422c-0.484,0.103-1.075,0.315-1.195,0.939
				c-0.146,0.76,0.273,1.176,0.949,1.443c2.026,0.801,3.192,2.406,4.103,4.305c0.494,1.031,1.414,1.381,2.614,1.501
				c1.941,0.195,4.019,0.481,3.861,3.311c-0.078,1.392,1.026,1.245,1.902,1.187c2.013-0.135,3.762,0.62,5.527,1.439
				c2.766,1.283,5.619,2.337,8.634,2.893"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M518.76,323.56c2.541-0.863,4.093-3.154,3.073-4.771c-0.47-0.745-1.082-1.626-2.355-1.5c-1.732,0.171-2.608,1.399-3.022,2.701
				c-0.279,0.876-0.279,2.295,1.104,2.854c0.33,0.134,0.642,0.316,0.961,0.476"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M392.04,537.64c-1.937-1.263-3.174-3.226-4.794-4.807c-0.453-0.441-0.886-0.798-1.438-0.226c-0.428,0.443-0.393,0.953-0.006,1.432
				c0.07,0.087,0.222,0.156,0.229,0.242c0.065,0.881,1.796,1.879,0.212,2.551c-1.112,0.474-1.696-0.9-2.11-1.837
				c-0.84-1.903-1.539-3.871-2.457-5.732c-0.268-0.543-0.631-1.667-1.61-1.174c-0.581,0.294-1.327,0.821-0.787,1.893
				c0.326,0.647-0.068,1.397-0.678,1.898"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M427.8,295.96c1.238,0.874,0.567,2.055,0.471,3.119c-0.293,3.203-0.689,6.397-0.899,9.604c-0.039,0.617,0.589,1.277,0.909,1.917"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M284.52,525.4c-0.16,0.08-0.467,0.185-0.458,0.236c0.149,0.876,1.07,1.912,0.173,2.584c-0.96,0.719-2.173,0.304-3.33-0.145
				c-1.917-0.742-3.694-1.734-5.529-2.629c-0.78-0.381-1.471-1.502-2.617-0.768"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M227.4,773.32c1.885-2.316,1.699-5.566,3.4-7.891c3.027-4.137,1.507-8.788,1.907-13.228c0.153-1.694,0.662-3.354,0.919-5.044
				c0.252-1.659-0.083-3.155-2.145-3.089c-4.56,0.146-9.151-0.559-13.681,0.45"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M153.96,678.76c-5.92-0.437-11.841-0.233-17.759,0.029c-0.729,0.031-1.441,0.451-2.161,0.691"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M90.12,689.32c-1.6-3.681-3.146-7.385-4.827-11.027c-0.645-1.396-1.696-2.571-2.853-3.613"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M422.28,617.8c3.04,0.96,6.138,1.769,9.107,2.911c6.319,2.433,12.669,4.862,17.765,9.578c0.47,0.436,0.974,0.113,1.447,0.23"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M521.88,624.04c-6.041-3.376-12.645-4.683-19.439-5.28"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M189.48,685c-2.248-0.074-1.988,1.835-2.379,3.126c-1.43,4.714-1.008,9.58-0.91,14.393c0.028,1.371-0.005,2.64-0.569,3.833
				c-1.222,2.588-2.428,5.195-3.874,7.658c-0.645,1.098-0.37,2.115-0.389,3.151c-0.022,1.202,1.553,0.733,1.57,0.69
				c1.367-3.44,5.751-4.529,6.533-8.375c0.092-0.453,0.542-0.639,0.531-1.197c-0.038-1.869,1.207-2.6,2.847-2.879"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M479.88,328.84c-3.576,0.599-7.183,0.731-10.8,0.725c-1.505-0.002-3.059-0.207-4.325,0.95c-0.699,0.639-1.327,0.018-1.907-0.251
				c-1.859-0.862-3.627-1.895-5.301-3.173c-5.953,9.367-13.344,17.128-23.496,21.931c-0.481,0.228-0.811,0.778-1.211,1.178"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M238.2,449.08c0.016,1.55-2.015,1.474-2.273,3.103c-0.244,1.532-0.768,2.286,1.284,2.982c4.277,1.453,4.789,2.536,4.109,6.155"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M355.56,617.8c-3.151-1.161-6.327-0.313-9.328,0.379c-1.768,0.408-3.939,0.607-5.312,2.262"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M493.32,278.8c-2.227,1.389-4.826,0.989-7.211,1.193c-2.525,0.215-5.089,0.951-7.669,0.367"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M478.2,280.36c-0.479-1.274,0.812-2.168,0.72-3.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M426.12,758.44c4.944,1.368,9.953,2.571,14.174,5.741c0.294,0.221,0.786,0.178,1.187,0.259"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M15.72,751.48c0.567-2.659,2.657-4.359,3.954-6.686c1.526,1.144,0.723,2.415,0.312,3.3c-1.572,3.391-3.359,6.684-4.254,10.348
				c-0.092,0.378-0.012,0.798-0.012,1.197"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M557.16,79.24c1.427-1.759,3.184-3.353,2.67-6.006c-0.092-0.475,0.419-0.811,0.929-0.957c5.598-1.604,10.141-1.568,14.613,3.149
				c3.076,3.243,2.269,6.827-0.692,9.814c-0.838,0.846-2.021,1.312-2.64,2.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M574.92,658.36c-4.715-1.285-9.607-1.389-14.4-2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M493.56,251.32c0.378,2.15,1.462,4.017,2.676,5.735c2.267,3.209,3.131,6.256,1.268,10.154c-1.687,3.527-2.503,7.471-3.703,11.231"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M93,701.32c-1.916,0.561-0.366,2.359-0.297,2.584c1.64,5.372,1.973,11.084,3.804,16.324c1.45,4.149,1.928,8.282,2.243,12.532
				c0.064,0.865,0.16,1.788-0.709,2.399"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M232.44,751.96c-0.548,0.411-1.542,0.309-1.471,1.442c0.029,0.468-0.2,1.014-0.462,1.43c-3.67,5.829-5.561,12.43-8.147,18.728"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M33.72,447.88c-1.491,0.297-2.859-0.559-4.319-0.463c-0.477,0.032-0.967-0.255-1.458,0.205c-0.811,0.762,0.183,1.501,0.319,1.633
				c1.508,1.464,2.271,3.601,4.238,4.66c0.448,0.241,1.098,0.633,0.979,1.405"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M530.04,522.04c-4.247,2.116-8.367,0.32-12.454-0.595c-0.71-0.159-1.47-0.355-1.917-0.079c-2.265,1.396-4.916,1.815-7.262,3.01
				c-0.689,0.351-1.146,0.929-0.88,1.222c1.816,2.003-0.59,3.233-0.777,4.842c-0.001,0.009-0.167,0.029-0.227-0.006
				c-0.094-0.056-0.163-0.153-0.243-0.233"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M483.48,656.68c-2.951-4.299-7.209-6.693-11.971-8.479c-0.757-0.285-1.392,0.578-1.95-0.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M534.84,592.84c2.164,1.396,4.262,2.866,6.012,4.789c0.547,0.602,1.028,1.675,2.149,0.732c1.163-0.978-0.307-1.562-0.553-1.862
				c-5.181-6.327-11.887-9.74-20.088-10.147c-0.243-0.012-0.48-0.151-0.721-0.231"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M531.48,385.24c-1.891-2.739-5.045-3.319-7.706-1.723c-1.916,1.149-4.124,0.481-6.215,0.282"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M463.32,585.64c-1.613,1.127-3.558,0.4-5.283,0.951c-6.527,2.084-12.83,4.48-16.341,11.036c-0.053,0.101-0.456,0.013-0.696,0.013"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M505.08,305.08c-0.72,3.04-1.59,6.054-2.121,9.126c-0.623,3.597-1.674,7.065-2.679,10.553"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M488.52,345.88c-2.961,2.195-5.444,5.088-9.119,6.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M224.28,208.12c1.503,3.316,0.663,6.005-2.166,6.697c-0.956,0.234-1.793,0.133-2.201-1.163c-0.468-1.487-1.333-2.83-2.587-3.861
				c-0.459-0.378-0.867-0.422-1.454-0.261c-0.895,0.245-0.66,0.886-0.716,1.468c-0.163,1.696,0.789,2.813,2.187,3.293
				c0.955,0.327,2.384,0.14,2.771,1.063c0.509,1.214,0.589,2.692,0.531,4.042c-0.167,3.92-3.022,7.191-6.925,8.162
				c-2.829,0.704-6.295-0.686-7.122-3.147c-0.293-0.874,0.254-2.073,0.548-3.085c0.166-0.572-0.188-0.831-0.387-1.208"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M465,551.08c2.145-1.443,2.612-3.616,2.653-6c0.011-0.657-0.222-1.333,0.429-1.959c0.391-0.376,0.604-1.095,1.222-0.877
				c0.49,0.174,0.799,0.694,0.769,1.397c-0.039,0.914-0.425,2.425,0.698,2.522c1.271,0.11,1.968-1.191,2.165-2.521
				c0.039-0.265,0.275-0.5,0.463-0.819c0.267,0.124,0.635,0.176,0.701,0.345c1.619,4.112,5.023,6.774,7.938,9.834
				c2.214,2.322,1.911,3.95-0.958,5.278"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M274.92,601.72c-4.623,0.531-9.018,1.731-12.972,4.303c-0.512,0.333-0.986,0.619-1.188,1.218"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M592.08,561.16c-0.561,3.492,1.068,6.584,1.8,9.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M420.84,387.16c-0.351,2.076,1.062,3.764,1.243,5.756c0.263,2.895,0.381,5.764-0.043,8.644"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M458.76,116.44c1.657,4.111,0.507,6.74-3.359,7.68c-3.325,0.808-5.575-0.879-5.761-4.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M587.88,351.64c3.552,0.505,6.274,2.805,9.358,4.323c0.577,0.284,0.208,0.811,0.219,1.198c0.051,1.839,0.023,3.68,0.023,5.52"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M530.76,489.16c-2.457-1.602-4.8-3.389-7.917-3.629c-2.08-0.16-2.541-2.65-4.438-3.262c-0.678,0.891-1.089,1.834,0.109,2.818
				c0.552,0.453,0.194,1.114,0.23,1.673c0.07,1.101-0.281,2.405,1.709,1.718c1.102-0.381,1.534,0.678,1.123,1.363
				c-0.568,0.945,1.607,2.521-0.878,3.01c-0.927,0.183-2.614-0.73-2.872,1.112c-0.197,1.414,0.269,2.374,1.565,3.461
				c2.244,1.881,3.371,4.729,4.143,7.582c0.309,1.141-0.494,1.724-1.656,1.441c-2.161-0.525-4.09-1.56-5.998-2.648"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M292.2,374.92c-3.292,3.974-6.653,7.883-10.315,11.525c-0.689,0.686-1.344,1.39-2.405,1.435"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M499.08,626.2c-2.292-0.567-4.649-0.666-6.954-0.427c-2.332,0.243-4.647,0.079-6.966,0.187"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M273.96,751.72c-3.951-0.946-7.852-2.085-12.001-2.018c-0.792,0.013-1.448,0.174-2.159,0.338"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M438.6,347.08c4.147,4.019,9.181,6.543,14.365,8.956c4.046,1.882,8.131,1.428,12.275,1.367c1.279-0.019,2.56-0.003,3.84-0.003"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M444.6,567.16c1.853-0.019,3.208-0.708,4.632-2.086c3.187-3.082,6.318-6.089,7.878-10.384c0.29-0.801,0.901-1.808,1.881-1.635
				c1.396,0.246,0.423,1.587,0.756,2.333c1.76,3.953-1.243,6.305-3.508,8.01c-2.699,2.031-3,4.334-2.998,7.121"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M163.8,677.08c2.619,4.259,4.916,8.816,6.225,13.511c0.84,3.011,1.132,6.372,1.936,9.529"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M596.76,488.92c-1.372,4.834-5.401,6.831-9.294,9.227c-2.54,1.563-3.868,4.709-6.531,6.396c-1.579,1.002-3.572,1.563-4.335,3.577"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M499.56,594.52c-0.354-0.881-1.126-0.448-1.68-0.455c-4.08-0.046-8.16-0.024-12.24-0.024"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M589.32,607c-3.932,0.181-7.922-0.535-11.771,0.931c-1.147,0.437-2.182,1.446-3.589,0.989"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M146.04,734.2c1.235,2.005,1.021,4.32,1.128,6.483c0.159,3.207-0.609,6.334-2.088,9.236"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M275.16,438.28c-0.4-0.081-1.005,0.251-1.178-0.248c-0.774-2.233-2.837-2.972-4.589-4.061c-0.806-0.501-2.222-0.984-1.806-1.883
				c0.568-1.229,1.919-1.106,3.271-0.573c2.418,0.957,4.984,1.538,7.402,2.496c0.992,0.393,1.921,0.441,2.9,0.418
				c2.077-0.051,2.788-1.271,1.929-3.114c-1.135-2.435-3.509-3.428-5.553-4.757c-1.314-0.855-2.502-1.801-3.574-2.92
				c-0.703-0.734,0.371-0.777,0.479-1.198"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M320.04,467.08c-1.56,0.317-2.088-1.325-3.341-1.74c-2.729-0.904-5.438-0.843-8.173-0.383c-0.84,0.141-1.606,0.343-2.406-0.037"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M368.28,387.88c-1.917,2.777-5.254,3.088-7.924,4.555c-1.944,1.068-4.235,1.732-4.797,4.325"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M478.2,281.8c3.796,4.282,7.161,8.878,10.08,13.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M43.08,389.8c-4.109,3.971-9.002,6.803-13.92,9.601"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,681.4c-2.667,0.596-5.086,1.687-7.491,3.029c-1.973,1.102-4.3,0.181-6.43-0.39"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M162.6,682.6c1.861,2.58,2.583,5.479,2.828,8.635c0.146,1.869,0.908,3.706,1.504,5.521c1.545,4.705,1.708,9.726,3.348,14.404
				c0.561,1.6,1.12,3.2,1.68,4.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M504.84,693.4c5.306,1.788,10.549,3.679,14.898,7.418c1.183,1.017,2.363,1.981,3.102,3.382"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M474.84,74.44c0.474,2.896,1.923,5.303,3.86,7.421c0.654,0.715,1.437,1.418,2.629,0.755c1.401-0.779,1.791-2.106,1.376-3.358
				c-1.004-3.028-3.501-4.875-6.438-5.174c-3.114-0.316-6.615-0.309-8.606,3.009c-1.462,2.436-1.04,4.876,0.459,7.188"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M78.84,423.88c0.23,1.754,0.671,3.499,0.466,5.278c-0.203,1.759,0.783,2.173,2.169,1.896c1.929-0.386,3.618,0.157,5.412,0.896
				c-0.868,1.24-2.255,1.448-3.286,2.195c-1.641,1.19-0.882,1.763,0.279,2.454"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M366.6,606.76c-4.075-3.244-8.811-4.473-13.92-4.56"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M375.96,759.64c-2.424,4.389-3.826,9.164-5.28,13.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M495.48,508.84c-0.031-0.77-0.679-1.155-1.201-1.161c-3.441-0.046-6.837-1.463-10.325-0.297c-1.523,0.51-1.964,0.887-0.787,2.473
				c1.301,1.755,3.127,2.217,4.894,2.759c1.554,0.477,2.702,1.35,3.034,2.723c0.604,2.504,2.082,3.198,4.386,2.864
				c0.779-0.112,1.49,0.089,2.159,0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M234.84,737.56c-4.639-1.514-9.4-1.378-14.153-0.896c-0.766,0.077-1.446,0.137-2.167-0.064"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M537.24,309.16c0.053-2.071-1.424-2.327-2.871-2.101c-1.128,0.177-2.08,0.945-2.178,2.34c-0.112,1.594,1.089,2.351,2.184,2.561
				c1.761,0.337,2.753-0.867,3.105-2.56"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M510.6,233.56c-0.295,2.521,2.496,5.275,5.521,5.518c2.749,0.22,5.249-0.442,7.439-2.158"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M398.76,98.68c-1.171,0.214-1.854,0.37-1.01,1.947c3.952,7.371,11.177,10.536,18.273,5.469c1.414-1.01,2.705-2.183,4.337-2.856"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M502.44,228.28c-0.824,1.592-1.288,3.291-1.717,5.031c-0.597,2.42-1.806,4.433-4.521,5.062c-0.99,0.229-1.992,0.558-1.923,1.908"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M368.76,636.04c2.26,0.52,3.388,2.548,4.976,3.915c0.86,0.742,1.215,0.965,2.244,0.443c1.851-0.938,4.449-0.129,5.74-2.438"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M530.76,643.24c-2.885-1.726-6.074-2.52-9.363-2.84c-1.523-0.148-3.066-0.073-4.557-0.521"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M495.12,61c-0.075-2.834-3.818-6.249-6.838-6.503c-8.981-0.756-14.896,6.721-14.152,16.102c0.089,1.126,0.311,2.241,0.47,3.361"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M39,301.96c2,1.84,3.967,3.717,6.011,5.508c1.513,1.326,3.26,2.271,5.269,2.652"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M472.2,602.2c0.64,0.56,1.198,1.309,1.941,1.63c0.829,0.357,0.921,0.927,0.664,1.474c-0.436,0.933-1.096,0.249-1.645,0.013
				c-3.685-1.585-7.497-2.654-11.521-2.876"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M455.64,444.76c-0.283-0.936-1.055-1.49-1.68-2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M450.84,441.04c-2.41-0.3-4.643-0.168-6.527,1.754c-0.575,0.587-1.673,0.358-2.604,0.085c-0.89-0.263-1.894-0.102-2.121,1.047
				c-0.161,0.81,0.201,1.782,0.944,1.988c3.352,0.928,5.644,4.047,8.897,4.694c2.742,0.545,3.452,1.279,2.386,3.758
				c-0.187,0.435-0.005,1.172-0.287,1.393c-0.836,0.652-0.629,1.711-1.168,2.441"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M431.64,421.24c-3.456,1.497-7.207,1.891-10.801,2.877c-0.883,0.242-1.874,0.253-2.398,1.203"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M78.6,460.36c0.294,1.423-0.192,2.251-1.673,2.664c-3.244,0.905-6.18,2.432-8.809,4.933c-0.046-2.941-2.371-2.351-3.63-1.934
				c-1.292,0.428-2.434,0.282-3.648,0.337"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M77.4,706.36c-1.553,1.054-1.583,2.804-1.6,4.32c-0.015,1.417-0.206,2.933-0.672,4.032c-1.699,4.015,0.434,6.767,2.512,9.647"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M80.04,729.88c1.687,4.156,3.798,8.101,6,12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M67.8,103.24c0,3.388-2.707,6.853-6,7.68c-2.232,0.561-6.403-2.398-6.499-4.559c-0.028-0.642,0.381-1.02,0.752-1.17
				c2.858-1.16,5.066-3.167,7.208-5.29c0.161-0.16,0.619-0.021,0.939-0.021"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M174.84,605.8c4.479,0.911,9.057,1.459,13.2,3.601"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M299.4,329.8c3.194,8.546,8.18,15.839,14.519,22.437c2.66,2.769,5.211,5.708,8.089,8.229c5.603,4.906,11.468,9.525,17.961,13.239
				c4.691,2.683,9.43,5.286,14.385,7.471c1.321,0.583,2.693,0.898,4.087,1.185"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M469.56,315.4c3.774,3.969,6.971,8.412,10.238,12.782c0.49,0.655,0.986,0.806,1.522,1.138"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M525.24,316.36c1.404,0.835,2.714,1.301,3.856-0.469c0.953-1.477,0.648-2.939-0.323-4.032c-0.665-0.747-1.892-1.419-3.061-0.306
				c-1.479,1.408-2.146,2.854-0.473,4.567"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M231.48,552.76c-0.56,0.4-1.071,0.919-1.69,1.176c-1.363,0.565-3.114,0.872-2.593,2.896c0.729,2.831,2.754,4.348,5.004,4.095
				c0.395-0.044,0.799-0.006,1.199-0.006"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M126.84,699.64c-1.417-1.972-0.53-4.351-1.026-6.52c-0.266-1.162-0.619-2.354-1.393-2.487c-1.388-0.241-2.822,0.313-3.583,1.806
				c-2.656,5.205-4.461,10.63-4.309,16.562c0.015,0.56-0.128,1.026-0.488,1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M167.4,746.68c-2.21,0.275-3.6,1.386-4.363,3.585c-1.021,2.94-2.475,5.71-4.005,8.433c-0.436,0.774-0.823,1.432-1.711,1.663"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M347.4,605.08c-4.469,0.132-8.418,2.066-12.467,3.635c-0.343,0.133-0.646,0.157-0.974,0.205"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M541.32,598.6c-3.497,1.218-6.283,4.522-10.314,4.029c-6.414-0.785-12.485,0.424-18.486,2.451"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M585,500.2c-3.06-0.674-6.08,0.17-9.12,0.229c-2.479,0.049-5.001,0.272-7.421-0.107c-1.097-0.173-2.979-0.388-3.128-2.283
				c-0.144-1.829,1.813-0.799,2.389-1.679"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M570,494.44c0.28-1.521,0.561-3.04,0.84-4.561"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M309.96,691.96c-0.515-1.083-1.502-0.427-2.159-0.42c-4.242,0.043-8.481,0.26-12.721,0.42"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M413.76,346.12c-1.271,1.98-1.055,4.456-2.21,6.519c-0.413,0.739-0.232,1.962,0.41,2.841"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M200.04,40.84c2.149-1.886,4.652-1.595,6.934-0.656c2.709,1.116,3.954,3.564,4.339,6.417c0.12,0.89-0.256,1.865,0.487,2.639"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M160.92,457.96c0.856,1.003,0.854,1.781-0.468,2.425c-0.707,0.343-1.298,0.928-1.93,1.418c-0.671,0.52-1.042,1.392-0.658,1.996
				c0.414,0.65,1.358,0.478,2.096,0.161c1.879-0.808,3.71-0.545,5.689,0.237c-1.315,1.74-3.598,1.73-5.094,3.253
				c2.208,2.02,4.503-1.529,6.641,0.05c-1.356,2.474-4.377,3.032-5.921,5.309c1.8,1.41,4.065,1.013,6.014,1.862
				c-0.758,1.454-2.523,1.477-3.248,2.729"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M390.36,734.68c-2.32,3.84-4.617,7.694-6.988,11.503c-0.306,0.49-0.548,1.278-1.412,1.218"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M563.04,430.12c-0.78,3.035-0.479,3.586,2.516,4.811c0.596,0.243,0.797,0.784,1.003,1.417c0.432,1.321,1.083,2.571,1.641,3.853
				c0.336,0.771,0.742,1.364,1.681,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M252.84,493.96c3.344,4.457,7.34,8.337,11.346,12.638c-1.878,0.761-3.361-0.096-4.864-0.405c-1.233-0.255-2.646-0.792-3.548,0.292
				c-0.929,1.114-0.146,2.394,0.415,3.562c0.812,1.692,2.392,2.765,3.372,4.314"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M352.68,522.76c0.08-0.64,0.174-1.278,0.236-1.92c0.053-0.542,0.108-1.126-0.48-1.432c-0.605-0.313-1.037,0.24-1.416,0.501
				c-1.409,0.967-2.706,2.099-4.116,3.066c-0.544,0.373-1.121,0.77-1.903,0.778c-0.736,0.009-1.408-0.143-1.632-0.771
				c-0.18-0.505-0.125-1.084,0.375-1.709c0.883-1.106,2.637-0.904,3.177-2.354"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M216.6,742.12c-1.822,1.683-1.943,4.152-2.854,6.251c-1.687,3.886-3.125,7.944-5.842,11.222c-2.195,2.646-1.232,5.493-1.006,8.196
				c0.119,1.423,0.294,2.879,0.343,4.331"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M252.36,569.32c-6.617-0.937-12.993,0.055-19.2,2.399"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M536.76,217.96c-3.461,3.908-5.57,8.626-7.92,13.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M385.08,626.92c-3.083-2.726-6.591-2.281-10.058-1.132c-0.956,0.317-1.954,0.704-2.902,1.132"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M541.8,73.96c-1.353-0.407-1.42-2.021-2.621-2.674c-2.938-1.595-5.769-2.01-8.67,0.018c-0.397,0.278-0.949,0.336-1.429,0.496"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M327.96,620.92c4.41,0.436,8.956,0.209,12.96,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M394.68,490.36c-2.745,0.87-5.06,2.476-6.933,4.584c-3.078,3.464-6.765,5.762-11.299,6.736c-0.192,0.041-0.848,0.08-0.729,0.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M88.92,553.96c-1.154,2.8,0.471,5.613-0.02,8.396c-0.535,3.033,0.46,6.305-1.42,9.124"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M87.24,574.84c0.08,0.24,0.229,0.48,0.229,0.72c0.011,3.12,2.142,4.976,4.079,6.972c0.947,0.976,2.237,1.951,2.537,3.138
				c0.399,1.58,1.24,0.439,1.794,0.691"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,344.44c0-0.88,0-1.76,0-2.64c0-2.983,0-2.983-2.881-2.64c-0.599,0.071-1.34,0.071-1.185,0.957
				c0.333,1.89-0.786,1.815-2.168,1.984c-1.972,0.24-3.884,1.03-5.772-0.293c-1.309-0.917-2.414,0.277-3.58,0.537
				c-0.655,0.146-1.003,1.202-1.935,1.135"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M145.32,477.16c2.663,2.774,5.899,4.552,9.617,5.663c-0.229-1.37-1.43-2.065-1.723-3.381c1.066-0.517,2.005-0.418,3.177,0.038
				c0.993,0.386,2.136,0.834,3.332,0.572c0.575-0.126,1.422,0.261,1.639-0.504c0.245-0.867-0.581-0.754-1.129-1.017
				c-0.596-0.285-1.623-0.642-1.439-1.605c0.2-1.05,1.195-0.605,1.845-0.841c1.384-0.503,3.292-0.037,4.121-1.806"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M189,185.32c-1.376-0.021-2.659-0.578-4.082-0.519c-3.169,0.13-5.307,2.958-4.596,6.048c0.42,1.826,2.44,1.173,3.158,2.392"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M181.56,723.4c-2.16,3.76-4.295,7.534-6.51,11.262c-0.292,0.492-0.556,1.343-1.407,1.2c-0.879-0.147-1.064-1.067-1.147-1.67
				c-0.162-1.186-0.528-2.429-1.723-2.285c-1.032,0.123-1.622,1.153-2.049,2.496c-1.4,4.397-0.722,8.822-0.843,13.236"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M57.96,552.04c-4.193,1.504-8.385,3.01-12.96,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M17.16,288.52c1.605,1.023,3.067,2.318,5.04,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M592.92,256.96c-1.459,0.612-3.127,0.085-4.56,0.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M568.8,274.12c-0.12,0.4-0.24,0.8-0.359,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M558.6,308.44c-0.96,2.96-1.92,5.92-2.88,8.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M111.24,752.44c2.756,1.832,3.593,5.147,5.72,7.476c0.182,0.2,0.075,0.893-0.164,1.42c-1.521,3.366-3.308,6.589-5.05,9.838
				c-0.315,0.59-0.731,0.624-1.226,0.706"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M317.88,523.48c0.207-1.3,1.555-1.673,2.15-2.646c0.534-0.87,1.266-2.025,0.646-2.809c-0.611-0.773-1.794-0.994-3.054-0.6
				c-1.936,0.606-3.971,0.948-5.986,1.229c-2.087,0.29-2.396-0.766-1.424-2.366c0.897-1.479,1.835-2.944,2.899-4.303
				c0.483-0.618,0.809-1.279,1.168-1.945"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M361.56,520.36c1.584-0.018,1.851-1.371,2.388-2.407c0.443-0.857,0.771-1.774,1.162-2.694c2.115,0.377,1,3.237,3.06,3.689
				c1.039-0.55,0.468-1.601,0.579-2.43c0.118-0.888,0.65-1.428,1.45-1.396c0.697,0.027,0.985,0.63,0.981,1.396
				c-0.004,1.133,0.562,2.173,0.494,3.362c-0.109,1.975,0.628,3.7,2.131,5.032c0.841,0.746,1.647,1.495,1.915,2.646"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M469.32,357.4c2.314-1.351,2.651-4.046,4.019-6.042c1.176-1.717,1.854-3.784,2.681-5.727c0.25-0.585,0.556-1.442,0.308-1.869
				c-0.33-0.569-1.25-0.674-1.964-0.509c-4.111,0.947-8.305,0.982-12.483,1.187"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M375.48,757.96c-2.184,0.405-3.365,1.679-4.148,3.815c-1.091,2.979-2.63,5.795-4.038,8.651c-0.185,0.374-0.538,0.887-1.174,0.733"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M363.48,272.44c-2.615,3.083-3.584,7.112-5.95,10.357c-0.545,0.748-0.827,1.67-1.73,2.123"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M417.96,522.76c-3.929,0.001-6.988,2.555-10.562,3.591c-1.629,0.471-3.065,0.754-2.699-1.919c0.19-1.384-0.753-2.1-2.332-2.228
				c-1.151-0.093-3.218,1.269-3.351-1.365c-0.105-2.104,1.517-2.659,3.112-3.09c0.441-0.119,0.908-0.059,1.192-0.509"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M484.68,265.48c2.8,4.24,5.601,8.48,8.4,12.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M66.36,620.68c-4.176,3.592-8.275,7.257-11.52,11.761"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M174.6,594.04c-4.075-2.502-8.382-1.942-12.72-0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M463.56,552.76c0.292,2.463,0.416,4.956,1.651,7.216c0.202,0.369-0.013,0.948,0.037,1.424c0.108,1.021-0.184,2.049-1.209,2.059
				c-0.583,0.006-1.716-0.572-1.461-1.822c0.089-0.436,0.03-0.927-0.696-0.976c-0.866-0.059-0.539,0.582-0.756,0.961
				c-0.91,1.6-0.307,3.21-0.206,4.819"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M369.24,747.16c0.24,0.32,0.674,0.63,0.691,0.961c0.327,6.247-0.442,12.346-2.609,18.24c-0.218,0.592-0.604,0.997-1.202,1.198"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M155.16,542.92c-2.327,2.138-5.376,1.976-8.17,2.321c-2.584,0.319-5.149,0.419-7.69,1.214c-2.127,0.666-4.325-0.24-6.45-0.699
				c-0.465-0.101-0.924-0.692-1.188-1.168c-0.825-1.482-2.722-2.198-4.097-1.42c-0.618,0.35-0.952,0.865-0.235,1.42
				c0.594,0.459,1.271,0.812,1.911,1.212"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M28.44,399.64c-3.038,0.145-6.062-0.596-9.142,0.148c-1.123,0.271-2.329,0.777-3.578,0.572"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M474.36,299.56c3.28,4.44,7.464,8.066,11.04,12.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M504.36,67c-0.171-2.982-1.083-5.754-3.161-7.881c-1.997-2.044-3.945-0.729-5.719,0.681"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M287.52,565c-0.226,2.219,2.125,1.923,3.042,3.086c2.353,2.981,4.92,5.792,7.396,8.675c1.306,1.52,1.596,0.332,1.915-0.723
				c0.118-0.389,0.233-0.798,0.236-1.198c0.029-4.972-0.239-9.93-0.688-14.882c-0.075-0.827-0.706-1.256-0.982-1.918"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M403.56,326.68c-4.265,1.822-7.537,5.172-11.487,7.494c-1.117,0.656-1.764,1.098-2.433-0.294"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M534.6,607.96c-5.468,0.013-10.956-0.241-16.319,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M117.72,721c3.093-0.162,4.099,2.439,5.484,4.346c3.004,4.135,4.689,9.012,7.22,13.423c0.751,1.31,1.497,2.596,2.655,3.592"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M52.2,512.44c-3.292,3.504-7.606,5.182-12,6.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M70.44,617.8c-0.191,0.994-0.382,1.988-0.58,3.021c-2.721-0.166-4.559-2.461-7.069-3.349c-0.857-0.304-1.498-0.898-2.432-0.392"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M353.4,733.24c-4.234,0.164-8.526,0.062-12.24,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M141.72,745.96c1.51,1.039,2.026,2.892,3.343,4.099c0.203,0.187,0.337,0.69,0.233,0.931c-1.602,3.719-1.883,7.845-3.576,11.53"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M504.6,711.16c1.59,1.552,3.466,1.621,5.524,1.218c4.59-0.899,9.268-0.917,13.916-1.218"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M298.92,660.76c-1.791,0.178-3.524-0.247-5.283-0.456c-2.432-0.289-4.817,0.256-7.197,0.696"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M48.84,680.68c-2.774,0.852-5.155,2.488-7.684,3.832c-5.891,3.13-11.461,6.729-16.556,11.048"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M107.88,754.36c-0.991,3.561-2.654,6.902-3.42,10.547c-0.096,0.456-0.394,1.261-1.14,1.453"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M440.28,116.44c0.239,1.28,0.273,2.645,0.759,3.824c1.446,3.517,4.872,4.376,8.121,2.176"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M475.56,520.36c-0.86-1.039-0.295-1.88,0.203-2.898c1.396-2.852,2.341-5.841,1.972-9.104c-0.085-0.747,0.006-1.565-0.517-2.138
				c-0.454-0.496-0.633-1.637-1.644-1.188c-0.824,0.366-1.278,0.898-1.319,2.124c-0.076,2.235,0.174,4.585-0.854,6.724"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M490.68,386.44c-1.397-2.182-2.964-2.636-4.802-0.482c-0.626,0.734-1.261,0.708-1.931,0.014c-0.155-0.162-0.463-0.192-0.708-0.249
				c-1.302-0.302-2.624-0.622-3.835,0.244c-1.301,0.93,0.42,1.913-0.004,2.873"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M480.36,393.88c0,0.56,0.231,1.298-0.039,1.65c-1.352,1.754-0.614,3.172,0.519,4.59"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M505.8,212.92c-1.086,3.369-0.512,5.363,2.423,7.164c1.686,1.035,3.102,2.329,4.537,3.636"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M410.76,588.76c-4.271-1.111-8.5-1.701-12.479,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M336.6,552.76c0.006-3.209,2.97-3.743,4.814-5.263c1.368-1.127,3.485-1.795,4.173-3.202c1.308-2.677,3.424-4.674,5.021-7.077
				c1.543-2.321,1.588-4.547,1.096-7.108c-1.742,0.668-2.018,2.527-3.087,3.706c-0.727,0.801-1.253,1.78-1.947,2.615
				c-0.806,0.968-1.356,2.604-2.856,2.34c-1.853-0.328-0.4-2.317-1.374-3.316c-0.892,0.157-1.437,1.039-1.961,1.734
				c-1.067,1.417-1.797,3.184-4.125,2.147c-0.587-0.261-0.785,0.488-1.197,0.693c-2.244,1.115-4.196,2.731-6.147,4.207
				c-1.936,1.463-2.451,4.177-2.964,6.604c-0.421,1.995-0.804,3.999-1.204,5.999"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M561.48,677.56c-6.394,0.847-12.229,2.977-17.037,7.444c-0.827,0.77-1.604,1.596-2.403,2.396"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M165.96,414.04c-0.689,1.664,0.504,2.972,1.032,4.291c1.125,2.814,2.648,5.469,4.008,8.188"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M390.84,697.72c2.471,1.825,4.847,3.765,6.951,6.009c0.765,0.816,1.478,0.865,2.653,0.812c5.214-0.237,10.45-0.149,15.115,2.78"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M127.56,487.96c0.08,0,0.171-0.025,0.238,0.004c4.72,2.075,10.35,2.263,14.038,6.816c-5.235,3.384-1.585,6.658,0.352,9.834
				c-0.817,0.637-1.541,0.442-2.142,0.346c-0.96-0.155-2.102-1.322-2.797-0.375c-0.676,0.92,0.45,2.046,0.871,3.055"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M401.4,573.88c-4.336,0.715-8.359,2.315-12.24,4.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M447,530.44c-1.949,2.006-4.143,3.729-6.748,4.727c-0.812,0.312-2.688,1.101-2.422-1.372c0.068-0.635-0.295-1.423-0.91-1.915"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M509.64,612.28c-2.699,1.907-6.097,2.941-7.706,6.227c-0.222,0.454-0.854,0.548-1.414,0.496c-2.547-0.233-5.473,0.84-7.212-2.155
				c-0.321-0.553-0.954-0.13-1.428-0.248"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M28.68,295c1.66,1.277,3.065,2.955,5.28,3.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M439.56,709.24c6.189,1.094,11.688,3.425,15.61,8.632c0.646,0.856,1.43,1.607,2.15,2.408"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M66.84,325.72c2.81-0.326,4.063,2.177,5.944,3.443c1.369,0.92,2.872,1.841,3.942,3.241c0.245,0.321,1.074,0.195,1.633,0.276"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M313.08,570.04c-1.028,1.765-0.976,3.674-0.429,5.505c0.311,1.038,0.195,1.68-0.779,1.9c-0.997,0.227-1.306-0.405-1.464-1.4
				c-1.092-6.861-4.017-13.026-8.256-18.384c-1.604-2.028-3.263-3.822-3.952-6.341"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M117,115.48c2.56,1.574,5.092,1.54,7.696,0.028c0.857-0.498,1.904-0.668,2.864-0.988"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M160.92,744.04c0.279,4.343-0.335,8.453-3.12,12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M126.12,505.72c-1.152,1.841-3.285,0.81-4.834,1.614c-0.672,0.35-1.672,0.079-1.636,1.266c0.018,0.562-0.593,1.106-0.014,1.685
				c0.578,0.578,1.132,0.02,1.682-0.028c0.8-0.069,1.424-1.92,2.402-0.216"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M51.24,592.6c-2.73-2.464-6.251-3.019-9.608-3.254c-4.73-0.332-9.516,0.037-14.152,1.334"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M550.2,103c-4.318,3.501-9.054,6.126-14.641,6.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M431.4,623.8c-1.92-0.72-3.813-1.523-5.766-2.142c-3.71-1.174-4.297-0.595-3.595,3.342"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M138.6,661.24c7.822,1.23,15.678,2.364,22.08,7.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M181.08,718.12c-2.678,3.84-3.978,8.436-6.72,12.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M466.68,331.24c3.034,3.945,6.497,7.573,8.88,12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M590.28,600.28c-4.005,0.03-8.021-0.187-12,0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M151.56,437.8c-1.683,3.892-2.521,7.85-0.96,12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M168.6,511.24c-1.76,1.359-3.529,2.708-5.278,4.083c-1.837,1.444-4.105,1.76-6.248,2.378c-1.496,0.431-1.58,0.279-1.206-1.184
				c0.077-0.301-0.109-0.707,0.03-0.947c1.177-2.027,0.276-2.184-1.463-1.705c-0.372,0.102-0.796,0.015-1.196,0.015"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M481.8,725.08c-4,0-8,0-12,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M332.04,643.24c7.152-2.321,14.393-1.87,21.588-0.422c3.014,0.607,6.222,1.024,8.652,3.302"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M397.32,320.44c-2.24,3.76-4.482,7.519-6.72,11.281c-0.654,1.101-0.997,2.807-2.881,1.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M120.36,568.84c2.795-0.366,4.804,1.973,7.439,2.167c0.483,0.035,0.963,0.141,1.44,0.236c1.273,0.255,2.751,0.584,3.036,1.935
				c0.254,1.201-1.499,1.102-2.076,1.902"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M534.6,254.44c-0.325,4.152-1.344,8.139-2.88,12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M123.72,650.92c-3.37-3.21-7.315-5.17-12-5.52"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M248.52,661.72c-4,0-8,0-12,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M462.84,284.2c3.521,2.796,7.862,3.544,12,4.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M531.24,445.96c-0.113,2.833,0.58,5.348,2.62,7.459c1.178,1.22,2.365,2.503,2.659,4.301"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M155.16,712.6c-1.71,2.973-1.784,6.395-2.618,9.606c-0.224,0.859,0.148,1.643,0.938,2.154"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M547.32,633.4c1.722-0.055,3.266,0.91,5.045,0.802c0.669-0.04,0.967,1.09,0.691,1.829c-0.449,1.21-0.365,2.637-1.417,3.608"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M548.76,640.12c-2.307,0.052-3.31,2.019-4.492,3.418c-1.631,1.93-2.788,4.262-4.147,6.422"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M150.84,613c-1.643,1.173-3.097,2.56-4.044,4.339c-0.49,0.921-0.865,1.226-1.964,0.979c-3.336-0.746-6.711-1.318-10.072-1.958"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M324.6,744.28c1.965,2.878,2.717,6.304,4.248,9.396c2.7,5.457,5.699,10.687,9.965,15.032c1.698,1.731,1.877,2.999,0.188,4.612"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M546.6,430.84c0.662,1.237-0.474,2.107-1.02,2.569c-2.533,2.143-2.534,5.274-3.154,8.023c-0.346,1.538-0.74,2.47-2.065,3.087"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M455.16,437.92c-1.73,0.415-3.313,0.973-3.12,3.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M498.36,715.24c-1.916-2.97-5.345-2.938-8.186-4.015c-0.917-0.348-1.978-0.888-3.095-0.546"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M177.48,94.6c-0.24,0.96-0.604,1.908-0.698,2.882c-0.351,3.681-2.54,5.353-5.793,5.936c-1.212,0.217-2.38,0.584-3.589,0.782"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M427.8,424.36c-1.293,4.311-2.442,8.646-2.399,13.199"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M365.64,506.92c-1.012,2.967-3.48,4.609-5.72,6.526c-1.355,1.159-1.517,3.137-2.228,4.741c-1.067,2.414-1.726,5.155-5.499,4.927
				c-1.733-0.105-3.396,1.364-4.764,2.56c-2.057,1.799-4.365,2.966-6.992,3.556c-0.98,0.22-1.812,0.851-1.839,1.692
				c-0.031,0.98,1.047,1.031,1.839,0.935c0.539-0.065,1.204-0.559,1.407,0.272c0.195,0.801-0.521,1.155-1.165,1.431"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M42.6,683.08c-3.725-2.311-7.662-2.542-11.76-1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M344.76,549.88c-1.106,1.678-0.062,3.894-1.796,5.643c-1.027,1.037-2.338,1.643-3.31,2.697c-0.14,0.151-1.263,0.57-1.867-0.169
				c-0.45-0.551-0.349-1.231-0.182-1.674c0.564-1.495,1.856-2.422,3.337-3.367c-1.876-0.812-3.101-0.37-4.632,0.635
				c-3.495,2.297-7.464,3.459-11.711,3.436"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M319.56,557.08c-0.766-1.323,0.52-2.373,0.48-3.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M570.12,440.44c0.313,1.322,0.218,2.996,2.401,2.648c0.902-0.144,1.396,0.7,1.622,1.216c0.729,1.663,1.884,2.38,3.646,2.676
				c1.231,0.206,1.792,1.123,0.735,2.345c-1.278,1.479-3.696,1.944-5.534,1.217c-2.445-0.969-5.021-1.633-7.398-2.733
				c-0.997-0.462-1.633-0.078-2.433,0.071"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M550.2,445.96c0.23,1.771,1.172,3.835-2.164,3.692c-0.816-0.035-1.611,1.006-1.48,2.312c0.126,1.259-0.115,2.555-0.195,3.835"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M59.64,743.8c-0.068,1.88,0.223,3.709,0.708,5.523c0.353,1.319-0.004,2.6-0.471,3.835c-1.115,2.953-1.949,6.032-3.836,8.642"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M105,628.6c-1.37-0.97-2.778-1.405-4.565-1.265c-3.343,0.265-6.716-0.157-10.082,0.253c-0.9,0.109-1.546,0.688-2.394,0.772"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M357.48,398.68c3.119,0.561,6.234,1.156,9.363,1.655c0.777,0.124,1.627,0.169,2.396-0.215"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M546.12,368.68c-0.069-1.043-1.308-1.648-0.988-2.888c0.228-0.884-0.797-0.922-1.169-0.902c-1.957,0.106-3.821-0.926-5.758-0.507
				c-1.882,0.408-3.229-0.257-4.325-1.703"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M354.6,385.96c4.51,0.968,9.265-0.07,13.691,1.652c0.154,0.06,0.469-0.292,0.709-0.452"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M473.16,677.8c-1.689,1.056-3.79,1.165-5.557,1.476c-3.433,0.604-6.326,2.206-9.271,3.671c-1.629,0.811-3.006,1.054-4.609,0.604
				c-3.538-0.993-6.872-0.441-10.083,1.21"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M354.36,289c-2.865,3.388-4.672,7.402-6.746,11.266c-1.237,2.305-1.907,4.972-4.055,6.734"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M272.04,737.8c-2.96-0.399-5.928-0.748-8.878-1.214c-3.405-0.536-6.741-0.071-10.082,0.494"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M317.88,552.76c-3.19,2.156-6.924,2.473-10.56,3.121c-1.761,0.314-3.52,0.639-5.28,0.959"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M559.56,585.64c-3.817-0.688-7.66-1.044-11.515-0.448c-1.311,0.202-2.564,0.769-3.845,1.169"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M63,680.68c2.817,4.616,3.68,9.529,2.706,14.893c-0.42,2.312-0.002,4.776-1.266,6.947"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M61.32,706.36c0.321,2.07-0.74,4.301,0.72,6.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M287.4,403c0.027,3.885,1.34,7.903-1.2,11.52"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M458.28,363.64c-0.396,0.493-0.526,1.061-0.48,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M456.12,372.04c-12.076-0.526-22.41,3.567-31.2,11.76"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M308.52,427.72c-4.083,1.189-7.899,3.169-12.229,3.686c-2.034,0.243-3.56,1.87-4.57,3.755"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M171.24,589.24c-3.603-2.189-7.378-3.189-11.52-1.681"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M138.36,529.96c-1.565-1.621-3.647-2.455-5.539-3.567c-0.625-0.368-1.448-0.544-2.156,0.193c-0.686,0.712-0.571,1.426-0.217,2.171
				c0.614,1.292,1.274,2.562,1.912,3.843c1.2,2.41,1.057,2.699-1.68,3.358c-0.396,0.096-0.813,0.121-1.197,0.249
				c-1.327,0.443-1.735,1.159-0.475,2.143c1.138,0.89,2.624,1.454,2.853,3.133c0.052,0.382,0.226,0.871-0.22,1.197"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M408.12,341.32c-0.308,2.916-2.313,4.756-4.354,6.439c-1.671,1.378-2.687,2.919-2.727,5.081"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M516.12,385c0,1.12,0.092,2.25-0.026,3.357c-0.113,1.051,0.549,1.941,1.214,1.841c2.15-0.322,2.72,1.446,3.853,2.482"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M537,253.96c0.316-1.438-0.44-2.119-1.672-2.659c-3.949-1.73-5.706-1.596-7.688,0.739"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M101.4,740.68c2.207,2.055,2.132,4.731,1.376,7.181c-1.904,6.168-1.51,12.152,0.209,18.286c0.664,2.368,0.732,4.96,1.295,7.413"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M515.76,314.92c-3.979-1.264-7.926-2.617-11.64-4.56"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M211.8,605.32c-2.023-0.261-3.608,0.541-5.056,1.902c-1.364,1.282-2.524,1.613-4.371,0.1c-4.545-3.726-9.688-6.569-15.293-8.482"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M39,751c-2.014-2.179-2.438-5.104-3.583-7.688c-0.458-1.034-0.716-2.158-1.697-2.872"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M547.32,330.88c-1.954,0.55-2.408,1.669-1.464,3.493c0.459,0.888,0.663,1.907,0.983,2.868"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M546,345.16c0.191,1.063,0.064,2.295,0.871,3.088c0.796,0.784,0.651,1.128,0.029,1.995c-0.901,1.257-1.95,0.589-2.405,0.14
				c-1.567-1.547-3.326-2.783-5.354-3.489c-1.271-0.442-2.728-0.765-4.067,0.175c-1.617,1.135-0.765,2.519-0.465,3.848
				c0.09,0.399,0.761,0.641,0.237,1.208c-2.26,2.446,0.092,3.601,1.435,5.035"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M295.56,439.96c0.16,0.8,0.424,1.596,0.457,2.401c0.056,1.367,0.993,1.718,1.924,1.385c1.434-0.515,2.888-0.095,4.375-0.547
				c1.988-0.604,1.227,1.55,1.644,2.521"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M107.88,619.96c-3.109-0.382-6.211-0.818-9.36-0.735c-0.893,0.024-1.866,0.278-2.64-0.465"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M334.44,717.64c-6.438-4.88-13.802-7.687-21.611-9.309c-4.1-0.852-8.309-0.004-12.469-0.051"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M373.08,758.68c-0.071-2.637,1.104-5.045,2.297-7.121c1.399-2.434,1.819-5.151,1.976-7.537c0.12-1.825,0.655-3.779,0.366-5.753
				c-0.306-2.093-1.023-3.901-2.523-5.218c-1.175-1.032-2.66-1.366-3.814,0.417c-1.103,1.705-1.873,3.484-1.896,5.532
				c-0.021,1.92-0.054,3.842,0.016,5.759c0.038,1.055-0.132,2.01-0.992,2.625c-1.711,1.226-2.265,3.371-3.999,4.668
				c-1.048,0.782-0.242,2.528-0.014,3.738c0.977,5.166,0.884,10.412,1.396,15.609c0.072,0.733-0.593,1.077-0.73,1.681"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M194.76,583c-3.031,1.379-6.509,1.705-9.153,4.043c-0.712,0.629-1.369,0.972-0.687,1.957"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M485.16,196.36c-0.551,2.741,1.293,3.428,3.362,3.827c2.145,0.413,4.216,0.902,5.518,2.893"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M99.72,466.84c-1.781-1.212-2.833,0.811-4.289,1.111c-1.647,0.34-2.725,1.869-1.525,3.96c0.388,0.677-0.125,1.296-1.098,1.752
				c-0.921,0.431-1.903,0.027-2.688,0.617"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M540.12,220.6c-4.005,2.446-5.398,6.731-7.444,10.558c-0.051,0.096-0.155,0.162-0.235,0.242"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M129,676.36c-5.876,1.152-10.238,4.585-13.948,9.097c-1.104,1.343-1.834,3.166-3.812,3.623"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M543.72,306.04c2.655-1.751,2.979-4.386,0.723-5.764c-1.193-0.729-2.282-0.642-3.367,0.237c-1.185,0.959-1.712,2.238-1.146,3.585
				c0.604,1.437,1.723,2.478,3.551,1.941"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M363.96,547.96c0.908,1.553,1.445,3.298,2.851,4.592c1.137,1.046,0.338,2.767,0.989,4.048"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M367.2,560.68c-0.529,1.488-0.364,3.026-0.373,4.561c-0.016,2.656,0.345,5.354-0.707,7.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M507.24,608.44c-1.015,0.57-1.792,0.933-2.927-0.193c-2.87-2.846-6.05-5.337-10.033-6.527"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M172.2,700.6c0.985,6.137,2.166,12.228,4.2,18.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M407.88,339.64c-0.109,0.475,0.917,1.235-0.243,1.422c-4.284,0.692-7.674,3.385-11.508,5.079c-0.942,0.417-1.829,0.858-2.889,0.698
				"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M421.32,493.96c-1.911,0.977-4.076,1.405-5.107,3.811c-1.053,2.455-3.591,2.169-5.673,1.829c-0.685-0.111-1.14-0.089-1.7,0.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M228.6,430.12c-0.037,1.618,0.517,2.812,1.875,3.896c1.573,1.258,3.17,2.505,4.48,4.173c0.948,1.205,0.986,2.79,0.523,3.078
				c-1.137,0.709-2.188,2.093-4.012,1.367c-3.262-1.299-6.349-2.766-8.382-5.798c-0.296-0.442-0.539-0.975-1.2-0.708
				c-0.676,0.272-0.346,1.051-0.396,1.442c-0.245,1.912,0.692,3.79,0.392,5.749"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M416.28,751.48c3.962,0.801,7.882,1.719,11.279,4.079"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M441.48,586.36c-7.928-3.866-21.004-1.801-25.921,4.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M560.52,591.16c-7.055-2.291-13.24,0.049-19.199,3.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M449.88,481c-1.256,2.145-3.338,2.262-5.517,2.436c-3.292,0.264-6.913,0.042-9.727,1.405c-2.054,0.995-4.137,1.174-6.085,1.994
				c-1.071,0.45-1.905,0.472-2.38-0.345c-0.68-1.168-1.727-0.959-2.691-1.17c-1.591-0.348-4.22,0.898-3.715-2.656
				c0.087-0.605-1.325-1.424-2.046-2.145"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M116.52,502.36c-1.266-0.378-0.541-1.422-0.716-2.161c-0.206-0.871,0.502-2.394-0.499-2.519c-1.363-0.169-3.022-1.036-4.297,0.367
				c-0.764,0.84-1.73,1.646-2.11,2.653c-0.835,2.216-2.276,2.655-4.103,1.599c-0.885-0.513-1.904-0.279-2.675-0.899"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M551.4,453.88c-0.25,4.402-4.164,6.62-6.052,10.052c-0.38,0.691-1.003,1.262-0.396,1.919c0.652,0.707,1.468,0.085,2.118-0.23
				c1.355-0.657,2.393-1.689,3.413-2.821c1.211-1.344,2.302-2.966,4.376-3.595c0.415,1.821-0.535,3.104-1.017,4.53
				c-0.461,1.363-2.101,2.76,0.229,4.742c0.608-0.837,1.248-1.717,1.889-2.597"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M264.84,518.68c4.176,2.281,6.975,6.174,10.606,9.062c4.322,3.438,9.271,5.892,14.598,7.484c0.367,0.109,0.796,0.015,1.196,0.015"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M87.24,465.88c-1.185,0.271-2.791,0.866-2.578-1.193c0.303-2.933-1.321-3.076-3.422-2.884c-0.721,0.065-1.44,0.157-2.16,0.237"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M145.8,584.92c-4.182-1.721-8.571-1.581-12.96-1.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M112.2,639.88c3.506,1.787,7.323,2.895,10.597,5.228c1.962,1.398,4.042,2.614,5.723,4.373"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M418.44,736.84c1.165,6.808-1.723,12.577-4.841,18.218c-1.521,2.75-3.302,5.403-5.499,7.684c-2.354,2.441-3.219,5.152-2.147,8.421
				c0.206,0.625,0.327,1.278,0.487,1.918"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M561.96,477.88c-1.868,0.125-2.959,1.385-3.56,2.896c-1.159,2.916-3.419,4.101-6.282,4.534c-0.469,0.07-1.261,0.196-1.394-0.019
				c-2.072-3.36-4.743-1.034-6.718-0.322c-4.238,1.526-8.519,2.933-12.767,4.431"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M554.76,256.84c-0.29,1.413,0.337,2.719,0.508,4.077c0.288,2.292,0.277,4.549-0.536,6.712c-0.431,1.146,0.054,1.98,0.509,2.891"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M296.76,733.24c-1.401-1.347-2.26-4.004-4.768-3.037c-2.598,1.001-2.571,3.879-3.109,6.167c-0.346,1.471,0.295,3.099-0.523,4.55"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M342.12,409.72c0.482,2.518,1.923,4.641,2.912,6.946c0.574,1.34,1.198,2.639,1.408,4.094"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M38.28,292.6c2.179,0.125,3.096,1.815,4.067,3.368c1.331,2.128,2.732,4.236,3.806,6.495c0.985,2.072,2.417,3.333,4.606,3.817"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M79.8,98.68c-0.612,2.275,0.356,4.888-2.118,6.775c-3.366,2.565-6.072,2.937-9.882,0.665"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M386.28,537.16c2.202,3.077,3.651,6.861,7.908,7.965c1.049,0.271,1.794,1.244,2.173,2.354c0.809,2.372,2.323,4.132,4.557,5.284
				c2.543,1.311,3.661,4.2,6.21,5.575c0.899,0.485,0.84,1.192-0.367,1.984c-0.896,0.587-1.659-0.426-2.241,0.356"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M222.6,598.84c-3.617,0.481-6.351,3.433-10.08,3.601"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M209.4,602.2c-0.48-0.08-1.036-0.03-1.428-0.261c-3.875-2.268-6.574-0.211-9.132,2.421"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M156.6,454.84c2.171-1.312,3.453-3.183,3.648-5.751c-4.762-0.899-7.595,2.388-6.043,6.964c1.087,0.065,1.208-1.314,2.155-1.453"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M416.04,735.64c-3.161,2.334-6.161,4.852-8.616,7.939c-0.483,0.608-0.769,1.307-1.704,1.181"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M177,554.2c1.193,1.185,2.704,0.688,4.08,0.679c0.912-0.006,1.539,0.354,1.884,1.02c0.335,0.648-0.288,1.26-0.707,1.637
				c-1.887,1.698-2.434,4.33-4.297,6.024"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M384.84,328.12c0.48-0.358,0.99-0.128,1.435,0.017c1.478,0.482,2.12-0.213,2.946-1.415c1.593-2.315,1.942-5.126,3.342-7.456
				c0.921-1.534,0.341-1.723-1.003-1.708c-1.119,0.012-2.252,0.111-3.356-0.021c-2.102-0.251-2.131,1.775-2.492,2.721
				c-0.423,1.109,0.436,2.992,1.581,3.393c0.944,0.331,2.348-0.651,3.548-1.051"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M452.76,560.68c-0.699-0.56-0.523-1.003-0.052-1.714c0.434-0.653,1.817-1.349,0.738-2.329c-1.12-1.02-1.749,0.298-2.347,0.941
				c-1.369,1.476-2.932,2.694-4.58,3.822"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M443.64,594.28c-6.082-3.349-12.3-5.963-19.439-3.601"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M458.04,380.92c1.47,1.697,0.734,3.876,1.065,5.784c0.737,4.235,1.515,8.466,2.252,12.704c0.513,2.949,1.276,5.813,2.24,8.62
				c0.561,1.635,0.534,3.091-0.277,4.572"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M572.76,345.4c-1.148,4.602-4.236,7.463-8.399,9.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M395.64,724.84c0.88-1.359,1.802-2.695,2.631-4.086c1.247-2.089,0.735-4.799-1.202-6.219c-0.931-0.682-1.852-0.832-2.15,0.704
				c-0.666,3.421-1.749,6.925-4.174,9.262c-4.314,4.158-7.822,9.211-13.104,12.339"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M436.68,564.76c-0.439-0.58-1.085-0.406-1.678-0.494c-1.342-0.198-2.108-0.838-1.952-2.389c0.124-1.234,0.4-2.816-1.415-3.076
				c-1.575-0.226-3.197-0.113-4.084,1.635c-0.178,0.352-0.617,0.615-0.688,0.969c-0.518,2.619-2.039,2.407-3.863,1.436"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M503.64,250.6c-0.059,3.305-2.232,5.865-3.13,8.877c-0.236,0.793-0.512,1.598-1.189,2.163"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M563.88,464.2c-1.331,3.166-0.031,7.394,2.881,9.358c0.956,0.646,1.947,1.244,2.874,1.93c1.783,1.318,1.184,2.833,0.238,4.308
				c-1.079,1.685-2.557,1.475-4.067,0.713c-0.831-0.419-1.605-0.949-2.405-1.429"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M563.16,479.08c-1.354-1.178-1.079-3.363-2.859-4.358c-1.512-0.844-2.694-1.625-2.919,0.997c-0.028,0.331-0.302,0.641-0.462,0.961"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M97.8,423.16c-0.32,1.04-0.597,2.096-0.968,3.116c-0.843,2.312-0.255,3.351,2.158,3.042c1.333-0.171,2.121,0.15,2.89,1.042"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M164.76,677.8c2.332-0.188,4.256,1.509,5.581,2.615c1.916,1.6,3.648,1.689,5.7,1.693c0.558,0.001,1.026,0.132,1.44,0.491"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M441.72,764.92c2.866,2.062,5.817,4.002,8.189,6.694c0.952,1.08,2.223,1.522,3.821,1.018c4.331-1.37,8.506,0.122,12.71,0.928"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M563.64,734.2c-3.51-2.132-6.244-5.456-10.319-6.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M587.88,353.08c2.614,3,5.518,5.612,9.36,6.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M210.84,663.88c-3.391-1.764-7.016-2.715-10.8-3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M322.2,629.8c0.935-0.83,1.504-1.776,1.471-3.121c-0.033-1.343,1.044-2.27,1.674-3.345c0.761-1.299,1.895-0.202,2.855-0.247
				c2.909-0.135,5.764,0.43,8.644,0.676c0.674,0.058,1.043,0.346,1.437,0.757"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M216.36,575.56c3.731,2.366,7.574,4.605,9.84,8.641"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M272.28,574.12c-3.587,0.712-7.331,0.545-10.8,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M90.12,536.68c0.137,1.525-1.018,2.457-1.707,3.584c-0.349,0.571-1.407,0.427-1.606,0.213c-2.752-2.954-4.342-0.632-6.047,1.243"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M252.36,702.04c-1.661-2.545-4.385-3.791-6.713-5.529c-3.665-2.738-6.04,0.456-8.606,1.998c-2.332,1.401-4.064,3.615-5.561,5.932"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M50.52,745.24c1.473,2.868,1.849,6.074,2.528,9.145c0.401,1.813,0.884,3.86,1.72,5.146c1.892,2.909,0.626,5.169-0.456,7.528
				c-0.966,2.105-1.307,4.254-1.393,6.501"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M486.6,544.12c0.991,2.185,3.088,3.008,5.066,3.771c1.199,0.464,2.544,0.043,2.851-1.612c0.324-1.747-0.871-2.055-2.157-2.146
				c-1.837-0.131-3.68-0.173-5.52-0.253c-1.969-0.086-4.929-2.776-5.28-4.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M110.76,418.6c-0.594,0.834-0.475,1.858-0.192,2.623c1.157,3.124,0.793,6.607,2.352,9.617"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M114.36,436.48c1.028,0.395,1.848,1.174,2.807,1.694c2.041,1.106,1.364,3.545,0.666,5.088c-0.715,1.579,0.58,1.249,0.838,1.204
				c2.372-0.407,4.385,1.172,6.731,1.076c1.778-0.072,0.459,1.851,1.198,2.577"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M182.52,564.04c-2.041,0.067-4.038-0.875-6.128,0.8c-0.818,0.656-3.065-0.524-4.674-0.789c-2.723-0.449-5.336,0.149-7.918,0.949"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M364.68,540.28c-1.066,1.594-0.176,3.371-0.473,5.041c-0.552,3.096-2.507,5.743-2.871,8.881c-0.382,3.294-1.113,6.627,0.464,9.838"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M132.36,760.12c2.889,1.595,4.18,4.622,6.26,6.942c0.787,0.878,1.119,2.301,2.621,2.418"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M37.08,412.84c-3.732,0.465-7.307,1.521-10.8,2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M284.76,414.28c-1.88-0.103-2.556,0.751-2.436,2.642c0.151,2.37,0.928,4.629,1.83,6.668c1.407,3.183,4.598,3.457,8.524,1.245
				c3.167-1.783,5.356-4.555,7.475-7.411c0.27-0.363,0.334-1.205,1.167-0.983"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M376.44,759.64c1.567,2.842,1.857,6.066,2.55,9.141c0.365,1.621,1.373,3.059,1.29,4.779"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M231,751.96c-0.719-0.576-0.248-2.021-1.561-2.157c-1.639-0.171-3.369-0.726-4.898-0.386c-2.175,0.481-4.368,0.142-6.501,0.623"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M290.04,607.24c-3.664,0.817-7.398,1.393-10.8,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M265.32,584.68c-4.243,1.223-7.917,3.31-10.56,6.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M156.36,704.44c-1.764-0.975-2.919-0.91-4.188,1.132c-1.174,1.889-1.363,4.237-3.011,5.828"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M160.68,467.8c-1.034,0.091-1.704,0.781-2.402,1.438c-1.039,0.978-1.9,1.401-2.923-0.208c-0.949-1.492-2.697-0.75-4.029-0.661
				c-0.971,0.066-2.592-1.115-3.059,1.318c-0.195,1.019-2.401-0.029-3.667,0.273"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M292.92,607.48c2.197,1.194,3.042,3.444,4.383,5.355c1.916,2.728,3.507,5.6,5.176,8.644c-3.028,0.777-5.987,0.075-8.839,0.641"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M338.52,748.36c3.471,0.901,6.415,2.862,9.047,5.125c1.241,1.067,2.019,1.754,3.693,1.171c1.096-0.381,2.38-0.216,3.58-0.296"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M176.52,565.24c-0.392,1.478-1.665,1.755-2.877,2.17c-2.186,0.749-4.522,0.155-6.752,0.856c-0.761,0.239-1.884-0.553-2.379-1.582
				c-0.4-0.831-0.688-1.774-1.671-2.165"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M498.36,768.04c-2.435-3.684-5.151-7.068-9.36-8.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M57.72,569.56c-2.892,3.022-5.724,6.1-7.647,9.857c-0.36,0.703-0.949,0.836-1.473,1.183c-0.371-0.736-1.285-1.126-1.214-2.16
				c0.18-2.636-0.286-5.295,0.258-7.919c0.299-1.444,0.318-2.881-0.004-4.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M465.72,277.48c2.937,2.928,6.688,4.225,10.561,5.28"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M465.72,208.36c-2.225-2.406-5.174-3.494-8.146-4.598c-0.885-0.329-1.468,0.079-2.174,0.278"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M394.68,715c-3.668-1.176-7.514-1.424-11.283-1.596c-6.31-0.286-12.656-0.537-18.954,0.412c-0.18,0.027-0.322,0.304-0.482,0.464"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M64.92,731.32c1.157,1.07,0.935,2.486,0.96,3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M64.2,741.04c-1.295,2.853-1.211,6.234-3.36,8.76"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M172.68,527.56c0.327,0.446,0.901,0.352,1.181,0.204c0.811-0.427,1.333-0.235,1.691,0.521c0.353,0.745,0.441,1.437-0.215,2.171
				c-1.437,1.609-3.794,2.287-4.577,4.545"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M307.56,644.44c-3.52,0-7.04,0-10.56,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M434.28,182.68c2.88,0.48,5.823,0.735,8.616,1.523c1.722,0.485,3.336,1.646,4.104,3.517"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M30.12,643.48c-3.564-0.166-7.023,0.826-10.56,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M305.16,608.2c-3.471-0.862-6.978-1.321-10.56-0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M28.92,425.32c-2.24-0.24-4.689,0.008-6.668-0.84c-2.653-1.137-4.091,0.282-6.007,1.63c2.352,2.05,4.67,3.888,7.876,3.998
				c1.3,0.044,2.118,0.561,2.928,1.655c1.725,2.331,4.279,3.741,6.643,5.357c0.763,0.521,2.052,0.927,1.708,2.359"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M105.96,535.96c2.938,0.956,5.713,2.501,8.881,2.598c0.617,0.019,1.111,0.264,1.41,0.552c2.704,2.604,5.901,3.797,9.627,3.555
				c0.643-0.042,0.825,0.457,1.201,0.736"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M431.64,753.4c-1.946,1.59-4.664,2.128-6.026,4.544c-0.469,0.831-1.257,0.02-1.893-0.002c-3.521-0.123-7.041-0.303-10.561-0.462"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M118.68,609.4c-1.013-0.046-2.08,0.345-2.59,0.998c-2.561,3.277-5.831,5.875-8.45,9.082"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M579.48,333.16c0-3.639-1.07-4.582-4.255-2.765c-2.646,1.511-4.429,0.148-6.546-0.596"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M195.48,547.96c0.37,1.6-0.687,3.2,0,4.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M193.32,561.88c-1.202,5.136-2.936,10.111-4.56,15.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M72.84,584.68c-3.081,0.23-5.404,2.132-7.914,3.61c-0.839,0.494-1.606,1.109-2.406,1.67"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M26.28,657.4c-2.395-0.059-4.546,0.826-6.728,1.658c-1.154,0.44-2.392,0.661-3.592,0.981"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M401.16,352.6c1.724,0.165,3.075-0.864,4.55-1.464c0.736-0.3,1.592-0.847,2.122-0.178c0.777,0.98,1.211,2.283,0.961,3.553
				c-0.16,0.815-0.841,1.366-1.875,1.227c-0.869-0.116-1.608,0.453-2.408,0.663c-2.538,0.669-2.771,0.922-2.888,3.398
				c-0.091,1.923,0.461,3.905-0.462,5.761"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M402.84,662.92c3.984,1.864,7.521,4.479,11.28,6.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M336.36,485.8c-2.559-1.771-5.617-1.023-8.408-1.645c-1.715-0.382-3.598,0.477-5.268-0.523c-0.846-0.507-1.693,0.413-1.839,0.568
				c-1.859,1.973-4.22,1.975-6.562,1.804c-1.974-0.145-3.822,0.607-5.764,0.516"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M139.8,608.2c-4.507-2.908-9.272-5.063-14.628-6.063c-5.587-1.043-11.143-1.711-16.812-1.137"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.36,740.2c-3.438-0.765-6.958-0.825-10.44-1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M382.92,327.16c1.084-0.311,1.822,0.74,1.823,1.2c0.005,1.289,0.888,1.958,1.577,2.597c1.65,1.529,1.491,2.496-0.508,3.429
				c-0.872,0.407-1.657,1.041-2.405,1.663c-3.948,3.282-4.958,6.227-1.025,11.198c-1.979,3.061-4.463,4.674-8.102,2.713"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M522.12,310.6c-3.133,3.216-7.323,4.935-10.837,7.632c-2.949,2.265-5.581,4.828-8.071,7.535c-0.819,0.891-1.491,1.5-2.692,1.393"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M573.24,617.32c-3.439,0.407-6.879,0.815-10.286,1.22c-0.386-1.293,0.767-1.276,1.406-1.7"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M95.4,693.16c0.61-2.28,0.909-4.605,0.954-6.96c0.042-2.188,0.533-4.274,1.503-6.211c0.145-0.289,0.143-1.177,0.897-0.972
				c0.546,0.148,0.79,0.525,1.036,1.155c0.555,1.422,0.494,3.055,1.194,4.312c1.257,2.26,1.146,4.604,1.137,6.996
				c-0.007,1.84-0.001,3.68-0.001,5.52"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M61.32,441.64c-2.733,3.398-5.67,6.561-9.584,8.669c-0.559,0.301-1.07,0.964-0.255,1.651"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M302.28,422.68c-2.332,2.938-7.406,4.207-9.6,2.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M562.68,618.76c-5.928,0.843-11.404,3.046-16.808,5.503c-2.062,0.938-0.528,2.604-0.952,3.857"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M343.8,622.36c-3.926-2.893-8.686-3.09-13.2-4.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M170.52,195.88c-1.479-0.886-2.877-1.504-4.811-1.3c-2.707,0.287-3.284,1.673-3.109,3.94"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M28.44,441.4c-3.084,0.689-6.091-0.084-9.122-0.463c-0.384-0.048-0.871-0.224-1.198,0.223"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M288.6,735.4c-0.943-0.291-1.901-1.462-2.852-0.219c-0.689,0.901-0.428,1.85,0.176,2.88c1.799,3.066,4.074,5.834,5.58,9.087
				c0.678,1.464,0.835,3.314,2.616,4.092"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M292.44,418.84c1.358-3.397,4.271-5.207,7.226-6.915c0.537-0.31,1.517-0.745,2.104-0.02c0.529,0.652,0.58,1.853,0.205,2.322
				c-1.129,1.413-0.544,2.217,0.53,3.19c1.242,1.126,1.775,2.56,2.026,4.289c0.219,1.507-0.177,2.188-1.234,3.191
				c-2.174,2.063-5.123,3.326-6.536,6.182"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M537.72,336.28c0.515,3.427,1.999,6.741,1.2,10.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M314.52,439.72c2.996,2.63,6.895,3.492,10.32,5.28"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M206.76,461.8c-0.24,0.16-0.461,0.365-0.723,0.474c-2.383,0.981-4.788,1.425-5.724,4.748c-0.608,2.161-3.972,1.276-6.033,1.979"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M377.88,744.04c1.314-1.965,2.343-0.851,2.836,0.496c1.714,4.679,3.773,9.324,4.688,14.174c0.834,4.423,1.661,9.25-1.5,13.428
				c-0.261,0.344-0.185,0.942-0.265,1.422"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M429.48,543.4c0.256,0.288,0.575,0.384,0.948,0.513c2.654,0.92,5.335,1.829,5.828,5.238c0.056,0.382,0.903,0.648,1.383,0.969"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M396.12,546.52c1.611-0.74,0.464-1.647,0.366-2.651c-0.097-1.012-2.192-1.946-0.553-2.762c1.091-0.541,2.168,0.901,2.755,1.857
				c0.397,0.646,0.897,1.22,1.276,1.873c0.464,0.801,1.334,0.614,1.897,0.43c0.855-0.279,0.609-1.263,0.462-1.857
				c-0.675-2.713-5.706-6.344-8.354-5.941c-0.615,0.093-1.977-0.029-1.378,1.903c0.517,1.668,1.969,3.241,1.367,5.229"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M472.44,663.64c3.702,0.994,6.574,3.226,9.131,5.99c2.266,2.449,4.59,4.85,6.963,7.196c1.54,1.521,3.384,0.58,5.014,0.204
				c2.247-0.521,4.378-1.528,6.732-1.63"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M566.28,342.52c-1.098,1.904-3.588,3.351-2.58,5.977c0.784,2.042-0.954,4.117,0.367,6.06c0.022,0.033-0.747,0.604-1.147,0.924"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M517.32,247c0.065,1.067,0.983,0.388,1.473,0.662c4.26,2.383,4.646,3.142,3.566,7.257c-0.714,2.722-1.439,5.44-2.159,8.161"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M81.48,662.68c-2.64,1.521-5.329,2.965-7.884,4.616c-0.599,0.388-1.76,0.46-1.716,1.624"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M246.12,759.64c0.614,2.835,1.605,5.493,3.6,7.681"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M470.28,414.28c-2.351,2.536-2.845,2.558-5.521,0.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M461.88,412.96c-1.475,0.78-3.929-0.397-4.498,2.485c-0.097,0.489-2.536,0.556-3.903,0.739c-0.816,0.109-1.6,0.066-2.429,0.426
				c-0.692,0.3-1.109,0.44-1.197,1.267c-0.094,0.879,0.564,1.182,0.987,1.683c1.076-0.918,1.581,0.719,2.65,0.538
				c3.02-0.509,4.176,1.602,4.732,4.035c0.192,0.84,0.48,1.601,0.765,2.392c0.586,1.636-0.422,2.803-2.148,2.652
				c-1.032-0.091-2.081,0.021-3.118-0.025c-1.74-0.077-2.983,0.519-3.101,2.41c-0.104,1.691-0.018,3.341,2.38,3.374
				c1.226,0.018,1.547,1.331,2.4,1.904"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M369,660.76c3.58,2.271,5.992,5.586,8.16,9.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M303.24,328.36c0.124,3.362,1.399,6.668,0.48,10.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M489.48,601.96c-2.989,1.93-6.117,3.64-8.691,6.188c-0.618,0.613-1.72,1.547-2.883,0.094c-0.757-0.946-2.026-1.482-3.066-2.202"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M415.56,528.52c-2.169,0.773-3.588,1.917-2.675,4.572c0.349,1.015-0.52,1.329-1.404,1.437c-0.719,0.088-1.491-0.304-2.16,0.231"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M436.2,430.12c-0.528,0.854-1.227,1.614-1.44,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M109.32,661.48c-6.377-2.861-12.93-4.387-19.923-2.652c-0.377,0.094-0.797,0.012-1.197,0.012"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M287.16,369.16c3.783,2.684,6.412,6.545,9.816,9.626c2.896,2.622,5.604,5.437,8.639,7.924c3.548,2.908,6.905,6.049,10.345,9.089"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M587.16,332.68c1.88,1.072,3.951,0.959,6,0.952c0.656-0.002,1.417-0.049,1.767,0.509c0.502,0.803-0.389,1.183-0.813,1.652
				c-1.12,1.238-2.748,1.71-4.082,2.634c-1.413,0.979-3.127,1.595-3.831,3.372"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M536.52,629.08c1.507,0.286,2.185-1.304,3.577-1.564c0.992-0.185,1.191-1.331-0.203-2.062c-4.496-2.357-9.244-2.783-14.175-2.141
				c-0.393,0.051-0.799,0.007-1.199,0.007"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M464.52,457.72c-2.438-1.103-4.171,1.278-6.479,1.279c-3.107,0.002-6.203,0.283-9.11-1.059c-1.531-0.706-2.871-0.84-3.827,0.753
				c-0.511,0.853-1.223,0.689-1.942,0.704c-0.72,0.014-1.471-0.127-2.153,0.032c-1.116,0.261-2.594,0.166-3.079,1.429
				c-0.599,1.555,0.881,1.918,1.882,2.359c1.105,0.486,1.244,1.528,1.671,2.422"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M564.36,280.84c-0.009-1.526,0.169-3.041-1.421-4.108c-1.6-1.074-2.989-0.983-4.332,0.277c-1.272,1.195-1.465,2.698-0.45,4.043
				c1.107,1.47,2.609,2.384,4.516,1.216c0.527-0.323,0.967-0.788,1.447-1.188"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M15.72,683.32c0.08,3.12,0.319,6.247,0.182,9.356c-0.087,1.972,0.672,2.744,2.458,2.883"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M43.32,732.52c-1.6,1.84-3.377,3.56-4.736,5.563c-0.871,1.285,0.374,2.831,0.4,4.277c0.051,2.802,1.115,5.472,0.375,8.43
				c-0.559,2.235,1.196,4.365,2.105,6.422c1.951,4.412,4.155,8.724,6.488,12.948c0.579,1.048,0.31,2.673,1.848,3.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M169.08,204.52c-2.304,0.548-4.641-0.373-6.97,0.2c-0.941,0.231-1.944,0.146-2.63,1"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M158.52,703.48c1.442,2.019,1.253,4.474,1.616,6.729c0.7,4.365,1.535,8.812,1.351,13.184c-0.161,3.83-0.104,7.696-0.503,11.533
				c-0.125,1.198-0.992,0.984-1.503,1.434"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M395.4,582.04c-3.935,2.103-6.744,5.39-9.36,8.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M462.84,592.84c-3.446-0.591-6.811-0.315-10.08,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M59.64,490.12c-0.4,0.32-0.804,0.635-1.199,0.961c-0.506,0.418-0.945,1.369-1.646,0.904c-0.711-0.472-0.051-1.243,0.199-1.868
				c0.188-0.469,0.261-0.992,0.497-1.431c0.898-1.674,0.521-2.441-1.452-2.167"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M525.24,335.08c-0.292-1.612-1.382-2.141-2.88-2.166c-1.014-0.017-1.569,0.498-1.666,1.447c-0.11,1.077-0.113,2.298,0.964,2.842
				c1.739,0.878,3.051,0.351,3.582-1.644"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M68.52,545.08c1.075,1.057,2.624,0.291,3.879,1.143c2.146,1.454,2.618,2.757,0.892,4.59c-1.043,1.108-2.368,1.952-3.572,2.906
				c-0.892,0.707-0.849,1.337,0.248,1.658c0.927,0.271,1.908,0.436,2.874,0.487c5.118,0.273,10.239,0.496,15.359,0.735"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M35.4,551.8c-3.2,1.056-6.559,0.841-9.846,1.134c-1.201,0.106-2.254,0.343-3.367,0.753c-1.955,0.72-3.988,0.717-5.987,0.033"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M26.28,463.96c-0.116,0.869-0.862,0.544-1.189,0.446c-2.926-0.879-5.781-0.194-8.65,0.273"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M555.96,368.2c-2.019,2.506-4.532,1.425-6.96,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M544.92,369.16c-1.407-0.14-2.653-0.553-3.867-1.4c-2.287-1.595-4.371-1.347-6.453,0.44c-1.081,0.928-2.303,0.319-3.837-1.922
				c-0.101-0.146-0.162-0.318-0.243-0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M565.68,325.48c0.21-2.784,0.523-5.568-1.54-7.937c-0.57-0.653-1.197-0.815-1.938-0.69c-1.128,0.191-1.012,1.107-0.953,1.906
				c0.109,1.471-0.389,2.782-0.969,4.081"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M452.28,335.56c2.057,2.227,4.364,4.068,7.169,5.348c0.911,0.416,1.728,0.645,2.671,0.652"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M314.04,647.08c3.713,2.069,6.323,5.336,9.12,8.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M174.36,609.4c0,0.08,0,0.159,0,0.239c0,1.601,0.593,3.631-0.167,4.679c-0.753,1.037-3.024,0.823-4.364,1.61
				c-4.105,2.41-8.675,4.225-11.548,8.352"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M188.04,49.72c-0.552-0.707-0.571-1.631-0.884-2.43c-1.007-2.579-2.539-3.504-5.123-2.877c-2.054,0.498-3.779,1.567-5.513,2.667"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M563.4,734.92c-5.065,1.548-10.284,2.697-14.675,5.954c-0.464,0.344-1.405,0.046-2.126,0.046"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M108.12,606.28c3.256,0.64,6.589-0.284,9.84,0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M415.08,606.52c-2.922-2.215-6.341-2.576-9.84-2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M330.84,576.04c-4.197,0.15-8.258,0.779-11.76,3.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M346.2,349.48c-0.021,3.011,0.356,5.894,2.64,8.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M252.36,595.48c-0.118,1.093-0.989,1.137-1.667,0.919c-1.008-0.324-0.042-0.918-0.02-1.399c0.078-1.715-1.814-1.746-2.402-1.786
				c-3.352-0.228-6.689-0.92-10.069-0.595c-0.094,0.009-0.162,0.301-0.242,0.461"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M180.12,191.8c-4.301,0.488-7.678,2.736-10.536,5.783c-2.19,2.335-1.369,6.489,1.174,7.901c1.11,0.615,2.414,0.957,3.122,2.157"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M26.52,757.72c2.012,5.812,5.478,10.808,8.88,15.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M503.16,345.64c-0.1,1.562,0.887,1.869,2.159,1.933c0.77,0.038,1.288-0.269,1.423-0.977c0.222-1.168-0.668-1.94-1.454-2.332
				c-0.971-0.483-1.833,0.206-2.368,1.135"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M130.44,598.84c1.929,1.045,4.003,0.271,6.002,0.46c1.189,0.112,2.398,0.021,3.598,0.021"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M314.28,752.44c0.346-0.001,0.509-0.231,0.763-0.425c0.908-0.694,1.26-2.753,2.533-2.093c1.223,0.634,1.451,2.569,1.445,3.957
				c-0.026,6.4-0.262,12.8-0.422,19.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M149.88,471.88c2.354,0.667,4.798,0.66,7.194,1.002c0.852,0.121,3.162-0.302,1.239,1.907c-0.454,0.521,0.404,0.952,0.687,1.411"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M384.12,501.64c-0.042,0.481,0.479,0.662,0.518,1.197c0.209,2.955,0.225,3.301,2.851,3.495c2.188,0.162,4.609,0.906,6.712-0.612"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M435.24,469.72c-1.604,0.636-2.368,2.133-3.326,3.387c-0.535,0.7-1.372,1.955-0.454,2.516c1.027,0.629,2.269,1.869,3.775,0.812
				c0.51-0.357,0.965-0.794,1.444-1.193"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M521.16,463.12c-0.736,0.957-1.938,0.676-2.88,1.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M168.84,490.6c-0.114-0.418-1.307-0.47-0.47-1.188c1.248-1.072,2.064-2.548,3.545-3.445c0.58-0.352,1.634-1.313,0.954-2.291
				c-0.495-0.711-1.67-0.445-2.328-0.214c-1.905,0.669-3.886,0.221-5.782,0.659"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M161.4,682.36c-0.573,1.166-0.549,2.377,0.265,3.339c1.471,1.738,1.063,4.533,3.335,5.781"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M460.2,396.52c-2.423,0.837-4.429,2.382-6.474,3.85c-1.024,0.735-2.104,1.129-3.366,0.951"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M538.44,433.96c-0.561,0.561-1.072,1.182-1.689,1.669c-2.216,1.753-2.836,4.113-1.582,6.443c1.056,1.963,0.205,3.22-1.096,3.778
				c-1.555,0.667-3.112-0.03-3.571-2.047c-0.109-0.481-0.63-0.882-0.222-1.443"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M37.08,401.8c0,0.4,0.173,0.993-0.025,1.172c-3.985,3.575-7.839,7.345-12.938,9.381c-0.538,0.215-1.117,0.327-1.677,0.487"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M460.44,286.6c4.112,2.777,8.83,4.143,13.439,5.76"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M94.2,413.8c-2.4-0.169-4.757-0.358-7.209-0.276c-4.698,0.157-5.993,3.352-7.01,6.338c-0.9,2.644-2.257,4.153-4.465,5.516
				c-1.8,1.111-1.058,3.089-0.729,4.738c0.255,1.281,0.506,2.445-0.986,3.125"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M114.36,698.92c-0.202,3.061-2.329,5.46-2.88,8.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M506.76,431.08c-1.553,2.17-3.692,2.783-6.241,2.412c-0.941-0.137-2.1-0.111-2.334,0.962c-0.225,1.033,0.719,1.785,1.627,2.113
				c1.264,0.456,2.103,0.968,2.732,2.386c0.82,1.848,2.96,1.462,4.696,1.487"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M241.8,564.28c3.2,0.08,6.4,0.159,9.6,0.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M571.8,557.8c-2.938,1.625-6.053,2.761-9.359,3.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M196.68,755.8c-1.993,0.842-2.738,2.863-4.085,4.316c-0.964,1.038-1.671,2.288-3.097,2.926c-1.317,0.589-1.426,2.137-1.643,3.328
				c-0.417,2.289-1.157,4.475-1.736,6.71"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M404.28,326.08c-0.475,2.486-1.933,4.439-3.874,5.833c-3.202,2.302-4.497,5.497-4.991,9.168c-0.307,2.277-1.674,4.049-2.695,5.977
				c-0.374,0.705-1.03,1.487-2.12,1.221"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M492.36,749.32c-3.306-1.947-6.445-0.588-9.601,0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M159.96,712.12c-2.827,0.756-4.011,2.445-4.223,5.51c-0.149,2.155-0.848,4.406-1.866,6.562c-0.983,2.082-0.743,4.996,0.232,7.061
				c1.148,2.434,0.585,4.889,1.206,7.291c1.269,4.917,1.297,10.048,1.768,15.098c0.623,6.681-0.76,13.095-2.637,19.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M310.68,443.56c-0.048,2.258-0.78,3.928-2.82,5.364c-2.848,2.004-3.307,5.744-5.115,8.546c-0.685,1.061-0.555,2.692-2.144,3.13"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M266.76,600.76c-3.496,1.197-6.837,2.642-9.12,5.76"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M558.6,604.6c-3.165-0.761-6.271-1.858-9.6-1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M292.92,407.32c-0.791,3.2-0.791,6.399,0,9.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M316.92,744.52c-1.252,2.639-2.887,5.123-3.147,8.158c-0.05,0.576-0.367,1.181-0.706,1.674c-3.986,5.812-3.91,12.184-2.626,18.729"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M529.8,637.48c3.526,0.896,6.646,3.347,10.561,2.76"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M20.28,702.52c-1.266,1.18-0.835,2.756-0.665,4.073c0.792,6.143,1.222,12.292,1.475,18.483c0.188,4.591,0.252,9.297,2.07,13.684"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M576.84,645.4c-3.2,0-6.399,0-9.6,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M528.12,253.72c0.363,2.569-1.156,4.751-1.532,7.186c-0.184,1.187-0.209,2.45-0.628,3.614"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M349.56,543.16c0.191,2.158-0.618,4.168-1.008,6.23c-0.243,1.29-1.118,2.186-2.32,0.703c-0.433-0.533-1.454-0.218-1.472-1.174"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M470.76,302.68c-2.163-0.482-3.788,0.516-4.06,2.643c-0.244,1.915,0.249,3.408,2.62,3.357"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M389.4,518.68c3.006,1.637,6.386,2.124,9.6,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M507,539.32c0.074,1.986,0.431,3.81,2.875,4.467c-0.921,1.294-1.536,2.554-2.641,3.446c-0.565,0.457-1.137,0.55-1.939,0.341
				c-0.42-0.108-1.073-1.296-1.661-0.098c-0.502,1.022,0.229,2.484,0.506,2.531c2.861,0.491,2.759,3.138,3.819,4.913
				c0.282,0.472,0.389,1.155,1.201,0.959"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M479.4,389.32c-2.708,0.022-4.761,1.624-6.964,2.874c-0.777,0.441-1.597,0.807-2.396,1.207"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M341.64,726.28c-3.222,1.841-6.615,3.413-9.359,6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M89.88,690.04c-0.608,2.567,0.95,4.779,1.374,7.169c0.214,1.204,0.274,2.701,1.026,3.871"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M308.28,622.36c-0.96,0.035-1.92,0.557-2.88,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M323.88,246.76c-0.082,2.086,0.184,4.174-0.431,6.255c-0.289,0.979-0.175,2.134,0.431,3.105"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M173.88,619.48c-4.943,1.406-9.221,3.859-12.6,7.961c-2.58-2.301-5.531-4.199-8.521-6.041"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M374.04,526.36c-0.08,0.08-0.148,0.178-0.242,0.237c-1.676,1.061-4.032,1.527-2.758,4.611c0.393,0.948-1.302,0.49-2.041,0.668
				c-1.03,0.247-1.201-0.373-1.204-1.197c-0.004-1.297,0.062-2.576-0.427-3.858c-0.267-0.699-0.446-1.153-1.248-1.189
				c-0.916-0.041-0.806,0.571-0.995,1.198c-0.984,3.259-0.315,6.574-0.36,9.851c-0.018,1.244-0.21,1.937-1.284,2.399"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M543.48,222.28c-0.167,3.944-1.185,7.796-1.239,11.759c-0.011,0.78,0.622,1.781-0.441,2.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M137.16,521.08c0.438,0.654,1.144,1.421,0.663,2.12c-0.452,0.657-1.381,0.163-2.103,0.043c-1.668-0.278-3.327-0.666-5.04-0.483"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M440.52,603.88c-1.359,0.561-2.711,1.141-4.081,1.676c-2.698,1.054-5.254,2.797-8.407,1.711c-0.333-0.115-0.849,0.034-1.183,0.229
				c-3.584,2.091-7.058,0.155-10.565-0.281c-0.345-0.043-0.643-0.454-0.963-0.694"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,722.68c-2.32,1.44-4.594,2.964-6.977,4.292c-1.636,0.911-3.215,1.941-4.246,3.429c-1.047,1.511-2.294,1.695-3.898,1.676
				c-1.438-0.018-2.896-0.292-4.347,0.385c-0.692,0.322-1.551,0.139-2.182-0.87c-1.594-2.544-4.616-3.658-6.431-6.031"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M435.72,353.8c-3.252,0.847-6.627,1.056-9.852,2.125c-4.747,1.575-9.196,3.616-12.948,6.995"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M417.48,370.6c0.187,2.208-1.443,3.584-2.382,5.29c-1.143,2.078-3.003,4.08-1.219,6.71"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M511.56,370.12c1.934,1.87,1.143,4.518,1.912,6.723c0.466,1.338,0.032,2.945,1.208,4.077"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M295.8,575.08c-2.591,0.872-5.231,1.629-7.447,3.35c-0.59,0.459-1.272,0.404-1.913,0.011"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M194.04,430.6c-1.472,2.108-1.431,4.369-0.192,6.452c0.983,1.651,0.248,1.511-1.009,1.488c-3.17-0.059-6.131,1.622-9.359,1.18"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M476.76,373.24c-2.417,0.737-4.46,2.129-6.504,3.565c-0.802,0.563-1.55,1.335-2.616,1.474"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M567.48,495.4c-1.452-1.46-2.486-3.229-3.314-5.061c-0.714-1.579-1.809-2.248-3.406-2.38"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M474.6,536.68c0.229,1.38,0.853,2.467,2.157,3.127c2.465,1.247,3.638,3.446,4.325,5.992c0.573,2.123,1.936,3.684,3.59,5.052
				c0.644,0.532,0.994-0.014,1.448-0.251"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M218.04,661.96c1.235,2.604,4.153,3.52,5.775,5.749c0.629,0.866,1.425,1.611,2.145,2.411"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M399.48,522.52c0.285,2.039,1.68,3.639,2.089,5.728c0.29,1.48,0.12,2.359-0.649,3.393"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M66.6,499.96c0.852-2.644,0.762-2.795-1.919-2.625c-1.126,0.071-2.306,0.138-3.335,0.533c-0.547,0.21-0.826-0.062-1.226-0.068
				c0.16-0.399,0.206-1.006,0.498-1.167c2.319-1.284,3.629-3.587,5.502-5.312"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M399.48,717.64c0.82-0.217,1.203-1.627,2.13-1.143c0.962,0.502,0.564,1.688,0.494,2.581c-0.348,4.4-1.522,8.655-2.412,12.956
				c-0.486,2.352-1.237,4.65-1.899,6.964c-0.308,1.078,0.887,1.506,0.967,2.402"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M462.6,659.8c-3.987-0.581-8.014-0.804-12.012-1.354c-2.658-0.367-5.439-0.046-8.141,0.224c-0.884,0.088-1.766,0.112-2.647,0.171"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M52.68,487.72c-1.915,1.272-4.02,2.093-6.24,2.64c-2.015,0.495-3.987,1.064-5.28,2.881"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M293.16,709.72c-1.935,1.027-4.029,1.871-6.044,2.501c-3.25,1.017-3.425,3.667-3.669,5.913c-0.339,3.121-1.354,5.958-2.287,8.866"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M462.12,406.12c-3.04,1.92-6.08,3.84-9.12,5.76"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M285.96,734.68c-1.649-1.583-2.812-3.544-3.79-5.544c-1.185-2.423-3.022-4.034-5.543-3.459c-2.624,0.598-3.698,3.189-4.317,5.65
				c-0.795,3.16-0.167,6.365,0.78,9.324c1.122,3.503,1.391,6.945,1.479,10.585c0.091,3.772-0.905,7.138-2.055,10.562
				c-1.079,3.215-2.155,6.425-2.4,9.842c-0.046,0.64-0.198,1.306-0.957,1.425c-0.905,0.143-0.703-0.726-1.021-1.135
				c-0.677-0.867-0.449-1.836-0.599-2.699c-1.029-5.914-4.078-11.011-6.608-16.276c-2.569-5.347-5.435-10.56-8.144-15.846
				c-1.111-2.169-2.643-4.2-4.313-5.983c-1.966-2.1-2.933-1.593-3.83,1.143c-1.555,4.745-0.502,9.325,1.09,13.658
				c2.037,5.545,4.861,10.813,8.379,15.581c1.521,2.061-0.392,3.14-0.695,4.583c-0.238,1.132-1.206,2.458-2.498,3.15"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M216.12,619.24c-1.2,0.479-2.381,1.016-3.604,1.428c-2.475,0.836-4.779-0.031-5.649-2.191c-1.625-4.029-4.336-7.084-7.307-10.036"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M443.4,468.28c-1.008,0.837-3.028-0.365-3.562,1.871c-0.075,0.314-2.979,0.336-4.331-0.478c-1.229-0.738-0.076-1.579,0.476-1.821
				c1.778-0.781,3.624-1.491,5.516-1.882c0.618-0.128,2.177-0.018,1.901,1.589"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M26.28,379.48c0.038,3.518,1.719,5.039,5.04,4.56c2.196-0.317,4.611,0.055,6.313,1c-1.448,2.838-2.371,5.962-4.633,8.359"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M349.08,348.28c0.687,2.542,0.491,5.194,0.117,7.662c-0.424,2.8,0.737,5.142,1.083,7.699"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M358.2,635.08c-2.546-3.647-5.065-7.329-8.432-10.284c-0.799-0.701-1.46-1.686-2.608-1.956"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M575.4,387.16c0.57,0.49,0.471,1.084,0.236,1.678c-0.601,1.53-0.408,3.353,0.572,4.222c1.529,1.356,1.326,2.828,1.351,4.42"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M477.24,378.28c-2.491,0.604-4.401,2.252-6.482,3.597c-0.843,0.544-1.601,1.251-2.638,1.443"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M200.04,677.56c3.907,1.197,7.472,2.968,10.339,5.982c0.458,0.482,0.835,1.517,1.646,1.183c3.471-1.43,4.041,1.611,5.261,3.418
				c1.888,2.794,3.513,5.764,5.336,8.604c0.659,1.026,2.257,0.975,2.858,2.174"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M156.36,501.4c-1.382-0.051-1.992,0.947-2.645,1.917c-0.94,1.397-0.583,2.231,0.944,2.929c0.875,0.398,2.056-0.161,2.661,0.914"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M126.84,595.24c4.965-3.953,10.473-3.458,16.072-1.893c1.086,0.304,1.178,1.348,1.096,2.373c-0.192,2.429-0.436,4.868,0.592,7.199"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M43.08,445.24c-2.427-1.658-5.13-0.055-7.706-0.599c-1.072-0.227-0.987,1.487-1.229,2.505c-0.442,1.855,1.124,2.051,1.956,2.911
				c1.199,1.239,2.806,0.719,4.108,0.818c2.394,0.185,4.779,0.607,7.19,0.604"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M495,362.92c-3.326,0.948-6.125,2.951-9.12,4.56"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M560.76,586.12c3.489,1.216,6.672,2.889,8.88,6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M394.44,571.72c-3.163,1.548-6.568,2.678-9.12,5.28"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M101.64,584.44c7.32-0.354,14.021,1.822,20.094,5.625c2.317,1.451,4.401,3.657,5.586,6.375"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M369,507.16c3.352,0.579,4.434,3.715,6.469,5.771c0.557,0.563,0.972,1.269,1.451,1.908"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M364.2,751.72c-1.173-3.591-1.149-7.429-2.189-11.031c-2.022-7.012,0.571-13.527,2-20.157c0.337-1.562,0.113-3.055,0.137-4.572
				c0.008-0.54-0.382-1.3-1.149-1.427c-0.689-0.113-0.854,0.371-1.271,0.89c-1.865,2.318-3.006,4.875-3.839,7.718
				c-0.594,2.026-1.725,3.937-2.84,5.762c-1.447,2.368-1.305,4.918-1.145,7.457c0.304,4.825,0.979,9.613,1.594,14.409
				c0.363,2.843-0.641,5.614-1.298,8.411c-1.057,4.501-3.012,8.767-3.479,13.418c-0.026,0.269-0.602,0.483-0.922,0.724"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M550.92,758.2c-3.344,1.791-7.091,2.809-10.082,5.277c-0.704,0.58-1.438,1.122-2.158,1.683"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M441.72,188.2c-1.859-1.37-4.109-1.99-6.057-3.274c-1.905-1.257-3.599-0.008-4.304,2.301c-0.591,1.938,0.545,3.678,0.28,5.532"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M556.44,313.24c-0.561-0.081-1.171-0.044-1.671-0.263c-1.251-0.548-2.061-0.032-2.637,0.99c-0.595,1.058-0.128,1.908,0.704,2.636
				c0.522,0.457,0.916,1.087,1.683,1.196"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M53.16,756.04c-2.366,2.671-4.105,5.748-5.76,8.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M26.76,416.44c-1.077-0.019-1.677-0.663-2.453-1.383c-2.47-2.292-5.274-4.159-8.586-5.098"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M501,563.56c-1.088-1.216-2.902-1.463-4.074-2.938c0.519-0.183,0.849-0.362,1.195-0.409c0.813-0.108,2.032,0.848,2.337-0.506
				c0.265-1.178-0.985-1.654-1.868-1.866c-2.201-0.528-3.841-2.443-6.229-2.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M113.4,569.8c-3.007-0.341-5.917,0.511-8.88,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M316.68,764.44c-1.199,2.96-2.399,5.919-3.6,8.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M159.24,518.68c-2.983,0.89-5.438,3.408-8.936,3.041c0.108-1.121,1.043-1.419,1.489-2.088c-0.308-0.651-0.908-0.388-1.427-0.502
				c-2.856-0.63-5.742-0.748-8.646-0.69"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M16.44,554.44c-0.521,3.81,1.729,7.243,1.482,11.042c-0.098,1.504,0.277,3.037,0.438,4.558"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M15.72,736.12c0.422,3.275,2.01,6.078,3.6,8.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M410.76,363.16c-1.832-0.052-3.508,0.498-5.031,1.454c-1.339,0.841-2.651,1.655-4.328,1.426"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M325.08,722.92c-1.664-1.812-5.466-2.32-3.669-6.271c0.298-0.654,0.602-2.047-0.436-2.559c-1.021-0.504-1.358,1.184-2.376,1.149"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,657.88c-3.342,1.769-6.217,4.081-8.4,7.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M377.88,544.6c0.654,1.634-0.102,3.315,0.318,5.079c0.443,1.866,1.257,2.386,2.874,2.49c-0.048,1.72-2.451,2.892-1.396,4.347
				c0.782,1.078,1.832,2.662,3.724,2.484"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M36.84,427.96c-0.743-2.746-3.024-3.887-5.285-5.03c-0.733-0.371-1.602-0.592-2.13,0.246c-0.537,0.854-0.386,1.761,0.205,2.631
				c1.972,2.901,4.41,5.34,7.209,7.434"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M482.04,565.48c1.343,0.826,1.996,1.908,2.261,3.584c0.263,1.672,1.339,3.216,2.06,4.815"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M289.32,556.84c2.4,0.32,4.8,0.64,7.2,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M509.64,554.68c-0.025,1.013-0.92,2.354,1.197,2.46c0.724,0.037,1.479,0.591,1.76,1.6c0.114,0.413,0.392,1.41,1.363,1.221
				c0.13,1.149,3.03-0.447,1.915,1.918c-0.862,1.828-2.584,1.858-4.052,1.371c-1.694-0.562-3.324-1.347-5.023-1.959
				c-1.017-0.366-1.788-0.183-2.68-0.13"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M275.88,311.56c-1.67,1.416-3.877,0.969-5.793,1.573c-1.034,0.326-2.07,0.671-3.086,1.067"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M245.16,610.12c-2.951-1.97-6.24-0.838-9.361-0.876c-1.896-0.023-3.926,0.031-5.759,0.876"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M174.12,581.56c3.446,2.056,5.85,5.183,8.4,8.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M221.16,524.44c0.226,1.641,0.047,1.771-1.668,2.663c-1.679,0.873-4.06,0.85-4.572,3.337"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M44.04,430.84c-0.042,1.243,0.137,1.818,1.701,1.258c2.126-0.762,4.369-1.328,6.907-1.162c-0.661,2.44-2.765,3.221-4.325,4.416
				c-1.217,0.932-3.099,1.746-2.235,3.34c0.819,1.512,2.727,1.437,4.451,0.587c1.489-0.733,3.265-0.675,4.803-1.436
				c0.455-0.226,1.256-0.274,1.659,0.438"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M93.96,497.08c-0.063,1.598-4.966,6.812-6.477,6.686c-2.055-0.174-3.572,0.766-5.043,1.954"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M170.76,758.92c-3.222,3.665-5,8.234-7.647,12.261c-0.442,0.674-0.586,1.556-1.232,2.14"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M34.2,184.12c-0.16,1.6-0.32,3.2-0.48,4.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M332.52,454.84c-0.851,1.976-2.227,3.474-4.071,4.574c-0.315,0.188-0.507,0.547-0.969,0.471c-4.625-0.756-6.133,4.31-9.84,5.275"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M348.84,440.2c-0.545,3.286,2.566,6.115,1.057,9.641c-0.515,1.204-0.771,1.272-1.78,0.932c-3.021-1.018-5.815-2.657-9.105-2.994
				c-0.869-0.089-1.488-0.33-2.439,0.022c-1.355,0.504-2.74-0.352-3.503-1.41c-0.633-0.879-0.942-2.156-0.079-3.318
				c1.26-1.696,3.064-2.941,3.896-5.015c0.352-0.878,0.765-0.779,1.381,0.47c1.206,2.44,1.855,5.085,2.654,7.673"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M45.72,565.48c-2.872,2.461-2.636,6.276-4,9.395c-0.226,0.518-0.549,1.192-0.572,1.885c-0.102,3.098-1.271,5.263-4.788,5.28"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M401.88,646.6c-1.86-0.476-3.527-1.432-5.302-2.103c-1.091-0.413-2.318-0.824-3.578-0.537"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M529.32,365.56c-1.354-0.764-2.787-0.968-4.323-0.742c-0.473,0.069-1.117,0.032-1.165,0.505c-0.364,3.634-3.604,2.489-5.584,3.275
				c-1.158,0.459-2.84-0.182-3.074,1.764c-0.231,1.92,1.576,1.931,2.631,2.63c0.204,0.135,0.476,0.167,0.715,0.248"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M494.52,569.8c-2.079-0.08-4.16-0.159-6.239-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M531.96,397.48c2.118,1.861,4.434,0.973,6.72,0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M559.8,641.32c-2.82-0.268-4.924,1.445-7.179,2.679c-0.48,0.263-0.968,0.468-1.461,0.681"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M15.72,179.32c0.986,3.126,0.513,6.436,1.2,9.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M300.6,570.76c2.475,0.352,4.822,1.025,6.991,2.35c0.447,0.272,1.15,1,1.89,0.291"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M410.76,709.96c-2.821-1.336-5.73-2.127-8.88-1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M502.32,626.92c-0.04,4-0.044,8.001-0.156,11.999c-0.019,0.651-0.563,1.241-1.404,1.199c-1.79-0.089-3.953,0.631-4.987-1.464
				c-0.798-1.616-2.001-2.646-3.412-3.574"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M294.36,322.6c-0.796,1.491-2.363,1.033-3.59,1.466c-5.848,2.065-11.986,2.801-18.014,4.031c-1.577,0.322-2.496,0.09-2.775-2.119
				c-0.345-2.726-0.901-5.618-2.74-7.938"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M127.32,754.36c1.055,0.385,1.368,1.53,2.172,2.145c2.292,1.753,3.288,3.556,1.227,6.284c-2.307,3.056-2.172,6.687-1.719,10.291"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M111.72,472.6c-0.507,1.162,0.042,2.235,0.206,3.365c0.549,3.793-0.439,6.702-4.046,7.675"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M65.64,692.44c-2.65-0.996-4.914,0.918-7.434,1.065c-0.592,0.034-1.501,0.207-2.166,0.614"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M207.6,85.48c0.968,1.491,0.834,2.755-0.845,3.59c-1.509,0.75-2.792,2.314-4.795,1.45"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M477.72,383.56c-3.146,1.121-5.725,3.263-8.64,4.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M282.36,595.72c-3.597-2.317-7.433-3.572-11.76-2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M99.96,417.64c0.359,0.866,2.33,1.039,1.02,2.688c-0.36,0.453,1.014,1.108,1.12,1.08c3.117-0.849,5.64,0.241,7.958,2.211
				c0.186,0.158,0.623,0.021,0.942,0.021"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M567.24,408.76c2.214,0.501,3.499,2.612,3.359,5.521"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M461.4,569.8c-2.302,0.801-3.139,2.859-4.062,4.81c-0.376,0.796-0.539,1.924-1.575,1.856c-1.343-0.088-1.213-1.405-1.302-2.348
				c-0.13-1.366,0.408-3.269-1.474-3.495c-1.556-0.188-2.864,1.064-3.566,2.547c-0.508,1.07-0.704,2.309-1.941,2.87"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M183.24,529.48c-0.489,1.487-1.721,2.798-2.735,3.451c-2.015,1.299-0.444,4.02-2.545,4.948"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M439.56,578.92c-3.257-1.403-6.667-1.394-10.072-0.908c-0.975,0.139-1.923,0.153-2.888,0.191c-1.222,0.047-2.201,0.355-1.92,1.917"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M464.28,81.64c-2.377,1.129-3.586,3.417-3.651,5.999c-0.052,2.031,1.042,3.783,1.011,5.761"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M445.08,496.72c-1.087-0.433-2.135-1.212-3.363-0.364c-0.055,0.037-0.164,0.026-0.236,0.002
				c-4.904-1.692-10.167-2.773-13.192-7.683c-0.223-0.361-0.328-0.795-0.488-1.194"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M545.4,331.72c-1.599-0.795-2.571,0.684-3.547,1.287c-2.905,1.796-5.616,3.86-8.916,5.003c-1.379,0.478-2.071,1.662-1.48,3.318
				c0.521,1.462,0.753,3.014,1.076,4.532c0.154,0.73,0.175,2.122,1.587,2.18"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M268.44,203.56c0.8,1.12,1.654,2.207,2.385,3.369c0.754,1.199,0.846,2.356-0.455,3.364c-1.188,0.92-2.5,0.88-3.577,0.176
				c-1.261-0.824-2.273-2.029-3.393-3.069"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M495,367.72c-3.183,0.473-5.771,2.372-8.64,3.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M113.4,454.84c0,0.16,0.05,0.342-0.008,0.477c-0.585,1.366,1.518,2.973-0.049,3.997c-1.063,0.694-2.663,0.566-4.023,0.807"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M175.32,621.64c-1.358,2.82-4.594,2.867-6.762,4.505c-0.245,0.185-0.677,0.577-1.158,0.535"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M274.2,756.28c-3.931-1.073-7.892-1.957-12-1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M478.2,633.4c-1.436,0.307-2.54-0.47-3.586-1.22c-1.317-0.945-2.604-1.849-4.334-1.661"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M471.96,627.4c2.088,0.1,2.682-2.38,4.56-2.641"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M284.52,715.24c7.247,2.857,13.341,7.487,19.2,12.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M212.04,685c-1.738,3.645-1.636,7.673-2.4,11.52"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M144.12,570.28c-3.099,2.99-4.685,3.545-8.4,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M24.36,415.48c-1.975,0.198-3.834-0.548-5.762-0.695c-0.95-0.072-1.947-0.218-2.878,0.215"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M59.88,96.76c-1.523,1.405-2.895,2.976-3.785,4.827c-0.466,0.969-1.054,2.213-0.055,3.333"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M269.16,198.76c1.873-1.19,0.225-2.369-0.305-3.071c-2.618-3.469-7.255-4.087-10.705-1.449c-1.286,0.983-3.179,1.398-3.63,3.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M490.92,534.52c-0.884-0.578-1.725-0.326-2.108,0.504c-0.285,0.617,0.176,1.316,0.64,1.919c0.356,0.461,1.518,0.62,0.763,1.667
				c-0.515,0.713-1.182,0.733-1.934,0.717c-1.932-0.039-3.885,0.208-5.761-0.486"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M478.92,535.96c0.023-1.593-1.268-2.908-0.96-4.56"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M513.24,224.2c1.049,1.517,1.084,3.341,1.391,5.049c0.242,1.35,0.562,2.569,1.489,3.591"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M401.4,612.52c-2.881-0.4-5.761-0.39-8.641,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M97.32,573.88c-0.081,0.4,0.174,0.838-0.234,1.206c-2.2,1.982-3.268,4.829-5.286,6.954"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M252.84,662.68c4.024-0.106,7.563,1.121,10.56,3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M64.44,684.76c-2.88,0.64-5.76,1.28-8.64,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M81,542.44c2.245,1.543,4.771,2.351,7.443,2.609c1.588,0.153,3.06-0.112,4.396,1.4c1.179,1.338,3.124,0.178,4.722,0.31"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M140.28,498.04c1.779,2.365,3.622,4.678,6.017,6.458c0.434,0.323,1.053,0.915,1.65,0.25c0.586-0.652-0.097-1.072-0.48-1.412
				c-0.923-0.823-1.554-1.84-2.146-2.896"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M290.04,601.48c-1.578,1.018-2.958,0.706-4.594-0.177c-2.25-1.214-4.706-1.82-7.412-1.566c-2.934,0.275-5.914,0.062-8.874,0.062"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M367.32,551.8c3.071-3.671,0.009-6.964-0.851-10.286c-0.343-1.325-0.828-2.273,0.337-3.723c0.219,2.493,2.814,2.61,3.635,4.408
				c1.508,3.301,2.133,6.95,4.039,10.104c0.301,0.498,0.093,1.304-0.922,1.187c-0.527-0.061-1.112-0.149-1.472,0.555
				c1.577,2.117,2.029,5.127,4.833,6.396"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,348.52c-2.641-0.64-5.281-1.278-7.92-1.922c-0.377-0.092-0.825-0.134-0.721-0.718"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M346.2,630.76c2.8,0.71,5.6,0.813,8.399,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M461.16,567.4c-2.015-1.376-3.219-0.727-4.126,1.42c-0.491,1.164-1.201,2.427-2.834,2.42"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M371.76,628.84c0.922,2.627,1.955,5.207,3.24,7.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M431.64,565.96c1.865,1.961,1.873,4.798,3.308,6.994c0.606,0.929,0.901,2.04,2.105,2.768c2.205-3.299,2.025-7.178,3.018-10.713
				c0.498-1.778,0.714-3.854,3.323-4.145c1.1-0.121,1.973-0.317,2.908,0.512c1.168,1.034-0.226,1.621-0.312,2.418
				c-0.072,0.667-0.589,1.286-0.909,1.926"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M39,594.52c-1.925,2.299-2.11,5.336-3.368,7.917c-0.33,0.676-0.504,1.464-1.192,1.924"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M31.8,606.04c-5.146,0.271-10.283,0.573-15.133,2.609c-0.439,0.184-0.868,0.189-0.947,0.751"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M498.84,381.4c0.003-1.695-0.149-3.362-0.44-5.047c-0.531-3.079-3.112-4.642-6.03-3.332c-1.89,0.849-3.689,1.899-5.529,2.859"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M375.72,594.04c2.811,1.257,6.048,1.58,8.4,3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M557.16,572.2c2.877-0.14,5.536,1.245,8.399,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M443.64,429.64c-0.142,2.548-0.539,4.929-3.348,6.03c-0.483,0.189-0.812,0.771-1.212,1.17"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M408.6,600.76c2.86,0.487,5.805,0.646,8.4,2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M396.84,585.88c-3.267,1.284-5.835,3.455-7.92,6.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M447.48,188.2c0.145,4.361-0.884,5.652-5.044,6.217c-0.433,0.059-0.7,0.215-0.956,0.503"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M71.16,646.12c-4.908,2.417-7.058,7.137-9.589,11.526c-0.714,1.237-1.291,2.554-1.931,3.834"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M54.84,729.88c3.4-2.739,4.394-6.904,6.226-10.567c0.61-1.219,1.021-2.541,1.936-3.591c0.985-1.131,0.764-2.152-0.367-3.255
				c-3.305,3.841-6.675,7.609-10.909,10.462c-0.681,0.459-0.505,1.241-0.478,1.911c0.158,3.911-0.658,7.701-1.137,11.544
				c-0.331,2.652,0,5.578,0.169,8.376"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M508.2,392.44c0.03,1.323,0.047,2.266,1.868,2.812c1.256,0.377,3.086,1.823,1.803,4.187c-0.545,1.003-1.033,1.214-1.991,1.175
				c-1.278-0.053-2.562-0.053-3.839,0.005c-0.558,0.026-1.13-0.74-1.718,0.199c-0.573,0.916,0.302,1.444,0.358,2.176
				c0.033,0.436,0.826,1.047,1.368,1.165c2.216,0.482,3.484,1.911,4.311,3.882"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M361.08,524.92c0.081,0.961-0.831,1.467-1.043,2.381c-0.287,1.246-0.943,2.501,0.352,3.334c1.363,0.877,1.287-1.389,2.441-1.319
				c0.897,0.965,0.342,2.05-0.088,3.037c-1.935,4.449-1.958,4.439,1.218,7.688"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M155.16,683.32c-2.4-0.32-4.798-0.657-7.202-0.942c-0.385-0.046-0.871-0.224-1.198,0.222"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M274.92,577c-2.691,0.597-5.563-0.041-8.16,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M58.2,437.8c-0.855,1.733-2.439,2.589-4.083,3.355c-1.286,0.6-2.877,0.82-3.118,2.645"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M223.08,697.72c0.132,7.823-0.324,15.557-1.783,23.306c-1.044,5.546-2.128,11.062-3.473,16.541
				c-1.192,4.86-0.391,9.759,0.105,14.633c0.139,1.366,0.391,2.721,0.591,4.081"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M218.88,763.24c-0.756,2.34-1.198,4.727-1.083,7.2c0.053,1.157-0.297,2.119-1.437,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M141.24,708.76c-1.144-4.745-3.353-9.41-0.972-14.405c0.56-1.175,0.513-2.277-0.445-3.375c-1.474-1.689-1.308-4.548-3.864-5.499"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M28.44,431.08c4.016,3.698,8.899,2.905,13.68,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M69.24,567.4c0.5,0.966,0.827,1.846-0.719,2.167c-2.385,0.495-4.688,1.475-7.202,1.192"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M329.64,756.28c-1.803,1.712-2.293,4.158-3.248,6.29c-0.598,1.334-1.485,1.776-2.512,2.35"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M125.64,531.16c-2.374-2.812-5.815-3.351-9.145-1.731c-1.355,0.66-2.858,1.301-4.56,0.335c-1.829-1.038-2.928,1.192-4.535,1.396"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M242.76,626.68c1.25-0.875,1.668-2.194,1.919-3.6c0.44-2.465,0.98-4.904,1.042-7.438c0.016-0.644-0.059-2.036,1.122-2.112
				c1.093-0.071,1.377,0.799,1.746,1.847c1.386,3.926,2.928,7.773,3.113,12.021c0.025,0.579,0.897,1.122,1.377,1.683"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M442.68,415.24c1.102,0.311,1.775,0.827,1.977,2.151c0.291,1.919,1.731,3.512,1.863,5.528"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M181.8,617.32c4.76-2.258,9.461-1.646,14.169,0.217c0.816,0.322,1.751,0.343,2.631,0.503"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M579.48,567.16c0.124,3.242-1.215,5.819-3.601,7.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M59.64,442.6c-1.882-0.136-3.518,0.813-5.277,1.214c-1.04,0.237-1.999,0.651-3.131,0.249c-2.105-0.747-3.01,1.14-4.312,2.138"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M140.52,512.44c-1.44-0.24-2.875-0.522-4.321-0.709c-1.692-0.219-2.086,0.397-1.221,1.921c0.483,0.85,0.975,1.544,1.936,1.924
				c2.574,1.02,4.448,3.013,6.503,4.765c1.059,0.903,0.794,1.421-0.256,1.939"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M550.68,645.16c-3.979,2.307-6.534,5.976-9.095,9.617c-0.507,0.721-1.264,1.557,0.166,2.46c0.684,0.432,0.85,1.683,1.249,2.562"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M482.76,531.16c-2.479-0.08-4.962-0.118-7.438-0.259c-1.141-0.064-1.523,0.319-1.469,1.46c0.05,1.033-0.133,2.078-0.213,3.119"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M445.56,572.2c1.334,2.504,1.025,5.605,2.881,7.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M126.84,582.04c0.175,1.659-1.234,2.334-2.2,3.067c-1.595,1.21-3.155,2.429-4.52,3.893"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M448.92,530.68c0.497,0.45,0.808,0.976,0.722,1.681c-0.313,2.57,0.598,4.69,2.398,6.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M443.88,474.52c-2.199,1.822-2.062,2.23-0.044,3.663c1.521,1.081,3.182,1.375,4.844,1.857"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M172.68,585.4c3.076,1.369,5.572,3.429,7.44,6.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M371.4,353.08c0.399,2.72,0.8,5.44,1.199,8.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M445.32,500.68c-1.426,1.473-3.276,1.129-5.04,1.209c-1.68,0.076-3.381-0.27-5.04,0.231"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M449.88,433.48c-2.076,1.412-2.489,3.997-4.063,5.773c-0.623,0.702,0.319,1.406,0.224,2.146"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M23.88,684.28c-2.719-0.964-5.321-2.257-8.16-2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M53.64,575.56c2.816,2.206,5.771,4.261,7.92,7.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M23.88,481.24c-2.639-1.006-5.521,0.052-8.16-0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M559.56,602.2c2.818,1.492,5.827,2.699,7.921,5.28"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M454.68,331.96c2.618,1.846,4.942,4.166,8.16,5.04"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M136.44,765.4c-0.96,2.72-1.92,5.439-2.88,8.159"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M101.4,556.6c0.038,1.62-0.098,3.223-0.48,4.801"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M570.84,426.76c-0.72,0.88-1.391,1.808-2.17,2.631c-1.995,2.108-4.236,1.919-6.229-0.471"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M85.2,580.36c-0.6-2.08-1.117-4.19-1.837-6.228c-0.335-0.947-0.529-2.184-1.883-2.413"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M79.68,566.92c-1.055-1.275-2.5-1.262-3.96-1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M359.88,584.2c-2.599,1.366-5.714,1.666-7.92,3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M36.84,600.28c3.099-0.27,4.146,1.046,3.408,4.092c-0.293,1.209-0.217,2.576-0.011,3.822c0.362,2.188,1.735,2.653,3.307,1.189
				c0.735-0.686,1.463-1.321,2.416-1.664"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M49.8,604.12c0.024-0.647,0.967-1.198,0.235-1.915c-0.616-0.604-1.373-0.188-1.898,0.035c-2.175,0.923-4.408,1.77-6.257,3.319"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M482.28,709.48c-2.688,0.703-5.473-0.225-8.16,0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M120.36,487.48c-0.921-0.324-1.405-1.095-1.659-1.927c-0.791-2.597-2.679-3.142-5.061-2.874"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M66.6,483.64c0.763-0.361,1.225-1.617,2.407-0.729c1.018,0.763,1.393,1.587,0.989,2.901c-0.488,1.586-1.927,2.537-2.436,4.068"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M471,270.28c2.395,1.882,5.327,2.238,8.16,2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M511.8,323.8c1.523,0.223,2.509,1.008,2.122,2.631c-0.292,1.228-1.342,1.729-2.602,1.688c-1.575-0.05-1.714-1.309-1.899-2.403
				c-0.275-1.622,1.446-1.409,2.139-2.156"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M177.24,542.92c-0.8,0.72-1.558,1.496-2.414,2.142c-0.608,0.457-1.506,0.956-1.113,1.657c0.39,0.693,1.418,1.287,2.314,0.966
				c2.011-0.723,3.648,0.042,5.292,0.995"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M478.2,552.28c1.264,1.901,1.322,4.267,2.399,6.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M480.84,566.92c-1.333,2.473-0.886,5.362-1.885,7.934c-0.593,1.525-0.835,3.187-1.235,4.786"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M62.28,523.48c0.105,2.069,1.897,1.73,3.126,2.141c0.768,0.257,1.738-0.382,2.439,0.547c-0.41,0.616-0.828,1.244-1.246,1.872"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M76.92,579.4c1.575-0.062,2.677,0.567,3.637,1.893c1.702,2.348,3.265,4.727,4.322,7.452c0.425,1.097,1.08,2.177,2.122,2.895"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M69.96,469.72c1.662,0.721,0.285,1.64-0.052,2.125c-1.229,1.771-2.677,3.389-4.025,5.077c-1.016,1.274-1.187,2.372,0.451,3.4
				c0.689,0.433,1.797,0.857,1.466,2.118"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M516.72,420.04c-0.996-1.111-2.474-1.335-3.749-1.847c-0.715-0.286-2.06-0.539-2.357,0.89c-0.33,1.585-1.527,1.657-2.641,1.383
				c-0.907-0.224-1.802-0.258-2.646-0.14c-1.122,0.156-2.302,0.631-2.612,1.882c-0.304,1.221,0.584,1.559,1.623,1.97
				c1.35,0.535,1.475,2.017,1.703,3.303"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M471,535.72c-0.96-1.124-2.097-0.459-2.848,0.049c-2.321,1.567-4.519,3.331-5.792,5.951"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M291.48,397.48c-0.8-1.515-1.691-1.553-2.352,0.02c-0.239,0.569-0.497,1.185-0.592,1.892c-0.439,3.275-2.63,4.994-5.696,5.769"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M159.72,588.04c1.824,3.466,2.199,7.372,3.364,11.039c1.112,3.499,1.364,7.251,3.116,10.561"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M526.44,342.28c0.64,2.8,1.319,5.592,1.894,8.405c0.158,0.771-0.005,1.61,0.986,1.915"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M571.56,533.56c0.863,2.312,0.615,4.147-2.163,4.787c-0.972,0.224-1.614,1.139-1.135,1.634c1.851,1.904,0.525,3.721,0.179,5.579"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M274.2,631.48c0.108,0.971-0.216,1.673-0.931,2.427c-3.824,4.034-6.358,8.826-7.949,14.133"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M282.36,725.32c2.779,1.37,5.614,2.648,7.92,4.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M512.04,414.52c1.531-0.307,2.843,0.637,4.304,0.837c1.277,0.175,0.425,2.114,1.216,3.004"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M522.36,419.68c1.763,0.654,3.34-0.11,4.764-0.904c0.78-0.435,1.349-0.826,2.196-0.415"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M146.28,571.48c-0.221,1.626-0.428,3.326-1.943,4.283c-0.875,0.554-0.069,1.462-0.697,1.956"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M417.24,719.32c2.88,2.56,5.785,5.091,8.625,7.696c0.923,0.847,1.643,1.16,2.919,0.511c2.479-1.263,5.295-0.098,7.896-0.768"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M369,560.2c1.523,2.212,2.249,4.803,3.276,7.235c0.371,0.88,0.751,1.336,1.523,1.645"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M410.52,632.68c2.439,1.283,4.723,2.966,7.681,2.894c0.562-0.014,0.077,0.494,0.24,0.707"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M101.64,551.8c1.932,1.171,4.067,1.382,6.238,1.181c2.152-0.199,3.824,0.376,4.802,2.42"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M413.16,555.64c-0.802,0.36-0.583,0.907-0.234,1.437c0.428,0.651,0.404,1.396-0.261,1.646c-1.863,0.7-2.072,2.294-2.385,3.878"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M578.52,632.2c2.968,0.725,5.442,2.406,7.921,4.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M307.56,723.4c-2.644,0.088-5.336-0.608-7.919,0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M450.84,694.6c2.56,2.08,5.12,4.16,7.68,6.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M402.36,662.44c-2.58-1.602-5.247-2.827-8.396-2.963c-0.987-0.042-2.233,0.252-3.134-0.628c-0.001-0.001-0.168,0.14-0.224,0.234
				c-0.035,0.06-0.007,0.156-0.007,0.236"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M495.24,242.2c2.243,2.068,3.388,4.618,3.359,7.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M193.08,561.64c-2.124-0.333-3.552,1.877-5.779,1.585c-0.396-0.052-1.261,0.017-1.9,0.104c-1.181,0.16-2.425,0.013-2.928,1.657
				c-0.494,1.613-2.585,1.457-3.663,2.815c1.934,1.393,4.2,2.061,6.351,2.959"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M229.56,289c-0.435,2.521,1.883,3.546,2.921,5.255c0.527,0.868,1.509,1.462,1.398,2.665"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M515.16,243.16c0.992-0.714,0.296-1.251-0.252-1.665c-1.069-0.807-2.262-1.334-3.57-1.752c-1.709-0.544-1.784,0.262-1.698,1.497"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M331.56,600.52c-3.678,1.666-7.063,3.797-10.065,6.496c-1.068,0.961-2.213,1.388-3.614,1.185"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M576.12,388.12c1.619,0.098,2.899,1.104,4.295,1.735c1.577,0.712,2.02,0.097,2.179-1.256c0.301-2.559-0.577-4.754-2.044-6.824
				c-2.437,2.31-5.021,3.539-8.27,2.265"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M520.92,755.56c8.008-1.104,15.834-0.185,23.511,2.189c0.866,0.268,1.609,0.931,2.409,1.411"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M509.64,740.92c-2.866,1.692-6.185,2.207-9.184,3.712c-4.427,2.22-8.039,5.125-10.976,9.008"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M555.96,466.84c1.159,1.129,0.512,2.48,0.179,3.582c-0.627,2.074-1.953,3.865-0.772,6.312c0.458,0.95-0.598,2.239-1.756,3.131
				c-1.461,1.124-3.185,2.055-4.13,3.774"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M566.28,630.28c-1.12,1.039-1.613,2.445-1.877,3.848c-0.312,1.665-0.991,2.96-2.443,3.832"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M558.36,307.72c-3.451-0.393-6.87,0.606-10.383,0.449c4.222-3.615,7.717-7.63,10.87-11.963c0.632-0.869,1.474-1.419,2.633-1.206"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M490.92,607c-2.882,0.863-5.499,2.161-7.439,4.56"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M363,726.52c7.432-0.372,14.712,0.234,21.6,3.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M368.28,532.36c0.185,2.645,1.704,5.169,0.72,7.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M23.16,393.16c-2.658,1.78-5.385,3.473-7.44,6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M519,635.8c2.16,0.4,4.473,1.48,6.444,1.038c2.853-0.639,2.141,0.937,2.195,2.322"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M369.96,647.08c-2.965,1.063-5.608,2.616-7.68,5.04"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M412.2,541.24c-0.745-1.674-1.438-3.334-2.592-4.838c-1.486-1.939-1.817-2.101-3.654-0.448c-1.747,1.57-2.583,3.915-4.554,5.286"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M226.2,754.36c-2.586-0.76-5.12,0.177-7.68,0.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M96.12,684.28c-2.576-0.067-5.06-1.121-7.68-0.721"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M166.44,568.6c0.066,0.643-0.183,1.323,0.24,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M200.04,572.2c2.505,1.03,5.126,0.22,7.68,0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M462.12,624.52c-1.386,1.637-2.875,2.695-5.294,2.231c-1.852-0.356-3.826-0.071-5.746-0.071"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M446.04,726.04c-5.992-4.254-12.466-5.258-19.44-2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M369,736.36c-2.265-1.738-4.88-2.458-7.68-2.641"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M464.88,426.88c-2.324-3.8-2.614-3.947-6.36-3.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M76.44,608.2c-0.01,2.699,0.082,5.365-1.124,7.955c-0.473,1.015-0.557,2.45-1.761,2.518c-1.399,0.08-2.651-0.884-3.57-1.975
				c-0.54-0.642-0.825-1.498-1.226-2.258"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M262.2,554.68c1.577,1.399,0.722,3.235,0.648,4.797c-0.185,3.93-0.711,7.846-1.145,11.762c-0.078,0.709,0.27,1.149,0.753,1.411
				c0.412,0.223,0.793-0.182,1.201-0.418c1.878-1.089,3.297-2.656,4.51-4.373c0.993-1.405,1.434-0.893,1.955,0.26
				c0.545,1.207,1.18,2.377,1.665,3.606c1.287,3.26,3.386,5.71,6.982,6.161c1.648,0.206,0.864,1.061,0.951,1.754"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M399,485.56c1.062,1.595,0.376,3.366,0.428,5.042c0.046,1.477,0.281,2.22,1.977,1.943c1.853-0.301,3.45,0.604,5.036,1.417
				c-0.137,1.149-1.319,0.915-1.963,1.38c-0.717,0.517-2.005,0.652-1.802,1.724c0.194,1.025,1.152,1.764,2.324,1.933
				c0.639,0.092,1.385,0.408,1.897,0.189c0.999-0.427,1.474,0.05,1.657,0.784c0.144,0.571,0.367,1.45-0.675,1.669
				c-0.992,0.208-1.234,1.262-2.115,1.76c-3.246,1.835-3.239,3.446-0.284,5.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M435.48,574.6c-2.513,0.918-5.117,0.367-7.681,0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M29.64,600.52c-2.62-0.396-5.078,0.932-7.68,0.721"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M521.88,375.16c0.791,1.437,2.054,2.048,3.592,1.648c1.676-0.436,2.788,1.123,4.328,0.992"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M585.48,731.8c3.38,0.907,6.962,0.352,10.324,1.428c0.53,0.17,1.115,0.172,1.676,0.253"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M195.96,560.68c3.069,1.025,5.396,3.416,8.4,4.561"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M232.2,662.68c-3.426-0.087-6.54,0.737-9.12,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M345,645.88c4.407,0.053,8.565,1.101,12.48,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M160.92,736.12c0.827,1.847,0.766,3.809,0.71,5.76c-0.033,1.167-3.185,3.829-5.03,4.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M325.08,561.16c1.57-0.321,3.05,0.344,4.547,0.568c1.286,0.191,2.728,0.964,1.266,2.833c-0.27,0.345-0.272,1.106-0.532,1.639"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M400.68,190.36c-0.999,3.336-3.665,5.229-6.239,7.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M152.28,645.88c-4.449-1.021-8.872-1.279-13.454-0.57c-3.498,0.541-6.867,1.238-10.066,2.73"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M258.36,556.84c-2.727-1.607-4.819-4.098-7.68-5.52"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M72.6,529.48c-3.041-1.336-6.072-1.941-8.867,0.494c-1.465,1.276-3.238,2.026-4.813,3.105"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M63.48,580.6c-0.213,0.99-0.847,1.61-1.668,2.178c-2.721,1.877-4.705,4.446-6.492,7.183"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M44.04,389.8c0.335,1.789-0.953,2.991-1.743,4.281c-0.673,1.098-1.363,2.126-1.617,3.399"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M308.04,577.96c-2.33-1.55-4.97-2.197-7.68-2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M426.36,738.04c-2.878-0.029-5.446-1.591-8.401-1.557c-1.292,0.015-2.193-1.662-3.143-2.742c-0.93-1.058-2.057-1.941-3.097-2.901"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M119.52,606.76c1.317,1.073,2.983,0.354,4.472,0.869c1.279,0.443,2.412,0.781,3.088,2.011"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M91.08,643.24c3.36-1.065,6.739-0.427,10.074,0.043c2.563,0.361,5.068,1.129,7.686,1.157"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M34.2,386.92c-3.506,1.203-6.579,3.194-9.6,5.28"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M524.04,410.44c-2.211,0.572-4.392,1.561-6.72,0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M433.56,471.88c1.631,1.324,3.452,2.364,4.898,3.992c0.608,0.686,2.302,0.408,3.502,0.568"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M372.6,561.64c-0.609,0.727-0.084,1.406,0.27,1.899c1.219,1.7,1.256,3.596,1.171,5.541"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M377.64,570.04c0.617,0.538,0.426,1.681,1.44,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M338.28,735.4c2.453,0.161,4.509,1.447,6.436,2.708c2.071,1.354,4.158,1.449,6.258,0.806c0.97-0.298,1.784-0.096,2.666-0.154"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M542.28,571c-3.695-0.774-5.239,1.914-6.96,4.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M113.16,614.92c2.436,1.073,4.773,2.401,7.44,2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M97.8,678.76c-3.303-0.88-6.658-1.325-10.079-1.189c-0.359,0.014-0.939-0.288-0.961,0.47"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M532.92,627.16c-2.506-0.736-4.99-0.76-7.439,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M181.32,675.64c-3.728,0.752-7.518,1.356-10.32,4.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M565.32,344.68c0.946,1.652,2.234,1.835,3.847,0.972c1.038-0.557,1.993-1.025,3.385-0.596c1.032,0.318,1.802-1.076,2.608-1.816"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M489.48,742.84c2.387,1.305,5.12,1.959,7.199,3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M534.36,625.36c1.84,0.738,1.53,2.556,1.888,3.968c0.841,3.319,0.916,6.855,2.912,9.832"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M173.4,601.48c-1.34,1.376-3.091,0.441-4.573,0.558c-1.421,0.11-2.867,0.326-4.307,0.402"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M561.96,447.64c2.256,0.839,2.688,3.237,3.988,4.873c0.441,0.554-0.574,1.488-1.566,1.899c-0.979,0.404-1.919,0.406-2.901,0.428"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,568.12c-0.03-2.77-2.323-5.609-5.04-6.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M389.64,708.52c-3.012-0.595-6.135,0.223-9.12-0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M501.24,201.88c-0.385,1.062-0.819,2.074-1.67,2.891c-1.752,1.68-2.575,1.656-4.33-0.25"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M518.04,443.32c-2.911,2.359-6.263,0.423-9.36,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M323.88,557.8c1.102,1.297,0.81,2.858,0.691,4.317c-0.218,2.678-0.677,5.345,0.609,8.267c-1.513-0.63-2.853-0.292-4.181,0.136"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M368.52,655.72c2.67,0.64,3.933,3.126,5.984,4.583c0.818,0.581,1.456,1.417,2.176,2.138"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M55.08,763.96c-2.835,0.383-5.662,0.809-8.4,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M229.32,621.4c-2.886-1.386-5.98-1.978-9.12-2.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M412.2,624.04c-0.673,2.439-0.804,5.102-2.88,6.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M225.48,628.84c-3.765,1.665-7.766,2.933-10.56,6.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M339.72,630.76c-1.864,1.047-4,0.994-6.012,1.377c-1.115,0.211-2.192,0.3-3.108,1.023"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M141.48,468.04c0.125-0.936-0.448-1.394-1.202-1.675c-1.92-0.716-3.355-2.44-5.518-2.646"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M478.92,634.6c0.049,0.328-0.13,0.688,0.236,0.965c4.447,3.376,7.51,7.853,10.084,12.716"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M556.92,575.32c2.391,1.027,5.118,0.895,7.44,2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M171.24,535.24c0.891,0.992,3.384-0.182,3.17,2.404c-0.045,0.546,0.665,0.405,1.154,0.25c0.859-0.272,1.754-0.648,2.405,0.46
				c0.743,1.266-0.402,1.704-0.969,2.405"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M314.52,610.36c-1.837,0.261-3.381-0.785-5.021-1.265c-4.13-1.209-4.315-1.146-3.619,3.185"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M552.6,567.88c0.521,2.25,1.576,4.353,1.717,6.718c0.054,0.9,0.526,2.042,1.626,1.83c0.608-0.117,0.77-1.22,0.758-2.068
				c-0.029-2.168-0.194-4.355,0.46-6.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M595.2,626.92c-1.062,1.739-1.951,3.554-2.527,5.519c-0.235,0.804-0.747,1.657-1.45,1.862c-1.471,0.431-1.15,1.476-1.183,2.459"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M90.12,446.2c-2,0.88-3.939,1.986-6.025,2.548c-1.103,0.297-0.894,1.038-1.13,1.553c-0.557,1.215-1.335,2.157-2.445,2.859"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M478.68,527.08c0.845,0.467,1.648,0.62,2.652,0.274c1.865-0.642,3.469-0.372,4.068,1.886"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M515.88,642.28c-3.36,0.56-6.72,1.119-10.08,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M36.6,417.16c-3.16,3.444-7.326,2.592-11.28,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M465.96,439.96c2.112,2.246,4.576,1.095,6.96,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M36.6,697.48c-2.333,0.141-3.254,2.389-5.023,3.39c-0.819,0.464-1.457,1.25-2.177,1.89"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M207.24,562.36c0.16,2.399,0.208,4.813,0.509,7.196c0.356,2.815,0.916,5.605,1.416,8.402c0.407,2.272,1.84,2.848,3.591,1.435
				c1.3-1.049,1.756-2.616,2.424-4.064c0.53-1.149,0.81-2.512,2.574-3.344c0.747,0.872,1.656,1.819,2.434,2.864
				c1.269,1.707,2.751,2.062,4.573,0.95"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M310.92,691.96c3.091-0.157,6.051,0.92,9.12,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M532.44,667.24c-4.019-3.177-8.716-4.076-13.681-4.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M353.16,762.04c-2.554,0.063-4.853,1.082-7.2,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M343.8,482.68c-2.002-0.999-2.722,1.15-4.039,1.767c-0.972,0.454-1.725,1.439-2.921,1.594"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M476.28,556.6c-0.727,1.327,0.088,2.442,0.763,3.327c1.573,2.062,0.892,3.596-0.763,5.073"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,686.44c-2.4-0.48-4.801-0.96-7.2-1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M250.44,559.96c-2.4-0.16-4.8-0.32-7.2-0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M267.96,662.44c-1.668-2.491-4.17-3.637-6.96-4.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M227.64,758.92c-0.635-0.07-1.126,0.029-1.458,0.711c-0.533,1.096-1.445,1.812-2.622,2.169"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M221.64,765.4c-1.06,1.932-1.166,4.485-3.6,5.52"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M522.6,411.4c0.35,2.651,2.736,3.635,4.305,5.294c0.385,0.408,1.207,0.578,0.976,1.426"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M561.24,494.68c-0.646,0.592-1.25,1.185-2.152,1.465c-1.601,0.499-4.538,0.285-2.007,3.399c0.265,0.325-0.462,2.164-0.897,2.233
				c-2.595,0.407-1.65,2.031-1.439,3.465c0.033,0.224-0.144,0.478-0.225,0.718"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M554.28,743.8c5.215,1.957,10.043,4.487,13.434,9.125c0.407,0.557,0.604,1.205,0.486,1.915"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M406.92,608.68c-0.911,1.92-0.172,3.924,0.107,5.744c0.709,4.635,0.968,9.281,1.029,13.938c0.023,1.729,1.172,2.602,1.863,3.839"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M363.96,555.16c-1.096,2.087-0.229,3.906,0.703,5.769c0.926,1.847,0.4,2.935-1.666,3.062c-1.949,0.119-2.153,2.396-3.837,2.689"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M251.4,627.4c-2.4,0.315-4.8,0.439-7.2,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M322.2,631c1.278-0.066,1.924,0.844,2.644,1.676c3.6,4.16,6.38,8.82,8.362,13.938c0.397,1.027,0.354,2.227,0.514,3.347"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M474.6,408.76c-0.8-0.24-1.754-0.875-2.367-0.637c-1.243,0.484-2.941,0.139-3.651,1.829c-0.787,1.87,0.831,2.401,1.699,3.368"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M168.12,748.12c1.442,3.045,1.602,6.442,2.638,9.601c0.732,2.235,1.442,4.479,2.162,6.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M467.16,117.88c-2.218,1.393-4.413,2.897-7.2,1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M49.56,737.32c-1.753,1.71-1.591,4.228-2.599,6.261c-0.221,0.444,0.327,0.821,0.918,0.699"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M311.64,624.04c2.4,1.04,4.801,2.08,7.2,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M590.76,583.24c2.506,2.063,4.678,4.449,6.721,6.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M500.52,646.36c-9.753,3.761-17.92,9.495-23.279,18.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M355.56,610.6c-2.342-0.817-4.771-0.757-7.199-0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M474.36,612.52c-1.313,1.875-1.817,4.065-2.099,6.248c-0.16,1.253-0.607,1.837-1.738,2.165c-1.831,0.532-3.791,0.772-5.283,2.147"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M303.96,326.92c-1.04,2.102-3.305,1.931-5.056,2.598c-7.005,2.667-14.387,4.032-21.584,6.042"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M345,582.52c3.771,1.829,6.478,4.723,8.4,8.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M23.16,447.88c-0.143,0.72-0.965,1.341-0.019,2.182c1.817,1.612,2.939,3.788,4.339,5.738"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M323.88,735.64c-1.413-0.216-1.98,0.346-3.025,1.555c-3.313,3.833-2.418,8.324-2.975,12.605"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M37.32,440.68c-2.12-1.688-4.916-0.77-7.257-1.788c-0.338-0.147-1.161-0.79-1.863-0.372"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M325.56,764.68c0.112,3.011,1.114,6.195-1.68,8.641"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M211.08,56.92c0.425-2.726,2.707-1.253,3.56-1.04c1.916,0.479,3.388,2.159,3.822,4.173c0.432,2.003,0.371,4.118-1.625,5.502
				c-2.052,1.423-4.407,1.173-6.439,0.389c-1.92-0.741-3.755-2.103-3.651-4.704c0.019-0.476-0.119-0.967-0.245-1.434
				c-0.031-0.115-0.295-0.193-0.464-0.23c-0.059-0.013-0.156,0.145-0.237,0.225"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M545.16,678.04c-2.597,1.098-4.62,3.071-6.96,4.56"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M26.76,702.76c-1.405,0.88-0.207,1.746-0.015,2.644c0.292,1.368,2.192,2.354,0.976,4.077"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M521.4,345.4c1.6,1.44,3.342,2.758,4.734,4.377c0.606,0.706,1.117,0.582,1.745,0.663"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M260.76,599.56c-2.064,1.549-3.422,3.772-5.275,5.525c-0.289,0.273-0.896,0.261-0.742,0.959c0.189,0.854,0.808,0.436,1.218,0.458
				c3.08,0.171,5.833,1.256,8.399,2.898"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M163.8,603.64c-2.521,0.914-4.35,3.085-6.96,3.841"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M153.24,444.04c-1.711-0.231-1.937,1.167-1.857,2.155c0.193,2.4-1.224,4.568-0.812,6.97c0.049,0.284-0.292,0.635-0.452,0.955"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M195.96,574.36c-1.777-0.138-3.023,1.163-4.546,1.717c-3.853,1.399-5.745,5.115-8.654,7.643"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M82.44,675.4c-1.101,1.8,0.146,3.39,0.514,5.032c0.295,1.317,0.72,2.567-0.034,3.848"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M172.68,485.56c2.719,0.981,2.798,1.313,0.709,3.591c-0.571,0.623-1.27,1.13-1.91,1.689"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M252.84,760.6c-2.703,3.391-3.43,7.033-1.448,11.045c0.301,0.609,0.488,1.275,0.729,1.915"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M326.52,644.2c1.891,2.009,3.123,4.404,4.08,6.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M167.16,740.68c-1.757,1.898-2.292,4.233-2.4,6.721"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M153,610.6c-2.044-2.646-2.726-5.917-4.07-8.885c-1.114-2.458-2.319-4.829-4.57-6.475"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M182.76,621.16c-2.296,0.489-4.501,1.643-6.96,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M562.92,479.32c-0.224,0.868-1.279,1.551-0.508,2.659c0.188,0.27,0.18,1.041,0.006,1.142c-3.074,1.79-1.72,4.971-2.308,7.495
				c-0.366,1.577-0.798,2.735-2.471,3.104"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M44.52,510.52c0.397-2.396-0.835-2.508-2.645-1.934c-0.773,0.245-1.596,0.496-2.484-0.049c0.9-1.896,3.16-2.485,4.169-4.257"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,662.2c-2.354,1.432-4.995,2.492-6.48,5.04"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M126.12,448.36c-0.77,1.557-2.196,1.342-3.596,1.479c-2.243,0.218-4.952-0.569-6.057,2.582c-0.252,0.72-1.096,1.342-2.107,1.219"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M305.88,422.44c2.857,4.521,5.081,9.378,7.397,14.181c0.347,0.717,0.705,1.345,1.242,1.898"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M483.72,619c-2.319,0.08-4.64,0.16-6.96,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M161.64,722.68c0.32-0.08,0.741-0.058,0.942-0.257c0.952-0.942,2.148-1.74,3.345-1.121c1.814,0.939,3.111,2.428,3.181,4.737
				c0.065,2.159-0.909,4.359,0.211,6.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M46.2,717.88c3.356,0.092,4.233,2.777,5.4,5.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M326.04,621.16c-2.619-0.156-5.309-1.002-7.712,0.92c-0.232,0.185-0.898,0.095-1.427-0.161c-1.547-0.75-3.182-1.319-4.781-1.959"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M260.04,556.12c-2.162,2.246-3.348,5.072-4.241,7.944c-0.504,1.624-1.231,2.693-2.719,3.336"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M238.44,617.8c-2.091,1.585-4.532,1.483-6.96,1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M432.84,633.16c-2.229-0.974-4.512-1.566-6.96-0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M352.68,580.84c-1.6-0.96-3.2-1.92-4.8-2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M344.52,579.16c-2.226,0.622-4.446,1.257-6.479,2.399"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M406.44,568.12c-0.883,0.231-2.271-2.274-2.773,0.697c-0.183,1.083-2.113,0.299-3.227,0.503"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M213,606.76c0.96,3.521,1.859,7.059,2.9,10.554c0.734,2.465,1.835,4.778,3.58,6.727"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M580.2,625.96c-0.885,2.357-1.195,5.003-3.36,6.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M77.4,581.32c1.597,2.048,2.306,4.433,2.64,6.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M462.12,192.28c2.028,1.689,4.611,2.144,6.96,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M280.2,713.8c-4.305-1.771-8.776-2.697-13.44-2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M559.8,547c-0.474,1.174-0.144,2.401-0.24,3.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M229.56,205c0.96,0.88,1.834,1.885,2.897,2.615c2.355,1.616,4.037,1.325,6.703-0.935"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M235.08,586.36c-2.62,1.166-4.424,3.219-6,5.52"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M505.08,397.24c-0.763-0.459-1.378-1.162-2.428-0.273c-1.291,1.093-2.979,0.729-4.532,0.753"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M24.6,750.52c0.629,2.442,1.549,4.729,0.823,7.468c-0.687,2.589,0.317,5.42-0.287,8.146c-0.086,0.39,0.584,0.947,0.904,1.427"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M184.92,216.76c1.244,2.814,2.907,4.882,6.488,4.378c1.108-0.156,1.766-0.829,2.632-1.258"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M29.16,399.88c2.191,1.104,4.72,0.572,6.96,1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M360.12,617.44c2.167,1.781,3.126,4.407,3.711,6.87c0.729,3.072,1.713,5.271,5.169,5.489"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M79.32,556.84c1.911,3.115,1.664,4.446-1.2,6.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M47.16,616.12c-1.108,0.729-1.957-0.086-2.881-0.478c-1.33-0.563-2.482-1.634-4.079-1.442"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M125.16,513.88c0.24,0.561,0.324,1.319,0.745,1.648c3.423,2.683,4.061,6.856,5.735,10.472"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M369.24,737.32c-2.432,0.585-4.743,1.487-6.96,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M114.36,572.44c1.28-0.641,2.56-1.28,3.84-1.921"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M118.44,570.28c1.049-0.192,1.712,0.354,2.153,1.203c1.053,2.026,1.864,4.117,1.447,6.477"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M547.56,578.2c3.21,0.121,6.463-0.354,9.601,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M364.44,349.48c-1.164,3.311,0.14,6.564,0.281,9.838c0.212,4.924,1.511,9.672,2.795,14.414c0.236,0.873,0.806,1.539,1.003,2.388"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M390.6,564.52c-1.547-0.58-2.404-1.874-3.398-3.088c-0.67-0.819-1.272-2.209-2.841-1.472"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M544.2,631c-2.32,0.08-4.641,0.16-6.96,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M403.32,183.88c0.221,0.875,0.868,1.151,1.663,1.48c1.841,0.759,3.062,2.055,2.656,4.28"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M239.64,405.16c-0.398,4.638,2.767,9.112,6.96,9.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M228.6,202.36c0.475,1.76,0.635,3.433,0.084,5.305c-0.536,1.822-1.354,2.693-3.205,2.615"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M549.48,438.52c0.387,1.996-0.079,3.724-1.686,5.034c-1.462,1.193-1.048,3.142-1.915,4.566"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M290.28,724.12c-2.24-1.28-4.479-2.56-6.72-3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M514.2,262.36c8.307,1.261,16.342,3.46,24.005,6.949c0.199,0.091,0.475,0.011,0.715,0.011"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M400.68,622.36c-0.574,2.209-0.973,4.471-2.611,6.265c-0.481,0.527-1.062,1.239-0.628,2.135"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M506.28,768.52c-4.08,1.28-8.164,2.547-12.237,3.85c-1.06,0.339-2.083,0.791-3.123,1.19"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M460.68,533.8c-0.64-0.613-1.603-0.299-1.868,0.04c-1.965,2.509-5.115,3.475-7.236,5.734c-0.274,0.293-0.335,0.786-0.495,1.186"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M80.76,592.36c-2.344-0.005-4.616,0.325-6.72,1.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M111.72,594.04c-3.067-1.012-4.446,1.096-6.021,3.104c-4.283,5.454-7.372,11.452-8.884,18.251c-0.258,1.16-0.854,2.246-1.294,3.365
				"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M109.08,732.04c-1.472-1.196-2.15-0.966-3.127,0.956c-0.996,1.96-1.739,4.115-3.833,5.284"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M507.24,530.44c2.452,0.444,2.395,2.763,2.745,4.35c0.306,1.385,0.788,2.493,1.574,3.57"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M206.52,563.56c-3.487,2.654-5.497,6.434-7.678,10.082c-0.403,0.673-0.532,1.701-1.68,1.427c-1.387-0.33-1.042-1.521-0.933-2.385
				c0.475-3.759,0.672-7.512,0.21-11.283"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M115.56,555.88c1.035,3.259-0.886,6.202-1.033,9.356c-0.12,2.557-1.165,5.066-0.407,7.684"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M54.6,562.84c-2.25-0.202-4.451,0.974-6.72,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M127.08,611.8c1.718,0.194,1.912,1.646,2.408,2.877c0.545,1.353,1.321,2.645,2.878,2.824c0.849,0.1,1.834-0.297,2.154-1.381
				c0.237-0.801,0.396-1.638,0.734-2.394c1.37-3.065,2.729-3.312,5.025-0.966c1.338,1.368,2.945,2.369,4.561,3.359"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M592.44,577c-1.173,1.386-0.911,3.259-1.616,4.829c-0.313,0.699-0.636,1.266-1.271,1.365c-0.667,0.104-0.855-0.728-1.17-1.17
				c-1.128-1.583-1.304-3.388-0.982-5.265"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M106.92,484.6c-1.245,4.567,0.296,9.368-1.178,13.928c-0.341,1.056-0.643,2.082-1.462,2.873"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M469.8,565.48c-2.91,2.744-3.236,6.781-5.026,10.087c-0.102,0.186-0.067,0.668-0.006,0.685c2.284,0.633,1.16,2.508,1.449,3.864
				c0.039,0.186,0.304,0.324,0.503,0.523c2.225-2.011,4.3-4.241,6.732-5.967c1.719-1.219,1.951-2.598,1.889-4.394
				c-0.058-1.677-0.605-3.359-0.021-5.039"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M199.8,614.92c2.37,0.605,4.055,2.631,6.48,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M23.88,447.64c1.41,2.594,3.873,4.301,5.653,6.618c1.323,1.723,4.322,1.139,6.347,2.262"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M398.76,609.88c-2.248,0.165-4.583-0.486-6.72,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M334.44,615.16c-1.295,0.463-2.562,1.009-3.592,1.93c-2.242,2.007-2.805,1.952-4.34-0.482c-0.364-0.579-0.216-1.536-1.188-1.688"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M450.6,429.64c-1.101-0.972-1.768-2.221-2.147-3.603c-0.688-2.496-2.452-3.186-4.812-3.139c-1.526,0.03-3.103,0.297-4.56-0.458"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M90.6,483.16c1.051,3.178,2.634,4.476,5.754,4.24c0.814-0.062,1.345-0.003,1.665,0.571c1.408,2.528,3.438,4.912,2.409,8.145
				c-0.51,1.601-0.01,2.968,0.733,4.324"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M162.36,577.96c-1.36,0.24-2.72,0.665-4.08,0.668c-1.354,0.003-1.492,0.8-1.68,1.732"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M186.6,44.68c0.4-1.12,0.623-2.349,1.231-3.341c2.385-3.889,7.525-3.39,11.249-0.5"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M262.2,203.08c1.686,4.279,0.634,6.94-1.922,9.117c-2.067,1.76-5.36,1.794-7.945,0.539c-1.698-0.824-1.805-2.221-2.133-3.656"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M163.08,455.8c-1.408,1.186-2.966,2.196-4.805,2.338c-0.958,0.074-1.759,0.673-2.076,1.084c-1.593,2.062-3.419,1.57-5.36,0.898"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M340.44,448.36c-0.681,2.01-0.256,3.039,2.162,2.929c1.774-0.081,1.739,1.153,1.484,2.6c-0.302,1.714-1.882,2.674-2.207,4.312"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M343.8,615.88c-2.302,0.627-4.568,1.352-6.72,2.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M387,695.56c-3.424-1.773-6.854-3.525-10.799-3.852c-0.75-0.062-1.189-0.59-1.717-1.155c-3.431-3.685-6.425-7.799-10.524-10.833"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M276.84,283.72c-0.4-0.081-0.99-0.022-1.167-0.265c-0.72-0.987-1.32-0.224-1.951,0.03c-0.529,0.212-0.127,0.442-0.018,0.72
				c0.312,0.792,0.685,1.331,1.698,1.22c0.79-0.086,1.638-0.274,2.397,0.214"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M67.56,535c-0.96,0.72-2.005,1.353-2.858,2.182c-1.061,1.031-0.712,1.733,0.692,2.16c1.647,0.5,4.315,0.071,4.614,1.929
				c0.291,1.812-1.751,3.416-2.94,5c-0.23,0.308-0.627,0.489-0.947,0.729"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M102.84,709c3.195,2.002,6.249,3.819,6.63,8.388c0.252,3.029-0.358,6.482,2.01,9.132"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M553.8,580.6c-1.917,0.142-3.833-0.027-5.768,0.163c-1.707,0.168-2.005,0.823-1.672,2.237"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M287.88,566.92c-0.7,1.282-1.494,2.443-1.529,4.078c-0.025,1.18-0.408,2.502-2.303,2.717c-2.286,0.261-2.676,2.709-3.848,4.245"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M172.44,767.32c-1.539-0.729-2.901,0.251-4.309,0.536c-0.862,0.175-1.611,0.183-2.411-0.057"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M25.08,416.68c-1.162,1.075-2.768,0.66-4.073,1.218c-0.818,0.35-1.911,0.176-2.407,1.183"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M302.04,747.64c1.099,2.671,1.284,5.665,2.88,8.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M17.64,442.12c1.736,1.72,4.138,2.663,5.528,4.794c0.74,1.135,1.766,0.009,2.632,0.246"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M91.8,732.76c1.789,1.156,3.245,2.688,4.519,4.353c0.503,0.656,0.842,0.767,1.481,0.447"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M36.6,572.44c1.859,2.627,0.905,5.802,1.68,8.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M534.84,289.24c-4.405-0.062-8.637-1.301-12.96-1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M449.16,83.56c4.056,0.569,7.666,1.954,10.078,5.521c0.298,0.44,0.709,0.552,1.202,0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M515.4,503.8c-0.057,1.971-0.059,3.88,2.392,4.59c0.562,0.163,0.376,0.906,0.508,1.405c0.547,2.072,0.726,4.14,0.237,6.249
				c-0.049,0.21,0.143,0.476,0.223,0.716"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M313.8,554.92c0.786,1.329-0.554,1.949-0.962,2.879c-0.444,1.013-0.889,1.704,0.686,2.478c3.21,1.576,3.123,1.754,0.276,4.243"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M471.72,198.52c-0.791,1.273-0.328,4.52,0.721,5.04c0.448,0.223,0.935-0.229,1.212-0.956c0.44-1.156-0.228-2.101-0.492-3.125"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M292.2,571.24c-2.307,0.987-3.831,3.539-6.72,3.359"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M351.24,554.92c-0.674,0.424-1.272,1.024-2.16,0.959c-1.144-0.086-1.822,0.504-2.417,1.431c-1.464,2.279-2.953,2.181-4.543-0.229"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M476.28,613.24c2.911,0.396,4.57,2.739,6.72,4.319"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M232.68,763.24c0.793,0.895,0.866,2.205,0.848,3.117c-0.052,2.664,1.508,4.556,2.512,6.723"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M411.48,541.48c-2.16,2.791-2.063,3.147,1.68,6.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M451.32,570.28c-1.077-2.425-1.425-2.576-3.092-0.695c-1.996,2.25-5.108,3.258-6.404,6.398c-0.515,1.247-2.256-0.327-3.464-0.424"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M248.76,538.96c1.468,0.052,1.143,1.149,1.204,2.04c0.11,1.599-0.278,3.222,0.236,4.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M495.48,639.16c-2.333,1.384-4.641,2.804-6.721,4.56"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M160.68,728.92c-2.449-0.811-4.244-2.841-6.72-3.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M265.8,71.8c1.916,1.497,3.93,2.538,3.995,5.757c0.079,3.893-2.7,4.119-4.955,5.283"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M307.56,421.48c-3.561,0.914-7.409,0.366-10.815,2.13c-0.283,0.146-0.785-0.13-1.185-0.21"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M16.44,686.68c2.16,1.28,4.32,2.561,6.48,3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M479.88,394.6c-2.563,0.4-4.534,1.724-6,3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M305.4,756.76c-0.313,3.31-1.745,6.123-3.84,8.641"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M379.32,505.96c2.789,2.156,4.631,5.325,7.683,7.195c1.196,0.731,0.899,2.111,1.438,3.125"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M109.08,674.44c-1.305-0.906-2.24-1.122-3.174,0.691c-1.501,2.91-2.132,6.032-2.776,9.158c-0.178,0.86-0.33,1.589-1.009,2.15"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M239.64,734.92c-2.751-1.545-3.034-1.367-4.111,1.429c-0.998,2.592-0.663,5.566-2.369,7.932"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M554.28,575.32c-2.16,0.08-4.32,0.159-6.48,0.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M324.36,574.12c-1.92,0.56-3.936,0.913-5.73,1.745c-1.452,0.673-1.787,0.185-2.216-1.016c-0.443-1.242-1.094-2.409-1.654-3.609"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M237.48,580.6c-2.661,0.602-4.443,2.471-6.24,4.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M58.44,507.64c-3.434,3.043-7.472,4.997-11.766,6.464c-1.025,0.35-2.145,0.77-3.114-0.224"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M405.24,189.4c0.495-2.383-1.651-2.364-2.899-3.086c-0.609-0.352-1.421-0.354-2.141-0.514"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M462.6,742.6c-2.178,1.121-4.492,2.02-6.239,3.841"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M495.24,358.6c0.454,4.553,0.541,9.172,0.022,13.655c-0.206,1.776-0.438,4.074-1.703,5.785"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M490.92,379.48c-1.131,0.077-2.308-1.68-3.301-0.2c-0.658,0.982-0.624,2.277,0.384,3.355c0.317,0.338,0.197,1.085,0.277,1.645"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M366.12,593.56c2.873,0.289,5.809-0.557,8.64,0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M20.28,486.04c-0.162,2.521,1.792,4.146,2.64,6.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M52.92,431.32c2.158,1.403,3.269,3.764,5.043,5.516c0.678,0.67,1.224,1.586,2.396,1.444"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M39.72,691c-0.296,1.934-1.611,3.436-2.26,5.244c-0.445,1.241-0.59,2.394-0.621,3.636"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M333,762.28c-1.982,2.113-2.227,4.799-2.072,7.435c0.069,1.197-0.134,2.253-0.328,3.365"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M461.88,457.24c-1.12-0.88-2.233-1.77-3.367-2.631c-0.19-0.146-0.663-0.135-0.685-0.255c-0.726-3.934-3.143-1.812-5.068-1.435"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M318.12,609.88c0.096,3.77-0.5,7.583,0.72,11.28"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M509.4,319c-2.16-0.88-4.32-1.76-6.48-2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M312.84,453.64c0.327,0.446,0.976,0.372,1.174,0.208c3.096-2.549,6.712-1.632,9.827-0.785c4.652,1.266,9.108,1.573,13.723,0.351
				c0.374-0.1,0.797-0.013,1.196-0.013"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M120.12,469c0.16,0.24,0.368,0.461,0.473,0.723c1.266,3.175,1.262,3.176,4.327,1.678"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M217.32,460.6c-0.203,1.272,0.849,2.065,1.263,3.096c0.516,1.285,0.587,2.322-0.303,3.385"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M64.44,703.96c2.583,2.187,2.826,2.745,1.14,5.239c-0.783,1.158-1.278,2.612-2.58,3.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M448.92,315.4c1.794,4.43,4.365,8.298,8.16,11.28"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M413.16,180.04c-0.391,1.831,1.206,2.146,2.148,2.048c1.09-0.113,2.091,0.22,3.132,0.112"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M170.52,771.88c-2.384-0.302-4.156,1.751-6.48,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M501.96,634.36c-2.16,0.239-4.32,0.479-6.479,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M512.52,757.72c-3.558,1.109-6.866,2.671-9.6,5.28"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M230.76,187.96c-0.427-1.246-1.229-2.406-0.495-3.848c1.011-1.985,2.476-2.726,4.298-1.611c1.166,0.713,1.956,0.639,3.183,0.262
				c1.381-0.424,2.853-0.259,3.636,1.571c0.444,1.038,0.834,2.069,1.139,3.146"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M591.24,678.76c-2.571,1.641-5.572,2.533-7.92,4.561"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M503.64,565.72c0.646,1.23,1.051,2.489,2.305,3.48c0.78,0.616,0.865,2.508-1.104,2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M357.96,501.4c1.04,0.079,2.097,0.142,3.122,0.178c4.106,0.144,4.166,0.054,4.292,3.663c0.053,1.537,0.775,2.221,2.186,2.398"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M43.08,569.08c-2.182-0.382-4.215-1.433-6.48-1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M457.8,635.56c-2.138-0.673-4.302-0.654-6.479-0.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M107.4,462.76c0.256,0.288,0.525,0.443,0.957,0.506c2.081,0.297,3.653,1.212,3.844,3.574"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M499.32,379c1.268-0.44,2.443-1.171,3.84-1.2c0.953-0.02,1.83,0.19,2.14,1.206c0.242,0.794-0.46,1.156-0.869,1.725
				c-0.831,1.155-2.719,1.142-2.95,2.83"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M459.48,391.48c-1.492,0.359-2.842,1.056-4.065,1.941c-0.819,0.593-1.553,0.76-2.415,0.219"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M158.52,567.88c0.32-0.16,0.312-0.472,0.001-0.455c-2.163,0.113-4.321,0.295-6.481,0.455"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M489.48,232.6c-1.923-2.376-4.206-1.51-6.48-0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M375.96,751.48c0.877,1.642,0.729,3.425,0.159,5.011c-0.372,1.034-0.003,1.789,0.081,2.669"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M299.4,616.6c-2.48,0.162-4.935,1.592-7.468,0.045c-0.383-0.233-0.991,0.628-1.652,0.436"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M248.76,539.32c-2.03,3.323-2.377,7.207-3.592,10.802c-0.104,0.31-0.099,0.64-0.203,0.972c-0.361,1.153-1.152,2.695-0.208,3.496
				c1.142,0.967,2.947,0.742,4.484,0.781c1.089,0.028,0.96-0.905,0.96-1.651c-0.002-2.479-0.001-4.96-0.001-7.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M108.6,596.68c-0.469,1.281-1.017,2.387-2.068,3.451c-1.337,1.354-1.547,3.528-2.012,5.429"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M299.4,567.64c-2.379-0.286-4.473,0.53-6.48,1.681"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M405,767.32c-2.257,1.616-3.596,4.077-5.52,6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M493.32,279.4c0.742,2.317-0.667,4.341-1.085,6.455c-0.554,2.804-0.957,5.672-1.814,8.419c-0.188,0.602-0.621,1.126-0.94,1.686"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M32.52,407.8c2.562,1.303,5.741,1.154,7.92,3.36c0.159,0.16,0.319,0.32,0.479,0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M38.52,592.36c-2.777-0.807-5.339,0.22-7.911,0.99c-0.723,0.217-1.281,1.048-2.169,0.449"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M335.4,765.4c-1.299,0.868-1.75,2.312-2.377,3.611c-0.4,0.827-0.541,1.795-1.703,1.908"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M429.72,566.2c-1.814,1.795-1.603,4.299-2.072,6.499c-0.388,1.81-0.907,3.539-1.527,5.261"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M346.92,441.88c0.283,2.173-1.081,4.093-0.96,6.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M280.68,615.16c-3.365,0.403-5.344,3.094-7.92,4.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M321.24,437.56c-1.364,0.696-2.918,0.435-4.334,0.596c-2.387,0.271-4.06,1.372-4.938,3.285c-0.675,1.473-1.659,2.089-2.975,2.325
				c-1.813,0.327-3.178,1.51-4.794,2.194"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M359.16,617.32c-0.16,0.56-0.375,1.11-0.471,1.682c-0.351,2.107-1.571,2.59-3.613,2.461c-2.396-0.15-4.896-0.588-7.196,0.657"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M357.48,598.84c4.699,1.001,8.838,3.124,12.479,6.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M274.68,758.2c1.168,3.176,1.319,6.543,1.6,9.847c0.143,1.687,0.814,3.313,0.56,5.033"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M383.16,554.68c3.083,1.973,6.8,2.652,9.706,5.157c1.148,0.989,3.336,1.551,5.167,1.276c1.951-0.292,3.847-0.081,5.767,0.047"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M167.88,505.72c0.669-0.535,1.444-0.211,2.161-0.222c2.909-0.047,2.909-0.022,2.16,2.622"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M354.6,661.72c-2.72,0-5.439,0-8.159,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M346.44,621.88c0.537,4.801-0.425,9.28-2.881,13.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M15.96,594.52c-0.082,2.298,0.223,4.54,0.96,6.721"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M522.84,694.6c2.42,1.482,4.134,3.701,6,5.761"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M410.28,563.32c-3.38,1.582-3.299,5.063-4.29,7.931c-0.363,1.052-0.377,2.225-0.547,3.316c1.021,0.238,2.019,0.619,2.917-0.207"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M381.48,599.8c-1.313,0.393-2.626,0.743-3.852,1.421c-2.03,1.122-4.038,2.125-6.5,0.798c-1.239-0.669-2.797-0.66-4.049,0.422"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M201.48,601.48c-2.134-0.541-4.242-0.747-6.24,0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M554.76,600.52c-3.12,0.081-6.24,0.133-9.359,0.249c-2.285,0.086-4.59,0.19-6.24,2.151"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M101.4,602.44c-1.734-0.697-3.065,0.534-4.557,0.97c-1.783,0.521-3.145,0.201-3.843-1.69"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M423.24,599.08c-3.308,1.708-6.455,3.582-7.681,7.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M448.92,451c-0.059,2.12,0.28,4.302-0.96,6.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M549,439.24c-2.333,0.649-3.888,2.745-6.24,3.359"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M47.88,548.92c-2.312-0.522-4.189,0.834-6.24,1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M51.24,423.88c-2.925,1.881-6.754,0.473-9.6,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M261.24,572.2c-2.539,0.658-4.552,2.12-6.24,4.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M98.28,609.16c-1.588-0.195-3.058,0.364-4.999,0.548c2.12,1.912,1.125,4.014,1.084,5.93c-0.022,1.016,0.094,1.74,0.795,2.402"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M483.48,564.52c2.058-0.743,2.892,0.179,2.87,2.16c-0.013,1.134,0.26,2.322-0.471,3.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M417.96,711.4c-2.05,1.176-4.33,1.924-6.24,3.359"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M262.44,617.32c5.06,1.385,10.08,2.867,14.893,5.011c2.487,1.108,4.574,3.097,7.427,3.389"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M462.6,567.4c2.171-0.411,3.014,0.463,2.661,2.643c-0.118,0.731-0.158,1.539,0.459,2.157"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M189.96,582.04c-2.08,1.44-4.16,2.88-6.24,4.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M378.84,543.16c1.049,0.065,1.816-0.382,2.581,1.36c1.252,2.854,3.736,5.146,5.544,7.783c0.814,1.188,1.729,2.606,0.275,4.057"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M66.12,609.88c-3.138-0.148-6.167-1.282-9.362-0.983c-0.949,0.089-1.888,0.026-2.638,0.743"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M462.36,570.76c0.487,1.903,0.156,3.841,0.228,5.761c0.124,3.29,0.093,3.291,3.372,3.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M209.4,621.64c-1.212,2.28-3.871,2.81-5.52,4.561"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M116.04,683.56c-1.834-1.18-3.764-2.097-6-2.159"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M65.16,697.24c-2.197-0.143-3.941,1.331-6,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M135.72,463.24c2.845-1.407,5.211,0.252,7.385,1.532c1.717,1.011,3.454,2.088,5.095,3.268"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M25.32,471.16c-2.903-0.222-1.697,2.068-1.565,3.105c0.162,1.259,0.533,2.751,1.805,3.614"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M107.64,599.56c0.176,4.333-0.354,8.642-0.425,12.961c-0.029,1.776-0.453,3.792,1.145,5.279"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M17.16,528.76c0.24,0.16,0.515,0.285,0.714,0.485c2.188,2.191,2.145,2.361-0.714,3.117c-0.244,0.064-0.48,0.157-0.721,0.237"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M542.76,456.52c-2.002,0.802-4.011,1.574-6.24,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M450.84,499.96c-1.231,2.386-3.33,1.179-4.759,0.715c-1.632-0.53-1.102-2.26-0.521-3.595"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M255,763.48c2.8,2.96,5.619,5.902,8.381,8.897c0.433,0.47,0.659,1.103,1.459,0.942"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M272.28,760.6c-1.933-0.799-3.89-1.513-6-1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M26.76,439.48c2.193,2.43,5.242,3.577,7.92,5.279"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M39.48,594.52c1.437,1.856,0.4,4.01,0.72,6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M163.56,646.36c-2.235,1.434-3.414,3.792-5.04,5.76"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M495.24,576.52c-1.482,1.341-3.268,0.327-4.799,0.32c-2.068-0.01-4.022,0.212-6.001,0.641"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M531.24,631.96c-0.319,2.215-1.183,4.065-3.36,5.04"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M493.32,548.44c-0.044,1.163,1.191,1.027,1.717,1.648c0.545,0.644,0.932,1.187,0.432,1.943c-0.507,0.768-1.225,0.543-1.908,0.247
				c-1.072-0.466-2.147-0.911-3.36-0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M384.84,660.04c-2.682-0.334-5.067,0.948-7.386,1.824c-1.815,0.687,0.47,1.765,0.666,2.735"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M294.6,727c2.079,1.136,4.144,2.29,5.76,4.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M144.6,559c1.005,0.49,2.058,0.855,3.1,1.252c1.582,0.601,1.526,1.548,0.02,2.348"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M516.6,642.52c2.538,1.419,5.474,1.55,8.194,2.271c6.205,1.645,11.331,4.497,14.864,9.958c0.264,0.408,0.782,0.651,1.182,0.972"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M464.76,551.8c0.374,1.005,1.322,1.522,2.168,1.609c1.146,0.118,1.436,0.682,1.162,1.501c-0.822,2.459,0.931,2.405,2.43,2.649"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M509.64,282.52c1.773,1.255,4.135,1.33,5.761,2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M129.48,686.68c-2.116,0.678-4.302,1.254-5.28,3.601"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M219.72,602.68c-2.173,0.488-4.004,1.752-6,2.641"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M559.56,73.24c-2.449,0.597-3.702,2.657-5.279,4.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M388.56,634.12c1.057-3.122,0.631-6.408,1.055-9.604c0.435-3.265,0.651-6.559,1.014-9.833c0.029-0.264,0.452-0.483,0.692-0.724"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M215.16,574.84c-0.765-0.042-1.557-0.245-2.163,0.479c-1.018,1.216-2.4,1.305-3.837,1.201"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M455.64,207.16c-0.872,1.905-1.927,3.774-1.199,6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M575.88,732.28c-1.892,0.942-3.964,0.98-6,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M583.32,619.72c-2.161,1.228-2.363,3.392-2.641,5.521"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M462.12,342.52c0,0.56,0,1.12,0,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M184.44,574.12c-2.36-1.4-4.109,0.252-6,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M128.76,576.04c-2.007,1.09-2.472,2.987-2.64,5.04"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M88.68,605.32c-2.19,0.004-4.311,0.115-6.274,1.388c-0.8,0.52-1.793-0.209-2.603-0.674c-0.935-0.537-1.862-0.889-2.884-0.234"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M444.36,448.84c-1.563,1.551-3.041,3.219-5.28,3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M200.76,220.6c1.085,0.38,0.619,1.453,0.927,2.174c1.174,2.747,2.023,3.136,4.713,1.906"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M144.12,606.52c-1.913,0.25-3.888-0.974-5.76,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M82.68,607c0,0.399,0.079,0.82-0.014,1.196c-0.374,1.511,0.948,3.386-0.948,4.561c-2.25,1.396-4.524,2.665-7.207,0.978
				c-0.172-0.108-0.471-0.015-0.711-0.015"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M270.84,607.24c-1.92-1.107-3.84-0.249-5.76,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M559.08,554.08c2.061,0.29,1.85,2.235,2.307,3.514c0.302,0.842,0.279,1.935,0.601,2.836c0.771,2.162-0.809,1.539-1.701,1.409
				c-0.907-0.133-1.767-0.599-2.646-0.919"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M93,206.68c0.33,0.64,0.33,1.28,0,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M75.72,578.44c-2.052-0.239-2.181-2.674-3.84-3.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M68.76,575.8c-1.634,0.686-2.213,2.284-3.12,3.601"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M176.52,215.32c-1.766,0.744-3.371,2.251-5.535,0.984c-0.602-0.352-1.057,0.263-1.421,0.7c-1.295,1.554-2.867,1.886-4.286,0.438
				c-1.466-1.496-0.609-3.226,0.232-4.744c0.282-0.509,0.929-0.817,1.409-1.217"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M484.92,574.6c-1.902,0.484-3.762,1.174-5.76,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,538.84c0,3.201-0.009,3.219-3.116,1.671c-1.117-0.557-2.096-0.7-2.884,0.489"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M471.72,610.36c0.979,0.842,2.387,0.701,3.393,1.646c1.482,1.394,1.106,3.226,1.328,4.845c0.212,1.546,0.325,3.21-0.16,4.789"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M318.36,613.96c2.276,0.435,3.889,1.698,4.8,3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M291.72,607.24c-0.687,1.242-0.815,2.537-0.225,3.833c0.309,0.679,0.378,1.287-0.015,1.927"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M376.2,607.48c-1.92,1.119-3.84,2.239-5.76,3.359"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M209.88,608.68c-1.323-0.332-1.772,0.993-2.667,1.376c-1.175,0.502-1.684,1.943-3.093,1.984"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M170.04,608.68c3.625-0.289,7.064,0.781,10.56,1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M108.6,733c-0.581,3.121-3.128,5.518-3.181,8.879c-0.016,1.056-0.981,1.934-1.859,2.641"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M86.28,547.96c0.86,2.269,1.561,4.576,3.815,6.039c1.455,0.944,1.739,2.796,2.379,4.301c0.46,1.081-0.778,1.458-1.376,1.335
				c-0.663-0.137-1.304-0.025-1.938-0.154"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M516.12,575.08c-1.222,1.821-3.261,3.2-3.12,5.76"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M550.44,72.52c-1.435-1.609-3.119-1.896-5.041-0.96c-2.04,0.993-3.905,2.233-3.746,4.795c0.038,0.6-0.361,1.125-0.094,1.686"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M522.12,394.12c1.725,1.336,3.955,0.249,5.76,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M560.28,72.28c-1.628-3.076-3.229-4.05-5.521-3.36c-2.146,0.646-4.058,3.275-3.84,5.28"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M313.32,577.48c1.721,1.664,2.495,3.999,4.08,5.76"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M79.56,595c-1.917-0.142-3.858,0.285-5.76-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M165.48,501.88c-1.281,0.049-0.494-0.823-0.444-1.195c0.107-0.803,1.208-1.565,0.41-2.371c-0.811-0.818-1.575,0.241-2.366,0.446"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M15.96,695.32c-0.387,1.92-0.387,3.84,0,5.76"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M62.76,631c-2.108,1.299-3.08,3.629-4.8,5.28"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M287.16,619.48c1.082,0.882,2.111,1.773,2.945,2.953c0.476,0.674,1.726,1.201,2.533,0.802c0.653-0.324,0.667-1.479,0.507-2.312
				c-0.213-1.115-0.619-2.198-0.464-3.363"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M310.68,660.04c7.281,2.292,12.606,7.198,17.259,12.977c0.479,0.594,0.661,1.424,0.981,2.144"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M511.08,388.12c1.202,2.183,0.014,3.09-1.92,3.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M404.28,561.64c1.686,1.209,3.822-0.18,5.52,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M411.24,565.24c-0.043,3.315,0.483,6.58,0.999,9.834c0.184,1.156,1.301,1.848,2.121,2.646"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M28.44,763.48c-2.148,3.017-2.79,6.536-3.36,10.079"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M397.56,623.08c2.562-1.463,5.227-2.094,7.888-0.428c0.701,0.439,1.297,0.331,1.953,0.428"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M239.64,614.2c0.121,1.923,0.053,4.18,0.698,5.665c0.904,2.083,0.493,4.597,2.182,6.335"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M370.8,628.12c-2.214,1.415-1.212,3.839-1.756,5.772c-0.246,0.876-0.122,2.008-1.248,2.082c-0.743,0.049-0.92-0.898-1.256-1.586
				c-0.803-1.643-0.92-3.324-0.9-5.068"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M469.56,402.52c-0.319-0.479-0.843-0.932-0.914-1.446c-0.119-0.859-0.339-1.837,0.213-2.62c0.593-0.841,1.476-0.73,2.385-0.504
				c3.155,0.788,5.997,2.223,8.637,4.091"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M97.32,101.56c-0.48,1.92-0.96,3.84-1.44,5.76"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M348.6,739.72c1.553,1.592,3.425,2.66,5.521,3.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M359.88,632.44c2.071-0.327,3.743,1.265,5.76,1.199"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M548.76,556.12c-1.763,1.997-0.905,4.509-1.056,6.729c-0.11,1.628,1.767,2.246,3.456,2.15"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M357.72,721.72c-4.438,0.883-8.745,2.206-12.963,3.833c-0.139,0.054-0.362,0.068-0.465-0.008c-0.129-0.096-0.172-0.305-0.252-0.465
				"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M359.4,514.84c1.918,1.878,2.31,5.86,0.704,7.908c-0.624,0.795-1.429,1.447-2.144,2.173c-1.125,1.144-2.095,2.178-2.301,4.063
				c-0.182,1.654-1.222,3.46-3.22,4.096"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M473.16,585.88c0.839,0.281,1.195,1.045,1.741,1.604c-0.781,0.977-1.58,0.338-2.207,0.046c-2.223-1.034-4.514-1.666-6.975-1.652
				c-0.751,0.004-1.486,0.083-2.172-0.463c-1.68-1.34-3.658-1.652-5.748-1.454"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M88.68,602.2c-1.92,0.399-3.84,0.8-5.76,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M489.96,229.72c0.981,0.347,1.769,1.166,2.086,1.95c0.637,1.576,1.606,1.235,2.714,0.93"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M75,683.56c-1.971-0.165-3.872,0.226-5.76,0.721"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M531.72,246.52c-2.737,1.604-5.617,1.923-8.64,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M549.24,556.12c1.479,1.455,1.428,3.474,1.9,5.285c0.487,1.865,0.33,3.921,1.699,5.515"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M286.2,578.92c0.074,1.631-1.208,2.462-2.19,3.325c-1.977,1.734-1.303,2.664,0.75,3.395"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M530.52,455.08c1.354-0.201,2.206,0.778,3.09,1.479c0.76,0.603,1.45,1.054,2.431,0.922"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M208.92,578.92c-1.821,0.5-3.682,0.124-5.52,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M401.88,576.04c-1.84,0.561-3.68,1.12-5.52,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M405.48,435.16c3.199,0.96,6.394,1.942,9.607,2.853c0.424,0.12,0.952-0.133,1.432-0.213"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M267.48,625.48c-1.853-0.344-3.464-1.794-5.52-1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M30.36,459.64c-0.556,3.094,1.583,4.632,3.6,6.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M420.84,433.24c-1.393,1.647-0.623,3.492-0.479,5.279"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M547.56,429.16c-2.159,1.68-4.31,3.373-6.505,5.007c-0.129,0.096-0.614-0.287-0.935-0.447"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,626.68c0,4.32,0,8.641,0,12.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M311.88,434.68c-3.271,1.686-6.951,2.196-10.332,3.572c-0.15,0.061-0.468-0.292-0.708-0.452"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M452.28,571.24c-0.512,3.385-1.646,6.53-3.601,9.359"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M443.64,598.36c2.297,0.954,4.474,2.042,6.558,3.488c1.507,1.045,3.764,0.255,5.683,0.352"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M529.32,213.16c-0.96,1.84-1.92,3.68-2.88,5.52"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M149.64,567.16c-1.12,1.2-2.24,2.399-3.36,3.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M180.84,629.56c5.424-0.34,10.688,0.502,15.84,2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M230.28,620.68c-1.787,1.205-1.463,3.516-2.64,5.04"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M228.12,628.36c2.16-0.4,4.32-0.801,6.48-1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M586.44,342.76c-0.553,0.617,0.214,1.69-0.941,2.203c-1.302,0.577-0.957,1.945-0.979,3.077"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M219.72,655c-1.729,1.312-3.287,2.819-4.526,4.584c-0.67,0.954-0.994-0.028-1.474-0.024"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M214.2,409c0.48,0.399,0.902,1.023,1.449,1.164c3.981,1.027,3.287,4.883,4.443,7.522c0.583,1.332-1.421,1.921-2.276,1.776
				c-2.135-0.36-3.708,0.183-5.083,1.754c-0.15,0.171-0.721,0.104-0.694-0.457"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M129.72,616.84c-1.84,0.595-3.68,1.032-5.52,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M36.6,490.36c1.71,0.996,3.758,0.44,5.52,1.199"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M530.04,593.32c-3.298-0.668-6.59-1.071-9.84,0.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M170.52,605.32c-1.862-0.436-3.704-0.393-5.52,0.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M442.44,191.8c-1.474,1.109-4.157,0.447-4.561,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M179.64,615.88c1.221,1.585,1.871,3.597,3.6,4.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M34.2,605.8c1.79,0.605,3.664,0.731,5.52,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M579,617.56c-2.859,0.87-5.454-0.631-8.139-1.086c-1.049-0.179-2.108-0.146-3.142-0.354"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M79.32,527.8c1.424,1.775,3.567,2.281,5.52,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M373.8,640.84c-5.583,2.089-10.799,4.86-15.591,8.412c-0.308,0.229-0.489,0.628-0.729,0.948"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M312.12,616.6c-1.289,0.271-2.061-1.146-3.364-0.989c-0.868,0.104-1.793-0.146-2.396,0.75"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M34.2,537.4c-0.298-1.27-1.311-0.944-2.16-0.957c-0.96-0.015-1.92-0.003-2.88-0.003"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M269.88,199c2,2.653,1.959,3.516-0.24,5.04"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M52.92,632.2c5.896,3.99,10.23,9.258,12.967,15.837c0.108,0.262,0.313,0.482,0.473,0.723"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M476.04,265.48c1.489,1.824,3.844,0.331,5.52,1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M144.36,581.8c3.006,1.281,6.195,2.217,8.64,4.561"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M38.52,410.44c-1.739,3.264-1.301,6.823-1.481,10.317c-0.091,1.761,0.769,3.687-0.679,5.282"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M512.52,385c-1.686-1.936-3.671-0.417-5.52-0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M324.6,623.56c-1.867-0.559-3.663,0.54-5.52,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M54.6,571.24c-2.24-0.24-4.48-0.48-6.72-0.721"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M419.88,379.48c-2.08,1.44-4.16,2.88-6.24,4.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M90.12,599.32c-1.776-0.849-3.266,0.859-5.056,0.578c-0.571-0.091-1.43,0.011-2.145,0.142"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M342.84,459.4c-0.018,2.312-0.006,4.644-3.12,5.04c-1.742,3.637-5.914,3.777-8.64,6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M511.56,596.44c-1.73,0.838-3.466,1.685-4.789,3.13c-0.62,0.677-1.244,0.521-1.931,0.229"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M125.4,751.48c1.615,1.117,2.253,2.265,0.769,4.118c-1.069,1.334-1.268,3.309-2.929,4.281"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M227.4,623.56c-1.316-0.874-2.56-2.08-4.32-0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M155.64,720.28c1.962,0.408,3.307,2.393,5.52,2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M32.04,471.64c0.48,0.561,0.968,1.113,1.438,1.683c0.368,0.446,1.147,0.718,0.938,1.431c-0.282,0.959-1.176,0.699-1.896,0.728"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M391.8,613.48c0.187-3.271-1.995-2.88-4.08-2.641"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M347.4,587.32c-1.84-1.2-3.681-2.4-5.521-3.601"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M488.16,562.12c0.29,3.768,0.676,7.546-1.32,11.04"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M149.16,601c2.512,0.14,2.98,2.834,4.8,3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M364.92,692.44c-1.76,0.88-3.52,1.76-5.28,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M64.68,83.56c-1.957,0.806-3.813-0.367-5.762-0.285c-4.659,0.196-9.434-0.518-13.678,2.205"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M348.36,754.84c-1.563,1.18-3.583,0.922-5.28,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M33.24,276.52c0.917,0.442,1.333,1.183,1.77,2.118c0.364,0.779,0.349,2.23,1.83,2.202"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M358.2,620.92c1.477,4.109,2.294,8.244,0.479,12.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M464.64,627.4c-0.667,3.47,1.136,6.649,1.216,10.076c0.036,1.586-1.515,2.979-0.375,4.563"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M148.44,534.28c-1.493-1.547-3.382-0.322-5.04-0.721"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M243,729.4c-1.447,1.519-3.029,2.958-3.12,5.279"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M446.52,635.68c-0.239,0.2-0.512,0.372-0.715,0.604c-2.848,3.247-2.681,3.083-5.134-0.177c-1.269-1.685-1.975-3.806-3.751-5.107"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M434.4,631.72c-1.324,1.045-1.652,2.661-2.255,4.091c-0.898,2.129-1.892,4.163-3.626,5.749"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M359.16,571.24c1.409,1.598,1.217,3.607,1.458,5.518c0.11,0.879-0.464,1.375-1.003,1.629c-0.572,0.27-0.857-0.414-1.201-0.888
				c-0.883-1.217-1.59-2.796-3.574-2.419"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M259.08,547.48c-2.222-2.531-5.195-1.715-7.918-1.614c-1.453,0.054-2.882,0.264-4.322-0.066"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M453.72,639.88c1.354-1.411,3.387-1.919,4.587-3.581c0.783-1.082,1.236-0.323,1.941,0.415c1.406,1.475,2.233,3.755,4.752,3.886"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M94.68,442.84c0,1.84,0,3.681,0,5.521"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M506.76,298.36c5.578,3.333,11.425,5.915,17.999,6.498c0.252,0.022,0.481,0.303,0.722,0.462"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M179.64,616.6c-1.864,1.219-2.182,3.829-4.355,4.758c-2.044-2.475-1.601-4.425,1.715-4.758"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M425.16,524.92c0.56,0.4,1.116,1.135,1.681,1.14c2.05,0.021,2.483,1.417,2.879,2.94"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M47.16,607.72c0.663,1.698-0.385,3.707,0.96,5.28"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M545.16,343.48c-1.928,0.913-2.19,2.965-3.12,4.56"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M507.96,635.56c-1.678,0.773-3.436,1.104-5.28,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M407.4,575.08c1.408,1.52,3.083,1.261,4.8,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M553.08,571.72c-1.76,0.16-3.52,0.32-5.28,0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M143.88,456.04c1.618,1.774,4.693,0.736,6.009,3.115c0.19,0.344,1.046,0.345,0.679,0.945c-0.274,0.447-0.771,0.409-1.404,0.427
				c-1.658,0.048-2.846-1.902-4.564-0.888"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M392.76,596.2c-1.412-1.633-3.016-3.012-5.04-3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M419.4,484.84c-1.903,0.996-3.49,2.138-3.895,4.551c-0.347,2.067-0.005,2.951,2.217,3.084c1.226,0.072,2.155,0.276,2.638,1.485"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M168.12,577.72c0.4,0.48,1.048,0.916,1.144,1.45c0.158,0.887,0.033,1.979,1.142,2.099c1.153,0.126,1.144-1.133,1.56-1.866
				c1.426-2.512,1.668-5.522,3.354-7.922"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M367.56,394.84c0.534,2.604,1.429,5.032,3.36,6.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M18.12,495.16c-0.999,2.355,1.203,3.548,1.92,5.28"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M220.68,671.32c-1.253-1.888-3.262-2.842-5.04-4.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M37.8,579.16c-1.628-0.485-3.155,1.057-4.8,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M33.36,577.48c1.75-1.326,0.799-3.941,2.52-5.28"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M192.36,621.88c2.142-0.433,3.529,1.617,5.52,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M171.96,428.92c0.942,1.818,1.715,3.736,3.12,5.28"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M482.04,621.88c-1.84-0.773-3.681,0.565-5.521,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M219,624.52c-1.056,1.72-3.088,2.01-4.56,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M152.52,589.24c1.698,1.022,2.035,3.404,4.08,4.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M532.68,460.12c-1.034,1.695-0.378,3.905-1.68,5.52"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M369,744.04c-2.109-0.136-3.478,1.544-5.28,2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M541.08,573.4c-1.916,1.734-1.861,4.35-2.794,6.517c-0.65,1.512-0.537,4.009-2.048,4.327c-1.624,0.344-1.892-2.195-2.826-3.413
				c-1.341-1.747-1.361-4.228-3.132-5.751"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M35.88,402.28c-2.08,0.56-4.16,1.119-6.24,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M505.32,578.2c1.552,0.236,2.767,0.815,3.914,2.093c1.403,1.562,1.401,2.627-0.074,3.907"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M263.88,678.52c2.717,0.772,4.072,3.315,6.24,4.801"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M211.56,594.52c-0.252,2.636,0.489,5.3-0.24,7.921"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M157.8,562.6c-1.899-0.785-3.575,0.073-5.28,0.721"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M391.8,562.48c1.566-0.563,1.558,0.573,1.679,1.56c0.139,1.124-0.353,2.336,0.481,3.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M26.76,569.08c-0.187,2.743,2.312,3.718,3.895,4.089c1.437,0.338,2.26,1.405,3.546,1.671"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M505.56,380.92c1.396,0.657,0.296,2.197,1.131,3.179c0.423,0.497-0.487,1.12-1.134,1.373c-0.504,0.198-1.035,0.568-1.448-0.227
				c-0.547-1.055-1.869-0.54-2.628-1.206"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M138.84,577.96c-1.924-0.81-3.084,0.287-4.071,1.687c-0.942,1.337-3.089,1.472-3.787-0.035c-0.842-1.818-2.279-3.499-1.982-5.731"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M301.56,583.72c1.928,1.455,4.173,1.688,6.48,1.681"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M356.04,560.2c0.08,0.399,0.282,0.82,0.22,1.196c-0.376,2.279,0.235,4.198,1.94,5.764"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M48.12,425.56c0.983,2.052,3.161,3.18,4.08,5.28"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M561.48,625.24c-1.761,0.64-3.521,1.28-5.28,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M522.84,470.2c1.65,0.922,3.576,0.331,5.28,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M318.84,679c-1.406,1.531-3.805,1.572-5.04,3.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M240.6,544.6c1.68,0.721,3.36,1.44,5.04,2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M462.12,542.44c1.21,0.962,1.21,0.962,3.465-0.802c1.388,1.811-0.131,2.999-0.79,4.417c-0.692,1.49-2.037,3.017-0.515,4.784"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M329.16,540.04c-0.735-0.453-1.067-0.179-1.73,0.425c-2.593,2.359-4.016,5.416-5.433,8.473c-0.568,1.227-1.416,2.386-1.478,3.822"
				/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M108.36,461.08c-1.6,1.2-3.2,2.4-4.8,3.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M98.52,461.8c0,1.127-0.766,1.941-1.225,2.868c-0.465,0.939-0.229,1.579,0.505,2.172"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M273.96,678.04c-2.029,0.628-3.38,2.161-4.8,3.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M102.36,547.72c1.353,1.433,1.909,3.282,2.64,5.04"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M73.56,677.8c-2.16,0.32-4.32,0.64-6.48,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M530.04,573.4c2.661,0.297,4.845,1.489,6.462,3.613c0.521,0.687,0.782,1.577,1.698,1.906"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.24,636.28c-2.351,0.007-2.523-2.941-4.561-3.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M492.6,630.76c-0.834,0.286-1.394,0.959-1.647,1.691c-0.8,2.303-2.424,2.819-4.592,2.389"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M378.12,514.6c-0.32,0.24-0.897,0.459-0.919,0.724c-0.175,2.09-1.065,1.83-2.186,0.701c-0.359-0.362-0.535-0.907-0.947-1.639
				c-1.777,2.32-0.392,3.652,0.232,5.005c0.79,1.713,1.804,3.346,0.699,5.289"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M155.64,685c-1.595,0.863-3.572,0.725-5.04,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M357.24,663.16c1.68,0.96,3.359,1.92,5.04,2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M529.32,453.4c1.284,2.101,1.086,4.837,2.88,6.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M466.2,208.84c0.648,1.618,2.133,3.076,0.96,5.04"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M15.72,508.12c0.493,2.183,2.472,2.681,4.08,3.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M256.68,560.68c-1.965-1.174-3.43,0.272-5.04,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M516.12,246.76c-1.2,0.655-2.441-0.363-3.591,0.028c-1.896,0.646-3.797,1.398-4.089,3.812"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M234.12,560.2c-2.118,1.563-1.053,4.019-1.68,6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M596.28,654.52c-3.36,2.801-6.77,5.544-10.054,8.431c-1.163,1.021-1.75-0.599-2.667-0.271"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M556.68,563.56c-1.786-0.677-3.011-2.426-5.04-2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M158.52,570.76c0.861,1.132,1.208,2.609,2.852,3.2c0.837,0.3,1.391,1.385,1.249,2.562c-0.132,1.099,0.411,1.915,1.256,2.372
				c1.808,0.218,1.251-3.716,3.764-1.895"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M249.96,552.28c-1.68,0-3.36,0-5.04,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,532.6c-0.336-1.228,1.113-3.553-1.921-3.119"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M446.04,635.56c-1.768-0.762-3.414-0.252-5.04,0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M464.28,551.8c-0.916,0.776-2.007,1.278-2.036,2.878c-0.013,0.672-0.456,2.071-1.804,2.162"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M79.8,571.96c-1.405,1.208-0.729,3.185-1.68,4.56"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M586.2,663.64c2.322,2.17,5.008,3.9,7.242,6.199c1.321,1.358,1.981,3.331,3.89,4.183c0.242,0.108,0.108,1.059,0.148,1.618"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M429.24,490.6c-0.768,0.11-1.175,0.725-1.681,1.2c2.243,1.937,2.664,3.412,1.671,6.237c-0.604,1.713-0.01,2.703,1.69,3.12
				c1.356,0.333,2.72,0.643,4.079,0.963"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M476.76,368.92c0.962,0.827,0.431,1.922,0.454,2.88c0.103,4.101,0.562,8.167,0.979,12.241c0.061,0.599-0.092,1.239,0.487,1.679"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M189.96,616.6c0,0.561,0,1.12,0,1.681"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M191.16,620.92c1.424,1.162,1.567,3.199,3.099,4.349c1.942,1.457,2.508,1.319,3.381-1.229"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M527.88,289c-1.717,5.19-3.431,10.381-6.24,15.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M542.76,572.2c2.023,1.358,1.483,3.616,1.9,5.524c0.295,1.349,0.165,2.851,1.7,3.596"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M542.52,584.92c-1.97,1.964-4.611,3.17-6.239,5.521"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M578.28,380.2c-0.4,0.593-0.864,0.138-1.19-0.021c-2.49-1.214-4.034-0.514-4.551,2.185c-0.128,0.668-0.243,1.234-0.979,1.436"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M283.8,713.32c-1.173-1.644-2.395-0.846-3.074,0.268c-2.211,3.625-3.902,7.495-4.606,11.732"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M294.36,407.56c1.821,1.108,2.742,3.021,4.08,4.561"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M372.36,708.76c1.593-1.07,3.372-0.168,5.041-0.473c0.869-0.157,1.796,0.241,2.639-0.247"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M129,596.44c-3.838,0.488-7.269,1.952-10.32,4.319"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M317.4,608.2c-3.759,1.78-4.011,5.569-5.012,8.889c-0.518,1.716-0.627,3.57-2.188,4.791"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M129,609.4c-0.704,0.07-1.563-0.276-1.92,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M124.68,614.68c-0.372,1.776-1.386,3.076-2.86,4.109c-0.373,0.261-0.854,0.818-0.26,1.411"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M415.56,612.52c-0.319,0.32-0.88,0.61-0.923,0.965c-0.567,4.569-4.098,3.074-6.757,3.355"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M499.8,582.28c0.625-0.764-0.023-0.71-0.479-0.715c-1.52-0.015-3.04-0.006-4.561-0.006"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M478.68,582.76c-1.28,1.44-2.56,2.88-3.84,4.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M581.4,350.92c0.922,0.974,0.669,2.189,0.733,3.359c0.106,1.897,1.551,2.76,3.106,1.921"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M250.92,600.76c2.266-0.13,4.375,0.322,6.24,1.681"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M321,720.76c-0.583,1.631-0.842,3.309-0.72,5.04"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M575.16,576.76c-0.96,0.16-2.052,0.085-2.853,0.53c-1.607,0.894-2.514,0.17-3.388-1.01"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M331.8,575.08c1.681,0.317,3.427,0.293,5.04,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M399,616.36c0.604-0.658,1.871-1.543-0.242-1.64c-1.835-0.086-3.737-0.405-5.518,0.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M323.4,765.16c-1.61,1.358-2.301,3.589-4.32,4.56"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M453.96,711.16c1.871,1.235,3.144,3.099,4.8,4.56"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M501.48,613.72c-1.318,1.375-2.89,2.562-3.36,4.561"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M430.2,529.48c0.941,2.013,3.212,0.872,4.56,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M416.76,569.56c-1.6-0.319-3.2-0.64-4.8-0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M238.44,616.36c-1.797-0.665-3.295,0.164-4.8,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M396.12,321.4c-1.95-0.688-2.91,1.924-4.8,1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M433.08,571.24c-1.578,0.513-3.202,0.116-4.8,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M506.76,240.28c0.705,3.496-0.205,6.716-1.68,9.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M450.36,431.32c-1.599,0.252-3.283-0.075-4.801,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M175.32,571.12c1.331-0.86,1.821-0.049,2.161,1.08c0.612,2.035,0.896,4.122,0.954,6.24c0.028,1.062,0.199,2.277,1.228,2.56
				c0.968,0.265,1.771-0.865,2.074-1.625c0.668-1.681,1.932-2.952,2.704-4.535"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M185.52,570.28c0.174-1.049,0.272-2.094-0.12-3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M278.28,584.44c-3.168-0.785-6.396-0.413-9.6-0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M119.88,611.08c1.011,1.312,0.734,2.853,0.697,4.319c-0.032,1.255,0.156,2.386,0.983,3.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M395.88,568.72c-2.599-2.434-5.113,0.484-7.68,0.12c-0.595,2.098-2.88,2.771-3.84,4.561"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M505.32,572.44c-0.334,1.586-2.639,2.876-0.721,4.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M156.12,574.6c1.631,0.546,3.266,1.152,4.8-0.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M553.32,630.28c-2.169-0.066-3.422,1.315-4.561,2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M351.72,766.12c-1.748-0.191-3.11,1.158-4.8,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M225.72,616.84c-3.082-0.912-6.229-0.551-9.36-0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M41.4,420.76c-0.539,1.562-1.031,3.129-0.48,4.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M255.96,614.68c3.219,1.324,2.893,4.886,4.529,7.222c1.745,2.493,1.774,6.005,4.591,7.898"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M459.48,427.72c1.816,0.008,3.613,0.124,5.279,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M484.2,628.36c-1.587,0.39-3.042,1.431-4.8,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M547.08,770.92c1.404,1.236,3.179,1.798,4.8,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M22.92,740.2c0.241,2.217-1.641,3.265-2.64,4.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M419.88,637.48c1.596,1.364,3.19,2.73,4.793,4.101c-1.852,2.755-3.825,5.463-5.033,8.619"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M280.68,602.92c-1.444-1.023-3.094-1.223-4.8-1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M128.28,769.24c-1.411,1.101-2.013,2.65-2.4,4.319"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M274.92,422.2c2.073,0.036,4.126-0.129,6.046,1.13c0.678,0.444,1.804,1.068,2.834,0.31"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M558.12,633.88c-1.819-0.067-2.931,1.892-4.8,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M555,302.2c1.501,0.897,3.375-0.689,4.8,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M93.96,487.72c-1.856,0.739-2.795,2.018-2.4,4.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M173.88,721.48c-1.342,1.49-2.37,3.355-4.56,3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M24.12,593.8c-0.72,2.32-1.358,4.671-2.2,6.945c-0.348,0.942-1.11,1.744-2.121,2.175"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M514.2,66.52c-2.139-2.514-4.772-1.979-7.439-1.196c-0.655,0.193-1.125,0.891-1.921,0.716"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M483.48,557.32c1.395,1.178,2.09,3.255,4.319,3.359"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M190.2,705.88c-0.842-1.408-0.848-3.86-3.6-2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M255.48,623.08c-0.547,1.004-0.23,2.09-0.202,3.119c0.033,1.212-0.17,2.199-1.238,2.881"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M518.76,634.36c-1.28,0.08-2.56,0.159-3.84,0.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M339.24,534.52c-1.689,1.06-2.664,2.57-2.88,4.561"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M250.2,621.4c-1.579,0.513-3.202,0.116-4.8,0.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M242.28,753.4c1.735,0.579,1.234,2.948,2.88,3.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M472.92,576.04c0.561,0.96,1.293,1.862,1.636,2.895c0.409,1.237,1.211,1.426,2.199,1.165c2.281-0.601,2.453,0.772,2.405,2.42"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M441.48,437.08c1.017,1.536,1.269,3.595,3.119,4.56"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M471.48,675.88c3.277,2.897,7.136,1.441,10.8,1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M466.68,374.68c0.945,2.722,0.789,5.604,1.156,8.405c0.635,4.833,2.093,9.52,2.444,14.395"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M91.56,215.08c-1.44-0.4-2.88-0.8-4.32-1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M254.76,615.64c-1.6,0.24-3.2,0.48-4.8,0.721"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M485.4,617.8c1.746,0.387,3.41,2.577,5.275,0.236c0.038-0.049,0.004-0.156,0.004-0.236"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M378.84,560.68c1.855,0.86,3.336,2.22,4.8,3.601"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M232.44,744.76c-1.339,1.418-2.112,3.168-2.88,4.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M187.08,675.64c-1.568-0.72-3.206-0.05-4.8-0.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M74.28,716.68c-1.947-0.381-3.073-2.132-4.8-2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M144.36,603.64c0.045,3.136-0.097,6.274,0.681,9.37c0.206,0.818,0.204,1.78-0.201,2.63"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M227.4,573.16c1.934,0.44,2.739,2.004,3.312,3.617c0.301,0.85,0.623,1.245,1.489,1.183"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M15.96,573.64c1.08-0.005,1.842,1.518,3.354,0.124c0.977-0.9,2.715,0.511,3.806,1.367c0.936,0.734-0.05,1.587-0.434,2.353
				c-0.574,1.146-1.319,2.106-2.526,2.637"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M35.16,601.48c-1.63-1.198-3.732-1.531-5.28-2.881"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M19.32,706.84c-1.648,1.066-2.647,2.674-3.6,4.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M554.04,608.44c1.28,0.96,2.627,1.844,3.825,2.896c2.027,1.781,3.873,3.74,5.055,6.223"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M217.8,568.36c-2.994-1.938-6.066-3.606-9.84-2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M448.92,630.28c-1.87,0.612-3.753,1.521-5.52-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M556.44,565c-2.764,1.42-5.19,3.707-8.641,3.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M195.48,660.04c-1.84-0.08-3.68-0.16-5.52-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M83.64,556.84c0.383,1.68,1.333,3.091,2.16,4.561"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M563.88,568.6c-2.254,0.312-4.207-1.258-6.484-1c-0.871,0.099-0.673-1.184-0.637-1.386c0.697-3.843,0.179-7.916,2.081-11.534"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M474.6,596.92c1.296,0.656,2.68,1.172,3.604,2.396c0.568,0.753,1.391,0.525,2.156,0.483"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M249.96,555.4c0.48,2.88,0.96,5.76,1.44,8.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M266.28,570.76c1.521,0.942,3.04,0.101,4.56,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M444.36,518.92c0.072,0.721-0.534,1.47,0.229,2.171c1.587,1.456,2.195,3.043,0.757,5.049c-0.907,1.265,0.506,1.397,1.158,1.919
				c0.523,0.418,2.111-0.194,1.455,1.422"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M580.44,679c1.588,0.741,2.994,1.721,4.079,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M223.56,706.12c2.228,0.172,2.771,2.028,3.6,3.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M131.4,466.12c-1.44,1.2-2.88,2.399-4.32,3.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M538.68,580.84c0.24,0.16,0.463,0.36,0.723,0.474c1.602,0.698,1.95,3.569,3.549,3.172c1.478-0.368,3.138-0.981,4.593-1.756
				c0.352-0.188,0.647-0.161,0.976-0.21"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M446.52,557.08c-1.359-0.709-2.694-1.159-4.099-0.023c-0.321,0.26-0.756-0.026-0.94-0.457"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M150.84,566.92c1.326,1.028,1.324,2.317,1,3.849c-0.389,1.837-0.87,3.771,0.44,5.512"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M15.96,721.48c1.52,0.319,3.04,0.64,4.56,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M564.6,241c-1.348,1.345-0.971,2.962-0.72,4.56"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M420.36,567.88c1.72,2.184,1.38,5.027,2.38,7.448c0.37,0.898,0.363,1.96,1.22,2.632"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M92.52,559.48c1.893,1.746,1.218,4.54,2.64,6.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M586.44,619c-0.171-1.131,0.23-1.734,1.437-1.43c1.154,0.291,2.468,0.16,3.363,1.189"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M283.08,619.48c1.283-0.031,2.472-1.576,3.748-0.17c-1.146,2.34-1.51,4.979-1.8,7.612c-0.145,1.315-0.206,2.572-1.468,3.357"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M327,618.28c-2.801,0.028-5.608-0.14-8.4,0.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M266.76,631.72c1.44,1.28,2.88,2.561,4.32,3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M563.4,413.56c2.542,0.005,4.379,2.282,6.96,2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M160.44,567.64c1.362,1.225,0.798,3.159,1.687,4.556c0.023,0.036,0.17,0.033,0.229-0.002c0.094-0.056,0.164-0.153,0.244-0.233"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M490.44,618.76c1.52,0.873,3.039,0.662,4.56,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M80.52,719.08c-1.743,0.25-2.924,1.685-4.56,2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M464.28,632.2c-1.521-0.034-3.04,0.56-4.561,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M449.64,595c-1.721,0.896-3.127,2.127-4.08,3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M513.72,514.36c-0.945,1.058-0.36,3.12-0.167,3.224c1.602,0.858,1.084,2.602,1.848,3.736"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M244.44,554.92c-1.574,2.496-1.616,5.482-2.566,8.186c-0.447,1.271-0.812,2.116-2.233,2.135"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M251.64,623.08c2.32-0.395,4.65-0.551,6.954,0.025c0.681,0.17,1.287,0.748,1.926-0.025"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M421.56,572.68c-1.652-0.103-2.776,1.8-4.56,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M23.4,455.08c-2.691,1.155-5.099-0.702-7.68-0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M88.44,599.56c0.745,4.131,1.341,8.271,0.72,12.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M491.88,555.16c0.475,1.157,0.538,2.371,0.48,3.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M373.56,603.16c-2.716,1.646-5.497,3.192-7.92,5.28"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M279.96,566.2c0.08,2.352-1.939,3.852-2.425,5.994c-0.33,1.455-0.343,3.169-2.135,3.846"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M567.72,623.8c0.649,0.724,1.465,0.073,2.169,0.133c1.908,0.162,2.634,0.92,2.392,2.747"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M500.28,578.92c-1.521-0.08-3.04-0.16-4.561-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M254.52,606.52c-2.659,0.173-4.911,1.485-7.189,2.661c-2.026,1.046-0.124,2.724-0.491,4.06"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M412.44,625.96c2.545,1.163,5.1,2.3,7.92,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M457.56,575.56c1.521,0.16,3.04,0.32,4.561,0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M102.84,612.04c-1.52-0.24-3.04-0.48-4.56-0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M145.56,688.84c-1.623,0.605-3.176,1.341-4.56,2.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M53.16,768.76c-1.792-0.381-3.08,0.836-4.56,1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M245.4,745.72c-1.4,0.855-3.111,0.527-4.56,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M508.2,439c-0.906,1.635-1.509,3.17,1.2,3.6c0.763,0.121,0.67,0.858,0.96,1.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M554.52,323.08c1.2,0.64,2.4,1.28,3.601,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M80.52,542.2c-1.632,0.672-3.381,1.168-4.32,2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M121.32,745.24c-1.222,1.298-1.123,3.291-2.4,4.56"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M268.68,624.28c-0.364,2.085-1.403,3.846-2.93,5.224c-0.428,0.387-0.341,0.661-0.43,1.016"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M48.84,685.48c-1.839-0.118-3.157,1.002-4.56,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M313.08,615.64c1.521,0.561,3.04,1.12,4.56,1.681"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M422.76,576.28c-1.26,0.219-2.771,0.33-3.528,1.259c-1.025,1.257-1.892,0.857-2.951,0.421"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M466.44,615.64c2.101-0.296,3.547,1.557,5.52,1.681"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M93.48,610.12c-1.586,2.395-3.63,3.087-6.24,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M353.16,573.16c-1.84,1.28-3.681,2.56-5.521,3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M175.2,693.16c-1.585-0.301-2.968-1.394-4.68-1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M56.28,697.48c1.933,0.24,1.787,0.301,2.443,1.901c0.606,1.479,0.839,3.177,2.117,4.338"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M282.84,572.2c-1.718,1.015-3.023-0.587-4.56-0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M470.76,624.04c-1.855-0.157-2.939,1.519-4.56,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M299.4,459.88c2.21,1.058,0.864,2.472,0.194,3.572c-0.759,1.242-1.874,2.268-2.834,3.388"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M144.6,569.92c2.109,1.039,4.019,2.547,6.48,2.76"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M537.96,641.8c-1.561,2.194-2.231,4.805-3.36,7.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M140.76,482.32c2.033-1.574,4.008-0.286,6,0.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M320.52,502.12c-1.294,0.916-3.108,0.273-4.319,1.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M223.8,758.44c-1.487-0.262-2.911,0.04-4.32,0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M47.16,744.52c-2.047,1.777-2.022,4.576-3.309,6.75c-0.862,1.456-1.363,3.124-2.691,4.29"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M567.96,631c1.085,0.38,0.587,1.462,0.916,2.18c0.328,0.719,0.312,1.869,1.241,1.834c1.238-0.048,0.957-1.243,0.958-2.094
				c0.003-1.521,0.085-2.981,1.925-3.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M275.88,630.04c-1.255,1.815-2.292,1.172-3.373-0.23c-1.107-1.436-1.878-3.179-3.587-4.09"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M392.28,614.2c1.089,1.684,1.429,3.628,1.92,5.52"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M548.04,602.92c-1.576-0.091-2.991,0.398-4.32,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M262.44,295.72c-1.45,1.051-0.076,2.933-1.2,4.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M369.48,754.84c-1.726,0.414-2.588,2.239-4.32,2.641"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M23.88,282.28c1.28,0.32,2.56,0.64,3.84,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M223.32,623.56c1.01,1.304,1.353,3.229,3.36,3.601"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M330.6,573.4c-1.438,0.106-2.838,0.903-4.319,0.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M51.72,565.72c-0.947-0.141-1.321,0.52-1.83,1.337c1.887,1.363,4.075,1.189,6.122,1.664c1.526,0.354,2.734,0.81,3.988-0.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M255,619.48c-1.438,0.102-2.902-0.239-4.32,0.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M354.6,691.96c-2.868-1.042-6.058-0.948-8.159,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M417.24,400.36c-1.263,1.23,0.302,3.089-0.96,4.319"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M475.56,218.2c-1.42-0.424-2.817-1.283-4.319-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M155.88,732.04c0.24-0.16,0.533-0.502,0.711-0.45c1.121,0.322,3.132,5.134,2.364,5.927c-0.962,0.993-1.105,2.64-2.596,3.163"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M445.8,423.64c-0.882,1.489-1.709,3.024-3.359,3.841"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M569.88,252.04c-0.64,1.76-1.28,3.52-1.92,5.28"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M398.28,565c1.603,1.863,1.335,4.413,2.385,6.487c0.505,0.999,0.54,2.26,1.695,2.873"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M458.52,385.72c-1.493,0.208-2.439,2.385-4.319,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M440.76,562.12c-1.153-0.261-2.144,0.284-2.838,1.001c-0.759,0.782-1.375,1.87-0.985,3.073c0.569,1.757,1.641-0.406,2.384,0.246"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M266.52,285.88c1.218,0.905,2.107,1.884,1.482,3.616c-0.204,0.565-0.339,1.328,0.415,1.933c0.984,0.789,0.608,1.479-0.458,1.892"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M17.88,769.24c-1.247,1.233-0.35,2.944-0.96,4.319"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M114.6,562.84c-1.44-0.24-2.88-0.479-4.32-0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M592.2,630.76c-0.16-0.08-0.312-0.187-0.481-0.235c-1.119-0.323-1.825-2.38-3.34-0.944c-1.372,1.299,0.309,2.38,0.489,3.576
				c0.054,0.348,0.452,0.644,0.691,0.964"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M557.4,252.28c0.445,1.372,1.994,2.354,1.439,4.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M398.16,564.04c-1.007,1.418-2.718,2.277-3.24,4.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M408.12,620.2c1.836-0.346,3.938,0.842,5.52-0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M398.28,90.76c-3.849,1.014-3.818,5.276-0.24,7.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M27.72,279.88c-1.262,0.375-2.502,1.081-3.84,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M319.32,559c-1.922,2.173-1.145,4.959-1.625,7.451c-0.298,1.542-0.116,3.191-0.035,4.788c0.059,1.129-0.398,1.718-1.46,1.921"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M142.92,566.2c-3.44,2.24-6.88,4.479-10.32,6.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M560.52,583.24c2.133,1.098,4.451,1.796,6.492,3.102c0.84,0.537,1.728,0.962,2.148,1.938"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M405.96,375.64c-0.473-1.271-1.347-1.424-2.125-0.452c-0.843,1.052-2.026,1.654-2.915,2.612"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M387.96,582.76c-1.728,2.017-2.669,4.512-4.08,6.721"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M484.92,364.12c1.186,2.452,0.364,5.159,0.972,7.677c0.578,2.396-0.194,5.106,1.668,7.203"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M280.2,567.88c2.219,1.077,1.896,4.002,3.84,5.28"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M101.88,571.96c-1.281,0.974-2.527,2.071-4.32,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M479.4,222.52c-0.254,1.458,1.011,2.833,0.239,4.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M306.84,617.08c1.316,1.14,0.728,3.126,1.92,4.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M156.84,596.92c0,0.32-0.036,0.646,0.006,0.959c0.248,1.845,0.09,3.21-2.413,2.948c-0.778-0.081-1.816,0.287-2.393,1.133"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M424.2,582.76c-1.859,1.152-4.193,1.399-5.812,3.07c-0.167,0.172-1.06-0.057-1.677-0.317c-1.099-0.463-2.178-1.407-3.552-1.072"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M535.08,602.44c3.36,0.72,6.729,1.399,10.074,2.183c1.075,0.252,2.086,0.777,3.126,1.177"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M481.32,624.76c-1.44-0.73-2.88,0.465-4.32,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M431.88,635.8c-1.04,0-2.092-0.102-3.117,0.021c-2.571,0.306-1.194,2.32-1.37,3.59c-0.059,0.416,0.728,0.95,1.127,1.43"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M594.36,617.08c-1.243,1.239-3.109,1.814-3.841,3.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M22.92,476.92c0.48,1.521,0.869,3.077,1.468,4.549c0.498,1.221-0.128,1.243-0.988,1.211"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.36,297.16c0.039,1.84,0.08,3.68,0.12,5.52"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M582.6,580.36c-1.717-2.047-1.717-2.047-3.359-0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M138.12,475.96c0.772,1.665,2.772,1.819,3.84,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M99.24,460.6c-0.279,1.724-1.796,0.787-2.674,1.105c-0.825,0.299-1.787,0.35-2.606,0.814"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M228.84,750.28c-1.52,2.72-3.052,5.433-4.553,8.163c-0.531,0.967-1.161,1.948-0.487,3.116"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M214.92,614.2c-0.16,0-0.359-0.063-0.474,0.01c-1.184,0.748-2.706,1.988-3.757,1.295c-1.182-0.78-0.772-2.798-0.496-4.17
				c0.372-1.842-0.521-3.966,1.126-5.535"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M515.64,389.8c-1.266,1.179-0.672,2.686-0.72,4.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M106.92,613c-1.319,0.552-2.467-1.639-3.84-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M443.16,512.92c1.726,0.737,0.783,2.809,1.92,3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M111.72,236.2c-1.28,0.32-2.56,0.64-3.84,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M15.72,493.72c2.334,0.535,4.184,2.429,6.72,2.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M28.68,594.28c-0.015,1.555,0.494,2.965,1.2,4.319"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M398.52,618.04c-1.052-2.567-2.85-0.658-4.319-0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M39.72,495.16c0.263,1.562,0.751,2.933,2.64,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M40.92,223.96c-0.962,0.885-0.584,2.998-2.64,2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M172.68,539.8c4.676-0.184,6.708,2.863,4.8,7.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M402.84,510.52c0.431,1.573-1.235,2.019-1.828,3.329c2.469-0.255,1.458,2.554,3.028,3.151"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M305.88,614.2c-1.417,0.326-2.671-0.731-4.08-0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M15.72,713.08c1.348,0.259,2.644,0.966,4.08,0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M122.76,254.2c-0.58-0.089-1.019-0.672-1.68-0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M259.8,769.48c-0.932,1.222-1.321,2.7-1.92,4.079"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M120.12,561.4c-1.28,1.04-2.56,2.079-3.84,3.119"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M381.48,552.52c0.768,0.808,1.027,1.714,1.341,2.841c1.303,4.669,1.795,9.685,4.898,13.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M82.2,601.48c-1.56,0.278-2.566-1.324-4.08-1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M444.12,631c-1.84,0.399-3.681,0.8-5.521,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M542.28,635.8c-1.357-0.121-2.68-0.854-4.08-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M80.28,221.56c-1.2,0.08-2.4,0.16-3.6,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M258.84,610.12c-2.509-0.807-4.969-0.12-7.44,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M127.08,599.32c-1.375,0.607-3.03,0.62-4.08,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M485.4,633.88c-1.708,0.693-2.538,2.263-3.601,3.601"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M578.52,334.6c0.4,1.36,0.801,2.72,1.2,4.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M336.84,626.92c2.588,2.29,3.685,5.8,6.48,7.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M209.88,611.8c-1.841,0.137-2.688,1.81-4.08,2.641"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M88.44,722.68c-1.76,1.521-3.577,2.98-5.258,4.583c-0.998,0.95-2.07,1.312-3.382,1.178"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M529.32,630.04c0.965,0.863,1.59,2.005,3.437,1.119c0.79-0.38,2.154,1.677,3.763,1.281"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M505.8,631.24c0.5,2.622,2.783,4.188,3.84,6.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M361.08,501.88c0.363,1.467-0.574,2.703-0.72,4.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M32.52,429.88c-1.84,0.4-3.68,0.8-5.52,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M439.08,450.52c-1.412-0.806-2.689,0.688-4.08,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M340.2,710.44c1.437,1.058,2.66,2.315,3.6,3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M405.72,578.92c1.338,0.469,2.722,0.14,4.08,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M164.76,567.64c-0.849,1.241-0.633,2.921-1.68,4.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M461.4,401.56c-1.652,0.02-2.466,1.821-4.08,1.921"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M36.36,565.72c0,2.08,0,4.16,0,6.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M150.36,577c-1.218-0.941-2.702-1.317-4.08-1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M550.68,454.6c-1.834-0.518-2.733,1.931-4.56,1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M457.56,454.6c-1.031,1.195-2.313,2.258-2.159,4.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M450.12,404.68c1.534,3.592,2.749,7.295,3.84,11.04"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M359.88,625.48c1.42-0.345,2.626,1.118,4.08,0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M563.4,455.08c-0.025,0.612,0.271,1.088,0.725,1.434c1.888,1.435,2.165,3.215,1.195,5.286"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M158.04,568.6c-1.189,1.479-1.837,3.19-2.16,5.04"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M151.8,577.48c-1.072-0.465-1.639,0.922-2.64,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M82.92,452.92c-0.08,0.16-0.273,0.383-0.224,0.471c2,3.6-1.093,3.861-3.136,4.81"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M17.64,431.56c0.461,1.498,2.384,2.221,2.16,4.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M366.36,564.76c-0.913-0.113-1.124,0.368-1.227,1.197c-0.198,1.611-1.94,2.334-2.374,3.843"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M526.44,463.72c-0.263,3.242-2.271,5.28-4.801,6.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M238.44,622.84c-1.786-0.477-2.616,1.373-4.08,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M46.92,206.2c-1.398-0.394-2.582,0.361-3.84,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M264.48,286.12c-2.061,1.562-0.86,4.092-1.74,6.027c-0.158,0.347,0.145,0.882-0.264,1.439c-1.399,1.91,0.063,1.899,1.404,1.894"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M240.12,620.68c-2.119,1.081-1.428,3.391-2.031,5.086c-0.362,1.019-0.749,1.949-1.329,2.834"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M407.16,569.32c1.359,0.239,2.72,0.479,4.08,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M520.92,628.36c-1.189,3.337-1.829,6.856-3.36,10.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M79.32,575.08c1.337,0.469,2.721,0.14,4.08,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M421.56,624.04c-1.359-0.08-2.72-0.16-4.079-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M585.24,619.24c3.037-0.136,5.212,1.915,7.635,3.204c1.196,0.637,2.292,1.364,3.645,1.596"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M45.96,201.16c-1.12,0.32-2.24,0.64-3.36,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M335.64,626.2c-2.387-1.705-5.126-0.586-7.68-0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M304.92,618.52c-1.337,0.298-2.439-1.285-3.84-0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M447,742.6c-0.256,0.289-0.82,0.377-0.913,0.513c-1.783,2.618-3.916,2.495-6.287,0.928"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M377.16,504.76c0.175,0.577,1.533,0.033,1.062,1.474c-0.392,1.201,0.909,2.212,1.817,3.087"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M429.96,626.68c-2.345,0.008-4.19-1.961-6.733-1.513c-1.5,0.264-2.094,0.74-2.442,2.22c-0.513,2.167,0.259,4.677-1.624,6.493"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M28.2,582.52c-0.92,1.077-2.874,1.2-2.89,3.12c0,0.001-0.167,0.029-0.226-0.006c-0.094-0.056-0.164-0.153-0.244-0.233"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M422.76,625.48c1.12,3.119,2.217,6.248,3.38,9.352c0.235,0.631,0.512,1.343,1.301,1.568"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M171.72,682.12c-1.046,1.255-2.794-0.295-3.84,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M57.48,616.36c-1.253-0.511-2.563-0.108-3.84-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M258.36,559.24c1.294,0.528,2.208,1.927,3.84,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M77.16,702.76c-1.619-1.565-2.591,0.317-3.84,0.721"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M401.4,617.8c-1.819-0.006-3.437,0.777-5.023,1.479c-1.88,0.834-3.803,0.924-5.777,0.921"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M103.8,679.96c-1.296-0.029-2.488-0.894-3.84-0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M113.64,243.4c-1.12,0.32-2.24,0.64-3.36,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M416.04,607.48c0,0.879,0,1.76,0,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M416.52,613.24c-0.306,3.813,2.657,3.588,5.04,4.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M580.44,564.76c-0.876,2.337-2.412,3.806-5.04,3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M462.12,579.88c-1.28-0.08-2.56-0.16-3.84-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M575.4,584.2c-1.225,0.537-2.67-0.101-3.841,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M142.68,478.84c0.82-0.79,2.127-1.159,1.607-2.929c-0.12-0.407,2.008-1.419,3.159-0.622c1.003,0.694,2.073,1.146,3.154,1.631"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M578.04,376.36c0.482,1.895-0.087,4.156,1.92,5.52"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M57,232.84c1.12,0.32,2.24,0.64,3.36,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M417,620.44c-0.071,2.484,0.662,4.943,0.24,7.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M399.48,570.28c-1.293,0.057-2.42,1.444-3.841,0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M365.16,473.08c0.099,3.45-0.427,6.943,0.72,10.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M529.8,631.24c-0.08,0.08-0.15,0.216-0.241,0.231c-1.229,0.213-2.992-0.008-3.477,1.025c-0.588,1.258,0.427,2.736,1.078,4.022"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M394.2,620.2c1.051,2.201,1.188,4.627,1.646,6.967c0.215,1.103,0.753,1.995,1.72,2.087c1.326,0.126,2.623,0.174,3.84,0.773
				c0.032,0.017,0.172-0.137,0.228-0.231c0.035-0.06,0.006-0.155,0.006-0.236"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M421.32,420.76c1.854-2.191,4.172-0.478,6.247-0.618c1.116-0.075,2.513-0.685,3.593,0.378"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M87.72,586.36c-1.276-0.264-2.354-1.579-3.84-0.721"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M37.32,197.8c1.256,0.467,2.561,0.145,3.84,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M94.92,603.88c-0.32,1.84-0.64,3.681-0.96,5.521"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M476.28,555.64c0.828,1.16,1.742,2.12,3.359,1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M43.32,571.72c1.28,0.24,2.56,0.48,3.84,0.721"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M239.88,738.76c1.301-0.007,2.505,0.756,3.84,0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M573.48,254.68c-0.425,1.254-0.9,2.495-0.961,3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M419.88,611.08c-2.897-0.553-5.805-1.454-8.64,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M60.36,567.88c0.151,1.788,0.958,3.629-0.476,5.284c-0.613,0.707,0.376,0.931,0.485,1.434c0.313,1.457,1.32,2.611,1.722,4.068
				c0.413,1.499,1.484,2.355,3.056,1.196c0.812-0.599,1.654,0.271,2.413-0.223"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M472.44,619.72c1.701,0.074,2.215,2.394,4.079,2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M330.84,538.36c-1.885,1.482-1.736,3.729-2.16,5.76"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M376.92,629.56c0.184,2.086,1.078,4.35-1.439,5.761"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M176.76,212.44c-0.533,2.837,0.442,4.3,3.36,5.04c1.992,0.505,4.757-1.129,5.28-3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M416.52,572.68c0.694,1.683-0.21,2.894-1.199,4.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M376.68,562.84c0.24,0.801,0.581,1.687-0.022,2.381c-0.998,1.149-0.41,2.404-0.457,3.619"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M45.96,593.56c-1.951-0.685-3.831-1.663-6-1.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M19.56,444.52c-1.119,1.007-2.514,1.522-3.84,2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M96.84,215.56c-1.143,0.001-2.302-0.117-3.36,0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M478.44,535.72c-0.32-0.08-0.722-0.325-0.948-0.214c-1.954,0.961-4.024,0.143-6.012,0.454"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M504.84,533.8c1.936,1.314,0.335,3.471,1.2,5.04"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M226.44,670.36c0.121,1.539,1.8,2.3,1.92,3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M351,597.4c-0.947,1.502-2.296,1.001-3.6,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M548.04,564.52c-0.651,2.53-0.76,5.052-0.562,7.687c0.208,2.772-0.237,5.594-0.397,8.394"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M23.88,437.08c-2.38-0.922-5.007-1.009-7.32-2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M233.16,709.96c-2.217,0.736-4.464-0.199-6.738,0.1c-0.991,0.131-2.049,0.462-3.102,0.381"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M43.32,740.68c-1.749-0.114-2.204,2.072-3.84,2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M518.76,394.84c-1.294-2.312-3.021-1.109-4.541-0.436c-0.905,0.401-1.618,1.236-2.419,1.876"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M445.32,586.84c1.119,1.04,2.24,2.08,3.359,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M187.8,612.28c0.901,1.439,2.36,0.79,3.6,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M22.92,280.36c-1.067,0.548-2.132,1.101-3.36,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M384.84,564.04c-2.473,1.828-4.331,4.427-7.2,5.76"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M413.16,616.84c0.641,3.247,3.505,2.978,5.77,3.563c0.818,0.212,1.746-0.003,2.391,0.757"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M109.56,738.52c-1.518,0.562-1.969,2.459-3.6,2.881"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M403.56,609.88c-1.279-0.08-2.56-0.16-3.84-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M391.8,562.84c-1.42,1.69-1.828,3.886-2.88,5.76"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M245.88,549.16c1.28,0.508,2.56,0.177,3.84,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M381,566.92c-1.102-1.037-2.198-2.083-3.84-2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M144.12,610.6c-1.703,0.206-2.58-1.24-3.84-1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M553.56,628.36c0.571,2.097,0.12,4.071-0.72,6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M448.68,634.36c1.269,0.43,2.372,1.02,2.895,2.394c0.444,1.167,1.297,2.028,2.386,2.646"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M346.44,622.36c-3.438,0.192-6.39,1.616-9.12,3.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M77.4,689.08c-1.065,0.805-2.557,0.329-3.6,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M479.64,543.16c-2.361,1.079-0.911,3.43-1.68,5.04"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M143.4,575.56c-1.356-1.014-2.388,0.406-3.6,0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M277.08,280.12c-1.033,0.624-2.564,0.84-2.16,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M402.84,614.2c-0.383,2.419-1.432,4.706-1.439,7.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M431.4,733.24c0.625,1.387,1.723,2.427,2.64,3.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M546.84,569.56c-1.2,0.081-2.399,0.16-3.6,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M174.36,575.08c1.339-0.017,2.151,1.547,3.6,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M177.48,598.84c-3.94,0.084-3.544-0.635-3.151,3.603c0.184,1.983,0.031,3.997,0.031,5.998"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M277.56,574.6c1.2,0.48,2.4,0.96,3.6,1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M412.68,620.44c0.149,1.203,0.143,2.881-0.773,3.211c-0.988,0.355-2.338,0.43-3.546,0.389"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M483.96,570.28c-1.2,0.32-2.4,0.64-3.6,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M76.68,685.72c0.148,1.169,0.544,2.407,1.054,3.307c1.475,2.604-0.012,4.594-0.814,6.773"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M51.72,729.64c1.245-0.9,2.383-1.814,2.934,0.709c0.242,1.109,1.066,2.091,1.626,3.132"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M98.76,223.24c-1.12,0.32-2.24,0.64-3.36,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M534.36,625.72c-1.389,1.486-1.677,3.424-2.16,5.28"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M19.8,433.48c2.038-0.818,3.638,0.882,5.52,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M238.2,627.16c1.181,0.362,2.341,1.044,3.6,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M255,615.88c0.733,2.188-0.396,4.574,0.72,6.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M431.16,607.96c-1.2,0.8-2.4,1.6-3.601,2.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M439.08,572.68c1.301-0.06,2.389,0.681,3.6,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M486.36,574.6c0.009-1.572,1.034-0.392,1.443-0.418c2.071-0.135,4.156-0.062,6.236-0.062"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M446.76,579.88c-1.119,1.2-2.24,2.4-3.359,3.601"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M506.76,627.16c-1.227,0.325-2.343-1.012-3.6-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M174.84,611.56c1.2,0.081,2.4,0.16,3.6,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M47.88,212.44c-1.076,0.443-2.333,0.046-3.36,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M354.36,757.72c-3.233,0.231-6.335,1.03-9.36,2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M86.52,572.44c-1.2-0.827-2.4-0.506-3.6,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M454.68,576.04c-1.189,0.289-2.279,1.082-3.6,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M440.28,566.92c1.157,0.479,2.151,1.572,3.6,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M376.44,562.12c-1.699,0.531-2.991-0.401-4.32-1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M327.96,629.56c-1.464,0.403-2.575,1.337-3.6,2.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M25.08,589.48c-0.32,1.199-0.64,2.399-0.96,3.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M584.28,620.68c1.601,1.684,2.263,3.793,2.619,6.004c0.176,1.083,0.555,2.027,1.221,2.876"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M261.72,568.12c-1.358-0.271-2.373,0.744-3.6,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M44.52,600.28c-1.16,0.471-2.306,0.992-3.6,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M492.36,379.72c0.571,1.163,1.224,2.294,1.439,3.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M576.36,615.64c0.625-0.276,1.187,0.224,1.397,0.512c1.29,1.763,3.17,1.949,5.082,2.129"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M303.72,560.68c-1.116,0.794-2.472-0.215-3.6,0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M543.24,564.76c-0.586,1.476-0.467,3.024-0.48,4.561"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M542.28,573.16c0,0.56,0,1.12,0,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M417.48,628.36c0.062,1.863-0.556,3.814,0.72,5.52"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M408.84,596.92c0.468-0.197,1.297,0.497,1.418-0.482c0.087-0.706,0.214-1.497-0.693-1.927c-1.452-0.686-2.986-0.905-4.564-0.951"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M485.16,398.2c-0.62-0.979-0.529-2.671-2.4-2.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M79.8,711.64c-1.2,0.161-2.4,0.32-3.6,0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M27.96,593.56c-1.92,0.761-3.663-0.516-5.52-0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M111.72,622.6c-0.668-1.433-2.05-1.865-3.36-2.399"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M305.88,617.08c-1.242,1.092-0.689,2.552-0.674,3.84c0.014,1.169-0.279,2.257-1.273,2.549c-0.74,0.219-1.095-1.027-1.653-1.589"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M441.96,620.92c-1.2,0.64-2.4,1.28-3.6,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M85.08,590.44c-1.2,0.399-2.4,0.8-3.6,1.199"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M348.36,401.56c0.856,0.96-0.25,1.886-0.057,2.892c0.156,0.807-0.694,1.876,1.014,1.959c0.584,0.028,0.491,0.846,0.487,1.39
				c-0.013,1.6-0.005,3.2-0.005,4.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M80.52,589c1.475,5.584,1.846,11.309,2.16,17.04"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M438.6,594.52c1.351,0.239,2.387,1.15,3.601,1.681"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M161.4,594.52c-1.177,0.354-2.308,0.94-3.6,0.721"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M22.92,372.76c0.08,0,0.163,0.013,0.24-0.001c4.081-0.762,4.081-0.762,4.081,3.362"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M578.04,583.72c1.2,0.319,2.4,0.44,3.6,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M140.28,568.36c1.026,0.973,2.518,0.197,3.6,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M489.24,562.12c0.683,0.416,1.436,0.204,2.16,0.238c0.815,0.039,2.119,0.394,1.814-0.934c-0.192-0.837,0.62-2.987-1.822-2.457
				c-0.916,0.199-1.912,0.032-2.873,0.032"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M289.32,617.08c-0.617,1.001-2.734-0.06-2.64,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M564.12,633.16c-1.167-0.405-2.345-0.757-3.601-0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M277.8,290.92c1.499,0.543,3.811,0.335,2.88,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M89.16,590.68c-2.602,0.629-2.663,2.461-2.16,4.561"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M88.44,574.12c0.16,0.08,0.317,0.167,0.48,0.239c3.338,1.478,3.338,1.477,0.959,4.561"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M138.84,573.88c-0.24,1.12-0.48,2.24-0.72,3.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M18.6,451.96c1.247,0.696,2.039,1.742,2.4,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M372.12,501.16c2.143,0.274,3.709,1.332,4.56,3.359"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M201,219.64c-1.75,1.435-4.771,1.887-6.01,0.974c-0.707-0.521-0.767-1.183-0.71-1.934"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M107.88,595.72c1.13,1.516,2.776,1.176,4.32,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M268.68,293.56c0.119,2.049-0.058,4.173,1.672,5.769c0.549,0.505,0.245,1.13-0.001,1.667c-0.388,0.846-0.845,1.663-0.71,2.645"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M146.52,452.2c-1.354,0.26-1.469,1.004-0.986,2.171c0.347,0.838,1.293,1.355,1.226,2.389"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M493.32,562.6c0.402,1.52,0.317,3.167,1.199,4.561"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M33.96,288.28c-0.96-0.074-1.949,0.193-2.88-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M544.44,625.72c-1.426-0.748-2.087,1.178-3.36,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M233.88,770.2c-1.236,0.966-0.363,2.233-0.48,3.359"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M519.96,394.72c-2.255,0.264-2.178,2.184-2.64,3.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M519.72,626.68c1.354,1.483,3.077,2.195,5.04,2.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M336.36,626.68c-2.284,1.18-3.069,3.532-4.32,5.521"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M334.68,366.04c0.251,1.669,1.749,2.963,1.44,4.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M176.52,614.2c1.317,0.818,2.601,2.183,4.08,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M281.64,620.44c-0.989,0.794-2.368,1.023-3.12,2.159"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M327.24,626.44c-1.332-0.014-1.822,1.935-3.36,1.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M289.32,623.08c-1.04,0.601-2.122,1.055-3.36,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M404.76,607.48c-1.559,1.613-0.604,4.047-1.888,5.783c-0.308,0.415,0.146,0.628,0.682,0.73c1.062,0.204,2.086,0.605,3.126,0.926"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,583.24c0,0.56,0,1.119,0,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M387.96,515.32c-1.005,0.453-2.219-1.509-2.759-0.19c-0.462,1.127,1.136,1.86,2.039,2.59"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M37.56,277.24c-0.596,1.057,0.209,2.315-0.48,3.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.24,630.76c-1.542,0.064-2.225,1.464-3.36,2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M317.64,576.52c2.017,3.382,1.239,5.756-1.46,8.833c-2.081,2.37-3.371,1.48-5.26,1.008"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M15.72,419.32c3.005-0.283,3.005-0.283,6.145,2.501c-2.242,0.401-4.193,0.75-6.145,1.099"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M108.84,583c-1.198-0.012-2.359,0.075-3.12,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M312.12,565.24c-1.04-0.447-2.08,1.005-3.12,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M73.56,594.04c-0.578,1.48-1.82,1.524-3.12,1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M132.84,574.12c0.903,1.019,2.331,1.122,3.36,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M550.68,444.28c-0.421,2.396-2.532,1.918-4.08,2.399"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M506.28,578.68c-0.1,1.129,0.318,2.331-0.48,3.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M52.68,611.08c0.807,1.26,0.994,2.544,0.801,4.09c-0.185,1.481-0.613,3.189,0.639,4.55"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M164.52,477.64c1.655,0.421,3.348,0.441,5.04,0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M507.24,583.72c1.318,0.262,2.34,0.681,2.228,2.405c-0.035,0.531,0.732,1.115,1.132,1.675"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M348.36,430.36c-0.08,1.119-0.16,2.24-0.24,3.359"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M118.68,576.52c1.015,0.37,1.68,1.409,2.879,1.463c0.327,0.014,0.907,0.276,0.922,0.46c0.312,3.993,4.19,2.322,6.039,3.838"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M381.48,584.44c1.119,0.88,2.239,1.76,3.359,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M309.96,528.76c-0.26,1.66-1.98,1.859-2.88,2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M338.28,645.4c-1.371,0.966-3.189,0.233-4.561,1.199"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M444.36,566.68c-0.81,1.902,0.537,3.266,1.199,4.801"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M153.48,579.88c-1.167,0.023-2.395-0.242-3.36,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M379.08,770.44c-1.062,0.802-1.921,1.747-2.16,3.119"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M386.04,567.88c-1.495,0.342-2.292,1.51-3.12,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M147.96,604.6c-0.886,1.127-1.657,2.379-3.12,2.881"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M504.36,578.92c-0.793,1.466-2.165,0.906-3.36,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M345.96,579.16c0.109,1.118,1.208,2.165,0.24,3.359"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M558.12,558.76c1.144,0.13,2.15,0.896,3.36,0.721"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M385.8,598.36c-1.319-0.381-2.276,0.512-3.359,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M251.16,570.76c-1.44,1.098-2.183,2.622-2.64,4.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M26.52,382.12c-1.175,0.508-2.038,1.271-2.16,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M78.12,577c2,0.24,4,0.48,6,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M146.76,479.56c-1.655,1.886,0.283,2.631,1.23,3.57c0.643,0.638,1.57,0.99,2.37,1.47"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M204.84,694.84c-0.975-0.829-2.143-0.979-3.36-0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M475.56,572.68c1.01,0.896,1.085,2.258,1.681,3.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M311.88,601.24c0.72,0.64,1.521,1.21,2.143,1.935c1.121,1.311,1.654,0.549,2.178-0.495"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M594.12,581.56c1.077,0.409,2.119,0.353,3.12-0.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M471.72,620.92c-0.021,2.162-1.167,4.282-0.239,6.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M522.84,622.84c-1.165,1.297-0.652,3.213-1.68,4.561"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M93.96,569.08c0.824,0.975,1.929,0.269,2.88,0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M95.16,571.72c-1.292,0.148-1.512,1.369-2.16,2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M271.8,762.52c-1.564-0.414-1.965,1.334-3.12,1.681"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M289.32,612.76c2.408,0.163,4.675,1.733,7.2,0.721"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M439.8,569.32c1.198,0.266,2.126,1.035,3.12,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M155.88,605.32c1.208,1.81,0.562,3.443-0.48,5.04"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M244.68,620.2c-1.041,0.159-2.08,0.32-3.12,0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,615.64c-1.921,2.112-3.841,1.003-5.761,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M475.8,580.6c-0.09,1.229,0.551,2.177,1.2,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M200.04,609.64c-0.958,3.375-1.014,6.855-1.2,10.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M30.12,599.56c-0.382,1.604,0.989,2.55,1.44,3.841"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M227.4,583.48c-0.96,1.52-1.92,3.039-2.88,4.56"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M511.08,631.24c-2.298-0.651-4.854,0.242-7.024-1.604c-1.155-0.981-2.976-0.123-4.496-0.076"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M166.44,598.84c-1.04,0-2.08,0-3.12,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M255.48,627.88c1.04,0.24,2.08,0.48,3.12,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M381.72,548.92c-0.239,1.04-0.479,2.08-0.72,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M232.44,621.64c0.678,0.661,0.969,1.467,1.254,2.384c0.604,1.939,1.313,3.896,2.946,5.297"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M285.72,586.6c0.96,0.88,1.92,1.761,2.88,2.641"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M114.84,560.68c-1.04-0.159-2.08-0.32-3.12-0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M471.72,403c-0.88-0.322-1.792-1.015-2.627,0.011c-0.431,0.53-1.437,0.799-1.175,1.657c0.245,0.8,0.836,1.304,1.884,1.248
				c1.356-0.072,2.314,0.527,2.158,2.124"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M18.36,769.24c1.238,1.236,0.343,2.946,0.96,4.319"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M277.8,288.28c0.16,0.081,0.314,0.211,0.481,0.233c2.58,0.33,2.686,0.559,1.199,2.647"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M273.48,287.56c-0.24,0.24-0.715,0.657-0.686,0.695c1.463,1.948-1.105,3.865-0.034,5.785"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M445.08,277.24c0.64,2.82-0.311,5.604-0.207,8.398c0.124,3.374-1.331,6.694-0.273,10.082"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M26.28,514.36c-1.04-0.08-2.08-0.16-3.12-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M595.8,512.68c-0.24,0.24-0.637,0.449-0.692,0.726c-0.222,1.111-1.205,2.015-1.911,1.798c-1.004-0.309-1.885-1.358-1.477-2.763"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M580.92,361.48c-0.027,1.682,0.209,3.378-0.24,5.04"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M56.52,299.8c1.011,0.453,2.08,0.161,3.12,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M467.16,530.2c0.685,1.765,3.423,2.298,2.88,4.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M213.72,678.76c1.04,0.88,2.08,1.761,3.12,2.641"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M364.68,461.8c0.247,3.597,0.953,7.172,0.48,10.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M67.56,468.04c-0.922,0.769-0.956,2.346-2.4,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M60.84,475c-0.228,1.382,0.808,0.948,1.44,0.941c0.96-0.01,1.92-0.142,2.88-0.222"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M60.6,675.88c-1.964,1.604-4.864,1.024-6.72,2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,667.96c-0.521,1.36-2.244,1.407-2.881,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M582.6,506.2c0.574,0.312-0.057,1.069,0.694,1.486c1.459,0.812,3.059,1.546,2.994,3.796c-0.025,0.856,1.863,0.702,2.072,1.918"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M156.6,455.08c0.886,0.891,0.157,2.154,0.72,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M51.72,303.4c-0.931-0.433-1.92-0.166-2.88-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M78.84,729.16c-0.426,1.177-0.821,2.406-0.209,3.584c0.401,0.771,0.127,1.007-0.51,1.216"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M160.68,731.08c-1.129-0.145-2.165,0.113-3.12,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M568.92,322.84c0.327,0.446,0.818,0.277,1.197,0.22c1.574-0.238,2.243,0.366,1.91,1.938c-0.104,0.488,0.326,0.488,0.492,0.723"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M561.96,609.64c-0.96,0.801-1.92,1.601-2.88,2.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M120.84,691.96c-1.44-0.399-2.88-0.8-4.32-1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M135.48,514.84c-0.908,0.711-1.962-0.352-2.881,0.241c0.123,1.453,1.661,1.927,2.161,3.119"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M324.12,545.8c0.96,0.16,1.92,0.32,2.88,0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M220.44,648.28c-2.306,1.629-3.801,4.037-5.76,6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M47.16,578.68c-1.986-0.335-3.983-0.528-6-0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M358.2,696.04c-0.96,0.8-1.92,1.6-2.88,2.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M20.28,512.2c2.192,0.954,3.193,3.226,5.04,4.56"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M19.56,701.32c-2.342,0.066-3.541,1.353-3.84,3.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M320.76,736.36c-1.305-0.428-1.884,0.886-2.88,1.199"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M367.08,677.8c-1.04,0.48-2.08,0.96-3.12,1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M122.04,678.52c-0.673-1.088-2.02-0.561-2.88-1.199"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M176.76,567.64c-0.16,0.96-0.32,1.92-0.48,2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M187.56,613c-0.925,0.503-1.923,0.122-2.88,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M582.12,613.48c0.976,0.618,1.146,1.603,1.2,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M354.6,497.8c0.334,1.747,1.973,2.188,3.12,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,486.04c-0.311,0.819,0.702,2.018-0.721,2.52"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M212.28,664.84c0.96,0.64,1.92,1.28,2.88,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M89.16,566.44c0.96,0.399,1.92,0.8,2.88,1.199"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M119.64,570.28c0.75-1.741,0.565-3.133-1.44-3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M56.52,572.2c0.759,0.883,1.66,1.479,2.88,1.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M363.72,573.16c-0.823,0.729-1.932,0.605-2.88,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M150.84,456.28c0.004-1.091-0.516-1.738-1.385-2.466c-1.074-0.898-2.549-1.406-3.175-2.814"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M15.72,251.32c0.406,0.958,0.311,2.124,1.2,2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M487.56,613.24c-1.05,0.848-2.278,1.558-2.399,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M484.2,620.2c0.942,1.096,0.329,2.398,0.479,3.6"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M18.12,570.52c0.4,0.961,0.8,1.921,1.2,2.881"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M168.12,213.16c-0.96,0.72-1.92,1.44-2.88,2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M589.32,595c0.796,0.805,3,0.061,2.491,2.42c-0.108,0.504,1.156,0.429,1.828,0.46"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M107.16,594.28c-0.96-0.359-1.92-0.359-2.88,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M507.96,395.56c-2.045,0.953-3.707,2.172-3.36,4.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M223.56,646.36c-1.098,0.203-1.68,1.439-2.88,1.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M238.44,710.44c2.96,0.708,5.92,0.616,8.88,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M457.8,371.56c0.96,0.08,1.92,0.16,2.88,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M483.96,531.88c0.878,2.163,3.235,1.861,4.8,2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M207.48,606.28c-1.841-1.971-3.744-2.46-5.76-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M357.24,616.36c-1.49,1.186-2.339,2.775-2.88,4.56"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M267.48,767.08c0.96,0.074,1.95-0.192,2.88,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M181.08,715c-2.428,0.693-3.208,3.216-5.054,4.541c-0.751,0.539-1.001,1.953-2.386,1.459"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M17.16,374.2c-0.48,0.96-0.96,1.92-1.44,2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M96.84,600.76c-1.311,0.264-1.593,1.351-1.92,2.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M245.4,616.6c-2,0.16-4,0.32-6,0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M83.4,587.56c-1.044,0.307-1.962,1.022-3.12,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M496.68,612.76c0.88,0.801,1.761,1.6,2.641,2.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M585.96,677.56c0.8,0.721,1.6,1.44,2.4,2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M416.76,613c0.88,0.08,1.761,0.16,2.641,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M222.36,584.2c0.88,0.72,1.76,1.439,2.64,2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M367.32,567.88c1.607-0.079,3.177,0.618,4.8,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M577.8,597.4c-3.575-0.087-6.926,1.057-10.309,1.961c-0.823,0.221-1.597,1.267-1.691-0.521"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M172.68,718.12c0.301,0.967,0.326,2.024,0.96,2.88"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M181.8,583.72c1.315,0.958,1.438,2.662,2.4,3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M123.48,613c-0.843-0.488-1.763-0.126-2.64-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M570.36,585.16c-0.85,0.397-1.916,0.434-2.4,1.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M356.76,613.96c-0.85,0.405-1.757,0.188-2.64,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M16.8,373.24c0.733,1.49,2.115,0.792,3.24,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M524.76,373.48c-0.873,0.336-1.919,0.201-2.64,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M572.28,629.32c-0.88-0.08-1.761-0.16-2.641-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M483.48,620.2c-1.414,1.659-1.674,3.766-2.109,5.771c-0.136,0.624-0.359,1.034-0.771,1.429"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M227.4,614.2c0.502,0.995,1.899,0.201,2.4,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M452.76,477.4c-0.159,0.88-0.32,1.76-0.479,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M522.84,622.48c2.603,0.932,1.805,3.347,2.09,5.171c0.23,1.482,0.423,2.933,1.03,4.309"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M410.28,614.2c-0.88,0.24-1.761,0.479-2.641,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M405.24,602.2c-0.573,1.494,0.168,3.057-0.24,4.56"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M224.76,186.28c0,0.879,0,1.76,0,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M153.96,605.8c-0.88,0.16-1.76,0.32-2.64,0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M341.16,771.4c0.812,0.707,1.163,1.925,2.399,2.159"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M323.16,625.96c-1.331-0.737-2.54,0.479-3.84,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M179.64,575.32c0.88,0.72,1.76,1.439,2.64,2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M24.12,691.24c0.88,0.64,1.76,1.28,2.64,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M20.52,430.12c-1.784,0.986-3.597,2.165-4.8-0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M538.44,765.64c-0.721,0.801-1.44,1.601-2.16,2.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M541.8,579.64c-0.564-0.935-1.206-1.771-2.399-1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M506.04,428.68c-0.843,0.488-1.763,0.126-2.64,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M29.16,697.24c-1.074,0.374-1.432,1.73-2.64,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M264.36,582.28c-0.812,0.535-1.59,1.253-2.64,0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M318.6,622.36c0.688,2.532-0.211,5.411,1.681,7.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M591.48,597.4c-0.88,0-1.761,0-2.641,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M219.72,618.76c-0.88-0.445-1.76-0.288-2.64,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M48.36,690.28c-0.88,0.239-1.76,0.479-2.64,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M63,237.88c-0.193,0.927-0.718,1.521-1.68,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M365.16,558.52c0.657,1.617,2.617-0.079,3.359,1.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M209.88,511.96c-1.287,0.777-2.596,1.372-4.08,0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M82.68,243.4c-0.843,0.489-1.763,0.125-2.64,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M575.88,508.12c1.68,0.399,3.355,0.819,5.042,1.193c1.159,0.257,1.191-1.069,1.918-1.434"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M572.28,248.92c-0.64,0.88-1.28,1.76-1.92,2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M74.76,216.76c-0.887,0.3-1.773,0.6-3.021,1.022c1.24,0.27,2.13,0.464,3.021,0.658"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M137.88,530.44c-0.442,0.526-1.681-0.047-1.632,0.957c0.046,0.922,1,0.613,1.625,0.752c0.355,0.079,0.646,0.45,0.966,0.69"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M458.04,645.88c3.747-0.187,7.318,0.612,10.802,1.917c0.069,0.026,0.204,0.035,0.229-0.003c0.101-0.146,0.169-0.314,0.249-0.474"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M210.6,615.88c-0.011,1.634,0.146,3.294-0.72,4.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M482.52,676.6c1.914,1.679,4.137,0.683,6.24,0.721"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M221.4,614.2c-0.526,1.68,0.706,3.359,0,5.04"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M196.2,568.36c-1.723-0.092-2.993,1.63-4.8,1.199"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M404.28,372.04c0.38,0.755,0.827,1.499,0.479,2.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M111.24,427.48c-0.713,0.594-1.46,1.019-2.4,0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M554.28,625c-0.539,1.746,1.61,1.441,2.107,2.812c0.868,2.396,1.62,4.806,2.163,7.278c0.137,0.624,0.357,1.035,0.77,1.429"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M29.16,584.44c-1.015-0.029-1.739,0.521-2.4,1.199"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M110.76,232.12c-0.8,0.24-1.6,0.48-2.4,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M94.2,586.84c-0.72,0.48-1.44,0.96-2.16,1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M245.4,708.04c1.601,0.414,1.615,3.122,3.842,2.649c0.332-0.07,0.746,0.071,0.718-0.489"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M544.2,621.88c0.8,0.48,1.6,0.96,2.399,1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M57,586.6c-0.979-0.042-1.283-1.435-2.4-1.199"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M90.12,594.04c-1.47,1.276-1.617,1.539-4.801,1.565c-1.099,0.009-1.915,0.459-2.879,0.595"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M350.04,581.08c-0.8,0.8-1.6,1.6-2.4,2.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M569.64,572.2c1.177,0.021,1.385,1.428,2.4,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M366.6,622.84c-0.788,0.348-1.315,1.35-2.399,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M327.24,623.32c0.479,2.56,1.009,5.112,1.403,7.686c0.092,0.598,0.478,0.817,0.757,1.194"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M490.92,566.2c-0.8,0-1.6,0-2.4,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M580.68,615.64c1.44,0.903,2.88,0.511,4.32,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M445.56,748.12c-0.771-0.603-0.85-1.822-1.92-2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M355.56,626.68c-0.795,0.13-1.643-0.268-2.399,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M18.84,574.6c-0.395,0.242-0.167,0.639-0.245,0.959c-0.172,0.712,0.52,1.67-0.26,2.118c-0.906,0.521-1.382-0.672-2.135-0.917"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M196.2,565.48c-1.208,0.199-2.308,0.939-3.6,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M258.12,612.28c-0.8,0.239-1.6,0.479-2.4,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M102.12,605.32c1.68,0.399,3.36,0.8,5.04,1.199"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M158.76,534.52c-0.544,0.949-1.418,0.418-2.16,0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M573.72,561.4c1.209,1.576,0.218,3.652,1.2,5.279"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M476.52,622.36c0.52,2.057-0.116,4.336,1.2,6.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M278.52,283.96c1.766,0.717,1.015,2.692,1.92,3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M441,576.76c-1.367,1.862-1.593,4.163-2.4,6.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M331.32,577.48c-0.681,0.437-1.379,0.709-2.16,0.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M154.44,581.32c-0.16,0.72-0.32,1.439-0.48,2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M498.12,631.96c1.178,0.981,2.57,0.234,3.84,0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M389.16,624.04c-2.788,0.741-3.927,3.447-5.999,5.041c-0.406,0.312-0.802,0.639-1.201,0.959"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M543.24,581.56c0.24,0.721,0.479,1.44,0.72,2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M422.52,178.84c-0.15,0.674,0.434,1.623-0.72,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M388.2,615.16c0.359,0.986,1.41,0.132,1.92,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M595.32,730.6c-0.828,0.453-1.468,1.093-1.92,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M423,609.16c0.736,0.37,1.131,1.355,2.16,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.24,620.2c-0.721-0.08-1.44-0.16-2.16-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M177.72,593.56c-1.204,0.795-1.681,2.679-3.6,2.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M597.48,592.6c-1.835,1.264-3.806,2.352-5.28,4.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M172.44,525.16c0.934,1.845-0.024,2.67-1.68,3.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M226.68,590.68c2.07,1.428,4.296,2.65,6,4.561"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M432.6,740.44c0.557,0.688,1.451,0.615,2.16,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M263.88,647.56c1.592,0.28,2.006,2.059,3.36,2.641"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M381.96,586.84c0.72,0.561,1.44,1.12,2.16,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M156.6,601c0.082,1.761,1.189,3.978-1.92,4.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M131.16,530.92c-1.496,0.549-3.041,0.141-4.56,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M28.44,384.28c-0.15,0.959-1.381,0.656-1.68,1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M16.2,569.56c0.586,0.763,1.29,0.993,2.16,0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M183.96,681.88c-1.123,0.04-2.267-0.213-3.36,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M302.28,565.72c-0.72,0-1.44,0-2.16,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M222.84,673.48c0.64,0.72,1.28,1.439,1.92,2.159"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M494.76,635.56c0.08-0.08,0.216-0.149,0.232-0.241c0.401-2.209-2.357-5.689-4.552-5.759"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M271.08,684.28c0.72,1.359,1.44,2.72,2.16,4.08"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M566.52,627.16c0.183,1.283-0.609,2.97,1.44,3.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M395.88,628.36c-2.118,0.93-4.146-0.52-6.24-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M305.4,563.56c-0.813,0.759-1.399-0.526-2.16-0.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M449.88,689.08c0.371,0.839,1.368,0.843,1.92,1.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M142.44,479.32c-0.08,0.72-0.16,1.439-0.24,2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M538.92,683.8c-0.587-1.415-2.106-1.729-3.12-2.64"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M330.6,628.6c-0.72,0-1.439,0-2.159,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M270.6,654.04c0.88,1.44,1.76,2.88,2.64,4.32"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M32.04,597.4c-0.64,0.479-1.28,0.96-1.92,1.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M143.4,596.92c-0.64,0-1.28,0-1.92,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M575.4,580.12c-0.641,0-1.28,0-1.92,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M320.04,567.64c-0.64,0.08-1.28,0.16-1.92,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M94.44,605.32c-0.64,0.08-1.28,0.159-1.92,0.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M442.68,562.6c1.349,0.854,0.235,2.69,1.44,3.601"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M258.6,709.96c0.586,0.508,1.286,0.111,1.92,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M143.4,600.04c-0.64-0.08-1.28-0.16-1.92-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M254.04,530.44c0.448,0.61,0.894,1.224,1.68,1.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M320.52,599.32c-1.432-0.169-1.711,1.805-3.119,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M226.2,617.32c0.974,0.278,2.021,0.337,2.88,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M533.4,599.8c0.64,0.48,1.279,0.96,1.92,1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M362.28,598.36c-0.86-0.119-1.403,0.394-1.92,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M353.16,598.36c-0.634-0.129-1.334,0.268-1.92-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M416.76,598.36c1.141,0.918,2.988,0.424,3.84,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M562.2,564.04c-0.993,0.686-2.088,0.059-3.12,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M419.16,742.12c1.151-0.062,2.169,0.818,3.359,0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M207.36,772.84c-1.048-0.53-2.273-3.25-3,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M153.48,583.72c-0.543,0.5-1.514,0.501-1.68,1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M154.92,588.28c0.56,0.399,1.12,0.8,1.68,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M204,705.16c-1.368-0.641-2.777-0.566-4.2-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M244.2,610.84c0.64-0.08,1.28-0.16,1.92-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M128.28,258.76c-0.56-0.04-1.12-0.08-1.68-0.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M436.68,678.76c-0.56,0.561-1.119,1.12-1.68,1.681"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M493.8,566.2c-0.64,0-1.28,0-1.92,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M517.32,631.48c0.56,0.399,1.119,0.8,1.68,1.199"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M236.28,445c-0.864,1.784,0.836,2.606,1.44,3.84"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M501.72,758.44c-0.64,0.479-1.28,0.96-1.92,1.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M382.92,628.84c-0.443-0.764-1.169-0.777-1.92-0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M43.08,388.84c-0.586-0.507-1.286-0.111-1.92-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M403.08,578.92c-0.587,0.508-1.286,0.111-1.92,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M536.04,661.72c0.881,0.217,0.95,1.014,1.2,1.681"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M517.8,578.44c-0.646,0.224-1.136,0.863-1.92,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M142.68,546.04c0.4,0.64,0.8,1.28,1.2,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M424.92,633.16c-0.902,0.205-1.242-0.716-1.92-0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M284.76,577.96c-0.625,0.308-1.457,0.328-1.68,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M550.44,619.48c0.754,0.365,1.313,0.925,1.68,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M494.76,629.56c-0.634,0.129-1.333-0.268-1.92,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M502.92,594.52c-1.198,0.723-0.678,3.163-2.88,2.881"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M316.2,504.52c1.267,1.217-0.048,2.249-0.24,3.36"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M22.2,598.6c-0.56-0.08-1.12-0.159-1.68-0.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M157.32,588.04c-0.896,1.776-0.92,3.938-0.336,5.465c1.08,2.825-0.081,3.168-2.304,3.186c-0.241,0.002-0.48,0.149-0.72,0.229"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M152.76,501.4c0.553,0.128,1.183-0.276,1.68,0.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M213.48,596.92c-0.56,0-1.12,0-1.68,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M148.68,596.44c0.822,0.128,0.853,0.915,1.2,1.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M345.72,628.36c-0.56,0.08-1.119,0.159-1.68,0.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M278.28,293.8c1.552,0.145,2.97,0.69,4.32,1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M151.56,597.88c-1.044-0.098-1.978,0.247-2.88,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M500.52,594.52c0.561,0.24,1.12,0.48,1.681,0.721"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M510.36,628.36c-0.561-0.08-1.12-0.16-1.681-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M570.84,566.2c0.561,0,1.12,0,1.68,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M570.36,607.72c0.56,0.08,1.119,0.16,1.68,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M35.64,566.92c-0.56-0.08-1.12-0.16-1.68-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M320.04,563.8c-0.553-0.128-1.183,0.275-1.68-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M262.92,611.32c0.55,0.413,1.101,0.825,1.805,1.354c-1.293,0.801-1.504,2.394-2.525,3.446"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M233.64,614.68c-0.974-0.103-1.929-0.138-2.64,0.721"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M413.88,613.48c-0.56-0.081-1.12-0.16-1.68-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M379.08,674.92c-1.025,0.683-2.284,0.276-3.36,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M426.12,628.6c-0.561,0.08-1.12,0.16-1.68,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M28.44,721.48c-0.32,0.56-0.64,1.119-0.96,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M387.48,549.4c-0.283,1.128-1.259-0.163-1.681,0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M301.8,562.84c-0.56,0.08-1.12,0.16-1.68,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M257.64,598.36c0.951-0.229,1.896,0.134,2.605,0.54c1.097,0.628,1.85,0.387,2.675-0.301"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M256.92,562.6c0.56,0.08,1.12,0.16,1.68,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M40.68,605.32c1.429,0.008,2.032,1.357,3.12,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M513,629.56c-0.561-0.08-1.12-0.159-1.68-0.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M563.16,607.24c0.56,0.399,1.12,0.8,1.68,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M81.72,677.08c-1.04,0.24-2.08,0.479-3.12,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M63.24,476.2c-0.324,0.587-0.845,0.938-1.44,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M297.72,741.16c1.498-0.995,1.916,0.161,2.401,1.199c0.334,0.714,0.266,1.606,0.958,2.16"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M564.84,583c-0.595,0.262-1.116,0.612-1.439,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M339.72,582.28c0.397,0.604,1.05,0.763,1.681,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M140.28,580.84c-0.56,0.08-1.12,0.16-1.68,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M273.72,285.64c-1.438,1.503,0.578,1.625,0.96,2.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M222.84,586.6c1.05,0.458,1.615,1.208,1.44,2.4"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M319.56,571.48c-0.56,0-1.119,0-1.68,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M93.96,556.12c-0.684,0.104-0.889,1.045-1.68,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M584.28,584.44c0.56,0.319,1.119,0.64,1.68,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M273,627.16c-0.56,0.16-1.12-0.467-1.68,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M370.68,565c-0.56,0.08-1.119,0.16-1.68,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M480.36,579.16c-0.684,0.104-0.889,1.045-1.681,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M569.88,577.72c-0.402,0.712-1.089,0.43-1.68,0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M230.52,577.48c-0.56,0.239-1.12,0.479-1.68,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M173.4,577.24c0.792-0.085,0.997,0.855,1.68,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M456.6,291.64c0.561,0.48,1.12,0.96,1.681,1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M573,570.04c0.224,0.704,0.725,0.993,1.44,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M491.88,633.4c-0.323,0.692,0.822,1.501-0.229,2.176c-0.835,0.536-1.209-0.063-1.69-0.496"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M498.84,768.52c0.32,0.561,0.64,1.12,0.96,1.681"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M405.48,631.48c0.844-0.474,1.822,0.527,2.64-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M57.96,589.24c-0.4,0.56-0.8,1.119-1.2,1.68"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M554.28,624.28c-0.45,0.92-1.285,0.299-1.92,0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M347.88,425.08c1.722,1.443,0.414,3.19,0.48,4.8"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M17.88,591.4c1.421,1.298-0.328,1.646-0.72,2.399"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M155.88,487.96c-1.161,0.122-1.111-1.773-2.4-1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M423.48,614.44c-0.48,0.72-0.961,1.439-1.44,2.159"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M565.56,599.8c-0.965,0.646-1.978,0.981-3.119,0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M115.32,564.04c0.8,0.64,1.6,1.28,2.4,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M99.72,228.76c0.72,0.4,1.44,0.8,2.16,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M444.84,86.92c-0.479-0.04-0.96-0.08-1.439-0.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M539.88,379.96c-0.844,0.367-1.046,1.215-1.439,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M436.92,742.36c0.8,0.399,1.6,0.8,2.4,1.199"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M84.6,581.56c0.24-0.399,0.408-1.048,0.736-1.144c0.541-0.158,0.705,0.465,0.685,0.903c-0.028,0.588,0.365,0.614,0.739,0.721"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M528.84,676.6c0.72,0.4,1.44,0.801,2.16,1.2"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M513.48,513.4c1.312,1.356,3.196,1.289,4.8,1.92"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M561,582.52c-0.747,0.106-0.957,1.286-1.92,0.961"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M78.36,584.2c-1.502,0.421-3.04,0.162-4.56,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M70.2,213.16c0.4-0.04,0.8-0.08,1.2-0.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M393.48,595.48c-0.368,0.843-0.879,1.495-1.921,1.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M308.76,565.96c-0.326,0.446-0.8,0.159-1.2,0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M361.32,709.72c0.56-0.722,1.36,0.483,1.92-0.239"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M364.2,528.76c-0.256,0.288-0.504,0.593-0.96,0.48"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M343.8,452.44c-0.72,0-1.439,0-2.16,0"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M34.68,386.44c0.64-0.08,1.28-0.16,1.92-0.24"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M505.8,592.12c-0.64,0.479-1.28,0.96-1.92,1.439"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M535.08,624.28c-0.08,0.32-0.16,0.64-0.24,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M140.52,491.8c1.08,0.313,0.452,0.911,0.24,1.44"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M66.84,679.24c-0.422,0.643-1.396-0.648-1.68,0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M375,629.32c0.099,0.892,0.973,0.621,1.44,0.96"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M489.24,354.28c-0.561-0.04-1.12-0.08-1.681-0.12"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M364.2,507.88c-0.24-0.16-0.48-0.32-0.72-0.479"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M222.36,523.48c-0.16,0.239-0.32,0.479-0.48,0.72"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M148.68,603.88c-0.48,0.933-1.016,0.201-1.429-0.021c-0.917-0.492-1.703-1.374-2.892-0.699"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M501.72,240.04c-2.04,1.501-0.489,3.348-0.444,5.039c0.008,0.295,0.697,0.74,0.881,0.453c0.497-0.78,1.174-1.613,0.975-2.605
				c-0.204-1.014,0.905-2.625-1.171-2.886"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M539.4,322.84c-1.697,1.889-1.605,3.745,0.245,4.043c0.861,0.139,1.948,0.369,2.606-0.465c0.522-0.661,0.485-1.681,0.21-2.354
				c-0.472-1.154-1.614-1.418-2.822-1.224"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M555.24,285.16c-2.066,1.216-3.095,3.377-2.13,4.779c0.579,0.842,1.521,1.915,2.575,1.392c1.513-0.75,2.718-1.944,2.465-4.015
				c-0.209-1.706-1.079-2.306-2.67-2.156"/>
			
			<path fill-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M537,233.08c-1.024,3.018-3.538,4.596-5.982,6.265c-0.39,0.265-1.033,0.531-0.555,1.444c0.476,0.907,1.275,0.894,1.708,0.641
				c1.803-1.055,3.272-0.464,4.86,0.477c0.434,0.257,1.183,0.681,1.907,0.075c0.702-0.587,0.691-1.589,0.409-1.894
				c-1.879-2.023-1.363-4.696-2.107-7.009"/>
			</g>
			</svg>
		</div>
    )
}
