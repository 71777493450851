import React from 'react'

function LoadingSpeaner() {
    return (
        <div id="loading-spinner">
            <img src="/loading.svg" alt="loading spinner"/>
        </div>
    )
}

export default LoadingSpeaner
