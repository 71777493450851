import React from 'react';
import './GrandCanyon.css';

export default function GrandCanyon(props) {
    
    return (
		<div className={`grand-canyon-container ${props.size}`}>
			<svg  xmlns="http://www.w3.org/2000/svg" id={props.id}>
			<g className="grand-canyon-image">
	
			<path onClick={() => props.onFill(0)} fill={props.fillColors[0]} d="M0,857C0,571.37,0,285.74,0,0c399.9,0,799.86,0,1200,0c0,285.6,0,571.3,0,857C800.11,857,400.08,857,0,857
				z M1196.83,851.1c0.16-2.61,0.43-4.88,0.43-7.16c-0.03-78-0.12-156.01-0.13-234.01c-0.02-199.18-0.02-398.35,0.1-597.53
				c0-4.49-1.3-5.52-5.64-5.52C797.69,6.98,403.79,6.98,9.9,6.86c-4.99,0-5.97,1.55-5.96,6.21c0.11,277.02,0.1,554.04,0.1,831.06
				c0,6.84,0.01,6.84,6.65,6.84c295.05,0.04,590.09,0.08,885.14,0.12c98.52,0.01,197.03,0,295.55,0
				C1193.01,851.1,1194.66,851.1,1196.83,851.1z"/>
			<path d="M1196.83,851.1c-2.17,0-3.82,0-5.46,0c-98.52,0-197.03,0.01-295.55,0c-295.05-0.04-590.09-0.08-885.14-0.12
				c-6.65,0-6.65,0-6.65-6.84c0-277.02,0.01-554.04-0.1-831.06c0-4.66,0.97-6.21,5.96-6.21c393.9,0.12,787.79,0.12,1181.69,0.02
				c4.34,0,5.64,1.03,5.64,5.52c-0.12,199.18-0.12,398.35-0.1,597.53c0.01,78,0.1,156.01,0.13,234.01
				C1197.26,846.21,1196.99,848.49,1196.83,851.1z M578.42,512.18c-1.79-0.24-3.57-0.61-5.37-0.68c-4.43-0.18-5.83,3.09-5.7,6.43
				c0.04,1.08,3.5,2.32,5.56,2.92c1.23,0.36,2.77-0.4,3.49-0.53c0.74-3.23,1.31-5.74,1.87-8.25c1.47-0.6,2.95-1.19,4.42-1.79
				c-0.31-0.68-0.55-1.91-0.95-1.96c-2.68-0.38-5.4-0.51-8.1-0.72c-0.01,0.48-0.02,0.96-0.03,1.44
				C575.21,510.09,576.81,511.14,578.42,512.18z M682.05,280.01c0.18,1.52,0.36,3.04,0.73,6.07c3.47-7.27,6.28-13.15,9.64-20.2
				c-2.07,1-4.28,1.33-4.98,2.54c-2.14,3.73-3.71,7.8-5.5,11.73c0.67-4.62,1.34-9.24,2.06-14.19c-2.36-0.64-4.53,0.74-5.25,3.64
				c-1.66,6.68-3.54,13.04-12.74,15.38C677.62,287.11,678.72,286.7,682.05,280.01z M1087.71,172.32c1.75,1.35,3.5,2.71,5.48,4.24
				c2.09-7.81,6.54-15.67,4.18-24.82c-1.4,2.07-1.89,4.32-2.02,6.59c-0.34,5.95-2.4,10.87-7.74,14.02c1.93-3.77,3.91-7.51,5.77-11.32
				c1.29-2.64,0.9-5.31-2.06-6.08c-5.1-1.33-10.36-2.09-15.59-2.88c-2.86-0.43-4.49,1.09-4.3,4.02c0.27,4.3,0.5,8.62,1.19,12.86
				c0.59,3.57,2.89,5.5,6.78,4.81C1082.16,173.28,1084.92,172.81,1087.71,172.32z M409.95,618.98c-1.98-0.72-3.92-1.8-5.96-2.11
				c-6.4-0.95-12.9-1.36-19.26-2.51c-2.3-0.41-4.32-2.32-6.48-3.55c1.87-1.61,3.5-4.06,5.65-4.65c5.04-1.4,10.32-1.92,15.51-2.79
				c-14.14-2.15-39.21,3.8-44.06,10.6c0.55,0.35,1.06,0.77,1.64,1.04c16.12,7.54,33.19,8.21,50.51,6.84
				c0.85-0.07,1.56-1.95,2.34-2.98c2.88,0.45,5.76,0.9,8.65,1.35c0.12-0.46,0.24-0.92,0.35-1.37c-1.64-2-3.29-4-4.93-5.99
				c1.23,0.73,2.54,1.35,3.68,2.19c6.06,4.46,12.95,5.32,21.35,5.67c-3.03-5.58-5.41-9.95-8.18-15.05c6.57,0,13.31-0.04,20.04,0.02
				c2.99,0.03,6.08,0.79,8.95,0.25c3.11-0.58,6-2.27,8.99-3.48c-0.14-0.61-0.27-1.23-0.41-1.84c-6.16-2.32-11.84-6.11-19.23-3.93
				c-3.77,1.11-7.98,1.84-11.83,1.37c-5.52-0.67-12.35-0.39-15.2-6.3c-2.79-5.8-6.38-7.47-13.02-2.56c-3.48,2.57-7.7,4.39-11.84,5.77
				c-8.08,2.7-16.34,4.85-24.52,7.23c0.15,0.6,0.31,1.21,0.46,1.81c3.29-0.59,6.6-1.12,9.88-1.78c13.61-2.74,22.12,0.19,31.01,10.75
				c-1.91-0.93-3.99-1.62-5.71-2.82c-7.21-5.03-14.92-4.75-22.8-2.32c-1.68,0.52-3.14,1.75-6,3.4c3.48,0.85,5.32,1.47,7.2,1.72
				c6.39,0.86,12.83,1.4,19.17,2.52C407.4,615.74,408.6,617.77,409.95,618.98z M669.22,506.8c-2.69,1.45-5.39,2.9-8.08,4.35
				c2.7,0.83,5.58,1.12,8.18,0.41c2.32-0.64,6.33-2.83,6.17-3.58c-1-4.47-4.25-0.1-6.39-1.06c3.77-3,7.6-5.87,5.42-11.75
				c-1.55-4.16,1.06-9.3-2.69-14.24c-2.25,5.63-4.05,10.11-5.85,14.6c-0.44-2.75-0.11-5.21,0.59-7.56c0.67-2.26,1.72-4.41,2.62-6.67
				c-8.8-2.07-18.49,3.31-20.8,11.1c-2.78,9.37,0.9,17.89,3.47,27.61c2.05-0.99,3.73-1.8,5.41-2.61c-1.31-1.27-2.62-2.54-3.93-3.8
				c-0.59-0.56-1.19-1.11-2.53-2.34c3.15,0.35,5.26,0.58,7.37,0.81c0.08-0.36,0.15-0.72,0.23-1.07c-2.77-1.4-5.54-2.8-8.3-4.2
				c2.94-0.25,5.64,1.33,7.76,0.78c2.12-0.56,3.68-3.26,5.17-4.71c-0.08,1.04-0.22,2.77-0.39,4.99
				C665.11,507.45,667.17,507.12,669.22,506.8z M1063.15,485.93c3.34,2.37,5.75,6.78,11.11,4.73c0.59-0.23,2.56,1.28,2.78,2.24
				c1.79,7.81,9.35,7.45,14.46,11.55c0-6.91,0-12.76,0-18.97c-3.74,1.06-7.19,4.07-10.08-0.44c-0.2-0.31-1.21-0.18-1.84-0.14
				c-5.5,0.36-11.01,0.74-16.51,1.12c-1.52-0.66-3.41-2.25-4.49-1.81c-4.93,2-7.99,0.4-9.78-4.19c-11.66,3.5-14.13,2.48-15.03-3.49
				c7.6,7.61,14.06,0.05,21.77-1.15c-0.84,1.41-1.36,2.03-1.59,2.74c-0.42,1.28-0.66,2.62-0.98,3.94c1.52,0.02,3.12,0.35,4.53-0.03
				c1.68-0.45,3.19-1.51,4.74-2.29c4.42,9.89,7.5-1.05,11.32-0.24c2.65,10.38,6.88-0.7,10.3,0.73c0.24,1.49,0.48,3.01,0.73,4.53
				c0.49,0.21,0.99,0.42,1.48,0.64c1.1-1.71,2.21-3.42,3.31-5.13c-0.33-0.22-0.65-0.45-0.98-0.67c1.37-0.37,2.74-0.74,4.08-1.1
				c0-1.73,0-3.17,0-4.61c0.37-0.11,0.74-0.21,1.11-0.32c1.18,2.34,2.36,4.68,3.54,7.01c0.55,0.06,1.09,0.11,1.64,0.17
				c0.64-2.24,1.28-4.49,1.94-6.78c3.53,3.1,5.21,1.12,5.64-3.25c4.96,2.25,2.05,5.45,1.17,8.32c2.13-0.49,3.67-0.98,5.25-1.19
				c1.64-0.21,3.31-0.15,4.97-0.21c-0.26-1.84-0.47-3.69-0.8-5.52c-0.16-0.88-0.56-1.71-0.88-2.65c1.99,0.09,3.61,0.16,5.22,0.24
				c0.26-0.57,0.52-1.15,0.78-1.72c-2.59-2.1-5.18-4.19-7.82-6.33c3.13-0.66,6.21-1.31,9.28-1.96c-0.07-0.44-0.14-0.87-0.21-1.31
				c-2.42-1.17-4.85-2.33-7.27-3.5c0.14-0.42,0.28-0.83,0.42-1.25c1.96-0.4,3.93-0.79,6.58-1.33c-4.62-4.23-9.61-3.24-14.62-3.01
				c1.04-3.72-1.01-6.56-3.63-4.88c-6.1,3.91-10.95-1.46-16.59-1.08c-5.66,0.38-10.73,0.84-13.06,5.75c-1.66,3.5-4.37,3.6-7.29,3.98
				c-2.76,0.36-5.54,0.6-8.28,1.06c-3.82,0.65-7.26,0.02-8.72-3.87c-1.35-3.57-3.25-5.02-7.2-5.44c-2.68-0.29-6.36-2.48-7.25-4.74
				c-0.73-1.85,1.48-5.57,3.36-7.53c1.78-1.87,4.71-3,7.33-3.63c7.68-1.86,15.47-3.3,23.2-4.96c0.78-0.17,1.47-0.73,1.09-0.53
				c-0.98-3.04-1.58-4.89-2.4-7.43c-2.02,1.35-3.96,2.64-5.9,3.93c-2.58-4.03-5.83-4.81-8.14-1.2c-3.96,6.18-8.83,6.25-15.08,4.66
				c-5.17-1.32-6.24,0.07-5.91,4.43c-6.97,4.03-13.56,7.85-20.16,11.67c0.16,0.61,0.32,1.23,0.49,1.84c3.12,1.07,6.24,2.13,9.83,3.36
				c-2.13,0.58-3.94,0.85-5.55,1.57c-1.22,0.55-2.19,1.67-3.27,2.53c1,1,1.82,2.37,3.02,2.93c2.61,1.23,5.42,2.05,8.04,3
				c-0.92,4.83-12.19,0.49-7.13,9.21c-1.42,1.34-2.67,2.52-4.26,4.02c2.41,1.15,4.24,2.03,6.99,3.34c-2.16,1.16-3.67,1.98-5.59,3.01
				c9.2,5.03,9.34-2.88,12.24-6.9c4,9.69,7.7,18.67,11.93,28.91c4.52-7.56,9.96-5.47,15.15-4.56c-0.21-0.48-0.41-0.95-0.62-1.43
				C1057.74,488.87,1060.44,487.4,1063.15,485.93z M89.95,627.89c6.65,3.23,13.14,6.89,20,9.58c7.11,2.78,13.66,5.99,18.49,12.14
				c1.2,1.53,2.74,3.43,4.44,3.84c9.47,2.31,14.73,9.18,19.31,16.92c3.52,5.95,8.53,9.41,15.49,10.55c4.01,0.65,8.71,1.47,11.57,3.97
				c10.14,8.82,23.54,10.53,34.73,16.92c0.79,0.45,2.57-0.83,3.89-1.3c-0.97-1-1.86-2.77-2.92-2.89c-7.65-0.84-13.93-4.22-19.29-9.58
				c-2.35-2.35-4.65-4.76-7.11-7c-3.16-2.87-5.01-5.63-1.51-9.7c2.42-2.82,1.37-4.85-2.11-6.39c-2.57-1.13-5.06-2.98-6.88-5.12
				c-1.67-1.96-1.86-5.28-3.65-7.03c-4.26-4.16-9.04-7.81-13.7-11.55c-11.79-9.48-23.59-18.94-35.49-28.28
				c-1.75-1.38-3.96-2.4-6.12-2.99c-5.44-1.48-11.51-1.6-16.33-4.15c-5.42-2.88-10.81-0.3-16.43-2c-6.44-1.95-9.7-4.9-12.84-9.98
				c-1.29-2.09-4.1-3.62-6.55-4.54c-4.75-1.79-9.79-2.78-14.58-4.49c-3.19-1.14-6.15-2.96-9.57-4.65c0.12,1.04,0.01,1.62,0.25,1.95
				c6.45,8.87,12.8,17.81,19.47,26.51c3.04,3.97,7.07,6.12,12.54,5.78c2.27-0.14,5.23,1.37,6.9,3.08c3.08,3.14,5.45,6.99,8.11,10.54
				c-2.78-2.42-5.72-4.67-8.28-7.3c-2.45-2.51-4.57-5.22-10.13-2.44c6.39,4.07,11.97,7.63,17.55,11.19
				C89.45,628.93,89.7,628.41,89.95,627.89z M881.41,576.57c-1.08-1.19-2.15-2.39-2.73-3.03c-5.01,2.64-9.55,5.04-13.86,7.31
				c0.39,0.17,1.29,0.86,2.21,0.89c5.78,0.19,9.9,2.96,13.28,7.54c1.01,1.37,3.32,1.85,5.1,2.58c3.93,1.6,7.91,3.1,11.87,4.65
				c1.05,0.41,2.09,0.82,3.13,1.23c0.42-0.55,0.84-1.09,1.26-1.64c-2.88-2.39-5.77-4.78-8.65-7.17c2.86,2.06,5.79,4.03,8.56,6.21
				c2.76,2.18,5.32,4.61,8.05,6.84c4.47,3.66,9.27,6.96,13.45,10.91c6.28,5.93,12.15,12.29,18.17,18.44
				c3.48-3.14,6.58-5.94,10.27-9.27c-5.68-0.52-10.44,4.25-16.03-0.01c7.4-6.13,14.46-11.98,21.43-17.75
				c-6.12-6.26,2.08-8.38,2.54-12.5c-0.56-0.08-1.06-0.1-1.53-0.22c-2.26-0.62-4.52-1.27-6.78-1.91c1.09-2.08,1.88-4.39,3.32-6.19
				c7.6-9.5,15.37-18.87,23.39-28.66c-1.37-0.53-1.78-0.83-2.21-0.84c-9.39-0.29-18.89,0.58-27.6-4.5c-1.65-0.96-4.5,0.22-6.8,0.31
				c-3.86,0.15-7.72,0.26-11.58,0.3c-1.14,0.01-2.28-0.28-3.43-0.38c-6.04-0.51-12.26-0.28-18.07-1.71
				c-5.53-1.36-9.38-0.02-12.86,3.88c-3.33,3.72-6.33,7.78-9.97,11.16C881.17,566.92,878.84,570.93,881.41,576.57z M10.85,490.87
				c-5.81,3.89-5.33,9.48-4.24,16.6c7.96-3.64,15.62-6.46,22.57-10.51c7.35-4.28,14.62-6.97,23.19-5.26
				c4.71,0.94,9.47,2.06,14.24,2.19c11.49,0.31,22.99,0.16,34.48,0.05c9.74-0.1,16.32-7.08,15.71-16.83
				c-0.24-3.79,1.08-5.85,4.61-7.14c2.1-0.77,4.56-1.94,5.7-3.68c7.67-11.75,12.76-24.47,13.28-38.49
				c-5.32,1.17-10.64,2.79-16.08,3.43c-9.53,1.12-19.24,1.02-28.68,2.58c-9.11,1.5-18.01,4.36-26.93,6.88
				c-1.18,0.33-2.48,2.03-2.79,3.32c-1.35,5.6-2.36,11.28-3.67,17.81c3.23-1.31,6.56-1.99,9.09-3.8c9.44-6.75,18.6-13.89,27.89-20.84
				c1.33-1,2.84-1.76,4.26-2.63c0.3,0.53,0.6,1.06,0.89,1.59c-13.87,11.63-26.89,24.56-45.87,28.63c2.59,0.87,5.05,1.54,7.42,1.33
				c15.37-1.36,30.29-4.19,43.23-13.49c2.68-1.93,5.68-3.61,8.78-4.72c4.4-1.56,9.02-2.48,13.55-3.67c0.14,0.48,0.28,0.96,0.42,1.44
				c-0.56,0.24-1.12,0.48-1.67,0.73c-9.01,4.15-17.94,8.48-27.06,12.4c-13.85,5.95-28.1,10.07-43.48,9.17
				c-3.26-0.19-6.96,0.48-9.86,1.94c-4.86,2.46-9.06,6.17-13.83,8.83c-9.36,5.22-19.85,8.63-26.89,17.43
				c-0.07,0.09-0.55-0.15-1.2-0.34c1.07-1.66,2.09-3.23,3.11-4.81c2.84-2.25,5.44-4.95,8.57-6.66c11.36-6.22,22.9-12.11,34.37-18.12
				c-0.23-0.58-0.47-1.16-0.7-1.74c-2.41,0.79-4.9,1.37-7.2,2.39c-7.52,3.35-14.91,6.96-22.45,10.27
				C17.34,479.93,13.05,484.38,10.85,490.87z M984.16,196.99c-1.86-0.51-3.58-1.54-4.45,1.64c-1.49,5.44-3.51,10.73-5.21,15.8
				c1.38,0.69,3.77,1.46,5.61,2.91c7.73,6.07,15.27,12.39,22.96,18.52c1,0.8,2.39,1.4,3.65,1.54c9.01,0.99,18.02,1.89,27.05,2.69
				c2.64,0.23,5.32,0.04,7.98,0.04c0.03,0.61,0.06,1.21,0.09,1.82c-1.67,0.23-3.36,0.76-5,0.63c-8.73-0.68-17.45-1.5-26.17-2.31
				c-7.1-0.67-14.19-1.51-21.29-2.09c-7.71-0.63-15.2,0.52-22.51,3.45c4.07,1.15,8.13,1.69,12.2,1.9c3.53,0.18,7.74-0.75,5.42,5.57
				c3.05,0.59,5.63,1.46,8.24,1.54c15.65,0.48,31.31,0.75,46.96,1.18c3.33,0.09,6.34,0.14,7.09-4.17c0.13-0.74,1.24-1.64,2.07-1.89
				c5.8-1.7,11.66-3.2,17.46-4.88c0.85-0.25,2.1-1.02,2.18-1.69c0.85-6.3,5.8-7.32,10.63-8.91c3.72-1.23,6.77-3.73,7.98-7.74
				c2.68-8.89,4.05-10.22,13.92-12.94c1.27-0.35,2.57-0.59,3.02-0.7c1.03-2.88,1.85-5.17,2.68-7.47c-0.38,0.17-0.76,0.35-1.13,0.52
				c-1.16-2.66-2.28-5.34-3.48-7.98c-0.44-0.98-1.13-2.7-1.6-2.67c-4.71,0.33-9-0.4-12.82-3.43c-0.57-0.45-1.92,0.06-2.91,0.18
				c-2.12,0.27-4.25,0.88-6.36,0.82c-12.32-0.34-24.63-0.81-36.94-1.32c-1.26-0.05-2.5-0.68-3.75-1.04c0.09-0.44,0.17-0.89,0.26-1.33
				c16.9,0.37,33.86,3.28,50.81-0.15c-3.32-2.28-6.79-4.73-10.17-4.63c-8.58,0.25-17.11,1.72-25.68,2.54
				c-1.77,0.17-3.72-0.02-5.39-0.61c-3.9-1.37-7.65-3.18-11.53-4.61c-0.92-0.34-2.31-0.21-3.16,0.28c-0.56,0.32-0.98,1.85-0.7,2.51
				c1.64,3.86,3.4,7.67,5.35,11.38c0.46,0.88,1.73,1.51,2.76,1.9c7.98,3.09,16.22,4.18,24.82,4.08c7.16-0.08,14.33,1.14,21.5,1.79
				c-0.01,0.48-0.03,0.96-0.04,1.45c-0.58,0.18-1.16,0.48-1.75,0.53c-10.11,0.8-20.22,1.52-30.32,2.42c-1.2,0.11-2.31,1.16-3.46,1.78
				c0.91,0.97,1.87,2.8,2.74,2.76c4.99-0.23,6.23,2.62,6.61,6.81c0.31,3.42,1.38,6.05,5.64,6.97c3.98,0.86,4.43,5.15,1.88,8.29
				c-1.43-2.12-2.48-5.49-4.57-6.36c-3.67-1.53-5.12-3.8-5.71-7.33c-0.3-1.77-0.99-3.48-1.34-4.67c-15.9,5.71-31.51,0.34-46.72,2.45
				c2.69-1.27,5.49-1.97,8.3-2.01c9.66-0.12,19.32,0.01,28.98-0.03c1.25,0,2.5-0.4,3.74-0.62c-0.07-0.59-0.14-1.18-0.2-1.78
				c-15.22,0.56-30.45,1.12-45.75,1.68c2.57,7.97,5.11,15.82,7.64,23.66c-0.41,0.18-0.82,0.37-1.24,0.55
				c-0.84-1.32-1.86-2.55-2.48-3.96c-2.87-6.63-6.21-13.14-8.24-20.03c-1.72-5.85-5.15-8.84-10.51-10.84
				c-2.18-0.81-3.85-2.99-5.75-4.55c5.24,2.48,10.48,4.96,15.75,7.46c-1.95-4.81-3.86-9.97-9.29-12.29c-1.51-0.65-3.96-0.45-5.32,0.4
				C984.25,193.01,984.46,195.4,984.16,196.99z M906.09,480.93l0.05,0.04c-0.55-3.25-1.23-6.48-1.59-9.75
				c-0.31-2.77-1.41-6.34-0.15-8.21c3.63-5.41,3.43-10.41,1.94-16.47c-1.5-6.09-3.85-9.83-10.71-11.48c-0.9,2.79-1.6,5.7-2.77,8.39
				c-1.6,3.66-2.84,7.02,0.25,10.64c0.65,0.76-0.07,2.76-0.27,4.18c-0.2,1.44-0.64,2.85-0.79,4.29c-0.39,3.72-0.01,7.67-1.16,11.12
				c-2.07,6.26-2.09,12.33-0.67,18.63c0.5,2.21,1.22,4.81,0.52,6.77c-3.04,8.51-1.59,16.62,0.85,24.91
				c1.24,4.23,1.63,8.73,2.19,13.13c0.44,3.53,0.56,7.1,0.82,10.65c0.27,0.45,0.55,0.91,0.82,1.36c6.15-7.85,12.55-3.92,19.06-2.07
				c2.61,0.74,5.54,0.48,8.32,0.43c8.83-0.16,17.66-0.49,26.48-0.63c1.09-0.02,2.17,0.84,3.3,1.01c7.82,1.13,15.64,2.48,23.51,2.99
				c1.56,0.1,3.69-2.92,4.88-4.91c0.81-1.35,0.45-3.4,0.6-5.08c-1.21-0.54-2.22-0.99-3.52-1.57c3.26-4.58-0.3-7.31-2.62-10.09
				c-8.07-9.69-16.28-19.29-24.6-28.76c-2.82-3.21-5.71-6.78-9.35-8.75c-9.23-4.99-15.31-12.15-19.58-21.71
				c-2.3-5.15-6.86-9.28-10.7-14.26c-6.06,9.43-5.9,9.4-4.06,18.43C907.56,476.26,906.48,478.67,906.09,480.93z M1136.18,579.94
				c-1.18,0.55-2.67,0.81-3.48,1.7c-3.34,3.68-6.71,5.44-11.15,1.62c-0.38-0.32-2.68,0.55-2.81,1.14
				c-1.44,6.84,4.84,13.12,12.13,12.49c0.44-1.49,0.81-2.93,1.37-4.31c0.04-0.09,1.56,0.14,2.11,0.57c1.96,1.55,2.98,6.01,6.36,2.82
				c1.07-1.01-0.09-4.39-0.24-6.82c2.46,0.24,5.46,0.52,8.46,0.81c0.06-0.42,0.11-0.83,0.17-1.25c-1.26-1.32-2.51-2.64-4.53-4.77
				c2.75-0.56,4.39-0.89,5.69-1.15c0.49,2.17,0.63,3.86,1.25,5.36c1.21,2.91,0.04,4.11-2.73,4.26c-1.11,0.06-2.24-0.25-3.72-0.44
				c-0.22,5.68-3.4,7.23-8.38,6.36c-1.12-0.2-2.43-0.24-3.46,0.15c-6.06,2.26-10.96,0.44-15.44-3.82c-1.04-0.99-3.6-2.05-4.22-1.55
				c-2.84,2.3-5.24,5.14-8.54,8.55c-9.23,0.95-9.88,1.5-8.48,8.37c-2.03,0.89-4.09,1.79-6.37,2.79c0.53,1.41,0.91,2.44,1.29,3.47
				c-4.31,3.45-4.08,6.61,1.28,8.16c2.46,0.71,5.83-0.73,8.46-1.91c2.08-0.94,3.58-2.42,5.52,0.08c3.42,4.4,8.81,4.69,13.05,0.78
				c1.02-0.95,1.92-2.03,2.29-2.41c3,2.15,5.58,5.45,8.02,5.36c2.69-0.1,5.25-3.33,7.95-5.25c1.03,1.13,1.85,2.82,3.06,3.17
				c1.8,0.52,3.88,0.1,5.84,0.08c-0.41-1.67-0.59-3.44-1.3-4.97c-0.51-1.12-1.7-1.93-2.6-2.89c2.52-0.38,5.23-0.06,5.94-1.1
				c0.83-1.22-0.09-3.62-0.26-5.49c2.28,0.46,4.8,1.64,6.87,1.1c1.1-0.29,1.41-3.57,2.18-5.79c4.33,2.02,9.43,2.68,14.26-1.88
				c-2.58-1.73-4.89-3.28-8.35-5.6c3.17-0.24,4.74-0.36,6.31-0.48c0.1-0.53,0.2-1.07,0.31-1.6c-4.01-1.31-8.02-2.62-12.03-3.93
				c0.12-0.42,0.25-0.83,0.37-1.25c2.19,0.17,4.38,0.35,6.57,0.52c0.06-0.34,0.12-0.68,0.18-1.02c-2.46-0.8-4.92-1.59-7.39-2.39
				c2.32-0.73,4.38-0.31,6.46-0.16c6.41,0.44,4.84,8.24,9.93,10.09c0.73-0.22,1.87-0.57,3.48-1.06c-1.09,2.7-1.95,4.83-2.93,7.26
				c5.34,1.01,6.96-2.1,8.02-5.83c1.8,1.37,3.2,2.44,5.28,4.02c0.57-2.61,0.99-4.54,1.5-6.83c1.69,1.2,3.02,2.13,4.99,3.53
				c0-14.05,0-27.16,0-40.27c-0.21-0.57-0.43-1.14-0.64-1.71c-3.26,5.23-7.46,6.13-12.4,4.83c-1.7-0.45-4.41,0.92-5.35,0.04
				c-1.2-1.11-1.06-3.65-1.5-5.58c0.46-0.09,0.93-0.18,1.39-0.28c0.6,1.59,1.2,3.17,2.21,5.85c2.08-2.49,3.59-4.3,4.95-5.94
				c1.43,1.67,2.74,3.19,4.04,4.72c0.81-1.79,1.63-3.59,2.46-5.42c1.51,0.3,3.19,0.62,5.08,0.99c0-14.73,0-28.85,0-43.34
				c-2.4-0.42-4.65-0.82-6.92-1.22c0.12,2.46,0.21,4.14,0.3,5.82c-1.83-0.73-3.67-1.45-6.82-2.7c-0.4,2.81-0.78,5.49-1.18,8.35
				c-1.19,0.19-2.77,0.45-4.6,0.75c0.29,1.63,0.53,2.99,0.78,4.42c-7.48-0.16-8.94,0.86-10.03,6.73c2.17,1.45,4.86,2.5,6.37,4.46
				c1.47,1.91,2.73,4.93,2.34,7.15c-1.13,6.4-3.04,7.55-9.28,8.18c-1.18,2.54-1.92,4.63-3.15,6.39c-0.28,0.4-2.23-0.35-4.07-0.7
				c1.95-1.03,3.14-1.66,4.38-2.31c-1.4-2.23-2.58-4.11-3.89-6.19c4.15-1.86,8.55,3.39,12-1.53c-1.4-1.61-2.66-3.07-4.68-5.39
				c2.96,0.51,4.61,0.79,6.26,1.07c0.31-0.41,0.61-0.82,0.92-1.23c-1.63-1.22-4.61-2.37-4.67-3.66c-0.23-4.97-3.9-4.17-6.38-3.9
				c-3.98,0.44-7.82,2.04-11.78,2.88c-2.37,0.5-4.91,0.89-7.27,0.58c-5.77-0.76-7.17,0.42-6.34,6.15c-7.8,0.98-7.8,0.98-6.23,7.7
				c-1.74-0.89-3-2.12-4.15-2.02c-3.62,0.31-7.24,0.94-10.71,1.96c-0.46,0.14-0.06,3.22-0.06,5.36c-0.29,0.19-1.16,0.78-2.41,1.61
				c1.8,1.35,3.19,2.4,4.87,3.66c-7.4,7.68,3.76,4.28,3.74,7.31c-1.18,1.89-3.15,5.07-2.51,5.74c2.06,2.16,5.05,4.6,7.76,4.7
				c3.99,0.15,4.82-3.42,4.67-7.56c1.31,1.66,2.61,3.32,3.92,4.97L1136.18,579.94z M12.16,291.74c-1.52,0.97-3.05,1.93-4.57,2.9
				c0.05,0.19,0.1,0.39,0.16,0.58c4.88-1.15,9.76-2.29,14.64-3.44c-0.09-0.45-0.18-0.9-0.27-1.35c-3.36,0.47-6.72,0.95-10.08,1.42
				c3.99-2.77,7.93-4.93,13.22-3.05c3.06,1.09,6.62,1.33,9.9,1.15c4.95-0.27,10.19-2.66,14.73-1.63c9.03,2.05,17.47,0.26,26.15-1.11
				c2.79-0.44,5.75,0.13,8.63,0.24c-0.03,0.75-0.06,1.49-0.09,2.24c-11.13,0.3-22.27,0.59-33.4,0.92c-1.82,0.05-3.65,0.19-5.45,0.41
				c-4.92,0.6-9.84,1.18-14.74,1.93c-8.29,1.28-16.56,2.68-24.78,4.02c0,18.62,0,36.07,0,54.08c1.87-0.33,3.45-0.51,4.98-0.89
				c13.86-3.47,24.39-12.07,33.28-22.66c6.74-8.03,14.28-13.32,25.25-12.29c2.63,0.25,5.37,0.05,7.97-0.39
				c16.06-2.75,29.71-9.67,38.64-23.84c2.76-4.38,6.28-6.37,11.13-7.35c3.11-0.63,6.92-1.64,8.8-3.86
				c7.3-8.61,13.94-17.77,20.83-26.73c-0.36-0.55-0.73-1.1-1.09-1.65c-6.68,0.51-13.36,1.01-20.03,1.52l0.07,0.09
				c2.98-3.81,6.8-4.57,11.49-3.97c3.93,0.5,7.99-0.04,11.99-0.12c-0.04-0.59-0.07-1.18-0.11-1.77c-19.19,1.12-38.31-4.63-57.51-0.28
				c-2.97,0.67-5.88,1.92-8.59,3.34c-7.71,4.04-13.74,12.66-24.35,8.6c-0.86-0.33-2.04-0.17-2.96,0.12
				c-5.67,1.75-10.61,0.03-15.81-2.3c-5.98-2.67-12.12-5.35-19.16-2.56c-5.97,2.36-12.28,3.91-18.46,5.72
				c-2.14,0.63-4.35,0.97-6.19,1.37c0,10.91,0,21.12,0,31.37C8.61,292.23,10.39,291.99,12.16,291.74z M384.94,771.05
				c7.09-6.47,14.04-13.09,21.33-19.32c2.69-2.3,5.98-4.61,9.32-5.24c5.91-1.13,9.5-4.62,13.27-8.81
				c5.84-6.5,12.05-11.94,21.46-14.24c9.1-2.23,17.68-7.4,25.82-12.37c5.12-3.12,3.63-9.04-2.07-11.35
				c-0.91-0.37-1.91-0.58-2.88-0.71c-9.65-1.3-19.01-3.1-25.97-11.02c-1.01-1.14-4.36-1.45-5.89-0.68
				c-6.11,3.06-13.06,4.97-16.9,11.57c-0.9,1.55-2.97,3.13-4.7,3.39c-7.73,1.16-12.71,6.65-18.66,10.76
				c-6.33,4.37-10.32,10.01-12.92,17.06c-0.62,1.68-2.36,3.05-3.81,4.31c-1.36,1.18-2.96,2.1-4.54,2.98
				c-3.98,2.2-7.8,5.23-12.08,6.23c-5.57,1.3-11.54,0.82-17.31,1.4c-2.37,0.24-5.27,0.6-6.88,2.07c-4.82,4.39-9.42,8.76-16.31,9.7
				c-1.21,0.16-2.6,1.71-3.22,2.96c-3.66,7.35-7.18,14.77-10.6,22.23c-1.59,3.46-2.84,7.07-4.72,11.79
				c7.11-2.93,12.89-6.23,19.1-7.62c6.2-1.39,12.84-0.89,19.28-1.21c-2.76,0.39-5.54,0.67-8.27,1.19c-3.4,0.65-6.94,1.06-10.11,2.33
				c-11.53,4.62-22.85,9.77-34.43,14.26c-3.69,1.43-3.56,3-2.28,5.91c1.46,3.32,3.04,6.7,3.75,10.21c2.07,10.14,3.05,20.3-3.06,30.17
				c10.92,0,21.08,0.17,31.22-0.1c3.22-0.08,6.49-1.03,9.59-2.06c11.01-3.66,21.76-8.24,32.96-11.13
				c14.12-3.64,28.61-5.81,42.86-8.97c2.82-0.63,6.46-2.24,7.67-4.5c3.23-6.03,5.33-12.66,7.9-19.05c0.31-0.76,0.61-1.59,1.14-2.19
				c5.57-6.27,6.58-13.98,6.98-21.87c0.77-15.24-0.84-30.89,7.74-44.79c0.13-0.21-0.36-0.81-0.98-2.08c-2.97,3.1-5.41,6.2-8.39,8.63
				c-3.37,2.75-7.11,5.09-10.88,7.27c-2.79,1.62-6.21,2.27-8.77,4.13c-3.61,2.63-6.47,6.27-10.05,8.96
				C394.93,764.78,389.86,767.81,384.94,771.05z M504.4,205.73c-1.38-2.04-3.16-3.92-4.04-6.16c-1.24-3.17-2.8-4.38-5.97-2.61
				c-8.01,4.45-16.12,8.19-25.34,3.22c-0.13-0.07-0.33-0.03-0.49-0.04c-9.1-0.41-18.19-1.07-27.29-1.01
				c-1.72,0.01-4.48,2.7-4.86,4.55c-0.59,2.79,0.26,5.99,1,8.88c0.25,0.97,2.02,2.2,3.1,2.21c14.5,0.12,29,0.13,43.5-0.06
				c2.88-0.04,5.75-1.02,8.63-1.56c0.06,0.55,0.12,1.1,0.17,1.65c-17.64,3.92-35.7,1.54-53.84,2.23c0.64,5.24,2.22,8.2,7.81,7.89
				c7.15-0.4,14.33-0.14,21.5-0.16c2.51-0.01,5.02,0,7.52,0c0.02,0.54,0.03,1.07,0.05,1.61c-1.87,0.12-3.73,0.34-5.6,0.34
				c-9.33,0.02-18.68-0.32-27.99,0.14c-3.2,0.16-8.05,1.4-9.13,3.62c-4.24,8.67-13.73,7.08-20.34,12.5
				c6.89-13.4,13.31-25.89,19.81-38.53c-11.32,0-21.76-0.02-32.2,0.03c-0.97,0-2.18,0.15-2.85,0.73c-0.9,0.77-1.96,2.09-1.86,3.07
				c0.08,0.75,1.81,1.83,2.84,1.88c1.96,0.09,3.94-0.56,5.93-0.7c4.05-0.29,8.11-0.44,12.16-0.65c-7.77,3.9-16.74,1.14-24.07,5.68
				c0.08,0.69,0.15,1.37,0.23,2.06c8.73-0.56,17.46-1.11,26.18-1.67c0.06,0.56,0.11,1.12,0.17,1.68c-8.89,0.94-17.79,1.89-26.55,2.82
				c-0.31,2.4-0.42,4.56-0.89,6.64c-0.92,4.11,0.86,6.37,4.98,7.33c2.1,0.49,4.25,2.01,5.7,3.66c1.73,1.95,2.48,4.76,4.14,6.79
				c1.14,1.39,3.15,3,4.72,2.94c3.86-0.14,6.18,1.68,8.43,4.33c1.02,1.2,2.47,2.17,3.9,2.87c3.53,1.72,7.31,2.97,10.72,4.89
				c3.21,1.81,5.38,1.78,7.45-1.6c1.06-1.73,2.74-3.67,4.54-4.25c11.21-3.6,22.52-6.89,33.83-10.17c2.23-0.65,4.6-0.78,8.31-1.38
				c-2.55-1.07-3.7-1.36-4.62-1.98c-1.18-0.79-3.19-2.01-3.07-2.74c0.24-1.38,1.51-2.97,2.79-3.69c1.98-1.12,4.33-2.19,6.53-2.21
				c13.95-0.13,27.91-0.1,41.86,0.21c3.7,0.08,7.92,5.49,8.08,9.29c0.09,2.16,0.2,4.35,0.02,6.49c-0.39,4.58,1.43,7.64,5.69,9.41
				c1.38,0.57,2.56,1.64,4.79,3.1c-7.37,0.01-12.28-4.92-12.85-11.74c-0.22-2.65,0.27-5.38-0.15-7.97c-0.18-1.13-2.13-3.05-2.79-2.86
				c-3.77,1.06-8.69,1.03-8.61,7.05c0.01,0.94-0.82,2.35-1.65,2.76c-6.1,2.98-12.18,6.08-18.52,8.5c-4.7,1.79-9.77,2.62-14.97,3.95
				c4.98,6.36-3.89,5.41-3.67,9.41c6.8,3.83,2.44,9.97,2.81,15.39c-3.87-4.97,2.05-13.81-8.03-16.06c2.58-2.14,4.24-3.51,6.96-5.77
				c-4.17,0.31-6.92,0.7-9.67,0.67c-7.98-0.07-16.22-1.66-23.87-0.16c-10.98,2.15-21.43-0.91-32.12-0.75
				c-9.91,0.15-19.82,0.03-29.72,0.03c-0.08,0.71-0.16,1.41-0.24,2.12c4.85,1.01,9.66,2.27,14.56,2.93c2.92,0.4,6.24-0.93,8.9-0.04
				c3.01,1.01,6.35,3.08,7.98,5.68c2.36,3.75,3.15,8.47,4.93,12.63c1.39,3.26,3.71,4.48,7.64,3.51c8.56-2.13,10.68-0.67,12.82,8.07
				c9.74-4.78,21.39-2.91,30.62-9.85c-2.47-0.74-4.87-0.68-7.26-0.76c-2.96-0.09-6.42,0.63-8.76-0.65
				c-4.62-2.53-9.13-2.29-14.01-1.99c-6.15,0.38-12.35,0.09-19.09,0.09c0.82-0.64,1.16-1.13,1.53-1.16
				c9.46-0.76,18.92-1.49,28.38-2.17c0.79-0.06,1.83,0.05,2.38,0.52c4.29,3.68,9.2,2.96,14.25,2.47c6.3-0.61,8.75,1.2,10.95,7.07
				c1.46,3.92,2.93,7.84,4.4,11.76c0.67,0.15,1.33,0.29,2,0.44c3.26-5.04,6.59-10.03,9.75-15.14c1.79-2.89,3.65-5.44,7.52-3.8
				c10.61,4.5,19.21,0.83,26.42-6.9c7.02-7.51,14.08-15.08,15.81-25.98c0.23-1.43,2.92-3.37,4.61-3.53c7.78-0.71,15.6-0.9,23.41-1.23
				c10.07-0.43,20.27,0.17,28.75-7.24c2.16-1.89,5.86-2.58,8.91-2.78c7.61-0.5,15.28-0.79,22.89-0.44c16.28,0.74,32.57,3.03,48.8-0.3
				c5.63-1.16,14.39,3.31,14.88-7.79c0.01-0.13,0.2-0.25,0.31-0.38c7.51-1.97,15.12-3.06,22.77-3.76c3.48-0.32,2.93-2.42,2.77-4.57
				c-0.44-5.95-1.34-11.9-1.17-17.83c0.11-3.61-0.58-6.14-3.57-7.99c-3.48-2.15-4.94-5.25-4.47-9.29c0.28-2.47,0.88-4.98,0.68-7.42
				c-0.11-1.4-1.63-3.89-2.34-3.83c-3.5,0.3-7.45,0.46-10.28,2.21c-4.47,2.76-8.15,6.78-12.2,10.23c-2.75,2.35-5.52,4.15-9.47,2.33
				c-3.19-1.47-4.7,0.63-5.89,3.5c11.36,0.52,22.3,1.02,33.24,1.52c-12.01,3.77-24.3-1.53-36.31,0.74c0.01,0.62,0.03,1.24,0.04,1.86
				c3.79,0,7.58,0,11.36,0c-6.37,2.67-14.07-0.1-19.54,4.96c0.1,0.58,0.19,1.15,0.28,1.73c4.67,0,9.34,0,14.01,0
				c-0.01,0.59-0.02,1.18-0.04,1.77c-7.46,0.88-14.92,2.25-22.64-1.07c-2.5,2.65-4.89,5.61-7.75,8.02c-1.21,1.02-3.4,0.95-5.16,1.26
				c-1.87,0.34-3.76,0.54-5.64,0.81c0.12,0.63,0.23,1.27,0.35,1.9c11.4-1.13,22.81-2.26,34.21-3.39c0.05,0.5,0.1,0.99,0.16,1.49
				c-0.74,0.24-1.46,0.61-2.21,0.69c-10.73,1.25-21.47,2.38-32.17,3.77c-1.73,0.22-3.31,1.57-4.95,2.4
				c11.75,0.55,22.83,0.01,33.91-0.36c4.11-0.14,8.21,0.1,7.82-6.44c6.67,2.74,8.69-0.91,10.13-6.03c0.31-1.11,2.67-2.22,4.16-2.34
				c8.77-0.68,17.56-1.05,26.34-1.58c1.91-0.12,3.81-0.45,5.72-0.68c0.07,0.43,0.13,0.86,0.2,1.29c-1,0.37-1.99,1-3.01,1.06
				c-7.63,0.42-15.27,0.58-22.89,1.15c-3.65,0.27-7.96-0.08-9.3,4.87c-1.2,4.42-3.07,7.81-8.73,5.09c-1.09,4.84-0.98,9.88-6.58,12.19
				c0.71-2.32,1.42-4.64,1.83-5.96c-9.64,0-19.28-0.33-28.88,0.12c-5.8,0.27-12.55-0.71-15.91,6.27c-0.06,0.13-0.61,0.02-2.22,0.02
				c3.04-5.65,5.84-10.83,9.08-16.85c-2.68,0.2-4.58,0.36-6.48,0.48c-5.11,0.32-10.22,0.8-15.33,0.86c-4.89,0.06-9.8-0.11-14.67-0.58
				c-4.05-0.39-9.39,3.7-11.87-3.06c-0.1-0.28-1.24-0.22-1.9-0.25c-7.74-0.26-8.27,0.21-9.6,7.84c-0.43,2.46-0.6,5.1-1.67,7.27
				c-1.7,3.46-4.05,6.59-6.13,9.86c-0.46-0.31-0.92-0.62-1.38-0.94c2.02-3.83,4.54-7.49,5.92-11.54c1.33-3.9,1.49-8.19,2.16-12.31
				c-0.66-0.15-1.32-0.3-1.99-0.45c-5.44,9.31-7.3,20.55-14.59,28.94c-0.44-1.5-0.33-2.76,0.14-3.88c3.4-8.08,6.77-16.18,10.37-24.18
				c2.41-5.35,6.3-9.16,12.64-8.9c10.97,0.45,12.42,1.77,16.12-13.1c0.32-1.28,0.29-2.78,0.97-3.83c2.76-4.23,2.03-11.52,9.24-11.93
				c13.62-0.77,27.26-1.47,40.89-1.54c13.59-0.07,27.19,0.65,40.79,1.04c1.36,0.04,2.71,0.17,5.64,0.36
				c-4.93,3.41-7.73,6.39-4.16,11.55c6.23-5.4,12.25-10.63,19.07-16.54c-2.16-0.1-3.26-0.28-4.33-0.18
				c-8.44,0.73-16.96,2.64-25.3,2.05c-16.19-1.14-32.28-3.78-48.43-5.6c-8.43-0.95-16.97-1.06-25.35-2.3
				c-10.8-1.6-10.69-1.91-13.56,8.57c-0.9,3.28-2.2,6.46-3.32,9.68c-0.6-0.21-1.21-0.42-1.81-0.63c2.26-6.84,4.4-13.72,6.93-20.46
				c0.34-0.92,2.65-1.51,4.06-1.54c6.66-0.14,13.52,1.01,19.95-0.19c20.09-3.75,40.2-0.58,60.28-1.15c0.46-0.01,0.94,0.43,1.41,0.67
				c-0.05,0.55-0.09,1.09-0.14,1.64c-24.57-0.15-49.22-2.93-75.18,1.49c2.61,0.47,3.78,0.77,4.97,0.87
				c10.27,0.85,20.62,1.17,30.81,2.59c21.1,2.94,42.09,6.73,63.47,2.26c3.53-0.74,7.36-0.1,11.05-0.1c0.13,0.46,0.25,0.91,0.38,1.37
				c-2.05,1.53-4.11,3.05-6.81,5.06c16.02,2.96,29.61-7.13,44.74-4.05c-2.2,1.17-4.39,2.35-6.59,3.52c0.13,0.45,0.26,0.89,0.4,1.34
				c4.71-1.06,9.47-1.97,14.13-3.23c4.4-1.19,8.62-3.44,13.07-3.99c12.49-1.54,25.06-2.45,37.59-3.6c-2.18-1.91-4.85-3.73-7.68-4
				c-5.59-0.53-11.26-0.34-16.89-0.2c-8.63,0.22-17.25,0.82-25.88,0.96c-11.12,0.18-22.25-0.06-33.38,0.13
				c-3.91,0.07-7.81,0.84-11.71,1.29c-0.08-0.63-0.15-1.26-0.23-1.9c5.97-0.69,11.92-1.86,17.9-1.98
				c14.81-0.29,29.64,0.04,44.46-0.15c8.95-0.11,17.9-1.01,26.84-0.91c2.74,0.03,5.43,2.25,8.2,3.32c1.47,0.57,3.07,0.81,4.58,1.26
				c2.19,0.66,4.35,1.94,6.54,1.96c4.45,0.04,3.9,4.5,6.29,6.33c3.32,2.54,6.33,5.47,10.02,8.72c3.97-4.93,8.93-9.64,11.99-15.37
				c2.14-4.02,4.32-5.26,8.36-5.25c14.13,0.04,28.25,0,42.38-0.16c3.38-0.04,6.76-0.63,9.95-0.94c1.48,7.24,2.82,13.78,4.3,21
				c5.69-2.25,11.21,3.72,15.91-1.66c3.92,6.66,7.57,13.16,11.5,19.49c3.85,6.2,7.97,12.23,11.97,18.33
				c-0.41,0.29-0.82,0.58-1.24,0.86c-0.69-0.62-1.55-1.13-2.03-1.89c-5.43-8.57-11.02-17.04-16.1-25.81
				c-4.22-7.29-10.55-9.2-17.91-4.77c-0.83,0.5-1.85,0.92-2.81,0.97c-6.97,0.34-13.94,0.54-20.91,0.87
				c-6.92,0.32-13.84,0.72-20.75,1.14c-3.12,0.19-5.14-0.24-7.95-2.89c-2.96-2.79-8.98-2.34-13.69-3.19
				c-0.23-0.04-0.7,1.02-0.99,1.59c-1.1,2.14-2.17,4.28-3.29,6.51c5.02,3.71,10.2,7.55,15.39,11.38c-0.41,0.55-0.83,1.1-1.25,1.65
				c-9.34-7.05-18.69-14.09-28.12-21.21c0,0.66-0.08,1.57,0.01,2.46c1.1,10.07,11.25,13.63,15.51,21.44c0.2,0.36,0.9,0.4,1.31,0.69
				c4.34,3.07,6.71,7.41,8.99,13.3c-1.67-0.93-2.35-1.11-2.7-1.54c-5.04-6.25-10.01-12.55-15.07-18.78c-0.68-0.84-1.69-1.72-2.7-1.95
				c-3.7-0.83-4.96-3.56-5.76-6.76c-1.2-4.82-2.28-9.68-3.65-14.46c-0.84-2.92-3.2-4.11-5.76-2.51c-0.86,0.54-0.93,3.37-0.43,4.83
				c1.58,4.56,3.6,8.98,5.45,13.45c-0.56,0.25-1.12,0.49-1.68,0.74c-1.38-3.13-2.89-6.21-4.11-9.4c-2.96-7.73-4.13-8.07-10.75-2.71
				c0.37,0.34,0.71,0.72,1.11,0.99c4.81,3.24,11.2,4.87,12.09,12.12c0.07,0.55,1.53,1.27,2.38,1.32
				c9.39,0.54,10.51,10.12,16.18,14.75c-2.65-0.94-5.31-2.29-6.49-4.44c-2.52-4.63-5.95-7.23-11.11-8.19
				c-1.36-0.25-3.36-2.36-3.37-3.61c-0.01-3.82-2.49-5.09-5.15-6.57c-2.6-1.45-4.96-3.42-7.68-4.55c-2.36-0.98-5.07-1.1-7.62-1.6
				c-0.48,3.62-1.25,7.24-1.21,10.85c0.01,0.76,2.9,1.82,4.58,2.17c3.75,0.77,8.08,0.73,9.68,4.95c1.62,4.25,2.54,8.79,3.49,13.25
				c0.33,1.54-0.15,3.26-0.27,4.89c-2.39-3.6-3.93-7.15-5.23-10.79c-1.59-4.46-3.95-6.68-9.04-4.62c-1.08,0.44-4.22-1.02-4.35-1.9
				c-1.16-7.92-4.76-16.16,1.65-22.97c-4.44-0.42-9.1-1.13-13.77-1.24c-3.97-0.1-7.99,0.28-11.92,0.9c-1.12,0.18-2.91,2-2.82,2.92
				c0.44,4.74,1.4,9.44,2.16,14.04c2.68,0.58,6.5,0.34,7.7,1.99c1.1,1.52-0.62,5.08-1.13,7.93c0.5,0.08,1.13,0.21,1.77,0.28
				c3.12,0.35,7.94-0.44,9.05,1.23c2.83,4.26,6.44,3.68,10.3,4.14c2.83,0.34,4.39,2.11,2.38,5.65c-1.41-4.7-4.23-4.55-7.75-3.57
				c-1.37,0.38-4.3-0.21-4.48-0.89c-1.6-6.14-6.37-4.49-10.46-4.76c-1.56-0.1-3.07-0.81-4.61-1.24c0.61-1.4,1.16-2.82,1.84-4.18
				c0.47-0.96,1.11-1.85,1.49-2.48c-3.21-1.1-6.06-2.19-8.98-3.06c-3.19-0.95-6.44-1.68-9.66-2.54c-9.03-2.4-10.44-5.74-5.85-13.73
				c0.1-0.17-0.17-0.55-0.33-1c-5.78-0.24-11.58,4.39-17.93,0.45c0.95-1.37,1.62-2.34,2.81-4.06c-6.44,1.52-12.01,2.99-17.65,4.13
				c-7.28,1.46-7.14,1.36-7.8,8.83c-0.49,5.59,0.11,10.1,6.22,12.57c1.47,0.59,2.59,3.68,2.7,5.69c0.37,7.06,0.27,14.14,0.27,21.22
				c0,3.49,1.77,4.53,5.06,4.44c7.13-0.21,14.27-0.04,21.4-0.22c8.6-0.21,10.26,1.39,10.38,9.93c0.03,2.45,0.81,4.9,1.25,7.35
				c0.49,0.09,0.98,0.18,1.46,0.26c1.26-1.4,2.86-2.61,3.72-4.22c1.87-3.51,3.25-7.28,5.08-10.8c0.69-1.33,1.98-3.12,3.19-3.28
				c12.17-1.68,24.37-3.1,36.57-4.48c1.43-0.16,2.93,0.44,4.4,0.63c4.11,0.52,8.22,1.42,12.32,1.42c7.58,0,15.15-0.73,22.73-0.8
				c6.47-0.06,9.08,3.61,8.29,10.05c-0.37,3.01,0.23,6.14,0.4,9.21c0.49-0.01,0.99-0.02,1.48-0.03c3.18-5.38,6.46-10.71,9.47-16.18
				c0.54-0.99,0.53-2.87-0.07-3.8c-2.94-4.58,0.64-6.59,3.48-8.22c2.18-1.24,5.08-1.63,7.67-1.65c19.1-0.14,38.2-0.13,57.31-0.01
				c1.82,0.01,3.59,1.35,5.45,1.78c4,0.92,8.37,3,12,2.14c4.81-1.13,7.72-5.39,7.47-11.06c-0.19-4.26,1.69-7.38,5.29-10.04
				c2.43-1.8,4.9-4.89,5.35-7.73c0.96-6,3.5-9.5,9.71-9.73c0.61-5.94,1.19-11.61,1.77-17.33c-7.34-1.25-11.05,0.09-13.12,4.12
				c-2.48,4.83-5.18,9.58-8.25,14.05c-4.74,6.9-9.88,13.53-14.85,20.28c-0.4-0.16-0.81-0.33-1.21-0.49c0.37-1.47,0.35-3.22,1.18-4.35
				c4.8-6.53,9.92-12.83,14.7-19.38c3.09-4.24,7.82-7.97,5.58-14.25c5.31-2.54,10.46-5,15.59-7.45c-0.05-4.1-2.96-4.28-5.9-4.2
				c-9.65,0.27-19.3,0.75-28.96,0.91c-32.47,0.52-64.93,1.03-97.4,1.26c-5.09,0.04-8.18,1.33-9.46,6.5
				c-0.56,2.24-2.08,4.24-2.74,5.52c-3.11-2.21-5.54-4-8.03-5.69c-1.5-1.01-3.02-2.08-4.68-2.74c-2.76-1.09-5.66-2.7-8.47-2.63
				c-15.15,0.37-30.29,1.77-45.43,1.72c-26.32-0.09-52.64-1.19-78.97-1.48c-13.81-0.15-27.63,0.83-41.44,0.75
				c-31.31-0.19-62.62-0.82-93.93-1.07c-16.16-0.13-32.32,0.1-48.48,0.32c-15.93,0.22-31.85,0.64-47.55,0.97
				c-0.98,3.63-0.63,5.53,2.8,5.48c9.3-0.14,18.66,0.36,27.5-3.5c1.34-0.58,3.18-1.42,4.27-0.95c7.61,3.35,15.56-3.78,23.1,0.9
				c0.75,0.47,2.31-0.04,3.36-0.45c4.48-1.77,6.92,0.15,9.23,4c2.96,4.94,6.7,9.41,10.07,14.1c0.65,0.9,1.17,1.89,1.76,2.83
				c-0.41,0.28-0.82,0.57-1.23,0.85c-5.17-4.74-10.04-9.78-12.69-16.55c-1.04-2.66-2.5-3.64-5.32-3.01
				c-7.3,1.62-14.85-1.92-22.12,0.96c-0.26,0.1-0.65-0.17-0.98-0.14c-3.51,0.26-7.04,0.43-10.54,0.85c-4.07,0.5-8.1,1.42-12.18,1.82
				c-4.12,0.4-8.29,0.21-12.4,0.63c-1.27,0.13-2.96,1.16-3.5,2.26c-1.5,3.02-2.49,6.29-4.15,10.71c2.83-1.42,4.77-2.15,6.44-3.29
				c2.1-1.43,3.73-3.8,5.99-4.69c3.99-1.56,8.25-2.64,12.49-3.32c7.81-1.26,15.69-2.09,23.54-3.09c0.08,0.59,0.16,1.18,0.24,1.77
				c-6.46,0.81-12.99,1.26-19.37,2.52c-6.4,1.26-13.13,2-18.54,6.07c0.11,0.46,0.22,0.92,0.33,1.38c7.12-0.74,14.24-1.49,21.36-2.23
				c-0.57,1.47-1.27,1.97-2.02,2.07c-6.59,0.87-13.18,1.66-19.78,2.48c-10.14,1.25-19.65,3.29-21.25,15.98
				c-0.13,1.01-1.3,1.88-1.98,2.81c-0.47-0.23-0.94-0.45-1.41-0.68c1.45-4.88,0.44-10.77,5.5-14.21c6.5-4.42,7.4-11.68,10.35-18.33
				c-3.53,0.24-6.54,1.3-9.63,2.03c-10.7,2.53-21.41,2.55-32.08-0.3c-1.11-0.3-2.29-0.55-3.43-0.51c-3,0.12-4.07,1.41-3.09,4.58
				c0.77,2.5,0.77,5.25,1.29,9.35c1.19,0,3.64,0.14,6.06-0.03c8.59-0.58,17.17-1.33,25.76-1.86c1.27-0.08,2.59,0.62,5.22,1.31
				c-2.21,1.24-3.09,2.09-4.07,2.23c-3.77,0.54-7.58,1.11-11.38,1.19c-8.49,0.17-17,0.22-25.49-0.01c-3.91-0.11-5.48,1.58-5.69,5.17
				c-0.22,3.95,2.83,3.66,5.16,3.65c5.99-0.04,11.97-0.48,17.96-0.63c4.74-0.11,9.48-0.02,14.22-0.02c0,0.46,0.01,0.91,0.01,1.37
				c-15.1,0.98-30.18,2.55-45.36,0.06c-0.62,2.93-1.24,5.85-1.89,8.91c9.48,0,19.06,0.03,28.64-0.02c1.98-0.01,4.97-1.24,5.76-0.35
				c2.91,3.23,5.67,0.71,8.46,0.59c4.3-0.19,8.62-0.3,12.91-0.63c5.58-0.43,11.16-0.97,16.72-1.59c4.28-0.48,8.52-1.3,12.81-1.64
				c1.28-0.1,3.43,0.52,3.84,1.42c2.29,5.11,4.18,10.41,6.2,15.64L504.4,205.73z M842.86,131.09c3.72-1.1,7.38-2.5,11.18-3.22
				c4.23-0.8,8.58-0.99,12.87-1.44c-0.03-0.25-0.06-0.5-0.08-0.74c-2.45-0.65-4.9-1.29-7.35-1.94c0.04-0.53,0.07-1.07,0.11-1.6
				c3.13-0.85,6.26-1.7,10.1-2.74c-1.18-0.82-1.56-1.3-1.93-1.3c-6.08-0.03-12.34,1.45-17.85-2.84c-0.91-0.71-2.93-0.24-4.37,0.03
				c-3.83,0.73-7.63,1.66-11.48,2.52c0.18-5.93-0.76-6.81-6.78-6.79c-2.03,0.01-4.06-0.18-6.36-0.29c0.96-2.11,1.58-3.48,2.21-4.86
				c-0.38-0.34-0.76-0.68-1.15-1.02c-1.51,1.32-3.02,2.65-4.48,3.92c-1.8-3.25-5.03-3.63-8.67-3.15c-4.88,0.65-3.16,4.54-3.37,8.85
				c-3.35-5.44-7.28-7.42-11.74-4.9c-1.29,0.73-0.95,4.36-1.33,6.48c-4.11-2.42-4.85-7.08-0.56-8.44c6.63-2.11,13.65-3.08,20.57-4.16
				c2.08-0.33,5.28,1.67,5.32-1.95c0.01-1.43-3.14-4.12-4.83-4.1c-3.49,0.04-6.94,1.7-10.47,2.29c-1.3,0.22-3.84-0.42-4.04-1.14
				c-1.68-6.27-3.52-7.44-9.76-5.23c-2.35,0.83-4.57,2.04-7.52,3.39c0.51-5.57,4.42-12.24-2.73-13.93c-6.26-1.48-8.2,4.67-8.94,10.04
				c-1.91-2.66-3.19-5.23-5.17-7.02c-3.94-3.57-6.83-2.43-7.9,2.69c-0.5,2.39-1.1,4.76-1.57,6.81c-2.68,0.92-5.18,1.79-7.94,2.74
				c-3.14-2.03-6.64-4.77-9.04-0.1c-4.36-0.24-8.11-0.74-11.82-0.5c-1.34,0.09-2.57,1.93-3.84,2.97c1.39,0.84,2.72,2.28,4.18,2.41
				c6.81,0.63,7.54,1.71,4.52,7.47c6.19,2.51,15.44-2.05,19.2,8.16c11.63,0.58,24.11,1.2,36.59,1.82c0.03,0.63,0.05,1.26,0.08,1.89
				c-4.26,1.12-8.52,2.24-12.78,3.35c-0.03,0.72-0.07,1.44-0.1,2.16c5.62-0.65,10.27,1.38,15.11,3.32c3.18,1.28,7.72-1,11.68-1.43
				c3.11-0.34,6.3-0.63,9.4-0.36c5.02,0.44,9.99,1.73,15.01,1.99c5.96,0.3,11.96-0.11,17.95-0.21c-2.13,0.67-4.23,1.77-6.4,1.93
				c-4.14,0.31-8.33,0.08-12.49,0.08c-3.99,0-8.56,1.25-11.83-0.29c-5.64-2.65-10.64-1.13-15.96-0.23c-1.64,0.28-3.38,0.68-4.96,0.38
				c-6.34-1.18-12.66-2.51-18.89-4.14c-0.55-0.14-0.81-2.94-0.49-4.35c0.21-0.94,1.67-1.61,3.45-3.18c-7.63,0-14.07,0.1-20.5-0.06
				c-2.04-0.05-4.69-0.13-5.98-1.34c-2.99-2.84-5.57-1.17-8.18-0.06c-3.91,1.67-7.69,3.67-10.41,4.99c-3.01,1.92-5.2,4.46-6.86,4.15
				c-3.52-0.65-5.39,0.53-7.68,2.84c-6.48,6.55-16.87,5.9-22.96-1.09c-0.5-0.58-1.35-1.36-1.94-1.28c-5.94,0.76-11.85,1.67-17.8,2.54
				c1.53,8.09-5.09,10.62-15.94,6.96c1.99-1.72,3.35-3.83,6.7-1.37c1.49,1.09,4.86-0.36,8.2-0.76c-2.62-3.14-4.21-5.06-6.17-7.41
				c8.5-0.3,8.5-0.3,10.41-3.48c8.37,3.85,11.54,3.53,17.29-2.15c0.93,6.39,3.53,10.91,10.73,10.19c6.4-0.64,12.13-2.09,12.48-9.59
				c2.77,1,5.23,1.88,8.45,3.04c-0.59-2.87-0.88-4.25-1.2-5.78c4.86-1.58,11.16,2.21,14.15-4.43c0.2-0.44,1.89-0.17,2.87-0.32
				c1.2-0.18,2.38-0.47,3.57-0.71c0-0.52,0-1.03,0-1.55c-7.51-0.61-15.01-1.22-22.52-1.83c-0.02-0.36-0.03-0.73-0.05-1.09
				c2.73-1.67,5.46-3.35,8.18-5.02c-0.17-0.35-0.34-0.71-0.51-1.06c-5.41-0.42-10.83-0.85-16.85-1.32c3.23-1.68,6.92-2.37,7.67-4.24
				c1.86-4.62,5.07-5.03,9.11-5.15c6.76-0.2,13.53-0.61,20.27-1.14c0.97-0.08,1.85-1.3,2.77-1.99c-0.93-0.79-1.81-2.16-2.81-2.27
				c-2.28-0.23-4.66-0.04-6.95,0.33c-4.43,0.72-8.79,1.92-13.23,2.41c-1.95,0.22-5.48-0.33-5.84-1.38
				c-1.96-5.67-6.31-3.92-10.27-4.35c-4.21-0.46-8.52-1.34-12.41-2.94c-3.24-1.33-3.28-5.28-0.26-5.97
				c4.52-1.03,7.98-3.87,11.99-5.42c3.8-1.47,8.48-0.53,12.75-0.92c1.31-0.12,2.54-1.17,3.9-1.83c-6.9-4.91-13.24-4.58-19.92-2.85
				c-2.18,0.57-5.79,0.34-7.03-1.05c-3.13-3.52-6.52-2.47-10.04-2c-3.95,0.53-7.88,1.4-11.84,1.64c-2.26,0.14-4.58-0.69-6.87-1.08
				c-0.09-0.49-0.18-0.98-0.27-1.47c1.76-1.36,3.51-2.73,5.27-4.09c-3.89-0.75-7.47-0.35-11.05-0.16c-10.7,0.57-21.4,1.46-32.1,1.45
				c-11.74-0.01-22.98-3.14-34.05-7c-18.41-6.42-33.25-16.62-39.69-36.12c-0.82-2.48-1.55-4.99-2.29-7.36
				c-184.57,0-368.87,0-553.45,0c0,47.06,0,93.8,0,140.73c8.55,0,16.83,0.01,25.1,0c40.17-0.06,80.34-0.19,120.52-0.16
				c18,0.01,36,0.64,53.99,0.45c18.99-0.19,37.97-1.37,56.96-1.46c23.83-0.11,47.66,0.85,71.48,0.63
				c32.97-0.31,65.93-1.41,98.91-1.91c8.47-0.13,16.97,1.26,25.44,1.09c29.96-0.59,59.91-1.83,89.88-2.28
				c26.32-0.39,52.65-0.16,78.98,0c41.32,0.24,82.64,0.71,123.96,0.96c21.8,0.13,43.62,0.48,65.41-0.16
				c34.12-1,68.22-2.96,102.34-4.1c15.78-0.53,31.6,0.01,47.4-0.13c30.31-0.27,60.62-1.01,90.93-0.9c21.3,0.08,42.59,1.52,63.89,1.84
				c23.47,0.34,46.95,0.09,70.43,0.08c0.95,0,1.9-0.2,2.82-0.3c0-45.03,0-89.73,0-134.47c-128.26,0-256.08,0-383.87,0
				c-0.46,4.42-0.48,4.28-4.53,4.84c-1.67,0.23-3.89,0.74-4.74,1.93c-3.43,4.82-7.93,4.52-12.9,3.73c-2.39-0.38-4.92,0.16-7.39,0.2
				c-4.14,0.06-9.7,1.7-12.09-0.34c-3.48-2.96-5.97-3.03-9.64-1.97c-2.04,0.59-4.31,0.9-6.39,0.65c-2.92-0.34-4.5-2.1-3.33-5.29
				c0.44-1.19,0.91-2.37,1.42-3.71c-11.31,0-22.26,0-31.89,0c-5.03,10.35-9.72,19.99-14.29,29.4c1.7,1.13,4.22,2.8,6.73,4.47
				c7.72-10.98,10.94-11.65,18.28-3.74c1.29-0.49,2.76-1.57,4.04-1.39c2.19,0.3,5.41,0.83,6.14,2.29c1.5,2.99,3.01,3.05,5.88,2.63
				c3.96-0.58,8.84-0.59,10.57,3.56c1.5,3.59,3.49,3.93,6.41,3.64c8.09-0.8,16.16-1.84,24.26-2.48c3.41-0.27,6.89,0.16,10.34,0.4
				c5.46,0.37,2.47,4.43,3.42,6.9c1.81,4.68-0.59,5.39-3.56,6.25c-4.72,1.37-9.53,2.44-14.88,3.79c1.26,1.49,2.36,2.78,3.46,4.08
				c-2.26-1.37-4.51-2.77-6.78-4.11c-1.74-1.03-3.42-2.63-5.27-2.87c-4.97-0.64-8.58,1.58-9.98,6.24c-1.99-1.18-3.67-2.82-5.55-3.11
				c-2.04-0.32-4.27,0.61-6.42,1.01c0.64-3.75,2.64-5.15,6.44-3.39c3.64,1.69,6.09,0.78,8.91-1.49c3.65-2.93,7.6-2.76,11.5-0.11
				c1.02,0.69,2.39,1.51,3.47,1.37c3.93-0.54,7.84-1.3,11.68-2.3c1.87-0.49,3.54-1.69,5.31-2.58c-0.11-0.49-0.21-0.99-0.32-1.48
				c-4.42-0.36-8.84-0.71-13.26-1.07c-0.04-0.37-0.08-0.74-0.11-1.11c3.8-1.01,7.59-2.02,11.39-3.03c-0.07-0.61-0.13-1.22-0.2-1.84
				c-15.16,1.04-30.32,2.09-45.49,3.13c-0.09-0.53-0.19-1.06-0.28-1.59c1.2-0.94,2.41-1.88,3.8-2.96c-4.98-5.42-10.25-1.93-14.2-1.14
				c-1.91-2.74-3.36-6.64-4.98-6.72c-2.28-0.1-4.69,2.64-6.13,3.57c-2.15-2.3-4.21-6.49-6.48-6.6c-8.38-0.42-8.71,6.98-10.07,14.39
				c-1.17-2.66-1.63-3.86-2.21-5c-2.53-4.91-7.53-7.17-12.99-5.91c-4.4,1.02-6.5,3.55-4.52,9.33c-5.55,0.27-10.3,0.42-15.03,0.81
				c-0.98,0.08-1.85,1.12-2.83,1.6c-1.42,0.7-2.85,1.71-4.36,1.87c-6.63,0.69-13.29,1.12-19.94,1.65c0.03,0.63,0.06,1.26,0.09,1.89
				c2.38,0,4.77,0.12,7.14-0.02c7.47-0.45,14.92-1.12,22.39-1.42c1.81-0.07,3.67,0.9,5.5,1.4c-0.05,0.46-0.1,0.93-0.16,1.39
				c-2.27,1.06-4.54,2.13-6.82,3.19c6.45,0.4,12.64-0.3,18.85-0.83c1.72-0.15,4.68-0.38,5.02,0.37c1.96,4.28,5.16,2.94,8.45,2.81
				c5.06-0.2,10.09-3.38,15.37,0.41c1.39,1,5.29-0.2,7.19-1.57c2.21-1.58,3.66-4.27,6.55-1.53c2.46,2.34,4.21,5.06,3.09,8.91
				c-2.09-2.85-3.79-5.17-5.49-7.49c-0.92,1.67-1.84,3.35-2.63,4.8c-1.31,0.47-3.81,0.87-3.85,1.46c-0.49,6.71-4.66,4.53-8.62,4.17
				c-4.45-0.4-9.93-2.34-11.44,4.12c-3.78,0.64-7.07,1.2-10.36,1.76c4.25,4.23,8.97,4.89,14.08,4.98c2.84,0.05,6.58,0.79,8.23,2.69
				c2.08,2.39,3.88,3.3,6.72,2.91c7.2-0.99,14.42-1.9,21.59-3.09c1.26-0.21,2.52-1.47,3.44-2.54c3.25-3.75,5.5-4.03,9.2-0.87
				c1.11,0.94,2.19,1.92,3.49,3.05c2.79-4.35,6.87-8.06,11.07-5.59c3.25,1.91,4.52,7.18,6.95,11.44c6.02-3.54,12.09-3.46,15.66,4.79
				c2.26-0.59,4.15-1.18,6.09-1.56c6.54-1.3,9.44,0.34,12.2,6.54c0.33,0.75,1.41,1.75,2.05,1.68c4.47-0.45,4.75,2.27,4.19,5.41
				c3.9,1.55,9.9-1.57,10.28,4.71c7.32,0,14.39,0,21.46,0c-0.58,0.64-1.15,1.27-2.11,2.33c5.91,1.11,12.14-2.12,16.84,3.27
				c-1.18,1.33-2.1,2.36-3,3.36c2.2,1.03,4.24,1.99,6.28,2.95c-0.1,0.43-0.2,0.85-0.3,1.28C864.91,128.4,853.88,129.75,842.86,131.09
				z M334.41,848.56c287.63,0,574.05,0,860.33,0c0-48.03,0-95.6,0-143.55c-2.88,0-5.37,0.09-7.85-0.02
				c-10.63-0.44-21.3-0.48-31.88-1.53c-12.5-1.24-24.57-1.87-36.57,3.51c-10.61,4.76-21.85,7.74-33.84,7.2
				c-8.62-0.39-17.29,0.44-25.93,0.2c-9.76-0.27-19.55-0.2-29.01-3.69c-12.4-4.58-24.32-3-35.06,4.84
				c-8.59,6.27-18.35,8.08-28.63,8.68c-14.76,0.87-29.5,2.08-44.26,2.93c-10.68,0.61-20.43,3.63-29.73,8.98
				c-2.78,1.6-6.88,0.88-10.38,1.24c-2.4,0.25-5.14-0.13-7.14,0.9c-11.76,6.04-24.29,7.1-37.19,6.71c-9.91-0.3-19.74-1.18-29.5,2.76
				c-6.99,2.82-14.71,4.31-22.25,5.16c-17.49,1.96-35.06,3.26-52.62,4.66c-20.23,1.61-40.48,3.02-60.72,4.57
				c-6.3,0.48-12.6,1.01-18.88,1.72c-13.85,1.58-27.66,3.43-41.53,4.88c-3.81,0.4-4.91,1.53-4.05,5.35c0.42,1.86,0.04,5.22-1.13,5.89
				c-4.28,2.45-8.9,4.67-13.66,5.86c-13.5,3.37-27.11,6.27-40.72,9.18c-3.03,0.65-6.36,1.26-9.3,0.65
				c-4.39-0.91-7.55,0.55-11.15,2.67c-4.33,2.55-9.07,4.91-13.94,5.93c-5.8,1.21-11.96,0.55-17.87,1.36
				c-7.02,0.96-14.31,1.64-20.81,4.18c-11.5,4.48-22.93,8.62-35.28,10.13c-6.86,0.84-13.64,2.49-20.37,4.14
				c-14.63,3.58-29.07,8.03-43.82,10.95C364.48,838.02,349.62,841.72,334.41,848.56z M6,848.73c90.32,0,180.06,0,269.72,0
				c-0.57-6.72-1.02-13.18-1.75-19.6c-0.2-1.71-0.99-3.55-2.03-4.94c-5.29-7.05-9.47-14.3-6.19-23.59c0.24-0.69-0.32-1.63-0.19-2.41
				c0.41-2.43,0.88-4.86,1.51-7.24c0.77-2.95,2.61-5.91,2.39-8.75c-0.3-3.87-1.87-7.71-3.32-11.39c-2.04-5.18-2.84-9.51,2.64-13.55
				c4.61-3.4,3.26-10.43-1.86-13.09c-2.8-1.46-6.16-2.35-8.27-4.49c-2.92-2.96-5.79-5.14-9.98-5.86c-1.25-0.21-3.34-2.91-3.06-3.46
				c2.45-4.79-1.25-4.96-4.05-6.39c-5.42-2.77-10.81-5.71-15.85-9.12c-4.7-3.19-8.75-7.33-13.43-10.57
				c-3.39-2.35-7.18-4.27-11.04-5.74c-8.95-3.42-18.21-6.06-27.02-9.78c-6.46-2.73-13.79-5.42-18.26-10.37
				c-7.63-8.44-15.77-14.51-27.16-15.97c-8.45-1.08-15.54-4.58-20.2-12.14c-5.11-8.29-12.28-14.53-20.76-19.03
				c-15.26-8.11-26.82-19.92-36.31-34.13c-4.39-6.57-9.31-12.78-14.34-19.63c-5.63,6.89-12.87,9.18-20.28,11.32
				c-6.15,1.78-10.92,8.61-10.92,14.97c0,79.8,0,159.61,0,239.41C6,844.85,6,846.5,6,848.73z M642.15,721.84
				c-7.07-4.31-13.34-8.15-19.64-11.94c-0.35-0.21-1.01-0.07-1.46,0.1c-4.83,1.78-7.19-1.12-9.8-4.54
				c-2.97-3.9-6.22-7.83-10.09-10.77c-7.91-6.02-15.11-12.31-19.36-21.69c-1.04-2.29-4.71-4.15-7.48-4.73
				c-5.7-1.19-10.48-2.76-12.42-9.05c-0.6-1.94-2.15-3.9-3.79-5.16c-9.54-7.34-12.22-17.67-13.62-28.89c-1.12-9-2.38-18.09-4.8-26.8
				c-3.75-13.46-8.66-26.66-7.81-40.92c0.64-10.77,2.04-21.49,2.74-32.26c0.28-4.26-0.4-8.59-0.62-12.47
				c-10.6,3.39-20.01,6.41-29.88,9.57c0,0.65,0,2.06,0,4.52c-5.33,0-10.4,0.05-15.47-0.03c-2.15-0.03-4.3-0.61-6.43-0.48
				c-4.59,0.28-5.81,2.47-4.17,6.79c0.46,1.22,1.24,2.86,0.8,3.8c-3.15,6.66,5.09,11.43,2.79,17.84c5.83,6.33,2.76,13.75,2.88,20.89
				c0.07,4.07,1.05,8.82,3.37,11.98c3.56,4.82,4.99,9.68,4.34,15.34c-0.31,2.78,0.53,6.93-3.56,6.89c-1.3-0.01-2.54-4.5-3.83-6.93
				c-0.11-0.2-0.36-0.33-0.21-0.2c-1.41,5.07-2.87,10.23-4.28,15.42c-0.44,1.6-0.87,3.21-1.09,4.85
				c-1.69,12.62-8.04,19.11-20.71,21.1c-5.27,0.83-9.52,5.14-10.53,10.71c1.67,0.42,3.34,0.83,6.77,1.69
				c-4.17,3.74-7.23,6.48-10.46,9.38c3.68,2.47,2.07,4.12-0.74,6.19c-4.46,3.28-4.1,7.8,0.35,10.86c1.76,1.21,3.55,2.56,4.84,4.23
				c3.48,4.5,8.16,6.74,13.52,7.86c3.26,0.68,6.69,0.79,9.79,1.87c3.02,1.06,6.45,2.43,8.43,4.75c4.98,5.83,2.46,12.53-5.84,15.95
				c-9.82,4.04-19.81,7.67-29.7,11.54c-1.05,0.41-2.43,1-2.83,1.88c-1.83,4.12-4.22,8.25-4.85,12.6
				c-1.51,10.48-2.12,21.09-3.16,31.64c-0.71,7.24-0.97,14.43-4.63,21.22c-4.41,8.17-7.83,16.88-11.91,25.87
				c1.22-0.18,1.98-0.25,2.73-0.41c8.11-1.65,16.15-4.13,24.35-4.8c19.52-1.6,30.32-14.82,40.73-28.89c0.18-0.25,0.37-0.68,0.28-0.92
				c-2.48-6.57,2.93-8.18,6.75-10.78c5.22-3.54,10.5-6.99,15.63-10.65c0.9-0.64,1.93-2.43,1.63-3.18c-1.88-4.69,0.56-5.28,4.39-5.82
				c1.73-0.24,4.75-3.06,4.52-3.61c-1.78-4.31,1.55-5.24,3.97-6.03c6.46-2.12,13.06-3.87,19.67-5.48c1.52-0.37,4.27-0.13,4.75,0.76
				c0.76,1.39,0.23,3.6-0.07,5.4c-0.17,0.98-1.01,1.85-1.6,2.85c6.43,2.03,6.94,3.61,3.28,8.88c-1.14,1.64-2.87,3.12-3.3,4.93
				c-0.59,2.44-0.76,5.32,0.06,7.58c0.34,0.94,3.7,0.9,5.7,1.09c1.98,0.18,4.28-0.52,5.88,0.29c1.38,0.69,2.55,2.87,2.78,4.54
				c0.43,3.18,0.13,6.45,0.13,9.69c2.19-0.2,4.38-0.42,6.58-0.59c1.82-0.14,3.7,0.02,5.46-0.36c1.51-0.32,3.03-1.04,4.33-1.9
				c3.23-2.15,6.37-4.45,9.49-6.75c0.82-0.61,2.09-1.5,2.06-2.21c-0.15-3.37,2.12-4.13,4.58-4.96c2.82-0.95,6-1.49,8.29-3.2
				c4.14-3.09,8.55-2.86,13.27-2.97c8.32-0.2,16.63-1.06,25.55-1.69c-0.82-2.03-0.97-2.9-1.44-3.51c-5.59-7.19-5.3-14.24,0-21.51
				c0.89-1.22,1.1-3.67,0.51-5.1C636.9,728.97,637.19,725.62,642.15,721.84z M551.07,557.19c-2.53-1.31-5.91-2.03-6.2-3.4
				c-0.8-3.77-0.91-7.93-0.21-11.74c1.76-9.61,4.26-19.09,6.16-28.68c1.05-5.32,1.47-10.76,2.28-16.93c-5.36,2.94-9.29,5-13.12,7.21
				c-5.53,3.19-5.65,3.81-3.23,9.95c0.6,1.52,1.19,3.22,1.1,4.79c-0.58,9.29-1.33,18.57-2.09,27.85c-0.41,4.95-1.89,10.02-1.24,14.82
				c1.34,9.84,3.69,19.55,5.83,29.27c1.81,8.24,4.06,16.38,5.83,24.63c1.55,7.22,2.63,15.02,8.94,19.48
				c7.92,5.6,16.65,10.08,25.16,14.81c4.48,2.49,9.72,3.84,13.78,6.84c12.52,9.26,25.1,18.55,36.69,28.91
				c5.11,4.56,13.35,6.21,15.06,14.38c0.03,0.16,0.58,0.21,1.18,0.41c4.88-6.57,10.86-2.17,16.07-0.61c2.25,0.68,4.6,5.01,4.78,7.82
				c0.42,6.56,2.42,10.47,7.87,12.27c3.3-2.87,6.21-5.86,9.6-8.15c1.47-0.99,4.06-1.09,5.89-0.63c5.24,1.31,5.9,6.39,8.05,10.38
				c2.19,4.06-0.06,5.36-2.99,6.79c-2.25,1.1-4.46,2.29-6.96,3.58c-1.03-4.47-4.91-1.63-7.78-1.93c-0.8,2.48-1.49,4.64-2.24,6.99
				c2.04,1.24,4.09,2.48,6.14,3.72c-0.56,0.25-1.11,0.5-1.67,0.75c0.7,4.01,1.4,8.03,2.24,12.81c5.74-6.46,12.33-11.59,14.12-20.39
				c0.54-2.67,2.11-5.18,3.47-7.62c3.88-6.95,7.57-14.03,11.92-20.67c5.69-8.68,11.74-17.14,18.1-25.34
				c1.66-2.14,4.99-3.04,7.63-4.35c2.35-1.17,5-1.83,7.17-3.25c5.12-3.35,9.91-7.22,15.08-10.47c3.05-1.92,4.3-4.3,3.17-7.49
				c-1.23-3.49-0.49-6.07,2.19-8.46c0.83-0.74,1.11-2.09,1.39-2.66c-3.52-1.16-6.64-2.07-9.68-3.21c-3.51-1.32-7.21-4.5-10.35-3.97
				c-8.49,1.43-11.72-4.21-16.11-9.22c-3.22-3.67-7.19-6.76-11.17-9.64c-0.98-0.71-3.5,0.01-4.93,0.78
				c-3.69,2.01-6.34,7.57-11.71,2.97c-0.08-0.07-0.51,0.26-0.68,0.34c0.95,2.24,1.9,4.46,2.84,6.68c-0.47,0.38-0.94,0.75-1.42,1.13
				c-2.31-1.46-4.98-2.55-6.85-4.44c-7.22-7.25-14.21-14.73-21.23-22.18c-3.92-4.17-3.56-8.69,0.81-12.18
				c3.81-3.05,4.04-5.08,0.02-7.68c-13.09-8.46-25.81-18.1-41.96-19.74c-6.61-0.67-13.55-0.03-20.08,1.3
				c-4.7,0.96-5.26,4.24-2.72,8.45c1.46,2.42,3.44,4.59,4.54,7.15c6.38,14.94,12.65,29.91,23.44,42.41c0.43,0.5,0.65,1.16,1.02,1.71
				c4.73,7.12,9.42,14.26,14.27,21.3c1.01,1.47,2.66,2.5,3.94,3.81c1.97,2.02,4.07,3.95,5.76,6.2c1.59,2.12,2.72,4.59,4.51,7.68
				c-5.8-5.92-10.72-10.86-15.51-15.9c-1.12-1.18-1.86-2.73-2.74-4.12c-2.09-3.3-4.07-6.66-6.27-9.88c-1.3-1.91-3.22-3.42-4.4-5.38
				c-5.06-8.41-13.51-14.8-15.12-25.28c-0.09-0.6-0.74-1.1-1.04-1.69c-2.76-5.44-5.46-10.9-8.26-16.32c-1.36-2.62-2.9-5.14-4.73-8.36
				c-2.95,22.73,8.55,40.53,15.59,60.88c-10.42-10.18-10.3-23.69-15.81-34.69c-5.51-11-8.04-23.5-12.2-36.34
				c-3.63,5.89-5.96,2.05-8.49-0.05c-1.34,1.19-2.52,2.24-3.71,3.3c-1.6-0.97-2.96-1.8-3.82-2.32c-2.74,1.52-4.99,2.76-7.5,4.15
				c-0.77-2.74-1.24-4.4-1.77-6.3c-9.75,2.83-18.89,2.92-27.83-2.2C549.73,560.13,550.39,558.67,551.07,557.19z M986.86,538.57
				c-3.16,10.07-7.02,18.87-14.06,26.27c-6.86,7.2-12.6,15.48-19.46,24.08c3.02,0,4.86-0.19,6.62,0.06c1.33,0.19,2.57,0.96,3.85,1.47
				c-0.58,1.13-0.98,2.4-1.77,3.36c-1.61,1.97-3.43,3.77-6.04,6.58c2.92-0.23,4.41-0.35,7.31-0.58c-6.57,9.61-15.8,14.71-24.4,21.89
				c6.53,0.7,11.46-3.81,17.66-1.01c-8.07,7.04-15.57,13.58-23.42,20.43c6.08,2.02,6.92,5.31,2.73,8.7
				c-3.21,2.6-7.14,4.34-10.26,7.02c-1.58,1.36-2.1,3.97-3.1,6.01c1.93,0.38,3.86,0.74,5.79,1.14c1.39,0.29,3.07,0.27,4.04,1.08
				c0.46,0.38-0.43,2.39-0.98,4.94c7.38-3.32,14.03-6.3,20.67-9.29c-0.11-0.47-0.23-0.94-0.34-1.4c-1.34-0.43-2.74-0.75-4.01-1.34
				c-0.96-0.44-2.57-1.38-2.48-1.81c0.23-1.11,1.01-2.55,1.97-2.99c10.61-4.89,16.94-13.53,21.51-23.86
				c0.63-1.43,2.07-2.51,3.13-3.76c-3.71,13.39-12.64,22.48-23.61,29.96c0.32,0.52,0.63,1.04,0.95,1.56
				c3.15-0.42,6.3-0.84,10.02-1.34c-6.35,9.3-16.14,11.8-25.08,15.69c9.99,4.2,17.62-3.01,26.25-5.27c-0.25,2.88-0.42,4.85-0.59,6.81
				c2.32-0.44,4.85-0.44,6.93-1.42c3.39-1.59,6.48-3.82,10.01-5.98c0.5,1.86,0.9,3.36,1.5,5.62c3.15-2.47,5.78-4.54,8.64-6.79
				c0.99,1.81,1.7,3.13,2.62,4.81c2.72-3.25,5.11-6.09,7.86-9.37c0.78,1.96,1.25,3.15,1.84,4.65c2.21-3.56,4.11-6.63,6.01-9.69
				c2.04,2.01,2.35,3.68,3.23,4.96c1.51,2.19,3.37,4.14,5.08,6.19c1.63-2.16,3.56-4.17,4.78-6.54c0.97-1.89,1.08-4.22,1.78-7.29
				c2.38,4.26,4.23,7.57,6.07,10.89c0.52-0.22,1.04-0.44,1.56-0.66c-0.18-2.9-0.35-5.8-0.62-10.12c3.78,3.25,6.39,5.49,10.24,8.8
				c-3.02-0.44-5.04-0.73-6.38-0.92c-0.84,1.96-1.62,3.78-2.39,5.61c-2.69-1.49-5.38-2.97-8.09-4.47
				c-4.37,7.81-8.02,11.59-15.31-1.22c-1.91,2.78-3.74,5.44-5.6,8.15c-1.69-0.88-2.94-1.53-4.14-2.15c-3.35,2.69-5.09,9.75-10.4,3.06
				c-3.91,2.29-7.17,7.83-11.08,0.97c-12.35,7.81-16.27,6.35-19.37,0.39c-13.23,6.09-21,6.02-26.79-0.54
				c-2.17,1.4-4.39,2.83-6.6,4.26c0.12,0.41,0.24,0.82,0.35,1.23c2.9,0.28,5.79,0.56,8.69,0.84c0.1,0.42,0.2,0.85,0.3,1.27
				c-2.82,2.36-5.64,4.72-9.36,7.83c14.44,4.15,27.1-0.29,40.87-4.4c-1.81,3.72-2.9,5.95-3.99,8.19c0.32,0.41,0.65,0.82,0.97,1.23
				c3.69-1.21,7.38-2.41,11.5-3.76c-0.27,2.17-0.45,3.68-0.8,6.53c4.22-2.23,7.47-3.95,11.36-6c-0.23,2.77-0.38,4.54-0.52,6.31
				c0.45,0.22,0.9,0.45,1.35,0.67c2.23-2.75,4.36-5.58,6.72-8.21c1.33-1.48,3.04-2.61,4.57-3.9c0.44,1.81,0.86,3.62,1.34,5.41
				c0.06,0.23,0.43,0.38,0.75,0.64c1.06-1.36,2.12-2.71,3.17-4.07c0.4,0.07,0.81,0.14,1.21,0.22c0.19,2.69,0.38,5.38,0.59,8.45
				c1.98-2.74,3.6-4.99,5.63-7.8c1.39,3.28,2.44,5.74,3.82,9.01c1.62-2.44,2.68-4.03,3.85-5.8c2.22,2.89,4.08,5.31,5.94,7.73
				c0.49-0.2,0.98-0.4,1.48-0.6c0.5-2.86,0.99-5.72,1.65-9.51c4.1,5.2,7.48,9.49,10.87,13.78c0.59-0.25,1.18-0.51,1.77-0.76
				c-0.14-2.95-0.28-5.9-0.42-8.84c0.41-0.11,0.82-0.21,1.22-0.32c0.9,1.35,1.79,2.69,2.5,3.74c3.42-4.51,4.28,1.45,6.49,1.63
				c1.73-0.75,3.54-1.53,5.31-2.3c0.99,1.58,1.86,2.96,2.96,4.72c0.9-2.03,1.48-3.33,2.43-5.47c2.36,2.65,4.32,4.84,6.63,7.42
				c0.51-2.53,0.9-4.49,1.41-7.02c3.33,3.16,6.15,5.83,8.96,8.5c0.4-0.28,0.79-0.56,1.19-0.84c0-2.24,0-4.48,0-7.22
				c1.62,0.45,2.72,0.76,3.71,1.04c0.06-1.39,0.12-2.55,0.22-4.77c3.49,2.95,6.16,5.22,9.34,7.91c-0.59-2.68-1.02-4.66-1.48-6.78
				c4.23,0.27,7.98,0.5,11.74,0.74c0.08-0.44,0.16-0.87,0.24-1.31c-1.67-1.5-3.35-3-5.95-5.32c2.85,0,4.19,0,5.7,0
				c-0.85-1.68-1.56-3.1-2.29-4.55c0.63-0.47,1.03-0.73,1.39-1.05c1.9-1.66,4.13-3.12,5.43-5.16c0.31-0.49-2.44-2.99-3.85-4.49
				c-0.56-0.6-1.38-0.96-1.97-1.53c-3.92-3.84-11.17-5.39-9.21-13.36c0.11-0.43-1.04-1.15-1.58-1.76c-1.02-1.16-2.01-2.35-3.49-4.08
				c2.96-0.76,4.98-1.28,8.08-2.07c-3.07-2.12-5.25-3.35-7.09-4.97c-1.42-1.25-3.01-2.82-3.49-4.53c-1.07-3.78-1.53-7.73-2.13-11.63
				c-0.14-0.91,0.21-1.89,0.23-2.84c0.03-1.18,0.13-2.41-0.13-3.53c-0.26-1.13-0.94-2.15-1.71-3.82c3.99,0,7.14,0,11,0
				c-0.95-1.54-1.32-2.46-1.96-3.13c-4.59-4.8-9.28-9.49-13.8-14.35c-0.91-0.98-2.02-2.97-1.61-3.77c1.43-2.81-0.67-3.65-1.92-5.35
				c-2.26-3.09-3.97-6.59-6.02-10.13c1.49-0.31,2.82-0.59,4.52-0.94c-4.62-6.89-10.03-12.93-9.58-21.32
				c3.54,6.13,6.38,12.46,10.52,17.79c3.36,4.32,3.76,8.09,1.63,12.3c1.89,2.16,5.42,4.91,4.98,5.9c-2.8,6.31,3.26,6.15,5.41,8.89
				c1.71-8.07,2.65-15.96,3.97-23.78c2.49-14.78-3.61-26.65-12.53-37.52c-0.84-1.02-3.35-0.67-5.81-1.07
				c0.92,2.55,1.36,3.78,1.39,3.85c-1.48,3.03-1.96,6.06-3.65,7.07c-5.29,3.16-10.21,7.55-17.27,6.47
				c-5.54-0.85-11.3-2.11-16.73-1.39c-7.73,1.03-14.38-1.91-21.32-3.82c-1.79-0.49-3.38-2.64-4.51-4.39
				c-2.12-3.3-2.59-6.54,1.78-8.74c0.8-0.4,1.98-1.5,1.87-2.05c-1.09-5.44,3.43-5.54,6.46-7.17c2.01-1.08,3.85-2.45,6.81-4.36
				c-3.04-1.09-4.86-1.29-6.03-2.27c-1.67-1.4-3.88-3.32-3.96-5.1c-0.07-1.73,1.9-4.22,3.63-5.17c2.53-1.39,5.64-1.81,8.54-2.43
				c7.34-1.57,14.7-3.03,17.63-10.83c-3.64-7.7-7.13-15.09-10.19-21.56c-10.51,8.45-21.02,16.9-31.65,25.44
				c1.62,0.97,2.83,1.69,4.49,2.68c-1.61,1.74-2.91,3.15-3.2,3.46c-0.62,13.26-7.51,21.62-16.61,29.09
				C984.32,537.84,985.68,538.23,986.86,538.57z M768.83,503.21c0.47,1.73,0.99,3.45,1.39,5.2c1.25,5.37,4.04,9.54,8.35,13.17
				c4.77,4.02,8.65,9.09,13.27,13.32c2.29,2.1,5.26,3.56,8.1,4.95c3.09,1.51,7.03,1.87,9.48,4.02c8.22,7.19,16.21,14.69,23.74,22.6
				c5.36,5.63,11.8,9.16,18.77,11.93c5.89,2.34,17.58-1.01,21.82-5.86c4.27-4.88,8.78-9.58,12.71-14.73
				c2.13-2.79,4.55-6.31,4.61-9.54c0.14-7.42-1.12-14.87-1.76-22.31c-0.72-8.41-2.36-16.89-1.78-25.21c0.6-8.69,0.43-16.41-5.82-23.2
				c-2.99-3.25-6.38-3.69-8.21,0.38c-2.38,5.3-6.62,8.11-11.21,10.9c-1.66,1.01-3.71,2.37-4.28,4.02c-2.1,6.02-6.6,9.82-11.19,13.71
				c-1.61,1.36-2.85,3.21-4.07,4.97c-4.66,6.73-10.96,10.87-18.99,12.52c-6.96,1.42-12.74,4.74-16.48,11.03
				c-0.42,0.71-0.74,1.56-1.33,2.08c-0.67,0.59-1.57,1.06-2.44,1.23c-0.32,0.06-1.28-1.04-1.18-1.3c0.31-0.79,0.93-1.51,1.57-2.11
				c1.07-1,2.54-1.68,3.34-2.84c3.84-5.61,9.37-8.11,15.82-9.35c6.39-1.23,11.65-4.38,15.86-9.55c3.55-4.36,7.71-8.22,11.56-12.34
				c1.13-1.21,2.66-2.39,3.11-3.86c3.41-11.07,16.59-13.44,20.9-23.66c0.21-0.5,0.89-0.8,0.88-0.79
				c-5.08-5.29-10.66-9.91-14.69-15.62c-4.18-5.91-6.89-12.86-10.19-19.38c-2.1-4.15-4.22-8.31-6.15-12.54
				c-1.84-4.02-5.47-5.52-9.03-4.53c-10.17,2.84-19.5,7.08-25.78,16.54c-3.06,4.61-9.2,6.59-10.58,13.3
				c-0.39,1.9-2.93,3.79-1.26,7.05c-3.47-3.57-6.5-2.43-9.87-0.61c-2.13,1.15-4.74,1.97-7.14,1.98c-9.53,0.04-17.02,4.68-24.48,10.26
				c5.66,9.45,8.59,19.97,12.55,30.11c-0.89,0.23-1.78,0.47-2.66,0.7c-0.43-2.35-0.47-4.87-1.36-7.03c-2.74-6.6-5.73-13.1-8.83-19.55
				c-0.47-0.98-2.47-2.29-3-2.01c-3.61,1.94-7.4,3.89-10.25,6.72c-0.77,0.76,1.22,4.73,2.43,6.97c4.11,7.59,10.99,13.78,11.58,23.16
				c0.04,0.58,0.56,1.25,1.05,1.66c5.9,4.85,7.42,12.01,10.11,18.6c0.82,1.99,2.37,4,4.12,5.22c2.84,1.99,5.96,4.03,9.25,4.82
				c8.51,2.04,10.9,10.64,17.06,15.12c0.77,0.56,0.58,2.47,0.79,3.76c0.24,1.46-0.04,3.24,0.7,4.34
				c5.57,8.28,10.99,16.72,21.21,20.25c2.41,0.83,4.54,2.59,6.66,4.12c2.22,1.6,4.1,3.74,6.44,5.11c4.21,2.46,9.08,9.12,8.5,12.77
				c-0.93-0.99-1.82-1.73-2.44-2.65c-2.78-4.15-5.4-8.41-10.74-9.82c-0.84-0.22-1.4-1.36-2.19-1.94c-2.75-2.02-5.28-4.65-8.36-5.84
				c-10.99-4.25-17.44-13.38-21.41-23.11c-2.22-5.44-6.3-9.32-8.26-14.6c-0.45-1.21-2.05-2.18-3.34-2.87
				c-5.69-3.06-11.46-5.98-17.39-9.05c-1.22,3.54-3.1,7.07-3.51,10.76c-0.48,4.31-3.14,9.57,3.24,12.53c1.24,0.58,1.56,3.16,2.3,4.81
				c-2.6-3.39-4.74-5.83-9.64-3.76c-1.14,0.48-4.15-1.96-5.48-3.69c-4.23-5.5-8.03-11.32-12.09-16.96c-0.59-0.82-1.69-1.27-2.55-1.89
				c-0.38,1.82-0.2,3.52,0.73,4.17c3.26,2.29,3.6,5.34,3.97,9c0.26,2.53,2.26,6.06,4.41,7c5.74,2.5,9.24,6.85,12.48,11.73
				c3.76,5.67,7.76,10.68,14.09,14.24c5.32,2.99,9.28,8.41,13.74,12.87c0.6,0.6,0.52,1.85,0.79,2.79c0.69,2.4,1.35,4.82,2.11,7.2
				c1.59,5.01,4.76,8.63,8.87,12c3.9,3.2,6.7,7.74,9.95,11.73c2.89,3.55,5.72,7.16,9.14,11.43c4.67-5.66,9.3-10.27,12.72-15.66
				c4.8-7.56,8.88-15.61,12.86-23.65c0.91-1.84,1.15-5.53,0.01-6.63c-6.46-6.27-11.27-14.61-18.3-19.56
				c-9.35-6.58-16.16-14.58-22-23.93c-2.58-4.13-5.28-7.93-10.37-9.53c-1.84-0.58-3.28-2.6-4.76-4.11
				c-4.51-4.59-8.43-9.94-13.53-13.71C770.6,518.03,768.75,511.21,768.83,503.21z M184.53,187.72c3.4,0,7-0.63,10.29,0.12
				c8.4,1.92,16.51,1.49,24.48-1.56c3.55-1.36,6.01-1.31,8.23,2.55c1.68,2.92,4.59,5.23,7.22,7.5c2.26,1.96,4.58,1.68,7.2-0.15
				c6.3-4.42,10.01-10.59,12.81-17.46c1.05-2.59,2.47-3.66,5.45-4.01c9.03-1.07,17.97-2.9,26.99-4.11
				c8.39-1.12,16.83-1.85,25.25-2.69c2.66-0.27,4.59,0.07,5.45,3.5c0.77,3.09,2.8,5.93,4.59,8.67c1.85,2.83,3.71,2.8,5.54-0.36
				c1.14-1.97,2.79-3.7,4.43-5.31c3.44-3.37,7.7-6.12,10.33-10c2.24-3.31,4.73-4.36,8.14-4.34c10.15,0.06,20.3,0.17,30.45,0.42
				c3.94,0.1,6.47,1.71,5.09,6.34c-0.23,0.79-0.03,1.71-0.03,2.18c2.61-1.51,4.86-3.86,7.24-3.99c8.6-0.47,17.24-0.18,25.99-0.18
				c0.32,1.54,0.6,2.91,0.89,4.34c6.82-2.61,6.85-2.66,4.88-7.36c-2.03-4.87-0.35-7.18,4.97-6.71c1.98,0.18,3.94,0.71,5.92,0.9
				c6.28,0.61,12.59,1.73,18.85,1.51c5.17-0.18,10.79-0.57,14.84-4.54c-14.39,0-28.6,0-43.04,0c0.38-1.03,0.84-2.28,1.47-3.99
				c-140.9,3.41-281.39,3.87-422.18,2.77c0,27.46,0,53.87,0,80.73c5.53,0,10.81,0.11,16.08-0.02c10.2-0.26,20.23-4.49,30.65-1.07
				c1.55,0.51,3.83-0.77,5.63-1.55c3.5-1.52,6.82-3.46,10.35-4.9c8.72-3.57,17.49-7.02,26.3-10.38c5.22-1.99,10.55-3.72,15.82-5.58
				c7.19-2.54,14.3-2.19,21.97-1.96c12.54,0.38,25.64-1.97,36.55-9.58c4.34-3.03,9.37-6.58,11.39-11.1c3.18-7.14,4.83-15.2,5.7-23.04
				c0.56-5.02,2.47-5.98,6.53-5.43c1.79,0.24,3.65,0.66,5.25,1.45c4.27,2.11,8.23,1.13,12.54-0.13c3.72-1.09,7.81-1.16,11.74-1.17
				c1.11,0,2.86,1.59,3.24,2.8c2.25,7.12,4.23,14.32,6.21,21.52c0.47,1.7,0.6,3.5,0.89,5.26c-0.53,0.12-1.06,0.23-1.59,0.35
				c-2.21-7.04-4.83-13.99-6.53-21.16c-1.62-6.8-3.35-8.92-10.05-6.51c-7.34,2.64-14,3.41-21.04-0.36c-0.86-0.46-3.65,0.77-4.06,1.8
				c-0.94,2.36-0.96,5.08-1.47,8.44c11.44-2.79,22.06-6.91,32.96-5.3c-9.32,2.27-19.07,4.19-28.5,7.11
				C186.38,173.95,183.8,179.69,184.53,187.72z M692.63,415.11c-5.39-4.93-14.62-0.27-18.28-8.52c-0.16-0.36-1.21-0.37-1.86-0.45
				c-15.98-1.95-31.95-3.91-47.94-5.79c-1.23-0.14-2.59,0.22-3.81,0.6c-4.36,1.33-8.61,3.18-13.06,4.08
				c-6.66,1.35-12.67,3.47-17.49,9.77c3.76-0.38,6.75-0.69,9.74-0.99c-3.24,1.94-6.67,3.02-10.17,3.69c-1.07,0.2-2.35-0.71-3.54-1.12
				c0.42-1.16,0.5-2.84,1.33-3.38c4.38-2.88,8.78-5.85,13.52-8.04c3.64-1.68,7.79-2.25,11.31-3.2c-12.58,0-25.38-0.45-38.12,0.25
				c-4.45,0.24-10.83,2.19-12.59,5.4c-2.24,4.08-5.05,5.86-8.51,7.95c-14.65,8.82-30.28,15.23-47.05,18.42
				c-11.41,2.17-22.97,3.44-33.8,8.05c-7.09,3.02-14.29,5.77-22.18,8.94c5.7,1.59,10.36,2.5,14.72,4.18
				c8.85,3.41,9.26,5.16,5.05,13.66c-1.36,2.76-3.45,5.87,0.14,9.53c3.87-2.66,7.65-5.31,11.48-7.89c3.92-2.64,7.47-2.61,11.29,0.7
				c2.26,1.95,5.34,3.61,8.25,4.03c7.37,1.08,14.88,1.18,22.29,2.05c7.24,0.85,8.17,2.77,4.99,8.87c-0.21,0.4-0.14,0.95-0.29,2.16
				c1.94-0.27,3.63-0.76,5.29-0.66c2.26,0.13,4.96,0.03,6.65,1.21c6.34,4.46,14.87,4.27,20.61-0.87c1.46-1.31,3.03-2.82,4.81-3.4
				c6.86-2.22,13.53-4.53,17.03-11.63c0.14-0.29,0.84-0.32,2-0.71c-0.72,5.64-3.98,8.6-8.49,10.42c5.11,5.43,10.28,6.46,16.66,3
				c4.41-2.39,9.28-4.5,14.17-5.25c6.47-1,12.64-0.86,18.92-4.58c5.35-3.17,11.24-4.71,14-11.07c0.52-1.19,2.63-1.84,4.13-2.41
				c4.46-1.7,8.95-3.31,13.49-4.8c3.08-1.01,6.25-1.73,9.39-2.59c0.13,0.49,0.25,0.98,0.38,1.47c-2.42,0.84-4.83,1.69-8.6,3
				c4.8,0.65,8.02,1.09,12.29,1.67c-8.97,0.91-16.65,1.59-24.31,2.49c-4.59,0.54-5.87,2.15-6.48,6.82c4.37-1.04,8.91-2.12,13.45-3.2
				c0.17,0.67,0.33,1.33,0.5,2c-8.29,2.47-16.58,4.93-24.87,7.41c-1.42,0.43-3.23,0.53-4.15,1.48c-4.09,4.25-9.27,3.84-14.44,4.05
				c-3.12,0.13-6.69,0.11-9.19,1.61c-2.33,1.4-3.51,4.73-5.51,7.68c11.42-0.13,21.82-0.32,31.72-4.58c3.31-1.42,6.99-2.11,10.55-2.83
				c4.88-0.99,10.87-0.22,14.49-2.84c7.23-5.22,14.37-5.27,22.57-4.49c3.81,0.36,8-2.52,11.87-4.26c0.9-0.4,1.99-2.74,1.66-3.15
				c-3.3-4.22,0.67-5.3,3.13-7.12c-1.54-1.06-2.9-2.01-4.96-3.44c2.11-1.18,3.45-2.03,4.88-2.72c4.64-2.24,9.49-4.09,13.91-6.7
				c5.6-3.31,5.79-4.61,1.8-9.64c-2.53-3.18-1.81-5.44,1-7.56c1.78-1.34,3.76-2.43,5.73-3.68c-5.07-5.4-4.84-6.73,1.28-10.75
				c1.49-0.98,2.84-2.17,4.26-3.27c-5.36-0.98-10.19-0.65-14.96-1.07c-4.65-0.42-8.1,1.29-11.06,4.51c-2.59,2.82-4.85,5.99-7.72,8.5
				c-3.84,3.36-7.92,6.57-12.31,9.11c-1.57,0.91-4.37-0.31-6.61-0.55c-0.01-0.53-0.02-1.07-0.03-1.6c-4.81,1.8-9.6,3.66-14.43,5.39
				c-4.83,1.73-9.62,3.69-14.59,4.87c-4.98,1.18-10.16,1.5-15.25,2.25c-5.09,0.75-10.15,1.66-15.25,2.33
				c-4.67,0.61-9.4,3.46-14.77,0.33c0.99-0.67,1.24-0.98,1.53-1.02c14.61-1.88,29.39-2.92,43.76-5.9c9.3-1.93,18.03-6.66,26.98-10.24
				c3.25-1.3,6.41-2.98,7.28,2.38C678.86,431.12,688.63,422.21,692.63,415.11z M101.56,380.71c6.23-2.53,11.6-4.41,16.71-6.84
				c11.38-5.41,22.63-11.08,33.92-16.66c3.57-1.77,7.02-3.83,10.71-5.31c14.3-5.75,28.55-11.67,43.07-16.85
				c13.75-4.9,22.44-14.1,26.52-27.9c1.13-3.81,2.08-7.68,3.11-11.52c2.1-7.83,6.38-13.32,14.94-14.8
				c8.83-1.52,17.61-3.32,26.36-5.22c3.9-0.85,7.68-2.26,11.88-3.53c-1.01-1.66-1.44-2.36-1.86-3.07c-3.84-6.39-3.76-6.21-10.48-3.34
				c-2.81,1.21-6,1.57-9.04,2.21c-8.39,1.77-16.78,3.59-25.22,5.14c-2.82,0.52-5.95,0.97-8.66,0.3c-10.26-2.56-20.4-5.61-30.6-8.4
				c-1.26-0.35-2.64-0.32-3.97-0.33c-5.82-0.03-11.7-0.45-17.46,0.16c-3.8,0.4-8.54,1.27-10.88,3.77c-3.27,3.49-6.49,6.39-10.55,9.07
				c-8.44,5.56-16.77,9.72-27.32,8.64c-5.22-0.53-10.43,1.57-13.45,6.21c-11.37,17.47-27.26,26.24-48.14,25.75
				c-8.85-0.21-15.99,2.96-22.13,9.47c-11.83,12.55-24.13,24.6-42.63,26.47c0,1.9,0,3.42,0,5.11c9.19-1.74,18.03-3.17,26.75-5.13
				c8.68-1.95,16.68-4.97,22.49-12.68c2.24-2.97,6.73-4.73,10.55-5.91c7.42-2.3,15.07-3.84,22.65-5.62c1.74-0.41,3.54-0.5,5.32-0.74
				c0.05,0.42,0.1,0.83,0.15,1.25c-8.94,2.27-17.95,4.31-26.79,6.91c-3.38,0.99-7.4,2.48-9.39,5.07
				c-7.67,10.01-18.14,13.36-29.94,14.78c-6.39,0.77-12.69,2.37-18.95,3.92c-1.26,0.31-3.04,2.09-3.08,3.23
				c-0.25,9.13-0.14,18.26-0.14,28.11c8.38-1.44,16.25-2.45,23.95-4.2c13.89-3.16,27.62-7.04,41.52-10.11
				c8.95-1.98,18.54,0.39,27.26-4.34C101.9,372.05,103.1,374.19,101.56,380.71z M228.69,328.93c-7.15,3.37-13.89,6.83-20.85,9.74
				c-7.33,3.06-14.89,5.57-22.33,8.38c-5.86,2.21-11.83,4.21-17.51,6.84c-12.64,5.85-25.14,12.03-37.68,18.09
				c-3.2,1.54-6.37,3.13-11.09,5.44c1.92,0.93,2.65,1.08,3.09,1.53c1.49,1.54,4.32,3.59,4,4.69c-0.58,2.03-2.86,3.75-4.76,5.14
				c-1.26,0.92-3.12,0.99-4.69,1.51c-7.6,2.5-15.19,5.02-22.78,7.54c1.46,1.09,2.75,1.19,3.99,1.46c1.37,0.29,2.7,0.75,4.05,1.13
				c0.17,0.52,0.34,1.04,0.51,1.56c-2.01,1.34-3.84,3.29-6.05,3.93c-18.2,5.3-34.8,13.99-50.9,23.74c-2.65,1.61-4.7,4.21-7.03,6.35
				c0.24,0.38,0.48,0.76,0.72,1.14c2.27-0.29,4.6-0.37,6.81-0.92c4.84-1.19,9.59-2.81,14.46-3.84c1.46-0.31,4.51,0.56,4.62,1.24
				c0.72,4.55,4.06,3.44,6.55,3.09c3.75-0.52,7.42-1.69,11.09-2.68c6.4-1.73,12.69-3.94,19.18-5.22c13.08-2.58,27.06,1.39,39.67-5.41
				c1.03-0.56,4.11,0.45,4.58,1.49c1.95,4.26,4.75,2.94,7.88,2.08c2.9-0.79,6.33-2.29,8.84-1.44c12.96,4.34,33.65-1.61,41.49-13.55
				c1.57-2.39,1.82-4.25-0.69-5.92c-3.82-2.54-3.6-5.56-1.26-8.96c0.65-0.94,1.75-2.13,1.57-2.99c-1.59-7.93,5.34-10.06,9.71-11.32
				c9.79-2.82,20.15-3.71,30.29-5.3c1.29-0.2,2.67,0.04,3.98,0.24c4.44,0.68,8.84,1.69,13.3,2.11c9.25,0.86,18.52,1.88,27.8,1.99
				c12.05,0.15,24.1-0.57,36.16-0.8c8.43-0.16,16.92-0.68,25.29,0.01c5.17,0.43,10.11,3.15,15.27,4.26c3.98,0.86,7.98-0.15,9.49-4.49
				c1.61-4.64,2.58-9.48,8.4-11.72c-3.97-1.61-7.6-2.23-11.16-3.11c-2.56-0.63-5.03-1.59-7.54-2.42c-4.4-1.46-8.71-3.84-13.19-4.24
				c-12.82-1.15-25.72-1.4-38.57-2.21c-8.23-0.52-16.46-1.27-24.66-2.16c-8.73-0.94-8.72-1.06-8.24-8.51
				c-2.36-0.76-4.65-1.5-6.95-2.25c0.97-2.04,1.93-4.08,3.34-7.04c-4.27,0.39-8.33,0.61-12.34,1.17c-5.98,0.84-11.84,1.47-17.64-1.17
				c-2.37-1.08-5.08-1.72-7.68-1.89C234.48,334.96,230.24,334.23,228.69,328.93z M863.85,283.32c-1.95-2.36-3.64-4.26-5.15-6.29
				c-1.14-1.53-2.03-3.24-3.07-4.85c-4.1-6.36-8.14-12.75-12.42-19c-0.58-0.84-2.32-0.92-3.54-1.27c-1.57-0.45-3.15-1.05-4.75-1.15
				c-2.42-0.15-4.9-0.16-7.28,0.21c-2.88,0.45-6.29,2.73-8.42,1.8c-6.15-2.67-9.28,0.49-12.66,4.51c-1.37,1.63-3.23,2.88-4.98,4.15
				c-3.54,2.57-3.91,5.39-1.21,8.85c2.65,3.41,4.93,7.12,7.7,10.42c1.52,1.81,3.51,3.66,5.66,4.4c3.1,1.07,4.37,3.13,4.36,5.86
				c-0.02,6.01,3.44,7.94,8.68,8.77c6.39,1.01,10.31,5.86,14.38,10.6c-2.39-0.15-4.33-1.2-6.32-2.17c-4.08-2-8.01-4.66-12.32-5.73
				c-4.51-1.12-6.46-3.15-6.9-7.65c-0.22-2.29-1.32-6.1-2.65-6.39c-6.37-1.4-7.58-6.79-10.37-11.17c-0.9-1.41-3.61-2.31-5.51-2.34
				c-7.22-0.11-14.45,0.48-21.67,0.29c-2.45-0.07-3.75,0.02-4.25,2.61c-0.9,4.72-2.04,9.4-2.92,14.13c-0.81,4.32,0.3,7.42,5.19,8.6
				c1.53,0.37,3.96,2.56,3.79,3.52c-0.81,4.42,1.63,6.61,4.43,9.24c2.56,2.4,4.29,5.68,6.39,8.57c-0.44,0.37-0.87,0.74-1.31,1.1
				c-3.91-4.93-7.84-9.84-11.71-14.81c-0.23-0.3-0.1-1.01,0.07-1.45c1.05-2.81-0.34-4.18-2.88-4.56c-3.74-0.56-5.78-2.74-7.1-6.14
				c-0.83-2.14-2.08-4.2-3.48-6.03c-3.33-4.35-7.38-8.37-3.84-15.77c-8.94,0-16.58-0.11-24.21,0.11c-1.31,0.04-3.4,1.24-3.73,2.33
				c-2.74,8.91-8.95,14.81-16.98,19.68c4.61,4.55,8.88,8.75,13.12,12.99c0.81,0.81,1.73,1.68,2.11,2.7c1.2,3.24,3.66,4.22,6.8,4.98
				c3.49,0.84,7.44,1.75,10.02,3.99c5.99,5.2,12.96,7.19,20.42,8.41c3.28,0.54,6.58,1.08,9.77,1.98c1.06,0.3,2.48,1.66,2.51,2.58
				c0.03,0.93-1.27,2.42-2.29,2.78c-2.64,0.94-5.5,1.23-8.17,2.1c-1.17,0.38-2.09,1.54-3.13,2.34c1.2,0.46,2.43,1.37,3.58,1.28
				c4.29-0.34,8.57-0.94,12.83-1.6c1.24-0.19,2.47-0.79,3.6-1.38c5.84-3.03,11.67-6.08,17.46-9.21c3.04-1.64,6.36-3.05,8.91-5.3
				c5.88-5.21,12.41-8.46,20.14-10.23c7.86-1.8,15.72-4.35,22.97-7.85c4.54-2.19,9.5-6.03,11.48-10.39
				c3.07-6.76,7.91-8.26,14.16-9.82c9.89-2.49,19.72-4.51,28.4-11.45c13.74-10.99,28.69-20.54,43.57-30.01
				c6.66-4.24,12.74-10.87,22.35-8.62c-1.49-1.75-3.04-2.67-4.22-3.95c-1.22-1.31-2.42-2.83-3.04-4.47
				c-2.06-5.42-5.74-6.72-10.94-3.96c-1.94,1.03-4.32,1.96-6.43,1.82c-10.3-0.66-20.57-1.73-30.86-2.59
				c-4.22-0.35-8.45-0.54-12.68-0.81c0,0.45,0.01,0.91,0.01,1.36c1.25,0,2.64-0.35,3.71,0.09c1.27,0.53,3.19,1.71,3.21,2.65
				c0.11,4.21,2.44,5.42,6.07,5.6c2.15,0.11,4.29,0.64,6.44,0.68c5.19,0.1,10.38,0.03,15.57,0.03c0.05,0.52,0.09,1.04,0.14,1.56
				c-2.65,0.34-5.32,1.01-7.96,0.96c-4.32-0.09-8.63-0.8-12.95-0.92c-1.08-0.03-2.19,1.2-3.28,1.85c1.04,0.81,1.97,1.91,3.15,2.34
				c1.19,0.44,2.63,0.27,3.96,0.28c4.96,0.02,9.92,0.01,14.89,0.01c0.02,0.52,0.04,1.04,0.06,1.55c-7.23,0.33-14.46,0.65-21.69,0.98
				c-0.01,0.54-0.02,1.07-0.04,1.61c3.46,0.72,6.93,1.43,10.39,2.15c-0.07,0.43-0.14,0.86-0.21,1.29c-3.13-0.23-6.27-0.46-9.86-0.72
				c0.98,3.06,1.72,5.4,2.47,7.73c-0.46,0.12-0.92,0.25-1.38,0.37c-3.75-5.74-4.82-12.22-4.58-19.39c-1.99-0.65-4.05-1.32-5.62-1.83
				c1.14-1.16,2.37-2.07,3.06-3.29c0.27-0.48-0.43-1.97-1.09-2.44c-2.47-1.78-6.87-0.06-7.95,2.98c-1.25,3.53-2.46,7.08-3.91,11.28
				c-4.38-3.55-3.11-7.04-2.07-10.48c1.34-4.45-0.96-7.76-4.31-9.59c-4-2.18-5.21,1.85-6.76,4.49c-2.89,4.89-5.79,9.78-9.27,15.68
				c3.26,0.36,5,0.64,6.75,0.73c3.33,0.18,6.67,0.11,9.98,0.39c4.4,0.37,6.89,2.24,8.03,7.18c0.88,3.82,4.26,7.12,6.75,10.49
				c0.82,1.1,2.35,1.68,3.55,2.5c-7.12-0.41-11.08-4.91-12.46-12.05c-0.37-1.89-2.11-4.68-3.63-4.99c-5.6-1.11-11.38-1.34-17.68-1.96
				c2.58,10.76,4.93,20.58,7.28,30.4c-0.39,0.14-0.78,0.29-1.17,0.43c-2.54-6.72-4.92-13.5-7.75-20.1c-0.69-1.6-2.79-3.59-4.33-3.66
				c-4.81-0.22-9.65,0.39-14.41,0.67c0.23,4.03,0.95,7.4,0.49,10.61c-0.81,5.76,0.98,10.19,5.47,13.56
				C865.58,278.14,865.12,280.76,863.85,283.32z M843.71,741.95c-0.41-2.29-0.95-4.04-1-5.8c-0.21-6.63-2.36-14.21,0.1-19.68
				c3.7-8.23,4.62-15.23,0.46-23.02c-0.22-0.41,0.01-1.03-0.17-1.47c-0.9-2.22-1.68-4.53-2.85-6.61c-1.86-3.3-4.24-6.31-5.95-9.68
				c-1.61-3.18-3.31-5.32-7.34-5.69c-1.89-0.18-4.61-2.53-5.09-4.38c-1.25-4.77-3.43-8.48-7.44-11.36c-1.16-0.83-2.04-2.65-2.24-4.13
				c-1.09-8.21-4.14-10.79-12.12-8.99c-4,0.9-6.99,0.14-10.14-2.33c-3.56-2.81-6.64-2.28-8.37,1.81c-2.41,5.72-5.46,11.04-6.07,17.76
				c-0.5,5.5-4.28,10.96-7.47,15.88c-2.02,3.1-5.6,5.2-8.55,7.66c-2.82,2.36-6.56,4.09-8.42,7.03c-4.11,6.48-7.75,13.16-14.22,17.73
				c-2.57,1.81-5.29,3.61-7.33,5.95c-10.24,11.81-20.17,23.88-30.44,35.66c-2.97,3.4-6.72,6.12-10.92,9.88
				c2.44,0,3.87,0.11,5.27-0.02c25.95-2.37,51.91-4.56,77.83-7.27c10.55-1.1,21.37-2.15,31.36-5.38c10.41-3.37,20.52-4.85,31.25-3.58
				C836.77,742.25,839.78,741.95,843.71,741.95z M29.1,576.06c0.75-2.37,1.36-5.11,5.52-2.9c7.91,4.19,16.07,7.92,24.22,11.62
				c2.36,1.07,5.09,1.98,7.62,1.91c4.26-0.13,6.59,1.66,8.79,5.14c2.47,3.9,4.37,9.46,11.06,7.54c0.38-0.11,1.05,0.21,1.35,0.54
				c2.54,2.78,5.45,3.14,8.92,1.9c0.91-0.33,2.23,0.37,3.34,0.69c4.94,1.46,9.81,3.89,14.81,4.18c4.57,0.26,9.28-1.77,15.31-3.1
				c-2.27-2.46-4.11-4.12-5.54-6.08c-1.43-1.97-3.79-4.65-3.3-6.29c0.56-1.86,3.67-3.71,5.94-4.16c8.14-1.61,16.43-2.44,24.6-3.95
				c4.18-0.77,8.32-2.1,12.29-3.66c3.29-1.29,4.08-4.23,1.73-6.85c-2.62-2.92-1.96-5.11,0.4-6.99c-2.22-3.76-3.45-8.28-6.37-10.13
				c-3.04-1.92-7.72-1.25-11.67-1.74c-0.49-0.06-0.97-0.23-1.45-0.35c-17.92-4.59-35.61-9.66-50.7-21.11
				c-3.71-2.81-7.45-3.71-11.59,0.2c-4.02,3.8-8.72,2.43-11.4-2.76c-2.11-4.09-1.36-10.06-7.64-11.36c-0.22-0.04-0.52-0.57-0.48-0.81
				c0.74-4.01-2.74-5.2-4.76-7.43c-1.43-1.57-2.73-3.38-3.6-5.31c-4.36-9.7-10.61-11.87-20.38-7.33
				c-9.16,4.26-18.54,8.04-27.67,12.35c-1.25,0.59-2.32,2.98-2.34,4.55C5.93,530.03,6,545.68,6,561.34c0,2.45,0,4.9,0,7.86
				c4.76-0.46,9.19-2.02,12.8-0.92C22.52,569.42,25.44,573.16,29.1,576.06z M278.32,848.66c8.91,0.8,11.04-0.27,12.53-7.37
				c2.18-10.36,1.26-20.53-3.38-30.18c-4.29-8.92-9.19-17.68-7.56-28.15c0.92-5.91,2.16-11.76,3.08-17.67
				c1.46-9.4,4.46-17.99,11.3-25.01c2.58-2.65,4.47-5.98,5.98-8.05c-1.97-2.73-3.27-4.42-4.43-6.19c-1.3-1.97-2-4.86-3.8-5.87
				c-3.97-2.23-8.35-3.99-12.77-5.12c-8.29-2.1-17.5,0.31-25.18-4.8c-2.13-1.42-5.16-3.71-5.13-5.56c0.04-2.35,2.37-5.15,4.46-6.85
				c2.72-2.21,6.16-3.53,10.55-5.93c-3.69-1.3-5.97-2.07-8.23-2.91c-4.19-1.57-6.74-6.4-4.17-8.83c4.42-4.18,3.14-7.25-0.5-10.79
				c-2.91-2.82-2.37-5.32,1.03-7.63c3.98-2.71,8.11-5.39,11.47-8.77c6.71-6.75,13.16-13.67,22.92-15.97
				c0.12-0.03,0.15-0.49-0.05,0.23c-15.48-7.96-30.75-15.8-45.69-23.48c-1.46,1.35-2.68,3.04-4.33,3.88
				c-4.66,2.39-9.44,4.59-14.3,6.56c-3,1.22-6.19,2.31-9.38,2.65c-2.81,0.3-4.91,0.53-6.5,3.39c-0.9,1.64-3.37,3.56-5.01,3.45
				c-8.03-0.51-10.1,8.97-17.17,9.82c-0.36,0.04-0.48,1.49-0.83,2.22c-1.25,2.65-2.6,5.24-3.79,7.91c-0.47,1.07-1.21,2.94-0.79,3.33
				c2.25,2.11,4.61,4.27,7.33,5.65c5.62,2.84,6.34,4.02,3.2,9.48c-1.53,2.66-1.35,4.51,0.79,6.58c2.5,2.42,4.72,5.13,7.19,7.57
				c4.87,4.82,10.36,8.22,17.63,8.06c2.31-0.05,4.64,1.02,5.93,1.33c0.35,4.27-0.59,8.44,1.08,10.46
				c5.77,6.96,12.2,13.31,21.59,15.73c6.67,1.72,6.89,2.7,5.62,9.34c2.84,1.11,5.88,1.9,8.53,3.42c3.81,2.18,7.12,5.27,11.01,7.26
				c5.67,2.91,7.64,12.48,3.1,16.96c-5.66,5.58-5.29,5.43-2.21,13.1c1.79,4.47,3.89,9.97,2.75,14.16c-1.52,5.57-3.9,10.52-4.54,16.76
				c-0.72,7.03,0.98,12.02,4.12,17.61c2.29,4.08,4.11,8.6,5.13,13.16C277.99,838.4,277.88,843.47,278.32,848.66z M426.01,625.53
				c-0.17-0.76-0.34-1.52-0.51-2.27c-10.48,0.39-20.96,1.04-31.44,1.07c-6.23,0.01-12.57-0.44-18.66-1.64
				c-5.65-1.12-10.99-3.76-16.59-5.27c-3.27-0.88-7.26-2.12-10.1-1.03c-8.66,3.32-16.91,7.72-25.35,11.63
				c-6.74,3.12-13.27,7.05-20.34,9.05c-19.09,5.39-34.75,16.03-48.85,29.86c1.1,1.47,2.68,2.91,3.34,4.69
				c0.65,1.75,1.09,4.2,0.35,5.74c-3.67,7.64-3.82,7.57,4.42,10.46c0.78,0.27,1.53,0.64,2.3,0.94c3.44,1.31,3.29,3.66,1.14,5.74
				c-1.6,1.55-3.9,2.39-5.92,3.51c-2.03,1.12-4.36,1.87-6.07,3.34c-2.34,2.02-1.83,4.47,1.05,5.36c4.74,1.46,9.6,2.94,14.5,3.37
				c9.46,0.83,18.45,2.72,26.14,8.55c1.68,1.28,3.18,3.29,3.87,5.27c1.32,3.79,5.32,5.28,7.87,2.15c4.18-5.11,8.42-10.31,11.67-16.02
				c8.3-14.61,18.25-27.73,31.83-37.79c3.52-2.61,3.81-5.62,3.43-9.57c-0.23-2.47,0.32-5.85,1.91-7.47
				c3.66-3.74,7.88-7.11,12.39-9.76c4.69-2.76,9.87-4.82,15.03-6.63c11.34-3.98,21.91-10.54,34.64-10.08
				C420.68,628.81,423.36,626.65,426.01,625.53z M343.66,293.13c-0.79,0.05-1.61,0.27-2.37,0.14c-13.05-2.28-24.06,5.12-36.1,7.62
				c-0.62,0.13-1.18,0.61-1.73,0.99c-4.72,3.27-9.97,4.34-15.64,3.98c-10.12-0.64-20.25-1.16-30.37-1.74
				c-4.81-0.28-9.65-0.32-14.4-0.98c-3.44-0.47-5.54,0.72-6.71,3.68c-1.92,4.86-3.63,9.8-5.27,14.27c3.59-0.68,7.53-2.61,10.82-1.83
				c13.28,3.16,27.33-0.91,40.29,4.7c2.03,0.88,4.59,0.87,6.88,0.76c4.02-0.19,7.05,0.78,9.06,4.66c0.85,1.64,2.7,3.64,4.31,3.85
				c5.77,0.74,9.42,4.43,13.22,8.15c8.61,8.43,19.42,11.6,30.98,13.55c10.46,1.77,20.73,4.61,31.13,6.74
				c1.46,0.3,3.81-0.25,4.69-1.29c2.54-3,4.62-6.41,6.72-9.76c3.73-5.94,7.34-11.95,11.87-19.37c-15.56-0.94-29.36-1.78-43.16-2.61
				c0.06-0.76,0.13-1.51,0.19-2.27c14.55,1.07,29.09,2.15,43.64,3.22c0.06-0.56,0.12-1.11,0.19-1.67
				c-16.19-1.45-32.38-2.9-48.56-4.35c0-0.49-0.01-0.97-0.01-1.46c1.66-0.18,3.34-0.64,4.97-0.49c13.06,1.27,26.1,2.85,39.19,3.87
				c3.19,0.25,6.64-0.86,9.75-1.97c3.9-1.39,7.52-3.54,11.34-5.15c10.62-4.46,21.49-7.86,33.79-6.37
				c-2.01-5.54-3.78-10.42-5.33-14.73c-4.53,0.38-8.74,0.85-12.96,1.08c-10.44,0.58-20.89,1.3-31.34,1.45
				c-5.06,0.07-10.14-0.92-15.19-1.62c-3.1-0.43-6.13-1.45-9.23-1.81c-4.92-0.56-9.87-0.95-14.82-1.06
				c-1.45-0.03-3.12,0.86-4.34,1.78c-5.11,3.88-5.38,10.03-7.01,15.55c-2.34,7.94-4.44,15.98-7.19,23.78
				c-1.68,4.75-6.51,7.39-8.93,6.64c2.56-4.06,6.14-7.99,7.71-12.6c2.98-8.76,4.73-17.93,7.13-26.89c1.4-5.21,3.07-10.34,4.71-15.8
				c-10.86-2.42-21.33-3.68-31.93-1.4c-1.82,0.39-3.51,1.65-5.11,2.72c-2.3,1.53-4.41,3.37-6.73,4.87c-1.86,1.2-3.91,2.1-6.85,3.65
				C318.95,297.74,330.15,286.88,343.66,293.13z M817.28,639.39c-3.93-5.46-6.96-10.42-10.73-14.73c-5.57-6.36-13.04-11.1-16.19-19.5
				c-0.39-1.04-1.39-1.85-2.12-2.75c-9.71-11.99-19.37-24.02-29.19-35.92c-2.07-2.51-4.58-4.74-7.17-6.73
				c-2.64-2.04-5.62-3.63-8.87-5.69c4.74,9.78,11.24,18.08,18.9,25.64c7.29,7.19,14.3,14.67,21.44,22.02
				c-0.61,0.59-1.23,1.18-1.84,1.77c-2.76-3.22-5.53-6.42-8.26-9.66c-2.76-3.28-4.91-7.47-8.33-9.71
				c-6.45-4.23-10.67-10.16-15.57-15.8c-8.55-9.82-8.45-22.15-12.15-33.01c-2.33-1.37-6.11-2.26-6.82-4.24
				c-2.16-6.09-6.74-11.57-5.65-18.67c0.04-0.24-0.6-0.59-1.52-1.42c-2.2,7.74-9.12,7.67-14.79,9.46c-3.84,1.21-5.03,3.43-4.5,7.05
				c0.19,1.31,0.56,2.59,0.86,3.88c-0.44,0.1-0.87,0.19-1.31,0.29c-0.67-2.85-1.34-5.7-2.02-8.54c-0.47-0.14-0.95-0.27-1.42-0.41
				c-0.65,1.63-2.17,3.49-1.81,4.86c1.68,6.54,3.65,13.02,5.94,19.38c3.04,8.42,6.48,16.69,9.75,25.03
				c-7.37-13.4-14.36-26.92-17.29-42.1c-0.37-1.9,0.22-5.23-3.59-4.74c-2.96,0.38-4.87,1.81-5.12,5.08
				c-0.19,2.47-0.99,4.91-0.99,7.36c0,1.82,0.26,4.25,1.42,5.36c3.54,3.4,3.79,7.48,3.89,11.9c0.11,4.95,0.63,9.89,1.09,14.82
				c0.32,3.46,1.36,6.94,1.17,10.35c-0.79,14.15,7.15,24.09,15.8,33.71c1.52,1.69,3.16,2.5,5.49,0.29c1.3-1.23,4.19-1.89,5.91-1.34
				c3.42,1.08,6.51,3.17,9.75,4.84c3.53,1.81,6.95,3.86,10.61,5.34c5.35,2.16,10.91,3.82,16.31,5.87c3.26,1.24,6.29,3.11,9.58,4.2
				c5.62,1.87,11.08,2.9,16.5-1.48c1.78-1.44,5.59-1.55,8.03-0.81C800.48,633.09,808.32,636.18,817.28,639.39z M448.34,658.09
				c-5.13-2.25-3.65-5.31-1.08-8.91c3.6-5.07,8.16-7.81,14.41-8.62c9.2-1.19,16.49-12.34,14.65-21.1c-0.69-3.28-0.29-6.78-0.72-10.14
				c-0.27-2.08-1.16-4.08-1.77-6.12c-2.06,1.12-5.24,1.74-5.99,3.44c-3.4,7.75-9.61,12.19-16.85,15.47
				c-2.07,0.94-4.69,0.59-7.02,1.02c-2.2,0.41-4.4,0.93-6.53,1.64c-1.78,0.59-4.1,2.65-5.05,2.1c-3.63-2.11-5.92,0.17-8.66,1.57
				c-2.05,1.04-4.32,2.55-6.41,2.43c-10.11-0.56-18.78,3.85-27.59,7.59c-9.01,3.82-17.9,7.96-26.58,12.47
				c-3.92,2.03-7.1,5.79-5.92,10.6c1.52,6.18-1.06,9.81-5.8,12.9c-16.18,10.55-25,26.89-34.79,44.15c6.7-2.94,12.4-5.12,17.78-7.89
				c6.8-3.51,13.75-4.46,21.25-3.18c9.75,1.67,19.49,2.5,28.64-2.8c1.69-0.98,3.75-1.77,5.66-1.82c9.79-0.3,16.2-6.96,23.25-12.24
				c5.25-3.93,9.77-8.84,15.1-12.64c4.49-3.2,9.67-5.44,14.58-8.12C437.49,661.81,447.49,662.6,448.34,658.09z M1141.78,304.68
				c0.42,2.32,0.76,4.15,1.19,6.55c2-1.76,3.51-3.08,4.34-3.81c2.36,2.09,5.17,5.58,6.05,5.14c2.66-1.3,4.57-4.13,7.17-6.76
				c6.13,1.95,8.13,0.98,8.11-4.07c3.09-1.32,5.9-2.52,9.59-4.1c-2.42-1.94-3.65-2.93-4.97-3.99c1.33-0.88,2.44-1.61,4.07-2.68
				c-4.83-2.1-9.01-3.92-13.74-5.98c-1.46,1.56-4.02,3.99-6.2,6.73c-2.61,3.29-5.6,3.27-8.77-0.84c2.27,0.22,4.16,0.41,6.87,0.68
				c-1.57-2.23-2.59-3.67-4.14-5.87c3.23,0.63,5.63,1.1,8.79,1.72c-1.25-2.7-2.1-4.54-2.99-6.46c2.53-0.19,4.67-0.35,6.97-0.52
				c-0.66-2.2-1.1-3.67-1.69-5.64c2.65,0,4.71,0,6.87,0c0-1.69,0-3,0-4.45c2.3,0,4.23,0,6.16,0c0.01-0.24,0.02-0.49,0.04-0.73
				c-1.72-0.64-3.45-1.28-6.2-2.3c2.81-1.1,4.54-1.77,6.28-2.45c-0.09-0.59-0.18-1.18-0.27-1.78c-5.89,0-11.77,0-17.66,0
				c-0.05-0.45-0.11-0.91-0.16-1.36c2.15-0.92,4.3-1.84,7.13-3.05c-4.28-1.1-7.51-1.93-10.73-2.76c0.11-0.7,0.22-1.41,0.33-2.11
				c3.59,0,7.17,0,10.76,0c0.05-0.43,0.1-0.87,0.15-1.3c-1.87-1.38-3.75-2.76-6.22-4.58c1.95-0.66,3.11-1.06,3.46-1.18
				c0.37-2.24,0.67-4.02,1.04-6.29c-0.28-0.37-1.03-1.36-1.73-2.28c1.24-1.44,2.25-2.62,3.25-3.79c-0.2-0.46-0.41-0.91-0.61-1.37
				c-3.4,0.83-6.8,1.66-10.94,2.67c1.21-2.29,2.04-3.88,2.88-5.47c-0.26-0.52-0.52-1.03-0.78-1.55c-4.09,1.22-8.18,2.43-12.27,3.65
				c-0.11-0.34-0.23-0.69-0.34-1.03c1.05-1.17,2.1-2.35,4.06-4.55c-4.46,0-7.81,0-11.16,0c-0.08-0.44-0.15-0.89-0.23-1.33
				c1.54-0.77,3.07-1.54,5.23-2.62c-5.04-0.77-9.3-1.42-13.44-2.06c1.34-2.03,2.49-3.78,3.64-5.53c-1.77-0.27-3.55-0.54-5.58-0.84
				c0-1.15,0-2.52,0-3.93c-1.77,0.1-3.3,0.18-5.24,0.28c0.62-4.65,0.77-8.7-5.53-5.71c-0.51,0.24-1.53-0.84-2.27-0.78
				c-1.75,0.13-3.48,0.58-6.06,1.05c2.09,2.52,3.35,4.03,5.42,6.52c-8.41-0.69-15.09,0.37-21.45,5.7c2.91,1.25,4.86,2.1,7.86,3.39
				c-3.23,0.8-5.29,1.31-7.49,1.86c1.04,1.83,1.79,3.14,2.65,4.67c-7.15,0.89-7.15,0.89-8.02,4.48c-5.34,0.66-12.26,0.24-13.83,6.31
				c-1.72,6.64,5.56,7.14,10.6,9.38c-3.16,1.08-5.58,1.91-8.69,2.97c1.91,1.64,2.95,2.53,3.99,3.42c-0.99,1.12-1.75,1.98-2.61,2.96
				c1.96,0.81,3.45,1.43,5.28,2.19c-0.84,1.13-1.42,1.91-2.11,2.83c1.83,0.79,3.33,1.45,6.03,2.62c-4.25,0.89-7.17,1.34-9.97,2.17
				c-1.07,0.32-1.87,1.54-2.79,2.35c0.99,0.65,1.9,1.46,2.98,1.9c1.25,0.51,2.64,0.69,4.14,1.05c-2.8,1.86-5.1,3.38-7.4,4.91
				c1.9,1.38,3.79,2.77,6.88,5.02c-1.55,0.47-3.17,0.96-4.8,1.46c2.4,2.07,3.86,2.87,5.4,3.07c1.58,0.21,3.48-0.73,4.82-0.17
				c3.75,1.57,7.29,3.63,10.89,5.54c3.25,1.72,7.02,2.92,9.57,5.38c3.59,3.46,6.59,2.09,9.87,0.31c1.35-0.73,2.49-1.85,4.76-3.58
				c-0.45,2.69-0.67,3.98-1.14,6.83c3.58-2.03,6.1-3.46,9.01-5.1c0,2.19,0,3.93,0,6.36c2.12-1.82,3.76-3.23,5.76-4.95
				c0.38,1.99,0.63,3.24,0.85,4.42c1.31-0.07,2.37-0.13,3.43-0.19c-0.92,1.51-2.73,1.89-2.99,2.75c-0.71,2.33-0.77,4.86-1.09,7.31
				c1.69-0.54,3.61-0.75,5.02-1.71C1138.72,308.58,1139.84,306.83,1141.78,304.68z M772.18,334.91c0.03-0.43,0.06-0.86,0.09-1.3
				c-2.84-0.57-5.71-1.03-8.51-1.74c-4.63-1.18-9.39-2.12-13.78-3.91c-6.4-2.61-12.56-5.84-18.78-8.88c-2.35-1.15-4.62-2.48-6.9-3.78
				c-3.68-2.1-7.09-5.23-11.04-6.17c-9.19-2.19-18.64-3.32-27.98-4.89c-6.69-1.12-7.36-2.14-5.82-8.74c0.99-4.25-1.06-6.51-5.68-6.38
				c-2.47,0.07-4.94,0.04-7.41-0.04c-7.55-0.26-15.11-0.81-22.66-0.77c-5.11,0.03-10.21,0.92-15.33,1.32
				c-3.08,0.24-6.49-0.52-7.48,3.95c-0.18,0.79-2.06,1.54-3.24,1.72c-5.26,0.81-10.54,1.4-15.82,2.07
				c-6.81,0.86-10.13,3.91-11.82,10.37c-0.32,1.2-1.68,2.7-2.85,3.02c-6.82,1.91-13.76,3.41-20.6,5.28
				c-1.68,0.46-3.61,1.43-4.58,2.77c-1.41,1.94-2.06,4.44-3.22,7.13c4.33,2.58,8.5,0.16,12.92-1.56c2.94-1.15,6.32-2.2,9.35-1.87
				c11.33,1.27,22.43,4.76,34.09,2.72c1.8-0.31,3.92,1.23,5.9,1.91c-0.69,1.75-1.36,3.5-2.08,5.23c-0.29,0.7-0.68,1.36-1.46,2.88
				c3.37-0.51,6.11-0.48,8.49-1.38c5.84-2.22,11.14-0.51,15.41,2.87c4.47,3.54,9.13,4.01,14.38,4.29c5.18,0.28,9.25-1.85,13.59-4.01
				c4.87-2.43,6.94-1.3,7.98,3.65c2.61-0.28,5.23-0.86,7.83-0.78c8.62,0.29,16.95-0.28,25.79,2.58c10.34,3.34,21.99,2.95,33.1,3.43
				c5.83,0.25,11.73-1.12,17.6-1.75c-0.42-0.6-0.85-1.2-1.27-1.8c1.22-2.03,1.97-4.86,3.77-5.9
				C766.38,335.16,769.47,335.35,772.18,334.91z M995.2,364.32c-1.37-2.18-2.42-4.13-3.75-5.88c-1.02-1.33-2.34-3.3-3.62-3.38
				c-2.55-0.15-5.34,0.38-7.73,1.38c-14.28,5.96-29.04,6.67-44.11,4.41c-3.67-0.55-6.85,0.19-8.4,3.74
				c-4,9.18-8.66,17.88-15.95,24.96c-0.78,0.76-1.11,2-1.62,3.04c-4.32,8.9-7.5,18.37-14.19,26.03c-5.27,6.03-4.24,12.06,2.95,15.47
				c5.23,2.47,7.62,6.18,9.26,11.44c1.29,4.12,3.28,8.63,6.36,11.43c5.07,4.62,9.2,9.66,11.61,15.95
				c2.52,6.58,6.84,11.19,12.94,14.57c3.61,2,7.31,4.26,10.11,7.22c7.63,8.1,14.87,16.57,22.11,25.01
				c3.73,4.35,7.14,8.97,11.26,14.17c3.49-3.74,8.54-6.66,9.12-10.3c1.15-7.26-0.15-14.9-0.43-22.24c-4.62,0-8.5-0.34-12.3,0.08
				c-5.34,0.59-6.82-0.38-6.97-5.81c-1.28,0-2.63,0.25-3.84-0.05c-2.25-0.56-4.41-1.45-6.61-2.21c1.37-2.01,2.36-5.28,4.16-5.81
				c5.31-1.56,9.34-7.35,15.93-4.97c0.85,0.3,2.3-1.09,3.5-1.71c-1.35-2.39-2.35-4.17-3.8-6.75c3.19,0,5.33,0,8.38,0
				c-1.19-4.1-1.84-7.7-3.27-10.97c-1.73-3.95-4.79-4.25-7.9-1.25c-0.94,0.91-2.12,2.67-2.86,2.51c-5.61-1.2-9.37,7.74-15.66,2.81
				c-0.37-0.29-2.1,1.63-3.32,1.86c-2.28,0.42-4.64,0.47-6.97,0.42c-1.9-0.04-3.79-0.57-5.69-0.67c-3.53-0.18-7.13,0.14-10.57-0.47
				c-1.3-0.23-2.89-2.39-3.11-3.85c-0.15-1.02,1.59-2.8,2.88-3.45c2.96-1.48,6.19-2.4,9.24-3.71c0.97-0.41,2.54-1.49,2.44-1.99
				c-1.11-5.54,3.37-5.29,6.41-6.17c3.78-1.09,7.69-1.74,11.56-2.53c2.33-0.48,4.69-1.2,7.03-1.17c2.19,0.03,4.37,0.89,7.94,1.7
				c-3.37-5.46-6.04-9.79-8.22-13.33c-5.33,2.6-9.26,4.99-13.52,6.34c-1.67,0.53-4.06-1.3-6.15-1.98c-1.63-0.53-3.31-1.29-4.97-1.3
				c-3.61-0.01-6.2,0.06-9.23-3.61c-3.63-4.39-7.34-7.09-1.91-12.24c-2.83-5.26,0.07-7.95,4.64-9.34
				c-2.12-7.91-0.02-10.55,7.84-10.61c1.5-0.01,3.09,0.39,4.47,0.01c2.32-0.64,3.67-2.28,1.08-4.08c-2.58-1.79-3.04-3.65-1.8-6.36
				c0.33-0.72,0.34-1.91-0.04-2.57c-2.86-4.99-2.18-6.81,3.52-8.54c2.05-0.62,4.15-1.05,6.23-1.57c0.88-2.6,2.44-5.72,4.19-5.83
				c6.11-0.38,10.62-5.37,17.33-4.73c3.44,0.33,7.41-2.89,11.73-1.81C993.57,365.82,994.5,364.77,995.2,364.32z M164,245.16
				c0.01-1.03,0.01-2.05,0.02-3.08c-18.58,0-37.16,0-55.73,0c1.04-1.28,2.21-1.96,3.37-1.95c12.61,0.1,25.21,0.39,37.82,0.47
				c6.93,0.04,14.77,3.66,19.8-4.64c0.27-0.45,1.18-0.57,1.82-0.72c4.84-1.17,9.63-2.6,14.53-3.38c5.06-0.81,10.24-0.8,15.32-1.46
				c5.7-0.74,11.32-2.27,17.03-2.69c13.88-1.02,27.8-1.59,41.7-2.38c0.37-0.02,0.71-0.55,1.75-1.4c-4.93-3.07-9.41-6.16-14.18-8.73
				c-2.51-1.35-3.76-2.52-3.6-5.66c0.1-1.88-1.25-4.34-2.72-5.7c-5.1-4.7-10.44-9.18-15.92-13.43c-1.5-1.16-4.02-2.08-5.75-1.68
				c-9.31,2.14-18.45,3.62-28,0.8c-4.15-1.22-9.42-1.88-12.43,2.48c-4.44,6.43-11.14,9.1-17.61,12.47c-3.24,1.69-5.84,4.56-8.83,6.76
				c-2.33,1.72-4.64,4.14-7.26,4.63c-5.83,1.09-11.52,0.21-16.51-3.77c-1.66-1.32-4.29-2.4-6.28-2.14
				c-5.58,0.73-10.03,3.5-13.79,8.08c-3.49,4.25-7.9,7.9-12.41,11.09c-4.18,2.96-9.08,4.87-13.57,7.42
				c-8.67,4.93-17.66,7.42-27.75,5.27c-3.34-0.71-7.02-0.45-10.45,0.03c-11.84,1.64-23.65,3.52-35.44,5.48
				c-1.08,0.18-2.65,1.56-2.78,2.55c-0.38,2.85-0.13,5.78-0.13,10.04c7.93-2.39,14.78-4.26,21.48-6.55c4.23-1.44,8.04-3.61,12.91-2
				c9.86,3.26,19.56,7.66,30.48,5.52c2.31-0.45,5.16,1.02,7.18,0.19c4.27-1.75,8.5-4.01,12.14-6.84c5.68-4.41,12-6.72,18.95-6.55
				c12.8,0.31,25.58,1.3,38.38,1.77C153.02,245.68,158.52,245.28,164,245.16z M536.34,343.11c-1.31,4.42,0.57,5.66,4.28,6.03
				c1.81,0.18,3.46,1.96,5.19,3.02c-1.5,1.61-2.84,3.41-4.55,4.75c-1.12,0.87-2.76,1.08-4.18,1.55c-3.43,1.13-6.87,2.24-10.82,3.52
				c1.51,1.73,2.53,2.91,3.46,3.96c-4.41,6.95-12.28,3.72-18.28,6.35c0.63,0.94,0.93,1.31,1.15,1.72c1.39,2.6,4.5,6.03,3.75,7.63
				c-1.15,2.43-4.81,4.52-7.72,5.07c-4.63,0.87-9.53,0.38-14.31,0.42c-3.77,0.03-7.55,0.01-10.47,0.01c0.89,2.81,1.8,5.06,2.3,7.4
				c2.49,11.68,4.78,23.4,7.43,35.04c0.28,1.25,2.62,3.13,3.65,2.93c8.28-1.6,16.81-2.73,24.6-5.74c10.8-4.17,20.96-9.98,31.53-14.79
				c2.59-1.18,3.72-2.51,4.38-5.27c0.52-2.15,2.19-4.84,4.07-5.69c6.48-2.92,13.21-5.3,19.96-7.55c4.59-1.53,9.37-2.51,14.65-3.9
				c-7.97-8.59-15.49-16.36-22.62-24.46c-9.2-10.44-19.39-20.03-26.27-32.5c-1.89-3.43-6.68-6.05-10.69-7.34
				c-6.19-1.99-13.18-1.56-17.76-7.54c-0.85-1.11-2.98-1.65-4.55-1.71c-8.77-0.31-17.59-0.99-26.31-0.39
				c-11.93,0.82-23.69,2.68-35.69-0.15c-7.2-1.69-14.42,0.23-21.22,3.53c4.6,0.75,9.11,0.44,13.62,0.13
				c4.26-0.29,8.53-0.91,12.77-0.78c4.42,0.14,8.81,1.46,13.21,1.46c9.6,0.01,19.2-0.53,28.81-0.75c1.64-0.04,3.48-0.06,4.91,0.59
				c7.92,3.62,15.87,7.19,23.54,11.28c6.58,3.51,6.44,6.38,0.18,10.35c-0.15,0.1-0.08,0.57-0.13,1.11
				C530.63,342.6,533.06,342.82,536.34,343.11z M715.62,9.22c-51.54,0-102.2,0-153.14,0c3.44,14.51,9.9,27.36,23.26,33.55
				c20.14,9.32,40.34,19.68,64.29,11.43c8.84-3.05,18.89-2.86,28.32-5.01c5.65-1.29,10.44-2.11,13.41-7.67
				c0.76-1.42,3.34-2.7,5.02-2.61c4.51,0.23,6.62-2.34,8.38-5.73c2.6-5.01,5.3-9.97,7.66-15.09C714.03,15.46,714.6,12.53,715.62,9.22
				z M917.42,314.65c-0.63-1.36-1.16-2.52-1.99-4.32c-1.13,2.17-1.9,3.65-2.31,4.46c-1.27-1.31-2.27-2.74-3.64-3.49
				c-0.38-0.21-2.35,1.46-2.73,2.56c-0.52,1.5-0.14,3.29-0.29,4.95c-0.18,1.94-0.51,3.87-0.77,5.81c-0.44-0.03-0.88-0.06-1.32-0.09
				c0-4.3,0-8.59,0-12.89c-0.49-0.04-0.97-0.08-1.46-0.12c-0.57,2.69-1.14,5.39-1.71,8.08c-0.56-0.1-1.12-0.2-1.68-0.3
				c0.11-1.86,0.44-3.74,0.24-5.56c-0.13-1.19-1.04-2.29-1.61-3.43c-0.99,1.03-2.44,1.89-2.86,3.12c-0.52,1.5-0.28,3.3-0.19,4.95
				c0.32,5.8-1.79,10.68-6.49,13.81c-5.49,3.65-11.31,6.9-17.26,9.75c-5.22,2.5-10.87,4.08-16.23,6.32
				c-3.14,1.31-5.93,3.05-5.13,7.44c0.18,0.97-1.04,2.75-2.07,3.29c-4.54,2.38-9.28,4.37-13.89,6.63c-7.46,3.66-8.58,9.26-2.81,16.77
				c1.65-2.71,3.49-5.05,4.6-7.7c3.52-8.41,10.21-11.58,18.75-12.47c10.23-1.07,20.4-2.76,30.64-3.7c4.22-0.38,8.59,0.31,12.84,0.87
				c6.05,0.8,12.04,2.11,18.1,2.86c3.48,0.43,7.38,1.28,9-3.71c0.3-0.92,2.51-1.67,3.87-1.7c3.47-0.09,7.07-0.24,10.41,0.53
				c14.38,3.31,28.02,0.67,41.41-4.53c1.01-0.39,2.64-1.35,2.59-1.94c-0.49-6.43,5.04-7.04,9.09-9.61c-1.95-6.53,3.57-4.87,6.52-5.67
				c0.96-2.29,1.81-4.3,2.57-6.12c-5.62,0-11.31,0.41-16.89-0.21c-2.16-0.24-4.05-2.81-6.07-4.32c1.59-1.47,3.02-3.19,4.8-4.35
				c2.16-1.41,4.63-2.35,6.96-3.5c-0.16-0.43-0.31-0.86-0.47-1.3c-6.25,0-12.51-0.27-18.74,0.08c-6.31,0.36-8.88-2.28-6.68-8.2
				c-14.8-0.67-29.37-3.97-44.34-0.52c4.04,2.92,10.81-0.86,13.8,5.61C923.13,308.51,920.44,308.74,917.42,314.65z M413.22,325.05
				c-6.24,1.85-8.74,7.15-11.81,12.05c-4.2,6.71-8.8,13.18-13.12,19.82c-1.47,2.26-2.64,4.71-3.94,7.07
				c0.28,0.47,0.55,0.95,0.83,1.42c4.71-0.37,9.42-0.8,14.13-1.09c1.9-0.11,4.11-0.5,5.68,0.26c5.73,2.77,9.93,2.36,14.83-1.8
				c1.3-1.11,3.03-1.71,5.15-2.87c-0.22,3.36-0.36,5.51-0.63,9.6c5.27-2.38,9.11-4.24,13.05-5.85c3.19-1.31,6.44-2.82,9.79-3.21
				c1.38-0.16,2.98,2.5,4.64,3.65c1.18,0.82,2.63,1.8,3.95,1.79c8.4-0.04,16.81-0.3,25.21-0.56c4.73-0.15,9.56-1.14,14.16-0.47
				c4.43,0.65,5.28,4.93,1.61,7.59c-2.54,1.85-5.95,3.02-9.1,3.38c-4.76,0.53-9.64,0.08-14.46,0.05c-6.03-0.03-11.09,2.18-15.54,6.06
				c1.26,1.9,2.14,3.21,3.16,4.75c-2.83,1.93-5.32,3.63-7.81,5.32c0.17,0.56,0.35,1.12,0.52,1.69c2.93-0.52,5.97-0.7,8.75-1.64
				c4.69-1.58,9.13-4.43,13.94-0.12c0.65,0.59,2.53-0.2,4.13-0.38c-3.3-6.13-2.15-7.9,4.24-7.68c2.33,0.08,4.66,0.54,6.97,0.46
				c5.8-0.19,11.68-0.04,17.35-1.05c5.03-0.9,5.21-2.9,1.91-6.73c-1.38-1.61-2.17-3.73-3.23-5.61c2.34-0.91,4.62-2.13,7.05-2.66
				c3.25-0.71,6.61-0.89,10.5-1.37c-4.17-5.52-3.78-6.93,1.72-8.88c2.65-0.94,5.47-1.41,8.12-2.36c2-0.72,3.84-1.88,4.64-2.28
				c-2.74-2.35-5.21-4.6-7.84-6.64c-0.8-0.62-2.07-0.6-3.06-1.03c-2.04-0.88-5.26-1.42-5.82-2.91c-1.37-3.66,2.38-3.87,4.7-4.86
				c0.23-0.1,0.31-0.55,0.64-1.19c-0.75-0.85-1.43-2.07-2.47-2.71c-5.18-3.17-10.44-6.21-15.7-9.23c-0.84-0.48-1.87-0.92-2.81-0.93
				c-10.43-0.12-20.86-0.3-31.28,0.03c-0.9,0.03-1.81,3.57-2.36,5.57c-0.06,0.2,2.37,1.56,3.64,1.57c5.65,0.02,11.3-0.28,16.95-0.38
				c0.74-0.01,1.49,0.5,2.24,0.78c-0.06,0.42-0.11,0.84-0.17,1.26c-9.61,0-19.22,0-29.22,0c0.86-0.67,1.33-1.04,2.55-2
				c-3.09-0.17-5.17-0.28-6.83-0.37c-0.71,3.07-1.26,5.46-1.9,8.24c8.92-0.5,17.12-1.89,25.04,0.79c0.09,0.54,0.19,1.08,0.28,1.61
				c-9.46,2.86-18.92,5.72-28.34,8.57c9.39,0.56,18.88,1.12,28.38,1.69c-0.03,0.4-0.07,0.8-0.1,1.21c-11.12,0-22.23,0-33.93,0
				c-0.22-3.9-0.44-7.6-0.64-11.24c-7.65-0.67-7.62-0.67-8,6.43c-0.06,1.05-0.64,2.06-0.98,3.1c-0.46-0.12-0.92-0.24-1.37-0.37
				c0.34-2.19,1.35-4.57,0.79-6.51c-0.45-1.59-2.77-2.65-3.79-3.54c0.92-0.95,2.01-2.07,3.09-3.19c-1.39-0.75-2.71-1.94-4.17-2.14
				c-2.28-0.31-4.65,0.19-6.98,0.09c-4.21-0.19-6.17,1.22-5.5,5.85c0.48,3.31,0.09,6.75,0.09,10.13c-0.5-0.02-1-0.03-1.5-0.05
				c-0.38-5.04-0.77-10.09-1.15-15.13c-0.77-0.05-1.54-0.11-2.31-0.16c-2.13,6.57-4.27,13.13-6.39,19.69
				c-2.87-3.56-1.37-7.19,0.27-11.15c1.21-2.92,1.95-6.27,1.87-9.41C415.44,331.7,414.09,328.53,413.22,325.05z M292.76,225.8
				c0.17,0.52,0.34,1.03,0.51,1.55c1.75,0.11,3.53,0.05,5.24,0.34c11.63,2.02,15.65,2.18,14.79,14.33c-0.11,1.6,0.82,3.6,1.9,4.88
				c2.45,2.92,5.38,5.44,7.96,8.27c2.45,2.7,5.21,3.08,8.6,2.13c6.43-1.8,12.82-3.05,19.52-0.38c2.21,0.88,5.5,0.5,7.76-0.5
				c4.34-1.93,8.43-4.53,12.38-7.21c4.33-2.94,6.56-2.95,9.76,1.12c1.31,1.67,2.02,3.79,3.18,5.59c0.78,1.22,1.74,3.05,2.83,3.22
				c5.04,0.77,10.19,1.65,15.24,1.38c7.84-0.43,15.63-1.76,21.63-2.49c-1.87-1.56-5.04-4.23-8.24-6.85
				c-0.76-0.62-1.68-1.61-2.46-1.55c-7.74,0.59-9.82-5.44-12.83-10.55c-0.77-1.3-2.25-3.09-3.4-3.09c-4.26,0-6-2.9-8.16-5.7
				c-2.21-2.86-4.94-5.31-7.23-8.12c-3.52-4.33-7.72-8.41-9.99-13.36c-1.9-4.15-3.99-5.05-7.93-4.66c-4.12,0.41-8.3,0.77-12.42,0.53
				c-6.79-0.4-13.55-1.29-20.32-1.97c-3.61-0.36-6.8,0.01-9.46,3.1c-0.99,1.15-3.46,2-4.9,1.63c-4.75-1.23-7.43,0.77-9.96,4.47
				c-1.84,2.7-4.44,4.91-6.81,7.24C297.61,221.43,295.16,223.58,292.76,225.8z M208.22,500.56c18-2.11,34.56-1.41,49.34,10.41
				c0.6-4.67,0.32-8.38,1.61-11.42c5.28-12.39,4.66-25.35,4.74-38.31c0.02-4.04-1.27-5.5-5.36-5.81c-6.55-0.5-13.05-1.73-19.58-2.65
				c8.6-2.57,17.13,3.94,25.64-0.12c4.18,5.63,11.09,0.32,17.11,3.63c-6.05,0-11.1,0-16.96,0c2.85,18.08-0.38,35.2-3.58,52.85
				c7.18-3.13,14.24-5.98,19.1-10.98c4.51-4.64,8.45-7.52,14.83-5.74c2.57-6.33,3.38-12.38,1.18-18.71
				c-1.36-3.91-2.51-7.9-3.52-11.92c-0.99-3.97-2.34-7.32-8.13-6.76c6.84-1.74,1.34-5.3,1.86-7.87c0.23-1.13-1.84-3.76-3.16-3.98
				c-5.06-0.86-10.23-1.07-15.37-1.42c-3.72-0.25-4.59,1.6-3.38,5.53c4.33,0,8.57,0,12.8,0c-8.32,1.77-16.46,1.73-24.65-0.45
				c-9.89-2.63-19.82-5.7-31.59-3.44c-1.33,7.78-3.9,16.04-3.74,24.24c0.11,5.83-0.4,11.05-2.47,16.29
				C212.92,489.12,210.79,494.25,208.22,500.56z M816.59,367.66c-3.76-1.91-6.26-3.17-9.58-4.86c2.54-1.54,4.04-2.45,5.56-3.38
				c-0.4-1.25-0.77-2.42-1.19-3.72c1.78-0.75,3.24-1.37,4.7-1.98c0.04-0.52,0.09-1.05,0.13-1.57c-4.06-0.67-8.14-1.23-12.17-2.02
				c-5.97-1.18-12.43-1.48-17.68-4.13c-3.9-1.97-7.06-3.19-11.1-1.99c-20.35,6.09-41.01,5.89-61.79,2.95c-1.97-0.28-4.3-0.1-5.8-1.12
				c-7.43-5.03-15.75-3.87-23.73-3.27c-6.2,0.46-12.31,2.53-18.39,4.15c-3.31,0.88-3.6,3.22-1.51,5.65c1.14,1.33,2.6,2.65,4.19,3.28
				c6.15,2.46,12.49,4.48,18.6,7.04c1.67,0.7,4.23,3.31,3.94,4c-1.75,4.07,0.91,4.63,3.57,5.35c2.55,0.69,5.12,1.66,7.72,1.82
				c22.2,1.31,44.4,2.88,66.62,3.51c14.3,0.4,28.65-0.63,42.07-6.61C812.46,369.98,814.09,368.99,816.59,367.66z M314.52,189.05
				c-0.95,1.64-1.49,3.08-2.46,4.12c-2.64,2.84-5.53,7.83-8.21,7.76c-5.95-0.16-11.62,0.59-17.36,1.56
				c-4.39,0.74-8.96,1.01-13.12,2.44c-7.5,2.57-14.97,4.28-22.95,3.48c-1.2-0.12-2.52,0.84-3.78,1.31c0.58,1.12,0.88,2.59,1.78,3.32
				c10.98,8.92,23.56,13.72,37.73,14.15c0.92,0.03,2.18-0.05,2.73-0.62c5.64-5.85,11.02-11.97,16.82-17.67
				c1.88-1.85,4.84-3.78,7.21-3.67c3.87,0.18,6.6-0.38,9.15-3.46c1.02-1.22,3.5-1.81,5.27-1.73c13.65,0.62,27.17,3.46,40.97,2.28
				c1.65-0.14,4.5,1.84,5.1,3.46c3.03,8.24,9.99,12.8,16.61,18.71c-3.96-5.93,0.06-9.79,2.4-14.02c0.23-0.42,0.5-0.98,0.42-1.41
				c-1.1-5.37,2.27-6.47,6.47-7.12c3.04-0.47,4.24-2.3,4.29-5.63c0.04-2.35,1.67-4.69,2.67-7c0.38-0.88,1.63-1.89,1.42-2.46
				c-2.03-5.69,1.99-4.99,5.26-5.22c5.39-0.37,3.11-5.48,4.76-8.17c0.81-1.33,0-3.76-0.37-5.63c-0.08-0.4-1.42-0.77-2.17-0.77
				c-6.66-0.01-13.33-0.14-19.97,0.19c-4.12,0.2-9.47,8.91-8.22,12.44c2.03,5.72,5.36,10.43,10.04,14.28c1.1,0.9,1.82,2.25,2.72,3.39
				c-0.48,0.37-0.96,0.73-1.44,1.1c-7.13-7.37-14.82-14.36-19.33-23.87c-1.45-3.04-2.34-3.75-5.73-3.13
				c-6.82,1.24-13.51,1.31-18.5-5.3c-2.77-3.68-6.48-3.54-9.91-0.05c-7.3,7.42-14.37,15.07-21.53,22.63
				c0.19,0.52,0.37,1.05,0.56,1.57c1.59,0,3.32,0.42,4.74-0.07c12.7-4.38,26.13-1.41,39.02-3.9c1.92-0.37,4.1,0.58,6.16,0.92
				c0.01,0.51,0.03,1.02,0.04,1.53c-2.48,1.57-4.96,3.14-7.72,4.9c3.78,0.73,7.63,0.41,9.9,2.14c3.67,2.81,6.91,2.44,10.65,1.23
				c1.04-0.33,2.12-0.53,3.18-0.79c-4.62,4.66-10.03,4.97-14.6,1.89c-1.27-0.86-2.98-1.28-4.54-1.43c-1.97-0.18-4.02,0.37-5.97,0.14
				c-1.49-0.18-2.9-1.08-4.34-1.66c0.97-1.14,1.76-2.52,2.94-3.37c1.52-1.1,3.34-1.77,5.04-2.63c-2.55-0.75-4.76-0.51-6.97-0.27
				c-9.7,1.05-19.38,2.47-29.11,3.02C326.5,192.26,320.27,193.98,314.52,189.05z M934.25,627.48c-2.98-2.74-5.98-5.35-8.81-8.13
				c-3.23-3.18-6.11-6.74-9.53-9.69c-2.48-2.15-5.56-3.61-8.4-5.32c-7.59-4.57-14.77-10-24.16-10.61c-1.47-0.1-3.54-1.14-4.17-2.35
				c-3.2-6.12-8.96-7.41-14.89-8.75c-4.29-0.97-6.12,0.48-8.29,4.53c-7.9,14.74-16.53,29.09-25.05,43.49
				c-2.32,3.92-5.23,7.5-8.37,11.95c6.85,1.76,12.75,4.68,18.52,4.42c8.63-0.39,16.99-4.6,25.97-2.33c0.27,0.07,0.64-0.16,0.94-0.3
				c8.76-4.28,18.05-7.76,26.14-13.07c7.93-5.2,15.02-6.78,23.49-2.06c2.22,1.24,5.38,1.48,7.99,1.18
				C928.44,630.1,931.12,628.61,934.25,627.48z M294.71,633.47c5.67-0.13,14.49-5.46,18.65-10.36c1.76-2.07,2.03-3.65,0.44-6.15
				c-1.04-1.63-1.36-4.43-0.73-6.28c2.35-6.92,1.28-9.24-5.91-11.47c-3.47-1.07-6.93-2.27-10.28-3.68c-3.73-1.57-4.21-4.17-1.25-6.92
				c2.92-2.7,6.17-5.03,9.16-7.65c1.03-0.9,2.62-2.43,2.4-3.27c-1.45-5.52,2.59-6.42,6.11-7.88c2.31-0.96,4.59-1.97,6.88-2.96
				c0.03-0.52,0.06-1.04,0.09-1.56c-26.46-14.46-52.64-29.29-70.86-54.37c0.61,1.5,1.22,3.01,1.93,4.73
				c-1.35,0.84-3.06,1.34-3.72,2.46c-0.78,1.32-1.39,3.58-0.77,4.74c2.24,4.21,2.14,4.71-2.75,5.52c-0.92,0.15-1.8,0.55-2.42,0.74
				c-0.96,8.77-1.88,17.15-2.79,25.46c-6.63-2.95-6.63-2.95-8.85-1.1c0.2,0.26,0.35,0.6,0.61,0.75c0.42,0.24,0.89,0.43,1.37,0.53
				c7.09,1.44,12.74,4.48,16.85,11.05c3.14,5.01,8.56,7.64,15.03,7.21c4.48-0.3,9-0.24,13.48-0.02c5.07,0.25,9.44,2.31,14.11,6.54
				c-3.93,1.57-6.95,2.78-10.2,4.08c1.43,1.69,2.55,3.02,4.49,5.32c-3.51,2.21-6.67,3.99-9.55,6.14c-0.81,0.6-1.36,3.18-1.05,3.39
				c2.95,1.98,5.93,4.46,9.26,5.2c3.89,0.86,6.25,1.96,5.84,6.28c-0.33,3.47,1.67,4.11,4.62,4.21c4.57,0.15,5.9,2.16,4.36,6.41
				c-0.79,2.18-2.14,4.15-2.94,6.32C295.62,628.82,295.33,630.9,294.71,633.47z M246.86,239.56c0.51,5.8-1.75,6.81-5.67,5.7
				c-1.26-0.35-2.65-0.23-3.97-0.38c-5.92-0.66-11.83-1.38-17.75-2.01c-4.03-0.43-8.58-2.12-12.03-0.87
				c-11.28,4.1-22.29,2.4-33.37,0.08c-3.1-0.65-4.89-0.07-6.73,2.4c-6.52,8.73-13.3,17.27-19.81,26.01
				c-2.74,3.68-5.06,7.69-8.12,12.4c2.85-0.62,5.12-0.63,6.89-1.58c6.33-3.41,12.52-7.09,18.69-10.8c0.91-0.55,1.24-1.97,2.09-2.7
				c2.4-2.06,4.72-5.23,7.45-5.69c6.12-1.04,12.48-1.01,18.73-0.84c5.4,0.14,10.77,1.27,16.17,1.6c5.9,0.36,12.31,1.82,17.66,0.15
				c15-4.69,30.11-1.2,45.1-2.21c3.33-0.22,6.67-0.56,10-0.58c1.39-0.01,3.23,0.26,4.09,1.15c1.66,1.74,2.52,4.25,4.19,5.98
				c1.39,1.44,3.69,3.41,5.19,3.11c9.64-1.9,19.61-3.25,27.96-11.5c-1.23-0.82-2.57-1.51-3.66-2.49c-5.45-4.89-10.95-9.7-12.68-17.41
				c-0.22-0.96-2.81-1.94-4.33-1.96c-6.62-0.13-13.24,0.03-19.86,0.23c-1.58,0.05-4.36,0.38-4.54,1.1
				c-1.91,7.55-7.05,3.79-11.15,3.39C260.84,241.21,254.3,240.4,246.86,239.56z M1193.85,273.21c0.34-0.31,0.68-0.62,1.02-0.93
				c0-32.57,0-65.13,0-97.73c-0.71-0.21-1.17-0.48-1.63-0.47c-14.03,0.13-28.23-1.48-41.83,3.41c-1.45,0.52-2.81,2.6-3.3,4.23
				c-1.79,6-3.13,12.14-4.79,18.18c-0.74,2.71-2.16,5.27-2.69,8.01c-0.71,3.71-2.6,9.09-0.9,10.97c3.57,3.95,7.19,9.29,14.01,8.88
				c1.45-0.09,2.99,0.86,4.44,1.44c1.96,0.79,3.95,1.58,5.79,2.6c1.19,0.66,2.14,1.74,3.2,2.63c-3.1,3.53,0.54,7.46-0.64,11.13
				c-0.47,1.46-0.91,3.06-0.78,4.54c0.18,2,0.92,3.94,1.43,5.91c-0.58,0.54-1.16,1.08-1.73,1.61c2.67,1.07,5.46,1.92,7.98,3.27
				c4.44,2.38,8.84,4.72,13.84,5.75c0.74,0.15,1.19,1.49,1.89,2.17C1190.69,270.31,1192.28,271.75,1193.85,273.21z M899.6,297.99
				c-1.9-0.44-2.97-0.76-4.06-0.93c-6.5-1.04-13.1-1.67-19.47-3.23c-3.66-0.9-5.35-0.04-7.12,3.03
				c-7.84,13.59-21.01,18.7-35.42,21.29c-5.96,1.07-11.83,1.79-16.53,6.1c-5.97,5.47-12.8,9.2-20.72,11.32
				c-3.39,0.91-6.28,3.68-9.4,5.6c0.08,0.66,0.15,1.33,0.23,1.99c3.14,1.18,6.2,2.86,9.45,3.44c5.88,1.05,11.87,1.74,17.84,2.05
				c5.64,0.29,8.38,3.29,9.54,8.39c0.75,3.28,1.54,6.54,2.41,10.24c6.57-3.23,12.37-6.02,18.1-8.96c1.08-0.55,2.7-1.79,2.62-2.57
				c-0.77-7.67,5.14-8.6,10.17-10.67c8.13-3.36,16.29-6.7,24.18-10.56c4.98-2.43,9.94-5.95,10.4-11.96
				C892.49,313.86,893.67,305.51,899.6,297.99z M467.06,395.33c-6.45,0-11.5-0.07-16.55,0.06c-1.07,0.03-2.19,0.64-3.18,1.18
				c-5.92,3.27-9.77,8.14-12.2,14.52c-1.63,4.28-3.56,8.92-6.7,12.05c-7.42,7.41-11.66,16.34-15.45,25.85
				c-2.55,6.39-5.47,13.05-9.86,18.2c-8.24,9.66-16,19.48-22.06,30.69c-2.45,4.53-0.06,6.57,3.04,8.4
				c4.01,2.37,11.48,0.88,14.76-2.52c4.49-4.67,9.03-9.3,13.53-13.96c7.27-7.52,14.7-14.79,18.74-24.92
				c1.63-4.09,4.83-8.23,8.44-10.7c6.12-4.17,13.23-6.85,19.71-10.54c1.97-1.12,4.91-4.53,4.54-5.15c-3.75-6.2-1.99-12.46-1.1-18.77
				c0.06-0.42-0.32-0.94-0.57-1.37c-2.32-3.98-1.21-7.22,1.13-11.03C465.36,403.93,465.81,399.55,467.06,395.33z M420.42,568.02
				c-6.63,2.15-12.08,3.01-16.55,5.57c-6.47,3.71-12.56,2.95-18.56,0.15c-4.67-2.18-8.78-1.54-13.44-0.31
				c-5.7,1.5-11.63,2.34-17.51,2.9c-6.54,0.62-13.16,0.34-19.69,0.95c-6.09,0.57-12.25,2.96-18.15,2.35
				c-4.62-0.47-7.12,1.04-9.98,3.6c-1.86,1.66-3.81,3.22-5.74,4.8c-3.28,2.69-3.28,4.51,0.52,6.17c3.79,1.65,7.89,2.56,11.77,4.03
				c5.27,2,6.58,6.01,4.56,11.16c-0.78,1.99-0.89,4.86-0.03,6.74c1.52,3.31,1,5.78-1.35,8.1c-1.34,1.33-2.97,2.37-4.46,3.55
				c0.32,0.38,0.63,0.76,0.95,1.13c0.73-0.26,1.49-0.47,2.19-0.79c9.81-4.48,19.61-8.96,29.41-13.46c4.07-1.87,8.09-3.83,12.16-5.68
				c8.89-4.05,17.53-8.92,26.78-11.86c11.46-3.63,22.94-6.91,33.13-13.48c1.3-0.84,2.68-2.39,2.94-3.81
				C420.03,576.2,420.07,572.48,420.42,568.02z M1087.43,441.14c7.18,0.41,13.1,0.68,19,1.13c1.09,0.08,2.51,0.53,3.13,1.32
				c2.71,3.43,6.42,3.18,9.3,1.51c2.07-1.2,2.97-4.42,4.91-7.57c0.09-0.02,1.6-0.28,3.54-0.63c-1.39-1.96-2.25-3.18-3.05-4.29
				c2.93-0.94,5.43-1.75,8.81-2.84c-2.05-1.36-3.04-2.02-4.27-2.83c1.67-1.11,2.92-1.94,4.49-2.99c-9.79-2.32-9.79-2.32-8.71-8.01
				c-1.28-0.75-2.57-1.5-4.5-2.63c2.66-1.19,4.45-1.99,6.8-3.04c-1.86-1.61-3.05-2.64-4.45-3.86c0.75-0.55,1.3-0.88,1.76-1.31
				c1.7-1.59,3.36-3.22,5.04-4.83c-1.54-1.37-2.9-3.04-4.64-4.04c-3.74-2.16-7.68-3.98-11.45-5.89c1.94-2.33,3.59-4.31,5.94-7.13
				c-3.83-0.19-6.15-0.31-8.02-0.4c0.34-2.41,0.58-4.13,0.82-5.84c-1.53,0.55-3.1,1-4.58,1.67c-2.96,1.34-5.87,4.44-8.25-1.11
				c-0.37-0.85-4.58-0.38-6.88,0.11c-0.77,0.16-1.11,2.3-1.71,3.67c-7.05-6.73-9.05-6.98-11.46-2.71c-3.44-0.72-6.56-1.92-9.67-1.9
				c-4.07,0.03-5.31,3.41-4.65,6.72c0.26,1.32,2.87,2.69,4.59,3.01c0.98,0.18,2.34-1.73,3.53-2.7c-1.55-0.21-3.1-0.4-4.64-0.67
				c-0.24-0.04-0.43-0.43-0.64-0.66c0.05-0.43,0.11-0.87,0.16-1.3c2.12,0.31,4.37,0.32,6.27,1.15c0.48,0.21,0.44,3.58-0.42,4.55
				c-2.91,3.26-8.39,2.37-10.72-1.39c-4.01-6.47-4.87-6.7-11.82-3.02c0.35,1.09,0.71,2.2,1.16,3.57c-2.56,0.5-4.73,0.92-8.14,1.58
				c2.51,1.99,4.11,3.25,6.04,4.77c-1.6,1.04-2.75,1.78-4,2.59c0.91,1.37,1.61,2.42,2.74,4.12c-3,1.06-5.65,1.88-8.2,2.93
				c-2.63,1.08-5.66,2.28-4.81,5.92c0.82,3.49,3.46,5.09,7.1,4.95c1.68-0.07,3.36-0.01,5.04-0.01c0.04,0.35,0.08,0.7,0.12,1.05
				c-1.28,0.86-2.56,1.73-3.84,2.59c0.33,0.18,0.65,0.35,0.98,0.53c-0.4,1.61-0.8,3.21-1.21,4.86c2.75,2.03,4.32-0.11,6.34-2.08
				c2.21-2.14,4.76-4.78,7.52-5.31c3.95-0.76,8.24,0.16,12.37,0.47c0.54,0.04,1.21,0.65,1.53,1.18c2.92,4.77,7.29,6.56,12.63,5.91
				c1.33-0.16,3.35-1.22,3.58-2.21c0.27-1.15-0.83-2.91-1.8-3.97c-0.92-1.01-3.53-1.44-3.54-2.17c-0.11-5.4-3.77-4.84-7.29-4.89
				c-1.32-0.02-2.63-0.46-3.95-0.71c0.01-0.4,0.03-0.81,0.04-1.21c1.98-0.34,3.95-0.68,6.06-1.04c-0.58-1.42-1.08-2.63-1.63-3.97
				c8.39-0.59,10.81-2.64,11.54-11.22c2.47,3.04,3.92,6.5,5.91,6.84c2.29,0.4,5.06-1.96,7.62-3.11c2.49,6.46,2.79,6.59,9.31,4.17
				c0.83,1.67,1.58,3.18,2.39,4.81c-3.42,3.07-6.87,6.16-10.29,9.23c0.58,1.31,1.14,2.55,1.36,3.06c-2.91,1.62-7.7,3.3-7.57,4.37
				c0.72,5.73,5.95,1.55,9.02,3.21c0.15,1.13,0.31,2.35,0.47,3.52c6.16,0.07,6.16,0.07,7.83,4.24c-4.7-1.82-8.64-3.34-12.59-4.87
				c-1.14-0.37-3.73,0.7-3.92,2.09C1096.14,433.76,1092.56,437.08,1087.43,441.14z M1054.97,365.69c-1.52-1.81-2.71-3.23-4.03-4.8
				c2.7-1.97,9.17,1.56,7.26-4.41c5.75-2.92,10.99-5.58,16.61-8.43c-2.32-1.92-4.15-3.44-5.97-4.94c4.86-1.44,9.03-2.67,13.2-3.91
				c-0.14-0.54-0.29-1.08-0.43-1.63c-4.06-1.01-8.09-2.23-11.55,1.94c-0.81,0.98-2.16,1.5-3.23,2.27
				c-5.1,3.67-10.18,7.35-15.27,11.04c-0.12,0.09-0.19,0.27-0.24,0.43c-1.6,4.85-5.76,5.97-9.96,5.89
				c-10.51-0.19-19.33-4.34-27.04-12.88c2.57-1.17,4.54-2.06,6.74-3.06c-0.34-1.29-0.7-2.63-0.99-3.71
				c3.68-4.58,13.2,3.47,13.66-7.27c3.1,0.65,6.18,1.29,8.84,1.85c1.04-2.18,1.94-4.88,2.35-4.81c4.13,0.71,7.38-0.85,10.47-3.21
				c0.05-0.04,0.46,0.39,0.67,0.58c-0.36,2.18-0.72,4.33-1.1,6.61c-2.81-0.89-5.52-1.75-8.53-2.71c-0.24,2.34-0.39,3.91-0.61,6.01
				c-3.23-0.55-6.07-1.04-8.96-1.53c-1.66,7.98-8.32,3.52-11.7,6.01c-1.62,2.16-3.24,4.32-4.37,5.83c1.5,1.82,2.95,3.56,4.77,5.76
				c1.78-0.12,4.38-0.29,7.47-0.49c-0.34,1.57-0.57,2.65-0.79,3.68c8.96,3.21,11.13,2.56,16.97-4.75c0.47-0.59,1.29-1.22,1.98-1.26
				c6.24-0.36,9.73-3.58,10.09-9.36c2.17-0.53,4.95-0.47,5.38-1.44c2.15-4.87,6.15-4.06,10.06-4.12c1.3-0.02,2.58-0.66,3.88-1.01
				c0-0.4,0-0.81,0-1.21c-5.04-1.64-10.08-3.27-15.15-4.92c-0.1-3.36,2.86-8.22-2.24-10.14c-3.7-1.4-8.12-0.89-12.25-1.24
				c0.25-0.85,0.49-1.69,0.66-2.26c-2.5-0.65-5.76-0.51-6.71-1.93c-3.72-5.54-6.43-2.18-9.6,0.3c-1.59,1.24-3.75,3.17-5.26,2.86
				c-5-1.03-5.95,0.91-4.76,5.28c-1.49,0.52-2.82,0.99-4.35,1.53c1.06,1.36,1.89,2.43,3.45,4.43c-3.52-0.44-5.94-0.76-8.36-1.03
				c-2.48-0.28-4.96-0.5-7.45-0.75c0.72,2.01,1.44,4.02,2.36,6.57c-2.63,0.31-5.37,0.64-8.69,1.04c1.27,1.98,2.05,3.22,3.41,5.34
				c-2.67,0-4.47-0.25-6.17,0.08c-1.42,0.27-2.71,1.21-4.06,1.85c1.05,0.79,2.11,1.57,3.15,2.38c0.77,0.6,1.51,1.23,3.05,2.5
				c-3.92,0-6.77-0.44-9.38,0.15c-1.87,0.42-3.79,1.94-4.93,3.52c-0.37,0.52,1.33,3.16,2.62,4.12c2.04,1.52,4.52,2.45,7.13,3.79
				c-0.96,1.2-1.77,2.22-3.53,4.42c4.56,0,7.86,0,11.15,0c0.04,0.41,0.09,0.82,0.13,1.23c-2.7,1.39-5.4,2.79-8.1,4.18
				c0.08,0.59,0.16,1.18,0.23,1.77c2.42,0.6,4.98,2.04,7.23,1.65c6.71-1.17,13.17-2.22,19.17,2.28c0.65,0.49,1.98-0.02,2.95,0.17
				C1036.64,373.86,1044.96,367.01,1054.97,365.69z M96.43,527.97c-0.23,0.45-0.47,0.9-0.7,1.35c6.89,3.84,13.47,8.49,20.76,11.32
				c9.6,3.72,19.37,9.84,30.24,2.85c1.38-0.89,3.57-0.64,5.39-0.69c7.99-0.26,15.98-0.28,23.96-0.72c2.57-0.14,5.61-0.64,7.51-2.15
				c7-5.53,13.69-11.47,20.33-17.45c1.38-1.24,2.52-3.15,2.98-4.95c1.07-4.24,3.75-6.3,7.73-7.57c3.97-1.26,7.76-3.09,11.63-4.66
				c-0.12-0.47-0.24-0.94-0.36-1.42c-1.24-0.15-2.48-0.36-3.72-0.45c-14.74-0.97-29.5-1.71-44.21-2.97
				c-6.08-0.52-10.96,0.06-15.91,4.55c-9.47,8.59-20.39,14.38-33.66,15.96C117.61,522.24,107.07,525.56,96.43,527.97z
				M1023.78,279.62c-0.38-0.45-0.76-0.89-1.15-1.34c1.12-2.44,2.29-4.85,3.33-7.33c0.39-0.94,0.36-2.05,0.71-3.02
				c0.53-1.46,0.84-3.37,1.92-4.18c2.25-1.67,4.81-3.33,2.95-6.19c-1.07-1.65-3.78-2.39-5.89-3.14c-1.35-0.48-2.96-0.27-4.46-0.29
				c-8-0.09-16.02,0.21-23.98-0.35c-5.37-0.38-12.32-0.28-15.56-3.45c-3.9-3.83-7.36-4.11-11.76-4.19c-1.82-0.03-4.08,0.26-5.35-0.68
				c-3.7-2.71-6.59-1.01-9.53,1c-9.33,6.38-18.58,12.86-27.94,19.19c-2.46,1.67-5.33,2.73-7.79,4.4c-5.23,3.56-10.3,7.33-15.44,11.01
				c0.22,0.62,0.44,1.24,0.67,1.86c1.81,0.2,3.65,0.74,5.41,0.54c9.2-1.07,18.37-2.49,27.59-3.43c2.92-0.3,6.05,0.18,8.92,0.92
				c5.11,1.31,10.37,2.7,14.97-0.52c5.98-4.18,11.92-4,18.47-2.01C994.41,282.84,1009.13,279.82,1023.78,279.62z M709.3,230.17
				c-2.85,14.7-12.37,24.65-25.23,32.38c5.24,0.78,10.03,0.47,14.76,0.88c3.99,0.34,10.17,0.27,11.41,2.55
				c3.33,6.12,7.97,5.51,13.11,5.36c3.82-0.11,7.63-0.1,11.45-0.07c6.31,0.04,12.64,0.51,18.91,0.11c6.28-0.4,12.27,4.87,18.72,0.72
				c0.75-0.48,2.24,0.26,3.39,0.33c5.12,0.3,10.25,0.8,15.36,0.73c2.08-0.03,4.14-1.34,6.21-2.07c0.04-0.66,0.08-1.31,0.13-1.97
				c-2.09-0.77-4.12-1.98-6.26-2.22c-5.95-0.69-11.96-0.85-17.92-1.47c-2.95-0.31-5.75-1.23-2.9-6.3c-8.28,3.14-8.14-5.77-13.95-6.87
				c1.21-0.85,1.76-1.55,2.2-1.49c7.3,0.97,15.06,0.77,20.12,7.65c2.01,2.73,3.91,5.69,8.83,5.02c-2.02-3.82-3.58-7.29-5.62-10.45
				c-1.85-2.86-3.72-6.51-6.51-7.79c-4.91-2.25-6.31-5.34-5.63-10.2c0.15-1.07-0.22-2.21-0.37-3.58c-6.52,0.7-13.07-1.51-18.76,3.57
				c-1.54,1.38-5.17,1.14-7.63,0.62c-5.49-1.15-10.82-3.09-16.28-4.42c-2.69-0.66-5.53-0.81-8.32-0.96
				C715.65,230.05,712.77,230.17,709.3,230.17z M1043.97,397.87c-0.52-3.94-2.6-8.54-1.11-10.85c2.34-3.64,6.74-6.62,10.9-8.24
				c4.09-1.6,9.65,1.22,12.86-3.94c0.12-0.19,0.63-0.19,0.95-0.21c5.02-0.25,10.59-1.97,14.92-0.36c5.38,2,9.33,0.95,13.78-1.24
				c0.69-0.34,1.66-0.08,2.47-0.22c9.34-1.6,18.1-4.68,27.38-10.59c-6.25-3.05-11.21-5.99-16.53-7.89c-4.8-1.72-9.97-2.49-15.03-3.36
				c-8.19-1.4-16.41-2.84-24.01,2.39c-4.58,3.15-9.33,6.07-14,9.09c1.88,3.63,1.21,5.31-2.54,5.96c-3.38,0.59-6.67,1.8-9.92,2.95
				c-9.35,3.32-18.61,4.6-28.01,0.02c-1.76-0.86-4.04-0.64-4.69-0.72c-1.56,3.61-2.52,5.86-3.68,8.55
				c10.46,0.45,15.23,7.5,14.96,16.72c-0.11,3.67-5.84,7.17-8.9,10.63c1.16,1.65,2.83,3.86,4.32,6.19c1.02,1.61,2.95,4.01,2.44,4.94
				c-1.1,2-3.31,4.23-5.42,4.66c-6.6,1.36-8.08,2.58-8.84,9.03c12.53,7.55,19.39-1.03,26.91-8.35c2.52-1.59,5.6-2.89,6.87-5.19
				c2.03-3.68-2.54-3.82-4.26-5.37c-4.12-3.72-3.84-8.23,1.02-10.96C1039.28,400.14,1041.84,398.95,1043.97,397.87z M877.44,291.96
				c16.44,4.6,32.46,0.64,49.72,2.33c-2.73,3.04-4.63,5.16-7.26,8.09c2.39,0,3.82,0.05,5.24-0.01c3.98-0.16,7.98-0.72,11.93-0.47
				c10.45,0.66,20.87,1.69,31.31,2.51c1.65,0.13,3.4-0.34,4.96,0.04c1.42,0.35,2.65,1.49,3.97,2.28c-0.95,1.03-1.75,2.33-2.9,3.04
				c-1.55,0.96-3.38,1.46-6.08,2.56c1.73,0.41,2.32,0.66,2.92,0.68c5.83,0.12,11.68-0.04,17.48,0.43c2.01,0.16,3.89,1.89,5.83,2.9
				c-1.46,1.5-2.72,3.3-4.43,4.42c-2.29,1.5-4.93,2.47-7.41,3.66c0.17,0.62,0.34,1.25,0.5,1.87c5.65,0,11.3,0.11,16.95-0.06
				c1.76-0.05,4.16-0.28,5.12-1.4c3.44-4.05,7.02-3.72,10.95-1.02c1.46-1.84,2.74-3.46,3.2-4.03c-2.01-2.52-3.71-4.72-5.48-6.85
				c-0.8-0.96-1.62-2-2.65-2.62c-3.2-1.93-6.2-13.05-3.46-15.32c3.28-2.71,7.45-4.39,11.34-6.3c2.14-1.05,4.5-1.66,6.77-2.46
				c-0.09-0.72-0.18-1.45-0.27-2.17c-1.71-0.14-3.41-0.38-5.12-0.4c-14.27-0.16-28.62,2.03-42.73-2.03
				c-4.9-1.41-9.58-0.73-14.48,1.56c-3.65,1.71-8.5,2.38-12.46,1.63c-13.06-2.47-25.74-1.7-38.44,1.88
				c-2.28,0.64-5.11,0.61-7.33-0.15c-5.09-1.73-9.84-0.88-14.62,0.77C886.44,288.71,882.43,290.18,877.44,291.96z M465.22,478.57
				c-2.31,2.45-4.31,5.05-6.81,7c-1.29,1.01-3.48,0.85-5.27,1.22c0.64-1.73,0.79-4,2.01-5.09c4.56-4.07,8.94-7.88,11.47-13.93
				c2.53-6.05,3.29-5.71-2.69-9.21c-1-0.59-1.84-1.51-2.9-1.94c-8.34-3.37-21.01-5.42-25.68,6.44
				c-5.11,12.99-14.56,22.52-24.08,32.05c-5.42,5.43-11.19,10.52-17.68,16.59c2.31,0.88,4.55,1.59,6.67,2.58
				c2.81,1.31,5.79,2.49,8.22,4.35c3.7,2.84,6.7,6.63,10.52,9.26c6.86,4.73,15.46,1.57,17.83-6.48c2.49-8.47,8.83-14.61,14.6-20.04
				c5.52-5.18,10.36-10.43,14.46-16.67C467.77,481.83,467.49,479.93,465.22,478.57z M6.01,465.85c8.58-7.81,16.46-14.84,24.13-22.11
				c1.31-1.24,1.63-3.49,2.48-5.24c0.93-1.9,1.45-4.61,3-5.51c11.91-6.93,23.89-13.76,36.13-20.09c6.92-3.58,14.37-6.16,21.07-8.97
				c-1.46-1.83-3.62-3.37-3.65-4.95c-0.03-1.22,2.45-2.92,4.14-3.65c3.34-1.43,6.94-2.25,10.38-3.48c4.74-1.69,7.59-6.1,7.56-11.41
				c-4.86-2.36-8.95,4.98-14.12,1.58c1.14-2.37,2.06-4.27,3.54-7.35c-3.52,1.55-5.64,3.31-7.72,3.26
				c-6.45-0.15-10.95,3.58-15.98,6.62c-5.8,3.5-12.19,6.03-17.98,9.55c-15.67,9.56-29.54,22.35-48.37,26.18
				c-1.76,0.36-4.37,2.86-4.41,4.42C5.86,438.15,6.01,451.6,6.01,465.85z M1057.1,543.65c2.51-0.47,4.14-0.78,6.29-1.19
				c-1.77-1.52-3-2.58-4.57-3.93c2.57-0.94,4.48-1.64,7.4-2.7c-2.65-1.28-4.24-2.04-6.35-3.06c4.57-1.05,8.47-1.95,12.37-2.85
				c-2.13-2.6-4.65-3.88-0.97-6.34c-3.64-1.14-6.77-2.99-9.87-2.94c-10.96,0.15-11.02,0.38-8.56-10.13c0.33-1.42,1.92-3.72,2.72-3.64
				c1.98,0.2,3.84,1.55,5.74,2.44c-0.24,0.46-0.48,0.91-0.72,1.37c-1.69-0.55-3.37-1.1-6.06-1.98c1.24,2.63,1.97,4.19,2.71,5.76
				c-1.51,0.54-2.81,0.99-4.11,1.45c-0.07,0.62-0.14,1.24-0.22,1.86c2.46,0.39,5.34,1.78,7.27,0.95c3.22-1.39,5.5-1.04,8.29,0.63
				c0.79,0.48,3.58-0.48,3.65-1.02c0.38-2.83,0.18-5.74,0.18-8.67c4.67-1.03,9.99-2.22,15.32-3.4c-0.06-0.62-0.12-1.25-0.18-1.87
				c-3.55,0-7.1,0-11.48,0c1.54-2.02,2.5-3.29,4.08-5.37c-3.44,0-5.93,0-9.28,0c1.95-2.13,3.22-3.53,4.49-4.92
				c-0.28-0.39-0.56-0.78-0.85-1.16c-1.82,0.78-3.64,1.56-4.28,1.83c-3.12-2.28-5.49-4.01-7.87-5.75c-1.61,2.25-3.22,4.51-4.96,6.95
				c-3.15-2.46-7.28-4.69-11.58-1.77c-0.98,0.66,0.09,4.33,0.24,7.02c-2.52,0.09-8.18-2.5-6.05,4.67c-3.28,2-9.45-1.61-9.27,4.31
				c-5.3,0.76-10.32,1.17-15.14,2.36c-1.6,0.39-2.69,2.81-4.02,4.3c1.7,1.14,3.25,2.77,5.12,3.3c2.37,0.67,5,0.44,8.06,0.62
				c-1.94,2.11-3.27,3.57-4.61,5.03c0.25,0.48,0.5,0.96,0.75,1.44c2.48-0.55,4.96-1.1,7.75-1.72c0.1,0.96,0.21,2.12,0.38,3.82
				c2.3-1.54,4.16-2.77,5.21-3.47c2.17,1.16,3.67,1.96,5.17,2.77c0-1.81,0-3.62,0-5.43c1.72,0.97,3.38,2.57,3.41,4.2
				c0.02,1.18-2.08,3.34-3.33,3.41c-4.29,0.23-8.62-0.3-12.94-0.46c-5.88-0.22-12.16-3.2-17.48,2.58c0.77,1.08,1.56,2.21,2.71,3.83
				c-1.84,0.57-3.6,0.61-4.46,1.5c-1.44,1.5-3.66,4.6-3.21,5.18c2.23,2.84,4.92,5.63,8.06,7.34c2.81,1.54,5.36-0.23,6.71-3.33
				c0.28-0.64,1.42-0.91,2.16-1.36c0.19,0.98,0.59,1.98,0.5,2.94c-0.08,0.88-0.7,1.71-1.07,2.57c0.26,0.5,0.51,1,0.77,1.51
				c2.16-0.74,4.31-1.49,7.45-2.57c7.58,5.3,16.88,2.39,26.55,1.77c-0.78-1.88-1.23-2.95-1.86-4.47c2.81,0.3,5.04,0.54,8.75,0.94
				C1059.8,546.47,1058.74,545.36,1057.1,543.65z M473.05,599.05c1.02-2.02,1.78-2.8,1.67-3.43c-1.02-6.23,0.85-12.04,1.9-18.07
				c1.53-8.78,1.58-17.81,2.21-26.74c0.06-0.8,0.06-2-0.42-2.38c-4.01-3.18-2.76-7.38-2.67-11.5c0.07-3.55-1.16-7-5.51-6.83
				c-6.9,0.28-13.75-4.31-20.66-0.17c-0.24,0.15-0.66-0.04-0.99,0.02c-6.21,1.1-12.47,0.77-18.37,4.55
				c-9.43,6.03-11.39,21.48-2.78,28.45c1.55,1.25,3.01,2.64,4.36,4.11c5.52,5.94,10.45,12.61,19.29,14.07
				c1.14,0.19,2.13,1.44,3.14,2.27C460.38,588.49,466.51,593.61,473.05,599.05z M212.32,623.79c0.21,0.52,0.41,1.03,0.62,1.55
				c2.67-1.04,5.43-1.91,8-3.16c4.31-2.1,8.51-4.44,12.74-6.71c1.36-0.73,2.68-1.54,4.02-2.32c-0.8-1.35-1.29-3.16-2.47-3.97
				c-6.34-4.33-11.62-10.11-20.42-10.63c-5.35-0.31-10.83-3.22-15.72-5.91c-10.47-5.78-21.38-9.48-33.4-8.76
				c-6.7,0.4-13.37,1.46-20.03,2.41c-6.24,0.89-12.5,1.8-18.63,3.22c-1.36,0.32-3.3,4-3.12,4.17c3.55,3.36,7.02,7.06,11.23,9.39
				c4.59,2.54,9.85,3.96,14.94,5.48c8.21,2.45,16.69,4.11,24.75,6.97c8.74,3.1,17.09,7.32,25.72,10.76c1.59,0.63,3.77,0.19,5.57-0.22
				C208.25,625.56,210.26,624.57,212.32,623.79z M594.4,296.07c-5.93-5.33-16.33-2.16-20.08-11.4c-0.13-0.33-0.86-0.61-1.3-0.6
				c-9.4,0.3-19.45-1.35-28.02,1.48c-10.68,3.53-20.72,3.64-31.23,1.83c-3.25-0.56-4.73,0.22-6.06,3.27
				c-1.32,3.06-3.53,5.78-5.57,8.48c-2.76,3.67-5.73,7.19-9.38,11.72c12.36,0.62,23.24-0.89,32.42,6.35
				c8.37,6.6,21.44,3.92,27.86,14.93c0.56-1.31,0.99-1.82,0.89-2.16c-2.03-6.67,4.67-10.04,5.93-15.49c0.18-0.79,2.16-1.38,3.4-1.67
				c5.56-1.3,11.14-2.54,16.74-3.66c3.91-0.78,7.57-1.45,7.05-6.93c-0.1-1,1.66-2.34,2.8-3.24
				C591.37,297.8,593.11,296.89,594.4,296.07z M422.42,533.16c-5.53-4.17-11.64-8.89-17.9-13.4c-1.21-0.87-3.06-0.79-4.55-1.34
				c-3.25-1.2-7.13-1.78-9.53-3.96c-4.61-4.2-13.26-4.78-17.61-0.29c-4.5,4.64-8.84,9.42-15.83,10.53c-2.15,0.34-4.07,2.16-7.37,4.03
				c5.69,2.52,9.79,4.82,6.32,10.71c10.19,0.86,16.6,5.41,19.35,14.64c0.41,1.37,1.28,2.86,2.39,3.73c5.46,4.28,10.93,8.6,16.73,12.4
				c1.79,1.17,4.77,1.37,6.96,0.89c4.16-0.92,8.13-2.72,12.21-4.04c3.89-1.26,5.15-3.7,4.11-7.67c-0.79-3.02-2.2-6.4-1.51-9.17
				C417.62,544.4,420.25,538.86,422.42,533.16z M511.73,760.27c2.19,5.12-0.24,7.69-3.9,9.82c-5.02,2.93-9.93,6.05-14.92,9.04
				c-3.11,1.87-6.13,3.66-4.36,8.2c0.29,0.74-0.08,2.2-0.67,2.76c-4.07,3.79-5.27,9.83-10.78,12.66c-3.27,1.68-5.72,4.93-8.54,7.47
				c1,0.28,1.75,0.1,2.41-0.25c10.03-5.46,20.68-7.71,32.14-7.48c11.29,0.23,22.17-1.55,31.29-9.42c1.62-1.4,4.96-0.91,7.52-1.07
				c3.74-0.23,7.51-0.05,11.23-0.43c4.91-0.51,5.24-5.47,3.61-7.34c-2.85-3.28-1.13-5.33,0.71-8.64c-2.67,0-4.57-0.12-6.46,0.02
				c-7.5,0.58-9.49-0.95-9.07-8.47c0.15-2.73,1.98-5.42,3.29-8c0.77-1.51,2.04-2.77,3.22-4.34c-2.88-0.85-5.12-1.51-7.97-2.35
				c1.99-2.84,3.51-5.01,5.75-8.21c-8.73,0.59-15.98,1.77-22.66,5.2c-1.05,0.54-1.09,2.81-1.92,4.07c-1.33,2.01-2.68,4.15-4.5,5.64
				C516.07,760.04,514.05,759.84,511.73,760.27z M935.37,722.06c-3.93-6.41-7.14-12.55-11.22-18.04c-3.28-4.41-7.59-8.07-11.52-11.98
				c-1.73-1.73-3.73-3.19-5.47-4.91c-1.72-1.7-4.25-3.35-4.72-5.41c-1.37-6.06-5.93-7.67-10.83-8.77c-3.54-0.8-7.3-0.58-10.89-1.24
				c-3.2-0.59-6.66-1.16-9.36-2.79c-9.26-5.6-13.39-4.91-22.32-0.07c-1.13-0.8-2.28-1.61-3.57-2.53c-1.46,10.2,2.67,17.26,11.15,20.9
				c6.86,2.94,13.47,6.52,20.1,9.98c1.05,0.55,1.51,2.11,2.43,3.02c2.98,2.96,5.58,7.17,9.19,8.43c5.87,2.06,10.24,4.51,12.84,10.49
				c1.72,3.97,4.38,8.16,10.05,7.2C918.74,725.07,926.24,723.68,935.37,722.06z M1087.62,649.38c-0.03,0.48-0.05,0.96-0.08,1.44
				c1.8,1.63,3.61,3.26,5.6,5.05c-1.68,0.95-2.61,1.47-4.67,2.63c9.46,7.38,18.06,14.52,27.13,20.99c5.08,3.63,10.66,5.98,13.5,12.51
				c1.35,3.11,6.09,5.92,9.73,6.62c8.74,1.69,17.77,1.84,26.62,3.05c3.8,0.52,5.18-0.97,4.74-4.2c-0.66-4.89-1.51-9.8-2.86-14.54
				c-1.58-5.55-3.85-10.91-5.7-16.39c-2.52-7.45-4.92-14.94-7.45-22.64c-3.22,2.95-5.87,5.65-8.82,7.97
				c-1.72,1.35-4.36,1.68-5.81,3.2c-3.27,3.47-10.81,5.96-15.24,4.43c-5.56,4.61-10.92,3.24-17.26-4.43
				c-5.63,2.55-6.03,2.24-7.31-3.69c-0.26-1.19-1.94-2.91-3.01-2.94C1093.7,648.36,1090.65,649.01,1087.62,649.38z M1142,405.18
				c-0.15-0.41-0.3-0.83-0.45-1.24c2.68-1.36,5.27-3.59,8.06-3.88c3.49-0.36,6.35-0.29,7.96-4.17c0.48-1.15,3.04-2.54,4.04-2.17
				c3.96,1.46,6.96-0.79,10.39-1.65c2.35-0.6,5.21-0.43,7.54,0.33c5.37,1.76,15.07-2.61,15.41-8.02c0.54-8.68,0.15-17.41,0.15-26.91
				c-3.78,1.51-6.81,2.39-9.54,3.86c-14.96,8.08-31.49,8.57-47.59,7.86c-9.32-0.41-17.44,0.07-26.25,5.47
				c2.56,2.49,5.13,4.65,7.23,7.2c0.77,0.94,0.76,2.86,0.5,4.22c-0.24,1.29-1.25,2.43-2.12,4c7.76,1.77,14.16,4.47,15.03,13.3
				c1.88,0.46,3.78,1.01,5.71,1.39C1139.36,405.03,1140.69,405.05,1142,405.18z M1092.74,188.45c1.75,0.12,2.58,0.27,3.39,0.22
				c4.75-0.29,8.57-0.48,9,6.51c0.15,2.49,5.93,6.91,8.52,6.5c4.91-0.78,7.76,0.16,10.06,4.58c2.91-4.9,5.88-9.17,8.07-13.8
				c1.9-4.01,2.84-8.47,4.33-12.68c1.5-4.24,3.88-6.68,9.04-5.19c2.61,0.76,6.07,1.12,8.33,0.02c7.77-3.79,15.88-2.86,23.97-2.96
				c1.43-0.02,2.85-0.31,3.89-0.44c-0.85-4.5-1.51-8.65-2.52-12.72c-0.22-0.9-1.74-2.16-2.59-2.11c-9.88,0.59-19.75,1.4-30.03,2.18
				c0.97,2.23,1.9,4.37,2.83,6.52c-0.3,0.16-0.6,0.33-0.91,0.49c-2.09-3.22-4.17-6.45-6.43-9.93c-1.56,0.73-3.14,1.47-5.67,2.65
				c5.48,1.71,8.92,3.68,2.52,8.35c5.39,0.71,9.85,1.3,14.31,1.89c-4.25,2.88-8.47,2.15-12.71,0.92c-5.6-1.62-5.9-2.36-2.9-7.29
				c-3.55-3.01-3.55-3.01,1.34-8.99c-3.29,0.31-6.01,0.66-8.76,0.81c-4.8,0.26-9.66,0.9-14.41,0.48c-7.92-0.7-8.74-0.38-6.55,7.52
				c2.95,10.62,0.61,21.14,0.3,31.7c-0.03,0.93-0.93,1.84-1.43,2.75c-0.77-3.3-0.86-6.43-0.15-9.37c1.92-7.95,1.92-15.75-0.6-23.57
				c-0.61-1.89-0.7-3.94-1.28-5.84c-0.51-1.66-1.37-3.21-2.08-4.81c-1.01,1.57-2.47,3.01-2.94,4.72
				C1097.97,167.62,1095.48,177.72,1092.74,188.45z M731.45,395.19c-0.05,0.95-0.1,1.9-0.15,2.85c-14.01-0.7-28.02-1.43-42.04-2.03
				c-1.22-0.05-3.13,0.45-3.59,1.3c-1.23,2.31-2.13,4.89-2.54,7.47c-0.16,1,1.33,3.25,2.11,3.27c12.73,0.34,25.68,1.92,38.16,0.18
				c18.34-2.56,36.35-7.48,54.46-11.54c2-0.45,3.76-1.92,5.65-2.9c5.24-2.73,10.41-5.59,15.74-8.14c4.9-2.35,10.07-4.16,14.89-6.65
				c0.98-0.51,0.97-2.95,1.41-4.5c-1.27-0.15-2.73-0.79-3.77-0.36c-10.02,4.09-20.5,5.75-31.21,5.82c-20.3,0.13-40.6,0.24-60.89-0.12
				c-7.62-0.13-15.27-1.28-22.81-2.53c-5.5-0.91-5.44-1.33-6.03,4.23c13.61,4.05,27.76,8.48,41.61,4.72
				c14.37-3.9,28.1,2.06,42.04-0.29c0.18-0.03,0.44,0.4,1.12,1.06c-10.87,3.52-21.5-1.72-32.24,0.3c4.08,0.83,7.89,1.6,11.7,2.37
				c-0.06,0.54-0.12,1.09-0.18,1.63c-9.15-2.77-17.75-0.01-26.47,1.79c-2.39,0.49-4.99,0.3-7.45,0.01
				c-10.82-1.25-21.63-2.65-32.7-4.03c-1.68,3.16,0.1,5.22,3.46,5.43c7.76,0.49,15.54,0.53,23.32,0.66
				C720.52,395.26,725.99,395.19,731.45,395.19z M402.71,459.73c0.5,0.06,0.99,0.13,1.49,0.19c1.28-2.92,2.43-5.9,3.86-8.74
				c4.2-8.36,8.21-16.83,12.92-24.9c1.72-2.94,2.47-4.84,0.6-7.95c-8.87-14.78-17.5-29.7-26.24-44.56c-0.82-1.4-1.8-2.71-2.8-4.2
				c-2.26,1.07-4.11,2.25-6.13,2.84c-4.15,1.21-5.73,3.63-5.65,8.03c0.05,2.38-1.81,4.76-2.61,7.2c-0.58,1.76-1.54,3.82-1.11,5.42
				c1.98,7.32,4.14,14.62,6.75,21.74c1.18,3.22,2.86,6.98,5.48,8.8c6,4.16,7.46,10.69,6.42,16.1
				C393.97,448.61,400.04,453.25,402.71,459.73z M617.62,329.83c-0.38-0.36-0.75-0.71-1.12-1.07c-2.3-0.12-4.59-0.23-6.89-0.37
				c-6.29-0.39-12.65-0.33-18.85-1.3c-7.53-1.19-14.7-1.91-21.71,2.14c-1.84,1.06-4.53,0.61-6.82,0.93c-1.64,0.23-4.44,0.2-4.68,0.95
				c-0.76,2.37-0.78,5.07-0.64,7.61c0.05,0.95,1.33,1.85,2.09,2.73c10.97,12.74,21.93,25.49,32.93,38.21
				c2.43,2.81,4.96,5.54,7.44,8.31c0.6-0.09,1.2-0.18,1.8-0.28c1.02-4.05,2.14-8.08,3.05-12.16c2.18-9.72,4.58-19.4,6.29-29.21
				C611.55,340.25,609.74,333.34,617.62,329.83z M1146.61,408.23c-3.33-0.77-6.17-1.7-9.08-2.02c-3.02-0.33-6.1-0.07-8.13-0.07
				c-1.11,3.32-1.88,5.62-2.77,8.3c0.92,1.4,2.11,3.2,3.3,5c0,0,6.07,10.22,6.18,10.4c3.69,0,7.5-0.02,11.31,0
				c7.53,0.05,7.55,0.06,7.46-7.55c-0.03-2.28-0.17-4.61,3.16-4.28c2.6,0.26,3.96,1.51,3.13,4.38c-0.2,0.69,0.14,1.54,0.38,3.56
				c2.21-2.65,3.68-4.54,5.29-6.31c0.95-1.04,2.1-1.9,3.16-2.84c0.01,1.9,0.4,3.91-0.07,5.68c-0.44,1.67-1.8,3.09-3.41,5.68
				c3.28-1.42,5.16-2.24,7.03-3.05c0.27,0.4,0.53,0.8,0.8,1.2c-1.85,2.1-3.7,4.2-5.62,6.37c2.12,1.87,3.82,4.64,5.85,4.9
				c4.06,0.51,4.91,1.89,2.73,5.59c6.01,1.06,4.91,3.92,0.78,8.43c3.97,0.37,6.96,1.55,8.79,0.57c1.81-0.97,2.45-4.13,3.94-6.95
				c4.13,0.77,4.78-2.4,4.36-6.67c-0.32-3.3-0.37-6.62-0.54-9.98c-1.5-0.58-3.12-1.21-4.65-1.8c2.81-11.07-6.46-1.27-7.54-5.45
				c0.15-1.64,0.33-3.64,0.54-5.95c2.24-0.68,4.67-1.41,7.87-2.38c-2.08-1.59-3.64-2.39-4.6-3.64c-0.91-1.19-1.86-2.97-1.64-4.29
				c0.57-3.29,4.1-3.78,6.07-0.77c-1.29,0.5-2.53,0.99-3.76,1.47c0.11,0.6,0.22,1.19,0.34,1.79c2.42-0.35,4.99-0.34,7.15-1.27
				c0.63-0.27,0.34-3.28,0.03-4.95c-0.34-1.83-1.19-3.57-2.25-6.58c-3.78-1.83-8.48-0.31-13.02,2.05c-3.48-5.1-6.3-2.26-10.17-0.39
				c-3.25,1.58-8.9-3.06-11.09,2.86c-1.28,3.47-2.72,3.65-5.85,2.9C1147.5,401.03,1145.88,402.88,1146.61,408.23z M57.22,496.61
				C56,506.72,66.75,509.24,69,517.11c5.59,0.88,4.94,5.62,5.98,9.44c1.39,5.11,4.82,5.87,8.92,2.46c1.56-1.3,3.52-2.53,5.46-2.88
				c8.27-1.5,16.86-1.79,24.84-4.18c8.28-2.48,18.04-2.15,23.91-10.59c2.76-3.97,6.2-7.46,9.89-11.84
				C116.75,492.18,86.44,501.12,57.22,496.61z M1166.94,514.13c0.5-0.72,1.01-1.45,1.51-2.17c-2.13-1.72-4.27-3.43-6.43-5.17
				c0.89-0.48,2.41-1.29,3.95-2.12c-0.95-1.61-1.75-2.97-2.67-4.54c1.56-1.37,2.98-2.51,4.26-3.78c1.25-1.24,2.37-2.62,3.6-4
				c-0.92-1.08-1.65-1.75-2.16-2.56c-3.47-5.51-9.27-5.88-14.13-1.5c-2.71,2.44-6.09,4.66-9.54,5.61c-5.12,1.4-10.84,0.26-15.61,3.65
				c-0.54,0.39-1.91-0.18-2.81-0.53c-4.65-1.76-7.03,0.5-8.82,4.44c1.81,1.36,3.26,2.46,4.66,3.5c-2.26,1.38-4.04,2.47-6.65,4.07
				c3.21,1.32,5.18,2.14,7.92,3.27c-3.07,2.02-5.25,3.45-8.74,5.75c4.28,1.46,7.09,2.41,9.79,3.33c-2.27,1.62-4.41,3.15-6.55,4.67
				c2.17,1.05,4.34,2.11,7.61,3.7c-1.77,0.78-3.24,1.42-4.71,2.06c0.33,0.48,0.65,0.95,0.98,1.43c1.57-0.23,3.16-0.73,4.71-0.62
				c1.54,0.1,3.05,0.81,5.03,1.38c0.51-0.36,1.67-1.18,3.31-2.34c0.18,2.48,0.32,4.27,0.51,6.79c2.19-1.38,3.65-2.31,5.1-3.22
				c4.05,4.74,6.17,4.89,11.77,0.77c-0.66-1.06-1.32-2.14-1.98-3.21c5.29,3.23,9.82,3.51,13.93-1.86c-1.81-1.18-3.6-2.36-6.44-4.21
				c2.14-0.31,2.94-0.29,3.63-0.56c2.01-0.78,3.97-1.67,5.94-2.52c-1.06-1.69-2-3.47-3.22-5.03c-0.83-1.06-2.07-1.81-3.44-2.97
				C1163.61,514.99,1165.28,514.56,1166.94,514.13z M245.04,525.74c-1.83-3.63-3.65-6.55,0.42-9.21c1.15-0.75,1.56-2.63,2.31-3.99
				c-1.54,0.32-3.45,0.17-4.57,1.03c-5.63,4.32-11.63,7.02-18.95,5.9c-1.4-0.21-3.18-0.07-4.33,0.65
				c-8.44,5.24-16.85,10.53-25.08,16.09c-7.02,4.73-14.22,8.44-23.02,8.39c-7.48-0.04-14.97,0.36-22.44,0.83
				c-2.77,0.17-5.49,1.11-8.24,1.7c0.02,0.5,0.05,1.01,0.07,1.51c2.35,0.47,4.68,1.06,7.05,1.38c5.25,0.7,10.67,0.69,15.75,2.01
				c8.52,2.22,16.91,4.56,25.84,3.98c7.96-0.52,15.94-0.93,23.92-1.18c4.32-0.14,8.53,0.33,11.87-3.62c1.19-1.4,4-2.22,5.96-2.04
				c5.81,0.54,6.84-3.98,6.4-7.26c-0.51-3.85,0.92-7.13,0.98-10.23C239.08,526.72,241,526.16,245.04,525.74z M381.19,720.63
				c-9.92,0.64-18.84-1.1-27.46,2.99c-5.34,2.54-11.18,4.03-16.73,6.16c-3.54,1.36-6.89,3.2-10.42,4.56
				c-11.91,4.58-20.99,13.21-30.37,21.33c-3.67,3.18-8.74,7.08-9.22,11.13c-1.22,10.21-0.26,20.7,0.02,31.07
				c0.03,0.98,1.87,2.86,2.12,2.74c4.94-2.34,10.37-4.21,14.41-7.68c2.54-2.18,2.74-6.94,4.42-10.32c3.59-7.22,5.91-14.59,6.33-22.81
				c0.74-14.52,12.69-24.06,28.29-20.31c3.86,0.93,6.99,0.37,9.54-2.41c3.59-3.9,7.58-4.7,12.62-3.21c4.49,1.33,9.08,1.22,11.82-3.62
				C378.1,727.55,379.29,724.64,381.19,720.63z M665.91,181.59c-9.64,3.23-17.99,2.01-26.79-0.2c-8.83-2.22-18.27-2-27.77-2.88
				c-0.38,1.33-0.8,2.8-1.35,4.75c9.07,0.56,17.57,1.92,25.9,1.26c4.27-0.34,6.34,1.39,9.98,3.75c-2.11,0.31-3.32,0.65-4.52,0.64
				c-9.66-0.04-19.31-0.12-28.97-0.26c-4.71-0.07-7.47,2.5-7.02,7.39c0.42,4.57,4.28,2.67,6.69,2.69c5.47,0.04,10.95-0.32,16.42-0.68
				c3.56-0.24,7.09-0.97,10.65-1.14c4.36-0.2,8.74-0.05,13.11-0.05c-5.58,1.86-11.12,2.96-16.71,3.34
				c-14.93,1.01-29.88,1.67-44.82,2.57c-2.43,0.15-4.82,0.8-7.23,1.22c0.07,0.61,0.13,1.22,0.2,1.83c5.26,0,10.52,0,15.76,0
				c0.12,4.47,0.35,4.64,4.08,4.13c7.04-0.98,14.07-2.8,21.12,0.1c0.98,0.4,2.29,0,3.45-0.05c8.23-0.34,16.46-0.67,24.68-1.06
				c0.76-0.04,1.74-0.26,2.21-0.78c3.93-4.35,6.81-10.44,14.49-8.27c0.35,0.1,0.95-0.32,1.29-0.65c4.04-3.84,8.37-7.45,11.98-11.66
				c2.61-3.03,4.81-4.83,8.82-2.74c1.07,0.55,2.67,0.08,4.42,0.08c-0.75-3.54-1.33-6.22-1.89-8.87c-5.68,0-10.99-0.06-16.3,0.01
				c-7.61,0.11-9.62,2.32-9.57,10.26c0.02,2.91,0,5.82,0,8.72c-0.33,0.01-0.66,0.02-0.99,0.04
				C666.83,190.88,666.41,186.68,665.91,181.59z M207.52,495.05c2.23-5.81,4.55-11.26,6.35-16.88c0.78-2.43,0.94-5.28,0.56-7.81
				c-1.72-11.3-3.79-22.55-5.65-33.83c-0.98-5.95-1.81-11.92-2.64-17.42c-7.42,3.42-14.5,6.61-21.47,10
				c-0.94,0.46-1.8,1.92-1.94,3.02c-0.92,7.27-1.8,14.55-2.32,21.85c-0.16,2.18,0.17,5.1,1.5,6.56c4.18,4.57,7.22,9.61,10.2,15.06
				C195.87,482.43,201.82,488.05,207.52,495.05z M1121.44,463.65c2.34,3.09,3.76,6.23-0.24,9.26c-0.6,0.45-0.66,1.82-0.7,2.78
				c-0.14,3.59-2.3,4.65-5.5,5.12c-2.08,0.31-4.01,1.54-6.01,2.36c0.17,0.5,0.34,1,0.51,1.5c2.24,0,4.47,0,6.21,0
				c0.88,1.62,1.84,2.73,2.05,3.98c0.21,1.22-0.35,2.58-0.38,2.73c3.49,1.11,6.44,2.41,9.51,2.91c1.9,0.31,4.35-0.03,5.91-1.06
				c2.23-1.47,3.66-2.6,6.53-0.9c3.13,1.85,8.99-1.13,8.5-3.89c-0.71-3.93,0.82-5.07,4.52-5.87c4.13-0.9,7.98-3.06,11.95-4.68
				c-0.11-0.57-0.23-1.14-0.34-1.7c-2.94-1.5-5.88-3-9.47-4.84c3.53-2.1,6.22-3.71,8.91-5.31c-0.09-0.51-0.19-1.02-0.28-1.54
				c-3.56-0.53-7.12-1.05-10.68-1.58c0.38-0.36,0.77-0.72,1.15-1.08c-1.37-2.73-2.74-5.46-4.28-8.52c0.36-0.69,1.02-1.94,2.54-4.83
				c-5.52,0.81-9.74,1.43-13.95,2.04c1.11-2.08,2.68-3.21,3.97-4.61c2.08-2.26,1.15-4.83-1.86-5.51c-6.83-1.54-15.09,1.66-19.37,7.63
				c1.44,1.04,2.89,2.08,4.32,3.13c0.11,0.08,0.14,0.29,0.3,0.68c-0.64,1.09-1.36,2.32-2.04,3.49
				C1127.53,459.36,1127.49,459.57,1121.44,463.65z M1083.48,314.78c2.25-0.74,3.92-1.37,5.64-1.82c1-0.26,2.28-0.65,3.09-0.26
				c5.91,2.88,2.14-6.25,6.5-5.18c0.01,0,0.16-0.51,0.25-0.82c-1.05-0.61-2.1-1.22-3.86-2.25c2.88-0.84,4.94-1.45,6.82-2
				c-1.26-8.53-7.68-4.14-12.07-4.63c-0.16-0.52-0.33-1.04-0.49-1.56c1.52-0.61,3.04-1.23,4.56-1.84c-0.32-0.51-0.64-1.01-0.96-1.52
				c-6.14,1.22-10.79-8.1-16.66-2.3c-2.75-1.3-4.86-2.29-8.18-3.85c-1.86-7.39-4.64,1.73-7.43,1.5c-1.6-0.99-0.71-6.7-4.22-3.56
				c-3.31,2.96-4.69,1.8-7.38-0.15c-1.05-0.76-3.5,0.4-5.31,0.68c0.69,1.37,1.29,2.79,2.1,4.08c0.61,0.97,1.48,1.78,2.23,2.66
				c-0.29,0.25-0.59,0.49-0.88,0.74c-1.22-0.74-2.45-1.48-3.67-2.22c-0.52,0.18-1.04,0.35-1.56,0.53c0.64,2.58,1.28,5.16,1.95,7.85
				c-2.63-0.42-5.41-0.86-8.49-1.35c0.39,1.92,0.62,3.06,0.92,4.56c-2.62,0-4.79-0.19-6.91,0.05c-2.1,0.24-4.15,0.92-6.23,1.41
				c0.03,0.39,0.07,0.77,0.1,1.16c1.44,0.65,2.89,1.31,4.58,2.07c-0.65,1.24-1.19,2.28-1.66,3.18c3.27,3.15,5.91,1.84,8.72-0.15
				c3.6-2.55,6.84-3.61,11.14-0.07c3.07,2.53,7.82,4.26,11.76,4.14c3.73-0.11,6.32,0.69,9.06,2.72c1.59,1.18,3.33,2.28,5.17,3
				C1078.25,321.97,1083.84,319.3,1083.48,314.78z M871.95,391.51c-0.7-5.2,2.27-6.02,5.68-5.98c2.98,0.04,5.94,0.59,8.92,0.62
				c1.3,0.01,3.37-0.32,3.79-1.16c1.82-3.62,4.38-3.39,7.56-2.34c2.67,0.89,5.5,1.31,8.21,2.09c3.34,0.96,6.15,0.14,7.73-2.85
				c2.77-5.23,5.16-10.65,7.81-16.21c-4.63-0.23-8.13,0-11.46-0.65c-9.1-1.78-17.94-4.15-27.53-2.37
				c-8.61,1.59-17.58,1.18-26.25,2.54c-4.88,0.76-9.64,3.01-14.1,5.28c-1.28,0.65-2.26,4.08-1.68,5.59c0.51,1.34,3.33,2.4,5.2,2.51
				c7.97,0.46,8.43,1.04,4.39,7.82C857.33,388.07,864.31,389.71,871.95,391.51z M872.39,453.4c-1.06-11.07-2.11-22.1-3.2-33.51
				c-1.73,0-3.39,0.19-5-0.04c-4.27-0.59-6.15,0.99-5.95,5.45c0.09,1.92-0.84,3.88-1.31,5.82c-0.55-3.74-0.33-7.32-0.12-10.9
				c0.07-1.18-0.28-2.58,0.23-3.5c1.74-3.14,1.09-4.48-2.57-4.78c-3.23-0.26-6.42-1.04-9.63-1.59c0.11-0.61,0.22-1.22,0.34-1.83
				c3.14,0.61,6.35,0.97,9.4,1.89c6.75,2.03,2.83-4.82,5.35-6.42c0.47-0.3-0.09-2.68-0.64-3.88c-0.19-0.43-1.8-0.11-2.72-0.32
				c-3.39-0.76-6.77-1.6-10.16-2.42c0.13-0.46,0.25-0.92,0.38-1.38c3.36,0.65,6.71,1.32,10.08,1.93c2.73,0.5,3.9-1.15,3.43-3.41
				c-0.27-1.31-1.67-2.77-2.94-3.39c-1.41-0.69-3.25-0.44-4.89-0.71c-6.01-0.99-6.92-2.43-5.41-8.08c-2.69-1.16-5.33-2.29-8.37-3.59
				c-7.2,11.55-14.46,23.02-18.02,36.25c8.35,1.61,16.47,3.06,24.51,4.84c1.45,0.32,3.21,1.74,3.78,3.1
				c3.31,7.75,6.15,15.7,9.45,23.46C861.3,453.18,865.19,454.92,872.39,453.4z M1122.04,330.58c-3.18,6.19-3.09,12.66-1.15,18.49
				c0.85,2.54,5.62,3.81,8.68,5.54c1.14,0.65,2.5,0.9,3.69,1.47c3.2,1.52,5.79,0.74,8.77-1.14c7.85-4.94,15.88-9.63,24.09-13.94
				c2.97-1.56,6.65-1.81,10.04-2.55c6.33-1.38,12.68-2.68,18.78-3.97c0-9.92,0-18.35,0-26.82c-8.36,1.14-15.68,3.5-20.07,11.34
				c-0.78,1.39-2.94,1.99-4.43,2.99c-1.05,0.7-1.98,1.64-3.1,2.18c-2.8,1.35-5.68,2.52-8.52,3.77c-1.65,0.73-3.87,1.05-4.83,2.31
				c-2.12,2.81-4.1,4.74-7.89,3.19c-0.75-0.31-2.03-0.09-2.75,0.38C1135.61,338.74,1129.15,335.24,1122.04,330.58z M636.61,691.17
				c-1.54,4.95-2.57,8.71-3.89,12.37c-0.98,2.7-0.03,4.42,2.49,4.86c3.37,0.58,4.55,2.58,5.35,5.6c0.52,1.97,1.72,3.98,3.16,5.44
				c2.14,2.18,1.35,3.62-0.29,5.49c-1.11,1.26-2.89,3.39-2.49,4.31c2.5,5.77-0.43,9.71-3.44,14.13c-3.11,4.57-1.21,9.06,1.5,13.45
				c2.51,4.05,5.57,5.6,10.33,4.99c7.88-1.01,15.85-1.32,23.72-2.38c3.5-0.47,6.84-2.07,10.26-3.16c-0.15-0.45-0.3-0.9-0.45-1.34
				c-6.98,0.23-13.43-1.12-19.02-5.67c-0.9-0.74-3.57-1.02-3.74-0.68c-2.54,5.1-5.91,2.67-9.21,1.25c-1.99-0.86-3.6-1.31-4.7,1.55
				c-0.54,1.4-2.54,2.23-4.73,4.01c0.24-1.85,0.18-2.21,0.33-2.41c0.31-0.38,0.94-0.6,1.08-1.01c1.91-5.77,5.43-6.15,10.28-3.43
				c0.98,0.55,3.42,0.31,3.96-0.44c2.9-4.1,5.38-1.85,8.48-0.26c4.2,2.14,8.67,3.97,13.24,5.09c3.15,0.77,5.07-2.65,3.87-5.49
				c-0.98-2.31-1.37-4.87-2.3-8.37c-2.49-1.34-6.98,0.21-10.48,5.11c-1.14-4.43-3.98-5.28-8.08-5.21c-2.95,0.05-4.35-2.6-3.58-5.82
				c0.33-1.39,0.41-2.84,0.61-4.27c0.14-2.03,0.35-4.13,1.24-5.88c0.7-1.38,2.05-2.82,3.45-3.33c1.57-0.57,3.52-0.13,6.28-0.13
				c-1.53-3.38-2.75-6.08-3.76-8.31c-7.1-0.36-5,5.96-7.73,10.2c-0.37-4.07-0.61-6.62-0.84-9.1c-12.07-0.56-14.17-2.63-10.36-9.03
				C643.66,699.29,640.3,695.41,636.61,691.17z M355.61,420.7c-1.13,1.56-2.39,3.23-3.57,4.94c-7.08,10.28-13.99,20.68-21.31,30.78
				c-1.74,2.4-3.07,3.84-1.1,6.81c0.76,1.14,0.31,3.94-0.63,5.21c-4.47,6.01-2.13,13.21,5.2,15.26c1.74,0.48,3.83,0.74,5.04,1.88
				c7.57,7.11,17.69,3.21,26.56,6.63c-5.84-6.21-4.66-13.52-4.72-20.56c-0.01-0.66-0.07-1.33-0.14-1.99
				C359.19,453.52,357.43,437.38,355.61,420.7z M310.93,574.54c7.71,3.04,13.98,1.63,20.02,0.11c8-2.01,15.71-2.94,24.01-1.06
				c7.37,1.67,14.53-0.76,21.17-4.51c-4.4-7.41-8.34-14.33-12.61-21.05c-1.05-1.65-2.96-3.28-4.8-3.8c-3.97-1.13-5.55-2.94-4.43-7.18
				c0.85-3.2-4.78-7.72-6.83-5.6c-3.6,3.73-9.34,4.75-11.4,10.56c-2.87,8.1-7.4,15.25-16.24,16.92c1.64,2.19,4.65,4.41,4.53,6.45
				c-0.22,4.01-3.96,5.71-7.72,6.69C315.12,572.47,313.71,573.32,310.93,574.54z M594.88,251.59c2.05,0.8,3.96,2.22,5.8,2.15
				c6.6-0.25,13.17-1.07,19.76-1.55c1.92-0.14,4.74-0.68,5.63,0.32c2.9,3.23,6.34,2.69,9.73,2.29c4.45-0.52,8.83-1.97,13.27-2.18
				c7.7-0.35,15.96-6.1,23.01,1.89c0.17,0.2,0.63,0.14,0.96,0.21c13.43,2.8,25.3-3.7,30.33-17.23c-4.19,0-8.13-0.24-12.02,0.06
				c-4.13,0.32-8.23,1.75-12.31,1.64c-12.8-0.34-25.66-0.57-38.38-1.98c-16.93-1.87-30.72,6.08-45.07,12.68
				C595.49,249.97,595.47,250.21,594.88,251.59z M601.31,528.37c2.23,5.9,2.62,10.35,0.37,15.04c-1.18,2.45-1.48,5.41-1.73,8.18
				c-0.08,0.86,1.17,2.53,2.02,2.68c5.15,0.94,10.37,1.44,15.54,2.28c9.45,1.54,10.53,0.72,10.54-8.6c0-5.2,0-10.4,0-15.6
				c-2.58,0.31-4.45,0.53-6.33,0.76c-0.46-0.61-0.92-1.23-1.39-1.84c7.19,1.19,8.11-3.11,8.47-7.46c0.33-4.03,0.07-8.11,0.07-12.63
				c0.6-0.88,1.65-2.42,2.74-4.02c-5.33-2.79-5.69-4-3.8-9.69c0.73-2.2,1.1-4.52,1.66-6.87c-6.65,1.05-12.35,1.8-17.96,2.99
				c-1.11,0.24-2.21,2.04-2.72,3.35c-1.67,4.29-3.17,8.66-4.5,13.07c-0.48,1.6-0.28,3.4-0.43,5.66c-0.51,0.77-1.46,2.21-2.41,3.64
				c1.61,0.41,3.22,1.12,4.84,1.14c2.31,0.04,4.62-0.61,6.93-0.63c3.54-0.04,7.09,0.21,10.63,0.34c-0.03,0.36-0.07,0.72-0.1,1.07
				c-7,1.18-14,2.37-21,3.55c0.04,0.28,0.08,0.56,0.11,0.84c6.49,0,12.98,0,19.47,0c-9.39,0.54-5.87,9.71-9.94,14.01
				c0.52-3.93,1.04-7.86,1.67-12.65C609.48,527.47,605.69,527.88,601.31,528.37z M1103.06,686.35c-3.6,6.73-5.74,7.67-14.45,6.29
				c0.17,2.1,0.34,4.21,0.52,6.31c-0.41,0.23-0.83,0.45-1.24,0.68c-2.42-2.02-4.84-4.05-7.84-6.57c1.14,4.25-0.99,4.34-2.67,3.72
				c-1.12,2.26-1.99,4.03-2.86,5.79c-3.03-1.73-6.07-3.45-9.08-5.17c-0.4,1.15-1.01,2.93-2.01,5.84c-2.46-2.8-4.31-4.92-6.07-6.92
				c-1.1,1.2-2.2,3.4-3.32,3.41c-3.1,0.02-6.2-0.93-9.32-1.37c-1.94-0.27-3.91-0.3-6.32-0.47c0.37,2.14,0.58,3.26,0.76,4.38
				c1.55,9.85,4.22,11.39,13.56,8.06c1.33-0.47,2.94-0.35,4.4-0.22c2.74,0.23,5.48,1.02,8.2,0.94c5.1-0.14,10.34-1.72,15.26-0.96
				c18.67,2.87,34.32-6.71,51.09-10.85c-3.74-4.89-6.63-10.03-10.81-13.74c-4.2-3.73-9.67-6.02-15.52-9.49
				c0.73,7.12-3.61,4.14-6.91,4.79C1100.11,682.84,1101.43,684.4,1103.06,686.35z M160.49,430.61c-0.09,0.21-0.4,0.65-0.46,1.11
				c-1.01,7.85-2.14,15.69-2.94,23.57c-0.78,7.72-2.53,15.68-1.35,23.14c0.84,5.31,1.49,10.2,0.59,15.45
				c-0.39,2.29,0.23,3.98,3.59,3.72c6.55-0.49,13.15-0.4,19.73-0.37c2.72,0.01,5.45,0.5,8.72,0.82c-0.93-7.02,0.99-14.23-5.49-18.4
				c-5.43-3.49-6.97-7.93-7.34-14.11c-0.71-11.78-2.45-23.5-3.72-34.94C167.78,430.61,164.44,430.61,160.49,430.61z M913.76,665.11
				c0.69-1.93,0.73-2.66,1.04-2.81c5.97-2.8,4.16-6.24,1.13-10.16c-4.73-6.12-10.22-11.82-11.45-20.05
				c-0.16-1.06-2.57-1.79-3.94-2.68c-0.75,1.5-2.21,3.05-2.1,4.48c0.28,3.45,2.24,6.97,1.79,10.22c-0.75,5.46,0.56,10.14,2.62,14.93
				c0.71,1.65,0.88,3.53,1.35,5.29c1.15,4.29,1.53,9.02,3.66,12.76c4.22,7.43,10.86,12.17,19.15,15.03
				c6.22,2.15,12.08,5.46,17.86,8.69c4.13,2.31,8.04,4.22,13.04,4.12c3.89-0.07,7.93,0.9,11.68,2.11c5.12,1.65,10.03,3.97,15.03,6
				c0.4-0.28,0.8-0.55,1.19-0.83c-3.4-5.76-6.77-11.54-10.26-17.24c-0.34-0.56-1.58-1.13-2.11-0.93c-3.95,1.5-6.42,0.22-7.04-3.92
				c-9.57,3.34-10.69,2.82-11.11-4.49c-12.33,2.39-24.71,6.62-38.15-0.62c3.67-2.59,6.12-4.33,8.95-6.33
				c-3.47-1.24-6.2-2.21-8.92-3.18c0.01-0.52,0.01-1.03,0.02-1.55c3.71-2.34,7.42-4.67,11.69-7.37
				C923.26,666.02,918.72,665.59,913.76,665.11z M991.25,394.9c3.07,0,5.69,0,8.32,0c-0.35-1.94-0.69-3.88-1.06-5.96
				c6.11-4.12,6.11-4.12,2.29-10.81c2.34-1.22,4.68-2.44,8.07-4.21c-7.31-2.5-13.33-4.58-19.37-6.57c-0.55-0.18-1.67-0.05-1.86,0.29
				c-2.13,3.95-5.43,2.53-8.59,2.1c-0.91-0.12-2.52,0.09-2.75,0.62c-1.54,3.45-4.51,2.62-7.19,2.71c-5.86,0.2-6.6,1.05-5.77,6.17
				c-4.47,1.31-8.89,2.6-13.31,3.89c-0.04,0.71-0.07,1.43-0.11,2.14c2.09,1.15,4.18,2.29,6.55,3.59c-0.7,0.45-1.48,0.75-1.92,1.3
				c-1,1.25-1.84,2.63-2.74,3.96c1.58,0.46,3.15,1.21,4.76,1.3c1.5,0.09,3.03-0.52,4.55-0.83c0.14,0.33,0.29,0.65,0.43,0.98
				c-1.82,1.58-3.64,3.15-4.69,4.06c3.26,2.19,5.97,4.88,9.21,5.96c3.55,1.18,7.69,1.48,11.36,0.88c1.19-0.19,1.72-4.42,2.74-7.34
				c0.34,0.03,2.84,0.52,2.94,0.21c1.56-4.72,4.82-1.51,7.43-1.53C990.87,396.49,991.16,395.28,991.25,394.9z M636.87,716.57
				c0.54-0.78,1.08-1.57,1.62-2.35c-2.05-1.2-3.97-2.75-6.17-3.53c-2.81-1-3.73-2.52-2.78-5.28c0.48-1.42,0.91-2.85,1.42-4.26
				c2.68-7.37,1.47-11.59-4.72-16.49c-5.22-4.13-10.27-8.48-15.68-12.35c-9.6-6.85-19.02-14.12-29.25-19.9
				c-10.99-6.2-23.85-9.1-33.04-18.78c3.79,7.07,6.43,15.24,14.88,18.02c8.53,2.81,12.59,13.08,22.65,13.05
				c0.52,0,1.17,0.67,1.56,1.19c3.65,4.91,7.43,9.74,10.81,14.84c3,4.54,5.31,9.54,8.33,14.07c2.16,3.24,5.22,5.88,7.47,9.08
				c1.84,2.63,3.57,4.02,7.15,4.2c3.14,0.16,6.26,2.33,9.22,3.94C632.65,713.28,634.7,715.03,636.87,716.57z M1125.37,327.89
				c3.65,5.45,7.98,6.39,12.45,5.8c1.84-0.24,3.41-2.54,4.66-3.55c2.8,0.28,5.71,1.01,8.44,0.56c0.79-0.13,1.04-3.61,1.55-5.59
				c11.47,2.17,13.47,1.31,11.88-5.32c8.71,0.19,10.8-1.53,10.31-7.35c2.98-1.67,5.45-3.29,8.11-4.47c1.68-0.74,3.66-1.2,5.48-1.14
				c4.55,0.15,6.63-1.51,6.71-6.07c0.12-6.65,0.24-13.31,0.17-19.97c-0.05-5.78-1.18-6.78-6.86-6.98c-0.74-0.03-1.46-0.4-2.21-0.61
				c0.36-1.71,0.65-3.07,0.98-4.64c-3.84-1.08-7.94-4.64-9.14,2.59c-0.08,0.47-1.32,0.76-2.03,1.12c-3.31,1.69-6.63,3.37-9.96,5.06
				c3.84,6.77,3.84,6.77,11.67,11.24c3.54,2.02,3.42,10.2-0.51,12.16c-3.12,1.56-5.44,4.42-8.02,5.76
				c-5.25,2.73-10.7,5.07-15.58,8.53c-0.96,0.68-2.48,0.85-3.1,1.71c-4.02,5.67-9.69,8.25-16.31,9.31
				C1131.52,326.45,1129.02,327.1,1125.37,327.89z M760.96,557.19c0.54-1.73,0.97-2.76,1.19-3.83c1.08-5.48,1.98-11,3.21-16.44
				c0.77-3.38-0.96-5.02-3.22-7.1c-2.14-1.96-3.88-4.71-4.92-7.45c-3.46-9.07-1.12-19.94-8.58-27.81c-0.11-0.11-0.11-0.32-0.2-0.46
				c-3.08-4.83-6.17-9.66-9.25-14.49c-0.59,0.01-1.18,0.01-1.77,0.02c-0.49,1.86-2,4.38-1.29,5.47c3.08,4.77,3.31,5.28-1.94,6.62
				c-4.58,1.18-4.6,3.33-2.74,6.85c2.16,4.09,4.07,8.3,6.07,12.47c2.42,5.03,4.83,10.06,7.2,15.1c0.5,1.05,0.83,2.19,1.23,3.28
				c-10.33-6.66-9.29-19.8-17.23-27.77c0.84,2.32,1.67,4.64,2.51,6.95c-0.42,0.19-0.84,0.37-1.26,0.56
				c-1.22-2.29-2.44-4.59-3.65-6.88c-0.38,0.21-0.76,0.41-1.14,0.62c2.55,8.21,2.63,17.24,9.37,24.01
				c8.74,8.78,18.2,17.01,23.66,28.54C758.5,556.06,759.53,556.32,760.96,557.19z M409.41,699.3c-0.29-0.43-0.58-0.85-0.88-1.28
				c-0.76,0.15-1.8,0.03-2.23,0.48c-5,5.29-11.49,6.82-18.36,7.55c-0.96,0.1-1.93,0.54-2.79,1.02c-6.68,3.71-13.9,4.75-21.29,3.59
				c-12.64-1.99-24.78-1.8-36.12,5.22c-3.8,2.35-8.29,3.62-11.91,6.18c-3.51,2.48-6.58,5.74-9.32,9.08
				c-3.22,3.93-5.86,8.33-8.8,12.49c-2.67,3.8-5.4,7.56-8.11,11.34c0.43,0.38,0.87,0.76,1.3,1.14c0.98-0.7,1.99-1.36,2.94-2.1
				c6.03-4.64,11.6-10.14,18.19-13.76c9.68-5.31,20.09-9.29,30.2-13.81c2.88-1.29,5.93-2.27,8.66-3.81
				c6.93-3.91,14.21-5.15,22.11-4.27c3.35,0.37,7.23,0.33,10.2-1.02c6.47-2.94,12.68-6.57,18.65-10.46
				C404.79,704.99,406.92,701.87,409.41,699.3z M419.9,273.16c-0.12-0.74-0.24-1.49-0.35-2.23c-2.82-0.34-5.7-1.19-8.47-0.92
				c-11.44,1.09-22.82,0.86-34.16-0.94c-2.28-0.36-4.9-0.44-6.7-1.64c-10.06-6.73-21.09-5.87-31.81-3.5
				c-20.23,4.48-40.24,9.93-60.36,14.91c-6.78,1.68-13.62,3.16-20.43,4.73c0,0.53,0,1.06,0,1.58c7.65,0.56,15.29,1.31,22.95,1.52
				c1.75,0.05,3.57-1.74,5.32-2.75c2.15-1.24,4.13-3.26,6.41-3.67c3.99-0.73,8.74,1.34,11.13-4.02c0.29-0.66,1.72-1,2.67-1.17
				c8.67-1.47,17.31-3.59,26.04-4.1c11.94-0.69,23.96-0.14,35.95-0.07c1.22,0.01,2.44,0.4,3.65,0.62c0.26,0.51,0.51,1.02,0.77,1.54
				c-3.88,2.94-7.76,5.89-12.33,9.35c8.8,0.32,16.79,0.94,24.67-1.9c3.38-1.22,7-1.98,10.58-2.4c5.87-0.7,11.84-0.69,17.68-1.55
				C415.48,276.19,417.64,274.33,419.9,273.16z M1087.6,610.37c1.49-18.15,10.82-28.08,28.68-29.59c-2.98-0.46-5.97-0.92-9.83-1.52
				c2.82-4.09,4.81-6.98,7.53-10.93c-7.52,7.16-14.02,13.36-20.53,19.56c-0.49-0.35-0.99-0.7-1.48-1.05
				c-1.14-7.09-0.48-14.49,3.05-20.47c6.88-11.65,15.51-22.13,26.66-30.74c-5.47-3.01-5.39,3.53-8.19,4.89
				c-3.48,1.7-6.39,4.73-9.27,7.48c-2.64,2.52-4.91,5.43-7.77,8.64c-0.78-1.61-1.29-2.18-1.3-2.75c-0.05-5.26-0.44-10.56,0.09-15.77
				c1.29-12.62,0.47-25.67,7.82-37.13c1.71-2.67,1.75-6.41,2.56-9.66c-0.46-0.09-0.92-0.18-1.38-0.27c-0.3,0.9-0.42,1.93-0.94,2.67
				c-1.6,2.29-3.35,4.48-5.05,6.71c-0.65-2.56-1.56-5.09-1.87-7.69c-0.37-3.05-0.21-6.15-0.28-9.24c-0.65,0.03-1.3,0.05-1.96,0.08
				c0,10.76,0.46,21.55-0.14,32.28c-0.49,8.74-2.07,17.44-3.57,26.09c-0.3,1.76-2.36,3.21-3.61,4.81c-0.98-2.08-2.58-4.09-2.82-6.25
				c-0.45-4.11,0.21-8.34-0.2-12.46c-0.49-4.86-1.3-9.79-5.05-13.47c0.83,4.89,2.68,9.54,3.32,14.34s2.02,10.02-1.26,14.83
				c-5.17-2.45-4.97-9.95-12.44-10.87c1.92,2.5,3.13,4.02,4.28,5.59c7.48,10.19,14.83,20.29,12.61,34.21
				c-1.44,9.01-1.5,18.26-3.13,27.23C1081.21,605.17,1082.52,608.15,1087.6,610.37z M1025.32,273.59c1.14,3.57,5.25,5.5,2.21,9.15
				c-0.21,0.26,0.92,2.16,1.79,2.64c0.75,0.41,2.38,0.27,3-0.3c2.32-2.12,4.98-1.35,7.54-1.81c4.91-0.88,9.44-2.15,14.28-0.05
				c0.46,0.2,1.38-0.82,2.15-1.1c0.76-0.28,1.63-0.56,2.39-0.44c4,0.59,7.98,1.31,12.06,2.01c0.1-1.01,0.36-1.77,0.16-1.92
				c-5.05-3.84,0.38-9.73-2.68-14.01c-0.16-0.22,1.18-1.9,2.09-2.39c1.42-0.77,3.08-1.1,4.63-1.61c-1.84-6.36-2.05-6.49-5.48-5.08
				c-5.88-2.59-10.55-4.64-15.99-7.03c0,2.62,0,3.63,0,5.02c-4.2-1.15-8.01-7.22-11.25-1.21c-2.78-0.25-4.84-0.44-6.91-0.63
				c0.13,2.64,0.25,5.29,0.39,8.04c-6.88,1.43-7.58,2.59-4.79,8.59C1029.17,272.12,1027.41,272.79,1025.32,273.59z M240.42,199.13
				c4.27,5,8.22,9.43,15.12,7.76c5.59-1.36,11.26-2.53,16.71-4.36c10.62-3.56,21.58-4.08,32.84-4.37c-0.21-0.82-0.2-1.73-0.64-2.32
				c-3.88-5.28-7.75-10.57-11.84-15.68c-0.73-0.91-2.69-1.67-3.74-1.35c-6.46,1.92-12.35,0.87-18.28-2.09
				c-3.36-1.68-12.76,0.86-13.73,3.37C253.71,188.29,248.32,194.54,240.42,199.13z M490.74,509.77c0.13,0.73,0.26,1.45,0.39,2.18
				c7.46,0.92,14.91,2.53,22.38,2.58c7.5,0.05,14.08-3.28,19.29-9.02c2.25-2.48,4.83-4.7,7.47-6.76c1.48-1.15,3.4-1.73,6.23-3.12
				c-4.22-1.06-7.94-0.94-9.85-2.74c-2.37-2.23-3.96-3.3-7.2-2.31c-1.29,0.39-3.64-0.61-4.55-1.75c-0.68-0.85-0.02-2.98,0.39-4.45
				c0.29-1.04,1.16-1.91,2.67-4.27c-5.9-0.72-10.91-1.98-15.79-1.58c-1.84,0.15-3.37,4.14-5.03,6.38c-0.5,0.67-1.01,1.33-1.46,2.02
				C500.69,494.54,495.72,502.16,490.74,509.77z M201.28,582.9c9.3,5.61,17.58,10.61,25.9,15.56c0.43,0.26,1.21-0.01,1.81-0.11
				c11.69-2.02,18.86-10.23,25.79-18.79c2.3-2.84,1.74-4.65-1.16-6.9c-3.73-2.9-7.12-6.34-10.22-9.92c-2.19-2.53-3.98-3.26-6.59-0.9
				c-7.22,6.51-15.43,11.39-24.42,15.1C209.07,578.29,206.01,580.32,201.28,582.9z M879.16,387.88c-0.86,2.96-2.18,5.37-2.06,7.71
				c0.11,2.14,1.77,4.2,2.77,6.33c-0.5,0.8-1.44,2.34-2.39,3.88c1.71,0.91,3.42,1.81,5.9,3.13c-2.31,0.59-3.81,0.98-5.92,1.52
				c0.39,3.54,0.92,6.95,1.1,10.38c0.35,6.71,1.02,13.46,0.58,20.13c-0.47,7.04,3.86,12.08,6.06,17.99c0.29,0.77,1.86,1.06,3.5,1.93
				c0.35-2.57,1.6-5.28,0.74-6.57c-2.77-4.14-0.48-7.53,0.34-11.35c0.82-3.78,2.27-8.66,0.67-11.46c-2.71-4.74-1.02-7.74,1.23-11.22
				c1.71-2.65,3.87-5.06,5.29-7.85c3.93-7.75,7.57-15.64,11.57-23.99c-6.51-1.38-11.79-3.39-17.31-0.22
				c-1.11,0.64-2.84,0.31-4.28,0.23C884.17,388.32,881.38,388.05,879.16,387.88z M735.82,488.82c-4.11-5.75-3.71-7.93,1.88-11.46
				c8.56-5.42,17.1-10.88,25.77-16.13c2.06-1.25,4.54-1.95,6.92-2.51c6.99-1.66,14.58-1.3,20.66-6.25
				c-3.68-5.75-5.52-13.26-12.97-14.01c-1.33,3.01-1.67,5.86-3,6.44c-7.69,3.37-15.54,6.42-23.47,9.17c-6.71,2.33-13.64,4-20.38,6.24
				c-1.06,0.35-1.61,2.22-2.42,3.38c-0.8,1.14-1.38,2.78-2.48,3.28c-4.43,2.04-6.21,4.97-4.47,9.5c2.01,5.22,4.6,10.21,7.15,15.76
				C731.49,490.99,733.62,489.93,735.82,488.82z M896.48,632.59c-6.64,3.38-11.87,5.89-16.95,8.67c-6.34,3.48-12.57,7.02-20.24,4.39
				c-0.85-0.29-1.97,0.08-2.94,0.26c-6.41,1.25-12.82,2.53-20.89,4.13c4.83,1.76,8.34,3.4,12.04,4.32c6.28,1.57,12.79,2.27,18.98,4.1
				c6.49,1.91,12.72,4.7,19.06,7.13c6.08,2.33,12.16,4.69,19.35,7.46c-1.61-5.32-3.14-9.97-4.42-14.69
				c-0.98-3.61-1.87-7.28-2.42-10.97C897.35,642.75,897.03,638.04,896.48,632.59z M636,397.18c5.1,1.01,10.23,2.08,15.39,3.02
				c3.77,0.69,6.61-0.51,8.95-3.83c1.68-2.38,4.21-4.21,6.49-6.12c7.84-6.56,12.2-15.37,16.88-24.52c-8.27-2.93-15.8-5.6-23.22-8.22
				C652.39,370.62,644.34,383.67,636,397.18z M275.36,423.03c0.03-0.25,0.06-0.49,0.09-0.74c3.22-0.29,7.97,0.58,9.29-1.16
				c1.72-2.26,0.73-6.6,0.9-10.04c0.01-0.16,0.03-0.4-0.06-0.49c-1.51-1.41-3.03-3.97-4.57-3.98c-6.19-0.06-12.42,0.41-18.56,1.24
				c-2.26,0.31-4.29,2.21-6.48,3.25c-2.25,1.07-4.5,2.46-6.89,2.86c-2.74,0.46-5.64-0.04-8.46-0.07c-8.02-0.09-8.72,0.53-9.99,8.66
				c3.43,0.44,7.05,0.38,10.23,1.52c1.93,0.69,4.47,3.23,4.5,4.98c0.07,3.93,2.1,4.2,4.77,3.76c11.12-1.85,22.24-3.16,33.57-1.99
				c1.27,0.13,3.74-1.66,3.92-2.82c0.37-2.46-0.97-4.3-4.11-4.24C280.79,423.81,278.07,423.3,275.36,423.03z M362.43,420.6
				c0,4.21,0.24,7.11-0.05,9.96c-0.59,5.74-3.04,11.8-1.87,17.1c2.14,9.7,3.97,20.75,16.94,22.93c0.37,0.06,0.66,0.68,0.97,1.05
				c5.53,6.75,9.55,6.76,14.7,0.1c1.39-1.8,2.91-3.49,4.82-5.75C388.06,449.92,378.31,433.76,362.43,420.6z M6.01,419.1
				c10.21-2.61,19.98-4.53,28.32-10.54c7.42-5.36,14.82-10.76,22.49-15.75c5.04-3.28,10.55-5.84,15.84-8.73
				c-0.16-0.49-0.32-0.97-0.48-1.46c-15.52,7.36-31.01,14.82-48.23,10.95c2.27-0.5,5.46-2.15,8.25-1.65
				c15.15,2.73,26.61-6.38,39.41-11.17c-0.13-0.48-0.25-0.97-0.38-1.45c-21.7,5.4-43.41,10.79-65.11,16.19
				c0.07,0.41,0.14,0.81,0.21,1.22c4.43-0.65,8.87-1.31,13.3-1.96c-3.26,1.83-6.8,2.45-10.13,3.64c-1.38,0.49-3.26,2.12-3.34,3.33
				C5.82,407.32,6.01,412.97,6.01,419.1z M303.14,233.95c0-0.81,0.01-1.62,0.01-2.44c-8.7-0.54-17.4-1.2-26.11-1.6
				c-9.59-0.45-19.19-0.92-28.79-0.9c-8.62,0.02-17.23,0.72-25.85,1.07c-4.46,0.18-8.99-0.17-13.38,0.45
				c-8.03,1.14-16,2.75-23.96,4.34c-3.23,0.64-6.37,1.72-9.55,2.61c8.6,5.36,17.3,4.38,26.04,3.03c7.54-1.17,14.67-3.84,22.75-0.03
				c5.18,2.45,11.96,1.53,18.44,2.16c-0.53-5.61,2.22-5.48,6.4-5.15c7.56,0.59,15.25-0.45,22.56,2.5c0.5,0.2,1.24-0.18,2.23-0.36
				c-0.6-4.48,2.29-5.03,5.59-5c4,0.03,8,0.25,11.99,0.14C295.41,234.65,299.27,234.23,303.14,233.95z M968.49,418.74
				c-0.36-0.5-0.71-1-1.07-1.51c2.45-1.57,4.89-3.14,6.72-4.31c-3.21-6.16-10.97-2.35-12.69-8.75c-0.06-0.22-1.23-0.18-1.89-0.2
				c-3.63-0.08-7.27-0.37-10.88-0.12c-2.81,0.2-5.57,1.07-8.36,1.65c0.67,2.19,1.33,4.37,2.12,6.95c-1.9,0.9-4.83,1.46-6.44,3.23
				c-3.43,3.79,2.57,3.69,2.94,5.02c-2.31,2.5-4.15,4.49-5.99,6.48c2.15,0.83,4.29,1.66,6.35,2.46c0,1.42,0,3.25,0,5.09
				c2.03-0.04,4.23,0.4,6.04-0.25c2.75-0.99,4.46-1.29,5.92,1.96c1.22,2.73,3.85,3.12,6.26,1.19c3.27-2.61,7.88-3.22,9.41-8.55
				c0.77-2.69,2.21-7.05,6.61-7.75c0.24-0.04,0.32-1.12,0.54-2C972.02,419.11,970.26,418.93,968.49,418.74z M109.31,211.79
				c-2.99,0.73-5.28,0.99-7.33,1.84c-13.3,5.53-26.6,11.06-39.77,16.89c-5.17,2.29-9.97,4.05-15.77,1.92
				c-2.64-0.97-5.94-0.35-8.92-0.12c-8.93,0.69-17.84,1.55-26.76,2.34c-1.44,0.13-2.88,0.25-4.47,0.39c0,3.45,0,6.52,0,10.15
				c6.76-0.75,13.16-1.54,19.57-2.15c11.06-1.04,22.17-2.92,33.2-2.59c7.01,0.21,13.11-0.61,19.06-3.92
				c5.8-3.23,11.7-6.31,17.36-9.77C101.13,223.31,106.39,219.31,109.31,211.79z M309.44,505.5c-0.41,0.03-0.83,0.05-1.24,0.08
				c-0.5,1.16-1.35,2.29-1.43,3.47c-0.91,13.07-1.95,26.14-2.28,39.23c-0.06,2.23,2.61,5.44,4.86,6.66
				c6.77,3.68,15.36,0.31,19.9-7.26c1.71-2.85,2.89-6.04,4.75-8.77c5.45-8.01,2.23-16.47,2.4-25.32
				c-12.17,5.14-13.75,16.92-19.39,26.33c-1.52-2.41-2.49-4.72-2.88-7.13c-1.08-6.66-1.85-13.37-2.89-20.04
				C310.85,510.29,310.05,507.91,309.44,505.5z M679.86,577.43c-0.59-7.76,0.18-14.74-1.94-20.71c-2.3-6.5-2.09-12.17-0.3-18.39
				c0.81-2.81,1.48-5.9,1.18-8.75c-1.01-9.51-1.19-9.49-10.74-8.84c-8.99,0.62-9,0.62-7.63,9.13c0.04,0.27,0.4,0.49,0.98,1.15
				c4.37-0.59,9.02-1.23,13.68-1.86c0.06,0.43,0.13,0.86,0.19,1.28c-1.68,0.41-3.34,0.91-5.04,1.21c-1.46,0.26-2.96,0.37-4.45,0.45
				c-5.89,0.32-7.29,1.48-7.71,7.25c-0.54,7.42-0.96,14.86-1.14,22.29c-0.04,1.57,0.75,3.95,1.92,4.63
				C665.15,569.92,671.67,573.15,679.86,577.43z M1055.19,151.1c11.21-0.05,20.93-2.69,30.7,0.33c0.94,0.29,2.28,0.9,2.86,0.5
				c2.24-1.54,5.75-3.21,6.03-5.21c0.54-3.89-3.35-2.39-5.5-2.41c-2.97-0.04-5.95,0.55-8.93,0.58c-17.11,0.16-34.23-0.04-51.34,0.43
				c-11.22,0.3-22.18,2.44-32.08,7.98c1.66,1.24,4.07,2.1,4.58,3.6c1.57,4.61,5.05,3.81,7.58,2.45c7-3.74,13.8-3.81,20.87-0.66
				c0.43,0.19,0.99,0.23,1.47,0.18c9.17-0.84,18.34-1.71,28.13-2.63C1057.99,154.4,1057.08,153.33,1055.19,151.1z M6.3,492.9
				c1.95-3.01,3.02-5.06,4.47-6.81c2.5-3.02,4.71-6.83,7.98-8.57c9.75-5.21,19.95-9.58,29.97-14.27c0.3-0.14,0.64-0.22,0.97-0.23
				c9.15-0.34,12.69-5.76,13.09-14.23c0.1-2.13,1.17-4.24,1.14-6.35c-0.02-1.57-0.67-3.8-1.8-4.49c-1.07-0.65-3.35-0.09-4.72,0.65
				c-3.49,1.88-6.94,3.94-10.1,6.33c-4.44,3.36-8.61,7.08-12.88,10.66c-4.77,4.01-9,9.07-14.4,11.85
				C5.32,475.01,5.12,474.63,6.3,492.9z M202.74,629.31c-4.86-1.72-9.91-3.15-14.65-5.26c-11.93-5.29-23.37-11.59-36.77-13.15
				c-5.58-0.65-10.82-3.98-16.32-5.79c-1.38-0.45-3.15,0.27-4.74,0.45c0.88,1.47,1.42,3.49,2.71,4.32c4.32,2.76,9.06,4.87,13.38,7.62
				c10.62,6.78,20.95,14.04,31.75,20.51c2.56,1.53,7.02,1.94,9.7,0.78C192.92,636.57,197.39,632.83,202.74,629.31z M1154.7,437.05
				c0.78-2.21,1.24-3.52,2.19-6.2c-7.86,1.78-15.47-2.64-21.86,3.69c-4.53-2.57-5.35-1.99-5.71,4.28c6.16,0.8,15.89-5.78,15.89,7
				c2.86,1,6.33,1.13,7.5,2.83c2.69,3.92,4.27,8.6,6.36,13.1c4.59,0.2,8.35,1.26,5.88,7.09c6.91,2.73,9.88-3.03,14.18-5.64
				c0.19-0.11,0.2-0.52,0.26-0.71c-1.27-0.39-2.54-0.77-4.95-1.5c2.6-1.03,3.97-1.58,5.67-2.25c-2.31-1.15-4.12-2.06-6.56-3.28
				c1.84-1.24,2.95-1.99,4.12-2.78c-1.19-0.9-2.12-1.6-2.95-2.23c1.97-1.58,3.63-2.91,5.82-4.67c-4.11-0.68-7.16-1.18-11.3-1.86
				c2.55-1.85,3.9-2.82,5.25-3.8c-0.11-0.28-0.21-0.56-0.32-0.85c-2.94,0.2-5.88,0.41-10.08,0.7c2.52-2.03,3.78-3.05,5.05-4.07
				c-0.18-0.42-0.36-0.83-0.53-1.25C1164.18,435.42,1159.75,436.18,1154.7,437.05z M810.22,227.84c-5.25,0-9.85,0.72-14.12-0.16
				c-6.92-1.43-11.9-0.64-13.49,7.24c-0.09,0.45-0.49,0.89-0.85,1.22c-3.79,3.48-5.37,6.74,0.18,10.24c1.34,0.85,1.87,3.06,2.67,4.69
				c1.7,3.42,3.36,6.86,4.98,10.32c1.49,3.19,4.26,3.79,6.53,1.85c5.98-5.09,14.4-8.41,12.92-19.07
				C808.34,239.1,809.72,233.74,810.22,227.84z M113.05,492.88c4.73,0,8.49,0.09,12.24-0.07c0.81-0.03,1.93-0.76,2.31-1.48
				c2.05-3.93,4.05-7.9,5.81-11.96c2.34-5.4,4.81-10.17,9.52-14.49c9.8-8.98,16.38-20.18,14.15-34.53c-6.38-0.4-7.6,0.41-9.79,6.01
				c-0.78,1.99-1.66,3.98-2.78,5.8c-3.55,5.75-7.36,11.34-10.85,17.12c-3.16,5.24-5.6,10.88-12.03,13.39
				c-1.39,0.54-1.82,3.76-2.52,5.8c-1.14,3.34-2.04,6.76-3.25,10.07C115.38,489.82,114.35,490.9,113.05,492.88z M494.32,240.49
				c4.74,1.11,9.33,2.18,13.93,3.25c-0.03,0.67-0.06,1.35-0.09,2.02c-5.93,4.15-13.47,2.05-19.6,5.44c-0.21-0.83-0.41-1.66-0.62-2.49
				c5.27-1.04,10.54-2.08,15.81-3.12c-11.3-4.08-46.12,2.25-53.66,9.61c5.28,0,9.68,0,15.33,0c-2.52,2.31-3.86,3.54-5.58,5.12
				c2.6,0.5,4.51,1.11,6.45,1.2c8.07,0.36,16.26,1.64,23.25-4.42c1.6-1.39,4.31-1.48,6.49-2.21c5.91-1.98,11.82-3.97,17.72-6
				c1.72-0.59,3.32-1.58,5.06-2.02c4.62-1.17,6.05-4,5-8.41C511.96,236.89,499.43,237.72,494.32,240.49z M1061.32,430.22
				c-5.01,0.32-10.45-0.12-15.36,1.29c-2.94,0.84-6.66,4.29-7.15,7.07c-0.73,4.15,3.8,4.5,7.19,4.79c2.24,0.19,4.48,0.48,5.24,0.56
				c1.83,3.72,2.8,8.06,4.31,8.25c2.44,0.31,5.22-2.1,8.25-3.56c3.61,3.52,5.91,3.48,8.89-0.9c-0.83-1.27-1.65-2.53-3.05-4.67
				c3.2,0.84,5.26,1.38,7.31,1.92c0.16-0.35,0.32-0.7,0.48-1.06c-1.61-1.34-3.22-2.68-5.36-4.47c4.21,0.35,7.62,0.63,11.37,0.95
				c-0.36-2.36-0.56-3.69-0.76-5.06c2.55,0.61,4.72,1.14,6.89,1.66c0.11-0.33,0.22-0.67,0.33-1c-0.86-0.93-1.73-1.86-2.63-2.83
				c2.23-0.96,4.03-1.73,5.83-2.5c-3.56-5.62-11.93,1.33-13.85-6.32c-5.01,0.71-10.06,1.26-15.02,2.27
				C1063.2,426.83,1062.5,428.7,1061.32,430.22z M684.21,257.51c-2.6-0.12-5.2-0.34-7.8-0.33c-4.18,0.02-9.08,2.62-11.4-3.52
				c-0.13-0.35-1.2-0.53-1.8-0.48c-4.13,0.35-8.32,0.49-12.35,1.34c-1.57,0.33-3.75,2.17-4.01,3.62c-1.21,6.77-2.21,13.63-2.58,20.49
				c-0.32,6,0.29,6.1,6.13,5.49c1.76-0.19,3.6,0.28,5.4,0.51c5.75,0.73,16.5-4.95,17.39-9.8
				C674.53,267.57,677.15,261.45,684.21,257.51z M297.23,617.13c-5.77-0.56-10.51-1.19-8.94-8.22c0.13-0.6-1.82-2.03-3.02-2.49
				c-1.84-0.69-4.23-0.35-5.75-1.38c-4.43-2.98-7.92-0.79-12,0.9c-5.28,2.18-10.94,3.46-16.5,4.89c-1.69,0.43-3.58,0.07-5.37,0.07
				c-0.17,0.8-0.35,1.6-0.52,2.4c14.6,6.64,29.18,13.31,43.82,19.84c0.88,0.39,3.08-0.06,3.29-0.63
				C294.03,627.63,295.5,622.63,297.23,617.13z M1194.87,646.82c-3.79,6.63-7.47,13.28-11.42,19.78c-0.74,1.22-2.6,2.61-3.76,2.46
				c-1.08-0.14-2.67-2.19-2.73-3.45c-0.61-13.37-2.75-27.12,9.26-37.52c1.98-1.71,2.71-4.86,4.02-7.34
				c-2.83,2.31-4.57,5.09-7.02,6.95c-3.23,2.45-4.96,1.45-5.69-2.58c-0.42-2.34-0.83-4.69-1.24-7.04c-0.59-0.04-1.18-0.07-1.77-0.11
				c-0.39,1.69-1.1,3.39-1.1,5.08c0.01,4.3,0.47,8.6,0.51,12.9c0.07,6.31-0.01,12.63-0.14,18.94c-0.06,2.98-0.05,6.02-0.65,8.91
				c-0.68,3.24-3.63,4.05-6.03,1.73c-1.26-1.22-2.25-2.91-2.85-4.58c-2.14-5.93-4.01-11.95-6.07-17.92
				c-0.88-2.57-1.96-5.07-2.95-7.61c-0.08,3.57,0.78,6.74,1.68,9.89c5.28,18.5,14.75,35.8,15.97,55.69c2.18,0,4.43,0,7.12,0
				c0.33-4.31,1.09-8.29,0.84-12.2c-0.75-11.52,3.31-21.29,10.55-29.79C1194.61,655.27,1195.93,651.47,1194.87,646.82z
				M588.88,260.39c-9.9-1.77-18.84-3.49-27.84-4.83c-1.47-0.22-3.97,0.82-4.7,2.04c-5.71,9.6-11.32,19.21-20.54,26.03
				c-0.09,0.06,0.14,0.55,0.35,1.3c1.4-0.42,2.98-0.53,4.11-1.31c5.7-3.93,11.88-4.59,18.59-3.67c12.19,1.66,19.6-6.29,26.93-14.08
				C587.06,264.5,587.7,262.53,588.88,260.39z M973.7,219.35c-0.02-0.6-0.03-1.2-0.05-1.79c-5.5-0.81-11-1.62-16.5-2.42
				c-1.12-0.16-3.03,0-3.25-0.53c-1.6-3.9-4.89-2.62-7.64-2.69c-4.81-0.11-9.63,0.14-14.44,0.04c-10.94-0.22-21.89-0.7-32.83-0.79
				c-4.26-0.03-8.46,0.88-12.15,5.28c2.31,0.28,3.91,0.25,5.36,0.72c1.26,0.41,2.5,1.21,3.46,2.13c1.6,1.54,2.98,3.32,4.45,5
				c0.19-0.54,0.39-1.09,0.58-1.63c1.94-0.64,3.91-1.91,5.81-1.82c11.77,0.55,23.53,1.41,35.28,2.19c1.29,0.09,2.6,0.63,3.85,0.48
				c7.01-0.84,14.02-1.8,21.01-2.8C969.02,220.37,971.35,219.81,973.7,219.35z M319.2,279.57c0,0.36,0.01,0.72,0.01,1.08
				c-2.29,0.23-4.59,0.41-6.87,0.69c-4.9,0.61-8.77,2.24-10.5,7.68c-0.66,2.07-3.36,4.19-5.57,4.91c-4.04,1.31-8.38,1.94-12.64,2.22
				c-10.36,0.69-20.74,1.01-31.94,1.52c1.23,0.46,1.76,0.81,2.3,0.83c12.76,0.44,25.52,0.92,38.29,1.21
				c9.3,0.22,15.02-7.95,23.28-10.24c0.14-0.04,0.3-0.21,0.34-0.35c1.25-4.54,5.34-4.86,8.78-5.18c5.28-0.5,11.04-1.38,15.89,0.15
				c10.54,3.31,15.12-5.61,22.62-8.37c-0.13-0.43-0.27-0.87-0.4-1.3c-9.42,1.71-18.84,3.42-28.25,5.13
				c-0.08-0.42-0.16-0.84-0.24-1.25c5.14-1.32,10.28-2.64,15.42-3.95c-14.7-1.84-28.94-0.68-42.58,5.22
				C311.15,279.57,315.17,279.57,319.2,279.57z M227.94,556.84c-0.47-0.49-0.93-0.98-1.4-1.48c-17.32,0.92-34.64,1.84-51.95,2.75
				c7.26,6.73,14.4,13.64,21.94,20.08c1.38,1.18,4.87,1.34,6.56,0.44c4.51-2.4,8.93-5.24,12.72-8.65
				C220.23,566.01,223.93,561.26,227.94,556.84z M1194.73,171.44c0-8.36,0-16.37,0-25.06c-13.54,0-26.66,0.18-39.77-0.04
				c-17.93-0.3-35.86-0.94-53.8-1.29c-1.28-0.03-2.58,1.13-3.88,1.74c1.09,0.95,2.04,2.36,3.31,2.74c2.84,0.86,5.88,1.04,8.76,1.79
				c8.06,2.11,15.98,1.67,24.14,0.16c6-1.11,12.32-0.45,18.49-0.8c4.78-0.27,9.54-0.9,14.32-1.31c3.73-0.32,7.55-1.15,11.17-0.62
				c2.71,0.4,6.35,2.44,4.28,6.18c-3.06,5.53-0.84,9.87,2,14.24C1186.58,173.56,1190.8,171.3,1194.73,171.44z M300.79,550.46
				c0.53-2.07,0.97-3.13,1.06-4.22c1.02-11.73,2.04-23.46,2.95-35.2c0.31-3.98-4.09-9.55-8-10.19c-1.05-0.17-2.85-0.06-3.23,0.57
				c-2.25,3.79-5.32,7.59-5.97,11.73c-1.04,6.65-0.65,13.58-0.38,20.37c0.11,2.65,0.85,5.86,2.51,7.73
				C292.67,544.54,296.53,547,300.79,550.46z M1067.09,153.01c-0.37,4.13-1.44,5.32-6.97,5.91c-8.41,0.9-16.84,1.61-25.27,2.19
				c-2.26,0.15-4.66-0.18-6.85-0.81c-3.86-1.1-5.84,0.29-5.51,4.41c0.13,1.65,0.56,3.27,0.74,4.92c0.52,4.8,2.63,6.8,6.81,5.03
				c7.98-3.37,15.91-1.88,23.96-1.13c5.56,0.52,11.15,0.64,17.86,1.01C1067.9,167.42,1072.8,159.19,1067.09,153.01z M485.69,290.41
				c-6.45,1.64-12.3,3.27-18.22,4.6c-4.19,0.94-8.52,1.24-12.67,2.27c-1.49,0.37-3.56,1.89-3.81,3.18
				c-0.77,3.98,6.95,12.85,11.04,12.66c8.21-0.37,16.41-1.14,24.6-1.8c4.44-0.36,6.69-2.95,5.28-6.96
				C490.17,299.44,487.67,294.78,485.69,290.41z M400.05,463.4c-0.13-1.21,0.12-2.85-0.49-4.04c-3.53-6.9-7.92-13.45-7.9-21.65
				c0-0.41-0.47-0.89-0.83-1.21c-6.42-5.76-7.46-13.65-9.14-21.51c-1.52-7.11-4.24-13.96-6.77-22c-4.23,6.27-7.83,11.02-10.77,16.16
				c-2.54,4.44-1.31,9.12,2.33,12.28C380.2,433.36,388.75,449.2,400.05,463.4z M327.15,356.11c0.12-0.62,0.23-1.24,0.35-1.85
				c-5.9-4.25-12.05-8.2-17.65-12.83c-6.01-4.97-12.63-7.57-20.35-7.21c-2.97,0.14-5.92,0.75-7.91,1.01
				c-1.59,2.85-2.89,5.18-4.12,7.4c6.79,1.73,6.79,1.73,5.09,8.89C297.26,355.2,312.16,356.23,327.15,356.11z M317.82,493.11
				c0.66,0.07,1.32,0.14,1.98,0.21c0.93-2.54,2.04-5.03,2.76-7.63c1.72-6.15-1.54-13.42,4.21-18.75c0.44-0.41,0.36-2.09-0.11-2.65
				c-3.75-4.45-1.81-7.71,1.71-11.16c3.9-3.82,2.3-9.19-3.05-9.98c-5.05-0.75-10.22-0.62-15.33-0.95c-3.61-0.23-5.1,1.51-4.14,4.83
				c1.84,6.33,4.03,12.57,6,18.86c2.29,7.34,8.33,13.93,4.3,22.61C315.66,489.56,317.21,491.56,317.82,493.11z M631.3,525.63
				c5.71,0.61,11.11,1.18,16.51,1.75c-0.52,1.05-0.95,1.37-1.39,1.37c-1.82,0.01-3.65-0.01-5.47-0.15c-10.4-0.79-10.37-0.8-9.86,9.21
				c0.11,2.15-0.09,4.32-0.1,6.48c-0.02,3.77-0.01,7.54-0.01,11.19c5.77,1.43,11.46,2.84,17.89,4.43c2.74-12-0.2-24.39,1.32-36.45
				C633.03,518.63,633.03,518.63,631.3,525.63z M745.65,688.66c-0.47-0.4-0.94-0.8-1.42-1.21c-3.48,0.85-7.05,2.66-10.42,2.33
				c-5.63-0.56-8.16,2.03-10.23,6.54c-6.84,14.91-18.23,27.32-23.48,43.17c-1.22,3.66-4.42,6.67-6.71,9.98
				c2-0.72,3.53-1.89,4.74-3.34c5.35-6.37,10.46-12.95,15.94-19.2c7.61-8.68,15.65-16.99,23.16-25.76
				C740.49,697.39,742.88,692.85,745.65,688.66z M814.59,642.13c-0.39,6.75,0.85,11.41,5.74,14.91c1.63,1.16,2.28,3.89,3.03,6.02
				c1,2.86,2.32,4.57,5.71,5.32c2.52,0.55,4.97,2.89,6.74,5.02c1.64,1.97,2.04,4.92,3.51,7.09c1.69,2.49,3.95,4.58,5.96,6.84
				c0.48-0.35,0.96-0.71,1.45-1.06c-0.82-2.97-1.77-5.92-2.44-8.93c-0.86-3.86-1.7-7.75-2.13-11.68c-0.13-1.14,1.17-2.44,1.82-3.67
				c1.19,0.68,2.43,1.28,3.55,2.06c0.64,0.44,1.09,1.15,1.69,1.82c3.16-1.28,6.05-2.46,8.95-3.63c-0.01-0.47-0.01-0.93-0.02-1.4
				c-2.04-0.9-4.11-2.64-6.11-2.56c-9.43,0.38-16.16-5.05-23.43-9.74C824.43,645.86,819.53,644.34,814.59,642.13z M270.79,396.24
				c4.94,0.81,9.32,1.53,14.26,2.34c-0.42-3.41-0.5-6.08-1.17-8.6c-0.32-1.18-1.63-2.73-2.74-2.97c-6.1-1.3-12.25-2.35-18.42-3.27
				c-3.44-0.51-6.96-0.51-10.42-0.91c-11.91-1.38-11.9-1.4-13.46,10.18c-0.15,1.15-0.75,2.33-0.58,3.4c0.14,0.91,1.09,2.42,1.68,2.43
				c3.7,0.02,7.42-0.14,11.09-0.57c3.54-0.41,7.05-1.83,10.54-1.71c4.49,0.16,8.54,0.7,9.21-5.42
				C270.79,392.74,270.79,394.33,270.79,396.24z M495.18,370.23c-0.14-0.7-0.28-1.39-0.41-2.09c-4.81-2.2-9.56-0.08-14.27,0.8
				c-11.31,2.11-22.33,2.78-32.56-4.08c-3.47-2.33-9.38-0.12-9.45,3.75c-0.17,9.64,0.43,19.3,0.71,28.95
				c0.58,0.27,1.16,0.54,1.74,0.81c3.89-4.52,9.21-8.46,11.37-13.69c3.84-9.33,11.24-10.91,19.41-11.74
				c4.77-0.49,9.66,0.22,14.44-0.2C489.22,372.47,492.17,371.11,495.18,370.23z M307.06,420.03c-0.68,2.62-0.97,4.24-1.52,5.77
				c-2.05,5.8,0.08,9.47,6.21,9.83c3.95,0.23,7.96-0.06,11.9-0.54c1.74-0.21,4.75-1.47,4.76-2.3c0.06-3.55-0.78-7.11-1.44-11.65
				c1.21-1.22,1.92-13.02-0.27-15.04c-0.5-0.46-1.49-0.7-2.17-0.59c-4.61,0.77-9.18,1.75-13.79,2.46c-2.09,0.32-5.15-0.28-4,3.13
				c0.93,2.74-2.2,8.21,4.62,7.71c4.34-0.32,8.73-0.06,13.1-0.06c0,0.43,0,0.85,0,1.28C318.54,420.03,312.61,420.03,307.06,420.03z
				M994.64,428.14c-0.5-0.15-1.01-0.31-1.51-0.46c-2.57-8.59-5.14-17.18-7.71-25.77c-0.73,0.18-1.47,0.36-2.21,0.54
				c3.85,18.58,14.2,36.11,10.88,56.43c-6.35-1.53-7.66-6.54-10.04-10.52c-3.32-5.56-6.4-11.27-9.83-16.76
				c-0.81-1.3-2.57-2-3.89-2.98c4.42,9.45,9.85,17.74,14.52,26.44c2.85,5.31,5.31,11.08,6.53,16.93c1.48,7.11,1.39,14.54,2.14,21.81
				c0.21,2.03,0.98,4.01,1.5,6.01c0.48-0.09,0.96-0.19,1.44-0.28c0.17-0.91,0.48-1.82,0.48-2.73c-0.04-8.65-0.03-17.29-0.32-25.93
				c-0.04-1.32-1.29-3.04-2.47-3.78c-2.63-1.63-2.9-3.71-0.5-4.95c4.26-2.2,4.4-5.54,4.47-9.59c0.06-3.77,0.73-7.63,1.77-11.27
				c1.76-6.13,4.03-12.11,6.08-18.15c-0.33-0.18-0.67-0.35-1-0.53c-2.62,4.13-5.24,8.26-7.86,12.39c-0.52-0.21-1.03-0.41-1.55-0.62
				c1.55-9.65,3.11-19.3,4.66-28.95c-0.73-0.1-1.46-0.21-2.18-0.31C996.91,412.79,995.77,420.46,994.64,428.14z M294.6,333.15
				c0.19-0.69,0.38-1.39,0.57-2.08c-1.84-0.92-3.59-2.17-5.54-2.68c-3.98-1.04-8.06-1.74-12.12-2.45c-4.06-0.71-8.12-1.52-12.21-1.83
				c-6.9-0.53-13.83-1.02-20.73-0.88c-3.46,0.07-7.03,1.2-10.25,2.56c-1.16,0.49-2.07,3.14-1.89,4.66c0.11,0.93,2.44,1.91,3.92,2.29
				c4.97,1.27,9.97,2.53,15.03,3.32c4.07,0.63,8.36,1.31,12.35,0.69C273.96,335.16,283.75,330.41,294.6,333.15z M214.6,410.8
				c1.78-1.32,3.16-2.51,4.7-3.44c5.13-3.09,8.29-9.34,15.29-9.51c0.56-0.01,1.4-1.33,1.56-2.14c0.61-3.09,1.23-6.22,1.3-9.34
				c0.02-0.98-1.7-2.85-2.61-2.85c-5.61,0.03-11.23,0.37-16.83,0.83c-6.08,0.5-9.66,4.31-10.1,10.29c-0.06,0.79-0.17,1.79-0.66,2.32
				c-3.68,3.99-1.47,6.28,2.09,8.61C211.2,406.79,212.58,408.75,214.6,410.8z M642.42,285.5c0-8.03,0-15.6,0-23.19
				c-2.63,0.34-4.79,0.62-7.52,0.97c2.41-4.55,10.01,0.17,9.76-7.33c-2.68,0.33-5.27,0.76-7.87,0.93c-4.12,0.28-8.49,2.42-12.12-1.68
				c-0.38-0.43-2.97,0.12-3.3,0.81c-0.74,1.54-1,3.48-0.8,5.19c0.07,0.61,2.07,1.51,3.1,1.4c2.53-0.27,5.01-1.05,7.51-1.63
				c0.12,0.51,0.24,1.03,0.36,1.54c-2.33,0.54-4.7,0.94-6.97,1.69c-1.24,0.41-2.3,1.4-3.44,2.12c0.82,1.17,1.64,2.34,2.46,3.5
				c-0.4,0.89-1.37,2-1.21,2.91c0.71,4.07,1.62,8.12,2.76,12.09c0.22,0.75,1.8,1.61,2.72,1.59
				C632.66,286.26,637.43,285.84,642.42,285.5z M468.04,440.02c2.52-0.88,5.13-1.88,7.8-2.69c6.34-1.95,6.17-1.89,5.12-8.7
				c-1.29-8.33-2.92-16.69-3.16-25.07c-0.21-7.4-0.23-8.34-8.19-9.4c-1.11,4.79-1.58,9.87-3.55,14.28c-1.51,3.37-2.29,6.07-0.51,9.31
				c1.74,3.16,1.98,6.21-0.25,9.37c-0.67,0.94-0.96,2.59-0.65,3.69C465.47,433.72,466.72,436.52,468.04,440.02z M981.51,455.04
				c-0.96-5-7.32-4.76-11.33-7.58c-0.66-0.47-2.39-0.47-2.86,0.03c-3.18,3.37-7.35,2.03-11.1,2.7c-2.73,0.48-5.39,1.35-8.05,2.13
				c-0.33,0.1-0.48,0.76-0.74,1.2c0.85,1.01,1.63,1.95,2.85,3.41c-4.24,1.43-8.04,2.49-11.64,4c-2.08,0.87-5.65,2.87-5.47,3.68
				c0.82,3.71,4.37,3.53,7.31,3.1c2.22-0.33,4.34-1.43,6.46-2.3c1.58-0.65,3.08-1.49,5.25-2.56c-0.36,2.35-0.6,3.86-0.86,5.55
				c5.39,1.8,8.91-8.66,14.23-1.61c6.45-5.17,7.89-5.86,10.15-5.16C977.46,459.63,979.12,457.75,981.51,455.04z M358.73,290.91
				c11.59,5.64,22.83-0.46,34.35-0.37c11.78,0.09,23.57,0.02,35.93,0.02c-2.58-4.97-4.31-8.69-6.46-12.16
				c-0.45-0.73-2.79-1.14-3.63-0.64c-6.8,4.05-14.15,4.18-21.7,3.74c-2.39-0.14-4.84,0.64-7.26,1.04c-6.97,1.16-13.91,3.07-20.9,3.29
				C363.99,285.99,359.95,286.04,358.73,290.91z M691.87,589.32c0.08-1.31,0.22-2.1,0.16-2.87c-0.93-11.73-1.83-23.46-2.92-35.17
				c-0.16-1.69-0.91-3.72-2.1-4.85c-3.97-3.77-3.94-7.97-2.52-12.78c0.69-2.32,0.98-4.96,0.65-7.34c-1.93-13.96-4.09-27.9-6.22-41.83
				c-0.17-1.1-0.79-2.14-1.61-4.27c-0.74,1.78-1.22,2.36-1.16,2.88c0.98,9.25,2.36,18.47,2.95,27.74
				c0.65,10.19,1.16,20.46,0.54,30.62c-0.29,4.69-1.15,8.96,0.86,13.53c1.16,2.63,1.91,5.98,1.36,8.71
				C679.75,574.16,681.96,582.71,691.87,589.32z M1082.58,330.73c-0.09,0.37-0.19,0.73-0.28,1.1c1.28,0.64,2.63,1.92,3.84,1.8
				c7.42-0.7,14.01,0.87,19.46,6.18c1.27,1.24,2.53,2.49,3.79,3.74c-1.7,1.14-3.41,3.26-5.09,3.24c-4.44-0.06-8.85-1.34-13.3-1.57
				c-3.55-0.19-7.14,0.45-10.72,0.71c8.17,2.02,15.99,4,23.92,4.64c8.79,0.71,15.43,5.57,22.61,9.6c0.72,0.4,1.57,0.94,2.3,0.86
				c1.39-0.15,2.74-0.71,4.1-1.09c-0.01-0.45-0.02-0.9-0.03-1.36c-2.09-0.81-4.11-2.03-6.27-2.34c-7.24-1.05-9.79-4.1-9.64-14.12
				c0.02-1.32,0.42-2.64,0.45-3.96c0.26-9.95,2.34-11.74,12.35-13.56c5.08-0.93,10.01-3.3,14.66-5.68c1.73-0.89,2.49-3.67,3.69-5.59
				c-0.44-0.37-0.88-0.74-1.32-1.11c-0.98,6.43-5.91,7.01-10.65,8.07c-2.74,0.61-5.33,1.82-8.04,2.62c-1.78,0.53-3.64,0.79-5.47,1.17
				c-0.2-2.36-1.06-4.92-0.43-7.04c0.88-2.99,2.87-5.66,3.91-7.6c-1.84-1.04-3.53-1.55-4.54-2.68c-1.11-1.24-1.58-3.05-2.34-4.61
				c-0.5,0.23-0.99,0.47-1.49,0.7c0.94,2.37,1.95,4.71,2.78,7.12c0.47,1.35,1.19,2.95,0.86,4.19c-1.96,7.33-4.22,14.57-6.32,21.87
				c-0.88,3.05-2.93,4.37-5.61,2.83c-4.23-2.43-8.61-4.95-12.07-8.31c-3.8-3.69-6.64-8.36-9.9-12.61c-0.42,0.3-0.84,0.6-1.26,0.91
				c2.12,3.33,4.23,6.67,6.81,10.72C1089.25,329.99,1085.91,330.36,1082.58,330.73z M1046.01,204.38c-0.42-4.5-3.21-7.83-8.33-9.2
				c-0.67-0.18-1.39-1.05-1.64-1.77c-1.33-3.74-4.12-3.35-7.3-3.22c-6.41,0.26-12.85,0.17-19.26-0.11c-4.62-0.2-9.26-1.18-14.24,3.43
				c2.46,1.07,4.25,2.49,6.07,2.52c6.48,0.12,12.97-0.4,19.46-0.34c3.39,0.03,6.77,0.78,10.15,1.2c-10.4,1.05-20.8-1.54-31.1,1.54
				c0.43,4.26,3.04,5.88,6.43,6.11c3.47,0.24,6.98-0.16,10.47-0.17C1026.47,204.36,1036.22,204.38,1046.01,204.38z M313.1,493.76
				c0.19-2.6,0.56-4.69,0.46-6.76c-0.21-4.2,0.37-8.85-1.25-12.48c-2.95-6.61-6.97-12.83-11.21-18.73c-1.01-1.4-4.65-0.9-7.09-1.28
				c-0.11,1.62-0.65,3.35-0.24,4.82c1.02,3.67,2.32,7.29,3.8,10.8c3.01,7.1,3.08,14.18,0.4,21.36c-0.33,0.87-0.53,1.79-0.74,2.52
				c2.28,0.45,5.07,0.17,6.04,1.37c2.25,2.76,3.85,2.28,6.12,0.39C310.51,494.84,311.99,494.35,313.1,493.76z M938.94,633.72
				c-3.13-4.43-6.91-4.19-10.46-2.27c-4.91,2.65-9.2,2.02-13.49-1.1c-0.94-0.68-1.92-1.54-2.99-1.73c-1.55-0.28-3.45-0.5-4.69,0.19
				c-0.7,0.39-0.65,2.68-0.45,4.04c1.51,10.12,10.41,15.67,14.12,24.55c3.41-2.3,6.08-3.98,8.61-5.84c2.48-1.82,4.83-3.82,8.07-6.4
				c-3.88-1.39-6.1-2.19-9.32-3.34C932.43,638.7,935.62,636.26,938.94,633.72z M1127.28,636.48c1.57,4.61,1.85,8.06-2.79,9.86
				c-2.28,0.88-4.62,1.99-6.46,3.55c-2.61,2.22-4.64,3.48-7.57,0.34c-2.25-2.41-6.59-0.4-7.89,4.09c4.95-4.35,7.41-1.76,8.98,3.11
				c1.48-1.35,2.47-2.26,3.03-2.77c1.63,2.1,3.02,3.89,4.41,5.69c1.88-1.98,3.75-3.96,5.86-6.19c1.41,1.83,2.54,3.3,4.29,5.55
				c0.92-2.82,1.54-4.73,2.29-7.04c1.51,1.28,2.69,2.28,4.47,3.79c1.36-2.66,2.49-4.88,3.6-7.06c1.3,0.47,2.55,0.92,4.1,1.47
				c0.27-2.12,0.47-3.66,0.72-5.62c1.38,0.55,2.36,0.94,2.71,1.08c1.4-1.54,2.34-3.25,3.78-4.01c2.75-1.45,2.78-3.69,1.17-5.24
				c-1.36-1.32-5.28-2.47-5.68-1.93c-3.29,4.48-6.9,2.41-10.77,1.43C1133.24,636.01,1130.68,636.48,1127.28,636.48z M848.53,690.13
				c-0.93,0.16-1.87,0.31-2.8,0.47c0.46,2.53,1.07,5.03,1.34,7.58c0.42,3.99,2.06,8.65,0.62,11.89c-3.15,7.07-3.5,14.14-3.1,21.48
				c0.53,9.9-0.57,10.38,11.8,9.11c3.48-0.36,4.59-1.9,4.26-5.59c-0.62-7.07-0.48-14.21-0.8-21.32c-0.04-0.98-0.82-2.69-1.42-2.77
				c-6.92-0.84-6.74-6.25-7.59-11.15C850.27,696.56,849.31,693.36,848.53,690.13z M574.27,280.45c5,8.11,15.05,8.2,22.94,12.45
				c1.13-3.81,1.97-6.99,3.01-10.09c1.39-4.13,3.71-8.49-4.96-9.23c2.81-1.19,4.19-1.78,6.35-2.69c-1.83-1.31-3.05-2.18-4.26-3.05
				c0.4-0.05,0.81-0.1,1.21-0.15c-0.33-2.85-0.65-5.7-1.15-10.07C588.8,266.12,581.38,273.44,574.27,280.45z M906.05,678.77
				c-0.73,0.59-1.45,1.17-2.18,1.76c1.05,1.51,1.93,3.19,3.2,4.49c3.53,3.62,7.21,7.09,10.84,10.61c1.42,1.38,2.61,3.28,4.31,4.04
				c8.01,3.56,15.99,7.35,24.33,9.92c4.21,1.29,7.32,3.06,10.34,6.13c6.94,7.04,21.6,7.47,28.37,0.85
				c-9.8-3.41-18.44-9.63-29.41-8.61c-2.21,0.21-4.74-0.35-6.77-1.31c-10.32-4.85-20.59-9.82-30.74-15.03
				c-2.43-1.25-4.51-3.38-6.42-5.42C909.78,683.9,907.99,681.26,906.05,678.77z M571.87,557.4c8.88-0.79,16.11-1.29,23.27-2.24
				c1.1-0.15,2.37-2.31,2.78-3.76c1.1-3.9,2.14-7.89,2.49-11.91c0.2-2.31-0.19-5.72-1.69-6.9c-3.1-2.45-12.4-0.56-14.54,2.86
				C579.96,542.22,576.33,549.36,571.87,557.4z M1103.64,624.11c-5.44,4.34-10.43,3.84-16.24,0.31c0,6.6-1.54,12.76,0.47,17.39
				c1.72,3.97,7.47,6.33,11.41,2.32c2.04,1.84,3.77,4.42,4.85,4.16c2.16-0.51,3.96-2.56,5.61-3.75c1.61,1.95,2.95,3.57,4.29,5.19
				c2.16-1.87,4.31-3.74,6.82-5.9c1.19-0.35,3.1-0.9,5-1.46c-2.22-1.39-4.48-1.4-5.77-2.52c-2.19-1.89-3.78-1.56-6.23-0.59
				c-1.67,0.66-5.63,0-5.77-0.67C1107.01,633.72,1098,631.7,1103.64,624.11z M1139.05,359.5c8.44,4.52,24.72,0.51,30.94-5.15
				c3.53-3.22,8-5.73,12.47-7.51c3.85-1.54,8.29-1.59,12.51-2.3c0-2.48,0-4.77,0-6.57c-7.4,1.21-15.24,1.09-21.85,3.88
				C1161.37,346.8,1150.36,353.52,1139.05,359.5z M1094.17,581.93c0.48,0.34,0.97,0.69,1.45,1.03c4.2-4.14,8.85-7.94,12.43-12.56
				c1.98-2.56,2.82-6.45,3.06-9.82c0.43-6.18,2.67-9.11,8.77-10.9c4.36-1.28,8.62-3.07,12.67-5.13c1.22-0.62,2.36-3.05,2.12-4.4
				c-0.19-1.05-2.47-1.98-3.99-2.5c-5.64-1.96-9.64,1.7-13,5c-5.77,5.68-10.78,12.13-16.17,18.2
				C1096.13,566.91,1094.68,574.25,1094.17,581.93z M707.75,435.54c1.48,2.08,2.94,3.41,3.46,5.04c0.63,1.96,0.58,4.18,0.62,6.29
				c0.03,1.46-0.29,2.93-0.46,4.39c1.67-0.11,3.58,0.27,4.95-0.42c3.84-1.95,7.55-4.2,11.1-6.63c1.39-0.95,2.47-2.58,3.27-4.12
				c0.95-1.83,1.46-3.89,1.97-5.33c-2.84-5.05-5.66-10.39-8.87-15.47c-0.56-0.88-3.1-1.36-4.22-0.87c-2.12,0.94-3.88,2.71-5.93,4.24
				c6.48,5.58,6.41,5.98-0.75,9.7C711.32,433.19,709.85,434.24,707.75,435.54z M826.84,420.72c-0.11-0.61-0.22-1.22-0.32-1.83
				c-5.13,0.52-11.45-0.67-15.15,1.91c-8,5.57-15.55,11.24-25.42,13.18c-2.96,0.58-3.4,3.01-1.02,5.67c2.28,2.55,3.91,5.66,6.03,8.37
				c1.01,1.29,2.48,2.2,3.75,3.28c1.07-1.52,2.17-3.02,3.19-4.58c1.36-2.07,2.44-4.36,4.04-6.22c1.14-1.33,3.38-1.77,4.37-3.16
				C811.64,429.91,818.38,424.29,826.84,420.72z M286.57,497.78c-0.62-0.26-1.23-0.52-1.85-0.78c-6.58,9.14-17.08,11.85-27.68,16.63
				c7.58,6.86,14.42,13.05,22.21,20.09C281.91,520.68,284.24,509.23,286.57,497.78z M638.23,370.25c-0.47-0.4-0.94-0.8-1.41-1.2
				c-12.57,8.2-25.13,16.39-38.9,25.37c9.39,0.47,17.67,1.28,25.92,1.04c2.13-0.06,4.54-3.15,6.14-5.34
				c1.52-2.07,2.18-4.77,3.17-7.22C634.86,378.68,636.54,374.46,638.23,370.25z M739.64,440.81c1.04,6.66-3.37,8.49-8.12,10.24
				c-5.56,2.05-11.04,4.32-17.31,6.8c1.31,1.66,2.45,3.36,3.91,4.72c0.21,0.2,2.47-1,2.86-1.94c1.84-4.42,5.65-5.72,9.74-6.8
				c9.43-2.49,18.8-5.27,28.3-7.5c4.71-1.11,5.75-5.28,7.61-8.44c0.52-0.89-3.39-6.24-4.65-6.02c-7.02,1.22-13.85,3.53-21.22,5.59
				c1.66,3.4,2.75,5.63,3.84,7.87c-0.35,0.22-0.71,0.44-1.06,0.66C742.23,444.26,740.94,442.54,739.64,440.81z M754.95,423.29
				c-1.17-5.32-2.32-10.6-3.47-15.8c-0.81-0.12-1.34-0.36-1.78-0.25c-2.89,0.75-5.74,1.67-8.65,2.37c-9.36,2.26-9.3,2.22-7.37,11.7
				c0.46,2.24,0.31,4.61,0.44,6.92c-0.5,0.01-1.01,0.03-1.51,0.04c-0.61-3.55-1.09-7.13-1.87-10.64c-0.95-4.29-2.23-4.56-5.83-1.47
				c3.28,6.08,3.92,14.03,11.85,16.54C739.15,422.86,748.84,427,754.95,423.29z M1190.81,497.71c0.69,0.17,1.38,0.33,2.08,0.5
				c0.75-2.33,1.99-4.63,2.15-7c0.28-3.96,0.96-8.16-2.29-11.56c-2.04-2.13-3.48-5.3-5.91-6.4c-1.32-0.6-4.16,2.18-5.96,3.22
				c-1.75-1.03-3.78-2.24-5.82-3.44c-0.5,2.89-1.01,5.78-1.54,8.83c-1.21,0.16-2.93,0.39-4.05,0.54c0.67,1.91,0.92,3.52,1.74,4.76
				c0.93,1.42,2.16,2.95,3.63,3.62c1.33,0.61,3.16,0.13,4.24,0.13c0.1,2.06,0.18,3.78,0.26,5.51c1.84-0.35,3.73-0.55,5.51-1.1
				c1.68-0.52,3.25-1.42,5.21-2.31C1190.36,494.89,1190.59,496.3,1190.81,497.71z M128.62,492.37c6.4,1.05,10.95,2.06,15.55,2.41
				c1.39,0.11,3.77-1.29,4.24-2.54c2.16-5.78,3.81-11.75,5.63-17.66c0.34-1.1,0.91-2.23,0.86-3.33c-0.22-5.26-0.61-10.52-0.99-16.6
				c-2.52,4.47-4.73,8.64-7.18,12.67c-1.63,2.68-3.56,5.17-5.41,7.71C137.32,480.53,133.29,486.01,128.62,492.37z M888.82,160.83
				c-4.4-0.97-8.1-2.43-11.8-2.42c-4.44,0.01-9.76,0-13.09,2.31c-4.72,3.26-8.03,8.6-11.81,13.15c-0.85,1.02-1.98,3.12-1.58,3.62
				c1.15,1.46,2.89,2.74,4.65,3.28c0.71,0.22,2.84-1.54,3-2.6c1.02-6.9,5.86-6.94,11.12-6.81c6.42,0.16,12.84,0.04,19.52,0.04
				C888.82,167.51,888.82,164.09,888.82,160.83z M341.82,407.29c0.04-0.51,0.08-1.02,0.12-1.53c-4.1,0-8.2,0-12.33,0
				c-1.41,9.15,0.18,14.44,5.6,15.84c-6.48,1.44-4.01,6.06-3.68,9.15c0.65,5.99,6.15-1.02,8.45,2.87c-1.01,0.45-1.91,1.04-2.89,1.24
				c-4.43,0.92-6.08,3.76-3.93,7.5c0.45,0.78,3.23,1.27,3.35,1.06c3.21-6.05,10.48-10.2,8.45-18.75c-1.09-4.61-1.32-9.42-1.99-14.5
				c-2.22-0.21-4.78-0.46-7.33-0.7c0.02-0.34,0.05-0.68,0.07-1.01C337.75,408.06,339.79,407.68,341.82,407.29z M1194.63,347.21
				c-9.8,0.39-18.61,2.09-25.48,9.74c-2.28,2.53-6.08,4.59-9.44,5.06c-7.81,1.1-15.77,1.08-23.67,1.59
				c-2.24,0.15-4.45,0.58-6.68,0.88c0.78,1.65,1.48,2.37,2.17,2.36c10.61-0.13,21.37,0.67,31.77-0.88c8.71-1.29,17-5.37,25.49-8.19
				C1195.79,355.45,1195.8,355.47,1194.63,347.21z M125.58,607.53c-0.4,0.46-0.8,0.92-1.2,1.39c1.13,1.33,2.08,2.88,3.42,3.94
				c15.38,12.14,30.83,24.18,46.22,36.31c2.45,1.93,4.28,1.26,5.85-0.97c2.34-3.33,1.38-5.62-2.06-7.7
				c-8.95-5.41-17.75-11.07-26.59-16.66C142.65,618.43,134.12,612.97,125.58,607.53z M1194.93,659.12
				c-9.01,9.79-12.52,20.31-12.3,32.57c0.21,11.24-0.27,11.25,11.27,10.12c0.28-0.03,0.53-0.34,1.02-0.67
				C1194.93,687.58,1194.93,673.88,1194.93,659.12z M224.81,432.25c2.39-0.44,4.12-1.02,5.84-0.99c1.69,0.03,4.55,0.24,4.87,1.11
				c2.56,7.08,8.52,6.49,14.08,6.62c4.83,0.11,9.71-0.58,14.5-0.19c6.04,0.5,12.02,1.78,18.03,2.64c2.93,0.42,4.68-0.43,4.78-3.94
				c0.1-3.48-1.05-4.78-4.57-4.56c-11.07,0.68-22.15,1.08-33.21,1.79c-3.8,0.24-6.23-0.47-6.11-4.93c0.03-1.01-0.4-2.38-1.12-3.02
				C237.34,422.7,227.3,425.4,224.81,432.25z M425.58,418.11c0.59,0,1.18-0.01,1.77-0.01c2.83-5.5,6.66-10.76,8.13-16.6
				c0.98-3.89-1.37-8.63-2.31-12.98c-1.66-7.73-5.94-16.35-9.05-17.23c-0.72,13.52-1.61,26.88-1.97,40.26
				C422.1,413.7,424.38,415.92,425.58,418.11z M613.55,174.4c4.07,4.23,9.1,3.1,13.9,3.42c4.57,0.31,9.42,0.35,13.6,1.95
				c6.52,2.49,13,2.32,19.59,1.69c3.05-0.29,6.1-0.87,7-5c0.26-1.19,3.09-2.22,4.89-2.58c2.22-0.44,4.59-0.11,6.9-0.11
				C657.42,171.24,635.47,170.97,613.55,174.4z M554.63,507.25c-1.84,9.84-3.48,19.94-5.69,29.91c-1.02,4.59-1.43,8.73,2.4,13.69
				c2.71-5.62,5.24-10.71,7.62-15.88c1.17-2.53,1.78-5.35,3.13-7.76c2.98-5.29,2.93-5.64-2.92-8.12c-0.89-0.38-1.62-1.16-2.42-1.76
				c0.15-0.32,0.3-0.65,0.46-0.97c2.42,0.8,4.84,1.6,7.41,2.45c0.51-2.41,0.97-4.61,1.43-6.81c-3.16-0.69-5.98-1.31-8.8-1.93
				c0.07-0.5,0.14-1,0.21-1.49c4.09-1.59,8.97,3.88,13.42-1.33C565.3,507.25,560.45,507.25,554.63,507.25z M478.79,476.77
				c-13.29,3.59-12.65,18-21.91,23.54c-1.44,7.2-2.88,14.39-4.55,22.76c2.63-1.29,4.22-2.02,5.76-2.84c5-2.69,6.49-7.72,8.38-12.53
				C470.38,497.73,474.38,487.81,478.79,476.77z M850.03,594.82c1.57-3.2,2.57-5.41,3.72-7.54c2.16-4,1.09-6.13-3.26-7.78
				c-4.92-1.86-9.85-4.15-14.09-7.21c-5.2-3.76-9.8-8.39-14.43-12.88c-3.79-3.67-7.15-7.77-10.87-11.51
				c-1.57-1.59-3.55-2.76-5.35-4.12c-0.27,0.35-0.55,0.69-0.82,1.04c1.07,1.64,2.1,3.32,3.23,4.92c6.38,9.05,11.74,19.09,22.95,23.44
				c1,0.39,1.77,1.48,2.52,2.36C838.91,581.7,844.15,587.88,850.03,594.82z M849.6,686.53c2.6,4.98,4.32,8.67,6.43,12.12
				c0.98,1.6,2.74,2.74,4.15,4.07c2.08,1.96,4.57,3.63,6.15,5.93c1.96,2.84,2.99,6.29,4.74,9.3c2.33,4.02,4.97,7.87,8.01,12.64
				c0.93-3.3,1.32-5.21,2-7c1.94-5.09,0.53-10.84-3.37-14.67c-3.83-3.75-8.55-7.18-10.86-11.76
				C863.25,690.04,856.93,689.41,849.6,686.53z M165.24,556.56c-0.37,0.48-0.74,0.96-1.1,1.44c0.68,0.86,1.32,1.76,2.05,2.59
				c0.76,0.88,1.88,1.56,2.33,2.56c0.63,1.39,1.46,3.24,1.02,4.47c-1.9,5.26-1.25,8.02,3.36,10.25c10.11,4.89,20.23,9.74,30.42,14.44
				c2.77,1.28,5.82,1.97,8.74,2.93c0.18-0.37,0.36-0.74,0.53-1.11c-3.8-2.57-7.85-4.85-11.34-7.78c-9.9-8.33-19.54-16.97-29.38-25.38
				C169.88,559.25,167.46,558.02,165.24,556.56z M973.49,416.68c4.02,2.55,3.74,2.41,1.82,6.22c-0.78,1.54-0.66,4.2,0.16,5.77
				c3.66,7.04,7.68,13.9,11.66,20.77c1.09,1.88,2.5,3.58,4.59,6.55c0.23-2.68,0.59-3.82,0.37-4.83c-2.95-13.7-5.95-27.39-9.09-41.05
				c-0.24-1.05-1.75-2.65-2.5-2.56c-1.28,0.15-2.78,1.2-3.54,2.31C975.6,411.87,974.72,414.2,973.49,416.68z M786.42,15.75
				c4.54,2.84,11.01,3.35,12.47-0.09c1.67-3.93,4.03-3.44,7-3.25c0.82,0.05,1.69-0.6,2.54-0.92c0.05-0.48,0.09-0.96,0.14-1.44
				c-1.66-0.37-3.32-1.05-4.98-1.06c-14.78-0.09-29.56-0.07-44.34-0.03c-1.46,0-3.4-0.11-4.26,0.72c-1.38,1.33-2.03,3.41-2.99,5.17
				c1.72,0.6,3.51,1.86,5.13,1.66c2.74-0.32,5.33-1.75,8.06-2.32c1.13-0.24,3.52,0.42,3.56,0.84c0.51,5.3,4.32,4.37,7.43,3.82
				C779.55,18.25,782.78,16.89,786.42,15.75z M301.71,282.68c-4.44,0.66-8.98-1.19-12.77,2.94c-1.62,1.76-4.01,3.96-6.07,3.97
				c-7.2,0.04-14.41-0.78-21.61-1.29c-5.27-0.37-10.54-1.08-15.79-0.94c-1.48,0.04-2.9,2.51-4.35,3.87c1.36,0.87,2.67,2.42,4.08,2.51
				c9.58,0.64,19.17,1.22,28.77,1.32c5.93,0.06,11.91-0.51,17.81-1.25C298.2,293,300.98,289.79,301.71,282.68z M797.62,390.16
				c6.44,7.75,12.76,15.33,18.93,22.75c2.02-5.02,3.8-9.35,5.5-13.72c0.27-0.7,0.43-1.63,0.21-2.31c-1.61-5.15-3.33-10.26-5.08-15.57
				C810.78,384.2,804.56,387.02,797.62,390.16z M1033.32,290.14c-0.95-0.38-2.87-1.16-4.95-2c-1.29,8.11-7.9-0.46-10.54,3.68
				c0.57,1.17,1.17,2.39,1.9,3.88c-3.47,0-6.48,0-9.49,0c0.48,2.63,0.96,5.26,1.51,8.31c-0.06,0.03-1.18,0.53-3.46,1.54
				c3.22,2.06,5.6,3.58,7.67,4.91c0.74,1.75,1.13,3.69,2.28,4.89c0.51,0.53,3.31-0.03,3.78-0.84c0.81-1.4,0.85-3.39,0.78-5.11
				c-0.05-1.23-0.71-2.48-1.27-3.64c-1.44-2.99-0.15-4.51,2.75-4.81c2.43-0.25,4.9-0.05,7.73-0.05c-0.92-7.54,5.3-3.66,7.41-5.27
				c0.62-2.63,1.63-4.76,1.41-6.76c-0.14-1.31-2.06-2.43-3.19-3.64C1036.47,286.56,1035.29,287.9,1033.32,290.14z M730.49,685.64
				c0.46,0.82,0.91,1.63,1.37,2.45c2.95-0.73,6.1-1.03,8.81-2.28c5.99-2.77,11.54-6.57,17.67-8.9c5.5-2.09,8.87-5.42,10.74-10.79
				c2.44-7,5.08-13.94,7.56-20.93c0.28-0.8,0.04-1.78,0.04-3.43c-4.72,2.97-7.91,6.03-7.48,11.4c0.51,6.23-2.33,10.66-8.25,12.79
				c-1.69,0.61-3.75,1.04-4.85,2.26c-4.5,4.99-10.38,7.46-16.29,10.21C736.34,680.05,733.57,683.19,730.49,685.64z M1097.6,549.4
				c0.49,0.2,0.98,0.41,1.48,0.61c5.43-5.32,10.9-10.59,16.27-15.98c3.99-4,3.07-5.45-1.78-7.97c-3.61-1.87-7.9-4-9.16-8.83
				c-0.28-1.06-1.16-1.96-1.76-2.93c-0.83,1.05-2.24,2.01-2.4,3.15c-1.03,7.39-1.89,14.82-2.58,22.25
				C1097.36,542.92,1097.6,546.17,1097.6,549.4z M1179.63,665.94c0.54,0.08,1.08,0.17,1.61,0.25c8.86-14.26,16.31-28.93,13.34-47.24
				c-2.3,4-3.54,8.16-6.32,10.58c-5.42,4.72-8.41,10.22-8.6,17.24C1179.49,653.15,1179.63,659.55,1179.63,665.94z M1022.58,479.3
				c-8.49-2.79-8.49-2.79-7.49-9.1c-5.29-0.41-10.5-0.81-16.06-1.23c-1.61,8.44-0.19,17.34,1.04,27.54
				C1008.29,490.22,1015.23,484.92,1022.58,479.3z M468.75,509.78c0.38,0.21,0.76,0.42,1.14,0.63c10.21-8.22,17.66-18.69,24.16-29.94
				c1.6-2.76,0.82-4.46-1.74-6.16c-5.82-3.87-8.32-3.55-11.21,2.89c-2.49,5.54-4.09,11.48-6.23,17.18
				C472.93,499.55,470.8,504.65,468.75,509.78z M501.9,517.77c-8.01-2.54-15.95-4.22-24.3-1.28c-1.73,0.61-4.08-0.86-6.15-0.85
				c-1.88,0-4.34,0.11-5.5,1.24c-2.18,2.12-3.61,5.02-5.48,7.76c5.22,3.19,10.78,4.2,16.01,1.23c3.38-1.92,6.29-1.89,9.86-1.51
				c4.83,0.52,9.75,0.13,14.46,0.13C501.24,521.82,501.54,519.98,501.9,517.77z M621.21,374.07c3.95-1.12,6.84-2.51,7.4-7.33
				c0.47-4.04,2.32-7.92,3.52-11.89c1.29-4.28,2.6-8.56,3.77-12.87c1.05-3.87-0.79-5.5-4.57-5.7c-5.71-0.31-10.07,4.04-10.11,10.4
				C621.17,355.72,621.21,364.75,621.21,374.07z M701.89,623.28c0.25-0.17,0.5-0.34,0.74-0.51c-0.48-1.4-0.95-2.8-1.88-5.54
				c4.05,1.05,6.93,1.79,10.08,2.61c-4.79-8.12-13.41-12.79-15.79-22.02c-0.18-0.71-1.5-1.11-2.24-1.7
				c-1.53-1.21-3.01-2.48-4.54-3.69c-1.04-0.82-2.29-2.35-3.17-2.18c-1.1,0.22-2.06,1.77-2.81,2.92c-0.97,1.5-1.68,3.18-3.1,5.96
				C686.43,606.84,694.16,615.06,701.89,623.28z M1147.21,177.66c-6.39-1.47-8.07,1.96-9.67,5.52c-3.25,7.24-6.33,14.57-9.86,21.68
				c-1.53,3.07-0.68,4.87,1.53,6.87c2.23,2.01,4.26,4.23,6.91,6.89C1139.71,205.36,1143.38,191.81,1147.21,177.66z M795.31,410.34
				c-3.44-2.51-6.16-4.34-8.71-6.4c-5.77-4.67-12.11-5.56-17.94-1.55c-3.93,2.7-1.81,6.82-0.27,9.79c1.63,3.16,2.4,9.01,7.75,7.23
				C782.48,417.31,788.33,413.73,795.31,410.34z M841.92,243.24c-0.62,0.03-1.24,0.05-1.86,0.08c-0.41-3.6-1.03-7.18-1.18-10.79
				c-0.15-3.67-2.29-3.77-5.06-3.62c-2.85,0.15-5.36,0.24-5.22,4.21c0.18,4.72,0.04,9.46,0.04,14.58c7.63,0,16.1,0,24.81,0
				c-1.27-6.55-2.31-11.87-3.4-17.5c-0.59,0.07-2.35,0.21-2.35,0.28c0.22,4.61,0.57,9.22,0.91,13.82c-0.4,0.11-0.8,0.22-1.2,0.33
				c-0.92-3.84-1.71-7.72-2.84-11.49c-0.36-1.22-1.61-2.17-2.45-3.25c-0.4,1.21-1.17,2.43-1.12,3.63
				C841.13,236.77,841.59,240,841.92,243.24z M581.07,491.39c-7.55-5.79-12.22-5.64-19.49,0.35c-1.72,1.42-3.89,2.29-5.91,3.44
				c0.16,3.19,0.33,6.58,0.5,9.9C573.03,507.22,575.66,505.75,581.07,491.39z M284.03,404.65c1.54-4.29-1.56-4.97-4.32-5.22
				c-3.75-0.34-7.82,0.77-11.27-0.3c-5.72-1.78-10.63-0.39-15.91,1.29c-3.18,1.01-6.84,1.33-10.16,0.93
				c-5.56-0.67-6.12-0.48-5.82,5.2c7.7-1.47,15.44-2.95,23.17-4.43c0.13,0.69,0.25,1.38,0.38,2.08c-4.46,0.79-8.97,1.37-13.37,2.42
				c-4.29,1.02-8.47,2.52-12.7,3.81c0.14,0.71,0.27,1.41,0.41,2.12c5.65-0.27,11.31-0.46,16.96-0.87c0.97-0.07,1.83-1.17,2.81-1.67
				c2.67-1.38,5.27-3.52,8.08-3.88C269.43,405.2,276.69,405.09,284.03,404.65z M99.67,636.19c4.78,5.68,9,11.28,13.87,16.25
				c2.8,2.85,6.36,5.49,10.08,6.79c7.43,2.61,15.21,4.25,22.78,6.27c-0.1-0.22-0.18-0.77-0.5-1.05
				c-8.72-7.68-19.97-12.06-27.65-21.14c-0.57-0.68-1.79-0.82-2.72-1.17C110.41,640.21,105.29,638.3,99.67,636.19z M706.13,519.33
				c3.38-1.01,6.73-1.18,8.93-2.87c2.84-2.17,4.77-5.55,7.09-8.41c-4.63-3.76,1.85-8.9-1.79-12.52c2.14,1.13,4.29,2.25,6.43,3.38
				c0.26-0.33,0.51-0.66,0.77-0.99c-1.88-4.7-3.77-9.4-5.65-14.11c-0.6,0.07-1.2,0.13-1.81,0.2c1.01,4.84-0.77,7.61-5.14,9.26
				c-1.2,0.45-1.5,3.25-2.24,4.96c-1.03,2.39-1.43,5.9-3.25,6.95c-4.39,2.53-6.83,4.97-4.13,10.1
				C705.85,516.25,705.8,517.52,706.13,519.33z M34.2,577.23c-5.32,3.3-7.48,2.73-11.51-2.54c-1.45-1.9-3.89-3.95-6.06-4.18
				c-3.33-0.34-6.82,0.84-10.28,1.37c0,7.77,0,15.27,0,22.59c3.24-2.48,6.09-5.3,9.49-7.12c4.34-2.33,9.2-3.66,13.64-5.82
				C31.3,580.64,32.63,578.7,34.2,577.23z M1005.11,691.52c-0.28,5.7-0.52,10.47-0.71,14.2c11.33,0.76,22.25,1.49,33.35,2.23
				c-0.29-1.59-0.7-3.79-1.1-5.99c-0.36,0.34-0.72,0.68-1.09,1.02c-3.9-3.38-7.8-6.76-11.64-10.09c-2.54,6.26-3.39,6.35-10.24,0.85
				C1010.11,698.47,1008.01,694.65,1005.11,691.52z M361.76,496.14c-2.32-0.85-4.88-1.94-7.53-2.74c-3.42-1.04-6.05-0.2-6.46,3.81
				c-1.01,9.81-1.89,19.64-2.83,29.46c0.4,0.32,0.81,0.63,1.21,0.95c2.5-2.55,5.99-4.67,7.31-7.73
				C356.71,512.34,358.96,504.37,361.76,496.14z M179.57,459.75c-0.49,0.26-0.98,0.51-1.47,0.77c0.26,5.24-2.25,11.55,2.88,15.21
				c6.46,4.62,9.36,10.3,9.5,17.95c0.07,4.03,2.65,5.88,6.48,6.06c2.25,0.1,4.51,0.02,7.14,0.02c-0.21-1.74,0.02-3.08-0.53-3.92
				c-3.41-5.24-6.96-10.4-10.55-15.52c-1.23-1.75-2.89-3.21-4.02-5C185.77,470.18,182.7,464.94,179.57,459.75z M306.99,196.03
				c1.25-2.19,2.09-3.45,2.73-4.82c1.74-3.71,3.78-6.03,8.38-3.58c0.88,0.47,3.28-1.1,4.38-2.29c0.44-0.47-0.57-2.57-1.25-3.73
				c-0.83-1.41-2.48-2.45-2.96-3.92c-1.99-6.19-6-7.41-11.9-5.9c-4.67,1.19-8.76,2.96-12.16,6.12c0.16,0.55,0.32,1.09,0.49,1.64
				c4.35-0.2,8.7-0.41,13.97-0.65c-1.21-1.65-1.78-2.24-2.13-2.95c-0.31-0.63-0.38-1.37-0.56-2.06c0.26-0.16,0.52-0.33,0.78-0.49
				c3.58,4.3,7.15,8.6,10.73,12.9c-5.57-6.74-12.76-6.37-21.97-3.31C299.42,187.42,302.57,191,306.99,196.03z M1164.12,623.14
				c2.3-0.34,5.05-0.17,5.31-0.9c0.57-1.6,0.13-3.72-0.35-5.51c-0.66-2.5-1.73-4.89-2.8-7.79c-6.17-0.36-9.99,4.34-14.53,7.43
				c-1.02,0.7-2.01,1.92-2.33,3.09c-0.9,3.19-1.43,6.48-2.27,10.47c0.38,0.43,1.53,1.72,3.03,3.42c1.32-2.03,2.41-3.72,3.74-5.76
				c1.01,1.79,1.8,3.19,3.08,5.47c1.41-2.67,2.35-4.45,3.28-6.22c1.27,0.43,2.35,0.79,3.71,1.25c0.06-1.34,0.12-2.15,0.14-2.96
				C1164.13,624.31,1164.12,623.5,1164.12,623.14z M696,456.85c0.44,0.22,0.87,0.43,1.31,0.65c-0.2,2.3-0.35,4.6-0.62,6.88
				c-0.11,0.96-0.86,1.96-0.68,2.81c1.38,6.52,2.91,13.01,4.62,20.46c2.77-2.21,6-4,8.06-6.68c1.64-2.13,3.1-5.72,2.43-8.04
				c-1.83-6.37-4.81-12.42-7.57-19.15C701.31,454.69,698.66,455.77,696,456.85z M243.48,508.74c-5.18-4.43-10.18-5.03-15.62-2.34
				c-4,1.97-8.29,3.38-12.19,5.5c-2.33,1.27-4.63,3.12-6.12,5.28c-1.97,2.85-3.13,6.26-4.85,9.87c2.65-1.45,5.76-2.43,7.87-4.46
				c4.78-4.6,10.02-6.34,16.66-5.67C235.58,517.56,240.07,513.85,243.48,508.74z M795.22,223.76c0,0.72,0,1.45,0.01,2.17
				c2.67,0.12,5.34,0.43,7.99,0.31c3.64-0.17,7.28-1.01,10.89-0.89c1.74,0.05,3.38,1.81,5.18,2.46c1.75,0.64,3.7,1.33,5.48,1.16
				c4.66-0.44,9.26-1.68,13.92-1.99c8.54-0.57,17.11-0.65,25.66-1.1c1.04-0.05,2.03-1.09,3.75-2.08c-1.7-0.91-2.5-1.71-3.31-1.71
				c-6.14-0.03-12.29,0.19-18.43,0.13c-5.55-0.05-11.09-0.42-16.64-0.5c-3.39-0.05-6.79,0.1-10.18,0.33
				c-3.18,0.21-6.35,0.73-9.53,0.95C805.09,223.33,800.15,223.51,795.22,223.76z M867.2,227.78c-3.1,0.36-6.52,0.97-9.96,1.11
				c-4.02,0.17-4.83,2.54-4.18,5.71c0.86,4.21,1.97,8.39,3.28,12.48c0.29,0.9,1.91,2.06,2.79,1.97c4.57-0.48,9.11-1.32,13.54-2.02
				C870.98,241.1,869.21,234.85,867.2,227.78z M878.83,733.97c-0.75-1.49-1.12-2.4-1.63-3.23c-3.88-6.34-7.44-12.92-11.8-18.92
				c-3.19-4.4-7.43-8.03-11.21-12.01c-0.64,0.57-1.29,1.15-1.93,1.72c2.06,2.45,3.66,5.89,6.29,7.1c3.37,1.55,3.71,3.33,3.69,6.49
				c-0.06,7.87,0.34,15.75,0.59,24.7C868.91,737.6,873.74,735.84,878.83,733.97z M301.15,388.57c0.4,2.29,0.78,4.21,1.06,6.14
				c0.69,4.78,2.95,6.98,8.08,5.55c2.35-0.66,4.93-0.5,7.4-0.75c8.66-0.85,8.7-1,7.95-9.7c-0.33-3.78-1.99-5.32-5.8-4.59
				C313.86,386.36,307.86,387.37,301.15,388.57z M5.8,470.05c0.29,0.41,0.57,0.82,0.86,1.23c9.52-3.86,19.59-6.84,25.71-16.25
				c2.83-4.35,6.01-8.18,11.03-10.3c2.46-1.04,4.51-3.07,7.9-5.47c-4.43,0.65-7.44,1.79-10.2,1.35c-4.83-0.76-7.2,1.93-9.98,4.97
				c-6.12,6.7-12.5,13.16-18.86,19.64c-1.02,1.04-2.51,1.6-3.71,2.48C7.58,468.4,6.72,469.26,5.8,470.05z M1180.74,621.05
				c0.55,0.27,1.11,0.53,1.66,0.8c1.53-2.4,3.05-4.79,4.75-7.45c1,1.05,2.03,2.14,2.9,3.05c7.14-4.12,4.95-10.38,4.83-16.84
				c-5.15,1.5-9.9,2.87-14.63,4.25c-1.69,0.49-3.54,1.72-5.03,1.37c-2.87-0.66-4.38,0.48-4.67,2.84c-0.26,2.13,0.44,4.39,0.72,6.59
				c1.78-0.61,3.56-1.22,5.33-1.85c0.59-0.21,1.16-0.45,1.78-0.69C1179.2,615.92,1179.97,618.48,1180.74,621.05z M1138.83,624.28
				c-7.98,5.42-7.93,5.32-14.7,1.58c-1.15-0.63-3-0.15-4.5,0.05c-3.79,0.48-7.57,1.07-11.34,1.67c-1.17,0.18-2.32,0.51-2.78,0.61
				c1.25,2.45,2.04,4.06,2.89,5.64c0.67,1.25,1.14,3.02,2.19,3.51c0.74,0.34,2.34-1.01,3.48-1.73c1.02-0.64,1.92-1.46,2.31-1.76
				c2.49,1.91,4.35,3.33,6.22,4.76c1.23-1.71,2.45-3.43,3.94-5.5c2.6,0.87,6.25,3.91,8.02-2.71c1.38,2.08,2.08,3.56,3.16,4.66
				c2.33,2.36,6.01,0.68,5.46-2.84C1142.75,629.49,1140.44,627.07,1138.83,624.28z M1016.99,383.81c-4.11-0.9-7.07-1.55-8.61-1.89
				c-1.79,2.98-3.08,4.84-4.09,6.85c-1.72,3.42-3.28,6.92-4.87,10.4c-0.11,0.24,0.06,0.66,0.21,0.94c1.59,3.06,11.73,5.25,14.27,2.92
				c2.34-2.15,4.38-4.62,6.94-7.36c-0.85-0.92-2.11-2.28-3.35-3.62c0.8-1,1.44-1.79,2.26-2.81c-2.3-0.81-4.18-1.46-6.43-2.25
				C1014.52,385.94,1015.22,385.34,1016.99,383.81z M1024.07,189.64c0.03-0.29,0.06-0.58,0.08-0.87c2.33,0,4.72,0.31,6.96-0.14
				c1.02-0.21,2.65-2.13,2.46-2.89c-1.07-4.37-4.64-6.54-8.96-5.41c-9.06,2.37-18,5.98-27.47,1.71c-0.56-0.25-1.47-0.32-1.96-0.03
				c-1.97,1.14-4.07,2.23-5.61,3.83c-0.5,0.51,0.31,3.52,0.93,3.67c2,0.49,4.22,0.39,6.31,0.11c3.37-0.44,6.71-1.78,10.04-1.7
				C1012.61,188.07,1018.33,189.02,1024.07,189.64z M989.04,689.86c-2.98,2.69-5.89,5.32-9.06,8.2c1.6,2.72,3.36,5.7,5.09,8.69
				c1.52,2.63,2.81,7.31,4.53,7.46c2.71,0.24,5.7-2.52,8.54-4.06c4.17-2.26,4.46-3.85,1.36-7.64
				C996.07,698.31,992.58,694.14,989.04,689.86z M983.97,498c1.5,2.63,4.27,3.23,6.18,0.65c2.23-3,1.7-10.63-2.37-13.6
				c-2.33-1.71-8.85-1.9-9.82-0.4c-2.12,3.28-4.77,1.95-7.05,2.83c-1.69,0.65-3.52,1.11-5.02,2.07c-0.94,0.6-1.4,1.95-2.07,2.97
				c1.13,0.49,2.28,1.4,3.4,1.38c2.82-0.07,5.64-0.56,8.11-0.85c0,2.57,0,4.54,0,6.52C977.93,499.1,980.56,498.62,983.97,498z
				M1107,510.68c0.17,2.85,0.33,5.51,0.53,8.91c3.72-2.24,6.7-4.03,8.65-5.21c-1.52-7.34-0.06-12.93,0.93-16.45
				c-2.85-3.87-5.13-6.95-7.53-10.21c-2.63,7.88-5.09,15.24-7.66,22.95C1103.99,510.68,1105.35,510.68,1107,510.68z M360.19,457.4
				c0.23,1.58,0.36,2.65,0.55,3.71c0.98,5.18,3.02,10.42,2.72,15.53c-0.44,7.52,3.12,12.82,7.52,17.77c1.14,1.29,5.11,1.69,6.46,0.7
				c1.34-0.99,2.12-4.84,1.26-6.4C372.92,478.3,366.63,468.16,360.19,457.4z M480.66,609.43c0.33-3.02,0.38-5.57,0.97-7.99
				c0.3-1.24,1.57-2.24,2.41-3.35c0.89,0.96,2.1,1.78,2.61,2.92c0.75,1.68,0.99,3.58,1.45,5.39c0.45-0.08,0.89-0.15,1.34-0.23
				c0.33-4.97,1.32-10.25-2.1-14.59c-5.5-6.97-5.66-14.47-4-22.66c0.6-2.96-0.3-6.23-0.51-9.35c-2.32,8.71-4.11,17.07-5.41,25.51
				c-0.75,4.87-1.24,9.91-0.84,14.79C476.8,602.84,478.96,605.64,480.66,609.43z M774.51,422.3c1.34,2.01,2.54,2.97,2.47,3.85
				c-0.37,5.19,2.62,4.02,5.35,2.88c5.78-2.42,11.63-4.75,17.15-7.69c2.36-1.26,3.95-3.95,5.9-5.98c-0.22-0.68-0.45-1.36-0.67-2.04
				c-2.85-0.14-5.77-0.72-8.54-0.3c-2.33,0.35-4.49,1.86-6.74,2.83C784.63,417.92,779.83,420,774.51,422.3z M1111.58,316.09
				c-0.2,1.69-0.78,3.5-0.48,5.15c0.22,1.21,1.6,2.2,2.47,3.3c0.82-0.9,1.93-1.68,2.39-2.74c1.06-2.42,2.74-5.08,2.47-7.45
				c-0.5-4.43-2.02-8.81-3.66-12.99c-0.22-0.55-3.69,0.05-5.63,0.32c-6.14,0.86-8.25,4.41-6.78,11.98c1.23,6.32,5.4,2.57,8.54,2.23
				C1111.06,315.88,1111.22,315.98,1111.58,316.09z M1090.47,317.23c5.62,6.68,8.89,15.15,17.81,18.52c2.08,0.79,4.71,1.36,5-1.8
				c0.24-2.62,3.77-5.7-0.95-8.13c-2.24-1.15-3.81-3.6-5.32-5.1c-3.21-2.52-6.04-4.76-9.04-7.11
				C1096.31,314.41,1094,315.53,1090.47,317.23z M393.67,266.63c-7.51-3.1-12.83-8.16-16.13-15.44c-0.29-0.65-2.49-1.17-3.24-0.75
				c-4.96,2.78-9.77,5.84-15.18,9.14C370.75,262.33,380.91,269.67,393.67,266.63z M660.3,399.44c6.73,3.72,11.71,3.41,15-1.33
				c4.51-6.51,8.27-13.53,12.39-20.3c2-3.29,0.6-4.8-3.22-5.74C678.5,382.77,671.66,392.88,660.3,399.44z M1178.5,470.76
				c0.13,0.76,0.26,1.53,0.4,2.29c6.84-5.68,11.21-1.67,15.88,3.38c0-10.63,0-20.08,0-29.54c-2.33,6.5-6.94,8.85-12.49,8.13
				c0.29,2.67,1.3,5.4,0.67,7.68C1182.17,465.58,1180.05,468.09,1178.5,470.76z M959.6,721.46c-8.13-10.41-20.3-11.85-31.76-17.73
				C938.74,726.2,942.23,722.41,959.6,721.46z M300.68,422.7c0.65,0.21,1.3,0.42,1.96,0.63c0.81-3.44,1.98-6.84,2.35-10.33
				c0.42-3.93-0.43-7.32-5.84-5.49c-0.57,0.19-1.29-0.06-1.94-0.13c-10.26-1.06-10.27-1.06-10.27,9.32c0,1.16-0.08,2.36,0.14,3.49
				c0.14,0.73,0.69,1.93,1.14,1.97c3.27,0.28,6.56,0.31,9.84,0.42c0.07-2.17,0.15-4.35,0.2-6.52c0.03-1.15,0-2.31,0-3.46
				c0.24-0.01,0.49-0.02,0.73-0.03C299.55,415.95,300.11,419.33,300.68,422.7z M600.39,293.18c0.49,0.63,0.97,1.26,1.46,1.89
				c5.22-1.52,10.45-3.03,15.67-4.55c-1.08-4.4-2.16-8.79-3.25-13.18c-0.08-0.32-0.21-0.83-0.41-0.88c-2.43-0.59-4.87-1.32-7.34-1.52
				c-0.89-0.07-1.89,1.19-3.69,2.44c3.01,0.4,4.65,0.61,6.3,0.83c0.02,0.36,0.04,0.73,0.05,1.09c-1.96,0.39-3.91,0.78-6.6,1.31
				C601.9,284.54,601.15,288.86,600.39,293.18z M462.83,608.2c-8.85,2.12-18.06-2.94-26.67,1.87c2.43,7.3,5.28,10.62,9.18,10.89
				C451.73,621.4,460.66,615.03,462.83,608.2z M231.28,602.9c4.11,1.81,8.07,5.27,11.26,4.66c13.72-2.63,25.25-10.5,37.55-17.66
				C263.93,594.21,248.26,598.38,231.28,602.9z M692.92,486.64c-1.08-2.88-1.83-4.6-2.38-6.38c-1.05-3.43-3-3.79-6.06-2.33
				c-2.66,1.27-3.88,3-3.26,5.88c0.73,3.38,1.54,6.74,2.47,10.84c4.87-2.36,11.43-3.43,13.51-7.07c2.64-4.62,0.89-11.18-2.11-16.47
				C686.93,474.98,693.63,480.69,692.92,486.64z M778.4,167.47c5.26,0,9.11,0.02,12.96-0.01c4.3-0.03,8.59-0.11,12.89-0.15
				c2.27-0.02,4.61,0.33,6.79-0.1c5.08-1,5.95-0.53,6.37,6.01c5.93-3.12,11.28-5.94,16.63-8.76
				C819.71,161.27,787.06,163.48,778.4,167.47z M558.45,250.46c5.58,4.49,31.89,7.71,35.96,5.51c-1.03-2.48-2.03-4.89-3.29-7.92
				C581.22,249.34,570.04,246.19,558.45,250.46z M887.34,477.31c0.58-2.53,1.28-5.2,1.77-7.9c0.54-2.98,0.19-5.62-3.25-6.85
				c-1.05-0.38-2.27-1.04-2.83-1.94c-4.54-7.35-11.66-3.1-17.4-4.95c-0.25,0.5-0.51,0.99-0.76,1.49
				C872.32,463.83,879.75,470.5,887.34,477.31z M345.35,741.81c-8.83-2.14-17.23-1.23-24.24,6.36c-3.39,3.67-4.36,7.66-3.49,12.6
				c3.34-4.85,7.54-7.39,13.3-7.56c1.41-0.04,2.88-1.54,4.15-2.59C338.55,747.73,341.94,744.74,345.35,741.81z M646.33,489.03
				c-11.45-2.06-16,1.33-17.38,13.36c5.56,1.1,11.09,2.19,16.06,3.17C645.47,499.84,645.88,494.7,646.33,489.03z M574.99,533.12
				c-0.45-0.7-0.89-1.4-1.34-2.1c-2.74,0-5.51-0.22-8.22,0.1c-1.21,0.15-3.12,1.24-3.29,2.17c-1.14,6.27-4.14,11.46-8.28,16.15
				c-0.67,0.75-0.75,2.02-1.27,3.55c2.42,0.59,4.43,1.08,6.72,1.64C564.62,547.33,569.8,540.22,574.99,533.12z M1019.91,454.9
				c-3.58-1.95-6.24-5.58-11.57-4.45c1.52,2.14,2.51,3.55,4.13,5.82c-2.37,0.21-4.05,0.36-4.43,0.39c-1.5-2.53-2.07-4.42-3.35-5.3
				c-0.85-0.59-3.22-0.2-3.95,0.62c-0.77,0.85-0.39,2.74-0.52,4.31c1.91,0,3.35,0,4.87,0c0.17,1.42,0.31,2.66,0.48,4.09
				c-4.17,0.3-8.24-0.02-10.52,3.97c2.79,3.5,8.99-4.37,9.7,3.73c2.04-0.54,3.78-1.39,5.51-1.37c3.65,0.05,4.85-2.28,4.76-5.06
				C1014.87,458.07,1016.02,455.94,1019.91,454.9z M286.73,578.89c-7.03-4.18-11.78-3.93-15.03-0.29c-2.64,2.97-5.83,5.7-9.29,7.63
				c-7.39,4.12-15.11,7.65-22.69,11.42c9.45-1.61,18.93-3.11,27.41-7.89c6.29-3.55,10.99-10.09,19.14-10.16
				C286.36,579.59,286.43,579.36,286.73,578.89z M878.7,454.49c0.5-0.46,1.01-0.93,1.51-1.39c-3.6-5.39-3.84-10.9-1.43-16.68
				c0.53-1.28,0.31-2.99,0.08-4.45c-1.13-7.33-2.37-14.65-3.63-21.96c-0.05-0.32-0.65-0.54-1.33-1.06c-0.56,1.46-1.31,2.62-1.42,3.84
				c-1.14,12.89-0.7,25.73,2.22,38.37C875.02,452.5,877.32,453.39,878.7,454.49z M444.9,196.28c0.03,0.53,0.06,1.07,0.1,1.6
				c1.98,0,3.97-0.09,5.94,0.02c7.26,0.38,14.73-0.13,21.71,1.48c9.61,2.22,16.64-2.36,24.08-6.58c0.2-0.11,0.03-0.89,0.03-1.8
				c-2.96,0.22-5.85-0.01-8.49,0.7c-9.04,2.41-17.96,3.78-27.48,2.46C455.68,193.45,450.2,195.48,444.9,196.28z M341.94,390.65
				c-0.61-3.16,1.79-8.29-5.31-7.75c-7.4,0.56-8.76,1.27-8.6,6.41c0.31,9.98,0.39,10.09,7.79,9.91
				C341.68,399.09,342.14,398.46,341.94,390.65z M529.03,234.26c-0.12-0.74-0.24-1.48-0.36-2.22c-15.1,1.46-30.64-3.18-45.42,4.49
				c2.25,1.26,4.59,2.3,6.88,2.2c9.24-0.44,18.48-1.07,27.69-1.98C521.6,236.38,525.3,235.12,529.03,234.26z M871.53,697.97
				c1.39,1.27,2.33,1.95,3.05,2.81c5.76,6.97,11.41,14.02,17.24,20.93c1.45,1.73,3.94,2.73,5,4.61c2.49,4.42,5.15,3.49,8.37,1.21
				c-2.13-2.56-4.98-4.63-5.88-7.35c-1.97-5.95-6.59-7.85-11.66-9.76c-1.81-0.69-3.28-2.28-4.91-3.45
				C878.94,704.27,877.77,698.25,871.53,697.97z M598.18,691.39c0.89-0.65,1.79-1.3,2.68-1.95c-5.12-7.36-10.05-14.86-15.5-21.96
				c-1.1-1.44-4.18-1.28-6.26-2.08c-1.8-0.69-3.73-1.4-5.16-2.62c-3.68-3.15-7.12-6.59-10.66-9.92c-0.36,0.41-0.71,0.81-1.07,1.22
				c2.34,5.79,4.46,12.18,12.2,12.35c6.91,0.16,9.46,4.75,12.52,9.66C590.29,681.44,594.41,686.31,598.18,691.39z M703.73,267.53
				c-7.66-2.82-11.83,2.25-10.17,9.34c1.39,5.96,1.02,11.37-1.22,16.99c-0.92,2.32-0.65,5.11-0.92,7.68c0.59,0.1,1.18,0.2,1.77,0.3
				C702.56,292.36,697.44,278.33,703.73,267.53z M1091.57,609.21c1.16-2.36,1.75-5.19,3.49-6.68c2.03-1.73,5.06-2.28,7.64-3.39
				c0.23-0.1,0.24-0.65,0.47-0.84c3.16-2.51,6.01-5.84,9.61-7.25c3.62-1.41,3.68-2.95,2.86-5.71
				C1102.5,583.5,1088.22,597.71,1091.57,609.21z M630.79,367.93c1.85-0.81,2.86-1.29,3.89-1.7c7.06-2.8,7.92-9.42,9.86-15.54
				c2.49-7.83,2.42-8.27-5.79-10.59C636.2,349.04,633.65,357.97,630.79,367.93z M505.83,479.59c-5.32,5.54-10.49,10.7-15.4,16.09
				c-4.88,5.36-9.49,10.96-14.5,16.78c3.95,0.35,8.52,0.53,11.1-2.99c6.84-9.28,13.03-19.03,19.47-28.61
				C506.55,480.81,506.38,480.61,505.83,479.59z M1038.29,175.59c7.15,5.83,14.85,5.65,23.24,4.02c7.92-1.54,16.12-1.69,24.45-2.47
				c-1.89,3.87,0.8,4.67,3.95,5.43c-0.4-7.96-0.87-7.91-7.9-7.21c-6.72,0.68-13.56,0.21-20.35,0.22
				C1053.94,175.6,1046.21,175.59,1038.29,175.59z M411.69,700.22c0.28,0.45,0.57,0.9,0.85,1.35c1.57-0.69,3.43-1.05,4.66-2.13
				c2.4-2.12,4.12-5.14,6.73-6.89c4.84-3.25,10.12-5.85,15.4-8.83c-0.97-1.99-2.02-4.14-3.11-6.36
				C424.31,681.45,417.71,690.54,411.69,700.22z M452.29,333.38c3.87-1.89,4-6.08,3.52-9.79c-0.53-4.09-4.21-1.76-6.55-2.07
				c-0.64-0.08-1.5-0.13-1.95,0.21c-1.63,1.24-3.61,2.41-4.46,4.1c-0.35,0.7,1.82,2.66,2.85,4.07c-0.37,0.17-1.65,0.78-3.41,1.61
				c1.46,1.15,2.55,2.01,3.01,2.38c-0.99,1.44-2.07,2.55-2.46,3.86c-0.12,0.4,1.79,2.04,2.63,1.94c2.79-0.32,5.52-1.18,8.27-1.84
				C453.3,336.53,452.87,335.2,452.29,333.38z M438.64,196.76c-0.34-0.61-0.68-1.23-1.02-1.84c-9.58,0-19.16-0.11-28.73,0.16
				c-1.03,0.03-2.87,2.33-2.77,3.45c0.12,1.4,1.87,3.8,2.89,3.79c7.62-0.1,15.28-0.37,22.84-1.31
				C434.25,200.71,436.38,198.24,438.64,196.76z M818.22,232.07c-2.26,5.83-4.33,11.16-6.46,16.67c3.93-0.29,8.37,4.72,12.17-1.67
				c3.04-5.11,3.88-10,1.66-15.42C822.93,231.81,820.49,231.94,818.22,232.07z M1171.12,665.16c0-10.79,0-20.26,0-30.21
				C1160.98,644.11,1160.63,656.5,1171.12,665.16z M220.98,438.69c13.18,2.67,24.63,5.03,36.12,7.26c1.78,0.35,3.69,0.06,5.54,0.07
				c0-1.13,0.45-2.91-0.09-3.28c-1.49-1.05-3.36-1.98-5.14-2.12c-4.3-0.34-8.7,0.22-12.94-0.39c-3.12-0.45-7.88-1.43-8.73-3.48
				c-2.09-5.1-5.41-3.84-8.48-2.82C225.04,434.68,223.3,436.87,220.98,438.69z M1014.5,175.98c8-0.1,7.98-0.11,6.67-7.63
				c-0.31-1.79-0.5-3.66-0.39-5.47c0.25-3.97-1.96-4.47-5.07-3.9c-5.41,0.98-6.7,3.57-6.51,11.42
				C1009.31,174.46,1010.26,176.98,1014.5,175.98z M387.41,294.25c5.96,3.55,11.13,3,16.42,2.53c6.22-0.56,12.48-0.74,18.73-0.86
				c5.98-0.11,11.96,0.17,17.94,0.06c2.87-0.05,4.97-1.33,4.79-4.94C426.24,292.1,407.48,293.13,387.41,294.25z M484.88,434.66
				c4.78-0.13,6.51-1.37,5.38-6.11c-2.2-9.29-3.67-18.76-5.54-28.14c-0.31-1.58-1.09-3.07-1.65-4.6c-0.65,0.14-1.3,0.27-1.95,0.41
				C478.52,409.32,484.38,421.69,484.88,434.66z M782.94,186.41c-0.53-5.25-0.95-8.8-1.24-12.36c-0.37-4.64-3.93-5.92-6.95-4.42
				c-3.03,1.5-5.26,4.92-7.29,7.88c-0.48,0.7,0.85,3.73,1.99,4.25C773.24,183.48,777.34,184.55,782.94,186.41z M809.68,413.55
				c0.38-0.47,0.77-0.95,1.15-1.42c-5.19-6.44-10.28-12.96-15.6-19.3c-3.24-3.87-5.63-0.24-7.86,1.52c-2.41,1.91-1.18,3.84,0.89,5.2
				C795.38,404.25,802.53,408.89,809.68,413.55z M597.88,564.5c0.18-0.46,0.35-0.93,0.53-1.39c1.83-0.16,3.88-0.95,5.44-0.38
				c4.73,1.74,8.11-0.29,12.61-3.48c-6.36-1.89-11.34-5.52-17.09-1.13c-0.49,0.38-1.4,0.28-2.11,0.28
				c-5.56,0.02-11.12,0.01-17.12,0.01c0.68,3.05,1.03,4.64,1.43,6.42c1.81-1.53,3.27-2.77,4.06-3.44c2.29,0.89,4.65,2.34,5.1,1.89
				C593.83,560.17,595.72,562.79,597.88,564.5z M1086.35,541.56c0.47,0.04,0.94,0.09,1.41,0.13c2.32-11.08,5.11-22.11,3.43-34.04
				c-1.84,0.4-3.09,0.78-4.36,0.92c-4.99,0.54-5.61,2.55-3.83,7.3c1.94,5.18,2.49,10.92,3.27,16.47
				C1086.7,535.35,1086.35,538.48,1086.35,541.56z M342.27,492.32c-4.79-1.19-7.57,0.73-9.09,4.59c-2.76,7.03-5.37,14.12-8.05,21.2
				C336.75,513.24,339.76,503.05,342.27,492.32z M652.22,521.68c0,0.91,0.03,1.88,0,2.86c-0.39,11.71-0.86,23.42-1.09,35.14
				c-0.03,1.39,1.27,2.8,1.95,4.2c1.36-2.92,1.82-5.17,1.97-7.43c0.28-4.2,0.31-8.41,0.59-12.61c0.16-2.43,0.53-4.85,0.93-7.25
				c0.52-3.09,1.45-6.13,1.7-9.23C658.58,523.45,657.05,520.89,652.22,521.68z M716.01,227.57c4.53,0.66,9.89,1.36,15.22,2.27
				c3.09,0.53,6.1,1.68,9.2,2.04c3.15,0.36,6.42,0.48,9.54,0.01c3.84-0.58,7.55-1.99,11.37-2.84c2.07-0.46,4.24-0.47,7.67-0.81
				C764.06,225.82,718.37,226.29,716.01,227.57z M823.68,391.95c0.71-0.04,1.43-0.08,2.14-0.12c0.42-2.29,1.53-4.71,1.15-6.86
				c-1.48-8.28-3.39-16.48-5.24-24.69c-0.32-1.43-1.08-2.76-1.45-3.67c-1.85,2.36-3.41,4.35-5.26,6.71c3.01,1.73,7.51,3.29,2.72,7.73
				c-0.32,0.3,0.07,1.49,0.28,2.22c0.75,2.69,1.55,5.37,2.36,8.04C821.47,384.86,822.59,388.4,823.68,391.95z M410.64,395.5
				c0.59-0.14,1.19-0.28,1.78-0.43c3.47-8.14,3.65-16.62,3.42-25.01c-5.99-1.21-8.17,0.56-7.67,5.79c0.35,3.63-0.24,7.35,0.16,10.96
				C408.66,389.76,409.84,392.61,410.64,395.5z M383.9,164.43c-9.52-2.74-18.7-3.62-28.76-2.2c6.04,8.03,10.09,9.22,18.01,6.29
				c1.6-0.59,3.79-1.11,5.16-0.48C381.83,169.66,382.58,167.58,383.9,164.43z M717.42,489.38c0.4-2.99,0.73-5.44,1.15-8.49
				c-1.28-1.02-3-2.39-4.11-3.28c-2.05,4.11-3.86,7.75-5.66,11.4c-0.22,0.44-0.25,0.97-0.49,1.39c-1.53,2.74-2.71,5.79-4.74,8.1
				c-2.29,2.6-3.1,4.85-0.95,8.22c4.36-2.12,8.41-3.79,7.87-9.92c-0.15-1.7,1.79-3.83,3.21-5.35
				C714.75,490.32,716.52,489.86,717.42,489.38z M287.33,400.3c4.94,0.31,9.65,0.62,14.86,0.95c-1.41-4.83-2.55-8.72-3.54-12.11
				c-4.38-0.47-8.38-0.91-13.12-1.42C286.19,392.36,286.7,395.9,287.33,400.3z M692.19,274.54c-0.87-0.05-1.73-0.1-2.6-0.14
				c-0.02,8.15-8.16,13.48-6.82,22.29c0.31,2.01-0.76,3.88,2.27,4.12c2.51,0.2,4.6,0.14,4.82-3.27c0.14-2.1,1.24-4.12,1.41-6.22
				C691.73,285.73,691.91,280.13,692.19,274.54z M453.83,503.23c-0.64-0.2-1.28-0.4-1.92-0.59c-3.13,8.38-12.52,12.87-13.54,22.32
				c8.74,0.64,12.81-3.89,13.81-11.89c0.14-1.12,0.93-2.16,1.09-3.28C453.58,507.62,453.66,505.42,453.83,503.23z M424.25,331.71
				c4.2-0.41,7.62-0.74,11.04-1.07c0.04,0.2,0.08,0.4,0.12,0.61c-1.33,0.62-2.65,1.24-4.82,2.25c4.03,0,7.01,0,9.38,0
				c0.47-2.39,1.15-4.15,1.04-5.86c-0.09-1.4-1.02-3.82-1.79-3.91c-5.19-0.59-10.44-0.67-16.44-0.95
				C423.35,326.22,423.76,328.74,424.25,331.71z M1013.38,413.41c2.84-4.77-0.2-5.35-3.03-4.87c-2.93,0.49-6.05,1.54-8.36,3.3
				c-1.28,0.97-1.29,3.89-1.41,5.95c-0.03,0.58,1.55,1.71,2.47,1.8c4.06,0.39,8.15,0.48,12.02,0.67c1.04-1.73,2.13-3.56,3.38-5.65
				C1016.95,414.26,1015.58,413.93,1013.38,413.41z M562.42,553.96c7.16,3.23,8.12,2.74,11.04-3.25c2.65-5.44,5.75-10.65,8.63-15.98
				c0.37-0.68,0.59-1.45,0.87-2.18c-4.49-1.47-6.68,0.06-8.68,4.12c-2.36,4.79-6.06,8.91-9.17,13.33
				C564.28,551.18,563.49,552.38,562.42,553.96z M905.75,164.13c-0.83,6.44-0.49,6.84,5.38,6.28c4.23-0.4,8.78-2.67,11.63,3.4
				c-0.65-4.59-1.03-8.16-1.71-11.68c-0.29-1.53-1.36-4.25-1.79-4.2c-4.64,0.59-9.23,1.62-13.82,2.57c-0.02,0,0.09,0.61,0.19,1.31
				c4.26,0,8.59,0,12.92,0c0.01,0.54,0.03,1.09,0.04,1.63C914.28,163.68,909.97,163.91,905.75,164.13z M998.15,235.83
				c-6.94-5.64-13.02-10.65-19.21-15.52c-0.69-0.54-2.5-0.69-2.96-0.21c-0.63,0.65-0.79,2.15-0.56,3.15
				C977.12,230.6,988.78,237.53,998.15,235.83z M1072.51,245.42c-1.9-0.52-3.9-1.59-5.82-1.46c-2.19,0.14-4.26,1.59-6.47,2.04
				c-3.25,0.66-6.63,0.71-9.83,1.52c-1.02,0.26-1.63,2.12-2.83,3.82c8.87-0.62,8.83-0.52,19.57,3.76c0.84,0.34,3.19-1.67,4.01-3.08
				C1072.02,250.49,1071.95,248.38,1072.51,245.42z M680.88,717.35c0,3.8,0,6.7,0,9.96c2.18-0.4,4.33-0.79,6.7-1.23
				c-0.5-1.89-0.9-3.43-1.46-5.53c8.84,5.38,8.84,5.38,11.89,2.81c-2.13-3.21-3.67-8.09-6.66-9.27
				C688.7,713.05,684.43,716.11,680.88,717.35z M345.87,492.48c-7.65,14.19-9.15,25.11-5.94,39.86c0.77-0.41,2-0.71,2.05-1.14
				C343.3,518.77,344.51,506.33,345.87,492.48z M630.45,396.44c0.16,0.24,0.33,0.49,0.49,0.73c2.23-2.94,4.95-5.64,6.59-8.88
				c3.88-7.67,7.25-15.6,10.81-23.44c0.49-1.08,0.87-2.2,1.59-4.04c-5.73,2.17-9.21,4.78-10.76,10.35
				C636.8,379.74,633.41,388.03,630.45,396.44z M210.81,414.91c-3.62,13.34,2.24,25.45,4.99,38
				C219.44,439.59,211.94,427.68,210.81,414.91z M263.87,264.73c-0.05-0.41-0.1-0.82-0.14-1.23c-12.23,0.63-24.81-2.88-36.65,3.67
				c6.7,3.52,13.21,4.64,19.97,0.52c1.59-0.97,3.83-0.9,5.79-1.22C256.52,265.86,260.2,265.31,263.87,264.73z M161.14,501.75
				c-2.89-0.26-5.76-0.61-8.63-0.71c-1.25-0.04-3.02,0.01-3.7,0.78c-4.16,4.66-8.07,9.54-12.45,14.81
				C143.18,516.42,148.17,513.32,161.14,501.75z M775.48,638.69c-6.18-2.21-12.5-4.42-18.78-6.71c-6.21-2.26-12.38-4.61-18.57-6.91
				c1.86,6.08,5.17,9.17,11.07,8.68c1.82-0.15,3.83,0.62,5.6,1.33C761.6,637.79,768.4,640.3,775.48,638.69z M662.14,700.54
				c-3.65-0.56-7.67-1.17-11.7-1.79c-0.26,0.37-0.53,0.74-0.79,1.11c6.41,4.82-2.61,4.75-1.74,7.83c6.99,4.3,12.49,3.81,17.01-1.3
				C664.02,704.48,663.17,702.69,662.14,700.54z M1095.61,426.35c-0.94-2.86-1.58-4.87-2.26-6.86c-1.81-5.27-3.54-10.57-5.51-15.78
				c-1.18-3.13-3.63-2.08-5.21-0.65c-0.95,0.86-1.69,3.41-1.12,4.21c1.84,2.63,4.12,5.07,6.63,7.1c3.57,2.89,3.74,5.56,0.56,8.73
				c-0.73,0.73-1.14,1.78-2.3,3.65C1090.03,426.6,1092.65,426.48,1095.61,426.35z M364.2,544.19c3.7,6.71,6.91,12.36,9.94,18.09
				c2.47,4.68,12.15,9.77,17.87,9.12c-4.06-3.21-8.28-6.13-11.97-9.61c-2.74-2.58-5.74-5.57-6.93-8.97
				C371.61,548.61,369.24,546.27,364.2,544.19z M1013.52,435.34c-2.08-0.13-4.54-0.43-4.57-0.26c-0.56,2.9-3.87,1.74-4.47,2.65
				c-1.8,2.75-2.77,6.11-3.58,9.34c-0.09,0.38,3,2.4,4.28,2.12c3.48-0.76,6.79-2.34,11.45-4.08
				C1011.78,442.55,1010.75,439.65,1013.52,435.34z M1079.06,342.64c8.32,0.52,17.08,1.07,26.9,1.68
				c-6.09-6.99-12.54-9.24-20.54-8.68C1086.92,341.65,1081.1,340.99,1079.06,342.64z M433.2,575.5c0,5.73,0,10.83,0,15.94
				c0,0.17-0.09,0.4-0.01,0.49c0.95,1.09,1.94,3.06,2.9,3.05c1.68-0.01,4.26-0.81,4.85-2.04C443.33,587.91,439.28,578.68,433.2,575.5
				z M602.85,770.4c-10.08,1.23-15.06,10.09-22.91,14.18c-0.13,0.07-0.02,0.6-0.02,0.92C589.16,786.6,598.25,780.76,602.85,770.4z
				M614.58,352.67c-0.34,0.05-0.69,0.1-1.03,0.14c-0.73,4.9-1.43,9.8-2.2,14.69c-0.79,5-1.65,9.99-2.57,15.48
				c4.22-2.97,9.9-4.13,8.78-11.12c-0.93-5.82-1.58-11.69-2.38-17.53C615.1,353.76,614.78,353.22,614.58,352.67z M1003.37,206.76
				c2.39,2.88,39.28,3.52,43.26,0C1032.33,206.76,1018.01,206.76,1003.37,206.76z M249.05,590.57c5.76-2.91,11.07-5.21,15.94-8.21
				c2.37-1.45,3.94-4.2,5.87-6.36c-0.36-0.54-0.72-1.08-1.08-1.62c-3.82,0.99-8.53,0.91-11.23,3.21
				C254.7,580.88,252.34,585.89,249.05,590.57z M711.32,272.93c-0.32-0.22-0.64-0.44-0.96-0.67c-0.77,1.41-1.89,2.73-2.24,4.24
				c-0.92,3.99-1.67,8.05-2.16,12.12c-0.21,1.72-0.09,3.86,0.8,5.19c0.61,0.9,3.65,1.45,4.16,0.88c1.15-1.29,1.99-3.4,1.91-5.14
				C712.58,283.99,711.86,278.46,711.32,272.93z M589.42,512.94c7.21,2.92,9.98,2.17,10.06-1.89c0.08-4.45,0.05-8.92-0.3-13.35
				c-0.06-0.7-2.1-1.25-3.22-1.86c-1.55,2.87-3.43,5.64-4.45,8.69c-0.27,0.8,2.11,2.48,3.89,4.44
				C593.68,510.12,591.91,511.29,589.42,512.94z M348.63,741.57c9.53,2.84,22.19-0.01,25.85-4.56c-4.04-0.5-8.55-1.98-12.71-1.31
				C357.57,736.37,353.73,739.18,348.63,741.57z M1171.55,526.64c3.21-4.98,6.05-9.4,8.87-13.79c-7.03-4.99-7.03-4.99-12.48,4.27
				C1169.08,520.15,1170.1,522.82,1171.55,526.64z M443.16,582.15c0,4.14,0,7.8,0,11.29c4.89,0,9.24,0,14.52,0
				C453.81,587.69,450.27,583.19,443.16,582.15z M763.81,404.3c-3.3-0.1-4.29,0.78-3.69,4.59c0.44,2.77,1.47,5.45,1.95,8.22
				c1.16,6.79,2.58,7.48,8.82,4.04C768.57,415.65,766.26,410.14,763.81,404.3z M317.54,525.68c7.11-8.02,8.69-17.8,11.7-27.3
				c-4.14,5.37-8.44,10.12-11.69,15.49C315.62,517.07,314.56,521.26,317.54,525.68z M420.05,369.33c-0.48-0.04-0.95-0.08-1.43-0.13
				c-0.21,2.06-0.39,4.11-0.63,6.17c-0.7,6.06-0.3,12.55-2.43,18.06c-2.65,6.85,0.88,10.65,4.77,16.3c0-3.69,0-6.15,0-8.76
				c-1,0.58-1.85,1.07-4.04,2.34c0.82-4.28,0.79-7.56,2.07-10.19C422.19,385.26,420.58,377.31,420.05,369.33z M427.42,568.64
				c-0.85,7.4-1.79,14.43-2.3,21.5c-0.07,1,2.02,2.16,3.11,3.24c0.91-0.95,2.43-1.79,2.61-2.86c0.56-3.26,0.97-6.63,0.75-9.91
				C431.33,576.45,432.02,571.78,427.42,568.64z M881.14,173.43c-0.03,0.54-0.06,1.07-0.08,1.61c10.83,0.28,21.65,0.66,32.48,0.76
				c1.83,0.02,3.67-1.19,5.5-1.83c-0.13-0.68-0.25-1.37-0.38-2.05C906.15,172.43,893.64,172.93,881.14,173.43z M719.11,468.15
				c5.35-2.67,5.28-2.7,7.49-7.04c0.47-0.93,1.54-1.87,2.52-2.18c7.55-2.4,15.14-4.67,22.74-6.92c2.43-0.72,4.91-1.23,7.37-1.84
				c-0.1-0.42-0.21-0.84-0.31-1.26c-8.58,2.04-17.08,4.62-25.77,5.96c-7.19,1.11-9.62,7.42-14.43,11.07
				C718.47,466.14,718.95,467.33,719.11,468.15z M765.05,291.71c0.42-0.1,0.83-0.2,1.25-0.31c0.78-3.16,1.48-6.35,2.35-9.49
				c0.86-3.12,0.13-5.16-3.38-5.57c-1.89-0.22-3.79-0.41-5.54-0.59C756.54,283.36,762.27,286.94,765.05,291.71z M396.4,369
				c2.91,5.61,5.63,10.84,8.34,16.08c0.72-0.18,1.43-0.36,2.15-0.54c0-3.05,0.01-6.11,0-9.16C406.87,367.8,404.45,366.2,396.4,369z
				M180.59,430.61c-6.36-1.28-7.62,2.19-7.13,5.87c0.73,5.56,2.41,10.99,3.69,16.47c0.52-0.03,1.03-0.06,1.55-0.09
				C179.32,445.55,179.94,438.24,180.59,430.61z M577.06,560.48c-5.31-1.3-10.22-2.53-15.16-3.68c-1.59-0.37-3.31-0.9-4.84-0.63
				c-1.13,0.2-2.61,1.43-2.9,2.48c-0.21,0.77,1.07,2.65,2.01,2.92C563.09,563.55,570.03,564.16,577.06,560.48z M696.57,502.56
				c0.25-0.76,0.49-1.52,0.74-2.28c-1.52-2.35-3.28-6.79-4.5-6.65c-3.29,0.39-6.57,2.45-9.36,4.51c-0.66,0.49,0.6,5.25,1.02,5.26
				C688.49,503.46,692.53,502.92,696.57,502.56z M1177.34,152.29c-1.82-0.24-3.64-0.67-5.45-0.68c-7.28-0.04-14.57-0.05-21.85,0.21
				c-1.36,0.05-2.67,1.43-4,2.2c0.23,0.52,0.47,1.03,0.7,1.55C1156.9,154.85,1167.25,156.93,1177.34,152.29z M477.81,341.39
				c-0.06-0.58-0.13-1.16-0.19-1.74c-7.05,0.32-14.11,0.45-21.12,1.14c-1.13,0.11-2.01,2.76-3,4.24c1.13,0.61,2.42,1.93,3.36,1.72
				C463.88,345.14,470.83,343.22,477.81,341.39z M673.73,340.95c-0.27-0.57-0.55-1.13-0.82-1.7c-1.57,0.31-3.4,0.19-4.65,0.99
				c-4.36,2.82-8.86,4.13-14.09,3.09c-0.83-0.16-2.61,1.25-2.91,2.26c-0.77,2.52-0.94,5.21-1.35,7.84c0.41,0.24,0.81,0.49,1.22,0.73
				C658.16,348.92,664.58,342.66,673.73,340.95z M739.06,436.56c6.81-2.27,13-4.33,19.2-6.39c0.1-0.52,0.2-1.04,0.31-1.56
				c-1.67-0.79-3.45-2.42-4.98-2.21c-4.25,0.57-8.61,1.44-12.46,3.21C739.79,430.21,739.81,433.79,739.06,436.56z M343.08,383.85
				c0.24,2.4,0.29,4.81,0.73,7.15c1.28,6.63,1.34,6.62,7.78,4.08C352.86,384.87,351.9,383.47,343.08,383.85z M325.44,500.19
				c-0.53-0.74-1.07-1.47-1.6-2.21c-3.63,1.58-8.12,2.36-10.57,5.02c-1.62,1.75-0.31,6.21-0.31,9.45c0.63,0.18,1.27,0.36,1.9,0.54
				C318.38,508.72,321.91,504.46,325.44,500.19z M347.09,425.95c0.34-0.05,0.69-0.09,1.03-0.14c1.6-3.48,3.43-6.87,4.69-10.47
				c0.65-1.86,1.08-4.72,0.12-5.93c-0.85-1.06-4.12-1.11-5.7-0.34c-1.19,0.58-2.05,3.2-1.95,4.84
				C345.54,417.95,346.44,421.94,347.09,425.95z M916.61,297.33c-11.27-1.57-13.23,0.98-12.4,11.95c1.02-3.65,1.86-6.67,2.7-9.69
				c0.65,0.18,1.31,0.36,1.96,0.54c-0.37,1.6-1.07,3.21-1.03,4.8c0.04,1.63,0.46,4.41,1.32,4.66c2.91,0.86,3.63-1.03,4.04-3.88
				C913.63,302.85,915.39,300.19,916.61,297.33z M299.18,441.94c-4.88,1.15-7.91,1.86-10.94,2.57c0.7,2.61,0.7,5.9,2.34,7.6
				c1.2,1.24,6.15,1.25,6.67,0.31C298.61,449.92,298.47,446.61,299.18,441.94z M689.8,383.68c4.96,6.93,38.6,9.41,49.7,4.27
				C722.82,391.28,706.63,387.96,689.8,383.68z M871.63,410.07c0-0.75,0-1.49-0.01-2.24c-3.05-0.14-7.17-1.56-8.89-0.11
				c-2.17,1.81-2.39,5.96-3.46,9.13c8.91,1.71,10.06,1.32,10.67-3.5c-1.26-0.25-2.55-0.51-4.42-0.88
				C868.04,411.48,869.83,410.77,871.63,410.07z M576.57,504.6c6.47,3.16,8.25,2.23,9.17-3.97c0.45-3.05,1.2-6.05,2.02-10.06
				C579.69,492.66,580.62,500.45,576.57,504.6z M1070.41,327.01c6.29,0.38,11.9,0.72,18.07,1.09c-3.82-11.48-11.95-3.65-17.78-6.34
				C1070.58,323.78,1070.51,325.03,1070.41,327.01z M715.98,453.43c0.18,0.54,0.35,1.08,0.53,1.61c6.11-2.61,12.26-5.13,18.3-7.91
				c0.91-0.42,1.71-2.02,1.79-3.13c0.17-2.16-0.2-4.37-0.35-6.55c-0.33-0.47-0.66-0.95-0.99-1.42
				C732.63,446.63,723.29,448.75,715.98,453.43z M216.61,573.04c8.63-3.45,14.41-8.35,19.98-14.29c-3.46-2.18-5.98-2.4-8.23,0.28
				C224.75,563.32,221.15,567.62,216.61,573.04z M287.31,440.84c7.69,1.49,10.79,1.02,11.81-1.92c0.5-1.43,0.18-4.47-0.55-4.75
				c-2.47-0.95-6.06-2.19-7.78-1.08C288.84,434.34,288.45,438.01,287.31,440.84z M1124.12,319.69c0.39,0.35,0.78,0.7,1.16,1.05
				c4.9-1.3,9.86-2.44,14.66-4.03c0.95-0.32,1.32-2.4,1.96-3.66c-1.41-0.32-3.54-1.39-4.11-0.84c-3.26,3.15-6.4,2.58-9.98-0.22
				C1126.44,314.86,1125.28,317.28,1124.12,319.69z M853.88,263.85c0.48-0.2,0.97-0.41,1.46-0.61c1.05-4.62,0.44-9.14-3.38-12.29
				c-1.67-1.38-4.7-1.1-7.11-1.57c-0.21,0.59-0.43,1.17-0.64,1.76C847.43,255.38,850.66,259.62,853.88,263.85z M367.8,393.58
				c-0.08-0.7-0.16-1.4-0.24-2.11c-4.05-1.76-8.1-3.53-12.99-5.66c0,4.11,0,6.81,0,10.8C359.31,395.53,363.56,394.55,367.8,393.58z
				M355,520.4c0.6,0.51,1.21,1.01,1.81,1.52c1.5-1.39,3.85-2.54,4.36-4.23c1.9-6.29,3.2-12.76,5.07-20.6
				C358.39,504.14,359.49,513.5,355,520.4z M996.4,399.51c-0.73-0.4-1.45-0.8-2.18-1.19c-1.9,1.64-5.51,3.45-5.37,4.9
				c0.43,4.63,2.25,9.13,3.53,13.68c0.52-0.07,1.03-0.14,1.55-0.21C994.76,410.96,995.58,405.23,996.4,399.51z M715.15,290.52
				c0.82,0.06,1.64,0.13,2.46,0.19c0.97-4.26,2.17-8.5,2.75-12.81c0.16-1.2-1.52-2.64-2.36-3.97c-1.49,1.19-4.23,2.37-4.24,3.57
				C713.72,281.84,714.61,286.18,715.15,290.52z M1021.31,177.38c-9.1,4.23-18.82-3.12-28.66,1.12
				C1002.86,182.34,1012.25,184.04,1021.31,177.38z M151.27,207.91c-6.98,0.58-13.69,1.14-20.68,1.73
				C135.62,216.02,147.76,215.02,151.27,207.91z M435.81,370.11c-3.38-0.53-6.5-0.21-5.58,3.87c1.28,5.63,3.51,11.05,5.34,16.56
				c0.45-0.12,0.89-0.24,1.34-0.36C436.55,383.54,436.18,376.89,435.81,370.11z M981.79,235.25c-2.46-3.08-5.37-5.8-7.11-9.12
				c-1.97-3.75-4.12-3.91-7.34-2.43C969.02,231.69,973.51,235.22,981.79,235.25z M895.38,159.22c0.17,2.39,0.29,4.34,0.44,6.3
				c0.45,6,1.25,6.48,6.77,4.26c0-3.47,0-6.88,0-10.55C900.18,159.22,898.1,159.22,895.38,159.22z M675.72,512.7
				c-4.43,0.42-8.62,0.94-12.83,1.18c-2.25,0.13-4.36,0.54-3.6,3.16c0.3,1.02,2.77,1.99,4.25,1.98c3.26-0.03,6.5-0.76,9.76-1.09
				C677.57,517.51,678,516.45,675.72,512.7z M1075.61,517.65c-3.49,5.25-1.15,16.99,4.14,21.11
				C1079.89,531.39,1080.63,524.04,1075.61,517.65z M693.24,514.66c2.57,3.18,3.89,6.41,5.98,7.02c2.87,0.84,4.99-1.71,4.39-4.95
				c-0.47-2.51,0.05-6.41-3.99-6.2C697.79,510.62,696.07,512.74,693.24,514.66z M768.06,435.86c2.96-2.44,5-4.13,7.05-5.82
				c-1.46-0.91-2.92-1.83-4.59-2.87c0.23-0.13,0.81-0.34,1.21-0.73c0.49-0.47,0.82-1.1,1.89-2.6c-4.16,0.41-7.22,0.71-10.79,1.07
				C764.73,428.9,766.15,431.86,768.06,435.86z M618.43,365.24c0.5-0.01,1.01-0.03,1.51-0.04c0-8.91,0-17.82,0-27
				c-4.14-0.93-4.55-0.53-3.76,4.51c0.15,0.94-0.24,1.97-0.13,2.93C616.81,352.18,617.63,358.71,618.43,365.24z M757.27,405.89
				c-0.83,0.13-1.66,0.27-2.49,0.4c-1.58,5.49-0.09,10.78,1.91,15.96c0.23,0.6,1.65,1.01,2.51,0.99c0.56-0.01,1.66-1.05,1.58-1.45
				C759.71,416.47,758.47,411.18,757.27,405.89z M708.28,268.43c-0.63-0.1-1.26-0.21-1.89-0.31c-4.76,7.02-5.79,14.77-3.46,23.2
				C704.71,283.69,706.5,276.06,708.28,268.43z M863.79,660.58c-0.19,0.56-0.38,1.12-0.56,1.68c7.14,5.21,15.05,8.08,23.98,7.88
				c0.14-0.42,0.28-0.84,0.42-1.26C879.68,666.11,871.74,663.34,863.79,660.58z M648.57,520.04c0.47-6.66-7.58-10.75-14.79-7.68
				c4,0.97,7.98,1.93,11.96,2.89c-0.09,0.4-0.17,0.79-0.26,1.19c-4.39-0.56-8.78-1.12-13.17-1.68c-0.12,0.64-0.24,1.27-0.36,1.91
				C637.36,517.77,642.78,518.87,648.57,520.04z M564.73,528.59c7.81,2.38,12.05,1.06,10.18-5.3
				C567.22,520.52,565.71,521.31,564.73,528.59z M304.53,439.37c8.6,0.68,16.29,1.28,25.7,2.02c-1.63-2.02-2.57-4.26-3.59-4.29
				c-6.71-0.21-13.44,0.03-20.16,0.2C306.16,437.31,305.84,437.95,304.53,439.37z M438.21,222.77c-0.25-0.7-0.76-2.16-1.28-3.62
				c-0.55-0.21-1.1-0.42-1.65-0.63c-4.35,6.06-8.71,12.11-13.06,18.17c0.19,0.27,0.37,0.55,0.56,0.82
				C427.64,232.86,432.52,228.21,438.21,222.77z M881.44,734.37c3.08,0,5.65,0,8.28,0c-1.67-4.84-3.09-8.98-4.52-13.12
				c-0.51,0.03-1.01,0.06-1.52,0.09C882.99,725.38,882.29,729.41,881.44,734.37z M620.94,286.5c0.49-0.08,0.99-0.16,1.48-0.24
				c-0.65-4.6-1.07-9.26-2.12-13.76c-0.22-0.94-2.74-1.35-4.19-2c-0.35,1.37-1.37,3.01-0.94,4.05
				C616.84,278.63,618.98,282.53,620.94,286.5z M688.67,524.66c6.35-1.84,6.35-1.84,3.61-11.49
				C685.3,514.31,684.37,517.07,688.67,524.66z M273.06,174.64c5.96,4.36,18.44,2.82,24.08-2.86
				C289.02,172.75,281.39,173.65,273.06,174.64z M1163.07,471.31c1.02,2.82,2.69,5.13,2.28,6.96c-0.44,1.95-2.87,3.45-4.43,5.14
				c0.15,0.29,0.31,0.57,0.46,0.86c3.12-1.63,6.24-3.26,9.37-4.9c0-2.46,0-4.85,0-7.35C1167.91,471.75,1165.37,471.52,1163.07,471.31
				z M462.11,325.23c0.38,4.53,4.11,3.82,6.64,3.52c1.42-0.17,2.56-2.77,3.82-4.28c-0.31-0.51-0.61-1.02-0.92-1.54
				c-3.75,0-7.51-0.15-11.24,0.11c-0.91,0.06-2.02,1.29-2.49,2.25c-0.26,0.53,0.62,1.62,0.98,2.46
				C459.88,326.99,460.86,326.22,462.11,325.23z M614.26,257.55c-0.34-0.48-0.68-0.96-1.01-1.45c-4.44,0.39-8.89,0.77-13.33,1.16
				c-0.18,0.48-0.35,0.96-0.53,1.44c3.77,1.39,7.47,3.11,11.37,3.88C611.57,262.74,613.08,259.32,614.26,257.55z M365.19,396.76
				c-3.33,0.48-6.22,0.91-9.51,1.38c1.43,0.91,2.42,1.54,4.08,2.59c-6.98,1.96-4.24,6.18-2.8,10.29
				C359.52,406.57,362.09,402.12,365.19,396.76z M1047.51,199.61c8.28,4.58,16.75,1.53,25.22,0
				C1064.32,199.61,1055.91,199.61,1047.51,199.61z M842.65,148.42c-0.04,0.36-0.09,0.72-0.13,1.09c3.27,2.05,6.53,4.11,10.5,6.6
				c0.98-2.62,1.87-5.03,3.09-8.31C850.92,148.04,846.79,148.23,842.65,148.42z M502.69,478.46c-0.18-0.46-0.35-0.93-0.53-1.39
				c-1.59,0.48-3.92,0.45-4.64,1.52c-3.38,4.99-6.31,10.27-9.41,15.45c0.45,0.35,0.9,0.69,1.35,1.04
				C493.87,489.54,498.28,484,502.69,478.46z M1182.04,504.61c4.4,3.38,8.77,6.75,12.92-0.29
				C1190.73,499.52,1190.73,499.52,1182.04,504.61z M672.64,721.19c-0.32,0.48-0.64,0.96-0.96,1.44c0,2.78-0.06,5.57,0.04,8.35
				c0.03,0.89,0.23,2.27,0.79,2.53c0.92,0.44,2.53,0.64,3.25,0.11c3.32-2.43,2.21-5.87,1.06-8.77
				C676.19,723.32,674.07,722.39,672.64,721.19z M371.65,472.23c3.28,5.52,6.05,10.17,8.83,14.83c0.43-0.06,0.87-0.13,1.3-0.19
				c0.5-1.75,1.94-4.49,1.33-5.09C379.79,478.62,378.49,472.93,371.65,472.23z M229.85,418.01c-0.25-0.45-0.5-0.9-0.75-1.35
				c-6.51-1-13.55,3.97-12.12,9.83C221.48,423.52,225.66,420.77,229.85,418.01z M780.72,434.48c-7.7-1.44-9.95,0.52-15.37,12.46
				c3.73-2.68,9.83-0.25,8.03-7.57c-0.16-0.67,1.25-1.97,2.18-2.61C776.7,435.99,778.11,435.6,780.72,434.48z M272.86,600.18
				c-0.29-0.67-0.57-1.34-0.86-2.02c-6.49,3.04-12.98,6.09-19.48,9.13c0.2,0.44,0.39,0.88,0.59,1.33
				C260.15,606.87,267.21,605.17,272.86,600.18z M422.99,586.63c2.7-7.11,2.18-18.79-1.66-22.18c0.28,4.5,0.74,8.36,0.71,12.22
				C421.96,585.9,421.87,585.9,422.99,586.63z M237.21,300.47c7.86,0.95,14.18,1.72,20.51,2.48c-4.67-2.45-9.35-3.86-13.95-5.47
				c-1.21-0.42-2.2-1.47-3.29-2.23C239.61,296.62,238.76,297.99,237.21,300.47z M709.73,484.17c-10.71,6.13-11.08,6.73-8.61,13.56
				C708.58,496.1,704.59,487.17,709.73,484.17z M289.21,424.89c0,6.05,0,6.05,8.25,5.56C298.96,423.97,297.52,422.96,289.21,424.89z
				M578.41,530.24c5.68,1.04,6.56-1.9,6.91-4.69c0.09-0.7-2.17-2.17-3.54-2.48c-3.57-0.8-4.69,1.85-4.84,4.6
				C576.88,528.83,578.3,530.06,578.41,530.24z M363.89,516.32c0.52,0.13,1.03,0.25,1.55,0.38c3.27-3.03,6.43-15.07,4.07-19.95
				C367.4,504.1,365.64,510.21,363.89,516.32z M683.87,507.09c0.81,2.25,1.38,3.85,1.93,5.38c8.76-1.93,10.03-3.09,8.95-7.78
				C691.39,505.43,688.06,506.16,683.87,507.09z M658.42,356.89c-0.53-0.31-1.07-0.63-1.6-0.94c-6.91,5.03-7.14,13.97-11.92,20.25
				c0.5,0.3,1.01,0.59,1.51,0.89C650.42,370.35,654.42,363.62,658.42,356.89z M698.16,301.92c0.25,0.6,0.5,1.2,0.75,1.81
				c3.63-1.53,7.26-3.06,11.35-4.79C703.8,293.59,702.66,293.96,698.16,301.92z M1194.74,425.75c0-5.45,0-10.91,0-16.36
				c-0.43-0.02-0.86-0.04-1.29-0.06c-0.14,1.71,0.35,4.02-0.57,5c-1.39,1.48-3.77,2.04-6.47,3.36
				C1190.27,419.97,1195.21,420.32,1194.74,425.75z M1159.78,643.36c7.37-2.94,0.62-7.09,2.69-10.27c1.34,0.91,2.51,1.7,3.67,2.49
				c-0.09-2-1.23-4.24-1.85-4.1c-2.12,0.46-5.5,1.48-5.76,2.8C1158.01,636.97,1159.21,639.99,1159.78,643.36z M663.55,721.67
				c-0.42,3.2-0.69,5.23-0.98,7.47c1.55,0.74,3.5,1.67,5.32,2.54c3.54-3.68,0.34-6.66-0.62-9.66
				C667.1,721.49,664.51,721.74,663.55,721.67z M234.51,401.27c-0.39-0.51-0.78-1.02-1.17-1.53c-3.66,2.62-7.32,5.25-10.97,7.87
				C233.81,406.94,233.81,406.94,234.51,401.27z M1095.63,414.73c0.44-0.03,0.88-0.07,1.32-0.1c1.02-4.85,2.05-9.71,3.07-14.56
				c-0.4-0.11-0.8-0.22-1.2-0.32c-1.05,2.7-2.09,5.39-3.14,8.09c-0.67,0.31-1.34,0.63-2.01,0.94c-0.96-1.56-1.92-3.11-2.89-4.67
				c-0.06,0.07-0.13,0.13-0.19,0.2C1092.28,407.77,1093.95,411.25,1095.63,414.73z M1099.46,494.87c3.51-5.17,4.49-10.45,2.61-14.17
				C1097.43,483.92,1096.69,487.95,1099.46,494.87z M721.13,285.84c0.42,0.12,0.84,0.24,1.26,0.37c1.67-2.78,3.52-5.48,4.89-8.4
				c0.43-0.91-0.44-2.43-0.72-3.67c-1.46,0.66-3.93,1.05-4.2,2.04C721.5,279.27,721.48,282.6,721.13,285.84z M586.35,506.06
				c0.57,0.34,1.14,0.69,1.71,1.03c1.81-3.88,3.77-7.71,5.28-11.71c0.25-0.65-1.51-2.06-3.21-4.24
				C588.64,497.04,587.49,501.55,586.35,506.06z M759.7,513.88c-0.63,0.24-1.26,0.49-1.89,0.73c1.6,5.07,0.16,11.49,7.68,13.96
				C763.57,523.67,761.63,518.78,759.7,513.88z M231.92,412.24c-6.14-2.71-12.52,0.33-15.33,5.85
				C221.83,416.09,226.88,414.17,231.92,412.24z M1086.46,617.69c-2.34,0.28-3.68,0.45-5.32,0.65c1.34,1.75,2.36,3.08,3.71,4.85
				c-2.15,0.4-3.63,0.68-5.94,1.11c1.93,2.47,3.41,4.37,4.88,6.28c0.4-0.17,0.79-0.35,1.19-0.52
				C1085.45,626.21,1085.9,622.37,1086.46,617.69z M308.24,402.74c5.96,3.23,15.24,2.3,18.58-1.38
				C320.33,401.84,314.28,402.29,308.24,402.74z M139.96,441.4c0.54,0.24,1.08,0.49,1.62,0.73c1.75-2.91,3.64-5.76,5.13-8.8
				c0.37-0.75-0.58-2.13-0.92-3.23c-1.13,0.6-2.93,0.95-3.27,1.85C141.39,435,140.77,438.24,139.96,441.4z M775.88,583.65
				c-0.38,0.33-0.77,0.66-1.15,0.99c4.16,4.77,8.32,9.54,12.48,14.31c0.62-0.54,1.25-1.08,1.87-1.61
				C785.37,592.18,780.74,587.82,775.88,583.65z M706.79,303.49c4.72,1.71,8.47,3.07,12.76,4.63
				C714.19,299.68,714.19,299.68,706.79,303.49z M809.55,417c-6.69,4.26-13.37,8.53-19.61,12.5
				C798.54,429.55,803.84,422.94,809.55,417z M600.84,508.55c0.75,0.15,1.49,0.29,2.24,0.44c0.98-3.74,2.05-7.47,2.86-11.25
				c0.15-0.68-0.79-1.59-1.22-2.4c-0.86,0.57-2.35,1.04-2.48,1.74C601.59,500.86,601.27,504.72,600.84,508.55z M817.37,226.99
				c-2.4,1.05-4.5,1.41-4.63,2.12c-0.74,4.2-1.02,8.47-1.45,12.72c0.5,0.09,1,0.18,1.49,0.26
				C814.16,237.59,815.52,233.08,817.37,226.99z M423.28,329.85c-1.83-2.36-3.06-3.95-4.29-5.54c-0.9,1.08-2.72,2.37-2.53,3.21
				c0.43,1.9,1.85,3.57,2.86,5.33C420.36,332.06,421.41,331.27,423.28,329.85z M275.79,302.4c7.98,1.33,16.23,3.08,25.25-1.5
				C291.87,301.45,283.83,301.93,275.79,302.4z M685.3,391.69c-0.3-0.26-0.61-0.52-0.91-0.78c-2.69,3.58-5.38,7.16-8.45,11.24
				c1.88,0.54,3.31,0.95,5.11,1.48C682.58,399.32,683.94,395.51,685.3,391.69z M1159.38,429.5c-0.48-2.85-0.85-5.04-1.22-7.23
				c-0.44,0.09-0.88,0.18-1.31,0.28c0.54,2.77,0.87,5.61,1.68,8.31c0.83,2.78,2.97,4.29,5.51,2.32c0.94-0.73,0.46-3.29,0.7-5.69
				C1162.47,428.33,1161.31,428.77,1159.38,429.5z M284.58,536.69c0.29-5.44,0.56-10.37,0.82-15.3
				C280.34,531.63,280.29,534.04,284.58,536.69z M602.03,398.84c-6.58-1.29-13.39-3.53-20.74,0.27c1.15,0.45,1.58,0.78,1.98,0.76
				C589.53,399.55,595.78,399.19,602.03,398.84z M1006.64,162.02c-4.71,4.86-4.42,10.49,0,13.26
				C1006.64,171.12,1006.64,167.17,1006.64,162.02z M334.09,486.98c-3.03-1.76-5.21-3.03-8.17-4.75c-0.2,3.29-0.35,5.72-0.58,9.41
				C328.87,489.76,331.08,488.58,334.09,486.98z M153.68,497.79c0.45-5.61,0.84-10.54,1.23-15.47
				C150.15,488.77,149.94,494.73,153.68,497.79z M601.25,262.34c1.01,5.77,2.67,6.5,11.78,5.61
				C609.91,263.27,605.02,265.04,601.25,262.34z M871.52,406.3c0.32-0.44,0.64-0.88,0.96-1.32c-0.93-1.16-1.65-2.91-2.85-3.36
				c-1.89-0.7-4.1-0.52-6.18-0.71c0.19,1.32,0.02,3.59,0.62,3.81C866.43,405.6,869.02,405.83,871.52,406.3z M647.34,511.27
				c-1.6-2.89-9.81-6.37-11.92-5.48c-0.77,0.33-1.09,1.73-1.75,2.87C638.59,509.6,642.64,510.38,647.34,511.27z M1166.63,509.16
				c5.62-4.72,10.82,0.17,17.22,1.01c-3.16-2.32-5.2-3.82-7.74-5.69C1174.06,507.12,1168.38,502.89,1166.63,509.16z M888.14,721.55
				c0.76,2.86,1.29,5.6,2.27,8.16c0.37,0.96,1.72,1.97,2.75,2.13c0.89,0.14,2.71-0.81,2.79-1.45c0.13-1.08-0.49-2.58-1.31-3.39
				C892.72,725.09,890.5,723.49,888.14,721.55z M454.18,259.71c-7.79-5.51-9.28-5.18-10.37,1.68
				C447.03,260.87,449.99,260.39,454.18,259.71z M581.66,518.18c2.68,1.52,5.31,1.91,5.98-1.34c0.55-2.68-0.39-5.66-0.81-9.89
				C584.84,511.26,583.3,514.61,581.66,518.18z M426.08,222.4c8.81-7.39,8.81-7.39,6.47-11.8
				C430.15,214.97,428.11,218.69,426.08,222.4z M802.05,637.27c-5.63-1.52-9.92-2.68-14.21-3.84
				C795.8,639.81,795.8,639.81,802.05,637.27z M388.02,719.77c-0.42-0.31-0.84-0.61-1.26-0.92c-2.88,4.42-5.75,8.84-8.63,13.26
				c1.91,0.41,3.2,0.24,3.59-0.44C383.94,727.77,385.94,723.75,388.02,719.77z M352.69,400.4c-0.43-1.02-0.87-2.03-1.3-3.05
				c-2.37,1.04-4.75,2.05-7.03,3.25c-0.11,0.06,0.71,1.87,1.09,2.86C347.86,402.45,350.27,401.42,352.69,400.4z M589.46,516.61
				c-0.01,0.83-0.02,1.67-0.04,2.5c2.6,0.37,5.21,0.94,7.81,0.95c0.51,0,1.03-2.15,1.93-4.17
				C595.15,516.19,592.31,516.4,589.46,516.61z M219.25,437.05c1.63-3.55,2.83-6.14,4.27-9.27
				C216.14,428.5,215.99,428.82,219.25,437.05z M325.83,260.39c0.06,0.6,0.12,1.2,0.18,1.8c6.33-1.04,12.66-2.08,18.98-3.12
				c-0.04-0.35-0.09-0.69-0.13-1.04C338.52,258.82,332.18,259.61,325.83,260.39z M673.51,737.21c0.04-0.26,0.07-0.53,0.11-0.79
				c-3.42-0.92-6.82-1.96-10.29-2.65c-0.77-0.15-1.78,0.92-2.68,1.42c0.76,0.67,1.48,1.88,2.27,1.93
				C666.44,737.33,669.98,737.21,673.51,737.21z M588.09,524.36c4.13,3.69,8.04,3.43,11.62,0
				C595.69,524.36,591.89,524.36,588.09,524.36z M598.66,530.41c0-0.47,0-0.93-0.01-1.4c-3.8,0-7.6,0-11.4,0c0,0.47,0,0.93,0.01,1.4
				C591.07,530.41,594.86,530.41,598.66,530.41z M391.84,435.15c0.12-4.64,0.18-9.22-6.59-9.77
				C387.72,429.03,389.78,432.09,391.84,435.15z M1181.21,500.07c-2.43-1.32-3.84-2.09-5.81-3.16c-1.22,1.84-2.36,3.55-3.5,5.27
				c0.4,0.36,0.81,0.71,1.21,1.07C1175.47,502.32,1177.83,501.39,1181.21,500.07z M890.28,178.4c-5.39-3.22-7.64-3.06-10.54,1.26
				C883.56,179.2,886.66,178.83,890.28,178.4z M901.78,298.71c-1.71,3.13-3.42,6.26-5.36,9.81
				C900.47,308.31,900.47,308.31,901.78,298.71z M303.22,502.23c6.67,2.68,9.07-2.61,12.9-4.46c-0.19-0.44-0.37-0.88-0.56-1.32
				C311.72,498.25,307.88,500.05,303.22,502.23z M869.21,399.6c0.28-0.77,0.55-1.54,0.83-2.31c-2.18-1.1-4.35-2.19-6.53-3.29
				c-0.38,1.14-0.77,2.27-1.15,3.41C864.64,398.14,866.92,398.87,869.21,399.6z M1172.6,626.74c-0.69-0.51-1.39-1.02-2.08-1.53
				c-1.37,1.19-2.73,2.38-4.1,3.56c1.13,1.03,2.26,2.07,3.39,3.1C1170.74,630.16,1171.67,628.45,1172.6,626.74z M609.83,569.1
				c-0.81,0.13-1.62,0.26-2.43,0.39c0.84,4.16,1.68,8.33,2.52,12.49c0.51-0.08,1.02-0.17,1.54-0.25
				C610.91,577.52,610.37,573.31,609.83,569.1z M823.36,192.4c0.2-0.3,0.4-0.6,0.6-0.9c-3.06-0.62-6.09-1.39-9.18-1.77
				c-0.75-0.09-1.65,1.13-2.48,1.75c1.03,0.47,2.06,1.3,3.1,1.33C818.05,192.87,820.7,192.56,823.36,192.4z M1110.03,577.33
				c5.16,1.65,5.16,1.65,6.13-5.88C1114.21,573.31,1112.27,575.18,1110.03,577.33z M1001.35,702.06c2.61-6.62-2.85-7.01-4.45-9.83
				c-0.46,0.28-0.91,0.56-1.37,0.83C997.26,695.74,998.99,698.41,1001.35,702.06z M329.3,402.03c4.3,0.65,7.74,1.27,11.21,1.63
				c0.69,0.07,1.48-0.8,2.22-1.23c-0.77-0.58-1.58-1.7-2.31-1.66C337,400.98,333.59,401.51,329.3,402.03z M110.65,388.91
				c0.72,0.47,1.44,0.93,2.15,1.4c1.57-2.79,3.14-5.58,4.7-8.38c-0.61-0.37-1.23-0.74-1.84-1.11
				C114,383.52,112.33,386.21,110.65,388.91z M1115.26,518.21c-0.83,0.1-1.65,0.19-2.48,0.29c0,1.79,0,3.58,0,5.37
				c1.14-0.03,2.27-0.06,3.41-0.09C1115.88,521.92,1115.57,520.07,1115.26,518.21z M1101.11,407.88c0.37,0.42,0.74,0.84,1.1,1.25
				c1.78-1.87,3.56-3.74,6.06-6.36C1102,401.55,1101.4,404.56,1101.11,407.88z M305.43,456.57c-1.2-5.11-2.15-9.13-3.21-13.63
				C300.05,447.46,300.65,450.09,305.43,456.57z M612.11,273.2c0.12-0.83,0.23-1.66,0.35-2.49c-3.03-0.32-6.06-0.65-9.09-0.97
				c-0.09,0.88-0.18,1.75-0.26,2.63C606.11,272.64,609.11,272.92,612.11,273.2z M1000.68,163.15c-0.3-0.03-0.6-0.05-0.91-0.08
				c0,3.63,0,7.27,0,10.9c0.3,0.01,0.6,0.03,0.91,0.04C1000.68,170.39,1000.68,166.77,1000.68,163.15z"/>
			<path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M786.15,68.97c-1.1-1.29-2.19-2.58-3.46-4.08c5.35-1.34,10.16-2.42,14.88-3.79
				c2.97-0.86,5.37-1.57,3.56-6.25c-0.96-2.48,2.04-6.53-3.42-6.9c-3.44-0.24-6.92-0.67-10.34-0.4c-8.1,0.64-16.17,1.68-24.26,2.48
				c-2.91,0.29-4.91-0.05-6.41-3.64c-1.73-4.15-6.61-4.14-10.57-3.56c-2.87,0.42-4.38,0.36-5.88-2.63c-0.73-1.46-3.96-1.99-6.14-2.29
				c-1.29-0.18-2.75,0.91-4.04,1.39c-7.34-7.91-10.56-7.24-18.28,3.74c-2.51-1.66-5.03-3.34-6.73-4.47
				c4.58-9.41,9.26-19.05,14.29-29.4c9.63,0,20.58,0,31.89,0c-0.51,1.34-0.99,2.52-1.42,3.71c-1.17,3.19,0.41,4.95,3.33,5.29
				c2.09,0.24,4.36-0.07,6.39-0.65c3.67-1.06,6.16-0.99,9.64,1.97c2.39,2.04,7.95,0.4,12.09,0.34c2.47-0.03,5-0.58,7.39-0.2
				c4.97,0.79,9.47,1.09,12.9-3.73c0.84-1.19,3.07-1.7,4.74-1.93c4.04-0.56,4.06-0.42,4.53-4.84c127.79,0,255.61,0,383.87,0
				c0,44.74,0,89.44,0,134.47c-0.93,0.1-1.88,0.3-2.82,0.3c-23.48,0.01-46.96,0.26-70.43-0.08c-21.3-0.31-42.59-1.76-63.89-1.84
				c-30.31-0.11-60.62,0.64-90.93,0.9c-15.8,0.14-31.62-0.4-47.4,0.13c-34.12,1.14-68.21,3.1-102.34,4.1
				c-21.79,0.64-43.6,0.29-65.41,0.16c-41.32-0.24-82.64-0.71-123.96-0.96c-26.33-0.15-52.66-0.39-78.98,0
				c-29.96,0.45-59.92,1.69-89.88,2.28c-8.47,0.17-16.97-1.22-25.44-1.09c-32.97,0.49-65.93,1.6-98.91,1.91
				c-23.82,0.22-47.66-0.74-71.48-0.63c-18.99,0.09-37.97,1.27-56.96,1.46c-17.99,0.18-35.99-0.44-53.99-0.45
				c-40.17-0.03-80.34,0.1-120.52,0.16c-8.28,0.01-16.55,0-25.1,0c0-46.94,0-93.68,0-140.73c184.58,0,368.88,0,553.45,0
				c0.73,2.37,1.47,4.88,2.29,7.36c6.44,19.5,21.28,29.69,39.69,36.12c11.06,3.86,22.3,6.99,34.05,7c10.7,0.01,21.4-0.89,32.1-1.45
				c3.58-0.19,7.16-0.59,11.05,0.16c-1.76,1.36-3.51,2.73-5.27,4.09c0.09,0.49,0.18,0.98,0.27,1.47c2.29,0.39,4.61,1.21,6.87,1.08
				c3.97-0.24,7.89-1.11,11.84-1.64c3.53-0.47,6.91-1.51,10.04,2c1.24,1.39,4.84,1.62,7.03,1.05c6.68-1.73,13.02-2.06,19.92,2.85
				c-1.36,0.67-2.59,1.71-3.9,1.83c-4.27,0.4-8.96-0.54-12.75,0.92c-4.02,1.55-7.48,4.39-11.99,5.42c-3.02,0.69-2.97,4.64,0.26,5.97
				c3.89,1.6,8.2,2.48,12.41,2.94c3.95,0.43,8.31-1.32,10.27,4.35c0.36,1.05,3.88,1.59,5.84,1.38c4.44-0.49,8.81-1.69,13.23-2.41
				c2.28-0.37,4.66-0.56,6.95-0.33c1,0.1,1.88,1.47,2.81,2.27c-0.92,0.69-1.8,1.92-2.77,1.99c-6.75,0.53-13.51,0.93-20.27,1.14
				c-4.04,0.12-7.25,0.53-9.11,5.15c-0.75,1.87-4.44,2.56-7.67,4.24c6.02,0.47,11.44,0.9,16.85,1.32c0.17,0.35,0.34,0.71,0.51,1.06
				c-2.73,1.67-5.46,3.35-8.18,5.02c0.02,0.36,0.03,0.73,0.05,1.09c7.51,0.61,15.01,1.22,22.52,1.83c0,0.52,0,1.03,0,1.55
				c-1.19,0.24-2.37,0.52-3.57,0.71c-0.98,0.15-2.67-0.12-2.87,0.32c-2.99,6.64-9.28,2.86-14.15,4.43c0.32,1.53,0.6,2.92,1.2,5.78
				c-3.23-1.16-5.68-2.04-8.45-3.04c-0.35,7.51-6.09,8.96-12.48,9.59c-7.2,0.72-9.8-3.8-10.73-10.19c-5.75,5.68-8.91,6-17.29,2.15
				c-1.9,3.18-1.9,3.18-10.41,3.48c1.96,2.35,3.55,4.27,6.17,7.41c-3.34,0.4-6.7,1.86-8.2,0.76c-3.35-2.46-4.71-0.34-6.7,1.37
				c10.85,3.67,17.47,1.14,15.94-6.96c5.95-0.87,11.87-1.79,17.8-2.54c0.59-0.08,1.44,0.71,1.94,1.28
				c6.09,6.99,16.47,7.64,22.96,1.09c2.29-2.31,4.15-3.49,7.68-2.84c1.66,0.31,3.85-2.23,6.86-4.15c2.72-1.32,6.49-3.32,10.41-4.99
				c2.61-1.11,5.19-2.78,8.18,0.06c1.28,1.22,3.93,1.29,5.98,1.34c6.43,0.16,12.87,0.06,20.5,0.06c-1.79,1.57-3.24,2.24-3.45,3.18
				c-0.31,1.41-0.06,4.21,0.49,4.35c6.23,1.63,12.55,2.96,18.89,4.14c1.58,0.29,3.32-0.11,4.96-0.38c5.32-0.89,10.32-2.41,15.96,0.23
				c3.27,1.53,7.84,0.28,11.83,0.29c4.17,0,8.35,0.23,12.49-0.08c2.17-0.16,4.27-1.25,6.4-1.93l-0.13,0.12
				c11.03-1.35,22.06-2.69,33.09-4.04c0.1-0.43,0.2-0.85,0.3-1.28c-2.04-0.96-4.08-1.91-6.28-2.95c0.9-1.01,1.82-2.04,3-3.36
				c-4.7-5.39-10.93-2.16-16.84-3.27c0.95-1.05,1.53-1.69,2.11-2.33c1.94-0.52,3.88-1.05,5.82-1.57c3.49-0.9,6.93-2.33,10.46-2.61
				c14.57-1.17,29.16-2,43.75-2.9c0.92-0.06,1.86,0.35,3.37,0.65c-3.98,1.46-7.19,2.63-11.23,4.11c5.6,2.49,9.83,4.4,14.1,6.23
				c0.56,0.24,1.32,0.09,1.98,0.04c9.43-0.8,18.84-1.84,28.29-2.4c33.23-1.97,66.46-3.98,99.71-5.55c16.95-0.8,33.94-0.54,50.9-1.11
				c3.27-0.11,6.49-1.84,9.73-2.82c-0.1-0.66-0.2-1.33-0.29-1.99c-1.48-0.38-2.95-1.01-4.45-1.1c-7.47-0.47-14.95-0.77-22.42-1.2
				c-1.29-0.07-3.42-0.09-3.69-0.74c-2.11-5.12-6.52-3.74-10.28-3.86c-5.49-0.18-11,0.15-16.48-0.09
				c-7.73-0.33-15.24,0.06-22.31,3.76c-1.15,0.6-2.89,0.08-4.36,0.08c-0.04-0.33-0.09-0.65-0.13-0.98c2.95-1.1,5.91-2.2,8.86-3.31
				c-0.13-0.71-0.25-1.41-0.38-2.12c-5.2-0.38-10.4-0.76-15.6-1.13c-0.01-0.62-0.01-1.25-0.02-1.87c11.82-0.56,23.64-1.26,35.47-1.65
				c10.61-0.35,21.23-0.27,31.84-0.66c1.64-0.06,3.22-1.6,4.84-2.45c-0.21-0.54-0.41-1.08-0.62-1.62c-3.93-0.23-7.86-0.47-11.8-0.7
				c0-0.66,0-1.32,0-1.98c3.2,0,6.43,0.27,9.6-0.06c5.84-0.62,11.64-1.59,17.46-2.41c1.92-0.14,3.83-0.28,5.75-0.41
				c2.14-0.12,4.29-0.44,6.41-0.31c18.8,1.14,37.58,2.61,56.39,3.35c3.87,0.15,7.85-2.35,11.78-3.63c0.15-0.15,0.31-0.29,0.46-0.44
				c2.09-4.85,0.65-8.07-4.14-8.56c-3.09-0.32-6.24-0.06-9.3-0.06c-1.02-6.24-1.03-6.18-6.53-7.02c-0.98-0.15-2.15-0.79-2.74-1.58
				c-7.31-9.81-13.02-10.48-21.41-2.16c-6.91-8.66-12.94-2.27-19.27,1.71c-0.71-1.34-1.18-2.35-1.76-3.31
				c-3.28-5.41-14.7-5.71-18.23-0.66c-1.46,2.09-3.22,4.66-5.39,5.4c-2.53,0.86-5.67-0.18-8.54-0.13c-3.39,0.06-7.55-0.78-9.98,0.86
				c-5.1,3.43-10.15,2.69-15.49,2.25c-5.46-0.45-10.95-1.16-16.39-0.91c-17.28,0.81-34.53,2.42-51.81,2.84
				c-5.28,0.13-11.11-1.75-15.76-4.36c-2.87-1.6-4.5-1.83-7.47-1.1c-4.75,1.17-9.74,1.39-14.63,1.94c-0.36,0.04-0.87-0.47-1.17-0.83
				c-2.56-3.03-5.86-2.66-8.61-1.02c-4.22,2.52-8.01,5.75-12.01,8.64c-1.28,0.93-2.58,1.92-4.02,2.49c-0.33,0.13-1.3-1.36-1.98-2.1
				c1.39-1.07,2.75-2.19,4.18-3.21c1.11-0.79,2.3-1.47,3.46-2.2c-0.06-0.46-0.13-0.93-0.19-1.39c-2.15-0.4-4.34-1.28-6.44-1.11
				c-6.11,0.5-12.17,1.85-18.28,2.08c-9.59,0.37-19.21,0.09-28.81,0.08c-0.32,0-0.64-0.15-0.99-0.24c-0.38-1.32-0.76-2.65-1.09-3.8
				c2.43-1.06,4.65-2.03,6.87-2.99c-0.12-0.79-0.24-1.57-0.36-2.36c-3.25-0.11-6.5-0.34-9.74-0.3c-3.81,0.05-7.63,0.67-11.41,0.46
				c-8.77-0.48-17.52-1.33-26.28-1.98c-1.14-0.08-2.94-0.28-3.35,0.32c-2.8,4.08-6.83,2.33-10.44,2.51
				c-5.13,0.25-10.11,0.88-15.05,5.22c8.47,3.02,17.37,2.3,23.5,8.12c-9.12,6.63-18.17-1.97-27.3,0.16
				c-0.22,0.05-0.66-0.27-0.81-0.53c-2.35-3.86-6.01-2.65-8.9-1.64c-5.69,1.99-11.25,2.5-17.03,0.9
				C786.02,68.85,786.15,68.97,786.15,68.97z M486.69,88.08c1.26,0.27,3.43,0.25,3.63,0.86c1.45,4.48,4.83,3.32,7.8,2.82
				c2.77-0.46,5.41-1.65,8.18-2.07c1.97-0.3,4.08,0.03,6.1,0.29c2.95,0.38,6,1.69,8.79,1.25c3.56-0.56,6.47-2.1,8.65,2.58
				c0.66,1.41,4.13,2.06,6.31,2.07c4.13,0.02,8.25-0.77,12.39-1.03c1.48-0.09,3.62-0.19,4.37,0.67c4.01,4.53,8.39,3.2,12.74,1.14
				c4.14-1.96,4.61-4.37,1.58-7.86c-1.75-2.02-3.92-3.68-6.52-6.08c6.96-1.42,12.86-3.12,17.15-0.11c0.67,3.51,1.08,5.62,1.48,7.73
				c0.14,0.77,0.61,1.7,0.34,2.29c-2.39,5.24,0.98,7.65,4.98,8.01c5.82,0.52,11.75-0.33,17.63-0.44c1.82-0.04,3.93-0.07,5.43,0.76
				c2.73,1.51,5.09,3.66,7.87,5.74c-3.58,3.61-9.37,4.38-14.95,4.29c-5.62-0.09-8.23,0.81-9.82,5.08
				c-5.28,1.12-11.36,0.72-14.54,3.55c-3.49,3.11-6.57,3.69-10.53,3.96c-2.27,0.16-4.79,0.44-6.64,1.57
				c-1.73,1.06-2.72,3.32-4.03,5.05c1.75,1.27,3.44,3.5,5.28,3.63c6.75,0.49,13.55,0.18,20.33,0.35c18.92,0.46,37.85,1.01,56.76,1.52
				c-0.38,6.45,4.16,7.85,15.47,4.32c-4.23-0.43-8.17-0.83-12.11-1.22c0.04-0.51,0.08-1.01,0.13-1.52c2.06-1.03,4.12-2.06,6.17-3.09
				c-0.11-0.44-0.22-0.88-0.33-1.31c-2.21,0-4.43-0.09-6.63,0.02c-4.16,0.21-8.3,0.76-12.46,0.76c-20.32,0.02-40.63,0.03-60.94-0.29
				c-3.05-0.05-6.07-1.85-9.11-2.84c0.1-0.66,0.2-1.33,0.3-1.99c4.15-2.75,8.76-3.79,13.89-3.54c1.73,0.09,4.58-0.41,5.09-1.5
				c1.85-3.98,4.99-4.59,8.63-4.43c3.85,0.17,7.69,0.55,12.47,0.9c-1.76-1.96-2.89-3.21-5.11-5.67c5.11,0,8.94,0,13.14,0
				c1.92-7.23,5.63-0.93,7.95-0.69c2.3-2.93,4.23-5.38,6.61-8.4c11.23,10.27,22.42,5.09,33.24-2.33c-2.19-0.01-4.1,0.8-6.01,1.59
				c-2.1,0.86-4.35,2.62-6.3,2.34c-3-0.42-5.24-0.87-7.07,2.24c-0.35,0.59-2.99,0.52-4.05-0.12c-2.8-1.68-5.3-3.84-8.14-5.97
				c4.34-1.91,5.57-7.08,11.01-8.11c5.72-1.08,10.94-4.55,16.59-6.3c3.6-1.11,6.25-2.14,5.29-6.17c2.23-1.29,4.62-2.01,6.03-3.59
				c4.39-4.93,15.92-6.67,21.45-2.89c3.25,2.22,3.24,4.79-0.09,7.05c-1.36,0.93-2.85,1.66-4.28,2.49c0.13,0.36,0.26,0.73,0.39,1.09
				c4.16,0.41,8.32,0.81,11.62,1.14c-0.28,4.43-0.48,7.51-0.72,11.19c4.63,0.5,9.4,1.01,14.18,1.52c-0.03,0.62-0.06,1.25-0.08,1.87
				c-15.23,0.76-30.45,1.52-45,2.25c11.7,2.88,44.7,0.93,53.09-3.43c-5.32-5.39-15.56,0.8-18.86-8.78c2.11-4.37,2.11-4.37-6.57-8.47
				c1.76-7.89-0.61-11.37-9.09-11.72c-4.71-0.2-9.48,1.02-14.22,1.61c-1.11,0.14-2.54,0.83-3.26,0.37
				c-5.73-3.65-12.03-3.24-18.38-3.13c-1.61,0.03-4.06-0.6-4.73-1.74c-2.46-4.18-5.53-3.25-9.05-2.07c-1.74,0.58-4.3,1.39-5.42,0.6
				c-2.98-2.12-5.43-1.41-8.4-0.29c-3.36,1.27-6.83,2.45-10.14-0.82c-4.6-4.53-9.6-3.98-13.7,1.09c-0.38,0.47-0.95,1.06-1.47,1.09
				c-5.61,0.33-11.59,1.86-16.76,0.47c-6.27-1.69-12.12-0.39-18.15-0.51c-1.98-0.04-3.96-0.01-5.96-0.01
				c0.76-7.17-1.54-9.01-8.56-7.49c-1.65,0.36-3.87,0.48-5.15-0.35c-6.33-4.07-8.38-3.99-14.43,0.65c-1.02,0.79-3.34,0.9-4.54,0.29
				c-8.29-4.18-10.75-3.39-14.23,5.59c-3.33-1.93-6.52-3.92-9.84-5.68c-9.28-4.91-20.76,0.57-22.38,10.35
				c-3.42,0.98-6.83,1.66-9.96,3.01c-1.2,0.52-2.51,2.61-2.38,3.84c0.11,1.05,2.08,2.24,3.45,2.74c1.51,0.55,3.28,0.33,4.93,0.52
				c4.78,0.54,9.55,1.12,14.87,1.76c-2.86,1.9-5.03,3.34-8.05,5.34c6.33,1.94,11,1.06,15.5-1.36
				C486.82,87.99,486.69,88.08,486.69,88.08z M437.6,72.44c7.32-1.04,13.31-5.78,11.26-9.59c-1.93-3.59-6.71-6.18-10.79-7.91
				c-3.21-1.36-7.76,2.11-10.76-2.22c-0.23-0.33-1.59,0.3-2.36,0.14c-1.56-0.32-3.5-0.41-4.54-1.39c-7.88-7.42-22.6-4.22-26.31,5.92
				c-0.81,2.21-1.67,3.05-4.34,2.59c-5.14-0.88-10.33-1.02-14.03,3.94c-0.49,0.66-1.79,1.13-2.65,1.05
				c-3.75-0.35-7.77-0.22-11.15-1.59c-5.78-2.34-10.61-1.59-15.34,2.45c-1.01-1.24-1.72-2.84-2.92-3.42
				c-2.24-1.08-4.8-2.26-7.17-2.14c-2.98,0.15-5.89,1.57-9.09,2.52c-0.75-7.62-4.08-11.81-10.18-11.3
				c-5.4,0.45-11.03,2.49-15.74,5.23c-2.53,1.48-3.94,1.51-6.46,1.05c-2.06-0.37-5.76,0.25-6.4,1.56c-1.97,4.03-5.14,3.66-8.4,3.64
				c-11.99-0.06-23.99-0.38-35.98-0.24c-9.48,0.11-18.96,0.52-28.41,1.26c-4.47,0.35-8.85,1.79-13.27,2.73
				c-0.07,0.57-0.15,1.14-0.22,1.71c1.38,0.87,2.67,2.29,4.14,2.51c4.24,0.64,8.57,0.69,12.83,1.21c1.1,0.13,2.1,1.11,3.15,1.69
				c-1.1,0.63-2.17,1.72-3.32,1.81c-4.29,0.34-8.61,0.38-12.92,0.49c-3.59,0.09-5.47,1.65-4.61,5.26
				c-8.15,1.04-15.97,2.03-24.12,3.07c0.99,5.23,4.64,5.5,7.43,5.71c10.27,0.77,20.57,1.32,30.87,1.45
				c23.14,0.3,46.28,0.42,69.42,0.34c6.9-0.02,13.8-0.94,21.3-1.49c-1.82,5.31,1.31,5.72,4.92,5.74c6.65,0.05,13.3,0.3,19.95,0.22
				c25.62-0.29,51.25-0.56,76.87-1.1c5.85-0.12,11.68-1.27,17.51-1.95c0.06-0.64,0.12-1.29,0.18-1.93c-3.2-0.93-6.39-1.86-9.03-2.63
				c1.18-1.02,2.41-2.08,3.64-3.15c-1.25-0.9-2.42-1.98-3.78-2.65c-1.61-0.79-3.39-1.23-5.1-1.82c2.13-1.05,3.92-1.05,5.72-1.05
				c5.63,0.02,11.3,0.45,16.88-0.04C440.2,79.63,440.67,78.21,437.6,72.44z M886.67,43.2c-0.05-0.16-0.1-0.32-0.16-0.48
				c1.9-0.17,3.88-0.06,5.68-0.59c1.39-0.41,3.73-2.08,3.61-2.39c-1.32-3.38,1.64-3.35,3.13-3.75c6.26-1.7,12.54-3.28,19.11-0.91
				c1.58,0.57,4.19,0.34,5.47-0.64c5.76-4.4,10.09-4.4,14.98,0.97c1.95,2.14,3.27,3.15,6.22,1.23c1.27-0.83,4.1,0.35,6.05,1.07
				c2.18,0.81,4.09,2.34,6.27,3.12c0.7,0.25,2.47-0.68,2.72-1.42c0.43-1.28,0.46-3.04-0.12-4.2c-0.34-0.67-2.66-1.19-3.31-0.71
				c-2.58,1.88-4.49,0.78-6.78-0.51c-1.31-0.74-3.53-1.22-4.69-0.6c-2.97,1.58-4.91,0.25-7.28-1.19c-5.13-3.14-10.39-4-15.81-0.25
				c-1.2,0.83-3.27,1.24-4.66,0.84c-6.96-1.98-13.55-0.39-20.03,1.79c-2.17,0.73-3.93,2.66-5.88,4.04c0.21,0.37,0.42,0.73,0.63,1.1
				c-3.98,1.12-8.97-0.61-13.11,5.18c4.88,0.6,8.56,1.7,12.12,1.37c20.99-1.93,41.77-1.85,62.6,2.37c7.29,1.48,14.92-0.88,22.08-4.5
				c-1.44-2.19-2.49-3.79-3.33-5.06c-3.44,0.87-6.37,1.61-9.8,2.48c1.39,1.18,2.37,2,3.35,2.83c-0.1,0.51-0.19,1.02-0.29,1.54
				c-5.88,0-11.78,0.29-17.64-0.06c-11.58-0.69-23.13-2-34.72-2.56C904.29,42.86,895.47,43.2,886.67,43.2z M356.8,40.52
				c-0.12,0.56-0.24,1.13-0.35,1.69c1.84,0.43,3.69,1.24,5.52,1.21c11.14-0.14,22.29-0.89,33.41-0.58
				c6.76,0.19,13.52,2.06,19.52-2.53c1.46-1.12,2.58-2.67,3.86-4.03c-1.72-1.48-3.54-4.32-5.15-4.21c-6.43,0.45-11.5-3.21-17.03-5.09
				c-6.76-2.3-8.78-2.74-13.69,1.92c-0.63,0.6-1.64,1.24-2.43,1.18c-7.63-0.52-15.24-1.35-22.87-1.76
				c-6.09-0.33-12.05,0.52-18.28,3.78c3.23,3.43,6.88,3.23,10.39,3.55c3.61,0.33,7.21,0.81,10.82,1.23
				c-0.01,0.49-0.02,0.97-0.03,1.46C359.25,39.07,358.03,39.79,356.8,40.52z M190.68,24.45c-0.01,0.88-0.02,1.76-0.03,2.64
				c2.45,0.61,4.9,1.18,7.34,1.84c8.66,2.35,17.58-2.16,26.7,2.61c5.72,2.98,13.95,1.35,21.05,1.4c5.63,0.05,11.26-0.51,16.89-0.75
				c2.49-0.1,5.02,0.12,7.48-0.2c1.08-0.14,2.04-1.25,3.06-1.92c-0.85-0.83-1.66-2.32-2.56-2.38c-3.05-0.19-3.99-1.78-4.89-4.5
				c-1.87-5.62-6.2-7.26-11.87-5.22c-0.89,0.32-2.1,0.82-2.79,0.48c-5.9-2.92-11.66,0.41-16.92,1.42
				c-11.74,2.26-23.56,2.08-35.28,3.59C196.13,23.81,193.4,24.12,190.68,24.45z M130.71,118.61c-6.77,1.35-12.81,2.33-18.71,3.87
				c-1.52,0.4-2.6,2.51-3.88,3.83c1.63,0.93,3.24,2.62,4.91,2.68c7.64,0.26,15.32,0.32,22.95-0.07c3.41-0.18,6.86-2.18,8.76,2.58
				c0.23,0.58,1.76,0.8,2.72,0.92c8.64,1.14,16.89-1.29,25.23-2.87c2.28-0.43,4.57-0.8,6.86-1.2c-0.05-0.43-0.09-0.86-0.14-1.29
				c-4.96,0-11.24-1.89-14.59,0.43c-5.59,3.87-11.21,1.64-16.58,3.05c-0.36,0.09-0.86-0.34-1.52-0.62c0.57-1.17,1.07-2.22,1.77-3.65
				c-12.55,0-24.57,0-36.58,0c7.68-5.97,16.75-4.05,25.23-5.16c0.25-0.48,0.5-0.96,0.75-1.44c-1.27-0.42-2.54-0.85-3.82-1.27
				c0.27-0.54,0.55-1.09,0.82-1.63c6.53,0,13.13-0.54,19.56,0.24c2.88,0.35,5.34,3.25,7.9-0.69c0.2-0.3,3.4,1.06,4.95,2.05
				c0.77,0.49,0.97,1.87,1.4,2.79c8.03-2.28,8.03-2.28,10.01,2.89c5.1-3.01,7.3-2.52,9.16,2.23c-1.97,0.66-3.93,1.31-6.34,2.12
				c5,1.14,8.27,0.11,8.87-2.51c0.61-2.7-2.54-5.36-6.73-5.89c-1.74-0.22-3.47-0.68-5.15-1.21c-4.92-1.53-9.81-3.15-14.73-4.7
				c-0.44-0.14-0.99,0.1-1.48,0.09c-6.84-0.1-13.71,0.17-20.51-0.44C135.47,113.18,131.92,114.48,130.71,118.61z M1021.56,34.4
				c-1.98,0.81-3.95,1.63-5.93,2.44c1.01,1.1,1.86,2.91,3.06,3.18c13.7,3.14,27.3,1.93,40.81-1.36c0.16-0.04,0.37-0.11,0.44-0.23
				c1.04-2.01,2.97-4.09,2.85-6.04c-0.18-2.8-1.98-5.31-5.66-4.95c-4.61,0.46-9.67-3.48-13.93,1.43c-0.27,0.31-2.01-0.81-3.12-1.04
				c-4.08-0.87-8.7-1.23-10.45,3.17C1027.95,35.26,1025.35,35.48,1021.56,34.4z M533.69,112.88c-0.25,0.47-0.5,0.93-0.75,1.4
				c1.42,1.24,2.8,3.49,4.25,3.53c5.02,0.14,10.06-0.54,15.1-0.79c2.14-0.1,4.45-0.36,6.43,0.26c4.22,1.32,10.17-1.21,11.9-4.99
				c-2.44-2.43-4.4-5.04-6.97-6.76c-2.49-1.66-5.36-3.39-8.69-0.78c-1.19,0.94-3.49,1.34-4.96,0.89c-4.05-1.23-6.17,0.27-7.93,3.8
				c-0.68,1.37-2.58,2.36-4.14,3.02C536.69,113,535.12,112.78,533.69,112.88z M488.27,111.99c2.11,0.49,4.44,1.42,6.8,1.49
				c4.14,0.13,8.32-0.08,12.45-0.5c4.28-0.44,8.55-1.16,12.75-2.06c1.28-0.27,3.28-1.51,3.31-2.38c0.05-1.34-0.98-3.5-2.1-3.96
				c-2.25-0.93-5.32-0.24-7.26-1.46c-7.01-4.42-12.4,4.05-18.97,2.01c-0.23-0.07-0.71,0.58-1.05,0.91
				C492.54,107.7,490.89,109.36,488.27,111.99z M1067.16,49.51c3.55-0.32,7.31-0.14,10.73-1.14c2.14-0.63,3.77-3.02,5.63-4.63
				c-2.18-1.04-4.44-3.11-6.51-2.94c-6.64,0.54-13.22,1.85-19.79,3.04c-0.91,0.17-2.41,1.61-2.28,2.08c0.3,1.05,1.34,2.47,2.28,2.64
				C1060.45,49.13,1063.77,49.23,1067.16,49.51z M446.04,34.94c1.38-0.22,2.69-0.37,3.98-0.64c3.32-0.68,3.8-3.34,2.95-5.74
				c-0.55-1.55-3.11-3.89-4-3.58c-5.2,1.77-10.19,0.93-15.28-0.09c-0.77-0.15-1.81-0.08-2.42,0.35c-1.54,1.08-2.89,2.41-4.32,3.65
				c1.44,1.38,2.7,3.59,4.37,3.97C436.15,33.95,441.14,34.3,446.04,34.94z M845.75,32.21c5.44,5.53,13.71,4.76,19.16-1.53
				C861.37,27.34,850.83,28.06,845.75,32.21z M566.92,56.38c0.59,0.47,1.18,0.94,1.78,1.41c2.65-3.38,5.29-6.75,7.94-10.13
				c-0.61-0.48-1.21-0.95-1.82-1.43C572.18,49.62,569.55,53,566.92,56.38z M530.72,26.69c0.3,0.64,0.59,1.27,0.89,1.91
				c4.12-1.83,8.25-3.66,12.37-5.49c-0.36-0.76-0.71-1.52-1.07-2.29C538.85,22.78,534.78,24.74,530.72,26.69z M582.9,51.25
				c-0.55-0.41-1.1-0.82-1.65-1.23c-2.08,3.12-4.16,6.25-6.23,9.37c0.55,0.37,1.11,0.74,1.66,1.11
				C578.75,57.42,580.82,54.33,582.9,51.25z"/>
			<path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M334.41,848.56c15.21-6.85,30.07-10.54,45.28-13.55c14.75-2.92,29.2-7.36,43.82-10.95
				c6.73-1.65,13.51-3.3,20.37-4.14c12.35-1.51,23.78-5.65,35.28-10.13c6.5-2.53,13.8-3.22,20.81-4.18
				c5.91-0.81,12.08-0.15,17.87-1.36c4.86-1.02,9.6-3.38,13.94-5.93c3.61-2.12,6.76-3.58,11.15-2.67c2.94,0.61,6.27-0.01,9.3-0.65
				c13.61-2.91,27.23-5.81,40.72-9.18c4.77-1.19,9.38-3.41,13.66-5.86c1.17-0.67,1.55-4.03,1.13-5.89c-0.86-3.82,0.24-4.95,4.05-5.35
				c13.86-1.44,27.68-3.29,41.53-4.88c6.28-0.72,12.58-1.24,18.88-1.72c20.24-1.55,40.49-2.96,60.72-4.57
				c17.55-1.4,35.12-2.7,52.62-4.66c7.53-0.84,15.26-2.34,22.25-5.16c9.76-3.94,19.59-3.06,29.5-2.76
				c12.9,0.39,25.43-0.66,37.19-6.71c2.01-1.03,4.74-0.65,7.14-0.9c3.5-0.36,7.6,0.36,10.38-1.24c9.3-5.36,19.06-8.37,29.73-8.98
				c14.76-0.85,29.5-2.06,44.26-2.93c10.27-0.6,20.03-2.42,28.63-8.68c10.75-7.84,22.66-9.41,35.06-4.84
				c9.46,3.49,19.25,3.42,29.01,3.69c8.64,0.24,17.31-0.59,25.93-0.2c11.99,0.54,23.22-2.44,33.84-7.2c12-5.38,24.07-4.75,36.57-3.51
				c10.57,1.05,21.25,1.09,31.88,1.53c2.48,0.1,4.96,0.02,7.85,0.02c0,47.95,0,95.52,0,143.55
				C908.46,848.56,622.05,848.56,334.41,848.56z M856.12,818.02c-4.19,0.72-8.39,1.45-12.58,2.17c0.08,0.71,0.16,1.43,0.24,2.14
				c3.01,0,6.02,0.08,9.02-0.03c2.31-0.09,5.38-1.47,6.8-0.45c5.3,3.81,10.47,0.53,15.64,0.55c3.36,0.02,6.72-0.63,10.09-0.98
				c-0.02-0.52-0.04-1.04-0.05-1.55c-10.47,0-20.94,0-31.77,0c1.14-0.88,1.84-1.42,2.54-1.95c7.87,0.61,15.28-0.93,22.43-3.75
				c-0.09-0.71-0.18-1.41-0.27-2.12c-5.97,0.31-11.93,0.61-17.9,0.92c0.01,0.5,0.01,1,0.02,1.49c1.59,0.22,3.17,0.45,5.07,0.71
				C862.07,816.19,859.1,817.11,856.12,818.02z M552.02,815.16c-0.29-0.15-0.58-0.31-0.87-0.46c0.47-3.31,0.95-6.63,1.37-9.59
				c-4.23,4.6-8.28,9.02-12.49,13.59c3.83,2.3,5.25-3.38,8.49-4.37c0,1.91,0,3.67,0,6.87c2.91-2.72,4.81-4.5,7.12-6.66
				c-0.42,1.36-0.79,2.1-0.85,2.87c-0.12,1.53-0.06,3.08-0.07,4.62c1.39-0.59,2.9-1,4.15-1.81c2.51-1.6,4.77-3.6,7.36-5.05
				c0.93-0.52,2.48,0.08,4.59,0.23c0.25-0.69,0.77-2.14,1.29-3.59c-0.44-0.43-0.88-0.85-1.32-1.28c-4.49,2.55-8.98,5.11-14.38,8.18
				c2.76-5.72,5.03-10.44,7.3-15.16c-0.55-0.36-1.1-0.71-1.65-1.07C558.72,806.72,555.37,810.94,552.02,815.16z M987.75,762.47
				c-0.55,1.86-1.08,3.06-1.23,4.31c-0.13,1.04,0.19,2.13,0.31,3.19c1.16-0.35,2.4-0.55,3.44-1.11c0.85-0.45,1.35-1.53,2.2-2
				c2.8-1.55,5.57-3.75,8.57-4.19c2.81-0.41,2.94,0.44,2.09-4.33c-4.76,2.75-9.19,5.31-14.6,8.43c2.71-5.69,4.99-10.49,7.28-15.29
				c-0.56-0.33-1.12-0.66-1.68-0.99c-3.34,4.23-6.68,8.46-10.02,12.69c-0.29-0.18-0.59-0.37-0.88-0.55c0.47-3.28,0.95-6.57,1.36-9.46
				c-4.27,4.62-8.33,9.02-12.43,13.45c3.97,2.41,5.15-3.39,8.48-4.14c0,1.79,0,3.53,0,6.77C983.53,766.5,985.37,764.74,987.75,762.47
				z M833.79,835.91c0.11,0.62,0.21,1.25,0.32,1.87c10.52-1.49,21.05-2.98,31.51-4.46c-0.15-1.19-0.36-2.76-0.7-5.39
				c-15.05,2.11-30.07,4.21-45.09,6.31c0.1,0.72,0.2,1.43,0.3,2.15c3.27-0.52,6.55-1.01,9.82-1.56c3.42-0.58,6.81-1.25,10.23-1.81
				c3.43-0.56,6.87-1.02,10.31-1.53c3.11-0.46,6.28-2.4,9.34,0.81C851.14,833.5,842.46,834.7,833.79,835.91z M665.5,808.43
				c0.14,0.63,0.28,1.26,0.42,1.88c10.51-2.36,21.02-4.73,31.53-7.09c-0.13-0.58-0.26-1.17-0.39-1.75
				C686.54,803.79,676.02,806.11,665.5,808.43z M1191.89,748.69c-0.09-0.59-0.17-1.17-0.26-1.76c-4.82,0.71-9.65,1.42-14.73,2.17
				c1.6,2.05,2.54,3.25,3.73,4.77c2.94-0.67,6.36-1.45,9.79-2.23c0-0.26,0-0.52,0-0.78c-1.47-0.33-2.93-0.66-5.71-1.29
				C1188,749.16,1189.95,748.92,1191.89,748.69z M676.56,809.65c0.16,0.68,0.32,1.35,0.48,2.03c9.04-1.83,18.08-3.66,27.11-5.49
				c-0.11-0.55-0.22-1.1-0.33-1.65C694.74,806.24,685.65,807.94,676.56,809.65z M836.94,801.68c2.28-2.67,3.78-4.43,5.29-6.2
				c-1.37-0.87-2.98-2.69-4.04-2.41c-2.19,0.57-4.09,2.26-6.11,3.48C833.37,797.92,834.66,799.28,836.94,801.68z M703.96,811.62
				c-0.11-0.49-0.23-0.98-0.34-1.47c-7.84,1.86-15.67,3.71-23.51,5.57c0.13,0.55,0.26,1.11,0.38,1.66
				C688.32,815.45,696.14,813.54,703.96,811.62z M994.2,769.85c0.29,0.68,0.59,1.36,0.88,2.04c3.7-1.69,7.39-3.39,11.09-5.08
				c-0.24-0.55-0.47-1.11-0.71-1.66C1001.71,766.71,997.96,768.28,994.2,769.85z M562.19,821.69c0.29,0.7,0.58,1.41,0.87,2.11
				c3.68-1.66,7.36-3.32,11.04-4.98c-0.23-0.56-0.46-1.12-0.69-1.68C569.68,818.66,565.94,820.18,562.19,821.69z M1048.67,820.06
				c0.17-0.89,0.33-1.78,0.5-2.67c-2.23-0.28-4.46-0.56-6.68-0.84c-0.12,0.78-0.25,1.56-0.37,2.34
				C1044.3,819.28,1046.48,819.67,1048.67,820.06z"/>
			<path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M6,848.73c0-2.23,0-3.89,0-5.54c0-79.8,0-159.61,0-239.41c0-6.36,4.77-13.19,10.92-14.97
				c7.41-2.14,14.64-4.43,20.28-11.32c5.03,6.85,9.95,13.06,14.34,19.63c9.48,14.21,21.05,26.02,36.31,34.13
				c8.47,4.5,15.65,10.74,20.76,19.03c4.66,7.56,11.75,11.06,20.2,12.14c11.39,1.46,19.53,7.53,27.16,15.97
				c4.47,4.95,11.8,7.64,18.26,10.37c8.81,3.72,18.07,6.36,27.02,9.78c3.86,1.47,7.65,3.4,11.04,5.74
				c4.67,3.24,8.72,7.38,13.43,10.57c5.04,3.41,10.43,6.35,15.85,9.12c2.8,1.43,6.5,1.6,4.05,6.39c-0.28,0.55,1.81,3.24,3.06,3.46
				c4.19,0.72,7.06,2.9,9.98,5.86c2.11,2.14,5.47,3.03,8.27,4.49c5.12,2.67,6.47,9.7,1.86,13.09c-5.48,4.04-4.68,8.37-2.64,13.55
				c1.45,3.69,3.02,7.53,3.32,11.39c0.22,2.84-1.61,5.8-2.39,8.75c-0.63,2.38-1.1,4.81-1.51,7.24c-0.13,0.77,0.43,1.71,0.19,2.41
				c-3.28,9.29,0.9,16.54,6.19,23.59c1.04,1.39,1.83,3.23,2.03,4.94c0.74,6.42,1.18,12.88,1.75,19.6
				C186.05,848.73,96.32,848.73,6,848.73z M126.1,741.12c0,0-0.13-0.13-0.13-0.13c5.89,4.46,11.46,9.46,17.75,13.25
				c11.78,7.11,23.97,13.55,36.04,20.17c2.01,1.1,4.21,2.13,6.44,2.52c7.32,1.27,14.07,3.72,20.14,8.09
				c1.85,1.33,4.13,2.06,6.22,3.07c-6.9-7.35-15.48-10.96-24.88-13.71c-6.49-1.9-12.67-5.69-18.22-9.69
				c-6.64-4.78-12.38-10.8-18.68-16.07c-7.37-6.16-14.85-11.86-24.66-14.25c-6.99-1.7-13.52-5.32-20.18-8.26
				c-2.58-1.14-4.96-2.75-7.43-4.14c0.68,1.7,1.8,2.64,3.02,3.43c6.26,4.07,12.55,8.1,18.83,12.13
				C122.24,738.76,124.18,739.93,126.1,741.12z M220.29,803.24c-26.44-16.51-56.83-24.26-84.43-38.2
				C162.28,781.6,193.41,787.72,220.29,803.24z M136.4,730.18c-0.39,0.49-0.77,0.98-1.16,1.47c16.9,10.98,33.8,21.96,50.7,32.94
				c0.31-0.48,0.62-0.96,0.93-1.43C170.06,752.16,153.23,741.17,136.4,730.18z M110.82,779.74c0.34-0.52,0.68-1.05,1.02-1.57
				c-9.82-6.33-19.63-12.66-29.45-18.98c-0.23,0.36-0.47,0.73-0.7,1.1C91.39,766.77,101.11,773.25,110.82,779.74z M91.43,753.31
				c0.42-0.59,0.84-1.19,1.26-1.78c-9.46-6.47-18.92-12.94-28.38-19.41c-0.23,0.33-0.47,0.67-0.7,1
				C72.88,739.84,82.15,746.58,91.43,753.31z M71.75,686.79c-0.38,0.25-0.76,0.49-1.14,0.74c2.72,6.89,7.61,12.38,13.01,17.53
				C79.66,698.98,75.71,692.89,71.75,686.79z M101.24,740.05c-0.26,0.33-0.53,0.67-0.79,1c5.81,4.02,11.62,8.04,17.43,12.07
				c0.26-0.39,0.52-0.77,0.78-1.16C112.85,747.98,107.05,744.02,101.24,740.05z M32.09,706.29c-0.37,0.46-0.74,0.92-1.11,1.38
				c4.02,3.19,8.04,6.38,12.06,9.57c0.29-0.37,0.59-0.73,0.88-1.1C39.98,712.86,36.04,709.57,32.09,706.29z"/>
			<path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M504.26,205.84c-2.02-5.23-3.91-10.53-6.2-15.64c-0.4-0.9-2.56-1.52-3.84-1.42
				c-4.29,0.35-8.53,1.16-12.81,1.64c-5.56,0.62-11.14,1.16-16.72,1.59c-4.29,0.33-8.6,0.44-12.91,0.63
				c-2.79,0.12-5.56,2.64-8.46-0.59c-0.8-0.89-3.78,0.34-5.76,0.35c-9.58,0.05-19.16,0.02-28.64,0.02c0.65-3.06,1.27-5.98,1.89-8.91
				c15.18,2.49,30.26,0.92,45.36-0.06c0-0.46-0.01-0.91-0.01-1.37c-4.74,0-9.48-0.09-14.22,0.02c-5.99,0.14-11.97,0.59-17.96,0.63
				c-2.33,0.02-5.38,0.3-5.16-3.65c0.2-3.6,1.78-5.28,5.69-5.17c8.49,0.23,16.99,0.19,25.49,0.01c3.8-0.08,7.6-0.65,11.38-1.19
				c0.98-0.14,1.86-0.99,4.07-2.23c-2.63-0.69-3.95-1.39-5.22-1.31c-8.59,0.54-17.17,1.28-25.76,1.86c-2.43,0.17-4.87,0.03-6.06,0.03
				c-0.53-4.11-0.52-6.85-1.29-9.35c-0.98-3.17,0.08-4.46,3.09-4.58c1.14-0.04,2.32,0.21,3.43,0.51c10.67,2.86,21.37,2.83,32.08,0.3
				c3.09-0.73,6.1-1.79,9.63-2.03c-2.95,6.65-3.84,13.91-10.35,18.33c-5.06,3.44-4.05,9.33-5.5,14.21c0.47,0.23,0.94,0.45,1.41,0.68
				c0.68-0.93,1.85-1.81,1.98-2.81c1.6-12.69,11.11-14.73,21.25-15.98c6.59-0.82,13.19-1.61,19.78-2.48c0.75-0.1,1.45-0.6,2.02-2.07
				c-7.12,0.74-14.24,1.49-21.36,2.23c-0.11-0.46-0.22-0.92-0.33-1.38c5.41-4.08,12.15-4.81,18.54-6.07
				c6.37-1.25,12.9-1.71,19.37-2.52c-0.08-0.59-0.16-1.18-0.24-1.77c-7.85,1.01-15.73,1.83-23.54,3.09
				c-4.24,0.68-8.5,1.76-12.49,3.32c-2.26,0.88-3.89,3.25-5.99,4.69c-1.66,1.14-3.61,1.87-6.44,3.29c1.66-4.42,2.66-7.69,4.15-10.71
				c0.54-1.09,2.24-2.13,3.5-2.26c4.11-0.42,8.29-0.23,12.4-0.63c4.08-0.39,8.11-1.32,12.18-1.82c3.5-0.43,7.02-0.59,10.54-0.85
				c0.32-0.02,0.71,0.25,0.98,0.14c7.27-2.88,14.82,0.66,22.12-0.96c2.83-0.63,4.28,0.35,5.32,3.01c2.65,6.77,7.52,11.81,12.69,16.55
				c0.41-0.28,0.82-0.57,1.23-0.85c-0.58-0.95-1.11-1.93-1.76-2.83c-3.38-4.69-7.11-9.16-10.07-14.1c-2.31-3.85-4.74-5.77-9.23-4
				c-1.04,0.41-2.61,0.91-3.36,0.45c-7.54-4.68-15.49,2.45-23.1-0.9c-1.08-0.48-2.93,0.36-4.27,0.95c-8.84,3.86-18.2,3.37-27.5,3.5
				c-3.43,0.05-3.78-1.86-2.8-5.48c15.71-0.33,31.63-0.75,47.55-0.97c16.16-0.22,32.32-0.45,48.48-0.32
				c31.31,0.25,62.62,0.88,93.93,1.07c13.81,0.08,27.63-0.9,41.44-0.75c26.33,0.29,52.64,1.38,78.97,1.48
				c15.14,0.05,30.28-1.35,45.43-1.72c2.81-0.07,5.71,1.54,8.47,2.63c1.66,0.66,3.18,1.73,4.68,2.74c2.5,1.69,4.92,3.48,8.03,5.69
				c0.65-1.27,2.18-3.27,2.74-5.52c1.29-5.17,4.37-6.46,9.46-6.5c32.47-0.23,64.94-0.75,97.4-1.26c9.65-0.15,19.3-0.63,28.96-0.91
				c2.94-0.08,5.85,0.1,5.9,4.2c-5.13,2.45-10.28,4.91-15.59,7.45c2.24,6.28-2.49,10.01-5.58,14.25c-4.78,6.55-9.9,12.85-14.7,19.38
				c-0.83,1.13-0.81,2.88-1.18,4.35c0.4,0.16,0.81,0.33,1.21,0.49c4.97-6.74,10.11-13.37,14.85-20.28c3.07-4.47,5.78-9.22,8.25-14.05
				c2.07-4.03,5.78-5.37,13.12-4.12c-0.59,5.71-1.17,11.39-1.77,17.33c-6.22,0.23-8.76,3.73-9.71,9.73
				c-0.45,2.83-2.92,5.93-5.35,7.73c-3.61,2.67-5.48,5.79-5.29,10.04c0.25,5.67-2.66,9.93-7.47,11.06c-3.64,0.86-8-1.21-12-2.14
				c-1.86-0.43-3.63-1.77-5.45-1.78c-19.1-0.13-38.2-0.13-57.31,0.01c-2.59,0.02-5.5,0.41-7.67,1.65c-2.85,1.63-6.42,3.64-3.48,8.22
				c0.6,0.93,0.62,2.82,0.07,3.8c-3.02,5.47-6.29,10.8-9.47,16.18c-0.49,0.01-0.99,0.02-1.48,0.03c-0.16-3.07-0.77-6.2-0.4-9.21
				c0.8-6.44-1.82-10.11-8.29-10.05c-7.58,0.07-15.15,0.8-22.73,0.8c-4.11,0-8.22-0.9-12.32-1.42c-1.47-0.19-2.98-0.79-4.4-0.63
				c-12.21,1.37-24.41,2.8-36.57,4.48c-1.21,0.17-2.5,1.96-3.19,3.28c-1.84,3.53-3.21,7.3-5.08,10.8c-0.86,1.61-2.45,2.83-3.72,4.22
				c-0.49-0.09-0.98-0.18-1.46-0.26c-0.44-2.45-1.22-4.89-1.25-7.35c-0.12-8.53-1.78-10.14-10.38-9.93
				c-7.13,0.18-14.27,0.01-21.4,0.22c-3.29,0.09-5.06-0.95-5.06-4.44c0-7.08,0.1-14.16-0.27-21.22c-0.11-2-1.23-5.09-2.7-5.69
				c-6.11-2.47-6.71-6.98-6.22-12.57c0.66-7.47,0.51-7.37,7.8-8.83c5.64-1.13,11.21-2.61,17.65-4.13c-1.19,1.72-1.86,2.69-2.81,4.06
				c6.35,3.94,12.15-0.69,17.93-0.45c0.16,0.46,0.43,0.83,0.33,1c-4.6,7.99-3.18,11.33,5.85,13.73c3.22,0.86,6.47,1.59,9.66,2.54
				c2.92,0.87,5.77,1.96,8.98,3.06c-0.39,0.63-1.02,1.52-1.49,2.48c-0.67,1.36-1.23,2.78-1.84,4.18c1.53,0.43,3.05,1.14,4.61,1.24
				c4.1,0.27,8.86-1.38,10.46,4.76c0.18,0.68,3.11,1.27,4.48,0.89c3.52-0.98,6.34-1.13,7.75,3.57c2-3.54,0.45-5.31-2.38-5.65
				c-3.86-0.46-7.47,0.12-10.3-4.14c-1.11-1.67-5.92-0.89-9.05-1.23c-0.64-0.07-1.27-0.2-1.77-0.28c0.51-2.85,2.23-6.41,1.13-7.93
				c-1.2-1.65-5.02-1.41-7.7-1.99c-0.76-4.61-1.71-9.3-2.16-14.04c-0.09-0.92,1.7-2.74,2.82-2.92c3.93-0.62,7.95-1,11.92-0.9
				c4.67,0.11,9.34,0.82,13.77,1.24c-6.41,6.8-2.81,15.05-1.65,22.97c0.13,0.88,3.27,2.34,4.35,1.9c5.09-2.06,7.45,0.17,9.04,4.62
				c1.3,3.63,2.83,7.19,5.23,10.79c0.11-1.64,0.6-3.35,0.27-4.89c-0.96-4.47-1.88-9-3.49-13.25c-1.6-4.21-5.94-4.18-9.68-4.95
				c-1.68-0.35-4.57-1.41-4.58-2.17c-0.04-3.61,0.73-7.23,1.21-10.85c2.55,0.5,5.27,0.63,7.62,1.6c2.72,1.13,5.08,3.1,7.68,4.55
				c2.66,1.48,5.14,2.75,5.15,6.57c0,1.26,2.01,3.36,3.37,3.61c5.16,0.96,8.59,3.56,11.11,8.19c1.17,2.16,3.84,3.5,6.49,4.44
				c-5.66-4.63-6.79-14.21-16.18-14.75c-0.85-0.05-2.31-0.77-2.38-1.32c-0.89-7.25-7.28-8.88-12.09-12.12
				c-0.41-0.27-0.74-0.66-1.11-0.99c6.62-5.36,7.79-5.02,10.75,2.71c1.22,3.19,2.73,6.27,4.11,9.4c0.56-0.25,1.12-0.49,1.68-0.74
				c-1.85-4.47-3.87-8.88-5.45-13.45c-0.5-1.46-0.43-4.29,0.43-4.83c2.56-1.61,4.92-0.42,5.76,2.51c1.37,4.78,2.45,9.64,3.65,14.46
				c0.8,3.2,2.06,5.93,5.76,6.76c1,0.23,2.01,1.11,2.7,1.95c5.06,6.23,10.03,12.54,15.07,18.78c0.35,0.44,1.03,0.61,2.7,1.54
				c-2.28-5.9-4.65-10.24-8.99-13.3c-0.4-0.28-1.11-0.33-1.31-0.69c-4.26-7.81-14.41-11.37-15.51-21.44c-0.1-0.89-0.01-1.8-0.01-2.46
				c9.44,7.12,18.78,14.16,28.12,21.21c0.42-0.55,0.83-1.1,1.25-1.65c-5.18-3.84-10.37-7.67-15.39-11.38
				c1.12-2.22,2.19-4.37,3.29-6.51c0.3-0.58,0.76-1.63,0.99-1.59c4.71,0.85,10.73,0.4,13.69,3.19c2.81,2.65,4.84,3.08,7.95,2.89
				c6.92-0.42,13.83-0.82,20.75-1.14c6.97-0.33,13.94-0.52,20.91-0.87c0.95-0.05,1.97-0.47,2.81-0.97
				c7.36-4.44,13.69-2.52,17.91,4.77c5.08,8.77,10.68,17.24,16.1,25.81c0.48,0.75,1.35,1.26,2.03,1.89c0.41-0.29,0.82-0.58,1.24-0.86
				c-4-6.1-8.12-12.13-11.97-18.33c-3.93-6.33-7.59-12.83-11.5-19.49c-4.7,5.38-10.22-0.59-15.91,1.66c-1.48-7.22-2.82-13.77-4.3-21
				c-3.18,0.32-6.56,0.91-9.95,0.94c-14.13,0.15-28.25,0.2-42.38,0.16c-4.04-0.01-6.22,1.23-8.36,5.25
				c-3.06,5.73-8.02,10.44-11.99,15.37c-3.69-3.25-6.7-6.19-10.02-8.72c-2.39-1.83-1.85-6.29-6.29-6.33
				c-2.18-0.02-4.35-1.3-6.54-1.96c-1.52-0.46-3.11-0.69-4.58-1.26c-2.77-1.08-5.46-3.29-8.2-3.32c-8.94-0.09-17.89,0.8-26.84,0.91
				c-14.82,0.18-29.64-0.14-44.46,0.15c-5.98,0.12-11.93,1.29-17.9,1.98c0.08,0.63,0.15,1.26,0.23,1.9c3.9-0.45,7.8-1.23,11.71-1.29
				c11.12-0.19,22.25,0.05,33.38-0.13c8.63-0.14,17.25-0.74,25.88-0.96c5.63-0.14,11.3-0.33,16.89,0.2c2.83,0.27,5.5,2.09,7.68,4
				c-12.54,1.16-25.1,2.06-37.59,3.6c-4.45,0.55-8.67,2.81-13.07,3.99c-4.66,1.26-9.42,2.17-14.13,3.23
				c-0.13-0.45-0.26-0.89-0.4-1.34c2.2-1.17,4.39-2.35,6.59-3.52c-15.13-3.07-28.72,7.02-44.74,4.05c2.7-2.01,4.76-3.54,6.81-5.06
				c-0.13-0.46-0.25-0.91-0.38-1.37c-3.69,0-7.51-0.64-11.05,0.1c-21.38,4.47-42.36,0.68-63.47-2.26
				c-10.19-1.42-20.54-1.74-30.81-2.59c-1.19-0.1-2.36-0.41-4.97-0.87c25.95-4.42,50.61-1.64,75.18-1.49
				c0.05-0.55,0.09-1.09,0.14-1.64c-0.47-0.23-0.95-0.68-1.41-0.67c-20.08,0.57-40.19-2.6-60.28,1.15
				c-6.43,1.2-13.29,0.05-19.95,0.19c-1.41,0.03-3.71,0.62-4.06,1.54c-2.53,6.74-4.67,13.62-6.93,20.46c0.6,0.21,1.21,0.42,1.81,0.63
				c1.12-3.22,2.42-6.4,3.32-9.68c2.87-10.48,2.77-10.17,13.56-8.57c8.38,1.24,16.92,1.35,25.35,2.3c16.15,1.82,32.23,4.45,48.43,5.6
				c8.34,0.59,16.86-1.32,25.3-2.05c1.07-0.09,2.17,0.08,4.33,0.18c-6.82,5.92-12.84,11.14-19.07,16.54
				c-3.57-5.16-0.77-8.14,4.16-11.55c-2.92-0.19-4.28-0.32-5.64-0.36c-13.6-0.39-27.19-1.11-40.79-1.04
				c-13.63,0.07-27.27,0.77-40.89,1.54c-7.21,0.41-6.48,7.7-9.24,11.93c-0.68,1.04-0.65,2.54-0.97,3.83
				c-3.7,14.87-5.14,13.55-16.12,13.1c-6.34-0.26-10.23,3.55-12.64,8.9c-3.6,7.99-6.97,16.09-10.37,24.18
				c-0.47,1.11-0.58,2.38-0.14,3.88c7.29-8.39,9.15-19.63,14.59-28.94c0.66,0.15,1.32,0.3,1.99,0.45c-0.67,4.12-0.83,8.42-2.16,12.31
				c-1.38,4.05-3.9,7.71-5.92,11.54c0.46,0.31,0.92,0.62,1.38,0.94c2.08-3.27,4.43-6.41,6.13-9.86c1.07-2.17,1.24-4.81,1.67-7.27
				c1.33-7.64,1.86-8.1,9.6-7.84c0.66,0.02,1.8-0.03,1.9,0.25c2.48,6.77,7.82,2.67,11.87,3.06c4.86,0.47,9.78,0.64,14.67,0.58
				c5.11-0.06,10.22-0.55,15.33-0.86c1.9-0.12,3.8-0.28,6.48-0.48c-3.24,6.02-6.04,11.2-9.08,16.85c1.6,0,2.16,0.11,2.22-0.02
				c3.36-6.99,10.1-6,15.91-6.27c9.6-0.45,19.23-0.12,28.88-0.12c-0.4,1.32-1.12,3.64-1.83,5.96c5.6-2.31,5.49-7.34,6.58-12.19
				c5.67,2.72,7.53-0.67,8.73-5.09c1.34-4.95,5.65-4.6,9.3-4.87c7.62-0.57,15.26-0.72,22.89-1.15c1.02-0.06,2-0.69,3.01-1.06
				c-0.07-0.43-0.13-0.86-0.2-1.29c-1.91,0.23-3.8,0.56-5.72,0.68c-8.78,0.53-17.57,0.9-26.34,1.58c-1.49,0.12-3.85,1.23-4.16,2.34
				c-1.44,5.12-3.46,8.77-10.13,6.03c0.38,6.54-3.72,6.31-7.82,6.44c-11.08,0.37-22.16,0.91-33.91,0.36c1.65-0.83,3.23-2.18,4.95-2.4
				c10.71-1.39,21.45-2.52,32.17-3.77c0.75-0.09,1.48-0.46,2.21-0.69c-0.05-0.5-0.1-0.99-0.16-1.49c-11.4,1.13-22.81,2.26-34.21,3.39
				c-0.12-0.63-0.23-1.27-0.35-1.9c1.88-0.26,3.77-0.47,5.64-0.81c1.76-0.32,3.94-0.24,5.16-1.26c2.86-2.41,5.25-5.37,7.75-8.02
				c7.72,3.32,15.18,1.95,22.64,1.07c0.01-0.59,0.02-1.18,0.04-1.77c-4.67,0-9.34,0-14.01,0c-0.09-0.58-0.19-1.15-0.28-1.73
				c5.47-5.07,13.17-2.3,19.54-4.96c-3.79,0-7.58,0-11.36,0c-0.01-0.62-0.03-1.24-0.04-1.86c12.01-2.27,24.3,3.03,36.31-0.74
				c-10.94-0.5-21.88-1-33.24-1.52c1.19-2.87,2.7-4.97,5.89-3.5c3.95,1.83,6.72,0.02,9.47-2.33c4.05-3.45,7.73-7.47,12.2-10.23
				c2.83-1.75,6.78-1.92,10.28-2.21c0.71-0.06,2.23,2.43,2.34,3.83c0.2,2.44-0.4,4.94-0.68,7.42c-0.46,4.04,0.99,7.14,4.47,9.29
				c2.99,1.85,3.68,4.39,3.57,7.99c-0.17,5.93,0.73,11.88,1.17,17.83c0.16,2.16,0.71,4.25-2.77,4.57c-7.65,0.7-15.26,1.79-22.77,3.76
				c-0.11,0.13-0.3,0.25-0.31,0.38c-0.48,11.1-9.24,6.64-14.88,7.79c-16.23,3.33-32.51,1.04-48.8,0.3
				c-7.61-0.34-15.28-0.05-22.89,0.44c-3.06,0.2-6.75,0.89-8.91,2.78c-8.48,7.41-18.68,6.82-28.75,7.24
				c-7.81,0.33-15.64,0.52-23.41,1.23c-1.69,0.16-4.39,2.09-4.61,3.53c-1.73,10.9-8.8,18.48-15.81,25.98
				c-7.22,7.72-15.81,11.39-26.42,6.9c-3.87-1.64-5.73,0.91-7.52,3.8c-3.16,5.1-6.49,10.1-9.75,15.14c-0.67-0.15-1.33-0.29-2-0.44
				c-1.47-3.92-2.94-7.84-4.4-11.76c-2.2-5.87-4.65-7.68-10.95-7.07c-5.05,0.49-9.96,1.21-14.25-2.47c-0.55-0.47-1.59-0.57-2.38-0.52
				c-9.46,0.68-18.93,1.41-28.38,2.17c-0.37,0.03-0.71,0.52-1.53,1.16c6.74,0,12.93,0.29,19.09-0.09c4.88-0.3,9.39-0.54,14.01,1.99
				c2.34,1.28,5.8,0.56,8.76,0.65c2.39,0.07,4.79,0.02,7.26,0.76c-9.23,6.94-20.89,5.08-30.62,9.85c-2.14-8.74-4.26-10.2-12.82-8.07
				c-3.92,0.98-6.24-0.25-7.64-3.51c-1.78-4.16-2.57-8.88-4.93-12.63c-1.63-2.59-4.97-4.67-7.98-5.68c-2.66-0.89-5.97,0.44-8.9,0.04
				c-4.9-0.66-9.71-1.92-14.56-2.93c0.08-0.71,0.16-1.41,0.24-2.12c9.91,0,19.82,0.12,29.72-0.03c10.69-0.16,21.14,2.9,32.12,0.75
				c7.65-1.5,15.89,0.09,23.87,0.16c2.75,0.02,5.51-0.37,9.67-0.67c-2.72,2.26-4.38,3.63-6.96,5.77c10.08,2.25,4.17,11.09,8.03,16.06
				c-0.37-5.42,3.99-11.56-2.81-15.39c-0.21-4,8.65-3.05,3.67-9.41c5.2-1.33,10.27-2.16,14.97-3.95c6.33-2.42,12.41-5.52,18.52-8.5
				c0.83-0.4,1.66-1.82,1.65-2.76c-0.09-6.02,4.83-5.98,8.61-7.05c0.66-0.18,2.6,1.73,2.79,2.86c0.42,2.6-0.07,5.33,0.15,7.97
				c0.57,6.82,5.48,11.75,12.85,11.74c-2.23-1.47-3.41-2.53-4.79-3.1c-4.26-1.77-6.08-4.83-5.69-9.41c0.18-2.15,0.07-4.33-0.02-6.49
				c-0.16-3.8-4.38-9.21-8.08-9.29c-13.95-0.32-27.91-0.34-41.86-0.21c-2.2,0.02-4.55,1.1-6.53,2.21c-1.28,0.72-2.54,2.31-2.79,3.69
				c-0.13,0.72,1.88,1.95,3.07,2.74c0.93,0.62,2.07,0.91,4.62,1.98c-3.71,0.6-6.09,0.73-8.31,1.38
				c-11.31,3.28-22.62,6.57-33.83,10.17c-1.81,0.58-3.49,2.52-4.54,4.25c-2.07,3.38-4.24,3.4-7.45,1.6
				c-3.41-1.92-7.19-3.17-10.72-4.89c-1.44-0.7-2.88-1.67-3.9-2.87c-2.25-2.66-4.57-4.48-8.43-4.33c-1.56,0.06-3.58-1.54-4.72-2.94
				c-1.66-2.03-2.41-4.83-4.14-6.79c-1.46-1.65-3.61-3.17-5.7-3.66c-4.12-0.96-5.9-3.22-4.98-7.33c0.47-2.08,0.58-4.23,0.89-6.64
				c8.77-0.93,17.66-1.88,26.55-2.82c-0.06-0.56-0.11-1.12-0.17-1.68c-8.73,0.56-17.46,1.11-26.18,1.67
				c-0.08-0.69-0.15-1.37-0.23-2.06c7.33-4.54,16.3-1.78,24.07-5.68c-4.06,0.21-8.11,0.36-12.16,0.65c-1.99,0.14-3.97,0.8-5.93,0.7
				c-1.04-0.05-2.77-1.13-2.84-1.88c-0.1-0.97,0.96-2.29,1.86-3.07c0.67-0.58,1.88-0.72,2.85-0.73c10.44-0.05,20.88-0.03,32.2-0.03
				c-6.5,12.64-12.92,25.13-19.81,38.53c6.61-5.42,16.1-3.83,20.34-12.5c1.08-2.22,5.94-3.46,9.13-3.62
				c9.31-0.46,18.66-0.12,27.99-0.14c1.87,0,3.73-0.22,5.6-0.34c-0.02-0.54-0.03-1.07-0.05-1.61c-2.51,0-5.02-0.01-7.52,0
				c-7.17,0.02-14.35-0.25-21.5,0.16c-5.59,0.32-7.17-2.65-7.81-7.89c18.14-0.68,36.2,1.69,53.84-2.23c-0.06-0.55-0.12-1.1-0.17-1.65
				c-2.88,0.54-5.75,1.52-8.63,1.56c-14.5,0.19-29,0.18-43.5,0.06c-1.08-0.01-2.86-1.24-3.1-2.21c-0.74-2.9-1.58-6.09-1-8.88
				c0.39-1.85,3.15-4.54,4.86-4.55c9.09-0.07,18.19,0.6,27.29,1.01c0.17,0.01,0.36-0.03,0.49,0.04c9.22,4.97,17.33,1.23,25.34-3.22
				c3.18-1.77,4.73-0.56,5.97,2.61c0.88,2.24,2.66,4.12,4.03,6.16c-3.09,2.61-6.18,5.23-2.43,9.58c-7.97,2.64-10.28,5.71-8.03,9.53
				c6.92-9.45,17.8-10.4,28.43-11.86c-0.11-0.42-0.23-0.85-0.34-1.27c-3.32,0-6.64,0-9.96,0c-0.01-0.28-0.02-0.56-0.02-0.84
				c4.33-0.67,8.66-1.33,12.99-2c0.01-0.28,0.02-0.55,0.03-0.83c-3.93,0-7.86,0-12.37,0c6.46-6.78,6.44-6.73,14.34-3.68
				c1.45,0.56,3.1,0.61,4.66,0.89c0.11-0.48,0.23-0.97,0.34-1.45c-3.9-1.61-7.79-3.21-11.75-4.84c0.9-3.44,2.24-6.87,2.61-10.4
				c0.41-4.02,1.65-5.45,6.07-5.65c14.92-0.68,30.12,4.48,44.79-2.19c2.93-1.33,3.94,0.43,3.99,3.55c0.07,5.05,5.29,12.4,9.4,13.44
				c-1.37-2.1-2.67-3.78-3.64-5.64c-1.28-2.48-2.88-5-3.33-7.68c-1.16-6.93-1.9-7.38-8.92-6.84c-7.92,0.61-15.9,0.66-23.86,0.62
				c-5.46-0.03-10.94-0.47-16.37-1.04c-5.58-0.58-8.35,2.32-9.72,7.16c-0.54,1.9-0.67,3.9-1.09,5.84
				C518.49,198.01,513.87,207.44,504.26,205.84z M744.05,205.9c6.93-1.58,8.22-0.55,8.92,6.78c0.3,3.1,0.88,6.17,1.33,9.25
				c0.66-0.11,1.32-0.23,1.98-0.34c-0.42-5.85-0.06-11.9-3.56-17.16c-0.71-1.07-3.34-1.8-4.65-1.37c-3.4,1.13-5-1.06-4.81-3.2
				c0.82-9.09-7.33-9.93-12.25-13.61c0.76,1.79,2.02,3.33,3.62,3.92c3.75,1.38,5.5,3.68,5.79,7.74c0.18,2.51,1.55,4.93,2.39,7.39
				c-1.7-1.28-3.41-2.56-5.11-3.83c-0.39,0.46-0.78,0.92-1.17,1.38c2.69,2.25,6.21,4.03,7.85,6.89c1.77,3.08,1.78,7.17,2.55,10.81
				c0.4-0.1,0.8-0.2,1.2-0.3C747.11,215.38,749.47,209.65,744.05,205.9z M974.86,154.37c0-0.64-0.01-1.28-0.01-1.92
				c-1.35-0.08-2.7-0.3-4.04-0.22c-11.57,0.64-23.13,1.42-34.7,1.93c-2.55,0.11-3.81,0.94-3.72,3.39c0.18,4.87-0.98,9.16-4.66,12.6
				c-0.41,0.39-0.29,1.35-0.48,2.42c4.92-0.98,6.51-4.22,6.56-8.04c0.14-9.18,2.53-8.47,10.06-8.55
				C954.21,155.87,964.53,154.94,974.86,154.37z M562.89,211.94c-10.13,1.1-20.25,2.2-30.38,3.3c0.05,0.45,0.09,0.9,0.14,1.34
				C543.19,220.07,553.33,219.09,562.89,211.94z M489.8,208.61c-13.69,0-28.16,0-42.63,0C453.65,212.25,486.33,211.07,489.8,208.61z
				M439.9,232.95c-0.03,0.36-0.07,0.72-0.1,1.09c6.07,0,12.13,0,18.2,0c0.02-0.36,0.04-0.72,0.06-1.09
				C452,232.95,445.95,232.95,439.9,232.95z M602.03,216.41c0.01-0.36,0.02-0.72,0.03-1.08c-4.86,0-9.73,0-14.59,0
				c-0.01,0.36-0.02,0.72-0.03,1.08C592.31,216.41,597.17,216.41,602.03,216.41z M923.44,150.24c0.03,0.64,0.06,1.27,0.09,1.91
				c4.41-0.22,8.83-0.44,13.24-0.66c-0.02-0.41-0.05-0.83-0.07-1.24C932.27,150.24,927.86,150.24,923.44,150.24z"/>
			<path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M642.15,721.84c-4.95,3.78-5.25,7.13-3.59,11.19c0.58,1.43,0.38,3.88-0.51,5.1
				c-5.3,7.27-5.59,14.32,0,21.51c0.48,0.61,0.62,1.48,1.44,3.51c-8.92,0.63-17.23,1.49-25.55,1.69c-4.71,0.11-9.13-0.12-13.27,2.97
				c-2.29,1.71-5.47,2.25-8.29,3.2c-2.46,0.83-4.74,1.59-4.58,4.96c0.03,0.71-1.24,1.6-2.06,2.21c-3.12,2.31-6.26,4.61-9.49,6.75
				c-1.3,0.86-2.81,1.58-4.33,1.9c-1.77,0.38-3.64,0.23-5.46,0.36c-2.2,0.16-4.39,0.39-6.58,0.59c0-3.24,0.31-6.51-0.13-9.69
				c-0.23-1.66-1.4-3.85-2.78-4.54c-1.6-0.8-3.9-0.11-5.88-0.29c-2.01-0.18-5.36-0.15-5.7-1.09c-0.82-2.27-0.65-5.15-0.06-7.58
				c0.44-1.8,2.17-3.29,3.3-4.93c3.66-5.27,3.14-6.84-3.28-8.88c0.59-1,1.43-1.87,1.6-2.85c0.31-1.81,0.83-4.01,0.07-5.4
				c-0.48-0.89-3.24-1.13-4.75-0.76c-6.61,1.61-13.21,3.36-19.67,5.48c-2.41,0.79-5.75,1.72-3.97,6.03c0.23,0.55-2.79,3.37-4.52,3.61
				c-3.83,0.54-6.27,1.13-4.39,5.82c0.3,0.75-0.74,2.54-1.63,3.18c-5.13,3.66-10.41,7.11-15.63,10.65c-3.83,2.59-9.24,4.2-6.75,10.78
				c0.09,0.24-0.1,0.67-0.28,0.92c-10.41,14.07-21.21,27.29-40.73,28.89c-8.19,0.67-16.23,3.15-24.35,4.8
				c-0.75,0.15-1.51,0.23-2.73,0.41c4.08-8.99,7.5-17.69,11.91-25.87c3.66-6.79,3.92-13.98,4.63-21.22
				c1.04-10.55,1.65-21.16,3.16-31.64c0.63-4.35,3.02-8.48,4.85-12.6c0.39-0.88,1.78-1.47,2.83-1.88c9.89-3.87,19.88-7.5,29.7-11.54
				c8.3-3.42,10.82-10.12,5.84-15.95c-1.98-2.32-5.4-3.69-8.43-4.75c-3.1-1.09-6.53-1.2-9.79-1.87c-5.36-1.11-10.04-3.36-13.52-7.86
				c-1.29-1.67-3.08-3.02-4.84-4.23c-4.44-3.05-4.8-7.57-0.35-10.86c2.81-2.07,4.42-3.72,0.74-6.19c3.23-2.9,6.28-5.64,10.46-9.38
				c-3.43-0.86-5.1-1.28-6.77-1.7c1.01-5.57,5.26-9.88,10.53-10.71c12.67-1.99,19.01-8.48,20.71-21.1c0.22-1.64,0.65-3.25,1.09-4.85
				c1.41-5.18,2.87-10.35,4.28-15.42c-0.15-0.13,0.11,0,0.21,0.2c1.29,2.43,2.54,6.91,3.83,6.93c4.08,0.04,3.24-4.12,3.56-6.89
				c0.64-5.67-0.79-10.52-4.34-15.34c-2.32-3.15-3.3-7.91-3.37-11.98c-0.13-7.14,2.95-14.56-2.88-20.89
				c2.29-6.41-5.94-11.18-2.79-17.84c0.44-0.94-0.34-2.58-0.8-3.8c-1.63-4.33-0.42-6.52,4.17-6.79c2.12-0.13,4.28,0.45,6.43,0.48
				c5.07,0.08,10.14,0.03,15.47,0.03c0-2.46,0-3.87,0-4.52c9.87-3.16,19.28-6.17,29.88-9.57c0.22,3.88,0.9,8.21,0.62,12.47
				c-0.7,10.77-2.1,21.49-2.74,32.26c-0.85,14.25,4.06,27.45,7.81,40.92c2.42,8.71,3.68,17.8,4.8,26.8
				c1.4,11.22,4.08,21.55,13.62,28.89c1.64,1.26,3.19,3.22,3.79,5.16c1.94,6.3,6.72,7.86,12.42,9.05c2.77,0.58,6.44,2.44,7.48,4.73
				c4.25,9.37,11.45,15.67,19.36,21.69c3.87,2.94,7.12,6.87,10.09,10.77c2.61,3.43,4.97,6.32,9.8,4.54c0.45-0.17,1.12-0.31,1.46-0.1
				C628.81,713.69,635.07,717.52,642.15,721.84z M556.69,666.39c-3.95-4.81-1.56-13.21-9.02-16.11c-0.75-0.29-0.96-1.98-1.41-3.02
				c-2.11-4.89-4.25-9.76-6.32-14.67c-1.14-2.7-2.34-5.4-3.19-8.2c-1.67-5.53-3.08-11.15-4.71-16.69c-0.26-0.87-1.15-1.55-2.49-3.26
				C534.75,627.78,543.07,648.31,556.69,666.39z M526.76,677.07c-1.13,5.48-2.14,9.8-2.83,14.17c-0.14,0.88,0.86,2.72,1.41,2.75
				c1.08,0.06,2.5-0.57,3.26-1.4C532.12,688.75,531.77,684.05,526.76,677.07z M572.61,737.92c-4.27,2.15-5.28,4.81-2.82,7.67
				c0.84,0.97,2.69,1.06,4.07,1.56c0.34-1.29,1.14-2.67,0.91-3.85C574.42,741.43,573.37,739.71,572.61,737.92z M536.49,691
				c-0.53,0-1.06,0.01-1.59,0.01c-0.67,1.27-1.97,2.6-1.84,3.78c0.16,1.46,1.5,2.79,2.33,4.18c1.14-1.03,3.2-2.04,3.22-3.09
				C538.64,694.27,537.27,692.63,536.49,691z M492.3,530.02c-2.03,3.7-1.68,6.53,1.1,7.93c0.65,0.33,1.68-0.1,2.54-0.18
				c-0.78-2.42-1.52-4.84-2.37-7.23C493.45,530.24,492.72,530.18,492.3,530.02z"/>
			<path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M551.07,557.19c-0.67,1.48-1.33,2.94-2.18,4.82c8.94,5.12,18.08,5.03,27.83,2.2c0.53,1.9,1,3.56,1.77,6.3
				c2.51-1.39,4.76-2.63,7.5-4.15c0.85,0.52,2.22,1.35,3.82,2.32c1.19-1.06,2.37-2.11,3.71-3.3c2.53,2.09,4.86,5.93,8.49,0.05
				c4.16,12.84,6.68,25.34,12.2,36.34c5.52,11,5.4,24.51,15.81,34.69c-7.04-20.35-18.53-38.15-15.59-60.88
				c1.83,3.22,3.38,5.74,4.73,8.36c2.81,5.41,5.5,10.88,8.26,16.32c0.3,0.59,0.94,1.09,1.04,1.69c1.62,10.48,10.07,16.87,15.12,25.28
				c1.18,1.96,3.09,3.48,4.4,5.38c2.2,3.22,4.18,6.59,6.27,9.88c0.88,1.4,1.62,2.94,2.74,4.12c4.8,5.05,9.71,9.98,15.51,15.9
				c-1.78-3.09-2.91-5.56-4.51-7.68c-1.69-2.24-3.79-4.18-5.76-6.2c-1.28-1.31-2.93-2.34-3.94-3.81c-4.84-7.04-9.53-14.19-14.27-21.3
				c-0.37-0.55-0.59-1.22-1.02-1.71c-10.79-12.5-17.06-27.47-23.44-42.41c-1.1-2.56-3.08-4.73-4.54-7.15
				c-2.54-4.21-1.98-7.49,2.72-8.45c6.53-1.33,13.48-1.97,20.08-1.3c16.14,1.64,28.86,11.27,41.96,19.74
				c4.02,2.6,3.79,4.64-0.02,7.68c-4.37,3.49-4.73,8.02-0.81,12.18c7.01,7.46,14.01,14.93,21.23,22.18c1.88,1.89,4.55,2.98,6.85,4.44
				c0.47-0.38,0.94-0.75,1.42-1.13c-0.94-2.22-1.89-4.44-2.84-6.68c0.16-0.09,0.59-0.41,0.68-0.34c5.37,4.6,8.02-0.97,11.71-2.97
				c1.43-0.77,3.95-1.49,4.93-0.78c3.98,2.88,7.95,5.97,11.17,9.64c4.39,5.01,7.62,10.65,16.11,9.22c3.13-0.53,6.84,2.65,10.35,3.97
				c3.04,1.14,6.16,2.06,9.68,3.21c-0.28,0.56-0.56,1.92-1.39,2.66c-2.68,2.4-3.42,4.97-2.19,8.46c1.13,3.2-0.12,5.57-3.17,7.49
				c-5.17,3.26-9.96,7.13-15.08,10.47c-2.17,1.42-4.82,2.08-7.17,3.25c-2.64,1.32-5.97,2.21-7.63,4.35
				c-6.36,8.2-12.41,16.67-18.1,25.34c-4.36,6.64-8.05,13.72-11.92,20.67c-1.36,2.44-2.92,4.95-3.47,7.62
				c-1.79,8.79-8.39,13.93-14.12,20.39c-0.84-4.78-1.54-8.8-2.24-12.81c0.56-0.25,1.11-0.5,1.67-0.75c-2.05-1.24-4.1-2.48-6.14-3.72
				c0.75-2.34,1.45-4.5,2.24-6.99c2.87,0.3,6.75-2.54,7.78,1.93c2.49-1.29,4.7-2.48,6.96-3.58c2.93-1.43,5.18-2.73,2.99-6.79
				c-2.15-3.98-2.8-9.06-8.05-10.38c-1.84-0.46-4.42-0.36-5.89,0.63c-3.39,2.29-6.3,5.28-9.6,8.15c-5.45-1.8-7.45-5.71-7.87-12.27
				c-0.18-2.81-2.53-7.15-4.78-7.82c-5.21-1.56-11.2-5.96-16.07,0.61c-0.61-0.2-1.15-0.25-1.18-0.41c-1.7-8.18-9.95-9.82-15.06-14.38
				c-11.59-10.36-24.17-19.65-36.69-28.91c-4.06-3-9.3-4.35-13.78-6.84c-8.51-4.73-17.24-9.21-25.16-14.81
				c-6.32-4.47-7.39-12.27-8.94-19.48c-1.78-8.25-4.02-16.39-5.83-24.63c-2.14-9.72-4.48-19.43-5.83-29.27
				c-0.65-4.79,0.83-9.86,1.24-14.82c0.76-9.28,1.51-18.56,2.09-27.85c0.1-1.57-0.5-3.28-1.1-4.79c-2.42-6.15-2.3-6.77,3.23-9.95
				c3.84-2.21,7.76-4.27,13.12-7.21c-0.81,6.17-1.23,11.61-2.28,16.93c-1.89,9.59-4.4,19.07-6.16,28.68
				c-0.7,3.81-0.59,7.97,0.21,11.74C545.16,555.16,548.53,555.88,551.07,557.19z M636.6,675.17c-1.15,4.5-0.47,7.82,2.23,9.93
				c2.2,1.72,4.4,2.08,5.29-1.28C645.21,679.64,641.26,675.23,636.6,675.17z M676.24,675.28c-0.36,6.5,2.08,11.12,6.64,12.99
				c2.93,1.21,5.17-0.22,4.92-3.13C687.47,681.23,680.73,675.35,676.24,675.28z"/>
			<path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M986.86,538.57c-1.18-0.34-2.54-0.73-4.96-1.43c9.1-7.47,15.99-15.84,16.61-29.09
				c0.29-0.31,1.59-1.72,3.2-3.46c-1.65-0.99-2.87-1.71-4.49-2.68c10.63-8.54,21.14-17,31.65-25.44c3.06,6.48,6.55,13.86,10.19,21.56
				c-2.93,7.81-10.28,9.26-17.63,10.83c-2.91,0.62-6.01,1.05-8.54,2.43c-1.73,0.95-3.7,3.45-3.63,5.17c0.07,1.78,2.29,3.71,3.96,5.1
				c1.17,0.98,2.99,1.18,6.03,2.27c-2.96,1.91-4.81,3.28-6.81,4.36c-3.03,1.62-7.56,1.72-6.46,7.17c0.11,0.55-1.07,1.64-1.87,2.05
				c-4.36,2.19-3.9,5.44-1.78,8.74c1.13,1.75,2.72,3.9,4.51,4.39c6.95,1.91,13.59,4.84,21.32,3.82c5.43-0.72,11.19,0.54,16.73,1.39
				c7.06,1.08,11.98-3.31,17.27-6.47c1.69-1.01,2.16-4.04,3.65-7.07c-0.03-0.07-0.47-1.3-1.39-3.85c2.46,0.41,4.97,0.05,5.81,1.07
				c8.93,10.86,15.02,22.74,12.53,37.52c-1.32,7.83-2.26,15.72-3.97,23.78c-2.16-2.74-8.22-2.58-5.41-8.89
				c0.44-0.99-3.09-3.74-4.98-5.9c2.13-4.2,1.73-7.97-1.63-12.3c-4.14-5.32-6.98-11.66-10.52-17.79c-0.45,8.39,4.96,14.43,9.58,21.32
				c-1.7,0.36-3.03,0.63-4.52,0.94c2.06,3.53,3.76,7.04,6.02,10.13c1.25,1.71,3.35,2.55,1.92,5.35c-0.41,0.8,0.7,2.79,1.61,3.77
				c4.52,4.86,9.21,9.56,13.8,14.35c0.64,0.67,1.01,1.58,1.96,3.13c-3.86,0-7.01,0-11,0c0.77,1.67,1.45,2.69,1.71,3.82
				c0.26,1.13,0.16,2.35,0.13,3.53c-0.02,0.95-0.37,1.93-0.23,2.84c0.6,3.9,1.06,7.85,2.13,11.63c0.48,1.72,2.07,3.28,3.49,4.53
				c1.84,1.62,4.01,2.85,7.09,4.97c-3.1,0.79-5.12,1.31-8.08,2.07c1.48,1.73,2.47,2.92,3.49,4.08c0.53,0.61,1.68,1.33,1.58,1.76
				c-1.95,7.97,5.29,9.52,9.21,13.36c0.59,0.58,1.4,0.94,1.97,1.53c1.41,1.51,4.17,4,3.85,4.49c-1.3,2.05-3.53,3.5-5.43,5.16
				c-0.36,0.31-0.76,0.58-1.39,1.05c0.73,1.45,1.45,2.87,2.29,4.55c-1.51,0-2.85,0-5.7,0c2.6,2.33,4.27,3.83,5.95,5.32
				c-0.08,0.44-0.16,0.87-0.24,1.31c-3.76-0.24-7.51-0.47-11.74-0.74c0.46,2.12,0.9,4.11,1.48,6.78c-3.18-2.69-5.85-4.96-9.34-7.91
				c-0.1,2.23-0.16,3.38-0.22,4.77c-1-0.28-2.09-0.58-3.71-1.04c0,2.75,0,4.98,0,7.22c-0.4,0.28-0.79,0.56-1.19,0.84
				c-2.81-2.67-5.63-5.34-8.96-8.5c-0.51,2.53-0.9,4.49-1.41,7.02c-2.31-2.58-4.27-4.77-6.63-7.42c-0.95,2.13-1.53,3.44-2.43,5.47
				c-1.1-1.76-1.97-3.14-2.96-4.72c-1.78,0.77-3.59,1.55-5.31,2.3c-2.22-0.18-3.07-6.15-6.49-1.63c-0.7-1.05-1.6-2.4-2.5-3.74
				c-0.41,0.11-0.82,0.21-1.22,0.32c0.14,2.95,0.28,5.9,0.42,8.84c-0.59,0.25-1.18,0.51-1.77,0.76c-3.39-4.29-6.77-8.59-10.87-13.78
				c-0.66,3.78-1.15,6.64-1.65,9.51c-0.49,0.2-0.98,0.4-1.48,0.6c-1.86-2.42-3.72-4.85-5.94-7.73c-1.17,1.76-2.23,3.36-3.85,5.8
				c-1.39-3.27-2.43-5.73-3.82-9.01c-2.03,2.81-3.66,5.06-5.63,7.8c-0.22-3.07-0.4-5.76-0.59-8.45c-0.4-0.07-0.81-0.14-1.21-0.22
				c-1.06,1.35-2.11,2.71-3.17,4.07c-0.31-0.26-0.68-0.41-0.75-0.64c-0.48-1.8-0.9-3.61-1.34-5.41c-1.54,1.29-3.25,2.42-4.57,3.9
				c-2.36,2.63-4.49,5.46-6.72,8.21c-0.45-0.22-0.9-0.45-1.35-0.67c0.15-1.77,0.29-3.54,0.52-6.31c-3.89,2.05-7.14,3.77-11.36,6
				c0.35-2.85,0.54-4.36,0.8-6.53c-4.12,1.35-7.81,2.55-11.5,3.76c-0.32-0.41-0.65-0.82-0.97-1.23c1.09-2.24,2.18-4.47,3.99-8.19
				c-13.76,4.11-26.43,8.55-40.87,4.4c3.71-3.11,6.54-5.47,9.36-7.83c-0.1-0.42-0.2-0.85-0.3-1.27c-2.9-0.28-5.79-0.56-8.69-0.84
				c-0.12-0.41-0.24-0.82-0.35-1.23c2.21-1.43,4.43-2.86,6.6-4.26c5.79,6.55,13.57,6.62,26.79,0.54c3.1,5.96,7.02,7.42,19.37-0.39
				c3.91,6.86,7.17,1.32,11.08-0.97c5.3,6.68,7.05-0.38,10.4-3.06c1.2,0.62,2.46,1.28,4.14,2.15c1.86-2.71,3.69-5.38,5.6-8.15
				c7.29,12.81,10.94,9.02,15.31,1.22c2.71,1.5,5.4,2.98,8.09,4.47c0.78-1.82,1.55-3.64,2.39-5.61c1.34,0.19,3.36,0.49,6.38,0.92
				c-3.85-3.31-6.46-5.55-10.24-8.8c0.26,4.32,0.44,7.22,0.62,10.12c-0.52,0.22-1.04,0.44-1.56,0.66c-1.85-3.31-3.7-6.63-6.07-10.89
				c-0.7,3.07-0.81,5.4-1.78,7.29c-1.22,2.37-3.16,4.38-4.78,6.54c-1.71-2.05-3.57-4-5.08-6.19c-0.88-1.28-1.2-2.95-3.23-4.96
				c-1.9,3.06-3.8,6.12-6.01,9.69c-0.59-1.5-1.06-2.68-1.84-4.65c-2.75,3.28-5.13,6.13-7.86,9.37c-0.91-1.68-1.63-3-2.62-4.81
				c-2.86,2.25-5.49,4.31-8.64,6.79c-0.6-2.26-1-3.75-1.5-5.62c-3.54,2.16-6.63,4.38-10.01,5.98c-2.07,0.98-4.6,0.98-6.93,1.42
				c0.17-1.97,0.34-3.94,0.59-6.81c-8.62,2.26-16.26,9.47-26.25,5.27c8.94-3.89,18.73-6.38,25.08-15.69
				c-3.72,0.5-6.87,0.92-10.02,1.34c-0.32-0.52-0.63-1.04-0.95-1.56c10.97-7.48,19.9-16.57,23.61-29.96
				c-1.06,1.24-2.5,2.32-3.13,3.76c-4.57,10.33-10.9,18.97-21.51,23.86c-0.96,0.44-1.74,1.88-1.97,2.99
				c-0.09,0.43,1.52,1.37,2.48,1.81c1.27,0.58,2.67,0.9,4.01,1.34c0.11,0.47,0.23,0.94,0.34,1.4c-6.65,2.99-13.29,5.97-20.67,9.29
				c0.56-2.56,1.44-4.56,0.98-4.94c-0.97-0.81-2.65-0.79-4.04-1.08c-1.93-0.4-3.86-0.76-5.79-1.14c1-2.04,1.51-4.65,3.1-6.01
				c3.12-2.68,7.05-4.42,10.26-7.02c4.19-3.4,3.35-6.68-2.73-8.7c7.85-6.84,15.35-13.38,23.42-20.43c-6.2-2.8-11.12,1.71-17.66,1.01
				c8.6-7.18,17.83-12.28,24.4-21.89c-2.9,0.23-4.38,0.35-7.31,0.58c2.61-2.81,4.42-4.61,6.04-6.58c0.79-0.96,1.19-2.23,1.77-3.36
				c-1.28-0.51-2.52-1.28-3.85-1.47c-1.77-0.26-3.6-0.06-6.62-0.06c6.86-8.6,12.6-16.87,19.46-24.08
				C979.85,557.45,983.71,548.64,986.86,538.57z M1071.15,637.3c-8.56-3.66-7.72-11.53-10.42-18.86c-0.93,3.06-1.38,4.53-2.01,6.62
				c-1.58-2.04-2.78-3.6-4.49-5.83c-0.75,2.05-1.21,3.31-1.66,4.52c-1.03-0.49-2.06-0.98-3.23-1.53c-0.32,1.98-0.56,3.44-0.9,5.46
				c-1.71-2.96-3.02-5.24-4.34-7.51c-0.44,0.14-0.88,0.27-1.33,0.41c0.28,4.55,0.55,9.1,0.83,13.64c-0.64,0.17-1.28,0.33-1.92,0.5
				c-2.11-4.58-4.23-9.15-6.34-13.73c-0.52,0.15-1.04,0.3-1.55,0.45c-0.51,4.08-1.02,8.16-1.53,12.24c-0.4,0.03-0.8,0.06-1.2,0.08
				c-1.33-4.38-2.66-8.76-3.99-13.14c-0.65,0.18-1.3,0.37-1.95,0.55c1.68,5.27,3.34,10.56,5.23,15.76c0.05,0.13,3.42-0.24,3.92-1.13
				c0.97-1.72,0.99-3.97,1.3-5.48c2.68,2.31,5.34,4.59,7.99,6.88c1.11-2.05,2.22-4.11,3.39-6.28c0.57,0.3,1.68,0.87,2.39,1.24
				c2.49-2.19,4.67-4.1,7.97-6.99c2.36,3.27,4.28,6.42,6.69,9.12c1.92,2.15,4.46,3.75,6.72,5.59c1.02-1.45,2.02-2.91,3.07-4.33
				c0.18-0.24,0.52-0.46,0.82-0.5c3.91-0.64,2.9-3.22,2.4-5.37c-2.3-0.68-4.32-1.27-6.79-2
				C1070.51,630.69,1070.78,633.53,1071.15,637.3z M1024.83,602.25c3.25-1.41,6.24-2.71,9.45-4.1c-0.56-1.9-1.26-4.25-2.09-7.05
				c-1.92,3.08-3.4,5.47-4.88,7.85c-0.39-0.23-0.78-0.45-1.18-0.68c0.63-1.79,1.25-3.59,2.38-6.8c-5.48,2.92-9.51,5.06-13.54,7.2
				c-0.56-0.71-1.12-1.42-1.67-2.14c2.31-1.79,5.05-3.24,6.8-5.47c1.76-2.24,2.54-5.25,3.74-7.92c-4.55,4.25-8.2,8.81-11.62,13.52
				c-0.63,0.87-0.94,2.94-0.41,3.47c0.73,0.73,2.57,0.99,3.63,0.61c2.59-0.91,5.01-2.31,7.85-3.68
				C1023.83,598.92,1024.28,600.4,1024.83,602.25z M1000.42,561.67c-0.41-0.43-0.82-0.85-1.23-1.28c-1.71,1.6-3.86,2.94-5.03,4.87
				c-1.31,2.16-1.73,4.86-2.53,7.33c2.34-0.76,4.68-1.53,7.02-2.27c0.29-0.09,0.64-0.01,1.07-0.01c0.24,2.84,0.22,6.02,4.44,5.61
				c2.98-0.29,5.78-4.42,5.31-8.13c-2.06,2.23-3.97,4.29-5.89,6.35c-0.51-0.31-1.02-0.62-1.53-0.92c0.71-2.8,1.41-5.6,2.12-8.4
				c-0.33-0.25-0.66-0.5-0.99-0.75c-2.29,1.48-4.58,2.95-6.88,4.43c-0.12-0.17-0.24-0.34-0.37-0.51
				C997.44,565.87,998.93,563.77,1000.42,561.67z M1032.25,499.15c-0.86-1.45-1.56-2.63-2.7-4.55c-1.13,2.89-1.92,4.91-2.71,6.92
				c-0.26-0.03-0.53-0.05-0.79-0.08c-0.3-2.23-0.6-4.45-0.98-7.25c-2.47,3.64-2.35,9.35,0.15,10.1c1.53,0.46,3.66-1.12,5.64-1.83
				c1.65-0.73,3.36-1.35,4.9-2.27c0.33-0.2,0.05-1.43,0.05-2.68C1034.44,498.14,1033.47,498.58,1032.25,499.15z M974.35,605.29
				c-0.5-0.31-1-0.62-1.49-0.93c0.76-2.31,1.51-4.62,2.49-7.59c-4.26,1.75-5.61,4.53-4.75,7.83c0.32,1.23,3.28,3.08,4.01,2.7
				c4.03-2.12,7.46-5.07,7.51-10.62C979.52,599.55,976.94,602.42,974.35,605.29z M1045.22,595.55c-0.34,0.11-0.69,0.23-1.03,0.34
				c-1.27-2.39-2.54-4.77-3.8-7.16c-0.66,0.34-1.32,0.68-1.98,1.02c1.55,3.23,2.95,6.54,4.79,9.6c0.28,0.46,3.21,0.23,3.75-0.52
				c1.79-2.5,0.17-10.09-2.42-12.62C1044.76,589.42,1044.99,592.49,1045.22,595.55z M1067.84,662.8c-0.5,0.15-1,0.3-1.5,0.45
				c0.7,2.06,1.02,4.39,2.24,6.07c0.75,1.03,3.22,1.85,4.28,1.37c1.34-0.61,2.04-2.64,3.01-4.06c-0.35-0.27-0.71-0.54-1.06-0.8
				c-1.31,1.04-2.62,2.08-4.27,3.4C1069.54,666.82,1068.69,664.81,1067.84,662.8z"/>
			<path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M768.83,503.21c-0.08,8,1.76,14.82,8.73,19.95c5.1,3.76,9.03,9.12,13.53,13.71
				c1.48,1.51,2.92,3.53,4.76,4.11c5.08,1.6,7.79,5.4,10.37,9.53c5.84,9.34,12.65,17.34,22,23.93c7.03,4.95,11.84,13.29,18.3,19.56
				c1.14,1.1,0.9,4.79-0.01,6.63c-3.98,8.04-8.05,16.09-12.86,23.65c-3.42,5.38-8.05,9.99-12.72,15.66
				c-3.41-4.28-6.24-7.88-9.14-11.43c-3.24-3.99-6.05-8.53-9.95-11.73c-4.11-3.38-7.28-7-8.87-12c-0.76-2.38-1.42-4.8-2.11-7.2
				c-0.27-0.94-0.2-2.19-0.79-2.79c-4.45-4.46-8.42-9.87-13.74-12.87c-6.32-3.56-10.33-8.58-14.09-14.24
				c-3.24-4.88-6.73-9.23-12.48-11.73c-2.15-0.94-4.16-4.47-4.41-7c-0.37-3.66-0.71-6.71-3.97-9c-0.92-0.65-1.11-2.35-0.73-4.17
				c0.86,0.62,1.96,1.07,2.55,1.89c4.06,5.63,7.86,11.46,12.09,16.96c1.33,1.73,4.34,4.18,5.48,3.69c4.9-2.08,7.04,0.37,9.64,3.76
				c-0.74-1.66-1.06-4.24-2.3-4.81c-6.38-2.96-3.72-8.22-3.24-12.53c0.41-3.69,2.3-7.21,3.51-10.76c5.92,3.06,11.69,5.98,17.39,9.05
				c1.28,0.69,2.89,1.66,3.34,2.87c1.96,5.28,6.04,9.16,8.26,14.6c3.97,9.73,10.42,18.86,21.41,23.11c3.08,1.19,5.61,3.82,8.36,5.84
				c0.79,0.58,1.35,1.72,2.19,1.94c5.34,1.41,7.96,5.67,10.74,9.82c0.62,0.92,1.52,1.66,2.44,2.65c0.58-3.64-4.29-10.3-8.5-12.77
				c-2.34-1.37-4.22-3.51-6.44-5.11c-2.12-1.53-4.25-3.29-6.66-4.12c-10.22-3.53-15.64-11.97-21.21-20.25
				c-0.74-1.1-0.46-2.88-0.7-4.34c-0.21-1.29-0.02-3.2-0.79-3.76c-6.16-4.48-8.55-13.08-17.06-15.12c-3.3-0.79-6.41-2.83-9.25-4.82
				c-1.75-1.23-3.31-3.23-4.12-5.22c-2.7-6.59-4.22-13.75-10.11-18.6c-0.49-0.4-1.02-1.08-1.05-1.66
				c-0.59-9.38-7.48-15.56-11.58-23.16c-1.21-2.23-3.19-6.2-2.43-6.97c2.85-2.83,6.63-4.77,10.25-6.72c0.52-0.28,2.52,1.03,3,2.01
				c3.1,6.44,6.09,12.95,8.83,19.55c0.9,2.16,0.93,4.68,1.36,7.03c0.89-0.23,1.78-0.47,2.66-0.7c-3.96-10.15-6.89-20.66-12.55-30.11
				c7.46-5.58,14.95-10.22,24.48-10.26c2.4-0.01,5.01-0.83,7.14-1.98c3.37-1.82,6.4-2.96,9.87,0.61c-1.67-3.26,0.87-5.15,1.26-7.05
				c1.38-6.71,7.52-8.69,10.58-13.3c6.28-9.46,15.61-13.7,25.78-16.54c3.55-0.99,7.19,0.51,9.03,4.53c1.93,4.23,4.04,8.39,6.15,12.54
				c3.3,6.52,6.01,13.47,10.19,19.38c4.03,5.71,9.61,10.33,14.69,15.62c0.02-0.01-0.67,0.29-0.88,0.79
				c-4.31,10.22-17.49,12.59-20.9,23.66c-0.45,1.47-1.98,2.64-3.11,3.86c-3.85,4.12-8,7.98-11.56,12.34
				c-4.21,5.17-9.47,8.32-15.86,9.55c-6.45,1.24-11.97,3.73-15.82,9.35c-0.8,1.16-2.27,1.85-3.34,2.84c-0.64,0.6-1.25,1.32-1.57,2.11
				c-0.1,0.26,0.86,1.36,1.18,1.3c0.87-0.18,1.77-0.64,2.44-1.23c0.59-0.52,0.91-1.37,1.33-2.08c3.74-6.3,9.52-9.61,16.48-11.03
				c8.03-1.64,14.33-5.78,18.99-12.52c1.22-1.76,2.46-3.61,4.07-4.97c4.59-3.89,9.09-7.69,11.19-13.71c0.58-1.65,2.62-3.01,4.28-4.02
				c4.59-2.79,8.83-5.6,11.21-10.9c1.83-4.06,5.22-3.63,8.21-0.38c6.25,6.79,6.42,14.51,5.82,23.2c-0.58,8.32,1.07,16.8,1.78,25.21
				c0.64,7.44,1.89,14.89,1.76,22.31c-0.06,3.23-2.48,6.75-4.61,9.54c-3.93,5.14-8.44,9.84-12.71,14.73
				c-4.24,4.85-15.93,8.2-21.82,5.86c-6.97-2.77-13.4-6.29-18.77-11.93c-7.53-7.91-15.53-15.4-23.74-22.6
				c-2.45-2.14-6.38-2.5-9.48-4.02c-2.84-1.39-5.81-2.85-8.1-4.95c-4.61-4.23-8.49-9.3-13.27-13.32c-4.3-3.63-7.1-7.79-8.35-13.17
				C769.82,506.67,769.3,504.95,768.83,503.21z M877.26,483.19c-0.26-0.34-0.52-0.68-0.77-1.02c-2.1,1.77-4.57,3.24-6.21,5.37
				c-4.84,6.26-8.7,13.31-15.63,17.75c-1.04,0.67-2.01,1.95-2.36,3.14c-1.79,6.06-5.62,10.26-10.93,13.53
				c-1.42,0.87-1.96,3.16-2.9,4.8c0.49,0.26,0.99,0.51,1.48,0.77C852.38,512.74,864.82,497.97,877.26,483.19z M828,453.1
				c-10.85,12.49-25.12,20.13-39.48,27.63C796.63,480.97,820.38,464.26,828,453.1z M807.53,595.38c-0.34,0.46-0.68,0.92-1.02,1.38
				c9.08,6.88,18.15,13.77,27.23,20.65c0.48-0.63,0.95-1.26,1.43-1.89C825.95,608.81,816.74,602.1,807.53,595.38z M780.44,475.42
				c10.7-0.56,19.17-6.21,27.09-12.93C798.5,466.8,789.47,471.11,780.44,475.42z M845.38,544.38c7.95-1.67,20.26-16.91,23.46-28.38
				C861.69,525.47,857.35,537.2,845.38,544.38z M774.68,566.82c-0.44,0.47-0.88,0.94-1.32,1.41c0.75,0.79,1.43,1.66,2.26,2.36
				c5.5,4.63,10.95,9.32,16.6,13.77c2.06,1.63,4.61,2.65,6.93,3.94c0.22-0.35,0.44-0.71,0.65-1.06
				C791.43,580.44,783.06,573.63,774.68,566.82z M863.54,504.03c-5.67,3.49-12.49,14.02-14.65,24.55
				C853.87,520.23,858.86,511.88,863.54,504.03z M799.91,463.41c-0.23-0.55-0.45-1.09-0.68-1.64c-6.24,2.73-12.48,5.46-18.72,8.19
				c0.25,0.57,0.51,1.13,0.76,1.7C787.49,468.91,793.7,466.16,799.91,463.41z M823.89,440.64c-6.89,2.82-12.8,6.69-15.27,14.26
				C813.71,450.15,818.8,445.39,823.89,440.64z M833.21,437.94c-6.88,2.12-10.53,6.59-13.1,12.22
				C824.29,446.26,828.46,442.36,833.21,437.94z M812.24,472.37c0.39,0.47,0.77,0.93,1.16,1.4c3.5-2.82,7-5.64,10.5-8.46
				c-0.32-0.41-0.65-0.83-0.97-1.24C819.36,466.83,815.8,469.6,812.24,472.37z"/>
			<path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M184.53,187.72c-0.73-8.03,1.85-13.77,8.29-15.76c9.44-2.92,19.18-4.84,28.5-7.11
				c-10.9-1.61-21.53,2.51-32.96,5.3c0.51-3.36,0.53-6.09,1.47-8.44c0.41-1.04,3.21-2.26,4.06-1.8c7.05,3.77,13.71,3.01,21.04,0.36
				c6.7-2.41,8.43-0.3,10.05,6.51c1.7,7.16,4.32,14.11,6.53,21.16c0.53-0.12,1.06-0.23,1.59-0.35c-0.29-1.75-0.42-3.55-0.89-5.26
				c-1.98-7.2-3.95-14.41-6.21-21.52c-0.38-1.2-2.13-2.8-3.24-2.8c-3.93,0.01-8.02,0.08-11.74,1.17c-4.31,1.26-8.26,2.24-12.54,0.13
				c-1.6-0.79-3.46-1.2-5.25-1.45c-4.06-0.55-5.97,0.41-6.53,5.43c-0.87,7.83-2.51,15.9-5.7,23.04c-2.02,4.52-7.05,8.07-11.39,11.1
				c-10.91,7.62-24.01,9.96-36.55,9.58c-7.67-0.23-14.78-0.58-21.97,1.96c-5.27,1.86-10.6,3.59-15.82,5.58
				c-8.8,3.36-17.58,6.81-26.3,10.38c-3.53,1.44-6.85,3.38-10.35,4.9c-1.8,0.78-4.08,2.06-5.63,1.55
				c-10.42-3.42-20.45,0.81-30.65,1.07c-5.28,0.13-10.56,0.02-16.08,0.02c0-26.86,0-53.26,0-80.73
				c140.79,1.1,281.27,0.65,422.18-2.77c-0.63,1.71-1.09,2.96-1.47,3.99c14.44,0,28.64,0,43.04,0c-4.05,3.97-9.67,4.36-14.84,4.54
				c-6.26,0.22-12.57-0.9-18.85-1.51c-1.98-0.19-3.93-0.73-5.92-0.9c-5.32-0.48-7,1.84-4.97,6.71c1.96,4.7,1.93,4.74-4.88,7.36
				c-0.29-1.42-0.58-2.8-0.89-4.34c-8.74,0-17.38-0.28-25.99,0.18c-2.37,0.13-4.63,2.48-7.24,3.99c0-0.48-0.2-1.4,0.03-2.18
				c1.38-4.63-1.15-6.24-5.09-6.34c-10.15-0.25-20.3-0.36-30.45-0.42c-3.41-0.02-5.9,1.03-8.14,4.34c-2.63,3.88-6.9,6.64-10.33,10
				c-1.64,1.61-3.29,3.34-4.43,5.31c-1.84,3.16-3.7,3.19-5.54,0.36c-1.79-2.74-3.82-5.59-4.59-8.67c-0.86-3.43-2.79-3.77-5.45-3.5
				c-8.42,0.84-16.86,1.57-25.25,2.69c-9.02,1.21-17.96,3.04-26.99,4.11c-2.98,0.35-4.4,1.42-5.45,4.01
				c-2.79,6.87-6.51,13.05-12.81,17.46c-2.62,1.84-4.94,2.11-7.2,0.15c-2.63-2.27-5.53-4.58-7.22-7.5c-2.22-3.86-4.68-3.91-8.23-2.55
				c-7.97,3.06-16.09,3.48-24.48,1.56C191.54,187.09,187.93,187.72,184.53,187.72z M8.08,168.29c0.01,0.52,0.02,1.05,0.03,1.57
				c6.1-0.37,12.25-0.33,18.27-1.22c6.92-1.02,6.87-1.38,4.4-8.91c4.44-0.23,8.6-0.4,12.76-0.69c3.26-0.22,4.62,0.99,4.01,4.39
				c-0.85,4.79-1.48,9.62-2.2,14.43c1.52-1.13,2.83-2.53,3.18-4.14c0.72-3.37,0.83-6.87,1.42-10.28c0.25-1.46,0.95-3.93,1.68-4.02
				c2.52-0.31,5.15,0.23,7.74,0.44c-0.3,1.7-0.26,3.57-0.97,5.08c-1.69,3.58-3.74,6.98-5.9,10.92c1.5,0.12,2.48,0.21,3.4,0.28
				c-1.01,7.6-5.78,11.53-12.04,14.41c-2.49,1.15-4.76,2.92-6.84,4.74c-2.73,2.39-5.17,5.11-7.71,7.71
				c-0.77,0.79-1.48,1.64-2.21,2.47c0.33,0.3,0.66,0.59,0.98,0.89c3.62-3.08,7.09-6.37,10.92-9.17c2.3-1.68,5.14-3.59,7.79-3.67
				c13.14-0.4,26.3-0.21,39.45-0.28c1.42-0.01,2.83-0.4,4.24-0.62c-0.06-0.39-0.12-0.78-0.18-1.17c-13.75,0-27.49,0-42.37,0
				c13.83-10.85,29.37-5.45,43.62-7.31c-0.03-0.53-0.06-1.05-0.08-1.58c-11.41,0-22.82,0-34.42,0c2.11-8.76,2.14-8.27,9.61-8.75
				c9.73-0.62,19.4-2.17,29.11-3.18c2.72-0.28,5.55-0.42,8.23-0.01c4.15,0.63,8.47,3.1,12.25,2.37c4.69-0.9,8.28-0.4,11.97,2.17
				c0.55,0.38,1.46,0.25,2.2,0.36c-4-2.82-7.08-8.18-13.16-4.68c-0.75,0.43-2.24,0.14-3.11-0.33c-5.96-3.18-12.24-3.84-18.57-1.86
				c-12.15,3.81-24.53,3.15-37.67,2.82c1.61-3.84,2.85-7.23,4.48-10.43c0.48-0.94,2.02-1.9,3.08-1.91
				c12.11-0.13,24.22-0.08,36.31-0.08c0.14,1.31,0.26,2.33,0.49,4.47c2.33-1.75,3.94-2.95,5.55-4.16c-0.16-0.49-0.32-0.99-0.48-1.48
				c-3.72-0.4-7.43-1.14-11.15-1.16c-19.79-0.1-39.59-0.02-59.38,0c-7.26,0.01-8.77,1.8-7.21,9.27
				C22.4,166.78,15.24,167.53,8.08,168.29z M156.95,183.75c6.69-4.1,8.38-3.76,15.11,2.15c-1.48-3.36-3.33-6.59-5.74-9.33
				c-1.46-1.66-3.74-3.57-5.71-3.63c-5.78-0.18-11.79-0.31-17.32,1.1c-4.43,1.13-9.26,3.74-12.11,7.18c-2.74,3.3-3.15,8.52-4.6,12.89
				c-0.53,1.62-1.1,3.23-1.65,4.84c0.47,0.23,0.94,0.47,1.4,0.7c0.96-1.54,2.2-2.97,2.83-4.63c1.17-3.09,1.65-6.48,3.06-9.43
				c0.79-1.65,2.75-3.59,4.41-3.84c7.37-1.11,14.83-1.65,22.84-2.46c-1.12,0.63-1.77,1.06-2.47,1.39c-4.22,2.02-4.35,2.88-0.82,5.61
				c3.06,2.36,6.13,4.72,9.9,7.63C165.18,188.22,160.58,186.71,156.95,183.75z M123.33,156.21c-0.12,0.34-0.24,0.68-0.35,1.02
				c2.04,4.22,4.08,8.44,6.04,12.48c1.02-0.61,2.41-2.16,3.88-2.23c8.45-0.39,16.93-0.19,25.37-0.68c5-0.29,8.99-0.22,8.5,6.4
				c-0.01,0.2,0.73,0.45,1.44,0.87c0.32-3.92,0.27-7.63,1.1-11.14c0.29-1.21,2.66-2.25,4.26-2.64c0.47-0.12,1.83,2.17,2.26,3.52
				c0.36,1.12-0.03,2.49-0.09,3.75c0.35,0.06,0.7,0.12,1.06,0.18c0.75-3.01,1.51-6.02,2.44-9.74c-4.3,0.32-8.55,2.98-10.15-1.26
				c-2.65,0.76-4.97,1.88-7.34,2c-5.65,0.3-11.35-0.21-16.98,0.27c-4.33,0.37-9.11,0.27-13.04,4.21c1.19,0.28,1.67,0.54,2.12,0.48
				c9.34-1.19,18.67-2.55,28.04-3.5c1.71-0.17,3.59,1.32,5.39,2.05c-1.63,0.83-3.23,2.31-4.9,2.4c-9.3,0.51-18.62,0.61-27.93,1
				c-3.36,0.14-5.5-0.63-6.65-4.28C127.17,159.39,124.87,157.92,123.33,156.21z M263.25,158.72c-8.42,3.17-17.99,1.32-25.62,7.69
				c2.43,1.2,4.86,1.67,7.3,2c2.67,0.35,5.35,0.56,8.69,0.9c-2.31,1.57-3.9,2.66-5.5,3.75c0.29,0.5,0.58,1,0.87,1.51
				c3.72-1.65,7.43-3.3,11.15-4.95c-0.18-0.7-0.35-1.4-0.53-2.1c-5.71-0.41-11.41-0.81-17.12-1.22c0-0.52-0.01-1.03-0.01-1.55
				c10.69-1.76,21.38-3.53,32.07-5.29c-0.09-0.64-0.18-1.28-0.27-1.92c-15.18,0-30.36,0-45.55,0c0,0.4-0.01,0.79-0.01,1.19
				C240.24,158.72,251.75,158.72,263.25,158.72z M39.8,200.27c-0.04,0.49-0.07,0.98-0.11,1.46c15.09-1.22,30.18-2.44,45.28-3.65
				c-0.04-0.46-0.07-0.92-0.11-1.38C69.97,199.58,54.57,195.72,39.8,200.27z M101.47,172.16c-2.28,6.04,1.5,18.85,5.13,20.16
				C104.86,185.47,103.16,178.81,101.47,172.16z M109.18,173.44c-0.46,0.05-0.92,0.1-1.38,0.15c-0.1,5.66,0.08,11.26,4.93,15.74
				C111.54,184.03,110.36,178.74,109.18,173.44z M288.39,159.04c0.08,0.63,0.16,1.26,0.24,1.89c4.19-0.56,8.37-1.13,12.56-1.69
				c-0.07-0.56-0.15-1.13-0.22-1.69C296.78,158.04,292.59,158.54,288.39,159.04z"/>
			<path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M692.63,415.11c-4,7.1-13.77,16.01-20.38,18.32c-0.87-5.37-4.03-3.68-7.28-2.38
				c-8.95,3.58-17.68,8.31-26.98,10.24c-14.38,2.98-29.16,4.02-43.76,5.9c-0.28,0.04-0.53,0.34-1.53,1.02
				c5.36,3.13,10.1,0.28,14.77-0.33c5.1-0.66,10.16-1.58,15.25-2.33c5.09-0.75,10.27-1.07,15.25-2.25c4.97-1.18,9.76-3.14,14.59-4.87
				c4.83-1.73,9.62-3.58,14.43-5.39c0.01,0.53,0.02,1.07,0.03,1.6c2.24,0.25,5.04,1.46,6.61,0.55c4.4-2.54,8.47-5.75,12.31-9.11
				c2.86-2.5,5.12-5.68,7.72-8.5c2.96-3.22,6.41-4.92,11.06-4.51c4.76,0.43,9.59,0.09,14.96,1.07c-1.42,1.1-2.77,2.29-4.26,3.27
				c-6.12,4.02-6.35,5.35-1.28,10.75c-1.96,1.25-3.94,2.33-5.73,3.68c-2.81,2.12-3.53,4.38-1,7.56c4,5.02,3.8,6.33-1.8,9.64
				c-4.41,2.61-9.27,4.46-13.91,6.7c-1.42,0.69-2.77,1.54-4.88,2.72c2.06,1.43,3.43,2.37,4.96,3.44c-2.46,1.82-6.43,2.9-3.13,7.12
				c0.32,0.41-0.76,2.75-1.66,3.15c-3.87,1.74-8.06,4.61-11.87,4.26c-8.2-0.77-15.33-0.72-22.57,4.49
				c-3.62,2.61-9.62,1.84-14.49,2.84c-3.57,0.73-7.24,1.41-10.55,2.83c-9.9,4.25-20.3,4.45-31.72,4.58c2.01-2.95,3.18-6.27,5.51-7.68
				c2.49-1.5,6.06-1.48,9.19-1.61c5.17-0.21,10.35,0.2,14.44-4.05c0.91-0.95,2.73-1.05,4.15-1.48c8.29-2.48,16.58-4.94,24.87-7.41
				c-0.17-0.67-0.33-1.33-0.5-2c-4.54,1.08-9.09,2.16-13.45,3.2c0.62-4.66,1.89-6.27,6.48-6.82c7.65-0.9,15.33-1.58,24.31-2.49
				c-4.27-0.58-7.48-1.01-12.29-1.67c3.77-1.32,6.18-2.16,8.6-3c-0.13-0.49-0.25-0.98-0.38-1.47c-3.13,0.85-6.3,1.58-9.39,2.59
				c-4.53,1.48-9.03,3.1-13.49,4.8c-1.5,0.57-3.61,1.23-4.13,2.41c-2.77,6.37-8.65,7.91-14,11.07c-6.28,3.72-12.44,3.59-18.92,4.58
				c-4.89,0.75-9.77,2.87-14.17,5.25c-6.38,3.46-11.55,2.43-16.66-3c4.5-1.82,7.77-4.78,8.49-10.42c-1.16,0.4-1.86,0.42-2,0.71
				c-3.5,7.1-10.17,9.41-17.03,11.63c-1.79,0.58-3.35,2.09-4.81,3.4c-5.74,5.15-14.26,5.33-20.61,0.87
				c-1.68-1.18-4.39-1.08-6.65-1.21c-1.66-0.09-3.35,0.4-5.29,0.66c0.15-1.21,0.08-1.76,0.29-2.16c3.17-6.1,2.25-8.02-4.99-8.87
				c-7.41-0.87-14.92-0.98-22.29-2.05c-2.91-0.42-5.99-2.08-8.25-4.03c-3.83-3.31-7.37-3.34-11.29-0.7
				c-3.83,2.58-7.61,5.23-11.48,7.89c-3.59-3.66-1.51-6.77-0.14-9.53c4.2-8.5,3.79-10.25-5.05-13.66c-4.36-1.68-9.03-2.59-14.72-4.18
				c7.89-3.17,15.09-5.93,22.18-8.94c10.83-4.61,22.39-5.88,33.8-8.05c16.77-3.19,32.4-9.6,47.05-18.42
				c3.46-2.08,6.27-3.86,8.51-7.95c1.76-3.22,8.14-5.16,12.59-5.4c12.74-0.7,25.54-0.25,38.12-0.25c-3.52,0.95-7.67,1.52-11.31,3.2
				c-4.74,2.19-9.14,5.16-13.52,8.04c-0.83,0.54-0.9,2.22-1.33,3.38c1.19,0.4,2.47,1.32,3.54,1.12c3.5-0.67,6.93-1.75,10.17-3.69
				c-2.99,0.31-5.98,0.61-9.74,0.99c4.82-6.3,10.83-8.43,17.49-9.77c4.44-0.9,8.69-2.75,13.06-4.08c1.22-0.37,2.58-0.74,3.81-0.6
				c15.99,1.87,31.96,3.83,47.94,5.79c0.65,0.08,1.7,0.09,1.86,0.45C678.01,414.84,687.24,410.18,692.63,415.11z M630.32,420.73
				c-10.5,4.44-19.93,9.32-29.92,12.4c-9.98,3.07-20.52,4.34-30.81,6.39c0.19,0.84,0.39,1.67,0.58,2.51
				c20.66-4.85,41.32-9.71,61.98-14.56c-0.15-0.68-0.3-1.37-0.44-2.05c-2.92,0.71-5.83,1.42-8.75,2.13c-0.09-0.33-0.17-0.66-0.26-1
				c4.82-2.15,9.65-4.3,14.47-6.46c-0.18-0.62-0.36-1.24-0.54-1.86c-1.95-0.36-3.9-0.72-6.73-1.24c2.93-1.99,5.11-3.49,7.3-4.98
				c-0.18-0.33-0.35-0.67-0.53-1c-6.48,0-12.95,0-19.43,0c-0.02,0.26-0.03,0.51-0.05,0.77c4.07,0.52,8.15,1.04,12.54,1.59
				c-2.41,1.85-4.76,3.67-8.45,6.5C624.76,420.21,627.04,420.42,630.32,420.73z M645.46,430.41c-0.08-0.63-0.16-1.26-0.23-1.89
				c-18.89,4.77-37.77,9.55-56.66,14.32c0.1,0.47,0.2,0.94,0.3,1.4C607.87,440.24,626.8,435.93,645.46,430.41z M508.57,453.83
				c10.91-3.59,22.12-7.28,34.43-11.33C535.04,441.24,510.28,450.3,508.57,453.83z M590.89,428.89c-7.27-1.85-20.81,0.76-23.78,4.46
				C575.38,431.8,583.13,430.34,590.89,428.89z M513.62,455.53c0.18,0.55,0.36,1.1,0.54,1.65c5.26-1.75,10.53-3.5,15.79-5.26
				c-0.14-0.42-0.28-0.84-0.41-1.26C524.04,451.69,518.34,452.1,513.62,455.53z"/>
			<path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M101.56,380.71c1.54-6.51,0.34-8.66-2.81-6.95c-8.72,4.73-18.31,2.36-27.26,4.34
				c-13.91,3.07-27.63,6.94-41.52,10.11c-7.7,1.75-15.57,2.76-23.95,4.2c0-9.85-0.11-18.98,0.14-28.11c0.03-1.14,1.81-2.92,3.08-3.23
				c6.26-1.55,12.56-3.16,18.95-3.92c11.8-1.42,22.27-4.77,29.94-14.78c1.99-2.6,6.01-4.08,9.39-5.07c8.84-2.6,17.85-4.64,26.79-6.91
				c-0.05-0.42-0.1-0.83-0.15-1.25c-1.77,0.24-3.58,0.33-5.32,0.74c-7.57,1.78-15.23,3.32-22.65,5.62
				c-3.82,1.18-8.31,2.94-10.55,5.91c-5.81,7.71-13.82,10.72-22.49,12.68c-8.72,1.96-17.56,3.39-26.75,5.13c0-1.69,0-3.21,0-5.11
				c18.5-1.86,30.8-13.91,42.63-26.47c6.13-6.51,13.28-9.67,22.13-9.47c20.88,0.49,36.77-8.28,48.14-25.75
				c3.02-4.64,8.23-6.74,13.45-6.21c10.56,1.08,18.88-3.07,27.32-8.64c4.06-2.67,7.28-5.58,10.55-9.07c2.35-2.5,7.09-3.37,10.88-3.77
				c5.76-0.6,11.63-0.19,17.46-0.16c1.33,0.01,2.71-0.02,3.97,0.33c10.2,2.79,20.34,5.85,30.6,8.4c2.7,0.67,5.83,0.22,8.66-0.3
				c8.43-1.55,16.82-3.38,25.22-5.14c3.04-0.64,6.23-1,9.04-2.21c6.71-2.88,6.64-3.05,10.48,3.34c0.42,0.71,0.85,1.41,1.86,3.07
				c-4.2,1.27-7.98,2.68-11.88,3.53c-8.75,1.91-17.54,3.7-26.36,5.22c-8.56,1.47-12.83,6.96-14.94,14.8
				c-1.03,3.84-1.99,7.71-3.11,11.52c-4.09,13.8-12.77,22.99-26.52,27.9c-14.51,5.17-28.76,11.1-43.07,16.85
				c-3.69,1.48-7.13,3.55-10.71,5.31c-11.3,5.58-22.55,11.25-33.92,16.66C113.16,376.29,107.79,378.18,101.56,380.71z M107.34,333.24
				c4.76-0.67,9.51-1.82,14.28-1.91c9.01-0.16,15.25-4.86,20.79-11.21c4.09-4.69,8.57-9.02,15.1-9.92
				c15.14-2.08,30.3-4.04,45.46-5.96c7.24-0.92,14.5-1.63,23.2-2.59c-1.86-1.03-2.26-1.46-2.62-1.43c-10.74,1.09-21.5,2.03-32.2,3.4
				c-8.39,1.07-16.71,3.68-25.08,3.79c-11.35,0.14-20.37,3.42-27.2,12.7c-4.5,6.11-10.91,8.75-18.48,9.55
				c-4.46,0.47-8.77,2.37-13.18,3.6c-9.49-0.59-18.28,0.46-25.89,7.37c-4.33,3.94-9.9,6.55-15.01,9.58c-2.43,1.45-5.1,2.5-7.67,3.73
				c0.17,0.37,0.34,0.73,0.5,1.1c3.4-1.5,6.99-2.66,10.15-4.56c5.66-3.4,10.72-8.01,16.67-10.69
				C92.86,336.8,100.26,335.37,107.34,333.24z M109.87,322.24c0.37,0.65,0.74,1.29,1.1,1.94c9.33-5.61,18.57-11.38,28.03-16.75
				c4.57-2.6,9.39-5.63,14.41-6.38c8.15-1.2,16.55-0.89,24.84-0.83c7.92,0.05,15.67-0.19,22.52-4.86c4.59-3.12,9.64-4.24,15.16-3.67
				c2.48,0.25,5.03,0.35,7.49,0.02c3.53-0.47,6.99-1.39,10.48-2.12c-9.97-0.34-19.62,0.27-29.27,0.99c-1.11,0.08-2.33,0.6-3.22,1.28
				c-9.41,7.24-20.14,6.71-31.09,5.67c-13.21-1.24-25.49,1.08-35.89,10.17c-2.6,2.27-5.73,3.97-8.74,5.71
				C120.47,316.44,115.15,319.3,109.87,322.24z M18.04,364.47c0.13,0.52,0.27,1.03,0.4,1.55c9.47-2.39,18.94-4.78,28.41-7.17
				c-0.14-0.53-0.27-1.06-0.41-1.59C36.98,359.66,27.51,362.07,18.04,364.47z M210.36,311.9c0-0.49,0-0.97-0.01-1.46
				c-4.35,0.82-8.7,1.65-13.05,2.47c0.12,0.62,0.25,1.23,0.37,1.85C201.9,313.81,206.13,312.86,210.36,311.9z"/>
			<path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M228.69,328.93c1.55,5.3,5.79,6.03,10.52,6.33c2.6,0.16,5.32,0.81,7.68,1.89
				c5.8,2.65,11.66,2.01,17.64,1.17c4.01-0.56,8.07-0.78,12.34-1.17c-1.4,2.96-2.37,5-3.34,7.04c2.3,0.74,4.6,1.48,6.95,2.25
				c-0.48,7.45-0.49,7.57,8.24,8.51c8.2,0.88,16.43,1.64,24.66,2.16c12.85,0.81,25.75,1.06,38.57,2.21c4.49,0.4,8.8,2.78,13.19,4.24
				c2.51,0.83,4.98,1.79,7.54,2.42c3.56,0.88,7.19,1.5,11.16,3.11c-5.82,2.24-6.79,7.07-8.4,11.72c-1.5,4.34-5.51,5.35-9.49,4.49
				c-5.17-1.11-10.1-3.83-15.27-4.26c-8.37-0.69-16.86-0.17-25.29-0.01c-12.05,0.23-24.11,0.95-36.16,0.8
				c-9.27-0.12-18.55-1.13-27.8-1.99c-4.46-0.42-8.86-1.43-13.3-2.11c-1.31-0.2-2.7-0.44-3.98-0.24c-10.14,1.59-20.5,2.48-30.29,5.3
				c-4.37,1.26-11.3,3.38-9.71,11.32c0.17,0.86-0.93,2.05-1.57,2.99c-2.33,3.4-2.56,6.42,1.26,8.96c2.51,1.67,2.26,3.52,0.69,5.92
				c-7.84,11.95-28.53,17.89-41.49,13.55c-2.52-0.84-5.94,0.65-8.84,1.44c-3.13,0.86-5.93,2.17-7.88-2.08
				c-0.47-1.04-3.55-2.04-4.58-1.49c-12.6,6.8-26.59,2.83-39.67,5.41c-6.48,1.28-12.78,3.5-19.18,5.22
				c-3.67,0.99-7.34,2.16-11.09,2.68c-2.48,0.35-5.82,1.45-6.55-3.09c-0.11-0.68-3.16-1.55-4.62-1.24
				c-4.87,1.03-9.62,2.64-14.46,3.84c-2.21,0.54-4.54,0.63-6.81,0.92c-0.24-0.38-0.48-0.76-0.72-1.14c2.33-2.14,4.38-4.74,7.03-6.35
				c16.1-9.74,32.7-18.44,50.9-23.74c2.21-0.64,4.04-2.59,6.05-3.93c-0.17-0.52-0.34-1.04-0.51-1.56c-1.35-0.38-2.68-0.84-4.05-1.13
				c-1.25-0.27-2.53-0.37-3.99-1.46c7.59-2.51,15.18-5.04,22.78-7.54c1.57-0.52,3.43-0.59,4.69-1.51c1.91-1.39,4.18-3.12,4.76-5.14
				c0.32-1.1-2.51-3.15-4-4.69c-0.43-0.45-1.17-0.6-3.09-1.53c4.72-2.32,7.9-3.9,11.09-5.44c12.55-6.06,25.04-12.24,37.68-18.09
				c5.67-2.63,11.64-4.62,17.51-6.84c7.44-2.81,15-5.31,22.33-8.38C214.81,335.76,221.54,332.3,228.69,328.93z M243.42,364.9
				c-0.07,0.92-0.13,1.85-0.2,2.77c1.85,0.21,3.77,0.83,5.54,0.53c1.69-0.29,3.2-2.15,4.84-2.22c10.62-0.5,21.26-0.56,31.88-1.08
				c4.43-0.22,8.82-1.19,13.23-1.82c-0.07-0.21-0.13-0.42-0.2-0.63C280.15,363.26,261.79,364.08,243.42,364.9z M296.44,371.33
				c-0.06-0.59-0.12-1.19-0.19-1.78c-10.78,0.31-21.79-1.81-32.07,3.45C274.94,372.44,285.69,371.88,296.44,371.33z M292.79,373.09
				c0.04,0.29,0.09,0.58,0.13,0.88c9.84,0,19.68,0,29.52,0c-0.03-0.29-0.06-0.58-0.08-0.88C312.5,373.09,302.64,373.09,292.79,373.09
				z M269.84,359.67c-0.08-0.65-0.15-1.29-0.23-1.94c-6.02,0.48-12.03,0.96-18.05,1.44c0.04,0.53,0.08,1.06,0.12,1.59
				C257.73,360.39,263.78,360.03,269.84,359.67z"/>
			<path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M863.85,283.32c1.27-2.56,1.73-5.17-1.52-7.62c-4.48-3.37-6.28-7.8-5.47-13.56
				c0.45-3.21-0.26-6.58-0.49-10.61c4.76-0.28,9.61-0.89,14.41-0.67c1.53,0.07,3.64,2.05,4.33,3.66c2.83,6.6,5.21,13.38,7.75,20.1
				c0.39-0.14,0.78-0.29,1.17-0.43c-2.35-9.82-4.7-19.64-7.28-30.4c6.3,0.62,12.08,0.86,17.68,1.96c1.52,0.3,3.26,3.09,3.63,4.99
				c1.38,7.14,5.34,11.63,12.46,12.05c-1.2-0.82-2.73-1.4-3.55-2.5c-2.49-3.37-5.86-6.67-6.75-10.49c-1.14-4.94-3.64-6.81-8.03-7.18
				c-3.32-0.28-6.66-0.22-9.98-0.39c-1.75-0.09-3.49-0.37-6.75-0.73c3.49-5.9,6.39-10.78,9.27-15.68c1.55-2.63,2.76-6.66,6.76-4.49
				c3.35,1.82,5.64,5.14,4.31,9.59c-1.03,3.44-2.31,6.93,2.07,10.48c1.45-4.2,2.66-7.75,3.91-11.28c1.08-3.04,5.48-4.76,7.95-2.98
				c0.66,0.48,1.37,1.96,1.09,2.44c-0.69,1.22-1.92,2.13-3.06,3.29c1.57,0.51,3.63,1.18,5.62,1.83c-0.24,7.17,0.83,13.65,4.58,19.39
				c0.46-0.12,0.92-0.25,1.38-0.37c-0.74-2.34-1.49-4.67-2.47-7.73c3.59,0.26,6.72,0.49,9.86,0.72c0.07-0.43,0.14-0.86,0.21-1.29
				c-3.46-0.72-6.93-1.43-10.39-2.15c0.01-0.54,0.02-1.07,0.04-1.61c7.23-0.33,14.46-0.65,21.69-0.98c-0.02-0.52-0.04-1.04-0.06-1.55
				c-4.96,0-9.92,0.01-14.89-0.01c-1.33-0.01-2.77,0.16-3.96-0.28c-1.18-0.43-2.11-1.54-3.15-2.34c1.1-0.65,2.21-1.88,3.28-1.85
				c4.32,0.12,8.63,0.83,12.95,0.92c2.65,0.06,5.31-0.61,7.96-0.96c-0.05-0.52-0.09-1.04-0.14-1.56c-5.19,0-10.38,0.07-15.57-0.03
				c-2.15-0.04-4.29-0.57-6.44-0.68c-3.63-0.19-5.96-1.39-6.07-5.6c-0.02-0.94-1.94-2.12-3.21-2.65c-1.07-0.44-2.46-0.09-3.71-0.09
				c0-0.45-0.01-0.91-0.01-1.36c4.23,0.26,8.46,0.45,12.68,0.81c10.29,0.86,20.56,1.93,30.86,2.59c2.11,0.14,4.49-0.79,6.43-1.82
				c5.2-2.77,8.88-1.47,10.94,3.96c0.63,1.65,1.82,3.16,3.04,4.47c1.18,1.27,2.73,2.2,4.22,3.95c-9.6-2.26-15.69,4.38-22.35,8.62
				c-14.88,9.47-29.83,19.02-43.57,30.01c-8.67,6.94-18.5,8.97-28.4,11.45c-6.25,1.57-11.09,3.07-14.16,9.82
				c-1.98,4.36-6.95,8.2-11.48,10.39c-7.24,3.5-15.1,6.04-22.97,7.85c-7.73,1.78-14.26,5.03-20.14,10.23
				c-2.54,2.25-5.86,3.65-8.91,5.3c-5.79,3.13-11.62,6.18-17.46,9.21c-1.14,0.59-2.36,1.19-3.6,1.38c-4.26,0.66-8.53,1.26-12.83,1.6
				c-1.15,0.09-2.39-0.83-3.58-1.28c1.03-0.8,1.95-1.96,3.13-2.34c2.67-0.87,5.53-1.16,8.17-2.1c1.03-0.36,2.33-1.85,2.29-2.78
				c-0.03-0.92-1.45-2.29-2.51-2.58c-3.19-0.89-6.49-1.44-9.77-1.98c-7.47-1.22-14.43-3.21-20.42-8.41
				c-2.58-2.24-6.53-3.15-10.02-3.99c-3.14-0.75-5.6-1.73-6.8-4.98c-0.38-1.02-1.3-1.89-2.11-2.7c-4.23-4.24-8.51-8.43-13.12-12.99
				c8.03-4.87,14.24-10.76,16.98-19.68c0.34-1.09,2.43-2.29,3.73-2.33c7.63-0.22,15.27-0.11,24.21-0.11
				c-3.54,7.4,0.51,11.42,3.84,15.77c1.4,1.83,2.65,3.89,3.48,6.03c1.32,3.4,3.36,5.59,7.1,6.14c2.54,0.38,3.93,1.75,2.88,4.56
				c-0.17,0.44-0.3,1.16-0.07,1.45c3.87,4.96,7.8,9.88,11.71,14.81c0.44-0.37,0.87-0.74,1.31-1.1c-2.1-2.89-3.83-6.16-6.39-8.57
				c-2.81-2.64-5.24-4.82-4.43-9.24c0.17-0.96-2.26-3.15-3.79-3.52c-4.89-1.18-6-4.28-5.19-8.6c0.89-4.73,2.03-9.41,2.92-14.13
				c0.49-2.59,1.79-2.68,4.25-2.61c7.21,0.2,14.45-0.39,21.67-0.29c1.9,0.03,4.61,0.93,5.51,2.34c2.79,4.38,4,9.77,10.37,11.17
				c1.33,0.29,2.43,4.11,2.65,6.39c0.44,4.5,2.39,6.53,6.9,7.65c4.32,1.07,8.24,3.73,12.32,5.73c1.98,0.97,3.92,2.02,6.32,2.17
				c-4.07-4.74-7.99-9.59-14.38-10.6c-5.24-0.83-8.7-2.76-8.68-8.77c0.01-2.73-1.26-4.8-4.36-5.86c-2.15-0.74-4.14-2.59-5.66-4.4
				c-2.77-3.3-5.05-7.02-7.7-10.42c-2.7-3.47-2.32-6.29,1.21-8.85c1.75-1.27,3.6-2.52,4.98-4.15c3.38-4.02,6.51-7.18,12.66-4.51
				c2.13,0.93,5.54-1.35,8.42-1.8c2.38-0.37,4.86-0.36,7.28-0.21c1.6,0.1,3.18,0.7,4.75,1.15c1.23,0.35,2.97,0.43,3.54,1.27
				c4.27,6.24,8.31,12.64,12.42,19c1.04,1.61,1.93,3.32,3.07,4.85C860.21,279.06,861.9,280.96,863.85,283.32z M791.89,293.14
				c5.47,4.1,10.76,8.66,19.04,7.68c-3.78-1.38-6.88-2.35-9.84-3.65c-3-1.32-5.86-2.98-8.78-4.49c-6.09-3.65-12.25-6.98-19.75-4.7
				C779.01,289.7,785.45,291.42,791.89,293.14z"/>
			<path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M843.71,741.95c-3.93,0-6.94,0.29-9.88-0.05c-10.73-1.27-20.83,0.21-31.25,3.58
				c-9.99,3.24-20.81,4.28-31.36,5.38c-25.91,2.71-51.88,4.9-77.83,7.27c-1.4,0.13-2.83,0.02-5.27,0.02
				c4.2-3.75,7.95-6.47,10.92-9.88c10.27-11.78,20.2-23.85,30.44-35.66c2.03-2.34,4.76-4.14,7.33-5.95
				c6.47-4.57,10.11-11.25,14.22-17.73c1.86-2.94,5.6-4.67,8.42-7.03c2.95-2.47,6.53-4.57,8.55-7.66c3.2-4.91,6.97-10.37,7.47-15.88
				c0.61-6.72,3.67-12.04,6.07-17.76c1.72-4.09,4.81-4.61,8.37-1.81c3.14,2.48,6.14,3.23,10.14,2.33c7.98-1.79,11.04,0.78,12.12,8.99
				c0.2,1.48,1.08,3.3,2.24,4.13c4.01,2.88,6.19,6.59,7.44,11.36c0.49,1.85,3.21,4.21,5.09,4.38c4.03,0.38,5.73,2.51,7.34,5.69
				c1.71,3.36,4.09,6.38,5.95,9.68c1.17,2.08,1.95,4.38,2.85,6.61c0.18,0.44-0.04,1.07,0.17,1.47c4.16,7.8,3.24,14.8-0.46,23.02
				c-2.46,5.48-0.32,13.05-0.1,19.68C842.76,737.91,843.29,739.66,843.71,741.95z M794.6,700.86c-2.72-1.65-4.48-3.1-4.79-2.84
				c-2.37,2.01-4.46,4.35-6.63,6.6c1.01,0.83,2.56,2.58,2.96,2.35C788.71,705.44,791.04,703.5,794.6,700.86z"/>
			<path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M29.1,576.06c-3.66-2.91-6.58-6.65-10.3-7.78c-3.62-1.1-8.05,0.46-12.8,0.92c0-2.96,0-5.41,0-7.86
				c0-15.66-0.07-31.31,0.1-46.97c0.02-1.57,1.09-3.96,2.34-4.55c9.13-4.31,18.51-8.09,27.67-12.35c9.77-4.54,16.02-2.37,20.38,7.33
				c0.87,1.93,2.17,3.74,3.6,5.31c2.03,2.23,5.51,3.42,4.76,7.43c-0.05,0.24,0.26,0.77,0.48,0.81c6.28,1.3,5.53,7.27,7.64,11.36
				c2.68,5.19,7.38,6.56,11.4,2.76c4.14-3.91,7.88-3.01,11.59-0.2c15.08,11.45,32.78,16.53,50.7,21.11c0.48,0.12,0.96,0.29,1.45,0.35
				c3.96,0.48,8.63-0.18,11.67,1.74c2.92,1.85,4.15,6.37,6.37,10.13c-2.36,1.88-3.01,4.07-0.4,6.99c2.35,2.62,1.55,5.56-1.73,6.85
				c-3.97,1.56-8.1,2.88-12.29,3.66c-8.16,1.51-16.46,2.34-24.6,3.95c-2.28,0.45-5.38,2.3-5.94,4.16c-0.49,1.64,1.87,4.31,3.3,6.29
				c1.42,1.96,3.26,3.62,5.54,6.08c-6.03,1.33-10.73,3.36-15.31,3.1c-5-0.29-9.87-2.72-14.81-4.18c-1.1-0.33-2.42-1.02-3.34-0.69
				c-3.46,1.24-6.38,0.88-8.92-1.9c-0.3-0.33-0.97-0.64-1.35-0.54c-6.69,1.92-8.59-3.64-11.06-7.54c-2.21-3.48-4.53-5.27-8.79-5.14
				c-2.53,0.08-5.26-0.84-7.62-1.91c-8.15-3.7-16.31-7.44-24.22-11.62C30.46,570.96,29.85,573.69,29.1,576.06z"/>
			<path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M278.32,848.66c-0.43-5.19-0.33-10.26-1.41-15.05c-1.03-4.55-2.84-9.08-5.13-13.16
				c-3.14-5.59-4.83-10.58-4.12-17.61c0.64-6.24,3.02-11.19,4.54-16.76c1.14-4.19-0.96-9.69-2.75-14.16
				c-3.08-7.68-3.45-7.53,2.21-13.1c4.54-4.48,2.57-14.06-3.1-16.96c-3.89-1.99-7.2-5.08-11.01-7.26c-2.64-1.51-5.69-2.31-8.53-3.42
				c1.28-6.64,1.06-7.62-5.62-9.34c-9.39-2.42-15.83-8.77-21.59-15.73c-1.67-2.02-0.74-6.19-1.08-10.46
				c-1.29-0.31-3.62-1.38-5.93-1.33c-7.27,0.17-12.76-3.23-17.63-8.06c-2.47-2.45-4.69-5.15-7.19-7.57
				c-2.14-2.07-2.31-3.92-0.79-6.58c3.14-5.47,2.42-6.64-3.2-9.48c-2.71-1.37-5.08-3.54-7.33-5.65c-0.42-0.4,0.31-2.27,0.79-3.33
				c1.18-2.67,2.54-5.27,3.79-7.91c0.35-0.74,0.47-2.18,0.83-2.22c7.07-0.85,9.14-10.33,17.17-9.82c1.64,0.11,4.11-1.81,5.01-3.45
				c1.58-2.86,3.68-3.09,6.5-3.39c3.19-0.34,6.38-1.43,9.38-2.65c4.86-1.97,9.64-4.17,14.3-6.56c1.65-0.85,2.87-2.54,4.33-3.88
				c14.94,7.68,30.2,15.52,45.69,23.48c0.19-0.72,0.17-0.26,0.05-0.23c-9.76,2.3-16.21,9.22-22.92,15.97
				c-3.37,3.39-7.49,6.07-11.47,8.77c-3.4,2.31-3.94,4.8-1.03,7.63c3.64,3.54,4.92,6.61,0.5,10.79c-2.57,2.43-0.02,7.26,4.17,8.83
				c2.25,0.84,4.54,1.61,8.23,2.91c-4.39,2.39-7.84,3.71-10.55,5.93c-2.08,1.7-4.42,4.5-4.46,6.85c-0.03,1.84,3,4.14,5.13,5.56
				c7.68,5.11,16.89,2.7,25.18,4.8c4.42,1.12,8.8,2.89,12.77,5.12c1.79,1.01,2.5,3.9,3.8,5.87c1.17,1.77,2.47,3.46,4.43,6.19
				c-1.51,2.07-3.39,5.4-5.98,8.05c-6.84,7.02-9.84,15.62-11.3,25.01c-0.92,5.91-2.16,11.76-3.08,17.67
				c-1.64,10.47,3.27,19.23,7.56,28.15c4.64,9.65,5.56,19.82,3.38,30.18C289.36,848.39,287.23,849.46,278.32,848.66z"/>
			<path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M384.94,771.05c4.91-3.24,9.99-6.27,14.68-9.8c3.57-2.69,6.44-6.33,10.05-8.96
				c2.57-1.87,5.98-2.52,8.77-4.13c3.77-2.19,7.52-4.52,10.88-7.27c2.98-2.44,5.42-5.53,8.39-8.63c0.62,1.27,1.11,1.87,0.98,2.08
				c-8.57,13.9-6.97,29.55-7.74,44.79c-0.4,7.89-1.42,15.6-6.98,21.87c-0.53,0.6-0.84,1.42-1.14,2.19
				c-2.57,6.38-4.67,13.02-7.9,19.05c-1.21,2.26-4.85,3.87-7.67,4.5c-14.25,3.16-28.74,5.33-42.86,8.97
				c-11.2,2.89-21.95,7.47-32.96,11.13c-3.09,1.03-6.37,1.98-9.59,2.06c-10.15,0.26-20.3,0.1-31.22,0.1
				c6.11-9.87,5.13-20.04,3.06-30.17c-0.72-3.52-2.29-6.89-3.75-10.21c-1.28-2.91-1.41-4.48,2.28-5.91
				c11.58-4.49,22.9-9.64,34.43-14.26c3.17-1.27,6.71-1.68,10.11-2.33c2.73-0.52,5.51-0.8,8.27-1.19l-0.12,0.11
				c3.28-0.38,6.63-0.47,9.83-1.21c6.13-1.41,12.41-2.65,18.2-5c4.39-1.78,8.11-5.19,12.13-7.88L384.94,771.05z"/>
			<path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M136,252.92c6.68-0.51,13.36-1.01,20.03-1.52c0.36,0.55,0.73,1.1,1.09,1.65
				c-6.89,8.96-13.54,18.12-20.83,26.73c-1.88,2.21-5.69,3.23-8.8,3.86c-4.85,0.98-8.37,2.97-11.13,7.35
				c-8.93,14.16-22.58,21.09-38.64,23.84c-2.61,0.45-5.35,0.64-7.97,0.39c-10.98-1.03-18.51,4.26-25.25,12.29
				c-8.89,10.58-19.42,19.18-33.28,22.66c-1.53,0.38-3.11,0.56-4.98,0.89c0-18.01,0-35.46,0-54.08c8.22-1.34,16.49-2.74,24.78-4.02
				c4.9-0.75,9.82-1.33,14.74-1.93c1.81-0.22,3.63-0.36,5.45-0.41c11.13-0.33,22.27-0.62,33.4-0.92c0.03-0.75,0.06-1.49,0.09-2.24
				c-2.88-0.11-5.84-0.68-8.63-0.24c-8.68,1.38-17.12,3.16-26.15,1.11c-4.54-1.03-9.78,1.36-14.73,1.63
				c-3.29,0.18-6.84-0.06-9.9-1.15c-5.29-1.88-9.23,0.28-13.22,3.05c0,0,0.13-0.12,0.13-0.12c-1.78,0.25-3.56,0.5-5.76,0.81
				c0-10.25,0-20.46,0-31.37c1.84-0.4,4.06-0.74,6.19-1.37c6.18-1.81,12.49-3.35,18.46-5.72c7.05-2.79,13.18-0.11,19.16,2.56
				c5.2,2.32,10.15,4.04,15.81,2.3c0.92-0.28,2.11-0.45,2.96-0.12c10.61,4.06,16.64-4.56,24.35-8.6c2.71-1.42,5.62-2.67,8.59-3.34
				c19.2-4.35,38.32,1.4,57.51,0.28c0.04,0.59,0.07,1.18,0.11,1.77c-4,0.08-8.06,0.62-11.99,0.12c-4.69-0.6-8.51,0.15-11.49,3.97
				c-9.42,0.35-18.84,0.53-28.25,1.09c-6.44,0.39-12.86,1.17-19.24,2.06c-1.7,0.24-3.25,1.49-4.87,2.28c1.19,1,1.93,0.95,2.67,0.83
				c6.52-1,13.01-2.4,19.56-2.9c6.45-0.5,13.01,0.28,19.46-0.25C128.99,255.84,132.47,254.04,136,252.92z M59.57,265.4
				c0.07,0.69,0.15,1.39,0.22,2.08c2.89-0.5,5.8-0.86,8.65-1.52c8.06-1.85,15.92-3.1,23.7,1.58c2.12,1.28,5.4,0.78,8.15,0.83
				c5.29,0.11,10.58,0.03,15.87,0.03c-0.33,0.95-0.7,1.26-1.1,1.31c-11.66,1.52-23.5,1.57-34.84,5.61c-2.81,1-6.67-0.75-9.99-1.48
				c-3.99-0.87-7.87-2.57-11.88-2.91c-11.16-0.94-21.64,2.61-32.13,5.8c-0.94,0.29-1.77,0.96-2.66,1.45
				c1.55,0.56,2.75,0.21,3.96-0.07c7.9-1.82,15.73-4.19,23.74-5.24c4.93-0.64,10.93-0.7,15.01,1.57c6.56,3.65,12.46,3.49,19.01,1.45
				c1.89-0.59,3.86-0.98,5.83-1.29c12.14-1.91,24.29-3.75,36.42-5.68c1.42-0.23,2.77-0.89,4.15-1.35c-0.1-0.44-0.2-0.88-0.3-1.32
				c-5.42,0-10.84-0.01-16.26,0c-4.27,0.01-8.55,0.12-12.81,0.02c-3.25-0.07-6.98,0.55-9.66-0.79
				C81.34,259.82,70.55,264.17,59.57,265.4z"/>
			<path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M426.01,625.53c-2.65,1.12-5.33,3.28-7.94,3.19c-12.74-0.46-23.3,6.1-34.64,10.08
				c-5.16,1.81-10.34,3.87-15.03,6.63c-4.51,2.65-8.73,6.03-12.39,9.76c-1.59,1.62-2.14,5.01-1.91,7.47
				c0.38,3.95,0.08,6.97-3.43,9.57c-13.58,10.06-23.53,23.18-31.83,37.79c-3.25,5.71-7.49,10.91-11.67,16.02
				c-2.56,3.12-6.55,1.63-7.87-2.15c-0.69-1.98-2.19-3.99-3.87-5.27c-7.69-5.83-16.68-7.72-26.14-8.55c-4.9-0.43-9.76-1.91-14.5-3.37
				c-2.89-0.89-3.4-3.34-1.05-5.36c1.71-1.48,4.04-2.22,6.07-3.34c2.02-1.11,4.31-1.96,5.92-3.51c2.16-2.09,2.3-4.43-1.14-5.74
				c-0.77-0.3-1.52-0.66-2.3-0.94c-8.24-2.89-8.09-2.82-4.42-10.46c0.74-1.53,0.29-3.99-0.35-5.74c-0.66-1.78-2.24-3.21-3.34-4.69
				c14.1-13.83,29.75-24.47,48.85-29.86c7.07-2,13.6-5.92,20.34-9.05c8.44-3.91,16.68-8.31,25.35-11.63
				c2.84-1.09,6.83,0.15,10.1,1.03c5.6,1.51,10.94,4.15,16.59,5.27c6.1,1.21,12.43,1.66,18.66,1.64c10.48-0.02,20.96-0.68,31.44-1.07
				C425.67,624.01,425.84,624.77,426.01,625.53z"/>
			<path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M343.66,293.13c-13.52-6.25-24.71,4.62-38.71,4.46c2.94-1.55,5-2.44,6.85-3.65
				c2.32-1.5,4.43-3.34,6.73-4.87c1.61-1.07,3.29-2.33,5.11-2.72c10.6-2.28,21.07-1.02,31.93,1.4c-1.64,5.45-3.31,10.59-4.71,15.8
				c-2.4,8.96-4.15,18.13-7.13,26.89c-1.57,4.61-5.15,8.54-7.71,12.6c2.43,0.75,7.25-1.88,8.93-6.64c2.76-7.8,4.85-15.83,7.19-23.78
				c1.63-5.51,1.9-11.67,7.01-15.55c1.22-0.93,2.9-1.81,4.34-1.78c4.95,0.11,9.9,0.5,14.82,1.06c3.11,0.36,6.13,1.38,9.23,1.81
				c5.05,0.7,10.13,1.69,15.19,1.62c10.45-0.15,20.9-0.87,31.34-1.45c4.22-0.23,8.43-0.69,12.96-1.08c1.56,4.3,3.33,9.19,5.33,14.73
				c-12.31-1.48-23.17,1.91-33.79,6.37c-3.83,1.61-7.45,3.76-11.34,5.15c-3.11,1.11-6.56,2.22-9.75,1.97
				c-13.08-1.02-26.12-2.6-39.19-3.87c-1.62-0.16-3.31,0.31-4.97,0.49c0,0.49,0.01,0.97,0.01,1.46c16.19,1.45,32.38,2.9,48.56,4.35
				c-0.06,0.56-0.12,1.11-0.19,1.67c-14.55-1.07-29.09-2.15-43.64-3.22c-0.06,0.76-0.13,1.51-0.19,2.27
				c13.8,0.83,27.6,1.67,43.16,2.61c-4.54,7.42-8.15,13.43-11.87,19.37c-2.1,3.35-4.19,6.75-6.72,9.76
				c-0.88,1.04-3.23,1.59-4.69,1.29c-10.4-2.13-20.67-4.98-31.13-6.74c-11.56-1.95-22.38-5.12-30.98-13.55
				c-3.79-3.72-7.44-7.4-13.22-8.15c-1.61-0.21-3.46-2.21-4.31-3.85c-2.01-3.88-5.04-4.86-9.06-4.66c-2.29,0.11-4.85,0.12-6.88-0.76
				c-12.96-5.61-27.02-1.54-40.29-4.7c-3.29-0.78-7.23,1.15-10.82,1.83c1.64-4.46,3.35-9.41,5.27-14.27
				c1.17-2.96,3.28-4.15,6.71-3.68c4.76,0.66,9.6,0.7,14.4,0.98c10.12,0.58,20.25,1.1,30.37,1.74c5.67,0.36,10.92-0.71,15.64-3.98
				c0.55-0.38,1.11-0.86,1.73-0.99c12.04-2.5,23.04-9.9,36.1-7.62C342.05,293.4,342.87,293.18,343.66,293.13z M239.48,309.96
				c-0.17,0.62-0.34,1.24-0.51,1.85c1.83,0.6,3.63,1.35,5.5,1.78c5,1.14,10.09,1.93,15.05,3.22c16.84,4.39,33.54,3.26,49.23-3.56
				c8.12-3.54,15.91-6.24,24.72-6.51c4.36-0.13,8.7-1.1,13.05-1.69c-0.1-0.64-0.2-1.28-0.3-1.92c-9.64,1.23-19.3,2.32-28.9,3.78
				c-2,0.31-3.78,2.01-5.69,3.02c-1.91,1.01-3.76,2.24-5.78,2.9c-16.81,5.42-33.7,6.81-50.81,1.15
				C249.96,312.3,244.67,311.28,239.48,309.96z M355.28,313.64c11.34,8.71,52.54,10.27,59.15,3.6c-9.08,0.5-18.91,1.89-28.62,1.33
				C375.91,317.98,366.12,315.48,355.28,313.64z M382.37,334.2c0-0.48,0-0.96,0-1.45c-7.98,0-15.95,0-23.93,0c0,0.48,0,0.96,0,1.45
				C366.42,334.2,374.39,334.2,382.37,334.2z M318.3,313.93c0.04,0.64,0.09,1.27,0.13,1.91c5.93-0.55,11.86-1.09,17.79-1.64
				c-0.05-0.46-0.11-0.92-0.16-1.38C330.14,313.19,324.22,313.56,318.3,313.93z"/>
			<path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M817.28,639.39c-8.96-3.21-16.8-6.3-24.84-8.73c-2.44-0.74-6.24-0.63-8.03,0.81
				c-5.42,4.38-10.88,3.35-16.5,1.48c-3.29-1.1-6.32-2.96-9.58-4.2c-5.4-2.06-10.96-3.71-16.31-5.87c-3.66-1.48-7.09-3.53-10.61-5.34
				c-3.24-1.66-6.33-3.76-9.75-4.84c-1.71-0.54-4.61,0.11-5.91,1.34c-2.33,2.2-3.97,1.39-5.49-0.29
				c-8.65-9.62-16.59-19.56-15.8-33.71c0.19-3.42-0.85-6.89-1.17-10.35c-0.46-4.93-0.98-9.87-1.09-14.82
				c-0.1-4.43-0.35-8.51-3.89-11.9c-1.15-1.11-1.42-3.54-1.42-5.36c0-2.45,0.8-4.89,0.99-7.36c0.26-3.27,2.17-4.7,5.12-5.08
				c3.82-0.49,3.23,2.84,3.59,4.74c2.93,15.18,9.92,28.7,17.29,42.1c-3.27-8.34-6.71-16.61-9.75-25.03
				c-2.29-6.35-4.26-12.84-5.94-19.38c-0.35-1.37,1.16-3.23,1.81-4.86c0.47,0.13,0.95,0.27,1.42,0.41c0.67,2.85,1.35,5.7,2.02,8.54
				c0.44-0.1,0.87-0.19,1.31-0.29c-0.29-1.29-0.66-2.57-0.86-3.88c-0.53-3.62,0.66-5.85,4.5-7.05c5.67-1.78,12.6-1.71,14.79-9.46
				c0.91,0.83,1.55,1.18,1.52,1.42c-1.09,7.09,3.49,12.58,5.65,18.67c0.71,1.99,4.49,2.88,6.82,4.24c3.7,10.86,3.6,23.19,12.15,33.01
				c4.9,5.63,9.12,11.57,15.57,15.8c3.43,2.24,5.57,6.43,8.33,9.71c2.73,3.24,5.51,6.44,8.26,9.66c0.61-0.59,1.23-1.18,1.84-1.77
				c-7.13-7.35-14.14-14.83-21.44-22.02c-7.67-7.56-14.16-15.86-18.9-25.64c3.26,2.06,6.24,3.65,8.87,5.69c2.59,2,5.1,4.22,7.17,6.73
				c9.82,11.9,19.48,23.93,29.19,35.92c0.73,0.9,1.73,1.72,2.12,2.75c3.15,8.4,10.62,13.14,16.19,19.5
				C810.32,628.97,813.35,633.93,817.28,639.39z"/>
			<path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M448.34,658.09c-0.85,4.51-10.85,3.72-5.41,11.78c-4.91,2.68-10.09,4.92-14.58,8.12
				c-5.33,3.8-9.85,8.71-15.1,12.64c-7.06,5.29-13.46,11.94-23.25,12.24c-1.91,0.06-3.98,0.85-5.66,1.82
				c-9.15,5.3-18.89,4.47-28.64,2.8c-7.5-1.28-14.45-0.33-21.25,3.18c-5.39,2.78-11.08,4.95-17.78,7.89
				c9.79-17.26,18.6-33.6,34.79-44.15c4.74-3.09,7.32-6.72,5.8-12.9c-1.18-4.81,2-8.56,5.92-10.6c8.68-4.51,17.58-8.65,26.58-12.47
				c8.81-3.74,17.48-8.15,27.59-7.59c2.09,0.12,4.37-1.39,6.41-2.43c2.74-1.4,5.03-3.67,8.66-1.57c0.95,0.55,3.27-1.51,5.05-2.1
				c2.12-0.71,4.32-1.23,6.53-1.64c2.33-0.43,4.95-0.09,7.02-1.02c7.24-3.28,13.45-7.71,16.85-15.47c0.74-1.7,3.93-2.32,5.99-3.44
				c0.61,2.04,1.5,4.04,1.77,6.12c0.43,3.36,0.03,6.85,0.72,10.14c1.84,8.76-5.45,19.91-14.65,21.1c-6.25,0.81-10.81,3.55-14.41,8.62
				C444.7,652.78,443.21,655.84,448.34,658.09z"/>
			<path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M864.05,112.3c-1.94,0.52-3.88,1.05-5.82,1.57c-7.07,0-14.14,0-21.46,0c-0.38-6.29-6.38-3.17-10.28-4.71
				c0.57-3.14,0.28-5.86-4.19-5.41c-0.64,0.06-1.72-0.93-2.05-1.68c-2.76-6.21-5.66-7.84-12.2-6.54c-1.94,0.38-3.83,0.98-6.09,1.56
				c-3.57-8.25-9.64-8.33-15.66-4.79c-2.43-4.26-3.7-9.53-6.95-11.44c-4.21-2.47-8.28,1.24-11.07,5.59c-1.3-1.14-2.38-2.11-3.49-3.05
				c-3.7-3.16-5.94-2.89-9.2,0.87c-0.93,1.07-2.18,2.33-3.44,2.54c-7.17,1.19-14.39,2.1-21.59,3.09c-2.84,0.39-4.64-0.52-6.72-2.91
				c-1.65-1.9-5.4-2.63-8.23-2.69c-5.11-0.09-9.82-0.75-14.08-4.98c3.29-0.56,6.59-1.12,10.36-1.76c1.51-6.46,6.99-4.51,11.44-4.12
				c3.96,0.35,8.13,2.54,8.62-4.17c0.04-0.58,2.54-0.99,3.85-1.46c0.8-1.45,1.72-3.12,2.63-4.8c1.7,2.32,3.4,4.64,5.49,7.49
				c1.11-3.85-0.63-6.57-3.09-8.91c-2.88-2.74-4.34-0.05-6.55,1.53c-1.91,1.36-5.8,2.56-7.19,1.57c-5.28-3.78-10.31-0.6-15.37-0.41
				c-3.29,0.13-6.49,1.47-8.45-2.81c-0.34-0.74-3.3-0.52-5.02-0.37c-6.21,0.54-12.4,1.23-18.85,0.83c2.27-1.06,4.54-2.13,6.82-3.19
				c0.05-0.46,0.1-0.93,0.16-1.39c-1.83-0.49-3.69-1.47-5.5-1.4c-7.47,0.31-14.92,0.97-22.39,1.42c-2.37,0.14-4.76,0.02-7.14,0.02
				c-0.03-0.63-0.06-1.26-0.09-1.89c6.65-0.53,13.31-0.96,19.94-1.65c1.5-0.16,2.93-1.17,4.36-1.87c0.98-0.48,1.85-1.52,2.83-1.6
				c4.73-0.39,9.48-0.54,15.03-0.81c-1.98-5.78,0.12-8.31,4.52-9.33c5.45-1.27,10.46,1,12.99,5.91c0.59,1.14,1.04,2.34,2.21,5
				c1.36-7.4,1.69-14.8,10.07-14.39c2.27,0.11,4.33,4.3,6.48,6.6c1.44-0.93,3.85-3.67,6.13-3.57c1.62,0.07,3.07,3.98,4.98,6.72
				c3.95-0.79,9.22-4.28,14.2,1.14c-1.39,1.08-2.6,2.02-3.8,2.96c0.09,0.53,0.19,1.06,0.28,1.59c15.16-1.04,30.32-2.09,45.49-3.13
				c0.07,0.61,0.13,1.22,0.2,1.84c-3.8,1.01-7.59,2.02-11.39,3.03c0.04,0.37,0.08,0.74,0.11,1.11c4.42,0.36,8.84,0.71,13.26,1.07
				c0.11,0.49,0.21,0.99,0.32,1.48c-1.76,0.88-3.44,2.09-5.31,2.58c-3.84,1-7.75,1.77-11.68,2.3c-1.08,0.15-2.45-0.67-3.47-1.37
				c-3.9-2.65-7.84-2.82-11.5,0.11c-2.83,2.27-5.27,3.17-8.91,1.49c-3.8-1.76-5.8-0.36-6.44,3.39c2.15-0.39,4.38-1.32,6.42-1
				c1.89,0.3,3.56,1.93,5.55,3.11c1.4-4.66,5.01-6.89,9.98-6.24c1.85,0.24,3.54,1.85,5.27,2.87c2.27,1.35,4.52,2.74,6.78,4.11
				c0,0-0.14-0.11-0.14-0.11c6.98,6.09,14.41,1.67,22.15,0.56c0.08,5.03,2.96,3.66,5.86,2.98c3.05-0.72,6.72-3.32,8.48,2.21
				c0.23,0.71,3.32,0.9,5.01,0.68c4.11-0.53,8.18-1.39,12.25-2.22c2.11-0.43,4.18-1.07,6.26-1.62c0.05-0.58,0.1-1.15,0.15-1.73
				c-7.39-2.94-14.78-5.88-22.17-8.82c4.5-1.39,9.14-1.77,13.78-1.8c2.56-0.02,5.36,1.42,6.73-2.24c0.19-0.51,1.39-0.96,2.1-0.92
				c6.94,0.32,13.87,0.78,20.81,1.13c4.55,0.23,9.11,0.37,13.66,0.49c1.25,0.03,2.5-0.16,3.74-0.26c0.03,0.28,0.05,0.56,0.08,0.85
				c-3.41,0.82-6.83,1.65-10.24,2.47c1.82,1.82,5.04,2.33,5.19,3.3c0.62,4.16,3.54,3.43,6.01,3.44c4.62,0.02,8.81-1.8,13.98-0.17
				c5.2,1.64,11.47,0.07,17.25-0.37c5.72-0.44,11.4-1.24,17.11-1.88c0.01,0.26,0.02,0.51,0.02,0.77c-2.46,0.99-4.92,1.98-8.6,3.46
				c2.68,1.23,4,1.83,4.56,2.09c-1.86,0.79-4.18,1.91-6.6,2.76c-1.13,0.4-2.59,0.68-3.66,0.32c-9.84-3.3-10.68-3.08-16.18,5.17
				c-18.57-7.73-32.24-3.82-34.36,10.11c-4.69,2.09-8.77,3.9-12.85,5.71c0.18,0.35,0.36,0.69,0.53,1.04
				c-2.01,0.36-4.03,0.72-6.04,1.08c-1.62,1.33-3.25,2.66-4.87,3.98c-2.38,5.69-1.59,7.19,4.02,7.58c3.95,0.28,7.92,0.42,11.85,0.92
				C862.79,110.01,863.38,111.47,864.05,112.3z"/>
			<path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M1141.78,304.68c-1.94,2.15-3.06,3.9-4.63,4.97c-1.41,0.96-3.33,1.17-5.02,1.71
				c0.32-2.45,0.38-4.98,1.09-7.31c0.26-0.86,2.07-1.24,2.99-2.75c-1.06,0.06-2.12,0.12-3.43,0.19c-0.23-1.18-0.47-2.42-0.85-4.42
				c-2,1.72-3.64,3.13-5.76,4.95c0-2.44,0-4.17,0-6.36c-2.91,1.65-5.44,3.08-9.01,5.1c0.48-2.85,0.69-4.14,1.14-6.83
				c-2.27,1.73-3.41,2.85-4.76,3.58c-3.28,1.78-6.28,3.15-9.87-0.31c-2.55-2.46-6.32-3.66-9.57-5.38c-3.6-1.91-7.15-3.97-10.89-5.54
				c-1.34-0.56-3.24,0.38-4.82,0.17c-1.54-0.2-3-1.01-5.4-3.07c1.62-0.49,3.24-0.99,4.8-1.46c-3.09-2.26-4.99-3.64-6.88-5.02
				c2.3-1.53,4.6-3.05,7.4-4.91c-1.5-0.36-2.89-0.54-4.14-1.05c-1.07-0.43-1.99-1.25-2.98-1.9c0.92-0.81,1.72-2.03,2.79-2.35
				c2.8-0.84,5.72-1.28,9.97-2.17c-2.7-1.17-4.2-1.83-6.03-2.62c0.69-0.92,1.27-1.7,2.11-2.83c-1.83-0.76-3.32-1.38-5.28-2.19
				c0.87-0.98,1.62-1.84,2.61-2.96c-1.03-0.89-2.08-1.78-3.99-3.42c3.11-1.06,5.53-1.89,8.69-2.97c-5.04-2.24-12.32-2.74-10.6-9.38
				c1.57-6.07,8.49-5.66,13.83-6.31c0.87-3.58,0.87-3.58,8.02-4.48c-0.87-1.52-1.61-2.83-2.65-4.67c2.2-0.55,4.26-1.05,7.49-1.86
				c-3-1.29-4.95-2.14-7.86-3.39c6.36-5.33,13.05-6.38,21.45-5.7c-2.08-2.5-3.33-4.01-5.42-6.52c2.58-0.47,4.31-0.92,6.06-1.05
				c0.74-0.06,1.76,1.02,2.27,0.78c6.3-2.99,6.15,1.07,5.53,5.71c1.94-0.11,3.47-0.19,5.24-0.28c0,1.41,0,2.78,0,3.93
				c2.03,0.31,3.8,0.58,5.58,0.84c-1.15,1.75-2.3,3.5-3.64,5.53c4.15,0.64,8.4,1.29,13.44,2.06c-2.16,1.08-3.69,1.85-5.23,2.62
				c0.08,0.44,0.15,0.89,0.23,1.33c3.35,0,6.7,0,11.16,0c-1.96,2.2-3.01,3.37-4.06,4.55c0.11,0.34,0.23,0.69,0.34,1.03
				c4.09-1.22,8.18-2.43,12.27-3.65c0.26,0.52,0.52,1.03,0.78,1.55c-0.84,1.59-1.67,3.18-2.88,5.47c4.14-1.01,7.54-1.84,10.94-2.67
				c0.2,0.46,0.41,0.91,0.61,1.37c-1.01,1.17-2.01,2.34-3.25,3.79c0.7,0.92,1.45,1.91,1.73,2.28c-0.38,2.27-0.67,4.05-1.04,6.29
				c-0.34,0.12-1.51,0.52-3.46,1.18c2.47,1.82,4.34,3.2,6.22,4.58c-0.05,0.43-0.1,0.87-0.15,1.3c-3.59,0-7.17,0-10.76,0
				c-0.11,0.7-0.22,1.41-0.33,2.11c3.23,0.83,6.45,1.66,10.73,2.76c-2.83,1.21-4.98,2.13-7.13,3.05c0.05,0.45,0.11,0.91,0.16,1.36
				c5.89,0,11.77,0,17.66,0c0.09,0.59,0.18,1.18,0.27,1.78c-1.73,0.68-3.47,1.35-6.28,2.45c2.76,1.02,4.48,1.66,6.2,2.3
				c-0.01,0.24-0.02,0.49-0.04,0.73c-1.93,0-3.86,0-6.16,0c0,1.45,0,2.75,0,4.45c-2.16,0-4.22,0-6.87,0
				c0.59,1.98,1.03,3.44,1.69,5.64c-2.3,0.17-4.44,0.33-6.97,0.52c0.89,1.92,1.74,3.76,2.99,6.46c-3.15-0.62-5.55-1.09-8.79-1.72
				c1.55,2.2,2.57,3.64,4.14,5.87c-2.71-0.27-4.6-0.45-6.87-0.68c3.17,4.1,6.16,4.12,8.77,0.84c2.18-2.74,4.74-5.17,6.2-6.73
				c4.73,2.06,8.91,3.88,13.74,5.98c-1.63,1.08-2.74,1.8-4.07,2.68c1.32,1.06,2.55,2.05,4.97,3.99c-3.69,1.58-6.51,2.78-9.59,4.1
				c0.02,5.04-1.98,6.02-8.11,4.07c-2.59,2.63-4.51,5.46-7.17,6.76c-0.88,0.43-3.68-3.06-6.05-5.14c-0.83,0.73-2.34,2.06-4.34,3.81
				C1142.53,308.84,1142.2,307.01,1141.78,304.68z M1111.07,277.02c1.8,5.98,5.18,6.9,9.77,3.12c3.64-3,7.9-5.25,11.59-8.19
				c1.01-0.8,1.74-3.33,1.22-4.36c-0.84-1.63-1.74-5.05-6.39-1.98c2.29,1.18,4.01,2.06,5.73,2.94c-0.28,0.53-0.57,1.06-0.85,1.59
				c-1.18,0.19-2.36,0.49-3.55,0.54c-1.39,0.06-2.78-0.1-4.31-0.17c1.4,6.53,1.4,6.53-5.57,5.63c-0.43,1.47-0.86,2.96-1.29,4.45
				c-2.37-1.27-4.42-2.36-6.46-3.45c3.1-2.45,7.73-3.99,5.81-8.59c1.53-1.92,2.72-3.28,3.75-4.75c1.27-1.82,1.96-4.55,3.66-5.45
				c4.14-2.18,8.79-3.33,13.07-5.29c1.67-0.76,4.02-2.61,3.97-3.87c-0.07-1.54-2.17-3.2-3.7-4.41c-1.19-0.94-2.86-1.26-4.38-1.88
				c2.81-4.74,2.32-5.57-2.59-6.22c-1.39-0.18-2.7-1.16-3.97-1.91c-1.3-0.77-2.99-2.69-3.66-2.36c-1.39,0.67-2.21,2.51-3.26,3.86
				c1.63,0.7,3.21,1.78,4.9,2.02c2.42,0.35,4.92,0.09,7.39,0.09c0.06,0.42,0.12,0.85,0.18,1.27c-1.76,1.24-3.51,2.47-6.31,4.44
				c5.31,1.47,10.66-0.12,13.49,4.92c-6.24,6.4-13.7,2.7-20.71,2.05c-0.02,0.42-0.04,0.83-0.06,1.25c2.08,0.91,4.16,1.82,6.24,2.73
				c-0.08,0.29-0.15,0.59-0.23,0.88c-3.98,0-7.95,0-11.93,0c-0.19,0.24-0.39,0.47-0.58,0.71c2.51,1.43,5.03,2.85,7.54,4.28
				c-0.1,0.31-0.19,0.62-0.29,0.93c-2.78,0-5.56,0-9.54,0c2.62,1.41,4.04,2.18,5.47,2.95c-0.13,0.35-0.26,0.69-0.39,1.04
				c-2.6-0.5-5.2-1-7.8-1.5c-0.24,0.46-0.47,0.91-0.71,1.37c2.59,1.53,5.19,3.05,8.83,5.19c-2.49,1.45-4.33,3.09-6.45,3.64
				c-6.75,1.74-12.01-1.23-16.88-5.88c1.15,2.55,2.64,5.28,4.75,5.91C1101.21,275.95,1106.2,276.27,1111.07,277.02z M1139.27,276.72
				c0.01-0.72,0.02-1.45,0.03-2.17c2.85,0,5.71,0,9.18,0c-0.76-1.71-1.13-2.53-1.56-3.48c2.01,0.1,3.55,0.17,5.09,0.25
				c0.3-0.45,0.6-0.9,0.9-1.34c-1.47-0.55-2.95-1.1-4.42-1.66c0.42-0.54,0.84-1.07,1.27-1.61c1.99,0.71,3.98,1.41,6.9,2.44
				c-4.06-5.4-7.59-5.52-11.04-1.42c-0.46,0.55-1.35,0.72-1.96,1.17c-2.43,1.79-4.97,3.47-7.11,5.56c-0.45,0.44,0.27,2.85,1.11,3.65
				c2.02,1.92,7.58,1.65,10.45-0.3C1145.05,277.43,1142.16,277.07,1139.27,276.72z"/>
			<path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M772.18,334.91c-2.71,0.45-5.8,0.26-8.02,1.55c-1.79,1.04-2.55,3.87-3.77,5.9c0.42,0.6,0.85,1.2,1.27,1.8
				c-5.87,0.64-11.77,2-17.6,1.75c-11.11-0.47-22.76-0.09-33.1-3.43c-8.84-2.86-17.17-2.28-25.79-2.58c-2.6-0.09-5.22,0.5-7.83,0.78
				c-1.04-4.95-3.11-6.07-7.98-3.65c-4.34,2.16-8.4,4.29-13.59,4.01c-5.25-0.28-9.9-0.75-14.38-4.29c-4.27-3.38-9.58-5.09-15.41-2.87
				c-2.38,0.9-5.12,0.87-8.49,1.38c0.78-1.52,1.17-2.18,1.46-2.88c0.72-1.73,1.39-3.49,2.08-5.23c-1.97-0.68-4.1-2.22-5.9-1.91
				c-11.65,2.04-22.76-1.45-34.09-2.72c-3.02-0.34-6.41,0.72-9.35,1.87c-4.42,1.73-8.59,4.14-12.92,1.56
				c1.16-2.69,1.81-5.18,3.22-7.13c0.98-1.34,2.9-2.31,4.58-2.77c6.84-1.87,13.77-3.38,20.6-5.28c1.17-0.33,2.53-1.82,2.85-3.02
				c1.69-6.46,5-9.51,11.82-10.37c5.28-0.67,10.57-1.26,15.82-2.07c1.18-0.18,3.07-0.93,3.24-1.72c0.99-4.48,4.4-3.71,7.48-3.95
				c5.11-0.4,10.22-1.29,15.33-1.32c7.55-0.04,15.1,0.51,22.66,0.77c2.47,0.08,4.94,0.11,7.41,0.04c4.61-0.13,6.67,2.14,5.68,6.38
				c-1.54,6.61-0.87,7.62,5.82,8.74c9.34,1.57,18.79,2.71,27.98,4.89c3.95,0.94,7.36,4.07,11.04,6.17c2.28,1.3,4.54,2.63,6.9,3.78
				c6.22,3.04,12.38,6.27,18.78,8.88c4.4,1.79,9.15,2.73,13.78,3.91c2.8,0.72,5.67,1.17,8.51,1.74
				C772.24,334.04,772.21,334.47,772.18,334.91z"/>
			<path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M995.2,364.32c-0.71,0.45-1.63,1.5-2.26,1.35c-4.32-1.08-8.29,2.13-11.73,1.81
				c-6.71-0.64-11.22,4.35-17.33,4.73c-1.75,0.11-3.31,3.23-4.19,5.83c-2.08,0.52-4.19,0.95-6.23,1.57c-5.7,1.74-6.38,3.55-3.52,8.54
				c0.38,0.66,0.37,1.85,0.04,2.57c-1.24,2.71-0.78,4.58,1.8,6.36c2.59,1.8,1.23,3.44-1.08,4.08c-1.38,0.38-2.97-0.02-4.47-0.01
				c-7.86,0.06-9.96,2.7-7.84,10.61c-4.57,1.4-7.47,4.08-4.64,9.34c-5.43,5.15-1.72,7.85,1.91,12.24c3.03,3.67,5.62,3.59,9.23,3.61
				c1.66,0.01,3.33,0.77,4.97,1.3c2.09,0.67,4.48,2.51,6.15,1.98c4.26-1.36,8.19-3.75,13.52-6.34c2.19,3.54,4.86,7.87,8.22,13.33
				c-3.58-0.81-5.76-1.68-7.94-1.7c-2.34-0.03-4.69,0.69-7.03,1.17c-3.87,0.79-7.78,1.44-11.56,2.53c-3.05,0.88-7.52,0.63-6.41,6.17
				c0.1,0.5-1.47,1.58-2.44,1.99c-3.05,1.31-6.28,2.23-9.24,3.71c-1.29,0.65-3.03,2.43-2.88,3.45c0.22,1.46,1.81,3.62,3.11,3.85
				c3.44,0.61,7.04,0.29,10.57,0.47c1.9,0.1,3.79,0.63,5.69,0.67c2.32,0.05,4.69-0.01,6.97-0.42c1.22-0.22,2.95-2.14,3.32-1.86
				c6.29,4.93,10.05-4.01,15.66-2.81c0.74,0.16,1.92-1.61,2.86-2.51c3.11-3,6.17-2.7,7.9,1.25c1.43,3.26,2.08,6.86,3.27,10.97
				c-3.05,0-5.19,0-8.38,0c1.46,2.58,2.46,4.36,3.8,6.75c-1.19,0.62-2.65,2.02-3.5,1.71c-6.6-2.38-10.62,3.4-15.93,4.97
				c-1.8,0.53-2.8,3.79-4.16,5.81c2.2,0.75,4.36,1.64,6.61,2.21c1.21,0.3,2.57,0.05,3.84,0.05c0.15,5.43,1.63,6.39,6.97,5.81
				c3.8-0.42,7.68-0.08,12.3-0.08c0.28,7.34,1.58,14.99,0.43,22.24c-0.58,3.63-5.63,6.56-9.12,10.3c-4.12-5.21-7.53-9.83-11.26-14.17
				c-7.25-8.45-14.48-16.92-22.11-25.01c-2.79-2.96-6.5-5.22-10.11-7.22c-6.1-3.38-10.41-7.99-12.94-14.57
				c-2.41-6.28-6.54-11.33-11.61-15.95c-3.08-2.8-5.07-7.31-6.36-11.43c-1.64-5.26-4.04-8.97-9.26-11.44
				c-7.19-3.4-8.22-9.44-2.95-15.47c6.7-7.66,9.88-17.13,14.19-26.03c0.5-1.04,0.83-2.28,1.62-3.04
				c7.28-7.08,11.95-15.78,15.95-24.96c1.55-3.56,4.73-4.29,8.4-3.74c15.07,2.26,29.82,1.54,44.11-4.41
				c2.38-0.99,5.18-1.53,7.73-1.38c1.28,0.08,2.6,2.05,3.62,3.38C992.79,360.19,993.84,362.14,995.2,364.32z"/>
			<path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M164,245.16c-5.49,0.13-10.98,0.53-16.45,0.33c-12.8-0.47-25.58-1.46-38.38-1.77
				c-6.95-0.17-13.27,2.14-18.95,6.55c-3.64,2.83-7.86,5.09-12.14,6.84c-2.02,0.83-4.88-0.64-7.18-0.19
				c-10.92,2.14-20.62-2.26-30.48-5.52c-4.87-1.61-8.68,0.56-12.91,2c-6.7,2.29-13.55,4.16-21.48,6.55c0-4.25-0.24-7.18,0.13-10.04
				c0.13-0.98,1.7-2.36,2.78-2.55c11.79-1.97,23.6-3.84,35.44-5.48c3.43-0.48,7.11-0.74,10.45-0.03c10.09,2.14,19.08-0.35,27.75-5.27
				c4.49-2.55,9.39-4.46,13.57-7.42c4.51-3.2,8.92-6.85,12.41-11.09c3.76-4.57,8.21-7.35,13.79-8.08c1.99-0.26,4.62,0.82,6.28,2.14
				c4.99,3.98,10.68,4.86,16.51,3.77c2.62-0.49,4.93-2.92,7.26-4.63c2.99-2.2,5.6-5.07,8.83-6.76c6.47-3.38,13.17-6.04,17.61-12.47
				c3.01-4.36,8.28-3.7,12.43-2.48c9.55,2.81,18.69,1.34,28-0.8c1.73-0.4,4.25,0.52,5.75,1.68c5.48,4.25,10.82,8.72,15.92,13.43
				c1.47,1.36,2.81,3.82,2.72,5.7c-0.16,3.14,1.09,4.31,3.6,5.66c4.77,2.56,9.26,5.65,14.18,8.73c-1.04,0.85-1.38,1.38-1.75,1.4
				c-13.9,0.79-27.81,1.36-41.7,2.38c-5.71,0.42-11.33,1.95-17.03,2.69c-5.09,0.66-10.27,0.65-15.32,1.46
				c-4.9,0.78-9.7,2.21-14.53,3.38c-0.64,0.15-1.55,0.28-1.82,0.72c-5.04,8.29-12.88,4.68-19.8,4.64
				c-12.61-0.08-25.21-0.36-37.82-0.47c-1.16-0.01-2.32,0.67-3.37,1.95c18.58,0,37.16,0,55.73,0
				C164.01,243.11,164.01,244.13,164,245.16z"/>
			<path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M345.05,784.91c-6.44,0.33-13.08-0.18-19.28,1.21c-6.21,1.39-11.99,4.69-19.1,7.62
				c1.87-4.72,3.13-8.33,4.72-11.79c3.42-7.46,6.94-14.88,10.6-22.23c0.62-1.25,2.02-2.79,3.22-2.96c6.89-0.94,11.49-5.31,16.31-9.7
				c1.61-1.46,4.51-1.83,6.88-2.07c5.77-0.59,11.74-0.1,17.31-1.4c4.28-1,8.1-4.03,12.08-6.23c1.58-0.87,3.18-1.8,4.54-2.98
				c1.45-1.26,3.19-2.62,3.81-4.31c2.59-7.05,6.59-12.69,12.92-17.06c5.96-4.11,10.93-9.6,18.66-10.76c1.73-0.26,3.8-1.83,4.7-3.39
				c3.83-6.6,10.79-8.51,16.9-11.57c1.54-0.77,4.89-0.46,5.89,0.68c6.97,7.92,16.32,9.71,25.97,11.02c0.98,0.13,1.98,0.34,2.88,0.71
				c5.7,2.32,7.18,8.23,2.07,11.35c-8.14,4.97-16.72,10.15-25.82,12.37c-9.41,2.3-15.61,7.74-21.46,14.24
				c-3.76,4.18-7.36,7.68-13.27,8.81c-3.34,0.64-6.64,2.95-9.32,5.24c-7.29,6.23-14.24,12.85-21.33,19.32c0,0,0.14-0.12,0.14-0.12
				c-6.63,4.01-13.74,6.54-21.37,8.14c-6.4,1.34-12.53,3.92-18.78,5.95L345.05,784.91z"/>
			<path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M1136.13,580c-1.31-1.66-2.61-3.32-3.92-4.97c0.15,4.14-0.68,7.71-4.67,7.56c-2.71-0.1-5.7-2.54-7.76-4.7
				c-0.64-0.67,1.33-3.84,2.51-5.74c0.02-3.03-11.14,0.36-3.74-7.31c-1.68-1.26-3.07-2.3-4.87-3.66c1.25-0.83,2.13-1.42,2.41-1.61
				c0-2.14-0.4-5.22,0.06-5.36c3.47-1.02,7.1-1.65,10.71-1.96c1.15-0.1,2.41,1.13,4.15,2.02c-1.57-6.72-1.57-6.72,6.23-7.7
				c-0.83-5.73,0.57-6.91,6.34-6.15c2.36,0.31,4.9-0.08,7.27-0.58c3.96-0.83,7.8-2.43,11.78-2.88c2.48-0.28,6.15-1.08,6.38,3.9
				c0.06,1.29,3.03,2.44,4.67,3.66c-0.31,0.41-0.61,0.82-0.92,1.23c-1.65-0.28-3.3-0.56-6.26-1.07c2.01,2.32,3.28,3.78,4.68,5.39
				c-3.46,4.92-7.86-0.33-12,1.53c1.3,2.08,2.48,3.95,3.89,6.19c-1.24,0.65-2.43,1.28-4.38,2.31c1.84,0.35,3.78,1.1,4.07,0.7
				c1.23-1.76,1.97-3.85,3.15-6.39c6.24-0.62,8.14-1.78,9.28-8.18c0.39-2.22-0.87-5.24-2.34-7.15c-1.51-1.96-4.2-3.01-6.37-4.46
				c1.09-5.87,2.55-6.89,10.03-6.73c-0.25-1.42-0.49-2.79-0.78-4.42c1.83-0.3,3.41-0.56,4.6-0.75c0.4-2.85,0.79-5.54,1.18-8.35
				c3.15,1.25,4.99,1.98,6.82,2.7c-0.09-1.68-0.17-3.36-0.3-5.82c2.27,0.4,4.52,0.8,6.92,1.22c0,14.49,0,28.61,0,43.34
				c-1.89-0.37-3.57-0.7-5.08-0.99c-0.83,1.83-1.65,3.63-2.46,5.42c-1.3-1.52-2.61-3.05-4.04-4.72c-1.36,1.63-2.87,3.44-4.95,5.94
				c-1.01-2.67-1.61-4.26-2.21-5.85c-0.46,0.09-0.93,0.18-1.39,0.28c0.44,1.93,0.3,4.47,1.5,5.58c0.94,0.87,3.65-0.49,5.35-0.04
				c4.94,1.3,9.14,0.4,12.4-4.83c0.21,0.57,0.43,1.14,0.64,1.71c0,13.11,0,26.22,0,40.27c-1.97-1.39-3.3-2.33-4.99-3.53
				c-0.5,2.29-0.92,4.22-1.5,6.83c-2.08-1.58-3.48-2.65-5.28-4.02c-1.06,3.73-2.68,6.84-8.02,5.83c0.98-2.43,1.84-4.56,2.93-7.26
				c-1.61,0.49-2.75,0.83-3.48,1.06c-5.09-1.85-3.52-9.64-9.93-10.09c-2.08-0.14-4.14-0.57-6.46,0.16c2.46,0.8,4.92,1.59,7.39,2.39
				c-0.06,0.34-0.12,0.68-0.18,1.02c-2.19-0.17-4.38-0.35-6.57-0.52c-0.12,0.42-0.25,0.83-0.37,1.25c4.01,1.31,8.02,2.62,12.03,3.93
				c-0.1,0.53-0.2,1.07-0.31,1.6c-1.57,0.12-3.14,0.24-6.31,0.48c3.46,2.32,5.77,3.87,8.35,5.6c-4.83,4.56-9.94,3.89-14.26,1.88
				c-0.77,2.22-1.08,5.51-2.18,5.79c-2.07,0.54-4.59-0.64-6.87-1.1c0.16,1.87,1.08,4.28,0.26,5.49c-0.71,1.04-3.42,0.71-5.94,1.1
				c0.9,0.96,2.09,1.77,2.6,2.89c0.7,1.53,0.89,3.3,1.3,4.97c-1.96,0.02-4.04,0.44-5.84-0.08c-1.2-0.35-2.02-2.03-3.06-3.17
				c-2.7,1.92-5.27,5.15-7.95,5.25c-2.45,0.09-5.02-3.21-8.02-5.36c-0.36,0.39-1.26,1.47-2.29,2.41c-4.23,3.91-9.63,3.62-13.05-0.78
				c-1.94-2.5-3.43-1.02-5.52-0.08c-2.63,1.19-6,2.63-8.46,1.91c-5.36-1.55-5.6-4.7-1.28-8.16c-0.38-1.02-0.76-2.05-1.29-3.47
				c2.29-1,4.35-1.91,6.37-2.79c-1.4-6.87-0.76-7.42,8.48-8.37c3.3-3.41,5.7-6.25,8.54-8.55c0.62-0.5,3.17,0.56,4.22,1.55
				c4.48,4.26,9.38,6.08,15.44,3.82c1.04-0.39,2.34-0.34,3.46-0.15c4.98,0.87,8.16-0.68,8.38-6.36c1.47,0.19,2.6,0.49,3.72,0.44
				c2.77-0.15,3.94-1.35,2.73-4.26c-0.62-1.49-0.77-3.19-1.25-5.36c-1.3,0.26-2.94,0.59-5.69,1.15c2.02,2.12,3.28,3.45,4.53,4.77
				c-0.06,0.42-0.11,0.83-0.17,1.25c-3-0.29-6-0.58-8.46-0.81c0.15,2.43,1.31,5.81,0.24,6.82c-3.38,3.19-4.4-1.27-6.36-2.82
				c-0.55-0.44-2.07-0.67-2.11-0.57c-0.55,1.37-0.93,2.82-1.37,4.31c-7.29,0.63-13.57-5.65-12.13-12.49
				c0.12-0.59,2.43-1.46,2.81-1.14c4.44,3.82,7.82,2.06,11.15-1.62c0.81-0.89,2.3-1.15,3.48-1.7c5.14,2.64,5.65,2.39,8.97-3.05
				c2.64-1.74,5.27-3.49,8.14-5.39c0.4,0.25,1.45,0.9,3.16,1.97c0.27-5.71,3.63-5.2,7.13-4.24c3.26,0.89,4.97,2.65,2.47,6.4
				c3.53,0.6,6.47,1.1,10.46,1.77c-2.21,1.35-3.33,2.03-3.35,2.05c1.07,1.85,2.09,3.61,3.11,5.38c0.36-0.25,0.72-0.51,1.08-0.76
				c-0.23-1.2-1.07-2.75-0.6-3.53c2.69-4.56-0.22-4.81-3.56-5.44c-1.18-0.22-2.14-1.84-3.12-2.89c-1.07-1.13-1.82-2.86-3.11-3.43
				c-3.12-1.37-6.47-2.21-10.2-3.42c0-1.87,0-4.38,0-7.38c-2.13-0.39-4.48-0.81-8.04-1.46c2.8,3.31,4.56,5.38,7.4,8.73
				c-4.47,0-7.43,0-10.06,0c0.88,2.35,1.78,4.77,2.68,7.18c-2.72,0-5.45,0-8.12,0C1141.91,579.62,1141.91,579.62,1136.13,580z
				M1185.67,580.68c1.79,1.37,3.01,2.29,3.35,2.55c-1.88,3.24-3.43,5.91-5.18,8.93c6.4,1.05,4.9-6.35,8.8-7.02
				c-0.57-2.08-1.05-3.8-1.5-5.52c-0.33-1.26-0.33-3.42-0.97-3.63c-1.63-0.52-3.54-0.17-5.34-0.17c0.02,0.55,0.04,1.1,0.06,1.65
				c1.28,0.12,2.55,0.24,4.34,0.41C1187.88,578.93,1186.92,579.7,1185.67,580.68z"/>
			<path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M536.34,343.11c-3.27-0.29-5.7-0.5-8.12-0.72c0.05-0.55-0.03-1.01,0.13-1.11
				c6.27-3.98,6.4-6.84-0.18-10.35c-7.67-4.1-15.63-7.66-23.54-11.28c-1.43-0.65-3.27-0.63-4.91-0.59
				c-9.6,0.23-19.21,0.76-28.81,0.75c-4.4,0-8.79-1.32-13.21-1.46c-4.24-0.13-8.51,0.49-12.77,0.78c-4.51,0.31-9.01,0.62-13.62-0.13
				c6.8-3.3,14.03-5.22,21.22-3.53c12,2.83,23.76,0.97,35.69,0.15c8.72-0.6,17.54,0.09,26.31,0.39c1.57,0.05,3.7,0.6,4.55,1.71
				c4.59,5.99,11.57,5.56,17.76,7.54c4.01,1.29,8.8,3.91,10.69,7.34c6.88,12.47,17.07,22.06,26.27,32.5
				c7.14,8.1,14.65,15.87,22.62,24.46c-5.29,1.38-10.06,2.36-14.65,3.9c-6.75,2.25-13.49,4.64-19.96,7.55
				c-1.88,0.85-3.55,3.54-4.07,5.69c-0.66,2.76-1.8,4.1-4.38,5.27c-10.57,4.81-20.73,10.62-31.53,14.79
				c-7.79,3.01-16.33,4.14-24.6,5.74c-1.04,0.2-3.37-1.68-3.65-2.93c-2.65-11.64-4.94-23.36-7.43-35.04c-0.5-2.34-1.41-4.58-2.3-7.4
				c2.92,0,6.69,0.03,10.47-0.01c4.78-0.04,9.68,0.45,14.31-0.42c2.91-0.55,6.57-2.64,7.72-5.07c0.76-1.59-2.35-5.03-3.75-7.63
				c-0.22-0.41-0.52-0.78-1.15-1.72c6-2.63,13.88,0.6,18.28-6.35c-0.92-1.06-1.95-2.23-3.46-3.96c3.95-1.28,7.39-2.39,10.82-3.52
				c1.42-0.47,3.06-0.68,4.18-1.55c1.71-1.34,3.05-3.14,4.55-4.75c-1.72-1.06-3.37-2.84-5.19-3.02
				C536.9,348.76,535.02,347.53,536.34,343.11z"/>
			<path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M715.62,9.22c-1.02,3.31-1.59,6.24-2.8,8.87c-2.36,5.12-5.06,10.08-7.66,15.09
				c-1.75,3.38-3.87,5.96-8.38,5.73c-1.67-0.09-4.26,1.2-5.02,2.61c-2.98,5.56-7.77,6.37-13.41,7.67
				c-9.43,2.16-19.48,1.97-28.32,5.01c-23.95,8.26-44.15-2.11-64.29-11.43c-13.37-6.19-19.82-19.04-23.26-33.55
				C613.42,9.22,664.08,9.22,715.62,9.22z"/>
			<path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M906.09,480.93c0.39-2.26,1.47-4.67,1.04-6.77c-1.84-9.03-2-9,4.06-18.43c3.84,4.98,8.4,9.11,10.7,14.26
				c4.26,9.56,10.34,16.71,19.58,21.71c3.64,1.97,6.53,5.54,9.35,8.75c8.33,9.48,16.53,19.07,24.6,28.76
				c2.31,2.78,5.88,5.52,2.62,10.09c1.3,0.58,2.31,1.03,3.52,1.57c-0.15,1.67,0.21,3.73-0.6,5.08c-1.19,1.99-3.32,5.01-4.88,4.91
				c-7.87-0.51-15.68-1.86-23.51-2.99c-1.13-0.16-2.21-1.02-3.3-1.01c-8.83,0.14-17.66,0.46-26.48,0.63
				c-2.78,0.05-5.72,0.31-8.32-0.43c-6.51-1.85-12.91-5.78-19.06,2.07c-0.27-0.45-0.55-0.91-0.82-1.36
				c-0.26-3.55-0.38-7.12-0.82-10.65c-0.56-4.4-0.94-8.9-2.19-13.13c-2.43-8.29-3.89-16.4-0.85-24.91c0.7-1.95-0.02-4.56-0.52-6.77
				c-1.42-6.3-1.4-12.36,0.67-18.63c1.14-3.46,0.76-7.41,1.16-11.12c0.15-1.44,0.59-2.85,0.79-4.29c0.2-1.41,0.92-3.41,0.27-4.18
				c-3.09-3.63-1.85-6.98-0.25-10.64c1.18-2.69,1.87-5.6,2.77-8.39c6.86,1.65,9.22,5.39,10.71,11.48c1.49,6.06,1.69,11.06-1.94,16.47
				c-1.26,1.87-0.16,5.45,0.15,8.21c0.37,3.27,1.05,6.5,1.59,9.75c-1.53,3.29-3.55,6.45-4.46,9.91c-0.98,3.75-0.95,7.77-1.29,11.68
				c-0.11,1.23-0.02,2.49-0.02,3.73c0.36-0.01,0.71-0.03,1.07-0.04c0.81-3.51,1.62-7.02,2.58-11.16c3.28,2.39,5.75,4.19,8.22,5.99
				c-0.56,0.09-1.12,0.19-1.67,0.28c-0.74,4.77-1.48,9.55-2.22,14.32c0.69,0.11,1.38,0.22,2.06,0.32c0.47-3.48,0.94-6.96,1.49-11.02
				c1.49,2.94,2.55,5.05,3.62,7.17c0.41-0.23,0.83-0.46,1.24-0.69c-2.52-5.81-1.77-13.19-9.6-16.4c-4.12-1.69-4.91-5.45-2.68-9.59
				C905.25,484.08,905.57,482.45,906.09,480.93z M916.57,481.33c-0.91,0.47-1.83,0.94-2.74,1.42c1.1,2.97,1.97,6.05,3.42,8.84
				c0.44,0.84,2.53,1.28,3.71,1.08c0.56-0.1,1.44-2.53,1.03-3.27C920.44,486.57,918.42,484,916.57,481.33z"/>
			<path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M917.42,314.65c3.02-5.92,5.71-6.14,16.55-1.84c-2.99-6.48-9.76-2.7-13.8-5.61
				c14.96-3.45,29.54-0.15,44.34,0.52c-2.2,5.92,0.37,8.55,6.68,8.2c6.23-0.35,12.49-0.08,18.74-0.08c0.16,0.43,0.31,0.86,0.47,1.3
				c-2.33,1.15-4.8,2.09-6.96,3.5c-1.79,1.17-3.22,2.88-4.8,4.35c2.02,1.51,3.91,4.08,6.07,4.32c5.58,0.62,11.27,0.21,16.89,0.21
				c-0.76,1.82-1.61,3.82-2.57,6.12c-2.95,0.8-8.47-0.86-6.52,5.67c-4.04,2.58-9.57,3.18-9.09,9.61c0.04,0.59-1.59,1.55-2.59,1.94
				c-13.39,5.2-27.03,7.84-41.41,4.53c-3.34-0.77-6.94-0.62-10.41-0.53c-1.35,0.04-3.57,0.78-3.87,1.7c-1.62,4.99-5.51,4.14-9,3.71
				c-6.06-0.75-12.05-2.06-18.1-2.86c-4.25-0.56-8.62-1.26-12.84-0.87c-10.24,0.93-20.41,2.63-30.64,3.7
				c-8.54,0.89-15.24,4.06-18.75,12.47c-1.11,2.65-2.95,4.98-4.6,7.7c-5.77-7.51-4.65-13.11,2.81-16.77
				c4.61-2.26,9.35-4.25,13.89-6.63c1.03-0.54,2.25-2.32,2.07-3.29c-0.8-4.39,1.99-6.13,5.13-7.44c5.36-2.23,11.01-3.82,16.23-6.32
				c5.95-2.85,11.77-6.1,17.26-9.75c4.71-3.13,6.82-8.02,6.49-13.81c-0.09-1.66-0.33-3.46,0.19-4.95c0.42-1.22,1.87-2.09,2.86-3.12
				c0.56,1.14,1.47,2.24,1.61,3.43c0.2,1.82-0.13,3.71-0.24,5.56c0.56,0.1,1.12,0.2,1.68,0.3c0.57-2.69,1.14-5.39,1.71-8.08
				c0.49,0.04,0.97,0.08,1.46,0.12c0,4.3,0,8.59,0,12.89c0.44,0.03,0.88,0.06,1.32,0.09c0.26-1.94,0.6-3.86,0.77-5.81
				c0.15-1.65-0.23-3.45,0.29-4.95c0.38-1.1,2.35-2.77,2.73-2.56c1.37,0.75,2.37,2.18,3.64,3.49c0.42-0.8,1.19-2.29,2.31-4.46
				C916.26,312.13,916.79,313.29,917.42,314.65z M939.04,312.33c5.84,0.97,11.6,1.93,17.43,2.9
				C953.37,310.53,943.3,309.04,939.04,312.33z M945.57,315.85c-4.6,0-9.19,0-13.79,0C936.31,319.23,940.93,318.23,945.57,315.85z"/>
			<path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M984.04,196.86c1.9,1.56,3.57,3.74,5.75,4.55c5.36,2,8.79,4.99,10.51,10.84
				c2.03,6.89,5.37,13.4,8.24,20.03c0.61,1.41,1.64,2.65,2.48,3.96c0.41-0.18,0.82-0.37,1.24-0.55c-2.53-7.85-5.07-15.69-7.64-23.66
				c15.3-0.56,30.53-1.12,45.75-1.68c0.07,0.59,0.14,1.18,0.2,1.78c-1.25,0.22-2.49,0.62-3.74,0.62c-9.66,0.03-19.32-0.1-28.98,0.03
				c-2.81,0.04-5.61,0.74-8.3,2.01c15.22-2.11,30.83,3.26,46.72-2.45c0.35,1.19,1.04,2.9,1.34,4.67c0.59,3.53,2.04,5.8,5.71,7.33
				c2.09,0.87,3.14,4.25,4.57,6.36c2.56-3.14,2.1-7.43-1.88-8.29c-4.26-0.92-5.33-3.55-5.64-6.97c-0.38-4.19-1.61-7.04-6.61-6.81
				c-0.87,0.04-1.82-1.79-2.74-2.76c1.15-0.62,2.27-1.67,3.46-1.78c10.1-0.9,20.22-1.62,30.32-2.42c0.59-0.05,1.17-0.35,1.75-0.53
				c0.01-0.48,0.03-0.96,0.04-1.45c-7.17-0.65-14.34-1.87-21.5-1.79c-8.6,0.09-16.84-0.99-24.82-4.08c-1.03-0.4-2.3-1.02-2.76-1.9
				c-1.95-3.71-3.72-7.52-5.35-11.38c-0.28-0.66,0.14-2.19,0.7-2.51c0.85-0.49,2.23-0.62,3.16-0.28c3.88,1.43,7.63,3.23,11.53,4.61
				c1.67,0.59,3.62,0.78,5.39,0.61c8.57-0.82,17.1-2.28,25.68-2.54c3.39-0.1,6.85,2.35,10.17,4.63c-16.95,3.42-33.91,0.51-50.81,0.15
				c-0.09,0.44-0.17,0.89-0.26,1.33c1.25,0.36,2.49,0.99,3.75,1.04c12.31,0.51,24.62,0.98,36.94,1.32c2.11,0.06,4.24-0.55,6.36-0.82
				c0.98-0.12,2.34-0.63,2.91-0.18c3.82,3.03,8.11,3.76,12.82,3.43c0.47-0.03,1.16,1.69,1.6,2.67c1.2,2.64,2.33,5.32,3.48,7.98
				c0.38-0.17,0.76-0.35,1.13-0.52c-0.82,2.29-1.64,4.58-2.68,7.47c-0.45,0.1-1.75,0.35-3.02,0.7c-9.88,2.73-11.24,4.05-13.92,12.94
				c-1.21,4.01-4.26,6.51-7.98,7.74c-4.84,1.6-9.78,2.61-10.63,8.91c-0.09,0.66-1.34,1.44-2.18,1.69c-5.8,1.68-11.66,3.18-17.46,4.88
				c-0.83,0.24-1.94,1.14-2.07,1.89c-0.75,4.31-3.75,4.26-7.09,4.17c-15.65-0.43-31.31-0.69-46.96-1.18
				c-2.61-0.08-5.19-0.95-8.24-1.54c2.31-6.32-1.89-5.39-5.42-5.57c-4.07-0.21-8.13-0.76-12.2-1.9c7.31-2.94,14.8-4.08,22.51-3.45
				c7.11,0.58,14.19,1.42,21.29,2.09c8.72,0.82,17.44,1.64,26.17,2.31c1.64,0.13,3.34-0.4,5-0.63c-0.03-0.61-0.06-1.21-0.09-1.82
				c-2.66,0-5.34,0.2-7.98-0.04c-9.03-0.8-18.04-1.7-27.05-2.69c-1.27-0.14-2.65-0.74-3.65-1.54c-7.68-6.13-15.23-12.45-22.96-18.52
				c-1.85-1.45-4.24-2.21-5.61-2.91c1.7-5.08,3.72-10.37,5.21-15.8c0.87-3.19,2.59-2.15,4.45-1.64
				C984.16,196.99,984.04,196.86,984.04,196.86z"/>
			<path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M413.22,325.05c0.87,3.49,2.21,6.65,2.29,9.84c0.08,3.13-0.66,6.49-1.87,9.41
				c-1.64,3.96-3.14,7.59-0.27,11.15c2.13-6.55,4.26-13.12,6.39-19.69c0.77,0.05,1.54,0.11,2.31,0.16
				c0.38,5.04,0.77,10.09,1.15,15.13c0.5,0.02,1,0.03,1.5,0.05c0-3.38,0.39-6.82-0.09-10.13c-0.67-4.63,1.29-6.04,5.5-5.85
				c2.32,0.1,4.7-0.4,6.98-0.09c1.46,0.2,2.78,1.39,4.17,2.14c-1.08,1.12-2.17,2.24-3.09,3.19c1.02,0.89,3.34,1.95,3.79,3.54
				c0.55,1.94-0.46,4.32-0.79,6.51c0.46,0.12,0.92,0.24,1.37,0.37c0.34-1.03,0.92-2.05,0.98-3.1c0.38-7.1,0.35-7.1,8-6.43
				c0.21,3.63,0.42,7.33,0.64,11.24c11.69,0,22.81,0,33.93,0c0.03-0.4,0.07-0.8,0.1-1.21c-9.49-0.57-18.98-1.13-28.38-1.69
				c9.41-2.85,18.88-5.71,28.34-8.57c-0.09-0.54-0.19-1.08-0.28-1.61c-7.92-2.68-16.12-1.29-25.04-0.79c0.64-2.78,1.19-5.17,1.9-8.24
				c1.67,0.09,3.74,0.2,6.83,0.37c-1.22,0.96-1.69,1.33-2.55,2c10,0,19.61,0,29.22,0c0.06-0.42,0.11-0.84,0.17-1.26
				c-0.75-0.27-1.5-0.79-2.24-0.78c-5.65,0.1-11.3,0.4-16.95,0.38c-1.28-0.01-3.7-1.36-3.64-1.57c0.55-2.01,1.45-5.54,2.36-5.57
				c10.42-0.33,20.85-0.15,31.28-0.03c0.95,0.01,1.97,0.44,2.81,0.93c5.27,3.02,10.52,6.06,15.7,9.23c1.04,0.64,1.72,1.86,2.47,2.71
				c-0.34,0.65-0.41,1.1-0.64,1.19c-2.32,1-6.07,1.21-4.7,4.86c0.56,1.49,3.77,2.02,5.82,2.91c0.99,0.43,2.26,0.41,3.06,1.03
				c2.63,2.05,5.1,4.29,7.84,6.64c-0.79,0.4-2.64,1.56-4.64,2.28c-2.64,0.96-5.47,1.42-8.12,2.36c-5.5,1.95-5.88,3.37-1.72,8.88
				c-3.89,0.48-7.26,0.66-10.5,1.37c-2.43,0.53-4.71,1.75-7.05,2.66c1.06,1.89,1.85,4.01,3.23,5.61c3.3,3.83,3.13,5.83-1.91,6.73
				c-5.67,1.01-11.55,0.86-17.35,1.05c-2.32,0.07-4.65-0.38-6.97-0.46c-6.39-0.21-7.54,1.55-4.24,7.68
				c-1.59,0.18-3.48,0.96-4.13,0.38c-4.8-4.31-9.25-1.46-13.94,0.12c-2.79,0.94-5.83,1.12-8.75,1.64c-0.17-0.56-0.35-1.12-0.52-1.69
				c2.49-1.7,4.99-3.4,7.81-5.32c-1.02-1.53-1.9-2.85-3.16-4.75c4.45-3.88,9.51-6.09,15.54-6.06c4.83,0.02,9.7,0.48,14.46-0.05
				c3.15-0.35,6.56-1.53,9.1-3.38c3.67-2.67,2.82-6.94-1.61-7.59c-4.6-0.68-9.42,0.32-14.16,0.47c-8.4,0.26-16.8,0.52-25.21,0.56
				c-1.32,0.01-2.77-0.97-3.95-1.79c-1.66-1.15-3.27-3.82-4.64-3.65c-3.34,0.39-6.6,1.91-9.79,3.21c-3.94,1.61-7.77,3.47-13.05,5.85
				c0.27-4.09,0.41-6.24,0.63-9.6c-2.12,1.16-3.85,1.76-5.15,2.87c-4.9,4.17-9.1,4.57-14.83,1.8c-1.57-0.76-3.78-0.38-5.68-0.26
				c-4.71,0.28-9.42,0.72-14.13,1.09c-0.28-0.47-0.55-0.95-0.83-1.42c1.31-2.36,2.47-4.81,3.94-7.07
				c4.33-6.64,8.92-13.11,13.12-19.82C404.48,332.2,406.99,326.9,413.22,325.05z M477.07,347.62c0.05,0.56,0.11,1.11,0.16,1.67
				c7.68-0.64,15.36-1.27,23.04-1.91c-0.06-0.6-0.12-1.2-0.18-1.8C492.41,346.26,484.74,346.94,477.07,347.62z M506.12,342.48
				c-0.01-0.58-0.01-1.16-0.02-1.74c-6.35,0.48-12.7,0.96-19.06,1.43c0.03,0.48,0.06,0.97,0.09,1.45
				C493.46,343.24,499.79,342.86,506.12,342.48z"/>
			<path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M11.04,491.03c-1.02,1.58-2.04,3.16-3.11,4.81c0.64,0.19,1.12,0.43,1.2,0.34
				c7.04-8.79,17.53-12.21,26.89-17.43c4.77-2.66,8.98-6.37,13.83-8.83c2.89-1.47,6.59-2.14,9.86-1.94
				c15.38,0.91,29.63-3.22,43.48-9.17c9.11-3.91,18.04-8.25,27.06-12.4c0.55-0.25,1.11-0.49,1.67-0.73
				c-0.14-0.48-0.28-0.96-0.42-1.44c-4.53,1.19-9.15,2.11-13.55,3.67c-3.1,1.1-6.1,2.79-8.78,4.72
				c-12.94,9.3-27.86,12.13-43.23,13.49c-2.38,0.21-4.83-0.46-7.42-1.33c18.97-4.08,32-17,45.87-28.63c-0.3-0.53-0.6-1.06-0.89-1.59
				c-1.43,0.87-2.93,1.63-4.26,2.63c-9.3,6.95-18.46,14.08-27.89,20.84c-2.53,1.81-5.86,2.49-9.09,3.8
				c1.31-6.54,2.32-12.22,3.67-17.81c0.31-1.29,1.62-2.99,2.79-3.32c8.92-2.52,17.82-5.38,26.93-6.88
				c9.44-1.56,19.15-1.46,28.68-2.58c5.44-0.64,10.76-2.26,16.08-3.43c-0.51,14.02-5.61,26.74-13.28,38.49
				c-1.14,1.75-3.6,2.92-5.7,3.68c-3.53,1.29-4.85,3.36-4.61,7.14c0.61,9.74-5.97,16.73-15.71,16.83
				c-11.49,0.12-22.99,0.26-34.48-0.05c-4.76-0.13-9.53-1.24-14.24-2.19c-8.57-1.72-15.84,0.98-23.19,5.26
				c-6.95,4.05-14.61,6.87-22.57,10.51c-1.09-7.12-1.56-12.7,4.24-16.6C10.85,490.87,11.04,491.03,11.04,491.03z"/>
			<path onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M881.41,576.57c-2.57-5.64-0.24-9.65,3.92-13.53c3.64-3.39,6.64-7.44,9.97-11.16
				c3.48-3.9,7.33-5.24,12.86-3.88c5.81,1.43,12.03,1.2,18.07,1.71c1.15,0.1,2.29,0.39,3.43,0.38c3.86-0.04,7.72-0.15,11.58-0.3
				c2.3-0.09,5.16-1.27,6.8-0.31c8.72,5.08,18.22,4.21,27.6,4.5c0.42,0.01,0.84,0.31,2.21,0.84c-8.02,9.79-15.78,19.16-23.39,28.66
				c-1.44,1.8-2.23,4.11-3.32,6.19c2.26,0.64,4.51,1.29,6.78,1.91c0.47,0.13,0.96,0.15,1.53,0.22c-0.46,4.13-8.66,6.24-2.54,12.5
				c-6.97,5.77-14.03,11.62-21.43,17.75c5.58,4.26,10.35-0.51,16.03,0.01c-3.68,3.33-6.79,6.13-10.27,9.27
				c-6.02-6.15-11.89-12.51-18.17-18.44c-4.19-3.95-8.98-7.25-13.45-10.91c-2.73-2.23-5.28-4.66-8.05-6.84
				c-2.77-2.18-5.7-4.15-8.56-6.21C889.15,584.8,885.28,580.69,881.41,576.57z"/>
			<path onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M292.76,225.8c2.4-2.21,4.85-4.37,7.17-6.65c2.37-2.33,4.97-4.53,6.81-7.24c2.52-3.7,5.21-5.7,9.96-4.47
				c1.44,0.37,3.9-0.48,4.9-1.63c2.66-3.08,5.86-3.46,9.46-3.1c6.77,0.69,13.53,1.58,20.32,1.97c4.12,0.24,8.3-0.11,12.42-0.53
				c3.94-0.4,6.03,0.51,7.93,4.66c2.27,4.94,6.47,9.03,9.99,13.36c2.28,2.81,5.02,5.26,7.23,8.12c2.16,2.8,3.9,5.7,8.16,5.7
				c1.16,0,2.64,1.79,3.4,3.09c3.01,5.11,5.09,11.14,12.83,10.55c0.78-0.06,1.7,0.93,2.46,1.55c3.2,2.62,6.37,5.3,8.24,6.85
				c-5.99,0.73-13.79,2.06-21.63,2.49c-5.05,0.28-10.2-0.6-15.24-1.38c-1.09-0.17-2.05-2-2.83-3.22c-1.16-1.8-1.87-3.92-3.18-5.59
				c-3.2-4.08-5.43-4.06-9.76-1.12c-3.95,2.68-8.04,5.28-12.38,7.21c-2.26,1.01-5.55,1.38-7.76,0.5c-6.7-2.67-13.09-1.41-19.52,0.38
				c-3.39,0.94-6.15,0.57-8.6-2.13c-2.57-2.83-5.5-5.35-7.96-8.27c-1.08-1.29-2.01-3.29-1.9-4.88c0.87-12.15-3.15-12.31-14.79-14.33
				c-1.72-0.3-3.49-0.24-5.24-0.34C293.1,226.83,292.93,226.31,292.76,225.8z"/>
			<path onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M208.22,500.56c2.56-6.31,4.69-11.44,6.73-16.61c2.06-5.24,2.58-10.46,2.47-16.29
				c-0.16-8.2,2.41-16.46,3.74-24.24c11.77-2.26,21.7,0.81,31.59,3.44c8.19,2.18,16.33,2.22,24.65,0.45c-4.23,0-8.47,0-12.8,0
				c-1.2-3.94-0.33-5.79,3.38-5.53c5.14,0.35,10.31,0.56,15.37,1.42c1.32,0.22,3.39,2.85,3.16,3.98c-0.52,2.57,4.98,6.13-1.86,7.87
				c5.8-0.55,7.14,2.8,8.13,6.76c1,4.02,2.16,8.01,3.52,11.92c2.21,6.33,1.4,12.38-1.18,18.71c-6.39-1.78-10.32,1.1-14.83,5.74
				c-4.86,4.99-11.92,7.84-19.1,10.98c3.2-17.65,6.44-34.78,3.58-52.85c5.86,0,10.92,0,16.96,0c-6.03-3.31-12.93,2-17.11-3.63
				c-8.5,4.05-17.04-2.45-25.64,0.12c6.52,0.92,13.02,2.15,19.58,2.65c4.09,0.31,5.38,1.77,5.36,5.81
				c-0.08,12.97,0.54,25.93-4.74,38.31c-1.3,3.04-1.01,6.75-1.61,11.42C242.78,499.14,226.22,498.45,208.22,500.56z M223.96,476.91
				c10.06,1.04,19.89,2.05,29.72,3.06C245.13,475.83,229.82,474.63,223.96,476.91z M229.24,471.89c-0.01,0.43-0.02,0.86-0.03,1.29
				c3.69,0,7.37,0,11.06,0c0.01-0.43,0.02-0.86,0.03-1.29C236.61,471.89,232.93,471.89,229.24,471.89z M242.89,469
				c0.05-0.51,0.11-1.02,0.16-1.53c-5.47,0-10.93,0-16.4,0c-0.03,0.24-0.06,0.47-0.1,0.71C232,468.45,237.44,468.72,242.89,469z"/>
			<path onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="M816.59,367.66c-2.5,1.33-4.13,2.32-5.86,3.09c-13.42,5.98-27.77,7.01-42.07,6.61
				c-22.22-0.62-44.42-2.2-66.62-3.51c-2.6-0.15-5.16-1.13-7.72-1.82c-2.66-0.72-5.32-1.27-3.57-5.35c0.29-0.68-2.27-3.3-3.94-4
				c-6.11-2.56-12.44-4.58-18.6-7.04c-1.59-0.64-3.05-1.95-4.19-3.28c-2.09-2.43-1.79-4.77,1.51-5.65
				c6.08-1.62,12.19-3.68,18.39-4.15c7.98-0.6,16.31-1.76,23.73,3.27c1.5,1.02,3.84,0.84,5.8,1.12c20.78,2.93,41.43,3.14,61.79-2.95
				c4.04-1.21,7.2,0.02,11.1,1.99c5.25,2.65,11.71,2.96,17.68,4.13c4.03,0.8,8.11,1.36,12.17,2.02c-0.04,0.52-0.09,1.05-0.13,1.57
				c-1.46,0.61-2.92,1.23-4.7,1.98c0.42,1.3,0.79,2.47,1.19,3.72c-1.53,0.93-3.03,1.84-5.56,3.38
				C810.33,364.48,812.83,365.75,816.59,367.66z"/>
			<path onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="M90.07,628.01c-2.67-3.55-5.03-7.4-8.12-10.54c-1.68-1.71-4.63-3.22-6.9-3.08
				c-5.46,0.34-9.5-1.81-12.54-5.78c-6.66-8.7-13.02-17.64-19.47-26.51c-0.24-0.33-0.13-0.9-0.25-1.95c3.43,1.7,6.38,3.51,9.57,4.65
				c4.78,1.71,9.83,2.7,14.58,4.49c2.45,0.92,5.26,2.45,6.55,4.54c3.14,5.08,6.41,8.03,12.84,9.98c5.62,1.7,11.02-0.87,16.43,2
				c4.81,2.56,10.89,2.68,16.33,4.15c2.16,0.59,4.37,1.61,6.12,2.99c11.9,9.34,23.7,18.81,35.49,28.28
				c4.66,3.74,9.43,7.39,13.7,11.55c1.79,1.75,1.98,5.07,3.65,7.03c1.82,2.13,4.31,3.99,6.88,5.12c3.48,1.53,4.53,3.57,2.11,6.39
				c-3.49,4.07-1.64,6.83,1.51,9.7c2.46,2.24,4.76,4.65,7.11,7c5.35,5.36,11.63,8.74,19.29,9.58c1.07,0.12,1.95,1.88,2.92,2.89
				c-1.32,0.47-3.1,1.75-3.89,1.3c-11.19-6.4-24.59-8.1-34.73-16.92c-2.87-2.49-7.56-3.31-11.57-3.97
				c-6.96-1.14-11.97-4.6-15.49-10.55c-4.58-7.74-9.84-14.61-19.31-16.92c-1.7-0.41-3.23-2.31-4.44-3.84
				c-4.83-6.14-11.39-9.35-18.49-12.14c-6.86-2.69-13.35-6.34-20-9.58L90.07,628.01z"/>
			<path onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="M1063.15,485.93c-2.7,1.47-5.41,2.94-8.11,4.4c0.21,0.48,0.41,0.95,0.62,1.43
				c-5.19-0.91-10.63-3-15.15,4.56c-4.22-10.24-7.93-19.22-11.93-28.91c-2.9,4.03-3.04,11.93-12.24,6.9
				c1.92-1.03,3.42-1.84,5.59-3.01c-2.74-1.31-4.58-2.19-6.99-3.34c1.59-1.5,2.83-2.68,4.26-4.02c-5.06-8.72,6.21-4.38,7.13-9.21
				c-2.62-0.95-5.43-1.77-8.04-3c-1.2-0.56-2.03-1.93-3.02-2.93c1.08-0.86,2.05-1.98,3.27-2.53c1.61-0.72,3.42-1,5.55-1.57
				c-3.59-1.22-6.71-2.29-9.83-3.36c-0.16-0.61-0.32-1.23-0.49-1.84c6.6-3.82,13.2-7.64,20.16-11.67c-0.33-4.37,0.74-5.75,5.91-4.43
				c6.24,1.6,11.11,1.52,15.08-4.66c2.31-3.61,5.56-2.82,8.14,1.2c1.94-1.29,3.88-2.59,5.9-3.93c0.82,2.53,1.42,4.39,2.4,7.43
				c0.39-0.2-0.31,0.36-1.09,0.53c-7.73,1.66-15.52,3.1-23.2,4.96c-2.62,0.64-5.55,1.77-7.33,3.63c-1.88,1.96-4.09,5.68-3.36,7.53
				c0.89,2.26,4.57,4.45,7.25,4.74c3.95,0.43,5.85,1.87,7.2,5.44c1.47,3.89,4.9,4.52,8.72,3.87c2.74-0.46,5.52-0.7,8.28-1.06
				c2.93-0.38,5.63-0.48,7.29-3.98c2.33-4.91,7.4-5.37,13.06-5.75c5.64-0.38,10.49,4.99,16.59,1.08c2.62-1.68,4.66,1.17,3.63,4.88
				c5.01-0.22,10-1.21,14.62,3.01c-2.65,0.54-4.62,0.93-6.58,1.33c-0.14,0.42-0.28,0.83-0.42,1.25c2.42,1.17,4.85,2.33,7.27,3.5
				c0.07,0.44,0.14,0.87,0.21,1.31c-3.08,0.65-6.15,1.3-9.28,1.96c2.65,2.14,5.23,4.24,7.82,6.33c-0.26,0.57-0.52,1.15-0.78,1.72
				c-1.62-0.07-3.24-0.15-5.22-0.24c0.32,0.94,0.72,1.77,0.88,2.65c0.33,1.83,0.54,3.68,0.8,5.52c-1.66,0.06-3.33,0-4.97,0.21
				c-1.58,0.2-3.12,0.69-5.25,1.19c0.89-2.87,3.79-6.08-1.17-8.32c-0.43,4.38-2.11,6.35-5.64,3.25c-0.66,2.3-1.3,4.54-1.94,6.78
				c-0.55-0.06-1.09-0.11-1.64-0.17c-1.18-2.34-2.36-4.68-3.54-7.01c-0.37,0.11-0.74,0.21-1.11,0.32c0,1.44,0,2.88,0,4.61
				c-1.34,0.36-2.71,0.73-4.08,1.1c0.33,0.22,0.65,0.45,0.98,0.67c-1.1,1.71-2.21,3.42-3.31,5.13c-0.49-0.21-0.99-0.42-1.48-0.64
				c-0.24-1.52-0.49-3.04-0.73-4.53c-3.42-1.43-7.65,9.65-10.3-0.73c-3.82-0.81-6.89,10.12-11.32,0.24
				c-1.55,0.78-3.06,1.84-4.74,2.29c-1.41,0.38-3.01,0.05-4.53,0.03c0.32-1.32,0.56-2.65,0.98-3.94c0.23-0.71,0.75-1.33,1.59-2.74
				c-7.71,1.2-14.16,8.76-21.77,1.15c0.9,5.97,3.38,6.99,15.03,3.49c1.79,4.59,4.85,6.19,9.78,4.19c1.08-0.44,2.97,1.15,4.49,1.81
				L1063.15,485.93z"/>
			<path onClick={() => props.onFill(43)} fill={props.fillColors[43]} d="M314.52,189.05c5.75,4.93,11.98,3.21,17.71,2.88c9.73-0.55,19.41-1.96,29.11-3.02
				c2.21-0.24,4.42-0.48,6.97,0.27c-1.69,0.86-3.52,1.53-5.04,2.63c-1.18,0.85-1.97,2.23-2.94,3.37c1.44,0.58,2.85,1.48,4.34,1.66
				c1.96,0.23,4-0.32,5.97-0.14c1.55,0.15,3.27,0.57,4.54,1.43c4.57,3.09,9.98,2.77,14.6-1.89c-1.06,0.26-2.14,0.45-3.18,0.79
				c-3.74,1.21-6.98,1.58-10.65-1.23c-2.27-1.74-6.12-1.42-9.9-2.14c2.76-1.75,5.24-3.32,7.72-4.9c-0.01-0.51-0.03-1.02-0.04-1.53
				c-2.06-0.34-4.23-1.29-6.16-0.92c-12.89,2.49-26.31-0.48-39.02,3.9c-1.42,0.49-3.15,0.07-4.74,0.07
				c-0.19-0.52-0.37-1.05-0.56-1.57c7.16-7.56,14.23-15.21,21.53-22.63c3.43-3.49,7.14-3.63,9.91,0.05c4.99,6.62,11.68,6.54,18.5,5.3
				c3.39-0.61,4.29,0.09,5.73,3.13c4.51,9.5,12.2,16.49,19.33,23.87c0.48-0.37,0.96-0.73,1.44-1.1c-0.9-1.14-1.63-2.49-2.72-3.39
				c-4.68-3.84-8.01-8.56-10.04-14.28c-1.26-3.54,4.1-12.24,8.22-12.44c6.64-0.33,13.31-0.2,19.97-0.19c0.76,0,2.1,0.37,2.17,0.77
				c0.37,1.87,1.19,4.3,0.37,5.63c-1.65,2.69,0.63,7.8-4.76,8.17c-3.27,0.23-7.3-0.47-5.26,5.22c0.2,0.57-1.04,1.58-1.42,2.46
				c-1,2.31-2.63,4.65-2.67,7c-0.06,3.33-1.25,5.16-4.29,5.63c-4.2,0.65-7.57,1.75-6.47,7.12c0.09,0.43-0.18,0.99-0.42,1.41
				c-2.34,4.24-6.36,8.09-2.4,14.02c-6.62-5.9-13.58-10.47-16.61-18.71c-0.6-1.62-3.45-3.6-5.1-3.46
				c-13.8,1.18-27.31-1.66-40.97-2.28c-1.77-0.08-4.26,0.5-5.27,1.73c-2.55,3.07-5.28,3.64-9.15,3.46c-2.37-0.11-5.32,1.83-7.21,3.67
				c-5.8,5.69-11.18,11.81-16.82,17.67c-0.55,0.57-1.81,0.65-2.73,0.62c-14.18-0.43-26.75-5.23-37.73-14.15
				c-0.9-0.73-1.2-2.19-1.78-3.32c1.26-0.46,2.58-1.43,3.78-1.31c7.98,0.8,15.45-0.9,22.95-3.48c4.16-1.43,8.73-1.7,13.12-2.44
				c5.74-0.97,11.41-1.71,17.36-1.56c2.68,0.07,5.57-4.92,8.21-7.76C313.03,192.13,313.57,190.69,314.52,189.05z"/>
			<path onClick={() => props.onFill(44)} fill={props.fillColors[44]} d="M934.25,627.48c-3.14,1.13-5.82,2.63-8.64,2.95c-2.61,0.3-5.76,0.06-7.99-1.18
				c-8.47-4.72-15.56-3.14-23.49,2.06c-8.09,5.31-17.37,8.79-26.14,13.07c-0.3,0.14-0.67,0.37-0.94,0.3
				c-8.98-2.26-17.34,1.94-25.97,2.33c-5.76,0.26-11.67-2.66-18.52-4.42c3.14-4.45,6.05-8.03,8.37-11.95
				c8.52-14.4,17.15-28.75,25.05-43.49c2.17-4.05,4.01-5.5,8.29-4.53c5.93,1.34,11.69,2.63,14.89,8.75c0.63,1.21,2.7,2.25,4.17,2.35
				c9.39,0.61,16.57,6.05,24.16,10.61c2.85,1.71,5.92,3.18,8.4,5.32c3.41,2.95,6.3,6.51,9.53,9.69
				C928.28,622.13,931.27,624.74,934.25,627.48z"/>
			<path onClick={() => props.onFill(45)} fill={props.fillColors[45]} d="M294.71,633.47c0.61-2.57,0.91-4.64,1.62-6.57c0.8-2.17,2.15-4.14,2.94-6.32
				c1.54-4.25,0.22-6.26-4.36-6.41c-2.95-0.1-4.95-0.74-4.62-4.21c0.41-4.32-1.95-5.42-5.84-6.28c-3.33-0.74-6.31-3.22-9.26-5.2
				c-0.31-0.21,0.24-2.78,1.05-3.39c2.88-2.16,6.04-3.94,9.55-6.14c-1.94-2.3-3.06-3.62-4.49-5.32c3.25-1.3,6.28-2.51,10.2-4.08
				c-4.67-4.23-9.04-6.29-14.11-6.54c-4.48-0.22-9-0.28-13.48,0.02c-6.47,0.43-11.89-2.2-15.03-7.21
				c-4.11-6.56-9.76-9.61-16.85-11.05c-0.47-0.1-0.95-0.28-1.37-0.53c-0.26-0.15-0.41-0.5-0.6-0.76c2.22-1.85,2.22-1.85,8.85,1.11
				c0.91-8.31,1.83-16.69,2.79-25.46c0.62-0.2,1.5-0.59,2.42-0.74c4.89-0.81,4.99-1.31,2.75-5.52c-0.62-1.16,0-3.41,0.77-4.74
				c0.66-1.12,2.37-1.62,3.72-2.46c-0.7-1.73-1.32-3.23-1.93-4.73c18.22,25.08,44.4,39.9,70.86,54.37c-0.03,0.52-0.06,1.04-0.09,1.56
				c-2.29,0.99-4.58,2-6.88,2.96c-3.52,1.46-7.56,2.36-6.11,7.88c0.22,0.84-1.37,2.37-2.4,3.27c-2.99,2.62-6.25,4.95-9.16,7.65
				c-2.96,2.74-2.49,5.35,1.25,6.92c3.35,1.41,6.8,2.61,10.28,3.68c7.19,2.22,8.26,4.55,5.91,11.47c-0.63,1.85-0.31,4.65,0.73,6.28
				c1.59,2.5,1.32,4.08-0.44,6.15C309.2,628.01,300.38,633.34,294.71,633.47z"/>
			<path onClick={() => props.onFill(46)} fill={props.fillColors[46]} d="M246.86,239.56c7.44,0.84,13.98,1.65,20.54,2.3c4.09,0.41,9.23,4.16,11.15-3.39
				c0.18-0.72,2.96-1.05,4.54-1.1c6.62-0.21,13.24-0.36,19.86-0.23c1.51,0.03,4.11,1,4.33,1.96c1.73,7.71,7.23,12.52,12.68,17.41
				c1.09,0.98,2.43,1.67,3.66,2.49c-8.36,8.25-18.33,9.61-27.96,11.5c-1.5,0.29-3.8-1.67-5.19-3.11c-1.67-1.73-2.52-4.24-4.19-5.98
				c-0.85-0.89-2.69-1.16-4.09-1.15c-3.34,0.02-6.67,0.35-10,0.58c-14.99,1.01-30.1-2.49-45.1,2.21c-5.35,1.68-11.76,0.21-17.66-0.15
				c-5.4-0.33-10.77-1.46-16.17-1.6c-6.25-0.17-12.61-0.2-18.73,0.84c-2.73,0.46-5.05,3.63-7.45,5.69c-0.85,0.73-1.19,2.15-2.09,2.7
				c-6.17,3.71-12.36,7.39-18.69,10.8c-1.76,0.95-4.03,0.96-6.89,1.58c3.07-4.72,5.38-8.72,8.12-12.4
				c6.51-8.74,13.29-17.28,19.81-26.01c1.84-2.46,3.63-3.05,6.73-2.4c11.07,2.32,22.08,4.02,33.37-0.08
				c3.45-1.25,7.99,0.44,12.03,0.87c5.92,0.63,11.83,1.35,17.75,2.01c1.32,0.15,2.71,0.02,3.97,0.38
				C245.11,246.37,247.37,245.36,246.86,239.56z"/>
			<path onClick={() => props.onFill(47)} fill={props.fillColors[47]} d="M1193.85,273.21c-1.57-1.46-3.17-2.9-4.69-4.4c-0.69-0.68-1.14-2.01-1.89-2.17
				c-5-1.03-9.41-3.37-13.84-5.75c-2.52-1.35-5.31-2.2-7.98-3.27c0.58-0.54,1.16-1.08,1.73-1.61c-0.5-1.97-1.25-3.91-1.43-5.91
				c-0.13-1.48,0.31-3.08,0.78-4.54c1.18-3.67-2.45-7.6,0.64-11.13c-1.06-0.89-2.02-1.97-3.2-2.63c-1.84-1.02-3.83-1.81-5.79-2.6
				c-1.45-0.59-2.99-1.53-4.44-1.44c-6.82,0.41-10.44-4.93-14.01-8.88c-1.7-1.88,0.19-7.26,0.9-10.97c0.53-2.74,1.94-5.3,2.69-8.01
				c1.66-6.04,2.99-12.18,4.79-18.18c0.49-1.63,1.85-3.71,3.3-4.23c13.6-4.89,27.8-3.28,41.83-3.41c0.46,0,0.92,0.26,1.63,0.47
				c0,32.6,0,65.17,0,97.73C1194.53,272.59,1194.19,272.9,1193.85,273.21z M1147.9,220.45c-0.39-8.01,1.67-14.05,7.53-18.53
				c-2.06,4.79-4.13,9.59-6.19,14.38c0.56,0.26,1.11,0.53,1.67,0.79c1.85-3.78,3.71-7.55,5.56-11.33c0.42,0.08,0.83,0.16,1.25,0.23
				c-0.27,2.03-0.7,4.06-0.77,6.1c-0.07,2.01,0.22,4.03,0.35,6.04c0.43-0.07,0.86-0.15,1.28-0.22c0.25-2.14,0.5-4.28,0.74-6.42
				c0.65-0.09,1.3-0.18,1.95-0.27c6.74,11.03,11.27,23.02,13.74,35.9c0.7-0.19,1.4-0.39,2.1-0.58c-3.21-13.94-6.41-27.88-9.77-42.49
				c-0.46,1.85-0.91,3.22-1.12,4.63c-0.21,1.36-0.19,2.76-0.29,4.48c-4.55-2.57-5.79-5.45-3.76-10.1c1.46-3.34,1.74-7.2,2.54-10.82
				c-0.56-0.12-1.12-0.24-1.68-0.37c-0.99,3.82-1.98,7.64-3.26,12.61c-4.22-5.31,1.18-8.1,0.94-11.33
				c-5.11-4.84-0.63-9.76-1.01-14.79c-1.34,1.6-2.63,3.5-2.51,5.3c0.52,7.46-2.37,13.56-6.59,19.37c-1.65,2.26-3.04,4.81-4.05,7.42
				C1145.41,213.4,1144.68,216.59,1147.9,220.45z"/>
			<path onClick={() => props.onFill(48)} fill={props.fillColors[48]} d="M899.6,297.99c-5.93,7.52-7.11,15.87-7.78,24.58c-0.46,6-5.43,9.53-10.4,11.96
				c-7.89,3.86-16.05,7.2-24.18,10.56c-5.03,2.08-10.94,3-10.17,10.67c0.08,0.78-1.55,2.02-2.62,2.57
				c-5.73,2.94-11.54,5.73-18.1,8.96c-0.87-3.7-1.67-6.96-2.41-10.24c-1.16-5.1-3.89-8.09-9.54-8.39c-5.97-0.31-11.96-1-17.84-2.05
				c-3.25-0.58-6.3-2.26-9.45-3.44c-0.08-0.66-0.15-1.33-0.23-1.99c3.12-1.92,6.01-4.69,9.4-5.6c7.92-2.12,14.75-5.85,20.72-11.32
				c4.7-4.31,10.57-5.03,16.53-6.1c14.42-2.59,27.58-7.7,35.42-21.29c1.77-3.07,3.46-3.93,7.12-3.03c6.37,1.56,12.97,2.18,19.47,3.23
				C896.63,297.23,897.7,297.55,899.6,297.99z"/>
			<path onClick={() => props.onFill(49)} fill={props.fillColors[49]} d="M467.06,395.33c-1.25,4.22-1.7,8.6-3.77,11.99c-2.33,3.82-3.45,7.06-1.13,11.03
				c0.25,0.43,0.63,0.94,0.57,1.37c-0.89,6.31-2.65,12.57,1.1,18.77c0.38,0.62-2.57,4.03-4.54,5.15
				c-6.48,3.69-13.59,6.37-19.71,10.54c-3.61,2.46-6.81,6.61-8.44,10.7c-4.04,10.13-11.47,17.39-18.74,24.92
				c-4.5,4.66-9.04,9.29-13.53,13.96c-3.27,3.4-10.75,4.89-14.76,2.52c-3.1-1.83-5.49-3.88-3.04-8.4
				c6.06-11.21,13.82-21.03,22.06-30.69c4.39-5.15,7.32-11.81,9.86-18.2c3.79-9.51,8.02-18.44,15.45-25.85
				c3.14-3.13,5.07-7.77,6.7-12.05c2.43-6.37,6.28-11.25,12.2-14.52c0.99-0.54,2.1-1.16,3.18-1.18
				C455.56,395.26,460.61,395.33,467.06,395.33z"/>
			<path onClick={() => props.onFill(50)} fill={props.fillColors[50]} d="M420.42,568.02c-0.35,4.46-0.4,8.18-1.06,11.79c-0.26,1.42-1.65,2.97-2.94,3.81
				c-10.19,6.57-21.67,9.85-33.13,13.48c-9.25,2.93-17.89,7.81-26.78,11.86c-4.07,1.85-8.1,3.81-12.16,5.68
				c-9.8,4.5-19.6,8.99-29.41,13.46c-0.7,0.32-1.46,0.53-2.19,0.79c-0.32-0.38-0.63-0.76-0.95-1.13c1.5-1.17,3.12-2.22,4.46-3.55
				c2.35-2.31,2.87-4.79,1.35-8.1c-0.86-1.88-0.75-4.75,0.03-6.74c2.02-5.15,0.71-9.16-4.56-11.16c-3.88-1.47-7.98-2.38-11.77-4.03
				c-3.8-1.66-3.8-3.48-0.52-6.17c1.93-1.58,3.88-3.14,5.74-4.8c2.86-2.55,5.36-4.07,9.98-3.6c5.9,0.61,12.05-1.78,18.15-2.35
				c6.54-0.61,13.16-0.33,19.69-0.95c5.88-0.56,11.81-1.4,17.51-2.9c4.66-1.22,8.78-1.87,13.44,0.31c6,2.8,12.09,3.56,18.56-0.15
				C408.35,571.03,413.8,570.16,420.42,568.02z"/>
			<path onClick={() => props.onFill(51)} fill={props.fillColors[51]} d="M842.99,130.98c-5.98,0.1-11.98,0.52-17.95,0.21c-5.02-0.26-9.99-1.54-15.01-1.99
				c-3.1-0.27-6.28,0.02-9.4,0.36c-3.96,0.43-8.5,2.71-11.68,1.43c-4.84-1.94-9.5-3.97-15.11-3.32c0.03-0.72,0.07-1.44,0.1-2.16
				c4.26-1.12,8.52-2.24,12.78-3.35c-0.03-0.63-0.05-1.26-0.08-1.89c-12.48-0.62-24.96-1.24-36.59-1.82
				c-3.77-10.21-13.01-5.65-19.2-8.16c3.02-5.76,2.29-6.83-4.52-7.47c-1.45-0.14-2.79-1.58-4.18-2.41c1.28-1.04,2.5-2.88,3.84-2.97
				c3.7-0.24,7.46,0.26,11.82,0.5c2.4-4.66,5.9-1.93,9.04,0.1c2.76-0.95,5.26-1.81,7.94-2.74c0.47-2.04,1.07-4.42,1.57-6.81
				c1.07-5.12,3.96-6.26,7.9-2.69c1.98,1.79,3.26,4.36,5.17,7.02c0.74-5.37,2.68-11.52,8.94-10.04c7.14,1.69,3.24,8.36,2.73,13.93
				c2.94-1.34,5.16-2.55,7.52-3.39c6.23-2.21,8.08-1.04,9.76,5.23c0.19,0.72,2.74,1.36,4.04,1.14c3.53-0.59,6.98-2.25,10.47-2.29
				c1.69-0.02,4.84,2.66,4.83,4.1c-0.04,3.62-3.24,1.63-5.32,1.95c-6.91,1.08-13.94,2.05-20.57,4.16c-4.28,1.37-3.54,6.02,0.56,8.44
				c0.38-2.12,0.04-5.75,1.33-6.48c4.46-2.51,8.39-0.54,11.74,4.9c0.2-4.31-1.51-8.21,3.37-8.85c3.64-0.48,6.87-0.1,8.67,3.15
				c1.46-1.28,2.97-2.6,4.48-3.92c0.38,0.34,0.76,0.68,1.15,1.02c-0.63,1.38-1.25,2.76-2.21,4.86c2.3,0.11,4.33,0.3,6.36,0.29
				c6.03-0.02,6.96,0.85,6.78,6.79c3.85-0.86,7.64-1.78,11.48-2.52c1.44-0.28,3.46-0.74,4.37-0.03c5.51,4.28,11.77,2.81,17.85,2.84
				c0.38,0,0.75,0.48,1.93,1.3c-3.83,1.04-6.96,1.89-10.1,2.74c-0.04,0.53-0.07,1.07-0.11,1.6c2.45,0.65,4.9,1.29,7.35,1.94
				c0.03,0.25,0.06,0.5,0.08,0.74c-4.3,0.45-8.64,0.64-12.87,1.44c-3.79,0.72-7.46,2.12-11.18,3.23
				C842.86,131.1,842.99,130.98,842.99,130.98z"/>
			<path onClick={() => props.onFill(52)} fill={props.fillColors[52]} d="M1087.43,441.14c5.13-4.05,8.71-7.37,9.48-12.98c0.19-1.39,2.78-2.46,3.92-2.09
				c3.95,1.53,7.89,3.05,12.59,4.87c-1.68-4.18-1.68-4.18-7.83-4.24c-0.15-1.17-0.32-2.38-0.47-3.52c-3.07-1.66-8.31,2.52-9.02-3.21
				c-0.13-1.07,4.65-2.75,7.57-4.37c-0.23-0.51-0.78-1.75-1.36-3.06c3.42-3.07,6.87-6.16,10.29-9.23c-0.81-1.63-1.56-3.14-2.39-4.81
				c-6.52,2.43-6.82,2.3-9.31-4.17c-2.55,1.16-5.32,3.51-7.62,3.11c-1.99-0.34-3.44-3.8-5.91-6.84c-0.73,8.58-3.15,10.63-11.54,11.22
				c0.55,1.34,1.04,2.55,1.63,3.97c-2.11,0.36-4.09,0.7-6.06,1.04c-0.01,0.4-0.03,0.81-0.04,1.21c1.32,0.25,2.63,0.69,3.95,0.71
				c3.52,0.04,7.19-0.52,7.29,4.89c0.01,0.72,2.62,1.16,3.54,2.17c0.97,1.07,2.07,2.82,1.8,3.97c-0.23,0.99-2.24,2.04-3.58,2.21
				c-5.34,0.65-9.71-1.14-12.63-5.91c-0.32-0.52-0.98-1.13-1.53-1.18c-4.13-0.32-8.42-1.23-12.37-0.47
				c-2.76,0.53-5.31,3.17-7.52,5.31c-2.02,1.96-3.59,4.11-6.34,2.08c0.41-1.65,0.81-3.26,1.21-4.86c-0.33-0.18-0.65-0.35-0.98-0.53
				c1.28-0.86,2.56-1.73,3.84-2.59c-0.04-0.35-0.08-0.7-0.12-1.05c-1.68,0-3.36-0.05-5.04,0.01c-3.64,0.14-6.28-1.46-7.1-4.95
				c-0.85-3.63,2.18-4.83,4.81-5.92c2.55-1.05,5.2-1.87,8.2-2.93c-1.13-1.7-1.82-2.75-2.74-4.12c1.25-0.81,2.4-1.55,4-2.59
				c-1.92-1.52-3.52-2.78-6.04-4.77c3.41-0.66,5.58-1.09,8.14-1.58c-0.44-1.36-0.8-2.48-1.16-3.57c6.96-3.68,7.82-3.45,11.82,3.02
				c2.33,3.76,7.81,4.65,10.72,1.39c0.86-0.97,0.91-4.34,0.42-4.55c-1.9-0.83-4.15-0.84-6.27-1.15c-0.05,0.43-0.11,0.87-0.16,1.3
				c0.21,0.23,0.4,0.62,0.64,0.66c1.54,0.27,3.09,0.45,4.64,0.67c-1.19,0.97-2.55,2.88-3.53,2.7c-1.73-0.32-4.33-1.69-4.59-3.01
				c-0.66-3.31,0.58-6.69,4.65-6.72c3.11-0.02,6.23,1.18,9.67,1.9c2.41-4.27,4.41-4.02,11.46,2.71c0.6-1.37,0.95-3.51,1.71-3.67
				c2.3-0.49,6.51-0.96,6.88-0.11c2.38,5.55,5.29,2.46,8.25,1.11c1.47-0.67,3.05-1.12,4.58-1.67c-0.24,1.71-0.48,3.43-0.82,5.84
				c1.87,0.09,4.19,0.21,8.02,0.4c-2.35,2.82-4,4.8-5.94,7.13c3.77,1.92,7.71,3.74,11.45,5.89c1.74,1.01,3.11,2.68,4.64,4.04
				c-1.68,1.61-3.34,3.24-5.04,4.83c-0.46,0.43-1.01,0.76-1.76,1.31c1.4,1.21,2.59,2.24,4.45,3.86c-2.34,1.05-4.14,1.85-6.8,3.04
				c1.93,1.13,3.22,1.88,4.5,2.63c-1.08,5.69-1.08,5.69,8.71,8.01c-1.57,1.04-2.82,1.88-4.49,2.99c1.23,0.82,2.22,1.47,4.27,2.83
				c-3.38,1.09-5.88,1.89-8.81,2.84c0.79,1.11,1.65,2.33,3.05,4.29c-1.94,0.34-3.45,0.61-3.54,0.63c-1.94,3.16-2.84,6.37-4.91,7.57
				c-2.89,1.67-6.59,1.93-9.3-1.51c-0.62-0.79-2.04-1.24-3.13-1.32C1100.53,441.82,1094.61,441.54,1087.43,441.14z"/>
			<path onClick={() => props.onFill(53)} fill={props.fillColors[53]} d="M1054.97,365.69c-10.02,1.32-18.34,8.17-28.68,6.12c-0.97-0.19-2.29,0.32-2.95-0.17
				c-6-4.5-12.46-3.46-19.17-2.28c-2.24,0.39-4.81-1.05-7.23-1.65c-0.08-0.59-0.16-1.18-0.23-1.77c2.7-1.39,5.4-2.79,8.1-4.18
				c-0.04-0.41-0.09-0.82-0.13-1.23c-3.3,0-6.6,0-11.15,0c1.76-2.2,2.57-3.22,3.53-4.42c-2.61-1.34-5.09-2.27-7.13-3.79
				c-1.28-0.96-2.99-3.6-2.62-4.12c1.14-1.58,3.06-3.1,4.93-3.52c2.62-0.58,5.47-0.15,9.38-0.15c-1.54-1.26-2.28-1.9-3.05-2.5
				c-1.04-0.81-2.1-1.59-3.15-2.38c1.35-0.64,2.64-1.58,4.06-1.85c1.7-0.32,3.51-0.08,6.17-0.08c-1.35-2.12-2.14-3.35-3.41-5.34
				c3.32-0.4,6.06-0.72,8.69-1.04c-0.92-2.56-1.64-4.56-2.36-6.57c2.48,0.25,4.97,0.47,7.45,0.75c2.42,0.27,4.84,0.59,8.36,1.03
				c-1.56-2.01-2.39-3.07-3.45-4.43c1.53-0.54,2.86-1,4.35-1.53c-1.19-4.37-0.24-6.3,4.76-5.28c1.51,0.31,3.67-1.61,5.26-2.86
				c3.18-2.48,5.88-5.84,9.6-0.3c0.95,1.41,4.21,1.27,6.71,1.93c-0.17,0.57-0.41,1.41-0.66,2.26c4.13,0.35,8.55-0.16,12.25,1.24
				c5.1,1.93,2.13,6.78,2.24,10.14c5.07,1.65,10.11,3.28,15.15,4.92c0,0.4,0,0.81,0,1.21c-1.29,0.35-2.58,0.99-3.88,1.01
				c-3.91,0.06-7.91-0.76-10.06,4.12c-0.43,0.97-3.21,0.91-5.38,1.44c-0.36,5.79-3.85,9-10.09,9.36c-0.69,0.04-1.51,0.67-1.98,1.26
				c-5.84,7.31-8.01,7.96-16.97,4.75c0.22-1.02,0.45-2.1,0.79-3.68c-3.09,0.2-5.69,0.37-7.47,0.49c-1.82-2.2-3.26-3.95-4.77-5.76
				c1.13-1.51,2.75-3.67,4.37-5.83c3.38-2.49,10.04,1.96,11.7-6.01c2.89,0.49,5.73,0.98,8.96,1.53c0.21-2.1,0.37-3.67,0.61-6.01
				c3.02,0.96,5.73,1.82,8.53,2.71c0.38-2.28,0.74-4.43,1.1-6.61c-0.21-0.18-0.62-0.61-0.67-0.58c-3.09,2.36-6.34,3.92-10.47,3.21
				c-0.42-0.07-1.31,2.63-2.35,4.81c-2.66-0.56-5.74-1.2-8.84-1.85c-0.46,10.74-9.98,2.68-13.66,7.27c0.29,1.08,0.64,2.42,0.99,3.71
				c-2.2,1-4.17,1.89-6.74,3.06c7.71,8.54,16.53,12.7,27.04,12.88c4.2,0.07,8.35-1.04,9.96-5.89c0.05-0.16,0.12-0.34,0.24-0.43
				c5.08-3.69,10.17-7.37,15.27-11.04c1.07-0.77,2.42-1.29,3.23-2.27c3.46-4.18,7.49-2.95,11.55-1.94c0.14,0.54,0.29,1.08,0.43,1.63
				c-4.17,1.24-8.34,2.47-13.2,3.91c1.81,1.5,3.64,3.02,5.97,4.94c-5.61,2.85-10.86,5.51-16.61,8.43c1.9,5.96-4.56,2.43-7.26,4.41
				C1052.26,362.46,1053.46,363.88,1054.97,365.69z"/>
			<path onClick={() => props.onFill(54)} fill={props.fillColors[54]} d="M96.43,527.97c10.64-2.41,21.17-5.73,31.95-7.01c13.27-1.58,24.2-7.36,33.66-15.96
				c4.95-4.49,9.83-5.07,15.91-4.55c14.72,1.25,29.47,2,44.21,2.97c1.25,0.08,2.48,0.29,3.72,0.45c0.12,0.47,0.24,0.94,0.36,1.42
				c-3.87,1.58-7.66,3.4-11.63,4.66c-3.98,1.27-6.66,3.34-7.73,7.57c-0.46,1.8-1.6,3.71-2.98,4.95
				c-6.64,5.97-13.32,11.92-20.33,17.45c-1.9,1.5-4.95,2-7.51,2.15c-7.98,0.44-15.97,0.47-23.96,0.72c-1.82,0.06-4.01-0.19-5.39,0.69
				c-10.87,6.99-20.65,0.87-30.24-2.85c-7.29-2.83-13.86-7.48-20.76-11.32C95.96,528.87,96.2,528.42,96.43,527.97z"/>
			<path onClick={() => props.onFill(55)} fill={props.fillColors[55]} d="M1023.78,279.62c-14.65,0.2-29.37,3.23-43.92-1.18c-6.56-1.99-12.5-2.17-18.47,2.01
				c-4.6,3.22-9.85,1.84-14.97,0.52c-2.87-0.74-6-1.22-8.92-0.92c-9.22,0.93-18.38,2.36-27.59,3.43c-1.76,0.2-3.61-0.34-5.41-0.54
				c-0.22-0.62-0.44-1.24-0.67-1.86c5.14-3.68,10.22-7.46,15.44-11.01c2.46-1.67,5.33-2.74,7.79-4.4
				c9.36-6.33,18.61-12.81,27.94-19.19c2.94-2.01,5.83-3.72,9.53-1c1.27,0.93,3.54,0.64,5.35,0.68c4.4,0.08,7.86,0.36,11.76,4.19
				c3.24,3.18,10.19,3.07,15.56,3.45c7.96,0.56,15.98,0.26,23.98,0.35c1.5,0.02,3.11-0.19,4.46,0.29c2.11,0.75,4.82,1.49,5.89,3.14
				c1.86,2.86-0.7,4.52-2.95,6.19c-1.08,0.81-1.39,2.72-1.92,4.18c-0.35,0.97-0.32,2.08-0.71,3.02c-1.03,2.47-2.21,4.89-3.33,7.33
				C1023.02,278.72,1023.4,279.17,1023.78,279.62z"/>
			<path onClick={() => props.onFill(56)} fill={props.fillColors[56]} d="M709.3,230.17c3.46,0,6.34-0.12,9.21,0.03c2.79,0.15,5.62,0.3,8.32,0.96c5.46,1.33,10.79,3.27,16.28,4.42
				c2.46,0.52,6.09,0.76,7.63-0.62c5.69-5.07,12.24-2.87,18.76-3.57c0.16,1.37,0.52,2.51,0.37,3.58c-0.68,4.86,0.73,7.95,5.63,10.2
				c2.79,1.28,4.66,4.93,6.51,7.79c2.04,3.15,3.6,6.63,5.62,10.45c-4.92,0.68-6.82-2.29-8.83-5.02c-5.06-6.88-12.83-6.68-20.12-7.65
				c-0.44-0.06-0.99,0.64-2.2,1.49c5.81,1.1,5.67,10.01,13.95,6.87c-2.85,5.07-0.05,5.99,2.9,6.3c5.96,0.62,11.97,0.79,17.92,1.47
				c2.15,0.25,4.18,1.45,6.26,2.22c-0.04,0.66-0.08,1.31-0.13,1.97c-2.07,0.73-4.13,2.04-6.21,2.07c-5.12,0.08-10.24-0.42-15.36-0.73
				c-1.15-0.07-2.65-0.81-3.39-0.33c-6.44,4.15-12.44-1.13-18.72-0.72c-6.28,0.4-12.61-0.07-18.91-0.11
				c-3.82-0.03-7.64-0.04-11.45,0.07c-5.14,0.15-9.79,0.76-13.11-5.36c-1.24-2.27-7.42-2.21-11.41-2.55
				c-4.73-0.4-9.51-0.09-14.76-0.88C696.93,254.82,706.45,244.87,709.3,230.17z"/>
			<path onClick={() => props.onFill(57)} fill={props.fillColors[57]} d="M1043.97,397.87c-2.13,1.08-4.69,2.26-7.15,3.64c-4.86,2.73-5.14,7.24-1.02,10.96
				c1.72,1.55,6.29,1.69,4.26,5.37c-1.26,2.3-4.35,3.6-6.87,5.19c-7.52,7.31-14.38,15.9-26.91,8.35c0.75-6.46,2.24-7.67,8.84-9.03
				c2.11-0.43,4.33-2.67,5.42-4.66c0.51-0.93-1.41-3.34-2.44-4.94c-1.49-2.33-3.15-4.54-4.32-6.19c3.07-3.46,8.8-6.96,8.9-10.63
				c0.27-9.21-4.5-16.27-14.96-16.72c1.16-2.69,2.13-4.94,3.68-8.55c0.65,0.09,2.93-0.13,4.69,0.72c9.4,4.58,18.66,3.29,28.01-0.02
				c3.25-1.15,6.54-2.36,9.92-2.95c3.75-0.65,4.43-2.32,2.54-5.96c4.67-3.02,9.42-5.94,14-9.09c7.6-5.23,15.83-3.79,24.01-2.39
				c5.06,0.87,10.23,1.64,15.03,3.36c5.32,1.91,10.27,4.85,16.53,7.89c-9.28,5.91-18.04,8.99-27.38,10.59
				c-0.82,0.14-1.78-0.12-2.47,0.22c-4.45,2.18-8.4,3.24-13.78,1.24c-4.33-1.61-9.9,0.11-14.92,0.36c-0.33,0.02-0.83,0.01-0.95,0.21
				c-3.21,5.16-8.78,2.34-12.86,3.94c-4.16,1.63-8.56,4.6-10.9,8.24C1041.37,389.33,1043.44,393.94,1043.97,397.87z"/>
			<path onClick={() => props.onFill(58)} fill={props.fillColors[58]} d="M877.44,291.96c4.99-1.78,9-3.25,13.05-4.64c4.79-1.65,9.53-2.5,14.62-0.77c2.22,0.76,5.06,0.79,7.33,0.15
				c12.7-3.58,25.38-4.35,38.44-1.88c3.96,0.75,8.81,0.08,12.46-1.63c4.9-2.29,9.58-2.97,14.48-1.56
				c14.12,4.06,28.47,1.87,42.73,2.03c1.71,0.02,3.42,0.26,5.12,0.4c0.09,0.72,0.18,1.45,0.27,2.17c-2.26,0.81-4.62,1.41-6.77,2.46
				c-3.89,1.91-8.06,3.59-11.34,6.3c-2.75,2.27,0.26,13.39,3.46,15.32c1.04,0.63,1.86,1.67,2.65,2.62c1.78,2.13,3.48,4.33,5.48,6.85
				c-0.45,0.57-1.74,2.19-3.2,4.03c-3.93-2.7-7.5-3.03-10.95,1.02c-0.96,1.12-3.36,1.35-5.12,1.4c-5.65,0.17-11.3,0.06-16.95,0.06
				c-0.17-0.62-0.34-1.25-0.5-1.87c2.49-1.2,5.12-2.17,7.41-3.66c1.71-1.12,2.97-2.92,4.43-4.42c-1.94-1.01-3.82-2.73-5.83-2.9
				c-5.8-0.47-11.65-0.31-17.48-0.43c-0.6-0.01-1.19-0.27-2.92-0.68c2.69-1.1,4.52-1.61,6.08-2.56c1.15-0.71,1.95-2,2.9-3.04
				c-1.32-0.79-2.55-1.93-3.97-2.28c-1.56-0.38-3.31,0.09-4.96-0.04c-10.44-0.82-20.86-1.85-31.31-2.51
				c-3.95-0.25-7.95,0.31-11.93,0.47c-1.42,0.06-2.85,0.01-5.24,0.01c2.63-2.93,4.53-5.05,7.26-8.09
				C909.9,292.6,893.88,296.56,877.44,291.96z"/>
			<path onClick={() => props.onFill(59)} fill={props.fillColors[59]} d="M465.22,478.57c2.27,1.36,2.56,3.26,0.66,6.15c-4.1,6.24-8.94,11.48-14.46,16.67
				c-5.77,5.42-12.11,11.57-14.6,20.04c-2.37,8.05-10.97,11.21-17.83,6.48c-3.82-2.63-6.82-6.43-10.52-9.26
				c-2.43-1.86-5.4-3.04-8.22-4.35c-2.11-0.99-4.36-1.7-6.67-2.58c6.49-6.07,12.26-11.16,17.68-16.59
				c9.52-9.53,18.97-19.07,24.08-32.05c4.67-11.86,17.34-9.82,25.68-6.44c1.05,0.43,1.9,1.35,2.9,1.94c5.99,3.49,5.22,3.16,2.69,9.21
				c-2.53,6.05-6.91,9.86-11.47,13.93c-1.21,1.08-1.37,3.36-2.01,5.09c1.78-0.37,3.98-0.22,5.27-1.22
				C460.91,483.62,462.9,481.02,465.22,478.57z"/>
			<path onClick={() => props.onFill(60)} fill={props.fillColors[60]} d="M6.01,465.85c0-14.25-0.15-27.7,0.2-41.14c0.04-1.56,2.64-4.06,4.41-4.42
				c18.83-3.83,32.7-16.62,48.37-26.18c5.78-3.53,12.18-6.05,17.98-9.55c5.04-3.04,9.53-6.77,15.98-6.62
				c2.08,0.05,4.2-1.71,7.72-3.26c-1.48,3.07-2.4,4.98-3.54,7.35c5.17,3.4,9.26-3.94,14.12-1.58c0.03,5.31-2.82,9.72-7.56,11.41
				c-3.44,1.23-7.04,2.04-10.38,3.48c-1.69,0.72-4.16,2.43-4.14,3.65c0.03,1.59,2.19,3.13,3.65,4.95c-6.7,2.81-14.15,5.39-21.07,8.97
				c-12.24,6.33-24.22,13.16-36.13,20.09c-1.55,0.9-2.07,3.61-3,5.51c-0.85,1.75-1.18,4-2.48,5.24
				C22.47,451.01,14.59,458.04,6.01,465.85z"/>
			<path onClick={() => props.onFill(61)} fill={props.fillColors[61]} d="M1057.1,543.65c1.64,1.71,2.7,2.82,4.93,5.15c-3.71-0.4-5.94-0.64-8.75-0.94
				c0.63,1.52,1.08,2.59,1.86,4.47c-9.66,0.61-18.96,3.52-26.55-1.77c-3.14,1.08-5.29,1.82-7.45,2.57c-0.26-0.5-0.51-1-0.77-1.51
				c0.38-0.85,0.99-1.69,1.07-2.57c0.09-0.96-0.31-1.96-0.5-2.94c-0.74,0.44-1.88,0.71-2.16,1.36c-1.35,3.1-3.9,4.86-6.71,3.33
				c-3.13-1.71-5.83-4.5-8.06-7.34c-0.46-0.58,1.76-3.68,3.21-5.18c0.86-0.89,2.62-0.93,4.46-1.5c-1.15-1.62-1.95-2.75-2.71-3.83
				c5.32-5.77,11.6-2.8,17.48-2.58c4.32,0.16,8.65,0.7,12.94,0.46c1.25-0.07,3.35-2.23,3.33-3.41c-0.03-1.63-1.69-3.23-3.41-4.2
				c0,1.81,0,3.62,0,5.43c-1.5-0.8-3-1.6-5.17-2.77c-1.05,0.7-2.9,1.93-5.21,3.47c-0.17-1.71-0.29-2.86-0.38-3.82
				c-2.79,0.62-5.27,1.17-7.75,1.72c-0.25-0.48-0.5-0.96-0.75-1.44c1.34-1.46,2.67-2.92,4.61-5.03c-3.06-0.19-5.69,0.04-8.06-0.62
				c-1.87-0.52-3.43-2.16-5.12-3.3c1.33-1.49,2.42-3.91,4.02-4.3c4.82-1.19,9.84-1.59,15.14-2.36c-0.19-5.92,5.99-2.3,9.27-4.31
				c-2.13-7.18,3.53-4.59,6.05-4.67c-0.15-2.69-1.21-6.36-0.24-7.02c4.3-2.93,8.43-0.7,11.58,1.77c1.74-2.44,3.35-4.69,4.96-6.95
				c2.38,1.74,4.76,3.47,7.87,5.75c0.64-0.27,2.46-1.05,4.28-1.83c0.28,0.39,0.56,0.78,0.85,1.16c-1.27,1.4-2.55,2.79-4.49,4.92
				c3.35,0,5.83,0,9.28,0c-1.58,2.08-2.54,3.34-4.08,5.37c4.38,0,7.93,0,11.48,0c0.06,0.62,0.12,1.25,0.18,1.87
				c-5.33,1.18-10.65,2.36-15.32,3.4c0,2.93,0.2,5.84-0.18,8.67c-0.07,0.54-2.86,1.49-3.65,1.02c-2.79-1.67-5.07-2.01-8.29-0.63
				c-1.93,0.83-4.81-0.56-7.27-0.95c0.07-0.62,0.14-1.24,0.22-1.86c1.3-0.46,2.59-0.92,4.11-1.45c-0.74-1.58-1.47-3.13-2.71-5.76
				c2.69,0.88,4.38,1.43,6.06,1.98c0.24-0.46,0.48-0.91,0.72-1.37c-1.91-0.89-3.77-2.24-5.74-2.44c-0.81-0.08-2.39,2.21-2.72,3.64
				c-2.46,10.52-2.39,10.29,8.56,10.13c3.1-0.04,6.23,1.8,9.87,2.94c-3.68,2.46-1.16,3.74,0.97,6.34c-3.9,0.9-7.8,1.79-12.37,2.85
				c2.11,1.02,3.7,1.79,6.35,3.06c-2.92,1.07-4.83,1.76-7.4,2.7c1.56,1.34,2.8,2.41,4.57,3.93
				C1061.24,542.86,1059.61,543.17,1057.1,543.65z"/>
			<path onClick={() => props.onFill(62)} fill={props.fillColors[62]} d="M473.05,599.05c-6.54-5.45-12.67-10.56-18.82-15.66c-1-0.83-2-2.08-3.14-2.27
				c-8.84-1.46-13.78-8.13-19.29-14.07c-1.36-1.46-2.82-2.85-4.36-4.11c-8.61-6.97-6.66-22.42,2.78-28.45
				c5.9-3.77,12.16-3.45,18.37-4.55c0.32-0.06,0.74,0.13,0.99-0.02c6.91-4.14,13.77,0.46,20.66,0.17c4.34-0.18,5.58,3.28,5.51,6.83
				c-0.08,4.12-1.33,8.33,2.67,11.5c0.48,0.38,0.48,1.58,0.42,2.38c-0.63,8.93-0.69,17.96-2.21,26.74
				c-1.05,6.03-2.93,11.84-1.9,18.07C474.83,596.25,474.08,597.03,473.05,599.05z"/>
			<path onClick={() => props.onFill(63)} fill={props.fillColors[63]} d="M212.32,623.79c-2.06,0.78-4.07,1.78-6.2,2.27c-1.8,0.41-3.98,0.85-5.57,0.22
				c-8.63-3.45-16.98-7.66-25.72-10.76c-8.06-2.86-16.53-4.52-24.75-6.97c-5.09-1.52-10.35-2.94-14.94-5.48
				c-4.21-2.32-7.68-6.03-11.23-9.39c-0.18-0.17,1.76-3.86,3.12-4.17c6.13-1.42,12.39-2.33,18.63-3.22
				c6.66-0.95,13.33-2.01,20.03-2.41c12.02-0.72,22.93,2.98,33.4,8.76c4.89,2.7,10.36,5.6,15.72,5.91c8.8,0.51,14.08,6.3,20.42,10.63
				c1.18,0.8,1.66,2.62,2.47,3.97c-1.34,0.78-2.66,1.59-4.02,2.32c-4.23,2.27-8.43,4.6-12.74,6.71c-2.57,1.25-5.33,2.12-8,3.16
				C212.73,624.82,212.53,624.3,212.32,623.79z M181.7,588.94c-1.69,0.92-3.16,1.71-4.62,2.5c0.93,1.62,1.48,3.88,2.88,4.71
				c2.51,1.49,5.48,2.47,8.37,2.95c1.44,0.24,3.14-1.09,4.72-1.71c-0.69-1.38-1.02-3.33-2.14-4.03
				C188.17,591.64,185.06,590.5,181.7,588.94z"/>
			<path onClick={() => props.onFill(64)} fill={props.fillColors[64]} d="M594.4,296.07c-1.29,0.81-3.03,1.73-4.55,2.93c-1.13,0.89-2.89,2.24-2.8,3.24
				c0.52,5.47-3.14,6.14-7.05,6.93c-5.6,1.12-11.18,2.35-16.74,3.66c-1.24,0.29-3.21,0.88-3.4,1.67c-1.26,5.45-7.96,8.82-5.93,15.49
				c0.11,0.35-0.33,0.86-0.89,2.16c-6.42-11-19.49-8.32-27.86-14.93c-9.18-7.24-20.06-5.73-32.42-6.35
				c3.64-4.53,6.61-8.04,9.38-11.72c2.04-2.7,4.24-5.42,5.57-8.48c1.32-3.05,2.8-3.83,6.06-3.27c10.51,1.81,20.55,1.7,31.23-1.83
				c8.57-2.83,18.63-1.18,28.02-1.48c0.44-0.01,1.17,0.27,1.3,0.6C578.07,293.92,588.47,290.74,594.4,296.07z"/>
			<path onClick={() => props.onFill(65)} fill={props.fillColors[65]} d="M422.42,533.16c-2.16,5.7-4.8,11.23-6.24,17.06c-0.68,2.77,0.73,6.15,1.51,9.17
				c1.03,3.97-0.23,6.4-4.11,7.67c-4.08,1.33-8.04,3.12-12.21,4.04c-2.19,0.48-5.17,0.28-6.96-0.89c-5.8-3.8-11.26-8.12-16.73-12.4
				c-1.11-0.87-1.98-2.36-2.39-3.73c-2.76-9.24-9.16-13.78-19.35-14.64c3.46-5.9-0.63-8.2-6.32-10.71c3.3-1.87,5.22-3.69,7.37-4.03
				c6.99-1.11,11.33-5.89,15.83-10.53c4.35-4.48,13-3.9,17.61,0.29c2.4,2.18,6.28,2.76,9.53,3.96c1.48,0.55,3.34,0.47,4.55,1.34
				C410.78,524.27,416.89,528.99,422.42,533.16z"/>
			<path onClick={() => props.onFill(66)} fill={props.fillColors[66]} d="M511.73,760.27c2.32-0.43,4.34-0.23,5.44-1.14c1.82-1.49,3.17-3.63,4.5-5.64
				c0.83-1.26,0.87-3.53,1.92-4.07c6.68-3.42,13.92-4.61,22.66-5.2c-2.24,3.2-3.76,5.37-5.75,8.21c2.84,0.84,5.09,1.5,7.97,2.35
				c-1.19,1.56-2.45,2.82-3.22,4.34c-1.31,2.58-3.14,5.27-3.29,8c-0.42,7.52,1.57,9.06,9.07,8.47c1.88-0.15,3.79-0.02,6.46-0.02
				c-1.84,3.31-3.56,5.35-0.71,8.64c1.63,1.88,1.29,6.84-3.61,7.34c-3.72,0.38-7.49,0.2-11.23,0.43c-2.56,0.16-5.9-0.33-7.52,1.07
				c-9.12,7.86-20,9.65-31.29,9.42c-11.46-0.24-22.11,2.02-32.14,7.48c-0.65,0.36-1.41,0.53-2.41,0.25c2.82-2.54,5.28-5.79,8.54-7.47
				c5.5-2.83,6.71-8.87,10.78-12.66c0.6-0.56,0.96-2.01,0.67-2.76c-1.76-4.54,1.25-6.33,4.36-8.2c4.99-2.99,9.9-6.11,14.92-9.04
				C511.5,767.96,513.92,765.39,511.73,760.27z"/>
			<path onClick={() => props.onFill(67)} fill={props.fillColors[67]} d="M935.37,722.06c-9.13,1.62-16.63,3-24.15,4.27c-5.67,0.96-8.33-3.24-10.05-7.2
				c-2.6-5.98-6.98-8.44-12.84-10.49c-3.61-1.26-6.2-5.47-9.19-8.43c-0.92-0.91-1.38-2.47-2.43-3.02c-6.63-3.47-13.23-7.04-20.1-9.98
				c-8.48-3.64-12.61-10.69-11.15-20.9c1.3,0.92,2.44,1.73,3.57,2.53c8.94-4.84,13.06-5.53,22.32,0.07c2.7,1.64,6.16,2.2,9.36,2.79
				c3.58,0.66,7.34,0.45,10.89,1.24c4.9,1.1,9.46,2.72,10.83,8.77c0.47,2.06,3,3.71,4.72,5.41c1.74,1.72,3.74,3.19,5.47,4.91
				c3.93,3.92,8.24,7.57,11.52,11.98C928.23,709.51,931.44,715.65,935.37,722.06z"/>
			<path onClick={() => props.onFill(68)} fill={props.fillColors[68]} d="M1087.62,649.38c3.04-0.36,6.09-1.02,9.11-0.93c1.07,0.03,2.75,1.75,3.01,2.94
				c1.27,5.93,1.68,6.23,7.31,3.69c6.34,7.67,11.7,9.04,17.26,4.43c4.43,1.53,11.97-0.96,15.24-4.43c1.44-1.53,4.09-1.85,5.81-3.2
				c2.95-2.32,5.6-5.02,8.82-7.97c2.53,7.7,4.93,15.19,7.45,22.64c1.85,5.48,4.12,10.84,5.7,16.39c1.35,4.74,2.2,9.65,2.86,14.54
				c0.44,3.23-0.94,4.72-4.74,4.2c-8.85-1.21-17.88-1.36-26.62-3.05c-3.64-0.7-8.37-3.52-9.73-6.62c-2.85-6.53-8.42-8.89-13.5-12.51
				c-9.07-6.48-17.68-13.62-27.13-20.99c2.06-1.16,2.99-1.69,4.67-2.63c-1.99-1.79-3.79-3.42-5.6-5.05
				C1087.56,650.34,1087.59,649.86,1087.62,649.38z"/>
			<path onClick={() => props.onFill(69)} fill={props.fillColors[69]} d="M1142,405.18c-1.31-0.13-2.64-0.15-3.93-0.4c-1.93-0.38-3.83-0.92-5.71-1.39
				c-0.87-8.83-7.27-11.53-15.03-13.3c0.87-1.56,1.89-2.71,2.12-4c0.25-1.36,0.27-3.28-0.5-4.22c-2.1-2.55-4.67-4.72-7.23-7.2
				c8.81-5.41,16.93-5.88,26.25-5.47c16.1,0.71,32.62,0.22,47.59-7.86c2.72-1.47,5.76-2.35,9.54-3.86c0,9.5,0.4,18.23-0.15,26.91
				c-0.34,5.41-10.03,9.78-15.41,8.02c-2.32-0.76-5.18-0.93-7.54-0.33c-3.43,0.87-6.42,3.12-10.39,1.65c-1-0.37-3.56,1.02-4.04,2.17
				c-1.61,3.88-4.48,3.81-7.96,4.17c-2.79,0.29-5.38,2.51-8.06,3.88C1141.7,404.35,1141.85,404.76,1142,405.18z"/>
			<path onClick={() => props.onFill(70)} fill={props.fillColors[70]} d="M1092.74,188.45c2.74-10.73,5.23-20.83,7.95-30.86c0.47-1.71,1.93-3.15,2.94-4.72
				c0.71,1.6,1.57,3.15,2.08,4.81c0.58,1.9,0.67,3.95,1.28,5.84c2.53,7.81,2.53,15.62,0.6,23.57c-0.71,2.94-0.62,6.07,0.15,9.37
				c0.5-0.92,1.4-1.82,1.43-2.75c0.31-10.56,2.65-21.08-0.3-31.7c-2.19-7.9-1.37-8.22,6.55-7.52c4.75,0.42,9.61-0.22,14.41-0.48
				c2.74-0.15,5.47-0.5,8.76-0.81c-4.89,5.98-4.89,5.98-1.34,8.99c-3,4.93-2.7,5.67,2.9,7.29c4.24,1.23,8.45,1.96,12.71-0.92
				c-4.46-0.59-8.92-1.18-14.31-1.89c6.4-4.67,2.96-6.64-2.52-8.35c2.53-1.18,4.12-1.92,5.67-2.65c2.25,3.48,4.34,6.7,6.43,9.93
				c0.3-0.16,0.6-0.33,0.91-0.49c-0.93-2.15-1.86-4.29-2.83-6.52c10.28-0.78,20.15-1.59,30.03-2.18c0.85-0.05,2.36,1.21,2.59,2.11
				c1.01,4.07,1.66,8.22,2.52,12.72c-1.04,0.12-2.46,0.42-3.89,0.44c-8.09,0.09-16.21-0.84-23.97,2.96
				c-2.27,1.11-5.73,0.74-8.33-0.02c-5.16-1.5-7.53,0.95-9.04,5.19c-1.49,4.21-2.43,8.67-4.33,12.68c-2.19,4.63-5.16,8.9-8.07,13.8
				c-2.3-4.43-5.16-5.36-10.06-4.58c-2.58,0.41-8.37-4.01-8.52-6.5c-0.43-6.99-4.25-6.79-9-6.51
				C1095.32,188.72,1094.49,188.57,1092.74,188.45z"/>
			<path onClick={() => props.onFill(71)} fill={props.fillColors[71]} d="M731.45,395.19c-5.47,0-10.94,0.07-16.41-0.02c-7.78-0.13-15.56-0.16-23.32-0.66
				c-3.36-0.21-5.14-2.27-3.46-5.43c11.06,1.38,21.87,2.78,32.7,4.03c2.46,0.28,5.06,0.48,7.45-0.01c8.73-1.79,17.33-4.56,26.47-1.79
				c0.06-0.54,0.12-1.09,0.18-1.63c-3.81-0.77-7.62-1.54-11.7-2.37c10.74-2.02,21.37,3.22,32.24-0.3c-0.69-0.66-0.94-1.09-1.12-1.06
				c-13.94,2.35-27.67-3.6-42.04,0.29c-13.85,3.75-27.99-0.67-41.61-4.72c0.59-5.55,0.52-5.14,6.03-4.23
				c7.54,1.25,15.19,2.4,22.81,2.53c20.29,0.36,40.59,0.25,60.89,0.12c10.71-0.07,21.19-1.73,31.21-5.82
				c1.03-0.42,2.5,0.22,3.77,0.36c-0.44,1.55-0.43,3.99-1.41,4.5c-4.82,2.49-9.99,4.31-14.89,6.65c-5.33,2.55-10.5,5.41-15.74,8.14
				c-1.88,0.98-3.65,2.45-5.65,2.9c-18.11,4.07-36.12,8.99-54.46,11.54c-12.48,1.74-25.43,0.16-38.16-0.18
				c-0.78-0.02-2.27-2.27-2.11-3.27c0.41-2.57,1.32-5.16,2.54-7.47c0.45-0.85,2.37-1.36,3.59-1.3c14.02,0.6,28.03,1.33,42.04,2.03
				C731.35,397.09,731.4,396.14,731.45,395.19z M716.5,399.62c-8.77,0.8-16.89,1.53-25.04,2.27
				C695.81,405.3,710.03,404.18,716.5,399.62z"/>
			<path onClick={() => props.onFill(72)} fill={props.fillColors[72]} d="M402.71,459.73c-2.67-6.48-8.74-11.12-7.02-20.04c1.04-5.4-0.42-11.94-6.42-16.1
				c-2.63-1.82-4.3-5.58-5.48-8.8c-2.6-7.12-4.77-14.42-6.75-21.74c-0.43-1.6,0.53-3.66,1.11-5.42c0.8-2.44,2.65-4.82,2.61-7.2
				c-0.08-4.41,1.49-6.82,5.65-8.03c2.02-0.59,3.87-1.77,6.13-2.84c1,1.49,1.97,2.8,2.8,4.2c8.74,14.86,17.37,29.78,26.24,44.56
				c1.87,3.11,1.11,5-0.6,7.95c-4.71,8.07-8.72,16.54-12.92,24.9c-1.43,2.84-2.58,5.82-3.86,8.74
				C403.7,459.86,403.21,459.79,402.71,459.73z"/>
			<path onClick={() => props.onFill(73)} fill={props.fillColors[73]} d="M617.62,329.83c-7.88,3.51-6.06,10.42-7.12,16.5c-1.71,9.8-4.1,19.49-6.29,29.21
				c-0.92,4.08-2.03,8.11-3.05,12.16c-0.6,0.09-1.2,0.18-1.8,0.28c-2.48-2.77-5.01-5.49-7.44-8.31
				c-10.99-12.72-21.96-25.47-32.93-38.21c-0.76-0.88-2.03-1.78-2.09-2.73c-0.14-2.54-0.12-5.24,0.64-7.61
				c0.24-0.75,3.04-0.72,4.68-0.95c2.29-0.32,4.98,0.13,6.82-0.93c7.02-4.05,14.19-3.33,21.71-2.14c6.2,0.98,12.56,0.92,18.85,1.3
				c2.3,0.14,4.59,0.25,6.89,0.37C616.87,329.12,617.24,329.47,617.62,329.83z"/>
			<path onClick={() => props.onFill(74)} fill={props.fillColors[74]} d="M1146.61,408.23c-0.73-5.34,0.9-7.2,5.47-6.1c3.13,0.75,4.57,0.57,5.85-2.9
				c2.18-5.92,7.83-1.28,11.09-2.86c3.86-1.88,6.68-4.71,10.17,0.39c4.53-2.36,9.24-3.88,13.02-2.05c1.07,3.02,1.92,4.75,2.25,6.58
				c0.31,1.67,0.6,4.68-0.03,4.95c-2.17,0.93-4.74,0.92-7.15,1.27c-0.11-0.6-0.22-1.19-0.34-1.79c1.23-0.48,2.47-0.96,3.76-1.47
				c-1.97-3.01-5.5-2.52-6.07,0.77c-0.23,1.32,0.72,3.1,1.64,4.29c0.96,1.25,2.52,2.05,4.6,3.64c-3.21,0.97-5.63,1.7-7.87,2.38
				c-0.21,2.31-0.39,4.31-0.54,5.95c1.08,4.18,10.35-5.61,7.54,5.45c1.52,0.59,3.15,1.22,4.65,1.8c0.17,3.36,0.22,6.68,0.54,9.98
				c0.41,4.26-0.24,7.44-4.36,6.67c-1.48,2.82-2.13,5.98-3.94,6.95c-1.84,0.98-4.83-0.2-8.79-0.57c4.13-4.51,5.23-7.37-0.78-8.43
				c2.18-3.7,1.33-5.08-2.73-5.59c-2.03-0.26-3.73-3.03-5.85-4.9c1.92-2.17,3.77-4.27,5.62-6.37c-0.27-0.4-0.53-0.8-0.8-1.2
				c-1.88,0.81-3.75,1.63-7.03,3.05c1.61-2.59,2.97-4.01,3.41-5.68c0.47-1.78,0.09-3.78,0.07-5.68c-1.06,0.94-2.22,1.8-3.16,2.84
				c-1.61,1.77-3.08,3.65-5.29,6.31c-0.24-2.03-0.58-2.87-0.38-3.56c0.83-2.87-0.53-4.12-3.13-4.38c-3.33-0.33-3.19,2-3.16,4.28
				c0.1,7.62,0.08,7.61-7.46,7.55c-3.81-0.03-7.62,0-11.31,0c-0.11-0.18-6.18-10.4-6.18-10.4c-1.19-1.8-2.38-3.6-3.3-5
				c0.89-2.68,1.66-4.98,2.77-8.3c2.03,0,5.11-0.26,8.13,0.07C1140.44,406.53,1143.28,407.46,1146.61,408.23z"/>
			<path onClick={() => props.onFill(75)} fill={props.fillColors[75]} d="M57.22,496.61c29.22,4.51,59.54-4.43,90.79,2.9c-3.69,4.37-7.13,7.87-9.89,11.84
				c-5.86,8.44-15.63,8.11-23.91,10.59c-7.98,2.39-16.57,2.68-24.84,4.18c-1.94,0.35-3.9,1.59-5.46,2.88
				c-4.1,3.41-7.54,2.65-8.92-2.46c-1.04-3.83-0.39-8.56-5.98-9.44C66.75,509.24,56,506.72,57.22,496.61z"/>
			<path onClick={() => props.onFill(76)} fill={props.fillColors[76]} d="M1166.94,514.13c-1.66,0.43-3.32,0.86-5.69,1.48c1.37,1.16,2.6,1.91,3.44,2.97
				c1.22,1.56,2.16,3.34,3.22,5.03c-1.98,0.85-3.94,1.75-5.94,2.52c-0.69,0.27-1.49,0.25-3.63,0.56c2.84,1.86,4.63,3.03,6.44,4.21
				c-4.11,5.37-8.65,5.1-13.93,1.86c0.66,1.07,1.33,2.15,1.98,3.21c-5.6,4.12-7.72,3.97-11.77-0.77c-1.44,0.91-2.91,1.84-5.1,3.22
				c-0.19-2.52-0.32-4.31-0.51-6.79c-1.64,1.16-2.8,1.98-3.31,2.34c-1.98-0.57-3.49-1.28-5.03-1.38c-1.55-0.1-3.14,0.39-4.71,0.62
				c-0.33-0.48-0.65-0.95-0.98-1.43c1.47-0.64,2.94-1.29,4.71-2.06c-3.27-1.59-5.44-2.65-7.61-3.7c2.14-1.53,4.28-3.05,6.55-4.67
				c-2.71-0.92-5.51-1.87-9.79-3.33c3.49-2.3,5.67-3.73,8.74-5.75c-2.74-1.13-4.72-1.94-7.92-3.27c2.61-1.6,4.39-2.68,6.65-4.07
				c-1.39-1.05-2.85-2.14-4.66-3.5c1.79-3.94,4.17-6.2,8.82-4.44c0.9,0.34,2.27,0.91,2.81,0.53c4.76-3.39,10.49-2.25,15.61-3.65
				c3.45-0.95,6.83-3.17,9.54-5.61c4.86-4.38,10.66-4.01,14.13,1.5c0.51,0.81,1.24,1.48,2.16,2.56c-1.23,1.38-2.35,2.76-3.6,4
				c-1.28,1.27-2.7,2.41-4.26,3.78c0.92,1.57,1.73,2.93,2.67,4.54c-1.54,0.83-3.06,1.65-3.95,2.12c2.16,1.74,4.29,3.46,6.43,5.17
				C1167.94,512.68,1167.44,513.4,1166.94,514.13z"/>
			<path onClick={() => props.onFill(77)} fill={props.fillColors[77]} d="M245.04,525.74c-4.04,0.42-5.96,0.98-6.06,5.94c-0.07,3.11-1.49,6.38-0.98,10.23
				c0.43,3.28-0.6,7.8-6.4,7.26c-1.95-0.18-4.77,0.63-5.96,2.04c-3.34,3.95-7.55,3.48-11.87,3.62c-7.98,0.25-15.95,0.66-23.92,1.18
				c-8.93,0.58-17.33-1.77-25.84-3.98c-5.08-1.32-10.5-1.31-15.75-2.01c-2.37-0.32-4.7-0.91-7.05-1.38c-0.02-0.5-0.05-1.01-0.07-1.51
				c2.74-0.59,5.47-1.52,8.24-1.7c7.47-0.47,14.96-0.87,22.44-0.83c8.8,0.05,16-3.66,23.02-8.39c8.23-5.55,16.65-10.84,25.08-16.09
				c1.15-0.72,2.94-0.86,4.33-0.65c7.33,1.12,13.32-1.58,18.95-5.9c1.13-0.86,3.03-0.71,4.57-1.03c-0.75,1.36-1.16,3.24-2.31,3.99
				C241.39,519.19,243.21,522.11,245.04,525.74z M224.86,548.37c-0.13-0.63-0.26-1.26-0.39-1.89c-3.66,0.73-7.32,1.46-10.97,2.19
				c0.14,0.69,0.29,1.38,0.43,2.07C217.57,549.95,221.21,549.16,224.86,548.37z"/>
			<path onClick={() => props.onFill(78)} fill={props.fillColors[78]} d="M381.19,720.63c-1.91,4.01-3.09,6.92-4.62,9.63c-2.74,4.84-7.33,4.95-11.82,3.62
				c-5.04-1.49-9.04-0.69-12.62,3.21c-2.55,2.78-5.68,3.34-9.54,2.41c-15.61-3.75-27.55,5.79-28.29,20.31
				c-0.42,8.22-2.74,15.59-6.33,22.81c-1.68,3.39-1.88,8.14-4.42,10.32c-4.05,3.47-9.47,5.35-14.41,7.68
				c-0.25,0.12-2.09-1.76-2.12-2.74c-0.28-10.37-1.24-20.85-0.02-31.07c0.48-4.05,5.55-7.95,9.22-11.13
				c9.37-8.12,18.46-16.75,30.37-21.33c3.54-1.36,6.89-3.2,10.42-4.56c5.55-2.14,11.39-3.62,16.73-6.16
				C362.35,719.53,371.27,721.27,381.19,720.63z"/>
			<path onClick={() => props.onFill(79)} fill={props.fillColors[79]} d="M665.91,181.59c0.5,5.09,0.91,9.28,1.33,13.48c0.33-0.01,0.66-0.02,0.99-0.04c0-2.91,0.02-5.82,0-8.72
				c-0.05-7.94,1.96-10.15,9.57-10.26c5.31-0.08,10.62-0.01,16.3-0.01c0.56,2.65,1.14,5.33,1.89,8.87c-1.76,0-3.36,0.47-4.42-0.08
				c-4.02-2.09-6.22-0.3-8.82,2.74c-3.62,4.21-7.94,7.82-11.98,11.66c-0.34,0.33-0.94,0.75-1.29,0.65
				c-7.68-2.17-10.55,3.92-14.49,8.27c-0.47,0.51-1.45,0.74-2.21,0.78c-8.23,0.39-16.45,0.72-24.68,1.06
				c-1.16,0.05-2.47,0.45-3.45,0.05c-7.05-2.9-14.08-1.08-21.12-0.1c-3.73,0.52-3.96,0.34-4.08-4.13c-5.24,0-10.5,0-15.76,0
				c-0.07-0.61-0.13-1.22-0.2-1.83c2.41-0.42,4.81-1.07,7.23-1.22c14.94-0.9,29.89-1.56,44.82-2.57c5.59-0.38,11.13-1.48,16.71-3.34
				c-4.37,0-8.75-0.16-13.11,0.05c-3.56,0.17-7.09,0.9-10.65,1.14c-5.46,0.37-10.94,0.73-16.42,0.68c-2.41-0.02-6.27,1.88-6.69-2.69
				c-0.45-4.89,2.31-7.45,7.02-7.39c9.65,0.14,19.31,0.21,28.97,0.26c1.21,0.01,2.41-0.33,4.52-0.64c-3.64-2.36-5.71-4.09-9.98-3.75
				c-8.34,0.65-16.83-0.7-25.9-1.26c0.55-1.94,0.97-3.42,1.35-4.75c9.5,0.88,18.94,0.66,27.77,2.88
				C647.92,183.6,656.27,184.82,665.91,181.59z M642.67,192.15c-0.03-0.62-0.06-1.23-0.09-1.85c-10.89,0.78-21.78,1.56-32.67,2.35
				c0.07,0.79,0.14,1.59,0.21,2.38C620.98,194.07,631.83,193.11,642.67,192.15z"/>
			<path onClick={() => props.onFill(80)} fill={props.fillColors[80]} d="M207.52,495.05c-5.7-7-11.66-12.62-15.4-19.45c-2.99-5.45-6.02-10.49-10.2-15.06
				c-1.33-1.46-1.66-4.38-1.5-6.56c0.52-7.3,1.4-14.58,2.32-21.85c0.14-1.09,1-2.56,1.94-3.02c6.98-3.4,14.06-6.58,21.47-10
				c0.83,5.5,1.65,11.47,2.64,17.42c1.86,11.28,3.93,22.53,5.65,33.83c0.39,2.53,0.22,5.38-0.56,7.81
				C212.08,483.79,209.75,489.24,207.52,495.05z"/>
			<path onClick={() => props.onFill(81)} fill={props.fillColors[81]} d="M1121.44,463.65c6.04-4.08,6.09-4.29,1.79-8.31c0.69-1.17,1.4-2.4,2.04-3.49c-0.17-0.39-0.19-0.6-0.3-0.68
				c-1.43-1.06-2.88-2.09-4.32-3.13c4.28-5.97,12.54-9.17,19.37-7.63c3.01,0.68,3.94,3.25,1.86,5.51c-1.29,1.4-2.87,2.53-3.97,4.61
				c4.22-0.62,8.43-1.24,13.95-2.04c-1.52,2.89-2.18,4.14-2.54,4.83c1.54,3.06,2.91,5.79,4.28,8.52c-0.38,0.36-0.77,0.72-1.15,1.08
				c3.56,0.53,7.12,1.05,10.68,1.58c0.09,0.51,0.19,1.02,0.28,1.54c-2.69,1.6-5.38,3.21-8.91,5.31c3.6,1.84,6.54,3.34,9.47,4.84
				c0.11,0.57,0.23,1.14,0.34,1.7c-3.97,1.61-7.82,3.78-11.95,4.68c-3.7,0.81-5.23,1.95-4.52,5.87c0.5,2.76-5.36,5.74-8.5,3.89
				c-2.88-1.7-4.31-0.57-6.53,0.9c-1.56,1.03-4.01,1.37-5.91,1.06c-3.08-0.5-6.02-1.8-9.51-2.91c0.02-0.14,0.58-1.5,0.38-2.73
				c-0.21-1.25-1.18-2.37-2.05-3.98c-1.74,0-3.98,0-6.21,0c-0.17-0.5-0.34-1-0.51-1.5c2-0.82,3.94-2.05,6.01-2.36
				c3.21-0.48,5.36-1.53,5.5-5.12c0.04-0.96,0.1-2.32,0.7-2.78C1125.21,469.88,1123.78,466.74,1121.44,463.65z"/>
			<path onClick={() => props.onFill(82)} fill={props.fillColors[82]} d="M1083.48,314.78c0.35,4.52-5.24,7.2-11.38,4.79c-1.84-0.72-3.58-1.81-5.17-3
				c-2.73-2.04-5.33-2.84-9.06-2.72c-3.94,0.12-8.69-1.61-11.76-4.14c-4.29-3.54-7.54-2.48-11.14,0.07c-2.81,1.99-5.45,3.3-8.72,0.15
				c0.47-0.9,1.01-1.94,1.66-3.18c-1.69-0.77-3.13-1.42-4.58-2.07c-0.03-0.39-0.07-0.77-0.1-1.16c2.07-0.49,4.12-1.17,6.23-1.41
				c2.12-0.24,4.29-0.05,6.91-0.05c-0.3-1.5-0.53-2.64-0.92-4.56c3.08,0.49,5.86,0.93,8.49,1.35c-0.67-2.68-1.31-5.27-1.95-7.85
				c0.52-0.18,1.04-0.35,1.56-0.53c1.22,0.74,2.45,1.48,3.67,2.22c0.29-0.25,0.59-0.49,0.88-0.74c-0.75-0.88-1.62-1.69-2.23-2.66
				c-0.81-1.29-1.41-2.71-2.1-4.08c1.81-0.28,4.26-1.44,5.31-0.68c2.69,1.95,4.07,3.12,7.38,0.15c3.52-3.15,2.62,2.56,4.22,3.56
				c2.79,0.24,5.57-8.89,7.43-1.5c3.32,1.56,5.43,2.55,8.18,3.85c5.87-5.8,10.52,3.52,16.66,2.3c0.32,0.51,0.64,1.01,0.96,1.52
				c-1.52,0.61-3.04,1.23-4.56,1.84c0.16,0.52,0.33,1.04,0.49,1.56c4.4,0.5,10.81-3.9,12.07,4.63c-1.88,0.55-3.95,1.16-6.82,2
				c1.77,1.03,2.81,1.64,3.86,2.25c-0.1,0.32-0.24,0.83-0.25,0.82c-4.36-1.07-0.59,8.06-6.5,5.18c-0.8-0.39-2.09,0-3.09,0.26
				C1087.4,313.41,1085.73,314.04,1083.48,314.78z"/>
			<path onClick={() => props.onFill(83)} fill={props.fillColors[83]} d="M871.95,391.51c-7.64-1.8-14.62-3.45-21.74-5.13c4.04-6.78,3.58-7.36-4.39-7.82
				c-1.86-0.11-4.69-1.17-5.2-2.51c-0.58-1.51,0.4-4.93,1.68-5.59c4.46-2.27,9.22-4.51,14.1-5.28c8.67-1.36,17.64-0.95,26.25-2.54
				c9.58-1.77,18.43,0.6,27.53,2.37c3.33,0.65,6.83,0.42,11.46,0.65c-2.65,5.56-5.04,10.98-7.81,16.21c-1.58,2.99-4.4,3.81-7.73,2.85
				c-2.71-0.78-5.54-1.2-8.21-2.09c-3.18-1.06-5.73-1.28-7.56,2.34c-0.42,0.83-2.49,1.17-3.79,1.16c-2.97-0.03-5.94-0.59-8.92-0.62
				C874.22,385.49,871.25,386.31,871.95,391.51z"/>
			<path onClick={() => props.onFill(84)} fill={props.fillColors[84]} d="M872.39,453.4c-7.19,1.52-11.09-0.22-13.98-7.01c-3.3-7.76-6.14-15.71-9.45-23.46
				c-0.58-1.36-2.33-2.78-3.78-3.1c-8.04-1.78-16.16-3.23-24.51-4.84c3.56-13.23,10.82-24.7,18.02-36.25
				c3.04,1.3,5.68,2.44,8.37,3.59c-1.52,5.65-0.61,7.08,5.41,8.08c1.64,0.27,3.48,0.02,4.89,0.71c1.27,0.62,2.68,2.08,2.94,3.39
				c0.46,2.26-0.7,3.91-3.43,3.41c-3.37-0.61-6.72-1.29-10.08-1.93c-0.13,0.46-0.25,0.92-0.38,1.38c3.38,0.81,6.76,1.65,10.16,2.42
				c0.92,0.21,2.53-0.11,2.72,0.32c0.55,1.21,1.11,3.58,0.64,3.88c-2.52,1.6,1.41,8.46-5.35,6.42c-3.05-0.92-6.26-1.27-9.4-1.89
				c-0.11,0.61-0.22,1.22-0.34,1.83c3.21,0.55,6.4,1.33,9.63,1.59c3.66,0.29,4.32,1.63,2.57,4.78c-0.51,0.92-0.16,2.32-0.23,3.5
				c-0.21,3.58-0.43,7.16,0.12,10.9c0.47-1.94,1.4-3.91,1.31-5.82c-0.2-4.46,1.67-6.04,5.95-5.45c1.61,0.22,3.27,0.04,5,0.04
				C870.28,431.3,871.33,442.33,872.39,453.4z"/>
			<path onClick={() => props.onFill(85)} fill={props.fillColors[85]} d="M1122.04,330.58c7.1,4.65,13.57,8.16,21.29,3.25c0.73-0.46,2-0.69,2.75-0.38
				c3.78,1.55,5.77-0.38,7.89-3.19c0.95-1.27,3.17-1.58,4.83-2.31c2.84-1.25,5.72-2.43,8.52-3.77c1.12-0.54,2.05-1.47,3.1-2.18
				c1.5-1,3.65-1.61,4.43-2.99c4.39-7.84,11.72-10.2,20.07-11.34c0,8.47,0,16.9,0,26.82c-6.09,1.28-12.44,2.58-18.78,3.97
				c-3.38,0.74-7.06,0.99-10.04,2.55c-8.21,4.31-16.24,9-24.09,13.94c-2.99,1.88-5.58,2.65-8.77,1.14c-1.19-0.57-2.55-0.82-3.69-1.47
				c-3.05-1.74-7.83-3.01-8.68-5.54C1118.95,343.25,1118.86,336.77,1122.04,330.58z"/>
			<path onClick={() => props.onFill(86)} fill={props.fillColors[86]} d="M636.61,691.17c3.68,4.24,7.04,8.12,10.51,12.12c-3.81,6.4-1.72,8.47,10.36,9.03
				c0.23,2.48,0.46,5.02,0.84,9.1c2.73-4.25,0.64-10.56,7.73-10.2c1.01,2.23,2.23,4.93,3.76,8.31c-2.77,0-4.71-0.44-6.28,0.13
				c-1.4,0.51-2.75,1.96-3.45,3.33c-0.89,1.76-1.09,3.86-1.24,5.88c-0.19,1.42-0.27,2.87-0.61,4.27c-0.77,3.22,0.63,5.87,3.58,5.82
				c4.1-0.07,6.94,0.78,8.08,5.21c3.5-4.9,7.99-6.45,10.48-5.11c0.93,3.5,1.32,6.06,2.3,8.37c1.2,2.84-0.72,6.26-3.87,5.49
				c-4.56-1.12-9.04-2.95-13.24-5.09c-3.11-1.58-5.58-3.84-8.48,0.26c-0.53,0.75-2.97,0.99-3.96,0.44
				c-4.85-2.72-8.36-2.34-10.28,3.43c-0.14,0.41-0.77,0.63-1.08,1.01c-0.15,0.19-0.09,0.56-0.33,2.41c2.19-1.78,4.19-2.61,4.73-4.01
				c1.1-2.86,2.71-2.41,4.7-1.55c3.3,1.43,6.67,3.86,9.21-1.25c0.17-0.34,2.84-0.06,3.74,0.68c5.59,4.55,12.04,5.91,19.02,5.67
				c0.15,0.45,0.3,0.9,0.45,1.34c-3.41,1.09-6.76,2.69-10.26,3.16c-7.87,1.07-15.84,1.37-23.72,2.38c-4.76,0.61-7.82-0.94-10.33-4.99
				c-2.72-4.39-4.61-8.88-1.5-13.45c3.01-4.42,5.94-8.35,3.44-14.13c-0.4-0.92,1.37-3.05,2.49-4.31c1.64-1.86,2.43-3.3,0.29-5.49
				c-1.43-1.46-2.64-3.47-3.16-5.44c-0.8-3.02-1.98-5.02-5.35-5.6c-2.52-0.43-3.47-2.15-2.49-4.86
				C634.05,699.88,635.08,696.12,636.61,691.17z"/>
			<path onClick={() => props.onFill(87)} fill={props.fillColors[87]} d="M355.61,420.7c1.82,16.68,3.58,32.81,5.33,48.95c0.07,0.66,0.13,1.33,0.14,1.99
				c0.05,7.04-1.12,14.35,4.72,20.56c-8.87-3.43-18.99,0.48-26.56-6.63c-1.21-1.13-3.3-1.39-5.04-1.88
				c-7.33-2.04-9.67-9.24-5.2-15.26c0.94-1.27,1.39-4.07,0.63-5.21c-1.97-2.97-0.64-4.4,1.1-6.81c7.32-10.1,14.23-20.5,21.31-30.78
				C353.23,423.93,354.49,422.26,355.61,420.7z"/>
			<path onClick={() => props.onFill(88)} fill={props.fillColors[88]} d="M310.93,574.54c2.78-1.22,4.19-2.07,5.71-2.47c3.77-0.98,7.5-2.69,7.72-6.69c0.11-2.04-2.9-4.26-4.53-6.45
				c8.83-1.67,13.37-8.83,16.24-16.92c2.06-5.81,7.8-6.83,11.4-10.56c2.05-2.12,7.68,2.4,6.83,5.6c-1.12,4.25,0.46,6.05,4.43,7.18
				c1.83,0.52,3.75,2.15,4.8,3.8c4.27,6.72,8.21,13.64,12.61,21.05c-6.64,3.74-13.8,6.17-21.17,4.51c-8.3-1.88-16.02-0.95-24.01,1.06
				C324.9,576.17,318.64,577.58,310.93,574.54z"/>
			<path onClick={() => props.onFill(89)} fill={props.fillColors[89]} d="M594.88,251.59c0.58-1.38,0.61-1.63,0.72-1.68c14.34-6.6,28.13-14.56,45.07-12.68
				c12.71,1.41,25.57,1.63,38.38,1.98c4.08,0.11,8.19-1.32,12.31-1.64c3.89-0.3,7.83-0.06,12.02-0.06
				c-5.04,13.53-16.9,20.02-30.33,17.23c-0.32-0.07-0.78-0.01-0.96-0.21c-7.05-7.99-15.3-2.24-23.01-1.89
				c-4.45,0.2-8.82,1.66-13.27,2.18c-3.39,0.39-6.83,0.94-9.73-2.29c-0.89-1-3.71-0.46-5.63-0.32c-6.59,0.48-13.17,1.3-19.76,1.55
				C598.84,253.81,596.94,252.4,594.88,251.59z"/>
			<path onClick={() => props.onFill(90)} fill={props.fillColors[90]} d="M601.31,528.37c4.38-0.48,8.17-0.9,12.76-1.41c-0.63,4.79-1.15,8.72-1.67,12.65
				c4.07-4.3,0.55-13.46,9.94-14.01c-6.49,0-12.98,0-19.47,0c-0.04-0.28-0.08-0.56-0.11-0.84c7-1.18,14-2.37,21-3.55
				c0.03-0.36,0.07-0.72,0.1-1.07c-3.54-0.13-7.09-0.37-10.63-0.34c-2.31,0.02-4.63,0.67-6.93,0.63c-1.62-0.03-3.22-0.74-4.84-1.14
				c0.95-1.44,1.9-2.88,2.41-3.64c0.15-2.25-0.05-4.06,0.43-5.66c1.33-4.41,2.83-8.78,4.5-13.07c0.51-1.31,1.61-3.11,2.72-3.35
				c5.61-1.19,11.31-1.93,17.96-2.99c-0.56,2.36-0.93,4.67-1.66,6.87c-1.89,5.69-1.53,6.9,3.8,9.69c-1.09,1.6-2.14,3.14-2.74,4.02
				c0,4.52,0.26,8.6-0.07,12.63c-0.36,4.35-1.28,8.65-8.47,7.46c0.46,0.61,0.92,1.23,1.39,1.84c1.87-0.22,3.74-0.45,6.33-0.76
				c0,5.21,0,10.4,0,15.6c-0.01,9.31-1.09,10.14-10.54,8.6c-5.17-0.84-10.39-1.34-15.54-2.28c-0.85-0.15-2.1-1.82-2.02-2.68
				c0.25-2.77,0.56-5.72,1.73-8.18C603.94,538.72,603.54,534.27,601.31,528.37z M611.06,529.61c-0.66-0.15-1.31-0.29-1.97-0.44
				c-0.94,4.34-1.87,8.69-2.81,13.03c0.61,0.13,1.22,0.26,1.83,0.39C609.09,538.26,610.07,533.94,611.06,529.61z"/>
			<path onClick={() => props.onFill(91)} fill={props.fillColors[91]} d="M1103.06,686.35c-1.64-1.95-2.95-3.51-4.66-5.55c3.3-0.65,7.64,2.33,6.91-4.79
				c5.85,3.48,11.33,5.77,15.52,9.49c4.17,3.7,7.07,8.85,10.81,13.74c-16.77,4.14-32.43,13.72-51.09,10.85
				c-4.92-0.76-10.16,0.82-15.26,0.96c-2.72,0.08-5.46-0.71-8.2-0.94c-1.46-0.12-3.07-0.25-4.4,0.22c-9.34,3.34-12.01,1.8-13.56-8.06
				c-0.18-1.12-0.39-2.24-0.76-4.38c2.41,0.17,4.38,0.2,6.32,0.47c3.12,0.44,6.22,1.39,9.32,1.37c1.12-0.01,2.22-2.21,3.32-3.41
				c1.76,2,3.61,4.12,6.07,6.92c1.01-2.92,1.62-4.69,2.01-5.84c3.02,1.72,6.05,3.44,9.08,5.17c0.87-1.76,1.74-3.52,2.86-5.79
				c1.68,0.62,3.81,0.53,2.67-3.72c3.01,2.52,5.42,4.54,7.84,6.57c0.41-0.23,0.83-0.45,1.24-0.68c-0.17-2.1-0.34-4.21-0.51-6.3
				C1097.32,694.01,1099.46,693.07,1103.06,686.35z"/>
			<path onClick={() => props.onFill(92)} fill={props.fillColors[92]} d="M160.49,430.61c3.95,0,7.29,0,11.33,0c1.28,11.44,3.01,23.16,3.72,34.94c0.37,6.17,1.91,10.62,7.34,14.11
				c6.48,4.16,4.56,11.38,5.49,18.4c-3.27-0.32-5.99-0.81-8.72-0.82c-6.58-0.03-13.18-0.12-19.73,0.37
				c-3.37,0.25-3.99-1.43-3.59-3.72c0.9-5.26,0.24-10.14-0.59-15.45c-1.18-7.46,0.57-15.42,1.35-23.14
				c0.8-7.87,1.93-15.72,2.94-23.57C160.09,431.25,160.4,430.82,160.49,430.61z"/>
			<path onClick={() => props.onFill(93)} fill={props.fillColors[93]} d="M913.76,665.11c4.96,0.48,9.5,0.91,15.13,1.46c-4.27,2.69-7.98,5.03-11.69,7.37
				c-0.01,0.52-0.01,1.03-0.02,1.55c2.73,0.97,5.45,1.94,8.92,3.18c-2.83,2-5.28,3.74-8.95,6.33c13.44,7.25,25.82,3.01,38.15,0.62
				c0.42,7.32,1.54,7.83,11.11,4.49c0.61,4.14,3.09,5.42,7.04,3.92c0.53-0.2,1.77,0.37,2.11,0.93c3.49,5.71,6.86,11.49,10.26,17.24
				c-0.4,0.28-0.8,0.55-1.19,0.83c-5-2.03-9.91-4.34-15.03-6c-3.75-1.21-7.79-2.19-11.68-2.11c-5,0.1-8.91-1.82-13.04-4.12
				c-5.78-3.23-11.64-6.55-17.86-8.69c-8.29-2.86-14.92-7.6-19.15-15.03c-2.12-3.74-2.51-8.47-3.66-12.76
				c-0.47-1.76-0.64-3.64-1.35-5.29c-2.06-4.79-3.38-9.47-2.62-14.93c0.45-3.25-1.52-6.77-1.79-10.22c-0.11-1.43,1.35-2.98,2.1-4.48
				c1.37,0.88,3.78,1.61,3.94,2.68c1.23,8.23,6.73,13.93,11.45,20.05c3.03,3.92,4.84,7.36-1.13,10.16
				C914.49,662.45,914.45,663.18,913.76,665.11z"/>
			<path onClick={() => props.onFill(94)} fill={props.fillColors[94]} d="M991.25,394.9c-0.09,0.38-0.39,1.59-0.72,2.95c-2.61,0.02-5.87-3.19-7.43,1.53
				c-0.1,0.3-2.6-0.19-2.94-0.21c-1.02,2.91-1.55,7.14-2.74,7.34c-3.67,0.6-7.81,0.3-11.36-0.88c-3.24-1.08-5.95-3.77-9.21-5.96
				c1.05-0.91,2.87-2.49,4.69-4.06c-0.14-0.33-0.29-0.65-0.43-0.98c-1.52,0.3-3.06,0.91-4.55,0.83c-1.6-0.09-3.17-0.84-4.76-1.3
				c0.9-1.33,1.74-2.71,2.74-3.96c0.45-0.56,1.23-0.85,1.92-1.3c-2.37-1.3-4.46-2.44-6.55-3.59c0.04-0.71,0.07-1.43,0.11-2.14
				c4.42-1.29,8.84-2.58,13.31-3.89c-0.84-5.13-0.1-5.97,5.77-6.17c2.68-0.09,5.65,0.75,7.19-2.71c0.24-0.53,1.85-0.75,2.75-0.62
				c3.17,0.43,6.46,1.84,8.59-2.1c0.19-0.35,1.31-0.48,1.86-0.29c6.04,2,12.06,4.08,19.37,6.57c-3.4,1.77-5.74,2.99-8.07,4.21
				c3.83,6.69,3.83,6.69-2.29,10.81c0.37,2.08,0.72,4.02,1.06,5.96C996.95,394.9,994.32,394.9,991.25,394.9z"/>
			<path onClick={() => props.onFill(95)} fill={props.fillColors[95]} d="M636.87,716.57c-2.17-1.54-4.22-3.29-6.54-4.55c-2.95-1.61-6.08-3.78-9.22-3.94
				c-3.58-0.18-5.31-1.58-7.15-4.2c-2.24-3.2-5.3-5.83-7.47-9.08c-3.02-4.53-5.33-9.52-8.33-14.07c-3.37-5.1-7.16-9.92-10.81-14.84
				c-0.38-0.52-1.03-1.19-1.56-1.19c-10.06,0.04-14.12-10.24-22.65-13.05c-8.45-2.78-11.1-10.95-14.88-18.02
				c9.2,9.68,22.05,12.58,33.04,18.78c10.24,5.77,19.65,13.04,29.25,19.9c5.41,3.86,10.46,8.22,15.68,12.35
				c6.2,4.9,7.4,9.13,4.72,16.49c-0.51,1.41-0.94,2.84-1.42,4.26c-0.94,2.76-0.03,4.28,2.78,5.28c2.2,0.78,4.12,2.32,6.17,3.53
				C637.95,715,637.41,715.79,636.87,716.57z"/>
			<path onClick={() => props.onFill(96)} fill={props.fillColors[96]} d="M1125.37,327.89c3.65-0.78,6.15-1.43,8.69-1.84c6.62-1.07,12.3-3.64,16.31-9.31
				c0.61-0.87,2.13-1.03,3.1-1.71c4.88-3.46,10.33-5.81,15.58-8.53c2.58-1.34,4.9-4.2,8.02-5.76c3.93-1.96,4.05-10.14,0.51-12.16
				c-7.83-4.47-7.83-4.47-11.67-11.24c3.32-1.69,6.64-3.37,9.96-5.06c0.71-0.36,1.95-0.65,2.03-1.12c1.2-7.23,5.3-3.66,9.14-2.59
				c-0.33,1.57-0.62,2.93-0.98,4.64c0.75,0.22,1.47,0.59,2.21,0.61c5.69,0.2,6.81,1.2,6.86,6.98c0.06,6.65-0.06,13.31-0.17,19.97
				c-0.08,4.56-2.16,6.22-6.71,6.07c-1.82-0.06-3.8,0.39-5.48,1.14c-2.67,1.18-5.14,2.8-8.11,4.47c0.48,5.82-1.6,7.53-10.31,7.35
				c1.59,6.63-0.41,7.49-11.88,5.32c-0.51,1.98-0.75,5.46-1.55,5.59c-2.72,0.45-5.64-0.28-8.44-0.56c-1.25,1-2.82,3.3-4.66,3.55
				C1133.35,334.27,1129.02,333.33,1125.37,327.89z"/>
			<path onClick={() => props.onFill(97)} fill={props.fillColors[97]} d="M760.96,557.19c-1.43-0.87-2.45-1.13-2.74-1.73c-5.47-11.53-14.92-19.76-23.66-28.54
				c-6.74-6.78-6.82-15.8-9.37-24.01c0.38-0.21,0.76-0.41,1.14-0.62c1.22,2.29,2.44,4.59,3.65,6.88c0.42-0.19,0.84-0.37,1.26-0.56
				c-0.84-2.32-1.67-4.64-2.51-6.95c7.94,7.98,6.9,21.11,17.23,27.77c-0.41-1.1-0.74-2.23-1.23-3.28c-2.38-5.05-4.79-10.08-7.2-15.1
				c-2-4.17-3.92-8.38-6.07-12.47c-1.85-3.52-1.84-5.67,2.74-6.85c5.25-1.35,5.02-1.86,1.94-6.62c-0.7-1.09,0.8-3.61,1.29-5.47
				c0.59-0.01,1.18-0.01,1.77-0.02c3.09,4.83,6.17,9.66,9.25,14.49c0.09,0.14,0.09,0.34,0.2,0.46c7.47,7.87,5.12,18.74,8.58,27.81
				c1.05,2.74,2.79,5.49,4.92,7.45c2.26,2.07,3.98,3.71,3.22,7.1c-1.23,5.44-2.13,10.96-3.21,16.44
				C761.93,554.43,761.5,555.46,760.96,557.19z"/>
			<path onClick={() => props.onFill(98)} fill={props.fillColors[98]} d="M409.41,699.3c-2.49,2.57-4.62,5.69-7.55,7.6c-5.96,3.89-12.17,7.52-18.65,10.46
				c-2.96,1.35-6.84,1.39-10.2,1.02c-7.9-0.88-15.17,0.36-22.11,4.27c-2.73,1.54-5.78,2.52-8.66,3.81
				c-10.11,4.52-20.52,8.5-30.2,13.81c-6.6,3.62-12.16,9.11-18.19,13.76c-0.95,0.73-1.96,1.4-2.94,2.1c-0.43-0.38-0.87-0.76-1.3-1.14
				c2.7-3.78,5.43-7.54,8.11-11.34c2.93-4.17,5.58-8.57,8.8-12.49c2.74-3.34,5.82-6.61,9.32-9.08c3.62-2.56,8.12-3.83,11.91-6.18
				c11.33-7.02,23.48-7.2,36.12-5.22c7.38,1.16,14.61,0.12,21.29-3.59c0.86-0.48,1.83-0.92,2.79-1.02
				c6.87-0.72,13.36-2.26,18.36-7.55c0.43-0.46,1.47-0.33,2.23-0.48C408.83,698.45,409.12,698.87,409.41,699.3z"/>
			<path onClick={() => props.onFill(99)} fill={props.fillColors[99]} d="M419.9,273.16c-2.26,1.17-4.42,3.03-6.81,3.38c-5.84,0.86-11.81,0.85-17.68,1.55
				c-3.58,0.43-7.2,1.18-10.58,2.4c-7.88,2.84-15.87,2.22-24.67,1.9c4.57-3.47,8.45-6.41,12.33-9.35c-0.26-0.51-0.51-1.02-0.77-1.54
				c-1.22-0.22-2.43-0.61-3.65-0.62c-11.99-0.07-24-0.62-35.95,0.07c-8.73,0.5-17.37,2.63-26.04,4.1c-0.95,0.16-2.38,0.5-2.67,1.17
				c-2.39,5.36-7.14,3.29-11.13,4.02c-2.28,0.42-4.26,2.43-6.41,3.67c-1.75,1.01-3.57,2.8-5.32,2.75c-7.66-0.21-15.3-0.96-22.95-1.52
				c0-0.53,0-1.06,0-1.58c6.81-1.57,13.64-3.05,20.43-4.73c20.12-4.98,40.13-10.43,60.36-14.91c10.72-2.37,21.75-3.24,31.81,3.5
				c1.8,1.2,4.42,1.28,6.7,1.64c11.34,1.8,22.72,2.03,34.16,0.94c2.77-0.26,5.64,0.59,8.47,0.92
				C419.66,271.67,419.78,272.42,419.9,273.16z"/>
			<path onClick={() => props.onFill(100)} fill={props.fillColors[100]} d="M1087.6,610.37c-5.08-2.22-6.39-5.2-5.43-10.48c1.63-8.97,1.69-18.21,3.13-27.23
				c2.22-13.92-5.14-24.02-12.61-34.21c-1.15-1.57-2.36-3.09-4.28-5.59c7.47,0.92,7.26,8.42,12.44,10.87
				c3.28-4.8,1.9-10.02,1.26-14.83s-2.49-9.45-3.32-14.34c3.75,3.68,4.56,8.61,5.05,13.47c0.41,4.12-0.25,8.35,0.2,12.46
				c0.24,2.16,1.84,4.17,2.82,6.25c1.25-1.59,3.3-3.05,3.61-4.81c1.49-8.65,3.08-17.35,3.57-26.09c0.6-10.73,0.14-21.51,0.14-32.28
				c0.65-0.03,1.3-0.05,1.96-0.08c0.07,3.08-0.08,6.19,0.28,9.24c0.31,2.6,1.22,5.13,1.87,7.69c1.69-2.23,3.45-4.41,5.05-6.71
				c0.52-0.75,0.64-1.77,0.94-2.67c0.46,0.09,0.92,0.18,1.38,0.27c-0.81,3.25-0.85,7-2.56,9.66c-7.36,11.46-6.53,24.52-7.82,37.13
				c-0.53,5.21-0.14,10.51-0.09,15.77c0.01,0.57,0.51,1.13,1.3,2.75c2.86-3.22,5.13-6.12,7.77-8.64c2.88-2.75,5.78-5.78,9.27-7.48
				c2.8-1.36,2.72-7.9,8.19-4.89c-11.15,8.61-19.78,19.09-26.66,30.74c-3.53,5.98-4.19,13.38-3.05,20.47
				c0.49,0.35,0.99,0.7,1.48,1.05c6.51-6.2,13.01-12.4,20.53-19.56c-2.72,3.95-4.71,6.84-7.53,10.93c3.86,0.6,6.84,1.06,9.83,1.52
				C1098.42,582.3,1089.09,592.22,1087.6,610.37z"/>
			<path onClick={() => props.onFill(101)} fill={props.fillColors[101]} d="M1025.32,273.59c2.08-0.8,3.84-1.47,5.59-2.14c-2.79-6.01-2.09-7.17,4.79-8.59
				c-0.13-2.75-0.26-5.39-0.39-8.04c2.07,0.19,4.13,0.37,6.91,0.63c3.24-6.01,7.05,0.07,11.25,1.21c0-1.39,0-2.4,0-5.02
				c5.43,2.39,10.1,4.44,15.99,7.03c3.43-1.4,3.64-1.28,5.48,5.08c-1.55,0.51-3.21,0.85-4.63,1.61c-0.91,0.49-2.25,2.17-2.09,2.39
				c3.06,4.28-2.37,10.17,2.68,14.01c0.2,0.15-0.06,0.91-0.16,1.92c-4.08-0.69-8.06-1.41-12.06-2.01c-0.76-0.11-1.64,0.17-2.39,0.44
				c-0.77,0.28-1.69,1.3-2.15,1.1c-4.84-2.1-9.37-0.83-14.28,0.05c-2.56,0.46-5.22-0.31-7.54,1.81c-0.62,0.57-2.25,0.71-3,0.3
				c-0.87-0.48-2-2.38-1.79-2.64C1030.57,279.09,1026.46,277.16,1025.32,273.59z"/>
			<path onClick={() => props.onFill(102)} fill={props.fillColors[102]} d="M240.42,199.13c7.9-4.59,13.28-10.85,16.44-19.04c0.97-2.51,10.36-5.05,13.73-3.37
				c5.93,2.96,11.83,4.02,18.28,2.09c1.05-0.31,3.01,0.44,3.74,1.35c4.1,5.11,7.96,10.4,11.84,15.68c0.43,0.59,0.43,1.5,0.64,2.32
				c-11.27,0.28-22.22,0.8-32.84,4.37c-5.44,1.83-11.11,3-16.71,4.36C248.64,208.56,244.69,204.14,240.42,199.13z M290.71,183.49
				c-9.68,0.76-18.92,1.48-28.55,2.23C268.27,188.92,284.88,187.58,290.71,183.49z"/>
			<path onClick={() => props.onFill(103)} fill={props.fillColors[103]} d="M490.74,509.77c4.98-7.61,9.95-15.23,14.94-22.84c0.46-0.7,0.97-1.35,1.46-2.02
				c1.67-2.24,3.19-6.23,5.03-6.38c4.87-0.4,9.88,0.87,15.79,1.58c-1.52,2.35-2.38,3.23-2.67,4.27c-0.41,1.46-1.07,3.59-0.39,4.45
				c0.91,1.14,3.26,2.14,4.55,1.75c3.24-0.99,4.83,0.08,7.2,2.31c1.91,1.8,5.63,1.68,9.85,2.74c-2.84,1.38-4.75,1.97-6.23,3.12
				c-2.64,2.06-5.22,4.28-7.47,6.76c-5.21,5.75-11.79,9.07-19.29,9.02c-7.46-0.05-14.92-1.65-22.38-2.58
				C491,511.23,490.87,510.5,490.74,509.77z"/>
			<path onClick={() => props.onFill(104)} fill={props.fillColors[104]} d="M201.28,582.9c4.73-2.58,7.79-4.61,11.11-5.98c8.99-3.71,17.2-8.59,24.42-15.1
				c2.61-2.35,4.4-1.63,6.59,0.9c3.1,3.58,6.49,7.02,10.22,9.92c2.9,2.25,3.46,4.06,1.16,6.9c-6.93,8.57-14.11,16.77-25.79,18.79
				c-0.6,0.1-1.38,0.37-1.81,0.11C218.86,593.52,210.58,588.51,201.28,582.9z"/>
			<path onClick={() => props.onFill(105)} fill={props.fillColors[105]} d="M879.16,387.88c2.22,0.17,5.01,0.45,7.8,0.59c1.44,0.07,3.17,0.4,4.28-0.23
				c5.52-3.17,10.8-1.16,17.31,0.22c-4,8.34-7.64,16.24-11.57,23.99c-1.42,2.79-3.58,5.19-5.29,7.85c-2.24,3.49-3.94,6.49-1.23,11.22
				c1.6,2.8,0.15,7.68-0.67,11.46c-0.83,3.81-3.11,7.21-0.34,11.35c0.86,1.29-0.38,3.99-0.74,6.57c-1.64-0.86-3.21-1.15-3.5-1.93
				c-2.2-5.91-6.53-10.96-6.06-17.99c0.44-6.67-0.23-13.42-0.58-20.13c-0.18-3.43-0.71-6.84-1.1-10.38c2.11-0.54,3.61-0.92,5.92-1.52
				c-2.48-1.32-4.19-2.22-5.9-3.13c0.95-1.54,1.9-3.08,2.39-3.88c-1-2.13-2.66-4.19-2.77-6.33
				C876.98,393.25,878.3,390.84,879.16,387.88z"/>
			<path onClick={() => props.onFill(106)} fill={props.fillColors[106]} d="M414.03,612.98c-8.88-10.56-17.39-13.49-31.01-10.75c-3.28,0.66-6.58,1.19-9.88,1.78
				c-0.15-0.6-0.31-1.21-0.46-1.81c8.18-2.38,16.44-4.53,24.52-7.23c4.14-1.38,8.36-3.2,11.84-5.77c6.64-4.91,10.23-3.24,13.02,2.56
				c2.85,5.91,9.68,5.63,15.2,6.3c3.84,0.47,8.05-0.26,11.83-1.37c7.4-2.18,13.07,1.62,19.23,3.93c0.14,0.61,0.27,1.23,0.41,1.84
				c-2.99,1.21-5.89,2.9-8.99,3.48c-2.87,0.53-5.96-0.23-8.95-0.25c-6.74-0.06-13.47-0.02-20.04-0.02c2.77,5.1,5.15,9.47,8.18,15.05
				c-8.4-0.35-15.29-1.21-21.35-5.67c-1.14-0.84-2.45-1.47-3.68-2.19L414.03,612.98z M425.4,609.44c0.04-0.52,0.07-1.04,0.11-1.57
				c-5.55-0.93-11.1-1.87-16.65-2.8c-0.09,0.57-0.18,1.14-0.27,1.71C414.19,607.67,419.8,608.55,425.4,609.44z"/>
			<path onClick={() => props.onFill(107)} fill={props.fillColors[107]} d="M735.82,488.82c-2.2,1.1-4.33,2.17-6.81,3.41c-2.55-5.55-5.14-10.54-7.15-15.76
				c-1.75-4.53,0.04-7.46,4.47-9.5c1.1-0.51,1.68-2.15,2.48-3.28c0.81-1.15,1.36-3.02,2.42-3.38c6.74-2.24,13.67-3.91,20.38-6.24
				c7.94-2.75,15.79-5.8,23.47-9.17c1.33-0.58,1.66-3.42,3-6.44c7.45,0.75,9.29,8.26,12.97,14.01c-6.09,4.95-13.67,4.59-20.66,6.25
				c-2.38,0.56-4.85,1.26-6.92,2.51c-8.67,5.25-17.2,10.71-25.77,16.13C732.12,480.9,731.71,483.08,735.82,488.82z"/>
			<path onClick={() => props.onFill(108)} fill={props.fillColors[108]} d="M896.48,632.59c0.56,5.45,0.87,10.16,1.56,14.81c0.55,3.7,1.44,7.36,2.42,10.97
				c1.28,4.72,2.8,9.37,4.42,14.69c-7.19-2.78-13.27-5.13-19.35-7.46c-6.34-2.43-12.57-5.22-19.06-7.13
				c-6.19-1.82-12.7-2.53-18.98-4.1c-3.69-0.92-7.21-2.56-12.04-4.32c8.07-1.6,14.48-2.89,20.89-4.13c0.97-0.19,2.09-0.56,2.94-0.26
				c7.67,2.63,13.9-0.91,20.24-4.39C884.61,638.48,889.84,635.97,896.48,632.59z"/>
			<path onClick={() => props.onFill(109)} fill={props.fillColors[109]} d="M636,397.18c8.34-13.51,16.39-26.56,24.48-39.67c7.42,2.63,14.95,5.3,23.22,8.22
				c-4.67,9.15-9.04,17.96-16.88,24.52c-2.28,1.91-4.81,3.73-6.49,6.12c-2.34,3.31-5.18,4.52-8.95,3.83
				C646.23,399.26,641.1,398.19,636,397.18z"/>
			<path onClick={() => props.onFill(110)} fill={props.fillColors[110]} d="M275.36,423.03c2.71,0.27,5.43,0.79,8.14,0.74c3.14-0.06,4.48,1.78,4.11,4.24
				c-0.17,1.16-2.65,2.95-3.92,2.82c-11.32-1.17-22.44,0.14-33.57,1.99c-2.67,0.44-4.69,0.17-4.77-3.76
				c-0.03-1.75-2.57-4.29-4.5-4.98c-3.18-1.14-6.8-1.08-10.23-1.52c1.27-8.13,1.97-8.75,9.99-8.66c2.83,0.03,5.72,0.53,8.46,0.07
				c2.39-0.4,4.64-1.78,6.89-2.86c2.19-1.05,4.22-2.95,6.48-3.25c6.14-0.83,12.37-1.3,18.56-1.24c1.54,0.01,3.05,2.57,4.57,3.98
				c0.09,0.09,0.07,0.32,0.06,0.49c-0.17,3.44,0.82,7.77-0.9,10.04c-1.32,1.74-6.07,0.87-9.29,1.16
				C275.42,422.53,275.39,422.78,275.36,423.03z"/>
			<path onClick={() => props.onFill(111)} fill={props.fillColors[111]} d="M362.43,420.6c15.89,13.16,25.63,29.32,35.5,45.39c-1.91,2.26-3.43,3.95-4.82,5.75
				c-5.15,6.67-9.16,6.65-14.7-0.1c-0.31-0.37-0.6-0.99-0.97-1.05c-12.97-2.19-14.8-13.23-16.94-22.93
				c-1.17-5.3,1.29-11.36,1.87-17.1C362.67,427.71,362.43,424.81,362.43,420.6z M368.25,450.21c1.05,5.03,1.75,9.02,2.79,12.92
				c0.2,0.76,1.84,1.73,2.62,1.58c0.8-0.15,1.69-1.47,1.95-2.43C376.6,458.64,373.59,452.97,368.25,450.21z"/>
			<path onClick={() => props.onFill(112)} fill={props.fillColors[112]} d="M6.01,419.1c0-6.13-0.2-11.77,0.16-17.39c0.08-1.21,1.95-2.84,3.34-3.33c3.34-1.18,6.87-1.81,10.13-3.64
				c-4.43,0.65-8.87,1.31-13.3,1.96c-0.07-0.41-0.14-0.81-0.21-1.22c21.7-5.4,43.41-10.79,65.11-16.19c0.13,0.48,0.25,0.97,0.38,1.45
				c-12.8,4.79-24.26,13.9-39.41,11.17c-2.79-0.5-5.98,1.15-8.25,1.65c17.22,3.87,32.72-3.59,48.23-10.95
				c0.16,0.49,0.32,0.97,0.48,1.46c-5.3,2.89-10.8,5.45-15.84,8.73c-7.67,4.98-15.07,10.39-22.49,15.75
				C25.99,414.57,16.23,416.49,6.01,419.1z"/>
			<path onClick={() => props.onFill(113)} fill={props.fillColors[113]} d="M303.14,233.95c-3.87,0.28-7.73,0.7-11.61,0.81c-3.99,0.11-7.99-0.11-11.99-0.14
				c-3.3-0.03-6.19,0.53-5.59,5c-0.99,0.18-1.73,0.56-2.23,0.36c-7.31-2.95-15.01-1.91-22.56-2.5c-4.19-0.33-6.94-0.46-6.4,5.15
				c-6.48-0.63-13.25,0.29-18.44-2.16c-8.08-3.81-15.21-1.14-22.75,0.03c-8.74,1.35-17.44,2.33-26.04-3.03
				c3.18-0.88,6.32-1.96,9.55-2.61c7.96-1.58,15.93-3.19,23.96-4.34c4.39-0.63,8.92-0.27,13.38-0.45c8.62-0.35,17.23-1.05,25.85-1.07
				c9.6-0.02,19.2,0.46,28.79,0.9c8.71,0.4,17.41,1.06,26.11,1.6C303.15,232.32,303.14,233.13,303.14,233.95z"/>
			<path onClick={() => props.onFill(114)} fill={props.fillColors[114]} d="M968.49,418.74c1.77,0.18,3.53,0.37,5.58,0.58c-0.22,0.88-0.3,1.96-0.54,2c-4.4,0.7-5.84,5.06-6.61,7.75
				c-1.53,5.33-6.14,5.94-9.41,8.55c-2.41,1.93-5.04,1.54-6.26-1.19c-1.45-3.25-3.16-2.94-5.92-1.96c-1.81,0.65-4.01,0.2-6.04,0.25
				c0-1.83,0-3.67,0-5.09c-2.05-0.79-4.2-1.63-6.35-2.46c1.84-1.99,3.68-3.98,5.99-6.48c-0.38-1.32-6.38-1.22-2.94-5.02
				c1.6-1.77,4.54-2.33,6.44-3.23c-0.79-2.58-1.45-4.77-2.12-6.95c2.78-0.58,5.55-1.45,8.36-1.65c3.61-0.25,7.25,0.04,10.88,0.12
				c0.66,0.01,1.83-0.03,1.89,0.2c1.72,6.4,9.49,2.59,12.69,8.75c-1.83,1.17-4.27,2.74-6.72,4.31
				C967.78,417.74,968.13,418.24,968.49,418.74z"/>
			<path onClick={() => props.onFill(115)} fill={props.fillColors[115]} d="M109.31,211.79c-2.93,7.52-8.18,11.51-13.83,14.97c-5.66,3.46-11.55,6.54-17.36,9.77
				c-5.95,3.31-12.04,4.13-19.06,3.92c-11.03-0.33-22.13,1.55-33.2,2.59c-6.42,0.6-12.81,1.4-19.57,2.15c0-3.62,0-6.7,0-10.15
				c1.59-0.14,3.03-0.27,4.47-0.39c8.92-0.79,17.83-1.64,26.76-2.34c2.98-0.23,6.28-0.85,8.92,0.12c5.8,2.13,10.6,0.37,15.77-1.92
				c13.16-5.84,26.47-11.36,39.77-16.89C104.03,212.78,106.33,212.52,109.31,211.79z"/>
			<path onClick={() => props.onFill(116)} fill={props.fillColors[116]} d="M309.44,505.5c0.61,2.41,1.42,4.79,1.8,7.24c1.04,6.67,1.81,13.38,2.89,20.04
				c0.39,2.41,1.36,4.73,2.88,7.13c5.63-9.41,7.21-21.18,19.39-26.33c-0.17,8.85,3.05,17.32-2.4,25.32
				c-1.86,2.73-3.04,5.92-4.75,8.77c-4.54,7.57-13.14,10.94-19.9,7.26c-2.24-1.22-4.91-4.43-4.86-6.66
				c0.33-13.09,1.37-26.16,2.28-39.23c0.08-1.19,0.94-2.32,1.43-3.47C308.61,505.55,309.02,505.53,309.44,505.5z"/>
			<path onClick={() => props.onFill(117)} fill={props.fillColors[117]} d="M679.86,577.43c-8.19-4.29-14.71-7.51-21-11.16c-1.17-0.68-1.96-3.06-1.92-4.63
				c0.18-7.44,0.6-14.87,1.14-22.29c0.42-5.76,1.83-6.92,7.71-7.25c1.49-0.08,2.99-0.19,4.45-0.45c1.7-0.31,3.36-0.8,5.04-1.21
				c-0.06-0.43-0.13-0.86-0.19-1.28c-4.66,0.63-9.31,1.27-13.68,1.86c-0.57-0.66-0.93-0.88-0.98-1.15c-1.37-8.52-1.36-8.52,7.63-9.13
				c9.55-0.65,9.73-0.67,10.74,8.84c0.3,2.85-0.37,5.94-1.18,8.75c-1.79,6.21-2,11.88,0.3,18.39
				C680.04,562.69,679.27,569.68,679.86,577.43z M661.83,538.27c0.11,0.74,0.22,1.48,0.33,2.22c3.76-0.96,7.52-1.93,11.28-2.89
				c-0.12-0.53-0.23-1.05-0.35-1.58C669.35,536.76,665.59,537.51,661.83,538.27z"/>
			<path onClick={() => props.onFill(118)} fill={props.fillColors[118]} d="M1055.19,151.1c1.89,2.23,2.8,3.3,4.36,5.13c-9.78,0.92-18.95,1.79-28.13,2.63
				c-0.49,0.04-1.04,0.01-1.47-0.18c-7.07-3.15-13.88-3.08-20.87,0.66c-2.53,1.35-6.01,2.16-7.58-2.45c-0.51-1.5-2.92-2.36-4.58-3.6
				c9.9-5.53,20.86-7.68,32.08-7.98c17.1-0.46,34.22-0.27,51.34-0.43c2.98-0.03,5.96-0.62,8.93-0.58c2.15,0.03,6.04-1.48,5.5,2.41
				c-0.28,2-3.79,3.68-6.03,5.21c-0.57,0.39-1.91-0.21-2.86-0.5C1076.12,148.41,1066.39,151.05,1055.19,151.1z"/>
			<path onClick={() => props.onFill(119)} fill={props.fillColors[119]} d="M6.3,492.9c-1.18-18.27-0.98-17.89,13.72-25.46c5.4-2.78,9.63-7.85,14.4-11.85
				c4.27-3.58,8.44-7.3,12.88-10.66c3.16-2.39,6.61-4.45,10.1-6.33c1.37-0.74,3.65-1.3,4.72-0.65c1.13,0.69,1.78,2.92,1.8,4.49
				c0.02,2.11-1.04,4.22-1.14,6.35c-0.4,8.46-3.94,13.89-13.09,14.23c-0.33,0.01-0.67,0.1-0.97,0.23
				c-10.03,4.69-20.22,9.06-29.97,14.27c-3.26,1.74-5.47,5.55-7.98,8.57C9.32,487.83,8.25,489.89,6.3,492.9z"/>
			<path onClick={() => props.onFill(120)} fill={props.fillColors[120]} d="M202.74,629.31c-5.35,3.51-9.81,7.25-14.94,9.49c-2.67,1.17-7.14,0.76-9.7-0.78
				c-10.8-6.48-21.13-13.73-31.75-20.51c-4.32-2.76-9.06-4.87-13.38-7.62c-1.3-0.83-1.83-2.85-2.71-4.32
				c1.59-0.18,3.36-0.9,4.74-0.45c5.5,1.81,10.74,5.14,16.32,5.79c13.4,1.56,24.84,7.86,36.77,13.15
				C192.83,626.16,197.88,627.59,202.74,629.31z"/>
			<path onClick={() => props.onFill(121)} fill={props.fillColors[121]} d="M1154.7,437.05c5.06-0.87,9.48-1.63,13.9-2.39c0.18,0.42,0.36,0.83,0.53,1.25
				c-1.27,1.02-2.53,2.04-5.05,4.07c4.19-0.29,7.13-0.49,10.08-0.7c0.11,0.28,0.21,0.56,0.32,0.85c-1.35,0.98-2.7,1.95-5.25,3.8
				c4.13,0.68,7.18,1.19,11.3,1.86c-2.19,1.76-3.85,3.08-5.82,4.67c0.84,0.63,1.77,1.33,2.95,2.23c-1.17,0.79-2.27,1.54-4.12,2.78
				c2.44,1.22,4.26,2.13,6.56,3.28c-1.7,0.68-3.07,1.22-5.67,2.25c2.41,0.73,3.68,1.12,4.95,1.5c-0.06,0.19-0.07,0.59-0.26,0.71
				c-4.3,2.61-7.27,8.37-14.18,5.64c2.47-5.82-1.29-6.89-5.88-7.09c-2.09-4.5-3.67-9.18-6.36-13.1c-1.17-1.7-4.64-1.83-7.5-2.83
				c0-12.78-9.74-6.21-15.89-7c0.35-6.28,1.17-6.85,5.71-4.28c6.39-6.33,14-1.91,21.86-3.69
				C1155.94,433.53,1155.48,434.84,1154.7,437.05z"/>
			<path onClick={() => props.onFill(122)} fill={props.fillColors[122]} d="M810.22,227.84c-0.5,5.91-1.88,11.26-1.18,16.33c1.48,10.65-6.94,13.98-12.92,19.07
				c-2.28,1.94-5.04,1.34-6.53-1.85c-1.62-3.46-3.28-6.9-4.98-10.32c-0.81-1.63-1.33-3.84-2.67-4.69c-5.55-3.5-3.97-6.76-0.18-10.24
				c0.36-0.33,0.76-0.77,0.85-1.22c1.59-7.89,6.57-8.67,13.49-7.24C800.37,228.55,804.96,227.84,810.22,227.84z"/>
			<path onClick={() => props.onFill(123)} fill={props.fillColors[123]} d="M113.05,492.88c1.3-1.97,2.33-3.06,2.8-4.35c1.21-3.31,2.11-6.73,3.25-10.07c0.7-2.04,1.13-5.26,2.52-5.8
				c6.43-2.51,8.87-8.15,12.03-13.39c3.49-5.78,7.29-11.37,10.85-17.12c1.12-1.81,2.01-3.8,2.78-5.8c2.19-5.6,3.41-6.41,9.79-6.01
				c2.24,14.35-4.34,25.55-14.15,34.53c-4.72,4.32-7.19,9.09-9.52,14.49c-1.76,4.07-3.76,8.03-5.81,11.96
				c-0.38,0.72-1.5,1.45-2.31,1.48C121.54,492.96,117.77,492.88,113.05,492.88z"/>
			<path onClick={() => props.onFill(124)} fill={props.fillColors[124]} d="M494.32,240.49c5.12-2.77,17.65-3.6,29.49-2.01c1.04,4.41-0.38,7.24-5,8.41
				c-1.75,0.44-3.35,1.43-5.06,2.02c-5.89,2.04-11.81,4.02-17.72,6c-2.18,0.73-4.89,0.82-6.49,2.21c-6.98,6.06-15.18,4.78-23.25,4.42
				c-1.93-0.09-3.85-0.7-6.45-1.2c1.72-1.58,3.07-2.81,5.58-5.12c-5.65,0-10.05,0-15.33,0c7.55-7.37,42.36-13.7,53.66-9.61
				c-5.27,1.04-10.54,2.08-15.81,3.12c0.21,0.83,0.41,1.66,0.62,2.49c6.13-3.39,13.67-1.3,19.6-5.44c0.03-0.67,0.06-1.35,0.09-2.02
				C503.65,242.67,499.05,241.59,494.32,240.49z"/>
			<path onClick={() => props.onFill(125)} fill={props.fillColors[125]} d="M1061.32,430.22c1.18-1.52,1.89-3.39,2.93-3.6c4.96-1,10.01-1.56,15.02-2.27
				c1.92,7.65,10.3,0.7,13.85,6.32c-1.79,0.77-3.6,1.55-5.83,2.5c0.9,0.97,1.77,1.9,2.63,2.83c-0.11,0.33-0.22,0.67-0.33,1
				c-2.17-0.52-4.35-1.05-6.89-1.66c0.21,1.37,0.41,2.69,0.76,5.06c-3.75-0.31-7.16-0.6-11.37-0.95c2.14,1.79,3.75,3.13,5.36,4.47
				c-0.16,0.35-0.32,0.7-0.48,1.06c-2.05-0.54-4.1-1.08-7.31-1.92c1.4,2.14,2.22,3.39,3.05,4.67c-2.98,4.38-5.28,4.42-8.89,0.9
				c-3.03,1.45-5.81,3.87-8.25,3.56c-1.5-0.19-2.48-4.53-4.31-8.25c-0.76-0.08-3-0.37-5.24-0.56c-3.39-0.29-7.92-0.63-7.19-4.79
				c0.49-2.78,4.21-6.23,7.15-7.07C1050.87,430.1,1056.31,430.54,1061.32,430.22z"/>
			<path onClick={() => props.onFill(126)} fill={props.fillColors[126]} d="M684.21,257.51c-7.06,3.94-9.68,10.07-11.03,17.32c-0.9,4.85-11.64,10.53-17.39,9.8
				c-1.8-0.23-3.64-0.69-5.4-0.51c-5.84,0.62-6.45,0.51-6.13-5.49c0.37-6.86,1.37-13.72,2.58-20.49c0.26-1.45,2.43-3.29,4.01-3.62
				c4.03-0.85,8.22-0.99,12.35-1.34c0.6-0.05,1.67,0.13,1.8,0.48c2.32,6.13,7.22,3.54,11.4,3.52
				C679.01,257.16,681.61,257.39,684.21,257.51z M650.09,258.39c-3.15,2.1-5.23,19.35-2.1,25.74
				C648.75,274.8,649.43,266.46,650.09,258.39z"/>
			<path onClick={() => props.onFill(127)} fill={props.fillColors[127]} d="M297.23,617.13c-1.73,5.5-3.21,10.5-5,15.38c-0.21,0.57-2.41,1.02-3.29,0.63
				c-14.65-6.53-29.23-13.2-43.82-19.84c0.17-0.8,0.35-1.6,0.52-2.4c1.8,0,3.69,0.37,5.37-0.07c5.56-1.43,11.22-2.71,16.5-4.89
				c4.08-1.69,7.57-3.88,12-0.9c1.53,1.03,3.92,0.68,5.75,1.38c1.21,0.46,3.16,1.89,3.02,2.49
				C286.72,615.94,291.46,616.58,297.23,617.13z"/>
			<path onClick={() => props.onFill(128)} fill={props.fillColors[128]} d="M1194.87,646.82c1.06,4.65-0.26,8.44-3.46,12.2c-7.24,8.5-11.3,18.27-10.55,29.79
				c0.26,3.91-0.51,7.89-0.84,12.2c-2.7,0-4.94,0-7.12,0c-1.22-19.89-10.69-37.19-15.97-55.69c-0.9-3.15-1.76-6.32-1.68-9.89
				c0.99,2.53,2.06,5.04,2.95,7.61c2.05,5.96,3.93,11.98,6.07,17.92c0.6,1.66,1.59,3.36,2.85,4.58c2.4,2.32,5.35,1.51,6.03-1.73
				c0.6-2.89,0.59-5.93,0.65-8.91c0.13-6.31,0.21-12.63,0.14-18.94c-0.05-4.3-0.5-8.6-0.51-12.9c0-1.69,0.71-3.39,1.1-5.08
				c0.59,0.04,1.18,0.07,1.77,0.11c0.41,2.35,0.82,4.69,1.24,7.04c0.72,4.02,2.46,5.03,5.69,2.58c2.45-1.86,4.19-4.63,7.02-6.95
				c-1.31,2.48-2.04,5.63-4.02,7.34c-12.01,10.4-9.87,24.15-9.26,37.52c0.06,1.27,1.65,3.31,2.73,3.45c1.16,0.15,3.02-1.24,3.76-2.46
				C1187.4,660.1,1191.08,653.45,1194.87,646.82z"/>
			<path onClick={() => props.onFill(129)} fill={props.fillColors[129]} d="M588.88,260.39c-1.18,2.14-1.82,4.11-3.1,5.48c-7.33,7.79-14.74,15.74-26.93,14.08
				c-6.71-0.91-12.89-0.26-18.59,3.67c-1.12,0.78-2.71,0.88-4.11,1.31c-0.21-0.75-0.43-1.24-0.35-1.3
				c9.22-6.82,14.83-16.43,20.54-26.03c0.73-1.22,3.22-2.26,4.7-2.04C570.04,256.9,578.98,258.63,588.88,260.39z"/>
			<path onClick={() => props.onFill(130)} fill={props.fillColors[130]} d="M973.7,219.35c-2.35,0.46-4.68,1.02-7.04,1.36c-6.99,1-14,1.95-21.01,2.8c-1.24,0.15-2.56-0.4-3.85-0.48
				c-11.76-0.78-23.51-1.63-35.28-2.19c-1.9-0.09-3.87,1.18-5.81,1.82c-0.19,0.54-0.39,1.09-0.58,1.63c-1.47-1.68-2.84-3.46-4.45-5
				c-0.96-0.93-2.2-1.73-3.46-2.13c-1.45-0.47-3.05-0.44-5.36-0.72c3.68-4.4,7.88-5.31,12.15-5.28c10.95,0.09,21.89,0.57,32.83,0.79
				c4.81,0.1,9.63-0.15,14.44-0.04c2.75,0.06,6.04-1.21,7.64,2.69c0.22,0.53,2.12,0.37,3.25,0.53c5.5,0.81,11,1.62,16.5,2.42
				C973.67,218.15,973.69,218.75,973.7,219.35z"/>
			<path onClick={() => props.onFill(131)} fill={props.fillColors[131]} d="M319.2,279.57c-4.03,0-8.05,0-12.08,0c13.65-5.9,27.88-7.06,42.58-5.22c-5.14,1.32-10.28,2.64-15.42,3.95
				c0.08,0.42,0.16,0.84,0.24,1.25c9.42-1.71,18.84-3.42,28.25-5.13c0.13,0.43,0.27,0.87,0.4,1.3c-7.49,2.75-12.08,11.67-22.62,8.37
				c-4.85-1.52-10.61-0.64-15.89-0.15c-3.44,0.32-7.53,0.64-8.78,5.18c-0.04,0.14-0.2,0.31-0.34,0.35
				c-8.26,2.28-13.99,10.45-23.28,10.24c-12.76-0.3-25.52-0.78-38.29-1.21c-0.54-0.02-1.07-0.37-2.3-0.83
				c11.2-0.51,21.58-0.83,31.94-1.52c4.26-0.28,8.6-0.91,12.64-2.22c2.22-0.72,4.91-2.84,5.57-4.91c1.74-5.44,5.6-7.08,10.5-7.68
				c2.28-0.28,4.58-0.46,6.87-0.69C319.21,280.29,319.2,279.93,319.2,279.57z"/>
			<path onClick={() => props.onFill(132)} fill={props.fillColors[132]} d="M227.94,556.84c-4.01,4.42-7.71,9.17-12.13,13.14c-3.79,3.41-8.21,6.25-12.72,8.65
				c-1.7,0.9-5.18,0.74-6.56-0.44c-7.54-6.44-14.68-13.35-21.94-20.08c17.31-0.92,34.63-1.83,51.95-2.75
				C227.01,555.86,227.48,556.35,227.94,556.84z"/>
			<path onClick={() => props.onFill(133)} fill={props.fillColors[133]} d="M1194.73,171.44c-3.93-0.14-8.15,2.13-10.99-2.26c-2.83-4.37-5.06-8.71-2-14.24
				c2.07-3.75-1.57-5.79-4.28-6.18c-3.61-0.53-7.44,0.3-11.17,0.62c-4.78,0.41-9.54,1.04-14.32,1.31c-6.17,0.34-12.48-0.32-18.49,0.8
				c-8.17,1.51-16.09,1.96-24.14-0.16c-2.88-0.75-5.92-0.93-8.76-1.79c-1.26-0.38-2.21-1.8-3.31-2.74c1.29-0.61,2.6-1.77,3.88-1.74
				c17.93,0.35,35.86,0.99,53.8,1.29c13.11,0.22,26.23,0.04,39.77,0.04C1194.73,155.06,1194.73,163.08,1194.73,171.44z"/>
			<path onClick={() => props.onFill(134)} fill={props.fillColors[134]} d="M300.79,550.46c-4.26-3.45-8.12-5.92-11.04-9.21c-1.67-1.88-2.41-5.08-2.51-7.73
				c-0.27-6.79-0.66-13.71,0.38-20.37c0.65-4.14,3.72-7.94,5.97-11.73c0.37-0.63,2.17-0.74,3.23-0.57c3.91,0.64,8.31,6.21,8,10.19
				c-0.91,11.74-1.93,23.47-2.95,35.2C301.75,547.33,301.32,548.39,300.79,550.46z"/>
			<path onClick={() => props.onFill(135)} fill={props.fillColors[135]} d="M1067.09,153.01c5.7,6.18,0.8,14.41,4.77,21.54c-6.71-0.36-12.3-0.49-17.86-1.01
				c-8.05-0.75-15.98-2.25-23.96,1.13c-4.18,1.77-6.29-0.24-6.81-5.03c-0.18-1.65-0.61-3.27-0.74-4.92
				c-0.32-4.11,1.65-5.51,5.51-4.41c2.19,0.63,4.59,0.96,6.85,0.81c8.43-0.58,16.86-1.29,25.27-2.19
				C1065.65,158.33,1066.72,157.14,1067.09,153.01z"/>
			<path onClick={() => props.onFill(136)} fill={props.fillColors[136]} d="M485.69,290.41c1.98,4.37,4.49,9.03,6.21,13.96c1.41,4.01-0.84,6.6-5.28,6.96
				c-8.2,0.66-16.39,1.43-24.6,1.8c-4.09,0.18-11.81-8.68-11.04-12.66c0.25-1.3,2.31-2.81,3.81-3.18c4.15-1.04,8.49-1.34,12.67-2.27
				C473.38,293.68,479.24,292.05,485.69,290.41z"/>
			<path onClick={() => props.onFill(137)} fill={props.fillColors[137]} d="M400.05,463.4c-11.3-14.2-19.85-30.04-33.57-41.97c-3.63-3.16-4.86-7.84-2.33-12.28
				c2.93-5.14,6.54-9.89,10.77-16.16c2.53,8.04,5.25,14.89,6.77,22c1.68,7.86,2.72,15.75,9.14,21.51c0.36,0.32,0.83,0.8,0.83,1.21
				c-0.02,8.2,4.36,14.75,7.9,21.65C400.17,460.55,399.92,462.18,400.05,463.4z"/>
			<path onClick={() => props.onFill(138)} fill={props.fillColors[138]} d="M327.15,356.11c-14.99,0.12-29.89-0.91-44.59-4.59c1.7-7.16,1.7-7.16-5.09-8.89
				c1.24-2.22,2.53-4.54,4.12-7.4c1.99-0.27,4.94-0.87,7.91-1.01c7.72-0.36,14.34,2.24,20.35,7.21c5.59,4.62,11.74,8.58,17.65,12.83
				C327.38,354.88,327.26,355.49,327.15,356.11z"/>
			<path onClick={() => props.onFill(139)} fill={props.fillColors[139]} d="M317.82,493.11c-0.61-1.56-2.16-3.55-1.67-4.6c4.03-8.68-2.01-15.27-4.3-22.61
				c-1.97-6.3-4.15-12.53-6-18.86c-0.96-3.32,0.53-5.06,4.14-4.83c5.11,0.33,10.28,0.2,15.33,0.95c5.35,0.79,6.95,6.16,3.05,9.98
				c-3.52,3.45-5.46,6.7-1.71,11.16c0.47,0.56,0.55,2.24,0.11,2.65c-5.75,5.33-2.49,12.59-4.21,18.75c-0.72,2.6-1.83,5.09-2.76,7.63
				C319.14,493.25,318.48,493.18,317.82,493.11z"/>
			<path onClick={() => props.onFill(140)} fill={props.fillColors[140]} d="M631.3,525.63c1.73-7,1.73-7,18.89-2.17c-1.52,12.06,1.42,24.45-1.32,36.45c-6.43-1.59-12.12-3-17.89-4.43
				c0-3.65-0.01-7.42,0.01-11.19c0.01-2.16,0.21-4.33,0.1-6.48c-0.51-10.01-0.54-9.99,9.86-9.21c1.82,0.14,3.65,0.15,5.47,0.15
				c0.44,0,0.88-0.33,1.39-1.37C642.41,526.81,637.02,526.23,631.3,525.63z"/>
			<path onClick={() => props.onFill(141)} fill={props.fillColors[141]} d="M669.23,506.79c-2.06,0.33-4.11,0.66-6.59,1.06c0.17-2.22,0.31-3.95,0.39-4.99
				c-1.48,1.45-3.04,4.15-5.17,4.71c-2.13,0.56-4.82-1.02-7.76-0.78c2.77,1.4,5.54,2.8,8.3,4.2c-0.08,0.36-0.15,0.72-0.23,1.07
				c-2.11-0.23-4.22-0.46-7.37-0.81c1.33,1.23,1.94,1.78,2.53,2.34c1.32,1.26,2.62,2.53,3.93,3.8c-1.68,0.81-3.35,1.62-5.41,2.61
				c-2.57-9.72-6.25-18.24-3.47-27.61c2.31-7.8,12-13.17,20.8-11.1c-0.9,2.26-1.95,4.41-2.62,6.67c-0.7,2.35-1.03,4.81-0.59,7.56
				c1.8-4.49,3.59-8.97,5.85-14.6c3.75,4.94,1.15,10.08,2.69,14.24c2.19,5.89-1.64,8.75-5.42,11.75L669.23,506.79z"/>
			<path onClick={() => props.onFill(142)} fill={props.fillColors[142]} d="M745.65,688.66c-2.78,4.2-5.17,8.73-8.42,12.52c-7.51,8.77-15.55,17.08-23.16,25.76
				c-5.48,6.25-10.59,12.83-15.94,19.2c-1.21,1.44-2.74,2.62-4.74,3.34c2.29-3.31,5.5-6.31,6.71-9.98
				c5.25-15.85,16.65-28.26,23.48-43.17c2.07-4.52,4.6-7.1,10.23-6.54c3.36,0.33,6.94-1.48,10.42-2.33
				C744.71,687.85,745.18,688.26,745.65,688.66z"/>
			<path onClick={() => props.onFill(143)} fill={props.fillColors[143]} d="M814.59,642.13c4.94,2.21,9.84,3.73,14,6.41c7.28,4.69,14,10.12,23.43,9.74c2-0.08,4.07,1.66,6.11,2.56
				c0.01,0.47,0.01,0.93,0.02,1.4c-2.9,1.18-5.79,2.35-8.95,3.63c-0.6-0.67-1.05-1.38-1.69-1.82c-1.12-0.77-2.36-1.38-3.55-2.06
				c-0.65,1.23-1.94,2.53-1.82,3.67c0.43,3.92,1.27,7.81,2.13,11.68c0.67,3.01,1.62,5.96,2.44,8.93c-0.48,0.35-0.96,0.71-1.45,1.06
				c-2.01-2.26-4.27-4.36-5.96-6.84c-1.47-2.16-1.88-5.11-3.51-7.09c-1.77-2.13-4.21-4.47-6.74-5.02c-3.39-0.74-4.7-2.46-5.71-5.32
				c-0.75-2.13-1.4-4.86-3.03-6.02C815.43,653.54,814.2,648.88,814.59,642.13z"/>
			<path onClick={() => props.onFill(144)} fill={props.fillColors[144]} d="M270.79,396.24c0-1.91,0-3.5,0-5.09c-0.68,6.12-4.72,5.58-9.21,5.42c-3.49-0.12-7,1.3-10.54,1.71
				c-3.67,0.43-7.39,0.59-11.09,0.57c-0.6,0-1.54-1.51-1.68-2.43c-0.17-1.08,0.43-2.26,0.58-3.4c1.55-11.58,1.54-11.56,13.46-10.18
				c3.46,0.4,6.98,0.4,10.42,0.91c6.17,0.92,12.32,1.97,18.42,3.27c1.11,0.24,2.42,1.79,2.74,2.97c0.67,2.52,0.75,5.2,1.17,8.6
				C280.11,397.77,275.73,397.05,270.79,396.24z"/>
			<path onClick={() => props.onFill(145)} fill={props.fillColors[145]} d="M495.18,370.23c-3,0.87-5.96,2.23-9.02,2.51c-4.78,0.43-9.67-0.29-14.44,0.2
				c-8.17,0.84-15.57,2.41-19.41,11.74c-2.16,5.24-7.47,9.17-11.37,13.69c-0.58-0.27-1.16-0.54-1.74-0.81
				c-0.29-9.65-0.88-19.31-0.71-28.95c0.07-3.87,5.98-6.08,9.45-3.75c10.22,6.86,21.24,6.19,32.56,4.08c4.71-0.88,9.47-3,14.27-0.8
				C494.9,368.84,495.04,369.54,495.18,370.23z"/>
			<path onClick={() => props.onFill(146)} fill={props.fillColors[146]} d="M307.06,420.03c5.55,0,11.47,0,17.4,0c0-0.43,0-0.85,0-1.28c-4.37,0-8.75-0.26-13.1,0.06
				c-6.81,0.5-3.69-4.96-4.62-7.71c-1.15-3.42,1.91-2.81,4-3.13c4.62-0.71,9.19-1.7,13.79-2.46c0.69-0.11,1.67,0.13,2.17,0.59
				c2.19,2.02,1.48,13.82,0.27,15.04c0.65,4.54,1.49,8.1,1.44,11.65c-0.01,0.82-3.02,2.08-4.76,2.3c-3.93,0.49-7.95,0.77-11.9,0.54
				c-6.13-0.36-8.26-4.03-6.21-9.83C306.09,424.27,306.39,422.65,307.06,420.03z"/>
			<path onClick={() => props.onFill(147)} fill={props.fillColors[147]} d="M994.64,428.14c1.14-7.68,2.27-15.36,3.41-23.03c0.73,0.1,1.46,0.21,2.18,0.31
				c-1.55,9.65-3.11,19.3-4.66,28.95c0.52,0.21,1.03,0.41,1.55,0.62c2.62-4.13,5.24-8.26,7.86-12.39c0.33,0.18,0.67,0.35,1,0.53
				c-2.05,6.04-4.32,12.02-6.08,18.15c-1.04,3.64-1.71,7.5-1.77,11.27c-0.07,4.04-0.21,7.39-4.47,9.59c-2.4,1.24-2.12,3.32,0.5,4.95
				c1.19,0.74,2.43,2.46,2.47,3.78c0.29,8.64,0.28,17.29,0.32,25.93c0,0.91-0.31,1.82-0.48,2.73c-0.48,0.09-0.96,0.19-1.44,0.28
				c-0.52-2-1.29-3.97-1.5-6.01c-0.75-7.27-0.66-14.7-2.14-21.81c-1.22-5.86-3.68-11.63-6.53-16.93c-4.67-8.7-10.1-16.99-14.52-26.44
				c1.32,0.98,3.08,1.68,3.89,2.98c3.42,5.5,6.51,11.2,9.83,16.76c2.38,3.98,3.69,8.99,10.04,10.52
				c3.32-20.32-7.03-37.85-10.88-56.43c0.74-0.18,1.47-0.36,2.21-0.54c2.57,8.59,5.14,17.18,7.71,25.77
				C993.63,427.83,994.13,427.99,994.64,428.14z"/>
			<path onClick={() => props.onFill(148)} fill={props.fillColors[148]} d="M294.6,333.15c-10.85-2.74-20.64,2.01-30.88,3.6c-3.99,0.62-8.28-0.06-12.35-0.69
				c-5.06-0.79-10.06-2.05-15.03-3.32c-1.48-0.38-3.81-1.37-3.92-2.29c-0.18-1.52,0.73-4.17,1.89-4.66c3.22-1.36,6.8-2.5,10.25-2.56
				c6.91-0.13,13.84,0.36,20.73,0.88c4.09,0.31,8.16,1.12,12.21,1.83c4.06,0.71,8.14,1.41,12.12,2.45c1.95,0.51,3.7,1.76,5.54,2.68
				C294.98,331.76,294.79,332.46,294.6,333.15z"/>
			<path onClick={() => props.onFill(149)} fill={props.fillColors[149]} d="M214.6,410.8c-2.02-2.05-3.4-4.01-5.26-5.23c-3.56-2.34-5.76-4.62-2.09-8.61c0.49-0.53,0.6-1.52,0.66-2.32
				c0.44-5.99,4.02-9.79,10.1-10.29c5.6-0.46,11.22-0.8,16.83-0.83c0.91-0.01,2.63,1.87,2.61,2.85c-0.07,3.12-0.7,6.25-1.3,9.34
				c-0.16,0.82-1,2.13-1.56,2.14c-7.01,0.17-10.16,6.43-15.29,9.51C217.77,408.29,216.39,409.48,214.6,410.8z"/>
			<path onClick={() => props.onFill(150)} fill={props.fillColors[150]} d="M642.42,285.5c-4.99,0.34-9.76,0.76-14.54,0.91c-0.93,0.03-2.51-0.83-2.72-1.59
				c-1.14-3.97-2.05-8.02-2.76-12.09c-0.16-0.91,0.82-2.02,1.21-2.91c-0.82-1.16-1.64-2.33-2.46-3.5c1.14-0.73,2.19-1.71,3.44-2.12
				c2.26-0.74,4.64-1.14,6.97-1.69c-0.12-0.51-0.24-1.03-0.36-1.54c-2.5,0.58-4.98,1.36-7.51,1.63c-1.03,0.11-3.03-0.79-3.1-1.4
				c-0.2-1.7,0.06-3.65,0.8-5.19c0.33-0.69,2.92-1.24,3.3-0.81c3.64,4.1,8.01,1.96,12.12,1.68c2.6-0.18,5.19-0.61,7.87-0.93
				c0.25,7.5-7.36,2.78-9.76,7.33c2.72-0.35,4.88-0.63,7.52-0.97C642.42,269.9,642.42,277.47,642.42,285.5z"/>
			<path onClick={() => props.onFill(151)} fill={props.fillColors[151]} d="M468.04,440.02c-1.32-3.5-2.57-6.29-3.39-9.21c-0.31-1.11-0.02-2.75,0.65-3.69
				c2.23-3.16,1.99-6.21,0.25-9.37c-1.78-3.24-1-5.95,0.51-9.31c1.97-4.41,2.44-9.49,3.55-14.28c7.96,1.06,7.98,2,8.19,9.4
				c0.24,8.38,1.86,16.74,3.16,25.07c1.06,6.81,1.23,6.75-5.12,8.7C473.17,438.14,470.56,439.14,468.04,440.02z"/>
			<path onClick={() => props.onFill(152)} fill={props.fillColors[152]} d="M981.51,455.04c-2.39,2.71-4.05,4.59-5.8,6.58c-2.26-0.69-3.7-0.01-10.15,5.16
				c-5.32-7.05-8.84,3.42-14.23,1.61c0.26-1.69,0.49-3.2,0.86-5.55c-2.17,1.07-3.67,1.91-5.25,2.56c-2.12,0.87-4.24,1.98-6.46,2.3
				c-2.94,0.43-6.49,0.61-7.31-3.1c-0.18-0.81,3.38-2.81,5.47-3.68c3.61-1.51,7.4-2.58,11.64-4c-1.22-1.46-2.01-2.4-2.85-3.41
				c0.25-0.44,0.41-1.11,0.74-1.2c2.66-0.79,5.32-1.65,8.05-2.13c3.75-0.67,7.92,0.67,11.1-2.7c0.47-0.5,2.2-0.49,2.86-0.03
				C974.19,450.28,980.55,450.04,981.51,455.04z"/>
			<path onClick={() => props.onFill(153)} fill={props.fillColors[153]} d="M358.73,290.91c1.22-4.87,5.26-4.92,10.33-5.08c6.99-0.22,13.93-2.13,20.9-3.29
				c2.42-0.4,4.87-1.18,7.26-1.04c7.56,0.44,14.9,0.31,21.7-3.74c0.84-0.5,3.18-0.09,3.63,0.64c2.15,3.46,3.88,7.18,6.46,12.16
				c-12.36,0-24.14,0.07-35.93-0.02C381.56,290.45,370.32,296.55,358.73,290.91z"/>
			<path onClick={() => props.onFill(154)} fill={props.fillColors[154]} d="M691.87,589.32c-9.91-6.61-12.12-15.16-10.01-25.62c0.55-2.73-0.2-6.08-1.36-8.71
				c-2.01-4.57-1.15-8.84-0.86-13.53c0.62-10.16,0.1-20.43-0.54-30.62c-0.59-9.27-1.97-18.49-2.95-27.74
				c-0.06-0.53,0.43-1.11,1.16-2.88c0.82,2.13,1.44,3.17,1.61,4.27c2.14,13.93,4.29,27.87,6.22,41.83c0.33,2.38,0.04,5.02-0.65,7.34
				c-1.43,4.81-1.46,9,2.52,12.78c1.19,1.13,1.94,3.16,2.1,4.85c1.1,11.71,1.99,23.44,2.92,35.17
				C692.08,587.22,691.94,588.01,691.87,589.32z"/>
			<path onClick={() => props.onFill(155)} fill={props.fillColors[155]} d="M1082.58,330.73c3.34-0.37,6.67-0.74,10.77-1.19c-2.57-4.05-4.69-7.39-6.81-10.72
				c0.42-0.3,0.84-0.6,1.26-0.91c3.26,4.25,6.1,8.92,9.9,12.61c3.46,3.36,7.84,5.88,12.07,8.31c2.68,1.54,4.73,0.22,5.61-2.83
				c2.1-7.29,4.36-14.54,6.32-21.87c0.33-1.24-0.39-2.84-0.86-4.19c-0.83-2.4-1.84-4.75-2.78-7.12c0.5-0.23,0.99-0.47,1.49-0.7
				c0.75,1.56,1.23,3.37,2.34,4.61c1.01,1.13,2.7,1.64,4.54,2.68c-1.04,1.94-3.03,4.61-3.91,7.6c-0.63,2.12,0.24,4.68,0.43,7.04
				c1.83-0.38,3.69-0.65,5.47-1.17c2.7-0.8,5.3-2.01,8.04-2.62c4.74-1.06,9.66-1.64,10.65-8.07c0.44,0.37,0.88,0.74,1.32,1.11
				c-1.2,1.92-1.96,4.7-3.69,5.59c-4.66,2.38-9.58,4.76-14.66,5.68c-10.01,1.83-12.09,3.61-12.35,13.56
				c-0.03,1.32-0.43,2.64-0.45,3.96c-0.16,10.03,2.4,13.07,9.64,14.12c2.16,0.31,4.19,1.54,6.27,2.34c0.01,0.45,0.02,0.9,0.03,1.36
				c-1.37,0.39-2.71,0.94-4.1,1.09c-0.73,0.08-1.59-0.46-2.3-0.86c-7.17-4.03-13.82-8.89-22.61-9.6c-7.93-0.64-15.76-2.62-23.92-4.64
				c3.57-0.27,7.17-0.9,10.72-0.71c4.45,0.24,8.86,1.51,13.3,1.57c1.68,0.02,3.39-2.09,5.09-3.24c-1.26-1.25-2.52-2.5-3.79-3.74
				c-5.46-5.31-12.04-6.88-19.46-6.18c-1.21,0.11-2.56-1.17-3.84-1.8C1082.39,331.46,1082.48,331.1,1082.58,330.73z"/>
			<path onClick={() => props.onFill(156)} fill={props.fillColors[156]} d="M1046.01,204.38c-9.79,0-19.54-0.02-29.29,0.01c-3.49,0.01-7,0.4-10.47,0.17
				c-3.4-0.23-6.01-1.85-6.43-6.11c10.29-3.08,20.69-0.49,31.1-1.54c-3.38-0.42-6.76-1.17-10.15-1.2
				c-6.48-0.06-12.98,0.46-19.46,0.34c-1.82-0.03-3.61-1.45-6.07-2.52c4.98-4.62,9.62-3.63,14.24-3.43
				c6.41,0.28,12.85,0.37,19.26,0.11c3.18-0.13,5.97-0.52,7.3,3.22c0.25,0.71,0.97,1.59,1.64,1.77
				C1042.8,196.55,1045.59,199.87,1046.01,204.38z"/>
			<path onClick={() => props.onFill(157)} fill={props.fillColors[157]} d="M313.1,493.76c-1.11,0.58-2.59,1.07-3.71,2.01c-2.27,1.9-3.87,2.37-6.12-0.39
				c-0.98-1.2-3.77-0.91-6.04-1.37c0.21-0.73,0.41-1.65,0.74-2.52c2.68-7.18,2.6-14.26-0.4-21.36c-1.49-3.51-2.78-7.13-3.8-10.8
				c-0.41-1.47,0.13-3.21,0.24-4.82c2.43,0.38,6.08-0.12,7.09,1.28c4.24,5.9,8.27,12.11,11.21,18.73c1.62,3.63,1.04,8.28,1.25,12.48
				C313.66,489.07,313.29,491.16,313.1,493.76z"/>
			<path onClick={() => props.onFill(158)} fill={props.fillColors[158]} d="M938.94,633.72c-3.32,2.54-6.51,4.98-10.59,8.1c3.22,1.16,5.44,1.95,9.32,3.34
				c-3.24,2.58-5.59,4.58-8.07,6.4c-2.53,1.86-5.2,3.54-8.61,5.84c-3.71-8.88-12.62-14.44-14.12-24.55c-0.2-1.36-0.25-3.65,0.45-4.04
				c1.24-0.68,3.14-0.47,4.69-0.19c1.07,0.19,2.05,1.05,2.99,1.73c4.28,3.11,8.58,3.75,13.49,1.1
				C932.03,629.53,935.82,629.29,938.94,633.72z"/>
			<path onClick={() => props.onFill(159)} fill={props.fillColors[159]} d="M1127.28,636.48c3.4,0,5.96-0.47,8.26,0.11c3.87,0.98,7.48,3.04,10.77-1.43c0.4-0.54,4.32,0.61,5.68,1.93
				c1.61,1.56,1.58,3.79-1.17,5.24c-1.44,0.76-2.38,2.47-3.78,4.01c-0.35-0.14-1.33-0.53-2.71-1.08c-0.25,1.96-0.45,3.49-0.72,5.62
				c-1.55-0.56-2.8-1.01-4.1-1.47c-1.11,2.18-2.25,4.4-3.6,7.06c-1.78-1.51-2.96-2.51-4.47-3.79c-0.75,2.31-1.37,4.22-2.29,7.04
				c-1.74-2.26-2.87-3.72-4.29-5.55c-2.11,2.22-3.98,4.2-5.86,6.19c-1.39-1.79-2.78-3.59-4.41-5.69c-0.55,0.5-1.55,1.42-3.03,2.77
				c-1.57-4.86-4.04-7.45-8.98-3.11c1.3-4.49,5.64-6.5,7.89-4.09c2.93,3.14,4.96,1.87,7.57-0.34c1.84-1.56,4.18-2.67,6.46-3.55
				C1129.13,644.55,1128.85,641.09,1127.28,636.48z"/>
			<path onClick={() => props.onFill(160)} fill={props.fillColors[160]} d="M848.53,690.13c0.78,3.23,1.74,6.43,2.3,9.7c0.85,4.91,0.67,10.32,7.59,11.15
				c0.6,0.07,1.37,1.79,1.42,2.77c0.32,7.11,0.18,14.24,0.8,21.32c0.33,3.7-0.78,5.24-4.26,5.59c-12.37,1.27-11.27,0.78-11.8-9.11
				c-0.39-7.33-0.04-14.41,3.1-21.48c1.44-3.24-0.19-7.9-0.62-11.89c-0.27-2.54-0.88-5.05-1.34-7.58
				C846.66,690.45,847.6,690.29,848.53,690.13z"/>
			<path onClick={() => props.onFill(161)} fill={props.fillColors[161]} d="M574.27,280.45c7.11-7.01,14.53-14.33,23.14-22.83c0.5,4.36,0.82,7.21,1.15,10.07
				c-0.4,0.05-0.81,0.1-1.21,0.15c1.21,0.87,2.43,1.74,4.26,3.05c-2.16,0.91-3.54,1.5-6.35,2.69c8.67,0.74,6.34,5.09,4.96,9.23
				c-1.04,3.11-1.88,6.28-3.01,10.09C589.31,288.65,579.26,288.56,574.27,280.45z"/>
			<path onClick={() => props.onFill(162)} fill={props.fillColors[162]} d="M906.05,678.77c1.94,2.49,3.72,5.13,5.88,7.43c1.91,2.04,3.98,4.17,6.42,5.42
				c10.15,5.21,20.41,10.18,30.74,15.03c2.03,0.95,4.56,1.51,6.77,1.31c10.98-1.02,19.61,5.19,29.41,8.61
				c-6.77,6.63-21.44,6.2-28.37-0.85c-3.02-3.07-6.13-4.83-10.34-6.13c-8.34-2.56-16.32-6.36-24.33-9.92
				c-1.7-0.75-2.89-2.66-4.31-4.04c-3.63-3.53-7.31-6.99-10.84-10.61c-1.27-1.3-2.14-2.98-3.2-4.49
				C904.6,679.94,905.33,679.35,906.05,678.77z"/>
			<path onClick={() => props.onFill(163)} fill={props.fillColors[163]} d="M409.83,618.87c-0.78,1.04-1.49,2.92-2.34,2.98c-17.31,1.37-34.39,0.7-50.51-6.84
				c-0.58-0.27-1.09-0.69-1.64-1.04c4.85-6.81,29.92-12.76,44.06-10.6c-5.18,0.87-10.47,1.39-15.51,2.79
				c-2.15,0.6-3.78,3.05-5.65,4.65c2.15,1.23,4.18,3.14,6.48,3.55c6.36,1.15,12.85,1.56,19.26,2.51c2.05,0.3,3.98,1.38,5.97,2.11
				C409.95,618.98,409.83,618.87,409.83,618.87z"/>
			<path onClick={() => props.onFill(164)} fill={props.fillColors[164]} d="M571.87,557.4c4.46-8.04,8.09-15.18,12.31-21.96c2.14-3.42,11.44-5.31,14.54-2.86
				c1.5,1.18,1.89,4.6,1.69,6.9c-0.35,4.02-1.39,8.01-2.49,11.91c-0.41,1.45-1.68,3.61-2.78,3.76
				C587.98,556.11,580.75,556.61,571.87,557.4z"/>
			<path onClick={() => props.onFill(165)} fill={props.fillColors[165]} d="M1103.64,624.11c-5.64,7.59,3.37,9.61,4.44,14.49c0.15,0.68,4.1,1.33,5.77,0.67
				c2.45-0.97,4.04-1.3,6.23,0.59c1.3,1.12,3.55,1.13,5.77,2.52c-1.91,0.56-3.81,1.11-5,1.46c-2.5,2.17-4.66,4.03-6.82,5.9
				c-1.34-1.62-2.68-3.25-4.29-5.19c-1.65,1.2-3.45,3.25-5.61,3.75c-1.07,0.25-2.81-2.33-4.85-4.16c-3.94,4.02-9.69,1.66-11.41-2.32
				c-2.01-4.63-0.47-10.79-0.47-17.39C1093.21,627.94,1098.2,628.45,1103.64,624.11z"/>
			<path onClick={() => props.onFill(166)} fill={props.fillColors[166]} d="M1139.05,359.5c11.32-5.98,22.32-12.7,34.07-17.66c6.61-2.79,14.45-2.67,21.85-3.88c0,1.8,0,4.1,0,6.57
				c-4.22,0.71-8.66,0.77-12.51,2.3c-4.46,1.78-8.93,4.29-12.47,7.51C1163.77,360.02,1147.48,364.02,1139.05,359.5z"/>
			<path onClick={() => props.onFill(167)} fill={props.fillColors[167]} d="M1094.17,581.93c0.51-7.67,1.96-15.01,7.35-21.08c5.39-6.07,10.4-12.53,16.17-18.2
				c3.35-3.3,7.35-6.95,13-5c1.51,0.53,3.8,1.45,3.99,2.5c0.24,1.35-0.9,3.78-2.12,4.4c-4.05,2.06-8.31,3.86-12.67,5.13
				c-6.11,1.79-8.34,4.72-8.77,10.9c-0.24,3.37-1.07,7.26-3.06,9.82c-3.58,4.62-8.23,8.42-12.43,12.56
				C1095.14,582.61,1094.65,582.27,1094.17,581.93z"/>
			<path onClick={() => props.onFill(168)} fill={props.fillColors[168]} d="M707.75,435.54c2.1-1.3,3.57-2.35,5.16-3.18c7.16-3.73,7.23-4.13,0.75-9.7c2.05-1.53,3.8-3.3,5.93-4.24
				c1.11-0.49,3.66-0.02,4.22,0.87c3.22,5.08,6.03,10.42,8.87,15.47c-0.51,1.44-1.02,3.51-1.97,5.33c-0.8,1.54-1.88,3.16-3.27,4.12
				c-3.55,2.43-7.26,4.68-11.1,6.63c-1.37,0.7-3.28,0.31-4.95,0.42c0.16-1.46,0.49-2.93,0.46-4.39c-0.04-2.11,0.01-4.33-0.62-6.29
				C710.69,438.95,709.23,437.62,707.75,435.54z"/>
			<path onClick={() => props.onFill(169)} fill={props.fillColors[169]} d="M826.84,420.72c-8.47,3.58-15.2,9.19-20.53,16.63c-0.99,1.38-3.23,1.83-4.37,3.16
				c-1.6,1.85-2.68,4.15-4.04,6.22c-1.02,1.56-2.12,3.06-3.19,4.58c-1.26-1.08-2.74-2-3.75-3.28c-2.12-2.71-3.75-5.82-6.03-8.37
				c-2.38-2.66-1.94-5.09,1.02-5.67c9.87-1.94,17.42-7.61,25.42-13.18c3.7-2.57,10.02-1.39,15.15-1.91
				C826.63,419.5,826.74,420.11,826.84,420.72z"/>
			<path onClick={() => props.onFill(170)} fill={props.fillColors[170]} d="M286.57,497.78c-2.33,11.45-4.66,22.9-7.32,35.94c-7.79-7.05-14.63-13.24-22.21-20.09
				c10.6-4.78,21.1-7.49,27.68-16.63C285.34,497.25,285.95,497.52,286.57,497.78z"/>
			<path onClick={() => props.onFill(171)} fill={props.fillColors[171]} d="M638.23,370.25c-1.69,4.22-3.36,8.44-5.07,12.65c-0.99,2.44-1.65,5.15-3.17,7.22
				c-1.61,2.19-4.01,5.28-6.14,5.34c-8.25,0.24-16.52-0.56-25.92-1.04c13.77-8.98,26.33-17.18,38.9-25.37
				C637.29,369.45,637.76,369.85,638.23,370.25z"/>
			<path onClick={() => props.onFill(172)} fill={props.fillColors[172]} d="M739.64,440.81c1.3,1.73,2.6,3.46,3.9,5.18c0.35-0.22,0.71-0.44,1.06-0.66c-1.09-2.24-2.18-4.47-3.84-7.87
				c7.36-2.07,14.2-4.37,21.22-5.59c1.27-0.22,5.18,5.13,4.65,6.02c-1.86,3.16-2.9,7.33-7.61,8.44c-9.49,2.23-18.86,5-28.3,7.5
				c-4.1,1.08-7.9,2.38-9.74,6.8c-0.39,0.94-2.65,2.13-2.86,1.94c-1.46-1.36-2.61-3.06-3.91-4.72c6.27-2.48,11.75-4.75,17.31-6.8
				C736.27,449.3,740.68,447.46,739.64,440.81z"/>
			<path onClick={() => props.onFill(173)} fill={props.fillColors[173]} d="M754.95,423.29c-6.11,3.71-15.8-0.43-18.18,9.42c-7.94-2.51-8.58-10.46-11.85-16.54
				c3.6-3.09,4.88-2.82,5.83,1.47c0.78,3.51,1.26,7.09,1.87,10.64c0.5-0.01,1.01-0.03,1.51-0.04c-0.13-2.31,0.02-4.68-0.44-6.92
				c-1.94-9.48-2-9.44,7.37-11.7c2.9-0.7,5.75-1.62,8.65-2.37c0.44-0.11,0.97,0.12,1.78,0.25
				C752.62,412.69,753.78,417.97,754.95,423.29z"/>
			<path onClick={() => props.onFill(174)} fill={props.fillColors[174]} d="M1190.81,497.71c-0.22-1.41-0.45-2.82-0.75-4.69c-1.96,0.89-3.53,1.79-5.21,2.31
				c-1.78,0.55-3.67,0.75-5.51,1.1c-0.08-1.73-0.16-3.45-0.26-5.51c-1.08,0-2.91,0.48-4.24-0.13c-1.47-0.67-2.7-2.2-3.63-3.62
				c-0.81-1.24-1.07-2.85-1.74-4.76c1.12-0.15,2.84-0.38,4.05-0.54c0.53-3.05,1.04-5.94,1.54-8.83c2.04,1.2,4.08,2.41,5.82,3.44
				c1.8-1.05,4.64-3.82,5.96-3.22c2.43,1.1,3.87,4.27,5.91,6.4c3.25,3.4,2.56,7.59,2.29,11.56c-0.17,2.37-1.4,4.67-2.15,7
				C1192.19,498.04,1191.5,497.87,1190.81,497.71z"/>
			<path onClick={() => props.onFill(175)} fill={props.fillColors[175]} d="M128.62,492.37c4.66-6.36,8.7-11.84,12.7-17.34c1.85-2.54,3.78-5.04,5.41-7.71
				c2.45-4.03,4.66-8.2,7.18-12.67c0.38,6.08,0.77,11.34,0.99,16.6c0.05,1.1-0.52,2.23-0.86,3.33c-1.82,5.91-3.47,11.88-5.63,17.66
				c-0.47,1.25-2.85,2.64-4.24,2.54C139.57,494.43,135.03,493.43,128.62,492.37z"/>
			<path onClick={() => props.onFill(176)} fill={props.fillColors[176]} d="M888.82,160.83c0,3.26,0,6.67,0,10.56c-6.67,0-13.1,0.12-19.52-0.04c-5.26-0.13-10.1-0.09-11.12,6.81
				c-0.16,1.06-2.29,2.82-3,2.6c-1.76-0.54-3.5-1.82-4.65-3.28c-0.4-0.5,0.73-2.6,1.58-3.62c3.78-4.55,7.09-9.89,11.81-13.15
				c3.34-2.31,8.66-2.3,13.09-2.31C880.71,158.4,884.42,159.87,888.82,160.83z"/>
			<path onClick={() => props.onFill(177)} fill={props.fillColors[177]} d="M341.82,407.29c-2.03,0.39-4.07,0.77-6.1,1.16c-0.02,0.34-0.05,0.68-0.07,1.01
				c2.55,0.24,5.11,0.49,7.33,0.7c0.67,5.08,0.9,9.89,1.99,14.5c2.03,8.56-5.24,12.7-8.45,18.75c-0.12,0.22-2.9-0.27-3.35-1.06
				c-2.15-3.74-0.51-6.57,3.93-7.5c0.98-0.2,1.87-0.79,2.89-1.24c-2.3-3.89-7.8,3.12-8.45-2.87c-0.33-3.09-2.81-7.71,3.68-9.15
				c-5.42-1.4-7.01-6.69-5.6-15.84c4.13,0,8.23,0,12.33,0C341.9,406.28,341.86,406.78,341.82,407.29z"/>
			<path onClick={() => props.onFill(178)} fill={props.fillColors[178]} d="M1194.63,347.21c1.17,8.26,1.16,8.24-5.84,10.56c-8.49,2.81-16.78,6.89-25.49,8.19
				c-10.41,1.55-21.17,0.75-31.77,0.88c-0.69,0.01-1.39-0.71-2.17-2.36c2.23-0.3,4.44-0.73,6.68-0.88c7.9-0.51,15.86-0.48,23.67-1.59
				c3.36-0.48,7.16-2.53,9.44-5.06C1176.03,349.29,1184.83,347.6,1194.63,347.21z"/>
			<path onClick={() => props.onFill(179)} fill={props.fillColors[179]} d="M125.58,607.53c8.54,5.44,17.08,10.9,25.63,16.31c8.84,5.59,17.64,11.25,26.59,16.66
				c3.44,2.08,4.4,4.37,2.06,7.7c-1.57,2.23-3.4,2.89-5.85,0.97c-15.39-12.12-30.84-24.17-46.22-36.31
				c-1.34-1.06-2.29-2.62-3.42-3.94C124.78,608.45,125.18,607.99,125.58,607.53z"/>
			<path onClick={() => props.onFill(180)} fill={props.fillColors[180]} d="M1194.93,659.12c0,14.76,0,28.46,0,42.02c-0.49,0.33-0.74,0.64-1.02,0.67
				c-11.54,1.13-11.06,1.12-11.27-10.12C1182.4,679.43,1185.92,668.91,1194.93,659.12z"/>
			<path onClick={() => props.onFill(181)} fill={props.fillColors[181]} d="M224.81,432.25c2.5-6.85,12.53-9.55,17.09-5.48c0.72,0.64,1.15,2.01,1.12,3.02
				c-0.13,4.46,2.3,5.17,6.11,4.93c11.06-0.71,22.15-1.11,33.21-1.79c3.52-0.22,4.66,1.08,4.57,4.56c-0.1,3.51-1.85,4.36-4.78,3.94
				c-6.01-0.86-11.99-2.14-18.03-2.64c-4.8-0.39-9.67,0.3-14.5,0.19c-5.56-0.13-11.52,0.46-14.08-6.62
				c-0.32-0.87-3.18-1.09-4.87-1.11C228.93,431.23,227.2,431.81,224.81,432.25z"/>
			<path onClick={() => props.onFill(182)} fill={props.fillColors[182]} d="M425.58,418.11c-1.2-2.19-3.48-4.41-3.43-6.57c0.36-13.38,1.25-26.74,1.97-40.26
				c3.12,0.89,7.39,9.5,9.05,17.23c0.93,4.34,3.29,9.09,2.31,12.98c-1.47,5.85-5.31,11.1-8.13,16.6
				C426.76,418.1,426.17,418.11,425.58,418.11z"/>
			<path onClick={() => props.onFill(183)} fill={props.fillColors[183]} d="M613.55,174.4c21.93-3.43,43.88-3.16,65.88-0.62c-2.31,0-4.68-0.34-6.9,0.11
				c-1.8,0.36-4.63,1.39-4.89,2.58c-0.9,4.14-3.95,4.71-7,5c-6.59,0.63-13.07,0.8-19.59-1.69c-4.18-1.6-9.02-1.64-13.6-1.95
				C622.65,177.5,617.62,178.63,613.55,174.4z"/>
			<path onClick={() => props.onFill(184)} fill={props.fillColors[184]} d="M554.63,507.25c5.82,0,10.67,0,16.25,0c-4.45,5.2-9.34-0.26-13.42,1.33c-0.07,0.5-0.14,1-0.21,1.49
				c2.82,0.62,5.64,1.24,8.8,1.93c-0.46,2.2-0.93,4.4-1.43,6.81c-2.57-0.85-4.99-1.65-7.41-2.45c-0.15,0.32-0.3,0.65-0.46,0.97
				c0.8,0.6,1.53,1.38,2.42,1.76c5.85,2.48,5.9,2.83,2.92,8.12c-1.36,2.41-1.97,5.23-3.13,7.76c-2.38,5.17-4.92,10.26-7.62,15.88
				c-3.83-4.96-3.41-9.1-2.4-13.69C551.15,527.18,552.79,517.09,554.63,507.25z"/>
			<path onClick={() => props.onFill(185)} fill={props.fillColors[185]} d="M478.79,476.77c-4.4,11.04-8.41,20.96-12.32,30.92c-1.89,4.81-3.38,9.84-8.38,12.53
				c-1.53,0.83-3.13,1.55-5.76,2.84c1.67-8.36,3.11-15.56,4.55-22.76C466.14,494.77,465.49,480.36,478.79,476.77z"/>
			<path onClick={() => props.onFill(186)} fill={props.fillColors[186]} d="M850.03,594.82c-5.89-6.93-11.12-13.12-16.39-19.28c-0.75-0.88-1.52-1.97-2.52-2.36
				c-11.22-4.35-16.57-14.39-22.95-23.44c-1.13-1.6-2.16-3.28-3.23-4.92c0.27-0.35,0.55-0.69,0.82-1.04c1.8,1.36,3.78,2.54,5.35,4.12
				c3.71,3.75,7.08,7.84,10.87,11.51c4.63,4.49,9.24,9.12,14.43,12.88c4.23,3.06,9.17,5.34,14.09,7.21c4.34,1.65,5.42,3.78,3.26,7.78
				C852.61,589.4,851.61,591.61,850.03,594.82z"/>
			<path onClick={() => props.onFill(187)} fill={props.fillColors[187]} d="M849.6,686.53c7.32,2.88,13.65,3.51,17.24,10.63c2.31,4.59,7.03,8.01,10.86,11.76
				c3.91,3.83,5.31,9.58,3.37,14.67c-0.68,1.79-1.07,3.7-2,7c-3.04-4.77-5.67-8.62-8.01-12.64c-1.74-3.01-2.78-6.46-4.74-9.3
				c-1.58-2.3-4.07-3.97-6.15-5.93c-1.42-1.33-3.17-2.47-4.15-4.07C853.92,695.2,852.21,691.5,849.6,686.53z"/>
			<path onClick={() => props.onFill(188)} fill={props.fillColors[188]} d="M165.24,556.56c2.22,1.45,4.64,2.68,6.64,4.4c9.84,8.41,19.47,17.05,29.38,25.38
				c3.49,2.94,7.54,5.21,11.34,7.78c-0.18,0.37-0.36,0.74-0.53,1.11c-2.92-0.96-5.97-1.65-8.74-2.93
				c-10.19-4.7-20.31-9.56-30.42-14.44c-4.61-2.23-5.26-4.98-3.36-10.25c0.44-1.22-0.39-3.08-1.02-4.47c-0.45-1-1.57-1.68-2.33-2.56
				c-0.72-0.83-1.37-1.72-2.05-2.59C164.5,557.52,164.87,557.04,165.24,556.56z"/>
			<path onClick={() => props.onFill(189)} fill={props.fillColors[189]} d="M973.49,416.68c1.23-2.49,2.11-4.81,3.47-6.8c0.76-1.11,2.26-2.16,3.54-2.31c0.75-0.09,2.26,1.51,2.5,2.56
				c3.14,13.66,6.13,27.35,9.09,41.05c0.22,1.01-0.14,2.15-0.37,4.83c-2.1-2.97-3.5-4.67-4.59-6.55c-3.98-6.87-8-13.73-11.66-20.77
				c-0.82-1.57-0.94-4.23-0.16-5.77C977.23,419.09,977.51,419.24,973.49,416.68z"/>
			<path onClick={() => props.onFill(190)} fill={props.fillColors[190]} d="M786.42,15.75c-3.64,1.14-6.86,2.49-10.22,3.09c-3.11,0.55-6.92,1.48-7.43-3.82
				c-0.04-0.42-2.43-1.08-3.56-0.84c-2.73,0.57-5.32,2-8.06,2.32c-1.62,0.19-3.42-1.06-5.13-1.66c0.96-1.76,1.61-3.84,2.99-5.17
				c0.86-0.83,2.8-0.71,4.26-0.72c14.78-0.04,29.56-0.06,44.34,0.03c1.66,0.01,3.32,0.69,4.98,1.06c-0.05,0.48-0.09,0.96-0.14,1.44
				c-0.85,0.33-1.72,0.98-2.54,0.92c-2.96-0.2-5.33-0.69-7,3.25C797.42,19.11,790.95,18.6,786.42,15.75z"/>
			<path onClick={() => props.onFill(191)} fill={props.fillColors[191]} d="M301.71,282.68c-0.73,7.11-3.51,10.32-9.93,11.13c-5.9,0.74-11.87,1.31-17.81,1.25
				c-9.6-0.1-19.19-0.68-28.77-1.32c-1.41-0.09-2.72-1.64-4.08-2.51c1.45-1.36,2.87-3.83,4.35-3.87c5.25-0.13,10.53,0.57,15.79,0.94
				c7.2,0.51,14.41,1.33,21.61,1.29c2.05-0.01,4.45-2.21,6.07-3.97C292.73,281.48,297.27,283.33,301.71,282.68z"/>
			<path onClick={() => props.onFill(192)} fill={props.fillColors[192]} d="M797.62,390.16c6.94-3.14,13.17-5.96,19.56-8.86c1.75,5.31,3.46,10.42,5.08,15.57
				c0.22,0.69,0.06,1.61-0.21,2.31c-1.7,4.37-3.48,8.7-5.5,13.72C810.37,405.49,804.06,397.91,797.62,390.16z"/>
			<path onClick={() => props.onFill(193)} fill={props.fillColors[193]} d="M1033.32,290.14c1.97-2.24,3.14-3.59,4.32-4.93c1.12,1.21,3.04,2.33,3.19,3.64c0.22,2-0.79,4.14-1.41,6.76
				c-2.11,1.62-8.34-2.26-7.41,5.27c-2.83,0-5.3-0.2-7.73,0.05c-2.9,0.29-4.19,1.81-2.75,4.81c0.56,1.16,1.22,2.41,1.27,3.64
				c0.07,1.72,0.04,3.72-0.78,5.11c-0.47,0.81-3.27,1.37-3.78,0.84c-1.15-1.2-1.54-3.14-2.28-4.89c-2.07-1.32-4.45-2.85-7.67-4.91
				c2.27-1.01,3.39-1.51,3.46-1.54c-0.55-3.05-1.03-5.68-1.51-8.31c3.01,0,6.02,0,9.49,0c-0.73-1.49-1.32-2.7-1.9-3.88
				c2.64-4.14,9.25,4.43,10.54-3.68C1030.45,288.98,1032.37,289.76,1033.32,290.14z"/>
			<path onClick={() => props.onFill(194)} fill={props.fillColors[194]} d="M730.49,685.64c3.08-2.46,5.85-5.6,9.32-7.22c5.9-2.75,11.78-5.22,16.29-10.21
				c1.1-1.22,3.16-1.65,4.85-2.26c5.91-2.13,8.75-6.56,8.25-12.79c-0.44-5.38,2.76-8.43,7.48-11.4c0,1.65,0.24,2.63-0.04,3.43
				c-2.47,6.99-5.12,13.92-7.56,20.93c-1.87,5.37-5.23,8.7-10.74,10.79c-6.13,2.33-11.68,6.13-17.67,8.9
				c-2.71,1.25-5.86,1.55-8.81,2.28C731.4,687.28,730.94,686.46,730.49,685.64z"/>
			<path onClick={() => props.onFill(195)} fill={props.fillColors[195]} d="M1087.69,172.34c-2.76,0.47-5.53,0.94-8.28,1.42c-3.89,0.68-6.19-1.24-6.78-4.81
				c-0.7-4.24-0.92-8.56-1.19-12.86c-0.19-2.93,1.44-4.45,4.3-4.02c5.23,0.79,10.49,1.56,15.59,2.88c2.96,0.77,3.35,3.44,2.06,6.08
				c-1.86,3.8-3.84,7.55-5.77,11.32C1087.62,172.36,1087.69,172.34,1087.69,172.34z"/>
			<path onClick={() => props.onFill(196)} fill={props.fillColors[196]} d="M1097.6,549.4c0-3.23-0.25-6.48,0.05-9.68c0.69-7.43,1.55-14.86,2.58-22.25c0.16-1.14,1.57-2.11,2.4-3.15
				c0.6,0.97,1.49,1.87,1.76,2.93c1.26,4.82,5.56,6.96,9.16,8.83c4.86,2.52,5.78,3.96,1.78,7.97c-5.37,5.38-10.84,10.66-16.27,15.98
				C1098.59,549.8,1098.1,549.6,1097.6,549.4z"/>
			<path onClick={() => props.onFill(197)} fill={props.fillColors[197]} d="M1179.63,665.94c0-6.39-0.14-12.79,0.04-19.18c0.19-7.02,3.17-12.52,8.6-17.24
				c2.77-2.41,4.02-6.58,6.32-10.58c2.97,18.31-4.47,32.98-13.34,47.24C1180.71,666.11,1180.17,666.02,1179.63,665.94z"/>
			<path onClick={() => props.onFill(198)} fill={props.fillColors[198]} d="M1022.58,479.3c-7.35,5.62-14.29,10.92-22.52,17.2c-1.23-10.19-2.64-19.09-1.04-27.54
				c5.56,0.43,10.77,0.83,16.06,1.23C1014.09,476.51,1014.09,476.51,1022.58,479.3z"/>
			<path onClick={() => props.onFill(199)} fill={props.fillColors[199]} d="M468.75,509.78c2.05-5.13,4.18-10.23,6.12-15.4c2.14-5.71,3.74-11.64,6.23-17.18
				c2.89-6.44,5.39-6.76,11.21-2.89c2.55,1.7,3.33,3.4,1.74,6.16c-6.51,11.25-13.95,21.72-24.16,29.94
				C469.51,510.2,469.13,509.99,468.75,509.78z"/>
			<path onClick={() => props.onFill(200)} fill={props.fillColors[200]} d="M501.9,517.77c-0.36,2.21-0.66,4.06-1.1,6.72c-4.7,0-9.63,0.39-14.46-0.13c-3.57-0.39-6.48-0.41-9.86,1.51
				c-5.23,2.97-10.79,1.96-16.01-1.23c1.86-2.75,3.29-5.64,5.48-7.76c1.16-1.13,3.62-1.23,5.5-1.24c2.07,0,4.42,1.46,6.15,0.85
				C485.95,513.55,493.89,515.23,501.9,517.77z"/>
			<path onClick={() => props.onFill(201)} fill={props.fillColors[201]} d="M621.21,374.07c0-9.32-0.04-18.35,0.01-27.38c0.04-6.37,4.39-10.72,10.11-10.4
				c3.78,0.21,5.61,1.84,4.57,5.7c-1.17,4.31-2.48,8.59-3.77,12.87c-1.19,3.96-3.04,7.84-3.52,11.89
				C628.05,371.56,625.17,372.95,621.21,374.07z"/>
			<path onClick={() => props.onFill(202)} fill={props.fillColors[202]} d="M701.89,623.28c-7.73-8.22-15.46-16.43-22.72-24.15c1.42-2.78,2.13-4.45,3.1-5.96
				c0.75-1.15,1.71-2.7,2.81-2.92c0.87-0.18,2.12,1.35,3.17,2.18c1.53,1.21,3.02,2.48,4.54,3.69c0.75,0.59,2.06,0.99,2.24,1.7
				c2.38,9.23,11,13.9,15.79,22.02c-3.16-0.82-6.04-1.56-10.08-2.61c0.93,2.73,1.4,4.13,1.88,5.54
				C702.39,622.94,702.14,623.11,701.89,623.28z"/>
			<path onClick={() => props.onFill(203)} fill={props.fillColors[203]} d="M1147.21,177.66c-3.83,14.15-7.5,27.7-11.1,40.96c-2.65-2.67-4.68-4.89-6.91-6.89
				c-2.21-1.99-3.05-3.8-1.53-6.87c3.53-7.1,6.61-14.43,9.86-21.68C1139.14,179.61,1140.82,176.19,1147.21,177.66z"/>
			<path onClick={() => props.onFill(204)} fill={props.fillColors[204]} d="M795.31,410.34c-6.98,3.39-12.83,6.97-19.17,9.08c-5.36,1.78-6.12-4.07-7.75-7.23
				c-1.53-2.97-3.66-7.09,0.27-9.79c5.83-4.01,12.18-3.12,17.94,1.55C789.14,406,791.87,407.83,795.31,410.34z"/>
			<path onClick={() => props.onFill(205)} fill={props.fillColors[205]} d="M841.92,243.24c-0.33-3.24-0.79-6.48-0.91-9.73c-0.04-1.19,0.73-2.42,1.12-3.63
				c0.84,1.08,2.09,2.03,2.45,3.25c1.13,3.78,1.92,7.65,2.84,11.49c0.4-0.11,0.8-0.22,1.2-0.33c-0.34-4.61-0.7-9.21-0.91-13.82
				c0-0.06,1.76-0.21,2.35-0.28c1.09,5.63,2.13,10.95,3.4,17.5c-8.71,0-17.18,0-24.81,0c0-5.12,0.14-9.86-0.04-14.58
				c-0.15-3.97,2.37-4.06,5.22-4.21c2.76-0.15,4.91-0.04,5.06,3.62c0.15,3.61,0.77,7.2,1.18,10.79
				C840.68,243.29,841.3,243.27,841.92,243.24z"/>
			<path onClick={() => props.onFill(206)} fill={props.fillColors[206]} d="M581.07,491.39c-5.42,14.36-8.05,15.83-24.9,13.69c-0.17-3.32-0.34-6.71-0.5-9.9
				c2.01-1.15,4.18-2.03,5.91-3.44C568.86,485.75,573.53,485.6,581.07,491.39z"/>
			<path onClick={() => props.onFill(207)} fill={props.fillColors[207]} d="M284.03,404.65c-7.34,0.44-14.6,0.55-21.75,1.46c-2.82,0.36-5.41,2.5-8.08,3.88
				c-0.97,0.5-1.84,1.6-2.81,1.67c-5.64,0.41-11.3,0.6-16.96,0.87c-0.14-0.71-0.27-1.41-0.41-2.12c4.23-1.29,8.41-2.79,12.7-3.81
				c4.4-1.05,8.91-1.63,13.37-2.42c-0.13-0.69-0.25-1.38-0.38-2.08c-7.73,1.48-15.47,2.96-23.17,4.43c-0.3-5.68,0.26-5.88,5.82-5.2
				c3.32,0.4,6.97,0.08,10.16-0.93c5.29-1.68,10.19-3.06,15.91-1.29c3.45,1.07,7.52-0.04,11.27,0.3
				C282.47,399.68,285.57,400.36,284.03,404.65z"/>
			<path onClick={() => props.onFill(208)} fill={props.fillColors[208]} d="M99.67,636.19c5.61,2.11,10.74,4.02,15.85,5.95c0.93,0.35,2.15,0.5,2.72,1.17
				c7.68,9.07,18.93,13.45,27.65,21.14c0.32,0.28,0.4,0.84,0.5,1.05c-7.57-2.02-15.35-3.66-22.78-6.27
				c-3.72-1.31-7.28-3.94-10.08-6.79C108.67,647.47,104.45,641.87,99.67,636.19z"/>
			<path onClick={() => props.onFill(209)} fill={props.fillColors[209]} d="M706.13,519.33c-0.33-1.82-0.27-3.08-0.78-4.04c-2.7-5.13-0.27-7.57,4.13-10.1
				c1.82-1.04,2.22-4.55,3.25-6.95c0.74-1.71,1.04-4.51,2.24-4.96c4.37-1.64,6.15-4.42,5.14-9.26c0.6-0.07,1.2-0.13,1.81-0.2
				c1.88,4.7,3.77,9.4,5.65,14.11c-0.26,0.33-0.51,0.66-0.77,0.99c-2.14-1.13-4.29-2.25-6.43-3.38c3.64,3.62-2.84,8.76,1.79,12.52
				c-2.32,2.86-4.24,6.23-7.09,8.41C712.86,518.15,709.5,518.33,706.13,519.33z"/>
			<path onClick={() => props.onFill(210)} fill={props.fillColors[210]} d="M34.2,577.23c-1.56,1.47-2.89,3.41-4.73,4.31c-4.44,2.16-9.3,3.49-13.64,5.82
				c-3.39,1.82-6.25,4.64-9.49,7.12c0-7.33,0-14.83,0-22.59c3.45-0.54,6.94-1.72,10.28-1.37c2.17,0.22,4.61,2.28,6.06,4.18
				C26.72,579.97,28.87,580.54,34.2,577.23z"/>
			<path onClick={() => props.onFill(211)} fill={props.fillColors[211]} d="M1005.11,691.52c2.9,3.13,4.99,6.95,8.58,2.22c6.85,5.51,7.69,5.41,10.24-0.85
				c3.84,3.32,7.74,6.71,11.64,10.09c0.36-0.34,0.72-0.68,1.09-1.02c0.41,2.2,0.81,4.41,1.1,5.99c-11.1-0.74-22.02-1.48-33.35-2.23
				C1004.59,702,1004.83,697.22,1005.11,691.52z"/>
			<path onClick={() => props.onFill(212)} fill={props.fillColors[212]} d="M361.76,496.14c-2.8,8.23-5.05,16.21-8.3,23.75c-1.32,3.06-4.81,5.18-7.31,7.73
				c-0.4-0.32-0.81-0.63-1.21-0.95c0.94-9.82,1.82-19.65,2.83-29.46c0.41-4.01,3.04-4.85,6.46-3.81
				C356.88,494.2,359.44,495.28,361.76,496.14z"/>
			<path onClick={() => props.onFill(213)} fill={props.fillColors[213]} d="M179.57,459.75c3.13,5.2,6.19,10.44,9.43,15.57c1.13,1.8,2.8,3.25,4.02,5
				c3.59,5.13,7.13,10.28,10.55,15.52c0.55,0.84,0.32,2.18,0.53,3.92c-2.63,0-4.89,0.08-7.14-0.02c-3.84-0.18-6.41-2.03-6.48-6.06
				c-0.14-7.65-3.03-13.33-9.5-17.95c-5.13-3.67-2.63-9.98-2.88-15.21C178.59,460.26,179.08,460,179.57,459.75z"/>
			<path onClick={() => props.onFill(214)} fill={props.fillColors[214]} d="M306.99,196.03c-4.43-5.03-7.57-8.6-11.47-13.04c9.21-3.07,16.4-3.43,21.97,3.31
				c-3.58-4.3-7.15-8.6-10.73-12.9c-0.26,0.16-0.52,0.33-0.78,0.49c0.18,0.69,0.25,1.44,0.56,2.06c0.35,0.7,0.92,1.3,2.13,2.95
				c-5.28,0.25-9.62,0.45-13.97,0.65c-0.16-0.55-0.32-1.09-0.49-1.64c3.39-3.16,7.49-4.93,12.16-6.12c5.9-1.51,9.9-0.29,11.9,5.9
				c0.47,1.47,2.13,2.51,2.96,3.92c0.68,1.16,1.69,3.25,1.25,3.73c-1.1,1.19-3.51,2.76-4.38,2.29c-4.6-2.45-6.65-0.13-8.38,3.58
				C309.08,192.57,308.24,193.84,306.99,196.03z"/>
			<path onClick={() => props.onFill(215)} fill={props.fillColors[215]} d="M1164.12,623.14c0,0.36,0.02,1.17,0,1.99c-0.02,0.81-0.07,1.63-0.14,2.96c-1.35-0.46-2.44-0.82-3.71-1.25
				c-0.93,1.77-1.87,3.55-3.28,6.22c-1.28-2.28-2.07-3.68-3.08-5.47c-1.32,2.04-2.42,3.72-3.74,5.76c-1.5-1.69-2.65-2.99-3.03-3.42
				c0.84-3.99,1.37-7.28,2.27-10.47c0.33-1.17,1.31-2.39,2.33-3.09c4.53-3.09,8.36-7.79,14.53-7.43c1.07,2.91,2.14,5.29,2.8,7.79
				c0.47,1.79,0.92,3.9,0.35,5.51C1169.17,622.96,1166.41,622.8,1164.12,623.14z"/>
			<path onClick={() => props.onFill(216)} fill={props.fillColors[216]} d="M696,456.85c2.66-1.08,5.31-2.16,7.55-3.07c2.76,6.74,5.74,12.78,7.57,19.15c0.66,2.31-0.8,5.91-2.43,8.04
				c-2.06,2.69-5.29,4.47-8.06,6.68c-1.7-7.45-3.24-13.94-4.62-20.46c-0.18-0.85,0.57-1.85,0.68-2.81c0.27-2.29,0.42-4.59,0.62-6.88
				C696.88,457.28,696.44,457.06,696,456.85z"/>
			<path onClick={() => props.onFill(217)} fill={props.fillColors[217]} d="M243.48,508.74c-3.41,5.1-7.9,8.82-14.25,8.18c-6.64-0.67-11.88,1.07-16.66,5.67
				c-2.1,2.02-5.22,3-7.87,4.46c1.72-3.61,2.88-7.02,4.85-9.87c1.49-2.16,3.79-4.01,6.12-5.28c3.91-2.12,8.19-3.53,12.19-5.5
				C233.31,503.72,238.3,504.32,243.48,508.74z"/>
			<path onClick={() => props.onFill(218)} fill={props.fillColors[218]} d="M795.22,223.76c4.93-0.25,9.86-0.43,14.79-0.76c3.18-0.21,6.35-0.74,9.53-0.95
				c3.39-0.22,6.79-0.38,10.18-0.33c5.55,0.08,11.09,0.45,16.64,0.5c6.14,0.05,12.28-0.17,18.43-0.13c0.81,0,1.61,0.8,3.31,1.71
				c-1.72,0.99-2.71,2.02-3.75,2.08c-8.55,0.45-17.12,0.53-25.66,1.1c-4.66,0.31-9.26,1.55-13.92,1.99
				c-1.78,0.17-3.73-0.53-5.48-1.16c-1.8-0.66-3.43-2.41-5.18-2.46c-3.62-0.11-7.25,0.72-10.89,0.89c-2.65,0.12-5.33-0.19-7.99-0.31
				C795.23,225.2,795.22,224.48,795.22,223.76z"/>
			<path onClick={() => props.onFill(219)} fill={props.fillColors[219]} d="M867.2,227.78c2.01,7.07,3.78,13.32,5.47,19.26c-4.43,0.7-8.96,1.54-13.54,2.02
				c-0.89,0.09-2.51-1.07-2.79-1.97c-1.31-4.1-2.42-8.27-3.28-12.48c-0.65-3.17,0.17-5.54,4.18-5.71
				C860.68,228.75,864.1,228.14,867.2,227.78z"/>
			<path onClick={() => props.onFill(220)} fill={props.fillColors[220]} d="M878.83,733.97c-5.1,1.87-9.92,3.63-16,5.85c-0.25-8.95-0.65-16.83-0.59-24.7
				c0.02-3.16-0.32-4.94-3.69-6.49c-2.63-1.21-4.23-4.65-6.29-7.1c0.64-0.57,1.29-1.15,1.93-1.72c3.77,3.97,8.02,7.61,11.21,12.01
				c4.35,6,7.92,12.58,11.8,18.92C877.71,731.57,878.09,732.48,878.83,733.97z"/>
			<path onClick={() => props.onFill(221)} fill={props.fillColors[221]} d="M301.15,388.57c6.7-1.19,12.71-2.21,18.69-3.35c3.81-0.73,5.47,0.81,5.8,4.59c0.76,8.7,0.71,8.85-7.95,9.7
				c-2.47,0.24-5.05,0.09-7.4,0.75c-5.13,1.43-7.38-0.76-8.08-5.55C301.93,392.78,301.55,390.86,301.15,388.57z"/>
			<path onClick={() => props.onFill(222)} fill={props.fillColors[222]} d="M5.8,470.05c0.91-0.79,1.78-1.65,2.75-2.36c1.2-0.88,2.69-1.44,3.71-2.48
				c6.36-6.48,12.74-12.94,18.86-19.64c2.78-3.04,5.15-5.73,9.98-4.97c2.76,0.43,5.77-0.7,10.2-1.35c-3.39,2.4-5.44,4.43-7.9,5.47
				c-5.02,2.13-8.2,5.95-11.03,10.3c-6.12,9.41-16.19,12.39-25.71,16.25C6.38,470.87,6.09,470.46,5.8,470.05z"/>
			<path onClick={() => props.onFill(223)} fill={props.fillColors[223]} d="M1180.74,621.05c-0.77-2.56-1.53-5.13-2.37-7.92c-0.62,0.24-1.19,0.48-1.78,0.69
				c-1.78,0.62-3.56,1.23-5.33,1.85c-0.28-2.2-0.98-4.45-0.72-6.59c0.29-2.36,1.8-3.5,4.67-2.84c1.49,0.34,3.33-0.88,5.03-1.37
				c4.74-1.38,9.48-2.75,14.63-4.25c0.13,6.46,2.31,12.72-4.83,16.84c-0.87-0.91-1.9-2-2.9-3.05c-1.69,2.66-3.22,5.05-4.75,7.45
				C1181.84,621.58,1181.29,621.31,1180.74,621.05z"/>
			<path onClick={() => props.onFill(224)} fill={props.fillColors[224]} d="M1138.83,624.28c1.61,2.79,3.93,5.21,4.35,7.93c0.55,3.52-3.13,5.2-5.46,2.84
				c-1.08-1.1-1.78-2.58-3.16-4.66c-1.77,6.62-5.42,3.59-8.02,2.71c-1.49,2.08-2.71,3.79-3.94,5.5c-1.86-1.43-3.73-2.86-6.22-4.76
				c-0.38,0.3-1.29,1.12-2.31,1.76c-1.14,0.72-2.74,2.07-3.48,1.73c-1.05-0.48-1.52-2.26-2.19-3.51c-0.85-1.58-1.64-3.19-2.89-5.64
				c0.45-0.1,1.6-0.42,2.78-0.61c3.78-0.59,7.55-1.18,11.34-1.67c1.5-0.19,3.35-0.68,4.5-0.05
				C1130.9,629.6,1130.84,629.7,1138.83,624.28z"/>
			<path onClick={() => props.onFill(225)} fill={props.fillColors[225]} d="M1016.99,383.81c-1.77,1.53-2.46,2.13-3.67,3.18c2.24,0.78,4.12,1.44,6.43,2.25
				c-0.82,1.02-1.46,1.81-2.26,2.81c1.24,1.34,2.5,2.7,3.35,3.62c-2.55,2.74-4.6,5.21-6.94,7.36c-2.54,2.33-12.68,0.14-14.27-2.92
				c-0.15-0.28-0.31-0.7-0.21-0.94c1.59-3.48,3.15-6.98,4.87-10.4c1.01-2.01,2.3-3.88,4.09-6.85
				C1009.91,382.26,1012.87,382.91,1016.99,383.81z"/>
			<path onClick={() => props.onFill(226)} fill={props.fillColors[226]} d="M1024.07,189.64c-5.74-0.62-11.47-1.57-17.22-1.72c-3.33-0.09-6.67,1.25-10.04,1.7
				c-2.08,0.27-4.31,0.37-6.31-0.11c-0.63-0.15-1.43-3.15-0.93-3.67c1.55-1.6,3.64-2.69,5.61-3.83c0.49-0.28,1.4-0.22,1.96,0.03
				c9.48,4.27,18.41,0.66,27.47-1.71c4.32-1.13,7.89,1.03,8.96,5.41c0.19,0.77-1.44,2.69-2.46,2.89c-2.24,0.45-4.63,0.14-6.96,0.14
				C1024.13,189.06,1024.1,189.35,1024.07,189.64z"/>
			<path onClick={() => props.onFill(227)} fill={props.fillColors[227]} d="M989.04,689.86c3.54,4.28,7.03,8.44,10.47,12.65c3.1,3.79,2.81,5.38-1.36,7.64
				c-2.84,1.54-5.84,4.31-8.54,4.06c-1.72-0.15-3.02-4.83-4.53-7.46c-1.73-3-3.49-5.97-5.09-8.69
				C983.15,695.19,986.06,692.56,989.04,689.86z"/>
			<path onClick={() => props.onFill(228)} fill={props.fillColors[228]} d="M983.97,498c-3.41,0.62-6.03,1.1-8.65,1.57c0-1.98,0-3.95,0-6.52c-2.47,0.28-5.28,0.78-8.11,0.85
				c-1.12,0.03-2.26-0.89-3.4-1.38c0.68-1.02,1.13-2.37,2.07-2.97c1.51-0.96,3.33-1.41,5.02-2.07c2.28-0.88,4.93,0.45,7.05-2.83
				c0.97-1.5,7.49-1.31,9.82,0.4c4.07,2.98,4.6,10.6,2.37,13.6C988.23,501.23,985.47,500.63,983.97,498z"/>
			<path onClick={() => props.onFill(229)} fill={props.fillColors[229]} d="M1063.07,486.03c5.5-0.38,11.01-0.76,16.51-1.12c0.63-0.04,1.64-0.18,1.84,0.14
				c2.89,4.51,6.34,1.5,10.08,0.44c0,6.21,0,12.06,0,18.97c-5.11-4.09-12.67-3.73-14.46-11.55c-0.22-0.96-2.19-2.47-2.78-2.24
				c-5.36,2.05-7.77-2.37-11.11-4.73C1063.15,485.93,1063.07,486.03,1063.07,486.03z"/>
			<path onClick={() => props.onFill(230)} fill={props.fillColors[230]} d="M1107,510.68c-1.66,0-3.02,0-5.07,0c2.57-7.71,5.03-15.07,7.66-22.95c2.4,3.26,4.68,6.34,7.53,10.21
				c-0.99,3.52-2.46,9.11-0.93,16.45c-1.96,1.18-4.94,2.97-8.65,5.21C1107.33,516.19,1107.17,513.53,1107,510.68z"/>
			<path onClick={() => props.onFill(231)} fill={props.fillColors[231]} d="M360.19,457.4c6.44,10.76,12.73,20.89,18.51,31.31c0.86,1.55,0.09,5.41-1.26,6.4
				c-1.35,0.99-5.32,0.59-6.46-0.7c-4.4-4.96-7.96-10.25-7.52-17.77c0.3-5.1-1.74-10.34-2.72-15.53
				C360.55,460.06,360.43,458.99,360.19,457.4z"/>
			<path onClick={() => props.onFill(232)} fill={props.fillColors[232]} d="M480.66,609.43c-1.71-3.79-3.86-6.59-4.1-9.55c-0.4-4.88,0.09-9.92,0.84-14.79
				c1.3-8.44,3.1-16.8,5.41-25.51c0.22,3.13,1.12,6.39,0.51,9.35c-1.66,8.19-1.5,15.68,4,22.66c3.42,4.34,2.43,9.62,2.1,14.59
				c-0.45,0.08-0.89,0.15-1.34,0.23c-0.46-1.81-0.7-3.71-1.45-5.39c-0.5-1.14-1.71-1.96-2.61-2.92c-0.83,1.11-2.1,2.11-2.41,3.35
				C481.04,603.86,480.99,606.41,480.66,609.43z"/>
			<path onClick={() => props.onFill(233)} fill={props.fillColors[233]} d="M774.51,422.3c5.32-2.3,10.12-4.38,14.92-6.46c2.24-0.98,4.4-2.48,6.74-2.83c2.77-0.41,5.69,0.16,8.54,0.3
				c0.22,0.68,0.45,1.36,0.67,2.04c-1.94,2.04-3.54,4.73-5.9,5.98c-5.52,2.94-11.37,5.27-17.15,7.69c-2.73,1.15-5.72,2.31-5.35-2.88
				C777.05,425.27,775.85,424.31,774.51,422.3z"/>
			<path onClick={() => props.onFill(234)} fill={props.fillColors[234]} d="M1111.58,316.09c-0.36-0.11-0.52-0.21-0.67-0.19c-3.14,0.34-7.31,4.09-8.54-2.23
				c-1.48-7.58,0.63-11.13,6.78-11.98c1.94-0.27,5.41-0.88,5.63-0.32c1.64,4.18,3.16,8.56,3.66,12.99c0.27,2.37-1.41,5.03-2.47,7.45
				c-0.46,1.06-1.57,1.83-2.39,2.74c-0.87-1.09-2.25-2.09-2.47-3.3C1110.8,319.59,1111.39,317.78,1111.58,316.09z"/>
			<path onClick={() => props.onFill(235)} fill={props.fillColors[235]} d="M1090.47,317.23c3.53-1.71,5.85-2.82,7.5-3.62c2.99,2.36,5.83,4.59,9.04,7.11c1.51,1.5,3.08,3.95,5.32,5.1
				c4.72,2.43,1.19,5.51,0.95,8.13c-0.29,3.16-2.92,2.59-5,1.8C1099.36,332.38,1096.09,323.91,1090.47,317.23z"/>
			<path onClick={() => props.onFill(236)} fill={props.fillColors[236]} d="M393.67,266.63c-12.75,3.04-22.91-4.31-34.55-7.04c5.41-3.3,10.22-6.36,15.18-9.14
				c0.75-0.42,2.95,0.1,3.24,0.75C380.83,258.47,386.16,263.53,393.67,266.63z"/>
			<path onClick={() => props.onFill(237)} fill={props.fillColors[237]} d="M660.3,399.44c11.37-6.57,18.2-16.67,24.17-27.37c3.82,0.93,5.22,2.45,3.22,5.74
				c-4.12,6.78-7.89,13.8-12.39,20.3C672.01,402.85,667.03,403.16,660.3,399.44z"/>
			<path onClick={() => props.onFill(238)} fill={props.fillColors[238]} d="M1178.5,470.76c1.55-2.67,3.67-5.17,4.46-8.05c0.63-2.28-0.38-5.01-0.67-7.68
				c5.55,0.72,10.15-1.63,12.49-8.13c0,9.45,0,18.91,0,29.54c-4.67-5.06-9.05-9.06-15.88-3.38
				C1178.77,472.29,1178.63,471.52,1178.5,470.76z"/>
			<path onClick={() => props.onFill(239)} fill={props.fillColors[239]} d="M959.6,721.46c-17.37,0.95-20.85,4.74-31.76-17.73C939.3,709.61,951.47,711.05,959.6,721.46z"/>
			<path onClick={() => props.onFill(240)} fill={props.fillColors[240]} d="M300.68,422.7c-0.56-3.38-1.13-6.75-1.69-10.13c-0.24,0.01-0.49,0.02-0.73,0.03c0,1.15,0.02,2.31,0,3.46
				c-0.05,2.17-0.13,4.35-0.2,6.52c-3.28-0.11-6.57-0.15-9.84-0.42c-0.45-0.04-1-1.24-1.14-1.97c-0.21-1.13-0.14-2.32-0.14-3.49
				c0-10.38,0.01-10.38,10.27-9.32c0.65,0.07,1.37,0.32,1.94,0.13c5.41-1.82,6.26,1.56,5.84,5.49c-0.37,3.49-1.53,6.89-2.35,10.33
				C301.98,423.13,301.33,422.92,300.68,422.7z"/>
			<path onClick={() => props.onFill(241)} fill={props.fillColors[241]} d="M600.39,293.18c0.76-4.32,1.51-8.64,2.2-12.57c2.69-0.53,4.64-0.92,6.6-1.31
				c-0.02-0.36-0.04-0.73-0.05-1.09c-1.65-0.22-3.29-0.43-6.3-0.83c1.8-1.24,2.8-2.51,3.69-2.44c2.47,0.2,4.9,0.93,7.34,1.52
				c0.2,0.05,0.33,0.56,0.41,0.88c1.09,4.39,2.17,8.79,3.25,13.18c-5.22,1.52-10.45,3.03-15.67,4.55
				C601.37,294.44,600.88,293.81,600.39,293.18z"/>
			<path onClick={() => props.onFill(242)} fill={props.fillColors[242]} d="M462.83,608.2c-2.16,6.83-11.09,13.2-17.49,12.75c-3.9-0.27-6.75-3.59-9.18-10.89
				C444.77,605.25,453.98,610.32,462.83,608.2z"/>
			<path onClick={() => props.onFill(243)} fill={props.fillColors[243]} d="M231.28,602.9c16.98-4.52,32.65-8.7,48.81-13c-12.3,7.16-23.83,15.02-37.55,17.66
				C239.35,608.17,235.39,604.71,231.28,602.9z"/>
			<path onClick={() => props.onFill(244)} fill={props.fillColors[244]} d="M692.92,486.64c0.71-5.96-5.99-11.67,2.18-15.53c3,5.29,4.75,11.86,2.11,16.47
				c-2.08,3.64-8.64,4.71-13.51,7.07c-0.94-4.1-1.74-7.46-2.47-10.84c-0.63-2.88,0.6-4.61,3.26-5.88c3.06-1.46,5.01-1.1,6.06,2.33
				C691.09,482.04,691.83,483.76,692.92,486.64z"/>
			<path onClick={() => props.onFill(245)} fill={props.fillColors[245]} d="M881.41,576.57c3.87,4.12,7.73,8.23,11.6,12.35c2.88,2.39,5.77,4.78,8.65,7.17
				c-0.42,0.55-0.84,1.09-1.26,1.64c-1.04-0.41-2.09-0.82-3.13-1.23c-3.96-1.54-7.93-3.04-11.87-4.65c-1.78-0.73-4.09-1.21-5.1-2.58
				c-3.38-4.58-7.5-7.34-13.28-7.54c-0.92-0.03-1.82-0.72-2.21-0.89c4.31-2.27,8.85-4.66,13.86-7.31
				C879.26,574.18,880.34,575.37,881.41,576.57z"/>
			<path onClick={() => props.onFill(246)} fill={props.fillColors[246]} d="M778.4,167.47c8.66-3.99,41.31-6.2,55.64-3c-5.35,2.82-10.69,5.63-16.63,8.76
				c-0.42-6.54-1.29-7.01-6.37-6.01c-2.19,0.43-4.53,0.08-6.79,0.1c-4.3,0.04-8.59,0.12-12.89,0.15
				C787.52,167.49,783.67,167.47,778.4,167.47z"/>
			<path onClick={() => props.onFill(247)} fill={props.fillColors[247]} d="M558.45,250.46c11.59-4.27,22.77-1.12,32.67-2.41c1.26,3.03,2.26,5.44,3.29,7.92
				C590.34,258.18,564.03,254.95,558.45,250.46z"/>
			<path onClick={() => props.onFill(248)} fill={props.fillColors[248]} d="M887.34,477.31c-7.59-6.81-15.03-13.48-22.46-20.16c0.25-0.5,0.51-0.99,0.76-1.49
				c5.73,1.86,12.86-2.39,17.4,4.95c0.55,0.9,1.77,1.56,2.83,1.94c3.44,1.24,3.79,3.87,3.25,6.85
				C888.63,472.12,887.92,474.78,887.34,477.31z"/>
			<path onClick={() => props.onFill(249)} fill={props.fillColors[249]} d="M413.9,612.85c1.64,2,3.29,4,4.93,5.99c-0.12,0.46-0.24,0.92-0.35,1.37c-2.88-0.45-5.76-0.9-8.65-1.35
				c0,0,0.12,0.12,0.12,0.12c-1.35-1.22-2.55-3.24-4.06-3.51c-6.34-1.12-12.78-1.66-19.17-2.52c-1.89-0.25-3.72-0.87-7.2-1.72
				c2.86-1.65,4.32-2.88,6-3.4c7.89-2.43,15.6-2.71,22.8,2.32c1.72,1.2,3.8,1.9,5.71,2.82C414.03,612.98,413.9,612.85,413.9,612.85z"
				/>
			<path onClick={() => props.onFill(250)} fill={props.fillColors[250]} d="M345.35,741.81c-3.41,2.93-6.8,5.93-10.28,8.81c-1.26,1.05-2.73,2.55-4.15,2.59
				c-5.76,0.16-9.96,2.71-13.3,7.56c-0.87-4.94,0.11-8.93,3.49-12.6C328.12,740.57,336.52,739.67,345.35,741.81z"/>
			<path onClick={() => props.onFill(251)} fill={props.fillColors[251]} d="M646.33,489.03c-0.45,5.66-0.86,10.81-1.32,16.53c-4.97-0.98-10.5-2.07-16.06-3.17
				C630.34,490.36,634.89,486.97,646.33,489.03z"/>
			<path onClick={() => props.onFill(252)} fill={props.fillColors[252]} d="M574.99,533.12c-5.18,7.11-10.37,14.22-15.68,21.51c-2.29-0.56-4.3-1.05-6.72-1.64
				c0.52-1.53,0.61-2.8,1.27-3.55c4.15-4.69,7.14-9.88,8.28-16.15c0.17-0.93,2.08-2.02,3.29-2.17c2.7-0.33,5.47-0.1,8.22-0.1
				C574.09,531.72,574.54,532.42,574.99,533.12z"/>
			<path onClick={() => props.onFill(253)} fill={props.fillColors[253]} d="M1019.91,454.9c-3.89,1.03-5.04,3.16-4.91,6.75c0.1,2.78-1.11,5.11-4.76,5.06
				c-1.73-0.02-3.47,0.83-5.51,1.37c-0.71-8.09-6.92-0.23-9.7-3.73c2.28-3.99,6.35-3.67,10.52-3.97c-0.17-1.43-0.31-2.66-0.48-4.09
				c-1.52,0-2.96,0-4.87,0c0.14-1.58-0.24-3.46,0.52-4.31c0.73-0.81,3.1-1.2,3.95-0.62c1.27,0.88,1.84,2.77,3.35,5.3
				c0.39-0.03,2.06-0.18,4.43-0.39c-1.61-2.27-2.61-3.68-4.13-5.82C1013.66,449.32,1016.33,452.96,1019.91,454.9z"/>
			<path onClick={() => props.onFill(254)} fill={props.fillColors[254]} d="M286.73,578.89c-0.3,0.48-0.38,0.71-0.46,0.71c-8.15,0.07-12.85,6.61-19.14,10.16
				c-8.47,4.79-17.96,6.28-27.41,7.89c7.58-3.77,15.3-7.3,22.69-11.42c3.46-1.93,6.64-4.67,9.29-7.63
				C274.95,574.95,279.7,574.7,286.73,578.89z"/>
			<path onClick={() => props.onFill(255)} fill={props.fillColors[255]} d="M878.7,454.49c-1.38-1.1-3.68-1.99-3.99-3.33c-2.92-12.64-3.36-25.47-2.22-38.37
				c0.11-1.22,0.86-2.38,1.42-3.84c0.68,0.52,1.27,0.75,1.33,1.06c1.26,7.31,2.5,14.63,3.63,21.96c0.23,1.46,0.45,3.17-0.08,4.45
				c-2.41,5.78-2.17,11.29,1.43,16.68C879.7,453.56,879.2,454.03,878.7,454.49z"/>
			<path onClick={() => props.onFill(256)} fill={props.fillColors[256]} d="M444.9,196.28c5.3-0.8,10.78-2.83,15.88-2.13c9.52,1.32,18.44-0.05,27.48-2.46
				c2.64-0.71,5.54-0.48,8.49-0.7c0,0.92,0.17,1.69-0.03,1.8c-7.45,4.22-14.48,8.8-24.08,6.58c-6.98-1.61-14.45-1.1-21.71-1.48
				c-1.98-0.1-3.96-0.02-5.94-0.02C444.96,197.35,444.93,196.82,444.9,196.28z"/>
			<path onClick={() => props.onFill(257)} fill={props.fillColors[257]} d="M341.94,390.65c0.2,7.81-0.27,8.44-6.13,8.57c-7.4,0.17-7.48,0.06-7.79-9.91c-0.16-5.14,1.2-5.85,8.6-6.41
				C343.73,382.37,341.33,387.5,341.94,390.65z"/>
			<path onClick={() => props.onFill(258)} fill={props.fillColors[258]} d="M529.03,234.26c-3.73,0.86-7.43,2.12-11.21,2.49c-9.21,0.91-18.45,1.54-27.69,1.98
				c-2.28,0.11-4.62-0.94-6.88-2.2c14.78-7.67,30.32-3.03,45.42-4.49C528.79,232.79,528.91,233.52,529.03,234.26z"/>
			<path onClick={() => props.onFill(259)} fill={props.fillColors[259]} d="M871.53,697.97c6.24,0.28,7.41,6.3,11.2,9c1.63,1.17,3.1,2.77,4.91,3.45c5.07,1.92,9.69,3.81,11.66,9.76
				c0.9,2.72,3.75,4.79,5.88,7.35c-3.22,2.28-5.88,3.21-8.37-1.21c-1.06-1.88-3.54-2.88-5-4.61c-5.82-6.91-11.48-13.96-17.24-20.93
				C873.87,699.92,872.93,699.25,871.53,697.97z"/>
			<path onClick={() => props.onFill(260)} fill={props.fillColors[260]} d="M598.18,691.39c-3.78-5.08-7.89-9.95-11.23-15.31c-3.06-4.91-5.6-9.5-12.52-9.66
				c-7.74-0.18-9.86-6.56-12.2-12.35c0.36-0.41,0.71-0.81,1.07-1.22c3.54,3.33,6.97,6.77,10.66,9.92c1.43,1.22,3.36,1.93,5.16,2.62
				c2.08,0.8,5.15,0.65,6.26,2.08c5.45,7.11,10.38,14.6,15.5,21.96C599.97,690.09,599.08,690.74,598.18,691.39z"/>
			<path onClick={() => props.onFill(261)} fill={props.fillColors[261]} d="M703.73,267.53c-6.29,10.8-1.17,24.82-10.54,34.31c-0.59-0.1-1.18-0.2-1.77-0.3
				c0.27-2.58,0-5.37,0.92-7.68c2.23-5.62,2.6-11.03,1.22-16.99C691.9,269.78,696.07,264.71,703.73,267.53z"/>
			<path onClick={() => props.onFill(262)} fill={props.fillColors[262]} d="M1091.57,609.21c-3.36-11.5,10.92-25.71,24.08-23.86c0.81,2.76,0.75,4.3-2.86,5.71
				c-3.6,1.4-6.45,4.74-9.61,7.25c-0.23,0.19-0.24,0.74-0.47,0.84c-2.58,1.11-5.61,1.65-7.64,3.39
				C1093.32,604.02,1092.73,606.84,1091.57,609.21z"/>
			<path onClick={() => props.onFill(263)} fill={props.fillColors[263]} d="M630.79,367.93c2.85-9.96,5.41-18.9,7.96-27.82c8.21,2.31,8.27,2.76,5.79,10.59
				c-1.94,6.12-2.79,12.74-9.86,15.54C633.65,366.65,632.64,367.12,630.79,367.93z"/>
			<path onClick={() => props.onFill(264)} fill={props.fillColors[264]} d="M505.83,479.59c0.54,1.02,0.72,1.22,0.68,1.28c-6.44,9.58-12.63,19.33-19.47,28.61
				c-2.59,3.51-7.15,3.33-11.1,2.99c5.01-5.82,9.62-11.42,14.5-16.78C495.34,490.29,500.51,485.13,505.83,479.59z"/>
			<path onClick={() => props.onFill(265)} fill={props.fillColors[265]} d="M1038.29,175.59c7.92,0,15.65,0.01,23.39,0c6.79-0.01,13.63,0.46,20.35-0.22c7.03-0.71,7.49-0.76,7.9,7.21
				c-3.15-0.77-5.83-1.56-3.95-5.43c-8.33,0.78-16.53,0.93-24.45,2.47C1053.14,181.24,1045.44,181.42,1038.29,175.59z"/>
			<path onClick={() => props.onFill(266)} fill={props.fillColors[266]} d="M411.69,700.22c6.02-9.68,12.62-18.77,24.53-22.86c1.08,2.22,2.13,4.36,3.11,6.36
				c-5.28,2.98-10.56,5.58-15.4,8.83c-2.61,1.75-4.32,4.77-6.73,6.89c-1.22,1.08-3.09,1.44-4.66,2.13
				C412.26,701.12,411.97,700.67,411.69,700.22z"/>
			<path onClick={() => props.onFill(267)} fill={props.fillColors[267]} d="M452.29,333.38c0.58,1.81,1.01,3.14,1.44,4.48c-2.75,0.65-5.48,1.51-8.27,1.84
				c-0.84,0.1-2.74-1.55-2.63-1.94c0.39-1.31,1.47-2.42,2.46-3.86c-0.46-0.37-1.55-1.23-3.01-2.38c1.76-0.83,3.04-1.44,3.41-1.61
				c-1.03-1.4-3.2-3.37-2.85-4.07c0.85-1.7,2.83-2.87,4.46-4.1c0.45-0.34,1.31-0.3,1.95-0.21c2.34,0.31,6.02-2.02,6.55,2.07
				C456.29,327.31,456.17,331.49,452.29,333.38z"/>
			<path onClick={() => props.onFill(268)} fill={props.fillColors[268]} d="M438.64,196.76c-2.26,1.48-4.39,3.95-6.79,4.25c-7.56,0.94-15.21,1.21-22.84,1.31
				c-1.02,0.01-2.77-2.39-2.89-3.79c-0.1-1.11,1.74-3.42,2.77-3.45c9.57-0.27,19.15-0.16,28.73-0.16
				C437.96,195.54,438.3,196.15,438.64,196.76z"/>
			<path onClick={() => props.onFill(269)} fill={props.fillColors[269]} d="M818.22,232.07c2.28-0.13,4.71-0.27,7.36-0.42c2.22,5.42,1.38,10.31-1.66,15.42
				c-3.8,6.38-8.24,1.38-12.17,1.67C813.89,243.23,815.95,237.9,818.22,232.07z"/>
			<path onClick={() => props.onFill(270)} fill={props.fillColors[270]} d="M1171.12,665.16c-10.49-8.66-10.14-21.05,0-30.21C1171.12,644.9,1171.12,654.38,1171.12,665.16z"/>
			<path onClick={() => props.onFill(271)} fill={props.fillColors[271]} d="M220.98,438.69c2.32-1.83,4.06-4.01,6.28-4.75c3.07-1.02,6.39-2.28,8.48,2.82
				c0.84,2.05,5.6,3.03,8.73,3.48c4.24,0.61,8.64,0.05,12.94,0.39c1.78,0.14,3.64,1.07,5.14,2.12c0.53,0.37,0.09,2.15,0.09,3.28
				c-1.85-0.01-3.75,0.27-5.54-0.07C245.61,443.73,234.16,441.36,220.98,438.69z"/>
			<path onClick={() => props.onFill(272)} fill={props.fillColors[272]} d="M1014.5,175.98c-4.24,1-5.19-1.52-5.29-5.59c-0.2-7.85,1.1-10.43,6.51-11.42c3.11-0.57,5.32-0.06,5.07,3.9
				c-0.12,1.81,0.07,3.68,0.39,5.47C1022.48,175.86,1022.5,175.88,1014.5,175.98z"/>
			<path onClick={() => props.onFill(273)} fill={props.fillColors[273]} d="M387.41,294.25c20.06-1.11,38.83-2.15,57.87-3.2c0.18,3.61-1.92,4.88-4.79,4.94
				c-5.98,0.11-11.96-0.17-17.94-0.06c-6.25,0.12-12.51,0.3-18.73,0.86C398.54,297.25,393.37,297.8,387.41,294.25z"/>
			<path onClick={() => props.onFill(274)} fill={props.fillColors[274]} d="M484.88,434.66c-0.51-12.97-6.37-25.35-3.76-38.44c0.65-0.14,1.3-0.27,1.95-0.41
				c0.56,1.53,1.34,3.02,1.65,4.6c1.86,9.38,3.33,18.84,5.54,28.14C491.39,433.3,489.66,434.53,484.88,434.66z"/>
			<path onClick={() => props.onFill(275)} fill={props.fillColors[275]} d="M782.94,186.41c-5.6-1.86-9.7-2.93-13.49-4.66c-1.15-0.52-2.47-3.56-1.99-4.25
				c2.04-2.96,4.27-6.38,7.29-7.88c3.02-1.5,6.58-0.21,6.95,4.42C781.98,177.61,782.4,181.16,782.94,186.41z"/>
			<path onClick={() => props.onFill(276)} fill={props.fillColors[276]} d="M809.68,413.55c-7.14-4.66-14.3-9.3-21.41-14c-2.07-1.37-3.3-3.3-0.89-5.2c2.22-1.76,4.61-5.39,7.86-1.52
				c5.32,6.33,10.41,12.86,15.6,19.3C810.45,412.6,810.06,413.08,809.68,413.55z"/>
			<path onClick={() => props.onFill(277)} fill={props.fillColors[277]} d="M597.88,564.5c-2.16-1.71-4.05-4.33-7.16-1.21c-0.45,0.45-2.8-1-5.1-1.89c-0.79,0.67-2.25,1.91-4.06,3.44
				c-0.4-1.78-0.75-3.37-1.43-6.42c5.99,0,11.56,0.01,17.12-0.01c0.71,0,1.62,0.09,2.11-0.28c5.75-4.39,10.74-0.77,17.09,1.13
				c-4.5,3.2-7.87,5.22-12.61,3.48c-1.56-0.57-3.61,0.21-5.44,0.38C598.23,563.57,598.06,564.04,597.88,564.5z"/>
			<path onClick={() => props.onFill(278)} fill={props.fillColors[278]} d="M1086.35,541.56c0-3.08,0.35-6.2-0.08-9.22c-0.78-5.55-1.33-11.29-3.27-16.47
				c-1.78-4.75-1.17-6.76,3.83-7.3c1.27-0.14,2.52-0.52,4.36-0.92c1.68,11.93-1.11,22.97-3.43,34.04
				C1087.29,541.64,1086.82,541.6,1086.35,541.56z"/>
			<path onClick={() => props.onFill(279)} fill={props.fillColors[279]} d="M342.27,492.32c-2.5,10.72-5.52,20.92-17.14,25.79c2.68-7.08,5.28-14.17,8.05-21.2
				C334.7,493.05,337.48,491.13,342.27,492.32z"/>
			<path onClick={() => props.onFill(280)} fill={props.fillColors[280]} d="M652.22,521.68c4.83-0.79,6.36,1.78,6.05,5.66c-0.25,3.1-1.18,6.15-1.7,9.23c-0.4,2.4-0.77,4.82-0.93,7.25
				c-0.28,4.2-0.31,8.41-0.59,12.61c-0.15,2.27-0.62,4.51-1.97,7.43c-0.68-1.4-1.98-2.81-1.95-4.2c0.23-11.72,0.7-23.43,1.09-35.14
				C652.25,523.56,652.22,522.58,652.22,521.68z"/>
			<path onClick={() => props.onFill(281)} fill={props.fillColors[281]} d="M716.01,227.57c2.36-1.28,48.05-1.76,52.99,0.67c-3.43,0.34-5.6,0.36-7.67,0.81
				c-3.81,0.84-7.53,2.25-11.37,2.84c-3.11,0.47-6.39,0.35-9.54-0.01c-3.1-0.36-6.11-1.51-9.2-2.04
				C725.9,228.93,720.54,228.24,716.01,227.57z"/>
			<path onClick={() => props.onFill(282)} fill={props.fillColors[282]} d="M823.68,391.95c-1.1-3.55-2.21-7.09-3.29-10.65c-0.81-2.67-1.61-5.35-2.36-8.04
				c-0.21-0.73-0.6-1.92-0.28-2.22c4.79-4.43,0.29-5.99-2.72-7.73c1.85-2.36,3.41-4.35,5.26-6.71c0.37,0.91,1.12,2.24,1.45,3.67
				c1.84,8.21,3.76,16.41,5.24,24.69c0.38,2.15-0.73,4.56-1.15,6.86C825.11,391.87,824.4,391.91,823.68,391.95z"/>
			<path onClick={() => props.onFill(283)} fill={props.fillColors[283]} d="M410.64,395.5c-0.8-2.89-1.98-5.74-2.31-8.68c-0.4-3.62,0.19-7.34-0.16-10.96c-0.51-5.22,1.68-7,7.67-5.79
				c0.22,8.39,0.05,16.87-3.42,25.01C411.82,395.21,411.23,395.36,410.64,395.5z"/>
			<path onClick={() => props.onFill(284)} fill={props.fillColors[284]} d="M383.9,164.43c-1.32,3.15-2.07,5.23-5.59,3.61c-1.37-0.63-3.56-0.11-5.16,0.48
				c-7.92,2.93-11.97,1.74-18.01-6.29C365.21,160.82,374.38,161.7,383.9,164.43z"/>
			<path onClick={() => props.onFill(285)} fill={props.fillColors[285]} d="M10.85,490.87c2.19-6.49,6.49-10.94,12.77-13.7c7.53-3.31,14.93-6.93,22.45-10.27
				c2.3-1.02,4.8-1.61,7.2-2.39c0.23,0.58,0.47,1.16,0.7,1.74c-11.47,6.01-23.01,11.9-34.37,18.12c-3.13,1.72-5.73,4.41-8.57,6.66
				C11.04,491.03,10.85,490.87,10.85,490.87z"/>
			<path onClick={() => props.onFill(286)} fill={props.fillColors[286]} d="M717.42,489.38c-0.91,0.48-2.67,0.94-3.72,2.06c-1.42,1.52-3.36,3.65-3.21,5.35
				c0.54,6.14-3.51,7.8-7.87,9.92c-2.15-3.38-1.34-5.62,0.95-8.22c2.03-2.3,3.21-5.36,4.74-8.1c0.24-0.42,0.27-0.95,0.49-1.39
				c1.8-3.65,3.61-7.28,5.66-11.4c1.11,0.89,2.84,2.26,4.11,3.28C718.16,483.94,717.83,486.39,717.42,489.38z"/>
			<path onClick={() => props.onFill(287)} fill={props.fillColors[287]} d="M287.33,400.3c-0.63-4.4-1.14-7.94-1.8-12.59c4.75,0.51,8.74,0.95,13.12,1.42
				c0.99,3.39,2.13,7.28,3.54,12.11C296.98,400.92,292.26,400.62,287.33,400.3z"/>
			<path onClick={() => props.onFill(288)} fill={props.fillColors[288]} d="M692.19,274.54c-0.29,5.59-0.47,11.19-0.92,16.77c-0.17,2.1-1.27,4.13-1.41,6.22
				c-0.23,3.42-2.32,3.47-4.82,3.27c-3.03-0.24-1.96-2.1-2.27-4.12c-1.34-8.81,6.8-14.14,6.82-22.29
				C690.46,274.45,691.33,274.5,692.19,274.54z"/>
			<path onClick={() => props.onFill(289)} fill={props.fillColors[289]} d="M453.83,503.23c-0.17,2.19-0.25,4.39-0.56,6.55c-0.16,1.12-0.95,2.16-1.09,3.28
				c-0.99,8-5.07,12.54-13.81,11.89c1.02-9.46,10.42-13.94,13.54-22.32C452.55,502.84,453.19,503.04,453.83,503.23z"/>
			<path onClick={() => props.onFill(290)} fill={props.fillColors[290]} d="M424.25,331.71c-0.49-2.96-0.91-5.49-1.48-8.93c6,0.28,11.25,0.37,16.44,0.95
				c0.77,0.09,1.69,2.51,1.79,3.91c0.11,1.7-0.57,3.46-1.04,5.86c-2.37,0-5.35,0-9.38,0c2.17-1.01,3.5-1.63,4.82-2.25
				c-0.04-0.2-0.08-0.4-0.12-0.61C431.87,330.97,428.46,331.3,424.25,331.71z"/>
			<path onClick={() => props.onFill(291)} fill={props.fillColors[291]} d="M1013.38,413.41c2.2,0.52,3.57,0.84,5.07,1.2c-1.25,2.09-2.35,3.92-3.38,5.65
				c-3.87-0.19-7.96-0.29-12.02-0.67c-0.92-0.09-2.5-1.22-2.47-1.8c0.12-2.06,0.13-4.98,1.41-5.95c2.31-1.76,5.43-2.81,8.36-3.3
				C1013.18,408.07,1016.22,408.65,1013.38,413.41z"/>
			<path onClick={() => props.onFill(292)} fill={props.fillColors[292]} d="M562.42,553.96c1.07-1.58,1.86-2.78,2.69-3.95c3.11-4.42,6.81-8.54,9.17-13.33c2-4.06,4.19-5.59,8.68-4.12
				c-0.28,0.72-0.5,1.49-0.87,2.18c-2.88,5.33-5.98,10.54-8.63,15.98C570.54,556.7,569.59,557.19,562.42,553.96z"/>
			<path onClick={() => props.onFill(293)} fill={props.fillColors[293]} d="M905.75,164.13c4.22-0.23,8.53-0.46,12.84-0.69c-0.01-0.54-0.03-1.09-0.04-1.63c-4.33,0-8.65,0-12.92,0
				c-0.11-0.7-0.21-1.3-0.19-1.31c4.59-0.95,9.18-1.98,13.82-2.57c0.43-0.05,1.5,2.67,1.79,4.2c0.68,3.52,1.06,7.1,1.71,11.68
				c-2.86-6.08-7.41-3.81-11.63-3.4C905.26,170.98,904.92,170.57,905.75,164.13z"/>
			<path onClick={() => props.onFill(294)} fill={props.fillColors[294]} d="M998.15,235.83c-9.37,1.7-21.03-5.23-22.73-12.58c-0.23-1-0.06-2.5,0.56-3.15
				c0.46-0.48,2.28-0.33,2.96,0.21C985.14,225.18,991.21,230.19,998.15,235.83z"/>
			<path onClick={() => props.onFill(295)} fill={props.fillColors[295]} d="M1072.51,245.42c-0.56,2.96-0.48,5.06-1.38,6.61c-0.81,1.4-3.16,3.41-4.01,3.08
				c-10.74-4.29-10.7-4.38-19.57-3.76c1.2-1.7,1.81-3.56,2.83-3.82c3.2-0.81,6.58-0.86,9.83-1.52c2.21-0.45,4.28-1.89,6.47-2.04
				C1068.61,243.84,1070.6,244.9,1072.51,245.42z"/>
			<path onClick={() => props.onFill(296)} fill={props.fillColors[296]} d="M680.88,717.35c3.55-1.24,7.82-4.3,10.47-3.26c2.99,1.18,4.53,6.05,6.66,9.27
				c-3.05,2.57-3.05,2.57-11.89-2.81c0.55,2.1,0.96,3.64,1.46,5.53c-2.37,0.43-4.52,0.83-6.7,1.23
				C680.88,724.06,680.88,721.15,680.88,717.35z"/>
			<path onClick={() => props.onFill(297)} fill={props.fillColors[297]} d="M345.87,492.48c-1.36,13.85-2.57,26.29-3.89,38.72c-0.05,0.43-1.28,0.73-2.05,1.14
				C336.72,517.58,338.22,506.67,345.87,492.48z"/>
			<path onClick={() => props.onFill(298)} fill={props.fillColors[298]} d="M630.45,396.44c2.96-8.41,6.35-16.7,8.73-25.28c1.55-5.57,5.03-8.18,10.76-10.35
				c-0.72,1.84-1.1,2.96-1.59,4.04c-3.56,7.83-6.93,15.76-10.81,23.44c-1.64,3.24-4.36,5.94-6.59,8.88
				C630.78,396.93,630.62,396.69,630.45,396.44z"/>
			<path onClick={() => props.onFill(299)} fill={props.fillColors[299]} d="M210.81,414.91c1.13,12.78,8.63,24.68,4.99,38C213.05,440.36,207.19,428.25,210.81,414.91z"/>
			<path onClick={() => props.onFill(300)} fill={props.fillColors[300]} d="M263.87,264.73c-3.68,0.58-7.36,1.13-11.03,1.74c-1.96,0.33-4.2,0.25-5.79,1.22
				c-6.76,4.13-13.27,3-19.97-0.52c11.83-6.55,24.42-3.04,36.65-3.67C263.78,263.91,263.83,264.32,263.87,264.73z"/>
			<path onClick={() => props.onFill(301)} fill={props.fillColors[301]} d="M161.14,501.75c-12.97,11.57-17.96,14.67-24.78,14.87c4.38-5.27,8.3-10.15,12.45-14.81
				c0.68-0.77,2.45-0.82,3.7-0.78C155.38,501.13,158.25,501.49,161.14,501.75z"/>
			<path onClick={() => props.onFill(302)} fill={props.fillColors[302]} d="M775.48,638.69c-7.09,1.61-13.88-0.89-20.68-3.62c-1.78-0.71-3.78-1.48-5.6-1.33
				c-5.9,0.49-9.21-2.6-11.07-8.68c6.19,2.31,12.37,4.65,18.57,6.91C762.99,634.27,769.3,636.48,775.48,638.69z"/>
			<path onClick={() => props.onFill(303)} fill={props.fillColors[303]} d="M662.14,700.54c1.03,2.16,1.87,3.94,2.78,5.84c-4.53,5.11-10.03,5.6-17.01,1.3
				c-0.87-3.08,8.15-3.01,1.74-7.83c0.26-0.37,0.53-0.74,0.79-1.11C654.47,699.36,658.49,699.98,662.14,700.54z"/>
			<path onClick={() => props.onFill(304)} fill={props.fillColors[304]} d="M1095.61,426.35c-2.96,0.13-5.58,0.25-9.22,0.41c1.16-1.88,1.57-2.92,2.3-3.65
				c3.18-3.17,3.01-5.85-0.56-8.73c-2.51-2.03-4.78-4.47-6.63-7.1c-0.57-0.81,0.17-3.36,1.12-4.21c1.58-1.43,4.02-2.48,5.21,0.65
				c1.97,5.21,3.7,10.51,5.51,15.78C1094.03,421.48,1094.67,423.49,1095.61,426.35z"/>
			<path onClick={() => props.onFill(305)} fill={props.fillColors[305]} d="M364.2,544.19c5.04,2.08,7.42,4.42,8.9,8.63c1.19,3.4,4.19,6.39,6.93,8.97c3.7,3.48,7.92,6.4,11.97,9.61
				c-5.71,0.66-15.4-4.44-17.87-9.12C371.1,556.55,367.89,550.9,364.2,544.19z"/>
			<path onClick={() => props.onFill(306)} fill={props.fillColors[306]} d="M1013.52,435.34c-2.77,4.31-1.75,7.21,3.12,9.77c-4.67,1.74-7.97,3.32-11.45,4.08
				c-1.28,0.28-4.38-1.74-4.28-2.12c0.82-3.24,1.79-6.59,3.58-9.34c0.6-0.91,3.91,0.25,4.47-2.65
				C1008.99,434.91,1011.45,435.21,1013.52,435.34z"/>
			<path onClick={() => props.onFill(307)} fill={props.fillColors[307]} d="M1079.06,342.64c2.04-1.65,7.85-0.99,6.36-7c8-0.56,14.46,1.69,20.54,8.68
				C1096.15,343.71,1087.38,343.16,1079.06,342.64z"/>
			<path onClick={() => props.onFill(308)} fill={props.fillColors[308]} d="M433.2,575.5c6.08,3.17,10.13,12.41,7.74,17.43c-0.59,1.24-3.17,2.03-4.85,2.04
				c-0.96,0.01-1.95-1.96-2.9-3.05c-0.08-0.09,0.01-0.32,0.01-0.49C433.2,586.34,433.2,581.23,433.2,575.5z"/>
			<path onClick={() => props.onFill(309)} fill={props.fillColors[309]} d="M602.85,770.4c-4.59,10.36-13.68,16.2-22.93,15.1c0-0.32-0.11-0.85,0.02-0.92
				C587.79,780.49,592.77,771.63,602.85,770.4z"/>
			<path onClick={() => props.onFill(310)} fill={props.fillColors[310]} d="M614.58,352.67c0.21,0.55,0.52,1.09,0.6,1.66c0.81,5.84,1.45,11.71,2.38,17.53
				c1.11,6.99-4.56,8.15-8.78,11.12c0.91-5.49,1.78-10.48,2.57-15.48c0.77-4.89,1.47-9.8,2.2-14.69
				C613.89,352.76,614.23,352.71,614.58,352.67z"/>
			<path onClick={() => props.onFill(311)} fill={props.fillColors[311]} d="M682.04,280.02c-3.32,6.68-4.42,7.09-16.04,4.98c9.2-2.34,11.08-8.71,12.74-15.38
				c0.72-2.91,2.89-4.29,5.25-3.64c-0.72,4.94-1.39,9.56-2.06,14.19L682.04,280.02z"/>
			<path onClick={() => props.onFill(312)} fill={props.fillColors[312]} d="M1003.37,206.76c14.64,0,28.95,0,43.26,0C1042.65,210.27,1005.76,209.64,1003.37,206.76z"/>
			<path onClick={() => props.onFill(313)} fill={props.fillColors[313]} d="M249.05,590.57c3.29-4.68,5.65-9.7,9.5-12.98c2.7-2.3,7.42-2.23,11.23-3.21c0.36,0.54,0.72,1.08,1.08,1.62
				c-1.93,2.16-3.5,4.91-5.87,6.36C260.12,585.36,254.81,587.67,249.05,590.57z"/>
			<path onClick={() => props.onFill(314)} fill={props.fillColors[314]} d="M711.32,272.93c0.54,5.54,1.26,11.07,1.51,16.62c0.08,1.73-0.76,3.85-1.91,5.14
				c-0.51,0.57-3.55,0.03-4.16-0.88c-0.9-1.33-1.01-3.47-0.8-5.19c0.49-4.07,1.24-8.12,2.16-12.12c0.35-1.5,1.47-2.83,2.24-4.24
				C710.68,272.48,711,272.7,711.32,272.93z"/>
			<path onClick={() => props.onFill(315)} fill={props.fillColors[315]} d="M589.42,512.94c2.49-1.65,4.25-2.82,5.98-3.97c-1.78-1.96-4.16-3.64-3.89-4.44
				c1.02-3.05,2.89-5.82,4.45-8.69c1.12,0.62,3.16,1.16,3.22,1.86c0.36,4.43,0.38,8.9,0.3,13.35
				C599.41,515.11,596.63,515.86,589.42,512.94z"/>
			<path onClick={() => props.onFill(316)} fill={props.fillColors[316]} d="M348.63,741.57c5.1-2.39,8.95-5.2,13.13-5.87c4.16-0.67,8.67,0.81,12.71,1.31
				C370.82,741.56,358.15,744.41,348.63,741.57z"/>
			<path onClick={() => props.onFill(317)} fill={props.fillColors[317]} d="M1171.55,526.64c-1.45-3.82-2.46-6.49-3.61-9.52c5.45-9.25,5.45-9.25,12.48-4.27
				C1177.59,517.24,1174.75,521.66,1171.55,526.64z"/>
			<path onClick={() => props.onFill(318)} fill={props.fillColors[318]} d="M443.16,582.15c7.12,1.04,10.65,5.54,14.52,11.29c-5.28,0-9.63,0-14.52,0
				C443.16,589.95,443.16,586.29,443.16,582.15z"/>
			<path onClick={() => props.onFill(319)} fill={props.fillColors[319]} d="M763.81,404.3c2.45,5.83,4.77,11.34,7.08,16.85c-6.24,3.44-7.67,2.75-8.82-4.04
				c-0.47-2.77-1.51-5.45-1.95-8.22C759.52,405.08,760.51,404.21,763.81,404.3z"/>
			<path onClick={() => props.onFill(320)} fill={props.fillColors[320]} d="M317.54,525.68c-2.98-4.42-1.92-8.61,0.02-11.81c3.25-5.38,7.55-10.12,11.69-15.49
				C326.23,507.88,324.64,517.66,317.54,525.68z"/>
			<path onClick={() => props.onFill(321)} fill={props.fillColors[321]} d="M420.05,369.33c0.53,7.98,2.14,15.93-1.69,23.78c-1.29,2.64-1.26,5.92-2.07,10.19
				c2.19-1.27,3.04-1.76,4.04-2.34c0,2.61,0,5.07,0,8.76c-3.89-5.65-7.42-9.44-4.77-16.3c2.13-5.51,1.73-12,2.43-18.06
				c0.24-2.05,0.42-4.11,0.63-6.17C419.1,369.25,419.57,369.29,420.05,369.33z"/>
			<path onClick={() => props.onFill(322)} fill={props.fillColors[322]} d="M427.42,568.64c4.6,3.14,3.91,7.81,4.18,11.96c0.22,3.28-0.19,6.65-0.75,9.91
				c-0.18,1.07-1.7,1.92-2.61,2.86c-1.09-1.08-3.19-2.24-3.11-3.24C425.63,583.08,426.58,576.04,427.42,568.64z"/>
			<path onClick={() => props.onFill(323)} fill={props.fillColors[323]} d="M881.14,173.43c12.51-0.5,25.01-1,37.52-1.51c0.13,0.68,0.25,1.37,0.38,2.05
				c-1.83,0.64-3.67,1.84-5.5,1.83c-10.83-0.1-21.65-0.47-32.48-0.76C881.08,174.51,881.11,173.97,881.14,173.43z"/>
			<path onClick={() => props.onFill(324)} fill={props.fillColors[324]} d="M719.11,468.15c-0.16-0.82-0.65-2.01-0.38-2.21c4.81-3.65,7.24-9.96,14.43-11.07
				c8.69-1.34,17.19-3.92,25.77-5.96c0.1,0.42,0.21,0.84,0.31,1.26c-2.46,0.61-4.95,1.13-7.37,1.84c-7.6,2.25-15.19,4.52-22.74,6.92
				c-0.99,0.31-2.05,1.25-2.52,2.18C724.39,465.45,724.46,465.48,719.11,468.15z"/>
			<path onClick={() => props.onFill(325)} fill={props.fillColors[325]} d="M765.05,291.71c-2.78-4.76-8.51-8.35-5.33-15.96c1.75,0.19,3.65,0.37,5.54,0.59
				c3.5,0.41,4.23,2.45,3.38,5.57c-0.86,3.14-1.57,6.32-2.35,9.49C765.88,291.5,765.47,291.61,765.05,291.71z"/>
			<path onClick={() => props.onFill(326)} fill={props.fillColors[326]} d="M396.4,369c8.06-2.79,10.47-1.2,10.49,6.37c0.01,3.05,0,6.11,0,9.16c-0.72,0.18-1.43,0.36-2.15,0.54
				C402.02,379.84,399.3,374.6,396.4,369z"/>
			<path onClick={() => props.onFill(327)} fill={props.fillColors[327]} d="M180.59,430.61c-0.65,7.63-1.27,14.94-1.9,22.26c-0.52,0.03-1.03,0.06-1.55,0.09
				c-1.28-5.48-2.95-10.92-3.69-16.47C172.98,432.8,174.23,429.33,180.59,430.61z"/>
			<path onClick={() => props.onFill(328)} fill={props.fillColors[328]} d="M577.06,560.48c-7.03,3.68-13.96,3.08-20.88,1.09c-0.94-0.27-2.22-2.15-2.01-2.92
				c0.29-1.05,1.76-2.28,2.9-2.48c1.53-0.27,3.25,0.26,4.84,0.63C566.84,557.94,571.75,559.18,577.06,560.48z"/>
			<path onClick={() => props.onFill(329)} fill={props.fillColors[329]} d="M696.57,502.56c-4.04,0.36-8.08,0.9-12.11,0.83c-0.42-0.01-1.68-4.77-1.02-5.26
				c2.79-2.07,6.06-4.12,9.36-4.51c1.22-0.14,2.99,4.3,4.5,6.65C697.06,501.04,696.82,501.8,696.57,502.56z"/>
			<path onClick={() => props.onFill(330)} fill={props.fillColors[330]} d="M1177.34,152.29c-10.09,4.64-20.44,2.56-30.6,3.27c-0.23-0.52-0.47-1.03-0.7-1.55
				c1.33-0.77,2.64-2.15,4-2.2c7.28-0.26,14.56-0.24,21.85-0.21C1173.71,151.62,1175.52,152.05,1177.34,152.29z"/>
			<path onClick={() => props.onFill(331)} fill={props.fillColors[331]} d="M477.81,341.39c-6.98,1.83-13.93,3.75-20.95,5.37c-0.94,0.22-2.23-1.11-3.36-1.72
				c0.99-1.48,1.87-4.13,3-4.24c7.01-0.7,14.08-0.83,21.12-1.14C477.68,340.23,477.74,340.81,477.81,341.39z"/>
			<path onClick={() => props.onFill(332)} fill={props.fillColors[332]} d="M673.73,340.95c-9.15,1.71-15.57,7.97-22.6,13.21c-0.41-0.24-0.81-0.49-1.22-0.73
				c0.41-2.62,0.58-5.32,1.35-7.84c0.3-1,2.08-2.42,2.91-2.26c5.22,1.04,9.73-0.28,14.09-3.09c1.25-0.8,3.08-0.69,4.65-0.99
				C673.18,339.81,673.46,340.38,673.73,340.95z"/>
			<path onClick={() => props.onFill(333)} fill={props.fillColors[333]} d="M739.06,436.56c0.75-2.77,0.73-6.35,2.07-6.96c3.85-1.77,8.21-2.63,12.46-3.21
				c1.53-0.21,3.31,1.42,4.98,2.21c-0.1,0.52-0.2,1.04-0.31,1.56C752.07,432.23,745.87,434.29,739.06,436.56z"/>
			<path onClick={() => props.onFill(334)} fill={props.fillColors[334]} d="M343.08,383.85c8.81-0.38,9.78,1.02,8.52,11.22c-6.44,2.54-6.5,2.56-7.78-4.08
				C343.37,388.67,343.32,386.26,343.08,383.85z"/>
			<path onClick={() => props.onFill(335)} fill={props.fillColors[335]} d="M325.44,500.19c-3.53,4.27-7.05,8.53-10.58,12.8c-0.63-0.18-1.27-0.36-1.9-0.54c0-3.25-1.31-7.7,0.31-9.45
				c2.46-2.66,6.95-3.44,10.57-5.02C324.37,498.72,324.9,499.46,325.44,500.19z"/>
			<path onClick={() => props.onFill(336)} fill={props.fillColors[336]} d="M347.09,425.95c-0.65-4.01-1.55-8.01-1.81-12.04c-0.11-1.64,0.75-4.26,1.95-4.84
				c1.59-0.77,4.85-0.72,5.7,0.34c0.97,1.21,0.53,4.07-0.12,5.93c-1.26,3.6-3.09,6.99-4.69,10.47
				C347.77,425.86,347.43,425.91,347.09,425.95z"/>
			<path onClick={() => props.onFill(337)} fill={props.fillColors[337]} d="M984.16,196.99c0.3-1.58,0.09-3.98,1.02-4.56c1.37-0.85,3.81-1.04,5.32-0.4
				c5.43,2.33,7.34,7.48,9.29,12.29c-5.27-2.5-10.51-4.98-15.75-7.46C984.04,196.86,984.16,196.99,984.16,196.99z"/>
			<path onClick={() => props.onFill(338)} fill={props.fillColors[338]} d="M916.61,297.33c-1.22,2.86-2.98,5.52-3.4,8.38c-0.41,2.85-1.13,4.74-4.04,3.88
				c-0.85-0.25-1.27-3.02-1.32-4.66c-0.04-1.59,0.65-3.2,1.03-4.8c-0.65-0.18-1.31-0.36-1.96-0.54c-0.84,3.02-1.68,6.04-2.7,9.69
				C903.39,298.31,905.34,295.76,916.61,297.33z"/>
			<path onClick={() => props.onFill(339)} fill={props.fillColors[339]} d="M299.18,441.94c-0.71,4.67-0.56,7.98-1.92,10.48c-0.51,0.95-5.46,0.94-6.67-0.31
				c-1.64-1.7-1.64-4.99-2.34-7.6C291.27,443.8,294.3,443.09,299.18,441.94z"/>
			<path onClick={() => props.onFill(340)} fill={props.fillColors[340]} d="M689.8,383.68c16.83,4.29,33.02,7.6,49.7,4.27C728.4,393.09,694.76,390.61,689.8,383.68z"/>
			<path onClick={() => props.onFill(341)} fill={props.fillColors[341]} d="M871.63,410.07c-1.8,0.71-3.6,1.41-6.11,2.4c1.86,0.37,3.16,0.63,4.42,0.88
				c-0.61,4.81-1.76,5.21-10.67,3.5c1.07-3.17,1.29-7.32,3.46-9.13c1.73-1.44,5.84-0.03,8.89,0.11
				C871.63,408.58,871.63,409.32,871.63,410.07z"/>
			<path onClick={() => props.onFill(342)} fill={props.fillColors[342]} d="M576.57,504.6c4.05-4.15,3.12-11.94,11.2-14.03c-0.82,4.01-1.57,7.01-2.02,10.06
				C584.82,506.82,583.04,507.76,576.57,504.6z"/>
			<path onClick={() => props.onFill(343)} fill={props.fillColors[343]} d="M1070.41,327.01c0.11-1.98,0.18-3.23,0.29-5.25c5.84,2.69,13.96-5.14,17.78,6.34
				C1082.31,327.73,1076.7,327.39,1070.41,327.01z"/>
			<path onClick={() => props.onFill(344)} fill={props.fillColors[344]} d="M715.98,453.43c7.31-4.68,16.64-6.8,19.28-17.4c0.33,0.47,0.66,0.95,0.99,1.42
				c0.15,2.19,0.52,4.39,0.35,6.55c-0.09,1.11-0.88,2.71-1.79,3.13c-6.03,2.78-12.18,5.3-18.3,7.91
				C716.34,454.51,716.16,453.97,715.98,453.43z"/>
			<path onClick={() => props.onFill(345)} fill={props.fillColors[345]} d="M216.61,573.04c4.54-5.41,8.14-9.71,11.75-14.01c2.26-2.68,4.77-2.47,8.23-0.28
				C231.02,564.69,225.24,569.59,216.61,573.04z"/>
			<path onClick={() => props.onFill(346)} fill={props.fillColors[346]} d="M287.31,440.84c1.15-2.83,1.53-6.5,3.48-7.75c1.72-1.11,5.31,0.12,7.78,1.08
				c0.73,0.28,1.04,3.32,0.55,4.75C298.1,441.86,295,442.32,287.31,440.84z"/>
			<path onClick={() => props.onFill(347)} fill={props.fillColors[347]} d="M1124.12,319.69c1.16-2.42,2.31-4.83,3.69-7.71c3.58,2.8,6.72,3.37,9.98,0.22
				c0.57-0.55,2.7,0.52,4.11,0.84c-0.64,1.27-1,3.35-1.96,3.66c-4.8,1.6-9.76,2.73-14.66,4.03
				C1124.9,320.39,1124.51,320.04,1124.12,319.69z"/>
			<path onClick={() => props.onFill(348)} fill={props.fillColors[348]} d="M853.88,263.85c-3.23-4.24-6.45-8.48-9.68-12.72c0.21-0.59,0.43-1.17,0.64-1.76
				c2.41,0.47,5.44,0.2,7.11,1.57c3.82,3.15,4.43,7.67,3.38,12.29C854.85,263.45,854.37,263.65,853.88,263.85z"/>
			<path onClick={() => props.onFill(349)} fill={props.fillColors[349]} d="M367.8,393.58c-4.25,0.97-8.49,1.95-13.22,3.03c0-3.98,0-6.69,0-10.8c4.89,2.13,8.94,3.89,12.99,5.66
				C367.65,392.18,367.73,392.88,367.8,393.58z"/>
			<path onClick={() => props.onFill(350)} fill={props.fillColors[350]} d="M355,520.4c4.49-6.89,3.39-16.26,11.25-23.31c-1.87,7.84-3.17,14.31-5.07,20.6
				c-0.51,1.69-2.86,2.84-4.36,4.23C356.21,521.41,355.6,520.9,355,520.4z"/>
			<path onClick={() => props.onFill(351)} fill={props.fillColors[351]} d="M996.4,399.51c-0.82,5.73-1.64,11.45-2.47,17.18c-0.52,0.07-1.03,0.14-1.55,0.21
				c-1.28-4.55-3.11-9.05-3.53-13.68c-0.13-1.46,3.48-3.26,5.37-4.9C994.95,398.71,995.68,399.11,996.4,399.51z"/>
			<path onClick={() => props.onFill(352)} fill={props.fillColors[352]} d="M715.15,290.52c-0.54-4.34-1.43-8.69-1.39-13.03c0.01-1.2,2.75-2.38,4.24-3.57
				c0.84,1.33,2.52,2.77,2.36,3.97c-0.58,4.32-1.78,8.55-2.75,12.81C716.79,290.65,715.97,290.59,715.15,290.52z"/>
			<path onClick={() => props.onFill(353)} fill={props.fillColors[353]} d="M1021.31,177.38c-9.06,6.66-18.45,4.96-28.66,1.12C1002.49,174.26,1012.21,181.62,1021.31,177.38z"/>
			<path onClick={() => props.onFill(354)} fill={props.fillColors[354]} d="M151.27,207.91c-3.51,7.11-15.65,8.11-20.68,1.73C137.58,209.06,144.29,208.5,151.27,207.91z"/>
			<path onClick={() => props.onFill(355)} fill={props.fillColors[355]} d="M435.81,370.11c0.37,6.78,0.74,13.43,1.11,20.07c-0.45,0.12-0.89,0.24-1.34,0.36
				c-1.83-5.51-4.06-10.93-5.34-16.56C429.3,369.9,432.42,369.58,435.81,370.11z"/>
			<path onClick={() => props.onFill(356)} fill={props.fillColors[356]} d="M981.79,235.25c-8.28-0.03-12.76-3.56-14.46-11.55c3.22-1.49,5.38-1.32,7.34,2.43
				C976.42,229.45,979.32,232.17,981.79,235.25z"/>
			<path onClick={() => props.onFill(357)} fill={props.fillColors[357]} d="M1087.62,172.36c5.35-3.15,7.4-8.07,7.74-14.02c0.13-2.27,0.62-4.52,2.02-6.59
				c2.36,9.15-2.09,17.01-4.18,24.82c-1.98-1.53-3.73-2.89-5.5-4.23C1087.69,172.34,1087.62,172.36,1087.62,172.36z"/>
			<path onClick={() => props.onFill(358)} fill={props.fillColors[358]} d="M578.28,512.08c-0.57,2.5-1.15,5.01-1.89,8.24c-0.71,0.13-2.26,0.88-3.49,0.53
				c-2.06-0.6-5.52-1.84-5.56-2.92c-0.13-3.34,1.27-6.61,5.7-6.43c1.8,0.07,3.58,0.45,5.37,0.68L578.28,512.08z"/>
			<path onClick={() => props.onFill(359)} fill={props.fillColors[359]} d="M895.38,159.22c2.71,0,4.79,0,7.21,0c0,3.68,0,7.08,0,10.55c-5.52,2.23-6.32,1.74-6.77-4.26
				C895.67,163.57,895.55,161.61,895.38,159.22z"/>
			<path onClick={() => props.onFill(360)} fill={props.fillColors[360]} d="M675.72,512.7c2.28,3.74,1.85,4.8-2.42,5.23c-3.26,0.32-6.51,1.06-9.76,1.09
				c-1.47,0.01-3.95-0.96-4.25-1.98c-0.76-2.61,1.36-3.03,3.6-3.16C667.1,513.64,671.29,513.13,675.72,512.7z"/>
			<path onClick={() => props.onFill(361)} fill={props.fillColors[361]} d="M1075.61,517.65c5.02,6.39,4.28,13.74,4.14,21.11C1074.46,534.63,1072.12,522.9,1075.61,517.65z"/>
			<path onClick={() => props.onFill(362)} fill={props.fillColors[362]} d="M693.24,514.66c2.83-1.92,4.55-4.03,6.38-4.13c4.04-0.21,3.52,3.69,3.99,6.2c0.61,3.24-1.51,5.8-4.39,4.95
				C697.13,521.07,695.81,517.84,693.24,514.66z"/>
			<path onClick={() => props.onFill(363)} fill={props.fillColors[363]} d="M768.06,435.86c-1.91-4-3.33-6.96-5.24-10.95c3.57-0.35,6.63-0.66,10.79-1.07
				c-1.06,1.49-1.4,2.12-1.89,2.6c-0.4,0.39-0.98,0.6-1.21,0.73c1.68,1.05,3.13,1.96,4.59,2.87
				C773.06,431.73,771.02,433.42,768.06,435.86z"/>
			<path onClick={() => props.onFill(364)} fill={props.fillColors[364]} d="M618.43,365.24c-0.8-6.53-1.62-13.06-2.38-19.6c-0.11-0.96,0.28-1.98,0.13-2.93
				c-0.79-5.04-0.37-5.44,3.76-4.51c0,9.18,0,18.09,0,27C619.44,365.21,618.94,365.23,618.43,365.24z"/>
			<path onClick={() => props.onFill(365)} fill={props.fillColors[365]} d="M757.27,405.89c1.2,5.3,2.43,10.59,3.51,15.91c0.08,0.39-1.02,1.44-1.58,1.45
				c-0.86,0.02-2.28-0.39-2.51-0.99c-2.01-5.19-3.49-10.48-1.91-15.96C755.61,406.15,756.44,406.02,757.27,405.89z"/>
			<path onClick={() => props.onFill(366)} fill={props.fillColors[366]} d="M708.28,268.43c-1.79,7.63-3.57,15.26-5.36,22.88c-2.33-8.43-1.3-16.18,3.46-23.2
				C707.02,268.22,707.65,268.33,708.28,268.43z"/>
			<path onClick={() => props.onFill(367)} fill={props.fillColors[367]} d="M863.79,660.58c7.94,2.76,15.89,5.53,23.83,8.29c-0.14,0.42-0.28,0.84-0.42,1.26
				c-8.93,0.2-16.84-2.67-23.98-7.88C863.42,661.69,863.61,661.14,863.79,660.58z"/>
			<path onClick={() => props.onFill(368)} fill={props.fillColors[368]} d="M648.57,520.04c-5.79-1.17-11.21-2.27-16.62-3.37c0.12-0.64,0.24-1.27,0.36-1.91
				c4.39,0.56,8.78,1.12,13.17,1.68c0.09-0.4,0.17-0.79,0.26-1.19c-3.98-0.96-7.96-1.92-11.96-2.89
				C640.98,509.29,649.04,513.38,648.57,520.04z"/>
			<path onClick={() => props.onFill(369)} fill={props.fillColors[369]} d="M564.73,528.59c0.98-7.28,2.49-8.07,10.18-5.3C576.79,529.65,572.54,530.97,564.73,528.59z"/>
			<path onClick={() => props.onFill(370)} fill={props.fillColors[370]} d="M304.53,439.37c1.31-1.42,1.63-2.06,1.96-2.07c6.72-0.16,13.45-0.41,20.16-0.2
				c1.02,0.03,1.96,2.27,3.59,4.29C320.82,440.66,313.13,440.05,304.53,439.37z"/>
			<path onClick={() => props.onFill(371)} fill={props.fillColors[371]} d="M438.21,222.77c-5.69,5.44-10.57,10.09-15.44,14.74c-0.19-0.27-0.37-0.55-0.56-0.82
				c4.35-6.06,8.71-12.11,13.06-18.17c0.55,0.21,1.1,0.42,1.65,0.63C437.45,220.61,437.96,222.08,438.21,222.77z"/>
			<path onClick={() => props.onFill(372)} fill={props.fillColors[372]} d="M881.44,734.37c0.85-4.95,1.54-8.99,2.24-13.03c0.51-0.03,1.01-0.06,1.52-0.09
				c1.43,4.14,2.85,8.28,4.52,13.12C887.09,734.37,884.52,734.37,881.44,734.37z"/>
			<path onClick={() => props.onFill(373)} fill={props.fillColors[373]} d="M620.94,286.5c-1.96-3.97-4.1-7.86-5.77-11.95c-0.43-1.05,0.59-2.69,0.94-4.05c1.46,0.65,3.98,1.06,4.19,2
				c1.05,4.51,1.47,9.16,2.12,13.76C621.93,286.34,621.43,286.42,620.94,286.5z"/>
			<path onClick={() => props.onFill(374)} fill={props.fillColors[374]} d="M688.67,524.66c-4.3-7.59-3.37-10.35,3.61-11.49C695.02,522.83,695.02,522.83,688.67,524.66z"/>
			<path onClick={() => props.onFill(375)} fill={props.fillColors[375]} d="M273.06,174.64c8.33-0.99,15.97-1.9,24.08-2.86C291.5,177.46,279.01,179,273.06,174.64z"/>
			<path onClick={() => props.onFill(376)} fill={props.fillColors[376]} d="M1163.07,471.31c2.3,0.21,4.84,0.44,7.67,0.7c0,2.5,0,4.89,0,7.35c-3.13,1.64-6.25,3.27-9.37,4.9
				c-0.15-0.29-0.31-0.57-0.46-0.86c1.56-1.69,3.99-3.19,4.43-5.14C1165.76,476.44,1164.08,474.13,1163.07,471.31z"/>
			<path onClick={() => props.onFill(377)} fill={props.fillColors[377]} d="M462.11,325.23c-1.26,0.99-2.23,1.76-3.21,2.53c-0.37-0.84-1.24-1.92-0.98-2.46
				c0.47-0.96,1.58-2.19,2.49-2.25c3.73-0.25,7.49-0.11,11.24-0.11c0.31,0.51,0.61,1.02,0.92,1.54c-1.26,1.51-2.4,4.11-3.82,4.28
				C466.22,329.05,462.49,329.76,462.11,325.23z"/>
			<path onClick={() => props.onFill(378)} fill={props.fillColors[378]} d="M681.94,280.15c1.79-3.93,3.35-8,5.5-11.73c0.69-1.21,2.91-1.54,4.98-2.54
				c-3.36,7.04-6.17,12.93-9.64,20.2c-0.36-3.03-0.54-4.55-0.73-6.07C682.04,280.02,681.94,280.15,681.94,280.15z"/>
			<path onClick={() => props.onFill(379)} fill={props.fillColors[379]} d="M614.26,257.55c-1.19,1.77-2.7,5.19-3.51,5.03c-3.89-0.76-7.6-2.49-11.37-3.88
				c0.18-0.48,0.35-0.96,0.53-1.44c4.44-0.39,8.89-0.77,13.33-1.16C613.59,256.59,613.92,257.07,614.26,257.55z"/>
			<path onClick={() => props.onFill(380)} fill={props.fillColors[380]} d="M365.19,396.76c-3.1,5.36-5.66,9.81-8.23,14.26c-1.44-4.11-4.18-8.33,2.8-10.29
				c-1.66-1.05-2.64-1.68-4.08-2.59C358.96,397.66,361.85,397.24,365.19,396.76z"/>
			<path onClick={() => props.onFill(381)} fill={props.fillColors[381]} d="M1047.51,199.61c8.41,0,16.81,0,25.22,0C1064.26,201.14,1055.79,204.19,1047.51,199.61z"/>
			<path onClick={() => props.onFill(382)} fill={props.fillColors[382]} d="M842.65,148.42c4.14-0.19,8.27-0.38,13.46-0.62c-1.22,3.27-2.11,5.69-3.09,8.31
				c-3.97-2.5-7.23-4.55-10.5-6.6C842.57,149.15,842.61,148.79,842.65,148.42z"/>
			<path onClick={() => props.onFill(383)} fill={props.fillColors[383]} d="M502.69,478.46c-4.41,5.54-8.82,11.08-13.22,16.62c-0.45-0.35-0.9-0.69-1.35-1.04
				c3.09-5.18,6.03-10.47,9.41-15.45c0.72-1.06,3.05-1.04,4.64-1.52C502.33,477.54,502.51,478,502.69,478.46z"/>
			<path onClick={() => props.onFill(384)} fill={props.fillColors[384]} d="M1182.04,504.61c8.69-5.09,8.69-5.09,12.92-0.29C1190.8,511.36,1186.43,507.99,1182.04,504.61z"/>
			<path onClick={() => props.onFill(385)} fill={props.fillColors[385]} d="M672.64,721.19c1.44,1.2,3.56,2.14,4.17,3.67c1.15,2.9,2.27,6.34-1.06,8.77
				c-0.72,0.52-2.33,0.33-3.25-0.11c-0.55-0.27-0.76-1.64-0.79-2.53c-0.1-2.78-0.04-5.56-0.04-8.35
				C671.99,722.15,672.31,721.67,672.64,721.19z"/>
			<path onClick={() => props.onFill(386)} fill={props.fillColors[386]} d="M371.65,472.23c6.84,0.7,8.15,6.38,11.46,9.56c0.62,0.59-0.83,3.33-1.33,5.09
				c-0.43,0.06-0.87,0.13-1.3,0.19C377.7,482.41,374.93,477.75,371.65,472.23z"/>
			<path onClick={() => props.onFill(387)} fill={props.fillColors[387]} d="M229.85,418.01c-4.19,2.76-8.37,5.51-12.87,8.47c-1.43-5.85,5.61-10.83,12.12-9.83
				C229.35,417.11,229.6,417.56,229.85,418.01z"/>
			<path onClick={() => props.onFill(388)} fill={props.fillColors[388]} d="M780.72,434.48c-2.61,1.12-4.02,1.51-5.16,2.28c-0.93,0.64-2.34,1.94-2.18,2.61
				c1.8,7.32-4.3,4.9-8.03,7.57C770.78,435,773.03,433.04,780.72,434.48z"/>
			<path onClick={() => props.onFill(389)} fill={props.fillColors[389]} d="M272.86,600.18c-5.65,4.99-12.71,6.69-19.74,8.44c-0.2-0.44-0.39-0.88-0.59-1.33
				c6.49-3.04,12.98-6.09,19.48-9.13C272.29,598.84,272.57,599.51,272.86,600.18z"/>
			<path onClick={() => props.onFill(390)} fill={props.fillColors[390]} d="M422.99,586.63c-1.12-0.73-1.03-0.73-0.95-9.96c0.03-3.86-0.43-7.72-0.71-12.22
				C425.17,567.85,425.69,579.52,422.99,586.63z"/>
			<path onClick={() => props.onFill(391)} fill={props.fillColors[391]} d="M237.21,300.47c1.55-2.47,2.41-3.85,3.27-5.22c1.09,0.76,2.08,1.81,3.29,2.23
				c4.6,1.61,9.28,3.02,13.95,5.47C251.39,302.18,245.07,301.42,237.21,300.47z"/>
			<path onClick={() => props.onFill(392)} fill={props.fillColors[392]} d="M709.73,484.17c-5.15,3-1.15,11.93-8.61,13.56C698.66,490.89,699.02,490.3,709.73,484.17z"/>
			<path onClick={() => props.onFill(393)} fill={props.fillColors[393]} d="M289.21,424.89c8.31-1.93,9.75-0.92,8.25,5.56C289.21,430.94,289.21,430.94,289.21,424.89z"/>
			<path onClick={() => props.onFill(394)} fill={props.fillColors[394]} d="M89.95,627.89c-0.25,0.52-0.5,1.05-0.76,1.57c-5.58-3.56-11.16-7.11-17.55-11.19
				c5.56-2.78,7.68-0.07,10.13,2.44c2.56,2.63,5.51,4.88,8.28,7.3C90.07,628.01,89.95,627.89,89.95,627.89z"/>
			<path onClick={() => props.onFill(395)} fill={props.fillColors[395]} d="M578.41,530.24c-0.11-0.18-1.53-1.42-1.47-2.57c0.15-2.75,1.27-5.4,4.84-4.6
				c1.36,0.31,3.63,1.78,3.54,2.48C584.97,528.34,584.09,531.28,578.41,530.24z"/>
			<path onClick={() => props.onFill(396)} fill={props.fillColors[396]} d="M363.89,516.32c1.75-6.11,3.5-12.22,5.61-19.57c2.36,4.88-0.79,16.92-4.07,19.95
				C364.92,516.58,364.41,516.45,363.89,516.32z"/>
			<path onClick={() => props.onFill(397)} fill={props.fillColors[397]} d="M683.87,507.09c4.2-0.93,7.53-1.66,10.88-2.4c1.08,4.68-0.19,5.85-8.95,7.78
				C685.25,510.94,684.67,509.33,683.87,507.09z"/>
			<path onClick={() => props.onFill(398)} fill={props.fillColors[398]} d="M658.42,356.89c-4,6.73-8.01,13.46-12.01,20.19c-0.5-0.3-1.01-0.59-1.51-0.89
				c4.78-6.28,5.01-15.22,11.92-20.25C657.36,356.26,657.89,356.57,658.42,356.89z"/>
			<path onClick={() => props.onFill(399)} fill={props.fillColors[399]} d="M698.16,301.92c4.5-7.96,5.64-8.33,12.1-2.98c-4.1,1.73-7.73,3.26-11.35,4.79
				C698.65,303.13,698.41,302.52,698.16,301.92z"/>
			<path onClick={() => props.onFill(400)} fill={props.fillColors[400]} d="M1194.74,425.75c0.48-5.43-4.46-5.78-8.33-8.06c2.7-1.32,5.08-1.88,6.47-3.36c0.92-0.98,0.43-3.29,0.57-5
				c0.43,0.02,0.86,0.04,1.29,0.06C1194.74,414.84,1194.74,420.3,1194.74,425.75z"/>
			<path onClick={() => props.onFill(401)} fill={props.fillColors[401]} d="M1159.78,643.36c-0.57-3.37-1.77-6.39-1.25-9.08c0.26-1.32,3.64-2.34,5.76-2.8
				c0.62-0.14,1.76,2.1,1.85,4.1c-1.17-0.79-2.33-1.58-3.67-2.49C1160.4,636.27,1167.15,640.42,1159.78,643.36z"/>
			<path onClick={() => props.onFill(402)} fill={props.fillColors[402]} d="M663.55,721.67c0.95,0.07,3.55-0.18,3.72,0.35c0.97,3,4.16,5.98,0.62,9.66c-1.83-0.87-3.78-1.8-5.32-2.54
				C662.86,726.9,663.13,724.87,663.55,721.67z"/>
			<path onClick={() => props.onFill(403)} fill={props.fillColors[403]} d="M234.51,401.27c-0.7,5.67-0.7,5.67-12.14,6.34c3.66-2.62,7.32-5.25,10.97-7.87
				C233.73,400.25,234.12,400.76,234.51,401.27z"/>
			<path onClick={() => props.onFill(404)} fill={props.fillColors[404]} d="M1095.63,414.73c-1.67-3.48-3.35-6.95-5.02-10.43c0.06-0.07,0.13-0.13,0.19-0.2
				c0.96,1.56,1.92,3.11,2.89,4.67c0.67-0.31,1.34-0.63,2.01-0.94c1.05-2.7,2.09-5.39,3.14-8.09c0.4,0.11,0.8,0.22,1.2,0.32
				c-1.02,4.85-2.05,9.71-3.07,14.56C1096.51,414.66,1096.07,414.69,1095.63,414.73z"/>
			<path onClick={() => props.onFill(405)} fill={props.fillColors[405]} d="M1099.46,494.87c-2.77-6.92-2.03-10.95,2.61-14.17C1103.95,484.42,1102.97,489.7,1099.46,494.87z"/>
			<path onClick={() => props.onFill(406)} fill={props.fillColors[406]} d="M721.13,285.84c0.35-3.24,0.38-6.57,1.23-9.67c0.27-0.99,2.74-1.38,4.2-2.04
				c0.28,1.24,1.15,2.76,0.72,3.67c-1.37,2.92-3.22,5.62-4.89,8.4C721.97,286.08,721.55,285.96,721.13,285.84z"/>
			<path onClick={() => props.onFill(407)} fill={props.fillColors[407]} d="M669.1,506.92c2.15,0.96,5.39-3.41,6.39,1.06c0.17,0.74-3.85,2.94-6.17,3.58
				c-2.6,0.72-5.48,0.42-8.18-0.41c2.69-1.45,5.39-2.9,8.08-4.35C669.23,506.79,669.1,506.92,669.1,506.92z"/>
			<path onClick={() => props.onFill(408)} fill={props.fillColors[408]} d="M586.35,506.06c1.14-4.51,2.28-9.02,3.78-14.91c1.7,2.18,3.46,3.58,3.21,4.24
				c-1.51,4-3.47,7.82-5.28,11.71C587.49,506.74,586.92,506.4,586.35,506.06z"/>
			<path onClick={() => props.onFill(409)} fill={props.fillColors[409]} d="M759.7,513.88c1.93,4.9,3.86,9.79,5.79,14.69c-7.52-2.47-6.08-8.89-7.68-13.96
				C758.44,514.37,759.07,514.12,759.7,513.88z"/>
			<path onClick={() => props.onFill(410)} fill={props.fillColors[410]} d="M231.92,412.24c-5.04,1.93-10.09,3.85-15.33,5.85C219.4,412.57,225.78,409.53,231.92,412.24z"/>
			<path onClick={() => props.onFill(411)} fill={props.fillColors[411]} d="M1086.46,617.69c-0.55,4.68-1.01,8.52-1.47,12.36c-0.4,0.17-0.79,0.35-1.19,0.52
				c-1.48-1.9-2.96-3.8-4.88-6.28c2.31-0.43,3.79-0.71,5.94-1.11c-1.35-1.77-2.38-3.1-3.71-4.85
				C1082.78,618.14,1084.11,617.97,1086.46,617.69z"/>
			<path onClick={() => props.onFill(412)} fill={props.fillColors[412]} d="M308.24,402.74c6.04-0.45,12.09-0.9,18.58-1.38C323.48,405.04,314.2,405.97,308.24,402.74z"/>
			<path onClick={() => props.onFill(413)} fill={props.fillColors[413]} d="M139.96,441.4c0.81-3.16,1.43-6.4,2.56-9.45c0.33-0.9,2.14-1.25,3.27-1.85c0.34,1.09,1.29,2.48,0.92,3.23
				c-1.49,3.04-3.38,5.88-5.13,8.8C141.04,441.89,140.5,441.64,139.96,441.4z"/>
			<path onClick={() => props.onFill(414)} fill={props.fillColors[414]} d="M775.88,583.65c4.86,4.16,9.49,8.53,13.2,13.68c-0.62,0.54-1.25,1.08-1.87,1.61
				c-4.16-4.77-8.32-9.54-12.48-14.31C775.11,584.31,775.5,583.98,775.88,583.65z"/>
			<path onClick={() => props.onFill(415)} fill={props.fillColors[415]} d="M706.79,303.49c7.39-3.81,7.39-3.81,12.76,4.63C715.26,306.56,711.51,305.2,706.79,303.49z"/>
			<path onClick={() => props.onFill(416)} fill={props.fillColors[416]} d="M809.55,417c-5.72,5.94-11.01,12.55-19.61,12.5C796.18,425.53,802.86,421.26,809.55,417z"/>
			<path onClick={() => props.onFill(417)} fill={props.fillColors[417]} d="M600.84,508.55c0.43-3.83,0.75-7.68,1.4-11.48c0.12-0.7,1.62-1.16,2.48-1.74c0.43,0.8,1.37,1.72,1.22,2.4
				c-0.81,3.78-1.88,7.51-2.86,11.25C602.33,508.84,601.59,508.69,600.84,508.55z"/>
			<path onClick={() => props.onFill(418)} fill={props.fillColors[418]} d="M817.37,226.99c-1.85,6.1-3.21,10.6-4.58,15.1c-0.5-0.09-1-0.18-1.49-0.26c0.43-4.25,0.71-8.53,1.45-12.72
				C812.87,228.4,814.97,228.04,817.37,226.99z"/>
			<path onClick={() => props.onFill(419)} fill={props.fillColors[419]} d="M423.28,329.85c-1.87,1.42-2.91,2.21-3.96,3c-1.01-1.76-2.43-3.44-2.86-5.33
				c-0.19-0.84,1.62-2.12,2.53-3.21C420.22,325.9,421.45,327.49,423.28,329.85z"/>
			<path onClick={() => props.onFill(420)} fill={props.fillColors[420]} d="M275.79,302.4c8.04-0.48,16.08-0.95,25.25-1.5C292.01,305.48,283.76,303.73,275.79,302.4z"/>
			<path onClick={() => props.onFill(421)} fill={props.fillColors[421]} d="M685.3,391.69c-1.36,3.81-2.72,7.63-4.25,11.93c-1.8-0.52-3.23-0.93-5.11-1.48
				c3.07-4.08,5.76-7.66,8.45-11.24C684.69,391.17,685,391.43,685.3,391.69z"/>
			<path onClick={() => props.onFill(422)} fill={props.fillColors[422]} d="M1159.38,429.5c1.93-0.73,3.09-1.17,5.35-2.02c-0.24,2.41,0.24,4.96-0.7,5.69
				c-2.54,1.98-4.68,0.46-5.51-2.32c-0.8-2.7-1.14-5.53-1.68-8.31c0.44-0.09,0.88-0.18,1.31-0.28
				C1158.53,424.46,1158.9,426.65,1159.38,429.5z"/>
			<path onClick={() => props.onFill(423)} fill={props.fillColors[423]} d="M284.58,536.69c-4.29-2.65-4.25-5.05,0.82-15.3C285.14,526.32,284.87,531.25,284.58,536.69z"/>
			<path onClick={() => props.onFill(424)} fill={props.fillColors[424]} d="M602.03,398.84c-6.25,0.35-12.5,0.71-18.75,1.03c-0.41,0.02-0.83-0.31-1.98-0.76
				C588.64,395.31,595.45,397.55,602.03,398.84z"/>
			<path onClick={() => props.onFill(425)} fill={props.fillColors[425]} d="M1006.64,162.02c0,5.15,0,9.1,0,13.26C1002.22,172.51,1001.93,166.88,1006.64,162.02z"/>
			<path onClick={() => props.onFill(426)} fill={props.fillColors[426]} d="M334.09,486.98c-3.01,1.6-5.22,2.78-8.75,4.67c0.23-3.69,0.38-6.12,0.58-9.41
				C328.88,483.95,331.06,485.22,334.09,486.98z"/>
			<path onClick={() => props.onFill(427)} fill={props.fillColors[427]} d="M153.68,497.79c-3.74-3.06-3.53-9.02,1.23-15.47C154.51,487.25,154.12,492.17,153.68,497.79z"/>
			<path onClick={() => props.onFill(428)} fill={props.fillColors[428]} d="M601.25,262.34c3.77,2.7,8.66,0.93,11.78,5.61C603.92,268.84,602.26,268.11,601.25,262.34z"/>
			<path onClick={() => props.onFill(429)} fill={props.fillColors[429]} d="M871.52,406.3c-2.5-0.47-5.09-0.71-7.45-1.58c-0.6-0.22-0.43-2.48-0.62-3.81
				c2.08,0.19,4.29,0.01,6.18,0.71c1.2,0.44,1.92,2.19,2.85,3.36C872.16,405.42,871.84,405.86,871.52,406.3z"/>
			<path onClick={() => props.onFill(430)} fill={props.fillColors[430]} d="M647.34,511.27c-4.7-0.9-8.76-1.67-13.66-2.61c0.65-1.14,0.97-2.54,1.75-2.87
				C637.54,504.9,645.74,508.38,647.34,511.27z"/>
			<path onClick={() => props.onFill(431)} fill={props.fillColors[431]} d="M1166.63,509.16c1.75-6.27,7.43-2.03,9.47-4.67c2.54,1.87,4.59,3.37,7.74,5.69
				C1177.45,509.32,1172.26,504.43,1166.63,509.16z"/>
			<path onClick={() => props.onFill(432)} fill={props.fillColors[432]} d="M888.14,721.55c2.36,1.94,4.59,3.54,6.51,5.45c0.82,0.81,1.44,2.31,1.31,3.39
				c-0.08,0.63-1.9,1.58-2.79,1.45c-1.03-0.16-2.39-1.17-2.75-2.13C889.43,727.15,888.9,724.41,888.14,721.55z"/>
			<path onClick={() => props.onFill(433)} fill={props.fillColors[433]} d="M454.18,259.71c-4.2,0.68-7.15,1.16-10.37,1.68C444.9,254.53,446.39,254.2,454.18,259.71z"/>
			<path onClick={() => props.onFill(434)} fill={props.fillColors[434]} d="M581.66,518.18c1.64-3.57,3.18-6.92,5.16-11.23c0.42,4.23,1.36,7.21,0.81,9.89
				C586.97,520.09,584.34,519.7,581.66,518.18z"/>
			<path onClick={() => props.onFill(435)} fill={props.fillColors[435]} d="M426.08,222.4c2.03-3.71,4.07-7.42,6.47-11.8C434.89,215.01,434.89,215.01,426.08,222.4z"/>
			<path onClick={() => props.onFill(436)} fill={props.fillColors[436]} d="M802.05,637.27c-6.25,2.54-6.25,2.54-14.21-3.84C792.13,634.59,796.42,635.75,802.05,637.27z"/>
			<path onClick={() => props.onFill(437)} fill={props.fillColors[437]} d="M388.02,719.77c-2.07,3.98-4.08,8-6.29,11.9c-0.39,0.68-1.68,0.85-3.59,0.44
				c2.88-4.42,5.75-8.84,8.63-13.26C387.18,719.15,387.6,719.46,388.02,719.77z"/>
			<path onClick={() => props.onFill(438)} fill={props.fillColors[438]} d="M352.69,400.4c-2.42,1.02-4.83,2.04-7.25,3.06c-0.38-0.99-1.2-2.8-1.09-2.86c2.28-1.2,4.67-2.21,7.03-3.25
				C351.82,398.37,352.25,399.39,352.69,400.4z"/>
			<path onClick={() => props.onFill(439)} fill={props.fillColors[439]} d="M589.46,516.61c2.85-0.21,5.7-0.42,9.71-0.72c-0.9,2.02-1.42,4.18-1.93,4.17
				c-2.61-0.01-5.21-0.58-7.81-0.95C589.43,518.28,589.45,517.45,589.46,516.61z"/>
			<path onClick={() => props.onFill(440)} fill={props.fillColors[440]} d="M219.25,437.05c-3.25-8.23-3.11-8.56,4.27-9.27C222.07,430.91,220.88,433.51,219.25,437.05z"/>
			<path onClick={() => props.onFill(441)} fill={props.fillColors[441]} d="M325.83,260.39c6.35-0.79,12.69-1.57,19.04-2.36c0.04,0.35,0.09,0.69,0.13,1.04
				c-6.33,1.04-12.66,2.08-18.98,3.12C325.95,261.6,325.89,260.99,325.83,260.39z"/>
			<path onClick={() => props.onFill(442)} fill={props.fillColors[442]} d="M578.42,512.18c-1.61-1.04-3.21-2.09-4.82-3.13c0.01-0.48,0.02-0.96,0.03-1.44
				c2.7,0.21,5.42,0.34,8.1,0.72c0.4,0.06,0.64,1.28,0.95,1.96c-1.47,0.6-2.95,1.19-4.41,1.79
				C578.28,512.08,578.42,512.18,578.42,512.18z"/>
			<path onClick={() => props.onFill(443)} fill={props.fillColors[443]} d="M673.51,737.21c-3.53,0-7.07,0.12-10.59-0.09c-0.79-0.05-1.52-1.26-2.27-1.93
				c0.9-0.51,1.91-1.58,2.68-1.42c3.47,0.69,6.87,1.73,10.29,2.65C673.59,736.68,673.55,736.94,673.51,737.21z"/>
			<path onClick={() => props.onFill(444)} fill={props.fillColors[444]} d="M588.09,524.36c3.8,0,7.6,0,11.62,0C596.13,527.78,592.22,528.04,588.09,524.36z"/>
			<path onClick={() => props.onFill(445)} fill={props.fillColors[445]} d="M598.66,530.41c-3.8,0-7.6,0-11.4,0c0-0.47,0-0.93-0.01-1.4c3.8,0,7.6,0,11.4,0
				C598.66,529.48,598.66,529.95,598.66,530.41z"/>
			<path onClick={() => props.onFill(446)} fill={props.fillColors[446]} d="M391.84,435.15c-2.06-3.06-4.13-6.12-6.59-9.77C392.02,425.93,391.96,430.51,391.84,435.15z"/>
			<path onClick={() => props.onFill(447)} fill={props.fillColors[447]} d="M1181.21,500.07c-3.37,1.32-5.74,2.25-8.1,3.18c-0.4-0.36-0.81-0.71-1.21-1.07
				c1.14-1.72,2.28-3.43,3.5-5.27C1177.36,497.98,1178.77,498.74,1181.21,500.07z"/>
			<path onClick={() => props.onFill(448)} fill={props.fillColors[448]} d="M890.28,178.4c-3.62,0.43-6.72,0.8-10.54,1.26C882.64,175.34,884.89,175.18,890.28,178.4z"/>
			<path onClick={() => props.onFill(449)} fill={props.fillColors[449]} d="M901.78,298.71c-1.31,9.6-1.31,9.6-5.36,9.81C898.36,304.97,900.07,301.84,901.78,298.71z"/>
			<path onClick={() => props.onFill(450)} fill={props.fillColors[450]} d="M303.22,502.23c4.66-2.18,8.5-3.98,12.34-5.78c0.19,0.44,0.37,0.88,0.56,1.32
				C312.29,499.62,309.88,504.92,303.22,502.23z"/>
			<path onClick={() => props.onFill(451)} fill={props.fillColors[451]} d="M869.21,399.6c-2.28-0.73-4.57-1.46-6.85-2.19c0.38-1.14,0.77-2.27,1.15-3.41
				c2.18,1.1,4.35,2.19,6.53,3.29C869.76,398.06,869.48,398.83,869.21,399.6z"/>
			<path onClick={() => props.onFill(452)} fill={props.fillColors[452]} d="M1172.6,626.74c-0.93,1.71-1.86,3.42-2.79,5.13c-1.13-1.03-2.26-2.07-3.39-3.1
				c1.37-1.19,2.73-2.38,4.1-3.56C1171.22,625.72,1171.91,626.23,1172.6,626.74z"/>
			<path onClick={() => props.onFill(453)} fill={props.fillColors[453]} d="M609.83,569.1c0.54,4.21,1.08,8.42,1.62,12.63c-0.51,0.08-1.02,0.17-1.54,0.25
				c-0.84-4.16-1.68-8.33-2.52-12.49C608.21,569.36,609.02,569.23,609.83,569.1z"/>
			<path onClick={() => props.onFill(454)} fill={props.fillColors[454]} d="M823.36,192.4c-2.65,0.16-5.31,0.47-7.96,0.41c-1.04-0.02-2.07-0.86-3.1-1.33
				c0.83-0.62,1.73-1.84,2.48-1.75c3.09,0.37,6.12,1.14,9.18,1.77C823.76,191.8,823.56,192.1,823.36,192.4z"/>
			<path onClick={() => props.onFill(455)} fill={props.fillColors[455]} d="M1110.03,577.33c2.24-2.15,4.18-4.02,6.13-5.88C1115.19,578.97,1115.19,578.97,1110.03,577.33z"/>
			<path onClick={() => props.onFill(456)} fill={props.fillColors[456]} d="M1001.35,702.06c-2.36-3.65-4.09-6.32-5.82-8.99c0.46-0.28,0.91-0.56,1.37-0.83
				C998.5,695.05,1003.96,695.44,1001.35,702.06z"/>
			<path onClick={() => props.onFill(457)} fill={props.fillColors[457]} d="M329.3,402.03c4.29-0.52,7.69-1.05,11.12-1.26c0.73-0.04,1.54,1.08,2.31,1.66
				c-0.74,0.43-1.53,1.3-2.22,1.23C337.04,403.29,333.6,402.68,329.3,402.03z"/>
			<path onClick={() => props.onFill(458)} fill={props.fillColors[458]} d="M110.65,388.91c1.67-2.7,3.34-5.39,5.01-8.09c0.61,0.37,1.23,0.74,1.84,1.11
				c-1.57,2.79-3.14,5.58-4.7,8.38C112.09,389.84,111.37,389.38,110.65,388.91z"/>
			<path onClick={() => props.onFill(459)} fill={props.fillColors[459]} d="M1115.26,518.21c0.31,1.86,0.62,3.71,0.93,5.57c-1.14,0.03-2.27,0.06-3.41,0.09c0-1.79,0-3.58,0-5.37
				C1113.61,518.4,1114.43,518.31,1115.26,518.21z"/>
			<path onClick={() => props.onFill(460)} fill={props.fillColors[460]} d="M1101.11,407.88c0.29-3.33,0.9-6.33,7.16-5.11c-2.5,2.63-4.28,4.5-6.06,6.36
				C1101.84,408.72,1101.47,408.3,1101.11,407.88z"/>
			<path onClick={() => props.onFill(461)} fill={props.fillColors[461]} d="M305.43,456.57c-4.78-6.48-5.38-9.11-3.21-13.63C303.28,447.45,304.22,451.46,305.43,456.57z"/>
			<path onClick={() => props.onFill(462)} fill={props.fillColors[462]} d="M12.04,291.86c3.36-0.47,6.72-0.95,10.08-1.42c0.09,0.45,0.18,0.9,0.27,1.35
				c-4.88,1.15-9.76,2.29-14.64,3.44c-0.05-0.19-0.1-0.39-0.16-0.58c1.52-0.97,3.05-1.93,4.57-2.9
				C12.17,291.74,12.04,291.86,12.04,291.86z"/>
			<path onClick={() => props.onFill(463)} fill={props.fillColors[463]} d="M612.11,273.2c-3-0.28-6-0.55-9-0.83c0.09-0.88,0.18-1.75,0.26-2.63c3.03,0.32,6.06,0.65,9.09,0.97
				C612.34,271.54,612.22,272.37,612.11,273.2z"/>
			<path onClick={() => props.onFill(464)} fill={props.fillColors[464]} d="M1000.68,163.15c0,3.62,0,7.24,0,10.87c-0.3-0.01-0.6-0.03-0.91-0.04c0-3.63,0-7.27,0-10.9
				C1000.08,163.1,1000.38,163.12,1000.68,163.15z"/>
			<path onClick={() => props.onFill(465)} fill={props.fillColors[465]} d="M846.16,101.41c1.62-1.33,3.25-2.66,4.87-3.98c2.76,0.39,5.52,0.77,8.28,1.16
				c4.94-6.96,7.53-7.19,13.64-1.26c0.48,0.46,0.93,0.98,1.48,1.33c2.84,1.86,8.29,0.86,9.89-1.9c2.03-3.5,0.49-6.3-2.53-7.96
				c-1.3-0.72-3.46,0.13-5.23,0.27c0.53,1.68,1.07,3.36,1.6,5.03c0.43-0.16,0.86-0.32,1.29-0.48c0.2-0.94,0.4-1.87,0.6-2.81
				c0.49-0.01,0.99-0.02,1.48-0.03c0.15,1.51,0.91,3.41,0.26,4.4c-0.56,0.86-2.72,0.98-4.15,0.92c-5.34-0.25-5.89-3.87-5.34-8.08
				c0.74-5.73,5.09-9.66,11.25-10.09c8.64-0.61,16.66,1.02,23.44,7.71c-0.11-7.29,1.79-11.42,5.51-10.9
				c4.27,0.59,8.31,2.84,12.37,4.33c0.81-4.33,3.22-6.26,8.04-4.52c3.68,1.33,5.94,0.22,8.47-1.99c3.12-2.73,6.16-5.67,9.67-7.8
				c5.75-3.5,8.13-2.39,9.8,4.24c1.42-1.08,2.56-2.31,3.97-2.92c1.56-0.68,3.76-1.59,4.99-0.99c3.26,1.61,5.58,0.54,8.29-1.11
				c1.09-0.66,3.78-0.69,4.28,0.03c4.71,6.7,12.71,5.1,18.35,5.25c16.49,0.44,33.04-1.77,49.59-2.64c5.92-0.31,11.88,0.34,17.83,0.37
				c6.68,0.03,13.44,1.2,19.74-2.75c1.44-0.9,4.42,0.65,6.7,1.07c-0.05,0.65-0.1,1.3-0.15,1.95c3.3-3.18,6.62-4.73,10.25,0.43
				c1.54-2.87,2.71-5,3.82-7.15c2.36-4.57,6.16-6.12,11.29-4.71c4.87,1.34,5.34,4.7,4.34,8.88c-0.08,0.34,0.36,0.81,0.83,1.78
				c1.4-1.25,2.47-2.47,3.78-3.33c2.9-1.89,5.77-3.98,8.93-5.29c4-1.66,6.32,0.01,7,4.32c0.2,1.25,0.38,2.5,0.62,4.07
				c2.83-3.44,4.79-6.57,7.46-8.85c3.2-2.73,8.55-1.96,11.46,0.91c3.34,3.3,1.47,5.91-1.05,8.49c-0.25,0.25-0.11,0.88-0.26,2.38
				c2.07-1.07,3.47-2.2,5.03-2.5c2.16-0.42,5.74-1.11,6.44-0.11c2.17,3.14-1.28,3.36-3.22,4.35c-0.65,0.33-0.92,1.44-1.36,2.19
				c0.84,0.24,2,0.96,2.48,0.64c4.19-2.71,8.51-2.26,13.26-1.87c4.89,0.4,6.04,2.35,5.6,6.51c-0.15,0.15-0.3,0.3-0.46,0.44
				c-4.99,0.33-9.99,1.06-14.96,0.9c-8.73-0.27-17.44-1.09-26.16-1.68c-6.92-0.47-13.86-1.37-20.77-1.25
				c-4.25,0.07-8.46,1.7-12.69,2.62c-1.92,0.14-3.83,0.28-5.75,0.41c-14.1,1.21-28.2,2.42-42.3,3.64c-0.04,0.63-0.08,1.26-0.11,1.9
				c5.29,0.54,10.59,1.08,15.88,1.62c-0.02,0.6-0.04,1.2-0.06,1.8c-6.38,0-12.89,0.82-19.11-0.19c-9.95-1.61-19.78-2.13-29.74-0.72
				c-4.26,0.6-8.6,0.81-12.77,1.77c-1.63,0.37-2.89,2.32-4.32,3.55c1.65,0.8,3.24,1.81,4.97,2.33c1.02,0.31,2.28-0.24,3.42-0.22
				c2.04,0.04,4.09,0.23,7.18,0.43c-3.56,1.47-5.96,2.45-9.19,3.79c4.74,3.37,8.07,5.78,12.63,2.08c1.82-1.48,4.56-2.56,6.89-2.58
				c9.64-0.08,19.27,0.3,28.91,0.54c0.82,0.02,2.06-0.02,2.39,0.46c3.39,4.86,8.34,2.74,12.74,2.99c7.47,0.42,14.95,0.75,22.43,1.12
				c0,0.58,0.01,1.17,0.01,1.75c-4.86,0.43-9.72,1.17-14.59,1.24c-14.3,0.22-28.61-0.11-42.9,0.34c-12.47,0.4-24.9,1.72-37.36,2.36
				c-23.26,1.21-46.53,2.2-69.8,3.33c-2.82,0.14-5.74,0.21-8.42,0.99c-6.73,1.95-12.97,1.56-18.33-2.66c3.06-1.03,6.48-2.17,9.9-3.32
				c-0.15-0.86-0.3-1.72-0.44-2.59c-13.52-1.9-27.13,1.17-40.69,0.72c-13.69-0.46-27.41-0.1-41.43-0.1
				C846.16,104.7,846.16,103.06,846.16,101.41z M1006.6,99.74c2.69-3.67,1.97-6.14-0.39-8.03c-2.9-2.33-5.97-4.16-9.81-1.11
				c-2.16,1.72-4.98,2.6-7.45,3.96c-1.29,0.71-3.3,1.5-3.46,2.5c-0.19,1.18,1.28,2.61,2.07,4.01c1.38-2.11,2.3-4.55,3.09-4.51
				c1.66,0.08,3.25,1.47,3.66,1.68c2.26-2.5,3.76-5.72,5.92-6.24C1004.93,90.86,1005.92,95.01,1006.6,99.74z M980.39,76.83
				c-0.06-0.77-0.12-1.53-0.18-2.3c-8.47,0.52-16.95,1.05-25.42,1.57c0.05,0.7,0.11,1.4,0.16,2.1
				C963.43,77.74,971.91,77.28,980.39,76.83z M979.93,80.38c-0.06-0.37-0.12-0.74-0.18-1.11c-4.36,0-8.72,0-13.08,0
				c0.04,0.61,0.07,1.23,0.11,1.84C971.16,80.87,975.54,80.62,979.93,80.38z"/>
			<path d="M486.82,87.99c-4.5,2.42-9.17,3.3-15.5,1.36c3.02-2.01,5.19-3.45,8.05-5.34c-5.32-0.63-10.09-1.21-14.87-1.76
				c-1.65-0.19-3.42,0.03-4.93-0.52c-1.37-0.5-3.34-1.69-3.45-2.74c-0.13-1.23,1.18-3.33,2.38-3.84c3.13-1.34,6.54-2.03,9.96-3.01
				c1.62-9.78,13.1-15.26,22.38-10.35c3.32,1.75,6.51,3.75,9.84,5.68c3.49-8.98,5.94-9.77,14.23-5.59c1.2,0.61,3.52,0.5,4.54-0.29
				c6.05-4.65,8.1-4.72,14.43-0.65c1.28,0.82,3.51,0.7,5.15,0.35c7.03-1.52,9.33,0.32,8.56,7.49c2.01,0,3.99-0.03,5.96,0.01
				c6.03,0.12,11.88-1.18,18.15,0.51c5.17,1.4,11.15-0.14,16.76-0.47c0.51-0.03,1.09-0.62,1.47-1.09c4.11-5.07,9.1-5.63,13.7-1.09
				c3.31,3.27,6.78,2.09,10.14,0.82c2.97-1.12,5.42-1.82,8.4,0.29c1.12,0.8,3.68-0.02,5.42-0.6c3.52-1.18,6.6-2.11,9.05,2.07
				c0.67,1.14,3.11,1.77,4.73,1.74c6.34-0.11,12.64-0.52,18.38,3.13c0.72,0.46,2.15-0.23,3.26-0.37c4.74-0.59,9.51-1.8,14.22-1.61
				c8.48,0.36,10.85,3.83,9.09,11.72c8.68,4.1,8.68,4.1,6.57,8.47c3.3,9.58,13.54,3.39,18.86,8.78c-8.4,4.36-41.4,6.31-53.09,3.43
				c14.55-0.73,29.78-1.49,45-2.25c0.03-0.62,0.06-1.25,0.08-1.87c-4.77-0.51-9.54-1.03-14.18-1.52c0.24-3.68,0.43-6.76,0.72-11.19
				c-3.3-0.32-7.46-0.73-11.62-1.14c-0.13-0.36-0.26-0.73-0.39-1.09c1.43-0.82,2.92-1.56,4.28-2.49c3.33-2.26,3.34-4.83,0.09-7.05
				c-5.53-3.79-17.06-2.04-21.45,2.89c-1.41,1.59-3.8,2.31-6.03,3.59c0.95,4.03-1.69,5.05-5.29,6.17c-5.65,1.75-10.87,5.22-16.59,6.3
				c-5.45,1.03-6.67,6.2-11.01,8.11c2.83,2.13,5.34,4.29,8.14,5.97c1.06,0.64,3.71,0.71,4.05,0.12c1.83-3.11,4.07-2.66,7.07-2.24
				c1.95,0.27,4.19-1.48,6.3-2.34c1.92-0.79,3.82-1.6,6.01-1.59c-10.82,7.42-22.01,12.6-33.24,2.33c-2.37,3.02-4.3,5.47-6.61,8.4
				c-2.33-0.24-6.03-6.55-7.95,0.69c-4.2,0-8.03,0-13.14,0c2.22,2.46,3.34,3.71,5.11,5.67c-4.78-0.35-8.62-0.73-12.47-0.9
				c-3.65-0.16-6.78,0.45-8.63,4.43c-0.5,1.08-3.36,1.58-5.09,1.5c-5.14-0.25-9.74,0.79-13.89,3.54c-0.1,0.66-0.2,1.33-0.3,1.99
				c3.04,0.99,6.06,2.79,9.11,2.84c20.31,0.32,40.63,0.31,60.94,0.29c4.15,0,8.3-0.55,12.46-0.76c2.21-0.11,4.42-0.02,6.63-0.02
				c0.11,0.44,0.22,0.88,0.33,1.31c-2.06,1.03-4.12,2.06-6.17,3.09c-0.04,0.51-0.08,1.01-0.13,1.52c3.94,0.4,7.87,0.8,12.11,1.22
				c-11.31,3.53-15.85,2.14-15.47-4.32c-18.92-0.51-37.84-1.06-56.76-1.52c-6.78-0.17-13.58,0.14-20.33-0.35
				c-1.84-0.13-3.52-2.36-5.28-3.63c1.32-1.73,2.31-4,4.03-5.05c1.85-1.13,4.38-1.41,6.64-1.57c3.96-0.28,7.04-0.85,10.53-3.96
				c3.18-2.84,9.26-2.43,14.54-3.55c1.59-4.27,4.2-5.17,9.82-5.08c5.59,0.09,11.37-0.68,14.95-4.29c-2.78-2.08-5.15-4.24-7.87-5.74
				c-1.5-0.83-3.6-0.79-5.43-0.76c-5.88,0.11-11.82,0.97-17.63,0.44c-3.99-0.36-7.37-2.76-4.98-8.01c0.27-0.59-0.19-1.52-0.34-2.29
				c-0.4-2.11-0.81-4.23-1.48-7.73c-4.3-3.01-10.2-1.3-17.15,0.11c2.6,2.4,4.77,4.06,6.52,6.08c3.02,3.5,2.56,5.9-1.58,7.86
				c-4.35,2.06-8.73,3.39-12.74-1.14c-0.76-0.86-2.9-0.76-4.37-0.67c-4.14,0.26-8.27,1.05-12.39,1.03c-2.19-0.01-5.65-0.67-6.31-2.07
				c-2.19-4.68-5.09-3.13-8.65-2.58c-2.79,0.44-5.84-0.87-8.79-1.25c-2.02-0.26-4.12-0.59-6.1-0.29c-2.77,0.42-5.41,1.62-8.18,2.07
				c-2.97,0.49-6.35,1.66-7.8-2.82c-0.2-0.61-2.37-0.59-3.63-0.86c3.09-1.07,6.27-3.52,8.81,1.04c0.32,0.58,2.08,0.64,3.1,0.48
				c1.96-0.3,3.85-1.11,5.81-1.4c3.24-0.48,7.65-2.2,9.54-0.76c3.86,2.97,7.08,1.63,10.73,0.95c2.23-0.41,4.5-0.57,7.5-0.94
				c-2.71,5.88,0.77,6.42,4.46,6.28c5.52-0.21,11.03-0.75,17.14-1.2c3.74,4.67,7.59,4.96,12.76,0.37c-1.06-6.73-7.18-5.37-10.81-6.34
				c0.83-2.94,1.46-5.17,1.88-6.65c5.15-0.36,10.5-0.76,15.85-1.11c1.14-0.07,2.33-0.15,3.44,0.04c3.61,0.64,7.16,1.6,10.79,2.06
				c2.61,0.33,5.29,0.06,7.93,0.06c0.07,0.4,0.14,0.8,0.21,1.2c-1.48,0.49-2.92,1.12-4.44,1.43c-2.6,0.55-5.3,0.7-7.84,1.42
				c-1.24,0.35-2.22,1.62-3.32,2.47c1.2,0.95,2.28,2.18,3.63,2.78c1.28,0.57,2.85,0.49,4.29,0.69c0.01,0.4,0.02,0.8,0.03,1.2
				c-2.35,0.9-4.69,1.81-8.43,3.24c10.78,7.22,20.37,4.09,28.16,0.98c2.56,2.8,4.12,5.98,5.9,6.11c2.99,0.22,6.17-1.37,9.17-2.48
				c0.97-0.36,2.04-1.47,2.32-2.45c0.33-1.16-0.18-2.55-0.26-3.84c-0.06-0.99-0.01-1.99-0.01-3.51c5.84,4.67,5.84,4.67,8.61,0.23
				c2.15-0.15,5.33,0.3,5.7-0.52c1.95-4.34,5.4-3.44,8.82-3.49c1.59-0.02,3.16-0.81,5.02-1.33c-1.45-1.95-2.39-3.22-3.82-5.16
				c2.57-0.5,4.49-0.75,6.32-1.27c0.81-0.23,1.46-1.03,2.18-1.57c-0.58-0.72-1.04-1.89-1.75-2.08c-7.25-1.86-14.48-3.55-21.92-0.49
				c-3.74,1.54-7.71,2.55-12.49,1.31c4.71-2,8.5-3.62,12.3-5.23c-0.27-0.8-0.55-1.6-0.82-2.4c-3.57,1.08-7.15,2.17-10.98,3.33
				c-3.35-4.16-4.53-4.21-9.51-0.78c-3.63,0.32-5.83,0.51-8.02,0.7c-1.88-2.44-4.62-5.4-7.01-5.15c-2.69,0.28-5.07,3.46-8.72,6.24
				c-5.4-1.68-13.18-5.58-17.34,6.03c-0.29-0.42-0.59-0.84-0.88-1.25c0.21-1.64,0.43-3.28,0.8-6.13c-9.57,0-19,0-29.12,0
				c0.77-2.04,1.08-2.76,1.31-3.51c0.41-1.28,0.78-2.57,1.16-3.86c-1.41-0.3-2.89-1.05-4.2-0.82c-2.75,0.48-5.4,1.47-8.5,2.38
				c0.12-5.28-3.55-5.55-6.72-4.3c-2.96,1.17-5.32,3.84-7.46,5.48c-3.22-1.55-6.43-4.21-9.83-4.49c-6.38-0.52-2.3,6.01-5.04,9.04
				c-3.08-1.72-6.63-3.01-9.3-5.32c-5.55-4.8-11.17-4.21-17.1-1.34c-6.2,3-6.61,4.77-2.65,10.23c0.44,0.61,0.68,1.36,1.71,3.48
				c-6.62-3.32-12.13-4.45-16.89,1.65c8.68,0.9,17.36,1.81,26.03,2.71c0.15,0.4,0.3,0.79,0.44,1.19c-1.87,1.32-3.74,2.65-5.94,4.21
				C482.19,87.99,484.51,87.99,486.82,87.99z"/>
			<path d="M437.6,72.44c3.07,5.77,2.6,7.19-3.32,7.71c-5.58,0.49-11.25,0.06-16.88,0.04c-1.8-0.01-3.59,0-5.72,1.05
				c1.71,0.59,3.49,1.03,5.1,1.82c1.36,0.67,2.53,1.75,3.78,2.65c-1.23,1.06-2.46,2.13-3.64,3.15c2.64,0.77,5.84,1.7,9.03,2.63
				c-0.06,0.64-0.12,1.29-0.18,1.93c-5.84,0.68-11.66,1.83-17.51,1.95c-25.62,0.54-51.25,0.81-76.87,1.1
				c-6.65,0.07-13.3-0.17-19.95-0.22c-3.61-0.02-6.74-0.43-4.92-5.74c-7.5,0.55-14.4,1.47-21.3,1.49
				c-23.14,0.08-46.28-0.04-69.42-0.34c-10.3-0.13-20.6-0.69-30.87-1.45c-2.79-0.21-6.43-0.48-7.43-5.71
				c8.16-1.04,15.97-2.03,24.12-3.07c-0.86-3.61,1.02-5.17,4.61-5.26c4.31-0.1,8.63-0.14,12.92-0.49c1.15-0.09,2.22-1.18,3.32-1.81
				c-1.05-0.59-2.05-1.56-3.15-1.69c-4.27-0.51-8.59-0.57-12.83-1.21c-1.47-0.22-2.77-1.64-4.14-2.51c0.07-0.57,0.15-1.14,0.22-1.71
				c4.42-0.94,8.8-2.38,13.27-2.73c9.45-0.74,18.93-1.15,28.41-1.26c11.99-0.14,23.99,0.17,35.98,0.24c3.26,0.02,6.44,0.39,8.4-3.64
				c0.64-1.32,4.34-1.94,6.4-1.56c2.53,0.46,3.93,0.43,6.46-1.05c4.71-2.74,10.34-4.78,15.74-5.23c6.09-0.51,9.43,3.68,10.18,11.3
				c3.2-0.95,6.11-2.37,9.09-2.52c2.36-0.12,4.93,1.06,7.17,2.14c1.2,0.58,1.91,2.18,2.92,3.42c4.73-4.04,9.55-4.79,15.34-2.45
				c3.38,1.37,7.4,1.24,11.15,1.59c0.86,0.08,2.16-0.39,2.65-1.05c3.69-4.96,8.89-4.83,14.03-3.94c2.67,0.46,3.53-0.38,4.34-2.59
				c3.71-10.15,18.43-13.34,26.31-5.92c1.04,0.98,2.98,1.07,4.54,1.39c0.77,0.16,2.13-0.47,2.36-0.14c3,4.33,7.55,0.86,10.76,2.22
				c4.08,1.73,8.86,4.32,10.79,7.91C450.91,66.66,444.92,71.4,437.6,72.44z M404.98,88.5c0.04-0.4,0.09-0.8,0.13-1.2
				c2.88-0.51,5.77-1.03,8.65-1.54c0-0.31,0-0.62,0-0.93c-4.77-1.47-9.54-2.94-14.31-4.41c0.15-0.52,0.3-1.04,0.45-1.56
				c12.11-1.69,24.7,2.16,36.6-2.45c-0.9-0.76-1.82-0.7-2.65-0.93c-1.46-0.4-2.87-0.98-4.31-1.48c1.43-1.59,2.7-3.39,4.38-4.65
				c0.65-0.49,2.4,0.72,3.33,0.35c3.08-1.22,6.13-2.63,8.92-4.4c0.6-0.38,0.57-3.39-0.07-3.81c-2.64-1.72-5.54-3.77-8.51-4.06
				c-3.05-0.3-6.28,1.3-9.7,2.13c-0.13-0.9-0.39-2.6-0.85-5.69c-2.7,2.13-4.68,3.69-6.69,5.27c-1.15-7.31-3.73-9.65-11.23-9.01
				c-3.31,0.28-7.49,1.11-9.49,3.31c-2.56,2.82-3.33,7.27-4.88,11.06c-11.88-4.7-13.79-4.19-16.9,5.08
				c-2.18-0.54-4.86-0.46-6.42-1.75c-2.97-2.44-5.04-2.56-6.16-0.28c-4.2-1.27-7.72-2.83-11.36-3.18c-1.43-0.14-3.13,2.41-4.7,3.74
				c1,1.17,1.96,2.38,3,3.5c0.69,0.75,1.51,1.37,2.74,2.47C344,73.15,344,73.15,344.85,80.77c-1.3,0.28-2.62,0.57-4.07,0.89
				c0.62,1.51,1.09,2.65,1.7,4.14c-5.22-2.26-5.2-3.73-2.53-7.6c2.11-3.05,3.21-6.99,3.95-10.7c0.25-1.23-1.75-3.36-3.22-4.33
				c-0.96-0.64-3.32-0.47-4.13,0.3c-1.14,1.08-1.42,3.06-2.16,4.9c-1.67-1.53-3.1-2.83-4.52-4.13c-0.04,0.41-0.07,0.82-0.11,1.22
				c-2.67,0.44-5.34,0.89-8.35,1.39c-0.28,1.04-0.68,2.52-1,3.68c-2.8-0.57-5.53-1.98-7.42-1.25c-1.48,0.58-1.92,3.81-2.98,6.22
				c-1.1-0.79-2.09-2.09-3.17-2.17c-3.45-0.27-7.86-1.38-10.18,0.26c-2.67,1.9-4.62,2.47-7.5,1.83c-7.94-1.76-15.81-2.21-23.44,1.48
				c-0.82,0.4-1.92,0.23-2.89,0.33c-0.14-0.61-0.28-1.21-0.41-1.82c3.33-0.86,6.67-1.69,9.99-2.58c8.55-2.28,17.9,2.35,26.1-3.05
				c0.36-0.24,0.98-0.07,1.48-0.09c4.11-0.13,8.85,2.17,11.63-3.06c0.11-0.21,0.62-0.19,0.93-0.3c3.14-1.12,6.39-2.01,9.38-3.44
				c3.68-1.75,3.74-5.58,0.36-7.79c-4.9-3.2-10.12-1.45-12.69,4.65c-4.83-5.31-7.23-1.56-9.01,2.76c-8.59-3.97-8.59-3.97-12.16,2.16
				c-7.02,0-13.83-0.05-20.65,0.02c-6.83,0.07-13.65,0.29-20.48,0.39c-7,0.1-14.01-0.12-20.99,0.29c-6.55,0.38-13.07,1.4-19.6,2.13
				c0.02,0.9,0.03,1.8,0.05,2.7c7.25,0.32,14.51,0.65,21.54,0.96c-0.78,2.2-1.45,4.11-2.54,7.22c-6.55,0-13.76,0-22.29,0
				c2.38,2.05,3.4,2.93,5.6,4.83c-9.43,1.02-17.53,1.89-25.62,2.76c-0.02,0.52-0.04,1.05-0.06,1.57c7.79,0.83,15.58,2.44,23.36,2.36
				c34.79-0.35,69.57-1.19,104.35-1.8c2.13-0.04,4.28,0.43,6.42,0.67c0.05,0.5,0.1,1,0.15,1.5c-2.3,1.05-4.61,2.1-6.91,3.15
				c0.07,0.34,0.14,0.67,0.21,1.01c34.82-0.7,69.65-1.4,104.47-2.1c0.05-0.29,0.1-0.57,0.15-0.86
				C412.05,90.48,408.52,89.49,404.98,88.5z"/>
			<path d="M1191.13,78.41c0.44-4.16-0.71-6.11-5.6-6.51c-4.76-0.39-9.07-0.84-13.26,1.87c-0.49,0.31-1.64-0.4-2.48-0.64
				c0.44-0.75,0.7-1.86,1.36-2.19c1.94-0.98,5.39-1.21,3.22-4.35c-0.69-1-4.28-0.31-6.44,0.11c-1.56,0.3-2.96,1.43-5.03,2.5
				c0.14-1.5,0.01-2.13,0.26-2.38c2.52-2.58,4.38-5.19,1.05-8.49c-2.91-2.88-8.26-3.65-11.46-0.91c-2.67,2.28-4.63,5.41-7.46,8.85
				c-0.24-1.57-0.42-2.82-0.62-4.07c-0.69-4.31-3-5.97-7-4.32c-3.17,1.31-6.03,3.4-8.93,5.29c-1.31,0.85-2.38,2.07-3.78,3.33
				c-0.47-0.98-0.91-1.44-0.83-1.78c1-4.18,0.53-7.54-4.34-8.88c-5.13-1.41-8.93,0.14-11.29,4.71c-1.11,2.15-2.28,4.28-3.82,7.15
				c-3.63-5.16-6.95-3.61-10.25-0.43c0.05-0.65,0.1-1.3,0.15-1.95c-2.27-0.42-5.26-1.98-6.7-1.07c-6.31,3.95-13.07,2.78-19.74,2.75
				c-5.94-0.02-11.91-0.68-17.83-0.37c-16.54,0.87-33.09,3.08-49.59,2.64c-5.64-0.15-13.64,1.45-18.35-5.25
				c-0.5-0.71-3.2-0.69-4.28-0.03c-2.71,1.64-5.03,2.72-8.29,1.11c-1.22-0.61-3.43,0.31-4.99,0.99c-1.41,0.61-2.56,1.85-3.97,2.92
				c-1.67-6.63-4.05-7.75-9.8-4.24c-3.51,2.14-6.55,5.08-9.67,7.8c-2.53,2.21-4.79,3.32-8.47,1.99c-4.82-1.75-7.23,0.18-8.04,4.52
				c-4.06-1.5-8.1-3.74-12.37-4.33c-3.72-0.52-5.62,3.61-5.51,10.9c-6.77-6.69-14.79-8.32-23.44-7.71
				c-6.16,0.43-10.51,4.36-11.25,10.09c-0.54,4.21,0.01,7.83,5.34,8.08c1.43,0.07,3.59-0.06,4.15-0.92c0.64-0.99-0.11-2.89-0.26-4.4
				c-0.49,0.01-0.99,0.02-1.48,0.03c-0.2,0.94-0.4,1.87-0.6,2.81c-0.43,0.16-0.86,0.32-1.29,0.48c-0.53-1.68-1.07-3.36-1.6-5.03
				c1.77-0.14,3.93-0.99,5.23-0.27c3.02,1.66,4.56,4.47,2.53,7.96c-1.61,2.77-7.06,3.76-9.89,1.9c-0.55-0.36-1-0.87-1.48-1.33
				c-6.11-5.94-8.71-5.7-13.64,1.26c-2.76-0.39-5.52-0.77-8.28-1.16c2.01-0.36,4.03-0.72,6.04-1.08c-0.18-0.35-0.36-0.69-0.53-1.04
				c4.08-1.81,8.16-3.63,12.85-5.71c2.12-13.94,15.79-17.84,34.36-10.11c5.5-8.25,6.34-8.47,16.18-5.17
				c1.07,0.36,2.53,0.07,3.66-0.32c2.42-0.85,4.74-1.97,6.6-2.76c-0.56-0.25-1.88-0.86-4.56-2.09c3.68-1.48,6.14-2.47,8.6-3.46
				c-0.01-0.26-0.02-0.51-0.02-0.77c-5.7,0.64-11.39,1.44-17.11,1.88c-5.78,0.45-12.05,2.02-17.25,0.37
				c-5.17-1.63-9.36,0.19-13.98,0.17c-2.47-0.01-5.39,0.73-6.01-3.44c-0.14-0.97-3.37-1.48-5.19-3.3c3.41-0.82,6.83-1.65,10.24-2.47
				c-0.03-0.28-0.05-0.56-0.08-0.85c-1.25,0.09-2.5,0.29-3.74,0.26c-4.55-0.12-9.11-0.26-13.66-0.49
				c-6.94-0.35-13.87-0.81-20.81-1.13c-0.71-0.03-1.91,0.41-2.1,0.92c-1.37,3.66-4.17,2.22-6.73,2.24c-4.64,0.03-9.27,0.41-13.78,1.8
				c7.39,2.94,14.78,5.88,22.17,8.82c-0.05,0.58-0.1,1.15-0.15,1.73c-2.09,0.55-4.15,1.19-6.26,1.62c-4.07,0.83-8.14,1.69-12.25,2.22
				c-1.68,0.22-4.78,0.03-5.01-0.68c-1.76-5.54-5.43-2.93-8.48-2.21c-2.9,0.68-5.79,2.05-5.86-2.98c-7.74,1.1-15.17,5.52-22.15-0.56
				c5.78,1.6,11.34,1.09,17.03-0.9c2.89-1.01,6.56-2.22,8.9,1.64c0.16,0.26,0.59,0.58,0.81,0.53c9.13-2.13,18.18,6.47,27.3-0.16
				c-6.13-5.83-15.03-5.11-23.5-8.12c4.95-4.33,9.92-4.97,15.05-5.22c3.61-0.17,7.64,1.57,10.44-2.51c0.41-0.6,2.21-0.4,3.35-0.32
				c8.76,0.65,17.51,1.5,26.28,1.98c3.78,0.21,7.6-0.41,11.41-0.46c3.24-0.04,6.49,0.19,9.74,0.3c0.12,0.79,0.24,1.57,0.36,2.36
				c-2.22,0.97-4.44,1.93-6.87,2.99c0.33,1.15,0.71,2.47,1.09,3.8c0.35,0.09,0.67,0.24,0.99,0.24c9.6,0.02,19.22,0.29,28.81-0.08
				c6.11-0.23,12.17-1.58,18.28-2.08c2.1-0.17,4.29,0.71,6.44,1.11c0.06,0.46,0.13,0.93,0.19,1.39c-1.15,0.73-2.35,1.41-3.46,2.2
				c-1.43,1.02-2.79,2.13-4.18,3.21c0.68,0.74,1.65,2.23,1.98,2.1c1.45-0.57,2.74-1.56,4.02-2.49c4-2.89,7.79-6.12,12.01-8.64
				c2.75-1.64,6.05-2.01,8.61,1.02c0.31,0.36,0.81,0.87,1.17,0.83c4.89-0.55,9.88-0.76,14.63-1.94c2.96-0.73,4.6-0.51,7.47,1.1
				c4.66,2.6,10.48,4.48,15.76,4.36c17.28-0.41,34.53-2.03,51.81-2.84c5.44-0.26,10.94,0.45,16.39,0.91
				c5.34,0.44,10.39,1.18,15.49-2.25c2.43-1.63,6.6-0.8,9.98-0.86c2.87-0.05,6.01,1,8.54,0.13c2.17-0.74,3.93-3.31,5.39-5.4
				c3.53-5.06,14.95-4.75,18.23,0.66c0.58,0.95,1.05,1.97,1.76,3.31c6.33-3.99,12.35-10.38,19.27-1.71c8.4-8.31,14.1-7.65,21.41,2.16
				c0.58,0.79,1.75,1.43,2.74,1.58c5.5,0.84,5.51,0.78,6.53,7.02c3.05,0,6.2-0.26,9.3,0.06
				C1191.78,70.34,1193.23,73.56,1191.13,78.41z"/>
			<path d="M846.16,101.41c0,1.64,0,3.29,0,4.82c14.02,0,27.74-0.36,41.43,0.1c13.56,0.45,27.16-2.62,40.69-0.72
				c0.15,0.86,0.3,1.72,0.44,2.59c-3.42,1.15-6.84,2.3-9.9,3.32c5.36,4.22,11.6,4.61,18.33,2.66c2.68-0.78,5.6-0.85,8.42-0.99
				c23.27-1.13,46.54-2.13,69.8-3.33c12.46-0.65,24.9-1.97,37.36-2.36c14.29-0.45,28.6-0.12,42.9-0.34
				c4.87-0.08,9.72-0.81,14.59-1.24c0-0.58-0.01-1.17-0.01-1.75c-7.48-0.37-14.95-0.71-22.43-1.12c-4.4-0.25-9.35,1.87-12.74-2.99
				c-0.33-0.48-1.57-0.44-2.39-0.46c-9.64-0.24-19.28-0.62-28.91-0.54c-2.33,0.02-5.07,1.09-6.89,2.58
				c-4.56,3.7-7.89,1.29-12.63-2.08c3.23-1.33,5.63-2.32,9.19-3.79c-3.09-0.19-5.13-0.38-7.18-0.43c-1.15-0.03-2.4,0.53-3.42,0.22
				c-1.73-0.53-3.32-1.53-4.97-2.33c1.43-1.23,2.7-3.17,4.32-3.55c4.17-0.96,8.51-1.17,12.77-1.77c9.96-1.4,19.78-0.89,29.74,0.72
				c6.22,1.01,12.73,0.19,19.11,0.19c0.02-0.6,0.04-1.2,0.06-1.8c-5.29-0.54-10.59-1.08-15.88-1.62c0.04-0.63,0.08-1.26,0.11-1.9
				c14.1-1.21,28.2-2.42,42.3-3.64c-5.82,0.82-11.62,1.8-17.46,2.41c-3.17,0.33-6.4,0.06-9.6,0.06c0,0.66,0,1.32,0,1.98
				c3.93,0.23,7.86,0.47,11.8,0.7c0.21,0.54,0.41,1.08,0.62,1.62c-1.61,0.85-3.2,2.39-4.84,2.45c-10.61,0.39-21.23,0.31-31.84,0.66
				c-11.83,0.39-23.64,1.09-35.47,1.65c0.01,0.62,0.01,1.25,0.02,1.87c5.2,0.38,10.4,0.76,15.6,1.13c0.13,0.71,0.25,1.41,0.38,2.12
				c-2.95,1.1-5.91,2.2-8.86,3.31c0.04,0.33,0.09,0.65,0.13,0.98c1.47,0,3.21,0.52,4.36-0.08c7.06-3.7,14.57-4.1,22.31-3.76
				c5.49,0.24,10.99-0.09,16.48,0.09c3.76,0.12,8.18-1.26,10.28,3.86c0.27,0.65,2.41,0.67,3.69,0.74c7.47,0.43,14.95,0.73,22.42,1.2
				c1.5,0.09,2.96,0.72,4.45,1.1c0.1,0.66,0.2,1.33,0.29,1.99c-3.24,0.98-6.46,2.71-9.73,2.82c-16.96,0.57-33.96,0.31-50.9,1.11
				c-33.25,1.57-66.48,3.58-99.71,5.55c-9.44,0.56-18.86,1.6-28.29,2.4c-0.66,0.06-1.41,0.2-1.98-0.04
				c-4.27-1.83-8.49-3.74-14.1-6.23c4.04-1.48,7.25-2.65,11.23-4.11c-1.51-0.31-2.45-0.71-3.37-0.65c-14.59,0.9-29.18,1.73-43.75,2.9
				c-3.53,0.28-6.98,1.71-10.46,2.61c-0.67-0.83-1.26-2.29-2.02-2.39c-3.92-0.5-7.89-0.64-11.85-0.92
				C844.57,108.6,843.78,107.11,846.16,101.41z"/>
			<path d="M886.67,43.2c8.8,0,17.61-0.34,26.39,0.08c11.59,0.56,23.14,1.86,34.72,2.56c5.86,0.35,11.76,0.06,17.64,0.06
				c0.1-0.51,0.19-1.02,0.29-1.54c-0.98-0.83-1.96-1.65-3.35-2.83c3.44-0.87,6.37-1.61,9.8-2.48c0.84,1.28,1.89,2.87,3.33,5.06
				c-7.16,3.62-14.79,5.97-22.08,4.5c-20.83-4.22-41.61-4.3-62.6-2.37c-3.55,0.33-7.24-0.78-12.12-1.37
				c4.14-5.8,9.13-4.06,13.11-5.18c-0.21-0.37-0.42-0.73-0.63-1.1c1.95-1.38,3.72-3.3,5.88-4.04c6.47-2.18,13.07-3.77,20.03-1.79
				c1.39,0.39,3.46-0.01,4.66-0.84c5.42-3.75,10.68-2.89,15.81,0.25c2.37,1.45,4.3,2.77,7.28,1.19c1.16-0.62,3.38-0.13,4.69,0.6
				c2.3,1.29,4.2,2.39,6.78,0.51c0.65-0.48,2.97,0.04,3.31,0.71c0.58,1.17,0.55,2.92,0.12,4.2c-0.25,0.74-2.03,1.67-2.72,1.42
				c-2.18-0.78-4.09-2.31-6.27-3.12c-1.95-0.72-4.78-1.9-6.05-1.07c-2.95,1.92-4.27,0.91-6.22-1.23c-4.89-5.36-9.21-5.36-14.98-0.97
				c-1.28,0.98-3.89,1.21-5.47,0.64c-6.57-2.36-12.85-0.79-19.11,0.91c-1.48,0.4-4.45,0.38-3.13,3.75c0.12,0.31-2.22,1.98-3.61,2.39
				c-1.79,0.53-3.78,0.42-5.68,0.59C886.57,42.88,886.62,43.04,886.67,43.2z"/>
			<path d="M356.8,40.52c1.23-0.73,2.45-1.45,3.68-2.18c0.01-0.49,0.02-0.97,0.03-1.46c-3.61-0.42-7.21-0.9-10.82-1.23
				c-3.51-0.32-7.16-0.12-10.39-3.55c6.22-3.26,12.18-4.11,18.28-3.78c7.63,0.41,15.24,1.24,22.87,1.76c0.79,0.05,1.8-0.58,2.43-1.18
				c4.91-4.66,6.93-4.22,13.69-1.92c5.52,1.88,10.6,5.54,17.03,5.09c1.61-0.11,3.43,2.73,5.15,4.21c-1.28,1.36-2.4,2.91-3.86,4.03
				c-6,4.6-12.76,2.72-19.52,2.53c-11.12-0.31-22.27,0.44-33.41,0.58c-1.84,0.02-3.68-0.79-5.52-1.21
				C356.56,41.65,356.68,41.08,356.8,40.52z M367.79,37.48c-1.67,2.2-2.18,2.87-2.59,3.42c10.25,0,20.23-0.49,30.14,0.16
				c7.57,0.5,14.3-1.31,21.26-4.44c-2.84-4.83-5.85-1.07-8.9,0.06c-1.37-4.1-3.27-7.07-7.62-3.56c-7.07-7.48-11.59-7.19-17.79,0.92
				c-2.22-1.01-4.64-3.15-6.61-2.8c-5.88,1.06-11.48,0.73-17.3-0.32c-3.53-0.64-7.33,0.25-11.01,0.45c0.08,0.19,0.15,0.37,0.23,0.56
				C354.02,33.69,360.45,35.46,367.79,37.48z"/>
			<path d="M190.68,24.45c2.73-0.33,5.45-0.64,8.18-0.99c11.71-1.51,23.54-1.33,35.28-3.59c5.26-1.01,11.02-4.35,16.92-1.42
				c0.69,0.34,1.9-0.16,2.79-0.48c5.68-2.04,10-0.4,11.87,5.22c0.9,2.72,1.85,4.31,4.89,4.5c0.9,0.06,1.71,1.55,2.56,2.38
				c-1.02,0.67-1.97,1.79-3.06,1.92c-2.46,0.32-4.98,0.09-7.48,0.2c-5.63,0.24-11.26,0.79-16.89,0.75
				c-7.11-0.06-15.34,1.58-21.05-1.4c-9.12-4.76-18.04-0.26-26.7-2.61c-2.43-0.66-4.89-1.23-7.34-1.84
				C190.65,26.21,190.66,25.33,190.68,24.45z M196.29,24c-0.01,0.64-0.01,1.28-0.02,1.93c0.19,0.24,0.34,0.64,0.58,0.7
				c0.79,0.21,1.61,0.3,2.42,0.44c8.99,1.52,18.66-3.29,27.15,2.91c0.22,0.16,0.65,0.06,0.98,0.06c10.2-0.12,20.43,0.06,30.59-0.63
				c2.04-0.14,3.86-3.6,5.78-5.53c-2.04-1.39-3.93-3.37-6.17-3.93c-1.08-0.27-2.82,2.16-4.41,3.48c-6.71-4.45-6.71-4.45-11.13-0.55
				c-3.04-0.32-5.96-0.05-8.88,0.28c-2.11,0.24-4.21,0.79-6.32,0.8C216.67,24.05,206.48,24,196.29,24z"/>
			<path d="M130.71,118.61c1.21-4.13,4.76-5.44,11.08-4.87c6.79,0.61,13.67,0.34,20.51,0.44c0.5,0.01,1.04-0.23,1.48-0.09
				c4.92,1.54,9.81,3.17,14.73,4.7c1.68,0.52,3.41,0.99,5.15,1.21c4.19,0.52,7.35,3.19,6.73,5.89c-0.6,2.62-3.86,3.66-8.87,2.51
				c2.41-0.8,4.37-1.46,6.34-2.12c-1.86-4.75-4.06-5.24-9.16-2.23c-1.98-5.17-1.98-5.17-10.01-2.89c-0.43-0.92-0.63-2.31-1.4-2.79
				c-1.56-0.98-4.76-2.35-4.95-2.05c-2.56,3.95-5.02,1.04-7.9,0.69c-6.44-0.78-13.03-0.24-19.56-0.24c-0.27,0.54-0.55,1.09-0.82,1.63
				c1.27,0.42,2.54,0.85,3.82,1.27c-0.25,0.48-0.5,0.96-0.75,1.44c-8.47,1.11-17.55-0.81-25.23,5.16c12.01,0,24.03,0,36.58,0
				c-0.7,1.44-1.2,2.48-1.77,3.65c0.67,0.29,1.16,0.72,1.52,0.62c5.38-1.41,10.99,0.81,16.58-3.05c3.36-2.32,9.63-0.43,14.59-0.43
				c0.05,0.43,0.09,0.86,0.14,1.29c-2.29,0.4-4.58,0.77-6.86,1.2c-8.34,1.58-16.59,4.02-25.23,2.87c-0.96-0.13-2.49-0.34-2.72-0.92
				c-1.9-4.75-5.35-2.75-8.76-2.58c-7.63,0.39-15.31,0.34-22.95,0.07c-1.66-0.06-3.27-1.74-4.91-2.68c1.28-1.32,2.36-3.44,3.88-3.83
				C117.9,120.95,123.94,119.96,130.71,118.61z"/>
			<path d="M1021.56,34.4c3.78,1.08,6.38,0.86,8.07-3.38c1.75-4.4,6.37-4.04,10.45-3.17c1.1,0.24,2.85,1.35,3.12,1.04
				c4.26-4.91,9.32-0.97,13.93-1.43c3.68-0.37,5.48,2.15,5.66,4.95c0.12,1.95-1.81,4.03-2.85,6.04c-0.06,0.12-0.28,0.19-0.44,0.23
				c-13.51,3.28-27.11,4.5-40.81,1.36c-1.2-0.28-2.05-2.09-3.06-3.18C1017.61,36.03,1019.59,35.22,1021.56,34.4z M1020.54,37.07
				c0,0.44,0,0.88,0,1.33c11.16,2.66,22.38,0.53,33.55-0.18c1.89-0.12,3.89-1.79,5.41-3.2c0.8-0.74,0.67-2.48,0.96-3.77
				c-1.32-0.29-2.66-0.86-3.95-0.76c-0.97,0.08-1.88,1.02-2.79,1.56c-3.39-4.87-3.84-4.83-9.21,0.62c-1.03-0.68-2.05-1.49-3.18-2.08
				c-3.71-1.94-8.93-0.82-9.82,2.59c-1.06,4.05-3.52,4.28-6.78,3.92C1023.35,36.94,1021.94,37.07,1020.54,37.07z"/>
			<path d="M533.69,112.88c1.43-0.11,3,0.12,4.24-0.41c1.56-0.66,3.45-1.64,4.14-3.02c1.76-3.53,3.87-5.03,7.93-3.8
				c1.47,0.44,3.77,0.04,4.96-0.89c3.33-2.61,6.2-0.89,8.69,0.78c2.57,1.72,4.53,4.33,6.97,6.76c-1.73,3.77-7.68,6.31-11.9,4.99
				c-1.98-0.62-4.28-0.37-6.43-0.26c-5.04,0.24-10.08,0.93-15.1,0.79c-1.45-0.04-2.84-2.29-4.25-3.53
				C533.19,113.82,533.44,113.35,533.69,112.88z M561.17,110.82c-0.9-1.83-1.56-3.17-2.22-4.51c-1.53,1.2-3.05,2.39-4.66,3.65
				c-0.36-0.09-0.87-0.15-1.31-0.36c-5.78-2.8-5.71-2.78-8.18,2.99c-0.34,0.81-1.59,1.39-2.54,1.73c-1.21,0.44-2.55,0.54-3.83,0.78
				c5.32,2.52,10.1,0.99,14.94-0.82c1.5-0.56,3.88-1,4.89-0.23c3.75,2.87,6.3,0.46,10.34-1.53
				C565.33,111.78,563.17,111.28,561.17,110.82z"/>
			<path d="M488.27,111.99c2.62-2.63,4.27-4.29,5.93-5.94c0.34-0.34,0.82-0.99,1.05-0.91c6.57,2.05,11.96-6.42,18.97-2.01
				c1.94,1.22,5.01,0.53,7.26,1.46c1.13,0.46,2.16,2.62,2.1,3.96c-0.03,0.86-2.03,2.1-3.31,2.38c-4.21,0.9-8.47,1.62-12.75,2.06
				c-4.13,0.42-8.31,0.63-12.45,0.5C492.71,113.4,490.38,112.47,488.27,111.99z M514.57,108.04c-4.14-4.6-4.11-4.55-9.75-1.69
				c-1.28,0.65-3,1.49-4.17,1.13c-3.88-1.21-4.92,1.01-6.01,4.47c8.64-0.98,16.86-1.91,25.79-2.92c-1.09-1.79-1.71-2.81-2.52-4.13
				C516.65,106.07,515.73,106.94,514.57,108.04z"/>
			<path d="M1067.16,49.51c-3.4-0.28-6.71-0.38-9.94-0.95c-0.94-0.16-1.98-1.59-2.28-2.64c-0.13-0.47,1.37-1.92,2.28-2.08
				c6.57-1.2,13.15-2.5,19.79-3.04c2.08-0.17,4.34,1.9,6.51,2.94c-1.86,1.6-3.48,4-5.63,4.63
				C1074.48,49.37,1070.71,49.2,1067.16,49.51z M1071.51,44.17c0.03,0.73,0.06,1.46,0.09,2.19c1.89,0,3.81,0.22,5.63-0.15
				c0.48-0.1,0.61-1.97,1.14-3.92C1075.36,43.12,1073.43,43.65,1071.51,44.17z"/>
			<path d="M446.04,34.94c-4.9-0.64-9.89-0.99-14.72-2.09c-1.67-0.38-2.92-2.6-4.37-3.97c1.43-1.23,2.79-2.57,4.32-3.65
				c0.6-0.42,1.65-0.5,2.42-0.35c5.09,1.02,10.08,1.86,15.28,0.09c0.88-0.3,3.45,2.03,4,3.58c0.85,2.4,0.37,5.05-2.95,5.74
				C448.73,34.56,447.42,34.72,446.04,34.94z M451.3,28.64c-4.15-3.23-6.27,0.06-8.46,2.31c-4.95-4.51-9.62-4.89-13.16-0.68
				C437.17,31.21,444.27,34.39,451.3,28.64z"/>
			<path d="M1116.1,79.45c4.23-0.93,8.45-2.55,12.69-2.62c6.91-0.12,13.85,0.78,20.77,1.25c8.72,0.59,17.43,1.4,26.16,1.68
				c4.97,0.16,9.97-0.57,14.96-0.9c-3.93,1.28-7.91,3.78-11.78,3.63c-18.81-0.74-37.59-2.21-56.39-3.35
				C1120.39,79.01,1118.24,79.33,1116.1,79.45z"/>
			<path d="M845.75,32.21c5.08-4.14,15.62-4.87,19.16-1.53C859.45,36.97,851.18,37.74,845.75,32.21z"/>
			<path d="M566.92,56.38c2.63-3.38,5.26-6.76,7.9-10.14c0.61,0.48,1.21,0.95,1.82,1.43c-2.65,3.38-5.29,6.75-7.94,10.13
				C568.1,57.33,567.51,56.86,566.92,56.38z"/>
			<path d="M530.72,26.69c4.06-1.96,8.13-3.91,12.19-5.87c0.36,0.76,0.71,1.52,1.07,2.29c-4.12,1.83-8.25,3.66-12.37,5.49
				C531.31,27.96,531.02,27.33,530.72,26.69z"/>
			<path d="M582.9,51.25c-2.08,3.08-4.15,6.17-6.23,9.25c-0.55-0.37-1.11-0.74-1.66-1.11c2.08-3.12,4.16-6.25,6.23-9.37
				C581.8,50.43,582.35,50.84,582.9,51.25z"/>
			<path d="M552.02,815.16c3.35-4.22,6.7-8.45,10.06-12.67c0.55,0.36,1.1,0.71,1.65,1.07c-2.27,4.72-4.55,9.44-7.3,15.16
				c5.41-3.08,9.9-5.63,14.38-8.18c0.44,0.43,0.88,0.85,1.32,1.28c-0.52,1.45-1.05,2.9-1.29,3.59c-2.11-0.15-3.67-0.75-4.59-0.23
				c-2.58,1.45-4.85,3.45-7.36,5.05c-1.26,0.8-2.76,1.22-4.15,1.81c0.01-1.54-0.04-3.09,0.07-4.62c0.06-0.77,0.43-1.51,0.85-2.87
				c-2.32,2.17-4.22,3.95-7.12,6.66c0-3.2,0-4.96,0-6.87c-3.25,0.99-4.66,6.67-8.49,4.37c4.2-4.57,8.26-8.99,12.49-13.59
				c-0.42,2.96-0.9,6.27-1.37,9.59C551.43,814.85,551.72,815.01,552.02,815.16z"/>
			<path d="M987.75,762.47c-2.39,2.27-4.22,4.03-7.12,6.79c0-3.24,0-4.98,0-6.77c-3.33,0.76-4.51,6.56-8.48,4.14
				c4.1-4.43,8.16-8.83,12.43-13.45c-0.42,2.89-0.89,6.17-1.36,9.46c0.29,0.18,0.59,0.37,0.88,0.55c3.34-4.23,6.68-8.46,10.02-12.69
				c0.56,0.33,1.12,0.66,1.68,0.99c-2.29,4.8-4.57,9.6-7.28,15.29c5.41-3.12,9.84-5.68,14.6-8.43c0.85,4.76,0.73,3.92-2.09,4.33
				c-3,0.44-5.77,2.64-8.57,4.19c-0.84,0.47-1.35,1.55-2.2,2c-1.05,0.56-2.29,0.76-3.44,1.11c-0.12-1.07-0.44-2.16-0.31-3.19
				C986.67,765.53,987.2,764.33,987.75,762.47z"/>
			<path d="M833.79,835.91c8.68-1.2,17.35-2.4,26.03-3.6c-3.05-3.21-6.22-1.27-9.34-0.81c-3.44,0.51-6.88,0.97-10.31,1.53
				c-3.42,0.56-6.82,1.23-10.23,1.81c-3.27,0.55-6.55,1.04-9.82,1.56c-0.1-0.72-0.2-1.43-0.3-2.15c15.02-2.1,30.04-4.21,45.09-6.31
				c0.34,2.63,0.55,4.2,0.7,5.39c-10.46,1.48-20.99,2.97-31.51,4.46C834,837.15,833.89,836.53,833.79,835.91z"/>
			<path d="M856.05,817.92c-0.7,0.54-1.4,1.08-2.54,1.96c10.83,0,21.3,0,31.77,0c0.02,0.52,0.04,1.04,0.05,1.55
				c-3.36,0.35-6.73,1-10.09,0.98c-5.17-0.02-10.34,3.26-15.64-0.55c-1.42-1.02-4.48,0.36-6.8,0.45c-3,0.11-6.01,0.03-9.02,0.03
				c-0.08-0.71-0.16-1.43-0.24-2.14c4.19-0.72,8.39-1.45,12.58-2.17L856.05,817.92z"/>
			<path d="M856.12,818.02c2.98-0.92,5.95-1.83,9.27-2.85c-1.9-0.27-3.48-0.49-5.07-0.71c-0.01-0.5-0.01-1-0.02-1.49
				c5.97-0.31,11.93-0.61,17.9-0.92c0.09,0.71,0.18,1.41,0.27,2.12c-7.14,2.82-14.55,4.37-22.43,3.75
				C856.05,817.92,856.12,818.02,856.12,818.02z"/>
			<path d="M665.5,808.43c10.52-2.32,21.04-4.64,31.56-6.96c0.13,0.58,0.26,1.17,0.39,1.75c-10.51,2.36-21.02,4.73-31.53,7.09
				C665.78,809.69,665.64,809.06,665.5,808.43z"/>
			<path d="M1191.89,748.69c-1.95,0.24-3.89,0.47-7.18,0.87c2.78,0.63,4.24,0.96,5.71,1.29c0,0.26,0,0.52,0,0.78
				c-3.43,0.78-6.85,1.56-9.79,2.23c-1.19-1.52-2.13-2.72-3.73-4.77c5.08-0.75,9.91-1.46,14.73-2.17
				C1191.72,747.51,1191.81,748.1,1191.89,748.69z"/>
			<path d="M676.56,809.65c9.09-1.7,18.17-3.41,27.26-5.11c0.11,0.55,0.22,1.1,0.33,1.65c-9.04,1.83-18.08,3.66-27.11,5.49
				C676.88,811,676.72,810.32,676.56,809.65z"/>
			<path d="M836.94,801.68c-2.28-2.41-3.58-3.77-4.87-5.13c2.02-1.23,3.92-2.92,6.11-3.48c1.07-0.28,2.68,1.54,4.04,2.41
				C840.73,797.25,839.22,799.02,836.94,801.68z"/>
			<path d="M703.96,811.62c-7.82,1.92-15.64,3.84-23.47,5.76c-0.13-0.55-0.26-1.11-0.38-1.66c7.84-1.86,15.67-3.71,23.51-5.57
				C703.73,810.63,703.85,811.13,703.96,811.62z"/>
			<path d="M994.2,769.85c3.75-1.57,7.51-3.14,11.26-4.71c0.24,0.55,0.47,1.11,0.71,1.66c-3.7,1.69-7.39,3.39-11.09,5.08
				C994.79,771.2,994.5,770.52,994.2,769.85z"/>
			<path d="M562.19,821.69c3.74-1.52,7.48-3.03,11.22-4.55c0.23,0.56,0.46,1.12,0.69,1.68c-3.68,1.66-7.36,3.32-11.04,4.98
				C562.78,823.1,562.49,822.4,562.19,821.69z"/>
			<path d="M1048.67,820.06c-2.18-0.39-4.37-0.78-6.55-1.17c0.12-0.78,0.25-1.56,0.37-2.34c2.23,0.28,4.46,0.56,6.68,0.84
				C1049,818.28,1048.83,819.17,1048.67,820.06z"/>
			<path d="M126.1,741.12c-1.92-1.19-3.85-2.36-5.76-3.58c-6.29-4.03-12.57-8.06-18.83-12.13c-1.22-0.79-2.34-1.74-3.02-3.43
				c2.47,1.4,4.84,3,7.43,4.14c6.66,2.94,13.2,6.56,20.18,8.26c9.81,2.39,17.3,8.09,24.66,14.25c6.3,5.27,12.04,11.29,18.68,16.07
				c5.56,4,11.74,7.79,18.22,9.69c9.4,2.75,17.98,6.36,24.88,13.71c-2.08-1.01-4.37-1.74-6.22-3.07c-6.07-4.38-12.82-6.82-20.14-8.09
				c-2.23-0.39-4.43-1.41-6.44-2.52c-12.07-6.63-24.25-13.07-36.04-20.17c-6.29-3.79-11.86-8.8-17.75-13.25
				c1.54,0.87,3.09,1.74,4.62,2.62c9.78,5.67,19.56,11.35,29.34,17.03c0.26-0.4,0.52-0.8,0.78-1.2c-0.74-0.86-1.38-1.86-2.25-2.56
				c-6.8-5.42-13.32-11.29-20.59-15.98c-4.38-2.82-9.95-3.77-14.99-5.57c-0.13,0.43-0.26,0.87-0.4,1.3
				C123.69,738.13,124.89,739.63,126.1,741.12z"/>
			<path d="M220.29,803.24c-26.88-15.52-58.01-21.65-84.43-38.2C163.46,778.99,193.85,786.74,220.29,803.24z"/>
			<path d="M136.4,730.18c16.83,10.99,33.65,21.98,50.48,32.97c-0.31,0.48-0.62,0.96-0.93,1.43c-16.9-10.98-33.8-21.96-50.7-32.94
				C135.63,731.16,136.02,730.67,136.4,730.18z"/>
			<path d="M110.82,779.74c-9.71-6.49-19.43-12.97-29.14-19.46c0.23-0.37,0.47-0.73,0.7-1.1c9.82,6.33,19.63,12.66,29.45,18.98
				C111.5,778.69,111.16,779.22,110.82,779.74z"/>
			<path d="M91.43,753.31c-9.27-6.73-18.55-13.46-27.82-20.19c0.23-0.33,0.47-0.67,0.7-1c9.46,6.47,18.92,12.94,28.38,19.41
				C92.27,752.12,91.85,752.71,91.43,753.31z"/>
			<path d="M71.75,686.79c3.96,6.09,7.91,12.18,11.87,18.27c-5.4-5.15-10.29-10.64-13.01-17.53
				C70.99,687.29,71.37,687.04,71.75,686.79z"/>
			<path d="M101.24,740.05c5.81,3.97,11.61,7.94,17.42,11.9c-0.26,0.39-0.52,0.77-0.78,1.16c-5.81-4.02-11.62-8.04-17.43-12.07
				C100.71,740.71,100.98,740.38,101.24,740.05z"/>
			<path d="M32.09,706.29c3.94,3.28,7.88,6.57,11.83,9.85c-0.29,0.37-0.59,0.73-0.88,1.1c-4.02-3.19-8.04-6.38-12.06-9.57
				C31.35,707.21,31.72,706.75,32.09,706.29z"/>
			<path d="M504.26,205.84c9.6,1.6,14.23-7.82,15.94-15.69c0.42-1.93,0.55-3.94,1.09-5.84c1.38-4.84,4.14-7.74,9.72-7.16
				c5.44,0.57,10.91,1.01,16.37,1.04c7.95,0.04,15.93-0.01,23.86-0.62c7.02-0.54,7.77-0.09,8.92,6.84c0.45,2.67,2.04,5.2,3.33,7.68
				c0.96,1.86,2.27,3.54,3.64,5.64c-4.11-1.03-9.33-8.38-9.4-13.44c-0.05-3.12-1.05-4.89-3.99-3.55
				c-14.66,6.67-29.87,1.51-44.79,2.19c-4.41,0.2-5.65,1.63-6.07,5.65c-0.36,3.53-1.71,6.96-2.61,10.4
				c3.96,1.63,7.85,3.24,11.75,4.84c-0.11,0.48-0.23,0.97-0.34,1.45c-1.56-0.28-3.21-0.33-4.66-0.89c-7.9-3.06-7.88-3.1-14.34,3.68
				c4.51,0,8.44,0,12.37,0c-0.01,0.28-0.02,0.55-0.03,0.83c-4.33,0.67-8.66,1.33-12.99,2c0.01,0.28,0.02,0.56,0.02,0.84
				c3.32,0,6.64,0,9.96,0c0.11,0.42,0.23,0.85,0.34,1.27c-10.63,1.47-21.51,2.41-28.43,11.86c-2.25-3.82,0.07-6.89,8.03-9.53
				c-3.75-4.35-0.66-6.97,2.43-9.58C504.4,205.73,504.26,205.84,504.26,205.84z M563.64,181.37c0-0.35,0-0.7,0-1.05
				c-12.08,0-24.16,0-36.25,0c0,0.35,0,0.7,0,1.05C539.48,181.37,551.56,181.37,563.64,181.37z"/>
			<path d="M974.86,154.37c-10.33,0.57-20.65,1.5-30.98,1.6c-7.54,0.08-9.93-0.63-10.06,8.55c-0.06,3.82-1.64,7.06-6.56,8.04
				c0.19-1.06,0.07-2.03,0.48-2.42c3.68-3.44,4.85-7.73,4.66-12.6c-0.09-2.45,1.17-3.28,3.72-3.39c11.57-0.51,23.13-1.29,34.7-1.93
				c1.34-0.07,2.69,0.14,4.04,0.22C974.85,153.08,974.85,153.73,974.86,154.37z"/>
			<path d="M742.81,205.3c-0.84-2.46-2.21-4.88-2.39-7.39c-0.29-4.06-2.04-6.37-5.79-7.74c-1.6-0.59-2.85-2.13-3.62-3.92
				c4.92,3.68,13.07,4.52,12.25,13.61c-0.19,2.14,1.41,4.33,4.81,3.2c1.31-0.43,3.94,0.3,4.65,1.37c3.5,5.26,3.14,11.31,3.56,17.16
				c-0.66,0.11-1.32,0.23-1.98,0.34c-0.45-3.08-1.04-6.15-1.33-9.25c-0.7-7.33-2-8.37-8.92-6.78
				C743.64,205.7,743.23,205.5,742.81,205.3z"/>
			<path d="M562.89,211.94c-9.56,7.15-19.69,8.12-30.24,4.65c-0.05-0.45-0.09-0.9-0.14-1.34
				C542.63,214.14,552.76,213.04,562.89,211.94z"/>
			<path d="M489.8,208.61c-3.47,2.46-36.15,3.64-42.63,0C461.64,208.61,476.12,208.61,489.8,208.61z"/>
			<path d="M439.9,232.95c6.05,0,12.1,0,18.15,0c-0.02,0.36-0.04,0.72-0.06,1.09c-6.07,0-12.13,0-18.2,0
				C439.83,233.68,439.87,233.32,439.9,232.95z"/>
			<path d="M742.81,205.3c0.41,0.2,0.83,0.4,1.24,0.61c5.41,3.75,3.06,9.47,4.09,14.34c-0.4,0.1-0.8,0.2-1.2,0.3
				c-0.78-3.65-0.78-7.73-2.55-10.81c-1.64-2.86-5.16-4.63-7.85-6.89c0.39-0.46,0.78-0.92,1.17-1.38
				C739.41,202.74,741.11,204.02,742.81,205.3z"/>
			<path d="M602.03,216.41c-4.86,0-9.72,0-14.58,0c0.01-0.36,0.02-0.72,0.03-1.08c4.86,0,9.73,0,14.59,0
				C602.05,215.69,602.04,216.05,602.03,216.41z"/>
			<path d="M923.44,150.24c4.42,0,8.84,0,13.25,0c0.02,0.41,0.05,0.83,0.07,1.24c-4.41,0.22-8.83,0.44-13.24,0.66
				C923.5,151.52,923.47,150.88,923.44,150.24z"/>
			<path d="M556.69,666.39c-13.62-18.08-21.95-38.61-27.14-61.95c1.33,1.71,2.23,2.39,2.49,3.26c1.64,5.54,3.04,11.16,4.71,16.69
				c0.85,2.8,2.05,5.5,3.19,8.2c2.07,4.91,4.21,9.78,6.32,14.67c0.45,1.04,0.66,2.73,1.41,3.02
				C555.14,653.17,552.74,661.58,556.69,666.39z"/>
			<path d="M526.76,677.07c5,6.98,5.36,11.68,1.83,15.52c-0.76,0.83-2.18,1.46-3.26,1.4c-0.55-0.03-1.55-1.87-1.41-2.75
				C524.62,686.87,525.63,682.55,526.76,677.07z"/>
			<path d="M572.61,737.92c0.76,1.79,1.81,3.51,2.17,5.37c0.23,1.18-0.57,2.56-0.91,3.85c-1.39-0.49-3.24-0.59-4.07-1.56
				C567.33,742.73,568.34,740.06,572.61,737.92z"/>
			<path d="M536.49,691c0.78,1.63,2.15,3.27,2.12,4.88c-0.02,1.05-2.08,2.06-3.22,3.09c-0.83-1.39-2.17-2.72-2.33-4.18
				c-0.13-1.18,1.18-2.51,1.84-3.78C535.43,691.01,535.96,691,536.49,691z"/>
			<path d="M492.3,530.02c0.43,0.16,1.16,0.23,1.26,0.52c0.86,2.39,1.6,4.81,2.37,7.23c-0.86,0.08-1.89,0.51-2.54,0.18
				C490.61,536.55,490.27,533.71,492.3,530.02z"/>
			<path d="M636.6,675.17c4.66,0.05,8.61,4.46,7.51,8.65c-0.88,3.36-3.08,3.01-5.29,1.28C636.13,683,635.45,679.67,636.6,675.17z"/>
			<path d="M676.24,675.28c4.48,0.08,11.23,5.95,11.56,9.86c0.25,2.92-1.99,4.34-4.92,3.13C678.33,686.4,675.88,681.78,676.24,675.28
				z M679.34,678.13c-0.54,0.44-1.08,0.87-1.62,1.31c1.95,2.58,3.9,5.17,5.85,7.75c0.53-0.39,1.06-0.79,1.59-1.18
				C685.36,681.87,682.53,679.87,679.34,678.13z"/>
			<path d="M1071.15,637.3c-0.37-3.77-0.64-6.61-0.93-9.62c2.47,0.73,4.49,1.32,6.79,2c0.49,2.15,1.51,4.72-2.4,5.37
				c-0.3,0.05-0.64,0.26-0.82,0.5c-1.05,1.43-2.05,2.89-3.07,4.33c-2.26-1.84-4.8-3.44-6.72-5.59c-2.42-2.7-4.33-5.85-6.69-9.12
				c-3.3,2.9-5.48,4.81-7.97,6.99c-0.71-0.37-1.81-0.94-2.39-1.24c-1.17,2.17-2.28,4.22-3.39,6.28c-2.65-2.28-5.31-4.57-7.99-6.88
				c-0.31,1.52-0.33,3.76-1.3,5.48c-0.5,0.88-3.87,1.26-3.92,1.13c-1.89-5.2-3.54-10.49-5.23-15.76c0.65-0.18,1.3-0.37,1.95-0.55
				c1.33,4.38,2.66,8.76,3.99,13.14c0.4-0.03,0.8-0.06,1.2-0.08c0.51-4.08,1.02-8.16,1.53-12.24c0.52-0.15,1.04-0.3,1.55-0.45
				c2.11,4.58,4.23,9.15,6.34,13.73c0.64-0.17,1.28-0.33,1.92-0.5c-0.28-4.55-0.55-9.09-0.83-13.64c0.44-0.14,0.88-0.27,1.33-0.41
				c1.31,2.27,2.63,4.55,4.34,7.51c0.33-2.02,0.57-3.48,0.9-5.46c1.17,0.56,2.21,1.05,3.23,1.53c0.44-1.21,0.91-2.47,1.66-4.52
				c1.71,2.22,2.92,3.78,4.49,5.83c0.63-2.08,1.08-3.55,2.01-6.62C1063.43,625.77,1062.59,633.64,1071.15,637.3z"/>
			<path d="M1024.83,602.25c-0.56-1.85-1-3.33-1.55-5.17c-2.84,1.37-5.26,2.76-7.85,3.68c-1.07,0.38-2.9,0.12-3.63-0.61
				c-0.53-0.53-0.22-2.6,0.41-3.47c3.43-4.71,7.07-9.27,11.62-13.52c-1.21,2.67-1.98,5.68-3.74,7.92c-1.75,2.23-4.49,3.68-6.8,5.47
				c0.56,0.71,1.12,1.42,1.67,2.14c4.03-2.14,8.06-4.29,13.54-7.2c-1.12,3.22-1.75,5.01-2.38,6.8c0.39,0.23,0.78,0.45,1.18,0.68
				c1.48-2.38,2.97-4.77,4.88-7.85c0.83,2.8,1.53,5.15,2.09,7.05C1031.08,599.54,1028.08,600.84,1024.83,602.25z"/>
			<path d="M1000.42,561.67c-1.49,2.1-2.98,4.21-4.47,6.31c0.12,0.17,0.24,0.34,0.37,0.51c2.29-1.48,4.58-2.95,6.88-4.43
				c0.33,0.25,0.66,0.5,0.99,0.75c-0.71,2.8-1.41,5.6-2.12,8.4c0.51,0.31,1.02,0.62,1.53,0.92c1.91-2.06,3.82-4.13,5.89-6.35
				c0.47,3.71-2.33,7.84-5.31,8.13c-4.22,0.41-4.2-2.77-4.44-5.61c-0.43,0-0.77-0.08-1.07,0.01c-2.35,0.74-4.68,1.51-7.02,2.27
				c0.81-2.47,1.23-5.17,2.53-7.33c1.17-1.93,3.31-3.27,5.03-4.87C999.6,560.81,1000.01,561.24,1000.42,561.67z"/>
			<path d="M1032.25,499.15c1.22-0.57,2.18-1.02,3.55-1.65c0,1.25,0.28,2.48-0.05,2.68c-1.54,0.91-3.25,1.54-4.9,2.27
				c-1.97,0.71-4.11,2.29-5.64,1.83c-2.49-0.75-2.61-6.46-0.15-10.1c0.38,2.8,0.68,5.03,0.98,7.25c0.26,0.03,0.53,0.05,0.79,0.08
				c0.79-2.01,1.58-4.03,2.71-6.92C1030.69,496.52,1031.39,497.7,1032.25,499.15z"/>
			<path d="M974.35,605.29c2.58-2.87,5.17-5.74,7.75-8.61c-0.05,5.55-3.47,8.5-7.51,10.62c-0.73,0.38-3.69-1.46-4.01-2.7
				c-0.85-3.31,0.5-6.08,4.75-7.83c-0.97,2.97-1.73,5.28-2.49,7.59C973.36,604.67,973.86,604.98,974.35,605.29z"/>
			<path d="M1045.22,595.55c-0.23-3.06-0.46-6.12-0.7-9.34c2.59,2.53,4.21,10.12,2.42,12.62c-0.54,0.75-3.47,0.99-3.75,0.52
				c-1.83-3.06-3.24-6.37-4.79-9.6c0.66-0.34,1.32-0.68,1.98-1.02c1.27,2.39,2.54,4.77,3.8,7.16
				C1044.53,595.78,1044.88,595.66,1045.22,595.55z"/>
			<path d="M1067.84,662.8c0.85,2.01,1.7,4.02,2.72,6.42c1.65-1.31,2.96-2.36,4.27-3.4c0.35,0.27,0.71,0.54,1.06,0.8
				c-0.97,1.42-1.67,3.45-3.01,4.06c-1.06,0.48-3.53-0.33-4.28-1.37c-1.22-1.68-1.54-4.01-2.24-6.07
				C1066.84,663.1,1067.34,662.95,1067.84,662.8z"/>
			<path d="M877.26,483.19c-12.44,14.77-24.88,29.55-37.32,44.32c-0.49-0.26-0.99-0.51-1.48-0.77c0.94-1.64,1.49-3.93,2.9-4.8
				c5.31-3.27,9.14-7.47,10.93-13.53c0.35-1.19,1.31-2.47,2.36-3.14c6.93-4.44,10.8-11.49,15.63-17.75c1.64-2.12,4.12-3.6,6.21-5.37
				C876.74,482.51,877,482.85,877.26,483.19z"/>
			<path d="M828,453.1c-7.62,11.16-31.37,27.87-39.48,27.63C802.89,473.23,817.15,465.59,828,453.1z"/>
			<path d="M807.53,595.38c9.21,6.71,18.42,13.43,27.64,20.14c-0.48,0.63-0.95,1.26-1.43,1.89c-9.08-6.88-18.15-13.77-27.23-20.65
				C806.85,596.3,807.19,595.84,807.53,595.38z"/>
			<path d="M780.44,475.42c9.03-4.31,18.06-8.62,27.09-12.93C799.61,469.21,791.14,474.86,780.44,475.42z"/>
			<path d="M845.38,544.38c11.97-7.18,16.31-18.91,23.46-28.38C865.65,527.47,853.33,542.71,845.38,544.38z"/>
			<path d="M774.68,566.82c8.37,6.81,16.75,13.62,25.12,20.43c-0.22,0.35-0.44,0.71-0.65,1.06c-2.33-1.3-4.87-2.32-6.93-3.94
				c-5.64-4.45-11.1-9.14-16.6-13.77c-0.83-0.7-1.51-1.57-2.26-2.36C773.8,567.76,774.24,567.29,774.68,566.82z"/>
			<path d="M863.54,504.03c-4.68,7.85-9.67,16.2-14.65,24.55C851.05,518.05,857.87,507.52,863.54,504.03z"/>
			<path d="M799.91,463.41c-6.21,2.75-12.43,5.5-18.64,8.25c-0.25-0.57-0.51-1.13-0.76-1.7c6.24-2.73,12.48-5.46,18.72-8.19
				C799.46,462.32,799.69,462.86,799.91,463.41z"/>
			<path d="M823.89,440.64c-5.09,4.75-10.18,9.51-15.27,14.26C811.09,447.33,817,443.46,823.89,440.64z"/>
			<path d="M833.21,437.94c-4.75,4.43-8.92,8.32-13.1,12.22C822.68,444.53,826.33,440.06,833.21,437.94z"/>
			<path d="M812.24,472.37c3.56-2.77,7.12-5.53,10.69-8.3c0.32,0.41,0.65,0.83,0.97,1.24c-3.5,2.82-7,5.64-10.5,8.46
				C813.01,473.3,812.63,472.83,812.24,472.37z"/>
			<path d="M8.08,168.29c7.16-0.76,14.33-1.52,21.54-2.28c-1.56-7.47-0.05-9.27,7.21-9.27c19.79-0.02,39.59-0.1,59.38,0
				c3.72,0.02,7.44,0.75,11.15,1.16c0.16,0.49,0.32,0.99,0.48,1.48c-1.61,1.21-3.22,2.41-5.55,4.16c-0.24-2.14-0.35-3.16-0.49-4.47
				c-12.09,0-24.2-0.05-36.31,0.08c-1.06,0.01-2.6,0.96-3.08,1.91c-1.62,3.2-2.87,6.59-4.48,10.43c13.14,0.32,25.52,0.98,37.67-2.82
				c6.33-1.98,12.61-1.32,18.57,1.86c0.88,0.47,2.37,0.76,3.11,0.33c6.08-3.5,9.16,1.86,13.16,4.68c-0.74-0.11-1.65,0.02-2.2-0.36
				c-3.69-2.57-7.28-3.07-11.97-2.17c-3.78,0.73-8.1-1.75-12.25-2.37c-2.69-0.41-5.51-0.27-8.23,0.01
				c-9.71,1.01-19.38,2.56-29.11,3.18c-7.47,0.48-7.5-0.01-9.61,8.75c11.6,0,23.01,0,34.42,0c0.03,0.53,0.06,1.05,0.08,1.58
				c-14.25,1.86-29.79-3.54-43.62,7.31c14.87,0,28.62,0,42.37,0c0.06,0.39,0.12,0.78,0.18,1.17c-1.41,0.22-2.83,0.61-4.24,0.62
				c-13.15,0.08-26.31-0.12-39.45,0.28c-2.65,0.08-5.49,1.99-7.79,3.67c-3.83,2.8-7.3,6.09-10.92,9.17c-0.33-0.3-0.66-0.59-0.98-0.89
				c0.73-0.82,1.44-1.68,2.21-2.47c2.54-2.6,4.99-5.32,7.71-7.71c2.08-1.82,4.35-3.59,6.84-4.74c6.25-2.88,11.03-6.82,12.04-14.41
				c-0.93-0.08-1.9-0.16-3.4-0.28c2.16-3.94,4.21-7.34,5.9-10.92c0.71-1.51,0.67-3.37,0.97-5.08c-2.59-0.21-5.21-0.75-7.74-0.44
				c-0.73,0.09-1.43,2.56-1.68,4.02c-0.59,3.41-0.7,6.91-1.42,10.28c-0.34,1.6-1.65,3-3.18,4.14c0.72-4.81,1.35-9.64,2.2-14.43
				c0.61-3.4-0.76-4.61-4.01-4.39c-4.16,0.28-8.32,0.45-12.76,0.69c2.47,7.53,2.52,7.89-4.4,8.91c-6.02,0.89-12.17,0.85-18.27,1.22
				C8.1,169.34,8.09,168.81,8.08,168.29z"/>
			<path d="M156.95,183.75c3.63,2.96,8.23,4.47,9.16,10.16c-3.78-2.91-6.84-5.26-9.9-7.63c-3.53-2.72-3.4-3.58,0.82-5.61
				c0.7-0.34,1.36-0.76,2.47-1.39c-8.02,0.81-15.47,1.35-22.84,2.46c-1.67,0.25-3.62,2.18-4.41,3.84c-1.41,2.95-1.9,6.34-3.06,9.43
				c-0.63,1.66-1.87,3.1-2.83,4.63c-0.47-0.23-0.94-0.47-1.4-0.7c0.55-1.61,1.12-3.22,1.65-4.84c1.45-4.37,1.86-9.59,4.6-12.89
				c2.85-3.44,7.68-6.04,12.11-7.18c5.52-1.41,11.54-1.28,17.32-1.1c1.97,0.06,4.25,1.97,5.71,3.63c2.41,2.74,4.26,5.97,5.74,9.33
				C165.34,179.99,163.64,179.65,156.95,183.75z M160.87,176.59c-8.14-2.6-20.08-0.94-24.25,3.62
				C145.56,178.87,153.21,177.73,160.87,176.59z"/>
			<path d="M123.33,156.21c1.54,1.71,3.84,3.18,4.47,5.17c1.14,3.66,3.29,4.42,6.65,4.28c9.31-0.39,18.63-0.49,27.93-1
				c1.67-0.09,3.27-1.56,4.9-2.4c-1.8-0.73-3.68-2.22-5.39-2.05c-9.37,0.95-18.69,2.31-28.04,3.5c-0.44,0.06-0.92-0.2-2.12-0.48
				c3.93-3.95,8.7-3.85,13.04-4.21c5.62-0.48,11.33,0.03,16.98-0.27c2.37-0.13,4.69-1.25,7.34-2c1.6,4.24,5.85,1.57,10.15,1.26
				c-0.93,3.73-1.68,6.73-2.44,9.74c-0.35-0.06-0.7-0.12-1.06-0.18c0.06-1.26,0.45-2.62,0.09-3.75c-0.43-1.35-1.79-3.63-2.26-3.52
				c-1.6,0.39-3.97,1.43-4.26,2.64c-0.83,3.51-0.78,7.22-1.1,11.14c-0.72-0.42-1.46-0.68-1.44-0.87c0.5-6.62-3.5-6.69-8.5-6.4
				c-8.44,0.49-16.92,0.29-25.37,0.68c-1.47,0.07-2.86,1.61-3.88,2.23c-1.96-4.05-4-8.27-6.04-12.48
				C123.09,156.89,123.21,156.55,123.33,156.21z"/>
			<path d="M263.25,158.72c-11.51,0-23.02,0-34.52,0c0-0.4,0.01-0.79,0.01-1.19c15.18,0,30.36,0,45.55,0
				c0.09,0.64,0.18,1.28,0.27,1.92c-10.69,1.76-21.38,3.53-32.07,5.29c0,0.52,0.01,1.03,0.01,1.55c5.71,0.41,11.41,0.81,17.12,1.22
				c0.18,0.7,0.35,1.4,0.53,2.1c-3.72,1.65-7.43,3.3-11.15,4.95c-0.29-0.5-0.58-1-0.87-1.51c1.6-1.09,3.2-2.18,5.5-3.75
				c-3.34-0.34-6.02-0.55-8.69-0.9c-2.44-0.32-4.87-0.8-7.3-2C245.26,160.04,254.84,161.88,263.25,158.72z"/>
			<path d="M39.8,200.27c14.77-4.55,30.16-0.69,45.06-3.57c0.04,0.46,0.07,0.92,0.11,1.38c-15.09,1.22-30.18,2.44-45.28,3.65
				C39.73,201.24,39.77,200.76,39.8,200.27z"/>
			<path d="M101.47,172.16c1.69,6.66,3.39,13.32,5.13,20.16C102.97,191.01,99.19,178.2,101.47,172.16z"/>
			<path d="M109.18,173.44c1.18,5.3,2.36,10.59,3.55,15.89c-4.85-4.47-5.02-10.07-4.93-15.74
				C108.25,173.54,108.72,173.49,109.18,173.44z"/>
			<path d="M288.39,159.04c4.19-0.5,8.39-1,12.58-1.49c0.07,0.56,0.15,1.13,0.22,1.69c-4.19,0.56-8.37,1.13-12.56,1.69
				C288.56,160.3,288.47,159.67,288.39,159.04z"/>
			<path d="M630.32,420.73c-3.28-0.31-5.56-0.52-9.05-0.85c3.69-2.84,6.04-4.65,8.45-6.5c-4.39-0.56-8.47-1.08-12.54-1.59
				c0.02-0.26,0.03-0.51,0.05-0.77c6.48,0,12.95,0,19.43,0c0.18,0.33,0.35,0.67,0.53,1c-2.19,1.49-4.37,2.98-7.3,4.98
				c2.83,0.52,4.78,0.88,6.73,1.24c0.18,0.62,0.36,1.24,0.54,1.86c-4.82,2.15-9.65,4.3-14.47,6.46c0.09,0.33,0.17,0.66,0.26,1
				c2.92-0.71,5.83-1.42,8.75-2.13c0.15,0.68,0.3,1.37,0.44,2.05c-20.66,4.85-41.32,9.71-61.98,14.56c-0.19-0.84-0.39-1.67-0.58-2.51
				c10.29-2.06,20.83-3.32,30.81-6.39C610.38,430.05,619.82,425.17,630.32,420.73z"/>
			<path d="M645.46,430.41c-18.66,5.52-37.59,9.83-56.59,13.83c-0.1-0.47-0.2-0.94-0.3-1.4c18.89-4.77,37.77-9.55,56.66-14.32
				C645.31,429.15,645.39,429.78,645.46,430.41z"/>
			<path d="M508.57,453.83c1.71-3.54,26.47-12.6,34.43-11.33C530.68,446.55,519.48,450.24,508.57,453.83z"/>
			<path d="M590.89,428.89c-7.76,1.45-15.51,2.91-23.78,4.46C570.08,429.65,583.62,427.03,590.89,428.89z"/>
			<path d="M513.62,455.53c4.72-3.43,10.42-3.84,15.92-4.87c0.14,0.42,0.28,0.84,0.41,1.26c-5.26,1.75-10.53,3.5-15.79,5.26
				C513.98,456.63,513.8,456.08,513.62,455.53z"/>
			<path d="M109.87,322.24c5.28-2.93,10.6-5.8,15.83-8.83c3.01-1.75,6.14-3.44,8.74-5.71c10.41-9.09,22.69-11.42,35.89-10.17
				c10.95,1.03,21.68,1.57,31.09-5.67c0.89-0.68,2.11-1.2,3.22-1.28c9.64-0.72,19.29-1.33,29.27-0.99
				c-3.49,0.73-6.96,1.65-10.48,2.12c-2.45,0.33-5.01,0.23-7.49-0.02c-5.52-0.57-10.57,0.55-15.16,3.67
				c-6.86,4.66-14.6,4.91-22.52,4.86c-8.29-0.05-16.7-0.37-24.84,0.83c-5.02,0.74-9.83,3.78-14.41,6.38
				c-9.46,5.38-18.7,11.15-28.03,16.75C110.61,323.53,110.24,322.88,109.87,322.24z"/>
			<path d="M107.44,333.3c4.38-1.26,8.69-3.15,13.15-3.62c7.57-0.8,13.98-3.44,18.48-9.55c6.83-9.28,15.86-12.56,27.2-12.7
				c8.37-0.1,16.69-2.72,25.08-3.79c10.71-1.37,21.46-2.32,32.2-3.4c0.36-0.04,0.76,0.39,2.62,1.43c-8.7,0.96-15.96,1.67-23.2,2.59
				c-15.16,1.92-30.32,3.88-45.46,5.96c-6.53,0.9-11.01,5.23-15.1,9.92c-5.55,6.36-11.78,11.05-20.79,11.21
				c-4.77,0.09-9.52,1.24-14.28,1.91L107.44,333.3z"/>
			<path d="M107.34,333.24c-7.09,2.12-14.49,3.56-21.17,6.57c-5.95,2.68-11,7.29-16.67,10.69c-3.16,1.9-6.75,3.06-10.15,4.56
				c-0.17-0.37-0.34-0.73-0.5-1.1c2.56-1.23,5.23-2.28,7.67-3.73c5.11-3.04,10.68-5.65,15.01-9.58c7.61-6.92,16.4-7.96,25.9-7.37
				C107.44,333.3,107.34,333.24,107.34,333.24z"/>
			<path d="M18.04,364.47c9.47-2.4,18.94-4.8,28.41-7.21c0.14,0.53,0.27,1.06,0.41,1.59c-9.47,2.39-18.94,4.78-28.41,7.17
				C18.31,365.5,18.18,364.98,18.04,364.47z"/>
			<path d="M210.36,311.9c-4.23,0.95-8.45,1.91-12.68,2.86c-0.12-0.62-0.25-1.23-0.37-1.85c4.35-0.82,8.7-1.65,13.05-2.47
				C210.35,310.93,210.36,311.42,210.36,311.9z"/>
			<path d="M243.42,364.9c18.36-0.82,36.73-1.63,55.09-2.45c0.07,0.21,0.13,0.42,0.2,0.63c-4.41,0.63-8.8,1.61-13.23,1.82
				c-10.62,0.52-21.26,0.58-31.88,1.08c-1.64,0.08-3.14,1.94-4.84,2.22c-1.77,0.3-3.69-0.31-5.54-0.53
				C243.29,366.75,243.36,365.82,243.42,364.9z"/>
			<path d="M296.44,371.33c-10.75,0.56-21.5,1.11-32.25,1.67c10.28-5.26,21.29-3.14,32.07-3.45
				C296.32,370.14,296.38,370.73,296.44,371.33z"/>
			<path d="M292.79,373.09c9.86,0,19.71,0,29.57,0c0.03,0.29,0.06,0.58,0.08,0.88c-9.84,0-19.68,0-29.52,0
				C292.88,373.67,292.83,373.38,292.79,373.09z"/>
			<path d="M269.84,359.67c-6.05,0.36-12.11,0.73-18.16,1.09c-0.04-0.53-0.08-1.06-0.12-1.59c6.02-0.48,12.03-0.96,18.05-1.44
				C269.68,358.37,269.76,359.02,269.84,359.67z"/>
			<path d="M791.89,293.14c-6.44-1.72-12.88-3.44-19.33-5.17c7.5-2.28,13.66,1.05,19.75,4.7
				C792.17,292.84,792.03,292.99,791.89,293.14z"/>
			<path d="M792.31,292.68c2.92,1.51,5.78,3.17,8.78,4.49c2.96,1.3,6.07,2.27,9.84,3.65c-8.29,0.98-13.57-3.58-19.04-7.68
				C792.03,292.99,792.17,292.84,792.31,292.68z"/>
			<path d="M794.6,700.86c-3.55,2.65-5.88,4.59-8.46,6.1c-0.39,0.23-1.94-1.52-2.96-2.35c2.17-2.24,4.26-4.58,6.63-6.6
				C790.11,697.75,791.87,699.2,794.6,700.86z"/>
			<path d="M344.93,785.02c6.25-2.03,12.39-4.61,18.78-5.95c7.63-1.6,14.74-4.13,21.37-8.14c-4.02,2.69-7.74,6.09-12.13,7.88
				c-5.79,2.35-12.06,3.59-18.2,5C351.56,784.55,348.21,784.64,344.93,785.02z"/>
			<path d="M59.57,265.4c10.98-1.23,21.77-5.58,33.09,0.11c2.68,1.35,6.41,0.72,9.66,0.79c4.27,0.1,8.54-0.01,12.81-0.02
				c5.42-0.01,10.84,0,16.26,0c0.1,0.44,0.2,0.88,0.3,1.32c-1.38,0.46-2.73,1.13-4.15,1.35c-12.14,1.93-24.29,3.77-36.42,5.68
				c-1.96,0.31-3.93,0.7-5.83,1.29c-6.54,2.04-12.45,2.19-19.01-1.45c-4.08-2.27-10.08-2.21-15.01-1.57
				c-8,1.04-15.84,3.41-23.74,5.24c-1.21,0.28-2.42,0.62-3.96,0.07c0.88-0.49,1.71-1.16,2.66-1.45c10.48-3.19,20.96-6.74,32.13-5.8
				c4.01,0.34,7.9,2.03,11.88,2.91c3.33,0.73,7.19,2.48,9.99,1.48c11.34-4.05,23.18-4.1,34.84-5.61c0.4-0.05,0.77-0.36,1.1-1.31
				c-5.29,0-10.58,0.08-15.87-0.03c-2.75-0.06-6.03,0.44-8.15-0.83c-7.78-4.68-15.63-3.42-23.7-1.58c-2.85,0.65-5.77,1.02-8.65,1.52
				C59.72,266.79,59.64,266.1,59.57,265.4z"/>
			<path d="M136,252.92c-3.53,1.12-7.01,2.91-10.6,3.2c-6.45,0.52-13.01-0.25-19.46,0.25c-6.56,0.51-13.04,1.9-19.56,2.9
				c-0.74,0.11-1.49,0.17-2.67-0.83c1.62-0.79,3.18-2.05,4.87-2.28c6.39-0.89,12.81-1.67,19.24-2.06c9.4-0.56,18.83-0.75,28.25-1.09
				C136.07,253.01,136,252.92,136,252.92z"/>
			<path d="M239.48,309.96c5.19,1.32,10.48,2.34,15.55,4.02c17.11,5.67,34,4.27,50.81-1.15c2.03-0.65,3.88-1.89,5.78-2.9
				c1.91-1.01,3.69-2.72,5.69-3.02c9.6-1.47,19.26-2.56,28.9-3.78c0.1,0.64,0.2,1.28,0.3,1.92c-4.35,0.59-8.69,1.56-13.05,1.69
				c-8.8,0.26-16.6,2.97-24.72,6.51c-15.69,6.83-32.39,7.95-49.23,3.56c-4.96-1.29-10.05-2.08-15.05-3.22
				c-1.87-0.42-3.67-1.18-5.5-1.78C239.14,311.19,239.31,310.58,239.48,309.96z"/>
			<path d="M355.28,313.64c10.84,1.83,20.63,4.34,30.53,4.92c9.71,0.57,19.53-0.83,28.62-1.33
				C407.82,323.91,366.62,322.35,355.28,313.64z"/>
			<path d="M382.37,334.2c-7.97,0-15.95,0-23.92,0c0-0.48,0-0.96,0-1.45c7.98,0,15.95,0,23.93,0
				C382.37,333.24,382.37,333.72,382.37,334.2z"/>
			<path d="M318.3,313.93c5.92-0.37,11.84-0.74,17.76-1.11c0.05,0.46,0.11,0.92,0.16,1.38c-5.93,0.55-11.86,1.09-17.79,1.64
				C318.39,315.2,318.34,314.57,318.3,313.93z"/>
			<path d="M1111.06,277.02c-4.86-0.76-9.85-1.08-14.52-2.46c-2.11-0.62-3.6-3.36-4.75-5.91c4.87,4.65,10.13,7.62,16.88,5.88
				c2.12-0.55,3.96-2.19,6.45-3.64c-3.64-2.14-6.23-3.67-8.83-5.19c0.24-0.46,0.47-0.91,0.71-1.37c2.6,0.5,5.2,1,7.8,1.5
				c0.13-0.35,0.26-0.69,0.39-1.04c-1.43-0.77-2.86-1.54-5.47-2.95c3.99,0,6.76,0,9.54,0c0.1-0.31,0.19-0.62,0.29-0.93
				c-2.51-1.43-5.03-2.85-7.54-4.28c0.19-0.24,0.39-0.47,0.58-0.71c3.98,0,7.95,0,11.93,0c0.08-0.29,0.15-0.59,0.23-0.88
				c-2.08-0.91-4.16-1.82-6.24-2.73c0.02-0.42,0.04-0.83,0.06-1.25c7.01,0.65,14.47,4.35,20.71-2.05c-2.82-5.04-8.17-3.45-13.49-4.92
				c2.8-1.97,4.55-3.21,6.31-4.44c-0.06-0.42-0.12-0.85-0.18-1.27c-2.47,0-4.97,0.26-7.39-0.09c-1.7-0.24-3.27-1.32-4.9-2.02
				c1.05-1.36,1.87-3.19,3.26-3.86c0.67-0.33,2.36,1.59,3.66,2.36c1.27,0.75,2.58,1.73,3.97,1.91c4.91,0.65,5.4,1.48,2.59,6.22
				c1.52,0.62,3.19,0.94,4.38,1.88c1.54,1.21,3.64,2.87,3.7,4.41c0.05,1.26-2.3,3.11-3.97,3.87c-4.27,1.95-8.93,3.11-13.07,5.29
				c-1.7,0.9-2.4,3.63-3.66,5.45c-1.02,1.47-2.21,2.82-3.75,4.75c1.92,4.6-2.71,6.14-5.81,8.59L1111.06,277.02z"/>
			<path d="M1139.27,276.72c2.89,0.36,5.78,0.71,8.84,1.09c-2.87,1.95-8.43,2.22-10.45,0.3c-0.84-0.8-1.56-3.22-1.11-3.65
				c2.14-2.09,4.68-3.77,7.11-5.56c0.61-0.45,1.5-0.62,1.96-1.17c3.45-4.1,6.97-3.98,11.04,1.42c-2.92-1.03-4.91-1.74-6.9-2.44
				c-0.42,0.54-0.84,1.07-1.27,1.61c1.47,0.55,2.95,1.1,4.42,1.66c-0.3,0.45-0.6,0.9-0.9,1.34c-1.54-0.07-3.09-0.15-5.09-0.25
				c0.43,0.95,0.79,1.78,1.56,3.48c-3.47,0-6.32,0-9.18,0C1139.28,275.27,1139.28,276,1139.27,276.72z"/>
			<path d="M1110.94,277.14c2.05,1.09,4.09,2.19,6.46,3.45c0.43-1.5,0.87-2.98,1.29-4.45c6.97,0.9,6.98,0.9,5.57-5.63
				c1.53,0.07,2.93,0.23,4.31,0.17c1.19-0.05,2.37-0.35,3.55-0.54c0.28-0.53,0.57-1.06,0.85-1.59c-1.72-0.88-3.44-1.77-5.73-2.94
				c4.65-3.07,5.56,0.35,6.39,1.98c0.53,1.02-0.21,3.55-1.22,4.36c-3.69,2.95-7.95,5.2-11.59,8.19c-4.59,3.77-7.97,2.86-9.77-3.12
				C1111.06,277.02,1110.94,277.14,1110.94,277.14z"/>
			<path d="M1136.13,580c5.77-0.38,5.77-0.38,4.47-7.57c2.67,0,5.4,0,8.12,0c-0.9-2.42-1.8-4.84-2.68-7.18c2.63,0,5.6,0,10.06,0
				c-2.83-3.34-4.59-5.42-7.4-8.73c3.57,0.65,5.91,1.07,8.04,1.46c0,3.01,0,5.51,0,7.38c3.73,1.21,7.08,2.06,10.2,3.42
				c1.29,0.57,2.04,2.29,3.11,3.43c0.99,1.05,1.94,2.67,3.12,2.89c3.33,0.62,6.25,0.88,3.56,5.44c-0.46,0.78,0.37,2.33,0.6,3.53
				c-0.36,0.25-0.72,0.51-1.08,0.76c-1.02-1.76-2.04-3.53-3.11-5.38c0.02-0.01,1.14-0.69,3.35-2.05c-3.99-0.68-6.93-1.18-10.46-1.77
				c2.5-3.75,0.79-5.51-2.47-6.4c-3.5-0.96-6.85-1.47-7.13,4.24c-1.71-1.07-2.76-1.72-3.16-1.97c-2.87,1.9-5.51,3.64-8.14,5.39
				c-3.32,5.45-3.83,5.69-8.97,3.05C1136.18,579.94,1136.13,580,1136.13,580z"/>
			<path d="M1185.67,580.68c1.25-0.98,2.21-1.75,3.55-2.81c-1.79-0.17-3.06-0.29-4.34-0.41c-0.02-0.55-0.04-1.1-0.06-1.65
				c1.8,0,3.71-0.35,5.34,0.17c0.65,0.21,0.64,2.37,0.97,3.63c0.45,1.72,0.93,3.43,1.5,5.52c-3.9,0.67-2.41,8.08-8.8,7.02
				c1.75-3.02,3.3-5.69,5.18-8.93C1188.68,582.97,1187.46,582.05,1185.67,580.68z"/>
			<path d="M906.09,480.93c-0.52,1.52-0.85,3.15-1.6,4.54c-2.23,4.14-1.44,7.9,2.68,9.59c7.83,3.21,7.08,10.59,9.6,16.4
				c-0.41,0.23-0.83,0.46-1.24,0.69c-1.07-2.11-2.14-4.23-3.62-7.17c-0.55,4.06-1.02,7.54-1.49,11.02c-0.69-0.11-1.38-0.22-2.06-0.32
				c0.74-4.77,1.48-9.55,2.22-14.32c0.56-0.09,1.12-0.19,1.67-0.28c-2.47-1.8-4.94-3.61-8.22-5.99c-0.96,4.14-1.77,7.65-2.58,11.16
				c-0.36,0.01-0.71,0.03-1.07,0.04c0-1.24-0.09-2.5,0.02-3.73c0.35-3.91,0.31-7.93,1.29-11.68c0.9-3.46,2.93-6.62,4.46-9.91
				C906.14,480.97,906.09,480.93,906.09,480.93z"/>
			<path d="M916.57,481.33c1.85,2.67,3.87,5.24,5.43,8.07c0.41,0.74-0.47,3.17-1.03,3.27c-1.19,0.21-3.28-0.24-3.71-1.08
				c-1.45-2.79-2.32-5.87-3.42-8.84C914.75,482.27,915.66,481.8,916.57,481.33z"/>
			<path d="M939.04,312.33c4.26-3.29,14.33-1.8,17.43,2.9C950.64,314.26,944.88,313.3,939.04,312.33z"/>
			<path d="M945.57,315.85c-4.64,2.38-9.26,3.38-13.79,0C936.38,315.85,940.97,315.85,945.57,315.85z"/>
			<path d="M477.07,347.62c7.67-0.68,15.34-1.35,23.01-2.03c0.06,0.6,0.12,1.2,0.18,1.8c-7.68,0.64-15.36,1.27-23.04,1.91
				C477.18,348.73,477.13,348.17,477.07,347.62z"/>
			<path d="M506.12,342.48c-6.33,0.38-12.66,0.76-18.98,1.15c-0.03-0.48-0.06-0.97-0.09-1.45c6.35-0.48,12.7-0.96,19.06-1.43
				C506.11,341.32,506.11,341.9,506.12,342.48z"/>
			<path d="M223.96,476.91c5.85-2.28,21.17-1.08,29.72,3.06C243.85,478.95,234.02,477.94,223.96,476.91z"/>
			<path d="M229.24,471.89c3.68,0,7.37,0,11.05,0c-0.01,0.43-0.02,0.86-0.03,1.29c-3.69,0-7.37,0-11.06,0
				C229.22,472.75,229.23,472.32,229.24,471.89z"/>
			<path d="M242.89,469c-5.45-0.27-10.89-0.55-16.34-0.82c0.03-0.24,0.06-0.47,0.1-0.71c5.47,0,10.93,0,16.4,0
				C242.99,467.98,242.94,468.49,242.89,469z"/>
			<path d="M1147.9,220.45c-3.23-3.85-2.49-7.05-1.35-10.01c1.01-2.61,2.41-5.15,4.05-7.42c4.22-5.81,7.12-11.91,6.59-19.37
				c-0.13-1.8,1.17-3.7,2.51-5.3c0.38,5.03-4.1,9.95,1.01,14.79c0.24,3.23-5.17,6.02-0.94,11.33c1.28-4.96,2.27-8.78,3.26-12.61
				c0.56,0.12,1.12,0.24,1.68,0.37c-0.8,3.63-1.08,7.48-2.54,10.82c-2.03,4.65-0.8,7.53,3.76,10.1c0.1-1.72,0.08-3.12,0.29-4.48
				c0.22-1.41,0.66-2.78,1.12-4.63c3.36,14.61,6.56,28.55,9.77,42.49c-0.7,0.19-1.4,0.39-2.1,0.58c-2.47-12.88-7-24.87-13.74-35.9
				c-0.65,0.09-1.3,0.18-1.95,0.27c-0.25,2.14-0.5,4.28-0.74,6.42c-0.43,0.07-0.86,0.15-1.28,0.22c-0.13-2.01-0.42-4.03-0.35-6.04
				c0.07-2.04,0.5-4.06,0.77-6.1c-0.42-0.08-0.83-0.16-1.25-0.23c-1.85,3.78-3.71,7.55-5.56,11.33c-0.56-0.26-1.11-0.53-1.67-0.79
				c2.06-4.79,4.13-9.59,6.19-14.38C1149.57,206.4,1147.51,212.44,1147.9,220.45z"/>
			<path d="M181.7,588.94c3.36,1.56,6.47,2.7,9.22,4.42c1.12,0.7,1.45,2.65,2.14,4.03c-1.58,0.62-3.28,1.95-4.72,1.71
				c-2.89-0.48-5.86-1.46-8.37-2.95c-1.4-0.83-1.95-3.09-2.88-4.71C178.55,590.65,180.01,589.86,181.7,588.94z M190.13,597.59
				c0.26-0.87,0.52-1.74,0.78-2.61c-3.37-1.13-6.74-2.25-10.11-3.38c-0.27,0.85-0.54,1.69-0.81,2.54
				C183.36,595.28,186.75,596.44,190.13,597.59z"/>
			<path d="M716.5,399.62c-6.47,4.56-20.7,5.68-25.04,2.27C699.61,401.15,707.73,400.41,716.5,399.62z"/>
			<path d="M224.86,548.37c-3.65,0.79-7.29,1.58-10.94,2.37c-0.14-0.69-0.29-1.38-0.43-2.07c3.66-0.73,7.32-1.46,10.97-2.19
				C224.6,547.11,224.73,547.74,224.86,548.37z"/>
			<path d="M642.67,192.15c-10.85,0.96-21.7,1.92-32.55,2.88c-0.07-0.79-0.14-1.59-0.21-2.38c10.89-0.78,21.78-1.56,32.67-2.35
				C642.61,190.92,642.64,191.54,642.67,192.15z"/>
			<path d="M611.06,529.61c-0.98,4.33-1.97,8.66-2.95,12.98c-0.61-0.13-1.22-0.26-1.83-0.39c0.94-4.34,1.87-8.69,2.81-13.03
				C609.74,529.32,610.4,529.46,611.06,529.61z"/>
			<path d="M290.71,183.49c-5.83,4.08-22.44,5.42-28.55,2.23C271.79,184.97,281.03,184.25,290.71,183.49z"/>
			<path d="M425.4,609.44c-5.6-0.89-11.21-1.77-16.81-2.66c0.09-0.57,0.18-1.14,0.27-1.71c5.55,0.93,11.1,1.87,16.65,2.8
				C425.47,608.4,425.44,608.92,425.4,609.44z"/>
			<path d="M368.25,450.21c5.34,2.76,8.35,8.43,7.36,12.06c-0.26,0.97-1.15,2.28-1.95,2.43c-0.78,0.15-2.41-0.82-2.62-1.58
				C370,459.23,369.3,455.24,368.25,450.21z"/>
			<path d="M661.83,538.27c3.76-0.75,7.51-1.5,11.27-2.26c0.12,0.53,0.23,1.05,0.35,1.58c-3.76,0.96-7.52,1.93-11.28,2.89
				C662.05,539.74,661.94,539.01,661.83,538.27z"/>
			<path d="M650.09,258.39c-0.66,8.08-1.34,16.42-2.1,25.74C644.86,277.74,646.94,260.49,650.09,258.39z"/>
			<path d="M1006.6,99.74c-0.68-4.74-1.67-8.89-6.38-7.75c-2.15,0.52-3.66,3.74-5.92,6.24c-0.41-0.21-2-1.6-3.66-1.68
				c-0.79-0.04-1.7,2.4-3.09,4.51c-0.79-1.39-2.25-2.83-2.07-4.01c0.16-1,2.17-1.79,3.46-2.5c2.47-1.36,5.28-2.24,7.45-3.96
				c3.85-3.05,6.91-1.22,9.81,1.11C1008.57,93.6,1009.29,96.08,1006.6,99.74z"/>
			<path d="M980.39,76.83c-8.48,0.46-16.96,0.91-25.44,1.37c-0.05-0.7-0.11-1.4-0.16-2.1c8.47-0.52,16.95-1.05,25.42-1.57
				C980.27,75.3,980.33,76.06,980.39,76.83z"/>
			<path d="M979.93,80.38c-4.39,0.24-8.77,0.49-13.16,0.73c-0.04-0.61-0.07-1.23-0.11-1.84c4.36,0,8.72,0,13.08,0
				C979.81,79.64,979.87,80.01,979.93,80.38z"/>
			<path onClick={() => props.onFill(466)} fill={props.fillColors[466]} d="M486.82,87.99c-2.32,0-4.63,0-7.38,0c2.21-1.56,4.07-2.89,5.94-4.21c-0.15-0.4-0.3-0.79-0.44-1.19
				c-8.68-0.9-17.36-1.81-26.03-2.71c4.76-6.1,10.27-4.98,16.89-1.65c-1.03-2.11-1.27-2.87-1.71-3.48
				c-3.97-5.46-3.55-7.23,2.65-10.23c5.93-2.87,11.55-3.46,17.1,1.34c2.67,2.31,6.22,3.61,9.3,5.32c2.74-3.03-1.34-9.56,5.04-9.04
				c3.41,0.28,6.62,2.94,9.83,4.49c2.14-1.64,4.5-4.31,7.46-5.48c3.17-1.25,6.84-0.98,6.72,4.3c3.1-0.9,5.76-1.9,8.5-2.38
				c1.31-0.23,2.8,0.52,4.2,0.82c-0.39,1.29-0.75,2.58-1.16,3.86c-0.24,0.75-0.55,1.47-1.31,3.51c10.12,0,19.55,0,29.12,0
				c-0.37,2.85-0.58,4.49-0.8,6.13c0.29,0.42,0.59,0.84,0.88,1.25c4.16-11.61,11.93-7.71,17.34-6.03c3.64-2.78,6.03-5.96,8.72-6.24
				c2.4-0.25,5.13,2.7,7.01,5.15c2.2-0.19,4.39-0.38,8.02-0.7c4.98-3.43,6.16-3.38,9.51,0.78c3.83-1.16,7.4-2.25,10.98-3.33
				c0.27,0.8,0.55,1.6,0.82,2.4c-3.8,1.62-7.6,3.23-12.3,5.23c4.78,1.24,8.75,0.23,12.49-1.31c7.44-3.06,14.67-1.37,21.92,0.49
				c0.71,0.18,1.17,1.36,1.75,2.08c-0.72,0.54-1.37,1.34-2.18,1.57c-1.83,0.53-3.75,0.78-6.32,1.27c1.43,1.93,2.38,3.2,3.82,5.16
				c-1.86,0.52-3.43,1.31-5.02,1.33c-3.42,0.05-6.87-0.86-8.82,3.49c-0.37,0.82-3.55,0.36-5.7,0.52c-2.78,4.43-2.78,4.43-8.61-0.23
				c0,1.52-0.05,2.51,0.01,3.51c0.08,1.29,0.59,2.68,0.26,3.84c-0.28,0.98-1.35,2.09-2.32,2.45c-3,1.11-6.18,2.7-9.17,2.48
				c-1.78-0.13-3.34-3.32-5.9-6.11c-7.79,3.1-17.38,6.23-28.16-0.98c3.73-1.44,6.08-2.34,8.43-3.24c-0.01-0.4-0.02-0.8-0.03-1.2
				c-1.44-0.2-3.01-0.12-4.29-0.69c-1.35-0.6-2.43-1.83-3.63-2.78c1.1-0.85,2.08-2.12,3.32-2.47c2.54-0.72,5.24-0.87,7.84-1.42
				c1.51-0.32,2.96-0.95,4.44-1.43c-0.07-0.4-0.14-0.8-0.21-1.2c-2.65,0-5.33,0.27-7.93-0.06c-3.62-0.46-7.18-1.42-10.79-2.06
				c-1.12-0.2-2.3-0.12-3.44-0.04c-5.35,0.35-10.7,0.74-15.85,1.11c-0.42,1.47-1.05,3.71-1.88,6.65c3.62,0.97,9.75-0.4,10.81,6.34
				c-5.16,4.59-9.02,4.3-12.76-0.37c-6.1,0.44-11.61,0.99-17.14,1.2c-3.69,0.14-7.17-0.4-4.46-6.28c-3,0.37-5.27,0.53-7.5,0.94
				c-3.65,0.67-6.86,2.02-10.73-0.95c-1.88-1.45-6.3,0.27-9.54,0.76c-1.96,0.29-3.85,1.1-5.81,1.4c-1.02,0.16-2.78,0.09-3.1-0.48
				c-2.54-4.56-5.72-2.1-8.81-1.04C486.69,88.08,486.82,87.99,486.82,87.99z"/>
			<path onClick={() => props.onFill(467)} fill={props.fillColors[467]} d="M404.98,88.5c3.53,0.99,7.07,1.98,10.6,2.97c-0.05,0.29-0.1,0.57-0.15,0.86
				c-34.82,0.7-69.65,1.4-104.47,2.1c-0.07-0.34-0.14-0.67-0.21-1.01c2.3-1.05,4.61-2.1,6.91-3.15c-0.05-0.5-0.1-1-0.15-1.5
				c-2.14-0.23-4.28-0.71-6.42-0.67c-34.78,0.61-69.56,1.45-104.35,1.8c-7.78,0.08-15.57-1.53-23.36-2.36
				c0.02-0.52,0.04-1.05,0.06-1.57c8.09-0.87,16.19-1.74,25.62-2.76c-2.2-1.89-3.22-2.77-5.6-4.83c8.53,0,15.74,0,22.29,0
				c1.1-3.11,1.77-5.02,2.54-7.22c-7.03-0.31-14.28-0.64-21.54-0.96c-0.02-0.9-0.03-1.8-0.05-2.7c6.53-0.74,13.05-1.75,19.6-2.13
				c6.98-0.41,13.99-0.18,20.99-0.29c6.83-0.1,13.65-0.32,20.48-0.39c6.81-0.07,13.63-0.02,20.65-0.02
				c3.57-6.12,3.57-6.12,12.16-2.16c1.77-4.32,4.17-8.07,9.01-2.76c2.57-6.1,7.8-7.85,12.69-4.65c3.38,2.21,3.32,6.04-0.36,7.79
				c-2.99,1.43-6.24,2.31-9.38,3.44c-0.31,0.11-0.82,0.09-0.93,0.3c-2.78,5.23-7.52,2.94-11.63,3.06c-0.5,0.02-1.12-0.15-1.48,0.09
				c-8.19,5.4-17.55,0.77-26.1,3.05c-3.32,0.89-6.66,1.72-9.99,2.58c0.14,0.61,0.28,1.21,0.41,1.82c0.97-0.1,2.06,0.07,2.89-0.33
				c7.63-3.7,15.49-3.24,23.44-1.48c2.88,0.64,4.83,0.06,7.5-1.83c2.32-1.64,6.72-0.53,10.18-0.26c1.09,0.08,2.07,1.38,3.17,2.17
				c1.06-2.4,1.5-5.64,2.98-6.22c1.89-0.74,4.62,0.67,7.42,1.25c0.31-1.16,0.72-2.64,1-3.68c3.01-0.5,5.68-0.94,8.35-1.39
				c0.04-0.41,0.07-0.82,0.11-1.22c1.43,1.3,2.85,2.6,4.52,4.13c0.74-1.83,1.03-3.82,2.16-4.9c0.81-0.77,3.17-0.94,4.13-0.3
				c1.47,0.97,3.46,3.1,3.22,4.33c-0.74,3.71-1.84,7.64-3.95,10.7c-2.67,3.87-2.69,5.34,2.53,7.6c-0.61-1.49-1.08-2.63-1.7-4.14
				c1.45-0.32,2.77-0.6,4.07-0.89c-0.85-7.62-0.85-7.62,10.08-6.71c-1.22-1.09-2.04-1.72-2.74-2.47c-1.05-1.12-2.01-2.33-3-3.5
				c1.57-1.33,3.27-3.87,4.7-3.74c3.64,0.35,7.16,1.91,11.36,3.18c1.13-2.28,3.2-2.16,6.16,0.28c1.56,1.29,4.24,1.21,6.42,1.75
				c3.11-9.27,5.02-9.78,16.9-5.08c1.56-3.79,2.32-8.25,4.88-11.06c2-2.2,6.18-3.02,9.49-3.31c7.5-0.64,10.08,1.7,11.23,9.01
				c2.01-1.58,3.99-3.14,6.69-5.27c0.46,3.09,0.71,4.79,0.85,5.69c3.42-0.83,6.66-2.43,9.7-2.13c2.97,0.29,5.87,2.34,8.51,4.06
				c0.64,0.42,0.67,3.43,0.07,3.81c-2.79,1.77-5.84,3.18-8.92,4.4c-0.93,0.37-2.69-0.84-3.33-0.35c-1.68,1.26-2.94,3.06-4.38,4.65
				c1.43,0.5,2.85,1.08,4.31,1.48c0.83,0.23,1.75,0.17,2.65,0.93c-11.9,4.62-24.49,0.76-36.6,2.45c-0.15,0.52-0.3,1.04-0.45,1.56
				c4.77,1.47,9.54,2.94,14.31,4.41c0,0.31,0,0.62,0,0.93c-2.88,0.51-5.77,1.03-8.65,1.54C405.07,87.7,405.02,88.1,404.98,88.5z"/>
			<path onClick={() => props.onFill(468)} fill={props.fillColors[468]} d="M367.79,37.48c-7.34-2.02-13.77-3.79-20.2-5.56c-0.08-0.19-0.15-0.37-0.23-0.56
				c3.68-0.2,7.48-1.08,11.01-0.45c5.83,1.05,11.42,1.38,17.3,0.32c1.97-0.35,4.39,1.79,6.61,2.8c6.21-8.11,10.72-8.4,17.79-0.92
				c4.36-3.51,6.25-0.54,7.62,3.56c3.05-1.13,6.06-4.89,8.9-0.06c-6.96,3.12-13.69,4.93-21.26,4.44c-9.91-0.65-19.89-0.16-30.14-0.16
				C365.61,40.35,366.12,39.68,367.79,37.48z"/>
			<path onClick={() => props.onFill(469)} fill={props.fillColors[469]} d="M196.29,24c10.19,0,20.38,0.05,30.57-0.04c2.11-0.02,4.21-0.56,6.32-0.8c2.92-0.33,5.84-0.6,8.88-0.28
				c4.43-3.9,4.43-3.9,11.13,0.55c1.58-1.32,3.33-3.75,4.41-3.48c2.24,0.55,4.14,2.53,6.17,3.93c-1.92,1.93-3.74,5.39-5.78,5.53
				c-10.16,0.69-20.39,0.51-30.59,0.63c-0.33,0-0.76,0.11-0.98-0.06c-8.5-6.2-18.17-1.39-27.15-2.91c-0.81-0.14-1.63-0.23-2.42-0.44
				c-0.24-0.06-0.39-0.46-0.58-0.7C196.27,25.29,196.28,24.65,196.29,24z"/>
			<path onClick={() => props.onFill(470)} fill={props.fillColors[470]} d="M1020.54,37.07c1.4,0,2.81-0.13,4.19,0.03c3.26,0.36,5.72,0.14,6.78-3.92c0.89-3.41,6.11-4.53,9.82-2.59
				c1.14,0.59,2.16,1.4,3.18,2.08c5.36-5.46,5.82-5.49,9.21-0.62c0.91-0.54,1.82-1.49,2.79-1.56c1.29-0.1,2.63,0.48,3.95,0.76
				c-0.29,1.29-0.16,3.03-0.96,3.77c-1.52,1.41-3.52,3.08-5.41,3.2c-11.17,0.71-22.39,2.84-33.55,0.18
				C1020.54,37.95,1020.54,37.51,1020.54,37.07z"/>
			<path onClick={() => props.onFill(471)} fill={props.fillColors[471]} d="M561.17,110.82c2,0.46,4.16,0.96,7.43,1.72c-4.04,1.99-6.59,4.4-10.34,1.53
				c-1.01-0.77-3.39-0.34-4.89,0.23c-4.83,1.81-9.61,3.34-14.94,0.82c1.28-0.24,2.62-0.34,3.83-0.78c0.95-0.35,2.2-0.93,2.54-1.73
				c2.46-5.77,2.4-5.79,8.18-2.99c0.44,0.21,0.95,0.26,1.31,0.36c1.61-1.26,3.14-2.46,4.66-3.65
				C559.61,107.65,560.27,108.99,561.17,110.82z"/>
			<path onClick={() => props.onFill(472)} fill={props.fillColors[472]} d="M514.57,108.04c1.16-1.1,2.08-1.96,3.34-3.16c0.8,1.32,1.43,2.34,2.52,4.13
				c-8.93,1.01-17.16,1.94-25.79,2.92c1.09-3.46,2.13-5.68,6.01-4.47c1.17,0.36,2.89-0.47,4.17-1.13
				C510.46,103.48,510.43,103.44,514.57,108.04z"/>
			<path onClick={() => props.onFill(473)} fill={props.fillColors[473]} d="M1071.51,44.17c1.92-0.53,3.85-1.05,6.86-1.88c-0.53,1.95-0.66,3.83-1.14,3.92
				c-1.81,0.36-3.74,0.15-5.63,0.15C1071.57,45.63,1071.54,44.9,1071.51,44.17z"/>
			<path onClick={() => props.onFill(474)} fill={props.fillColors[474]} d="M451.3,28.64c-7.03,5.75-14.13,2.58-21.62,1.64c3.54-4.22,8.21-3.84,13.16,0.68
				C445.03,28.69,447.15,25.4,451.3,28.64z"/>
			<path onClick={() => props.onFill(475)} fill={props.fillColors[475]} d="M126.1,741.12c-1.21-1.49-2.41-2.99-3.62-4.48c0.13-0.43,0.26-0.87,0.4-1.3
				c5.04,1.79,10.61,2.75,14.99,5.57c7.28,4.69,13.8,10.56,20.59,15.98c0.87,0.7,1.51,1.7,2.25,2.56c-0.26,0.4-0.52,0.8-0.78,1.2
				c-9.78-5.68-19.56-11.36-29.34-17.03c-1.53-0.89-3.08-1.75-4.62-2.62C125.97,740.99,126.1,741.12,126.1,741.12z"/>
			<ellipse onClick={() => props.onFill(476)} fill={props.fillColors[476]} cx="545.52" cy="180.85" rx="25.63" ry="0.74"/>
			<path onClick={() => props.onFill(477)} fill={props.fillColors[477]} d="M679.34,678.13c3.19,1.74,6.02,3.74,5.82,7.88c-0.53,0.39-1.06,0.79-1.59,1.18
				c-1.95-2.58-3.9-5.17-5.85-7.75C678.26,679,678.8,678.56,679.34,678.13z"/>
			<path onClick={() => props.onFill(478)} fill={props.fillColors[478]} d="M160.87,176.59c-7.65,1.14-15.3,2.28-24.25,3.62C140.79,175.65,152.73,173.99,160.87,176.59z"/>
			<path onClick={() => props.onFill(479)} fill={props.fillColors[479]} d="M190.13,597.59c-3.38-1.15-6.76-2.3-10.14-3.45c0.27-0.85,0.54-1.69,0.81-2.54
				c3.37,1.13,6.74,2.25,10.11,3.38C190.64,595.84,190.39,596.72,190.13,597.59z"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M332.51,849c1.83,0.33,3.67,0.96,5.5,0.96c284.39,0.04,568.78,0.03,853.17,0.13c4.08,0,5.1-1.12,5.08-5.09
				c-0.13-45.51-0.08-91.01-0.08-136.52c0-15.17,0.02-30.34-0.02-45.51c0-1.67-0.31-3.33-0.48-5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1195.18,477.45c0.33-0.83,0.96-1.67,0.96-2.5c-0.1-13.5-0.39-27-0.42-40.51c-0.02-9.33,0.48-18.67,0.39-28
				c-0.05-4.51-0.91-9-1.43-13.5c-0.1-0.84-0.73-1.87-0.43-2.47c3.64-7.28,1.66-15.02,1.85-22.54c0.33-12.83,0.09-25.67,0.09-38.5
				c0-15.84,0-31.67,0-47.51c0-34.5,0-69.01,0-103.51c0-55.01,0-110.01,0-165.02c0-1.68,0-3.37,0-5.25c-397.24,0-793.99,0-1191.23,0
				c0,1.91,0,3.58,0,5.25c0,44.01-0.06,88.01,0.02,132.02c0.05,29.5,0.36,59.01,0.46,88.51c0.08,20.84,0.01,41.67,0.01,62.51
				c0,18.67,0.06,37.34-0.02,56.01c-0.09,20.84-0.46,41.67-0.45,62.51C5,441.28,5.4,467.62,5.41,493.96
				c0.01,23.5-0.37,47.01-0.43,70.51c-0.03,10.83,0.3,21.67,0.47,32.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M35.45,576.97c-4.16,7.04-11.65,8.61-18.53,10.92c-5.8,1.94-11.97,9.19-11.97,15.09c0,80.51,0,161.02,0,241.53
				c0,1.68,0,3.37,0,5.5c2.16,0,3.83,0,5.5,0c87.02,0,174.03,0,261.05,0c13.17,0,26.34-0.12,39.51,0.04
				c10.56,0.12,20.78-0.01,30.59-5.38c11.84-6.49,25.18-8.45,38.4-10.8c16.34-2.9,32.47-6.77,48.11-12.67
				c5.73-2.16,12.3-2.02,18.42-3.24c5.55-1.1,11.1-2.33,16.49-4.02c8.25-2.58,16.28-5.85,24.56-8.33c3.81-1.14,7.96-1.21,11.97-1.59
				c6.33-0.59,12.66-1.12,19-1.5c5.57-0.33,9.66-3.51,14.09-6.39c2.49-1.62,6.28-3.1,8.83-2.37c4.97,1.43,9.12,0.02,13.59-1.23
				c5.45-1.52,11.06-2.49,16.46-4.14c2.51-0.77,4.79-2.44,7.01-3.94c3.2-2.16,6.25-4.54,10.16-7.41c-0.71-4.14,3.81-4.47,7.34-6.12
				c8.63-4.04,17.9-3.61,27.02-4.55c5.89-0.6,11.64-2.54,17.53-3.31c6.62-0.86,13.32-1.11,19.99-1.58c5.33-0.37,10.66-0.79,16-0.97
				c4.03-0.14,7.1-1.51,10.12-4.41c3.84-3.68,9.15-5.89,12.75-9.75c7.1-7.59,13.41-15.93,20.15-23.87
				c4.05-4.77,8.31-9.35,12.48-14.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1000.64,146.41c-2.83-0.83-5.68-2.42-8.5-2.37c-14.84,0.23-29.67,1.1-44.51,1.29c-9.33,0.12-18.68-1.01-28-0.83
				c-20.34,0.39-40.68,1.19-61.01,1.93c-21.01,0.76-42,2.08-63.01,2.37c-24,0.33-48.01-0.26-72.01-0.37
				c-16.84-0.08-33.67,0.11-50.51-0.06c-13.17-0.13-26.34-0.74-39.51-0.93c-18.17-0.27-36.34-0.55-54.51-0.47
				c-18,0.08-36.01,0.6-54.01,0.95c-15.84,0.31-31.67,0.65-47.51,1.04c-0.84,0.02-1.67,0.63-2.5,0.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M424.53,170.92c1.15-1.84,2.3-3.69,3.61-5.78c-0.91-1.82-1.91-3.82-3.05-6.1c1.61-3.27,4.39-3.6,7.95-2.66
				c10.6,2.8,21.29,3.77,31.99,0.51c2.57-0.78,5.05-1.89,7.48-3.03c0.53-0.25,0.7-1.27,1.03-1.94c-1-0.5-1.98-1.39-3-1.43
				c-4.16-0.16-8.33-0.02-12.5-0.08c-8.5-0.13-17.45,1.31-25.4-0.88c-11.52-3.17-22.86-1.11-34.12-1.24
				c-27.16-0.32-54.33,1.62-81.51,2.02c-22.66,0.33-45.34-0.51-68.01-0.3c-15.17,0.14-30.33,1.5-45.51,1.82
				c-11.99,0.25-24-0.32-36.01-0.4c-32.34-0.21-64.68-0.43-97.02-0.48c-17.67-0.03-35.34,0.33-53.01,0.44
				c-3.83,0.02-7.67-0.31-11.5-0.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1112.66,105.41c-5.83,1-11.64,2.58-17.51,2.87c-9.64,0.48-19.34-0.17-29,0.2c-14.18,0.55-28.34,1.67-42.51,2.4
				c-9.33,0.48-18.68,0.49-28,1.07c-21.85,1.37-43.67,3.04-65.52,4.35c-2.62,0.16-5.32-1.24-8-1.88c-3.5-0.85-7-1.67-10.5-2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M224.49,300.93c-18,2.33-36.01,4.68-54.01,6.98c-2.32,0.3-4.66,0.42-7,0.51c-8.73,0.33-16.07,3.36-21.55,10.48
				c-1.71,2.22-3.65,4.57-6.03,5.91c-3.74,2.13-7.79,4.13-11.96,4.99c-10.08,2.08-20.36,3.24-30.45,5.27
				c-3.17,0.64-6.55,2.29-8.93,4.47c-7.63,6.97-16.66,11.24-26.1,14.9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M935.63,722.98c-7.5,1.33-15,2.68-22.51,4c-8.19,1.43-15.82,4.27-23.06,8.4c-1.78,1.01-4.73,1.7-6.35,0.91
				c-3.44-1.67-6.15-0.54-9.1,0.69c-16.43,6.87-33.61,7.17-51.02,6.11c-3.96-0.24-8.33,0.08-11.94,1.54
				c-11.94,4.84-24.44,6.6-37.07,7.83c-16.49,1.6-33,3.04-49.51,4.52c-11.5,1.03-23,2.09-34.51,2.92c-1.79,0.13-3.66-0.91-5.49-1.41"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M427.03,625.47c-4.3,3.21-9.12,4.06-14.46,4.82c-7.84,1.11-15.57,3.87-23,6.81c-8.72,3.46-17.26,7.5-25.48,12
				c-5.7,3.12-10.47,7.33-8.25,15.42c0.78,2.84-0.54,5.85-3.75,8.06c-14.22,9.78-24.27,23.16-32.14,38.37
				c-1.92,3.71-4.11,7.53-7.03,10.44c-9,8.96-17.44,18.73-23.02,29.79c-7.18,14.23-8.91,30.29-6.24,46.28
				c0.67,4,3.18,7.69,4.85,11.53c5.77,13.26,8,26.57,0.5,40"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M722.59,164.91c-16.69-0.54-33.24,4.11-50.02,1.06c-10.78-1.96-21.63-3.64-32.51-4.97c-7.61-0.94-15.35-0.84-22.98-1.68
				c-4.06-0.45-8.01-1.91-12.55-3.05c-4.96,6.45-5.05,14.85-7.97,22.15"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M474.54,600.97c4.64,2.12,3.01,6.67,3.31,10.02c0.67,7.48,0.99,15.08-3.26,22.02c-3.3,5.4-7.82,8.95-14.06,9.38
				c-8.25,0.56-11.61,6.17-15.03,12.66c2.15,0.89,3.86,1.61,6.53,2.72c-3.44,2.71-6.39,5.04-9.71,7.65c0.65,1.05,1.43,2.31,2.84,4.59
				c-0.71,0.4-1.77,0.82-2.62,1.5c-5.41,4.33-5.38,8.93-0.02,12.99c2.37,1.79,4.87,3.46,6.97,5.53c6.3,6.24,14.74,6.08,22.51,8.09
				c2.71,0.7,5.7,2.34,7.4,4.48c3.71,4.67,2.48,8.96-4.44,12.24c-9.71,4.61-19.89,8.27-29.95,12.11
				c-6.78,2.59-11.91,7.32-16.38,12.63c-3.46,4.11-7.28,6.06-12.43,7.9c-6.4,2.29-11.53,8.15-17.17,12.52
				c-6.86,5.31-13.23,11.43-20.66,15.73c-5.22,3.02-11.96,3.28-17.83,5.34c-8.95,3.14-18.09,4.07-27.51,4.75
				c-6.46,0.47-12.78,3.78-19.01,6.23c-8.75,3.45-17.36,7.28-26.03,10.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M267.5,263.93c-2.83-0.67-5.66-1.85-8.5-1.9c-7.5-0.13-15.01,0.07-22.5,0.48c-2.86,0.16-6.13,0.55-8.42,2.05
				c-6.47,4.22-12.72,1.55-19.09,0.36c-3.5-0.65-7-1.88-10.5-1.89c-7.83-0.02-15.68,0.43-23.5,0.95c-3.03,0.2-4.84,1.96-6.72,4.79
				c-3.8,5.71-11.05,7.48-16.22,11.72c-5.82,4.77-12.62,3.23-19.07,3.87c-7.97,0.79-12.8,4.69-17.86,11.28
				c-10.01,13.03-23.78,21.21-41.14,20.74c-11-0.3-20,2.89-27.68,11.38c-6.3,6.96-13.76,13.07-21.46,18.47
				c-5.38,3.77-11.85,6.36-18.87,6.2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M687.08,256.43c7.37-0.94,10.69-6.79,14.48-12.02c1.58-2.17,2.89-4.54,3.97-8.46c-6.32,0.32-12.63,0.84-18.95,0.93
				c-11.17,0.15-22.34-0.02-33.51,0.06c-11,0.08-22.04-0.17-32.99,0.72c-4.29,0.35-8.34,3.45-12.53,5.25
				c-2.81,1.21-5.72,2.22-8.48,3.55c-2.2,1.06-4.23,2.48-6.52,3.84c4.09,5.82,8.79,5.39,13.51,5.15c3.16-0.16,6.33-0.05,9.5-0.01
				c0.34,0,0.89,0.53,0.97,0.45c5.06-5.35,9.7,0.09,14.55,0.33c4.79,0.24,9.66-1.11,14.49-1.81c4.34-0.63,8.66-1.41,13.01-1.97
				c4.09-0.53,8.45-1.9,10.88,3.42c0.28,0.61,2.37,0.56,3.62,0.53c4.5-0.09,9-0.3,13.5-0.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M181.98,87.9c6,1,11.98,2.72,18.01,2.87c21,0.51,42.01,0.74,63.01,0.53c13.34-0.13,26.67-1.3,40.01-1.87
				c4.83-0.21,9.67-0.03,14.5-0.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M233.49,288.93c-7.33,0.67-14.71,1.07-21.99,2.11c-4.08,0.58-8.29,1.69-11.95,3.53c-4.83,2.43-9.48,4.7-15.07,4.4
				c-5.66-0.31-11.34-0.3-17-0.55c-11.89-0.52-22.4,2.61-32.12,9.86c-7.75,5.78-16.55,10.16-24.89,15.16"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M812.6,362.94c1.68,1.31,3.37,2.62,5.99,4.66c-2.03,1.42-3.64,2.87-5.51,3.8c-11.01,5.49-22.85,7.09-34.99,7.03
				c-19.84-0.11-39.69,0.05-59.51-0.66c-8.54-0.3-17.07-2.21-25.47-4c-2.41-0.51-6-2.97-6.22-4.86c-0.55-4.65-3.45-5.23-6.79-6.56
				c-6.79-2.69-13.37-5.91-20.04-8.91"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M533.05,510.46c4.71,4.56,3.81,10.5,3.38,15.99c-0.75,9.53-2.63,18.98-3.29,28.52c-0.99,14.37,2.72,28.18,7.65,41.41
				c2.84,7.62,3.89,15.35,4.58,23.11c1.08,11.99,8.63,18.59,18.25,22.84c23.08,10.2,44.01,23.4,62.55,40.54
				c3.69,3.41,7.84,6.22,7.75,11.61c-0.05,3.01-1.18,6.16-2.45,8.96c-1.65,3.66-0.85,5.26,3.07,6.12c3.75,0.82,5.63,3.35,4.52,7.42"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M427.03,319.43c1.68-4.49,6.07-4.29,9.52-4.86c7.28-1.2,14.48-1.24,22.04-0.02c8.4,1.35,17.32,0.18,25.93-0.74
				c9.73-1.04,19.37-1.47,29.01,0.19c2.41,0.41,5.32,0.97,6.89,2.56c4.67,4.73,10.71,6.04,16.65,7.27c8.3,1.71,12.62,7.12,16.68,14
				c3.56,6.05,8.66,11.24,13.34,16.59c10.41,11.92,20.97,23.7,31.48,35.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M325.51,63.4c1.07-4.49,0.75-8.17-4.48-10.06c-5.14-1.86-8.68-1.02-12.09,3.65c-4.44-2.8-7.11-0.79-8.89,3.75
				c-1.62-0.66-3.06-1.31-4.55-1.83c-2.63-0.92-5.32-1.25-6.43,2.01c-1.11,3.26-3.46,3.01-6.06,3.01
				c-18.34-0.05-36.68-0.31-55.01,0.11c-7.9,0.18-15.77,1.82-23.66,3.71c7.5,5.39,16.1,3.14,24.15,4.16"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M420.53,365.44c-5.18,2.81-10.14,4.27-16.13,1.25c-2.59-1.31-6.72-0.53-9.84,0.39c-4.78,1.41-9,1.33-13.04-1.64
				c-0.67-0.49-1.35-1.45-2-1.43c-8.05,0.3-15.1-3.27-22.55-5.43c-4.44-1.29-9.28-1.4-13.96-1.59c-9.98-0.41-17.44-5.98-25.03-11.51
				c-5.27-3.84-9.19-9.71-16.46-10.67c-1.62-0.21-3.77-1.72-4.38-3.18c-2.24-5.31-6.45-5.45-11.14-5.26
				c-1.65,0.07-3.39-0.43-4.99-0.97c-7.5-2.54-15-3.76-23.04-2.66c-4.35,0.59-9.08-0.37-13.45-1.42c-5.37-1.29-9.56,0.63-13.5,3.66
				c-2.56,1.97-0.36,8.12,3.48,8.87c8.44,1.63,16.95,3.11,25.5,3.92c3.57,0.34,7.31-1.6,10.99-1.68c2.43-0.05,6.11-2.71,7.25,1.41
				c0.37,1.33-2.13,3.46-3.72,5.83c1.32,0.33,2.88,0.95,4.46,1.05c2.1,0.13,3.82,0.32,3.5,3.06"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M456.03,385.44c-1.29-2.98,0.03-4.94,2.5-6.51c4.43-2.83,9.1-4.57,14.51-4.57c6,0,12.07-0.18,17.97-1.14
				c2.21-0.36,5.99-3.73,5.7-4.68c-1.26-4.07-5.5-3.64-8.64-2.98c-8.12,1.69-16.09,3.76-24.54,2.95c-5.44-0.53-10.39-1.05-14.76-5.29
				c-1.47-1.42-7.35-0.81-8.99,0.95c-4.85,5.18-10.62,5.3-16.74,5.27"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M131.47,266.93c-2.5,0.33-5.02,0.56-7.5,1.02c-15.34,2.8-30.66,5.75-46.03,8.38c-2.37,0.41-5.62,0.28-7.37-1.04
				c-7.23-5.43-15.4-4.16-23.08-3.12c-8.15,1.11-16.04,4.1-24.04,6.26"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M152.98,207.42c-4.25,8.82-18.05,10.72-24.36,2.88c-2.74-3.4-5.82-3.05-8.6-2.22c-4.88,1.47-9.31,3.77-12.23,8.73
				c-2.09,3.54-5.35,6.94-8.91,8.94c-9.74,5.47-19.75,10.53-30.01,14.95c-3.23,1.39-7.58,0.1-11.41,0.24
				c-6.67,0.24-13.38,0.3-20,1.05c-10.54,1.2-21.01,2.92-31.52,4.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M226.49,325.93c-11.85,8.98-25.44,14.52-39.57,18.27c-8.39,2.23-15.84,5.96-23.46,9.7c-14.12,6.94-28.33,13.69-42.48,20.56
				c-0.6,0.29-1.01,0.97-1.51,1.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M617.07,399.44c-4-0.83-7.99-1.75-12.01-2.48c-7.27-1.32-14.34-4.75-22.02-1.57c-6.23,2.58-12.67,4.66-18.97,7.07
				c-3.15,1.21-5.16,3.39-5.59,6.97c-0.13,1.07-1.02,2.52-1.95,2.93c-13.29,5.95-26.47,12.18-40.05,17.39
				c-6.4,2.45-13.62,2.76-20.42,4.24c-2.86,0.62-3.11-0.74-3.58-3.03c-2.34-11.37-4.92-22.69-7.45-34.02
				c-0.19-0.86-0.65-1.67-0.98-2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M110.97,211.42c-3.33,0.5-6.93,0.36-9.95,1.62c-7.95,3.32-15.28,7.8-24,9.65c-5.97,1.26-11.42,5.01-17.05,7.75
				c-3.91,1.9-7.54,2.42-12.08,1.26c-4.34-1.11-9.27-0.05-13.93,0.25c-9.34,0.59-18.67,1.31-28.01,1.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M346.51,304.43c-8.33,0.83-16.68,1.57-25,2.56c-2.38,0.28-4.98,0.79-6.96,2.02c-16.96,10.5-35.24,11.11-54.04,7.36
				c-7.09-1.41-14.02-3.6-21.02-5.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M694.08,415.45c-6.33,6.17-12.56,12.46-19.1,18.4c-1.33,1.2-3.9,2.25-5.54-0.82c-0.43-0.81-2.78-1.38-3.82-0.97
				c-6.75,2.63-13.23,6.03-20.09,8.26c-6.12,1.99-12.59,3.01-18.97,4.09c-8.97,1.52-17.98,2.79-27.01,3.99
				c-1.94,0.26-3.99-0.27-5.99-0.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M862.61,455.45c3.67,4,7.29,8.04,11.01,11.99c4.29,4.55,9.9,8.37,12.63,13.7c2.64,5.16,4.05,12.15,2.96,17.74
				c-1.66,8.53-0.58,16.38,0.78,24.6c1.48,8.92,2.17,17.98,3.03,26.99c0.11,1.11-0.68,2.52-1.46,3.46
				c-4.76,5.73-10.22,10.97-14.3,17.14c-4.16,6.3-10.89,6.69-16.65,9.44c-1.48,0.7-3.22,1.67-3.94,3
				c-7.47,13.76-14.41,27.82-22.24,41.37c-3.4,5.88-8.51,10.76-12.85,16.09"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M309.51,90.91c-0.58,0.96-1.15,1.92-2.6,4.34c8.93,0.06,16.77,0.12,24.61,0.14c0.83,0,1.66-0.45,2.5-0.46
				c23.5-0.36,47.01-0.64,70.51-1.06c4.51-0.08,9-0.63,13.5-0.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M561.05,8.9c2.79,13.51,9.12,25.38,20.69,32.71c8.9,5.64,19.23,9.47,29.4,12.54c8.97,2.71,18.56,3.61,27.95,4.55
				c3.04,0.3,6.25-2.1,9.49-2.73c6.29-1.22,12.68-1.95,18.98-3.12c2.57-0.48,5-1.66,7.52-2.42c1.47-0.44,3.08-1.18,4.48-0.93
				c4.27,0.78,8.08,0.59,11.02-3.09"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M823.61,159.91c-8.66-9.14-19.77-5.2-30.01-5.42c-9.49-0.2-19.01,0.14-28.5,0.45c-11.01,0.37-22.01,0.96-33.01,1.48
				c-2.5,0.12-5,0.33-7.5,0.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1195.18,145.41c-11-0.33-22-0.93-33.01-0.93c-11,0-22.02,1.06-33,0.8c-11.85-0.28-23.66-1.98-35.51-2.26
				c-13.82-0.33-27.67,0.14-41.5,0.41c-10.34,0.2-20.68,0.54-31,1.01c-6.01,0.28-12.03,0.78-17.98,1.63
				c-1.09,0.16-1.86,2.5-2.77,3.83"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M349.51,259.43c-4.39-5.21-10.83-3.12-14.84-1.58c-8.66,3.33-12.85-1.05-17.73-6.36c-3.51-3.81-6.86-7.32-6.56-13.07
				c0.09-1.66-1.35-3.41-2.12-5.2c-10.24,6.05-21.39,1.35-32.22,3.56c1.1,5.7-3.33,5.65-5.96,4.45c-8.07-3.67-16.4-1.72-25.2-2.45
				c1.23,4.95-0.89,6.74-5.38,5.1c-7.29-2.66-15.23-0.33-22.74-4c-3.71-1.81-9.78,1.18-14.78,2.05c-10.36,1.81-20.57,2.49-30.01-3.51
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M410.03,696.48c-3,1.5-5.96,3.1-9.02,4.47c-2.26,1.01-4.61,1.86-7,2.49c-2.62,0.69-5.8,0.31-7.91,1.67
				c-10.06,6.47-20.75,5.27-31.58,3.36c-5.89-1.04-11.55-0.57-17.01,2c-5.81,2.72-11.64,5.4-17.51,7.97
				c-1.38,0.6-2.98,0.71-4.48,1.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1025.15,281.43c-9.17,0.33-18.35,1.16-27.5,0.85c-7.69-0.26-15.32-2.06-23.02-2.75c-3.11-0.28-6.8-0.32-9.4,1.08
				c-6.12,3.28-12.05,3.45-18.62,1.91c-11.27-2.64-22.41-0.32-33.49,1.89c-2.31,0.46-5.26,1.74-6.89,0.83
				c-5.45-3.07-10.43-1.39-15.62,0.18c-5.65,1.72-11.4,3.16-16.97,5.11c-2.05,0.72-4.59,2.17-5.4,3.96
				c-6.19,13.74-18.96,18.13-31.72,21.12c-9.94,2.33-19.06,4.61-26.62,12.13c-3.01,3-8.14,3.87-12.32,5.68c-0.96,0.42-1.97,0.77-3,1
				c-5.91,1.29-8.51,8.53-16.03,7.83c-5.18-0.48-10.63,2.09-15.98,3.17c-14.99,3.03-30.03,2.7-45-0.05
				c-6.23-1.14-12.29-3.86-18.53-4.24c-11.71-0.72-23.44-0.5-34.57,4.65c-3.32,1.54-4.45,3.72-4.89,6.66
				c-1.63,11.07-8.32,19.18-15.54,27"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M382.52,719.98c-3.83-0.17-7.67-0.43-11.5-0.48c-5.3-0.07-10.43-0.04-15.56,2.36c-13.66,6.39-28.81,9.5-41.4,18.2
				c-6.82,4.71-12.99,10.35-19.61,15.36c-1.67,1.27-3.95,1.73-5.95,2.57"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M439.53,730.98c-0.17,2.17,0.51,5.11-0.65,6.36c-4.74,5.13-4.34,11.71-5.15,17.67c-1.27,9.4-0.8,19.02-1.87,28.46
				c-0.56,4.94-2.65,9.75-4.39,14.5c-1.38,3.77-3.23,7.38-4.96,11.02c-1.44,3.03-3.21,5.92-4.41,9.04c-0.77,2-0.75,4.3-1.08,6.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1094.16,63.9c-2.98-3.78-7.4-1.64-9.29-0.22c-4.29,3.24-8.72,3.07-13.21,2.64c-22.75-2.19-45.35,1.28-68.02,1.49
				c-5.83,0.05-11.67-0.92-17.5-1.42c-0.17-0.01-0.46,0.05-0.48-0.01c-2.96-7.02-7.57-3.73-12.05-2.07
				c-0.97,0.36-2.34-0.14-3.47-0.44c-4.38-1.19-7.32,0.4-9.01,4.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M740.59,478.95c3.5,6,6.25,12.65,10.71,17.82c4.43,5.14,3.88,11.1,5.16,16.71c1.75,7.66,1.83,15.49,11.55,18.71
				c6.25,2.07,12.15,5.89,17.47,9.91c5.74,4.33,11.78,9,12.13,17.36c0.03,0.84,0.04,1.91,0.52,2.46
				c6.87,7.87,11.44,18.13,22.92,21.19c2.28,0.61,4.05,3.18,6.04,4.85c1.18,0.99,2.18,2.48,3.53,2.92
				c6.33,2.06,9.19,7.25,11.98,12.58"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M454.53,259.93c-2.83,0.67-5.72,2.06-8.49,1.85c-5.85-0.46-11.57,0.17-17.31-3.94c-4.5-3.22-9.72-7.99-14.32-8.77
				c-8.42-1.43-10.46-7.19-13.92-12.63c-0.49-0.78-1.39-1.99-1.96-1.92c-5.87,0.73-7.03-3.91-9.67-7.49
				c-3.54-4.8-8.26-8.71-12.3-13.16c-1.44-1.59-2.89-3.49-3.44-5.49c-1.27-4.66-4.41-6.73-8.56-5.33
				c-6.93,2.36-13.39-0.48-20.05-0.68c-6.01-0.18-12-0.66-18.01-0.94c-0.99-0.05-2.56-0.04-2.91,0.53c-2.7,4.34-6.37,5.11-11.12,4.1
				c-1.46-0.31-3.75,0.75-4.89,1.92c-4.52,4.65-9.47,9.14-12.89,14.55c-3.85,6.09-8.6,6.52-14.71,5.45
				c-4.94-0.87-9.99-1.46-14.99-1.48c-7.33-0.03-14.68,0.47-22,0.95c-4.51,0.29-8.99,1.14-13.51,1.42
				c-7.83,0.48-15.72,0.27-23.49,1.16c-8.56,0.98-17.06,2.68-25.5,4.48c-3.46,0.74-7.09,2-9.94,4.02
				c-6.77,4.79-14.43,3.91-21.56,3.15c-13.59-1.44-27.03-2.62-40.53,0.23"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M95.46,329.43c-9.67,2.17-19.49,3.83-28.94,6.73c-4.4,1.35-8.99,4.36-11.89,7.93c-6.67,8.22-15.89,9.86-25.19,11.85
				c-7.83,1.67-15.67,3.34-23.5,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M610.56,566.46c1.26,3.78,2.69,7.3,5.88,10.13c1.81,1.61,2.5,4.51,3.61,6.87c2.03,4.32,4.01,8.67,6.01,13
				c2.16,4.67,4.32,9.34,6.51,14c0.63,1.35,1.11,2.85,2.03,3.97c5.76,7.06,11.67,14,17.44,21.06c1.22,1.49,1.96,3.36,3.05,4.96
				c1.94,2.87,3.54,6.19,6.08,8.4c4.43,3.84,8.07,8,9.9,13.62"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M981.14,194.42c-1.33,3.17-2.72,6.31-3.98,9.51c-0.77,1.96-0.93,4.3-2.12,5.92c-1.58,2.14-3.85,5.21-5.91,5.28
				c-4.47,0.14-9.78-0.2-13.24-2.57c-2.78-1.9-5.06-2.01-7.75-2.11c-11.33-0.39-22.67-0.88-34.01-0.96c-6.67-0.05-13.34,0.56-20,0.97
				c-1.18,0.07-2.53,0.36-3.46,1.02c-2.12,1.49-4.04,3.28-6.04,4.95c2.17,0.83,4.27,1.98,6.51,2.43c4.32,0.87,8.84,8.93,7.53,13.58
				c-0.73,2.6-1.04,5.32-1.54,7.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M901.12,296.93c-1.17,2.17-2.63,4.23-3.44,6.52c-1.55,4.42-3.83,8.98-3.82,13.48c0.01,7.73-3.03,13.49-9.33,16.85
				c-8.67,4.62-17.96,8.08-26.91,12.19c-4.36,2-10.08,2.84-9.19,9.99c0.11,0.84-2.04,2.2-3.35,2.94c-5.78,3.26-11.67,6.33-17.45,9.59
				c-0.86,0.49-2.06,1.77-1.91,2.43c0.95,4.38,2.22,8.69,3.39,13.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M992.64,355.94c-4.02-4.47-8.43-2.95-13.01-1.02c-12.19,5.12-24.92,6.51-37.99,5c-1.52-0.18-2.99-0.79-4.51-0.95
				c-8.97-0.97-10.44,0.44-13.88,8.93c-2.74,6.77-5.35,13.86-11.59,18.6c-0.9,0.69-1.52,1.89-2.01,2.97
				c-4.77,10.57-8.5,21.63-16.41,30.59c-1.95,2.21-3.83,5.39-1.63,8.91c3.26,5.23,1.34,10.47-0.58,15.45
				c-1.23,3.2-2.4,6.03,0.08,9.06c0.31,0.38,0.56,1.01,0.49,1.48c-1.12,7.84-2.3,15.67-3.47,23.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M923.13,364.94c-4-0.5-8.01-0.91-12-1.53c-3.52-0.54-6.98-1.65-10.51-1.9c-6.32-0.44-12.7-0.92-18.99-0.46
				c-10.2,0.74-20.36,2.14-30.51,3.41c-6.41,0.8-11.14,3.89-13.03,10.47c-0.3,1.06-0.9,2.05-1.49,3c-2.81,4.52-5.59,9.06-8.53,13.48
				c-0.9,1.35-2.29,2.36-3.46,3.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M148.47,429.95c-4.15-4.78-8.9-5.24-14.55-2.6c-2.72,1.27-5.99,2.14-8.95,1.99c-15.38-0.79-30.15,1.58-44.55,7.03
				c-3.78,1.43-7.95,2.23-11.98,2.38c-1.95,0.07-3.99-2.03-5.83-3.73c-3.66,0.17-7.65-0.24-9.94,4.81c-1.08,2.38-4.99,3.58-7.74,5.1
				c-4.58,2.51-8.8,5.36-11.49,10.03c-3.46,6-8.9,9.68-15,12.49c-4.13,1.91-8.33,3.68-12.5,5.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M894.12,433.45c7.25,1.69,11.92,5.5,13.22,13.47c0.57,3.54,2.81,7.37,5.43,9.89c4.67,4.49,8.92,8.97,11.44,15.12
				c3.11,7.6,8.06,13.43,15.81,17.27c4.47,2.22,8.08,6.5,11.56,10.32c7.86,8.66,15.41,17.6,23.06,26.46c2.4,2.78,4.68,5.66,7.01,8.49
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M135.47,764.49c9.18,7.3,20.63,9.76,31.03,14.45c17.75,8.02,35.65,15.72,53.48,23.58c0.26,0.12,0.35,0.64,0.51,0.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M166.48,242.42c-0.75,3.63-3,4.33-6.51,4.08c-12.15-0.88-24.33-1.82-36.5-1.94c-8.16-0.08-16.35,1.07-24.49,1.95
				c-1.9,0.21-3.77,1.41-5.49,2.44c-4.89,2.91-9.68,5.98-14.53,8.96c-0.63,0.39-1.49,1.12-1.97,0.94c-8.29-2.96-17.37,2.12-25.61-2.8
				c-3.53-2.11-7.84-3.32-11.95-3.95c-2.86-0.44-6.02,0.62-8.96,1.36c-8.2,2.06-16.35,4.3-24.51,6.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M559.05,790.49c-0.18-4.67-4.26-9.19,0.66-15.12c-3.17-0.57-5.68-1.81-7.61-1.2c-3.44,1.09-7.11,1.76-8.28-1.78
				c-1.01-3.06-0.23-7.13,0.91-10.34c0.94-2.67,3.56-4.74,5.85-7.6c-2.97-1.13-5.05-1.93-7.69-2.93c1.49-2.1,2.74-3.87,4.83-6.8
				c-2.04-0.62-4.04-1.97-5.64-1.6c-7.69,1.77-15.26,4.01-22.97,6.11c2.66,7.71,0.14,8.57-8.69,9.85
				c3.98,10.45-6.77,9.83-10.99,14.32c-2.08,2.22-5.21,3.47-7.91,5.09c-3.24,1.94-6.22,3.94-4.54,8.53c0.2,0.53-0.13,1.41-0.47,1.97
				c-4.05,6.5-8.74,12.4-14.92,17.09c-2.02,1.53-3.39,3.93-5.06,5.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M877.62,472.95c-2.17,2.33-4.3,4.7-6.51,6.99c-1.96,2.04-3.69,4.52-6.06,5.9c-4.26,2.48-8.11,4.98-9.49,10.1
				c-0.3,1.1-1.14,2.15-1.97,2.99c-6.96,7.04-13.85,14.16-21.07,20.93c-1.91,1.79-4.85,3.25-7.42,3.45
				c-8.94,0.69-14.96,5.4-19.52,12.63c-0.29,0.46-0.97,0.68-1.47,1.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M862.11,662.98c9.16,5.89,18.69,8.91,30.05,8c4.59-0.37,10.93,3.76,14.2,7.72c7.26,8.8,16.14,13.95,26.71,17.47
				c5.35,1.78,10.03,5.55,15.09,8.28c1.7,0.92,3.69,2.04,5.47,1.93c10.41-0.67,19.61,2.8,28.54,7.56c1.31,0.7,2.97,0.72,4.47,1.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M70.96,616.97c4.46,7.57,12.78,9.96,19.5,14.51c2.87,1.94,6.09,3.6,8.42,6.07c4.79,5.06,9.13,10.55,13.58,15.93
				c5.74,6.93,14.26,7.33,22.02,9.46c6.97,1.91,13.49,4.12,18.1,10.46c4.69,6.46,11.78,9.43,19.36,11.71
				c6.04,1.82,11.71,4.87,17.55,7.36c9,3.84,18.09,7.48,26.95,11.61c2.82,1.31,5.8,3.42,7.41,5.98c4.78,7.63,12.4,10.46,20.12,13.47
				c3.16,1.23,6.91,2.75,1.82,7.62c2.82,1.03,5.29,1.86,7.69,2.85c1.23,0.51,2.39,1.24,3.5,1.98c3.19,2.13,6.14,4.75,9.55,6.4
				c7.38,3.57,8.85,11.42,2.39,16.5c-3.95,3.1-4.01,6.12-1.97,10.12c1.39,2.72,2.47,5.61,3.52,8.49c1.81,4.98,1.64,9.96-2.07,13.9
				c-2.36,2.51-3.14,4.61-1.44,7.61c0.4,0.7,0.78,1.87,0.47,2.45c-4.35,8.01-0.43,14.75,4.26,20.37c4.58,5.49,3.28,11.8,4.71,17.69
				c0.73,3.03,0.43,6.31,0.6,9.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M974.64,43.9c-2.83-1.79-4.67,0.37-7.05,1.39c-7.42,3.19-15.14,2.95-22.97,1.15c-14.05-3.23-28.2-3-42.52-1.79
				c-6.18,0.52-12.89,2.32-18.98-1.25"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M84.46,288.93c-4.83,0-9.67-0.13-14.5,0.03c-5.34,0.18-10.67,0.59-16,0.98c-6.17,0.45-12.34,0.96-18.5,1.5
				c-4.02,0.35-7.84,1.39-11.7-1.77c-1.43-1.17-5.18,0.21-7.79,0.81c-3.38,0.78-6.68,1.95-10.01,2.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M910.62,263.43c-7.1-2.75-11.58-7.23-11.78-15.5c-0.03-1.38-3.59-3.47-5.75-3.85c-4.91-0.85-9.97-0.92-14.98-1.14
				c-4.75-0.21-8.88-4.73-8.5-9.53c0.16-2,0.79-4.09,0.41-5.98c-0.83-4.12-2.6-7.18-7.92-6.61c-6.79,0.72-13.66,0.91-20.5,1.03
				c-3.16,0.05-6.34-0.54-9.5-0.95c-5.24-0.67-10.42-2.15-15.58,0.89c-1.36,0.8-3.7,0.5-5.41,0.05c-6.32-1.66-12.09,0.77-18.02,2.07"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M232.49,188.42c-2.5-9-4.95-18.02-7.57-26.98c-0.29-0.99-1.56-2.36-2.43-2.4c-3.15-0.14-6.79-0.72-9.4,0.57
				c-5.96,2.95-11.28,2.45-17.14-0.09c-5.81-2.52-7.5-1.25-7.93,4.9c-0.16,2.32-0.03,4.67-0.03,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M421.53,620.47c-6.25-5.17-11.9-11.56-18.98-15.04c-4.17-2.05-10.65,0.29-16.01,1.14c-2.57,0.41-4.96,1.96-7.42,2.99
				c0,0.65,0.01,1.29,0.01,1.94c2.46,0.82,4.87,2.03,7.4,2.39c6.3,0.9,12.68,1.24,18.98,2.19c1.61,0.24,3.02,1.89,4.52,2.89"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M132.47,445.45c-9.7,0.65-18.13,4.81-25.96,10.07c-9.17,6.16-19.58,8.34-30.07,10.35c-4.24,0.82-8.65,0.97-12.99,0.99
				c-2.33,0.01-4.67-0.91-7-1.41"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M688.58,154.91c-10.84-0.33-21.68-1.11-32.5-0.87c-9.68,0.21-19.33,1.62-29.01,2.34c-4.82,0.36-9.67,0.37-14.5,0.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1195.18,510.46c0.33,1.5,0.95,3,0.96,4.5c0.06,27.17,0.04,54.34,0.04,81.51c0,4,0.03,8-0.01,12c-0.03,3.43,0.73,6.95-3.4,9.18
				c-1.28,0.69-1.32,3.54-2.13,5.31c-0.87,1.89-1.52,4.29-3.04,5.42c-9.15,6.78-9.65,16.69-9.83,26.6
				c-0.08,4.44-1.64,9.48,3.74,14.15c3.58-7.27,6.34-14.3,10.29-20.58c5.72-9.07,4.14-18.89,4.33-28.58c0.02-0.83-0.62-1.67-0.95-2.5
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M158.98,251.43c-7.2-0.35-14.31-2.47-21.56,0.89c-7.54,3.5-15.69,3.3-23.96,2.77c-9.64-0.62-19.48-0.95-28.5,3.84"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1093.66,86.4c-8.84,1.17-17.64,2.86-26.51,3.32c-5.95,0.3-12.01-1.81-17.99-1.66c-8.52,0.21-17.02,1.43-25.5,2.43
				c-1.27,0.15-2.4,1.49-4.98,3.19c7.53,0.84,13.75,1.53,19.97,2.22"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M130.97,602.47c6.83,2.5,13.5,6.4,20.54,7.23c19.31,2.27,34.8,14.35,52.98,19.27"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1189.68,79.4c-4.33,0.67-8.71,2.09-13,1.87c-16.02-0.83-32.01-2.29-48.02-3.3c-3.97-0.25-8,0.27-12,0.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M682.58,172.92c-12.67-0.67-25.33-1.64-38.01-1.9c-9.16-0.19-18.34,0.52-27.5,0.92c-3.64,0.16-10.06,8.18-8.84,11.42
				c0.52,1.37,2.79,2.83,4.35,2.93c6.98,0.47,14,0.38,20.99,0.66c3.67,0.15,7.34,0.63,11,0.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M640.32,134.91c-0.92-0.33-1.83-0.95-2.75-0.96c-16.84-0.22-33.67-0.44-50.51-0.51c-6.5-0.03-13,0.35-19.5,0.44
				c-1.83,0.03-3.85,0.12-5.45-0.58c-2.03-0.89-3.71-2.56-5.55-3.89c2-1.5,3.84-3.33,6.05-4.39c1.56-0.75,3.63-0.36,5.44-0.63
				c3.36-0.49,6.3-0.61,9.34-3.64c2.51-2.5,7.99-2.02,12.17-2.82c0.64-0.12,1.33-0.02,2-0.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M75.96,381.44c-1.33,1.5-2.41,3.4-4.04,4.44c-11.76,7.45-23.94,14.28-35.37,22.21c-9.4,6.52-19.96,9.34-30.6,12.36"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1072.65,531.46c-1.33,0.33-2.96,0.32-3.9,1.12c-0.67,0.57-1,2.86-0.51,3.29c6.26,5.54,9.93,12.67,13.53,20.05
				c3.71,7.6,2.43,15.31,1.84,23.05c-0.43,5.69-1.13,11.36-2.03,16.99c-0.41,2.57-1.59,5.01-2.43,7.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M180.98,428.95c0.92,9.7-0.75,19.1-2.92,28.52c-1.89,8.2-3.38,16.52,5.88,22.05c3.93,2.35,4.88,6.57,5.05,10.93
				c0.11,2.84,0.32,5.67,0.49,8.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M94.46,527.46c2,2,3.74,4.39,6.05,5.94c11.25,7.57,23.58,12.23,36.87,15.47c7.53,1.83,15.12,2.65,22.53,4.41
				c4.45,1.06,9.43,1.74,13.27,6.02c5.95,6.65,12.98,12.37,19.86,18.11c5.75,4.8,11.99,9.01,17.93,13.6
				c1.67,1.29,3.03,2.97,4.53,4.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1195.18,356.94c-7.83,2.67-15.58,5.65-23.53,7.89c-5.51,1.56-11.28,2.78-16.98,2.95c-8.65,0.26-17.33-0.48-26-0.89
				c-0.85-0.04-1.67-0.78-2.5-1.2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M74.46,379.94c-4.67,0.33-9.48,0.06-13.98,1.11c-13.58,3.19-27,7.01-40.55,10.32c-4.56,1.11-9.32,1.4-13.99,2.07"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M356.52,41.4c15.67,0.33,31.35,0.44,47,1.12c5.93,0.26,9.51-4.2,15.23-4.79c-2.76-5.11-5.67-6.29-10.02-3.09
				c-2.22-2.63-4.23-6.36-8.7-3.74"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M229.49,325.43c-0.5-1.5-1.89-3.54-1.36-4.42c4.34-7.17,7.11-14.76,8.45-23.07c1.46-9.04,5.37-11.91,14.38-14.12
				c13.27-3.26,26.35-7.28,39.54-10.87c3.78-1.03,7.68-1.63,11.49-2.54c12.34-2.97,24.66-6.07,37.02-8.94
				c4.1-0.95,8.43-2.28,12.47-1.82c5.43,0.61,11.08,2.01,15.94,4.45c8.19,4.13,16.9,4.71,25.6,5.19c6.8,0.37,13.66-0.79,20.5-0.74
				c3.1,0.02,6.2,1.31,9.3,2.02c0.12,0.82,0.24,1.63,0.35,2.45c-3.22,1.8-6.25,4.55-9.69,5.2c-5.86,1.1-11.97,0.83-17.96,1.25
				c-1.35,0.09-2.7,0.54-4,0.98c-8.76,2.98-17.7,4.69-27.01,4.01c-2.49-0.18-5-0.03-7.5-0.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M957.63,35.4c-1.03,3.44-3.22,2.91-5.49,1.48c-5.22-3.28-5.83-3.35-10.45-0.52c-2.7-1.83-5.17-4.47-8.12-5.23
				c-2.89-0.74-6.88-0.52-9.29,1.01c-2.88,1.82-4.91,2.37-8.19,1.37c-7.2-2.17-14.08,0.72-20.93,2.52c-0.72,0.19-0.92,2.33-1.54,4.03
				c-3.36,0.93-7.68,2.13-12,3.33"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M467.04,512.96c-1.5,2.17-2.82,4.49-4.54,6.47c-4.18,4.83-9.49,7-15.95,7.71c-5.6,0.62-11.33,2.13-16.46,4.46
				c-10.55,4.8-12.85,19.11-10.05,26.85c1.59,4.4,0.12,7.54-4.05,8.94c-7.75,2.59-15.54,5.29-23.53,6.79
				c-3.01,0.57-6.55-2.32-9.97-3.12c-2.21-0.52-4.87-0.73-6.94,0.01c-9.18,3.3-18.05,5.75-28.15,2.74c-4.21-1.26-9.57,1.31-14.4,2.16
				c-7.48,1.32-14.94,3.34-22.5,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M679.08,479.45c1.33,8.83,2.69,17.67,3.99,26.5c0.71,4.83,0.79,9.83,2.15,14.46c1.6,5.45,2.31,10.84,0.21,15.99
				c-1.56,3.83,0.13,6.36,2.25,8.45c2.46,2.42,2.76,4.99,2.97,8.1c0.62,9.35,1.73,18.66,2.36,28.01c0.25,3.81-0.26,7.66-0.43,11.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M454.53,487.45c0.67-1.33,0.98-3.26,2.06-3.9c7.4-4.42,9.91-12.32,13.81-19.18c0.64-1.12,0.02-3.76-0.96-4.84
				c-5.72-6.3-21.88-9.32-28.73-4.33c-4.32,3.15-7,8.65-10.14,13.28c-2.25,3.33-3.52,7.44-6.15,10.38
				c-7.38,8.26-15.11,16.22-22.98,24.02c-2.52,2.49-5.93,4.08-8.93,6.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M263.5,225.42c-5-3.67-9.89-7.51-15.05-10.93c-2.41-1.59-3.82-2.69-3.23-6.12c0.29-1.72-2.03-4.36-3.78-5.88
				c-5.96-5.17-12.18-10.03-18.34-15.04c-7.04,0.75-15.06,1.98-23.11,2.33c-5.14,0.22-10.33-1.16-15.5-1.22
				c-2.16-0.02-5.31,0.89-6.33,2.47c-3.84,5.93-9.97,8.23-15.65,11.43c-9.56,5.38-20.23,4.64-30.53,5.96
				c-1.17,0.15-2.33,0.33-3.5,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M534.55,344.44c-3.38-0.32-7.45,1.54-9.92-2.95c2.19-1.48,4.07-2.75,6.16-4.16c-0.42-0.81-0.62-1.95-1.27-2.34
				c-8.59-5.13-16.12-12.3-26.48-14c-2.27-0.37-4.66-0.08-6.99-0.05c-11.67,0.16-23.64,1.98-34.91-0.06
				c-8.02-1.45-15.1,0.79-22.61,0.55c-2.67-0.09-5.44,0.09-7.98-0.56c-9.52-2.42-25.59,3.5-30.26,13.97
				c-3.44,7.71-9.12,14.43-13.75,21.62c-1.58,2.45-3.01,4.99-4.51,7.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M973.13,221.42c-9.08,0.54-18.1,1.17-27.06,3.27c-4.19,0.99-8.95-0.38-13.45-0.79c-8.67-0.79-17.33-1.79-26.02-2.32
				c-1.09-0.07-2.32,2.17-3.49,3.33"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M436.53,394.69c3.27,9.44-2.63,16.6-7.26,23.07c-7.93,11.08-16.06,21.79-19.77,35.18c-2.36,8.52-9.06,14.21-14.64,20.36
				c-6.07,6.7-11.46,13.51-14.41,22.13c-0.46,1.35-1.93,2.36-2.93,3.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M747.59,685.98c-2.17,0.33-4.33,0.69-6.5,1c-3.66,0.52-7.37,0.82-10.98,1.59c-1.6,0.34-3.55,1.22-4.42,2.48
				c-3.08,4.5-5.79,9.26-8.59,13.94c-3.69,6.15-7.5,12.24-10.95,18.53c-2,3.65-3.22,7.72-5.11,11.44c-1.76,3.46-3.98,6.67-5.94,10.03
				c-1.24,2.13-2.35,4.33-3.51,6.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M96.96,402.44c-6.67,3.17-13.27,6.48-20.02,9.47c-13.72,6.09-27.52,12.13-38.88,22.17c-3.75,3.32-5.64,8.68-9.22,12.27
				c-7.33,7.34-15.24,14.1-22.9,21.1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M553.05,555.46c-5.9-3.76-7.76-9.82-6.07-17.02c2.76-11.77,5.1-23.65,7.53-35.5c0.3-1.44,0.36-3.09-0.05-4.47
				c-0.44-1.45-1.31-3.33-2.5-3.82c-0.9-0.37-2.63,1.05-3.9,1.81c-1.55,0.92-2.94,2.12-4.53,2.95c-4.21,2.2-7.45,5.23-10.84,8.69
				c-4.02,4.11-10.71,5.57-16.14,8.37c-7.66,3.95-14.72-0.17-21.4-2.38c-5.14-1.7-9.46-1.42-14.1-0.08c-3.1,0.89-5.91,1.16-8.52-1.06
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M228.49,503.46c-3.21,4.82-8.8,5.35-13.48,7.56c-2.15,1.01-5.23,2.21-5.83,4.01c-4.06,12.2-14.07,18.7-23.81,25.27
				c-3.1,2.09-7.51,2.74-11.4,3.05c-7.3,0.59-14.69,0.09-21.98,0.76c-4.07,0.38-8.01,2.19-12.02,3.36"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M530.05,605.97c2,7,3.94,14.02,6.04,20.99c0.58,1.91,1.35,3.88,2.51,5.47c3.43,4.68,6.28,9.53,6.47,15.54
				c0.03,0.84,0.11,2.28,0.51,2.41c7.86,2.54,5.97,11.67,10.97,16.09"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1041.65,240.92c-7.89,0.36-15.59,1.1-23.59-1.2c-6.75-1.94-14.25-1.31-21.42-1.79c-1.16-0.08-2.43,0.29-3.48-0.06
				c-8.59-2.86-17.25-1.14-25.45,0.85c-6.04,1.46-11.61,5.37-17.03,8.77c-11.64,7.28-23.22,14.68-34.47,22.55
				c-5.28,3.69-9.75,8.56-14.58,12.89"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M920.12,657.48c-3.17-4.5-6.64-8.82-9.4-13.56c-2.16-3.71-3.57-7.89-5.06-11.96c-1.61-4.4-3.07-4.73-7.08-2.56
				c-9.89,5.38-19.96,10.41-29.98,15.55c-0.93,0.48-2.2,1.23-2.96,0.93c-6.38-2.46-12.4-0.12-18.54,1.05c-4.3,0.83-8.66,1.36-13,2.03
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M384.52,294.43c1.17-0.67,2.32-1.88,3.5-1.92c12-0.31,24.01-0.18,36-0.69c5.86-0.25,11.66-1.76,17.52-2.29
				c2.09-0.19,5.58-1.61,5.91,2.4c0.28,3.52-2.94,4.51-5.43,4.87c-4.27,0.62-8.65,0.48-12.98,0.62c-10.5,0.35-21,0.86-31.51,0.9
				c-3.5,0.01-7.54-0.36-10.37-2.1c-4.06-2.5-7.96-1.9-12.13-1.87c-3.66,0.03-7.37-0.38-10.99-0.98
				c-4.07-0.67-10.15,5.12-11.58,10.54c-2.75,10.4-5.52,20.83-9.17,30.94c-1.16,3.21-5.1,5.42-7.77,8.09"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M177.48,652.97c-0.8,4.5,1.65,8.18,7,10.5c6.1,2.64,6.11,3.2,3.14,9.07c-0.77,1.53-0.59,4.7,0.49,5.8
				c5.03,5.13,10.37,10.01,15.96,14.52c1.92,1.55,4.85,2.15,7.42,2.55c10.42,1.61,8.38,0.4,6.99,9.07"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M474.54,529.46c2.28,3.73,4.16,7.46,2.1,12.05c-0.38,0.85,0.35,2.42,0.95,3.42c0.78,1.3,3.04,2.77,2.78,3.47
				c-2.15,5.74,2.73,9.16,3.84,13.65c1.06,4.28-0.83,9.24-1.12,13.92c-0.31,5.03,1.23,9.52,4.43,13.52c4.29,5.39,4.83,13.48,0.8,20.2
				c-1.29-3.65-2.28-6.44-3.27-9.22"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1195.18,335.44c-0.67,0-1.44-0.23-1.98,0.04c-5.22,2.55-10.34,4.33-16.52,4.31c-4.99-0.02-10.35,2.35-14.93,4.8
				c-8.43,4.51-16.43,9.84-24.53,14.93c-0.44,0.27-0.21,1.59-0.3,2.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1146.17,648.47c1.17-0.67,2.54-1.13,3.45-2.05c1.38-1.4,2.43-3.12,4.52-5.9c3.4,9.97,6.28,18.19,9,26.46
				c1.18,3.61,1.25,7.82,3.19,10.89c4.85,7.72,5.16,16.03,4.85,24.6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M674.08,479.95c0.67,5.5,1.36,11,1.99,16.5c1.19,10.33,2.82,20.65,3.33,31.01c0.31,6.29-1.18,12.66-1.8,19
				c-0.14,1.49-0.55,3.27,0.06,4.45c3.76,7.25,3.81,15.01,2.21,22.5c-1.3,6.08,2.01,10.1,5.4,12.81c7.12,5.69,11.85,12.95,16.88,20.2
				c2.61,3.77,5.99,7,8.89,10.58c0.77,0.95,1.04,2.3,1.54,3.47c-1.33,0.17-2.7,0.61-3.99,0.44c-2.03-0.26-4.01-0.94-6.01-1.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M303.5,281.43c-1.96,10.27-6.39,13.82-17.5,14c-10.67,0.18-21.34,0.28-32,0.55c-1.68,0.04-3.34,0.78-5,1.2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M271,29.9c-8.17,0.5-16.34,0.89-24.5,1.53c-8.73,0.68-17.33,0.94-25.54-2.97c-1.29-0.61-2.97-0.52-4.47-0.54
				c-5.5-0.06-11.02,0.23-16.5-0.14c-2.55-0.17-5.01-1.55-7.51-2.39"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M249.49,608.47c-0.17,0-0.37-0.06-0.49,0.01c-7.94,4.87-13.09-0.64-18.5-5.02c-5.27-4.26-10.48-7.93-18.03-7.24
				c-3.37,0.31-7.04-1.93-10.46-3.3c-3.25-1.29-6.66-2.53-9.46-4.54c-5.92-4.26-12.8-5.68-19.6-6.99c-3.81-0.73-8.02,0.01-11.96,0.63
				c-9.37,1.47-18.68,3.28-28.02,4.93c-2.16,0.38-4.56,0.21-6.43,1.14c-1.8,0.89-3.54,2.66-4.33,4.47c-0.3,0.69,1.9,2.84,3.3,3.85
				c5.29,3.79,5.78,5.48,2.46,10.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M875.62,125.91c-4,0.67-7.99,1.78-12.01,1.9c-7.37,0.22-14.43,2.49-21.54,3.19c-10.85,1.08-22,3.53-33.07-0.84
				c-3.35-1.32-7.98,0.03-11.88,0.84c-5.27,1.09-10.13,2.29-15.15-1.42c-2.07-1.53-5.54-1.17-8.37-1.67"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M778.1,640.47c-4.65,0.14-11,7.1-10.5,11.5c0.88,7.8,0,9.11-6.97,12.57c-2.87,1.42-5.27,3.77-8.06,5.39
				c-3.74,2.17-7.66,4.01-11.47,6.05c-5.13,2.74-10.37,5.4-11.51,11.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M443.53,197.92c5,0.33,10.01,0.55,15,1.03c5.35,0.52,10.66,1.5,16.01,1.89c3.3,0.24,6.88,0.4,9.94-0.59
				c4.21-1.36,8.09-3.77,12.02-5.9c0.74-0.4,1.61-1.82,1.4-2.39c-0.31-0.86-1.57-2-2.36-1.95c-4.51,0.29-9.23,0.2-13.46,1.56
				c-6.36,2.05-12.54,2.56-19.07,1.43c-2.4-0.41-5.02,0.04-7.48,0.44c-15.65,2.5-31.35,0.23-47.01,0.42c-0.66,0.01-1.33-0.62-2-0.96"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1195.18,172.92c-6,0-12.03,0.37-18-0.09c-9.48-0.73-18.48,1.34-27.52,3.56c-0.79,0.19-1.68,0.13-2.49,0.01
				c-7.31-1.12-7.68-0.73-10.55,6c-3.46,8.09-7.3,16.02-10.94,24.03c-0.61,1.34-1.08,2.75-2.4,3.54c-1.63-5.44-2.31-8.97-8.69-6.77
				c-4.36,1.5-11.08-4.42-11.3-9.28c-0.18-3.91-2.27-3.87-5.11-4.09c-2.21-0.18-6.07-1.01-6.19-1.94c-0.38-2.88,0.43-6.03,1.23-8.95
				c2.2-8.04,4.63-16.01,6.94-24.02c0.24-0.81,0.34-1.66,0.51-2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M306.01,198.42c-2.82,3.68-6.4-0.1-9.52,0.94c-0.7,0.23-1.73-0.58-2.47-0.39c-8.85,2.26-17.59,5.12-26.55,6.79
				c-7.02,1.31-14.31,1.16-21.47,1.66"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1195.18,345.94c-9.51,0.77-18.66,1.65-25.66,9.86c-4.76,5.58-12.38,6.34-19.36,7c-4.1,0.39-8.32-0.53-12.49-0.87"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M147.97,497.46c-13.71-3.27-27.58-2.45-41.51-2.16c-16.66,0.34-33.4,0.25-49.98-1.11c-10.83-0.89-20.27-0.13-29.69,6.02
				c-6.13,4-13.84,5.59-20.84,8.25"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M803.1,543.46c6,8.17,11.89,16.43,18.09,24.43c1.4,1.8,4.07,2.55,5.87,4.11c2.49,2.16,4.74,4.59,7.03,6.96
				c2.87,2.97,5.68,5.99,8.51,9c2.18,2.32,4.34,4.67,6.5,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M357.02,313.93c6.67,1.67,13.26,3.91,20.03,4.84c7.41,1.02,15,1.26,22.48,1c4.86-0.17,9.67-1.84,14.51-2.84"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M117.47,267.93c-5.67-0.17-11.34-0.31-17-0.51c-2.67-0.1-6.11,0.68-7.86-0.67c-6.69-5.13-14.02-3.07-21.14-2.71
				c-3.7,0.19-7.34,1.55-11.01,2.39"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M973.64,153.41c-12.17,0.5-24.34,0.9-36.5,1.65c-1.23,0.08-3.26,2.12-3.35,3.37c-0.37,5.36-1.94,10.07-5.66,13.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M706.08,235.92c0.9-2.7,1.8-5.4,2.33-7c4.35,0,8.03-0.26,11.66,0.08c3.37,0.31,6.68,1.21,10.01,1.93
				c5.18,1.13,10.33,2.36,15.51,3.46c0.96,0.2,2.39,0.46,2.93-0.04c6.13-5.63,14.12-3.03,21.06-4.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M734.09,65.9c-2.83,0-6.3,1.04-8.38-0.21c-4.93-2.97-9.43-1.17-14.13-0.28c-3.44,0.65-6.76,1.51-9.13-2.44
				c-0.5-0.83-2.59-1.13-3.87-1c-4.85,0.49-9.66,1.44-14.52,1.86c-2.64,0.23-5.35-0.27-9.43-0.53c2.62-1.64,4.03-2.52,5.44-3.4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M645.57,429.45c-16.17,4.17-32.32,8.42-48.53,12.41c-2.51,0.62-5.31,0.09-7.98,0.09"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M250.49,665.48c1.33,1.67,2.6,3.4,4.02,4.99c2.72,3.05,2.92,5.8,0.05,9.07c-3,3.44-1.97,5.94,2.41,7.98
				c2.76,1.29,5.35,2.96,8.02,4.46c-2.67,1.83-5.33,3.67-8,5.5c-0.99,0.68-2.2,1.16-2.96,2.04c-1.32,1.53-3.21,3.27-3.27,4.97
				c-0.05,1.28,2.17,3.1,3.77,3.91c6.08,3.1,12.72,3.95,19.46,4.08c7.58,0.15,14.34,2.88,20.42,7.12c1.53,1.07,1.87,3.71,3.14,5.34
				c1.43,1.85,3.28,3.38,4.95,5.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M862.11,740.49c-0.33-3.83-0.83-7.66-0.96-11.5c-0.17-4.83,0.16-9.68-0.16-14.49c-0.1-1.55-1.19-3.76-2.45-4.38
				c-5.18-2.5-6.56-4.59-5.94-10.63c0.53-5.17-2.33-9.67-8.56-11.45c0.77,4.11,0.8,7.81,2.21,10.88c1.86,4.02,2.45,7.6,0.36,11.58
				c-5.51,10.49-4.12,21.22-1.01,32"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M941.63,64.9c-3-0.33-6.04-1.14-8.99-0.9c-6.53,0.52-13,1.7-19.52,2.36c-4.14,0.42-9.34,2.01-12.27,0.18
				c-5.01-3.12-9.25,0.05-13.73-0.64c-1-0.16-2.83-0.21-2.88-0.52c-1.01-6.81-6.26-3.12-9.62-3.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M121.97,607.97c2.39-2.69,4.53-3.07,6.56,0.47c0.42,0.74,1.62,1.02,2.44,1.54c15.67,9.83,31.35,19.65,47,29.51
				c4.64,2.92,8.47,2.77,12.48-1.04c3.02-2.88,5.81-5.85,10.54-5.55c0.96,0.06,2.67-0.72,2.89-1.45c1.87-6.28,6.51-5.68,11.61-5.71
				c2.84-0.01,5.67-2.42,8.5-3.77c3.84-1.82,7.81-3.43,11.44-5.6c1.84-1.1,3.06-3.23,4.56-4.9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1083.66,99.41c-2.17-0.17-4.33-0.47-6.5-0.48c-11.84-0.05-23.67-0.1-35.51,0.07c-1.68,0.02-3.59,0.92-4.95,1.98
				c-3.16,2.45-5.86,1.97-9.11-1.08c2.36-1.07,4.46-2.03,6.55-2.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M40.95,578.97c5,2,9.94,4.18,15.02,5.94c3.73,1.3,7.72,1.86,11.46,3.14c2.15,0.74,4.32,1.95,5.95,3.51
				c1.7,1.62,2.73,3.93,4.09,5.91c1.8,2.63,5.16,2.73,7.21,3.97c5.64,3.43,11.38,0.87,16.81,2.46c6.24,1.82,12.65,3.06,18.98,4.59
				c1.02,0.25,2.29,0.36,2.96,1.03c13.38,13.3,28.43,24.48,44.01,35.02c2.11,1.43,3.64,3.68,5.55,5.43c4.05,3.71,6.67,2.95,8.46-2.5
				c0.53-1.6,0.7-3.32,1.03-4.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M743.09,551.46c-2.19,5.3,1.72,9.04,4.19,12.36c5.47,7.36,10.57,15.14,18.7,20.32c3.33,2.12,5.35,6.25,8.14,9.3
				c2.7,2.95,5.64,5.69,8.47,8.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M492.04,213.92c-5.17,0.67-10.32,1.76-15.51,1.91c-10.16,0.3-20.35,0.41-30.5-0.02c-6.6-0.28-11.9,1.35-16.04,6.59
				c-1.31,1.65-2.97,3.02-4.47,4.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M356.02,283.93c-5.12,1.7-10.13,1.62-15.54,0.74c-5.49-0.9-11.3,0.06-16.96,0.31c-0.85,0.04-1.94,0.39-2.46,1
				c-4.63,5.32-11.36,7.32-17.01,11.02c-0.42,0.28-0.37,1.28-0.53,1.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M612.06,176.42c3.5,0.83,6.98,2.25,10.51,2.38c7.47,0.29,14.69,0.96,22.09,2.73c6.9,1.65,14.58,0.05,21.91-0.11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M823.61,160.91c-1.17,0.33-2.32,0.89-3.5,0.97c-11.5,0.72-23.04,0.99-34.49,2.17c-7.25,0.75-14.5,2.46-18.54,9.85
				c-2.22,4.06-1.4,7.51,3.06,9.44c3.51,1.52,7.27,2.54,11,3.43c0.6,0.14,2.35-1.54,2.34-2.36c-0.05-3.67-0.57-7.33-0.86-11
				c-0.09-1.16-0.01-2.33-0.01-3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M768.6,502.46c0.72,8.71,3.1,16.21,11.39,21.19c3.99,2.4,7.51,6.38,9.94,10.42c3.18,5.29,8.27,7.52,13.25,8.54
				c4.07,0.83,6.48,2.45,8.99,5.3c3.58,4.06,7.24,8.2,11.52,11.45c7.86,5.98,13.79,14.44,23.42,18.12c2.86,1.09,5.67,2.32,8.51,3.49"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M799.1,271.93c2.5,3.17,5.23,6.18,7.42,9.55c1.44,2.23,2.51,4.08,5.56,4.59c2.75,0.46,4.67,2.27,4.64,5.86
				c-0.04,4.72,1.9,8.53,7.39,8.99c6.73,0.56,10.98,5.46,16,9.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M688.08,385.94c5,1.33,9.96,2.85,15.01,3.95c4.28,0.93,8.65,1.85,12.99,1.94c5.82,0.12,11.8,0.09,17.47-1.05
				c7.42-1.49,14.65-2.51,22.04-0.34"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M696.08,303.43c7.83,2,15.67,3.98,23.5,6.02c1.53,0.4,3.89,0.56,4.36,1.55c3.19,6.72,10.26,6.62,15.66,9.41
				c1.26,0.65,2.95,0.69,3.94,1.57c7.46,6.62,16.67,8.19,26.04,9.52c2,0.28,3.88,1.47,5.72,3.5c-2.24,0.81-4.41,1.96-6.72,2.37
				c-6.31,1.14-6.33,1.01-4.99,7.06"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M598.06,295.93c-2.33,1.5-4.81,2.83-6.94,4.57c-1.15,0.94-2.56,2.7-2.41,3.91c0.53,4.2-2.36,5.49-5.17,5.9
				c-6.6,0.97-12.39,5.43-19.51,4.28c-0.98-0.16-2.49,1.69-3.43,2.88c-1.34,1.7-2.48,3.58-3.5,5.49c-0.56,1.05-1.31,2.66-0.89,3.39
				c0.58,1.01,2.22,2.03,3.35,1.97c3.52-0.19,7.54,0.06,10.4-1.57c7.44-4.24,14.65-1.81,22.12-0.94c6.6,0.76,13.33,0.36,19.99,0.68
				c2.38,0.11,4.72,0.85,8.5,1.57c-7.56,5.67-6.69,13.15-7.68,20.35c-1.67,12.09-4.25,24.08-7.03,35.98
				c-0.51,2.18-3.72,3.79-5.81,5.52c-1.56,1.3-3.31,2.36-4.98,3.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M97.96,634.97c10.54,3.31,21.65,5.26,29.16,14.88c2.17,2.78,6.12,4.22,9.36,6.1c5.02,2.9,10.62,5.18,11.49,12.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1019.64,38.9c6.26,1.73,12.4,2.77,19.05,1.18c4.61-1.11,9.66-0.22,14.44-0.8c2.42-0.3,4.91-1.56,6.92-3.01
				c1.04-0.75,1.5-2.87,1.51-4.37c0.01-2.81-2.07-3.28-4.41-3c-1.17,0.14-3.02,0.88-3.39,0.41c-3.28-4.07-5.8-2.12-8.71,0.48
				c-0.66,0.59-2.64-0.01-3.89-0.43c-5.04-1.7-8.15-0.18-11.18,4.43c-0.91,1.38-3.82,1.47-5.84,2.1c-1.94,0.61-5.17-1.63-5.5,2.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1172.17,625.47c0.17,5.33,0.48,10.67,0.46,16c-0.02,7-0.22,14.01-0.53,21c-0.05,1.25-0.99,2.46-1.7,4.11
				c-6.2-3.83-6.35-10.4-8.63-15.65c-2.31-5.3-3.77-10.96-5.6-16.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M923.63,106.91c-2.5-0.33-5.02-1.04-7.5-0.94c-12.18,0.53-24.34,1.24-36.51,1.95c-4.17,0.24-8.34,0.65-12.5,0.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M728.59,202.42c-10,0.83-20.01,1.6-30,2.57c-1.39,0.13-3.62,1.03-3.85,1.97c-1.36,5.48-3.81,8.98-9.39,7.4
				c-1.67,4.23-3.22,8.15-4.77,12.07"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M287.5,403.94c-3.5,0.67-6.98,1.59-10.51,1.94c-4.47,0.44-9.01,0.21-13.49,0.64c-2.38,0.23-5.56,0.51-6.86,2.05
				c-6.93,8.22-15.61,3.76-23.65,3.88"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1133.67,699.98c-16.13,4.98-31.47,13.01-49.01,12.52c-9.67-0.27-19.34-0.41-29.01-0.47c-2-0.01-4.08,1.14-5.99,0.85
				c-5.88-0.9-11.68-2.26-17.52-3.4c-8.27-1.6-16.61-3.94-24.96-1.38c-4.72,1.45-9.06,4.17-13.53,6.39
				c-1.67,0.82-3.24,1.83-5.15,2.93c-3.38-7.52-6.92-14.62-9.68-22.01c-0.83-2.23,0.49-5.27,0.82-7.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M371.52,389.44c-7.67-2.33-15.21-5.38-23.04-6.77c-6.3-1.12-12.98-0.48-19.46-0.15c-5.69,0.29-11.41,0.91-16.99,1.99
				c-11.72,2.28-23.37,0.4-35.01-0.21c-8.54-0.45-16.99-2.44-25.52-3.27c-5.12-0.5-10.34-0.35-15.49-0.03
				c-6.19,0.39-12.5,0.62-18.45,2.15c-4.43,1.13-8.37,4.21-12.52,6.42c0.46,0.39,0.92,0.79,1.38,1.18c-0.8,2.56-1.89,5.08-2.32,7.71
				c-0.31,1.92-0.5,4.57,0.55,5.87c2.93,3.66,6.53,6.78,9.86,10.11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M8.45,496.46c4.65-7.05,11.3-11.29,18.95-14.63c6.12-2.68,11.73-6.52,17.56-9.87c2.85-1.64,5.67-3.33,8.5-5
				c1.11-0.65,2.22-1.3,3.33-1.95c-0.22-0.65-0.43-1.3-0.65-1.94c-3.06,0.8-6.3,1.2-9.15,2.46c-7.43,3.28-14.5,7.48-22.1,10.25
				c-6.08,2.21-10.27,5.8-13.49,11.15c-1.52,2.52-3.61,4.7-5.45,7.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M558.55,172.42c-5.88-4.28-10.21-9.89-12.89-16.55c-1.52-3.77-3.61-4.55-7.12-3.48c-1.46,0.45-3.5,1.47-4.41,0.89
				c-5.01-3.2-9.93,0.2-14.55-0.23c-13.56-1.25-26.18,4.88-39.54,3.84c-0.68-0.05-1.33-0.32-2.29-0.56
				c-3.44,7.42-6.28,15.24-13.09,20.73c-1.83,1.47-2.07,4.87-3.15,7.34c-0.53,1.22-1.31,2.34-1.98,3.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M442.03,28.4c-3.53-1.5-6.97-3.94-10.98-1.47c-0.98,0.6-1.69,1.63-2.52,2.47c1,0.83,1.91,2.23,3.02,2.4
				c4.63,0.73,9.32,1.45,13.99,1.43c2-0.01,4.2-1.59,5.89-2.97c0.55-0.45-0.22-2.53-0.39-3.86c-2.17,0.33-4.34,0.61-6.5,1.03
				c-0.7,0.14-1.34,0.64-2.01,0.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1090.66,186.42c-8.5,0.33-17.01,1-25.5,0.91c-9.17-0.1-18.34-0.91-27.51-1.41"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M209.49,410.94c-10.64,11.55-23.17,18.63-39.51,17.59c-5.97-0.38-12.02,0.03-17.98,0.57c-1.43,0.13-3.21,1.93-3.92,3.39
				c-2.12,4.41-4.41,8.39-7.83,12.2c-4.15,4.63-6.71,10.91-9.14,16.81c-2.08,5.05-5.74,7.91-10.1,10.51c-1.25,0.75-3,2.37-2.88,3.42
				c0.68,6.21-1.52,11.14-5.61,15.56c-0.65,0.7-0.71,1.96-1.04,2.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M812.6,641.47c0.33,3.33,0.62,6.67,1.04,10c0.09,0.7,0.45,1.66,0.99,1.95c4.78,2.65,7.33,6.58,8.14,12.03
				c0.2,1.34,2.5,3.36,3.83,3.36c6.41-0.04,7.8,4.69,10.12,9.11c1.91,3.64,4.88,6.73,7.38,10.06"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M779.6,637.97c-1.67,4.83-3.5,9.62-4.94,14.52c-0.85,2.9-0.53,6.2-1.67,8.94c-3.21,7.75-5.53,15.93-15.81,18.43
				c-3.96,0.96-8.15,4.34-9.89,10.03c-2.29,7.48-8.17,13.07-14.7,17.59"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M688.58,382.94c5.5,1,11.05,1.78,16.49,3.04c11.66,2.7,23.23,2.38,35.05,0.67c6.83-0.99,13.97,0.23,20.97,0.25
				c4.83,0.02,9.67-0.3,14.5-0.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1124.16,535.46c-1.5,1.17-3.06,2.27-4.49,3.51c-10.08,8.73-18.9,18.65-24.78,30.61c-2.17,4.41-1.69,10.13-2.43,15.25
				c0.73,0.35,1.45,0.7,2.18,1.05c6.34-6.8,12.68-13.61,19.02-20.41"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M224.49,553.96c-1.67,0.5-3.32,1.36-5,1.44c-12,0.61-24,1.13-36.01,1.51c-3.83,0.12-7.67-0.29-11.5-0.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M963.13,241.42c3.19,4.64,8.32,2.41,12.51,3.45c2.68,0.66,4.67,0.93,6.91,3.71c2.22,2.74,7.86,3.41,12.09,3.71
				c12.48,0.88,24.99,1.16,37.5,1.62c3.16,0.12,6.61,0.77,9.41-0.24c1.97-0.71,6.08-0.34,5.74-4.76c-0.06-0.75,1.82-2.32,2.86-2.37
				c4.59-0.22,8.67-1.39,12.59-3.97c1.59-1.05,4.24-0.48,6.41-0.64"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M361.52,194.42c3.24-1.89,6.48-3.77,9.72-5.66c-0.08-0.46-0.15-0.93-0.23-1.39c-4.16,0.19-8.33,0.29-12.49,0.59
				c-2.35,0.17-4.66,0.73-7.01,0.95c-7.33,0.71-14.69,1.14-21.99,2.08c-5.57,0.72-10.35-0.97-15.08-3.5
				c-2.43,2.76-4.93,5.6-7.43,8.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M560.55,653.47c2.87,7.48,6.16,13.94,15.99,14.37c2.77,0.12,6.13,4.19,7.85,7.23c3.37,5.95,7.17,11.34,12.71,15.35
				c1.22,0.89,2.96,1.06,4.46,1.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M362.02,418.95c9.98,7.64,17.99,16.95,24.06,27.97c0.94,1.71,2.51,3.14,3.97,4.51c2.86,2.66,5.48,5.2,6.18,9.49
				c0.33,2.02,2.8,3.7,4.3,5.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M821.61,70.9c-2,0-4.2-0.53-5.95,0.12c-2.67,1.01-4.08,0.31-6.21-1.42c-1.49-1.21-4.91-1.4-6.76-0.54
				c-5.97,2.78-11.52,2.53-17.06-0.69c-1.43-0.83-3.07-1.71-3.95-3.02c-4.44-6.63-13.91-6.71-17.99,0.09
				c-0.4,0.66,0.26,1.95,0.42,2.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M981.64,401.94c0.5,2.17,0.88,4.37,1.52,6.49c2.45,8.02,5.34,15.92,7.36,24.04c1.58,6.36,2.24,12.96,3.02,19.49
				c0.17,1.42-0.9,2.99-1.4,4.49c-1.33-1-3.13-1.72-3.92-3.05c-2.88-4.88-5.28-10.03-8.13-14.93c-2.11-3.63-4.62-7.03-6.96-10.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M404.27,327.93c-5.58-0.67-11.16-1.44-16.76-1.98c-11.33-1.08-22.67-2.02-34-3.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M136.47,730.48c16.67,11,33.34,22,50.01,33"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M613.57,269.93c-0.86,3.89,1.43,6.85,3.01,9.99c5.91,11.71,3.64,15.36-9.51,15.53c-6.68,0.09-13.12-0.37-18.56-4.96
				c-0.58-0.49-2,0.14-2.93-0.11c-2.04-0.53-5.09-0.7-5.83-2.06c-3.57-6.54-9.45-7-15.69-6.89c-8.03,0.15-15.72,0.19-23.68,3.56
				c-7.66,3.24-16.84,3.26-25.31,0.84c-4.69-1.34-7.01-0.94-8.84,4.47c-1.42,4.2-5.98,7.24-8.59,11.17
				c-2.12,3.2-3.43,6.94-5.09,10.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1083.16,334.94c6.49,0.16,13.52-2.33,18.61,4.42c1.13,1.49,3.63,1.95,5.94,3.11c-2.42,4.38-6.01,3.71-9.55,2.96
				c-7.16-1.52-14.35-3.76-22.23,2.25c2.92,0.55,4.87,1.36,6.72,1.19c10.97-1.06,20.99,3.57,31.48,5.3c3.24,0.54,6.23,3.54,8.99,5.83
				c3.92,3.24,7.82,3.3,12.05,0.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M120.47,735.48c6.41,7.49,14.43,12.93,23.04,17.43c6.8,3.55,14.06,6.22,20.92,9.66c3.4,1.7,6.54,4.06,9.51,6.46
				c5.05,4.09,10.69,7.02,17.05,7.86c8.2,1.09,14.06,6.62,20.99,10.1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M762.09,448.45c-2.83,0.17-5.77-0.09-8.48,0.58c-8.55,2.12-17.05,4.5-25.5,6.99c-1.81,0.53-4.36,1.59-4.83,2.99
				c-1.49,4.45-4.92,5.27-8.69,5.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M606.06,566.96c1,5.67,1.83,11.37,3.08,16.98c0.51,2.28,2.64,4.3,2.79,6.53c0.59,8.86,7.93,15.49,8.65,23.54
				c0.77,8.63,6.36,14.21,8.98,21.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M706.08,101.91c-3.5,0.67-6.99,1.81-10.5,1.91c-13,0.38-26,0.42-39.01,0.59"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M298,361.94c-4.67,0.83-9.31,2.11-14.01,2.41c-11.82,0.76-23.67,1.09-35.5,1.59"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M584.06,207.92c3.5-0.33,7-0.71,10.5-0.98c1.7-0.13,3.23-0.03,3.14,2.49c-0.02,0.47,1.87,1.45,2.85,1.41
				c3.67-0.13,7.34-0.91,11-0.86c11,0.16,22,0.71,33.01,0.88c3.66,0.05,7.72,0.25,10.89-1.19c2.88-1.31,4.9-4.65,7.07-7.28
				c1.54-1.87,2.65-1.62,5.09-1.25c6.54,1,13.29,0.62,19.96,0.79"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M275,600.97c3.5,1.33,7.17,2.35,10.43,4.13c1.73,0.94,4.14,3.55,3.83,4.81c-1.3,5.2,2.1,5.5,5.24,5.46
				c4.74-0.06,4.94,2.86,3.37,5.52c-2.89,4.9-4.28,10-4.37,15.58"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1082.66,334.94c-2.33,0.33-4.66,0.8-7,0.97c-2.6,0.19-4.46,1.39-6.72,2.63c-4.36,2.4-7.1,5.59-9.9,9.31
				c-1.06,1.41-3.58,1.69-5.36,2.62c-1.39,0.72-3.12,1.34-3.94,2.53c-4.45,6.5-13.36,7.48-19.58,1.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M619.82,426.95c-10.48,5.36-21.56,8.57-33.24,10.11c-5.25,0.69-10.35,2.56-15.52,3.89"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M696.58,523.96c2.33,17.03,9.63,32.28,16.99,47.51c0.13,0.27,0.02,0.66,0.02,0.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M877.62,483.45c-3.5,3.5-7,7-10.5,10.5c-3.33,3.33-6.75,6.59-9.97,10.03c-1.54,1.64-2.97,3.5-3.98,5.49
				c-2.55,5.02-4.87,10.03-10.5,12.6c-1.53,0.7-2.39,2.88-3.56,4.38"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M322.51,564.96c-2.17,1.83-4.23,3.82-6.53,5.46c-2.62,1.87-7.23,1.51-6.25,7.09c0.23,1.33-3,3.34-4.73,4.94
				c-3.19,2.96-6.45,5.85-10.9,9.88c5.47,2.3,9.45,3.94,13.41,5.65c2.19,0.94,4.6,1.65,6.44,3.08c2.36,1.83,4.62,3.73,1.77,7.57
				c-1.48,1.99-2.99,5.41,0.1,8.54c0.92,0.93,0.03,4.41-0.93,6.25c-1.35,2.59-3.55,4.73-5.38,7.06"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M880.62,455.45c-4.67-0.17-9.35-0.61-14-0.42c-4.16,0.17-6.83-1.12-8.56-5.06c-3.48-7.91-7.29-15.69-10.94-23.53
				c-2.5-5.37-6.25-7.25-12-7.6c-10.66-0.64-21.18-1.98-31.06-6.8c-7.16-3.49-13.82-7.4-19.56-13c-1.11-1.08-3.69-1.38-5.38-1.01
				c-14.54,3.17-29.11,6.26-43.48,10.08c-5.02,1.33-10.8,1.54-14.12,7.66c-1.68,3.1-6.66,4.41-9.99,6.43
				c1.65,2.91,2.65,4.68,3.78,6.68c-2.13,1.52-5.35,3.81-9.33,6.64c6.74,5.43,6.07,10.93,1.11,16.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M242.49,610.97c6.67,4.17,13.12,8.75,20.06,12.4c8.77,4.6,17.95,8.44,26.95,12.61"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M910.62,158.41c-0.67-0.67-1.38-1.95-1.99-1.91c-11.69,0.81-23.32,1.64-35.04-0.43c-4.96-0.88-9.35,0.14-12.31,6.17
				c-2.53,5.14-8,8.83-12.16,13.17"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M419.03,566.46c0.67,4.17,1.96,8.36,1.78,12.49c-0.12,2.78-1.37,6.34-5.79,6.07c-0.98-0.06-2.34,0.31-2.94,0.99
				c-6.28,7.11-15.06,7.99-23.53,10.05c-12.11,2.95-22.66,9.6-33.51,15.44c-0.83,0.45-1.36,1.46-2.02,2.21"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M529.55,231.92c-13.17,0-26.34-0.21-39.5,0.16c-2.99,0.09-5.93,2.21-8.89,3.39c0.05,0.51,0.11,1.01,0.16,1.52
				c2.41,0.98,4.81,1.96,7.22,2.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M887.62,56.9c-3.33,0-6.67,0.13-10-0.04c-2.84-0.14-5.66-0.79-8.51-0.93c-8.33-0.42-17.62-3.16-24.76-0.41
				c-8.89,3.43-18.74,0.34-26.75,5.88"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M306.01,505.46c-1.17,15.67-2.33,31.34-3.5,47.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M253.5,22.4c-3.19-4.04-6.64-6.05-11.01-1.51c-0.24,0.25-0.65,0.47-0.99,0.49c-8.83,0.52-17.68,0.91-26.5,1.55
				c-6.85,0.5-13.67,1.3-20.51,1.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1036.15,175.92c6.71,4.41,13.55,7.58,22.04,5.14c3.94-1.13,8.3-0.8,12.46-1.14c4.17-0.33,8.34-0.67,12.5-1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M824.11,195.42c1.04-3.99-0.6-5.93-5.5-6.5c-8.4-0.98-8.28-0.99-8.57-9.5c-0.1-3-0.07-6.29,3.1-6.76
				c2.49-0.37,5.43,1.15,7.93,2.34c2.35,1.12,4.26,3.14,6.57,4.37c3.21,1.71,6.36,3.1,6.64,7.54c0.07,1.07,2.03,2.58,3.36,2.9
				c4.27,1.03,7.72,2.27,9.73,7c1.1,2.59,4.75,4.11,7.25,6.11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M251.49,665.48c7-6,13.56-12.65,21.14-17.81c6.52-4.44,14.11-7.4,21.4-10.62c4.32-1.91,9.09-2.8,13.45-4.63
				c13.56-5.69,26.97-11.75,40.59-17.31c2.08-0.85,5.04-0.19,7.43,0.42c11.32,2.88,21.95,8.78,34.02,8.36
				c8.51-0.29,17-1.52,25.51-1.83c5.15-0.19,10.49-0.08,15.45,1.11c3.84,0.92,6.48,0.38,9.05-2.21"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1048.65,206.42c-9.17-0.5-18.33-1.19-27.51-1.44c-6.33-0.17-12.67,0.27-19,0.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M366.02,273.93c-1.17-0.5-2.33-1.42-3.5-1.43c-12-0.11-24.03-0.51-36,0.11c-6.55,0.34-13.06,2.31-19.48,3.96
				c-1.42,0.37-2.37,2.53-3.54,3.86"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M877.12,408.94c-1.7,6.74,1.28,13.01,1.9,19.51c0.38,3.95-0.4,7.99-0.34,11.99c0.04,2.84,0.35,5.73,1.02,8.48
				c0.67,2.75,2.16,5.29,2.87,8.03c0.99,3.81,3.49,5.05,7.06,4.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M772.6,421.95c-2.5,0.5-5.06,0.82-7.48,1.56c-1.68,0.51-3.19,1.61-4.77,2.44c0.92,1.17,1.85,2.32,2.75,3.5
				c1.79,2.37,3.57,4.75,5.67,7.55c-2.09,3.75-4.04,8.1-6.81,11.85c-1.32,1.79-4.07,2.84-6.38,3.54c-8.28,2.51-16.67,4.65-24.96,7.14
				c-1.88,0.56-4.3,1.29-3.14,4.48c0.19,0.52-1.09,1.77-1.91,2.42c-1.4,1.12-2.97,2.03-4.47,3.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M547.55,630.97c-1.12,8.3,6.38,17.87,14.91,21.25c4.54,1.8,8.04,6.17,12.13,9.22c1.66,1.24,3.58,2.18,5.49,3
				c2.27,0.98,5.64,1,6.82,2.66c5.23,7.39,9.84,15.22,14.67,22.88c1.16,1.84,2.22,3.75,3.52,5.48c2.42,3.23,4.86,6.46,7.52,9.49
				c2.18,2.48,4.27,5.49,8.48,4.08c0.9-0.3,2.23-0.43,2.95,0.02c6.6,4.1,13.12,8.35,20.09,12.84c-4.49,3.48-7,6.43-3.68,11.2
				c0.73,1.04,0.44,3.87-0.47,4.81c-7.95,8.18-5.07,15.88,0.59,23.61c0.26,0.35,0.34,0.83,0.51,1.24"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1052.65,210.92c-1.08-0.67-2.18-1.94-3.25-1.91c-14.42,0.36-28.84,0.9-43.26,1.41c-3.13,0.11-5.42,0.31-4.72-4.54
				c0.72-4.95-7.93-15.54-12.82-16.25c-1.63-0.24-3.53,2.33-5.49,3.22c-2.67,1.22-0.9,2.05,0.02,3.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1071.65,174.92c-8.84-0.33-17.67-0.7-26.51-0.99c-5.06-0.17-10.04-0.16-14.57,2.89c-1.22,0.82-3.27,0.43-4.93,0.6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M653.57,467.95c-9.17,2.67-19.62,3.43-27.16,8.52c-8.11,5.48-16.52,2.81-24.34,5.52c-1.75,0.6-3.79,1.24-4.94,2.53
				c-4.92,5.56-11.65,6.95-17.55,2.89c-6.25-4.29-11.71-2.47-17.55,1.51c-2.71,1.85-5.65,3.36-8.48,5.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1195.18,643.47c1.43,5.69,2.31,10.93-2.39,16.1c-7.33,8.06-11.61,17.48-10.75,28.91c0.35,4.6-0.86,9.32-1.36,13.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M985.64,539.46c-1,2.17-1.94,4.37-3.01,6.49c-2.12,4.19-3.78,8.75-6.59,12.43c-7.19,9.41-14.92,18.4-22.37,27.61
				c-0.63,0.78-0.83,1.92-1.6,3.8c3.52,0.28,6.54,0.51,10.62,0.83c-1.93,2.23-3.38,4.15-5.07,5.82c-2.8,2.76-2.72,4.88,1.03,6.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M264.5,451.45c-0.17,11.67,0.07,23.36-0.67,34.99c-0.37,5.89-2.57,11.66-3.79,17.52c-0.41,1.95-0.37,3.99-0.54,5.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M245.99,508.96c-5.89,7.02-13,10.35-22.54,9.31c-2.95-0.32-6.24,3.16-9.48,4.66c-4.27,1.97-8.65,3.7-12.99,5.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M85.46,197.42c-14.9,2.46-30.27-0.31-45.01,4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M308.51,72.4c-5.28-1.45-10.47-3.71-15.21,1.82c-0.9,1.06-4.57,0.23-6.78-0.39c-7.91-2.24-15.24-0.24-22.52,2.57"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M348.01,742.99c-0.33-0.67-0.59-1.86-1.01-1.92c-6.71-0.93-13.62-3.11-19.96,0.97c-7.34,4.72-12.62,10.46-11.29,20.49
				c0.53,3.96-1.35,8.45-2.85,12.43c-2.12,5.64-4.94,11.02-7.36,16.56c-0.31,0.7-0.04,1.65-0.04,2.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M652.57,197.42c-5.17,0.5-10.33,1.22-15.51,1.45c-10.83,0.49-21.68,0.55-32.5,1.11c-6.52,0.34-13.24,0.48-19.41,2.28
				c-3.07,0.89-5.8,4.97-7.46,8.23c-4.24,8.29-7.8,16.93-11.64,25.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M53.46,495.46c3.88,6.04,4.49,14.42,12.5,17.5c-0.2,6.23,7.88,7.61,7.86,14.5c-0.02,5.15,6.36,7.04,10.03,3.39
				c3.01-2.99,6.01-3.2,9.61-2.39"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M687.08,393.94c8.67,3.97,17.91,2.98,27.01,2.94c5.84-0.03,11.67-0.61,17.5-0.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M549.55,259.43c-10.41-4.7-11.22-6.39-10.66-18.51c0.09-1.95-1.38-4.65-3-5.78c-1.26-0.89-3.87,0-5.85,0.31
				c-3.68,0.58-7.32,1.66-11.01,1.89c-5.31,0.33-10.66,0.08-16,0.09c-4.44,0.01-8.16,0.94-12.23,3.65
				c-4.12,2.74-10.16,2.46-15.26,3.9c-8.88,2.5-17.69,5.27-26.5,8.01c-0.29,0.09-0.35,0.95-0.52,1.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M906.12,726.48c-2.83-2.8-6.08-4.94-7.3-9.42c-0.67-2.45-4.11-5.28-6.75-5.85c-6.99-1.51-10.22-6.57-14.19-11.55
				c-1.57-1.97-5.77-1.85-8.77-2.69"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M682.58,752.99c-6.48,2.76-12.04-0.32-16.87-3.69c-3.94-2.74-6.28-3.26-8.64,1.34c-1.52-0.39-3.08-0.6-4.48-1.19
				c-3.7-1.55-7.28-3.27-8.14,3.02c-0.06,0.46-1.88,0.68-2.88,1.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M675.08,341.94c0.17-1.17,0.33-2.33,0.69-4.85c-2.87,0.69-5.56,0.78-7.63,1.96c-3.82,2.18-7.46,3.56-12.08,3.02
				c-6.41-0.76-7.62,0.57-8.48,6.88c-0.07,0.49-0.04,1,0,1.5c0.15,2.17,0.33,4.33,0.49,6.5c2.17-0.83,4.33-1.69,6.5-2.49
				c1.48-0.54,3-1.01,4.5-1.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M162.98,761.49c-4-3.83-7.95-7.73-12.03-11.47c-1.49-1.36-3.6-2.1-4.92-3.58c-6.86-7.62-15.64-10.4-25.52-11.7
				c-4.19-0.55-8.12-3.61-12.01-5.8c-3.46-1.95-6.69-4.29-10.03-6.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M785.6,54.4c4.87-0.06,9.86,0.44,13.98-3.02c0.18-0.15,0.08-0.64,0.25-2.39c-16.28-2.61-32.21,3.89-48.24,1.92"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M526.05,237.92c-1.44,11.87-12.43,11.94-20.07,15.88c-5.64,2.91-12.17,3.08-18.21,6.09c-6.13,3.07-14.4,2.02-21.74,2.44
				c-2.13,0.12-4.33-0.9-6.5-1.4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M843.11,173.42c-1.32,7.03,1.61,13.46,7.5,16.51c0.53,0.27,1.34,0.57,1.45,1.01c2.42,9.25,13.22,12.08,16.05,20.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M129.97,174.92c-4.47-2.07-8.53-6.96-13.74-1.89c-6.78-4.51-13.81-4.55-21.29-3.2c-7.93,1.42-15.97,2.24-23.99,3.04
				c-4.64,0.47-9.33,0.39-14,0.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M802.6,638.97c-4.01,1.66-7.6,2.2-11.62-0.84c-2.96-2.23-6.21-3.72-11.08-1.63c-3.64,1.56-9.49-0.36-13.73-2.19
				c-9.97-4.31-19.42-9.7-30.55-11.08c-2.54-0.32-4.6-3.89-7.05-5.72c-5.2-3.88-7.57-3.8-13.5-0.05c-0.55,0.35-1.32,0.36-1.99,0.52"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1067.65,231.42c0.32-4.32,0.47-8.13-5.44-9.31c-1.57-0.31-2.86-4.25-3.47-6.72c-1.49-6.06-1.54-6.09-6.66-2.57
				c-1.4,0.96-3.6,1.03-5.44,1.05c-10.17,0.09-20.34-0.01-30.51,0.09c-2,0.02-4,0.62-6,0.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M753.59,8.9c-0.83,2.33-1.67,4.67-2.5,7c2.67,0.33,5.34,0.94,8,0.91c1.84-0.02,3.65-1.04,5.51-1.35c0.95-0.16,2.7,0.01,2.88,0.48
				c2.54,6.64,7.8,3.74,12.1,3.33c2.11-0.2,4.09-1.73,6.26-2.71c5.93,4.02,12.15,3.21,15.26-2.16"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M90.46,191.92c-12.67,0.33-25.34,0.71-38.01,0.95c-1.66,0.03-3.33-0.62-5-0.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M586.56,197.42c-4.28-3.91-7.32-8.35-7.69-14.49c-0.09-1.57-2.56-4.32-3.8-4.25c-2.88,0.17-5.67,1.78-8.52,2.72
				c-1.8,0.59-3.67,1.47-5.49,1.44c-9.17-0.17-18.34-0.58-27.51-0.93c-2.84-0.11-5.67-0.32-8.5-0.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1147.67,177.42c-0.64,10.7-4.99,20.42-7.92,30.53c-1.17,4.02-1.42,8.31-2.08,12.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M267.5,264.93c-4.67,1.17-9.34,2.3-14,3.51c-3.68,0.95-7.29,2.33-11.02,2.89c-3.26,0.49-6.73,0.55-9.96-0.03
				c-2.79-0.51-5.36-2.2-8.03-3.36"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M978.14,552.96c-6.5-0.5-13.01-0.96-19.5-1.53c-1.35-0.12-2.79-0.4-3.98-1.01c-4.84-2.5-9.76-3.41-15.03-1.48
				c-3.13,1.14-6.29,0.4-9.5,0.12c-4.47-0.39-8.99-0.07-13.49-0.12c-0.84-0.01-1.74-0.15-2.49-0.5c-6.97-3.24-13.71-3.8-20.02,1.52"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M104.47,435.45c-6.83,5.33-13.41,11.06-20.58,15.88c-6.29,4.23-13.24,7.49-19.96,11.06c-0.58,0.31-1.63-0.27-2.47-0.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M773.6,582.97c2.83,4,5.37,8.26,8.57,11.94c4.5,5.18,9.76,9.26,12.39,16.4c1.8,4.87,8.27,7.84,11.95,12.25
				c4.58,5.49,8.43,11.59,12.6,17.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M158.98,428.45c-0.33,5.83,0.19,11.89-1.27,17.43c-1.18,4.51-4.49,8.57-7.29,12.53c-4.46,6.31-9.7,12.11-13.82,18.62
				c-3.32,5.26-5.47,11.25-8.13,16.92"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M490.54,207.42c-2.33,0.83-4.66,2.36-7,2.38c-11.67,0.09-23.34-0.22-35.01-0.38"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1054.65,362.44c0.67,1.17,1.33,2.33,2.28,3.99c-10.33,0.93-18.95,8.51-29.78,6.48c-3.21-0.6-6.52-1.24-9.46-2.57
				c-3.48-1.56-6.52-2.19-9.55,0.6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M896.62,631.97c1.56,6.07,2.61,12.03,2.37,18.52c-0.18,5.1,2.9,10.34,4.65,15.48c1.34,3.94,1.92,7.43-0.28,11.64
				c-1.53,2.92,0.57,6.14,3.67,8.49c3.54,2.68,6.36,6.28,9.61,9.37c2.23,2.11,4.71,3.96,6.96,6.05c1.29,1.2,2.56,2.52,3.48,4
				c2.45,3.93,5,7.84,6.97,12.01c2.88,6.12,7.73,6.79,13.58,6.02c4.47-0.59,9-0.72,13.5-1.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M103.97,158.91c-4.67-0.5-9.33-1.36-14-1.42c-8.33-0.11-16.67,0.22-25,0.46c-1.18,0.03-3.13,0.33-3.37,1.01
				c-1.74,4.75-3.13,9.62-4.63,14.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M522.55,157.41c-12.38,1-24.79,1.93-36.46,6.62c-1.67,0.67-2.71,2.89-4.05,4.39"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M480.04,557.46c-0.67,6.67-0.9,13.42-2.13,19.98c-1.13,6-3.1,11.77-2,18.05c0.21,1.18-1.21,2.64-1.88,3.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M282,348.44c-2.44,2.91-0.97,4.75,2.01,4.91c10.03,0.53,19.66,5.67,30.02,2.64c0.72-0.21,1.75,0.1,2.47,0.48
				c4.89,2.64,9.28,1.31,13.52-1.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M730.59,67.4c-0.34,1.67-0.67,3.33-1.07,5.3c-3.45-0.27-6.94-0.76-10.43-0.74c-3.31,0.02-6.86-0.11-8.6,3.89
				c-0.28,0.65-1.89,0.73-2.9,1.04c-2.62,0.81-5.24,1.6-8.59,2.62c4.64,5.7,10.01,6.06,15.08,5.72c4.37-0.3,7.39-0.35,10.25,4.02
				c2.48,3.79,7.45,1.66,11.26,1.07c6.07-0.94,11.93-3.93,18.27-1.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M818.6,229.92c-0.25-3.65-2.69-3.68-5.5-3.54c-7.22,0.38-14.43,2.15-21.59-1.29c-2.16-1.04-5.34-0.94-7.04,2.76
				c-2.33,5.07-5.54,9.73-8.37,14.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M767.59,500.46c-3.41-10.72-6.22-21.7-13.57-31.67c-4.24,2.68-8.59,5.41-12.93,8.17c-0.7,0.45-1.28,1.11-2.01,1.47
				c-6.39,3.2-6.68,5.11-1.13,10.36c-2.88,1.43-5.62,2.79-8.37,4.16"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M683.08,219.42c-8.84,0.17-17.67,0.25-26.5,0.53c-5.01,0.16-10,0.64-15,0.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1193.18,391.44c-5.28,1.23-10.07,2.75-16.11,1.5c-5.68-1.18-12.31,1.55-18.34,3.2c-1.49,0.41-2.29,3.33-3.53,5.3
				c-2.19,0-4.96-0.47-7.5,0.11c-3.94,0.9-4.16,2.95-1.03,5.89"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M278.75,582.97c-1.92,2.17-3.42,5.4-5.82,6.32c-12.39,4.75-24.58,10.55-38.45,9.81c-1.62-0.09-3.33,1.54-4.99,2.38"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M206.49,415.45c0.83,5.17,1.54,10.36,2.53,15.5c1.55,8.02,3.28,16.01,5.02,24c0.13,0.58,0.95,1.01,1.45,1.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M299,441.95c-14.5-0.67-29.01-1.3-43.51-2.02c-4.51-0.22-9.1-0.21-13.47-1.16c-2.38-0.52-4.42-2.76-6.51-4.36
				c-0.82-0.63-1.19-2.03-2.06-2.4c-3.56-1.51-12.33,2.75-13.43,6.44c-0.27,0.92,0.1,2.01-0.07,2.98c-0.6,3.51-0.44,7.53-2.16,10.39
				c-3.18,5.26-3.77,10.8-2.12,16.05c2.32,7.37-1.65,13.18-3.33,19.53c-0.89,3.37-3.2,6.37-4.86,9.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M906.62,85.4c-2.78-6.76-9.26-7.22-15.03-8.35c-3.34-0.65-7.04-0.18-10.46,0.43c-6.35,1.14-11.43,8.65-10.52,14.92
				c0.62,4.25,8.37,7.75,11.39,4.88c1.18-1.12,1.88-3.76,1.45-5.34c-0.84-3.08-3.29-3.23-7.45-0.85c1.61,0.81,2.61,1.31,3.61,1.81"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M735.59,623.97c1.36,7.25,6.36,10.88,13.51,10.65c2.47-0.08,4.97,1.61,7.5,2.34c5.48,1.56,11,3.02,16.5,4.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M862.61,580.97c1.33,0.5,2.64,1.34,4.01,1.44c5.32,0.41,9.4,2.67,12.58,7c1,1.37,2.81,2.35,4.44,3c4.94,1.98,9.97,3.76,14.99,5.52
				c1.09,0.38,2.32,0.36,3.49,0.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M633.07,509.46c4,0.83,7.97,1.87,12.01,2.43c2.64,0.37,4.17,1.29,4.51,4.07c1.92,15.53,3.04,31.04-0.52,46.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M825.61,109.91c4.03,1.09,10.11-0.96,9.88,5.99c4.91-0.67,10.68-3.28,14.42-1.54c6.12,2.85,12.02,2.84,18.21,3.09
				c0.86,0.03,1.71,0.52,2.76,0.87c-2.33,5.48-6.93,4.72-11.26,4.6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M594.56,246.92c-11,0.33-22,0.67-33.01,1c-3.6,0.11-3.58,2.65-4.79,5.37c-2.93,6.57-7.02,12.73-11.33,18.54
				c-2.84,3.83-6.92,6.72-10.35,10.12c-1,1-1.69,2.31-2.53,3.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M564.06,109.91c1.85,0.62,3.7,1.24,5.55,1.86c-3.75,2.37-6.64,8.18-12.16,2.75c-0.51-0.5-2.63,0.39-3.88,0.92
				c-6.95,2.92-13.74,4.26-20.02-1.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M5.95,570.46c3.33-0.5,6.69-1.49,9.99-1.33c2.21,0.1,4.63,1.47,6.44,2.92c2.17,1.74,3.78,4.17,5.94,6.66
				c1.83-1.72,3.21-3.03,4.62-4.36c8.38,2.93,10.76,11.4,15.93,17.2c6.38,7.16,11.98,15.02,18.21,22.32
				c1.02,1.19,3.54,1.43,5.39,1.53c3.34,0.18,6.53-0.12,9.07,3.02c3.04,3.74,6.64,7.02,9.89,10.6c0.49,0.54,0.38,1.63,0.55,2.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M399.52,197.42c-5.33-6.17-11.59-11.8-15.61-18.73c-1.95-3.37,0.27-9.15,0.71-15.44c-2.46-3.55-8.18-1.63-12.64-2.05
				c-7.57-0.7-15.32,0.22-22.97,0.84c-1.74,0.14-3.66,1.57-4.96,2.92c-6.78,7.06-13.38,14.29-20.06,21.45c-0.96,1.03-2,2-3,3.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M988.14,480.95c-5.5,1.67-11.09,3.1-16.47,5.08c-3.56,1.32-6.83,3.44-10.23,5.2c0.2,0.9,0.4,1.8,0.59,2.7
				c2.87,0.17,5.75,0.22,8.6,0.58c1.21,0.15,2.34,0.94,3.51,1.44c-2.28,2.84-0.37,4.72,2.01,4.8c2.49,0.08,5.99,0.52,6.99-3.3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M291.5,454.95c1.83,6,3.15,12.23,5.63,17.95c3.46,7.97,1.21,14.97-2.13,22.06"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M828.61,453.45c-10.06,10.71-21.86,19.04-35.01,25.49c-1.61,0.79-3.33,1.35-4.99,2.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M357.52,327.43c9,0.5,18.03,0.76,27,1.59c5.55,0.52,11.01,1.91,16.51,2.91"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M470.54,73.9c-3.33,0.5-6.74,0.74-9.96,1.63c-1.23,0.34-2.04,2.2-3.04,3.37c1.17,0.83,2.27,2.26,3.51,2.4
				c7.48,0.86,14.99,1.43,22.49,2.1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M684.08,588.97c-2.17,2.67-4.33,5.33-7.22,8.89c5.49,5.94,11.08,12.05,16.74,18.1c2.09,2.24,4.19,4.5,6.54,6.45
				c1.4,1.16,3.28,1.73,4.95,2.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M178.98,457.95c1.33,1.83,2.74,3.62,3.99,5.51c2.39,3.63,4.7,7.31,7,11c0.59,0.94,0.87,2.1,1.54,2.98
				c4.79,6.37,9.65,12.69,14.48,19.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M794.6,453.45c-0.5-0.33-1.33-1.05-1.45-0.94c-5.96,5.04-13.26,5.59-20.54,6.51c-6.95,0.88-11.95,5.76-17.51,9.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1133.67,579.47c-1,1-1.86,2.24-3.04,2.94c-1.34,0.78-3.85,1.92-4.33,1.43c-4.72-4.86-9.51-0.45-14.14-0.26
				c-13.67,0.56-25.51,15.58-22.01,27.39"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M343.01,292.93c-7.42-2.09-14.4-0.41-21.55,1.87c-6.14,1.96-12.58,3.16-18.97,4.04c-9.62,1.33-19.33,2.09-28.99,3.09"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M404.52,201.92c-0.02-4.9,0.26-9.63,3.89-13.6c0.75-0.82,0.11-2.9,0.11-4.9c5.66,0,11.34,0.11,17-0.02
				c10.34-0.25,20.67-0.64,31.01-0.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1061.15,429.45c-7.69-1.01-15.26-0.95-21.41,4.6c-1.45,1.31-2.94,4.47-2.31,5.76c0.9,1.86,3.67,3.84,5.73,3.93
				c4.62,0.2,7.5,1.35,9.14,6.16c1.56,4.56,4.3,4.7,9.85,1.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1089.66,480.45c3.08,1.61,3.49,4.67,3.43,7.5c-0.34,14.34-0.79,28.68-1.52,43c-0.19,3.7-1.48,7.36-2.47,10.99
				c-0.26,0.94-1.27,1.68-1.94,2.52c-0.67-1-1.85-1.97-1.91-3.01c-0.22-4.16,0.53-8.45-0.24-12.47c-1.05-5.45-3.18-10.7-4.85-16.03"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M163.98,499.96c-2.5,2.67-4.8,5.56-7.54,7.96c-7.42,6.49-16.1,9.73-25.94,11.24c-11.8,1.82-23.37,5.13-35.04,7.8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M429.03,258.93c-2.5,0.17-5,0.29-7.5,0.51c-5.5,0.47-10.99,1.12-16.51,1.46c-5.49,0.33-11.05,0.85-16.48,0.3
				c-2.15-0.22-4.19-2.94-5.97-4.82c-1.14-1.21-1.73-2.94-2.56-4.45c-2.21-4.03-4.94-3.98-8.55-1.56c-4.15,2.78-8.63,5.06-12.96,7.57
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1183.68,171.92c-2.79-5.86-4.89-11.56-3.85-18.55c0.54-3.64-4.49-3.63-7.65-3.4c-4.68,0.33-9.33,1.14-14.01,1.41
				c-5.56,0.32-11.26-0.81-16.5,2.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M893.62,589.47c2.83,2.83,5.55,5.8,8.54,8.46c2.66,2.36,5.61,4.4,8.47,6.54c5.03,3.76,10.13,7.24,14.16,12.38
				c4.47,5.71,10.18,10.46,15.34,15.63"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M463.54,170.92c-10,0.67-20,1.39-30.01,1.96c-2.32,0.13-4.74,0.02-6.98-0.52c-6.34-1.51-7.81-0.54-8.54,6.06
				c-0.15,1.35-0.64,2.67-0.97,4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M277.5,447.95c-13.4,0.98-26.16-2.3-39.05-5.33c-5.76-1.35-11.96-0.84-17.96-1.17"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1034.15,188.92c-8.5,0.97-16.91,1.84-25.54,0.21c-4.25-0.81-9.42-1.65-12.96,2.79"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M647.57,342.94c-4-2.5-7.73-5.74-12.06-7.35c-9.58-3.57-13.97,1.56-15.33,10.37c-0.44,2.88,0.97,6.01,0.79,8.98
				c-0.36,5.85-1.32,11.67-1.85,17.51c-0.1,1.13,0.6,2.33,0.94,3.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M941.63,175.92c-11.77-2.93-22.87,3.94-34.5,2.44c-7.33-0.94-14.4-0.13-21.52,1.52c-6.76,1.57-6.79,1.42-7.95-4.75
				c-3.73-0.75-7.39-2.02-11.04-2.02c-2.53,0-6.41-0.69-6.7,4.3c-0.1,1.74-2.47,3.35-3.8,5.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M566.56,179.92c-3,0-6.01,0.13-9-0.02c-8.67-0.43-17.38-0.61-25.99-1.6c-5.91-0.68-9.39,3.33-9.88,9.64
				c-0.73,9.53-5.58,15.93-13.08,21.08c-1.57,1.08-2.39,3.25-3.56,4.92"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M394.02,571.96c-2.33-1.83-4.78-3.55-6.98-5.52c-5.87-5.26-12.77-9.22-15.36-17.88c-1.51-5.05-7.55-6.67-13.16-6.63
				c-3.07,0.02-4.3-1.44-3-4.47c1.17-2.75,0.69-4.65-2.48-5.56c-1.1-0.32-2.02-1.28-3.02-1.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M556.55,85.4c6.28-0.63,8.69,1.05,11.5,8c-7.27,5.61-10.52,5.5-15.5-0.5c-16.42,3.83-21.88,3-23.13-4.25
				c-1.98,0.42-3.91,0.98-5.87,1.22c-3.61,0.45-7,2-10.74-1.19c-1.57-1.34-5.76,0.46-8.76,0.7c-3.68,0.29-7.53,3.99-11-0.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M810.6,300.43c-4.83-1.67-9.99-2.74-14.43-5.13c-7.46-4.02-14.83-7.72-23.58-7.37"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M545.55,68.9c0.17-2,0.33-4,0.5-6c-2.33-0.17-4.67-0.5-7-0.44c-1.67,0.04-3.34,0.61-5,0.94c-4.66-6.39-9.58-5.77-15.75,1.69
				c-3.67-1.43-7.12-3.55-10.78-4.01c-5.31-0.67-3.99,4.37-5.29,8.11c-2.96-1.63-6-2.96-8.66-4.83c-6.05-4.25-15.49-3.91-21.04,1.05
				c-3.24,2.89-3.41,6.26-0.51,9.51c0.71,0.79,1.34,1.66,2.01,2.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M429.53,73.9c4.33-1.33,8.91-2.19,12.91-4.18c2.33-1.16,4.49-3.88,5.41-6.38c1.23-3.31-2.97-2.68-4.16-4.07
				c-4.23-4.98-8.8-3.66-13.67-1.86"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M382.52,489.7c-4-5.92-8.11-11.77-11.97-17.77c-3.45-5.37-6.64-10.91-9.95-16.37c-0.56,0.2-1.12,0.39-1.68,0.59
				c0.87,4.1,1.77,8.2,2.58,12.31c0.42,2.15,1.25,4.42,0.91,6.48c-0.91,5.47,0.69,9.91,3.46,14.61c3.2,5.45,1.17,11.22-0.69,16.96
				c-1.31,4.07-1.16,8.62-1.66,12.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M712.08,228.42c3.32-4.62,8.62-3.77,13-3.79c8.33-0.05,16.66,1.34,25.01,1.69c3.31,0.14,6.66-0.93,10-1.39
				c7.83-1.07,10.77,1.45,10.99,9.49c0.11,3.79,0.4,6.33,4.8,8.86c7.58,4.36,9.96,13.32,13.21,21.14"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M853.11,697.98c3,2.33,6.02,4.65,8.99,7.01c0.73,0.58,1.52,1.22,1.98,2c5.01,8.43,8.16,18.01,15.49,25.03
				c0.5,0.48,0.38,1.62,0.54,2.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M960.13,681.48c-1.54,4.31-5.72,2.28-8.56,3.35c-9.18,3.46-18.75,4.31-28.44,2.12c-0.91-0.2-1.69-0.93-3.16-1.78
				c2.77-2.42,5.34-4.66,8.2-7.15c-3.84-1.29-7.2-2.41-10.54-3.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1062.15,155.91c-1.83,0.5-3.64,1.24-5.51,1.46c-7.99,0.93-15.99,1.88-24.01,2.44c-2.35,0.16-4.79-0.96-7.19-1.98
				c-4.34,0.66-3.98,4.29-3.75,7.59c0.23,3.35,0.94,6.67,1.44,10.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M479.54,475.45c-1.67,5.5-3.08,11.09-5.07,16.48c-2.01,5.45-4.73,10.64-6.83,16.07c-0.49,1.25,0.24,2.96,0.4,4.46
				c1.5-0.5,3.36-0.6,4.44-1.57c3.83-3.48,8.03-6.83,10.93-11.03c4.7-6.8,8.67-14.13,12.55-21.45c1.64-3.1,3.59-2.52,6.1-2
				c2.62,0.54,5.41,1.3,7.98,0.93c6.84-0.98,13.12,0.06,19.02,3.41c0,0.54,0.11,0.93-0.02,1.2c-0.97,2.02-1.98,4.01-2.98,6.01
				c2.33,0.33,4.66,0.75,7.01,0.96c0.98,0.09,2.21-0.74,2.96-0.38c4.24,2.01,8.37,4.26,12.54,6.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M467.04,393.44c-0.83,5.17-0.67,10.81-2.82,15.35c-1.64,3.45-2.16,6.23-0.57,9.09c1.81,3.26,1.55,5.98-0.03,9.1
				c-0.64,1.26-0.77,3.03-0.51,4.45c0.61,3.37,1.6,6.68,2.43,10.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M739.59,534.46c-1.13,2.54-0.34,4.65,0.9,7.05c1.88,3.64,2.94,7.69,4.67,11.42c0.72,1.54,2.02,3.46,3.46,3.91
				c8.53,2.65,11.65,10.43,16.47,16.62c2.98,3.83,6.09,6.97,10.8,9.39c5.52,2.84,9.64,8.52,14.06,13.24
				c0.51,0.55-0.86,2.87-1.36,4.37"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M482.54,285.93c-0.33-0.33-0.64-0.92-1-0.96c-10-1.05-19.98-2.46-30.01-2.89c-6.63-0.28-13.33,0.85-20,1.35"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M290.5,497.46c-1.83,6.17-5.34,12.42-5.01,18.48c0.3,5.66,0.41,11.35,0.46,17.03c0.02,2.33-0.61,4.67-0.94,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M941.63,172.42c1,2.17,1.8,4.45,3.03,6.48c6.6,10.88,13.3,21.69,19.97,32.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M998.14,174.92c0.88-4.99-2.14-10.59,2.4-15.1c0.42-0.41-0.22-2.55-0.96-3.34c-3.04-3.29-14.56-0.89-15.29,3.47
				c-1.09,6.52-5.55,10.63-9.1,15.52c-4.72,6.51-9.06,13.3-13.56,19.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M987.64,716.98c-1.83,1.17-3.53,2.84-5.53,3.4c-4.24,1.18-8.68,1.62-12.96,2.64c-5.92,1.42-9.19-2.88-12.36-6.18
				c-3.24-3.37-6.62-5.34-11.11-6.52c-4.04-1.06-7.64-3.68-11.57-5.27c-2.18-0.88-4.64-1.07-6.97-1.57"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M166.48,165.91c-11.5,0.17-23.01,0.22-34.5,0.67c-1.22,0.05-2.34,2.49-3.51,3.83"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1089.16,655.48c-0.17,1.33-0.98,3.26-0.39,3.9c2.4,2.58,5.34,4.66,7.87,7.13c7.88,7.69,17.04,13.54,26.48,19.06
				c1.33,0.78,2.17,2.51,3.02,3.94c4.32,7.2,8.93,13.31,19.07,11.19c3.57-0.75,7.61,1.06,11.46,1.25
				c12.83,0.63,25.67,1.05,38.51,1.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M264.5,157.91c-12,0.17-24,0.33-36.01,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M347.51,492.45c-1.17,10.33-2.4,20.66-3.43,31.01c-0.16,1.62,0.6,3.33,0.93,4.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M529.55,365.94c-6.87,1.87-13.74,3.75-20.62,5.62c-0.29,0.46-0.59,0.91-0.88,1.37c1.66,1.17,3.61,2.08,4.94,3.56
				c3.42,3.81,2.83,6.67-2.48,7.76c-6.34,1.3-12.96,1.34-19.47,1.63c-3.51,0.15-7.04-0.32-11.85-0.58c1.9,3.3,2.97,5.17,4.11,7.17
				c-3.6,2.24-6.46,2.28-10.44,0.31c-2.61-1.3-6.87,0.49-10.32,1.21c-3.99,0.82-7.92,2.09-11.51-1.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M419.03,235.92c2.67,4.2,5.48,1.24,7.94-0.11c1.33-0.73,2.04-2.57,3.06-3.89c3.42-4.43,7.21-6.69,13.52-6.26
				c10.94,0.75,21.99-0.08,32.99-0.24"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1140.67,152.91c-3.83,0-7.67,0.05-11.5-0.01c-7.67-0.13-15.34-0.39-23-0.46c-4.52-0.04-8.8-4.67-13.87,0.62
				c-1.67,1.74-7.65-0.53-11.63-1.17c-10.87-1.75-10.95-1.71-10.01,9.02c0.2,2.33,0.35,4.67,0.5,7c0.36,5.44,2.11,7.28,7.5,6.92
				c3.03-0.2,6.01-1.25,9.01-1.92"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M420.03,171.92c-0.55-1.82-1.11-3.64-1.82-6c-7.67,0-15.69-0.2-23.68,0.16c-1.72,0.08-3.74,1.87-4.93,3.4
				c-2.57,3.32-5.27,4.69-9.08,2.19"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M55.96,183.42c5.67,0,11.34-0.06,17,0.02c6.17,0.09,12.34,0.32,18.5,0.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M758.59,430.95c-5,1.67-10.09,3.12-14.97,5.07c-3.59,1.43-7.51,2.89-5.76,8.5c0.31,0.99-2.15,3.54-3.82,4.31
				c-5.36,2.47-10.88,4.65-16.48,6.52c-2.3,0.77-4.97,0.43-7.47,0.6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M686.08,368.94c-2.67,3.5-5.19,7.12-8.04,10.47c-4.2,4.95-8.65,9.68-12.96,14.54c-2.2,2.47-4.34,5-6.51,7.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M667.58,455.95c-5.17,1.67-10.33,3.34-15.5,5c-1.16,0.37-2.33,0.96-3.5,0.96c-6.77,0.02-9.75,4.06-11,10.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M718.59,192.42c-11.67-0.5-23.34-1-35.01-1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M133.22,119.91c-5.08,0.67-10.2,1.16-15.24,2.07c-2.99,0.54-5.85,1.73-8.78,2.63c0,0.61,0,1.23,0,1.84
				c1.92,0.49,3.84,1.4,5.77,1.4c11.17-0.02,22.34-0.26,33.51-0.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M681.58,211.42c-8,0.83-15.99,1.76-24.01,2.46c-4.32,0.38-8.67,0.37-13,0.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M78.96,379.94c-10.67,4.17-21.27,8.53-32.05,12.37c-2.86,1.02-6.3,0.38-9.45,0.65c-5.01,0.43-10,0.98-15,1.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M785.1,342.44c3.67,5.34,9.51,5.27,15.01,5.98c3.49,0.45,7.01,0.58,10.5,1.04c8.08,1.08,11.25,4.1,13.03,11.97
				c0.5,2.2,1.31,4.34,1.97,6.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M175.48,167.41c0.85-2.44,1.7-4.87,2.84-8.16c-15.89,1.02-31.16-2.01-45.85,3.66"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M605.06,69.4c-2-1.5-3.81-3.82-6.04-4.33c-4.51-1.04-8.28,0.36-8.67,4.74c-5.51,0.38-10.66,0.73-15.8,1.11
				c-0.51,0.04-1.12,0.59-1.48,0.44c-8.51-3.5-17.37-0.64-26.02-1.47c-0.84-0.08-1.67-0.32-2.5-0.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M302.5,439.95c0.67,1.17,1.89,2.33,1.91,3.5c0.15,10.1,2.85,19.35,8.03,28.04c3.01,5.05,5.03,10.37,2.25,16.55
				c-0.73,1.62,0.82,4.25,1.32,6.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M977.64,448.45c-1.5-0.33-3.05-0.53-4.49-1.03c-3.36-1.15-6.07-2.58-9.77,0.74c-2.28,2.05-7.09,1.21-10.74,1.81
				c-3.12,0.52-6.84,0.19-6.28,5.51c0.1,0.96-3.29,2.51-5.25,3.43c-3.43,1.6-6.98,2.92-10.81,4.5c4.62,5.81,7.98,6.7,13.35,4.58
				c1.76-0.7,3.66-1.03,5.49-1.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M829.11,420.95c-8.49,3.38-15.75,8.34-21.06,15.96c-0.91,1.3-2.76,1.9-3.91,3.07c-2.06,2.12-5.99,2.86-4.81,7.54
				c0.31,1.21-2.73,3.27-4.23,4.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M222.49,165.41c-11.82-0.75-22.88,2.88-33.97,6.11c-1.08,0.32-2.06,2.11-2.47,3.41c-1.34,4.28-2.4,8.65-3.57,12.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M421.53,55.4c-3.35-7.62-10.19-6.79-16.5-6.39c-5.18,0.32-9.38,4.72-10.37,10.42c-0.26,1.51,1.19,3.31,1.86,4.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M493.04,110.91c1.8-3.04,3.26-6.35,8.06-4.66c1.13,0.4,3.08-0.98,4.42-1.89c2.43-1.64,4.62-2.45,7.08-0.01
				c0.67,0.67,2.51,1.3,2.87,0.96c4.24-4.07,4.79,0.78,7.86,3.62c-12.22,2.5-23.23,5.22-34.78,1.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M504.54,518.96c-0.67,0.67-1.97,1.4-1.9,1.99c0.68,6.12-3.68,5.06-7.1,4.96c-3.34-0.1-6.67-0.81-10-0.88
				c-2.16-0.04-4.71-0.02-6.43,1.04c-7.56,4.63-14.58,3-21.57-1.11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M486.04,287.68c-0.5-0.25-1.32-0.83-1.45-0.7c-7.17,7.01-16.95,5.61-25.54,8c-3.09,0.86-6.01,2.28-9.02,3.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M851.61,820.5c9.5,0.17,19,0.36,28.51,0.47c1.83,0.02,3.67-0.31,5.5-0.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M344.51,491.95c-0.83,2.17-1.98,4.27-2.45,6.51c-2.16,10.43-10.11,16.09-17.39,22.68c-5.01,4.53-9.57,10.36-7.17,18.32"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M972.63,626.47c-1,1-2.43,1.82-2.93,3.03c-4.35,10.37-11.08,18.57-21.04,24.04c-0.93,0.51-1.37,1.94-2.04,2.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M937.13,431.45c1.04,5.55,3.11,6.24,11.64,3.08c0.28,0.5,0.75,1.51,1.38,2.41c2.43,3.55,4.78,3.12,8.61,1.25
				c4.92-2.41,8.6-5.39,10.88-10.24"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M891.62,669.98c-6.67-2.83-13.32-5.72-20.02-8.47c-2.09-0.86-4.3-1.77-6.5-1.89c-1.46-0.08-2.99,1.19-4.49,1.86"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M659.07,532.46c-1.91,5.07-3.85,10.05-1.12,15.56c0.53,1.07-0.62,2.93-0.86,4.44c-0.71,4.33-1.35,8.67-2.01,13"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M642.57,190.92c-11,0.83-22,1.67-33.01,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M305.01,441.95c8.45-2.55,16.78-0.71,24.98,1.13c3.45,0.78,3.77,6.37,1.02,10.36c-0.26,0.38-0.69,0.65-0.99,1.01
				c-4.67,5.61-4.67,5.62-0.28,11.29c-3.8,3.54-6.18,7.31-4.5,13.28c1,3.58-2.21,8.49-0.94,11.82c3.66,9.57-0.71,15.5-7.25,21.15
				c-1.05,0.91-1.7,2.3-2.53,3.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M441.03,684.48c-3.5,2-7.19,3.74-10.46,6.06c-4.84,3.43-9.22,7.51-14.14,10.8c-1.57,1.05-4.24,0.47-6.41,0.64"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1031.65,197.42c-10-0.17-20-0.35-30.01-0.48c-0.83-0.01-1.67,0.31-2.5,0.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M730.09,508.46c-0.67-1.83-1.07-3.83-2.09-5.44c-0.71-1.12-2.24-1.72-3.41-2.56c-0.5,1.67-1.95,3.89-1.35,4.91
				c4.16,7.13,2.73,16.39,9.15,22.79c1.5,1.5,2.39,2.97,4.6,4.01c3.22,1.52,6.28,4.06,8.49,6.88c4.21,5.37,7.79,11.24,11.6,16.92
				c0.61,0.91,1.09,1.92,1.4,2.45c7.7-3.17,8.91-2.63,11.12,4.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M917.62,303.93c13.84,0.5,27.68,0.91,41.5,1.57c3.07,0.15,6.1,1.2,9.64,1.93c-1.37,1.91-2.29,3.19-4.06,5.66
				c2.93,0.53,5.17,1.21,7.42,1.28c5.5,0.17,11-0.04,16.5,0.12c1.42,0.04,2.81,0.9,4.22,1.38c0.1,0.51,0.2,1.01,0.3,1.52
				c-2.17,1.01-4.41,1.91-6.49,3.07c-2.18,1.21-4.21,2.67-6.31,4.02c-0.02,0.54-0.04,1.08-0.06,1.62c2.62,0.78,5.19,1.8,7.86,2.25
				c1.76,0.29,3.65-0.39,5.49-0.39c5.45,0,11.06-0.87,16,2.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M162.48,247.42c-8.75,10.86-14.64,23.85-24.97,33.54c-0.46,0.43-0.37,1.46-0.54,2.21"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M287,402.44c-3.33-1.5-6.54-3.71-10.03-4.34c-5.39-0.96-10.97-1.01-16.47-1.08c-2.16-0.03-4.63,0.42-6.44,1.51
				c-5.63,3.38-11.3,2.04-17.06,0.91"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M296.5,369.94c-6.33,0.33-12.68,0.51-19,1.06c-4.36,0.38-8.67,1.28-13.01,1.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M697.58,802.49c-10.67,2.33-21.34,4.67-32.01,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M932.63,641.97c5.89,2.24,6.22,4.39,0.96,8.45c-2.77,2.13-6.18,3.45-8.88,5.65c-1.77,1.44-4.52,2.17-4.79,5.39
				c-0.07,0.79-2.79,1.35-4.29,2.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M680.58,57.9c-6.91-2.88-13.69-0.06-20.5,0.54c-3.99,0.35-8.01,1.52-11.51-1.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M486.54,351.94c-9.5-0.33-19.01-0.57-28.5-1.1c-1.38-0.08-3.04-1.28-3.94-2.45c-2.52-3.29-2-6.55,0.93-9.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M374.02,391.94c2.67,6.5,5.68,12.89,7.89,19.54c1.59,4.8,1.42,10.25,3.33,14.87c1.63,3.93,5.12,7.09,7.79,10.59"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M581.56,91.41c-2.69,6.15-0.32,9.43,8,9.35c6.18-0.06,12.34-1.51,18.51-2.35"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1042.65,415.45c0.4,4.09-1.48,6.51-5.51,6.96c-2.88,0.32-5.25,0.74-4.55,4.56c0.08,0.42-0.5,1.22-0.96,1.46
				c-4.97,2.56-10.02,4.97-14.96,7.58c-1.54,0.81-2.82,2.12-4.07,3.09c2.04,5.72,6.23,6.24,11.05,5.37"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M638.07,338.44c-2.67,9-5.42,17.98-7.92,27.03c-0.42,1.51,0.25,3.31,0.42,4.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M691.08,374.44c-1.17,3.17-1.88,6.61-3.6,9.44c-2.97,4.89-7.13,9.1-9.77,14.13c-4.07,7.77-10.32,5.82-16.64,4.95
				c-12.66-1.73-25.32-3.5-38.02-4.87c-2.05-0.22-4.35,1.45-6.48,2.37c-2.53,1.09-4.91,2.98-7.53,3.36
				c-8.56,1.23-15.83,4.42-20.86,12.58c4.13-0.65,8.25-1.31,12.38-1.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M402.02,603.97c-1.5-0.5-3.03-1.5-4.5-1.42c-8.84,0.5-17.67,1.25-26.51,1.92"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M323.51,494.96c-1.67,0.17-3.34,0.29-5,0.51c-2.17,0.29-4.84-0.04-6.41,1.11c-5.49,4.04-11.03,1.85-16.59,0.84
				c-2.38-0.43-4.66-1.33-7.01-1.95c-2.96-0.78-4.62,0.62-6.61,2.89c-5.38,6.14-12.68,9.3-20.38,11.64
				c-1.91,0.58-3.68,1.63-5.51,2.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M557.55,252.93c10.96,2.19,21.93,4.39,33.4,6.68c-2.69,5.81-6.46,11.09-12.36,14.88c-2.87,1.84-5.37,4.28-8.04,6.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M540.05,442.95c-9.34,3-18.68,5.97-27.99,9.04c-1.1,0.36-2.01,1.29-3.01,1.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M247.99,508.46c4.46-2.39,5.55,2.32,6.81,4.13c7.33,10.54,16.8,18.64,27.18,25.89c7.55,5.27,14.82,10.96,22.58,15.89
				c6.02,3.83,12.61,6.77,18.94,10.1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M473.54,600.47c-7.59-2.96-14.13-7.27-19.06-13.96c-2.87-3.9-7.01-5.92-11.95-6.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M393.52,367.94c7.33,12.83,14.55,25.74,22.07,38.47c2.79,4.72,6.27,9.04,9.44,13.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1113.16,255.93c3.7,4,8.03,1.93,11.97,0.89c3.8-1,7.44-2.74,11-4.46c1.54-0.74,2.7-2.26,4.04-3.43
				c-1.33-1.17-2.58-2.45-4.03-3.46c-1.02-0.71-2.31-1.04-3.47-1.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M479.04,394.94c-0.98,11.88,2.75,23.29,3.53,35c0.13,2.01,0.64,4,0.97,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M507.54,478.45c-0.17,1.33,0.09,2.93-0.57,3.96c-5.55,8.74-11.27,17.38-16.94,26.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M909.62,386.94c-4.67-1-9.33-2.02-14.01-2.98c-0.64-0.13-1.83-0.22-1.92,0.01c-2.37,6.07-7.22,2.63-11.07,3
				c-2.01,0.19-4,0.64-6,0.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M700.58,170.42c-2.17,1.17-4.24,3.06-6.52,3.36c-5.78,0.76-11.65,0.97-17.49,1.1c-7.43,0.17-10.12,3.05-9.52,10.54
				c0.27,3.33,0.35,6.67,0.52,10"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1111.66,103.91c-8-0.5-16.01-0.93-24-1.55c-2.03-0.16-4-0.95-6.01-1.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1109.66,80.4c-9.34,0.58-18.68,1.16-28.03,1.74c-0.21,0.46-0.41,0.92-0.62,1.38c1.88,1.13,3.76,2.25,5.64,3.38"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M869.61,516.46c-4.17,6-8.08,12.21-12.6,17.93c-2.82,3.57-6.57,6.41-9.91,9.58"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M81.46,759.49c10,6.33,20,12.67,30.01,19"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1148.17,310.93c2.99,2.71,1.5,5.85-0.62,7.33c-3.93,2.75-8.44,4.77-12.92,6.59c-3.84,1.56-8.19,1.96-11.88,3.77
				c-1.87,0.92-3.26,3.65-3.95,5.86c-0.66,2.13-0.14,4.62-0.14,6.96c0,10.76,0.12,10.84,11,14.52c1.55,0.52,3.01,1.31,4.51,1.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1061.15,545.46c0.17,1,0.74,2.72,0.44,2.89c-3.57,2-7.13,4.88-10.97,5.38c-7.68,1-15.69,2.13-22.97-2.27"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M722.59,111.91c7.33,0.5,14.67,0.95,22,1.58c0.72,0.06,1.77,1.16,1.94,1.93c1,4.69,4.42,4.47,8.07,4.54
				c7.01,0.13,14,0.61,21.01,0.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M820.61,641.47c-9-0.11-16.66-4.17-24.6-7.79c-3.53-1.61-8.44-4.52-11.91,1.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M694.58,266.43c-4.84,4.57-1.29,9.97-1.53,15c-0.18,3.67-0.5,7.36-1.01,11c-0.47,3.36-1.29,6.67-1.96,10.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M163.48,177.92c-0.83-1.33-1.73-3.85-2.49-3.82c-7.02,0.35-14.02,1.07-21,1.89c-1.23,0.14-2.34,1.26-3.51,1.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M997.64,467.95c0.17,8,0.34,16,0.5,24c0.06,3.09,2.14,6.52-1.5,9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1022.64,582.47c-0.51,5.36-4.32,8.74-7.49,12.52c-1.09,1.3-2,2.77-3.86,5.37c7.11-1.29,11.94-3.62,15.85-7.88"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M57.46,174.42c-1.12,5.84-2.27,11.1-8.37,14.72c-7.39,4.4-13.8,10.45-20.64,15.77c-0.29,0.22-0.66,0.34-0.99,0.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M303,299.93c-6.33,6.95-14.73,4.74-22.51,4.89c-2.32,0.04-4.67-1.22-7-1.89"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M689.58,458.45c1.67-0.67,3.33-1.33,5-2c3.33-1.33,6.59-3,10.04-3.82c1.23-0.29,2.96,1.49,4.46,2.32"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M717.09,474.45c4.17,7.67,7.8,15.69,12.63,22.92c6.61,9.88,6.76,22.78,15.37,31.59"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M416.53,368.44c0.89,10.2,1.24,20.32-4.5,29.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M324.51,183.92c-2.67-4-5.63-7.84-7.9-12.06c-1.56-2.89-3.84-2.78-6.09-2.37c-5.74,1.04-11.64,1.9-16.02,6.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1030.15,178.92c1.7,3.49,4.91,5.94,5.22,10.49c0.29,4.24,7.68,8.36,13.28,8.51c7,0.19,14.01,0.24,21,0.57
				c2.02,0.09,4,0.94,6,1.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M464.54,607.47c-11.67,0-23.34,0-35.01,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M47.46,358.44c-9.67,2.33-19.34,4.67-29.01,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M320.51,279.43c-8.5,0.67-17,1.34-25.51,2c-3.26,0.25-7.31-1.2-7.73,4.49c-0.07,0.95-3.07,2.3-4.78,2.41
				c-9.19,0.58-18.34,0.44-27-3.4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M454.03,501.46c-0.83,0.17-2.15,0.06-2.43,0.54c-4.24,7.29-11.91,12.31-14.04,20.97c-0.42,1.69-0.37,3.49-0.54,5.24"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M373.52,507.46c-3.15,9.12-9.72,13.96-18.96,16.18c-2.78,0.67-5.33,3.2-7.49,5.38c-4.65,4.69-11.27,7.29-13.69,14.4
				c-2.33,6.85-6.82,12.16-14.36,14.1c-0.94,0.24-1.68,1.28-2.52,1.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M796.1,265.93c3.82,5.87,2.62,7.76-4.49,8.6c-5.81,0.68-11.45,1.16-17.03-1.06c-0.58-0.23-1.35-0.16-1.98-0.01
				c-12.22,2.78-24.33-1.12-36.51-0.57c-3.65,0.17-7.33-0.42-11-0.41c-3.49,0.02-6.98,0.37-11.25,0.62
				c-2.28,6.08-0.81,12.72,1.25,19.34"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M783.6,397.94c2-2.5,3.59-5.58,6.11-7.34c2.6-1.81,5.51-1.91,8.02,1.75c4.01,5.84,8.89,11.07,13.36,16.6
				c3.4,4.21,3.32,8-1.06,10.9c-6.09,4.03-10.87,10.45-18.94,11.08c-1.82,0.14-3.67,0.02-5.5,0c0,0-0.03-0.32,0.01-0.48
				c0.05-0.18,0.16-0.34,0.24-0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M63.46,732.48c9.67,6.67,19.34,13.34,29.01,20"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M438.03,675.48c-6.34,1.71-12.06,4.48-16.51,9.49c-2.27,2.56-4.84,4.87-6.93,7.56c-1.63,2.1-2.51,4.78-4.11,6.92
				c-2.12,2.83-4.21,5.95-7.03,7.92c-5.37,3.76-11.28,6.75-16.9,10.16c-1.3,0.79-2.82,1.74-3.44,3.01
				c-2.21,4.56-4.08,9.29-6.09,13.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M390.02,225.92c0.33-1.67,0.63-3.34,1.01-5c0.64-2.84,1.26-5.69,2.03-8.49c0.25-0.9,1.12-1.62,1.43-2.52
				c0.61-1.79,0.51-4.71,1.64-5.28c2.67-1.33,5.89-2.08,8.9-2.1c6.17-0.04,12.33,0.67,18.5,0.84c3.33,0.09,6.67-0.29,10-0.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M270.5,174.42c-3.83,0.5-7.7,0.85-11.48,1.59c-1.12,0.22-2.59,1.39-2.93,2.44c-2.92,8.98-8.81,15.48-16.59,20.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M807.6,595.47c5,4.17,9.91,8.45,15.03,12.46c3.85,3.02,7.97,5.7,11.97,8.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M60.96,158.91c-1.17-0.33-2.45-1.16-3.48-0.92c-7.04,1.66-14.03-0.28-21.02-0.01c-2.42,0.09-4.8,1-7.65,1.63
				c1.21,2.52,1.99,4.14,3.11,6.47c-8.27,1.9-16.16,3.78-24.47,2.33"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M773.6,567.46c4.83,3.5,9.8,6.83,14.46,10.56c3.4,2.72,6.35,5.97,9.58,8.91c0.45,0.41,1.3,0.37,1.97,0.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M807.6,462.45c-8.6,5.92-17.4,11.4-28.01,13"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M607.06,398.44c-0.5,0.5-1,1.43-1.5,1.44c-8.67,0.09-17.34,0.06-26,0.06"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M638.07,367.44c-0.33,3.5,0.1,7.31-1.16,10.44c-2.44,6.06-5.84,11.73-8.84,17.57"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M290.5,183.42c-9.15,2.9-18.5,3.62-28.01,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M419.53,215.42c-9.34,0.67-18.67,1.33-28.01,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M171.48,186.42c-3.61-7.76-7.8-9.72-16.01-8.05c-5.45,1.11-10.98,1.83-16.5,2.47c-1.27,0.15-2.66-0.74-3.98-1.2
				c-0.08-0.03-0.07-0.5,0.01-0.71c0.08-0.2,0.31-0.34,0.48-0.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M435.53,72.9c1.04,1.3,2.08,2.6,3.55,4.44c-5.76,3.17-11.73,2.49-17.56,2.55c-3.67,0.04-7.33,0.01-11,0.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M704.58,805.99c-9.34,1.67-18.67,3.33-28.01,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M299,171.42c-2.5-0.17-5.03-0.64-7.5-0.44c-5.68,0.47-11.36,1.16-16.99,2.03c-1.1,0.17-2.02,1.57-3.02,2.4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M684.08,84.65c3.63-8.14,1.21-11.72-8-11.74c-6.48-0.01-13.05-0.57-17.18,6.38c-0.77,1.3-3.88,1.22-5.89,1.77
				c0.47,1.65,0.85,2.97,1.23,4.29c-0.3,0.25-0.46,0.48-0.67,0.53c-2.5,0.69-4.98,1.41-7.51,1.98c-0.95,0.21-2.21-0.34-2.96,0.1
				c-3.75,2.2-7.21,4.93-11.08,6.85c-4.18,2.07-5.48-1.2-6.46-4.41"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M361.02,417.95c-0.5,4.17-1.43,8.34-1.41,12.5c0.03,8.34,0.58,16.67,0.91,25"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M761.59,165.41c-4.33,0.5-8.7,0.8-12.99,1.55c-6.7,1.16-13.36,2.54-19.99,4.04c-0.97,0.22-2.21,1.47-2.42,2.44
				c-0.61,2.77-0.69,5.66-1.12,8.48c-0.68,4.47,0.57,8.4,4.58,10.38c4.05,1.99,3.99,4.96,4.02,8.63c0.07,7.67,0.59,15.34,0.92,23"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M705.58,266.43c-4.67,7.71-5.38,16.27-5.57,25c-0.04,2.01-0.61,4.16-1.51,5.96c-1.17,2.33-2.94,6.21-4.42,6.2
				c-4.75-0.04-9.5-1.57-13.01-2.28c0-6.4-0.64-11.5,0.16-16.36c1.03-6.32,0.85-13.15,5.34-18.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M717.59,127.41c-4.11,5.94-11.67,9.09-18,7.5c-4.3-1.08-7.16-5.69-6.5-10.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M471.04,600.97c-2.33,3.33-4.88,6.54-6.96,10.03c-3.86,6.47-15.34,12.34-21.05,10.47c-4.2-1.37-8.22-8.33-7.5-13"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M376.02,733.98c-4,0.33-8.32,1.76-11.92,0.73c-4.61-1.33-7.48,0.11-10.6,2.75c-1.55,1.31-3.31,2.36-4.98,3.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M874.62,290.93c3.91,3.86,9.03,4.27,14.01,4.42c8.99,0.28,18,0.13,27,0.05c3.33-0.03,6.81,2.51,10-0.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M423.53,360.44c-4.01,7.95,0.68,16.42-1.46,24.51c-1.08,4.1-1.71,8.32-2.54,12.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1178.67,154.41c-11,0.83-22,1.67-33.01,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M628.57,531.46c0.33,6.17,0.76,12.33,0.96,18.5c0.09,2.83-0.29,5.67-0.46,8.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M364.52,495.96c-5.47,7.11-6.9,15.91-9.94,24.03c-0.31,0.82,0.27,1.98,0.44,2.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M155.48,452.95c0,1.67-0.05,3.34,0.01,5c0.14,3.83,0.33,7.67,0.49,11.5c0.02,0.5,0.21,1.15-0.03,1.48
				c-4.87,6.64,2.36,13.73-0.8,20.6c-0.92,2,0.17,4.92,0.33,7.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M860.61,833c-9,1.17-18,2.33-27.01,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M279.5,582.97c3.09-1.1,6.18-2.21,10.07-3.59c-4.98-4.26-9.89-5.49-15.04-4.71c-1.69,0.26-2.94,2.9-4.55,4.28
				c-2.25,1.93-4.52,3.89-7.01,5.48c-3.2,2.04-6.59,3.8-9.99,5.51c-1.73,0.87-3.65,1.37-5.48,2.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1097.16,312.43c-0.83,0.67-1.57,1.68-2.52,1.94c-5.83,1.6-5.73,1.47-2.51,6.58c3.86,6.12,7.57,12.3,14.53,15.49
				c5.51,2.52,7.35,1.72,8.46-4.01c0.41-2.12,0.37-4.33,0.54-6.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M550.55,561.46c7.45,3.22,15.18,4.48,22.97,1.89c4.14-1.38,5.9,0.06,7.04,3.61"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M722.59,411.94c-8.5,0-17,0-25.5,0c-6.87,0-13.45-0.95-19.01-5.49c-1.29-1.05-2.66-2.01-3.99-3.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M605.06,768.99c-0.33,2.67,0.31,6.64-1.18,7.76c-4.85,3.64-10.29,6.73-15.9,9.05c-3.05,1.26-6.92,0.53-10.42,0.69"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1115.16,489.45c-2.34-3.21-5.44-3.7-7.95-2.86c-0.92,4.82-0.91,9.48-2.7,13.29c-5.87,12.45-7.5,25.61-7.81,39.08
				c-0.1,4.16,0.29,8.33,0.46,12.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M936.13,628.47c-3.67,1.17-7.27,2.87-11.02,3.31c-2.55,0.3-5.33-1.07-7.97-1.83c-4.1-1.17-7.84-5.11-12.51-1.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1091.66,616.47c-1.32-0.24-2.65-0.48-5.84-1.05c1.57,3.71,2.01,7.24,4,8.86c3.16,2.56,7.15,3.54,10.96-0.19
				c1.22-1.19,4.79-4.34,4.89,1.38"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M663.57,501.46c-5.62,9.43-6.26,19.52-3.8,30.82c5.22-0.93,10.51-1.87,15.8-2.81"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1126.16,60.4c-1.46-4.66-5.18-6.54-9.5-6.36c-4.12,0.17-7.99,1.64-9.58,6.34c-0.56,1.65-1.93,3.02-2.93,4.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M328.01,400.44c0.72,11.36,1.43,22.71,2.18,34.62c-8.19,2.75-17.47,1.26-26.69,0.89"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M179.48,127.41c-4.5,0.33-9.01,0.58-13.5,1.06c-1.21,0.13-2.3,1.21-3.51,1.4c-4.98,0.78-9.98,1.54-15.01,1.88
				c-0.75,0.05-1.66-2.16-2.49-3.33"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M687.08,256.93c-2,2.17-4.3,4.13-5.93,6.55c-1.99,2.95-3.7,6.17-5,9.48c-1.58,4.02-3.66,7.1-7.47,9.63
				c-5.67,3.77-11.1,3.82-17.64,3.18c-6.85-0.68-13.97,1.34-20.97,2.16c-3.16,0.37-6.33,0.67-9.5,1.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M851.61,381.44c-4.31,4.56-3.98,7.94,0.98,7.66c7.03-0.39,12.14,4.24,18.52,4.85c0.68,0.07,1.33,0.32,2,0.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M821.11,642.47c8,4.5,15.68,9.81,24.14,13.17c4.48,1.78,10.07,1.06,14.37,4.33"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1075.65,200.92c-6.67,0.67-13.34,1.3-20,2.02c-2.01,0.22-4,0.65-6,0.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M362.52,543.46c5.17,8.75,10.34,17.5,15.5,26.25"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M961.13,64.4c-3.44-3.52-7.92-3.36-12.99,0.51c-0.74,0.57-1.26,1.45-2.03,1.97c-4.71,3.16-7.39,9.81-15.63,6.36
				c-4.59-1.92-7.07,1.46-6.36,6.16"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M485.54,339.94c-6.5,2-12.99,4.06-19.51,5.98c-3.8,1.12-7.66,2.02-11.5,3.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M815.6,168.41c-10.5,0.17-21,0.39-31.51,0.45c-2,0.01-4-0.62-6-0.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M845.61,102.41c0,1.68,0,3.37,0,5.22c7.32,0.52,14.18,1,21.44,1.51c-1.06,0.99-1.75,1.63-2.43,2.27"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M291.5,271.93c-2.68-3.62-5.36-7.24-7.22-9.75c-6.03,0.86-10.91,1.56-15.79,2.25"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M330.01,494.96c-0.83,4.5-1.51,9.04-2.55,13.49c-0.95,4.05-2.29,8.01-3.45,12.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M584.56,531.96c-0.17,0.67-0.11,1.61-0.53,1.96c-7.47,6.11-8.11,15.81-12.47,23.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1002.64,210.92c-0.17,0.67-0.66,1.46-0.45,1.98c3.1,7.86,6.29,15.69,9.45,23.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M282.5,455.45c-13.02,1.34-25.76-0.84-38.51-2.96c-1.78-0.3-3.66-0.04-5.49-0.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1038.15,504.46c-6.14,1.38-11.29,5.79-18.01,5.61c-2.15-0.06-4.61,0.8-6.41,2.01c-1.58,1.06-3.63,3.54-3.29,4.79
				c0.46,1.67,2.93,2.92,4.74,4.02c1.32,0.8,2.96,1.06,4.47,1.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M376.02,504.96c4.68,2.01,9.36,4.01,14.04,6.02c2.96,2.39,4.44,7.13,10.53,5.17c1.27-0.41,3.86,1.06,4.85,2.39
				c5.19,7.01,13.03,10.15,20.1,14.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M65.96,439.95c-1,4.33-2.47,8.62-2.88,13.01c-0.46,4.95-2.42,8.53-6.63,10.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M940.63,233.92c-8-0.17-16.01-0.18-24-0.63c-1.55-0.09-4.18-1.73-4.32-2.89c-0.82-6.83-5.77-4.85-9.7-4.9
				c-0.99-0.01-2,0.92-3,1.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1054.65,332.44c-3.06-1.03-6.12-2.05-9.44-3.17c-0.15,2.67-0.23,4.18-0.34,6.12c-3.42-0.57-6.56-1.09-10.36-1.72
				c0.99,4.34-0.81,5.76-4.87,5.36c-2.65-0.26-5.38,0.33-8.35,0.55c0.71,2.01,1.12,3.15,1.56,4.41c-1.87,0.78-3.59,1.5-5.37,2.24
				c7.93,10.23,9.74,8.45,15.17,6.71"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M239.49,553.96c-3.58-1.67-2.58-4.47-1.45-6.98c1.3-2.86,2.52-5.5,0.51-8.57c-0.46-0.7-0.47-2.26,0.01-2.9
				c1.29-1.73,3.86-2.93,1.42-5.56c1.96-1.45,3.93-2.9,6.16-4.56c-1.99-6.21-1.99-6.21,3.81-10.42
				c-3.45-10.14-11.01-12.21-20.97-12.51c-18.53-0.57-37.01-2.72-55.52-3.91c-4.63-0.3-9.33,0.78-14,0.83
				c-3.16,0.04-6.33-0.6-9.5-0.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M260.5,403.44c-12,2-24.23,3.15-35.94,6.24c-11.76,3.1-10.89,3.63-9.99,14.26c0.37,4.37,1.49,8.7,2.52,12.99
				c0.2,0.82,1.58,1.36,2.41,2.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M423.03,334.44c-3.8-0.5-5.5,0.63-5.73,4.99c-0.16,3.06-2.8,5.92-3.64,9.05c-0.53,1.98,0.2,4.3,0.37,6.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M163.48,554.96c-2.49,5.48,3.31,7.01,5.23,10.72c-1.97,2.35-3.87,4.61-0.88,7.91c1.23,1.36,0.81,4.2,1.15,6.37"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M958.63,667.98c-6,2-11.92,4.31-18.03,5.88c-3.69,0.95-7.31-0.31-9.97-3.38"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1006.64,536.96c-2.37,1.95-5.7,3.3-4.03,7.51c1.62,4.07,7.65,7.79,12.03,7.56c1.81-0.09,3.65,0.76,5.5,0.89
				c2.32,0.17,4.66,0.04,7,0.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1168.17,63.9c-3.07-7.71-6.32-10.37-12.95-8.79c-3.13,0.75-5.72,3.79-8.55,5.79"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M383.52,333.44c-8.33-0.17-16.67-0.33-25-0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1023.64,478.45c-4.83,4.17-9.58,8.45-14.54,12.46c-2.98,2.4-6.3,4.38-9.47,6.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M720.09,482.95c-0.47,5.58-3.58,9.2-8.48,11.55c-0.33,0.16-0.62,1.05-0.48,1.44c2.81,8.08-4.6,9.41-8.54,13.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M716.59,399.44c-8.14,2.76-16.42,4.2-25,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M658.57,720.48c0.22-3,0.44-6,0.64-8.76c-4.88-0.87-9.13-1.62-13.58-2.41c0.94-4.34,6.38-5.61,3.44-9.83
				c2-0.67,3.97-1.52,6.02-1.9c0.72-0.13,1.69,1.38,2.48,1.32c3.95-0.31,6.89,0.45,7.63,5.06c0.11,0.68,1.8,1.1,3.62,2.13
				c-3.98,2.76-6.61,4.57-9.24,6.39"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M436.03,368.44c0.33,8.33,0.67,16.67,1,25"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M173.48,429.45c-2.65,8.87,1.61,16.77,3.5,25"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.63,214.92c10.35,5.76,19.94,12.47,27.51,21.75"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M766.09,255.43c3.7,0.95,6.85,2.03,9.31,5.8c1.48,2.27,5.94,2.99,9.21,3.58c3.74,0.68,8.66,2,11.27,0.3
				c5.52-3.61,12.71-8.34,14.02-13.85c1.77-7.45,8.06-12.94,6.87-19.38c4.88-1.37,8.56-2.65,12.34-3.37c2.93-0.56,6.2-1.2,8.95-0.44
				c10.52,2.9,20.42-0.87,30.54-2.14"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M472.04,331.43c3.33,0.17,6.67,0.42,10,0.48c4.83,0.08,9.67,0.02,14.5,0.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M704.58,811.49c-8.37,0.54-16.46,2.19-24,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M910.12,455.45c-5.29,5.31-5.81,11.5-4.17,18.54c0.68,2.93,0.37,6.74-0.99,9.38c-4.12,7.98-4.23,8.51,3.66,13.59
				c1.2,0.78,2.51,1.5,3.45,2.55c0.65,0.72,1.18,2.72,0.99,2.83c-5.1,2.97-1.73,8.27-3.44,12.11c-0.09,0.2-0.32,0.34-0.49,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M389.02,196.92c-4.59,2.36-9.06,3.78-13.59-0.41c-0.89-0.82-2.92-0.39-4.41-0.6c-2.17-0.3-4.34-0.66-6.5-0.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M755.09,221.42c-0.33-3.83-0.52-7.69-1.04-11.5c-0.61-4.48-3.35-6-7.97-5.61c-2.88,0.24-7.23,3.18-7.99-2.9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M288,432.45c-12.74-2.28-25.38-0.5-38,1.08c-3.96,0.5-6.08,0.97-5.78-4.6c0.25-4.58-4.84-4.3-8.23-4.98
				c-6.95-1.38-13.13,1.62-19.5,3.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M885.62,218.92c-3,5.83-6.45,11.49-8.83,17.57c-1.42,3.64-2.37,8.25-1.43,11.87c2.26,8.69,5.76,17.06,8.76,25.57"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M650.07,258.93c-0.5,0.67-1.4,1.31-1.44,2c-0.43,7.5-0.73,15-1.06,22.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M207.49,81.9c-7.67,1-15.34,1.95-23,3.04c-1.7,0.24-3.34,0.96-5.01,1.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M838.11,184.92c-2.75-5.54-5.5-11.09-8.28-16.7c-2.91,1.66-5.57,3.18-8.22,4.7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M624.57,521.46c-7.98-3.03-15.61,2.05-23.5,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M578.56,520.96c-0.33,0.33-0.9,0.63-0.97,1.01c-2.05,12.04-7.12,22.51-16.01,31.03c-0.25,0.24-0.03,0.97-0.03,1.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1000.64,378.44c4.5,1.17,9.06,2.16,13.48,3.56c3.27,1.04,5.52,5.78,5.66,9.44c0.06,1.5,1.63,2.95,2.77,4.85
				c-5.55,4.96-9.85,11.75-18.91,10.16"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M506.54,166.41c-7.67,0.67-15.35,1.23-23,2.07c-2.21,0.24-4.31,1.41-6.52,1.89c-1.79,0.39-3.66,0.38-5.49,0.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M696.08,461.2c-1.17,2.42-2.8,4.72-3.41,7.27c-1.47,6.14-16.96,12.87-22.11,10.5c-4.58-2.11-8.72-0.74-13.04,1.38
				c-4.62,2.27-9.51,4.21-14.48,5.49c-7.4,1.91-14.98,3.08-22.48,4.61c-4.68,0.96-9.34,1.99-14,2.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M622.57,75.9c8.23-0.94,15.82-5.64,24.51-4.03c4.59,0.85,9.51,0.6,13,4.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1026.65,622.47c1.35,4.71,2.69,9.42,4.04,14.14c0.52,0.08,1.04,0.16,1.56,0.24c0.97-3.13,1.94-6.25,2.91-9.38"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M646.07,255.43c-2.81,10.05-4.53,20.16-1.5,30.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M353.51,162.41c5.94,8.9,10.96,9.83,20.03,7.61c1.81-0.44,3.97,0.55,5.97,0.89"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1117.16,295.93c-3.44,2.17-0.87,4.39,0.02,6.49c3.25,7.61,3.91,15.11-1.85,23.31c-1.36-0.67-4.43-1.24-5.38-2.96
				c-0.72-1.3-2.18-5.16,1.7-6.34"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M278.5,585.47c1,1.33,3.05,2.98,2.76,3.93c-0.47,1.59-2.2,3.37-3.81,3.96c-5.92,2.16-5.98,1.99-2.82,7.36
				c-6.62,4.91-14.16,7.62-22.16,9.2c-0.58,0.11-1.32-0.61-1.99-0.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M386.02,369.94c-4.63,2.44-8.21,5.48-7.61,11.51c0.11,1.09-1.07,2.45-1.91,3.47c-6.74,8.21-11.28,17.74-16.29,26.99
				c-0.84,1.55-2.58,2.59-3.66,4.06c-1.78,2.41-3.41,4.95-5.02,7.48c-4.36,6.82-8.55,13.75-13.11,20.43
				c-0.99,1.46-3.24,2.07-4.9,3.07"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M818.6,104.41c1.14-6.05-3.79-10.25-10-8.48c-1.37,0.39-2.67,0.99-4.01,1.48c-3.24,1.2-2.95,1.05-4.58-1.96
				c-1.71-3.16-4.68-4.76-8.42-4.01c-2.53,0.51-5.01,1.3-7.51,1.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1057.15,555.46c0,7.04,3.46,12.61,7.44,18.05c1.24,1.7,1.73,3.95,2.56,5.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M579.56,512.96c-1.17-0.5-2.32-1.04-3.5-1.49c-6.63-2.54-8.65-1.24-10.05,5.48c-2.3,11.05-5.83,21.66-12.41,31.04
				c-1.14,1.62-1.71,3.64-2.54,5.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M581.56,205.92c5.17,4.66,0.8,9.87,0.13,14.45c-0.6,4.09-4.3,7.72-6.64,11.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M766.59,532.46c-1,4.17-2.33,8.29-2.9,12.52c-0.49,3.6-0.1,7.32-0.1,10.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M980.64,75.4c-8.67,0.67-17.34,1.33-26.01,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M580.06,567.96c1.88-1.41,3.75-2.81,6.05-4.53c2.52,3.95,5.02,2.62,7.26-0.36c1.73,1.49,3.17,2.73,4.7,4.05
				c1.11-1.02,2.16-1.97,3.44-3.15c0.1,0.05,0.66,0.17,1.03,0.5c3.52,3.2,7.27,2.63,10.47-0.07c10.08-8.5,21.5-6.6,31.94-2.64
				c11.84,4.5,22.79,11.36,34.12,17.19"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1093.16,269.93c4.11,6.35,15.16,8.15,20.55,3.55c2.1-1.79,2.05-3.46,0.95-5.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M496.54,476.45c-1.83-1.67-3.51-3.57-5.54-4.94c-2.67-1.8-5.64-2.01-8.46-0.05c-2.35,1.64-4.45,4.02-7.05,4.86
				c-6.44,2.07-8.13,8.01-11.45,12.64c-2.88,4.02-5.68,8.1-8.29,11.83c-1.4,6.2-2.88,12.42-4.18,18.68
				c-0.41,1.95-0.37,3.99-0.54,5.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M224.49,476.95c10.22-1.8,20,1.75,30.01,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M428.53,370.44c2.5,7.67,5,15.34,7.5,23"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1143.17,447.45c2.06-6.4,0.24-8.84-6.5-8.48c-4.01,0.21-8,0.64-12,0.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1124.66,528.96c-2,1.17-4.28,2.03-5.95,3.55c-6.95,6.37-13.75,12.92-20.52,19.49c-0.93,0.9-1.37,2.3-2.04,3.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M591.56,428.45c-7.67,1.33-15.34,2.67-23,4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1168.17,204.42c-2.29,7.52-1.45,15.69,1.99,21.72c3.26,5.72,2.15,12.08,5.43,17.33c0.4,0.64-0.26,1.95-0.42,2.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1079.16,378.94c-5.57-4.34-11.5-4.62-14.56-1.05c-2.34,2.74-2.32,5.35-0.41,8.02c2.19,3.07,5.14,2.12,7.96,1
				c1.02-0.4,1.93-1.06,2.48-1.37c-1.57-5.26-4.42-3.69-6.98-3.11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1060.15,428.45c1.57-4.23,5.71-2.24,8.5-3.5c1.83-0.83,3.67-1.67,5.5-2.5c-1.5-2.17-2.69-4.7-4.64-6.34
				c-0.91-0.77-3.27,0.17-6.34,0.47c-4-2.36-7.21-2.11-11.41,3.46c-3.45,4.58-6.63,4.35-10.62,1.41"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M264.5,159.91c-5.83,1-11.68,1.92-17.49,3.05c-1.89,0.37-3.68,1.29-5.51,1.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M782.1,96.41c0.33-3.17,1.33-6.44,0.86-9.48c-0.94-6.08-5.87-7.58-10.34-3.5c-1.3,1.19-2.35,2.64-3.52,3.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M499.54,345.94c-4.83,0.67-9.66,1.41-14.51,1.97c-2.82,0.33-5.66,0.36-8.5,0.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1148.17,307.43c-2.98,12.61-14.7,11.53-24.5,15.63c0-2.62-0.77-5.13,0.15-6.54c2.66-4.08,5.86-7.63,5.51-13.11
				c-0.13-1.94,1.51-3.99,2.35-5.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M386.02,423.45c6.71,1.53,8.84,7.66,8.12,12.44c-1.35,8.96,2.28,15.62,6.38,22.56c0.87,1.48,1.67,3,2.51,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M237.99,558.96c-5.5,4.33-10.43,10.09-16.66,12.62c-6.73,2.72-13.04,5.96-19.35,9.39"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M992.64,499.96c1.88,2.41,2.68,4.57,1.8,8.08c-0.84,3.35-0.03,7.19-0.54,10.96c-0.82,6.14-3.67,10-8.7,13.06
				c-1.95,1.18-3.3,3.35-5.25,5.42c1.7,1.21,2.95,2.1,4.2,2.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1049.65,391.94c-5.55,0.59-6.06,1.6-2.94,6.09c-1.86,0.98-3.62,2.23-5.58,2.85c-3.28,1.04-7.43,2.03-6.67,6
				c0.51,2.65,4.02,4.73,6.19,7.06"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1029.15,473.45c3.67,7.5,7.33,15,11,22.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M796.1,410.94c-7.5,3.67-15,7.33-22.5,11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1127.16,363.44c-2,1.5-3.85,3.29-6.03,4.44c-4.22,2.21-8.57,4.22-13,6c-2.18,0.88-4.97,2.03-6.91,1.37
				c-4.69-1.58-8.24-0.49-11.57,2.69"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M259.5,302.93c-7.5-0.33-15-0.67-22.5-1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M831.11,167.91c2.83-2.97,5.55-3.58,8.52-0.02c8.52,10.22,18.95,18.23,29.97,25.55c0.96,0.64,1.68,1.64,2.52,2.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M960.63,601.47c-7.63,6.87-15.27,13.74-22.9,20.61c0.03,0.37,0.06,0.73,0.08,1.1c4.61-0.74,9.21-1.47,13.82-2.21"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M731.59,185.42c0.83,1.17,1.47,3.09,2.53,3.38c6.67,1.76,7.72,7.12,8.41,12.63c0.37,2.98-0.22,5.85,3.42,7.76
				c1.22,0.64,0.82,4.4,1.13,6.73c0.2,1.5,0.34,3,0.51,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M594.06,81.4c-5,0.17-10.39,1.56-14.89,0.13c-3.89-1.24-7.34-1.49-11.12-1.12c-2.67,0.26-5.34,0.65-8,0.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1056.65,493.45c-2-0.5-3.98-1.1-6.01-1.48c-4.59-0.85-7.28,1.55-9.12,5.86c1.67,0.85,3.39,1.74,5.12,2.62"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M426.53,565.96c-0.5,6.17-0.96,12.34-1.53,18.5c-0.11,1.19-0.63,2.34-0.97,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M226.99,553.46c4.33,1.83,8.69,3.62,12.99,5.52c1.56,0.69,3.82,1.29,4.37,2.54c2.85,6.47,8.35,9.81,14.14,12.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M505.04,478.45c-3,3.67-5.74,7.6-9.08,10.92c-2.76,2.74-6.25,4.75-9.42,7.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M460.04,338.94c5.33,0,10.67-0.12,16,0.07c1.68,0.06,3.34,0.93,5,1.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M682.08,263.43c4.57,2.57,9.59,1.86,14.5,2.01c3.5,0.11,7.06-0.01,10.47,0.64c1.48,0.28,2.63,2.24,2.32,3.55
				c-1.93,7.77-3.86,15.53-5.79,23.3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M272,596.47c-7.33,3.67-14.67,7.33-22,11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1110.66,210.92c-7.42-3.16-13.26,1.44-19.5,4.01c-0.55,0.22-1.01,0.65-1.68,1.1c0.55,1.44,1.11,2.91,1.67,4.39"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M842.61,70.9c-5.5,1.17-10.98,2.51-16.53,3.34c-1,0.15-2.31-1.84-3.48-2.84"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M799.1,54.9c1.88,1.73,3.71,3.45-0.5,4.5c-4.67,1.17-9.34,2.31-14,3.52c-0.71,0.18-1.34,0.65-2.01,0.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M650.07,357.94c1.92,3.1,0.12,5.78-1.01,8.5c-1.95,4.69-3.9,9.38-6.01,14c-2.43,5.33-3.79,11.29-8.93,15.08
				c-0.69,0.51-0.72,1.93-1.05,2.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M416.53,209.92c-7.17,0.33-14.34,0.67-21.5,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M224.49,72.9c0.49,2.78-0.78,4.01-3.5,4c-3.5-0.01-7.01-0.14-10.5,0.04c-2.53,0.13-5.04,0.68-7.63,1.06
				c0.25,1.57,0.44,2.73,0.63,3.89"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1194.18,478.2c3.26,6.27,2.4,12.73,1.04,19.26c-0.27,1.28-0.04,2.66-0.04,3.99c0,2,0,4,0,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M957.13,656.98c-6.92,7.19-16.3,9.99-25,14"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M859.11,662.98c-3.36,1.43-6.72,2.86-11.33,4.82c-0.61-1.05-1.59-2.75-2.58-4.45c-0.48,0.26-0.96,0.52-1.44,0.77
				c-0.85,7.18,0.71,14.46,4.71,20.1c2.31,3.27,8.35,3.82,12.61,5.81c1.81,0.85,3.65,2.02,4.97,3.49
				c4.96,5.55,10.86,10.69,14.25,17.12c3.73,7.07,9.84,10.93,15.26,15.9c1.03,0.94,1.39,2.61,2.05,3.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M821.11,61.4c5.83,2.17,11.68,4.3,17.5,6.52c2.37,0.9,4.67,1.98,7.01,2.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M784.1,187.42c3,0.82,6.01,1.64,9.81,2.67c-1.5,2.46-2.86,4.68-4.43,7.24c2.58,0.37,5.09,0.97,7.62,1.02
				c3.02,0.06,5.66-0.22,7.24,3.46c0.49,1.15,3.96,1.88,5.73,1.44c4.01-0.99,5.48,0.66,6.04,4.16"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M923.13,76.4c-1-0.33-2.02-0.61-3-1.01c-8.76-3.59-10.9-2.77-15.51,6.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M807.1,415.45c-4.64,7.5-12.76,9.86-20,13.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M433.03,573.46c-0.33,5.17-0.65,10.34-1.01,15.5c-0.13,1.84-0.33,3.67-0.49,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M648.07,485.45c-0.33,2.83-0.85,5.66-0.96,8.5c-0.16,4.33-0.09,8.67-0.02,13c0.03,1.67,0.31,3.33,0.48,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M347.51,429.45c-3.07-7.6-4-15.42-2.33-23.95c-8.01-1.35-15.6-2.62-23.77,0.18c-4.48,1.53-9.92,0.44-14.9,0.14
				c-1.06-0.06-2.57-1.7-2.91-2.89c-1.55-5.44-2.76-10.97-4.1-16.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M719.09,165.91c-6.83,6.17-13.56,12.47-20.59,18.4c-1.59,1.35-4.99,2.66-6.29,1.88c-4.91-2.94-7.07,0.58-9.59,3.26
				c-1.42,1.51-2.38,3.46-3.55,5.21"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M390.02,717.48c-0.5,1.67-0.81,3.42-1.55,4.98c-0.77,1.61-2.58,2.93-2.83,4.54c-0.75,4.86-3.81,6.95-8.12,7.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M817.1,378.94c-6.83,3.33-13.67,6.67-20.5,10"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1020.14,653.47c1.71,5.45-1.94,9.19-4.69,12.86c-1.09,1.45-4.36,1.68-5.84-1.34c-1.01-2.08-2.3-4.01-3.47-6.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M757.59,512.46c0.83,0.17,2.19,0.08,2.42,0.54c2.79,5.72,6.6,11.12,6.08,17.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M769.1,92.41c0.37-5.49-3.82-7.8-7.58-9.86c-2.74-1.5-6.54,2.85-7.38,6.86c-0.34,1.62-0.25,3.33-0.39,5.49
				c-3.65,0-7.15-0.12-10.65,0.03c-5.84,0.24-11.75,0.19-17.46,1.24c-1.81,0.33-4.21,3.71-4.27,5.75c-0.08,2.76,2.81,2.46,5.24,2.12
				c1.4-0.2,2.98,0.87,4.48,1.37c-1,1.67-2,3.33-3,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M758.09,403.44c1.33,6.83,2.67,13.67,4,20.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M870.11,92.41c-4.36-0.5-8.58-1.23-10.83,4.37c-0.31,0.78-5.01-0.2-7.68-0.37"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M363.52,30.9c-6.75-2.22-13.33-2.47-19.5,1.75"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M975.14,668.48c-3.33,1.83-6.56,3.93-10.03,5.43c-4.17,1.81-6.97,0.24-7.97-3.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M134.47,179.92c-1.17,2.17-2.47,4.27-3.47,6.51c-1.93,4.29-3.7,8.66-5.53,12.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1169.67,416.45c-0.26,5.59-3.66,8.62-8.74,10.5c-0.78-2.6-1.57-5.27-2.36-7.94c-0.6,0.12-1.2,0.24-1.8,0.37
				c-0.2,1.19-0.53,2.37-0.57,3.57c-0.29,7.5-0.27,7.38-7.52,7.54c-4.84,0.11-9.67,0.62-14.5,0.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1144.67,64.9c0.33-1.5,1.44-3.55,0.84-4.39c-1.33-1.86-3.69-4.56-5.31-4.36c-6.2,0.77-11.38,3.96-15.03,9.24"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1004.64,379.44c1-1,1.99-2.01,3-3c2.46-2.38,1.89-4.53-1.5-5.5c-5.33-1.52-10.67-3.01-16-4.5c-0.66-0.19-1.74-0.68-1.94-0.45
				c-4.29,5.02-11.43,1.15-16.2,5.3c-1.67,1.45-4.95,0.91-7.34,1.75c-1.35,0.48-2.64,1.75-3.37,3c-0.22,0.39,1.5,1.91,2.34,2.91"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M711.08,481.95c-2.75,5.46-3.2,12.06-8.04,16.46c-0.89,0.81-2.29,1.04-3.46,1.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M716.08,8.9c0.25,7.84-4.39,13.9-7.55,20.48c-1.19,2.48-2.64,5-4.55,6.92c-1.5,1.5-3.81,2.61-5.92,3
				c-5.22,0.97-7.92,4.75-5.98,9.1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1124.16,328.93c3.24,5.32,8.46,6.19,14,5.94c2.53-0.12,5.49-0.46,4.5-4.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M998.14,403.44c-1.5,7.67-3,15.34-4.5,23"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M543.55,352.94c-4,1.83-7.98,3.71-12.01,5.48c-2.59,1.14-5.25,2.12-7.95,3.2c1.52,2.11,2.49,3.47,3.46,4.82"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M874.62,248.92c-1.5,0.17-3.14,0.8-4.47,0.41c-3.42-1.02-6.37-1.04-9.63,0.92c-1.4,0.84-3.9-0.17-5.9-0.33"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M272.5,175.92c3.83,0.83,7.65,2.18,11.51,2.34c3.13,0.13,6.33-1.17,9.5-1.84"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M871.12,412.95c-0.63,13.79,0.01,27.48,3,41.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M270.5,358.44c-6.67,0.33-13.34,0.67-20,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1076.15,515.46c5.1,6.7,4.76,14.75,5.4,22.51c0.07,0.89-1.23,1.9-2.32,3.45c-2.4-4.74-7.02-7.38-5.77-13
				c0.11-0.51-2.47-1.62-3.81-2.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M968.13,431.95c2,2.17,4.71,4.01,5.87,6.56c3.88,8.54,8.66,16.52,13.48,24.53c2.53,4.2,5.06,9.17,1.65,14.41"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M735.59,435.45c-2.45,0.5-3.28,2.01-3.64,4.48c-0.24,1.61-1.53,3.52-2.92,4.43c-4.68,3.07-9.61,5.76-14.44,8.59"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1169.67,72.9c5-1.96,10.04-2.57,15.52-2.21c4.94,0.33,6.96,1.9,6.49,6.71"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1089.41,225.92c-5.76,2.29-10.64,6.3-16.66,8.28c-1.8,0.59-2.49,4.52-3.6,6.74c2.32,2.5,4.17,4.49,6.01,6.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M981.14,405.44c-3.9,5.13-9.18,2.75-13.99,2.39c-1.06-0.08-1.91-1.74-3.03-2.35c-2.09-1.14-4.31-2.05-6.48-3.04
				c-1.16-0.52-2.33-1-3.5-1.5c0.83-1.17,1.45-2.65,2.56-3.42c1.44-0.99,3.28-1.41,4.95-2.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M631.07,488.95c-1,3.5-1.99,7.01-3.01,10.5c-0.92,3.15-0.98,5.06,3.5,5.17c4.85,0.11,9.67,1.5,14.51,2.34"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1060.65,622.47c0.1,6.26,4.27,12.93,10.41,16.22c0.71-1.77,1.4-3.49,2.09-5.22"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M765.59,401.94c-0.08,6.81,2.66,12.5,6.37,18.09c2.23,3.35,3.13,7.58,4.63,11.41"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M113.47,379.44c-4.35,0.48-8.71,0.95-13.38,1.46c0.29-1.95,0.66-4.36,0.95-6.27c-7.46,1.56-14.02,2.94-20.58,4.31"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M617.57,558.96c-5-1.33-10.05-3.92-14.99-3.73c-8.87,0.36-17.65,2.97-26.53,3.48c-4.42,0.25-8.98-2.1-13.49-3.24
				c-0.98-0.25-2-0.35-3-0.51c-4.72-0.74-7.51,1.1-8,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M100.47,172.42c1.17,5,2.26,10.02,3.54,14.99c0.4,1.57,1.29,3.01,1.96,4.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M974.14,597.47c-0.66,2.97-5.08,5.74,0.03,9.69c2.72-3.14,5.35-6.17,7.97-9.19"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1104.16,627.47c-0.17,1-0.84,2.4-0.42,2.93c2.33,2.97,4.68,6.03,7.59,8.35c0.69,0.55,3.56-1.65,5.52-2.63
				c6.83,5.56,7.9,5.46,10.31-1.15c2.17,0,4.33,0,6.5,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M694.08,468.95c4.23,2.55,3.74,7.06,4.45,11.01c0.44,2.45,0.38,4.99,0.55,7.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M723.09,416.95c1,1,2.86,2,2.86,3c-0.01,7.95,6.97,10.69,11.11,15.53c0.71,0.82,1.03,1.98,1.53,2.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M758.59,274.43c-1.77,5.12-1.51,9.46,2.86,13.65c2.62,2.51,3.56,6.69,5.75,9.77c1.03,1.45,3.13,2.34,4.91,3.02
				c2.93,1.1,5.69,1.9,4.08,6.1c-0.35,0.91,0.25,2.55,0.96,3.42c3.21,3.93,6.61,7.71,9.95,11.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M302.5,418.45c7.67,0.33,15.34,0.67,23,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M161.98,179.92c-2.24,1-4.48,2-6.78,3.02c3.51,3.76,6.65,7.12,9.78,10.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M729.09,40.4c-4.02-7.98-10.68-7.06-16,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1107.16,153.41c0.83,7.17,2.3,14.33,2.32,21.5c0.02,6.83-1.48,13.67-2.32,20.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M117.47,377.94c-1.5,1.33-3.88,2.41-4.35,4.04c-2.38,8.29-8.29,11.87-16.14,13.53c-2.2,0.47-4.21,1.83-7.12,3.14
				c3.84,2.73,6.88,4.07,10.6,2.78"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M458.53,233.92c-6.33-0.33-12.67-0.67-19-1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M336.51,313.93c-6.33,0.5-12.67,1-19,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M71.96,686.48c2.33,4.33,4.56,8.73,7.04,12.98c1.25,2.15,2.96,4.03,4.46,6.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M505.54,340.94c-6.33,0.5-12.67,1-19,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M677.58,518.46c-7.67,0.67-15.61,0.32-22.92,2.3c-6.85,1.85-12.3-2.71-18.58-1.8c-1.33,0.19-2.67,0.34-4,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M341.51,32.4c5.17,0.83,10.33,1.73,15.51,2.48c4.47,0.65,5.76,2.28,4.5,6.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1113.66,389.94c4.83,2.33,9.7,4.6,14.49,7.03c2.9,1.47,2.96,4,2.01,6.73"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M815.6,371.94c1.67,5,3.27,10.02,5.03,14.99c0.84,2.39,2.09,4.63,2.93,7.03c0.56,1.59,1.41,3.56,0.92,4.94
				c-1.86,5.28-4.21,10.38-6.38,15.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M752.59,404.94c1,5.67,1.72,11.41,3.16,16.96c0.46,1.77,2.84,3.04,4.34,4.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M562.06,212.92c-6.33,1.17-12.67,2.33-19,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M803.1,108.41c-2-0.33-4.07-1.14-5.98-0.88c-2.24,0.31-5.34,0.99-6.22,2.55c-0.77,1.36,1.04,4.17,1.7,6.33"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M48.96,159.91c0.04,6.4-0.67,12.65-3.5,18.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M352.01,274.93c-6.17,1.17-12.34,2.33-18.5,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M24.45,291.93c-6.17,1.5-12.34,3-18.5,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M799.6,462.45c-6.17,2.83-12.34,5.67-18.5,8.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M153.48,480.45c-1.5,5.33-2.9,10.7-4.54,15.99c-0.75,2.41-1.59,4.94-3.03,6.96c-3.44,4.83-7.26,9.39-10.93,14.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1093.66,153.91c2.14,5.05,0.48,9.32-2.43,13.55c-1.28,1.86-1.74,4.28-2.58,6.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1079.16,692.98c-0.86,1.08-1.72,2.15-2.58,3.23c-0.3-0.25-0.6-0.49-0.91-0.74c-0.56,2.24-1.13,4.47-1.69,6.71
				c-0.79,0.45-1.59,0.9-2.38,1.35c-2.22-2.79-4.44-5.58-6.74-8.47c-0.65,1.88-1.37,3.95-2.33,6.72c-2.3-3.17-4.1-5.66-6.37-8.8
				c-0.81,2.43-1.33,3.97-1.58,4.72c-2.88-0.26-5.44-0.31-7.92-0.78c-3.01-0.57-6.37-1.05-6.01-5.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M921.12,176.42c-0.06-4.32-2.03-6.3-6.51-5.53c-2.13,0.36-4.34,0.29-6.49,0.55c-3.11,0.37-4.27-0.89-4.05-4.02
				c0.22-3.15,0.05-6.33,0.05-9.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M264.5,28.9c0.31-3.06,1.02-6.03-1.95-8.56c-3.28-2.8-5.73-2.8-9.56,0.81"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M987.14,400.44c1.67,6.17,3.33,12.33,5,18.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M723.09,509.96c-2.91,5.76-8.12,7.97-13.98,9.57c-6.55,1.79-7.93,5.86-4.52,11.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1086.66,440.95c5.66,0.92,10.9,4.78,17,2.48c3.7-1.39,5.62,0.46,7.01,3.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1166.67,574.97c2.94-0.42,2.71-2.99,1.39-3.84c-2.65-1.71-6.31-4.02-8.81-3.34c-5.11,1.38-9.22-0.13-13.59-1.82"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M827.11,230.42c1.74,5.86,0.94,11.48-1.47,17.01c-1.73,3.96-4.59,5.31-8.54,4.04c-1.91-0.62-3.99-0.72-5.99-1.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M398.52,29.9c-8.47-5.7-13.42-4.37-16.51,2.52c-5.65-3.47-11.53-3.01-17.49-1.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M842.61,250.92c4.96,6.92,11.82,12.48,14.59,20.97c0.55,1.7,1.94,3.6,3.47,4.41c3.32,1.75,3.29,4.18,2.44,7.12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M583.06,490.45c-3.83,6.33-7.67,12.67-11.5,19"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M393.52,61.9c-3.17-0.33-6.33-0.9-9.5-0.95c-5.55-0.08-7.71,3.1-6,8.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M756.09,424.45c-4.17,1-8.31,2.15-12.51,2.95c-3.5,0.67-5.84,1.9-5.05,6.06c0.11,0.58-0.61,1.32-0.94,1.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M678.58,85.9c5.02-1.13,9.79-0.86,14.43,2.55c-0.93,1.56-1.77,2.96-2.24,3.75c3.59,9.11,12.06,5.92,18.31,8.21
				c0.84,0.31,1.67,0.66,2.5,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M648.57,527.46c-3.5-0.17-7-0.44-10.5-0.46c-2.5-0.02-5,0.3-7.5,0.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M295.5,332.94c-4.67,0.33-9.35,0.53-13.99,1.09c-1.24,0.15-2.34,1.41-3.51,2.16"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M426.53,607.97c-6-0.83-12-1.67-18-2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M433.28,209.92c-5.72,3.59-7.88,8.89-8.43,15.49c-0.23,2.76-2.25,5.51-3.88,7.98c-2.09,3.16-4.61,6.04-6.94,9.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1007.14,98.41c0.3-3.58-0.46-6.23-4.46-7.61c-3.8-1.31-5.72,0.85-7.42,3.2c-0.71,0.98-0.12,2.91-0.12,4.41"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M235.99,399.94c-3.67-1.56-6.53,0.21-8.96,2.55c-2.54,2.45-4.71,5.29-7.05,7.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M646.57,133.91c-3,0.68-6.82-0.3-8.07,4.25c4.32,5.06,10.06,1.5,15.07,2.25"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M983.64,475.45c7.28,4.35,7.53,11.66,8.71,18.97c0.82,5.06-1.78,6.11-5.24,6.92c-0.99,0.23-2.31-0.89-3.47-1.39"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1143.67,271.43c-0.17,0-0.4-0.07-0.49,0.01c-1.83,1.67-6.83,1.31-5.2,4.85c1.18,2.56,5.26,3.68,8.7,1.66
				c0.25-0.15,0.66-0.02,0.99-0.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M100.47,740.49c6,4,12,8,18,12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M634.57,690.98c4.9-0.11,7.46,2.9,8.96,7.02c0.92,2.53,2.44,3.55,5.04,2.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M990.64,180.92c3.5,0.33,7.19,0.07,10.47,1.12c8.7,2.78,16.08-0.6,23.54-4.12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M589.56,489.95c-1.83,6.5-3.56,13.03-5.57,19.48c-0.56,1.8-2.05,3.3-2.9,5.04c-0.68,1.41-1.69,3.17-1.33,4.42
				c0.28,0.97,2.41,1.53,3.81,2.02c1.09,0.38,2.32,0.38,3.49,0.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M492.54,433.95c-9.84,3.17-19.81,5.96-29.45,9.65c-5.16,1.98-9.73,5.52-14.56,8.35"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M407.53,368.94c0,4,0.15,8.01-0.07,12c-0.1,1.86-0.93,3.67-1.43,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1022.64,686.48c2.83,4.17,5.48,8.48,8.64,12.39c0.74,0.92,3.2,0.44,4.86,0.61"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M238.49,381.94c-0.83,6.83-1.64,13.67-2.52,20.5c-0.24,1.85-0.65,3.67-0.99,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1079.66,259.93c-5.3-2.67-11.52-2.44-16.7-6.24c-2.93-2.15-7.81-4.08-11.81-0.26"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M769.1,169.91c-2.67,0.33-5.33,0.84-8,0.96c-3.83,0.17-7.67,0.04-11.5,0.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1127.16,550.46c-3.33,0.5-6.7,0.84-9.99,1.54c-3.66,0.78-4.15,3.31-3.01,6.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M593.06,489.95c1,5.63,5.49,3.45,8.5,3.36c5.64-0.16,7.36,1.34,5.42,6.61c-1.4,3.82-3.65,7.33-5.37,11.05
				c-0.47,1.03-0.38,2.31-0.55,3.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M867.61,815.99c-4.67,0.83-9.52,1.14-13.94,2.67c-3.34,1.16-6.25,3.4-10.06,2.33"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M52.96,437.45c-2.17,0.33-4.33,0.92-6.5,0.94c-3.5,0.04-7-0.28-10.5-0.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M956.63,315.43c-5.26-3.34-10.93-4.29-17-3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M769.6,275.93c-1,5.67-2,11.33-3,17"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1077.16,347.94c-5.17,2.83-10.34,5.65-15.49,8.52c-0.59,0.33-1.21,0.89-1.48,1.49c-1.8,4.09-4.96,4.08-8.53,2.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M492.54,240.92c2.83,0.67,5.67,1.33,8.5,2c1.73,0.41,3.45,0.85,5.17,1.28c0.02,0.53,0.03,1.06,0.05,1.59
				c-5.91,1.38-11.82,2.76-17.72,4.13"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M489.04,260.93c2.75,4.28-2.91,3.66-3.5,6c0,0.33-0.11,0.91,0.02,0.97c7.53,3.45,2.46,10.52,4.49,15.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M228.99,555.96c-4.33,5.17-8.67,10.33-13,15.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M555.05,505.96c4,0,8.01-0.1,12,0.05c1.68,0.06,3.34,0.62,5,0.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M636.57,411.94c-7-0.17-14-0.33-21-0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M530.55,451.45c-5.67,1.5-11.34,3-17,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M786.6,121.41c-4,0.83-8.05,1.49-11.96,2.63c-0.93,0.27-1.37,2.2-2.04,3.37"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1143.67,616.97c7.09,3.31,7.34,5.85,0.84,10.62c-1.06-0.51-2.29-0.94-3.32-1.65c-1.17-0.8-2.18-1.84-3.18-2.7
				c-9.03,6.25-9.23,6.25-14.16,0.97c-5.27,0.99-9.96,1.98-14.7,2.71c-1.44,0.22-2.99-0.27-4.49-0.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M676.58,510.96c-5.5,0.5-11,1-16.5,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M421.03,321.43c2.7,9.85,3.72,19.83,2.5,30"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M272.5,574.97c-3.17-0.33-6.37-0.49-9.49-1.04c-4.94-0.87-5.4,3.57-7.5,6.05c-5.46,6.44-9.36,14.56-18.5,17.05
				c-0.28,0.08-0.35,0.95-0.51,1.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M418.53,584.97c2.33,2.83,4.14,6.65,7.13,8.25c3.79,2.03,8.51,2.95,12.88,3.08c5.98,0.18,12-0.8,18-1.37
				c0.86-0.08,1.67-0.63,2.5-0.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1056.65,513.96c-2.35-1.03-4.8-0.89-4.75,2c0.03,1.36,2.65,3.66,4.27,3.82c4.35,0.43,8.8-1.49,13.09,1.54
				c1.47,1.04,3.33,0.01,4.13-2.78c0.76-2.64,3.44-4.73,5.27-7.07"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M601.06,493.96c0.17,5.5,0.33,11,0.5,16.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1160.67,192.92c-6.8,7.66-15.3,14.46-13.5,26.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1035.15,525.46c-1.63,3.53-3.64,4.95-8.05,3.69c-5.97-1.7-12.38-2.47-17.92,1.86c-0.9,0.7-1.37,1.96-2.03,2.96
				c1.17,0.67,2.33,1.33,3.5,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M619.07,268.43c1.5,5,3.04,9.99,4.47,15.01c0.36,1.28,0.36,2.66,0.53,3.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M811.1,227.92c-0.33,3.67-0.83,7.33-0.93,11c-0.05,1.82,0.6,3.67,0.93,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M421.53,561.46c6.5,5.83,13.25,11.43,19.33,17.67c1.5,1.53,0.98,5.16,1.14,7.83c0.17,2.82,0.04,5.67,0.04,8.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M366.52,494.96c-3.33-0.83-6.64-1.84-10.01-2.46c-6.51-1.21-13.07-2.22-19.96-3.36c-3.09,1.71-6.81,3.77-10.54,5.83"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M957.63,684.48c0,2,0,4,0,6c2.33-0.17,4.68-0.22,6.99-0.55c1.16-0.17,2.25-0.85,3.65-1.41c-0.69,4.56,1.19,5.54,4.87,4.49
				c1.28-0.37,2.66-0.37,3.99-0.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1074.65,446.95c-0.98,3.94-3.64,5.17-7.5,5.06c-3.19-0.09-4.58-1.48-4.5-4.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M670.58,479.95c-1.67,5.5-3.33,11-5,16.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M286,405.94c0.83,8.67,1.67,17.34,2.5,26"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M925.13,176.42c-1.57-7.23-3.14-14.46-4.7-21.65c-3.8,0.83-6.8,1.49-9.8,2.15"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M337.01,513.46c0.33,5,0.66,10,1,15c0.15,2.17,0.33,4.33,0.5,6.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M615.07,350.94c0.5,3.5,0.92,7.01,1.53,10.5c0.33,1.86,0.97,3.67,1.47,5.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M603.06,274.43c-1.5,6.83-3,13.67-4.5,20.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M142.97,428.45c-1.17,5.33-2.33,10.67-3.5,16"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1034.15,477.95c2.95,3.57,6.44,3.76,10.54,2.08c1.63-0.67,3.63-0.42,5.47-0.58"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M729.59,273.43c-1.75,8.29-6.45,14.45-13.47,19.05c-1.85,1.21-3.36,2.96-5.03,4.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M108.47,173.42c1.17,5.33,2.33,10.67,3.5,16"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1132.67,596.97c-4.97,2.5-10.72,1.71-12.85-2.58c-1.48-2.98-3-3.17-5.67-2.47c-2.18,0.57-9.15,6.22-9.49,8.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M691.08,272.43c-1.17,2-2.61,3.89-3.44,6.03c-1.23,3.16-1.21,7.04-5.57,7.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1096.66,425.95c-1.67,2.67-3.42,5.28-4.98,8.01c-4.19,7.31-10.22,9.4-17.53,6.02c-0.41-0.19-0.99-0.02-1.49-0.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M364.52,67.4c-5.64-6.1-10.36-6.25-16.5-0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M823.61,440.45c-5,4.67-10,9.33-15,14"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M713.58,474.95c-2.68,6.56-7.04,11.27-13.95,13.66c-1.21,0.42-1.72,2.85-2.55,4.35"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M811.6,189.42c-1.03,2.88-0.98,5.29,3,4.97c8.52-0.68,11.34,1.53,13.01,10.53c0.28,1.51,0.66,3,0.99,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M522.55,212.42c-5.33-0.17-10.67-0.33-16-0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1023.64,319.43c-4.06,3.58-8.3,6.07-14.11,3.77c-0.52-0.21-2.56,3.4-3.89,5.23"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1087.16,392.94c0.67,1.33,2.07,2.78,1.88,3.98c-1.18,7.59,3.52,13.21,6.12,19.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M259.5,168.91c-6.27-1.42-13.02,0.24-19-3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M528.05,678.48c-0.5,5.33-1,10.67-1.5,16"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1037.15,628.47c1.6,2.64,3.2,5.29,4.79,7.93c0.58-0.06,1.16-0.13,1.74-0.19c0.49-2.41,0.98-4.82,1.46-7.24"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M342.51,382.44c0.5,5.17,1,10.33,1.5,15.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M616.57,265.43c-0.17-0.5-0.26-1.4-0.51-1.44c-5.15-0.91-10.32-1.73-15.49-2.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1109.66,644.47c-1.83,1.83-3.38,4.18-5.57,5.38c-3.46,1.9-3.63,1.57-1.38,5.73c1.6-0.77,3.22-1.55,4.39-2.11
				c2.25,1.68,4.15,3.09,6.05,4.5c0.67-1.17,1.33-2.33,2-3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1062.15,380.44c-3.33-0.17-7.09-1.37-9.89-0.23c-3.3,1.35-5.87,4.64-8.5,7.34c-0.34,0.35,0.89,2.22,1.39,3.39"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M491.54,87.9c-3.67,0.33-7.33,0.75-11,0.96c-1.49,0.08-3-0.29-4.5-0.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M284,519.46c-1.67,5.17-3.33,10.33-5,15.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M666.58,129.91c2.33,1.61,4.65,3.22,7.04,4.87c-2.64,4.49-6.6,5.31-9.97,4.37c-2.69-0.74-4.74-0.37-7.07,0.26"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M595.06,493.96c-1.67,4.17-3.41,8.31-4.93,12.53c-0.3,0.83,0.27,1.98,0.43,2.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1149.67,487.45c-2.12,5.42-5.6,7.11-11.02,5.55c-1.08-0.31-2.9-0.54-3.4,0.03c-4.14,4.84-8.58,2.97-13.4,0.52
				c-1.7,3.63-7.03,5.74-3.2,10.91"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1021.64,454.45c-0.33,0.67-0.51,1.74-1.03,1.93c-3.16,1.2-5.72,2.24-3.73,6.68c0.46,1.03-2.22,3.46-3.84,5.8
				c-6.17-0.85-12.89-1.77-19.6-2.69c-0.07-0.58-0.14-1.17-0.2-1.75c2.49-2.88,5.06-5.63,9.39-5.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1195.18,444.95c-1.8,1.01-3.45,1.57-3.83,4.46c-0.22,1.73-3.47,3.7-5.72,4.39c-3.38,1.04-6.55,0.01-7.95-3.85
				c4.42-3.91,4.38-4.05-2-7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M852.11,229.92c0.67,4.83,1.39,9.66,1.97,14.51c0.2,1.64,0.03,3.33,0.03,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M322.51,372.94c-10,0.5-20,1-30.01,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1157.17,203.42c2.2,2.25,3.76,4.2,1.19,7.64c-1.26,1.69-0.52,4.86-0.69,7.36"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M326.01,383.94c0.33,5,0.67,10,1,15"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M665.08,285.93c2,0.67,3.96,1.63,6.01,1.93c2.96,0.44,5.99,0.4,8.99,0.57"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M692.08,127.41c-4.67,2.92-9.73,2.01-14.75,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M933.13,311.93c-4.51-3.78-9.92-2.15-15-2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1069.15,492.45c-0.5-0.5-0.91-1.22-1.52-1.46c-2.45-0.97-3.45-5.21-7.56-2.65c-3.6,2.24-2.34,5.22-2.43,8.12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M422.03,274.93c2.83,5,5.67,10,8.5,15"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1092.16,481.7c4.69-2.13,4.74,1.29,4.98,4.25c0.31,4.01,0.45,8.04,0.66,12.07c0.58,0.12,1.16,0.25,1.73,0.37
				c0.88-1.81,1.67-3.67,2.66-5.42c1.06-1.89,2.31-3.68,3.48-5.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1140.67,153.91c-1.87,1.45-3.74,2.9-6.03,4.68c2.01,1.25,3.73,2.33,5.73,3.58c-1.06,1.19-2.17,2.43-3.59,4.03
				c5.06,3.29,10.29,3.95,15.89,2.72"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1171.67,272.93c-2.41,1.34-4.83,2.68-7.29,4.05c1.49,5.82,4.21,10.89,11.76,12.15c3.04,0.5,3.02,9.17-0.01,10.72
				c-2.55,1.3-5.17,3.66-8.46,1.59"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M368.52,395.94c-8.54,0.63-17.08,1.26-25.9,1.92c0.61,3.11,1.01,5.09,1.4,7.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M292.5,177.92c4.67,6.5,9.34,13,14,19.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M975.64,217.42c-2.5,4.25-1.35,8.6,2.96,12.55c1.9,1.74,3.37,3.96,5.04,5.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1001.14,568.46c0,2.01,0,4.01,0,6.04c5.17,1.56,6.18-2.36,8-5.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M730.09,413.45c1.81,4.84,4.02,9.61,2.5,15"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M669.58,721.98c0.33,2.5,0.94,5,0.93,7.5c-0.01,2.66-2.33,4.63-4.31,3.33c-4.19-2.77-5.54-0.29-8,3.75
				c2.5,0.56,4.44,1.01,6.38,1.42c1.66,0.35,3.34,0.65,5,1.01c5.08,1.08,11.09-5.62,10.05-11.01c-0.57-2.95-0.92-5.99-0.97-8.99
				c-0.03-2.02,7.77-6.75,10.93-7.01c4.75-0.39,5.95,3.31,7.47,6.53c0.76,1.61,1.17,3.38,2,5.83c-5.35,4.42-12.64,2.45-18.97,4.15"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M702.58,38.9c5.25,1.75,10.25,3.75,10.5,10.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1028.15,653.47c0.4,3.64,0.8,7.27,1.2,10.91c-0.65,0.28-1.29,0.56-1.94,0.85c-2.25-2.25-4.51-4.5-6.76-6.75"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M602.56,215.42c-5,0.17-10,0.33-15,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M781.6,436.95c3.5,4.83,7,9.67,10.5,14.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M227.49,422.95c1.17-1.83,2.34-3.66,3.59-5.61c-1.82-4.26-5.02-2.02-7.57-1.31c-2.93,0.81-5.69,2.25-8.52,3.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M985.14,197.92c5,3,10,6,15,9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M623.07,72.4c-0.21-3.37-3.2-5.76-6.49-4.97c-3.7,0.88-7.34,1.97-11.01,2.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M817.6,105.41c-3.85-0.18-7.6-2.62-11.57,0.41c-2.98,2.27-3.32,2.56-0.43,6.09"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M609.06,185.92c-4.74,3.11-4.43,8.35-5.5,13"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1032.65,300.93c-2.33,0.33-4.66,0.86-7,0.96c-5.67,0.23-3.92,2.5-1.5,5.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M346.01,67.4c-1.83-1.83-3.39-4.3-5.6-5.28c-1.49-0.66-4.26,0.32-5.76,1.47c-0.8,0.61-0.14,3.15-0.14,4.81"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M291.5,227.92c2.67,0.5,5.38,0.84,7.99,1.55c2.23,0.6,4.35,1.62,6.51,2.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M859.11,411.94c-4.83-0.67-9.67-1.33-14.5-2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M285,385.44c0.17,4.83,0.33,9.67,0.5,14.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M631.57,261.43c-4.83,1-9.67,2-14.5,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1125.16,414.45c0.25,6.08,5.47,7.2,9.93,9.07c-0.01,0.47-0.02,0.94-0.03,1.41c-1.8,0.67-3.6,1.35-5.4,2.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1114.66,461.95c3.53-0.24,7.06-0.47,10.59-0.71c0.18-0.8,0.37-1.61,0.55-2.41c-2.71-1.46-5.43-2.92-8.14-4.38"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1165.17,319.43c2.19,4.91,0.8,7.31-4.5,7.48c-3.35,0.1-6.31-0.46-8.23,3.92c-1.71,3.9-5.69,1.86-8.77,0.6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M486.04,288.43c2.33,4.83,4.67,9.67,7,14.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M568.56,816.49c0.79-0.89,1.59-1.77,3.04-3.4c-7.32-0.47-10.32,5.18-15.04,7.42c-0.79-5.94,1.96-12.62,7-17.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M484.54,599.47c-0.83,2-2.66,4.21-2.3,5.96c0.8,3.86-1.01,5.76-3.7,7.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M721.59,273.93c-0.5,4.83-1,9.67-1.5,14.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1109.16,318.43c-6.76,1.61-7.64-4.5-10.5-8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1020.64,453.95c-2.33-1.67-4.57-3.5-7.05-4.92c-1.07-0.62-3.4-1.02-3.83-0.48c-3.02,3.81-6.42,0.98-9.63,0.9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M839.61,228.92c0.5,4.83,1,9.67,1.5,14.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M647.07,357.44c-6.6,8.78-16.18,13.43-25.46,18.58c-3.61,2-6.62,5.06-10.09,7.36c-1.58,1.05-3.62,1.4-5.46,2.06"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1077.66,272.93c-3.89-1.39-6.89-0.62-8.63,3.83c0.97,1.29,2.05,2.73,3.13,4.17"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M20.45,395.44c-4.83,0.83-9.67,1.67-14.5,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1195.18,273.93c-2.16-0.54-4.31-1.08-6.52-1.63c-0.84-5.76-3.64-7.32-10-5.94c-2.58,0.56-5.32,0.4-7.99,0.57"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M955.63,620.97c-3.67,2.83-7.46,5.52-10.97,8.54c-4.47,3.85-8.68,7.99-13.07,11.92c-0.37,0.33-1.29,0.04-1.96,0.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1134.67,631.47c1.83,1.83,3.41,4.33,5.62,5.19c0.94,0.37,3.21-2.69,4.88-4.19"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M966.13,222.92c-0.11,6.49,4.47,9.73,9,13"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M846.61,396.44c8.7,2.22,17.35,5.1,26.51,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M211.49,311.43c-4.67,0.83-9.34,1.67-14,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M644.57,211.42c-1.5,3-2.95,6.03-4.52,8.99c-1.25,2.38-2.65,4.68-3.98,7.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1019.64,294.93c-2.17-0.17-4.42-0.01-6.48-0.58c-3.03-0.83-4.86-0.34-5.01,3.08c-0.13,3.11,0.87,5.39,4.49,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1054.65,45.9c6.33,0.67,12.66,1.43,19.01,1.93c1.63,0.13,3.33-0.6,5-0.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M966.63,79.4c4.67,0,9.34,0,14,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1159.17,179.92c-0.33,4.75-0.67,9.5-1,14.25"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1002.14,763.49c1.07-4.58-2.2-2.62-3.44-1.9c-3.51,2.03-6.75,4.53-10.07,6.83c-0.79-5.93,2.09-12.91,7.01-16.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M864.61,30.9c-2.33,0.5-4.65,1.29-7.01,1.43c-3.99,0.24-8,0.07-12,0.07"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M671.58,201.42c2-3.85,3.25-7.93,9.59-5.76c3.68,1.26,8.25-0.07,12.41-0.24"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M125.47,383.44c-2.17,1-4.64,1.62-6.43,3.09c-2.87,2.35-5.49,4.74-9.57,3.91"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1007.14,437.45c-1.5-3.67-2.78-3.81-4.55-0.02c-3.16,6.78-4.5,13.68-2.45,21.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M554.05,107.91c-2-0.5-4-1.36-6-1.37c-1.33-0.01-3.8,1.17-3.82,1.88c-0.18,5.62-5.04,4.91-8.18,6.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M697.58,503.96c-3.83,0.5-7.67,1.02-11.5,1.49c-0.82,0.1-1.67,0.01-2.5,0.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M832.61,438.45c-4.33,4-8.67,8-13,12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M937.63,150.41c-4.67,0.17-9.34,0.33-14,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M447.53,294.93c2.17,6,4.33,12,6.5,18"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M342.01,399.44c-3.17,0.17-6.35,0.2-9.5,0.52c-9.18,0.93-18.34,1.98-27.51,2.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M675.08,503.96c-4.73,1.32-8.26,6.36-14,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M504.04,215.42c-2,1-3.97,2.06-6.01,2.99c-3.06,1.39-4.51,3.63-3.99,7.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1128.67,264.93c0.83,0.5,1.86,0.84,2.45,1.54c0.85,1.01,1.38,2.29,2.05,3.46c-1.17,0.67-2.28,1.46-3.52,1.96
				c-0.92,0.37-1.98,0.37-2.98,0.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M857.61,147.41c-1.83,4.33-3.67,8.67-5.5,13"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M645.07,515.96c-4.5-0.83-9-1.67-13.5-2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M907.62,301.43c-0.67,4.17-1.48,8.32-1.95,12.51c-0.39,3.48-0.38,7-0.55,10.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1195.18,556.96c-1.5-0.13-3.01-0.26-4.04-0.35c-1.06,1.91-2.07,3.74-3.2,5.79c-1.33-1-2.3-1.72-3.26-2.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1008.14,162.41c0.17,4.5,0.33,9,0.5,13.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M918.62,161.91c-4.5,0.33-9,0.67-13.5,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1024.64,176.92c-1.17-0.17-2.33-0.44-3.5-0.48c-5.67-0.21-11.55,0.55-16.96-0.72c-9.63-2.25-15.36,1.85-16.55,13.2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M654.57,103.41c-0.83,0-2.03-0.36-2.44,0.06c-4.31,4.36-10.42,3.87-15.6,5.85c-1.94,0.75-4.63,1.19-6.39,0.43
				c-3.25-1.42-6.07-3.83-9.07-5.83"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1193.18,476.45c-1.17-1.17-2.17-2.58-3.53-3.46c-5.1-3.28-8.39-1.58-8.97,4.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M181.48,128.41c0.5,0,1.07,0.16,1.49-0.03c2.19-0.94,4.35-1.97,6.51-2.97c-1.67-1.5-3.11-3.7-5.06-4.32
				c-2.49-0.79-5.52-0.54-5.94,3.32"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M524.05,208.92c-4.5,0-9,0-13.5,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1067.65,662.48c0.17,1.67-0.07,3.51,0.58,4.96c1.59,3.55,3.27,3.38,7.42-0.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1163.67,191.42c-1.17,4.5-2.33,9-3.5,13.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1122.66,406.44c0.33,0,0.72,0.11,0.99-0.02c6.18-2.8,12.33-1.77,18.53-0.05c0.53,0.15,1.32-0.6,1.98-0.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1021.64,159.41c-2.83-0.33-6.56-1.95-8.31-0.72c-3.27,2.3-6.67,2.61-10.2,3.22"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1184.18,511.96c-2-0.67-4.2-1-5.96-2.07c-3.16-1.93-6.05-2.3-9.05,0.07"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M436.53,331.43c-4.17,1-8.33,2-12.5,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M701.08,293.93c1.17-0.17,2.65-0.88,3.45-0.41c6.54,3.77,13.65,6.93,17.03,14.43c0.19,0.42,0.02,0.99,0.02,1.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1044.65,297.93c-3.64-0.4-7.27-0.81-10.91-1.21c-0.25,0.6-0.51,1.2-0.76,1.8c1.22,0.8,2.45,1.61,3.67,2.41"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M610.06,529.46c-1,4.33-2,8.67-3,13"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1047.15,291.93c-1.83-2.17-3.38-4.73-5.6-6.36c-1.46-1.07-4.09-1.49-5.86-0.99c-3.03,0.86-2.96,3.37-1.54,5.85"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1157.67,261.93c5.17-0.76,10.25-1.75,15.54-0.11c2.64,0.82,4.35,1.59,3.97,4.61"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M635.57,71.4c-2.01-5.15-3.98-5.42-11-1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M318.01,184.92c-6.34-7.4-14.19-5.32-22-3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M932.13,315.93c4.33,0,8.67,0,13,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1119.16,472.45c0.27,4.59,0.41,8.76-6.54,7.18c-1.72-0.39-3.96,1.48-5.96,2.32"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M614.57,526.96c-4.33,0-8.67,0-13,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1078.16,690.98c3.02,2.31,6.03,4.62,10.36,7.94c-0.6-3.25-0.99-5.35-1.41-7.67c2.24,0.25,4.39,0.67,6.56,0.69
				c5.87,0.08,7.8-3.17,4.96-8.45c-1.43-2.66-1.9-4.14,2.01-4.7c1.49-0.21,2.69-2.48,4.03-3.82"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M301.5,158.41c-4.33,0.5-8.67,1-13,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1079.66,46.4c0-1.17,0.14-3.33-0.02-3.35c-2.64-0.38-5.37-0.85-7.96-0.48c-1.14,0.16-2.02,2.16-3.02,3.33"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M891.62,176.92c-4.33-0.33-8.67-0.67-13-1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1190.68,750.99c-4.33-0.33-8.67-0.67-13-1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M956.63,388.44c-2.98,0.33-5.92,1.24-6.29,4.52c-0.14,1.18,2.12,2.64,3.29,3.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M583.06,473.95c-3.17,3.33-6.33,6.67-9.5,10"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1170.17,302.93c-2.28,5.42-4.89,6.34-10,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1195.18,305.93c-2.83,0.5-5.7,0.87-8.49,1.56c-1.26,0.31-2.36,1.26-3.51,1.94c-2.04,1.21-4.07,2.44-6.68,4.01
				c-0.9,5.03-3.24,7.13-9.82,7.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M286.5,442.95c0.5,2.17,1.06,4.32,1.49,6.5c0.73,3.74,1.87,6.4,6.56,4.08c1.74-0.86,3.96-0.75,5.96-1.09"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M298.5,411.44c0.42,4.16,0.83,8.31,1.19,11.89c-3.88,0.21-7.53,0.41-11.19,0.61"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M543.55,22.4c-4,2-8,4-12,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M887.12,720.48c1.33,4.17,2.67,8.33,4,12.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1001.14,409.94c-1.84,4.1-2.89,8.23-0.5,12.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M864.11,503.96c-1.5,1.67-2.91,3.43-4.52,4.98c-3.18,3.06-5.71,6.14-6.23,11c-0.28,2.65-3.09,5.03-4.75,7.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M860.61,406.94c-1.33,8.17-2.67,16.34-4,24.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1190.68,403.94c-1.95,0.39-3.9,0.77-5.84,1.16c0.01,0.64,0.01,1.29,0.02,1.93c1.94,0.97,3.88,1.94,5.82,2.91"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M745.59,116.91c-3,1.5-5.86,3.51-9.04,4.36c-3.51,0.94-7.3,0.8-10.97,1.14"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M904.62,296.93c-1.33,7.5-2.67,15-4,22.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1053.15,476.95c-0.67,1.67-1.33,3.33-2,5c2,0.5,4.01,1.39,6,1.37c1.51-0.02,3-1.2,4.5-1.87"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1001.14,160.41c0.5,1.67,1.31,3.31,1.43,5.01c0.23,3.15,0.07,6.33,0.07,9.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M337.01,487.95c-3.67-2.5-7.33-5-11-7.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M318.01,497.96c-9.26,6.15-8.75,3.34-4.56,16.02c0.89,2.7,0.94,5.67,1.6,8.47c0.48,2.04,1.3,4.01,1.96,6.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1154.67,254.93c2.5,0.5,5.12,0.7,7.46,1.61c1.55,0.6,2.71,2.23,4.04,3.39"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1007.64,422.45c-1.17,4.17-2.33,8.33-3.5,12.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M413.03,88.4c3.65,3,7.81,4.22,12.5,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M960.63,378.94c-2.5,0.67-5.12,1.06-7.48,2.06c-5.23,2.22-5.33,3.5-1.02,7.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M563.06,529.46c7.33,0.5,14.67,1,22,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M519.05,200.42c4,1.5,8,3,12,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M726.59,123.41c-0.5,1.5-1,3-1.5,4.5c-2.5-0.83-5-1.67-7.5-2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M903.62,494.46c-0.83,4-1.67,8-2.5,12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M306.01,406.44c0,1.83,0,3.67,0,5.5c-0.01,4.96,0.44,10.03-3.5,14"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M446.53,334.44c-5.67-0.17-11.34-0.33-17-0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1155.67,199.92c2.53,3.5-1.13,5.99-1.68,8.97c-0.46,2.49-2.49,4.7-3.82,7.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1171.67,547.46c0.83-1,2.54-2.19,2.36-2.97c-1.48-6.63-8-8.79-14.39-8.5c-4.57,0.2-7.33,3.71-11.48,3.46
				c-1.84-0.11-3.67-0.32-5.5-0.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M615.57,527.46c-0.83,4-1.67,8-2.5,12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M673.58,536.96c-4,0.83-8,1.67-12,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M354.01,384.94c0.17,5.33,0.33,10.67,0.5,16"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1110.66,381.94c2,0,4.04-0.24,5.99,0.06c3.02,0.46,3.46,2.1,1.52,4.46c-0.91,1.11-1.68,2.32-2.51,3.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M884.12,719.48c-1.33,4-2.67,8-4,12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M990.64,686.98c-2.17,2-4.22,4.15-6.54,5.95c-1.44,1.12-3.29,1.72-4.96,2.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M992.64,683.48c-3.27,5.32,2.05,8.67,3.17,12.96c0.5,1.93,2.57,3.79,4.39,4.95c2.83,1.81,2.95,4.33,2.94,7.1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M370.52,272.43c-5.01,3.43-11.38,5.32-13.5,12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1155.17,234.42c3.53-3.19,7.27-1.82,11.94-1.23c-1.64,2.17-2.58,3.43-3.53,4.69c0.81,1.17,1.57,2.27,2.68,3.89
				c-1.72,0.99-3.15,1.82-4.59,2.65"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M533.55,345.44c1.5,1.67,2.75,3.73,4.57,4.89c1.85,1.17,4.26,1.44,6.43,2.11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M840.61,148.41c1.83,1.5,3.64,3.04,5.51,4.49c1.79,1.39,3.66,2.68,5.49,4.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M729.09,44.4c-0.32-4.7,3.6-5.31,6.54-5.72c1.27-0.18,2.86,2.85,4.5,4.17c0.95,0.77,2.27,1.4,3.47,1.48
				c2.99,0.21,6.05-0.27,8.98,0.17c2.74,0.41,4.04,2.49,3.52,5.4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1091.66,505.96c-4,1.67-8,3.33-12,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1118.16,513.96c-1.67,1.17-3.43,2.22-4.97,3.54c-1.16,0.98-3.04,2.57-2.82,3.41c0.45,1.69,2.22,4.39,3.27,4.31
				c3.22-0.24,3.75,2.49,5.52,3.75"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1038.65,523.46c2.28,0.9,4.6,2.35,2.79,4.85c-0.76,1.06-3.79,0.48-5.8,0.65"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M636.57,419.45c-5.17,2.17-10.34,4.33-15.5,6.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1103.66,424.45c-0.05,2.75,1.22,3.67,4,3.61c1.99-0.04,4,0.89,6,1.39"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1183.18,555.46c0.19,1.16,0.37,2.33,0.56,3.47c-1.72,1.03-3.4,2.02-6.12,3.65c-0.7-2.58-1.32-4.85-1.94-7.12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M934.63,421.95c-0.83,2.33-1.67,4.67-2.5,7c2.5,0.33,5,0.67,7.5,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M498.04,194.42c2,3.83,4,7.67,6,11.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1053.65,314.93c-0.83-0.83-1.55-2.17-2.52-2.4c-2.67-0.63-4.99-0.54-7.27-3.36c-2.14-2.65-5.87,0.07-8.75,1.69
				c-2.13,1.2-4.6,2.21-6.97,2.36c-1.09,0.07-2.32-2.12-3.49-3.29c1-1,2-2,3-3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1006.64,766.49c-3.83,1.5-7.67,3-11.5,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M938.63,239.42c-7.67,0-15.34,0-23,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1089.66,379.44c-4.59-5.25-6.91-5.74-12-2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1081.16,84.4c-3.83,0-7.67,0-11.5,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M853.61,248.92c-3.5,0.17-7.01,0.25-10.5,0.53c-2.01,0.16-4.87,1.64-5.85,0.79c-3.9-3.37-7.7-1.87-11.65-0.82"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1140.17,309.43c-0.83,0.5-1.81,0.85-2.48,1.53c-3.09,3.14-5.92,3.42-8.53-0.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M698.58,507.46c-0.33-4-0.55-8.02-1.03-12c-0.52-4.28-3.19-4.31-6.48-3.02c-2.8,1.09-5.66,2.02-8.49,3.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1158.17,231.92c-1.68-3.26-7.22-4.87-10.49-2.98c-1.24,0.72-2.34,1.65-3.51,2.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1176.17,69.9c-0.67-2-0.98-5.47-2.06-5.73c-3.63-0.87-7.34-0.03-9.94,3.23"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M272.5,302.43c-3.83-0.17-7.67-0.33-11.5-0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1005.14,380.44c2.71,5.11-0.44,7.28-2.8,8.24c-1.59,3.77-2.26,6.58-3.93,8.56c-0.5,0.6-3.77-1.14-5.77-1.81"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M259.5,300.93c-4.83-1.67-9.67-3.33-14.5-5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M986.64,666.48c-2.87,2.02-5.75,4.04-10.03,7.04c-0.22-3.28-0.34-5.16-0.47-7.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M686.08,388.44c-0.33,2.67-0.27,5.47-1.1,7.97c-1.1,3.3-2.9,6.37-4.4,9.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M608.56,97.41c1.67,6.5,1.67,6.5,9.5,6.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M289.5,431.45c3.83,0.17,7.67,0.33,11.5,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M870.11,418.45c-3.83,0-7.67,0-11.5,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M938.13,65.4c-3.5,1.17-7,2.33-10.5,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M31.95,706.98c3.83,3.17,7.67,6.33,11.5,9.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M460.54,386.44c-4.25-1.25-6.77,0.8-9.65,3.86c-3.54,3.76-8.19,6.48-12.35,9.64"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M763.09,65.9c-5.02-3.48-7.41-3.37-10.5,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M286,422.95c-3.83-0.17-7.67-0.33-11.5-0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M226.49,467.45c5.67,0.33,11.34,0.67,17,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1016.64,310.93c-0.33,1.17-1.15,3.24-0.93,3.36c2.04,1.07,4.26,1.81,6.43,2.64"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M994.64,342.44c-1.17,0.33-2.34,0.63-3.5,1.01c-5.38,1.73-6.25,3.31-5,9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1151.17,568.96c-1.02,4.22-2.77,7.39-8,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1056.65,151.91c3.33,1.33,6.67,2.67,10,4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M890.62,170.92c-3.67,0.67-7.33,1.33-11,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1162.67,553.46c0.58,1.81,1.16,3.61,2.11,6.58c-2.5-0.24-4.3-0.41-6.11-0.58"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M574.56,818.5c-3.67,1.33-7.33,2.67-11,4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1003.64,336.44c-1.83,0.17-3.74,0.07-5.48,0.58c-1.61,0.47-3.02,1.59-4.52,2.42c1.5,1.33,2.93,2.76,4.54,3.95
				c0.62,0.46,1.63,0.38,2.47,0.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1080.16,330.43c0.33,0.5,0.66,1,1,1.5c3.98,5.83,3.51,7.39-3,10.01c-0.69,0.28-1.34,0.66-2,0.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1024.64,595.97c0.36,1.49,0.73,2.99,1.21,4.96c3.75-2.15,6.93-4.36,5.79-9.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M159.48,116.41c-0.83,0.17-1.95,0.76-2.45,0.43c-2.42-1.61-4.7-2.43-7.59-1.01c-1.07,0.53-2.64,0.19-3.96,0.06
				c-2.67-0.26-5.38-1.09-7.99-0.82c-1.75,0.18-3.35,1.84-5.02,2.84c1.67,0.83,3.33,1.67,5,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M588.56,521.46c3.67,0,7.33,0,11,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M558.05,517.46c6.33,1.67,12.67,3.33,19,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1027.65,290.93c-3.17-0.33-6.34-0.88-9.5-0.83c-0.68,0.01-1.34,2.16-2,3.33"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M693.58,511.96c0.67,3.67,1.33,7.33,2,11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1043.65,620.47c1.02,3.5,0.34,8.04,5.5,9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1126.16,639.47c2.09,2.44,0.27,5.2-1.44,4.75c-6.02-1.57-6.84,11.23-14.07,4.75c-0.18-0.16-0.33-0.33-0.5-0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1006.14,655.48c-2,3.17-4,6.33-6,9.5c-1.17,1.85-2.33,1-3.5,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M823.61,464.45c-3.5,2.83-7,5.67-10.5,8.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M983.14,458.45c-1.5,0.67-3.13,1.14-4.47,2.05c-1.86,1.27-2.65,3.7-6.18,2.35c-1.42-0.55-4.52,3.27-7.08,5.29
				c-1.36-0.87-2.81-1.78-4.43-2.81c-1.58,1.02-3.09,2.4-4.87,3.04c-1.68,0.6-3.63,0.43-5.47,0.59c0.17-1.83,0.33-3.67,0.5-5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M782.6,432.95c-0.51,4.97-5.53,3.38-8,6c0.26,1.19,0.63,2.85,1,4.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1066.65,327.43c3.33,0.83,6.66,2.28,10,2.35c4.51,0.09,9.04-0.9,14.24-1.5c-2.01-3.01-3.77-5.35-5.16-7.9
				c-0.49-0.9-0.08-2.29-0.08-3.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M669.58,461.45c-3.67-0.5-7.33-1-11-1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M415.03,323.43c0.67,3.5,1.33,7,2,10.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M774.6,443.95c-3.5,1.17-7,2.33-10.5,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M129.47,166.91c-2.3-3.03-1.83-8.21-7-9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M613.07,574.47c-0.5,3.5-1,7-1.5,10.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1155.67,267.93c-4.57-3.17-7.33-2.82-8,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M929.13,667.98c0.81-0.36,1.63-0.71,2.44-1.07c-0.29-0.41-0.59-0.82-0.88-1.24c-3.35-0.57-6.71-1.13-10.06-1.7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M981.14,760.49c-3.17,1.83-6.33,3.67-9.5,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M616.07,266.43c-1.59,3.28-4.39,3.38-7.51,3.04c-1.98-0.21-4-0.04-6-0.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1005.64,689.98c1.52,1.64,3.04,3.28,5.49,5.92c1.09-3.22,1.8-5.32,2.51-7.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M835.61,164.91c-0.07-4.53-4.23-3.22-6.55-4.39c-1.2-0.6-2.95-0.11-4.45-0.11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1160.17,308.43c-2.83,2.68-4.73,7.2-10,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1155.67,435.95c3.5-0.83,6.97-1.99,10.52-2.34c1.4-0.14,2.98,1.51,4.48,2.34c-1.67,1-3.33,2-5,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1158.67,590.97c2.93,0.57,5.86,1.14,8.8,1.72c0.16-0.64,0.33-1.28,0.49-1.93c-2.69-2.95-6.4-3.33-10.29-3.29"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M953.63,401.94c-2,0.17-4.03,0.64-6,0.45c-7.84-0.79-10.12,2.04-7.88,8.48c-2.12,2.67-4.88,4.67-5.38,7.13
				c-0.69,3.41,2.54,3.67,5.26,3.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1009.14,302.93c-1.99,2.89-1.25,4.81,1.99,6.03c1.24,0.47,2.35,1.3,3.51,1.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M371.02,470.95c5.5-0.78,7.44,4.71,11.48,6.54c1.69,0.76,3.19,1.96,4.77,2.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M689.08,475.95c1.17,3.5,2.33,7,3.5,10.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1112.16,277.43c1.59,1.73,3.18,3.46,4.19,4.55c3.36-2.24,5.78-3.85,8.2-5.47c0.6,0.24,1.2,0.49,1.81,0.73
				c-0.4-2.27-0.8-4.55-1.2-6.82"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1078.16,603.22c3.05,3.36,6.09,6.72,9.06,9.99c2.74-1.47,5.04-2.71,7.41-3.99c-0.71-4.66,1.3-7.53,6.04-8.24
				c1.66-0.25,3.33-0.35,5-0.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M318.01,67.9c-7.35-0.96-9.82,1.36-8,7.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1061.65,285.43c-2.83-4.04-5.67-2.7-8.24,0.21c-3.69-1.91-7.01-5.83-10.76-0.71"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M631.07,425.95c-3.35,1.46-6.44,4.28-10.5,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1085.66,413.95c5.34,3.64,5.63,6.04,0.5,10"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1124.16,235.92c2,0.5,4.04,0.88,5.99,1.54c1.25,0.43,2.35,1.29,3.52,1.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1040.65,695.48c-7.81,2.08-1.25,7.42-2.42,11.03c-0.37,1.16,0.25,2.64,0.42,3.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M594.56,507.96c-2.29,1.45-4.58,2.9-6.88,4.35c0.06,0.68,0.13,1.37,0.19,2.05c3.4,0.37,6.79,0.83,10.19,1.06
				c2.49,0.17,5,0.03,7.5,0.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M926.63,107.41c-3.5,1.17-7,2.33-10.5,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1083.16,623.47c-2.01-1.92-2.01-1.92-4.59,0.17c2.07,5.73,4.08,11.28,6.09,16.83"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M685.58,514.46c3.5-1.67,7.13-3.12,10.46-5.07c2.71-1.58,5.05-3.61,6.54,1.07c1.01,3.16,2,6.33,3,9.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1060.15,509.96c-1.67-0.67-3.33-1.33-5-2c-0.33,1.67-0.67,3.33-1,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1016.64,420.95c-2.33,0.17-4.68,0.6-7,0.45c-7.14-0.46-7.95-0.04-10.51,6.55c-0.65,1.67-1.33,3.33-2,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1105.16,512.96c0.17,1.5-0.11,3.28,0.62,4.43c0.83,1.32,2.55,2.07,3.88,3.07"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1075.65,289.43c5.14-3.94,9.6-1.71,14.02,1.47c1.19,0.86,2.65,1.36,3.98,2.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1176.67,616.47c-1.5,1.17-4.15,2.22-4.29,3.52c-0.42,4.13-2.93,5.05-6.21,5.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M542.55,217.92c-0.5-0.67-0.99-1.9-1.5-1.91c-2.83-0.03-5.67,0.24-8.5,0.41"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1086.66,200.92c-3.33-0.17-6.67-0.33-10-0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M993.64,95.41c-1.67,0.17-3.38,0.15-4.99,0.54c-3.38,0.81-2.25,2.39-0.51,3.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M845.61,229.92c1,4.83,2,9.67,3,14.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1156.17,289.43c-0.66,7.56-4.86,1.88-7.5,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1168.67,608.47c-0.48,4.06,0.44,7.56,4,10"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M916.62,244.92c3.33,0.33,6.67,0.67,10,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M735.59,66.9c-3.05-3.18,1.25-3.7,2-5.5c1.17,0.67,2.9,1.06,3.38,2.06c1.05,2.15,1.45,4.61,2.12,6.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M549.05,812.49c-3.17,1.83-6.33,3.67-9.5,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M695.08,174.42c0.83,3.33,1.67,6.67,2.5,10"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M240.49,472.95c-3.33-0.17-6.67-0.33-10-0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1108.66,401.44c0.82,6.1-3.97,7.22-8,9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M224.49,546.46c-3.17,1-6.33,2-9.5,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1040.15,589.47c1.25,3.18,2.5,6.35,3.75,9.53c0.81-0.2,1.63-0.41,2.44-0.61c-0.4-3.81-0.8-7.61-1.2-11.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M399.52,79.4c6.11,1.19,12.48,1.63,17.5,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M357.52,400.44c-1.67,0.83-4.27,1.27-4.82,2.58c-1.52,3.61-4.37,3.21-7.18,3.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1095.16,407.44c-1.53-8.12-6.15-9.78-13.55-5.08c-1.14,0.73-2.95,0.41-4.45,0.58"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M452.53,340.94c-3.33,0-6.67,0-10,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M421.03,399.44c0,3.33,0,6.67,0,10"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1110.66,262.43c2.52,0.36,5.04,0.73,7.94,1.14c-3.83,5.78-7.91,3.36-11.95,1.36"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M666.08,710.48c1.76,3.11,3.53,6.22,5.95,10.5c-4.25,0-7.59,0-11.44,0c0.97,3.14,3.22,5.57-0.51,7c0.5,1.17,1,2.33,1.5,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1137.67,449.95c3-0.67,5.98-1.71,9.01-1.89c2.64-0.16,6.76-1.8,5.34,4.47c-0.52,2.28,2.93,5.45,5.01,8.97
				c-1.95,0.2-3.15,0.33-4.35,0.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1027.65,270.93c-1.25,1.07-2.5,2.14-4,3.42c4.3,3.78-0.82,13.31,9,13.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1155.67,470.95c1,0,2.13,0.31,2.98-0.05c4.55-1.96,9.03-0.04,13.52-0.11c2.02-0.03,4-1.84,6-2.84c-0.5-0.83-1-1.67-1.5-2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M915.12,234.42c-0.05,7.02-0.56,14.14,4.5,20"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1073.15,479.45c-2.09,4.67-6.7,6.34-11,4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M582.06,50.9c-2.17,3.17-4.33,6.33-6.5,9.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1091.16,482.95c-1.78,1.25-3.56,2.49-5.35,3.74c-3.42-1.23-3.87-3.39-2.65-6.24"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M574.56,48.4c-2.33,3-4.67,6-7,9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1179.68,502.96c-3.17-1.29-6.33,3.6-9.5,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1011.14,406.94c1.44,1.58,5.83,0.64,4.24,5.09c-0.14,0.39,3.06,1.97,4.88,3.11c-0.84,1.7-1.73,3.51-2.62,5.31"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1094.66,293.43c1,0.83,1.96,1.73,3.01,2.49c2.14,1.54,4.32,3.01,6.49,4.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1165.67,516.96c2.5,2.73,5.05,5.44,2.5,9.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1102.16,400.94c-0.83,4.5-1.67,9-2.5,13.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M997.64,398.44c0.83,1.5,1.31,3.77,2.57,4.35c3.17,1.47,3.51,3.37,2.18,6.15"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M178.98,120.41c-2.72-4.55-6.3-1.03-9.5-1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1141.67,538.46c-1.33,0.33-3.37,0.24-3.88,1.07c-2.27,3.73-5.05,6.45-8.99,8.66c-2.68,1.5-0.63,3.83,1.87,4.77"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M236.49,550.46c-4.1-0.59-7.71,0.64-11,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1183.18,598.47c0.64,3.05,2.71,4.27,5.48,3.43c3.77-1.15,1.93-4.05,1.52-6.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1067.15,580.47c-1.07,3.99-0.53,7.23,3.4,9.66c0.9,0.56,0.56,3.1-0.16,5.11c2.25,2.08,4.5,4.16,6.76,6.23"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M859.61,813.49c6.33,0,12.67,0,19,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M587.06,526.96c4.33,0.33,8.67,0.67,13,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1124.16,657.48c-1.66,1.5-3.33,3.01-5.29,4.78c-1.2-0.9-2.45-1.84-3.71-2.78"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1099.66,645.47c-2.33,0.67-4.65,1.43-7.01,1.97c-2.51,0.58-5.07,0.96-7.61,1.43c-0.22,0.61-0.43,1.21-0.65,1.82
				c2.75,1.59,5.51,3.19,8.26,4.78"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1176.67,267.43c0.2,1.16,0.4,2.32,0.68,3.91c-2.49,0.2-4.84,0.4-7.18,0.59"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M557.55,508.96c3,0.67,6,1.33,9,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M376.77,65.9c-2.08-0.17-4.17-0.46-6.25-0.46c-2,0-4,0.3-6,0.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M725.59,172.42c-3-0.5-6-1-9-1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M903.62,170.92c-3,0-6,0-9,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1084.16,319.43c-6.76,2.37-13.17,3.08-19.05-2.45c-4.47-4.21-9.58-1.53-14.46-0.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1159.67,608.47c-4.29,5.63-7.11,5.89-11,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M460.54,329.93c3.5,0.17,7,0.33,10.5,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1122.66,571.96c-5.75,1.7-6.37,3.29-3.5,9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1072.65,482.95c3,4.38,6,0.1,9,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M441.53,322.43c0.17,9.33,0.33,18.67,0.5,28"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M455.53,257.93c-2.99-1.37-5.97-2.74-9.82-4.51c-1.4,2.51-2.79,5.01-4.18,7.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M591.06,259.43c7.45-2.72,7.74-2.49,9.05,4.99c0.54,3.05,1.62,6.01,2.59,9.51c3.18,0,6.52,0,9.86,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M346.51,68.4c-0.05,4.54,5.09,2.35,6.5,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1105.66,471.45c5.57,3.18-0.17,4.74-1,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1187.18,614.47c0.69,5.72-1.34,9-7.62,10.83c-0.47-4.05-0.93-7.94-1.39-11.83"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1131.67,656.98c-1.33,1-2.67,2-4,3c-1-1.83-2-3.67-3-5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M168.98,118.41c-2.39-2.34-4.6-5.3-8.5-2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M346.01,71.4c-3.57,2.21-1.86,8.03-6.5,9.5c1,1.5,2,3,3,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1182.18,512.46c-1,2.33-1.73,4.84-3.08,6.95c-1.31,2.06-3.02,4.04-4.99,5.46c-1.96,1.42-4.45,2.1-7.14,3.29
				c-0.74,1.66-1.77,3.98-2.8,6.3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M344.01,407.94c-2.83,0.33-5.67,0.67-8.5,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1165.17,281.93c-1.82,1.07-5.1-0.06-4.27,4.55c0.66,3.61-3.29,1.98-5.23,1.84c-1.37-0.1-2.68-1.22-4.01-1.89"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M428.53,59.4c2.68-2.7-0.05-5.05-1.53-5.21c-1.87-0.21-4.04,1.59-5.92,2.79c-0.5,0.32-0.38,1.59-0.55,2.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M274.5,158.41c-2.83,0.17-5.67,0.33-8.5,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1194.18,508.96c-3.19,0.74-6.38,1.47-9.87,2.28c1.09,1.79,1.98,3.26,2.87,4.72"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M930.13,669.48c-4.61-1.85-6.78,2.38-10,4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M644.07,261.43c-2.83,0.33-5.67,0.67-8.5,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1103.66,415.95c-3.57-1.91-6.92-1.21-8.8,2.11c-0.74,1.3,0.19,4.79,1.46,5.65c1.48,1.01,4.17,0.24,6.34,0.24"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M999.14,561.96c-1.83,2-3.75,3.93-5.44,6.05c-0.41,0.51-0.17,1.65,0.03,2.43c0.04,0.16,1.45,0.3,1.88-0.02
				c2.57-1.91,5.03-3.96,7.53-5.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M809.1,8.9c1.29,3.41,0.13,4.87-3.5,4.52c-1.32-0.13-2.67-0.02-4-0.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M998.14,684.48c1.42,3.91-0.86,5.7-4,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M256,169.41c-2.5,1.17-5,2.33-7.5,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1182.68,601.97c-2.47,2.06-4.77,4.53-8.54,2.58c-0.52-0.27-1.74,0.43-2.44,0.94c-4.92,3.6-9.92,3.46-15.02,0.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M457.53,321.43c0.17,3,0.33,6,0.5,9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M627.57,95.41c-2,2.17-4,4.33-6,6.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M473.54,322.43c-0.73,3.15-1.46,6.31-2.31,10c-1.35,0-3.02,0-4.69,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1173.17,471.95c-0.42,2.62-0.85,5.24-1.31,8.11c-1.36,1.02-3.28,2.46-5.19,3.89"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M582.06,83.4c-1.88,2.11-5.37,4-1,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1102.66,63.9c-3.27-2.72-5.52,0.03-8,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M984.14,752.49c-1.26,4.24-3.51,8.33-2,13"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1086.66,616.47c-2.67,0.17-5.33,0.33-8,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1191.68,747.99c-2.67,0.33-5.33,0.67-8,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M552.05,804.49c-1.26,4.24-3.51,8.33-2,13"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M683.58,740.49c-0.67,1.17-1.33,2.33-2,3.5c0.41-0.03,0.83-0.07,1.24-0.1c0.25,2.87,0.51,5.73,0.76,8.6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M555.05,106.91c2.12-3.17,4.57-3.1,6.92-0.43c0.42,0.48-0.25,1.93-0.42,2.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M303,403.94c-4.75-0.17-9.5-0.33-14.25-0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.17,472.95c1.37,1.12,2.74,2.24,4.61,3.78c-2.67,2.45-5.14,4.71-7.61,6.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M439.03,197.92c-1.67,2.5-3.59,4.87-4.95,7.53c-2.4,4.7,2.64,7.74,3.08,11.96c0.27,2.58,1.86,5.01,2.87,7.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1014.14,435.45c-2.6-1.18-5.13-3.64-8-0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1106.66,486.45c-0.74-2.94-1.48-5.88-2.28-9.02c-3.31,2.15-5.52,3.59-7.72,5.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1143.67,153.91c1.67,3.83,3.33,7.67,5,11.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1082.66,264.43c-3.33,0.5-6.71,0.8-9.99,1.56c-3.45,0.8-3.61,2.9-1.51,5.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1146.67,486.45c2.17-0.33,4.34-0.66,6.5-1c2-0.32,4-0.7,6-0.99c6.06-0.89,10.55,1.16,13,6.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M339.51,432.95c-2.67,0.5-5.33,1-8,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M589.06,507.96c-4.17-0.67-8.33-1.33-12.5-2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1118.66,571.96c-2.18-1.98-4.22-1.55-5.98,0.52c-1.47,1.73-2.74,3.61-5.11,6.77c3.99-0.33,6.79-0.56,9.59-0.78"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M974.64,409.94c0.52,4.56-2.63,6.36-6,8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1005.64,685.48c-1,2.67-2.39,5.26-2.89,8.02c-0.5,2.74-0.11,5.65-0.11,8.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1168.17,502.96c-1.18,2.92-2.93,5.03-6.5,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M615.07,335.94c2.5,0.33,5,0.67,7.5,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1084.16,178.42c-0.91,5.01,3.92,3.76,6,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M711.58,298.93c-2,1.83-4,3.67-6,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1152.67,634.47c0.33,2.5,0.67,5,1,7.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1094.16,428.45c-2.5-0.5-5-1-7.5-1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1087.16,624.47c-0.67,2.5-1.33,5-2,7.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M300.5,495.46c0.77,8.36,1.1,8.67,9.5,9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M326.51,65.4c-2-2.97-4.38-1.19-5.86,0.15c-0.92,0.84-0.47,3.18-0.64,4.85"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M627.57,511.96c1.17,0,2.33,0,3.87,0c-0.49,5.53-0.76,10.54-1.42,15.5c-0.66,5.03-5.14,4.16-8.46,5.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1016.64,470.45c-0.24,1.68-0.48,3.36-0.74,5.14c5.51,3,8.56,2.51,12.24-2.14"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1157.17,461.95c1,0.33,1.98,0.79,3,0.98c4.57,0.85,4.99,1.82,2.5,6.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1159.67,247.92c2.5,1.38,5.01,2.75,7.88,4.33c-1.66,1.56-2.52,2.36-3.38,3.17"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M996.64,662.48c-2.38,2.78-4.76,5.56-8.03,9.38c-1.04-1.63-1.76-2.75-2.48-3.87"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M333.01,64.4c-2.33-0.17-4.67-0.33-7-0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1078.16,503.46c4.21-3.49,7.68-1.56,11,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1123.16,437.45c3.07,5.39-2.37,6.95-4.5,10"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1168.17,593.47c3.41,0.89,3.4,3.88,4,6.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1109.16,449.45c3.44-3.67,13.04-2.1,14.92,2.54c0.16,0.4-0.91,1.3-1.41,1.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M270.5,390.44c-0.33,2.33-0.67,4.67-1,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M556.55,812.49c-2.33,2-4.67,4-7,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M301.5,426.45c0.33,2.83,0.78,5.66,0.95,8.5c0.09,1.48,0.14,3.22-0.52,4.46c-3.56,6.67-2.42,13.42,3.57,19.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1193.68,499.46c-2.83-0.17-6.02-1.22-8.4-0.26c-2.52,1.02-4.27,3.94-6.88,6.56c3.69,1.23,7.16,1.11,6.78,5.2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1036.65,659.48c-2.33-0.67-4.67-1.33-7-2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M720.59,102.41c-2.33,0.33-4.67,0.67-7,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1032.65,254.43c0.33,2.33,0.67,4.67,1,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1177.17,495.96c2.33,1,4.67,2,7,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M785.1,431.45c-4,1.67-8.88-1.17-12.53,2.48c-0.83,0.83-1.98,1.36-2.98,2.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1125.66,253.93c-2.33-0.67-4.67-1.33-7-2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1163.17,634.97c2.78,3.89,2.11,7.06-2,9.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M652.07,506.96c2.33,0.83,4.67,1.67,7,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M988.64,760.49c-2.33,2-4.67,4-7,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1126.16,406.94c2.1,5.03,0.92,6.43-5.5,6.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1182.68,420.95c4.44-1.56,4.99-1.5,9.78,1.16c-0.94,2.58-1.65,5.35,2.72,5.35"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1069.15,284.93c-5.53-1.88-6.73-1.32-7.5,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M838.11,376.94c4.17,1.5,8.33,3,12.5,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M224.49,425.95c-0.33,2.33-0.67,4.67-1,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1042.65,289.43c-0.5,2.33-1,4.67-1.5,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1081.66,423.95c-2.33-0.5-4.67-1-7-1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1085.16,641.47c1.83,1.83,3.67,3.67,5.5,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M420.53,85.9c-1.83,0.33-3.66,0.75-5.5,0.98c-3.16,0.4-6.33,0.68-9.5,1.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1116.66,489.45c-0.5,0.83-1.49,2.35-1.43,2.4c1.57,1.13,3.26,2.1,4.93,3.1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1073.65,407.44c4.44,0.04,9.23-0.59,10.5,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M168.48,155.41c-0.5,4-1.18,7.99-1.42,12.01c-0.12,1.97,0.59,4,0.92,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1122.66,504.96c-2.68-1.37-4.47,0.18-5.94,2.05c-0.55,0.7-0.83,2.62-0.49,2.84c1.67,1.08,3.59,1.78,5.43,2.61"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.67,515.46c2.72,1.91,4.98,1.06,6.37-1.57c0.37-0.71-0.87-2.26-1.37-3.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M658.07,513.46c-2.17-0.5-4.33-1-6.5-1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M357.52,285.43c0,1.33,0.56,3.35-0.1,3.88c-2.81,2.28-1.26,4.12,0.1,6.12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1142.17,573.46c-0.5,2.5-1,5-1.5,7.5c-3.42-0.92-7.62-0.66-8.5-5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M912.62,310.43c-5.5-0.33-11-0.67-16.5-1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1014.14,691.48c1.75,1.91,3.5,3.82,5.83,6.36c1.24-2.49,2.2-4.43,3.17-6.36"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1160.67,209.92c2,1.83,4,3.67,6,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M862.11,392.44c-0.33,2.17-0.67,4.33-1,6.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1081.66,247.42c-1.67,0-3.58-0.51-4.94,0.13c-1.6,0.76-3.53,2.35-3.9,3.91c-0.72,3.07,2.03,2.38,3.83,2.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1046.15,198.42c0.5,1.33,1.08,2.64,1.48,4.01c0.58,1.98,1.02,3.99,1.52,5.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1135.67,225.92c4.22-0.2,9.1-3.62,12,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M913.12,504.46c1,2.17,2,4.33,3,6.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1070.15,152.41c-2.17,0.17-4.33,0.33-6.5,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1144.67,627.97c0.62,4.83,2.76,6.21,8.5,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1159.67,526.96c2.17,0.5,4.33,1,6.5,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1159.67,551.96c2.33,0.33,4.66,0.82,7,0.95c2.16,0.12,5.46,1.16,5.26-2.44c-0.09-1.58-2.76-3.01-4.26-4.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1036.65,262.43c-4.51-2.07-6.68,1.41-8.81,4.15c-0.35,0.45,1.81,2.85,2.81,4.35"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M740.09,439.45c1.5,2,3,4,4.5,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1076.66,280.93c-1.67,1.33-3.33,2.67-5,4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M622.57,525.46c-2,0.5-4,1-6,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M306.51,173.42c1,1.83,2,3.67,3,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1195.18,407.94c-2.58,0.4-5.38,0.48-3.68,4.58c0.18,0.44-2.26,1.83-3.29,2.95c-1.12,1.22-2.03,2.64-3.03,3.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M263.5,437.45c0,3.33,0,6.67,0,10"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M632.57,412.95c-3.51,1.71-4.39,7.8-10,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M702.58,522.46c-2.17,0.17-4.33,0.33-6.5,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1150.67,583.97c-0.83,0.34-1.66,0.68-2.57,1.05c0.35,0.64,0.72,1.29,1.06,1.95c0.51,0.99,1,2,1.5,3
				c-1.17,0.33-2.32,0.73-3.5,0.98c-1.15,0.25-2.33,0.35-3.5,0.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1072.65,498.46c3.78-1.97,6.78-1.14,9,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1024.64,496.46c0.17,2,0.33,4,0.5,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1119.66,462.95c1.1,1.87,2.21,3.74,3.34,5.67c-3.64,3.81-3.64,3.81-6.34,0.83"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M669.58,732.98c2,1,4,2,6,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1172.67,579.97c1.17,1.5,2.33,3,3.5,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1093.16,474.45c0.33,2,0.67,4,1,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M603.56,278.43c2,0,4,0,6,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1125.66,295.43c-0.83,2-1.67,4-2.5,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1116.66,558.96c-1.35,0.09-2.7,0.18-3.71,0.25c0.64,4.22,1.17,7.73,1.71,11.25"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1070.65,627.47c1.33,1.67,2.67,3.33,4,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1066.65,533.96c-2-0.33-4-0.67-6-1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1069.65,493.45c1.91-0.59,3.83-1.17,6.7-2.06c-0.32,2.6-0.51,4.08-0.7,5.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1111.16,374.94c1.59,1.69,4.01,3.02,2.5,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1173.67,425.95c-2.33,2.33-4.67,4.67-7,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1169.67,574.97c2.17,0.67,4.37,1.25,6.45,2.12c0.21,0.09-0.28,1.88-0.45,2.88"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M353.01,406.44c1,2,2,4,3,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.17,629.97c-2.18,2.27-4.34,4.61-8,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1003.14,361.44c-4.09,1.63-7.99,2.5-11.08-3.47c1.68-0.56,3.13-1.04,4.58-1.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1078.66,442.95c-1.78,5.75-4.6,3.65-7.5,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1114.66,298.93c-4.26,2.02-10.05,0.04-13.21,5.38c-0.52,0.88-3.79,0.12-5.79,0.12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1096.16,144.41c0,1.83,0,3.67,0,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M296,385.94c-0.17,1.83-0.33,3.67-0.5,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1188.68,580.97c1.02,1.14,2.05,2.29,2.85,3.18c-2.19,2.52-4.27,4.92-6.35,7.32"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M457.03,334.94c-1.83-0.33-3.67-0.67-5.5-1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M589.56,515.46c-1.33,5.17-2.67,10.33-4,15.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1127.67,433.95c-0.33,1.83-0.67,3.67-1,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M600.56,515.46c0,4,0,8,0,12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M872.62,407.94c-0.01,6.22-4.75,4.03-8,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M953.13,658.48c-1.83-0.5-3.67-1-5.5-1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1166.17,597.47c1.67,0.67,3.33,1.33,5,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M676.58,127.41c-1.83,1.5-3.67,3-5.5,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1095.16,220.92c-2.72-1.13-5.09-0.46-6.32,2.09c-0.27,0.55,1.82,2.24,2.82,3.41"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1126.66,213.42c4.82-0.59,6.37,0.97,5,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1174.67,454.95c2.68,1.29,5.36,2.58,8.04,3.88c-0.17,0.71-0.35,1.42-0.52,2.12c-2.17,0-4.35,0-6.52,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M992.14,397.94c-4.31,1.95-8.52,4.59-13.5,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1100.16,397.94c3.03,3.41,6.26,4.73,10,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M442.53,329.43c1.67,0,3.33,0,5,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1185.68,580.47c1.77-0.5,3.55-1,5.32-1.5c-0.11-0.76-0.22-1.52-0.33-2.28c-1.5,0.09-3,0.19-4.49,0.28"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1178.67,439.45c-0.67,0-1.33,0-2,0c0.06,4.42-3.3,3.72-6,4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1040.65,498.96c-3.23,2.11-2.96,4.7-1,7.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1029.65,495.46c-0.06,3.3,0.83,7.08-4,8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1047.65,413.95c-2,0.17-4,0.33-6,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1141.67,590.47c0.5,1.83,1.98,4.23,1.31,5.39c-1.62,2.8-4.62,2.16-7.31,1.11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M774.6,426.45c-1.33,0.67-2.67,1.33-4,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M329.01,422.45c-1.5,0-3,0-4.5,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M972.13,417.45c1.2,0.62,2.4,1.24,4.16,2.15c-1.52,1.78-2.84,3.32-4.16,4.86"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1129.17,220.42c2.88-3.25,5.35,0.06,8,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M687.08,721.98c3.27,2.45,2.99,6.02,3,9.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1054.65,621.47c-0.12,3.89,2.87,3.98,5.5,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M683.08,739.49c-1.5-0.83-3-1.67-4.5-2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1149.67,557.46c6.01,2.58,6.93,4.52,5,10.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M914.62,308.93c-0.67,2.17-1.33,4.33-2,6.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M875.12,388.94c-1.58,4.62-1.46,9.16-0.71,14.03c0.7,4.58,2.37,6.2,6.71,5.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M675.58,720.98c-1.33-0.17-2.67-0.33-4-0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1141.17,305.43c-0.1,2.81-1.56,6.08,2.5,7.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M296.5,399.44c0,1.33,0,2.67,0,4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1023.64,520.96c-3.57,0.41-4.51,3.22-5.5,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M284.5,454.45c1.33-0.17,2.67-0.33,4-0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M462.54,325.43c-0.33,1.17-0.67,2.33-1,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1160.67,628.47c3.67,1.67,7.33,3.33,11,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1007.14,450.95c1.82,0.98,0.2,5.81,4.5,4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M998.64,150.91c-1.33,0.67-2.67,1.33-4,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1175.67,438.95c-1.33-0.33-2.67-0.67-4-1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1050.15,622.47c-0.17,2.67-0.33,5.33-0.5,8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M818.6,353.94c-1.17,2-1.96,4.42-3.6,5.89c-1.7,1.52-4.23,2.12-6.4,3.12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1069.15,288.43c2.33-6.79,4.67-0.47,7,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M441.53,340.44c-1.33,0-2.67,0-4,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1047.15,251.93c1.83,1.17,3.67,2.33,5.5,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1004.64,329.93c-2.17,1.42-4.5,2.75-3,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1165.17,622.47c-0.17,2.33-0.33,4.67-0.5,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1144.67,270.93c2.33-2.83,4.67-0.72,7,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1108.66,202.92c-2.89,1.26-3.63,3.24-2,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1125.66,208.92c0.67,1.17,1.33,2.33,2,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1135.17,532.46c-1.41,3.89-3.73,4.64-6.99,1.99c-0.08-0.07-0.07-0.36,0-0.48c0.12-0.2,0.32-0.34,0.49-0.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1057.65,543.96c2.33,0,4.67,0,7,0c0.17-0.64,0.35-1.29,0.52-1.93c-1.84-1.02-3.68-2.04-5.52-3.07"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1124.66,432.95c3.17-0.5,6.33-1,9.5-1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M232.99,409.44c-0.83,2-1.67,4-2.5,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1132.17,653.47c-1.05,3.89,3.13,4.33,3.75,3.34c2.4-3.87,6.84-4.53,9.72-7.38c0.25-0.25,0.03-0.97,0.03-1.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M874.62,406.44c-4.5-0.17-9-0.33-13.5-0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M180.48,590.97c-1.83,0.33-2.86,1.1-1.5,3c2.28,3.18,8.37,4.57,13.56,2.81c-2.88-4.28-6.88-5.66-11.56-5.81"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M678.08,676.48c-1.68,5.04-0.12,7.79,6.5,11.5c3.19-3.89,0.68-8.69-6-11.5"/>
		
			</g>
			</svg>
		</div>
    )
}