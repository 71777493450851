import ColorPage from "./component/ImageGalary/ColorPage";
import NavbarBase from "./component/navbar";
import { Switch, Route } from "react-router-dom";
import Home from "./component/Home";
import Auth from "./component/auth";
import ImageGalary from "./component/ImageGalary/ImageGalary";
import Logout from "./component/Logout";
import MyGalary from "./component/ImageGalary/MyGalary";
import FeaturedImage from "./component/ImageGalary/FeaturedImage";

function App() {
  return (
    <div className="App">
      <NavbarBase />
        <Switch>
          <Route path="/colorpage/:id/:component" component={ColorPage} />
          <Route path="/gallery" component={ImageGalary} />
          <Route path="/mygallery" component={MyGalary} />
          <Route path="/featuredimage" component={FeaturedImage} />
          <Route path="/login" component={Auth} />
          <Route path="/logout" component={Logout} />
          <Route path="/" exact component={Home} />
        </Switch>
      {/* <div id="footer">
          Footer stuff
      </div> */}
    </div>
  );
}

export default App;
