import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_URL, IMAGE_URL } from "../../GlobalConfig";
import LoadingSpeaner from "../LoadingSpeaner";
import { resetArray } from "./array";
import "./imageGalary.css";

function ImageGalary() {
  const [galary, setGalary] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetch(`${API_URL}/allimage`)
      .then((r) => r.json())
      .then((data) => {
        // console.log("galarydata", data);
        setGalary(data);
        setLoading(false);
      });
  }, []);

  if (loading === true) {
    return <LoadingSpeaner />;
  }

  console.log("Galary data", galary);

  const sendArray = (id) => {
    // console.log("array", resetArray)
    const formdata = new FormData();
    // formdata.append("user_id", user.id)
    formdata.append("image_id", id);
    formdata.append("fill_colors", JSON.stringify(resetArray));
    // console.log("formdata", formdata.get("fill_colors"))

    fetch(`${API_URL}/checkimage`, {
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      headers: { Authorization: `Bearer ${token}` },
      body: formdata,
    })
      .then((res) => {
        // console.log("pass array", res);
        return res.json();
      })
      // .then((res) => {
      //   console.log("respponse status",res)
      //   history.push(`/colorpage/${id}/${component}`)
      // })
      .catch((err) => console.log("error", err));
  };

  return (
    <div className="container">
      <h2 style={{ color: "black", margin: "50px", fontSize: "2rem" }}>
        Choose an image to color!
      </h2>
      <div className="row">
        {galary.map((item) => (
          <div className="col-md-4 col-sm-6 col-xs-12">
            <div className="card cardHover shadow text-center pl-2 mb-3">
              <div className="card-body">
                <h5 className="card-title">{item.component}</h5>
                <img
                  src={`${IMAGE_URL}${item.svg_url}`}
                  height="300px"
                  width="100%"
                  alt={item.component}
                />
                {/* <ColoredInImage component = {item.component} onFill = {() => {}} fillColors = {JSON.parse(item.fill_colors)}/> */}
                <Link
                  to={{
                    pathname: `/colorpage/${item.id}/${item.component}`,
                    state: item,
                  }}
                >
                  <button
                    onClick={() => {
                      sendArray(item.id);
                    }}
                    type="button"
                    className="btn btn-outline-primary mt-3 col-12"
                  >
                    Color me
                  </button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ImageGalary;
