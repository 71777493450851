import React from 'react';
import './Zion.css';

export default function Zion(props) {
    
    return (
    	<div className={`zion-container ${props.size}`}>
			<svg  xmlns="http://www.w3.org/2000/svg" id={props.id}>
			<g className="zion-image">
	
			<path d="M0.72,0.63c387.26,0,774.52,0,1161.78,0c0,499.71,0,999.41,0,1499.12c-387.26,0-774.52,0-1161.78,0
				C0.72,1000.04,0.72,500.34,0.72,0.63z M95.63,1060.98c2.02-2.11,0.66-5.89,4.12-7.21c-1.27-1.02-2.15-1.72-3.4-2.72
				c4.02-2.71,0.79-5.39,0.22-8.15c5.24-3.76,6.04-10.13,8.73-15.34c0.9-1.75,1.13-5.99,0.71-6.34c-5.1-4.28,0.52-6.3,1.67-9.13
				c0.72-1.77,2.98-2.9,2.39-5.21c-1.8-7.09-6.36-12.9-8.83-21.18c-1.35,7.11,1.96,12.7-0.64,18.4
				c-2.92-3.76-14.12-42.55-12.64-43.23c1.87,0.07,1.38,2.59,3.09,2.93c0.95-1.04,1.63-3.99,3.4-2.07c5.1,5.57,7.55,3.11,9.51-2.47
				c0.18-0.5,0.91-0.81,1.48-1.28c8.22,6.72,4.42,18.71,11.33,25.86c2.38-1.53,1.49-5.01,5.13-5.52c1.08,6.91,3.5,13.49,7.74,18.98
				c3.47,4.5,5.45,9.41,6.59,14.74c1.45,6.81,2.96,13.68,1.99,21.24c-2.05-1.7-2.07-3.7-3-5.2c-0.62-1-0.31-2.96-2.27-2.76
				c-2.84,0.3-4.1-1.82-5.61-3.52c-2.85-3.19-6.58-5.44-8.97-9.12c0.44,4.44,1.99,8.25,4.08,11.88c2.13,3.68,6.79,5.08,8.79,9.05
				c-2.06,1.58-3.63-0.18-5.18,0.29c1.85,5.07-0.55,9.14-5.3,9.07c2.96,6.03,8.22,10.4,11.8,15.39c-1.33,2.54-3.26-0.19-4.15,1.59
				c0.92,3.04,5.26,3.63,5.17,7.61c-3.27,0.48-5.53-2.02-8.91-2.47c4.26,3.96,8.05,7.49,12.11,11.27c-3.37,2.03-5.58-0.85-8.25-0.4
				c2.64,4.21,7.68,5.46,10.08,9.02c-0.58,1.95-2.41,0.78-3.09,2.53c3.85,2.17,4.72-2.01,6.5-2.7c-2.51-6.51,3.13-9.21,5.94-13.45
				c-2.26,0.03-4.7,0.41-2.73-2.74c-2.1-0.08-3.85,1.39-6.06-0.81c5.29-3.57,10.42-7.03,15.67-10.57c-2.79-2.09-4.39-4.77-7.93-5.55
				c4.95-5.32,13.47-6.57,13.98-15.46c-3.86,1.08-6.14-1.79-9.65-2.77c4.12-3.88,8-7.18,9.19-12.18c-1.4-1.24-3.26-0.14-3.86-2.2
				c2.59-3.29,5.26-6.69,8.67-11.04c1.18,8.58,6.63,12.95,12.1,16.87c-2.26,3.18-2.83,7.64-8.05,6.67c2.4,4.53,6.54,5.72,11.3,8.07
				c-4.67,0.54-6.29,3.63-8.87,5.5c2.76,3.09,7.34,3.63,9.97,7.15c-2.11,1.59-4.49,0.2-6.34,1.38c1.88,2.65,1.88,2.65-1.29,4.47
				c2.6,4.33,7.08,6.46,10.96,9.22c2.55,1.82,2.54,3.05-0.24,4.13c1.13,2.16,4.24,2.12,4.19,5.25c-3.96-0.29-7.42,1.59-11.74,1.83
				c4.77,5.45,10.96,7.83,16.96,10.96c-2.63,2.55-5.24,0.96-7.25,2.11c0.18,2.1,3.63,2.02,3,4.77c-3.68,1.57-6.52-2.33-11.62-1.81
				c5.77,2.97,6.75,8.66,11.27,11.18c2.77-1.54,5.5-2.75,7.23-5.36c-1.3-1.93-4.66-1.29-5.18-4.82c6.85,0,7.47-7.63,13.48-9.88
				c-2.8-0.88-5.12-1.02-5.83-3.42c1.31-1.75,3.68-1.5,4.55-3.42c0.36-3.87-4.94-2.75-5.66-6.32c7.2-1.49,4.69-6.58,4.03-10.92
				c-1.83-12.03-3.56-24.15-1.72-36.25c1.03-6.74,1.95-13.39,2.17-20.21c0.11-3.47,0.27-7.36,3.23-10.41
				c4.47,3.42,5.45,9.9,11.32,12.6c-2.36-13.54-7.25-26.18-6.4-39.75c1.76,0.11,1.99,0.98,2.34,1.69
				c8.48,16.93,14.04,35.13,22.54,52.06c2.46,4.89,4.87,9.66,9.53,13.56c-0.36-12.08-4.63-23.38-3.6-35.05
				c0.59-0.13,1.19-0.27,1.78-0.4c3.64,10.4,7.28,20.8,10.92,31.2c-2.05-20.52-11.02-39.24-14.04-59.5
				c9.74,15.12,12.04,33.12,19.33,49.21c7.26,16.05,15.07,32,17.88,50.16c6.96-6.06,7.31-6.78,4.65-14.12
				c-2.72-7.51-5.03-15.13-7.15-22.84c-5.17-18.79-10.98-37.5-20.18-54.65c-8.85-16.5-15-33.6-18.05-51.97
				c-0.87-5.25-1.52-10.99-4.52-15.22c-9.39-13.26-12.51-29.19-19.51-43.44c-0.44-0.9-0.83-1.84-1.42-2.63
				c-7.21-9.74-11.65-20.86-16.18-31.96c-3.45-8.47-7.21-16.81-10.95-25.16c-0.82-1.83-1.27-4.05-3.42-5.33
				c-1.49,3.06-2.53,6.48-0.87,8.83c2.67,3.79,3.05,7.76,2.41,11.78c-0.97,6.12,0.05,11.63,2.22,17.38c1.35,3.59,2.86,7,4.6,10.4
				c1.89,3.7,2.67,7.39,1.2,11.89c-1.66,5.08-0.77,11.15,2.29,15.24c2.45,3.27,2.95,5.39,2.02,9.11c-1.35,5.34,1.24,6.9,6.62,6.56
				c2.9-0.18,7.26-4,9.33,1.68c-3.68-0.77-7.02,0.78-10.39,1.73c-3.51,0.99-5.53,0.06-7.21-3.23c-2.01-3.96-1.26-7.66,0.12-11.29
				c-4.5-4-7.47-14.34-5.41-20.45c1.37-4.07,0.96-7.7-1.28-10.92c-3.92-5.61-4.36-12.5-7.37-18.41c-0.73-1.43-0.17-2.88,0.07-4.37
				c0.7-4.38,1.97-9.48-0.12-13.04c-2.65-4.5-2.52-8.39-1.21-12.76c0.95-3.2,1.19-6.74-0.65-9.2c-7.6-10.2-11.06-22.45-16.93-33.5
				c-3.47-6.52-6.96-12.44-11.68-18.25c-4.29-5.29-9.06-8.91-14.51-12.29c-2.61,6.12-3.25,14.49-1.45,20.08
				c1.36,4.22,1.61,8.46,0.54,12.79c-0.9,3.62-1.15,7.18,0.61,10.71c2.87,5.77,3.86,12.62,10,16.46c1.22,0.76,1.82,2.46,1.78,4
				c-0.15,5.19,1.64,10.34,1.15,15.37c-0.69,7.2,0.42,14.8-2.98,21.55c-2.41,4.79-2.49,9.47-1.24,14.51
				c2.7,10.81-4.74,25.32-15.08,29.44c-3.04,1.21-4.94,2.97-5.59,6.31c-0.43,2.21-1.14,4.62-4.06,5.04
				c-4.77,0.68-8.14,3.35-10.47,7.47c-1.15,2.03-2.44,3.11-5.2,2.51c-7.24-1.56-8.41-0.4-8.06,7.01c0.38,8.07-5.27,17.03-11.8,17.54
				c-6.22,0.49-9.31,5.14-13.08,8.69c-3.91,3.68-7.8,7.59-10.11,12.69c-0.54,1.2-1.89,1.68-3.31,1.94c-7.86,1.46-8.43,10-13.54,14.16
				c-0.24,0.2-0.19,1.01-0.01,1.43c1.4,3.37,1.34,6.76,0.6,10.27c-0.36,1.71,0.6,4.3,2.29,3.29c3.35-2.02,8.28-1.36,10.22-5.54
				c1.9-4.11,4.62-3.91,8.19-2.5c6.44,2.53,7.24,2.11,10.45-4.13c2.49-4.83,5.76-7.75,11.78-8.56c5.83-0.78,9.16-6.73,13.7-10.25
				c0.1-0.08,0.08-0.32,0.09-0.48c0.21-5.81,3.06-6.92,8.27-5.17c2.05,0.69,5.72,1.84,6.43-1.36c0.75-3.37,2.5-6.47,2.47-10.09
				c-0.04-4.69,0.16-9.88,4.33-12.74c3.8-2.6,6.92-6.25,10.91-8.19c6.49-3.16,7.51-3.79,7.37-10.98c-0.05-2.59-0.03-4.88,3.18-5.91
				c5.09-1.64,7.2-5.53,6.36-11.02c-0.46-3-0.67-5.98,0.53-8.85c0.82-1.95,0.77-3.83,0.17-5.82c-1.08-3.56-1.55-7.11-0.23-10.77
				c0.7-1.93-0.19-3.86-0.58-5.82c-1.55-7.86-4.29-15.89,2.3-23.04c1.31-1.42,2.5-3.42,0.61-5.35c-6.02-6.13-3.34-12.53-0.6-18.88
				c0.58-1.35,2.11-2.69,1.12-4.07c-3.68-5.12,0.3-9.5,1.18-14.13c0.46-2.4,2.59-3.56,4.95-3.18c2.24,0.36,3.5,2.24,3.21,4.42
				c-0.62,4.72,1.72,7.49,5.5,9.61c1.94,1.09,2.84,2.94,2.38,5.05c-0.46,2.09-1.2,4.37-3.8,4.73c-2.55,0.35-3.17,1.67-3.96,4.09
				c-1.65,5.02-0.05,8.48,3.06,12.22c2.92,3.51,6.14,7.47,3.59,12.6c-2.07,4.18-1.15,9.23-4.14,13.14c-0.9,1.17,0.14,2.61,0.75,3.74
				c2.31,4.21,2.03,8.55,1.31,13.1c-0.69,4.37-1.77,9.06-0.87,13.21c0.89,4.11,1.77,7.65-0.65,11.35c-0.55,0.84-0.67,1.75-0.51,2.83
				c1.71,11.96,1.48,23.57-4.66,34.6c-2.55,4.58-0.84,7.58,4.21,8.15c3.79,0.43,5.56,2.47,6.82,5.56c2.09,5.15,3.27,10.42,2.78,16.07
				c-0.37,4.3-0.95,8.77-0.19,12.94c1.24,6.77,0.48,13.12-1.06,19.7c-1.4,5.99-0.78,12.31,3.45,17.05
				c4.85,5.43,7.26,11.78,9.24,18.49c0.23,0.77,0.4,1.66,1.81,1.8c-0.2-2.7-0.22-5.36-0.62-7.96c-0.72-4.72-0.56-9.5,1.08-13.81
				c2.85-7.46,2.31-14.9,1.92-22.59c-0.5-9.96,0.16-20.04,5.87-28.71c1.65-2.51,2.68-4.67,2.72-7.63c0.07-5.97-0.86-12.02,0.9-17.89
				c0.82-2.75,1.87-5.76,5.39-5.61c3.4,0.14,7.27-1.3,10.25,3.21c0.46-8.98-1.27-16.51-3.49-23.97c2.43,0.05,2.89,1.92,3.29,3.72
				c0.95,4.21,2.2,8.4,2.62,12.67c0.37,3.83,1.03,7.87-0.69,11.53c-2.48,0.31-3.22-1.48-4.16-2.7c-2.27-2.99-5.52-1.89-8.23-1.76
				c-2.14,0.1-2.44,2.8-2.85,4.77c-0.85,4.12-0.71,8.27-0.58,12.44c0.15,4.96,0.56,9.99-4.11,13.64c-1.05,0.82-1.71,2.55-1.92,3.96
				c-1.12,7.7-3.34,15.26-2.42,23.23c0.59,5.1,1.86,10.66-0.08,15.37c-4.16,10.09-2.93,20.25-2.15,30.52c0.15,2,0.73,4.24-1.52,6.04
				c-3.56-3.48-4.91-7.81-6.2-11.99c-1.64-5.31-3.84-10.06-7.45-14.43c-5.44-6.6-6.36-14.42-4.2-22.65c0.76-2.89,2.08-5.85,1.46-8.8
				c-1.81-8.61-0.6-17.27-0.83-25.89c-0.11-3.94-1.55-7.45-3.03-10.97c-0.62-1.46-1.66-2.19-3.34-2.56
				c-8.49-1.87-10.78-6.52-6.15-13.92c4.34-6.94,4.77-14.74,4.21-22.02c-0.64-8.43,2.62-16.97-0.86-25.27
				c-0.37-0.89,0.49-2.23,0.55-3.37c0.32-5.62,3.45-11.75,1.26-16.66c-3.47-7.79,0.94-13.86,2.41-20.41
				c0.92-4.11,1.29-7.31-1.2-10.63c-1-1.33-2.03-2.63-3.07-3.92c-5.12-6.38-3.52-14.06,3.55-18c2.84-1.58,3.11-3.78,0.2-5.73
				c-4.1-2.74-7.1-6-6.38-11.43c0.08-0.63-0.35-1.26-2.05-1.56c-1.21,3.9-5.28,7.15-2.97,12.14c1.37,2.95,0.69,5.08-0.64,7.87
				c-2.26,4.72-4.93,10.26-0.48,14.85c3.27,3.37,3.23,6.29,0.59,9.04c-5.86,6.09-3.21,13.12-2.36,19.7
				c0.48,3.75,2.13,7.24,0.44,11.08c-0.77,1.76-0.23,3.63,0.36,5.38c1.16,3.42,1.43,6.79-0.15,10.17c-0.73,1.56-0.57,3.25-0.35,4.91
				c1.23,9.28-0.64,12.72-9.41,16.86c0.51,4.7,1.16,10.21-3.46,13.08c-4.3,2.67-7.97,6.24-12.46,8.45c-3.58,1.76-5.83,4.38-5.66,8.04
				c0.25,5.33-2.06,9.94-2.97,14.92c-0.59,3.27-2.58,4.57-5.58,4.46c-2.75-0.1-5.48-0.55-8.16-0.84
				c-1.52,8.95-10.01,11.05-14.98,16.39c-0.52,0.56-1.92,0.46-2.89,0.39c-3.88-0.28-6.18,1.85-7.96,4.93
				c-5.9,10.17-5.91,10.16-17.17,6.74c-0.29-0.09-0.64,0.05-1.3,0.12c-1.98,4.56-6.25,6.02-10.78,7.51
				c-2.34,0.77-4.28,1.82-6.41,0.01c-2.11-1.8-2.79-4.16-1.96-6.76c0.75-2.33,0.73-4.53-0.05-6.84c-0.6-1.77-1.11-4.15,0.23-5.27
				c5.58-4.66,6.63-13.87,15.38-15.3c1.26-0.21,2.04-1.18,2.43-2.31c1.89-5.4,6.46-8.88,10.17-12.64c3.04-3.09,6.21-7.08,10.99-8.08
				c9.12-1.92,12.58-6.43,11.29-15.74c-1.79-12.95-5.01-25.45-12.43-36.51c-2.92-4.35-4.64-9.15-5.25-14.39
				c-0.32-2.79-0.77-5.64-1.68-8.28c-4.11-11.95-8.26-23.88-12.67-35.72c-2.2-5.91-4.26-12.19-8.93-16.58
				c-6.23-5.85-10.35-12.91-13.99-20.43c-1.16-2.39-2.76-4.38-5.44-5.56c-10-4.36-20.26-8.37-27.93-17.24
				c-0.03,4.32-0.56,8.37,0.23,11.95c12.16,1.46,11.76,11.7,14.69,19.68c3-1.19,5.21-0.55,6.24,2.68c2.94,9.22,7.53,17.62,12.5,25.87
				c1.71,2.84,1.67,6.25,0.16,10.48c-3.79-4.05-8.35-5.5-12.94-6.94c-0.72-0.22-1.48-0.96-1.81-1.66
				c-3.02-6.28-6.98-11.68-13.46-14.82c-0.41-0.2-0.65-0.74-0.99-1.11c-1.18-1.24-2.45-2.25-4.37-1.54
				c-1.2,4.12,0.22,7.09,3.18,10.17c3.07,3.19,8.18,3.04,10.47,6.46c4.61,6.88,11.1,12.2,15.07,19.61
				c3.42,6.37,6.64,13.55,15.81,13.36c0.16,0,0.34,0.01,0.49,0.05c2.67,0.74,2.51,3.22,3.17,5.19c0.57,1.68-0.05,2.77-1.6,3.67
				c-6.26,3.64-9.68,9.32-11.36,16.22c-1.97,8.11-0.47,17.09-6.12,24.29c-0.45,0.57-0.49,1.63-0.41,2.42
				c0.33,3.39-0.59,6.51-1.43,9.75c-0.75,2.91-2.14,6.54,0.62,8.92c3.66,3.16,4,6.91,3.77,11.22c-0.3,5.62,0.49,11.34-1.6,16.78
				c-0.6,1.56-0.17,2.96,0.57,4.3c4.6,8.37,3.57,17.15,1.22,25.7c-2.5,9.07-2.55,18.41-4.16,27.56c-0.14,0.78-0.62,2.01,1.04,2.61
				c3.52-6.61,6.59-13.44,9.42-21.57c2.48,3.47,2.35,6.13,2.41,8.69c0.18,6.62,2.04,12.55,7.39,16.77c2.36,1.86,1.96,3.79,1.38,6.23
				c-1.7,0.29-3.33,0.57-5.1,0.88c2.16,3.5,5.91,3.51,9.23,5.6c-3.45,1.44-4.04,5.56-8.19,5.18c2.41,3.6,6.97,2.81,9.34,5.74
				c-1.87,2.34-4.35,1.4-7.02,1.51c3.99,2.39,13.12-1.97,13.81-5.99c-0.58-1.01-2.45,0.08-2.64-1.82c2.01-2.58,5.51-3.87,7.3-6.96
				c-1.1-0.62-2.88,1.18-3.37-1.66c5.5-3.24,7.59-9.48,8.74-15.06c1.35-6.55,5.55-11.06,7.65-17.89c3.18,5.46,3.73,10.5,5.43,15.11
				c0.85,2.31,2.59,4.17,1.53,7.4c-1,3.05-3.38,4.06-5.12,5.71c1.74,5.34,7.3,6.87,10.45,10.53c-2.04,2.04-3.6-0.65-5.1,0.64
				c0.47,2.52,4.04,3.04,3.85,6.5c-3.86-1.95-5.24,3.07-9.1,2.28c2.96,4.4,6.55,7.29,10.35,9.98c1.56,1.1,3.75,1.85,3.13,4.51
				c-1.95,0.17-3.74-1.39-5.76-0.6c0.06,1.71,3.14,1.65,2.37,3.54c-1.23,1.84-3.32,0.36-4.53,1.65c0.76,1.47,2.57,1.9,2.7,3.3
				c-0.06,1.87-2.11,0.78-2.43,2.02c1.78,3.13,6.32,3.91,6.67,8.13c-3.22,0.17-5.82-2.05-9.51-2.5c1.93,3.09,4.13,5.18,6.75,7.35
				C93.94,1064.09,94.79,1062.53,95.63,1060.98z M811.7,1169.92c-3.41,0.45-6.83,0.9-9.82,1.29c-5.36,9.96-2.92,19.57-0.38,29.72
				c1.33-11.42,1.82-22.53,10.12-31.08c2.7-0.86,6.54,0.26,7.96-4.71C816.41,1167.06,814.05,1168.49,811.7,1169.92z M767.95,1136.92
				c-2.47-3.19-5.95-5.16-9.12-7.48c-1.71-1.25-3.86-1.18-5.64-0.09c-1.79,1.1-1.3,2.76-0.28,4.67c2.51-1.98,4.73-1.33,7.08,0.47
				c2.25,1.73,5.02,2.44,7.9,2.5c2.09,1.88,4.27,3.67,6.25,5.67c4.09,4.14,4.05,9.12-0.6,11.83c-3.86,2.25-7.33,4.17-8,9.18
				c-0.23,1.74-2.21,2.99-4.01,3.46c-11.71,3.08-20.98,13.34-34.3,11.66c-0.22-0.03-0.5,0.4-0.81,0.67c0.58,2.5,1.3,5.39,4.68,4.14
				c5.42-2,11.17-2.81,16.33-5.89c5.59-3.33,11.13-7.13,18.29-7.14c4.58-0.01,10.72-5.04,9.89-8.37c-1.02-4.06,1.54-5.62,3.03-7.97
				c3.25-5.14,2.84-8.86-2.12-12.37C773.85,1139.95,770.82,1138.54,767.95,1136.92z M310.09,460.77c-1.6-5.95-1.19-12.49-5.07-18.25
				c-1.15,5.94-3.35,11.63,3.57,15.14c0.82,0.42,1.06,1.99,1.56,3.02c0.72,1.83,0.21,3.56-0.28,5.35
				c-3.06,11.25-7.35,22.03-12.58,32.42c-2.42,4.82-2.95,9.7-3.4,15.11c-0.89,10.74,0.77,20.39,5.49,29.97
				c2.64,5.36,2.15,11.74,3.62,17.6c0.98-11.92,5.52-23.21,6.6-36.17c-2.59,3.6-2.86,7.18-5.84,9.37c-3.71-4.3-4.13-9.33-3.88-14.25
				c0.74-14.43,1.74-28.85,7.57-42.41c1.77-4.11,3.1-8.42,3.72-12.86C311.35,463.49,312.83,461.45,310.09,460.77z M806.68,705.04
				c-4.2,3.19-4.05,5.44,1.03,8.25c7.27,4.01,14.78,7.59,22.06,11.59c4.95,2.71,10.88,4.93,13.78,9.6c3.74,6.03,9.3,8.01,15.03,10.63
				c12.65,5.78,25.99,10.35,36.32,20.35c2.75,2.66,6.63,2.99,10.34,3.62c-2.41-2.78-4.88-5.42-7.71-7.58
				c-12.22-9.35-23.67-20.09-39.77-22.69c-2.62-0.43-5.26-1.47-7.09-3.43c-2.57-2.76-5.75-4.47-8.96-6.18
				c-8.94-4.76-17.94-9.42-26.89-14.17c-3.93-2.09-7.35-4.66-7.75-9.62c1.72-2.35,4.85-2.54,7.29-4.54
				c-3.21-1.86-5.3-5.06-10.05-4.79C806.91,698.99,809.16,701.57,806.68,705.04z M81.2,425.85c7.57-4.25,15.65-5.25,24.08-3.58
				c4.01,0.79,6.57-0.61,8.11-4.82c-7.42,0.03-14.49-0.38-21.57-1.52c-12.86-2.06-25.78-2.51-38.58,0.79
				c-4.04,1.04-8.17,1.46-12.32,1.55c-12.89,0.26-25.78,1.2-38.52-0.29c-1.03,2.54-0.06,3.52,2.01,3.69
				c2.98,0.24,5.96,0.58,8.94,0.56c5.12-0.03,10.39-1.16,15.33-0.32c12.22,2.07,24.15,2.69,35.9-2.12c1.98-0.81,4.26-0.9,6.23-1.72
				c3.96-1.65,6.37,0.31,8.32,3.3c0.9,1.37,1.41,2.99,2.09,4.5c-1.87-0.29-2.57-1.86-3.6-3.14c-1.02-1.26-2.14-2.79-4.12-1.77
				c-6.07,3.13-12.11,6.32-19.12,9.99C64.57,431.43,72.7,427.68,81.2,425.85z M607.86,906.2c2.21,2.95,4.67,5.39,8.69,3.18
				c4.75-2.61,9.48-0.95,11.04,4.11c0.72,2.33,1.18,4.75,1.92,7.79c2-19.31,2-19.31-0.22-21.86c-0.54,0.31-1.18,0.55-1.66,0.98
				c-3.05,2.69-5.88,4.57-8.62-0.51c-0.77-1.42-2.75-1.6-4.56-1.34c-1.87,2.88-1.12,8.11-6.7,7.73c0.69-6.08,3.75-11.57,4.34-17.69
				c0.12-1.29,1.06-2.43,2.33-3.09c5.93-3.07,11.63-8.04,18.56-1.37c1.15-2.79,1.34-5.47,3.44-6.64c3.35-1.88,3.5-4.77,3.55-8.06
				c0.04-3.16-0.17-6.61,2.8-8.79c2.21,0.76,2.47,3.53,4.82,3.59c0.34-0.82,0.57-1.65,0.99-2.35c2.83-4.64,3.86-9.15-0.68-13.43
				c-0.73-0.69-1.04-1.7-0.7-2.77c1.68-5.38,3.25-10.8,5.1-16.13c0.66-1.9,1.64-4.02,5.25-2.72c-3.13-5.82-0.89-10.57,0.66-15.24
				c1.44-4.37,5.16-4.89,8.2-1.55c0.78,0.86,1.46,1.9,2.41,2.51c10.89,7.01,12.77,20.55,21.21,29.37c1.31,1.37,1.34,4.04,1.82,6.14
				c0.78,3.4,2.38,6.9,5.32,8.09c4.49,1.81,6.92,4.72,8.18,9.15c0.64-8.7-6-13.57-10.04-19.74c-4.26-6.51-10.87-11.57-11.28-20.36
				c-0.24-5.12-3.24-8.78-7.77-11.41c-6.28-3.64-11.37-8.6-14.9-15.07c-0.91-1.67-1.31-3.76-3.11-4.83
				c-6.76,3.23-15.27,13.47-16.94,20.5c-2.4,10.13-4.34,20.39-7.14,30.41c-2.97,10.6-9.78,19.94-10.51,31.37
				c-0.11,1.8-2.07,2.74-3.85,2.43c-3.59-0.62-5.2,1.34-6.3,4.24c-0.54,1.41-1.24,2.68-2.39,3.74
				C603.11,893.95,602.56,897.35,607.86,906.2z M718.24,1325.68c-4.18-1-8.41-1.84-12.53-3.06c-4.39-1.29-8.45-0.96-12.58,1.01
				c-3.64,1.74-6.89,4.03-10.08,6.42c-16.17,12.07-16.45,22.29-0.95,35.18c3.44,2.86,7.48,4.6,11.97,4.04
				c4.24-0.53,8.42-0.92,12.74-0.16c-2.61-2.19-5.67-3.29-8.76-4.36c-6.91-2.4-13.84-4.77-19.93-8.97c-2.63-1.82-5.51-3.92-5-7.47
				c0.54-3.77,4.54-3.22,7.14-4.36c0.25-0.11,0.63,0.09,0.95,0.16c5.97,11.54,7.27,12.72,13.04,11.75c-0.59-4.26-4.19-7.16-5.1-11.24
				c-0.46-2.04-0.62-4.31,1.92-4.91c2.35-0.56,4.77-0.31,5.17,3c0.12,0.99,0.47,1.94,0.65,2.92c1.48,7.93,4.51,14.8,11.64,19.48
				c4.15,2.73,7.85,6.15,11.73,9.3c2.47,2.01,5.18,3.32,8.91,3.55c-1.91-3.67-3.61-6.92-5.31-10.18c0.43-0.52,0.87-1.04,1.3-1.56
				c10.45,15.26,24.7,23,42.72,23.66c-1.76-4.33-4.05-7.95-6.34-11.57c-0.71-1.12-1.78-1.32-3.02-1.34
				c-6.25-0.09-11.18-2.96-15.08-7.62c-1.51-1.81-3.33-2.77-5.48-3.09c-5.7-0.86-8.89-4.35-11.32-9.38
				c-3.11-6.43-4.56-13.61-11.43-18.23c-5.04-3.39-3.04-9.61,3.16-13.06c-0.33,0.76-0.6,1.55-0.99,2.27
				c-3.45,6.27-3.18,7.59,2.56,11.99c0.92,0.71,1.84,1.44,2.85,2c2.2,1.22,3.19,2.43,2.75,5.48c-0.78,5.41,6.77,15.09,12.16,16.41
				c3.18,0.78,5.71,2.18,7.94,4.68c3.26,3.65,7.27,6.21,12.46,6.02c0.62-2.34-2.58-3.28-1.57-5.79c0.56-0.03,1.27-0.29,1.78-0.08
				c4.85,2.04,5.67,7.55,9.03,10.89c4.29-3.66,4.29-3.66,7.54-2.78c-2.29,10.07-1.7,12.63,3.61,15.7c0.56-2.48,0.67-5.32,5.98-5.81
				c-6.24,8.79,3.56,13.58,3.44,20.9c-0.03,1.79,1.81,2.79,3.47,3.16c5.36,1.2,9.93,4.12,15.27,7.34c-3.16-4.13-6.51-7.17-5.94-13.3
				c1.22,1.56,1.64,2.21,2.17,2.76c4.72,4.88,7.86,11.19,13.19,15.44c6.13,4.89,14.04,6.43,20.87,10.09
				c1.51,0.81,3.23,0.85,4.89,0.62c2.45-0.34,3.76-1.56,2.31-4.08c-6.31-10.95-12.21-22.23-23.72-28.92
				c-10.2-5.92-20.23-12.16-30.59-17.78c-10.39-5.64-18.09-13.36-21.91-24.71c-1.19-3.53-3.17-6.6-6.3-8.76
				c-9.03-6.23-17.99-12.56-27.13-18.63C729.62,1327.48,724.37,1325.05,718.24,1325.68z M351.45,1186.98
				c0.82,3.45,3.42,3.97,6.43,4.11c2.8,0.14,4.89-1.87,7.5-2.18c0.03,2.01-2.75,2.68-2.1,5.22c2.74,10.81,5.93,21.36,13.98,29.65
				c0.38,0.39,0.76,0.77,1.14,1.16c0.38,0.91,0.65,1.88,1.15,2.72c6.3,10.38,12.52,20.76,16.82,32.22
				c2.28,6.07,6.51,10.85,12.74,13.98c-1.77-5.95-3.54-11.91-5.31-17.86c2.88,1.96,3.8,4.79,4.76,7.56
				c8.15,23.53,8.15,47.74,5.31,72.03c-1.19,10.18-2.02,20.36-3.24,30.34c4.69,2.33,8.65-2.45,13.28,0.38
				c0.27-4.06,0.45-7.5,0.74-10.93c1.61-19.08,5.65-37.83,8.08-56.8c1.26-9.88,1.84-19.95,5.24-29.4c1.77-4.93,2.35-9.36,0.48-14.43
				c-1.72-4.66-1.21-9.51,2.36-13.68c-1.96,6.4-0.76,12.46,1.32,18.5c0.85,2.48-0.12,4.9-0.92,7.29c-1.64,4.92-3.14,9.89-2.6,15.56
				c4.36-1.83,8.14-3.48,12.16-3.58c0.68,0.77,0.2,1.08-0.08,1.47c-0.25,0.34-0.43,0.74-0.68,1.17c24.36,3.84,48.42,6.95,70.9-7
				c-4.12-10.61-11.51-18.21-21.45-24.34c3.2-1.13,4.62,0.29,6.14,1.16c6.72,3.84,11.74,9.47,15.08,16.26
				c5.86,11.9,14.22,21.6,24.17,30.16c2.89,2.48,5.53,5.31,8,8.22c3.27,3.86,6.17,8.29,6.22,13.37c0.19,19.67,2.59,39.63-6.23,58.37
				c2.63,2.86,6.84,1.29,9.41,3.88c5.32-9.31,9.06-18.93,8.85-29.49c-0.24-11.79,0.94-23.18,5.37-34.23
				c2.85-7.11,0.93-13.15-5.09-18.22c-6.57-5.53-11.29-12.62-15.04-20.34c-4.99-10.27-3.2-20.39,5.14-28.22
				c2.66-2.5,5.73-4.55,8.51-6.93c9-7.71,13.71-16.98,12.42-29.35c-2.11-20.23-19.24-29.84-36.05-35.52
				c-2.59-0.88-5.86-1.34-8.51,1.08c-5.57,5.09-7.24,12.71-3.97,19.58c2.37,4.97,5.54,9.44,8.76,13.91
				c6.73,9.32,12.1,19.18,10.35,31.36c-1.69,11.75-4.69,23.44-0.91,36.33c-2.4-1.96-2.65-3.75-3.08-5.44
				c-1.84-7.16-0.79-14.4,0.85-21.26c3.49-14.61,1.07-27.65-8.04-39.52c-1.82-2.37-3.56-4.82-5.27-7.28
				c-6.5-9.33-9.66-18.77-0.38-28.84c-11.03,1.98-21.86,1.33-32.46,3.33c-18.35,3.46-36.83,3.27-55.08,0.07
				c-9.4-1.65-18.43-2.19-27.64-0.05c-9.43,2.2-17.62-0.5-25.23-5.95c-5.71-4.1-10.38-9.15-14.18-15.71
				c-1.77,3.81-1.72,7.34-2.02,10.71c-0.81,9.15-5.08,15.24-14.28,17.38c0.18-2.03,1.88-2.63,3.33-3.31
				c5.02-2.33,8.01-6.51,8.24-11.68c0.51-11.52,8.41-22.56,1.89-33.92c4.17-4.69,6.01,0.91,9.23,0.61c-1.71,2.6-4.15,1.92-6.11,2.57
				c3.1,5.33,10.86,4.94,14.12-0.74c1.79-3.13,2.97-6.58,4.93-10.22c-7.45,1.47-14.6,4.07-22.14,2.57
				c3.28-7.92,7.11-10.86,14.33-9.84c8.62,1.21,15.31,5.6,18.4,14.1c1.7,4.67,2.63,9.61,4.08,14.38c0.64,2.11,0.68,4.68,3.17,5.82
				c1.85-1.21,1.31-3.02,1.32-4.59c0.04-7.2-0.98-14.25-2.92-21.18c-1.97-7.05-5.1-13.4-11.18-17.83
				c-11.37-8.29-29.58-9.59-42.48,7.9c-0.1,1.65,2.67,1.43,2.14,3.92c-5.87-3.5-11.76-2.54-17.79,0.37c0.28-2.19,2.61-2.92,1.15-5.33
				c-6.73-11.13-19.86-12.05-27.68-1.73c-5.06,6.68-7.47,14.4-8.8,22.52c-0.92,5.61-1.63,11.24-0.14,17.05
				c2.57-7.96,8.02-14.85,7.92-23.65c-0.02-1.78,0.54-4.11,1.96-4.24c3.57-0.31,5.63-3.38,8.89-4.07c2.05-0.43,3.47-0.34,5.23,1.29
				c2.02,1.87,3.74,4.45,6.94,4.51c-1.34,5.37-4.62,9.68-4.63,14.98c2.78,1.71,7.49,2.53,5.47,6.76c-1.16,2.42-0.63,3.97-0.16,5.89
				c1.32,5.35,3.62,10.5,2.52,16.29c-0.48,2.54,0.59,4.9,2.66,6.64C349.93,1185.56,350.69,1186.27,351.45,1186.98z M342.49,753.96
				c-3.44,4.96-1.15,7.97,3.27,10.71c2.79,1.73,5.22,4.1,7.64,6.37c1.19,1.12,3.5,2.34,1.69,4.42c-1.65,1.9-3.8,1-5.36-0.34
				c-2.51-2.16-4.81-4.57-7.17-6.9c-6.1-6.02-11.94-12.32-18.76-17.42c-3.36,2.83-3.16,5.05-0.11,7.15c2.05,1.41,3.96,3.06,6.13,4.27
				c8.85,4.92,15.88,12,22.87,19.11c0.87,0.89,2.27,1.62,1.43,3.38c-2.33,1.41-4.25-1.07-7.21-0.81c3.36,3.85,6.17,7.52,10.07,10.09
				c4.59-5.3,8.89-10.26,12.61-14.55c-5.75-12.69-19.15-16.02-27.22-25.36c-3.71-8-9.55-13.38-17.91-16.7
				c-4.1-1.63-8-3.83-12.29-5.22c-5.09-1.65-8.44-6.75-6.2-9.43c4.32-5.16,7.21-12.46,16.09-11.9c0.15-8.32-0.44-16.26-2.39-23.98
				c-1.14-4.5-1.64-8.85-0.43-13.27c0.83-3.02,0.72-5.83-0.39-8.4c-7.54-3.44-6.97,4-9.32,7.46c-2-1.76-2.53-3.54-3.23-5.2
				c-0.59-1.38-0.95-2.86-2.86-3.03c-2.09-0.18-2.87,1.38-3.79,2.74c-1.47,2.15-1.82,4.58-1.81,7.13c0.01,1.98-0.04,4-0.38,5.95
				c-0.35,1.99-1.57,2.68-3.67,1.84c-8.84-3.53-9.49-4.47-9.16-14.04c0.05-1.3,0.01-2.6,0.01-3.94c-2.19-0.73-4.31-0.66-5.28-3.31
				c-1.88-5.13-4.31-10.03-5.1-15.54c-0.39-2.69-1.57-4.99-4.72-5.44c0,27.93,4.16,55.56,1.43,84.45c2.45-2.12,2.41-4.21,3.96-4.82
				c3.43-0.58,5.15,3.25,8.32,2.81c4.2-0.58,6.9,1.63,8.07,5.15c1.27,3.8,6.99,4.39,6.24,9.34c8.05,1.28,3.06,6.31,2.22,9.33
				c-2.08,7.52-4.86,14.87-5.96,22.64c-0.7,4.93,0.58,10.23-1.39,14.85c-4.78,11.16-3.39,22.89-3.91,34.45
				c-0.08,1.81-0.24,4.52,1.22,5.14c5.34,2.26,7.43,7.04,9.87,11.77c0.9-4.27-1.97-8.77,1.58-12.46c3.89,0.6,7.06,2.14,5.34,6.46
				c-2.14,5.35,1.12,9.37,2.51,14.37c1.51-1.04,2.24-2.77,3.55-3.61c1.39-0.89,3.28,0.4,5.13-0.6c5.81-3.14,7.52-2.31,9.42,3.79
				c0.5,1.59-0.01,4.35,2.65,4.01c2.53-0.32,5.18-1.15,5.9-4.43c0.41-1.86,0.31-4.04,2.22-5.44c1.35-0.32,2.14,1.68,4.57,0.58
				c-0.14-7.66-1.13-16.28,5.75-22.86c1.19,0.72,2.42,1.46,3.61,2.18c2.25-1.68,3.35-3.53,2.45-6.16c-1.62-4.73-0.54-9.15,1.83-13.45
				c-7.56-7.45-14.02-15.66-21.73-22.81c4.8-0.06,7.68,3.65,12.47,5.12c-4.97-6.14-10.37-10.4-16.35-14
				c-3.85-2.32-7.61-4.78-10.73-8.09c-3.1-3.3,0.05-5.44,1.48-7.78c1.7-2.79,3.59-1.39,5.33,0.12c3.5,3.03,6.98,6.09,10.49,9.16
				C339.13,757.44,339.83,754.63,342.49,753.96z M990.43,688.6c1.67-11.01,4.46-21.83,5.66-32.93c1.41-13.04,5.08-25.74,5.78-38.95
				c0.3-5.67,0.42-11.79,3.37-16.97c4.57-8.02,9.48-15.83,10.82-25.23c0.15-1.07,0.93-2.14,1.66-3.01c3.6-4.27,18.25-6.82,23.1-4.08
				c1.16,0.65,2.25,1.44,3.43,2.03c1.03,0.52,2.41,1.48,3.2,1.16c6.96-2.78,11.98-0.19,17.73,4.06c3.46,2.56,6.63,4.63,8.15,8.63
				c1.58,4.14,2.98,8.35,4.6,12.94c1.81-3.1,2.06-6.07,2.53-8.99c1.88-11.68,5.25-23,8.55-34.31c1.2-4.1,3.35-8.81,8.34-8.87
				c5.22-0.06,6.53,4.85,7.97,8.99c0.59,1.69,0.86,4.26,2.9,4.22c4.05-0.07,8.43,1.31,11.61-3.42c3.18-4.71,5.31-4.56,9.66-0.98
				c2.49,2.06,5.47,2.84,6-1.06c1.14-8.23,6.32-13.96,10.71-20.32c3.98-5.77,5.62-6.15,10.14-1.1c0.52,0.58,1.01,1.35,3.34,0.52
				c3.62-22.65,0.69-45.99,1.14-69.31c-4.37,4.81-8.18,9.63-11.6,14.71c-2.86,4.24-6.26,6.76-11.62,5.95c-2.77-0.42-5,0.74-6.14,3.27
				c-4.36,9.66-11.51,17.8-14.78,28.1c-1.64,5.18-4.64,9.92-6.98,14.88c-0.76,1.62-1.89,2.75-3.64,3.12
				c-6.07,1.26-9.56,5.62-12.74,10.44c-5.95,9.02-11.3,18.44-18.35,26.72c-4.67,5.48-6.82,5.78-12.16,0.85
				c-0.85-0.79-1.71-1.62-2.36-2.57c-2.33-3.37-5.19-3.74-8.64-1.84c-2.09,1.15-4.37,2.14-6.5,0.27c-3.36-2.94-6.8-2.23-10.72-1.25
				c-5.07,1.28-10.78,4.28-15.48,1.56c-5.72-3.31-11.41-4.57-17.73-4.82c-2.78-0.11-5.13-3-8.01-2.28
				c-6.54,1.64-12.85,0.33-19.26-0.66c-6.18-0.95-11.46,0.66-16.57,4.81c-5.57,4.53-7.74,11.08-11.79,16.46
				c-2.2,2.92-5.98,4.34-7.17,8.12c-1.42,4.52-4.64,7.71-8.51,10.09c-3.78,2.32-5.66,5.67-6.05,9.79c-1.3,13.83-4.3,27.28-9.17,40.24
				c-2.06,5.48-0.93,8.74,4.86,10.93c1.34-4.31,1.45-8.74,1.9-13.12c1-9.72,1.21-19.73,9.88-26.66c1.69-1.35,3.08-3.37,5.53-2.56
				c2.21,0.73,2.37,3.04,2.8,4.95c0.56,2.5,0.61,5.17,2.72,7.68c2.29-3.97,3.37-7.95,4.6-11.87c1.2-3.83,2.38-7.71,5.84-10.19
				c3.85-2.76,8.94-1.78,13.02-4.17c1.2-0.7,2.96,0.45,3.72,1.87c1.72,3.21,4.09,6.35,4.78,9.8c1.39,6.99,9.47,9.25,10.6,16.18
				c0.03,0.21,0.52,0.49,0.81,0.5c4.21,0.14,3.48,3.02,3.28,5.71c-0.82,11.3-1.91,22.63-5,33.51c-2.29,8.07-2.08,14.39,6.33,18.35
				c-0.92,5.33,1.82,10.24,1.38,16.33c4.49-2.51,7.21-6.85,12.52-6.25c1.76,0.2,3.82-2.33,5.89-0.42c2.14,1.97,1.53,4.57,1.13,7.04
				c-0.55,3.36-2.19,6.59-1.31,10.54c5.83-2.19,9.6-6.1,12.42-11.22c4.43-8.03,11.65-11.88,20.52-11.86c2.93,0.01,4.4-0.57,6.11-2.66
				c4.28-5.23,8.57-5.73,14.29-2.07c0.68,0.43,1.82,1.23,2.1,1.02c4.62-3.52,6.12,1.31,8.54,3.31c3.28-1.18,4.55-5.97,8.39-4.87
				c3.66,1.05,2.03,5.93,4.6,8.37c2-11.56,4.72-22.78,5.66-34.15c0.8-9.73,2.63-19.05,5.53-28.28c1.69-5.41,2.52-11.02,3.19-16.64
				c0.48-4.05-2.47-6.07-5.27-7.73c-2.44-1.45-3.98,0.97-5.3,2.54c-2.13,2.55-4.43,3.36-7.42,1.7c-3.18-1.76-5.73-0.23-8.24,1.58
				c-7.26,5.24-8.04,5.15-13.79-1.53c-4.35-5.05-5.3-5.11-8.95,0.79c-2.11,3.41-4.7,5.18-8.58,4.43c-3.88-0.75-7.89-1.42-11.49-2.96
				c-7.17-3.06-8.6-2.82-8.83,4.88c-0.65,21.51-4.02,42.7-6.73,63.97c-0.19,1.48,0.6,3.38-1.77,4.55c-1.21-4.03-0.7-7.93-0.13-11.72
				c2.66-17.93,5.66-35.8,5.4-54.03c-0.04-3.14,0.73-6.3,2.08-9.19c1.61-3.44,5.72-4.95,8.7-2.82c3.71,2.64,8.13,2.67,12.19,3.97
				c2.91,0.93,5.12-0.55,7.01-3.25c4.77-6.83,7.2-6.83,12.67-0.69c0.44,0.5,0.92,0.97,1.3,1.51c2.52,3.54,5.13,4.21,8.52,0.85
				c2.79-2.76,6.28-4.88,10.35-3.2c2.98,1.23,4.85,0.79,6.94-1.58c2.93-3.33,6.19-3.62,9.3,0.02c0.46,0.54,0.94,1.47,2.03,0.7
				c0.48-3.27-0.67-6.19-2.17-8.99c-1.29-2.41-3.67-3.74-5.73-1.91c-2.97,2.64-6.33,2.41-9.7,2.75c-6.25,0.63-8.22-0.82-10.06-6.98
				c-1.09-3.66-2.26-7.3-3.63-10.86c-0.82-2.15-1.94-4.07-4.37-5.14c-6.05-2.67-11.09-8.93-18.88-4.95
				c-0.12,0.06-0.36,0.03-0.49-0.05c-6.38-4.31-12.87-4.74-20.47-2.79c-5.29,1.35-7.73,1.91-7.98,7.59c-0.06,1.33,0.17,2.76-0.7,3.86
				c-4.5,5.65-5.1,13.37-10.06,18.82c-1.31,1.44-1.21,3.7-1.53,5.69c-1.98,12.31-2.6,24.8-4.92,37.07
				c-2.53,13.39-3.4,27.07-6.96,40.29C993.28,685.93,993.35,688.33,990.43,688.6z M599.56,582.7c1.8,0.62,1.97,3.61,4.43,3.24
				c1.66-1.47,0.25-2.56-0.33-3.57c-4.18-7.21-11.9-10.4-17.95-15.48c-1.65-1.39-5.41-1.55-6.53,0.21c-3.34,5.23-8.44,3.4-12.79,3.85
				c-8.12,0.84-17.48-10.67-14.67-17.99c2.14-5.58,4.86-10.87,4.82-17.1c-0.02-2.75,1.47-3.32,4.68-3c8.76,0.88,15.94,3.57,19.96,12
				c0.26,0.55,0.96,0.91,1.5,1.29c4.67,3.3,8.45,7.48,12.1,11.83c2.66,3.17,5.15,6.89,9.41,7.9c5.14,1.23,7.86,4.62,9.81,9.08
				c0.91,2.07,1.86,4.12,2.88,6.37c3.44-1.59,6.91-1.46,10.09-3.75c-4.21-4.61-1.82-10.23-2.88-15.21c-1.79-8.48-5.8-16.3-7.99-24.87
				c-3.5-13.72-13.07-24.29-24.28-33.13c-8.53-6.73-18.13-11.77-27.11-17.77c-2.94-1.97-6.24-3.68-7.54-7.52
				c0.71-0.31,1.2-0.74,1.57-0.65c10.08,2.42,19.51,7.02,29.81,8.74c2.18,0.37,3.58,2.35,4.97,4.01c4.88,5.85,9.19,12.21,15.51,16.74
				c0.91,0.65,1.56,1.72,2.24,2.66c8.26,11.51,13.48,24.91,22.39,36.04c1.1,1.38,1.51,3.31,2.22,4.99c0.7,1.64,1.4,3.29,2.09,4.94
				c0.39-2.31,0.31-4.32-0.12-6.25c-1.13-4.95-0.56-10.31-4.03-14.71c-6.04-7.66-10.3-16.68-17.99-23.1
				c-2.03-1.69-3.13-4.25-4.1-6.77c-1.02-2.64-1.39-5.02,1.79-6.42c-1.21-2.23-2.8-4.26-4.87-4.36c-6.34-0.32-9.52-4.95-13.19-8.91
				c-2.58-2.79-5.25-4.66-9.29-4.98c-8.81-0.7-17.44-2.16-24.42-8.51c-1.5-1.37-3.87-1.82-5.88-2.58c-1.4-0.52-2.88-0.78-3.87-2.08
				c-3.85-5.06-9.89-6.07-15.25-8.43c-5.49-2.41-11.66-0.95-16.95-3.19c-5.9-2.5-11.92-2.88-18.07-2.78
				c-4.91,0.08-9.14-1.31-12.78-4.61c-2.26-2.05-4.87-3.29-8.62-3.09c2.21,11.68,3.36,23.25,8.38,33.92
				c4.54,9.64,8.15,19.65,11.89,29.61c6.49,17.25,8.54,35.53,12.63,53.33c2.85,12.4,6.2,24.65,11.09,36.58
				c0.65-0.09,0.75-1.31,1.18-2.1c2.09-3.86,4.07-7.72,9.34-7.85c0.69-0.02,1.48-0.76,2.03-1.35c2.8-3.02,4.91-2,7.42,0.67
				c3.76,3.99,6.99,8.31,9.66,13.31c1.28-0.61,2.45-0.92,3.33-1.62c8.29-6.61,17.86-9.06,28.29-9.57c4.84-0.24,9.71-1.4,14.21-4.36
				c-10.94,0.05-16.16-8.7-23.69-13.9c4.37-2.01,7.65,1.22,11.37,1.13c6.57-0.17,9.64,4.08,12.17,9.14
				c-4.57-3.22-8.17-8.52-15.12-6.67C589.03,579.31,592.4,585.09,599.56,582.7z M988.32,1143.44c2.74-5.39,6.56-10.14,8.28-16.13
				c2.47-8.62,4.82-17.39,8.56-25.48c2.07-4.5,3.94-8.82,3.95-13.59c0.04-10.68,3.04-20.67,6.22-30.67c3.06-9.64,6.1-19.28,9.3-29.41
				c-7.9,1.36-12.8-4.93-19.35-7.05c-1.87-0.61-4.02-2.49-3.57-4.88c0.43-2.33,3.09-2.11,4.93-2.64c2.07-0.6,4.18-1.09,6.28-1.58
				c1.01-0.24,2.41-0.65,2.19-1.63c-0.96-4.28,1.69-8.65-1.11-13c-4.56-7.07-2.57-14.75-1.72-23.27c4.75,3.2,6.15,7.42,8.37,10.99
				c1.25,2.01,2.44,4.59,4.98,4.4c4.42-0.34,8.46,0.51,12.38,2.58c-4.52-4.85-12.69-5.06-14.87-12.07
				c-1.84-5.93-5.58-12.28,1.21-19.81c-0.02,10.39,8.49,9.13,13.76,12.68c3.11-4.1,6.49-3.52,10.11,0.49
				c2.34-11.83,5.18-22.88,4.03-34.25c-0.64-6.33-5.54-11.42-6.54-17.96c-0.29-1.87-1.57-3.8-1.18-5.98
				c0.78-4.42,1.54-8.86,3.19-13.06c2.31-5.88,4.82-11.68,7.09-17.57c1.12-2.92,2.76-5.9,0.65-9.29c-1.93,4.01-3.44,8.13-4.7,12.32
				c-1.54,5.13-3.47,10.07-6.53,14.49c-3.07,4.43-6.31,0.43-9.35-0.09c-2.99-0.51-1.76-3.07-1.35-4.9c0.75-3.41,0.97-7.19,2.66-10.08
				c4.72-8.12,2.29-13.45-4.82-18.67c-4.47-3.28-7.51-8.35-9.35-13.8c8.76,3.97,12,14.24,21.41,17.5
				c6.03-13.86,7.97-29.33,17.38-42.78c-2.96,0.5-4.72,0.98-6.49,1.06c-3.66,0.15-6.12-2.48-5.37-6c1.4-6.5,2.99-12.9,7.7-18.12
				c4.3-4.77,4.9-6.6,5.24-11.32c-2.64,7.78-7.88,11.85-13.51,15.54c-1.06-1.07-1.04-1.89-0.75-2.7c2.66-7.37,6.42-14.24,9.88-21.24
				c0.82-1.66,2.37-3.52,4.14-3.21c3.96,0.69,7.67,0.09,11.17-1.43c1.48,2.66,0.65,4.75,0.46,6.88c-0.91,10.36-5.46,19.86-6.97,30.07
				c-0.58,3.9-0.44,7.86,3.15,10.37c2.56,1.79,3.1,4.02,3.06,6.86c-0.04,2.97-0.6,6.09,1.5,9.58c1.72-3.42,3.14-6.2,4.51-9.01
				c1.97-4.03,3.39-8.45,5.97-12.03c7.47-10.35,14.99-20.61,19.68-32.62c0.35-0.91,0.91-1.85,1.62-2.5
				c7.69-6.88,7.1-17.03,10.76-25.44c-2.86-2.04-5.6-2.16-8.11-1.3c-11.62,3.98-20,1.54-26.39-8.07
				c-10.15,6.01-17.22,15.03-23.93,24.39c-1.46,2.03-2.67,4.18-5.02,5.41c-12.08,6.31-20.12,16.38-26.47,28.14
				c-3.81,7.05-6.36,14.64-9.82,21.83c-1.07,2.22-1.98,4.81-5.04,4.92c-3.17,0.11-4.55,2.35-6.04,4.59
				c-5.35,8.07-9.56,16.72-13.4,25.59c-2.76,6.38-5.44,12.99-9.42,18.59c-2.8,3.95-5.71,7.64-7.38,12.23
				c-3.93,10.8-8.6,21.27-14.2,31.32c-1.21,2.17-1.99,4.83-4.17,6.12c-4.28,2.55-6.42,6.61-8.52,10.79
				c-3.67,7.3-6.56,14.9-9.25,22.61c-2.73,7.82-0.51,12.91,7.32,15.51c2.98,0.99,4.24,2.64,4.58,5.69c0.28,2.52,0.28,5.37,3.55,6.57
				c1.42,0.52,1,2.32,1.02,3.61c0.13,7.83,0.03,15.64-1.71,23.35c-1.13,5-2.03,10.05-3.03,15.08c6.43-13.87,12.39-40.46,12.7-54.03
				c0.08-3.37-1.23-5.72-4.22-7.27c-1.84-0.95-3.52-2.15-2.67-4.67c2.14-6.33,3.04-12.93,4.48-19.41c0.77-3.46,2.65-6.74,6.55-6.31
				c3.89,0.44,3.41,4.26,3.56,7.23c0.1,1.83,0.14,3.67,0.38,5.48c0.27,2.02,0.9,4.61,2.74,5.04c6.2,1.46,6.52,6.68,8.05,11.24
				c0.34,1.01,0.37,2.3,2.12,2.64c2.33-6.93,4.12-13.96,5.18-21.19c0.4-2.72,0.87-6.25-0.91-7.66c-3.46-2.75-2.28-6.1-2.63-9.35
				c-6.24,0.17-4.69-4.68-4.83-7.88c-0.18-4.26,1.6-8.49,0.57-13.18c-3.12,1.14-3.96-0.24-3.46-3.11c0.4-2.29,0.63-4.61,0.97-6.91
				c1.48-9.81,6.54-14.61,16.63-15.79c3.75-12.99,4.24-26.75,9.13-39.51c-1.61,15.18-3.04,30.38-7.78,46.04
				c-3-3.69-5.38-1.84-7.97-1.16c-4.78,1.26-10.68,11.68-7.53,15.09c4.82,5.22,2.04,10.59,2.12,15.91c0.03,2.09-1.06,4.22,0.53,6.11
				c1.39,0.42,2.14-1.97,4.06-0.23c-0.48,5.19,0.41,10.59,3.16,15.59c0.3,0.55,0.35,1.3,0.34,1.96c-0.26,10.42-2.72,20.34-7.02,29.8
				c-0.08,0.17-0.59,0.15-1.01,0.25c-2.41-1.72-3.38-4.43-4.16-7.1c-1.16-4.01-2.28-7.61-6.88-9.35c-2.94-1.11-3.76-4.52-4.17-7.56
				c-0.42-3.06-0.59-6.16-0.89-9.49c-3.43,0.16-4.07,2.35-4.54,4.67c-1.15,5.7-1.85,11.5-3.76,17.04c-0.71,2.05,0.1,3.35,1.96,4.49
				c2.86,1.76,4.53,4.54,4.44,7.93c-0.12,4.47-0.27,9-1.04,13.39c-2.89,16.59-7.62,32.69-13.61,48.42c-0.47,1.24-0.45,3.11-2.57,3.1
				c-1.43-1.44-0.74-3.17-0.72-4.75c0.12-9.73,2.96-19.06,4.86-28.47c2.05-10.12-2.6-18.47-4.99-27.48c-0.13-0.51-0.88-1.1-1.43-1.22
				c-7.92-1.71-10.98-7.35-11.48-14.59c-2.59-0.74-5.05-1.56-6.25,0.92c-3.08,6.39-8.74,11.29-10.24,18.64
				c-0.89,4.36-2.2,9.24-5.92,11.75c-3.17,2.14-4.89,4.71-6.39,7.87c-1.07,2.24-1.83,4.71-3.71,6.45
				c-5.43,5.05-9.13,11.13-12.2,17.92c-3.48,7.69-8.08,14.88-12.21,22.28c-0.87,1.55-2.49,2.09-3.95,2.89
				c-10.15,5.54-17.93,13.06-20.51,24.87c-0.57,2.6-1.14,5.2-1.75,7.79c-2.63,11.19-7.49,21.71-10.57,32.67
				c-4.17,14.81-8.08,29.79-10.34,45.09c-0.17,1.13-0.63,2.88-0.09,3.34c5.05,4.39,4.13,11.04,6.48,16.39
				c3.5,7.95,3.31,16.16,2.59,24.49c-0.25,2.9-0.86,5.8-0.21,8.77c2.93-0.17,4.18-1.45,4.62-3.99c0.85-4.91,1.96-9.78,2.94-14.67
				c1.18-5.91,3.06-11.54,6.26-16.72c1.38-2.25,4.89-2.63,5.36-4.85c0.93-4.44,3.71-8.5,3.89-12.79c0.42-9.86,4.34-18.74,6.56-28.07
				c1-4.22,2.3-8.47,5.55-12.23c0.44,0.95,0.87,1.43,0.78,1.74c-3.74,12.25-3.71,25.34-8.47,37.37c-1.23,3.11-1.48,6.39-1.01,9.8
				c0.82,5.97,0.5,11.8-1.36,17.72c-2.49,7.91-4.22,16.06-6.07,24.16c-0.44,1.94-1.21,4.1,0.1,6.63c4.95-4.2,10.34-7.26,13.79-12.55
				c2.02-3.1,4.75-5,8.5-5.46c4.36-0.53,8.73-2.94,10.22-6.28c2.84-6.36,4.14-13.46,4.79-20.35c-7.06-0.27-11.23,2.64-13.45,8.97
				c-0.75,2.13-2.38,4.93-6.98,4.47c7.31-24.53,16.61-48.4,17.33-74.42c-4.12,0.25-6.95-0.7-7.45-5.04c-0.17-1.48-1.75-1.85-3-2.31
				c-6.37-2.34-10.52-6.54-11.35-13.55c-0.05-0.4-0.55-0.75-0.81-1.08c-7.02,1.29-7.85,0.66-6.02-6.3
				c2.07-7.87,4.71-15.59,7.26-23.32c1.13-3.42,3.54-5.66,7.33-5.81c4.35-0.17,6.45-3.22,6.93-6.67c0.65-4.67,2.78-8.83,3.62-13.42
				c0.75-4.08,3.23-7.06,7.96-6.76c0.94,1.96,0.31,3.42,0.16,4.88c-0.5,5.07,0.33,9.91,2.38,14.58c1.12,2.55,2.47,3.22,5.34,2.31
				c4.22-1.34,5.12-4.98,6.66-8.17c4.59-9.5,1.99-21.17,8.97-29.97c0.09-0.11,0.06-0.32,0.09-0.49c1.02-6.74,1.95-13.49,3.11-20.21
				c0.59-3.37,1.3-6.83,4.89-9.35c1.82,3.81,4.63,6.53,7.81,8.84c5.07,3.69,7.43,8.19,4.8,14.45c-0.37,0.87-0.22,1.97-0.27,2.97
				c-0.39,7.45-0.96,14.83-0.44,22.38c0.29,4.23,0.19,7.04,4.36,8.88c1.84,0.81,2.01,2.79,1.67,4.79
				c-2.18,12.77-4.31,25.55-6.4,38.33c-1.39,8.49-3.84,16.82-4.43,26.97c5.59-12.24,8.61-23.75,11.95-35.17
				c1.91-6.53,1.78-13.81,5.57-19.55c3.15-4.77,5.02-9.63,6.63-15.07c2.93-9.88,6.26-19.61,7.75-29.86c0.31-2.13,0.91-4.21,3.79-5.71
				c1.59,5.4,6.65,7.65,10.11,11.77c1.78-1.73,3.8-2.2,5.58-0.09c2.14,2.52,5.81,4.07,4.76,8.58c-2.77,11.99-5.24,24.04-7.98,36.03
				c-1.3,5.67-2.76,11.28-3.39,17.11c-0.59,5.53,2.57,9.42,4.87,13.78c1.59,3,4.49,1.43,6.73,2.02c3.52,0.93,5.23,1.7,4.08,6.32
				c-2.62,10.49-5.69,20.83-8.7,31.22c-1.56,6.14-5.4,11.31-7.4,17.25c-0.64,1.9-1.45,4.1,2.36,3.66
				C984.51,1157.34,988.07,1150.8,988.32,1143.44z M624.91,1485.97c6.53,2.49,13.32,3.85,20.67,4.48c0.16-3.73,2.96-4.5,6.01-4.96
				c3.31-0.5,6.64-0.56,9.96-0.12c15.25,2.02,29.8,6.7,44.18,11.93c2.23,0.81,4.38,1.33,6.75,1.33c43.79-0.03,87.57-0.02,131.36-0.06
				c1.36,0,2.99,0.69,4.1-0.82c-5.69-7.08-16.19-8.81-23.1-3.57c-2.17,1.65-4.02,1.69-6.44,0.99c-8.31-2.41-16.39-5.54-25.52-4.05
				c-3.58,0.58-8.05,1.28-11.52-1.55c-5.27-4.3-10.22-2.82-14.94,0.87c-1.59,1.24-3.18,1.83-5.06,1.34
				c-9.13-2.38-18.86-2.51-27.78-2.08c-8.39,0.41-15.44,0.72-22.85-3.62c-6.67-3.91-14.77-2.09-22.19-3.89
				c-15.32-3.71-29.9-8.77-43.54-16.67c-4.13-2.39-8.51-4.32-13.11-5.53c-2.03,3.79-2.03,3.79,0.03,7.95
				c1.94,3.92-0.2,7.55-4.97,8.25c0.49-2.45,2.85-3.62,3.8-5.49c-3.98-7.28-3.46-14.07,1.05-20.69c0.95-1.39,1.19-2.84,0.13-4.61
				c-4.25-7.15-3.94-9.63,1.49-15.14c-4.18-4.13-2.63-10.29-5.34-15.09c-2.52-4.47-3.12-10.53-10.53-9.9
				c-1.54,0.13-2.22-1.81-2.25-3.42c-0.13-6.04-4.66-10.39-10.3-12.23c-3.66-1.2-6.87-3.09-10.87-1.9c-0.47,0.14-1.42-0.37-1.72-0.85
				c-3.18-4.92-6.8-2.95-10.59-0.96c1.92,3.19,2.75,6.49,6.62,8.37c3.38,1.64,5.14,6.01,7.75,8.82c3.4,3.67,3.43,6.52,1.12,10.04
				c-1.17,1.78-1.55,3.04-0.11,4.83c3.05,3.81-0.96,6.08-2.38,8.7c-1.17,2.17-3.69,2.76-6.17,1.9c-3.42-1.18-2.8-4.05-2.7-6.7
				c0.02-0.46-0.3-0.92-0.42-1.29c-0.99-0.79-1.95-0.58-2.94-0.34c-6.92,1.69-7.47,1.23-6.54-5.71c0.29-2.13-0.22-3.63-2.49-4.12
				c-2.09-0.45-4.18-1.02-6.3-1.25c-7.63-0.82-7.64-0.78-8.68-7.96c-1.33-0.48-2.2,0.45-3.16,1.05c-2.29,1.42-4.28,1.09-5.34-1.39
				c-1.57-3.68-3.9-3.62-6.71-1.72c-2.27,1.54-4.37,1.24-6.45-0.28c-2.44-1.79-4.35-2.11-6.79,0.57c-3.4,3.74-6.57,3.49-10.4,0.21
				c-1.34-1.15-2.31-2.92-4.69-2.89c-3.55,4.69-8.8,5.94-14.41,6.11c-2.42,0.08-5.52-0.8-5.48-3.25c0.06-4.08-1.7-5.11-5.13-5.46
				c-0.98-0.1-2.28-1.08-2.7-1.99c-1.72-3.81-3.49-3.85-6.72-1.29c-4.75,3.76-8.75,2.6-12.4-2.72c-1.34-1.96-3.19-1.42-4.81-0.47
				c-1.13,0.66-1.95,1.84-3.07,2.51c-2.22,1.33-4.48,1.1-6.49-0.53c-1.57-1.28-1.35-2.75-0.47-4.45c2.12-4.05,1.22-5.41-3.44-5.28
				c-3.04,0.09-6.85,0.52-7.9-2.7c-1.32-4.03-4.85-8.27-1-12.94c0.85-1.03,2.24-2.03,1.94-4.48c-5.12,0.19-10.61,2.22-13.33,5.17
				c-5.04,5.47-10.92,2.65-16.23,4.04c-3.05,0.8-5.71-0.85-7.47-3.77c-1.47-2.44-3.78-3.59-6.76-3.41
				c-5.73,0.36-12.54,6.17-13.69,11.85c-0.72,3.56-2.41,5-5.92,2.87c-1.29-0.79-2.56-1.18-4.18-0.33c-4.3,2.26-10.27,2.21-11.44,8.66
				c-0.07,0.4-0.71,1.04-1,1c-4.5-0.67-5.67,3.2-7.75,5.71c-2.96,3.56-2.52,6.91,1.51,9c10.18,5.29,21.13,8.91,32.26,11.06
				c14.82,2.86,30.24,3.07,44.61,7.92c9.21,3.11,18.33,4.45,27.85,4.05c9.93-0.42,19.5,1.18,29.01,3.98
				c10.17,2.99,20.22,6.32,30.25,10.54c0.32-1.61,0.49-2.75,0.78-3.85c1.56-5.83,3.55-7.1,9.36-5.89c3.04,0.63,6.15-1.23,6.77-2.96
				c2.01-5.64,4.85-4.28,8.48-1.57c2.16-3.55,4.74-3.97,8.8-3.22c4.57,0.85,9.18,0.21,11.12,5.72c0.05,0.14,0.61,0.08,0.93,0.15
				c5.94,1.2,7.18,2.79,6.88,9.09c5.95,0.42,6.34,9.63,13.8,8.44c1.43-3.34,4.96-4.85,7.41-7.12c5.9-5.49,8.38-2.7,11.68,1.88
				c1.05,1.46,2.23,3.14,3.76,3.87c3.46,1.64,4.02,4.38,4.22,7.72c0.12,2.12,0.87,4.14,3.4,4.95c6.61,2.1,8.34,7.42,4.5,13.63
				c-1.06-2,0.28-3.94,0.06-5.88c-0.32-2.72-0.7-5.77-4.29-5.56c-3.68,0.21-5.26-2.4-5.32-4.7c-0.11-4.59-2.75-7.49-5.61-10.02
				c-2.51-2.22-3.71-6.04-7.68-6.85c-1.62,2.43-3.15,4.83-6.54,4.71c-1.06-0.04-1.49,1.04-1.99,1.96c-2.84,5.21-7.84,5.65-11.76,1.05
				c-0.97-1.14-1.68-2.49-2.65-3.62c-1.31-1.51-2.75-1.92-4,0.12c-0.82,1.35-1.27,3.64-3.49,2.17c-1.93-1.27-1.16-3.25-0.29-4.94
				c0.61-1.18,1.54-2.23,1.97-3.46c0.77-2.19-0.15-3.83-1.99-4.86c-1.57-0.05-1.97,2.14-3.77,1.42c-1.48-1.09-1.56-3.07-2.4-4.66
				c-0.9-1.69-2.34-3.58-4.23-2.32c-2.78,1.86-4.76,0.61-7.09-1.12c-2.44,4.09-5.23,6.67-9.38,2.52c-6.17,7.06-7.8,8.17-15.05,5.19
				c-5.07,5.8-4.33,10.05,2.56,12.97c4.27,1.81,8.78,3.06,13.17,4.6c2.82,0.98,6.2,1.42,8.06,3.72c3.65,4.51,8.58,6.86,13.59,8.99
				c23.58,10.01,47.72,18.49,72.43,25.3c2.12,0.58,4.65,0.41,6.96,3.26c-6.94-0.29-12.66-2.46-18.56-3.44
				c-7.82-2.78-15.45-6.35-23.48-8.2c-27.38-6.33-49.75-23.94-75.84-33.09c-0.15-0.05-0.28-0.18-0.43-0.26
				c-21.54-11.16-44-18.76-68.7-17.84c-6.01,0.22-12.04-0.05-17.73-2.15c-10.53-3.87-21.42-6.14-32.53-7.07
				c-16.21-1.35-31.58-5.69-46.37-12.21c-4.96-2.18-10.3-4.49-10.15-11.48c-3.29-0.32-6.26-0.58-9.22-0.9
				c-16.32-1.78-32.39-5.63-48.91-5.88c-4.11-0.06-8.66-0.08-12.01-2.72c-5.2-4.1-11.07-3.91-17.04-3.93
				c-1.64-0.01-3.38,0.53-6.31-0.99c8.41-1.98,15.45-2.43,21.86,1.33c3.27,1.92,6.38,3.4,10.11,3.63c6.81,0.41,13.77,0.05,20.38,1.38
				c13.07,2.64,26.33,3.51,39.47,5.39c2.53,0.36,4.64-0.18,5.96-2.54c2.62-4.71,9.85-4.72,11.11-10.88c0.08-0.38,1.13-0.76,1.75-0.8
				c5.15-0.35,9.56-5.93,16.1-0.69c-1.4-8.84,3.98-11.28,9.27-14.13c5.84-3.15,10.96-2.71,15.72,2.16
				c3.85-16.37,6.14-52.39,4.07-63.38c-2.04,1.5-4.05,2.9-6.79,2.2c-2.04-0.52-3.42,0.76-4.75,2.12c-3.33,3.43-6.9,4.52-10.77,0.63
				c-4.06,4.2-9.69,3.67-14.4,5.64c-5.24,2.19-8.04,0.69-10.84-4.43c-2.59-4.73-4.6-5.2-9.83-2.89c-3.68,1.62-7.88,2.98-11.45,0.54
				c-2.94-2.01-5.31-1.91-8.27-0.72c-3.32,1.33-6.23,0.74-8.05-2.71c-0.55-1.05-1.11-2.05-2.46-2.18c-7.44,4.83-7.79,4.81-16.14-0.93
				c-0.27-0.18-0.66-0.5-0.86-0.42c-5.63,2.21-10.32-2.97-15.94-1.87c-5.16,1.01-5.17-4.12-6.93-6.99c-1.52-2.48,0.34-3.84,1.97-5.42
				c2.38-2.3,5.3-3.4,8.11-4.92c4.51-2.43,9.58-4.78,11.86-10.16c0.43-1.02,2-1.22,3.18-0.95c4.95,1.12,6.76-3.15,7.96-5.95
				c1.3-3.03,2.58-5.17,5.58-6.34c1.82-0.71,2.94-2.09,3.5-4.57c-16.03-6.68-31.09-15.26-45.82-24.67
				c-13.68-8.74-29.08-14.69-42.13-24.67c-2.56-1.96-5.8-2.87-9.16-2.7c2.27,12.49,9.98,23.45,7.59,36.65
				c3.79,0.29,6.03-2.32,8.56-4.4c3.51-2.88,7-2.22,7.2,2.04c0.28,5.77,5.41,12.36-2.79,17.46c6.74,4.97,3.55,11.56,4.08,17.76
				c1.61-0.65,2.94-1.28,4.33-1.73c4.77-1.54,8.67,1.63,7.5,6.43c-2.31,9.47-6.74,18.68-2.7,28.75c0.22,0.55,0.09,1.38-0.16,1.96
				c-2.13,5,0.92,7.61,4.47,10.1c5.31,3.71,6.5,8.68,4.31,14.81c-2.01,5.63-5.31,10.2-9.63,14.21c-4.05,3.76-3.55,7.29,1.3,9.68
				c1.61,0.79,1.96,2.18,2.22,3.65c0.89,5.02-2.29,12.91-6.82,15.08c-5.81,2.78-12.12,4.4-17.93,7.28c-1.46,0.73-3.3,0.4-4.86-0.35
				c-5.37-2.6-10.77-4.24-16.69-1.61c-0.94,0.41-2.34,0.09-3.43-0.23c-3.64-1.07-7.08-0.56-10.77,0.23
				c-5.01,1.08-9.84-0.41-14.22-2.9c2.56-0.34,5.06-0.34,7.39,0.28c2.67,0.71,5.2,1.08,7.81,0.03c2.88-1.16,5.76-1.28,8.77-0.41
				c1.9,0.55,3.94,0.99,5.89,0.16c4.89-2.09,9.41-1,13.86,1.36c3.01,1.6,6.16,1.64,9-0.24c3.27-2.16,6.92-3.23,10.61-4.2
				c2.16-0.57,4.04-1.57,5.58-3.15c2.83-2.9,4.47-11.66,2.22-12.27c-4.58-1.24-4.94-4.72-5.56-8.4c-6.35,5.58-10.74,5.23-16.14-1.12
				c-0.97-1.14-2-2.28-2.69-3.58c-2.06-3.92-4.91-3.91-8.28-1.93c-1.43,0.84-3.07,1.44-4.3,2.51c-8.78,7.7-17.17,2.5-25.92-0.18
				c2.35-8.02,12.52-7.74,14.58-15.31c-4.26-4.04-9.55-5.78-17.88-2.68c-7.65,2.85-14.91,1.36-22.35,1.13
				c-2.87-0.09-6.6-0.17-7.96-3.21c-2.65-5.94-7.32-6.08-12.48-5.24c-2.26,0.37-4.44,0.42-6.18-1.01c-2.53-2.07-5.43-3.04-8.47-3.98
				c-7.69-2.37-9.29-9.3-4.15-15.49c2.43-2.93,5.72-4.32,8.26-6.52c1.91,0.15,1.98,2.6,4.09,2.37c0.46-1.08,0.73-2.48,1.53-3.43
				c1.49-1.76,3.34-3.68,5.66-3.62c2.39,0.06,3.7,2.44,4.05,4.54c1.14,6.86,5.56,10.68,11.47,13.25c2.09,0.91,2.87,2.15,2.91,4.55
				c0.1,5.77,1.63,6.86,8.91,6.72c-4.87-3.59-5.46-8.49-5.57-13.5c-0.1-4.45-2.77-7.31-5.76-10.04c-1.59-1.45-3.69-2.69-3.99-4.96
				c-1.28-9.53-9.37-14.89-13.86-22.46c-4.29-7.23-4.34-13.11,2.79-17.66c2.72,1.21,3.44,3.84,4.4,6.31
				c1.57,4.02,3.13,8.05,4.71,12.07c0.84,2.15,2.4,3.54,4.59,4.25c2.85,0.92,3.88,0.29,4.42-3.06c1.23-7.56,6.03-14.32,5.22-22.41
				c-0.01-0.14,0.11-0.31,0.21-0.45c3.28-4.4,3.4-9.54,3.55-14.7c0.15-5.17-1.75-10.24-0.86-15.4c0.45-2.6,0.24-5-1.05-7.23
				c-2.16-3.74-1.51-7.49,0.22-10.99c1.18-2.39,1.45-4.52,0.99-7.13c-0.93-5.29,0.61-6.5,5.51-4.88c2.31-4.53,0.19-8.7-1-13.09
				c-2.36-0.13-4.67-0.27-6.99-0.38c-2.56-0.12-4.3,1.16-4.62,3.6c-0.87,6.58-4.35,12.12-6.84,18.06c-1.06,2.53-2.82,4.54-5.39,4.5
				c-2.58-0.05-3.55,1.21-5.19,2.75c-3.57,3.34-7.54,6.15-9.44,10.96c-0.52,1.33-2.16,1.9-3.69,2.32
				c-4.66,1.28-11.71,10.26-11.72,15.18c-0.01,5.46-4.89,10.45-1.49,16.21c0.11,0.19-0.07,0.78-0.28,0.9
				c-3.29,1.92-2.74,5.95-4.67,8.5c-2.58,3.43-1.52,5.81,0.97,8.64c5.06,5.75,3.44,12.29-2.2,17.14
				c-7.38,6.36-11.34,11.38-3.88,19.98c3.57,4.11,4.98,9.39,3.27,14.98c-0.31,1.03-0.56,2.08,0.28,2.78c3.65,3.09,3.21,7.89,5,11.75
				c1.48,3.19,0.27,5.93-3.04,7.34c-1.71,0.73-3.16,1.41-3.25,3.86c5.91-1.22,11.57-2.48,15.68,3.18c4.69,6.47,6.44,6.94,13.36,3.28
				c2.54-1.35,5.26-1.8,7.66-0.45c4.57,2.56,9.61,2.4,14.48,3.07c4.88,0.68,7.77,3.12,7.48,7.98c-0.25,4.11,0.33,8.73-4.47,11.39
				c-1.54,0.85-2.48,3.04-2.33,5.18c9.72,4.32,10.14,4.15,12.74,10.45c2.01,4.87,5.24,8.13,9.77,10.01c5.7,2.36,9.8,6.05,12.07,11.76
				c0.52,1.3,1.24,2.73,2.66,2.65c3.26-0.18,5.43,1.99,8.11,3.01c7.11,2.73,9.82,8.56,12.06,14.93c0.86,2.45,1.25,4.44,4.92,4.04
				c3.22-0.35,5.88,2.21,7.79,4.79c1.98,2.68-1.44,3.96-2.02,6.02c-0.22,0.77-0.5,1.53-0.85,2.62c2.32,0.7,3.08,2.98,4.99,4.13
				c5.19,3.13,8.11,7.48,7.51,13.87c-0.22,2.39-0.35,4.85,2.34,6.58c4.87,3.13,5.6,8.36,5.92,13.58c0.07,1.07-0.96,2.33,0.38,3.15
				c5.99,3.64,7.83,8.71,4.89,15.53c91.77,0,183,0,274.53,0c-1.33-2.03-3.2-2.46-4.8-3.19c-7.56-3.46-15.19-6.77-22.77-10.19
				c-5.18-2.33-10.16-5.06-14.23-9.08c-2.74-2.71-5.58-5.85-4.66-9.96c0.67-2.99-0.4-4.63-2.14-6.53c-2.87-3.14-6.22-6.14-4.35-11.26
				c-5.32-1.96-10.23-4-15.29-5.58c-9.83-3.07-19.61-6.45-29.89-7.74c-3.78-0.47-8.77-0.94-10.53,2.59c-2.26,4.51-5.01,4.09-8.5,3.22
				c-12.26-3.05-24.53-6.06-36.74-9.29c-6.29-1.66-12.8-3.67-17.41-8.25c-3.94-3.91-8.43-5-13.3-5.82c-3.6-0.61-7.28-0.77-10.91-1.23
				c-7.75-0.97-15.57-1.64-23.04-4.09c-3.04-1-6.33-2.05-7.23-5.68c2.16-3.24,5.94-1.71,9.55-3.82c-5.03-0.9-8.89-1.82-12.81-2.26
				c-10.02-1.11-20.33-0.83-29.03-7.36c-1.18-0.89-3.18-0.84-4.82-0.93c-7.76-0.43-14.59-4.39-22.12-5.8
				c10.27-0.14,20.12,2.32,29.62,5.61c3.45,1.19,6.67,3.57,10.49,4.09c5.6,0.76,11.24,1.21,16.83,2.02
				c5.61,0.81,11.4,1.16,16.43,4.34c-2.11,4.98-7.09,2.79-10.22,5c2.45,2.44,5.42,2.97,8.31,3.59c9.61,2.07,19.43,2.61,29.14,4.02
				c4.22,0.61,9.08,0.61,12.07,3.91c3.07,3.39,6.67,5.55,10.69,7.02c15.17,5.54,31.08,8.46,46.62,12.71c1.13,0.31,2.3,0.48,3.05-0.85
				c3.53-6.28,9.56-6.18,15.3-5.25c11.34,1.84,22.34,5.17,33.11,9.16c4.62,1.71,9.5,2.85,13.97,5.68c-3,3.99-1.12,6.84,1.66,9.7
				c1.9,1.95,5.26,3.63,4.41,6.77c-1.66,6.15,2.07,9.32,5.93,12.54c4,3.33,8.74,5.45,13.47,7.52c9.14,4,18.51,7.54,27.11,12.63
				c5.08,3,10.2,2.48,16.46,2.05c-12.84-7.72-24.8-14.88-36.73-22.11c-3.85-2.33-7.55-4.89-10.32-8.55
				c-1.12-1.47-2.75-3.19-2.11-4.87c1.57-4.14-0.75-6.15-3.33-8.62c-2.32-2.22-3.97-5.24-1.76-8.49c2.18-3.21,5.07-1.24,7.69-0.53
				c1.11,0.3,2.21,0.75,3.23,1.28c17.86,9.22,36.99,15.48,55.13,24.23C589.8,1478.25,607.6,1481.85,624.91,1485.97z M1159.45,70.52
				c2.74-0.56,1.87-2.78,1.87-4.41c0.05-20.15,0.04-40.31,0.01-60.46c0-1.12,0.27-2.35-0.76-3.5c-220.3,0-440.73,0-661.42,0
				c12.86,17.91,7.19,41.31-13.82,61.05c0.87,0.25,1.63,0.49,2.4,0.69c8.41,2.18,15.65,5.83,18.46,14.91
				c0.42,1.37,1.31,1.78,2.52,1.99c2.94,0.53,5.75,1.4,8.28,3.53c2.8-4.93,5.19-9.69,9.56-13c9.2-6.95,19.83-8.33,30.8-7.52
				c7.97,0.58,15.55,2.82,20,10.36c1.45,2.46,3.1,3.24,5.88,3.49c9.47,0.82,16.98,4.65,19.8,14.63c0.28,0.98,0.45,1.98,1.52,2.41
				c4.29,1.74,6.73,6.09,10.44,8.16c17.73,9.92,20.94,42.18,2.18,53.65c0.77,2.28,1.54,4.6,2.42,7.23c4.11-0.68,8.02-1.29,11.93-1.96
				c6.86-1.18,13.15-0.06,17.56,5.68c2.43,3.17,4.97,3.95,8.81,3.86c15.41-0.37,26.22,12.23,23.89,27.39
				c-0.27,1.76-1.09,3.5-0.13,5.69c1.03,0,2.16,0.03,3.29-0.01c8.89-0.29,15.64,2.69,18.37,11.93c0.21,0.72,0.77,1.71,1.37,1.9
				c6.11,1.84,11.04,5.75,16.48,8.76c10.53,5.83,10.95,5.05,23.65,2.32c2.6-0.56,5.2-1.3,7.84-1.47
				c10.19-0.65,19.58,1.97,25.75,10.29c3.37,4.54,5.96,8.68,12.13,8.99c1.12,0.06,2.2,1.37,3.24,2.18c4.11,3.24,9.78,4.13,12.99,8.8
				c0.31,0.46,1.92,0.21,2.87,0.02c12.82-2.54,22.96,0.94,29.27,13.43c2.22-2.84,4-5.44,6.11-7.73c4.17-4.54,9.37-7.06,15.58-6.05
				c2.48,0.4,4.38,0,6.63-0.88c8.02-3.16,16.91-1.6,25.06-4.27c0.28-0.09,0.72-0.02,0.97,0.16c4.52,3.21,7.71,0.22,11.02-2.31
				c1.59-1.21,3.21-2.38,4.87-3.48c12.09-7.97,27.17-5.49,36.07,5.94c4.82,6.19,7.76,13.25,8.5,21c0.36,3.76,1.69,4.92,5.23,4.56
				c2.87-0.29,6.7-0.18,7.85,2.16c3.1,6.3,9.35,7.12,14.5,9.96c1.56,0.86,3.1,2.14,4.05,3.62c1.73,2.71,3.75,3.04,6.71,2.36
				c12.47-2.89,22.95,1.21,31.92,9.79c4.65,4.45,6.53,9.94,5.13,16.36c-0.7,3.2-1.48,6.39-2.3,9.92c4.68,0.75,6.43,5.97,10.52,6.49
				c6.48,0.83,11.24,4.02,15.08,8.8c2.14,2.66,4.59,4.14,7.87,4.68c10.49,1.74,16.21,9.13,20.7,17.83c1.55,3.01,0.03,8.57-2.85,9.57
				c-3.58,1.25-6.91,3.13-10.77,3.72c-11.78,1.79-23.61,1.38-35.37,0.47c-31.19-2.43-62.42-4.38-93.68-5.5
				c-27.44-0.99-54.91-1.45-82.38-1.25c-3.5,0.03-7.18,0.3-10.27-1.68c0.14-3.18,3.81-3.47,4.28-6.65
				c-7.92-1.38-15.63-0.35-23.25,1.07c-19.99,3.74-40.08,6.33-60.45,6.44c-51.77,0.28-103.55,0.63-155.29-1.19
				c-19.91-0.7-39.9-1.74-58.94-8.86c-4.85-1.82-10.07-2.12-15.26-2.13c-1.32,0-2.63-0.08-3.78,0.87
				c-11.38,9.41-25.2,10.44-39.05,11.27c-29.61,1.76-59.25,0.92-88.88,1.03c-19.47,0.07-38.93,1.36-58.41,0.04
				c-5.53-0.37-10.82-0.94-15.11-5.25c-2.21-2.23-5.46-3.1-8.57-3.8c-7.35-1.65-14.83-2.21-22.32-1.82
				c-15.78,0.81-31.54,2.21-47.34,2.75c-21.95,0.75-43.93,0.86-65.89,1.42c-4.24,0.11-9.37-0.87-12.49,2.11
				c-6.7,6.4-14.8,7.88-23.33,8.58c-20.94,1.72-41.93,0.68-62.9,0.9c-3.94,0.04-7.39,0.77-10.79,2.99
				c-5.91,3.85-12.52,6.14-19.54,7.13c-16.72,2.35-33.54,1.89-50.34,1.75c-1.93-0.02-3.98-0.67-5.76,0.77c0,6.61,0,13.17,0,20.38
				c17.64-0.04,34.93,1.01,52.05-1.97c8.91-1.55,17.75-2.91,26.76-1.63c13.52,1.93,27.07,2.94,40.75,2.52
				c7.51-0.23,14.89-1.46,22.36-1.93c10.37-0.65,12.2,0.39,16.74,9.49c0.13,0.27,0.48,0.43,1.11,0.99
				c9.52-2.72,19.56-3.83,29.75-3.56c0.76,0.02,2.01-0.22,2.22-0.69c2.72-6.11,8.62-6.78,13.86-7.21c14.84-1.24,29.77-1,44.65-2.02
				c6.88-0.47,13.89,1.03,20.85,1.49c4.43,0.29,9.12,1.3,13.28,0.28c11.48-2.82,22.92-1.82,34.24-0.34
				c16.14,2.11,32.08,5.63,48.22,7.94c12.86,1.85,25.95,1.29,38.7,3.86c2.88,0.58,4.75,0.13,6.45-2.14c2.49-3.3,5.88-4.66,10.02-4.52
				c14.88,0.52,29.78,0.75,44.64,1.53c5.47,0.29,12.12-1.29,15.02,5.96c0.09,0.23,0.57,0.47,0.84,0.45
				c6.95-0.48,13.27,4.43,20.05,3.06c8.36-1.7,16.47-1,24.67,0.2c5.52,0.81,10.91-0.87,16.3-0.83c7.95,0.06,15.9,1.17,23.82,2.14
				c3.57,0.44,6.71,0.18,10.17-1.11c5.51-2.06,12.51-3.26,16.89-0.14c7.27,5.18,15.45,10.23,19.39,18.97
				c1.87,4.14,4.77,5.61,8.95,5.5c8.76-0.23,17.85,1.73,24.96-6.28c2.72-3.06,7.43-1.77,10.79,0.01c5.33,2.82,10.74,3.18,16.44,2.16
				c10.01-1.79,20.11-3.75,30.23-2.12c12.44,2,24.86,1.4,37.32,1.31c8.98-0.06,17.99-0.11,26.77,2.27c4.72,1.28,9.56,1.84,14.34,1.22
				c16.58-2.16,32.77,0.51,48.99,3.42c7.34,1.32,14.74,3.46,22.25,2.21c21.61-3.6,42,3.47,62.33,8.14
				c18.66,4.29,36.42,12.52,54.52,19.18c5.26,1.94,10.46,3.01,16.09,2.19c5.97-0.87,12.02-0.88,17.79,1.12
				c8.53,2.96,16.82,2.76,25.35-0.22c6.46-2.27,13.3-3.61,20.09-1.34c10.49,3.5,20.58,2.46,30.67-1.6
				c6.82-2.75,13.91-3.36,20.97-0.81c2.28,0.82,3.67,0.38,5.03-1.63c2.25-3.32,5.49-5.29,9.51-5.78c2.43-0.3,4.49-1.31,6.14-3.04
				c5.17-5.42,11.9-9,16.97-14.54c1.81-1.98,4.25-3.07,7.03-3.45c6.05-0.83,11.77-7.63,11.77-13.83c0-100.27,0.01-200.54-0.02-300.81
				c0-1.78,0.46-3.67-0.9-5.74c-4.68,0.43-9.55,0.88-14.98,1.38c2.62,4.3-0.03,4.38-2.52,4.68c-11.11,1.34-22.22,0.99-33.36,0.36
				c-6.74-0.38-13.72,0.75-19.07-4.97c-0.55-0.59-1.83-0.7-2.77-0.7c-19.8-0.15-39.6-1.39-59.4,0.11c-3.01,0.23-6.29,0.5-8.59,2.26
				c-3.97,3.04-8.31,2.96-12.78,2.89c-9.16-0.13-18.31-0.26-27.46-0.52c-4.98-0.14-9.93-0.55-14.73-2.25
				c-3.34-1.18-4.03-3.27-2.64-6.17c2.09-4.39,5.64-7.62,9.37-10.55c6.35-4.99,13.61-5.68,21.53-3.48c0.64-5.77,4.54-7.5,9.18-8.6
				c3.13-0.75,6.25-0.94,9.41-0.79c2.55,0.12,4.1-0.81,4.56-3.97c-3.97,0-7.79-0.01-11.6,0c-27.25,0.05-54.32-4.72-81.67-2.64
				c-26.22,1.99-52.51,2.64-78.82,2.16c-5.82-0.11-11.67-0.39-17.46,0.05c-22.55,1.7-45.07,3.73-67.62,5.44
				c-4.74,0.36-9.22-0.85-12.62-4.73c-2.02-2.3-1.7-4.04,0.63-5.78c4.01-3,8.54-4.42,13.5-4.49c4.52-0.06,9.18-1.86,11.67-4.63
				c3.87-4.31,7.98-4.75,12.9-4.62c1.73,0.05,3.8,1.03,5.13-0.82c3.06-4.26,7.51-3.8,11.78-3.41c3.58,0.32,6.35,0.6,8.78-3.31
				c2.24-3.6,6.81-3.78,10.94-2.98c4.64,0.9,8.76,3.07,12.81,5.41c1.5,0.87,2.8,2.36,4.81,2.08c5.02-19.62,16.65-21.58,27.4-15.23
				c2.96-5.98,7.84-8.97,14.48-9.89c3.15-0.44,6.77-1.68,8.95-3.82c3.14-3.07,6.22-4.09,10.62-2.85c-0.02-6.11,2.27-10.91,6.65-14.71
				c9.01-7.82,18.88-9.09,29.65-4.03c1.37,0.64,2.84,2.53,4.44,1.06c3.21-2.97,6.97-2.65,10.72-2.25
				c6.33-12.3,11.48-15.11,23.7-12.87c4.28,0.79,8.19,2.68,12.09,4.59c1.44,0.71,3.17,2.03,4.66,0.77c4.4-3.74,8.58-2.42,12.88,0.03
				c1.51,0.86,3.2,0.61,4.88,0.58c7.79-0.13,13.72,5.1,14.69,12.72c0.13,1.02-0.56,2.4,1.5,3.36c2.68-4.93,9.04-4.98,12.63-9.4
				c2.15-2.64,5.69-2.34,8.89-1.46c2.85,0.78,4.53,3.26,6.85,4.82c6.86-10.08,12.26-11.6,22.5-6.43c3.67,1.85,6.26,4.9,8.84,7.96
				c1.15,1.37,2.01,3.13,4.14,3.3c5.89,0.46,9.78,3.54,12.2,8.89c1.2,2.66,7.12,4.61,9.13,3.37c6.57-4.05,11.87-3.41,17.41,1.94
				c3.6,3.47,5.34,7.84,6.08,12.74c3.47,0,6.66-0.31,9.76,0.07c5.35,0.65,10.97,0.95,14.48,6.11c-1.31,0.37-2.2-0.58-3.21-1.07
				c-15.52-7.42-27.89-2.88-34.49,12.66c-0.11,0.27-0.48,0.43-0.93,0.82c-0.63-0.22-1.45-0.36-2.12-0.76
				c-6.62-3.88-7.12-3.83-12.9,1.17c-0.59,0.51-1.5,1.25-2.06,1.11c-3.97-0.99-6.37,1.4-8.54,4.09
				c-20.63-21.11-44.89-17.14-52.4,8.58c-2.8-0.14-7.02-2.04-8.33,0.44c-2.39,4.52-6.66,4.53-10.23,6.27
				c-1.9,0.93-4.97,0.94-5.78,2.36c-2.66,4.63-6.71,3.37-10.54,3.47c-8.46,0.23-10.83,1.94-13.25,10.09
				c-6.28-1.55-12.95-3.75-18.99,0.51c-5.18,3.66-10.57,7.36-12.13,14.59c1.83,0.41,2.94,0.84,4.06,0.88
				c15.11,0.49,30.23,0.95,45.35,1.32c1.07,0.03,2.29-0.48,3.23-1.08c4.21-2.68,8.91-3.54,13.75-3.9c21.6-1.62,43.2,0.08,64.8,0.2
				c0.27,0,0.53,0.34,0.75,0.48c1,1.4-1.35,1.84-0.33,4.15c15.1,1.38,30.71,2.23,46.41,0.86c0.03-6.09,4.73-4.83,8-5.42
				c3.71-0.66,7.5-0.87,11.31-1.28c0.16-1.01,0.34-1.64,0.34-2.28c0.02-17.64-0.03-35.28,0.11-52.92
				C1161.44,73.72,1160.15,72.31,1159.45,70.52z M838.26,277.28c-1.23-0.25-1.34-0.94-1.51-1.57c-2.5-9.83-7.53-14.24-17.52-15.64
				c-3.73-0.52-7.29,0.42-10.9,0.84c-2.01,0.23-3.13-0.51-4.73-1.72c-6.2-4.68-12.19-10.08-20.58-10.46
				c-1.94-0.09-3.01-0.77-3.63-2.65c-3.76-11.48-12.96-14.82-23.73-15.45c-5.72-0.33-11.17,1.57-16.66,2.87
				c-4.24,1.01-8.45,3.28-11.94-1.53c-0.4-0.55-1.51-0.78-2.3-0.82c-4.09-0.21-7.14-2.14-9.76-5.2c-0.91-1.07-2.45-1.82-3.84-2.21
				c-5.94-1.64-10.84-3.42-13.13-10.64c-1.97-6.22-12.75-7.93-20.69-4.83c-1.43-1.66,0.02-3.09,0.37-4.6
				c2.02-8.7,3.01-17.07-4.9-23.88c-5.17-4.45-10.93-6.19-17.54-5.27c-2.93,0.41-4.86-0.2-6.61-2.94c-3.27-5.12-8.1-7.81-14.42-6.93
				c-3.46,0.48-6.91,0.98-10.34,1.64c-6.58,1.28-6.55,1.32-8.3-5.01c-0.31-1.11-0.75-2.23-1.35-3.2c-1.38-2.22-0.74-3.62,1.43-4.83
				c2.46-1.37,4.98-2.78,6.52-5.3c6.6-10.81,8.11-30.11-6.67-40.85c-4.65-3.38-7.87-8.61-13.67-10.52c-0.73-0.24-1.31-1.46-1.63-2.34
				c-3.61-10-8.01-13.29-18.75-13.49c-2.6-0.05-4.37-0.57-5.53-3.13c-2.53-5.6-7.27-8.5-13.05-9.84c-8.99-2.09-17.86-1.41-26.56,1.51
				c-7.34,2.47-12.48,7.39-15.6,14.5c-0.77,1.76-0.89,3.94-3.06,5.25c-1.57-1.32-3.03-2.92-4.83-3.93c-2.02-1.13-4.4-1.38-6.77-1.44
				c-1.6-0.04-2.08-1.43-2.61-2.67c-4.76-11.06-12.28-15.36-24.44-13.91c-1.48,0.18-2.98,0.84-3.8-1.24
				c0.16-0.39,0.23-0.94,0.54-1.25c0.58-0.58,1.25-1.12,1.98-1.49c8.64-4.44,14.54-11.59,19.03-19.92
				c5.22-9.68,7.57-19.91,4.15-30.79c-2.54-8.08-6.1-10.59-14.51-10.6c-111.87,0-223.74,0-335.62,0c-1.76,0-3.52,0-5.28,0
				c4.33,3.32,8.13,7.55,13.68,7.53c4.01-0.01,6.83,1.49,9.85,3.83c6.37,4.94,11.73,11.17,19.62,14.24c4.95,1.92,5.76,5.78,3.4,10.66
				c-1.19,2.45-2.66,4.77-4.08,7.29c5.06,0.48,9.66,1.78,13.65,4.74c7.03,5.21,7.81,10.32,2.64,17.32c-0.99,1.34-2.32,2.57-1.09,4.41
				c1.17,1.76,2.73,3.14,4.96,2.66c7.59-1.62,15.26-0.84,22.89-0.99c1.71-0.03,3.26-0.46,4.73-1.34c10.47-6.27,27.29-6.44,35.82,6.94
				c0.98,1.53,2.29,2.85,3.47,4.25c2.85,3.41,6.1,5.8,10.86,6.31c4.54,0.49,7.57,3.28,7.76,8.28c0.29,7.65,0.6,15.3,0.85,22.96
				c0.22,6.59,1.54,8.29,7.9,10.03c5.27,1.44,10.79,3.39,12.61,8.8c2.29,6.81,3.16,14.11,4.61,21.21c0.02,0.11-0.51,0.32-0.79,0.48
				c-0.15-0.05-0.37-0.06-0.44-0.17c-0.84-1.22-1.58-2.5-2.48-3.68c-5.63-7.39-11.41-15.02-22.12-13.64
				c-6.01,0.78-9.42-2.19-12.42-6.25c-8.28-11.2-19.97-14.53-32.7-12.42c-8.07,1.34-16.15,1.5-24.12,2.65
				c-12.23,1.76-20.49-1.28-25.52-13.23c-2.72-6.45-8.94-8.52-15.96-7.27c-2.93,0.53-5.89,1.16-8.7,2.14
				c-6.19,2.17-12.14,2.75-18.38-0.21c-3.08-1.46-6.69-2.13-10.12-2.49c-6.49-0.67-11.49-3.57-14.5-9.26
				c-11.17-21.08-37.47-18.6-53.62-12.82c-2.74,0.98-6.2,3.08-5.61,6.23c0.6,3.19,4.55,3.36,7.51,3.67
				c11.97,1.28,22.53,11.51,24.35,23.92c-1.3,0.14-2.6,0.24-3.9,0.43c-5.38,0.77-11.61-0.45-14.4,6.37
				c-0.43,1.05-1.87,1.19-3.05,1.42c-4.52,0.89-8.77,0.67-13.12-1.3c-11.26-5.08-24.2-0.39-30.44,11.06
				c5.66,0.14,9.61,3.47,13.72,6.59c3.2,2.43,6.65,3.56,10.71,1.85c2.51-1.06,5.22-0.71,7.87-0.13c9.37,2.05,14.64,8.71,18.58,16.74
				c5.68,11.57,4.13,18.99-5.4,27.55c-2.34,2.11-5.29,3.67-7.29,7.02c7.23-1.49,12.17,2.09,17.05,5.51
				c6.04,4.22,11.96,8.24,19.77,8.67c9.29,0.51,13.55,5.89,12.93,15.23c-0.16,2.49-0.31,4.98-0.38,7.48
				c-0.08,2.91,0.84,5.39,3.88,6.22c3.06,0.83,4.45-1.6,5.9-3.62c1.59-2.21,2.63-4.75,2.67-7.43c0.07-4.76,2.88-6.74,6.96-7.79
				c2.75-0.71,5.53-1.51,8.36-1.06c3.37,0.54,5.64-0.88,7.89-3.16c8.04-8.17,16.08-6.24,19.77,4.62c2.53,7.46,5.17,8.59,11.94,5.1
				c11.51-5.92,20.27-3.43,26.8,7.72c2.23,3.81,5.26,5.16,9.64,4.12c4.31-1.02,8.71-3.19,13.17-1.37c5.97,2.43,11.97,3.52,18.44,3.09
				c3.3-0.22,6.48,1.07,7.01,5.7c-5.9,0.45-11.98-2.77-17.32,1.46c0.26,0.31,0.34,0.49,0.45,0.51c0.65,0.12,1.3,0.24,1.95,0.28
				c5.58,0.37,10.4,1.81,12.95,7.58c1.22,2.75,4,3.97,6.89,4.42c10.57,1.65,19.53,9.38,30.85,8.19c0.3-0.03,0.63,0.19,0.93,0.32
				c5.24,2.3,10.12,1.51,15.37-0.67c7.7-3.19,16.11-4.48,23.59-0.07c5.16,3.05,10.11,6.18,15.88,7.93c1.55,0.47,3.29,1.26,4.28,2.46
				c5.36,6.49,11.73,9.36,20.15,6.78c1.29-0.4,2.94,0.28,4.4,0.57c2.64,0.52,5.39,0.88,7.94,0.2c8.44-2.27,15.42,0.24,21.47,6.04
				c3.19,3.07,6.93,3.96,11.04,2.97c2.25-0.54,5.66-0.21,5.97-3.37c0.29-2.93-0.45-6.54-3.28-7.89c-5.09-2.42-6.81-6.45-7.44-11.51
				c-0.12-0.92-0.53-2.15-1.23-2.6c-4.6-3.01-7.73-8.05-12.1-10.68c-4.65-2.8-5.45-5.72-5.5-10.24c-0.11-8.19-3.65-11.63-11.86-12.32
				c-2.24-0.19-4.6,0.83-7.04-0.64c0.14-2.6,1.56-4.67,2.97-6.66c2.24-3.15,4.12-6.4,5.08-10.2c1.95-7.73-1.4-13.1-9.21-14.67
				c-1.53-0.31-3.45,0.46-4.81-1.97c7.18-8.48-0.46-15.22-3.56-22.49c-3.67-8.61-11.69-10.67-20.87-10.87
				c1.92-2.61,3.66-2.35,5.26-2.33c12.17,0.1,23.19,7.51,27.77,18.64c0.94,2.29,1.15,5.22,3.38,6.54c8.3,4.89,14.23,12.37,21.21,18.7
				c3.52,3.2,6.68,6.37,8.29,10.96c2.1,6.01,6.3,9.13,12.87,8.94c4.54-0.13,9.01,0.37,12.74,3.37c1.5,1.21,2.72,0.38,4.12-0.2
				c10.25-4.25,22.69-1.46,29.28,7.26c2.74,3.62,5.61,4.22,9.41,3.37c2.27-0.51,4.5-1.18,6.72-1.89c5.77-1.85,10.83-0.52,15.31,3.35
				c3.28,2.83,6.46,5.78,8.64,9.61c0.96,1.68,2.09,3.16,4.2,3.01c10.06-0.69,18.37,5.34,27.76,7.31c3.9,0.82,7.93,1.36,11.95,2.67
				c-10.89,8.23-25.06,2.77-36.89,8.83c4.25,4.11,8.88,7.5,11.36,12.72c1.54,3.24,4.12,4.68,7.63,4.48
				c7.26-0.41,13.86,1.17,19.47,6.09c1.4,1.23,3.28,1.56,5.11,1.83c8.07,1.16,14.56,5.16,18.61,12.1c2.99,5.13,6.77,5.69,11.71,4.22
				c1.59-0.47,3.25-0.77,4.8-1.36c5.97-2.24,11.44-1.38,16.44,2.52c1.9,1.49,3.81,2.46,6.4,1.96c7.98-1.54,16.28,0.28,24.19-2.31
				c1.04-0.34,2.46-0.31,3.45,0.13c7.2,3.18,14.78,5.46,21.9,8.68c3.96,1.79,7.29,3,11.67,2.49c7.61-0.88,15.37-0.4,22.86,0.73
				c7.11,1.08,12.78,6.22,18.78,9.93c7.82,4.83,16.17,7.88,24.95,9.56c12.71,2.43,25.35,5.11,37.99,7.86
				c16.69,3.63,32.71,11.14,50.5,8.38c2.64-0.41,5.57,0.37,7.71,2.35c-0.2,2.83-3.81,2.86-4.06,5.67c1.57,0.11,3.05,0.29,4.52,0.31
				c16.98,0.25,33.95,0.6,50.93,0.7c35.14,0.19,70.16,2.57,105.21,4.73c14.44,0.89,28.86,2.62,43.36,2.14
				c6.64-0.22,13.36-0.4,19.51-3.4c3.17-1.55,5.99-3.91,4.13-7.83c-2.44-5.12-5.28-10.87-10.73-12.69c-8.99-3-16.28-7.84-23.35-14.03
				c-4.13-3.62-10.57-3.85-15.57-7.46c-4.16-3-5.05-4.8-3.62-9.37c3.63-11.62,1.12-17.49-9.13-23.96c-6.96-4.39-14.31-5.78-22.43-4.3
				c-2.94,0.53-6.55,2.67-8.96-1.46c-2.44-4.19-6.39-6.1-10.95-7.12c-1.88-0.42-4.51-0.24-5.1-2.48c-1.69-6.42-6.4-7-11.7-6.47
				c-3.97,0.4-5.69-0.98-5.87-5.17c-0.33-7.82-2.98-14.99-7.67-21.29c-7.75-10.42-21.53-12.86-32.38-5.76
				c-3.2,2.09-6.29,4.36-9.33,6.69c-2.17,1.66-4.08,2.46-6.67,0.57c-1.96-1.43-4.45-1.33-6.72-0.41c-4.35,1.74-8.96,1.68-13.42,2.41
				c-6.84,1.12-13.76,2.06-20.52,3.43C846.39,263.61,841.76,270.24,838.26,277.28z M391.75,232.05c0.61,0.21,1.18,0.47,1.78,0.6
				c13.42,2.83,14.79,4.34,15.81,17.96c0.14,1.85,0.82,2.76,2.41,3.84c5.44,3.69,9.33,9.19,14.81,12.93
				c1.65,1.13,1.72,3.46,1.93,5.49c0.3,2.91,1.35,5.36,4.25,6.85c4.94,2.55,4.71,7.63,5.45,12.16c0.17,1.03-0.39,1.76-1.4,2.36
				c-7.01,4.13-13.96,3.64-20.18-1.81c-3.51-3.08-7.78-6.08-12-5.3c-8.33,1.55-16.54-0.93-24.73,0.5c-4.31,0.75-8.31,0.33-11.35-3.36
				c-3.86-4.68-8.87-7.45-14.75-8.72c-2.18-0.47-4.06-1.52-5.67-3.01c-7.87-7.27-17.26-6.7-25.7-2.83
				c-11.11,5.09-21.08-1.45-31.58-0.97c-0.14,0.01-0.32-0.12-0.44-0.22c-4.51-3.82-10.12-4.84-15.63-6.09
				c-4.38-0.99-8.31-2.74-10.38-7c-1.77-3.65-4.67-4.97-8.46-4.97c-4.06,0-8.12,0-12.65,0c1.85-4.2,6.14-5.11,8.96-7.83
				c-6.27-3.32-12.26-5.05-19.27-2.88c-8.18,2.53-11.35,0.89-15.32-6.79c-2.69-5.21-6.92-7.38-12.55-7.33
				c-4.29,0.04-8.07,1.68-11.76,3.71c-4.83,2.65-8.54,1.74-11.44-2.95c-1.12-1.81-1.85-3.89-2.53-5.93
				c-2.32-6.94-9.12-8.72-14.24-3.51c-3.37,3.43-6.55,6.64-12.05,5c-1.29-0.38-2.92,0.38-4.4,0.62c-4.17,0.66-7.1,2.24-7.09,7.29
				c0.01,3.18-1.81,6.01-3.76,8.55c-1.87,2.44-3.96,4.74-7.46,3.86c-3.57-0.9-5.5-3.39-6.29-7c-0.78-3.55,0.3-6.96,0.38-10.43
				c0.16-7.44-2.5-11.29-9.8-11.7c-7.76-0.44-14.08-3.51-19.96-8.09c-6.4-4.98-13.02-8.85-21.43-4.75c-0.18,0.09-0.57-0.24-1.06-0.47
				c-0.6-2.23,1-3.69,2.31-4.98c2.83-2.8,5.9-5.37,8.89-8.02c3.29-2.92,6.23-6.09,7-10.62c1.69-9.94-7.34-24.64-17.03-27.97
				c-3.4-1.17-6.84-1.74-10.23-0.35c-3.86,1.58-7.31,0.63-10.33-1.66c-5.23-3.98-10.42-7.73-17.51-6.94
				c-0.28,0.03-0.64-0.67-0.94-1.01c6.97-13.77,23.63-22.42,36.42-15.16c3.55,2.02,7.4,1.52,11.22,1.19
				c2.64-0.23,2.46-2.82,3.68-4.23c1.34-1.55,3.34-2.17,5.31-2.56c3.03-0.59,6.09-1.03,9.62-1.61c-2.32-4.29-4.01-8.44-6.91-11.9
				c-3.21-3.83-6.96-6.42-12.34-6.45c-6.33-0.03-12.15-3.05-12.01-6.12c0.26-5.72,4.67-8.59,9.28-9.92
				c20.96-6.03,43.72-6.16,55.46,15.35c2.3,4.22,6.17,5.97,10.84,7.11c12.44,3.03,24.4,5.73,37.39,0.93
				c8.25-3.05,15.02,1.29,19.61,9.05c1.27,2.15,2.38,4.39,3.64,6.54c1.61,2.74,4.06,5.01,7.16,4.77c10.38-0.82,20.83,0.69,31.18-1.73
				c15.64-3.66,29.3-0.46,39.68,12.81c1.86,2.38,3.81,5.46,7.51,5c9.62-1.19,17.2,2.26,23.23,10.35
				c-0.33-12.49-2.69-15.76-12.73-18.28c-6.98-1.76-9.2-4.42-9.55-11.83c-0.32-6.82-0.52-13.64-0.71-20.47
				c-0.18-6.62-1.44-8.3-7.89-9.23c-2.82-0.4-5.11-1.56-6.98-3.49c-2.66-2.75-5.31-5.54-7.6-8.59c-6-7.97-14.39-11.77-23.23-8.09
				c-11.04,4.6-22.26,4.04-33.53,4.96c-0.66,0.05-1.36,0.05-1.97,0.26c-4.26,1.52-6.97-0.62-9.32-3.8c-2.47-3.34-0.06-5.5,1.75-7.86
				c3.62-4.71,3.38-8.21-1.22-12.01c-5.81-4.81-12.42-6.13-19.63-3.55c-2.36,0.85-4.72,2.03-6.21-1.42c2.48-3.09,6.18-3.88,9.41-5.46
				c3.7-1.8,4.87-5.33,5.68-8.89c0.46-2.02-1.17-3.68-2.86-4.25c-8.68-2.93-14.36-9.96-21.31-15.23c-1.33-1.01-2.67-2.55-4.29-2.34
				c-7.19,0.94-12.18-3.69-17.82-6.69c-3.34-1.78-6.37-3.79-10.56-3.77C91.6,1.84,49.49,1.77,7.37,1.8c-1.75,0-3.68-0.58-5.31,0.92
				C1.99,3.84,1.88,5,1.88,6.16C1.87,132.74,1.89,259.32,1.78,385.9c0,3.99,1.29,5.28,5.08,5.23c12.15-0.15,24.31,0.1,36.45-0.25
				c11.36-0.33,22.75-1.39,32.55-7.89c4.46-2.96,9.02-3.66,14.11-3.72c20.63-0.23,41.29,0.91,61.89-0.84
				c7.69-0.66,15.25-1.75,21.03-7.74c1.37-1.42,3.33-1.34,5.16-1.47c17.76-1.27,35.54-1.87,53.35-1.79
				c9.31,0.05,18.62-0.07,27.94-0.81c16.25-1.29,32.52-2.78,48.85-2.24c8.32,0.28,17.03,0.44,23.88,5.74
				c5.16,3.99,10.61,5.16,16.66,5.16c48.77,0.03,97.55,0.78,146.29-1.18c13.35-0.54,26.66-2.01,37.42-11.43
				c1.04-0.91,2.43-1.11,3.78-0.99c5.96,0.55,12.06,0.53,17.69,2.75c14.66,5.79,30.16,6.55,45.55,7.78
				c22.08,1.76,44.24,1.43,66.37,1.68c40.28,0.44,80.57,1.36,120.82-0.77c12.21-0.64,24.33-2.41,36.29-5.15
				c-0.34-1.3-1.28-1.2-2.04-1.44c-20.3-6.37-41.11-10.45-62.05-14c-9.19-1.56-17.81-4.76-25.98-9.6
				c-6.48-3.84-12.41-9.65-20.21-10.18c-7.41-0.5-14.91-1.42-22.4-0.31c-3.73,0.55-7.22,0.17-10.23-2.6
				c-2.28-2.09-5.28-2.79-8.32-2.93c-2.05-0.1-3.92-0.45-5.53-1.86c-4.85-4.24-10.42-4.61-16.41-3.08
				c-7.15,1.83-14.57-0.02-21.78,1.47c-2.13,0.44-3.57-1.53-5.15-2.7c-4.17-3.07-8.57-4.11-13.61-2.26
				c-3.43,1.25-6.97,2.16-10.69,2.31c-3.45,0.15-5.98-0.75-7.82-4.03c-2.21-3.93-5.02-7.8-9.23-9.58
				c-7.87-3.35-16.27-5.47-23.52-10.28c-0.63-0.42-1.63-0.52-2.43-0.43c-9.45,1.05-16.08-2.43-20.64-11.09
				c-1.88-3.58-6.01-5.97-9.93-9.68c10.11-6.54,21.31-4.83,31.69-6.99c-6.56-3.12-13.84-3.73-20.13-7.17
				c-0.99-0.54-2.22-0.7-3.36-0.86c-1.15-0.16-2.33-0.19-3.49-0.13c-4.73,0.24-7.98-1.46-10.62-5.75
				c-6.61-10.73-12.43-12.64-24.75-9.31c-1.91,0.52-3.9,0.82-5.87,1.06c-3.06,0.37-5.28-0.8-7.22-3.36
				c-7.21-9.51-18.02-12.14-28.79-7.23c-1.35,0.62-2.74,1.78-4.07,0.36c-3.6-3.84-8.25-3.75-12.95-3.88
				c-6.09-0.17-11.07-2.52-13.72-8.31c-5.51-12.06-15.63-19.89-25.59-27.76c-3.64-2.88-6.68-5.74-8.19-10.28
				c-1.7-5.09-4.59-9.47-9.55-12.39c2.84,8.86,11.61,15.7,7.51,26.15C401.53,207.21,402.03,209.99,391.75,232.05z M108.33,607.51
				c4.82,5.8,4.82,10.99,0.25,16.14c-1.37,1.55-1.97,3.42-2.04,5.5c-0.35,11.03-0.22,22.05,1.25,33.01c0.36,2.7,0.26,5.16-0.8,7.78
				c-2.56,6.31-3.42,12.93-2.1,19.68c0.92,4.7,4.4,6.19,7.54,2.83c2.12-2.27,4.67-4.06,6.65-6.58c2.18-2.77,4.66-6.74,8.83-6.22
				c3.12,0.4,6.62,2.03,8.35,5.02c4.93,8.53,12.6,15.6,14.59,25.69c1.08,5.47,1.93,10.8,4.76,15.84c2.69,4.8,4.56,9.91,5.66,15.63
				c0.99,5.18,5.09,9.95,7.4,15.27c3.93,9.03,7.73,18.33,13.46,26.55c1.22,1.74,2.55,3.67,2.47,5.91
				c-0.34,10.3,3.48,19.35,7.74,28.44c7.63,16.24,13.25,33.39,23.12,48.61c3.79,5.85,6.13,12.53,7.91,19.33
				c1.14,4.35,2.11,9.01,4.88,12.48c7.48,9.36,10.05,20.45,12.47,31.71c2.65,12.34,4.5,24.94,10.9,36.14
				c11.89,20.82,19.27,43.4,26.06,66.23c2.7,9.08,5.53,18.12,8.39,27.47c2.33-2.27,1.96-5.49,4.54-7.09
				c2.17,2.97,2.64,6.66,5.71,9.63c1.4-6.83,0.96-13.14,1.01-19.38c0.18-19.81,0.04-39.62,0.46-59.44c0.13-6.05,1.32-13.25-2.2-18.03
				c-3.43-4.65-3.35-8.89-3.44-13.77c-0.08-3.81-0.58-7.62-0.99-11.43c-0.16-1.49-0.46-3.05-2.42-3.28c-4.17-0.49-5.26-3.42-5.76-7
				c-0.29-2.12-0.69-4.31-1.5-6.28c-3.83-9.35-2.34-19.44-4.09-29.1c-0.76-4.22,3.45-7.71,7.66-7.65c1.3,0.02,2.25-0.1,3.03-1.15
				c2.82-3.82,5.97-3.41,9.49-0.93c4,2.82,7.35,1.73,10.48-1.8c4.06-4.58,10.85-6.45,12.98-13.04c0.19-0.59,1.31-1,2.08-1.26
				c5.41-1.84,8.29-8.3,14.81-8.14c0.36,0.01,0.85-0.56,1.08-0.97c3.55-6.1,9.13-7.7,15.7-7c1.31-2.88,0.8-5.76,0.44-8.48
				c-0.65-5.01-0.69-9.58,1.67-14.47c1.89-3.89,1.44-8.68-1.2-13.56c-2.07,3.43-4.61,3.35-7.08,1.37c-3.42,5.09-4.94,10-3.6,15.87
				c1.06,4.68-1.49,7.06-6.62,7.2c-0.25,0.92-0.54,1.87-0.76,2.83c-1.03,4.59-5.19,4.99-8.51,5.5c-3.14,0.48-3.42-2.85-4.04-5.28
				c-0.47-1.87-0.71-4.02-3.2-4.82c-4.45,3.12-10.49,3.12-14.65,8.4c-2.14-7.87-4.75-14.38-4.06-21.73
				c-2.26,4.95,1.49,10.92-3.95,15.46c-1.46-3.5-2.68-6.54-3.99-9.55c-0.94-2.16-2.06-4.76-4.46-5c-3.31-0.33-3.59-2.51-4.01-4.75
				c-1.44-7.8,0.12-15.61-0.01-23.41c-0.12-7.19,4.29-13.42,4.32-20.36c0.06-11.56,3.23-22.3,6.96-33.01
				c0.57-1.63,1.76-3.45,0.31-6.13c-3.67,9.53-5.87,18.99-12.66,26.07c-0.38-11.32,4.55-20.89,9.66-30.48
				c-3.17-2.1-5.36-4.23-6.53-7.36c-0.39-1.06-2.32-2.62-2.9-2.38c-4.22,1.72-6.83-1.67-9.86-3.15c-3.25,3.45-1.82,8.77-5.69,11.63
				c-2.01-2.17-1.64-4.57-1.64-6.82c0.05-13.65,0.81-27.29,0.39-40.96c-0.33-10.83-3.04-21.51-2.27-32.35
				c0.47-6.61-0.8-12.38-4.15-18.07c-4.26-7.23-3.52-10.07,3.19-15.87c-5.47-7.77-12.32-14.97-13.47-25.21
				c-2.34,10.91-4.19,21.61-4.39,32.42c-0.16,8.83-4.96,16.17-6.15,24.68c-3.52-0.92-4.02-5.3-6.14-5.74
				c-5.38-1.14-4.26-5.03-4.69-8.34c-0.57-4.36-0.52-8.5-3.77-12.44c-4.21-5.1-10.07-9.15-11.93-16.05c-0.03-0.1-0.55-0.07-1.4-0.16
				c-0.77,11.29,1.79,22.53,0.46,33.76c-1.36,11.49,0.01,22.98,0.67,34.4c0.63,10.97,2.04,21.88,1.23,32.88
				c-1.77-14.71-2.92-29.44-3.85-44.19c-1.07-16.95,1.18-33.95-0.74-50.92c-0.39-3.47-0.1-7.21,2.23-10.37
				c1.86,0.9,2.61,2.5,3.28,3.9c2.14,4.5,4.84,8.24,8.53,11.86c2.66,2.61,7.39,5.27,7.1,10.37c-0.38,6.57,0.42,12.55,7.01,15.88
				c2.54-6.1,5.29-12.43,5.46-19.5c0.27-10.85,1.97-21.56,4.3-32.11c1.26-5.72,1.12-11.08,0.09-16.7
				c-1.29-7.05-1.88-14.17-1.12-21.37c2.93-27.99,10.93-54.73,20.04-81.18c2.23-6.47,4.93-12.89,10.25-17.47
				c4-3.45,4.94-7.97,6.38-13.19c-9.43,1.11-17.78-2.06-26.41-3.61c-2.73-0.49-5.62-0.96-8.32,0.44c-3.74,1.93-7.75,2.99-11.92,3.41
				c-2.98,0.3-5.08,1.73-7.06,4.06c-3.1,3.65-6.04,7.64-10.22,10.03c-1.82-1.74,0.42-3.3-1.02-4.73c-3.95,1.11-6.91,3.62-9.58,6.69
				c-3.05,3.5-5.66,7.46-9.75,9.93c-5.89,3.55-10.16,8.65-13.2,14.66c-3.6,7.12-6.69,14.49-10.06,21.73
				c-0.65,1.4-0.52,3.41-2.63,3.86c-1.28-1.3-0.19-2.46,0.12-3.67c4.04-15.42,12.99-28.34,21.39-41.49
				c3.85-6.03,6.64-12.49,9.98-18.9c-4.04-1.56-7.85-1.35-11.67-1.19c-4.03,0.16-6.62,1.45-7.54,6.02
				c-1.36,6.72-4.7,12.25-11.31,15.51c-2.43,1.2-4.29,3.63-6.27,5.64c-9.26,9.41-15.75,20.46-19.98,32.98
				c-2.32,6.85-5.8,13.23-10.64,20.33c-0.16-3.31,1-4.79,1.79-6.31c3.72-7.25,7.15-14.6,9.74-22.37
				c4.47-13.47,12.53-24.84,23.85-33.06c6.44-4.68,9.25-10.43,11.34-17.44c-4.03-1.71-8.31-1.21-12.32-2.16
				c-3.24-0.77-5.2,0.31-6.43,3.7c-1.63,4.49-3.07,9.3-6.95,12.5c-11.04,9.1-17.46,21.23-22.1,34.34
				c-1.95,5.52-4.15,10.85-6.71,16.08c-0.49,0.99-0.86,2.31-2.73,1.6c-0.4-1.88-0.1-3.81,0.43-5.75c2.55-9.18,5.3-18.59,10.87-26.14
				c7.71-10.45,8.92-22.21,10.17-34.25c-4.69-1.57-7.71-0.28-10.14,4.59c-3.42,6.87-8,13.35-14.53,17.5
				c-5.96,3.79-9.5,9.06-12.67,14.96c-2.75,5.12-4.88,11.01-9.75,14.32c-7.76,5.29-12.49,12.58-16.56,20.68
				c-2.16,4.3-4.84,8.34-7.47,12.39c-1.29,1.99-2.61,4.13-6.11,5.8c3.81-15.79,7.35-30.38,12.22-44.87
				c4.85-14.44,5.31-30.31,14.26-44.73c-4.51,0.81-8.41-2.76-10.36,1.39c-2.62,5.59-6.71,9.72-10.67,14.2
				c-3.72,4.21-7.45,8.84-8.88,14.33c-4.02,15.41-11.96,29.2-17.43,43.99c-0.65,1.76-0.8,3.92-3.04,5.1c-0.51-2.1,0.73-3.6,1.23-5.25
				c4.14-13.53,11.74-25.72,15.27-39.48c2.44-9.5,7.69-17.31,14.59-23.97c3.26-3.15,5.08-6.78,6.63-10.86
				c-4.77-2.11-9.37-0.89-13.97-0.44c-1.76,0.17-2.27,1.44-2.43,2.71c-0.79,6.56-4.63,12.15-6.04,18.4
				c-3.73,16.58-11.92,31.34-18.14,46.9c-0.33,0.83-0.5,2.06-3.13,1.25c11.11-21.78,18.24-44.88,25.47-68.15
				c-3.04,0-5.36,0.13-7.66-0.03c-4.13-0.29-6.34,1.42-7.48,5.5c-1.75,6.23-3.66,12.48-6.19,18.43
				c-6.77,15.91-13.68,31.76-19.13,48.2c-1.37,4.15-4.24,7.21-7.95,9.64c-1.38-2.16,0.37-4.3-0.88-6.12c-2.23-0.35-3.5,2.6-6.24,1.65
				c-0.43-13.68,3.51-26.55,9.11-39.79c-3.72,1.37-4.68,4.91-8.44,4.69c1.29-6.83,4.86-12.62,5.8-19.24
				c-1.88-0.29-2.81,2.17-5.4,0.88c2.42-7.51,6.42-14.62,8.34-22.91c-6.87-1.76-13.52-0.93-20.29-0.82
				c0.06,7.92-2.02,15.45,4.52,21.25c1.43,1.27,1.04,3.41,1.02,5.25c-0.06,5.16-1.03,10.64,0.82,15.37c2.4,6.15,1.4,12.03,0.98,18.1
				c-0.41,5.97-1.25,11.96,0.26,17.89c0.9,3.51,2.6,7.18,6.4,7.42c3.34,0.21,3.89,2.03,4.5,4.21c1.19,4.25,2.63,8.34,6.58,10.79
				c3.64,2.26,5.02,5.22,3.76,9.37c-0.1,0.32,0.21,0.76,0.42,1.41c2.84-3.3,4.1-7.57,7.86-9.42c1.2,0.52,1.34,1.4,1.35,2.21
				c0.09,8.95,3.02,17.66,1.75,26.77c-0.76,5.41-0.49,10.98,0.62,16.41c0.67,3.26,1.57,6.45,5.4,8.75
				c7.52-8.62,11.17-19.89,18.8-28.45c0.37,1.03,0.24,1.78,0.01,2.51c-4.63,15.06-9.72,29.96-12.72,45.53
				c-1.39,7.19-1.76,13.89,1.55,20.36c2.28,4.46,2.45,8.95,1.54,13.57c-2.75,13.89-4.02,27.98-6.19,41.95
				c-0.36,2.3,0.16,4.3,1.03,6.26c0.67,1.5,2.15,2.62,3.62,1.49c4.09-3.13,5.6,0.09,7.33,3.21c5.5-7.24,9.21-15.43,16.09-20.28
				c3.45,1.14,1.55,4.49,4.06,5.71c1.74-1.99,4.41-1.24,6.88-1.71c3.36-15.78,3.43-31.24-1.01-46.6c-0.64-2.22-1.11-4.8-3.16-6.09
				c-3.99-2.5-4.84-6.07-4.36-10.33c0.48-4.24-0.99-6.78-5.65-6.71c-0.96,0.01-2.03-0.03-2.92-1.56c0.92-6.76,2.64-13.9,2.37-21.26
				c-0.27-7.35,1.16-14.35,4.63-21.04c5.33,3.46,9.24,9.31,16.93,4.78c0.92,9.85,1.66,19.04-0.51,29.25
				C101.08,611.54,104.63,609.57,108.33,607.51z M513.53,1046.11c-3.92-0.12-5.7-4-8.95-3.54c-6.05,0.85-12.03,4.85-18.03-0.32
				c-0.89-0.76-2.22,0.11-3.18,0.84c-1.11,0.83-2.31,1.14-3.74,1c-5.62-0.56-8.3,1.63-8.44,7.14c-0.07,2.8-1.16,4.75-3.47,6.17
				c-0.8,0.49-2.12,0.63-2.13,1.85c-0.03,5.13-5.32,7.73-5.97,12.64c-0.2,1.52-1.93,2.83-3.51,3.34c-6.82,2.22-12.46,6.27-18.07,10.6
				c-3.7,2.85-8.44,1.95-11.62-1.53c-1.63-1.78-2.97-3-5.46-1.45c-3.53,2.19-6.39,0.6-8.78-2.1c-2.09-2.36-3.96-4.92-6.03-7.31
				c-1.82-2.1-3.53-2.9-6.32-0.79c-4.58,3.48-6.87,2.87-10.46-1.72c-5.53-7.06-7.45-7.39-14.75-2.14c-2.18,1.57-4.1,2.48-6.81,1.11
				c-5.3-2.68-15.12,2.42-16.41,8.27c-0.65,2.95-1.78,5.7-4.02,7.89c-2.11,2.07-3.95,0.47-5.91-0.26c-0.88-0.33-1.56,0.35-2.18,0.91
				c-1.64,1.51-3.45,2.48-5.8,2.48c-1.82,0-3.02,1.21-3.93,2.71c-3.19,5.24-3.88,10.91-2.82,16.84c0.4,2.21,1.44,3.54,4.14,2.92
				c8.04-1.85,13.8,1.97,18.6,7.88c4.62,5.68,9.07,5.82,13.89,0.12c17.94-21.23,47.09-13.57,55.01,9.96
				c2.89,8.59,4.32,17.42,4.01,26.5c-0.09,2.54-0.31,5.56-3.19,6.09c-3.04,0.57-4.17-2.46-4.89-4.75c-1.59-5.07-2.73-10.28-4.18-15.4
				c-1.08-3.8-2.37-7.53-6.23-9.49c-3.6,3.87-3.91,9.08-6.64,13.06c-3.32,4.84-8.46,3.68-13.06,3.56
				c-2.42,6.96,0.61,12.24,4.63,17.12c7.05,8.56,15.88,15.57,27.19,13.91c12.17-1.78,23.97-3.31,36.03-0.32
				c2.08,0.52,4.27,0.64,6.42,0.85c24.08,2.4,47.76-1.96,71.51-4.6c11.3-1.26,21.39,0.15,31.57,5.95
				c13.54,7.72,23.06,20.34,36.51,27.86c-0.3,1.43-1.3,2.18-2.31,2.62c-4.26,1.88-5.52,4.84-4.48,9.43c0.65,2.85,0.33,5.96,0.17,8.94
				c-0.61,11.33-3.14,22.4-4.91,33.56c-1.82,11.49,0.84,21.07,9.55,28.87c1.74,1.55,3.42,3.17,5.16,4.72
				c5.67,5.07,8.65,11.22,8.07,19c-0.18,2.42-0.83,4.93,0.13,7.4c1.81,0.28,3.53,1.02,5.87-0.11c-0.65-10.8-1.26-21.74-2-32.68
				c-0.54-7.96-1.93-15.97,0.59-23.79c1.25-3.89,1.64-7.74,1.57-11.77c-0.23-13.65-1.4-27.29-0.56-40.94
				c0.31-4.92,1.67-9.05,5.76-12.67c4.17-3.7,8.14-7.98,8.51-14.48c0.28-4.91,0.9-10.54,5.16-13.66
				c9.76-7.16,10.07-20.14,18.14-28.22c0.3-0.3,0.25-0.95,0.34-1.43c1.4-7.32,3.27-8.42,10.42-9.26c5.98-0.7,13.06,0.09,17.49-4.33
				c4.9-4.88,9.21-4.35,14.27-1.66c3.58,1.91,7.02,4.09,10.52,6.15c-1-3.59-3.63-6.68-2.31-10.14c2.53-6.62-0.78-9.83-6.04-12.57
				c-3.4-1.77-7.4-3.94-7.94-7.81c-0.6-4.31-3.21-5.51-6.26-7.21c-7.33-4.1-7.85-11.69-1.25-16.6c1.58-1.17,3.41-2.13,5.26-2.78
				c13.51-4.79,27.19-8.79,41.62-9.8c4.92-0.34,10.17-0.09,14.5-3.01c3.77-2.54,7.92-3.35,12.22-3.86
				c5.12-0.61,10.25-1.15,15.38-1.54c10.52-0.8,20.25-4.92,30.38-7.13c15.06-3.3,30.76-2.77,46.04-5.27
				c10.88-1.78,21.54-4.12,31.48-9.1c5-2.51,5.79-4.89,2.56-9.15c-3.26-4.31-4.97-8.84-4.07-14.39c0.82-5.05-0.89-9.84-4.37-13.33
				c-5.03-5.04-9.93-11.07-16.64-13.24c-8.57-2.77-12.93-11.15-21.6-13.74c-4.54-1.36-10.19-2.52-13.12-7.57
				c-0.24-0.41-1.6-0.4-2.37-0.24c-2.62,0.54-4.2-1.27-6.26-2.26c-4.99-2.39-10.43-3.88-13.99-8.6c-0.21-0.27-0.99-0.27-1.45-0.16
				c-6.34,1.54-9.67-3.99-13.08-7.01c-4.23-3.73-8.59-6.28-13.65-8.17c-1.64-0.61-2.96-1.64-3.6-3.25c-1.9-4.77-6.38-4.15-10.05-4.47
				c-2.99-0.26-4.45,1.38-2.81,4.84c2.1,4.43,3.65,9.13,5.43,13.73c-1.85,0.83-2.79-0.37-3.75-1.15c-5.02-4.09-10.72-2.71-15.77-1.01
				c-3.56,1.2-6.23,1.51-9.25-0.57c-1.31-0.91-2.63-2.31-4.44-0.92c-3.78,2.92-7.88,3.36-12.71,3.78
				c-6.72,0.59-12.39-0.27-18.04-3.56c-2.01,0.95-2.41,4.33-4.57,4.28c-5.16-0.13-8.81,1.46-13.23,4.62
				c-4.42,3.16-7.79,7.23-11.57,10.74c-3.15,2.93-5.49,5.49-9.99,4.81c-2.69-0.41-4.03,0.69-5.21,3.32
				c-1.94,4.33-1.78,8.56-1.54,13.09c0.43,8.31-6.75,12.93-13.97,9.36c-0.7-0.35-1.76-0.84-2.26-0.56
				c-6.71,3.67-10.08-0.92-13.37-5.58c-2.25,1.44-4.85,2.73-6.45,0.45c-3.68-5.24-9.84-3.39-14.53-5.65
				c-1.3-0.63-3.03-1.55-4.78-0.75c-3.22,1.48-4.69-0.05-5.16-3.1c-0.24-1.54-1.08-2.5-2.53-2.65c-1.6-0.17-2.84,0.58-3.64,2.09
				c-0.52,0.98-0.18,2.41-1.84,3.09c-1.44-0.48-3.13-1.03-5.26-1.74c0.48,4.71-4.09,6.39-5.43,10.45
				c-1.49,4.48-2.48,10.73-9.01,12.12c-0.46,0.1-0.97,1.41-0.95,2.14c0.17,4.42-2.02,9.17,2.7,12.92c1.85,1.47,1.11,4.35-0.27,6.48
				c-1.41,2.19-3.45,2.35-5.72,1.7c-0.91-0.26-1.98-1.18-2.75-0.18c-3.78,4.91-8.73,6.15-14.86,4.16c0.23,7.46-8.75,8.52-8.37,15.69
				c-2.83-4.11-6.37-1.69-8.98-1.41c-4.59,0.48-3.42-5.09-6.3-5.37C513.68,1043.5,514.75,1045.65,513.53,1046.11z M393.94,806.9
				c-2.94,0.9-4.19-2.22-5.81-1.26c-3.44,2.05-6.05,0.08-8.87-1.11c-7.71-3.25-12.77-0.65-14.83,7.62
				c-1.51,6.05-2.8,11.99-2.43,18.44c0.39,6.84,0.31,13.75,4.43,19.37c4.15,5.66,5.3,11.68,3.17,17.88
				c-2.07,6.01-2.06,11.5,0.08,17.37c2.71,7.48-3.79,12.99-4.8,19.77c-0.43,2.88-2.59,5.26-4.84,7.39
				c-3.82,3.63-7.93,7.09-9.53,12.49c-0.42,1.43-1.35,2.9-2.98,3.1c-8.88,1.09-12.06,7.02-13.1,14.98c-0.41,3.12-1.43,5.95-5.79,4.56
				c0.25,5.09,0.53,9.73,0.68,14.37c0.05,1.63,0.17,4.27-1.4,4.4c-3.33,0.29-3.69,3.09-5.16,4.84c-2.89,3.44-3.44,10.55-10.78,6.29
				c-3.43,3.76-6.1,0.67-8.71-1.19c-2.88,2.7-2.88,2.7-2.33,8.62c1.59,0.02,3.36-0.38,4.61,1.01c3.45,3.82,5.7,1.34,7.9-1.45
				c0.62-0.78,1.11-1.67,1.73-2.44c2.73-3.37,7.63-2.67,9.14,1.35c0.94,2.52,1.3,5.16,1.22,7.85c-0.22,6.82-0.54,13.63-0.68,20.45
				c-0.48,22.82,1.44,45.58,1.83,68.39c0.03,1.67,0.55,3.45-0.87,4.97c-2.53-33.59-4.02-67.19-3.47-101.26
				c-2.24,1.69-4.31-1.3-6.46,1.37c-5.48,6.82-5.71,6.63-12.76,2.56c-0.12-0.07-0.31-0.03-0.48-0.05c-2.62,1.37-2.15,3.85-2.19,6.15
				c-0.39,20.45,0.65,40.92-0.93,61.35c-0.58,7.47-2.22,15.09,6.93,19.55c-2.9,1.87-4.87,3.14-6.97,4.49
				c3.95,6.84,11.94,8.71,17.3,14.42c-5.11,1.89-9.62-1.31-14.2,0.28c2.75,6.65,9.26,9.58,14.82,14.57c-3.01,0.4-4.59,1.54-4.05,4.01
				c-2.92,0.53-5.02-1.37-7.62-1.38c2.55,4.48,7.07,6.44,10.84,9.03c-1.85,2.9-4.13,1.31-5.51,1.76c-0.21,6.43-4.66,1.18-6.67,2.83
				c2.47,2.81,5.37,4.7,9.21,5.56c3.1-6.43,6.64-12.38,12.19-17.1c-0.95-2.08-2.75-3.41-2.83-5.82c-0.44-14.64-1.82-29.23-2.57-43.84
				c-1-19.45-0.9-38.95-1.22-58.43c-0.03-1.69-0.67-3.52,1.28-5.09c1.64,31.31,1.01,62.73,4.87,94.23
				c1.94-8.38,10.76-6.93,15.4-11.36c8.1,3.28,7.41-3.27,8.51-7.7c0.39-1.57,1.1-2.66,2.59-3.39c3.56-1.75,7.09-3.57,10.55-5.49
				c1.8-1,3.46-1.18,5.07,0.17c2.23,1.87,3.99,1.14,6.15-0.44c7.41-5.42,11.84-4.89,17.37,2.32c2.52,3.29,4.78,4.51,8.05,1.33
				c2.57-2.51,4.98-1.94,7.5,0.13c2.87,2.36,4.92,5.41,7.24,8.23c1.85,2.25,3.84,3.7,6.77,1.58c2.93-2.12,5.11-0.87,7.3,1.53
				c4.46,4.87,6.2,4.88,10.97,0.2c2.84-2.78,5.92-6.38,9.36-6.92c10.73-1.67,10.75-11.09,15.49-18.27c2.91-0.35,4.79-2.27,4.9-6.72
				c0.12-4.83,5.84-7.42,10.63-8.18c5.08-0.81,9.64-2.11,14.01,1.54c0.15,0.13,0.68-0.03,0.92-0.21c6.86-4.91,14.65,0.8,21.74-1.87
				c0.96-0.36,2.45,0.77,2.7,1.87c0.81,3.51,3.85,2.9,5.95,2.99c5.94,0.24,9.33-4.57,11.3-8.69c2.03-4.25,3.88-5.51,8.17-4.73
				c3.59,0.65,7.56-0.46,7.83-4.28c0.68-9.58,2.53-19.78-1.61-28.59c-3.24-6.89-3.52-13.66-4-20.65c-1.05-15.49,0.7-30.94,0.8-46.42
				c0.02-3.33,0.21-7.17-2.26-9.49c-4.68-4.39-5.95-10.03-7.79-15.77c-8.59-26.73-10.24-54.85-16.76-82.02
				c-3.96-16.48-3.93-33.69-6.12-50.52c-0.51-3.94-0.61-8.15-2.92-11.5c-2.32-3.37-2.53-7.03-3.2-10.8
				c-2.12-11.87,0.95-23.56,0.76-35.34c-0.31-19.65-0.33-39.29,1.66-58.87c0.13-1.25,0.83-2.68-0.66-3.64
				c-2.58-1.67-2.78-4.39-3.1-7.03c-1.03-8.42-2.14-16.83-1.38-25.37c0.27-3.04,0.04-6.11,0.04-9.69c-3.56,1.6-5.47,3.99-8.37,4.36
				c-1.56,0.2-1.73,1.4-1.95,2.51c-1.07,5.53-2.88,10.91-2.72,16.68c0.22,7.82,0.03,15.65,0.15,23.47c0.04,2.52-1.3,3.46-3.42,4.13
				c-2.26,0.71-3.49-0.45-4.52-2.08c-1.35-2.14-2.09-4.51-2.42-7.02c-1.06-8.24-1.96-16.5-3.23-24.71c-0.48-3.1,0.06-7.3-4.9-7.84
				c-1.49,2.39-0.94,4.84-0.33,6.93c3.41,11.73,4.49,23.84,6.26,35.84c0.35,2.37,2.22,5.78-1.84,6.57c-3.46,0.67-7.9,0.31-8.78-3.69
				c-1.45-6.56-5.59-12-6.71-18.62c-0.69-4.11-0.13-8.49-2.16-12.7c-2.61,1.42-2.29,3.61-1.99,5.41c0.67,4.09,1.67,8.12,2.57,12.16
				c0.47,2.14,0.41,4.36-2,4.94c-3.05,0.73-4.28,3.09-5.9,5.99c-1.28-1.86-2.39-3.1-3.08-4.54c-1.28-2.66-3.34-4.3-6.17-4.38
				c-4.2-0.11-5.83-2.61-6.58-6.17c-1.06-5.04-2.09-10.09-3.07-15.15c-0.55-2.82-1.5-4.88-4.99-4.99c-3.04-0.09-5.77-1.43-7.94-3.72
				c-2.44-2.57-3.8-0.97-4.6,1.43c-0.41,1.22-0.15,2.65-0.32,3.96c-0.71,5.53,0.36,11.07,0.54,16.67c-6.07,0.08-6.32-7.55-12.07-7.28
				c-1.66,3.61-3.37,7.38-5.1,11.13c-2.43,5.26-6.21,8.2-9.55,6.82c-4.86-2.01-8.33-0.92-10.91,3.55c-1.29,2.24-3.01,3.42-5.78,2.46
				c-4.83-1.67-7.51,0.08-8.09,5.29c-0.31,2.83,0.31,5.61,0.73,8.41c0.78,5.13,0.89,10.27-0.6,15.76c-2.74-1.24-3.74-2.79-4.16-4.55
				c-0.81-3.38-1.33-6.84-2.03-10.25c-0.76-3.71-0.18-8.32-2.99-10.81c-2.62-2.31-3.08-4.69-3.05-7.58c-5.46,0.19-6.21-0.52-9-8.05
				c-0.67-1.8-1-4.55-3.64-3.75c-2.31,0.7-2.13,3.34-1.29,5.14c3.03,6.48-1.43,11.88-4.06,16.1c-5.01,8.06-3.6,15.59-2.47,23.59
				c0.31-2.12,0.3-4.28,0.32-6.44c0.01-1.14-0.34-2.97,0.72-3.2c4.82-1.06,4.03-4.97,4.39-8.19c0.38-3.45,2.22-4.48,5.33-3.81
				c0.7,0.15,1.05,0.77,2.12,0.42c5.21-1.75,6.63-0.98,7.96,4.26c1.48,5.85,1.49,11.86,1.51,17.86c0,1.64-0.6,4.23,0.82,4.64
				c4.24,1.24,8.1,5.04,12.61,3.81c8.76-2.39,16.19,1.4,24.15,3.8c1.54-2.1,1.4-5.84,5.03-6.42c1.65,0.58,2.22,2.72,4.37,2.57
				c3.23-3.09,4.74-6.84,4.01-11.48c-0.31-1.96-0.47-3.98-0.38-5.96c0.07-1.45,0.59-3.65,2.46-2.91c3.51,1.39,5.92-0.39,8.87-2.71
				c-0.09,6.39,5.33,10.72,3.41,16.57c2.91,2.56,5.53,2.31,7.97,0.02c1.76-1.65,1.2-4,1.39-6.07c0.56-6,1.16-11.96,4.09-18.86
				c4.72,10.38,7.19,20.12,10.33,29.51c2.31,6.92,3.13,13.45,12.36,13.06c0.3-0.01,0.6-0.14,1.13-0.28
				c0.5-10.62-3.19-21.04-2.62-32.76c3.58,3.05,2.51,6.98,4.68,9.38c0.91-1.39-0.48-3.53,1.91-4.55c2.23,2.63,1.84,6.63,4.45,9.07
				c1.45,1.35,2.75,3.29,4.94,2.85c1.92-0.39,1.83-2.51,2.25-4.09c0.25-0.94,0.45-1.99,1.78-2.13c1.82,1.09,1.12,2.95,1.18,4.49
				c0.37,10.19,1.79,20.27,4.51,30.03c1.39,4.99-1.87,7.64-4.49,7.57c-7.71-0.22-7.11,5.14-6.62,9.28
				c0.87,7.23-0.95,14.06-1.54,21.07c-0.25,3-0.85,5.97-1.29,8.95c-5.85-11.54-7.22-23.88-7.86-37.16c-8.27,5.38-0.77,11.7-2.47,16.9
				c-7.51,1.38-9.74,0.26-12.07-6.7c-0.64-1.91-1.94-3.36-3.32-3.1c-4.74,0.92-9.51-1.42-14.18,0.43c-1.52,0.6-3.16,1.67-4.79,0.9
				c-4.51-2.12-7.18,0.85-9.09,3.8c-1.85,2.88-3.63,4.99-7.44,4.62c-0.16,4.73-2.37,8.46-4.23,12.39c0.96,0.8,1.62,1.65,2.48,2.02
				c5.48,2.35,8.76,6.58,11.8,11.63c2.68,4.45,5.85,8.89,10,12.28c4.88,3.99,6.49,12.13,2.89,18.94c-1.4-4.7-6.63-4.04-8.62-7.31
				c-2.82-4.64-7.12-1.06-10.23-2.42C401.38,796.44,395.74,798.06,393.94,806.9z M1161.07,1182.3c-2.37,1.32-3.85,4.24-6.64,3.23
				c-4.97-1.82-7,1.15-8.82,4.83c-0.81,1.64-1.61,3.3-2.62,4.82c-2.13,3.21-5.72,5.24-9.02,5.35c-3.45,0.12-5.18-3.79-6.34-6.96
				c-0.97-2.64-1.55-5.43-2.19-8.18c-0.83-3.56-1.32-7.2-2.83-10.58c-1.99-4.46-4-4.86-6.44-0.76c-2.61,4.39-4.89,8.3-10.65,4.11
				c-0.42-0.3-1.49-0.28-1.87,0.05c-4.68,3.98-9.26,0.32-13.81-0.24c-3.15-0.38-4.26-3.62-2.86-5.72c3.76-5.7-0.75-9.82-1.63-14.98
				c-4.78,2.4-9.35,3.58-14.27,2.01c-0.73-0.23-1.93-0.27-2.4,0.16c-4.9,4.56-10.84,4.31-17.92,3.21
				c5.03,10.63,4.64,20.46-0.26,31.47c-3.09-6.85-8.02-7.37-13.63-6.1c-1.41,0.32-2.95-0.32-3.35-1.63c-1.07-3.5-3.95-3.68-6.7-4.03
				c-4.93-0.62-9.21-2.11-11.22-8.68c-2.13,6.91-5.31,11.26-11.72,11.49c-4.76,0.17-5.52,2.64-5.27,6.78
				c0.4,6.81,1.59,13.57,1.16,20.43c-0.14,2.21-0.14,5.04-3.36,4.03c-5.27-1.64-7.81,1.37-10.07,5.08c-1.99,3.27-4.23,6.07-8.44,6.1
				c-2.27,0.02-2.64,1.59-3.04,3.25c-1.49,6.19-2.06,12.48-1.99,18.82c0.18,16.15,1.88,32.23,1.74,48.4
				c-0.12,13.98,0.41,27.96,2.08,41.87c0.88,7.38,1.52,14.8,2.27,22.2c-9.25-38.47-6.12-77.88-9.39-117.21
				c-6.23,4.66-13.25,7.84-18.73,13.82c2.37,2.3,2.49,5.38,0.24,7.11c-5.21,4.03-9.08,9.14-13.19,14.12
				c-0.78,0.95-2.3,1.27-3.46,1.92c-2.46,1.38-4.86,2.88-7.38,4.15c-4.38,2.21-5.11,5.54-4.68,10.29
				c2.62,28.82,6.84,57.56,5.41,86.63c-0.09,1.9,0.15,3.6,1.33,5.17c3.19,4.25,3.66,8.96,1.41,13.66c-2.4,5.02-1.63,9.69,0.7,14.35
				c1.84,3.7,3.8,7.34,5.81,11.21c3.28-2.41,7-6.27,10.2-4.35c5.51,3.3,12.3,4.43,16.76,9.57c2.07-1.62,3.92-3.69,6.94-3.37
				c8.63,0.92,17.37-0.11,25.96,1.91c4.21,0.99,7.6,2.44,8.84,6.89c0.98,3.51,3.32,4.3,6.53,3.47c1.92-0.5,3.8-1.22,5.73-1.67
				c3.91-0.91,7.62-0.91,10.36,2.75c2.83,3.78,5.57,3.67,9.13,0.53c4.49-3.96,9.74-3.59,14.49,0.2c0.88,0.71,1.58,2.28,2.74,1.9
				c5.6-1.83,9.06,2.03,12.93,4.69c1.08,0.75,1.94,0.9,3.18,0.69c6.6-1.12,12.78,1.8,15.57,7.62c0.73,1.53,1.33,2.27,3.07,2.53
				c1.58,0.24,3.09,1.14,4.54,1.92c6.88,3.7,11.42,10.8,19.26,13.18c1.81,0.55,2.97,2.85,5.19,2.65c5.79-0.53,9.38,2.37,11.62,7.65
				c4.07-2.08,7.78,1.05,11.89-0.25c6.63-2.1,11.33,1.02,13.17,7.75c0.43,1.55-0.04,3.38,1.28,4.68c1.51,0.3,2.96,0.48,4.67-0.32
				C1161.07,1392.97,1161.07,1287.82,1161.07,1182.3z M477.71,668.72c0.05-0.98,0.21-1.63,0.1-2.24
				c-2.11-11.94-2.79-24.13-6.12-35.87c-0.63-2.21-0.83-4.59-0.9-6.9c-0.05-1.58-0.19-3.59,1.73-4.32c2.02-0.77,3.98-0.05,5.57,1.29
				c1.6,1.35,2.17,3.3,2.44,5.32c0.95,7.08,1.93,14.16,2.81,21.25c0.47,3.8,0.78,7.62,2.12,11.24c0.44,1.2,0.94,2.65,2.7,2.38
				c1.75-0.26,1.88-1.62,1.87-3.02c-0.03-4,0.12-8-0.13-11.99c-0.61-9.56,0.27-18.93,2.92-28.14c0.36-1.26,0.4-3.33,1.75-3.28
				c4.63,0.17,7.09-4.08,12.3-5.48c1.44,14.17-0.78,28.35,3.61,41.85c0.75-8.52,1.2-17.04,1.51-25.56c0.14-3.87,0.32-7.8,2.24-11.16
				c4.35-7.61,3.74-15.67,2.09-23.59c-2.37-11.38-6.15-22.49-8.19-33.92c-4.54-25.5-11.06-50.32-22.29-73.76
				c-3.84-8.01-6.32-16.42-7.84-25.17c-1.47-8.47-3.34-16.85-6.54-25.24c-6.28,3.54-13.07,4.23-19.87,5.33
				c-0.42,2.93-0.93,5.54-1.13,8.17c-0.56,7.27-0.71,14.47-7.47,19.61c-2.32,1.76-3.26,5.32-4.87,8.03
				c-0.55,0.92-0.63,2.39-2.42,2.19c-2.71-4.03-1.22-9.01-2.66-13.4c-0.68,1.14-1.3,2.19-2.68,4.51
				c-2.08-10.97-1.44-20.39-0.73-29.79c-1.97-1.11-4.08-1.17-5.93-0.61c-7.75,2.38-15.45,1.22-23.25,0.39
				c-5.06-0.54-10.19-1.24-15.35,0.23c-3.2,0.91-3.93,2.45-3.94,5.63c-0.04,10.6,2.12,21.09,0.37,31.83
				c-2.41,14.77-1.28,29.93-4.09,44.71c-0.32,1.68,0.03,3.69-1.89,4.7c-1.41-0.49-1.17-1.61-1.12-2.55
				c0.41-7.64,0.69-15.3,1.34-22.92c0.68-7.99,1.94-16.39-0.15-23.85c-2.79-9.93-3.93-19.86-4.5-29.95c-0.23-4.1-1.81-5.05-5.72-5.4
				c-15.78-1.42-31.64,0.01-47.39-1.66c-3.04-0.32-5.45,1.19-8.11,2c-1.97,0.6-2.28,2.18-1.48,3.54c2.45,4.17,1.17,9.37,3.38,13.29
				c2.93,5.18,1.67,9.91,0.25,14.84c-1.01,3.51-2.16,7-3.52,10.39c-5.42,13.59-5.79,27.88-6.08,42.19c-0.04,1.88-0.06,3.89,1.57,5.47
				c2.99-3.23,3.4-8.11,9.25-10.82c-3.83,20.86-6.46,40.6-13.27,59.44c-1.57-2.85-1.51-2.85-1.2-5.17c1.8-13.85,0.78-27.23-6.7-39.52
				c-1.32-2.18-2.27-4.45-1.55-7.09c1.16-4.24,1.34-8.65,0.8-12.9c-0.71-5.61,0.87-10.41,3.31-15.26
				c5.33-10.56,9.84-21.45,13.02-32.87c0.79-2.86,0.77-5.73-1.91-7.07c-5.05-2.53-4.8-6.51-4.13-10.99c0.26-1.72,1.23-3.45,0.41-5.3
				c-2.24-0.51-4.36-0.07-6.48,0.28c-5.05,0.82-8.99,2.73-9.76,8.62c-0.32,2.4-1.14,5.09-3.17,6.65
				c-6.56,5.05-9.46,12.27-12.16,19.66c-5.32,14.54-9.92,29.38-13.37,44.4c-4.45,19.38-8.97,38.97-4.58,59.2
				c2.53,11.62,3.15,23.76,12.41,32.98c2.61,2.6,3.61,6.46,0.02,10.17c-2.89,2.98-1.35,11.58,2.72,12.71
				c8.08,2.24,8.95,8.34,10.29,14.79c0.74,3.55,2.47,8.91,4.8,9.25c6.13,0.87,4.49,4.66,4.49,7.94c0,8.08-0.09,8.08,7.77,10.64
				c0.87-3.86-0.29-7.96,1.73-11.7c3.7-6.87,7.67-7.26,12.59-0.53c0.46-6.71,5.34-4.4,8.77-4.53c4.37-0.16,4.22,4.09,3.78,6.22
				c-1.88,9.08,0.32,17.74,1.55,26.55c0.7,5.02,0.78,10.12,1.13,15.17c1.97,0.13,2.98-1.1,3.95-2.17c5.22-5.69,11.67-7.95,19.28-7.09
				c0.82,0.09,1.66,0.02,2.5,0.01c1.75,0,2.8-0.68,2.85-2.63c0.17-7.17,0.04-14.32-1.46-21.36c-0.39-1.81-0.81-4.22-3.91-3.23
				c-1.11,4.88,0.16,9.61,1.17,14.28c0.83,3.87-0.16,6.05-3.97,6.85c-4.02-6.76,1.78-14.64-2.75-21.47
				c-2.73,1.34-2.33,3.76-2.58,5.74c-0.28,2.21-0.42,4.92-2.44,5.7c-3.3,1.28-2.53,3.69-2.74,6.02c-0.31,3.42,0.91,7.12-2.22,11.3
				c-2.37-8.66-3.79-16.33-2.43-24.34c0.39-2.31,1.13-4.49,2.34-6.53c3.38-5.71,6.81-11.32,4.87-18.57
				c-0.65-2.43-0.54-5.84,2.93-6.45c3.31-0.58,4.99,1.8,5.98,4.8c0.73,2.18,1.67,5.9,2.87,6.04c5.13,0.63,6.03,3.64,7.86,7.83
				c1.31,3.01,2.84,6.04,3.71,9.3c1.25,4.68,0.94,9.73,3.38,15.5c0.78-5.58,0.45-9.77-0.34-14.01c-1.48-7.9,0.11-11.36,6.9-15.65
				c3.6,3.44,6.37,2.41,8.87-1.74c1.92-3.2,5.28-3.89,8.78-3.31c5.92,0.98,7.7,0.01,10.03-5.43c1.92-4.47,4.05-8.86,6.51-14.22
				c1.51,2.8,3.71,2.48,5.01,3.89c1.28,1.39,1.82,3.58,4.48,4.14c0-5.29-0.06-10.39,0.02-15.49c0.04-2.74,1.57-4.68,3.93-5.94
				c2.43-1.29,3.6,0.64,4.98,2.06c2.11,2.19,4.95,2.71,7.75,2.99c3.16,0.31,4.63,1.83,5.18,4.94c1,5.72,2.29,11.4,3.53,17.08
				c0.43,1.98,1.04,3.88,3.67,4.01c4.39,0.22,7.31,2.73,9.58,7.42c1.12-3.55,4.43-2.85,5.67-4.45c1.13-7.95-7.66-16.71,2.7-23.44
				c2.19,2.43,2.15,4.62,2.5,6.76c0.89,5.38,0.36,11.15,3.43,15.94c1.58,2.46,2.88,4.96,3.14,7.76
				C470.56,667.61,473.03,669.06,477.71,668.72z M1074.29,849.99c-4.58-3.13-4.55-7.47-4.79-11.77c-0.12-2.21,1.03-4.75-1.27-6.52
				c-5.09-3.93-5.35-9.33-4.31-14.93c0.69-3.74,1.88-7.41,3.03-11.05c2.09-6.61,3.48-13.33,4.29-20.54c-2.54,0.9-4.63,1.7-6.76,0.81
				c-2.41-1.01-3.94,0.09-4.92,2.02c-2.61,5.12-5.05,10.31-8.1,16.6c5.09-2.99,6.62-6.98,8.34-10.88c0.74-1.68,1.2-4.46,3.97-3.28
				c2.22,0.95,1.81,3.18,1.34,5.26c-0.93,4.19-2.92,7.91-5.71,10.96c-4.23,4.63-6.24,10.13-7.61,16.02
				c-1.13,4.88,0.15,6.14,5.05,4.84c1.78-0.47,3.45-1.46,4.99,0.42c1.73,2.1,0.35,3.88-0.79,5.39c-3.58,4.75-5.46,10.22-7.27,15.77
				c-2.52,7.74-5.22,15.43-7.76,23.16c-0.73,2.22-1.46,4.16-3.92,3.03c-1.61,8.21-5.55,15.71-6.07,23.96
				c3.92,3.09,6.46,2.17,8.34-2.3c1.49-3.53,2.94-7.04,4.06-10.71c1.17-3.81,2.56-7.55,3.98-11.27c0.73-1.9,2.33-2.99,4.34-2.53
				c1.92,0.44,2.28,2.26,2.31,4c0.05,3.76-1.36,7.14-2.81,10.51c-3.61,8.4-7.39,16.74-9.03,25.83c-0.38,2.09-1.22,4.27,0.09,6.29
				c3.29,5.07,2.76,11.63,6.64,16.56c1.05,1.34,0.78,3.84,0.86,5.82c0.42,10.58-1.79,20.84-3.96,31.1c-0.3,1.43-0.48,2.97-2.08,3.72
				c-2.66-0.36-3.49-3.57-6.12-3.93c-2.02,0.84-3.18,4.2-5.94,2.46c-4.25-2.68-10.16-1.99-13.23-7.55
				c-1.1,7.76,2.36,14.74,8.29,17.52c2.88,1.35,5.62,2.88,8,5.02c1.44,1.29,2.71,2.53,1.08,4.61c-1.44,1.84-2.79,1.14-4.3,0.17
				c-2.72-1.74-5.62-2.82-8.95-2.41c-3.99,0.48-6.52-1.52-8.5-4.84c-1.42-2.39-2.29-5.26-4.96-7.38c-0.38,5.28-1.55,10.45,1.21,14.29
				c3.54,4.92,2.3,10,2.49,15.13c0.11,2.95-1.28,4.57-4.14,5.34c-2.82,0.76-5.9,0.79-9.84,3.37c7.57,1.9,12.01,7.81,19.23,8.1
				c1.64,0.06,3.25,0.29,4.53-1.32c4.16-5.24,11.92-4.32,16.24-9.36c0.18-0.21,0.67-0.32,0.94-0.24c6.1,1.74,7.05-2.94,8.59-6.87
				c1.58-4.02,3.17-8.04,4.73-12.07c0.85-2.19,1.64-4.68,3.68-5.81c7.49-4.12,13.76-9.58,19.5-15.86c2.54-2.78,5.55-2.74,7.77,0.99
				c2.02,3.39,4.88,5.51,8.73,6.95c3.24,1.2,6.85,3.59,6.15,7.93c-2.12,13.21-2.34,26.51-2.36,39.83c0,1.32,0.59,3-1.11,3.96
				c-1.29-1.07-0.94-2.49-0.98-3.74c-0.38-12.66-0.42-25.34,2.05-37.81c0.7-3.52-0.44-6.04-2.95-6.96c-5.7-2.1-9.94-5.68-13.05-10.99
				c-3.84,2.05-6.26,4.75-8.41,7.7c-1.18,1.62-2.2,3.33-4.17,4.16c-7.79,3.27-13.14,8.55-15.13,17.12
				c-0.81,3.51-2.69,6.79-4.17,10.13c-0.53,1.2-1.39,3.3-2,3.27c-6.47-0.34-10.82,4.57-16.38,6.16c-1.99,0.57-3.69,1.04-5.07,2.44
				c-2.88,2.94-4.32,6.68-5.48,10.52c-5.51,18.26-13.05,35.98-13.49,55.53c-0.19,8.43-6.96,14.82-8.25,23.15
				c-2.02,13.1-10.68,23.8-13.69,36.61c-0.87,3.72-2.35,7.32-2.98,11.07c-0.79,4.66-3.7,5.23-7.21,4.04
				c-4.17-1.42-1.84-4.6-0.77-6.56c5.9-10.74,9.41-22.32,12.54-34.05c1.43-5.36,2.55-10.8,3.72-15.81c-3.39-2.64-7.44,0.06-9.86-3.28
				c-4.74-6.54-7.83-13.4-5.89-21.8c3.46-15.06,6.39-30.26,10.25-45.22c1.23-4.77,0.46-7.5-3.5-9.7c-1.75-0.29-2.12,2.24-4.5,1.66
				c-1.74-4.16-6.05-6.34-9.38-9.59c-0.36,0.42-0.53,0.52-0.56,0.65c-3.28,15.95-5.35,32.27-15.08,46.15
				c-1.26,1.8-1.55,4.36-2.04,6.63c-2.45,11.39-5.71,22.56-9.55,33.54c-2.15,6.15-4.65,12.19-7.3,19.1
				c-2.13-2.79-2.54-4.95-2.64-7.29c-0.17-3.71,0.65-7.29,1.26-10.89c2.85-16.71,5.83-33.39,8.61-50.11
				c0.33-1.98,1.77-4.94-0.39-6.05c-4.96-2.55-4.87-5.98-5.14-11.12c-0.51-9.91,0.3-19.55,1.65-29.25c0.25-1.82,0.68-3.43-1.1-4.97
				c-3.44-2.98-6.68-6.19-11.16-9.09c-1.23,7.86-2.28,15.22-3.57,22.54c-0.53,3.02-0.22,6.4-2.91,8.76c-2.59,2.27-3.2,5.2-3.11,8.55
				c0.21,8.28-3.04,15.54-7.04,22.55c-2.01,3.53-5.83,4.04-8.98,4.43c-2.78,0.34-3.74-3.28-4.92-5.63
				c-2.51-4.99-2.11-10.51-2.36-15.93c-2.17,0.35-2.48,1.74-2.82,2.97c-1.14,4.14-2.2,8.3-3.29,12.46c-1.13,4.32-2.73,9.16-7.56,9.52
				c-5.9,0.45-7.54,3.71-8.69,8.4c-0.67,2.74-1.9,5.33-2.6,8.06c-1.22,4.82-3.47,9.46-3.48,15.32c5.66-3,6.94,0.45,7.73,4.03
				c0.88,3.97,2.41,6.85,6.63,8.39c3.93,1.43,8.05,3.33,8.04,8.44c7.15-0.1,7.76,0.34,6.75,7.05c-2.2,14.62-4.08,29.3-9.19,43.34
				c-2.58,7.08-4.57,14.38-6.82,21.58c1.99-1.81,3.24-3.93,4.12-6.18c0.91-2.35,2.49-3.22,4.88-3.52
				c6.71-0.83,10.55-3.91,10.36-10.46c-0.38-13.27,5.9-24.36,10.19-36.16c1.2-3.3,3.55-3.52,6.4-3.09c3.21,0.49,3.4,2.71,2.96,5.22
				c-0.41,2.29-0.75,4.62-1.47,6.81c-3.24,9.78-6.53,19.55-9.98,29.26c-0.94,2.65-1.09,4.85,2.1,5.25c3.68,0.46,4.33,2.87,4.15,5.63
				c-0.25,3.97-1.07,7.89-1.45,11.86c-0.54,5.63-2.9,11.06-1.93,17.84c3.9-5.75,8.21-4.92,12.85-2.29c0.85,0.48,2.3,0.11,3.41-0.13
				c6.7-1.44,6.57-1.43,8.63,4.82c1.08,3.29,2.28,6.99,6.52,7.32c4.37,0.34,6.25-2.87,8.01-6.3c1.84-3.59,2.86-7.67,6.36-11.6
				c2.52,11.53-3.35,20.4-5.97,29.85c-2.69,9.7-5.7,19.3-8.64,29.21c5.27-0.75,8.56-3.34,7.66-5.94c-2.14-6.22,0.96-11.9,1.58-17.82
				c0.25-2.38,2.37-4.11,4.66-4.83c3.07-0.96,3.4-2.94,3.27-5.75c-0.25-5.48-0.22-10.99-0.14-16.48c0.07-5.04,1.45-9.54,6.07-12.39
				c3.61-2.23,5.07-5.53,6.09-9.66c1.42-5.72,4.21-11.56,10.67-12.64c5.54-0.93,8.05-4.02,9.91-8.53
				c2.23-5.41,5.21-10.08,11.39-11.61c1.2-0.3,1.94-1.13,2.58-2.18c3.55-5.77,5.58-11.76,2.65-18.42c-0.73-1.67-1.17-3.09,0.52-4.43
				c5.99-4.75,11.45-10.31,18.34-13.73c4.21-2.09,6.8-5.08,8.5-9.25c0.88-2.14,2-4.2,3.19-6.2c0.96-1.61,2.29-3.07,4.43-2.52
				c4.15,1.06,5.38-1.5,6.01-4.72c1.06-5.38,2.04-10.78,2.96-16.19c1.28-7.46,5.7-13.99,7-21.24c1.24-6.9,3.53-10.69,10.88-10.58
				c2.94,0.05,5.08-3.87,8.27-2.5c2.96,1.27,2.16,5.44,4.72,7.44c4.96-2.23,7.79-7.65,12.58-9.47c5.51-2.1,6.55-5.92,7.03-10.67
				c0.37-3.64,0.69-7.28,1.01-10.93c0.94-10.61,1.3-21.26,2.91-31.81c0.74-4.83,2.93-8.38,6.99-11.49c3.32-2.54,7.84-1.85,10.6-5.14
				c1.84-2.2,4.03-3.96,7.4-3.31c-0.55-8.09,4.22-14.2,9.23-17.52c5.77-3.82,11.49-7.63,16.96-11.92c2.77-2.18,3.88-4.33,3.87-7.88
				c-0.12-54.79-0.08-109.58-0.1-164.38c0-1.77,0.47-3.65-0.79-5.57c-5-0.24-10.14-0.25-15.23-0.83c-3.26-0.37-4.5,0.58-4.79,3.81
				c-1.12,12.44-5.81,24.17-7.48,36.52c-0.24,1.74,0.08,4.11-2.87,5.14c-4.39-15.44-2.2-30.57,1.42-46.36
				c-6.12,6.83-7.94-3.31-12.65-1.81c-0.35,1.03-0.79,2.26-1.18,3.5c-2.62,8.41-2.92,17.79-10.4,24.17
				c-1.17,0.99-1.83,2.81-2.27,4.38c-2.49,8.66-8.5,15.35-13.25,22.61c-3.83,5.86-7.82,11.45-10.43,18
				C1079.57,841.25,1077.53,845.78,1074.29,849.99z M658.35,1336.47c0.54-5.93,0.63-11.59,1.66-17.08
				c2.23-11.88,0.47-24.84,9.11-35.08c0.46-0.54,0.36-1.58,0.47-2.4c0.81-6.43,1.39-12.91,2.47-19.3c1.77-10.5,0.85-21.34,3.83-31.65
				c1.27-4.4,0.63-8.12-1.77-11.82c-1.36-2.1-3.31-4.73-1.96-6.89c2.59-4.14,4.45-9.33,8.62-11.79c3.62-2.15,4.64-4.75,4.84-8.24
				c0.19-3.27,0.04-6.57,0.04-10.56c-3.4,2.31-6.43,3.6-8.64-0.62c-7.14,2.93-6.73,13.91-17.21,14.27
				c5.72-3.38,8.48-7.44,10.27-12.47c1.06-2.96,5.24-5.41,7.1-4.04c4.46,3.29,6.77,0.24,10.33-2.46c0.98,8.34,1.32,15.82-0.59,23.29
				c-0.11,0.43-0.53,1.07-0.88,1.11c-5.15,0.71-5.97,5.72-8.89,8.62c-2.86,2.85-3.1,5.17-0.98,8.21c3.16,4.54,4.01,9.35,2.04,14.66
				c-0.68,1.84-0.97,3.89-1.08,5.87c-0.81,15.31-3.65,30.39-5.02,45.63c-0.12,1.37-0.36,2.63-1.54,3.52
				c-2.19,1.65-2.78,4.17-3.13,6.61c-1.86,13.18-3.59,26.37-5.36,39.56c-0.29,2.16-0.71,4.11,2.16,5.28
				c2.69,1.09,4.58,1.06,6.57-1.22c4.18-4.76,8.59-9.33,14.08-12.61c2.59-1.55,3.68-3.55,3.68-6.5c0-5.68,0.99-11.26,2.29-16.77
				c0.86-3.64,2.18-6.66,6.34-8.18c2.29-0.84,4.38-2.8,6.73-4.01c2.95-1.52,3.99-3.86,3.81-7.27c-0.3-5.83-0.07-11.83,2.23-17.21
				c1.98-4.61,2.44-9.26,2.64-14.05c0.28-7.01,1.22-13.6,7.95-17.76c0.96-0.59,2.35-1.38,2.37-3.61c-1.99-1.6-4.32-3.41-6.57-5.3
				c-1.73-1.45-3.7-3.88-2.15-5.51c3.97-4.16,5.94-9.2,8.21-14.22c0.45-0.99,2.1-1.57,3.3-2.07c3.03-1.24,4.25-3.48,4.24-6.65
				c0-1.18-0.49-2.57,1-3.85c1.71,3.31,4.1,6.58,1.79,9.82c-3.22,4.52-4.46,9.28-4.54,14.66c-0.04,2.71-0.55,5.35,2.13,7.18
				c2.68,1.83,5.21,1.72,7.75-0.36c2.35-1.92,5.22-0.77,7.51-0.28c1.97,0.42,1.58,3.42,1.39,5.36c-0.73,7.78-1.6,15.55-2.43,23.32
				c-0.14,1.34-0.62,2.47-1.56,3.56c-4.15,4.75-4.02,14.1-0.15,19.03c2.28,2.9,3.66,7.13,0.77,9.5c-4.67,3.84-7.94,9.43-14.12,11.54
				c-2.06,0.71-4.22,2.02-5.29,4.69c2.74,2.42,2.49,4.42,0.76,8.17c-1.75,3.78-0.09,9.2-0.73,13.89c-0.05,0.35,0.85,1.04,1.42,1.22
				c11.91,3.74,20.3,13.17,30.84,19.08c8.15,4.58,13.29,11.07,16.51,19.74c4.67,12.53,16.53,16.72,27.24,23.12
				c0.21-4.69,0.11-8.54,0.6-12.31c0.86-6.54,1.08-13.5,8.02-17.25c0.93-0.5,0.86-1.35,0.86-2.14c-0.03-6.49,0.43-13.02-1.04-19.41
				c-0.8-3.46-3.06-6.48-5.95-8.09c-2.6-1.45-3.01-3.05-3.04-5.51c-0.08-6.66-0.3-13.32-0.47-19.97
				c-0.32-12.01-0.29-12.09,11.01-15.61c2.9-0.91,6.83-1.77,7.04-4.79c0.42-6.11,5.31-11.18,3.36-17.86
				c-0.88-3.01-0.73-7.92,2.56-8.96c5.77-1.81,8.73-7.34,14.27-8.95c1.35-9.38-1.81-18.37-0.83-27.71
				c1.51-14.45,0.27-17.69-4.81-26.48c2.55,12.7-2.98,24.28-2.88,36.43c-3.03-12.8,1.71-25.83-1.75-39.89
				c-1.58,10.97,0.15,20.8-1.23,30.47c-5.44-16.43,0.3-33.58-4-51.54c-1.6,4.45-3.78,6.01-7.21,6.77c-2.72,0.6-5.44,2.63-6.11,5.95
				c-1.56,7.75-4.11,15.22-3.87,23.41c0.22,7.43-0.84,14.93-0.9,22.43c-0.02,2.65,0.05,5.81-3.56,6.78
				c-2.01-1.59-1.39-3.74-1.43-5.65c-0.28-16.19-1.16-32.26-8.04-47.34c-0.73-1.59-1.06-3.63-0.83-5.35
				c1.12-8.33-5.14-14.48-6.35-22.15c-1.28,2.26-2.57,4.52-3.86,6.78c1.82,1,4.08-0.47,5.3,2.05c-2.34,3.08-7.17,3.05-8.96,7.14
				c-1.96,4.49-6.07,6.45-10.87,6.75c-3.04,0.19-5.77,0.92-8.48,2.49c-8.04,4.66-16.29,8.91-25.51,10.82
				c-4.12,0.85-7.06-1.56-7.52-6.37c-0.41-4.33,2.75-4.26,5.65-4.31c5.58-0.1,11.01-0.91,15.66-4.21c4.13-2.92,8.5-5.15,13.4-6.42
				c2.28-0.59,4.79-1.4,5.16-4.09c0.56-4.02,3.05-5.95,6.42-7.53c5.54-2.6,5.61-6.06,1.13-10.26c-3.61-3.38-8.77-3.23-12.32-6.2
				c-2.66-2.21-4.55-2.25-6.57,0.52c-1.53,2.09-3.71,2.95-6.16,2.2c-4.55-1.39-9.44-2.06-12.71-6.24c-1.78-2.27-4.38-3.56-7.03-1.73
				c-4.01,2.77-8.1,5.26-13.16,6.57c5.6,1.3,5.79,6.61,8.45,9.6c5.05,5.69,3.46,10.89,2.17,16.64c-0.44,1.97-0.66,3.88-0.34,5.92
				c0.51,3.21-4.86,9.26-7.97,9.21c-4.27-0.08-6.46-3.48-4.37-7.7c2.6-5.25,4.49-10.29,1.88-16.14c-0.63-1.41-0.25-3.29-0.22-4.95
				c0.05-2.44-0.6-4.58-2.62-6.85c-5.18,6.85-10.86,5.59-16.68,0.87c-1.38-1.12-3.21-1.39-4.75-0.75c-2.59,1.08-5.16,1.06-7.83,0.78
				c-1.9-0.2-3.67,0.3-5.18,1.44c-6.05,4.57-11.8,9.22-8.74,18.28c0.78,2.31,0.79,4.92,0.9,7.41c0.13,2.89,1.49,6.39-1.62,8.3
				c-2.94,1.8-5.72-0.42-8.35-1.76c-1.03-0.53-1.95-1.27-2.93-1.9c-1-0.64-2.04-1.04-3.29-0.84c-8.63,1.41-14.8,7.81-16.26,17.07
				c3.34,1.44,5.3,3.63,3.99,7.74c-0.62,1.94,0.03,4.2,1.37,5.65c4.06,4.4,4.24,9.75,3.58,15.03c-2.43,19.49-4.12,39.05-5.28,58.65
				c-0.1,1.62-0.03,3.41-1.97,4.08c-2.44-0.11-2.21-3.09-4.09-3.83c-3.76,9.76-3.92,19.9-4.81,29.89
				c-0.46,5.13-0.04,10.31,0.56,15.45c0.22,1.91,0.87,3.03,2.78,3.54C640.39,1331.58,649.16,1333.98,658.35,1336.47z M1030.46,772.69
				c2.09-0.99,2.04-5.71,5.3-3.92c3.78,2.08,5.77,1.1,7.59-2.24c0.64-1.18,1.73-2.2,3.12-2.3c4.1-0.29,7.23-3.27,11.64-3.43
				c3.2-0.12,6.35-2.24,9.9-0.77c1.12,0.46,2.2-0.41,3.08-1.25c9.62-9.02,21.42-12.97,34.15-15.02c6.74-1.08,11.61-4.16,14.02-11.52
				c2.88-8.8,7.03-17.32,14.2-23.7c5.31-4.72,8.13-12.25,15.96-14.16c0.66-0.16,1.12-1.18,1.65-1.82c2.75-3.34,5.43-6.74,8.25-10.03
				c1.5-1.75,2.1-3.61,2.07-5.92c-0.1-7.83,0-15.65-0.05-23.48c-0.03-4.61-1.42-5.54-5.81-4.02c-4.78,1.65-7.16,5.74-9.72,9.63
				c-1.46,2.22-2.74,4.88-5.87,4.82c-5.39-0.1-7.61,3.18-9.02,7.67c-0.94,2.99-1.18,6.55-3.88,8.46c-3.25,2.3-5.35,5.33-7.47,8.54
				c-2.26,3.43-5.45,5.29-9.8,4.36c-0.77-0.17-1.95-0.34-2.38,0.07c-4.06,3.86-9.63,5.16-14,8.39c-4.29,3.16-6.39,2.83-8.8-1.92
				c-1.38-2.72-1.27-6.06-3.33-8.56c-7.21,5.82-7.21,5.82-12.45,0.66c-1.79,0.41-3.39,1.61-5.54,0.18c-6.86-4.55-8.37-4.19-13.41,2.2
				c-0.99,1.26-1.73,3.15-3.8,2.7c-10.98-2.41-17.55,3.15-22.58,12.03c-3.42,6.05-9.26,9.14-15.58,12.13
				c-3.52-6.99,2.15-13.32,0.33-20.37c-3.51,3-8.56,0.56-11.58,4.67c-1.21,1.65-3.23,3.4-5.58,2.24c-2.44-1.2-2.1-3.68-2.15-6.11
				c-0.09-4.16-0.05-9.04-2.53-12.03c-2.26-2.72-4.97-7.06-10.24-5.54c-1.11,0.32-2.13-0.97-2.65-2c-3.27-6.53-8.35-6.64-14.35-4.41
				c-2.45,0.91-5.7,2.11-7.49-0.35c-3.59-4.94-7.6-7.39-13.87-9.15c-4.75-1.33-9.59-3.27-14.71-4.12
				c-6.61-1.1-12.28-7.24-11.98-13.58c-7.13,4.45-14.51,7.09-22.4,8.18c-0.15,0.02-0.3,0.14-0.43,0.25
				c-6.1,4.92-13.69,6.63-20.65,9.72c-2.26,1-4.68,2.03-6.47,3.66c-5.42,4.95-11.83,7.12-18.95,8c-12.26,1.52-21.78,8.79-32.04,15.12
				c8.64,6.04,8.64,6.04-0.07,12.12c0.14,2.7,2.08,4.23,4.17,5.1c18.6,7.75,34.04,21.8,53.88,27.07c0.95,0.25,1.87,0.71,2.75,1.18
				c9.57,5.12,19.08,10.75,26.56,18.42c6.61,6.77,14.33,8.33,22.66,9.74c2.11,0.36,4.11,0.75,6.19-0.55c2.35-1.47,4.45-0.1,6.39,1.3
				c0.92,0.66,1.76,1.78,2.76,1.95c7.54,1.3,14.95-0.8,22.44-1.12c1.14-0.05,1.52-1.18,2.24-1.81c3.96-3.46,8.59-3.83,12.68-0.42
				c4.19,3.49,9.02,3.11,13.51,2.2c3.48-0.71,5.82,0.12,8.15,2.14c3.73,3.23,7.46,3.48,11.31,0.42c1.11-0.88,2.18-0.29,3.24-0.47
				c3.58-0.6,7.54-0.07,10.67-1.19c2.83-1.02,4.47-0.89,6.63,0.57C1027.53,772.12,1028.88,772.82,1030.46,772.69z M1133.83,1197.47
				c5.23-1.49,7.72-4.93,9.74-9.17c1.55-3.27,3.62-6.68,8.08-6.35c7.07,0.51,9.71-1.83,9.71-9.04c0.01-73.93,0.01-147.87-0.03-221.8
				c0-1.56,0.55-3.27-0.83-5.09c-4.7,4.05-8.56,8.99-14.23,11.6c-8.25,3.8-12.23,10.26-12.36,19.33c-0.04,2.55,0.7,5.53-1.91,7.77
				c-2.41-1.86-1.49-4.99-3.55-6.91c-2.77,1.43-4.36,4.52-7.61,5.32c-8.06,1.98-10.99,5.2-12.03,13.07
				c-0.55,4.12-0.67,8.29-1.18,12.42c-1.42,11.38-0.83,22.96-3.51,34.21c-0.55,2.31-0.97,4.98-3.46,5.69
				c-4.6,1.3-7.49,4.78-10.99,7.53c-7.04,5.55-8.27,7.53-11.65-2.65c-0.14-0.42-0.64-0.72-0.92-1.02c-0.27-0.04-0.46-0.13-0.58-0.07
				c-1.2,0.54-2.39,1.09-3.57,1.66c-6.17,2.97-9.72,7.25-12.54,13.61c-4.09,9.23-6.36,18.87-7.82,28.78
				c-0.39,2.62-1.06,5.22-1.9,7.73c-0.79,2.35-2.6,4.38-5.16,3.9c-4.81-0.9-5.99,2.28-7.37,5.57c-1.67,3.98-3.01,8.36-7.23,10.41
				c-6.53,3.17-11.87,7.91-17.51,12.31c-2.51,1.96-3.47,3.5-2.22,6.95c2.44,6.71-1.24,19.09-6.38,20.54
				c-6.91,1.96-9.82,6.78-12.2,12.67c-1.4,3.47-3.42,6.97-7.43,7.43c-8.25,0.95-10.97,6.59-12.39,13.5c-0.66,3.2-1.54,5.92-4.74,7.65
				c-5.16,2.8-7.25,7.46-7.05,13.25c0.19,5.49,0.12,10.99,0.53,16.46c0.22,2.9-0.4,4.61-3.22,5.84c-5.71,2.49-6.81,5.09-5.63,11.98
				c2.94-1.96,5.67-4.13,8.7-5.73c5.84-3.1,10.45-6.81,10.29-14.26c-0.04-1.74,0.86-3.53,1.45-5.26c0.5-1.47,1.3-2.85,3.18-2.57
				c4.26,0.63,6.29-2.04,8.22-5.17c2.68-4.34,6.21-7.39,11.72-6.43c1.1-1.91,0.69-3.78,0.59-5.57c-0.33-6.15-0.74-12.29-1.19-18.44
				c-0.29-4.04,1.06-6.3,5.54-6.28c5.46,0.02,9.53-2.08,11.39-7.79c0.77-2.38,2.36-4.8,6.15-4.44c-0.51,7.46,4.91,7.67,10.13,9.51
				c6.24,2.21,12.11,5.43,18.87,6.13c0.54,0.06,1.04,0.62,1.76,1.07c0.42-0.87,0.74-1.42,0.96-2.02c2.63-7.25,0.43-14.04-1.81-20.91
				c-1.55-4.75-0.83-5.47,3.84-4.65c10.43,1.83,19.61-4.8,29.83-4.42c0.71,0.03,1.43-0.8,2.18-1.15c0.81-0.38,1.55-0.26,2.3,0.32
				c1.57,1.22,5.4,12.42,4.81,14.26c-1.93,6.04-1.91,5.97,4.24,7.71c2.23,0.63,4.21,0.73,6.16-0.66c1.27-0.91,2.91-1.65,4.18-0.64
				c4.38,3.49,5.77,0.18,7.8-2.88c3.87-5.85,7.96-5.44,11.05,0.86c2.15,4.4,2.59,9.25,3.75,13.92
				C1129.7,1190.89,1130.21,1195,1133.83,1197.47z M2.08,467.31c-0.1,1.8-0.21,2.92-0.21,4.05c-0.01,88.61,0,177.22-0.06,265.83
				c0,2.7,1.1,4.56,2.52,6.72c5.55,8.46,15.02,11.54,23.12,15.76c6.45,3.36,10.61,7.47,13.85,13.37c2.15,3.93,3.84,8.21,7.28,11.25
				c3.69,3.26,6.52,7.14,8.59,11.53c8.81,18.67,16.67,37.67,20.04,58.22c0.29,1.79,0.66,3.7,1.55,5.23
				c6.38,10.93,12.15,22.11,15.31,35.02c3.59-2.16,6.84-0.98,10.02-0.44c3.17-4.89,6.31-9.37,12.53-9.95
				c2.08-0.19,2.62-1.9,2.79-3.59c0.38-4.02,2.93-6.15,6.36-7.46c9.96-3.78,16.28-15.72,13.73-26.23c-0.95-3.92-2.15-8.08-0.51-11.78
				c2.64-5.93,3.09-12.04,3.96-18.31c0.83-5.98-0.64-11.57-0.47-17.32c0.14-4.73-1.12-8.43-5.09-11.16
				c-1.99-1.37-3.34-3.46-3.54-5.82c-0.25-2.93-1.36-5.43-2.66-7.95c-1.66-3.24-2.02-6.63-0.95-10.17c1.44-4.77,1.15-9.43-0.52-14.18
				c-2.94-8.41,0.56-16.24,2.69-24.16c0.08-0.29,0.82-0.39,1.32-0.61c2.68,1.94,4.09,5.71,7.76,5.93c3.25,0.2,4.17,2.44,5.83,4.59
				c6.62,8.62,13.7,16.86,17.71,27.22c0.67,1.74,2.22,3.22,2.64,5.24c1.72,8.32,6.79,15.09,10.95,22.18
				c1.72,2.94,1.92,6.66,5.25,9.84c-1.31-5.4-0.57-10.2-2.86-14.43c-3.16-5.83-6.34-11.64-9.68-17.37
				c-4.72-8.09-6.26-17.76-12.46-25.14c-0.65-0.77-0.81-2.25-0.69-3.34c0.39-3.4-1.51-5.94-2.63-8.87
				c-1.88-4.92-6.25-8.84-6.27-14.36c-0.01-2.93-0.92-5.47-1.49-8.2c-1.53-7.37-7.71-11.88-10.62-18.34
				c-1.96-4.35-5.38-7.06-10.67-8.37c-3.76,3.9-7.73,8.03-11.72,12.15c-2.42,2.5-5.52,3.17-8.72,2.5c-2.98-0.62-3.16-3.4-3.64-5.9
				c-1.45-7.5-0.72-14.74,2.35-21.7c0.71-1.62,1.13-3.29,0.66-4.84c-1.17-3.86-0.8-7.8-1.18-11.69c-0.41-4.19,1.15-8.32,0-12.4
				c-1.99-7.04-0.84-13.42,3.53-19.26c2.39-3.19,2.66-6.3-0.35-9.37c-3.02,0.78-5.28,2.81-7.86,4.25c-1.47,0.82-3.15,1.31-4.74,0.24
				c-1.84-1.25-1.03-2.93-0.53-4.51c2.27-7.18,0.83-14.39,0.6-22.02c-5.81,1.55-9.15-3.72-14.26-4.04
				c-4.8,11.58-1.2,24.17-4.98,35.68c8.17,3.15,8.2,3.15,8.16,11.71c-0.01,2.07,0.03,4.14,1.95,5.35c3.56,2.24,4.68,5.88,5.88,9.53
				c3.9,11.8,3.6,23.99,2.81,36.11c-0.3,4.63-1.11,9.45-3.53,14.22c-3.72-3.49-6.37-0.58-9.03,1.3c-1.95-1.86-1.09-4.56-3.05-6.2
				c-6.63,5.45-9.57,14.03-15.5,19.73c-2.82-0.9-2.61-3.66-4.48-4.56c-6.5,2.24-10.79-1.3-9.91-8.53
				c1.57-12.87,2.47-25.83,5.28-38.55c1.44-6.53,1.87-12.95-1.57-19.37c-3.1-5.79-2.48-12.17-0.72-18.49
				c3.43-12.37,4.72-25.31,10.34-37.12c-2.91,3.97-5.33,8.11-7.76,12.25c-1.81,3.08-3.09,7.71-7.44,6.84c-4-0.8-5.15-5.26-6.28-8.96
				c-0.29-0.94-0.29-1.97-0.45-2.96c-1.06-6.47-0.81-12.98-0.04-19.43c0.84-7.02-2.05-13.39-2.1-21.06
				c-4.61,4.92-6.02,10.54-9.99,15.74c-0.89-4.38-0.03-7.64,0.42-10.78c0.51-3.59-0.54-6.24-3.21-8.26
				c-3.51-2.66-5.32-6.31-6.59-10.33c-0.56-1.77-1.03-3.03-3.3-3.71c-4.46-1.35-6.83-5.08-7.96-9.48c-1.18-4.58-1-9.23-0.54-13.9
				c0.52-5.29,2.51-11.18,0.9-15.76c-2.72-7.73-2.51-15.34-2.65-23.1C5.28,470.8,4.44,469.22,2.08,467.31z M951.15,869.57
				c2.13-6.19,5.77-9.97,9.94-13.42c3.2-2.64,6.25-5.5,7.86-9.52c3.1-7.75-0.62-16.64-7.63-18.4c-9.58-2.4-19.36-4.03-27.44-10.71
				c-3.62-2.99-8.44-4.53-12.77-6.63c-3.55-1.72-7.41-1.97-11.23-1.52c-11.73,1.36-22.47-1.83-32.78-6.99c-2-1-3.94-1.89-6.13-0.73
				c-1.25,0.66-2.75,1.09-3.83,0.35c-6-4.09-13.77-1.57-19.91-5.66c-2.89-1.93-6.33-3.06-8.01-6.34c-1.7-3.31-3.93-4.56-7.65-3.48
				c-4.11,1.19-7.19,0.1-8.27-4.61c-0.28-1.23-1.38-2.24-1.94-3.43c-1.55-3.29-5.85-2-7.25-3.66c-2.66-3.15-5.52-4.15-9.24-4.46
				c-3.77-0.31-6.33-3.13-9.44-4.85c-2.14-1.19-3.29-2.03-3.71-4.5c-1-5.86-5.88-7.89-10.68-4.34c-3.42,2.53-6.4,3.21-9.36-0.12
				c-3.18,1.81-5.32,5.39-9.39,4.19c-8.19-2.4-10.65-1.49-15.51,5.76c-1.27,1.91-2.29,4.58-4.62,5.05
				c-6.81,1.4-9.27,7.69-13.74,11.67c-3.92,3.49-5.88,9.17-12.04,10.11c-1.62,0.25-2.85,1.59-3.69,3.14
				c-1.63,3.01-4.29,3.66-7.24,2.46c-2.54-1.03-4.3-0.45-5.29,1.92c-1.26,3.02-3.43,4.38-6.6,4.27c-1.94-0.07-2.99,0.95-3.86,2.56
				c-1.15,2.14,0.73,2.94,1.6,4.22c5.17,7.54,13.39,10.51,21.2,14.03c2.38,1.07,4.8,2.67,7.56,2.24c3.38-0.53,5.18,1.27,6.94,3.69
				c2.42,3.32,4.65,7.22,9.98,5.1c0.53-0.21,1.58,0.43,2.16,0.93c4.91,4.23,11.87,5.86,15.28,12.02c0.27,0.48,1.06,0.66,1.57,0.96
				c5.03-5.42,5.17-5.38,10.55,0.01c2.44,2.45,4.6,6.17,8.41,2.89c7.53,6.34,14.82,12.47,21.86,18.4c3.06-1.92,2.57-5.72,5.92-6.27
				c3.12,1.73,4.22,5.92,7.95,7.1c1.7-2.35,0.61-5.63,3.41-7.36c5.12,3.91,4.1,15.6,15.15,12.34c5.51,7.8,5.51,7.8,11.2,3.65
				c2.68,4.47,9.43,2.64,11.1,8c2.16-0.8,1.97-3.13,4.14-3.09c2.21,2.74,3.18,8,7.53,7.56c5.32-0.54,11.75,0.6,15.1-5.94
				c1.14-2.23,3.12-5.28,6.09-4.14c3.6,1.38,5.6-0.09,7.85-2.24c0.69-0.66,2.17-1.61,2.49-1.37c4.25,3.21,6.72-0.8,9.5-2.51
				c2.76,1.82,2.7,5.36,5.61,6.9c1.99-1.75,3.02-4.76,5.81-5.4c2.06,0.82,2.79,3.36,5.49,2.99c3.82-7.93,8.34-15.1,19.28-11.49
				c1.19,0.39,2.23-0.68,2.93-1.6C945.7,866.21,948.15,865.95,951.15,869.57z M1132.97,65.58c-0.6-5.92-3.09-10.1-7.22-13.28
				c-4.69-3.61-8.58-3.52-12.78,0.62c-1.78,1.75-3.11,1.39-4.79,0.41c-7.2-4.21-13.37,0.47-21.15,1.83c0.14,0.22-0.3-0.68-0.94-1.43
				c-7.22-8.44-16.69-9.65-26.82-7.83c-4.1,0.73-6.74,3.5-6.69,8.1c0.01,1.13,0.32,2.36-1.05,3.57c-4.85,0.47-12.27-4.3-13.53,5.49
				c-14.41-3.37-16.04-0.05-17.2,9.18c-2.79-2.03-2.72-4.48-1.48-6.96c3.52-7.02,9.57-7.36,16.43-5.65c2.39-6.74,8.29-4.8,13.39-5.36
				c1.2-5.93,4.39-10.16,10.52-11.22c8.49-1.47,16.61-0.29,23.84,4.69c5.4,3.72,11.25,3.76,17.34-0.43c-1.41-3.16-3.75-6.04-7.03-6.3
				c-5.54-0.44-8.57-3.74-11.61-7.57c-1.02-1.29-2.34-2.39-3.67-3.36c-9.16-6.69-16.7-4.62-21.07,5.7c-0.25,0.58-0.77,1.04-1.16,1.55
				c-2.04-0.28-2.72-1.97-3.78-3.17c-5-5.62-8.89-5.73-14.15-0.44c-0.79,0.8-1.76,1.63-2.8,1.95c-5.05,1.53-8.04,6.82-13.69,7.33
				c-1.22,0.11-2.11,1.69-2.28,3.06c-0.46,3.76-2.96,4.02-6.05,4.17c-9.73,0.45-12.4,3.39-12.61,13.3c-0.01,0.65-0.12,1.33-1.08,1.66
				c-1.67,0.06-2.72-1.47-4.16-2.19c-11.32-5.71-22.76-5.93-33.98-0.03c-7.58,3.98-10.53,12.34-7.87,20.38
				c0.22,0.66,0.27,1.28-0.9,1.86c-2.9-2.59-3.64-5.81-2.82-9.66c2.4-11.27,11.43-18.4,24.31-19.11c8.04-0.44,15.81,0.64,23.36,4.76
				c1.49-9.61,6.88-13.93,15.9-13.71c1.71,0.04,2.78-0.44,3.32-2.2c0.77-2.51,2.48-4.34,4.86-5.4c1.54-0.68,1.69-1.8,1.59-3.24
				c-0.34-4.61-3.9-9.79-8.53-10.68c-5.3-1.02-10.77-1.02-15.65-3.87c-1.21-0.7-2.78,0.2-3.64,1.1c-2.64,2.77-5.36,1.93-8.18,0.56
				c-3.89-1.89-7.75-3.99-12.08-4.6c-9.67-1.37-18.49-0.58-20.12,13.03c-0.05,0.42-0.55,0.78-1.02,1.41
				c-4.61-2.32-9.17-3.26-12.46,1.86c-24.9-9.5-36.95-3.81-37.85,17.75c-1.78,0.44-3.07-0.94-4.61-1.3
				c-1.95-0.45-4.19-1.05-5.67,0.62c-3.79,4.25-9.08,5.02-14.06,6.42c-4.54,1.27-8.34,3.12-10.08,7.94c-0.52,1.44,0.27,3.44-1.6,4.39
				c-7.6-6.04-13.17-7.51-17.66-4.03c-5.74,4.45-8.46,10.5-7.16,18.23c-3.65,0.18-5.95-1.52-8.28-2.97c-4.4-2.73-8.8-5.39-14.02-6.27
				c-3.13-0.53-6.91-0.39-8.15,2.28c-2.69,5.81-6.96,4.19-11.3,3.73c-2.97-0.32-7.05-1.01-8.26,1.74c-1.72,3.92-4.17,2.86-6.82,2.7
				c-3.62-0.23-7.8-1.43-10.36,1.79c-4.19,5.28-9.92,6.56-15.92,7.41c-4.75,0.67-9.4,1.28-12.74,5.48c3.35,4.02,7.6,4.54,12,4.27
				c13.11-0.81,26.21-2.17,39.33-2.51c14.63-0.38,29.09-2.99,43.8-2.43c20.28,0.78,40.57-0.14,60.85-0.95
				c21.06-0.83,42.1-2.8,63.25-0.26c18.01,2.17,36.2,1.84,54.32,2.02c3.11,0.03,7.36-0.89,9.1-3c3.13-3.8,6.71-4.42,10.87-4.36
				c6.74-23.75,32.65-28.47,54.41-9.81c2.37-3.35,7.03-1.41,9.62-3.84c4.8-4.51,9.59-4.69,15.03-1
				C1122.32,72.95,1126.01,67.8,1132.97,65.58z M935.73,970.96c0.59-1.52,1.09-2.72,1.53-3.94c3.23-9.11,7.02-17.96,11.93-26.31
				c2.38-4.04,6.58-6.34,8.91-10.59c9.26-16.86,14.86-35.45,25.23-51.83c5.2-8.22,8.18-17.81,12.76-26.48
				c2.99-5.67,5.91-12.36,12.08-14.72c4.44-1.7,6.19-4.29,7.74-7.97c1.42-3.37,2.82-6.76,4.4-10.05
				c7.32-15.28,15.68-29.71,31.47-37.99c0.71-0.37,1.43-0.97,1.84-1.65c3.62-5.99,8.17-11.27,13.57-16.98
				c-5.77-1.49-9.96,1.98-14.43,2.84c-2.2,0.42-5.95,0.81-7.36,3.25c-2.67,4.62-5.92,5.92-10.93,2.74c-0.73,3.55-2.95,4.95-5.62,3.87
				c-3.67-1.48-6.89-2.08-11.02-1.06c-4.24,1.05-9.08-0.5-12.76,3.23c-0.73,0.74-1.86,0.27-2.85,0.23c-4.01-0.17-7.66-1.02-10.7-3.94
				c-0.86-0.83-2.12-1.44-3.66-1.02c-5.76,1.56-11.15,0.81-16.04-2.86c-3.83-2.87-7.62-2.37-10.75,1.42
				c-0.53,0.64-1.01,1.78-1.75,1.54c-6.46-2.13-12.62,3.88-19.15,0.65c-0.94-0.47-2.68-0.69-3.58,1.89c5.31,0.32,9.12,3.15,12.24,7.1
				c0.3,0.37,1.32,0.52,1.83,0.31c3.42-1.36,5.81-0.24,7.46,2.88c0.88,1.66,2.5,1.31,3.94,1.58c7.65,1.47,14.78,4.04,20.16,10.05
				c4.04,4.52,4.11,8.79,0.19,13.39c-2.44,2.86-5.52,4.76-9.14,5.79c-4.34,1.25-8.82,1.54-13.28,2.07
				c-4.77,0.57-9.79,0.18-15.36,3.05c5.16,0.73,8.82,2.19,13.15,2.04c8.03-0.27,15.45,2.35,21.14,8.42
				c4.51,4.82,4.12,13.64-0.59,18.23c-2.43,2.36-5.24,4.38-7.2,7.45c2.33,1.16,4.24,2.12,6.72,3.35c2.83-6.89,9.54-1,13.7-4.58
				c-0.64,2.43-2.15,3.94-4.28,4.47c-1.81,0.45-3.72-1.56-5.69,0.11c-1.57,1.33-2.86,3.51-5.53,2.39c-0.15-0.06-0.34-0.07-0.47-0.16
				c-5.43-4.05-8.44-0.34-11.35,3.56c-1.52,2.04-2.83,4.31-6.01,2.63c-0.45-0.24-1.58-0.05-1.8,0.31c-3.08,4.92-6.37,2.25-9.37,0.11
				c-5.08,4.27-5.45,4.4-8.97,3.41c-3.31-0.93-10.68,2.38-12.15,5.47c-3.75,7.85-3.75,7.85-10.96,4.24
				c-5.48,6.37-5.48,6.37-10.9-0.72c-5.07,3.05-11.77,0.89-16.37,5.14c-0.17,0.15-0.7,0.08-0.96-0.07c-3.74-2.07-5.37,0.63-7.18,3.08
				c-2.27,3.09-4.51,5.02-9.12,5.54c-6.09,0.68-12.54,0.99-18.41,3.52c-2.76,1.19-4.64,2.75-3.37,6.08c1.9,5.01-0.35,8.28-4.42,10.88
				c-1.68,1.07-3.35,2.19-4.86,3.49c-4.23,3.63-4.36,7.37-0.02,10.88c2.96,2.38,6.38,4.2,9.64,6.19
				c10.31,6.33,10.39,13.14,0.19,19.53c-5.17,3.24-5.46,5.81-1.16,10.22c1.7,1.75,4.38,3.06,4,5.95c-0.5,3.76,1.85,5.03,4.58,6.1
				c2.16,0.85,4.47,1.35,6.6,2.26c8.74,3.7,16.45,8.25,20,18.1c1.65,4.58,7.24,6.7,9.16,11.96c0.94,2.57,5.91,2.6,9.19,2.65
				c2.96,0.04,2.54-3.48,4.02-5.12c1.11-1.23,1.79-3.06,3.12-3.82c3.2-1.84,4.93-4.87,5.54-7.96c1.56-7.96,6.34-14.13,10.26-20.83
				C927.05,971.56,929.89,968.15,935.73,970.96z M1.87,1336.01c3.66-0.5,5.99,0.89,7.94,3.56c2.05,2.8,4.05,2.24,5.66-0.75
				c0.93-1.71,1.25-4.09,3.35-4.78c6.27-2.06,12.48-5.8,18.52,1.26c2.05,2.39,6.12,3.83,9.41,0.91c5.81-5.16,10.22-4.76,14.53,1.63
				c0.81,1.2,1.2,2.96,3.1,2.81c5.24-0.41,10.2,1.14,15.19,2.22c5.35,1.16,10.72,2.69,12.45,9.13c0.58,2.15,2.38,3.53,4.15,4.84
				c1.19,0.88,2.53,1.79,3.25,3.01c3.18,5.43,10.88,6.55,14.94,1.85c2.53-2.93,5-2.96,8.36-1.47c5.81,2.57,4.95,8.8,9.16,13.79
				c-6.05,0.11-11.74-1.48-13.87,5.09c-0.49,1.5-2.99,2.35-1.45,4.56c1.37,1.97,3.39,1.36,4.98,0.77c4.7-1.76,8.56,0.11,12.85,1.78
				c7.06,2.75,11.58,9.61,19.61,10.96c3.65,0.61,6.43,4.38,5.28,8.92c-0.83,3.27-5.58,4.58-10.38,3.23
				c-0.64-0.18-1.31-0.32-1.89-0.63c-1.78-0.96-3.38-0.8-5.18,0.17c-4.29,2.31-8.37,1.33-12.2-1.26c-2.62-1.77-5.08-3.79-7.8-5.37
				c-2.51-1.46-4.92-1.41-7.07,1.18c-6.99,8.45-11.8,9.27-20.72,3.8c-1.31,0.69-2.7,1.29-3.95,2.1c-2.05,1.33-5.75,1.75-4.19,5.52
				c1.35,3.26,4.26,3.23,7.23,2.33c3.31-1,6.49-1.06,9.6,0.86c0.89,0.55,2.43,0.84,3.32,0.45c6.02-2.66,12.19-0.26,18.27-0.62
				c4.03-0.24,9.04,1.48,9.88,5.38c1.56,7.26,7.04,9.48,12.43,11.55c4.04,1.55,5.83,2.72,5.01,7.56c-0.95,5.62,3.8,8.35,9.04,5.84
				c1.49-0.71,2.95-1.78,4.52-1.98c3.15-0.41,6.74,0.36,7,3.84c0.29,3.86-3.98,6.07-5.59,9.5c-1.09,2.32-3.51-0.57-5.59-0.22
				c-0.26,1.08-0.55,2.18-0.79,3.29c-1.65,7.52-9.3,12.37-16.2,8.97c-4.74-2.33-9.28-3.48-14.35-3.83c-1.59-0.11-3.15-1-4.68-1.63
				c-2.29-0.94-4.53-2.05-7.28-1.94c-1.28,1.65-1.67,3.87-3.37,5.34c-1.6,1.39-2.44,3.45-1.26,5.38c1.07,1.74,3.29,1.8,5,1.25
				c5.48-1.76,8.41,0.71,10.51,5.49c2.78,6.31,8.48,9.76,14.69,9.47c6.47-0.3,10.04,1.72,11.8,7.21c23.56,0,46.64,0,69.8,0
				c0.76-3.44,1.21-6.95-1.36-9.11c-2.61-2.2-3.85,2.39-6.54,2.43c-1.44-1.12-2.96-2.38-4.57-3.54c-1.56-1.12-3.11-1.51-5.09-0.57
				c-5.12,2.44-12.97-0.12-16.08-5.06c-1.32-2.11-2.5-4.31-3.93-6.35c-1.39-1.98-3.51-3.88-5.84-3.04
				c-5.17,1.86-8.58-3.17-13.95-1.54c1.7-3.21,3.31-2.93,4.95-2.37c3.09,1.06,5.99,2.35,9.54,1.66c2.24-0.43,4.54,1.13,6.02,3.02
				c1.74,2.21,3.24,4.62,4.74,7.02c1.7,2.72,3.87,3.6,7.22,4.16c5.79,0.97,12.78-3.33,16.91,3.7c8.25-4.67,8.95-4.05,11.43,9.9
				c10.67,0,21.45-0.18,32.22,0.1c3.83,0.1,5.35-1.53,6.27-4.83c1.2-4.26-1.08-6.61-4.29-8.49c-2.27-1.33-3.7-2.3-2.74-5.77
				c0.98-3.55,0.33-8.35-3.22-10.29c-4.35-2.37-4.71-5.75-4.39-9.59c0.47-5.65-2.07-9.26-6.64-12.21c-7.76-5-7.66-5.14-2.83-12.88
				c0.14-0.22,0.02-0.6,0.02-1.09c-1.9-1.84-3.55-4.16-7.06-2.85c-2.12,0.79-3.49-1.05-4.31-2.62c-2.04-3.93-3.97-7.87-6.23-11.69
				c-3.02-5.1-7.57-5.79-11.62-7.22c-2.95-1.04-5.08-1.81-6.16-4.69c-1.78-4.75-4.95-7.82-9.75-9.84
				c-5.56-2.34-10.26-5.84-12.47-12.12c-1.08-3.08-2.7-6.3-6.71-7.37c-7.27-1.94-8.59-6.45-2.9-11.18c3.39-2.82,3.51-6.2,3.78-9.66
				c0.26-3.38-1.21-5.55-5.31-5.48c-4.42,0.08-9.05,0.27-13.07-2.11c-3.24-1.92-5.94-1.81-9.19,0.33
				c-4.79,3.15-11.08,2.07-13.68-1.74c-4.94-7.24-5.35-7.33-13.57-5.26c-3.78,0.95-7.91,1.13-10.78-1.72
				c-2.75-2.73-5.61-3.04-8.97-2.58c-2.17,0.29-5.06,0.9-6.2-0.68c-3.02-4.2-7.74-5.07-11.88-6.65c-6.56-2.52-13.44-4.78-20.61-4.35
				c-5.21,0.31-12.51-2.48-15.18-6.8c-3.24-5.24-7.15-6.76-12.75-4.38c-3.65,1.55-7.31,3.47-10.97,4.54
				c-5.28,1.53-10.84,3.9-16.71,1.2c-4.29-1.97-8.44,0.93-8.54,5.62C1.81,1327.94,1.87,1331.59,1.87,1336.01z M620.72,875.37
				c1.52-8.16,2.52-15.84,6.78-22.42c1.67-2.59,2.93-5.3,3.3-8.26c1.16-9.25,5.24-17.77,6.56-26.98c0.44-3.07,1.05-6.16,2.59-9.05
				c3.2-6,6.04-12.2,9.13-18.26c0.61-1.19,0.55-2.08,0.08-3.25c-2.87-7.15-4.52-14.66-5.46-22.23c-1.29-10.42-6.87-19.16-10.28-28.74
				c-0.64-1.78-2.03-2.16-3.6-1.83c-2.01,0.43-2.01,1.98-1.51,3.54c1.16,3.61,3.35,7.25,1.99,11.03c-1.1,3.07-1.03,5.82-0.19,8.74
				c2.92,10.12,3.17,20.71,5.32,30.96c0.43,2.03,0.28,3.65-1.59,4.74c-2.73,1.61-3.05,4.11-2.78,6.92c0.36,3.81,0.81,7.63,0.88,11.45
				c0.04,2.27,1.09,4.84-1.63,7.74c-2.94-11.1-6-21.4-8.26-31.87c-0.76-3.54-1.38-8.5-4.14-8.81c-6.7-0.76-7.14-5.56-8.7-9.96
				c-1.06-2.98-1.74-6.19-6.36-6.65c0.04,9.54,5.74,18.31,4.03,27.7c-2.42,0.89-3.7-1.56-5.82-0.84c-1.8,4.89-0.42,9.57,0.68,14.22
				c3.81,16.14,5.55,32.41,3.44,48.96c-0.1,0.81-0.09,2.1-0.55,2.33c-6.55,3.29-5.02,9.56-5.28,14.9
				c-0.42,8.64-0.12,17.32-0.31,25.98c-0.15,6.83-3.3,13.09-3.62,19.96c-2.05-1.89-1.4-4.11-1.43-6.14
				c-0.18-10.98,0.44-22.21-3.41-32.6c-2.56-6.91-3.93-13.68-3.79-20.89c0.23-11.37,1.42-22.88-6.07-32.34
				c-2.9,5.39,0.22,12.12-0.93,18.88c-4.97-2.08-5.39-5.89-5.88-9.65c-0.71-5.44,0.88-11.26-1.3-16.31
				c-1.88-4.37-2.61-8.58-1.98-13.07c1.13-8.02-3.16-14.84-4.61-22.29c-0.18-0.9-0.64-1.47-1.61-1.6c-4.49-0.6-5.56-4.35-6.85-7.73
				c-1.55-4.03-2.85-8.16-4.18-12.27c-0.82-2.54-2.46-4.08-4.97-4.93c-2.71,3.79-3.68,7.4-2.64,11.91c0.99,4.3,1.44,9.02-3.96,11.5
				c-1.61,0.74-2.08,2.54-2.48,4.16c-0.33,1.36-0.8,2.79-2.21,3.08c-6.16,1.26-6.38,6.09-6.52,10.81
				c-0.26,8.99-0.82,17.97-1.01,26.95c-0.2,9.62-1.37,19.27,0.24,28.91c3.12,18.69,5.08,37.57,10.12,55.94
				c1,3.66,1.51,7.26,4.92,9.29c4.57,2.72,6.09,6.98,6.25,11.79c0.2,5.98,0.27,12.01-0.22,17.97c-1.02,12.49-0.28,24.94-0.26,37.42
				c0.01,7.29,3.65,13.37,4.61,20.22c0.92,6.62,1.01,13.28,0.85,19.95c-0.04,1.43,0.14,2.69,1.67,3.25c1.02,0.37,2.03,0.25,2.65-0.73
				c0.78-1.22,0.79-2.54,0.02-3.77c-0.71-1.13-1.62-2.13-2.29-3.27c-2.65-4.55-1.65-13.9,2.23-15.45c3.63-1.46,4.02-4.46,5.47-7.13
				c1.64-3.02,1.33-7.28,4.29-9.18c3.09-1.97,4.13-7.88,9.69-5.53c0.11,0.04,0.42-0.46,0.67-0.68c6.02-5.43,8.51-4.98,11.61,2.29
				c4.5-0.61,8.88,0.47,13.47,2c0.69-3.5,0.39-6.61,0.02-9.71c-1.73-14.38-3.35-28.75-2.76-43.3c0.38-9.31,0.47-18.69-1.16-27.91
				c-1.49-8.43,0.18-15.49,7.27-20.83c0.75-0.57,1.36-1.51,1.69-2.4C612.32,877.4,615.17,874.62,620.72,875.37z M658.73,790.2
				c1.48,0.45,1.7,1.76,2.17,2.75c3.56,7.55,8.38,14.1,15.78,18.18c6.84,3.78,9.53,9.79,10.53,17.04c0.32,2.3,0.59,4.82,2.41,6.43
				c5.37,4.76,8.83,11.1,13.57,16.29c7.54,8.24,5.78,17.1,3.32,26.33c-1.75-4.3-3.23-8.65-4.42-13.08c-1.39-5.16-7.82-4.57-9.93-9.09
				c-1.19-2.55-2.47-5.01-3.35-7.72c-2.85-8.8-9.57-15.45-13.33-23.89c-2.09-4.71-7.39-7.96-11.16-11.95
				c-1.71-1.81-3.29-1.26-3.79,0.61c-1,3.77-2.77,7.33-1.74,11.63c1.33,5.55,3.97,10.59,5.43,16.05c0.44,1.64,1.79,3.26,0.11,5.59
				c-3.69-5.05-4.75-11.41-10.19-17.09c0.27,8.66-8.19,15.01-1.45,23.14c0.45,0.54,0.23,1.61,0.4,2.41c1.18,5.46-3.39,8.97-3.9,13.47
				c-3.03,0.6-3.86-2.11-6.5-2.44c-0.14,6.06-0.3,12.26-6.12,16.08c-0.21,0.14-0.28,0.61-0.3,0.93c-0.37,5.58-3.29,4.5-6.8,2.79
				c-7.3-3.56-15.84,2.28-15.73,10.92c3.87,0.17,7.18,1.71,9.43,5.06c1.02-0.68,1.73-1.11,2.39-1.6c4.53-3.35,7.46-2.21,8.43,3.43
				c0.25,1.45,0.28,3.04-0.04,4.47c-1.55,6.88-1.8,13.85-1.93,20.85c-0.03,1.5,0.37,3.3-1.69,4.24c-1.58-1.55-1.71-3.46-2.13-5.16
				c-0.91-3.71-1.51-7.5-2.42-11.21c-1.08-4.37-4.34-5.64-8.31-3.49c-2.99,1.62-5.76,0.74-8.41-0.96c-1.38-0.88-1.75-2.85-3.21-3.25
				c0.05,24.58,0.37,49.19,2.31,73.75c0.55,7.04,6.03,10.43,12.38,6.83c1.39,2,2.71,4.09,4.23,6.02c1.41,1.8,3.44,3.31,5.6,1.96
				c2.94-1.84,5.12-1.43,7.49,0.8c0.92,0.87,2.28,1.36,2.38,1.32c6.41-4.17,6.69-9.07,6.11-14.99c-0.48-4.87,1.54-11.38,6.19-12.43
				c6.01-1.35,10.22-3.45,14.7-7.75c3.38-3.24,5.58-8.13,11.33-9.76c2.6-0.74,5.02-4.46,9.12-3.62c-0.52-2.88-0.91-5.34-1.4-7.78
				c-1.05-5.24-9.15-11.34-14.35-10.82c-0.64,0.06-1.44,0.2-1.83,0.62c-2.57,2.73-7.4,2.14-9.06,6.34c-0.27,0.68-2.22,1.26-3.13,1
				c-2.56-0.74-4.72,0.71-7.15,1.06c-0.4-0.77-0.82-1.31-0.97-1.92c-1.62-6.73-1.97-13.89-6.79-19.54c-0.76-0.9-0.46-2.26,0.03-3.36
				c1.69-3.87,3.76-7.56,7.6-9.61c2.25-1.2,3.54-2.37,3.56-5.17c0.03-2.68,2.04-3.7,4.74-4.1c3.14-0.46,3.6,1.51,4.33,3.59
				c0.7,2,1.5,3.96,2.49,6.53c1.87-1.96,3.68-2.88,5.85-3.35c4.71-1,7.71-6.04,6.84-10.73c-0.94-5.07-1.92-10.14-2.5-15.25
				c-0.23-2.03-1.35-5.24,1.95-5.77c2.78-0.45,4.96,1.15,5.86,4.25c1.29,4.47,2.82,8.87,4.4,13.25c2.7,7.48,4.31,15.4,8.89,22.14
				c2.03,2.99,4.11,5.28,8.14,2.91c2.31-1.36,4.26-0.74,4.83,2.15c1.37,6.99,2.83,13.97,3.12,21.56c-4.9,0.06-8.91,3.37-13.81,2.27
				c0.05,7.96,0.36,7.88,7.69,9.53c2.72,0.61,5.07-1.12,6.88-0.53c4.76,1.55,8.01-0.42,11.42-2.99c1.19-0.89,2.74-1.81,4.14-0.76
				c4,2.99,7.85,2.81,11.85,0.05c0.6-0.41,1.69-0.53,2.39-0.29c3.37,1.17,6.34-0.37,10.13-0.9c-5.19-9.88-9.73-19.37-13.2-29.46
				c-3.72-10.81-8.39-21.33-12.22-32.16c-3.6-10.17-7.36-20.41-13.67-29.3c-4.25-5.98-8.26-12.07-11.87-18.46
				c-6.09-10.79-13.34-20.56-23.41-28.2c-6.29-4.77-12.82-9.66-15.53-17.78c-0.63-1.87-2.18-3.3-4.96-3.7
				c-1.1,3.13-4.43,4.62-6.42,7.48C655.7,793.87,656.63,790.63,658.73,790.2z M676.96,450.4c4.3,5.48,8.27,10.53,12.23,15.58
				c0.96,1.22,2.1,1.47,3.53,0.85c6.85-2.98,14.46-3.72,21.09-7.49c4.86-2.76,10.06-4.7,15.35-0.46c0.67,0.54,1.9,0.54,2.86,0.51
				c8.47-0.31,17.06,0.6,25.4-1.18c13.88-2.95,25.56,2.19,37.36,8.49c26.49,14.14,52.64,29.02,83.34,32.95
				c15.44,1.98,30.84,4.82,45.8,9.7c14.2,4.64,28.5,8.95,43.65,9.85c16.93,1.01,33.57,4.37,50.12,7.93c3.38,0.73,5.52,0.5,6.57-2.79
				c1.13-3.53,3.42-4.22,6.58-3.24c2.06,0.64,4.08,1.42,6.1,2.17c2.89,1.06,5.77,1.78,8.54-0.32c3.84-2.9,7.74-2.67,11.55-0.06
				c3.95,2.72,6.63,1.77,9.61-1.94c8.76-10.91,21.03-17.6,32.17-25.59c3.48-2.5,6.54-4.87,7.43-9.23c-0.52-0.27-0.8-0.45-1.11-0.57
				c-7.06-2.76-13.98-1.73-20.97,0.53c-7.92,2.55-15.98,5.35-24.38,2.68c-9.95-3.17-19.63-2.64-29.31,0.89
				c-6.66,2.43-13.32,2.35-20.04,0.11c-6.39-2.13-12.97-3.05-19.67-1.83c-5.41,0.98-10.55,0.19-15.68-1.57
				c-27.42-9.43-53.93-21.74-83.14-25.53c-8.71-1.13-17.32-4.26-26.12-3.03c-11.61,1.62-22.83,0.08-34.18-1.84
				c-14.04-2.38-28.05-5.48-42.49-2.84c-4.56,0.83-9.41,0.59-13.89-0.68c-11.56-3.27-23.34-3.11-35.16-3
				c-10.79,0.1-21.55,0.27-32.32-1.45C697.71,446.36,687.65,447.93,676.96,450.4z M865.39,1349.49c3.91-1.27,9.77-1.47,7.1-7.16
				c-3.73-7.95-7.65,0.89-11.72,0.18c0.3,7.42,2.6,12.84,9.82,15.06c1.39,0.43,2.72,1.3,3.87,2.22c7.6,6.13,16.41,9.83,25.92,10.73
				c10.25,0.98,18.48,4.73,24.77,12.84c0.13,0.17,0.61,0.08,1.13,0.13c-0.23-3.49-0.66-6.94-0.64-10.39
				c0.14-24.2-3.41-48.12-5.54-72.14c-1.09-12.31-0.63-14.32,10.18-20.29c0.87-0.48,1.75-0.97,2.67-1.34
				c2.64-1.07,4.05-2.81,4.85-5.74c3.49-12.65,7.35-25.19,11.03-37.78c1.76-6.03,5.1-11.62,5.6-19.21c-3.43,6.02-5,13-12.66,12.34
				c-7.37-0.64-7.8-7.56-9.88-12.79c-3.26-0.76-6.25,2.4-9.27,0.88c-3.94-1.99-7.28-2.37-10.65,1.05c-1.06,1.08-2.97,1.96-4.54,0.65
				c-1.36-1.13-0.6-2.79-0.35-4.23c1.16-6.71,2.41-13.41,3.52-20.14c1.41-8.52,1.37-8.52-6.27-12.4c-0.25-0.13-0.37-0.52-0.77-1.11
				c2.82-8.08,5.78-16.35,8.58-24.68c1.48-4.4,2.82-8.87,3.99-13.37c0.45-1.73,1.83-4.19-1.01-5.1c-2.75-0.89-3.14,1.68-3.89,3.47
				c-1.34,3.22-2.55,6.5-4.05,9.65c-2.91,6.09-4.77,12.34-4.5,19.22c0.15,3.99-0.56,7.98-2.84,11.46c-0.67,1.03-0.97,2.1-0.9,3.29
				c0.34,5.75-1.79,11.15-2.35,16.73c-0.93,9.23-7.47,12.19-14.82,14.12c-3.17,0.83-5.28,2.29-7.14,4.82
				c-4.38,5.98-10.91,9.27-16.9,14.18c-1.63-3.95-1.36-7.13-0.67-10.13c2.19-9.55,4.47-19.08,7.05-28.53
				c1.45-5.31,0.38-10.32-0.47-15.52c-1.42,4.59-1.76,9.57-7.13,11.66c-0.74,0.29-1.15,1.59-1.56,2.49
				c-4.65,10.06-4.8,21.23-8.79,31.85c5.08,0.7,7.22,3.42,7.7,7.46c1.76,14.8,3.94,29.58,5.05,44.43
				c1.11,14.77,1.01,29.61-0.78,44.38c-0.15,1.25-0.61,2.65,0.8,3.86c1.33-0.2,2.79-0.48,4.26-0.64c9.77-1.07,19.09,6.08,20.44,15.65
				c0.74,5.26-2.4,10.22-7.61,12.05C871.28,1355.31,867.94,1354.15,865.39,1349.49z M628.84,800.81c-0.42-3.02-0.11-6.57,3.21-9.13
				c1.25-0.96,1.07-2.4,0.78-3.7c-2.23-10.07-2.37-20.5-5.32-30.45c-0.72-2.44-1.12-5.02-0.21-7.38c1.19-3.07,0.71-5.93-0.42-8.73
				c-1.21-2.99-2.05-5.86-0.03-8.39c-3.46-5.06-7.44-8.53-13.16-9.93c-3.31-0.81-5.48-3.88-6.07-6.84
				c-1.45-7.28-7.37-8.54-12.84-11.03c-3.73,4.24-6.72,8.78-9.86,13.26c-2.98,4.24-3.4,8.48-2.69,13.21
				c0.91,6.08,1.98,12.14,2.63,18.25c0.62,5.77,2.43,12.2-0.07,17.23c-2.25,4.54-3.03,8.92-3.64,13.6c-0.28,2.14-0.1,4.53-3.06,4.94
				c-2.85,0.39-4.25-1.41-5.2-3.71c-1.37-3.29-1.54-6.82-1.7-10.29c-0.12-2.44-2.04-3.92-3.3-3.56c-6.1,1.71-6.02-3.2-6.96-6.38
				c-1.84-6.26-2.26-12.85-2.09-19.29c0.24-9.23-4.78-17.5-3.9-26.84c1.23-13.11,0.28-26.26-0.53-39.38
				c-0.18-2.89-0.57-5.46-3.04-7.55c0.86-0.77,1.4-1.66,2.06-1.76c5.5-0.87,6.79-5.19,7-9.5c0.29-5.76,2.14-10.57,6.61-14.86
				c-4.28-1.11-6.26-4.63-9.63-6.97c-0.07,1.38-0.04,2.22-0.17,3.03c-0.35,2.26,0.83,5.32-3.08,5.72c-3.41,0.35-5.3-1.38-6.36-4.36
				c-1.25-3.49-1.7-7.11-1.73-10.79c-0.04-5.13-0.01-10.27-0.01-15.8c-2.76,0.42-4.39-0.57-5.55-2.39c-0.7-1.1-1.57-3.2-2.13-3.13
				c-5.87,0.77-7.54-4.25-10.48-7.77c-1.81,3.69-2.78,7.28-5.24,10.1c-5.4,6.19-7.29,13.48-7.43,21.62
				c-0.24,13.48-1.51,26.91-2.32,40.37c-0.7,11.73-0.15,23.53-0.15,35.3c0.1-2.53,0.08-5.19,0.32-7.84
				c0.84-9.22,1.84-18.43,11.27-23.54c0.78-0.42,1.47-0.97,1.44-1.87c-0.34-8.99,2.84-17.98,1.01-26.84
				c-1.99-9.59,1.73-18.43,2.46-27.66c-1.32,20.43,2.54,40.95-1.56,61.74c-5.3-3.07-7.2,0.61-8.97,4.36
				c-0.63,1.34-1.16,2.8-1.37,4.26c-2.27,15.55-4.96,31.06-6.46,46.69c-0.72,7.47-0.78,15.48,2.48,22.58
				c1.76,3.84,3.13,7.64,3.89,11.74c2.81,15.23,3.8,30.7,6.1,46c0.8,5.31,0.94,10.77,3.81,15.82c0.9-12.63,0.83-25.22,0.94-37.81
				c0.05-5.2,1.1-10.1,6.92-12.01c1.59-0.52,1.84-1.69,2.31-2.93c0.71-1.9,1.69-3.68,3.55-4.67c2.89-1.54,2.96-4.21,2.76-6.88
				c-0.16-2.15-1.1-4.29-0.98-6.39c0.23-4.07,1.48-8.9,5.31-9.88c3.98-1.02,6.49,3.05,7.82,6.85c1.31,3.77,2.41,7.61,3.82,11.34
				c1.15,3.03,1.89,6.61,6.36,6.55c1.16-0.02,1.79,1.02,1.98,1.98c1.72,8.55,6.74,16.39,5.28,25.66c-0.42,2.71-0.85,5.83,0.83,8.29
				c2.14,3.14,2.2,6.52,2.13,10.03c-0.06,3.02-0.01,6.04-0.01,9.06c1.32-5.42,0.16-11.64,6.67-15.28c2.14,4.14,1.24,8.57,3.51,11.89
				c3.12,4.54,3.25,9.54,3.28,14.77c0.06,8.3-1.37,16.68,0.64,24.89c1.41,5.77,4.21,11.16,4.76,17.21c0.28,3.08,0.01,6.33,1.88,10.33
				c0-10.28-0.1-19.44,0.04-28.59c0.08-5.33,0.22-10.77,5.13-14.45c1.14-0.85,0.64-2.13,0.8-3.24c2.3-15.74-0.08-31.11-3.66-46.39
				c-0.84-3.56-1.5-7.14-1.31-10.86c0.21-4.36,1.32-7.54,6.67-5.83c0.6-10.26-5.95-19.24-3.84-29.59c9.05,5.96,9.21,5.91,11.12,12.74
				c1.19,4.27,2.6,7.64,7.54,5.86C626.43,783.19,622.15,794.22,628.84,800.81z M893.15,1498.59c-2.94-6.22-6.04-11.12-10.78-14.67
				c-5.28-3.96-9.59-8.69-13.07-14.26c-2.14-3.42-5.15-6.08-8.05-8.84c-1.09-1.04-2.14-1.79-3.92-1.64
				c-5.05,0.44-9.66-1.37-13.49-4.37c-11.06-8.66-23.44-15.14-35.46-22.25c-6.58-3.89-13.77-7.37-19.07-12.65
				c-7.97-7.95-16.88-12.45-27.79-14.62c-6.39-1.27-12.14-5.6-18.51-7.19c-15.7-3.91-30.8-9.53-45.97-15
				c-14.49-5.23-27.23-13.2-37.05-25.29c-4.15-5.11-3.88-6.3,0.5-10.98c1.42-1.52,3.43-2.57,4.37-5.19
				c-18.48-5.63-36.85-11-56.66-14.23c0,7.53,0.09,14.31-0.03,21.08c-0.08,4.59,0.33,8.62,5.94,9.77c0.98,0.2,2.06,1.26,2.56,2.21
				c1.96,3.65,5.43,4.6,8.89,4.3c5.99-0.52,10.43,0.99,13.01,6.77c0.5,1.12,1.72,2.09,2.83,2.74c6.34,3.72,13.22,5.41,20.57,5.44
				c5.42,0.02,8.22,2.54,9.04,7.93c1.02,6.7,2.13,7.58,8.74,6.51c4.81-0.78,9.21,0.2,12.15,4.05c2.89,3.79,5.01,8.11,10.13,9.51
				c0.77,0.21,1.45,1.39,1.82,2.27c1.9,4.5,5.66,7.09,9.48,9.68c7.54,5.12,12.86,11.74,14.18,21.27c1.7,0.22,3.67,1.02,5.46,1.09
				c5.13,0.22,8.58,4.9,13.92,4.53c7.64-0.54,11.96,2.06,14.82,9.17c1.29,3.22,3.08,5.16,6.17,6.52c3.29,1.45,5.85,3.87,6.35,7.75
				c1.57,12.2,12.24,20.12,24.37,18.21c4.25-0.67,8.24-0.5,12.4,1.88c3.5,2,8.06,3.82,12.25,1.81c10.62-5.12,18.85-3.84,27.33,4.94
				c0.99,1.02,1.85,1.77,3.33,1.77C866.71,1498.58,879.51,1498.59,893.15,1498.59z M1.87,1170.17c0,6.33,0.06,12.32-0.03,18.3
				c-0.04,2.47,0.32,4.17,2.97,5.5c3.86,1.94,4.25,6.53,2.92,10.69c-1.41,4.41-4.98,8.09-5.54,12.27
				c-1.02,7.66-0.29,15.55-0.29,22.31c5.04,3.99,10.43,4.37,15.38,6.06c4.29,1.47,10.39,2.25,7.72,9.63
				c-0.09,0.25,0.08,0.68,0.26,0.94c3.38,5.02,4.89,11.38,10.62,14.71c3.12,1.81,5.43,4.28,6.54,7.84c1.2,3.82,3.86,5.69,7.93,5.33
				c3.49-0.31,6.1,1.25,8.17,3.89c0.96,1.22,1.65,2.58,0.63,4.11c-1.02,1.54-2.4,3.73-4.16,2.99c-6.53-2.75-13.17,1.07-19.67-0.78
				c-1.61-0.46-3.42-0.47-4.94,1.29c-5.4,6.29-7.02,6.29-13.82,0.91c-1.35-1.07-2.76-2.86-4.78-1.56c-2.31,1.49-4.76,2.27-7.46,2.52
				c-1.21,0.12-2.34,0.66-2.35,2.12c-0.01,5.86-0.48,11.74,0.33,18.02c3.47-1.91,6.58-3.14,9.89-1.29c1.75,0.98,3.48,0.32,5.26,0.34
				c7.11,0.11,13.02-3.26,19.22-5.9c6.6-2.81,13.07-0.83,16.69,4.97c2.48,3.98,8.02,6.52,12.6,5.89c1.29-0.18,2.71-0.28,3.92,0.08
				c10,2.97,20.97,3.33,29.26,11.02c1.24,1.15,3.27,1.27,5.22,0.88c3.83-0.76,7.58-0.84,10.37,2.76c0.93,1.19,2.38,2.21,3.89,1.16
				c1.22-0.85,1.95-2.24,1.6-3.93c-0.71-3.38-0.78-6.89-2.99-9.89c-1.56-2.12-3.12-4.5-2.61-7.35c1.26-7.01-1.44-12.31-7.26-15.84
				c-4.02-2.43-8.33-5.19-12.81-5.89c-8.76-1.38-10.14-2.17-10.23-10.88c-0.06-5.87-4.58-9.12-9.98-6.97
				c-3.51,1.39-7.15,2.77-10.52,0.65c-3.99-2.52-8.01-3.4-12.67-3.25c-4.45,0.14-5.9-2.3-4.67-6.53c2.14-7.36,8.37-10.38,15.58-7.54
				c2.89,1.14,5.64,2.35,7.27-1.9c0.81-2.11,2.74-2.79,4.99-1.99c1.7,0.6,3.39,1.48,5.48-0.13c-0.51-4.38-2.38-8.11-7.16-8.6
				c-8.09-0.83-11.22-8.44-17.37-11.82c-0.13-0.07-0.19-0.27-0.28-0.41c-3.49-5.04-6.86-10.16-10.5-15.08
				c-2.5-3.39-2.28-5.52,1.65-5.95c7.57-0.82,15.26-2.49,22.74,0.88c3.41,1.54,6.32,1.18,7.97-3.37c-4.1-1.1-8.59,0.59-11.69-2.55
				c-2.48-2.51-5.38-3.05-8.67-2.81c-1.65,0.12-3.37,0.2-4.8-0.88c-2.75-2.07-5.62-2.13-8.95-1.38c-4.44,1.01-6.91-2.21-5.43-6.52
				c1.73-5.04,8.84-9.23,14.75-9.92c6.9-0.81,13.34-3.99,20.34-4.73c1.45-0.15,2.51-1.53,3.24-2.92c0.76-1.44,1.8-2.14,3.62-1.95
				c8.97,0.96,9.71,0.48,13.48-7.85c-5.11-1.15-16.23-1.08-21.23,0.4c-3.45,1.02-6.44,0.04-8.7-2.22c-2.34-2.33-3.22-5.49-1.37-8.71
				c1.14-1.98,1-3.73-0.26-5.57c-0.85-1.24-1.75-2.65-1.28-4.17c1.02-3.34-1.12-5.02-3.52-5.48c-4.61-0.87-8.33-4.09-13.15-4.39
				c-2.76-0.17-5.57-1.56-4.74-5.04c0.73-3.03,3.34-3.15,6.09-2.48c3.41,0.84,6.83,1.48,10.02-0.99c1.24-0.96,3.04-0.83,4.36-0.31
				c4.6,1.81,8.3,0.44,11.95-3.03c-1.59,0-2.91,0.1-4.21-0.02c-5.73-0.51-7.26-4.71-8.76-9.41c-1.35-4.23,1.26-5.22,4.01-6.61
				c2.15-1.09,2.3-3.04,1.8-5.17c-0.83-3.52,0.96-6.36,3.81-7.3c3.82-1.28,7.1-4.66,11.61-3.19c-0.42-4.56-5.61-6.67-4.83-11.41
				c0.53-3.26-1.36-5.04-4.02-6.95c-0.97,3.87-0.16,8.37-4.76,9.51c-4.32,1.06-6.46,5.73-11.07,6.22c-0.91,0.1-1.49,1.37-1.5,2.41
				c-0.06,5.78-3.91,8.11-8.7,9.37c-4.16,1.1-4.17,3.6-2.68,6.76c0.56,1.2,1.28,2.33,1.95,3.47c2.31,3.99,1.74,6.83-2.64,8.21
				c-5.74,1.8-9.04,5.8-10.48,10.99c-1.3,4.69-3.54,5.62-7.54,3.95c-2.52-1.06-4.44-0.03-4.35,2.24c0.24,6.14,0.81,12.31,1.88,18.36
				c0.41,2.3,3.79,4.01,1.06,6.94c-2.87,3.08-5.47,6.53-10.46,3.81C8.2,1168.58,5.28,1169.11,1.87,1170.17z M249.65,1252.38
				c-5.52,5.97-7.78,14.25-14.15,19.61c-1.85,1.56-3.14,3.75-5.66,4.53c-2.64,0.81-4.45,2.86-4.76,5.34
				c-0.92,7.4-7.79,7.98-12.56,12.44c0.17-3.52,2.08-4.69,3.63-5.41c3.93-1.81,6.28-4.49,7.28-8.74c0.53-2.25,2.15-4.14,4.38-5.2
				c6.09-2.89,9.82-8.22,12.99-13.68c2.68-4.62,5.7-8.96,8.67-13.35c2.97-4.39,3.35-12.16,0.29-17.12c-2.05,0.04-3.09,1.72-4.4,2.9
				c-2.32,2.11-5.14,2.96-8.1,3.54c-1.91,0.38-3.41-0.68-2.76-2.33c2.45-6.16,1.38-11.71-1.96-17.12c-0.24-0.38-0.14-0.98-0.19-1.47
				c-0.61-7.13-3.7-13.44-6.23-20.77c-4,15.16-6.72,21.2-10.52,23.8c-0.6-0.75-0.56-1.71-0.13-2.35c5.65-8.38,6.77-18.11,8.47-27.68
				c0.26-1.48,1.14-3.1,0.32-4.36c-2.67-4.11-3.33-8.89-5.02-13.32c-0.88-2.3-2.19-4.47-2.78-6.9c-0.35-1.42-1.03-2.99-2.49-2.5
				c-5.45,1.85-5.12-1.98-5.65-5.27c-0.57-3.48-1.45-6.85-6.46-6.7c-0.31,3.99-2.74,6.35-5.6,8.52c-7.8,5.9-7.83,6.13-5.27,15.68
				c1.8,6.72-0.91,11.02-6.68,10.29c0.11,1.34,0.67,2.81,0.25,3.92c-2.61,7.02-1.81,13.71-0.33,21c0.91,4.5,0.54,9.79,0.76,14.72
				c0.29,6.48,0.31,12.93-3.87,18.59c-1.39,1.88-1.24,4.29-0.78,6.73c0.48,2.5,1.64,5.86-0.95,7.44c-3.75,2.29-3.75,5.42-3.75,9
				c0,4.19-1.95,5.63-6.03,4.88c-5.4-0.99-7.33-5.27-9.14-9.63c-1.77-4.24-2.7-8.83-5.14-13.02c-4.22,2.65-4.64,6.34-2.4,9.59
				c2.79,4.05,4.91,8.7,8.14,12.21c6.88,7.45,8.6,18.25,16.7,24.79c0.71,0.57,0.66,2.15,0.85,3.28c0.8,4.82,0.46,9.9,4.73,13.69
				c1.6,1.43,1.99,4.1-1.25,4.96c-5.52,1.48-11.32-2.17-11.74-7.77c-0.2-2.73-1.09-4.01-3.47-5.22c-5.32-2.71-9.58-6.52-10.57-12.91
				c-0.2-1.3-0.58-2.61-1.97-3.29c-2.42,0.42-3.47,2.01-4.21,4.25c-0.81,2.46-2.52,4.32-5.21,2.2c-1.98-1.55-3.45-0.54-4.94,0.36
				c-5.59,3.4-7.58,11.32-3.1,12.85c6.54,2.24,12.04,7.83,19.85,6.41c4.43-0.81,8.28,0.59,10.44,4.97c0.82,1.67,2.26,2.64,3.92,2.74
				c7.94,0.44,15.77,3.33,23.9-0.02c3.51-1.45,7.82-1.52,11.72-1.33c2.87,0.14,5.88,1.32,8.26,3.18c1.9,1.49,3.5,3.6,1.14,5.88
				c-2.94,2.82-4.95,6.76-9.4,7.87c-1.37,0.34-2.76,1.2-2.77,3.17c0.95,0.29,1.97,0.42,2.79,0.89c6.35,3.63,12.31,3.09,18.13-1.28
				c1.7-1.28,3.76-2.12,5.71-3.03c3.44-1.58,6.35-1.27,8.63,2.23c1.44,2.22,3.11,4.38,5.05,6.16c4.32,3.96,8.09,3.31,10.93-1.75
				c1.89-3.38,4.28-6.16,7.14-8.83c3.29-3.05,7.39-5.77,8.2-10.83c1.19-7.33-5.52-8.9-9.31-13.46c-2.68,5.04-5.21,10.07-10.36,11.42
				c-4.97,1.3-9.05,3.58-12.86,6.74c-0.1,0.08-0.53-0.25-1.18-0.58c2.9-2.91,4.91-6.4,9.48-7.3c10.32-2.05,17.46-15.53,13.53-25.32
				c-1.12-2.79-1.5-5.74-0.52-8.28c1.63-4.24,2.59-8.49,3.15-12.96c0.29-2.29,1.86-4.55-1.09-5.99c-1.92-0.93-8.36,2.11-8.6,4.13
				c-0.98,8.57-10.47,10.45-13.1,17.71c-1.12-3.79,0.6-6.61,3.1-8.28c7.02-4.68,7.85-11.87,8.56-19.17
				C251.66,1259.12,253.03,1255.52,249.65,1252.38z M757.51,489.3c4.03-0.24,6.33-4.53,9.99-2.25c6.5,4.05,12.84,8.02,15.03,16.39
				c1.75,6.71,4.65,13.13,7.27,19.59c3.01,7.44,8.06,9.5,15.61,6.78c3.27-1.17,6.49-3.65,9.9-3.09c9.1,1.52,18.62,1.12,27.09,5.92
				c11.47,6.49,25.02,6.47,37.01,11.44c0.73,0.3,1.65,0.2,2.48,0.2c10.03-0.02,19.81,2.15,29.66,3.59
				c13.82,2.01,27.01,7.07,40.87,8.97c2.07,0.28,4.18,1.29,6.3-0.01c6.25-3.83,12.97-3.56,19.78-2.16c1.66,0.34,3.32,0.87,4.83-0.13
				c5.13-3.38,11.05-5.33,15.9-9.24c6.34-5.12,10.17-13.31,19.81-14.91c-2.86-2.04-5.24-2.36-7.48-2.73
				c-10.98-1.82-21.97-3.59-33-5.13c-12.15-1.69-24.52-1.31-36.47-4.98c-20.02-6.14-40.22-11.5-60.97-14.81
				c-10.94-1.75-22.21-2.55-32.58-6.96c-20.2-8.59-40.12-17.71-59.11-28.85c-7.67-4.5-16.28-7.78-25.62-6.38
				c-10.21,1.52-20.42,2.36-31.62,1.26c1.85,2.77,3.27,4.9,4.73,7.1c1.76-0.41,2-2.66,4.45-2.33
				C747.06,473.5,755.52,478.94,757.51,489.3z M75.36,1070.93c-3.54,1.33-3.26,3.51-1.66,6.17c1.01,1.69,3.57,2.81,3.13,4.89
				c-1,4.77,2.61,7.6,4.07,11.32c0.77,1.96,3.75,2.31,3.66,5.01c-2.21,1.54-4.52,0.63-6.76,0.48c-4.37-0.29-12.09,3.32-11.53,5.81
				c1.35,6.03-0.94,9.57-5.92,11.04c0.17,7.72,3.98,10.31,10.68,9.99c2.69-0.13,5.6,0.73,6.98,4.57c-12.2,3.73-24.46,5.37-37.44,4.66
				c1.28,2.64,3.23,2.19,4.61,2.88c7.17,3.56,18.56,2,18.2,14.82c-0.01,0.28,0.31,0.58,0.48,0.86c1.22,2.02,1.34,4.09,0.19,6.16
				c-1.25,2.26-1.44,4.56,0.37,6.47c1.86,1.96,4.42,2.4,6.94,1.57c4.81-1.59,9.86-0.24,14.77-1.55c2.29-0.61,5.17,0.67,7.7,1.38
				c1.78,0.5,2.78,1.63,1.75,3.77c-3.93,8.2-8.39,10.62-17.53,9.22c-2.62,4.74-7.14,5.53-12.18,6.11
				c-6.21,0.71-11.91,3.89-18.27,4.42c-1.85,0.16-3.68,1.5-5.34,2.57c-2.43,1.56-3.66,4.28-2.91,6.78c0.75,2.5,3.36,1.21,5.23,0.8
				c1.51-0.33,2.93-0.13,4.42,0.22c8.8,2.07,17.18,5.74,26.26,6.74c1.84,0.2,3.61,1.03,4.24,3.02c0.68,2.1-0.77,3.4-2.07,4.72
				c-3.07,3.11-6.6,2.9-10.11,1.14c-2.79-1.4-5.4-2.22-8.56-1.13c-1.47,0.51-3.37,0.39-4.9-0.04c-2.91-0.82-5.37-0.07-8.17,1.09
				c1.72,2.3,4.06,4.1,4.5,6.28c1.08,5.38,5.6,9.05,8.7,11.59c4.66,3.82,8.19,10.33,15.97,10.41c4,0.04,8.84,8.16,7.47,11.08
				c-2.21,4.7-6.14,2.49-9.61,1.95c-0.27-0.04-0.6,0.23-0.9,0.36c-2.98,5.89-4.59,6.33-11.47,3.14c-4.4-2.04-9.19,0.13-10.93,4.65
				c-1.45,3.76-0.5,5.48,3.58,5.06c3.8-0.4,7.17,0.33,10.31,2.69c2.77,2.09,5.87,1.64,9.02,0.47c8.71-3.23,14.26,0.54,14.41,9.76
				c0.05,3.43,0.86,7.4,4.46,7.2c5.46-0.3,9.15,3.23,13.74,4.63c5.06,1.54,10.94,5.75,11.09,10.27c0.32,9.1,3.66,16.85,6.8,25.15
				c3.66-0.21,5.65-1.4,3.72-5.58c-1.76-3.82-1.34-8.66-5.54-11.35c-0.94-0.6-0.67-1.71-0.12-2.69c2.6-4.54,0.96-8.81-1.32-12.73
				c-6.82-11.73-6.88-11.66,0.81-22.21c0.93-1.28,1.93-2.16,3.38-2.75c2.64-1.07,3.58-3.3,3.65-5.99c0.08-3.13-0.83-5.81-3.1-8.12
				c-2.84-2.88-3.28-5.61-1.09-9.73c2.3-4.33,5.76-8.87,4.01-14.59c-0.31-1.02-0.22-2.55,0.35-3.4c2.8-4.2,1.35-9.35,3.62-13.36
				c-4.49-4.1-8.62-7.68-14.78-8.16c-2.86-0.22-3.84-2.55-1.24-4.96c1.33-1.23,2.46-3.01,4.45-2.98c4.22,0.05,6.19-3.07,8.86-5.43
				c-2.66-3.79-7.61-2.38-10.22-5.29c-3.73-4.16-8.2-6.65-13.79-7.21c-2.19-0.22-3.61-1.55-4.09-3.78c-0.49-2.24-0.54-4.25,1.74-5.71
				c3.05-1.94,6-4.03,8.8-5.92c-3.62-3.15-7.44-5.46-12.36-6.47c-4.81-0.99-4.38-3.99-2.46-8.62c2.29-5.55,10.34-8.25,7.27-16.59
				c0.02,0.01-0.67-0.41-1.37-0.83c-5.85-3.46-6.07-5.67-1.03-10.15c2.31-2.05,1.86-5.69-1-7.43c-1.93-1.17-4.41-1.64-5.08-4.14
				c1.05-3.63,6.15-1.92,7.17-5.92c-4.31-1.43-5.02-5.41-6.82-8.91c-2.94-5.73-8.63-9.67-11.24-15.79c-0.32-0.75-1.53-1.26-2.44-1.58
				c-2.9-1.02-4-3.16-4.44-6.04C76.81,1075.16,77.11,1072.86,75.36,1070.93z M837.88,974.03c-0.14-3.1-1.74-3.72-2.54-4.91
				c-4.25-6.31-2.49-12.29,4.37-14.67c3.23-1.12,7.9-1.29,8.3-5.27c0.39-3.82-4.06-5.18-6.87-7.08c-4.13-2.78-8.84-4.7-12.5-8.18
				c-5.29-5.03-5.22-9.56,0.27-14.34c2-1.74,4.18-3.3,6.42-4.73c3.08-1.98,4.54-4.05,3.56-8.19c-1.55-6.53,1.2-10.39,7.77-12.28
				c3.02-0.86,6.18-1.24,9.27-1.83c-6.89,0.82-10.5-4.98-15.83-7.21c-4,3.44-5.8,3.26-9.49-0.97c-0.89-1.02-1.07-2.96-3.16-2.66
				c-5.2,0.73-7.71-2.89-10.36-6.37c-0.92-1.21-0.87-3.2-2.79-3.71c-1.37,5.67-2.92,6.21-7.06,2.4c-1.56-1.44-2.86-3.15-4.36-4.82
				c-4.36,6.54-8.2,6.77-13.07,1.25c-0.66-0.75-1.29-1.54-1.79-2.4c-2.84-4.86-8.31-8.05-12.47-9.18c-5.48-1.48-9.02-4.28-12.74-7.7
				c-0.63-0.58-1.29-1.05-2.22-0.74c-3.55,4.75-4.96,4.85-9.13,0.54c-0.92-0.95-1.56-2.23-2.58-3.03c-3.99-3.09-8.19-6.73-13.01-7.32
				c-4.52-0.55-7.36-2.33-9.99-5.48c-1.14-1.36-1.74-3.67-3.75-3.75c-7.95-0.28-14.34-4.61-22.33-8.69
				c6.52,8.98,9.96,18.27,16.19,25.84c5.93,7.21,10.3,15.53,12.35,24.3c4.33,18.46,15.62,34.16,19.63,52.62
				c0.89,4.08,3.36,5.12,7.38,4.17c4.35-1.02,9.42,0.28,11.47,3.44c3.13,4.84,8.43,5.75,12.42,7.72c4.96,2.46,7.48,7.17,12.4,9.43
				c5.86,2.69,11.92,5.2,17.34,8.83c0.11,0.08,0.34-0.04,0.49,0c5.29,1.55,9.63,5.35,15.18,6.31c0.38,0.07,0.81,0.65,0.98,1.09
				C827.87,972.14,834.07,969.93,837.88,974.03z M785.42,656.04c3.25,1.98,4.83,3.41,2.57,5.62c-4.73,4.61-9.76,8.68-16.79,8.72
				c-4.33,0.03-7.33,0.75-5.53,6.24c0.55,1.68,0.28,3.63,0.31,5.46c0.12,5.52,1.22,6.72,6.71,6.49c2.29-0.1,4.58-0.71,6.84-1.22
				c2.3-0.52,4.56-0.83,6.88-0.09c4.27,1.36,8.58,2.57,12.83,4c3.56,1.2,6.86,0.9,10.02-1.12c2.95-1.87,5.88-3.76,8.88-5.55
				c8.05-4.79,16.35-8.81,25.94-9.54c4.71-0.36,8.97-2.14,12.78-5.09c9.81-7.61,21.94-10.8,32.76-16.53
				c5.64-2.99,12.66-4.39,18.93-6.94c3.07-1.25,4.81-2.93,5.78-6.13c3.15-10.38,5.11-20.97,6.43-31.71
				c0.53-4.35,1.23-9.32,5.17-11.46c7.89-4.29,9.6-13.19,16.85-19.57c-10.43,0.46-19.99-3.44-27.8,4.92
				c-2.12,2.27-4.98,3.87-7.62,5.6c-2.51,1.65-5.33,3.39-8.31,3.02c-6.55-0.79-11.54,2.27-16.58,5.58c-4.31,2.83-8.6,5.7-13.01,8.35
				c-3.84,2.3-7.79,5.24-12.42,4.59c-7.38-1.04-12.52,2.65-17.24,7.18c-4.28,4.1-9.31,6.62-14.93,7.41
				c-7.22,1.01-13.3,4.12-18.85,8.48c-3.25,2.55-6.15,5.55-10.2,7.02c5.05,0.83,9.06-3.65,14.15-2.71
				C805.08,647.08,797.47,653.38,785.42,656.04z M1080.44,598.38c4.06-0.86,7.54,0.35,10.81-1.48c4.93-2.77,7.88-1.8,10.49,3.23
				c2.02,3.9,3.06,7.99,3.12,12.44c0.08,5.73-1.36,11.19-2.14,16.78c-2.7,19.25-3.96,38.64-4.91,58.05
				c-0.12,2.53-1.66,5.02-0.69,7.53c1.62,0.43,2.62-0.32,3.53-0.97c3.56-2.51,6.54-6.32,11.86-4.15c0.8,0.33,2.9-0.75,3.37-1.69
				c3.34-6.61,10.92-10.14,12.55-18.1c0.86-4.22,2.97-8.48,8.2-8.88c3.6-0.27,5.68-2.03,7.49-4.9c3.87-6.14,8.59-11.11,16.99-10.12
				c0-36.94,0-73.51,0-110.43c-5.07,1.78-6.38-3.54-9.63-5.32c-4.62,6.73-11.82,11.62-12.32,20.55c-0.05,0.96-0.53,1.93-0.95,2.82
				c-2.01,4.26-5.96,5.66-9.26,2.35c-4-4.02-5.88-1.99-8.58,1.2c-5.36,6.33-15.18,4.4-17.72-3.36c-0.99-3.01-1.62-7.03-5.23-7.03
				c-3.37,0-4.58,3.84-5.82,6.75c-0.07,0.15-0.13,0.31-0.18,0.47C1087.41,568.49,1083.71,582.94,1080.44,598.38z M879,1052.15
				c-4.61,2.54-8.92,3.42-13.23,4.39c-6.94,1.56-14.67,1.02-19.88,7.45c-0.79,0.98-2.71,1.14-4.15,1.49
				c-4.74,1.14-9.6,0.92-14.41,0.82c-10.24-0.22-20.22,1.33-30.03,4.09c-2.33,0.66-4.97,1.04-6.34,3.54
				c-2.29,4.19-6.07,5.79-10.59,6.5c-5.47,0.87-10.96,0.82-16.43,0.63c-12.38-0.43-24.58,0.51-36.53,3.89
				c-1.87,0.53-3.98,0.57-5.28,2.69c1.4,3.89,4.74,5.14,8.32,6.22c3.62,1.09,7.62,1.85,9.09,6.09c1.16,3.34,3.54,5.07,6.79,5.96
				c3.58,0.99,6.78,2.92,7.1,6.82c0.45,5.43,3.79,8.15,7.91,10.67c1.81,1.11,3.84,2.43,4.84,4.19c2.65,4.6,6.82,7.19,11.24,9.5
				c6.19,3.24,9.74,8.4,11.47,14.96c0.93,3.54,1.81,7.1,3.49,10.37c1.57,3.05,2.28,6.14,1.74,9.61c-0.39,2.47-0.28,5.04,2.44,7.11
				c0.19-2.01,0.45-3.49,0.46-4.98c0.04-4.51,1.51-6.73,6.76-6.86c4.6-0.12,9.68-0.7,13.22-4.78c1.8-2.08,3.66-1.32,5.69,0.11
				c3.51,2.48,3.15,6.52,4.46,10.04c1.52-12.01,5.67-23.22,8.44-34.78c1.04-4.35,1.08-9.09,2.82-13.11
				c5.19-11.97,8.7-24.4,11.46-37.08c2.61-12,10.58-19.91,20.64-25.57C874.64,1059.8,877.07,1057.11,879,1052.15z M468.59,430.28
				c-6.76-2.13-12.91-2.83-19.23-2.89c-6.82-0.06-13.55,0.94-20.42,0.71c-10.87-0.37-21.4-3.09-32.18-3.64
				c-20.31-1.03-40.39-3.82-60.31-7.73c-14.58-2.86-29.45-5.98-44.01-2.91c-14.94,3.15-30.35,4.06-44.92,9.03
				c-1.29,0.44-2.66,1.26-4.75-0.75c9.28-3.15,18.03-5.88,27.07-8.02c-10.35,0.39-20.51-3.29-30.77-0.71
				c-6.1,1.54-12.14,1.01-18.3,0.56c-7.9-0.57-15.65,0.59-22.7,4.7c12.18,1.05,22.8,8.05,35.08,8.21c-5.97,1.63-11.87,1.21-17.4-0.81
				c-15.62-5.7-31.36-4.26-47.2-1.62c-6.86,1.14-13.55,3.75-20.65,3.04c-14.9-1.5-29.94-0.72-44.8-2.8
				c-6.16-0.87-12.17-0.18-18.14,1.99c-22.75,8.26-46.3,10.03-70.25,8.14c-4.09-0.32-8.17-0.65-12.56-1.01c0,3.75,0,7,0,10.23
				c2,1.37,4.02,0.48,5.96,0.51c5.77,0.08,11.69-1.1,17.12,1.91c5.53,3.07,11.25,3.61,17.34,1.83c15.44-4.5,31.24-5.37,47.06-4.62
				c-1.33-1.78-4.86-0.7-5.78-3.62c1.24-1.96,3.2-1.52,4.94-1.74c14.6-1.86,29.24,0.68,43.86-0.34c4.07-0.28,8.96-0.8,11.16,4.46
				c0.4,0.97,1.38,0.82,2.16,0.86c5.3,0.24,10.65,1.61,15.91,0.3c13.17-3.27,26.46-0.51,39.58-0.2c14.9,0.35,29.75,1.17,43.91-3.38
				c7.71-2.48,14.56-2.12,21.79-0.09c8.89,2.51,17.56,2.34,26.53-0.05c7.32-1.96,14.9-4.42,22.22-4.84c14.72-0.85,29.57,0.48,44.37,1
				c7.48,0.26,15.02,1.22,22.27-1.31c3.55-1.24,7.07-1.49,10.76-1.47c7.66,0.03,15.25,2.87,22.83,1.48
				c7.49-1.37,14.8-1.32,22.29-0.63c5.28,0.49,10.65,0.37,15.96,0.16C459.1,434.02,463.77,433.2,468.59,430.28z M438.27,739.76
				c-2.47-3.77-4.85-7.1-8.91-8.27c-9.45-2.72-17.85-7.05-24.18-14.87c-1.12-1.38-2.67-2.85-4.29-3.3
				c-7.66-2.12-13.99-7.83-22.14-8.41c-7.6-0.55-15.13,0.01-22.8-2.02c-3.85-1.02-9.14,0.4-13.76,0.59
				c-3.56,0.14-6.26,1.25-8.91,3.62c-2.67,2.39-5.19,6.39-8.88,5.96c-7.55-0.9-10.93,4.03-15.04,8.57c-2.5,2.76-1.7,4.82,0.66,6.67
				c1.39,1.09,3.17,1.87,4.89,2.33c7.65,2.07,14.03,6.74,21.03,10.12c1.63,0.79,3.04,2.1,3.86,3.8c4.24,8.87,10.96,15.16,19.78,19.4
				c6.11,2.94,10.01,8.88,12.44,14.25c1.84,4.05-5.41,7.53-8,11.69c-0.09,0.14-0.25,0.22-0.35,0.35c-8.67,10.77-9.4,16.71-3.57,28.26
				c1.27-1.62,1.09-3.53,1.38-5.29c1.87-11.07,8.48-16.08,19.95-10.63c1.3,0.62,3.22,1.98,4.13,0.99c2.48-2.68,4.38-0.76,6.59,0.57
				c1.24-2.08,2.22-3.99,3.45-5.7c1.77-2.45,4.32-4.36,7.24-2.93c7.04,3.42,16.36-0.29,22.06,7.17c0.3,0.39,1.21,0.3,1.82,0.43
				c1.75-5.13,1.63-7.15-1.55-9.44c-4.99-3.6-8.15-8.81-11.42-13.65c-3.06-4.54-5.81-8.76-11.03-10.97
				c-1.99-0.84-4.68-3.51-3.34-4.77c4.44-4.2-0.81-14.41,9.36-14.83c0.79-0.03,1.4-2.11,2.26-3.1c2.36-2.75,4.74-6.39,8.72-5.68
				C426.03,741.79,431.9,738.12,438.27,739.76z M21.34,1007.32c1.81-2.27,6.31-1.68,6.41-6.73c0.22-10.77,1.5-21.49,4.15-32.06
				c1.56-6.22,2.33-13.17-0.08-19.16c-2.02-5.02-1.4-9.39-1.21-14.37c0.28-7.38,4.59-16-3.95-21.97c-0.46-0.32-0.84-1.19-0.77-1.74
				c0.98-8.17,1.58-16.51,4.88-24.11c1.56-3.6,2.75-6.96,2.87-10.89c0.35-11.57,4.26-21.43,14.38-28.05
				c0.07-4.73-3.69-4.48-6.51-4.76c-2.1-0.21-3.73-0.39-5.17-2.18c-5.99-7.46-10.73-15.79-16.63-23.38
				c-5.03-6.47-11.64-9.08-17.45-13.94c0,87.63,0,174.75,0,261.86c0.66,0.48,1.32,0.95,1.98,1.43c-1.02-6.03,3.57-6.96,6.61-10.06
				c-2.91-1.36-2.14-3.07-0.66-4.65c-1.29-1.39-3.88-0.78-4.26-3.62c4.23-2.2,8.88-3.99,12.46-7.22c-2.12-2.92-5.97-2.92-8.67-5.94
				c4.82-2.1,7.04-6.21,9.93-10.06c-2.25-0.95-4.98-0.41-6.19-2.96c1.39-2.07,3.83-2.03,5.6-3.16c1.96-1.25,4.15-2.16,6-3.54
				c3.57-2.67,3.31-4.21-0.78-5.9C23.06,1009.64,21.74,1009.27,21.34,1007.32z M161.6,1099.94c4.89,2.62,5.04,8.68,1.77,13.02
				c6.68,4.09-0.4,5.82-1.68,8.83c0.66,2.2,3.16,4.06,0.78,7.32c3.98-1.11,7.77,1.26,9.92,0.08c5.27-2.9,8.82-1.05,12.38,2.28
				c4.06-3.21,8.93-4.2,13.36-6.02c2.8-1.15,4.85-3.58,4.24-5.27c-2.05-5.66,4.98-9.14,2.7-15.15c-0.92-2.43,0.65-7.65,5.24-9.16
				c0.81-0.27,1.15-1.16,0.82-2.09c-1.07-2.97,0.99-5.06,2.18-7.32c1.08-2.04,1.95-3.61,1.02-6.32c-1.8-5.25,1.17-9.25,4.99-13.04
				c-2.77,13.65-4.2,27.3-12.58,38c0.69,2.14,3.06,1,3.09,2.84c-0.84,3.45-8.01,5.17-2.48,10.22c-0.41,0.49-0.84,0.98-1.24,1.49
				c-1.54,1.96-4.59,3.76-2.17,6.61c2.26,2.67,5.07,1.57,7.58-0.25c2.16-1.57,4.66-1.22,7.17-1.38c6.26-0.39,10.91,2.53,15.41,6.31
				c2.28,1.91,5.54,4.46,7.96,3.34c4-1.86,6.59,0.5,9.68,1.39c2.33,0.67,3.96,3.01,6.56,3.12c1.12-3.57-0.08-5.86-3.06-8.04
				c-4.49-3.27-6.25-8.36-8.21-13.93c6.5,1.85,6.5,1.85,9.38,1.08c-3.81-4.21-10.1-5.75-10.83-12.91
				c-0.59-5.79-7.15-7.85-10.72-11.88c-1.9-2.15-4.31-4.02-4.01-7.26c2.3-1.13,3.68,1.21,5.37,0.7c1.37-2.86,0.33-4.67-1.97-6.51
				c-2.27-1.8-4.34-4.11-3.43-7.9c2.38,0,4.64,0,6.82,0c0.47-2.78-1.21-3.75-2.89-4.95c-4.79-3.43-8.78-7.56-11.43-13.88
				c5.16,0.89,9.1,1.57,14.55,2.51c-6.09-4.46-12.06-6.89-14.21-12.89c2.24-3.39,5.34-2.38,9.52-1.43
				c-5.19-4.12-7.49-8.72-10.48-14.02c-1.44,5.85-8.94,7.03-8.21,13.44c1.74,0.75,3.17-0.71,4.44,0.92
				c-0.53,3.54-4.25,4.87-5.88,7.37c1.99,2.25,4.69,1.17,7.44,1.89c-2.76,4.78-8.7,4.54-12.18,9.23c4.72-1.18,7.71,1.55,11.65,2.76
				c-8.94-1.43-10.83,7.44-17.47,9.93c2.27,1.43,4.24,0.14,5.5,1.69c-0.46,3.01-1.99,5.54-3.65,7.79c2.21,1.58,4.52-0.1,7.59,1.57
				c-6.95,3.14-9.02,10.7-16.46,12.46c1.79,1.87,4.13,0.67,4.87,2.82c-1.98,3.33-5.36,5.22-8.21,7.48c0.64,1.89,3.33,1.48,3.24,3.71
				c-1.84,1.72-3.72-0.31-5.31-0.03c0.19,4.49,3.1,7.63,6.62,11.31c-5.33,0.64-8.92-0.16-12.53-2.59c2.1-2.24,3.78,0.36,5.35-1.02
				c-0.83-3.33-1.83-6.52-4.91-8.7c-0.87-0.61-2.02-2.45-1.78-2.76c3.69-4.82-1.47-6.36-3.25-9.08c-1.03-1.58-3.15-2.51-2.88-5.18
				c2.24,0,4.45,0,6.63,0c0.16-1.55-2.12-2.21-0.86-3.74c0.95-1.19,2.47,0.84,3.54-0.73c-5.77-3.32-10.49-7.59-13.83-13.6
				c3.8,0.91,7.44,3.13,10.14-1.28c-3.91-9.14-10.93-15.93-18.77-22.21c2.2,0.93,4.4,1.87,6.95,2.95c-0.21-1.25-0.35-2.12-0.5-3.04
				c1.67-0.85,3.52,0.86,5.13-0.56c-3.27-2.67-7.22-4.49-8.67-9.34c2.83,0.18,4.53-0.86,4.84-3.49c-2.22-2.72-5.4-4.91-5.21-9.17
				c2.38-0.62,4.18,1.97,6.5,0.56c1.13-7.2-7.75-8.44-8.72-14.71c-2.34,5.36,0,12.13-6.92,15.92c4.05,0.45,2.98,5.19,6.68,4.5
				c-0.49,7.85-5.11,12.32-11.24,15.32c1.52,5.25,4.91,2.49,8.12,2.03c-2.35,6.13-7.09,9.07-11.75,11.93
				c0.35,1.67,2.46,0.01,2.57,1.81c-0.4,0.69-0.84,1.45-1.27,2.18c1.55,0.84,2.81-1.54,4.44,0.18c-2.31,2.65-7.17,4.78-4.79,8.87
				c1.47,2.52,0.24,2.93-0.79,4.03c-3.02,3.22-6.12,6.37-9.01,9.35c0.31,2.08,4.23,2.53,2.52,4.85c-1.86,2.54,1.92,3.33,0.65,5.74
				c-4.78,0.81-8.65-2.8-13.08-4.03c-0.38,0.46-0.75,0.92-1.13,1.39c4.19,4.31,8.38,8.62,12.55,12.91c-4.95,0.28-7.97-4.59-13.4-6.65
				c2.28,2.55,3.86,4.32,5.01,5.6c0.35,2.46-1.89,1.53-2.18,2.71c3.06,8.13,12.22,10.48,17.07,16.86c-0.25,0.37-0.51,0.73-0.76,1.1
				c-1.87-0.36-3.73-0.71-5.42-1.03c-3.3,3.47-1.62,6.56,0.17,9.81c1.54-0.96,3.1-2.68,3.99-2.38c4.35,1.45,6.15-2,8.46-4.12
				c1.37-1.26,2.73-2.29,4.38-3.1c1.77-0.87,3.46-2.81,2.4-4.33c-3.1-4.45-0.06-6.6,3.23-9.32c-3.12-1.85-4.05-3.82-1.42-6.84
				c1.48-1.7,1.41-4.98-0.5-6.31c-4.78-3.34-1.83-5.85,0.38-8.49c1.35-1.62,3.46-2.56,4.5-4.85c-3.43-2.47-7.25-4.9-3.99-9.86
				c-6.55-5.02-6.1-12.98,1.6-21.6c-0.48,4.56-2.24,8.06-3.34,11.48c-1.59,4.93,0.74,7.12,4.36,9.41c-5.21,5.89,1.19,7.29,3.71,10.13
				C168.24,1093.87,163.01,1095.21,161.6,1099.94z M613.76,687.17c1.22-2.98,2.05-5.73,3.9-7.93c1.72-2.04,1.91-4.18,1.75-6.86
				c-0.76-12.78-0.24-25.59-1.8-38.38c-0.86-7.05,2.41-9.27,8.97-6.96c2.7,0.95,4.3,0.14,6.13-1.54c6.25-5.75,8.76-13.23,8.93-21.31
				c0.07-3.25,3.19-5.71,2.1-8.4c-1.7-4.21,0.71-6.94,2.2-10.13c2.46-5.27,5.72-10.08,9.08-15.15c-2.17-1.51-2.9-3.86-3.99-6.1
				c-5.06,4.01-9.75,7.72-13.52,12.46c-2.36,2.97-5.5,4.77-9.21,5.59c-7.48,1.65-14.68,4.2-21.92,6.68c-8,2.73-16.07,5.81-24.69,5.64
				c-11.18-0.22-19.2,6.3-27.96,12.05c2.61,4.73,5.21,9.39,4.32,14.74c-0.97,5.85,1.9,10.26,4.68,14.77
				c1.76,2.86,3.97,4.06,6.85,1.24c2.38-2.34,4.05-1.38,5.45,1.16c1.03,1.87,1.44,4.34,3.45,5.34c6.4,3.21,8.75,8.66,9.12,15.38
				c0.1,1.86,0.45,3.61,2.22,4.76c3.45,2.25,4.23,5.82,4.42,9.57c0.35,6.82,0.49,13.64,0.91,20.45c0.43,7.02,2.33,9.08,9.66,10.94
				c0.29-4.33,0.59-8.64,0.87-12.95C606.01,686.81,608.26,685.31,613.76,687.17z M652.87,560.8c1.56,2.6,1.99,6.01,5.93,6.73
				c4.8,0.88,6.68,4.41,7.06,9.04c0.23,2.89-0.36,5.61-1.02,8.38c-1.57,6.62-1.85,12.75,2.82,18.82c4.93,6.4,6.71,14.28,7.6,22.4
				c1.06,9.55-0.06,19.29,2.93,28.68c1.51,4.73,3.42,9.32,4.1,14.35c1.78,13.26,0.41,26.61,1.55,39.91
				c0.63,7.4,5.41,10.93,10.05,16.89c-1.56-24.7-1.65-47.85-3.64-70.9c-0.33-3.87-1.08-7.46-3.03-10.92
				c-1.99-3.53-2.7-7.51-2.18-11.6c0.34-2.7,0.3-5.4-0.78-7.86c-3.3-7.58-4.62-15.4-4.7-23.7c-0.07-6.96,3.23-13.43,1.2-21.33
				c5.14,2.55,8.71,5.51,13.13,6.25c4.56,0.76,7.36,3.63,8.37,7.6c1.84,7.21,7.19,12.68,9.05,19.87c0.7,2.71,2.73,5.07,6.07,5.36
				c0.81-2.19-0.44-3.33-1.14-4.56c-2.92-5.15-6.23-9.88-6.88-16.3c-0.4-3.91-3.15-8.63-6.32-10.98c-5.81-4.29-9.24-9.93-12.39-15.96
				c-6.06-11.62-14.21-21.53-24.4-29.65c-3.47-2.77-6.53-5.63-8.4-9.65c-3.19-6.9-8-12.53-13.17-18.04
				c-6.99-7.45-15-14.23-18.16-24.6c-0.78-2.54-2.88-4.02-5.56-4.48c-6.57-1.13-12.14-4.38-17.52-8.1
				c-13.24-9.15-28.27-14.71-42.81-21.22c-5.23-2.34-14.33,0.08-17.97,4.37c7.44,1.3,11.32,8.5,18.55,10.01
				c1.23,0.26,2.49,1.12,3.39,2.04c4.93,5.01,11.3,6.16,17.82,6.58c6.65,0.43,12.12,2.51,16.29,8.07c1.9,2.54,4.06,5.51,7.78,5.77
				c3.67,0.26,5.98,2.45,8.34,4.91c6.5,6.76,11.02,15.48,19.72,20.1c0.84,0.44,1.47,1.35,2.09,2.13
				c7.11,8.94,11.69,19.29,16.34,29.59c0.52,1.16,1.7,2.65-0.05,4.72c-9.25-8.46-12.64-21.68-23.96-28.53
				c11.67,10.8,11.11,25.26,13.29,39.91c-3.67-1.74-4.65-4.26-5.9-6.52c-2.33-4.22-2.82-9.6-6.24-12.96
				c-7.76-7.63-10.2-18.34-16.51-26.83c-3.68-4.95-6.78-10.23-11.87-13.91c-2.07-1.49-3.43-3.51-4.9-5.56
				c-4.18-5.82-8.22-11.99-17-10.89c-1.3,0.16-2.93-0.72-4.11-1.54c-3.33-2.3-7.12-2.74-10.96-3.24c1.43,1.23,2.94,2.21,4.5,3.13
				c15.34,9.13,30.24,18.69,41.16,33.42c4.28,5.78,7.53,12.33,8.79,18.72c1.14,5.76,3.03,10.98,4.98,16.33
				c1.57,4.32,3.57,8.33,2.22,13.37c-0.85,3.16,0.97,6.35,3.93,9.33C636.99,570.94,643.78,564.65,652.87,560.8z M687.04,807.76
				c0.15-7.92-0.39-14.1-1.66-20.27c-1.2-5.86-2.33-11.75-3.09-17.68c-0.49-3.83-0.58-7.87,4.09-11.46
				c4.03,10.08,3.76,22.28,16.08,26.41c1.88-1.97,1.91-6.07,5.85-7.14c4.19,3.4,3.18,9.09,5.79,14.01c1.9-2.35,4.43-2.47,6.67-3.34
				c-1.01-5.53-1.88-10.77-2.92-15.97c-1.46-7.27-0.4-15.56-8.16-20.34c-0.5-0.31-0.72-1.1-1.03-1.69
				c-3.64-6.97-7.79-13.82-13.33-19.21c-5.32-5.18-8.87-12.96-17.64-14.11c-1.75-0.23-3.26-2.25-3.49-3.97
				c-0.95-7.01-5.98-12.6-6.71-19.6c-0.62-5.95-2.89-11.14-5.98-16.17c-2.36-3.84-5.91-6.67-8.11-10.68
				c-1.47-2.67-6.09-1.53-7.07,1.49c-0.56,1.73-0.91,3.54-1.16,5.35c-0.94,6.67-0.88,6.54-7.27,5.35c-2.21-0.41-4.41-0.63-6.55-1.98
				c6.88,8.7,5.37,18.15,1.7,26.77c-2.06,4.85-2.81,9.27-2.57,14.2c0.15,3.16,0.39,6.31,0.63,9.46c0.08,1.11-0.3,2.68,1.1,3.03
				c4.32,1.09,4.64,4.78,5.55,8.09c0.67,2.44,1.68,4.56,4.37,5.85c-0.76-13.09-1.72-25.84-2.18-38.61c-0.29-7.89,0.99-8.96,8.85-9.88
				c0.1-0.6,0.2-1.25,0.33-1.88c1.36-6.38-0.87-12.55-1.07-18.83c-0.07-2.14,0.1-5.03,2.61-5.29c2.84-0.3,2.91,2.65,3.17,4.76
				c0.83,6.61,1.93,13.16,3.62,19.61c1.29,4.91,0.3,9.27-3.13,12.93c-1.14,1.22-1.67,2.24-1.24,3.92
				c4.74,18.63,2.59,37.76,4.01,56.63c0.06,0.8,0.6,1.95-0.99,2.66c-1.72-6.92-2.02-13.86-2.2-20.8c-0.35-13.14-0.3-26.31-3.87-39.13
				c-0.51-1.84-0.59-3.48,1.52-4.69c2.74-1.56,3.79-4.37,3.38-7.29c-1.11-7.96-2.54-15.88-3.85-23.82c-0.63,1.57-0.44,3.02-0.21,4.46
				c0.87,5.43,1.62,10.86,0.95,16.38c-0.21,1.76-0.46,3.35-2.67,3.54c-2.09,0.18-4.63-1.93-6.16,1.19c-1.08,2.2-0.95,4.57-0.82,6.87
				c1.33,24.22,2.34,48.47,8.58,72.1c0.65,2.45,0.63,5.13,2.11,7.38c7.03-3.98,10.81-3.71,13.77,1.93
				C671.09,796.75,679.54,800.49,687.04,807.76z M330.72,944.58c1.67-1.76,1.39-4.1,2.06-6.11c1.14-3.45,1.49-7.49,5.14-9.3
				c5.89-2.92,12.02-5.35,14.12-12.54c0.23-0.8,1.44-1.3,2.18-1.96c4.92-4.38,9.24-9.12,9.95-16.12c0.1-0.96,0.39-2.06,0.98-2.78
				c3.49-4.35,2.76-9,1.09-13.7c-1.39-3.92-1.23-7.64,0.03-11.67c1.46-4.69,3.51-10.17,0.48-14.45c-5.08-7.18-6.8-15.22-8.01-24.07
				c-2.03,2.91-2.14,5.37-1.68,8.16c0.62,3.76,0.95,7.6,0.92,11.4c-0.03,3.02-1.42,4.93-5.14,4.2c-4.64-0.9-8.42,0.8-11.05,4.84
				c-1.11,1.71-2.35,3.51-4.48,3.68c-3.59,0.29-5.67,2.7-8.4,4.51c-4.33,2.87-8.3,5.58-10.52,10.44c-0.34,0.75-1.56,1.25-2.47,1.58
				c-2.75,0.99-4.79,2.79-6.74,4.94c-3.82,4.2-7.59,4.77-12.44,1.87c-2.93-1.75-5.39-2.74-7.37,1.13c-0.78,1.53-2.23,1.96-3.85,1.42
				c-4.4-1.46-6.07,2.25-5.68,4.66c1.36,8.55,1.29,17.27,3.11,25.71c0.72,3.33,2.57,6.43,2.77,10.01c0.14,2.43,1.42,4.09,4.13,4
				c3.8-0.13,6.45,1.4,7.51,5.16c0.49,1.72,1.57,2.7,3.26,2.31c4.73-1.09,9.57,1.38,14.27-0.59c1.28-0.54,2.86-0.21,3.78,0.72
				C322.21,945.55,326.48,944.93,330.72,944.58z M844.41,955.88c-3.81,1.77-7.65,1.12-8.33,5c-0.7,3.99,1.09,7.1,4.37,9.4
				c0.68,0.48,1.36,0.98,1.91,1.6c0.65,0.72,1.63,1.56,1.66,2.37c0.17,5.59,4.25,7.72,8.46,9.35c8.49,3.29,14.71,9.64,21.25,15.46
				c4.35,3.87,6.62,9.22,6.17,15.23c-0.43,5.7,0.45,10.71,4.3,15.34c3.02,3.63,1.66,8.69-2.39,10.57
				c-9.95,4.64-20.26,8.01-31.14,10.1c-21.44,4.12-43.78,1.98-64.71,10.32c-7.99,3.18-17.29,3.03-26.11,3.58
				c-4.61,0.29-9.5,0.38-13.51,2.97c-4.1,2.66-8.5,3.69-13.2,3.95c-15.25,0.85-29.88,4.51-44.19,9.62c-1.86,0.67-3.76,1.45-5.38,2.56
				c-4.98,3.41-4.81,8.97,0.3,12.13c1.55,0.96,3.25,1.67,4.88,2.51c1.39,0.72,2.65,1.66,2.48,3.4c-0.45,4.52,2.69,6.71,5.86,8.45
				c6.76,3.7,11.36,7.74,9.01,16.81c-1.42,5.49,2.68,8.32,8.34,7.26c2.84-0.53,5.31-1.83,7.64-3.47c5.98-4.21,9.15-3.94,14.37,1.28
				c3.4,3.39,7.5,4.2,12.59,4.15c-1.08-4.15-1.58-7.83,2.54-9.63c3.23-1.41,6.56,0.27,11.1,0.87c-4.88-2.89-9.59-4.9-10.44-9.2
				c-1.19-6.02-5.63-7.71-9.43-11.2c-6.21-5.71-12.47-10.56-20.12-13.97c-2-0.89-3.37-3.36-2.96-5.94c0.47-2.89,3.16-3.28,5.31-3.8
				c9.53-2.3,19.02-4.68,29-4.39c8.47,0.25,16.98,0.24,25.44-0.67c3.04-0.33,6.45-0.72,7.95-3.47c2.76-5.09,7.51-6.14,12.33-7.46
				c10.55-2.87,21.31-3.37,32.17-3.19c4.8,0.08,10.43,0.65,13.78-2.97c3.59-3.87,7.71-4.68,12.25-5.5c4.25-0.77,8.49-1.62,12.72-2.53
				c6.92-1.49,11.97-5.11,14.79-11.95c2.97-7.18,5.9-14.45,12.41-19.52c1.33-1.03,3.11-2.55,3.07-4.95
				c-3.83-0.59-8.96-0.4-10.57-3.58c-2.65-5.25-8.14-8.17-10.06-13.53c-1.61-4.51-4.44-7.65-8-10.51c-4.8-3.86-10.59-5.45-16.16-7.6
				c-3.08-1.19-6.71-2.46-6.6-6.52c0.08-2.75-0.99-4.31-2.83-5.91C843.08,965.52,841.24,961.71,844.41,955.88z M445.74,1358.78
				c0.26-5.73,5.96-5.17,8.63-8.12c3.19-3.53,6.74-3.67,10.7-1.09c2.79,1.81,5.79,2.68,8.61-0.17c1.89-1.91,4.25-1.61,5.91-0.21
				c4.07,3.43,6.89,1.96,10.11-1.35c2.95-3.03,6.79-2.75,8.99,0.73c2.13,3.37,4.38,2.68,6.6,1.21c6.43-4.27,12.4-9.91,20.95-4.87
				c0.1,0.06,0.35-0.14,0.47-0.09c5.02,2.12,10.05,4.02,15.68,2.36c0.54-0.16,1.36,0.92,2.13,1.16c3.7,1.14,7.2,3.69,11.56,1.99
				c0-10.06,0.8-19.85,0.11-29.67c-0.18-2.59-0.88-4.25-3.72-5.02c-11.74-3.17-22.63-8.64-33.9-13.07
				c-12.01-4.72-24.09-9.26-36.1-13.87c-1.91,3.31-0.69,5.95,1.03,7.76c4.11,4.35,9.1,8.11,5.21,15.14c-0.11,0.19,0.24,0.6,0.31,0.92
				c0.91,3.77-0.43,7.27-1.66,10.66c-1.8,4.98-9.98,7.02-12.95,1.83c-0.82-1.43-1.98-1.72-3.64-1.94c-6.78-0.9-14.57,0.13-18.02-8.21
				c-0.35-0.85-1.4-1-2.19-0.8c-5.07,1.24-9.43,0.35-12.49-5.34c-5.41,18.15-11.25,34.8-10.49,52.76
				C434.19,1362.6,438.64,1353.91,445.74,1358.78z M795.17,762.32c3.41-0.2,4.12,3.53,6.17,4.22c5.04,1.69,10.01,3.21,14.97,5.31
				c4.71,1.99,7.77,4.84,9.27,8.72c1.37,3.54,3.17,4.13,5.86,3.18c4.79-1.7,7.25,0.37,9.76,4.21c4.23,6.48,11.27,7.74,18.34,8.6
				c5.38,0.65,10.49,3.05,16.06,2.58c0.28-0.02,0.65,0.22,0.87,0.45c2.94,3.02,6.89,3.92,10.73,4.81c5.78,1.34,11.81,3.85,17.41,3.17
				c8.74-1.07,16.42-0.36,23.79,4.51c1.82,1.2,4.2,2.84,6.5,1.58c7.23-3.96,15.1-3.41,22.88-3.91c4.45-0.29,9.05-0.03,13.27-1.86
				c4.14-1.79,4.68-4.42,1.65-7.74c-3.55-3.89-7.8-6.71-13.01-7.92c-2.06-0.48-3.57-1.01-3.32-3.88c0.21-2.44-2.1-4.6-4.2-3.64
				c-4.49,2.06-6.37-0.56-8.53-3.41c-0.81-1.07-1.84-1.95-3.29-2.06c-4.14-0.31-6.65-2.38-7.72-6.51c-0.74-2.86-3.01-4.35-5.72-2.82
				c-3.8,2.14-7.38,0.97-10.71-0.35c-2.99-1.18-5.38-1.49-7.72,0.85c-2.21,2.2-4.58,2.39-7.24,0.87c-1.31-0.75-2.83-0.79-4.29-1.09
				c-5.91-1.2-12.6,1.56-17.8-3.61c-1.03-1.02-3.04-0.64-4.23,0.34c-3.55,2.92-6.68,1.61-10-0.45c-3.07-1.91-6.52-3.38-9.55-0.17
				c-3.39,3.58-5.47,1.51-7.57-1.25c-4.78-6.31-7.47-6.45-15.22-5.04c-5.21,0.95-10.88-1.79-16.36,0.28
				c-0.31,0.12-0.9-0.37-1.29-0.67c-1.72-1.31-3.66-0.95-5.63-1.15C804.13,757.95,799.79,760.09,795.17,762.32z M382.87,1261.25
				c-2.58-6.04-8.48-5.55-12.34-8.31c-1.7-1.22-4-2.73-6.03-1.67c-4.28,2.24-8.51,2.3-13.15,1.7c-3.26-0.43-6.18,2.19-9.6,2.35
				c-4.38,0.2-9.17,5.69-9.01,10.01c0.06,1.57,0.4,3.47,2.09,3.54c6.77,0.31,14.02,4.8,20.07-2.12c1.7-1.94,4.16-1.56,5.17,0.22
				c2.61,4.59,6.02,3.61,9.74,2.21c1.28-0.48,2.62-0.67,3.91-0.18c2.82,1.08,5.46,0.43,7.44-1.47c3.56-3.42,6.32-2.34,9.27,0.76
				c2.25,2.36,4.82,3.37,7.84,0.39c-4.82-5.57-5.9-12.75-9.27-18.84c-0.88-1.59-1.79-4.06-3.67-3.58c-3.37,0.86-6.05-0.61-9.07-1.17
				c-3.31-0.62-6.87-0.32-10.27,0.03c-6.52,0.67-12.57,5.41-19.58,2.22c-0.61-0.28-1.62,0.45-2.38,0.33
				c-5.18-0.79-9.12,2.52-12.96,4.77c-3.27,1.92-8.52,3.96-9.21,6.67c-1.29,5.08-6.32,6.48-7.32,10.72c-2.52,0.31-4.39-1.95-6.8,0.49
				c-2.88,2.91-2.95,9.49-9.52,8c-2.38,4.58-9.06,3.03-11.06,8.51c1.19,1.17,2.3,3.05,1.97,5.22c3.12,1.74,6.33-0.61,9.07,1.13
				c2.81,1.78,5.79,2.33,9.05,1.55c1.26-0.3,2.18,0.57,3.02,1.42c4.48,4.52,6.58,4.92,11.8,1.37c2.97-2.02,5.02-1.5,6.52,1.35
				c1.77,3.37,4.19,3.16,7.13,1.98c2.14-0.86,4.41-1.91,6.62-0.19c4.6,3.59,9.17,2.25,13.38-0.45c2.23-1.43,3.97-1.27,5.96-0.15
				c2.54,1.43,4.6,3.44,5.56,6.19c0.9,2.58,2.96,3.54,5.03,3.06c4.96-1.14,9.95-2.49,14.61-4.49c2.39-1.03,3.99-2.51,6.5-0.29
				c1.65,1.46,3.59,0.32,4.86-1.07c2.39-2.62,5.01-4.25,8.74-3.65c2.08,0.34,3.4-1.02,4.4-2.68c2.12-3.53-0.27-16.42-3.46-19.03
				c-2.82-2.31-5.58-4.7-8.58-7.25c-6.07,5.23-9.53-0.97-14.1-4.11c-5.59,8.16-14.58,3.23-21.6,6.08c-1.52,0.62-2.65-0.98-3.44-2.33
				c-0.66-1.13-1.2-2.44-2.85-2.39c-6.84,5.09-15.49,6.18-23.37,3.08c-1.75-0.69-2.58-1.91-3.11-3.59
				c-1.64-5.2,3.14-14.04,8.52-14.33c6.17-0.33,11.95-4.99,18.44-1.72c0.4,0.2,1.25-0.31,1.8-0.63c3.99-2.34,7.89-1.88,11.87,0.11
				c2.91,1.46,5.64,3.22,9.08,3.72C383.47,1255.16,384.41,1257.46,382.87,1261.25z M990.15,555.78c3.68-1.02,6.8,0.25,9.99,1.6
				c2.65,1.12,5.67,1.22,8.32,0.5c3.03-0.82,5.04,0.12,7.26,1.85c2.32,1.82,4.7,3.88,8.05,3.23c4.07-0.79,8.14-1.59,12.2-2.45
				c3.02-0.64,6.07-0.97,8.53,1.24c2.34,2.1,4.52,2.22,7.21,0.78c3.72-2,7.03-1.38,9.67,2.11c0.7,0.92,1.55,1.74,2.38,2.56
				c4.53,4.44,5.85,4.28,9.77-0.54c6.86-8.42,11.69-18.17,17.84-27.05c3.26-4.72,6.8-9.09,12.68-10.67c2.03-0.55,2.71-2.21,3.54-3.87
				c6.53-13.01,11.48-26.8,19.54-39.08c3.04-4.64,6.07-8.3,12.18-7.55c2.78,0.34,4.76-0.89,6.28-3.18
				c3.79-5.7,8.43-10.72,13.11-15.68c2.37-2.51,3.34-5.11,2.04-9.32c-3.27,5.16-7.35,8.21-13.1,8.49c-0.86,0.04-1.87,0.84-2.48,1.57
				c-4.63,5.54-11.38,8.6-16.19,13.91c-2.1,2.32-4.75,3.68-7.86,3.83c-5.2,0.25-8.23,2.83-10.53,7.56c-2.12,4.36-3.65,9.6-8.48,12.01
				c-10.01,4.99-18.31,12.43-27.38,18.76c-2,1.4-3.68,3.37-5.24,5.29c-5.71,7.07-6.03,7.21-14,3.25c-2.11-1.05-4.38-2.58-6.49-0.76
				c-4.98,4.28-10.02,3.2-15.31,0.9c-1.61-0.7-3.35-1.4-5.63-1.03c-0.11,5.57-4.07,7.46-8.61,9.34c-3.19,1.32-7.36,1.59-9.01,5.57
				c-1.79,4.31-4.95,7.26-8.72,9.93C998.04,551.46,994.13,553.52,990.15,555.78z M849.94,1433.32c2.24,6.38,5.46,11.8,8.33,17.38
				c0.67,1.3,1.82,1.7,3.13,2.17c21.6,7.76,42.77,16.49,62.9,27.63c5.83,3.23,12.14,5.37,18.55,7.21c3.99,1.14,8.37,1.9,11.7,4.13
				c10.32,6.9,21.65,7.56,33.39,6.79c1.49-0.1,2.99-0.01,4.49-0.01c4.18,0,8.35,0,12.53,0c-6.43-5.53-13.55-8.58-21.37-9.75
				c-12-1.8-24.17-2.96-35.48-7.62c-23.25-9.59-44.19-23.72-66.85-34.5c-0.86-0.41-1.77-1.05-1.4-2.44c1.95-1.35,3.41,0.34,4.83,1.12
				c17.09,9.36,35.03,16.87,52.98,24.34c14.07,5.86,28.37,10.64,44,8.63c0.8-0.1,1.72-0.07,2.46,0.22
				c8.99,3.43,19.25,4.53,26.79,10.52c10.97,8.72,22.93,10.67,36.19,9.55c5.45-0.46,10.98-0.06,16.47-0.09
				c1.34-0.01,2.68-0.15,5.3-0.3c-1.91-0.82-2.48-1.16-3.1-1.32c-19.61-5.17-37.86-13.2-54.82-24.49
				c-10.23-6.82-21.57-11.79-33.08-16.23c-5.04-1.94-9.95-3.28-15.4-0.87c-2.99,1.32-6.5,1.19-9.86,0.78
				c-16.1-1.97-31.5-6.87-46.86-11.69C887.63,1438.78,869.26,1434.9,849.94,1433.32z M488.88,1386.32c0.77-4.21,5.48-3.24,6.46-6.27
				c1.19-3.63,3.07-4.48,6.76-2.79c3.08,1.41,8.43-3.39,7.86-6.56c-0.69-3.82-7.16-7.48-10.69-5.8c-3.89,1.85-5.91,0.76-6.85-3.32
				c-0.6-2.59-2.33-4.45-5.06-3.09c-4.06,2.02-7.59,1.81-11.11-1.02c-1.11-0.89-2.65-0.71-3.85-0.22
				c-7.37,3.02-16.63-1.38-22.94,5.84c-0.38,0.43-1.25,0.66-1.86,0.61c-4.52-0.36-6.26,2.63-5.84,6.14c0.86,7.26,2.28,8.5,7.91,7.87
				c3.08-0.34,4.95,0.69,6.77,3c2.42,3.07-1.72,4.56-1.61,7.11c2.82,1.66,4.77,0.56,6.79-1.24c2.55-2.27,5.54-2.72,8.01-0.05
				c4.92,5.3,5.56,5.56,11.38,1.5c2.97-2.07,5.24-1.91,6.84,1.47c1.04,2.19,2.53,3.28,5.06,3.21c2.7-0.08,4.29,2.04,3.94,4.17
				c-0.63,3.78,1.64,3.9,3.98,3.91c3.89,0.02,8.03-0.6,10.62-3.47c3.47-3.84,6.2-3.04,9.15,0.03c2.86,2.97,5.74,2.67,8.04-0.25
				c2.76-3.5,5.62-2.81,8.77-1.06c1.43,0.8,2.84,2.4,4.5,1.3c5.06-3.35,8.61-1.97,10.93,3.26c7.33-3.4,8.4-2.71,9.08,6.05
				c4.09-0.31,8.14,0.56,12.12,1.54c5.8,1.44,4.73,5.83,3.44,9.9c1.89,1.17,3.39,0.17,4.98-0.19c3.39-0.75,5.53,0.47,5.78,4.17
				c0.12,1.68-0.98,3.95,1.8,4.41c2.41,0.4,3.79-1.06,4.27-3.23c1.14-5.15-0.81-10.81,2.55-15.6c-3.3-2.38-4.12-6.67-7.06-9.28
				c-2.51-2.23-6.94-3.83-7.53-6.39c-1.09-4.75-4.42-4.61-7.45-5.96c-3.21-1.43-6.01-4.6-9.77-1.17c-0.84,0.76-1.9,0.7-2.73-0.07
				c-3.31-3.11-7.9-2.89-11.81-4.47c-3.92-1.59-5.8-3.72-4.78-7.81c0.55-2.2,0.1-4.08-2.06-4.18c-4.24-0.2-7.76-4.07-12.23-1.88
				c-1.4-7.63-1.9-8.17-9.88-7.28c-3.89,0.44-6.42-1.03-8.45-3.97c-2.15-3.13-4.47-4.56-8.33-2.3c-2.22,1.3-5.62,2.28-7.13-0.75
				c-2.7-5.43-5.41-3.6-8.34-0.64c-2.84,2.88-5.62,3.82-9.1,0.91c-1.47-1.23-3.38-2.42-5.38-0.61c-3.66,3.31-7.31,2.25-10.98,0.04
				c-1.41-0.85-3.23-2.44-4.51-1.41c-4.1,3.32-10.42,4.37-11.46,11.47c4.47-5.44,10.21-5.74,16.27-4.95
				c0.59,0.08,1.24-0.46,1.89-0.58c4.6-0.85,9.19-2.99,13.65,0.81c1.58,1.34,3.76,0.58,5.71-0.19c4.31-1.71,7.6-0.58,8.89,4.11
				c0.77,2.82,2.26,3.1,4.37,1.78c2.89-1.82,4.96-0.91,7.6,0.97c3.45,2.47,5.68,5.33,5.95,9.42c2.43,0.62,4.67-0.24,6.68,1.03
				c1.02,0.64,1.76,2.62,3.26,1.62c3.99-2.67,8.02-0.46,12.02-0.37c1.21,0.03,2.19,0.07,3.25-0.65c2.77-1.86,4.6-0.2,6.37,1.73
				c-2.47-0.25-4.88-1.43-6.63,1.5c-0.57,0.96-1.77,0.28-2.75,0.23c-4.13-0.23-8.32-2.69-12.39,0.39c-0.9,0.68-1.86,0.01-2.61-0.78
				c-3.56-3.8-7.77-2.88-11.2-0.48c-3.2,2.24-6,2.97-9.49,0.99C496.99,1383.07,493.51,1384.96,488.88,1386.32z M723.25,783.2
				c0.39,0.17,0.79,0.34,1.18,0.51c2.25-4.92,8.19-6.53,9.85-12.03c0.42-1.39,1.83-1.85,3.24-1.93c2.79-0.14,3.64-1.67,3.37-4.31
				c-0.44-4.3-0.71-8.61-1.03-12.92c-0.09-1.19-0.24-2.32-1.42-2.99c-5.82-3.33-5.92-8.69-5.16-14.4c0.7-5.26,2.29-10.44,1.4-15.84
				c-2.32-14.06-4.13-28.2-6.13-42.3c-1.12-7.9,0.96-15.96-1.93-23.62c-2.64-7.02-4.67-14.18-5.38-21.69
				c-0.12-1.22-0.05-2.68-1.54-3.45c-7.55-3.89-14.77-8.15-17.88-16.94c0.18,1.8,1.04,3.2,1.33,4.72c0.98,5.01,3.16,8.86,8.15,11.03
				c2.55,1.11,3.37,3.93,4.01,6.6c2.95,12.29,5.02,24.77,6.73,37.26c1.94,14.16,0.6,28.6,3,42.78c0.59,3.5,1.9,7.07,0.58,10.89
				c-4.4-0.15-5.07-3.59-5.64-6.51c-1.02-5.21-1.59-10.52-2.19-15.8c-1.41-12.49-2.72-25-4.13-38c-3.26,3.38-4.43,6.86-3.61,10.83
				c3.88,18.89,2.9,38.47,9.73,57.04c4.51,12.26,5.47,25.64,6.64,38.75c0.08,0.87,0.75,2.05-1.25,2.41c-1.59-4-1.67-8.34-2.33-12.51
				c-1.43-9.04-3.09-18.02-4.91-26.99c-0.95-4.67-4.93-8.32-5.07-12.8c-0.49-16.03-4.32-31.62-5.59-47.52
				c-0.43-5.4,2.94-9.35,5.28-13.77c2.76,1.62,3.34,3.42,3.55,5.51c1.32,12.73,2.61,25.46,4.06,38.18
				c0.62,5.45,1.07,10.97,2.87,16.21c-0.59-8.6-2.27-17.16-2.14-25.69c0.27-17.81-3.88-34.98-5.96-52.45
				c-1.04-8.74-5.77-15.26-12.56-18.09c-6.51-2.71-8.13-7.45-11.03-12.32c-2.6-4.35-6.35-7.97-8.8-12.68
				c0.86,5.63-1.52,11.12,0.62,16.46c2.72,6.79,5.47,13.56,4.26,21.21c-0.27,1.68,0.22,3.82,1.12,5.29
				c4.38,7.21,4.23,15.21,4.67,23.19c0.91,16.45,2.21,32.88,3.04,49.33c0.46,9.23,2.37,17.61,9.08,24.55
				c2.48,2.57,3.91,6.15,5.79,9.29c0.85,1.43,1.54,2.98,3.1,3.81c3.27,1.74,4.5,4.73,5.07,8.14
				C720.62,767.49,721.93,775.35,723.25,783.2z M740.91,470.51c-2.75,1.81-1.43,3.95-0.63,5.84c1.76,4.14,3.22,8.3,4,12.79
				c1.52,8.66,2.12,17.51,4.92,25.91c0.96,2.87,0.86,4.83-2.18,6.57c-1.92,1.1-4.2,3.09-1.48,6c7.76,8.29,14.19,17.6,19.89,27.38
				c4.59,7.86,10.48,13.95,19.22,17.18c5.86,2.16,11.05,5.68,14.48,11.01c6.42,9.98,14.85,17.59,25.11,23.48
				c3.92,2.25,7.16,5.7,10.66,8.55c1.75-1.39,3.11-2.63,4.63-3.65c4.24-2.86,8.2-6.8,13.77-6.26c6.94,0.68,12.05-2.67,17.34-6.4
				c-1.72-2.4-4.27-2.78-6.59-3.45c-4.72-1.37-8.88-3.64-12.5-6.97c-2.45-2.25-4.94-4.44-7.48-6.59
				c-15.51-13.09-34.5-21.54-48.7-36.41c-0.89-0.93-2.1-1.86-3.31-2.15c-6.49-1.56-9.13-6.58-11.29-12.09
				c-1.64-4.17-3.3-8.34-4.83-12.55c-0.57-1.57-1.01-3.19-2.28-4.36c-7.2-6.63-5.58-15.07-5.22-23.78c-1.97-2.42-4.35-0.92-6.67,0.46
				c-1.21,0.72-3.2,1.26-2.46,3.2c3.64,9.54,4.4,20.24,10.74,28.67c7.27,9.67,12.32,20.46,17.14,31.42c0.34,0.77,1.3,1.73-0.68,2.74
				c-0.92-1.87-1.83-3.75-2.76-5.61c-3.51-6.97-6.08-14.67-10.75-20.75c-8.14-10.62-12.17-22.82-15.66-35.21
				C754.47,485.29,749.44,477.03,740.91,470.51z M851.31,1329.85c3.75-0.63,3.58-3.35,3.82-6.07c2.54-28.31-1.3-56.32-3.93-84.39
				c-0.19-1.99-0.34-4-0.81-5.93c-0.81-3.36-3.73-4.34-6.51-4.18c-3.19,0.19-4.34,2.52-3.55,5.73c0.27,1.11,0.19,2.32,0.17,3.48
				c-0.04,4.31,2.19,9.86-5.48,10.09c-0.46,0.01-1.06,0.84-1.3,1.42c-1.12,2.61-3.3,3.68-5.88,4.02c-3.27,0.44-4.6,2.48-4.6,5.47
				c0.01,2.98,0.37,5.97,0.49,8.95c0.07,1.79,0.84,4.08-0.88,5.17c-3.95,2.5-3.02,6.89-2.55,9.63c1.88,10.87,0.97,21.83,3.35,32.9
				c3.23,14.99,2.98,31.02,0.45,46.6c-0.42,2.6-1.83,5.43-0.72,7.86c3.93,8.64,2.52,17.64,2.29,26.57c-0.04,1.53-0.31,3.05,1.14,4.08
				c5.94,4.21,9.74,10.33,13.47,15.52c7.1-0.65,13.74,2.19,20.08-0.63c-0.96-3.74-2.21-7.23-2.64-10.81
				c-0.27-2.21-0.91-3.57-2.68-4.82c-9.78-6.87-13.12-16.16-10.8-27.89c0.91-4.62,1.5-9.53-1.1-14.17c-4.31,1.11-7.28-0.52-9.25-4.17
				c-2-3.71-0.05-6.2,2.5-8.71c2.2-2.17,2.41-4.99,1.44-7.88c-0.85-2.55-1.52-5.16-1.04-7.84c0.71-3.99,2.45-7.63,6.82-8.18
				c4.27-0.53,5.95,2.84,6.99,6.42C850.79,1328.71,851.08,1329.29,851.31,1329.85z M276.88,1117.13c-2.82,4.8-6.14,8.28-9.29,11.84
				c2.22,1.64,4.13-0.51,5.78,0.88c0.06,1.78-2.22,2.6-1.7,4.51c1.17,1.54,3.22-1.48,4.46,1.1c-1.67,4.14-6.84,5.51-8.78,10.02
				c3.47-0.39,2.85,3.78,5.71,4.19c-2.16,4.12-7.11,4.55-9.02,8.89c3.57,2.23,3.94,5.33,2.37,9.28c-0.86,2.15-1.11,4.89,1.22,6.9
				c0.52,0.45,0.99,1.83,0.71,2.2c-3.91,5.25,0.95,11.13-1.81,16.62c5.4-1.56,5.4-1.56,16.14-18.45c-4.28-2.72-5.57-5.88-2.6-10.78
				c1.38-2.28,1.08-6.32-1.49-8.12c-2.86-2.01-2.39-3.6-0.73-5.63c1.46-1.79,3.12-3.41,4.75-5.18c-0.56-1.24-3.14-2.71-1.39-4.02
				c4.65-3.48,2.41-8.75,4.45-13.47c-5.14-0.52-6.99-2.76-3-7.37c-4.99-2.29-4.43-5.31-2.35-9.41c2.27-4.49,4.05-9.44,4.89-14.38
				c0.53-3.13,2.07-7.1,1.19-9.39c-2.31-5.99,1.16-9.28,4.39-13.16c-5.18,5.02-1.72,11.35-2.51,16.99c-0.62,4.4-1.49,8.95-2.71,13.03
				c-1.18,3.93-2.61,8.12-4.92,11.46c0.49,1.41,1.42,1.78,2.28,2.29c0.95,0.56,2.3,0.62,2.67,1.77c0.32,2.05-2.22,2.66-1.93,4.53
				c5.55,1.23,5.55,1.22,3.24,7.23c-0.17,0.45-0.4,1.06-0.23,1.44c1.74,3.9-0.67,6.55-3,8.95c1.34,1.82,3.63,0.09,4.76,2.36
				c-2.38,3.63-8.26,4.19-9.36,9.07c1.26,1.51,3.48,1.11,4.18,2.91c-0.86,4.2,0.81,8.85-2.51,12.58c-0.27,0.31,0.19,1.92,0.69,2.18
				c6.83,3.52,2.26,6.55-0.21,9.87c-1.21,1.64-1.87,3.69-2.69,5.37c3.08,2.88,6.12-0.89,9.14,0.59c3.16,1.54,4.6,4.75,7.23,6.76
				c0.25,0.19,0.62,0.23,0.93,0.35c7.76,3,11.93,10.4,10.38,18.44c2.06,1.18,4.85,1.65,4.67,5.02c-0.33,5.95,0.73,6.85,6.6,5.81
				c-0.15-1.96-2.78-3.07-1.83-5.52c1.95-1.41,4.88,0.94,6.96-1.8c-6.23-2.78-12.48-5.46-15.7-12.82c4.04,0.38,7.29-0.45,10.88-1.55
				c-3.3-3.91-8.84-5.47-9.74-11.3c3.19-0.44,5.79,0.81,8.5,1.24c1.64,0.26,3.63,0.96,4.3-1.12c0.53-1.65-0.55-3.57-2.12-4.1
				c-7.5-2.55-6.27-9.94-8.73-15.26c-0.08-0.16,0.31-0.54,0.66-1.11c6.51,2.98,6.22-2.63,7.63-6.32c-1.34-0.39-2.4-2.36-3.92-1.31
				c-5.98,4.13-5.04-0.72-5.21-3.96c-0.11-2.16-0.25-4.4,0.19-6.48c1.22-5.76,1.36-11.12-3.59-15.43c-0.7-0.61-1.17-1.63-0.79-2.72
				c0.99-1.54,3.45,0.08,4.28-2.12c-3.94-2.98-8.55-5.47-9.96-11.07c2.51-0.25,4.23,2.17,5.74,1.09c4.96-6.26-4.04-9.29-3.12-15.26
				c2.06,0.78,3.55,1.35,5.24,1.99c1.74-8.11-9.11-10.46-7.87-18.44c2.97,1.37,5.83,0.21,10.06,0.89c-6.8-4.48-12.3-8.31-14.02-15.48
				c1.88-0.59,3.13,1.39,5.41-0.36c-2.13-6.08-8.04-10.25-9.95-17.76c-2.88,6.26-6.27,10.66-10.57,14.81c2.73,0,4.84,0,6.96,0
				c-6.35,2.54-6.45,10.18-11.84,13.27c2.44,0.21,4.83-1.23,6.5,1.31c-3.11,3.5-8.09,6.02-6.69,11.66c2.27,1.32,4.01-1.27,5.87,0.6
				c-1.06,2.53-3.47,3.42-5.65,4.42c-2.55,1.17-4.76,2.26-2.88,5.28c1.83,1,3.65-0.6,5.53,1.42c-2.67,1.45-4.61,3.52-5.63,6.54
				C273.95,1116.66,275.2,1116.86,276.88,1117.13z M1068.64,1453.67c-4.03-3.99-7.88-7.7-13.97-6.62c-1.06,0.19-1.83-0.44-2.67-1.06
				c-7.1-5.19-8.27-5.27-15.13,0.07c-2.9,2.26-5.57,2.47-7.86-0.28c-3.87-4.64-8.03-4.47-13.29-2.45c-7.26,2.78-9.33,1.59-12.75-5.59
				c-1.32-2.77-3.53-3.61-6.37-3.99c-5.01-0.66-9.93-1.17-15.06-1.2c-7.03-0.04-14.11-2.63-19.22,5.22
				c-1.09-3.18-0.24-7.13-4.01-6.81c-3.19,0.27-5.22-1.31-6.96-2.97c-4.07-3.87-7.66-2.98-11.28,0.27c-2.13,1.91-4.85,2.52-6.91,1.17
				c-5.64-3.68-9.21-0.9-12.39,3.38c-5.33-3.88-16.42-6.12-19.44-4c0.94,2.7,6.17,2.83,4.35,7.33c-1.03-1.77-2.8-2.7-4.04-4
				c-4.05-4.27-11.28-4.44-13.62-10.86c-0.46-1.25-2.37-0.88-3.55-1.34c-7.15-2.74-15.03,3.31-21.91-1.92c-0.1-0.08-0.34-0.05-0.49,0
				c-6.35,2.25-12.91,2.06-19.69,1.19c3.88,8.25,3.95,8.02,10.16,9.78c14.42,4.08,29.46,5.2,43.83,9.59
				c14.62,4.46,29.38,8.48,44.1,12.59c1.77,0.49,3.86,1.69,5.51-0.39c5.94-7.48,14.86-7.63,22.92-8.02
				c7.92-0.39,15.85,2.54,24.04,1.95c3.17-0.23,6.2,1.1,8.01,4.17c4.38,7.4,4.39,7.39,12.33,4.75c5.42-1.8,8.65,0.08,9.54,5.75
				c0.33,2.11,0.71,3.64,3.23,4.28c9.67,2.43,11.06,5.84,6.19,14.43c-0.47,0.83-0.94,1.68-1.5,2.69c3.31,2.15,6.52,3.73,10.29,4.89
				c0.97-4.6,3.3-7.81,8.21-6.85c5.72,1.13,9.87-2.78,14.51-4.51c2.79-1.04,5.19-3.1,8.49-2.68c1.26,0.16,2.27-0.24,3.16-1.1
				c2.19-2.12,4.59-2.39,7.3-1c0.81,0.42,1.8,1.34,2.75,0.56c5.77-4.74,10.63-2.08,15.53,1.7c-2.03-5.56-7-7.26-11.87-8.84
				c-2-0.65-3.6-1.09-4.53-3.25C1081.61,1452.82,1075.5,1450.5,1068.64,1453.67z M659.29,486.19c6.02,5.86,12.91,9,20.91,10.86
				c7.56,1.75,9.36,9.88,4.22,15.54c-1.38,1.52-1.51,3.31-1.37,5.13c0.34,4.52,1.11,9.13,3.55,12.88c5.12,7.86,6.17,16.73,7.44,25.61
				c0.75,5.27,1.54,10.53,2.26,15.8c0.57,4.22,2.47,7.99,6.15,9.79c9.21,4.51,15.35,12.63,23.11,18.82
				c4.55,3.63,10.57,6.01,12.33,12.54c0.18,0.68,1.56,1.24,2.47,1.47c4.77,1.19,9.58,2.25,15.2,3.55
				c-3.97-9.54-14.86-16.12-9.88-28.24c4.45,1.34,7.32,4.82,11.07,6.82c4.71,2.51,11.4,2.07,14.72-1.82
				c7.02,7.03,17.13,1.73,25.02,6.7c-2.51-4.7-4.75-9.22-7.81-13.1c-5.2-6.58-10.1-13.79-19.77-14.54c-2.13-0.17-4.07-1.49-5.42-3.31
				c-3.19-4.3-7.53-7.1-12.05-9.69c-2.27,2.73-3.47,6.3-7.31,7.33c-1.98-2.24-1.01-4.86-1.36-7.24c-0.36-2.39,0.11-5.3-3.95-6.26
				c0.33,8.86,2.12,18.12-9.14,22.59c-6.66-14.88-15.03-29.14-14.86-46.41c-0.21,3.97,0.53,7.67,1,11.41
				c1.01,8.16-2.18,11.47-10.8,10.55c0.4-12.74-3.63-24.99-5.1-37.64c-0.2-1.72,0.41-4.44-2.05-4.42c-5.29,0.03-3.98-3.45-4.19-6.48
				c-0.26-3.67,2.09-7.22,0.81-11.21c-2.73,1.48-1.9,5.07-5.74,6.28c-6.65-8.44-14.17-16.72-25.04-21.15
				C661.43,480.01,666.03,486.26,659.29,486.19z M934.23,1426.96c-0.98-1.99-1.57-3.35-2.29-4.63c-4.18-7.44-7.37-14.83-2.87-23.51
				c1.79-3.46,0.24-7.21-2.23-10.2c-6.4-7.76-12.82-14.95-24.27-15.39c-14.54-0.56-25.81-9.8-38.04-16.28
				c-2.72-1.44-4.1-4.3-4.87-7.29c-0.91-3.49-0.8-7.42-5.42-9.75c7.7,0.35,7.86,0.31,10.46-2.38c-2.65-0.37-5.66,1.52-8.47-1.24
				c2.93-1.27,7.29,0.98,6.63-5.16c2.46,3.97,5.71,4.91,8.47,6.58c4.99,3.03,5.39,8.29,1.01,11.92c-0.67,0.55-2.05,0.49-1.76,1.93
				c8.02,0.55,11.98-3.86,10.29-11.44c-1.64-7.38-9.45-12.44-17.38-11.59c-7.69,0.82-13.9,5.17-20.57,8.35
				c-2.3,1.1-4.68,3.81-1.09,6.68c1.29,1.04,1.52,2.43,1.46,4.02c-0.12,3.21,1.32,6.29,4.15,7.03c3.25,0.85,7.15,0.66,8.51-3.87
				c0.21-0.69,0.79-1,1.79-0.79c1.11,9.52-7.3,6-11.91,8.3c1.58,5.04,1.86,10.23,0.76,15.24c-2.7,12.21,2.39,20.55,12.44,26.77
				c3.43-2.58,3.11-6.92,4.93-11.31c1.6,4.98,0.04,8.37-1.81,11.63c-3.75,6.62-1.12,14.4,6.31,17.72c0.6-4.89-2.36-9.78-0.54-14.26
				c1.7-4.19,4.75-7.82,7.2-11.7c0.65,4.29-2.33,6.84-3.73,10.29c10.92,8.72,22.8,13.11,37.34,10.89c-2.52-4.25-5.88-7.15-6.17-11.84
				c3.95,5.52,8.23,10.65,13.52,14.78C921.41,1420.61,926.52,1425.18,934.23,1426.96z M173.92,1166.21
				c-10.33-9.28-22.15-15.8-34.72-21.24c-10.51-4.54-20.86-9.48-31.03-14.74c-3.56-1.84-5.93-2.11-8.29,1.11
				c0.28,1.84,1.65,2.51,2.92,3.29c4.03,2.48,5.68,11.11,1.95,13.61c-4.05,2.72-4.89,7.18-9.23,11.67
				c8.49,0.77,13.67,6.49,20.94,7.62c-0.1,2.89-2.24,3.45-3.84,4.47c-2.78,1.77-5.75,3.28-8.34,5.28c-2.38,1.84-2.38,4.98,0.7,5.12
				c9.38,0.4,14.22,9.48,22.92,11.16c2.87,0.56,4.58,4.04,1.87,6.68c-2.4,2.33-4.9,5.03-8.74,5.36c-1.64,0.14-3.39,0.51-3.66,3.32
				c6.72-0.68,10.31,5.02,15.31,7.7c2.5-0.83,3.5-2.78,4.59-4.65c1.02-1.74,2.08-3.76,4.26-3.85c3.82-0.16,5.72-2.13,6.54-5.61
				c0.41-1.76,1.61-3,3.15-3.67c4.41-1.92,6.21-7.94,12.16-7.56c1.05,0.07,1.57-1.87,2.24-3.03
				C169.56,1181.47,170.9,1173.7,173.92,1166.21z M891.82,765.99c-0.58-0.49-1.07-0.89-1.55-1.29
				c-10.39-8.72-23.15-12.88-35.24-18.31c-4.12-1.85-8.74-3.21-11.07-7.23c-2.14-3.69-5.24-5.86-8.58-8.02
				c-7.97-5.15-16.45-9.38-24.94-13.58c-2.84-1.4-5.79-2.67-7.74-5.29c-1.66-2.23-3.07-4.93-0.31-7.08c4.77-3.7,2.15-5.96-1.16-8.29
				c-2.32-1.64-5.02-2.54-7.55-3.76c-8.66-4.16-17.51,0.14-26.4-1.5c0.58,2.45,2,3.36,3.59,3.81c5.15,1.48,7.26,5.44,8.56,10.13
				c0.99,3.6,0.65,7.28,0.6,10.92c-0.19,11.77-0.27,23.54,1.18,35.65c5.57-0.69,10.52-0.16,13.15,5.62c0.56,1.23,2.1,1.17,3.1,0.48
				c8.89-6.08,18.83,0.97,27.95-2.27c0.48-0.17,1.29,0.29,1.83,0.63c1.95,1.26,3.77,1.18,6.07,0.54c7.73-2.16,11.67-0.51,16.47,6.17
				c0.58,0.8,1.12,1.68,2.24,1.82c6.87-4.23,7.79-4.35,15-0.46c2.27,1.22,4.17,1.69,6.42,0.08c2.95-2.12,6-1.84,8.7,0.46
				C885.14,767.82,888.22,767.92,891.82,765.99z M706.33,1461.97c1.95-4.18,4.03-8.13,1.17-12.09c-2.13-2.94-3.48-6.1-4.03-9.53
				c-0.7-4.4-3.53-6.59-7.21-8.13c-7.14-2.98-7.22-2.96-5.8-10.99c0.32-1.81,0.53-3.44-1.37-4.24c-5.23-2.19-9.57-5.78-14.33-8.71
				c-2.29-1.4-4.04-2.79-4.91-5.23c-2.99-8.38-10.98-10.13-17.87-12.91c-3.1-1.25-6.37-1.84-9.56-3.45
				c-4.67-2.37-10.03-3.34-13.38-7.96c-0.31-0.43-1.23-0.67-1.83-0.62c-6.76,0.6-9.41-4.66-12.72-8.91c-1.19-1.53-1.77-3.76-4.66-2.9
				c-0.24,3.89-0.62,7.84-0.71,11.78c-0.14,6.15,1,12,6,16.17c2.71,2.26,3.93,4.72,2.6,8.06c3.73,1.12,7.54,1.53,9.39,4.8
				c1.92,3.4,3.71,7.06,4.58,10.82c0.64,2.77,2.16,3.68,4.26,4.77c16.05,8.33,33.06,14.81,48.06,25.19
				C691.1,1452.82,698.6,1457.13,706.33,1461.97z M322.11,1142.42c-2.34,6.38-4.31,11.74-6.24,16.99c2.38,2.44,6.22,1.21,8.5,4.87
				c-5.76,1.01-3.81,9.66-11.67,9.08c2.41,2.3,4.05,3.87,5.37,5.14c0.64,2-2.28,1.67-1.49,3.53c3.53,2.28,8.27,3.58,8.24,8.84
				c-3.41,4.41-7.94,2.93-13.53,1.95c5.12,4.8,11.34,6.42,13.33,12.56c-4.19,2.02-8.65-2.77-12.58,1.18
				c4.56,4.73,11.12,5.79,16.28,9.17c-2.43,3.42-6.08,3.24-10.31,5.2c1.5,0.76,2.46,0.95,2.97,1.54c3.81,4.44,10.09,4.39,14.22,7.53
				c4.77,3.62,10.39,3.19,15.39,4.92c7.55,2.61,8,1.29,9.78,9.05c7.84-2.84,15.96-1.9,25.31-0.58c-3.11-5.32-4.9-9.77-7.86-13.2
				c-9.03-10.43-15.45-21.96-16.88-35.76c-10.61-1.45-16.58-8.46-16.84-18.76c-0.16-6.15-0.96-12.56-4.18-17.71
				c-2.04-3.25-4.34-6.01-4.03-10.26C330.45,1148.8,325.84,1148.31,322.11,1142.42z M921.75,661.04c7.02-0.73,12.93,0.7,17.86,5
				c0.38,0.33,1.33,0.19,1.95,0.01c3.71-1.04,6.45,0.66,7.86,3.69c2.66,5.72,6.47,5.84,11.79,4.04c6.11-2.07,9.48-0.57,13.36,4.62
				c0.98,1.31,1.57,2.89,3.84,2.3c2.18-0.57,2.17-2.2,2.42-3.82c1.65-10.67,3.15-21.43,5.41-31.91c1.85-8.56-2.42-14.1-6.46-19.2
				c-3.24-4.08-6.17-7.89-8.05-12.76c-1.1-2.86-2.26-6.21-4.95-7.82c-2.17-1.29-4.11,1.53-6.28,2.17c-0.32,0.09-0.67,0.21-0.98,0.17
				c-5.9-0.86-7.8,3.32-9.44,7.64c-1.59,4.19-2.84,8.5-4.45,12.68c-0.56,1.45-1.06,3.53-3.06,3.54c-2.21,0.01-2.7-2.15-3.5-3.75
				c-1.44-2.88-0.71-6.55-3.36-9.61c-3.99,4.19-8.57,7.22-9.83,13.31C923.95,640.64,924.15,650.2,921.75,661.04z M577.25,783.81
				c2.32-5.94,1-11.62,4.06-16c1.48-2.12,1.78-4.15,1.73-6.51c-0.18-10.18-1.74-20.2-3.45-30.2c-0.79-4.63-0.13-8.66,2.51-12.78
				c3.14-4.89,5.74-10.1,9.88-14.29c0.77-0.78,1.02-1.72,0.74-2.75c-2.47-9.14-1.24-18.56-2.02-27.82c-0.21-2.58-0.56-5.02-3.08-6.41
				c-2.64-1.45-3.47-3.78-3.71-6.62c-0.31-3.67-0.68-7.77-3.91-9.86c-4.16-2.69-7.76-5.49-8.38-11.53c-1.66,2.77-2.49,4.61-3.72,6.12
				c-2.86,3.55-4.87,7.16-4.87,12.06c0,3.72-1,7.84-4.09,10.4c-1.77,1.47-1.78,2.89-1.68,4.68c0.93,16.12,1.93,32.23,0.61,48.38
				c-0.13,1.63-0.19,3.61,0.59,4.9c3.3,5.51,2.41,11.58,2.87,17.49c0.44,5.64,1.27,11.24,1.6,16.88c0.27,4.7,3.03,5.92,7,5.72
				c0.91-0.05,1.79-1,2.85,0.24C574.37,771.34,572.41,777.93,577.25,783.81z M831.44,618.49c-0.15-0.54-0.29-1.08-0.44-1.62
				c-1.29-1.04-2.49-2.22-3.89-3.08c-13.16-8.05-24.4-17.98-32.6-31.3c-3.34-5.42-9.54-7.32-15.33-9.34
				c4.16,5.25,10.65,8.1,13.79,14.27c2.94,5.79,5.95,11.55,8.93,17.34c-2.16,1.02-3.35,0.26-4.63,0.01c-3.26-0.62-6-3.56-9.35-2.9
				c-4.33,0.85-8.23,0.27-12.12-1.49c-0.97-0.44-2.01-1.32-2.99,0.21c-1.6,2.48-4.1,1.53-6.37,1.54c-7.14,0.04-12.97-3.31-18.67-7.77
				c-1.56,5.3,0.73,8.84,3.25,12.39c5.1,7.18,10.99,14.08,9.11,23.96c-0.17,0.91,0.05,2.08,0.5,2.9c3.51,6.43,3.48,13.42,3.41,20.43
				c-0.05,4.73-0.01,9.47-0.01,14.05c9.58,0.83,16.87-1.3,22.24-8.17c-0.64-1.47-2.76-1.79-2.19-4.23c3.62-2,7.42-4.26,11.69-5.74
				c4.89-1.7,6.65-3.85,8.66-9.66c-4.48,0.87-8.9,4.24-13.84,0.17C803.73,631.71,815.38,621.28,831.44,618.49z M691.84,567.04
				c0.31-0.09,0.63-0.18,0.94-0.28c-0.36-2.61-0.56-5.26-1.1-7.83c-1.7-8.07-1.11-16.69-5.9-24.02c-2.97-4.54-4.25-9.69-5.28-14.95
				c-0.77-3.91-0.32-7.35,2.84-9.96c1.42-1.18,1.43-2.42,1.2-3.97c-0.74-4.95-3.6-7.27-9.39-7.59c-1.78-0.1-3.64,0.54-5.32-0.69
				c-8.96-6.54-18.34-12.51-26.09-20.65c-4.89-5.13-8.56-11.64-15.36-14.73c-5.5-2.5-10.29-6.16-12.78-11.43
				c-4.32-9.14-13.13-13.97-18.97-21.55c-0.82-1.06-2.02-1.04-3.2-0.94c-5.09,0.45-10.27,0-15.05,2.68c-2.46,1.38-5.41,1.13-8.3,0.75
				c-6.39-0.82-12.81-1.32-19.76-2c1.8,7.36,3.17,14.2-0.87,21.33c5.5-0.2,9.64-0.78,13.91,1.01c2.78-5.67,5.44-11.1,8.31-16.95
				c6.34,4.36,5.43,11.81,9.34,16.85c1.06-5.44-6.7-12.61,3.52-15.92c4.17,5.21,6.23,11.41,7.84,17.92c1.73,7,4.12,8.5,11.83,8.48
				c-0.04-6.62-3.57-12.97-2.02-20.1c13.54,13.01,16.3,18.9,18.24,38.88c6.49,0.57,8.68,5.67,10.69,10.93
				c0.95,2.48,1.95,4.86,3.81,6.92c9.9,10.92,21.53,20.46,27.79,34.34c0.31,0.69,0.72,1.57,2.55,1.28
				c-2.56-6.38,0.91-13.02-1.41-19.53c-1.39-3.91-2.56-7.91-3.63-11.92c-0.4-1.5-1.18-3.51,0.75-4.47c1.78-0.89,2.49,0.95,3.48,2.02
				c1.01,1.09,2.12,2.13,3.35,2.96c4.4,2.94,6.28,7,6.26,12.28c-0.04,7.99,0.24,15.98,0.35,23.98c0.05,3.94,1.54,7.28,4.77,9.41
				c3.78,2.5,6.22,5.86,8.15,9.84C688.59,562.06,690.32,564.5,691.84,567.04z M678.88,713.32c0-7.04,0.04-13.98-0.01-20.92
				c-0.06-7.81,1.34-15.98-1.18-23.33c-4.14-12.08-4.05-24.47-5.08-36.85c-0.75-8.96-1.88-18.22-7.44-25.55
				c-5.55-7.31-5.09-14.73-2.93-22.64c0.86-3.13,1.02-6.23,0.59-9.38c-0.32-2.37-2.11-3.04-4.18-3.6c-2.14-0.58-2.55,0.92-3.31,2.15
				c-5.95,9.69-10.21,19.84-11.39,31.41c-0.68,6.68-0.35,13.96-7.18,19.07c-3.5,2.62-6.62,6.7-4.69,12.78
				c3.69,11.59,5.57,23.61,5.12,35.88c-0.11,3.1,1.35,4.09,4.31,3.46c0.53-3.14,1.12-6.07,1.49-9.02c0.46-3.68,2.8-4.96,6.11-5.33
				c3.38-0.38,6.3,0.08,7.97,3.54c0.72,1.49,1.31,3.12,2.35,4.36c5.95,7.16,10.09,15.04,11.31,24.45c0.83,6.41,5.79,11.47,6.55,18.02
				C677.38,712.58,678.12,713.23,678.88,713.32z M777.65,1484.01c-3.96-2.82-5.95-6.89-5.82-10.88c0.16-5.21-2.14-7.76-6.33-9.44
				c-2.98-1.2-4.69-3.11-5.77-6.27c-2.29-6.69-5.62-8.63-12.86-8.54c-1.97,0.02-4,0.51-5.84-0.63c-4.51-2.79-9.26-4.8-14.72-4.51
				c-1.69,0.09-2.45-0.85-2.21-2.5c1.24-8.8-4.89-13.78-10.45-18.41c-4.51-3.75-10.07-6.18-12.14-12.3c-0.4-1.19-1.55-1.93-2.69-2.01
				c-3.74-0.29-5.21-2.92-6.72-5.79c-2.86-5.41-6.29-7.05-12.25-6.15c-8.07,1.22-9.97-0.19-11.29-8.33
				c-0.47-2.92-1.31-6.06-4.78-6.03c-3.87,0.03-7.54-0.85-11.3-1.25c-7.02-0.75-12.78-3.79-16.73-9.67c-1.96-2.91-4-4.41-7.75-4.04
				c-5.26,0.53-10.28-0.22-13.39-5.49c-1.06-1.79-3.02-2.23-5.09-2.49c-0.31,2.28-1.08,4.36,2.31,4.37c2.73,0.01,3.64,2.41,5.12,4.2
				c2.45,2.96,3.81,7.49,8.57,7.6c3.51,0.08,6.02,1.28,7.94,4.17c0.94,1.42,2.63,1.94,4.11,2.7c8.38,4.34,18.16,5.1,26.07,10.95
				c3.46,2.56,7.75,3.66,8.34,8.81c0.15,1.32,0.93,2.72,2.19,3.19c5.35,1.98,8.74,7.01,14.33,8.74c3.83,1.19,5.93,3.82,4.17,8.47
				c-1.38,3.63-0.72,6.39,4.12,7.62c4.84,1.22,8.3,4.68,9.2,10.12c0.35,2.09,0.82,4.48,2.08,6.04c3.56,4.4,3.79,8.94,1.98,14.01
				c-0.63,1.77-1.38,3.62,0.94,5.25c7.34,5.15,15.17,9.56,24.26,9.34c5.85-0.14,10.71,1.69,15.65,3.95c4.53,2.07,8.93,4.42,13.36,6.7
				c1.52,0.78,2.87,1.39,4.67,0.5C771.57,1484.69,774.52,1484.29,777.65,1484.01z M67.08,1389.93c0.65-10.9-1.66-12.47-11.1-7.89
				c-4.9,2.38-8.69,0.09-8.81-5.95c-0.09-4.29-2.11-6.02-5.84-6.52c-2.13-0.29-4.29-0.32-6.43-0.43c-2.62-0.14-5.4-0.82-6.34-3.26
				c-2.04-5.3-5.96-4.71-9.75-3.35c-3.72,1.34-5.89,0.22-7.03-3.11c-1.55-4.56-5.22-5.95-9.52-7.38c0,20.86,0,41.29,0,62.46
				c5.76-3.15,10.79-4.17,15.74,0.71c1.51,1.49,4.17-0.28,5.47-2.15c8.08-11.65,9.87-11.61,17.79,0.7c1.04,1.61,2.36,2.74,4.17,3.36
				c1.15,0.4,2.19,0.49,3.31-0.22c7.61-4.78,7.62-4.76,14.75,0.54c2.98,2.21,5.97,3.67,9.12-0.13c-1.51-2.72-4.37-5.49-4.08-7.88
				c0.62-5.07-2.36-8-4.6-11.5C61.18,1393.65,61.8,1391.83,67.08,1389.93z M955.25,559.25c-15.82-0.98-29.87-6.72-44.65-8.88
				c-10.65-1.56-21.76-1.25-32-4.12c-13.66-3.83-27.69-6.36-40.86-12.43c-11.9-5.48-24.88-6.66-37.6,1.29
				c4.95,1.94,9.02,4.51,12.43,8.31c4.65,5.19,9.97,9.77,14.23,15.35c1.31,1.71,2.91,3.61,5.01,3.97c7.67,1.33,14.97,4.9,22.91,4.33
				c7.59-0.54,14.88,0.46,22.14,2.51c3.01,0.85,6.27,2.06,9.26,0.57c7.57-3.77,15.69-3.74,23.8-4.03c3.99-0.14,8.21,0.07,11.72-1.9
				c7.7-4.32,15.57-3.15,23.2-0.78C949.4,564.88,952.06,563.7,955.25,559.25z M241.8,1054.53c-3.62,6.05-9.07,1.53-13.4,2.99
				c3.72,6.01,9.11,9.06,15.91,11.18c-2.31,1.91-2.02,3.96-1.88,5.72c-2.93,1.46-5.83-0.27-8.52,0.73c1.99,5.37,7.99,5.26,11.01,8.61
				c-0.56,2.19-2.89,1.3-3.57,2.76c0.34,1.69,3.04,1.61,2.79,3.97c-2.42,1.9-5.16-0.71-8.01,0.05c1.81,2.5,3.6,4.38,5.93,5.65
				c4.27-3.43,8.19-6.89,5.74-13.15c1.39-1.79,4.04-2.54,4.41-5.73c-4.14-2-2.78-6.14-0.97-8.18c3.6-4.05,6.82-8.21,9.09-14.87
				c2.5,6.02,4.73,10.18,8.56,13.33c1.98,1.63,1.12,3.7-1.08,4.72c-1.18,0.55-2.62,0.51-3.95,0.74c1.61,3.92,7.61,3.84,7.25,8.71
				c-0.95,1.62-2.65,1.06-3.52,1.86c0.39,3.66,2.54,6.27,4.73,8.35c-0.16,1.8-2.47,0.79-2.16,2.88c3.62-0.63,4.73-3.58,6.53-6.21
				c-2.42-0.83-5.43-0.17-6.16-3.66c4.05-2.12,7.7-4.6,9.44-9.56c-3.95,0.41-3.92-1.73-4.55-4.63c-2.02-9.22-4.29-18.39-8.19-27.12
				c-5.6-12.54-10.69-25.3-16-37.97c3.26,10.36,5.65,20.84,6.2,33.03c-4.34-4.93-5.63-9.86-7.28-14.6
				c-1.63-4.67-3.26-9.34-4.88-14.01c0.65,10.68,3.6,21.03,2.99,32.66c-1.57-1.11-2.21-1.38-2.58-1.86
				c-15.24-19.29-21.2-43.22-32.52-66.12c0.86,11.29,3.94,20.6,5.92,30.12c0.36,1.75,1.39,3.86-0.66,4.97
				c-1.99,1.07-3.47-0.84-4.59-2.1c-2.6-2.91-4.91-6.07-8.13-10.12c-1.26,21.11-6.89,40.89-1.53,61.53c3.75-2,7.3-3.89,11.05-5.9
				c-2.34-2.32-5.85,0.26-7.52-2.83c1.37-3.27,6-2.78,7.35-6.25c-2.67-1.61-5.38-3.26-9.37-5.67c8.83-2.37,13.16-8.5,17.76-15.74
				c3.37,8.23,7.51,14.72,14.24,18.96c-2.27,4.32-7.38,0.39-9.22,4.37C232.15,1049.94,236.84,1052.07,241.8,1054.53z M633.14,1423.83
				c0.05,2.67,1.28,3.69,2.12,4.88c1.19,1.71,0.97,3.49-0.63,4.35c-5.33,2.85-3.06,7.19-1.35,10.22c2.12,3.77,1.87,6.51-0.76,9.4
				c-2.31,2.55-1.47,4.2,1.36,5.21c9.44,3.35,17.81,8.86,26.83,13.08c11.7,5.47,24.28,8.36,36.75,10.38
				c7.92,1.28,15.56,1.84,22.39,6.41c1.91,1.28,4.06,1.01,6.23,0.28c2.83-0.95,5.83-2.24,8.75-1.46c9.3,2.51,19.01,0.51,28.31,2.8
				c0.62,0.15,1.11-0.55,1.24-1.83c-7.58-3.59-15.06-7.81-23.33-10.31c-1.07-0.33-2.3-0.27-3.45-0.2
				c-7.37,0.45-14.25-1.29-20.45-5.14c-10.3-6.39-20.6-12.81-30.57-19.7C670,1440.72,651.39,1433.2,633.14,1423.83z M19.73,1163.1
				c-2.4-2.86-4.14-6.01-3-9.66c0.76-2.42,0.61-4.3-0.52-6.58c-1.51-3.04-0.78-6.23,1.15-8.95c1.91-2.69,4.52-2.2,7.2-1.26
				c0.7,0.25,1.57,0.04,2.12,0.04c2.12-3.76,2.9-7.63,5.71-10.76c2.86-3.19,7.37-3.48,10.3-6.56c-1.37-2.46-2.78-4.53-3.72-6.79
				c-2-4.81-0.8-8,4.17-9.37c6.4-1.76,7.63-6.39,8.14-12.91c-15.88-6.74-31.45-14.64-49.08-17.8c0,31.55,0,62.76,0,93.73
				c2.87,1.45,5.69-0.72,8.13,0.65C14.72,1169.36,17.44,1167.61,19.73,1163.1z M606.6,1387.72c-2.08-8,0.57-15.4-0.26-22.84
				c-1.53-13.72-2.74-27.58-0.81-41.3c1.53-10.9-0.87-19.36-9.75-26.4c-10.21-8.1-14.95-18.88-12.68-32.18
				c0.56-3.27,0.95-6.58,1.46-9.86c1.87-11.98,4.45-23.89,3.43-36.15c-0.15-1.83,0.46-4.21-2.98-3.96
				c1.06,13.7-5.16,23.99-15.08,32.57c-2.38,2.06-4.64,4.29-6.76,6.63c-6.25,6.89-7.26,14.75-3.66,23.16
				c3.23,7.55,7.72,14.31,13.64,20.09c2.01,1.96,4.46,3.88,5.54,6.33c6.17,13.98,15.81,26.57,16.26,42.88
				c0.28,10.27,2.36,20.49,0.23,30.79c-0.52,2.49-2.12,4.84-1.27,8.4C598.45,1383.68,602.49,1384.58,606.6,1387.72z M656.1,1498.59
				c-6.16-4.27-13.24-5.05-20-7c-26.07-7.52-52.97-12.32-77.38-25.38c-13.99-7.49-29.3-12.52-44.02-18.63
				c-1.65-0.69-3.38-1.57-5.23,0.28c0.08,2.33,0.91,4.41,2.91,5.9c2.76,2.06,5.41,3.95,3.01,8.1c-0.87,1.5,0.43,3.16,1.46,4.52
				c2.25,2.98,5.21,5.16,8.36,7.03c13.42,8.01,26.9,15.93,40.35,23.88c1.3,0.77,2.6,1.33,4.2,1.33
				C598.53,1498.59,627.32,1498.59,656.1,1498.59z M459.81,727.21c3.09,12.72,0.59,25.06,5.4,36.02c3.22-7.43,2.09-15.05,2.19-22.58
				c0.03-2.32,0.53-4.66,1.8-6.67c0.97-1.53,2.02-2.79,4.33-2.66c4.29,0.23,6.05-1.84,5.18-5.95c-1.76-8.26-4.04-16.41-4.69-25.46
				c-5.34,1.89-7.99-1.98-11.07-4.67c-1.04,1.21,0.24,3.11-1.97,3.6c-0.68-0.86-1.4-1.78-2.46-3.13c0.48,7.6,2.45,14.52,0.8,22.7
				c-3.53-3.05-7.03-3.18-10.44-3.56c-2.73-0.3-4.18-1.76-4.6-4.38c-1.63-10.38-6.66-19.74-8.92-29.94c-0.2-0.9,0.11-2.49-1.66-2.49
				c-1.44,7.21-2.25,14.45-2.31,21.89c-1.9,0.33-3.53-0.01-5.29,0.56c-4.03,1.3-7.86-1.78-7.66-5.92c0.19-3.95-1.62-6.92-3.61-10.24
				c-2.2,1.23-4.53,0.37-6.91,0.78c0.18,3.78,0.59,7.26,0.46,10.71c-0.26,7.02-5.57,10.42-11.11,7.32c-2.97,4.61-2.66,5.63,2.37,7.08
				c3.37,0.97,5.86,3.17,8,5.72c5.66,6.74,13.15,10.28,21.22,13.19c8.37,3.02,13.89,9.59,17.59,17.29c1.8,3.75,3.91,4.2,8.39,3.86
				C448.47,740.98,456.15,735.42,459.81,727.21z M121.27,1038.76c-0.39-2.44,1.45-4.2,0.76-7.05c-2.17-8.9-8.69-16.39-8.21-26.34
				c-0.76,4.37-3.7,7.17-5.2,9.39c1.64,11.08-3.71,19.18-7.61,27.66c2.14,0.48,3.65-1.97,5.79-0.83c0.19,2.5-2.05,2.79-3.34,3.83
				c-1.76,1.42-3.25,3.69-2.02,5.24c2.93,3.72,0.26,8-1.04,9.25c-3.96,3.8-4.23,8.85-6.47,12.75c0.52,2.06,3.12,2.58,2.65,4.5
				c-1.27,1.76-2.68-0.32-3.77,0.7c1.11,3.07,4.81,3.91,6.17,7.05c-2.98,1.9-5.15-1.43-7.65-0.71c-0.06,3.1,2.61,4.97,3,7.87
				c0.76,5.68,3.8,10.43,7.81,14.24c3.05,2.9,3.56,4.95,0.2,7.45c-1,0.75-2.16,1.27-3.42,1.98c2.09,2.03,5.93,1.93,5.8,5.73
				c-0.12,3.6,2.22,5.33,5.08,6.64c9.79,4.51,19.57,9.01,30.06,13.83c-1.64-3.72-1.02-8.24-4.32-9.7c-4.5-1.99-8.59-4.96-13.6-5.94
				c-6.66-1.3-12.38-9.34-11.18-16.53c1.74,11.26,10.84,13.51,19.14,16.92c2.43,1,4.65,2.47,7.47,2.77c0.29-1.54,0.58-3.05,0.89-4.65
				c-3.05-2.28-6.36-4.6-7.94-8.1c-4.13-9.17-13.03-13.35-19.59-20.04c-2.66,3.61-8.64,0.8-11.28-5.1c3.34,0.58,5.79,7.67,9.62,0.89
				c5.13,4.4,9.96,8.55,14.79,12.7c-1.96-3.41-5.08-5.94-5.32-10.64c3.85,2.61,7.71,5.22,12.73,8.61c-3.25-5.05-7.46-7.67-8.48-13.54
				c5.66,2.02,9.54,6.02,14.6,7.12c0.46-3.11-0.7-5.29-2.98-7.29c-4.94-4.33-6.51-11.12-11.18-15.79c-2.07-2.06-0.97-3.94,2.18-3.81
				c-2.34-3.69-6.33-5.87-6.53-10.46c2.77-1.04,4.11,2.04,5.24,1.89c1.63-9.4-6.2-15.34-8.65-23.33
				C118.2,1036.93,119.08,1038.96,121.27,1038.76z M726.5,1240.4c-1.05-0.48-2.08-0.74-2.83-1.34c-3.54-2.84-5.34-1.04-6.53,2.37
				c-0.38,1.1-0.82,2.25-0.84,3.38c-0.16,8.48-1.27,16.73-4.22,24.79c-1.96,5.36-1.03,11.22-1.27,16.86
				c-0.09,2.13-0.28,3.79-2.96,4.39c-2.02,0.45-4.33,1.38-5.61,2.88c-2.27,2.65-6.86,2.44-7.84,6.24
				c-1.69,6.58-2.73,13.28-2.67,20.78c10.1-5.58,19.32,2.38,28.1,1c6.09-5.37-2.18-12.97,4.7-17.72c-4.88-3.96-2.59-6.71,1.05-9.2
				c1.1-0.75,2.17-1.68,3.4-2.07c5.56-1.8,8.28-7,12.55-10.35c2.13-1.68,1.26-4.44-0.29-6.29c-3.73-4.44-5.71-15.73-2.28-20
				c3.03-3.76,3.44-7.81,3.61-12.02c0.26-6.24,2.53-12.33,1.76-18.54c-2.63-0.67-4.48-0.24-6.58,0.84
				c-5.68,2.89-12.36-0.96-12.04-7.14c0.24-4.55-0.56-9.24,1.27-13.83c-3.03,0.31-3.78,2.25-3.73,4.5c0.08,2.98-1.19,5.21-3.51,6.77
				c-3.88,2.61-2.6,4.73,0.44,6.76c2.94,1.96,5.7,4.04,6.52,7.54c-0.38,2.18-3.04,2.34-3.28,4.39c5.7,3.75,5.41,3.74,4.94,11.71
				c-0.72,12.11-3.86,23.95-3.34,36.24c0.19,4.48-3,8.83-6.62,11.64c-4.91,3.81-11.36,4.95-16.5,8.61c2.44-4.35,6.43-6.53,10.76-8.1
				c5.76-2.09,9.86-7.05,9.79-12.04C722.24,1269.01,726.01,1255.09,726.5,1240.4z M13.7,1053.83c2.66,4.78-3.36,5.58-3.35,8.19
				c0.01,4.74-4.73,4.54-6.38,7.39c4.57,3.1,10.22,1.68,14.85,4.08c6.5,3.37,13.43,5.73,20.28,8.27c3.83,1.42,7.94,2.47,11.13,5.38
				c5.22,4.76,6.57,4.53,10.28-1.6c-2.82-3.61-5.94-7.06-7.72-12.57c-1.45,3.69-3.74,3.53-6.02,3.01c-4.86-1.12-9.77,0.09-14.85-1.28
				c-5.57-1.5-8.28-2.5-9.21-9.72c3.39,3.45,4.61,7.51,10.67,7.83c4.39,0.23,8.93-2.73,13.04,0.33c0.57-1.32-0.8-2.26,0.36-3.36
				c3.93-0.57,7.08,2.83,11.45,3.17c-0.61-1.03-1.04-1.54-1.25-2.13c-3.17-8.82-8.39-14.67-18.66-15.28
				c-3.62-0.21-7.82-1.72-10.44-5.28c7.74,2.53,16.07,2.43,23.6,6.78c-0.35-2.84-3.57-4.27-1.4-7.03c1.89,0.47,3.79,0.93,5.7,1.4
				c1.05-1.64,2.15-3.08,2.21-5.37c-6.67-3.12-8.84-11.21-16.18-13.9c2.02-2.26,3.37,0.36,5.02-0.92c-3.51-3.58-2.23-9.15-6.24-13.28
				c3.34,1.13,5.52,1.13,5.86-2.34c-2.13-2.57-5.44-4.34-5.74-8.2c2.72-1.6,4.42,1.22,8.16,1.13c-4.45-6.36-9.28-11.96-8.82-19.71
				c-4.53,6.42-5.53,14.71-13.14,18.65c1.5,0.97,3.61,0.41,3.73,2.53c0.41,7.22-6.08,8.96-10.57,12.36c2.01,1.23,4.08-0.28,6.3,2.01
				c-5.59,1.96-6.32,7.88-10.96,10.93c2.58,1.62,5.08,0,7.41,1.24c2.71,8.73-7.19,8.03-9.75,12.78c2.11,1.63,5.09-0.69,7.89,1.82
				C18.31,1052.14,16.22,1052.9,13.7,1053.83z M227.68,1192.69c3.77,3.38,8.72,2.88,12.17,5.5c12.67,9.62,27.45,15.65,40.8,24
				c14.96,9.36,29.91,18.52,46.19,25.39c1.89,0.8,3.8,1.9,5.67,0.82c6.19-3.57,12.7-3.95,19.53-2.64c1.44,0.28,3.04,0.1,3.95-1.57
				c-0.11-1.62-0.34-4.12-1.68-4.34c-4.61-0.77-8.11-4.07-12.84-4.71c-6.55-0.87-12.08-6.09-19.24-4.96
				c-0.12,0.02-0.31-0.15-0.4-0.27c-2.2-2.89-5.43-3.4-8.7-4.22c-2.79-0.7-6.38-1.94-5.44-5.87c1.25-5.25-1.04-5.03-4.65-3.76
				c-0.93,0.32-1.96,0.6-2.65-0.55c-0.04-0.32-0.25-0.77-0.1-0.95c3.32-4.15,7.77-8.3-0.26-12.21c-0.08-0.04-0.11-0.32-0.07-0.46
				c1.49-4.58-1.78-4.76-4.81-5.39c-1.89-0.39-2.95-2.04-4.17-3.42c-4.44-5.02-11.87-5.11-16.88-0.39c-4.14,3.9-10.6,5.27-14-0.39
				c-0.57-0.94-2.07-2.39-2.46-2.2c-5.26,2.53-10.4-0.73-15.19-0.26C237.83,1190.27,232.7,1190.6,227.68,1192.69z M693.45,603.24
				c3.36-2.05,5.67-1.8,7.42,1.4c1.04,1.89,2.65,3.52,3.42,5.49c3.12,8.05,9.24,12.75,16.98,15.69c1.98,0.75,2.33,2.43,2.44,3.99
				c0.59,8.73,3.12,17,6.12,25.08c2.09,5.61-0.46,11.2,0.87,16.73c3.07,12.77,1.76,26.13,5.52,38.89c2.63,8.9,1,18.11-0.27,27.18
				c-0.42,2.98-0.75,6.65,2.16,8.23c4.59,2.49,4.5,6.55,4.83,10.6c0.22,2.71,0.04,5.46,0.04,8.19c3.2-4.01,6.74-6.78,10.94-8.35
				c2.67-1,3.05-2.48,2.94-4.87c-0.4-8.64-0.78-17.29-0.94-25.94c-0.06-3.01-0.69-5.25-3.31-7.15c-3.13-2.27-4.51-5.79-4.27-9.66
				c0.19-3.01-0.49-5.71-2.5-7.82c-4.36-4.6-5.85-10.31-5.04-16.15c1.83-13.24-3.95-25.11-6.08-37.61
				c-0.98-5.78-2.1-11.34-1.17-17.25c1.17-7.47,0.76-14.9-5.74-20.02c-5.02-3.95-8.72-9-13-13.56c-1.94-2.06-3.73-4.26-6.31-7.23
				c3.53,11.51,7.1,21.33,13.01,30.03c-5.27,2.96-9.39,2.1-11.47-3.31c-2.63-6.84-7.36-12.62-9.26-19.75
				c-0.83-3.13-2.22-6.26-5.55-7.19c-3.8-1.07-7.34-2.76-11.77-4.45c1.2,3.98-0.53,7.04-0.82,9.86c7.2,8.13,11.31,17.98,18.21,25.82
				C700.94,613.39,696.31,608.95,693.45,603.24z M774.68,753.39c1.84-15.2,0.4-29.71,0.94-44.16c0.17-4.63-2.16-8.03-6.9-9.9
				c-4.04-1.59-7.02-4.86-6.88-9.53c0.32-10.37-2.55-20.49-2.17-30.82c0.43-11.5,0.09-22.87-4.73-33.29
				c-2.29,2.58,0.31,7.33-3.16,10.77c-1.41-3.02-0.7-5.69-0.85-8.24c-0.33-5.79-0.76-6.19-6.86-5.1c-0.43,8.74-1.19,17.91,2.62,25.9
				c3.68,7.71,2.87,14.94,2.13,22.78c-0.95,10.16,2.05,19.46,8.22,27.81c2.98,4.03,7.17,8.09,7.57,12.96
				c0.86,10.59,1.9,21.24,1.2,31.91c-0.11,1.64-0.01,3.34-1.71,5.49c-0.6-11.17-1.62-21.3-1.52-31.41
				c0.08-7.18-3.95-12.15-7.49-17.28c-6.68-9.66-10-19.9-8.67-31.68c0.55-4.86,2.66-9.92,0.12-14.7c-5.15-9.69-4.98-20.17-5.16-30.65
				c-0.05-3.07,1.17-4.57,5.76-4.24c-4.59-0.99-7.6-1.64-11.26-2.43c1.08,7.8-0.58,15.06-0.03,22.37c0.12,1.64,0.4,3.31,0.89,4.89
				c2.49,8.1,3.14,16.62,6.09,24.65c1.27,3.46,1.13,7.43,0.64,11.34c-0.81,6.47-0.68,12.9,4.21,18.29c2.48,2.73,3.77,6.09,3.32,10.06
				c-0.39,3.39,1.15,6.31,4.06,8.04c2.5,1.49,3.14,3.54,3.31,6.19c0.6,9.31,0.82,18.63,0.57,27.95c-0.05,2.06-0.77,4.04,2.1,5.36
				c2.69,1.24,4.5,0.56,6.22-1.17C769.29,753.5,771.5,752.43,774.68,753.39z M156.87,1139.17c0.97,1.42,2.68,2.77,2.67,4.1
				c-0.04,6.87,4.12,8.32,9.75,8.28c1.55-0.01,2.78,0.88,4.12,1.56c1.97,0.99,4.18,1.45,5.68-0.94c2.38-3.78,5.25-2.67,7.32-0.16
				c3.02,3.65,5.14,2.89,7.96-0.15c4.6-4.97,9.74-4.81,15.32-0.58c4.96,3.76,10.11,8.01,16.83,7.03c3.4-0.5,4.9,0.39,6.18,3.16
				c1.6,3.45,4.08,4.13,7.18,1.76c1.31-1,3.29-3.04,4.16-2.03c3.3,3.81,6.99,3.2,11.05,2.18c0.63-0.16,1.77,0.38,2.2,0.95
				c3.34,4.49,5.32,1.58,7.79-1.4c-5.05-3.7-5.29-10.45-11.25-14.08c-3.23-1.97-5.18-4.72-9.14-3.03c-3.1-4.26-3.19-4.11-9.21-7.69
				c-2.51-1.49-4.94-3.49-6.69-5.8c-1.87-2.47-2.44-5.58-6.98-4.44c-2.93,0.73-6.33-2.1-9.46,0.56c-2.77,2.36-5.7,2.42-8.55,0.03
				c-1.83-1.54-4.23-2.32-5.95-0.81c-3.78,3.31-9.43,2.46-12.62,6.39c-5.66-3.73-5.66-3.7-12.21-2.3c-0.74,0.16-1.64-0.09-2.39-0.35
				c-4.21-1.45-8.07,0.27-12.01,1.26c-0.41,0.1-0.72,0.6-1.36,1.16c1.16,0.42,2.83-0.35,2.87,1.55
				C159.25,1136.59,157.17,1136.85,156.87,1139.17z M696.53,508.07c3.13,0.3,5.61,1.98,5.51,5.53c-0.23,7.94,3.19,15.24,3.75,23.03
				c0.35,4.77,1.13,9.5,1.71,14.2c4.95,0.24,6.77-2.32,6.05-8.66c-0.47-4.12-1.31-8.2-2.02-12.29c-0.25-1.41-0.28-2.82,1.26-3.38
				c1.83-0.67,2.74,0.73,3.42,2.1c1.28,2.57,1.47,5.41,1.91,8.2c0.96,6.05,0.24,12.74,3.36,17.96c2.77,4.64,4.87,9.34,6.62,14.34
				c0.68,1.95,1.2,4.08,2.81,5.15c10.46-5.24,2.78-15.82,6.93-22.82c7.48,1.57,6.91,7.68,7.59,13.13c3.2-1.5,3.34-5.06,5.24-6.13
				c9.42,1.72,12.28,13.32,22.52,13.73c-0.25-2.52-1.7-3.54-2.72-4.77c-5.73-6.93-11.38-13.94-15.31-22.07
				c-3.33-6.88-7.45-12.89-14.17-16.92c-1.63-0.98-3.03-2.68-4-4.35c-2.58-4.47-6.26-7.64-10.6-10.28
				c-7.86-4.79-15.09-10.3-20.14-18.23c-1.52-2.38-3.18-4.67-4.85-6.94c-1.31-1.78-3.21-2-4.82-0.76c-1.35,1.04-1.99,2.72-0.45,4.32
				c1.87,1.95,1.44,4.19,1.1,6.56C696.78,501.64,695.81,504.55,696.53,508.07z M1112.26,751.06c16.36,2.85,31.74,9.92,48.74,8.4
				c0-24.41,0-48.56,0-72.72c-0.38-0.25-0.75-0.5-1.13-0.75c-3.01,11.25-13.97,14.69-20.9,22.25c-7.91,8.62-13.55,17.81-17.76,28.35
				C1119.19,741.63,1117.46,746.92,1112.26,751.06z M631,1195.25c-1.52,2.05-2.97,4.05-4.47,6.02c-3.82,5.01-8.94,9.11-8.38,16.62
				c0.56,7.62,0.04,15.29,0.57,22.96c0.56,8.11,1.77,16.48-0.6,24.33c-1.93,6.39-2.67,12.56-2.2,19.1
				c0.86,11.95,1.6,23.92,2.18,35.89c0.32,6.68,0.42,6.94,7.75,6.64c-0.16-1.97-0.34-3.94-0.46-5.9
				c-0.82-14.22,0.96-28.14,4.91-41.79c0.45-1.57,1.05-3.22,2.56-4.17c2.03-1.28,1.91,2.05,3.68,1.96
				c0.49-8.37,0.76-16.53,1.48-24.64c0.74-8.28,1.87-16.52,2.92-24.77c0.93-7.32,1.92-14.53-3.79-20.74
				c-1.05-1.15-1.34-3.54-1.1-5.23C636.64,1197.33,634.42,1195.89,631,1195.25z M1153.04,1498.61c0.2-6.87-2.03-10.79-6.48-10.65
				c-4.67,0.15-9.3,0.55-14,0.51c-2.86-0.03-4.14-0.51-6.09-2.58c-5.79-6.14-14.58-7.28-21.44-11.78c-0.35-0.23-1.2,0.43-1.83,0.5
				c-2.33,0.26-4.26,0.29-6.03-2.12c-2.52-3.45-6.07-3.81-9.5-0.88c-1.05,0.9-2.37,2.21-3.44,1.3c-4.34-3.67-7.22-1.1-9.92,2.02
				c-4.97-1.99-5.06-2.07-8.45,1.66c-1.19,1.31-2.38,1.62-3.95,1.46c-1.7-0.17-3.44-0.16-4.55,1.38c-1.9,2.64-4.21,2.53-7.05,1.77
				c-2.71-0.72-5.43-0.42-6.33,3.05c-0.92,3.57,2.12,3.62,4.11,4.35c18.16,6.7,36.45,11.87,56.3,10.24
				c13.06-1.07,26.29-0.2,39.44-0.2C1146.8,1498.61,1149.77,1498.61,1153.04,1498.61z M76.7,529.4c0.39,0.25,0.78,0.49,1.16,0.74
				c3.61-6.48,7.21-12.97,10.84-19.45c1.46-2.6,2.25-5.87,4.83-7.45c9.24-5.68,14.29-14.55,19.18-23.72
				c1.99-3.74,3.99-7.63,7.98-9.99c8.6-5.09,14.23-12.9,18.69-21.58c2.72-5.29,1.44-7.34-4.67-7.39
				c-13.46-0.12-26.92,0.01-40.38,0.07c-0.26,0-0.51,0.26-0.77,0.4c0.17,1.12,1.15,0.98,1.86,1.26c6.57,2.57,6.97,2.95,4.25,9.22
				c-3.29,7.59-7.26,14.78-8.28,23.3c-0.87,7.22-1.84,14.86-4.94,21.27C81.27,506.8,80.3,518.41,76.7,529.4z M656.41,907.54
				c-0.6,0-1.25-0.05-1.88,0.01c-4.67,0.45-11.04,8.05-8.84,11.12c3.84,5.35,5.01,11.45,6.37,17.64c3.42-0.61,7.03,2.44,9.54-2.09
				c2.13-3.84,8.91-6.07,13.18-4.95c8.23,2.16,14.79,5.82,14.65,15.82c-0.02,1.57,0.69,3.15,1.21,5.32c1.98-2.28,3.28-4.76,6.09-4.78
				c0.65-5.61-2.79-10.69-1.14-16.54c3.48,2.47,3.36,6.55,6.56,8.67c2.47-1.57,6.06,0.21,8.55-2.41c-1.25-5.92-2.48-11.72-3.71-17.54
				c-7.05,1.83-9.35,0.84-13.1-5.1c-2.06-3.25-3.73-6.67-4.87-10.34c-2.57-8.25-5.13-16.51-7.75-24.74
				c-0.39-1.22-0.07-3.27-3.11-2.77c1.09,6.55,2.18,13.06,3.26,19.58c0.52,3.12-3.48,11.03-6.49,11.36c-4,0.44-6.56,2.41-8.51,5.55
				c-2.54-1.28-2.73-3.64-3.83-5.41c-1.31-2.11-0.6-6.52-3.74-6.14C654.75,900.28,658.07,904.62,656.41,907.54z M66.91,1047.83
				c-5.38,0.72-6.77,7.39-12.19,6.71c0.38,2.57,4.1,2.21,3.75,4.84c-0.92,1.61-3.09-0.13-3.76,1.58c2.3,5.65,8.27,7.53,11.9,11.62
				c-2.65,3.06-5.78,3.89-9.86,3.71c3.2,3.23,4.81,6.86,9.26,8.34c0.31-4.89,1.74-9.04,3.97-12.89c1.16-1.99,2.92-3.38,5.24-3.3
				c2.48,0.08,3.15,2.42,3.54,4.24c0.9,4.2,2.14,7.82,6.71,9.48c1.52,0.55,2.09,2.45,2.3,4.2c0.16,1.35,0.76,2.46,2.7,2.47
				c-1.31-3.12-2.56-6.07-3.45-8.18c0.54-2.55,2.01-0.53,2.63-1.67c-0.61-2.86-4.36-4.05-4.25-7.63c1.13,0.24,1.98,0.42,3.02,0.65
				c-1.42-3.11-6.24-5.03-2.5-9.19c-1.8-3.32-5.69-5.51-5.73-9.81c2.42-0.98,3.53,1.98,5.59,1.33c-3.11-5.7,1.31-12.96-5.54-17.71
				c3.76-0.95,5.4,1.8,8.05,1.22c-4.13-4.04-8.32-7.85-10.12-13.34c2.46-1.25,3.44,0.9,5.33,0.4c-1.56-2.46-1.15-4.02,1.35-3.58
				c-0.66-6.2-4.31-11.35-5.57-17.71c5.02,1.4,7.31,0.02,6.47-3.72c-1.02-4.5-2.78-8.83-4.36-13.66c-6,10.94-7.22,23.13-13.3,33.42
				c0.57,0.43,1.91-0.45,2.52,1.31c-4.12,3.68-8.37,7.49-12.69,11.35c1.23,0.97,3.13,0.69,2.83,2.95c-2.9,2.41-6.5,0.28-10.01,0.82
				C54.69,1042.25,60.9,1044.65,66.91,1047.83z M818.82,1284.06c-4.25,2.21-7.73,3.83-11.49,4.39c-4.44,0.65-5.59,3.2-5.45,7.3
				c0.24,6.98,0.11,13.98,0.2,20.97c0.04,3.12,0.1,5.86,3.58,7.94c2.29,1.36,4.41,4.17,5.18,7.11c2.39,9.08,1.41,18.37,2.02,28.41
				c-4.94-2.49-6.15,0.47-7.19,3.63c-2.28,6.9-2.17,14.09-2.55,21.22c-0.08,1.53,0.51,2.51,1.8,3.23c5.78,3.21,11.55,6.45,17.87,9.99
				c1.18-8.29,1.86-16.1-0.64-23.6c-1.34-4.02-1.45-7.68-0.73-11.67c2.78-15.58,0.02-31.17-0.06-46.76
				c-0.01-1.13-0.22-2.47-0.84-3.36c-3.55-5.11-2.64-10.79-2.34-16.41C818.36,1292.63,818.57,1288.82,818.82,1284.06z M67.62,1498.43
				c1.96-3.55-0.87-6.12-0.89-8.96c-0.07-12.53-5.88-23.04-11.64-33.52c-4.68-8.53-8.6-17.47-10.18-26.92
				c-1.17-7.02-4.68-12.42-8.01-18.15c-1.37-2.36-3.66-4.75-6.35-3.17c-2.33,1.37-4.39,3.71-2.95,7.53
				c1.87,4.95,3.06,10.17,4.26,15.34c3,12.93,3.64,26.45,8.8,38.82c3.8,9.1,7.62,18.04,7.29,28.15c-0.01,0.23,0.4,0.48,0.76,0.89
				C54.86,1498.43,61.14,1498.43,67.62,1498.43z M2.29,1294.82c1.96-0.21,3.8,0.15,4.82-0.6c5.15-3.81,9.01-1.66,13.13,1.9
				c2.6,2.24,5.59,1.91,7.69-1.26c1.02-1.54,1.96-3.4,4.01-3.4c6.92-0.02,13.85,0.03,20.76,0.41c1.51,0.08,2.94,0.96,3.83-0.75
				c1.07-2.04-0.69-2.96-1.95-3.97c-1.26-1.01-2.77-1.03-4.27-0.93c-4.95,0.32-8.38-1.78-10.03-6.46c-1.17-3.31-3.27-5.64-6.28-7.4
				c-6.45-3.78-9.69-10.45-11.24-16.9c-1.24-5.17-3.6-6.8-7.76-7.64c-4.29-0.86-8.64-1.44-12.72-5.79
				C2.29,1260.51,2.29,1277.35,2.29,1294.82z M947.8,566.1c-7.36-1.48-14.7-4.09-21.94-0.96c-5.35,2.32-10.65,3.37-16.38,3.48
				c-6.46,0.13-13.21,0.12-19.09,2.74c-4.62,2.06-8.73,2.41-13.43,1.39c-5.33-1.16-10.53-3.5-16.11-2.84
				c-6.74,0.79-13.25,0.08-19.7-1.83c-1.19-0.35-2.41-1.2-3.66-0.09c-0.02,1.53,1.05,2.44,1.88,3.43
				c7.58,9.01,16.53,15.95,27.23,21.28c6.61,3.29,11.11,2.96,16.44-1.35c3.5-2.82,7.53-5.03,12.33-4.6c4.8,0.43,9.23-0.99,12.33-4.32
				c8.74-9.4,19.57-10.98,31.4-9.31C944.14,573.84,947.18,572.25,947.8,566.1z M740.34,521.13c-5.46-7.82-5.51-16.07-2.58-24.66
				c0.3-0.89,0.09-1.99-0.04-2.97c-1.49-11.09-3.91-21.66-11.44-30.72c-3.18-3.83-5.73-4.63-9.44-2.17
				c-6.21,4.12-13.19,6.15-20.22,8.16c-1.21,0.35-2.65,0.52-3.68,2.18c1.07,3.33,2.22,6.93,3.37,10.53c0.26,0.82,0.7,1.7,1.61,1.68
				c4.62-0.13,6.05,3.73,8.01,6.61c6.09,8.91,13.13,16.67,23.09,21.39C733.61,513.32,737.28,516.63,740.34,521.13z M74.09,1446.43
				c3.52-8.26,4.69-16.51,3.58-25.12c-0.83-6.44-0.77-12.92,0.54-19.38c1.64-8.07,2.02-16.05-1.7-23.87
				c-1.47-3.09-2.07-6.67-2.67-10.1c-1.3-7.41-1.99-14.86-0.22-22.39c2.28,12.32,2.06,25.11,7.56,38.03
				c2.67-11.49,4.62-21.73,4.57-32.3c-0.02-3.32-1.72-5.27-4.49-5.64c-6.67-0.89-13.25-3.37-20.34-1.37c-1.45,3.68-1.56,8-3.63,11.65
				c-2.05,3.62-4.03,7.29-6.39,10.7c-2.15,3.11-1.22,6.34-1.07,9.57c0.17,3.73,2.2,4.57,5.39,3.32c11.45-4.48,14.74-2.21,14.54,10.11
				c-0.02,0.97-0.14,1.94-0.18,2.35c-1.31,2.13-3.75,0.04-4.72,2.54c1.15,1.73,2.45,3.63,3.69,5.56c1.24,1.94,3.74,3.88,2.99,6.05
				c-1.04,2.98,0.32,4.75,1.64,6.78c2,3.1,3.11,6.05-0.9,8.48c-0.96,0.59-1.25,1.36-1.09,2.51
				C72.18,1431.41,73.12,1438.92,74.09,1446.43z M89.52,1485.74c2.77-13.97,11.62-24.71,18.71-36.43
				c5.22-8.63,8.82-18.09,12.51-28.15c-12.78-0.86-25.1,0.64-37.4-2c2.18,9.76,1.76,19.59-2.1,29.11c-0.72,1.78,0.02,3.23,0.57,4.77
				C85.52,1463.57,87.9,1474.4,89.52,1485.74z M52.16,448.55c-9.66,2.98-18.39,6.04-27.3,1.08c-2.22,6.13-4.22,11.65-6.57,18.14
				c1.46-1.24,2.12-2.28,3.48-1.32c0.69,6.48-3.17,12.18-4.45,18.76c3.47-1.91,4.47-5.9,9.18-7.23
				c-4.76,14.84-11.97,28.35-10.93,43.78c2.14-0.07,3.06-2.78,5.17-2.29c1.75,1.18,0.53,3.24,2.55,5.66
				C32.96,499.48,42.36,474.56,52.16,448.55z M511.54,429.64c-3.81,0.45-7.43,1.4-11.04,0.68c-5.52-1.09-10.8-3.38-16.58-3.17
				c-1.18,0.04-2.44-0.62-2.75-1.83c-1.16-4.57-4.99-4.45-8.34-4.6c-6.48-0.3-12.98-0.37-19.46-0.2c-4.26,0.11-8.16-2.24-12.27-1.82
				c-5.97,0.6-11.83,0.98-17.84,0.31c-3.03-0.34-6.08,0.99-7.48,5.21c8.26-0.26,15.95,1.59,23.77,0.6
				c9.19-1.17,18.22-0.32,27.25,1.62c4.44,0.96,7.68,2.78,9.02,7.31c0.83,2.82,2.82,3.81,5.7,4.68c20.26,6.12,41.23,8.55,61.9,12.51
				c1.25,0.24,2.59,0.78,4-0.56c-1.96-5.03-2.22-10.56-3.58-15.86c-0.39-1.52-0.09-4.29-2.8-3.86c-3.17,0.5-1.66,3.13-1.45,4.85
				c0.53,4.5,1.27,8.98,0.77,13.86c-4.12-1.76-4.74-4.92-5.29-8.02c-1.81-10.15-1.78-10.16-11.72-11.49
				c-0.45-0.06-0.93,0.13-1.68,0.24c0.66,3.17,1.33,6.21,1.93,9.26c0.36,1.83,1.87,4.13-0.82,5.2c-2.82,1.12-4.8-0.51-5.96-3.04
				C515.02,437.64,513.33,433.7,511.54,429.64z M126.11,1370.78c-0.58-2.11-0.84-3.97-1.6-5.59c-1.75-3.72-6.9-5.3-8.24-2.47
				c-2.41,5.08-6.42,4.52-10.55,4.1c-2.2-0.22-3.72-0.14-4.59,2.41c-1.98,5.76-5.54,10.56-9.8,14.87c-6.76,6.85-9.01,15.22-8.4,25.4
				c3.2-1.68,6.03-2.98,8.67-4.6c2-1.22,3.5-1.12,5.39,0.29c4.52,3.37,10.82,2.98,13.95-1.59c3.16-4.62,6.8-6.45,12.2-4.82
				c-0.37-2.07-0.62-3.7-0.96-5.31c-0.37-1.75-0.82-3.48-1.21-5.12c-5.83-0.43-8.95-3.26-8.24-7.24
				C113.48,1376.8,121.04,1370.73,126.11,1370.78z M607.47,584.63c2.83-1.13,5.07-2.02,7.31-2.91c-3.49-9.01-4.6-10.19-12.55-13.86
				c-1.05-0.48-2.23-0.98-2.95-1.82c-7.48-8.66-16.58-15.75-23.76-24.69c-1.75-2.18-3.21-4.96-6.55-4.7
				c-3.16,0.25-6.19-0.37-9.72-1.15c-0.2,5.91-1.94,11.02-4.26,16.02c-2.74,5.92,1.86,15.37,8.28,16.45
				c4.04,0.69,8.73,1.52,12.32-1.26c4.87-3.77,9.35-4.51,13.8-0.47C595.71,572,604.32,575.39,607.47,584.63z M664.71,471.62
				c1.57,6.29,5.97,8.3,10.32,10.44c3.64,1.79,6.55,4.42,8.75,7.93c1.44,2.29,2.74,4.9,5.27,5.97c5.26-6.26,4.89-12.48,2.17-19.67
				c-2.4-6.35-6.9-10.87-10.55-16.15c-3.63-5.25-7.16-10.45-14.9-9.21c-1.92,0.31-3.82-0.95-5.5-2.09c-1.46-0.99-2.94-2-4.96-1.47
				c1.45,5.01,0.9,9.94,0.37,14.89c-0.58,5.44-1.58,10.84-0.98,16.38c0.43,3.89,2.42,4.9,5.78,4.58
				C661.52,479.45,661.12,475.59,664.71,471.62z M38.69,1498.32c0.35-10.24-1.41-18.61-12.5-21.97c-4.02-1.22-6.78-4.7-9.66-7.71
				c-4.05-4.24-9.09-6.94-14.35-9.82c0,13.4,0,26.31,0,39.5C14.39,1498.32,26.3,1498.32,38.69,1498.32z M264.16,1191.77
				c-0.96-7.71,2.75-15.26-1.63-22.75c-2.24,0.81-4.28-0.23-5.97-2.33c-0.73-0.91-1.84-1.85-3-1.04c-4.16,2.89-7.38,0.64-10.31-1.95
				c-6.96,5.04-8.47,4.66-14.31-3.25c-6.75,1.66-12.15-1.59-17.54-5.22c-0.17,2.7-0.24,4.67,3.17,4.42c2.23-0.17,3.6,1.54,4.13,3.65
				c0.69,2.76,2.56,5.19,3.1,7.79c1.3,6.22,5.62,11.44,5.59,18.04c0.48,0.1,0.96,0.3,1.45,0.3c7.24,0.02,14.41-4.63,21.73-0.33
				c0.2,0.12,0.68-0.06,0.94-0.23C256.37,1185.67,260.17,1187.2,264.16,1191.77z M664.46,1177.99c0.82-6.4-0.39-11.65-0.51-16.94
				c-0.17-7.59,7.7-17.27,15.1-18.69c2.32-0.45,4.65,1.06,6.87-0.19c4.11-2.3,7.64-0.8,11.17,1.45c5.45,3.48,6.43,3.28,11-2.31
				c-6.22-3.17-11.49-7.78-18.1-10.09c-1.55-0.54-3.62-0.93-4.75,0.42c-5.59,6.67-13.76,5.08-20.83,6.33
				c-7.41,1.31-7.65,0.65-9.05,7.72c-0.19,0.96-0.33,2.13-0.93,2.79c-4.93,5.42-7.22,12.23-10.14,18.72
				c-1.09,2.43-2.61,4.83-4.83,6.28c-6.16,4.03-7.1,10.22-7.9,17.39c4-6.29,5.73-12.45,13.27-16.05
				C652.92,1170.96,657.84,1175.07,664.46,1177.99z M776.53,1406.92c-2.67-5.86-4.78-10.48-7.02-15.39
				c-4.26,2.34-8.12,1.09-11.97,0.12c-1.26-0.32-2.37-1.02-3.81-0.57c-8.86,2.82-15.96,0.24-21.36-7.21
				c-3.37-4.65-9.45-6.76-13.62-7.09c-3.62-0.29-6.05-1.51-8.55-3.03c-4.23-2.59-8.65-3.44-13.37-2.64
				c-7.4,1.24-13.27-1.35-18.41-6.49c-5.69-5.69-11.02-11.48-9.66-21.32c-3.65,3.21-7.08,4.94-9.26,8.05
				c2.62,5.04,6.34,8.81,10.36,12.34c9.38,8.26,20.43,13.4,31.96,17.95c19.46,7.67,40.22,11.47,59.24,20.4
				C765.51,1404.12,770.49,1405.05,776.53,1406.92z M260.35,1060.42c-1.44,3.61-2.77,5.89-4.74,7.75c-3.07,2.9-4.07,5.73,0.48,8.09
				c-3.23,5.05-5.21,10.13-4.68,16.03c-6.39,4.59-6.57,8.62-0.11,13.98c2.1,1.74,1.76,3.04,0.1,4.5c5.53,3.76,9.34,8.77,15.65,12.11
				c-5.96,2.72-9.67-2.94-14.26-1.36c0.33,4.99,3.77,8.71,13.08,14.15c1.14-0.85,2.67-1.3,3.19-3.01c-1.19-1.35-3.59-0.85-4.49-2.77
				c2.07-3.97,6.09-6.52,7.3-11c-0.62-1.08-2.53-0.82-2.82-2.59c0.39-1.87,3.09-2.43,3.12-4.7c-2.19-2.07-4.38-4.15-6.56-6.21
				c1.88-2.35,4.91-1.45,6.12-3.32c-1.79-5.23-6.72-7.99-9.46-12.41c2.22-1.32,3.16,1.02,4.66,0.42c0.53-3.58-3.84-5.72-3.08-9.09
				c1.38-1.53,3.18,0.83,4.18-1.08c-2.68-2.91-7.46-4.39-6.28-9.44c1.89-1.34,3.73,1.35,5.65-0.91
				C264.83,1067.04,262.08,1064.66,260.35,1060.42z M440.73,1301.02c-0.04,2.33-3.19,4.17-0.62,7.14c2.42,2.79,4.58,5.14,8.8,3.86
				c3.01-0.92,5.68-0.29,6.55,3.36c0.47,1.96,2.09,2.59,3.69,2.93c4.31,0.92,8.31,3.63,13.05,2.24c1.45-0.42,2.52,0.49,3.29,1.71
				c1.3,2.06,2.79,3.85,5.61,3.13c3.11-0.8,3.98-3.25,5.18-6.01c1.65-3.8-1.5-7.59,0.75-11.06c1.49-2.3,0.76-4.29-1.04-5.73
				c-4.42-3.54-7.28-7.85-7.73-14.19c-1.07,3.7-5.23,3.46-6.28,5.02c-3.41,5.08-6.44,9.06-13.16,5.63
				C453.41,1304.04,447.17,1299.94,440.73,1301.02z M99.48,979.64c5.6,7.55,7.24,16.01,11.91,22.75c1.53-0.36,1.3-2.7,3.77-2.84
				c1.05,10.2,9.46,15.46,15.43,22.48c1.52,0.56,1.63-2.29,3.32-1.31c1.19,0.33,0.39,2.64,1.62,2.47
				c1.25-15.47-10.08-25.95-15.27-39.1c-1.67,3.06-3.66,2.46-5.6,0.55c-2.61-2.55-3.53-5.9-4.29-9.36
				c-1.04-4.76-1.39-9.79-4.64-13.89c-2.4,1.6-1.23,4.57-2.88,6.18c-3.36,0.47-6.15-0.7-8.69-2.8c-4.83,9.56,3.28,16.46,3.85,24.72
				C98.79,986.62,97.99,983.78,99.48,979.64z M610.15,689.01c-0.45,0.24-1.22,0.44-1.25,0.71c-0.68,6.54-2.39,13.11-0.7,19.64
				c0.9,3.49,2.07,7.57,5.26,9.39c5.14,2.94,11.12,4.43,14.96,9.7c0.16-4.57-0.28-8.53-0.41-12.5c-0.14-4.4-0.33-8.52,2.64-12.71
				c3.75-5.29,3.14-12.35,2.2-18.23c-0.67-4.19-4.48-8.12-4.75-14.36c-3.6,4.49-6.23,7.82-8.92,11.09
				c-2.96,3.61-1.85,8.52-3.65,12.51C612.24,693.66,612.69,689.9,610.15,689.01z M293.22,937.6c0.8,7.36,2.36,14.11,1.58,21.06
				c-0.05,0.44,0.16,1.09,0.48,1.35c4.55,3.62,4.72,8.82,4.74,14.67c3.77-2.03,5.91,0.27,8.44,2.33c2.37-3.73,5.64-1.46,8.15-1.31
				c5.56-8.76,13.76-16.27,8.77-28.07c-1.07-0.22-3.14-0.03-4.16-0.97c-3.24-2.99-6.93-1.67-10.5-2.22
				C304.69,943.51,295.95,948.43,293.22,937.6z M947.46,1452.2c5.57,2.09,10.88,1.86,15.85-0.03c4.24-1.61,8-1.18,12.05,0.23
				c17.05,5.96,32.77,14.51,47.79,24.41c1.65,1.09,3.18,2.29,5.19,2.4c6.71-7.64,5.97-10.85-3.29-13.08c-2.92-0.7-3.67-2.76-4.3-4.88
				c-2.12-7.19-2.02-7.22-9.29-4.53c-5.08,1.88-8.91,0.52-11.77-4.19c-3.59-5.91-6.07-7.11-12.69-6.1c-2.96,0.45-6.19,1.31-8.86,0.09
				c-7.34-3.36-14.11-1.21-20.97,1.24c-1.19,0.42-2,1.67-3.61,1.13C950.61,1447.91,949.13,1449.87,947.46,1452.2z M896.29,1469.12
				c-1.62,7.72,2.42,11.85,6.84,15.78c2,1.78,3.73,3.13,2.21,6.62c-1.91,4.37,0.53,7.07,5.46,7.08c14.79,0.02,29.59,0.01,44.38,0.01
				c1.87,0,3.73,0,5.6,0c0.15-0.63,0.29-1.27,0.44-1.9C938.48,1490.24,917.34,1480.03,896.29,1469.12z M522.86,1276.91
				c-10.56,7.33-21.82,10.14-33.72,11.81c21.9,7.11,42.25,18.05,64.42,24.46C545.59,1299.04,530.22,1291.69,522.86,1276.91z
				M772.87,1390.93c7.38,17.53,10.36,21.5,21.4,29.1c17.12,11.8,36.27,20.35,52.83,33.07c2.53,1.94,5.66,3.52,9.74,2.16
				c-3.56-7.13-7.02-14.07-10.65-21.33c-3.21,2.3-6.55,2.69-9.94,1.2c-5.73-2.52-11.61-4.77-17.06-7.81
				c-7.19-4.01-14.46-7.89-21.57-12.05c-5.34-3.13-11.87-3.25-13.21-11.88C783.72,1398.84,778.02,1395.33,772.87,1390.93z
				M833.56,572.09c-3.45-6.63-9.14-11.3-14.1-16.48c-6-6.27-11.57-12.94-19.6-17.39c-5.37-2.97-10.9-7.71-12.98-14.02
				c-2.18-6.6-5.35-12.88-6.57-19.85c-0.72-4.14-2.59-7.9-6.65-10.06c-1.53-0.82-3.43-1.23-3.49,0.77
				c-0.17,5.44-0.71,11.5,2.85,15.79c2.78,3.34,4.43,6.85,5.95,10.69c1.95,4.94,4.13,9.79,6.21,14.68c0.74,1.75,1.8,3.21,3.72,3.77
				c6.4,1.87,10.76,6.4,14.87,11.28c1.59,1.9,3.02,4.05,5.63,4.74c3.95,1.04,7.27,3.25,10.47,5.64
				C824.46,565.08,829,568.6,833.56,572.09z M603.97,448.3c1,5.29,1.64,9.2,2.49,13.08c0.83,3.82-1.1,4.29-4.17,3.84
				c-6.83-0.99-11.12-4.5-12.38-11.57c-0.9-5.02-2.2-9.99-6.8-15.07c-3.77,6.59,2.63,12.33,0.39,18.39
				c-7.32-4.2-6.71-12.6-10.96-18.62c-2.4,5.18-4.54,9.81-6.92,14.97c17.86,7.48,34.56,17.74,52.05,28.1
				C618.64,467.49,614.56,457.26,603.97,448.3z M9.68,1415.26c-7.36,1.98-10.99,9.16-7.09,15.25c5.91,9.24,9.99,19.5,16.38,28.4
				c3.24,4.51,6.82,8.92,12.92,11.1c-1.45-10.11-2.44-19.82-8.26-28.14C17.9,1433.7,13.13,1425.02,9.68,1415.26z M105.4,1468.55
				c3.28-0.67,6.97-0.14,8.38-4.11c1.27-3.6,3.8-4.25,7.15-3.16c2.5,0.81,5.45,2.8,7.46,2.11c8.23-2.83,16.61-5.7,23.08-12.25
				c-1.31-2.1-2.51-4.03-3.79-6.08c-6.07,1.14-11.88,0.03-17.42-2.39c-3.14-1.37-5.49-0.63-8.29,1.12
				C112.67,1449.59,110.98,1460.25,105.4,1468.55z M33.89,812.58c-4.06-10.64-10.74-20.09-13.57-31.27
				c-2.24,0.59-3.55,1.52-5.12,1.18c-1.76-1.24-1.5-3.2-1.72-4.98c-0.57-4.73-2.22-9.03-5.17-12.77c-1.08-1.38-2.42-2.15-4.35-1.57
				c-1.86,0.56-2.09,1.86-2.09,3.46c0.02,7.31,0.01,14.62,0.01,22.24c5.15,0.21,6.73,5.75,10.76,6.87c4.39,1.21,6.01,3.94,7.44,7.68
				c1.16,3.04,2.54,6.7,6.55,6.64C29.45,810.01,31.33,811.06,33.89,812.58z M592.75,1356.37c-2.37-0.41-4.23,0.44-6.15,0.51
				c-3.5,0.14-6.59-4.04-10.29-0.71c-3.02,2.72-2.85,6.59-4.09,9.96c-0.86,2.34-1.8,4.65-2.5,7.04c-0.83,2.8-3.92,4.62-3.63,6.86
				c8.66,4.22,17.04,4.22,25.59,0.68C593.66,1372.88,593.28,1364.65,592.75,1356.37z M550.03,1377.49
				c3.82-6.91,5.12-14.16,5.87-21.57c0.13-1.26-0.25-2.69-1.57-2.67c-4.73,0.07-8.82-3.73-12.68-3.6c-5.16,0.18-9.63,0.95-14.13-2.45
				c-0.92-0.7-3.54,0.14-5.45-0.87c-5.21-2.76-7.09-2.05-10.18,3.04c2.9,5.63,6.97,8.32,13.64,7.01c3.2-0.63,6.69,0.8,7.74,4.77
				c0.31,1.16,0.78,1.51,1.95,1.71c3.24,0.53,5.97,2.51,9.29,3.13c3.12,0.59,5.28,2.78,3.84,6.64
				C547.73,1374.23,547.66,1375.94,550.03,1377.49z M861.08,1454.74c-0.91,2.71,0.7,3.6,1.96,4.55c4.47,3.32,7.92,7.53,10.94,12.15
				c2.02,3.09,4.44,5.82,7.44,7.97c5.53,3.95,9.88,8.95,12.65,15.1c1.92,4.27,4.71,4.88,8.7,3.56c-0.12-1.68-0.73-3.36-0.23-4.6
				c1.75-4.34-0.88-6.58-3.57-9.1c-2.67-2.51-5.36-5.35-5.4-9.22c-0.04-3.49-1.73-5.51-4.25-7.34
				C880.77,1461.57,870.5,1459.27,861.08,1454.74z M68.99,1465.89c0-3.28,0.29-6.58-0.06-9.82c-0.94-8.76-2.66-17.47-3.08-26.25
				c-0.26-5.39-1.51-9.27-6.04-12.51c-2.4-1.72-4.38-2.59-6.48-0.8c-2.04,1.74-4.93,2.93-4.08,7.02c1.88,9.09,5.09,17.52,9.95,25.4
				C62.6,1454.43,66.14,1459.86,68.99,1465.89z M634.64,674.08c1.35-14.93-2.8-28.4-5.32-42.08c-0.7-3.79-3.88-3.2-6.26-3.41
				c-3.24-0.28-2.98,2.5-2.96,4.61c0.06,7.97,1.84,15.84,1.24,23.89c-0.39,5.24-0.07,10.53-0.07,16.51
				C628.81,665.94,629.89,666,634.64,674.08z M41.69,1340.32c-3.73-1.02-6.33-3.35-9.14-5.4c-3.97-2.9-5.93-1.97-6.56,2.95
				c-0.36,2.8-0.96,5.63-0.86,8.42c0.19,5.13-1.37,9.7,3.54,15.07c4.48,4.9,8.19,5.35,13.94,5.49
				C41.16,1357.88,41.12,1349.16,41.69,1340.32z M574.46,1354.35c4.51-3.92,8.42-0.14,12.59-0.01c3.56,0.11,5.46-0.65,4.92-4.55
				c-0.64-4.6-1.11-9.24-1.91-13.81c-1.47-8.4-3.28-9.98-12.35-11.35C573.78,1333.91,573.95,1343.65,574.46,1354.35z M34.59,1473.56
				c-18.07-5.17-22.52-21.96-31.06-35.2c2.69,6.45,5.39,12.89,8.02,19.18c-2.35,1.68-3.84-0.63-6,0.08
				c4.32,3.06,9.06,4.94,12.38,8.78c3.63,4.21,7.88,7.57,13.22,9.47c4.21,1.5,6.62,4.81,8.28,8.81c1.36,3.28,1.49,6.8,2.11,10.22
				c0.24,1.32-0.31,3.16,2.79,3.7c0.73-6.63,0.91-12.98-2.24-19.06c-4.4-8.5-7.13-17.52-8.8-26.91c-1.57-8.82-3.15-17.63-4.72-26.45
				c-0.58,5.5,1.64,10.77,0.95,17.71c-3.49-5.44-5.79-9.9-10.87-13.73C29.24,1447.27,34.6,1461.95,34.59,1473.56z M540.21,589.33
				c-2.4,1.58-4.3,3.16-7.17,3.59c-2.92,0.43-4.22,2.78-2.55,6.02c2.22,4.29,4.68,7.8,9.96,5.47c2.12,1.65,1.05,4.54,2.89,5.66
				c1.71,0.81,3-1.57,5.03,0c0.45,5.43,0.21,10.88,0.52,16.31c0.29,5.12-0.6,10.73,3.79,15.39
				C559.08,631.13,549.73,597.94,540.21,589.33z M1086.44,754.61c2.05,3.84,4.62,5.03,8.2,5.76c8.69,1.77,17.36-2.49,26.05,0.47
				c2.51,0.85,5.18,0.71,5.63,3.61c11.48-3.52,22.99,1.78,34.11-1.78c-14.33,1.81-27.3-4.85-41.09-6.15
				c-4.9-0.46-8.96-2.28-10.59-7.52C1101.12,750.29,1093.86,751.81,1086.44,754.61z M437.23,471.28c1.9-6.98,8.64-9.37,9.79-15.48
				c1.09-5.81,1.46-11.76,2.19-18.08c-5.75-0.4-10.97-0.76-16.4-1.14c-2.06,6.02-1.4,11.84-1.49,18.01c2.36-1.57,1.48-4.95,4.68-5.5
				C437.21,456.12,434.35,463.09,437.23,471.28z M689.61,801.57c4.03,0.74,6.6,0.72,8.38-2.79c1.52-2.99,4.13-4.62,7.88-2.97
				c3.45,1.52,5.64-1.08,5.47-3.64c-0.27-3.88-0.77-8.1-3.53-11.43c-2.7,2.1-2.01,5.89-4.61,7.7c-10.75-4.45-15.49-13.28-17.7-24.1
				C684.2,777.26,689.57,789.01,689.61,801.57z M652.61,446.7c-6.53,5.65-9.86,13.2-18.43,15.51c6.2,6.74,11.8,12.83,17.4,18.91
				c0.42-0.2,0.85-0.41,1.27-0.61C650.38,469.61,656.11,458.77,652.61,446.7z M441.63,1298.91c5.95-1.14,11,2.96,15.62-1.76
				c1.56-1.6,3.05-0.58,4.51,0.19c3.05,1.6,5.22,0.72,6.18-2.48c0.77-2.57,2.27-3.93,4.89-4.21c1.24-0.13,2.37-0.47,1.76-2.24
				c-8.03-1.1-16.09-2.18-24.13-3.33c-1.94-0.28-3.15,0.32-3.9,2.16C445.21,1290.6,443.74,1293.93,441.63,1298.91z M1020.44,1498.23
				c-11.36-9.53-24.84-12.84-37.81-17.23c-0.72-0.25-1.67-0.04-2.46,0.15c-6.01,1.48-11.9,0.35-17.79-0.73
				c-10.64-1.95-20.7-5.75-30.63-9.94c13.17,7.92,27.51,12.41,42.66,14.11c11.57,1.3,22.67,3.86,32.24,10.74
				C1010.74,1498.29,1014.89,1499.25,1020.44,1498.23z M699.03,1497.85c-15.98-4.9-31.56-11.89-49.23-9.75
				C664.69,1500.76,681.88,1499.2,699.03,1497.85z M108.86,1475.04c-9.59,2.38-10.98,7.17-12.47,23.4c3.09-0.26,6.59,1.77,8.76-2.33
				c3.86-7.3,8.72-13.65,16.91-16.88C117.03,1479.34,111.82,1480.69,108.86,1475.04z M75.49,1498.2c0.51-3.26-1.73-5.37-1.99-8.02
				c-1.25-12.99-1.02-25.75,3.96-38.16c2.97-7.39,5.58-15.03,4.82-23.23c-0.72-7.75-2.29-15.43-2.05-23.27
				c-1.98,8.75,0.71,17.5-0.17,26.23c-0.87,8.53-2.62,16.83-6.01,24.62c-0.41-0.11-0.82-0.22-1.23-0.32
				c-1.44-10.66-2.88-21.31-4.32-31.96c0.08,6.51,1.04,12.9,1.66,19.32c1.12,11.64,5.06,23.48-2.18,34.62c-0.29,0.44,0,1.3,0.11,1.94
				c1.03,6.12,2.08,12.25,3.14,18.45c0.99,0.06,1.82,0.16,2.64,0.14C74.34,1498.54,74.81,1498.36,75.49,1498.2z M101.62,1470.57
				c6.06-9.74,8.7-21.48,20.04-29.42c-7.54,2.62-7.53,2.63-10.55,8c-0.98,1.74-1.94,3.5-3.13,5.09
				c-7.97,10.62-13.85,22.23-16.98,35.2c-0.46,1.92-0.56,4.35-3.93,5.48c-0.86-7.56-0.3-15.12-3.2-22.1
				c1.53,8.42,1.99,16.94,2.09,25.84c2.96-0.81,7.2,1.83,7.28-2.51c0.08-4.17,1.91-7.62,2.55-11.46c1.01-6.11,4.68-9.69,10.57-11.17
				c1.19-0.3,2.82-0.03,2.76-2.07C107.04,1470.2,104.98,1472.82,101.62,1470.57z M960.37,789.64c4.47,2.17,9.11,4,12.9,7.31
				c2.39,2.09,4.95,4.37,4.14,7.95c-0.86,3.84-4.03,5.43-7.56,6.41c-4.22,1.17-8.57,0.78-12.85,1.22
				c-6.62,0.68-13.56-0.27-20.27,3.44c2.19,1.51,3.61,4.61,5.87,3.53c8.42-4.02,17.6-2.89,26.32-4.64c3.93-0.79,7.71-1.99,10.67-4.96
				c4-4.02,4.13-7.64,0.23-11.64C974.49,792.8,967.69,790.65,960.37,789.64z M850.65,952.19c4.57-4.36,4.64-7.7,0.23-10.96
				c-3.2-2.36-6.8-4.16-10.13-6.36c-9.09-6-9.26-12.47-0.54-18.7c0.81-0.58,1.62-1.16,2.47-1.7c3.3-2.07,4.91-4.56,3.48-8.75
				c-1.45-4.24,0.81-6.95,4.76-8.52c1.19-0.47,2.93,0.13,3.56-1.82c-1.58,0.29-3.05,0.52-4.5,0.84c-7.74,1.69-9.42,3.99-8,11.77
				c0.64,3.48-0.69,5.82-3.26,7.74c-2.13,1.6-4.41,2.99-6.49,4.64c-6.26,4.94-6.19,8.75,0.44,13.2c4.55,3.06,9.41,5.66,13.97,8.69
				C849.94,944.46,852.32,947.35,850.65,952.19z M126.26,1484.54c-1.48,7.96-10.21,7.5-12.9,13.76c12.9,0,25.27,0,38.05,0
				c-0.57-2.7-1.52-4.59-4.22-4.21C138.85,1495.27,132.41,1492.06,126.26,1484.54z M2.09,1348.48c4.46,2.14,9.94,3.06,12.02,8.12
				c1.74,4.23,3.73,2.96,6.36,1.44c-0.01-7.11,0.06-13.99,1.36-21.96c-3.14,2.01-3.67,3.83-4.56,5.36c-2.1,3.58-5.81,4.22-9.03,1.54
				c-1.51-1.25-1.98-4.45-4.88-3.19c-2.93,1.28-1.01,4.1-1.46,6.18C1.75,1346.75,2.02,1347.61,2.09,1348.48z M41.08,426
				c-13.17-1.99-25.91-1.02-38.56-1.4c-0.8,2.18-0.98,3.89,0.01,5.46C15.76,432.74,28.6,432.67,41.08,426z M231.43,445.73
				c-7.69,0.58-13.76,1.84-19.92,0.95c-1.06-0.15-2.01,0.08-2.61,1.04c-4.37,7.03-6.84,15.04-11.92,21.71
				c5.68-1.58,8.51-6.04,11.82-9.91c3.45-4.03,7.7-8.45,12.66-7.85C226.9,452.33,227.71,448.72,231.43,445.73z M728.97,462.95
				c7.69,7.83,8.86,18.05,10.85,27.94c0.59,2.94,0.76,5.89-0.3,8.85c-2.82,7.88-0.8,14.86,4.24,21.41c1.38-1.26,2.69-2.46,4.04-3.69
				c-2.98-8.39-3.94-16.96-5.09-25.51C741.22,480.85,736.59,471.13,728.97,462.95z M82.51,1498.43c1.22-13.67-1.43-26.28-4.44-40.01
				c-4.06,11.49-3.24,22.08-1.71,32.5C76.73,1493.49,76.33,1498.89,82.51,1498.43z M480.56,442.64c4.1,1.01,7.89,2.44,10.96,5.47
				c1.58,1.55,3.73,2.7,5.78,2.69c9.52-0.05,18.71,1.51,27.85,4.16c2.89,0.84,6.31-0.18,9.11,1.71c3.46,2.34,6.11,1.13,9.5-2.82
				C521.8,451.79,501.29,446.73,480.56,442.64z M132.23,1405.22c2.28,2.03,5.07,2.39,7.16,1.19c3.62-2.08,6.77-1.82,10.36-0.2
				c2.18,0.98,5.3,1.04,6.26-1.25c1.18-2.82-1.63-5.07-3.89-5.36c-5.32-0.69-9.21-3.2-12.53-7.14c-0.58-0.69-1.66-1.06-2.97-0.59
				C135.18,1396.24,133.7,1400.75,132.23,1405.22z M960.46,867.09c2.6-3.97,6.01-6.67,8.18-10.5c2.16-3.81,6.04-6.14,9.01-9.27
				c0.77-0.81,1.55-1.92,1.67-2.96c1.01-8.76-3.26-14.37-12.17-16.19c5.06,4.94,6.88,10.8,4.94,17.69c-1.57,5.59-5.68,9.26-9.83,12.9
				c-2.09,1.83-4.6,3.29-5.73,6.43C957.72,865.76,958.86,866.32,960.46,867.09z M245.38,1143.69c2.52-1.08,6.14-2.42,7.05-0.29
				c2.26,5.29,9.51,5.81,10.1,12.4c2.17-1.64,3.87-2.92,5.49-4.16c-0.91-2.85-4.19-2.91-5.56-4.86c3.02-3.2,5.86-6.22,8.76-9.3
				c-3.33-1.49-5.07,3.58-8.72,0.68c1.3,3.45,0.27,4.49-2.39,4.21c-3.11-0.32-5.55-2.1-8.07-3.7c-3.29-2.09-6.64-3.24-10.36-0.95
				C242.89,1139.68,244.01,1141.49,245.38,1143.69z M113.43,423.24c15.18-0.43,29.73,2.3,44.99,0.77c-1.37-3.54-1.77-6.33-6.13-5.76
				c-11.15,1.46-22.39,1.7-33.64,1.5C116.61,419.72,114.69,420.06,113.43,423.24z M181.99,1151.76c-1.93,5.85-5.94,4.99-9.76,3.35
				c-2.54-1.08-4.84-1.44-8.18-0.61c4.05,3.39,7.44,6.48,11.09,9.22c3.18,2.39,11.05,2.16,12.21-0.44c1.3-2.92,3.43-4.82,5.94-6.95
				C187.6,1159.92,186.22,1153.39,181.99,1151.76z M165.18,1456.91c-0.37-3.42,2.78-4.05,4.02-6.16c-3.1-1.99-2.99-1.71-5.69-0.72
				c-8.93,3.27-13.4,12.75-23.05,15.27c7.01,6.49,14.7,4.29,16.62-4.1C158.08,1456.86,159.77,1454.32,165.18,1456.91z M372.95,439.61
				c1.23,12.39,0.53,24.41,5.61,35.45c3.64-11.88-0.44-23.68,0.48-35.85C376.83,439.36,375.22,439.46,372.95,439.61z M1088.9,700.14
				c5.58-2.39,6.08-6.6,6.6-10.97c1.21-10.25,0.42-20.61,2.05-30.84c0.71-4.47,1.34-9.02,0.08-13.65
				C1095.77,663.14,1091.72,681.23,1088.9,700.14z M663.05,503.98c0.13,0.85,0.14,1.36,0.29,1.83c2.32,7.05,4.62,13.85,3.55,21.75
				c-0.58,4.3-1.6,10.63,4.34,13.04c0-7.28-0.26-14.71,0.08-22.1C671.62,511.85,669.67,506.81,663.05,503.98z M150.83,465.28
				c6.25-5.19,12.52-10.09,13.81-18.59c-2.39,0-4.58,0.26-6.67-0.06c-3.52-0.54-5.13,1.04-6,4.22c-0.92,3.38-2.13,6.77-0.69,10.17
				C152,462.71,150.66,463.83,150.83,465.28z M715.09,1144.33c0.91,4.88-0.78,9.85,1.09,14.38c1.47,3.57,0.68,6.79-0.89,10.03
				c-0.79,1.63-1.6,3.29-2.15,5.01c-0.69,2.17,0.62,3.68,2.48,2.84c2.35-1.06,5.4-2.34,4.61-6.05c-0.65-3.07-0.12-6.17,1.05-8.79
				C724.84,1153.75,716.5,1150.34,715.09,1144.33z M876.72,1410.23c-0.32,5.22,2.28,7.52,7.29,7.46c3.25-0.04,5.49,1.11,7.1,4.26
				c2.12,4.16,7.02,5.24,10.31,2.82C898.15,1417.38,893.27,1414.5,876.72,1410.23z M1131.61,802.62c1.21-12.74,6.26-23.84,7.28-35.83
				c-4.23-0.62-4.38-0.65-5.04,2.94C1131.9,780.22,1127.8,790.52,1131.61,802.62z M339.67,1137.41c-5.72-1.25-11.21-2.44-16.78-3.66
				c-1.11,5.41,0.77,9.03,4.45,11.45c3.41,2.24,6.89,1.14,10.37-1.47c-1.73-0.94-3.72,0.66-4.83-1.22c0.48-0.58,1.04-1.14,1.44-1.79
				C335.49,1138.83,338.33,1140.21,339.67,1137.41z M692.42,1341.69c-2.04,4.31,1.22,6.85,2.59,10.03c0.82,1.9,2.61,3.96,0.86,5.95
				c-1.86,2.11-4.35,0.99-6.52,0.19c-5.24-1.93-7.89-6.16-9.22-11.26c-4.37,0.97-4.75,1.1-4.8,2.33c-0.08,2.1,1.5,3.31,2.95,4.35
				c7.08,5.07,15.18,7.78,24.99,10.79C696.91,1356.87,695.88,1348.77,692.42,1341.69z M86.86,1381.43
				c4.43-4.21,7.38-7.89,10.12-11.87c2.73-3.98,0.73-6.86-1.38-9.67c-1.36-1.81-3.54-3-5.93-4.93
				C91.46,1364.1,89.54,1372.15,86.86,1381.43z M47.43,1362.27c4.89-8.96,3.65-18.31,7.05-27.93c-5.5,1.79-8.41,4.75-8.73,8.42
				C45.22,1348.91,45.31,1355.24,47.43,1362.27z M118.61,1439.1c4.63-1.86,7.55-4.53,10.26-7.41c4.88-5.18,3.93-8.23-3.23-10.27
				C123.42,1427.02,121.19,1432.62,118.61,1439.1z M154.43,1136.14c-1.91,1.73-2.53,4.88-5.62,3.56c-1.58-0.68-2.93-0.84-3.77,0.87
				c-0.94,1.92,0.22,3.15,1.74,4.05c4.08,2.39,8.2,4.71,12.18,7C159.15,1145.99,155.19,1141.8,154.43,1136.14z M445.49,1284.56
				c-4.02-0.75-8.18,2.17-8.89,6.47c-1.1,6.65-1.82,13.36-2.7,20.04C436.76,1301.79,441.04,1293.18,445.49,1284.56z M128.25,1402.07
				c2.38-3.5,3.04-7.58,3.94-11.54c0.57-2.5-1.53-3.04-3.24-3.48c-1.68-0.43-3.68-1.13-4.96,0.76
				C122.79,1389.59,126.05,1400.44,128.25,1402.07z M909.84,1414.47c0.47,7.74,3.31,12.61,10.13,14.99c1.38-1.09,2.85-2.25,4.6-3.63
				C919.67,1422.05,915.15,1418.56,909.84,1414.47z M16.05,1418.22c2.85,8.39,5.9,12.86,10.46,15.34c-0.88-5.45-0.99-10.9-3.52-15.73
				C20.57,1417.97,18.44,1418.09,16.05,1418.22z M624.64,513.41c6.25,10.46,16.38,16.99,21.58,27.5
				C641.57,529.9,636.74,519.04,624.64,513.41z M107.7,1498.24c3.77-2.28,6.08-6.23,10.04-8.25c6.01-3.07,8.01-5.79,6.7-8.93
				C115.99,1484,110.84,1490.13,107.7,1498.24z M129.76,1437.67c6.35,3.39,13.91,4.15,18.53,1.88c-4.82-2.17-9.19-4.75-13.54-7.61
				C133.03,1433.91,131.52,1435.64,129.76,1437.67z M34.59,431.79c10.76,3.1,18.38-3.24,27.68-8.09
				C52.04,425.44,43.43,428.45,34.59,431.79z M898.93,1416.98c4.1,3.38,4.7,10.19,12.4,8.99c-2.19-3.29-2.94-6.64-4.23-9.97
				C904.49,1416.32,902.07,1416.61,898.93,1416.98z M51.28,1440.58c3.48,11.2,10.36,20.74,14.91,31.41
				C64.74,1459.85,55.87,1451.22,51.28,1440.58z M522.25,443.69c-0.78-4.43-1.56-7.58-1.83-10.76c-0.35-4.12-2.36-4.86-5.86-3.72
				C515.69,435.83,517.71,440.02,522.25,443.69z M581.34,1200.4c3.91,13.17,2.51,13.31,12.79,10.2
				C588.81,1208.24,585.02,1204.42,581.34,1200.4z M838.32,1350.76c0.16,2.01-1.83,2.25-1.98,3.67c2.42,2.95,4.82,2.69,8.23-0.23
				c-4.35-2.13-1.85-6.63-4.35-9.88c-1.68,1.82-3.08,3.33-4.21,4.55C836.17,1350.87,837.59,1350.21,838.32,1350.76z M624.52,455.67
				c5.36,5.9,8.52,5.65,14.01-0.9C633.93,455.82,629.49,455.25,624.52,455.67z M876.08,1417.38c-2.55-3.86-0.41-9.26-5.8-11.7
				c-0.83,4.3,0.6,7.68,1.1,11.32C872.89,1417.13,874.12,1417.23,876.08,1417.38z M2.47,1210.37c3.63-11.56,3.63-11.56-0.31-14.18
				C1.67,1201.09,1.76,1205.73,2.47,1210.37z M545.62,429.66c0.48,6.55,2.01,12.75,3.59,14.93
				C550.8,438.48,549.17,431.01,545.62,429.66z M399.32,1129.74c-6.95-4.18-12.21-3.82-16.62,1.34
				C388.36,1129.81,393.37,1130.2,399.32,1129.74z M843.98,1325.75c0.09,2.14,3.38,2.24,3.01,4.71c-1.1,1.65-2.94-0.69-4.02,0.81
				c-0.21,2.25,0.67,2.69,2.64,1.9c2.01-0.81,3.61-1.74,2.97-4.33c-0.59-2.36-1.43-4.7-4.22-4.87c-2.92-0.17-4.2,1.76-4.74,4.57
				C841.92,1328.62,841.79,1326.23,843.98,1325.75z M8.1,1455.14c-2.06-4.76-3.73-8.65-5.93-13.74
				C1.51,1452.14,1.51,1452.14,8.1,1455.14z M204.89,1068.62c2.73-1.61,5-3.05,5.68-6.18c-2.29-0.27-4.35-0.51-6.46-0.76
				C203.27,1063.98,203.97,1065.98,204.89,1068.62z M46.99,1369.15c2.39-4.17,4.59-8,6.78-11.84c-0.39-0.22-0.79-0.44-1.18-0.66
				c-2.19,2.83-2.59,6.88-5.48,9.35c-0.86-0.94-1.42-1.55-2.27-2.47C45.03,1365.61,44.47,1367.36,46.99,1369.15z M294.65,741.72
				c-3.19,4.35-5.05,9.2-5.61,14.52C291.42,751.6,293.77,746.96,294.65,741.72z M749.12,1389.86c-4.25-1.74-8.49-3.48-13.66-5.6
				C739.35,1389.4,744.16,1389.76,749.12,1389.86z M337.67,1134.32c-2.08-2.74-4.62-4.47-8.72-2.28
				C331.59,1134.59,334.71,1133.86,337.67,1134.32z M614.68,504.8c0.91,4.68,3.77,7.71,7.74,9.87
				C619.84,511.38,617.26,508.09,614.68,504.8z M580.81,1315.47c-0.91,2.42-1.67,3.88-1.47,5.66c1.22,1.05,2.58,1.74,4.74,1.16
				C583.08,1320.2,582.14,1318.25,580.81,1315.47z M537.69,443.31c0.59-9.78,0.59-9.78-2.12-12.01
				C536.25,435.15,536.86,438.62,537.69,443.31z M307.71,1135.61c0.64,1.73,1.42,3.03,3.22,3.47c0.65-1.84,1.82-3.5,1-5.85
				C310.48,1134.05,309.11,1134.82,307.71,1135.61z M770.94,1387.59c0.01-2.67,0.04-5.12,0.45-8.44c-1.52,1.92-2.4,3.03-3.28,4.15
				C769.14,1384.87,770.04,1386.23,770.94,1387.59z M46.57,1421.02c-0.81,3.63,0.11,6.87,1.82,9.93
				C47.79,1427.65,47.18,1424.34,46.57,1421.02z M81.76,1392.76c3.35-4.8,3.35-4.8,1.36-6.63
				C82.66,1388.42,82.21,1390.59,81.76,1392.76z M346.68,692.97c0.84-3.47,0.66-5.55-1.35-8.76
				C345.72,687.64,345.08,689.75,346.68,692.97z"/>
			<path onClick={() => props.onFill(0)} fill={props.fillColors[0]} d="M1159.55,70.61c-3.5-5.16-9.13-5.45-14.48-6.11c-3.1-0.38-6.29-0.07-9.76-0.07
				c-0.74-4.9-2.48-9.26-6.08-12.74c-5.54-5.36-10.85-5.99-17.41-1.94c-2.02,1.24-7.93-0.71-9.13-3.37
				c-2.42-5.35-6.31-8.43-12.2-8.89c-2.13-0.17-2.98-1.93-4.14-3.3c-2.58-3.06-5.16-6.1-8.84-7.96c-10.24-5.17-15.65-3.65-22.5,6.43
				c-2.31-1.56-4-4.04-6.85-4.82c-3.2-0.88-6.74-1.18-8.89,1.46c-3.59,4.42-9.95,4.47-12.63,9.4c-2.06-0.96-1.37-2.34-1.5-3.36
				c-0.98-7.62-6.9-12.85-14.69-12.72c-1.68,0.03-3.37,0.28-4.88-0.58c-4.3-2.45-8.48-3.77-12.88-0.03
				c-1.48,1.26-3.21-0.07-4.66-0.77c-3.89-1.91-7.8-3.8-12.09-4.59c-12.22-2.25-17.37,0.57-23.7,12.87
				c-3.74-0.41-7.5-0.72-10.72,2.25c-1.6,1.48-3.07-0.41-4.44-1.06c-10.78-5.06-20.64-3.8-29.65,4.03c-4.38,3.8-6.67,8.6-6.65,14.71
				c-4.39-1.24-7.48-0.22-10.62,2.85c-2.18,2.13-5.8,3.38-8.95,3.82c-6.64,0.92-11.52,3.92-14.48,9.89
				c-10.75-6.35-22.38-4.39-27.4,15.23c-2.01,0.28-3.31-1.21-4.81-2.08c-4.05-2.34-8.17-4.51-12.81-5.41
				c-4.12-0.8-8.69-0.63-10.94,2.98c-2.43,3.91-5.21,3.63-8.78,3.31c-4.28-0.39-8.72-0.84-11.78,3.41c-1.33,1.85-3.4,0.87-5.13,0.82
				c-4.92-0.13-9.02,0.31-12.9,4.62c-2.49,2.77-7.16,4.56-11.67,4.63c-4.95,0.07-9.48,1.49-13.5,4.49c-2.33,1.74-2.65,3.48-0.63,5.78
				c3.4,3.88,7.88,5.08,12.62,4.73c22.55-1.71,45.07-3.75,67.62-5.44c5.79-0.44,11.64-0.16,17.46-0.05
				c26.31,0.48,52.6-0.16,78.82-2.16c27.35-2.08,54.42,2.69,81.67,2.64c3.81-0.01,7.63,0,11.6,0c-0.46,3.16-2.01,4.09-4.56,3.97
				c-3.16-0.14-6.28,0.05-9.41,0.79c-4.63,1.1-8.54,2.83-9.18,8.6c-7.92-2.19-15.18-1.51-21.53,3.48c-3.73,2.93-7.27,6.16-9.37,10.55
				c-1.38,2.9-0.7,4.99,2.64,6.17c4.79,1.7,9.74,2.11,14.73,2.25c9.15,0.26,18.31,0.39,27.46,0.52c4.46,0.06,8.81,0.15,12.78-2.89
				c2.3-1.76,5.58-2.03,8.59-2.26c19.8-1.5,39.6-0.26,59.4-0.11c0.94,0.01,2.22,0.11,2.77,0.7c5.35,5.72,12.33,4.6,19.07,4.97
				c11.14,0.62,22.25,0.98,33.36-0.36c2.5-0.3,5.15-0.38,2.52-4.68c5.43-0.5,10.3-0.95,14.98-1.38c1.36,2.08,0.9,3.96,0.9,5.74
				c0.02,100.27,0.02,200.54,0.02,300.81c0,6.2-5.73,13-11.77,13.83c-2.78,0.38-5.22,1.47-7.03,3.45
				c-5.08,5.55-11.8,9.13-16.97,14.54c-1.64,1.72-3.71,2.74-6.14,3.04c-4.01,0.5-7.26,2.46-9.51,5.78c-1.36,2.01-2.74,2.45-5.03,1.63
				c-7.07-2.55-14.16-1.94-20.97,0.81c-10.09,4.07-20.18,5.11-30.67,1.6c-6.79-2.27-13.62-0.93-20.09,1.34
				c-8.53,2.99-16.82,3.19-25.35,0.22c-5.77-2-11.82-2-17.79-1.12c-5.63,0.82-10.83-0.25-16.09-2.19
				c-18.1-6.66-35.86-14.89-54.52-19.18c-20.32-4.67-40.72-11.74-62.33-8.14c-7.51,1.25-14.9-0.89-22.25-2.21
				c-16.22-2.91-32.4-5.58-48.99-3.42c-4.79,0.62-9.62,0.06-14.34-1.22c-8.78-2.38-17.79-2.33-26.77-2.27
				c-12.46,0.08-24.88,0.69-37.32-1.31c-10.12-1.63-20.22,0.33-30.23,2.12c-5.7,1.02-11.1,0.66-16.44-2.16
				c-3.35-1.77-8.07-3.07-10.79-0.01c-7.11,8.01-16.2,6.05-24.96,6.28c-4.18,0.11-7.09-1.36-8.95-5.5
				c-3.94-8.74-12.12-13.78-19.39-18.97c-4.38-3.12-11.38-1.92-16.89,0.14c-3.46,1.3-6.6,1.55-10.17,1.11
				c-7.91-0.98-15.87-2.08-23.82-2.14c-5.39-0.04-10.78,1.63-16.3,0.83c-8.2-1.2-16.31-1.9-24.67-0.2
				c-6.78,1.38-13.1-3.54-20.05-3.06c-0.28,0.02-0.75-0.22-0.84-0.45c-2.91-7.25-9.56-5.68-15.02-5.96
				c-14.87-0.78-29.76-1.01-44.64-1.53c-4.14-0.14-7.53,1.21-10.02,4.52c-1.71,2.26-3.57,2.72-6.45,2.14
				c-12.75-2.57-25.84-2.01-38.7-3.86c-16.14-2.32-32.07-5.83-48.22-7.94c-11.32-1.48-22.77-2.48-34.24,0.34
				c-4.16,1.02-8.85,0.01-13.28-0.28c-6.96-0.46-13.97-1.96-20.85-1.49c-14.88,1.02-29.81,0.78-44.65,2.02
				c-5.24,0.44-11.14,1.1-13.86,7.21c-0.21,0.48-1.47,0.71-2.22,0.69c-10.19-0.27-20.23,0.83-29.75,3.56
				c-0.63-0.55-0.98-0.72-1.11-0.99c-4.54-9.1-6.36-10.15-16.74-9.49c-7.47,0.47-14.85,1.7-22.36,1.93
				c-13.68,0.42-27.23-0.59-40.75-2.52c-9.01-1.28-17.86,0.08-26.76,1.63c-17.12,2.98-34.41,1.93-52.05,1.97c0-7.21,0-13.77,0-20.38
				c1.78-1.44,3.83-0.78,5.76-0.77c16.81,0.14,33.63,0.61,50.34-1.75c7.02-0.99,13.63-3.28,19.54-7.13c3.4-2.22,6.85-2.95,10.79-2.99
				c20.97-0.22,41.96,0.83,62.9-0.9c8.53-0.7,16.62-2.17,23.33-8.58c3.12-2.98,8.24-2,12.49-2.11c21.96-0.56,43.93-0.67,65.89-1.42
				c15.79-0.54,31.55-1.94,47.34-2.75c7.49-0.39,14.97,0.18,22.32,1.82c3.11,0.7,6.36,1.58,8.57,3.8c4.28,4.31,9.57,4.87,15.11,5.25
				c19.47,1.32,38.94,0.03,58.41-0.04c29.63-0.11,59.27,0.73,88.88-1.03c13.86-0.82,27.67-1.86,39.05-11.27
				c1.15-0.95,2.46-0.88,3.78-0.87c5.18,0.02,10.41,0.32,15.26,2.13c19.04,7.12,39.04,8.16,58.94,8.86
				c51.74,1.82,103.52,1.47,155.29,1.19c20.37-0.11,40.46-2.7,60.45-6.44c7.62-1.43,15.33-2.45,23.25-1.07
				c-0.47,3.18-4.14,3.46-4.28,6.65c3.09,1.98,6.77,1.7,10.27,1.68c27.47-0.2,54.94,0.27,82.38,1.25c31.26,1.12,62.48,3.07,93.68,5.5
				c11.76,0.92,23.59,1.32,35.37-0.47c3.86-0.59,7.2-2.47,10.77-3.72c2.87-1,4.4-6.56,2.85-9.57c-4.49-8.7-10.21-16.09-20.7-17.83
				c-3.28-0.54-5.73-2.02-7.87-4.68c-3.84-4.78-8.61-7.97-15.08-8.8c-4.09-0.52-5.84-5.74-10.52-6.49c0.82-3.53,1.6-6.72,2.3-9.92
				c1.4-6.42-0.48-11.91-5.13-16.36c-8.97-8.58-19.45-12.67-31.92-9.79c-2.96,0.69-4.98,0.35-6.71-2.36
				c-0.94-1.48-2.49-2.76-4.05-3.62c-5.15-2.83-11.4-3.66-14.5-9.96c-1.15-2.34-4.99-2.45-7.85-2.16c-3.54,0.35-4.87-0.81-5.23-4.56
				c-0.74-7.75-3.69-14.81-8.5-21c-8.89-11.43-23.98-13.91-36.07-5.94c-1.67,1.1-3.29,2.27-4.87,3.48c-3.31,2.53-6.5,5.53-11.02,2.31
				c-0.24-0.17-0.69-0.25-0.97-0.16c-8.15,2.68-17.04,1.12-25.06,4.27c-2.24,0.88-4.15,1.28-6.63,0.88
				c-6.22-1.01-11.42,1.51-15.58,6.05c-2.11,2.3-3.89,4.89-6.11,7.73c-6.31-12.49-16.46-15.97-29.27-13.43
				c-0.95,0.19-2.56,0.44-2.87-0.02c-3.2-4.66-8.88-5.56-12.99-8.8c-1.03-0.82-2.12-2.12-3.24-2.18c-6.17-0.32-8.76-4.45-12.13-8.99
				c-6.17-8.32-15.56-10.95-25.75-10.29c-2.63,0.17-5.24,0.91-7.84,1.47c-12.7,2.73-13.12,3.51-23.65-2.32
				c-5.44-3.01-10.37-6.92-16.48-8.76c-0.6-0.18-1.15-1.17-1.37-1.9c-2.73-9.24-9.48-12.22-18.37-11.93
				c-1.13,0.04-2.26,0.01-3.29,0.01c-0.96-2.19-0.14-3.93,0.13-5.69c2.33-15.16-8.48-27.75-23.89-27.39
				c-3.84,0.09-6.39-0.69-8.81-3.86c-4.4-5.74-10.7-6.86-17.56-5.68c-3.9,0.67-7.82,1.29-11.93,1.96c-0.88-2.63-1.66-4.95-2.42-7.23
				c18.76-11.46,15.55-43.73-2.18-53.65c-3.71-2.07-6.15-6.42-10.44-8.16c-1.07-0.43-1.24-1.43-1.52-2.41
				c-2.82-9.98-10.32-13.81-19.8-14.63c-2.78-0.24-4.43-1.03-5.88-3.49c-4.45-7.54-12.03-9.78-20-10.36
				c-10.97-0.8-21.6,0.57-30.8,7.52c-4.37,3.3-6.76,8.07-9.56,13c-2.53-2.13-5.35-3-8.28-3.53c-1.21-0.22-2.1-0.62-2.52-1.99
				c-2.81-9.08-10.05-12.73-18.46-14.91c-0.77-0.2-1.53-0.44-2.4-0.69c21.01-19.74,26.67-43.14,13.82-61.05
				c220.69,0,441.12,0,661.42,0c1.03,1.16,0.76,2.38,0.76,3.5c0.02,20.15,0.04,40.31-0.01,60.46c0,1.63,0.87,3.85-1.87,4.41
				L1159.55,70.61z"/>
			<path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M838.26,277.28c3.5-7.03,8.13-13.66,15.91-15.24c6.76-1.37,13.68-2.31,20.52-3.43
				c4.46-0.73,9.08-0.67,13.42-2.41c2.27-0.91,4.76-1.02,6.72,0.41c2.58,1.89,4.5,1.09,6.67-0.57c3.04-2.33,6.13-4.6,9.33-6.69
				c10.85-7.1,24.63-4.66,32.38,5.76c4.69,6.3,7.34,13.46,7.67,21.29c0.18,4.19,1.9,5.56,5.87,5.17c5.3-0.53,10.01,0.05,11.7,6.47
				c0.59,2.24,3.21,2.06,5.1,2.48c4.56,1.02,8.51,2.93,10.95,7.12c2.41,4.13,6.02,1.99,8.96,1.46c8.12-1.48,15.47-0.1,22.43,4.3
				c10.25,6.47,12.76,12.35,9.13,23.96c-1.43,4.58-0.53,6.37,3.62,9.37c5,3.61,11.44,3.84,15.57,7.46
				c7.06,6.19,14.36,11.03,23.35,14.03c5.45,1.82,8.29,7.58,10.73,12.69c1.87,3.92-0.96,6.28-4.13,7.83c-6.15,3-12.86,3.17-19.51,3.4
				c-14.51,0.49-28.92-1.25-43.36-2.14c-35.05-2.16-70.08-4.54-105.21-4.73c-16.98-0.09-33.95-0.44-50.93-0.7
				c-1.48-0.02-2.95-0.2-4.52-0.31c0.25-2.81,3.86-2.84,4.06-5.67c-2.15-1.98-5.07-2.75-7.71-2.35c-17.8,2.75-33.82-4.75-50.5-8.38
				c-12.64-2.75-25.28-5.43-37.99-7.86c-8.78-1.68-17.14-4.73-24.95-9.56c-6-3.71-11.68-8.85-18.78-9.93
				c-7.5-1.14-15.26-1.61-22.86-0.73c-4.38,0.5-7.71-0.7-11.67-2.49c-7.12-3.22-14.7-5.51-21.9-8.68c-0.99-0.44-2.41-0.47-3.45-0.13
				c-7.91,2.6-16.21,0.78-24.19,2.31c-2.59,0.5-4.5-0.47-6.4-1.96c-5-3.91-10.47-4.77-16.44-2.52c-1.55,0.58-3.2,0.88-4.8,1.36
				c-4.94,1.47-8.71,0.91-11.71-4.22c-4.05-6.94-10.54-10.94-18.61-12.1c-1.83-0.26-3.7-0.6-5.11-1.83
				c-5.61-4.92-12.21-6.5-19.47-6.09c-3.51,0.2-6.09-1.24-7.63-4.48c-2.48-5.22-7.11-8.62-11.36-12.72c11.83-6.06,26-0.59,36.89-8.83
				c-4.02-1.31-8.05-1.86-11.95-2.67c-9.4-1.97-17.71-8-27.76-7.31c-2.11,0.14-3.24-1.34-4.2-3.01c-2.18-3.82-5.36-6.77-8.64-9.61
				c-4.48-3.87-9.54-5.2-15.31-3.35c-2.21,0.71-4.45,1.38-6.72,1.89c-3.8,0.86-6.68,0.25-9.41-3.37c-6.6-8.72-19.03-11.5-29.28-7.26
				c-1.41,0.58-2.63,1.41-4.12,0.2c-3.73-3.01-8.2-3.51-12.74-3.37c-6.57,0.2-10.76-2.93-12.87-8.94c-1.61-4.6-4.77-7.77-8.29-10.96
				c-6.98-6.33-12.91-13.81-21.21-18.7c-2.23-1.32-2.44-4.25-3.38-6.54c-4.58-11.13-15.59-18.54-27.77-18.64
				c-1.6-0.01-3.35-0.27-5.26,2.33c9.18,0.2,17.2,2.27,20.87,10.87c3.1,7.27,10.74,14.01,3.56,22.49c1.36,2.42,3.28,1.66,4.81,1.97
				c7.82,1.57,11.17,6.94,9.21,14.67c-0.96,3.8-2.85,7.05-5.08,10.2c-1.41,1.99-2.84,4.05-2.97,6.66c2.44,1.47,4.8,0.45,7.04,0.64
				c8.22,0.69,11.76,4.13,11.86,12.32c0.06,4.52,0.86,7.44,5.5,10.24c4.36,2.62,7.5,7.66,12.1,10.68c0.69,0.45,1.11,1.68,1.23,2.6
				c0.63,5.06,2.35,9.09,7.44,11.51c2.84,1.35,3.58,4.97,3.28,7.89c-0.31,3.15-3.73,2.82-5.97,3.37c-4.11,0.99-7.84,0.1-11.04-2.97
				c-6.05-5.81-13.04-8.31-21.47-6.04c-2.54,0.68-5.29,0.32-7.94-0.2c-1.46-0.29-3.11-0.96-4.4-0.57
				c-8.42,2.58-14.79-0.29-20.15-6.78c-0.99-1.2-2.73-1.99-4.28-2.46c-5.77-1.75-10.72-4.88-15.88-7.93
				c-7.48-4.41-15.89-3.13-23.59,0.07c-5.25,2.18-10.13,2.97-15.37,0.67c-0.3-0.13-0.64-0.35-0.93-0.32
				c-11.33,1.2-20.28-6.54-30.85-8.19c-2.89-0.45-5.67-1.67-6.89-4.42c-2.55-5.78-7.37-7.22-12.95-7.58
				c-0.65-0.04-1.31-0.16-1.95-0.28c-0.11-0.02-0.18-0.2-0.45-0.51c5.34-4.23,11.41-1.01,17.32-1.46c-0.54-4.63-3.71-5.92-7.01-5.7
				c-6.47,0.44-12.47-0.65-18.44-3.09c-4.46-1.82-8.86,0.35-13.17,1.37c-4.38,1.04-7.41-0.31-9.64-4.12
				c-6.53-11.15-15.29-13.65-26.8-7.72c-6.77,3.49-9.41,2.36-11.94-5.1c-3.69-10.86-11.73-12.78-19.77-4.62
				c-2.24,2.28-4.52,3.7-7.89,3.16c-2.83-0.45-5.61,0.35-8.36,1.06c-4.07,1.04-6.89,3.03-6.96,7.79c-0.04,2.68-1.09,5.22-2.67,7.43
				c-1.45,2.02-2.84,4.45-5.9,3.62c-3.04-0.83-3.97-3.3-3.88-6.22c0.07-2.49,0.21-4.99,0.38-7.48c0.61-9.34-3.65-14.72-12.93-15.23
				c-7.82-0.43-13.74-4.45-19.77-8.67c-4.89-3.42-9.82-7-17.05-5.51c2-3.35,4.95-4.91,7.29-7.02c9.52-8.56,11.08-15.98,5.4-27.55
				c-3.94-8.03-9.2-14.69-18.58-16.74c-2.64-0.58-5.36-0.93-7.87,0.13c-4.06,1.71-7.51,0.58-10.71-1.85
				c-4.1-3.12-8.06-6.45-13.72-6.59c6.24-11.45,19.18-16.14,30.44-11.06c4.36,1.97,8.6,2.19,13.12,1.3c1.18-0.23,2.62-0.37,3.05-1.42
				c2.79-6.81,9.01-5.6,14.4-6.37c1.29-0.18,2.59-0.29,3.9-0.43c-1.83-12.42-12.38-22.64-24.35-23.92c-2.95-0.32-6.91-0.48-7.51-3.67
				c-0.59-3.14,2.87-5.24,5.61-6.23c16.16-5.79,42.46-8.26,53.62,12.82c3.01,5.69,8,8.59,14.5,9.26c3.44,0.36,7.04,1.02,10.12,2.49
				c6.23,2.95,12.19,2.38,18.38,0.21c2.81-0.98,5.76-1.62,8.7-2.14c7.02-1.26,13.24,0.82,15.96,7.27
				c5.04,11.95,13.29,14.99,25.52,13.23c7.96-1.15,16.04-1.31,24.12-2.65c12.73-2.12,24.43,1.21,32.7,12.42
				c3,4.06,6.41,7.03,12.42,6.25c10.71-1.38,16.49,6.25,22.12,13.64c0.89,1.17,1.64,2.46,2.48,3.68c0.07,0.11,0.29,0.11,0.44,0.17
				c0.28-0.16,0.8-0.38,0.78-0.48c-1.44-7.1-2.31-14.39-4.61-21.21c-1.82-5.41-7.35-7.36-12.61-8.8c-6.36-1.73-7.68-3.43-7.9-10.03
				c-0.25-7.65-0.56-15.31-0.85-22.96c-0.19-5.01-3.22-7.8-7.76-8.28c-4.76-0.51-8-2.9-10.86-6.31c-1.17-1.4-2.49-2.72-3.47-4.25
				c-8.53-13.39-25.35-13.21-35.82-6.94c-1.47,0.88-3.03,1.31-4.73,1.34c-7.64,0.15-15.31-0.63-22.89,0.99
				c-2.24,0.48-3.79-0.9-4.96-2.66c-1.23-1.84,0.1-3.07,1.09-4.41c5.16-7,4.38-12.11-2.64-17.32c-3.99-2.96-8.59-4.26-13.65-4.74
				c1.42-2.52,2.89-4.83,4.08-7.29c2.36-4.87,1.55-8.73-3.4-10.66c-7.89-3.07-13.25-9.3-19.62-14.24c-3.02-2.34-5.84-3.85-9.85-3.83
				c-5.55,0.02-9.35-4.21-13.68-7.53c1.76,0,3.52,0,5.28,0c111.87,0,223.74,0,335.62,0c8.41,0,11.98,2.52,14.51,10.6
				c3.42,10.88,1.06,21.11-4.15,30.79c-4.49,8.33-10.39,15.47-19.03,19.92c-0.73,0.37-1.39,0.91-1.98,1.49
				c-0.31,0.31-0.38,0.87-0.54,1.25c0.82,2.09,2.33,1.42,3.8,1.24c12.16-1.45,19.68,2.85,24.44,13.91c0.53,1.24,1.01,2.63,2.61,2.67
				c2.37,0.06,4.75,0.31,6.77,1.44c1.79,1.01,3.25,2.61,4.83,3.93c2.18-1.3,2.29-3.49,3.06-5.25c3.12-7.1,8.26-12.03,15.6-14.5
				c8.69-2.92,17.57-3.6,26.56-1.51c5.78,1.34,10.52,4.24,13.05,9.84c1.16,2.57,2.93,3.09,5.53,3.13
				c10.74,0.2,15.14,3.49,18.75,13.49c0.32,0.88,0.9,2.1,1.63,2.34c5.8,1.91,9.01,7.14,13.67,10.52
				c14.78,10.74,13.28,30.04,6.67,40.85c-1.54,2.51-4.06,3.93-6.52,5.3c-2.17,1.21-2.81,2.61-1.43,4.83c0.6,0.98,1.05,2.09,1.35,3.2
				c1.76,6.33,1.73,6.29,8.3,5.01c3.42-0.67,6.88-1.16,10.34-1.64c6.32-0.88,11.15,1.81,14.42,6.93c1.75,2.74,3.67,3.35,6.61,2.94
				c6.61-0.92,12.37,0.82,17.54,5.27c7.91,6.8,6.92,15.17,4.9,23.88c-0.35,1.51-1.8,2.94-0.37,4.6c7.95-3.1,18.72-1.39,20.69,4.83
				c2.29,7.23,7.19,9,13.13,10.64c1.39,0.39,2.93,1.14,3.84,2.21c2.62,3.06,5.67,4.99,9.76,5.2c0.8,0.04,1.9,0.27,2.3,0.82
				c3.49,4.81,7.7,2.53,11.94,1.53c5.49-1.3,10.94-3.21,16.66-2.87c10.77,0.63,19.97,3.97,23.73,15.45c0.62,1.88,1.69,2.56,3.63,2.65
				c8.4,0.38,14.38,5.78,20.58,10.46c1.61,1.21,2.73,1.95,4.73,1.72c3.62-0.42,7.18-1.36,10.9-0.84c10,1.39,15.02,5.8,17.52,15.64
				C836.91,276.33,837.03,277.02,838.26,277.28z"/>
			<path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M391.75,232.05c10.28-22.06,9.78-24.84-5.88-32.19c4.1-10.45-4.67-17.29-7.51-26.15
				c4.96,2.93,7.86,7.31,9.55,12.39c1.51,4.54,4.55,7.4,8.19,10.28c9.96,7.87,20.08,15.7,25.59,27.76c2.65,5.79,7.63,8.15,13.72,8.31
				c4.7,0.13,9.35,0.05,12.95,3.88c1.33,1.42,2.72,0.26,4.07-0.36c10.77-4.91,21.58-2.28,28.79,7.23c1.94,2.56,4.16,3.73,7.22,3.36
				c1.97-0.24,3.96-0.54,5.87-1.06c12.32-3.33,18.14-1.42,24.75,9.31c2.65,4.29,5.9,5.99,10.62,5.75c1.16-0.06,2.34-0.03,3.49,0.13
				c1.14,0.16,2.37,0.33,3.36,0.86c6.29,3.44,13.57,4.04,20.13,7.17c-10.38,2.16-21.58,0.45-31.69,6.99c3.92,3.71,8.05,6.1,9.93,9.68
				c4.56,8.66,11.19,12.14,20.64,11.09c0.79-0.09,1.79,0.01,2.43,0.43c7.25,4.81,15.64,6.93,23.52,10.28
				c4.2,1.79,7.02,5.66,9.23,9.58c1.84,3.27,4.37,4.17,7.82,4.03c3.72-0.16,7.26-1.06,10.69-2.31c5.04-1.85,9.44-0.81,13.61,2.26
				c1.58,1.16,3.02,3.14,5.15,2.7c7.21-1.49,14.63,0.36,21.78-1.47c6-1.53,11.56-1.16,16.41,3.08c1.62,1.41,3.48,1.77,5.53,1.86
				c3.05,0.14,6.04,0.84,8.32,2.93c3.02,2.77,6.5,3.15,10.23,2.6c7.48-1.1,14.99-0.19,22.4,0.31c7.81,0.52,13.74,6.33,20.21,10.18
				c8.17,4.85,16.78,8.04,25.98,9.6c20.95,3.55,41.75,7.63,62.05,14c0.77,0.24,1.7,0.14,2.04,1.44c-11.96,2.75-24.08,4.51-36.29,5.15
				c-40.26,2.12-80.54,1.21-120.82,0.77c-22.13-0.24-44.29,0.08-66.37-1.68c-15.39-1.23-30.89-1.99-45.55-7.78
				c-5.63-2.22-11.74-2.2-17.69-2.75c-1.35-0.12-2.74,0.08-3.78,0.99c-10.77,9.42-24.07,10.89-37.42,11.43
				c-48.75,1.96-97.52,1.21-146.29,1.18c-6.05,0-11.5-1.17-16.66-5.16c-6.85-5.3-15.56-5.47-23.88-5.74
				c-16.33-0.54-32.6,0.95-48.85,2.24c-9.32,0.74-18.62,0.85-27.94,0.81c-17.81-0.09-35.59,0.51-53.35,1.79
				c-1.83,0.13-3.79,0.05-5.16,1.47c-5.78,5.99-13.34,7.08-21.03,7.74c-20.61,1.76-41.26,0.62-61.89,0.84
				c-5.1,0.06-9.65,0.76-14.11,3.72c-9.8,6.5-21.2,7.56-32.55,7.89c-12.14,0.35-24.3,0.09-36.45,0.25c-3.78,0.05-5.08-1.24-5.08-5.23
				C1.89,259.32,1.87,132.74,1.88,6.16c0-1.16,0.12-2.32,0.18-3.43C3.69,1.22,5.62,1.81,7.37,1.8C49.49,1.77,91.6,1.84,133.72,1.69
				c4.19-0.01,7.22,2,10.56,3.77c5.63,3,10.63,7.62,17.82,6.69c1.62-0.21,2.96,1.33,4.29,2.34c6.95,5.27,12.64,12.3,21.31,15.23
				c1.69,0.57,3.33,2.23,2.86,4.25c-0.81,3.56-1.98,7.08-5.68,8.89c-3.23,1.58-6.94,2.36-9.41,5.46c1.49,3.45,3.84,2.27,6.21,1.42
				c7.21-2.59,13.82-1.27,19.63,3.55c4.6,3.81,4.84,7.3,1.22,12.01c-1.81,2.36-4.23,4.52-1.75,7.86c2.35,3.18,5.07,5.31,9.32,3.8
				c0.61-0.22,1.31-0.21,1.97-0.26c11.26-0.92,22.49-0.36,33.53-4.96c8.84-3.68,17.23,0.12,23.23,8.09c2.29,3.05,4.95,5.84,7.6,8.59
				c1.87,1.93,4.16,3.09,6.98,3.49c6.46,0.92,7.71,2.61,7.89,9.23c0.19,6.82,0.38,13.65,0.71,20.47c0.35,7.41,2.57,10.07,9.55,11.83
				c10.04,2.53,12.4,5.79,12.73,18.28c-6.03-8.09-13.61-11.54-23.23-10.35c-3.69,0.46-5.64-2.62-7.51-5
				c-10.38-13.28-24.04-16.47-39.68-12.81c-10.35,2.42-20.8,0.91-31.18,1.73c-3.1,0.24-5.55-2.03-7.16-4.77
				c-1.26-2.15-2.37-4.39-3.64-6.54c-4.59-7.75-11.36-12.1-19.61-9.05c-12.98,4.81-24.95,2.11-37.39-0.93
				c-4.67-1.14-8.54-2.89-10.84-7.11c-11.74-21.51-34.5-21.38-55.46-15.35c-4.61,1.33-9.02,4.2-9.28,9.92
				c-0.14,3.07,5.68,6.09,12.01,6.12c5.38,0.03,9.13,2.62,12.34,6.45c2.9,3.46,4.59,7.61,6.91,11.9c-3.52,0.58-6.59,1.02-9.62,1.61
				c-1.97,0.38-3.97,1-5.31,2.56c-1.22,1.41-1.04,4-3.68,4.23c-3.82,0.33-7.67,0.83-11.22-1.19c-12.79-7.26-29.45,1.39-36.42,15.16
				c0.3,0.34,0.66,1.04,0.94,1.01c7.09-0.8,12.28,2.96,17.51,6.94c3.01,2.29,6.46,3.24,10.33,1.66c3.39-1.39,6.83-0.82,10.23,0.35
				c9.7,3.33,18.72,18.03,17.03,27.97c-0.77,4.53-3.72,7.7-7,10.62c-2.98,2.65-6.05,5.21-8.89,8.02c-1.31,1.29-2.91,2.76-2.31,4.98
				c0.49,0.23,0.88,0.56,1.06,0.47c8.41-4.1,15.03-0.24,21.43,4.75c5.88,4.57,12.2,7.65,19.96,8.09c7.3,0.42,9.97,4.26,9.8,11.7
				c-0.08,3.48-1.16,6.89-0.38,10.43c0.79,3.61,2.72,6.1,6.29,7c3.5,0.88,5.59-1.42,7.46-3.86c1.95-2.54,3.77-5.37,3.76-8.55
				c-0.01-5.05,2.92-6.62,7.09-7.29c1.48-0.24,3.11-1,4.4-0.62c5.5,1.64,8.69-1.57,12.05-5c5.12-5.22,11.93-3.43,14.24,3.51
				c0.68,2.04,1.41,4.12,2.53,5.93c2.9,4.69,6.61,5.61,11.44,2.95c3.69-2.03,7.47-3.67,11.76-3.71c5.63-0.05,9.86,2.11,12.55,7.33
				c3.97,7.68,7.14,9.32,15.32,6.79c7.01-2.17,13-0.45,19.27,2.88c-2.82,2.73-7.11,3.63-8.96,7.83c4.52,0,8.59,0,12.65,0
				c3.79,0,6.69,1.33,8.46,4.97c2.07,4.25,6.01,6,10.38,7c5.51,1.25,11.11,2.27,15.63,6.09c0.12,0.1,0.3,0.23,0.44,0.22
				c10.5-0.48,20.47,6.07,31.58,0.97c8.44-3.87,17.83-4.44,25.7,2.83c1.61,1.49,3.49,2.54,5.67,3.01c5.88,1.27,10.89,4.04,14.75,8.72
				c3.03,3.68,7.04,4.11,11.35,3.36c8.2-1.43,16.4,1.05,24.73-0.5c4.22-0.78,8.49,2.21,12,5.3c6.21,5.45,13.16,5.94,20.18,1.81
				c1.01-0.6,1.57-1.33,1.4-2.36c-0.75-4.53-0.51-9.61-5.45-12.16c-2.9-1.49-3.95-3.94-4.25-6.85c-0.21-2.04-0.27-4.37-1.93-5.49
				c-5.48-3.74-9.38-9.24-14.81-12.93c-1.59-1.08-2.27-1.98-2.41-3.84c-1.02-13.62-2.39-15.13-15.81-17.96
				C392.93,232.52,392.36,232.26,391.75,232.05z"/>
			<path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M108.33,607.51c-3.7,2.06-7.25,4.03-11.75,6.54c2.17-10.21,1.43-19.4,0.51-29.25
				c-7.69,4.53-11.6-1.32-16.93-4.78c-3.47,6.69-4.9,13.69-4.63,21.04c0.27,7.35-1.45,14.49-2.37,21.26
				c0.89,1.53,1.96,1.57,2.92,1.56c4.66-0.06,6.14,2.48,5.65,6.71c-0.49,4.26,0.37,7.83,4.36,10.33c2.05,1.28,2.52,3.87,3.16,6.09
				c4.44,15.36,4.37,30.82,1.01,46.6c-2.47,0.46-5.13-0.29-6.88,1.71c-2.5-1.22-0.61-4.57-4.06-5.71
				c-6.87,4.85-10.59,13.04-16.09,20.28c-1.73-3.12-3.24-6.34-7.33-3.21c-1.47,1.12-2.95,0-3.62-1.49c-0.87-1.96-1.39-3.96-1.03-6.26
				c2.17-13.97,3.44-28.06,6.19-41.95c0.91-4.62,0.74-9.11-1.54-13.57c-3.31-6.47-2.93-13.17-1.55-20.36
				c3-15.56,8.08-30.47,12.72-45.53c0.22-0.73,0.36-1.49-0.01-2.51c-7.64,8.56-11.29,19.83-18.8,28.45c-3.83-2.3-4.73-5.49-5.4-8.75
				c-1.11-5.43-1.38-11-0.62-16.41c1.27-9.11-1.65-17.82-1.75-26.77c-0.01-0.8-0.15-1.68-1.35-2.21c-3.76,1.86-5.02,6.13-7.86,9.42
				c-0.21-0.65-0.52-1.09-0.42-1.41c1.26-4.15-0.11-7.11-3.76-9.37c-3.95-2.45-5.39-6.55-6.58-10.79c-0.61-2.18-1.15-4-4.5-4.21
				c-3.81-0.24-5.51-3.91-6.4-7.42c-1.51-5.93-0.67-11.92-0.26-17.89c0.41-6.07,1.42-11.96-0.98-18.1
				c-1.85-4.73-0.88-10.21-0.82-15.37c0.02-1.84,0.41-3.97-1.02-5.25c-6.53-5.81-4.46-13.33-4.52-21.25
				c6.78-0.11,13.42-0.94,20.29,0.82c-1.92,8.28-5.92,15.4-8.34,22.91c2.59,1.29,3.52-1.17,5.4-0.88c-0.94,6.62-4.51,12.42-5.8,19.24
				c3.76,0.22,4.72-3.32,8.44-4.69c-5.6,13.24-9.54,26.11-9.11,39.79c2.74,0.95,4.01-2,6.24-1.65c1.25,1.82-0.51,3.96,0.88,6.12
				c3.71-2.43,6.57-5.49,7.95-9.64c5.45-16.44,12.36-32.28,19.13-48.2c2.53-5.95,4.43-12.19,6.19-18.43c1.15-4.07,3.36-5.79,7.48-5.5
				c2.3,0.16,4.62,0.03,7.66,0.03c-7.23,23.27-14.36,46.37-25.47,68.15c2.63,0.81,2.8-0.42,3.13-1.25
				c6.23-15.56,14.42-30.32,18.14-46.9c1.4-6.24,5.24-11.83,6.04-18.4c0.15-1.26,0.67-2.53,2.43-2.71c4.6-0.45,9.21-1.68,13.97,0.44
				c-1.55,4.08-3.37,7.71-6.63,10.86c-6.9,6.65-12.15,14.47-14.59,23.97c-3.53,13.75-11.13,25.95-15.27,39.48
				c-0.51,1.65-1.74,3.15-1.23,5.25c2.25-1.18,2.39-3.34,3.04-5.1c5.47-14.79,13.42-28.58,17.43-43.99
				c1.43-5.48,5.16-10.12,8.88-14.33c3.96-4.47,8.05-8.61,10.67-14.2c1.95-4.15,5.85-0.59,10.36-1.39
				c-8.96,14.42-9.42,30.29-14.26,44.73c-4.87,14.49-8.41,29.08-12.22,44.87c3.51-1.67,4.82-3.81,6.11-5.8
				c2.63-4.04,5.31-8.09,7.47-12.39c4.07-8.1,8.8-15.39,16.56-20.68c4.87-3.31,7-9.2,9.75-14.32c3.17-5.89,6.7-11.17,12.67-14.96
				c6.53-4.15,11.12-10.63,14.53-17.5c2.42-4.87,5.44-6.16,10.14-4.59c-1.25,12.04-2.45,23.8-10.17,34.25
				c-5.57,7.55-8.33,16.96-10.87,26.14c-0.54,1.93-0.83,3.87-0.43,5.75c1.86,0.72,2.24-0.6,2.73-1.6c2.56-5.22,4.76-10.56,6.71-16.08
				c4.63-13.11,11.05-25.24,22.1-34.34c3.89-3.2,5.33-8.01,6.95-12.5c1.23-3.39,3.18-4.47,6.43-3.7c4.02,0.95,8.3,0.46,12.32,2.16
				c-2.09,7.01-4.89,12.76-11.34,17.44c-11.32,8.22-19.37,19.59-23.85,33.06c-2.58,7.77-6.01,15.12-9.74,22.37
				c-0.78,1.52-1.94,3-1.79,6.31c4.84-7.1,8.32-13.48,10.64-20.33c4.23-12.52,10.72-23.57,19.98-32.98c1.98-2.01,3.84-4.44,6.27-5.64
				c6.6-3.26,9.95-8.79,11.31-15.51c0.92-4.56,3.51-5.85,7.54-6.02c3.82-0.15,7.63-0.37,11.67,1.19c-3.34,6.41-6.12,12.87-9.98,18.9
				c-8.41,13.14-17.36,26.06-21.39,41.49c-0.32,1.21-1.4,2.36-0.12,3.67c2.11-0.45,1.97-2.46,2.63-3.86
				c3.37-7.24,6.46-14.61,10.06-21.73c3.04-6.01,7.31-11.11,13.2-14.66c4.1-2.47,6.7-6.43,9.75-9.93c2.67-3.07,5.63-5.58,9.58-6.69
				c1.43,1.43-0.81,2.99,1.02,4.73c4.17-2.39,7.12-6.38,10.22-10.03c1.98-2.33,4.08-3.76,7.06-4.06c4.17-0.42,8.18-1.48,11.92-3.41
				c2.71-1.4,5.59-0.93,8.32-0.44c8.62,1.55,16.98,4.73,26.41,3.61c-1.44,5.22-2.38,9.74-6.38,13.19
				c-5.32,4.58-8.02,11.01-10.25,17.47c-9.11,26.45-17.11,53.19-20.04,81.18c-0.75,7.2-0.17,14.32,1.12,21.37
				c1.03,5.62,1.17,10.99-0.09,16.7c-2.33,10.54-4.03,21.25-4.3,32.11c-0.18,7.08-2.93,13.4-5.46,19.5
				c-6.59-3.33-7.39-9.31-7.01-15.88c0.3-5.1-4.44-7.76-7.1-10.37c-3.69-3.63-6.38-7.36-8.53-11.86c-0.67-1.4-1.41-3.01-3.28-3.9
				c-2.34,3.16-2.63,6.9-2.23,10.37c1.92,16.96-0.33,33.97,0.74,50.92c0.93,14.75,2.08,29.48,3.85,44.19c0.8-11-0.6-21.91-1.23-32.88
				c-0.66-11.42-2.03-22.91-0.67-34.4c1.33-11.23-1.24-22.47-0.46-33.76c0.85,0.09,1.38,0.06,1.4,0.16
				c1.86,6.9,7.71,10.95,11.93,16.05c3.25,3.94,3.2,8.08,3.77,12.44c0.43,3.31-0.68,7.2,4.69,8.34c2.12,0.45,2.62,4.83,6.14,5.74
				c1.19-8.51,5.99-15.85,6.15-24.68c0.2-10.81,2.05-21.51,4.39-32.42c1.15,10.24,8,17.43,13.47,25.21
				c-6.71,5.79-7.44,8.64-3.19,15.87c3.35,5.69,4.62,11.47,4.15,18.07c-0.77,10.84,1.94,21.52,2.27,32.35
				c0.42,13.66-0.35,27.31-0.39,40.96c-0.01,2.25-0.38,4.65,1.64,6.82c3.87-2.85,2.44-8.18,5.69-11.63c3.03,1.47,5.64,4.87,9.86,3.15
				c0.58-0.24,2.51,1.32,2.9,2.38c1.17,3.14,3.36,5.27,6.53,7.36c-5.11,9.59-10.03,19.16-9.66,30.48
				c6.79-7.08,8.99-16.54,12.66-26.07c1.45,2.68,0.26,4.5-0.31,6.13c-3.73,10.71-6.89,21.45-6.96,33.01
				c-0.04,6.94-4.44,13.17-4.32,20.36c0.13,7.8-1.44,15.61,0.01,23.41c0.42,2.24,0.69,4.42,4.01,4.75c2.4,0.24,3.52,2.84,4.46,5
				c1.31,3.01,2.53,6.05,3.99,9.55c5.44-4.55,1.69-10.51,3.95-15.46c-0.69,7.35,1.92,13.86,4.06,21.73c4.16-5.28,10.2-5.28,14.65-8.4
				c2.49,0.8,2.73,2.94,3.2,4.82c0.61,2.43,0.9,5.76,4.04,5.28c3.32-0.51,7.48-0.91,8.51-5.5c0.22-0.96,0.51-1.91,0.76-2.83
				c5.13-0.14,7.68-2.52,6.62-7.2c-1.34-5.88,0.18-10.79,3.6-15.87c2.47,1.98,5.02,2.06,7.08-1.37c2.64,4.88,3.08,9.67,1.2,13.56
				c-2.37,4.88-2.33,9.45-1.67,14.47c0.35,2.72,0.87,5.6-0.44,8.48c-6.57-0.7-12.16,0.9-15.7,7c-0.24,0.41-0.72,0.98-1.08,0.97
				c-6.52-0.15-9.4,6.3-14.81,8.14c-0.77,0.26-1.89,0.67-2.08,1.26c-2.13,6.59-8.92,8.46-12.98,13.04c-3.13,3.53-6.48,4.61-10.48,1.8
				c-3.52-2.48-6.68-2.89-9.49,0.93c-0.78,1.05-1.73,1.17-3.03,1.15c-4.21-0.06-8.42,3.43-7.66,7.65c1.75,9.66,0.26,19.74,4.09,29.1
				c0.81,1.97,1.2,4.15,1.5,6.28c0.5,3.58,1.59,6.51,5.76,7c1.96,0.23,2.26,1.79,2.42,3.28c0.41,3.8,0.92,7.61,0.99,11.43
				c0.1,4.88,0.01,9.12,3.44,13.77c3.52,4.78,2.33,11.98,2.2,18.03c-0.42,19.81-0.28,39.63-0.46,59.44
				c-0.06,6.24,0.39,12.54-1.01,19.38c-3.07-2.97-3.54-6.66-5.71-9.63c-2.58,1.61-2.21,4.82-4.54,7.09
				c-2.86-9.35-5.7-18.39-8.39-27.47c-6.79-22.83-14.16-45.41-26.06-66.23c-6.39-11.19-8.25-23.8-10.9-36.14
				c-2.42-11.26-4.98-22.35-12.47-31.71c-2.77-3.47-3.74-8.12-4.88-12.48c-1.78-6.8-4.11-13.48-7.91-19.33
				c-9.87-15.21-15.49-32.37-23.12-48.61c-4.27-9.09-8.08-18.14-7.74-28.44c0.07-2.24-1.26-4.17-2.47-5.91
				c-5.73-8.22-9.53-17.52-13.46-26.55c-2.31-5.31-6.41-10.08-7.4-15.27c-1.1-5.72-2.97-10.83-5.66-15.63
				c-2.82-5.04-3.67-10.37-4.76-15.84c-1.99-10.09-9.66-17.16-14.59-25.69c-1.73-2.99-5.23-4.63-8.35-5.02
				c-4.16-0.53-6.65,3.45-8.83,6.22c-1.98,2.52-4.53,4.31-6.65,6.58c-3.14,3.36-6.62,1.88-7.54-2.83c-1.32-6.75-0.46-13.37,2.1-19.68
				c1.06-2.62,1.16-5.08,0.8-7.78c-1.47-10.96-1.6-21.97-1.25-33.01c0.07-2.08,0.66-3.95,2.04-5.5
				C113.15,618.5,113.15,613.31,108.33,607.51z M194.15,674.9c2.38-2.21,2.55-4.47,3.43-6.38c3.18-6.94,1.89-14.39,2.51-21.62
				c0.96-11.1,1.72-22.22,2.46-33.34c0.24-3.64-0.05-6.51-5.38-5.68c-3.89,0.6-6.75-2.3-8.74-5.61c-2.26-3.75-3.42-7.92-4.52-12.1
				c-0.33-1.27-0.16-2.96-1.48-3.56c-4.77-2.16-6.91-8.63-13.67-7.79c-2.3,0.29-4.29-2.3-5.65-4.48c-1.97-3.16-2.98-6.64-3.48-10.31
				c-1.09-7.96-0.95-15.92-0.28-23.9c0.16-1.88,1.27-4.13-1.83-6.7c-3.16,22.68-3.62,44.68-6.82,66.19
				c13.87,2.32,9.49,14.62,13.69,22.01c3.85-0.33,6.1-3.9,9.65-4.87c1.92,1.79,2.52,4.26,3.28,6.6c3.16,9.79,3.36,19.98,3.71,30.1
				c0.43,12.33-1.18,24.57-2.24,36.83c-0.16,1.82-1.05,3.86,1.11,5.96c6.46-10.79,7.82-23.01,11.92-34.68
				C193.82,665.87,192.67,670.18,194.15,674.9z M244.17,730.73c3.64,5.92,7.18,9.87,13.91,10.66c3.5,0.41,6.46,4.29,10.4,5.4
				c1.16,0.33,0.89,2.04,0.88,3.16c-0.07,11.82,0.08,23.63-0.37,35.45c-0.58,15.27-2.68,30.7,0.36,45.81
				c1.91,9.48,3.26,18.94,4.29,28.51c0.56,5.17,0.7,10.55,3.42,15.36c0.36-0.95,0.52-1.91,0.34-2.81
				c-2.71-13.51-1.88-27.45-5.59-40.92c-2.89-10.5-1.12-21.54-0.81-32.34c0.47-15.97,1.31-31.94,0.98-47.92
				c-0.04-2.09,0.56-5.36-1.39-6c-4.79-1.58-8.27-6.05-13.02-6.62c-4.64-0.55-7.18-2.74-9.5-6.18c-3.17-4.71-5.84-4.51-8.42,0.66
				c-1.67,3.34-2.85,6.94-5.69,10.95c-0.25-3.45-0.37-5.86-0.61-8.26c-0.15-1.51-0.37-3.3-2.5-2.92c-3.81,0.68-5.24-2.09-7.05-4.44
				c-1.77-2.31-2.23-5.54-5.29-7.51c-3.18,4.67-3.4,9.81-3.31,14.88c0.52,28.63,0.48,57.24-1.2,85.84c-0.04,0.62-0.08,1.38,1.24,1.37
				c0.16-1.02,0.42-2.1,0.49-3.2c1.37-19.76,2.26-39.53,1.89-59.35c-0.18-9.48-0.02-18.97,0.01-28.46c0.01-2.09,0.04-4.21,1.62-6.24
				c2.58,3.44,4,7.98,8.25,9.18c3.21,0.9,3.63,2.67,3.71,5.37c0.09,2.85-0.07,5.91,2.93,8.28
				C238.39,743.15,239.85,736.59,244.17,730.73z M265.29,919.85c0.43-0.06,0.86-0.12,1.29-0.17c0.15-1.31,0.52-2.64,0.4-3.92
				c-1.68-17.95,0.21-35.98-0.97-53.88c-0.78-11.8-1.53-23.56-1.34-35.37c0.35-22.31,0.17-44.61-0.82-66.91
				c-0.16-3.59-0.9-5.6-4.96-3.91c-1.59,0.66-3.88,1.12-4.69-0.25c-2.86-4.8-8.25-3.85-12.99-6.78c-4.38,15.82,0.52,31.28-1.17,46.52
				c-1.7,15.28-0.8,30.7-0.07,46.07c0.33-14.81,0.58-29.63,1.94-44.33c1.37-14.82-1.57-29.66,0.73-44.98
				c1.79,3.66,5.34,0.45,6.86,2.46c3.01,3.99,6.75,5.08,11.75,3.63C263.13,812.21,261.42,866.12,265.29,919.85z M232.07,855.92
				c1.38-31,2.75-62,4.19-94.28C233.19,770.27,229.43,849.29,232.07,855.92z"/>
			<path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M513.53,1046.11c1.22-0.46,0.15-2.6,2.24-3.2c2.88,0.28,1.71,5.85,6.3,5.37c2.62-0.28,6.15-2.69,8.98,1.41
				c-0.38-7.16,8.6-8.22,8.37-15.69c6.12,1.99,11.08,0.75,14.86-4.16c0.77-1,1.84-0.08,2.75,0.18c2.27,0.65,4.3,0.49,5.72-1.7
				c1.37-2.13,2.12-5.01,0.27-6.48c-4.72-3.75-2.53-8.5-2.7-12.92c-0.03-0.73,0.48-2.04,0.95-2.14c6.53-1.39,7.53-7.64,9.01-12.12
				c1.34-4.05,5.91-5.74,5.43-10.45c2.13,0.71,3.82,1.26,5.26,1.74c1.66-0.68,1.32-2.11,1.84-3.09c0.8-1.5,2.04-2.25,3.64-2.09
				c1.45,0.15,2.3,1.11,2.53,2.65c0.47,3.05,1.94,4.58,5.16,3.1c1.75-0.81,3.47,0.12,4.78,0.75c4.68,2.26,10.84,0.41,14.53,5.65
				c1.6,2.28,4.2,0.99,6.45-0.45c3.3,4.67,6.67,9.25,13.37,5.58c0.51-0.28,1.57,0.22,2.26,0.56c7.22,3.57,14.4-1.05,13.97-9.36
				c-0.23-4.53-0.4-8.76,1.54-13.09c1.18-2.63,2.52-3.73,5.21-3.32c4.5,0.68,6.84-1.87,9.99-4.81c3.78-3.52,7.14-7.58,11.57-10.74
				c4.42-3.16,8.07-4.75,13.23-4.62c2.17,0.05,2.56-3.33,4.57-4.28c5.65,3.29,11.31,4.15,18.04,3.56c4.83-0.42,8.93-0.86,12.71-3.78
				c1.8-1.39,3.12,0.01,4.44,0.92c3.01,2.08,5.68,1.76,9.25,0.57c5.06-1.7,10.75-3.08,15.77,1.01c0.96,0.78,1.9,1.99,3.75,1.15
				c-1.79-4.6-3.33-9.3-5.43-13.73c-1.64-3.46-0.19-5.1,2.81-4.84c3.67,0.32,8.15-0.29,10.05,4.47c0.64,1.62,1.96,2.64,3.6,3.25
				c5.05,1.89,9.42,4.44,13.65,8.17c3.41,3.01,6.73,8.54,13.08,7.01c0.46-0.11,1.24-0.11,1.45,0.16c3.56,4.72,9,6.21,13.99,8.6
				c2.06,0.99,3.64,2.8,6.26,2.26c0.77-0.16,2.13-0.17,2.37,0.24c2.92,5.04,8.58,6.21,13.12,7.57c8.67,2.6,13.03,10.97,21.6,13.74
				c6.71,2.17,11.61,8.21,16.64,13.24c3.48,3.49,5.19,8.28,4.37,13.33c-0.9,5.55,0.81,10.08,4.07,14.39
				c3.23,4.26,2.44,6.64-2.56,9.15c-9.94,4.98-20.61,7.33-31.48,9.1c-15.29,2.5-30.98,1.97-46.04,5.27
				c-10.13,2.22-19.85,6.33-30.38,7.13c-5.14,0.39-10.27,0.93-15.38,1.54c-4.3,0.51-8.45,1.32-12.22,3.86
				c-4.32,2.92-9.58,2.66-14.5,3.01c-14.43,1.01-28.11,5.01-41.62,9.8c-1.85,0.66-3.69,1.61-5.26,2.78
				c-6.6,4.92-6.08,12.51,1.25,16.6c3.05,1.71,5.66,2.9,6.26,7.21c0.54,3.87,4.53,6.04,7.94,7.81c5.26,2.74,8.56,5.95,6.04,12.57
				c-1.32,3.46,1.31,6.56,2.31,10.14c-3.5-2.06-6.94-4.24-10.52-6.15c-5.06-2.69-9.37-3.22-14.27,1.66
				c-4.44,4.42-11.52,3.63-17.49,4.33c-7.14,0.84-9.02,1.93-10.42,9.26c-0.09,0.49-0.05,1.14-0.34,1.43
				c-8.07,8.08-8.38,21.06-18.14,28.22c-4.25,3.12-4.87,8.75-5.16,13.66c-0.38,6.5-4.34,10.78-8.51,14.48
				c-4.09,3.62-5.45,7.75-5.76,12.67c-0.85,13.66,0.32,27.3,0.56,40.94c0.07,4.03-0.31,7.87-1.57,11.77
				c-2.52,7.82-1.13,15.83-0.59,23.79c0.74,10.93,1.34,21.88,2,32.68c-2.34,1.13-4.06,0.38-5.87,0.11c-0.96-2.47-0.31-4.97-0.13-7.4
				c0.58-7.77-2.4-13.93-8.07-19c-1.74-1.55-3.42-3.17-5.16-4.72c-8.71-7.8-11.37-17.39-9.55-28.87c1.77-11.16,4.3-22.23,4.91-33.56
				c0.16-2.98,0.47-6.09-0.17-8.94c-1.04-4.59,0.22-7.55,4.48-9.43c1.01-0.44,2.01-1.19,2.31-2.62
				c-13.45-7.52-22.96-20.14-36.51-27.86c-10.17-5.8-20.27-7.2-31.57-5.95c-23.75,2.64-47.42,6.99-71.51,4.6
				c-2.15-0.21-4.34-0.33-6.42-0.85c-12.07-3-23.87-1.47-36.03,0.32c-11.31,1.66-20.14-5.35-27.19-13.91
				c-4.02-4.88-7.04-10.16-4.63-17.12c4.6,0.12,9.74,1.28,13.06-3.56c2.73-3.98,3.04-9.2,6.64-13.06c3.86,1.96,5.15,5.69,6.23,9.49
				c1.45,5.12,2.59,10.33,4.18,15.4c0.72,2.29,1.84,5.32,4.89,4.75c2.88-0.54,3.1-3.55,3.19-6.09c0.31-9.08-1.12-17.91-4.01-26.5
				c-7.92-23.53-37.07-31.19-55.01-9.96c-4.82,5.7-9.27,5.56-13.89-0.12c-4.79-5.9-10.56-9.72-18.6-7.88
				c-2.7,0.62-3.75-0.7-4.14-2.92c-1.06-5.93-0.37-11.6,2.82-16.84c0.91-1.5,2.11-2.71,3.93-2.71c2.34,0.01,4.16-0.97,5.8-2.48
				c0.61-0.56,1.3-1.24,2.18-0.91c1.96,0.73,3.8,2.33,5.91,0.26c2.24-2.19,3.37-4.93,4.02-7.89c1.29-5.85,11.11-10.96,16.41-8.27
				c2.72,1.37,4.63,0.46,6.81-1.11c7.31-5.25,9.22-4.92,14.75,2.14c3.59,4.59,5.88,5.2,10.46,1.72c2.78-2.11,4.5-1.31,6.32,0.79
				c2.07,2.39,3.93,4.95,6.03,7.31c2.39,2.7,5.25,4.29,8.78,2.1c2.49-1.54,3.83-0.33,5.46,1.45c3.17,3.48,7.92,4.38,11.62,1.53
				c5.61-4.33,11.25-8.38,18.07-10.6c1.58-0.52,3.31-1.83,3.51-3.34c0.65-4.92,5.94-7.51,5.97-12.64c0.01-1.22,1.32-1.36,2.13-1.85
				c2.32-1.43,3.4-3.37,3.47-6.17c0.14-5.51,2.82-7.7,8.44-7.14c1.43,0.14,2.63-0.17,3.74-1c0.96-0.72,2.3-1.6,3.18-0.84
				c6,5.17,11.98,1.16,18.03,0.32C507.83,1042.11,509.61,1045.99,513.53,1046.11z"/>
			<path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M393.94,806.9c1.8-8.84,7.44-10.46,15.8-6.82c3.11,1.35,7.41-2.22,10.23,2.42
				c1.99,3.27,7.22,2.6,8.62,7.31c3.6-6.8,1.98-14.95-2.89-18.94c-4.14-3.39-7.32-7.83-10-12.28c-3.04-5.05-6.31-9.29-11.8-11.63
				c-0.86-0.37-1.51-1.21-2.48-2.02c1.86-3.93,4.07-7.65,4.23-12.39c3.81,0.37,5.59-1.74,7.44-4.62c1.9-2.96,4.57-5.92,9.09-3.8
				c1.62,0.76,3.27-0.3,4.79-0.9c4.67-1.85,9.44,0.49,14.18-0.43c1.38-0.27,2.69,1.19,3.32,3.1c2.33,6.97,4.56,8.08,12.07,6.7
				c1.71-5.2-5.8-11.51,2.47-16.9c0.64,13.28,2.01,25.62,7.86,37.16c0.44-2.98,1.04-5.95,1.29-8.95c0.59-7,2.41-13.84,1.54-21.07
				c-0.5-4.13-1.09-9.5,6.62-9.28c2.62,0.07,5.88-2.58,4.49-7.57c-2.72-9.76-4.14-19.84-4.51-30.03c-0.06-1.54,0.65-3.4-1.18-4.49
				c-1.33,0.14-1.53,1.19-1.78,2.13c-0.42,1.57-0.33,3.7-2.25,4.09c-2.2,0.45-3.49-1.49-4.94-2.85c-2.61-2.44-2.22-6.44-4.45-9.07
				c-2.4,1.02-1.01,3.16-1.91,4.55c-2.17-2.4-1.1-6.33-4.68-9.38c-0.57,11.72,3.12,22.15,2.62,32.76c-0.53,0.13-0.83,0.27-1.13,0.28
				c-9.23,0.39-10.05-6.14-12.36-13.06c-3.14-9.39-5.6-19.14-10.33-29.51c-2.93,6.89-3.53,12.86-4.09,18.86
				c-0.19,2.07,0.37,4.41-1.39,6.07c-2.44,2.29-5.06,2.54-7.97-0.02c1.91-5.85-3.51-10.19-3.41-16.57c-2.95,2.31-5.35,4.09-8.87,2.71
				c-1.87-0.74-2.4,1.47-2.46,2.91c-0.09,1.98,0.07,4,0.38,5.96c0.73,4.64-0.78,8.39-4.01,11.48c-2.16,0.15-2.72-1.99-4.37-2.57
				c-3.64,0.59-3.49,4.32-5.03,6.42c-7.96-2.4-15.39-6.19-24.15-3.8c-4.51,1.23-8.37-2.57-12.61-3.81c-1.42-0.41-0.82-3-0.82-4.64
				c-0.02-5.99-0.03-12-1.51-17.86c-1.33-5.24-2.75-6.01-7.96-4.26c-1.07,0.36-1.42-0.27-2.12-0.42c-3.12-0.67-4.95,0.36-5.33,3.81
				c-0.36,3.22,0.43,7.13-4.39,8.19c-1.06,0.23-0.7,2.06-0.72,3.2c-0.02,2.16-0.01,4.32-0.32,6.44c-1.13-8.01-2.54-15.54,2.47-23.59
				c2.62-4.22,7.09-9.62,4.06-16.1c-0.84-1.8-1.02-4.44,1.29-5.14c2.64-0.8,2.97,1.95,3.64,3.75c2.79,7.53,3.54,8.25,9,8.05
				c-0.03,2.89,0.43,5.27,3.05,7.58c2.81,2.48,2.23,7.09,2.99,10.81c0.7,3.41,1.22,6.86,2.03,10.25c0.42,1.76,1.42,3.31,4.16,4.55
				c1.5-5.49,1.38-10.63,0.6-15.76c-0.42-2.8-1.04-5.58-0.73-8.41c0.58-5.22,3.25-6.97,8.09-5.29c2.77,0.96,4.49-0.23,5.78-2.46
				c2.58-4.48,6.05-5.56,10.91-3.55c3.34,1.38,7.12-1.56,9.55-6.82c1.73-3.75,3.45-7.51,5.1-11.13c5.75-0.27,6,7.36,12.07,7.28
				c-0.18-5.6-1.25-11.14-0.54-16.67c0.17-1.32-0.09-2.74,0.32-3.96c0.8-2.4,2.16-4,4.6-1.43c2.17,2.28,4.91,3.62,7.94,3.72
				c3.49,0.11,4.45,2.17,4.99,4.99c0.98,5.06,2.01,10.1,3.07,15.15c0.75,3.56,2.38,6.06,6.58,6.17c2.83,0.08,4.89,1.72,6.17,4.38
				c0.69,1.44,1.8,2.68,3.08,4.54c1.61-2.9,2.85-5.26,5.9-5.99c2.41-0.58,2.47-2.8,2-4.94c-0.9-4.05-1.9-8.08-2.57-12.16
				c-0.29-1.8-0.62-4,1.99-5.41c2.04,4.21,1.47,8.59,2.16,12.7c1.12,6.62,5.26,12.06,6.71,18.62c0.88,4,5.33,4.37,8.78,3.69
				c4.06-0.79,2.19-4.19,1.84-6.57c-1.77-12-2.85-24.11-6.26-35.84c-0.61-2.09-1.16-4.55,0.33-6.93c4.97,0.54,4.42,4.74,4.9,7.84
				c1.27,8.21,2.17,16.47,3.23,24.71c0.32,2.5,1.07,4.88,2.42,7.02c1.03,1.63,2.26,2.8,4.52,2.08c2.12-0.67,3.46-1.61,3.42-4.13
				c-0.12-7.82,0.07-15.65-0.15-23.47c-0.16-5.77,1.65-11.15,2.72-16.68c0.21-1.11,0.39-2.31,1.95-2.51c2.9-0.38,4.81-2.76,8.37-4.36
				c0,3.58,0.22,6.66-0.04,9.69c-0.75,8.54,0.36,16.95,1.38,25.37c0.32,2.64,0.52,5.36,3.1,7.03c1.49,0.96,0.79,2.39,0.66,3.64
				c-1.98,19.59-1.97,39.22-1.66,58.87c0.19,11.78-2.88,23.47-0.76,35.34c0.67,3.77,0.88,7.44,3.2,10.8
				c2.31,3.34,2.41,7.56,2.92,11.5c2.19,16.83,2.16,34.04,6.12,50.52c6.52,27.16,8.17,55.28,16.76,82.02
				c1.84,5.73,3.11,11.37,7.79,15.77c2.48,2.32,2.29,6.16,2.26,9.49c-0.11,15.48-1.86,30.93-0.8,46.42c0.48,7,0.76,13.77,4,20.65
				c4.15,8.81,2.29,19.01,1.61,28.59c-0.27,3.82-4.24,4.92-7.83,4.28c-4.28-0.77-6.13,0.48-8.17,4.73c-1.97,4.12-5.37,8.94-11.3,8.69
				c-2.1-0.09-5.14,0.53-5.95-2.99c-0.25-1.09-1.74-2.23-2.7-1.87c-7.1,2.67-14.89-3.04-21.74,1.87c-0.25,0.18-0.77,0.34-0.92,0.21
				c-4.37-3.65-8.93-2.35-14.01-1.54c-4.79,0.76-10.51,3.35-10.63,8.18c-0.11,4.45-1.99,6.37-4.9,6.72
				c-4.74,7.19-4.76,16.6-15.49,18.27c-3.44,0.54-6.53,4.14-9.36,6.92c-4.77,4.68-6.51,4.67-10.97-0.2c-2.2-2.4-4.37-3.65-7.3-1.53
				c-2.93,2.12-4.93,0.67-6.77-1.58c-2.32-2.83-4.37-5.88-7.24-8.23c-2.52-2.07-4.93-2.64-7.5-0.13c-3.26,3.18-5.53,1.96-8.05-1.33
				c-5.52-7.21-9.96-7.75-17.37-2.32c-2.15,1.58-3.91,2.31-6.15,0.44c-1.61-1.35-3.27-1.17-5.07-0.17
				c-3.47,1.92-6.99,3.74-10.55,5.49c-1.49,0.74-2.2,1.82-2.59,3.39c-1.11,4.43-0.41,10.98-8.51,7.7
				c-4.64,4.44-13.46,2.99-15.4,11.36c-3.86-31.5-3.24-62.93-4.87-94.23c-1.95,1.57-1.31,3.4-1.28,5.09
				c0.33,19.48,0.22,38.98,1.22,58.43c0.75,14.61,2.12,29.2,2.57,43.84c0.07,2.41,1.88,3.74,2.83,5.82
				c-5.55,4.73-9.09,10.68-12.19,17.1c-3.84-0.85-6.74-2.75-9.21-5.56c2.01-1.65,6.46,3.59,6.67-2.83c1.38-0.44,3.66,1.14,5.51-1.76
				c-3.78-2.59-8.29-4.55-10.84-9.03c2.6,0.01,4.7,1.91,7.62,1.38c-0.55-2.47,1.04-3.62,4.05-4.01c-5.56-4.99-12.07-7.92-14.82-14.57
				c4.58-1.59,9.09,1.61,14.2-0.28c-5.36-5.72-13.35-7.59-17.3-14.42c2.1-1.35,4.07-2.62,6.97-4.49c-9.15-4.46-7.51-12.08-6.93-19.55
				c1.58-20.43,0.54-40.9,0.93-61.35c0.04-2.29-0.42-4.77,2.2-6.15c0.16,0.01,0.35-0.03,0.47,0.04c7.04,4.08,7.27,4.26,12.76-2.56
				c2.15-2.67,4.22,0.32,6.46-1.37c-0.56,34.06,0.94,67.66,3.47,101.26c1.43-1.52,0.9-3.3,0.87-4.97
				c-0.39-22.8-2.31-45.56-1.83-68.39c0.14-6.82,0.46-13.64,0.68-20.45c0.09-2.69-0.27-5.34-1.22-7.85
				c-1.51-4.02-6.41-4.72-9.14-1.35c-0.63,0.77-1.12,1.66-1.73,2.44c-2.2,2.8-4.45,5.27-7.9,1.45c-1.26-1.39-3.02-1-4.61-1.01
				c-0.55-5.91-0.55-5.91,2.33-8.62c2.61,1.86,5.28,4.95,8.71,1.19c7.34,4.26,7.89-2.86,10.78-6.29c1.47-1.75,1.83-4.56,5.16-4.84
				c1.56-0.13,1.45-2.77,1.4-4.4c-0.15-4.64-0.43-9.28-0.68-14.37c4.37,1.39,5.38-1.44,5.79-4.56c1.05-7.96,4.23-13.88,13.1-14.98
				c1.63-0.2,2.56-1.67,2.98-3.1c1.6-5.4,5.71-8.86,9.53-12.49c2.25-2.13,4.41-4.51,4.84-7.39c1-6.78,7.51-12.29,4.8-19.77
				c-2.13-5.88-2.15-11.36-0.08-17.37c2.13-6.2,0.98-12.22-3.17-17.88c-4.12-5.62-4.04-12.53-4.43-19.37
				c-0.37-6.45,0.92-12.39,2.43-18.44c2.07-8.27,7.12-10.87,14.83-7.62c2.82,1.19,5.43,3.16,8.87,1.11
				C389.75,804.68,391,807.8,393.94,806.9z M314.64,1050.34c-1.03-14.83,1.61-29.67,0.12-44.49
				C312.88,1020.68,312.72,1035.51,314.64,1050.34z"/>
			<path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M1161.07,1182.3c0,105.51,0,210.66,0,315.71c-1.7,0.8-3.16,0.62-4.67,0.32c-1.32-1.3-0.86-3.13-1.28-4.68
				c-1.84-6.73-6.54-9.85-13.17-7.75c-4.1,1.3-7.81-1.83-11.89,0.25c-2.24-5.28-5.83-8.18-11.62-7.65c-2.21,0.2-3.38-2.1-5.19-2.65
				c-7.84-2.39-12.38-9.48-19.26-13.18c-1.45-0.78-2.96-1.69-4.54-1.92c-1.74-0.26-2.34-1-3.07-2.53c-2.79-5.81-8.97-8.74-15.57-7.62
				c-1.23,0.21-2.09,0.06-3.18-0.69c-3.87-2.67-7.33-6.53-12.93-4.69c-1.16,0.38-1.86-1.2-2.74-1.9c-4.75-3.8-10-4.16-14.49-0.2
				c-3.56,3.14-6.31,3.25-9.13-0.53c-2.74-3.66-6.45-3.65-10.36-2.75c-1.93,0.45-3.81,1.16-5.73,1.67c-3.21,0.84-5.55,0.04-6.53-3.47
				c-1.24-4.45-4.64-5.9-8.84-6.89c-8.59-2.02-17.34-0.99-25.96-1.91c-3.02-0.32-4.87,1.76-6.94,3.37
				c-4.47-5.14-11.25-6.27-16.76-9.57c-3.2-1.92-6.92,1.94-10.2,4.35c-2.01-3.87-3.96-7.51-5.81-11.21c-2.32-4.66-3.1-9.33-0.7-14.35
				c2.25-4.7,1.79-9.41-1.41-13.66c-1.18-1.57-1.42-3.27-1.33-5.17c1.42-29.08-2.79-57.81-5.41-86.63c-0.43-4.76,0.3-8.08,4.68-10.29
				c2.52-1.27,4.92-2.77,7.38-4.15c1.16-0.65,2.68-0.98,3.46-1.92c4.11-4.98,7.98-10.09,13.19-14.12c2.24-1.73,2.13-4.81-0.24-7.11
				c5.48-5.98,12.5-9.15,18.73-13.82c3.28,39.34,0.15,78.74,9.39,117.21c-0.75-7.4-1.39-14.81-2.27-22.2
				c-1.66-13.91-2.19-27.9-2.08-41.87c0.13-16.17-1.56-32.26-1.74-48.4c-0.07-6.34,0.5-12.63,1.99-18.82
				c0.4-1.67,0.78-3.23,3.04-3.25c4.22-0.04,6.46-2.83,8.44-6.1c2.26-3.71,4.8-6.71,10.07-5.08c3.22,1,3.22-1.83,3.36-4.03
				c0.43-6.85-0.76-13.62-1.16-20.43c-0.24-4.14,0.51-6.61,5.27-6.78c6.41-0.23,9.59-4.58,11.72-11.49
				c2.01,6.57,6.29,8.06,11.22,8.68c2.75,0.35,5.63,0.53,6.7,4.03c0.4,1.31,1.94,1.95,3.35,1.63c5.6-1.27,10.53-0.75,13.63,6.1
				c4.9-11.02,5.3-20.84,0.26-31.47c7.08,1.1,13.02,1.35,17.92-3.21c0.46-0.43,1.67-0.39,2.4-0.16c4.92,1.57,9.49,0.38,14.27-2.01
				c0.89,5.16,5.39,9.28,1.63,14.98c-1.39,2.11-0.28,5.34,2.86,5.72c4.54,0.56,9.13,4.22,13.81,0.24c0.38-0.33,1.46-0.35,1.87-0.05
				c5.75,4.18,8.03,0.27,10.65-4.11c2.44-4.1,4.45-3.7,6.44,0.76c1.51,3.38,2,7.02,2.83,10.58c0.64,2.75,1.22,5.54,2.19,8.18
				c1.16,3.17,2.89,7.08,6.34,6.96c3.3-0.11,6.89-2.13,9.02-5.35c1.01-1.52,1.8-3.18,2.62-4.82c1.82-3.67,3.86-6.64,8.82-4.83
				C1157.22,1186.55,1158.7,1183.63,1161.07,1182.3z"/>
			<path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M624.91,1485.97c-17.3-4.12-35.11-7.72-50.95-15.36c-18.14-8.75-37.27-15-55.13-24.23
				c-1.03-0.53-2.12-0.98-3.23-1.28c-2.62-0.71-5.5-2.68-7.69,0.53c-2.21,3.25-0.56,6.28,1.76,8.49c2.59,2.47,4.9,4.48,3.33,8.62
				c-0.63,1.68,1,3.4,2.11,4.87c2.77,3.66,6.47,6.22,10.32,8.55c11.93,7.23,23.89,14.39,36.73,22.11
				c-6.26,0.43-11.39,0.95-16.46-2.05c-8.6-5.09-17.97-8.63-27.11-12.63c-4.73-2.07-9.47-4.18-13.47-7.52
				c-3.85-3.21-7.59-6.39-5.93-12.54c0.85-3.14-2.51-4.82-4.41-6.77c-2.78-2.86-4.66-5.71-1.66-9.7c-4.47-2.82-9.35-3.97-13.97-5.68
				c-10.78-3.99-21.78-7.31-33.11-9.16c-5.74-0.93-11.77-1.03-15.3,5.25c-0.75,1.34-1.92,1.16-3.05,0.85
				c-15.54-4.25-31.45-7.17-46.62-12.71c-4.02-1.47-7.63-3.63-10.69-7.02c-2.99-3.3-7.85-3.3-12.07-3.91
				c-9.7-1.41-19.53-1.95-29.14-4.02c-2.89-0.62-5.86-1.15-8.31-3.59c3.13-2.22,8.11-0.03,10.22-5c-5.03-3.18-10.82-3.53-16.43-4.34
				c-5.59-0.81-11.23-1.26-16.83-2.02c-3.82-0.52-7.04-2.9-10.49-4.09c-9.5-3.29-19.35-5.75-29.62-5.61
				c7.53,1.41,14.36,5.37,22.12,5.8c1.64,0.09,3.64,0.05,4.82,0.93c8.7,6.53,19.01,6.24,29.03,7.36c3.92,0.43,7.78,1.35,12.81,2.26
				c-3.61,2.11-7.39,0.58-9.55,3.82c0.9,3.63,4.19,4.68,7.23,5.68c7.47,2.45,15.29,3.12,23.04,4.09c3.63,0.46,7.31,0.61,10.91,1.23
				c4.87,0.83,9.37,1.91,13.3,5.82c4.61,4.58,11.12,6.58,17.41,8.25c12.21,3.23,24.48,6.24,36.74,9.29c3.49,0.87,6.24,1.29,8.5-3.22
				c1.77-3.53,6.75-3.07,10.53-2.59c10.28,1.29,20.06,4.67,29.89,7.74c5.06,1.58,9.97,3.62,15.29,5.58
				c-1.87,5.12,1.48,8.13,4.35,11.26c1.74,1.91,2.81,3.54,2.14,6.53c-0.92,4.11,1.92,7.25,4.66,9.96c4.07,4.03,9.05,6.75,14.23,9.08
				c7.58,3.41,15.21,6.72,22.77,10.19c1.59,0.73,3.46,1.16,4.8,3.19c-91.53,0-182.76,0-274.53,0c2.94-6.82,1.09-11.88-4.89-15.53
				c-1.34-0.82-0.31-2.08-0.38-3.15c-0.32-5.23-1.05-10.45-5.92-13.58c-2.69-1.73-2.56-4.19-2.34-6.58
				c0.6-6.39-2.33-10.75-7.51-13.87c-1.91-1.15-2.67-3.43-4.99-4.13c0.36-1.09,0.64-1.84,0.85-2.62c0.58-2.06,4-3.35,2.02-6.02
				c-1.91-2.57-4.56-5.14-7.79-4.79c-3.68,0.4-4.06-1.58-4.92-4.04c-2.23-6.37-4.94-12.19-12.06-14.93c-2.68-1.03-4.85-3.2-8.11-3.01
				c-1.42,0.08-2.14-1.35-2.66-2.65c-2.27-5.71-6.37-9.4-12.07-11.76c-4.52-1.88-7.76-5.14-9.77-10.01
				c-2.6-6.31-3.02-6.14-12.74-10.45c-0.15-2.14,0.79-4.33,2.33-5.18c4.81-2.66,4.22-7.28,4.47-11.39c0.29-4.86-2.59-7.3-7.48-7.98
				c-4.88-0.68-9.91-0.52-14.48-3.07c-2.4-1.34-5.11-0.9-7.66,0.45c-6.92,3.66-8.67,3.18-13.36-3.28c-4.1-5.65-9.77-4.4-15.68-3.18
				c0.09-2.45,1.55-3.13,3.25-3.86c3.31-1.41,4.52-4.14,3.04-7.34c-1.79-3.87-1.36-8.67-5-11.75c-0.84-0.71-0.6-1.76-0.28-2.78
				c1.72-5.59,0.3-10.87-3.27-14.98c-7.46-8.59-3.5-13.62,3.88-19.98c5.63-4.85,7.26-11.39,2.2-17.14c-2.49-2.83-3.55-5.21-0.97-8.64
				c1.92-2.55,1.37-6.58,4.67-8.5c0.21-0.12,0.4-0.71,0.28-0.9c-3.39-5.76,1.49-10.75,1.49-16.21c0.01-4.92,7.06-13.9,11.72-15.18
				c1.52-0.42,3.16-0.99,3.69-2.32c1.9-4.82,5.87-7.63,9.44-10.96c1.64-1.53,2.61-2.79,5.19-2.75c2.57,0.05,4.33-1.96,5.39-4.5
				c2.49-5.94,5.96-11.48,6.84-18.06c0.32-2.44,2.07-3.72,4.62-3.6c2.32,0.11,4.63,0.25,6.99,0.38c1.19,4.39,3.31,8.56,1,13.09
				c-4.91-1.62-6.44-0.41-5.52,4.88c0.46,2.61,0.18,4.75-0.99,7.13c-1.73,3.51-2.37,7.25-0.22,10.99c1.28,2.23,1.5,4.62,1.05,7.23
				c-0.89,5.17,1.01,10.23,0.86,15.4c-0.15,5.17-0.27,10.31-3.55,14.7c-0.1,0.13-0.23,0.3-0.21,0.45c0.81,8.09-3.99,14.85-5.22,22.41
				c-0.54,3.35-1.57,3.98-4.42,3.06c-2.19-0.71-3.75-2.11-4.59-4.25c-1.58-4.02-3.14-8.04-4.71-12.07c-0.96-2.47-1.69-5.1-4.4-6.31
				c-7.14,4.55-7.09,10.43-2.79,17.66c4.49,7.56,12.58,12.92,13.86,22.46c0.3,2.27,2.4,3.5,3.99,4.96c2.99,2.73,5.66,5.59,5.76,10.04
				c0.11,5.01,0.69,9.91,5.57,13.5c-7.29,0.14-8.82-0.95-8.91-6.72c-0.04-2.41-0.82-3.65-2.91-4.55
				c-5.91-2.56-10.34-6.39-11.47-13.25c-0.35-2.09-1.66-4.48-4.05-4.54c-2.32-0.05-4.17,1.87-5.66,3.62
				c-0.8,0.95-1.07,2.34-1.53,3.43c-2.11,0.23-2.18-2.22-4.09-2.37c-2.53,2.2-5.82,3.59-8.26,6.52c-5.14,6.18-3.54,13.11,4.15,15.49
				c3.04,0.94,5.95,1.9,8.47,3.98c1.74,1.43,3.92,1.38,6.18,1.01c5.16-0.84,9.83-0.71,12.48,5.24c1.36,3.04,5.08,3.12,7.96,3.21
				c7.44,0.23,14.7,1.72,22.35-1.13c8.33-3.1,13.62-1.35,17.88,2.68c-2.05,7.57-12.23,7.29-14.58,15.31
				c8.75,2.68,17.14,7.88,25.92,0.18c1.22-1.07,2.86-1.67,4.3-2.51c3.38-1.99,6.22-1.99,8.28,1.93c0.69,1.31,1.72,2.45,2.69,3.58
				c5.4,6.34,9.79,6.7,16.14,1.12c0.62,3.68,0.98,7.16,5.56,8.4c2.25,0.61,0.61,9.37-2.22,12.27c-1.54,1.57-3.41,2.58-5.58,3.15
				c-3.69,0.97-7.34,2.04-10.61,4.2c-2.84,1.87-5.99,1.83-9,0.24c-4.45-2.36-8.97-3.45-13.86-1.36c-1.95,0.83-3.99,0.39-5.89-0.16
				c-3.01-0.87-5.89-0.75-8.77,0.41c-2.6,1.05-5.14,0.68-7.81-0.03c-2.33-0.62-4.82-0.62-7.39-0.28c4.39,2.49,9.22,3.98,14.22,2.9
				c3.69-0.79,7.12-1.3,10.77-0.23c1.09,0.32,2.5,0.65,3.43,0.23c5.92-2.62,11.32-0.98,16.69,1.61c1.56,0.75,3.4,1.08,4.86,0.35
				c5.81-2.88,12.13-4.5,17.93-7.28c4.52-2.16,7.7-10.06,6.82-15.08c-0.26-1.47-0.61-2.86-2.22-3.65c-4.85-2.39-5.36-5.91-1.3-9.68
				c4.32-4.01,7.62-8.57,9.63-14.21c2.19-6.13,1-11.1-4.31-14.81c-3.55-2.48-6.6-5.1-4.47-10.1c0.25-0.58,0.38-1.41,0.16-1.96
				c-4.04-10.07,0.39-19.28,2.7-28.75c1.17-4.8-2.73-7.96-7.5-6.43c-1.39,0.45-2.72,1.08-4.33,1.73c-0.54-6.2,2.65-12.79-4.08-17.76
				c8.2-5.1,3.07-11.69,2.79-17.46c-0.21-4.26-3.7-4.92-7.2-2.04c-2.53,2.08-4.77,4.69-8.56,4.4c2.39-13.2-5.32-24.15-7.59-36.65
				c3.37-0.16,6.6,0.75,9.16,2.7c13.05,9.98,28.45,15.93,42.13,24.67c14.73,9.41,29.79,17.99,45.82,24.67
				c-0.56,2.48-1.68,3.86-3.5,4.57c-2.99,1.17-4.28,3.31-5.58,6.34c-1.2,2.8-3.01,7.07-7.96,5.95c-1.18-0.27-2.75-0.06-3.18,0.95
				c-2.29,5.38-7.36,7.73-11.86,10.16c-2.81,1.52-5.73,2.61-8.11,4.92c-1.63,1.58-3.48,2.94-1.97,5.42c1.75,2.87,1.77,7.99,6.93,6.99
				c5.62-1.1,10.31,4.08,15.94,1.87c0.2-0.08,0.59,0.24,0.86,0.42c8.35,5.73,8.7,5.75,16.15,0.92c1.34,0.14,1.9,1.13,2.45,2.18
				c1.81,3.46,4.73,4.04,8.05,2.71c2.97-1.19,5.33-1.29,8.27,0.72c3.57,2.44,7.76,1.08,11.45-0.54c5.23-2.31,7.25-1.84,9.83,2.89
				c2.8,5.13,5.61,6.62,10.84,4.43c4.71-1.97,10.34-1.44,14.4-5.64c3.87,3.89,7.45,2.8,10.77-0.63c1.32-1.36,2.7-2.65,4.75-2.12
				c2.74,0.7,4.75-0.7,6.79-2.2c2.06,10.98-0.22,47-4.07,63.38c-4.76-4.88-9.88-5.32-15.72-2.16c-5.29,2.86-10.67,5.3-9.27,14.13
				c-6.54-5.24-10.95,0.34-16.1,0.69c-0.62,0.04-1.67,0.42-1.75,0.8c-1.27,6.16-8.49,6.17-11.11,10.88c-1.32,2.37-3.43,2.9-5.96,2.54
				c-13.14-1.88-26.4-2.75-39.47-5.39c-6.62-1.34-13.58-0.98-20.38-1.38c-3.73-0.22-6.85-1.71-10.11-3.63
				c-6.4-3.76-13.45-3.32-21.86-1.33c2.93,1.52,4.67,0.98,6.31,0.99c5.97,0.02,11.84-0.16,17.04,3.93c3.35,2.64,7.9,2.66,12.01,2.72
				c16.52,0.25,32.59,4.1,48.91,5.88c2.96,0.32,5.92,0.58,9.22,0.9c-0.16,6.99,5.19,9.29,10.15,11.48
				c14.79,6.51,30.16,10.86,46.37,12.21c11.11,0.93,22,3.2,32.53,7.07c5.7,2.09,11.73,2.37,17.73,2.15
				c24.69-0.92,47.15,6.68,68.7,17.84c0.15,0.08,0.27,0.2,0.43,0.26c26.09,9.15,48.45,26.76,75.84,33.09
				c8.03,1.85,15.66,5.42,23.48,8.2C625.13,1485.7,625.02,1485.83,624.91,1485.97z M145.25,1271.56c1.22-7.23-5.66-9.87-7.55-15.15
				C137.89,1262.58,138.38,1268.59,145.25,1271.56z M158.2,1210.38c1.54,6.35,3.08,8.2,6.87,9.07
				C162.66,1216.27,160.43,1213.33,158.2,1210.38z M172.15,1245.12c0.32-2.91,1.46-5.93-1.35-8.46
				C171.35,1239.46,169.36,1242.58,172.15,1245.12z M166.64,1217.96c-0.95-4-2.33-6.76-4.61-9.07
				C162.31,1212.17,163.02,1215.26,166.64,1217.96z M167.88,1238.5c-0.91,2.46-1.96,4.95,0.33,7.76
				C169.01,1243.29,168.88,1240.89,167.88,1238.5z"/>
			<path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M477.71,668.72c-4.68,0.34-7.15-1.11-7.57-5.64c-0.26-2.79-1.57-5.29-3.14-7.76
				c-3.07-4.8-2.54-10.57-3.43-15.94c-0.35-2.13-0.31-4.33-2.5-6.76c-10.36,6.72-1.57,15.49-2.7,23.44c-1.24,1.6-4.55,0.9-5.67,4.45
				c-2.27-4.69-5.19-7.2-9.58-7.42c-2.63-0.13-3.23-2.03-3.67-4.01c-1.24-5.68-2.53-11.36-3.53-17.08c-0.55-3.11-2.01-4.64-5.18-4.94
				c-2.8-0.27-5.64-0.8-7.75-2.99c-1.37-1.42-2.54-3.35-4.98-2.06c-2.36,1.25-3.88,3.2-3.93,5.94c-0.08,5.1-0.02,10.2-0.02,15.49
				c-2.66-0.56-3.2-2.75-4.48-4.14c-1.3-1.41-3.5-1.09-5.01-3.89c-2.47,5.36-4.6,9.74-6.51,14.22c-2.33,5.43-4.11,6.41-10.03,5.43
				c-3.5-0.58-6.85,0.11-8.78,3.31c-2.49,4.16-5.26,5.19-8.87,1.74c-6.8,4.29-8.38,7.75-6.9,15.65c0.79,4.24,1.12,8.43,0.34,14.01
				c-2.44-5.77-2.13-10.82-3.38-15.5c-0.87-3.27-2.41-6.3-3.71-9.3c-1.82-4.19-2.73-7.2-7.86-7.83c-1.2-0.15-2.15-3.86-2.87-6.04
				c-1-3-2.67-5.39-5.98-4.8c-3.48,0.61-3.58,4.02-2.93,6.45c1.94,7.25-1.49,12.86-4.87,18.57c-1.21,2.04-1.95,4.21-2.34,6.53
				c-1.36,8.01,0.06,15.67,2.43,24.34c3.13-4.18,1.91-7.88,2.22-11.3c0.21-2.33-0.56-4.74,2.74-6.02c2.02-0.78,2.16-3.49,2.44-5.7
				c0.25-1.97-0.15-4.4,2.58-5.74c4.53,6.83-1.27,14.71,2.75,21.47c3.81-0.79,4.8-2.98,3.97-6.85c-1.01-4.67-2.28-9.4-1.17-14.28
				c3.1-0.99,3.52,1.42,3.91,3.23c1.51,7.04,1.63,14.19,1.46,21.36c-0.04,1.95-1.09,2.62-2.85,2.63c-0.83,0-1.67,0.08-2.5-0.01
				c-7.62-0.86-14.06,1.39-19.28,7.09c-0.98,1.07-1.98,2.29-3.95,2.17c-0.36-5.05-0.43-10.15-1.13-15.17
				c-1.23-8.81-3.43-17.47-1.55-26.55c0.44-2.13,0.59-6.38-3.78-6.22c-3.43,0.12-8.3-2.18-8.77,4.53c-4.93-6.73-8.89-6.34-12.59,0.53
				c-2.02,3.74-0.87,7.84-1.73,11.7c-7.86-2.57-7.77-2.57-7.77-10.64c0-3.27,1.64-7.07-4.49-7.94c-2.33-0.33-4.07-5.7-4.8-9.25
				c-1.34-6.45-2.21-12.55-10.29-14.79c-4.08-1.13-5.61-9.73-2.72-12.71c3.59-3.71,2.59-7.57-0.02-10.17
				c-9.26-9.22-9.88-21.36-12.41-32.98c-4.39-20.23,0.13-39.82,4.58-59.2c3.45-15.02,8.06-29.86,13.37-44.4
				c2.7-7.38,5.6-14.6,12.16-19.66c2.02-1.56,2.85-4.24,3.17-6.65c0.77-5.9,4.71-7.8,9.76-8.62c2.12-0.35,4.24-0.79,6.48-0.28
				c0.83,1.85-0.15,3.58-0.41,5.3c-0.67,4.48-0.92,8.46,4.13,10.99c2.68,1.34,2.71,4.22,1.91,7.07
				c-3.18,11.42-7.69,22.31-13.02,32.87c-2.44,4.84-4.02,9.64-3.31,15.26c0.54,4.25,0.35,8.66-0.8,12.9
				c-0.72,2.65,0.22,4.92,1.55,7.09c7.48,12.29,8.5,25.67,6.7,39.52c-0.3,2.33-0.36,2.32,1.2,5.17c6.8-18.84,9.44-38.58,13.27-59.44
				c-5.85,2.71-6.26,7.59-9.25,10.82c-1.62-1.58-1.6-3.59-1.57-5.47c0.29-14.31,0.66-28.61,6.08-42.19c1.35-3.39,2.5-6.88,3.52-10.39
				c1.42-4.93,2.68-9.65-0.25-14.84c-2.21-3.91-0.94-9.12-3.38-13.29c-0.8-1.36-0.49-2.94,1.48-3.54c2.67-0.81,5.07-2.32,8.11-2
				c15.75,1.67,31.61,0.25,47.39,1.66c3.9,0.35,5.49,1.3,5.72,5.4c0.57,10.1,1.71,20.02,4.5,29.95c2.09,7.46,0.84,15.86,0.15,23.85
				c-0.65,7.62-0.93,15.28-1.34,22.92c-0.05,0.94-0.3,2.06,1.12,2.55c1.92-1.01,1.57-3.01,1.89-4.7c2.81-14.78,1.68-29.94,4.09-44.71
				c1.75-10.74-0.41-21.23-0.37-31.83c0.01-3.19,0.74-4.72,3.94-5.63c5.17-1.47,10.29-0.77,15.35-0.23c7.8,0.84,15.5,1.99,23.25-0.39
				c1.85-0.57,3.95-0.5,5.93,0.61c-0.71,9.4-1.35,18.82,0.73,29.79c1.38-2.32,2.01-3.37,2.68-4.51c1.45,4.39-0.04,9.37,2.66,13.4
				c1.8,0.19,1.88-1.28,2.42-2.19c1.62-2.71,2.56-6.26,4.87-8.03c6.76-5.14,6.91-12.34,7.47-19.61c0.2-2.63,0.72-5.24,1.13-8.17
				c6.79-1.1,13.59-1.79,19.87-5.33c3.2,8.38,5.07,16.76,6.54,25.24c1.52,8.74,4,17.16,7.84,25.17
				c11.23,23.44,17.74,48.26,22.29,73.76c2.04,11.43,5.81,22.54,8.19,33.92c1.65,7.91,2.26,15.98-2.09,23.59
				c-1.92,3.35-2.11,7.29-2.24,11.16c-0.31,8.53-0.76,17.05-1.51,25.56c-4.39-13.49-2.18-27.67-3.61-41.85
				c-5.21,1.4-7.67,5.65-12.3,5.48c-1.36-0.05-1.39,2.02-1.75,3.28c-2.65,9.21-3.53,18.58-2.92,28.14c0.26,3.98,0.1,7.99,0.13,11.99
				c0.01,1.4-0.13,2.76-1.87,3.02c-1.77,0.26-2.26-1.19-2.7-2.38c-1.34-3.62-1.64-7.44-2.12-11.24c-0.88-7.09-1.87-14.16-2.81-21.25
				c-0.27-2.02-0.84-3.97-2.44-5.32c-1.59-1.34-3.55-2.06-5.57-1.29c-1.92,0.73-1.78,2.75-1.73,4.32c0.07,2.31,0.27,4.69,0.9,6.9
				c3.33,11.74,4,23.93,6.12,35.87C477.92,667.09,477.77,667.74,477.71,668.72z"/>
			<path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M1074.29,849.99c3.24-4.21,5.29-8.74,7.11-13.3c2.61-6.55,6.6-12.15,10.43-18
				c4.75-7.26,10.77-13.95,13.25-22.61c0.45-1.57,1.11-3.39,2.27-4.38c7.48-6.39,7.78-15.76,10.4-24.17c0.39-1.24,0.83-2.47,1.18-3.5
				c4.71-1.51,6.53,8.64,12.65,1.81c-3.62,15.79-5.81,30.92-1.42,46.36c2.95-1.03,2.64-3.4,2.87-5.14
				c1.67-12.35,6.36-24.08,7.48-36.52c0.29-3.24,1.53-4.18,4.79-3.81c5.08,0.58,10.23,0.58,15.23,0.83c1.27,1.91,0.79,3.8,0.79,5.57
				c0.03,54.79-0.01,109.58,0.1,164.38c0.01,3.55-1.1,5.7-3.87,7.88c-5.47,4.3-11.19,8.11-16.96,11.92
				c-5.01,3.31-9.79,9.42-9.23,17.52c-3.38-0.65-5.57,1.11-7.4,3.31c-2.75,3.29-7.28,2.6-10.6,5.14c-4.06,3.11-6.25,6.65-6.99,11.49
				c-1.61,10.55-1.97,21.2-2.91,31.81c-0.32,3.64-0.64,7.29-1.01,10.93c-0.49,4.75-1.53,8.58-7.03,10.67
				c-4.79,1.82-7.62,7.24-12.58,9.47c-2.56-2-1.77-6.17-4.72-7.44c-3.19-1.37-5.34,2.55-8.27,2.5c-7.35-0.12-9.64,3.68-10.88,10.58
				c-1.3,7.24-5.72,13.77-7,21.24c-0.93,5.41-1.91,10.81-2.96,16.19c-0.63,3.22-1.86,5.78-6.01,4.72c-2.14-0.55-3.47,0.91-4.43,2.52
				c-1.19,1.99-2.31,4.06-3.19,6.2c-1.71,4.17-4.3,7.16-8.5,9.25c-6.9,3.42-12.35,8.98-18.34,13.73c-1.69,1.34-1.25,2.76-0.52,4.43
				c2.93,6.66,0.9,12.65-2.65,18.42c-0.64,1.04-1.38,1.88-2.58,2.18c-6.18,1.54-9.15,6.2-11.39,11.61c-1.86,4.51-4.37,7.6-9.91,8.53
				c-6.46,1.08-9.25,6.92-10.67,12.64c-1.02,4.12-2.48,7.42-6.09,9.66c-4.62,2.86-6,7.36-6.07,12.39
				c-0.08,5.49-0.11,10.99,0.14,16.48c0.13,2.8-0.2,4.78-3.27,5.75c-2.29,0.72-4.42,2.45-4.66,4.83c-0.61,5.92-3.72,11.6-1.58,17.82
				c0.89,2.6-2.39,5.19-7.66,5.94c2.95-9.91,5.96-19.51,8.64-29.21c2.62-9.45,8.49-18.32,5.97-29.85c-3.51,3.93-4.52,8.01-6.36,11.6
				c-1.76,3.43-3.65,6.64-8.01,6.3c-4.25-0.33-5.44-4.03-6.52-7.32c-2.05-6.25-1.93-6.26-8.63-4.82c-1.12,0.24-2.56,0.61-3.41,0.13
				c-4.65-2.63-8.95-3.46-12.85,2.29c-0.97-6.78,1.39-12.21,1.93-17.84c0.38-3.96,1.21-7.89,1.45-11.86
				c0.17-2.76-0.47-5.17-4.15-5.63c-3.19-0.4-3.04-2.6-2.1-5.25c3.46-9.71,6.74-19.47,9.98-29.26c0.73-2.19,1.07-4.53,1.47-6.81
				c0.44-2.5,0.24-4.73-2.96-5.22c-2.85-0.43-5.2-0.21-6.4,3.09c-4.28,11.79-10.57,22.89-10.19,36.16
				c0.19,6.55-3.66,9.62-10.36,10.46c-2.4,0.3-3.97,1.17-4.88,3.52c-0.88,2.26-2.13,4.37-4.12,6.18c2.26-7.2,4.25-14.5,6.82-21.58
				c5.11-14.03,6.99-28.72,9.19-43.34c1.01-6.71,0.4-7.15-6.75-7.05c0.01-5.11-4.1-7.01-8.04-8.44c-4.22-1.54-5.75-4.42-6.63-8.39
				c-0.79-3.58-2.07-7.03-7.73-4.03c0.01-5.86,2.25-10.5,3.48-15.32c0.69-2.73,1.93-5.33,2.6-8.06c1.15-4.69,2.78-7.96,8.69-8.4
				c4.83-0.36,6.43-5.2,7.56-9.52c1.09-4.15,2.14-8.32,3.29-12.46c0.34-1.23,0.65-2.62,2.82-2.97c0.25,5.42-0.15,10.94,2.36,15.93
				c1.18,2.35,2.14,5.97,4.92,5.63c3.15-0.39,6.97-0.9,8.98-4.43c4-7.01,7.25-14.27,7.04-22.55c-0.09-3.35,0.52-6.28,3.11-8.55
				c2.69-2.36,2.38-5.74,2.91-8.76c1.29-7.32,2.34-14.68,3.57-22.54c4.48,2.89,7.72,6.11,11.16,9.09c1.77,1.54,1.35,3.15,1.1,4.97
				c-1.35,9.7-2.16,19.34-1.65,29.25c0.27,5.15,0.17,8.57,5.14,11.12c2.17,1.11,0.72,4.07,0.39,6.05
				c-2.78,16.72-5.76,33.4-8.61,50.11c-0.62,3.6-1.43,7.18-1.26,10.89c0.11,2.34,0.52,4.5,2.64,7.29c2.66-6.92,5.15-12.95,7.3-19.1
				c3.84-10.99,7.1-22.15,9.55-33.54c0.49-2.26,0.78-4.83,2.04-6.63c9.73-13.88,11.81-30.2,15.08-46.15c0.03-0.13,0.2-0.24,0.56-0.65
				c3.33,3.25,7.64,5.44,9.38,9.59c2.38,0.59,2.75-1.94,4.5-1.66c3.96,2.2,4.73,4.93,3.5,9.7c-3.85,14.96-6.78,30.16-10.25,45.22
				c-1.93,8.41,1.16,15.26,5.89,21.8c2.42,3.34,6.47,0.64,9.86,3.28c-1.17,5-2.3,10.44-3.72,15.81
				c-3.12,11.73-6.64,23.31-12.54,34.05c-1.07,1.96-3.4,5.14,0.77,6.56c3.51,1.19,6.41,0.62,7.21-4.04
				c0.64-3.75,2.11-7.35,2.98-11.07c3-12.8,11.66-23.51,13.69-36.61c1.29-8.33,8.06-14.73,8.25-23.15
				c0.44-19.55,7.99-37.27,13.49-55.53c1.16-3.84,2.6-7.58,5.48-10.52c1.38-1.41,3.08-1.88,5.07-2.44c5.56-1.59,9.91-6.5,16.38-6.16
				c0.62,0.03,1.47-2.07,2-3.27c1.48-3.34,3.35-6.62,4.17-10.13c1.98-8.57,7.34-13.85,15.13-17.12c1.97-0.83,2.99-2.54,4.17-4.16
				c2.15-2.95,4.57-5.65,8.41-7.7c3.11,5.32,7.35,8.89,13.05,10.99c2.51,0.93,3.65,3.45,2.95,6.96c-2.47,12.47-2.43,25.15-2.05,37.81
				c0.04,1.25-0.31,2.67,0.98,3.74c1.7-0.96,1.11-2.64,1.11-3.96c0.03-13.32,0.24-26.61,2.36-39.83c0.7-4.34-2.91-6.73-6.15-7.93
				c-3.86-1.43-6.71-3.56-8.73-6.95c-2.22-3.73-5.23-3.77-7.77-0.99c-5.74,6.28-12.01,11.74-19.5,15.86
				c-2.04,1.12-2.83,3.62-3.68,5.81c-1.56,4.03-3.15,8.05-4.73,12.07c-1.55,3.93-2.49,8.62-8.59,6.87c-0.28-0.08-0.76,0.03-0.94,0.24
				c-4.32,5.04-12.08,4.12-16.24,9.36c-1.28,1.61-2.89,1.38-4.53,1.32c-7.22-0.28-11.66-6.2-19.23-8.1c3.95-2.58,7.03-2.61,9.84-3.37
				c2.85-0.77,4.25-2.39,4.14-5.34c-0.2-5.12,1.05-10.2-2.49-15.13c-2.76-3.84-1.6-9.01-1.21-14.29c2.67,2.13,3.54,5,4.96,7.38
				c1.98,3.32,4.51,5.32,8.5,4.84c3.34-0.4,6.23,0.67,8.95,2.41c1.51,0.97,2.86,1.66,4.3-0.17c1.63-2.08,0.35-3.31-1.08-4.61
				c-2.38-2.14-5.11-3.67-8-5.02c-5.93-2.78-9.39-9.75-8.29-17.52c3.07,5.55,8.98,4.87,13.23,7.55c2.76,1.74,3.92-1.62,5.94-2.46
				c2.63,0.36,3.45,3.57,6.12,3.93c1.6-0.75,1.78-2.29,2.08-3.72c2.17-10.26,4.39-20.52,3.96-31.1c-0.08-1.98,0.2-4.48-0.86-5.82
				c-3.88-4.93-3.35-11.49-6.64-16.56c-1.31-2.02-0.47-4.2-0.09-6.29c1.64-9.09,5.42-17.43,9.03-25.83
				c1.45-3.37,2.87-6.75,2.81-10.51c-0.03-1.73-0.38-3.56-2.31-4c-2.01-0.46-3.61,0.63-4.34,2.53c-1.42,3.72-2.82,7.46-3.98,11.27
				c-1.12,3.67-2.57,7.18-4.06,10.71c-1.89,4.47-4.43,5.39-8.34,2.3c0.52-8.26,4.47-15.75,6.07-23.96c2.45,1.13,3.18-0.81,3.92-3.03
				c2.54-7.73,5.24-15.42,7.76-23.16c1.81-5.55,3.69-11.02,7.27-15.77c1.14-1.51,2.52-3.29,0.79-5.39c-1.55-1.88-3.22-0.89-4.99-0.42
				c-4.9,1.3-6.19,0.04-5.05-4.84c1.37-5.89,3.38-11.39,7.61-16.02c2.79-3.05,4.78-6.78,5.71-10.96c0.46-2.08,0.88-4.31-1.34-5.26
				c-2.77-1.18-3.23,1.6-3.97,3.28c-1.72,3.9-3.25,7.88-8.34,10.88c3.05-6.29,5.49-11.48,8.1-16.6c0.98-1.92,2.52-3.02,4.92-2.02
				c2.13,0.89,4.22,0.09,6.76-0.81c-0.81,7.21-2.2,13.94-4.29,20.54c-1.15,3.64-2.34,7.31-3.03,11.05c-1.03,5.59-0.78,11,4.31,14.93
				c2.3,1.77,1.15,4.31,1.27,6.52C1069.73,842.52,1069.71,846.86,1074.29,849.99z"/>
			<path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M658.35,1336.47c-9.19-2.49-17.96-4.89-26.75-7.22c-1.91-0.51-2.56-1.63-2.78-3.54
				c-0.61-5.15-1.02-10.32-0.56-15.45c0.89-10,1.05-20.14,4.81-29.89c1.88,0.74,1.65,3.72,4.09,3.83c1.94-0.68,1.88-2.47,1.97-4.08
				c1.16-19.61,2.85-39.16,5.28-58.65c0.66-5.28,0.48-10.63-3.58-15.03c-1.34-1.45-1.99-3.71-1.37-5.65c1.31-4.1-0.65-6.3-3.99-7.74
				c1.45-9.26,7.63-15.66,16.26-17.07c1.25-0.2,2.29,0.19,3.29,0.84c0.98,0.63,1.9,1.37,2.93,1.9c2.63,1.34,5.41,3.57,8.35,1.76
				c3.11-1.91,1.75-5.41,1.62-8.3c-0.11-2.49-0.12-5.1-0.9-7.41c-3.06-9.05,2.69-13.71,8.74-18.28c1.51-1.14,3.28-1.63,5.18-1.44
				c2.67,0.27,5.24,0.3,7.83-0.78c1.54-0.65,3.38-0.37,4.75,0.75c5.82,4.72,11.51,5.98,16.68-0.87c2.03,2.27,2.68,4.41,2.62,6.85
				c-0.04,1.66-0.41,3.55,0.22,4.95c2.61,5.85,0.72,10.89-1.88,16.14c-2.09,4.22,0.1,7.63,4.37,7.7c3.12,0.06,8.48-6,7.97-9.21
				c-0.32-2.04-0.1-3.95,0.34-5.92c1.29-5.76,2.88-10.96-2.17-16.64c-2.66-2.99-2.85-8.3-8.45-9.6c5.06-1.32,9.15-3.81,13.16-6.57
				c2.65-1.83,5.25-0.54,7.03,1.73c3.27,4.18,8.16,4.85,12.71,6.24c2.45,0.75,4.63-0.11,6.16-2.2c2.03-2.77,3.92-2.73,6.57-0.52
				c3.56,2.97,8.71,2.81,12.32,6.2c4.48,4.2,4.4,7.67-1.13,10.26c-3.37,1.58-5.86,3.51-6.42,7.53c-0.37,2.69-2.88,3.5-5.16,4.09
				c-4.9,1.27-9.28,3.5-13.4,6.42c-4.65,3.3-10.08,4.11-15.66,4.21c-2.9,0.05-6.06-0.02-5.65,4.31c0.46,4.82,3.4,7.23,7.52,6.37
				c9.22-1.91,17.48-6.16,25.51-10.82c2.71-1.57,5.44-2.3,8.48-2.49c4.8-0.3,8.91-2.26,10.87-6.75c1.79-4.1,6.61-4.07,8.96-7.14
				c-1.22-2.51-3.48-1.05-5.3-2.05c1.29-2.26,2.57-4.52,3.86-6.78c1.2,7.67,7.47,13.83,6.35,22.15c-0.23,1.73,0.1,3.76,0.83,5.35
				c6.88,15.08,7.76,31.15,8.04,47.34c0.03,1.9-0.58,4.05,1.43,5.65c3.62-0.96,3.54-4.12,3.56-6.78c0.05-7.5,1.12-14.99,0.9-22.43
				c-0.24-8.19,2.31-15.66,3.87-23.41c0.67-3.33,3.39-5.35,6.11-5.95c3.43-0.76,5.61-2.33,7.21-6.77c4.31,17.96-1.44,35.11,4,51.54
				c1.38-9.67-0.35-19.5,1.23-30.47c3.46,14.06-1.28,27.09,1.75,39.89c-0.09-12.15,5.43-23.74,2.88-36.43
				c5.08,8.8,6.33,12.03,4.81,26.48c-0.98,9.35,2.18,18.33,0.83,27.71c-5.54,1.6-8.5,7.13-14.27,8.95c-3.3,1.04-3.44,5.95-2.56,8.96
				c1.95,6.68-2.94,11.75-3.36,17.86c-0.21,3.02-4.14,3.88-7.04,4.79c-11.3,3.52-11.33,3.61-11.01,15.61
				c0.18,6.66,0.39,13.31,0.47,19.97c0.03,2.47,0.44,4.06,3.04,5.51c2.89,1.61,5.15,4.62,5.95,8.09c1.47,6.39,1.02,12.92,1.04,19.41
				c0,0.79,0.07,1.64-0.86,2.14c-6.94,3.75-7.16,10.71-8.02,17.25c-0.5,3.77-0.4,7.62-0.6,12.31c-10.72-6.4-22.58-10.59-27.24-23.12
				c-3.23-8.67-8.36-15.16-16.51-19.74c-10.53-5.91-18.92-15.33-30.84-19.08c-0.57-0.18-1.46-0.87-1.42-1.22
				c0.64-4.69-1.02-10.11,0.73-13.89c1.73-3.75,1.98-5.75-0.76-8.17c1.08-2.68,3.23-3.98,5.29-4.69c6.18-2.11,9.45-7.7,14.12-11.54
				c2.88-2.37,1.5-6.6-0.77-9.5c-3.87-4.93-4-14.29,0.15-19.03c0.95-1.09,1.42-2.22,1.56-3.56c0.83-7.77,1.69-15.54,2.43-23.32
				c0.18-1.95,0.57-4.94-1.39-5.36c-2.28-0.49-5.16-1.64-7.51,0.28c-2.54,2.08-5.07,2.19-7.75,0.36c-2.68-1.83-2.17-4.47-2.13-7.18
				c0.09-5.38,1.32-10.15,4.54-14.66c2.31-3.24-0.07-6.5-1.79-9.82c-1.49,1.28-1,2.67-1,3.85c0.01,3.17-1.21,5.41-4.24,6.65
				c-1.21,0.49-2.85,1.07-3.3,2.07c-2.27,5.02-4.24,10.06-8.21,14.22c-1.55,1.63,0.42,4.05,2.15,5.51c2.26,1.9,4.58,3.7,6.57,5.3
				c-0.01,2.23-1.41,3.02-2.37,3.61c-6.73,4.16-7.67,10.75-7.95,17.76c-0.19,4.79-0.66,9.44-2.64,14.05
				c-2.3,5.37-2.53,11.38-2.23,17.21c0.18,3.41-0.86,5.75-3.81,7.27c-2.35,1.21-4.44,3.18-6.73,4.01c-4.16,1.52-5.48,4.54-6.34,8.18
				c-1.3,5.51-2.29,11.09-2.29,16.77c0,2.95-1.1,4.96-3.68,6.5c-5.49,3.28-9.9,7.85-14.08,12.61c-2,2.28-3.89,2.31-6.57,1.22
				c-2.88-1.17-2.45-3.12-2.16-5.28c1.77-13.19,3.5-26.38,5.36-39.56c0.35-2.44,0.94-4.96,3.13-6.61c1.18-0.89,1.42-2.15,1.54-3.52
				c1.38-15.24,4.22-30.32,5.02-45.63c0.1-1.97,0.4-4.03,1.08-5.87c1.97-5.31,1.12-10.12-2.04-14.66c-2.12-3.04-1.88-5.37,0.98-8.21
				c2.92-2.91,3.74-7.91,8.89-8.62c0.35-0.05,0.77-0.68,0.88-1.11c1.91-7.47,1.57-14.95,0.59-23.29c-3.56,2.7-5.87,5.74-10.33,2.46
				c-1.86-1.37-6.04,1.08-7.1,4.04c-1.8,5.03-4.55,9.09-10.27,12.47c10.48-0.36,10.07-11.34,17.21-14.27
				c2.2,4.23,5.24,2.94,8.64,0.62c0,3.99,0.15,7.29-0.04,10.56c-0.2,3.49-1.22,6.09-4.84,8.24c-4.17,2.47-6.03,7.66-8.62,11.79
				c-1.35,2.16,0.6,4.8,1.96,6.89c2.4,3.7,3.04,7.41,1.77,11.82c-2.97,10.31-2.06,21.15-3.83,31.65c-1.08,6.39-1.65,12.86-2.47,19.3
				c-0.1,0.82-0.01,1.86-0.47,2.4c-8.64,10.24-6.87,23.2-9.11,35.08C658.98,1324.88,658.9,1330.54,658.35,1336.47z M653.31,1289.04
				c0.48,0.05,0.97,0.1,1.45,0.15c2.66-12.43,4.17-25.03,5.54-37.68c0.8-7.36-1-14.77,1.63-22.17c1.73-4.86-1.54-11.01-5.22-11.56
				c-2.97-0.44-3.47-2.05-3.76-4.28c-0.49-3.79-0.92-7.58-1.38-11.37c-2.19,5.38-2.28,10.64,0.07,15.75
				c0.38,0.83,1.24,1.64,2.07,2.04c5.53,2.64,7.04,5.67,5.2,11.37c-1.05,3.24-1.22,6.5-1.02,9.85c0.33,5.49,0.03,10.95-0.68,16.41
				C655.84,1268.04,654.6,1278.54,653.31,1289.04z"/>
			<path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M988.37,1143.53c3.03-10.36,6.1-20.7,8.73-31.19c1.16-4.62-0.56-5.39-4.08-6.32
				c-2.24-0.59-5.14,0.98-6.73-2.02c-2.31-4.35-5.47-8.25-4.87-13.78c0.63-5.83,2.09-11.43,3.39-17.11
				c2.74-11.99,5.21-24.05,7.98-36.03c1.04-4.52-2.62-6.06-4.76-8.58c-1.79-2.1-3.8-1.64-5.58,0.09c-3.47-4.12-8.53-6.37-10.11-11.77
				c-2.88,1.5-3.48,3.57-3.79,5.71c-1.49,10.25-4.82,19.98-7.75,29.86c-1.61,5.44-3.48,10.31-6.63,15.07
				c-3.79,5.74-3.66,13.02-5.57,19.55c-3.35,11.42-6.37,22.93-11.95,35.17c0.6-10.14,3.04-18.47,4.43-26.97
				c2.1-12.78,4.23-25.56,6.4-38.33c0.34-2,0.17-3.98-1.67-4.79c-4.17-1.84-4.07-4.65-4.36-8.88c-0.52-7.55,0.05-14.93,0.44-22.38
				c0.05-1-0.1-2.1,0.27-2.97c2.63-6.26,0.27-10.76-4.8-14.45c-3.18-2.31-5.99-5.03-7.81-8.84c-3.59,2.52-4.3,5.98-4.89,9.35
				c-1.16,6.71-2.09,13.47-3.11,20.21c-0.03,0.16,0,0.37-0.09,0.49c-6.98,8.81-4.38,20.47-8.97,29.97c-1.54,3.19-2.44,6.83-6.66,8.17
				c-2.87,0.91-4.22,0.24-5.34-2.31c-2.06-4.67-2.88-9.51-2.38-14.58c0.14-1.46,0.78-2.92-0.16-4.88c-4.73-0.3-7.21,2.68-7.96,6.76
				c-0.84,4.59-2.97,8.75-3.62,13.42c-0.48,3.45-2.58,6.5-6.93,6.67c-3.79,0.15-6.2,2.38-7.33,5.81c-2.55,7.73-5.19,15.45-7.26,23.32
				c-1.83,6.95-1,7.58,6.02,6.3c0.26,0.33,0.76,0.68,0.81,1.08c0.83,7.01,4.98,11.21,11.35,13.55c1.26,0.46,2.83,0.82,3,2.31
				c0.5,4.34,3.34,5.29,7.45,5.04c-0.72,26.02-10.02,49.89-17.33,74.42c4.61,0.46,6.24-2.34,6.98-4.47
				c2.22-6.33,6.39-9.24,13.45-8.97c-0.65,6.89-1.95,13.99-4.79,20.35c-1.49,3.34-5.86,5.75-10.22,6.28
				c-3.75,0.46-6.48,2.36-8.5,5.46c-3.45,5.28-8.85,8.35-13.79,12.55c-1.31-2.52-0.54-4.69-0.1-6.63c1.85-8.1,3.58-16.25,6.07-24.16
				c1.86-5.92,2.19-11.75,1.36-17.72c-0.47-3.41-0.23-6.69,1.01-9.8c4.76-12.02,4.73-25.12,8.47-37.37c0.1-0.32-0.34-0.8-0.78-1.74
				c-3.25,3.76-4.54,8.01-5.55,12.23c-2.22,9.33-6.15,18.21-6.56,28.07c-0.18,4.3-2.96,8.35-3.89,12.79
				c-0.47,2.22-3.98,2.6-5.36,4.85c-3.19,5.18-5.07,10.81-6.26,16.72c-0.98,4.89-2.09,9.76-2.94,14.67
				c-0.44,2.54-1.69,3.82-4.62,3.99c-0.65-2.97-0.04-5.88,0.21-8.77c0.72-8.33,0.91-16.54-2.59-24.49c-2.36-5.35-1.43-12-6.48-16.39
				c-0.54-0.47-0.07-2.21,0.09-3.34c2.26-15.3,6.17-30.28,10.34-45.09c3.09-10.96,7.94-21.48,10.57-32.67
				c0.61-2.59,1.18-5.19,1.75-7.79c2.58-11.81,10.37-19.32,20.51-24.87c1.46-0.8,3.09-1.34,3.95-2.89
				c4.13-7.4,8.73-14.59,12.21-22.28c3.07-6.78,6.77-12.86,12.2-17.92c1.88-1.75,2.64-4.22,3.71-6.45c1.51-3.16,3.22-5.73,6.39-7.87
				c3.72-2.51,5.02-7.39,5.92-11.75c1.5-7.34,7.16-12.25,10.24-18.64c1.19-2.48,3.65-1.66,6.25-0.92c0.5,7.24,3.56,12.87,11.48,14.59
				c0.55,0.12,1.3,0.71,1.43,1.22c2.38,9,7.04,17.35,4.99,27.48c-1.91,9.41-4.74,18.74-4.86,28.47c-0.02,1.58-0.72,3.31,0.72,4.75
				c2.12,0,2.1-1.86,2.57-3.1c5.99-15.73,10.72-31.83,13.61-48.42c0.77-4.39,0.91-8.92,1.04-13.39c0.09-3.38-1.57-6.17-4.44-7.93
				c-1.86-1.14-2.67-2.44-1.96-4.49c1.91-5.54,2.61-11.33,3.76-17.04c0.46-2.31,1.11-4.51,4.54-4.67c0.3,3.33,0.47,6.43,0.89,9.49
				c0.41,3.03,1.23,6.44,4.17,7.56c4.6,1.75,5.72,5.35,6.88,9.35c0.78,2.67,1.74,5.38,4.16,7.1c0.42-0.1,0.93-0.07,1.01-0.25
				c4.31-9.47,6.77-19.38,7.02-29.8c0.02-0.65-0.03-1.41-0.34-1.96c-2.75-5-3.64-10.4-3.16-15.59c-1.92-1.73-2.67,0.65-4.06,0.23
				c-1.6-1.89-0.5-4.02-0.53-6.11c-0.08-5.32,2.69-10.7-2.12-15.91c-3.15-3.41,2.75-13.84,7.53-15.09c2.58-0.68,4.97-2.53,7.97,1.16
				c4.74-15.66,6.17-30.86,7.78-46.04c-4.89,12.76-5.37,26.51-9.13,39.51c-10.09,1.18-15.15,5.98-16.63,15.79
				c-0.35,2.3-0.57,4.62-0.97,6.91c-0.5,2.87,0.34,4.25,3.46,3.11c1.03,4.69-0.76,8.92-0.57,13.18c0.14,3.2-1.41,8.05,4.83,7.88
				c0.35,3.24-0.83,6.6,2.63,9.35c1.78,1.41,1.31,4.94,0.91,7.66c-1.06,7.23-2.85,14.26-5.18,21.19c-1.76-0.34-1.79-1.63-2.12-2.64
				c-1.53-4.56-1.86-9.78-8.05-11.24c-1.84-0.43-2.47-3.02-2.74-5.04c-0.24-1.81-0.29-3.65-0.38-5.48c-0.16-2.96,0.33-6.79-3.56-7.23
				c-3.9-0.44-5.78,2.84-6.55,6.31c-1.44,6.48-2.34,13.09-4.48,19.41c-0.85,2.51,0.83,3.72,2.67,4.67c2.99,1.54,4.3,3.89,4.22,7.27
				c-0.3,13.56-6.26,40.15-12.7,54.03c1-5.03,1.9-10.08,3.03-15.08c1.73-7.71,1.84-15.52,1.71-23.35c-0.02-1.29,0.4-3.09-1.02-3.61
				c-3.27-1.2-3.27-4.04-3.55-6.57c-0.33-3.04-1.59-4.7-4.58-5.69c-7.83-2.6-10.05-7.69-7.32-15.51c2.69-7.7,5.58-15.31,9.25-22.61
				c2.1-4.18,4.24-8.24,8.52-10.79c2.18-1.3,2.96-3.95,4.17-6.12c5.6-10.04,10.27-20.51,14.2-31.32c1.67-4.59,4.57-8.28,7.38-12.23
				c3.98-5.6,6.65-12.21,9.42-18.59c3.84-8.88,8.05-17.52,13.4-25.59c1.49-2.24,2.87-4.48,6.04-4.59c3.06-0.11,3.97-2.7,5.04-4.92
				c3.46-7.19,6.01-14.78,9.82-21.83c6.35-11.75,14.4-21.83,26.47-28.14c2.35-1.23,3.56-3.37,5.02-5.41
				c6.71-9.36,13.78-18.38,23.93-24.39c6.38,9.61,14.76,12.05,26.39,8.07c2.51-0.86,5.25-0.74,8.11,1.3
				c-3.66,8.4-3.07,18.56-10.76,25.44c-0.72,0.64-1.27,1.59-1.62,2.5c-4.69,12.01-12.21,22.27-19.68,32.62
				c-2.58,3.58-4,8.01-5.97,12.03c-1.37,2.8-2.79,5.58-4.51,9.01c-2.1-3.49-1.54-6.62-1.5-9.58c0.04-2.84-0.5-5.06-3.06-6.86
				c-3.6-2.51-3.73-6.48-3.15-10.37c1.51-10.2,6.06-19.71,6.97-30.07c0.19-2.12,1.01-4.22-0.46-6.88c-3.49,1.53-7.21,2.12-11.17,1.43
				c-1.77-0.31-3.32,1.54-4.14,3.21c-3.45,7-7.22,13.87-9.88,21.24c-0.29,0.81-0.31,1.62,0.75,2.7c5.63-3.7,10.88-7.77,13.51-15.54
				c-0.34,4.72-0.94,6.55-5.24,11.32c-4.71,5.22-6.3,11.62-7.7,18.12c-0.76,3.52,1.71,6.15,5.37,6c1.78-0.07,3.54-0.56,6.49-1.06
				c-9.41,13.46-11.35,28.92-17.38,42.78c-9.42-3.26-12.65-13.53-21.41-17.5c1.84,5.46,4.87,10.52,9.35,13.8
				c7.11,5.21,9.54,10.55,4.82,18.67c-1.69,2.9-1.91,6.68-2.66,10.08c-0.4,1.83-1.63,4.39,1.35,4.9c3.04,0.52,6.28,4.52,9.35,0.09
				c3.06-4.42,4.99-9.35,6.53-14.49c1.26-4.19,2.77-8.31,4.7-12.32c2.11,3.39,0.47,6.37-0.65,9.29c-2.26,5.9-4.78,11.7-7.09,17.57
				c-1.65,4.2-2.41,8.64-3.19,13.06c-0.38,2.19,0.9,4.11,1.18,5.98c1.01,6.54,5.9,11.64,6.54,17.96c1.15,11.37-1.69,22.43-4.03,34.25
				c-3.62-4-7-4.59-10.11-0.49c-5.28-3.55-13.79-2.29-13.76-12.68c-6.78,7.53-3.05,13.88-1.21,19.81
				c2.18,7.01,10.35,7.22,14.87,12.07c-3.92-2.07-7.96-2.92-12.38-2.58c-2.54,0.2-3.73-2.39-4.98-4.4
				c-2.22-3.57-3.63-7.79-8.37-10.99c-0.85,8.52-2.84,16.21,1.72,23.27c2.8,4.34,0.14,8.72,1.11,13c0.22,0.99-1.18,1.4-2.19,1.63
				c-2.1,0.49-4.2,0.98-6.28,1.58c-1.84,0.53-4.5,0.31-4.93,2.64c-0.44,2.39,1.7,4.28,3.57,4.88c6.54,2.12,11.44,8.41,19.35,7.05
				c-3.2,10.13-6.23,19.78-9.3,29.41c-3.18,10-6.18,19.99-6.22,30.67c-0.02,4.77-1.88,9.1-3.95,13.59
				c-3.73,8.09-6.08,16.86-8.56,25.48c-1.72,5.99-5.55,10.75-8.28,16.13L988.37,1143.53z"/>
			<path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M96.69,1043.03c0.63-2.66-1.57-3.4-3.13-4.51c-3.8-2.69-7.39-5.58-10.35-9.98
				c3.87,0.79,5.25-4.23,9.1-2.28c0.19-3.46-3.38-3.98-3.85-6.5c1.51-1.29,3.07,1.4,5.1-0.64c-3.15-3.66-8.71-5.19-10.45-10.53
				c1.74-1.65,4.12-2.66,5.12-5.71c1.06-3.23-0.68-5.1-1.53-7.4c-1.7-4.62-2.25-9.66-5.43-15.11c-2.1,6.83-6.3,11.34-7.65,17.89
				c-1.15,5.58-3.24,11.82-8.74,15.06c0.49,2.84,2.27,1.04,3.37,1.66c-1.78,3.09-5.28,4.38-7.3,6.96c0.19,1.89,2.06,0.8,2.64,1.82
				c-0.69,4.01-9.81,8.38-13.8,5.99c2.68-0.11,5.15,0.83,7.02-1.51c-2.37-2.92-6.93-2.14-9.34-5.74c4.16,0.38,4.74-3.73,8.19-5.18
				c-3.32-2.1-7.07-2.11-9.23-5.6c1.77-0.31,3.39-0.59,5.1-0.88c0.58-2.44,0.98-4.37-1.38-6.23c-5.35-4.21-7.22-10.15-7.39-16.77
				c-0.07-2.56,0.06-5.22-2.41-8.69c-2.83,8.13-5.9,14.95-9.42,21.57c-1.66-0.6-1.18-1.84-1.04-2.61c1.61-9.15,1.66-18.49,4.16-27.56
				c2.35-8.55,3.39-17.33-1.22-25.7c-0.74-1.34-1.16-2.74-0.57-4.3c2.09-5.44,1.3-11.16,1.6-16.78c0.23-4.32-0.1-8.06-3.77-11.22
				c-2.75-2.38-1.37-6.01-0.62-8.92c0.84-3.24,1.77-6.36,1.43-9.75c-0.08-0.8-0.04-1.85,0.41-2.42c5.65-7.21,4.15-16.19,6.12-24.29
				c1.68-6.9,5.09-12.58,11.36-16.22c1.55-0.9,2.17-1.99,1.6-3.67c-0.66-1.96-0.5-4.45-3.17-5.19c-0.16-0.04-0.33-0.05-0.49-0.05
				c-9.17,0.19-12.39-6.99-15.81-13.36c-3.98-7.41-10.47-12.74-15.07-19.61c-2.29-3.42-7.4-3.27-10.47-6.46
				c-2.96-3.08-4.38-6.05-3.18-10.17c1.92-0.71,3.19,0.3,4.37,1.54c0.34,0.36,0.58,0.91,0.99,1.11c6.48,3.14,10.44,8.53,13.46,14.82
				c0.33,0.7,1.1,1.43,1.81,1.66c4.59,1.43,9.14,2.89,12.94,6.94c1.51-4.23,1.55-7.64-0.16-10.48c-4.97-8.26-9.55-16.66-12.5-25.87
				c-1.03-3.23-3.24-3.87-6.24-2.68c-2.92-7.99-2.53-18.22-14.69-19.68c-0.79-3.58-0.26-7.63-0.23-11.95
				c7.66,8.87,17.93,12.87,27.93,17.24c2.68,1.17,4.28,3.17,5.44,5.56c3.64,7.52,7.76,14.58,13.99,20.43
				c4.67,4.39,6.73,10.67,8.93,16.58c4.41,11.84,8.57,23.77,12.67,35.72c0.91,2.64,1.36,5.49,1.68,8.28
				c0.61,5.24,2.33,10.04,5.25,14.39c7.42,11.06,10.64,23.56,12.43,36.51c1.29,9.31-2.17,13.82-11.29,15.74
				c-4.78,1.01-7.95,4.99-10.99,8.08c-3.7,3.76-8.28,7.24-10.17,12.64c-0.39,1.13-1.17,2.1-2.43,2.31
				c-8.74,1.43-9.8,10.64-15.38,15.3c-1.34,1.12-0.83,3.5-0.23,5.27c0.78,2.3,0.8,4.5,0.05,6.84c-0.83,2.6-0.15,4.96,1.96,6.76
				c2.13,1.82,4.07,0.76,6.41-0.01c4.52-1.49,8.8-2.96,10.78-7.51c0.66-0.07,1.02-0.2,1.3-0.12c11.26,3.42,11.27,3.43,17.17-6.74
				c1.79-3.08,4.08-5.21,7.96-4.93c0.97,0.07,2.38,0.17,2.89-0.39c4.97-5.35,13.45-7.44,14.98-16.39c2.68,0.29,5.41,0.74,8.16,0.84
				c3,0.11,4.99-1.2,5.58-4.46c0.91-4.98,3.22-9.59,2.97-14.92c-0.17-3.66,2.08-6.28,5.66-8.04c4.49-2.21,8.16-5.78,12.46-8.45
				c4.61-2.87,3.97-8.38,3.46-13.08c8.77-4.14,10.65-7.58,9.41-16.86c-0.22-1.67-0.38-3.35,0.35-4.91c1.59-3.39,1.31-6.75,0.15-10.17
				c-0.59-1.74-1.14-3.62-0.36-5.38c1.69-3.84,0.04-7.33-0.44-11.08c-0.85-6.58-3.49-13.61,2.36-19.7c2.64-2.75,2.69-5.67-0.59-9.04
				c-4.45-4.59-1.78-10.12,0.48-14.85c1.33-2.79,2-4.92,0.64-7.87c-2.31-4.99,1.76-8.24,2.97-12.14c1.69,0.3,2.13,0.93,2.05,1.56
				c-0.72,5.43,2.29,8.69,6.38,11.43c2.92,1.95,2.64,4.15-0.2,5.73c-7.08,3.94-8.67,11.61-3.55,18c1.04,1.3,2.07,2.59,3.07,3.92
				c2.5,3.33,2.12,6.52,1.2,10.63c-1.47,6.55-5.87,12.62-2.41,20.41c2.19,4.91-0.94,11.04-1.26,16.66c-0.07,1.14-0.93,2.49-0.55,3.37
				c3.48,8.3,0.21,16.84,0.86,25.27c0.56,7.28,0.12,15.08-4.21,22.02c-4.62,7.4-2.34,12.05,6.15,13.92c1.68,0.37,2.72,1.1,3.34,2.56
				c1.49,3.52,2.93,7.03,3.03,10.97c0.23,8.63-0.97,17.28,0.83,25.89c0.62,2.95-0.7,5.91-1.46,8.8c-2.16,8.23-1.25,16.05,4.2,22.65
				c3.61,4.37,5.81,9.12,7.45,14.43c1.29,4.18,2.64,8.51,6.2,11.99c2.25-1.8,1.67-4.04,1.52-6.04c-0.78-10.27-2.01-20.43,2.15-30.52
				c1.94-4.71,0.67-10.26,0.08-15.37c-0.92-7.97,1.3-15.53,2.42-23.23c0.21-1.41,0.87-3.14,1.92-3.96c4.66-3.65,4.25-8.68,4.11-13.64
				c-0.12-4.17-0.27-8.32,0.58-12.44c0.41-1.97,0.7-4.67,2.85-4.77c2.71-0.13,5.95-1.22,8.23,1.76c0.93,1.23,1.68,3.01,4.16,2.7
				c1.72-3.66,1.06-7.71,0.69-11.53c-0.42-4.27-1.67-8.46-2.62-12.67c-0.41-1.8-0.86-3.67-3.29-3.72l0.11,0.1
				c-2.07-5.68-6.43-1.86-9.33-1.68c-5.38,0.34-7.97-1.22-6.62-6.56c0.94-3.71,0.43-5.83-2.02-9.11c-3.06-4.09-3.95-10.16-2.29-15.24
				c1.47-4.5,0.69-8.19-1.2-11.89c-1.74-3.4-3.25-6.8-4.6-10.4c-2.17-5.76-3.19-11.27-2.22-17.38c0.64-4.03,0.26-7.99-2.41-11.78
				c-1.66-2.35-0.62-5.78,0.87-8.83c2.14,1.29,2.6,3.5,3.42,5.33c3.75,8.34,7.5,16.69,10.95,25.16c4.53,11.1,8.97,22.22,16.18,31.96
				c0.59,0.79,0.98,1.74,1.42,2.63c7,14.24,10.12,30.17,19.51,43.44c3,4.23,3.64,9.97,4.52,15.22c3.05,18.37,9.21,35.46,18.05,51.97
				c9.2,17.15,15.01,35.86,20.18,54.65c2.12,7.7,4.43,15.32,7.15,22.84c2.66,7.34,2.31,8.06-4.65,14.12
				c-2.81-18.16-10.61-34.11-17.88-50.16c-7.28-16.09-9.59-34.09-19.33-49.21c3.01,20.25,11.98,38.98,14.04,59.5
				c-3.64-10.4-7.28-20.8-10.92-31.2c-0.59,0.13-1.19,0.27-1.78,0.4c-1.02,11.68,3.24,22.98,3.6,35.05
				c-4.66-3.89-7.07-8.66-9.53-13.56c-8.51-16.93-14.06-35.13-22.54-52.06c-0.36-0.71-0.58-1.58-2.34-1.69
				c-0.85,13.57,4.04,26.21,6.4,39.75c-5.88-2.71-6.86-9.18-11.32-12.6c-2.96,3.05-3.12,6.94-3.23,10.41
				c-0.22,6.82-1.14,13.47-2.17,20.21c-1.84,12.09-0.12,24.21,1.72,36.25c0.66,4.35,3.17,9.43-4.03,10.92
				c0.73,3.57,6.02,2.45,5.66,6.32c-0.87,1.92-3.24,1.67-4.55,3.42c0.71,2.4,3.03,2.54,5.83,3.42c-6.01,2.25-6.63,9.88-13.48,9.88
				c0.52,3.53,3.88,2.89,5.18,4.82c-1.73,2.61-4.46,3.82-7.23,5.36c-4.51-2.52-5.49-8.21-11.27-11.18c5.09-0.52,7.94,3.37,11.62,1.81
				c0.63-2.75-2.82-2.67-3-4.77c2.01-1.16,4.62,0.44,7.25-2.11c-5.99-3.13-12.19-5.51-16.96-10.96c4.32-0.24,7.78-2.12,11.74-1.83
				c0.05-3.13-3.06-3.09-4.19-5.25c2.78-1.08,2.79-2.31,0.24-4.13c-3.88-2.76-8.36-4.89-10.96-9.22c3.17-1.83,3.17-1.83,1.29-4.47
				c1.84-1.18,4.23,0.21,6.34-1.38c-2.63-3.51-7.21-4.06-9.97-7.15c2.58-1.87,4.2-4.96,8.87-5.5c-4.76-2.35-8.9-3.54-11.3-8.07
				c5.21,0.97,5.79-3.49,8.05-6.67c-5.47-3.93-10.92-8.29-12.1-16.87c-3.42,4.35-6.09,7.75-8.67,11.04c0.6,2.06,2.46,0.97,3.86,2.2
				c-1.19,5-5.07,8.31-9.19,12.18c3.51,0.98,5.79,3.85,9.65,2.77c-0.51,8.89-9.03,10.13-13.98,15.46c3.54,0.78,5.14,3.46,7.93,5.55
				c-5.25,3.54-10.37,7-15.67,10.57c2.21,2.2,3.96,0.73,6.06,0.81c-1.97,3.15,0.47,2.77,2.73,2.74c-2.81,4.25-8.45,6.94-5.94,13.45
				c-1.78,0.69-2.65,4.87-6.5,2.7c0.68-1.75,2.51-0.58,3.09-2.53c-2.4-3.56-7.44-4.81-10.08-9.02c2.67-0.46,4.87,2.42,8.25,0.4
				c-4.06-3.78-7.85-7.3-12.11-11.27c3.38,0.45,5.63,2.95,8.91,2.47c0.1-3.99-4.24-4.57-5.17-7.61c0.89-1.78,2.83,0.95,4.15-1.59
				c-3.58-4.98-8.85-9.36-11.8-15.39c4.75,0.07,7.14-4,5.3-9.07c1.55-0.47,3.12,1.28,5.18-0.29c-2-3.97-6.66-5.37-8.79-9.05
				c-2.1-3.63-3.64-7.44-4.08-11.88c2.39,3.67,6.12,5.92,8.97,9.12c1.51,1.7,2.77,3.82,5.61,3.52c1.96-0.21,1.65,1.76,2.27,2.76
				c0.93,1.5,0.95,3.5,3,5.2c0.97-7.56-0.54-14.42-1.99-21.24c-1.13-5.33-3.11-10.24-6.59-14.74c-4.24-5.49-6.66-12.06-7.74-18.98
				c-3.64,0.52-2.75,4-5.13,5.52c-6.91-7.14-3.11-19.13-11.33-25.86c-0.57,0.47-1.31,0.78-1.48,1.28c-1.96,5.58-4.41,8.03-9.51,2.47
				c-1.77-1.93-2.45,1.02-3.4,2.07c-1.71-0.34-1.22-2.86-3.08-2.94c-1.48,0.69,9.71,39.48,12.63,43.24c2.6-5.69-0.71-11.29,0.64-18.4
				c2.48,8.28,7.03,14.09,8.83,21.18c0.59,2.31-1.68,3.44-2.39,5.21c-1.15,2.82-6.77,4.85-1.67,9.13c0.42,0.35,0.19,4.59-0.71,6.34
				c-2.69,5.22-3.49,11.58-8.73,15.34L96.69,1043.03z M139.54,974.29c-2.76-1.46-1.33-5.1-5.48-6.65
				c5.01,23.86,7.21,47.32,15.1,69.69c1.92-6.09,1.74-12.21,1.59-18.27c-0.1-3.84,2.6-6.85,4.85-7.5c4.16-1.2,4.16-3.36,3.8-6.5
				c-0.61-5.28-1.18-10.56-1.75-15.85c-1.17-10.89-1.79-21.87-4.9-32.47c-0.48-1.64-0.36-3.6-2.34-4.39
				c-2.06,1.82-0.48,4.48-1.99,6.95c-4.62-10.69-8.89-20.81-12.78-31.22C137.11,943.51,138.44,958.72,139.54,974.29z"/>
			<path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M1030.46,772.69c-1.58,0.13-2.93-0.57-4.15-1.4c-2.16-1.46-3.8-1.59-6.63-0.57
				c-3.13,1.12-7.09,0.59-10.67,1.19c-1.05,0.18-2.12-0.42-3.24,0.47c-3.85,3.06-7.58,2.81-11.31-0.42
				c-2.32-2.01-4.67-2.84-8.15-2.14c-4.49,0.91-9.32,1.29-13.51-2.2c-4.09-3.41-8.72-3.04-12.68,0.42c-0.73,0.64-1.1,1.77-2.24,1.81
				c-7.49,0.32-14.89,2.42-22.44,1.12c-1-0.17-1.84-1.29-2.76-1.95c-1.94-1.4-4.04-2.77-6.39-1.3c-2.08,1.3-4.07,0.91-6.19,0.55
				c-8.33-1.41-16.05-2.97-22.66-9.74c-7.49-7.67-16.99-13.3-26.56-18.42c-0.88-0.47-1.79-0.93-2.75-1.18
				c-19.84-5.27-35.28-19.32-53.88-27.07c-2.09-0.87-4.03-2.4-4.17-5.1c8.71-6.08,8.71-6.08,0.07-12.12
				c10.26-6.33,19.78-13.6,32.04-15.12c7.12-0.89,13.53-3.06,18.95-8c1.79-1.63,4.2-2.66,6.47-3.66c6.96-3.09,14.55-4.8,20.65-9.72
				c0.13-0.1,0.28-0.22,0.43-0.25c7.89-1.09,15.27-3.73,22.4-8.18c-0.3,6.33,5.37,12.48,11.98,13.58c5.12,0.85,9.96,2.79,14.71,4.12
				c6.27,1.76,10.28,4.21,13.87,9.15c1.79,2.46,5.04,1.26,7.49,0.35c6.01-2.24,11.09-2.12,14.35,4.41c0.51,1.03,1.53,2.32,2.65,2
				c5.26-1.52,7.98,2.83,10.24,5.54c2.49,2.99,2.44,7.87,2.53,12.03c0.05,2.43-0.29,4.91,2.15,6.11c2.35,1.16,4.37-0.59,5.58-2.24
				c3.02-4.1,8.08-1.67,11.58-4.67c1.82,7.05-3.85,13.39-0.33,20.37c6.31-2.99,12.15-6.09,15.58-12.13
				c5.03-8.88,11.6-14.44,22.58-12.03c2.07,0.45,2.8-1.44,3.8-2.7c5.04-6.38,6.55-6.75,13.41-2.2c2.16,1.43,3.76,0.23,5.54-0.18
				c5.24,5.16,5.24,5.16,12.45-0.66c2.06,2.5,1.95,5.85,3.33,8.56c2.41,4.75,4.51,5.08,8.8,1.92c4.37-3.22,9.94-4.53,14-8.39
				c0.43-0.41,1.61-0.24,2.38-0.07c4.35,0.93,7.54-0.93,9.8-4.36c2.12-3.21,4.22-6.24,7.47-8.54c2.7-1.91,2.94-5.48,3.88-8.46
				c1.41-4.49,3.63-7.77,9.02-7.67c3.13,0.06,4.41-2.6,5.87-4.82c2.56-3.89,4.95-7.98,9.72-9.63c4.39-1.52,5.79-0.59,5.81,4.02
				c0.05,7.83-0.05,15.66,0.05,23.48c0.03,2.3-0.57,4.17-2.07,5.92c-2.82,3.28-5.5,6.68-8.25,10.03c-0.53,0.64-0.99,1.66-1.65,1.82
				c-7.83,1.92-10.65,9.44-15.96,14.16c-7.18,6.39-11.32,14.9-14.2,23.7c-2.41,7.36-7.28,10.44-14.02,11.52
				c-12.73,2.04-24.53,5.99-34.15,15.02c-0.89,0.83-1.96,1.71-3.08,1.25c-3.56-1.47-6.71,0.65-9.9,0.77
				c-4.41,0.16-7.54,3.14-11.64,3.43c-1.39,0.1-2.48,1.12-3.12,2.3c-1.82,3.34-3.81,4.32-7.59,2.24
				C1032.5,766.99,1032.55,771.7,1030.46,772.69z"/>
			<path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M1133.83,1197.47c-3.62-2.47-4.13-6.58-5.08-10.4c-1.16-4.67-1.6-9.52-3.75-13.92
				c-3.09-6.3-7.18-6.71-11.05-0.86c-2.03,3.06-3.42,6.37-7.8,2.88c-1.27-1.02-2.91-0.27-4.18,0.64c-1.95,1.39-3.93,1.29-6.16,0.66
				c-6.16-1.74-6.18-1.67-4.24-7.71c0.59-1.83-3.25-13.04-4.81-14.26c-0.75-0.58-1.49-0.7-2.3-0.32c-0.75,0.35-1.47,1.17-2.18,1.15
				c-10.22-0.38-19.4,6.25-29.83,4.42c-4.67-0.82-5.39-0.1-3.84,4.65c2.24,6.87,4.45,13.66,1.81,20.91c-0.22,0.6-0.54,1.15-0.96,2.02
				c-0.72-0.46-1.21-1.01-1.76-1.07c-6.75-0.7-12.62-3.92-18.87-6.13c-5.22-1.85-10.64-2.05-10.13-9.51
				c-3.79-0.35-5.37,2.06-6.15,4.44c-1.85,5.71-5.93,7.81-11.39,7.79c-4.48-0.02-5.83,2.25-5.54,6.28
				c0.45,6.14,0.85,12.29,1.19,18.44c0.1,1.79,0.51,3.66-0.59,5.57c-5.5-0.96-9.03,2.09-11.72,6.43c-1.93,3.13-3.96,5.8-8.22,5.17
				c-1.88-0.28-2.67,1.1-3.18,2.57c-0.59,1.73-1.49,3.51-1.45,5.26c0.16,7.45-4.45,11.16-10.29,14.26c-3.03,1.6-5.76,3.77-8.7,5.73
				c-1.18-6.9-0.08-9.49,5.63-11.98c2.82-1.23,3.44-2.95,3.22-5.84c-0.41-5.47-0.34-10.97-0.53-16.46c-0.2-5.8,1.89-10.46,7.05-13.25
				c3.2-1.73,4.08-4.45,4.74-7.65c1.42-6.91,4.14-12.54,12.39-13.5c4.01-0.46,6.03-3.97,7.43-7.43c2.38-5.89,5.29-10.71,12.2-12.67
				c5.14-1.45,8.82-13.83,6.38-20.54c-1.25-3.44-0.29-4.99,2.22-6.95c5.64-4.4,10.98-9.14,17.51-12.31
				c4.22-2.05,5.56-6.43,7.23-10.41c1.38-3.29,2.56-6.47,7.37-5.57c2.56,0.48,4.37-1.54,5.16-3.9c0.84-2.51,1.51-5.11,1.9-7.73
				c1.46-9.91,3.74-19.56,7.82-28.78c2.82-6.36,6.37-10.64,12.54-13.61c1.18-0.57,2.37-1.12,3.57-1.66c0.13-0.06,0.32,0.04,0.58,0.07
				c0.28,0.3,0.78,0.6,0.92,1.02c3.38,10.18,4.62,8.2,11.65,2.65c3.5-2.76,6.39-6.23,10.99-7.53c2.5-0.71,2.91-3.38,3.46-5.69
				c2.69-11.25,2.1-22.83,3.51-34.21c0.51-4.12,0.64-8.3,1.18-12.42c1.04-7.87,3.98-11.09,12.03-13.07c3.26-0.8,4.84-3.89,7.61-5.32
				c2.06,1.92,1.14,5.05,3.55,6.91c2.6-2.24,1.87-5.22,1.91-7.77c0.13-9.07,4.11-15.53,12.36-19.33c5.67-2.61,9.53-7.55,14.23-11.6
				c1.39,1.82,0.83,3.53,0.83,5.09c0.03,73.93,0.03,147.87,0.03,221.8c0,7.21-2.64,9.55-9.71,9.04c-4.46-0.32-6.53,3.08-8.08,6.35
				C1141.55,1192.53,1139.06,1195.98,1133.83,1197.47z"/>
			<path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M2.08,467.31c2.35,1.91,3.19,3.5,3.23,5.72c0.14,7.76-0.07,15.37,2.65,23.1
				c1.61,4.58-0.38,10.47-0.9,15.76c-0.46,4.67-0.64,9.32,0.54,13.9c1.13,4.4,3.5,8.13,7.96,9.48c2.27,0.69,2.75,1.95,3.3,3.71
				c1.27,4.02,3.08,7.67,6.59,10.33c2.67,2.02,3.72,4.67,3.21,8.26c-0.45,3.13-1.31,6.4-0.42,10.78c3.97-5.2,5.39-10.82,9.99-15.74
				c0.05,7.67,2.93,14.05,2.1,21.06c-0.77,6.45-1.03,12.95,0.04,19.43c0.16,0.98,0.16,2.01,0.45,2.96c1.13,3.7,2.28,8.16,6.28,8.96
				c4.35,0.87,5.63-3.75,7.44-6.84c2.43-4.14,4.85-8.27,7.76-12.25c-5.62,11.81-6.92,24.75-10.34,37.12
				c-1.75,6.32-2.38,12.7,0.72,18.49c3.44,6.42,3.02,12.84,1.57,19.37c-2.81,12.72-3.71,25.68-5.28,38.55
				c-0.89,7.24,3.41,10.77,9.91,8.53c1.87,0.9,1.66,3.67,4.48,4.56c5.93-5.7,8.86-14.28,15.5-19.73c1.96,1.64,1.1,4.34,3.05,6.2
				c2.66-1.88,5.31-4.79,9.03-1.3c2.42-4.76,3.23-9.59,3.53-14.22c0.78-12.12,1.09-24.31-2.81-36.11c-1.21-3.65-2.32-7.29-5.88-9.53
				c-1.92-1.21-1.96-3.28-1.95-5.35c0.04-8.56,0.01-8.56-8.16-11.71c3.78-11.5,0.17-24.1,4.98-35.68c5.11,0.31,8.45,5.59,14.26,4.04
				c0.23,7.63,1.68,14.84-0.6,22.02c-0.5,1.57-1.31,3.26,0.53,4.51c1.58,1.08,3.27,0.59,4.74-0.24c2.57-1.45,4.83-3.47,7.86-4.25
				c3.01,3.07,2.74,6.18,0.35,9.37c-4.37,5.84-5.53,12.22-3.53,19.26c1.15,4.07-0.41,8.2,0,12.4c0.38,3.89,0.01,7.83,1.18,11.69
				c0.47,1.55,0.05,3.22-0.66,4.84c-3.06,6.97-3.79,14.21-2.35,21.7c0.48,2.5,0.66,5.28,3.64,5.9c3.2,0.67,6.29,0,8.72-2.5
				c3.99-4.12,7.96-8.25,11.72-12.15c5.29,1.31,8.71,4.02,10.67,8.37c2.92,6.47,9.09,10.98,10.62,18.34c0.57,2.73,1.48,5.27,1.49,8.2
				c0.02,5.53,4.39,9.44,6.27,14.36c1.12,2.93,3.02,5.47,2.63,8.87c-0.12,1.09,0.04,2.56,0.69,3.34c6.2,7.38,7.74,17.04,12.46,25.14
				c3.34,5.72,6.52,11.54,9.68,17.37c2.29,4.23,1.55,9.03,2.86,14.43c-3.33-3.18-3.53-6.9-5.25-9.84
				c-4.16-7.09-9.23-13.85-10.95-22.18c-0.42-2.02-1.97-3.5-2.64-5.24c-4.01-10.35-11.09-18.6-17.71-27.22
				c-1.65-2.15-2.57-4.39-5.83-4.59c-3.67-0.22-5.08-3.99-7.76-5.93c-0.5,0.22-1.24,0.33-1.32,0.61c-2.13,7.92-5.64,15.75-2.69,24.16
				c1.66,4.75,1.95,9.41,0.52,14.18c-1.07,3.54-0.71,6.93,0.95,10.17c1.29,2.52,2.41,5.03,2.66,7.95c0.2,2.37,1.54,4.45,3.54,5.82
				c3.97,2.73,5.23,6.43,5.09,11.16c-0.17,5.75,1.3,11.34,0.47,17.32c-0.87,6.27-1.32,12.38-3.96,18.31
				c-1.65,3.7-0.44,7.85,0.51,11.78c2.55,10.51-3.77,22.45-13.73,26.23c-3.43,1.3-5.98,3.44-6.36,7.46c-0.16,1.7-0.71,3.4-2.79,3.59
				c-6.22,0.58-9.36,5.06-12.53,9.95c-3.18-0.55-6.42-1.72-10.02,0.44c-3.16-12.91-8.93-24.1-15.31-35.02
				c-0.89-1.53-1.25-3.44-1.55-5.23c-3.37-20.55-11.23-39.55-20.04-58.22c-2.07-4.4-4.91-8.27-8.59-11.53
				c-3.44-3.05-5.13-7.32-7.28-11.25c-3.23-5.9-7.4-10.01-13.85-13.37c-8.1-4.22-17.57-7.3-23.12-15.76
				c-1.42-2.16-2.52-4.02-2.52-6.72c0.06-88.61,0.05-177.22,0.06-265.83C1.87,470.23,1.99,469.11,2.08,467.31z M82.72,728.62
				c-2.83-0.08-3.75-3.28-5.64-1.95c-3.34,2.36-5.56,0.48-7.94-1.4c-1.97-1.56-3.95-1.33-6.05-0.27c-3.69,1.87-6.39,4.75-8.4,8.29
				c-4.79,8.4-4.27,17.8-5.5,26.75c0.85,0.35,1.17,0.6,1.45,0.57c0.82-0.09,1.65-0.22,2.44-0.46c6.81-2.11,7.49-2.09,10.47,4.54
				c3.68,8.2,9.05,13.51,18.59,13.54c2.46,0.01,3.6,2.23,4.6,4.13c2.98,5.64,5.21,11.49,4.76,18.07c-0.38,5.41,0.18,11.03,3.06,15.43
				c3.93,6,5.33,12.8,7.96,19.19c0.57,1.38,0.83,2.8,2.13,3.8c7.58,5.83,10.11,14.05,10.79,23.11c0.22,2.89-0.07,5.93,3.33,8.98
				c2.42-6.66,5.89-12.61,6.59-18.88c1.46-12.88,2.76-25.76,4.93-38.55c0.97-5.73,1.53-11.51-2.36-16.74
				c-1.23-1.65-1.91-3.96-2.14-6.04c-1.17-10.64-5.71-20.87-3.83-31.89c0.24-1.43,0.13-3.11-0.39-4.44
				c-2.52-6.44-2.43-14.72-10.34-17.64c-5.32-1.97-7.22-5.65-8.48-10.62c-1.11-4.39,0.02-8.55,0.15-12.82
				c0.14-4.6-3.46-7.34-6.57-4.77C89.96,713.82,80.44,717.12,82.72,728.62z"/>
			<path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M365.3,1189c9.2-2.14,13.48-8.23,14.28-17.38c0.3-3.37,0.25-6.9,2.02-10.71
				c3.8,6.55,8.47,11.61,14.18,15.71c7.61,5.45,15.79,8.15,25.23,5.95c9.2-2.15,18.24-1.6,27.64,0.05
				c18.25,3.2,36.74,3.39,55.08-0.07c10.6-2,21.44-1.35,32.46-3.33c-9.28,10.07-6.12,19.51,0.38,28.84c1.71,2.46,3.44,4.9,5.27,7.28
				c9.12,11.87,11.54,24.91,8.04,39.52c-1.64,6.86-2.7,14.09-0.85,21.26c0.43,1.68,0.68,3.48,3.08,5.44
				c-3.78-12.89-0.79-24.58,0.91-36.33c1.76-12.18-3.62-22.04-10.35-31.36c-3.22-4.47-6.39-8.94-8.76-13.91
				c-3.27-6.87-1.6-14.49,3.97-19.58c2.65-2.42,5.91-1.96,8.51-1.08c16.82,5.68,33.94,15.28,36.05,35.52
				c1.29,12.37-3.42,21.64-12.42,29.35c-2.78,2.38-5.85,4.43-8.51,6.93c-8.34,7.83-10.13,17.95-5.14,28.22
				c3.74,7.71,8.47,14.81,15.04,20.34c6.02,5.07,7.95,11.11,5.09,18.22c-4.44,11.05-5.61,22.44-5.37,34.23
				c0.21,10.56-3.53,20.18-8.85,29.49c-2.57-2.59-6.78-1.02-9.41-3.88c8.82-18.74,6.42-38.7,6.23-58.37
				c-0.05-5.08-2.95-9.51-6.22-13.37c-2.47-2.91-5.11-5.74-8-8.22c-9.95-8.56-18.31-18.26-24.17-30.16
				c-3.34-6.79-8.37-12.42-15.08-16.26c-1.52-0.87-2.94-2.28-6.14-1.16c9.93,6.13,17.33,13.73,21.45,24.34
				c-22.47,13.95-46.54,10.84-70.9,7c0.25-0.43,0.43-0.83,0.68-1.17c0.28-0.39,0.76-0.71,0.08-1.47c-4.02,0.1-7.8,1.74-12.16,3.58
				c-0.54-5.67,0.96-10.63,2.6-15.56c0.8-2.39,1.77-4.82,0.92-7.29c-2.08-6.04-3.28-12.1-1.32-18.5c-3.57,4.17-4.08,9.02-2.36,13.68
				c1.87,5.07,1.29,9.51-0.48,14.43c-3.4,9.45-3.98,19.52-5.24,29.4c-2.43,18.97-6.46,37.71-8.08,56.8
				c-0.29,3.43-0.47,6.87-0.74,10.93c-4.63-2.83-8.6,1.95-13.28-0.38c1.22-9.98,2.05-20.16,3.24-30.34
				c2.85-24.3,2.84-48.5-5.31-72.03c-0.96-2.77-1.88-5.6-4.76-7.56c1.77,5.95,3.54,11.91,5.31,17.86
				c-6.22-3.13-10.46-7.91-12.74-13.98c-4.3-11.46-10.52-21.84-16.82-32.22c-0.5-0.83-0.77-1.81-1.15-2.72
				c1.77-1.06,2.24,1.76,4.95,1.08c-6.99-8.13-8.38-18.18-11.61-28.17c-1.09,4.71-0.65,8.74,1.1,12.35
				c2.12,4.36,3.52,8.87,4.42,13.58c-8.05-8.28-11.24-18.84-13.98-29.65c-0.64-2.54,2.13-3.21,2.1-5.22
				C365.38,1188.92,365.3,1189,365.3,1189z M496.17,1271.84c-0.11-1.73,2.1-2.86,1.29-5.25c-2.52,3.11-4.79,5.89-7.59,9.34
				c3.65-0.51,4.28-3.39,6.43-4.23c0,1.33,0.25,2.76,1.83,1.24c2.58-2.5,4.27-5.54,4.53-10.13
				C499.93,1266.17,499.43,1270.01,496.17,1271.84z M566.43,1316.1c3.95-8.44-1.61-21.49-12.94-30.99
				C557.71,1295.81,569.86,1302.66,566.43,1316.1z"/>
			<path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M951.15,869.57c-3-3.62-5.45-3.36-7.77-0.29c-0.69,0.92-1.74,1.99-2.93,1.6
				c-10.94-3.61-15.46,3.57-19.28,11.49c-2.71,0.38-3.44-2.16-5.49-2.99c-2.79,0.64-3.82,3.66-5.81,5.4
				c-2.91-1.55-2.85-5.08-5.61-6.9c-2.78,1.71-5.26,5.72-9.5,2.51c-0.32-0.24-1.8,0.71-2.49,1.37c-2.24,2.15-4.25,3.62-7.85,2.24
				c-2.97-1.13-4.94,1.91-6.09,4.14c-3.35,6.53-9.78,5.4-15.1,5.94c-4.34,0.44-5.31-4.82-7.53-7.56c-2.17-0.04-1.98,2.3-4.14,3.09
				c-1.67-5.36-8.42-3.53-11.1-8c-5.69,4.14-5.69,4.14-11.2-3.65c-11.05,3.26-10.03-8.43-15.15-12.34c-2.8,1.73-1.71,5.01-3.41,7.36
				c-3.74-1.18-4.84-5.37-7.95-7.1c-3.35,0.55-2.85,4.35-5.92,6.27c-7.05-5.93-14.33-12.06-21.86-18.4
				c-3.81,3.29-5.97-0.44-8.41-2.89c-5.38-5.39-5.52-5.43-10.55-0.01c-0.51-0.3-1.31-0.48-1.57-0.96
				c-3.41-6.16-10.37-7.79-15.28-12.02c-0.58-0.5-1.64-1.14-2.16-0.93c-5.33,2.12-7.56-1.78-9.98-5.1c-1.77-2.42-3.56-4.22-6.94-3.69
				c-2.76,0.43-5.18-1.17-7.56-2.24c-7.81-3.52-16.03-6.49-21.2-14.03c-0.87-1.27-2.75-2.08-1.6-4.22c0.87-1.61,1.92-2.63,3.86-2.56
				c3.17,0.11,5.33-1.25,6.6-4.27c0.99-2.37,2.74-2.95,5.29-1.92c2.95,1.2,5.61,0.55,7.24-2.46c0.84-1.56,2.08-2.9,3.69-3.14
				c6.16-0.94,8.13-6.62,12.04-10.11c4.47-3.98,6.93-10.27,13.74-11.67c2.33-0.48,3.34-3.15,4.62-5.05
				c4.85-7.25,7.31-8.17,15.51-5.76c4.07,1.19,6.2-2.38,9.39-4.19c2.96,3.32,5.94,2.65,9.36,0.12c4.81-3.55,9.68-1.52,10.68,4.34
				c0.42,2.46,1.57,3.31,3.71,4.5c3.1,1.72,5.66,4.54,9.44,4.85c3.72,0.31,6.58,1.31,9.24,4.46c1.4,1.66,5.7,0.37,7.25,3.66
				c0.56,1.19,1.66,2.2,1.94,3.43c1.08,4.71,4.16,5.8,8.27,4.61c3.73-1.08,5.96,0.16,7.65,3.48c1.68,3.28,5.12,4.41,8.01,6.34
				c6.14,4.1,13.91,1.58,19.91,5.66c1.08,0.74,2.57,0.31,3.83-0.35c2.19-1.16,4.13-0.27,6.13,0.73c10.31,5.17,21.04,8.35,32.78,6.99
				c3.83-0.44,7.68-0.2,11.23,1.52c4.33,2.09,9.15,3.64,12.77,6.63c8.08,6.68,17.86,8.31,27.44,10.71
				c7.01,1.76,10.72,10.65,7.63,18.4c-1.61,4.03-4.67,6.88-7.86,9.52C956.92,859.6,953.28,863.38,951.15,869.57z"/>
			<path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M1132.97,65.58c-6.95,2.22-10.65,7.37-13.17,13.9c-5.43-3.7-10.23-3.52-15.03,1
				c-2.59,2.44-7.25,0.49-9.62,3.84c-21.77-18.66-47.67-13.94-54.41,9.81c-4.15-0.06-7.74,0.56-10.87,4.36
				c-1.74,2.11-5.99,3.03-9.1,3c-18.12-0.18-36.32,0.15-54.32-2.02c-21.15-2.54-42.19-0.57-63.25,0.26
				c-20.29,0.8-40.57,1.72-60.85,0.95c-14.71-0.56-29.17,2.04-43.8,2.43c-13.12,0.34-26.22,1.7-39.33,2.51
				c-4.4,0.27-8.65-0.24-12-4.27c3.34-4.19,7.98-4.81,12.74-5.48c6-0.84,11.73-2.12,15.92-7.41c2.56-3.22,6.74-2.02,10.36-1.79
				c2.65,0.17,5.1,1.22,6.82-2.7c1.21-2.76,5.29-2.06,8.26-1.74c4.33,0.46,8.6,2.08,11.3-3.73c1.24-2.67,5.02-2.81,8.15-2.28
				c5.22,0.88,9.63,3.54,14.02,6.27c2.33,1.45,4.63,3.15,8.28,2.97c-1.3-7.73,1.41-13.77,7.16-18.23c4.49-3.48,10.06-2.02,17.66,4.03
				c1.87-0.94,1.08-2.94,1.6-4.39c1.74-4.82,5.54-6.67,10.08-7.94c4.98-1.4,10.28-2.17,14.06-6.42c1.48-1.67,3.72-1.07,5.67-0.62
				c1.54,0.36,2.84,1.74,4.61,1.3c0.9-21.56,12.95-27.25,37.85-17.75c3.3-5.12,7.85-4.18,12.46-1.86c0.47-0.63,0.97-0.99,1.02-1.41
				c1.64-13.61,10.46-14.39,20.12-13.03c4.33,0.61,8.19,2.71,12.08,4.6c2.82,1.37,5.54,2.21,8.18-0.56c0.86-0.9,2.43-1.81,3.64-1.1
				c4.88,2.84,10.35,2.84,15.65,3.87c4.63,0.89,8.19,6.07,8.53,10.68c0.11,1.44-0.05,2.55-1.59,3.24c-2.39,1.06-4.1,2.89-4.86,5.4
				c-0.54,1.76-1.61,2.24-3.32,2.2c-9.01-0.22-14.41,4.11-15.9,13.71c-7.54-4.12-15.31-5.2-23.36-4.76
				c-12.88,0.71-21.91,7.84-24.31,19.11c-0.82,3.84-0.07,7.07,2.82,9.66c1.18-0.58,1.12-1.2,0.9-1.86
				c-2.66-8.04,0.29-16.4,7.87-20.38c11.22-5.89,22.65-5.68,33.98,0.03c1.44,0.73,2.49,2.25,4.16,2.19c0.96-0.32,1.07-1.01,1.08-1.66
				c0.21-9.91,2.87-12.85,12.61-13.3c3.09-0.14,5.59-0.41,6.05-4.17c0.17-1.37,1.06-2.95,2.28-3.06c5.65-0.51,8.64-5.8,13.69-7.33
				c1.04-0.31,2.01-1.15,2.8-1.95c5.26-5.29,9.15-5.18,14.15,0.44c1.06,1.2,1.74,2.88,3.78,3.17c0.39-0.51,0.91-0.97,1.16-1.55
				c4.37-10.32,11.91-12.39,21.07-5.7c1.34,0.98,2.65,2.08,3.67,3.36c3.04,3.83,6.08,7.13,11.61,7.57c3.28,0.26,5.63,3.13,7.03,6.3
				c-6.09,4.18-11.94,4.14-17.34,0.43c-7.23-4.98-15.35-6.16-23.84-4.69c-6.13,1.06-9.32,5.29-10.52,11.22
				c-5.1,0.56-11-1.38-13.39,5.36c-6.86-1.7-12.91-1.36-16.43,5.65c-1.24,2.47-1.31,4.92,1.48,6.96c1.16-9.23,2.79-12.55,17.2-9.18
				c1.26-9.79,8.67-5.03,13.53-5.49c1.38-1.21,1.06-2.43,1.05-3.57c-0.05-4.6,2.59-7.36,6.69-8.1c10.13-1.82,19.59-0.61,26.82,7.83
				c0.64,0.74,1.08,1.65,0.94,1.43c7.78-1.36,13.95-6.04,21.15-1.83c1.68,0.99,3.02,1.34,4.79-0.41c4.2-4.14,8.09-4.22,12.78-0.62
				C1129.87,55.48,1132.37,59.66,1132.97,65.58z"/>
			<path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M935.73,970.96c-5.84-2.81-8.68,0.6-11.24,4.97c-3.91,6.7-8.7,12.87-10.26,20.83
				c-0.61,3.1-2.34,6.13-5.54,7.96c-1.33,0.76-2.02,2.59-3.12,3.82c-1.48,1.64-1.06,5.16-4.02,5.12c-3.28-0.05-8.25-0.08-9.19-2.65
				c-1.92-5.26-7.51-7.38-9.16-11.96c-3.55-9.84-11.27-14.39-20-18.1c-2.14-0.91-4.44-1.41-6.6-2.26c-2.73-1.07-5.08-2.33-4.58-6.1
				c0.39-2.89-2.3-4.21-4-5.95c-4.3-4.41-4.02-6.98,1.16-10.22c10.2-6.39,10.13-13.2-0.19-19.53c-3.26-2-6.68-3.81-9.64-6.19
				c-4.34-3.5-4.21-7.24,0.02-10.88c1.51-1.29,3.18-2.41,4.86-3.49c4.06-2.6,6.32-5.87,4.42-10.88c-1.26-3.33,0.61-4.89,3.37-6.08
				c5.87-2.53,12.32-2.84,18.41-3.52c4.61-0.52,6.85-2.45,9.12-5.54c1.8-2.45,3.44-5.15,7.18-3.08c0.26,0.15,0.8,0.22,0.96,0.07
				c4.6-4.26,11.3-2.1,16.37-5.14c5.42,7.1,5.42,7.1,10.9,0.72c7.21,3.61,7.21,3.61,10.96-4.24c1.47-3.09,8.84-6.4,12.15-5.47
				c3.52,0.99,3.9,0.86,8.97-3.41c3,2.14,6.28,4.81,9.37-0.11c0.22-0.36,1.36-0.54,1.8-0.31c3.18,1.68,4.49-0.59,6.01-2.63
				c2.91-3.9,5.93-7.61,11.35-3.56c0.13,0.09,0.32,0.1,0.47,0.16c2.66,1.11,3.96-1.06,5.53-2.39c1.96-1.67,3.87,0.35,5.69-0.11
				c2.12-0.53,3.63-2.04,4.28-4.47c-4.16,3.59-10.87-2.31-13.7,4.58c-2.48-1.23-4.38-2.19-6.72-3.35c1.96-3.07,4.77-5.09,7.2-7.45
				c4.71-4.59,5.11-13.41,0.59-18.23c-5.69-6.07-13.11-8.69-21.14-8.42c-4.32,0.15-7.98-1.31-13.15-2.04
				c5.56-2.88,10.59-2.48,15.36-3.05c4.46-0.53,8.94-0.82,13.28-2.07c3.62-1.04,6.7-2.93,9.14-5.79c3.92-4.6,3.85-8.87-0.19-13.39
				c-5.38-6.01-12.51-8.58-20.16-10.05c-1.43-0.28-3.06,0.07-3.94-1.58c-1.65-3.12-4.04-4.24-7.46-2.88
				c-0.51,0.2-1.54,0.06-1.83-0.31c-3.12-3.95-6.93-6.78-12.24-7.1c0.89-2.58,2.63-2.36,3.58-1.89c6.53,3.22,12.68-2.79,19.15-0.65
				c0.74,0.24,1.22-0.9,1.75-1.54c3.13-3.79,6.92-4.3,10.75-1.42c4.88,3.66,10.27,4.42,16.04,2.86c1.54-0.42,2.8,0.19,3.66,1.02
				c3.03,2.93,6.68,3.77,10.7,3.94c1,0.04,2.12,0.52,2.85-0.23c3.67-3.73,8.52-2.18,12.76-3.23c4.13-1.02,7.35-0.42,11.02,1.06
				c2.67,1.08,4.89-0.31,5.62-3.87c5.02,3.18,8.27,1.87,10.93-2.74c1.41-2.45,5.16-2.83,7.36-3.25c4.47-0.86,8.67-4.33,14.43-2.84
				c-5.4,5.71-9.94,10.99-13.57,16.98c-0.41,0.68-1.13,1.27-1.84,1.65c-15.79,8.28-24.16,22.7-31.47,37.99
				c-1.58,3.3-2.98,6.68-4.4,10.05c-1.55,3.68-3.3,6.27-7.74,7.97c-6.17,2.36-9.09,9.05-12.08,14.72
				c-4.57,8.66-7.55,18.26-12.76,26.48c-10.37,16.38-15.97,34.97-25.23,51.83c-2.34,4.25-6.54,6.55-8.91,10.59
				c-4.91,8.36-8.7,17.2-11.93,26.31C936.83,968.24,936.32,969.44,935.73,970.96z"/>
			<path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M1.87,1336.01c0-4.41-0.06-8.07,0.02-11.73c0.1-4.69,4.26-7.59,8.54-5.62c5.87,2.7,11.43,0.33,16.71-1.2
				c3.66-1.06,7.33-2.98,10.97-4.54c5.61-2.39,9.51-0.86,12.75,4.38c2.67,4.31,9.97,7.11,15.18,6.8c7.16-0.43,14.05,1.83,20.61,4.35
				c4.14,1.59,8.86,2.45,11.88,6.65c1.14,1.59,4.03,0.98,6.2,0.68c3.36-0.46,6.22-0.15,8.97,2.58c2.87,2.85,7,2.68,10.78,1.72
				c8.22-2.08,8.64-1.98,13.57,5.26c2.6,3.81,8.89,4.9,13.68,1.74c3.25-2.14,5.95-2.24,9.19-0.33c4.02,2.38,8.65,2.19,13.07,2.11
				c4.1-0.07,5.58,2.1,5.31,5.48c-0.27,3.46-0.39,6.84-3.78,9.66c-5.69,4.73-4.38,9.24,2.9,11.18c4.01,1.07,5.63,4.29,6.71,7.37
				c2.21,6.28,6.91,9.78,12.47,12.12c4.79,2.02,7.97,5.08,9.75,9.84c1.08,2.88,3.21,3.65,6.16,4.69c4.05,1.43,8.6,2.12,11.62,7.22
				c2.26,3.81,4.2,7.76,6.23,11.69c0.82,1.57,2.19,3.41,4.31,2.62c3.51-1.31,5.16,1.01,7.06,2.85c0,0.49,0.12,0.87-0.02,1.09
				c-4.83,7.74-4.93,7.88,2.83,12.88c4.57,2.95,7.11,6.55,6.64,12.21c-0.32,3.85,0.04,7.22,4.39,9.59c3.55,1.94,4.2,6.73,3.22,10.29
				c-0.96,3.47,0.47,4.43,2.74,5.77c3.21,1.88,5.49,4.23,4.29,8.49c-0.92,3.29-2.44,4.93-6.27,4.83c-10.77-0.28-21.55-0.1-32.22-0.1
				c-2.48-13.95-3.18-14.57-11.43-9.9c-4.13-7.03-11.12-2.73-16.91-3.7c-3.35-0.56-5.53-1.43-7.22-4.16
				c-1.49-2.39-2.99-4.81-4.74-7.02c-1.48-1.88-3.79-3.45-6.02-3.02c-3.55,0.68-6.46-0.6-9.54-1.66c-1.64-0.56-3.24-0.84-4.95,2.37
				c5.37-1.63,8.78,3.41,13.95,1.54c2.33-0.84,4.45,1.05,5.84,3.04c1.43,2.03,2.6,4.24,3.93,6.35c3.11,4.94,10.96,7.51,16.08,5.06
				c1.98-0.94,3.53-0.55,5.09,0.57c1.61,1.16,3.14,2.42,4.57,3.54c2.69-0.04,3.93-4.63,6.54-2.43c2.57,2.16,2.11,5.67,1.36,9.11
				c-23.16,0-46.24,0-69.8,0c-1.75-5.49-5.33-7.51-11.8-7.21c-6.21,0.28-11.91-3.16-14.69-9.47c-2.1-4.77-5.03-7.24-10.51-5.49
				c-1.72,0.55-3.93,0.49-5-1.25c-1.18-1.93-0.34-3.99,1.26-5.38c1.7-1.47,2.09-3.7,3.37-5.34c2.75-0.1,4.99,1.01,7.28,1.94
				c1.53,0.63,3.09,1.52,4.68,1.63c5.06,0.35,9.61,1.49,14.35,3.83c6.9,3.4,14.55-1.45,16.2-8.97c0.24-1.11,0.53-2.21,0.79-3.29
				c2.08-0.35,4.5,2.54,5.59,0.22c1.61-3.43,5.88-5.65,5.59-9.5c-0.26-3.48-3.85-4.26-7-3.84c-1.57,0.21-3.03,1.27-4.52,1.98
				c-5.24,2.51-9.99-0.22-9.04-5.84c0.82-4.84-0.97-6.01-5.01-7.56c-5.39-2.07-10.86-4.29-12.43-11.55c-0.84-3.9-5.86-5.62-9.88-5.38
				c-6.08,0.36-12.25-2.04-18.27,0.62c-0.89,0.39-2.43,0.1-3.32-0.45c-3.11-1.92-6.29-1.86-9.6-0.86c-2.96,0.9-5.88,0.93-7.23-2.33
				c-1.56-3.77,2.13-4.19,4.19-5.52c1.25-0.81,2.64-1.41,3.95-2.1c8.92,5.48,13.73,4.65,20.72-3.8c2.14-2.59,4.56-2.65,7.07-1.18
				c2.72,1.59,5.18,3.61,7.8,5.37c3.83,2.59,7.91,3.57,12.2,1.26c1.79-0.97,3.4-1.13,5.18-0.17c0.58,0.31,1.25,0.45,1.89,0.63
				c4.8,1.36,9.56,0.04,10.38-3.23c1.15-4.54-1.62-8.31-5.28-8.92c-8.04-1.35-12.55-8.21-19.61-10.96
				c-4.29-1.67-8.15-3.54-12.85-1.78c-1.59,0.6-3.61,1.2-4.98-0.77c-1.54-2.21,0.97-3.06,1.45-4.56c2.13-6.57,7.82-4.98,13.87-5.09
				c-4.22-4.99-3.35-11.22-9.16-13.79c-3.36-1.49-5.83-1.45-8.36,1.47c-4.06,4.7-11.75,3.58-14.94-1.85
				c-0.72-1.22-2.07-2.13-3.25-3.01c-1.77-1.31-3.57-2.69-4.15-4.84c-1.73-6.44-7.1-7.97-12.45-9.13c-4.98-1.08-9.95-2.63-15.19-2.22
				c-1.9,0.15-2.29-1.62-3.1-2.81c-4.31-6.38-8.73-6.79-14.53-1.63c-3.29,2.92-7.36,1.49-9.41-0.91c-6.04-7.06-12.25-3.32-18.52-1.26
				c-2.1,0.69-2.43,3.07-3.35,4.78c-1.62,2.99-3.62,3.55-5.66,0.75C7.86,1336.89,5.53,1335.51,1.87,1336.01z M170.79,1426.32
				c0.85,0.25,1.43,0.49,2.03,0.59c4.5,0.73,8.29,1.28,11.01,6.51c2.59,4.98,7.53,9.85,14.35,9.02c6.14-0.75,11.79-0.75,16.64,3.92
				c1.56-2.51,0.61-4.54-0.15-6.45c-1.34-3.41-2.68-6.24-7.6-4.09c-2.79,1.22-6.66,1.46-9.54,0.56c-3.1-0.97-7.01-1.61-8.18-6.22
				c-1.08-4.25-8.36-8.28-12.8-7.87C174.08,1422.53,171.68,1423.12,170.79,1426.32z M246.97,1476.7c0.69-1.34,1.18-2.2,1.59-3.1
				c2.81-6.25,1.14-8.76-5.28-11.08c-4.61-1.66-9.79-2.12-13.21-7.24c-3.29-4.91-6.98-4.52-14.44-0.45c-3.76,2.05-4.62,5.63-4.1,9.75
				c2.77,0.8,5.29,0.34,7.44-0.73c4.81-2.4,8.24,0.59,11.87,2.65c1.21,0.68,2.01,3.12,2.94,3.08
				C239.43,1469.37,242.41,1473.93,246.97,1476.7z"/>
			<path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M625.24,1485.56c5.9,0.99,11.62,3.16,18.56,3.44c-2.31-2.85-4.84-2.68-6.96-3.26
				c-24.71-6.81-48.84-15.28-72.43-25.3c-5.01-2.13-9.94-4.47-13.59-8.99c-1.86-2.3-5.24-2.73-8.06-3.72
				c-4.39-1.53-8.9-2.79-13.17-4.6c-6.89-2.91-7.63-7.17-2.56-12.97c7.25,2.98,8.88,1.87,15.05-5.19c4.15,4.15,6.95,1.57,9.38-2.52
				c2.33,1.73,4.31,2.98,7.09,1.12c1.89-1.26,3.33,0.63,4.23,2.32c0.84,1.58,0.92,3.57,2.4,4.66c1.8,0.72,2.2-1.47,3.77-1.42
				c1.85,1.03,2.76,2.67,1.99,4.86c-0.43,1.23-1.36,2.28-1.97,3.46c-0.87,1.69-1.63,3.66,0.29,4.94c2.22,1.47,2.67-0.82,3.49-2.17
				c1.25-2.05,2.69-1.64,4-0.12c0.98,1.13,1.69,2.48,2.65,3.62c3.92,4.6,8.91,4.16,11.76-1.05c0.5-0.92,0.94-2,1.99-1.96
				c3.39,0.12,4.92-2.27,6.54-4.71c3.97,0.81,5.17,4.63,7.68,6.85c2.86,2.53,5.5,5.43,5.61,10.02c0.06,2.3,1.64,4.92,5.32,4.7
				c3.59-0.21,3.97,2.84,4.29,5.56c0.23,1.94-1.12,3.88-0.06,5.88c3.84-6.2,2.11-11.53-4.5-13.63c-2.53-0.81-3.28-2.83-3.4-4.95
				c-0.19-3.34-0.76-6.08-4.22-7.72c-1.53-0.73-2.71-2.41-3.76-3.87c-3.29-4.57-5.78-7.36-11.68-1.88c-2.44,2.27-5.97,3.78-7.41,7.12
				c-7.46,1.18-7.85-8.03-13.8-8.44c0.3-6.3-0.94-7.88-6.88-9.09c-0.32-0.06-0.88-0.01-0.93-0.15c-1.94-5.51-6.55-4.87-11.12-5.72
				c-4.06-0.75-6.64-0.33-8.8,3.22c-3.64-2.72-6.47-4.08-8.48,1.57c-0.62,1.73-3.73,3.59-6.77,2.96c-5.81-1.21-7.8,0.07-9.36,5.89
				c-0.3,1.1-0.46,2.24-0.78,3.85c-10.03-4.22-20.08-7.55-30.25-10.54c-9.51-2.8-19.09-4.4-29.01-3.98
				c-9.52,0.4-18.64-0.94-27.85-4.05c-14.38-4.85-29.79-5.06-44.61-7.92c-11.12-2.15-22.07-5.77-32.26-11.06
				c-4.02-2.09-4.46-5.44-1.51-9c2.09-2.51,3.25-6.38,7.75-5.71c0.29,0.04,0.93-0.6,1-1c1.16-6.44,7.14-6.39,11.44-8.66
				c1.62-0.85,2.89-0.46,4.18,0.33c3.51,2.13,5.2,0.68,5.92-2.87c1.16-5.67,7.96-11.49,13.69-11.85c2.97-0.19,5.29,0.97,6.76,3.41
				c1.76,2.92,4.42,4.57,7.47,3.77c5.3-1.39,11.19,1.42,16.23-4.04c2.72-2.95,8.21-4.98,13.33-5.17c0.3,2.45-1.09,3.45-1.94,4.48
				c-3.85,4.67-0.31,8.91,1,12.94c1.05,3.22,4.86,2.79,7.9,2.7c4.65-0.13,5.56,1.23,3.44,5.28c-0.89,1.69-1.11,3.16,0.47,4.45
				c2.01,1.63,4.26,1.86,6.49,0.53c1.12-0.67,1.95-1.85,3.07-2.51c1.63-0.95,3.47-1.49,4.81,0.47c3.65,5.32,7.64,6.47,12.4,2.72
				c3.23-2.55,5-2.51,6.72,1.29c0.41,0.91,1.71,1.88,2.7,1.99c3.43,0.36,5.19,1.39,5.13,5.46c-0.04,2.45,3.06,3.32,5.48,3.25
				c5.61-0.18,10.86-1.42,14.41-6.11c2.39-0.03,3.35,1.75,4.69,2.89c3.83,3.28,7,3.53,10.4-0.21c2.44-2.69,4.35-2.37,6.79-0.57
				c2.08,1.52,4.18,1.82,6.45,0.28c2.81-1.9,5.14-1.96,6.71,1.72c1.06,2.48,3.05,2.82,5.34,1.39c0.96-0.6,1.84-1.53,3.16-1.05
				c1.04,7.18,1.05,7.13,8.68,7.96c2.12,0.23,4.21,0.8,6.3,1.25c2.27,0.49,2.77,1.99,2.49,4.12c-0.93,6.94-0.38,7.4,6.54,5.71
				c0.98-0.24,1.95-0.45,2.94,0.34c0.13,0.36,0.44,0.83,0.42,1.29c-0.1,2.65-0.72,5.52,2.7,6.7c2.48,0.86,5,0.28,6.17-1.9
				c1.42-2.63,5.43-4.89,2.38-8.7c-1.44-1.79-1.05-3.05,0.11-4.83c2.31-3.52,2.28-6.37-1.12-10.04c-2.61-2.81-4.37-7.18-7.75-8.82
				c-3.87-1.88-4.71-5.18-6.62-8.37c3.8-1.99,7.42-3.96,10.59,0.96c0.31,0.48,1.26,0.98,1.72,0.85c4-1.19,7.21,0.7,10.87,1.9
				c5.64,1.85,10.17,6.2,10.3,12.23c0.04,1.61,0.71,3.56,2.25,3.42c7.41-0.63,8.01,5.43,10.53,9.9c2.7,4.8,1.16,10.96,5.34,15.09
				c-5.43,5.51-5.74,7.99-1.49,15.14c1.06,1.78,0.81,3.22-0.13,4.61c-4.51,6.62-5.03,13.4-1.05,20.69c-0.95,1.87-3.31,3.04-3.8,5.49
				c4.77-0.7,6.91-4.34,4.97-8.25c-2.06-4.16-2.06-4.16-0.03-7.95c4.6,1.2,8.98,3.14,13.11,5.53c13.64,7.9,28.22,12.95,43.54,16.67
				c7.42,1.8,15.53-0.02,22.19,3.89c7.41,4.34,14.46,4.03,22.85,3.62c8.92-0.43,18.65-0.3,27.78,2.08c1.88,0.49,3.47-0.09,5.06-1.34
				c4.72-3.68,9.67-5.16,14.94-0.87c3.48,2.84,7.94,2.14,11.52,1.55c9.12-1.49,17.21,1.64,25.52,4.05c2.42,0.7,4.26,0.66,6.44-0.99
				c6.91-5.24,17.42-3.51,23.1,3.57c-1.11,1.51-2.74,0.82-4.1,0.82c-43.79,0.04-87.57,0.03-131.36,0.06c-2.37,0-4.52-0.52-6.75-1.33
				c-14.38-5.23-28.93-9.91-44.18-11.93c-3.31-0.44-6.64-0.38-9.96,0.12c-3.05,0.46-5.85,1.23-6.01,4.96
				c-7.35-0.63-14.13-1.99-20.67-4.48C625.02,1485.83,625.13,1485.7,625.24,1485.56z M603.11,1410.44c-3.51,2.85-7.67,5.53-1.31,9.34
				c0.75,0.45,1.16,1.47,0.54,2.63c-1.89,3.56-0.3,5.4,3.3,5.65c3.77,0.27,5.9,3.47,5.85,5.79c-0.12,5.53,3.14,6.18,7.4,6.59
				c-1.62,5.78,2.51,7.04,6.34,8.19c2.16,0.65,3.67-0.56,4.04-3.15c-5.11,1.77-7.09,0.56-7.97-4.62c-0.41-2.4-1.81-3.4-4.18-3.19
				c-1.59,0.14-3.25,0.04-3.33-1.99c-0.25-6.51-4.11-9.57-10.73-10.61c4.72-4.94-0.72-6.67-2.58-9.68c3.6-2.56,6.81-5.07,2.08-9.31
				c-0.9-0.8,0.27-1.98,0.99-2.96c2.9-3.93,6.43-3.36,10.3-1.35c-2.41-3.92-5.18-5.6-9.06-2.6
				C599.44,1403.31,599.18,1404.81,603.11,1410.44z"/>
			<path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M620.72,875.37c-5.55-0.74-8.4,2.03-10.08,6.56c-0.33,0.9-0.94,1.84-1.69,2.4
				c-7.08,5.34-8.76,12.4-7.27,20.83c1.63,9.22,1.54,18.6,1.16,27.91c-0.59,14.55,1.03,28.92,2.76,43.3c0.37,3.1,0.67,6.21-0.02,9.71
				c-4.59-1.53-8.97-2.61-13.47-2c-3.09-7.26-5.59-7.72-11.61-2.29c-0.24,0.22-0.56,0.73-0.67,0.68c-5.55-2.35-6.6,3.56-9.69,5.53
				c-2.97,1.9-2.66,6.17-4.29,9.18c-1.45,2.67-1.84,5.68-5.47,7.13c-3.88,1.56-4.88,10.9-2.23,15.45c0.67,1.15,1.58,2.15,2.29,3.27
				c0.77,1.23,0.75,2.55-0.02,3.77c-0.62,0.98-1.63,1.11-2.65,0.73c-1.53-0.55-1.7-1.82-1.67-3.25c0.17-6.67,0.08-13.33-0.85-19.95
				c-0.96-6.85-4.59-12.94-4.61-20.22c-0.02-12.48-0.76-24.93,0.26-37.42c0.49-5.96,0.42-11.99,0.22-17.97
				c-0.16-4.81-1.68-9.07-6.25-11.79c-3.41-2.03-3.91-5.64-4.92-9.29c-5.04-18.37-7-37.25-10.12-55.94
				c-1.61-9.64-0.44-19.29-0.24-28.91c0.19-8.97,0.75-17.96,1.01-26.95c0.14-4.72,0.36-9.55,6.52-10.81
				c1.41-0.29,1.88-1.72,2.21-3.08c0.39-1.63,0.87-3.43,2.48-4.16c5.4-2.48,4.94-7.2,3.96-11.5c-1.03-4.51-0.07-8.11,2.64-11.91
				c2.52,0.85,4.15,2.39,4.97,4.93c1.33,4.11,2.64,8.24,4.18,12.27c1.29,3.38,2.37,7.12,6.85,7.73c0.97,0.13,1.43,0.7,1.61,1.6
				c1.46,7.44,5.74,14.26,4.61,22.29c-0.63,4.49,0.1,8.69,1.98,13.07c2.17,5.05,0.59,10.87,1.3,16.31c0.49,3.76,0.91,7.57,5.88,9.65
				c1.15-6.76-1.97-13.48,0.93-18.88c7.5,9.46,6.3,20.98,6.07,32.34c-0.14,7.21,1.23,13.99,3.79,20.89
				c3.85,10.39,3.24,21.61,3.41,32.6c0.03,2.03-0.62,4.25,1.43,6.14c0.33-6.86,3.47-13.13,3.62-19.96
				c0.19-8.66-0.11-17.33,0.31-25.98c0.26-5.34-1.27-11.61,5.28-14.9c0.46-0.23,0.45-1.52,0.55-2.33c2.1-16.55,0.37-32.82-3.44-48.96
				c-1.1-4.65-2.48-9.33-0.68-14.22c2.12-0.72,3.4,1.73,5.82,0.84c1.71-9.39-3.99-18.16-4.03-27.7c4.62,0.46,5.3,3.67,6.36,6.65
				c1.56,4.39,2.01,9.19,8.7,9.96c2.77,0.31,3.38,5.27,4.14,8.81c2.25,10.47,5.32,20.77,8.26,31.87c2.72-2.9,1.67-5.47,1.63-7.74
				c-0.07-3.82-0.52-7.64-0.88-11.45c-0.26-2.81,0.06-5.31,2.78-6.92c1.87-1.1,2.02-2.72,1.59-4.74c-2.15-10.25-2.4-20.83-5.32-30.96
				c-0.84-2.92-0.92-5.66,0.19-8.74c1.36-3.78-0.83-7.42-1.99-11.03c-0.5-1.55-0.5-3.11,1.51-3.54c1.57-0.33,2.96,0.05,3.6,1.83
				c3.41,9.58,8.99,18.32,10.28,28.74c0.93,7.58,2.59,15.09,5.46,22.23c0.47,1.17,0.53,2.07-0.08,3.25
				c-3.09,6.06-5.93,12.26-9.13,18.26c-1.54,2.89-2.15,5.98-2.59,9.05c-1.32,9.22-5.4,17.73-6.56,26.98
				c-0.37,2.96-1.63,5.67-3.3,8.26C623.25,859.53,622.25,867.21,620.72,875.37z M551.37,774.73c-4.17,2.96-3.31,7.5-3.53,11.67
				c-0.76,14.64,2.92,29.05,2.18,43.69c-0.05,0.96,0.11,2.08,0.58,2.9c5.28,9.31,5.55,19.72,6.57,29.94
				c0.31,3.12,0.78,5.56,3.98,7.28c2.74,1.48,3.25,4.84,3.83,7.81c0.3,1.53-0.39,3.44,1.81,4.52c2.27-3.39,1.69-7.31,2.06-10.99
				c0.74-7.4-0.13-14.83,1.32-22.29c1.13-5.81,1.56-12.64-0.93-17.69c-2.51-5.09-2.21-9.77-2.51-14.77
				c-0.27-4.63,0.1-9.54-2.09-13.71c-4.02-7.66-7.6-15.46-10.27-23.68C553.83,777.73,553.59,775.79,551.37,774.73z"/>
			<path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M599.6,582.72c-2.54-5.07-5.62-9.32-12.18-9.15c-3.72,0.09-7-3.14-11.37-1.13
				c7.53,5.19,12.75,13.95,23.69,13.9c-4.49,2.96-9.37,4.13-14.21,4.36c-10.42,0.51-20,2.96-28.29,9.57
				c-0.87,0.7-2.05,1.01-3.33,1.62c-2.67-5-5.91-9.31-9.66-13.31c-2.5-2.66-4.61-3.68-7.42-0.67c-0.55,0.59-1.33,1.33-2.03,1.35
				c-5.27,0.13-7.25,3.99-9.34,7.85c-0.43,0.8-0.53,2.02-1.18,2.1c-4.89-11.92-8.24-24.18-11.09-36.58
				c-4.09-17.8-6.14-36.08-12.63-53.33c-3.75-9.96-7.36-19.97-11.89-29.61c-5.02-10.67-6.17-22.24-8.38-33.92
				c3.75-0.2,6.36,1.04,8.62,3.09c3.64,3.3,7.87,4.7,12.78,4.61c6.15-0.1,12.17,0.28,18.07,2.78c5.29,2.24,11.46,0.78,16.95,3.19
				c5.37,2.35,11.4,3.37,15.25,8.43c0.99,1.3,2.48,1.55,3.87,2.08c2.01,0.76,4.38,1.21,5.88,2.58c6.98,6.35,15.61,7.81,24.42,8.51
				c4.04,0.32,6.71,2.19,9.29,4.98c3.66,3.96,6.85,8.59,13.19,8.91c2.07,0.1,3.66,2.13,4.87,4.36c-3.18,1.4-2.81,3.77-1.79,6.42
				c0.97,2.52,2.07,5.08,4.1,6.77c7.7,6.41,11.96,15.44,17.99,23.1c3.47,4.4,2.9,9.76,4.03,14.71c0.44,1.92,0.51,3.93,0.12,6.25
				c-0.7-1.65-1.39-3.3-2.09-4.94c-0.72-1.68-1.12-3.62-2.22-4.99c-8.91-11.12-14.13-24.53-22.39-36.04
				c-0.68-0.94-1.33-2.01-2.24-2.66c-6.32-4.53-10.63-10.89-15.51-16.74c-1.39-1.66-2.79-3.65-4.97-4.01
				c-10.3-1.72-19.73-6.32-29.81-8.74c-0.37-0.09-0.86,0.34-1.57,0.65c1.31,3.84,4.6,5.55,7.54,7.52c8.98,6,18.58,11.04,27.11,17.77
				c11.2,8.84,20.78,19.41,24.28,33.13c2.19,8.58,6.2,16.4,7.99,24.87c1.05,4.98-1.33,10.6,2.88,15.21
				c-3.18,2.29-6.65,2.16-10.09,3.75c-1.02-2.24-1.97-4.29-2.88-6.37c-1.95-4.46-4.66-7.85-9.81-9.08c-4.26-1.02-6.74-4.74-9.41-7.9
				c-3.66-4.35-7.43-8.53-12.1-11.83c-0.54-0.38-1.24-0.74-1.5-1.29c-4.03-8.43-11.21-11.12-19.96-12c-3.21-0.32-4.7,0.25-4.68,3
				c0.04,6.24-2.68,11.52-4.82,17.1c-2.8,7.32,6.56,18.83,14.67,17.99c4.35-0.45,9.45,1.38,12.79-3.85c1.12-1.76,4.88-1.6,6.53-0.21
				c6.05,5.08,13.78,8.27,17.95,15.48c0.58,1.01,1.99,2.1,0.33,3.57c-2.45,0.37-2.62-2.62-4.43-3.24L599.6,582.72z"/>
			<path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M658.73,790.2c-2.1,0.42-3.03,3.66-6.15,2.68c1.99-2.86,5.32-4.35,6.42-7.48c2.78,0.39,4.34,1.82,4.96,3.7
				c2.71,8.13,9.25,13.02,15.53,17.78c10.07,7.64,17.32,17.41,23.41,28.2c3.61,6.39,7.62,12.47,11.87,18.46
				c6.31,8.89,10.07,19.13,13.67,29.3c3.83,10.83,8.5,21.34,12.22,32.16c3.47,10.09,8.01,19.58,13.2,29.46
				c-3.8,0.53-6.76,2.06-10.13,0.9c-0.69-0.24-1.79-0.12-2.39,0.29c-4,2.76-7.85,2.94-11.85-0.05c-1.41-1.05-2.96-0.13-4.14,0.76
				c-3.41,2.57-6.66,4.54-11.42,2.99c-1.81-0.59-4.16,1.15-6.88,0.53c-7.33-1.65-7.64-1.57-7.69-9.53c4.89,1.1,8.91-2.21,13.81-2.27
				c-0.3-7.59-1.76-14.57-3.12-21.56c-0.57-2.89-2.52-3.51-4.83-2.15c-4.03,2.37-6.11,0.08-8.14-2.91
				c-4.58-6.74-6.19-14.66-8.89-22.14c-1.58-4.38-3.11-8.78-4.4-13.25c-0.9-3.11-3.08-4.7-5.86-4.25c-3.3,0.53-2.18,3.74-1.95,5.77
				c0.58,5.11,1.56,10.19,2.5,15.25c0.87,4.69-2.14,9.73-6.84,10.73c-2.17,0.46-3.98,1.38-5.85,3.35c-0.99-2.57-1.79-4.53-2.49-6.53
				c-0.73-2.08-1.19-4.04-4.33-3.59c-2.7,0.39-4.71,1.41-4.74,4.1c-0.03,2.8-1.31,3.96-3.56,5.17c-3.83,2.05-5.9,5.74-7.6,9.61
				c-0.49,1.11-0.79,2.47-0.03,3.36c4.82,5.65,5.17,12.81,6.79,19.54c0.15,0.61,0.57,1.15,0.97,1.92c2.42-0.34,4.59-1.8,7.15-1.06
				c0.91,0.26,2.87-0.32,3.13-1c1.66-4.19,6.49-3.61,9.06-6.34c0.39-0.42,1.2-0.55,1.83-0.62c5.21-0.52,13.3,5.58,14.35,10.82
				c0.49,2.44,0.89,4.9,1.4,7.78c-4.1-0.84-6.52,2.88-9.12,3.62c-5.75,1.63-7.95,6.52-11.33,9.76c-4.49,4.3-8.69,6.39-14.7,7.75
				c-4.65,1.05-6.67,7.56-6.19,12.43c0.58,5.92,0.3,10.82-6.11,14.99c-0.1,0.03-1.46-0.45-2.38-1.32c-2.37-2.23-4.55-2.64-7.49-0.8
				c-2.16,1.35-4.19-0.16-5.6-1.96c-1.52-1.94-2.84-4.02-4.23-6.02c-6.35,3.6-11.83,0.21-12.38-6.83
				c-1.93-24.56-2.25-49.16-2.31-73.75c1.46,0.4,1.83,2.37,3.21,3.25c2.65,1.7,5.42,2.58,8.41,0.96c3.97-2.16,7.23-0.88,8.31,3.49
				c0.91,3.71,1.51,7.5,2.42,11.21c0.42,1.7,0.55,3.61,2.13,5.16c2.06-0.94,1.66-2.75,1.69-4.24c0.12-7,0.38-13.97,1.93-20.85
				c0.32-1.43,0.29-3.02,0.04-4.47c-0.98-5.64-3.91-6.78-8.43-3.43c-0.66,0.49-1.37,0.92-2.39,1.6c-2.25-3.35-5.56-4.89-9.43-5.06
				c-0.11-8.64,8.44-14.48,15.73-10.92c3.51,1.71,6.42,2.8,6.8-2.79c0.02-0.32,0.09-0.79,0.3-0.93c5.82-3.83,5.98-10.02,6.12-16.08
				c2.64,0.34,3.47,3.05,6.5,2.44c0.52-4.5,5.08-8.02,3.9-13.47c-0.17-0.8,0.05-1.87-0.4-2.41c-6.74-8.12,1.71-14.47,1.45-23.14
				c5.44,5.68,6.5,12.04,10.19,17.09c1.68-2.33,0.32-3.95-0.11-5.59c-1.45-5.46-4.1-10.5-5.43-16.05c-1.03-4.3,0.74-7.86,1.74-11.63
				c0.5-1.88,2.08-2.42,3.79-0.61c3.77,3.99,9.06,7.25,11.16,11.95c3.76,8.44,10.48,15.09,13.33,23.89c0.88,2.71,2.16,5.17,3.35,7.72
				c2.11,4.52,8.54,3.93,9.93,9.09c1.19,4.43,2.67,8.78,4.42,13.08c2.46-9.23,4.22-18.09-3.32-26.33
				c-4.75-5.19-8.2-11.53-13.57-16.29c-1.82-1.61-2.1-4.13-2.41-6.43c-1-7.25-3.69-13.26-10.53-17.04
				c-7.39-4.09-12.21-10.63-15.78-18.18C660.42,791.96,660.21,790.65,658.73,790.2z"/>
			<path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M676.96,450.4c10.69-2.47,20.75-4.04,30.81-2.43c10.77,1.72,21.53,1.55,32.32,1.45
				c11.82-0.11,23.6-0.27,35.16,3c4.48,1.27,9.33,1.51,13.89,0.68c14.45-2.63,28.45,0.47,42.49,2.84
				c11.35,1.92,22.57,3.47,34.18,1.84c8.8-1.23,17.42,1.9,26.12,3.03c29.21,3.78,55.72,16.09,83.14,25.53
				c5.13,1.77,10.27,2.56,15.68,1.57c6.7-1.22,13.28-0.3,19.67,1.83c6.72,2.24,13.38,2.32,20.04-0.11
				c9.68-3.53,19.35-4.05,29.31-0.89c8.4,2.67,16.47-0.13,24.38-2.68c6.99-2.26,13.91-3.28,20.97-0.53c0.31,0.12,0.59,0.3,1.11,0.57
				c-0.89,4.36-3.95,6.73-7.43,9.23c-11.14,7.99-23.41,14.69-32.17,25.59c-2.98,3.71-5.65,4.66-9.61,1.94
				c-3.81-2.62-7.71-2.84-11.55,0.06c-2.77,2.09-5.65,1.38-8.54,0.32c-2.03-0.74-4.04-1.53-6.1-2.17c-3.17-0.98-5.46-0.28-6.58,3.24
				c-1.05,3.29-3.2,3.51-6.57,2.79c-16.55-3.56-33.19-6.92-50.12-7.93c-15.15-0.9-29.45-5.21-43.65-9.85
				c-14.96-4.88-30.36-7.73-45.8-9.7c-30.7-3.93-56.85-18.81-83.34-32.95c-11.8-6.3-23.48-11.44-37.36-8.49
				c-8.34,1.77-16.92,0.87-25.4,1.18c-0.96,0.04-2.2,0.03-2.86-0.51c-5.29-4.24-10.5-2.3-15.35,0.46
				c-6.63,3.77-14.24,4.52-21.09,7.49c-1.42,0.62-2.57,0.37-3.53-0.85C685.23,460.93,681.27,455.89,676.96,450.4z"/>
			<path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M865.39,1349.49c2.55,4.66,5.89,5.82,10.63,4.16c5.21-1.82,8.35-6.79,7.61-12.05
				c-1.35-9.57-10.66-16.72-20.44-15.65c-1.47,0.16-2.93,0.44-4.26,0.64c-1.42-1.21-0.96-2.61-0.8-3.86
				c1.79-14.77,1.89-29.61,0.78-44.38c-1.11-14.85-3.29-29.63-5.05-44.43c-0.48-4.04-2.62-6.76-7.7-7.46
				c3.99-10.61,4.14-21.79,8.79-31.85c0.42-0.9,0.83-2.2,1.56-2.49c5.37-2.09,5.71-7.07,7.13-11.66c0.85,5.2,1.93,10.21,0.47,15.52
				c-2.58,9.45-4.86,18.98-7.05,28.53c-0.69,3-0.96,6.18,0.67,10.13c5.99-4.9,12.52-8.2,16.9-14.18c1.85-2.53,3.97-3.99,7.14-4.82
				c7.35-1.92,13.89-4.88,14.82-14.12c0.56-5.58,2.69-10.99,2.35-16.73c-0.07-1.2,0.23-2.27,0.9-3.29c2.27-3.48,2.99-7.47,2.84-11.46
				c-0.26-6.88,1.59-13.12,4.5-19.22c1.5-3.14,2.71-6.43,4.05-9.65c0.75-1.79,1.14-4.36,3.89-3.47c2.83,0.92,1.46,3.37,1.01,5.1
				c-1.17,4.5-2.51,8.96-3.99,13.37c-2.81,8.33-5.76,16.6-8.58,24.68c0.4,0.59,0.52,0.98,0.77,1.11c7.64,3.89,7.68,3.89,6.27,12.4
				c-1.11,6.72-2.36,13.42-3.52,20.14c-0.25,1.44-1.01,3.1,0.35,4.23c1.57,1.3,3.49,0.42,4.54-0.65c3.37-3.43,6.71-3.04,10.65-1.05
				c3.02,1.52,6.02-1.64,9.27-0.88c2.08,5.23,2.51,12.15,9.88,12.79c7.67,0.67,9.23-6.32,12.66-12.34c-0.5,7.6-3.84,13.18-5.6,19.21
				c-3.67,12.6-7.54,25.14-11.03,37.78c-0.81,2.93-2.22,4.67-4.85,5.74c-0.92,0.37-1.8,0.86-2.67,1.34
				c-10.81,5.97-11.28,7.98-10.18,20.29c2.13,24.02,5.68,47.94,5.54,72.14c-0.02,3.45,0.42,6.89,0.64,10.39
				c-0.52-0.05-0.99,0.04-1.13-0.13c-6.28-8.11-14.52-11.87-24.77-12.84c-9.51-0.91-18.32-4.6-25.92-10.73
				c-1.15-0.93-2.48-1.8-3.87-2.22c-7.23-2.22-9.52-7.64-9.82-15.06c4.07,0.71,7.99-8.13,11.72-0.18
				C875.16,1348.02,869.3,1348.22,865.39,1349.49z"/>
			<path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M628.84,800.81c-6.69-6.59-2.41-17.62-9.44-25.17c-4.94,1.78-6.36-1.6-7.54-5.86
				c-1.9-6.83-2.07-6.79-11.12-12.74c-2.11,10.34,4.44,19.33,3.84,29.59c-5.35-1.71-6.46,1.47-6.67,5.83
				c-0.18,3.71,0.48,7.3,1.31,10.86c3.58,15.28,5.96,30.65,3.66,46.39c-0.16,1.1,0.33,2.38-0.8,3.24c-4.91,3.68-5.05,9.12-5.13,14.45
				c-0.14,9.15-0.04,18.3-0.04,28.59c-1.87-4-1.6-7.24-1.88-10.33c-0.56-6.06-3.35-11.44-4.76-17.21
				c-2.01-8.21-0.59-16.59-0.64-24.89c-0.03-5.23-0.16-10.22-3.28-14.77c-2.28-3.31-1.37-7.75-3.51-11.89
				c-6.5,3.64-5.34,9.86-6.67,15.28c0-3.02-0.05-6.04,0.01-9.06c0.07-3.52,0.02-6.89-2.13-10.03c-1.68-2.46-1.25-5.58-0.83-8.29
				c1.45-9.27-3.56-17.11-5.28-25.66c-0.19-0.95-0.82-1.99-1.98-1.98c-4.47,0.06-5.22-3.52-6.36-6.55c-1.41-3.73-2.5-7.57-3.82-11.34
				c-1.33-3.8-3.84-7.87-7.82-6.85c-3.83,0.98-5.08,5.81-5.31,9.88c-0.12,2.1,0.82,4.24,0.98,6.39c0.2,2.67,0.13,5.34-2.76,6.88
				c-1.86,0.99-2.84,2.78-3.55,4.67c-0.47,1.24-0.72,2.41-2.31,2.93c-5.81,1.91-6.87,6.81-6.92,12.01
				c-0.11,12.59-0.04,25.18-0.94,37.81c-2.87-5.04-3.02-10.51-3.81-15.82c-2.3-15.3-3.29-30.77-6.1-46
				c-0.76-4.09-2.13-7.9-3.89-11.74c-3.26-7.1-3.2-15.11-2.48-22.58c1.5-15.63,4.19-31.14,6.46-46.69c0.21-1.46,0.74-2.92,1.37-4.26
				c1.77-3.75,3.67-7.43,8.97-4.36c4.09-20.79,0.24-41.31,1.56-61.74c-0.73,9.23-4.45,18.07-2.46,27.66
				c1.84,8.86-1.34,17.85-1.01,26.84c0.03,0.9-0.66,1.45-1.44,1.87c-9.44,5.12-10.43,14.32-11.27,23.54
				c-0.24,2.64-0.22,5.3-0.32,7.84c0-11.77-0.56-23.57,0.15-35.3c0.81-13.45,2.08-26.89,2.32-40.37c0.15-8.13,2.03-15.43,7.43-21.62
				c2.47-2.82,3.44-6.41,5.24-10.1c2.94,3.52,4.61,8.54,10.48,7.77c0.56-0.07,1.43,2.03,2.13,3.13c1.16,1.83,2.79,2.81,5.55,2.39
				c0,5.53-0.03,10.66,0.01,15.8c0.03,3.68,0.48,7.3,1.73,10.79c1.07,2.99,2.96,4.71,6.36,4.36c3.91-0.4,2.74-3.46,3.08-5.72
				c0.12-0.81,0.1-1.65,0.17-3.03c3.37,2.34,5.34,5.86,9.63,6.97c-4.47,4.29-6.33,9.1-6.61,14.86c-0.21,4.3-1.5,8.63-7,9.5
				c-0.66,0.1-1.2,1-2.06,1.76c2.47,2.09,2.86,4.67,3.04,7.55c0.81,13.13,1.76,26.27,0.53,39.38c-0.88,9.34,4.14,17.61,3.9,26.84
				c-0.17,6.44,0.25,13.04,2.09,19.29c0.93,3.18,0.86,8.09,6.96,6.38c1.26-0.35,3.19,1.12,3.3,3.56c0.17,3.46,0.34,7,1.7,10.29
				c0.95,2.3,2.35,4.1,5.2,3.71c2.96-0.41,2.79-2.8,3.06-4.94c0.61-4.69,1.38-9.06,3.64-13.6c2.5-5.03,0.69-11.45,0.07-17.23
				c-0.65-6.11-1.73-12.17-2.63-18.25c-0.7-4.73-0.29-8.96,2.69-13.21c3.14-4.48,6.13-9.02,9.86-13.26
				c5.47,2.49,11.39,3.75,12.84,11.03c0.59,2.95,2.76,6.02,6.07,6.84c5.72,1.4,9.71,4.87,13.16,9.93c-2.02,2.53-1.18,5.4,0.03,8.39
				c1.13,2.79,1.61,5.65,0.42,8.73c-0.91,2.35-0.51,4.94,0.21,7.38c2.96,9.94,3.1,20.38,5.32,30.45c0.29,1.3,0.47,2.73-0.78,3.7
				C628.73,794.23,628.41,797.79,628.84,800.81z M546.68,653.16c0.37-1.99-1.43-2.69-1.73-4.17c-0.67-3.25-3.07-5.91-6.06-5.47
				c-3.33,0.49-3.1,4.29-3.02,7.07c0.31,11.63,0.74,23.25,1.17,34.87c0.04,0.97,0.08,2.12,0.59,2.86c2.63,3.84,2.47,8.17,2.49,12.49
				c0.1,16.13-0.73,32.23-1.9,48.31c-0.27,3.75-0.04,7.53-0.04,12.22c2.03-2.04,1.91-3.56,1.99-5c0.64-10.61,1.47-21.22,1.84-31.85
				c0.35-9.8,1.56-19.85-0.14-29.34c-1.44-8.02-2.63-15.86-2.66-23.97c-0.01-4.15-0.54-8.29-0.68-12.43
				c-0.14-4.19-1.09-8.45,0.37-12.5C543.82,646.14,541.57,653.61,546.68,653.16z M549.2,654.37c-3.54,8-7.18,94.91-4.6,98.53
				c0.93-1.15,0.73-2.52,0.78-3.81c0.65-18.75,1.23-37.51,1.96-56.26c0.19-4.8,0-9.88,1.52-14.3
				C551.55,670.68,549.11,663.23,549.2,654.37z"/>
			<path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M893.15,1498.59c-13.64,0-26.44-0.01-39.24,0.02c-1.49,0-2.35-0.74-3.33-1.77
				c-8.48-8.77-16.71-10.05-27.33-4.94c-4.19,2.02-8.75,0.2-12.25-1.81c-4.16-2.38-8.15-2.55-12.4-1.88
				c-12.12,1.9-22.8-6.01-24.37-18.21c-0.5-3.88-3.06-6.3-6.35-7.75c-3.09-1.36-4.88-3.31-6.17-6.52c-2.86-7.11-7.19-9.71-14.82-9.17
				c-5.34,0.38-8.78-4.31-13.92-4.53c-1.79-0.08-3.76-0.87-5.46-1.09c-1.32-9.53-6.64-16.15-14.18-21.27
				c-3.82-2.59-7.58-5.18-9.48-9.68c-0.37-0.88-1.06-2.06-1.82-2.27c-5.12-1.4-7.24-5.72-10.13-9.51c-2.93-3.84-7.34-4.83-12.15-4.05
				c-6.6,1.07-7.71,0.19-8.74-6.51c-0.82-5.38-3.62-7.91-9.04-7.93c-7.34-0.02-14.23-1.72-20.57-5.44c-1.11-0.65-2.33-1.62-2.83-2.74
				c-2.59-5.78-7.02-7.29-13.01-6.77c-3.46,0.3-6.94-0.65-8.89-4.3c-0.51-0.95-1.58-2.01-2.56-2.21c-5.61-1.15-6.02-5.18-5.94-9.77
				c0.12-6.77,0.03-13.55,0.03-21.08c19.81,3.24,38.18,8.6,56.66,14.23c-0.94,2.61-2.94,3.67-4.37,5.19
				c-4.38,4.68-4.65,5.87-0.5,10.98c9.82,12.08,22.55,20.05,37.05,25.29c15.16,5.47,30.26,11.09,45.97,15
				c6.38,1.59,12.12,5.92,18.51,7.19c10.91,2.17,19.83,6.67,27.79,14.62c5.3,5.29,12.49,8.76,19.07,12.65
				c12.02,7.1,24.4,13.58,35.46,22.25c3.83,3,8.44,4.8,13.49,4.37c1.78-0.15,2.82,0.6,3.92,1.64c2.89,2.76,5.91,5.42,8.05,8.84
				c3.48,5.57,7.79,10.31,13.07,14.26C887.11,1487.47,890.21,1492.37,893.15,1498.59z"/>
			<path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M1.87,1170.17c3.41-1.06,6.33-1.59,9.17-0.04c4.99,2.73,7.59-0.72,10.46-3.81
				c2.73-2.94-0.65-4.64-1.06-6.94c-1.07-6.05-1.64-12.22-1.88-18.36c-0.09-2.27,1.83-3.3,4.35-2.24c4,1.68,6.23,0.74,7.54-3.95
				c1.44-5.19,4.74-9.19,10.48-10.99c4.38-1.38,4.95-4.22,2.64-8.21c-0.67-1.15-1.38-2.28-1.95-3.47c-1.49-3.17-1.48-5.67,2.68-6.76
				c4.78-1.26,8.63-3.59,8.7-9.37c0.01-1.04,0.59-2.31,1.5-2.41c4.61-0.49,6.75-5.16,11.07-6.22c4.6-1.13,3.79-5.64,4.76-9.51
				c2.66,1.91,4.55,3.68,4.02,6.95c-0.78,4.75,4.41,6.86,4.83,11.41c-4.51-1.47-7.78,1.91-11.61,3.19c-2.84,0.95-4.64,3.78-3.81,7.3
				c0.5,2.13,0.35,4.08-1.8,5.17c-2.75,1.39-5.36,2.38-4.01,6.61c1.5,4.7,3.03,8.9,8.76,9.41c1.3,0.12,2.62,0.02,4.21,0.02
				c-3.64,3.47-7.35,4.84-11.95,3.03c-1.32-0.52-3.12-0.65-4.36,0.31c-3.19,2.46-6.61,1.83-10.02,0.99
				c-2.75-0.68-5.36-0.56-6.09,2.48c-0.83,3.47,1.98,4.87,4.74,5.04c4.82,0.3,8.53,3.52,13.15,4.39c2.4,0.45,4.54,2.14,3.52,5.48
				c-0.47,1.52,0.44,2.93,1.28,4.17c1.25,1.84,1.4,3.59,0.26,5.57c-1.85,3.21-0.97,6.37,1.37,8.71c2.26,2.26,5.25,3.24,8.7,2.22
				c5-1.48,16.11-1.55,21.23-0.4c-3.77,8.32-4.51,8.8-13.48,7.85c-1.82-0.19-2.86,0.51-3.62,1.95c-0.73,1.39-1.79,2.77-3.24,2.92
				c-7,0.73-13.44,3.92-20.34,4.73c-5.9,0.69-13.02,4.88-14.75,9.92c-1.48,4.31,0.99,7.53,5.43,6.52c3.33-0.76,6.2-0.7,8.95,1.38
				c1.44,1.08,3.15,1,4.8,0.88c3.29-0.24,6.19,0.3,8.67,2.81c3.09,3.14,7.59,1.45,11.69,2.55c-1.66,4.55-4.56,4.9-7.97,3.37
				c-7.48-3.37-15.17-1.7-22.74-0.88c-3.93,0.43-4.15,2.57-1.65,5.95c3.64,4.92,7.01,10.04,10.5,15.08c0.09,0.14,0.15,0.34,0.28,0.41
				c6.14,3.38,9.27,10.99,17.37,11.82c4.78,0.49,6.65,4.22,7.16,8.6c-2.08,1.61-3.78,0.74-5.48,0.13c-2.25-0.8-4.18-0.12-4.99,1.99
				c-1.63,4.25-4.38,3.04-7.27,1.9c-7.21-2.84-13.44,0.17-15.58,7.54c-1.23,4.22,0.23,6.67,4.67,6.53c4.66-0.15,8.68,0.73,12.67,3.25
				c3.37,2.13,7.01,0.75,10.52-0.65c5.4-2.15,9.93,1.1,9.98,6.97c0.08,8.71,1.47,9.5,10.23,10.88c4.48,0.7,8.79,3.46,12.81,5.89
				c5.83,3.52,8.52,8.82,7.26,15.84c-0.51,2.85,1.04,5.23,2.61,7.35c2.21,3,2.28,6.51,2.99,9.89c0.36,1.69-0.38,3.09-1.6,3.93
				c-1.51,1.05-2.96,0.03-3.89-1.16c-2.79-3.6-6.54-3.51-10.37-2.76c-1.96,0.39-3.99,0.27-5.22-0.88
				c-8.29-7.69-19.26-8.05-29.26-11.02c-1.21-0.36-2.64-0.26-3.92-0.08c-4.58,0.63-10.13-1.9-12.6-5.89
				c-3.61-5.8-10.09-7.79-16.69-4.97c-6.2,2.64-12.11,6.01-19.22,5.9c-1.78-0.03-3.51,0.64-5.26-0.34c-3.31-1.85-6.41-0.63-9.89,1.29
				c-0.81-6.29-0.34-12.16-0.33-18.02c0-1.45,1.13-2,2.35-2.12c2.7-0.26,5.15-1.03,7.46-2.52c2.01-1.3,3.42,0.49,4.78,1.56
				c6.8,5.37,8.42,5.37,13.82-0.91c1.52-1.77,3.33-1.75,4.94-1.29c6.5,1.85,13.14-1.97,19.67,0.78c1.76,0.74,3.14-1.45,4.16-2.99
				c1.02-1.53,0.33-2.89-0.63-4.11c-2.07-2.64-4.68-4.2-8.17-3.89c-4.07,0.36-6.73-1.51-7.93-5.33c-1.11-3.56-3.42-6.03-6.54-7.84
				c-5.73-3.33-7.24-9.69-10.62-14.71c-0.18-0.26-0.35-0.69-0.26-0.94c2.67-7.39-3.43-8.17-7.72-9.63
				c-4.95-1.69-10.34-2.07-15.38-6.06c0-6.76-0.73-14.65,0.29-22.31c0.56-4.17,4.13-7.86,5.54-12.27c1.33-4.16,0.94-8.74-2.92-10.69
				c-2.65-1.34-3.01-3.03-2.97-5.5C1.93,1182.49,1.87,1176.5,1.87,1170.17z M24.87,1221c1.1-0.29,1.89,1.62,2.65,0.91
				c-2.65-2.87-5.31-5.75-7.86-8.52c0.53,3.19,3.56,5.05,5.32,7.71c-3.08-0.34-4.04-3.98-7.21-4.76c0.71,2.66,2.73,3.92,4.24,5.53
				C23.44,1223.38,24.22,1222.3,24.87,1221z M34.45,1165.95c-2.65,1.77-0.6,3.73-1.18,5.32c-1.32,0.26-1.01-2.08-2.75-1.46
				c-0.03,2.47,1.22,4.26,3.11,6.08C37.95,1172.66,34,1169.43,34.45,1165.95z M16.77,1222.55c-0.22,0.39-0.44,0.77-0.65,1.16
				c2.17,1.12,3.54,3.67,8.21,3.2C21,1224.99,18.88,1223.77,16.77,1222.55z"/>
			<path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M249.65,1252.38c3.38,3.15,2.01,6.74,1.68,10.08c-0.71,7.31-1.55,14.49-8.56,19.17
				c-2.5,1.67-4.22,4.49-3.1,8.28c2.63-7.26,12.13-9.14,13.1-17.71c0.23-2.03,6.68-5.07,8.6-4.13c2.96,1.44,1.38,3.71,1.09,5.99
				c-0.57,4.48-1.52,8.73-3.15,12.96c-0.98,2.53-0.6,5.49,0.52,8.28c3.93,9.79-3.21,23.27-13.53,25.32c-4.57,0.91-6.57,4.39-9.48,7.3
				c0.65,0.33,1.08,0.66,1.18,0.58c3.81-3.16,7.89-5.43,12.86-6.74c5.15-1.35,7.68-6.39,10.36-11.42c3.79,4.56,10.49,6.14,9.31,13.46
				c-0.82,5.05-4.92,7.77-8.2,10.83c-2.86,2.66-5.25,5.45-7.14,8.83c-2.83,5.07-6.61,5.71-10.93,1.75c-1.94-1.78-3.61-3.94-5.05-6.16
				c-2.28-3.5-5.19-3.81-8.63-2.23c-1.96,0.9-4.01,1.75-5.71,3.03c-5.82,4.37-11.78,4.91-18.13,1.28c-0.82-0.47-1.84-0.6-2.79-0.89
				c0.01-1.97,1.4-2.83,2.77-3.17c4.46-1.11,6.47-5.05,9.4-7.87c2.37-2.27,0.76-4.39-1.14-5.88c-2.38-1.86-5.4-3.04-8.26-3.18
				c-3.9-0.19-8.2-0.12-11.72,1.33c-8.13,3.35-15.96,0.46-23.9,0.02c-1.66-0.09-3.09-1.07-3.92-2.74c-2.16-4.38-6.01-5.78-10.44-4.97
				c-7.81,1.42-13.31-4.17-19.85-6.41c-4.47-1.53-2.48-9.45,3.1-12.85c1.49-0.91,2.96-1.91,4.94-0.36c2.69,2.11,4.4,0.26,5.21-2.2
				c0.74-2.24,1.79-3.83,4.21-4.25c1.4,0.68,1.77,1.99,1.97,3.29c0.99,6.39,5.25,10.2,10.57,12.91c2.38,1.21,3.27,2.49,3.47,5.22
				c0.42,5.61,6.22,9.25,11.74,7.77c3.25-0.87,2.85-3.54,1.25-4.96c-4.27-3.8-3.93-8.88-4.73-13.69c-0.19-1.13-0.14-2.71-0.85-3.28
				c-8.1-6.55-9.81-17.34-16.7-24.79c-3.23-3.5-5.35-8.16-8.14-12.21c-2.24-3.25-1.82-6.94,2.4-9.59c2.45,4.19,3.38,8.78,5.14,13.02
				c1.82,4.36,3.74,8.64,9.14,9.63c4.08,0.75,6.03-0.69,6.03-4.88c0-3.58,0-6.71,3.75-9c2.59-1.58,1.43-4.94,0.95-7.44
				c-0.46-2.43-0.61-4.84,0.78-6.73c4.17-5.66,4.16-12.11,3.87-18.59c-0.22-4.94,0.15-10.22-0.76-14.72
				c-1.48-7.28-2.28-13.98,0.33-21c0.41-1.11-0.14-2.58-0.25-3.92c5.77,0.73,8.48-3.57,6.68-10.29c-2.56-9.54-2.53-9.77,5.27-15.68
				c2.86-2.17,5.29-4.53,5.6-8.52c5.01-0.16,5.9,3.22,6.46,6.7c0.54,3.3,0.2,7.12,5.65,5.27c1.46-0.5,2.15,1.08,2.49,2.5
				c0.59,2.43,1.9,4.6,2.78,6.9c1.69,4.43,2.35,9.21,5.02,13.32c0.82,1.26-0.06,2.88-0.32,4.36c-1.7,9.58-2.83,19.31-8.47,27.68
				c-0.43,0.63-0.47,1.6,0.13,2.35c3.8-2.59,6.51-8.64,10.51-23.8c2.53,7.33,5.62,13.65,6.23,20.77c0.04,0.5-0.05,1.09,0.19,1.47
				c3.34,5.41,4.41,10.96,1.96,17.12c-0.65,1.64,0.85,2.71,2.76,2.33c2.96-0.59,5.78-1.44,8.1-3.54c1.3-1.18,2.35-2.86,4.4-2.9
				c3.06,4.96,2.68,12.73-0.29,17.12c-2.97,4.39-5.99,8.73-8.67,13.35c-3.17,5.46-6.91,10.79-12.99,13.68
				c-2.24,1.06-3.85,2.96-4.38,5.2c-1,4.25-3.36,6.94-7.28,8.74c-1.55,0.71-3.46,1.89-3.63,5.41c4.78-4.46,11.64-5.04,12.56-12.44
				c0.31-2.48,2.12-4.53,4.76-5.34c2.53-0.77,3.81-2.97,5.66-4.53C241.87,1266.62,244.13,1258.35,249.65,1252.38z M202.71,1226.05
				c1.67,0.81,2.84,0.82,3.59-0.33c1.03-1.58-0.36-2.48-1.3-3.29c-2.22-1.92-2.18-4.35-2.64-7.04c-1-5.86-0.34-11.05,2.82-16.01
				c0.33-0.52,0.54-1.37,0.36-1.91c-2.22-6.3,1.09-9.81,5.92-13.08c3.7-2.51,3.09-5.09-1.08-6.84c-3.55-1.49-16.72,7.72-15.57,11.3
				c2.25,6.98-1.66,12.72-3.31,18.7c-1.34,4.87-2.53,9.1-0.41,13.85C193.59,1227,197.59,1228.55,202.71,1226.05z M205.35,1233.09
				c-4.44,9.24-4.49,18.1-3.02,27.16c0.3,1.83,0.23,3.7,2.44,4.41c2.13,0.69,3.7-0.13,5.44-1.43c5.59-4.16,8.7-10.01,12.01-15.91
				c2.25-4.01,3.71-8.36,6.12-12.33c2.1-3.46,2.65-7.91,2.52-12.1c-6.69,5.92-10.09,13.56-14.61,20.46
				c-1.33,2.03-3.49,2.89-5.79,3.26C207.6,1242.73,210.71,1236.83,205.35,1233.09z"/>
			<path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M210.5,896.04c2.23,7.46,3.95,14.99,3.49,23.97c-2.97-4.51-6.85-3.07-10.25-3.21
				c-3.53-0.14-4.57,2.86-5.39,5.61c-1.75,5.87-0.82,11.92-0.9,17.89c-0.04,2.96-1.07,5.13-2.72,7.63
				c-5.71,8.68-6.37,18.76-5.87,28.71c0.39,7.7,0.92,15.13-1.92,22.59c-1.64,4.31-1.8,9.1-1.08,13.81c0.4,2.6,0.42,5.26,0.62,7.96
				c-1.41-0.15-1.58-1.03-1.81-1.8c-1.97-6.71-4.38-13.06-9.24-18.49c-4.23-4.74-4.86-11.05-3.45-17.05
				c1.54-6.58,2.3-12.93,1.06-19.7c-0.76-4.17-0.18-8.64,0.19-12.94c0.49-5.65-0.69-10.91-2.78-16.07c-1.26-3.1-3.02-5.14-6.82-5.56
				c-5.04-0.57-6.76-3.57-4.21-8.15c6.14-11.02,6.37-22.64,4.66-34.6c-0.16-1.08-0.04-1.99,0.51-2.83c2.42-3.7,1.54-7.24,0.65-11.35
				c-0.9-4.15,0.18-8.84,0.87-13.21c0.72-4.55,1-8.89-1.31-13.1c-0.62-1.12-1.65-2.57-0.75-3.74c2.99-3.91,2.07-8.96,4.14-13.14
				c2.55-5.13-0.67-9.09-3.59-12.6c-3.11-3.74-4.71-7.2-3.06-12.22c0.79-2.42,1.41-3.74,3.96-4.09c2.6-0.36,3.34-2.64,3.8-4.73
				c0.47-2.12-0.44-3.96-2.38-5.05c-3.77-2.12-6.12-4.89-5.5-9.61c0.29-2.18-0.97-4.06-3.21-4.42c-2.35-0.38-4.49,0.78-4.95,3.18
				c-0.88,4.63-4.86,9.01-1.18,14.13c0.99,1.38-0.53,2.72-1.12,4.07c-2.74,6.35-5.42,12.75,0.6,18.88c1.9,1.93,0.7,3.93-0.61,5.35
				c-6.59,7.14-3.85,15.18-2.3,23.04c0.39,1.96,1.28,3.89,0.58,5.82c-1.32,3.66-0.85,7.22,0.23,10.77c0.6,1.98,0.65,3.87-0.17,5.82
				c-1.2,2.87-0.99,5.85-0.53,8.85c0.84,5.49-1.27,9.38-6.36,11.02c-3.22,1.03-3.24,3.32-3.18,5.91c0.14,7.19-0.88,7.83-7.37,10.98
				c-3.98,1.94-7.11,5.59-10.91,8.19c-4.18,2.86-4.37,8.04-4.33,12.74c0.03,3.62-1.72,6.72-2.47,10.09
				c-0.72,3.21-4.38,2.05-6.43,1.36c-5.21-1.75-8.06-0.64-8.27,5.17c-0.01,0.16,0.01,0.41-0.09,0.48c-4.55,3.52-7.87,9.47-13.7,10.25
				c-6.02,0.81-9.29,3.73-11.78,8.56c-3.21,6.24-4.01,6.66-10.45,4.13c-3.57-1.41-6.29-1.61-8.19,2.5
				c-1.94,4.18-6.87,3.52-10.22,5.54c-1.68,1.01-2.65-1.58-2.29-3.29c0.74-3.52,0.8-6.91-0.6-10.27c-0.17-0.42-0.23-1.23,0.01-1.43
				c5.11-4.17,5.69-12.7,13.54-14.16c1.42-0.26,2.76-0.74,3.31-1.94c2.31-5.1,6.2-9,10.11-12.69c3.77-3.55,6.86-8.2,13.08-8.69
				c6.53-0.52,12.18-9.48,11.8-17.54c-0.35-7.41,0.82-8.57,8.06-7.01c2.76,0.6,4.05-0.48,5.2-2.51c2.33-4.12,5.7-6.79,10.47-7.47
				c2.92-0.42,3.63-2.83,4.06-5.04c0.65-3.34,2.55-5.1,5.59-6.31c10.34-4.12,17.78-18.63,15.08-29.44
				c-1.26-5.04-1.17-9.72,1.24-14.51c3.4-6.76,2.29-14.35,2.98-21.55c0.48-5.03-1.3-10.18-1.15-15.37c0.04-1.53-0.56-3.24-1.78-4
				c-6.15-3.84-7.13-10.69-10-16.46c-1.76-3.53-1.51-7.09-0.61-10.71c1.07-4.33,0.81-8.56-0.54-12.79
				c-1.8-5.59-1.15-13.96,1.46-20.08c5.44,3.38,10.21,7,14.51,12.29c4.72,5.81,8.21,11.73,11.68,18.25
				c5.87,11.05,9.34,23.3,16.93,33.5c1.84,2.47,1.6,6,0.65,9.2c-1.3,4.37-1.44,8.26,1.21,12.76c2.09,3.55,0.82,8.66,0.12,13.04
				c-0.24,1.49-0.8,2.94-0.07,4.37c3.01,5.91,3.45,12.8,7.37,18.41c2.24,3.21,2.66,6.85,1.28,10.92c-2.06,6.11,0.91,16.45,5.41,20.45
				c-1.38,3.63-2.14,7.34-0.12,11.29c1.68,3.29,3.7,4.22,7.21,3.23c3.37-0.95,6.71-2.49,10.39-1.73
				C210.61,896.14,210.5,896.04,210.5,896.04z"/>
			<path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M990.42,688.61c2.93-0.29,2.86-2.68,3.41-4.73c3.56-13.22,4.43-26.9,6.96-40.29
				c2.32-12.28,2.94-24.76,4.92-37.07c0.32-1.99,0.22-4.25,1.53-5.69c4.96-5.45,5.56-13.17,10.06-18.82c0.87-1.1,0.64-2.52,0.7-3.86
				c0.25-5.68,2.7-6.24,7.98-7.59c7.6-1.95,14.1-1.51,20.47,2.79c0.12,0.08,0.36,0.12,0.49,0.05c7.79-3.98,12.83,2.28,18.88,4.95
				c2.43,1.07,3.55,2.99,4.37,5.14c1.36,3.56,2.53,7.21,3.63,10.86c1.84,6.16,3.81,7.61,10.06,6.98c3.37-0.34,6.73-0.11,9.7-2.75
				c2.06-1.83,4.44-0.49,5.73,1.91c1.5,2.8,2.65,5.72,2.17,8.99c-1.09,0.76-1.57-0.16-2.03-0.7c-3.11-3.64-6.37-3.35-9.3-0.02
				c-2.09,2.37-3.97,2.81-6.94,1.58c-4.07-1.68-7.55,0.44-10.35,3.2c-3.39,3.35-6,2.68-8.52-0.85c-0.39-0.54-0.86-1.02-1.3-1.51
				c-5.47-6.14-7.9-6.14-12.67,0.69c-1.89,2.71-4.1,4.19-7.01,3.25c-4.05-1.3-8.47-1.33-12.19-3.97c-2.98-2.12-7.09-0.62-8.7,2.82
				c-1.35,2.89-2.13,6.05-2.08,9.19c0.26,18.23-2.74,36.1-5.4,54.03c-0.56,3.79-1.08,7.69,0.13,11.72c2.37-1.16,1.58-3.06,1.77-4.55
				c2.71-21.27,6.08-42.46,6.73-63.97c0.23-7.7,1.66-7.94,8.83-4.88c3.6,1.54,7.6,2.2,11.49,2.96c3.88,0.75,6.46-1.02,8.58-4.43
				c3.65-5.9,4.61-5.84,8.95-0.79c5.74,6.67,6.53,6.76,13.79,1.53c2.51-1.81,5.06-3.34,8.24-1.58c2.99,1.66,5.29,0.84,7.42-1.7
				c1.31-1.57,2.86-3.99,5.3-2.54c2.8,1.66,5.75,3.69,5.27,7.73c-0.67,5.62-1.5,11.23-3.19,16.64c-2.89,9.23-4.72,18.54-5.53,28.28
				c-0.94,11.37-3.66,22.59-5.66,34.15c-2.56-2.44-0.93-7.32-4.6-8.37c-3.84-1.1-5.11,3.7-8.39,4.87c-2.42-2-3.93-6.83-8.54-3.31
				c-0.28,0.22-1.43-0.58-2.1-1.02c-5.71-3.66-10.01-3.17-14.29,2.07c-1.71,2.09-3.18,2.67-6.11,2.66
				c-8.86-0.02-16.08,3.83-20.52,11.86c-2.82,5.11-6.59,9.03-12.42,11.22c-0.88-3.95,0.76-7.18,1.31-10.54
				c0.4-2.47,1.01-5.07-1.13-7.04c-2.07-1.91-4.13,0.62-5.89,0.42c-5.31-0.6-8.03,3.73-12.52,6.25c0.44-6.09-2.3-10.99-1.38-16.33
				L990.42,688.61z M1008.02,612.12c1.77,1.11,2.3,1.73,2.84,1.73c6.89,0.09,7.18,4.63,6.68,9.76c-0.26,2.64-0.29,5.31-0.73,7.92
				c-2.83,16.52-8,32.57-9.83,49.29c-0.34,3.14-0.74,6.25,0.25,9.36c0.81-0.5,1.28-1.03,1.33-1.59c1.66-17.58,6-34.64,9.97-51.78
				c1.43-6.18,1.93-12.47,1.29-18.81c-0.4-3.92-1.14-7.44-6.57-5.93C1012.06,612.4,1010.69,612.12,1008.02,612.12z"/>
			<path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M757.51,489.3c-1.99-10.36-10.45-15.8-16.15-22.74c-2.45-0.32-2.68,1.93-4.45,2.33
				c-1.47-2.2-2.89-4.33-4.73-7.1c11.2,1.1,21.42,0.27,31.62-1.26c9.34-1.39,17.95,1.89,25.62,6.38c19,11.13,38.91,20.26,59.11,28.85
				c10.37,4.41,21.64,5.21,32.58,6.96c20.75,3.31,40.96,8.67,60.97,14.81c11.95,3.67,24.32,3.28,36.47,4.98
				c11.02,1.54,22.02,3.31,33,5.13c2.24,0.37,4.62,0.69,7.48,2.73c-9.64,1.6-13.47,9.79-19.81,14.91c-4.85,3.92-10.78,5.86-15.9,9.24
				c-1.51,1-3.17,0.47-4.83,0.13c-6.81-1.39-13.54-1.66-19.78,2.16c-2.12,1.3-4.23,0.29-6.3,0.01c-13.87-1.89-27.06-6.95-40.87-8.97
				c-9.85-1.43-19.63-3.61-29.66-3.59c-0.83,0-1.75,0.1-2.48-0.2c-11.99-4.97-25.54-4.95-37.01-11.44
				c-8.47-4.79-17.98-4.4-27.09-5.92c-3.41-0.57-6.63,1.91-9.9,3.09c-7.56,2.71-12.6,0.66-15.61-6.78
				c-2.62-6.46-5.52-12.88-7.27-19.59c-2.19-8.37-8.53-12.33-15.03-16.39C763.84,484.77,761.55,489.06,757.51,489.3z"/>
			<path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M75.36,1070.93c1.75,1.93,1.45,4.22,1.76,6.27c0.44,2.88,1.54,5.02,4.44,6.04
				c0.91,0.32,2.12,0.83,2.44,1.58c2.61,6.12,8.3,10.07,11.24,15.79c1.8,3.5,2.5,7.48,6.82,8.91c-1.02,3.99-6.12,2.28-7.17,5.92
				c0.68,2.5,3.16,2.97,5.08,4.14c2.86,1.74,3.31,5.38,1,7.43c-5.04,4.47-4.82,6.69,1.03,10.15c0.7,0.41,1.39,0.84,1.37,0.83
				c3.07,8.34-4.97,11.04-7.27,16.59c-1.92,4.64-2.35,7.63,2.46,8.62c4.93,1.02,8.75,3.33,12.36,6.47c-2.8,1.89-5.75,3.98-8.8,5.92
				c-2.28,1.46-2.23,3.47-1.74,5.71c0.49,2.22,1.9,3.56,4.09,3.78c5.59,0.56,10.06,3.05,13.79,7.21c2.61,2.91,7.56,1.5,10.22,5.29
				c-2.68,2.37-4.64,5.48-8.86,5.43c-1.99-0.02-3.12,1.75-4.45,2.98c-2.6,2.41-1.62,4.74,1.24,4.96c6.16,0.48,10.29,4.06,14.78,8.16
				c-2.27,4-0.82,9.16-3.62,13.36c-0.57,0.85-0.67,2.38-0.35,3.4c1.75,5.72-1.71,10.27-4.01,14.59c-2.19,4.12-1.75,6.85,1.09,9.73
				c2.27,2.31,3.18,4.98,3.1,8.12c-0.07,2.7-1,4.92-3.65,5.99c-1.45,0.59-2.45,1.47-3.38,2.75c-7.68,10.54-7.63,10.47-0.81,22.21
				c2.28,3.92,3.92,8.19,1.32,12.73c-0.56,0.97-0.83,2.08,0.12,2.69c4.2,2.69,3.78,7.53,5.54,11.35c1.92,4.18-0.06,5.36-3.72,5.58
				c-3.14-8.3-6.48-16.04-6.8-25.15c-0.16-4.52-6.04-8.73-11.09-10.27c-4.59-1.4-8.28-4.93-13.74-4.63c-3.6,0.2-4.4-3.77-4.46-7.2
				c-0.15-9.22-5.7-12.98-14.41-9.76c-3.15,1.17-6.25,1.62-9.02-0.47c-3.14-2.36-6.51-3.09-10.31-2.69
				c-4.08,0.43-5.03-1.29-3.58-5.06c1.74-4.52,6.53-6.7,10.93-4.65c6.88,3.19,8.49,2.75,11.47-3.14c0.3-0.13,0.62-0.4,0.9-0.36
				c3.47,0.54,7.4,2.75,9.61-1.95c1.37-2.92-3.46-11.03-7.47-11.08c-7.78-0.08-11.31-6.6-15.97-10.41c-3.1-2.54-7.63-6.21-8.7-11.59
				c-0.44-2.18-2.78-3.98-4.5-6.28c2.81-1.16,5.27-1.91,8.17-1.09c1.54,0.43,3.44,0.55,4.9,0.04c3.16-1.1,5.77-0.27,8.56,1.13
				c3.51,1.76,7.04,1.97,10.11-1.14c1.3-1.32,2.75-2.62,2.07-4.72c-0.64-1.99-2.4-2.81-4.24-3.02c-9.08-1-17.46-4.67-26.26-6.74
				c-1.49-0.35-2.91-0.56-4.42-0.22c-1.88,0.41-4.48,1.7-5.23-0.8c-0.75-2.51,0.48-5.22,2.91-6.78c1.66-1.07,3.49-2.42,5.34-2.57
				c6.36-0.54,12.06-3.71,18.27-4.42c5.04-0.58,9.56-1.37,12.18-6.11c9.14,1.4,13.6-1.02,17.53-9.22c1.03-2.15,0.03-3.28-1.75-3.77
				c-2.53-0.71-5.41-1.99-7.7-1.38c-4.91,1.31-9.96-0.04-14.77,1.55c-2.52,0.83-5.08,0.39-6.94-1.57c-1.81-1.91-1.63-4.21-0.37-6.47
				c1.15-2.07,1.03-4.14-0.19-6.16c-0.17-0.28-0.49-0.58-0.48-0.86c0.36-12.83-11.03-11.27-18.2-14.82
				c-1.39-0.69-3.33-0.24-4.61-2.88c12.98,0.71,25.23-0.93,37.44-4.66c-1.38-3.84-4.29-4.7-6.98-4.57
				c-6.7,0.32-10.51-2.27-10.68-9.99c4.98-1.47,7.28-5.01,5.92-11.04c-0.56-2.49,7.16-6.1,11.53-5.81c2.24,0.15,4.55,1.06,6.76-0.48
				c0.1-2.7-2.89-3.05-3.66-5.01c-1.46-3.72-5.07-6.55-4.07-11.32c0.44-2.08-2.12-3.21-3.13-4.89
				C72.1,1074.44,71.82,1072.27,75.36,1070.93z"/>
			<path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M837.88,974.03c-3.81-4.1-10-1.89-12.23-7.55c-0.17-0.44-0.59-1.02-0.98-1.09
				c-5.54-0.96-9.89-4.76-15.18-6.31c-0.15-0.04-0.38,0.07-0.49,0c-5.42-3.64-11.48-6.15-17.34-8.83c-4.92-2.26-7.45-6.97-12.4-9.43
				c-3.99-1.98-9.29-2.88-12.42-7.72c-2.05-3.17-7.12-4.47-11.47-3.44c-4.03,0.94-6.5-0.1-7.38-4.17
				c-4.01-18.46-15.3-34.16-19.63-52.62c-2.05-8.77-6.42-17.09-12.35-24.3c-6.23-7.57-9.67-16.86-16.19-25.84
				c7.99,4.08,14.37,8.41,22.33,8.69c2.01,0.07,2.6,2.38,3.75,3.75c2.63,3.14,5.46,4.92,9.99,5.48c4.82,0.59,9.02,4.22,13.01,7.32
				c1.03,0.8,1.66,2.08,2.58,3.03c4.17,4.31,5.58,4.21,9.13-0.53c0.93-0.31,1.59,0.16,2.22,0.74c3.72,3.42,7.26,6.22,12.74,7.7
				c4.16,1.13,9.63,4.32,12.47,9.18c0.5,0.86,1.13,1.65,1.79,2.4c4.87,5.53,8.71,5.29,13.07-1.25c1.49,1.67,2.8,3.39,4.36,4.82
				c4.14,3.81,5.68,3.27,7.06-2.4c1.92,0.51,1.87,2.5,2.79,3.71c2.65,3.48,5.16,7.11,10.36,6.37c2.09-0.3,2.26,1.64,3.16,2.66
				c3.69,4.23,5.49,4.41,9.49,0.97c5.33,2.24,8.94,8.04,15.83,7.21c-3.09,0.59-6.25,0.96-9.27,1.83c-6.57,1.88-9.31,5.74-7.77,12.28
				c0.98,4.15-0.48,6.22-3.56,8.19c-2.23,1.43-4.41,2.99-6.42,4.73c-5.5,4.77-5.56,9.3-0.27,14.34c3.66,3.48,8.37,5.39,12.5,8.18
				c2.82,1.9,7.26,3.26,6.87,7.08c-0.4,3.97-5.08,4.15-8.3,5.27c-6.87,2.39-8.63,8.37-4.37,14.67
				C836.13,970.3,837.74,970.93,837.88,974.03z"/>
			<path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M1159.45,70.52c0.7,1.79,1.99,3.2,1.97,5.43c-0.14,17.64-0.09,35.28-0.11,52.92
				c0,0.64-0.19,1.28-0.34,2.28c-3.81,0.41-7.6,0.62-11.31,1.28c-3.27,0.58-7.97-0.67-8,5.42c-15.7,1.37-31.31,0.52-46.41-0.86
				c-1.01-2.31,1.33-2.75,0.33-4.15c-0.22-0.15-0.48-0.48-0.75-0.48c-21.6-0.11-43.2-1.82-64.8-0.2c-4.84,0.36-9.54,1.22-13.75,3.9
				c-0.94,0.6-2.16,1.11-3.23,1.08c-15.12-0.37-30.23-0.83-45.35-1.32c-1.12-0.04-2.23-0.47-4.06-0.88
				c1.56-7.23,6.95-10.93,12.13-14.59c6.04-4.26,12.7-2.06,18.99-0.51c2.42-8.15,4.79-9.86,13.25-10.09
				c3.82-0.1,7.88,1.16,10.54-3.47c0.81-1.42,3.88-1.43,5.78-2.36c3.56-1.74,7.83-1.76,10.23-6.27c1.31-2.48,5.53-0.58,8.33-0.44
				c7.51-25.71,31.76-29.69,52.4-8.58c2.17-2.68,4.57-5.08,8.54-4.09c0.57,0.14,1.47-0.59,2.06-1.11c5.79-5,6.28-5.05,12.9-1.17
				c0.67,0.39,1.49,0.54,2.12,0.76c0.45-0.39,0.82-0.55,0.93-0.82c6.6-15.54,18.97-20.08,34.49-12.66c1.01,0.48,1.9,1.44,3.21,1.06
				C1159.55,70.61,1159.45,70.52,1159.45,70.52z"/>
			<path onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M990.51,688.5c-8.4-3.96-8.62-10.28-6.33-18.35c3.09-10.88,4.18-22.21,5-33.51
				c0.19-2.69,0.92-5.58-3.28-5.71c-0.28-0.01-0.77-0.29-0.81-0.5c-1.13-6.93-9.21-9.19-10.6-16.18c-0.68-3.45-3.05-6.58-4.78-9.8
				c-0.76-1.42-2.52-2.57-3.72-1.87c-4.08,2.39-9.17,1.41-13.02,4.17c-3.46,2.48-4.64,6.36-5.84,10.19c-1.23,3.91-2.31,7.9-4.6,11.87
				c-2.11-2.51-2.15-5.17-2.72-7.68c-0.43-1.91-0.59-4.22-2.8-4.95c-2.45-0.81-3.83,1.2-5.53,2.56c-8.67,6.93-8.88,16.94-9.88,26.66
				c-0.45,4.38-0.56,8.82-1.9,13.12c-5.8-2.18-6.92-5.45-4.86-10.93c4.87-12.96,7.87-26.42,9.17-40.24c0.39-4.12,2.27-7.47,6.05-9.79
				c3.87-2.38,7.09-5.57,8.51-10.09c1.19-3.78,4.96-5.2,7.17-8.12c4.05-5.38,6.22-11.93,11.79-16.46c5.11-4.15,10.39-5.76,16.57-4.81
				c6.41,0.99,12.72,2.3,19.26,0.66c2.88-0.72,5.22,2.17,8.01,2.28c6.32,0.25,12.01,1.52,17.73,4.82c4.7,2.72,10.41-0.28,15.48-1.56
				c3.93-0.99,7.36-1.69,10.72,1.25c2.13,1.87,4.42,0.88,6.5-0.27c3.45-1.9,6.31-1.53,8.64,1.84c0.66,0.95,1.51,1.78,2.36,2.57
				c5.34,4.92,7.49,4.63,12.16-0.85c7.06-8.28,12.4-17.7,18.35-26.72c3.18-4.81,6.67-9.18,12.74-10.44c1.75-0.36,2.88-1.49,3.64-3.12
				c2.33-4.96,5.33-9.71,6.98-14.88c3.27-10.3,10.42-18.45,14.78-28.1c1.14-2.54,3.38-3.69,6.14-3.27c5.35,0.81,8.76-1.71,11.62-5.95
				c3.42-5.08,7.23-9.9,11.6-14.71c-0.45,23.33,2.48,46.66-1.14,69.31c-2.32,0.83-2.82,0.06-3.34-0.52
				c-4.52-5.05-6.16-4.68-10.14,1.1c-4.39,6.37-9.57,12.1-10.71,20.32c-0.54,3.89-3.51,3.11-6,1.06c-4.35-3.59-6.48-3.74-9.66,0.98
				c-3.19,4.73-7.56,3.35-11.61,3.42c-2.03,0.03-2.31-2.54-2.9-4.22c-1.44-4.14-2.76-9.05-7.97-8.99c-4.99,0.06-7.14,4.77-8.34,8.87
				c-3.3,11.31-6.67,22.64-8.55,34.31c-0.47,2.92-0.72,5.89-2.53,8.99c-1.62-4.59-3.02-8.8-4.6-12.94c-1.52-4-4.69-6.07-8.15-8.63
				c-5.74-4.25-10.76-6.84-17.73-4.06c-0.79,0.32-2.17-0.64-3.2-1.16c-1.19-0.59-2.28-1.38-3.43-2.03c-4.85-2.74-19.5-0.19-23.1,4.08
				c-0.73,0.87-1.51,1.94-1.66,3.01c-1.34,9.4-6.25,17.21-10.82,25.23c-2.95,5.18-3.07,11.3-3.37,16.97
				c-0.7,13.21-4.37,25.9-5.78,38.95c-1.2,11.11-3.98,21.92-5.66,32.94C990.42,688.61,990.51,688.5,990.51,688.5z"/>
			<path onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M785.42,656.04c12.05-2.67,19.66-8.97,24.52-18.98c-5.09-0.95-9.11,3.53-14.15,2.71
				c4.06-1.47,6.96-4.47,10.2-7.02c5.55-4.36,11.63-7.47,18.85-8.48c5.62-0.79,10.66-3.31,14.93-7.41c4.73-4.53,9.86-8.22,17.24-7.18
				c4.63,0.65,8.58-2.29,12.42-4.59c4.42-2.65,8.7-5.52,13.01-8.35c5.04-3.31,10.03-6.37,16.58-5.58c2.98,0.36,5.79-1.37,8.31-3.02
				c2.64-1.73,5.5-3.34,7.62-5.6c7.81-8.36,17.37-4.47,27.8-4.92c-7.25,6.38-8.96,15.28-16.85,19.57c-3.94,2.14-4.64,7.11-5.17,11.46
				c-1.31,10.74-3.28,21.33-6.43,31.71c-0.97,3.2-2.71,4.87-5.78,6.13c-6.27,2.55-13.28,3.96-18.93,6.94
				c-10.82,5.73-22.95,8.92-32.76,16.53c-3.8,2.95-8.06,4.73-12.78,5.09c-9.58,0.73-17.89,4.75-25.94,9.54
				c-3,1.79-5.93,3.68-8.88,5.55c-3.17,2.01-6.46,2.31-10.02,1.12c-4.24-1.43-8.56-2.64-12.83-4c-2.32-0.74-4.58-0.43-6.88,0.09
				c-2.26,0.51-4.54,1.12-6.84,1.22c-5.48,0.23-6.59-0.97-6.71-6.49c-0.04-1.83,0.24-3.79-0.31-5.46c-1.8-5.49,1.19-6.21,5.53-6.24
				c7.03-0.04,12.05-4.11,16.79-8.72C790.26,659.46,788.68,658.03,785.42,656.04z"/>
			<path onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M1080.44,598.38c3.27-15.44,6.97-29.89,10.98-44.26c0.04-0.16,0.11-0.31,0.18-0.47
				c1.24-2.91,2.45-6.76,5.82-6.75c3.61,0,4.24,4.02,5.23,7.03c2.55,7.75,12.36,9.69,17.72,3.36c2.7-3.18,4.57-5.22,8.58-1.2
				c3.3,3.31,7.25,1.91,9.26-2.35c0.42-0.9,0.9-1.86,0.95-2.82c0.5-8.93,7.7-13.82,12.32-20.55c3.26,1.78,4.57,7.1,9.63,5.32
				c0,36.92,0,73.49,0,110.43c-8.4-0.99-13.12,3.98-16.99,10.12c-1.81,2.87-3.89,4.63-7.49,4.9c-5.23,0.4-7.33,4.66-8.2,8.88
				c-1.63,7.96-9.21,11.49-12.55,18.1c-0.48,0.94-2.57,2.02-3.37,1.69c-5.32-2.16-8.31,1.65-11.86,4.15
				c-0.92,0.64-1.92,1.39-3.53,0.97c-0.97-2.51,0.56-5,0.69-7.53c0.95-19.4,2.2-38.8,4.91-58.05c0.78-5.58,2.23-11.05,2.14-16.78
				c-0.06-4.45-1.1-8.55-3.12-12.44c-2.61-5.03-5.56-6-10.49-3.23C1087.98,598.73,1084.5,597.52,1080.44,598.38z"/>
			<path onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="M879,1052.15c-1.92,4.96-4.36,7.64-8.51,9.98c-10.05,5.66-18.02,13.57-20.64,25.57
				c-2.76,12.68-6.28,25.11-11.46,37.08c-1.74,4.02-1.78,8.76-2.82,13.11c-2.77,11.56-6.92,22.77-8.44,34.78
				c-1.32-3.52-0.96-7.56-4.46-10.04c-2.02-1.43-3.89-2.18-5.69-0.11c-3.54,4.09-8.62,4.66-13.22,4.78
				c-5.24,0.14-6.72,2.35-6.76,6.86c-0.01,1.48-0.27,2.97-0.46,4.98c-2.72-2.07-2.82-4.65-2.44-7.11c0.54-3.47-0.18-6.56-1.74-9.61
				c-1.68-3.27-2.55-6.83-3.49-10.37c-1.73-6.56-5.28-11.72-11.47-14.96c-4.41-2.31-8.59-4.9-11.24-9.5
				c-1.01-1.75-3.03-3.08-4.84-4.19c-4.12-2.52-7.46-5.24-7.91-10.67c-0.32-3.9-3.51-5.83-7.1-6.82c-3.25-0.9-5.63-2.62-6.79-5.96
				c-1.47-4.24-5.47-5-9.09-6.09c-3.58-1.08-6.92-2.33-8.32-6.22c1.3-2.13,3.41-2.17,5.28-2.69c11.96-3.37,24.15-4.32,36.53-3.89
				c5.47,0.19,10.96,0.24,16.43-0.63c4.52-0.72,8.3-2.31,10.59-6.5c1.37-2.5,4.01-2.88,6.34-3.54c9.81-2.76,19.79-4.31,30.03-4.09
				c4.81,0.1,9.66,0.33,14.41-0.82c1.44-0.35,3.36-0.51,4.15-1.49c5.21-6.44,12.95-5.89,19.88-7.45
				C870.08,1055.57,874.39,1054.7,879,1052.15z"/>
			<path onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="M342.49,753.95c-2.66,0.68-3.36,3.49-5.4,5.05c-3.51-3.07-7-6.12-10.49-9.16
				c-1.74-1.51-3.63-2.91-5.33-0.12c-1.43,2.35-4.59,4.48-1.48,7.78c3.12,3.31,6.88,5.77,10.73,8.09c5.98,3.6,11.38,7.87,16.35,14
				c-4.79-1.47-7.67-5.18-12.47-5.12c7.71,7.15,14.17,15.36,21.73,22.81c-2.37,4.3-3.45,8.72-1.83,13.45c0.9,2.63-0.2,4.48-2.45,6.16
				c-1.19-0.72-2.42-1.46-3.61-2.18c-6.88,6.58-5.9,15.2-5.75,22.86c-2.43,1.1-3.21-0.9-4.57-0.58c-1.91,1.4-1.81,3.58-2.22,5.44
				c-0.72,3.28-3.37,4.11-5.9,4.43c-2.66,0.33-2.15-2.42-2.65-4.01c-1.9-6.1-3.6-6.93-9.42-3.79c-1.86,1-3.75-0.29-5.13,0.6
				c-1.3,0.84-2.04,2.57-3.55,3.61c-1.39-5-4.65-9.02-2.51-14.37c1.73-4.32-1.45-5.86-5.34-6.46c-3.55,3.69-0.68,8.19-1.58,12.46
				c-2.44-4.73-4.53-9.51-9.87-11.77c-1.46-0.62-1.3-3.33-1.22-5.14c0.52-11.56-0.87-23.28,3.91-34.45
				c1.98-4.62,0.69-9.92,1.39-14.85c1.1-7.77,3.88-15.11,5.96-22.64c0.84-3.03,5.83-8.05-2.22-9.33c0.75-4.95-4.98-5.55-6.24-9.34
				c-1.17-3.52-3.87-5.74-8.07-5.15c-3.17,0.44-4.89-3.39-8.32-2.81c-1.56,0.61-1.51,2.7-3.96,4.82c2.73-28.89-1.43-56.52-1.43-84.45
				c3.15,0.45,4.33,2.76,4.72,5.44c0.8,5.5,3.23,10.41,5.1,15.54c0.97,2.65,3.1,2.57,5.28,3.31c0,1.34,0.04,2.64-0.01,3.94
				c-0.34,9.57,0.32,10.52,9.16,14.04c2.1,0.84,3.31,0.15,3.67-1.84c0.35-1.94,0.39-3.96,0.38-5.95c-0.01-2.56,0.34-4.99,1.81-7.13
				c0.92-1.35,1.7-2.92,3.79-2.74c1.91,0.16,2.27,1.64,2.86,3.03c0.7,1.66,1.23,3.44,3.23,5.2c2.35-3.46,1.78-10.9,9.32-7.46
				c1.11,2.56,1.21,5.37,0.39,8.4c-1.21,4.42-0.71,8.77,0.43,13.27c1.95,7.72,2.54,15.66,2.39,23.98
				c-8.88-0.56-11.78,6.74-16.09,11.9c-2.24,2.68,1.11,7.78,6.2,9.43c4.29,1.39,8.2,3.59,12.29,5.22c8.36,3.32,14.21,8.7,17.91,16.7
				L342.49,753.95z"/>
			<path onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="M468.59,430.28c-4.82,2.93-9.49,3.74-14.21,3.93c-5.31,0.21-10.68,0.33-15.96-0.16
				c-7.49-0.69-14.81-0.73-22.29,0.63c-7.58,1.38-15.17-1.45-22.83-1.48c-3.69-0.02-7.21,0.23-10.76,1.47
				c-7.25,2.53-14.79,1.57-22.27,1.31c-14.79-0.52-29.65-1.85-44.37-1c-7.32,0.42-14.89,2.88-22.22,4.84
				c-8.97,2.4-17.64,2.56-26.53,0.05c-7.22-2.04-14.07-2.39-21.79,0.09c-14.16,4.55-29.01,3.73-43.91,3.38
				c-13.12-0.31-26.41-3.07-39.58,0.2c-5.26,1.31-10.61-0.06-15.91-0.3c-0.78-0.04-1.75,0.11-2.16-0.86
				c-2.2-5.26-7.09-4.75-11.16-4.46c-14.63,1.02-29.26-1.52-43.86,0.34c-1.75,0.22-3.7-0.22-4.94,1.74c0.92,2.92,4.45,1.84,5.78,3.62
				c-15.82-0.75-31.62,0.13-47.06,4.62c-6.08,1.77-11.8,1.24-17.34-1.83c-5.43-3.01-11.35-1.83-17.12-1.91
				c-1.94-0.03-3.96,0.86-5.96-0.51c0-3.23,0-6.48,0-10.23c4.39,0.35,8.47,0.68,12.56,1.01c23.95,1.89,47.5,0.12,70.25-8.14
				c5.97-2.17,11.98-2.85,18.14-1.99c14.87,2.09,29.9,1.31,44.8,2.8c7.1,0.71,13.78-1.9,20.65-3.04c15.84-2.63,31.59-4.07,47.2,1.62
				c5.53,2.02,11.43,2.44,17.4,0.81c-12.28-0.16-22.9-7.15-35.08-8.21c7.05-4.11,14.8-5.26,22.7-4.7c6.16,0.44,12.2,0.97,18.3-0.56
				c10.25-2.58,20.42,1.09,30.77,0.71c-9.04,2.14-17.79,4.86-27.07,8.02c2.09,2.01,3.46,1.19,4.75,0.75
				c14.57-4.96,29.97-5.87,44.92-9.03c14.56-3.07,29.43,0.04,44.01,2.91c19.92,3.91,40,6.7,60.31,7.73
				c10.78,0.55,21.32,3.27,32.18,3.64c6.87,0.24,13.6-0.76,20.42-0.71C455.68,427.44,461.83,428.15,468.59,430.28z"/>
			<path onClick={() => props.onFill(43)} fill={props.fillColors[43]} d="M438.27,739.76c-6.37-1.64-12.24,2.03-18.57,0.9c-3.97-0.71-6.35,2.93-8.72,5.68
				c-0.85,0.99-1.47,3.07-2.26,3.1c-10.17,0.42-4.92,10.63-9.36,14.83c-1.34,1.27,1.35,3.93,3.34,4.77
				c5.22,2.21,7.97,6.43,11.03,10.97c3.27,4.84,6.43,10.05,11.42,13.65c3.18,2.29,3.3,4.32,1.55,9.44c-0.61-0.13-1.52-0.04-1.82-0.43
				c-5.7-7.46-15.02-3.75-22.06-7.17c-2.93-1.42-5.48,0.49-7.24,2.93c-1.24,1.71-2.21,3.62-3.45,5.7c-2.21-1.33-4.11-3.25-6.59-0.57
				c-0.92,0.99-2.83-0.37-4.13-0.99c-11.47-5.44-18.08-0.43-19.95,10.63c-0.3,1.76-0.11,3.67-1.38,5.29
				c-5.83-11.56-5.1-17.5,3.57-28.26c0.1-0.13,0.27-0.21,0.35-0.35c2.6-4.17,9.84-7.64,8-11.69c-2.43-5.36-6.33-11.31-12.44-14.25
				c-8.82-4.24-15.54-10.52-19.78-19.4c-0.82-1.71-2.22-3.01-3.86-3.8c-7-3.38-13.38-8.04-21.03-10.12c-1.73-0.47-3.5-1.24-4.89-2.33
				c-2.36-1.85-3.16-3.91-0.66-6.67c4.12-4.54,7.5-9.47,15.04-8.57c3.69,0.44,6.21-3.57,8.88-5.96c2.65-2.37,5.35-3.48,8.91-3.62
				c4.62-0.19,9.91-1.61,13.76-0.59c7.67,2.04,15.2,1.48,22.8,2.02c8.15,0.59,14.48,6.29,22.14,8.41c1.62,0.45,3.17,1.92,4.29,3.3
				c6.34,7.81,14.73,12.15,24.18,14.87C433.42,732.67,435.79,735.99,438.27,739.76z"/>
			<path onClick={() => props.onFill(44)} fill={props.fillColors[44]} d="M21.34,1007.32c0.4,1.96,1.72,2.33,2.94,2.83c4.08,1.68,4.35,3.23,0.78,5.9c-1.85,1.38-4.05,2.29-6,3.54
				c-1.77,1.13-4.2,1.09-5.6,3.16c1.2,2.55,3.94,2.01,6.19,2.96c-2.89,3.84-5.1,7.95-9.93,10.06c2.7,3.02,6.55,3.02,8.67,5.94
				c-3.58,3.23-8.22,5.03-12.46,7.22c0.38,2.85,2.97,2.23,4.26,3.62c-1.48,1.58-2.25,3.29,0.66,4.65c-3.04,3.1-7.63,4.03-6.61,10.06
				c-0.66-0.48-1.32-0.95-1.98-1.43c0-87.12,0-174.23,0-261.86c5.81,4.85,12.43,7.47,17.45,13.94c5.9,7.59,10.64,15.92,16.63,23.38
				c1.44,1.79,3.07,1.98,5.17,2.18c2.82,0.28,6.58,0.03,6.51,4.76c-10.12,6.62-14.04,16.48-14.38,28.05
				c-0.12,3.93-1.3,7.29-2.87,10.89c-3.3,7.6-3.9,15.94-4.88,24.11c-0.07,0.55,0.32,1.42,0.77,1.74c8.54,5.97,4.23,14.59,3.95,21.97
				c-0.19,4.99-0.81,9.35,1.21,14.37c2.41,5.99,1.64,12.94,0.08,19.16c-2.65,10.57-3.92,21.29-4.15,32.06
				C27.65,1005.63,23.16,1005.05,21.34,1007.32z"/>
			<path onClick={() => props.onFill(45)} fill={props.fillColors[45]} d="M161.6,1099.94c1.41-4.73,6.64-6.07,8.03-10.62c-2.52-2.83-8.92-4.24-3.71-10.13
				c-3.63-2.29-5.95-4.48-4.36-9.41c1.1-3.42,2.86-6.92,3.34-11.48c-7.7,8.62-8.15,16.58-1.6,21.6c-3.26,4.96,0.56,7.4,3.99,9.86
				c-1.04,2.29-3.15,3.23-4.5,4.85c-2.21,2.65-5.16,5.16-0.38,8.49c1.91,1.33,1.98,4.61,0.5,6.31c-2.63,3.02-1.7,5,1.42,6.84
				c-3.29,2.73-6.33,4.87-3.23,9.32c1.06,1.52-0.63,3.46-2.4,4.33c-1.64,0.81-3.01,1.84-4.38,3.1c-2.31,2.12-4.1,5.57-8.46,4.12
				c-0.89-0.3-2.46,1.42-3.99,2.38c-1.78-3.24-3.46-6.34-0.17-9.81c1.69,0.32,3.55,0.68,5.42,1.03c0.25-0.37,0.51-0.73,0.76-1.1
				c-4.86-6.38-14.01-8.73-17.07-16.86c0.29-1.19,2.53-0.25,2.18-2.71c-1.15-1.29-2.73-3.05-5.01-5.6c5.43,2.05,8.45,6.93,13.4,6.65
				c-4.17-4.29-8.36-8.6-12.55-12.91c0.38-0.46,0.75-0.92,1.13-1.39c4.43,1.23,8.3,4.84,13.08,4.03c1.27-2.41-2.51-3.2-0.65-5.74
				c1.7-2.33-2.21-2.78-2.52-4.85c2.89-2.98,5.99-6.13,9.01-9.35c1.04-1.1,2.26-1.51,0.79-4.03c-2.38-4.09,2.48-6.22,4.79-8.87
				c-1.63-1.72-2.89,0.66-4.44-0.18c0.43-0.73,0.87-1.49,1.27-2.18c-0.11-1.8-2.22-0.14-2.57-1.81c4.66-2.86,9.4-5.8,11.75-11.93
				c-3.21,0.46-6.6,3.22-8.12-2.03c6.13-3.01,10.75-7.47,11.24-15.32c-3.7,0.7-2.64-4.05-6.68-4.5c6.92-3.79,4.59-10.56,6.92-15.92
				c0.97,6.27,9.85,7.51,8.72,14.71c-2.31,1.42-4.12-1.17-6.5-0.56c-0.19,4.26,3,6.45,5.21,9.17c-0.31,2.63-2.01,3.67-4.84,3.49
				c1.45,4.85,5.4,6.67,8.67,9.34c-1.62,1.42-3.46-0.29-5.13,0.56c0.15,0.92,0.29,1.79,0.5,3.04c-2.55-1.08-4.75-2.01-6.95-2.95
				c7.83,6.29,14.85,13.07,18.77,22.21c-2.7,4.41-6.34,2.19-10.14,1.28c3.33,6.01,8.06,10.29,13.83,13.6
				c-1.07,1.57-2.59-0.45-3.54,0.73c-1.26,1.54,1.02,2.19,0.86,3.74c-2.18,0-4.39,0-6.63,0c-0.28,2.67,1.84,3.6,2.88,5.18
				c1.78,2.72,6.94,4.26,3.25,9.08c-0.24,0.31,0.92,2.14,1.78,2.76c3.08,2.17,4.08,5.36,4.91,8.7c-1.57,1.38-3.24-1.22-5.35,1.02
				c3.61,2.43,7.2,3.23,12.53,2.59c-3.52-3.69-6.43-6.82-6.62-11.31c1.59-0.28,3.47,1.75,5.31,0.03c0.09-2.23-2.6-1.82-3.24-3.71
				c2.85-2.26,6.24-4.15,8.21-7.48c-0.74-2.14-3.08-0.95-4.87-2.82c7.44-1.76,9.52-9.32,16.46-12.46c-3.07-1.67-5.37,0.01-7.59-1.57
				c1.67-2.25,3.2-4.78,3.65-7.79c-1.26-1.55-3.22-0.26-5.5-1.69c6.63-2.49,8.52-11.36,17.47-9.93c-3.94-1.21-6.92-3.94-11.65-2.76
				c3.48-4.69,9.42-4.46,12.18-9.23c-2.74-0.72-5.45,0.36-7.44-1.89c1.63-2.5,5.35-3.83,5.88-7.37c-1.27-1.64-2.7-0.17-4.44-0.92
				c-0.72-6.41,6.77-7.59,8.21-13.44c2.99,5.3,5.29,9.9,10.48,14.02c-4.18-0.95-7.27-1.97-9.52,1.43c2.14,6,8.12,8.43,14.21,12.89
				c-5.45-0.94-9.39-1.62-14.55-2.51c2.65,6.32,6.64,10.44,11.43,13.88c1.68,1.21,3.35,2.17,2.89,4.95c-2.18,0-4.44,0-6.82,0
				c-0.92,3.79,1.16,6.1,3.43,7.9c2.3,1.83,3.35,3.64,1.97,6.51c-1.68,0.5-3.07-1.83-5.37-0.7c-0.29,3.23,2.12,5.11,4.01,7.26
				c3.57,4.03,10.13,6.1,10.72,11.88c0.73,7.17,7.02,8.71,10.83,12.91c-2.88,0.78-2.88,0.78-9.38-1.08
				c1.96,5.57,3.71,10.66,8.21,13.93c2.98,2.17,4.18,4.46,3.06,8.04c-2.6-0.11-4.23-2.45-6.56-3.12c-3.09-0.89-5.68-3.25-9.68-1.39
				c-2.42,1.13-5.69-1.42-7.96-3.34c-4.5-3.78-9.15-6.7-15.41-6.31c-2.5,0.16-5-0.19-7.17,1.38c-2.51,1.82-5.32,2.92-7.58,0.25
				c-2.42-2.85,0.63-4.65,2.17-6.61c0.4-0.51,0.83-1,1.24-1.49c-5.53-5.05,1.65-6.77,2.48-10.22c-0.03-1.84-2.4-0.7-3.09-2.84
				c8.38-10.7,9.81-24.34,12.58-38c-3.82,3.78-6.79,7.78-4.99,13.04c0.93,2.71,0.06,4.27-1.02,6.32c-1.19,2.27-3.25,4.35-2.18,7.32
				c0.34,0.93-0.01,1.82-0.82,2.09c-4.59,1.5-6.16,6.72-5.24,9.16c2.28,6.01-4.75,9.48-2.7,15.15c0.61,1.69-1.44,4.12-4.24,5.27
				c-4.43,1.83-9.31,2.81-13.36,6.02c-3.57-3.34-7.12-5.19-12.38-2.28c-2.16,1.19-5.94-1.18-9.92-0.08c2.38-3.26-0.12-5.13-0.78-7.32
				c1.28-3.01,8.37-4.74,1.68-8.83C166.64,1108.62,166.49,1102.56,161.6,1099.94z"/>
			<path onClick={() => props.onFill(46)} fill={props.fillColors[46]} d="M613.76,687.17c-5.51-1.86-7.75-0.36-8.1,5.09c-0.28,4.31-0.58,8.62-0.87,12.95
				c-7.33-1.87-9.23-3.92-9.66-10.94c-0.42-6.81-0.55-13.64-0.91-20.45c-0.19-3.74-0.97-7.32-4.42-9.57
				c-1.77-1.15-2.12-2.89-2.22-4.76c-0.37-6.72-2.72-12.17-9.12-15.38c-2.01-1.01-2.43-3.48-3.45-5.34c-1.4-2.55-3.06-3.5-5.45-1.16
				c-2.88,2.82-5.09,1.62-6.85-1.24c-2.78-4.51-5.65-8.92-4.68-14.77c0.89-5.36-1.71-10.02-4.32-14.74
				c8.76-5.75,16.77-12.27,27.96-12.05c8.62,0.17,16.69-2.91,24.69-5.64c7.24-2.47,14.44-5.03,21.92-6.68
				c3.71-0.82,6.85-2.62,9.21-5.59c3.77-4.74,8.46-8.45,13.52-12.46c1.1,2.24,1.83,4.58,3.99,6.1c-3.36,5.07-6.62,9.87-9.08,15.15
				c-1.49,3.19-3.9,5.92-2.2,10.13c1.09,2.69-2.03,5.15-2.1,8.4c-0.17,8.08-2.68,15.56-8.93,21.31c-1.82,1.68-3.43,2.49-6.13,1.54
				c-6.56-2.31-9.83-0.09-8.97,6.96c1.57,12.78,1.05,25.6,1.8,38.38c0.16,2.67-0.03,4.82-1.75,6.86
				C615.81,681.44,614.98,684.19,613.76,687.17z"/>
			<path onClick={() => props.onFill(47)} fill={props.fillColors[47]} d="M652.87,560.8c-9.08,3.85-15.87,10.14-22.53,16.94c-2.95-2.98-4.78-6.17-3.93-9.33
				c1.35-5.03-0.65-9.05-2.22-13.37c-1.94-5.35-3.84-10.57-4.98-16.33c-1.26-6.39-4.5-12.94-8.79-18.72
				c-10.93-14.74-25.82-24.29-41.16-33.42c-1.55-0.92-3.07-1.91-4.5-3.13c3.83,0.5,7.62,0.94,10.96,3.24
				c1.19,0.82,2.82,1.7,4.11,1.54c8.77-1.11,12.82,5.07,17,10.89c1.47,2.05,2.83,4.06,4.9,5.56c5.09,3.68,8.19,8.96,11.87,13.91
				c6.31,8.49,8.75,19.2,16.51,26.83c3.42,3.36,3.91,8.74,6.24,12.96c1.25,2.26,2.22,4.79,5.9,6.52
				c-2.18-14.66-1.62-29.11-13.29-39.91c11.32,6.85,14.71,20.08,23.96,28.53c1.75-2.07,0.57-3.55,0.05-4.72
				c-4.64-10.3-9.23-20.65-16.34-29.59c-0.62-0.78-1.25-1.68-2.09-2.13c-8.71-4.62-13.22-13.34-19.72-20.1
				c-2.36-2.45-4.68-4.65-8.34-4.91c-3.72-0.26-5.88-3.24-7.78-5.77c-4.17-5.56-9.64-7.64-16.29-8.07
				c-6.52-0.42-12.89-1.57-17.82-6.58c-0.9-0.92-2.17-1.78-3.39-2.04c-7.23-1.5-11.11-8.71-18.55-10.01
				c3.64-4.3,12.75-6.71,17.97-4.37c14.54,6.51,29.58,12.08,42.81,21.22c5.38,3.72,10.95,6.97,17.52,8.1
				c2.68,0.46,4.78,1.94,5.56,4.48c3.16,10.37,11.18,17.15,18.16,24.6c5.17,5.51,9.98,11.14,13.17,18.04
				c1.86,4.03,4.92,6.88,8.4,9.65c10.19,8.12,18.34,18.04,24.4,29.65c3.15,6.03,6.58,11.66,12.39,15.96
				c3.18,2.35,5.93,7.07,6.32,10.98c0.65,6.42,3.95,11.15,6.88,16.3c0.7,1.23,1.95,2.37,1.14,4.56c-3.34-0.29-5.37-2.65-6.07-5.36
				c-1.85-7.19-7.21-12.66-9.05-19.87c-1.01-3.97-3.81-6.83-8.37-7.6c-4.42-0.74-7.99-3.69-13.13-6.25c2.03,7.9-1.26,14.37-1.2,21.33
				c0.08,8.29,1.39,16.12,4.7,23.7c1.07,2.46,1.11,5.17,0.78,7.86c-0.51,4.09,0.2,8.07,2.18,11.6c1.95,3.46,2.7,7.05,3.03,10.92
				c1.99,23.06,2.08,46.21,3.64,70.9c-4.64-5.96-9.41-9.49-10.05-16.89c-1.14-13.3,0.24-26.65-1.55-39.91
				c-0.68-5.04-2.59-9.63-4.1-14.35c-3-9.39-1.87-19.13-2.93-28.68c-0.9-8.11-2.67-15.99-7.6-22.4c-4.68-6.07-4.39-12.19-2.82-18.82
				c0.65-2.76,1.25-5.49,1.02-8.38c-0.37-4.63-2.26-8.17-7.06-9.04C654.85,566.81,654.43,563.39,652.87,560.8z"/>
			<path onClick={() => props.onFill(48)} fill={props.fillColors[48]} d="M687.04,807.76c-7.51-7.26-15.95-11-20.39-19.45c-2.96-5.64-6.74-5.9-13.77-1.93
				c-1.48-2.25-1.46-4.93-2.11-7.38c-6.24-23.63-7.25-47.89-8.58-72.1c-0.13-2.3-0.25-4.68,0.82-6.87c1.53-3.12,4.06-1.01,6.16-1.19
				c2.22-0.19,2.46-1.78,2.67-3.54c0.67-5.53-0.08-10.95-0.95-16.38c-0.23-1.44-0.42-2.89,0.21-4.46c1.31,7.94,2.74,15.86,3.85,23.82
				c0.41,2.92-0.64,5.73-3.38,7.29c-2.12,1.2-2.04,2.85-1.52,4.69c3.57,12.82,3.52,25.99,3.87,39.13c0.18,6.94,0.47,13.88,2.2,20.8
				c1.58-0.71,1.05-1.85,0.99-2.66c-1.42-18.87,0.72-38-4.01-56.63c-0.43-1.68,0.09-2.7,1.24-3.92c3.43-3.67,4.42-8.02,3.13-12.93
				c-1.69-6.44-2.79-13-3.62-19.61c-0.27-2.11-0.33-5.06-3.17-4.76c-2.51,0.26-2.68,3.15-2.61,5.29c0.2,6.28,2.44,12.45,1.07,18.83
				c-0.14,0.64-0.23,1.28-0.33,1.88c-7.87,0.93-9.14,1.99-8.85,9.88c0.46,12.77,1.41,25.52,2.18,38.61c-2.69-1.3-3.69-3.41-4.37-5.85
				c-0.92-3.31-1.23-7-5.55-8.09c-1.4-0.35-1.02-1.92-1.1-3.03c-0.24-3.15-0.48-6.31-0.63-9.46c-0.24-4.93,0.51-9.35,2.57-14.2
				c3.66-8.62,5.17-18.07-1.7-26.77c2.14,1.35,4.34,1.56,6.55,1.98c6.38,1.19,6.33,1.31,7.27-5.35c0.25-1.8,0.6-3.62,1.16-5.35
				c0.99-3.02,5.61-4.16,7.07-1.49c2.2,4.01,5.75,6.84,8.11,10.68c3.1,5.03,5.37,10.22,5.98,16.17c0.73,7,5.75,12.59,6.71,19.6
				c0.23,1.73,1.74,3.74,3.49,3.97c8.77,1.15,12.31,8.93,17.64,14.11c5.54,5.39,9.69,12.23,13.33,19.21
				c0.31,0.59,0.53,1.38,1.03,1.69c7.75,4.78,6.7,13.07,8.16,20.34c1.04,5.2,1.92,10.44,2.92,15.97c-2.24,0.87-4.77,0.98-6.67,3.34
				c-2.61-4.92-1.61-10.6-5.79-14.01c-3.94,1.07-3.97,5.17-5.85,7.14c-12.32-4.13-12.05-16.33-16.08-26.41
				c-4.67,3.6-4.58,7.63-4.09,11.46c0.76,5.93,1.89,11.82,3.09,17.68C686.65,793.65,687.19,799.84,687.04,807.76z M674.35,792.59
				c-0.41-9.66-3.17-19.14-2.34-28.71c0.81-9.45-7.36-12.99-10.38-19.98c-2.59,1.78-2.96,3.79-2.8,5.76
				c0.87,10.42,0.87,20.93,3.08,31.22c0.21,0.96,0.43,2.01,2.39,1.56c-1.6-10.81-2.34-21.75-3.14-32.79
				c7.42,4.29,8.95,10.87,8.68,18.26c-0.03,0.83-0.02,1.67,0.04,2.49C670.42,777.97,671.98,785.36,674.35,792.59z"/>
			<path onClick={() => props.onFill(49)} fill={props.fillColors[49]} d="M330.72,944.58c-4.24,0.35-8.52,0.97-12.04-2.59c-0.92-0.93-2.49-1.26-3.78-0.72
				c-4.7,1.97-9.54-0.5-14.27,0.59c-1.69,0.39-2.77-0.59-3.26-2.31c-1.06-3.76-3.71-5.29-7.51-5.16c-2.71,0.09-3.99-1.57-4.13-4
				c-0.2-3.58-2.05-6.67-2.77-10.01c-1.82-8.43-1.75-17.16-3.11-25.71c-0.38-2.41,1.28-6.12,5.68-4.66c1.61,0.54,3.06,0.11,3.85-1.42
				c1.98-3.87,4.44-2.88,7.37-1.13c4.85,2.9,8.63,2.33,12.44-1.87c1.95-2.14,3.99-3.95,6.74-4.94c0.91-0.33,2.13-0.82,2.47-1.58
				c2.22-4.86,6.18-7.57,10.52-10.44c2.73-1.81,4.81-4.22,8.4-4.51c2.12-0.17,3.37-1.97,4.48-3.68c2.62-4.04,6.41-5.74,11.05-4.84
				c3.72,0.73,5.11-1.19,5.14-4.2c0.04-3.8-0.3-7.65-0.92-11.4c-0.46-2.79-0.36-5.25,1.68-8.16c1.21,8.85,2.92,16.89,8.01,24.07
				c3.03,4.28,0.98,9.76-0.48,14.45c-1.26,4.03-1.42,7.75-0.03,11.67c1.67,4.7,2.4,9.35-1.09,13.7c-0.58,0.73-0.88,1.82-0.98,2.78
				c-0.72,7-5.04,11.74-9.95,16.12c-0.74,0.66-1.95,1.16-2.18,1.96c-2.11,7.18-8.23,9.62-14.12,12.54c-3.65,1.81-4.01,5.85-5.14,9.3
				C332.12,940.48,332.39,942.82,330.72,944.58z"/>
			<path onClick={() => props.onFill(50)} fill={props.fillColors[50]} d="M844.41,955.88c-3.17,5.83-1.34,9.63,2.37,12.86c1.84,1.59,2.9,3.16,2.83,5.91
				c-0.11,4.06,3.52,5.33,6.6,6.52c5.57,2.15,11.37,3.74,16.16,7.6c3.56,2.86,6.39,6,8,10.51c1.91,5.36,7.41,8.28,10.06,13.53
				c1.6,3.18,6.73,2.99,10.57,3.58c0.05,2.41-1.74,3.92-3.07,4.95c-6.51,5.07-9.45,12.34-12.41,19.52
				c-2.82,6.84-7.87,10.46-14.79,11.95c-4.23,0.91-8.47,1.76-12.72,2.53c-4.54,0.82-8.66,1.62-12.25,5.5
				c-3.36,3.63-8.98,3.05-13.78,2.97c-10.86-0.19-21.62,0.31-32.17,3.19c-4.82,1.31-9.57,2.37-12.33,7.46
				c-1.49,2.75-4.9,3.14-7.95,3.47c-8.46,0.91-16.97,0.92-25.44,0.67c-9.97-0.29-19.47,2.09-29,4.39c-2.15,0.52-4.85,0.9-5.31,3.8
				c-0.42,2.58,0.95,5.05,2.96,5.94c7.65,3.41,13.9,8.26,20.12,13.97c3.8,3.49,8.24,5.18,9.43,11.2c0.85,4.3,5.56,6.31,10.44,9.2
				c-4.53-0.6-7.87-2.29-11.1-0.87c-4.12,1.81-3.62,5.48-2.54,9.63c-5.09,0.05-9.2-0.76-12.59-4.15c-5.23-5.22-8.39-5.49-14.37-1.28
				c-2.34,1.65-4.8,2.94-7.64,3.47c-5.66,1.06-9.76-1.77-8.34-7.26c2.35-9.08-2.25-13.11-9.01-16.81c-3.17-1.74-6.31-3.93-5.86-8.45
				c0.17-1.74-1.09-2.68-2.48-3.4c-1.63-0.84-3.33-1.55-4.88-2.51c-5.11-3.16-5.28-8.71-0.3-12.13c1.62-1.11,3.52-1.89,5.38-2.56
				c14.31-5.11,28.94-8.77,44.19-9.62c4.7-0.26,9.09-1.3,13.2-3.95c4.01-2.59,8.89-2.68,13.51-2.97c8.82-0.55,18.11-0.4,26.11-3.58
				c20.93-8.34,43.26-6.21,64.71-10.32c10.88-2.09,21.19-5.45,31.14-10.1c4.04-1.89,5.4-6.95,2.39-10.57
				c-3.85-4.63-4.73-9.65-4.3-15.34c0.45-6.02-1.82-11.36-6.17-15.23c-6.54-5.82-12.77-12.17-21.25-15.46
				c-4.21-1.63-8.29-3.77-8.46-9.35c-0.02-0.81-1-1.65-1.66-2.37c-0.55-0.61-1.23-1.12-1.91-1.6c-3.28-2.3-5.07-5.41-4.37-9.4
				C836.76,957.01,840.6,957.65,844.41,955.88z"/>
			<path onClick={() => props.onFill(51)} fill={props.fillColors[51]} d="M445.74,1358.78c-7.1-4.87-11.55,3.83-18.13,2.7c-0.76-17.96,5.08-34.61,10.49-52.76
				c3.06,5.69,7.42,6.58,12.49,5.34c0.78-0.19,1.83-0.05,2.19,0.8c3.45,8.35,11.24,7.31,18.02,8.21c1.67,0.22,2.83,0.51,3.64,1.94
				c2.96,5.19,11.14,3.15,12.95-1.83c1.23-3.39,2.57-6.88,1.66-10.66c-0.08-0.32-0.42-0.73-0.31-0.92c3.88-7.02-1.1-10.79-5.21-15.14
				c-1.72-1.82-2.94-4.45-1.03-7.76c12.01,4.61,24.09,9.15,36.1,13.87c11.27,4.43,22.15,9.89,33.9,13.07
				c2.84,0.77,3.54,2.43,3.72,5.02c0.69,9.82-0.11,19.61-0.11,29.67c-4.36,1.7-7.86-0.85-11.56-1.99c-0.77-0.24-1.59-1.32-2.13-1.16
				c-5.63,1.65-10.66-0.25-15.68-2.36c-0.12-0.05-0.37,0.15-0.47,0.09c-8.55-5.04-14.52,0.6-20.95,4.87
				c-2.22,1.47-4.47,2.16-6.6-1.21c-2.2-3.48-6.04-3.77-8.99-0.73c-3.22,3.31-6.04,4.78-10.11,1.35c-1.66-1.4-4.02-1.7-5.91,0.21
				c-2.82,2.86-5.82,1.98-8.61,0.17c-3.96-2.57-7.5-2.44-10.7,1.09C451.7,1353.61,446,1353.05,445.74,1358.78z"/>
			<path onClick={() => props.onFill(52)} fill={props.fillColors[52]} d="M795.17,762.32c4.62-2.23,8.96-4.38,14.13-3.86c1.97,0.2,3.92-0.16,5.63,1.15
				c0.39,0.3,0.99,0.78,1.29,0.67c5.48-2.07,11.15,0.67,16.36-0.28c7.75-1.41,10.44-1.27,15.22,5.04c2.1,2.77,4.18,4.83,7.57,1.25
				c3.03-3.21,6.49-1.73,9.55,0.17c3.33,2.07,6.46,3.38,10,0.45c1.19-0.98,3.2-1.36,4.23-0.34c5.19,5.17,11.88,2.41,17.8,3.61
				c1.46,0.3,2.98,0.33,4.29,1.09c2.66,1.53,5.04,1.34,7.24-0.87c2.34-2.34,4.73-2.03,7.72-0.85c3.34,1.32,6.92,2.49,10.71,0.35
				c2.71-1.53,4.98-0.04,5.72,2.82c1.06,4.13,3.57,6.2,7.72,6.51c1.45,0.11,2.48,0.99,3.29,2.06c2.16,2.85,4.04,5.47,8.53,3.41
				c2.1-0.96,4.41,1.2,4.2,3.64c-0.25,2.87,1.25,3.4,3.32,3.88c5.2,1.21,9.45,4.02,13.01,7.92c3.02,3.31,2.49,5.94-1.65,7.74
				c-4.22,1.83-8.82,1.58-13.27,1.86c-7.77,0.5-15.65-0.06-22.88,3.91c-2.3,1.26-4.68-0.38-6.5-1.58
				c-7.37-4.87-15.06-5.58-23.79-4.51c-5.6,0.68-11.64-1.82-17.41-3.17c-3.84-0.89-7.79-1.79-10.73-4.81
				c-0.22-0.23-0.59-0.47-0.87-0.45c-5.57,0.48-10.68-1.92-16.06-2.58c-7.07-0.86-14.11-2.11-18.34-8.6
				c-2.51-3.84-4.97-5.91-9.76-4.21c-2.69,0.96-4.5,0.36-5.86-3.18c-1.5-3.89-4.56-6.73-9.27-8.72c-4.96-2.1-9.93-3.62-14.97-5.31
				C799.29,765.85,798.58,762.12,795.17,762.32z"/>
			<path onClick={() => props.onFill(53)} fill={props.fillColors[53]} d="M382.87,1261.25c1.54-3.79,0.6-6.1-2.3-6.52c-3.44-0.5-6.16-2.25-9.08-3.72c-3.98-2-7.88-2.45-11.87-0.11
				c-0.55,0.32-1.4,0.83-1.8,0.63c-6.5-3.27-12.27,1.39-18.44,1.72c-5.38,0.29-10.16,9.13-8.52,14.33c0.53,1.68,1.37,2.9,3.11,3.59
				c7.88,3.1,16.53,2.01,23.37-3.08c1.64-0.05,2.18,1.27,2.85,2.39c0.8,1.35,1.92,2.95,3.44,2.33c7.02-2.86,16.01,2.08,21.6-6.08
				c4.57,3.14,8.02,9.34,14.1,4.11c3.01,2.54,5.76,4.94,8.58,7.25c3.19,2.61,5.58,15.5,3.46,19.03c-1,1.66-2.31,3.01-4.4,2.68
				c-3.73-0.6-6.36,1.03-8.74,3.65c-1.27,1.39-3.2,2.53-4.86,1.07c-2.51-2.22-4.11-0.73-6.5,0.29c-4.66,2-9.65,3.35-14.61,4.49
				c-2.07,0.48-4.13-0.48-5.03-3.06c-0.96-2.75-3.02-4.76-5.56-6.19c-1.99-1.12-3.73-1.28-5.96,0.15c-4.21,2.7-8.78,4.05-13.38,0.45
				c-2.21-1.73-4.48-0.67-6.62,0.19c-2.94,1.18-5.36,1.39-7.13-1.98c-1.5-2.85-3.56-3.37-6.52-1.35c-5.22,3.56-7.32,3.15-11.8-1.37
				c-0.84-0.85-1.75-1.72-3.02-1.42c-3.27,0.78-6.24,0.23-9.05-1.55c-2.74-1.74-5.95,0.61-9.07-1.13c0.34-2.16-0.77-4.05-1.97-5.22
				c2-5.47,8.68-3.93,11.06-8.51c6.57,1.49,6.64-5.09,9.52-8c2.41-2.43,4.28-0.18,6.8-0.49c1-4.24,6.03-5.64,7.32-10.72
				c0.69-2.71,5.94-4.75,9.21-6.67c3.84-2.26,7.78-5.57,12.96-4.77c0.76,0.12,1.77-0.61,2.38-0.33c7.01,3.19,13.06-1.55,19.58-2.22
				c3.4-0.35,6.96-0.65,10.27-0.03c3.02,0.56,5.7,2.03,9.07,1.17c1.88-0.48,2.8,1.99,3.67,3.58c3.37,6.09,4.45,13.27,9.27,18.84
				c-3.02,2.98-5.6,1.97-7.84-0.39c-2.95-3.1-5.71-4.17-9.27-0.76c-1.98,1.9-4.62,2.55-7.44,1.47c-1.28-0.49-2.62-0.31-3.91,0.18
				c-3.72,1.4-7.13,2.38-9.74-2.21c-1.01-1.78-3.47-2.16-5.17-0.22c-6.05,6.92-13.3,2.42-20.07,2.12c-1.69-0.08-2.04-1.98-2.09-3.54
				c-0.16-4.33,4.63-9.81,9.01-10.01c3.42-0.15,6.34-2.78,9.6-2.35c4.64,0.61,8.87,0.55,13.15-1.7c2.03-1.06,4.33,0.45,6.03,1.67
				C374.39,1255.71,380.28,1255.21,382.87,1261.25z M405.02,1282.05c-4.05,1.15-6.79,3.99-10.66,1.66
				c-8.06-4.85-8.14-4.73-13.29,2.87c-3.73,5.5-10.79,5.63-14.95,0.43c-1.91-2.39-5.03-3.44-7.63-2.49
				c-7.37,2.69-14.02-1.53-21.08-1.75c-3.36-0.11-7.14-2.29-10.66-3.7c-2.23-0.89-4.01-2.26-4.31-4.99c-0.18-1.6-0.81-3.16-1.24-4.73
				c-1.72,6.92-0.12,10.52,5.82,12.25c6.26,1.82,12.1,5.79,19.15,4.32c0.83-0.17,1.98,0.34,2.76,0.86c2.79,1.86,5.58,1.8,8.5,0.38
				c2.53-1.22,4.98-0.82,6.73,1.25c5.72,6.74,14.62,6.17,19.01-1.35c3.57-6.11,3.56-6.11,9.53-2.19
				C397.3,1287.89,400.78,1287.24,405.02,1282.05z"/>
			<path onClick={() => props.onFill(54)} fill={props.fillColors[54]} d="M990.15,555.78c3.99-2.27,7.89-4.33,11.54-6.91c3.77-2.67,6.93-5.62,8.72-9.93
				c1.65-3.98,5.82-4.24,9.01-5.57c4.54-1.89,8.5-3.78,8.61-9.34c2.28-0.37,4.02,0.33,5.63,1.03c5.29,2.3,10.33,3.39,15.31-0.9
				c2.11-1.81,4.38-0.29,6.49,0.76c7.97,3.96,8.29,3.82,14-3.25c1.56-1.93,3.23-3.89,5.24-5.29c9.07-6.33,17.37-13.77,27.38-18.76
				c4.84-2.41,6.36-7.65,8.48-12.01c2.3-4.73,5.33-7.31,10.53-7.56c3.11-0.15,5.76-1.51,7.86-3.83c4.81-5.32,11.55-8.38,16.19-13.91
				c0.61-0.73,1.62-1.53,2.48-1.57c5.75-0.28,9.83-3.33,13.1-8.49c1.3,4.21,0.33,6.81-2.04,9.32c-4.68,4.95-9.32,9.97-13.11,15.68
				c-1.52,2.28-3.5,3.52-6.28,3.18c-6.11-0.75-9.13,2.91-12.18,7.55c-8.06,12.28-13.01,26.07-19.54,39.08
				c-0.83,1.66-1.51,3.33-3.54,3.87c-5.88,1.58-9.42,5.94-12.68,10.67c-6.14,8.89-10.98,18.63-17.84,27.05
				c-3.92,4.81-5.24,4.98-9.77,0.54c-0.83-0.82-1.68-1.63-2.38-2.56c-2.64-3.48-5.95-4.11-9.67-2.11c-2.69,1.45-4.87,1.32-7.21-0.78
				c-2.46-2.21-5.52-1.88-8.53-1.24c-4.06,0.86-8.13,1.66-12.2,2.45c-3.35,0.65-5.73-1.41-8.05-3.23c-2.22-1.74-4.23-2.67-7.26-1.85
				c-2.64,0.71-5.67,0.62-8.32-0.5C996.95,556.03,993.82,554.77,990.15,555.78z"/>
			<path onClick={() => props.onFill(55)} fill={props.fillColors[55]} d="M849.94,1433.32c19.32,1.58,37.7,5.46,55.83,11.15c15.35,4.82,30.76,9.72,46.86,11.69
				c3.35,0.41,6.87,0.54,9.86-0.78c5.45-2.4,10.36-1.07,15.4,0.87c11.51,4.44,22.85,9.42,33.08,16.23
				c16.95,11.29,35.2,19.32,54.82,24.49c0.62,0.16,1.19,0.5,3.1,1.32c-2.63,0.16-3.97,0.3-5.3,0.3c-5.49,0.03-11.01-0.37-16.47,0.09
				c-13.26,1.12-25.21-0.83-36.19-9.55c-7.54-5.99-17.8-7.09-26.79-10.52c-0.74-0.28-1.66-0.32-2.46-0.22
				c-15.63,2.01-29.92-2.77-44-8.63c-17.95-7.47-35.89-14.98-52.98-24.34c-1.43-0.78-2.88-2.47-4.83-1.12
				c-0.36,1.39,0.54,2.03,1.4,2.44c22.66,10.78,43.6,24.92,66.85,34.5c11.31,4.66,23.48,5.82,35.48,7.62
				c7.82,1.17,14.94,4.22,21.37,9.75c-4.18,0-8.35,0-12.53,0c-1.5,0-3-0.08-4.49,0.01c-11.74,0.77-23.07,0.11-33.39-6.79
				c-3.34-2.23-7.72-2.99-11.7-4.13c-6.42-1.83-12.72-3.98-18.55-7.21c-20.13-11.14-41.3-19.86-62.9-27.63
				c-1.31-0.47-2.47-0.87-3.13-2.17C855.39,1445.12,852.18,1439.7,849.94,1433.32z"/>
			<path onClick={() => props.onFill(56)} fill={props.fillColors[56]} d="M488.88,1386.32c4.63-1.36,8.11-3.25,9.85-7.83c3.49,1.98,6.3,1.25,9.49-0.99
				c3.43-2.4,7.64-3.31,11.2,0.48c0.74,0.79,1.71,1.46,2.61,0.78c4.07-3.09,8.27-0.62,12.39-0.39c0.97,0.05,2.17,0.73,2.75-0.23
				c1.75-2.93,4.16-1.74,6.63-1.5c-1.78-1.93-3.6-3.58-6.37-1.73c-1.06,0.71-2.04,0.68-3.25,0.65c-3.99-0.1-8.03-2.3-12.02,0.37
				c-1.5,1-2.24-0.98-3.26-1.62c-2.02-1.27-4.25-0.41-6.68-1.03c-0.27-4.09-2.51-6.95-5.95-9.42c-2.63-1.89-4.71-2.79-7.6-0.97
				c-2.1,1.32-3.59,1.04-4.37-1.78c-1.28-4.69-4.58-5.82-8.89-4.11c-1.95,0.77-4.14,1.53-5.71,0.19c-4.47-3.8-9.05-1.66-13.65-0.81
				c-0.65,0.12-1.3,0.66-1.89,0.58c-6.06-0.79-11.8-0.49-16.27,4.95c1.04-7.1,7.36-8.15,11.46-11.47c1.28-1.04,3.1,0.56,4.51,1.41
				c3.68,2.21,7.33,3.28,10.98-0.04c2-1.81,3.92-0.62,5.38,0.61c3.48,2.91,6.26,1.97,9.1-0.91c2.93-2.96,5.64-4.79,8.34,0.64
				c1.51,3.03,4.91,2.05,7.13,0.75c3.86-2.26,6.18-0.82,8.33,2.3c2.03,2.95,4.56,4.41,8.45,3.97c7.98-0.9,8.48-0.36,9.88,7.28
				c4.47-2.19,7.99,1.68,12.23,1.88c2.16,0.1,2.61,1.98,2.06,4.18c-1.02,4.1,0.85,6.22,4.78,7.81c3.9,1.58,8.5,1.36,11.81,4.47
				c0.82,0.77,1.89,0.83,2.73,0.07c3.76-3.43,6.56-0.26,9.77,1.17c3.04,1.35,6.37,1.21,7.45,5.96c0.59,2.56,5.01,4.16,7.53,6.39
				c2.94,2.61,3.76,6.9,7.06,9.28c-3.37,4.79-1.41,10.45-2.55,15.6c-0.48,2.18-1.86,3.63-4.27,3.23c-2.78-0.46-1.68-2.73-1.8-4.41
				c-0.26-3.7-2.39-4.92-5.78-4.17c-1.59,0.35-3.08,1.36-4.98,0.19c1.29-4.07,2.36-8.46-3.44-9.9c-3.97-0.99-8.02-1.86-12.12-1.54
				c-0.68-8.76-1.75-9.46-9.08-6.05c-2.31-5.23-5.86-6.61-10.93-3.26c-1.66,1.1-3.07-0.5-4.5-1.3c-3.14-1.75-6.01-2.44-8.77,1.06
				c-2.3,2.92-5.19,3.22-8.04,0.25c-2.96-3.07-5.69-3.87-9.15-0.03c-2.59,2.87-6.73,3.49-10.62,3.47c-2.34-0.01-4.61-0.13-3.98-3.91
				c0.36-2.13-1.24-4.25-3.94-4.17c-2.53,0.08-4.02-1.02-5.06-3.21c-1.61-3.38-3.88-3.54-6.84-1.47c-5.82,4.06-6.46,3.8-11.38-1.5
				c-2.47-2.66-5.46-2.22-8.01,0.05c-2.03,1.81-3.97,2.9-6.79,1.24c-0.11-2.55,4.03-4.04,1.61-7.11c-1.82-2.31-3.69-3.34-6.77-3
				c-5.63,0.63-7.05-0.61-7.91-7.87c-0.42-3.51,1.32-6.5,5.84-6.14c0.61,0.05,1.49-0.18,1.86-0.61c6.31-7.22,15.57-2.81,22.94-5.84
				c1.2-0.49,2.74-0.67,3.85,0.22c3.52,2.82,7.04,3.03,11.11,1.02c2.73-1.36,4.46,0.5,5.06,3.09c0.94,4.08,2.96,5.17,6.85,3.32
				c3.54-1.68,10,1.98,10.69,5.8c0.58,3.18-4.77,7.97-7.86,6.56c-3.69-1.69-5.57-0.84-6.76,2.79
				C494.36,1383.08,489.65,1382.11,488.88,1386.32z"/>
			<path onClick={() => props.onFill(57)} fill={props.fillColors[57]} d="M723.25,783.2c-1.31-7.86-2.63-15.71-3.94-23.57c-0.57-3.41-1.8-6.4-5.07-8.14
				c-1.56-0.83-2.25-2.39-3.1-3.81c-1.88-3.14-3.31-6.72-5.79-9.29c-6.71-6.95-8.62-15.33-9.08-24.55
				c-0.83-16.45-2.12-32.88-3.04-49.33c-0.44-7.98-0.29-15.98-4.67-23.19c-0.89-1.47-1.38-3.61-1.12-5.29
				c1.21-7.65-1.53-14.42-4.26-21.21c-2.14-5.33,0.24-10.83-0.62-16.46c2.45,4.71,6.2,8.32,8.8,12.68c2.9,4.86,4.53,9.6,11.03,12.32
				c6.79,2.83,11.52,9.35,12.56,18.09c2.08,17.47,6.23,34.64,5.96,52.45c-0.13,8.53,1.55,17.09,2.14,25.69
				c-1.81-5.24-2.25-10.76-2.87-16.21c-1.45-12.71-2.74-25.45-4.06-38.18c-0.22-2.08-0.79-3.89-3.55-5.51
				c-2.35,4.42-5.72,8.37-5.28,13.77c1.28,15.9,5.1,31.49,5.59,47.52c0.14,4.48,4.12,8.13,5.07,12.8c1.82,8.97,3.48,17.95,4.91,26.99
				c0.66,4.17,0.74,8.51,2.33,12.51c2-0.36,1.33-1.55,1.25-2.41c-1.17-13.1-2.13-26.49-6.64-38.75c-6.83-18.57-5.86-38.15-9.73-57.04
				c-0.81-3.96,0.35-7.44,3.61-10.83c1.41,13,2.72,25.51,4.13,38c0.6,5.28,1.17,10.59,2.19,15.8c0.57,2.91,1.24,6.36,5.64,6.51
				c1.32-3.82,0.01-7.39-0.58-10.89c-2.4-14.18-1.06-28.62-3-42.78c-1.71-12.49-3.78-24.97-6.73-37.26c-0.64-2.67-1.46-5.49-4.01-6.6
				c-4.99-2.17-7.18-6.02-8.15-11.03c-0.3-1.52-1.15-2.92-1.33-4.72c3.11,8.79,10.33,13.06,17.88,16.94
				c1.49,0.77,1.43,2.23,1.54,3.45c0.71,7.51,2.74,14.67,5.38,21.69c2.88,7.66,0.81,15.72,1.93,23.62c2,14.1,3.81,28.24,6.13,42.3
				c0.89,5.39-0.7,10.58-1.4,15.84c-0.76,5.71-0.66,11.07,5.16,14.4c1.18,0.67,1.33,1.8,1.42,2.99c0.32,4.31,0.59,8.62,1.03,12.92
				c0.27,2.64-0.57,4.16-3.37,4.31c-1.41,0.07-2.82,0.54-3.24,1.93c-1.66,5.5-7.6,7.11-9.85,12.03
				C724.04,783.54,723.64,783.37,723.25,783.2z"/>
			<path onClick={() => props.onFill(58)} fill={props.fillColors[58]} d="M740.91,470.51c8.53,6.52,13.56,14.78,16.44,24.96c3.5,12.39,7.52,24.59,15.66,35.21
				c4.67,6.08,7.24,13.78,10.75,20.75c0.94,1.86,1.84,3.74,2.76,5.61c1.98-1.02,1.02-1.98,0.68-2.74
				c-4.83-10.95-9.88-21.75-17.14-31.42c-6.33-8.42-7.09-19.13-10.74-28.67c-0.74-1.94,1.24-2.47,2.46-3.2
				c2.32-1.38,4.71-2.88,6.67-0.46c-0.37,8.72-1.99,17.16,5.22,23.78c1.27,1.17,1.7,2.79,2.28,4.36c1.54,4.21,3.2,8.37,4.83,12.55
				c2.16,5.52,4.81,10.54,11.29,12.09c1.21,0.29,2.41,1.22,3.31,2.15c14.2,14.86,33.18,23.31,48.7,36.41
				c2.54,2.14,5.04,4.34,7.48,6.59c3.62,3.33,7.78,5.6,12.5,6.97c2.32,0.67,4.87,1.05,6.59,3.45c-5.28,3.73-10.4,7.08-17.34,6.4
				c-5.57-0.55-9.53,3.39-13.77,6.26c-1.51,1.02-2.88,2.26-4.63,3.65c-3.5-2.86-6.74-6.3-10.66-8.55
				c-10.26-5.89-18.69-13.5-25.11-23.48c-3.43-5.33-8.62-8.85-14.48-11.01c-8.75-3.23-14.63-9.32-19.22-17.18
				c-5.71-9.78-12.13-19.08-19.89-27.38c-2.73-2.91-0.44-4.9,1.48-6c3.04-1.74,3.14-3.69,2.18-6.57c-2.81-8.4-3.41-17.25-4.92-25.91
				c-0.78-4.48-2.24-8.65-4-12.79C739.48,474.46,738.16,472.32,740.91,470.51z"/>
			<path onClick={() => props.onFill(59)} fill={props.fillColors[59]} d="M851.31,1329.85c-0.23-0.56-0.52-1.14-0.7-1.76c-1.04-3.58-2.72-6.96-6.99-6.42
				c-4.37,0.55-6.12,4.19-6.82,8.18c-0.47,2.68,0.19,5.29,1.04,7.84c0.97,2.89,0.76,5.71-1.44,7.88c-2.56,2.52-4.5,5-2.5,8.71
				c1.97,3.66,4.94,5.28,9.25,4.17c2.6,4.64,2.02,9.55,1.1,14.17c-2.32,11.72,1.02,21.02,10.8,27.89c1.77,1.25,2.42,2.61,2.68,4.82
				c0.43,3.59,1.68,7.07,2.64,10.81c-6.34,2.82-12.98-0.02-20.08,0.63c-3.74-5.19-7.53-11.31-13.47-15.52
				c-1.45-1.03-1.18-2.54-1.14-4.08c0.23-8.93,1.64-17.92-2.29-26.57c-1.1-2.43,0.3-5.26,0.72-7.86c2.53-15.58,2.78-31.62-0.45-46.6
				c-2.38-11.07-1.47-22.03-3.35-32.9c-0.47-2.74-1.4-7.12,2.55-9.63c1.72-1.09,0.96-3.38,0.88-5.17c-0.12-2.99-0.48-5.97-0.49-8.95
				c-0.01-2.99,1.33-5.03,4.6-5.47c2.58-0.34,4.76-1.41,5.88-4.02c0.25-0.57,0.84-1.4,1.3-1.42c7.66-0.23,5.43-5.78,5.48-10.09
				c0.01-1.16,0.1-2.37-0.17-3.48c-0.79-3.21,0.36-5.54,3.55-5.73c2.79-0.17,5.7,0.82,6.51,4.18c0.47,1.93,0.62,3.94,0.81,5.93
				c2.64,28.07,6.47,56.08,3.93,84.39C854.88,1326.51,855.06,1329.23,851.31,1329.85z"/>
			<path onClick={() => props.onFill(60)} fill={props.fillColors[60]} d="M276.88,1117.13c-1.68-0.27-2.93-0.47-4.49-0.72c1.01-3.01,2.95-5.09,5.63-6.54
				c-1.88-2.02-3.7-0.42-5.53-1.42c-1.88-3.02,0.32-4.11,2.88-5.28c2.18-1,4.6-1.88,5.65-4.42c-1.86-1.86-3.6,0.73-5.87-0.6
				c-1.41-5.64,3.57-8.16,6.69-11.66c-1.66-2.54-4.05-1.1-6.5-1.31c5.4-3.09,5.49-10.73,11.84-13.27c-2.12,0-4.23,0-6.96,0
				c4.31-4.15,7.7-8.56,10.57-14.81c1.91,7.52,7.82,11.68,9.95,17.76c-2.28,1.75-3.53-0.22-5.41,0.36
				c1.73,7.17,7.22,11.01,14.02,15.48c-4.23-0.68-7.09,0.48-10.06-0.89c-1.24,7.98,9.61,10.32,7.87,18.44
				c-1.69-0.64-3.17-1.2-5.24-1.99c-0.92,5.97,8.08,9,3.12,15.26c-1.51,1.08-3.23-1.34-5.74-1.09c1.41,5.6,6.01,8.1,9.96,11.07
				c-0.83,2.2-3.3,0.58-4.29,2.12c-0.37,1.09,0.09,2.11,0.8,2.72c4.94,4.31,4.81,9.66,3.59,15.43c-0.44,2.08-0.3,4.32-0.19,6.48
				c0.17,3.24-0.77,8.09,5.21,3.96c1.52-1.05,2.58,0.92,3.92,1.31c-1.41,3.69-1.12,9.3-7.63,6.32c-0.35,0.57-0.73,0.95-0.66,1.11
				c2.46,5.32,1.24,12.72,8.73,15.26c1.57,0.53,2.65,2.45,2.12,4.1c-0.67,2.08-2.66,1.38-4.3,1.12c-2.71-0.43-5.31-1.68-8.5-1.24
				c0.9,5.83,6.44,7.39,9.74,11.3c-3.59,1.1-6.85,1.93-10.88,1.55c3.21,7.35,9.46,10.04,15.7,12.82c-2.08,2.74-5.01,0.38-6.96,1.8
				c-0.95,2.45,1.68,3.56,1.83,5.52c-5.87,1.04-6.93,0.14-6.6-5.81c0.19-3.37-2.61-3.83-4.67-5.02c1.56-8.03-2.62-15.44-10.38-18.44
				c-0.31-0.12-0.67-0.16-0.93-0.35c-2.63-2.01-4.08-5.22-7.23-6.76c-3.02-1.48-6.06,2.29-9.14-0.59c0.82-1.69,1.48-3.73,2.69-5.37
				c2.47-3.33,7.04-6.36,0.21-9.87c-0.5-0.26-0.97-1.87-0.69-2.18c3.32-3.73,1.64-8.38,2.51-12.58c-0.71-1.81-2.92-1.4-4.18-2.91
				c1.1-4.88,6.98-5.44,9.36-9.07c-1.14-2.27-3.42-0.54-4.76-2.36c2.33-2.4,4.74-5.05,3-8.95c-0.17-0.37,0.06-0.99,0.23-1.44
				c2.31-6,2.31-6-3.24-7.23c-0.3-1.88,2.24-2.48,1.93-4.53c-0.37-1.15-1.72-1.21-2.67-1.77c-0.86-0.51-1.79-0.88-2.28-2.29
				c2.31-3.34,3.74-7.53,4.92-11.46c1.22-4.08,2.09-8.63,2.71-13.03c0.79-5.64-2.67-11.96,2.51-16.99
				c-3.23,3.88-6.69,7.18-4.39,13.16c0.88,2.29-0.66,6.25-1.19,9.39c-0.84,4.94-2.62,9.89-4.89,14.38c-2.08,4.1-2.64,7.12,2.35,9.41
				c-3.99,4.62-2.14,6.86,3,7.37c-2.04,4.72,0.2,9.99-4.45,13.47c-1.75,1.31,0.83,2.78,1.39,4.02c-1.63,1.76-3.29,3.39-4.75,5.18
				c-1.66,2.04-2.13,3.63,0.73,5.63c2.56,1.8,2.87,5.84,1.49,8.12c-2.97,4.91-1.68,8.06,2.6,10.78
				c-10.74,16.89-10.74,16.89-16.14,18.45c2.77-5.48-2.1-11.36,1.81-16.62c0.28-0.37-0.19-1.75-0.71-2.2
				c-2.34-2.01-2.08-4.75-1.22-6.9c1.57-3.95,1.2-7.05-2.37-9.28c1.91-4.34,6.87-4.77,9.02-8.89c-2.86-0.41-2.23-4.58-5.71-4.19
				c1.94-4.51,7.11-5.88,8.78-10.02c-1.24-2.58-3.3,0.44-4.46-1.1c-0.53-1.9,1.76-2.72,1.7-4.51c-1.65-1.39-3.56,0.76-5.78-0.88
				C270.74,1125.41,274.06,1121.92,276.88,1117.13z"/>
			<path onClick={() => props.onFill(61)} fill={props.fillColors[61]} d="M1068.64,1453.67c6.86-3.17,12.97-0.85,15.95,6.01c0.94,2.16,2.53,2.6,4.53,3.25
				c4.86,1.58,9.84,3.29,11.87,8.84c-4.91-3.77-9.77-6.44-15.53-1.7c-0.95,0.78-1.94-0.14-2.75-0.56c-2.71-1.39-5.12-1.12-7.3,1
				c-0.89,0.86-1.9,1.26-3.16,1.1c-3.3-0.42-5.7,1.64-8.49,2.68c-4.64,1.73-8.78,5.64-14.51,4.51c-4.91-0.97-7.24,2.25-8.21,6.85
				c-3.78-1.16-6.98-2.74-10.29-4.89c0.57-1.02,1.03-1.86,1.5-2.69c4.87-8.59,3.48-12-6.19-14.43c-2.52-0.63-2.9-2.17-3.23-4.28
				c-0.89-5.68-4.12-7.55-9.54-5.75c-7.94,2.64-7.95,2.65-12.33-4.75c-1.82-3.07-4.84-4.4-8.01-4.17
				c-8.18,0.59-16.12-2.34-24.04-1.95c-8.06,0.39-16.99,0.55-22.92,8.02c-1.65,2.08-3.75,0.88-5.51,0.39
				c-14.73-4.11-29.48-8.13-44.1-12.59c-14.37-4.38-29.41-5.5-43.83-9.59c-6.22-1.76-6.28-1.53-10.16-9.78
				c6.78,0.88,13.34,1.06,19.69-1.19c0.15-0.05,0.38-0.08,0.49,0c6.88,5.24,14.77-0.81,21.91,1.92c1.18,0.45,3.09,0.08,3.55,1.34
				c2.34,6.42,9.57,6.59,13.62,10.86c1.24,1.3,3.01,2.23,4.04,4c1.82-4.49-3.41-4.63-4.35-7.33c3.03-2.11,14.12,0.13,19.44,4
				c3.18-4.29,6.75-7.06,12.39-3.38c2.06,1.35,4.78,0.74,6.91-1.17c3.62-3.25,7.21-4.15,11.28-0.27c1.74,1.66,3.77,3.24,6.96,2.97
				c3.77-0.32,2.93,3.63,4.01,6.81c5.11-7.84,12.19-5.26,19.22-5.22c5.13,0.03,10.05,0.54,15.06,1.2c2.83,0.38,5.05,1.22,6.37,3.99
				c3.42,7.18,5.49,8.37,12.75,5.59c5.27-2.02,9.43-2.2,13.29,2.45c2.29,2.75,4.96,2.53,7.86,0.28c6.86-5.34,8.04-5.26,15.13-0.07
				c0.84,0.61,1.61,1.24,2.67,1.06C1060.76,1445.97,1064.61,1449.68,1068.64,1453.67z"/>
			<path onClick={() => props.onFill(62)} fill={props.fillColors[62]} d="M659.29,486.19c6.74,0.08,2.14-6.18,4.43-7.81c10.88,4.43,18.39,12.71,25.04,21.15
				c3.83-1.22,3.01-4.8,5.74-6.28c1.28,3.99-1.07,7.54-0.81,11.21c0.21,3.03-1.1,6.51,4.19,6.48c2.45-0.01,1.85,2.7,2.05,4.42
				c1.46,12.65,5.49,24.9,5.1,37.64c8.62,0.92,11.8-2.39,10.8-10.55c-0.46-3.74-1.21-7.45-1-11.41c-0.17,17.27,8.2,31.53,14.86,46.41
				c11.25-4.47,9.46-13.73,9.14-22.59c4.06,0.96,3.59,3.87,3.95,6.26c0.36,2.38-0.62,5,1.36,7.24c3.84-1.03,5.04-4.61,7.31-7.33
				c4.51,2.59,8.86,5.39,12.05,9.69c1.35,1.82,3.28,3.14,5.42,3.31c9.66,0.75,14.57,7.96,19.77,14.54c3.06,3.88,5.3,8.4,7.81,13.1
				c-7.9-4.97-18.01,0.33-25.02-6.7c-3.32,3.89-10.01,4.33-14.72,1.82c-3.75-2-6.63-5.48-11.07-6.82
				c-4.98,12.12,5.91,18.7,9.88,28.24c-5.62-1.3-10.42-2.36-15.2-3.55c-0.92-0.23-2.29-0.79-2.47-1.47
				c-1.76-6.53-7.79-8.91-12.33-12.54c-7.76-6.19-13.9-14.31-23.11-18.82c-3.68-1.8-5.57-5.57-6.15-9.79
				c-0.72-5.27-1.5-10.53-2.26-15.8c-1.27-8.88-2.32-17.75-7.44-25.61c-2.44-3.75-3.21-8.36-3.55-12.88
				c-0.14-1.82-0.01-3.61,1.37-5.13c5.14-5.66,3.34-13.78-4.22-15.54C672.2,495.19,665.31,492.05,659.29,486.19z"/>
			<path onClick={() => props.onFill(63)} fill={props.fillColors[63]} d="M934.23,1426.96c-7.71-1.79-12.82-6.36-18.14-10.5c-5.3-4.12-9.57-9.26-13.52-14.78
				c0.29,4.69,3.64,7.59,6.17,11.84c-14.54,2.22-26.42-2.17-37.34-10.89c1.4-3.45,4.38-6.01,3.73-10.29c-2.46,3.88-5.5,7.52-7.2,11.7
				c-1.82,4.48,1.14,9.37,0.54,14.26c-7.43-3.32-10.05-11.1-6.31-17.72c1.85-3.26,3.41-6.66,1.81-11.63
				c-1.82,4.38-1.5,8.73-4.93,11.31c-10.05-6.22-15.14-14.55-12.44-26.77c1.11-5.01,0.82-10.19-0.76-15.24
				c4.61-2.3,13.02,1.22,11.91-8.3c-1-0.21-1.58,0.1-1.79,0.79c-1.36,4.53-5.26,4.72-8.51,3.87c-2.83-0.74-4.26-3.82-4.15-7.03
				c0.06-1.59-0.17-2.99-1.46-4.02c-3.59-2.87-1.21-5.59,1.09-6.68c6.67-3.18,12.88-7.53,20.57-8.35
				c7.94-0.84,15.74,4.22,17.38,11.59c1.69,7.58-2.27,11.99-10.29,11.44c-0.29-1.44,1.09-1.38,1.76-1.93
				c4.38-3.64,3.98-8.89-1.01-11.92c-2.76-1.67-6.01-2.61-8.47-6.58c0.66,6.15-3.7,3.89-6.63,5.16c2.8,2.77,5.81,0.88,8.47,1.24
				c-2.6,2.68-2.76,2.72-10.46,2.38c4.61,2.32,4.51,6.26,5.42,9.75c0.78,2.98,2.15,5.84,4.87,7.29c12.23,6.49,23.5,15.73,38.04,16.28
				c11.46,0.44,17.88,7.63,24.27,15.39c2.47,2.99,4.02,6.74,2.23,10.2c-4.51,8.69-1.31,16.07,2.87,23.51
				C932.66,1423.62,933.25,1424.97,934.23,1426.96z M853.63,1375.11c-0.56,5.92,3.65,11.25,9.44,12.07
				C859.1,1383.5,854.92,1380.47,853.63,1375.11z M852.8,1342.64c-2.25-1.45-4.05-1.6-5.29,0.63c-0.58,1.04,0.22,1.96,1.16,2.35
				C851.26,1346.73,852.13,1345.07,852.8,1342.64z"/>
			<path onClick={() => props.onFill(64)} fill={props.fillColors[64]} d="M349.18,1184.85c-2.07-1.74-3.14-4.1-2.66-6.64c1.1-5.79-1.2-10.93-2.52-16.29
				c-0.47-1.92-1-3.48,0.16-5.89c2.03-4.23-2.69-5.04-5.47-6.76c0.01-5.31,3.29-9.62,4.63-14.98c-3.2-0.06-4.92-2.64-6.94-4.51
				c-1.76-1.63-3.18-1.73-5.23-1.29c-3.26,0.69-5.32,3.75-8.89,4.07c-1.42,0.12-1.98,2.46-1.96,4.24c0.1,8.8-5.35,15.69-7.92,23.65
				c-1.49-5.81-0.79-11.44,0.14-17.05c1.34-8.12,3.74-15.84,8.8-22.52c7.82-10.32,20.95-9.4,27.68,1.73
				c1.46,2.41-0.87,3.14-1.15,5.33c6.03-2.91,11.92-3.88,17.79-0.37c0.53-2.49-2.24-2.27-2.14-3.92c12.9-17.49,31.11-16.18,42.48-7.9
				c6.08,4.43,9.21,10.77,11.18,17.83c1.93,6.93,2.96,13.99,2.92,21.18c-0.01,1.57,0.53,3.38-1.32,4.59
				c-2.49-1.14-2.53-3.71-3.17-5.82c-1.45-4.77-2.38-9.71-4.08-14.38c-3.09-8.5-9.78-12.89-18.4-14.1
				c-7.22-1.02-11.04,1.92-14.33,9.84c7.54,1.49,14.69-1.11,22.14-2.57c-1.96,3.64-3.14,7.09-4.93,10.22
				c-3.26,5.69-11.02,6.07-14.12,0.74c1.96-0.65,4.4,0.03,6.11-2.57c-3.22,0.3-5.06-5.29-9.23-0.61c6.52,11.36-1.39,22.4-1.89,33.92
				c-0.23,5.17-3.22,9.35-8.24,11.68c-1.45,0.67-3.15,1.27-3.33,3.31c0,0,0.09-0.08,0.09-0.08c-2.61,0.31-4.7,2.32-7.5,2.18
				c-3.01-0.15-5.61-0.67-6.43-4.11c4.84,2.7,7.67-0.94,10.95-3.36C358.1,1185.64,353.53,1184.2,349.18,1184.85z M378.6,1150.78
				c-0.28-1.79-0.52-3.9-2.96-3.75c-3.63,0.22-4.94,3-5.36,6.1c-0.29,2.19,1.55,2.84,3.26,2.76
				C376.6,1155.74,378.26,1153.9,378.6,1150.78z M348.75,1176.67c1.91,2.2,3.68,4.23,6.01,6.92c0.1-4.2,4.6-4.7,4.89-8.32
				c-2.84-0.78-4.02,1.89-6.05,2.66C352.07,1177.82,351.24,1175.52,348.75,1176.67z M365.61,1179.73c0.98-6.47-1.06-9.91-7.51-12.06
				C362.9,1170.87,364.11,1174.92,365.61,1179.73z M372.09,1164.66c-2.09,1.99-4.33,4.01-4.55,7.75
				C370.4,1170.2,371.14,1167.4,372.09,1164.66z"/>
			<path onClick={() => props.onFill(65)} fill={props.fillColors[65]} d="M173.92,1166.21c-3.02,7.49-4.37,15.26-8.31,22.05c-0.67,1.15-1.19,3.09-2.24,3.03
				c-5.95-0.38-7.75,5.64-12.16,7.56c-1.53,0.67-2.74,1.91-3.15,3.67c-0.81,3.47-2.71,5.45-6.54,5.61c-2.17,0.09-3.24,2.11-4.26,3.85
				c-1.09,1.86-2.09,3.81-4.59,4.65c-5-2.68-8.59-8.37-15.31-7.7c0.27-2.82,2.02-3.18,3.66-3.32c3.84-0.33,6.34-3.03,8.74-5.36
				c2.71-2.64,1.01-6.12-1.87-6.68c-8.69-1.68-13.53-10.76-22.92-11.16c-3.08-0.13-3.09-3.27-0.7-5.12c2.59-2,5.56-3.52,8.34-5.28
				c1.6-1.02,3.73-1.59,3.84-4.47c-7.27-1.13-12.44-6.84-20.94-7.62c4.34-4.49,5.17-8.95,9.23-11.67c3.73-2.5,2.07-11.13-1.95-13.61
				c-1.27-0.78-2.64-1.46-2.92-3.29c2.36-3.23,4.73-2.95,8.29-1.11c10.17,5.26,20.52,10.2,31.03,14.74
				C151.78,1150.41,163.59,1156.93,173.92,1166.21z"/>
			<path onClick={() => props.onFill(66)} fill={props.fillColors[66]} d="M891.82,765.99c-3.6,1.93-6.68,1.83-9.68-0.73c-2.7-2.31-5.75-2.58-8.7-0.46
				c-2.25,1.61-4.15,1.14-6.42-0.08c-7.21-3.89-8.14-3.78-15,0.46c-1.12-0.14-1.66-1.02-2.24-1.82c-4.8-6.68-8.74-8.33-16.47-6.17
				c-2.3,0.64-4.11,0.72-6.07-0.54c-0.54-0.35-1.35-0.81-1.83-0.63c-9.11,3.24-19.06-3.81-27.95,2.27c-1,0.68-2.54,0.75-3.1-0.48
				c-2.63-5.78-7.58-6.31-13.15-5.62c-1.45-12.11-1.36-23.88-1.18-35.65c0.06-3.65,0.4-7.33-0.6-10.92
				c-1.29-4.69-3.4-8.65-8.56-10.13c-1.59-0.46-3-1.36-3.59-3.81c8.89,1.64,17.74-2.66,26.4,1.5c2.54,1.22,5.23,2.12,7.55,3.76
				c3.31,2.33,5.92,4.6,1.16,8.29c-2.76,2.14-1.35,4.85,0.31,7.08c1.95,2.62,4.9,3.88,7.74,5.29c8.49,4.2,16.96,8.43,24.94,13.58
				c3.34,2.16,6.44,4.33,8.58,8.02c2.33,4.02,6.95,5.38,11.07,7.23c12.09,5.43,24.85,9.59,35.24,18.31
				C890.75,765.11,891.24,765.51,891.82,765.99z"/>
			<path onClick={() => props.onFill(67)} fill={props.fillColors[67]} d="M706.33,1461.97c-7.73-4.84-15.23-9.15-22.32-14.06c-15.01-10.38-32.01-16.86-48.06-25.19
				c-2.1-1.09-3.63-2-4.26-4.77c-0.87-3.77-2.65-7.42-4.58-10.82c-1.85-3.28-5.66-3.69-9.39-4.8c1.33-3.34,0.11-5.8-2.6-8.06
				c-5-4.17-6.14-10.02-6-16.17c0.09-3.95,0.47-7.89,0.71-11.78c2.89-0.86,3.47,1.37,4.66,2.9c3.31,4.26,5.96,9.51,12.72,8.91
				c0.6-0.05,1.52,0.19,1.83,0.62c3.35,4.63,8.71,5.59,13.38,7.96c3.19,1.62,6.46,2.2,9.56,3.45c6.89,2.78,14.88,4.52,17.87,12.91
				c0.87,2.44,2.63,3.83,4.91,5.23c4.76,2.92,9.1,6.51,14.33,8.71c1.9,0.8,1.7,2.43,1.37,4.24c-1.42,8.04-1.34,8.02,5.8,10.99
				c3.68,1.53,6.51,3.73,7.21,8.13c0.55,3.42,1.9,6.59,4.03,9.53C710.37,1453.84,708.28,1457.79,706.33,1461.97z"/>
			<path onClick={() => props.onFill(68)} fill={props.fillColors[68]} d="M322.11,1142.42c3.72,5.89,8.34,6.38,13.79,5.3c-0.31,4.25,1.99,7,4.03,10.26
				c3.23,5.15,4.03,11.56,4.18,17.71c0.26,10.3,6.23,17.31,16.84,18.76c1.43,13.79,7.86,25.32,16.88,35.76
				c2.96,3.43,4.75,7.87,7.86,13.2c-9.35-1.32-17.47-2.26-25.31,0.58c-1.78-7.75-2.23-6.44-9.78-9.05c-5-1.73-10.62-1.29-15.39-4.92
				c-4.14-3.14-10.41-3.09-14.22-7.53c-0.51-0.59-1.47-0.79-2.97-1.54c4.23-1.96,7.89-1.78,10.31-5.2
				c-5.16-3.38-11.72-4.44-16.28-9.17c3.93-3.95,8.38,0.84,12.58-1.18c-2-6.14-8.21-7.76-13.33-12.56
				c5.59,0.97,10.12,2.46,13.53-1.95c0.03-5.26-4.71-6.57-8.24-8.84c-0.79-1.86,2.12-1.53,1.49-3.53c-1.33-1.27-2.97-2.84-5.37-5.14
				c7.86,0.59,5.91-8.07,11.67-9.08c-2.28-3.66-6.13-2.43-8.5-4.87C317.8,1154.16,319.77,1148.8,322.11,1142.42z"/>
			<path onClick={() => props.onFill(69)} fill={props.fillColors[69]} d="M921.75,661.04c2.4-10.84,2.2-20.39,4.12-29.71c1.26-6.09,5.83-9.12,9.83-13.31
				c2.66,3.06,1.93,6.73,3.36,9.61c0.8,1.6,1.29,3.76,3.5,3.75c2-0.01,2.5-2.09,3.06-3.54c1.61-4.18,2.86-8.49,4.45-12.68
				c1.64-4.31,3.54-8.49,9.44-7.64c0.31,0.05,0.66-0.07,0.98-0.17c2.17-0.63,4.11-3.46,6.28-2.17c2.69,1.6,3.85,4.96,4.95,7.82
				c1.87,4.86,4.81,8.67,8.05,12.76c4.05,5.11,8.31,10.64,6.46,19.2c-2.26,10.47-3.75,21.24-5.41,31.91
				c-0.25,1.62-0.23,3.24-2.42,3.82c-2.27,0.6-2.86-0.98-3.84-2.3c-3.87-5.2-7.25-6.7-13.36-4.62c-5.32,1.8-9.13,1.69-11.79-4.04
				c-1.41-3.03-4.15-4.73-7.86-3.69c-0.62,0.17-1.57,0.32-1.95-0.01C934.69,661.73,928.77,660.3,921.75,661.04z"/>
			<path onClick={() => props.onFill(70)} fill={props.fillColors[70]} d="M577.25,783.81c-4.85-5.88-2.88-12.48-4.48-17.88c-1.07-1.24-1.94-0.29-2.85-0.24
				c-3.98,0.2-6.73-1.02-7-5.72c-0.32-5.64-1.16-11.25-1.6-16.88c-0.46-5.91,0.43-11.98-2.87-17.49c-0.78-1.3-0.72-3.28-0.59-4.9
				c1.32-16.15,0.32-32.26-0.61-48.38c-0.1-1.79-0.09-3.21,1.68-4.68c3.09-2.57,4.09-6.68,4.09-10.4c0-4.9,2.01-8.51,4.87-12.06
				c1.22-1.52,2.06-3.35,3.72-6.12c0.63,6.04,4.22,8.84,8.38,11.53c3.23,2.09,3.61,6.19,3.91,9.86c0.24,2.83,1.07,5.17,3.71,6.62
				c2.52,1.39,2.87,3.83,3.08,6.41c0.77,9.27-0.46,18.68,2.02,27.82c0.28,1.03,0.03,1.97-0.74,2.75c-4.14,4.19-6.74,9.4-9.88,14.29
				c-2.64,4.12-3.3,8.15-2.51,12.78c1.71,10,3.27,20.02,3.45,30.2c0.04,2.36-0.25,4.39-1.73,6.51
				C578.25,772.19,579.58,777.87,577.25,783.81z"/>
			<path onClick={() => props.onFill(71)} fill={props.fillColors[71]} d="M831.44,618.49c-16.05,2.8-27.71,13.23-40.85,21.97c4.94,4.08,9.37,0.7,13.84-0.17
				c-2.01,5.81-3.77,7.96-8.66,9.66c-4.27,1.49-8.07,3.75-11.69,5.74c-0.57,2.44,1.55,2.76,2.19,4.23c-5.38,6.86-12.66,9-22.24,8.17
				c0-4.59-0.04-9.32,0.01-14.05c0.07-7.02,0.1-14-3.41-20.43c-0.45-0.82-0.67-1.99-0.5-2.9c1.88-9.88-4.02-16.78-9.11-23.96
				c-2.52-3.55-4.81-7.09-3.25-12.39c5.7,4.46,11.53,7.81,18.67,7.77c2.27-0.01,4.77,0.93,6.37-1.54c0.99-1.53,2.02-0.64,2.99-0.21
				c3.9,1.76,7.79,2.34,12.12,1.49c3.35-0.66,6.09,2.28,9.35,2.9c1.28,0.24,2.48,1.01,4.63-0.01c-2.99-5.79-5.99-11.55-8.93-17.34
				c-3.14-6.17-9.63-9.02-13.79-14.27c5.79,2.03,11.99,3.92,15.33,9.34c8.2,13.32,19.44,23.25,32.6,31.3c1.4,0.86,2.6,2.05,3.89,3.08
				C831.15,617.41,831.29,617.95,831.44,618.49z"/>
			<path onClick={() => props.onFill(72)} fill={props.fillColors[72]} d="M691.84,567.04c-1.53-2.53-3.25-4.97-4.53-7.63c-1.93-3.98-4.36-7.34-8.15-9.84
				c-3.23-2.13-4.72-5.47-4.77-9.41c-0.11-7.99-0.38-15.98-0.35-23.98c0.02-5.27-1.85-9.34-6.26-12.28
				c-1.23-0.82-2.34-1.87-3.35-2.96c-0.99-1.07-1.71-2.91-3.48-2.02c-1.93,0.96-1.15,2.97-0.75,4.47c1.08,4.01,2.24,8.01,3.63,11.92
				c2.32,6.51-1.15,13.15,1.41,19.53c-1.83,0.29-2.24-0.59-2.55-1.28c-6.26-13.88-17.89-23.42-27.79-34.34
				c-1.86-2.05-2.86-4.43-3.81-6.92c-2.01-5.26-4.2-10.37-10.69-10.93c-1.94-19.98-4.7-25.88-18.24-38.88
				c-1.55,7.12,1.98,13.47,2.02,20.1c-7.7,0.02-10.1-1.48-11.83-8.48c-1.61-6.51-3.67-12.71-7.84-17.92
				c-10.22,3.31-2.46,10.48-3.52,15.92c-3.91-5.05-3.01-12.49-9.34-16.85c-2.87,5.85-5.53,11.28-8.31,16.95
				c-4.28-1.79-8.41-1.21-13.91-1.01c4.04-7.13,2.67-13.97,0.87-21.33c6.95,0.68,13.38,1.17,19.76,2c2.9,0.37,5.84,0.62,8.3-0.75
				c4.79-2.68,9.97-2.23,15.05-2.68c1.18-0.1,2.38-0.13,3.2,0.94c5.85,7.58,14.65,12.42,18.97,21.55c2.49,5.27,7.28,8.93,12.78,11.43
				c6.8,3.09,10.47,9.6,15.36,14.73c7.76,8.13,17.13,14.1,26.09,20.65c1.68,1.23,3.53,0.59,5.32,0.69c5.79,0.32,8.65,2.64,9.39,7.59
				c0.23,1.55,0.22,2.8-1.2,3.97c-3.16,2.61-3.61,6.05-2.84,9.96c1.03,5.27,2.31,10.41,5.28,14.95c4.79,7.32,4.2,15.95,5.9,24.02
				c0.54,2.57,0.74,5.22,1.1,7.83C692.47,566.85,692.16,566.94,691.84,567.04z"/>
			<path onClick={() => props.onFill(73)} fill={props.fillColors[73]} d="M678.88,713.32c-0.75-0.09-1.5-0.74-1.59-1.49c-0.76-6.55-5.72-11.61-6.55-18.02
				c-1.22-9.41-5.36-17.29-11.31-24.45c-1.04-1.24-1.63-2.87-2.35-4.36c-1.66-3.46-4.58-3.92-7.97-3.54
				c-3.31,0.37-5.65,1.65-6.11,5.33c-0.37,2.95-0.96,5.88-1.49,9.02c-2.96,0.62-4.42-0.37-4.31-3.46
				c0.45-12.27-1.43-24.29-5.12-35.88c-1.93-6.08,1.19-10.17,4.69-12.78c6.83-5.1,6.5-12.39,7.18-19.07
				c1.18-11.57,5.44-21.73,11.39-31.41c0.76-1.24,1.17-2.73,3.31-2.15c2.07,0.56,3.86,1.23,4.18,3.6c0.43,3.15,0.27,6.25-0.59,9.38
				c-2.16,7.9-2.62,15.32,2.93,22.64c5.56,7.33,6.69,16.59,7.44,25.55c1.03,12.38,0.93,24.77,5.08,36.85
				c2.52,7.35,1.12,15.52,1.18,23.33C678.91,699.34,678.88,706.28,678.88,713.32z"/>
			<path onClick={() => props.onFill(74)} fill={props.fillColors[74]} d="M777.65,1484.01c-3.13,0.28-6.08,0.69-8.74,2.01c-1.8,0.89-3.15,0.28-4.67-0.5
				c-4.43-2.28-8.83-4.64-13.36-6.7c-4.94-2.26-9.81-4.09-15.65-3.95c-9.09,0.22-16.91-4.18-24.26-9.34
				c-2.32-1.63-1.57-3.49-0.94-5.25c1.81-5.07,1.57-9.62-1.98-14.01c-1.26-1.56-1.74-3.95-2.08-6.04c-0.9-5.44-4.36-8.89-9.2-10.12
				c-4.84-1.22-5.49-3.98-4.12-7.62c1.76-4.65-0.34-7.28-4.17-8.47c-5.58-1.74-8.98-6.77-14.33-8.74c-1.26-0.46-2.04-1.87-2.19-3.19
				c-0.58-5.15-4.87-6.25-8.34-8.81c-7.92-5.85-17.69-6.62-26.07-10.95c-1.48-0.77-3.17-1.28-4.11-2.7
				c-1.92-2.89-4.43-4.09-7.94-4.17c-4.75-0.1-6.12-4.64-8.57-7.6c-1.48-1.79-2.39-4.2-5.12-4.2c-3.39-0.01-2.62-2.09-2.31-4.37
				c2.06,0.26,4.03,0.7,5.09,2.49c3.11,5.27,8.13,6.02,13.39,5.49c3.75-0.38,5.79,1.12,7.75,4.04c3.95,5.87,9.71,8.92,16.73,9.67
				c3.76,0.4,7.43,1.28,11.3,1.25c3.47-0.03,4.31,3.1,4.78,6.03c1.32,8.13,3.22,9.54,11.29,8.33c5.95-0.9,9.39,0.74,12.25,6.15
				c1.51,2.87,2.98,5.51,6.72,5.79c1.14,0.09,2.29,0.83,2.69,2.01c2.07,6.12,7.63,8.54,12.14,12.3c5.56,4.63,11.7,9.61,10.45,18.41
				c-0.23,1.65,0.52,2.59,2.21,2.5c5.46-0.29,10.21,1.71,14.72,4.51c1.85,1.14,3.88,0.66,5.84,0.63c7.24-0.09,10.57,1.85,12.86,8.54
				c1.08,3.16,2.8,5.07,5.77,6.27c4.19,1.69,6.49,4.23,6.33,9.44C771.7,1477.12,773.7,1481.19,777.65,1484.01z"/>
			<path onClick={() => props.onFill(75)} fill={props.fillColors[75]} d="M67.08,1389.93c-5.28,1.91-5.9,3.72-3.16,7.99c2.24,3.5,5.22,6.43,4.6,11.5
				c-0.29,2.38,2.58,5.16,4.08,7.88c-3.14,3.8-6.14,2.35-9.12,0.13c-7.14-5.3-7.14-5.32-14.75-0.54c-1.12,0.7-2.16,0.61-3.31,0.22
				c-1.81-0.62-3.14-1.75-4.17-3.36c-7.92-12.31-9.71-12.35-17.79-0.7c-1.3,1.88-3.96,3.64-5.47,2.15
				c-4.95-4.88-9.98-3.86-15.74-0.71c0-21.17,0-41.59,0-62.46c4.3,1.43,7.97,2.82,9.52,7.38c1.13,3.33,3.31,4.44,7.03,3.11
				c3.79-1.36,7.71-1.95,9.75,3.35c0.94,2.44,3.72,3.12,6.34,3.26c2.15,0.11,4.31,0.14,6.43,0.43c3.73,0.5,5.75,2.23,5.84,6.52
				c0.13,6.05,3.92,8.33,8.81,5.95C65.42,1377.46,67.73,1379.03,67.08,1389.93z"/>
			<path onClick={() => props.onFill(76)} fill={props.fillColors[76]} d="M718.26,1325.67c6.12-0.62,11.36,1.82,16.21,5.04c9.13,6.07,18.1,12.39,27.13,18.63
				c3.13,2.16,5.11,5.23,6.3,8.76c3.82,11.35,11.52,19.07,21.91,24.71c10.36,5.62,20.39,11.86,30.59,17.78
				c11.52,6.69,17.42,17.97,23.72,28.92c1.45,2.52,0.14,3.74-2.31,4.08c-1.66,0.23-3.38,0.19-4.89-0.62
				c-6.82-3.66-14.73-5.2-20.87-10.09c-5.33-4.25-8.48-10.55-13.19-15.44c-0.53-0.55-0.95-1.2-2.17-2.76
				c-0.57,6.13,2.78,9.17,5.94,13.3c-5.34-3.22-9.91-6.14-15.27-7.34c-1.66-0.37-3.5-1.36-3.47-3.16c0.13-7.33-9.67-12.12-3.44-20.9
				c-5.31,0.49-5.42,3.33-5.98,5.81c-5.3-3.07-5.89-5.64-3.6-15.7c-3.26-0.88-3.26-0.88-7.54,2.78c-3.36-3.34-4.19-8.85-9.03-10.89
				c-0.51-0.22-1.22,0.04-1.78,0.08c-1.01,2.51,2.2,3.45,1.57,5.79c-5.19,0.19-9.2-2.37-12.46-6.02c-2.23-2.5-4.76-3.9-7.94-4.68
				c-5.39-1.32-12.94-11-12.16-16.41c0.44-3.05-0.54-4.26-2.75-5.48c-1.01-0.56-1.93-1.3-2.85-2c-5.74-4.41-6.01-5.72-2.56-11.99
				c0.4-0.72,0.67-1.51,0.99-2.27L718.26,1325.67z"/>
			<path onClick={() => props.onFill(77)} fill={props.fillColors[77]} d="M955.25,559.25c-3.2,4.45-5.85,5.63-10.42,4.21c-7.64-2.37-15.5-3.55-23.2,0.78
				c-3.5,1.97-7.73,1.76-11.72,1.9c-8.11,0.29-16.23,0.25-23.8,4.03c-2.99,1.49-6.26,0.28-9.26-0.57
				c-7.26-2.05-14.55-3.06-22.14-2.51c-7.94,0.57-15.24-3-22.91-4.33c-2.1-0.36-3.7-2.26-5.01-3.97
				c-4.26-5.58-9.58-10.16-14.23-15.35c-3.4-3.8-7.47-6.37-12.43-8.31c12.72-7.95,25.7-6.77,37.6-1.29
				c13.17,6.06,27.2,8.59,40.86,12.43c10.24,2.87,21.34,2.56,32,4.12C925.39,552.53,939.43,558.27,955.25,559.25z"/>
			<path onClick={() => props.onFill(78)} fill={props.fillColors[78]} d="M241.8,1054.53c-4.96-2.46-9.65-4.6-13.33-8.4c1.84-3.98,6.95-0.05,9.22-4.37
				c-6.73-4.24-10.87-10.73-14.24-18.96c-4.6,7.24-8.93,13.37-17.76,15.74c3.98,2.41,6.7,4.05,9.37,5.67
				c-1.35,3.47-5.99,2.98-7.35,6.25c1.67,3.1,5.18,0.51,7.52,2.83c-3.75,2-7.3,3.9-11.05,5.9c-5.36-20.64,0.27-40.41,1.53-61.53
				c3.22,4.06,5.53,7.22,8.13,10.12c1.12,1.26,2.6,3.17,4.59,2.1c2.05-1.11,1.02-3.22,0.66-4.97c-1.98-9.52-5.05-18.83-5.92-30.12
				c11.32,22.9,17.27,46.83,32.52,66.12c0.38,0.48,1.02,0.75,2.58,1.86c0.62-11.62-2.34-21.97-2.99-32.66
				c1.63,4.67,3.25,9.34,4.88,14.01c1.65,4.74,2.94,9.67,7.28,14.6c-0.56-12.19-2.95-22.67-6.2-33.03
				c5.31,12.67,10.41,25.43,16,37.97c3.9,8.73,6.17,17.9,8.19,27.12c0.63,2.89,0.61,5.03,4.55,4.63c-1.74,4.96-5.39,7.43-9.44,9.56
				c0.74,3.49,3.74,2.83,6.16,3.66c-1.8,2.63-2.9,5.58-6.53,6.21c-0.31-2.08,2-1.07,2.16-2.88c-2.19-2.09-4.34-4.69-4.73-8.35
				c0.87-0.79,2.56-0.24,3.52-1.86c0.35-4.87-5.65-4.79-7.25-8.71c1.33-0.23,2.78-0.19,3.95-0.74c2.19-1.02,3.06-3.09,1.08-4.72
				c-3.83-3.15-6.06-7.32-8.56-13.33c-2.26,6.67-5.49,10.82-9.09,14.87c-1.82,2.04-3.17,6.18,0.97,8.18
				c-0.37,3.19-3.02,3.94-4.41,5.73c2.45,6.26-1.47,9.72-5.74,13.15c-2.33-1.27-4.11-3.15-5.93-5.65c2.85-0.76,5.59,1.85,8.01-0.05
				c0.25-2.36-2.45-2.27-2.79-3.97c0.68-1.45,3-0.57,3.57-2.76c-3.02-3.35-9.02-3.24-11.01-8.61c2.69-1,5.6,0.74,8.52-0.73
				c-0.15-1.77-0.44-3.81,1.88-5.72c-6.8-2.12-12.19-5.16-15.91-11.18C232.73,1056.06,238.18,1060.58,241.8,1054.53z"/>
			<path onClick={() => props.onFill(79)} fill={props.fillColors[79]} d="M633.14,1423.83c18.25,9.37,36.86,16.89,53.46,28.38c9.96,6.89,20.26,13.31,30.57,19.7
				c6.2,3.84,13.09,5.58,20.45,5.14c1.15-0.07,2.38-0.13,3.45,0.2c8.27,2.5,15.75,6.72,23.33,10.31c-0.14,1.29-0.62,1.99-1.24,1.83
				c-9.3-2.29-19.01-0.29-28.31-2.8c-2.92-0.79-5.92,0.5-8.75,1.46c-2.18,0.74-4.33,1-6.23-0.28c-6.83-4.57-14.47-5.12-22.39-6.41
				c-12.47-2.02-25.05-4.91-36.75-10.38c-9.02-4.22-17.39-9.72-26.83-13.08c-2.83-1.01-3.67-2.66-1.36-5.21
				c2.62-2.89,2.88-5.63,0.76-9.4c-1.71-3.04-3.98-7.37,1.35-10.22c1.6-0.85,1.82-2.64,0.63-4.35
				C634.43,1427.52,633.2,1426.5,633.14,1423.83z"/>
			<path onClick={() => props.onFill(80)} fill={props.fillColors[80]} d="M19.73,1163.1c-2.29,4.51-5.01,6.26-9.41,3.78c-2.44-1.37-5.26,0.8-8.13-0.65c0-30.98,0-62.18,0-93.73
				c17.63,3.16,33.2,11.06,49.08,17.8c-0.51,6.53-1.74,11.15-8.14,12.91c-4.97,1.37-6.17,4.56-4.17,9.37
				c0.94,2.27,2.36,4.33,3.72,6.79c-2.92,3.08-7.44,3.37-10.3,6.56c-2.8,3.13-3.59,7-5.71,10.76c-0.55,0-1.41,0.21-2.12-0.04
				c-2.67-0.93-5.29-1.43-7.2,1.26c-1.93,2.73-2.65,5.91-1.15,8.95c1.13,2.28,1.28,4.16,0.52,6.58
				C15.59,1157.09,17.34,1160.25,19.73,1163.1z"/>
			<path onClick={() => props.onFill(81)} fill={props.fillColors[81]} d="M606.6,1387.72c-4.11-3.14-8.16-4.04-12.68-1.84c-0.85-3.56,0.76-5.9,1.27-8.4
				c2.13-10.3,0.05-20.52-0.23-30.79c-0.45-16.32-10.09-28.9-16.26-42.88c-1.08-2.45-3.53-4.37-5.54-6.33
				c-5.92-5.78-10.41-12.54-13.64-20.09c-3.6-8.41-2.59-16.27,3.66-23.16c2.12-2.33,4.37-4.57,6.76-6.63
				c9.93-8.58,16.14-18.87,15.08-32.57c3.44-0.25,2.83,2.12,2.98,3.96c1.02,12.25-1.56,24.17-3.43,36.15
				c-0.51,3.28-0.91,6.59-1.46,9.86c-2.27,13.3,2.47,24.08,12.68,32.18c8.87,7.03,11.28,15.5,9.75,26.4
				c-1.93,13.72-0.72,27.58,0.81,41.3C607.18,1372.32,604.53,1379.72,606.6,1387.72z M574.28,1279.85c-0.31,0.28-0.61,0.57-0.92,0.85
				c4.16,8.05,9.9,14.94,16.59,20.96c5.49,4.94,9.47,10.61,11.36,17.77c0.26,0.96,0.14,2.36,1.74,2.91c1.3-1.75,0.37-3.56-0.07-5.12
				c-1.76-6.21-4.91-11.59-10-15.74c-5.58-4.55-10.27-9.93-14.45-15.78C577.13,1283.74,575.7,1281.8,574.28,1279.85z"/>
			<path onClick={() => props.onFill(82)} fill={props.fillColors[82]} d="M656.1,1498.59c-28.78,0-57.57-0.01-86.35,0.02c-1.59,0-2.9-0.56-4.2-1.33
				c-13.46-7.95-26.93-15.87-40.35-23.88c-3.14-1.88-6.1-4.05-8.36-7.03c-1.03-1.36-2.33-3.02-1.46-4.52
				c2.41-4.15-0.24-6.04-3.01-8.1c-2-1.49-2.83-3.57-2.91-5.9c1.85-1.85,3.58-0.96,5.23-0.28c14.72,6.11,30.03,11.15,44.02,18.63
				c24.41,13.07,51.31,17.87,77.38,25.38C642.87,1493.55,649.94,1494.33,656.1,1498.59z"/>
			<path onClick={() => props.onFill(83)} fill={props.fillColors[83]} d="M459.81,727.21c-3.67,8.21-11.35,13.76-4.95,23.05c-4.49,0.33-6.59-0.11-8.39-3.86
				c-3.7-7.7-9.22-14.27-17.59-17.29c-8.07-2.91-15.57-6.45-21.22-13.19c-2.14-2.55-4.63-4.75-8-5.72c-5.03-1.45-5.34-2.47-2.37-7.08
				c5.54,3.1,10.85-0.3,11.11-7.32c0.13-3.45-0.28-6.93-0.46-10.71c2.38-0.41,4.71,0.45,6.91-0.78c1.99,3.32,3.8,6.28,3.61,10.24
				c-0.2,4.14,3.62,7.23,7.66,5.92c1.76-0.57,3.39-0.23,5.29-0.56c0.06-7.43,0.87-14.68,2.31-21.89c1.77,0,1.46,1.59,1.66,2.49
				c2.27,10.19,7.29,19.56,8.92,29.94c0.41,2.62,1.86,4.08,4.6,4.38c3.41,0.38,6.91,0.51,10.44,3.56c1.65-8.18-0.32-15.09-0.8-22.7
				c1.06,1.35,1.78,2.27,2.46,3.13c2.21-0.49,0.93-2.39,1.97-3.6c3.08,2.69,5.74,6.56,11.07,4.67c0.65,9.05,2.93,17.2,4.69,25.46
				c0.88,4.11-0.89,6.17-5.18,5.95c-2.32-0.12-3.36,1.13-4.33,2.66c-1.28,2.01-1.77,4.36-1.8,6.67c-0.1,7.54,1.03,15.15-2.19,22.58
				C460.4,752.27,462.91,739.94,459.81,727.21z"/>
			<path onClick={() => props.onFill(84)} fill={props.fillColors[84]} d="M121.27,1038.76c-2.19,0.19-3.06-1.83-5.84-0.83c2.46,8,10.28,13.93,8.65,23.33
				c-1.13,0.15-2.47-2.93-5.24-1.89c0.21,4.59,4.2,6.77,6.53,10.46c-3.14-0.13-4.24,1.74-2.18,3.81c4.67,4.67,6.23,11.45,11.18,15.79
				c2.28,2,3.45,4.18,2.98,7.29c-5.07-1.1-8.94-5.1-14.6-7.12c1.02,5.87,5.23,8.49,8.48,13.54c-5.02-3.4-8.87-6-12.73-8.61
				c0.24,4.71,3.36,7.23,5.32,10.64c-4.83-4.15-9.67-8.3-14.79-12.7c-3.83,6.78-6.28-0.31-9.62-0.89c2.64,5.91,8.62,8.71,11.28,5.1
				c6.56,6.69,15.46,10.87,19.59,20.04c1.58,3.51,4.89,5.82,7.94,8.1c-0.31,1.6-0.59,3.1-0.89,4.65c-2.83-0.31-5.05-1.77-7.47-2.77
				c-8.29-3.41-17.4-5.66-19.14-16.92c-1.19,7.19,4.53,15.23,11.18,16.53c5.01,0.98,9.09,3.95,13.6,5.94c3.3,1.46,2.68,5.99,4.32,9.7
				c-10.49-4.83-20.27-9.33-30.06-13.83c-2.86-1.32-5.2-3.04-5.08-6.64c0.13-3.81-3.72-3.71-5.8-5.73c1.25-0.72,2.42-1.24,3.42-1.98
				c3.36-2.51,2.85-4.55-0.2-7.45c-4.01-3.81-7.05-8.56-7.81-14.24c-0.39-2.9-3.06-4.76-3-7.87c2.5-0.72,4.67,2.61,7.65,0.71
				c-1.36-3.13-5.06-3.98-6.17-7.05c1.09-1.02,2.5,1.06,3.77-0.7c0.47-1.92-2.13-2.44-2.65-4.5c2.25-3.9,2.51-8.95,6.47-12.75
				c1.3-1.25,3.97-5.53,1.04-9.25c-1.23-1.56,0.27-3.82,2.02-5.24c1.29-1.04,3.53-1.33,3.34-3.83c-2.14-1.14-3.64,1.31-5.79,0.83
				c3.9-8.48,9.25-16.58,7.61-27.66c1.51-2.22,4.44-5.02,5.2-9.39c-0.48,9.95,6.04,17.44,8.21,26.34
				C122.72,1034.56,120.87,1036.33,121.27,1038.76z M116.63,1083c3.44-2.62-0.16-7.2,3.03-10.42c0.92-0.92-1.31-1.84-2.65-1.98
				c-1.46-0.16-2.92-0.24-4.77-0.39c0.52-1.19,1.65-1.87,1.01-2.96c-1.28-1.07-3.43-0.03-4.24-1.45c-0.63-3.65,7.57-4.24,2.52-9
				c1.32-1.89,4.14-2.63,4.59-5.79c-3.39,0.1-5.82,3.6-9.51,1.49c0.87-1.32,1.61-2.55,2.46-3.69c2.43-3.29,5.17-6.41,2.97-11.02
				c-1.39-2.91-0.15-5.59,1.06-8.32c1.55-3.49,1.41-3.57,0.46-6.71c-0.96,6.91-9.47,12.45-3.44,20.23c-1.62,3.7-5.67,5.89-5.69,9.58
				c-0.01,3.06,2.99,5.5,4.84,8.03c-1.38,1.87-2.74,3.71-3.33,4.51c0.39,7.35,5.27,7.78,9.41,7.87c1.04,5.42-3.74,10.86,2.22,14.61
				c0.04,1.92-2.55,2.97-0.69,4.78C121.71,1088.04,121.71,1087.89,116.63,1083z"/>
			<path onClick={() => props.onFill(85)} fill={props.fillColors[85]} d="M726.5,1240.4c-0.49,14.69-4.26,28.61-4.06,43.05c0.07,4.99-4.03,9.95-9.79,12.04
				c-4.33,1.57-8.32,3.74-10.76,8.1c5.14-3.66,11.6-4.8,16.5-8.61c3.62-2.81,6.81-7.16,6.62-11.64c-0.52-12.29,2.61-24.13,3.34-36.24
				c0.48-7.98,0.77-7.96-4.94-11.71c0.23-2.04,2.9-2.21,3.28-4.39c-0.81-3.5-3.57-5.58-6.52-7.54c-3.04-2.03-4.32-4.15-0.44-6.76
				c2.32-1.56,3.58-3.79,3.51-6.77c-0.06-2.26,0.7-4.2,3.73-4.5c-1.83,4.59-1.03,9.27-1.27,13.83c-0.33,6.18,6.36,10.03,12.04,7.14
				c2.1-1.07,3.95-1.5,6.58-0.84c0.76,6.21-1.51,12.3-1.76,18.54c-0.17,4.2-0.59,8.25-3.61,12.02c-3.44,4.27-1.45,15.56,2.28,20
				c1.55,1.84,2.43,4.61,0.29,6.29c-4.26,3.35-6.99,8.55-12.55,10.35c-1.23,0.4-2.3,1.32-3.4,2.07c-3.64,2.49-5.93,5.23-1.06,9.2
				c-6.87,4.75,1.4,12.35-4.69,17.72c-8.78,1.38-18-6.58-28.1-1c-0.06-7.5,0.98-14.2,2.67-20.78c0.98-3.8,5.57-3.58,7.84-6.24
				c1.28-1.49,3.6-2.43,5.61-2.88c2.68-0.6,2.87-2.26,2.96-4.39c0.24-5.64-0.69-11.51,1.27-16.86c2.95-8.07,4.06-16.31,4.22-24.79
				c0.02-1.13,0.46-2.29,0.84-3.38c1.2-3.41,2.99-5.21,6.53-2.37C724.42,1239.66,725.45,1239.92,726.5,1240.4z"/>
			<path onClick={() => props.onFill(86)} fill={props.fillColors[86]} d="M13.7,1053.83c2.53-0.93,4.61-1.69,7.28-2.67c-2.8-2.51-5.78-0.19-7.89-1.83
				c2.55-4.74,12.46-4.05,9.75-12.77c-2.34-1.24-4.83,0.38-7.41-1.24c4.64-3.05,5.36-8.97,10.96-10.93c-2.23-2.29-4.3-0.78-6.3-2.01
				c4.48-3.39,10.97-5.14,10.57-12.36c-0.12-2.11-2.23-1.56-3.73-2.53c7.61-3.95,8.61-12.23,13.14-18.65
				c-0.46,7.75,4.37,13.35,8.82,19.71c-3.75,0.09-5.44-2.73-8.16-1.13c0.3,3.86,3.61,5.63,5.73,8.2c-0.34,3.47-2.52,3.47-5.86,2.34
				c4.01,4.13,2.73,9.7,6.24,13.28c-1.65,1.28-3-1.34-5.02,0.92c7.34,2.69,9.51,10.77,16.18,13.9c-0.07,2.3-1.16,3.74-2.21,5.37
				c-1.91-0.47-3.81-0.94-5.7-1.4c-2.17,2.77,1.04,4.19,1.4,7.03c-7.53-4.35-15.86-4.25-23.6-6.78c2.62,3.56,6.82,5.07,10.44,5.28
				c10.27,0.61,15.49,6.46,18.66,15.28c0.21,0.59,0.64,1.1,1.25,2.13c-4.36-0.35-7.52-3.74-11.45-3.17c-1.16,1.1,0.21,2.04-0.36,3.36
				c-4.1-3.06-8.64-0.1-13.04-0.33c-6.06-0.32-7.28-4.38-10.67-7.83c0.94,7.22,3.64,8.22,9.21,9.72c5.08,1.37,9.99,0.16,14.85,1.28
				c2.28,0.52,4.57,0.68,6.02-3.01c1.78,5.51,4.9,8.96,7.72,12.57c-3.71,6.13-5.06,6.36-10.28,1.6c-3.19-2.9-7.29-3.96-11.13-5.38
				c-6.86-2.54-13.78-4.89-20.28-8.27c-4.63-2.4-10.28-0.99-14.85-4.08c1.66-2.85,6.39-2.65,6.38-7.39
				C10.34,1059.4,16.36,1058.61,13.7,1053.83z"/>
			<path onClick={() => props.onFill(87)} fill={props.fillColors[87]} d="M227.68,1192.69c5.02-2.09,10.15-2.42,14.77-2.87c4.79-0.47,9.92,2.78,15.19,0.26
				c0.4-0.19,1.9,1.26,2.46,2.2c3.4,5.66,9.86,4.29,14,0.39c5.01-4.72,12.44-4.62,16.88,0.39c1.22,1.37,2.28,3.03,4.17,3.42
				c3.02,0.63,6.29,0.81,4.81,5.39c-0.04,0.14-0.01,0.42,0.07,0.46c8.03,3.92,3.58,8.06,0.26,12.21c-0.14,0.18,0.06,0.63,0.11,0.95
				c0.68,1.15,1.72,0.87,2.64,0.55c3.61-1.26,5.9-1.48,4.65,3.76c-0.94,3.93,2.66,5.17,5.44,5.87c3.28,0.82,6.5,1.34,8.7,4.22
				c0.1,0.13,0.28,0.29,0.4,0.27c7.16-1.13,12.69,4.09,19.24,4.96c4.73,0.63,8.23,3.94,12.84,4.71c1.34,0.22,1.57,2.72,1.68,4.34
				c-0.92,1.67-2.52,1.85-3.95,1.57c-6.83-1.31-13.34-0.93-19.53,2.64c-1.88,1.08-3.78-0.02-5.67-0.82
				c-16.28-6.87-31.23-16.03-46.19-25.39c-13.35-8.35-28.12-14.38-40.8-24C236.4,1195.57,231.46,1196.07,227.68,1192.69z"/>
			<path onClick={() => props.onFill(88)} fill={props.fillColors[88]} d="M693.45,603.24c2.87,5.71,7.49,10.15,7.43,16.87c-6.9-7.84-11.02-17.69-18.21-25.82
				c0.29-2.82,2.02-5.88,0.82-9.86c4.43,1.69,7.98,3.38,11.77,4.45c3.33,0.93,4.72,4.06,5.55,7.19c1.9,7.13,6.62,12.91,9.26,19.75
				c2.08,5.4,6.2,6.27,11.47,3.31c-5.92-8.71-9.49-18.53-13.01-30.03c2.58,2.97,4.37,5.17,6.31,7.23c4.29,4.56,7.99,9.61,13,13.56
				c6.5,5.12,6.91,12.54,5.74,20.02c-0.92,5.91,0.19,11.47,1.17,17.25c2.12,12.5,7.91,24.37,6.08,37.61
				c-0.81,5.84,0.68,11.55,5.04,16.15c2,2.12,2.68,4.81,2.5,7.82c-0.24,3.87,1.13,7.39,4.27,9.66c2.62,1.9,3.26,4.14,3.31,7.15
				c0.16,8.65,0.54,17.3,0.94,25.94c0.11,2.39-0.27,3.87-2.94,4.87c-4.2,1.57-7.75,4.34-10.94,8.35c0-2.73,0.18-5.48-0.04-8.19
				c-0.33-4.05-0.25-8.11-4.83-10.6c-2.91-1.58-2.57-5.25-2.16-8.23c1.27-9.07,2.89-18.28,0.27-27.18
				c-3.77-12.76-2.45-26.12-5.52-38.89c-1.33-5.52,1.22-11.11-0.87-16.73c-3.01-8.08-5.54-16.35-6.12-25.08
				c-0.1-1.56-0.46-3.24-2.44-3.99c-7.74-2.94-13.86-7.64-16.98-15.69c-0.77-1.97-2.38-3.6-3.42-5.49
				C699.11,601.44,696.81,601.19,693.45,603.24z"/>
			<path onClick={() => props.onFill(89)} fill={props.fillColors[89]} d="M774.68,753.39c-3.18-0.96-5.39,0.11-7.39,2.13c-1.72,1.74-3.53,2.41-6.22,1.17
				c-2.86-1.32-2.15-3.3-2.1-5.36c0.25-9.33,0.03-18.64-0.57-27.95c-0.17-2.65-0.81-4.7-3.31-6.19c-2.91-1.73-4.45-4.65-4.06-8.04
				c0.45-3.97-0.84-7.33-3.32-10.06c-4.89-5.39-5.02-11.82-4.21-18.29c0.49-3.92,0.63-7.88-0.64-11.34
				c-2.95-8.03-3.6-16.55-6.09-24.65c-0.48-1.57-0.76-3.24-0.89-4.89c-0.55-7.31,1.11-14.57,0.03-22.37
				c3.65,0.79,6.67,1.44,11.26,2.43c-4.6-0.33-5.82,1.17-5.76,4.24c0.17,10.49,0.01,20.96,5.16,30.65c2.54,4.78,0.43,9.84-0.12,14.7
				c-1.34,11.79,1.98,22.03,8.67,31.68c3.55,5.13,7.57,10.1,7.49,17.28c-0.11,10.11,0.92,20.24,1.52,31.41
				c1.7-2.16,1.6-3.86,1.71-5.49c0.69-10.67-0.34-21.33-1.2-31.91c-0.4-4.88-4.59-8.93-7.57-12.96c-6.17-8.35-9.18-17.65-8.22-27.81
				c0.74-7.84,1.55-15.07-2.13-22.78c-3.81-7.99-3.05-17.16-2.62-25.9c6.1-1.09,6.53-0.69,6.86,5.1c0.15,2.54-0.56,5.22,0.85,8.24
				c3.47-3.44,0.86-8.19,3.16-10.77c4.82,10.43,5.16,21.8,4.73,33.29c-0.38,10.34,2.48,20.46,2.17,30.82
				c-0.14,4.67,2.84,7.94,6.88,9.53c4.74,1.87,7.07,5.27,6.9,9.9C775.09,723.69,776.52,738.19,774.68,753.39z"/>
			<path onClick={() => props.onFill(90)} fill={props.fillColors[90]} d="M156.87,1139.17c0.3-2.32,2.38-2.58,3.25-3.81c-0.04-1.9-1.71-1.13-2.87-1.55
				c0.64-0.56,0.95-1.06,1.36-1.16c3.94-0.99,7.8-2.7,12.01-1.26c0.76,0.26,1.65,0.51,2.39,0.35c6.55-1.4,6.54-1.43,12.21,2.3
				c3.19-3.92,8.83-3.07,12.62-6.39c1.72-1.51,4.12-0.73,5.95,0.81c2.85,2.39,5.78,2.33,8.55-0.03c3.13-2.66,6.53,0.18,9.46-0.56
				c4.54-1.13,5.11,1.97,6.98,4.44c1.75,2.31,4.18,4.31,6.69,5.8c6.02,3.58,6.11,3.43,9.21,7.69c3.96-1.69,5.9,1.07,9.14,3.03
				c5.96,3.63,6.2,10.39,11.25,14.08c-2.47,2.99-4.45,5.9-7.79,1.4c-0.43-0.57-1.57-1.11-2.2-0.95c-4.05,1.02-7.74,1.63-11.05-2.18
				c-0.87-1.01-2.85,1.03-4.16,2.03c-3.1,2.37-5.58,1.69-7.18-1.76c-1.29-2.77-2.78-3.65-6.18-3.16c-6.72,0.98-11.87-3.28-16.83-7.03
				c-5.59-4.23-10.72-4.39-15.32,0.58c-2.82,3.04-4.94,3.8-7.96,0.15c-2.07-2.51-4.94-3.61-7.32,0.16c-1.5,2.39-3.71,1.93-5.68,0.94
				c-1.34-0.68-2.57-1.56-4.12-1.56c-5.63,0.03-9.79-1.41-9.75-8.28C159.55,1141.94,157.84,1140.59,156.87,1139.17z"/>
			<path onClick={() => props.onFill(91)} fill={props.fillColors[91]} d="M696.53,508.07c-0.72-3.51,0.26-6.42,0.69-9.35c0.35-2.37,0.77-4.61-1.1-6.56
				c-1.54-1.6-0.89-3.28,0.45-4.32c1.61-1.24,3.5-1.02,4.82,0.76c1.68,2.27,3.34,4.56,4.85,6.94c5.05,7.93,12.29,13.44,20.14,18.23
				c4.34,2.64,8.02,5.81,10.6,10.28c0.97,1.68,2.37,3.38,4,4.35c6.72,4.03,10.84,10.04,14.17,16.92c3.93,8.13,9.58,15.14,15.31,22.07
				c1.02,1.24,2.47,2.25,2.72,4.77c-10.24-0.42-13.1-12.01-22.52-13.73c-1.9,1.07-2.04,4.62-5.24,6.13
				c-0.68-5.45-0.11-11.56-7.59-13.13c-4.15,7,3.53,17.59-6.93,22.82c-1.61-1.07-2.13-3.21-2.81-5.15c-1.74-5-3.85-9.71-6.62-14.34
				c-3.12-5.22-2.4-11.91-3.36-17.96c-0.44-2.79-0.64-5.62-1.91-8.2c-0.68-1.37-1.59-2.77-3.42-2.1c-1.54,0.56-1.51,1.96-1.26,3.38
				c0.72,4.09,1.55,8.17,2.02,12.29c0.73,6.34-1.1,8.9-6.05,8.66c-0.58-4.7-1.36-9.43-1.71-14.2c-0.57-7.8-3.99-15.09-3.75-23.03
				C702.14,510.05,699.66,508.37,696.53,508.07z"/>
			<path onClick={() => props.onFill(92)} fill={props.fillColors[92]} d="M718.36,1325.58c-6.19,3.45-8.2,9.67-3.16,13.06c6.86,4.62,8.32,11.8,11.43,18.23
				c2.43,5.02,5.62,8.51,11.32,9.38c2.15,0.33,3.97,1.29,5.48,3.09c3.89,4.67,8.82,7.53,15.08,7.62c1.23,0.02,2.31,0.22,3.02,1.34
				c2.29,3.62,4.58,7.24,6.34,11.57c-18.01-0.67-32.27-8.4-42.72-23.66c-0.43,0.52-0.87,1.04-1.3,1.56c1.7,3.26,3.4,6.52,5.31,10.18
				c-3.72-0.23-6.44-1.55-8.91-3.55c-3.88-3.15-7.57-6.57-11.73-9.3c-7.13-4.68-10.16-11.55-11.64-19.48
				c-0.18-0.98-0.53-1.94-0.65-2.92c-0.4-3.31-2.82-3.56-5.17-3c-2.53,0.6-2.37,2.87-1.92,4.91c0.91,4.07,4.51,6.98,5.1,11.24
				c-5.77,0.98-7.07-0.21-13.04-11.75c-0.32-0.06-0.71-0.26-0.96-0.15c-2.6,1.14-6.6,0.6-7.14,4.36c-0.51,3.55,2.36,5.65,5,7.47
				c6.09,4.2,13.03,6.57,19.93,8.97c3.09,1.07,6.14,2.17,8.76,4.36c-4.32-0.76-8.49-0.37-12.74,0.16c-4.49,0.56-8.52-1.18-11.97-4.04
				c-15.5-12.89-15.22-23.11,0.95-35.18c3.2-2.39,6.44-4.68,10.08-6.42c4.12-1.97,8.19-2.3,12.58-1.01
				c4.12,1.21,8.35,2.05,12.54,3.05C718.26,1325.67,718.36,1325.58,718.36,1325.58z"/>
			<path onClick={() => props.onFill(93)} fill={props.fillColors[93]} d="M1112.26,751.06c5.2-4.13,6.92-9.43,8.94-14.48c4.21-10.54,9.86-19.73,17.76-28.35
				c6.93-7.56,17.89-11,20.9-22.25c0.38,0.25,0.75,0.5,1.13,0.75c0,24.16,0,48.31,0,72.72
				C1144.01,760.97,1128.62,753.91,1112.26,751.06z"/>
			<path onClick={() => props.onFill(94)} fill={props.fillColors[94]} d="M631,1195.25c3.42,0.64,5.64,2.08,5.05,6.28c-0.24,1.69,0.05,4.09,1.1,5.23c5.7,6.2,4.71,13.42,3.79,20.74
				c-1.04,8.25-2.18,16.49-2.92,24.77c-0.72,8.12-1,16.27-1.48,24.64c-1.77,0.09-1.65-3.24-3.68-1.96c-1.51,0.95-2.11,2.6-2.56,4.17
				c-3.95,13.65-5.73,27.57-4.91,41.79c0.11,1.97,0.3,3.93,0.46,5.9c-7.34,0.29-7.43,0.03-7.75-6.64
				c-0.58-11.97-1.32-23.94-2.18-35.89c-0.47-6.54,0.27-12.72,2.2-19.1c2.37-7.84,1.16-16.21,0.6-24.33
				c-0.53-7.66,0-15.34-0.57-22.96c-0.56-7.52,4.56-11.61,8.38-16.62C628.03,1199.3,629.48,1197.3,631,1195.25z"/>
			<path onClick={() => props.onFill(95)} fill={props.fillColors[95]} d="M1153.04,1498.61c-3.27,0-6.24,0-9.21,0c-13.15,0-26.37-0.87-39.44,0.2c-19.85,1.63-38.14-3.54-56.3-10.24
				c-1.99-0.74-5.03-0.78-4.11-4.35c0.9-3.47,3.62-3.77,6.33-3.05c2.84,0.76,5.15,0.87,7.05-1.77c1.1-1.54,2.85-1.55,4.55-1.38
				c1.56,0.16,2.76-0.15,3.95-1.46c3.39-3.73,3.48-3.65,8.45-1.66c2.7-3.12,5.58-5.69,9.92-2.02c1.07,0.91,2.39-0.4,3.44-1.3
				c3.43-2.93,6.98-2.57,9.5,0.88c1.77,2.42,3.7,2.39,6.03,2.12c0.63-0.07,1.48-0.73,1.83-0.5c6.86,4.5,15.65,5.64,21.44,11.78
				c1.95,2.07,3.24,2.56,6.09,2.58c4.7,0.04,9.34-0.36,14-0.51C1151.01,1487.81,1153.24,1491.74,1153.04,1498.61z"/>
			<path onClick={() => props.onFill(96)} fill={props.fillColors[96]} d="M76.7,529.4c3.6-10.99,4.57-22.6,9.76-33.32c3.1-6.41,4.07-14.05,4.94-21.27c1.02-8.52,5-15.71,8.28-23.3
				c2.72-6.27,2.32-6.66-4.25-9.22c-0.71-0.28-1.69-0.14-1.86-1.26c0.26-0.14,0.52-0.4,0.77-0.4c13.46-0.05,26.92-0.18,40.38-0.07
				c6.11,0.05,7.39,2.1,4.67,7.39c-4.46,8.68-10.09,16.48-18.69,21.58c-3.99,2.36-5.99,6.25-7.98,9.99
				c-4.89,9.17-9.94,18.04-19.18,23.72c-2.58,1.58-3.38,4.86-4.83,7.45c-3.63,6.47-7.23,12.96-10.84,19.45
				C77.47,529.89,77.08,529.64,76.7,529.4z"/>
			<path onClick={() => props.onFill(97)} fill={props.fillColors[97]} d="M656.41,907.54c1.66-2.92-1.66-7.26,2.44-7.75c3.15-0.38,2.44,4.03,3.74,6.14
				c1.1,1.77,1.29,4.14,3.83,5.41c1.95-3.14,4.51-5.11,8.51-5.55c3.01-0.33,7.01-8.24,6.49-11.36c-1.08-6.51-2.17-13.02-3.26-19.58
				c3.05-0.5,2.72,1.54,3.11,2.77c2.62,8.23,5.18,16.49,7.75,24.74c1.14,3.68,2.81,7.09,4.87,10.34c3.76,5.94,6.06,6.93,13.1,5.1
				c1.23,5.82,2.46,11.62,3.71,17.54c-2.49,2.62-6.09,0.83-8.55,2.41c-3.2-2.11-3.07-6.2-6.56-8.67c-1.66,5.85,1.78,10.94,1.14,16.54
				c-2.81,0.01-4.11,2.5-6.09,4.78c-0.52-2.17-1.23-3.75-1.21-5.32c0.14-10-6.42-13.65-14.65-15.82c-4.27-1.12-11.05,1.1-13.18,4.95
				c-2.51,4.53-6.11,1.47-9.54,2.09c-1.36-6.19-2.52-12.29-6.37-17.64c-2.2-3.07,4.17-10.67,8.84-11.12
				C655.17,907.49,655.81,907.54,656.41,907.54z"/>
			<path onClick={() => props.onFill(98)} fill={props.fillColors[98]} d="M607.85,906.2c-5.29-8.86-4.74-12.25,3.25-19.63c1.15-1.06,1.85-2.33,2.39-3.74
				c1.11-2.9,2.71-4.87,6.3-4.24c1.78,0.31,3.73-0.63,3.85-2.43c0.73-11.43,7.54-20.76,10.51-31.37c2.81-10.02,4.74-20.28,7.14-30.41
				c1.67-7.03,10.18-17.27,16.94-20.5c1.81,1.08,2.2,3.16,3.11,4.83c3.53,6.47,8.62,11.43,14.9,15.07c4.53,2.63,7.53,6.29,7.77,11.41
				c0.41,8.79,7.02,13.85,11.28,20.36c4.05,6.18,10.68,11.05,10.04,19.74c-1.26-4.43-3.69-7.34-8.18-9.15
				c-2.94-1.19-4.55-4.69-5.32-8.09c-0.48-2.1-0.51-4.76-1.82-6.14c-8.44-8.82-10.32-22.36-21.21-29.37
				c-0.95-0.61-1.63-1.65-2.41-2.51c-3.04-3.34-6.76-2.82-8.2,1.55c-1.54,4.67-3.79,9.43-0.66,15.24c-3.6-1.3-4.59,0.82-5.25,2.72
				c-1.84,5.33-3.42,10.75-5.1,16.13c-0.33,1.07-0.03,2.08,0.7,2.77c4.54,4.29,3.51,8.79,0.68,13.43c-0.43,0.7-0.65,1.52-0.99,2.35
				c-2.34-0.07-2.61-2.83-4.82-3.59c-2.96,2.19-2.76,5.63-2.8,8.79c-0.04,3.29-0.2,6.17-3.55,8.06c-2.1,1.18-2.29,3.85-3.44,6.64
				c-6.93-6.67-12.64-1.69-18.56,1.37c-1.27,0.66-2.2,1.8-2.33,3.09c-0.59,6.12-3.65,11.61-4.34,17.69L607.85,906.2z"/>
			<path onClick={() => props.onFill(99)} fill={props.fillColors[99]} d="M66.91,1047.83c-6.01-3.19-12.22-5.58-16.17-11.75c3.51-0.54,7.1,1.59,10.01-0.82
				c0.3-2.26-1.6-1.98-2.83-2.95c4.31-3.86,8.57-7.67,12.69-11.35c-0.61-1.76-1.96-0.88-2.52-1.31c6.08-10.29,7.3-22.48,13.3-33.42
				c1.58,4.83,3.34,9.16,4.36,13.66c0.85,3.73-1.45,5.12-6.47,3.72c1.26,6.36,4.91,11.51,5.57,17.71c-2.49-0.44-2.9,1.12-1.35,3.58
				c-1.89,0.5-2.87-1.66-5.33-0.4c1.8,5.49,5.99,9.3,10.12,13.34c-2.65,0.58-4.3-2.18-8.05-1.22c6.86,4.75,2.43,12.01,5.54,17.71
				c-2.05,0.66-3.17-2.31-5.59-1.33c0.04,4.3,3.92,6.49,5.73,9.81c-3.75,4.16,1.07,6.08,2.5,9.19c-1.04-0.22-1.89-0.41-3.02-0.65
				c-0.11,3.59,3.63,4.77,4.25,7.63c-0.62,1.14-2.09-0.89-2.63,1.67c0.89,2.12,2.14,5.07,3.45,8.18c-1.94-0.01-2.53-1.12-2.7-2.47
				c-0.21-1.76-0.77-3.65-2.3-4.2c-4.57-1.66-5.81-5.28-6.71-9.48c-0.39-1.82-1.06-4.16-3.54-4.24c-2.32-0.08-4.09,1.31-5.24,3.3
				c-2.24,3.85-3.67,8-3.97,12.89c-4.44-1.48-6.06-5.1-9.26-8.34c4.08,0.18,7.21-0.65,9.86-3.71c-3.64-4.09-9.6-5.97-11.9-11.62
				c0.67-1.71,2.84,0.03,3.76-1.58c0.35-2.63-3.37-2.26-3.75-4.84C60.15,1055.22,61.54,1048.55,66.91,1047.83z"/>
			<path onClick={() => props.onFill(100)} fill={props.fillColors[100]} d="M818.82,1284.06c-0.25,4.76-0.45,8.56-0.66,12.37c-0.3,5.62-1.2,11.3,2.34,16.41
				c0.62,0.89,0.83,2.23,0.84,3.36c0.08,15.59,2.83,31.18,0.06,46.76c-0.71,4-0.61,7.66,0.73,11.67c2.5,7.5,1.82,15.31,0.64,23.6
				c-6.32-3.54-12.09-6.78-17.87-9.99c-1.29-0.72-1.88-1.7-1.8-3.23c0.38-7.13,0.27-14.32,2.55-21.22c1.04-3.15,2.25-6.12,7.19-3.63
				c-0.6-10.04,0.37-19.33-2.02-28.41c-0.77-2.94-2.9-5.75-5.18-7.11c-3.49-2.08-3.55-4.82-3.58-7.94
				c-0.08-6.99,0.04-13.98-0.2-20.97c-0.14-4.1,1.02-6.65,5.45-7.3C811.09,1287.9,814.57,1286.27,818.82,1284.06z"/>
			<path onClick={() => props.onFill(101)} fill={props.fillColors[101]} d="M67.62,1498.43c-6.48,0-12.77,0-18.91,0c-0.36-0.41-0.77-0.66-0.76-0.89c0.33-10.11-3.49-19.05-7.29-28.15
				c-5.16-12.37-5.8-25.89-8.8-38.82c-1.2-5.17-2.4-10.39-4.26-15.34c-1.44-3.82,0.62-6.16,2.95-7.53c2.69-1.58,4.98,0.81,6.35,3.17
				c3.34,5.73,6.84,11.13,8.01,18.15c1.58,9.45,5.5,18.4,10.18,26.92c5.76,10.48,11.56,20.99,11.64,33.52
				C66.75,1492.31,69.58,1494.87,67.62,1498.43z"/>
			<path onClick={() => props.onFill(102)} fill={props.fillColors[102]} d="M2.29,1294.82c0-17.47,0-34.32,0-52.79c4.07,4.36,8.42,4.93,12.72,5.79c4.16,0.84,6.52,2.47,7.76,7.64
				c1.54,6.45,4.79,13.12,11.24,16.9c3,1.76,5.11,4.09,6.28,7.4c1.65,4.68,5.08,6.78,10.03,6.46c1.5-0.1,3.02-0.08,4.27,0.93
				c1.26,1.02,3.02,1.93,1.95,3.97c-0.89,1.71-2.33,0.83-3.83,0.75c-6.91-0.38-13.84-0.43-20.76-0.41c-2.05,0-2.99,1.86-4.01,3.4
				c-2.1,3.17-5.09,3.5-7.69,1.26c-4.12-3.55-7.99-5.71-13.13-1.9C6.09,1294.98,4.25,1294.62,2.29,1294.82z"/>
			<path onClick={() => props.onFill(103)} fill={props.fillColors[103]} d="M947.8,566.1c-0.62,6.15-3.66,7.74-8.71,7.03c-11.83-1.66-22.66-0.09-31.4,9.31
				c-3.1,3.33-7.53,4.75-12.33,4.32c-4.8-0.43-8.83,1.78-12.33,4.6c-5.33,4.3-9.83,4.64-16.44,1.35
				c-10.7-5.33-19.65-12.27-27.23-21.28c-0.83-0.99-1.9-1.91-1.88-3.43c1.25-1.11,2.47-0.27,3.66,0.09
				c6.45,1.91,12.96,2.62,19.7,1.83c5.57-0.65,10.78,1.69,16.11,2.84c4.7,1.02,8.81,0.67,13.43-1.39c5.87-2.63,12.62-2.61,19.09-2.74
				c5.73-0.12,11.03-1.17,16.38-3.48C933.09,562.01,940.44,564.62,947.8,566.1z"/>
			<path onClick={() => props.onFill(104)} fill={props.fillColors[104]} d="M740.34,521.13c-3.05-4.5-6.73-7.8-11.32-9.98c-9.96-4.72-17-12.48-23.09-21.39
				c-1.96-2.87-3.39-6.74-8.01-6.61c-0.91,0.03-1.35-0.86-1.61-1.68c-1.16-3.6-2.31-7.2-3.37-10.53c1.03-1.65,2.47-1.83,3.68-2.18
				c7.03-2.01,14.01-4.04,20.22-8.16c3.71-2.46,6.25-1.66,9.44,2.17c7.53,9.05,9.95,19.63,11.44,30.72c0.13,0.98,0.34,2.09,0.04,2.97
				C734.83,505.06,734.88,513.31,740.34,521.13z"/>
			<path onClick={() => props.onFill(105)} fill={props.fillColors[105]} d="M74.09,1446.43c-0.96-7.51-1.91-15.02-2.92-22.53c-0.15-1.15,0.13-1.92,1.09-2.51
				c4.01-2.43,2.9-5.38,0.9-8.48c-1.31-2.04-2.67-3.81-1.64-6.78c0.76-2.17-1.75-4.11-2.99-6.05c-1.24-1.93-2.54-3.83-3.69-5.56
				c0.97-2.5,3.41-0.41,4.72-2.54c0.03-0.41,0.16-1.38,0.18-2.35c0.21-12.31-3.08-14.59-14.54-10.11c-3.2,1.25-5.23,0.42-5.39-3.32
				c-0.14-3.24-1.08-6.46,1.07-9.57c2.35-3.41,4.34-7.09,6.39-10.7c2.07-3.65,2.18-7.97,3.63-11.65c7.09-2,13.68,0.48,20.34,1.37
				c2.77,0.37,4.48,2.31,4.49,5.64c0.05,10.57-1.9,20.81-4.57,32.3c-5.49-12.92-5.28-25.71-7.56-38.03
				c-1.77,7.53-1.08,14.99,0.22,22.39c0.6,3.43,1.2,7.01,2.67,10.1c3.73,7.82,3.35,15.8,1.7,23.87c-1.32,6.46-1.38,12.95-0.54,19.38
				C78.78,1429.92,77.61,1438.17,74.09,1446.43z"/>
			<path onClick={() => props.onFill(106)} fill={props.fillColors[106]} d="M89.52,1485.74c-1.62-11.35-4-22.17-7.72-32.69c-0.55-1.55-1.29-2.99-0.57-4.77
				c3.87-9.52,4.28-19.34,2.1-29.11c12.3,2.64,24.62,1.14,37.4,2c-3.68,10.06-7.29,19.52-12.51,28.15
				C101.14,1461.03,92.28,1471.77,89.52,1485.74z"/>
			<path onClick={() => props.onFill(107)} fill={props.fillColors[107]} d="M52.16,448.55c-9.8,26-19.2,50.93-28.87,76.58c-2.01-2.41-0.8-4.47-2.55-5.66
				c-2.12-0.5-3.03,2.22-5.17,2.29c-1.04-15.43,6.17-28.94,10.93-43.78c-4.71,1.33-5.72,5.32-9.18,7.23
				c1.28-6.58,5.14-12.28,4.45-18.76c-1.35-0.96-2.02,0.08-3.48,1.32c2.35-6.49,4.35-12.01,6.57-18.14
				C33.77,454.59,42.5,451.53,52.16,448.55z"/>
			<path onClick={() => props.onFill(108)} fill={props.fillColors[108]} d="M511.54,429.64c1.79,4.06,3.48,8,5.27,11.9c1.16,2.53,3.14,4.16,5.96,3.04c2.69-1.07,1.18-3.37,0.82-5.2
				c-0.6-3.05-1.27-6.09-1.93-9.26c0.75-0.12,1.23-0.3,1.68-0.24c9.94,1.33,9.91,1.34,11.72,11.49c0.55,3.1,1.17,6.26,5.29,8.02
				c0.5-4.88-0.24-9.36-0.77-13.86c-0.21-1.73-1.72-4.35,1.45-4.85c2.71-0.43,2.41,2.34,2.8,3.86c1.36,5.3,1.62,10.83,3.58,15.86
				c-1.41,1.34-2.75,0.8-4,0.56c-20.67-3.96-41.65-6.39-61.9-12.51c-2.88-0.87-4.87-1.86-5.7-4.68c-1.34-4.54-4.58-6.36-9.02-7.31
				c-9.03-1.94-18.06-2.79-27.25-1.62c-7.81,0.99-15.51-0.86-23.77-0.6c1.4-4.23,4.45-5.55,7.48-5.21
				c6.01,0.67,11.87,0.29,17.84-0.31c4.11-0.41,8.01,1.93,12.27,1.82c6.48-0.17,12.98-0.1,19.46,0.2c3.36,0.15,7.19,0.03,8.34,4.6
				c0.31,1.21,1.57,1.87,2.75,1.83c5.79-0.2,11.06,2.08,16.58,3.17C504.11,431.04,507.74,430.09,511.54,429.64z"/>
			<path onClick={() => props.onFill(109)} fill={props.fillColors[109]} d="M126.11,1370.78c-5.07-0.05-12.63,6.02-13.4,10.31c-0.71,3.98,2.41,6.82,8.24,7.24
				c0.39,1.65,0.84,3.38,1.21,5.12c0.34,1.61,0.59,3.23,0.96,5.31c-5.4-1.63-9.04,0.2-12.2,4.82c-3.13,4.57-9.43,4.96-13.95,1.59
				c-1.89-1.41-3.4-1.51-5.39-0.29c-2.64,1.62-5.47,2.92-8.67,4.6c-0.61-10.18,1.64-18.55,8.4-25.4c4.26-4.31,7.83-9.11,9.8-14.87
				c0.87-2.54,2.4-2.63,4.59-2.41c4.13,0.42,8.14,0.98,10.55-4.1c1.34-2.83,6.49-1.25,8.24,2.47
				C125.27,1366.81,125.53,1368.68,126.11,1370.78z"/>
			<path onClick={() => props.onFill(110)} fill={props.fillColors[110]} d="M607.47,584.63c-3.15-9.24-11.76-12.63-18.08-18.37c-4.45-4.04-8.93-3.3-13.8,0.47
				c-3.59,2.78-8.27,1.95-12.32,1.26c-6.42-1.09-11.02-10.53-8.28-16.45c2.31-5.01,4.06-10.11,4.26-16.02
				c3.54,0.77,6.56,1.4,9.72,1.15c3.33-0.27,4.79,2.52,6.55,4.7c7.18,8.94,16.29,16.02,23.76,24.69c0.72,0.84,1.91,1.33,2.95,1.82
				c7.95,3.66,9.06,4.85,12.55,13.86C612.55,582.61,610.31,583.5,607.47,584.63z"/>
			<path onClick={() => props.onFill(111)} fill={props.fillColors[111]} d="M664.71,471.62c-3.59,3.97-3.19,7.83-4.23,11.6c-3.36,0.32-5.35-0.69-5.78-4.58
				c-0.61-5.55,0.4-10.94,0.98-16.38c0.53-4.95,1.08-9.88-0.37-14.89c2.02-0.53,3.5,0.48,4.96,1.47c1.68,1.15,3.58,2.4,5.5,2.09
				c7.75-1.24,11.28,3.96,14.9,9.21c3.65,5.28,8.15,9.8,10.55,16.15c2.72,7.2,3.09,13.42-2.17,19.67c-2.54-1.08-3.84-3.68-5.27-5.97
				c-2.2-3.51-5.1-6.14-8.75-7.93C670.68,479.92,666.28,477.91,664.71,471.62z"/>
			<path onClick={() => props.onFill(112)} fill={props.fillColors[112]} d="M38.69,1498.32c-12.39,0-24.3,0-36.52,0c0-13.2,0-26.11,0-39.5c5.26,2.88,10.31,5.58,14.35,9.82
				c2.87,3.01,5.64,6.5,9.66,7.71C37.28,1479.7,39.04,1488.08,38.69,1498.32z"/>
			<path onClick={() => props.onFill(113)} fill={props.fillColors[113]} d="M264.16,1191.77c-3.98-4.56-7.78-6.09-12.64-2.9c-0.26,0.17-0.74,0.35-0.94,0.23
				c-7.32-4.3-14.49,0.35-21.73,0.33c-0.49,0-0.97-0.2-1.45-0.3c0.03-6.6-4.29-11.83-5.59-18.04c-0.54-2.6-2.41-5.03-3.1-7.79
				c-0.53-2.11-1.9-3.82-4.13-3.65c-3.41,0.26-3.33-1.72-3.17-4.42c5.4,3.63,10.79,6.89,17.54,5.22c5.84,7.91,7.35,8.29,14.31,3.25
				c2.93,2.59,6.15,4.84,10.31,1.95c1.17-0.81,2.27,0.12,3,1.04c1.69,2.1,3.73,3.14,5.97,2.33
				C266.91,1176.5,263.2,1184.05,264.16,1191.77z"/>
			<path onClick={() => props.onFill(114)} fill={props.fillColors[114]} d="M664.46,1177.99c-6.62-2.92-11.55-7.03-19.64-3.17c-7.55,3.6-9.27,9.76-13.27,16.05
				c0.8-7.17,1.74-13.36,7.9-17.39c2.21-1.45,3.73-3.85,4.83-6.28c2.92-6.49,5.21-13.3,10.14-18.72c0.61-0.67,0.74-1.83,0.93-2.79
				c1.4-7.06,1.64-6.41,9.05-7.72c7.07-1.25,15.25,0.34,20.83-6.33c1.13-1.35,3.2-0.96,4.75-0.42c6.61,2.31,11.88,6.92,18.1,10.09
				c-4.57,5.59-5.55,5.79-11,2.31c-3.52-2.25-7.06-3.75-11.17-1.45c-2.22,1.24-4.55-0.26-6.87,0.19c-7.4,1.42-15.27,11.1-15.1,18.69
				C664.08,1166.33,665.29,1171.59,664.46,1177.99z"/>
			<path onClick={() => props.onFill(115)} fill={props.fillColors[115]} d="M776.53,1406.92c-6.04-1.87-11.02-2.8-15.47-4.89c-19.03-8.93-39.79-12.73-59.24-20.4
				c-11.53-4.55-22.58-9.69-31.96-17.95c-4.01-3.53-7.74-7.3-10.36-12.34c2.18-3.12,5.6-4.84,9.26-8.05
				c-1.36,9.84,3.97,15.63,9.66,21.32c5.14,5.15,11.01,7.74,18.41,6.49c4.72-0.79,9.14,0.06,13.37,2.64c2.5,1.53,4.92,2.74,8.55,3.03
				c4.17,0.34,10.25,2.44,13.62,7.09c5.4,7.45,12.5,10.03,21.36,7.21c1.44-0.46,2.55,0.25,3.81,0.57c3.85,0.97,7.71,2.22,11.97-0.12
				C771.75,1396.44,773.86,1401.06,776.53,1406.92z"/>
			<path onClick={() => props.onFill(116)} fill={props.fillColors[116]} d="M260.35,1060.42c1.73,4.24,4.48,6.62,7.02,9.14c-1.92,2.25-3.76-0.44-5.65,0.91
				c-1.17,5.05,3.6,6.54,6.28,9.44c-1,1.9-2.79-0.45-4.18,1.08c-0.76,3.37,3.61,5.51,3.08,9.09c-1.49,0.6-2.44-1.75-4.66-0.42
				c2.75,4.42,7.67,7.18,9.46,12.41c-1.21,1.86-4.24,0.96-6.12,3.32c2.18,2.07,4.37,4.14,6.57,6.22c-0.03,2.26-2.73,2.82-3.13,4.69
				c0.29,1.76,2.21,1.51,2.82,2.59c-1.21,4.48-5.23,7.03-7.3,11c0.9,1.92,3.3,1.42,4.49,2.77c-0.52,1.72-2.05,2.17-3.19,3.01
				c-9.31-5.44-12.74-9.16-13.08-14.15c4.59-1.58,8.3,4.08,14.26,1.36c-6.31-3.34-10.12-8.35-15.65-12.11c1.66-1.47,2-2.77-0.1-4.5
				c-6.46-5.35-6.28-9.38,0.11-13.98c-0.54-5.9,1.45-10.98,4.68-16.03c-4.54-2.36-3.54-5.19-0.48-8.09
				C257.58,1066.31,258.91,1064.03,260.35,1060.42z"/>
			<path onClick={() => props.onFill(117)} fill={props.fillColors[117]} d="M440.73,1301.02c6.44-1.08,12.69,3.02,18.08-1.97c6.72,3.42,9.75-0.55,13.16-5.63
				c1.05-1.56,5.21-1.32,6.28-5.02c0.45,6.34,3.31,10.65,7.73,14.19c1.81,1.45,2.54,3.43,1.04,5.73c-2.25,3.47,0.9,7.26-0.75,11.06
				c-1.2,2.76-2.07,5.21-5.18,6.01c-2.81,0.73-4.31-1.06-5.61-3.13c-0.77-1.22-1.84-2.14-3.29-1.71c-4.74,1.38-8.74-1.32-13.05-2.24
				c-1.6-0.34-3.22-0.97-3.69-2.93c-0.87-3.65-3.54-4.28-6.55-3.36c-4.22,1.29-6.37-1.06-8.8-3.86
				C437.53,1305.18,440.69,1303.35,440.73,1301.02z"/>
			<path onClick={() => props.onFill(118)} fill={props.fillColors[118]} d="M99.48,979.64c-1.49,4.14-0.69,6.99-1.47,9.83c-0.57-8.26-8.68-15.15-3.85-24.72
				c2.53,2.1,5.33,3.27,8.69,2.8c1.65-1.6,0.48-4.58,2.88-6.18c3.25,4.11,3.6,9.14,4.64,13.89c0.76,3.46,1.68,6.81,4.29,9.36
				c1.94,1.9,3.94,2.5,5.6-0.55c5.19,13.15,16.52,23.62,15.27,39.1c-1.23,0.17-0.43-2.14-1.62-2.47c-1.69-0.98-1.81,1.86-3.32,1.31
				c-5.97-7.01-14.38-12.28-15.43-22.48c-2.47,0.15-2.24,2.48-3.77,2.84C106.72,995.65,105.08,987.19,99.48,979.64z"/>
			<path onClick={() => props.onFill(119)} fill={props.fillColors[119]} d="M610.15,689.01c2.54,0.89,2.09,4.65,5.37,5.25c1.8-3.99,0.7-8.91,3.65-12.51
				c2.68-3.28,5.31-6.6,8.92-11.09c0.28,6.24,4.09,10.17,4.75,14.36c0.94,5.88,1.55,12.93-2.2,18.23c-2.97,4.19-2.79,8.31-2.64,12.71
				c0.13,3.97,0.57,7.93,0.41,12.5c-3.84-5.28-9.81-6.77-14.96-9.7c-3.19-1.82-4.36-5.91-5.26-9.39c-1.69-6.53,0.02-13.1,0.7-19.64
				C608.93,689.45,609.7,689.25,610.15,689.01z"/>
			<path onClick={() => props.onFill(120)} fill={props.fillColors[120]} d="M293.22,937.6c2.73,10.83,11.47,5.91,17.5,6.83c3.57,0.55,7.26-0.77,10.5,2.22
				c1.02,0.94,3.08,0.75,4.16,0.97c4.99,11.8-3.21,19.31-8.77,28.07c-2.51-0.15-5.78-2.42-8.15,1.31c-2.53-2.06-4.68-4.35-8.44-2.33
				c-0.02-5.85-0.2-11.06-4.74-14.67c-0.32-0.26-0.53-0.91-0.48-1.35C295.57,951.71,294.01,944.96,293.22,937.6z"/>
			<path onClick={() => props.onFill(121)} fill={props.fillColors[121]} d="M947.46,1452.2c1.67-2.33,3.14-4.29,6.1-3.3c1.61,0.54,2.43-0.71,3.61-1.13
				c6.86-2.45,13.63-4.6,20.97-1.24c2.67,1.22,5.9,0.36,8.86-0.09c6.63-1.01,9.1,0.18,12.69,6.1c2.86,4.71,6.68,6.08,11.77,4.19
				c7.27-2.69,7.17-2.66,9.29,4.53c0.62,2.12,1.38,4.17,4.3,4.88c9.26,2.23,10,5.44,3.29,13.08c-2.01-0.12-3.54-1.31-5.19-2.4
				c-15.02-9.9-30.74-18.45-47.79-24.41c-4.05-1.41-7.81-1.84-12.05-0.23C958.34,1454.06,953.03,1454.3,947.46,1452.2z"/>
			<path onClick={() => props.onFill(122)} fill={props.fillColors[122]} d="M896.29,1469.12c21.05,10.91,42.18,21.12,64.92,27.58c-0.15,0.63-0.29,1.27-0.44,1.9c-1.87,0-3.73,0-5.6,0
				c-14.79,0-29.59,0.01-44.38-0.01c-4.93-0.01-7.37-2.7-5.46-7.08c1.52-3.49-0.21-4.83-2.21-6.62
				C898.71,1480.97,894.68,1476.83,896.29,1469.12z"/>
			<path onClick={() => props.onFill(123)} fill={props.fillColors[123]} d="M522.86,1276.91c7.35,14.78,22.72,22.13,30.69,36.27c-22.16-6.41-42.52-17.34-64.42-24.46
				C501.04,1287.05,512.3,1284.24,522.86,1276.91z"/>
			<path onClick={() => props.onFill(124)} fill={props.fillColors[124]} d="M772.87,1390.93c5.15,4.4,10.84,7.92,11.55,12.45c1.34,8.63,7.88,8.75,13.21,11.88
				c7.11,4.17,14.38,8.04,21.57,12.05c5.44,3.04,11.33,5.29,17.06,7.81c3.39,1.49,6.73,1.1,9.94-1.2c3.63,7.26,7.09,14.2,10.65,21.33
				c-4.08,1.37-7.21-0.22-9.74-2.16c-16.57-12.71-35.71-21.27-52.83-33.07C783.24,1412.42,780.25,1408.45,772.87,1390.93z"/>
			<path onClick={() => props.onFill(125)} fill={props.fillColors[125]} d="M833.56,572.09c-4.56-3.48-9.1-7.01-13.7-10.44c-3.2-2.39-6.52-4.6-10.47-5.64
				c-2.6-0.69-4.03-2.84-5.63-4.74c-4.11-4.89-8.48-9.41-14.87-11.28c-1.92-0.56-2.97-2.02-3.72-3.77
				c-2.08-4.89-4.26-9.74-6.21-14.68c-1.52-3.85-3.17-7.35-5.95-10.69c-3.57-4.29-3.03-10.35-2.85-15.79
				c0.06-1.99,1.96-1.59,3.49-0.77c4.06,2.17,5.93,5.92,6.65,10.06c1.22,6.96,4.38,13.24,6.57,19.85
				c2.09,6.31,7.62,11.05,12.98,14.02c8.03,4.45,13.6,11.12,19.6,17.39C824.42,560.79,830.12,565.46,833.56,572.09z"/>
			<path onClick={() => props.onFill(126)} fill={props.fillColors[126]} d="M603.97,448.3c10.59,8.96,14.67,19.19,13.68,33.1c-17.49-10.35-34.19-20.62-52.05-28.1
				c2.39-5.16,4.52-9.78,6.92-14.97c4.25,6.02,3.65,14.42,10.96,18.62c2.24-6.05-4.16-11.8-0.39-18.39c4.6,5.08,5.91,10.05,6.8,15.07
				c1.26,7.07,5.54,10.58,12.38,11.57c3.07,0.45,5.01-0.02,4.17-3.84C605.61,457.51,604.96,453.59,603.97,448.3z"/>
			<path onClick={() => props.onFill(127)} fill={props.fillColors[127]} d="M9.68,1415.26c3.45,9.76,8.23,18.44,13.94,26.61c5.82,8.32,6.81,18.03,8.26,28.14
				c-6.09-2.19-9.67-6.59-12.92-11.1c-6.39-8.9-10.47-19.16-16.38-28.4C-1.31,1424.42,2.32,1417.24,9.68,1415.26z"/>
			<path onClick={() => props.onFill(128)} fill={props.fillColors[128]} d="M105.4,1468.55c5.58-8.29,7.27-18.96,16.56-24.76c2.8-1.75,5.15-2.49,8.29-1.12
				c5.54,2.42,11.35,3.53,17.42,2.39c1.28,2.05,2.48,3.98,3.79,6.08c-6.47,6.55-14.85,9.41-23.08,12.25
				c-2.01,0.69-4.96-1.3-7.46-2.11c-3.35-1.09-5.87-0.44-7.15,3.16C112.37,1468.41,108.67,1467.88,105.4,1468.55z"/>
			<path onClick={() => props.onFill(129)} fill={props.fillColors[129]} d="M33.89,812.58c-2.56-1.53-4.44-2.57-7.27-2.53c-4.01,0.06-5.39-3.59-6.55-6.64
				c-1.43-3.74-3.04-6.47-7.44-7.68c-4.03-1.11-5.61-6.65-10.76-6.87c0-7.62,0.01-14.93-0.01-22.24c0-1.6,0.23-2.9,2.09-3.46
				c1.93-0.58,3.27,0.19,4.35,1.57c2.95,3.75,4.6,8.05,5.17,12.77c0.22,1.78-0.04,3.73,1.72,4.98c1.56,0.33,2.87-0.59,5.11-1.18
				C23.15,792.49,29.82,801.94,33.89,812.58z"/>
			<path onClick={() => props.onFill(130)} fill={props.fillColors[130]} d="M592.75,1356.37c0.53,8.28,0.91,16.51-1.06,24.34c-8.56,3.53-16.94,3.54-25.59-0.68
				c-0.29-2.24,2.8-4.06,3.63-6.86c0.71-2.38,1.64-4.7,2.5-7.04c1.24-3.36,1.07-7.24,4.09-9.96c3.7-3.33,6.8,0.85,10.29,0.71
				C588.52,1356.81,590.38,1355.96,592.75,1356.37z"/>
			<path onClick={() => props.onFill(131)} fill={props.fillColors[131]} d="M342.37,754.07c8.07,9.34,21.47,12.67,27.22,25.36c-3.71,4.28-8.01,9.25-12.61,14.55
				c-3.9-2.57-6.71-6.24-10.07-10.09c2.96-0.26,4.88,2.22,7.21,0.81c0.84-1.76-0.56-2.49-1.43-3.38c-7-7.12-14.03-14.19-22.87-19.11
				c-2.16-1.2-4.07-2.86-6.13-4.27c-3.05-2.1-3.25-4.32,0.11-7.15c6.81,5.11,12.66,11.41,18.76,17.42c2.36,2.33,4.66,4.74,7.17,6.9
				c1.56,1.34,3.7,2.24,5.36,0.34c1.82-2.09-0.49-3.3-1.69-4.42c-2.42-2.27-4.85-4.64-7.64-6.37c-4.41-2.74-6.71-5.75-3.27-10.71
				C342.49,753.95,342.37,754.07,342.37,754.07z"/>
			<path onClick={() => props.onFill(132)} fill={props.fillColors[132]} d="M81.24,425.86c-0.69-1.51-1.2-3.13-2.1-4.5c-1.95-2.99-4.35-4.95-8.32-3.3c-1.97,0.82-4.25,0.91-6.23,1.72
				c-11.75,4.81-23.68,4.19-35.9,2.12c-4.95-0.84-10.21,0.29-15.33,0.32c-2.98,0.02-5.97-0.32-8.94-0.56
				c-2.07-0.17-3.04-1.15-2.01-3.69c12.74,1.49,25.63,0.54,38.52,0.29c4.15-0.08,8.28-0.51,12.32-1.55
				c12.8-3.29,25.72-2.85,38.58-0.79c7.07,1.13,14.15,1.55,21.57,1.52c-1.55,4.21-4.11,5.62-8.11,4.82
				c-8.43-1.66-16.51-0.67-24.08,3.58C81.2,425.85,81.24,425.86,81.24,425.86z"/>
			<path onClick={() => props.onFill(133)} fill={props.fillColors[133]} d="M550.03,1377.49c-2.37-1.55-2.29-3.26-1.7-4.84c1.44-3.87-0.72-6.05-3.84-6.64
				c-3.32-0.63-6.06-2.6-9.29-3.13c-1.17-0.19-1.64-0.55-1.95-1.71c-1.05-3.97-4.53-5.4-7.74-4.77c-6.67,1.31-10.74-1.38-13.64-7.01
				c3.09-5.1,4.97-5.8,10.18-3.04c1.92,1.01,4.53,0.18,5.45,0.87c4.51,3.4,8.98,2.62,14.13,2.45c3.86-0.13,7.95,3.67,12.68,3.6
				c1.32-0.02,1.7,1.41,1.57,2.67C555.15,1363.32,553.85,1370.57,550.03,1377.49z"/>
			<path onClick={() => props.onFill(134)} fill={props.fillColors[134]} d="M861.08,1454.74c9.41,4.53,19.69,6.83,28.25,13.07c2.51,1.83,4.21,3.85,4.25,7.34
				c0.04,3.87,2.73,6.71,5.4,9.22c2.69,2.53,5.32,4.77,3.57,9.1c-0.5,1.24,0.11,2.93,0.23,4.6c-3.99,1.32-6.78,0.71-8.7-3.56
				c-2.77-6.15-7.13-11.15-12.65-15.1c-3-2.15-5.42-4.88-7.44-7.97c-3.02-4.62-6.47-8.83-10.94-12.15
				C861.78,1458.34,860.17,1457.45,861.08,1454.74z"/>
			<path onClick={() => props.onFill(135)} fill={props.fillColors[135]} d="M68.99,1465.89c-2.85-6.03-6.39-11.46-9.78-16.96c-4.86-7.87-8.07-16.31-9.95-25.4
				c-0.85-4.09,2.04-5.28,4.08-7.02c2.1-1.79,4.08-0.92,6.48,0.8c4.53,3.24,5.78,7.11,6.04,12.51c0.42,8.78,2.14,17.49,3.08,26.25
				C69.28,1459.31,68.99,1462.62,68.99,1465.89z"/>
			<path onClick={() => props.onFill(136)} fill={props.fillColors[136]} d="M807.07,705.39c0.41,4.96,3.82,7.54,7.75,9.62c8.95,4.75,17.94,9.41,26.89,14.17
				c3.21,1.71,6.4,3.42,8.96,6.18c1.83,1.97,4.47,3.01,7.09,3.43c16.1,2.61,27.54,13.35,39.77,22.69c2.83,2.16,5.3,4.8,7.71,7.58
				c-3.72-0.63-7.59-0.96-10.34-3.62c-10.34-10-23.68-14.56-36.32-20.35c-5.72-2.62-11.29-4.61-15.03-10.63
				c-2.9-4.67-8.83-6.89-13.78-9.6c-7.28-3.99-14.79-7.57-22.06-11.59c-5.09-2.81-5.23-5.05-1.03-8.25
				C806.81,705.15,806.94,705.27,807.07,705.39z"/>
			<path onClick={() => props.onFill(137)} fill={props.fillColors[137]} d="M634.64,674.08c-4.75-8.08-5.84-8.14-13.38-0.49c0-5.99-0.32-11.28,0.07-16.51
				c0.6-8.04-1.17-15.91-1.24-23.89c-0.02-2.11-0.28-4.89,2.96-4.61c2.38,0.21,5.57-0.37,6.26,3.41
				C631.84,645.69,635.99,659.16,634.64,674.08z"/>
			<path onClick={() => props.onFill(138)} fill={props.fillColors[138]} d="M310.07,460.79c2.75,0.67,1.28,2.7,1.09,4.03c-0.63,4.44-1.95,8.75-3.72,12.86
				c-5.83,13.56-6.82,27.98-7.57,42.41c-0.25,4.92,0.16,9.96,3.88,14.25c2.98-2.2,3.26-5.77,5.84-9.37
				c-1.08,12.96-5.63,24.25-6.6,36.17c-1.47-5.86-0.98-12.23-3.62-17.6c-4.71-9.58-6.37-19.22-5.49-29.97
				c0.45-5.41,0.97-10.3,3.4-15.11c5.23-10.39,9.51-21.17,12.58-32.42c0.49-1.79,1-3.52,0.28-5.35L310.07,460.79z"/>
			<path onClick={() => props.onFill(139)} fill={props.fillColors[139]} d="M41.69,1340.32c-0.58,8.84-0.53,17.56,0.92,26.53c-5.75-0.15-9.46-0.6-13.94-5.49
				c-4.91-5.37-3.35-9.93-3.54-15.07c-0.1-2.79,0.5-5.62,0.86-8.42c0.63-4.91,2.59-5.85,6.56-2.95
				C35.36,1336.98,37.97,1339.3,41.69,1340.32z"/>
			<path onClick={() => props.onFill(140)} fill={props.fillColors[140]} d="M574.46,1354.35c-0.51-10.7-0.68-20.44,3.25-29.73c9.07,1.36,10.88,2.95,12.35,11.35
				c0.8,4.57,1.27,9.21,1.91,13.81c0.54,3.91-1.35,4.66-4.92,4.55C582.88,1354.21,578.97,1350.43,574.46,1354.35z"/>
			<path onClick={() => props.onFill(141)} fill={props.fillColors[141]} d="M34.59,1473.56c0.01-11.61-5.35-26.3-15.95-43.4c5.08,3.83,7.37,8.29,10.87,13.73
				c0.69-6.94-1.53-12.21-0.95-17.71c1.57,8.81,3.16,17.63,4.72,26.45c1.67,9.39,4.4,18.41,8.8,26.91
				c3.15,6.08,2.97,12.43,2.24,19.06c-3.09-0.54-2.54-2.37-2.79-3.7c-0.63-3.42-0.75-6.94-2.11-10.22c-1.66-4-4.07-7.31-8.28-8.81
				c-5.34-1.9-9.58-5.26-13.22-9.47c-3.32-3.85-8.06-5.72-12.38-8.78c2.16-0.71,3.65,1.61,6-0.08c-2.63-6.29-5.32-12.73-8.02-19.18
				C12.06,1451.6,16.51,1468.4,34.59,1473.56z"/>
			<path onClick={() => props.onFill(142)} fill={props.fillColors[142]} d="M540.21,589.33c9.52,8.61,18.87,41.8,12.48,52.43c-4.39-4.66-3.5-10.28-3.79-15.39
				c-0.31-5.42-0.07-10.88-0.52-16.31c-2.03-1.56-3.32,0.82-5.03,0c-1.85-1.11-0.77-4-2.89-5.66c-5.28,2.33-7.74-1.18-9.96-5.47
				c-1.68-3.24-0.38-5.59,2.55-6.02C535.91,592.49,537.81,590.91,540.21,589.33z"/>
			<path onClick={() => props.onFill(143)} fill={props.fillColors[143]} d="M1086.44,754.61c7.43-2.8,14.68-4.32,22.3-5.61c1.64,5.25,5.69,7.06,10.59,7.52
				c13.79,1.29,26.76,7.95,41.09,6.15c-11.12,3.56-22.63-1.74-34.11,1.78c-0.45-2.9-3.12-2.75-5.63-3.61
				c-8.69-2.96-17.36,1.3-26.05-0.47C1091.05,759.65,1088.48,758.45,1086.44,754.61z"/>
			<path onClick={() => props.onFill(144)} fill={props.fillColors[144]} d="M437.23,471.28c-2.88-8.18-0.02-15.16-1.23-22.19c-3.2,0.54-2.32,3.93-4.68,5.5
				c0.09-6.17-0.57-11.99,1.49-18.01c5.44,0.38,10.66,0.74,16.4,1.14c-0.73,6.32-1.1,12.27-2.19,18.08
				C445.87,461.91,439.13,464.29,437.23,471.28z"/>
			<path onClick={() => props.onFill(145)} fill={props.fillColors[145]} d="M689.61,801.57c-0.04-12.55-5.41-24.31-4.11-37.23c2.21,10.82,6.95,19.65,17.7,24.1
				c2.6-1.81,1.91-5.59,4.61-7.7c2.75,3.33,3.25,7.55,3.53,11.43c0.18,2.56-2.01,5.16-5.47,3.64c-3.76-1.66-6.36-0.02-7.88,2.97
				C696.2,802.28,693.63,802.31,689.61,801.57z"/>
			<path onClick={() => props.onFill(146)} fill={props.fillColors[146]} d="M767.95,1136.91c2.87,1.63,5.89,3.04,8.57,4.94c4.95,3.51,5.36,7.24,2.12,12.37
				c-1.49,2.36-4.05,3.92-3.03,7.97c0.83,3.33-5.31,8.36-9.89,8.37c-7.16,0.01-12.7,3.82-18.29,7.14
				c-5.16,3.07-10.91,3.89-16.33,5.89c-3.38,1.25-4.1-1.64-4.68-4.14c0.31-0.27,0.59-0.69,0.81-0.67c13.32,1.68,22.6-8.58,34.3-11.66
				c1.79-0.47,3.77-1.72,4.01-3.46c0.67-5.01,4.14-6.93,8-9.18c4.65-2.71,4.69-7.69,0.6-11.83c-1.97-2-4.16-3.79-6.25-5.67
				L767.95,1136.91z"/>
			<path onClick={() => props.onFill(147)} fill={props.fillColors[147]} d="M652.61,446.7c3.5,12.08-2.23,22.91,0.24,33.81c-0.42,0.2-0.85,0.41-1.27,0.61
				c-5.6-6.09-11.2-12.17-17.4-18.91C642.75,459.9,646.08,452.34,652.61,446.7z"/>
			<path onClick={() => props.onFill(148)} fill={props.fillColors[148]} d="M441.63,1298.91c2.11-4.98,3.58-8.3,4.94-11.67c0.74-1.84,1.95-2.44,3.9-2.16
				c8.04,1.16,16.1,2.23,24.13,3.33c0.6,1.77-0.52,2.11-1.76,2.24c-2.62,0.28-4.12,1.64-4.89,4.21c-0.96,3.2-3.13,4.08-6.18,2.48
				c-1.46-0.77-2.94-1.79-4.51-0.19C452.63,1301.86,447.58,1297.77,441.63,1298.91z"/>
			<path onClick={() => props.onFill(149)} fill={props.fillColors[149]} d="M1020.44,1498.23c-5.55,1.02-9.7,0.06-13.8-2.89c-9.57-6.88-20.66-9.44-32.24-10.74
				c-15.15-1.71-29.49-6.19-42.66-14.11c9.93,4.19,19.99,7.99,30.63,9.94c5.89,1.08,11.79,2.2,17.79,0.73
				c0.79-0.2,1.74-0.4,2.46-0.15C995.6,1485.39,1009.08,1488.7,1020.44,1498.23z"/>
			<path onClick={() => props.onFill(150)} fill={props.fillColors[150]} d="M699.03,1497.85c-17.15,1.34-34.35,2.91-49.23-9.75C667.48,1485.96,683.06,1492.96,699.03,1497.85z"/>
			<path onClick={() => props.onFill(151)} fill={props.fillColors[151]} d="M108.86,1475.04c2.96,5.66,8.18,4.3,13.21,4.19c-8.2,3.23-13.05,9.58-16.91,16.88
				c-2.17,4.11-5.67,2.07-8.76,2.33C97.87,1482.21,99.27,1477.41,108.86,1475.04z"/>
			<path onClick={() => props.onFill(152)} fill={props.fillColors[152]} d="M75.49,1498.2c-0.69,0.16-1.15,0.34-1.63,0.35c-0.82,0.01-1.64-0.08-2.64-0.14
				c-1.06-6.21-2.11-12.33-3.14-18.45c-0.11-0.65-0.39-1.5-0.11-1.94c7.24-11.14,3.3-22.98,2.18-34.62
				c-0.62-6.42-1.58-12.81-1.66-19.32c1.44,10.65,2.88,21.31,4.32,31.96c0.41,0.11,0.82,0.22,1.23,0.32
				c3.39-7.8,5.14-16.1,6.01-24.62c0.89-8.73-1.81-17.48,0.17-26.23c-0.24,7.85,1.33,15.52,2.05,23.27
				c0.76,8.2-1.85,15.85-4.82,23.23c-4.98,12.41-5.21,25.17-3.96,38.16C73.76,1492.83,76,1494.94,75.49,1498.2z"/>
			<path onClick={() => props.onFill(153)} fill={props.fillColors[153]} d="M101.62,1470.57c3.36,2.25,5.42-0.38,7.51,0.87c0.06,2.04-1.57,1.77-2.76,2.07
				c-5.89,1.49-9.56,5.06-10.57,11.17c-0.64,3.85-2.46,7.29-2.55,11.46c-0.09,4.33-4.32,1.69-7.28,2.51
				c-0.1-8.89-0.56-17.42-2.09-25.84c2.9,6.98,2.34,14.54,3.2,22.1c3.37-1.13,3.47-3.55,3.93-5.48c3.13-12.97,9.01-24.57,16.98-35.2
				c1.19-1.59,2.15-3.36,3.13-5.09c3.03-5.37,3.01-5.38,10.55-8C110.32,1449.09,107.68,1460.83,101.62,1470.57z"/>
			<path onClick={() => props.onFill(154)} fill={props.fillColors[154]} d="M960.37,789.64c7.31,1.01,14.12,3.16,19.44,8.62c3.9,4,3.77,7.63-0.23,11.64
				c-2.96,2.97-6.74,4.17-10.67,4.96c-8.72,1.75-17.9,0.62-26.32,4.64c-2.26,1.08-3.68-2.01-5.87-3.53
				c6.72-3.71,13.65-2.76,20.27-3.44c4.28-0.44,8.63-0.04,12.85-1.22c3.52-0.98,6.69-2.57,7.56-6.41c0.8-3.58-1.75-5.86-4.14-7.95
				C969.48,793.64,964.84,791.8,960.37,789.64z"/>
			<path onClick={() => props.onFill(155)} fill={props.fillColors[155]} d="M850.65,952.19c1.67-4.84-0.71-7.73-4.01-9.92c-4.57-3.04-9.42-5.64-13.97-8.69
				c-6.63-4.45-6.7-8.26-0.44-13.2c2.09-1.65,4.37-3.04,6.49-4.64c2.57-1.93,3.89-4.26,3.26-7.74c-1.42-7.78,0.25-10.08,8-11.77
				c1.45-0.32,2.92-0.55,4.5-0.84c-0.63,1.96-2.38,1.35-3.56,1.82c-3.95,1.56-6.21,4.28-4.76,8.52c1.43,4.19-0.18,6.68-3.48,8.75
				c-0.84,0.53-1.66,1.11-2.47,1.7c-8.72,6.23-8.55,12.7,0.54,18.7c3.33,2.2,6.94,4,10.13,6.36
				C855.29,944.49,855.22,947.83,850.65,952.19z"/>
			<path onClick={() => props.onFill(156)} fill={props.fillColors[156]} d="M126.26,1484.54c6.14,7.52,12.59,10.73,20.93,9.55c2.69-0.38,3.65,1.51,4.22,4.21
				c-12.78,0-25.14,0-38.05,0C116.05,1492.04,124.79,1492.5,126.26,1484.54z"/>
			<path onClick={() => props.onFill(157)} fill={props.fillColors[157]} d="M2.09,1348.48c-0.08-0.87-0.35-1.73-0.18-2.5c0.45-2.08-1.47-4.91,1.46-6.18c2.9-1.26,3.37,1.94,4.88,3.19
				c3.21,2.67,6.93,2.04,9.03-1.54c0.89-1.53,1.42-3.36,4.56-5.36c-1.3,7.98-1.37,14.86-1.36,21.96c-2.63,1.52-4.62,2.79-6.36-1.44
				C12.03,1351.54,6.55,1350.62,2.09,1348.48z"/>
			<path onClick={() => props.onFill(158)} fill={props.fillColors[158]} d="M41.08,426c-12.48,6.68-25.31,6.74-38.56,4.06c-0.99-1.57-0.8-3.28-0.01-5.46
				C15.17,424.98,27.91,424,41.08,426z"/>
			<path onClick={() => props.onFill(159)} fill={props.fillColors[159]} d="M231.43,445.73c-3.72,2.98-4.52,6.6-9.96,5.94c-4.96-0.6-9.21,3.83-12.66,7.85
				c-3.31,3.87-6.14,8.33-11.82,9.91c5.08-6.68,7.55-14.69,11.92-21.71c0.59-0.96,1.55-1.19,2.61-1.04
				C217.66,447.58,223.73,446.31,231.43,445.73z"/>
			<path onClick={() => props.onFill(160)} fill={props.fillColors[160]} d="M728.97,462.95c7.62,8.18,12.25,17.9,13.74,29c1.15,8.55,2.11,17.12,5.09,25.51
				c-1.35,1.23-2.66,2.43-4.04,3.69c-5.04-6.55-7.06-13.53-4.24-21.41c1.06-2.96,0.89-5.91,0.3-8.85
				C737.83,481,736.66,470.78,728.97,462.95z"/>
			<path onClick={() => props.onFill(161)} fill={props.fillColors[161]} d="M82.51,1498.43c-6.18,0.45-5.78-4.94-6.15-7.51c-1.52-10.42-2.34-21.01,1.71-32.5
				C81.08,1472.15,83.73,1484.76,82.51,1498.43z"/>
			<path onClick={() => props.onFill(162)} fill={props.fillColors[162]} d="M480.56,442.64c20.73,4.1,41.24,9.16,63.2,11.21c-3.39,3.95-6.04,5.16-9.5,2.82
				c-2.8-1.89-6.22-0.87-9.11-1.71c-9.13-2.65-18.33-4.2-27.85-4.16c-2.05,0.01-4.2-1.13-5.78-2.69
				C488.45,445.08,484.66,443.65,480.56,442.64z"/>
			<path onClick={() => props.onFill(163)} fill={props.fillColors[163]} d="M132.23,1405.22c1.46-4.47,2.94-8.98,4.38-13.36c1.31-0.47,2.39-0.09,2.97,0.59
				c3.32,3.94,7.21,6.45,12.53,7.14c2.26,0.29,5.07,2.55,3.89,5.36c-0.96,2.29-4.08,2.24-6.26,1.25c-3.59-1.62-6.74-1.88-10.36,0.2
				C137.3,1407.61,134.52,1407.25,132.23,1405.22z"/>
			<path onClick={() => props.onFill(164)} fill={props.fillColors[164]} d="M960.46,867.09c-1.6-0.77-2.74-1.33-3.93-1.91c1.13-3.14,3.63-4.6,5.73-6.43
				c4.15-3.63,8.26-7.31,9.83-12.9c1.94-6.89,0.12-12.75-4.94-17.69c8.91,1.82,13.19,7.43,12.17,16.19c-0.12,1.05-0.9,2.16-1.67,2.96
				c-2.97,3.13-6.85,5.46-9.01,9.27C966.47,860.42,963.06,863.12,960.46,867.09z"/>
			<path onClick={() => props.onFill(165)} fill={props.fillColors[165]} d="M607.75,906.29c5.58,0.37,4.83-4.85,6.7-7.73c1.81-0.26,3.79-0.08,4.56,1.34c2.74,5.08,5.57,3.2,8.62,0.51
				c0.48-0.42,1.12-0.66,1.66-0.98c2.22,2.54,2.22,2.54,0.22,21.86c-0.74-3.04-1.21-5.46-1.92-7.79c-1.55-5.06-6.29-6.73-11.04-4.11
				c-4.02,2.21-6.48-0.24-8.69-3.18C607.85,906.2,607.75,906.29,607.75,906.29z"/>
			<path onClick={() => props.onFill(166)} fill={props.fillColors[166]} d="M245.38,1143.69c-1.36-2.2-2.49-4.01-3.69-5.95c3.72-2.29,7.07-1.14,10.36,0.95
				c2.52,1.6,4.96,3.38,8.07,3.7c2.66,0.28,3.68-0.77,2.39-4.21c3.64,2.91,5.39-2.17,8.72-0.68c-2.9,3.08-5.74,6.09-8.76,9.3
				c1.38,1.94,4.66,2,5.56,4.86c-1.63,1.23-3.33,2.51-5.49,4.16c-0.59-6.59-7.84-7.11-10.1-12.4
				C251.51,1141.26,247.9,1142.61,245.38,1143.69z"/>
			<path onClick={() => props.onFill(167)} fill={props.fillColors[167]} d="M113.43,423.24c1.26-3.18,3.18-3.52,5.22-3.49c11.25,0.2,22.48-0.04,33.64-1.5
				c4.36-0.57,4.76,2.22,6.13,5.76C143.16,425.54,128.61,422.81,113.43,423.24z"/>
			<path onClick={() => props.onFill(168)} fill={props.fillColors[168]} d="M181.99,1151.76c4.23,1.63,5.6,8.16,11.29,4.58c-2.51,2.13-4.64,4.03-5.94,6.95
				c-1.15,2.6-9.03,2.83-12.21,0.44c-3.65-2.74-7.04-5.83-11.09-9.22c3.34-0.83,5.64-0.47,8.18,0.61
				C176.05,1156.74,180.06,1157.61,181.99,1151.76z"/>
			<path onClick={() => props.onFill(169)} fill={props.fillColors[169]} d="M165.18,1456.91c-5.41-2.6-7.1-0.05-8.09,4.29c-1.92,8.39-9.61,10.59-16.62,4.1
				c9.65-2.52,14.12-12,23.05-15.27c2.7-0.99,2.59-1.27,5.69,0.72C167.96,1452.86,164.82,1453.49,165.18,1456.91z"/>
			<path onClick={() => props.onFill(170)} fill={props.fillColors[170]} d="M372.95,439.61c2.27-0.15,3.88-0.25,6.09-0.4c-0.92,12.17,3.16,23.97-0.48,35.85
				C373.47,464.03,374.17,452,372.95,439.61z"/>
			<path onClick={() => props.onFill(171)} fill={props.fillColors[171]} d="M1088.9,700.14c2.83-18.91,6.87-37,8.72-55.46c1.27,4.63,0.64,9.17-0.08,13.65
				c-1.63,10.23-0.84,20.59-2.05,30.84C1094.98,693.54,1094.47,697.75,1088.9,700.14z"/>
			<path onClick={() => props.onFill(172)} fill={props.fillColors[172]} d="M663.05,503.98c6.63,2.83,8.57,7.87,8.26,14.52c-0.34,7.4-0.08,14.82-0.08,22.1
				c-5.94-2.42-4.93-8.74-4.34-13.04c1.07-7.89-1.23-14.7-3.55-21.75C663.19,505.35,663.18,504.83,663.05,503.98z"/>
			<path onClick={() => props.onFill(173)} fill={props.fillColors[173]} d="M150.83,465.28c-0.16-1.45,1.17-2.57,0.46-4.26c-1.44-3.4-0.23-6.79,0.69-10.17
				c0.86-3.18,2.48-4.76,6-4.22c2.09,0.32,4.28,0.06,6.67,0.06C163.34,455.19,157.08,460.09,150.83,465.28z"/>
			<path onClick={() => props.onFill(174)} fill={props.fillColors[174]} d="M715.09,1144.33c1.42,6.01,9.75,9.42,6.18,17.42c-1.17,2.62-1.7,5.72-1.05,8.79
				c0.79,3.71-2.25,4.99-4.61,6.05c-1.87,0.84-3.17-0.68-2.48-2.84c0.55-1.72,1.35-3.38,2.15-5.01c1.58-3.24,2.37-6.46,0.89-10.03
				C714.3,1154.18,715.99,1149.2,715.09,1144.33z"/>
			<path onClick={() => props.onFill(175)} fill={props.fillColors[175]} d="M876.72,1410.23c16.55,4.28,21.43,7.15,24.7,14.54c-3.29,2.43-8.19,1.35-10.31-2.82
				c-1.61-3.15-3.84-4.3-7.1-4.26C878.99,1417.75,876.39,1415.45,876.72,1410.23z"/>
			<path onClick={() => props.onFill(176)} fill={props.fillColors[176]} d="M1131.61,802.62c-3.81-12.1,0.29-22.4,2.24-32.89c0.67-3.59,0.81-3.56,5.04-2.94
				C1137.87,778.78,1132.82,789.88,1131.61,802.62z"/>
			<path onClick={() => props.onFill(177)} fill={props.fillColors[177]} d="M811.61,1169.84c-8.3,8.56-8.78,19.66-10.11,31.09c-2.54-10.15-4.98-19.76,0.38-29.72
				c2.99-0.39,6.41-0.84,9.82-1.29C811.7,1169.92,811.61,1169.84,811.61,1169.84z"/>
			<path onClick={() => props.onFill(178)} fill={props.fillColors[178]} d="M339.67,1137.41c-1.34,2.8-4.18,1.42-5.35,3.31c-0.4,0.65-0.96,1.2-1.44,1.79
				c1.11,1.88,3.1,0.28,4.83,1.22c-3.49,2.61-6.96,3.71-10.37,1.47c-3.67-2.41-5.56-6.04-4.45-11.45
				C328.46,1134.97,333.94,1136.16,339.67,1137.41z"/>
			<path onClick={() => props.onFill(179)} fill={props.fillColors[179]} d="M692.42,1341.69c3.47,7.08,4.49,15.18,10.85,22.38c-9.81-3.01-17.92-5.71-24.99-10.79
				c-1.45-1.04-3.02-2.24-2.95-4.35c0.05-1.23,0.43-1.36,4.8-2.33c1.33,5.1,3.98,9.32,9.22,11.26c2.16,0.8,4.66,1.93,6.52-0.19
				c1.75-1.99-0.04-4.05-0.86-5.95C693.64,1348.54,690.38,1346.01,692.42,1341.69z"/>
			<path onClick={() => props.onFill(180)} fill={props.fillColors[180]} d="M86.86,1381.43c2.68-9.28,4.6-17.32,2.8-26.47c2.39,1.93,4.57,3.12,5.93,4.93
				c2.11,2.81,4.12,5.69,1.38,9.67C94.24,1373.54,91.29,1377.21,86.86,1381.43z"/>
			<path onClick={() => props.onFill(181)} fill={props.fillColors[181]} d="M47.43,1362.27c-2.11-7.04-2.21-13.36-1.68-19.51c0.31-3.67,3.23-6.63,8.73-8.42
				C51.07,1343.96,52.31,1353.31,47.43,1362.27z"/>
			<path onClick={() => props.onFill(182)} fill={props.fillColors[182]} d="M81.2,425.85c-8.5,1.83-16.63,5.58-26.82,5.09c7.01-3.67,13.05-6.87,19.12-9.99
				c1.98-1.02,3.1,0.51,4.12,1.77c1.03,1.27,1.73,2.85,3.61,3.14C81.24,425.86,81.2,425.85,81.2,425.85z"/>
			<path onClick={() => props.onFill(183)} fill={props.fillColors[183]} d="M118.61,1439.1c2.58-6.47,4.81-12.08,7.03-17.67c7.16,2.03,8.11,5.08,3.23,10.27
				C126.16,1434.57,123.24,1437.23,118.61,1439.1z"/>
			<path onClick={() => props.onFill(184)} fill={props.fillColors[184]} d="M96.58,1042.9c0.56,2.76,3.8,5.44-0.22,8.15c1.25,1,2.13,1.7,3.4,2.72c-3.46,1.32-2.1,5.11-4.12,7.21
				l0.08,0.09c-0.35-4.22-4.89-5-6.67-8.13c0.31-1.24,2.36-0.15,2.43-2.02c-0.13-1.4-1.94-1.83-2.7-3.3c1.21-1.29,3.3,0.19,4.53-1.65
				c0.77-1.89-2.31-1.82-2.37-3.54c2.02-0.79,3.81,0.77,5.76,0.6C96.69,1043.03,96.58,1042.9,96.58,1042.9z"/>
			<path onClick={() => props.onFill(185)} fill={props.fillColors[185]} d="M154.43,1136.14c0.77,5.66,4.72,9.85,4.53,15.47c-3.98-2.28-8.1-4.61-12.18-7
				c-1.53-0.89-2.69-2.13-1.74-4.05c0.84-1.71,2.19-1.55,3.77-0.87C151.9,1141.02,152.52,1137.87,154.43,1136.14z"/>
			<path onClick={() => props.onFill(186)} fill={props.fillColors[186]} d="M445.49,1284.56c-4.45,8.62-8.73,17.23-11.59,26.51c0.88-6.68,1.6-13.39,2.7-20.04
				C437.3,1286.74,441.47,1283.81,445.49,1284.56z"/>
			<path onClick={() => props.onFill(187)} fill={props.fillColors[187]} d="M128.25,1402.07c-2.21-1.63-5.47-12.47-4.25-14.26c1.28-1.89,3.28-1.19,4.96-0.76
				c1.71,0.43,3.81,0.97,3.24,3.48C131.29,1394.49,130.63,1398.57,128.25,1402.07z"/>
			<path onClick={() => props.onFill(188)} fill={props.fillColors[188]} d="M909.84,1414.47c5.3,4.09,9.83,7.58,14.73,11.36c-1.74,1.38-3.21,2.54-4.6,3.63
				C913.16,1427.08,910.32,1422.21,909.84,1414.47z"/>
			<path onClick={() => props.onFill(189)} fill={props.fillColors[189]} d="M16.05,1418.22c2.4-0.13,4.52-0.25,6.94-0.39c2.53,4.83,2.64,10.28,3.52,15.73
				C21.95,1431.08,18.9,1426.61,16.05,1418.22z"/>
			<path onClick={() => props.onFill(190)} fill={props.fillColors[190]} d="M624.64,513.41c12.1,5.63,16.93,16.49,21.58,27.5C641.02,530.4,630.89,523.87,624.64,513.41z"/>
			<path onClick={() => props.onFill(191)} fill={props.fillColors[191]} d="M107.7,1498.24c3.14-8.11,8.29-14.24,16.74-17.18c1.31,3.14-0.69,5.86-6.7,8.93
				C113.78,1492.01,111.47,1495.96,107.7,1498.24z"/>
			<path onClick={() => props.onFill(192)} fill={props.fillColors[192]} d="M129.76,1437.67c1.77-2.03,3.27-3.76,4.99-5.74c4.35,2.86,8.71,5.44,13.54,7.61
				C143.67,1441.83,136.11,1441.07,129.76,1437.67z"/>
			<path onClick={() => props.onFill(193)} fill={props.fillColors[193]} d="M34.59,431.79c8.84-3.34,17.45-6.35,27.68-8.09C52.97,428.55,45.35,434.89,34.59,431.79z"/>
			<path onClick={() => props.onFill(194)} fill={props.fillColors[194]} d="M898.93,1416.98c3.14-0.38,5.56-0.67,8.17-0.98c1.29,3.33,2.05,6.68,4.23,9.97
				C903.63,1427.18,903.03,1420.37,898.93,1416.98z"/>
			<path onClick={() => props.onFill(195)} fill={props.fillColors[195]} d="M51.28,1440.58c4.59,10.64,13.46,19.27,14.91,31.41C61.64,1461.32,54.76,1451.78,51.28,1440.58z"/>
			<path d="M377.26,1223.78c-0.9-4.71-2.3-9.22-4.42-13.58c-1.75-3.6-2.19-7.63-1.1-12.35c3.23,9.99,4.62,20.04,11.61,28.17
				c-2.71,0.67-3.17-2.14-4.95-1.08C378.02,1224.56,377.64,1224.17,377.26,1223.78z"/>
			<path onClick={() => props.onFill(196)} fill={props.fillColors[196]} d="M522.25,443.69c-4.54-3.67-6.56-7.86-7.68-14.48c3.5-1.15,5.51-0.4,5.86,3.72
				C520.7,436.11,521.47,439.26,522.25,443.69z"/>
			<path onClick={() => props.onFill(197)} fill={props.fillColors[197]} d="M581.34,1200.4c3.68,4.02,7.47,7.83,12.79,10.2C583.85,1213.71,585.25,1213.57,581.34,1200.4z"/>
			<path onClick={() => props.onFill(198)} fill={props.fillColors[198]} d="M838.32,1350.76c-0.73-0.56-2.15,0.11-2.32-1.88c1.13-1.22,2.53-2.73,4.21-4.55c2.5,3.24,0,7.74,4.35,9.88
				c-3.41,2.92-5.81,3.18-8.23,0.23C836.48,1353.01,838.47,1352.78,838.32,1350.76z"/>
			<path onClick={() => props.onFill(199)} fill={props.fillColors[199]} d="M767.9,1136.98c-2.88-0.06-5.65-0.77-7.9-2.5c-2.35-1.8-4.57-2.45-7.08-0.47
				c-1.02-1.92-1.51-3.58,0.28-4.67c1.78-1.09,3.93-1.17,5.64,0.09c3.17,2.32,6.65,4.3,9.12,7.48
				C767.95,1136.91,767.9,1136.98,767.9,1136.98z"/>
			<path onClick={() => props.onFill(200)} fill={props.fillColors[200]} d="M310.15,460.69c-0.51-1.04-0.74-2.61-1.56-3.02c-6.92-3.51-4.72-9.2-3.57-15.14
				c3.88,5.76,3.48,12.29,5.06,18.26C310.07,460.79,310.15,460.69,310.15,460.69z"/>
			<path onClick={() => props.onFill(201)} fill={props.fillColors[201]} d="M599.56,582.7c-7.16,2.39-10.52-3.39-15.09-6.66c6.95-1.84,10.56,3.45,15.13,6.68
				C599.6,582.72,599.56,582.7,599.56,582.7z"/>
			<path onClick={() => props.onFill(202)} fill={props.fillColors[202]} d="M988.32,1143.44c-0.26,7.36-3.81,13.9-4.97,21.04c-3.8,0.45-2.99-1.76-2.36-3.66
				c2-5.95,5.84-11.11,7.39-17.27C988.37,1143.53,988.32,1143.44,988.32,1143.44z"/>
			<path onClick={() => props.onFill(203)} fill={props.fillColors[203]} d="M624.52,455.67c4.98-0.42,9.41,0.15,14.01-0.9C633.03,461.32,629.88,461.57,624.52,455.67z"/>
			<path onClick={() => props.onFill(204)} fill={props.fillColors[204]} d="M876.08,1417.38c-1.96-0.16-3.2-0.26-4.7-0.38c-0.51-3.64-1.93-7.02-1.1-11.32
				C875.68,1408.13,873.53,1413.52,876.08,1417.38z"/>
			<path onClick={() => props.onFill(205)} fill={props.fillColors[205]} d="M2.47,1210.37c-0.71-4.65-0.8-9.28-0.31-14.18C6.1,1198.81,6.1,1198.81,2.47,1210.37z"/>
			<path onClick={() => props.onFill(206)} fill={props.fillColors[206]} d="M545.62,429.66c3.56,1.35,5.19,8.82,3.59,14.93C547.62,442.41,546.09,436.21,545.62,429.66z"/>
			<path onClick={() => props.onFill(207)} fill={props.fillColors[207]} d="M399.32,1129.74c-5.95,0.47-10.95,0.07-16.62,1.34C387.11,1125.92,392.37,1125.56,399.32,1129.74z"/>
			<path onClick={() => props.onFill(208)} fill={props.fillColors[208]} d="M843.98,1325.75c-2.2,0.49-2.06,2.88-4.37,2.8c0.54-2.81,1.83-4.75,4.74-4.57
				c2.79,0.17,3.63,2.51,4.22,4.87c0.64,2.58-0.95,3.52-2.97,4.33c-1.97,0.79-2.85,0.35-2.64-1.9c1.08-1.5,2.92,0.84,4.02-0.81
				C847.37,1327.99,844.07,1327.89,843.98,1325.75z"/>
			<path onClick={() => props.onFill(209)} fill={props.fillColors[209]} d="M8.1,1455.14c-6.59-3-6.59-3-5.93-13.74C4.37,1446.49,6.04,1450.38,8.1,1455.14z"/>
			<path onClick={() => props.onFill(210)} fill={props.fillColors[210]} d="M806.68,705.04c2.47-3.47,0.23-6.05-2.37-8.98c4.75-0.27,6.85,2.93,10.05,4.79
				c-2.45,1.99-5.57,2.19-7.29,4.54C806.94,705.27,806.81,705.15,806.68,705.04z"/>
			<path onClick={() => props.onFill(211)} fill={props.fillColors[211]} d="M204.89,1068.62c-0.92-2.64-1.62-4.64-0.78-6.94c2.11,0.25,4.17,0.49,6.46,0.76
				C209.88,1065.56,207.62,1067,204.89,1068.62z"/>
			<path onClick={() => props.onFill(212)} fill={props.fillColors[212]} d="M46.99,1369.15c-2.52-1.79-1.96-3.54-2.15-5.62c0.85,0.92,1.41,1.53,2.27,2.47
				c2.89-2.47,3.29-6.52,5.48-9.35c0.39,0.22,0.79,0.44,1.18,0.66C51.57,1361.14,49.38,1364.97,46.99,1369.15z"/>
			<path onClick={() => props.onFill(213)} fill={props.fillColors[213]} d="M95.63,1060.98c-0.85,1.55-1.69,3.11-2.69,4.94c-2.61-2.17-4.82-4.26-6.75-7.35
				c3.69,0.44,6.3,2.67,9.51,2.5C95.71,1061.07,95.63,1060.98,95.63,1060.98z"/>
			<path onClick={() => props.onFill(214)} fill={props.fillColors[214]} d="M294.65,741.72c-0.88,5.23-3.23,9.88-5.61,14.52C289.6,750.92,291.46,746.07,294.65,741.72z"/>
			<path d="M349.18,1184.85c4.36-0.64,8.92,0.8,13.22-1.22c-3.28,2.42-6.11,6.06-10.95,3.36
				C350.69,1186.27,349.93,1185.56,349.18,1184.85z"/>
			<path onClick={() => props.onFill(215)} fill={props.fillColors[215]} d="M749.12,1389.86c-4.96-0.1-9.78-0.46-13.66-5.6C740.63,1386.38,744.88,1388.12,749.12,1389.86z"/>
			<path onClick={() => props.onFill(216)} fill={props.fillColors[216]} d="M337.67,1134.32c-2.96-0.45-6.08,0.27-8.72-2.28C333.05,1129.85,335.59,1131.58,337.67,1134.32z"/>
			<path onClick={() => props.onFill(217)} fill={props.fillColors[217]} d="M614.68,504.8c2.58,3.29,5.16,6.58,7.74,9.87C618.45,512.52,615.59,509.49,614.68,504.8z"/>
			<path onClick={() => props.onFill(218)} fill={props.fillColors[218]} d="M580.81,1315.47c1.33,2.78,2.27,4.73,3.26,6.81c-2.16,0.58-3.52-0.1-4.74-1.16
				C579.14,1319.34,579.9,1317.89,580.81,1315.47z"/>
			<path onClick={() => props.onFill(219)} fill={props.fillColors[219]} d="M537.69,443.31c-0.83-4.69-1.44-8.16-2.12-12.01C538.28,433.53,538.28,433.53,537.69,443.31z"/>
			<path onClick={() => props.onFill(220)} fill={props.fillColors[220]} d="M307.71,1135.61c1.4-0.79,2.76-1.56,4.21-2.37c0.82,2.34-0.35,4.01-1,5.85
				C309.13,1138.64,308.35,1137.34,307.71,1135.61z"/>
			<path onClick={() => props.onFill(221)} fill={props.fillColors[221]} d="M770.94,1387.59c-0.9-1.36-1.8-2.72-2.84-4.29c0.88-1.12,1.76-2.23,3.28-4.15
				C770.98,1382.47,770.95,1384.92,770.94,1387.59z"/>
			<path onClick={() => props.onFill(222)} fill={props.fillColors[222]} d="M46.57,1421.02c0.61,3.31,1.21,6.62,1.82,9.93C46.68,1427.89,45.76,1424.65,46.57,1421.02z"/>
			<path onClick={() => props.onFill(223)} fill={props.fillColors[223]} d="M81.76,1392.76c0.45-2.17,0.89-4.34,1.36-6.63C85.12,1387.96,85.12,1387.96,81.76,1392.76z"/>
			<path onClick={() => props.onFill(224)} fill={props.fillColors[224]} d="M346.68,692.97c-1.6-3.22-0.96-5.33-1.35-8.76C347.34,687.42,347.52,689.49,346.68,692.97z"/>
			<path onClick={() => props.onFill(225)} fill={props.fillColors[225]} d="M811.7,1169.92c2.36-1.43,4.72-2.86,7.88-4.78c-1.42,4.97-5.26,3.85-7.96,4.71
				C811.61,1169.84,811.7,1169.92,811.7,1169.92z"/>
			<path d="M194.15,674.9c-1.48-4.72-0.32-9.03-2.32-13.35c-4.1,11.67-5.46,23.89-11.92,34.68c-2.16-2.1-1.26-4.13-1.11-5.96
				c1.05-12.26,2.66-24.5,2.24-36.83c-0.35-10.12-0.55-20.31-3.71-30.1c-0.76-2.34-1.36-4.81-3.28-6.6c-3.56,0.97-5.8,4.54-9.65,4.87
				c-4.2-7.38,0.18-19.69-13.69-22.01c3.21-21.51,3.66-43.5,6.82-66.19c3.1,2.57,1.99,4.82,1.83,6.7c-0.67,7.98-0.81,15.94,0.28,23.9
				c0.5,3.67,1.51,7.15,3.48,10.31c1.36,2.18,3.35,4.77,5.65,4.48c6.76-0.84,8.9,5.63,13.67,7.79c1.32,0.6,1.15,2.28,1.48,3.56
				c1.1,4.18,2.26,8.35,4.52,12.1c2,3.31,4.86,6.21,8.74,5.61c5.32-0.83,5.62,2.04,5.38,5.68c-0.74,11.12-1.5,22.24-2.46,33.34
				c-0.62,7.22,0.66,14.68-2.51,21.62C196.7,670.42,196.52,672.69,194.15,674.9z M182.05,685.95c4.27-9.91,5.35-20.77,9.99-31.01
				c3.3,3.5,1.76,7.91,4.62,11.05c1.21-18.43,2.43-36.85,3.64-55.24c-8.76-0.86-12.55-3.55-15.6-10.82
				c-1.09-2.59-1.85-5.33-2.64-8.04c-1.21-4.13-7.62-9.74-11.86-10.28c-4.13-0.52-6.83-3.04-9.1-6.31
				c-2.03-2.92-2.62-6.44-4.18-10.34c-1.23,11.36-2.37,21.89-3.47,32.1c10.98,3.6,9.51,13.78,12.61,21.51
				c0.99-0.53,1.73-0.87,2.42-1.3c6.99-4.32,7.69-4.07,10.6,3.87c0.34,0.93,0.6,1.9,0.87,2.86c2.84,9.84,3.36,19.97,3.66,30.12
				C183.93,664.77,182.59,675.34,182.05,685.95z"/>
			<path d="M244.17,730.73c-4.32,5.86-5.78,12.42-10.01,17.74c-2.99-2.36-2.84-5.42-2.93-8.28c-0.08-2.7-0.5-4.47-3.71-5.37
				c-4.26-1.2-5.67-5.74-8.25-9.18c-1.59,2.02-1.62,4.14-1.62,6.24c-0.04,9.49-0.19,18.98-0.01,28.46
				c0.37,19.82-0.52,39.59-1.89,59.35c-0.08,1.09-0.33,2.18-0.49,3.2c-1.33,0.01-1.28-0.75-1.24-1.37c1.68-28.6,1.72-57.21,1.2-85.84
				c-0.09-5.07,0.12-10.21,3.31-14.88c3.06,1.97,3.52,5.2,5.29,7.51c1.81,2.36,3.24,5.12,7.05,4.44c2.13-0.38,2.35,1.41,2.5,2.92
				c0.24,2.4,0.36,4.8,0.61,8.26c2.84-4.01,4.02-7.61,5.69-10.95c2.58-5.17,5.25-5.37,8.42-0.66c2.32,3.44,4.85,5.62,9.5,6.18
				c4.75,0.57,8.23,5.04,13.02,6.62c1.96,0.64,1.35,3.91,1.39,6c0.34,15.99-0.51,31.95-0.98,47.92c-0.32,10.8-2.08,21.84,0.81,32.34
				c3.71,13.48,2.88,27.41,5.59,40.92c0.18,0.89,0.02,1.86-0.34,2.81c-2.73-4.81-2.86-10.19-3.42-15.36
				c-1.04-9.58-2.38-19.03-4.29-28.51c-3.04-15.11-0.94-30.54-0.36-45.81c0.45-11.82,0.3-23.63,0.37-35.45
				c0.01-1.13,0.28-2.84-0.88-3.16c-3.94-1.11-6.9-4.99-10.4-5.4C251.35,740.6,247.81,736.65,244.17,730.73z"/>
			<path d="M265.29,919.85c-3.88-53.73-2.16-107.64-4.06-161.84c-5,1.46-8.74,0.36-11.75-3.63c-1.51-2.01-5.06,1.2-6.86-2.46
				c-2.3,15.32,0.65,30.16-0.73,44.98c-1.36,14.7-1.61,29.53-1.94,44.33c-0.73-15.36-1.63-30.78,0.07-46.07
				c1.7-15.24-3.2-30.7,1.17-46.52c4.74,2.93,10.13,1.98,12.99,6.78c0.82,1.37,3.1,0.91,4.69,0.25c4.06-1.69,4.8,0.32,4.96,3.91
				c0.99,22.3,1.17,44.6,0.82,66.91c-0.18,11.81,0.56,23.57,1.34,35.37c1.18,17.9-0.71,35.93,0.97,53.88
				c0.12,1.28-0.26,2.61-0.4,3.92C266.16,919.74,265.72,919.79,265.29,919.85z"/>
			<path d="M232.07,855.92c-2.64-6.63,1.13-85.65,4.19-94.28C234.82,793.92,233.44,824.92,232.07,855.92z"/>
			<path d="M314.64,1050.34c-1.92-14.82-1.76-29.65,0.12-44.49C316.25,1020.67,313.61,1035.51,314.64,1050.34z"/>
			<path d="M145.25,1271.56c-6.87-2.97-7.36-8.98-7.55-15.15C139.59,1261.69,146.47,1264.33,145.25,1271.56z"/>
			<path d="M158.2,1210.38c2.23,2.94,4.46,5.89,6.87,9.07C161.28,1218.58,159.74,1216.74,158.2,1210.38z"/>
			<path d="M172.15,1245.12c-2.79-2.53-0.79-5.65-1.35-8.46C173.61,1239.19,172.47,1242.2,172.15,1245.12z"/>
			<path d="M166.64,1217.96c-3.62-2.7-4.33-5.78-4.61-9.07C164.32,1211.2,165.69,1213.96,166.64,1217.96z"/>
			<path d="M167.88,1238.5c0.99,2.39,1.13,4.79,0.33,7.76C165.92,1243.45,166.97,1240.96,167.88,1238.5z"/>
			<path d="M653.31,1289.04c1.29-10.5,2.53-21,3.89-31.49c0.71-5.46,1.01-10.92,0.68-16.41c-0.2-3.35-0.02-6.61,1.02-9.85
				c1.84-5.7,0.34-8.73-5.2-11.37c-0.84-0.4-1.69-1.21-2.07-2.04c-2.36-5.11-2.27-10.37-0.07-15.75c0.46,3.79,0.88,7.59,1.38,11.37
				c0.29,2.23,0.79,3.83,3.76,4.28c3.68,0.55,6.95,6.7,5.22,11.56c-2.63,7.4-0.83,14.81-1.63,22.17
				c-1.37,12.65-2.88,25.25-5.54,37.68C654.27,1289.14,653.79,1289.09,653.31,1289.04z"/>
			<path d="M139.54,974.29c-1.1-15.56-2.43-30.78-3.9-46.2c3.89,10.41,8.16,20.53,12.78,31.22c1.51-2.47-0.07-5.13,1.99-6.95
				c1.98,0.79,1.86,2.75,2.34,4.39c3.11,10.6,3.73,21.58,4.9,32.47c0.57,5.28,1.13,10.57,1.75,15.85c0.37,3.14,0.36,5.3-3.8,6.5
				c-2.25,0.65-4.95,3.66-4.85,7.5c0.15,6.07,0.34,12.18-1.59,18.27c-7.9-22.38-10.09-45.83-15.1-69.69
				C138.22,969.19,136.78,972.83,139.54,974.29z M139.93,945.2c-0.22,0.13-0.45,0.26-0.67,0.4c1.19,12.92,2.37,25.83,3.57,38.86
				c-3.04-0.12-2-3.05-4.29-3.5c3.87,15.55,5.14,31.42,10.25,46.68c0-6.79,0-13.58,0-20.4c2.34,0.3,1.79,2.69,3.34,3.07
				c1.45-1.6,0.17-4.31,1.94-5.57c2.06,0.25,1.15,2.62,2.81,2.82c0.18-0.26,0.53-0.52,0.51-0.74c-1.67-13.84-3.37-27.68-5.09-41.52
				c-0.1-0.83-0.35-1.84-1.26-1.97c-4.74-0.68-5.47-4.8-7-8.15C142.55,951.9,141.29,948.53,139.93,945.2z"/>
			<path d="M82.72,728.62c-2.28-11.5,7.24-14.8,13.61-20.06c3.11-2.57,6.71,0.17,6.57,4.77c-0.13,4.27-1.26,8.43-0.15,12.82
				c1.26,4.97,3.16,8.66,8.48,10.62c7.91,2.93,7.82,11.21,10.34,17.64c0.52,1.34,0.63,3.02,0.39,4.44
				c-1.88,11.02,2.67,21.25,3.83,31.89c0.23,2.08,0.92,4.39,2.14,6.04c3.89,5.23,3.34,11.02,2.36,16.74
				c-2.18,12.79-3.48,25.67-4.93,38.55c-0.71,6.26-4.17,12.21-6.59,18.88c-3.4-3.04-3.11-6.09-3.33-8.98
				c-0.68-9.07-3.21-17.28-10.79-23.11c-1.3-1-1.56-2.42-2.13-3.8c-2.62-6.39-4.02-13.2-7.96-19.19c-2.88-4.4-3.44-10.02-3.06-15.43
				c0.46-6.59-1.78-12.43-4.76-18.07c-1.01-1.9-2.15-4.13-4.6-4.13c-9.54-0.03-14.9-5.34-18.59-13.54
				c-2.98-6.63-3.66-6.66-10.47-4.54c-0.79,0.24-1.62,0.37-2.44,0.46c-0.28,0.03-0.59-0.22-1.45-0.57c1.23-8.95,0.71-18.35,5.5-26.75
				c2.01-3.53,4.71-6.41,8.4-8.29c2.1-1.06,4.08-1.29,6.05,0.27c2.37,1.88,4.59,3.76,7.94,1.4
				C78.97,725.34,79.89,728.54,82.72,728.62z M118.52,865.43c0.68-5.34,4.71-9.43,4.86-14.62c0.33-11.16,1.79-22.18,3.71-33.15
				c1.29-7.38,2.78-14.66-2.16-21.55c-1.45-2.02-1.81-4.99-2.19-7.6c-1.5-10.28-4.79-20.36-3.37-30.98c0.21-1.58-0.07-3.48-0.8-4.87
				c-2.53-4.79-1.99-11.68-8.54-13.76c-5.88-1.87-8.24-6.19-9.53-11.68c-1.01-4.29-1.12-8.49-0.18-12.82
				c0.39-1.81-0.22-5.49-2.53-3.53c-5.07,4.31-13.07,6.26-12.99,15.48c0.05,5.63-0.85,5.69-6.11,3.59c-0.57-0.23-1.41-0.5-1.82-0.25
				c-3.03,1.82-5.54,0.51-7.73-1.4c-2.6-2.26-4.88-1.49-7.14,0.24c-2.83,2.16-4.86,4.95-6.32,8.21c-2.92,6.52-2.43,13.59-3.79,20.47
				c9.75-1.17,10.23-0.89,14.84,8.27c0.6,1.19,1,2.55,1.84,3.53c2.92,3.39,6.53,6.56,10.96,6.51c4.39-0.05,7,1.61,8.92,4.98
				c3.42,5.98,6,12.22,5.53,19.36c-0.34,5.05-0.01,10.44,2.62,14.54c5.95,9.27,7.1,20.81,15.17,29.16
				C117.29,849.28,118.1,857.48,118.52,865.43z"/>
			<path d="M566.43,1316.1c3.43-13.43-8.71-20.29-12.94-30.99C564.82,1294.61,570.38,1307.66,566.43,1316.1z"/>
			<path d="M496.18,1271.83c3.25-1.83,3.76-5.67,6.48-9.03c-0.26,4.6-1.95,7.63-4.53,10.13c-1.58,1.53-1.82,0.09-1.83-1.24
				L496.18,1271.83z"/>
			<path d="M496.3,1271.7c-2.15,0.84-2.78,3.72-6.43,4.23c2.8-3.45,5.06-6.23,7.59-9.34c0.81,2.39-1.4,3.52-1.28,5.24
				C496.18,1271.83,496.3,1271.7,496.3,1271.7z"/>
			<path d="M170.79,1426.32c0.89-3.2,3.29-3.79,5.76-4.03c4.44-0.42,11.72,3.62,12.8,7.87c1.17,4.61,5.07,5.25,8.18,6.22
				c2.89,0.9,6.76,0.66,9.54-0.56c4.92-2.15,6.26,0.69,7.6,4.09c0.75,1.92,1.7,3.94,0.15,6.45c-4.86-4.67-10.5-4.67-16.64-3.92
				c-6.82,0.83-11.75-4.04-14.35-9.02c-2.72-5.23-6.51-5.78-11.01-6.51C172.21,1426.82,171.64,1426.58,170.79,1426.32z
				M212.51,1441.32c-1.85-3.53-1.88-3.69-2.49-3.56c-5.98,1.23-11.96,2.49-17.98-0.14C198.3,1442.02,205.72,1437.56,212.51,1441.32z
				M183.95,1427.74c1.41,1.39-0.15,4.69,3.15,5.25C187.72,1430.25,186.17,1428.85,183.95,1427.74z"/>
			<path d="M246.97,1476.7c-4.56-2.76-7.54-7.32-13.2-7.12c-0.93,0.03-1.74-2.4-2.94-3.08c-3.64-2.06-7.07-5.05-11.87-2.65
				c-2.15,1.07-4.67,1.53-7.44,0.73c-0.52-4.11,0.34-7.7,4.1-9.75c7.46-4.06,11.16-4.45,14.44,0.45c3.43,5.12,8.6,5.57,13.21,7.24
				c6.43,2.32,8.1,4.82,5.28,11.08C248.16,1474.5,247.66,1475.36,246.97,1476.7z M214.22,1461.61c1.32,0.92,2.76,0.5,3.82-0.06
				c5.11-2.66,9.92-0.69,13.32,2.38c4.6,4.14,11.16,4.15,15.26,8.91c0.21-3.53,3.31-7.27-3.92-7.83c-4.62-0.36-11.09-1.23-13.5-7.42
				c-0.06-0.14-0.19-0.27-0.33-0.37c-1.97-1.45-4.23-4.14-6.43-2.71C219.49,1456.44,214.08,1456.03,214.22,1461.61z"/>
			<path d="M603.11,1410.44c-3.93-5.63-3.67-7.14,1.68-11.27c3.88-3,6.66-1.33,9.06,2.6c-3.88-2.01-7.4-2.58-10.3,1.35
				c-0.73,0.98-1.89,2.16-0.99,2.96c4.73,4.23,1.52,6.74-2.08,9.31c1.86,3.01,7.3,4.74,2.58,9.68c6.62,1.05,10.48,4.1,10.73,10.61
				c0.08,2.03,1.74,2.13,3.33,1.99c2.37-0.2,3.77,0.79,4.18,3.19c0.88,5.18,2.86,6.39,7.97,4.62c-0.37,2.59-1.88,3.8-4.04,3.15
				c-3.83-1.15-7.96-2.41-6.34-8.19c-4.26-0.4-7.52-1.06-7.4-6.59c0.05-2.31-2.09-5.52-5.85-5.79c-3.6-0.25-5.19-2.09-3.3-5.65
				c0.62-1.16,0.21-2.18-0.54-2.63C595.44,1415.97,599.6,1413.29,603.11,1410.44z"/>
			<path d="M551.37,774.73c2.22,1.06,2.46,3.01,3,4.69c2.67,8.22,6.26,16.02,10.27,23.68c2.19,4.17,1.82,9.07,2.09,13.71
				c0.29,5.01,0,9.68,2.51,14.77c2.49,5.06,2.07,11.89,0.93,17.69c-1.46,7.46-0.59,14.89-1.32,22.29c-0.37,3.68,0.21,7.59-2.06,10.99
				c-2.2-1.09-1.52-2.99-1.81-4.52c-0.58-2.97-1.09-6.34-3.83-7.81c-3.21-1.73-3.67-4.16-3.98-7.28
				c-1.02-10.22-1.29-20.63-6.57-29.94c-0.46-0.82-0.62-1.94-0.58-2.9c0.73-14.64-2.94-29.06-2.18-43.69
				C548.06,782.23,547.2,777.68,551.37,774.73z M551.26,779.65c-1.39,4.38-0.76,8.67-0.81,12.94c-0.16,12.24,3.18,24.29,1.9,36.6
				c-0.13,1.24,0.22,2.72,0.84,3.8c3.35,5.76,4.1,12.17,5.19,18.57c1.27,7.42-0.31,16.08,8.95,21.07
				c-2.26-11.21,0.76-21.95,0.37-32.86c-0.19-5.33-4.16-9.87-3.51-14.57C566.62,807.94,556.57,794.52,551.26,779.65z"/>
			<path d="M546.68,653.16c-5.11,0.45-2.86-7.02-7.77-6.9c-1.46,4.05-0.51,8.31-0.37,12.5c0.14,4.15,0.66,8.29,0.68,12.43
				c0.03,8.11,1.22,15.95,2.66,23.97c1.7,9.49,0.49,19.54,0.14,29.34c-0.37,10.62-1.2,21.23-1.84,31.85c-0.09,1.44,0.04,2.96-1.99,5
				c0-4.69-0.23-8.48,0.04-12.22c1.17-16.08,1.99-32.18,1.9-48.31c-0.03-4.32,0.14-8.65-2.49-12.49c-0.51-0.74-0.55-1.89-0.59-2.86
				c-0.42-11.62-0.86-23.25-1.17-34.87c-0.07-2.78-0.31-6.58,3.02-7.07c2.99-0.44,5.39,2.22,6.06,5.47
				C545.26,650.46,547.05,651.17,546.68,653.16z"/>
			<path d="M549.2,654.37c-0.08,8.86,2.35,16.31-0.35,24.16c-1.52,4.42-1.33,9.5-1.52,14.3c-0.74,18.75-1.31,37.51-1.96,56.26
				c-0.05,1.29,0.15,2.67-0.78,3.81C542.01,749.28,545.66,662.37,549.2,654.37z"/>
			<path d="M34.45,1165.95c-0.45,3.48,3.51,6.7-0.81,9.94c-1.89-1.83-3.14-3.61-3.11-6.08c1.73-0.62,1.43,1.72,2.75,1.46
				C33.85,1169.68,31.79,1167.72,34.45,1165.95z"/>
			<path d="M24.99,1221.11c-1.77-2.67-4.8-4.53-5.33-7.72c2.55,2.77,5.21,5.65,7.86,8.52c-0.76,0.71-1.55-1.2-2.65-0.91
				L24.99,1221.11z"/>
			<path d="M16.77,1222.55c2.12,1.22,4.23,2.44,7.56,4.36c-4.67,0.47-6.04-2.08-8.21-3.2C16.33,1223.32,16.55,1222.94,16.77,1222.55z
				"/>
			<path d="M24.87,1221c-0.65,1.3-1.44,2.38-2.86,0.87c-1.52-1.61-3.53-2.87-4.24-5.53c3.17,0.78,4.13,4.42,7.21,4.76
				C24.99,1221.11,24.87,1221,24.87,1221z"/>
			<path d="M202.71,1226.05c-5.12,2.5-9.12,0.95-11.62-4.66c-2.12-4.76-0.93-8.99,0.41-13.85c1.64-5.98,5.56-11.71,3.31-18.7
				c-1.15-3.58,12.01-12.79,15.57-11.3c4.17,1.75,4.78,4.33,1.08,6.84c-4.83,3.27-8.14,6.78-5.92,13.08
				c0.19,0.54-0.03,1.39-0.36,1.91c-3.16,4.96-3.83,10.15-2.82,16.01c0.46,2.69,0.42,5.13,2.64,7.04c0.94,0.81,2.33,1.71,1.3,3.29
				C205.55,1226.87,204.38,1226.86,202.71,1226.05z M201.17,1223.45c-0.83-5.49-3.27-10.99-1.93-15.31
				c1.92-6.2,3.33-12.37,4.42-18.71c0.72-4.21,5.42-5.4,7.74-8.61c-3.56-1.42-14.1,5.78-13.82,9.09c0.09,0.99,0.15,2.02,0.47,2.94
				c0.59,1.71,0.4,3.44-0.38,4.85c-2.36,4.26-2.13,9.29-4.46,13.63c-1.47,2.74-0.91,6.15,0.51,9.17
				C195.39,1224.09,197.62,1225.89,201.17,1223.45z"/>
			<path d="M205.35,1233.09c5.36,3.74,2.25,9.64,5.12,13.52c2.3-0.36,4.46-1.23,5.79-3.26c4.51-6.9,7.92-14.54,14.61-20.46
				c0.14,4.19-0.42,8.65-2.52,12.1c-2.41,3.97-3.87,8.32-6.12,12.33c-3.32,5.9-6.43,11.74-12.01,15.91
				c-1.73,1.29-3.31,2.11-5.44,1.43c-2.22-0.71-2.15-2.58-2.44-4.41C200.87,1251.19,200.91,1242.32,205.35,1233.09z M223.6,1238.69
				c-0.38-0.22-0.75-0.44-1.13-0.66c-1.58,2.5-2.92,5.21-4.81,7.45c-2.17,2.56-5.29,4.73-8.6,4.04c-2.91-0.61-1.43-4.34-2.35-6.55
				c-0.48-1.16-0.39-2.56-0.57-4.02c-3.07,6.05-3.05,17.73,0.15,23.72C215.87,1257.2,219.06,1247.47,223.6,1238.69z"/>
			<path d="M1008.02,612.12c2.67,0,4.04,0.28,5.23-0.05c5.43-1.51,6.17,2.01,6.57,5.93c0.64,6.34,0.14,12.62-1.29,18.81
				c-3.97,17.14-8.31,34.2-9.97,51.78c-0.05,0.56-0.52,1.09-1.33,1.59c-0.99-3.11-0.59-6.22-0.25-9.36c1.83-16.72,7-32.77,9.83-49.29
				c0.45-2.6,0.48-5.28,0.73-7.92c0.5-5.13,0.21-9.67-6.68-9.76C1010.32,613.84,1009.79,613.23,1008.02,612.12z"/>
			<path d="M674.35,792.59c-2.37-7.22-3.92-14.61-4.47-22.19c-0.06-0.83-0.07-1.66-0.04-2.49c0.27-7.39-1.26-13.97-8.68-18.26
				c0.8,11.04,1.53,21.98,3.14,32.79c-1.96,0.45-2.18-0.6-2.39-1.56c-2.21-10.29-2.21-20.8-3.08-31.22c-0.16-1.97,0.21-3.98,2.8-5.76
				c3.02,6.99,11.19,10.53,10.38,19.98C671.18,773.45,673.94,782.93,674.35,792.59z"/>
			<path d="M405.02,1282.05c-4.23,5.19-7.72,5.84-12.32,2.82c-5.97-3.92-5.97-3.92-9.53,2.19c-4.39,7.52-13.29,8.09-19.01,1.35
				c-1.75-2.07-4.2-2.47-6.73-1.25c-2.92,1.41-5.7,1.47-8.5-0.38c-0.78-0.52-1.93-1.03-2.76-0.86c-7.05,1.47-12.89-2.5-19.15-4.32
				c-5.94-1.73-7.54-5.34-5.82-12.25c0.43,1.58,1.06,3.13,1.24,4.73c0.31,2.74,2.09,4.11,4.31,4.99c3.52,1.4,7.3,3.59,10.66,3.7
				c7.06,0.22,13.72,4.44,21.08,1.75c2.61-0.95,5.72,0.1,7.63,2.49c4.15,5.2,11.21,5.07,14.95-0.43c5.16-7.6,5.23-7.72,13.29-2.87
				C398.22,1286.03,400.96,1283.2,405.02,1282.05z"/>
			<path d="M853.63,1375.11c1.29,5.36,5.47,8.39,9.44,12.07C857.28,1386.36,853.07,1381.03,853.63,1375.11z"/>
			<path d="M852.8,1342.64c-0.67,2.43-1.53,4.08-4.14,2.98c-0.93-0.39-1.73-1.31-1.16-2.35
				C848.74,1341.04,850.55,1341.2,852.8,1342.64z"/>
			<path d="M378.6,1150.78c-0.33,3.12-1.99,4.96-5.06,5.1c-1.7,0.08-3.55-0.57-3.26-2.76c0.42-3.1,1.73-5.87,5.36-6.1
				C378.07,1146.88,378.32,1148.99,378.6,1150.78z"/>
			<path d="M348.75,1176.67c2.49-1.15,3.32,1.15,4.86,1.26c2.03-0.78,3.21-3.44,6.05-2.66c-0.29,3.62-4.79,4.12-4.89,8.32
				C352.43,1180.91,350.66,1178.87,348.75,1176.67z"/>
			<path d="M365.61,1179.73c-1.5-4.81-2.7-8.86-7.51-12.06C364.55,1169.82,366.59,1173.26,365.61,1179.73z"/>
			<path d="M372.09,1164.66c-0.95,2.74-1.69,5.54-4.55,7.75C367.76,1168.67,369.99,1166.65,372.09,1164.66z"/>
			<path d="M574.28,1279.85c1.42,1.95,2.86,3.89,4.26,5.86c4.17,5.85,8.87,11.23,14.45,15.78c5.09,4.15,8.24,9.53,10,15.74
				c0.44,1.56,1.37,3.37,0.07,5.12c-1.6-0.55-1.49-1.95-1.74-2.91c-1.89-7.16-5.87-12.84-11.36-17.77
				c-6.69-6.02-12.42-12.91-16.59-20.96C573.67,1280.41,573.97,1280.13,574.28,1279.85z"/>
			<path d="M116.63,1083c5.08,4.89,5.08,5.03,0.27,9.35c-1.86-1.8,0.73-2.85,0.69-4.78c-5.96-3.75-1.18-9.19-2.22-14.61
				c-4.13-0.08-9.02-0.51-9.41-7.87c0.59-0.8,1.95-2.64,3.33-4.51c-1.85-2.54-4.86-4.97-4.84-8.03c0.02-3.69,4.07-5.88,5.69-9.58
				c-6.03-7.78,2.48-13.31,3.44-20.23c0.96,3.14,1.1,3.22-0.46,6.71c-1.21,2.73-2.46,5.41-1.06,8.32c2.2,4.61-0.54,7.73-2.97,11.02
				c-0.85,1.15-1.59,2.38-2.46,3.69c3.68,2.12,6.11-1.39,9.51-1.49c-0.44,3.16-3.27,3.9-4.59,5.79c5.06,4.76-3.15,5.35-2.52,9
				c0.81,1.42,2.95,0.37,4.23,1.45c0.64,1.09-0.49,1.77-1.01,2.96c1.84,0.15,3.31,0.23,4.77,0.39c1.34,0.14,3.56,1.06,2.65,1.98
				C116.47,1075.8,120.07,1080.38,116.63,1083z"/>
			<path onClick={() => props.onFill(226)} fill={props.fillColors[226]} d="M182.05,685.95c0.54-10.61,1.88-21.18,1.56-31.84c-0.3-10.15-0.83-20.28-3.66-30.12
				c-0.28-0.96-0.53-1.92-0.87-2.86c-2.91-7.94-3.61-8.19-10.6-3.87c-0.69,0.43-1.43,0.77-2.42,1.3c-3.1-7.73-1.64-17.91-12.61-21.51
				c1.1-10.21,2.24-20.74,3.47-32.1c1.56,3.9,2.16,7.42,4.18,10.34c2.27,3.28,4.97,5.79,9.1,6.31c4.23,0.53,10.65,6.15,11.86,10.28
				c0.79,2.71,1.55,5.44,2.64,8.04c3.05,7.27,6.84,9.96,15.6,10.82c-1.21,18.39-2.43,36.82-3.64,55.24
				c-2.86-3.15-1.32-7.55-4.62-11.05C187.41,665.18,186.32,676.03,182.05,685.95z"/>
			<path onClick={() => props.onFill(227)} fill={props.fillColors[227]} d="M139.93,945.2c1.36,3.33,2.62,6.71,4.11,9.97c1.53,3.36,2.26,7.47,7,8.15c0.91,0.13,1.16,1.14,1.26,1.97
				c1.71,13.84,3.42,27.67,5.09,41.52c0.03,0.22-0.32,0.49-0.51,0.74c-1.66-0.2-0.75-2.57-2.81-2.82c-1.77,1.26-0.49,3.98-1.94,5.57
				c-1.55-0.38-1-2.77-3.34-3.07c0,6.82,0,13.61,0,20.4c-5.11-15.25-6.38-31.13-10.25-46.68c2.29,0.45,1.25,3.38,4.29,3.5
				c-1.2-13.03-2.38-25.95-3.57-38.86C139.48,945.46,139.71,945.33,139.93,945.2z"/>
			<path onClick={() => props.onFill(228)} fill={props.fillColors[228]} d="M118.52,865.43c-0.42-7.96-1.23-16.15-6.76-21.88c-8.07-8.35-9.22-19.89-15.17-29.16
				c-2.63-4.1-2.95-9.49-2.62-14.54c0.47-7.14-2.11-13.38-5.53-19.36c-1.92-3.36-4.53-5.03-8.92-4.98
				c-4.43,0.05-8.04-3.12-10.96-6.51c-0.84-0.98-1.24-2.34-1.84-3.53c-4.61-9.16-5.09-9.44-14.84-8.27
				c1.36-6.88,0.87-13.95,3.79-20.47c1.46-3.26,3.49-6.05,6.32-8.21c2.27-1.73,4.55-2.51,7.14-0.24c2.18,1.9,4.7,3.22,7.73,1.4
				c0.41-0.25,1.25,0.03,1.82,0.25c5.25,2.1,6.15,2.04,6.11-3.59c-0.08-9.22,7.92-11.17,12.99-15.48c2.31-1.96,2.92,1.72,2.53,3.53
				c-0.94,4.33-0.83,8.53,0.18,12.82c1.3,5.49,3.65,9.81,9.53,11.68c6.55,2.08,6.01,8.98,8.54,13.76c0.74,1.39,1.01,3.3,0.8,4.87
				c-1.42,10.62,1.87,20.7,3.37,30.98c0.38,2.61,0.74,5.57,2.19,7.6c4.94,6.9,3.45,14.17,2.16,21.55
				c-1.92,10.97-3.38,21.99-3.71,33.15C123.22,856,119.2,860.09,118.52,865.43z"/>
			<path onClick={() => props.onFill(229)} fill={props.fillColors[229]} d="M212.51,1441.32c-6.79-3.76-14.21,0.7-20.47-3.7c6.03,2.63,12,1.37,17.98,0.14
				C210.63,1437.63,210.66,1437.79,212.51,1441.32z"/>
			<path onClick={() => props.onFill(230)} fill={props.fillColors[230]} d="M183.95,1427.74c2.22,1.11,3.77,2.51,3.15,5.25C183.8,1432.43,185.36,1429.13,183.95,1427.74z"/>
			<path onClick={() => props.onFill(231)} fill={props.fillColors[231]} d="M214.22,1461.61c-0.13-5.58,5.27-5.17,8.22-7.09c2.2-1.43,4.46,1.25,6.43,2.71
				c0.13,0.1,0.27,0.23,0.33,0.37c2.41,6.19,8.88,7.06,13.5,7.42c7.23,0.56,4.13,4.3,3.92,7.83c-4.1-4.77-10.66-4.77-15.26-8.91
				c-3.4-3.07-8.21-5.04-13.32-2.38C216.98,1462.11,215.54,1462.54,214.22,1461.61z"/>
			<path onClick={() => props.onFill(232)} fill={props.fillColors[232]} d="M551.26,779.65c5.31,14.87,15.36,28.29,12.94,45.56c-0.66,4.69,3.32,9.24,3.51,14.57
				c0.39,10.91-2.64,21.64-0.37,32.86c-9.25-4.99-7.68-13.65-8.95-21.07c-1.09-6.39-1.84-12.8-5.19-18.57
				c-0.63-1.08-0.97-2.56-0.84-3.8c1.27-12.31-2.06-24.36-1.9-36.6C550.5,788.32,549.87,784.03,551.26,779.65z"/>
			<path onClick={() => props.onFill(233)} fill={props.fillColors[233]} d="M201.17,1223.45c-3.54,2.44-5.77,0.63-7.44-2.95c-1.41-3.02-1.97-6.43-0.51-9.17
				c2.32-4.34,2.1-9.37,4.46-13.63c0.78-1.41,0.97-3.14,0.38-4.85c-0.32-0.93-0.39-1.95-0.47-2.94c-0.29-3.32,10.25-10.51,13.82-9.09
				c-2.31,3.21-7.01,4.4-7.74,8.61c-1.09,6.35-2.5,12.51-4.42,18.71C197.9,1212.47,200.33,1217.96,201.17,1223.45z"/>
			<path onClick={() => props.onFill(234)} fill={props.fillColors[234]} d="M223.6,1238.69c-4.54,8.78-7.74,18.52-17.31,23.97c-3.2-5.99-3.22-17.68-0.15-23.72
				c0.19,1.46,0.09,2.86,0.57,4.02c0.92,2.21-0.57,5.94,2.35,6.55c3.31,0.7,6.43-1.47,8.6-4.04c1.9-2.23,3.23-4.95,4.81-7.45
				C222.85,1238.25,223.23,1238.47,223.6,1238.69z"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M839.34,272.47c1.66-1.83,3.46-3.57,4.97-5.52c4.63-5.95,10.23-9.2,18.01-6.99c0.46,0.13,1.15,0.23,1.47-0.01
				c7.35-5.54,16.97-0.9,24.48-5.5c1.36-0.83,3.15-0.89,3.94-0.36c6.72,4.47,10.54-1.02,15.05-4.11c4.56-3.11,9.02-5.98,14.99-6.09
				c11.95-0.2,20.21,5.16,25.46,15.6c2.6,5.17,4.93,10.5,4.47,16.48c-0.28,3.62,1.45,4.23,4.54,4.05c5.54-0.31,11-0.31,12.56,6.93
				c0.3,1.37,2.1,1.91,3.42,1.96c5.42,0.21,9.41,3.03,12.4,7.11c1.78,2.43,3.48,2.55,6.1,1.99c9.03-1.91,17.57-1.29,25.51,4.37
				c12.19,8.7,14.06,14.35,8.46,28.55c-0.11,0.29-0.01,0.66-0.01,1.8c5.68,1.57,8.72,8,15.97,8.46c4.1,0.26,8.16,3.51,10.37,7.3
				c2.21,3.78,5.37,4.74,9.12,5.44c10,1.87,15.38,9.05,19.42,17.5c1.5,3.13-1.27,6.63-6.43,8.97c-4.93,2.24-10.23,2.75-15.49,2.93
				c-16.54,0.57-32.98-1.14-49.45-2.37c-15.46-1.16-30.97-1.68-46.45-2.58c-25.79-1.51-51.6-1.61-77.42-2.13
				c-10.49-0.21-20.98-0.09-31.47-0.36c-2.04-0.05-4.21,0.6-5.94-1.61c1.49-1.32,3.35-2.96,5.66-5.02
				c-6.58-3.38-13.18-2.34-19.18-1.63c-14.04,1.66-27.8,5.37-41.98,6.27c-7.16,0.46-14.32,1.39-21.48,1.42
				c-32.3,0.16-64.6,0.25-96.9,0.01c-23.31-0.18-46.63-0.71-69.92-1.52c-13.23-0.46-26.42-2.12-39.44-4.54
				c-5.95-1.11-11.4-4.45-17.51-5.35c-6.7-0.98-13.12-1.83-19.08,3.36c-5.79,5.03-13.47,6.36-20.88,7.04
				c-40.06,3.66-80.24,1.37-120.36,2.18c-13.33,0.27-26.64,0.36-39.96-0.12c-5.16-0.18-9.76-1.1-14.13-4.81
				c-6.38-5.42-14.88-5.83-22.83-6.04c-17.86-0.48-35.66,1.21-53.45,2.78c-7.94,0.7-15.97,0.47-23.97,0.58
				c-15.32,0.2-30.65,0-45.94,0.7c-5.37,0.24-11.09-0.11-15.73,5.1c-3.55,3.98-9.82,4.49-15.25,5.16
				c-12.44,1.54-24.96,0.99-37.45,1.04c-5.66,0.02-11.37,0.48-16.97-0.1c-11.79-1.22-22.31,1.73-32.56,7.45
				c-5.49,3.06-12.07,3.12-18.39,3.76c-15.82,1.61-31.65,0.48-47.46,0.88"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.72,1457.28c-1.2,13.81-0.05,27.65-0.62,41.73c387.04,0,773.97,0,1160.9,0c0-498.96,0-998.16,0-1497.87c-1.82,0-3.66,0-5.49,0
				c-217.94,0-435.88,0-653.81,0.03c-1.65,0-3.42-0.59-4.99,0.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M176.04,48.6c6.89-0.65,14.52-7.03,15.96-13.5c0.84-3.8-2.22-6.63-4.54-7.24c-8.12-2.11-12.64-8.86-18.82-13.38
				c-3.18-2.33-6.05-4.01-10.09-3.93c-5.74,0.12-9.82-4.12-14.34-6.69c-3.39-1.93-6.51-2.74-10.13-2.74c-44.12,0-88.24,0-132.86,0
				c0,1.82,0,3.66,0,5.5c0,126.76,0,253.52,0.03,380.28c0,1.66-0.59,3.42,0.47,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.72,465.86c-1.06,1.58-0.47,3.34-0.47,5C1.22,559.47,1.22,648.08,1.22,736.7c0,7.6,4.5,15,11.52,17.39
				c6.95,2.36,11.87,8.32,19.39,9.87c3.19,0.66,5.07,4.99,6.47,8.25c2.62,6.12,6.43,11.21,10.99,16.03
				c6.08,6.43,9.05,14.69,12.14,22.88c4.42,11.74,10.46,23.3,11.96,35.56c1.67,13.69,10.81,23.52,14.49,35.91
				c2.83,9.53,6.02,18.98,2.43,28.99c-1.73,4.83-4.82,7.65-10.45,8.17c-5.18,0.48-8.46,5.03-11.89,8.45
				c-3.67,3.65-7.66,7.25-9.66,12.36c-0.62,1.59-2.37,2.45-3.95,2.43c-2.47-0.02-3.68,1.5-4.99,3.08c-0.68,0.82-1.2,1.84-2.02,2.47
				c-7.29,5.65-6.34,13.71-6.52,21.51c-0.07,2.83,1.16,4.45,3.54,5.52c4.47-3.63,12.17-2.71,13.44-9.86c1.6-0.36,2.24,0.41,3.08,0.77
				c8.39,3.6,9.73,3.02,14.47-5.41c2.27-4.04,5.01-7.49,10.48-6.52c1.11,0.2,2.1,0.04,3.03-0.95c3.19-3.41,6.46-6.76,10.94-8.57
				c0.46-0.19,1.02-1,0.97-1.45c-0.93-7.54,3.42-6.14,8.06-5.08c4.17,0.96,5.96-0.6,7.35-5.31c1.44-4.89,0.86-9.89,2.48-14.65
				c0.56-1.64,0.73-3.77,2.19-4.34c5.2-2.05,8.13-7.23,13.42-9.17c4.69-1.72,6.21-5.6,6.07-10.43c-0.08-2.74-1.26-5.94,3.93-6.23
				c3.46-0.19,6.51-7.76,5.47-11.75c-0.85-3.27-0.47-6.38,0.55-9.5c0.06-0.2,0.48-0.36,0.46-0.5c-1.02-6.81-0.01-13.8-0.81-20.45
				c-1.01-8.42-4.64-16.55,2.32-24.05c0.49-0.52,0.74-1.29,1-1.99c0.24-0.64,0.35-1.33,0.52-1.99c-5.66-6.44-6.12-12.59-1.49-20.49
				c0.73-1.24,1.75-2.99,0.91-3.92c-4.54-5.04,0.51-9.08,1.01-13.58c0.25-2.23,2-2.41,3.07-2.34c1.58,0.1,2.81,1.41,2.5,3.35
				c-0.81,5.04,1.81,7.9,5.97,10.06c1.58,0.82,3.17,2.66,2.36,4.36c-0.66,1.39-0.9,3.51-3.81,3.77c-4.19,0.38-6.69,9.91-3.97,14.27
				c1.05,1.68,2.7,2.96,3.93,4.54c3.11,4,3.24,7.8,1.96,13.09c-1.43,5.91-5.82,11.64-1.86,19.1c2.5,4.71-0.52,11.45-1.18,17.26
				c-0.07,0.59-0.58,1.45-0.36,1.99c3.5,8.45-0.84,16.96,0.26,25.53c1.03,8.02,0.24,16.18-4.27,23.48
				c-4.23,6.85-2.32,11.13,5.48,12.42c1.76,0.29,2.7,0.44,3.64,2.49c2.74,5.98,4.46,11.85,3.47,18.58c-0.65,4.4-0.9,9.15,0.07,13.43
				c1.27,5.58,0.72,10.72-0.67,16.04c-2.21,8.45-0.71,16.02,5.46,22.49c4.79,5.02,5.23,12.03,7.89,18.03
				c0.56,1.25,1.45,1.95,2.11,2.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M481.72,63.59c6.2-5.22,12.03-10.88,15.91-18.03c5.58-10.28,9.16-21.33,4.93-32.89C498.14,0.59,496.64,1.1,486.71,1.1
				C373.83,1.15,260.95,1.13,148.07,1.16c-1.65,0-3.42-0.59-4.99,0.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M676.51,449.37c11.21-2.61,22.43-4.34,33.98-2.07c4.07,0.8,8.31,0.97,12.47,1.03c9.82,0.14,19.65,0.12,29.47,0.02
				c7.33-0.07,14.37,1.06,21.54,2.75c6.35,1.5,13.25,1.35,19.92,0.28c13.11-2.1,25.7,1.59,38.46,3.46c10.59,1.55,21.05,3.51,32,1.73
				c8.65-1.41,17.49,0.26,25.92,2.84c11.63,3.56,24.01,2.93,35.57,7.72c16.62,6.88,33.89,12.15,50.84,18.26
				c3.96,1.43,7.75,2.25,12.02,1.53c7.05-1.19,14.11-1.1,20.97,1.42c7.14,2.62,14.36,2.56,21.46-0.02
				c8.93-3.25,17.79-3.99,27.01-1.03c8.55,2.75,16.81,0.61,24.97-2.39c8.09-2.98,16.3-3.58,24.46-0.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M846.33,955.07c-4.54-0.31-9.16,0.11-11.35,4.56c-2.06,4.19,0.6,7.78,3.38,10.91c0.54,0.61,1.76,0.91,1.91,1.53
				c2.27,9.31,10.65,11.72,17.5,16.05c8.08,5.12,16.29,10.42,19.93,19.95c0.92,2.4,1.31,5.08,0.74,7.98
				c-0.93,4.76,0.81,9.04,3.79,13.01c3.93,5.25,2.57,8.02-3.39,11.02c-17.03,8.55-35.61,9.68-53.98,11.93
				c-9.33,1.15-19.09-0.1-27.9,3.65c-5.89,2.5-11.87,3.73-18.03,4.93c-9.27,1.79-18.76,1.49-27.99,3.41c-1.34,0.28-3,0.26-3.94,1.06
				c-6.04,5.05-13.49,4.68-20.54,5.34c-13.87,1.31-27.08,4.92-39.96,10.05c-4.09,1.63-7.83,3.61-7.89,8.53
				c-0.06,4.79,3.23,7.37,7.4,9.01c2.45,0.96,4.09,2.24,4.13,5.48c0.05,4.2,4.14,5.69,6.88,7.97c1.38,1.15,2.87,2.25,4.49,2.98
				c3.96,1.79,4.98,4.54,3.5,8.55c-2.72,7.34,3.83,11.93,5.49,17.98c0.05,0.2,0.35,0.51,0.48,0.48c9.11-1.88,7.84,7.57,11.72,11.3
				c2.9,2.8,0.76,8.26-0.45,12.14c-0.89,2.85-0.72,5.07,0.29,7.49c-6.73,7.22-6.73,7.22-11.59,3.55c1.1-3.09,2.4-6.08,3.9-9.04
				c1.08-2.15,1.45-4.6,0.31-7.5c-2.09-5.32-0.85-11.4-2.68-16.92"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M274.43,414.39c7.79-0.31,15.34-2.27,23-3.29c7.55-1.01,15.35,0.37,22.93,1.06c11.24,1.01,22.22,4.23,33.46,5.91
				c15.61,2.33,31.28,3.57,46.94,5.18c11.48,1.18,22.98,2.56,34.5,3.63c3.71,0.35,7.25-1.04,10.98-1.12
				c7.12-0.15,14.07,0.87,20.96,2.23c3.04,0.6,5.18,2.37,6.71,5.81c3.71,8.39,4.84,17.19,6.45,26.04c1.98,10.9,6.81,20.9,11.22,31.06
				c8.12,18.71,13.86,38.24,17.05,58.42c2.42,15.32,7.19,30.02,10.92,45c1.57,6.3,2.63,12.98-1.85,18.98
				c-3.18,4.26-3.94,9.27-4.04,14.47c-0.38,19.37-2.65,38.63-3.3,57.97c-0.31,9.12-2.12,18.39,1.15,27.54
				c0.93,2.59,0.59,5.96-0.31,8.94c-3.55,11.77-3.82,24.1-0.07,35.33c3.22,9.64,5.11,19.27,6.5,29.14c1.1,7.79,1.46,15.69,2.62,23.47
				c1.35,9.08,1.29,18.4,5.33,27.04c2.45,5.26,1.49,11.31,2.52,16.96c3.2,17.55,5.11,35.31,10.31,52.53
				c1.74,5.75,3.33,10.73,8.19,14.49c2.03,1.57,2.34,4.52,2.46,6.92c0.3,5.98,0.84,12.11-0.11,17.95c-0.98,6.06-0.5,12.03-0.78,18.02
				c-0.44,9.35,0.36,18.66,0.96,27.98c0.07,1.12,0.28,2,0.95,3.04c6.35,9.97,3.61,21.2,3.89,31.94c0.1,3.75-5.76,7.2-9.8,6.41
				c-3.73-0.73-6.74-1.18-6.52,4.08c0.07,1.67-1.3,1.83-2.39,2.63c-3.04,2.19-5.32,5.14-4.59,9.37"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M937.74,1123.47c6.63-16.37,12-33.13,15.51-50.46c0.28-1.38,0.77-2.8,1.5-3.98c9.02-14.57,12.68-30.8,15-47.48
				c0.1-0.74,0.88-1.39,2-3.07c2.2,5.44,7.4,7.48,10.01,12.16c1.59-0.65,2.93-3.78,4.83-1.52c1.93,2.29,5.98,3.15,4.7,7.94
				c-4.17,15.7-6.69,31.78-10.89,47.5c-2.09,7.81,0.18,15.69,5.86,21.87c2.56,2.79,6.94-0.38,9.99,2.57
				c-3.9,18.84-9.08,37.76-18.54,55.23c3.35,1.55,3.35,1.55,6.39,1.24c2.54-8.52,3.78-17.7,7.89-25.33
				c6.13-11.37,7.05-24.72,13.23-35.33c6.46-11.09,3.75-22.86,7.37-33.82c4.15-12.6,7.2-25.58,12.15-37.92
				c2.15-5.36,4.22-11.43,11.91-10.99c1.34,0.08,1.94-0.8,2.46-1.56c2.94-4.29,6.99-4.64,11.89-3.22c2.4-5.81,5.18-11.4,7.01-17.28
				c1.61-5.19,4.01-9.3,9.12-11.45c5.96-2.51,9.7-7.58,13.94-12.04c2.95-3.1,5.2-3.94,7.58,0.52c1.79,3.36,4.75,5.5,8.45,6.52
				c3.01,0.83,4.15,3.88,6.24,5.2c-3.34,15.62-2.7,31.22-2.74,46.77"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1020.15,529.32c-6.84-3.81-14.64-3.6-22.01-4.77c-7.12-1.13-14.26-2.43-21.4-3.5c-5.71-0.85-11.38-0.28-17.04-1.14
				c-11.25-1.7-22.24-4.56-32.9-8.24c-15.49-5.35-31.51-8.62-47.54-10.51c-25.26-2.98-47.3-13.68-69.38-24.82
				c-6.46-3.26-12.77-6.77-19.06-10.35c-11.94-6.77-24.66-9.02-38.44-5.37c-5.29,1.4-11.29,0.53-16.91,0.05
				c-1.76-0.15-3.34,0.13-5.01,0.18"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.72,802.66c-1.07,1.04-0.47,2.35-0.47,3.5c-0.04,85.95-0.04,171.9,0,257.85c0,1.15-0.61,2.46,0.47,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M92.63,895.61c3.47-0.69,6.82-2.31,10.52-0.56c2.02,0.95,2.64-1.12,3.47-2.43c2.58-4.09,5.6-7.48,10.99-7.51
				c2.61-0.02,2.84-2.47,2.87-3.98c0.08-3.72,2.55-6.02,5.17-6.83c9.92-3.05,12.74-11.73,16.3-19.71c1.29-2.88-0.01-6.43-0.98-9.4
				c-1.67-5.12-1.36-9.93,1.18-14.49c1.53-2.75,1.94-5.52,1.95-8.53c0.04-8,1.63-15.89,0.21-24.03c-0.76-4.33-1.17-8.02-5.2-10.49
				c-2.68-1.64-2.95-4.83-4.28-7.31c-4.27-7.95-3.43-15.89-2.34-24.14c0.23-1.74-0.72-3.72-1.42-5.47
				c-3.23-8.14,0.88-15.42,1.96-23.92c3.63,3.12,5.99,6.57,10.58,6.07c0.72-0.08,2.03,1.72,2.42,2.87c1.68,5.02,4.38,9.01,9.53,11.03
				c0.66,0.26,1.34,1.22,1.47,1.95c1.41,7.96,7.28,13.96,9.45,21.5c1.95,6.75,6.09,12.3,9.17,18.42
				c5.45,10.85,11.59,21.35,16.37,32.53c6.54,15.29,11.95,31.06,21.39,45.04c5.44,8.06,7.46,17.77,10.63,26.9
				c0.83,2.39,1.46,5.12,3.06,6.93c8.85,10.07,10.31,22.73,12.93,35.05c2.34,11.02,4.54,22.17,10.5,31.96
				c8.58,14.11,14.05,29.58,19.3,45.04c5.47,16.11,9.79,32.6,14.67,48.91c0.18,0.6,0.96,1.01,1.46,1.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M268.94,244.49c-3.29-2.47-7.05-2.66-10.99-2.55c-3.89,0.1-7.61-1.29-10.94-3.04c-5.19-2.72-9.96-1.31-15.06,0.02
				c-6.69,1.74-9.57,0.19-12.97-5.92c-4.65-8.36-13.52-11.69-21.89-6.85c-11.33,6.56-13.53,2.35-17.06-7.15
				c-2.3-6.19-8.83-9.36-13.18-4.7c-5.57,5.97-12.12,6.02-18.8,7.17c-4.64,0.8-7.91,2.27-7.62,8.03c0.17,3.44-2.26,6.37-4.41,8.95
				c-3.66,4.39-8.65,3.14-10.42-2.46c-0.78-2.46-0.52-4.93-0.13-7.51c0.41-2.76,0.69-5.54-0.24-8.52c-1.49-4.81-3.82-7.72-9.14-7.99
				c-8.59-0.44-15.91-3.84-22.54-9.37c-5.21-4.35-11.35-6.75-18.41-5.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M315.39,154.04c-2.92-1.53-4.61-4.39-6.94-6.55c-3.75-3.48-8.03-5.33-13.04-4.88c-5.94,0.54-10.04-1.4-13.61-6.47
				c-8.01-11.36-26.08-15.7-38.84-11.09c-3.73,1.35-7.45,1.69-11.51,1.16c-4.07-0.53-8.32-0.02-12.46,0.39
				c-8.77,0.87-11.9-0.51-15.96-8.05c-3.74-6.95-8-13.17-17.01-12.92c-4.63,0.13-9.13,1.37-13.48,2.96
				c-4.86,1.78-9.58,1.99-14.52,0.05c-3.98-1.57-8.04-2.84-12.45-3.17c-5.67-0.41-10.06-3.46-12.99-8.4
				c-8.66-14.62-21.64-19.12-37.99-17.14c-6.84,0.83-13.51,0.89-19.55,5.02c-2.64,1.8-4.71,3.74-4.23,6.6c0.49,2.94,3.69,3.56,6.33,4
				c5.05,0.84,10.33,0.74,14.49,4.51c5.03,4.55,7.47,10.7,11.26,16.78c-3.73,0.66-6.76,1.12-9.77,1.74c-3.14,0.65-6.26,0.8-7.68,4.92
				c-1.01,2.92-8.83,3.45-13.31,1.56c-14-5.91-25.74-2.49-34.5,9.97c-0.51,0.72-0.36,1.34-0.46,2.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,683.23c-2.01,0.99-3.26,2.77-4.48,4.51c-3.42,4.88-6.29,10.12-12.98,11.61c-3.38,0.75-3.17,5.46-5.58,7.29
				c-10.16,7.71-15.03,18.78-19.31,30.1c-2.58,6.84-7.57,8.79-14.07,10.08c-12.48,2.47-25.34,4.44-34.51,14.85
				c-2.02,2.29-4.93,4-6.36,6.57c-6.44,11.54-18.3,17.4-27,26.5c-7.37,7.71-12.45,17.07-16.56,26.91c-1.32,3.17-2.65,6.33-4,9.49
				c-1.46,3.44-3.26,5.83-7.4,7.26c-5.8,2-8.2,8.36-10.88,13.34c-5.35,9.96-7.99,21.66-14.53,30.63
				c-11.64,15.97-14.76,35.92-26.27,51.62c-4.02,5.48-9.38,9.93-11.9,16.57c-2.81,7.4-6.17,14.6-8.87,22.03
				c-1.73,4.75,2.14,12.45,7.47,14.32c4.02,1.41,5.86,3.47,6.04,7.64c0.06,1.53,0.42,3.8,1.95,4.39c2.98,1.16,2.31,3.44,2.47,5.61
				c0.88,11.97-2.24,23.42-4.54,34.97c-0.79,3.99,1.35,8.17-0.96,12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M880.8,1051.01c-9.47,3.09-19.21,5.08-28.97,6.99c-1.02,0.2-1.8,0.37-2.57,1.45c-3.53,4.94-8.9,5.65-14.41,5.52
				c-13.21-0.31-26.29,0.33-38.95,4.54c-2.46,0.82-4.62,1.52-6.19,4.38c-2.46,4.49-7.45,5.24-12.3,5.62
				c-9.82,0.77-19.66,0.14-29.47,0.55c-8.53,0.35-16.79,2.54-24.99,4.87c-3.24,0.92-2.5,3.08-1.38,4.48
				c2.14,2.65,4.49,5.14,8.38,5.67c4.11,0.56,7.68,2.08,9.12,6.87c0.98,3.26,4.26,4.72,7.39,5.47c3.88,0.93,5.84,3.04,6.01,7.06
				c0.17,4.26,3.21,6.3,6.35,8.65c2.25,1.68,6.46,2.4,5.61,6.85"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M608.58,1357.83c3.08-0.08,5.9,0.95,7.41,3.54c2.73,4.67,7.31,5.36,11.53,4.73c4.47-0.66,6.78,0.94,9.06,4.19
				c4.09,5.85,9.44,9.49,16.95,9.6c3.49,0.05,6.98,0.72,10.47,1.07c4.55,0.46,5.77,4.35,5.98,8.35c0.29,5.5,3.02,6.92,8.01,6.41
				c9.3-0.96,11.37-0.1,16.03,8.06c0.93,1.62,1.69,3.35,4.47,3.26c1.27-0.04,3.76,1,4.51,3.25c1.75,5.29,5.9,8.39,10.48,10.98
				c5.87,3.32,8.62,9.16,11.95,14.53c1.25,2.01,0.48,4.05-0.99,6.38c6.82,0.22,12.82,1.57,18.09,5.48c1.07,0.8,3.24,0.12,4.91,0.12
				c7.27,0,10.95,2.2,13.37,9.03c1.08,3.06,2.81,4.81,5.6,5.97c4.41,1.83,6.89,4.69,6.61,9.99c-0.3,5.71,4.16,9,8.91,12.81
				c-5.17,0.62-9.66-0.74-13.55,2.12c-6.57,4.83-13.94,1.21-20.94,1.61c-7.16,0.4-14.38-3.57-21.5,0.4c-1.76,0.98-3.66,0.7-5.49-0.43
				c-7.17-4.46-15.11-5.54-23.4-6.89c-17.09-2.78-34.03-7.1-49.01-16.62c-5.28-3.36-11.13-5.42-16.98-7.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M661.53,1498.75c-4.16-4.09-9.03-6.02-14.97-6.65c-10.74-1.14-21.09-4.41-31.49-7.31c-14.42-4.02-28.78-8.17-42.95-13.02
				c-5.92-2.03-10.51-6.41-16.43-8.64c-13.82-5.22-27.36-11.18-41.01-16.86c-2.02-0.84-4.18-2.16-5.95,0.05
				c-1.83,2.28-0.2,5.03,1.09,6.31c3.22,3.21,6.31,5.44,4.72,11.24c-0.84,3.04,3.62,5.75,6.6,7.93c10.18,7.44,21.44,13.13,32.03,19.9
				c3.77,2.41,8.14,3.86,11.45,7.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M286.42,443.87c-0.95,5.5-2.52,10.47-6.9,14.59c-6.22,5.84-8.49,13.91-11.24,21.83c-5.49,15.82-10.64,31.76-14.08,48.08
				c-2.54,12.09-5,24.41-4.68,36.92c0.17,6.91,1.9,13.68,2.76,20.51c1.02,8.04,3.05,15.52,7.16,22.47c2.4,4.06,6.38,7.13,7.45,12.02
				c-5.53,4.11-6.98,7.05-2.88,13.91c3.2,5.36,5,10.55,4.09,17.06c-1.22,8.66,1.29,17.29,1.82,25.96
				c0.82,13.32,0.67,26.64,0.07,39.98c-0.23,5.15,0.27,10.32,0.43,15.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1018.15,104.57c-1.89,3.33-4.71,4.67-8.49,4c-2.92-0.52-5.73,0.33-8.48,1.04c-4.25,1.11-7.8,3.04-6.13,9.54
				c-8.99-3.95-16.62-2.94-23.29,2.48c-3.45,2.81-7.01,5.87-8.9,9.99c-0.85,1.86-0.72,4.58,2.84,4.99c4.66,0.54,9.27,1.4,13.99,1.46
				c7.33,0.1,14.65-0.01,21.98,0.32c6.05,0.28,12.1,1.04,18.11-2.63c6.63-4.05,14.84-2.57,22.35-3.09
				c16.14-1.12,32.27,1.16,48.44,1.06c1.96-0.01,3.66-0.53,5.49-0.69"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M565.63,267.47c-11.96,5.96-26.11,2.07-39.33,8.4c6.82,4.8,11.27,10.25,15.03,16.48c1.76,2.91,5.92,2.66,9.31,2.6
				c5.59-0.09,10.74,0.75,15.08,4.9c1.43,1.36,3.56,2.46,5.89,2.62c8.55,0.59,15.34,5.23,19.18,12.14c3.4,6.12,7.5,5.03,12.28,4.29
				c1.86-0.29,3.7-0.86,5.49-1.48c5.48-1.91,10.55-1.52,14.98,2.53c2.5,2.28,5.17,2.67,8.52,2.08c8.08-1.42,16.48,0.23,24.49-2.48
				c1.24-0.42,3.46,0.06,4.38,0.97c6.44,6.38,16.99,3.8,23.18,10.8c0.73,0.83,3.29,0.45,4.85,0.06c7.52-1.89,15,0.56,22.49,0.06
				c3.83-0.26,6.84,1.46,10.04,3.41c10.72,6.55,21.41,13.6,33.94,15.9c10.15,1.86,20.29,3.78,30.42,5.74
				c13.17,2.55,26.06,6.31,38.96,9.97c0.59,0.17,1.01,0.96,1.51,1.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,458.86c-5.84,4.8-9.98,11.11-14.31,17.11c-2.43,3.37-4.83,4.73-8.66,4.38c-4.92-0.44-7.05,2.61-9.64,6.38
				c-8.39,12.18-13.03,26.16-19.8,39.1c-1.34,2.56-2.89,4.11-5.94,5.2c-5.8,2.06-9.34,7.2-12.55,12.29
				c-5.62,8.92-10.95,17.99-17.83,26.12c-2.82,3.33-4.54,2.84-7.15,0.83c-0.93-0.71-1.77-1.57-2.5-2.49
				c-3.55-4.47-7.42-7.15-13.07-3.08c-0.69,0.5-2.25,1.31-3.43,0.09c-4.57-4.7-9.79-2.71-14.99-1.53
				c-6.63,1.51-13.35,3.25-18.58-3.39c-0.17-0.22-1.74-0.43-2.92-0.27c-4.72,0.65-9.78,0.75-13.87-1.94
				c-3.28-2.15-6.09,0.19-9.1,0.01c-6.31-0.39-12.43-2.13-18.98-1.64c-9.43,0.7-14.06,7.19-18.79,13.39
				c-3.31,4.34-5.29,9.7-9.65,13.38c-1.22,1.03-2.11,2.27-2.58,3.96c-1.11,4.04-3.59,7-7.4,9.07c-5.21,2.83-6.93,7.7-7.61,13.43
				c-1.49,12.66-3.38,25.28-8.93,36.98c-1.15,2.42,0.68,5.34-2.07,7.54c5.8,4.87,10.74,10.24,19.09,8.52
				c2.34-0.48,3.98,1.37,5.47,2.95c1.11,1.17,2.1,2.42,4.01,2c5.68-1.28,8.87,1.8,10.96,6.5c1.39,3.13,4.03,3.33,6.49,2.41
				c7.22-2.7,13.64-3.48,17.55,5.06c0.21,0.46,1.11,1.08,1.44,0.96c7.12-2.61,8.35,5.99,13.16,6.35c-0.58,6.55,1.3,12.07,1.38,18.11
				c1.97-0.7,3.55-0.78,3.86-1.46c2.57-5.46,8.59-3.91,12.68-6.28c2.01-1.17,2.83,1.16,2.96,3.32c0.21,3.33-1.22,6.3-1.65,9.49
				c-0.28,2.06-0.66,4.01,0.2,6.32c6.07-2.19,11.16-5.67,14.32-11.33c4.99-8.94,11.8-14.06,22.52-11.95c1.82,0.36,2.62-1,3.47-2.03
				c6.01-7.27,7.06-7.51,14.51-2.98c2,1.22,2,1.22,5.46-0.98c2.41,1.19,2.1,5.84,6.01,4.92c2.91-0.68,3.53-4.88,7.46-5.05
				c2.32,4.2,2.13,9.89,6.9,13.73c4.16-5.17,11.37-5.12,15.65-10.11c1.1-1.28,2.92-2.22,4.43-1.44c4.14,2.15,6.47-0.35,8.49-3.11
				c4.78-6.53,11.61-11.74,13.1-20.44c0.38-2.23,2.27-4.35,4.43-4.38c6.14-0.07,8.83-4.08,11.53-8.6c2.43-4.05,6.16-6.39,10.93-7.07
				c1.44-0.21,2.36,0.62,3.5,1.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M81.64,583.29c2.62,2.34,6.31,2.62,9.09,4.87c1.57,1.27,3.52-1.02,6.02-1.79c-0.61,9.59,1.29,18.84-2.01,28.28
				c5.58,0.83,8.34-3.79,12.4-5.35c4.91,5.02,5.1,6.5,1.47,11.96c-3.79,5.71-4.84,11.73-3.14,18.52c0.82,3.29,0.12,6.96,0.15,10.46
				c0.03,4.68-0.6,9.49,1.13,13.94c0.52,1.34,0.11,2.53-0.23,2.98c-3.71,4.82-2.04,10.83-3.76,16.11c-1.18,3.62,0.2,7.98,2.13,10.75
				c2.03,2.91,6.12,1.52,8.67-0.86c4.56-4.25,8.85-8.81,12.87-12.86c7.34,1.63,10.57,6.84,13.71,12.37c2.91,5.13,7.22,9.3,8.67,15.47
				c0.66,2.83,1.92,5.54,1.74,8.55c-0.31,4.98,3.81,8.09,5.26,12.41c1.33,4,4.59,7.35,3.76,12.08c-0.06,0.32-0.15,0.81,0.01,0.98
				c4.86,4.88,6.22,11.55,8.99,17.5c4.38,9.39,9.52,18.41,14.15,27.65c1.96,3.93,2.44,9.39,1.81,14.33"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M980.69,681.23c3.88-15.27,6.25-30.78,7.49-46.47c0.04-0.5,0.05-1.43,0-1.44c-6.4-0.63-5.09-8.18-9.87-10.76
				c-4.98-2.68-4.38-9.48-7.54-13.83c-1.41-1.94-2.22-4.3-4.43-5.51c-3.2,1.38-5.96,3-9.61,3.26c-5.11,0.36-6.6,5.05-8.02,9.29
				c-1.68,5.04-2.79,10.35-6.3,14.91c-4.47-3.69-2.11-10.03-6.14-13.93c-7.7,5-11.71,11.51-12.67,20.5c-0.82,7.68-0.5,15.6-3.35,23"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M440.76,1240.4c-2.01,5.44-3.13,10.91-0.5,16.49c1.41,2.99,1.08,5.89,0.07,9.02c-1.99,6.13-2.48,12.62-3.61,18.87
				c-1.47,8.16-3.31,16.38-3.76,24.59c-0.85,15.4-6.33,30.03-6.83,45.46c-0.09,2.81-0.68,5.79-0.17,8.46
				c0.44,2.28-0.47,3.27-1.79,4.64c-2.56-0.56-4.85-1.89-8.02-0.36c-2.41,1.16-5.84,0.88-8.47-2.15c-5.22-6.01-8.25-5.9-15.43-2.19
				c-4.58,2.36-8.61,4.43-8.02,10.6c0.19,2.05-1.16,3.2-3.41,2c-1.31-0.7-2.66-1.34-4.13-2.08c-2.12,1.67-4.24,3.4-7.36,3.16
				c-1.12-0.08-3.21,0.73-3.35,1.43c-1.39,6.84-9.14,7.17-12.26,12.38c-2.56,4.28-2.68,8.01,1.68,10.5c3.28,1.88,6.89,3.2,10.4,4.68
				c15.86,6.73,32.67,8.73,49.49,10.9c10.11,1.31,19.75,4.54,29.49,7.4c5.01,1.47,10.32,0.71,15.46,0.5
				c19.08-0.75,36.63,5.45,53.88,12.24c10.74,4.23,20.68,10.5,32.04,13.3c4.53,1.12,6.5,5.5,10.39,7.68
				c11.02,6.21,22.83,10.71,34.67,14.48c12.64,4.03,24.76,9.66,37.83,12.45c4.84,1.03,9.65,2.36,14.51,3.38"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M576.12,836.14c-2.19-4.83,0.08-10.16-1.55-14.97c-2.81-8.32-2.84-16.87-3.06-25.5c-0.14-5.56-4.36-10.25-4.4-15.99
				c-0.01-0.91-0.58-1.47-1.49-1.49c-5.58-0.13-5.93-4.77-7.53-8.49c-2.49-5.76-2.25-13.03-9.31-16.78
				c-3.54,3.13-5.82,6.73-4.62,11.76c1.19,5,1.52,8.78-3.65,12.95c-3.14,2.54-5.95,6.53-9.18,9.72c-2.13,2.11-2.16,5.96-2.22,9.31
				c-0.22,12.33-0.63,24.65-0.93,36.98c-0.03,1.16-0.27,2.14-1.02,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M678.01,207.01c8.87-4.62,21.58,0.43,23.38,9.51c0.53,2.68,2.14,2.93,4.09,3.5c4.91,1.44,9.6,3.33,13,7.46
				c0.75,0.91,1.53,1.62,2.99,1.56c4.29-0.16,7.67,1.84,10.25,5.28c6.79-3.12,13.84-4.09,20.74-5.18
				c10.11-1.6,25.64,5.05,27.94,15.35c0.43,1.93,1.56,2.36,3.49,2.6c3.68,0.47,7.05,1.3,10.18,4.21c3.51,3.27,9.73,3.16,11.51,8.7
				c4.98-0.17,9.38-1.88,14.31-1.19c7.84,1.1,13.05,5.19,16.99,11.64c1.3,2.12,0.91,4.31,0.96,6.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M330.87,1402.31c-3.76,2.05-7.98,2.71-13.42,3.27c4.9,5.92,10.61,6.31,15.92,7.17c9.62,1.55,19.32,2.65,28.95,4.1
				c3.52,0.53,6.32,1.88,9.23,4.72c5.26,5.14,12.57,7.68,19.77,9.11c11.64,2.31,22.87,6.14,34.45,8.57c2.49,0.52,4.89,1.83,6.75-2.33
				c1.74-3.91,6.69-3.7,10.73-3.6c6.95,0.17,13.35,2.86,19.99,4.45c9.63,2.3,18.75,6.03,28.18,9.62c-2.42,4-0.7,7.1,2.27,9.91
				c2.72,2.58,6.07,4.75,3.66,9.57c-0.6,1.21,0.63,3.41,1.81,4.94c5.19,6.72,12.5,10.21,20,13.49c8.19,3.58,16.34,7.27,24.46,11.03
				c1,0.46,1.69,1.62,2.52,2.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M265.94,921.59c0.44-11.37-1.61-22.63-1.48-33.98c0.04-3.17,0.62-6.35,0.43-9.49c-1.86-32.13-1.19-64.29-1.61-96.44
				c-0.11-8.3,0.46-16.74-1.4-25.61c-4.92,2.26-8.85,1.7-12.2-3.19c-0.91-1.33-5.47,0.41-7.03-3.38c-3.58,6.03-2.43,12.06-2.01,17.67
				c0.75,10.04,0.6,19.95,0.02,30.01c-0.88,15.12-0.52,30.3-0.69,45.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M117.6,867.62c-2.17-5.11-0.42-10.88-2.92-16.03c-2.3-4.73-3.84-9.82-8.54-12.99c-3.11-2.1-3.09-5.89-3.9-9
				c-1.97-7.55-7.25-13.62-8.8-21.42c-0.81-4.09-0.43-8.04-0.79-12.03c-0.63-6.91-3.28-12.9-7.98-18c-0.98-1.07-1.97-1.75-3.51-1.5
				c-6.78,1.08-10.32-3.57-13.97-7.99c-0.46-0.56-0.68-1.32-1.02-1.98c-5-9.74-5-9.74-15.79-7.32c2.13-11.07,0.13-22.93,10.26-31.2
				c3.24-2.65,5.92-4.16,9.62-0.55c1.99,1.94,4.9,2.89,7.25-0.13c1.84,0.93,2.65,2.96,4.82,3.11c2.52-1.64,1.07-4.59,1.7-6.92
				c1.08-3.98,2.04-7.56,6.58-9.07c1.55-0.51,2.7-2.23,4.03-3.39c1.43-1.25,2.46-3.29,5.01-2.03c2.53,1.26,2.39,3.59,1.9,5.5
				c-1.34,5.19-0.24,10.04,1.01,15.03c0.83,3.35,2.44,5.87,5.59,6.92c10.05,3.34,10.71,12.26,12.43,20.55
				c0.3,1.44,0.05,2.99,0.04,4.49c-0.06,13.71,2.55,26.9,8.03,39.46c2.52,5.77,0.67,11.49-0.22,16.98
				c-1.8,10.96-2.92,21.95-4.04,32.99c-0.66,6.45-3.51,12.7-6.76,18.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M839.34,1229.41c8.35-3.79,11.65-1.61,13.11,7.48c1.12,7.01,1.03,14,1.48,21c0.37,5.72,2.79,11.07,3.06,16.99
				c0.69,15.01,1.16,30-0.28,44.97c-0.27,2.74-0.11,5.34-0.53,8.87c7.37-2.38,13.67-2.03,19.68,2.07c7.88,5.38,9.07,16.24,1.95,21.05
				c-2.61,1.77-7.7,1.28-10.78-1.24c3.87-1.28,7.94-2.21,6.86-7.77c-0.6-3.07-2.59-4.03-5.05-5.03c-2.87-1.16-5.8-2.5-5.51-6.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M679.01,205.51c0.4-4.34,1.73-8.51,1.59-13c-0.3-9.59-9.92-19.52-19.57-19.45c-5.34,0.04-10.05,0.34-13.71-5.39
				c-3.69-5.78-10.68-5.12-16.75-4.54c-4.19,0.4-8.29,1.77-12.35,2.69c-0.31-0.63-0.6-0.95-0.63-1.29
				c-0.25-3.83,2.59-11.86,4.95-15.01c9.23-12.28,8.17-33.12-6.01-43.4c-4.63-3.35-7.79-8.52-13.42-10.6
				c-0.68-0.25-1.28-0.76-1.63-1.91c-3.15-10.14-9.06-14.34-19.86-14.53c-1.5-0.03-3.2,0.47-4-1.49
				c-3.36-8.31-10.49-10.94-18.48-11.96c-13.02-1.65-25.12,0.24-34.97,9.95c-3.03,2.99-4.19,7.09-6.63,10.94
				c-3.05-2.56-6.36-4.5-10.34-4.38c-1.66,0.05-2.19-0.92-2.47-2.06c-2.45-9.96-10.37-12.98-19.02-14.94
				c-2.79-0.63-5.79-2.06-8.48,0.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M687,1177.44c-0.67,5.98,0.8,12.02-0.48,17.99c-0.51,2.4-0.92,3.98-3.9,5.25c-4.8,2.05-5.79,8.21-9.47,11.87
				c-0.94,0.94,0.35,3.47,1.41,4.83c3.77,4.84,3.36,9.71,2.21,15.58c-2.08,10.65-2.48,21.62-3.8,32.43
				c-0.71,5.83-2.03,11.58-1.98,17.49c0.01,0.83,0.43,1.79-0.47,2.5c-6.26,4.92-5.27,12.41-6.4,19c-1.78,10.4-3.43,20.86-3.64,31.46
				c-0.01,0.67-0.62,1.33-0.96,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M402.3,1131.47c-1.83,4.33-3.35,8.84-5.59,12.94c-2.01,3.68-6.68,4.41-13.76,3.07c-3.64,12.52,3.97,19.83,12.32,27
				c9.28,7.96,19.89,8.71,30.97,5.86c7.13-1.83,14.11-0.97,20.98,0.22c13.29,2.3,26.54,2.5,39.98,2.1
				c11.11-0.33,21.78-3.89,32.97-3.35c5.14,0.25,10.28-1.42,15.47-1.98c11.26-1.22,20.62,2.95,29.48,9.1
				c7.61,5.28,15.12,10.63,21.55,17.42c2.55,2.7,5.57,5.15,9.25,5.64c1,1.68-0.54,2.28-0.85,2.34c-6.11,1.08-6.17,5.03-5.58,10.09
				c1.37,11.73-2.14,23.01-3.67,34.5c-1.42,10.73-4.27,22.2,3.81,31.91c3.54,4.24,7.33,8.36,11.5,11.96
				c7.21,6.23,7.12,14.44,6.31,22.55c-1.11,11.04-0.91,21.96-0.13,33.01c0.42,5.9,1.92,11.88,0.37,18c-1.2,4.75,0.77,9.62,0.88,14.46
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M67.15,574.79c-4.24,12.7-9.51,25.09-11.53,38.47c-0.54,3.54-1.25,7.14-2.53,10.47c-2.38,6.18-2.21,12.61,0.81,17.88
				c3.91,6.8,2.95,13.5,1.6,20.09c-2.37,11.59-3.67,23.28-4.79,35.02c-0.17,1.74-1.55,3.09-1.07,5c1.68,6.77,3.74,7.73,10.09,4.45
				c1.45,1.45,1.02,4.24,3.71,5.31c5.21-6.81,9-14.71,15.73-20.7c2.13,1.85,1.81,4.51,3.39,6.49c1.9-0.76,3.48-3.55,6.61-2.13
				c1.97,0.89,2.12-0.81,2.53-2.41c2.55-9.89,2.62-20.03,1.71-29.98c-0.82-8.92-1-18.56-9.23-25.05c-2.58-2.03-1.56-5.24-1.47-7.95
				c0.12-4.03-0.56-6.98-5.56-6.52c-1.7,0.16-3.1-0.36-2.48-2.48c3.21-10.94,0.58-22.54,3.81-33.54c0.64-2.19,3.4-3.7,2.17-6.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,71.59c-6.02-4.14-12.53-5.91-19.97-6.2c-9.59-0.37-18.7,5.5-20.48,15.66c-1.55,0.41-2.39-0.69-3.55-1.39
				c-2.07-1.24-4.68-2.81-6.88-1c-4.62,3.8-10.86,3.94-15.67,7.46c-9.74-11.51-22.51-13.78-36.29-10.37
				c-9.55,2.36-15.25,9.25-16.34,19.55c-3.23,0.96-6.94-0.99-9.68,1.76c-4.67,4.69-9.31,6.28-15.98,5.51c-0.33-0.04-0.67-0.02-1,0
				c-13.35,0.71-26.72-0.3-39.93-1.34c-17.51-1.37-35.03-2.57-52.45-1.42c-20.98,1.39-41.97,1.53-62.95,2.18
				c-9.14,0.28-18.33-0.72-27.46-0.31c-11.84,0.53-23.63,2.48-35.47,2.73c-13.55,0.28-26.93,2.46-40.45,2.66
				c-4.79,0.07-8.43-1.96-11.26-6.21c4.07-4.26,9.1-5.91,14.76-6.3c5.46-0.38,10.31-2.15,14-6.46c1.04-1.21,2.23-2.74,3.97-2.46
				c6.42,1.03,12.19-0.71,17.44-4.13c2.15-1.4,3.99-0.92,6.04-0.43c3.76,0.9,6.95,1.18,9.62-2.91c2.82-4.32,7.33-4.47,13.87-2.13
				c5.45,1.96,9.89,5.91,15.79,8.03c-0.18-8.86,3.36-15.75,10.78-19.24c4.93-2.32,10.57-0.87,14.87,3.26"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M956.72,559.3c-10.32-2.33-20.7-4.46-30.95-7.05c-14.45-3.65-29.11-5.7-43.97-6.44c-0.5-0.02-1.06,0.15-1.49-0.03
				c-13.32-5.38-27.87-6.53-41.08-12.74c-10.8-5.07-22.64-7.36-34.32-1.66c-1.98,0.97-4.58,0.29-6.03,2.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M214.5,822.15c0.33-2.67,0.85-5.32,0.97-8c1.24-27.3,1.1-54.63,0.98-81.95c-0.02-3.48,1.33-6.43,1.85-10.44
				c4.59,5.41,5.19,13.45,13.34,12.53c1.92,3.9-0.46,8.12,2.09,11.66c5.34-4.55,5.01-12.39,10.75-16.55c1.31,1.58,2.1,3.22,3.11,4.71
				c2.15,3.17,5.09,5.21,9.35,5.73c4.75,0.58,8.07,5.01,12.94,6.12c0.79,0.18,1.04,4.06,1.01,6.22c-0.17,15.83-0.88,31.65-1.29,47.48
				c-0.35,13.7-1.82,27.64,3.31,40.98c1.47,3.83,0.98,8.08,1.22,12c0.49,8.07,2.1,15.96,2.8,23.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M2.22,790.17c2.36,0.55,4.13,1.97,6.07,3.4c5.51,4.07,10.89,8.02,13.01,15.06c0.52,1.73,2.85,2.98,4.39,2.87
				c3.94-0.27,6.68,1.29,8.8,4.9c2.11-3.85,0.33-6.41-0.66-9.29c-0.73-2.13-2.35-3.54-3.37-5.32c-3.97-6.93-6.82-14.28-9.17-21.35
				c-2.33-1.05-3.4,1.23-5.12,0.72c-2.59-2.29-1.22-5.72-2.33-8.54c-1.43-3.64-3.03-7.04-5.63-9.94c-1.23-1.37-2.62-1.93-4.51-1.04
				c-1.8,0.85-2.49,2.14-2.49,4.03c0.02,10.66-0.21,21.33,0.11,31.98c0.14,4.73,3.86,7.27,7.9,8.98c4.87,2.05,7.7,6.14,11.12,9.89
				c6.56,7.18,11.12,15.59,16.33,23.62c1.22,1.88,2.99,1.96,5,2.05c4.4,0.18,8.25,1.28,8,6.87c-8.46,4.07-12.32,11.35-13.93,20.07
				c-0.67,3.6-0.73,7.32-1.05,10.98c-0.06,0.66,0.28,1.57-0.05,1.96c-4.57,5.55-4.74,12.44-5.96,19.02
				c-0.95,5.15-3.48,10.53,2.49,14.52c0.97,0.65,1.69,1.5,1.55,2.98c-0.6,6.65,0.94,13.41-1.39,20.04c-0.58,1.66-1.41,3.81-0.19,5.97
				c5.16,9.1,2.97,18.44,1.18,27.99c-1.91,10.18-4.33,20.42-3.14,30.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M652.54,560.8c-0.04,3.95,0.68,7.01,5.47,8.11c5.95,1.36,8.14,6.29,5.92,13.35c-1.52,4.83-1.26,9.65-1.44,14.52
				c-0.08,2.2,0.67,3.82,1.99,5.53c5.9,7.61,8.3,16.37,9.16,25.94c1.13,12.59,0.68,25.43,5.69,37.56c1.6,3.87,1.72,8.51,1.71,12.92
				c-0.05,12-0.31,24.01,0.97,35.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M601.09,757.69c-0.05,10.55,5.4,20.31,4.83,31.39c-1.7-0.84-2.97-1.47-4.16-2.06c-0.52,0.29-0.99,0.38-1.15,0.66
				c-3.63,6.41-0.79,12.79,0.59,18.95c3.34,14.89,4.59,29.83,2.92,45c-0.11,1-0.04,2.02-1.04,2.48c-5.12,2.36-4.16,7.36-4.91,11.53
				c-0.41,2.26-0.06,4.65-0.08,6.98c-0.1,11.38,0.98,22.86-2.53,33.97c-0.95,3.01-0.15,6.01-0.47,9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M735.45,615.27c-0.48,12.22-1.69,24.27,1.6,36.58c1.86,6.96,4.19,14.01,5.07,21.36c0.55,4.61-0.56,9-0.25,13.52
				c0.29,4.18,0.93,8.15,3.59,11.47c2.6,3.25,4.76,6.39,4.1,11.03c-0.46,3.27,0.91,6.89,3.94,8.31c3.46,1.63,3.79,4.41,3.83,7.16
				c0.17,10.49,1.41,20.99,0.09,31.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M311.4,519.83c-2.42,4.05-4.83,8.1-7.28,12.2c-3.8-3.56-3.19-8.15-3.09-12.2c0.4-16.49,3.27-32.59,9.44-47.95
				c2.21-5.5,2.98-10.6-0.01-16.05c-2.1-3.82-0.57-8.55-2.9-12.55c-0.88-1.52-0.71-3.48-3.42-3.41c-0.57,3.47-1.3,6.97-1.66,10.51
				c-0.17,1.71-0.71,3.65,1.41,5.03c7.86,5.12,5.89,6.74,3.94,13.43c-3.43,11.8-8.35,23.06-14,33.95c-1.23,2.37-1.63,4.64-1.36,7.04
				c0.67,5.91,0.84,11.74-1.02,17.5c-0.56,1.74,0.64,3.18,1.51,4.46c7.07,10.46,7.34,22.7,8.76,34.53c0.44,3.68-1.47,7.64-2.3,11.47"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M953.72,1215.42c-1.33,2.5-2.69,4.98-3.99,7.5c-1.79,3.46-4.51,5.66-8.48,4.92c-4.73-0.88-6.84-5.26-7.23-8.95
				c-0.57-5.38-2.88-4.66-6.28-3.48c-1.14,0.4-2.81,1.34-3.42,0.91c-5.6-4.06-10.26-0.83-15.07,1.59c-0.27,0.13-0.66,0.02-1.54,0.02
				c1.19-6.72,2.37-13.36,3.55-19.99c0.48-2.67,1.3-5.32,1.41-8c0.13-3.15,1.4-7.26-4.42-7.14c-1.16,0.02-2.36-1.82-3.19-2.51
				c4.28-12.59,8.65-24.9,12.52-37.37c0.65-2.1,1.67-5.88-2.35-6.9c-3.58-0.91-3.98,2.07-5.03,4.43c-2.14,4.84-3.87,9.83-6.33,14.57
				c-3.11,5.99-2.22,12.91-3.13,19.43c-1.1,7.85-3.94,10.85-11.49,11.4c-2.94,0.22-3.53,2.2-4.47,4.1c-1.62,3.27-3,6.68-6.51,8.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M749.93,620.27c-1.94,1.49-4.38,0.78-6.96,1.15c-0.25,9.78-1.64,19.67,2.76,28.19c3.77,7.29,3.08,13.95,1.7,21.14
				c-0.89,4.66,0.37,8.97,1.28,13.54c2.07,10.3,8.44,17.86,14.14,25.99c1.49,2.12,0.32,4.96,0.57,7.45c1.11,10.97,2.2,21.94,1,32.98"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M357.85,167.53c10.89-2.05,17.59,2.03,22.97,13.99c0.37,0.82,0.62,1.69,1.01,2.49c4.23,8.83,4.24,8.83,1.98,17.55
				c3.41,0.47,6.78,1.22,9.49,3.46c5.47,4.55,5.72,13.63,0.47,20.93c-1.7,2.36-3.7,4.45-4.01,7.54c3.17,0.93,6.45,0.66,9.59,1.89
				c6.34,2.5,8.54,4.91,8.39,11.58c-0.09,4.27,0.68,6.94,4.82,9.84c4.67,3.27,8.55,8.02,13.17,11.72c0.82,0.65,0.94,2.14,1.17,3.41
				c0.68,3.72,1.28,7.29,5.79,9.22c3.64,1.56,3.93,6.08,4.58,9.79c0.18,1.02-0.11,1.84-0.99,2.51c-3.99,3.09-14.18,2.71-17.95-1.05
				c-5.82-5.8-12.42-8.3-20.52-5.96c-2.33,0.67-4.64,0.59-7,0.02c-3.81-0.93-7.57-1.02-11.51-0.13c-3.9,0.89-7.68,0.13-10.49-3.37
				c-3.72-4.64-8.59-7.35-14.44-8.6c-2.11-0.45-4.39-1.51-5.95-2.98c-8.35-7.81-17.83-6.28-26.96-2.73
				c-5.57,2.17-10.6,2.74-16.06,0.78c-0.55-0.2-1.07-1.01-1.49-0.93c-11.77,2.26-20.5-7.23-31.47-8.13
				c-3.19-0.26-5.82-2.56-6.89-5.46c-1.65-4.5-5.19-5.8-9.1-5.82c-3.81-0.02-7.73-1.42-11.49,0.37"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.72,1318.36c-1.11-6.3-0.21-12.66-0.49-18.99c-0.11-2.4,0.8-3.91,3.49-3.48c2.09,0.33,4.23-0.46,5.38-1.63
				c2.77-2.81,4.7-0.84,6.58,0.65c6.87,5.45,6.88,5.52,13.01-1.05c1.25-1.34,2.84-1.7,4.5-1.47c5.37,0.75,10.66,1.46,16.04-0.38
				c1.15-0.39,2.96,1.14,4.75,1.91c1.78-0.36,2.59-2.13,3.78-3.36c-1.69-4.3-4.66-6.14-9.08-5.71c-4.51,0.44-7.19-1.85-8.48-5.99
				c-0.87-2.81-2.21-5.13-4.99-6.52c-6.85-3.42-11.36-10.38-11.92-16.52c-0.93-10.14-7.81-8.81-13.59-10.35
				c-1.7-0.45-3.2-1.05-4.43-2.13c-1.07-0.94-2.15-2.08-3.5-1.38c-1.69,0.86-1.55,2.73-1.55,4.43c0.03,8.99-0.11,17.99,0.05,26.98
				c0.13,7.33-0.7,14.68,0.46,21.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M710.48,1370.33c-4.07,1.73-7.92-1.5-11.99-0.51c-12.17,2.96-19.04-4.54-25.49-12.96c-2.66-3.47-3.99-7.11-3.03-11.52
				c0.29-1.32,0.38-2.9-1.22-4.21c-3.43,3.51-7.82,6.04-10.97,10.17c4.84,10.12,13.45,16.38,22.31,21.91
				c14.74,9.2,31.5,13.95,47.95,18.87c12.01,3.59,23.62,8.09,35.39,12.24c4.69,1.66,9.36,3.43,14.49,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1096.07,697.22c-1.73-4.08,0.85-7.93,0.81-12c-0.02-2.3,0.77-5,0.63-7.49c-0.57-10.42,2.45-20.65,1.89-30.98
				c-0.49-9.03,2.39-17.36,3.39-26.02c0.76-6.53,1.67-13.58-2.16-20c-3.01-5.05-4.5-5.82-9.02-2.41c-3.2,2.42-6.69,1.18-10.03,1.39
				c-5.17,0.33-5.65-3.54-6.6-7.41c-0.98-3.96-2.7-7.78-4.45-11.49c-1-2.13-2.29-4.17-5.43-4.13c-2.33,0.03-3.86-1.89-5-3.89
				c-1.67-2.94-4.51-3.17-6.94-1.9c-4.9,2.55-8.67,0.91-12.57-2.05c-2.49-1.89-5.41-2.32-8.46-1.06c-2.71,1.11-5.22,2.12-8.51,1.72
				c-3.84-0.47-6.32,3.11-6.8,6.31c-1.36,8.94-5.63,16.54-10.29,23.88c-2.49,3.92-2.2,8.13-2.69,12.1
				c-2.94,23.78-6.39,47.46-11.16,70.94c-0.34,1.66-0.67,3.33-1,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1130.53,977.06c-0.72-0.73-1.35-1.66-2.51-0.51c-3.93,3.92-9.26,5.45-14,7.96c-4.55,2.4-6.03,6.41-6.45,11.04
				c-1.22,13.32-2.19,26.67-3.59,39.97c-0.6,5.67-0.52,11.23-8.25,13.96c-4.68,1.65-8.44,7.01-13.04,10.14
				c-0.45-0.25-0.95-0.35-1.08-0.62c-1.21-2.48-1.77-5.65-3.66-7.33c-2.92-2.58-4.58,2.34-7.35,2.34c-1.17,0-2.37-0.19-3.49,0.04
				c-3.26,0.65-6.12,1.63-6.09,5.96c0.01,2.16-0.52,4.53-1.47,6.46c-5.16,10.43-7.03,21.71-8.96,33.01
				c-0.74,4.33-1.49,8.95-7.97,7.04c-1.94-0.57-3.08,1.08-3.41,2.48c-2.66,11.23-12.47,15.48-20.52,21.51
				c-3.68,2.75-7.49,5.41-10.55,9.11c5.09,7.4,1.81,14.4-1.53,21.31c-0.54,1.12-1.83,1.73-3.39,2.17
				c-5.27,1.47-8.52,5.34-10.54,10.35c-2.02,5.02-4.03,9.04-10.93,9.89c-5.76,0.72-8.94,6.63-9.99,12.61
				c-0.65,3.72-1.7,6.56-5.46,8.64c-5.14,2.83-6.75,8.07-6.59,13.84c0.16,5.33-0.35,10.68,0.51,15.99c0.42,2.61-0.38,4.39-2.98,5.54
				c-6.2,2.73-7.87,7.33-5.51,14.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M936.24,972.56c-5.6-1.56-8.6,0.76-11.16,5.91c-2.98,6-7.24,11.26-9,18.03c-0.96,3.68-1.89,7.98-6.73,9.76
				c-1.82,0.67-3.05,3.65-4.01,5.81c-1.59,3.62-3.25,7.13-6.07,9.96c-9.58,9.61-13.14,22.76-19.96,33.99
				c-1.86,3.06-4.1,5.29-7.42,7.14c-11.3,6.3-19.01,15.68-21.01,28.84c-1.52,10.01-4.66,19.66-8.4,28.83
				c-2.84,6.96-4.11,14.12-6.01,21.17c-2.75,10.17-5.94,20.3-6.7,30.94c-0.09,1.21-1.25,2.34-1.92,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M158.56,534.82c-0.67,7.83-2.43,15.77-1.67,23.45c0.68,6.82,2.09,14.24,7.69,19.49c1.13,1.05,2.59,2.34,3.97,2.43
				c9.29,0.6,13.35,6.51,15.48,14.59c1.29,4.88,3.44,9.26,7.49,12.49c1.75,1.4,3.83,2.5,5.97,1.92c3.55-0.97,4.3,0.6,4.01,3.58
				c-1.12,11.65-1.36,23.37-2.92,34.99c-0.77,5.77,0.68,11.7-1.03,17.49c-0.86,2.9-3.34,5.19-3.04,8.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M358.35,829.64c-4.33,5.05-2.32,10.95-1.85,16.48c0.26,3.07,0.47,5.83-1.1,8.53c-6.27-1.55-11.48-0.21-14.99,5.49
				c-0.99,1.61-2.58,2.88-4.02,2.84c-3.82-0.12-5.58,3.45-8.11,4.29c-7.65,2.54-8.66,12.45-16.84,13.96
				c-1.42,0.26-1.99,2.7-3.11,3.78c-2.94,2.84-6.17,4.36-10.45,1.67c-6.03-3.79-5.96-3.84-10.44,2.42c-5.14-1.47-8.31,0.19-8.95,5
				c-0.68,5.12,3.12,10.04,2.49,14.42c-0.74,5.17-1.06,9.72,1.58,13.99c1.63,2.65,1.29,5.42,1.76,8.09c0.61,3.46,1.82,5.24,5.62,5.05
				c3.05-0.15,5.72,1.2,6.42,4.44c0.75,3.45,3.18,2.99,5.56,2.99c1.83-0.01,4,0.16,5.44-0.22c7.77-2.03,14.09,3.73,21.54,3.4
				c3.11-0.14,4.26-1.44,4.41-3.69c0.59-8.78,4.83-14.27,13.57-15.94c1.36-0.26,2.1-0.82,2.49-2.04c2.04-6.37,7-10.5,11.5-14.98
				c1.28-1.28,1.96-2.82,2.5-4.5c2.8-8.63,8.53-16.8,3.15-26.58c-0.94-1.72-0.12-4.52,0.7-6.95c2.35-6.96,3.56-13.71-1.69-20.58
				c-4.13-5.42-4.81-12.38-4.83-19.35c-0.01-4.33-0.58-9.14-2.51-12.91c-6.53-12.75-1.99-22.73,6.66-32.07
				c2.34-2.52,5.28-4.56,6.54-8.12c-5.53-6.62-10.66-13.52-18.94-17.55c-5.32-2.59-9.34-7.42-11.99-12.86
				c-1.67-3.43-3.93-6.56-7.14-7.78c-6.87-2.61-12.66-7.57-19.93-9.1c-2.89-0.61-4.38-2.46-6.02-4.53c-1.31-1.66-1.4-2.97,0.02-4.54
				c2.22-2.45,4.34-4.99,6.51-7.48c1.58-1.82,3.49-2.93,5.99-2.49c4.61,0.8,8.13-1.1,10.98-4.51c3.68-4.39,8.33-5.96,14-5.57
				c3.65,0.25,7.38,0.54,11.01-0.85c1.81-0.69,3.86,0.06,5.42,1.01c1.92,1.16,3.82,2.6,6.02,1.86c6.2-2.09,12.03,0.06,18.02,0.97
				c2.74,0.42,4.67,2.06,6.96,3.14c6.78,3.2,13.33,6.11,18.41,12.62c3.14,4.02,9.25,7.1,15.09,8.25c5.87,1.16,10.06,4.69,13.37,9.14
				c2.65,3.57,6.82,5.84,7.52,11.36c0.36,2.84,5.76,2.06,8.97,2.06c0.09-5.8-6.22-12.51,2.6-17"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1068.6,761.68c-6.32-2.59-11.8,1-17.45,2.61c-3.81,1.09-7.28,2.89-9.74,6.74c-1.45,2.27-4.27-0.16-6.34-1.29
				c-4.75,5.72-5.72,5.8-12.37,0.97c-4.83,3.66-10.46,0.66-15.35,3.43c-0.1-0.52-0.2-1.03-0.3-1.55c-0.92,1.38-1.85,2.77-2.6,3.9
				c-5.39-0.51-10.25-1.76-14.29-5.29c-2.52-2.2-4.41,1.78-7.57,0.77c-3.89-1.25-8-0.88-11.56-4.12c-2.37-2.16-6.69-2.21-9.21,0.43
				c-2.58,2.72-5.2,3.37-8.62,2.94c-1.29-0.16-2.84-0.07-3.95,0.51c-3.01,1.59-5.94,2.12-9.02,0.43c-2.49-1.36-4.14,0.42-6.74,1.81
				c4.7,4.78,13.17,3.7,14.78,11.17c5.8-2.91,9.35-1.62,9.69,3.54c0.15,2.35,0.64,2.08,2.23,2.56c5.09,1.55,9.83,3.86,13.51,7.92
				c3.93,4.33,3.37,7.37-2.01,9.97c-4.58,2.22-9.66,1.29-14.47,2.04c-5.47,0.86-11.58-1.06-16.37,1.68
				c-6.89,3.94-12.02,1.36-17.62-2.16c-5.08-3.19-10.77-3.2-16.45-2.01c-2.6,0.55-5.02,0.84-7.52-0.51
				c-4.96-2.67-10.88-1.47-16.04-3.86c-1.82-0.84-3.89-1.13-5.43-2.65c-0.59-0.58-1.36-1.49-1.98-1.44
				c-5.57,0.51-10.74-1.78-16.02-2.27c-7.67-0.72-14.72-2.55-19.2-8.49c-3.11-4.13-5.34-5.94-10.28-3.91
				c-2.34,0.96-4.36-0.42-5.47-2.89c-2.65-5.9-2.69-5.88-9.46-8.03c-0.4-0.13-0.81-0.58-0.99-0.98c-1.67-3.74-4.75-4.75-8.51-4.49
				c-5.17,0.36-7.56-4.85-11.97-6.05c-0.44-0.12-0.83-0.9-0.99-1.45c-2.56-8.55-4.69-9.61-13.03-7.07c-2.34,0.71-4.7,2.35-7.57,0.22
				c-1.65-1.23-2.87,1.58-4.35,2.43c-2.7,1.55-4.89,3.24-8.13,0.54c-2.28-1.9-10.06,0.76-12.27,4.46c-2.72,4.54-5.62,7.98-11.03,9.68
				c-2.78,0.88-3.53,5.21-6.37,7.49c-2.95,2.38-6.46,5-7.81,9.2c-0.35,1.1-1.62,2.01-3.39,2.13c-3.25,0.22-6.12,1.81-7.43,4.93
				c-1.14,2.72-3.55,2.5-5.01,1.83c-4.38-2-6.46-0.01-8.06,3.63c-1.76,3.99-5.81,2.72-8.48,2.66c-2.26-0.05-1.92-3.78-2.03-6.16
				c-0.43-9.17-3.53-17.91-4.41-26.99c-0.31-3.19-0.53-6.55,2.15-10.03c2.88,10.7,5.27,21.68,17.46,26.23
				c1.53-2.27,1.79-6.12,4.9-8.72c3.19,4.22,2.69,9.39,4.88,13.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M682.01,580.79c1.8,5.23,0.01,10.4-0.64,15.47c-1.26,9.75,0.15,18.99,3.69,27.98c1.11,2.82,1.55,5.46,1.07,8.53
				c-0.5,3.22-0.16,6.45,1.8,9.53c3.24,5.09,3.64,11.11,4.02,16.94c0.84,12.65,1.38,25.32,2.05,37.97
				c0.49,9.33,1.01,18.66,1.49,27.98c0.19,3.67,2.48,6.34,4.56,8.94c3.64,4.55,7.02,9.21,9.45,14.53c0.34,0.74,0.85,1.54,1.5,1.98
				c9.6,6.55,6.96,17.45,9.47,26.51c0.83,3,0.29,6.38,2.5,8.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1016.65,1172.44c-1.43,0.79-2.55,1.69-3.05,3.48c-1.75,6.21-6.73,8.26-12.44,8.39c-3.63,0.08-4.05,1.67-4.04,4.62
				c0.05,8.67,2.01,17.29,0.63,26.58c-5.75-2.93-9.21,0.27-12.04,4.43c-2.35,3.47-4.1,7.06-9.56,6.16c-2.28-0.38-2.55,2.97-2.89,4.82
				c-1.77,9.57-2.55,19.19-1.65,28.98c1.18,12.78,1.93,25.61,1.57,38.47c-0.35,12.36,0.76,24.67,1.61,36.97
				c0.65,9.36,2.23,18.66,3.39,27.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M955.72,1209.42c0.18,18.45-9.92,34.32-13.11,51.95c-0.54,2.99-1.86,5.85-1.9,9.02c-0.06,4.62-1.8,8.29-6.46,9.99
				c-0.99,0.36-2.08,0.52-2.98,1.03c-10.59,5.86-10.62,7.34-9.65,19.98c1.82,23.81,5.95,47.47,5.05,71.44
				c-0.16,4.19,1.56,8.33,0.09,12.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M511.68,653.25c-3.15,0.05-3.17-2.04-3.58-4.48c-1.9-11.28-2.1-22.62-1.95-33.99c0.02-1.51,0.8-3.32-1-4.53
				c-3.15,1.63-5.23,5.26-9.45,5.07c-1.52-0.07-1.29,1.34-1.54,2.44c-1.21,5.3-3.07,10.43-2.98,16c0.13,7.99-0.03,15.99,0.07,23.98
				c0.03,2.12-0.37,3.87-2.55,4.46c-2.38,0.64-3.23-1.13-4-2.95c-1.62-3.84-2-7.92-2.5-12c-0.93-7.5-1.91-15-3.01-22.48
				c-0.4-2.69-2.16-4.61-4.95-4.42c-3.43,0.23-1.92,3.09-1.97,4.92c-0.16,6.23,2.62,11.93,3.46,17.99c1.22,8.8,2.42,17.61,3.63,26.41
				c-7.34,0.91-9.11-0.13-10.05-6.43c-0.69-4.64-4.18-7.91-5.2-12.45c-1.22-5.48-0.74-11.23-2.86-16.67
				c-6.16,1.8-4.74,7.33-3.71,10.55c2.17,6.75,4.43,12.6-4.29,16.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M187.53,1166.95c0.22,4.2,2.49,7.79,1.86,12.54c-0.75,5.63-2.51,4.73-5.63,2.79c-1.76,1.32-0.63,2.81-0.78,4.15
				c-0.88,8.01-2.66,15.83-0.23,24.08c0.89,3.02,0.18,6.9,0.73,10.42c1.17,7.48,0.87,14.78-3.37,21.52
				c-1.06,1.68-1.91,3.52-1.18,5.99c1.1,3.69,1.91,7.65-2.85,10.01c-2.5,1.24-1.27,3.88-1.53,5.94c-0.22,1.75,0.79,3.83-1.49,5.01
				c-2.44,1.26-4.76,0.21-6.43-1.1c-4.75-3.72-5.38-9.74-7.62-14.88c-0.97-2.23-0.94-5.07-3.48-6.49c-6.03,4.65-5.95,8.41-2.12,14.56
				c4.93,7.92,12.8,14.11,14.6,23.89c0.31,1.67,1.5,2.11,2.5,3.04c3.9,3.62,7.32,7.42,7.14,13.44c-0.15,4.81,1.78,9.11,6.17,12.05
				c-6.15,3.48-11.85,0.56-12.21-5.56c-0.15-2.57-0.34-3.88-3.41-5.28c-5.14-2.34-10.12-5.93-10.64-12.7
				c-0.18-2.29-2.07-3.5-3.43-4.94c-2.79,2.34-6.39,4.07-5.49,9.47c-2.62-1.74-4.05-2.69-5.69-3.78c-3.73,2.71-8.49,4.39-9.31,9.76
				c-0.88,5.8,0.41,7.78,5.96,9.46c3.05,0.92,6.18,1.46,8.52,3.99c1.28,1.38,3.25,1.23,4.95,1c5.6-0.75,11.19-1.98,13.57,5.51
				c0.73,2.31,3.81,2.29,5.42,2.33c7.99,0.19,15.81,2.33,24.09-0.45c5.22-1.76,11.69-2.95,17.45,1.02c2.21,1.53,3.38,2.61,1.36,4.57
				c-2.79,2.71-4.41,6.67-8.91,7.69c-2.02,0.45-3.97,2.18-4.09,5.16c2.28,0.97,4.83,1.25,7.17,2.5c4.26,2.28,9.07,1.8,13.37-0.82
				c1.73-1.05,3.28-2.41,5.02-3.46c6.54-3.96,7.96-3.63,12.5,2.47c1.19,1.6,2.53,3.13,4,4.49c4.12,3.83,8.04,3.5,11.42-1.01
				c0.75-1,1.34-2,2.52-2.5c0.6,0.97,1.15,1.6,0.67,3.09c-0.66,2.05,0.11,5.2,1.91,5.76c5.65,1.78,4.63,5.76,3.9,9.67
				c-0.94,4.99-4.12,7.99-8.94,9.54c-3.84,1.23-7.59,2.37-11.14,4.75c-3.78,2.53-8.45,0.71-12.31-1.35
				c-3.23-1.72-6.29-1.67-9.52-0.44c-0.49,0.19-1.05,0.57-1.49,0.47c-7.3-1.75-14.67,1.34-22-0.39c-2.62-0.62-5.63,0.64-7.97-1.57"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M818.86,1283.38c1.67,2.5,1.27,5.38,0.91,7.98c-1.15,8.26-0.62,16.32,2.74,23.93c0.81,1.83,0.79,3.4,0.8,5.07
				c0.04,10.84,2.49,22.17,0.46,32.36c-2.99,14.95,3.41,29.09,0.72,43.62c-0.26,1.41-1.25,2.53-1.13,3.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M866.81,1338.34c-0.33,0.33-0.72,0.63-0.99,1.01c-1.65,2.26-6.91-0.06-6.39,4.48c0.57,4.93,1.5,10.67,6.45,12.84
				c10.72,4.7,19.63,13.06,31.86,14.88c5.15,0.77,10.56,0.37,15.59,3.14c7.1,3.9,11.72,10.04,16.29,16.22
				c2.07,2.8,1.76,6.73,0.06,9.87c-3.65,6.76-1.44,12.62,2.02,18.55c1.57,2.69,2.7,5.64,4.04,8.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M546.65,652.25c-1.18,0.08-2.41,0.04-2.47-1.5c-0.13-3.17-2.21-4.89-4.61-6.55c-1.59,0.86-2.47,1.94-2.47,4.05
				c0.05,12.67-0.77,25.82,1.74,37.83c2.63,12.59,1.96,24.8,2.09,37.12c0.14,12.48-1.45,24.98-2.28,37.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M949.72,566.3c-9.43-3.53-18.76-5.84-28.52-0.59c-5.7,3.07-12.32,0.92-18.44,2.06c-5.42,1.01-10.87,0.78-16.13,3.76
				c-4.08,2.31-9.27-0.09-13.82-1.29c-4.62-1.21-9.15-2.06-14.01-1.54c-5.56,0.6-11.18,0.1-16.45-1.97c-2.72-1.07-5.3-1.03-8.02-0.44
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M382.32,1260.39c-0.19-1.71,1.28-3.98-1.99-4.54c-4.72-0.81-8.59-3.8-12.96-5.53c-0.91-0.36-1.88-0.64-2.5-0.36
				c-7.98,3.66-16.8,1.82-25.01,3.9c-3.69,0.93-9.48,8.98-8.37,12.49c0.41,1.28,0.41,3.35,2.87,3.59c6.94,0.69,14.12,4.77,20.47-1.58
				c2.62-2.63,4.37-1.81,6.05,1c0.59,0.99,0.38,3.06,2.46,2.5c4.77-1.29,9.49-1.7,14.51-1.22c2.57,0.24,4.92-2.69,7.06-4.84
				c4.88,0.64,7.58,10.66,14.39,3.58"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M616.07,582.29c-1.09-3.77-3.11-7.09-4.96-10.51c-1.05-1.94-2.58-3.7-4.54-3.86c-5.06-0.43-8.38-3.59-10.87-7.19
				c-5.98-8.66-16.16-13.32-21.18-22.86c-1.16-2.2-3.78-2.75-6.41-2.58c-3.08,0.2-6.19,0.24-9.68-2.35
				c-0.32,8.47-4.07,15.21-6.05,22.44c-1.39,5.09,7.52,15.54,13.2,14.28c4.38-0.97,9.59,1.64,13.26-3.69
				c1.42-2.07,5.53-1.94,8.25,0.33c7.26,6.06,16.37,10.28,19.98,19.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M160.05,423.88c-4.19-9.24-4.18-9.12-14.49-8.02c-18.37,1.95-36.7,1.03-54.93-1.53c-12.59-1.77-25.03-2.2-37.49,0.99
				c-4.04,1.03-8.23,1.68-12.47,1.58c-12.82-0.31-25.65,0.58-38.46-0.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M560.13,268.47c-11.06-3.5-21.47-9.33-33.47-9.46c-2.38-0.03-3.82-1.06-5.03-3c-1.32-2.11-2.82-4.14-4.48-6
				c-5.99-6.72-12.62-10.26-22.02-6.18c-5.5,2.39-10.58,2.07-15.16-4.15c-6.37-8.64-18.48-9.61-30.23-3.93
				c-3.48-3.83-8.22-4.88-13.47-4.79c-7.72,0.13-11.64-2.57-14.43-9.97c-1.56-4.14-4.24-7.11-7.46-10.06
				c-7.22-6.62-13.69-14.08-22.06-19.4c-2.02-1.29-2.18-3.56-3.01-5.49c-2.75-6.44-5.71-12.71-13.47-14.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M214,921.59c-2.45-4.32-6.7-3.94-10.47-3.37c-4.16,0.64-3.73,4.75-4.56,7.85c-0.85,3.17-0.34,6.34-0.42,9.51
				c-0.14,5.43-0.93,10.04-4.08,15.26c-4.84,8.04-5.02,18.16-4.36,27.71c0.46,6.63,1.1,13.15-1.56,19.5
				c-1.6,3.83-1.83,7.95-1.45,11.98c0.58,6.17,0.35,12.33,0.43,18.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M629.06,923.59c-2.06-1.53-1.61-4.37-1.69-6.01c-0.25-5.19-2.5-7.68-8.57-8.29c-0.85,0.76-2.31,2.93-5.24,2.41
				c-2.77-0.5-4.35-2.31-5.95-4.12c-7.31-8.29-6.8-14.73,2.07-21.84c1.41-1.13,1.47-2.53,2.4-3.62c2-2.31,2.1-7.49,7.57-4.67
				c0.95,0.49,2.68-1.42,2.93-3.33c1.14-8.58,3.68-16.62,8.35-24.07c1.95-3.11,2.35-7.12,2.65-10.91c0.09-1.17-0.25-2.28,0.41-3.53
				c3.61-6.85,3.9-14.58,5.7-21.92c0.83-3.37,2.97-6.5,4.89-9.5c4.5-7.02,6.25-16.13,15.18-20.11c2.07,0.76,4.28,2.06,5.16,4.13
				c2.57,6.01,6.97,10.29,12.1,13.93c12.98,9.23,22.22,21.45,29.53,35.46c5.46,10.45,13.74,19.17,18.05,30.47
				c4.64,12.17,8.11,24.78,13.83,36.54c3.19,6.57,5.4,13.49,7.67,20.43c1.66,5.1,5.05,9.62,7.23,14.59c0.99,2.26,3.11,4.55,1.6,7.45"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1102.06,612.27c-1.32,0.32-1.83-0.81-2.48-1.52c-4.34-4.66-4.7-4.82-8.95-0.43c-2.2,2.28-4.03,3.11-7.09,1.52
				c-4.16-2.16-7.39,0.44-10.44,2.93c-4.32,3.53-6.9,3.53-9.86-1.09c-1.57-2.45-3.73-3.98-5.21-6.02c-3.52,0.83-5.71,2.92-6.82,5.64
				c-1.44,3.54-4.1,3.92-7.08,3.41c-4.42-0.76-8.97-1.41-12.96-3.51c-4.87-2.56-7.16-1.6-8.55,4.06c-1.49,6.1-1.22,12.37-1.31,18.5
				c-0.23,14.87-3.79,29.28-5.28,43.96c-0.31,3.03-0.05,6.02-0.38,9.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M204.01,1223.91c-3.78-7.34-5.34-14.78-1-22.49c1.18-2.09,1.44-3.9,0.73-6.56c-1.07-4,1.22-7.76,4.83-9.8
				c1.9-1.08,3.17-2.21,4.25-4.25c-1.41-1.5-2.56-3.02-5.33-2.84c-1.41,3.26-4.26,5.07-7.96,6.04c-2.82,0.74-4.16,2.46-3.12,5.95
				c2.05,6.86-1.88,12.85-4.05,18.9c-1.73,4.82-1.56,11.2,1.62,15.08c3.29,4.01,6.68,0.57,10.02,0.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M699.99,608.77c1.66,6.81,1.93,14.67,9.07,18.34c5.83,2.99,5.98,8.49,6.72,13.18c2.97,18.86,7.09,37.57,6.47,56.94
				c-0.21,6.69,0.45,13.82,2.63,20.51c0.49,1.5,0.4,3.37-0.75,5.02c-2.21-0.48-2.86-2.63-3.1-4.56c-1.46-11.81-2.74-23.64-4.05-35.47
				c-0.7-6.33-1.3-12.66-2.05-18.98c-0.09-0.74-0.87-1.39-1.38-2.15c-5.37,5.64-5.15,12.11-4.18,19.15
				c1.81,13.11,4.46,26.12,4.75,39.46c0.1,4.62,3.99,8.27,4.95,12.98c2.47,12.09,5.12,24.14,5.83,36.5c0.06,1.12,1.16,1.49,1.07,2.49
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M185.03,46.6c3.98,0.91,8.12,0.53,12.05,2.37c3.36,1.57,5.83,3.74,7.94,6.62c3.07,4.19,1.05,7.76-1.59,10.94
				c-1.62,1.95-3.35,3.27-1.48,6.1c1.64,2.49,3.78,3.97,6.54,3.38c6.15-1.32,12.25-1.62,18.51-1c4.14,0.41,7.82-0.46,11.62-3.22
				c6.86-4.99,22.34-2.41,28.31,3.82c1.8,1.87,3.41,3.93,5,5.99c3.64,4.69,7.53,8.84,13.99,9.54c4.21,0.45,6.36,3.12,6.52,7.44
				c0.27,7.66,0.69,15.32,0.97,22.99c0.23,6.31,2.15,8.9,8,10.49c11.15,3.03,14.31,6.85,14.84,19.49c0.15,3.55,1.6,6.17,3.14,8.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M802.88,696.72c1.61,1.44,3.36,2.78,4.31,6.07c-1.72,1.18-3.9,2.67-5.57,3.81c0.95,6.08,5.03,7.68,8.76,9.6
				c9.68,5,19.75,9.28,28.53,15.92c0.49,0.37,1.5,0.42,1.92,1.09c8.04,12.65,22.55,14.39,34.47,20.5
				c7.07,3.62,14.19,7.23,18.99,13.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M180.53,800.66c-2.58,5-4.02,9.88-0.06,15.04c2.43,3.17,0.93,7.19,0.34,10.41c-1.7,9.33,2.97,16.73,6.34,24.47
				c1.84,4.23,2.97,7.61,1.74,12.64c-1.44,5.87-0.9,12.83,3.77,17.76c1.92,2.02,1.76,3.06,0.92,5.16c-1.15,2.87-0.62,5.69,0.87,8.5
				c1.37,2.59,3.14,2.92,5.56,1.96c3.05-1.21,6.18-1.92,9.49-1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M530.17,1047.02c-3.33-2.32-6.66,0.12-10.08,0c-1.55-1.6-1.55-4.4-4.55-5.75c-0.79,0.94-2.74,1.03-2.94,3.46
				c-1.3-0.57-3.18-0.78-3.8-1.78c-1.68-2.72-3.47-1.13-5.6-1.19c-5.14-0.13-10.68,5.16-15.51-0.73c-0.22-0.26-0.71-0.56-0.97-0.48
				c-3.6,1.04-7.22,1.73-10.95,2.28c-4.41,0.66-6.34,4.74-6.17,9.68c0.12,3.39-3.45,3.83-5.89,5.29c0.08,1.94,1.32,4.45-1.93,5.79
				c-0.92,0.38-1.86,1.86-1.96,2.91c-0.61,6.54-5.25,7.71-10.5,9.22c-3.19,0.92-5.88,3.93-8.53,6.3c-5.83,5.24-8.47,5.39-13.98-0.07
				c-1.94-1.92-4.16-2.33-5.96-0.87c-3.06,2.48-5.04,1.14-7.06-1.1c-2.53-2.8-4.91-5.75-7.53-8.46c-1.87-1.93-3.79-3.23-6.53-0.6
				c-3.44,3.3-6.31,2.9-9.56-1.33c-6.16-8.02-9.14-7.77-19.24,0.21c-7.65-7.09-13.02,1.51-19.57,3.24c-1.28,0.34-0.97,1.82-1.03,2.94
				c-0.25,4.18-1.92,7.61-5.43,10.19c-2.21-1.78-4.36-3.48-6.55-0.21c-0.44,0.66-1.05,1-1.98,1.05c-6.93,0.35-8.97,5.92-11.49,10.95"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M214,603.78c-2.45,7.11-1.12,14.29-0.54,21.49c0.87,10.82-0.36,21.64-0.2,32.48c0.26,17.4,2.64,34.62,3.23,51.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M299.91,837.64c-2.31-1.1-2.37-3.69-3.36-5.57c-1.04-1.98-1.7-4.56-3.68-5.31c-6.39-2.42-5.33-7.63-5.48-12.61
				c-0.35-11.21,0.34-22.34,4.16-32.93c1.71-4.74,0.21-9.38,1.24-14.07c1.83-8.33,4.45-16.39,7.12-24.46c1.11-3.35,0.95-5.54-3.5-4"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M912.51,647.25c-3.29,0.85-6.37,2.16-9.25,3.99c-1.13,0.72-2.29,1.84-3.5,1.93c-12.61,0.99-21.98,9.94-33.52,13.38
				c-2.13,0.63-4.1,1.61-5.32,2.81c-6.21,6.1-14.08,7.76-22.11,8.79c-6.67,0.86-12.29,4.15-17.89,7.22
				c-5.91,3.23-10.95,8.09-18.06,8.85c-0.16,0.02-0.42-0.07-0.49,0.01c-0.69,0.81-0.68,1.32-0.01,1.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M537.66,1177.94c0.46,1.9-0.92,2.82-1.98,4.02c-4.92,5.57-5.86,11.67-2.55,18.49c2.82,5.81,6.73,10.83,10.51,15.98
				c8.56,11.67,10.33,24.48,7.12,38.5c-2,8.75-3.1,17.77,0.88,26.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M299.91,987.55c1.64,0.3,1.97-1.38,2.47-1.67c3.05,0.21,4.03,3.08,6.59,3.87c2.55-1.12,5.05-3.19,5.69-5.27
				c1.32-4.29,4.71-2.26,6.78-2.64c2.41-0.45,1.93,2.91,2.42,4.72c1,3.61,0.85,7.38,0.44,10.98c-2.1,18.18-0.53,36.36,0.38,54.46
				c0.54,10.82-0.41,21.76,1.71,32.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M642.05,453.86c-2.88-2.35-6.17-0.73-8.96-0.29c-4.07,0.65-8.03-0.07-12.03,0.09c-2.73,0.11-4.37-2.83-5.46-5.31
				c-3.96-9.05-12.22-14.27-19.29-20.13c-3.74-3.1-11.02-1.96-15.53,0.46c-5.48,2.94-10.48,1.44-15.64,1.1
				c-7.66-0.52-15.07-2.37-23.02-1.16c-7.49,1.14-15.55,1.37-22.84-1.03c-3.85-1.27-6.92,0.1-10.04,0.57
				c-4.01,0.6-7.62,0.88-11.55-0.2c-4.05-1.12-8.06-2.86-12.47-2.1c-1.26,0.22-2.55,0.22-3-1.47c-1.6-5.92-6.88-5.07-10.99-5.38
				c-9.82-0.74-19.62-0.05-29.53-1.71c-6.09-1.02-12.86,0.62-19.42,0.3c-3.62-0.17-7.05,2.27-8.48,6.28"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M579.11,1197.93c-1.01,3.37,1.66,5.71,2.63,8.45c4.85,13.82,2,25.73-8.15,35.99c-3.81,3.85-8.13,7.2-11.92,11.06
				c-7.41,7.54-7.63,16.31-3.49,25.43c3.45,7.61,8.27,14.3,14.42,20.03c2.45,2.28,4.48,4.83,6.16,7.89
				c3.03,5.53,0.34,10.96-1.52,14.88c-4.93,10.34-4.64,20.93-4.56,31.66c0.06,8.26-2.48,15.78-6.16,22.92
				c-1.12,2.16-2.33,4.09-2.37,6.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M770.41,492.34c-0.57,0.79-1.79,1.02-1.54,2.51c1.15,6.85-0.18,13.7,6.14,19.88c4.52,4.43,5.33,12.61,7.91,19.08
				c1.5,3.76,3.6,6.88,7.95,8.08c3.67,1.01,6.56,3.6,8.97,6.46c3.74,4.43,7.78,7.83,13.4,10.26c7.35,3.18,13.41,8.99,19.55,14.27
				c1.57,1.35,2.79,1.79,4.55,0.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M807.87,694.22c2.17,3.03,6.02,3.87,8.63,6.5c-1.93,3.09-6.21,2.57-8.1,5.57c1.07,6.74,7.57,7.57,12.05,10.28
				c7.69,4.66,15.91,8.46,23.89,12.64c1.39,0.73,2.9,1.2,4.02,2.47c3.7,4.15,8.43,5.79,13.93,6.69c6.7,1.09,12.37,5.04,18.04,8.77
				c9.66,6.35,18.61,13.54,26.94,21.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M566.63,882.61c-0.36-6.4,2.57-12.53,1.5-18.99c-1.49-9.02,2.52-17.92,0.57-27c-0.57-2.65,0.4-5.49-2.47-7.62
				c-1.05-0.77-1.49-3-1.12-4.86c2.16-10.81-1.19-20.66-6.11-29.91c-2.92-5.5-4.16-11.55-6.71-17.1c-3.51,0.59-3.22,3.11-3.15,5.54
				c0.34,12.88-0.24,25.83,2.98,38.48c0.16,0.63,0.25,1.44-0.01,1.98c-2.2,4.56-1.24,9.03,1.12,12.96c3.77,6.25,4.5,13.54,4.45,20.04
				c-0.07,8.59,4.54,13.68,8.95,19.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M557.64,479.35c2.55,2.33,6.05,2.39,9.02,2.8c6.9,0.96,12.52,6.49,19.97,5.28c0.89-0.15,2.24,0.68,2.94,1.45
				c6.84,7.48,13.54,15.1,20.38,22.58c5.23,5.72,10.01,12.6,12.67,19.54c3.91,10.18,13.05,17.09,15.4,27.82
				c0.24,1.1,0.82,0.99,1.54,0.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M176.04,1166.95c-4.08,3.38-3.25,8.8-5.26,13.1c-1.43,3.07-3.14,6.1-4.33,9.35c-0.88,2.41-2.74,4.33-5.9,3.55
				c-0.47-0.12-1.39-0.15-1.44,0c-1.84,5.48-9.44,5.73-10.66,11.95c-0.47,2.4-2.15,4.6-5.38,4.03c-0.48-0.09-1.27-0.22-1.46,0.02
				c-3.65,4.72-9.32,8.6-10.15,14.51c-0.57,4.04-3.63,7.68-2.68,11.39c1.66,6.43-1.81,11.15-4.38,15.94
				c-2.26,4.22-1.35,6.61,1.62,9.66c3.92,4.04,3.75,12.14-0.53,14.23c-3.06,1.5-4.54,3.88-6.39,6.2c-5.89,7.38-3.73,10.07,0.43,16.02
				c3.46,4.94,5.87,10.32,2.16,17.01c5.31,2.61,4.38,8.58,6.74,13.01c1.34,2.52-0.62,5-3.36,5.85c-2.65,0.83-3.84,2.43-3.98,5.06"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M595.35,703.22c-6.88,4.94-10.14,12.55-14.13,19.55c-1.41,2.46-0.52,5.56-0.18,8.45c1.05,8.99,3.08,17.88,3.04,26.98
				c-0.02,4.2,0.74,8.37-2.76,12.18c-1.56,1.71-0.62,5.53-1.29,8.29c-0.5,2.07,0.67,4.96-3.11,6.27c-5.63-4.81-3.95-12.03-5.3-18.24"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M643.05,746.69c-0.22-5-0.03-9.99-0.4-15c-0.7-9.48-2-18.94-1.58-28.47c0.26-5.95,2.33-7.18,8.18-4.91
				c4.58-9.01-1.54-18.53,0.78-27.65c3.8,3.84,2.56,8.81,3.95,13.1c0.67,2.06,0.55,4.36,1.09,6.47c2.56,10,2.32,11.29-3.93,18.38
				c5.64,20.76,2.97,42.14,5.39,63.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M695.49,603.78c4.12,3.64,8.56,6.75,10.65,12.43c1.99,5.39,7.77,7.33,12.41,9.91c2.56,1.42,3.9,2.47,4.02,5.64
				c0.31,8.35,3.82,16.02,5.94,23.98c0.98,3.69,1.07,7.26,0.51,11.02c-0.84,5.62,1.99,10.87,1.99,16.48
				c-0.01,12.9,4.01,25.27,5.31,37.99c0.38,3.74-1.01,7.64-1.33,11.48c-0.49,5.95-2.42,12.01,4.8,16.26
				c2.74,1.62,1.78,6.66,2.17,10.22c0.31,2.83-0.95,5.85,0.98,8.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M76.14,1129.47c-3.42,0.46-7.25-0.19-9.51,3.49c-0.05,0.07-0.34,0.06-0.48,0c-7.5-3.46-14.94,3.66-22.53,0.12
				c-1.03-0.48-3.27-0.99-3.96,1.39c-0.68,2.35,0.71,3.92,2.51,3.88c4.63-0.1,8.19,2.83,12.44,3.83c3.67,0.87,8.14,2.5,6.61,8.31
				c-0.32,1.21,0.4,2.5,1.42,3.5c1.64,1.61,1.33,3.14,0.08,5.02c-1.81,2.73-1.42,5.52,0.93,7.96c2.42,2.51,5.31,2.77,8.5,2
				c6.92-1.66,13.95-1.54,20.97-0.95c0.7,0.06,1.34,0.94,2,1.44c-7.22,10.52-6.92,12.12-17.42,9.38c-2.36,5.44-7.01,6.72-12.55,6.12
				c-0.66-0.07-1.59-0.3-1.96,0.03c-6.12,5.58-15.14,2.54-21.58,7.37c-2.38,1.79-3.22,4.29-3.76,6.62c-0.61,2.66,1.3,4.39,4.31,3.93
				c1.35-0.2,3.25-1.38,3.91-0.86c5.79,4.6,14.15,0.44,19.56,5.89c1.9,1.91,4.24,1.42,6.51,1.48c2.47,0.07,5.2-0.59,6.49,2.5
				c-4.14,5.99-5.26,6.19-12.03,3.09c-1.01-0.46-2.01-1.57-3.46-1.11c-5.57,1.78-11.4-0.54-16.97,1.05c-0.76,0.22-1.39,0.89-2.31,1.5
				c4.31,6.23,7.7,13.27,13.04,18.18c4.81,4.42,8.62,11.21,16.73,11.37c3,0.06,8.13,6.03,7.92,8.89c-0.29,3.84-3.81,3.69-5.84,2.83
				c-3.5-1.48-4.91-1.11-6.19,2.63c-1.48,4.31-5.29,1.94-7.27,0.86c-3.97-2.17-6.75-2.01-10.74,0.42c-3.24,1.98-3.52,4.82-4.12,7.31
				c-0.69,2.86,1.86,4.22,4.77,3.93c4.21-0.41,8-0.11,11.58,2.9c3.17,2.66,7.01,0.7,10.38-0.44c6.3-2.14,11.54,1.36,11.47,8.04
				c-0.04,3.54-0.01,6.96,3.54,8.97c1.35,0.76,2.82,2.04,4.44,0.96c0.9-0.6,2-0.71,2.49-0.37c6.08,4.12,13.6,6.03,18.51,11.94
				c1.87,2.25,1.09,5.01,0.92,7.48c-0.29,4.27,0.23,8.4,3.19,11.4c2.71,2.74,0.69,6.5,2.9,9.09"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M228.98,1195.93c4.87,0.91,9.45,2.34,13.52,5.45c10.03,7.64,21.16,13.5,32.45,19c13.19,6.43,24.12,16.66,37.91,22.1
				c5.31,2.1,10.69,4.23,15.52,7.41"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1071.6,1498.75c-2.72-3.31-6.85-3.44-10.5-4.44c-18.17-4.97-34.56-13.6-50.47-23.47c-12.21-7.57-25.11-13.84-38.91-18.09
				c-1.74-0.54-3.31-0.68-5.01,0.02c-8.65,3.56-17.37,2.24-25.95-0.04c-17.53-4.66-35.03-9.44-52.44-14.51
				c-10.01-2.91-20.27-4.3-30.51-5.8c-3.25-0.48-6.46-3.07-10.15-0.73c1.73,6.01,4.91,11.47,7.7,17.08c1.21,2.43,3.2,4.52,2.97,7.51"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M605.09,707.72c-8.66-2.3-10.46-4.7-11.58-12.98c-0.9-6.67-0.88-13.32-0.9-20c-0.01-3.6-0.4-6.9-3.97-9.05
				c-2.49-1.5-2.82-4.18-3.08-6.94c-0.53-5.62-2.28-10.37-8.39-12.62c-3.17-1.17-2.39-5.57-4.54-7.88c-2.74-0.86-3.68,1.05-4.46,3.02
				c-0.23,0.58,0.24,1.69-0.08,1.93c-5.95,4.43-6.02,11.16-6.95,17.55c-0.61,4.17-3.37,6.46-6.63,7.56
				c1.75,6.08,1.79,12.08,2.02,17.93c0.42,10.48,0.1,20.98,0.13,31.48c0.01,4.32,0.29,8.34,2.18,12.63
				c1.78,4.03,0.97,9.19,1.31,13.86s0.44,9.36,1.07,13.98c0.46,3.42,1.26,6.9,4.27,9.59c1.48-3.23,3.32-0.09,5.15-0.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1039.63,873.12c2.34,6.83-2.74,12.24-3.54,18.48c-0.37,2.87-1.25,5.68-1.92,8.6c2.23,1.07,4.93,1.21,7,3.28
				c8.5-8.6,7.63-21.53,14.92-31.19c2.94,4.66,1.53,8.6-0.03,12.3c-3.87,9.15-8.17,18.11-9.95,28c-0.55,3.05,0.53,5.71,1.09,8.58
				c1.24,6.29,5.67,11.33,6.26,17.42c1.18,12.27-1.84,24.16-4.28,35.93c-3.34,0.21-4.04-3.19-5.82-3.46
				c-2.99-0.13-3.3,2.65-5.59,3.11c-4.02-3.73-11.45-2.12-14.13-8.61"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M319.89,999.54c0.17,1.33,0.51,2.67,0.47,4c-0.4,16.34,0.76,32.65,0.96,48.97c0.19,16.41,1.53,32.7,3.5,48.98
				c0.5,4.18-0.78,8.7,3.06,11.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M548.65,654.75c-0.37,2.83-0.29,5.63-0.17,8.5c0.22,5.15,1.61,10.4-1.17,15.57c-1.26,2.34-1.04,5.57-1.12,8.41
				c-0.41,15.82-0.92,31.65-0.92,47.47c0,6.05-1.54,11.95-1.11,17.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1117.55,762.68c-2.94,9.45-2.64,20.07-10.87,27.62c-2.13,1.96-3.04,5.45-3.97,8.41c-0.32,1.02-0.66,2.11-1.19,2.92
				c-9.79,14.95-20.67,29.24-26.79,46.69c-4.4-2.97-3.38-6.75-3.67-10.17c-0.19-2.17,1.18-4.79-0.98-6.46
				c-7.11-5.51-5.76-12.24-3.56-19.55c2.41-8.04,4.17-16.28,6.01-24.48c0.31-1.38,0.85-3.3-1.19-4.66c-2.21,1.92-4.67,3.13-7.75,1.24
				c-1.56-0.96-3.31,0.59-3.85,1.51c-3.78,6.46-8.09,12.72-9.1,20.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.72,1071.5c-1.06,1.58-0.46,3.34-0.46,5c-0.05,28.65-0.16,57.3,0.03,85.95c0.07,10.32-0.8,20.67,0.43,30.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M235.97,760.68c-1.55,17.12-1.93,34.3-2.74,51.46c-0.62,13.16-1.93,26.29-1.74,39.49c0.02,1.67-0.31,3.39,0.49,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M937.24,1127.47c-4.01-11.52,1.02-22.46,2.83-33.43c1.32-7.99,2.09-16.09,3.66-24.04c0.96-4.84,1.89-9.69,3.09-15.82
				c-0.54-0.38-1.71-1.51-3.11-2.12c-3.61-1.57-4.71-3.52-3.16-7.61c1.07-2.84-1.08-6.12-0.91-9.43c0.38-7.2,0.7-14.43,2.24-21.45
				c0.66-3.03-0.03-5.07-2.19-6.46c-4.06-2.61-6.96-6.39-10.53-9.65c-3.78,2.83-3.17,7.25-3.84,11.09
				c-0.92,5.29-1.33,10.66-2.1,15.97c-0.22,1.54-0.5,3.62-1.54,4.4c-4.77,3.58-4.19,8.58-4.5,13.59c-0.43,6.92-3.19,13.16-6.88,19.01
				c-1.79,2.84-5.36,2.01-7.62,4.42c-6.14-6.8-6.31-14.79-5.6-22.96c-3.71,0.45-5.35,2.65-6.36,6.51
				c-1.31,5.04-2.17,10.23-4.39,15.02c-1.32,2.85-3.25,4.6-6.52,4.48c-3.62-0.13-6.14,2.04-6.87,5.03c-2.27,9.4-6.72,18.2-7.62,27.96
				c2.22,2.53,3.94-0.97,6.24-0.43c0.52,0.87,1.63,1.88,1.67,2.93c0.22,6.71,4.46,9.89,10.07,11.99c3.24,1.21,5.34,3.23,4.44,6.92
				c8.44,1.14,7.81,1.22,6.7,10.09c-2.59,20.65-8.94,40.26-15.21,59.92c-0.45,1.41-0.15,2.7-0.42,4.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M351.85,1392.31c-12.49-1.5-25.14-2.18-37.41-4.76c-9.69-2.03-19.42-1.16-29.03-2.68c-4.09-0.65-6.68-4-10.48-5.06
				c-5.96-1.66-11.97-1.12-17.98-0.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M827.35,1177.44c-0.09,1.69,0.33,3.77,1.55,4.39c7.1,3.62,6.25,11.52,9.06,17.28c2.07,4.23,1.42,10.24,0.72,15.29
				c-1.32,9.6,0.85,19,0.65,28.5c-0.02,1.01,0,2.01,0,3.58c-3.55-0.51-5.97,0.61-7.52,3.41c-0.83,1.5-2.01,2.38-3.96,2.58
				c-4.64,0.47-6.18,3.65-5.99,7.92c0.1,2.17-0.21,4.32,0.4,6.52c0.43,1.55,0.62,4.51,0,4.79c-5.69,2.52-0.53,10.76-6.91,12.69
				c-3.02,0.92-5.95,2.19-9.01,2.93c-3.34,0.81-5.16,2.2-5.6,6.04c-1.08,9.37,0.87,18.67,0.1,28c-0.13,1.6,0.78,2.22,1.97,3.05
				c4.46,3.12,7.34,7.03,7.66,12.93c0.36,6.67,1.5,13.3,0.37,20"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M728.46,1203.42c-2.3-0.19-4.64,0.68-5.37,2.55c-1.86,4.77-2.94,9.78-7.53,13.07c-0.95,0.68,0.45,2.32,1.37,3.41
				c2.55,2.99,6.63,4.3,8.37,8.56c-2.46,1.59-4.18,4.67-7.29,6.47c-3.09,1.79-3.56,5.59-3.35,8.41c0.65,8.76-2.31,16.8-4.36,24.96
				c-1.2,4.77-0.37,9.38-0.79,14.04c-0.2,2.16,0.01,3.77-2.98,4.67c-4.01,1.21-7,4.74-11,6.42c-1.32,0.55-2.13,2.12-2.64,3.87
				c-2.14,7.38-2.5,14.94-2.9,22.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M693.99,491.34c2.41,1.42,2.79,3.33,2.08,6.02c-0.72,2.73-0.74,5.64-1.09,8.47c-0.28,2.28,0.13,3.57,3,3.64
				c1.75,0.04,3.27,1.66,2.98,3.86c-0.94,7.29,2.74,13.84,3.24,20.97c0.4,5.81,2.06,11.59,1.68,18.28c2.77-0.69,5.47,0.9,6.97-1.35
				c1.81-2.71,2.8-6.15,2.01-9.4c-1.13-4.65-2.28-9.2-1.72-14.32c3.63,1.31,3.21,4.59,3.66,6.83c1.12,5.57,1.48,11.3,2.15,16.96
				c0.08,0.69-0.12,1.32,0.46,2.03c5.19,6.28,6.39,14.44,10.05,21.45c0.31,0.6,0.97,1.01,1.39,1.43c10.32-5.28,5.88-15.32,7.43-23.6
				c7.96,2.36,4.57,9.34,6.2,14.39c3.5-1.56,4.69-5.12,7.07-8c4.35,3.6,9.24,6.38,12.86,10.78c1.43,1.74,3.19,3.49,5.48,3.44
				c10.86-0.26,15.47,8.29,20.81,15.19c3.4,4.39,6.61,9.27,8.16,14.86"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M382.32,1132.97c4.94,0.61,9.65-1.08,14.47-1.61c8.61-0.94,10.14-0.14,13.56,8.08c1.83,4.39,2.65,9.02,3.82,13.55
				c0.86,3.32,1.62,6.43,4.72,8.32c3.88-2.19,2.42-6.09,2.3-9.35c-0.41-11.34-2.13-22.64-9.03-31.89
				c-10.21-13.67-33.31-17.42-47.33,0.4c-0.95,1.21-1.99,2.34-2.99,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M84.13,440.37c11.31-0.91,22.55-2.13,33.99-0.7c6.01,0.75,12.27-0.83,18.46-0.32c5.38,0.44,7.06,3.3,4.49,8.01
				c-5.09,9.33-11.07,17.79-20.41,23.59c-5.34,3.32-7.15,9.59-10.09,14.87c-3.2,5.75-6.43,11.61-12.46,14.99
				c-6.95,3.89-8.41,11.62-12.43,17.54c-2.59,3.82-4.77,7.93-7.01,11.98c-0.72,1.31-1.44,2.21-3.03,1.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M650.04,795.16c3.5,0.51,6.02-1.93,9.32-3.29c3.66,10.7,11.63,17.48,20.66,23.26c4.44,2.84,4.88,7.7,5.84,12.04
				c0.93,4.17,2.27,7.35,5.42,10.67c5.45,5.75,9.95,12.54,14.03,19.39c2.01,3.37,3.51,7.42,1.03,12.08c-0.76,1.43-1.5,5.85,1.14,8.31
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M224.49,1234.91c-3.31,0.91-4.21,3.92-5.47,6.51c-1.97,4.05-5.26,6.14-9.46,7.25c-2.15-4.35-1.52-9.06-2.8-13.58
				c-5.73,7.01-5.73,24.71,0.11,29.07c9.62-7.28,15.88-16.71,18.12-28.76"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M651.54,1201.43c-0.94,4.7-0.59,9.32,0.39,14.01c0.38,1.81,1.04,3.33,2.61,3.39c6.73,0.25,6.85,5.98,6.16,9.52
				c-1.17,6.07-1.08,11.99-1.35,18.04c-0.64,14.43-3.08,28.71-5.31,42.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M608.58,1364.83c3.03-0.23,5.32,0.14,7.14,3.42c2.58,4.64,5.62,9.5,12.34,8.58c0.33-0.04,0.89-0.13,0.97,0.01
				c3.49,6.31,10.95,6.23,16.02,9.97c0.78,0.57,1.73,1.53,2.48,1.43c7.8-1.07,12.48,5.53,18.97,7.64c1.15,0.37,2.66,1.44,2.92,2.46
				c2.51,10.07,11.69,12.51,19.1,16.93c2.73,1.63,4.65,2.61,2.66,6.63c-2.03,4.11,0.31,7,4.77,8.97c4.1,1.82,8.35,3.73,8.62,9.42
				c0.09,2.03,0.71,4.47,2,5.92c5.13,5.74,3.56,11.57,0.91,17.57"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M34.19,138.05c5.77-0.61,10.96,0.45,15.53,4.45c4.41,3.87,9.23,6.26,15.48,3.64c4.41-1.85,8.83,0.4,12.36,2.56
				c8.91,5.46,13,14.34,14.47,24.35c0.49,3.32-0.76,6.7-2.9,9.47c-4.94,6.38-12.15,10.5-16.97,17"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M63.16,582.79c-4.69,4.93-6.94,11.36-10.52,16.97c-1.22,1.91-2.06,4.4-5.56,4.58c-5.47-3.97-5.74-10.46-6.3-16.56
				c-0.45-4.97,0.03-9.98,0.77-15c1.09-7.42-2.99-14.47-2.44-22.18c-4.01,5.16-9.06,9.47-10.42,16.2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M630.56,1193.43c3.22-0.38,5.13,2.04,7.64,3.38c-1.3,5.07-0.47,9.22,3.19,13.25c2.2,2.42,2.51,6.63,2.01,9.83
				c-2.98,19.06-4.47,38.24-5.33,57.49c-0.11,2.44-1.94,4.47-1.52,6.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M669.52,1341.34c5.65-6.42,11.46-12.61,18.95-17.04c5.54-3.27,10.95-4.91,17.55-3.1c6.14,1.69,12.73,1.69,18.91,3.26
				c8.47,2.14,14.96,8.12,21.93,12.99c3.52,2.45,6.88,5.24,10.61,7.3c7.36,4.06,11.24,10.4,14.04,18.04c3.29,9,11,13.97,18.88,18.52
				c8.98,5.18,17.89,10.49,26.99,15.46c13.14,7.18,20.95,18.96,27.96,31.51c0.4,0.72,0.99,1.34,1.49,2.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M49.17,526.82c3.83-9.83,7.02-19.98,11.7-29.38c3.48-6.99,4.4-14.75,7.89-21.54c3.65-7.11,6.99-14.66,14.37-19.06
				c1.46-0.87,1.91-2.55,2.47-3.99c3.24-8.3,4.85-9.16,13.76-6.92c1.09,3.38-0.77,6.39-2.35,8.86c-6.36,9.99-6.63,21.61-9.38,32.55
				c-0.89,3.53-1.08,7.17-2.93,10.53c-5.55,10.07-6.34,21.54-9.06,32.44c-0.49,1.98,0.28,4.55-2.49,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M371.33,438.87c2.36,5.12,0.82,10.68,1.68,16.04c1.09,6.76,1.95,13.39,4.13,20c2.15,6.52,1.46,13.55,0.73,20.43
				c-1.01,9.48-1.71,18.98-2.54,28.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M709.98,1142.46c-8.25,8.06-8.79,5.6-15.01,1.53c-2.28-1.49-4.67-2.45-7.54-0.64c-1.27,0.8-3.26,0.52-4.93,0.57
				c-0.83,0.03-1.67-0.46-2.5-0.44c-5.04,0.12-7.28,4.71-10.99,6.97c-4.11,2.51-3.89,6.2-3.69,10.5c0.22,4.69,1.39,9.27,1.21,13.98
				c-0.18,4.79-1.4,5.87-5.45,3.39c-5.37-3.29-9.58-4.48-16.27-1.38c-6.5,3.01-8.06,7.66-10.4,12.92c-0.52,1.17-0.6,2.51-1.36,3.56"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M179.53,695.72c2.09-19.44,4.28-38.91,1.9-58.45c-0.93-7.66-1.98-15.49-7-22.35c-2.99,1.83-5.92,3.62-8.93,5.46
				c-4.22-7.32-0.47-19.37-13.08-21.78c-0.09-11.59,3.18-23.05,3.19-34.8c0-0.67,0.62-1.33,0.96-2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M628.06,733.7c-2.38,1.81-1.1,3.93-0.5,6c1.05,3.62,3.26,6.94,0.67,11.09c-1.24,1.99,0.18,4.93,0.82,7.4
				c2.77,10.67,3.22,21.73,5.6,32.86c-7.81,3.37-3.86,11.02-5.11,16.71c-0.62,2.82,0.54,7.29,1.53,10.89"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M308.4,668.74c-0.94-0.59-0.72-1.66-1.07-2.47c-1.7-3.87-5.38-4.38-7.86-0.99c-2.11,2.88-3.88,6.32-2.9,9.91
				c0.69,2.51-0.85,4.03-0.38,6.12c-10.8-2.57-11.82-9.55-9.46-18.25c-8.55-0.98-8.59-8.1-10.2-13.84c-1.22-4.36-1.65-8.98-6.6-10.91
				c-0.67-0.26-1.64,0.27-2.47,0.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M927.75,1468.77c4.04,5.69,10.75,6.77,16.49,9.47c12.16,5.72,25.38,7.03,38.44,9.05c9.65,1.49,18.51,4.94,25.98,11.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M706.48,587.29c-0.1,0.68,0.32,1.5-0.5,2c6.2,7.05,4.52,17.79,11.46,24.51c0.67,0.65,1.35,1.61,1.44,2.48
				c0.11,1.12,1.62,2.49-0.4,3.53c-1.12,0.58-1.9,0.45-3.04,0.01c-3.79-1.45-5.52-4.91-6.29-8.1c-1.7-6.99-7.21-12.12-8.5-19.39
				c-0.41-2.27-3.55-4.99-7.64-5.31c-3.42-0.26-6.68-2.74-10.01-4.24"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M604.09,905.1c-1.81,3.75,0.64,7.29,0.57,10.99c-0.2,11.66-0.31,23.32-0.01,34.98c0.31,11.85,2.72,23.58,2.43,35.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M946.23,1452.78c1.67-7.12,8.54-5.49,13.08-7.24c4.21-1.62,9.44-3.34,13.81-1.56c6.85,2.81,13.74,1.1,20.57,1.73
				c3.33,0.31,5.32,2.04,6.99,4.57c5.46,8.27,4.77,7.59,12,4.53c5.74-2.43,7.65-1.05,9.07,5.44c0.42,1.92,0.32,4.26,3.89,4.69
				c8.75,1.04,10.34,5.34,5.58,12.86c-0.71,1.12-2.28,1.79-2.06,3.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M187.53,445.87c-2.2,7.75-4.72,14.43-12.22,19.85c-12.67,9.17-20.99,22.44-25.74,37.61c-2.34,7.47-6.78,13.93-9.99,20.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M814.37,865.62c0.58,2.72-1.79,4.72-1.69,7.5c0.06,1.76-2.21,2.17-3.78,0.97c-2.36-1.8-4.04-4.29-5.32-6.47
				c-1.03,0-1.56-0.13-1.66,0.02c-4.86,7.26-6.6,7.53-11.92,0.41c-3.85-5.14-10.32-6.87-14.08-11.92c-6.98,1.97-9.14-4.7-13.59-7.32
				c-2.66-1.56-3.81,2.14-5.39,3.82c-1.53,0.35-2.75-0.65-3.43-1.55c-3.34-4.44-7.75-7.35-12.51-10.03
				c-2.29-1.29-4.02-2.49-7.07-2.11c-4.17,0.52-6.2-3.56-8.34-6.39c-1.07-1.41-1.51-2.93-3.62-2.92c-7.05,0.06-12.87-3.1-18.54-6.9
				c-1.45-0.97-3.28-2.56-5.44-1.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M987.68,937.58c0.14,4.63-1,9.26,2.37,13.59c2.55,3.27,1.51,7.42,0.71,11.41c-1.43,7.19-3.04,14.27-5.93,21.41
				c-2.08-1.54-2.54-3.41-3.25-5.4c-1.43-3.99-1.5-8.8-7.33-10.26c-2.9-0.73-3.71-4.63-4.03-7.77c-0.14-1.32,0.08-2.67-0.04-3.99
				c-0.2-2.24,1.11-5.49-2.49-5.89c-3.08-0.34-4.35,2.29-4.95,4.89c-1.11,4.81-1.72,9.74-3.12,14.46c-1.26,4.27-2.14,7.65,3,10.2
				c2.03,1.01,2.72,4.08,2.61,6.82c-0.5,12.46-3.02,24.6-6.65,36.44c-2.11,6.88-3.39,14.09-6.86,20.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M579.11,841.64c-2.6-6.77-0.99-18.59,2.58-21.63c7.05,11.92,6.94,25.24,6.42,38.62c-0.23,5.94,0.99,11.52,3.39,17.03
				c2.74,6.28,2.47,13.28,3.53,19.96c0.24,1.55,0.28,3.09,1.06,4.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M272.94,413.39c-1.03-0.43-1.65-1.62-3-1.51c-8.86,0.76-17.66-1.97-26.46-0.87c-6.49,0.81-13.04,3.07-19.46,1.69
				c-8.1-1.74-15.49,0.25-22.99,2.26c-2.6,0.7-5.84,1.43-5.52,5.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M53.67,447.87c-6.16,18.48-13.3,36.57-20.91,54.5c-2.11,4.97-3.45,10.28-5.05,15.47c-1.31,4.26-3.96,7.45-7.51,9.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M771.41,1390.81c4.14,15.5,14.1,26.41,27.49,34.45c16.09,9.66,32.86,18.19,47.96,29.48c1.47,1.1,3.44,2.61,4.95,2.39
				c9.63-1.4,13.73,5.71,18.45,11.68c3.82,4.85,7.44,9.7,12.46,13.56c5.47,4.21,9.35,9.95,12.07,16.38"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M478.72,444.87c4.26-1.19,7.97,0.38,10.91,3.08c3.67,3.38,7.82,4.97,12.55,4.34c7.03-0.93,13.44,1.46,19.97,3.13
				c5.14,1.32,10.43,1.48,15.57,3.24c5.21,1.79,10.4,3.55,14.26,7.36c1.63,1.61,3.32,2.68,5.19,3.22c2.74,0.8,5.51,1.8,7.37,3.69
				c4.94,5.01,11.23,5.65,17.57,6.4c5.26,0.63,10.18,1.29,14.13,5.88c3.28,3.81,6.14,8.49,12.84,8.52c2.77,0.01,6.55,3.57,6.49,8.1"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1009.66,612.27c0.67,0.33,1.3,0.87,2,0.97c6.99,1.02,6.72,1.01,7.05,8.02c0.45,9.47-1.93,18.5-4.18,27.46
				c-3.48,13.85-5.89,27.83-7.37,42.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M721.96,1235.91c5.81,2.27,5.94,6.28,5.24,12.02c-1.45,11.92-3.49,23.8-3.62,35.95c-0.05,4.64-3.66,9.69-9.09,12.05
				c-4.58,1.99-9.09,4.23-13.01,7.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M291.42,936.58c0.85,7.47,3.25,14.84,1.55,23.08c8.27,6.13,5.93,15.91,6.15,24.4c0.27,10.62-0.04,21.31-0.19,31.97
				c-0.2,13.82-0.35,27.65-0.5,41.48c-0.03,2.53-0.28,4.97-2.01,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M694.99,727.2c-3.96-0.96-6.12-4.35-8.32-7.13c-2.16-2.73-3.51-4.78-7.67-4.61c-3.4,0.13-4.09-3.97-4.33-6.76
				c-0.17-1.94-0.38-4.39-1.3-5.34c-3.97-4.05-4.08-9.11-4.9-14.12c-0.95-5.84-4.27-10.73-7.56-15.42c-1.77-2.52-3.96-4.78-5.38-7.58
				c-1.54-3.04-3.88-3.07-7.01-2.65c-3.54,0.48-3.9,2.39-4.01,5.14c-0.09,2.02-1.29,3.84-1,6c0.42,3.12-1.76,3.01-3.96,3.02
				c-5.67,0.02-8.21-4.15-10.71-8.86c-7.28,5.92-11.89,12.78-14.67,20.86c-0.37,1.09,0.57,2.63,0.9,3.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M28.69,1423.3c3.29,9.56,3.93,19.67,5.94,29.49c1.88,9.16,4.27,18.17,8.63,26.43c3.33,6.31,2.77,12.91,2.91,19.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M963.21,1434.79c4.9-5.79,11.09-5.15,17.48-3.51c0.98,0.25,2.25,0.82,2.95,0.44c5.62-3.06,10.58,1.74,16.04,1.3
				c1.56-0.13,3.56,1.94,4.57,4.24c2.72,6.22,5.8,7.65,11.85,4.92c4.96-2.23,9.02-2.33,12.52,2.12c2.55,3.25,4.87,3.05,8.07,0.56
				c6.62-5.15,8.67-4.95,15.89,0.47c0.83,0.62,1.51,0.96,2.53,0.98c7.67,0.17,8.09,0.39,13.49,6.47c5.54-5,15.35,0.81,16.8,6.05
				c0.78,2.81,2.28,2.37,4.13,3.07c5.5,2.09,11.48,3.92,13.03,10.87"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M579.61,1385.82c1,2,3.3,2.69,3.49,5.97c0.13,2.33,5.53,3.46,8.03,5.99c2.81,2.85,4.5,6.52,6.66,9.73
				c1.54,2.29-1.11,5.76-3.71,7.77c3.35,5.12,3.17,8.49-0.47,11.53c-1.53,1.28-3.39,1.31-4.98,0.88c-1.71-0.47-1.63-2.33-1.53-3.89
				c0.32-4.8-1.22-6.31-6.04-4.1c-3.36,1.54-4.9-0.23-3.95-3.89c1.07-4.13-0.63-6.51-4.99-6.99c-3-0.33-5.92-1.44-8.99-0.99
				c-3.52,0.52-3.46-2.02-2.91-3.98c1.28-4.54-1.23-5.03-4-3.38c-3.45,2.05-4.72,1.49-6.21-2.09c-1.18-2.85-4.52-2.21-6.26-0.91
				c-2.58,1.93-4.28,1.53-6.64-0.08c-2.56-1.75-4.94-2.53-7.58,0.84c-2.73,3.49-6.5,3.61-9.87,0.12c-1.16-1.2-2.15-2.69-4.4-3.11
				c-3.71,4.72-8.93,6.94-15.06,6.5c-2.27-0.16-5.7,0.12-4.16-4.46c0.5-1.48-0.84-3.63-3.34-3.44c-3.05,0.23-4.9-1.62-5.93-4.03
				c-1.35-3.17-3.4-2.51-4.99-0.9c-2.48,2.5-5.27,3.15-8.57,2.96c-1.89-0.11-3.24-0.77-4-2.53c-1.77-4.11-3.99-4.9-6.93-1.94
				c-3.1,3.14-5.88,3.46-9.28,0.44c1.12-1.74,2.17-3.37,3.22-5c-1.19-2.92-3.11-4.41-6.5-4.06c-2.63,0.27-6.8,0.83-7.18-1.98
				c-0.57-4.15-4.69-8.39,0.07-13.07c4.63-4.54,8.16-10.09,14.03-13.51c2.48-1.44,4.45-1.66,6.54-0.32c4.1,2.64,7.54,3.83,12.2,0.18
				c2.89-2.27,5.84,2.04,8.81,3.27c2.49-0.84,4.1-2.83,6.03-4.45c2.85-2.41,6.32-2.69,7.79,1.02c1.82,4.58,5.73,2.83,6.97,1.72
				c4.17-3.73,7.29-0.76,8.42,1.82c2.2,4.99,5.87,4.67,9.74,4.33c6.65-0.59,8.56,0.69,9.49,7.05c4.99-1.21,8.85,2.5,13.46,3.15
				c1.93,0.27,1.69,2.52,1.06,4.36c-1.23,3.57-0.11,4.86,3.81,6.37c3.31,1.27,7.45,1.13,10.18,4.07c0.39,0.42,1.46,0.71,1.93,0.48
				c5.01-2.46,9.14,0.1,13.54,1.98c3.7,1.58,6.63-2.62,10.48-2.1c0.87,0.12,3.07-0.62,3.35-0.24c2.26,3.05,4.5,4.88,8.65,3
				c3.14-1.42,4.24,4.36,8.59,2.79c2-0.73,4.2,3.95,6.42,6.05c2.07,1.96,4.38,3.29,2.03,6.63c-0.63,0.9-0.13,2.53,1.9,2.46
				c6.52-0.22,9.85,4.71,10.68,9.52c1.31,7.68,6.1,10.21,12.23,13.08c18.64,8.75,37.08,17.93,54.04,29.79
				c7.79,5.45,15.78,10.66,24,15.44c5.56,3.23,11.79,4.9,18.42,4.11c3.89-0.46,7.1,1.53,10.51,2.94c6.21,2.58,12.31,5.35,17.96,9.04"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M857.82,894.11c-2.88-0.07-5.67,0.47-8.51,0.9c-7.13,1.06-10.98,5.37-8.91,13.08c0.82,3.05,0.04,4.86-2.52,6.57
				c-3.15,2.1-6.08,4.54-9.01,6.96c-3.64,3-3.4,6.73,0,10.47c3.06,3.36,7.18,5.2,10.97,7.52c3.97,2.43,8.44,4.31,10.32,9.27
				c-0.67,1.37-1.5,3.04-2.32,4.71"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M943.23,821.65c1.61-3.58,5.11-2.92,8-3.47c6.94-1.33,14.1-1.04,20.99-2.96c3.64-1.01,6.57-2.69,8.99-5.55
				c3.84-4.53,4.05-7.84-0.06-11.98c-6.14-6.19-13.81-9.05-22.44-9.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M474.72,698.22c1.65,9.53,2.5,19.2,5.45,28.5c0.71,2.25-2.87,7.03-4.9,6.35c-6.89-2.29-6.14,3.87-6.74,6.69
				c-0.69,3.2-1.33,7.01,0.61,10.49c0.64,1.14,0.73,3.69-0.01,4.32c-3.71,3.14-0.28,7.9-2.89,11.12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.72,1337.35c-1.12-4.97-0.47-10-0.42-14.99c0.04-4.26,5.57-7.42,9.38-5.45c5.37,2.79,10.45,0.45,15.48-0.68
				c3.65-0.83,7.05-2.81,10.51-4.38c6.19-2.8,11.77-1.21,15.49,4.51c3.13,4.81,8.36,7.03,13.99,6.02c0.97-0.17,2.07-0.28,2.98,0.01
				c10.06,3.21,21.34,3.19,29.56,11.38c1.38,1.37,4.22,1.83,5.84,0.93c3.25-1.81,6.13-0.6,7.94,1.27c3.87,3.97,8.29,3.13,12.6,2.29
				c7.69-1.51,9.41-0.97,14,6.08c2.65,4.07,7.35,4.83,12.44,1.42c3.31-2.22,6.17-2.48,9.56-0.46c4.13,2.47,8.9,2.48,13.46,2.42
				c4.22-0.05,5.68,2.28,5.91,5.62c0.31,4.53,0.41,9.15-4.38,12.05c-1.86,1.12-2.28,3.47-3.12,5.52c0.78,1.12,1.32,2.3,3.08,2.46
				c5.32,0.48,8.96,3.49,10.42,8.47c2,6.82,7.34,9.94,13.11,12.36c4,1.67,6.42,4.19,8.01,8.08c1.2,2.95,3.49,4.13,6.62,5.66
				c3.68,1.8,11.44,0.07,11.34,7.86c-0.01,0.5,0.58,1.4,0.96,1.44c4.86,0.45,4.11,4.72,4.87,7.59c0.86,3.27,1.47,4.98,5.66,4.18
				c3.35-0.64,5.42,2.81,7.92,5.26c-2.5,1.96-2.98,4.78-4.58,6.86c2.95,3.71,7.21,5.56,10.25,9.03c2.8,3.19,2.93,6.44,2.88,10.12
				c-0.04,2.88,0.01,5.11,3.3,7.26c3.99,2.61,5.36,7.58,4.23,12.74c-0.28,1.26-0.22,2.45,1.38,3.57c9.45,6.61,5.94,8.03,4.06,14.9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,531.82c-1.02,0.81-2.04,1.62-3.08,2.45c-2.35-1.51-4.19-3.67-6.6-6.48c-5.12,6.67-12.43,11.76-13.77,21.02
				c-0.42,2.9-1.09,5.61-4.02,6.98c-2.83,1.32-4.41-0.78-5.97-2.5c-2.93-3.25-5.78-1.2-6.84,1.1c-2.82,6.06-8.29,4.12-12.68,4.72
				c-2.79,0.38-3.98-2.83-4.41-5.31c-0.74-4.37-3.28-7.11-6.96-8.97c-4.91,2.55-6.87,7.03-8.08,11.99
				c-3.18,12.99-6.37,25.97-9.43,38.99c-0.29,1.23-1.13,1.95-1.56,2.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M858.82,1454.28c4.53-1.7,7.78,1.83,11.43,3.15c7.27,2.63,14.08,7.16,21.17,9.43c21.21,6.79,39.31,20.74,61.27,25.53
				c4.36,0.95,8.51,3.29,12.02,6.37"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M243.47,1464.27c-5.47-2.41-11.28-3.44-15.29-9.28c-3.04-4.44-12.67-0.68-14.78,4.74c-0.5,1.29-1.44,2.31-0.37,3.51
				c1.03,1.16,2.39,0.6,3.45,0c5.23-2.96,10.17-1.33,14.39,1.69c2.44,1.74,3.98,3.85,7.59,4.1c3.3,0.23,5.02,4.67,8.51,5.74
				c3.11-6.7,2.08-10.33-3-10.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M795.39,1181.94c3.36-1.08,2.97-3.88,3-6.5c0.02-1.97,1.84-3.44,0.47-6.17c8.69,1.02,15.2-2.69,22.39-6.88
				c-0.77,5.53-2.85,7.52-6.83,8.71c-4.23,1.26-6.84,4.25-7.73,9.3c-1.23,6.99-2.46,14.02-3.79,20.93
				c-1.63,8.48-0.9,16.75-1.55,25.08c-0.08,1.02-0.63,2-0.96,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M251.46,1003.04c6.83,16.49,13.78,32.93,20.41,49.5c2.58,6.45,2.81,13.67,6.01,19.99c0.07,0.13-0.62,0.64-0.95,0.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.72,432.88c-1.04,9.81-0.16,19.66-0.5,29.48c-0.04,1.28,0.61,2.2,1.5,3c4.31,3.82,4.18,8.84,4.04,14
				c-0.15,5.45,0.16,10.69,2.13,16.11c1.35,3.72-0.46,8.54-0.65,12.88c-0.31,7.28-2.09,14.68,2.33,21.58
				c0.81,1.26,1.82,3.18,2.65,3.25c5.83,0.47,6.74,4.79,8.05,9.13c0.77,2.54,2.63,4.42,4.86,6.11c2.6,1.97,5.09,4.34,3.66,8.44
				c-0.45,1.29,0.24,2.96,0.4,4.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M561.63,481.85c1.93,4.72,6.85,5.66,10.56,7.88c14.96,8.95,29.14,18.78,38.78,33.67c3.75,5.79,6.79,11.99,7.33,19.4
				c0.45,6.22,4.58,11.62,6.76,17.51c0.37,0.99,1.23,2.23,0.92,2.96c-2.54,5.95,0.35,10.92,2.59,16.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M69.65,446.37c-5.43,19.03-10.97,38.01-19.45,55.98c-2.01,4.25-3.69,8.65-5.52,12.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M486.71,1288.87c12.65,5,25.34,9.9,37.95,15.02c9.09,3.69,18,7.79,27.45,10.58c1.24,0.37,2.03,0.01,3.03-0.11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M692.99,570.3c-0.83-0.67-2.02-1.16-2.44-2.02c-6.24-12.75-15.46-22.86-26.99-31.01c-1.1-0.78-1.94-2.18-2.5-3.46
				c-5.5-12.59-15.66-21.58-24.73-31.26c-3.68-3.92-5.76-8.1-7.79-12.69c-1.72-3.89-4.34-6.35-8.95-7.14
				c-5.97-1.02-11.04-4.48-16-7.87c-12.77-8.73-27.11-14.37-40.95-21c-2.91-1.39-6.03-1.33-8.98-0.89c-4.65,0.7-9.04,0.32-13.51-1.05
				c-6.48-1.99-13.37-1.58-19.93-2.75c-12.78-2.29-25.35-5.76-37.99-8.82c-1.73-0.42-3.34-0.64-5,0.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M385.82,1244.4c-5.06,1.84-9.78-1.59-15.03-0.76c-4.86,0.77-9.81,0.71-14.46,2.72c-1.38,0.6-3.36,1.45-4.41,0.91
				c-4.56-2.34-9.09,0.14-13.54-0.45c-3.61-0.48-4.22,4.98-8,3.58c-2.1,5.43-11.09,4.15-11.25,11.99c-0.05,2.48-5.23,2.96-5.21,6.67
				c-5.93-1.91-8.26-0.26-12.02,7.33c-6.99,1.62-11.68,5.65-16.42,9.21c-0.45,2.36,2.28,3.08,2.01,5.28
				c-0.29,2.31,2.41,3.71,3.36,3.31c5.78-2.38,10.44,4.11,16.07,1.71c0.92-0.39,1.72,0.21,2.51,0.94c6.06,5.61,8.32,5.59,15.11-0.1
				c0.43,0.36,1.01,0.66,1.33,1.14c3.28,4.79,7.13,6.82,12.56,3.07c0.55-0.38,1.94-0.44,2.96,0.39c6.25,5.04,11.91,2.36,17.49-1.49
				c3.39,1.59,5.9,3.89,7.56,7.47c1.49,3.2,4.06,4.46,7.93,2.64c5.14-2.41,11.58-1.52,16.05-6.71c2.68,4.57,5.82,3.83,8.85,1.08
				c2.33-2.11,4.29-4.17,8.05-3.08c2.89,0.84,3.5-3.24,5.96-3.88"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M757.93,622.27c-1.05,6.58,1.98,12.38,3.37,18.52c1.51,6.68,0.57,13.63,0.59,20.46c0.03,10.24,2.65,20.23,2.67,30.48
				c0.01,2.71,2.28,4.51,5.3,5.63c5.68,2.11,8.21,6.79,8.04,12.86c-0.4,13.82,0.78,27.66-0.46,41.48c-0.09,0.98,0.3,2,0.47,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M403.8,1255.39c3.51,5.51,4.9,11.82,6.43,18.01c4.1,16.57,4.57,33.5,3.98,50.45c-0.18,5.35-1.32,10.66-1.91,15.99
				c-0.89,8.16-1.68,16.32-2.53,24.48c-0.04,0.35-0.32,0.67-0.48,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M716.97,1326.85c-6.45,9.11-3.09,9.78,7.49,17.99c-1.08,6.92,5.91,18.31,12.5,19.93c3.18,0.78,5.72,2.09,8.04,4.5
				c4.28,4.45,9.01,8.33,15.92,5.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M114.1,1168.94c-3.67,4.19-10.05,4.85-13.02,9.77c0.51,3.38,1.48,5.65,5.54,5.36c1.94-0.14,4.27,0.78,5.93,1.94
				c4.62,3.23,8.69,7.05,14.48,8.63c2.88,0.78,4.31,3.35,0.16,5.95c-2.47,1.54-4.33,3.74-7.59,3.9c-2.54,0.13-3.95,1.98-4.8,5.09
				c7.8-0.36,12.39,5.46,18.28,8.83"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M212.5,972.56c6.16,9.47,9.96,20.03,13.99,30.48c4.64,12.04,10.06,23.68,18.01,33.96c0.27,0.35,0.97,0.36,1.47,0.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M256.95,1386.32c3.91-0.11,7.83,0.08,11.47,1.55c3.73,1.51,7.51,2.23,11.5,2.53c3.32,0.25,6.31,1.3,9.14,3.75
				c3.28,2.84,8.13,3,12.36,3.54c8.15,1.05,16.38,1.48,24.45,3.15c0.54,0.11,1,0.64,1.5,0.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M471.48,430.38c-9.75,6.59-20.87,6.04-31.7,5.32c-8.29-0.55-16.35-1.34-24.52,1.06c-3.86,1.13-8.04-0.47-11.92-1.1
				c-8.21-1.32-16.07-0.89-24.02,1.18c-6.95,1.81-13.9,1.14-20.99,0.2c-6.06-0.8-12.3-0.09-18.46-0.18c-7-0.11-14.01,0.45-20.99-0.92
				c-4.87-0.96-8.84,1.97-12.97,3.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M944.23,575.79c-3.79-1.04-7.84,0.39-11.45-0.63c-8.65-2.44-15.06,1.73-20.93,6.73c-5.49,4.68-11.16,8.06-18.57,7.36
				c-2.81-0.27-4.84,1.16-7,2.52c-7.14,4.52-14.28,9.06-21.49,13.47c-2.55,1.56-5.37,2.42-8.48,2.05
				c-5.32-0.63-10.09,1.16-13.92,4.55c-6.99,6.2-14.37,10.87-24,12.19c-4.05,0.56-8.11,3.57-11.42,6.35
				c-4.51,3.78-8.8,7.67-14.95,8.96c1.18,2.31,2.92,2.14,4.35,1.86c4.37-0.84,8.85-1.51,12.5-4.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M65.65,1474.76c-4.4-10.94-11.09-20.79-15.08-31.95c-0.76-2.13-0.68-3.92-0.75-6.02c-0.1-3.06,0.07-6.28-0.92-9.58
				c-2.49-8.22-1.27-9.63,8.05-13.54c3.16,1.66,4.97,5.44,8.69,6.66c0.7,0.23,1.04,1.01,1.04,1.96c0.09,14.44,4.37,28.48,4.01,42.98
				c-0.09,3.44-1.12,7.4-2.85,9.2c-2.64,2.75-0.65,4.57-0.71,6.79c-0.17,5.98,2.64,11.52,2.52,17.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M322.88,711.71c1.68-9.71-0.89-19.1-2.75-28.43c-0.54-2.69-0.65-5.27-0.08-7.5c1.07-4.15,0.98-8.04-0.18-12.04
				c-8.48-1.15-9.84-0.11-10.49,8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M737.95,470.85c0.83-0.83,1.66-1.67,2.5-2.5c11.44,7.65,15.96,19.31,19.68,31.92c2.9,9.82,5.8,19.68,12.54,28.23
				c6.41,8.13,10.24,18.13,14.23,27.79"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.72,1351.34c-1.12,9.98-0.45,19.99-0.43,29.98c0.02,11.33-0.7,22.67,0.43,33.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M857.82,896.1c-1.83,0.67-3.63,1.46-5.5,1.97c-4.52,1.23-5.47,3.24-4.59,8.04c0.49,2.66,0.78,5.59-2.36,7.55
				c-2.38,1.48-4.33,3.64-6.54,5.42c-5.17,4.15-5.52,8.2-0.43,12.43c3.68,3.05,7.76,5.71,11.98,7.97c3.46,1.86,5.58,4.23,4.86,8.08
				c-0.67,3.59-2.49,6.13-6.89,6.67c-3.91,0.48-6.05,7.56-3.46,10.79c2.69,3.35,6.54,5.54,6.28,11.06c-0.1,2.2,4.14,3.65,7.12,4.56
				c6.66,2.02,13.05,4.66,18.03,9.89c2.24,2.36,4.31,4.65,5.05,8c0.32,1.45,1.4,2.85,2.45,3.98c2.08,2.26,5.69,2.94,5.27,7.56
				c-0.17,1.9,3.63,3.31,6.19,3.95c2.47,0.61,4.99,1.02,7.49,1.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M205.51,997.55c-1.81,1.81-1.72,4.5-1.82,6.51c-0.99,18.47-4.82,36.9-1.18,55.46c0.69,3.49,0.62,7.13,2,10.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M759.42,952.57c-2.93-4.75-6.36-6.65-12.57-5.44c-3.22,0.62-6.41,0.01-9.51,2.29c-1.77,1.3-4.95-0.11-6.23-1.49
				c-2.66-2.85-4.51-1.6-6.63,0.15c-2.99,2.47-5.83,4.1-10.08,2.18c-2.01-0.9-4.11,2.57-7.53,1.09c-2.9-1.26-6.4-0.58-9.02-3.65
				c-1.59-1.86-3.87-0.53-4.85,1.87c-1.16,2.85-4.07,3.26-5.91,2.33c-3.5-1.79-5.36-0.7-7.09,2.17c-1.01,1.68-1.46,4.75-4.52,2.04
				c-0.1-0.09-0.85,0.23-0.94,0.48c-1.68,4.87-7,6.51-9.42,10.54c-2.23,3.71-4.98,5.71-9.05,4.32c-2.48-0.84-4.51,0.63-4.83,1.68
				c-1.04,3.4-4.45,6.28-3.57,9.9c1.7,7.05-0.12,12.54-6.58,17.09c-2.46,0.12-4.45-2.58-7.03-4.07c-6.02,3.69-7.14,3.25-14.16-5.72
				c-2.32,2.71-4.49,3.18-7.39,0.3c-5.03-5-12.05-5.28-18.4-5.16c-2.53,0.05-3.19-0.56-3.96-1.94c-3.24-5.8-4.27-5.96-8.54-0.98
				c-0.46,0.53-1.27,1.53-1.45,1.43c-5.12-2.7-5.2,2.98-7.69,4.28c-4.34,2.26-2.04,6.82-4.78,9.35c-2.34,2.15-1.76,6.29-5.99,7.62
				c-4.15,1.3-4.69,10.25-1.59,14.83c1.28,1.89,3.59,3.58,2.05,6.48c-2.27,4.26-4.86,2.55-7.5,0.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1017.65,1170.44c-0.68,5.26,1.21,8.6,6,9.96c4.28,1.21,9.73-0.12,11.57,6c0.31,1.02,1.79,0.39,2.92,0.11
				c4.58-1.17,8.92-0.99,11.8,3.96c2.94-9.17,5.94-8.8-0.87-29.14c2.04-1.14,4.09,0.12,6.02,0.3c1.83,0.17,4.43,0.97,5.39,0.17
				c5.78-4.82,12.77-2.57,19.11-3.82c2.07-0.41,3.82-1.34,6.1-3.6c1.13,6.44,6.95,10.95,2.89,17.56c-0.91,1.47-0.84,2.78,0.49,4.01
				c1.87,1.71,10.77,2.99,12.94,1.41c1.63-1.18,3.1-1.3,4.52-0.38c3.7,2.39,6.02,1.89,7.62-2.51c0.79-2.18,2.85-3.75,5.28-5.18
				c6.39,3.65,6.09,10.63,7.55,16.65c1.25,5.14,2.19,10.29,6.8,13.55c6.29-1.7,8.97-6.71,11.72-11.58c1.98-3.51,3.79-5.76,8.6-3.66
				c3.65,1.6,4.53-3.73,7.41-4.81"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M691,469.36c9.71-3.52,19.72-6.33,28.91-11.1c3.92-2.03,6.09,0.39,6.77,2.69c0.69,2.32,2.01,3.83,3.33,5.37
				c6.69,7.83,7.1,17.66,8.86,27.05c0.39,2.09-0.11,4.28-0.77,6.53c-1.69,5.76-2.09,11.61,1.79,16.99c1.42,1.97,2.45,4.32,2.05,6.96"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M664.02,844.64c0.5-3.22-1.39-6.03-2.72-8.37c-2.65-4.66-3.14-9.72-4.14-14.64c-0.78-3.83,1.17-7.57,2.96-10.91
				c1.3-2.42,3.45-2.21,5.41-0.08c3.75,4.07,9.06,7.39,11.15,12.15c3.76,8.56,10.82,15.18,13.34,24.34
				c1.12,4.07,2.52,8.91,6.54,10.26c5.67,1.9,6.48,6.51,8.41,10.74"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,447.37c-2,2.17-3.86,4.48-6.04,6.45c-1.75,1.58-3.75,3-6.46,2.58c-1.51-0.23-3,0.2-3.98,1.48
				c-4.38,5.76-11.39,8.46-16.04,13.95c-2.66,3.15-6.19,4.8-10.45,5.08c-6.13,0.4-8.17,5.53-10.42,9.98
				c-2.51,4.98-5.19,9.48-11.05,10.99c-0.9,0.23-1.84,0.81-2.48,1.49c-8.69,9.1-21.49,13.38-28.54,24.44
				c-1.79,2.8-4.65,3.54-7.41,1.48c-4.79-3.57-9.23-4.34-14.09-0.04c-1.52,1.35-3.92,0.88-5.92,0.09c-2.16-0.85-4.27-1.99-6.51-2.42
				c-2.47-0.47-5.21-2.66-6.34,2.84c-0.95,4.62-6.8,6.33-11.19,7.9c-2.32,0.83-4.32,1.92-5.34,3.72
				c-4.47,7.94-11.62,12.44-19.61,15.86c-1.65,0.71-2.67,1.64-3.46,3.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M240.47,972.06c4.14,9.34,7.51,19.11,9.2,29.03c2.07,12.18,7.25,23.8,6.78,36.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M897.28,1183.94c1.41,8.45-1.42,16.4-4.36,23.84c-1.48,3.75-6.95,5.72-11.62,6.64c-2.27,0.45-4.64,0.89-5.97,3.01
				c-4.36,6.97-11.58,10.75-17.51,15.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M621.07,454.36c0.34,3.04,3.26,4.71,5.07,5.25c6.11,1.82,9.81,6.24,13.35,10.79c6.35,8.15,14.91,13.8,22.68,20.27
				c3.9,3.24,8.17,6.3,14.33,6.49c7.31,0.22,8.49,2.7,10,11.68c-6.19,5.78-7.05,11.85-1.38,21.92c7.09,12.59,7.81,26.17,8.59,40.03
				c0.29,5.19,3.36,10.32,8.33,12.9c7.17,3.73,12.49,9.47,17.87,15.14c4.95,5.21,11.63,8.64,15.21,15.35
				c0.81,1.52,3.63,2.11,5.81,2.65c3.99,1.01,8.63,0.34,11.62,4.35c0.34,0.45,2.22-0.25,3.38-0.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1134.53,65.59c-1.36-4.21-2.01-8.68-5.46-12.02c-5.38-5.22-11.85-8.6-18.44-0.56c-4.87-1.76-8.59-4.8-11.12-9.37
				c-0.77-1.39-2.25-3.35-3.44-3.37c-8.03-0.16-11.35-6.94-16.49-11.14c-2.75-2.25-6.05-3.49-9.49-3.96
				c-7.89-1.09-9.57,0.21-14.05,10.11c-2.42-0.85-3.59-3.17-5.41-4.67c-3.3-2.72-7.81-2.65-11.01-0.01c-5,4.11-10.1,8.04-16.44,10.1
				c-2.4,0.78-4.39,2.54-5.07,5.4c-0.45,1.88-1.7,2.43-3.93,2.65c-10.59,1.03-13.69,2.13-15.05,14.8c-7.31-5.05-15.45-6.49-23.92-5.9
				c-13.23,0.92-21.84,7.26-23.89,18.46c-0.5,2.71-0.57,5.77,1.91,7.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M716.97,1376.82c-4.66-5-9.31-10.01-13.99-14.99c-4.23-4.51-5.96-10.1-6.97-16c-0.6-3.52-1.35-4.42-5.35-5.59
				c-2.79,6.93,4.65,10.45,5.05,16.24c-6.05,3.29-11.15-0.62-14.7-11.65c-0.33,0-0.78-0.14-0.98,0.02c-1.87,1.53-5.68,0.4-5.96,3.98
				c-0.27,3.45,2.73,5.15,5.01,6.37c8.02,4.29,15.99,8.93,25.41,9.62"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M20.2,1051.01c-3.53-0.2-9.23,1.26-8.48,3.31c1.59,4.36-2.3,4.59-2.83,6.73c-1.05,4.24-4.88,5.58-7.52,8.29
				c1.41,2.19,3.51,2.29,5.35,2.62c13.94,2.47,26.13,9.84,39.42,14.14c2.61,0.84,5.04,2.76,6.53,5.39"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M528.67,618.77c1.57,6.76-0.28,13.41-1.24,19.95c-0.59,4.06,1.35,7.31,1.27,11.03c-0.22,9.39-0.23,18.78-2.45,28
				c-0.21,0.87,0.59,1.98,0.93,2.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M232.98,427.38c-9.48-0.5-18.35-4.14-27.52-5.69c-13.2-2.22-26.83-1.88-39.87,1.82c-11.68,3.31-23.28,1.74-35,1.5
				c-10.88-0.22-21.65-1.28-32.46-2.12c-4.71-0.37-9.15,0.91-13.48,2.51c-9.23,3.41-18.48,6.7-28.48,6.96c-1.4,0.04-2.91,0.35-4-0.99
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M622.57,1470.27c-0.15-3.02,1.3-5.79,0.69-9.03c-0.54-2.86-2.29-5.06-4.15-4.74c-5.19,0.9-5.31-3.44-5.77-5.77
				c-0.62-3.13-0.68-5.32-4.04-7.31c-1.7-1-3.83-3.68-5.33-6.06c-1.84-2.92-4.65-4.43-6.82-0.05c-0.83,1.68-1.88,2.13-3.55,1.99
				c-1.82-0.15-2.87,0.23-3.62,2.44c-1.69,4.93-5.48,5.45-9.34,1.52c-2.38-2.43-3.47-6.35-8.02-6.08c-0.96,0.06-0.81-1.21-0.61-2.41
				c0.99-5.81-0.71-7.39-6.55-6.45c-2.29-3.44-3.52-9.29-9.82-5.03c-3.9-3.88-5.86-1.09-8.68,1.82c-1.61,1.67-3.55-2.12-5.75-2.42
				c-5.55,8.32-7.86,9.14-14.48,5.63c-3.65,2.81-4.43,6.67-4.06,10.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M873.3,598.28c-3.13-2.44-7.02-3.41-10.38-5.2c-11.56-6.17-22.24-13.66-28.58-25.78c-0.26-0.5-0.37-1.22-0.77-1.47
				c-9.88-5.98-16.23-15.59-24.13-23.59c-3.57-3.61-8.35-5.02-12.3-8.12c-8.56-6.71-11.62-16.02-13.96-25.37
				c-2.35-9.39-7.68-15.66-15.35-20.28c-3.65-2.21-6.63,1.16-9.41,3.36"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1102.56,49.1c-4.26,3.38-9.92,1.71-14.83,4.18c-6.05-7.36-14.47-9.75-23.62-9.59c-8.79,0.15-12.05,3.35-13.55,12.27
				c-2.27-0.12-4.6-0.21-6.93-0.37c-2.31-0.16-4.67,0.53-5.37,2.55c-1.33,3.82-3.22,2.82-6.15,2.09c-7.87-1.98-13.71,4.3-11.46,11.86
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M264.44,1166.95c-4.21,2.3-6.84-0.36-9.69-3.19c-3.76,1.99-7.9,4.71-10.12-2.22c-1.59,0.92-2.96,1.55-4.15,2.44
				c-4.14,3.11-6.48,2.72-8.95-2.05c-1.24-2.41-1.89-2.95-5.12-2.32c-4.19,0.81-8.86-0.97-12.42-4.14c-1.29-1.15-2.29-2.92-4.5-2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1097.57,641.25c-4.13,8.06-2.29,17.04-3.97,25.49c-2.09,10.48-4.02,20.98-6.02,31.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M251.46,591.78c-2.57,9.49-3.49,19.24-4.51,28.98c-0.76,7.31-0.22,14.93-4.52,21.47c-1.36,2.06-0.47,4.47-1.63,6.62
				c-6.49-4.03-10.19-9.56-9.33-17.59c0.55-5.13-3.71-9.08-6.33-11.03c-4.65-3.47-6.62-7.72-8.68-12.44
				c-0.26-0.6-0.97-1.01-1.47-1.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M702.98,1498.75c-4.65-4.31-10.89-4.63-16.5-6.43c-8.63-2.76-17.41-5.11-26.46-5.99c-3.12-0.3-6.32,0.28-9.48,0.42
				c-3.7,0.17-2.74,2.38-2,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M439.76,740.7c-6.45,1.03-13,2.03-19.46,1.32c-4.16-0.46-6.95,2.36-7.67,4.31c-1.66,4.45-4.61,5.07-8.32,5.25
				c0.12,4.76-1.29,8.9-4.28,12.46c0.33,0.71,0.43,1.45,0.79,1.62c12.54,6.06,16.11,20.42,26.39,28.6c1.77,1.41,2.47,3.65,1.73,6.46
				c-0.65,2.45-0.71,5.34,0.33,7.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M636.05,676.23c1.19-15.42-2.59-30.17-5.99-44.97c-0.89-3.89-4.34-3.42-7.01-3.92c-2.79-0.52-3.54,1.21-4.16,3.88
				c-0.59,2.53-0.09,4.78,0.26,7.03c1.67,10.81,0.58,21.67,0.94,32.49c0.05,1.37-0.66,2.99,0.98,4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1111.55,746.19c-1.36,4.92,0.15,7.37,5.5,8.46c3.29,0.67,6.74,0.71,9.97,1.59c11.3,3.06,22.7,5.3,34.48,4.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M414.79,645.25c1.17-4.8,0.05-9.66,0.45-14.5c0.28-3.43-0.78-7.31,4.87-8.05c2.47,3.39,6.42,5.8,11.67,5.57
				c1.68-0.08,2.24,0.76,2.57,2.48c1.15,6.03,2.62,12,3.91,18.01c0.71,3.3,1.82,5.48,6,5.64c5.5,0.21,7,5.24,9,9.35"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M83.63,1411.3c3.02-2.84,7.39-3.31,10.22-6.18c8.92,6.07,13.55,5.55,20.15-2.89c2.49-3.19,5.32-2.96,8.03-1.8
				c2.91,1.25,5.55,3.33,8.01,5.41c3.62,3.07,7.49,3.41,11.49,1.38c2.19-1.11,4.03-1.43,6.04,0.06c0.11,0.08,0.36-0.05,0.49,0.02
				c3.11,1.79,6.08,1.24,9-0.5c1.46-0.87,1.39-2.54,0.9-3.45c-1.58-2.97-3.67-6-7.4-5.93c-3.34,0.07-5.68-1.21-7.46-3.64
				c-3.04-4.14-8.28-4.51-12.1-7.37c-2.43-1.82-5.41-1.4-8.46-0.22c-3.85,1.5-6.5-0.19-8.5-4.49c3.34-5.75,6.95-11.1,15.04-9.38"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M71.65,1076c2.78,3.14,3.76,6.68,4.34,10.95c0.49,3.57,3.96,6.73,6.5,10.66c-4.89-0.53-9.27,0.17-13.34,2.36
				c-2.62,1.42-5.7,2.43-4,6.51c1.69,4.06-1.1,6.49-4.04,7.29c-3.56,0.96-2.57,2.64-1.94,4.7c2.09,6.75,3.1,7.27,10.46,8.16
				c1.74,0.21,4.81-0.99,5.51,2.33"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M651.54,562.8c-3.16,2.17-6.88,3.83-9.38,6.59c-6.33,6.97-13.59,11.1-23.01,13.22c-9.98,2.24-19.33,7.14-29.52,9.28
				c-3.68,0.78-7.35,0.94-11.02,1.33c-5.79,0.62-11.25,1.94-15.98,5.55c-3.18,2.42-6.4,4.79-10.48,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M727.46,459.86c5.37,2.06,7.54,7.35,9.73,11.63c4.72,9.22,7.39,19.29,7.83,29.85c0.14,3.35,1.07,6.71,1.95,9.99
				c2.14,7.98,2.06,8.31-5.53,13"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M807.37,639.26c-4.37,9.84-13.99,12.94-23.09,17.24c1.55,1.16,2.59,1.94,3.77,2.82c-0.22,0.47-0.33,1.06-0.68,1.4
				c-5.11,5.1-10.64,9.39-18.46,8.47c-2.15-0.25-3.48,1.74-5.5,1.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M850.33,1498.75c-7.2-9.54-16.73-11.56-26.46-5.47c-4.18,2.62-8.55,0.44-11.32-1.31c-6.05-3.84-12.09-2.5-18.16-2.09
				c-4.93,0.33-9.12-0.76-12.99-3.62"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M695.99,929.59c0.21,4.23,2.04,7.56,5.84,10.07c0.63,0.2,1.01-0.75,1.67-1.04c2.72-1.16,6.72,0.66,8.33-2.61
				c1.32-2.7-1.18-5.5-1.46-8.42c-0.39-4.09-0.85-8.27-2.66-12.08c-8.6,2.95-12.19,0.38-16.21-11.41
				c-3.01-8.82-6.04-17.64-8.96-26.49c-0.71-2.16-1.03-5.1-3.99-4.34c-2.83,0.73-1.4,3.36-1.06,5.35c0.73,4.15,1.25,8.34,2.04,12.49
				c1.47,7.75-0.7,11.53-8.53,13.95c-2.32,0.72-3.35,2.68-4.98,4.05c-2.33-1.75-2.41-4.64-3.43-7.03c-0.94-2.2-1.33-4.44-4.57-4
				c-3.51,0.47-2.28,3.07-2.48,5.03c-0.08,0.82-0.01,1.66-0.01,2.41c-6.42,1.04-9.04,5.99-11.44,11.1c-0.32,0.68-0.41,2.02,0.01,2.42
				c4.96,4.85,5.34,11.32,6.47,17.54c0.13,0.7,0.65,1.33,0.56,1.16c6.97,1.66,11.62-0.75,13.9-7.15"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M303.9,439.87c-7.3-1.14-13.71,3.25-20.98,3.23c-4.61-0.02-9.16,0.02-13.45-1.34c-5.1-1.62-10.36-1.89-15.51-2.93
				c-2.53-0.51-4.02,1.34-5.99,2.07c-7.27,2.65-14.91,3.03-22.48,4.03c-8.59,1.14-17.06-0.83-25.48-0.7
				c-9.91,0.15-19.69,0.46-29.5-1.23c-1.23-0.21-3.61,0.25-3.94,2.37c-1.47,9.47-8.4,15.17-14.5,21.5
				c-7.71,7.99-12.9,17.61-16.36,28.01c-2,6-5.47,11.5-6.13,17.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M880.3,1444.78c14.65,7.33,28.85,15.84,44.08,21.66c16.05,6.13,31.91,14.34,49.82,13.73c17.25-0.58,31.52,6.87,45.41,15.64
				c1.24,0.78,3.28,0.88,3.54,2.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M864.31,1177.94c3.12,8.47,0.99,16.62-0.85,25.02c-2.07,9.43-6.1,18.52-5.64,28.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M510.69,1374.32c0.19-5.57-2.67-9.98-7.49-11.49c-1.66-0.52-3.25,0.31-4.44,1.08c-2.95,1.88-4.06,1.12-5.14-2.05
				c-1.78-5.25-3.07-5.61-8.4-3.52c-2.01,0.78-4.41,1.26-5.92-0.09c-4.55-4.07-9.01-1.46-13.57-0.44c-1.13,0.25-2.23,0.34-3.51,0.2
				c-3.7-0.4-7.73-1.73-10.65,2.71c-0.96,1.45-3.68,3.15-6.31,1.62"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M355.85,774.68c-2.87,0.15-4.61-1.98-6.45-3.55c-8.54-7.27-16.93-14.72-25.41-22.13c-2.49,1.3-3.22,3.76-5.26,6.19
				c11.6,9.4,24.64,16.88,34.12,28.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1092.07,134.55c3.82,5.83,10.23,4.22,15.51,4.63c2.33,0.18,4.98,0.33,7.46,0.28c9.83-0.18,19.75,1.18,29.42-1.38
				c0.62-1.23-1.1-1.51-0.91-3.05c5.64-2.37,11.95-1.5,17.94-2.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M135.58,968.56c2.66,15.66,5.22,31.34,8.06,46.96c0.92,5.07,2.6,10.01,3.93,15"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M876.3,1134.46c-3.9,3.27-3.92,8.29-4.79,12.53c-1.92,9.35-4.51,18.55-6.57,27.86c-0.94,4.25-2.67,9.08-4.69,12.76
				c-1.53,2.78-5.7,4.9-7.51,8.77c-3.96,8.45-3.7,17.92-6.89,26.53c-0.47,1.28-0.69,2.66-1.02,3.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.72,1440.29c-0.75,5.28-1.37,10.53,1.42,15.53c0.4,0.72,0.08,2,1.08,2.44c9.94,4.37,15.82,14.4,25.95,18.53
				c6.18,2.52,8.68,7.72,9.5,13.97c0.35,2.66,0.67,5.33,1,7.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M633.06,1277.38c-2.59,5.9-4.87,11.75-4.64,18.49c0.16,4.81-1.71,9.56-1.92,14.48c-0.26,6.22,0.76,12.33,1.07,18.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M226.48,1498.75c0.11-4.28,0.33-8.58-3.04-12.04c-2.4,0.44-4.04,2.23-5.7,3.65c-4.06-1.81-6.68-7.79-12.38-2.76
				c-1.91,1.69-12.32-3.88-13.81-6.85c-1.31-2.62-2.93-4.96-5.04-6.97c-0.77-0.73-1.89-2.08-2.45-1.9c-5.3,1.7-9.95-1.92-15.02-1.61"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M455.74,681.73c2.43,11.2,1.9,22.66,4.04,35.38c-3.83-2.2-6.55-2.91-9.53-3.05c-2.74-0.13-4.82-1.58-5.19-5.32
				c-0.41-4.06-1.08-8.32-3.31-12.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M139.58,942.08c2.29,4.54,4.1,9.3,6.21,13.89c0.73,1.59-0.06,7.41,5.4,4.31c0.38-0.22,2.14,2.8,2.38,4.78
				c1.65,13.33,3.36,26.65,4.95,39.98c0.23,1.92,0.5,4.06-1.52,5.43c-0.6-0.6-1.27-1.27-1.94-1.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M149.07,1442.78c-7.46,0.71-14.71,0.22-21.49-3.47c-2.48-1.35-5.54-0.38-7.34,0.7c-2.65,1.58-3.17-0.74-4.97-1.34
				c-8.24,16.81-21.2,31.23-25.63,50.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M415.79,646.25c-1.98-0.54-3.39-1.62-4.57-3.45c-1.55-2.41-3.89-4.13-6.8-4.53c-2.52,5.19-4.47,10.62-7.6,15.48
				c-1.97,3.07-3.99,4.33-8.09,2.73c-3.15-1.22-6.9-0.55-9.5,3.19c-3.58,5.15-4.01,4.96-8.95,1.53c-7.06,3.89-6.45,9.77-5.15,16.57
				c0.85,4.45,0.84,9.38-0.61,14.87c-4.54-6.17-3.56-12.9-5.18-18.91c-0.6-2.25-0.27-4.92-2.5-6.48c-2.62-1.84-3.22-4.14-1.89-6.88
				c-1.72-2.6-4.09,0.16-5.52-0.75c-2.34-1.48-3.13-4.32-4.07-6.88c-0.98-2.67-1.39-6.71-5.43-5.31c-3.19,1.11-2.63,4.8-1.47,7.27
				c2.73,5.8-4.06,9.3-3.09,14.53c-6.2,5.8-5.14,13.15-4.55,20.49c0.3,3.83,1.37,7.4,2.56,10.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M633.06,461.86c5.71-3.14,9.94-8,14.49-12.49c5.02-4.95,7.1-5.08,13.52-1.56c1.95,1.07,4.42,2.22,6.44,1.91
				c5.96-0.92,8.95,2.28,12.1,6.58c5.97,8.15,13.64,15.18,15.05,26.03c0.15,1.13,1.16,3.63,3.84,3.06c2.42-0.51,3.98,1.38,4.91,3
				c6.45,11.26,15.33,19.76,27.01,25.52c4.65,2.29,6.34,7.91,10.53,10.91"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M211.5,967.56c-0.13,14.13,4.92,27.52,7.26,42.22c-6.33-3.37-8.67-8.38-11.75-12.74"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M135.58,928.59c2.53,7.12,2.21,14.62,3.1,21.97c1.36,11.31,0.87,22.81,3.4,33.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1024.65,39.61c-0.2-7.39-2.66-13.83-10.53-15.25c-4.97-0.9-9.9-1.17-14.5-3.63c-1.33-0.71-3.09,0.19-4.47,1.36
				c-2.55,2.16-5.09,1.68-8.01,0.13c-4.78-2.54-9.79-4.42-15.43-4.67c-10.12-0.46-15.06,1.85-18.02,14.22
				c-4.53-1.32-9.13-2.67-12.37,2.46c-8.71-4.93-17.58-8.09-27.03-2.56c-10.82,6.33-13.84,12.16-11.01,20.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.72,1241.4c-1.12-8.48-0.72-17.02-0.26-25.47c0.2-3.68,3.49-6.86,4.52-10.94c1.43-5.67,1.54-7.57-3.77-10.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M662.03,747.19c2.2,2.46,3.87,5.23,6.87,7.19c2.7,1.76,1.9,6.09,1.84,8.8c-0.21,10.74,2.55,21,3.78,31.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1013.16,976.56c-0.38,7.74-2.04,15.49,3.33,22.61c1.92,2.55-0.51,6.44,0.02,9.89c0.87,5.61-4.6,4.24-7.42,5.29
				c-1.94,0.72-4.04,1.17-6.77,2.75c6.35,4.4,12.22,8.75,19.82,9.9c1.54,0.23,3.2-0.25,4.49,1.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M730.45,1191.43c0.81,4.16,1.65,7.8-1.22,12.18c-2.86,4.38-2.39,10.39-2.26,15.81c0.12,4.89,6.44,9.29,9.76,6.26
				c3.2-2.92,5.82-1.08,9.03-1.89c0.85,8.23-2.3,15.52-1.98,23.12c0.13,3.2-0.5,6.45-3.63,9.23c-2.27,2.02-1.87,6.27-1.27,9.76
				c0.54,3.19-0.64,6.51,2.95,9.13c3.5,2.55,2.71,5.81-0.86,8.89c-3.2,2.77-5.03,7.14-9.06,8.86c-3.69,1.57-6.95,3.46-9.36,6.75
				c0.51,1.91,2.31,3.12,4.28,5.28c-1.74,1.35-3.25,2.53-4.91,3.82c3.96,5.07,0.3,9.9,0.55,14.73"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1161.5,944.08c-3.5,1.52-5.61,4.72-8.48,7.01c-3.37,2.69-6.97,4.78-10.85,6.77c-4.85,2.48-7.54,7.89-9.05,13.23
				c-1.23,4.34-1.86,8.89-1.09,13.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M225.49,1193.43c1.25,0.53,2.9,1.2,2.91,2.5c0.04,7.11,5.06,12.87,5.17,19.99c0.04,2.5,0.6,5.05,2.84,7.07
				c3,2.71,0.24,6-0.16,8.89c-0.19,1.35-1.24,3.22-0.16,3.85c0.99,0.58,2.85,0.49,4.42-0.26c3.42-1.64,5.93-4.44,9.16-6.62
				c5.54,3.27,0.48,10.34,5.35,13.94c-8.36,9.08-12.29,20.76-20.96,29.16c-1.43,1.38-2.55,2.86-4.55,3.46
				c-1.64,0.49-3.58,1.37-3.91,2.98c-1.44,7.09-7.33,10.27-12.08,14.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M314.89,1003.54c-0.8,15.65-0.68,31.31-0.5,46.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M480.22,1287.87c-0.59,5.23,1.09,9.33,5.44,12.56c2.94,2.19,4.71,4.76,2.24,9.03c-1.68,2.91,1.02,6.74-0.48,10.47
				c-1.17,2.91-1.83,5.38-5.17,6.49c-2.89,0.95-4.89,0.25-6.51-2.08c-1.81-2.61-4.78-1.53-6.87-2.33
				c-5.05-1.94-12.65,0.43-14.71-7.63c-0.56-2.19-3.3-1.98-4.86-1.42c-4.65,1.67-7.48-0.03-9.57-4.08c-0.34-0.67-1.29-1.02-1.95-1.52
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M320.89,1268.39c0.3,9.68,2.07,10.07,10.82,13.87c6.54,2.84,13.62,0.85,19.73,4.47c1.48,0.87,3.98,0.42,5.94-0.81
				c2.81-1.78,6.47-0.55,7.22,1.54c2.04,5.72,6.33,4.53,10.23,4.33c4.28-0.23,6.67-3.48,8.45-6.93c2.22-4.28,4.17-5,8.03-2.48
				c6.96,4.55,7.13,4.55,13.49,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1048.62,809.66c0.17-0.33,0.24-0.81,0.51-0.98c6.46-3.98,10.16-10.3,13.93-18.13c1.98,7.69-1.78,12.22-5.14,15.94
				c-4.82,5.36-6.26,11.68-7.77,18.16c-0.8,3.42,2.52,5.87,6.4,4.34c1.7-0.67,3.35-1.37,4.47-0.26c1.91,1.89-0.58,3.06-1.43,4.4
				c-6.65,10.45-8.56,22.7-12.96,33.99c-0.92,2.37-1.28,4.97-2.95,7.02c-9.92-3.18-14.62-12.04-21.03-19.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M79.64,1402.31c-2.41,7.07-1.73,14.53-0.64,21.43c1.04,6.59-1.02,12.4-1.64,18.52c-0.18,1.73,0.29,4.47-1.71,6.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M844.34,1357.33c2.01,5.95,1.72,11.84,0.61,18.01c-2.02,11.25,3.57,19.24,12.37,25.47c0.38,0.27,0.67,0.66,1,0.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M120.6,978.56c1.15,7.79,4.5,14.81,9.14,20.88c4.56,5.97,5.11,12.89,6.85,19.6c1.24,4.76-0.23,9.79,1.49,14.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M547.15,609.77c0.02,9.35-0.32,18.74,1.55,27.97c0.66,3.26,2.09,6.17,6.35,4.71c1.67-3.35-0.17-7.35,2.59-10.2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1005.17,854.63c-2,15.11-4.52,30.13-7.99,44.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M662.53,500.84c1.94,1.73,3.63,3.67,5.93,5.11c2.5,1.56,3.94,4.28,4.05,7.39c0.36,9.83,0.68,19.65,0.99,29.48
				c0.01,0.33-0.31,0.67-0.48,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M459.24,728.7c1.91,12.87,0.43,26.29,6.41,38.52c0.76,1.54,0.41,3.63,0.58,5.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M573.62,1279.38c4.29,8.55,10.33,15.85,17.58,21.87c6.82,5.66,10.3,12.73,11.89,21.1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M751.43,932.58c7.04-2.58,12.76-1.42,17.62,4.89c2.41,3.14,7.2,3.28,10.6,5.76c1.6,1.17,3.11,2.74,4.82,3.72
				c4.34,2.48,6.86,8.29,12.91,7.56c2.8-0.34,4.02,1.5,5.48,3.08c1.93,2.08,3.55,4.62,7.02,2.99c0.14-0.06,0.43-0.07,0.48,0
				c3.45,5.58,11.25,3.37,15.03,8.46c2.26,3.04,10.95,5.77,14.95,5.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M9.21,1456.28c0.25-3.97-2.11-7.32-3.74-10.36c-4.32-8.08-4.33-16.53-4.21-25.11c0.07-5.4,6.34-9.19,10.43-6.46
				c0.65,0.44,1.27,1.23,1.48,1.98c2.66,9.44,6.22,18.5,12.06,26.44c5.3,7.2,5.71,15.81,7.3,24.04c0.3,1.55,3.08,3.04-0.04,5.01
				c-9.66-3.67-15.12-12.18-20.29-21.02c-3.01-5.15-4.36-11.27-8.97-15.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M44.18,426.38c-2.26-3.47-5.92-1.98-8.99-2.48c-10.94-1.76-22.01,0.42-32.96-1.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M427.77,805.16c-4.83-0.5-7.14-5.57-11.08-6.64c-3.01-0.83-6.77,0.95-10.42-0.27c-7.93-2.65-7.98-2.47-11.99,5.91
				c-0.29,0.6-0.97,1.01-1.63,1.67c-2.3-0.98-4.35-2.04-7.43-0.89c-3.28,1.23-6.23-2.56-9.89-2.86c-6.17-0.5-9.53,1.55-11.54,7.58
				c-1.27,3.81-1.2,8.01-3.45,11.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M830.85,1184.94c-0.33,1.33-1.15,2.76-0.92,3.98c2.35,12.59-2.28,24.96-1.07,37.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M963.71,827.15c10.71,10.4,10.62,18.63-0.53,28.95c-3.93,3.64-8.19,6.76-9.59,12.49c-0.72,2.97-2.04,2.77-6.88-0.48
				c-2.94,3.09-5.66,6.12-10.56,2.64c-1.96-1.39-10.44,5.28-11.89,8.86c-2.37,5.83-3.71,5.99-8.54,0.95
				c-2.66,1.25-3.69,3.98-5.42,6.17c-3.83-0.91-3.6-5.15-6.25-7.31c-2.76,1.67-5.05,6.51-9.03,1.98c-6.1,5.37-6.35,5.45-11.46,3.62
				c-2.16,0.5-3.9,2.45-4.61,4.14c-3.02,7.17-9.97,5.25-15.19,6.72c-4.64,1.3-6.06-3.54-7.99-7.43c-4.46,5.57-6.15,0.86-8.43-1.85"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1140.52,765.68c-2.66,12.49-5.36,24.98-7.95,37.49c-0.25,1.22-0.51,2.26-1.54,2.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M955.22,867.62c1.82-0.42,3.38,0.43,5.22,1.29c6.69-6.76,11.56-15.02,18.67-21.38c3.7-3.31,0.93-13.22-3.32-16.98
				c-5.48-4.84-12.21-4.49-18.58-5.29c-8.26-1.04-16.08-2.58-21.96-9.11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M379.82,437.37c0.43,13.16,2.65,26.29,0.47,39.47c-0.09,0.54-0.63,1-0.97,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M73.65,1345.34c-0.33,12.27,1.29,24.27,5.27,35.88c0.04,0.13-1.13,7.39,3.72,2.59"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1133.53,765.68c-1.67,7.83-3.74,15.6-4.85,23.51c-0.77,5.48,0.68,11.03,1.82,16.48c0.41,1.96,1.13,3.99,0.03,5.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M310.9,523.32c0.45,5.85-1.1,11.49-2.64,16.95c-2.19,7.76-2.56,15.83-4.85,23.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M933.74,771.68c-2.39-2.55-4.31-4.86-8.65-2.27c-2.09,1.25-5.89,0.74-8.91-1.11c-2.94-1.79-6.44-1.88-8.89,1.39
				c-1.59,2.12-3.08,1.3-5.05,0.56c-4.66-1.75-9.44-3.41-14.46-1.08c-1.58,0.73-3.2-0.45-3.89-1.11c-3.64-3.5-7.5-4.56-11.53-1.31
				c-2.83,2.28-4.29-0.36-6.01-1.17c-7.23-3.41-8.25-3.43-14.83,2.29c-1.2-1.52-2.46-3.1-3.69-4.7c-4.49-5.84-7.31-6.68-14.5-4.53
				c-1.6,0.48-2.93,1.39-5.07,0.17c-4.63-2.63-5.32-2.27-10.35,0.82c-6.55-3.61-13-4.11-19.58-0.03c-1.21,0.75-2.96,1.05-4.45,0.07"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M24.7,478.85c-2.36,10.19-8.09,19.34-9.04,29.98c-0.43,4.83-1.38,9.62-2.06,14.25c3.28,1.01,4.33-2.09,6.56-2.35
				c0.36,1.93,0.71,3.76,1.05,5.59"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M82.64,1415.3c-1.68,3-0.36,6.26,0.23,8.94c1.68,7.59,0.18,14.53-2.21,21.54c-1.54,4.53-0.56,8.86,0.25,13.53
				c2.03,11.68,3.3,23.52,3.23,35.44c-0.01,1.33,0,2.67,0,4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M83.63,1415.3c2.75,3.6,5.97,5.54,10.5,3.03c0.38-0.21,1.02-0.16,1.48-0.01c8.7,2.81,17.79-0.85,26.45,1.57
				c2.25,0.63,4.03-1.25,6.63,0.22c4.86,2.74,6.47,8.95,2.42,12.72c-1.73,1.6-3.77,3.03-4.52,5.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M655.03,826.65c-4.31,5.97-3.64,13.68-7.26,20.36c8.31,5.92,2.98,12.56,0.57,19.15c-2.66,0.42-3.05-3.02-5.3-3.53
				c-1.74,1.64-2.16,3.63-2.05,6c0.17,3.71,0.86,7.58-3.9,9.59c-2.74,1.15-1.47,4.81-3.25,7.62c-7.01-7.17-13.38-2.98-19.16,1.42
				c-3.08,2.34-3.19,6.52-2.11,10.35"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M501.2,1249.9c9.96,5.92,16.57,14.43,21.71,24.87c4.66,9.47,12.76,16.61,20.68,23.67c5.57,4.97,11.07,10.2,13.15,17.89
				c0.21,0.79,0.96,1.49,0.92,2.53c-0.63,15.66,1.5,31.42-1.96,46.98c-0.95,4.26-2.69,8.12-4.55,11.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M52.67,1353.59c2.95,1.92,3.38,2.95,1.98,5.24c-1.44,2.37-2.84,4.78-4.52,6.98c-2.23,2.92-2.4,6.02-2.03,9.53
				c0.63,6.05,2.97,7.27,8.6,4.57c3.13-1.5,6.75-2.98,9.92-0.04c3.27,3.05,1.75,7.22,1.99,10.96c0.02,0.31-0.6,0.93-0.96,0.96
				c-7.54,0.59-3.29,3.35-1.69,6.63c1.54,3.16,6.47,5.59,3.33,10.49c-0.34,0.52,1.13,2.29,1.87,3.4c4.2,6.33,3.23,8.52-4.01,9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M208.5,445.87c-4.39,7.37-8.33,15.05-12.15,22.66c-2.95,5.88-6.98,10.97-10.1,16.67c-4.53,8.28-8.12,16.81-11.22,25.63"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M631.06,673.74c-1.82,2.32-1.08,4.39,0.49,6.51c5.69,7.69,3.82,16.36,0.1,23.25c-3.33,6.16-2.55,11.9-2.27,17.7
				c0.16,3.33,0.53,6.65,0.19,10.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M242.97,999.54c-0.18,14.17,3.62,27.92,4.5,41.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M123.09,1440.29c0.32,1.32-0.75,1.99-1.52,2.46c-9.93,5.97-11.9,17.41-17.92,26.05c-0.2,0.29,0.19,0.99,0.45,2.15
				c4.52-1.91,10.4-0.46,12.05-7.17c0.48-1.94,2.8-2.07,3.89-1.42c12.31,7.37,21.8,0.31,30.29-6.86c4.6-3.89,10.35-4.47,14.86-8.03
				c1.61-1.27,4.31,0.89,6.12,2.45c-0.96,3.22-4.5,4.18-4.83,7.35c-0.14,1.31-1.65,1.36-2.41,0.95c-4.2-2.25-4.39-0.2-5.2,3.52
				c-2.21,10.18-13.4,12.57-20.3,4.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1154.51,1498.75c0-10.33-4.72-14.11-13.95-10.88c-1.13,0.39-1.9,0.21-2.5-0.17c-2.9-1.81-5.69-0.82-8.64,0.28
				c-2.56-4.88-5.48-8.99-11.88-8.31c-2.04,0.22-3.63-1.69-4.91-2.99c-3.67-3.74-7.91-3.98-12.56-2.92c-4.98-6.09-9-6.63-15.2-1.83
				c-3.21-1.93-6.77-3.97-9.71,0.37c-1.25,1.85-2.58,0.71-3.52,0.36c-2.07-0.77-3.69-0.21-4.99,1.13c-1.84,1.89-4.4,2.17-6.37,3.26
				c-4.48,2.46-8.64,3.2-13.66,2.94c-3.43-0.18-3.98,3.79-4.48,6.76"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M901.77,51.1c-4.24-1.13-7.54-1.21-11.37,2.62c-1.93,1.93-6.59,3.51-10.59,3.99c-7.53,0.9-12.93,7.29-12,13.38"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M522.67,1275.38c-10.49,6.79-22.01,10.38-34.47,10.97c-1.15,0.05-2.14,0.27-2.98,1.04c-0.08,0.08-0.07,0.36,0,0.48
				c0.12,0.2,0.32,0.34,0.49,0.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M381.32,1158.95c-3.15,3.65-2.14,8.37-2.79,12.52c-1.07,6.79-3.05,12.53-9.73,15.37c-1.88,0.8-3.35,2.04-4.92,3.14
				c-5.96,4.19-10.21,3.34-14.02-3.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M159.06,1068.5c0.17-0.5,0.3-1.42,0.5-1.43c1.94-0.15,2.3,0.91,1.48,2.43c-2.45,4.54-1.09,7.6,3.5,9.53
				c-2.37,5.86-2.37,5.86,4.18,10.59c-1.79,4.17-7.26,5.53-8.16,10.37c5.03,4.47,5.29,7.8,1,12.49c1.33,1.33,2.66,2.67,4.05,4.06
				c-1.7,1.47-3.39,2.94-5.09,4.4c-0.02,3.08,4.58,5.6,0.47,9.75c5.32-3.59,10.49,2.09,15.75-1.94c2.69-2.06,5.5,2.64,8.64,4.18
				c2.77-4.12,8.39-3.37,12.2-6.36c1.78-1.39,3.98-0.95,5.98,0.84c2.58,2.31,5.84,2.35,8.35-0.02c1.65-1.56,3.31-1.55,5.07-1.37
				c5.25,0.54,10.36,0.08,14.3,5.74c2.71,3.9,7.93,6.52,13.83,2.97c1.3-0.78,4.71,1.04,6.93,2.62c1.54,1.09,3.25,2.15,4.95,3.03
				c2.02,1.04,4.41,1.4,3.44-2.4c-0.37-1.46,1.33-1.84,1.78-3.08c-4.89-4.13-11.18-7-11.76-14.41"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M115.1,1168.44c-3-0.39-6.14-0.2-8.06-3.46c-0.98-1.66-2.89-2.03-4.93-2.11c-3.06-0.11-5.74-1.25-6.99-4.43
				c3.55-3.21,3.88-9,8.96-11.07c0.99-0.4,0.81-10.31,0.03-10.92c-6.9-5.35-6.9-5.35,0.14-10.09c10.7,6.59,22.67,10.74,33.84,16.56
				c2.23,1.16,4.11,1.02,5.06-1.95c0.37-1.15,2.02-3.02,2.36-2.88c7.08,3.1,8.29-4.66,12.54-6.66c0.87-0.41,1.67-0.99,2.5-1.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M583.61,435.88c4.11,6.12,6.14,13.12,8.5,19.98c2.2,6.41,6.41,8.87,13.89,7.95c-1.87-6.21-2.28-12.63-3.41-18.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M628.06,522.32c-0.99,3.72,1.45,6.27,3.58,8.41c5.75,5.79,6.59,13.08,7.48,20.56c0.5,4.18,0.34,8.48,1.93,12.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M563.63,453.86c2.82-5.59,5.64-11.18,8.73-17.33c5.16,5.73,4.07,13.87,9.88,18.54c1.61-3.74-0.43-7.12-1.38-10.12
				c-1.18-3.69-0.51-5.35,2.75-6.58"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M653.04,552.81c-3.73-5.7-5.01-12.47-8-18.48c-4.98-10.01-11.46-18.64-21.96-23.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M525.67,677.73c-9.26,3.09-9.68,11.31-10.97,18.99c-0.84,5,0.09,10.29-2.51,14.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M653.04,446.37c3.25,4.47,2.48,9.76,1.74,14.45c-0.65,4.16-0.27,8.48-1.72,12.54c-1.14,3.19,2.17,6.22,0.48,9.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M450.25,436.87c-0.67,6.66-1.32,13.33-2,19.99c-0.23,2.3-2.1,3.49-3.49,5c-3.77,4.1-7.13,8.43-8.49,13.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M661.03,744.69c-2.14,12.81,0.97,25.34,2,37.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M604.09,446.87c11.16,9.24,16.75,20.75,14.48,35.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M613.08,689.23c-1.17-0.93-1.78-2.61-4.04-1.6c-2.12,0.96-1.86,2.43-1.97,4.09c-0.27,4-0.62,8-0.98,11.99
				c-0.75,8.33,4.15,16.03,11.98,19.03c2.02,0.77,4.07,1.68,5.51,3.46c2.37,2.94,4.49,6.25,8.99,6.02c0.32-0.02,0.89,0.24,0.96,0.49
				c1.83,6.29,7.01,11.09,8.76,17.06c3.55,12.07,5.57,24.58,8.3,36.89c0.22,1,1.12,1.84,1.7,2.76"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1085.08,753.19c0.45,5.12,3,7.84,7.99,8.49c5.39,0.7,10.57,2.06,16.12-0.72c2.33-1.17,6.17,0.95,9.36,1.16
				c3.41,0.22,6.57,0.43,6.9,5.43c4.57-3.8,9.27-3.29,13.56-2.73c7.51,0.99,14.99,0.73,22.5,0.85"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M781.9,1397.31c1.29,3.84,5.89,5.96,5.18,11.02c-0.24,1.72,2.24,3.02,4.31,3.48c5.49,1.2,9.7,5.47,14.61,7.12
				c8.16,2.75,13.77,10.15,22.83,11.11c2.77,0.29,5.28,3.47,8.51,4.27c2.95,0.73,5.76,1.27,8-1.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M354.85,701.22c-1.04-6.79-0.25-13.69-1.35-20.51c-0.3-1.86-0.86-3.64-1.24-5.45c-0.38-1.78-1.42-3.43-3.91-3.05
				c-2.52,0.39-2.26,2.2-2.54,4.02c-0.63,4.11,1.27,7.71,2.16,11.47c0.75,3.13,0.4,5.2-2.67,6.57c-0.82-3.88-1.88-7.71,0.06-11.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M261.45,1309.36c2.83,1.58,4.58,4.42,7.44,6.08c4.29,2.49,4.72,9.26-0.52,16.35c-2.93,3.96-6.59,7.38-9.92,11.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M196.02,1154.45c-2.46-0.09-4.7,0.81-6.79,1.78c-2.87-1.46-3.45-5.58-7.7-5.23c-2.43,4.61-5.73,5.21-10.62,2.19
				c-1.96-1.21-5.52-1.66-8.35-0.23"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1023.15,961.57c-0.55,5.02-2.19,9.63,0.14,15.14c1.91,4.54,3.94,7.55,8.33,9.39c3.45,1.44,6.75,3.26,9.13,6.14
				c-1.44,3.86-3.87,1.41-5.02,0.67c-2.59-1.66-4.86-2.66-8.11-1.99c-2.99,0.62-5.38-0.62-7.11-3.8c-1.81-3.32-3.38-6.96-6.35-9.57"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M415.29,682.73c-2.42,1.33-4.85,2.57-8.4-0.78c-0.48,8.45,3.26,17.08-5.13,22.3c-2.2,0.1-2.51-2.69-4.6-3.11
				c-2.81,1.51-2.49,4.58-3.34,7.07"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M361.34,1192.93c2.85,8.45,4.13,17.41,9.41,25.04c4.31,6.22,9.43,11.91,12.6,18.92c3.28,7.26,7.85,13.8,10.73,21.4
				c2.9,7.66,7.36,15.04,15.71,18.59"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M365.84,1127.47c-2.82-2.89-5.73-4.59-10.05-2.63c-1.88,0.85-4,0.19-5.56-2.29c-2.48-3.93-5.82-7.24-10.35-9.1
				c-5.75-2.37-10.92-1.46-15.47,2.56c-4.92,4.34-7.75,10.15-10.51,15.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M863.81,1389.32c0.48,4.74-1.5,8.65-3.96,12.51c-3.45,5.42,2.08,16.99,8.46,17.92c0.28,0.04,0.66-0.62,0.99-0.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M689,951.07c-0.67-4.16-1.03-8.41-2.1-12.47c-0.73-2.77-2.35-5.26-5.88-5.58c-1.53-0.14-3.19-0.65-4.46-1.49
				c-3.41-2.25-6.44-2.31-8.95,1.11c-0.45,0.61-0.87,0.47-1.05,0.39c-0.4-0.17-0.69-0.62-1.02-0.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M826.85,1183.44c-2.77,6.28-2.21,12.97-1.86,19.48c0.26,4.84,0.21,9.67,0.36,14.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M91.63,1091.49c-4.62-5.57-12.07-8.8-13.58-16.97c-0.35-1.89-0.19-4.31-2.54-5.41c-8.14,2.79-4.62,13.78-11.76,18.28
				c-3.12-4.75-7.23-7.84-8.58-12.89"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M128.09,1371.83c-1.6-4-1.54-8.72-6.42-11.13c-2.93-1.45-5.66-0.57-6.34,1.22c-1.78,4.68-5.48,4.53-8.65,3.71
				c-2.88-0.74-7.44,1.38-8.39-4.74c-0.49-3.19-5.95-4.42-7.65-8.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M609.58,904.6c1.92-3.19,1.39-8.94,8.02-7.08c2.64,0.74,3.05,3.01,4.43,4.58c3.27,0.24,4.37-3.26,6.7-4.27
				c4.65,3.85,4.64,4.69,3.91,9.78c-1.14,7.93-3.28,15.84-1.58,23.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M89.63,1351.59c-2.61,0.5-2.22,2.64-1.94,4.24c1.39,7.94-0.99,15.48-2.79,22.94c-0.79,3.29-0.22,4.65,2.23,6.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M216.49,1219.41c-0.04-5.13,4.21-8.51,5.2-13.45c1.85-9.26,6.36-17.72,0.77-27.79c-2.1-3.78-3.91-8.95-4.88-13.75
				c-0.53-2.59-1.63-3.84-4.08-3.42c-2.65,0.45-3.62-0.32-3.57-3.05c0.03-1.83-0.07-4.05-1.06-5.4c-1.22-1.65-2.42-3.83-5.36-3.67
				c-4.1,0.22-5.38,3.72-6.81,6.18c-2.59,4.46-7.08,7.01-10.14,11.03c-1.43,1.88-8.2,1.76-12-1.16c-4.41-3.39-8.68-6.97-13.01-10.47"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M88.63,1351.34c-1.29-4.88-4.87-6.54-9.47-7.6c-6.94-1.6-14.3-0.47-21-3.4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M290.42,1074c-1.51,4.37-6.21,8.27-2,13.49c-4.41,2.84-1.25,7.73-2.9,11.74c-2.09,5.07-3.81,10.54-5.9,15.82
				c-1.22,3.08,2.85,3.54,4.84,5.1c0.09,2.08-2.69,2.38-3.14,4.63c1.4,2.48,5.29,0.44,6.73,3.48c-2.39,2.23-3.97,4.87-1.59,7.8
				c-0.33,3.28-8.41,4.77-1.54,8.9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M61.66,1086.99c-2.24,2.06-3.75,5.09-7.49,5.05c-1.71-0.02-2.45,1.9-2.39,2.94c0.36,6.24-4.06,8.1-8.63,9.42
				c-5.08,1.46-4.22,4.59-2.97,8.08c0.93,2.6,4.37,4.51,3.32,7.44c-0.95,2.65-4.46,2.83-6.73,4.21c-4.11,2.51-7.21,6.54-7.93,11.36
				c-0.57,3.83-2.76,3-4.62,2.37c-2.26-0.76-4.32-1.87-6,0.62c-1.64,2.43-2.55,5.32-0.94,7.92c1.62,2.63,1.73,4.68,0.56,7.61
				c-1.46,3.66,1.56,6.5,3.71,9.43c-3.29,4.57-6.08,8.76-12.48,4.19c-1.46-1.05-4.53,0.15-6.87,0.32"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M553.64,1284.38c2.66,4.16,4.96,8.63,8.07,12.42c5.06,6.18,7.09,12.79,4.91,20.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M811.37,1358.33c-3.81-2.62-4.56,0.43-6.05,2.96c-3.72,6.29-2.63,13.3-3.42,20.03c-0.24,1.99-0.34,4-0.51,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M338.87,1141.96c-1.3,4.59-2.28,8.35,2.06,12.91c2.14,2.25,2.7,8.19,3.4,12.58c0.52,3.3,0.98,6.66,0.98,9.99
				c0,7.89,5.32,11.6,12.54,8.48c1.71-0.74,3.34-1.65,5-2.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M656.03,569.3c-3.19,6.92-7.19,13.4-10.89,20.04c-0.72,1.3-1.49,2.75-0.35,5.07c1.69,3.43-1.65,6.97-1.99,10.85
				c-1.04,11.9-3.74,16.05-12.74,24.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M607.58,1325.85c15.02,1.94,29.47,6.26,43.97,10.43c5.37,1.55,10.79,2.9,15.97,5.06"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M51.67,1437.79c5.66,10.49,11.32,20.99,16.98,31.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M2.22,1350.34c4.87,1.66,9.57,3.48,11.53,8.98c0.75,2.1,2.22,2.35,4.49,1.61c4.47-1.48,8.84-2.46,11.13,3.84
				c0.86,2.36,3.77,3.46,6.83,3.14c2.15-0.23,4.53-0.78,7.48,0.96c0-9.51,0-19.01,0-28.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M702.48,620.77c-3.69,1.66-5.44-0.52-6.46-3.51c-2.33-6.85-8.43-11.49-10.65-18.44c-0.33-1.03-1.48-2.73-3.37-2.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M110.11,1473.77c-7.43,1.23-12.03,4.37-13.48,12.91c-0.66,3.92-1.36,8.04-2,12.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M578.11,1322.35c0.5,0.33,1,0.95,1.5,0.96c10.84,0.09,11.93,8.56,12.81,16.05c1.25,10.59,2.97,21.21,2.16,31.96
				c-0.31,4.15-0.41,8.39-2.98,11.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M191.52,659.74c-3.63,8.36-4.49,17.58-7.98,25.99c-0.58,1.39-0.16,3.25-2.01,3.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M79.14,1455.28c-2.01,0.57-2.74,2.52-2.89,4.01c-0.77,7.64-3.58,14.9-1.93,23.04c1.1,5.44,0.91,11.18,3.32,16.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M339.37,1135.96c-4.42-0.59-8.8-1.25-13.02-2.92c-2.08-0.82-5.15-0.78-4.84,2.41c0.79,8.08-4.15,14.33-6.12,21.5
				c-0.67,2.43-1.82,4.56-4.52,6.87c-1.32-11.25,0.08-21.37,3.34-31.49c-3.37-1.3-5.08,2.31-7.82,2.13c0.13,3.13,2.79,4.49,4.52,6.5"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M222.99,454.86c-0.58-2.51-1.36-2.85-4.07-1.67c-4.44,1.95-7.42,5.15-10.46,8.63c-3.53,4.05-6.87,8.46-12.44,10.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M272.94,415.39c-5.31,2.83-11.52,1.55-17.03,3.88c-3.74,1.59-7.94,2.11-11.94,3.11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M91.13,967.06c1.56-0.89,1.3-2.68,2.51-4.96c2.31,2,4.4,5.73,7.93,3.87c2.33-1.22,1.42-5.04,4.04-6.47
				c7.37,6.96,2.91,19.19,11.05,25.6c2.05-0.11,1.79-2.03,2.94-2.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M996.18,898.6c-5.89-1.84-12.66,2.5-14.44,9.51c-0.78,3.09-1.08,6.31-1.51,9.49c-0.06,0.47,0.15,1.27,0.49,1.45
				c6.08,3.37,2.28,8.64,2.35,13.03c0.04,2.54-0.84,5.01,0.58,7.52c1.21,2.14,2.04-0.03,3.04-0.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M88.63,445.87c-4.71-2.1-9.59-1.64-14.48-1c-9.67,1.27-19.65,0.71-28.94,4.09c-7.58,2.76-14.4,1.99-21.57-1.48
				c-6.79-3.28-14.23-1.44-21.42-1.11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M111.11,1470.27c-0.21,7.49,1.03,8.54,8.49,6.98c3.68-0.77,6.27,1.1,7.39,4.05c2.62,6.92,8.1,9.65,14.56,11.55
				c1.07,0.31,2.09,0.48,3.02,0.34c6.25-0.94,6.84-0.6,8.5,5.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M215,1446.28c-1.54-3.48-0.3-8.15-4.69-10.4c-5,2.26-10.3,2.43-15.79,0.94c-6.03-1.63-11.68-3.56-13.48-10.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M87.63,1080c2.45,1.5,2.53,4.28,2.88,6.52c1.03,6.58,5.62,11.63,7.17,17.96c0.71,2.88,4.26,2.59,5.98,4.56
				c0.12,4.86-4.6,4.07-7.73,6.3c3.85,2.87,9.07,4.67,8.19,10.64"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M437.76,1284.88c4.58-4.64,10.07-2.12,14.96-1.35c10.62,1.68,21.24,3.07,31.99,3.35"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M79.64,424.38c-3.41-5.79-9.34-6.24-15.01-1.54c-9.99,8.27-20.88,13.83-34.43,10.04c-0.15-0.04-0.34-0.04-0.5,0
				c-9.25,2.28-18.32-0.46-27.47-1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M664.52,474.85c1.84,2.93,4.02,5.16,7.43,6.63c5.57,2.39,9.81,6.81,12.9,11.94c1.25,2.08,2.66,3.4,4.73,4.46
				c2.79-3.66,4.26-8.03,6.4-12.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M263.45,1193.43c-2.13-1.87-4.27-3.74-6.61-5.79c-3.88,2.48-7.84,4.79-11.93,0.35c-0.38-0.42-1.76-0.35-2.41-0.01
				c-5.21,2.74-10.94,2.38-16.51,2.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M521.67,604.78c3.61-1.45,4.38-5.07,5.96-8.01c1.68-3.13,3.2-6.31,7.52-5.97c2.43,0.19,2.58-3.26,6.1-2.92
				c4.8,4.79,9.41,10.48,10.35,18.41c0.12,1,0.42,1.69,0.98,2.53c3.42,5.15,3.7,10.97,3.61,16.96c-0.1,6.73,6.24,14.39,11.92,14.99"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M23.2,1067c1.77,2.53,3.17,5.37,6.1,7.25c1.89-1.36,3.79-1.74,5.91-0.3c2.04,1.38,4.3,1.79,6.47,0.04
				c1.66-1.34,3.1,0.07,4.47,0.57c2.26,0.83,3.21,0.37,3.02-2.07"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M602.09,759.69c5.5,1.87,7.94,6.01,9.07,11.48c0.77,3.73,2.39,7.13,8.13,5.58c4.63,8.52,4.32,19.02,8.78,27.91"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M746.44,591.78c-1.81,6.54,0.75,11.91,4.49,16.99c2.56,3.48,6.02,6.45,5.57,11.5c-0.17,1.88,1.71,1.43,2.92,1.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M244.47,1007.04c3.77,4.86,3.93,11.15,6.49,16.49c1.58,3.3,1.33,7.42,4.5,9.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M556.64,1352.84c-5.14-0.75-10.32-1.36-14.53-4.94c-0.48-0.41-1.78-0.36-2.42-0.01c-3.96,2.14-7.53,2.84-10.02-2.05
				c-4.79,2.32-8.45-2.32-12.99-2.08c-4.17,0.22-4.77,3.95-6.99,6.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M876.3,1392.31c-2.82,5.52-8.22,9.83-7.56,17c0.28,3.06,2.26,5.79,0.37,9.73c6.04-0.85,10.86-0.99,15.69-0.31
				c2.27,0.32,3.85,0.96,5.02,3.05c2.96,5.25,6.71,6.56,12.44,4.49c1.5-0.54,2.67-0.7,4.04,0.48c1.91,1.64,4.23,1.12,6.45,0.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M47.17,1419.3c-3.66-1.19-6.8-2.88-8.06-6.98c-0.4-1.3-1.48-2.47-2.46-3.49c-1.73-1.81-3.68-4.45-6.39-2.42
				c-2.79,2.09-6.21,4.37-5.06,8.88c0.56,2.19,1.31,4.34,1.98,6.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M69.65,1421.8c1.72,8.94,1.36,18.18,4,26.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M272.44,725.71c1.52-1.55,0.89-4.27,3.08-5.47c3.24,0.98,5.62,4.65,9.94,3.08c1.84-0.67,3.01,1.68,3.86,2.95
				c1.74,2.62,2.91,5.43,6.04,7.03c2.63,1.34,0.18,3.99,0.25,5.91c0.29,7.68-3.11,14.4-7.7,20.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M252.46,1268.39c1.84,1.54,3.39-0.29,4.97-0.63c2.04-0.44,3.95-2.56,5.99-0.34c1.74,1.9,1.16,3.94,0.83,6.5
				c-0.71,5.52-3.34,10.45-3.86,15.95c-0.18,1.9,0.1,3.28,0.96,5.06c4.42,9.15-4.09,25.15-13.92,26.65c-4.8,0.74-6.43,4.58-9.95,6.27
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M299.41,840.64c4.13-4.99,0.35-10.72,1.07-15.95c3.45-1.3,6.15,0.97,5.18,2.83c-3.43,6.55,1.66,11.75,1.84,17.98
				c3.27-5.68,10.63-3.76,14.37-8.33c2.96,1.38,3.93,4.21,4.4,6.99c0.36,2.13,0.87,4.15,3.09,3.84c2.64-0.36,6.06-0.63,6.88-3.9
				c0.46-1.86,2.45-3.27,1.09-5.06c2.68-2.18,1.97,1.74,3.95,0.69c1.96-1.78,3.43-3.72,2.3-7.65c-1.61-5.59,1.01-10.78,4.29-15.43
				c2.04-1.41,2.26,2.44,3.97,1.45c1.3-0.76,2.36-1.95,3.52-2.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M177.54,1425.04c2.33,0.25,5.02-0.15,6.91,0.9c2.76,1.54,4.4,4.29,4.65,7.84c0.38,5.24,4.23,8.31,9.41,7.43
				c5.29-0.9,10.27-0.66,15,2.06"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M747.44,592.78c2.05,0.18,3.13,1.78,4.5,2.99c4.73,4.16,10.82,3.87,16.48,4.99c0.48,0.1,1.33,0.2,1.45-0.01
				c2.95-5.44,6.32-1.31,9.54-0.48c0.82,0.21,1.72,0.62,2.49,0.46c6.99-1.43,13.01,2.29,19.49,3.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M239.97,1137.96c2.47,1.75,3.45,4.58,4.9,7.2c4.05-2.06,5.94-0.12,9.42,2.31c6,4.19,7.23,11.11,12.16,15.45
				c-1.27,4.63-4.51,9.4,1.67,13.53c-6.9,4.6,2.26,12.39-4.4,17.14c3.6,2.8,6.47,0.5,9.19-1.22c2.67-1.69,3.7-4.76,4.98-7.47
				c1.67-3.54,3.89-6.64,6.94-9.73c-4.92-2.9-7.8-6.05-2.57-11.4c1.35-1.39-2.44-4.67-0.32-7.32"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M371.83,1196.43c-0.93,6.25,1.37,11.93,3.65,17.43c1.17,2.82,0.88,6.07,2.84,8.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M88.13,1493.25c0.33-8.16-1.63-16.04-3.05-23.98c-0.07-0.41-0.94-0.68-1.44-1.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M613.58,1401.31c-5.37-3.79-7.43-3.2-11.56,1.94c-3.08,3.83,2.39,4.45,2.02,7.61c-1.53,1.37-3.38,3.01-5.52,4.92
				c2.92,2.77,8.62,4.45,3.19,9.42c2.97,2.52,8.05,0.65,9.3,5.12c0.45,1.6,2,2.35,1.5,5.05c-0.77,4.15,4.23,3.22,7.84,3.48
				c-1.11,4.36,0.17,6.89,4.16,8.6c3.14,1.34,3.26-1.85,5.06-2.16"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M81.14,1385.82c-1.42,7.53,0.62,14.99,0.48,22.49c-0.02,1.16,0.27,2.14,1.02,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M88.63,962.07c3,10.16,5.99,20.32,8.99,30.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M23.2,1335.85c-3.01-1.61-4.56,0.19-6.01,2.49c-4.16,6.64-4.17,6.62-9.48,1.01c-1.57-1.66-3.23-3.03-5.49-1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M211.5,897.1c3.51,8.17,4.66,16.67,3.5,25.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M543.65,428.88c1.83,8,3.66,15.99,5.49,23.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M624.57,517.83c-6.66-2.18-9.51-7.91-12.42-13.52c-0.31-0.59,0.01-1.94,0.51-2.38c0.47-0.41,1.72-0.36,2.4-0.04
				c3.52,1.65,6.76,5.59,7.07,8.01c0.94,7.43,6.06,11.35,10.5,15.87c4.92,4.99,9.58,10.04,12.48,16.53c0.35,0.78,1.11,1.84,2.44,1.53
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M160.05,1032.03c-1.16,0.19-2.32,0.37-3.49,0.56c0.34,3.94,4.61,1.21,6.04,3.52c-0.78,5.62-4.52,9.04-9.03,11.93
				c-1.99,1.28-4.44,2.04-5.51,4.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M17.7,1022.03c3-1.83,6.2-3.41,8.91-5.59c1.64-1.32,3.02-3.37,2.47-6.27c-2.53-0.71-4.87-2.59-7.89-2.63"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M433.77,672.74c2.5,7.66,4.99,15.32,7.49,22.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M800.88,1205.42c-3.37-5.71-2.21-12.49-4.4-18.52c-0.92-2.55-1.08-5.28-3.02-7.53c-0.95-1.11-1.76-3.08-1.47-4.41
				c2.08-9.43-4.85-16.75-5.82-25.49c-0.26-2.32-2.73-4.9-4.85-6.42c-7.61-5.46-14.59-12-23.43-15.53c-5.46-2.18-7.91,0.58-7.47,7.93
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M54.17,1353.34c2.34-4.02,1.4-8.84,3.31-13.08c0.85-1.89-0.68-4.62-2.84-5.86c-2.24-1.28-4.2-0.64-6,1.41
				c-3.96,4.48-6.9,4.69-11.92,1c-1.53-1.12-2.77-2.54-4.48-3.54c-3.97-2.31-8.17-0.47-8.52,4.07c-0.55,7.32-1.45,14.62-1.01,21.99"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M124.09,1479.76c-9.23,3.42-14.71,10.6-18.98,18.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M81.64,984.05c3.21,4.87,2.91,10.98,5.68,16.16c0.95,1.77-0.87,6.26-5.18,6.33"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.72,393.9c-0.97,9.33-0.61,18.66,0,27.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M433.27,675.73c-1.95,6.18-2.47,12.66-2.77,19c-0.2,4.2-3.87,3.37-5.22,5.49c-1.83-2.02-6.7-0.8-5.97-5.49
				c0.51-3.3-0.46-6.31-2.12-8.94c-1.09-1.73-1.87-3.13-0.9-5.06"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M401.8,1130.47c-4.38-6.18-16.5-5.55-19.6-1.09c-1.26,1.81,0.66,5.13-2.87,5.58"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M431.27,435.88c-2.09,9.66-0.68,19.32,0,28.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M715.47,1373.82c0.83-0.01,1.44-0.97,2.54-0.05c4.02,3.39,8.38,6.06,13.94,6.11c1.44,0.01,1.72,1.46,2.09,2.91
				c1.69,6.7,12.14,10.59,18.39,7.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M357.85,1244.4c-0.53-4.14-0.6-8.72-6.99-7c-0.16,0.04-0.43,0.07-0.48,0c-3.64-5.31-11.09-1.5-15.13-6.38
				c-1.14-1.38-3.95-2.2-5.85-1.97c-7.5,0.89-9.04-5.16-12.69-9.71c3.2-0.88,6.23-0.54,8.18-2.91"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M40.68,980.56c0.1,10.52,2.28,20.34,10.46,28c-2.61,1.85-4.58,1.41-6.46,1.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M100.62,983.55c4.06,6.65,6.63,14.01,9.99,20.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M295.41,738.7c-5.07,5.7-7.6,12.4-8.02,19.99c-0.11,1.97-0.01,4.14-1.97,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M893.28,1468.27c2.09,3.55,0.84,7.97,3.39,11.57c2.41,3.4,5.37,5.99,8.88,8.47c-2.6,3.35-2.2,6.87-0.78,10.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M870.31,1403.31c8.44,7.87,19.01,10.81,29.99,12.37c3.51,0.5,7.21-0.55,10.98-2.35c3.94,5.15,10.45,7.87,14.97,12.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M24.2,448.37c-2.43,7.02-5.99,13.64-7.49,20.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M661.53,499.84c-1.62,3.35,0.82,6.06,1.58,8.97c1.55,5.94,4.11,11.46,2.16,18.08c-0.95,3.23,1.5,7.19,0.25,10.92"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M116.6,1010.04c-0.94,4.22,0.93,8.22,2.73,11.36c3.64,6.32,7.33,12.12,3.21,19.7c-0.8-0.13-2.13-0.35-3.45-0.57"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M489.71,1386.32c0.4-1.05,0.54-2.37,2-2.48c4.06-0.31,4.75-3.92,6.73-7.32c3.38,3.39,6.45,2.39,9.81-0.12
				c3.93-2.93,9.21-2.11,13.55,1.53c3.52-4.6,7.9-0.32,11.87-1.06c0.94-0.17,2.71,0.69,2.88,0.39c2.81-5.04,5.44-1.06,8.1,0.06"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M250.46,1250.9c4.78,4.82,2.62,10.57,1.93,15.98c-0.86,6.69-3.34,12.72-9.44,16.47c-2.15,1.32-1.81,4.01-3.48,5.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M962.21,1437.29c0.55-3.98,0.78-7.75-4.99-7.49c-2.47,0.11-4.04-1.73-5.4-3.1c-3.92-3.92-8.04-3-11.47-0.27
				c-3.48,2.77-5.97,2.64-9.67,0.49c-4.78-2.77-7.4,1.73-10.22,4.14c-7.08-2.67-11.11-7.69-11.68-15.27"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M133.08,1110.98c-9.3-3.4-16.21-10.19-23.39-16.76c-1.2,2.3-2.58,4.01-5.05,2.23c-1.81-1.3-3.36-2.96-5.02-4.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M686,1179.44c-1.83,1-3.29,2.69-5.51,3.06c-1.49-0.66-1.7-2.78-3.48-3.07c-2.61,1.32-5.55,2.28-6.48,5.5
				c-1.52,5.26-5.31,8.29-10,10.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M64.16,422.38c-7.38-0.29-14.06,2.77-20.93,4.67c-3.17,0.88-6.3,2.39-9.55,3.29c-1.46,0.4-3.03,0.45-2.99,2.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M840.34,1334.85c1.5,0,3.12,0.37,4.47-0.08c2.95-0.99,6.08-2.5,4.92-6.39c-0.87-2.89-2.38-6.39-6.36-5.41
				c-3.42,0.85-6.11,3.02-4.53,7.37"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M40.18,985.05c-1.83,3.5-4.21,6.83-5.37,10.53c-1.72,5.51-5.39,8.83-10.12,11.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M127.59,1483.76c-4.42,6.18-12.87,7.92-16.48,14.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M239.97,1073.5c-2.66,1.51-5.33-2.03-8.07,0.39c0.34,4.66,5.07,6.07,8.07,9.2c-0.44,1.57-1.5,3.12-1,4.9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M577.62,572.79c3.83,2.83,8.14,5.21,11.33,8.64c2.52,2.71,5.07,3.07,8.14,2.82c2.49-0.2,4.22,0.55,5,3.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M970.7,1242.4c-7.12,5.38-15.61,8.93-21.65,15.69c2.95,5,1.63,8.43-4.36,11.2c-1.03,0.48-1.92,1.16-2.95,1.59"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M21.2,465.86c-0.92,8.33-4.42,15.97-6.49,23.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M101.12,1365.83c-3.41,6.37-7.31,12.28-12.3,17.66c-3,3.23-4.18,8.16-6.18,12.32"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M632.06,1423.3c-2.12,3.67,2.31,4.53,3,7c-5.81,6.61-5.85,6.63-1.47,15.48c1.27,2.56-0.36,4.24-1.56,5.99
				c-2.72,3.97-3.57,12.41-0.89,16.46c2.92,4.42-2.4,4.92-3.07,7.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M734.45,1381.32c8.24,4.3,17,7.08,25.89,9.77c4.12,1.24,7.07-0.64,10.57-0.77"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M444.76,1360.33c-2-2.67-4.55-3.07-6.94-0.94c-3.22,2.86-7.62,2.75-11.04,4.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M143.07,1143.96c5.58,4.07,11.73,7.13,18.67,9.81c-2.45-4.84-2.65-10.43-6.38-14.93c1.04-1.95,1.97-3.92,4.69-3.87"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M123.09,1420.8c-1.76,6.22-4.5,12.06-6.99,17.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M327.38,947.07c0.2,2.33,0.26,4.69,0.35,7c0.18,4.71,1.67,9.68-4.17,13.27c-3.04,1.87-4.82,6.34-5.88,10.64
				c-3.39-2.1-6.44-2.94-9.15,0.49c-2.81-1.8-5.07-5.54-8.62-1.92"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M765.42,689.73c5.5,0.15,10.93,0.33,16.51-0.24c6.96-0.72,13.76,1.7,19.95,5.24"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M110.11,1110.48c1.93,5.21,3.69,10.83,9.05,13.36c4.44,2.09,9.03,4.3,13.55,5.74c4.97,1.59,6.2,4.38,6.79,8.4
				c0.23,1.54,1.41,1.99,2.08,2.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M549.15,428.88c0.67,3.5,1.54,6.97,1.93,10.5c0.29,2.62,0.65,5.5-1.93,7.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M260.95,1059.01c1.84,4.12,4.87,7.42,7.88,10.83c-1.65,2.77-3.61,2.79-6.58-0.03c0.45,6.68,5.88,7.28,7.74,10.4
				c-0.54,2.65-3,1.06-4.06,2.28"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M512.68,428.38c1.85,4.73,2.5,9.96,5.98,14c0.96,1.12,2.03,2.03,3.5,1.45c1.98-0.78,1.25-2.84,0.9-3.92
				c-1.23-3.78-2.26-7.55-2.39-11.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M149.57,1007.54c0,7.5,0.4,15.03-0.22,22.47c-0.21,2.52,0.26,5.04-0.28,7.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M276.93,1188.43c5.87-0.07,12.25-1.91,16.05,4.96c0.52,0.93,1.35,2.38,2.93,2.02c5.96-1.38,5.17,4.9,7.78,7.25
				c2.92,2.63,2.51,7.09,0.21,10.76"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M136.58,1026.53c-3.81-3.25-8.62-4.93-12.11-8.88c-4.78-5.4-9.95-10.31-10.36-18.1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M476.22,1287.38c0.11,2.49,0.16,4.93-3.5,4.5c-3-0.35-3.19,2.15-3.99,4c-1.39,3.22-3.8,4.57-6.94,2.89
				c-1.5-0.81-2.75-1.3-4.03-0.36c-5.43,3.99-10.99,0.95-16.51,0.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M222.99,1025.53c3.19,6.08,6.97,11.74,11.99,16.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M841.34,1418.8c0.81-0.88,1.89-1.93,2.97-1.44c5.81,2.64,11.4,1.3,17.01-0.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M129.59,1110.48c-2.01,3.7,1.38,5.57,3.11,7.38c4.31,4.5,9.21,8.44,13.87,12.6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M104.11,1019.03c1.41-0.44,2.87-1.34,3.8,0.38c-0.99,8.6-4.04,16.31-9.33,23.55c1.24,2.31,3.32,5.11-0.7,7.56
				c1.35,0.9,2.55,1.69,4.16,2.75c-3.37,1.59-2.47,5.26-4.28,7.82c-2.46,3.49-5,6.92-6.34,11.33c-1.81,5.97,0.4,9.08,5.19,11.59
				c0.47,0.25,0.68,0.97,1.02,1.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M814.87,869.12c2.09,3.21,3.52,6.9,6.51,9.48c2.22,1.92,4.67,2.99,7.48,1.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.72,1195.43c-0.81,5.66-0.81,11.33,0,16.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M152.56,445.87c-1.74,5.37-4.59,10.44-2.22,16.58c0.58,1.5-2.57,3.83-1.28,6.41"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M296.41,1076c2.05,6.13,7.11,9.53,12.06,12.88c1.15,0.77,2.43,0.94,2.42,2.61"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M534.16,429.88c0.38,4.38,1.98,8.54,2.07,12.99c0.04,2.06,2.13,3.1,3.43,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M537.66,429.88c1.73,5.24,1.87,10.59,1,15.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M111.11,1071c2.04,2.39,5.5-0.56,7.52,2.05c-0.29,1.69-2.22,1.76-3.03,2.95c0.25,1.54,2.87,2.94,1.88,3.87
				c-4.36,4.13-0.52,5.35,2.34,7.14c-0.94,1.55-1.83,3.01-2.72,4.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M301.91,1216.42c1.66-4.17,3.64-4.05,7.99,0.5c-2.55,6.07,0.53,8.54,9.99,8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M309.9,1205.92c4.84,5.41,10.89,8.64,17.98,9.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M134.08,1429.79c5.34,1.82,8.84,7.26,14.98,7.56c0.62,0.03,1.3,1,0.99,1.93c-1.71,5.15,1.24,8.79,4.01,12.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.72,745.69c-0.82,5.33-0.82,10.66,0,15.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M260.45,1056.51c-1.31,4.6-3.68,8.48-6.9,12.08c-2.23,2.5-4.46,5.85,0.41,8.4c-4.33,6.66-4.24,15.26-9.5,21.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M300.91,1092.49c0.84,4.37,3.76,7.57,7.1,9.84c3.07,2.08,3.37,4.17,2.39,7.15"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M447.25,1283.88c-4.85,8.91-8.78,18.19-11.53,27.97c-0.42,1.48-0.36,3.46-2.45,4.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M175.54,1077.5c2.72,5.92,9.21,7.4,13.49,11.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M323.88,1163.45c-3.16-0.13-5.13,1.95-5.35,4.51c-0.45,5.18-3.53,4.07-6.63,3.48c-0.67,2.42,0.51,3.81,2.46,5.05
				c1.43,0.91,2.22,2.28,0.03,3.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M44.68,1418.8c-0.53,5.3,0.65,10.26,3,14.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M225.49,1055.01c2.4,5.15,5.96,9.97,11.09,11.67c4.29,1.43,1.32,5.74,4.39,6.81"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M41.18,1019.03c3.91,1.72,5.94,4.39,6.1,8.99c0.32,9.38,7.46,14.31,13.97,19.39c1.08,0.85,3.31-0.19,4.91,0.61"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M172.54,1053.01c-1.31,6.45,3.29,9.38,7.51,12.47c1.31,0.96,3.42,1.1,2.98,3.52c-0.87,4.78,2.55,5.9,5.99,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M857.32,1349.34c-1.44,6.5-1.44,6.5-11.49,6.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M322.88,1141.96c3.31,5.7,8.32,6.12,13.99,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M122.09,1386.82c0.46,4.86,2.54,9.49,2,14.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M336.37,775.68c6,2.42,9.04,8.02,12.83,12.62c2.43,2.95,5.02,5.36,8.15,7.37"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M114.1,1021.53c0.36,3.07-1.04,5.86-2.16,8.42c-1.32,3.05-2.81,5.68-1.36,9.07c2.52,5.87-3.04,8.75-5.21,12.92
				c1.52,3.61,3.48,4.52,6.78,2.13c1.25-0.9,2.15-2.49,3.95-2.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M117.6,1039.52c1.85,4.41,4.43,8.35,7.48,12.01c0.61,0.74,1.61,1.84,1.42,2.46c-1.96,6.37,2.86,9.57,6.09,13.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M435.76,448.87c-2.84,8.06-1.49,16.03,0.5,23.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M236.97,1042.02c-3.13,2.26-8.04-1.69-10.89,3.18c3.41,4.6,8.59,6.65,13.26,10.09c-4.21,3.71-8.37-0.08-12.36,0.22"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M752.93,621.77c-0.33,4.66-0.67,9.33-1,13.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M190.02,1095.99c-4.05-1.34-7.94-3.76-12.53-2.06c1.21,3.11,4.2,4.62,6.22,6.89c3.51,3.93,3.13,9.08,5.49,13.33
				c0.89,1.61,2.99,3.03,2.81,5.32"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M345.36,675.73c-2.08-0.27-0.88-2.89-2.68-3.56c-4.71,0.82-4.39,5.63-3.99,8c0.81,4.71-5.23,3.25-4.92,7.06
				c0.25,3.12,0.65,6.37-0.4,9.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M158.06,1019.03c2.71-1.33,3.65,0.08,3.46,2.5c-0.34,4.22-5.11,6.17-5.63,10.48c-0.06,0.49-2.49,0.69-3.82,1.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M28.19,1422.3c-3.44,1.91-1.77,5.49-1.18,7.4c0.75,2.42,0.39,4.4,0.18,6.59"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M134.58,1390.31c-1.2,4.83-2.22,9.72-4.99,13.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M853.33,1374.32c0.22,5.94,4.14,9.36,8.49,12.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M10.21,1036.02c3.71-0.7,6.3,2.64,9.98,2.76c1.39,0.05,1.46,2.85-0.48,4.25c-3.22,2.34-7.29,3.37-10,6.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M166.05,444.87c-7.66,1.16-15.32-0.1-22.98,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M51.17,1052.01c0.44,2.68,4.98,4.43,1.58,8.07c-1.06,1.13,1.04,2.75,1.57,3.27c2.84,2.77,4.49,6.44,7.34,9.15"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M275.93,1117.97c-4.66,2.67-5.77,8.44-10.21,10.98c1.17,2.43,3.27,1.74,4.72,2.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M164.55,1009.54c1.03,7.77,5.61,12.78,12.49,15.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M828.85,877.62c0.76,4.44,2.87,7.77,7.53,9.18c1.54-1.27,3-2.48,4.35-3.6c1.08,1.62,2.39,4.04,5.61,3.92"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M192.52,655.75c0.67,4.33,1.33,8.66,2,12.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M990.68,857.13c-1.61,1.32-2.4,3.45-5.61,1.71c-3.02-1.64-5.26,1.73-7.38,3.79c-2.56-0.84-4.54-3.13-7.49-3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M287.92,1143.96c-4.58,1.97-7.99,5.23-10.53,9.1c0.12,2.74,3.01,1.17,3.53,2.89"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M358.85,1167.45c4.31,2.84,6.87,6.71,6.99,11.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M222.49,1026.53c-2.23,3.84-5.38,6.89-8.46,10.03c-2.01,2.04-4.22,2.96-7.02,2.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M156.56,1055.51c-1.69,5.1-7.39,5.53-10.55,9.56c-0.01,1.13,2.27,2.04,1.56,3.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M218.49,1052.01c-4.51,3.68-9.88,5.86-14.98,8.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M580.61,1312.86c2.5,3.51,5.31,6.87,5.49,11.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M232.48,1087.49c3.21,4.32,5.99,8.81,11.4,11.19c2.91,1.28,4.1,5.79,4.65,8.39c0.9,4.32,3.26,5.71,6.37,7.5
				c1.92,1.11,3.39,3.08,4.05,5.4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M311.89,1178.94c2.78,4.55,9.24,5.41,11.13,10.87c-3.38,6.58-8.63,0.41-13.07,2.53c3.53,4.82,9.12,7.56,12.93,12.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M80.14,1025.53c2.02,5.96,8.19,8.65,10.99,13.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M111.11,1005.54c1.41,3.25-1.14,5.21-2.54,7.47c-1,1.61-3.37,2.48-2.45,5.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M28.69,1051.01c7.72,3.47,16.57,3.56,23.97,8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M800.88,1406.8c2.05,4.85,6.86,7.7,8.99,12.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M783.4,1146.96c-1.64,3.43-2.37,7.36-5.48,10.01c-1.33,1.13-0.01,2.04,0.48,2.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M229.98,1222.41c0.21,4.91-3.48,8.27-4.99,12.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M724.96,1367.83c2.32,4.23,6.53,7.19,7.99,11.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M219.99,1067.5c-3.87,4.18-6.09,8.75-3.5,14.49c0.59,1.3-0.58,2.08-1.05,2.97c-1.59,3.01-4.87,5.42-2.99,9.54
				c0.16,0.35-0.17,1.32-0.47,1.43c-5.04,1.77-4.89,6.69-6.51,10.62c0.49,1.5,2.4,0.18,2.77,2.06c-6.13,3.58-2.84,11.66-6.74,16.86"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M821.86,1164.45c2.22,1.42,2.79,3.47,2.53,6c-0.25,2.37,1.24,4.16,2.47,5.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M22.2,1025.03c-2.87,3.77-5.52,7.72-8.99,10.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M123.09,1090.99c5.93,4.62,12.85,7.04,19.98,8.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M81.64,981.55c-5.2,8.99-5.79,19.92-12.01,28.47c-1.38,1.89,0.82,2.65,1.02,4.01c-0.79,3.08-5.59,3.49-4.99,7.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M839.84,1331.85c-0.11,5.72,0.79,11.64-4.45,16.04c-2.42,2.04-0.91,4.93,0.12,6.86c1.12,2.09,3.09,4.44,6.35,2.61
				c0.9-0.51,1.98-0.69,2.98-1.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M458.24,692.72c3.93,0.21,7.18,1.82,9.45,5.02c1.24,1.75,3.12,1.95,4.47,1.33c1.48-0.68,2.99-2.08,2.61-4.37
				c-0.16-0.95,0.28-1.99,0.45-2.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M287.92,1071.5c-8.65,1.05-7.88,11.84-15.49,14.09c3.58,1.71,6.59,1.81,9.49-0.1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M24.7,1415.3c-3.57,2.15-7.3,0.9-10.99,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M766.42,1381.32c2.66-0.19,3.17-3.35,6.66-4.33c-0.89,4.33-1.15,8.38-0.17,12.32"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M211,1083.99c-4.02,0.89-6.88,2.79-8.64,6.93c-1.56,3.67-6.25,3.6-9.34,5.56c1.36,1.87,3.65,2.02,5.66,3.12
				c-1.51,3.71-5.34,4.71-8.15,6.87"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M232.48,444.87c-1.53,4.44-5.57,6.75-8.57,9.92c-0.93,0.98-2.05,1.69-2.41,3.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M60.66,1048.02c-1.67,3.64-4.28,5.68-8.49,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M502.69,1262.39c-0.76,4.06-2.9,7.4-5.49,10.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M19.2,1212.42c2.91,3.09,4.91,7.08,8.99,8.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M157.06,1210.42c3.25,2.48,3.68,7.46,7.99,8.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M593.1,1354.34c-3.01,1.79-6.87,2.02-9.36,0.75c-4.14-2.12-6.96-0.77-10.12,1.25"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M168.55,1042.52c1.7,4.47,6.54,5.43,9.49,8.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M299.91,1120.47c2.98,4.77,6.87,8.55,11.99,10.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M171.04,1235.41c0.17,3.33,0.33,6.66,0.5,9.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M123.59,1070.5c3.49,5.77,6.51,11.97,13.49,14.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M697.99,937.58c-1.7,3.42,1.53,6.6,0.5,9.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M581.61,574.29c7.68-0.03,14.75,1.1,17.98,9.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M528.17,597.78c0.5,0.67,1.11,1.28,1.48,2.01c2.2,4.31,4.1,9.03,10.06,6.22c1.85,3.16,1.56,7.57,6.44,5.77"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.72,1339.34c-0.79,3.33-0.79,6.66,0,9.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M184.53,1074.5c-3.9,2.55-7.83,4.29-11.99,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M99.12,980.56c0.34,1.48,0.82,2.75,0.69,4.51c-0.46,6.14-0.82,12.38,0.81,18.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M212.5,1062.01c-2.28,5.88-7.86,8.17-12.62,11.42c7.17,3.8,7.27,4.2,2.14,8.57"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M497.7,1266.39c-1.73,3.47-3.39,6.99-6.99,8.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M275.43,1134.96c-3.5,3.66-6.99,7.33-10.49,10.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M131.08,1083.49c3,3.5,5.99,7,8.99,10.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M664.02,472.85c-0.16,3.92-3.25,7.33-1.8,11.44c-1.91,1.49-3.98-0.95-5.69,0.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M28.69,1442.28c0.01-2.63-0.29-4.91-2.95-6.57c-3-1.87-3.88-6.08-7.54-7.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M897.28,1415.8c0.83,3.9,5.23,5.55,5.99,9.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M902.77,1402.81c2.33,3.16,4.66,6.33,6.99,9.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M162.05,1208.42c1.33,3,2.66,6,4,8.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M163.05,1051.51c4.19,2.97,8.93,4.22,13.99,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M371.83,1163.45c0.33,3.74-3.93,5.43-4,8.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M290.42,1053.51c1.17,5.19,6.18,13.26,12.49,20.49c-1,2.12-2.8,3.09-4.99,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M168.05,1237.4c-0.4,3-1.05,6,0,8.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M127.59,1094.99c3.5,6.28,6.94,12.62,14.48,14.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M51.67,1355.33c-1.42,3.04-2.83,6.07-4.36,9.34c-0.65-1.34-1.14-2.34-1.63-3.35"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M119.1,1060.01c3.74,3.54,6.47,7.88,9.49,11.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M289.92,1056.01c-2.98,6.39-7.21,11.95-12.54,17.23c1.72,0.43,2.39,0.6,3.05,0.76"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M277.93,1087.49c-0.28,4.07-4.58,5.8-5.49,9.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M875.3,1408.3c-0.94,3.36,1.21,6.02,2,8.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M303.4,1108.48c0.36,3.79,3.55,5.74,5.62,8.19c-0.41,2.14-0.77,3.97-1.12,5.8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M80.14,1003.54c0.56,5.87,6.29,9.62,6.32,15.99c0.01,1.61,2.13,5.34,5.66,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M25.2,1024.03c-3.88,2.02-7.22-1.83-10.99-1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M34.19,1165.45c-1.97,2.83,1.56,5.66-0.07,8.62c-2.44-0.62-2.89-2.48-2.93-4.62"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M146.07,1080c-0.72,4.89-5.18,6.78-7.99,9.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M168.05,1030.53c2.72,6.46,6.42,9.31,10.99,8.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M81.64,1054.01c4.28,4.48,5.54,10.81,9.49,15.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M212,1040.02c0.94,2.79,3.26,2.56,5.69,2.44c-1.3,3.95-5.4,4.84-7.69,7.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M118.1,1095.99c3.86,2.96,8.18,5.54,8.99,10.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M264.44,1080.5c2.44,3.87,2.04,9.59,7.49,11.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M164.05,1058.51c-0.24,3.21-1.9,5.71-4,8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M757.93,1370.33c3.68,3.78,6.22,8.28,8.53,12.97c1.12,2.27,2.63,4.35,3.96,6.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M342.36,754.19c-1.84,2.16-3.83,4.26-3,7.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M863.81,1335.85c-2.38,1.91-4.95,0.98-7.49,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M150.56,952.57c0.33,2.5,0.67,5,1,7.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M248.46,1117.47c0.3,1.68,1.23,2.69,2.99,2.48c5.18-0.63,9.75,1.57,14.49,3.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M272.94,1149.46c-3.16,2.5-6.33,5-9.49,7.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M273.44,1136.46c-2.36-0.22-4.7-2.32-7.05,0.45c-1.69,1.99-2.83-1.15-4.44-0.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M195.02,1109.98c-4-1-7.99-2-11.99-3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M333.87,1129.47c-2.79-0.07-5.23,0.67-6.99,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M185.03,1118.97c3.11,1.75,6.82,0.55,9.99,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M41.18,1008.04c1.33,0.43,2.33,0.92,3.09,2.45c2.18,4.37,5.36,7.66,10.89,7.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M899.78,1429.29c1.39,2.49,5,2.57,5.99,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M206.51,1060.51c4.67-0.52,8.64,3.67,13.49,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.72,423.88c-0.81,2.33-0.81,4.66,0,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M75.14,1449.28c-1.52,2.11-1.5,4.56-1.5,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M334.87,1129.97c2.03,1.82,5.02,2.75,5.49,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M84.63,1057.01c4.49-2.49,7.02,1.89,10.49,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M359.84,1175.44c-5.28,3.36-5.63,3.85-4.99,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M164.05,1011.54c-1.66,2.17-3.33,4.33-4.99,6.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M109.61,1056.01c-1.85,2.59,1.79,2.44,2.04,4.29c-1.37,1.99-3.99,3.19-5.04,5.63c0.62,1.72,2.88,0.46,3.5,2.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M81.14,1037.02c2.5,1.02,4.51,2.73,6.6,4.53c-1.63,4.86-0.6,9.66,0.39,14.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M116.6,417.39c-1.38,2.42-4.43,3.29-5.49,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M208,1050.01c3,0.67,5.99,1.33,8.99,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M254.46,243.49c-1.79,1.78-5.2,1.39-5.99,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M61.16,1034.52c-6.4-0.27-12.76-0.88-18.98-2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M133.08,1104.48c-2.53,1.11-3.84-3.12-6.49-1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M16.21,1222.41c1.55,2.09,4.08,2.89,5.99,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M379.82,1218.42c0.14,2.54,0.19,5.04-2.5,6.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M17.2,1216.42c2.16,1.83,4.33,3.66,6.49,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M93.62,444.87c0.09-2.76-3.16-2.17-4-4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M280.93,1098.99c-4.79,0.69-7.78,4.72-12.09,6.16c1.55,5.16,4.55,6.48,9.09,4.33"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M113.1,1068c-2.52-1.48-2.26,4.02-4.99,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M150.56,1051.01c1.25,1.59,2.49,3.17,3.74,4.76c2.89-0.25,3.91-2.53,5.75-2.76"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M87.13,1017.03c2,0.67,4,1.33,5.99,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M201.01,1082.5c1.19,2.77,4.52,0.4,5.99,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M137.58,1256.89c2.21,4.98,3.92,10.22,7.49,14.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M177.04,1423.3c-0.98,3.29-4.27,1.01-5.99,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M839.84,1343.34c1.32-0.32,2.47,1.1,2.38,1.47c-1.04,4.32,2.71,7.49,2.61,11.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M60.16,1074c-4.88,2-8.12-2.84-12.49-3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M145.07,1070.5c1.6-0.79,3.25-1.91,4.65,0.89c-2.44,1.78-4,4.27-3.58,7.66c1.75,1.24,3.31,0.05,4.93-0.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M22.2,482.85c-1.83,1.5-3.66,3-5.49,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M154.06,1004.54c1.18,3.72,0.34,6.78-3,8.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M545.65,453.86c-0.02,2.98-3.72,2.28-4.5,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M263.45,1089.99c3.61,1.55,4.27,6.88,8.99,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M387.81,1140.46c-2.16,2.29-4.33,1.44-6.35-0.15c-2.48,2.6-0.18,4.27,0.85,6.15"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M131.58,1059.51c-0.3-2.71-2.93-2.42-4.5-3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M855.82,1328.85c-1.77,0.83-3.03,3.08-5.49,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M319.89,1202.92c-3.07,1.21-6.74,0.02-9.56,2.42c-0.14,0.12-1.26-0.92-1.93-1.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M174.04,1025.03c-0.34,6.29-0.95,6.6-7.99,4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M314.89,1159.95c1,2.52,4.04,1.31,5.49,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M304.4,1108.98c2.41,1.63,5.86-0.03,7.99,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M105.61,1042.52c-1.84,0.81-3.31,2.44-5.49,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M273.93,1110.98c-0.66,2.32-2.37,3.88-4.02,5.52c1.01,0.49,2.02,0.99,3.02,1.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M181.03,1052.01c-2.5-1.41-5.16-0.28-7.44,0.23c-1.87,0.42-2.12-0.67-3.05-1.23"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M10.21,1457.28c-2.32,0.18-3.95-2.62-6.49-1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M310.9,1123.97c-2.61-0.96-5.24-1.66-7.99-0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M315.89,1092.99c-5.52-1.89-11.5,0.21-16.98-2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M86.63,1073c2.51,2.88,6.83,1.47,9.49,4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M193.02,1089.99c-2.91-1.18-5.69-0.19-8.49,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M234.48,1088.99c3.37-1.63,6.35,0.47,9.49,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M855.32,1339.34c0.4,1.91,2.42,1.54,3.5,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M6.22,1050.01c2.96-1.52,4.82,0.79,6.99,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M144.57,1081.5c-1.57-0.04-2.96,0.23-3.5,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M262.95,1146.96c4.07-2.21,4.8,2.75,7.49,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M145.57,1078c-1.17,0.17-2.33,0.33-3.5,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M82.14,1027.03c4.08,0,4.08,0,2.5-3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M718.47,1323.85c-0.1,0.68,0.32,1.5-0.5,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M141.07,1126.97c-2.76,0.33-0.28,4.14-3,4.5"/>
		
			</g>
        	</svg>
    
    	</div>
        )
    }