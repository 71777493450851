// const initialstate = {
//     token: null
// }

export default (state = {}, { type, payload }) => {
    switch(type) {
        case 'SET_TOKEN': 
        localStorage.token = payload.token;
        return payload;
            //return {...state, token: payload.token};
        case 'CLEAR_TOKEN':
            localStorage.clear("token")
            localStorage.clear('user')
            return {};
        default:
            return state
    }
}