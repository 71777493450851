import React from 'react';
import './Yellowstone.css';

export default function Yellowstone(props) {
    
    return (
        <div className={`yellowstone-container ${props.size}`}>
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id}>
            <g className="yellowstone-image">
       
            <path d="M1162.41,1499.5c-387.26,0-774.52,0-1161.79,0c0-499.65,0-999.29,0-1498.94c387.26,0,774.52,0,1161.79,0
                C1162.41,500.21,1162.41,999.85,1162.41,1499.5z M348.34,195.41c0,0,0.09-0.04,0.09-0.04c0.32,0.36,0.65,0.72,0.97,1.08
                c2.15,3.89,4.3,7.77,6.44,11.66c0.64-0.45,1.28-0.91,1.92-1.36c-2.81-3.41-5.62-6.82-8.44-10.23
                C349,196.15,348.67,195.77,348.34,195.41z M1083.42,1001.78c0.37,0.35,0.74,0.7,1.11,1.05c1.3,1.88,2.35,4.02,3.94,5.61
                c6.47,6.47,13.13,12.75,19.68,19.14c1.19,1.16,2.21,2.49,3.31,3.74c-0.29,0.44-0.58,0.87-0.88,1.31
                c-2.96-1.22-5.92-2.45-8.87-3.67c-9.85-4.09-19.6-8.31-27.26-16.17c-0.92-0.94-3-0.76-5.83-1.37c1.43,2.86,2.1,4.34,2.89,5.75
                c9.86,17.79,24.12,31.67,39.44,44.64c1.65,1.39,3.08,3.04,3.56,3.52c-2.04,3.72-2.77,7.06-4.8,8.34c-4.16,2.63-2.5,5.27-0.95,7.91
                c4.3,7.31,9.23,14.28,13.18,21.77c5.56,10.55,10.53,21.42,15.59,32.22c0.74,1.58,0.57,3.6,1.03,6.92
                c-3.19-2.21-5.04-3.49-6.89-4.77c8.04,14.81,15.84,29.54,28.64,42.8c0.32-3.62,0.66-5.67,0.66-7.72
                c0.03-47.11,0.02-94.23,0.02-141.34c0-1.5,0.15-3.05-0.21-4.47c-0.25-0.96-1.11-2.26-1.96-2.51c-5.99-1.73-12.04-3.22-18.08-4.79
                c0.15,0.44,0.29,0.88,0.44,1.32c-2.64,0.9-5.64,3.09-7.86,2.47c-8.09-2.23-16.6-3.73-23.33-9.52c-1.13-0.97-3.1-1.77-4.45-1.51
                c-5.32,1.04-9.38-1.34-13.39-4.14c-2.57-1.8-5.13-3.62-7.69-5.43c-0.38-0.34-0.77-0.69-1.15-1.03
                C1083.33,1001.86,1083.41,1001.78,1083.42,1001.78z M1090.42,261.46c-0.34,0.35-0.68,0.7-1.02,1.06
                c-0.33,0.34-0.66,0.68-0.98,1.02c-0.33,0.34-0.67,0.67-1,1.01l-0.16,0.08c0,0,0.08-0.16,0.09-0.16c0.33-0.33,0.66-0.67,1-1.01
                c0.33-0.34,0.65-0.68,0.98-1.02c0.36-0.34,0.72-0.69,1.07-1.03l0.11-0.14L1090.42,261.46z M352.37,876.77l-0.03,0.09
                c-0.38-0.3-0.76-0.6-1.13-0.9c-5-11.34-11.46-21.73-19.8-30.95c-3.12-3.45-6.02-7.11-8.81-10.83c-0.93-1.25-1.2-3-2.25-5.79
                c5.69,2.97,10.03,5.24,14.37,7.51c0.31-0.44,0.63-0.87,0.94-1.31c-0.7-1.24-1.31-2.54-2.11-3.72
                c-12.85-18.93-24.44-38.51-31.21-60.58c-0.58-1.89-1.4-3.81-2.55-5.4c-1.86-2.57-4.08-4.87-6.15-7.28c-0.5,0.24-1,0.49-1.5,0.73
                c-1.76,9.8,6.51,19.69,0.36,30.26c-1.32-1.41-2.35-2.38-3.22-3.47c-10.52-13.17-11.48-28.24-8.72-44.04
                c0.48-2.74,1.99-3.97,4.92-3.64c1.68,0.19,3.43-0.22,5.85-0.42c-1.34-3.5-2.48-6.21-3.43-9c-2.52-7.39-5.39-14.64-4.11-22.82
                c0.81-5.17,1.34-10.52,0.95-15.7c-0.62-8.1-3.34-15.7-8.08-24.06c-0.53,3.21-0.83,4.97-1.12,6.73c-0.78,4.75-0.38,10.18-2.61,14.1
                c-3.06,5.37-3.4,10.56-3.05,16.23c0.27,4.37-1.43,7.81-5.07,10.21c-8.83,5.81-18.44,6.7-28.5,4.29c-3.22-0.77-6.34-2-9.58-2.64
                c-2.96-0.59-4.43,0.61-3.56,3.94c0.63,2.41,1.19,4.9,1.23,7.37c0.08,4.61-0.78,9.08-4.97,11.88c-4.46,2.98-8.9,2.98-13.37-0.36
                c-4.93-3.68-10.02-7.15-15.09-10.63c-6.17-4.23-12.23-8.65-18.68-12.42c-3.54-2.07-7.77-2.97-11.68-4.4
                c-0.22,0.46-0.44,0.93-0.66,1.39c6.34,5.29,12.9,10.33,18.96,15.92c10.5,9.69,21.34,19.13,30.8,29.77
                c7.93,8.91,5.98,14.33-4.75,19.75c-0.74,0.37-1.5,0.74-2.27,1.03c-2.62,0.97-4.04,2.46-3.68,5.61c0.4,3.51-1.69,4.43-4.75,3.31
                c-1.87-0.69-3.72-1.49-5.43-2.48c-4.75-2.75-9.16-6.34-14.22-8.23c-4.27-1.59-9.62-2.54-13.83-1.38
                c-3.7,1.02-7.15,2.69-11.21,2.76c-3.46,0.06-4.83,1.9-2.77,5.28c2.85,4.69,5.35,9.62,8.48,14.11
                c17.14,24.55,34.32,49.08,51.72,73.45c5.99,8.39,12.62,16.33,19,24.44c1.16,1.47,2.55,2.74,3.83,4.11
                c0.37-2.31-0.19-4.01-0.72-5.71c-2.58-8.26-5.45-16.44-7.54-24.82c-0.62-2.48,0.96-5.5,1.53-8.27c2.33,1.02,4.92,1.69,6.92,3.16
                c2.52,1.85,4.45,4.48,6.84,6.55c2.37,2.06,4.87,4.01,7.51,5.7c3.31,2.12,6.58,1.44,8.84-1.62c3.22-4.36,6.24-8.9,8.91-13.6
                c0.74-1.29,0.14-3.49-0.19-5.21c-0.59-3.1-1.67-6.11-2.1-9.22c-0.38-2.77,1.28-3.98,3.6-2.29c3.47,2.53,6.73,5.39,9.82,8.37
                c3.22,3.11,5.89,6.82,9.3,9.68c1.34,1.12,4.33,1.39,6.02,0.7c2.93-1.19,1.61-3.67,0.65-5.93c-3.25-7.66-6.42-15.35-9.43-23.11
                c-0.69-1.78-0.65-3.85-0.95-5.79c10.73,5.43,14.99,16.15,23.07,24.08c-0.64-8.37-0.26-8.63,7.17-5.69
                c5.41,2.15,10.64,5.11,16.25,6.23c4.56,0.91,7.19,3.12,9.94,6.43c5.07,6.1,10.63,11.78,15.99,17.64
                C351.64,876.19,352.01,876.48,352.37,876.77z M1103.39,813.84c0.4,0.42,0.79,0.83,1.19,1.25c0.99,0.62,1.98,1.24,2.96,1.86
                c1.05,1.04,2,2.2,3.16,3.1c13.22,10.34,28.03,17.86,43.4,24.38c1.5,0.64,2.87,1.59,4.3,2.4c-0.13,0.46-0.27,0.91-0.4,1.37
                c-2.95,0-5.9,0-9.5,0c1.75,2.63,4.25,4.68,4.01,6.32c-0.95,6.32,4.59,7.61,7.46,12.32c0.56-2.21,0.98-3.12,0.98-4.02
                c0.04-25.47,0.06-50.95-0.06-76.42c-0.01-1.35-1.22-3.72-2.06-3.81c-1.49-0.15-3.87,0.64-4.57,1.81
                c-2.69,4.51-6.52,4.72-11.17,4.6c-4.74-0.12-9.51,0.98-14.27,1.54c-0.12,0.44-0.24,0.89-0.36,1.33
                c8.2,6.21,16.4,12.41,24.59,18.62c-0.09,0.45-0.19,0.91-0.28,1.36c-2.46,0.36-4.92,0.73-6.44,0.95c-2.54,2.29-5.58,3.75-6.33,5.98
                c-1.68,5.05-5.43,5.46-9.14,4.64c-7.89-1.75-15.61-4.23-23.41-6.41c-0.99-0.62-1.97-1.24-2.96-1.87c-0.4-0.42-0.8-0.83-1.21-1.25
                C1103.3,813.91,1103.39,813.83,1103.39,813.84z M876.79,1386.6l0.09-0.03c0.33,0.36,0.65,0.73,0.98,1.09c0,0-0.08,0.08-0.08,0.07
                C877.45,1387.36,877.12,1386.98,876.79,1386.6z M742.86,1388.81c-0.37,0.28-0.75,0.56-1.12,0.85c-0.32,0.35-0.63,0.7-0.95,1.04
                c-2.84,1.95-6.66,3.67-5.91,7.74c0.95,5.16,2.32,10.44,4.64,15.1c2.49,5,1.37,9.28-0.54,13.73c-3.25,7.56-6.76,15-9.89,22.6
                c-0.44,1.06,0.66,2.76,1.05,4.16c1.36-0.84,3.35-1.36,3.97-2.58c1.73-3.39,2.79-7.11,4.31-10.62c5.26-12.18,10.36-24.44,16-36.45
                c3.01-6.41,7-12.36,10.52-18.54c2.44-4.28,4.83-8.59,7.24-12.89c-0.51-0.31-1.01-0.61-1.52-0.92c-1.56,2.04-3.01,4.17-4.72,6.07
                c-1.29,1.43-2.94,3.64-4.38,3.6c-4.64-0.12-7.31,2.95-10.51,5.25c-2.52,1.81-4.93,3.76-7.45,5.55c-2.41,1.72-2.8,0.08-2.89-1.89
                c0.31-0.35,0.63-0.69,0.95-1.04c0.4-0.29,0.8-0.57,1.19-0.87l0.09-0.11C742.96,1388.6,742.86,1388.81,742.86,1388.81z
                M586.11,349.37c0,0-0.08-0.06-0.09-0.06L586.11,349.37z M829.69,1417.49l0.1,0.15l-0.15-0.12
                C829.64,1417.52,829.69,1417.49,829.69,1417.49z M192.3,614.14l0.11,0.1l-0.19-0.11c-0.29-0.38-0.59-0.77-0.88-1.15
                c-0.37-0.3-0.74-0.6-1.11-0.9c-0.58-0.26-1.16-0.52-1.74-0.78c-0.4-0.42-0.8-0.83-1.19-1.25c-0.94-0.59-1.89-1.19-2.83-1.78
                c-0.37-0.41-0.74-0.83-1.11-1.24c-0.74-0.24-1.48-0.48-2.21-0.72c-3.35-2.67-6.63-5.42-10.06-7.99
                c-5.99-4.48-10.67-9.76-10.26-17.78c0.42-8.09,0.95-16.18,1.93-24.21c1.08-8.78,2.75-17.49,4.16-26.23
                c-0.54-0.08-1.08-0.17-1.62-0.25c-0.43,2.74-0.65,5.54-1.34,8.21c-0.82,3.19-2.2,6.23-3.05,9.41c-1.26,4.73-2.84,9.48-3.21,14.3
                c-0.33,4.24,0.83,8.58,1.33,12.88c0.81,7.03-0.64,8.63-7.73,8.55c-6.66-0.07-7.95,1.17-7.81,8.08c0.08,3.65,1.54,7.56,0.64,10.88
                c-2.53,9.39,0.79,19.46-4.06,28.65c-1.5,2.83-2.45,4.71-5.54,3.91c-5.21-1.35-6.39,1.38-5.78,5.55
                c0.96,6.58,2.41,13.08,3.45,19.64c2.87,18.2-3.58,21.66-15.84,22.37c-3.14,0.18-4.14,1.94-3.71,4.62
                c0.57,3.6,1.34,7.19,2.25,10.72c1.36,5.22,0.62,7.69-4.57,8.27c-6.45,0.73-8.61,3.35-6.95,9.46c0.05,0.17-0.39,0.48-0.88,1.03
                c-15.79-11.9-31.41-24.09-47.48-35.65c-16.2-11.64-33.33-21.82-51.49-30.19c-3.18,6.97-2.07,12.93,3.5,16.92
                c26.21,18.77,47.59,42.54,68.92,66.36c1.3,1.45,2.12,3.32,3.16,4.99c-0.34,0.36-0.67,0.72-1.01,1.08
                c-1.45-0.55-3.01-0.9-4.32-1.69c-3.85-2.32-7.88-4.45-11.32-7.29c-15.94-13.1-31.67-26.46-47.54-39.64
                c-3.65-3.03-7.58-5.71-12.61-9.48c0,7.44-0.15,13.34,0.13,19.23c0.05,1.11,1.7,2.29,2.81,3.19
                c14.99,12.13,30.03,24.19,45.03,36.31c11.51,9.29,22.97,18.65,34.5,27.93c1.25,1,2.76,1.67,4.15,2.49
                c0.28-0.42,0.55-0.84,0.83-1.25c-15.29-13.93-30.57-27.86-45.86-41.78c15.38,7.97,55.69,45.67,56.5,52.86
                c-2.94-1.39-5.5-2.35-7.81-3.73c-15.79-9.43-29.86-21.06-43.47-33.4c-12.44-11.27-25.61-21.75-38.54-32.46
                c-2.19-1.82-4.83-3.1-8.12-5.17c-0.18,3.03-0.38,4.8-0.39,6.58c-0.03,8.16,0.24,16.33-0.11,24.47c-0.2,4.77,1.31,8.24,4.87,11.31
                c18.78,16.2,37.34,32.63,56.21,48.73c9.34,7.97,17.17,17.03,23.89,27.31c4.52,6.91,9.7,13.6,15.61,19.34
                c14.44,14.03,29.41,27.52,44.33,41.04c3.29,2.98,7.01,5.76,11,7.65c13.71,6.48,27.12,13.19,37.08,25.24
                c4.44,5.37,9.56,10.2,14.37,15.26l-0.1,0.04c-0.19-1.31-0.2-2.67-0.59-3.91c-6.27-20.18-15.55-38.75-27.45-56.33
                c-12.94-19.14-24.59-39.17-36.33-59.09c-2.96-5.03-6.65-10.69-2.86-17.26c0.37-0.64-0.32-1.89-0.54-2.85
                c-3.12-13.75-6.36-27.48-9.25-41.28c-0.49-2.35,0.54-5.02,0.86-7.55c2.3,0.66,4.64,1.22,6.89,2.03c1.4,0.5,2.65,1.39,3.98,2.08
                c2.48,1.27,4.97,2.51,7.46,3.76c0.31-0.41,0.62-0.82,0.93-1.22c-3.17-8.2-6.45-16.36-9.49-24.61c-2.81-7.64-5.62-15.31-7.86-23.13
                c-0.76-2.67-0.78-6.51,0.62-8.65c2.34-3.56,2.58-6.84,2.04-10.73c-0.39-2.86,5.21-10.43,7.5-10.34
                c5.79,0.24,11.58,0.75,17.36,1.14c0,0-0.07,0.07-0.07,0.07c-1.13-1.41-2.24-2.84-3.4-4.24c-3.06-3.71-6.29-7.29-9.12-11.16
                c-1.06-1.46-1.19-3.59-1.74-5.42c1.85-0.17,3.73-0.61,5.55-0.46c3.17,0.26,6.31,0.88,9.46,1.34c0.2-0.42,0.4-0.84,0.6-1.26
                c-1.23-1.68-2.58-3.28-3.66-5.05c-2.5-4.08-5.25-8.07-7.19-12.41c-1.78-3.99-1.58-8.62,1.93-11.56c4.93-4.13,6.23-8.92,5.44-15.01
                c-0.21-1.57,1.35-4.63,2.58-4.92c6.14-1.43,12.58-3.96,18.33,1.27c0.73,0.24,1.46,0.47,2.19,0.71c0.37,0.41,0.74,0.83,1.11,1.24
                c0.95,0.59,1.89,1.19,2.84,1.79c0.4,0.41,0.79,0.83,1.19,1.24c0.58,0.26,1.16,0.52,1.74,0.79c0.37,0.3,0.73,0.59,1.1,0.89
                C191.71,613.32,192,613.73,192.3,614.14z M888.6,1412.41l0.32-0.09c0,0-0.28,0.18-0.27,0.19L888.6,1412.41z M1037.42,1308.49
                c0.34-0.69,0.69-1.39,1.03-2.08l0.18-0.15c0,0-0.12,0.23-0.11,0.23c-0.35,0.69-0.7,1.39-1.03,2.08L1037.42,1308.49z
                M857.45,1480.75l0.32-0.07c0,0-0.43,0.05-0.43,0.05c-0.62-0.38-1.23-0.76-1.85-1.13c-1.25-1.4-2.5-2.8-3.74-4.2
                c-1.56-7.45,1.92-11.92,9.42-11c4.6,0.56,9.01,2.63,13.75,4.09c0.63-4.66,7.54-7.8,4.23-14.86c-3.02,0.25-6.51,0.53-9.45,0.78
                c1.12,2.25,3.27,5.31,2.79,5.8c-1.66,1.7-4.13,3.04-6.5,3.5c-2.88,0.57-6-0.17-8.95,0.17c-3.27,0.37-6.72,0.78-9.62,2.17
                c-2.6,1.24-6.66,4.24-6.36,5.73c0.62,3.12,3.64,5.78,5.77,8.56c0.36,0.48,1.15,0.63,1.74,0.94c5.11,2.72,5.23,4.89,0.44,8.06
                c-0.69,0.46-1.82,0.66-2.1,1.27c-0.84,1.84-1.67,3.81-1.81,5.78c-0.04,0.48,2.53,1.53,3.95,1.66c2.81,0.26,5.68,0.26,8.47-0.05
                c1.28-0.14,3.18-1.02,3.53-2.02c1.69-4.74,5.58-5.22,9.64-5.68c2.63-0.3,5.36-0.35,7.83-1.16c2.74-0.9,3.32-2.95,1.48-5.5
                c-4.74-6.58-5.96-6.84-12.98-2.34c1.24,0.54,2.44,1.06,4.27,1.85c-14.12,2.49-24.24-2.42-21.87-10.56
                c0.89,1.03,1.64,1.89,2.39,2.75c1.25,1.4,2.5,2.81,3.76,4.21C856.21,1479.95,856.82,1480.35,857.45,1480.75z M865.71,1494.47
                c-0.12,0.07-0.27,0.12-0.36,0.22c-0.05,0.06,0,0.2,0,0.31c0.1-0.2,0.2-0.41,0.3-0.61L865.71,1494.47z M881.81,1390.57l-0.12-0.06
                l0.22,0.07l0.14,0.17C882.05,1390.75,881.82,1390.57,881.81,1390.57z M1029.73,328.06l-0.04,0.35l0-0.29
                c-0.16-0.24-0.32-0.49-0.35-0.52C1029.36,327.63,1029.55,327.84,1029.73,328.06z M588.05,301.31l-0.07,0.08
                c0.19-2.04,0.37-4.09,0.56-6.13c-1.96-2.89-3.03-6.37-4.76-9.49c-0.77-1.38-2.78-2.54-4.41-2.83c-1.93-0.35-4.06,0.33-6.43,0.61
                c1.45,1.82,2.74,3.42,4.2,5.25c-1.38,0.95-2.41,1.66-3.42,2.36c2.75,4.77,2.75,4.77-8.64,8.73c2.76,1.77,5.23,3.36,8.26,5.3
                c-0.94-2.03-1.56-3.36-1.8-3.87C577.13,301.32,582.59,301.32,588.05,301.31z M613.04,385.27l-0.07,0.08
                c-1.31-1.1-2.44-2.61-3.94-3.24c-6.55-2.75-13.25-5.17-19.82-7.88c-3.41-1.4-6.62-2.33-9.71,0.56c-1.93,1.81-3.89,3.58-6.14,5.65
                c-0.04-0.16-0.03,0.64,0.21,0.71c4.16,1.27,7.4,5.72,12.67,3.44c1.27-0.55,3.81-0.29,4.64,0.62c3.73,4.13,7.67,2.32,11.87,1.3
                C606.08,385.7,609.6,385.66,613.04,385.27z M68.65,730.97l-0.07,0.06c-19.5-23.16-40.32-44.92-65.38-62.45
                c-2.35,6.91-1.62,9.17,3.67,12.96c3.38,2.42,6.75,4.87,9.92,7.55c13.07,11.07,25.98,22.33,39.14,33.29
                C59.84,725.65,64.4,728.14,68.65,730.97z M882.61,1087.74c15.44-12.23,32.13-23.03,45.68-38.38c-6.71-1.64-11.96-3.03-17.27-4.17
                c-3.58-0.77-6.01,0.2-7.88,4.01c-4.39,8.91-9.51,17.45-14.23,26.2c-2.2,4.07-4.15,8.27-6.21,12.4L882.61,1087.74z M981.65,806
                c-3.32,0.11-6.65,0.15-9.96,0.33c-11.28,0.6-22.63,0.74-33.83,2.03c-10.16,1.17-17.69,8.59-19.68,18.86
                c8.78-1.85,17.18-2.93,25.12-5.45c10.7-3.39,21.08-7.82,31.53-11.97c2.39-0.95,4.51-2.57,6.75-3.88L981.65,806z M1120.57,717.06
                l0.1-0.02c2.36,1.82,4.64,3.76,7.09,5.43c7.98,5.44,14.13,12.06,18.45,21.07c2.57,5.36,8.49,9.1,13.23,13.91
                c0.93-1.79,1.34-2.22,1.35-2.66c0.07-9.82,0.14-19.64,0.07-29.46c-0.01-1.01-0.66-2.44-1.46-2.95c-3.89-2.44-7.94-4.63-12.58-7.28
                c-0.54,6.43-4.11,5.22-7.71,4.05c-1.71-0.55-4.06-1.91-5.12-1.27C1129.14,720.86,1124.97,718.19,1120.57,717.06z M509.01,327.29
                c1.99-1.47,3.97-2.93,5.63-4.15c2.3,1.54,4.88,3.28,7.66,5.14c1.03-3.94,1.66-6.42,2.32-8.9c1.46-5.51,4.98,0.78,7.06-1.23
                c-3.72-6.06-3.56-5.99-0.77-12.92c0.5-1.25-0.94-3.35-1.64-4.99c-0.87-2.03-3.24-4.81-2.61-5.85c2.27-3.76,0.01-5.32-2.19-7.46
                c-5.86-5.7-5.54-8.65,1.14-11.77c-2.55-3.37-5.09-6.72-7.85-10.38c-1.6,1.28-2.98,2.39-5.16,4.14c2.69,0.44,4.08,0.67,7.02,1.15
                c-3.1,4.29-5.62,7.79-8.18,11.33c6.14,1.76,6.83,4.06,2.76,8.88c-1.27,1.51-3.1,2.56-4.31,4.1c-0.79,1-1.67,2.94-1.22,3.71
                c1.98,3.39,0.2,5.5-2.17,7.2c-2.89,2.07-3.6,4.08-1.11,6.93c2.57,2.95,1.76,5.74-0.65,8.4c-0.79,0.87-1.42,1.89-2.46,3.3
                c7.84-1.65,7.84-1.65,6.83,3.43L509.01,327.29z M773.89,236.52c-5.94,1.31-11.92,2.48-17.81,4c-1.97,0.51-3.94,1.6-5.52,2.89
                c-4.35,3.57-8.92,6.37-14.79,6.33c-0.79,0-1.58,0.48-2.38,0.74c2.8,0.65,6.08-0.59,7.94,0.52c5.33,3.21,10.24,1.6,15.35,0.19
                c1.34-0.37,2.66-0.78,3.99-1.18c0.16,0.53,0.32,1.06,0.49,1.59c-10.6,3.53-20.69,8.64-33.03,8.42
                c8.39,8.87,17.07,11.27,27.39,5.18c-3.34,5.14-9.34,7.67-14.4,5.59c-4.53-1.87-7.43-0.75-10.01,2.64
                c1.47,0.76,3.46,1.13,4.19,2.29c1.83,2.92,3.98,3.05,7.16,2.74c5.36-0.54,10.82-0.15,16.23-0.15c0.06,0.46,0.13,0.91,0.19,1.37
                c-4.85,0.55-9.98,0.3-14.48,1.87c-4.82,1.68-9.03,5.07-13.88,7.94c3.93,2.81,7.42,6.23,11.62,8.05
                c4.72,2.05,16.46-5.86,18.41-10.75c5.28-13.23,10.96-26.31,16.52-39.43c1.12-2.65,2.42-5.23,3.95-8.52
                c-3.18,1.15-5.3,1.92-8.5,3.07c0.65-2.69,0.99-4.08,1.31-5.47L773.89,236.52z M747.75,213.31c7.37,0.1,15,1.19,19.73-5.72
                c1.91,3.34,3.54,6.21,5.16,9.04c6.8-3.62,8.02-9.03,8.4-15.79c4.31,8.12-2.34,19.23-10.62,20.07c-0.63-1.83-0.99-3.83-1.98-5.43
                c-0.64-1.03-2.46-2.31-3.32-2.04c-6.16,1.88-12.24,4.06-16.61,10.49c3.35,0.86,6.28,2.05,9.28,2.26c3.09,0.22,6.26-0.5,9.39-0.82
                c0.04,0.2,0.07,0.39,0.11,0.59c-7.93,2.28-15.86,4.56-24.48,7.04c1.14,1.71,1.7,3.66,2.72,3.93c11.23,2.98,21.92,2.37,31.57-7.26
                c0.38,2.99,0.56,4.39,0.74,5.78c0,0-0.08-0.07-0.08-0.07c5.94-10.07,11.97-20.1,17.69-30.3c0.48-0.85-0.68-3.58-1.76-4.15
                c-3.44-1.82-7.1-3.5-10.87-4.36c-4.68-1.07-6.87-1.94-8.5-5.14c12.26,3.9,17.57,3.55,21.16-1.6c-1.29-0.45-2.54-0.83-3.74-1.33
                c-1.21-0.5-2.37-1.13-2.6-1.24c-1.19,1.33-1.96,3.01-3.06,3.28c-6.19,1.52-11.33-0.26-13.03-4.17c2.34,0.57,4.66,1.14,8.27,2.03
                c-2.62-3.69-4.55-5.98-5.99-8.55c-2.8-5-10.82-6-14.81-1.7c-2.11,2.27-4.26,4.51-6.48,6.84c6.63,2.23,11.92-0.23,17.05-4.57
                c-1.63,2.93-3.68,6.53-6,6.71c-8.04,0.65-8.27,8.49-13.83,12.03c6.03,2.38,10.6,2.25,14.66-2.12c-0.83,4.62-3.63,6.13-7.56,6.5
                c-1.41,0.14-3.11,0.74-4.04,1.73c-2.36,2.53-4.36,5.39-6.49,8.12L747.75,213.31z M424.24,174.4l-0.09,0.1
                c-3.99-7.68-3.99-7.68,3.57-8.8c-3.09-3.32-6.19-6.63-8.33-8.92c3.27-3.66,5.55-6.21,7.47-8.36c-2.54-3.15-4.32-5.35-6.18-7.65
                c7.72-4.61,7.37-6.32-1.84-13.06c-3.14,3.33-6.27,6.66-9.61,10.2c1.82,0.43,3.18,0.76,5.37,1.28
                c-3.75,5.58-7.18,10.68-11.05,16.43c4.04-0.51,7.77-0.99,12.77-1.63c-6.3,9.58-14.78,15.06-23.14,21.76
                c3.79,0.52,7.1,0.97,9.44,1.29c0,2.71,0,4.82,0,8.33c-2.63,1.27-6.61,3.19-10.59,5.11c0.08,0.4,0.17,0.79,0.25,1.19
                c2.85,0.19,5.71,0.38,10.07,0.68c-4.3,3.96-7.38,6.81-10.59,9.78c1.7,0.91,3.34,1.78,5.73,3.05c-3.93,2.58-7.14,4.68-10.47,6.86
                c12.41,10.1,25.09,19.07,43.24,18.5c-4.72-8.38-7.97-16.71-14.98-23.09c5.31-1.09,9.4-1.93,13.49-2.76
                c-0.1-0.47-0.2-0.94-0.3-1.41c-2.64-1.67-5.29-3.34-9.1-5.74c5.06-1.53,8.34-2.52,11.62-3.51c-9.18-0.24-15.89-4.83-18.11-13.12
                c1.65-0.4,3.29-0.8,4.78-1.16c-0.61-2.62-1.14-4.88-1.88-8.05C419.37,172.83,421.8,173.62,424.24,174.4z M91.62,956.77
                c0,0-0.05,0.08-0.04,0.08c-4.4-5.58-8.62-11.31-13.25-16.69c-3.79-4.4-8.79-7.91-11.9-12.69c-3.44-5.3-5.44-11.56-7.9-17.47
                c-1.55-3.73-2.1-8.07-4.31-11.3c-9.03-13.16-18.26-26.22-27.97-38.89c-6.35-8.29-13.63-15.88-20.55-23.73
                c-0.67-0.76-1.73-1.18-2.74-1.85c-0.39,0.86-0.75,1.3-0.75,1.74c-0.05,12.81-0.14,25.63,0.04,38.44c0.02,1.57,1.19,3.44,2.38,4.61
                c11.25,11.08,22.6,22.05,33.96,33.02c7.63,7.37,14.64,15.59,25.67,18.24c1.52,0.36,3,1.57,4.12,2.74
                c4.03,4.18,7.8,8.61,11.85,12.77C83.89,949.57,87.81,953.11,91.62,956.77z M159.54,1041.85l-0.12-0.06c0,0,0.22,0.05,0.22,0.05
                c3.14,2.97,6.49,5.77,9.31,9.02c0.95,1.09,0.94,3.41,0.68,5.06c-0.42,2.62-2.05,5.16-2.04,7.73c0.04,8.09-3.71,11.95-11.72,10.71
                c-3.9-0.61-7.64-2.2-11.46-3.31c-1.74-0.51-3.51-0.94-5.26-1.41c-0.27,0.54-0.53,1.09-0.8,1.63c3.37,2.54,6.49,5.53,10.17,7.5
                c5.94,3.2,10.74,7.36,13.56,13.47c4.91,10.64,14.75,13.14,24.77,15.38c0.99,0.22,2.24-0.33,3.28-0.75
                c9.17-3.7,17.37-9.75,27.63-10.83c4.77-0.5,9.51-1.61,14.16-2.84c1.48-0.39,3.84-2.33,3.67-3.09c-0.42-1.87-1.82-4.7-3.25-4.98
                c-11.06-2.14-19.46-9.28-28.55-14.94c-4.35-2.7-8.09-6.89-11.1-11.11c-4.26-5.96-9.42-10.29-16.34-12.44
                C170.78,1044.92,165.14,1043.44,159.54,1041.85z M483.1,201.45c-1.79-3.95-3.58-7.89-5.52-12.17c-3.44,2.72-6.6,5.21-10,7.89
                c4.78,5.02,10.06,5.08,15.59,4.19c-2.92,8.89-12.32,6.18-18.65,9.97c4.78,7.35,11.89,7.21,19.24,6.47
                c0.12,0.59,0.23,1.17,0.35,1.76c-3.84,3.11-7.68,6.22-12.44,10.07c4.33,1.72,8.15,3.23,12.55,4.98c-3.61,3.06-7,5.94-10.38,8.82
                c0.22,0.34,0.45,0.67,0.67,1.01c-1.89,0.12-3.77,0.25-6.62,0.43c6.24,4.46,11.68,8.35,17.54,12.54c-1.74,1.3-3.04,2.27-4.43,3.31
                c1.42,0.6,2.54,1.07,4.31,1.82c-1.83,1.12-3.02,1.84-4.49,2.74c3.81,2.81,0.1,3.91-1.65,5.64c2.56,2.94,5.69,5.35,7.06,8.52
                c0.89,2.05,0.09,6.19-1.53,7.63c-1.97,1.75-4.85,0.25-5.88-2.46c1.72-0.88,4.21-1.48,4.31-2.36c0.17-1.49-1.14-3.16-1.87-4.73
                c-0.13-0.28-0.42-0.54-0.69-0.71c-6.35-4.01-4.67-11.29-6.86-16.99c-0.19-0.49,0.05-1.54,0.44-1.8c5.61-3.72,0.81-4.78-1.64-6.17
                c-4.81-2.73-8.88-6.04-11.07-11.07c5.35-1.47,10.14-2.79,16.02-4.41c-2.18-1.55-3.1-2.21-4.03-2.86
                c-1.09-0.76-2.29-1.39-3.29-2.25c-3.34-2.9-3.07-4.23,0.78-6.52c1.29-0.77,2.13-2.28,3.17-3.46c-9.82-3.91-13.8-8.08-13.26-13.74
                c4.61-0.61,9.21-1.21,13.91-1.83c-7.88-5.75-15.27-11.15-23.17-16.92c0.23-0.2-0.57,0.48-1.97,1.67c6.99,1.42,2.37,4.45,1.49,6.69
                c-1.11,2.82-2.87,5.39-3.91,7.26c0.74,2.32,1.91,4.26,1.81,6.12c-0.11,2-1.41,3.93-2.37,6.33c2.98,0.23,5.97,0.47,9.98,0.78
                c-4.02,6.3-16.3,8.03-8.19,19.09c-4.81,2.35-9.38,4.58-13.95,6.8c0.07,0.48,0.15,0.96,0.22,1.44c5.05,0,10.11,0,15.68,0
                c-3.81,6.94-6.05,8.72-11.19,7.47c1.92-0.79,3.84-1.58,6.21-2.56c-5.8-1.91-9.73-3.03-13.49-4.57c-0.92-0.38-1.89-2.23-1.72-3.21
                c0.14-0.79,1.89-1.49,3.05-1.86c3.38-1.09,6.81-1.99,10.29-2.98c-2.64-6.83-5.93-9.17-8.9-6.3c-4.18,4.03-8.38,2.84-12.95,2.01
                c-1.9-0.35-3.91-0.06-5.87-0.06c-0.14,0.45-0.27,0.89-0.41,1.34c9.72,5.93,15.52,15.84,24.9,22.47
                c13.11,9.26,24.88,20.42,37.13,30.88c1.99,1.7,3.67,3.85,5.11,6.05c0.85,1.3,1.79,3.64,1.2,4.58c-3.35,5.37,0.5,8.11,3.76,10.77
                c4.35,3.56,9.08,6.67,13.65,9.97c-0.98,2.26-0.12,1.38-0.18,0.57c-0.47-5.52-3.52-10.91-0.71-16.59c0.06-0.12-0.06-0.32-0.08-0.49
                c-3.49-26.72-6.72-53.48-10.64-80.13C488.42,215.22,485.28,208.41,483.1,201.45z M973.64,1080.78
                c5.18-10.39,10.38-20.77,15.48-31.2c0.34-0.69-0.25-1.8-0.25-2.71c0.01-1.73-0.16-3.55,0.32-5.15c0.43-1.42,1.66-2.61,2.28-3.52
                c-2.84-1.32-5.12-2.44-7.46-3.44c-0.69-0.29-1.61-0.51-2.29-0.3c-6.41,1.94-12.77,4.07-19.21,5.92c-1.78,0.51-3.77,0.32-5.67,0.33
                c-1.89,0.01-3.93-0.62-5.65-0.1c-2.51,0.76-4.66,2.87-7.18,3.4c-8.85,1.84-14.85,7.04-19.79,14.37
                c-3.21,4.77-7.73,8.24-13.15,11.04c-5.32,2.75-9.63,7.53-14.19,11.63c-5.4,4.87-10.57,9.98-15.87,14.96
                c-0.75,0.7-1.67,1.23-2.52,1.83c-0.43-0.17-0.87-0.33-1.3-0.5c0.33-2.47,0.08-5.22,1.11-7.36c3.16-6.57,6.8-12.9,10.26-19.32
                c4.45-8.24,8.92-16.48,13.68-25.3c-13.82-4.04-25.57-8.83-35.49-17.94c-3.52,3.51-6.48,7.52-5.51,12.31
                c1.34,6.66-2.24,10.33-6.05,14.72c-6.15,7.09-11.6,14.79-17.61,22.57c4.18,3.1,7.33,5.86,10.88,7.91c2.14,1.24,4.93,2.01,7.4,1.93
                c7.63-0.23,11.97,2.35,13.91,9.87c1.11,4.3,1,8.9,1.81,13.3c0.31,1.7,1.25,3.9,2.59,4.68c3.72,2.16,7.75,3.82,11.77,5.41
                c1.16,0.46,3.07,0.5,3.98-0.17c4.02-2.94,7.54-6.61,11.72-9.26c6.97-4.42,13.91-9.36,21.58-12.02
                c7.15-2.48,12.67-5.81,17.11-11.88c6.28-8.57,21.42-12.16,31.13-7.89c0.86,0.38,1.49,1.28,2.22,1.94
                c-3.66,0.04-7.33,0.29-10.97,0.06c-9.63-0.6-16.81,2.81-22.43,11.01c-2.52,3.68-7.14,6.25-11.29,8.38
                c-6.18,3.16-13.39,4.56-19.07,8.34c-9.05,6.02-19.32,11.2-22.11,23.65c-0.69,3.09-4.52,5.7-7.32,8.04
                c-7.11,5.93-14.55,11.47-21.62,17.45c-1.04,0.88-1.61,4.06-0.89,5c4.16,5.35,8.35,10.81,13.34,15.35
                c6.54,5.94,13.83,11.06,21.2,16.85c3.32-2.41,7.61-5.49,11.87-8.61c5.61-4.1,11.23-8.18,16.76-12.39c1.14-0.87,2.16-2.11,2.79-3.4
                c1.57-3.17,2.6-6.62,4.35-9.68c6.67-11.68,13.44-23.3,20.38-34.82c3.68-6.11,7.79-11.96,11.66-17.95
                c1.43-2.21,3.02-4.37,4.06-6.77c1.48-3.41,2.28-7.12,3.79-10.51C969.77,1087.38,971.81,1084.13,973.64,1080.78z M1032.57,1309.7
                c-2.76,1.89-3.36,4.24-3.02,7.7c0.74,7.6,1.24,15.32,0.82,22.93c-1.23,22.08-2.89,44.14-4.57,66.2c-0.25,3.27,0.56,5.25,3.33,6.98
                c6.26,3.92,10.69,9.39,13.15,16.45c0.43,1.22,1.92,3.14,2.58,3c1.64-0.33,3.46-1.35,4.49-2.66c0.94-1.18,1.09-3.09,1.33-4.71
                c1.6-10.82,3.09-21.65,4.71-32.47c0.24-1.58,1-3.09,1.52-4.63c0.64,0.16,1.29,0.32,1.93,0.47c-2.06,13.05-4.13,26.1-6.23,39.39
                c8.62-0.84,10.37-2.21,10.03-6.46c-0.94-11.57-2.46-23.16-2.41-34.73c0.1-20.13,1.25-40.24,1.89-60.37
                c0.15-4.87,0.02-9.74,0.02-14.62c-2.5,9.01-5.2,17.4-13.98,23.82c0.1-1.97-0.02-2.53,0.18-2.91c0.46-0.87,1-1.74,1.66-2.47
                c7.41-8.22,9.09-18.77,10.41-28.94c1.18-9.12-0.45-18.57-0.06-27.84c0.55-12.94,2.36-25.84,2.66-38.79
                c0.35-15.13-0.33-30.28-0.6-45.43c-0.02-1.32-0.37-2.63-0.57-3.94c-0.35,0.02-0.7,0.04-1.05,0.06c-0.31,1.67-0.62,3.35-0.92,5.02
                c-1.65-1.1-2.47-2.22-2.53-3.38c-0.26-5.61-0.61-11.25-0.22-16.83c0.1-1.45,2.78-2.73,4.27-4.08c0.42,0.36,0.83,0.73,1.25,1.09
                c-0.71,1.77-1.63,3.5-2.03,5.33c-0.19,0.88,0.61,1.97,0.96,2.96c1.4-0.86,2.81-1.72,4.34-2.66c1.55,2.5,1.08,5.88,6.19,6.39
                c3.95,0.39,7.57,5.23,11.1,8.36c1.77,1.58,2.7,4.15,4.53,5.61c7.81,6.2,15.81,12.17,23.76,18.19c1.18,0.89,2.46,1.65,3.69,2.47
                c0.35-0.41,0.69-0.83,1.04-1.24c-1.39-1.43-2.74-2.92-4.19-4.28c-13.33-12.55-26.67-25.08-40.01-37.62
                c-5.07-4.77-7.14-10.5-6.55-17.53c0.56-6.62,0.64-13.31,0.46-19.95c-0.07-2.51-1.45-4.99-2.54-8.46
                c-1.55,1.81-2.16,2.35-2.56,3.02c-2.7,4.6-5.02,9.52-11.49,9.9c-0.94,0.06-2.28,1.63-2.59,2.74c-1.24,4.38-2.73,8.82-3.01,13.3
                c-0.35,5.45,0.4,10.97,0.72,16.45c0.37,6.43-1.06,12.24-5.69,16.98c-5.34,5.46-10.76,10.84-15.99,16.41
                c-0.93,0.99-1.09,2.71-1.6,4.09c1.34,0.15,3.04,0.89,3.97,0.34c4.55-2.69,8.91-5.7,13.3-8.66c1.49-1,2.86-2.16,4.29-3.24
                c0.64,0.35,1.28,0.69,1.92,1.04c-8.24,5.28-5.53,13.11-6.1,20.12c-1.93,23.39-4.01,46.77-5.41,70.19
                c-0.62,10.4-6.52,16.01-14.65,20.78c-9.73,5.7-19.22,11.81-28.79,17.78c-0.17,0.11-0.1,0.6-0.27,1.82c1.87-0.4,3.55-0.51,5-1.13
                c5.51-2.34,10.87-5.06,16.44-7.25c10.03-3.95,19.3-8.6,22.34-21.09c1,1.91,1.6,3.07,2.45,4.69c0-2.56,0-4.54,0-7.41
                c1.56,1.85,2.41,3.05,3.45,4.05c3.87,3.75,4.19,8.78,0.36,12.4c-1.3,1.23-3.4,1.6-5.14,2.37c-0.62-1.74-1.23-3.48-1.85-5.22
                L1032.57,1309.7z M333.33,173.39l0.09-0.06c1,4.7,3.13,8.61,7.13,11.53c3.35,2.45,6.8,4.93,9.56,7.98
                c3.76,4.14,8.29,8.36,9.98,13.39c2.4,7.13,6.17,12.64,11.43,17.71c7.88,7.61,16.23,14.94,22.89,23.54
                c8.51,11,15.55,23.14,23.17,34.82c0.35,0.53,0.18,1.4,0.25,2.11c-4.29-2.2-7.52-5.19-10.92-7.99
                c-9.47-7.79-17.38-18.15-31.16-18.95c-1.19-0.07-2.32-1.2-3.46-1.88c-0.84-0.5-2-1.72-2.43-1.49c-5.91,3.12-10.88-1.09-16.27-1.86
                c-9-1.28-11.19,0.99-9.62,9.86c0.23,1.31,0.4,2.67,0.9,3.89c2.28,5.53,4.79,10.97,0.12,16.6c-0.64,0.78-0.41,2.25-0.67,3.39
                c-0.71,3.2-1.62,6.36-2.16,9.58c-1.58,9.44-2.74,18.5,5.32,26.41c6.25,6.13,11.32,13.47,16.85,20.32c2.08,2.57,4.01,5.27,6,7.92
                c0.46-0.25,0.93-0.49,1.39-0.74c-3.3-9.49-6.58-18.99-9.93-28.47c-0.66-1.87-1.67-3.62-2.38-5.49c-1.21-3.19,0.05-4.91,3.21-3.57
                c4.69,1.98,9.08,4.66,13.63,6.98c1.85,0.95,4.6,3.01,5.51,2.4c3.98-2.63,7.6-1.25,11.48-0.3c4.61,1.13,9.29,1.99,13.94,2.96
                c1.12-6.26,2.02-6.83,8.15-4.6c2.81,1.02,5.53,2.3,8.21,3.64c2.79,1.4,4.35,0.72,5.97-2.05c2.97-5.07,5.93-10.31,9.85-14.62
                c7.77-8.53,16.18-16.46,25.32-25.64c-10.81-8.62-21.93-17.47-33.02-26.37c-1.41-1.13-2.74-2.39-3.99-3.71
                c-3.17-3.36-5.83-7.37-9.47-10.1c-15.41-11.53-31.1-22.69-46.68-33.99c-2.28-1.65-4.64-3.23-6.74-5.09
                C354.27,192.15,343.81,182.75,333.33,173.39z M567.92,271.33c0.96-3.18,2.66-6.35,2.71-9.55c0.07-4.28,0.29-9.73-2.07-12.63
                c-5.07-6.22-5.88-8.94-0.42-15.1c3.36-3.78,3.39-9.5-0.39-13c-0.79,2.19-1.32,4.24-2.25,6.09c-1.7,3.37-2.9,7.57-5.64,9.7
                c-4.03,3.14-5.58,6.81-5.91,11.43c-0.3,4.14-0.61,8.3-0.56,12.44c0.1,8.15,2.53,16.5-3.08,23.99c-0.99,1.32-0.97,3.5-1.14,5.31
                c-0.31,3.31-0.16,6.68-0.64,9.96c-1.36,9.13-3.06,18.21-4.38,27.34c-0.32,2.2,0.36,4.53,0.52,6.8c0.22,2.99,0.26,5.99,0.59,8.96
                c0.3,2.65,0.5,5.42,1.47,7.84c0.84,2.08,2.85,3.66,4.09,5.62c1.73,2.73,3.86,3.32,6.8,2.12c1.98-0.8,4.09-1.68,6.17-1.76
                c6.27-0.23,12.54-0.08,18.55-0.08c1.39-4.95-0.27-6.03-3.93-6.36c-4.4-0.4-8.78-1.39-13.04-2.6c-1.27-0.36-3.04-2.37-2.94-3.47
                c0.34-3.84,1.41-7.61,2.3-11.89c-1.58-1.07-4.03-2.72-7.5-5.07c4.29-1.27,7.13-2.1,10.94-3.23c-1.89-1.33-2.94-1.83-3.66-2.62
                c-1.38-1.51-2.57-3.18-3.84-4.79c1.67-0.62,3.33-1.26,5-1.87c0.73-0.27,1.49-0.47,2.44-0.77c-1.05-1.87-1.95-3.45-3.07-5.44
                c2.19-0.48,3.66-0.8,6.2-1.36c-2.56-1.69-4.14-2.83-5.81-3.83c-6.59-3.95-6.59-4.03-4.66-11.48c1-3.86,1.51-7.87,2.78-11.64
                c1.08-3.21,2.95-6.15,4.46-9.21c-1.72,5.7-3.6,11.36-5.06,17.13c-0.56,2.19-0.97,6.1,0,6.64c2.22,1.23,5.32,0.88,8.94,1.26
                c-2.14-3.7-3.51-6.07-5.49-9.49c2.94,0.27,4.35,0.39,5.75,0.52l-0.06,0.07c-5.02-3.79-0.83-8.5-1.72-13.3
                c10.84,1.11,6.74-9.38,11.5-15.24c0.81,7.9,0.32,15.59,10.52,12.61c0.84,6.36-5.35,6.43-7.58,9.65c0.14,0.55,0.27,1.09,0.41,1.64
                c3.31-0.58,6.63-1.17,11.14-1.96c-1.5,1.86-2.43,2.86-3.19,3.97c-1.34,1.95-2.57,3.96-3.85,5.94c2.04,0.59,4.08,1.18,7.34,2.12
                c-9.22,5.65-2.26,11.45-0.65,17.41c1.41,0.08,2.77,0.15,4.4,0.24c-6.05,8.21-6.04,8.2-0.52,16.18c0.56,0.8,1.4,2.08,1.13,2.67
                c-0.99,2.1-2.4,4-3.79,6.19c1.5,0.5,2.96,0.98,4.43,1.46c-0.49,1.33-0.85,2.32-1.22,3.31c1.83,0.59,3.29,1.06,4.76,1.53
                c0.11,0.52,0.22,1.04,0.33,1.56c-4.36,1.68-8.87,3.07-13.02,5.18c-1.88,0.96-3.34,3.2-4.35,5.19c-0.4,0.78,0.62,2.78,1.54,3.63
                c2.83,2.64,6.24,4.71,8.83,7.55c2.14,2.35,3.99,3.8,7.24,2.65c3.48-1.24,6.07,0.55,7.44,3.4c2.29,4.76,4.03,9.78,6.03,14.77
                c3.37-1.23,6.83-2.88,10.48-3.71c3.66-0.83,4.34-2.59,3.84-6.02c-0.92-6.25-1.64-12.54-1.99-18.85
                c-0.44-7.81-0.7-15.46-4.51-22.72c-1.68-3.2-1.8-7.4-1.97-11.18c-0.47-10.12-0.6-20.25-0.82-30.38
                c-0.08-3.69-0.01-7.38-0.01-11.06c0.44-0.01,0.88-0.01,1.32-0.02c0.51,4.88,1.11,9.75,1.53,14.64c0.65,7.62,1.8,15.26,1.57,22.86
                c-0.21,6.71,0.82,12.81,3.52,18.89c1.24,2.79,1.93,5.97,2.17,9.03c0.75,9.62,1.1,19.28,1.76,28.91c0.11,1.52,0.79,4.03,1.69,4.28
                c4.57,1.25,9.56,3.23,13.93,2.42c5.59-1.04,10.73-1.3,16.18,0.02c2.63,0.64,4.52-0.15,4.11-3.67c-2.91-24.9-1.29-50.26-7.65-74.8
                c-1.92-7.39-3.86-14.77-5.91-22.12c-1.12-4.01-4.68-5.05-7.88-4.22c-1.22,0.31-2.37,4.44-2.03,6.59
                c0.86,5.4,2.74,10.63,3.95,15.98c2.75,12.18,5.17,24.35,3.84,37.03c-0.56,5.37,0.15,10.9,0.62,16.33
                c0.38,4.47,1.31,8.89,1.86,13.35c0.11,0.88-0.41,1.84-0.64,2.77c-0.57,0-1.13-0.01-1.7-0.01c-1.1-10.15-2.39-20.28-3.22-30.44
                c-0.46-5.59,0.55-11.36-0.34-16.86c-1.74-10.82-4.37-21.5-6.55-32.25c-0.66-3.24-1.52-6.55-1.47-9.81
                c0.08-4.96,3.37-7.14,8.01-5.64c2.66,0.86,5.17,2.2,7.72,3.32c0-5.79,0.26-11.6-0.05-17.37c-0.87-16.08-1.96-32.15-3.04-48.22
                c-0.19-2.8-0.38-5.69-1.22-8.33c-1.97-6.17-8.43-10.75-5.86-18.65c-1.28,0.82-1.98,1.4-2.18,2.12
                c-2.42,8.61-4.77,17.25-7.12,25.88c-0.61,2.23-1.14,4.48-1.73,6.72c-1,3.79-2.92,7.58-2.89,11.36c0.1,12.47,0.81,24.94,1.45,37.4
                c0.59,11.46,1.41,22.9,2.04,34.35c0.06,1.03-0.5,2.1-0.77,3.16c-1.49-4.94-1.81-9.71-2.14-14.48
                c-1.32-19.42-2.81-38.83-3.83-58.27c-0.22-4.18,0.93-8.54,2.04-12.66c2.43-9.01,5.23-17.91,7.88-26.86
                c-0.48-0.36-0.96-0.72-1.44-1.07c-1.28,1.27-2.78,2.38-3.78,3.84c-2.12,3.11-3.27,7.34-6.07,9.41c-5.05,3.73-4.15,8.09-4.12,13.21
                c0.07,10.73,0.21,21.68-1.8,32.12c-1.28,6.65-3.04,13.07-3.24,19.96c-0.27,9.08-1.43,18.14-2.24,27.2
                c-0.01,0.12-0.55,0.19-1.11,0.36c-0.19-0.98-0.59-1.94-0.52-2.86c0.97-12.73,1.99-25.46,3.02-38.18c0.05-0.66,0.25-1.31,0.47-1.94
                c4.06-11.62,3.73-23.6,2.64-35.57c-0.56-6.16-1.3-11.98,5.06-15.87c1.19-0.73,2.21-2.2,2.68-3.55c2.04-5.9,5.25-10.45,11.84-11.67
                c0.12-0.02,0.14-0.59,0.31-1.41c-7.36-1.93-8.1-8.48-9.89-14.52c-0.98-3.29-1.99-7.34-4.4-9.26c-5.31-4.24-7.67-9.75-9.59-15.78
                c-0.35-1.09-0.88-2.22-1.62-3.07c-1.73-1.96-6.4-1.06-6.48,1.36c-0.19,5.75,0.19,11.53,0.25,17.29c0.03,3.35,1.01,7.35-0.44,9.93
                c-3.08,5.44-0.62,8.33,3.18,11.33c5.09,4.01,6.85,12.77,3.74,18.92c-0.24-0.5-0.65-0.93-0.63-1.35
                c0.37-8.08-1.73-14.91-8.84-19.77c-2.7-1.85-2.32-4.66-0.93-7.62c1.23-2.62,2.29-5.65,2.21-8.46c-0.1-3.47-1.46-6.91-2.43-10.99
                c-0.65,2.79-1.1,5.19-1.78,7.52c-0.36,1.23-0.84,3.11-1.71,3.41c-7.98,2.77-7.8,10.01-9.3,16.35c-1.64,6.95-2.97,13.99-5.05,20.81
                c-1.35,4.45-1.59,8.38,0.89,12.41c5.68,9.2,11.46,18.34,16.81,27.72c1.42,2.5,1.77,5.78,1.97,8.75c0.51,7.21-5.85,9.59-9.9,13.93
                c-2.3-2.12-4.15-5.34-6.39-5.63c-8.19-1.08-10.61-6.93-13.08-13.33c-2.12-5.5-4.79-10.78-7.22-16.16
                c-2.25,2.05-4.09,3.28-5.34,4.96c-2.11,2.84-2.83,5.61,0.1,8.88c2,2.23,3.83,5.51,3.89,8.36c0.11,4.57-1.14,9.22-2.18,13.75
                C570.47,269.39,568.89,270.29,567.92,271.33z M929.52,1164.62c3.2,0.89,3.14,2.77,1.06,4.63c-6.44,5.76-12.88,11.53-19.57,16.99
                c-6.92,5.65-14.5,10.55-21.13,16.51c-7,6.3-14.06,12.2-23.07,15.39c-1.74,0.61-3.32,1.67-4.97,2.52c0.12,0.45,0.24,0.9,0.36,1.34
                c13.74,1.74,27.47,2.06,41.21-1.71c0.12,0.55,0.24,1.11,0.36,1.66c-2.73,1.5-5.46,2.99-9.19,5.04c1.97,0.16,2.77,0.39,3.51,0.25
                c9.97-1.8,20.14-2.92,29.83-5.7c9.87-2.83,19.24-7.37,28.89-11.01c1.41-0.53,3.14-0.21,4.73-0.28c-0.11,1.53-0.22,3.05-0.34,4.58
                c-0.05,0.56-0.14,1.12-0.33,2.65c3-2.24,5.1-4.14,7.48-5.54c7.68-4.51,11.4-15.48,22.76-13.97c11.01-7.05,23.3-12.54,29.56-25.21
                c3.91-7.92,8.38-15.48,15.13-21.47c1.83-1.63,3.45-3.72,4.56-5.91c2.84-5.59,4.69-12.02,12.58-12.46
                c0.26-0.01,0.57-0.36,0.75-0.63c4.47-6.58,9.55-12.91,7.82-21.77c-0.37-1.89-0.05-3.91-0.05-5.86c0.46-0.06,0.92-0.11,1.39-0.17
                c0.48,1.48,0.92,2.98,1.46,4.44c2.78,7.47,5.42,15,8.48,22.35c0.82,1.96,2.8,4.59,4.51,4.8c4.2,0.53,5.28,3.36,6.81,6.35
                c3.38,6.59,7.64,12.46,14.24,16.3c4.07,2.36,8.26,4.6,12.01,7.41c5.18,3.89,9.51,9.16,15.1,12.21
                c7.94,4.34,16.61,7.46,25.19,10.44c1.45,0.5,4.32-2.08,6-3.76c0.64-0.64,0.26-3.09-0.46-4.17c-8.09-11.97-16.26-23.9-24.62-35.68
                c-1.82-2.56-4.5-4.5-6.81-6.7c-3.75-3.59-7.76-6.44-13.37-6.31c-1.44,0.03-3-1.34-4.37-2.26c-3.87-2.6-7.66-5.31-11.5-7.96
                c-6-4.14-10.63-9.29-13.88-16c-3.08-6.37-7.16-12.25-10.99-18.66c-4.18,3.15-7.11,0.9-9.97-1.66
                c-8.39-7.5-13.86-16.94-18.15-27.18c-1.73-4.14-2.72-8.98-5.53-12.21c-5.09-5.85-5.1-12.34-4.77-19.21
                c0.1-2.01,0.01-4.03,0.01-7.68c-5.42,3.57-9.81,5.93-13.53,9.08c-2.79,2.36-4.87,4.93-7.86,1.38c-4.17,3.98-8.15,7.49-11.78,11.32
                c-2.45,2.59-4.89,5.38-6.53,8.51c-7.63,14.58-14.99,29.3-22.44,43.97c-0.82,1.63-1.59,3.28-2.35,4.94
                c-1.71,3.68-3.12,7.52-5.15,11.01c-4.25,7.31-8.92,14.39-13.24,21.66c-5.01,8.43-9.8,16.99-14.74,25.45
                c-1.59,2.72-3.37,5.33-5.05,8L929.52,1164.62z M134.5,275.42l0.07-0.06c0.18,1.15,0.48,2.3,0.5,3.45
                c0.05,2.04-0.07,4.08-0.12,6.12c-2.23-0.23-4.6-0.09-6.67-0.79c-2.7-0.91-5.17-2.49-7.98-3.91c-0.4,1.29-0.65,1.9-0.78,2.53
                c-1.35,6.93-3.79,8.22-9.53,3.93c-3.92-2.93-7.04-6.97-10.35-10.67c-4.22-4.71-8.27-9.58-12.39-14.38c-0.41,0.3-0.82,0.6-1.24,0.9
                c1.15,2.72,2.4,5.39,3.41,8.16c1.77,4.84,3.58,9.68,5.01,14.63c0.82,2.86-0.02,4.49-3.66,3.37c-4.28-1.31-7.51-3.6-10.32-7.14
                c-3.08-3.88-6.83-7.23-10.45-10.65c-1.18-1.11-2.85-1.71-4.29-2.55c-0.47,0.45-0.95,0.91-1.42,1.36
                c4.74,7.92,9.65,15.75,14.16,23.8c4.51,8.05,7.2,16.8,10,26.91c-4.82-2.42-6.45-0.3-8.32,2.46c-2.68,3.94-2.56,7.4-0.49,11.57
                c7.65,15.45,13.05,31.63,15.81,48.75c1.66,10.33,4.37,20.49,6.58,30.73c0.88,4.07,1.84,8.12,2.45,12.23
                c0.26,1.75-0.27,3.61-0.58,6.95c-2.29-2.47-3.82-3.57-4.6-5.05c-2.46-4.7-5.29-9.36-6.81-14.38c-3.46-11.46-6.19-23.15-9.29-34.72
                c-1.29-4.83-2.77-9.6-4.17-14.4c-0.34,2.34,0.41,4.36,0.81,6.44c0.26,1.36,0.48,3.78-0.08,4.05c-1.29,0.62-3.19,0.59-4.63,0.13
                c-2.3-0.73-4.42-2.03-6.9-3.22c-0.82,1.9-1.25,3.54-2.14,4.88c-2.44,3.67-4.48,7.98-7.83,10.56c-2.7,2.08-4.44,4.02-4.43,7.11
                c0.01,4.8,0.58,9.6,0.78,14.41c0.07,1.58,0.2,4.22-0.51,4.54c-1.41,0.63-3.6,0.44-5.09-0.24c-2.85-1.31-5.39-3.26-8.13-4.83
                c-1.25-0.72-2.88-1.91-3.97-1.6c-7.23,2.07-14.7,3.33-18.79,11.4c-2.95,5.83-7.27,11.15-11.76,15.95
                c-2.83,3.03-4.39,5.69-4.38,9.9c0.13,47.79,0.08,95.59,0.09,143.38c0,1.68,0.2,3.36,0.39,6.27c1.18-1.72,1.59-2.21,1.89-2.77
                c6.09-11.25,12.15-22.52,18.25-33.77c5.4-9.96,10.96-11.62,20.77-6.23c1.02,0.56,2.03,1.16,3.09,1.64
                c6.41,2.93,9.65,1.14,10.83-5.87c0.75-4.43,1.32-8.91,2.51-13.22c1.27-4.6,3.78-7.75,9.49-7.77c6.01-0.03,9.49-4.35,9.67-10.48
                c0.08-2.83-0.16-5.72,0.38-8.46c0.39-1.97,1.38-4.6,2.91-5.43c4.02-2.18,5.2-5.62,5.21-9.53c0-5.29-0.25-10.63-1-15.87
                c-0.95-6.58-2.65-13.04-3.77-19.6c-0.36-2.09,0.12-4.33,0.21-6.5c0.54-0.13,1.08-0.27,1.62-0.4c0.93,1.57,2.13,3.05,2.75,4.74
                c1.55,4.21,2.41,8.74,4.38,12.73c1.47,2.98,3.8,5.97,6.53,7.8c4.14,2.79,6.81,1.07,7.33-3.92c0.21-1.99,0.27-3.99,0.46-5.98
                c0.64-6.77,0.77-6.86,8.14-5.66c-0.79-5.8-1.35-11.37-2.35-16.87c-1.25-6.87-3.02-13.66-4.24-20.53
                c-0.47-2.65-0.08-5.46-0.08-8.19c0.62-0.11,1.25-0.22,1.87-0.32c5.17,6.29,6.99,15.32,15.1,20.04c0.41-1.45,0.8-2.2,0.81-2.97
                c0.1-12.38-0.46-24.61-5.77-36.18c-2.63-5.73-4.65-11.75-6.68-17.73c-0.63-1.85-0.39-4-0.55-6.02c0.43-0.19,0.86-0.37,1.29-0.56
                c1.05,1.11,2.17,2.17,3.14,3.34c2.22,2.7,4.12,5.72,6.62,8.12c2.3,2.21,5.22,3.77,7.86,5.62c0.74-3.07,1.7-6.1,2.15-9.21
                c0.36-2.45-0.19-5.02,0.16-7.47c0.4-2.76,1.39-5.42,2.07-8.14c0.66-2.64,2.22-5.52,1.69-7.92c-2.58-11.65-5.64-23.2-8.71-34.74
                c-1.36-5.13-3.25-10.12-4.67-15.24c-0.37-1.32,0.11-2.87,0.29-5.71c1.73,2.11,2.79,2.86,3.07,3.84
                c2.65,9.23,11.68,11.19,18.15,16.04c0.53,0.4,1.48,0.23,2.73,0.38c-1.46-6.89-2.31-13.5-4.32-19.73
                C141.14,290.95,137.62,283.25,134.5,275.42z M1153.47,1019.75l-0.06,0.08c-24.95-15.76-44.37-36.96-60.6-61.34
                c-0.76-1.15-2.36-1.7-3.43-2.69c-7.55-6.96-15.26-13.75-22.46-21.07c-1.97-2-3.46-5.44-1.39-9.13c2.15,3.66,3.57,7.33,6.05,10.03
                c4.83,5.26,10.14,10.09,15.51,14.82c1.45,1.28,4.38,2.69,5.62,2.06c3.79-1.92,6.34-0.03,9.23,1.6c2.6,1.47,5.15,3.28,7.96,4.06
                c1.8,0.5,4.74,0.06,5.93-1.16c1.87-1.92,1.22-4.23-1.14-6.33c-7.86-6.99-11.59-16.39-14.58-26.18c-0.46-1.51-1.32-3.04-2.38-4.22
                c-6.02-6.67-12.29-13.13-18.2-19.89c-2.71-3.1-5.29-6.5-7.08-10.18c-3.51-7.24-2.57-9.63,4.33-13.44
                c-1.84-2.44-3.66-4.85-5.48-7.27c0.43-0.36,0.86-0.73,1.29-1.09c2.48,2.33,5.1,4.54,7.36,7.07c0.68,0.76,0.95,3,0.4,3.56
                c-3.07,3.11-1.46,6.07,0.35,8.54c4.5,6.13,9.31,12.04,14.17,17.89c0.86,1.04,2.46,1.62,3.84,2.02c3.2,0.93,6.47,1.57,9.7,2.42
                c6.4,1.68,12.76,3.5,19.19,5.07c1.26,0.31,2.73-0.2,4.42-0.36c-1-3.16-1.78-5.64-2.77-8.76c6.5,1.06,11.18,5.85,18,3.85
                c-1.19-1.07-1.82-1.76-2.56-2.28c-5.84-4.13-11.78-8.11-17.53-12.35c-16.05-11.81-27.83-27.18-36.85-44.79
                c-0.91-1.77-1.46-3.73-2.42-5.48c-0.95-1.72-2.16-3.3-3.31-4.91c-3.46-4.81-7-9.56-10.39-14.4c-1.13-1.61-1.92-3.46-2.87-5.2
                c-1.75,12.89-2.23,25.41-5.53,37.64c-1.2-1.88-1.61-3.73-1.47-5.53c0.83-10.12,1.79-20.23,2.73-30.34
                c0.31-3.37,0.45-6.63-3.65-8.49c-0.21,0.84-0.45,1.43-0.49,2.04c-0.7,12.47-1.38,24.93-2.06,37.4
                c-0.36,6.54-6.82,13.88-12.18,12.6c-6.21-1.48-8.95,1.49-11.4,6.08c-3.41,6.36-10.65,7.85-15.67,12.18
                c-0.52,0.45-1.72,0.04-2.41,0.4c-1.84,0.98-4.13,1.76-5.25,3.32c-4.55,6.33-10.47,10.64-17.81,12.98
                c-1.4,0.45-3.05,0.06-4.54,0.06c5.77-3.54,11.52-6.26,16.24-10.21c4.52-3.78,6.85-9,14.07-10.78c5.26-1.29,11.61-5.86,14.59-12.48
                c1.59-3.54,4.6-5.99,9.25-5.23c7.41,1.21,11.14-2.12,11.47-9.72c0.22-5.15,0.55-10.31,0.9-15.45c0.48-7.03,1.04-14.06,1.62-21.87
                c-4.88,2.59-9.03,4.29-12.6,6.82c-3.66,2.6-6.7,6.08-10.12,9.04c-1.23,1.06-2.82,1.72-4.22,2.58c-2.11,1.3-4.22,2.61-6.32,3.93
                c-6.39,4.05-12.47,8.74-19.25,11.97c-6.15,2.93-8,8.61-11.88,12.96c0.2,0.41,0.39,0.82,0.59,1.23c2.79,0,5.58,0,9.09,0
                c-0.79,1.87-1.02,3.02-1.66,3.84c-4,5.04-8.14,9.97-12.12,15.02c-0.89,1.13-1.96,2.63-1.9,3.91c0.3,6.12-2.83,10.67-6.87,14.4
                c-13,11.96-26.11,23.85-43.78,28.82c-2.63,0.74-5.37,2.14-7.33,3.99c-5.06,4.77-11,6.65-17.73,6.79c-2.2,0.05-4.41,0.25-6.61,0.38
                c7.67,7.15,15.98,11.84,26.31,12.09c2.15,0.05,4.39,0.88,6.37,1.8c3.46,1.6,6.68,4.88,10.13,5.07c6.89,0.37,13.86-0.91,20.81-1.43
                c1.76-0.13,3.82-0.48,5.27,0.22c7.97,3.86,15.7,3.65,23.27-0.81c4.73-2.78,9.3-5.83,14.02-8.64c1.37-0.82,3.13-1.95,4.48-1.69
                c7,1.32,12.69-1.71,17.3-5.98c6.91-6.41,12.99-13.71,19.45-20.6c1.18-1.26,2.52-2.38,3.78-3.56c0.32,0.38,0.64,0.76,0.97,1.14
                c-8.47,13.34-17.62,26.01-33.46,31.75c-1.84,0.67-3.93,0.63-5.85,1.12c-2.32,0.59-4.75,1.08-6.85,2.17
                c-6.64,3.42-13.17,7.05-19.68,10.71c-1.92,1.08-3.91,2.27-5.39,3.85c-8.83,9.46-16.3,20.68-26.5,28.26
                c-22,16.33-47,26.94-74.23,31.59c-4.18,0.71-8.49,0.66-13.1,0.99c0.53,0.84,0.68,1.38,1.03,1.58
                c10.41,6.05,20.9,11.87,33.54,11.42c1.63-0.06,3.44,0,4.89,0.63c9.71,4.26,19.2,2.98,28.55-0.98c4.13-1.75,8.14-3.82,12.11-5.9
                c2.99-1.57,5.58-2.27,6.65,1.61c7.24-2.2,14.04-4.36,20.91-6.27c1.24-0.35,3.4-0.18,4.05,0.63c2.34,2.89,4.81,2.16,7.62,1.19
                c1.12-0.39,2.47-0.1,3.72-0.13c-0.01,1.25,0.01,2.5-0.04,3.75c-0.03,0.66-0.19,1.32-0.29,1.97c0.41,0.23,0.82,0.46,1.23,0.69
                c4.64-5.54,9.28-11.09,14.27-17.05c1.05,2.28,1.71,3.7,2.7,5.83c3.13-5.27,6.7-8.86,12.73-8.8c0.95,0.01,2.01-1.62,2.85-2.64
                c4.64-5.63,6.62-12.18,6.81-19.41c0.07-2.65,0.66-5.28,1.11-8.7c2.19,1.5,3.32,2.28,5.13,3.51c2.65-10.06-1.83-20.67,4.36-29.51
                c0.24,5.57-0.3,11.09-0.18,16.59c0.06,2.7,0.47,6.64,2.21,7.83c4.71,3.22,5.94,8.23,8.36,12.65c3.09,5.64,7.26,9.38,14.18,9.3
                c1.42-0.02,2.96,1.18,4.27,2.07c5.74,3.9,11.38,7.95,17.15,11.81c1.17,0.78,2.72,1.01,4.09,1.49c0.34-0.54,0.68-1.07,1.02-1.61
                c-1.7-1.31-3.49-2.52-5.08-3.95c-7.71-6.96-14.88-14.38-18.74-24.28c-0.94-2.4-1.11-5.09-1.63-7.65c0.83-0.51,1.66-1.03,2.49-1.54
                c0.4,1.08,0.52,2.42,1.25,3.21c5.5,5.95,10.89,12.02,16.78,17.56c3.12,2.93,7.04,5.03,11.88,2.96c1-0.43,3.01,0.35,3.99,1.22
                c6.37,5.58,14.23,7.78,22.09,9.9c1.08,0.29,3.52-0.92,3.81-1.88c1.08-3.68,3.17-3.13,5.79-2.14
                C1145.66,1017.07,1149.58,1018.38,1153.47,1019.75z M1143.37,807.95l-0.09,0.03c-5.77-4.78-11.32-9.86-17.38-14.24
                c-4.91-3.55-10.2-6.73-15.71-9.24c-12.72-5.8-24.59-12.59-33.2-24.01c-1.55-2.05-3.4-3.87-4.68-5.31
                c-3.63,4.84-6.96,9.23-10.23,13.67c-0.48,0.65-0.85,1.48-0.96,2.27c-0.74,5.69-3.53,10.3-6.25,15.32
                c-4.15,7.67-7.32,15.66-13.96,21.81c-2.84,2.63-4.26,6.74-6.73,9.86c-4.56,5.78-5.19,5.79-10.91,2.13
                c-0.38,0.28-0.91,0.49-1.16,0.88c-9.07,14.12-24.82,14.74-38.72,18.94c-2.45,0.74-5.5-0.51-8.28-0.84
                c5.64-2.04,10.88-3.18,16.09-4.43c1.6-0.38,3.1-1.43,4.7-1.57c9.54-0.84,16.97-5.3,22.9-12.68c2.75-3.42,5.79-6.53,9.99-1.26
                c1.41-1.55,2.84-2.6,3.57-4.02c5.28-10.24,16.05-16.94,18.61-28.96c0.29-1.38,1.64-2.5,2.28-3.84c1.4-2.93,2.72-5.9,3.95-8.9
                c1.05-2.57,1.61-5.38,2.96-7.76c2.41-4.24,5.27-8.21,7.9-12.33c0.83-1.29,1.5-2.68,2.22-3.97c0.96,0.43,1.35,0.49,1.55,0.71
                c1.12,1.23,2.26,2.44,3.28,3.75c13.36,17.2,31.15,27.23,52.11,31.97c2.8,0.63,5.87,0.09,8.81-0.01c2.6-0.09,5.21-0.16,7.78-0.54
                c2.17-0.32,4.63-0.55,6.32-1.73c2.29-1.61,3.96-4.09,5.88-6.18c-2.91-2.26-6.53-4.67-9.65-7.61c-2.34-2.21-4.55-2.87-7.62-2.07
                c-6.22,1.63-11.66-0.05-16.89-3.77c-9.42-6.7-19.12-13.01-28.72-19.46c-0.82-0.55-1.78-0.88-2.68-1.3
                c-12.63-5.97-20.7-15.81-24.51-29.17c-0.35-1.23-0.05-2.64-0.05-3.97c0.44-0.11,0.87-0.21,1.31-0.32c0.84,1.83,1.79,3.62,2.5,5.51
                c3.87,10.21,10.76,18.01,19.85,23.71c11.48,7.21,23.34,13.81,33.81,22.55c5.08,4.25,10.82,5.28,18.23,2.24
                c-1.83-2.1-2.83-4.36-4.45-4.96c-18.82-7-34.12-19.17-48.56-32.64c-1.31-1.22-2.31-2.76-3.45-4.15c0.46-0.49,0.91-0.98,1.37-1.47
                c4.18,3.32,8.81,6.21,12.46,10.03c14.61,15.26,33.61,21.8,53.1,27.48c0.53,0.15,1.15,0.02,3.06,0.02
                c-2.88-2.35-4.97-3.71-6.61-5.48c-2.23-2.4-4.91-4.83-6-7.76c-2.95-7.91-8.38-13.46-15.02-18.17
                c-5.97-4.24-11.97-8.45-17.83-12.84c-1.56-1.17-2.68-2.93-4-4.42c0.32-0.55,0.63-1.1,0.95-1.65c2.53,0.72,5.05,1.45,7.58,2.15
                c6.37,1.76,11.83,8.1,19.46,3.75c0.71-0.4,2.18,0.5,3.29,0.83c1.52,0.45,3.03,0.92,4.47,1.35c2.95-6.41,3.2-6.47,8.91-3.03
                c2.26,1.36,4.6,2.6,6.9,3.9c0.44-0.63,0.89-1.25,1.33-1.88c-4.2-3.56-8.03-7.76-12.68-10.56c-11.92-7.18-20.84-17.54-30.32-27.32
                c-1.33-1.37-2.02-3.36-3.6-6.09c3.32,0.54,5.14,0.84,8.17,1.33c-1.71-3.47-3.06-6.01-4.24-8.64c-1.56-3.46-2.21-6.61,4.09-6.41
                c-1.91-1.79-3.2-3.03-4.52-4.24c-11.62-10.75-23.32-21.46-31.52-35.24c-1.85-3.11-3.4-6.52-4.37-9.99
                c-0.99-3.55,0.65-4.68,4.04-3.23c3.05,1.31,5.98,2.96,9.12,4.02c4.01,1.36,7.62,0.55,9.96-3.37c2.43-4.07,2.02-9.15-0.95-12.12
                c-11-10.99-18.99-23.97-25.2-38.11c-1.17-2.68-2.07-5.48-3.1-8.22c-6.23,24.12-21.15,43.46-33.3,64.58c2.97,0.1,5.18-0.9,7.47-1.1
                c1.25-0.11,3.67,1.02,3.76,1.79c0.63,5.45,0.98,10.96-3.29,15.47c-9.02,9.54-17.53,19.61-27.08,28.58
                c-8.03,7.54-17.25,13.82-26.21,20.86c4.15-1.21,8.18-2.38,12.21-3.55c0.18,0.5,0.37,1,0.55,1.51
                c-15.76,12.29-29.74,27.18-49.97,33.04c6.52,0.61,12.65,0.86,18.76,0.56c12.97-0.64,24.43-7.82,37.48-8.72
                c4.09-0.28,7.9-4,11.95-5.91c1.51-0.71,3.33-0.74,5.01-1.09c0.14,1.73,0.81,3.63,0.32,5.15c-2.84,8.64-6.54,16.78-14.56,22.05
                c-2.62,1.73-5.1,3.68-8.25,5.98c5.99,2.63,0.6,5.28,0.64,8.03c0.1,7.15-3.94,12.34-8.92,16.6c-5.93,5.07-12.43,9.48-18.66,14.19
                c-0.95,0.72-1.83,1.52-2.74,2.28c0.1,0.45,0.21,0.91,0.31,1.36c3.22,0.27,6.57,1.24,9.65,0.68c11.41-2.06,22.83-4.22,34.04-7.12
                c13.13-3.39,23.72-10.21,27.87-24.25c0.7-2.36,2.04-4.56,3.27-6.72c0.47-0.83,1.48-1.37,2.24-2.03
                c-0.59,4.54-2.59,8.32-3.71,12.35c-3.55,12.78-14.18,17.65-24.62,22.94c-2.15,1.09-4.57,2.76-5.59,4.8
                c-6.88,13.8-15.08,26.3-29.5,33.41c-0.89,0.44-1.75,1.02-2.45,1.71c-6.31,6.17-14.1,9.25-22.57,11c-1.77,0.36-3.64,0.8-5.16,1.72
                c-12.65,7.67-26.33,11.77-41.1,12.53c-4.36,0.23-8.69,1.03-13.8,1.66c3.62,5.86,7.99,8.88,13.23,10.9
                c1.34,0.52,3.62,1.46,3.6,2.17c-0.15,8.43,6.83,7.33,11.26,7.55c7.22,0.37,14.52-1.01,21.8-1.57c2.08-0.16,4.2-0.18,6.27,0.02
                c3.71,0.34,7.48,1.6,11.07,1.19c5.25-0.61,10.35-2.41,15.54-3.58c1.74-0.39,3.7-0.89,5.33-0.47c5.69,1.48,10.53-0.4,15.12-3.3
                c7.54-4.75,14.99-9.64,22.45-14.52c0.95-0.62,2-1.38,2.53-2.34c3.56-6.37,9.88-9.03,16.02-11.71c5.8-2.53,7.89-6.15,6.25-12.35
                c-0.29-1.09-0.04-2.32-0.04-3.49c0.4-0.12,0.8-0.24,1.19-0.37c0.77,1.32,1.46,2.7,2.31,3.96c2.42,3.55,4.26,7.88,7.51,10.39
                c4.76,3.68,7.34,8.38,9.77,13.5c2.65,5.56,6.24,10.83,12.52,12.04c5.06,0.97,8.11,3.92,11.6,7.01
                c5.32,4.72,10.92,9.12,16.59,13.41c1.07,0.81,4.15,1.01,4.38,0.55c2.14-4.33,4.02-1.38,6.28-0.23c6.47,3.3,12.99,6.53,19.66,9.39
                c1.79,0.77,4.19,0.12,7.66,0.12c-3.98-4.57-7.38-7.63-9.7-11.37c-2.43-3.91-3.77-8.49-5.69-12.73c-0.64-1.4-1.28-3.17-2.46-3.89
                c-10.04-6.18-20.35-11.93-30.31-18.25c-13.83-8.78-21.8-22.12-27.61-36.98c-0.31-0.78-0.04-1.78-0.04-4.24
                c1.83,2.21,2.89,3.31,3.74,4.54c2.64,3.84,5.1,7.81,7.8,11.61c9.16,12.88,21.23,20.9,37.31,22.18c2.92,0.23,4.31-0.51,6.03-3.45
                C1138.21,813.66,1136.94,807.39,1143.37,807.95z M718.06,1357.61l-0.1-0.07c6.45,7.92,12.48,15.98,12.96,26.85
                c0.1,2.23,1.42,3.44,4.44,3.26c6.15-0.36,10.99-2.23,13.73-9.04c0.86,1.75,1.28,2.61,1.74,3.54c7.66-5.75,15.1-11.34,23.64-17.75
                c2.04,3.43,4.41,7.3,6.65,11.23c0.9,1.58,1.53,3.32,2.33,4.97c1.28,2.64,2.81,4.65,5.98,1.36c0.48,2,0.82,3.4,1.16,4.8
                c0.28-0.38,0.57-0.75,0.85-1.13c1.92,0.59,4.27,0.68,5.68,1.88c2.68,2.28,4.73,5.29,7.22,7.83c3.2,3.26,7.84,5.77,9.6,9.63
                c2.82,6.23,10.22,4.41,13.3,9.28c5.56-2.21,5.75,3.12,7.35,5.69c2.87,4.63,4.75,9.78,10.17,12.47c5.42,2.69,7.71,7.86,9.28,13.45
                c0.34,1.21,1.7,2.83,2.79,3c6.34,0.99,12.73,1.83,19.13,2.28c3.88,0.27,5.4-2.06,4.32-5.83c-2.44-8.47-4.92-16.92-7.39-25.38
                c-0.42-1.43-0.97-2.82-1.28-4.27c-2.4-11.41-3.05-23.57-7.57-34.07c-5.19-12.04-13.72-22.66-20.96-33.78
                c-3.07-4.71-6.69-9.05-9.81-13.73c-6.76-10.14-12.79-20.83-20.2-30.47c-6.95-9.05-14.72-17.32-19.38-28.05
                c-1.33-3.06-5.16-5.4-8.35-7.18c-4.19-2.33-9.56-2.92-13.21-5.79c-6.19-4.88-13.33-7.29-20.45-9.67
                c-12.12-4.06-24.68-4.9-37.32-2.8c-8.92,1.48-17.79,3.27-26.67,4.96c-2.16,0.41-4.29,0.98-7.41,1.71
                c7.95,8.2,17.52,14.33,17.62,26.66c-1.09-0.15-1.39-0.95-1.78-1.7c-5.05-9.97-12.68-17.72-21.55-24.22
                c-2.66-1.95-6.22-2.74-9.44-3.84c-3.34-1.14-6.74-2.3-10.2-2.85c-1.19-0.19-3.64,1.22-3.87,2.26c-1.31,6.05-2.14,12.2-3.14,18.39
                c6.98,2.06,7.49,2.76,4.84,7.16l0.11,0.09c-1.94-1.36-3.89-2.73-5.37-3.77c-2.02,0.94-3.63,1.69-5.26,2.46
                c-1.16-0.14-2.33-0.28-3.49-0.42c-0.01,0.31-0.02,0.62-0.03,0.93c1.21-0.19,2.41-0.39,3.62-0.58c0.98,1.86,1.9,3.76,2.96,5.58
                c2.21,3.81,6.11,6.91,4.7,12.18c-0.17,0.64,0.68,1.8,1.36,2.39c5.04,4.39,5.28,10.51,4.54,16.13c-0.51,3.86-3.61,7.37-5.55,11.04
                c-0.56-0.29-1.12-0.59-1.68-0.88c0.42-1.36,0.84-2.73,1.25-4.1l0.03,0.09c-2.64,1.28-3.34,0.44-2.6-2.26
                c0.59-2.15,1-4.35,1.49-6.53c-0.58-0.18-1.16-0.35-1.75-0.53c-3.5,9.27-4.38,20.06-14.73,26.13c0.22-1.65,0.38-2.82,0.54-4
                c0.34-0.7,0.68-1.39,1.01-2.09c8.4-10.11,12.28-21.83,11.77-34.87c-0.27-6.93-1.27-13.77-9.76-16.08
                c-0.88-0.24-1.05-3.02-1.6-4.61c-0.38-1.11-0.55-2.8-1.33-3.18c-8-3.92-15.88-8.72-25.41-4.57c-1.33,0.58-3.12,0.08-4.7,0.08
                c-0.01-0.4-0.01-0.79-0.02-1.19c4.71-1.32,9.42-2.63,14.12-3.95c-0.06-0.45-0.12-0.9-0.18-1.35c-0.84-0.29-1.76-0.96-2.5-0.79
                c-4.94,1.09-9.82,2.48-14.76,3.53c-3.94,0.84-7.9,2.74-11.98-0.05c-0.96-0.65-2.74-0.1-4.13-0.1c-0.1,6.83,0.76,7.38,6.37,5.04
                c1.57-0.66,3.39-0.72,5.79-1.19c-0.6,1.62-0.61,1.86-0.72,1.91c-0.59,0.27-1.18,0.54-1.8,0.72c-8.12,2.4-14.48,7.03-17.22,15.24
                c-2.02,6.06-4.21,12.42-4.34,18.68c-0.17,7.97,1.62,15.98,2.69,23.96c0.12,0.88,0.91,1.8,1.63,2.44
                c6.35,5.63,12.75,11.21,19.46,17.08c1.33-2.17,1.97-3.23,2.6-4.26c2.63,1.48,4.98,3.02,7.5,4.17c4.7,2.14,9.85,3.51,14.17,6.23
                c3.52,2.22,6.28,2.88,9.21-0.06c3.02-3.04,5.76-6.36,9.1-10.09c0.37,2.48,0.62,4.14,0.85,5.7c19.84-7.94,23.05-24.64,23.94-43.13
                c1.44,3.4,3,7.05,2.49,10.38c-0.89,5.8-2.4,11.71-4.87,17.01c-2.4,5.15-5.77,10.14-9.76,14.17c-3.97,4-9.42,6.49-13.87,10.07
                c-5.94,4.77-5.4,8.8,1.32,13.06c0.65-0.91,1.3-1.82,2.91-4.07c0.74,4.31,1.23,7.15,1.83,10.59c-2.64-0.99-4.14-1.55-6.33-2.37
                c0.87,2.99,1.45,4.98,2.03,6.97c-0.56,0.23-1.11,0.46-1.67,0.68c-3.08-5.51-6.27-10.97-9.11-16.6c-0.3-0.6,1.38-2.21,2.52-3.93
                c-2.99-1.23-6-2.42-8.96-3.7c-5.18-2.25-10.17-5.07-15.54-6.68c-4.84-1.46-10.07-1.73-15.16-2.19c-0.61-0.06-2.03,1.98-2,3.02
                c0.1,4.48,0.33,8.99,1.03,13.4c0.62,3.93,1.92,7.75,2.92,11.61c0.73,0.11,1.46,0.22,2.19,0.33c2.28-4.97,4.56-9.93,6.84-14.9
                c0.5,0.13,1,0.26,1.49,0.39c-0.55,3.07-0.31,6.63-1.82,9.13c-3.85,6.36-7.86,12.83-12.93,18.19
                c-10.97,11.58-19.23,24.12-19.75,40.7c-0.07,2.39-1.4,4.73-2.11,7.11c-0.12,0.39-0.02,0.85-0.02,1.37
                c1.36-0.71,2.53-1.33,4.96-2.6c-1.32,3.24-2.36,5.11-2.84,7.1c-1,4.23,2.93,5.77,6.86,2.72c-4.62-6.57-4.09-10.55,2.8-13.8
                c-1.04,1.99-1.63,3.22-2.32,4.39c-1.17,1.99-2.34,4.55,0.67,5.2c1.76,0.38,5.7-1.47,5.8-2.58c0.54-5.97,0.96-12.15-0.09-17.99
                c-0.93-5.16,1.92-11.08,8.08-11.15c-4.21,3.04-4.56,5.99-3.42,10.61c1.09,4.38,0.21,9.15,4.38,12.43
                c6.36-4.39,9.64-4.03,11.37,0.83c0.94-1.22,1.79-3.36,3.22-3.88c1.17-0.42,3.03,1.09,4.67,1.79c0.25-0.39,0.9-1.4,1.67-2.6
                c0.9,1.23,1.49,2.03,2.17,2.95c0.94-1.37,1.64-2.41,2.55-3.74c0.81,1.12,1.35,1.87,2.61,3.61c4.69-4.06,9.35-8.01,13.9-12.07
                c2.11-1.89,3.69-5.06,6.08-5.8c3.69-1.13,5.12-3.69,5.08-6.49c-0.11-9.24,5.29-18.18,1.23-27.69c-0.72-1.68,0.24-4.59,1.3-6.42
                c2.25-3.84,3.69-7.51,2.98-12.16c-0.36-2.38,1.01-5.03,1.61-7.56c0.66,0.02,1.32,0.05,1.97,0.07c0.42,1.69,0.97,3.37,1.24,5.08
                c0.67,4.27,1.12,8.57,1.9,12.81c0.13,0.69,1.43,1.19,2.23,1.73c1.02,0.68,2.23,1.15,3.08,1.98c3.69,3.62,6.78,8.12,11,10.89
                c11.29,7.41,15.2,19.43,17.75,30.97c2.84,12.83,2.19,26.45,2.81,39.74c0.13,2.76,0.97,4.03,3.64,4.15
                c7.48,0.32,14.96,0.61,22.44,1c3,0.16,3.92-1.22,4.27-4.08c0.55-4.43,1.63-8.8,2.65-13.15c1.14-4.85,2.48-9.66,3.95-15.3
                c5.71-1.93,8.54-9.39,5.85-16.41c-3.3-8.63-5.74-17.59-8.79-26.32c-3.03-8.7-6.4-17.29-9.48-25.97
                C717.73,1360.57,718.08,1359.02,718.06,1357.61z M111.74,1152.57l-0.32,0.04l0.41-0.09c5.38,5.9,12.91,7.23,20.01,7.67
                c21.36,1.32,40.55,10.23,60.58,16.17c6.63,1.97,12.69,5.98,13.16,14c0.22,3.75,0.96,6.59,3.81,9.19c1.74,1.59,3.11,4.05,3.65,6.35
                c0.42,1.8-0.11,4.42-1.25,5.86c-1.78,2.26-13.33,2.04-17.72-0.59c4.51-0.53,7.85-0.87,11.17-1.32c4.84-0.65,5.69-2.48,2.9-6.52
                c-1.02-1.48-2.67-2.53-3.69-4.02c-0.99-1.43-2.05-3.12-2.19-4.77c-0.81-9.33-3.44-12.82-12.26-15.65
                c-6.81-2.18-13.68-4.23-20.4-6.67c-11.63-4.21-23.42-7.41-35.84-8.28c-12.33-0.86-23.01-6.31-32.95-13.28
                c-13.05-9.14-27.31-15.32-43.23-16.78c-10.48-0.96-20.18-3.32-29.05-9.37c-5.77-3.93-12.33-6.35-19.63-6.47
                c-6.77-0.12-6.82-0.4-6.82,6.24c-0.01,122.4-0.01,244.81,0,367.21c0,6.36,0.23,6.59,6.38,6.59c34.63,0.01,69.26,0.04,103.88-0.06
                c2.41-0.01,4.99-0.53,7.19-1.51c10.94-4.88,21.7-10.18,32.7-14.94c4.03-1.75,6.55-4.28,7.85-8.33c3.16-9.81,6.1-19.7,9.65-29.36
                c4.4-11.97,5.93-24.18,4.66-36.81c-1.3-12.92-2.63-25.83-3.87-38.75c-1.12-11.68-4.59-22.59-11.59-32.03
                c-8.46-11.41-10.6-24.54-11.43-38.12c-0.27-4.42,1.25-5.28,5.43-3.24c4.04,1.97,7.92,4.3,12.09,5.93
                c1.23,0.48,4.24-0.47,4.64-1.48c2.03-5.11,3.78-10.54,1.38-15.98c-2.27-5.15-4.75-10.25-7.64-15.06
                c-10.74-17.86-22.39-35.07-36.4-50.59c-2.25-2.49-3.92-5.51-5.85-8.29c1.62-0.17,2.36,0.36,2.97,1
                c17.29,18.18,31.71,38.49,44.76,59.88c6,9.83,8.56,19.63,3.93,30.75c-1.29,3.11-2.84,4.41-5.94,3.43
                c-2.37-0.75-4.67-1.75-6.92-2.81c-2.9-1.37-5.71-2.92-8.56-4.39c-0.44,0.31-0.87,0.63-1.31,0.94c1.11,7.47,1.35,15.2,3.58,22.31
                c1.84,5.88,5.75,11.22,9.23,16.47c6.22,9.38,9.84,19.57,10.98,30.72c0.95,9.27,1.63,18.59,3.07,27.79
                c3.31,21.18,0.57,41.36-8.22,60.93c-2.65,5.89-3.76,12.47-5.75,19.36c1.65-0.39,2.29-0.49,2.9-0.7
                c15.21-5.35,30.59-10.27,45.55-16.24c8.24-3.28,16.81-7.13,23.39-12.85c10.23-8.9,18.83-19.66,28.25-29.51
                c1.77-1.85,3.97-3.44,6.25-4.59c3.65-1.84,9.42-9.44,8.96-12.85c-2.53-18.78-5.1-37.55-7.84-56.29c-0.26-1.76-1.58-3.51-2.78-4.97
                c-3.87-4.72-7.83-9.38-11.91-13.92c-3.29-3.66-6.33-7.81-10.31-10.54c-12.36-8.49-22.86-18.28-27.37-33.17
                c-0.53-1.74-1.4-3.39-2.07-5.09c-2.34-5.87-6.28-10.03-12.31-12.3c-23.66-8.88-40.32-26.52-56.14-45.18
                c-0.12-0.14,0.12-0.58,0.43-1.93c3.12,3.11,6.05,5.65,8.53,8.58c13.65,16.15,29.53,29.29,49.47,36.99
                c6.04,2.33,9.57,6.94,12.04,12.65c2.51,5.8,4.83,11.71,7.71,17.33c3.92,7.64,11.25,11.73,18.21,16.25
                c-2.55-4.88-5.93-9.16-8.55-13.86c-2.79-5.03-6.16-10.27-6.96-15.75c-1.32-9.08-3.49-17.79-8.07-25.52
                c-6.33-10.7-13.64-20.82-20.54-31.19c-1.11-1.67-2.21-3.33-3.32-5c0.41-0.3,0.82-0.6,1.23-0.9c4.38,4.92,9.09,9.6,13.07,14.82
                c10.19,13.36,18.18,27.77,20.77,44.78c0.76,4.96,2.29,10.2,4.92,14.4c8.21,13.09,17,25.83,25.85,38.51
                c2.04,2.92,5.28,4.98,7.84,7.57c1.34,1.35,3.13,2.82,3.47,4.49c3.47,17.12,7.49,34.18,6.88,51.85c-0.06,1.84,0.28,3.7,0.43,5.55
                c0.5,0.18,1,0.35,1.5,0.53c1.82-2.43,4.33-4.61,5.35-7.35c3.93-10.54,4.46-20.86-1.72-31.17c-2.63-4.4-4.26-9.88-4.71-15.02
                c-1.38-15.75-8.42-28.5-19.06-39.63c-3.33-3.49-7.23-6.53-10.08-10.36c-2.72-3.65-4.88-7.9-6.41-12.19
                c-1.44-4.02,0.23-7.27,4.94-7.96c3.77-0.55,7.59-0.8,11.35-1.41c5.99-0.96,6.67-2.09,5.73-7.98c-0.44-2.78-0.86-5.62-0.77-8.42
                c0.21-6.46,2.9-8.74,9.28-9.03c6.78-0.3,13.55-0.86,20.29-1.58c1.42-0.15,3.02-1.29,3.92-2.45c0.4-0.51-0.39-2.54-1.19-3.32
                c-9.8-9.63-19.05-19.84-31.26-26.8c-4.96-2.82-8.9-7.44-13.26-11.29c-2.74-2.42-5.21-5.19-8.14-7.34
                c-11.52-8.47-23.09-16.87-34.85-25c-2.26-1.56-5.4-2.31-8.2-2.52c-10.94-0.81-22.08-0.22-32.82-2.07
                C151.02,1161.5,131.43,1156.75,111.74,1152.57z M406.16,271.37l-0.08,0.06c-1.68-3.4-3.09-6.98-5.1-10.18
                c-10.23-16.24-24.41-28.84-37.68-40.65c-4.1,0.92-7.51,1.65-10.9,2.46c-1.22,0.29-2.7,0.46-3.55,1.23
                c-3.43,3.13-7.02,2.37-10.71,0.84c-1.77-0.74-3.47-1.65-6.32-3.02c0.77,3.63,1.45,6.13,1.78,8.68c0.17,1.35,0.2,3.43-0.57,4
                c-0.87,0.64-2.99,0.4-4.09-0.25c-2.83-1.69-5.66-3.56-8.03-5.83c-6.25-5.98-12.2-12.26-18.38-18.32
                c-5.62-5.51-12.77-8.2-20.43-10.67c2.83,10.33,6.4,20.05,9.9,29.8c1.14,3.16-1.19,4.69-3.46,4.83c-4.74,0.29-9.54,0.01-14.29-0.38
                c-2.87-0.23-5.68-1.12-8.52-1.71c-0.3,0.4-0.6,0.79-0.9,1.19c1.25,2.4,2.24,5,3.82,7.16c2.06,2.8,4.57,5.27,6.92,7.86
                c2.44,2.69,1.57,4.53-1.56,5.5c-2,0.62-4.12,0.85-6.95,1.4c2.62,7.38,4.94,14.4,7.61,21.29c2.66,6.87,8.84,12.1,10.32,19.54
                c-0.43,0.4-0.85,0.8-1.28,1.2c-4.13-1.92-8.26-3.85-12.38-5.77c-0.3,0.33-0.6,0.67-0.9,1c4.25,6.09,8.67,12.09,12.66,18.35
                c1.12,1.76,1.03,4.3,1.49,6.49c-2.43,0.09-5.33,1.08-7.2,0.1c-4.53-2.36-8.56-5.65-12.82-8.54c-5.74-3.89-11.5-7.76-17.26-11.64
                l0.09-0.1c1.19,1.96,2.12,4.15,3.6,5.84c6.92,7.88,14.02,15.6,21,23.43c1.54,1.73,3.06,3.56,4.24,5.54
                c2.95,4.94,1.37,7.94-4.4,7.26c-4.5-0.53-8.82-2.59-13.18-4.09c-1.55-0.53-3.01-2.09-4.46-2.03c-7.99,0.34-14.31-3.68-20.73-7.47
                c-10.03-5.92-20.01-11.92-29.97-17.94c-1.47-0.89-2.77-2.07-4.62-3.48c9.01-6.3,6.51-13.78,3.48-21.31
                c-0.31-0.77-0.6-1.56-0.99-2.29c-1.98-3.77-0.55-5.47,3.38-6.09c6.5-1.03,7.79-2.84,7.31-9.6c-0.49-6.93,0.28-7.87,7.22-8.69
                c0.83-0.1,1.66-0.21,2.49-0.19c4.51,0.07,5.15-2.32,3.49-5.8c-2.44-5.1-5.1-10.08-7.65-15.13c-2.02-4-4.13-7.97-5.92-12.07
                c-0.53-1.2-0.69-3.66-0.04-4.1c4.45-3.01,2.15-6.46,0.87-9.72c-1.28-3.24-3.12-6.26-4.45-9.49c-1.61-3.91-0.44-5.57,3.84-5.78
                c2.25-0.11,4.51-0.02,7.35-0.02c-0.67-2.22-1.14-4.11-1.8-5.94c-4.44-12.19-8.85-24.39-13.44-36.53c-1.3-3.43-0.43-5.3,2.93-6.53
                c7.68-2.81,8.93-5.45,5.95-13.33c-2.29-6.06-5.01-11.97-7.48-17.97c-4.52-11-10.25-20.26-22.46-25.07
                c-8.12-3.2-14.54-9.99-16.38-19.55c-0.09-0.49-0.77-0.86-1.9-2.05c-0.57,10.18-1.74,19.3,3.5,27.87
                c3.91,6.38,7.45,12.99,10.91,19.63c0.74,1.42,0.41,3.4,0.58,5.12c-1.63-0.43-3.56-0.46-4.81-1.39c-2.51-1.87-4.98-3.99-6.88-6.46
                c-6.46-8.43-10.54-18.05-13.44-28.22c-1.37-4.8-2.64-9.69-4.69-14.2c-1.83-4.03-3.4-8.67-8.65-10.66
                c-1.14,4.25-3.55,6.05-7.77,5.68c-0.99-0.09-2.91,1.68-3.12,2.83c-1.29,7.04-2.23,14.04,1.15,20.99
                c5.24,10.77,10.29,21.65,14.99,32.67c5.6,13.16,10.78,26.5,16.05,39.79c0.59,1.48,0.61,3.19,0.9,4.79
                c-0.53,0.27-1.05,0.54-1.58,0.81c-2.38-2.62-4.91-5.13-7.11-7.89c-12.34-15.53-17.81-33.99-22.71-52.77
                c-1.96-7.52-5.03-14.76-7.83-22.03c-0.63-1.64-2.24-2.9-2.78-3.57c-2.58,2.51-4.77,4.42-6.69,6.57c-1.17,1.31-2.28,2.87-2.83,4.52
                c-2.84,8.47,0.69,15.51,5.04,22.5c4.1,6.6,7.98,13.38,11.44,20.34c0.79,1.6-0.56,4.27-0.93,6.45c-2.22-0.76-5.34-0.85-6.49-2.4
                c-3.61-4.9-7.57-9.99-9.41-15.65c-4.2-12.95-7.11-26.32-10.81-39.45c-1.13-4.03-0.34-6.46,3.74-7.64c4.42-1.28,6.02-4.76,4.6-8.52
                c-1.14-3.02-3.72-5.84-6.35-7.85c-3.86-2.96-6.53-6.15-7.91-11c-1.39-4.89-3.4-9.95-6.41-13.98
                c-6.76-9.05-14.18-17.63-21.61-26.16c-2.8-3.21-6.01-6.02-10.99-5.96C39.64,2.07,23.49,2.03,7.34,1.9
                c-3.88-0.03-5.35,1.4-5.31,5.26C2.15,21.48,2,35.81,2.17,50.13c0.02,1.96,0.85,4.28,2.08,5.8c6.48,8,13.21,15.79,19.84,23.67
                c9.36,11.13,14.14,24.39,17.64,38.21c0.39,1.53,0.05,3.23,0.05,4.86c-5.61-11.25-9.36-23.14-15.44-33.69
                c-6.18-10.7-13.66-20.9-23.54-30.31c0,125.51,0,249.61,0,373.72c0.47,0.18,0.94,0.36,1.4,0.55c2.65-3.32,5.76-6.38,7.86-10.03
                c5.09-8.81,10.15-17.01,21.75-18.47c3.23-0.41,5.6-0.64,8.23,1.23c2.61,1.86,5.43,3.42,9.33,5.84c-0.41-4.76-0.9-7.99-0.93-11.23
                c-0.05-6.39-0.23-12.93,7.43-15.81c0.8-0.3,1.44-1.39,1.88-2.25c1.89-3.68,3.82-7.36,5.46-11.15c1.22-2.83,2.58-3.55,5.24-1.58
                c1.72,1.27,3.79,2.08,6.68,3.6c-1.44-8.17-5.17-14.71-3.06-22.15c2.67,2.97,4.96,6.07,6.09,9.54c2.86,8.86,5.17,17.89,7.75,26.85
                c2.99,10.39,5.94,20.79,9.09,31.13c0.64,2.11,2.13,3.96,3.23,5.94c0.38-0.19,0.77-0.38,1.15-0.57c-0.92-3.68-1.9-7.35-2.73-11.05
                c-2.07-9.25-4.57-18.44-6-27.79c-2.41-15.79-7.05-30.8-13.64-45.28c-1.31-2.88-2.76-5.72-3.73-8.72
                c-0.51-1.58-0.68-3.75,0.01-5.15c1.54-3.09,3.97-5.74,5.57-8.81c0.94-1.81,1.95-4.5,1.27-6.1c-3.49-8.24-7.39-16.32-11.39-24.33
                c-2.44-4.9-5.58-9.47-7.85-14.44c-1.01-2.2-0.66-5.02-0.92-7.56c2.39,0.73,5.29,0.84,7.07,2.3c4.84,4,9.13,8.66,13.82,12.86
                c1.89,1.69,4.25,2.85,6.4,4.25c0.47-0.43,0.94-0.86,1.41-1.29c-3.6-9.41-7.21-18.82-10.81-28.22c0.57-0.35,1.14-0.7,1.7-1.04
                c1.4,0.96,3.04,1.69,4.16,2.9c6.08,6.6,12.08,13.28,18.02,20c3.16,3.57,5.82,7.71,11.49,8.9c0.42-1.62,0.83-3,1.12-4.4
                c1.3-6.19,2.14-6.5,7.3-3.13c2.38,1.56,5.01,2.74,7.52,4.09c0.38-0.34,0.76-0.69,1.14-1.03c-1.92-6.17-3.84-12.35-5.76-18.52
                c0.82-0.65,1.64-1.3,2.46-1.95c1.02,1.84,1.84,3.83,3.09,5.5c11.92,15.83,15.05,34.95,19.83,53.42c0.22,0.84-1.81,3.21-2.79,3.2
                c-2.04-0.01-4.07-1.17-6.12-1.78c-1.03-0.3-2.79-0.92-3.06-0.55c-2.59,3.55-3.97,0.56-5.32-0.99c-1.86-2.12-3.4-4.52-5.08-6.79
                l0.09-0.04c0.74,2.53,1.28,5.14,2.25,7.58c4.8,11.98,8.28,24.27,8.89,37.23c0.09,1.91-0.36,3.91-0.91,5.76
                c-0.95,3.16-2.66,6.16-3.17,9.36c-0.6,3.74,0.07,7.67-0.38,11.45c-0.85,7.05-4.65,8.63-10.14,4.27c-3.07-2.45-5.78-5.35-9.75-9.06
                c1.45,3.79,2.17,6.48,3.45,8.87c6.87,12.88,8.83,26.79,8.56,41.11c-0.05,2.58-1.33,5.13-2.05,7.69c-2.7-1.32-5.7-2.25-8.01-4.06
                c-2.01-1.59-3.21-4.2-4.82-6.31c-0.59-0.78-1.33-1.44-2-2.15c0.34,5.62,1.74,10.73,2.67,15.93c1.1,6.21,1.92,12.48,2.62,18.75
                c0.43,3.91-0.7,6.43-5.38,4.33c-0.51-0.23-1.21-0.03-2.21-0.03c-0.24,3.35-0.3,6.49-0.73,9.59c-0.69,5.05-3.48,7.4-8.29,5.89
                c-3.26-1.02-6.08-3.66-8.87-5.85c-1.21-0.95-1.86-2.6-2.32-3.28c0.53,7.37,1.43,15.12,1.5,22.88c0.04,4.51-1.44,8.79-6.09,11.3
                c-1.28,0.69-2.15,2.87-2.43,4.5c-0.42,2.43-0.16,4.98-0.19,7.47c-0.08,7.03-4.78,12.63-11.85,12.92c-4.91,0.2-6.9,2.76-7.77,6.82
                c-0.97,4.55-1.44,9.21-2.38,13.77c-1.34,6.56-5.93,9.24-12.22,7.01c-2.8-0.99-5.34-2.68-8.04-3.98
                c-5.44-2.63-9.03-1.75-12.48,3.23c-1.32,1.9-2.28,4.06-3.37,6.11c-4.38,8.2-8.62,16.48-13.16,24.6c-5.27,9.42-9.38,19-8.61,30.22
                c0.51,7.46,0.45,15-0.02,22.47c-0.34,5.45,1.68,8.29,6.49,10.78c31.3,16.19,61.02,34.78,87.16,58.68c0.88,0.8,2.04,1.29,2.07,1.32
                c2.3-2.24,3.78-4.95,5.74-5.37c9.14-1.97,9.19-1.75,7.08-11.09c-0.04-0.16-0.08-0.32-0.11-0.49c-2.21-10.46-1.22-11.93,9.11-13.54
                c7.66-1.19,10.25-3.8,10.06-11.59c-0.12-5.11-1.4-10.19-1.99-15.31c-0.61-5.26-1.34-10.54-1.42-15.82
                c-0.05-3.66,1.75-5.86,6.18-4.79c1.33,0.32,4-0.83,4.53-2c2.45-5.41,3.83-10.98,3.39-17.22c-0.54-7.53,0.25-15.17,0.6-22.76
                c0.08-1.79,0.22-4.04,1.28-5.22c1.57-1.75,3.95-3.77,6.05-3.85c8.39-0.34,8.66-0.4,7.48-8.92c-0.46-3.3-1.29-6.64-1.09-9.91
                c0.26-4.24,0.58-8.78,2.27-12.57c5.31-11.92,6.87-24.41,7.53-37.24c0.31-6.03,1.02-12.25,2.86-17.96
                c4.09-12.69,14.67-22.71,15.42-36.87c0.03-0.62,0.41-1.29,0.79-1.82c4.53-6.34,5.91-13.65,6.67-21.18
                c0.72-7.17,2.79-13.64,8.93-18.27c1.47-1.11,2.87-3.15,3.12-4.92c1.28-9.14,9.2-15.95,8.79-25.63c-0.03-0.62,0.4-1.26,0.61-1.89
                c0.67,0.3,1.34,0.6,2.01,0.9c-0.63,7.46-1.23,14.92-1.95,22.37c-0.07,0.74-0.74,1.51-1.31,2.09c-3.87,3.98-4.64,8.91-5.14,14.2
                c-0.27,2.85-1.56,6.44-3.66,8.11c-3.91,3.12-5.56,6.81-6.1,11.47c-0.73,6.26-1.3,12.57-2.52,18.74
                c-1.35,6.84-3.62,13.49-5.02,20.32c-1.64,8-4.42,15.32-10.18,21.31c-4.57,4.75-6.69,10.57-7.52,17.05
                c-1.97,15.33-4.07,30.65-6.22,45.96c-2.08,14.86,1.49,25.96,15.3,35.11c16.1,10.67,30.06,24.59,44.88,37.17
                c1.62,1.38,2.9,3.21,4.15,4.97c0.67,0.95,0.94,2.18,1.39,3.29c-0.38,0.37-0.75,0.75-1.13,1.12c-2.52-1.64-5.23-3.04-7.52-4.95
                c-9.33-7.79-18.4-15.89-27.83-23.55c-5.28-4.29-10.7-8.74-16.77-11.59c-3.15-1.48-7.95,0-11.79,0.97c-0.79,0.2-0.36,4.18-1,6.27
                c-0.99,3.21-1.72,6.86-3.79,9.29c-5.01,5.9-6.05,9.4-2.05,16.04c2.73,4.54,6.35,8.53,9.37,12.91c0.94,1.36,1.23,3.17,1.82,4.78
                c-1.56,0.34-3.13,1.03-4.67,0.95c-3.57-0.17-7.13-0.71-10.69-1.1c-0.34,0.44-0.67,0.88-1.01,1.33
                c5.82,6.59,11.65,13.19,17.47,19.78c-0.36,0.48-0.72,0.97-1.08,1.45c-1.55-0.17-3.25,0.03-4.62-0.57
                c-4.87-2.1-9.84-2.11-15.04-1.98c-4.55,0.11-8.01,4.03-7.9,5.95c0.32,5.8-1.12,10.63-3.56,15.63c-0.75,1.53-0.05,3.97,0.46,5.86
                c0.99,3.69,2.16,7.35,3.54,10.92c4.62,11.96,9.43,23.85,14,35.83c0.65,1.7,0.41,3.74,0.58,5.62c-1.84-0.38-3.85-0.41-5.5-1.2
                c-5.01-2.42-9.87-5.14-16.02-8.4c2.08,10.74,3.89,20.03,5.58,28.79c4.54-1.01,8.36-1.84,12.17-2.7c1.27-0.29,2.71-0.37,3.75-1.05
                c5.42-3.56,11.17-3.44,16.72-1c4.99,2.19,9.6,5.26,14.4,7.9c2.22,1.22,4.49,2.34,6.74,3.51c-0.12-0.6-0.25-1.21-0.38-1.81
                c2.61-2.19,5.13-4.5,7.85-6.54c1.58-1.18,3.58-1.78,5.23-2.89c4.41-2.96,5.33-5.7,2.35-10.04c-3.27-4.76-6.83-9.56-11.12-13.36
                c-14.07-12.47-28.55-24.49-42.84-36.72c-1.4-1.2-2.61-2.64-3.91-3.97c0.17-0.45,0.35-0.9,0.52-1.35c1.84,0,3.71-0.17,5.53,0.03
                c13.38,1.45,23.71,9.06,34.03,16.79c5.72,4.29,11.54,8.47,17.61,12.24c4.85,3.02,10.54,0.7,11.61-4.91
                c0.72-3.76,0.21-7.92-0.5-11.75c-1.16-6.2,0.05-7.88,6.42-7.32c1.49,0.13,3.07,0.22,4.4,0.8c7.26,3.12,14.47,3.48,22.36,2.03
                c10.15-1.86,13.88-5.55,13.46-15.86c-0.13-3.04,0.11-6.4,1.27-9.14c4.42-10.38,6.09-20.9,3.31-31.97
                c-0.31-1.23-0.28-2.55-0.5-4.83c1.58,1.07,2.35,1.3,2.57,1.78c4.32,9.52,9.26,18.85,12.6,28.71c1.96,5.78,2.7,12.7,1.52,18.62
                c-1.33,6.7-1.36,12.81,0.75,19.07c1.6,4.73,3.43,9.37,5.28,14.01c1.33,3.33,0.25,5.03-3.21,5.59c-7.2,1.17-7.17,1.21-7.82,8.81
                c-0.23,2.65-0.76,5.29-0.85,7.94c-0.33,9.49,3.63,17.36,9.65,24.55c0.26-4.55-0.53-8.85-1.17-13.16c-0.49-3.25-1.13-6.53-1.12-9.8
                c0.01-4.51,1.63-5.35,5.47-3.02c5.73,3.47,8.87,8.99,10.77,15.14c6.52,21.11,17.63,39.8,29.97,57.88c1.13,1.65,2.4,3.24,3.26,5.02
                c0.59,1.23,0.59,2.75,0.86,4.14c-1.54,0.29-3.09,0.84-4.62,0.78c-1.72-0.07-3.42-0.69-4.98-1.03c6.47,9.2,13.1,18.07,19.1,27.35
                c6.02,9.32,11.41,19.05,17.08,28.6c-0.52,0.4-1.03,0.8-1.55,1.2c-0.99-0.67-2.18-1.16-2.95-2.03
                c-9.95-11.19-19.82-22.45-29.8-33.62c-1.26-1.42-2.85-2.82-4.58-3.49c-4.46-1.76-9.08-3.08-13.62-4.63
                c-2.4-0.82-4.76-1.76-7.95-2.94c1.13,4.21,1.97,7.34,2.81,10.46c-0.64,0.43-1.29,0.86-1.93,1.3c-3.57-3.49-7.35-6.79-10.66-10.5
                c-4.07-4.57-7.75-9.5-11.6-14.26c0,0,0.07-0.09,0.07-0.09c2.7,6.96,5.44,13.9,8.07,20.88c0.9,2.38,3.82,4.57,0.3,7.41
                c-3.43,2.76-6.71,5.45-10.81,1.6c-3.74-3.51-7.13-7.4-10.74-11.06c-2.07-2.1-4.27-4.06-6.41-6.09c-0.65,0.18-1.3,0.36-1.95,0.54
                c5.48,8.42,2.81,14.88-3.47,20.61c-0.76,0.7-0.8,2.16-1.28,3.22c-3.34,7.45-9.46,9.69-16.25,5.29c-3.99-2.59-7.26-6.28-10.9-9.43
                c-1.73-1.5-3.58-2.87-5.37-4.29c-0.47,0.33-0.94,0.67-1.41,1c0.7,3.18,1.15,6.43,2.14,9.52c2.75,8.56,5.77,17.03,8.55,25.58
                c0.59,1.83,0.61,3.85,1.01,6.57c-2.1-0.73-3.29-0.78-3.86-1.4c-33.93-37.02-59.32-80.32-89.78-120.99
                c1.01,3.78,1.87,7.04,2.75,10.29c0.38,1.42,1.61,3.5,1.08,4.16c-4.73,5.82-0.45,10.64,2.05,14.97
                c9.71,16.87,18.82,34.28,30.31,49.9c16.76,22.78,28.41,47.67,36.61,74.45c0.49,1.58,0.59,3.29,0.87,4.94
                c-0.42,0.23-0.84,0.46-1.25,0.69c-1.25-1.15-2.57-2.24-3.74-3.47c-9.87-10.36-19.65-20.81-29.62-31.08c-1.66-1.71-4-2.88-6.2-3.95
                c-13.58-6.63-27.31-12.76-38.06-24.12c-8.56-9.05-18.01-17.31-27.43-25.48c-11.16-9.68-20.54-20.65-28.7-33.03
                c-5.23-7.94-12.12-15.04-19.23-21.44c-18.43-16.59-37.4-32.59-56.16-48.81c-0.69-0.6-1.53-1.03-3.06-2.03
                c-0.15,2.49-0.33,4.24-0.34,5.99c-0.03,10.99,0.34,22-0.14,32.97c-0.3,6.8,0.84,12.25,6.81,16.37c2.95,2.04,5.17,5.16,7.69,7.82
                c20.04,21.14,38.99,43.4,61.97,61.53c14.7,11.6,26.32,25.93,37.04,40.98c4.28,6.02,8.27,12.89,9.96,19.96
                c2.64,11.06,6.75,21.18,12.85,30.62c4.97,7.68,9.8,15.47,15.1,22.91c12.04,16.92,27.39,30.12,46.27,39.87
                c0.04-7.25-0.25-14.95,10.77-13.44c0.63-7.1,4.19-10.65,11.71-9.45c-0.98-2.7-1.7-4.53-2.32-6.39c-2.38-7.1-2.76-14.17,1.54-20.65
                c1.94-2.93,2.14-5.33,1.13-8.7c-4.06-13.56,4.41-22.14,18.12-18.68c1.66,0.42,3.32,0.79,5.55,1.32c-0.42-1.99-0.7-3.1-0.89-4.23
                c-1.41-8.32,2.81-14.51,11.18-15.85c3.08-0.49,6.3-0.03,9.44-0.24c1.35-0.09,3.55-0.46,3.85-1.28c2.55-6.81,7.41-9.52,14.6-8.32
                c0-1.71,0.06-2.84-0.01-3.96c-0.58-8.79,2.99-14.77,10.94-18.28c16.82-7.42,33.46-0.46,39.63,16.75c1.03,2.89,2.21,3.55,5.1,2.51
                c8.25-3,16.68-3.1,24.97-0.26c3.67,1.26,7.07,3.29,10.72,5.03c2.96-4.73,5.62-9.25,8.59-13.54c0.98-1.42,2.64-2.79,4.25-3.26
                c3.95-1.15,6.01-3.16,6.67-7.49c0.7-4.61,3.98-7.24,8.95-6.8c2.42,0.21,4.81,0.77,8.09,1.31c-2.29-14.28-1.4-27.65,12.78-34.88
                c13.35-6.81,25.78-2.3,37.39,6.42c5.85-12.29,11.51-23.93,16.86-35.71c0.8-1.76,0.35-4.21,0.13-6.3
                c-0.57-5.29-2.49-10.71-1.81-15.8c1.44-10.84-2.07-20.46-5.8-30c-8.86-22.64-18.24-45.07-27.29-67.63
                c-3.03-7.57-5.76-15.26-8.45-22.95c-0.58-1.66-0.38-3.59-0.68-6.9c3.28,2.56,5.28,4.11,7.49,5.84c-0.69-6.7-0.75-13.08-2.16-19.16
                c-1.39-6.02-4.14-11.72-6.29-17.56c0.61-0.28,1.22-0.56,1.83-0.84c2.54,3.09,5.08,6.18,7.63,9.27
                c-2.16-13.59-7.53-26.25-8.22-40.34c1.49,0.39,1.87,0.39,2.1,0.58c0.51,0.41,1.01,0.87,1.39,1.4
                c14.12,19.85,25.13,41.41,31.36,64.87c2.32,8.75,5.83,16.77,8.92,25.08c0.46,1.25,1.22,2.39,1.59,3.66
                c0.28,0.95,0.16,2.02,0.21,3.04c-0.45,0.27-0.91,0.54-1.36,0.81c-5.28-10.28-11.31-19.86-22.06-25.18
                c4.69,10.37,3.68,22.62,13.46,31.22c8.78,7.73,11.74,19.49,15.72,30.3c2.51,6.81,5.44,13.46,8.41,20.72
                c2.87-3.03,5.05-5.48,7.39-7.76c3.45-3.38,5.13-7.25,5.86-12.2c3.01-20.58,6.44-41.09,9.98-61.58
                c2.97-17.21,6.85-34.27,9.33-51.54c2.04-14.26,5.59-27.65,13.92-39.6c7.36-10.56,12.39-21.97,11.54-35.32
                c-0.18-2.78,0.32-5.67,0.93-8.42c0.75-3.35-0.46-4.88-3.66-5.73c-5.62-1.48-10.81-3.62-12.18-10.29c-0.18-0.89-0.91-1.88-1.68-2.4
                c-4.76-3.26-9.6-6.41-14.35-9.56c0,5.37-0.1,10.58,0.03,15.78c0.11,3.95,0.12,7.98,0.88,11.83c1.44,7.33-3.88,13.82-11.09,12.22
                c-3.69-0.82-7.13-3.31-10.33-5.54c-2.42-1.68-4.15-4.32-6.44-6.24c-2.9-2.44-4.06-5.06-2.47-8.75c1.35-3.14-0.1-5.83-3.17-5.95
                c-8.98-0.35-13.03-6.87-17.94-12.78c-4.54-5.45-9.58-10.53-14.79-15.34c-4.14-3.83-8.91-6.97-13.34-10.49
                c-1.03-0.82-1.81-1.97-3.23-3.56c7.11-2.3,12.79-0.92,18.25,1.83c8.03,4.04,15.86,8.5,24.01,12.26c3.66,1.69,7.92,2.08,11.88,3.14
                c9.1,2.42,18.47,4.17,27.2,7.56c5.91,2.29,11,6.79,16.28,10.54c1.52,1.08,3.05,2.84,3.54,4.58c1.83,6.48,7.02,7.71,13.58,9.41
                c0.32-7.78,1.29-15.03,0.74-22.16c-0.73-9.43-4.84-17.11-14.33-21.09c-3.14-1.32-5.83-3.81-8.59-5.95
                c-2.48-1.92-2.83-4.19-0.95-6.96c1.27-1.88,2.51-4.05,2.85-6.24c0.84-5.37,1.69-6.15,6.66-3.52c3.04,1.61,5.9,3.78,8.39,6.16
                c2.36,2.26,4.13,5.13,6.49,8.14c1.27-2.02,1.74-2.42,1.74-2.82c-0.22-13.27-0.28-26.54-0.82-39.8
                c-0.32-7.93-1.58-15.81-2.08-23.74c-0.52-8.22-0.29-16.5-1-24.7c-0.77-8.94-6.79-14.11-17.38-14.93
                c-0.81,13.49,3.11,27.22-1.11,40.57c-0.9-2.35-1.44-4.72-1.49-7.1c-0.23-11.73-0.29-23.47-0.52-35.21
                c-0.03-1.62-0.61-3.25-1.04-4.84c-0.99-3.69-1.85-7.44-3.13-11.04c-1.42-3.97-3.28-7.79-4.9-11.7c-1.07-2.58-2.69-5.13-2.93-7.8
                c-0.66-7.42-5.57-14.16-12.89-16.48c-0.2,0.79-0.38,1.58-0.61,2.35c-5.13,17.85-6.77,36.69-15.62,53.51
                c-3.82,7.27-5.03,8.09-13.18,7.16c-3.05-0.35-6.94-0.3-9.25,1.31c-5.97,4.15-12.15,5.11-18.36,2.17
                c-10.66-5.05-21.71-4.28-32.83-3.42c-6.93,0.54-11.65,4.94-16.88,10.53c11.9,5.6,20.53,13.9,25.7,25.54
                c-6,0.49-9.54-3.6-13.73-6.25c-4.44-2.81-8.79-5.79-13.18-8.69c-0.41,0.39-0.82,0.78-1.23,1.17c1.21,1.3,2.28,2.79,3.67,3.86
                c4.33,3.32,9.51,5.84,13.04,9.84c7.58,8.57,14.28,17.92,21.48,26.84c2.66,3.3,5.71,6.29,8.84,9.71
                c-5.9-1.15-11.22-3.14-16.45-2.92c-4.68,0.19-7.8-1.1-11.01-4.02c-1.3-1.19-4.58-2.48-5.02-1.98c-3.15,3.51-5.32,0.48-7.86-0.63
                c-5.13-2.24-10.21-5.71-15.51-6.12c-4.39-0.34-7.54-1.42-10.6-4.08c-2.14-1.86-4.21-3.82-6.52-5.44
                c-8.75-6.12-17.59-12.92-28.73-12.61c-10.06,0.27-15.12-5.52-19.74-12.64c-2.43-3.75-1.05-6.46,3.04-8.19
                c5.7-2.4,10.3-0.9,15.34,2.33c4.31,2.76,10.42,6.56,14.3,5.35c8.61-2.69,15.54-0.65,23.1,2.45c3.39,1.39,6,2.77,8.1,5.96
                c1.45,2.2,4.28,4.41,6.79,4.81c4.71,0.76,9.64,0.22,15.2,0.22c-2.35-1.53-4.32-2.94-6.4-4.16c-4.34-2.54-8.94-4.76-8.3-11.12
                c0.09-0.88-0.95-2.2-1.82-2.78c-4.4-2.92-8.7-6.1-13.43-8.37c-7.18-3.46-10.46-10.35-6.38-17.11
                c6.4-10.63,13.26-21.02,20.48-31.11c8.85-12.37,18.28-24.32,27.85-36.97c-3.53-1.54-7.29-3.17-11.25-4.89
                c-1.64,8.13-1.5,7.76-8.34,5.19c-5.07-1.91-10.53-2.8-15.86-3.94c-0.95-0.2-2.52,0.27-3.11,1c-2.21,2.72-4.26,1.81-6.47,0.19
                c-2.68-1.97-5.29-4.06-8.1-5.82c-2.08-1.3-4.43-2.15-6.81-3.27c2.34,7.82,4.56,14.68,6.44,21.64c1.88,6.91,6.05,13.41,4.62,22.52
                c-2.26-2.45-3.63-3.8-4.85-5.28c-9.16-11.2-18.32-22.39-27.38-33.67c-1.32-1.64-2.62-3.61-3.03-5.61
                c-2.5-11.95,1.44-23.3,3.56-34.82c0.06-0.31,0.4-0.59,0.42-0.9c0.16-2.47,0.61-4.98,0.33-7.4c-0.68-6.06-1.88-12.07-2.44-18.14
                c-0.11-1.21,1.81-3.76,2.68-3.7c5.37,0.34,10.71,1.23,16.05,1.95c1.5,0.2,2.99,0.47,5.56,0.88c-3.18-6.55-5.81-11.99-8.45-17.42
                c0.61-0.38,1.22-0.77,1.82-1.15c1.51,1.57,3.23,2.98,4.47,4.74c1.81,2.58,2.84,5.82,4.98,8.04c3.11,3.22,6.59,7.46,10.48,8.17
                c4.81,0.88,8.84,2.15,12.56,5.13C396.15,263.65,401.17,267.49,406.16,271.37z M420.73,177.69c4.03,3.82,8.07,5.03,12.51,4.32
                c1.39-0.22,3.1-1.63,3.71-2.94c2.93-6.29,5.46-12.77,8.3-19.11c0.73-1.63,1.77-3.4,3.18-4.38c3.29-2.29,4.39-5.43,5.43-9.1
                c0.65-2.31,3.07-5.45,5.04-5.72c2.38-0.33,5.75,1.4,7.52,3.32c1.74,1.88,2.23,5.06,2.91,7.76c1.42,5.64,2.01,11.56,4,16.97
                c4.26,11.58,10.13,22.63,13.62,34.41c3.94,13.33,6.61,27.13,8.73,40.89c2.78,18.08,4.46,36.33,6.67,54.5
                c0.19,1.6,0.78,3.16,1.18,4.74c1.46-0.92,2.13-1.97,1.85-2.52c-2.14-4.17-0.58-6.72,3.1-8.97c1.95-1.19,3.38-3.23,5.06-4.9
                c-3.89-2.3-6.99-4.13-10.34-6.11c4.43-2.54,7.95-4.57,12.24-7.03c-3.66-2.14-5.94-3.47-9.86-5.77
                c10.14-2.18,12.73-8.58,12.85-15.96c4.12,3.96,7.9,7.59,12.08,11.61c-2.33,1.18-4.7,2.39-8.52,4.32
                c4.39,2.76,7.81,4.92,12.18,7.67c-3.81,1.86-7.08,3.45-11.23,5.47c2.21,2.26,4.49,4.12,6.14,6.42c1.15,1.6,2.49,4.22,1.92,5.68
                c-1.38,3.53-0.26,5.23,2.7,6.71c1.42,0.71,2.64,1.81,3.29,2.26c-1.26,4.78-2.32,8.83-3.39,12.88c0.39,0.23,0.79,0.46,1.18,0.69
                c0.72-0.84,1.56-1.61,2.14-2.54c4.55-7.37,4.06-15.42,3.74-23.68c-0.3-7.73-0.37-15.83,1.66-23.16c1.34-4.85,2.51-9.46,2.53-14.43
                c0.01-4-0.01-7.99-0.01-11.99c0-4.31,1.19-7.75,5.73-9.51c1.53-0.59,3.15-2.19,3.77-3.71c2.52-6.23,9.28-5.91,13.72-9.11
                c0.54-0.39,2.76,0.82,3.61,1.81c1.04,1.21,1.43,2.96,2.13,4.47c2.94,6.3,5.89,12.6,9.27,19.82c2.35-15.83,4.22-30.27,6.72-44.6
                c1.48-8.51,3.84-16.87,6.04-25.23c0.48-1.84,1.51-4.17,3-4.99c5.41-2.98,6.27-8.02,7.31-13.3c0.63-3.22,1.76-6.47,3.3-9.37
                c2.34-4.43,7.23-5.38,10.89-1.99c2.09,1.94,3.36,4.82,4.83,7.38c1.15,2,1.51,4.76,3.12,6.13c5.9,5,8.81,11.38,10.73,18.69
                c0.79,3,3.06,7.16,5.49,7.85c4.19,1.18,4.5-3.99,5.74-6.72c1.95-4.29,4.97-4.94,7.72-2.02c4.03,4.27,7.38,9.24,10.64,14.16
                c3.36,5.08,5.11,12.07,9.64,15.36c7.28,5.28,9.84,11.66,10.27,19.84c0.24,4.49,0.4,9,1,13.45c1.13,8.4,1.29,17.22,4.13,25.04
                c4.38,12.05,9.19,23.72,10.91,36.65c1.39,10.45,4.83,20.62,7.2,30.95c0.79,3.44,1.09,7,1.73,11.25c2.46-1.39,3.97-2.25,5.95-3.37
                c-1.45-1.06-2.5-1.6-3.24-2.42c-1.16-1.3-2.11-2.79-3.15-4.2c1.34-0.8,2.64-1.65,4.01-2.39c3.36-1.79,6.74-3.53,10.12-5.29
                c-0.1-0.43-0.2-0.85-0.31-1.28c-6.46-1.11-13.1-1.93-16.58-9.67c6.27,0.23,11.92,1.59,16.97-2.33c-2.63-1.83-4.78-3.33-7.88-5.48
                c6.81-2.64,13.72-1.27,18.25-6.75c-4.24-7.61-14.32-7.13-20.18-14.75c7.55,0.55,14.05,2.29,20.77-3.37
                c-3.77-1.72-6.66-2.85-9.35-4.33c-2.04-1.12-3.85-2.69-6.71-4.74c4.42-1.19,7.13-1.92,11.11-2.99c-4.93-4.26-8.8-7.6-12.68-10.95
                c0.29-0.51,0.58-1.01,0.87-1.52c6.77,0.12,13.71,1.43,20.21-2.34c-4.8-1-11.07,1.75-13.63-6.12c7.56-1.05,15.71-0.23,20.29-6.57
                c-4.65-3.1-9.02-6.02-13.4-8.94c0.22-0.47,0.44-0.95,0.65-1.42c4.81-0.86,9.61-1.72,14.42-2.58c-0.02-0.54-0.04-1.08-0.05-1.63
                c-3.28-1.32-6.57-2.65-10.71-4.31c4.83-1.86,8.48-3.26,12.13-4.67c-0.18-0.55-0.36-1.09-0.54-1.64
                c-3.51-0.46-7.72,3.03-10.59-1.15c4.21-3.56,8.21-6.93,12.2-10.31c-0.3-0.45-0.6-0.9-0.9-1.36c-3.24-0.61-6.49-1.23-9.73-1.84
                c0.03-0.49,0.05-0.98,0.08-1.47c5.53-2.26,11.09-4.49,13.39-10.37c-3.08-1.61-5.89-3.07-9.83-5.13c4.18-2.04,6.8-3.09,9.15-4.57
                c1.1-0.69,2.62-2.68,2.33-3.3c-2.02-4.22,1.15-6.04,3.37-8.37c1.21-1.27,2.35-2.6,3.41-3.78c-2.73-2.93-5.05-5.43-7.4-7.96
                c4.53-1.67,8.08-2.8,11.43-4.35c0.89-0.41,1.79-2.31,1.54-3.2c-0.24-0.87-1.88-1.53-3.03-1.94c-1.37-0.49-3.08-0.28-4.24-1.01
                c-0.68-0.43-1.18-2.7-0.75-3.11c3.46-3.39,7.16-6.53,10.61-9.59c3.02,4.86,6.22,10.01,9.65,15.54c-0.39,0.3-1.59,1.22-3.49,2.69
                c3.31,1.76,5.99,3.18,10.51,5.59c-2.57,1.02-4.26,1.69-5.95,2.37c0.25,0.52,0.49,1.05,0.74,1.57c2.89,0.91,5.79,1.83,8.68,2.74
                c0.11,0.44,0.22,0.89,0.33,1.33c-2.78,0.88-5.57,1.75-8.35,2.63c-0.04,0.5-0.09,1-0.13,1.5c3.22,0.74,6.45,1.47,10.85,2.48
                c-3.16,1.71-5.37,2.91-7.58,4.1c0.1,0.63,0.19,1.27,0.29,1.9c3.47,0.25,6.95,0.5,11.52,0.83c-2.99,2.43-5.2,4.22-7.41,6.01
                c0.15,0.47,0.3,0.94,0.45,1.4c4.48,0.52,8.95,1.05,13.43,1.57c0.19,0.39,0.38,0.79,0.57,1.18c-4.39,2.54-8.79,5.07-13.18,7.61
                c0.19,0.4,0.39,0.81,0.58,1.21c3.48-0.02,7.81,2.93,9.71-2.92c1.45-4.48,5.25-4.17,8.55-4.47c3.64-0.32,3.83,2.91,4.59,5.45
                c0.76,2.55,1.37,5.24,2.67,7.5c0.83,1.44,2.89,3.24,4.25,3.12c5.35-0.46,7.29,2.95,9.24,6.81c1.03,2.05,2.38,3.95,3.59,5.91
                c0.47-0.12,0.94-0.24,1.41-0.36c0.51-1.5,1.12-2.97,1.53-4.5c1.55-5.79,2.54-11.79,4.72-17.33c1.58-4.02,3.98-8.29,7.23-10.99
                c12.67-10.55,27.91-17.99,38.12-31.56c0.2-0.26,0.47-0.49,0.76-0.64c6.64-3.39,7.8-9.09,7.42-15.95
                c-0.52-9.53-2.38-19.36,3.9-28.04c0.6-0.83,0.47-2.26,0.52-3.42c0.34-7.98,0.93-15.96,0.86-23.94
                c-0.09-10.64-0.77-21.28-1.01-31.92c-0.05-2.09,0.29-4.44,1.24-6.26c3.62-6.93,7.43-13.77,11.49-20.45
                c0.81-1.33,3.21-2.76,4.57-2.51c5.76,1.09,11.22,2.87,14.31,8.77c0.96,1.83,3.17,3.46,5.18,4.18c6.06,2.19,9.62,6.34,11.13,12.44
                c0.68,2.75,1.03,5.6,1.98,8.24c0.56,1.54,1.78,3.65,3.09,3.97c5.32,1.34,8.08,5.11,10.1,9.62c2.52,5.61,5.4,11.18,7.01,17.07
                c6.03,21.98,11.8,44.03,17.25,66.16c1.77,7.18,3.5,13.86,10.92,17.29c1.15,0.53,2.15,1.61,2.94,2.65
                c13.78,18.04,24.45,37.69,29.68,59.91c1.44,6.14,3.88,11.33,8.69,15.27c8.62,7.07,14.02,16.34,18.54,26.27
                c0.67,1.47,1.48,2.87,2.91,5.6c7.92-5.95,15.68-11.07,22.56-17.18c5.59-4.96,11.55-8.23,18.85-9.43
                c16.73-2.75,33.45-5.52,50.15-8.47c7.07-1.25,7.05-1.46,7.05-8.71c0-75.11,0-150.22,0-225.32c0-7.6-0.02-7.61-7.87-7.61
                c-68.26,0-136.51,0-204.77,0c-237.23,0-474.47,0-711.7,0.02c-2.34,0-4.67,0.27-7.01,0.41c9.5,6.68,13.27,16.67,18.22,25.83
                c1.27,2.34,2.87,4.52,3.97,6.93c2.75,6.02,5.51,12.05,7.86,18.23c2.06,5.43,2.75,11.52,5.49,16.52
                c6.59,11.98,11.35,24.41,13,38.01c1.2,9.84,3.9,18.92,13.01,24.64c1.09,0.69,1.95,1.82,2.77,2.86
                c13.81,17.56,32.79,28.84,50.76,42.06c6.88-8.75,11.77-18.44,15.14-29.48c1.15,1.21,1.5,1.41,1.58,1.69
                c2.79,9.13,5.57,18.26,8.26,27.42c0.23,0.77-0.24,1.79-0.5,2.66c-0.25,0.82-1.17,1.79-0.94,2.34c1.6,3.81,3.56,7.48,5.11,11.31
                c0.76,1.87,0.89,3.99,1.3,5.99c-0.36,0.16-0.71,0.31-1.07,0.47c1.43,1.04,2.86,2.07,4.29,3.11c-0.51,1.72-0.31,3.2,0.54,4.02
                c3.15,3.09,6.94,2.12,10,0.23c0.7-0.43-0.82-4.47-1.35-7.02c2.07-1.02,4.47-2.21,7.66-3.78c-1.98-1.28-3.22-1.8-4.06-2.69
                c-1.19-1.26-2.09-2.8-3.11-4.23c1.31-0.82,2.56-1.81,3.96-2.43c1.78-0.79,3.68-1.28,6.5-2.23c-4.02-4.48-7.44-8.29-10.85-12.09
                c10.66-0.25,13.64-10.08,21.95-13.19c-4.73-2.28-10.15-0.75-11.93-7.11c6.35-0.73,9.86-4.27,10.4-10.44
                c-4.34-3.19-4.54-3.74-2.43-5.75c3.94-3.74,7.72-7.67,11.78-11.27c0.86-0.76,3.26-1.13,3.92-0.54c3.26,2.93,6.18,6.25,9.51,9.74
                c-1.47,2.62-3.14,5.58-5.02,8.92c2.2,0.76,4.17,1.45,5.99,2.08c1.63,6.83-5.47,8.18-7.39,13.01c4.21,4.63,9.31,7.92,16.53,7.14
                c-0.09,0.7-0.18,1.4-0.27,2.1c-4.87,0.88-9.73,1.76-14.62,2.65c2.16,1.94,4.49,4.05,6.83,6.15c-0.27,0.48-0.53,0.96-0.8,1.45
                C428.35,177.69,425.04,177.69,420.73,177.69z M943.08,709.87c-0.11-0.62-0.22-1.25-0.33-1.87c19.92-3.3,36.6-12.47,51.52-27
                c-4.24-0.26-7.43-0.45-10.62-0.64c-0.09-0.56-0.17-1.13-0.26-1.69c1.52-1.13,2.91-2.53,4.58-3.35
                c20.83-10.12,36.71-26.24,52.11-42.92c2.8-3.03,5.25-6.5,7.33-10.07c2.56-4.4,0.62-7.26-4.31-7.18c-1.64,0.03-3.33-0.16-4.93-0.54
                c-3.07-0.73-4.28-2.34-2.33-5.41c3.3-5.2,6.12-10.74,9.8-15.65c13.79-18.36,22-39.03,26.3-61.42c0.26-1.33,0.65-2.63,0.98-3.94
                c0.58-0.06,1.15-0.12,1.73-0.18c0.14,14.6,4.94,27.6,12.17,39.87c7.19,12.2,15.89,23.04,27.46,31.07
                c-2.97,2.14-6.15,3.25-7.22,5.4c-3.4,6.83-7.97,9.43-15.3,7.37c-2.49-0.7-4.9-1.66-8.83-3.02c6.5,12.66,14.43,22.33,23.71,30.87
                c9.21,8.48,18.81,16.53,28.23,24.78c-0.17,0.45-0.33,0.91-0.5,1.36c-4.69-0.94-9.39-1.87-12.88-2.57
                c0.44,5.34,0.83,10.68,1.36,16.01c0.07,0.72,0.64,1.55,1.22,2.05c11.79,10.06,23.61,20.08,35.43,30.11
                c0.49-0.28,0.99-0.55,1.48-0.83c0-1.94,0-3.87,0-5.81c0-107.92,0-215.85,0-323.77c0-1.5,0.3-3.14-0.22-4.45
                c-0.42-1.05-1.95-2.48-2.86-2.39c-8,0.78-14.78-2.4-20.99-6.67c-5.5-3.78-10.8-3.96-16.4-0.97c-8.5,4.54-16.92,9.24-25.38,13.85
                c-1.45,0.79-2.95,2.08-4.44,2.1c-9.53,0.1-17.04,3.32-24.42,10c-5.64,5.1-9.31,10.09-10.44,17.22c-1.35,8.54-1.93,17.24-3.8,25.66
                c-2.27,10.23-3.31,20.33-1.33,30.68c0.4,2.11,0.79,4.41,0.37,6.45c-1.11,5.3-2.25,10.67-4.17,15.71
                c-1.96,5.16-6.15,9.72-7.1,14.96c-2.12,11.72-2.97,23.68-4.08,35.57c-0.98,10.43-1.23,20.94-2.65,31.3
                c-0.42,3.08-3.11,6.79-5.83,8.42c-4.69,2.8-5.6,6.05-4.29,10.82c1.01,3.69,2.31,7.43,2.4,11.18c0.05,2.09-1.63,5.43-3.35,6.11
                c-4.08,1.61-8.63,2.45-13.04,2.73c-15.55,1-31.12-0.99-46.76,1.97c-13.06,2.47-17.51-2.67-17.43-16.08
                c0.07-10.98,0.25-21.98-0.1-32.95c-0.1-3.16-1.65-6.36-2.93-9.37c-1.33-3.13-3.63-2.69-5.35-0.4c-1.47,1.96-2.81,4.22-3.45,6.55
                c-4.85,17.81-9.4,35.7-14.28,53.5c-2.15,7.84-4.79,15.56-7.34,23.29c-2.79,8.48-6.01,16.84-8.55,25.39
                c-3.95,13.32-0.95,25.17,7.83,35.97c2.17,2.67,3.16,6.85,3.42,10.43c0.52,7.27-0.01,14.61,0.24,21.91
                c0.31,8.98,1.59,17.96,1.26,26.9c-0.19,4.88-2.38,9.89-4.56,14.41c-1.55,3.22-4.15,2.94-5.64-0.74
                c-1.56-3.85-3.46-7.59-4.74-11.53c-2.29-7.05-4.84-14.1-6.13-21.35c-1.38-7.77-3.4-15.04-9.1-20.63
                c-7.96-7.79-15.77-15.87-24.54-22.65c-6.04-4.67-13.29-8.64-20.61-10.52c-5.77-1.48-8.88-3.42-9.87-9.04
                c-0.49-2.78-1.12-5.54-1.7-8.31c-2.33-11.07-4.19-22.26-7.08-33.18c-8.62-32.58-17.38-65.06-13.11-99.37
                c1.29-10.38,0.44-21.17,2.87-31.21c2.45-10.09,1.67-19.06-2.55-28.07c-2.89-6.18-6.28-12.14-9.02-18.38
                c-1.07-2.45-2.08-5.84-1.21-8.06c3.25-8.25,3.95-16.76,4.3-25.44c0.1-2.53,1.43-5.01,2.19-7.52c0.6,0.05,1.19,0.09,1.79,0.14
                c3.93,9.61,1.24,21.3,8.09,30.45c5.21-6.81,14.56-9.42,26.57-8.1c2.11-6.37,4-12.69,6.31-18.85c1.41-3.78,4.08-5.85,8.6-4.83
                c3.72,0.84,7.58,1.01,11.37,1.59c6.56,1.02,8.89,3.65,9.14,10.26c0.33,8.81,0.47,17.63,0.99,26.43c0.3,5.06,2.12,9.63,7.48,11.35
                c8.23,2.64,14.4,8.03,20.32,14.02c7.7,7.78,15.33,15.71,23.72,22.7c4.15,3.45,5.83,6.95,4.33,11.53
                c-2.38,7.22-5.35,14.27-8.47,21.21c-0.39,0.86-3.9,1.44-4.82,0.71c-2.81-2.23-5.35-4.95-7.46-7.88
                c-4.09-5.67-7.73-11.65-11.7-17.41c-3.04-4.41-7.45-5.42-13.23-4.88c2.44,6.09,5.82,10.49,10.37,14.35
                c3.49,2.95,6.22,6.78,9.42,10.09c2.8,2.9,5.58,5.9,8.76,8.36c2.19,1.69,5.02,2.56,7.58,3.76c3.62,1.69,4.5,4.18,3.32,8.12
                c-2.96,9.88-5.68,19.85-8.18,29.85c-1.79,7.15-4.22,8.95-10.82,5.88c-3.98-1.85-7.7-4.62-10.91-7.65
                c-3.49-3.29-6.36-7.26-9.3-11.08c-4.97-6.46-5.27-13.38-1.75-20.65c0.75-1.55,1.81-4.04,1.15-5.02
                c-4.34-6.4-9.1-12.51-13.78-18.68c-0.23-0.31-0.93-0.35-1.42-0.37c-6.21-0.31-8.27-4.78-9.82-9.76c-0.89-2.85-1.41-5.86-2.64-8.55
                c-3.06-6.69-2.95-7.84,4.14-10.63c4.31-1.7,4.26-4.06,2.38-7.28c-0.91-1.56-1.96-3.16-3.3-4.35c-10.72-9.57-5.71-27.62,3.1-33.8
                c7.92-5.55,8.97-14.28,6.92-22.91c-1.61-6.77-8.44-4.2-13.1-5.39c-1.45-0.37-3.01-0.92-4.41-0.68c-1.61,0.27-4.05,0.96-4.46,2.08
                c-2.02,5.55-4.13,11.21-4.95,17.01c-0.77,5.45-1.47,6.56-6.65,4.62c-6.69-2.5-12.61,1.12-18.8,2.44
                c-0.81,0.17-1.36,1.76-1.96,2.73c-0.9,1.48-1.74,3-2.61,4.5c-1.58-1.35-3.88-2.39-4.61-4.1c-2.33-5.44-4.08-11.13-5.58-15.39
                c-1.33,4.81-3.29,10.92-4.59,17.18c-0.46,2.22,0.24,4.98,1.22,7.13c2.75,6.06,6.03,11.88,8.89,17.89
                c3.41,7.17,6.4,14.96,4.18,22.67c-2.93,10.15-2.34,20.43-3.52,30.63c-3.21,27.82-1.03,55.09,6.32,82.15
                c4.88,17.97,8.62,36.26,12.83,54.42c0.97,4.21,1.75,8.47,2.59,12.71c0.55,2.81,2.04,4.03,5.05,4.47
                c3.54,0.51,7.19,1.57,10.36,3.22c14.66,7.6,26.88,18.34,38.08,30.34c6.67,7.15,10.18,15.53,10.45,25.25
                c0.3,10.8,6.95,19.08,11.25,28.64c1.51-2.7,2.74-5.4,2.96-8.18c0.32-3.96-0.08-7.97-0.21-11.96c-0.44-13.28-0.69-26.57-1.49-39.83
                c-0.19-3.21-1.52-6.86-3.52-9.35c-7.4-9.23-10.94-19.52-9.1-31.17c1.74-10.99,3.91-21.88,9.57-31.87
                c2.82-4.97,4.29-10.81,5.81-16.41c4.47-16.38,8.51-32.88,13.03-49.25c1.63-5.9,2.75-12.83,6.59-17.02
                c5.28-5.77,5.61-12,6.21-18.68c0.31-3.48,0.7-6.97,0.77-10.46c0.25-13.18,8.98-22.04,15.92-31.86c1.45-2.04,2.85-4.47,3.2-6.87
                c1.27-8.72,1.97-17.53,3.12-26.27c0.83-6.27,0.25-12.11-2.41-18.01c-1.8-3.99-2.86-8.56-3.12-12.93c-0.28-4.7,2.27-6.37,6.98-5.9
                c5.41,0.53,8.36-1.73,8.68-6.98c0.45-7.48,0.78-14.96,1.01-22.45c0.79-25.79,1.38-51.6,2.3-77.38
                c0.41-11.59,0.69-11.47-10.9-11.63c-3.91-0.05-7.82-0.8-11.73-1.23c-0.2-0.72-0.4-1.44-0.6-2.17c0.85-0.47,1.72-0.9,2.53-1.43
                c4.94-3.26,9.81-6.65,14.83-9.78c1.63-1.02,3.65-1.83,5.54-1.95c15.68-1.01,27.48,8.88,29.31,24.41c0.17,1.49,0.27,2.99,0.27,4.49
                c0,9.15-0.68,18.36,0.17,27.43c0.62,6.63,2.73,13.27,5.2,19.49c1.05,2.64,4.63,4.37,7.27,6.24c2.66,1.88,5.45,3.66,8.39,5.01
                c2.52,1.16,5.51,2.66,8.01,2.25c9.32-1.53,13.09-5.93,13.97-15.39c0.98-10.44,1.54-20.91,2.69-31.33
                c1.02-9.24,2.41-18.45,3.99-27.62c1.12-6.49,2.91-12.87,4.21-19.33c0.3-1.48,0.28-3.37-0.4-4.64
                c-5.51-10.43-10.81-20.91-20.16-28.85c-3.71-3.15-6.79-8.03-8.2-12.71c-4.66-15.48-9.82-30.5-18.15-44.64
                c-8.08-13.7-18.52-23.87-32.13-31.76c-8.26-4.79-15.43-11.42-23.21-17.06c-2.37-1.71-5.01-3.63-7.77-4.12
                c-7.78-1.38-11.39-6.36-12.89-13.42c-0.79-3.73-1.29-7.56-1.56-11.36c-1.35-19.43-2.61-38.86-3.81-58.3
                c-0.28-4.48-1.37-7.87-5.4-10.94c-3.32-2.53-5-7.25-7.29-11.06c-0.79-1.32-1.23-2.84-2.05-4.79c-3.63,6.33-6.88,11.91-10.01,17.56
                c-0.53,0.97-0.73,2.24-0.73,3.36c0,6.49,0.07,12.98,0.17,19.47c0.17,10.81,0.58,21.62,0.46,32.42c-0.04,3.91-0.37,8.34-2.23,11.58
                c-2.75,4.8-3.66,9.54-3.2,14.79c1.4,15.81,2.84,31.62,4.24,47.42c0.75,8.47-0.31,16.59-4.49,24.18c-2.48,4.5-3.23,9.25-2.07,14.47
                c0.63,2.8,0.54,6.14-0.41,8.82c-1.4,3.95-0.44,6.3,2.45,9.12c4.19,4.08,7.45,8.98,8.6,15.11c-2.4-0.46-4.23-1.46-6.1-2.38
                c-5.19-2.55-8.92-1.71-13.39,2.94c-4.41,4.58-5.04,7.79-2.17,12.98c2.58,4.66,5.38,9.2,8.16,13.74c2.84,4.66,2.54,9.16-0.54,13.55
                c-1.34,1.91-2.79,3.74-4.07,5.69c-7.96,12.13-11.71,25.16-8.79,39.76c0.43,2.15-0.04,4.64-0.67,6.8
                c-2.4,8.21-3.87,16.37-2.96,25.08c1.13,10.87,1.07,21.86,1.9,32.77c0.23,3.08,1.11,6.56,2.88,9c4.63,6.37,9.2,12.52,10.41,20.7
                c0.39,2.67,2.26,6.14,4.49,7.34c10.24,5.52,20.79,10.47,31.35,15.38c2.77,1.29,4.63,0.09,3.93-3.32c-0.43-2.12-1.17-4.17-1.56-6.3
                c-1.08-5.96-0.4-6.86,5.53-7.98c1.37-0.26,2.72-0.68,4.73-1.19c-4.05-2.18-7.61-3.6-10.57-5.82c-3.04-2.27-5.24-5.65-8.28-7.92
                c-3.22-2.4-6.8-4.7-10.58-5.93c-8.02-2.61-10.78-6.5-9.23-14.79c1.35-7.2,2.61-14.41,4.17-21.56c0.59-2.72,1.51-5.54,3.05-7.8
                c0.71-1.05,3.76-1.75,4.87-1.1c10.77,6.31,21.07,13.11,22.58,27.24c0.19,1.81,1.42,4.69,2.67,5c5.64,1.39,11.44,2.92,17.83,1.29
                c-1.61-4.64-2.94-8.71-4.45-12.72c-0.99-2.62-2.23-5.15-3.71-8.5c-3.41,4.31-5.34,1.38-7.44-0.85c-2.96-3.15-5.51-6.91-9-9.28
                c-6.04-4.11-8.11-9.27-6.72-16.2c0.26-1.3,0.37-2.66,0.29-3.98c-0.13-2.31-0.47-4.6-0.71-6.9c-1.71,0.66-2.2,1.29-2.59,1.98
                c-1.78,3.16-3.11,6.79-7.68,6.86c-5.42,0.09-7.79-3.01-9.16-7.87c-1.74-6.18-6.47-9.02-12.61-9.7c-8.84-0.99-8.84-0.97-10.06-9.67
                c-0.12-0.82-0.21-1.66-0.4-2.46c-1.79-7.62,1.61-13.75,6.02-19.3c3.56-4.48,7.36-3.93,10.3,1.01c1.87,3.14,3.37,6.51,5.31,9.6
                c1.64,2.61,3.7,3.65,6.46,0.94c3.11-3.05,6.85-4.55,11.39-3.81c1.11,0.18,3.09-0.27,3.48-1.04c2.33-4.63,4.32-9.43,6.43-14.18
                c-0.31-0.31-0.62-0.62-0.93-0.94c-1.62,0.87-3.28,1.66-4.85,2.61c-3.53,2.13-7.12,3.7-11.3,1.84c-3.62-1.62-5.96-4.03-5.89-8.36
                c0.09-5.61,1.35-10.45,6.49-13.73c1.42-0.91,2.86-2.71,3.14-4.31c1.07-6.06,1.43-12.24,2.49-18.3
                c2.03-11.63,4.12-23.26,6.63-34.79c1.59-7.31,5.42-11.22,10.47-10.91c3.62,0.22,5-1.31,6.25-4.23c1.92-4.48,5.64-8.13,10.38-6.92
                c5.28,1.34,11.47,3.64,14.67,7.61c4.54,5.63,8.19,12.88,9.55,19.95c2.1,10.92,4.5,21.4,10.44,30.95c2.36,3.79,3.9,8.09,5.86,12.13
                c0.52,1.07,1.2,2.05,1.8,3.07c0.44-0.2,0.87-0.4,1.31-0.6c-1.82-4.55-3.69-9.08-5.43-13.66c-1.65-4.33-3.81-8.62-4.5-13.12
                c-0.41-2.66,0.74-6.75,2.7-8.29c2.61-2.05,2.56-3.78,1.42-5.81c-2.9-5.17-6.08-10.18-9.23-15.21c-2.17-3.46-4.96-6.63-6.62-10.31
                c-4.96-11.03-10.76-21.36-19.93-29.58c-4.89-4.38-10.03-6.31-16.79-5.65c-4.87,0.48-10.08-0.58-14.87-1.92
                c-4.99-1.39-9.63-4.01-14.49-5.93c-2.13-0.84-4.42-1.27-8.1-2.3c3.1-2.76,4.68-4.86,6.77-5.89c4.1-2.01,4.74-5.08,4.91-9.28
                c0.67-16.3,0.96-32.68,3.08-48.81c1.15-8.76,0.65-16.78-1.66-25.01c-0.76-2.72-1.27-5.51-1.73-8.3c-0.16-1,0.19-2.07,0.3-3.11
                c0.43-0.23,0.86-0.46,1.29-0.69c1.86,2.13,4.48,3.97,5.41,6.44c2.02,5.4,4.09,11.06,4.49,16.73c1.12,15.77,1.49,31.59,1.95,47.4
                c0.13,4.62,1.83,7.46,5.95,9.92c4.46,2.66,8.17,6.62,12.09,10.14c2.88,2.59,5.41,5.6,8.4,8.06c4.86,4,10.02,7.64,14.95,11.56
                c1.16,0.92,1.9,2.36,2.84,3.56c-1.23,0.36-1.77,0.1-2.26-0.25c-6.44-4.66-12.9-9.3-19.3-14.01c-1.73-1.27-3.99-2.42-4.82-4.19
                c-3.3-6.96-9.63-10.24-15.54-14.25c-2.15-1.46-4.24-4.39-4.58-6.9c-0.89-6.57-0.76-13.27-1.12-19.92
                c-0.65-11.96-1.1-23.94-2.17-35.87c-0.37-4.11-2.34-8.09-3.58-12.12c-0.47,0.17-0.93,0.34-1.4,0.5c0.99,3.5,2.23,6.94,2.92,10.5
                c0.81,4.2,1.83,8.54,1.57,12.75c-1.13,18.59-2.96,37.13-3.93,55.72c-0.27,5.27-0.66,9.43-6.22,11.46
                c-0.87,0.32-1.42,1.54-2.13,2.35c4.85,1.78,10.15,2.41,13.58,5.27c5.71,4.76,11.78,4.89,18.24,4.13c6.77-0.8,12.31,1.2,17.41,5.62
                c9.7,8.4,15.83,19.12,21.01,30.61c1.67,3.7,4.42,6.9,6.43,10.47c3.63,6.46,7.25,12.93,10.41,19.61c0.57,1.2-0.88,4.85-1.7,4.95
                c-4.17,0.51-3.86,3.3-3.34,5.87c0.62,3.07,1.82,6.03,2.85,9c2.5,7.2,5,14.39,7.61,21.54c0.54,1.46,1.28,3.44,2.47,3.97
                c6.5,2.92,10.14,8.44,13.69,14.11c8.96,14.28,11,30.92,15.75,46.63c0.95,3.15,1.37,6.46,2.04,9.69c-0.47,0.15-0.94,0.3-1.41,0.46
                c-2.16-4.5-4.37-8.98-6.46-13.52c-1.31-2.85-3.44-5.77-3.47-8.68c-0.12-16.62-11.48-27.53-19.66-40.06
                c-3.97-6.08-8.18-12.02-11.85-18.27c-2.77-4.72-4.44-10.1-7.33-14.74c-5.08-8.15-6.94-17.11-8.59-26.37
                c-1.89-10.59-5.08-20.44-16.66-25.24c-8.05-3.34-10.01-3.1-14.25,5.09c-0.88,1.71-3.24,3.47-5.1,3.7
                c-6.75,0.85-8.77,1.86-10.33,8.48c-2.2,9.39-4.13,18.86-5.67,28.37c-1.51,9.36-2.29,18.84-3.66,28.23
                c-0.2,1.35-1.95,3.62-2.83,3.55c-4.23-0.35-5.62,2.68-5.86,5.44c-0.29,3.34-0.05,7.49,1.76,9.97c1.17,1.61,5.64,1.39,8.5,1
                c1.91-0.26,3.46-2.81,5.42-3.28c1.99-0.48,4.37,0.11,6.41,0.76c0.47,0.15,0.73,2.68,0.26,3.75c-1.84,4.25-4.14,8.29-6.01,12.53
                c-1.23,2.79-2.85,4.54-6.08,3.69c-4.34-1.14-7.96,0.25-10.89,3.32c-3.31,3.45-6.3,2.69-8.78-0.6c-2.18-2.9-3.86-6.19-5.63-9.38
                c-2.75-4.97-4.98-4.98-8.21-0.29c-4.46,6.46-4.21,13.36-2.76,20.61c0.59,2.97,1.63,4.39,4.77,4.41
                c8.81,0.06,14.61,4.27,17.89,12.59c0.77,1.96,3.59,3.93,5.71,4.25c1.39,0.21,3.66-2.28,4.75-4.05c1.6-2.58,2.49-5.6,4.19-9.65
                c1.52,2.45,2.88,3.7,3.06,5.11c0.76,5.86,1.13,11.78,1.72,17.66c0.21,2.09-0.06,4.8,1.13,6.11c4.64,5.15,9.77,9.85,15.31,15.3
                c0.93-1.81,1.67-3.26,2.58-5.04c1.58,1.75,2.48,2.36,2.83,3.19c3.31,7.81,6.63,15.61,9.6,23.55c0.34,0.91-1.31,3.61-2.31,3.77
                c-3.23,0.51-6.6,0.01-9.88,0.27c-10.29,0.84-14.39-4.67-15.84-14.1c-1.76-11.48-12-14.81-20.61-20.65
                c-0.9,2.44-1.76,4.06-2.1,5.79c-1.6,8.16-2.89,16.39-4.63,24.52c-1.11,5.17,1.12,8.58,5.67,9.37c7.55,1.31,12.03,6.57,17.32,11.1
                c3.69,3.16,8.07,5.52,12.18,8.17c2.07,1.33,4.24,2.52,6.36,3.77c-0.12,0.57-0.24,1.14-0.36,1.7c-2.79,0.78-5.53,1.95-8.37,2.27
                c-4.33,0.49-5.23,2.48-4.18,6.42c1.08,4.04,1.69,8.2,2.68,13.16c-4.57-1.16-8.26-1.63-11.55-3.03
                c-9.19-3.89-18.22-8.17-27.32-12.3c-4.95-2.25-7.99-6.23-8.71-11.44c-1.06-7.66-4.92-13.71-9.8-19.28
                c-6.67-7.62-13.33-15.25-20.24-22.66c-5.44-5.84-7.6-12.36-5.51-20.16c0.73-2.73,1.41-5.48,2.2-8.2
                c2.79-9.65,2.59-19.25,0.8-29.13c-2.1-11.6-3.74-23.36-4.51-35.12c-0.64-9.85-1.4-20.03,4.75-29.04c0.73-1.07-0.35-4.12-1.52-5.42
                c-0.59-0.66-3.58,0.05-4.89,0.98c-1.98,1.41-3.14,4.08-5.19,5.29c-8.27,4.89-11.02,12.75-10.12,21.33
                c2.14,20.45,2.13,41.17,8.95,61.09c3.9,11.4,4.66,23.44-0.82,35.11c-1.36,2.9-1.59,6.46-1.77,9.75
                c-0.31,5.65-0.09,11.32-0.26,16.98c-0.05,1.63-0.64,3.25-0.97,4.82c-1.08-0.15-1.47-0.1-1.71-0.27c-0.54-0.37-1.14-0.76-1.48-1.29
                c-4.52-7.1-10.64-11.13-19.19-12.81c-8.44-1.65-11.37-8.29-8.19-16.39c3.43-8.74,3.54-17.09-0.77-25.52
                c-2.82-5.53-2.44-11.23-0.3-16.96c1.28-3.42,2.87-6.9,3.22-10.46c1.62-16.39,2.93-32.81,4.13-49.24c0.12-1.65-0.87-4.37-2.07-4.88
                c-1.36-0.58-3.93,0.31-5.27,1.41c-1.99,1.65-3.88,3.82-4.91,6.16c-2.68,6.07-5.17,12.25-7.19,18.57
                c-3.69,11.57-6.83,23.32-10.55,34.88c-0.85,2.63-2.71,6.3-4.84,6.91c-7.02,2.01-10.26,7.1-12.77,13.03
                c-7.84,18.52-13.3,37.69-14.8,57.82c-0.77,10.37-1.64,20.46-8.89,28.94c-1.64,1.92-1.86,5.69-1.6,8.5
                c3.14,33.17,4.71,66.4,4.18,99.72c-0.06,3.48-0.08,7.15-1.16,10.38c-4.32,12.91-4.07,26.21-4.22,39.55
                c-0.27,23.96-0.75,47.92-1.24,71.87c-0.04,1.93-0.37,4.11-1.33,5.72c-2.54,4.27-2.64,8.64-0.88,12.97
                c4.98,12.29,5.77,25.24,6.36,38.23c0.32,7.05,0.63,14.1,0.94,21.14c3.29-28.83,1.97-57.96,6.21-87.57
                c0.55,61.5-3.82,121.81-10.6,182.12c3.86-3.6,7.2-8.76,12.63-6.66c4.29,1.65,7.61,5.82,11.38,8.87
                c8.63-10.34,9.3-10.33,16.34,0.81c0.56-0.67,1.17-1.21,1.53-1.88c6.39-12.01,16.35-18.06,29.97-17.88
                c3.42,0.05,3.79-2.08,3.65-4.59c-0.4-6.81-0.8-13.63-1.38-20.43c-0.87-10.34-1.72-20.63,3.68-30.23c0.77-1.37,0.93-3.23,0.92-4.86
                c-0.16-22.29,0.89-44.69-0.95-66.85c-2.42-29.17-7.12-58.15-10.82-87.21c-0.31-2.42-0.49-4.86-0.74-7.3
                c0.45-0.07,0.9-0.15,1.35-0.22c0.49,1.14,1.13,2.23,1.44,3.42c6.71,25.79,12.97,51.67,15.46,78.3c0.81,8.62,4.7,15.71,10.51,22.12
                c7.59,8.37,14.61,17.25,21.97,25.83c3.24,3.77,6.12,8.29,10.19,10.81c7.28,4.52,11.32,10.87,14.48,18.45
                c1.89,4.53,4.16,9.44,7.68,12.6c3.86,3.48,5.16,7.05,4.93,11.73c-0.17,3.49,0.04,7.09-0.78,10.43
                c-3.13,12.76-6.62,25.42-9.92,38.14c-0.65,2.49-1.08,5.03-1.95,9.12c7.85-2.27,14.64-3.73,20.98-6.3
                c2.86-1.16,4.45-4.54,1.47-7.89c-0.87-0.98-1.52-2.2-2.08-3.39c-1.23-2.66-0.51-4.43,2.64-4c5.04,0.68,7.64-1.85,9.53-6.11
                c4.67-10.54,13.01-16.35,24.37-17.26c10.61-0.85,21.3-1.67,31.91-1.32c12.55,0.41,22.61-4.35,31.56-12.37
                c6.9-6.18,10.39-14.59,14.65-22.7c-5.8,0.63-11.06,2.42-16.42,2.84c-7.84,0.61-15.77,0.49-23.64,0.1
                c-1.85-0.09-3.58-2.53-5.36-3.89c1.56-1.32,3.1-2.67,4.69-3.95c8.37-6.75,17.29-12.93,24.93-20.42c4.92-4.83,3.62-9.9-2.29-14.9
                c5.68-4.22,11.21-8.26,16.66-12.41c5.74-4.37,8.18-10.57,10.68-18.07c-5.38,2.42-9.3,4.77-13.54,5.92
                c-4.25,1.15-8.82,1.09-13.25,1.6c-0.64,0.08-1.29,0.36-1.88,0.66c-16.23,8.34-33.53,7.15-50.85,5.75
                C946.45,710.91,944.77,710.28,943.08,709.87z M630.44,401.48c-10.3,8.74-21.09,15.74-26.73,26.82
                c-5.81,11.41-10.67,23.69-13.48,36.15c-3.43,15.19-4.14,30.99-6.33,46.48c-2.73,19.28-5.77,38.51-8.73,57.75
                c-0.12,0.78-0.81,1.47-1.24,2.21c-1.4-17.65,3.36-34.36,5.8-51.36c2.45-17.07,4.47-34.21,6.85-52.68
                c-1.27,1.68-1.9,2.21-2.16,2.88c-3.7,9.75-7.4,19.51-11.01,29.3c-2.51,6.8-4.94,13.63-7.32,20.48c-1.64,4.7-3.83,9.34-4.55,14.2
                c-1.64,11.02-3.3,22.14-3.52,33.25c-0.3,15.68-6.37,28.96-14.06,42.05c-3.31,5.64-6.05,11.91-7.57,18.25
                c-4.87,20.35-5.52,41.6-13.05,61.41c-0.64,1.68-0.74,3.58-1.02,5.39c-3.94,24.82-7.84,49.65-11.82,74.46
                c-0.46,2.84-1.3,5.63-1.97,8.44c-0.36-0.04-0.72-0.07-1.08-0.11c-0.3-1.81-0.61-3.62-0.91-5.42c-0.39-0.16-0.78-0.33-1.18-0.49
                c-1.5,2.43-3.75,4.67-4.38,7.31c-2.8,11.6-5.02,23.34-7.7,34.97c-1.02,4.44-2.3,8.91-4.22,13.02c-1.66,3.55-4.8,6.38-6.57,9.9
                c-3.5,6.98-5.97,14.53-9.88,21.25c-3.19,5.48-6.6,10.57-5.93,17.43c-15.35-8.23-15.96-8.14-23.78,3.07c-0.3,0-0.62,0.02-0.95,0
                c-3.13-0.24-16.99,8.82-17.95,11.73c-0.43,1.32-0.86,2.64-1.46,4.49c-2.54-5.11-0.6-9.67,3.92-11.85
                c5.03-2.43,10.23-4.78,14.68-8.07c4.14-3.07,7.39-7.34,10.97-11.02c-13.47-9.19-33.93-2.08-37.21,14
                c-1.03,5.06-0.02,10.55,0.16,15.83c0.07,2.06,0.43,4.12,0.78,7.2c-2.9-1.11-4.86-1.79-6.75-2.61c-7.09-3.08-10.08-1.5-11.85,5.89
                c-0.45,1.88-1.63,4.51-3.11,5.05c-4.57,1.67-8.88,3.01-9.15,8.99c-0.03,0.67-1.55,1.16-2.05,1.95c-1.42,2.26-2.79,4.57-3.94,6.97
                c-0.5,1.05-0.42,2.37-0.76,4.56c-11.76-10.66-24.43-13.7-38.49-8.15c-14.59,5.76-20.47,17.47-20.42,32.98
                c-1.54-0.56-2.37-0.7-3.03-1.12c-4.76-3.01-9.93-3.64-14.63-0.72c-4.89,3.04-4.59,8.44-4.24,13.54c0.12,1.68,0.02,3.37,0.02,5.53
                c-1.72-0.56-2.67-0.77-3.55-1.17c-3.67-1.67-7.56-3.84-11.19-0.66c-3.55,3.11-2.17,7.31-1.18,11.23c0.36,1.43,0.54,2.9,1.07,5.87
                c-3.86-2.47-6.18-4.36-8.83-5.51c-1.81-0.79-4.76-1.44-5.95-0.53c-1.48,1.13-2.16,3.92-2.29,6.04c-0.11,1.85,1.04,3.78,1.6,5.68
                c0.42,1.42,0.79,2.86,1.18,4.29c-0.49,0.22-0.99,0.45-1.48,0.67c-1.04-1.7-2.01-3.46-3.14-5.09c-3.87-5.56-7.15-11.72-11.86-16.47
                c-6.49-6.56-15.88-7.31-22.53-3.24c-7.07,4.33-10.04,12.64-7.77,21.94c2.11,8.62,7.36,15.16,13.9,20.85
                c1.22,1.06,2.33,2.23,3.5,3.35c-0.49,0.49-0.98,0.98-1.47,1.47c-3.65-3.45-7.5-6.72-10.9-10.4c-3-3.24-6.41-4.7-10.15-2.37
                c-3.88,2.42-2.86,6.27-1.39,9.85c0.5,1.22,0.93,2.47,1.4,3.71c-2.49-0.24-3.96-1.23-5.5-2.11c-4.99-2.86-7.57-1.36-7.52,4.35
                c0.01,1.98,0.83,4.4-0.03,5.85c-3.19,5.38,0.58,6,4.2,7.44c6.3,2.51,13.41,4.28,18.43,8.46c7.92,6.61,15.99,11.59,26.37,12.6
                c5.53,0.54,10.39,1.57,15.9-2.31c8.08-5.69,18-4.96,27.49-3.41c2.9,0.47,3.99,2.05,2,4.95c-0.91,1.32-1.13,3.13-1.76,5.01
                c5.72,0.81,8.59-2.14,11.87-5.16c3.03-2.79,7.09-6.46,10.58-6.35c7.35,0.25,14.63,2.71,21.92,4.31c0.13,0.03,0.14,0.6,0.27,1.19
                c-2.06,1.64-4.21,3.35-6.36,5.06c1.85,0.71,3.12,0.52,4.38,0.3c12.18-2.06,24.41-3.73,36.68-0.96c6.81,1.54,12.53,1.17,17.43-4.82
                c4.69-5.73,11.11-6.9,18.13-4.27c1.01,0.38,2.12,0.5,3.3,0.77c-1.01-11.23-0.08-12.66,10.3-16.25c0.47-0.16,1.12-0.19,1.38-0.52
                c1.32-1.65,3.52-3.33,3.62-5.11c0.29-4.94,2.72-7.36,7.27-7.96c2.89-0.38,3.72-1.88,3.98-4.65c1.07-11.57,8.38-18.33,19.96-18.8
                c10.46-0.42,19.23,8.5,17.39,18.73c-0.96-2.28-1.63-3.89-2.31-5.5c-3.44-8.08-9.85-11.63-17.83-9.89
                c-9.54,2.08-14.41,7.55-13.77,16.68c0.34,4.82-1.35,6.02-5.72,6.38c-1.98,0.16-5.52,2.56-5.41,3.65
                c0.73,7.47-4.42,8.62-9.62,10.34c-7.14,2.35-8.34,5.41-5.56,12.6c0.37,0.97,0.55,2.02,0.96,3.6c-2.89-0.58-5.16-0.97-7.4-1.51
                c-9.47-2.29-16.92,0.79-21.17,9.56c-3.19,6.58-4.33,14.26-11.33,18.7c2.36-0.09,4.29-0.53,6.21-0.99
                c15.85-3.83,31.64-7.42,47.92-1.63c5.88,2.09,10.75-0.55,13.43-6.42c3.11-6.81,6.37-13.61,8.79-20.68
                c3.82-11.17,12.36-17.05,22.48-21.18c4.59-1.87,9.58-2.74,14.36-4.12c6.36-1.84,13.19-2.81,18.97-5.79
                c16.29-8.39,33.83-12.94,51.25-17.86c6.24-1.76,11.91-3.98,16.59-8.57c9.38-9.21,18.83-18.35,28.28-27.48
                c5.22-5.04,11.2-5.72,18.02-3.54c12.67,4.06,25.42,7.84,38.18,11.58c5.82,1.7,9.6,5.08,10.77,11.16c0.57,2.94,1.1,5.9,1.9,8.79
                c3.28,11.85,6.63,23.68,10.03,35.49c0.33,1.13,1.25,2.1,2,3.32c0.98-0.71,1.64-0.99,2.01-1.48c7.27-9.61,16.97-12.71,28.68-11.24
                c2.43,0.31,5.08-1.06,7.62-1.7c7.03-1.76,14.08-3.42,21.05-5.4c2.56-0.72,5.54-1.62,7.23-3.46c9.34-10.12,18.31-20.57,27.48-30.85
                c1.76-1.98,3.54-4.27,5.82-5.38c5.95-2.9,7.94-8.16,9.46-13.9c2.91-10.94,5.54-21.96,8.8-32.79
                c4.42-14.68,13.32-26.68,23.76-37.64c3.3-3.46,6.67-6.9,9.57-10.69c1.11-1.45,1.99-4.85,1.23-5.61c-1.64-1.65-4.86-3.49-6.64-2.89
                c-4.4,1.49-8.41,4.22-12.47,6.62c-1.38,0.82-2.46,2.16-4.54,4.04c-0.41-2.89-0.59-4.49-0.86-6.07c-0.86-5.19-3.56-6.42-8.07-3.65
                c-1.12,0.69-1.96,1.98-3.14,2.41c-1.63,0.59-3.78,1.36-5.15,0.76c-7.67-3.35-10.84-2.41-14.67,5.09
                c-1.14,2.23-2.09,4.57-3.13,6.86c-0.51-0.24-1.01-0.47-1.52-0.71c0.07-5.48,2.83-9.86,6.79-13.66c3.25-3.12,6.9-3.38,11.07-1.68
                c1.67,0.68,4.31,0.94,5.69,0.08c8.42-5.26,9.69-5.2,15.99,2.41c2.56-1.47,5.02-3.18,7.72-4.37c8.72-3.85,8.76-3.77,7.18-13.5
                c-0.37-2.3-0.71-4.61-1.32-6.85c-4.12-15.07-18.13-21.77-32.47-15.76c-10.48,4.39-15.72,12.84-18.67,23.21
                c-0.44,1.55-0.95,3.08-1.42,4.62c-0.55-0.09-1.1-0.19-1.64-0.28c0-2.22,0.19-4.47-0.05-6.67c-0.27-2.46-0.65-4.99-1.52-7.29
                c-0.57-1.53-1.98-3.67-3.19-3.8c-1.36-0.14-3.49,1.36-4.27,2.73c-1.83,3.22-3.06,6.78-4.93,11.14c-1.03-2.05-1.73-3.58-2.55-5.04
                c-1.44-2.59-2.57-5.47-4.5-7.63c-4.6-5.15-9.25-4.45-12.76,1.59c-1.03,1.77-1.88,3.63-3.18,6.15c-5.61-8.44-7.06-0.81-10.38,2.66
                c0.5-16.91-2.82-32.62-20.5-35.62c-17.71-3.01-27.01,10.04-32.59,25.97c-0.82-2.87-1.02-5.38-0.47-7.71
                c0.76-3.2,2.73-6.21,3.05-9.41c0.83-8.27,1.25-16.6,1.46-24.92c0.85-32.43,1.44-64.87,2.44-97.3c0.17-5.41,1.04-11.12,3.06-16.09
                c4.2-10.33,4.67-20.64,2.54-31.35c-0.49-2.43-0.7-4.96-0.68-7.45c0.2-23.96,0.52-47.93,0.7-71.89
                c0.12-14.82,0.07-29.63,0.07-44.45c0.01-16.29,0.05-32.58-0.04-48.87c-0.08-14.3-0.04-28.6-0.57-42.88
                c-0.76-20.51,0.23-40.63,10.13-59.27c0.74-1.4,1.37-2.87,2.37-5c-7.91,1.43-12.59,5.58-15.87,11.77c-1.13,2.13-2.43,4.7-4.37,5.78
                c-12.37,6.9-19.8,17.32-23.27,30.86c-1.08,4.19-2.43,8.31-3.77,12.43c-0.29,0.9-1.06,1.64-1.61,2.45
                c-0.46-0.2-0.92-0.39-1.37-0.59c2.96-9.91,3.89-20.54,9.7-29.43c3.69-5.64,7.88-11.69,13.35-15.24
                c6.28-4.08,8.45-10.23,12.17-15.95c-3.28,0.67-6.41,1.59-9.33,2.95c-2.83,1.32-5.6,2.89-8.1,4.75c-2.42,1.8-4.45,4.11-6.65,6.2
                c-0.35-0.41-0.71-0.82-1.06-1.23c1.65-2.33,3.3-4.67,5.07-7.18c-5.32-2.51-9.9-2.51-15.01-0.88c-6.85,2.18-11.16,6.9-15.23,12.27
                c-1.97,2.6-4.28,4.95-6.43,7.42c-0.34-0.27-0.68-0.54-1.02-0.81C630.44,404.38,630.44,403.47,630.44,401.48z M219.04,1168.38
                c3.01,2.59,4.32,3.83,5.74,4.94c10.1,7.87,20.28,15.63,30.31,23.58c7.67,6.08,15.18,12.35,22.72,18.59
                c5.1,4.23,10.65,8.07,14.99,12.99c4.19,4.74,8.58,8.48,14.38,11.13c4.71,2.15,9.48,5.09,12.96,8.86
                c6.06,6.54,11.13,14,16.64,21.05c9.51,12.19,15.32,25.13,12.41,41.41c-2.46,13.75-12.55,21.39-21.01,30.53
                c-0.42,0.46-1.16,0.84-1.77,0.87c-6.89,0.25-10.46,5.74-15.37,9.28c-9.86,7.1-15.95,16.63-16.84,28.31
                c-1.13,14.81-13.16,22.16-20.02,33.01c-0.4,0.64-1.31,1.28-2.04,1.35c-5.2,0.5-8.11,4.35-11.17,7.76
                c-5,5.57-9.7,11.41-14.53,17.13c-9.12,10.81-19.97,19.45-33.82,22.85c-28.56,7.02-54.37,20.71-81.04,32.33
                c-1.59,0.69-3.08,1.61-4.61,2.43c0.1,0.31,0.19,0.63,0.29,0.94c186.73,0,373.46,0,561.2,0c-12.42-3.48-14.27-4.69-14.15-8.42
                c3.05,1.54,6.28,3.16,9.5,4.78c-1.27-3.51-3.21-5.81-4.67-8.4c-3.77-6.67-1.46-12.19,5.99-14.15c8.06-2.13,16.21-2.44,23.97,1.07
                c2.97,1.34,4.98,1.07,7.7-0.71c7.71-5.04,18.22-1.78,20.61,6.38c0.78,2.66,0.12,5.75,0.12,9.88c1.76-1.96,2.97-3.31,4.6-5.12
                c2.42,3.62,0.54,5.5-2.49,6.63c-2.46,0.92-5.82,0.53-7.54,2.08c-3.59,3.26-7.47,3.16-11.65,2.83c-4.42-0.35-8.71-0.14-12.58,2.88
                c0.52,0.35,0.66,0.53,0.79,0.53c33.96,0.05,67.92,0.15,101.87,0c2.35-0.01,4.69-1.86,8.17-3.34c-4.29-2.44-7.16-3.76-9.63-5.61
                c-1.13-0.85-2.25-2.94-1.96-4.11c0.26-1.04,2.4-1.91,3.86-2.28c2.71-0.68,5.61-0.69,8.27-1.51c1.17-0.36,2.64-2,2.69-3.11
                c0.04-0.96-1.57-2.57-2.74-2.92c-5.25-1.56-10.57-2.96-15.94-4.06c-3.61-0.74-7.34-0.86-10.99-1.26
                c0.82,4.21,2.15,7.64,1.91,10.94c-0.2,2.78-1.65,7.15-3.56,7.79c-4.85,1.63-10.36,2.45-15.44,1.94c-5.77-0.58-7.94-4.59-6.1-10.22
                c1.35-4.12,4-7.81,6.22-11.97c-1.7-0.64-3.8-1.42-5.89-2.21c0.11-0.4,0.22-0.79,0.32-1.19c9.72,1.03,19.48,1.72,29.13,3.19
                c7.37,1.12,14.64,3.01,21.83,5.03c1.69,0.47,3.99,2.85,3.97,4.31c-0.03,1.88-1.74,4.3-3.44,5.43c-1.95,1.29-4.66,1.45-7.06,2.02
                c-1.32,0.31-2.68,0.45-4.03,0.67c-0.11,0.49-0.22,0.97-0.33,1.46c2.42,1.21,5.13,2.06,7.16,3.74c1.8,1.49,2.83,3.9,5.02,7.1
                c4.13,0,10.08,0.33,15.95-0.22c1.6-0.15,3.04-2.88,4.38-4.56c1.15-1.44,1.9-3.24,3.13-4.59c1.02-1.12,2.49-1.81,4.12-2.94
                c-3.07-2.16-5.72-3.62-7.88-5.61c-6.24-5.77-5.38-11.49,2.1-15.45c6.23-3.3,12.88-4.23,19.85-3.68c1.74,0.14,3.52-0.28,5.28-0.45
                c0.03-0.5,0.05-1,0.08-1.5c-1.64-0.56-3.25-1.22-4.92-1.66c-6.09-1.59-12.19-3.16-18.32-4.61c-3.09-0.73-5.48-1.72-3.18-6.08
                c-12.81-0.66-24.95-1.28-37.08-1.91c0.01-0.45,0.02-0.91,0.02-1.36c6.94,0,13.87,0,21.83,0c-1.58-1.15-2.36-1.72-4.11-3
                c10.01,0.14,18.96-0.81,27.67,5.86c-0.85-2.39-0.95-3.87-1.73-4.68c-5.29-5.41-10.72-10.69-16.14-15.97
                c-2.6-2.54-5.1-5.22-7.95-7.45c-3.32-2.61-6.86-5.01-10.5-7.15c-4.52-2.66-7.26-8.87-14.09-7.29c-0.36,0.08-1.37-2.08-1.87-3.29
                c-0.63-1.52-0.62-3.84-1.69-4.5c-0.92-0.56-2.98,0.72-4.68,1.23c-4.99-6.02-10.2-12.3-15.75-18.98c-1.82,1.12-4.69,1.94-5.86,3.81
                c-3.2,5.13-4.99,11.22-8.56,16.02c-11.75,15.83-16.85,34.57-23.97,52.44c-1.6,4.02-0.6,5.07,3.67,5.01
                c3.34-0.05,5.04-1.18,3.72-5.43c1.97,1.21,4.15,2.49,4.03,3.5c-0.16,1.38-1.88,3.29-3.27,3.66c-2.83,0.75-5.91,0.94-8.85,0.77
                c-2.74-0.16-3.95,0.77-5.39,3.11c-1.28,2.08-4.16,4.66-6.21,4.55c-17.74-0.97-35.47-2.38-53.17-3.97
                c-3.66-0.33-6.95-2.1-2.25-6.56c-10.4-0.59-20.08-1.13-29.75-1.68c0.04-0.45,0.09-0.89,0.13-1.34c8.75-1.93,33.05,0.02,36.32,3.88
                c-1.42,0.6-2.94,1.24-4.46,1.88c0.23,0.49,0.45,0.97,0.68,1.46c3.72,0.56,7.47,0.97,11.15,1.73c4.31,0.88,5.86-0.92,5.57-5.02
                c-0.74-10.46-1.74-20.92-2.05-31.39c-0.17-5.68-1.33-10.73-4.39-15.52c-3-4.7-4.89-10.27-8.51-14.36
                c-5.09-5.77-11.31-10.55-17.1-15.69c-0.5-0.44-1.45-0.37-1.23-0.32c-2.22,2.38-3.91,4.21-6.23,6.7c4.43,6.65-0.84,13.67-1.71,21.1
                c-1.45,12.38-11.14,18.47-19.32,25.15c-5.39,4.4-12.81,6.49-19.57,8.91c-2.83,1.01-6.33,0.48-9.48,0.18
                c-3.87-0.37-7.72-1.78-11.53-1.6c-1.48,0.07-2.77,3.27-4.24,4.96c-0.64,0.74-1.55,1.24-2.26,1.94
                c-3.48,3.42-6.97,6.84-10.39,10.32c-3.32,3.37-7.3,3.82-11.5,2.42c-4.18-1.4-4.88-4.87-3.82-8.55c0.95-3.3,1.94-6.94,4.06-9.46
                c3.81-4.52,4.69-9.55,5.47-15.12c1.73-12.38,5.15-24.46,15.53-32.51c8.14-6.32,6.78-14.26,5.91-22.49
                c-0.9-8.46-7.58-16.52-1.27-25.3c-4.53-3.58-8.79-6.69-12.71-10.19c-1.8-1.61-4-3.93-4.14-6.05c-0.59-9.02-0.6-18.08-0.57-27.13
                c0.01-3.44,0.81-6.87,1-10.31c0.05-0.97-0.49-2.31-1.22-2.93c-5.84-4.97-9.05-11.12-10.22-18.79c-1-6.56,0.31-10.6,6.6-14.71
                c3.42-2.24,8.64-1.32,13.07,1.73c2.36,1.61,5.26,2.41,7.86,3.68c6.2,3.03,12.15,0.36,17.88-1.26c10.2-2.89,21.1-3.91,29.49-11.87
                c5.61-5.32,6.54-4.84,13.59-0.78c1.23,0.71,2.77,1.07,4.21,1.2c6.72,0.6,13.58,0.25,19.6,4.28c4.69,3.14,8.95,2.06,13.68-0.86
                c2.62-1.62,6.89-1.98,9.96-1.17c9.77,2.6,17.59-4.18,26.77-4.55c-1.8-1.32-3.6-2.03-5.45-2.61c-5.54-1.74-11.5-2.66-16.57-5.29
                c-9.11-4.72-17.87-8.98-28.73-7.88c-6.64,0.68-9.54-2.67-10.95-9.14c-1.34-6.14-4.61-9.08-10.74-10.18
                c-4.06-0.73-8.04-1.86-12.06-2.8c-6.27-1.47-12.59-2.77-18.79-4.46c-2.63-0.72-5.27-1.92-7.52-3.46
                c-10.01-6.9-19.65-14.8-32.79-14.08c-9.62,0.53-19.34,1.03-28.75,2.87c-7.23,1.41-13.04,0.41-19.36-3.23
                c-14.56-8.39-29.72-13.01-46.67-6.14c-6.63,2.69-13.83,4.03-21.42,1.36c-12.27-4.32-23.98-9.18-32.54-20.53
                c0.03,6.48,1.96,11.46,7.01,14.18c8.8,4.72,17.88,8.93,28.1,9.76c2.64,0.21,5.23,0.95,7.85,1.44c-0.06,0.46-0.13,0.92-0.19,1.37
                c-1.55,0-3.18,0.34-4.62-0.05c-9.74-2.66-19.6-4.99-29.09-8.38c-11.32-4.04-16.09-18.04-10.43-28.69
                c0.45-0.85,1.22-1.52,1.82-2.29c1.16-1.49,2.7-2.83,3.41-4.52c5.5-13.09,6.85-27.03,8-40.99c0.46-5.55,0.67-11.11,0.97-16.37
                c-4.34-0.63-8.34-0.51-11.77-1.84c-7.9-3.07-16.09-2.69-24.01-1.89c-16,1.62-31.33,6.04-45.32,14.4
                c-1.31,0.78-3.04,0.87-4.58,1.27c-0.29-0.62-0.58-1.25-0.87-1.87c3.74-2.51,7.49-5.01,11.23-7.53c4.39-2.97,9.29-5.43,13-9.1
                c2.88-2.85,4.27-7.21,6.32-10.9c-3.03-0.84-6.03-1.85-9.1-2.48c-13.22-2.72-26.42-2.52-39.57,0.51c-1.3,0.3-2.59,0.72-3.91,0.81
                c-0.91,0.07-2.43-0.07-2.66-0.58c-0.4-0.88-0.39-2.37,0.13-3.15c1.21-1.82,2.82-3.37,4.31-5.09c-2.94-0.94-5.51-1.59-7.95-2.56
                c-6.51-2.58-12.07-0.76-16.94,4.43c-2.71,2.88-6.46,4.98-10.1,6.67c-1.47,0.68-4.46-0.12-5.69-1.33
                c-0.96-0.95-0.37-3.57-0.29-5.45c0.05-1.08,0.44-2.15,0.87-4.06c-9.73-0.2-19.06-1.41-27.1,4.71c-3.22,2.45-6.23,3.37-10.14,2.35
                c-5.09-1.33-10.27-2.3-15.97-3.54c3.03,4.52,5.66,8.13,7.95,11.95c1.71,2.86,1.38,4.71-2.77,4.31c-7.82-0.75-9.7,1.75-7.26,9.25
                c3.2,9.84,1.87,12.07-8.49,14.11c-1.14,0.22-2.32,0.26-3.46,0.48c-10.36,1.98-20.79,3.74-29.55,11.01
                c6.16,1.13,12.34,1.84,18.54,2.24c18.49,1.18,32.6,9.69,42.27,25.39c4.55,7.38,3.82,10.36-3.37,15.25
                c-2.56,1.74-5.6,2.94-8.57,3.91c-3.95,1.28-8.24,1.63-12.04,3.21C229.84,1162.29,225.1,1165.2,219.04,1168.38z M891.17,975.36
                c-3.56,18.42-15.5,32.67-22.67,49.77c10.88,0.91,20.75-0.86,30.6-3.45c3.89-1.02,6.32-2.97,7.88-6.74
                c3.19-7.69,6.79-15.2,10.21-22.79c1.2-2.65,2.39-5.31,3.58-7.97c-5.72,2.67-9.22,8.25-16.91,9.53
                c4.11-14.8,12.02-26.4,19.72-38.37c-8.58-5.08-18.43-7.62-24.25-17.82c6.5-0.31,11.6-0.37,16.67-0.83
                c9.61-0.88,16.21-6.24,20.11-14.77c7.4-16.2,14.24-32.67,21.93-48.73c2.36-4.93,6.17-9.51,10.29-13.14
                c4.11-3.62,8.86-2.1,10.2,2.92c2.09,7.85,8.11,9.75,14.65,11.52c1.53-2.36,2.93-4.52,4.35-6.7c-7.32-3.79-7.51-4.85-2.3-10.99
                c1.23-1.44,2.37-2.95,3.55-4.43c-0.21-0.52-0.42-1.04-0.63-1.57c-2.8,0.19-5.62,0.17-8.38,0.62c-7.19,1.17-14.75,4.6-21.42,3.29
                c-7.59-1.48-14.38,0.01-21.54,0.44c-3.98,0.24-8.01,0.4-11.97,0.04c-5.71-0.52-11.04-2.16-15.09-6.74
                c-2.59-2.93-5.5-5.57-9.02-9.1c-3.04,5.74-5.2,10.19-7.7,14.44c-1.65,2.81-3.23,6.28-5.81,7.82c-8.3,4.94-12.23,12.57-14.45,21.3
                c-6.51,25.63-12.79,51.32-19.14,77c-0.11,0.43,0.06,0.94,0.18,2.44c1.78-2.29,3.15-3.87,4.31-5.59
                c8.76-13.04,16.51-26.58,20.91-41.82c0.83-2.88,2.28-5.58,3.44-8.36c0.61,0.25,1.21,0.49,1.82,0.74
                c-2.24,7.07-4.21,14.25-6.77,21.2c-7.23,19.68-20.96,35.45-32.13,52.75c-2.69,4.16-5.93,8.06-9.43,11.56
                c-1.87,1.87-4.75,2.89-7.33,3.85c-3.21,1.19-6.6,1.9-9.89,2.87c-2.86,0.85-5.77,1.59-8.52,2.73c-2.34,0.97-5.24,1.89-6.59,3.77
                c-5.49,7.68-11.17,15.37-15.48,23.73c-5.14,9.95-10.28,19.71-17.67,28.25c-7.36,8.5-12.82,18.22-16.2,29.03
                c-0.23,0.75-1.05,1.31-1.59,1.96c-0.47-0.18-0.95-0.36-1.42-0.55c0.92-3.57,1.14-7.53,2.9-10.62c4.75-8.35,10.17-16.33,15.3-24.47
                c4.42-7,9.47-13.71,13.06-21.11c5.13-10.58,10.98-20.55,18.87-29.26c1.4-1.55,3.15-3.23,5.05-3.81c7.54-2.29,15.23-4.1,22.81-6.26
                c1.53-0.44,3.26-1.31,4.19-2.53c4.42-5.79,10.18-11.2,12.53-17.79c5.34-15.02,9.22-30.58,13.2-46.04
                c4.02-15.62,7.18-31.46,11.23-47.07c2.17-8.34,6.27-15.73,14.27-20.29c3.08-1.76,5.07-4.59,4.88-8.95
                c-9.94,2.33-19.55,4.21-28.91,6.96c-4.11,1.21-8.14,3.69-11.44,6.49c-7.35,6.24-13.72,13.44-18.46,21.99
                c-1.26,2.28-3.15,4.37-5.17,6.03c-13.17,10.82-21.69,24.68-26.4,40.85c-6.28,21.56-11.26,43.5-20.71,64.06
                c-2.1,4.56-2.76,9.92-3.34,15c-1.91,16.7-7.34,31.57-19.9,43.46c-3.6,3.41-6.56,7.89-8.64,12.42c-2.2,4.77-2.97,10.19-4.42,15.31
                c-0.31,1.08-0.82,2.11-1.45,3.72c-0.83-1.14-1.37-1.54-1.35-1.9c0.75-12.03,3.07-23.37,12.74-31.93
                c8.69-7.69,14.39-17.17,16.98-28.65c2.58-11.47,2.9-23.53,8.75-34.19c1.46-2.67,2.38-5.64,3.55-8.47
                c-2.06,0.23-3.38,0.94-4.13,2.05c-7.14,10.49-15.82,19.61-25.02,28.28c-0.36,0.34-0.68,0.75-0.91,1.19
                c-3.33,6.43-8.73,8.58-15.73,8.21c-5.89-0.31-11.34,1.47-16.35,5.34c3.05,1.43,6.11,2.59,9.26,2.87c7.4,0.66,14.84,0.8,22.25,1.33
                c4.58,0.32,5.49,1.89,3.94,6.17c-0.4,1.09-0.9,2.16-1.52,3.14c-6.33,9.98-12.67,19.95-19.04,29.91
                c-2.49,3.89-5.96,5.97-10.67,6.43c-6.45,0.63-12.27,2.66-16.42,8.25c-1.32,1.78-3.64,2.82-5.49,4.22
                c-6.33,4.79-12.65,9.59-18.96,14.39c-1.15,0.88-2.26,1.81-3.39,2.72c-0.39-0.37-0.77-0.75-1.16-1.12
                c0.95-1.39,1.65-3.09,2.91-4.12c5.75-4.73,11.63-9.31,17.52-13.87c1.96-1.52,4.65-2.39,6.06-4.26c4.58-6.1,10.97-8.07,17.97-9.67
                c3.25-0.75,6.99-2.75,8.88-5.36c6.63-9.16,12.58-18.81,18.7-28.33c0.84-1.3,1.2-2.92,2-4.96c-7.66-1.32-14.4-3.6-20.41,3.11
                c-3.85,4.31-8.67,7.76-13.15,11.49c-1.09,0.91-2.52,1.41-3.8,2.09c-0.41-0.5-0.81-0.99-1.22-1.49
                c4.96-5.03,9.91-10.06,15.32-15.55c-6.07-1.96-10.99-4.12-16.13-5.1c-10.75-2.04-21.07-2.02-27.55,9.29
                c-0.82,1.43-2.65,2.49-4.23,3.26c-21.45,10.44-37.97,26.35-49.77,46.79c-8.34,14.44-16.43,28.92-27.08,41.86
                c-3.14,3.81-5.44,8.36-7.87,12.71c-2.97,5.3-1.07,9.18,4.89,8.58c8.3-0.83,12.44,4.28,16.84,9.54c1.97,2.35,3.76,4.85,5.08,6.57
                c5.58-4.47,10.59-8.44,15.56-12.47c4.1-3.32,8.34-6.51,12.19-10.11c5.82-5.43,11.19-11.35,17.1-16.67
                c2.39-2.15,5.53-3.85,8.62-4.75c6.05-1.76,7.55-6.69,10.09-11.74c-7.03-1.23-13.4-2.1-19.66-3.49c-7.2-1.61-13.81-1.19-19.74,3.72
                c-0.86,0.72-2.23,0.83-4.32,1.55c0.73-2.24,0.87-3.61,1.56-4.6c4.41-6.4,8.37-13.25,13.58-18.94c6.17-6.73,8.76-13.93,7.1-22.79
                c-0.35-1.87-0.76-3.74-1.14-5.6c1.92,1.13,2.91,2.59,3.45,4.21c3.45,10.29,0.56,18.81-6.98,26.35c-4.26,4.26-7.9,9.14-12.02,13.97
                c5.12-0.56,9.3-1.63,13.38-1.34c8.11,0.57,16.17,1.92,24.25,2.9c3.77,0.46,4.96,2.56,3.54,5.91c-1.26,2.97-2.92,5.76-4.55,8.91
                c1.64,0.59,2.68,1.12,3.78,1.34c8.26,1.64,16.61,2.89,24.77,4.95c3.77,0.95,5.95-0.04,8.14-2.75
                c5.75-7.12,11.36-14.39,17.52-21.14c3.18-3.48,7.11-6.59,11.27-8.78c6-3.15,12.31-6.2,18.85-7.71
                c11.31-2.61,19.67-8.55,26.43-17.82c7.32-10.04,16.52-18.69,21.35-30.55c2.32-5.69,7.19-9.21,12.31-12.66
                c4.66-3.14,9.64-7.12,12.11-11.94c8.7-16.98,23.22-28.03,37.55-39.42C889.53,975.54,890.27,975.57,891.17,975.36z M473.94,1097.52
                c0.63-0.06,1.26-0.12,1.9-0.18c0.4,1.7,0.96,3.38,1.17,5.1c2.07,16.85,0.01,33.28-4.58,49.56c-1.56,5.52-2.48,11.36-2.68,17.09
                c-0.27,7.75,0.52,7.84,8.02,5.57c10.38-3.14,21.06-6.38,31.82-2.93c9.09,2.92,17.86,6.99,26.51,11.11
                c5.1,2.43,9.6,1.67,12.11-3.36c4.06-8.17,7.71-16.57,12.25-24.45c1.94-3.36,5.53-5.88,8.68-8.39c1.96-1.57,4.5-2.42,7.65-4.04
                c-3.06-2.42-5.21-4.18-7.41-5.86c-3.68-2.8-7.15-2.69-10.86,0.29c-6.95,5.57-19,2.51-22.09-5.96c-1.91-5.23-2.38-10.97-3.6-16.45
                c-0.67-3.03-1.15-6.22-2.53-8.92c-0.67-1.32-3.39-2.63-4.84-2.33c-5.55,1.14-11.31,2.23-16.33,4.69
                c-5.8,2.84-10.12,3.09-14.45-2.25c-1.22-1.5-4.15-2.52-6.06-2.21c-1.08,0.17-1.88,3.4-2.43,5.36c-1.22,4.3-2.21,8.67-3.29,13.02
                c-0.59-0.13-1.18-0.26-1.77-0.39c0.78-5.35,1.42-10.74,2.38-16.06c1.05-5.81,5.04-7.64,10.23-4.69c1.98,1.13,3.51,3.05,5.41,4.36
                c1.27,0.87,3.06,2.11,4.25,1.8c6.47-1.67,12.93-3.47,19.17-5.82c4.32-1.63,7.73-1.03,9.93,2.76c1.61,2.78,2.49,6.09,3.19,9.27
                c1.18,5.32,1.52,10.85,3,16.07c2.16,7.62,12.21,10.55,18.35,5.72c5.14-4.03,8.67-4.14,14.02-0.36c1.77,1.25,3.33,2.95,5.27,3.74
                c2.23,0.9,4.94,1.8,7.15,1.32c4.93-1.06,9.22-0.17,13.38,2.32c1.37,0.82,2.73,1.68,3.61,2.23c6.14-8.86,12.07-17.24,17.81-25.74
                c4.37-6.47,8.94-12.87,12.65-19.72c7.48-13.8,14.88-27.56,27.2-37.87c1.24-1.04,2.16-2.54,3.03-3.94
                c4.52-7.27,10.65-12.8,18.33-16.46c3.32-1.58,3.07-3.31,2.01-6.3c-2.73-7.66-5.24-15.42-7.37-23.27
                c-1.96-7.22-2.87-14.74-5.12-21.85c-0.92-2.91-3.66-6.38-6.38-7.38c-11.69-4.31-23.68-7.82-35.58-11.56
                c-7.33-2.3-13.75-0.86-19.17,4.77c-5.54,5.75-11.53,11.11-16.7,17.17c-10.1,11.84-23.4,17.33-37.95,21.32
                c-11.65,3.19-22.91,7.83-34.38,11.7c-5.49,1.85-10.99,3.86-16.65,5.02c-12.25,2.51-24.77,4.22-34.78,12.59
                c-2.68,2.24-5.01,5.35-6.42,8.53c-2.82,6.37-4.28,13.38-7.42,19.56c-5.67,11.15-8,22.83-7.95,35.16
                c0.02,4.83,0.49,9.73-0.17,14.47c-0.86,6.17-2.27,12.33-4.13,18.28c-1.62,5.2-4.39,10.04-6.28,15.17
                c-1.38,3.74-0.03,6.84,3.49,8.9c4.16,2.44,8.16,5.22,12.48,7.33c4.54,2.21,9.39,3.81,14.53,5.84c0.43-2.14,0.7-3.23,0.87-4.33
                c1.09-7.34,2.07-14.69,3.24-22.01c1.35-8.44,3.45-16.82,4.15-25.31C474.5,1115.41,473.94,1106.44,473.94,1097.52z M1071.77,276.76
                c-8.06,2.63-12.02,8.6-12.87,16.95c-0.57,5.63-0.89,11.29-1.61,16.91c-1.94,14.98-4.51,29.91-5.85,44.94
                c-0.55,6.25-2.15,11.54-5.42,16.7c-5.12,8.05-16.56,9.59-23.49,3.1c-1.52-1.42-3.69-2.1-5.44-3.31c-3.19-2.2-7.12-3.97-9.21-6.98
                c-2.67-3.85-4.76-8.62-5.4-13.23c-0.99-7.18-0.57-14.57-0.69-21.87c-0.16-9.63,0.55-19.35-3.24-28.57
                c-2.55-6.21-18.92-13.62-25.25-11.38c-0.62,0.22-1.25,0.53-1.74,0.95c-3.34,2.83-6.65,5.71-10.57,9.09
                c4.01,0,7.43,0.03,10.85-0.01c6.65-0.08,7.46,0.69,7.21,7.48c-0.43,12.15-1.01,24.29-1.37,36.44c-0.69,23.13-1.18,46.27-1.93,69.4
                c-0.26,8.24-3.65,11.38-11.88,11.16c-3.29-0.09-4.5,1.4-3.97,4.08c0.77,3.88,1.72,7.82,3.34,11.4c2.51,5.54,3.06,11.08,2.25,17.04
                c-1.32,9.73-2.12,19.53-3.57,29.23c-0.37,2.49-2.26,4.82-3.69,7.08c-4.57,7.23-10.94,13.86-13.43,21.73
                c-2.85,8.99-2.36,19.06-3.1,28.68c-0.11,1.5,0.71,3.23,1.53,4.61c3.51,5.91,5,12.23,4.8,19.09c-0.28,9.65-0.43,19.31-0.41,28.97
                c0.02,9.19,1.28,13.3,13.59,11.66c12.45-1.66,24.75-2.62,37.27-1.95c4.8,0.26,9.67,0.05,14.47-0.41
                c8.85-0.86,10.06-2.66,8.16-11.13c-0.47-2.11-1.18-4.17-1.6-6.29c-1.11-5.47,0.99-8.98,5.56-12.14c2.28-1.58,4.69-4.58,4.96-7.16
                c1.19-11.57,1.71-23.2,2.34-34.82c0.92-16.98,1.46-34.03,12.26-48.52c0.67-0.9,1.15-2.13,1.22-3.24
                c0.4-6.41,0.77-12.84,0.92-19.26c0.15-6.62-0.54-13.3,0.16-19.85c1.04-9.74,3.96-19.35,4.3-29.08
                c0.56-15.84,11.36-23.91,22.09-32.28c1.7-1.32,4.33-1.62,6.59-2c2.95-0.49,5.96-0.6,8.94-0.86c3-0.26,4.25-1.55,4.05-4.88
                c-0.83-13.6-4.57-26.1-12.18-37.53c-1.92-2.88-2.88-6.69-3.33-10.19c-1.36-10.69-2.19-21.44-3.34-32.16
                C1073.41,288.62,1072.56,282.91,1071.77,276.76z M3.63,803.79c-0.52,0.16-1.04,0.31-1.56,0.47c0,6.89-0.29,13.81,0.16,20.67
                c0.16,2.49,1.62,5.3,3.32,7.23c7.5,8.45,15.87,16.18,22.86,25.01c9.91,12.52,18.86,25.79,28.25,38.72
                c3.17,4.36,5.48,8.73,5.43,14.58c-0.03,3.88,1.72,8.26,3.94,11.56c3.31,4.91,7.71,9.09,11.7,13.54
                c4.65,5.19,9.67,10.08,13.97,15.55c5.13,6.52,9.71,13.48,14.43,20.32c1.07,1.54,1.73,3.36,2.59,5.05
                c-1.81,0.26-2.61-0.02-3.12-0.58c-10.68-11.42-21.22-22.97-32.04-34.26c-3.17-3.31-6.69-7.48-10.74-8.5
                c-9.15-2.3-15.69-7.87-21.96-14.07c-8.52-8.43-16.64-17.26-25.1-25.74c-3.83-3.83-8.08-7.24-12.13-10.84
                c-0.52,0.19-1.05,0.39-1.57,0.58c0,5.9,0.25,11.81-0.08,17.69c-0.25,4.55,1.31,7.24,5.4,9.5c17.36,9.6,32.1,22.55,45.52,37.03
                c1.95,2.11,4.74,5.25,4.34,7.39c-1.26,6.67,2.27,10.72,6,15.05c1.57,1.83,2.83,3.94,4.23,5.92c-0.6,0.58-1.21,1.17-1.81,1.75
                c-20.65-15.48-41.3-30.96-63.07-47.29c-0.26,3.53-0.5,5.3-0.5,7.06c-0.03,13.15,0.14,26.31-0.1,39.46
                c-0.08,4.51,1.46,7.13,5.25,9.7c26.14,17.68,54.84,31.73,77.56,54.31c1.4,1.39,3.28,2.31,4.94,3.45c0.33-0.37,0.66-0.73,0.98-1.1
                c-2-2.8-3.88-5.69-6.01-8.37c-5.08-6.38-10.56-12.46-15.3-19.08c-2.12-2.96-3.77-6.87-4-10.45c-0.44-6.99,4.72-10.35,10.93-7.47
                c3.27,1.51,6.17,3.86,9.15,5.94c3.13,2.18,6.16,4.51,10.23,7.5c-2.27-6.16-4.83-10.7-5.47-15.49c-0.78-5.84-3.88-9.57-7.77-13.33
                c-4.83-4.68-9.34-9.7-13.99-14.57c1.27-0.62,1.94-0.53,2.35-0.16c6.73,6.2,13.47,12.39,20.08,18.72c0.99,0.95,1.81,2.55,1.88,3.9
                c0.57,10.84,5.65,19.28,13.48,26.38c1.24,1.13,2.21,2.55,3.31,3.84c-0.27,0.4-0.55,0.8-0.82,1.2c-1.42-0.51-3.01-0.76-4.23-1.57
                c-6.92-4.62-13.75-9.36-20.59-14.08c-2.32-1.6-4.41-3.7-6.95-4.78c-2.02-0.86-5.87-1.51-6.58-0.54c-1.44,1.96-2.15,5.28-1.6,7.67
                c0.68,2.93,2.68,5.7,4.61,8.15c5.14,6.54,10.8,12.69,15.77,19.35c2.94,3.94,4.98,8.57,7.42,12.88c-0.64,0.49-1.28,0.98-1.91,1.47
                c-16.41-8.8-28.13-23.99-44.06-33.51c-15.9-9.5-31.5-19.5-47.46-29.42c-0.5,1.17-0.96,1.76-0.97,2.36
                c-0.06,13.82-0.06,27.64-0.1,41.46c-0.01,3.22,2.1,3.6,4.63,3.87c8.43,0.9,16.89,1.65,25.25,3.01c8.03,1.31,15.95,3.3,23.92,4.99
                c14.11,2.99,28.02,6.86,39.29,16.25c11.47,9.57,24.34,16.03,38.4,20.57c9.02,2.91,17.09,7.09,21.87,16.16
                c1.33,2.53,4.97,3.84,7.55,5.71c0.44-0.55,0.87-1.1,1.31-1.64c-4.34-5.16-7.77-11.87-13.24-15.13
                c-8.16-4.87-14.21-11.26-19.95-18.4c-2.07-2.58-4.45-4.96-6.91-7.19c-12.72-11.49-25.53-22.89-38.28-34.35
                c-1.31-1.18-2.45-2.54-3.67-3.81c0.35-0.34,0.69-0.68,1.04-1.02c1.81,1.38,3.71,2.65,5.42,4.16c12.49,11,25.23,21.74,37.3,33.19
                c8.27,7.85,18.29,11.39,28.83,13.85c7.05,1.65,9.47-0.97,9.15-8.21c-0.08-1.81-0.55-4.14,0.35-5.36c3.15-4.28,0.53-6.72-2.35-9.13
                c-3.18-2.66-6.52-5.13-9.8-7.67c-0.91-0.71-2.16-1.18-2.75-2.09c-5.27-8.06-12.87-13.2-21.26-17.45c-4.6-2.33-9.09-4.87-13.59-7.4
                c-1.32-0.74-2.47-1.78-4.5-3.27c1.68-0.19,2.25-0.45,2.58-0.27c9.34,5.09,19.64,9.02,27.73,15.64
                c10.3,8.43,21.6,13.45,34.13,16.67c8.34,2.14,15.43,6.32,20.05,13.82c7.86,12.74,21.72,16.99,33.66,23.94
                c0.94,0.55,3.96-0.26,4.12-0.91c1.53-6.29,6.18-9.03,12.72-10.82c-10.94-12.45-21.62-23.54-37.74-27.61
                c-3.13-0.79-6.08-2.96-8.66-5.06c-3.91-3.18-7.25-7.06-11.14-10.27c-7.15-5.9-14.66-11.36-21.76-17.33
                c-11.55-9.7-18.96-22.74-26.8-35.23c-5.79-9.21-11.08-19.11-14.37-29.42c-3.77-11.8-8.92-22.39-16.24-32.3
                c-11.28-15.26-25.35-27.56-39.13-40.41c-19.81-18.48-38.12-38.57-57.03-58C9.31,810.1,6.51,806.91,3.63,803.79z M203.26,304.89
                c13.22,7.8,26.05,15.43,38.97,22.9c1.97,1.14,4.74,2.6,6.54,2.02c5.18-1.67,8.38,1.84,12.39,3.4c3.8,1.48,7.82,2.39,11.75,3.54
                c0.32-0.59,0.64-1.17,0.96-1.76c-0.87-1.49-1.55-3.13-2.63-4.45c-3.06-3.74-6.27-7.34-9.42-11c-5.42-6.31-10.98-12.51-16.18-19
                c-1.71-2.14-2.49-5.02-3.7-7.56c0.43-0.39,0.86-0.78,1.29-1.17c2.12,1.03,4.44,1.79,6.34,3.13c8.02,5.66,15.85,11.58,23.89,17.21
                c2.05,1.44,4.59,2.17,6.9,3.22c-3.45-10.55-12.79-17.41-13.67-28.26c0.45-0.35,0.9-0.7,1.35-1.05c3.59,1.94,7.19,3.88,11.83,6.4
                c-0.75-1.98-0.95-2.6-1.22-3.18c-4.55-10.12-9.27-20.16-13.59-30.37c-2.33-5.52-1.28-6.69,4.83-7.14c0.9-0.07,1.77-0.57,3.32-1.1
                c-1.48-1.78-2.71-3.07-3.73-4.5c-2.21-3.1-4.65-6.11-6.35-9.49c-0.95-1.89-0.6-4.44-0.84-6.69c2.21-0.1,4.45-0.5,6.61-0.25
                c5.26,0.62,10.47,1.76,15.74,2.26c3.05,0.29,5.17-1.2,3.11-4.53c-6.11-9.86-5.06-22.37-11.95-31.92c-0.28-0.38,0.05-1.2,0.12-2.13
                c1.23,0.16,2.25,0.06,3.06,0.44c11.98,5.47,23.93,10.83,32.6,21.51c3.85,4.74,8.75,8.65,13.27,12.82c1.22,1.13,2.81,1.86,5.21,3.4
                c-0.36-2.75-0.78-4.2-0.69-5.61c0.14-2.39,0.6-4.76,0.92-7.14c2.08,0.64,4.25,1.07,6.23,1.95c3.77,1.68,7.34,4.45,11.01-0.43
                c0.64-0.86,3.02-0.35,4.58-0.6c1.55-0.25,3.07-0.67,4.6-1.01c-0.33-1.4-0.31-3.02-1.04-4.17c-5.03-8.03-10.29-15.92-15.31-23.95
                c-3.05-4.88-5.59-10.1-8.8-14.86c-2.26-3.36-4.94-6.62-8.01-9.23c-5.83-4.94-12.38-9.03-18.07-14.11
                c-8.28-7.38-16.13-15.26-24-23.09c-1.71-1.7-3.28-3.94-3.96-6.21c-1.61-5.4-2.93-10.93-3.87-16.49
                c-1.64-9.75-4.08-19.08-9.05-27.82c-3.18-5.58-5.33-11.83-7.28-17.99c-6.73-21.2-16.21-40.61-33.47-55.48
                c-2.76-2.38-5.31-3.47-8.85-3.45C193.49,2,172.01,1.94,150.54,1.96c-1.76,0-3.51,0.26-5.86,0.45c0.91,1.64,1.27,2.77,2.01,3.54
                c2.99,3.09,6.17,5.99,9.14,9.09c6.62,6.9,10.6,15.2,13.08,24.38c0.48,1.78,2.02,3.6,3.57,4.69c2.79,1.96,6.14,3.1,8.91,5.08
                c4.3,3.06,8.4,2.43,10.31-2.5c1.04-2.7,1.16-5.81,1.39-8.76c0.3-3.75,2.34-5.6,5.56-3.87c3.69,1.98,8.03,4.34,9.92,7.74
                c5.32,9.54,9.62,19.65,14.14,29.62c2.46,5.41,3.41,10.97,0.78,16.76c-0.65,1.42-0.77,3.38-0.4,4.92
                c3.36,14.36,4.24,29.35,11.52,42.7c5.19,9.52,10.05,19.36,10.67,30.41c0.38,6.77,2.13,14.41-6.3,18.65
                c-1.54,0.77-2.46,3.57-2.87,5.6c-0.78,3.89-1.06,7.89-1.42,11.86c-0.32,3.47-0.32,6.97-0.76,10.42c-0.14,1.13-1.08,2.85-1.9,3.01
                c-0.96,0.18-2.65-0.76-3.23-1.71c-2-3.23-3.91-6.57-5.4-10.06c-2.28-5.34-5.38-7.08-12.43-5.81c1.7,3.7,3.77,7.14,4.85,10.86
                c0.98,3.36,2.32,7.87,0.88,10.37c-1.81,3.14-1.37,5.18-0.1,7.64c3.6,6.93,7.8,13.59,10.95,20.72c3.32,7.51,4.98,12.32-6.92,11.78
                c-4.13-0.19-5.88,1.14-5.31,5.87c0.98,8.17-1.69,11.12-9.82,12.61c-0.4,0.07-0.72,0.61-1.27,1.11
                C207.36,287.77,212.22,296.59,203.26,304.89z M905.26,1496.32c0.07,0.6,0.14,1.2,0.21,1.8c1.76,0,3.51,0,5.27,0
                c80.73,0,161.45,0,242.18,0c8.07,0,8.09-0.01,8.09-7.89c0-59.78,0-119.55,0-179.32c0-8.29-0.03-8.13-8.11-9.01
                c-28.92-3.16-57.77-6.74-85.56-16c-0.85-0.28-1.82-0.22-2.41-0.29c0,8.41-0.12,16.49,0.04,24.57c0.11,5.96,0.96,11.92,0.83,17.86
                c-0.54,24.12-1.28,48.23-2.08,72.34c-0.16,4.88,1.44,6.72,6.65,7.51c9.78,1.48,19.64,3.51,28.95,6.79
                c7.35,2.59,9.03,8.48,5.91,15.51c-1.52,3.42-0.63,4.92,2.26,6.79c4.43,2.87,8.91,5.91,12.59,9.64c3.64,3.69,2.56,7.5-2.33,9.37
                c-3.52,1.35-7.38,1.9-11.13,2.54c-3.04,0.52-6.14,0.64-9.55,0.98c1.2,5.62-1.46,7.09-5.81,7.65c-12.11,1.56-24.15,2.54-36.23-0.31
                c-1.59-0.37-3.52,0.17-5.16,0.69c-4.2,1.33-8.22,3.54-12.51,4.23c-9.03,1.44-18.19,2.13-27.29,3.12
                c0.96,7.2-1.94,10.34-9.76,10.16c-7.93-0.18-15.06-2.36-20.65-8.37c-1.82,1.05-3.41,2.46-5.24,2.94
                c-11.05,2.93-22.09,1.43-33.1-0.44c-0.77-0.13-1.57-0.65-2.16-1.2c-4.03-3.78-8.14-1.97-12.63-0.7c0.76,2.19,1.49,3.86,1.93,5.61
                c2.12,8.37-1.09,11.75-9.71,11.48C914.27,1494.24,909.75,1495.63,905.26,1496.32z M719.42,595.1c-9.04,1.45-9.02,1.46-11.27,10.94
                c-0.3,1.26-1.11,2.4-1.51,3.65c-1.66,5.32-4.36,10.59-4.67,16c-1.04,17.94-0.68,35.97-1.92,53.89
                c-0.73,10.61,2.86,19.17,8.51,27.43c0.94,1.37,1.84,2.78,2.83,4.12c2.4,3.26,1.77,5.41-2.41,5.51c-5.98,0.15-7.7,3.86-7.83,8.86
                c-0.15,5.99,0.3,12-0.08,17.97c-0.2,3.19-1.4,6.38-2.48,9.45c-1.63,4.67-4.6,9.12-5.16,13.88c-2.05,17.48-3.36,35.05-4.88,52.59
                c-0.06,0.67,0.58,1.4,0.91,2.13c3.34-1.36,6.35-2.82,9.5-3.82c12.5-3.94,22.65-0.44,29.22,10.55c2.61,4.37,4.16,9.38,6.19,14.11
                c0.57,1.34,1.11,2.7,1.66,4.04c0.49-0.08,0.99-0.16,1.48-0.24c0.19-1.53,0.46-3.05,0.55-4.58c0.33-5.65,0.21-11.35,0.95-16.94
                c3.65-27.48,4.55-55.08,3.16-82.71c-0.71-14.07-2.2-28.18-4.62-42.06c-1.34-7.69-5.08-14.89-0.08-22.49
                c0.58-0.87,0.47-2.27,0.5-3.42c0.55-20.62,1.42-41.24,1.51-61.86c0.07-17.13-1.38-34.3,3.89-51.04c0.78-2.48,0.75-5.27,0.78-7.93
                c0.4-33.3-0.98-66.53-4.18-99.68c-0.49-5.07,0.33-9.55,3.74-13.49c1.73-2,4.19-4.11,4.53-6.43c1.43-9.64,2.18-19.37,3.24-29.63
                c-2.2,0.42-3.69,1.11-5,0.86c-2.31-0.44-5.5-0.78-6.58-2.36c-5.8-8.41-9.55-17.46-5.16-27.88c0.65-1.53,1.3-3.07,1.82-4.65
                c2.44-7.42,4.66-14.93,7.29-22.28c2.56-7.15,5.53-14.15,8.33-21.21c0.49-1.24,1.02-2.48,1.54-3.71c-1.26,0.32-1.6,1.03-2,1.71
                c-3.41,5.7-5.99,12.16-10.43,16.9c-6.67,7.12-10.74,15.35-14.67,24.03c-8.59,19.02-6.41,40.08-11.97,59.77
                c-3.17,11.24-1.81,23.77-2.51,35.71c-0.09,1.61-0.29,3.21-0.44,4.81c-0.61-0.07-1.23-0.14-1.84-0.21
                c-0.97-20.02-0.94-39.99,4.67-60.04c-9.8,3.16-11.83,5.76-11.34,14.44c0.53,9.29,1.92,18.58,1.78,27.86
                c-0.18,11.63-1.76,23.23-2.22,34.87c-0.13,3.21,1.4,6.54,2.47,9.71c0.99,2.93,3.65,5.85,3.35,8.53c-0.76,7-2.65,13.89-4.27,20.78
                c-0.77,3.25-0.56,5.71,1.65,8.71c1.9,2.57,3.58,7.62,2.31,9.77c-3.88,6.56-3.24,13.09-2.31,19.86
                c1.57,11.48,2.18,23.33,11.87,31.83C718.58,592.36,718.77,593.66,719.42,595.1z M957.59,1214.48c-0.92-0.1-1.29-0.25-1.56-0.14
                c-1.24,0.49-2.46,1.01-3.66,1.58c-20.12,9.62-41.3,14.8-63.63,15.04c-2.98,0.03-6.56,0.54-4.72-4.69
                c-9.99-0.6-19.87,0.94-29.28-4.28c3.71-2.9,7.84-4.77,11.62-7.17c6.97-4.42,13.77-9.09,20.56-13.78c2.54-1.75,2.89-3.86,0.26-6.05
                c-3.57-2.97-7-6.11-10.62-9.02c-3.1-2.49-6.82-4.33-9.49-7.19c-7.69-8.25-14.62-17.25-22.62-25.17
                c-3.28-3.25-8.39-5.92-12.93-6.39c-9.35-0.95-18.87-0.35-28.32-0.16c-2.96,0.06-5.98,0.58-8.84,1.38
                c-1.83,0.51-3.41,1.91-5.13,2.93c4.92,1.24,10.42,1.01,13.18,3.65c5.49,5.25,11.58,8.83,18.47,11.45
                c3.78,1.43,4.94,3.86,2.83,7.36c-3.77,6.25-7.64,12.45-11.59,18.59c-1.7,2.65-4.61,4.84-5.42,7.68
                c-2.84,9.99-9.37,17.48-15.55,25.32c-1.29,1.63-2.51,4.61-1.88,6.24c3.24,8.4-0.94,14.1-6.2,19.6c-2.59,2.71-5.23,5.39-7.95,8.18
                c5.53,3.09,10.84,5.23,15.11,8.67c4.93,3.96,10.14,6.15,16.22,7.72c11.8,3.03,22.81,3.41,33.21-4.09
                c3.35-2.42,7.26-4.05,10.85-6.15c1.56-0.91,3.45-1.8,4.33-3.23c6.1-9.88,15.02-15.34,25.93-18.92c9.56-3.13,18.64-4.19,28.29-1.05
                c6.14,2,12.44,3.97,18.81,4.63c5.65,0.58,9.39,2.9,12.04,7.51c2.38,4.14,4.58,8.37,6.99,12.8c8.8-3.74,16.04-2.74,20.84,6.35
                c0.54,1.03,2.38,1.69,3.73,1.91c5.82,0.97,10.37-0.3,14-5.85c6.57-10.05,14.24-19.23,24.76-25.68c3.48-2.13,6.46-5.16,9.45-8
                c3.47-3.31,6.5-7.1,10.1-10.25c4.84-4.23,6.63-11.15,3.77-17.65c-2.84,1.25-5.64,2.63-8.55,3.72c-2.35,0.89-5.03,1.83-6.4-1.43
                c-1.25-2.96-2.11-5.82,1.09-8.55c4.53-3.87,8.48-8.43,13.03-12.27c11.99-10.11,7.88-23.03,7.22-36.33
                c-7.07,5.28-11.47,11.37-14.94,18.27c-3.63,7.22-8.06,13.86-14.93,18.42c-5.69,3.77-11.5,7.36-17.34,10.88
                c-1.21,0.73-3.15,1.58-4.14,1.1c-4.17-2.02-5.94,0.93-8.42,3.17c-5.79,5.21-11.73,10.27-17.8,15.14c-1.63,1.31-3.97,1.72-7.09,3
                C956.25,1219.54,956.87,1217.2,957.59,1214.48z M879.69,1327.48c2.21-5.04,3.28-9.44,5.81-12.72c2.82-3.65,6.79-8.57,10.57-8.85
                c6.99-0.52,14.23,1.75,21.27,3.33c1.36,0.3,2.5,2.61,3.31,4.21c3.67,7.34,6.69,14.86,5.25,23.39c-0.13,0.78,0.35,1.88,0.91,2.52
                c4.13,4.77,2.86,9.03-3.34,10.82c-1.38,0.4-2.81,0.65-4.44,1.01c0.23,1.25,0.29,2.24,0.61,3.14c1.51,4.36,0.43,6.49-4.06,7.48
                c-1.44,0.32-3.25,0.82-4.41,0.25c-6.1-3.02-12.12-6.22-17.95-9.73c-0.84-0.51-0.94-3.12-0.56-4.55c0.38-1.44,1.78-2.6,2.99-4.24
                c-2.93,0-5.37,0-7.78,0c-2.61,8.43-10.82,7.36-16.65,9.97c-6.75,3.02-13.61,1.34-20.6-2.88c8.28,15.29,15.98,29.49,23.45,43.29
                c5.99,2.36,12.44,4.9,18.89,7.44c0.26-0.47,0.53-0.94,0.79-1.41c-2.96-1.75-5.94-3.48-8.89-5.26c-3.65-2.21-7.73-3.97-10.77-6.82
                c-3.53-3.31-2.26-7.03,2.29-8.88c2.77-1.12,5.84-1.77,8.26-3.4c5.68-3.83,11.71-5.81,18.54-5.28c9.74,0.74,12.22,5.2,7.64,12.59
                c2.46,3.31,4.78,6.43,6.88,9.26c2.83,0.74,5.75,1.19,8.42,2.26c5.84,2.34,11.56,4.99,17.73,7.7c0.53-2.17,0.72-3.1,0.99-4.01
                c1.42-4.89,2.4-9.98,4.41-14.61c2.35-5.4,7.4-6.9,13-4.78c1.7,0.65,3.55,2.02,5.1,1.75c7.3-1.24,10.63,3.56,13.65,8.63
                c1.51,2.54,2.63,3.79,6.1,3.25c4.22-0.65,8.34-0.36,12.22-3.08c1.45-1.02,4.71,0.08,6.97,0.78c5.57,1.73,11.03,3.8,17.59,6.1
                c0.01-26.81,3.37-52.49,2.2-78.8c-10.82,4.71-20.69,9.01-30.56,13.3c-1.22,0.53-2.74,1.68-3.65,1.32c-2.3-0.89-4.47-2.3-6.34-3.91
                c-0.4-0.34,0.31-3.06,1.19-3.63c10.14-6.64,20.27-13.31,30.69-19.49c6.98-4.14,12.23-8.71,12-17.81
                c-0.11-4.42-1.67-5.99-5.69-6.15c-17.11-0.65-34.22-1.38-51.33-1.95c-1.3-0.04-3.29,0.57-3.85,1.52
                c-2.46,4.19-9.22,5.33-8.45,11.79c0.03,0.25-0.32,0.56-0.53,0.82c-5.07,6.46-19.67,6.42-24.8,0.15c-1.04-1.27-3.11-2.25-4.76-2.35
                c-2.59-0.16-5.23,0.54-7.99,0.89c4.62,6.45,2.68,11.21-5.06,12.33c-2.7,0.39-6.16,0.56-8.23-0.78c-5.01-3.26-9.89-2.41-15.21-1.71
                c-11.18,1.47-15.1-2.59-13.45-13.48c-6.13-0.39-12.53-2.12-18.3-0.89c-10.28,2.19-18.33-3.35-27.35-5.55
                c-2.78-0.68-5.72-1.56-8.48-1.29c-6.87,0.67-13.58,1.09-20.16-1.64c-1.51-0.63-3.3-0.55-5.77-0.92c5.34,11.6,12.58,20.47,20,29.4
                c7.34,8.83,13.29,18.83,20.55,29.33c2.92-10.22,11.1-11.82,18.28-14.64C866.65,1318.93,874.17,1318.51,879.69,1327.48z
                M1066.8,1182.4c-0.33,2.06-0.61,2.85-0.55,3.61c2.55,29.65-2.37,59.11-2.31,88.69c0.02,8.15-0.32,8.23,7.54,10.68
                c27.28,8.48,55.5,11.77,83.71,15.1c4.38,0.52,5.89-0.92,5.88-5.35c-0.12-34.29-0.06-68.59-0.08-102.89c0-1.53-0.33-3.05-0.67-6.04
                c-4.94,8.01-10.58,6-16.58,4.32c-13.9-3.92-24.95-12.2-35.29-21.79c-2.18-2.03-4.36-4.87-6.97-5.47
                c-8.29-1.91-13.51-7.59-17.16-14.24c-3.44-6.28-8.33-10.13-14.87-13.78c0,5.68,0.57,11.03-0.13,16.22
                c-1.17,8.69,2.09,14.99,8.33,20.67c13.29,12.09,26.26,24.54,39.45,36.73c2.73,2.52,3.39,5.39,0.81,7.67
                c-1.06,0.93-4.66,0.25-6.25-0.89c-10.67-7.64-21.11-15.62-31.64-23.47C1075.91,1189.1,1071.78,1186.07,1066.8,1182.4z
                M1160.62,246.83c-18.56,2.85-36.31,5.32-53.94,8.48c-3.92,0.7-7.63,3.5-11.06,5.87c-3.8,2.63-6.85,6.42-10.78,8.77
                c-9.13,5.46-10.46,7.82-8.36,18.57c2.26,11.58,5.12,23.01,4.6,35.04c-0.18,4.18,1.19,8.99,3.39,12.59
                c6.51,10.66,10.43,21.87,10.37,34.42c-0.01,1.41,0.48,2.82,0.87,4.94c2.34-1.1,4.12-1.85,5.81-2.77
                c5.84-3.16,11.59-6.49,17.49-9.55c6.71-3.48,13.09-3.51,19.7,1.04c4.01,2.76,8.84,4.73,13.59,5.9c7.07,1.73,8.69,0.08,8.7-7.02
                c0.01-36.95,0.01-73.91-0.01-110.86C1160.99,250.29,1160.73,248.36,1160.62,246.83z M229.95,211.21c0.67-0.08,1.34-0.15,2.01-0.23
                c0-3.55-0.13-7.11,0.03-10.66c0.34-7.7,0.56-15.47,8.33-20.21c0.88-0.54,1.19-2.28,1.46-3.53c2.16-9.93-0.15-19.42-4.52-28.07
                c-7.51-14.84-13.08-30.12-15.69-46.59c-1.04-6.53-2.14-12.77,0.35-19.13c0.4-1.02,0.59-2.37,0.29-3.39
                c-3.77-12.62-7.71-25.21-15.4-36.12c-1.76-2.49-4.37-4.55-6.96-6.26c-2.69-1.77-4.09-0.75-4.29,2.7c-0.19,3.23-0.6,6.73-2.03,9.55
                c-2.99,5.91-8.77,6.56-13.87,2.44c-2.12-1.72-4.83-4.07-7.13-3.91c-4.67,0.32-5.7-2.04-6.55-5.59
                c-2.04-8.49-4.96-16.64-10.98-23.23c-2.22-2.43-5.89-4.01-7.15-6.77c-4.48-9.8-12.25-11.43-21.7-10.31
                c-2.79,0.33-5.65,0.05-8.49,0.05c-3.23,0-6.45,0-9.68,0c-0.16,0.49-0.31,0.99-0.47,1.48c1.5,1.4,2.97,2.83,4.51,4.18
                c2.63,2.3,5.68,4.25,7.82,6.93c1.31,1.64,1.5,4.33,1.77,6.6c0.37,3.11,0.54,6.28,0.41,9.4c-0.14,3.46,0.42,7.91-1.46,10.03
                c-1.55,1.74-6.54,1.62-9.43,0.64c-3.08-1.05-6.11-3.63-8.08-6.34c-4.89-6.74-10.94-11.75-17.92-16.28
                c-4.83-3.14-8.16-8.55-12.31-12.8c-1.53-1.56-3.51-2.67-5.29-3.98c-0.29,0.48-0.58,0.96-0.87,1.44
                c5.67,6.72,11.47,13.33,16.96,20.2c3.72,4.66,7.83,9.29,10.27,14.62c3.93,8.56,6.54,17.46,15.29,23.05
                c4.98,3.19,4.59,14.34-3.22,16.8c-1.08,0.34-2.78,1.76-2.66,2.42c0.84,4.87,2.07,9.68,3.26,14.85c4.36-7.51,8.18-14.1,12.26-21.12
                c1.9,1.37,3.95,2.84,6.68,4.81c0.67-4.6,1.15-8.53,1.84-12.42c0.59-3.31,1.59-6.1,6.11-5.45c1.17,0.17,3.41-1.39,3.81-2.6
                c1.42-4.31,3.78-4.21,6.7-1.91c1.79,1.42,3.83,3.01,4.73,4.99c3.16,6.92,5.76,14.1,8.79,21.09c3.88,8.96,7.84,17.9,12.06,26.71
                c0.93,1.95,3.09,3.31,4.68,4.95c0.3-0.28,0.61-0.57,0.91-0.85c-1.45-2.39-2.83-4.83-4.36-7.17c-7.61-11.67-11.73-24.22-9.49-38.28
                c0.28-1.77,1.52-3.4,2.32-5.09c1.63,0.99,3.45,1.77,4.82,3.04c0.88,0.82,1.01,2.39,1.59,3.58c1.36,2.8,2.15,6.3,4.33,8.21
                c4.93,4.34,10.22,8.53,16,11.58c5.44,2.88,9.67,6.43,12.19,11.92c4.5,9.83,9.01,19.67,13.14,29.65c1.16,2.81,1.38,6.16,1.41,9.26
                c0.03,3.47-1.81,6.28-5.44,6.77c-5.07,0.69-5.22,3.23-3.71,7.25c6.67,17.76,13.2,35.57,19.83,53.34
                C228.24,208.8,229.2,209.97,229.95,211.21z M414.23,390.91c4.52-0.76,8.45-2.13,12.31-1.94c9.22,0.45,18.44,1.44,27.59,2.69
                c1.97,0.27,4.41,2.15,5.36,3.95c2.25,4.29,5.46,4.64,9.62,4.02c3.24-0.49,6.62-0.74,9.87-0.4c7.68,0.8,7.46,0.69,10.92-6.02
                c8.52-16.51,9.8-35.22,16.28-52.33c1.18-3.11-0.68-4.68-2.7-6.71c-3.4-3.42-6.37-7.3-9.24-11.2c-1.39-1.89-1.79-4.52-3.23-6.35
                c-1.62-2.06-4.02-3.48-5.89-5.37c-3.75-3.78-8-7.39-5.24-13.77c0.35-0.82-0.32-2.26-0.83-3.25c-5.27-10.04-13.01-11.27-20.71-3.35
                c-0.46,0.48-0.98,0.92-1.38,1.45c-8.48,11.28-21.58,18.9-26.16,33.35c-0.66,2.09-3.33,3.57-5.15,5.25
                c-1.58,1.46-3.58,2.56-4.86,4.24c-7.78,10.16-15.49,20.37-23.04,30.71c-5.07,6.95-9.91,14.08-14.69,21.24
                c-2.3,3.45-4.31,7.1-6.28,10.76c-0.42,0.78-0.06,1.97-0.06,2.34c4.58-1.45,8.88-3.7,13.31-4c7.21-0.48,12.64-3.35,17.16-8.7
                c1.38-1.64,3.15-2.94,5.54-5.13C413.39,386.1,413.8,388.47,414.23,390.91z M843.93,873.01c0.73-0.65,1.44-1.31,2.17-1.94
                c9.36-8.16,16.68-19.3,30.7-20.69c1.15-0.11,2.59-2.4,3.02-3.93c2.32-8.15,4.72-16.31,6.4-24.61c2.1-10.39,3.63-20.9,5.12-31.4
                c0.25-1.77-0.29-4.42-1.51-5.49c-5.87-5.22-8.19-12.34-11.43-19.08c-2.09-4.36-5.29-8.3-8.53-11.95
                c-3.93-4.44-8.74-8.1-12.59-12.6c-7.78-9.08-14.94-18.7-22.8-27.71c-6.12-7.02-10.01-14.73-11.14-24.01
                c-1.42-11.68-3.25-23.3-4.9-34.95c-0.62,3.33-0.41,6.58,0.16,9.76c5.83,32.92,7.04,66.15,6.37,99.47
                c-0.09,4.41-1.31,8.81-2.19,13.18c-1.2,5.94-3.55,11.83-3.69,17.78c-0.26,10.95,0.67,21.94,1.28,32.9
                c0.08,1.46,0.82,3.56,1.92,4.18c12.75,7.16,15.19,19.52,16.16,32.35C838.77,868.49,840.29,871.01,843.93,873.01z M900.38,151.31
                c-0.73-0.25-1.46-0.51-2.19-0.76c-4.71,4.81-9.43,9.61-14.14,14.43c-1.61,1.65-2.98,3.63-4.81,4.97
                c-6.7,4.88-13.25,10.07-20.42,14.17c-8.29,4.74-11.98,11.74-13.99,20.66c-2.51,11.18-5.37,22.35-9.11,33.16
                c-3.74,10.81-8.06,21.53-16.52,29.91c-1.27,1.26-1.72,3.97-1.56,5.93c0.22,2.63,2.05,5.18,2.05,7.76
                c-0.01,6.31-0.76,12.62-1.08,18.93c-0.76,14.97-1.37,29.89-7.09,44.14c-1.22,3.05-0.92,7.62,0.5,10.64
                c4.77,10.17,5.24,20,0.76,30.34c-1.91,4.4-0.05,8.2,4.29,10.3c1.92,0.93,4.19,1.11,6.15,1.98c3.7,1.63,7.34,3.42,10.9,5.33
                c1.22,0.66,2.15,1.87,2.85,2.5c2.35-13.34,4.69-26.58,7.01-39.81c0.06-0.32,0.04-0.67-0.01-1c-0.72-5.27-0.55-10.86-2.33-15.74
                c-7.09-19.43-7.09-39.87-9.22-59.99c-1.17-11.08,1.51-20.87,11.9-27.11c1.09-0.66,1.91-1.84,2.72-2.9
                c2.94-3.87,6.39-6.35,11.6-4.27c1.04-1.99,1.85-3.81,2.88-5.49c3.58-5.84,7.36-5.95,11.51-0.57c2.49,3.23,5.21,6.3,7.08,8.55
                c2.74-4.5,4.48-9.67,8.06-12.61c3.67-3.01,9.13-3.84,14.43-5.86c-1.67-1.88-3.67-4.98-6.44-7.04c-3.14-2.34-3.44-4.68-2.6-8.16
                c0.76-3.16,1.37-6.69,0.73-9.78c-1.19-5.76-0.3-10.96,2.38-15.92c3.6-6.65,4.8-13.71,4.68-21.19c-0.08-4.8,0.7-9.63,0.58-14.43
                C901.84,158.67,900.93,155,900.38,151.31z M2.08,1060.3c0,5.83-0.18,11.66,0.11,17.47c0.09,1.69,1.12,4.23,2.42,4.81
                c17.14,7.68,34.35,15.21,51.69,22.44c3.6,1.5,7.95,2.71,11.65,2.12c9.62-1.54,17.27,2.36,25.54,6.24
                c10.41,4.88,20.59,13.61,33.47,6.26c0.39-0.22,0.98-0.12,1.48-0.14c6.39-0.24,9.55-4.03,11.89-9.63c1.36-3.27,3.88-6.57,6.76-8.59
                c5.16-3.63,6.13-5.82,0.9-9.46c-5.62-3.91-12.24-6.39-18.4-9.54c-10.62-5.42-22.01-9.78-31.61-16.63
                c-8.51-6.08-16.78-12.21-26.84-14.8c-16.72-4.31-33.63-7.93-50.52-11.54c-4.35-0.93-8.95-0.64-13.41-1.14
                c-4.07-0.46-5.3,1.4-5.19,5.17C2.19,1048.98,2.08,1054.64,2.08,1060.3z M753.82,1174.48c10.47-8.27,20.29-15.67,29.7-23.56
                c8.2-6.88,17.55-7.98,27.65-7.24c4.93,0.36,9.94,0.22,14.86-0.23c1.54-0.14,3.72-1.79,4.22-3.23c3.05-8.73,9.68-14.13,17.92-15.93
                c8.12-1.77,12.17-9.2,19.58-11.36c0.36-0.1,0.63-0.74,0.81-1.19c1.5-3.71-2.32-17.66-5.66-20.06c-2.44-1.76-4.85-2.51-7.37,0.46
                c-2.81,3.32-5.9,6.5-9.26,9.24c-5.64,4.6-12.14,8.26-17.29,13.32c-5.74,5.63-11.8,7.56-19.45,5.83
                c-7.88-1.78-14.52,0.46-19.46,6.96c-2.61,3.43-5.14,6.95-7.35,10.65c-2.79,4.67-6.53,7.92-11.78,9.39
                c-5.45,1.52-10.85,3.23-16.36,4.48c-2.14,0.48-5.25,0.83-6.64-0.32c-6.41-5.27-12.37-11.09-18.49-16.71
                c0.26-0.38,0.53-0.77,0.79-1.15c1.45,0.88,3.07,1.57,4.31,2.69c3.08,2.79,6.23,5.57,8.88,8.74c3.92,4.68,8.21,6.38,14.05,3.75
                c2.55-1.15,5.38-1.78,8.15-2.33c7.34-1.46,12.08-5.5,15.97-12.14c7.52-12.84,14.4-20.11,31.54-16.52
                c3.43,0.72,8.48-1.06,11.26-3.46c7.32-6.31,14.28-12.92,23.05-17.31c2.12-1.06,3.64-3.31,5.66-5.22
                c-9.07-5.47-16.11-11.92-26.67-12.43c-8.87-0.43-17.09-0.28-25.19,3.81c-5.17,2.61-10.85,4.18-16.28,6.28
                c-7.89,3.06-15.91,5.86-23.58,9.4c-3.5,1.61-6.76,4.33-9.34,7.25c-6.61,7.48-12.8,15.33-19.09,23.08
                c-2.47,3.05-5.07,4.14-9.11,2.64c-2.64-0.98-5.84-0.47-8.79-0.63c-0.14,0.53-0.29,1.06-0.43,1.59c4.3,5.59,8.6,10.98,15.09,14.66
                c6.88,3.91,13.44,8.52,19.66,13.42c2.07,1.63,2.85,5.05,3.87,7.78C753.79,1170.32,753.6,1172.05,753.82,1174.48z M293.84,1367.27
                c0.51-0.91,0.79-1.34,0.99-1.8c4.51-10.45,14.52-15.79,21.96-23.51c0.95-0.99,2.64-1.31,4.03-1.82
                c12.67-4.65,19.55-14.82,23.98-26.61c1.86-4.93,2.66-11.09,1.52-16.15c-2.37-10.52-6.44-20.48-14.5-28.51
                c-5.12-5.1-9.24-11.27-13.42-17.24c-3.37-4.82-8.01-7.11-12.98-8.13c-2.66,2.37-5.29,4.98-8.27,5.49
                c-6.17,1.05-12.52,1.21-18.81,1.33c-7.68,0.14-9.26,1.64-8.55,9.3c0.15,1.65,0.51,3.29,0.83,4.92c1.38,7.07-0.13,9.38-7.19,10.68
                c-1.79,0.33-3.69,0.18-5.44,0.64c-3.22,0.83-8.58,1.36-9.04,3.09c-0.78,2.93,1.02,6.99,2.73,10.01c1.76,3.11,4.62,5.63,7.15,8.27
                c2.53,2.64,5.37,4.99,7.87,7.66c8.85,9.47,15.62,20.52,16.53,33.39C283.99,1348.95,287.54,1357.83,293.84,1367.27z M814.31,250.57
                c-0.52,0.14-1.05,0.29-1.57,0.43c-0.42-0.65-0.96-1.26-1.23-1.96c-2.73-6.94-5.52-13.86-8.07-20.87
                c-1.81-4.98-6.22-6.96-10.45-7.09c-1.92-0.06-5.07,4.25-5.78,7.06c-2.58,10.09-6.57,19.47-11.65,28.51
                c-1.68,2.99-2.26,6.58-3.48,9.85c-3.14,8.39-5.35,17.35-9.88,24.92c-3.63,6.07-4.35,11.75-4.41,18.34
                c-0.06,6.03-0.84,12.23-2.5,18.01c-2.08,7.26-5.51,14.13-8.11,21.26c-2.44,6.71-4.05,13.77-6.92,20.28
                c-4.39,9.94-6.46,19.33,0.5,29.11c2.61,3.68,5.5,5.11,8.67,1.76c2.05-2.15,3.39-5.21,4.38-8.09c4.51-13.02,8.54-26.21,13.28-39.15
                c1.97-5.39,5.97-9.43,11.74-11.58c1.69-0.63,3.37-2.76,3.99-4.57c3.09-8.96,6.14-17.96,8.6-27.11
                c3.98-14.77,7.74-28.71,20.91-39.48c12.88-10.53,18.53-26.88,23.33-42.78c0.48-1.58-0.15-3.83-0.97-5.4
                c-1.67-3.18-3.96-6.02-5.73-9.15c-1.91-3.38-4.18-4.46-6.59-2.83c0,10.45,0,20.03,0,29.61c-1.06-3.62-1.95-7.45-1.34-11.03
                c1.16-6.81-1.02-12.82-3.14-18.94c-1.46-4.23-2.87-8.5-4.62-12.61c-0.58-1.37-2.14-2.33-3.25-3.48c-0.57,1.52-1.79,3.13-1.6,4.55
                c0.65,4.9,2.18,9.69,2.67,14.6c0.97,9.72,1.44,19.5,2.19,29.25C813.51,244.85,813.96,247.7,814.31,250.57z M663.72,1222.23
                c3.91-4.28,7.09-7.18,9.58-10.58c10.91-14.96,25.94-25.72,39-38.47c1.86-1.82,5.05-3.38,7.53-3.25
                c8.87,0.46,17.74,1.47,26.54,2.77c3.61,0.53,4.88-0.51,4.19-3.74c-0.43-2.02-1.21-4.49-2.69-5.66
                c-5.2-4.09-10.39-8.51-16.29-11.31c-8.28-3.92-13.8-10.17-18.69-17.43c-0.92-1.36-1.77-3.23-3.1-3.76
                c-12.19-4.91-24.04-8.42-34.53,4.63c-7.4,9.21-16.18,17.46-26.69,23.65c-4.26,2.51-7.84,6.6-10.9,10.61
                c-6.16,8.04-11.76,16.52-17.57,24.83c-0.96,1.38-1.81,2.83-2.89,4.54c9.71,2.66,18.49,6.04,27.56,7.27
                C654.17,1207.6,661.07,1210.63,663.72,1222.23z M664.92,1223.54c0.07,0.64,0.14,1.29,0.21,1.93c3.16,0.31,6.36,1.1,9.47,0.84
                c6.92-0.57,13.05,1.06,19.02,4.58c4.41,2.6,9.23,4.55,14.02,6.43c7.08,2.78,14.37,5.04,21.41,7.92c6.33,2.59,11.93,1.58,16.26-3.8
                c7.26-9.03,15.51-16.85,25.71-22.6c3.55-2,6.88-4.76,9.6-7.8c3.88-4.33,6.34-9.4,4.09-15.58c-0.44-1.2-0.31-2.63-0.31-3.96
                c0.01-10.05-0.96-11.14-10.91-11.66c-2.82-0.15-5.66,0.04-8.48-0.01c-2.48-0.05-5.14,0.35-7.39-0.42
                c-10.58-3.61-21.18-6.24-32.55-6.12c-5.21,0.06-9.39,0.84-13.09,4.45C696.3,1193.03,680.6,1208.28,664.92,1223.54z
                M1132.71,1131.61c-0.53-1.52-0.83-3.04-1.55-4.31c-6.85-11.97-13.85-23.85-20.63-35.85c-2.52-4.47-4.86-9.09-6.7-13.87
                c-0.59-1.54,0.05-4.38,1.21-5.63c4-4.32,4.09-7.46-0.12-11.22c-15.46-13.81-29.96-28.41-38.74-47.67c-0.47-1.03-1.1-2.24-2-2.78
                c-6.13-3.64-8.93-9.62-11.68-15.75c-0.72-1.59-2.24-2.82-3.05-3.8c-6.96,4.05-9.7,9.68-10.33,16.54
                c-0.09,0.96-0.86,1.86-0.95,2.82c-0.87,9.05-1.72,18.1-2.39,27.17c-0.09,1.19,0.72,2.52,1.33,3.68c2.46,4.66,5.37,9.11,7.46,13.92
                c4.33,9.99,8.96,19.72,17.16,27.22c1.4,1.28,3.05,2.28,4.54,3.38c4.57-5.39,4.88-5.35,7.79,0.54c0.59,1.19,1.16,2.4,1.77,3.58
                c7.76,15.1,16.64,29.24,32.53,37.2c2.16,1.08,4.53,1.75,6.81,2.57c2.19,0.79,4.54,1.27,6.56,2.36c1.82,0.97,3.29,2.59,4.69,3.74
                C1128.69,1134.06,1130.48,1132.97,1132.71,1131.61z M710.71,358.01c-0.07-0.46-0.14-0.92-0.2-1.37c-1.33-0.37-2.84-0.45-3.96-1.16
                c-2.14-1.34-4.05-3.03-6.06-4.57c1.84-1.3,3.74-2.53,5.5-3.92c1.52-1.2,4.28-2.8,4.12-3.88c-1.35-9.2-3.13-18.34-5.02-27.44
                c-1.24-5.98-2.87-11.88-4.34-17.8c-1.91-7.7-3.09-15.69-5.94-23.03c-3.34-8.59-6.5-17.07-7.29-26.31
                c-0.79-9.28-1.35-18.6-2.58-27.83c-0.66-4.94-1.31-10.58-8.27-11.34c-0.75-0.08-1.61-1.15-2.03-1.97
                c-3.22-6.16-6.13-12.48-9.55-18.52c-2.51-4.43-4.5-9.59-11.27-11.97c1.71,5.73-2.06,12.2,5.93,14.4c0.51,0.14,0.96,0.91,1.23,1.49
                c2.43,5.21,5.75,10.11,4.5,16.42c-0.44,2.21,0.19,4.62,0.21,6.94c0.05,4.64,0.01,9.29,0.01,13.94c-1.38-0.64-1.66-1.34-1.7-2.06
                c-0.5-7.77-1.05-15.55-1.4-23.33c-0.28-6.3-3.52-11.07-8.66-11.97c0.17,0.71,0.14,1.46,0.49,1.92c5.83,7.67,6.8,16.36,5.76,25.61
                c-0.31,2.77,0.16,5.65,0.43,8.46c1.23,13.08,3.37,26.13,3.56,39.22c0.28,18.26,2.2,36.14,7.1,53.72c0.66,2.38,0.88,4.92,1,7.39
                c0.71,14.46,1.31,28.92,2.02,43.38c0.1,2.04,0.65,4.05,1.09,6.71c2.21-1.02,3.78-1.53,5.11-2.4c3.88-2.55,5.75-4.95,4.06-10.7
                c-3.83-13.02-6.24-26.49-8.81-39.85c-2.16-11.25-3.69-22.62-5.61-33.92c-0.3-1.76-0.78-3.79-1.9-5.06
                c-4.31-4.89-3.94-10.45-2.47-16.03c0.41-1.55,2.25-3.36,3.81-3.85c7.32-2.3,9.04-6.83,4.56-13.26c-2.09-3-4.66-5.71-6.46-8.86
                c-1.73-3.03-2.74-6.46-4.07-9.71c5.28,6.55,9.91,13.12,14.08,19.97c1.03,1.7,1.41,4.67,0.64,6.41c-1.58,3.57-0.42,5.11,2.62,6.66
                c2.12,1.08,3.89,2.82,5.82,4.27c-0.38,0.62-0.76,1.23-1.14,1.85c-2.41-1.16-4.84-2.28-7.23-3.48c-7.88-3.95-11.53-1.8-10.59,6.97
                c0.38,3.55,3.36,6.75,4.16,10.33c2.36,10.54,5.4,21.13,5.99,31.82c0.91,16.36,6.58,31.53,9.56,47.34
                c0.08,0.41,0.61,0.74,1.09,1.29C695.99,365.25,703.35,361.63,710.71,358.01z M921.3,530.97c0.64-0.14,1.29-0.27,1.93-0.41
                c2.65-6.57,5.53-13.06,7.81-19.76c0.65-1.92,0.38-5.13-0.85-6.53c-4.37-4.96-9.22-9.51-14.11-13.98
                c-5.52-5.05-11.1-10.06-16.98-14.67c-4.01-3.14-8.27-6.38-12.95-8.14c-8.06-3.02-11.42-8.6-11.82-16.67
                c-0.12-2.38-0.25-4.76-0.42-8.08c-2.28,2.36-3.63,4.05-5.26,5.39c-10.22,8.43-11.18,19.92-2.44,29.95c0.87,1,1.73,2.04,2.45,3.15
                c4.34,6.74,3.56,9.75-3.59,12.94c-3.02,1.35-4.17,2.76-2.27,6.04c1.31,2.25,2.02,4.91,2.62,7.48c1.16,4.98,2.65,9.42,8.98,9.47
                c0.73,0.01,1.66,0.56,2.14,1.15c4.34,5.45,8.82,10.83,12.81,16.53c2.32,3.32,2.26,6.91-0.11,10.72
                c-3.59,5.79-3.06,11.89,0.98,17.32c3.27,4.4,6.76,8.71,10.67,12.52c2.55,2.48,5.89,4.29,9.13,5.86c3.38,1.64,5.42,0.39,6.37-3.57
                c2.15-8.89,4.64-17.7,7.21-26.48c2.33-7.97,2.28-7.58-5.34-11.42c-4.44-2.24-8.47-5.54-12.15-8.94
                c-3.59-3.31-6.66-7.23-9.68-11.09c-3.95-5.05-7.79-10.2-11.33-15.54c-1.16-1.75-1.5-4.79,1.95-4.71
                c6.55,0.16,13.03,0.89,17.08,7.3c3.65,5.77,7.42,11.46,11.31,17.07C917.18,526.41,919.34,528.63,921.3,530.97z M316.75,951.81
                c0.39-12.31,6.23-21.36,15.45-28.64c1-0.79,1.07-3.71,0.55-5.31c-4.27-13.2-17.82-19.82-32.28-15.97
                c-11.2,2.98-15.2,9.82-12.29,20.99c0.43,1.66,0.77,3.35,1.22,5.31c-1.23-0.07-1.79,0.05-2.17-0.15
                c-9.13-4.92-10.56-4.31-14.63,5.61c-0.41,1.01-2.48,1.84-3.8,1.87c-3.3,0.06-6.64-0.76-9.93-0.55c-9.6,0.6-13.8,7.5-10.25,16.41
                c0.69,1.72,1.37,3.44,2.33,5.84c-5.19-1.31-9.28-2.72-13.49-3.34c-9.68-1.41-15.33,5.79-12.05,14.93
                c0.83,2.33,1.96,3.31,4.44,2.18c9.59-4.36,21.41-0.04,28.32,8.89c1.1,1.42,2.24,2.8,3.98,4.96c3.52-7.98,9.04-6.87,13.9-4.21
                c1.8-4.27,2.43-9.28,5.26-11.51c2.85-2.25,7.87-1.75,12.18-2.49C297.12,949.1,297.31,948.97,316.75,951.81z M645.14,386.22
                c-0.21-0.55-0.43-1.1-0.64-1.66c-7.01-0.23-13.89-0.29-20.65,2.94c-2.91,1.39-6.79,2.1-9.89,1.46
                c-8.71-1.78-15.91,2.05-23.24,5.26c-8.56,3.75-16.14,10.39-26.74,5.54c-5.34-2.44-7.3-0.35-6.43,5.39
                c0.62,4.11,1.45,8.19,2.1,12.29c0.17,1.11,0.07,2.27,0.03,3.4c-0.31,7.73-2.14,15.77-0.66,23.14c3.35,16.69,3.36,33.41,3.17,50.2
                c-0.04,3.59-1.36,7.16-1.89,10.77c-0.6,4.1-0.97,8.23-1.44,12.35c0.87,0.21,1.74,0.42,2.61,0.63c2.43-6.92,4.88-13.83,7.27-20.76
                c2.59-7.51,4.46-15.35,7.86-22.47c3.99-8.36,9.29-16.08,14.01-24.09c0.5-0.85,1.06-1.75,1.23-2.69
                c3.74-20.72,17.59-34.15,33.26-46.27C631.75,396.51,638.45,391.37,645.14,386.22z M986.38,159.15c-0.21-2.18-0.2-3.17-0.42-4.1
                c-3.49-14.9-6.44-29.96-10.68-44.65c-3.95-13.71-9.01-27.12-14.18-40.43c-1.16-2.97-4.45-5.83-7.46-7.17
                c-3.27-1.46-5.25-2.98-5.9-6.49c-0.63-3.43-0.98-7.13-2.59-10.1c-1.71-3.15-4.28-6.45-7.32-8.11c-3.54-1.93-6.28-3.96-8.12-7.56
                c-2.08-4.07-6-5.25-11.1-6.08c1.6,7.19,2.93,14.31,8.95,17.66c4.42,2.46,5.4,5.46,5.67,9.41c0.98,14.27,1.66,28.57,2.66,42.84
                c0.69,9.78,1.44,19.58,2.7,29.3c0.8,6.2,4.05,10.41,10.93,11.92c3.77,0.83,7.41,3.55,10.45,6.18
                C967.77,148.52,976.01,154.46,986.38,159.15z M921.79,1457.09c1.9,0.44,3.73,1.08,5.61,1.26c14.13,1.33,28.19,1.05,42.23-1.37
                c3.85-0.66,6.09-2.82,6.25-6c0.67-13.82-1.47-25.25-18.79-28.32c-4.96-0.88-9.63-3.32-14.48-4.9c-4.33-1.41-8.66-3.3-13.11-3.73
                c-5.07-0.49-10.36-0.12-15.4,0.78c-13.21,2.36-23.75,8.41-27.25,22.57c-1.03,4.15-1.44,8.46-2.01,12.72
                c-1.03,7.7,4.47,12.09,11.7,9.41c7.18-2.66,11.65-0.3,13.33,7.06c0.86,3.77,3.94,4.87,6.82,2.45c2.86-2.41,5.75-4.8,8.78-7.32
                c-4.17-1.37-9.45-2.29-6.73-9.33c-3.02-0.59-5.67-0.89-8.17-1.66c-2.67-0.82-7.3-1.89-7.42-3.19c-0.54-5.75-5.17-5.67-8.57-7.44
                c-5.17-2.7-5.31-6.17-0.64-9.92c1.86-1.49,3.91-3.21,4.87-5.29c2.97-6.41,11.44-8.4,18.74-2.99c-3.56,0-6.09,0.11-8.6-0.03
                c-3.79-0.21-6.32,0.83-7.3,5.02c-0.39,1.67-2.28,3.05-3.64,4.42c-1.31,1.31-2.81,2.42-5.16,4.41c3.62,1.56,6.27,2.41,8.62,3.79
                c2.19,1.28,4.08,3.09,6.05,4.72c1.38,1.14,2.49,2.79,4.04,3.49c1.76,0.79,3.91,0.68,5.84,1.14
                C922.97,1450.14,923.62,1451.29,921.79,1457.09z M2.92,1085.31c-0.39,2.66-0.79,4.24-0.82,5.82c-0.08,5.82,0.14,11.65-0.08,17.47
                c-0.16,4.08,1.64,5.71,5.51,5.91c9.89,0.53,18.47,4.72,27.13,9.19c5.47,2.82,11.68,4.81,17.77,5.7
                c10.58,1.54,21.22,2.67,30.75,7.84c16.16,8.77,33.65,13.3,51.44,17c2.62,0.54,5.35,0.53,7.37,0.72
                c-3.97-4.64-9.13-8.66-11.44-13.93c-2.32-5.3-5.13-9.15-10.07-11.59c-12.06-5.96-24.09-11.99-36.33-17.57
                c-3.19-1.46-7.3-2.47-10.61-1.81c-7.62,1.52-14.67,0.07-21.45-2.69c-12-4.89-23.79-10.29-35.63-15.56
                C12.24,1089.93,8.12,1087.82,2.92,1085.31z M878.21,396.62c-0.25,0.47-0.49,0.95-0.74,1.42c0.15-2.07,0.56-4.17,0.39-6.22
                c-0.72-8.76-1.88-17.48-2.33-26.25c-0.31-6.07-0.37-12.29,0.65-18.24c1.16-6.81,4.36-13.31,1.38-20.41
                c-0.48-1.13-0.22-2.65-0.08-3.96c1.33-12.41,4.3-24.14,12.41-34.18c5.39-6.67,4.79-8.75,1.03-16.25
                c-4.97-9.92-15.23-14.75-21.49-23.36c-0.38-0.52-3.53-0.09-3.98,0.7c-1.44,2.54-2.29,5.43-3.25,8.22
                c-0.96,2.8-1.81,5.64-2.64,8.49c-1.28,4.4-3.23,8.77-3.59,13.25c-1.35,16.56,1.21,32.86,4.57,49.02
                c1.86,8.97,1.95,17.82-0.35,26.69c-1,3.86-2.11,7.7-3.01,11.59c-1.14,4.92-1.04,10.11,2.6,13.69
                C865.52,386.49,872.02,391.39,878.21,396.62z M1110.46,916.1c15.01,14.78,29.78,27.94,49.53,33.97c0.47-0.93,0.75-1.23,0.75-1.52
                c0.08-23.96,0.14-47.92,0.2-71.88c0.01-2.87-1.72-3.76-4.25-3.55c-9.29,0.78-17.11-3.34-25-7.33c-1.28-0.65-2.7-1.07-4.11-1.4
                c-3.92-0.92-8.58-0.69-11.64-2.81c-6.5-4.49-12.12-10.25-18.22-15.34c-1.63-1.36-3.72-2.18-5.59-3.25
                c0.78,10.02,9.94,14.97,10.92,24.03c13.94,16.02,28.82,30.91,48.48,40c1.34,0.62,2.54,1.55,4.25,2.62
                c-6.79,4.22-13.07,4.8-19.71,1.89c0.21,5.27-0.91,6.89-5.5,6.68C1124.33,917.93,1118.12,916.94,1110.46,916.1z M745.36,1246.6
                c8.84,3.75,17.03,7.28,25.29,10.63c0.81,0.33,2.41-0.04,3.04-0.67c3.84-3.91,7.96-7.68,11.06-12.13c1.69-2.43,2.91-6.62,1.99-9.16
                c-2.07-5.7,0.31-9.28,3.22-13.64c10.09-15.12,19.74-30.54,29.55-45.84c0.18-0.28,0.27-0.61,0.45-0.89
                c2.17-3.36,1.12-5.12-2.47-6.77c-4.77-2.2-9.68-4.62-13.61-8.01c-5.96-5.15-17.48-6.89-23.82-2.18
                c-6.4,4.76-12.51,9.92-18.7,14.97c-0.79,0.64-1.25,1.67-2.74,3.73c6.79,0,12.4-0.38,17.95,0.09c9.01,0.76,11.52,3.9,11.37,12.89
                c-0.03,1.66-0.32,3.45,0.19,4.96c2.74,8.13-0.79,14.5-6.06,19.87c-3.55,3.62-7.99,6.49-12.37,9.15
                C760.05,1229.47,752.43,1237.3,745.36,1246.6z M481.64,825.23c5.15-3.56,5.58-7.26,6.17-11.05c0.81-5.23,1.48-10.59,3.16-15.57
                c3.34-9.94,4.49-19.22-0.71-29.25c-5.04-9.7-8.27-20.33-12.49-30.46c-0.92-2.22-2.54-5.69-4.08-5.82
                c-4.34-0.37-5.79-3.37-6.9-6.25c-4.59-11.94-8.82-24.01-13.09-36.07c-0.34-0.95-0.04-2.13-0.04-3.2c0.41-0.37,0.82-0.74,1.23-1.11
                c4.67,3.12,9.34,6.24,13.43,8.97c-1-5.84-0.92-12.61-3.36-18.29c-6.69-15.56-14.42-30.68-21.8-45.94
                c-0.61-1.26-1.66-2.31-2.51-3.46c-0.43,0.16-0.86,0.31-1.29,0.47c3.69,12.99,7.38,25.97,11.06,38.96
                c-0.44,0.24-0.88,0.47-1.32,0.71c-2.16-1.83-4.33-3.65-6.49-5.48c0.24,3.01,1.29,5.57,2.21,8.17c0.66,1.86,2.22,4.69,1.51,5.54
                c-3.41,4.09-0.28,7.46,0.47,11.09c0.34,1.65,0.63,3.31,1.21,6.38c-3.49-1.9-5.67-3.09-9.04-4.93c1.08,3.27,1.67,5.46,2.51,7.55
                c4.55,11.27,9.35,22.44,13.72,33.77c8.31,21.57,16.51,43.19,24.44,64.9c1.13,3.1,0.58,6.81,0.79,10.24
                C480.82,811.39,481.19,817.69,481.64,825.23z M714.35,591.82c-2.31-3.2-4.31-5.65-5.93-8.32c-1.18-1.95-2.31-4.13-2.67-6.33
                c-1.19-7.21-2.48-14.46-2.82-21.74c-0.22-4.63,0.48-9.59,2.06-13.94c1.44-3.98,1.24-6.61-1.35-9.96
                c-1.71-2.22-2.89-5.71-2.61-8.45c0.62-6.02,2.03-12.02,3.69-17.86c1.22-4.3,0.71-7.87-2.2-11.1c-3.25-3.59-4.2-7.76-3.84-12.47
                c1.63-20.77,2.07-41.58-0.23-62.3c-0.98-8.8,2.78-13.82,10.11-17.35c1.56-0.75,3.69-1.95,4.03-3.32c1.8-7.33,3.12-14.78,4.6-22.2
                c-0.38-0.39-0.77-0.79-1.15-1.18c-2.57,2.2-5.74,3.97-7.59,6.66c-11,15.98-12.25,34.63-13,53.04c-0.73,18.1,0.17,36.27,0.22,54.41
                c0.04,12.48-0.32,24.96-0.18,37.43c0.19,16.45,1.13,32.89,0.96,49.33c-0.25,23.79-2.08,47.59-1.55,71.35
                c0.38,17.19-0.8,33.83-3.25,50.9c-3.34,23.24-2.43,47.1-3.31,70.7c-0.02,0.46,0.32,0.94,0.53,1.54
                c1.69-5.44,2.33-11.09,5.06-15.41c3.78-5.99,4.06-12.11,3.91-18.62c-0.06-2.33-0.12-4.66-0.04-6.99
                c0.25-7.57,0.71-15.03,11.2-16.59c-1.63-2.64-3.15-4.59-4.11-6.79c-2.7-6.18-7.23-12.43-7.35-18.72
                c-0.42-20.77,0.55-41.59,1.53-62.37c0.23-4.98,2.44-9.88,3.91-14.78c0.8-2.66,2.23-5.16,2.85-7.85
                C706.91,597.82,708.44,593.58,714.35,591.82z M246.24,1132.31c-2.11,0.54-3.66,1.01-5.23,1.32c-3.68,0.73-4.99,2.68-4.02,6.46
                c0.42,1.61-0.11,3.46-0.21,5.2c-1.8-0.54-4.05-0.58-5.32-1.72c-5.35-4.82-11.78-6.39-18.61-7.64
                c-23.86-4.34-47.65-9.01-71.48-13.53c-0.92-0.18-1.89-0.14-3.21-0.22c-0.67,4.7,1.47,7.99,4.45,10.68c4.19,3.78,9,6.92,12.97,10.9
                c9.44,9.44,22.14,12.39,33.78,17.37c2.87,1.23,6.59,0.37,9.88,0.77c4.11,0.5,8.37,0.77,12.2,2.15c3.51,1.27,5.88,0.76,8.56-1.55
                c6.32-5.45,13.79-8.15,21.99-9.83c4.95-1.02,9.52-4.19,14.05-6.77c0.93-0.53,1.72-3.33,1.17-4.11
                c-3.64-5.19-7.65-10.12-11.55-15.13c-0.41,0.29-0.83,0.59-1.24,0.88C244.94,1128.85,245.44,1130.19,246.24,1132.31z
                M613.1,1196.19c1.12-0.63,1.66-0.77,1.94-1.12c3.24-4.01,6.5-8,9.63-12.09c9.05-11.83,9.1-11.92-1.34-22.32
                c-2.28-2.27-5.41-5.51-7.95-5.34c-8.01,0.53-13.08-3.66-18.26-8.56c-3.41-3.23-7.84-5.41-12.33-3.41
                c-9.76,4.33-21.44,6.6-24.04,19.87c-0.39,1.98-2.32,3.6-3.31,5.51c-2.14,4.17-4.13,8.41-6.91,14.13
                C574.58,1178.44,596.23,1177.98,613.1,1196.19z M1159.67,1019.11c0.58-1.71,1.23-2.75,1.24-3.8c0.07-18.3,0.09-36.61-0.03-54.91
                c-0.01-1.28-1.17-2.56-1.8-3.83c-0.47,0.07-0.95,0.14-1.42,0.21c-0.02,4.09-1.54,7.1-5.8,6.22c-5.14-1.06-9.79-1.95-13.57,3.08
                c-0.74,0.98-3.12,1.07-4.72,1c-2.8-0.13-5.74-0.26-8.35-1.18c-8.85-3.14-17.57-6.64-26.36-9.96c-0.97-0.37-2.02-0.5-3.03-0.74
                c-0.27,0.39-0.54,0.79-0.81,1.18C1112.68,980.96,1131.45,1004.4,1159.67,1019.11z M886.57,981.78
                c-14.78,9.38-25.62,20.57-32.56,35.25c-2.69,5.7-7,9.75-12.47,12.42c-6.35,3.11-10.17,8.14-13.35,14.23
                c-3.21,6.14-7.03,11.99-11.01,17.68c-3.83,5.47-8.2,10.57-12.33,15.83c8.66-2.93,17.09-1.67,25.4-0.15
                c3.69,0.67,5.27-0.75,7.19-3.54c5.64-8.18,11.79-16.02,17.48-24.17c1.4-2,2.57-4.71,2.52-7.06c-0.11-5.41,1.43-9.95,4.26-14.53
                c7.25-11.73,14.2-23.66,21.04-35.63C884.35,989.3,885.06,985.97,886.57,981.78z M936.06,1281.44c4.05-5,9.34-9.84,5.24-17.6
                c-2.74-5.17-4.25-11.55-11.14-13.04c1.87,7.53,3.65,14.72,5.44,21.91c-0.41,0.22-0.82,0.44-1.23,0.66
                c-1.31-2.75-3.38-5.4-3.77-8.28c-0.74-5.35-1.64-10.1-7.43-12.3c-1.51-0.57-2.7-1.93-4.11-2.8c-11.08-6.81-21.63-2.14-25.68,6.7
                c-3.12,6.81-7.7,13.28-15.97,15.34c-1.24,0.31-2.68,1-3.39,1.97c-1.19,1.65-2.66,3.75-2.49,5.51c0.13,1.35,2.42,2.92,4.08,3.6
                c2.1,0.85,4.51,1.03,6.81,1.26c12.54,1.26,25.08,2.48,37.63,3.65c1.97,0.18,4.05,0.36,5.94-0.06c1.93-0.43,5.33-1.81,5.25-2.42
                c-0.3-2.42-1.1-6.02-2.81-6.82c-9.55-4.46-7.22-13.76-10.18-22.12c4.13,2.67,4.98,5.63,5.63,9.2c0.54,2.95,2.2,5.94,4.1,8.33
                C930.05,1276.77,932.98,1278.72,936.06,1281.44z M233.79,1140.99c-1.23-7.67,3.57-9.25,8.61-10.75
                c-3.13-5.22-5.95-9.8-11.49-11.27c-4.94-1.31-10.02-2.61-15.09-2.85c-20.03-0.92-38.79-7.17-57.6-13.23
                c-4.98-1.61-11.83,0.65-13.93,5.59c-1.4,3.3-2.64,6.68-4.15,10.53c1.72,0.54,3.52,1.33,5.4,1.68c18.13,3.32,36.29,6.5,54.42,9.85
                C211.13,1132.6,223.09,1131.86,233.79,1140.99z M561.09,376.76c-1.69-0.41-3.43-0.64-5.02-1.25c-8.4-3.22-10.4-2.08-11.61,6.54
                c-0.89-2.17-2.15-5.39-1.11-6.54c1.85-2.04,5.22-4.15,7.72-3.89c6.66,0.69,13.17,2.76,19.8,3.94c1.65,0.29,4.03-0.16,5.23-1.22
                c5.56-4.96,9.35-5.13,15.61-1.93c5.58,2.85,11.73,4.6,17.67,6.72c0.94,0.34,2.11,0.05,3.42,0.05c-0.51-2.36-0.4-4.7-1.48-6.13
                c-1.16-1.54-3.71-3.41-5.2-3.08c-4.67,1.02-7.74-0.49-10.19-4.22c-2.32-3.53-5.34-4.88-9.8-5.47c-5.53-0.73-10.67-1.24-15.93,0.79
                c-0.84,0.33-1.94-0.05-2.92-0.02c-2.97,0.1-5.96,0.02-8.89,0.43c-6.86,0.96-13.8,6.93-20.28-1.63c-2.3-3.03-6.24-1.14-8.98,1.04
                c-2.97,2.37,0.08,3.94,0.94,5.86c1.14,2.56,2.18,5.19,3.04,7.85c1.12,3.48,1.62,7.22,3.18,10.46c1,2.06,3.24,3.78,5.31,5.01
                c2.96,1.77,6.23,3.06,9.44,4.38c7.25,2.97,15.14,2.01,22.62,3.58c0.13,0.03,0.29-0.14,0.45-0.2c3.26-1.24,6.64-2.24,9.75-3.79
                c2.49-1.24,4.66-3.14,6.98-4.75c-0.22-0.57-0.43-1.14-0.65-1.7c-1.77,0-3.55,0.03-5.32-0.01c-1.9-0.04-4.12,0.49-5.62-0.32
                c-3.56-1.91-7.51-2.85-10.73-5.94c-1.73-1.66-6.42-0.43-9.74-0.12c-1.29,0.12-2.48,1.28-3.72,1.97c-0.3-0.4-0.61-0.81-0.91-1.21
                C556.41,380.23,558.71,378.53,561.09,376.76z M1072.72,1466.3c0.01,0.18,0.03,0.37,0.04,0.55c6.12-0.6,12.26-1.02,18.33-1.96
                c1.25-0.19,3.38-2.86,3.09-3.54c-2.28-5.37-0.72-10.44,0.25-15.69c0.41-2.21,0.2-5.2-1.02-6.94
                c-5.39-7.71-12.92-12.29-22.51-11.33c-5.69,0.57-11.29,2.52-16.81,4.22c-7.1,2.18-11.98,6.23-10.94,14.7
                c0.19,1.58-0.68,3.27-0.7,4.92c-0.02,1.24,0.2,2.74,0.9,3.69c5.17,7.01,11.83,11.42,20.88,11.38
                C1067.06,1466.29,1069.89,1466.3,1072.72,1466.3z M438.12,470.44c-2-2.23-3.05-3.45-4.15-4.62c-5.35-5.69-11.16-11.03-15.96-17.15
                c-5.24-6.7-11.59-8.99-19.62-7.49c-4.63,0.87-8.51-0.5-10.71-4.51c-2.29-4.16-5.36-6.47-9.98-7.19c-1.58-0.25-3.17-1.1-4.52-2.03
                c-2.88-1.98-5.59-2.26-8.83-0.64c-5.51,2.75-11.04,1.84-16.27-0.98c-2.77-1.49-5.54-3.13-7.95-5.13
                c-3.32-2.75-6.77-2.48-10.25-0.95c-0.98,0.43-2.14,2.2-1.99,3.16c0.61,3.95,9.24,11.17,13.41,11.11
                c8.27-0.12,15.88,2.08,22.71,6.48c6.54,4.21,12.65,9.07,19.03,13.54c1.58,1.11,3.34,2.13,5.17,2.66c5.63,1.63,11.34,2.99,17,4.49
                c1.56,0.41,3.3,1.72,4.57,1.34c4.47-1.36,8.29,0.16,10.95,3.09C425.29,470.64,430.91,470.21,438.12,470.44z M448.31,502.98
                c-0.24,0.49-0.49,0.98-0.73,1.47c1.45,1.24,2.79,2.62,4.35,3.69c9.92,6.86,18.93,14.57,25.48,24.95c2.71,4.29,6.72,7,12.29,7.51
                c5.93,0.55,7.87,4.33,6.38,10.33c-0.3,1.2-0.62,3.14,0.01,3.71c4.75,4.31,9.31,9.1,14.78,12.27c4.97,2.87,10-1.96,9.54-7.89
                c-0.42-5.41-0.67-10.88-0.39-16.28c0.58-11.1-0.9-16.23-13.71-18.43c-9.01-1.55-18.57-1.49-26.92-6.53
                c-6.82-4.12-13.97-7.73-21.13-11.27C455.13,504.95,451.63,504.13,448.31,502.98z M989.29,876.99c-0.25-0.11-1.22-0.8-2.28-0.99
                c-5.88-1.07-9.22-4.74-11.23-10.17c-1.63-4.41-4.12-5.17-7.31-1.71c-3.1,3.36-5.81,7.32-7.76,11.46
                c-6.75,14.3-13.11,28.79-19.59,43.22c-0.79,1.76-1.39,3.6-2.4,6.23c2.14-0.54,3.38-0.77,4.56-1.16
                c15.93-5.22,27.09-17.17,39.05-27.93c1.53-1.38,2.61-3.53,3.27-5.53C987.01,886.16,988,881.78,989.29,876.99z M985.17,964.89
                c-9.42-2.31-9.26-2.19-14.81,4.88c-2.64,3.36-5.75,6.47-9.12,9.1c-3.31,2.58-7.13,3.42-10.95,0.25c-2.6-2.16-5.25-1.66-8.28-0.13
                c-4.37,2.21-8.99,4.55-13.72,5.18c-3.09,0.41-4.27,1.31-5.38,3.74c-4.16,9.07-8.45,18.09-12.64,27.14
                c-0.36,0.77-0.38,1.7-0.76,3.51C940.05,1007.26,967.54,993.34,985.17,964.89z M733.87,331.19c-8.19,8.81-18.91,13.84-28.88,21.11
                c4.07,0,8.14,0,12.16,0c-2.34,10.48-12.16,10.55-19.25,15.98c4.71,1.06,8.46,1.44,11.84,2.78c4.97,1.97,9.09,0.58,11.34-4.37
                c2.2-4.83,4.12-9.89,7.02-14.28c5.82-8.81,12.55-17.02,18.28-25.88c5.62-8.69,5.7-19.1,8.75-29.87
                c-9.16,3.47-16.9,6.41-25.62,9.71c2.1,1.12,3.2,1.7,4.31,2.29c-0.11,0.72-0.22,1.45-0.33,2.17c-5.01,2.44-10.02,4.89-16.59,8.09
                c6.3,0.49,11.31,0.88,16.41,1.27c-2.7,7.65-10.64,8.76-16.06,13.55C724.09,337.86,728.28,331.45,733.87,331.19z M437.83,215.17
                c8.09-3.59,8.09-3.59,3.05-10.25c8.59-7.48,8.59-7.48,3.76-15.07c8.65-5.5,11.92-14.63,15.41-23.89c1.01,2.02,1,3.95,1.57,5.69
                c1.37,4.16,2.45,8.58,4.7,12.25c1.97,3.22,6.14,4.31,9.1,1.61c1.23-1.12,1.02-5.5-0.17-7.32c-4.66-7.15-6.82-15-8.07-23.25
                c-0.37-2.47-0.85-4.92-1.1-7.4c-0.37-3.58-3.16-4.48-5.71-4.12c-1.48,0.21-2.91,2.51-3.88,4.15c-1.39,2.35-2.07,5.13-3.53,7.43
                c-1.99,3.16-4.68,5.89-6.6,9.09c-2.03,3.38-3.9,6.97-5.09,10.71c-1.07,3.38-1.11,7.09-1.61,10.66c-0.26,1.83-0.11,3.85-0.87,5.44
                c-3.41,7.09-5.15,14.29-2.68,23.07c-2.58,1.43-5.76,3.19-10.18,5.63c3.74,2.96,7.26,5.76,9.41,7.47
                c5.59-2.51,9.48-4.26,13.52-6.07C445.29,219.11,442.05,217.4,437.83,215.17z M853.2,1157.64c0.89-0.48,1.4-0.62,1.72-0.95
                c7.79-8.24,16.42-15.28,26.38-20.92c2.85-1.61,3.89-6.53,5.55-10.04c0.22-0.46-0.64-1.95-1.31-2.23c-5.2-2.17-10.37-4.52-15.77-6
                c-1.92-0.53-5.1,0.55-6.58,2.02c-3.82,3.78-8.18,5.87-13.29,7.28c-6.52,1.79-12.21,5.21-15.58,11.5c-1.5,2.81-1.28,4.54,2.17,6.1
                c3.98,1.8,7.59,4.56,11.09,7.26C849.72,1153.33,851.37,1155.65,853.2,1157.64z M1033.78,1225.98c-0.62-0.44-1.25-0.88-1.87-1.32
                c-1.61,2.05-3.47,3.96-4.8,6.19c-5.77,9.64-14.86,15.92-22.86,23.38c-6.56,6.12-11.97,13.47-18.78,21.29
                c6.15,0,10.74,0.16,15.3-0.1c1.27-0.07,2.66-1.22,3.66-2.21c8.15-8.1,16.22-16.28,24.35-24.41c0.12-0.12,0.59,0.1,1.5,0.27
                c-1.84,5.36-4.88,9.45-9.26,13.02c-4.9,3.99-9.17,8.74-13.71,13.16c0.25,0.47,0.49,0.95,0.74,1.42c6.4,0.52,12.8,1.17,19.22,1.44
                c0.9,0.04,2.55-1.68,2.71-2.75c1.41-9.53,2.89-19.08,3.68-28.67C1034.24,1239.83,1033.78,1232.89,1033.78,1225.98z
                M1155.26,951.97c-2.07-0.91-4.21-1.74-6.25-2.77c-16.09-8.07-30.25-18.67-41.62-32.7c-2.53-3.13-4.11-6.76-9.28-5.74
                c-1.39,0.27-3.54-1.14-4.71-2.37c-3.99-4.2-8.01-8.45-11.41-13.12c-2.75-3.78-4.61-8.2-6.86-12.33
                c-0.74,6.03,2.19,10.41,5.87,14.45c6.61,7.25,13.31,14.42,19.84,21.73c1.16,1.29,2.03,3.02,2.53,4.69
                c3.19,10.84,7.57,20.88,17.26,27.66c1.32,0.92,2.39,2.2,3.23,2.99c-1.92,1.73-3.25,2.93-4.58,4.13c1.64,1.13,3.15,2.65,4.97,3.31
                c2.47,0.89,5.15,1.32,7.78,1.6c3.12,0.33,5.75-0.3,7.53-3.57c0.63-1.16,3.19-1.86,4.82-1.77c2.69,0.15,5.34,1.11,7.91,1.7
                C1153.4,956.89,1154.25,954.64,1155.26,951.97z M979.92,1437.34c2.81-1.04,6.29-2.07,9.55-3.59c7.88-3.67,14.62-1.59,18.87,5.9
                c0.73,1.28,2.08,2.21,3.14,3.3c1.11-1.28,2.4-2.45,3.29-3.87c1.94-3.07,3.35-6.53,5.56-9.38c6.97-8.99,4.26-16.55-7.04-18.69
                c-12.06-2.29-23.32,1.62-34.55,5.01c-2.48,0.75-4.62,3.2-6.44,5.29c-0.75,0.86-0.77,3.17-0.14,4.25
                C974.45,1429.53,977.16,1433.24,979.92,1437.34z M1016.12,1472.2c0.14-0.34,0.28-0.67,0.42-1.01c3.3-0.31,6.6-0.65,9.9-0.93
                c5.13-0.44,10.31-0.57,15.37-1.41c2.02-0.34,4.85-1.9,5.35-3.52c0.48-1.56-1.08-4.48-2.61-5.78c-4.17-3.53-8.65-6.75-13.28-9.66
                c-8.85-5.57-18.35-4.19-27.3-0.8c-7.53,2.85-8.99,11.49-2.9,15.86C1005.52,1468.16,1011.06,1469.84,1016.12,1472.2z M908.9,986.78
                c0.52,0.41,1.05,0.81,1.57,1.22c1.98-1.68,4.09-3.21,5.9-5.06c3.13-3.21,6.32-4.41,10.66-2.08c1.49,0.8,3.91,0.58,5.65,0.02
                c2.49-0.79,4.95-2.05,7.07-3.59c4.32-3.11,8.33-3.33,12.74-0.13c1.24,0.9,3.67,1.34,5,0.74c6.95-3.08,11.2-8.79,15.15-16.43
                c-4.57,0.55-7.94,1.02-11.32,1.34c-8.82,0.82-17.6,1.72-24.57-5.82c-2.08-2.25-10.18-1.83-11.43,0.35
                C919.75,967.1,914.36,976.96,908.9,986.78z M548.8,361.36c0.3-0.58,0.59-1.16,0.89-1.75c-1.41-1.97-2.83-3.93-4.23-5.91
                c-1.05-1.48-3.13-3.14-2.94-4.45c1.08-7.51-1.3-14.76-1.17-22.14c0.04-2.19,1.9-4.32,2.13-6.55c0.92-8.76,1.26-17.59,2.37-26.32
                c0.62-4.86,2.35-9.57,3.54-14.36c0.62-2.49,1.54-4.99,1.63-7.52c0.33-9.11-0.12-18.28,0.75-27.33c0.32-3.31,3.11-6.7,5.51-9.4
                c2.94-3.31,5.43-6.49,4.46-11.64c-1.84,0.87-3.23,2.13-4.57,3.46c-1.73,1.73-3.1,4.13-5.17,5.13c-3.48,1.69-4.31,4.23-4.28,7.64
                c0.05,5.99,0.14,11.99-0.08,17.97c-0.08,2.24-0.86,4.47-1.49,6.67c-0.84,2.98-2.52,5.89-2.63,8.88
                c-0.25,6.96,0.38,13.96,0.33,20.93c-0.03,3.92-0.19,7.97-1.17,11.73c-1.68,6.46-4.78,12.62-5.96,19.14
                c-1.5,8.28-4.53,14.99-12.12,19.21c-0.84,0.46-1.79,1.98-1.62,2.77c0.81,3.81,2,7.53,3.18,11.77c6.19-4.92,12.01-5.56,17.47,1.07
                C544.5,361.41,547.04,361.06,548.8,361.36z M409.96,389.87c-7.89,6.76-9.62,7.36-20.17,9.14c-3.03,0.51-6.21,1.57-8.73,3.26
                c-3.44,2.31-2.84,4.16,0.6,6.24c8.17,4.93,16.2,10.09,24.23,15.25c4.87,3.12,9.62,6.41,14.43,9.63c0.26-0.35,0.52-0.7,0.78-1.05
                c-2.12-2.29-4.01-4.87-6.42-6.8c-3.75-3-7.9-5.47-11.78-8.31c-2.21-1.62-5.77-3.98-2.83-6.59c4.62-4.09,9.92-8.63,15.64-10.02
                c12.35-3,25-2.96,37.16,2.47c2.94,1.32,6.58,1.07,10.6,1.63c-2.72-3.08-4.31-5.14-6.18-6.9c-1.37-1.3-2.98-2.78-4.71-3.18
                c-4.98-1.14-10.06-2.35-15.12-2.44c-6.39-0.11-12.95,0.22-19.17,1.57C414.09,394.7,411.11,395.58,409.96,389.87z M564.59,338.71
                c4.95,1.73,3.63,4.17,0.36,7.69c9.99-0.44,19.14,3.02,25.58-6.62c-0.14,1.86-0.27,3.72-0.48,6.55c3-0.8,5.44-1.44,8.64-2.29
                c-6.16-4.49-6.51-7.8-2.41-12.41c0.53-0.59,0.96-2.14,0.65-2.44c-0.69-0.67-2.01-1.33-2.83-1.11c-5.67,1.48-11.09,2.12-15.13-2.67
                c-2.39,1.12-4.37,2.06-6.35,2.99c-0.25-0.48-0.51-0.96-0.76-1.45c2.56-1.85,5.12-3.7,7.65-5.54c1.72,1.65,3.25,3.11,5.38,5.14
                c-0.11-2.46-0.17-3.74-0.24-5.35c2.29,1.42,4.03,2.5,7.28,4.52c0-5.5-0.25-9.54,0.06-13.54c0.45-5.99,0.07-6.62-5.97-6.95
                c-2.29-0.12-4.58-0.25-6.87-0.38c0.19,0.45,0.39,0.9,0.58,1.35c-2.49,1.75-6.79,3.24-7.1,5.31c-0.71,4.79-4.02,5.46-8.23,6.94
                c3.11,1.54,5.36,2.66,8.59,4.26c-2.87,2.38-5.15,4.28-7.58,6.3c1.83,1.68,3.59,3.29,5.78,5.3
                C568.97,335.81,567.37,336.87,564.59,338.71z M74.72,2.2c3.7,7.37,8.57,11.77,14.26,15.77c5.33,3.74,9.91,8.56,14.79,12.94
                c2.69,2.41,5.08,5.24,8.06,7.18c1.48,0.96,5.21,1.13,5.79,0.25c1.16-1.75,1.2-4.5,1.03-6.79c-0.19-2.58-2.42-5.75-1.51-7.52
                c3.2-6.28-1.08-9.07-4.59-12.5c-2.13-2.09-4.07-4.38-6.22-6.44c-1.17-1.11-2.59-2.72-3.94-2.76C93.51,2.08,84.61,2.2,74.72,2.2z
                M3.37,911.38c-2.59,6.84-1.8,14.44,1.68,17.07c14.98,11.34,30,22.62,45.02,33.9c1.32,0.99,2.8,1.76,4.21,2.63
                c0.29-0.3,0.58-0.59,0.86-0.89c-3-4.44,0.41-10.42-4.62-14.64c-11.83-9.91-23.19-20.38-34.99-30.33
                C11.92,916.07,7.51,913.97,3.37,911.38z M867.83,1283.18c-1.22-8.71,3.55-12.69,10.73-15.22c2.18-0.76,4.27-2.59,5.71-4.46
                c4.87-6.33,9.43-12.91,14.17-19.48c-0.63-0.1-1.63-0.54-2.51-0.37c-12.85,2.61-26.2,4.41-34.63,16.2
                c-3.52,4.92-7.76,8.44-13.22,10.93c-4.15,1.89-7.95,4.55-11.9,6.87c0.04,0.5,0.08,1,0.12,1.5c5.29,1.62,10.74,4.81,15.81,4.28
                c3.08-0.32,5.63-6.32,8.3-9.86c0.68-0.9,0.67-2.38,1.42-3.15c2.97-3.03,5.68-6.95,9.35-8.5c3.28-1.38,5.46-3.04,7.67-5.63
                c2.48-2.9,5.74-5.12,8.66-7.64c0.52,0.67,1.03,1.34,1.55,2.01c-8.06,2.17-8.86,12.76-17.87,13.94c-0.96,0.13-1.99,1.3-2.59,2.24
                c-3.31,5.22-6.49,10.51-10.05,16.33C862.08,1283.18,864.78,1283.18,867.83,1283.18z M1069.21,1408.95
                c-5.35,0.62-6.18,1.44-5.51,6.42c0.29,2.13,0.42,4.45,1.4,6.28c0.76,1.42,2.74,3.16,4.13,3.11c10-0.33,18.2,3.16,24.72,10.67
                c2,2.3,3.56,1.52,5.36-0.3c8.01-8.12,6.71-16.34-4.03-20C1086.86,1412.27,1077.92,1410.95,1069.21,1408.95z M971.81,1402.2
                c10.61,0.24,11.97-1.89,7.44-10.27c-1.24-2.3-2.9-4.45-4.7-6.36c-0.92-0.98-2.49-1.53-3.87-1.86c-3.3-0.78-6.74-1.05-9.96-2.03
                c-3.31-1.02-6-0.63-7.84,2.31c-3.23,5.16-5.25,10.75-4.65,16.98c0.37,3.92,2.24,5.47,6.38,4.51
                C960.65,1404.08,966.8,1403.14,971.81,1402.2z M590.92,220.75c-0.69,0.02-1.38,0.04-2.07,0.06c-0.58,10.7-1.34,21.39-1.55,32.1
                c-0.03,1.56,2.25,3.67,3.96,4.66c3.14,1.82,6.65,3,10.38,4.6c5.17-3.48,8.04-9.05,5.6-13.56
                C602.1,239.16,596.39,230.02,590.92,220.75z M176.54,1159.3c-1.26-0.61-2.46-1.37-3.78-1.8c-13.06-4.24-22.4-13.96-32.55-22.4
                c-2.27-1.89-4.36-5.11-4.71-7.94c-0.7-5.74-4.12-5.67-8.04-4.88c-2.28,0.46-4.4,1.76-7.37,3.01c2.82,1.82,5.05,2.77,6.58,4.37
                c2.23,2.32,5.05,4.89,5.66,7.77c1.13,5.37,4.26,8.7,8.1,12.05C150.85,1158.55,163.72,1158.61,176.54,1159.3z M1038.59,1451.44
                c7.07-11.99-0.11-32.55-12.57-35.87c3.05,7.7-0.35,13.26-5.13,18.62c-1.71,1.93-2.31,4.85-3.39,7.26
                c4.32,1.66,7.93,2.81,11.32,4.43C1032.2,1447.5,1035.35,1449.58,1038.59,1451.44z M911.12,1341.37c0.01-0.09,0.01-0.17,0.02-0.26
                c1,0,2.01,0.11,2.99-0.02c4.43-0.6,8.19-2.34,8.96-7.24c0.75-4.77-1.79-7.96-6.03-9.77c-2.43-1.04-4.96-2.01-7.55-2.45
                c-1.7-0.29-4.13-0.19-5.23,0.83c-2.53,2.33-4.85,5.1-6.51,8.1c-2.66,4.81-0.41,8.82,4.96,9.65
                C905.52,1340.64,908.32,1340.99,911.12,1341.37z M964.07,1478.2c2.01-0.35,5.69-0.65,9.13-1.7c3.34-1.02,3.84-3.34,1.36-6.06
                c-6.3-6.91-24.71-7.86-31.42-1.45c-1.28,1.23-3.03,3.86-2.56,4.62c0.94,1.55,3.17,2.95,5.02,3.19
                C951.17,1477.56,956.82,1477.71,964.07,1478.2z M167.57,510.68c18.16-16.05,18.31-39.08,23.19-60.37
                c-2.83,4.04-4.66,8.48-6.27,12.99c-1.32,3.71-1.92,7.74-3.63,11.24c-3.48,7.13-7.64,13.92-11.23,20.99
                C167.13,500.45,166.24,505.72,167.57,510.68z M869.92,1350.41c15.15-4.28,16.54-3.75,8.63-17.93c-0.24-0.43-0.56-0.83-0.8-1.26
                c-2.06-3.55-4.34-4.31-8.57-2.58c-4.91,2-6.81,5.67-7.96,9.99c-0.4,1.49,0.75,3.79,1.87,5.19
                C864.85,1346.06,867.19,1347.85,869.92,1350.41z M707.22,1475.8c-7.52-3.38-15.73-3.13-23.77,0.29c-3.62,1.54-3.5,4.99-0.25,7.4
                c2.26,1.67,4.31,3.79,6.8,4.93c2.39,1.09,5.29,1.74,7.89,1.56c2.87-0.2,5.66-1.57,8.34-2.39
                C706.55,1483.83,706.85,1480.17,707.22,1475.8z M988.61,1455.41c2.58-1.64,5.25-3.17,7.72-4.95c2-1.44,3.62-3.43,5.69-4.76
                c4.22-2.7,4.52-4.87,0.49-7.94c-3.31-2.52-6.79-4.12-10.92-1.28c-1.59,1.09-3.63,1.56-5.5,2.21c-4.42,1.55-6.71,5.61-6.04,10.66
                C980.69,1454.2,984.38,1454.09,988.61,1455.41z M359.89,157.84c-3.98,7.39-7.49,13.7-10.75,20.14c-0.57,1.12-0.73,3.44-0.06,4.01
                c3.32,2.85,6.98,5.31,10.52,7.9c0.35-0.24,0.69-0.48,1.04-0.72c-0.64-4.15-1.28-8.29-1.92-12.44c0.57-0.14,1.14-0.27,1.71-0.41
                c0.96,3.42,2.38,6.79,2.77,10.27c0.67,6.01,1.49,11.68,8.16,16.11c-1.34-2.73-3.06-4.68-2.62-5.59c2.89-6.03-1.65-9.94-3.63-14.58
                c-0.84-1.97-2.62-4.47-2.02-5.95C365.74,169.94,361.81,164.81,359.89,157.84z M1102.03,1456.73c2.48-0.26,5.11-0.08,7.4-0.87
                c3.41-1.17,6.58-3.03,9.85-4.6c-2.27-2.86-4.07-6.37-6.9-8.47c-11.71-8.7-13.04-8.11-16.28,5.83c-0.04,0.16-0.08,0.32-0.12,0.48
                C1094.27,1456.45,1094.67,1456.96,1102.03,1456.73z M952.05,1418.31c-0.43-5.44-1.07-9.64-5.52-11.55
                c-4.86-2.08-9.8-4.43-14.94-5.28c-4.2-0.69-8.96,0.01-13.02,1.41c-1.94,0.67-2.82,4.39-4.18,6.73c2.1,0.4,4.23,1.27,6.28,1.11
                c9.28-0.74,18.3-0.83,26.11,5.49C947.96,1417.17,949.72,1417.41,952.05,1418.31z M903.38,1318.05c-2.03-1.79-4.01-3.11-5.43-4.89
                c-2.47-3.1-4.98-2.78-6.79,0.08c-2.8,4.43-4.93,9.29-7.5,13.87c-2.24,4-0.79,7.72,1.57,10.57c1.34,1.62,4.62,1.65,7.03,2.4
                c0.46-1.64,0.92-3.28,1.39-4.91c0.7-2.48,0.95-5.24,2.23-7.38C897.78,1324.56,900.43,1321.78,903.38,1318.05z M892.46,1478.18
                c2.97-0.63,6.97-1.1,10.7-2.36c4.76-1.61,3.88-5.95,2.88-9.29c-0.55-1.84-3.75-4.63-4.87-4.27c-6.72,2.19-13.25,5.03-19.67,8.03
                c-0.94,0.44-1.5,3-1.2,4.34c0.23,1.02,2.01,2.09,3.28,2.39C886.15,1477.63,888.84,1477.74,892.46,1478.18z M554.65,512.25
                c2.29-6.43,3.66-11.94-3.36-15.87c-2.23-1.25-3.71-3.78-5.76-5.44c-1.71-1.39-3.75-2.38-5.85-3.67c-1.27,2.68-1.95,4.99-3.3,6.77
                c-2.56,3.36-1.76,5.62,1.56,7.67C543.41,505.08,548.82,508.56,554.65,512.25z M571.89,1284.91c0.54-0.15,1.07-0.29,1.61-0.44
                c-1.29-5.88-2.59-11.76-4.19-19.02c4.66,2.59,8.05,4.46,12.27,6.8c-1.31-3.9-2-7.34-3.56-10.33c-3.36-6.43-9.37-5.94-14.02-0.55
                c-3.27,3.79-3.62,6.34-0.84,9.98c0.35,0.46,0.36,1.26,0.37,1.91C563.63,1279.23,567.57,1282.21,571.89,1284.91z M779.11,1488.21
                c8.88,0.01,11.73-1.75,11.67-6.96c-0.08-6.17-2.82-9.84-7.45-9.94c-5.52-0.12-11.04,5.79-11.18,11.9
                C772.02,1488.76,776.33,1487.63,779.11,1488.21z M947.1,1277.01c-0.52,0.06-1.52,0.06-2.48,0.28c-5.26,1.21-7.76,4.82-7.82,10.51
                c-0.04,4.34,2.07,6.27,6.13,6.85c5.43,0.78,6.93-0.2,9.35-6.59c0.23-0.62,0.47-1.23,0.75-1.83
                C955.88,1280.22,954.15,1277.3,947.1,1277.01z M970.94,1462.61c3.59,3.33,6.69,6.22,8.95,8.32c5.84-1.8,11.03-3.39,16.47-5.07
                c-1.13-2.38-1.77-6.51-3.8-7.38c-4.11-1.77-9.08-2.66-13.51-2.17C976.31,1456.63,973.93,1460.16,970.94,1462.61z M805.33,202.34
                c-7.86,2.04-8.4,9.16-12.27,14.86c6.98,2.65,12.57,6.43,14.87,13.74C808.98,221.22,809.81,211.87,805.33,202.34z M156.86,156.97
                c0.57-0.24,1.15-0.49,1.72-0.73c-7.49-17.21-14.98-34.43-22.48-51.64c-0.38,0.16-0.76,0.32-1.15,0.49
                C139.39,123.57,144.29,141.87,156.86,156.97z M636.39,1251.78c1.81,1.84,3.66,2.85,3.67,3.88c0.03,4.26,1.19,8.81-2.7,12.44
                c-0.81,0.76-0.07,3.18-0.05,4.84c1.78-0.04,4.83,0.48,5.13-0.21c2.7-6.1,7.2-11.99,5.07-19.15c-0.74-2.47-3.11-5.26-5.41-6.14
                C640.89,1246.96,638.23,1250.3,636.39,1251.78z M999.24,1406.68c3.27,0,6.05-0.14,8.81,0.04c2.97,0.2,5.92,0.66,8.87,1.07
                c2.27,0.32,4.69,1.29,5.3-2.35c0.52-3.14,0.64-5.74-2.96-7.41C1011.31,1394.36,1003.26,1397.52,999.24,1406.68z M856.06,1336.35
                c-0.55-0.2-1.12-0.61-1.65-0.57c-1.81,0.13-3.6,0.42-5.4,0.65c0.61,1.39,1,2.95,1.88,4.13c2.52,3.35,5.1,6.69,7.97,9.73
                c1.01,1.07,2.95,1.27,4.47,1.87c0.34-0.38,0.69-0.76,1.03-1.14c-0.79-1.43-1.22-3.53-2.43-4.17c-5.4-2.87-5.67-6.75-3.09-11.62
                c1.32-2.49,0.39-3.86-2.28-4.78c-3.67-1.26-7.3-1.42-9.34,2.03c-1.63,2.76-3.19,6.65-2.48,9.45c1.07,4.16,5.62,5.45,9.44,6.73
                c-1.94-2.85-4.15-5.25-6.41-7.6c-2.44-2.54-2.7-5.22,0.44-7.04C851.16,1332.29,854.23,1332.57,856.06,1336.35z M896.41,1495.39
                c0.21-0.42,0.41-0.84,0.62-1.26c0.99,0,2.03,0.19,2.96-0.03c3.44-0.83,8.34,0.4,8.98-4.9c0.39-3.28-6.51-6.96-11.06-6.28
                c-0.81,0.12-1.66,0.29-2.38,0.66c-3.25,1.66-8.47,2.43-7.56,6.94C888.4,1492.62,893.45,1493.79,896.41,1495.39z M998.54,1482.27
                c0.1-0.24,0.21-0.48,0.31-0.72c1.65-0.16,3.38-0.03,4.92-0.53c2.84-0.93,3.53-2.84,1.63-5.36c-3.45-4.57-15.33-6.45-19.84-3.23
                c-2.6,1.86-2.94,3.91,0.04,5.24C989.77,1479.52,994.21,1480.77,998.54,1482.27z M919.77,1490.95c3.13,0.16,5.57-0.37,5.61-4.38
                c0.05-5.34-4.52-11.53-8.84-11.63c-2.91-0.07-6.05,3.63-6.17,7.28C910.24,1486.33,915.01,1490.76,919.77,1490.95z M959.61,1406.8
                c-4.17,0.83-5.72,1.57-5.07,5.85c0.9,5.9,4.69,7.22,9.48,8.24c2.19,0.47,3.88,1.42,5.51-1.16c1.58-2.5,1.92-4.83-0.37-6.64
                C966.17,1410.74,962.8,1408.87,959.61,1406.8z M947.91,1272.24c4.16,3.22,7.69,6.13,11.43,8.73c0.99,0.69,2.92,0.94,3.92,0.42
                c1.02-0.52,1.96-2.22,2-3.43c0.18-5.34-3.31-7.95-7.87-9.42C953.33,1267.23,950.57,1269.31,947.91,1272.24z M768.13,163.23
                c-2.58,3.2-5.16,6.4-8.13,10.09c8.28,0,15.55,0,22.82,0c0.16-0.64,0.31-1.29,0.47-1.93C778.32,168.72,773.36,166.05,768.13,163.23
                z M1158.79,775.02c0.84-0.45,1.69-0.9,2.53-1.35c-0.6-3.32-0.16-8.58-2.02-9.57c-4.66-2.5-10.35-3.08-15.62-4.42
                c-0.34,0.6-0.68,1.19-1.02,1.79C1148.03,765.98,1153.41,770.5,1158.79,775.02z M991.59,1408.19c-4-4.88-13.35-5.36-23.96-0.83
                c3.06,2.25,5.8,5.89,8.13,5.65C981.01,1412.46,986.07,1410,991.59,1408.19z M891.99,1299.44c3.21-1.47,6.41-2.93,9.62-4.4
                c-3.21-1.73-6.25-4.03-9.67-5.02c-3.43-0.99-7.67-1.17-9.07,3.48C881.97,1296.46,885.9,1298.56,891.99,1299.44z M125.2,137.19
                c-1-11.35-9.49-18.66-14.84-27.68C113.34,119.76,115.31,130.54,125.2,137.19z M860.2,1467c-1.89,1.28-3.65,2.46-5.4,3.65
                c1.29,1.87,2.3,4.99,3.94,5.36c3.33,0.75,7.1,0.54,10.47-0.24c3.15-0.72,3.21-3.5,0.65-5.05
                C867.13,1469.07,863.85,1468.35,860.2,1467z M938.47,1467.07c-4.48-3.15-13.6-2.46-16.8,1.56c-0.6,0.76-0.32,3.33,0.43,3.88
                C926.44,1475.68,936.7,1472.13,938.47,1467.07z M775.63,151.95c-2.78,1.32-4.82,2.23-6.8,3.25c-2.08,1.08-4.1,2.28-6.68,3.72
                c1.34,0.94,2.12,1.88,2.6,1.75c3.87-1.09,6.41,1.38,9.26,3.15c1.11,0.69,2.38,1.26,3.65,1.48c0.94,0.16,2-0.36,3-0.57
                c-0.26-0.86-0.48-1.74-0.79-2.59c-0.85-2.31-1.69-4.62-2.61-6.91C776.78,154.06,776.14,152.97,775.63,151.95z M918.9,1321.38
                c-1.57-10.51-9.56-14.2-19.54-9.21C905.87,1315.24,912.38,1318.31,918.9,1321.38z M994.6,1404.83c3.24-3.7,6.43-7.34,9.84-11.24
                c-1.03-0.35-1.83-0.9-2.19-0.69c-4.65,2.68-9.54,3.96-14.96,3.29c-0.66-0.08-2.02,1.35-2.12,2.2c-0.1,0.82,0.79,2.15,1.61,2.63
                C989.17,1402.4,991.75,1403.47,994.6,1404.83z M890.51,1417.19c0.2,0.65,0.39,1.31,0.59,1.96c1.82-0.33,3.77-0.37,5.44-1.07
                c2.67-1.12,5.13-2.76,7.74-4.05c1.63-0.8,3.78-0.95,4.97-2.12c1.12-1.1,1.47-3.14,1.74-4.83c0.09-0.56-1.17-1.95-1.69-1.89
                c-1.7,0.2-4.44,0.48-4.8,1.47c-1.78,4.76-5.83,5.95-9.86,7.54C893.11,1414.8,891.88,1416.17,890.51,1417.19z M724.53,1481.64
                c0.26,0.4,0.53,0.8,0.79,1.19c2.45-1.62,6.9-3.16,6.97-4.87c0.16-4.16-4.12-4.36-7.42-4.39c-3.28-0.03-7.69-0.02-7.84,4.05
                C716.86,1482.09,721.14,1481.84,724.53,1481.64z M890.58,1377.73c5.87,2.7,11.31,3.32,16.87,1.75c2.41-0.68,3.32-2.36,0.76-4.25
                C904.39,1372.42,894.86,1373.46,890.58,1377.73z M631.96,1253.62c-2.97,1.13-5.76,1.74-8.03,3.18c-2.38,1.51-4.78,4.28-2.32,6.9
                c1.32,1.41,5.77,2.06,6.94,1C631.32,1262.19,633.76,1258.7,631.96,1253.62z M518.98,343.68c6-1.71,9.62-3.85,9.98-9.37
                c-1.93-1.06-3.71-2.4-5.72-3.01c-1.03-0.31-3.46,0.54-3.55,1.12C519.19,335.89,519.19,339.43,518.98,343.68z M707.01,374.19
                c-9.52-5.15-16.97-4.29-22.86,4.52C691.7,377.21,699,375.77,707.01,374.19z M890.63,1392.17c1.43,1.96,4,4.39,5.15,7.37
                c1.09,2.83,0.69,6.23,0.95,9.55c4.8-3.17,5.38-5.56,3.06-11.55C898.22,1393.49,894.41,1391.69,890.63,1392.17z M880.25,1430.77
                c0.57,0.24,1.14,0.47,1.71,0.71c1.24-2.29,3.74-4.78,3.42-6.83c-0.47-3.01-2.94-5.7-5.07-9.41c1.06-2.56-0.29-5.4-4.35-6.54
                c0.35,5.72,0.54,11.47,6.71,14.68c0.74,0.38,0.48,2.69,0.68,4.11c-0.48,0.08-0.97,0.15-1.45,0.23c-0.66-1.23-1.32-2.47-1.98-3.7
                c-0.32,0.11-0.64,0.22-0.97,0.34C879.38,1426.49,879.82,1428.63,880.25,1430.77z M469.09,190.8c-3.68-3.95-7.2-7.72-10.69-11.46
                c-2.4,2.52-2.87,10.01-0.27,10.81c0.55-0.64,1.13-1.31,2.02-2.35C462.2,193.2,465.65,192.74,469.09,190.8z M905.63,1297.17
                c3.3,3.94,9.19,5.45,13.1,2.94c0.95-0.61,1.75-3.35,1.23-3.99C917.94,1293.59,908.16,1294.52,905.63,1297.17z M916.2,1357.44
                c-1.34-6.11-4.01-8.34-8.16-6.41c-1.48,0.69-3.04,3.28-2.93,4.89C905.3,1358.83,911.44,1359.62,916.2,1357.44z M909.85,1393.79
                c-2.6,2.12-5.82,3.45-6.12,5.27c-0.62,3.68,3.16,2.03,5.09,2.65c0.31,0.1,0.66,0.1,0.98,0.19c3.01,0.91,5.96,0.12,5.94-3.01
                C915.72,1397.34,912.31,1395.82,909.85,1393.79z M895.42,1385.91c-4.04-3.99-10.32-4.53-14.66-1.5
                C885.3,1388.95,890.41,1389.57,895.42,1385.91z M911.71,1390.73c-2.25-4.23-8.64-5.57-11.45-2.4c-0.72,0.81-1.25,2.71-0.79,3.42
                c0.63,0.97,2.42,1.96,3.47,1.75C905.92,1392.91,908.78,1391.71,911.71,1390.73z M419.06,184.38c6.69,7.15,12.83,7.75,16.52,1.28
                C429.99,185.23,424.57,184.81,419.06,184.38z M698.07,1494.2c0.12,0.44,0.23,0.88,0.35,1.32c9.42-1.52,18.84-3.04,29.7-4.8
                c-2.08-0.65-2.8-0.82-3.49-1.1c-6.02-2.41-11,2.13-16.63,2.51C704.66,1492.37,701.38,1493.49,698.07,1494.2z M201.33,419.59
                c-5.23,2.36-7.55,6.96-6.55,12.78C198.98,429.18,204.17,426.48,201.33,419.59z M886.43,1405.38c0.12-0.54,0.24-1.09,0.35-1.63
                c-2.57-1.63-5.02-3.57-7.79-4.76c-1.18-0.51-3.04,0.54-4.58,0.88c0.69,1.57,1.09,4.23,2.12,4.5
                C879.7,1405.19,883.11,1405.11,886.43,1405.38z M1005.98,280.64c4.95,7.02,9.91,14.04,14.86,21.06c0.33-0.25,0.67-0.49,1-0.74
                C1017.8,293.26,1014.06,285.32,1005.98,280.64z M890.72,1499.13c0.05-0.43,0.1-0.85,0.15-1.28c-2.64-2.44-5.28-4.88-8.3-7.67
                c-0.69,0.48-2.05,1.43-4.63,3.23C882.93,1495.65,886.82,1497.39,890.72,1499.13z M786.82,184.03c0.3-0.47,0.61-0.94,0.91-1.41
                c-2.24-5.84-6.05-7.55-11.34-3.68C780.28,180.83,783.55,182.43,786.82,184.03z M580.96,272.01c-2.24,1.76-4.14,3.26-6.05,4.76
                c4.69,3.75,6.27,3.55,10.53-1.37C584.02,274.32,582.62,273.26,580.96,272.01z M899.29,1287.89c5.42,7.71,11.3,1.33,16.32,2.46
                C910.6,1289.6,905.6,1288.84,899.29,1287.89z M564.01,1445.53c-2.63,3.58-2.74,6.61,0.88,8.64c1.09,0.61,3.13-0.51,5.06-0.9
                C567.51,1450.08,565.93,1448.02,564.01,1445.53z M715.87,1483.94c-1.73-1.95-3.01-3.4-4.3-4.86c-0.77,1.32-2.31,2.78-2.11,3.94
                c0.29,1.67,1.84,3.13,2.86,4.67C713.32,1486.65,714.31,1485.59,715.87,1483.94z M903.45,1344.37c2.68,4.98,1.71,2.98,11.78,2.71
                C913.78,1344.17,910.8,1343.48,903.45,1344.37z M733.8,1483.79c-4.32,0.45-8.92,0.92-14.74,1.52
                C728.15,1487.12,731.62,1486.59,733.8,1483.79z M901.83,1351.26c0.19-0.94,0.38-1.88,0.57-2.82c-1.9-0.34-3.79-0.81-5.7-0.89
                c-0.27-0.01-0.63,1.81-0.94,2.78C897.78,1350.64,899.81,1350.95,901.83,1351.26z M859.17,1326.42c0.21,0.75,0.42,1.5,0.63,2.26
                c3.15-1.01,6.3-2.03,9.45-3.04c-0.17-0.59-0.34-1.18-0.51-1.77C865.55,1324.72,862.36,1325.57,859.17,1326.42z M513.89,329.97
                c-0.5-0.23-1-0.46-1.5-0.69c-1.11,1.26-3.02,2.48-3.09,3.8c-0.07,1.2,1.86,2.51,2.91,3.77c0.4-0.15,0.81-0.3,1.21-0.45
                C513.58,334.26,513.73,332.12,513.89,329.97z M423.13,209.7c0.05,0.5,0.1,0.99,0.16,1.49c2.45,0,4.9,0.12,7.32-0.15
                c0.3-0.03,0.36-2.14,0.63-3.9C427.98,208.16,425.55,208.93,423.13,209.7z M884.27,1485.26c1.78-0.99,3.48-1.94,5.18-2.89
                c-0.2-0.33-0.41-0.67-0.61-1c-1.94-0.14-3.88-0.29-6.52-0.49C883.35,1483.2,883.99,1484.63,884.27,1485.26z M886.89,1463.02
                c-3.08-2.56-5.2-1.32-7.2,3.98C882.23,1465.59,884.32,1464.44,886.89,1463.02z M870.6,1496.68c0,0.33,0,0.66,0.01,1
                c2.32,0,4.63,0,6.95,0c0-0.33,0-0.66-0.01-1C875.24,1496.68,872.92,1496.68,870.6,1496.68z M772.24,139.89
                c-0.65-0.22-1.31-0.43-1.96-0.65c-0.59,1.32-1.18,2.64-1.77,3.96c0.87,0.21,2.44,0.74,2.51,0.58
                C771.6,142.57,771.88,141.21,772.24,139.89z M520.64,259.28c-1.05,4.17-0.82,4.37,3.92,3.26
                C523.12,261.34,521.96,260.38,520.64,259.28z M920.03,1344.39c0.23,0.72,0.47,1.44,0.7,2.17c1.56-0.61,3.12-1.22,4.68-1.83
                c-0.21-0.59-0.42-1.18-0.63-1.77C923.2,1343.43,921.61,1343.91,920.03,1344.39z M882.68,1408.93c-0.18,0.45-0.35,0.89-0.53,1.34
                c0.94,0.38,1.88,0.76,2.82,1.14c0.2-0.56,0.4-1.11,0.61-1.67C884.61,1409.47,883.64,1409.2,882.68,1408.93z M433.15,198.29
                c-0.17-0.48-0.34-0.96-0.51-1.44c-1.24,0.45-2.48,0.89-3.73,1.34c0.16,0.43,0.31,0.87,0.47,1.3
                C430.63,199.09,431.89,198.69,433.15,198.29z M881.39,1438.02c0.44,0.12,0.89,0.24,1.33,0.36c0.35-1.24,0.69-2.48,1.04-3.73
                c-0.47-0.13-0.94-0.25-1.41-0.38C882.03,1435.52,881.71,1436.77,881.39,1438.02z M729.44,1458.24c-0.55-0.08-1.1-0.15-1.65-0.23
                c-0.12,1.14-0.25,2.28-0.37,3.42c0.43,0.06,0.87,0.12,1.3,0.18C728.96,1460.49,729.2,1459.36,729.44,1458.24z M908.3,1478.55
                c-0.29-0.26-0.59-0.53-0.88-0.79c-0.79,0.61-1.58,1.23-2.37,1.84c0.44,0.41,0.89,0.82,1.33,1.23
                C907.02,1480.08,907.66,1479.31,908.3,1478.55z M532.39,328.45c0.11-0.51,0.23-1.02,0.34-1.54c-1.1-0.19-2.21-0.38-3.31-0.57
                c-0.07,0.37-0.15,0.75-0.22,1.12C530.26,327.8,531.32,328.12,532.39,328.45z M942.86,1463.84c0-0.36,0-0.72,0-1.09
                c-1.08,0-2.17,0-3.25,0c0,0.36,0,0.72,0,1.09C940.7,1463.84,941.78,1463.84,942.86,1463.84z M209.23,403.16
                c-0.4-0.06-0.81-0.12-1.21-0.18c-0.17,0.97-0.34,1.95-0.52,2.92c0.31,0.06,0.62,0.13,0.93,0.19
                C208.7,405.12,208.97,404.14,209.23,403.16z M651.56,1315.08c0.27,0.05,0.55,0.1,0.82,0.15c0.15-0.85,0.31-1.7,0.46-2.55
                c-0.22-0.05-0.45-0.1-0.67-0.14C651.96,1313.38,651.76,1314.23,651.56,1315.08z M1061.27,1179.64c-0.36,0.22-0.84,0.42-0.82,0.51
                c0.1,0.43,0.33,0.83,0.51,1.24c0.34-0.2,0.69-0.4,1.03-0.6C1061.75,1180.41,1061.51,1180.03,1061.27,1179.64z M848.34,1448.71
                c-0.47,0.45-0.75,0.71-1.02,0.97c0.22,0.18,0.42,0.46,0.67,0.52c0.27,0.06,0.58-0.08,0.87-0.13
                C848.72,1449.71,848.58,1449.35,848.34,1448.71z M956.01,1291.15c0.28-0.39,0.61-0.71,0.73-1.09c0.05-0.16-0.33-0.46-0.51-0.69
                c-0.29,0.31-0.64,0.59-0.84,0.96C955.34,1290.43,955.72,1290.77,956.01,1291.15z M921.47,1398.01c0.37-0.39,0.65-0.69,0.94-1
                c-0.37-0.24-0.73-0.66-1.11-0.67c-0.3-0.01-0.62,0.48-0.93,0.74C920.69,1397.36,921.02,1397.64,921.47,1398.01z M966.85,1461.45
                c-0.02-0.28-0.05-0.55-0.07-0.83c-0.65,0.07-1.31,0.13-1.96,0.2c0.03,0.26,0.06,0.76,0.09,0.75
                C965.56,1461.57,966.2,1461.51,966.85,1461.45z M649.57,1306.8c0.19-0.22,0.4-0.41,0.53-0.65c0.05-0.1-0.07-0.29-0.11-0.44
                c-0.21,0.07-0.6,0.13-0.6,0.21C649.38,1306.21,649.5,1306.5,649.57,1306.8z M685.49,1488.32c-0.13,0.16-0.26,0.31-0.39,0.47
                c0.37,0.3,0.74,0.61,1.11,0.91c0.13-0.15,0.25-0.3,0.38-0.45C686.22,1488.94,685.86,1488.63,685.49,1488.32z M892.08,1405.92
                c-0.01,0.15-0.02,0.3-0.04,0.45c0.25-0.03,0.51-0.03,0.75-0.1c0.06-0.02,0.04-0.28,0.06-0.42
                C892.6,1405.88,892.34,1405.9,892.08,1405.92z M532.57,322.81c-0.13,0.07-0.37,0.17-0.36,0.19c0.08,0.26,0.2,0.5,0.31,0.75
                c0.12-0.07,0.36-0.18,0.35-0.21C532.8,323.29,532.68,323.05,532.57,322.81z M888.78,1422.29c0.12,0.24,0.22,0.42,0.31,0.6
                c0.05-0.13,0.16-0.28,0.13-0.38C889.17,1422.42,888.98,1422.39,888.78,1422.29z M774.1,146.57c0.08-0.26,0.14-0.45,0.2-0.64
                c-0.12,0.05-0.32,0.08-0.35,0.17C773.91,146.2,774.02,146.35,774.1,146.57z"/>
            <path onClick={() => props.onFill(0)} fill={props.fillColors[0]} d="M247.31,297.38c5.75,3.88,11.52,7.74,17.26,11.64c4.26,2.89,8.29,6.18,12.82,8.54
                c1.87,0.98,4.77-0.01,7.2-0.1c-0.46-2.18-0.37-4.73-1.49-6.49c-4-6.26-8.41-12.25-12.66-18.35c0.3-0.33,0.6-0.67,0.9-1
                c4.13,1.92,8.26,3.85,12.38,5.77c0.43-0.4,0.85-0.8,1.28-1.2c-1.48-7.44-7.66-12.68-10.32-19.54c-2.67-6.88-4.99-13.91-7.61-21.29
                c2.82-0.55,4.95-0.78,6.95-1.4c3.14-0.97,4-2.8,1.56-5.5c-2.34-2.59-4.86-5.06-6.92-7.86c-1.58-2.16-2.57-4.76-3.82-7.16
                c0.3-0.4,0.6-0.79,0.9-1.19c2.84,0.59,5.65,1.47,8.52,1.71c4.75,0.39,9.55,0.67,14.29,0.38c2.27-0.14,4.6-1.67,3.46-4.83
                c-3.5-9.75-7.07-19.47-9.9-29.8c7.66,2.47,14.81,5.16,20.43,10.67c6.18,6.05,12.13,12.34,18.38,18.32
                c2.38,2.27,5.2,4.14,8.03,5.83c1.1,0.66,3.22,0.89,4.09,0.25c0.77-0.56,0.74-2.65,0.57-4c-0.33-2.55-1.01-5.05-1.78-8.68
                c2.86,1.37,4.55,2.28,6.32,3.02c3.68,1.53,7.28,2.29,10.71-0.84c0.85-0.78,2.32-0.94,3.55-1.23c3.39-0.81,6.8-1.54,10.9-2.46
                c13.27,11.82,27.45,24.41,37.68,40.65c2.01,3.2,3.42,6.78,5.1,10.18l0.08-0.05c-4.99-3.88-10.01-7.72-14.95-11.66
                c-3.72-2.97-7.76-4.24-12.56-5.13c-3.89-0.71-7.38-4.96-10.48-8.17c-2.14-2.21-3.17-5.45-4.98-8.04
                c-1.23-1.76-2.96-3.17-4.47-4.74c-0.61,0.38-1.22,0.77-1.82,1.15c2.64,5.44,5.28,10.88,8.45,17.42c-2.57-0.41-4.06-0.67-5.56-0.88
                c-5.35-0.73-10.68-1.62-16.05-1.95c-0.86-0.05-2.79,2.5-2.68,3.7c0.55,6.07,1.75,12.08,2.44,18.14c0.27,2.43-0.17,4.93-0.33,7.4
                c-0.02,0.31-0.36,0.58-0.42,0.9c-2.12,11.52-6.06,22.88-3.56,34.82c0.42,2,1.72,3.97,3.03,5.61
                c9.06,11.28,18.22,22.47,27.38,33.67c1.21,1.48,2.59,2.83,4.85,5.28c1.42-9.11-2.75-15.6-4.62-22.52
                c-1.89-6.96-4.1-13.82-6.44-21.64c2.37,1.12,4.73,1.97,6.81,3.27c2.81,1.76,5.42,3.85,8.1,5.82c2.21,1.62,4.26,2.53,6.47-0.19
                c0.59-0.73,2.16-1.2,3.11-1c5.33,1.14,10.8,2.03,15.86,3.94c6.84,2.58,6.7,2.94,8.34-5.19c3.96,1.72,7.72,3.35,11.25,4.89
                c-9.57,12.65-19,24.61-27.85,36.97c-7.22,10.09-14.09,20.48-20.48,31.11c-4.07,6.77-0.8,13.65,6.38,17.11
                c4.73,2.28,9.03,5.45,13.43,8.37c0.87,0.58,1.91,1.91,1.82,2.78c-0.65,6.36,3.96,8.57,8.3,11.12c2.08,1.22,4.05,2.62,6.4,4.16
                c-5.56,0-10.49,0.54-15.2-0.22c-2.5-0.4-5.33-2.61-6.79-4.81c-2.1-3.19-4.71-4.57-8.1-5.96c-7.56-3.1-14.49-5.14-23.1-2.45
                c-3.89,1.22-9.99-2.59-14.3-5.35c-5.04-3.23-9.64-4.73-15.34-2.33c-4.09,1.72-5.47,4.44-3.04,8.19
                c4.62,7.12,9.68,12.92,19.74,12.64c11.14-0.3,19.99,6.5,28.73,12.61c2.31,1.62,4.38,3.58,6.52,5.44c3.06,2.66,6.21,3.74,10.6,4.08
                c5.3,0.41,10.38,3.87,15.51,6.12c2.54,1.11,4.71,4.14,7.86,0.63c0.45-0.5,3.72,0.79,5.02,1.98c3.21,2.92,6.33,4.21,11.01,4.02
                c5.23-0.21,10.54,1.77,16.45,2.92c-3.13-3.42-6.18-6.41-8.84-9.71c-7.2-8.92-13.89-18.27-21.48-26.84
                c-3.53-3.99-8.71-6.51-13.04-9.84c-1.39-1.07-2.45-2.56-3.67-3.86c0.41-0.39,0.82-0.78,1.23-1.17c4.39,2.9,8.73,5.88,13.18,8.69
                c4.2,2.66,7.73,6.74,13.73,6.25c-5.17-11.64-13.8-19.94-25.7-25.54c5.23-5.59,9.96-9.99,16.88-10.53
                c11.12-0.86,22.17-1.63,32.83,3.42c6.21,2.94,12.4,1.98,18.36-2.17c2.3-1.6,6.2-1.65,9.25-1.31c8.14,0.93,9.35,0.11,13.18-7.16
                c8.84-16.82,10.48-35.66,15.62-53.51c0.22-0.77,0.4-1.56,0.61-2.35c7.33,2.32,12.23,9.06,12.89,16.48
                c0.24,2.67,1.86,5.22,2.93,7.8c1.62,3.91,3.48,7.72,4.9,11.7c1.28,3.59,2.14,7.34,3.13,11.04c0.43,1.6,1.01,3.22,1.04,4.84
                c0.23,11.73,0.29,23.47,0.52,35.21c0.05,2.38,0.59,4.76,1.49,7.1c4.22-13.35,0.29-27.08,1.11-40.57
                c10.58,0.81,16.61,5.99,17.38,14.93c0.71,8.2,0.48,16.48,1,24.7c0.5,7.93,1.76,15.81,2.08,23.74c0.54,13.26,0.6,26.53,0.82,39.8
                c0.01,0.39-0.47,0.8-1.74,2.82c-2.35-3.01-4.13-5.88-6.49-8.14c-2.49-2.39-5.35-4.55-8.39-6.16c-4.97-2.63-5.83-1.86-6.66,3.52
                c-0.34,2.19-1.58,4.36-2.85,6.24c-1.88,2.77-1.53,5.04,0.95,6.96c2.76,2.13,5.44,4.63,8.59,5.95c9.49,3.98,13.6,11.66,14.33,21.09
                c0.55,7.13-0.42,14.38-0.74,22.16c-6.56-1.7-11.75-2.94-13.58-9.41c-0.49-1.74-2.02-3.5-3.54-4.58
                c-5.28-3.75-10.37-8.25-16.28-10.54c-8.73-3.39-18.09-5.15-27.2-7.56c-3.97-1.05-8.22-1.45-11.88-3.14
                c-8.15-3.76-15.98-8.21-24.01-12.26c-5.46-2.75-11.14-4.13-18.25-1.83c1.42,1.58,2.2,2.73,3.23,3.56
                c4.43,3.53,9.19,6.67,13.34,10.49c5.21,4.81,10.25,9.89,14.79,15.34c4.92,5.91,8.96,12.43,17.94,12.78
                c3.07,0.12,4.52,2.81,3.17,5.95c-1.59,3.69-0.43,6.32,2.47,8.75c2.28,1.92,4.02,4.55,6.44,6.24c3.21,2.23,6.65,4.72,10.33,5.54
                c7.21,1.6,12.53-4.89,11.09-12.22c-0.76-3.86-0.78-7.88-0.88-11.83c-0.14-5.2-0.03-10.41-0.03-15.78
                c4.75,3.15,9.59,6.29,14.35,9.56c0.76,0.52,1.49,1.51,1.68,2.4c1.37,6.67,6.56,8.81,12.18,10.29c3.19,0.84,4.4,2.38,3.66,5.73
                c-0.61,2.75-1.1,5.64-0.93,8.42c0.85,13.35-4.18,24.75-11.54,35.32c-8.33,11.95-11.88,25.34-13.92,39.6
                c-2.47,17.27-6.35,34.34-9.33,51.54c-3.54,20.49-6.98,41.01-9.98,61.58c-0.72,4.95-2.4,8.82-5.86,12.2
                c-2.34,2.29-4.52,4.73-7.39,7.76c-2.97-7.26-5.9-13.91-8.41-20.72c-3.98-10.8-6.94-22.57-15.72-30.3
                c-9.77-8.6-8.77-20.85-13.46-31.22c10.75,5.32,16.78,14.89,22.06,25.18c0.45-0.27,0.91-0.54,1.36-0.81
                c-0.06-1.02,0.07-2.09-0.21-3.04c-0.37-1.27-1.12-2.41-1.59-3.66c-3.1-8.31-6.6-16.33-8.92-25.08
                c-6.23-23.46-17.23-45.02-31.36-64.87c-0.38-0.53-0.88-0.99-1.39-1.4c-0.23-0.19-0.62-0.18-2.1-0.58
                c0.69,14.09,6.06,26.75,8.22,40.34c-2.54-3.09-5.08-6.18-7.63-9.27c-0.61,0.28-1.22,0.56-1.83,0.84
                c2.15,5.84,4.9,11.55,6.29,17.56c1.4,6.07,1.46,12.46,2.16,19.16c-2.21-1.72-4.21-3.28-7.49-5.84c0.3,3.31,0.1,5.24,0.68,6.9
                c2.69,7.7,5.42,15.39,8.45,22.95c9.05,22.56,18.43,44.99,27.29,67.63c3.73,9.54,7.24,19.16,5.8,30
                c-0.68,5.09,1.24,10.51,1.81,15.8c0.23,2.09,0.67,4.53-0.13,6.3c-5.35,11.78-11.01,23.42-16.86,35.71
                c-11.61-8.72-24.04-13.23-37.39-6.42c-14.18,7.23-15.06,20.6-12.78,34.88c-3.27-0.55-5.67-1.1-8.09-1.31
                c-4.97-0.44-8.25,2.19-8.95,6.8c-0.66,4.33-2.72,6.34-6.67,7.49c-1.61,0.47-3.27,1.84-4.25,3.26c-2.97,4.3-5.63,8.82-8.59,13.54
                c-3.65-1.74-7.05-3.77-10.72-5.03c-8.29-2.84-16.72-2.74-24.97,0.26c-2.88,1.05-4.06,0.38-5.1-2.51
                c-6.16-17.2-22.81-24.16-39.63-16.75c-7.95,3.51-11.52,9.48-10.94,18.28c0.07,1.12,0.01,2.25,0.01,3.96
                c-7.19-1.2-12.05,1.51-14.6,8.32c-0.3,0.81-2.5,1.18-3.85,1.28c-3.14,0.21-6.36-0.25-9.44,0.24c-8.37,1.34-12.6,7.53-11.18,15.85
                c0.19,1.13,0.47,2.24,0.89,4.23c-2.23-0.53-3.89-0.9-5.55-1.32c-13.71-3.46-22.18,5.12-18.12,18.68c1.01,3.38,0.81,5.77-1.13,8.7
                c-4.3,6.47-3.92,13.55-1.54,20.65c0.62,1.86,1.34,3.69,2.32,6.39c-7.53-1.2-11.08,2.35-11.71,9.45
                c-11.02-1.5-10.74,6.19-10.77,13.44c-18.88-9.75-34.23-22.95-46.27-39.87c-5.3-7.45-10.13-15.23-15.1-22.91
                c-6.1-9.44-10.21-19.56-12.85-30.62c-1.69-7.07-5.68-13.94-9.96-19.96c-10.72-15.06-22.33-29.38-37.04-40.98
                c-22.98-18.13-41.93-40.39-61.97-61.53c-2.52-2.66-4.73-5.78-7.69-7.82c-5.97-4.12-7.11-9.57-6.81-16.37
                c0.48-10.97,0.12-21.98,0.14-32.97c0-1.75,0.19-3.5,0.34-5.99c1.52,1.01,2.36,1.43,3.06,2.03c18.77,16.22,37.73,32.22,56.16,48.81
                c7.11,6.4,14,13.5,19.23,21.44c8.15,12.38,17.54,23.35,28.7,33.03c9.43,8.18,18.87,16.44,27.43,25.48
                c10.75,11.36,24.48,17.49,38.06,24.12c2.2,1.07,4.53,2.24,6.2,3.95c9.97,10.27,19.75,20.71,29.62,31.08
                c1.17,1.23,2.49,2.31,3.74,3.47c0.42-0.23,0.84-0.46,1.25-0.69c-0.28-1.65-0.38-3.35-0.87-4.94
                c-8.21-26.78-19.86-51.66-36.61-74.45c-11.49-15.62-20.6-33.03-30.31-49.9c-2.49-4.33-6.77-9.15-2.05-14.97
                c0.53-0.66-0.69-2.73-1.08-4.16c-0.88-3.25-1.74-6.51-2.75-10.29c30.46,40.67,55.86,83.97,89.78,120.99
                c0.57,0.62,1.76,0.67,3.86,1.4c-0.4-2.72-0.41-4.74-1.01-6.57c-2.78-8.55-5.8-17.02-8.55-25.58c-0.99-3.08-1.44-6.34-2.14-9.52
                c0.47-0.33,0.94-0.67,1.41-1c1.79,1.43,3.64,2.8,5.37,4.29c3.64,3.14,6.91,6.84,10.9,9.43c6.78,4.4,12.9,2.16,16.25-5.29
                c0.48-1.06,0.51-2.52,1.28-3.22c6.28-5.73,8.95-12.19,3.47-20.61c0.65-0.18,1.3-0.36,1.95-0.54c2.14,2.02,4.34,3.99,6.41,6.09
                c3.61,3.66,7,7.54,10.74,11.06c4.1,3.85,7.38,1.16,10.81-1.6c3.52-2.84,0.59-5.03-0.3-7.41c-2.63-6.98-5.37-13.92-8.07-20.88
                l-0.07,0.08c3.85,4.77,7.53,9.69,11.6,14.27c3.31,3.72,7.09,7.02,10.66,10.5c0.64-0.43,1.29-0.86,1.93-1.3
                c-0.84-3.12-1.68-6.24-2.81-10.46c3.2,1.19,5.55,2.12,7.95,2.94c4.54,1.55,9.16,2.87,13.62,4.63c1.73,0.68,3.31,2.08,4.58,3.49
                c9.97,11.17,19.84,22.43,29.8,33.62c0.77,0.87,1.95,1.36,2.95,2.03c0.52-0.4,1.03-0.8,1.55-1.2c-5.67-9.55-11.06-19.28-17.08-28.6
                c-6-9.28-12.63-18.15-19.1-27.35c1.56,0.35,3.26,0.97,4.98,1.03c1.53,0.06,3.08-0.5,4.62-0.78c-0.27-1.39-0.27-2.91-0.86-4.14
                c-0.86-1.78-2.13-3.37-3.26-5.02c-12.33-18.08-23.45-36.77-29.97-57.88c-1.9-6.15-5.04-11.67-10.77-15.14
                c-3.85-2.33-5.46-1.49-5.47,3.02c-0.01,3.27,0.63,6.55,1.12,9.8c0.65,4.32,1.43,8.61,1.17,13.16c-6.03-7.19-9.98-15.06-9.65-24.55
                c0.09-2.65,0.63-5.29,0.85-7.94c0.65-7.6,0.62-7.64,7.82-8.81c3.46-0.56,4.54-2.26,3.21-5.59c-1.85-4.64-3.68-9.29-5.28-14.01
                c-2.11-6.26-2.08-12.36-0.75-19.07c1.18-5.92,0.44-12.84-1.52-18.62c-3.34-9.86-8.28-19.19-12.6-28.71
                c-0.22-0.48-0.99-0.71-2.57-1.78c0.22,2.28,0.19,3.6,0.5,4.83c2.79,11.07,1.11,21.59-3.31,31.97c-1.17,2.74-1.4,6.1-1.27,9.14
                c0.42,10.31-3.31,14-13.46,15.86c-7.89,1.45-15.11,1.09-22.36-2.03c-1.34-0.58-2.92-0.67-4.4-0.8c-6.37-0.56-7.58,1.12-6.42,7.32
                c0.72,3.84,1.22,7.99,0.5,11.75c-1.07,5.62-6.76,7.93-11.61,4.91c-6.06-3.77-11.89-7.96-17.61-12.24
                c-10.32-7.73-20.65-15.34-34.03-16.79c-1.82-0.2-3.69-0.03-5.53-0.03c-0.17,0.45-0.35,0.9-0.52,1.35c1.3,1.33,2.5,2.76,3.91,3.97
                c14.29,12.23,28.77,24.25,42.84,36.72c4.29,3.81,7.84,8.6,11.12,13.36c2.98,4.33,2.06,7.07-2.35,10.04
                c-1.65,1.11-3.65,1.71-5.23,2.89c-2.72,2.04-5.25,4.34-7.85,6.54c0.12,0.6,0.25,1.21,0.38,1.81c-2.25-1.17-4.52-2.29-6.74-3.51
                c-4.8-2.64-9.41-5.71-14.4-7.9c-5.55-2.44-11.3-2.56-16.72,1c-1.03,0.68-2.48,0.76-3.75,1.05c-3.81,0.87-7.63,1.7-12.17,2.7
                c-1.7-8.76-3.5-18.05-5.58-28.79c6.15,3.26,11.02,5.98,16.02,8.4c1.64,0.79,3.65,0.82,5.5,1.2c-0.17-1.88,0.06-3.92-0.58-5.62
                c-4.57-11.98-9.38-23.87-14-35.83c-1.38-3.56-2.54-7.23-3.54-10.92c-0.51-1.9-1.21-4.33-0.46-5.86c2.44-5,3.87-9.83,3.56-15.63
                c-0.11-1.91,3.35-5.83,7.9-5.95c5.2-0.13,10.17-0.12,15.04,1.98c1.37,0.59,3.07,0.4,4.62,0.57c0.36-0.48,0.72-0.97,1.08-1.45
                c-5.82-6.59-11.65-13.19-17.47-19.78c0.34-0.44,0.67-0.88,1.01-1.33c3.56,0.39,7.12,0.93,10.69,1.1c1.54,0.07,3.11-0.61,4.67-0.95
                c-0.59-1.61-0.88-3.42-1.82-4.78c-3.02-4.38-6.63-8.37-9.37-12.91c-4-6.64-2.96-10.14,2.05-16.04c2.06-2.43,2.8-6.08,3.79-9.29
                c0.64-2.08,0.21-6.06,1-6.27c3.84-0.97,8.64-2.45,11.79-0.97c6.07,2.85,11.5,7.3,16.77,11.59c9.43,7.67,18.5,15.77,27.83,23.55
                c2.29,1.91,5,3.32,7.52,4.95c0.38-0.37,0.75-0.75,1.13-1.12c-0.45-1.11-0.72-2.34-1.39-3.29c-1.25-1.76-2.53-3.59-4.15-4.97
                c-14.82-12.59-28.78-26.5-44.88-37.17c-13.81-9.15-17.38-20.24-15.3-35.11c2.14-15.31,4.25-30.62,6.22-45.96
                c0.83-6.48,2.95-12.3,7.52-17.05c5.76-5.99,8.53-13.31,10.18-21.31c1.4-6.83,3.67-13.49,5.02-20.32
                c1.22-6.17,1.79-12.48,2.52-18.74c0.54-4.66,2.18-8.35,6.1-11.47c2.1-1.67,3.39-5.26,3.66-8.11c0.51-5.29,1.27-10.22,5.14-14.2
                c0.57-0.58,1.24-1.35,1.31-2.09c0.71-7.45,1.31-14.91,1.95-22.37c-0.67-0.3-1.34-0.6-2.01-0.9c-0.21,0.63-0.63,1.27-0.61,1.89
                c0.41,9.67-7.52,16.49-8.79,25.63c-0.25,1.78-1.64,3.81-3.12,4.92c-6.14,4.63-8.21,11.09-8.93,18.27
                c-0.76,7.53-2.14,14.84-6.67,21.18c-0.38,0.53-0.75,1.2-0.79,1.82c-0.76,14.16-11.33,24.18-15.42,36.87
                c-1.84,5.71-2.55,11.93-2.86,17.96c-0.66,12.83-2.22,25.32-7.53,37.24c-1.69,3.79-2.01,8.33-2.27,12.57
                c-0.2,3.27,0.64,6.61,1.09,9.91c1.18,8.52,0.91,8.58-7.48,8.92c-2.11,0.08-4.49,2.11-6.05,3.85c-1.06,1.18-1.2,3.43-1.28,5.22
                c-0.36,7.59-1.15,15.22-0.6,22.76c0.45,6.24-0.93,11.81-3.39,17.22c-0.53,1.17-3.2,2.32-4.53,2c-4.43-1.06-6.23,1.14-6.18,4.79
                c0.08,5.28,0.81,10.56,1.42,15.82c0.6,5.11,1.87,10.19,1.99,15.31c0.19,7.79-2.41,10.4-10.06,11.59
                c-10.33,1.6-11.32,3.08-9.11,13.54c0.03,0.16,0.08,0.32,0.11,0.49c2.11,9.34,2.06,9.12-7.08,11.09c-1.97,0.42-3.44,3.13-5.74,5.37
                c-0.04-0.02-1.2-0.52-2.07-1.32c-26.14-23.9-55.86-42.49-87.16-58.68c-4.81-2.49-6.83-5.33-6.49-10.78
                c0.47-7.47,0.53-15.01,0.02-22.47c-0.77-11.22,3.34-20.8,8.61-30.22c4.54-8.11,8.78-16.4,13.16-24.6c1.1-2.05,2.06-4.21,3.37-6.11
                c3.45-4.98,7.04-5.86,12.48-3.23c2.7,1.3,5.24,2.99,8.04,3.98c6.29,2.23,10.88-0.45,12.22-7.01c0.93-4.56,1.41-9.22,2.38-13.77
                c0.86-4.05,2.86-6.62,7.77-6.82c7.07-0.29,11.76-5.89,11.85-12.92c0.03-2.5-0.23-5.04,0.19-7.47c0.29-1.63,1.15-3.81,2.43-4.5
                c4.65-2.51,6.13-6.79,6.09-11.3c-0.07-7.76-0.98-15.51-1.5-22.88c0.46,0.68,1.11,2.33,2.32,3.28c2.8,2.19,5.61,4.83,8.87,5.85
                c4.81,1.51,7.6-0.83,8.29-5.89c0.42-3.09,0.49-6.23,0.73-9.59c1,0,1.7-0.2,2.21,0.03c4.68,2.1,5.81-0.42,5.38-4.33
                c-0.7-6.27-1.51-12.54-2.62-18.75c-0.92-5.2-2.33-10.31-2.67-15.93c0.67,0.71,1.41,1.38,2,2.15c1.61,2.12,2.81,4.72,4.82,6.31
                c2.3,1.82,5.31,2.75,8.01,4.06c0.71-2.56,2-5.12,2.05-7.69c0.26-14.32-1.69-28.23-8.56-41.11c-1.28-2.39-2-5.08-3.45-8.87
                c3.96,3.72,6.67,6.62,9.75,9.06c5.48,4.37,9.28,2.79,10.14-4.27c0.46-3.78-0.21-7.71,0.38-11.45c0.51-3.21,2.22-6.2,3.17-9.36
                c0.56-1.85,1-3.85,0.91-5.76c-0.61-12.96-4.09-25.25-8.89-37.23c-0.98-2.44-1.51-5.05-2.25-7.58l-0.09,0.04
                c1.68,2.28,3.22,4.68,5.07,6.8c1.35,1.54,2.73,4.53,5.32,0.99c0.27-0.37,2.03,0.25,3.06,0.55c2.06,0.61,4.08,1.76,6.12,1.78
                c0.97,0.01,3-2.36,2.79-3.2c-4.78-18.47-7.9-37.58-19.83-53.42c-1.25-1.66-2.07-3.66-3.09-5.5c-0.82,0.65-1.64,1.3-2.46,1.95
                c1.92,6.17,3.84,12.35,5.76,18.52c-0.38,0.34-0.76,0.69-1.14,1.03c-2.51-1.35-5.14-2.53-7.52-4.09c-5.16-3.38-6-3.07-7.3,3.13
                c-0.29,1.4-0.71,2.78-1.12,4.4c-5.67-1.19-8.34-5.33-11.49-8.9c-5.94-6.73-11.94-13.41-18.02-20c-1.12-1.21-2.76-1.95-4.16-2.9
                c-0.57,0.35-1.14,0.7-1.7,1.04c3.6,9.41,7.21,18.82,10.81,28.22c-0.47,0.43-0.94,0.86-1.41,1.29c-2.15-1.4-4.51-2.56-6.4-4.25
                c-4.69-4.2-8.97-8.87-13.82-12.86c-1.78-1.47-4.68-1.57-7.07-2.3c0.27,2.54-0.08,5.36,0.92,7.56c2.28,4.97,5.41,9.54,7.85,14.44
                c3.99,8.02,7.9,16.09,11.39,24.33c0.68,1.6-0.33,4.29-1.27,6.1c-1.6,3.07-4.03,5.72-5.57,8.81c-0.7,1.39-0.52,3.56-0.01,5.15
                c0.96,3,2.41,5.84,3.73,8.72c6.6,14.48,11.23,29.49,13.64,45.28c1.43,9.35,3.92,18.54,6,27.79c0.83,3.7,1.82,7.37,2.73,11.05
                c-0.38,0.19-0.77,0.38-1.15,0.57c-1.1-1.97-2.58-3.83-3.23-5.94c-3.16-10.34-6.11-20.74-9.09-31.13
                c-2.57-8.95-4.89-17.99-7.75-26.85c-1.12-3.47-3.42-6.57-6.09-9.54c-2.11,7.44,1.63,13.98,3.06,22.15
                c-2.89-1.53-4.96-2.33-6.68-3.6c-2.66-1.97-4.01-1.25-5.24,1.58c-1.64,3.79-3.57,7.47-5.46,11.15c-0.44,0.86-1.08,1.94-1.88,2.25
                c-7.66,2.88-7.48,9.42-7.43,15.81c0.02,3.24,0.52,6.47,0.93,11.23c-3.9-2.42-6.72-3.98-9.33-5.84c-2.63-1.87-4.99-1.64-8.23-1.23
                c-11.59,1.46-16.66,9.66-21.75,18.47c-2.1,3.64-5.21,6.7-7.86,10.03c-0.47-0.18-0.94-0.36-1.4-0.55c0-124.1,0-248.21,0-373.72
                c9.88,9.41,17.36,19.6,23.54,30.31c6.08,10.54,9.83,22.44,15.44,33.69c0-1.62,0.33-3.33-0.05-4.86
                c-3.5-13.82-8.28-27.09-17.64-38.21c-6.63-7.88-13.36-15.67-19.84-23.67c-1.23-1.52-2.06-3.84-2.08-5.8
                C2,35.81,2.15,21.48,2.03,7.16C1.99,3.29,3.46,1.86,7.34,1.9c16.15,0.13,32.3,0.17,48.45-0.01c4.98-0.06,8.2,2.75,10.99,5.96
                c7.43,8.53,14.85,17.11,21.61,26.16c3.01,4.03,5.02,9.08,6.41,13.98c1.38,4.85,4.05,8.05,7.91,11c2.63,2.01,5.21,4.84,6.35,7.85
                c1.42,3.75-0.18,7.24-4.6,8.52c-4.08,1.18-4.87,3.61-3.74,7.64c3.7,13.13,6.6,26.49,10.81,39.45c1.84,5.66,5.8,10.75,9.41,15.65
                c1.14,1.55,4.27,1.64,6.49,2.4c0.37-2.18,1.73-4.85,0.93-6.45c-3.45-6.96-7.33-13.73-11.44-20.34c-4.34-6.99-7.87-14.03-5.04-22.5
                c0.55-1.65,1.66-3.21,2.83-4.52c1.92-2.15,4.1-4.06,6.69-6.57c0.54,0.67,2.15,1.93,2.78,3.57c2.8,7.28,5.87,14.52,7.83,22.03
                c4.9,18.78,10.37,37.24,22.71,52.77c2.2,2.77,4.73,5.27,7.11,7.89c0.53-0.27,1.05-0.54,1.58-0.81c-0.29-1.6-0.31-3.31-0.9-4.79
                c-5.27-13.3-10.45-26.64-16.05-39.79c-4.69-11.02-9.74-21.9-14.99-32.67c-3.38-6.95-2.44-13.95-1.15-20.99
                c0.21-1.15,2.13-2.91,3.12-2.83c4.22,0.37,6.63-1.44,7.77-5.68c5.25,1.99,6.82,6.63,8.65,10.66c2.05,4.52,3.33,9.41,4.69,14.2
                c2.9,10.18,6.98,19.79,13.44,28.22c1.89,2.47,4.37,4.59,6.88,6.46c1.25,0.93,3.19,0.96,4.81,1.39c-0.16-1.72,0.16-3.7-0.58-5.12
                c-3.46-6.64-7-13.25-10.91-19.63c-5.25-8.57-4.08-17.69-3.5-27.87c1.13,1.19,1.8,1.56,1.9,2.05c1.85,9.57,8.26,16.35,16.38,19.55
                c12.2,4.81,17.93,14.06,22.46,25.07c2.47,6,5.18,11.9,7.48,17.97c2.98,7.88,1.74,10.51-5.95,13.33c-3.36,1.23-4.23,3.1-2.93,6.53
                c4.59,12.13,9,24.34,13.44,36.53c0.66,1.82,1.13,3.71,1.8,5.94c-2.85,0-5.11-0.09-7.35,0.02c-4.28,0.21-5.45,1.88-3.84,5.78
                c1.33,3.23,3.17,6.25,4.45,9.49c1.28,3.25,3.58,6.71-0.87,9.72c-0.65,0.44-0.49,2.9,0.04,4.1c1.8,4.1,3.9,8.07,5.92,12.07
                c2.55,5.05,5.21,10.03,7.65,15.13c1.66,3.47,1.02,5.87-3.49,5.8c-0.83-0.01-1.66,0.09-2.49,0.19c-6.94,0.83-7.71,1.76-7.22,8.69
                c0.48,6.76-0.81,8.57-7.31,9.6c-3.94,0.62-5.36,2.32-3.38,6.09c0.39,0.74,0.68,1.52,0.99,2.29c3.04,7.54,5.54,15.01-3.48,21.31
                c1.85,1.41,3.15,2.59,4.62,3.48c9.97,6.02,19.95,12.02,29.97,17.94c6.42,3.79,12.74,7.81,20.73,7.47c1.45-0.06,2.91,1.5,4.46,2.03
                c4.36,1.5,8.69,3.56,13.18,4.09c5.77,0.68,7.35-2.31,4.4-7.26c-1.18-1.99-2.69-3.81-4.24-5.54c-6.98-7.83-14.08-15.55-21-23.43
                c-1.49-1.7-2.42-3.88-3.6-5.84L247.31,297.38z M431.08,526.23c7.06,3.57,9.24,8.2,8.27,15.65c-0.33,2.5,0.04,6.43,1.63,7.6
                c6.13,4.51,12.83,8.28,19.47,12.05c1.58,0.9,3.8,0.69,7.19,1.21c-2.39-3.86-4.07-6.11-5.2-8.61c-0.58-1.28-0.81-3.44-0.11-4.46
                c3.99-5.82,4.23-8.1-1.87-11.76c-10.69-6.42-13.22-17.76-18.37-27.62c-1.61-3.08-3.6-6.41-6.33-8.34
                c-3.29-2.33-7.49-3.44-11.41-4.74c-1.32-0.44-3.94-0.29-4.29,0.42c-0.66,1.33-0.67,3.58,0.05,4.9c2.45,4.53,5.41,8.78,8.02,13.23
                c0.88,1.5,1.3,3.27,1.94,4.92c-0.45,0.23-0.9,0.47-1.34,0.7c-16.7-23.23-35.75-44.32-56.74-64.66
                c-0.56,12.55,6.51,21.25,10.89,31.28c-3.82-0.68-7.14-1.66-9.98-3.42c-7.63-4.74-15.11-9.72-22.54-14.77
                c-7.5-5.09-14.34-10.71-17.11-20.02c-0.98-3.28-3.17-6.2-4.91-9.24c-4.3-7.52-10.58-11.91-19.26-13.59
                c-8.02-1.54-15.46-5.55-19.81-12.48c-9.43-15-22.23-26.33-36.19-36.76c-2.37-1.77-4.49-5.1-4.95-7.99
                c-1.77-11.07-6.67-20.58-15.19-27.35c-11.42-9.08-23.75-17.01-35.77-25.31c-1.23-0.85-3.02-0.88-4.55-1.29
                c-0.17,0.41-0.34,0.83-0.51,1.24c0.91,0.84,1.79,1.71,2.73,2.51c11.98,10.09,23.71,20.46,32.7,33.44c1.06,1.52,1.83,4.77,1,5.79
                c-2.82,3.46-2.7,6.97-1.8,10.82c1.8,7.71,6.02,14,11.52,19.52c7.87,7.89,16.11,15.43,23.57,23.69
                c10.14,11.23,19.68,23.02,29.53,34.52c1.19,1.39,2.67,2.53,4.96,4.68c0.89-8.22-3.23-13.6-4.93-19.84
                c4.22,3.66,7.96,7.58,11.21,11.89c2.98,3.96,5.16,8.51,8.14,12.47c4.7,6.24,9.33,12.65,14.8,18.18
                c8.74,8.83,18.11,17.03,27.32,25.38c0.95,0.86,3.35,1.68,3.93,1.18c5.66-4.85,10.61-1.21,15.72,0.94
                c-3.72,0.64-2.92,3.14-1.9,5.28c2.14,4.5,4.94,8.72,6.71,13.35c1.05,2.74,0.66,6.03,0.92,9.07c-0.54,0.11-1.09,0.23-1.63,0.34
                c-4.62-3.71-9.1-7.59-13.88-11.07c-6.05-4.39-12.69-8.03-18.42-12.77c-6.52-5.39-12.11-11.89-18.51-17.45
                c-4.99-4.33-10.31-8.46-16.01-11.76c-4.96-2.87-8.33-1.04-9.59,4.51c-0.98,4.35-1.33,8.85-1.75,13.31
                c-0.23,2.5-0.04,5.03-0.04,7.55c-3.42-7.73-4.86-15.59-0.56-23.06c3.59-6.22,2.62-11.39-1.95-16.32
                c-6.79-7.32-13.32-14.92-20.57-21.76c-6.08-5.73-13.03-10.54-19.6-15.75c-0.39,0.47-0.79,0.94-1.18,1.41
                c3.15,2.79,6.47,5.41,9.42,8.39c9.81,9.93,19.57,19.92,29.19,30.03c2.7,2.84,4.68,6.27,2.32,10.38
                c-4.81,8.38-4.85,17.15-2.09,26.09c1.43,4.65,0.76,8.83-1.87,12.84c-5.62,8.57-8.24,18.32-9.23,28.24
                c-1.87,18.7-2.9,37.49-4.06,56.25c-0.18,2.9-2.15,7.44,3.91,7.47c0.44,0,0.93,1.28,1.29,2.02c3.54,7.16,7.1,14.32,10.57,21.51
                c4.42,9.17,6.46,18.78,5.95,29.03c-0.18,3.68,0.92,7.42,1.37,11.13c0.4,3.21,1.58,6.63,0.9,9.61c-1.92,8.38-2.83,16.23,0.31,24.83
                c4.32,11.81,7.07,24.19,10.42,36.34c1.23,4.46,1.38,9.46,3.52,13.39c8.21,15.09,12.92,31.27,16.85,47.84
                c1.02,4.31,3.91,8.32,6.49,12.1c3.55,5.2,7.63,10.03,11.48,15.03c4.05,5.26,7.97,10.61,12.2,15.72c1.06,1.27,3.08,2.36,4.65,2.35
                c0.87-0.01,1.72-2.47,2.65-3.93c4.49,6.86,9.12,13.57,13.37,20.51c4.11,6.71,7.83,13.65,11.73,20.48
                c1.17-1.39,1.38-2.25,1.14-2.95c-3.89-11.51-7.12-23.32-12.07-34.35c-2.66-5.92-7.52-11.57-12.79-15.42
                c-7.65-5.59-10.18-13.02-9.22-21.17c0.69-5.93-0.07-11.11-1.69-16.51c-0.35-1.17-0.4-2.42-0.43-2.6c4.91,1.45,9.8,3.97,14.74,4.08
                c6.83,0.15,7.2-1.41,5.09-7.96c-3.31-10.28-7-20.49-9.35-31c-2.62-11.73-8.38-21.14-16.83-29.39c-4.13-4.03-8.03-8.49-11.14-13.34
                c-5.46-8.53-10.23-17.52-15.31-26.3c-0.68-1.17-1.54-2.23-2.31-3.35c-0.44,0.2-0.88,0.4-1.32,0.59c0.39,1.45,0.8,2.9,1.16,4.36
                c1.33,5.46,2.96,10.88,3.77,16.42c0.24,1.66-1.58,4.98-2.83,5.18c-1.92,0.31-4.86-0.86-6.14-2.41
                c-3.81-4.62-4.41-10.12-3.21-16.04c0.63-3.1,0.73-6.54,0.08-9.61c-1.51-7.16-3.85-14.1-1.75-21.62c0.52-1.85-0.48-4.21-1.06-6.26
                c-5.79-20.33-15.8-38.39-29.23-54.66c-1.21-1.47-1.93-3.92-1.8-5.85c0.91-13.43,2.53-26.82,3.06-40.26
                c0.4-9.98,2.83-19.25,8.13-27.42c5.76-8.89,7.24-18.34,7-28.55c-0.12-4.94,0.29-9.98,1.19-14.84c0.88-4.73,3.04-5.71,7.08-3.04
                c5.25,3.46,10.2,7.45,14.91,11.63c6.97,6.17,13.39,12.97,20.48,18.99c4.91,4.16,10.55,7.45,15.87,11.13l0.09-0.03
                c-9.71-3.99-19.43-7.97-29.14-11.96c-0.38,1.76-0.31,2.31,0.01,2.61c0.71,0.69,1.53,1.28,2.33,1.86
                c22.91,16.5,42.84,35.83,57.73,60.06c3.48,5.67,7.25,11.18,11.21,16.53c2.36,3.18,5.08,6.2,8.09,8.76
                c4.11,3.5,5.57,2.73,6.36-2.69c0.19-1.32,0.23-2.74,0.79-3.9c0.88-1.81,1.85-3.82,3.38-4.94c0.55-0.4,3.08,1.27,4.24,2.45
                c7.59,7.71,14.96,15.64,22.6,23.3c2.12,2.13,4.92,3.58,7.4,5.34c0.46-0.44,0.93-0.89,1.39-1.33c-0.35-1.71-0.29-3.65-1.11-5.09
                c-3.6-6.32-7.37-12.54-11.24-18.69c-1.94-3.08-4.81-5.7-6.17-8.98c-1.02-2.46-0.28-5.64-0.33-8.5c2.55,0.55,5.28,0.71,7.62,1.75
                c2.7,1.2,5.05,3.19,7.57,4.81c6.29,4.06,12.53,8.2,18.91,12.1c1.66,1.02,3.78,1.29,5.69,1.9c0.28-0.38,0.55-0.75,0.83-1.13
                c-0.81-1.33-1.49-2.77-2.47-3.97c-9.28-11.5-18.54-23.02-28-34.37c-1.63-1.96-4.04-3.98-6.41-4.47
                c-6.84-1.43-12.09-5.1-16.81-9.91c-6.25-6.38-12.29-12.98-18.84-19.03c-3.93-3.63-8.44-6.8-13.1-9.42
                c-5.61-3.16-11.78-5.33-17.48-8.34c-1.67-0.88-2.58-3.18-3.85-4.83c23.57,3.34,40.15,20,60.48,32.59
                c-0.76-3.83-1.22-6.19-1.68-8.55L431.08,526.23z M387.29,736.8c0.54-0.5,1.09-0.99,1.63-1.49c2.95,1.4,5.93,2.71,8.83,4.2
                c3.69,1.9,7.17,4.26,10.99,5.81c3.64,1.49,5,0,4.1-3.83c-0.23-0.97-0.57-1.92-0.94-2.84c-3.89-9.71-8.66-19.19-7.39-30.19
                c0.11-0.91-0.37-1.98-0.81-2.84c-4.47-8.71-8.18-17.96-13.69-25.97c-9.49-13.81-20.1-26.86-30.29-40.19
                c-0.58-0.75-1.64-1.13-2.48-1.69c-0.32,0.27-0.65,0.53-0.97,0.8c1.31,2.37,2.41,4.89,3.95,7.1c6.46,9.28,13.43,18.22,19.45,27.78
                c3.21,5.09,5.5,11,7.03,16.84c1.88,7.19,2.64,14.68,3.67,22.07c0.35,2.48,0.05,5.04,0.05,8.22c-2.75-1.24-4.64-2.26-6.64-2.96
                c-2.18-0.76-4.44-1.51-6.72-1.74c-6.68-0.65-9.23,1.76-9.29,8.43c-0.09,11.7,4.55,21.66,11.58,30.65
                c1.09,1.39,2.86,2.24,5.25,4.05c0-3.53,0-5.71,0-7.9c0.52-0.13,1.04-0.25,1.56-0.38c1.19,3.81,2.81,7.55,3.47,11.45
                c1.39,8.33,1.71,16.87,3.5,25.09c2.65,12.15,6.65,24.01,9.2,36.18c1.6,7.63,6.29,10.45,13.01,11.52c1.54,0.24,3.18-0.24,4.78-0.39
                c-0.16-1.55,0.12-3.33-0.55-4.63c-7.5-14.65-10.08-30.85-14.59-46.42c-0.81-2.78-1.34-6.3-0.34-8.82
                c2.44-6.21-0.45-10.61-3.67-15.14c-1.93-2.71-4.23-5.19-5.9-8.05C392.28,746.74,389.87,741.73,387.29,736.8z M165.42,350.11
                c4.11-0.62,8.12-0.06,9.36-1.65c3.27-4.21,7.87-3.51,11.85-5.04c1.64-0.63,4.09-3.24,3.79-4.19c-1.76-5.5-4.24-10.77-6.47-16.12
                c-0.48,0.12-0.95,0.23-1.43,0.35c1.07,4,1.65,8.22,3.34,11.93c1.9,4.18,0.56,5.21-3.36,5.78c-2.63,0.38-5.16,1.53-7.68,2.49
                c-1.88,0.72-3.61,2.23-5.51,2.45c-6.37,0.73-7.93,2.55-5.19,8.29c2.19,4.6,5.33,8.79,8.3,12.98c4.11,5.81,8.46,11.46,12.7,17.18
                c-1.62,0.1-2.45-0.43-3.23-1.01c-2.67-1.99-4.79-5.3-9.04-3.44c-0.61,0.27-1.89-0.56-2.66-1.15c-3.74-2.86-6.21-1.34-8.67,1.99
                c-9.64,13.03-10.34,29.13-1.45,42.96c1.66,2.58,1.52,4.35,0.24,7.15c-4.62,10.09-7.49,20.69-5.8,31.95
                c0.38,2.53,3.06,5.17,2.59,7.29c-1.35,6.12-3.48,12.13-5.96,17.91c-0.64,1.49-3.78,1.8-5.57,2.96c-1.37,0.88-2.82,2.01-3.57,3.4
                c-2.91,5.39-5.66,10.87-8.27,16.41c-1.7,3.6-2.29,8.02-4.74,10.92c-8.66,10.28-9.28,22.19-9.28,34.79c0,7.12-0.69,15.3-4.18,21.15
                c-9.06,15.21-12.38,32.02-17.09,48.78c-5.55-0.08-6.93,4.69-7.84,8.93c-2.69,12.47-4.86,25.06-7.18,37.61
                c-0.1,0.52,0.33,1.13,0.85,2.7c1.02-1.86,1.89-2.85,2.13-3.97c1.05-5.02,1.85-10.1,2.89-15.12c1.58-7.64,3.19-15.27,5.01-22.86
                c0.49-2.06,1.47-4.38,4.54-2.68c3.96,2.19,4.38,0.11,4.4-3.35c0.03-4.08,0.3-8.23,1.12-12.21c0.9-4.37,2.33-8.68,3.93-12.86
                c2.49-6.48,4.8-13.11,8.13-19.16c3.95-7.18,6.67-14.48,6.62-22.7c-0.02-4.49-0.67-9.09,0.05-13.46
                c1.01-6.13,2.49-12.32,4.77-18.08c2.73-6.89,6.55-13.33,9.83-20c1.68-3.43,3.05-7.02,4.86-10.38c1.69-3.12,3.8-5.85,8.09-5.58
                c0.99,0.06,2.9-1.18,2.98-1.98c1.02-9.25,9.25-16.5,4.66-27.77c-4.2-10.32-0.16-21.84,5.07-31.68c2.06-3.87,1.74-5.98-0.45-9.19
                c-8.16-11.98-7.91-27.92,0.53-39.31c0.9-1.21,3.22-2.73,4.15-2.34c5.86,2.46,11.54,5.35,17.22,8.22c3.06,1.55,5.99,3.33,8.98,5.01
                c0.4-0.51,0.8-1.02,1.2-1.54c-1.01-1.56-1.93-3.2-3.06-4.68c-7.04-9.28-14.24-18.45-21.12-27.85
                C168.71,357.46,167.41,353.94,165.42,350.11z M390.59,646.85c-0.49,0.24-0.98,0.47-1.48,0.71c0.48,2.02,0.81,4.09,1.47,6.05
                c3.55,10.56,7.15,21.1,10.78,31.63c4.39,12.74,8.53,25.58,13.43,38.12c1.82,4.65,5.23,8.77,8.38,12.76
                c1.9,2.41,3.58,1.53,5.19-1.26c4.34-7.5,3.93-14.74,0.01-22.13c-2.02-3.82-3.8-7.77-5.86-11.56
                c-5.71-10.52-11.43-21.05-17.32-31.47c-3.68-6.51-7.65-12.85-11.58-19.22C392.81,649.15,391.61,648.05,390.59,646.85z
                M104.42,170.7c0.61-0.34,1.22-0.68,1.83-1.03c-1.03-2.31-1.78-4.8-3.13-6.91c-4.55-7.11-7.16-14.82-8.9-23.07
                c-1.25-5.94-3.35-11.88-6.15-17.26c-2.89-5.52-6.75-10.67-10.93-15.3c-1.31-1.46-5.04-0.74-7.66-1.01
                c0.25,2.07,0.12,4.29,0.85,6.17c0.94,2.45,2.65,4.59,3.88,6.94c8.1,15.4,14.43,31.78,25.4,45.58
                C101.19,166.79,102.81,168.74,104.42,170.7z M506,503.77c0.24-0.65,0.48-1.3,0.72-1.95c-3.88-1.78-7.82-3.46-11.64-5.35
                c-7.84-3.87-15.66-7.78-23.41-11.82c-8.71-4.53-14.66-10.89-13.78-21.62c0.09-1.1-0.12-2.43-0.67-3.36
                c-4.18-7-10.41-11.96-16.88-16.68c3.2,4.69,7.07,8.76,10.57,13.13c1.59,1.99,3.28,4.49,3.5,6.89
                c1.29,13.97,7.24,21.67,20.44,26.26c4.18,1.45,8.07,3.72,12.1,5.6C493.29,497.84,499.65,500.8,506,503.77z M480.16,416.22
                c-0.31,0.41-0.61,0.82-0.92,1.24c1.04,1.07,1.98,2.29,3.15,3.19c7.91,6.09,15.86,12.13,23.84,18.12c1.32,0.99,2.86,2.25,4.33,2.3
                c1.48,0.04,3.85-0.89,4.37-2.06c4.31-9.61,1.97-19.72,1.85-29.72c-1.02,1.94-1.66,3.95-1.82,5.99c-0.42,5.14-0.4,10.33-1.03,15.44
                c-0.27,2.17-1.71,4.19-2.62,6.28c-1.87-0.85-3.94-1.42-5.58-2.6c-7.41-5.36-14.69-10.91-22.05-16.34
                C482.63,417.27,481.34,416.82,480.16,416.22z M38.84,603.43c11.87,11.13,23.74,22.27,36.53,34.27
                C73.16,629.83,48.76,607.11,38.84,603.43z M36.37,621.89c5.19,3.83,10.43,7.58,15.55,11.5c4.99,3.83,9.85,7.82,14.76,11.75
                c0.4-0.55,0.81-1.1,1.21-1.65C59.14,633.84,48.08,627.3,36.37,621.89z M509.14,494.95c0.29-0.31,0.59-0.62,0.88-0.93
                c-4.6-4.21-9.2-8.42-13.8-12.63c-0.07,0.09-0.15,0.18-0.22,0.28C500.38,486.09,504.76,490.52,509.14,494.95z"/>
            <path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M420.73,177.69c4.31,0,7.62,0,10.93,0c0.27-0.48,0.53-0.96,0.8-1.45c-2.33-2.1-4.67-4.21-6.83-6.15
                c4.89-0.89,9.75-1.77,14.62-2.65c0.09-0.7,0.18-1.4,0.27-2.1c-7.23,0.77-12.32-2.51-16.53-7.14c1.92-4.83,9.03-6.18,7.39-13.01
                c-1.82-0.63-3.79-1.31-5.99-2.08c1.88-3.34,3.55-6.3,5.02-8.92c-3.33-3.49-6.25-6.81-9.51-9.74c-0.66-0.59-3.06-0.22-3.92,0.54
                c-4.06,3.6-7.84,7.53-11.78,11.27c-2.11,2.01-1.91,2.56,2.43,5.75c-0.54,6.18-4.06,9.72-10.4,10.44
                c1.78,6.36,7.19,4.82,11.93,7.11c-8.31,3.11-11.29,12.94-21.95,13.19c3.41,3.8,6.83,7.61,10.85,12.09
                c-2.82,0.95-4.73,1.44-6.5,2.23c-1.4,0.62-2.65,1.61-3.96,2.43c1.02,1.42,1.92,2.96,3.11,4.23c0.84,0.89,2.08,1.41,4.06,2.69
                c-3.19,1.57-5.59,2.76-7.66,3.78c0.53,2.55,2.05,6.58,1.35,7.02c-3.06,1.89-6.85,2.86-10-0.23c-0.84-0.82-1.05-2.3-0.54-4.02
                c-1.43-1.04-2.86-2.07-4.29-3.11c0.36-0.16,0.71-0.31,1.07-0.47c-0.41-2.01-0.54-4.13-1.3-5.99c-1.55-3.83-3.51-7.5-5.11-11.31
                c-0.23-0.55,0.68-1.52,0.94-2.34c0.27-0.87,0.73-1.88,0.5-2.66c-2.68-9.16-5.47-18.29-8.26-27.42c-0.09-0.28-0.43-0.48-1.58-1.69
                c-3.37,11.04-8.25,20.73-15.14,29.48c-17.98-13.22-36.96-24.5-50.76-42.06c-0.82-1.04-1.68-2.17-2.77-2.86
                c-9.11-5.72-11.81-14.8-13.01-24.64c-1.65-13.6-6.41-26.03-13-38.01c-2.75-5-3.43-11.09-5.49-16.52
                c-2.35-6.18-5.11-12.21-7.86-18.23c-1.1-2.41-2.71-4.59-3.97-6.93c-4.95-9.16-8.73-19.15-18.22-25.83
                c2.34-0.14,4.67-0.41,7.01-0.41c237.23-0.02,474.47-0.02,711.7-0.02c68.26,0,136.51,0,204.77,0c7.85,0,7.87,0.01,7.87,7.61
                c0,75.11,0,150.22,0,225.32c0,7.25,0.02,7.46-7.05,8.71c-16.69,2.95-33.42,5.72-50.15,8.47c-7.29,1.2-13.26,4.47-18.85,9.43
                c-6.88,6.11-14.64,11.23-22.56,17.18c-1.43-2.73-2.24-4.13-2.91-5.6c-4.52-9.93-9.92-19.21-18.54-26.27
                c-4.8-3.94-7.24-9.13-8.69-15.27c-5.23-22.23-15.9-41.87-29.68-59.91c-0.79-1.04-1.79-2.12-2.94-2.65
                c-7.42-3.43-9.15-10.11-10.92-17.29c-5.45-22.13-11.22-44.18-17.25-66.16c-1.62-5.89-4.49-11.46-7.01-17.07
                c-2.02-4.51-4.78-8.28-10.1-9.62c-1.31-0.33-2.53-2.43-3.09-3.97c-0.95-2.64-1.3-5.5-1.98-8.24c-1.51-6.1-5.07-10.25-11.13-12.44
                c-2-0.72-4.22-2.36-5.18-4.18c-3.1-5.9-8.56-7.67-14.31-8.77c-1.36-0.26-3.76,1.18-4.57,2.51c-4.06,6.68-7.87,13.52-11.49,20.45
                c-0.95,1.82-1.28,4.17-1.24,6.26c0.24,10.64,0.92,21.28,1.01,31.92c0.06,7.98-0.53,15.96-0.86,23.94
                c-0.05,1.16,0.08,2.58-0.52,3.42c-6.28,8.68-4.42,18.51-3.9,28.04c0.37,6.86-0.78,12.56-7.42,15.95
                c-0.29,0.15-0.57,0.38-0.76,0.64c-10.21,13.57-25.44,21-38.12,31.56c-3.25,2.7-5.65,6.97-7.23,10.99
                c-2.17,5.54-3.17,11.54-4.72,17.33c-0.41,1.53-1.01,3-1.53,4.5c-0.47,0.12-0.94,0.24-1.41,0.36c-1.21-1.97-2.56-3.86-3.59-5.91
                c-1.94-3.86-3.88-7.27-9.24-6.81c-1.36,0.12-3.42-1.68-4.25-3.12c-1.3-2.26-1.91-4.95-2.67-7.5c-0.75-2.54-0.94-5.77-4.59-5.45
                c-3.3,0.29-7.1-0.01-8.55,4.47c-1.89,5.85-6.23,2.9-9.71,2.92c-0.19-0.4-0.39-0.81-0.58-1.21c4.39-2.54,8.79-5.07,13.18-7.61
                c-0.19-0.39-0.38-0.79-0.57-1.18c-4.48-0.52-8.95-1.05-13.43-1.57c-0.15-0.47-0.3-0.94-0.45-1.4c2.21-1.79,4.41-3.58,7.41-6.01
                c-4.57-0.33-8.05-0.58-11.52-0.83c-0.1-0.63-0.19-1.27-0.29-1.9c2.21-1.19,4.41-2.39,7.58-4.1c-4.4-1.01-7.63-1.74-10.85-2.48
                c0.04-0.5,0.09-1,0.13-1.5c2.78-0.88,5.56-1.75,8.35-2.63c-0.11-0.44-0.22-0.89-0.33-1.33c-2.89-0.91-5.79-1.83-8.68-2.74
                c-0.25-0.52-0.49-1.05-0.74-1.57c1.69-0.67,3.38-1.35,5.95-2.37c-4.52-2.4-7.2-3.83-10.51-5.59c1.91-1.47,3.11-2.39,3.49-2.69
                c-3.44-5.53-6.64-10.68-9.65-15.54c-3.44,3.06-7.15,6.2-10.61,9.59c-0.42,0.42,0.07,2.68,0.75,3.11c1.16,0.73,2.87,0.53,4.24,1.01
                c1.15,0.41,2.79,1.06,3.03,1.94c0.25,0.9-0.66,2.79-1.54,3.2c-3.35,1.55-6.9,2.67-11.43,4.35c2.35,2.53,4.68,5.03,7.4,7.96
                c-1.06,1.18-2.2,2.51-3.41,3.78c-2.22,2.33-5.39,4.15-3.37,8.37c0.3,0.62-1.23,2.61-2.33,3.3c-2.35,1.48-4.96,2.52-9.15,4.57
                c3.94,2.06,6.75,3.52,9.83,5.13c-2.3,5.88-7.86,8.11-13.39,10.37c-0.03,0.49-0.05,0.98-0.08,1.47c3.24,0.61,6.49,1.23,9.73,1.84
                c0.3,0.45,0.6,0.9,0.9,1.36c-3.99,3.37-7.98,6.75-12.2,10.31c2.88,4.17,7.08,0.68,10.59,1.15c0.18,0.55,0.36,1.09,0.54,1.64
                c-3.65,1.41-7.31,2.81-12.13,4.67c4.14,1.67,7.43,2.99,10.71,4.31c0.02,0.54,0.04,1.08,0.05,1.63c-4.81,0.86-9.61,1.72-14.42,2.58
                c-0.22,0.47-0.44,0.95-0.65,1.42c4.38,2.92,8.75,5.84,13.4,8.94c-4.58,6.34-12.73,5.53-20.29,6.57c2.56,7.87,8.83,5.12,13.63,6.12
                c-6.51,3.77-13.44,2.46-20.21,2.34c-0.29,0.51-0.58,1.01-0.87,1.52c3.87,3.35,7.75,6.69,12.68,10.95
                c-3.97,1.07-6.69,1.8-11.11,2.99c2.86,2.05,4.66,3.62,6.71,4.74c2.69,1.48,5.58,2.61,9.35,4.33c-6.72,5.66-13.22,3.93-20.77,3.37
                c5.87,7.62,15.94,7.14,20.18,14.75c-4.54,5.48-11.44,4.11-18.25,6.75c3.09,2.15,5.25,3.65,7.88,5.48
                c-5.05,3.92-10.71,2.57-16.97,2.33c3.48,7.74,10.12,8.56,16.58,9.67c0.1,0.43,0.2,0.85,0.31,1.28c-3.38,1.76-6.76,3.5-10.12,5.29
                c-1.37,0.73-2.68,1.59-4.01,2.39c1.04,1.41,1.99,2.9,3.15,4.2c0.73,0.82,1.78,1.36,3.24,2.42c-1.98,1.12-3.49,1.98-5.95,3.37
                c-0.64-4.25-0.94-7.81-1.73-11.25c-2.37-10.33-5.8-20.5-7.2-30.95c-1.72-12.93-6.54-24.61-10.91-36.65
                c-2.84-7.82-3-16.64-4.13-25.04c-0.6-4.45-0.76-8.96-1-13.45c-0.43-8.18-2.99-14.56-10.27-19.84c-4.53-3.29-6.28-10.28-9.64-15.36
                c-3.26-4.92-6.61-9.89-10.64-14.16c-2.75-2.92-5.77-2.27-7.72,2.02c-1.24,2.73-1.55,7.9-5.74,6.72c-2.43-0.68-4.7-4.84-5.49-7.85
                c-1.92-7.31-4.82-13.69-10.73-18.69c-1.61-1.36-1.97-4.13-3.12-6.13c-1.47-2.56-2.73-5.44-4.83-7.38
                c-3.66-3.4-8.55-2.45-10.89,1.99c-1.53,2.9-2.66,6.15-3.3,9.37c-1.04,5.28-1.9,10.32-7.31,13.3c-1.49,0.82-2.52,3.15-3,4.99
                c-2.19,8.37-4.55,16.73-6.04,25.23c-2.5,14.33-4.37,28.77-6.72,44.6c-3.38-7.22-6.33-13.52-9.27-19.82
                c-0.7-1.5-1.1-3.26-2.13-4.47c-0.85-0.98-3.07-2.19-3.61-1.81c-4.44,3.21-11.19,2.88-13.72,9.11c-0.62,1.53-2.23,3.12-3.77,3.71
                c-4.53,1.76-5.73,5.19-5.73,9.51c0,4,0.02,7.99,0.01,11.99c-0.02,4.97-1.19,9.58-2.53,14.43c-2.03,7.33-1.97,15.43-1.66,23.16
                c0.33,8.26,0.81,16.31-3.74,23.68c-0.58,0.93-1.42,1.7-2.14,2.54c-0.39-0.23-0.79-0.46-1.18-0.69c1.07-4.05,2.13-8.1,3.39-12.88
                c-0.65-0.45-1.87-1.55-3.29-2.26c-2.97-1.48-4.08-3.18-2.7-6.71c0.57-1.45-0.77-4.07-1.92-5.68c-1.65-2.3-3.93-4.16-6.14-6.42
                c4.15-2.02,7.42-3.61,11.23-5.47c-4.37-2.75-7.79-4.91-12.18-7.67c3.82-1.94,6.19-3.14,8.52-4.32
                c-4.18-4.01-7.96-7.65-12.08-11.61c-0.12,7.38-2.71,13.78-12.85,15.96c3.92,2.29,6.2,3.63,9.86,5.77
                c-4.29,2.47-7.81,4.49-12.24,7.03c3.35,1.98,6.45,3.81,10.34,6.11c-1.67,1.67-3.11,3.71-5.06,4.9c-3.68,2.25-5.24,4.81-3.1,8.97
                c0.28,0.55-0.39,1.6-1.85,2.52c-0.4-1.58-0.99-3.13-1.18-4.74c-2.21-18.17-3.89-36.42-6.67-54.5
                c-2.12-13.76-4.78-27.56-8.73-40.89c-3.49-11.78-9.35-22.83-13.62-34.41c-1.99-5.41-2.58-11.33-4-16.97
                c-0.68-2.7-1.16-5.88-2.91-7.76c-1.78-1.92-5.15-3.65-7.52-3.32c-1.97,0.27-4.38,3.41-5.04,5.72c-1.04,3.67-2.14,6.81-5.43,9.1
                c-1.41,0.98-2.45,2.76-3.18,4.38c-2.84,6.34-5.37,12.81-8.3,19.11c-0.61,1.31-2.33,2.72-3.71,2.94
                C428.8,182.72,424.75,181.5,420.73,177.69z"/>
            <path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M943.08,709.87c1.69,0.4,3.37,1.04,5.08,1.18c17.32,1.4,34.63,2.59,50.85-5.75
                c0.59-0.3,1.23-0.58,1.88-0.66c4.43-0.52,9-0.45,13.25-1.6c4.24-1.15,8.16-3.5,13.54-5.92c-2.5,7.5-4.94,13.7-10.68,18.07
                c-5.45,4.15-10.98,8.18-16.66,12.41c5.91,4.99,7.22,10.07,2.29,14.9c-7.64,7.49-16.56,13.67-24.93,20.42
                c-1.59,1.28-3.13,2.63-4.69,3.95c1.78,1.36,3.51,3.79,5.36,3.89c7.86,0.39,15.8,0.51,23.64-0.1c5.37-0.42,10.63-2.21,16.42-2.84
                c-4.27,8.1-7.76,16.52-14.65,22.7c-8.95,8.02-19.02,12.78-31.56,12.37c-10.61-0.34-21.3,0.47-31.91,1.32
                c-11.36,0.91-19.69,6.72-24.37,17.26c-1.89,4.26-4.49,6.78-9.53,6.11c-3.15-0.42-3.87,1.34-2.64,4c0.55,1.2,1.21,2.42,2.08,3.39
                c2.98,3.35,1.39,6.73-1.47,7.89c-6.34,2.57-13.13,4.03-20.98,6.3c0.86-4.09,1.3-6.63,1.95-9.12c3.31-12.71,6.8-25.38,9.92-38.14
                c0.82-3.34,0.61-6.94,0.78-10.43c0.23-4.68-1.07-8.25-4.93-11.73c-3.52-3.17-5.79-8.07-7.68-12.6
                c-3.16-7.58-7.2-13.93-14.48-18.45c-4.07-2.52-6.95-7.04-10.19-10.81c-7.36-8.58-14.38-17.46-21.97-25.83
                c-5.81-6.41-9.7-13.51-10.51-22.12c-2.49-26.63-8.75-52.51-15.46-78.3c-0.31-1.18-0.95-2.28-1.44-3.42
                c-0.45,0.07-0.9,0.15-1.35,0.22c0.24,2.43,0.43,4.87,0.74,7.3c3.7,29.06,8.41,58.04,10.82,87.21c1.84,22.16,0.79,44.56,0.95,66.85
                c0.01,1.63-0.15,3.49-0.92,4.86c-5.4,9.61-4.56,19.89-3.68,30.23c0.57,6.8,0.98,13.62,1.38,20.43c0.15,2.51-0.22,4.64-3.65,4.59
                c-13.62-0.18-23.58,5.87-29.97,17.88c-0.36,0.67-0.97,1.2-1.53,1.88c-7.04-11.14-7.71-11.15-16.34-0.81
                c-3.76-3.06-7.09-7.22-11.38-8.87c-5.43-2.09-8.77,3.07-12.63,6.66c6.78-60.31,11.15-120.61,10.6-182.12
                c-4.23,29.61-2.92,58.74-6.21,87.57c-0.31-7.05-0.62-14.1-0.94-21.14c-0.59-13-1.38-25.94-6.36-38.23
                c-1.75-4.33-1.66-8.7,0.88-12.97c0.96-1.61,1.29-3.79,1.33-5.72c0.49-23.96,0.97-47.91,1.24-71.87
                c0.15-13.33-0.1-26.63,4.22-39.55c1.08-3.23,1.11-6.9,1.16-10.38c0.53-33.32-1.04-66.55-4.18-99.72c-0.27-2.81-0.04-6.58,1.6-8.5
                c7.25-8.48,8.12-18.57,8.89-28.94c1.5-20.12,6.96-39.3,14.8-57.82c2.51-5.93,5.75-11.03,12.77-13.03c2.13-0.61,4-4.28,4.84-6.91
                c3.72-11.56,6.86-23.31,10.55-34.88c2.01-6.32,4.51-12.5,7.19-18.57c1.03-2.34,2.92-4.51,4.91-6.16c1.34-1.1,3.91-1.99,5.27-1.41
                c1.2,0.51,2.19,3.22,2.07,4.88c-1.2,16.43-2.51,32.85-4.13,49.24c-0.35,3.56-1.94,7.04-3.22,10.46
                c-2.14,5.73-2.52,11.43,0.3,16.96c4.3,8.44,4.19,16.78,0.77,25.52c-3.18,8.1-0.25,14.74,8.19,16.39
                c8.55,1.67,14.66,5.71,19.19,12.81c0.34,0.53,0.94,0.92,1.48,1.29c0.24,0.16,0.63,0.11,1.71,0.27c0.33-1.57,0.93-3.19,0.97-4.82
                c0.16-5.66-0.05-11.33,0.26-16.98c0.18-3.29,0.41-6.85,1.77-9.75c5.48-11.67,4.72-23.72,0.82-35.11
                c-6.82-19.91-6.82-40.63-8.95-61.09c-0.9-8.58,1.85-16.44,10.12-21.33c2.05-1.21,3.21-3.88,5.19-5.29
                c1.31-0.93,4.29-1.65,4.89-0.98c1.17,1.3,2.25,4.35,1.52,5.42c-6.15,9.01-5.39,19.19-4.75,29.04c0.77,11.76,2.4,23.52,4.51,35.12
                c1.79,9.89,1.99,19.48-0.8,29.13c-0.79,2.72-1.47,5.47-2.2,8.2c-2.09,7.8,0.07,14.31,5.51,20.16c6.9,7.41,13.56,15.05,20.24,22.66
                c4.89,5.58,8.74,11.62,9.8,19.28c0.72,5.21,3.76,9.19,8.71,11.44c9.09,4.13,18.12,8.4,27.32,12.3c3.29,1.4,6.98,1.87,11.55,3.03
                c-0.99-4.95-1.6-9.12-2.68-13.16c-1.05-3.94-0.15-5.94,4.18-6.42c2.84-0.32,5.58-1.48,8.37-2.27c0.12-0.57,0.24-1.14,0.36-1.7
                c-2.12-1.25-4.29-2.44-6.36-3.77c-4.11-2.65-8.49-5.01-12.18-8.17c-5.29-4.53-9.77-9.79-17.32-11.1c-4.55-0.79-6.78-4.2-5.67-9.37
                c1.74-8.13,3.03-16.36,4.63-24.52c0.34-1.73,1.2-3.35,2.1-5.79c8.61,5.84,18.84,9.16,20.61,20.65c1.45,9.43,5.55,14.94,15.84,14.1
                c3.28-0.27,6.65,0.24,9.88-0.27c1-0.16,2.65-2.87,2.31-3.77c-2.97-7.94-6.3-15.74-9.6-23.55c-0.35-0.83-1.25-1.44-2.83-3.19
                c-0.91,1.78-1.66,3.24-2.58,5.04c-5.54-5.45-10.67-10.15-15.31-15.3c-1.18-1.31-0.92-4.02-1.13-6.11
                c-0.59-5.89-0.96-11.8-1.72-17.66c-0.18-1.41-1.54-2.66-3.06-5.11c-1.7,4.05-2.59,7.06-4.19,9.65c-1.09,1.77-3.36,4.26-4.75,4.05
                c-2.12-0.32-4.94-2.29-5.71-4.25c-3.28-8.31-9.08-12.52-17.89-12.59c-3.15-0.02-4.18-1.44-4.77-4.41
                c-1.45-7.26-1.7-14.15,2.76-20.61c3.23-4.69,5.46-4.67,8.21,0.29c1.77,3.19,3.45,6.48,5.63,9.38c2.48,3.29,5.47,4.05,8.78,0.6
                c2.93-3.07,6.55-4.46,10.89-3.32c3.23,0.85,4.85-0.9,6.08-3.69c1.87-4.23,4.17-8.28,6.01-12.53c0.47-1.08,0.21-3.6-0.26-3.75
                c-2.04-0.65-4.42-1.24-6.41-0.76c-1.96,0.47-3.51,3.03-5.42,3.28c-2.86,0.38-7.32,0.61-8.5-1c-1.81-2.48-2.05-6.63-1.76-9.97
                c0.24-2.76,1.64-5.79,5.86-5.44c0.88,0.07,2.63-2.19,2.83-3.55c1.36-9.39,2.14-18.87,3.66-28.23c1.54-9.52,3.47-18.99,5.67-28.37
                c1.55-6.62,3.58-7.63,10.33-8.48c1.86-0.23,4.22-2,5.1-3.7c4.24-8.19,6.2-8.43,14.25-5.09c11.58,4.8,14.78,14.65,16.66,25.24
                c1.65,9.26,3.51,18.22,8.59,26.37c2.89,4.63,4.56,10.02,7.33,14.74c3.67,6.26,7.88,12.19,11.85,18.27
                c8.19,12.53,19.54,23.44,19.66,40.06c0.02,2.9,2.16,5.83,3.47,8.68c2.09,4.54,4.3,9.01,6.46,13.52c0.47-0.15,0.94-0.3,1.41-0.46
                c-0.67-3.23-1.09-6.54-2.04-9.69c-4.75-15.71-6.78-32.35-15.75-46.63c-3.55-5.67-7.19-11.19-13.69-14.11
                c-1.19-0.53-1.94-2.51-2.47-3.97c-2.62-7.15-5.12-14.35-7.61-21.54c-1.03-2.98-2.24-5.94-2.85-9c-0.52-2.57-0.83-5.36,3.34-5.87
                c0.81-0.1,2.26-3.75,1.7-4.95c-3.17-6.68-6.79-13.16-10.41-19.61c-2.01-3.57-4.76-6.76-6.43-10.47
                c-5.17-11.5-11.3-22.21-21.01-30.61c-5.1-4.42-10.64-6.42-17.41-5.62c-6.46,0.77-12.53,0.63-18.24-4.13
                c-3.43-2.86-8.73-3.49-13.58-5.27c0.71-0.81,1.25-2.03,2.13-2.35c5.56-2.02,5.94-6.19,6.22-11.46
                c0.97-18.59,2.79-37.14,3.93-55.72c0.26-4.21-0.76-8.55-1.57-12.75c-0.69-3.55-1.92-7-2.92-10.5c0.47-0.17,0.93-0.34,1.4-0.5
                c1.24,4.04,3.21,8.01,3.58,12.12c1.07,11.92,1.52,23.91,2.17,35.87c0.36,6.65,0.23,13.35,1.12,19.92c0.34,2.5,2.43,5.43,4.58,6.9
                c5.91,4.02,12.25,7.29,15.54,14.25c0.84,1.77,3.09,2.92,4.82,4.19c6.41,4.71,12.86,9.35,19.3,14.01c0.48,0.35,1.03,0.61,2.26,0.25
                c-0.93-1.2-1.68-2.64-2.84-3.56c-4.93-3.92-10.09-7.57-14.95-11.56c-2.99-2.46-5.52-5.47-8.4-8.06
                c-3.92-3.52-7.63-7.48-12.09-10.14c-4.12-2.46-5.82-5.3-5.95-9.92c-0.46-15.81-0.83-31.63-1.95-47.4
                c-0.4-5.67-2.48-11.33-4.49-16.73c-0.92-2.48-3.55-4.31-5.41-6.44c-0.43,0.23-0.86,0.46-1.29,0.69c-0.12,1.04-0.47,2.12-0.3,3.11
                c0.45,2.79,0.96,5.58,1.73,8.3c2.31,8.24,2.82,16.26,1.66,25.01c-2.12,16.13-2.41,32.52-3.08,48.81c-0.17,4.2-0.82,7.27-4.91,9.28
                c-2.1,1.03-3.67,3.12-6.77,5.89c3.68,1.03,5.97,1.46,8.1,2.3c4.86,1.92,9.5,4.54,14.49,5.93c4.79,1.33,10,2.39,14.87,1.92
                c6.76-0.66,11.9,1.27,16.79,5.65c9.17,8.21,14.96,18.54,19.93,29.58c1.66,3.68,4.45,6.85,6.62,10.31
                c3.15,5.03,6.34,10.04,9.23,15.21c1.14,2.03,1.19,3.76-1.42,5.81c-1.95,1.53-3.11,5.63-2.7,8.29c0.7,4.5,2.85,8.78,4.5,13.12
                c1.74,4.58,3.62,9.11,5.43,13.66c-0.44,0.2-0.87,0.4-1.31,0.6c-0.61-1.02-1.29-2.01-1.8-3.07c-1.96-4.05-3.5-8.34-5.86-12.13
                c-5.93-9.55-8.34-20.02-10.44-30.95c-1.36-7.07-5.01-14.33-9.55-19.95c-3.2-3.97-9.39-6.27-14.67-7.61
                c-4.74-1.21-8.46,2.44-10.38,6.92c-1.25,2.92-2.62,4.45-6.25,4.23c-5.05-0.31-8.87,3.6-10.47,10.91
                c-2.51,11.53-4.61,23.16-6.63,34.79c-1.06,6.06-1.42,12.24-2.49,18.3c-0.28,1.6-1.72,3.4-3.14,4.31
                c-5.14,3.28-6.4,8.12-6.49,13.73c-0.07,4.33,2.26,6.74,5.89,8.36c4.17,1.86,7.76,0.29,11.3-1.84c1.57-0.95,3.23-1.75,4.85-2.61
                c0.31,0.31,0.62,0.62,0.93,0.94c-2.1,4.75-4.09,9.55-6.43,14.18c-0.39,0.77-2.36,1.22-3.48,1.04c-4.53-0.74-8.28,0.76-11.39,3.81
                c-2.76,2.71-4.82,1.67-6.46-0.94c-1.94-3.09-3.44-6.46-5.31-9.6c-2.94-4.95-6.74-5.5-10.3-1.01c-4.41,5.55-7.8,11.69-6.02,19.3
                c0.19,0.81,0.29,1.64,0.4,2.46c1.23,8.7,1.23,8.68,10.06,9.67c6.14,0.69,10.87,3.52,12.61,9.7c1.37,4.86,3.74,7.96,9.16,7.87
                c4.57-0.07,5.9-3.7,7.68-6.86c0.39-0.69,0.88-1.31,2.59-1.98c0.25,2.3,0.58,4.6,0.71,6.9c0.08,1.32-0.03,2.68-0.29,3.98
                c-1.4,6.93,0.68,12.09,6.72,16.2c3.49,2.38,6.04,6.14,9,9.28c2.1,2.23,4.03,5.16,7.44,0.85c1.48,3.36,2.72,5.88,3.71,8.5
                c1.51,4.01,2.84,8.08,4.45,12.72c-6.39,1.63-12.19,0.1-17.83-1.29c-1.26-0.31-2.48-3.19-2.67-5
                c-1.51-14.13-11.81-20.93-22.58-27.24c-1.11-0.65-4.16,0.05-4.87,1.1c-1.53,2.26-2.46,5.08-3.05,7.8
                c-1.56,7.15-2.82,14.36-4.17,21.56c-1.55,8.29,1.21,12.19,9.23,14.79c3.78,1.23,7.36,3.52,10.58,5.93
                c3.04,2.27,5.24,5.65,8.28,7.92c2.97,2.22,6.53,3.64,10.57,5.82c-2.01,0.51-3.35,0.93-4.73,1.19c-5.92,1.12-6.61,2.03-5.53,7.98
                c0.39,2.12,1.13,4.18,1.56,6.3c0.69,3.41-1.16,4.61-3.93,3.32c-10.55-4.91-21.11-9.86-31.35-15.38c-2.22-1.2-4.09-4.67-4.49-7.34
                c-1.21-8.18-5.78-14.33-10.41-20.7c-1.77-2.44-2.64-5.91-2.88-9c-0.83-10.91-0.77-21.9-1.9-32.77
                c-0.91-8.72,0.56-16.87,2.96-25.08c0.63-2.16,1.1-4.65,0.67-6.8c-2.92-14.59,0.83-27.63,8.79-39.76c1.28-1.95,2.73-3.78,4.07-5.69
                c3.08-4.39,3.38-8.89,0.54-13.55c-2.77-4.55-5.58-9.08-8.16-13.74c-2.87-5.19-2.23-8.4,2.17-12.98c4.48-4.65,8.21-5.49,13.39-2.94
                c1.87,0.92,3.7,1.92,6.1,2.38c-1.15-6.13-4.41-11.03-8.6-15.11c-2.89-2.81-3.85-5.17-2.45-9.12c0.95-2.68,1.03-6.02,0.41-8.82
                c-1.17-5.22-0.41-9.97,2.07-14.47c4.19-7.6,5.24-15.71,4.49-24.18c-1.4-15.81-2.84-31.62-4.24-47.42
                c-0.47-5.25,0.45-9.99,3.2-14.79c1.86-3.24,2.19-7.67,2.23-11.58c0.12-10.8-0.29-21.61-0.46-32.42
                c-0.1-6.49-0.18-12.98-0.17-19.47c0-1.13,0.2-2.4,0.73-3.36c3.13-5.65,6.38-11.23,10.01-17.56c0.82,1.94,1.26,3.47,2.05,4.79
                c2.29,3.82,3.97,8.53,7.29,11.06c4.03,3.07,5.12,6.46,5.4,10.94c1.19,19.44,2.46,38.87,3.81,58.3c0.26,3.81,0.77,7.63,1.56,11.36
                c1.49,7.06,5.1,12.04,12.89,13.42c2.75,0.49,5.4,2.4,7.77,4.12c7.78,5.63,14.95,12.27,23.21,17.06
                c13.6,7.88,24.05,18.05,32.13,31.76c8.34,14.14,13.49,29.16,18.15,44.64c1.41,4.68,4.49,9.56,8.2,12.71
                c9.35,7.94,14.65,18.42,20.16,28.85c0.67,1.28,0.69,3.16,0.4,4.64c-1.3,6.47-3.09,12.84-4.21,19.33
                c-1.58,9.16-2.97,18.38-3.99,27.62c-1.15,10.41-1.72,20.89-2.69,31.33c-0.88,9.45-4.65,13.86-13.97,15.39
                c-2.5,0.41-5.49-1.1-8.01-2.25c-2.95-1.35-5.74-3.13-8.39-5.01c-2.63-1.86-6.22-3.6-7.27-6.24c-2.48-6.22-4.58-12.87-5.2-19.49
                c-0.85-9.07-0.16-18.28-0.17-27.43c0-1.5-0.1-3-0.27-4.49c-1.83-15.52-13.63-25.42-29.31-24.41c-1.89,0.12-3.91,0.94-5.54,1.95
                c-5.03,3.13-9.89,6.51-14.83,9.78c-0.81,0.53-1.69,0.96-2.53,1.43c0.2,0.72,0.4,1.44,0.6,2.17c3.91,0.43,7.81,1.18,11.73,1.23
                c11.59,0.16,11.32,0.05,10.9,11.63c-0.92,25.79-1.52,51.59-2.3,77.38c-0.23,7.49-0.57,14.97-1.01,22.45
                c-0.31,5.25-3.27,7.51-8.68,6.98c-4.72-0.46-7.26,1.2-6.98,5.9c0.26,4.38,1.32,8.94,3.12,12.93c2.65,5.9,3.23,11.74,2.41,18.01
                c-1.15,8.74-1.85,17.55-3.12,26.27c-0.35,2.41-1.75,4.83-3.2,6.87c-6.94,9.82-15.67,18.68-15.92,31.86
                c-0.07,3.49-0.46,6.98-0.77,10.46c-0.6,6.68-0.93,12.91-6.21,18.68c-3.83,4.19-4.96,11.12-6.59,17.02
                c-4.52,16.37-8.56,32.87-13.03,49.25c-1.53,5.6-2.99,11.43-5.81,16.41c-5.66,9.99-7.83,20.87-9.57,31.87
                c-1.84,11.64,1.71,21.94,9.1,31.17c2,2.49,3.33,6.14,3.52,9.35c0.8,13.26,1.05,26.55,1.49,39.83c0.13,3.99,0.53,8.01,0.21,11.96
                c-0.22,2.78-1.46,5.48-2.96,8.18c-4.29-9.55-10.95-17.84-11.25-28.64c-0.27-9.72-3.78-18.09-10.45-25.25
                c-11.2-12.01-23.42-22.74-38.08-30.34c-3.17-1.65-6.81-2.7-10.36-3.22c-3.01-0.44-4.5-1.66-5.05-4.47
                c-0.84-4.24-1.61-8.5-2.59-12.71c-4.2-18.16-7.94-36.44-12.83-54.42c-7.35-27.05-9.53-54.33-6.32-82.15
                c1.18-10.2,0.59-20.48,3.52-30.63c2.22-7.7-0.76-15.49-4.18-22.67c-2.86-6.01-6.14-11.83-8.89-17.89
                c-0.98-2.15-1.68-4.91-1.22-7.13c1.3-6.25,3.26-12.37,4.59-17.18c1.5,4.26,3.25,9.95,5.58,15.39c0.73,1.71,3.03,2.75,4.61,4.1
                c0.87-1.5,1.7-3.02,2.61-4.5c0.6-0.98,1.14-2.56,1.96-2.73c6.19-1.31,12.11-4.94,18.8-2.44c5.19,1.94,5.88,0.83,6.65-4.62
                c0.82-5.8,2.94-11.46,4.95-17.01c0.41-1.12,2.85-1.81,4.46-2.08c1.39-0.23,2.96,0.31,4.41,0.68c4.66,1.19,11.49-1.38,13.1,5.39
                c2.05,8.63,1,17.36-6.92,22.91c-8.81,6.18-13.82,24.23-3.1,33.8c1.33,1.19,2.38,2.79,3.3,4.35c1.88,3.22,1.93,5.58-2.38,7.28
                c-7.09,2.8-7.2,3.94-4.14,10.63c1.23,2.69,1.75,5.7,2.64,8.55c1.55,4.97,3.61,9.45,9.82,9.76c0.49,0.02,1.18,0.06,1.42,0.37
                c4.67,6.17,9.44,12.28,13.78,18.68c0.67,0.98-0.39,3.47-1.15,5.02c-3.52,7.28-3.22,14.19,1.75,20.65c2.94,3.82,5.81,7.8,9.3,11.08
                c3.21,3.02,6.93,5.8,10.91,7.65c6.6,3.07,9.03,1.28,10.82-5.88c2.5-10.01,5.22-19.97,8.18-29.85c1.18-3.94,0.3-6.43-3.32-8.12
                c-2.56-1.2-5.39-2.07-7.58-3.76c-3.17-2.46-5.95-5.45-8.76-8.36c-3.2-3.31-5.94-7.14-9.42-10.09c-4.55-3.85-7.93-8.26-10.37-14.35
                c5.78-0.54,10.19,0.47,13.23,4.88c3.97,5.76,7.61,11.74,11.7,17.41c2.11,2.92,4.65,5.64,7.46,7.88c0.92,0.73,4.43,0.14,4.82-0.71
                c3.12-6.94,6.1-13.98,8.47-21.21c1.51-4.58-0.18-8.08-4.33-11.53c-8.39-6.99-16.03-14.91-23.72-22.7
                c-5.92-5.99-12.09-11.38-20.32-14.02c-5.36-1.72-7.18-6.29-7.48-11.35c-0.52-8.8-0.66-17.62-0.99-26.43
                c-0.25-6.6-2.58-9.24-9.14-10.26c-3.78-0.59-7.65-0.76-11.37-1.59c-4.53-1.02-7.19,1.05-8.6,4.83c-2.3,6.16-4.2,12.47-6.31,18.85
                c-12.02-1.31-21.37,1.29-26.57,8.1c-6.85-9.16-4.15-20.84-8.09-30.45c-0.6-0.05-1.19-0.09-1.79-0.14
                c-0.77,2.51-2.09,4.99-2.19,7.52c-0.35,8.68-1.05,17.18-4.3,25.44c-0.87,2.21,0.14,5.61,1.21,8.06c2.74,6.25,6.13,12.2,9.02,18.38
                c4.21,9.02,5,17.98,2.55,28.07c-2.43,10.04-1.58,20.83-2.87,31.21c-4.27,34.3,4.48,66.78,13.11,99.37
                c2.89,10.92,4.75,22.11,7.08,33.18c0.58,2.77,1.21,5.52,1.7,8.31c0.99,5.62,4.1,7.55,9.87,9.04c7.32,1.88,14.57,5.85,20.61,10.52
                c8.78,6.78,16.58,14.86,24.54,22.65c5.7,5.58,7.72,12.86,9.1,20.63c1.29,7.25,3.84,14.31,6.13,21.35
                c1.28,3.94,3.18,7.68,4.74,11.53c1.49,3.68,4.09,3.96,5.64,0.74c2.18-4.52,4.38-9.53,4.56-14.41c0.34-8.94-0.94-17.93-1.26-26.9
                c-0.25-7.3,0.28-14.64-0.24-21.91c-0.26-3.58-1.24-7.76-3.42-10.43c-8.78-10.81-11.78-22.65-7.83-35.97
                c2.54-8.56,5.76-16.91,8.55-25.39c2.55-7.73,5.18-15.45,7.34-23.29c4.88-17.8,9.43-35.69,14.28-53.5
                c0.63-2.33,1.98-4.59,3.45-6.55c1.72-2.29,4.02-2.73,5.35,0.4c1.28,3.01,2.83,6.21,2.93,9.37c0.35,10.97,0.17,21.96,0.1,32.95
                c-0.09,13.41,4.37,18.55,17.43,16.08c15.64-2.96,31.2-0.96,46.76-1.97c4.41-0.28,8.96-1.12,13.04-2.73
                c1.73-0.68,3.41-4.02,3.35-6.11c-0.09-3.75-1.39-7.49-2.4-11.18c-1.31-4.77-0.4-8.02,4.29-10.82c2.72-1.63,5.41-5.33,5.83-8.42
                c1.42-10.36,1.67-20.87,2.65-31.3c1.12-11.89,1.96-23.85,4.08-35.57c0.95-5.24,5.14-9.8,7.1-14.96
                c1.92-5.04,3.06-10.41,4.17-15.71c0.43-2.04,0.04-4.34-0.37-6.45c-1.98-10.35-0.94-20.45,1.33-30.68
                c1.86-8.42,2.44-17.12,3.8-25.66c1.13-7.13,4.8-12.11,10.44-17.22c7.38-6.68,14.9-9.9,24.42-10c1.49-0.02,2.99-1.31,4.44-2.1
                c8.46-4.61,16.88-9.31,25.38-13.85c5.6-2.99,10.9-2.81,16.4,0.97c6.21,4.28,13,7.45,20.99,6.67c0.91-0.09,2.44,1.34,2.86,2.39
                c0.52,1.3,0.22,2.95,0.22,4.45c0.01,107.92,0,215.85,0,323.77c0,1.94,0,3.87,0,5.81c-0.49,0.28-0.99,0.55-1.48,0.83
                c-11.82-10.03-23.64-20.05-35.43-30.11c-0.58-0.5-1.15-1.33-1.22-2.05c-0.52-5.33-0.92-10.67-1.36-16.01
                c3.49,0.7,8.19,1.63,12.88,2.57c0.17-0.45,0.33-0.91,0.5-1.36c-9.43-8.24-19.03-16.29-28.23-24.78
                c-9.27-8.54-17.21-18.22-23.71-30.87c3.93,1.36,6.35,2.32,8.83,3.02c7.32,2.06,11.9-0.54,15.3-7.37c1.07-2.16,4.25-3.27,7.22-5.4
                c-11.57-8.03-20.27-18.87-27.46-31.07c-7.23-12.27-12.03-25.27-12.17-39.87c-0.58,0.06-1.15,0.12-1.73,0.18
                c-0.33,1.31-0.73,2.61-0.98,3.94c-4.3,22.39-12.52,43.06-26.3,61.42c-3.68,4.9-6.5,10.45-9.8,15.65
                c-1.95,3.06-0.74,4.68,2.33,5.41c1.6,0.38,3.28,0.57,4.93,0.54c4.94-0.08,6.87,2.77,4.31,7.18c-2.08,3.58-4.53,7.04-7.33,10.07
                c-15.4,16.68-31.28,32.8-52.11,42.92c-1.67,0.81-3.06,2.22-4.58,3.35c0.09,0.56,0.17,1.13,0.26,1.69
                c3.19,0.19,6.38,0.39,10.62,0.64c-14.92,14.53-31.6,23.7-51.52,27C942.86,708.63,942.97,709.25,943.08,709.87z"/>
            <path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M630.44,401.48c0,1.99,0,2.9,0,3.81c0.34,0.27,0.68,0.54,1.02,0.81c2.16-2.46,4.46-4.82,6.43-7.42
                c4.07-5.37,8.38-10.09,15.23-12.27c5.11-1.62,9.69-1.63,15.01,0.88c-1.77,2.51-3.42,4.84-5.07,7.18c0.35,0.41,0.71,0.82,1.06,1.23
                c2.2-2.09,4.24-4.4,6.65-6.2c2.5-1.86,5.27-3.43,8.1-4.75c2.92-1.36,6.05-2.28,9.33-2.95c-3.71,5.72-5.88,11.88-12.17,15.95
                c-5.47,3.55-9.66,9.6-13.35,15.24c-5.81,8.89-6.74,19.52-9.7,29.43c0.46,0.2,0.92,0.39,1.37,0.59c0.55-0.81,1.32-1.55,1.61-2.45
                c1.34-4.12,2.69-8.24,3.77-12.43c3.48-13.54,10.91-23.96,23.27-30.86c1.94-1.08,3.24-3.65,4.37-5.78
                c3.29-6.19,7.96-10.34,15.87-11.77c-1,2.13-1.62,3.59-2.37,5c-9.91,18.65-10.89,38.76-10.13,59.27
                c0.53,14.28,0.49,28.59,0.57,42.88c0.09,16.29,0.04,32.58,0.04,48.87c-0.01,14.82,0.04,29.63-0.07,44.45
                c-0.19,23.96-0.51,47.93-0.7,71.89c-0.02,2.48,0.19,5.01,0.68,7.45c2.14,10.71,1.66,21.02-2.54,31.35
                c-2.02,4.97-2.9,10.68-3.06,16.09c-1,32.43-1.59,64.87-2.44,97.3c-0.22,8.32-0.63,16.65-1.46,24.92c-0.32,3.2-2.29,6.21-3.05,9.41
                c-0.55,2.33-0.35,4.84,0.47,7.71c5.57-15.93,14.88-28.98,32.59-25.97c17.68,3,21,18.71,20.5,35.62c3.32-3.47,4.77-11.1,10.38-2.66
                c1.3-2.52,2.15-4.39,3.18-6.15c3.51-6.03,8.16-6.73,12.76-1.59c1.93,2.16,3.06,5.04,4.5,7.63c0.81,1.46,1.51,2.99,2.55,5.04
                c1.87-4.36,3.1-7.92,4.93-11.14c0.78-1.37,2.91-2.87,4.27-2.73c1.21,0.12,2.61,2.27,3.19,3.8c0.86,2.3,1.25,4.83,1.52,7.29
                c0.24,2.2,0.05,4.44,0.05,6.67c0.55,0.09,1.1,0.19,1.64,0.28c0.48-1.54,0.98-3.07,1.42-4.62c2.95-10.37,8.19-18.82,18.67-23.21
                c14.35-6.01,28.36,0.69,32.47,15.76c0.61,2.24,0.95,4.56,1.32,6.85c1.58,9.72,1.54,9.65-7.18,13.5c-2.7,1.19-5.17,2.91-7.72,4.37
                c-6.3-7.61-7.58-7.68-15.99-2.41c-1.38,0.86-4.02,0.61-5.69-0.08c-4.17-1.7-7.83-1.44-11.07,1.68c-3.96,3.8-6.73,8.18-6.79,13.66
                c0.51,0.24,1.01,0.47,1.52,0.71c1.04-2.29,1.99-4.62,3.13-6.86c3.83-7.5,7-8.44,14.67-5.09c1.37,0.6,3.52-0.17,5.15-0.76
                c1.18-0.43,2.02-1.71,3.14-2.41c4.51-2.78,7.21-1.54,8.07,3.65c0.26,1.58,0.45,3.17,0.86,6.07c2.08-1.88,3.16-3.22,4.54-4.04
                c4.06-2.4,8.07-5.14,12.47-6.62c1.78-0.6,5,1.24,6.64,2.89c0.76,0.77-0.12,4.16-1.23,5.61c-2.9,3.79-6.27,7.23-9.57,10.69
                c-10.45,10.96-19.34,22.96-23.76,37.64c-3.26,10.83-5.89,21.85-8.8,32.79c-1.52,5.73-3.51,10.99-9.46,13.9
                c-2.28,1.11-4.06,3.41-5.82,5.38c-9.17,10.28-18.14,20.73-27.48,30.85c-1.69,1.83-4.67,2.73-7.23,3.46
                c-6.97,1.97-14.03,3.63-21.05,5.4c-2.54,0.64-5.19,2-7.62,1.7c-11.71-1.47-21.4,1.63-28.68,11.24c-0.37,0.49-1.03,0.77-2.01,1.48
                c-0.76-1.22-1.68-2.18-2-3.32c-3.41-11.81-6.76-23.64-10.03-35.49c-0.8-2.88-1.33-5.84-1.9-8.79c-1.17-6.08-4.96-9.46-10.77-11.16
                c-12.76-3.74-25.52-7.52-38.18-11.58c-6.81-2.18-12.79-1.51-18.02,3.54c-9.46,9.13-18.91,18.27-28.28,27.48
                c-4.68,4.6-10.35,6.82-16.59,8.57c-17.42,4.91-34.96,9.46-51.25,17.86c-5.79,2.98-12.61,3.95-18.97,5.79
                c-4.79,1.38-9.78,2.25-14.36,4.12c-10.12,4.13-18.65,10.01-22.48,21.18c-2.42,7.07-5.68,13.86-8.79,20.68
                c-2.68,5.87-7.55,8.51-13.43,6.42c-16.27-5.79-32.06-2.2-47.92,1.63c-1.92,0.46-3.86,0.9-6.21,0.99
                c6.99-4.43,8.14-12.12,11.33-18.7c4.25-8.77,11.7-11.85,21.17-9.56c2.24,0.54,4.51,0.92,7.4,1.51c-0.41-1.58-0.58-2.63-0.96-3.6
                c-2.78-7.2-1.58-10.25,5.56-12.6c5.2-1.71,10.35-2.87,9.62-10.34c-0.11-1.1,3.43-3.49,5.41-3.65c4.37-0.36,6.06-1.56,5.72-6.38
                c-0.64-9.13,4.23-14.6,13.77-16.68c7.98-1.74,14.39,1.81,17.83,9.89c0.68,1.6,1.35,3.21,2.31,5.5
                c1.84-10.23-6.93-19.15-17.39-18.73c-11.58,0.47-18.89,7.23-19.96,18.8c-0.26,2.77-1.09,4.26-3.98,4.65
                c-4.55,0.6-6.98,3.02-7.27,7.96c-0.1,1.77-2.3,3.45-3.62,5.11c-0.26,0.33-0.91,0.36-1.38,0.52c-10.39,3.59-11.32,5.02-10.3,16.25
                c-1.18-0.27-2.29-0.39-3.3-0.77c-7.02-2.63-13.44-1.46-18.13,4.27c-4.91,5.99-10.63,6.36-17.43,4.82
                c-12.27-2.77-24.5-1.1-36.68,0.96c-1.26,0.21-2.53,0.41-4.38-0.3c2.15-1.71,4.3-3.42,6.36-5.06c-0.13-0.6-0.14-1.16-0.27-1.19
                c-7.3-1.6-14.57-4.07-21.92-4.31c-3.49-0.12-7.55,3.56-10.58,6.35c-3.28,3.02-6.16,5.97-11.87,5.16c0.63-1.89,0.85-3.69,1.76-5.01
                c2-2.9,0.91-4.48-2-4.95c-9.5-1.55-19.42-2.27-27.49,3.41c-5.52,3.88-10.37,2.85-15.9,2.31c-10.38-1.01-18.45-6-26.37-12.6
                c-5.02-4.18-12.12-5.94-18.43-8.46c-3.62-1.45-7.4-2.06-4.2-7.44c0.86-1.45,0.05-3.87,0.03-5.85c-0.04-5.71,2.53-7.21,7.52-4.35
                c1.54,0.88,3.01,1.87,5.5,2.11c-0.46-1.24-0.89-2.49-1.4-3.71c-1.48-3.58-2.5-7.43,1.39-9.85c3.74-2.33,7.15-0.88,10.15,2.37
                c3.4,3.68,7.25,6.95,10.9,10.4c0.49-0.49,0.98-0.98,1.47-1.47c-1.16-1.12-2.28-2.29-3.5-3.35c-6.54-5.68-11.79-12.22-13.9-20.85
                c-2.27-9.29,0.69-17.61,7.77-21.94c6.65-4.07,16.04-3.32,22.53,3.24c4.7,4.75,7.99,10.91,11.86,16.47
                c1.14,1.64,2.1,3.39,3.14,5.09c0.49-0.22,0.99-0.45,1.48-0.67c-0.39-1.43-0.76-2.87-1.18-4.29c-0.56-1.9-1.71-3.82-1.6-5.68
                c0.13-2.13,0.81-4.91,2.29-6.04c1.2-0.91,4.14-0.26,5.95,0.53c2.65,1.15,4.97,3.04,8.83,5.51c-0.53-2.98-0.71-4.45-1.07-5.87
                c-0.98-3.91-2.36-8.12,1.18-11.23c3.63-3.18,7.52-1.01,11.19,0.66c0.87,0.4,1.83,0.61,3.55,1.17c0-2.16,0.1-3.85-0.02-5.53
                c-0.35-5.1-0.66-10.5,4.24-13.54c4.7-2.92,9.87-2.29,14.63,0.72c0.66,0.42,1.49,0.56,3.03,1.12
                c-0.05-15.51,5.83-27.21,20.42-32.98c14.05-5.55,26.73-2.5,38.49,8.15c0.34-2.19,0.26-3.52,0.76-4.56
                c1.15-2.4,2.52-4.71,3.94-6.97c0.5-0.79,2.02-1.28,2.05-1.95c0.27-5.97,4.58-7.32,9.15-8.99c1.48-0.54,2.66-3.17,3.11-5.05
                c1.77-7.38,4.75-8.96,11.85-5.89c1.89,0.82,3.85,1.5,6.75,2.61c-0.34-3.08-0.71-5.14-0.78-7.2c-0.18-5.29-1.19-10.77-0.16-15.83
                c3.27-16.08,23.73-23.19,37.21-14c-3.58,3.68-6.83,7.95-10.97,11.02c-4.45,3.3-9.65,5.64-14.68,8.07
                c-4.52,2.18-6.46,6.75-3.92,11.85c0.6-1.85,1.03-3.17,1.46-4.49c0.96-2.91,14.82-11.97,17.95-11.73c0.32,0.02,0.65,0,0.95,0
                c7.82-11.21,8.43-11.3,23.78-3.07c-0.67-6.85,2.74-11.95,5.93-17.43c3.91-6.72,6.38-14.27,9.88-21.25
                c1.76-3.51,4.91-6.35,6.57-9.9c1.92-4.1,3.2-8.58,4.22-13.02c2.68-11.63,4.9-23.37,7.7-34.97c0.64-2.64,2.88-4.88,4.38-7.31
                c0.39,0.16,0.78,0.33,1.18,0.49c0.3,1.81,0.61,3.62,0.91,5.42c0.36,0.04,0.72,0.07,1.08,0.11c0.66-2.81,1.51-5.59,1.97-8.44
                c3.98-24.81,7.88-49.64,11.82-74.46c0.29-1.81,0.39-3.71,1.02-5.39c7.52-19.81,8.18-41.06,13.05-61.41
                c1.52-6.34,4.26-12.61,7.57-18.25c7.68-13.09,13.76-26.37,14.06-42.05c0.21-11.11,1.88-22.22,3.52-33.25
                c0.72-4.85,2.91-9.5,4.55-14.2c2.38-6.85,4.81-13.68,7.32-20.48c3.61-9.79,7.31-19.55,11.01-29.3c0.26-0.67,0.88-1.2,2.16-2.88
                c-2.38,18.47-4.4,35.61-6.85,52.68c-2.44,17-7.2,33.72-5.8,51.36c0.43-0.73,1.12-1.43,1.24-2.21c2.96-19.24,6-38.48,8.73-57.75
                c2.19-15.49,2.9-31.29,6.33-46.48c2.82-12.46,7.67-24.74,13.48-36.15C609.35,417.23,620.15,410.23,630.44,401.48z M608.91,707.99
                c1.09-4.33,2.78-8.61,3.15-13c0.71-8.28,0.77-16.62,0.9-24.93c0.58-36.59-0.44-73.29,6.27-109.52
                c3.97-21.45,6.91-42.91,7.76-64.82c1.17-30.3,5.3-60.35,15.12-89.3c0.26-0.77,0.03-1.71,0.03-2.56c-0.5-0.15-1-0.3-1.5-0.45
                c-2.36,6.62-5.3,13.1-6.97,19.9c-6.71,27.24-10.45,54.94-10.58,83.01c-0.07,15.72-1.45,31.19-4.69,46.58
                c-4.64,22.03-6.91,44.26-6.91,66.89c0,29.43-1.61,58.85-2.52,88.28L608.91,707.99z M662.52,586.35c-0.48-0.01-0.95-0.01-1.43-0.02
                c0,10.43-0.13,20.86,0.02,31.28c0.42,29.29,0.93,58.58,1.45,87.87c0.26,14.45,0.72,28.9,0.94,43.35
                c0.09,5.65,0.44,11.42-0.5,16.94c-4.6,26.87-5.31,53.91-4.23,81.05c0.13,3.32,0.46,6.64,0.83,9.95c0.1,0.93,0.68,1.8,1.04,2.7
                c0.41-0.07,0.82-0.15,1.23-0.22c0-1.94,0.03-3.88-0.01-5.82c-0.24-12.81-1.16-25.65-0.57-38.42c0.71-15.43,2.77-30.8,4.08-46.21
                c0.53-6.29,1.54-12.75,0.72-18.91c-2.47-18.47-1.28-36.9-0.76-55.37c0.11-3.99,0.34-8,0.23-11.98
                c-0.86-30.45-1.78-60.91-2.7-91.36C662.81,589.59,662.64,587.97,662.52,586.35z M669.68,946.09c0.55,0.41,1.11,0.82,1.66,1.24
                c2.35-7.27,6.67-5.75,11.41-4.08c1.07,0.38,3.37-1.16,4.35-2.41c3.63-4.62,7.65-5.25,11.8-1.14c2.17,2.15,3.86,4.78,4.9,6.1
                c3.84-2.49,7.16-6.25,10.77-6.54c3.98-0.32,5.71-1.45,7.59-4.58c4.5-7.51,15.06-11.1,23.68-8.51c6.23,1.87,8.79,6.78,8.8,17.14
                c0,3-0.28,6.01-0.44,9.02c2.83-6.31,3.19-13.98,1.48-19.49c-1.49-4.8-4.48-7.91-9.51-9.25c-9.84-2.61-20.45,1.12-26.46,9.51
                c-0.83,1.15-1.85,2.52-3.08,2.97c-4.17,1.54-8.48,2.71-13.54,4.27c-0.54-0.58-1.78-2.15-3.26-3.44c-4.22-3.7-8.85-3.65-13.05-0.02
                c-5.61,4.85-12.57,2.92-14.67-4.07c-3.08-10.23-15.07-17.02-26.33-14.91c-9.68,1.82-16.85,11.38-15.04,22.01
                c0.97,5.71,4.44,10.99,6.36,15.48c3.75-0.9,6.88-1.66,11.16-2.69c-2.17-1.29-3.53-2.27-5.03-2.96c-6.43-2.97-9.3-7.38-8.73-13.6
                c0.68-7.35,4.85-12.93,10.95-14.62c12.15-3.38,24,5.41,24.21,17.96C669.71,941.68,669.68,943.88,669.68,946.09z M626.18,850.06
                c0.41-0.06,0.83-0.13,1.24-0.19c2.39-65.14,4.78-130.29,7.17-195.43c-0.8-0.02-1.6-0.03-2.4-0.05c0,11.07,0.46,22.17-0.11,33.21
                c-0.88,17.25-2.44,34.46-3.72,51.69c-0.55,7.45-1.36,14.89-1.62,22.35c-0.48,13.79-0.68,27.58-0.94,41.38
                c-0.24,12.8-0.46,25.6-0.54,38.4C625.25,844.29,625.86,847.17,626.18,850.06z M677.58,421.52c-0.36-0.18-0.71-0.35-1.07-0.53
                c-0.77,1.42-1.82,2.75-2.26,4.27c-4,13.55-9.09,26.92-11.54,40.75c-3.69,20.91-6.9,42.09-7.81,63.27
                c-1.3,30.43-0.33,60.96-0.33,91.44c0.61-5.28,0.98-10.58,1.11-15.88c0.75-31.57-0.9-63.23,4.09-94.63
                c2.76-17.38,4.48-34.94,7.8-52.21C669.94,445.65,674.17,433.66,677.58,421.52z M351.02,1007.2c1.86-9.8,8.43-10.58,15.81-10.04
                c1.43,0.1,2.97-0.09,4.34-0.52c3.36-1.05,6.73-2.12,9.93-3.56c2.54-1.14,6.38-2.41,6.91-4.36c1.11-4.11,3.11-5.26,6.68-4.47
                c8.8,1.94,13.1-5.98,19.68-8.86c0.63-0.28,0.94-1.72,1.07-2.67c1.58-10.96,11.53-15.83,22.05-14.4c1.11,0.15,2.27,0.02,4.2,0.02
                c-9.97-6.61-23.88-1.25-28.41,9.47c-4.07,9.64-12.43,15.75-21.36,13.41c-1.5-0.39-4.94,1.14-5.15,2.22
                c-1.19,6.3-5.48,7.16-10.66,7.29c-1.42,0.03-2.82,0.88-4.25,1.29c-2.75,0.8-5.47,2.03-8.27,2.22c-5.41,0.35-10.04,1.62-13.49,6.28
                c-0.81,1.09-3.31,1.57-4.88,1.33c-3.75-0.56-7.37-2.32-11.11-2.51c-6.88-0.36-13.74,0.29-18.88,5.85
                c-4.15,4.49-4.47,11.44-0.3,15.33c-0.09-1.71-0.12-2.95-0.23-4.19c-0.63-7.06,2.42-11.56,9.13-13.45
                c7.15-2.02,14.13-1.79,20.9,1.55C346.49,1005.28,348.31,1006.01,351.02,1007.2z M651.18,894.76c-1.6-6.64-5.22-10.67-10.54-13.66
                c-20.19-11.36-50.59,8.61-48.81,29.12c0.12,1.41-1.37,3.92-2.63,4.33c-7.13,2.32-10.31,7.13-10.98,14.4
                c-0.39,4.15-1.91,7.55-7.17,8.62c-9.49,1.92-12.92,12.03-7.17,19.89c0.51,0.7,1.57,1.01,3.47,2.16c-1.09-3.03-1.77-4.7-2.3-6.41
                c-2.37-7.57,1.68-12.78,9.62-12.53c1.02,0.03,2.72-0.29,2.99-0.92c1.7-4,3.56-8.03,4.47-12.24c1.05-4.85,4.27-9.27,8.37-9.17
                c2.1,0.05,4.18,1.21,6.11,1.82c-0.3-5.44-1.75-10.92-0.56-15.75c1.2-4.88,4.28-9.85,7.88-13.44c3.62-3.62,8.64-6.23,13.49-8.15
                C630.79,877.57,640.15,880.96,651.18,894.76z M611.62,498.57c-0.41-0.15-0.81-0.29-1.22-0.44c-0.62,1.82-1.48,3.6-1.83,5.48
                c-2.52,13.4-5.2,26.77-7.3,40.23c-2.49,15.93-5.47,31.88-6.42,47.93c-1.32,22.25-0.82,44.6-8.46,66.03
                c-1.36,3.81-1.48,8.06-2.17,12.11c0.77,0.13,1.53,0.26,2.3,0.39c3.29-17.23,7.21-34.37,9.65-51.72
                c1.73-12.3,0.59-25.01,2.29-37.32c3.55-25.7,8.14-51.25,12.29-76.87C611.06,502.47,611.33,500.52,611.62,498.57z M776.44,923
                c0.69-0.43,1.38-0.85,2.07-1.28c-0.51-1.95-0.41-4.59-1.66-5.72c-2.36-2.13-5.35-3.81-8.35-4.94c-4.3-1.63-9.08-9.63-7.21-13.71
                c4.39-9.62,0.09-17.22-5.7-24.12c-4.44-5.3-12.56-5.93-16.97-1.17c-4.18,4.51-7.18,8.01-10.93,0.06c-0.12-0.26-0.53-0.55-0.8-0.55
                c-5.61,0.13-6.74-4.03-8.63-8.14c-2.14-4.67-8.13-6.15-12.45-3.49c-1.89,1.16-3.14,3.37-4.67,5.1c0.34,0.26,0.69,0.51,1.03,0.77
                c9.32-6.71,11.82-5.59,14.68,6.08c0.26,1.06,2.85,1.68,4.45,2.25c1.58,0.56,3.8,0.28,4.83,1.29c3.02,2.95,5.6,6.36,8.82,10.14
                c0.55-1.79,0.97-2.96,1.26-4.16c1.16-4.89,3.46-8.71,8.98-9.34c5.4-0.62,8.94,2.25,11.21,6.69c3.49,6.84,6.29,13.81-0.61,19.58
                c1.62,2.38,4.19,4.52,3.84,5.95c-1.34,5.54,2.44,7,5.98,8.01C771.06,913.86,776.43,915.03,776.44,923z M542.85,870.14
                c0.41-0.06,0.82-0.12,1.23-0.18c0-2.13,0.28-4.31-0.04-6.4c-4.31-27.41-3.73-54.99-3.08-82.59c0.33-14.18,0.06-28.37,0.06-42.55
                C539.04,782.38,532.75,826.46,542.85,870.14z M501.72,965.6c-8.29-0.88-8.1-0.77-12.32,6.28c-1.53,2.56-3.95,4.87-6.49,6.43
                c-1.26,0.78-3.71-0.44-5.64-0.68c-3.53-0.45-7.09-0.93-10.06,2.16c4.22,0.31,8.26-0.03,11.87,1.06c3.67,1.1,6.57,2.75,7.45-2.77
                c0.08-0.52,0.83-1.28,1.33-1.33c3.34-0.39,4.04-2.72,4.71-5.49c0.91-3.73,3.31-4.73,6.78-2.77c1.76,0.99,3.59,1.87,6.18,3.21
                c-0.24-4.49-0.67-7.92-0.57-11.34c0.42-14.97,12.3-25.19,26.08-22.36c8.81,1.81,15.06,7.43,20.06,14.58
                c1.63,2.33,3.01,4.84,5.1,8.22c-2.75-14.2-17.14-26.03-29.89-25.59C511.73,935.7,504.55,944.48,501.72,965.6z M585.01,553.08
                c0.35,0.04,0.69,0.07,1.04,0.11c0.26-0.53,0.69-1.04,0.76-1.59c1.87-14.84,3.85-29.66,5.49-44.52
                c2.35-21.31,3.5-42.82,10.83-63.25c1.93-5.39,4.28-10.63,6.43-15.94c-0.34-0.22-0.67-0.44-1.01-0.67
                c-2.17,3.55-5.13,6.85-6.36,10.7c-3.25,10.11-6.88,20.3-8.32,30.74c-3.56,25.83-6,51.82-8.83,77.75
                C584.8,548.61,585.01,550.86,585.01,553.08z M677.49,482.79c-0.45,0.01-0.9,0.02-1.35,0.03c-1.03,11.41-2.94,22.82-2.84,34.22
                c0.09,10.7,2.14,21.41,3.63,32.07c2.81,20.17,3.93,40.36,2.09,60.68c-0.19,2.13-0.03,4.3-0.03,6.45c0.49,0.01,0.97,0.03,1.46,0.04
                c0.29-1.03,0.72-2.04,0.84-3.08c2.47-23.01,0.06-45.78-3.15-68.55c-1.48-10.49-2.67-21.1-2.78-31.66
                C675.24,502.93,676.71,492.86,677.49,482.79z M608.32,746.03c-0.54-0.05-1.08-0.09-1.63-0.14c-3.64,6.2-8.86,77.06-5.57,83.12
                C603.57,800.78,605.95,773.4,608.32,746.03z M246.73,1054.88c-1.06-2.15-2.26-4.24-3.15-6.46c-3.02-7.49,1.21-13.45,9.35-13.11
                c1.96,0.08,3.96,0.55,5.84,1.17c2.97,0.97,4.66,0.32,5.41-2.95c1.19-5.15,4.75-7.77,9.9-8.4c2.63-0.32,5.29-0.54,7.89-1.02
                c1.58-0.29,3.76-0.53,4.49-1.6c3.85-5.63,8.84-4.8,14.23-3.28c1.61,0.45,3.41,0.22,5.13,0.31c-6.04-5.13-14.76-5.14-19.44-1.62
                c-2.34,1.76-5.37,2.84-8.25,3.62c-5.35,1.43-11.77,0.88-14.65,6.9c-2.06,4.31-4.38,5.64-9.23,4.55
                c-5.98-1.34-12.22,2.36-13.97,7.15C238.51,1044.95,240.98,1051.67,246.73,1054.88z M393.67,1027.08c0.3-2.24,0.56-3.76,0.71-5.29
                c0.79-8.27,7.16-15.01,15.26-16.11c6.36-0.86,10.6,1.91,12.39,8.05c0.32,1.09,0.79,2.13,1.53,4.07
                c1.82-8.48-2.64-14.42-10.37-15.22c-8.11-0.85-16.49,4.13-20.43,12.4c-1.21,2.54-1.97,4.78-5.67,3.93
                c-1.16-0.27-3.59,1.04-4.02,2.17c-1.37,3.56-3.87,4.41-7.26,4.74c-1.22,0.12-2.28,1.76-4.15,3.32c4.23-0.33,7.02-0.33,9.72-0.86
                c1.18-0.23,2.63-1.31,3.14-2.39c2.33-4.87,2.8-4.98,6.53-0.81C391.55,1025.65,392.26,1026.03,393.67,1027.08z M742.26,989.95
                c1.13-3.42,1.3-7.71,3.42-9.18c1.84-1.28,5.89,0.63,9.39,1.2c-0.18-8.01-0.41-17.09,11.48-18.07c6.05-0.5,8.22,1.89,10.89,10.66
                c2.58-5.01,0.99-9.55-4.22-12.09c-8.36-4.07-17.84,0.14-20.59,9.42c-0.9,3.04-1.84,4.63-5.48,4.92
                C741.59,977.27,738.95,984.78,742.26,989.95z M365.48,1039.61c0.35-7-4.37-13.5-10.79-15.73c-6.13-2.13-12.55-0.43-15.74,5.15
                c-2.15,3.76-4.06,4.07-7.85,2.69c-5.51-2.01-9.06,1-9.68,7.59c2.63-1.72,5.12-4.61,7.73-4.72c2.92-0.12,5.95,2.27,9.44,3.78
                c1.7-7.05,4.26-13.3,12.93-12.3C359.1,1026.94,362.12,1033.13,365.48,1039.61z M398.09,960.36c-9.3-5.95-18.67-8.79-28.95-5.26
                c-5.09,1.75-8.72,5.66-9.13,11.41c-0.47,6.64,0.66,12.9,5.68,17.82c-0.11-2.79-1.04-5.33-1.73-7.94
                c-3.65-13.78,2.13-21.21,16.25-20.07C386.02,956.8,391.7,958.87,398.09,960.36z M679.42,910.8c4.76-0.53,9.38-0.94,13.95-1.62
                c0.91-0.13,2.13-1.07,2.45-1.92c1.48-3.89,3.78-2.85,6.3-1.35c1.21,0.72,2.45,1.38,3.12,1.75c1.94-3.8,2.85-8.56,5.57-10.05
                c2.78-1.53,7.32,0.16,10.69,0.41c-3.59-4.91-10.32-6.14-14.35-1.5c-2.9,3.34-4.39,8.1-10.3,6.18c-0.43-0.14-1.1,0.67-1.71,0.89
                c-2.16,0.78-4.29,1.82-6.52,2.15C684.54,906.35,680.91,907.33,679.42,910.8z M268.72,1019.51c0.94-7.56-3.36-15.39-8.76-17.63
                c-4.06-1.68-8.02-0.13-10.53,4.11c-2.99,5.06-2.58,10.43,1.25,13.25c0-2.08-0.07-3.98,0.01-5.88c0.17-3.81,0.87-7.68,5.06-8.84
                c4.1-1.14,6.45,2.04,8.25,5.07C265.66,1012.36,266.82,1015.43,268.72,1019.51z M571.5,655.41c1.68-17.95,3.35-35.9,5.03-53.85
                C572.76,609.97,568.91,648.58,571.5,655.41z M555.5,911.85c0.36-5.78-2.87-12.15,3.28-13.76c3.84-1,8.65,1.74,12.9,2.76
                c-2.5-3.65-6.16-6.96-11.3-6.09c-2.76,0.46-6.04,2.87-7.4,5.33C551.09,903.49,551.59,907.62,555.5,911.85z M723.47,975.1
                c-3.59-3.96-10.28-4.77-15.82-0.99c-3.98,2.72-4.96,6.62-3.19,9.93c2.24-2.81,4.11-7.22,7.29-8.59
                C715.35,973.91,720.12,975.1,723.47,975.1z M682.43,438.15c-0.46-0.07-0.93-0.14-1.39-0.22c-1.12,11.36-2.24,22.72-3.35,34.08
                c0.33,0.03,0.66,0.07,0.99,0.1C679.93,460.79,681.18,449.47,682.43,438.15z M649.95,420.93c0.77,0.17,1.53,0.33,2.3,0.5
                c1.9-7.97,3.81-15.94,5.71-23.91c-0.54-0.14-1.08-0.28-1.62-0.41C654.21,405.05,652.08,412.99,649.95,420.93z M346.46,984.85
                c-3.53-3.15-7.2-3.29-10.84-0.89c-3.45,2.28-5.29,5.42-4.13,10.2C334.36,987.47,338.19,982.7,346.46,984.85z M595.5,962.92
                c-0.17-8.44-0.17-8.44,8.8-12.36c-4.83-1.56-8.21,0.01-10.53,3.56C591.99,956.87,591.69,959.89,595.5,962.92z M463.06,897.17
                c-3.53-3.06-7.35-3.23-10.96-1.13c-1.89,1.1-3.14,3.31-4.69,5.01c0.43,0.55,0.86,1.1,1.29,1.64
                C452.42,897.76,457.24,896.11,463.06,897.17z"/>
            <path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M219.04,1168.38c6.06-3.18,10.79-6.09,15.86-8.2c3.8-1.58,8.09-1.93,12.04-3.21
                c2.97-0.96,6.01-2.16,8.57-3.91c7.18-4.89,7.91-7.87,3.37-15.25c-9.67-15.7-23.78-24.21-42.27-25.39
                c-6.2-0.4-12.37-1.11-18.54-2.24c8.76-7.26,19.19-9.03,29.55-11.01c1.14-0.22,2.32-0.26,3.46-0.48
                c10.36-2.04,11.7-4.27,8.49-14.11c-2.44-7.49-0.56-9.99,7.26-9.25c4.15,0.4,4.48-1.46,2.77-4.31c-2.28-3.82-4.91-7.43-7.95-11.95
                c5.71,1.25,10.89,2.21,15.97,3.54c3.91,1.02,6.92,0.1,10.14-2.35c8.03-6.11,17.37-4.91,27.1-4.71c-0.43,1.92-0.82,2.98-0.87,4.06
                c-0.08,1.87-0.68,4.5,0.29,5.45c1.23,1.21,4.22,2.01,5.69,1.33c3.64-1.68,7.4-3.79,10.1-6.67c4.88-5.19,10.43-7.01,16.94-4.43
                c2.44,0.97,5,1.62,7.95,2.56c-1.49,1.71-3.11,3.27-4.31,5.09c-0.52,0.78-0.53,2.27-0.13,3.15c0.23,0.51,1.75,0.64,2.66,0.58
                c1.31-0.09,2.61-0.51,3.91-0.81c13.15-3.03,26.36-3.23,39.57-0.51c3.07,0.63,6.07,1.64,9.1,2.48c-2.05,3.69-3.44,8.05-6.32,10.9
                c-3.71,3.67-8.61,6.13-13,9.1c-3.74,2.52-7.48,5.02-11.23,7.53c0.29,0.62,0.58,1.25,0.87,1.87c1.54-0.41,3.27-0.49,4.58-1.27
                c13.99-8.36,29.32-12.78,45.32-14.4c7.92-0.8,16.11-1.18,24.01,1.89c3.44,1.33,7.44,1.21,11.77,1.84
                c-0.31,5.26-0.52,10.82-0.97,16.37c-1.15,13.95-2.5,27.89-8,40.99c-0.71,1.69-2.24,3.03-3.41,4.52c-0.6,0.77-1.37,1.44-1.82,2.29
                c-5.66,10.65-0.89,24.65,10.43,28.69c9.49,3.38,19.35,5.71,29.09,8.38c1.44,0.39,3.08,0.05,4.62,0.05
                c0.06-0.46,0.13-0.92,0.19-1.37c-2.61-0.49-5.21-1.23-7.85-1.44c-10.22-0.83-19.3-5.04-28.1-9.76c-5.06-2.71-6.99-7.7-7.01-14.18
                c8.55,11.36,20.27,16.22,32.54,20.53c7.59,2.67,14.78,1.33,21.42-1.36c16.95-6.87,32.1-2.25,46.67,6.14
                c6.32,3.64,12.14,4.64,19.36,3.23c9.41-1.84,19.13-2.34,28.75-2.87c13.14-0.72,22.77,7.19,32.79,14.08
                c2.25,1.55,4.89,2.74,7.52,3.46c6.21,1.69,12.52,2.99,18.79,4.46c4.02,0.94,8,2.08,12.06,2.8c6.14,1.1,9.41,4.05,10.74,10.18
                c1.41,6.47,4.31,9.82,10.95,9.14c10.86-1.11,19.62,3.15,28.73,7.88c5.07,2.63,11.03,3.55,16.57,5.29
                c1.85,0.58,3.65,1.29,5.45,2.61c-9.18,0.37-17,7.15-26.77,4.55c-3.06-0.82-7.34-0.45-9.96,1.17c-4.73,2.92-8.99,4-13.68,0.86
                c-6.02-4.03-12.88-3.68-19.6-4.28c-1.43-0.13-2.97-0.49-4.21-1.2c-7.05-4.06-7.98-4.53-13.59,0.78
                c-8.39,7.95-19.3,8.98-29.49,11.87c-5.73,1.62-11.68,4.29-17.88,1.26c-2.61-1.27-5.51-2.07-7.86-3.68
                c-4.44-3.04-9.65-3.96-13.07-1.73c-6.29,4.11-7.6,8.14-6.6,14.71c1.16,7.67,4.38,13.82,10.22,18.79c0.73,0.62,1.27,1.96,1.22,2.93
                c-0.19,3.45-0.99,6.88-1,10.31c-0.03,9.05-0.03,18.11,0.57,27.13c0.14,2.12,2.34,4.44,4.14,6.05c3.91,3.5,8.18,6.6,12.71,10.19
                c-6.31,8.79,0.37,16.84,1.27,25.3c0.87,8.23,2.23,16.17-5.91,22.49c-10.38,8.06-13.79,20.14-15.53,32.51
                c-0.78,5.57-1.66,10.6-5.47,15.12c-2.12,2.52-3.1,6.16-4.06,9.46c-1.06,3.68-0.36,7.15,3.82,8.55c4.2,1.4,8.18,0.95,11.5-2.42
                c3.43-3.48,6.91-6.9,10.39-10.32c0.71-0.69,1.62-1.2,2.26-1.94c1.47-1.7,2.76-4.9,4.24-4.96c3.81-0.17,7.66,1.23,11.53,1.6
                c3.15,0.3,6.65,0.84,9.48-0.18c6.76-2.43,14.19-4.52,19.57-8.91c8.18-6.68,17.88-12.77,19.32-25.15
                c0.87-7.43,6.14-14.45,1.71-21.1c2.31-2.49,4.01-4.31,6.23-6.7c-0.22-0.05,0.73-0.12,1.23,0.32c5.8,5.14,12.01,9.92,17.1,15.69
                c3.62,4.1,5.51,9.66,8.51,14.36c3.06,4.8,4.21,9.84,4.39,15.52c0.32,10.48,1.31,20.93,2.05,31.39c0.29,4.1-1.25,5.9-5.57,5.02
                c-3.68-0.75-7.43-1.17-11.15-1.73c-0.23-0.49-0.45-0.97-0.68-1.46c1.52-0.64,3.05-1.29,4.46-1.88
                c-3.27-3.86-27.57-5.81-36.32-3.88c-0.04,0.45-0.09,0.89-0.13,1.34c9.67,0.55,19.35,1.09,29.75,1.68
                c-4.7,4.46-1.41,6.23,2.25,6.56c17.7,1.6,35.43,3,53.17,3.97c2.04,0.11,4.93-2.47,6.21-4.55c1.44-2.35,2.65-3.27,5.39-3.11
                c2.94,0.17,6.02-0.02,8.85-0.77c1.4-0.37,3.11-2.28,3.27-3.66c0.12-1.01-2.06-2.29-4.03-3.5c1.32,4.25-0.38,5.38-3.72,5.43
                c-4.27,0.06-5.27-0.98-3.67-5.01c7.12-17.87,12.23-36.61,23.97-52.44c3.57-4.81,5.36-10.89,8.56-16.02
                c1.17-1.88,4.05-2.69,5.86-3.81c5.54,6.68,10.75,12.96,15.75,18.98c1.7-0.51,3.76-1.8,4.68-1.23c1.07,0.66,1.06,2.97,1.69,4.5
                c0.5,1.21,1.51,3.37,1.87,3.29c6.84-1.59,9.57,4.62,14.09,7.29c3.64,2.15,7.18,4.54,10.5,7.15c2.84,2.24,5.35,4.92,7.95,7.45
                c5.41,5.29,10.84,10.57,16.14,15.97c0.79,0.8,0.88,2.29,1.73,4.68c-8.71-6.67-17.67-5.71-27.67-5.86c1.75,1.28,2.53,1.85,4.11,3
                c-7.95,0-14.89,0-21.83,0c-0.01,0.45-0.02,0.91-0.02,1.36c12.14,0.62,24.27,1.25,37.08,1.91c-2.29,4.36,0.09,5.35,3.18,6.08
                c6.13,1.45,12.23,3.02,18.32,4.61c1.67,0.43,3.28,1.1,4.92,1.66c-0.03,0.5-0.05,1-0.08,1.5c-1.76,0.16-3.54,0.58-5.28,0.45
                c-6.97-0.55-13.61,0.38-19.85,3.68c-7.48,3.97-8.35,9.68-2.1,15.45c2.16,1.99,4.81,3.45,7.88,5.61c-1.63,1.13-3.11,1.83-4.12,2.94
                c-1.24,1.35-1.98,3.15-3.13,4.59c-1.34,1.68-2.79,4.41-4.38,4.56c-5.87,0.55-11.82,0.22-15.95,0.22c-2.19-3.2-3.22-5.61-5.02-7.1
                c-2.03-1.68-4.74-2.53-7.16-3.74c0.11-0.49,0.22-0.97,0.33-1.46c1.34-0.22,2.71-0.36,4.03-0.67c2.4-0.57,5.11-0.73,7.06-2.02
                c1.7-1.12,3.41-3.55,3.44-5.43c0.02-1.47-2.28-3.84-3.97-4.31c-7.18-2.02-14.46-3.91-21.83-5.03c-9.65-1.47-19.42-2.16-29.13-3.19
                c-0.11,0.4-0.22,0.79-0.32,1.19c2.1,0.79,4.19,1.57,5.89,2.21c-2.22,4.16-4.87,7.85-6.22,11.97c-1.85,5.63,0.32,9.64,6.1,10.22
                c5.08,0.51,10.59-0.31,15.44-1.94c1.91-0.64,3.36-5.01,3.56-7.79c0.24-3.31-1.1-6.73-1.91-10.94c3.64,0.4,7.38,0.52,10.99,1.26
                c5.37,1.09,10.69,2.49,15.94,4.06c1.17,0.35,2.78,1.95,2.74,2.92c-0.05,1.11-1.52,2.75-2.69,3.11c-2.66,0.82-5.56,0.82-8.27,1.51
                c-1.46,0.37-3.6,1.24-3.86,2.28c-0.29,1.17,0.83,3.26,1.96,4.11c2.47,1.85,5.35,3.18,9.63,5.61c-3.48,1.48-5.82,3.33-8.17,3.34
                c-33.96,0.15-67.91,0.05-101.87,0c-0.14,0-0.27-0.17-0.79-0.53c3.87-3.02,8.16-3.23,12.58-2.88c4.19,0.33,8.06,0.43,11.65-2.83
                c1.72-1.56,5.08-1.17,7.54-2.08c3.03-1.13,4.91-3.01,2.49-6.63c-1.63,1.81-2.84,3.16-4.6,5.12c0-4.13,0.66-7.21-0.12-9.88
                c-2.39-8.16-12.9-11.42-20.61-6.38c-2.72,1.78-4.72,2.05-7.7,0.71c-7.77-3.51-15.91-3.2-23.97-1.07
                c-7.45,1.96-9.76,7.49-5.99,14.15c1.46,2.58,3.41,4.89,4.67,8.4c-3.23-1.62-6.45-3.24-9.5-4.78c-0.12,3.72,1.73,4.93,14.15,8.42
                c-187.75,0-374.47,0-561.2,0c-0.1-0.31-0.19-0.63-0.29-0.94c1.54-0.81,3.03-1.74,4.61-2.43c26.67-11.62,52.48-25.31,81.04-32.33
                c13.84-3.4,24.7-12.04,33.82-22.85c4.83-5.72,9.53-11.56,14.53-17.13c3.06-3.41,5.97-7.26,11.17-7.76
                c0.73-0.07,1.63-0.71,2.04-1.35c6.87-10.84,18.89-18.2,20.02-33.01c0.89-11.68,6.98-21.2,16.84-28.31
                c4.91-3.54,8.48-9.03,15.37-9.28c0.61-0.02,1.35-0.41,1.77-0.87c8.46-9.15,18.55-16.78,21.01-30.53
                c2.91-16.28-2.89-29.22-12.41-41.41c-5.51-7.06-10.58-14.51-16.64-21.05c-3.48-3.76-8.24-6.7-12.96-8.86
                c-5.8-2.65-10.19-6.39-14.38-11.13c-4.34-4.91-9.9-8.76-14.99-12.99c-7.53-6.25-15.05-12.52-22.72-18.59
                c-10.03-7.95-20.21-15.71-30.31-23.58C223.37,1172.22,222.05,1170.98,219.04,1168.38z M539.09,1384.58
                c-0.35,0.37-0.7,0.73-1.05,1.1c-1.14,0.66-2.42,1.16-3.38,2.01c-8.8,7.8-18.9,13.06-30.48,15.08c-12.44,2.16-25,3.68-37.43,5.91
                c-6.86,1.23-13.56,3.35-20.33,5.06c8.37-0.75,16.41-2.8,24.57-4.21c12.45-2.14,25.11-3.2,37.42-5.92
                c11.65-2.58,22.35-7.68,29.58-18c0.35-0.37,0.69-0.74,1.04-1.1L539.09,1384.58z M220.37,1473.42c0.74-0.27,1.48-0.55,2.22-0.82
                c0.66-0.35,1.32-0.69,1.98-1.04c4.64-3.74,9.41-7.33,13.89-11.25c10.23-8.95,19.84-18.7,33.45-22.81c2.46-0.74,4.8-3.09,6.39-5.28
                c3.7-5.09,6.61-10.76,10.41-15.76c3.04-4,6.38-8.26,10.55-10.82c7.5-4.61,11.74-10.51,10.24-19.24
                c-1.82-10.63,1.99-18.06,11.12-23.27c2-1.14,3.87-2.62,5.53-4.24c4.12-4.02,8.08-8.2,12.11-12.31c0,0,0.07,0.07,0.07,0.07
                c-5.28,4.04-10.23,8.63-15.9,12.01c-11.7,6.97-18.41,16.06-15.39,30.31c0.03,0.16-0.01,0.33,0,0.5c0.23,5.3-1.86,9.56-6.44,12.12
                c-6.95,3.9-11.49,9.6-15.29,16.55c-5.2,9.5-11.22,18.54-23.15,21.05c-2.63,0.55-5.29,2.29-7.32,4.15
                c-10.18,9.3-20.15,18.82-30.2,28.26c-0.66,0.34-1.31,0.69-1.98,1.03c-0.74,0.28-1.49,0.57-2.23,0.85L220.37,1473.42z
                M444.11,1385.74c-0.64,0.25-1.27,0.51-1.91,0.76c0,0-0.04-0.09-0.04-0.08c0.63-0.25,1.25-0.51,1.88-0.76
                c0.39-0.4,0.77-0.81,1.16-1.21c1.01-0.62,2.02-1.25,3.02-1.87c8.69-3.3,17.38-6.6,26.08-9.89c20.1-8.45,37.48-19.96,46.49-40.9
                c-0.79,0.33-1.31,0.95-1.76,1.61c-9.15,13.26-19.53,25.14-34.46,32.31c-3.68,1.76-6.81,4.67-10.2,7.04
                c-9.04,2.39-18.46,3.77-26.08,9.9c-1.01,0.63-2.01,1.25-3.02,1.88C444.88,1384.92,444.5,1385.33,444.11,1385.74z M320.51,1467.65
                c0.64-0.38,1.28-0.75,1.92-1.13c0.66-0.33,1.32-0.65,1.98-0.98c1.99-1.34,3.99-2.68,5.98-4.02c2.57-2.02,5.14-4.03,7.71-6.05
                c2.41-1.66,4.82-3.32,7.23-4.98c2.56-1.37,5.11-2.74,7.68-4.11c1.15-0.24,2.3-0.48,3.45-0.71c0.02,0.02,0.05,0.03,0.06,0.04
                c-1.15,0.24-2.29,0.48-3.44,0.72c-2.56,1.38-5.12,2.76-7.68,4.13c-2.41,1.66-4.82,3.32-7.23,4.98c-2.57,2.02-5.14,4.04-7.71,6.05
                c-2,1.34-3.99,2.67-5.99,4.01c-0.66,0.33-1.31,0.66-1.97,0.98c-0.63,0.36-1.26,0.73-1.91,1.08l-0.36-0.03
                C320.23,1467.64,320.51,1467.65,320.51,1467.65z M213.45,1477.65c-0.74,0.27-1.48,0.53-2.23,0.8c-0.64,0.35-1.29,0.7-1.93,1.05
                c-6.92,2.67-13.84,5.35-20.76,7.99c-2.9,1.11-5.83,2.15-8.77,3.21c-0.25,0-0.5,0-0.75,0c0-0.01,0-0.02,0-0.03
                c0.23,0,0.45,0,0.68,0c0.65-0.11,1.3-0.18,1.94-0.33c9.75-2.24,19.67-4.03,27.59-10.91c0.64-0.35,1.28-0.7,1.93-1.04
                c0.75-0.28,1.5-0.55,2.25-0.83C213.4,1477.57,213.45,1477.65,213.45,1477.65z M422.24,1436.66c-1.74,0.6-3.48,1.21-5.22,1.81
                c-15.12,1.67-30.23,3.34-45.35,5.02c9.61-0.37,19.26-0.53,28.83-1.48c5.54-0.55,10.96-2.36,16.44-3.6
                c1.74-0.61,3.49-1.22,5.23-1.84L422.24,1436.66z M517.09,1249.69l0.07-0.07c1.54,6.78,3.08,13.56,4.49,19.74
                C524.84,1264.19,523.21,1255.99,517.09,1249.69z M451.13,1360.44c4.06-2.83,8.01-5.82,12.2-8.45
                c12.7-7.98,16.82-20.45,18.64-34.19c-0.8,1.04-1.27,2.27-1.6,3.54c-3.56,13.88-9.98,25.59-23.33,32.58
                c-2.43,1.27-3.91,4.35-5.83,6.6c-2.24,1.96-4.33,4.14-6.76,5.83c-2.43,1.69-5.15,2.99-7.82,4.29c-2.47,1.2-5.04,2.18-7.57,3.26
                C440.23,1372.18,447.16,1367.66,451.13,1360.44z M314.39,1213.72c5.07,2.78,10.15,5.55,15.22,8.35
                c5.52,3.05,11.03,6.13,16.54,9.19c0.3-0.35,0.61-0.7,0.91-1.05c-0.91-2.14-1.83-4.28-2.9-6.79c2.21,0.44,3.88,0.78,6.5,1.31
                c-0.66-9.85-7.54-15.54-12.15-22.49c27.15,5.8,45.66,21.98,57.01,46.41c10.13,21.8,11.19,62.36,2.55,72.51
                c3.49-29.15,3.03-36.63-3.44-41.84c-0.96,5.8-1.83,11.04-2.72,16.4c-3.58-2.49-4.14-2.53-5.62,0.96
                c-1.55,3.66-2.49,7.57-3.81,11.33c-0.65,1.85-1.32,3.76-2.36,5.41c-3.8,6.04-7.78,11.96-11.64,17.96
                c-1.4,2.17-2.68,4.41-4.01,6.62c-0.33-0.13-0.66-0.26-0.99-0.38c1.09-6.24,2.17-12.48,3.26-18.71c-0.69-0.13-1.38-0.27-2.07-0.4
                c-1.32,5.84-2.87,11.65-3.85,17.55c-0.39,2.36,0.45,4.93,0.73,7.4c1.94-1.6,4.74-2.81,5.67-4.86
                c5.76-12.82,17.14-22.63,19.86-37.06c0.07-0.39,0.6-0.69,1.35-1.5c0.62,3.94,1.16,7.43,1.71,10.92c0.75,0.01,1.5,0.01,2.25,0.02
                c1.24-7.67,2.48-15.34,3.72-23.02c3.88,15.91-0.67,31.19-3.19,46.84c1.87-1.03,3.03-2.28,3.93-3.69
                c5.94-9.39,8.29-19.82,9.26-30.77c2.77-31.29-5.38-58.66-28.23-81.01c-10.69-10.46-23.71-16.4-37.92-20.07
                c-2.03-0.52-4.3-0.07-7.72-0.07c4.69,8.01,12.52,12.29,14.53,21.04c-3.28-0.89-5.79-1.57-9.32-2.53c1.27,2.94,2.01,4.65,3.16,7.29
                c-12.53-5.31-21.52-16.37-35.94-15.65c17.13,12.76,32.18,26.96,42.83,45.78c10.86,19.21,13.76,39.65,13.03,61.23
                c1.12-1.35,1.64-2.58,1.79-3.85c2.01-17.04,0.27-33.43-7.15-49.22c-9.41-20.03-22.34-36.97-40.71-49.53
                C314.47,1213.72,314.39,1213.72,314.39,1213.72z M446.41,1275.92c11.66-4.11,9.44-14.46,11.16-23.34c1.79,1.66,2.94,2.73,4.75,4.4
                c1.11-4.38,3.5-2.63,6.22-2.44c3.52,0.25,8.23-0.2,10.58-2.36c7.55-6.95,13.62-15.08,13.42-26.28c-0.03-1.76,1.27-3.54,1.95-5.31
                c1.92,0.8,4.09,1.27,5.73,2.46c4.54,3.32,7.9,7.73,14.68,8.15c4.29,0.27,7.19,0.12,10.11-2.8c5.05-5.07,10.38-9.54,18.38-7.44
                c0.57,0.15,1.42-0.02,1.91-0.37c4.36-3.12,8.61-1.5,12.78,0.33c11.14,4.91,20.13,12.24,25.91,23.19c0.87,1.65,2.29,3.01,3.46,4.5
                c0.41-0.29,0.82-0.59,1.23-0.88c-0.24-9.99-1.98-19.62-11.06-25.7c-10.22-6.84-20.72-13.3-31.44-19.32
                c-5.54-3.11-11.82-4.93-18.63-7.68c0.34,2.41,0.5,3.54,0.72,5.08c-6.73-1.93-13.14-3.76-20.29-5.8c0.42,2.96,0.66,4.69,1.05,7.42
                c-3.4-0.6-6.41-1.02-9.37-1.67c-4.37-0.97-8.76-3.15-13.06-2.97c-15.38,0.64-30.05-1.99-44.46-7.16
                c-6.08-2.18-12.48-3.5-18.79-4.97c-1.92-0.45-4.03-0.07-6.05-0.07c-0.21,0.47-0.43,0.94-0.64,1.4c4.6,3.33,9.3,6.55,13.78,10.04
                c4.56,3.55,8.92,7.38,13.37,11.08c-0.27,0.44-0.54,0.88-0.81,1.32c-2.39-0.5-4.78-0.99-7.17-1.51c-2.74-0.6-5.55-1.94-8.21-1.7
                c-9.31,0.82-18.16-0.52-26.95-3.6c-5.16-1.81-10.62-2.77-15.99-3.9c-1.77-0.37-3.69-0.06-5.54-0.06c-0.5,0.69-0.99,1.39-1.49,2.08
                c4.6,3.35,9.21,6.71,13.81,10.06c-0.76,0.46-1.52,0.92-2.27,1.38c-0.2,2.08-1.12,4.44-0.46,6.19c2.3,6.06,4.66,12.2,7.96,17.73
                c2.36,3.95,5.78,7.91,11.39,7.32c9.98-1.06,14.82,1.63,17.33,11.41c1.81,7.05,2.29,14.43,3.44,21.65c0.25,1.56,0.8,3.07,1.21,4.6
                c0.47-0.02,0.95-0.04,1.42-0.06c0.42-1.33,0.98-2.63,1.22-3.99c2.31-12.93,3.52-25.75-2.62-38.17c-0.82-1.66-0.02-4.13,0.03-6.22
                c2.8,0.39,6.76-0.22,8.14,1.39c2.29,2.67,3.58,6.66,4.14,10.28C443.99,1254.09,445.05,1264.69,446.41,1275.92z M325.47,1139.34
                c-0.41,0.18-0.82,0.36-1.23,0.54c0.36,1.65,0.49,3.4,1.13,4.93c7.37,17.67,19.7,30.05,38.71,34.31
                c14.1,3.16,28.42,5.32,42.65,7.88c0.32,0.06,0.74-0.46,1.46-0.93c-8.24-5.92-17.01-8.73-26.85-9.83
                c-23.25-2.61-42.09-12.81-53.8-34.02C326.98,1141.19,326.17,1140.3,325.47,1139.34z M356.53,1075.04
                c-2.19-0.72-2.93-1.16-3.68-1.19c-13.08-0.5-25.26,2.59-35.38,11c-3.3,2.74-5.25,7.4-7.09,11.51c-1.38,3.08-1.33,6.76-2.36,10.04
                c-1.72,5.47-0.63,10.47,1.75,15.8c4.25-9.61,9.03-18.5,17.71-24.35c8.11-5.47,16.46-10.58,25.01-16.04
                c-1.8-0.21-3.27-0.38-6.02-0.69C350.18,1078.88,352.78,1077.3,356.53,1075.04z M639.21,1488.54c0.03-0.54,0.05-1.09,0.08-1.63
                c-2.75-0.18-5.55-0.08-8.23-0.6c-5.53-1.06-12.08-0.99-16.2-4.07c-4.72-3.54-9.24-3.63-14.25-4.08
                c-3.82-0.34-7.62-0.93-11.96-1.48c1.69-2.49,2.74-4.02,3.82-5.6c-7-3.98-74.11-6.88-81.73-3.64c25.84,1.77,51.57,0.46,77.62,3.98
                c-1.49,2.08-2.4,3.35-3.7,5.17c2.12,1.05,3.76,2.34,5.57,2.65c4.25,0.73,8.63,0.74,12.87,1.51c2.97,0.54,5.95,1.55,8.69,2.84
                C620.55,1487.73,629.71,1489.08,639.21,1488.54z M428.56,1095.45c-0.1-0.72-0.2-1.44-0.31-2.16c-4.75,0.84-9.89,0.83-14.19,2.7
                c-11.83,5.15-16.23,12.65-5.97,23.12c0.12,0.12,0.22,0.25,0.34,0.36c2.22,2.1,2.09,4.43-0.42,5.78
                c-5.82,3.15-6.52,7.81-4.95,13.55c0.34,1.23,0.1,2.64-0.02,3.96c-1.22,12.61,4.6,21.3,16.68,24.76c0.75,0.21,1.61,0.03,2.43,0.03
                c-1.69-1.86-3.57-2.8-5.44-3.78c-9.12-4.77-12.1-10.58-10.82-20.76c0.22-1.78,0.3-3.71-0.14-5.42c-1.08-4.2-0.27-7.12,3.65-9.71
                c5.29-3.5,5.28-6.22,1.04-10.87c-7.14-7.82-6.4-12.53,2.94-17.52c2.16-1.16,4.63-1.81,7.03-2.43
                C423.08,1096.35,425.83,1095.96,428.56,1095.45z M273.01,1202.4c0.35-0.55,0.7-1.1,1.05-1.66c-5.2-3.19-10.35-6.47-15.62-9.55
                c-6.89-4.02-13.11-8.72-15.87-16.54c-0.68-1.92-0.68-5.76,0.33-6.31c6.35-3.48,10.21-10.34,17.79-12.31
                c3.29-0.85,7.29-3.55,8.63-6.48c4.26-9.31-3.1-21.51-13.8-23.4c2.8,2.47,5.46,4.46,7.68,6.86c7.19,7.76,4.55,18.02-5.2,21.49
                c-2.22,0.79-4.14,2.55-6.06,4.03c-2.21,1.72-4.12,3.87-6.43,5.41c-5.71,3.8-6.98,6.8-3.96,12.91c1.66,3.36,3.79,6.97,6.71,9.12
                C256.23,1191.83,264.72,1196.97,273.01,1202.4z M764.15,1450.59c0,3.75-0.72,6.8,0.25,9.16c0.65,1.56,3.64,2.36,5.73,3.03
                c1.7,0.54,3.62,0.34,5.44,0.53c15.02,1.51,30.03,3.05,45.05,4.56c1.31,0.13,2.62,0.16,3.93,0.24c0.05-0.54,0.1-1.08,0.15-1.62
                c-18.61-5.02-38.05-4.28-58.19-6.87c1.43-2.99,2.14-5.25,3.46-7.07c2.09-2.88,0.79-3.84-1.93-4.27c-5.9-0.93-11.8-1.85-17.72-2.67
                c-1.1-0.15-2.27,0.24-3.41,0.37c0,0.42-0.01,0.83-0.01,1.25C753.02,1448.43,759.14,1449.62,764.15,1450.59z M269.87,1159.42
                c-0.75,0.27-1.17,0.28-1.33,0.49c-0.49,0.66-0.98,1.36-1.28,2.12c-2.96,7.31-0.97,13.73,3.83,19.48c2.76,3.31,6.75,5.94,8.54,9.66
                c6.37,13.24,15.93,23.67,26.86,33.11c2.16,1.87,4.19,3.89,6.29,5.84c0.41-0.42,0.81-0.83,1.22-1.25
                c-3.55-3.54-7.28-6.92-10.6-10.66c-5.83-6.56-12.12-12.88-16.88-20.17c-3.8-5.83-6.89-11.98-12.04-16.92
                c-5.31-5.08-7.19-11.46-5-18.75C269.73,1161.5,269.72,1160.55,269.87,1159.42z M645.05,1231c0.33-0.39,0.67-0.79,1-1.18
                c-1.44-1.29-2.85-2.62-4.32-3.88c-6.64-5.69-14.65-8.24-22.92-10.48c-5.74-1.56-11.4-3.65-16.79-6.17
                c-4.12-1.92-7.1-5.16-7.85-10.25c-0.28-1.86-2.3-4.2-4.1-4.97c-9.25-3.92-18.93-5.42-29.27-4.09c8.31,1.63,16.29,3.08,24.22,4.79
                c3.69,0.8,6.1,2.45,7.29,6.94c0.94,3.54,4.52,7.01,7.8,9.15c4.09,2.67,8.93,4.74,13.73,5.69
                C625.44,1218.88,636.49,1222,645.05,1231z M660.19,1465.8c0.09-0.58,0.19-1.16,0.28-1.74c-2.66-0.6-5.31-1.29-8-1.78
                c-7.82-1.41-15.67-2.61-23.45-4.21c-1.46-0.3-2.64-2.03-3.93-3.12c-1.56-1.32-3.01-3.69-4.66-3.83
                c-8.58-0.75-17.21-0.97-25.82-1.32c-2.18-0.09-4.36-0.01-6.54-0.01c-0.01,0.51-0.01,1.02-0.02,1.53c2.22,0.17,4.43,0.38,6.65,0.49
                c7.46,0.35,14.93,0.51,22.37,1.1c1.64,0.13,3.26,1.54,4.71,2.62c1.88,1.4,3.35,3.9,5.39,4.42c6.39,1.66,12.94,2.68,19.46,3.82
                C651.14,1464.54,655.67,1465.13,660.19,1465.8z M321.44,1421.39c6.62-4.26,8.92-11.04,10.66-18.17c0.47-1.91,0.67-4.32,1.93-5.53
                c4.96-4.79,4.96-9.95,2.65-15.96c-2.45-6.38-1.27-11.85,4.63-16.31c3.35-2.54,5.8-6.28,8.65-9.48c-0.4-0.36-0.81-0.72-1.21-1.07
                c-2.46,2.42-4.6,5.31-7.43,7.18c-8.69,5.74-10.32,13.31-6.72,22.57c1.51,3.88,1.46,7.44-1.67,10.6c-0.67,0.68-1.26,1.68-1.4,2.6
                C330.23,1406.54,327.17,1414.46,321.44,1421.39z M816.69,1456.69c0.05-0.52,0.11-1.05,0.16-1.57c-1.57-0.27-3.14-0.54-4.71-0.83
                c-8.16-1.51-16.36-2.87-24.46-4.65c-1.85-0.41-3.39-2.27-5.05-3.5c-1.65-1.22-3.13-3.27-4.92-3.58
                c-5.06-0.88-10.24-1.07-15.38-1.49c-2.91-0.24-5.83-0.43-8.75-0.64c1.21,1.54,2.46,1.91,3.74,2.19
                c9.19,1.99,18.42,1.28,27.25,7.79C793.29,1456.83,805.62,1456.25,816.69,1456.69z M788.35,1496.56c0.03-0.67,0.07-1.34,0.1-2.01
                c-2.01-0.3-4.02-0.89-6.02-0.83c-5.47,0.17-10.92,0.72-16.39,0.92c-2.8,0.1-5.68,0.09-8.4-0.45c-4.54-0.91-5.32-3.15-3.86-7.6
                c0.42-1.29-0.28-3.98-1.26-4.48c-2.41-1.24-5.29-1.58-7.97-2.28c-0.12,0.43-0.24,0.85-0.36,1.28c2.38,1.17,4.76,2.34,6.1,3
                c-0.52,3.02-1.89,6.13-1.04,6.98c2.36,2.33,5.57,5.04,8.6,5.23C767.99,1496.95,778.18,1496.56,788.35,1496.56z M500.73,1433.1
                c-16.38,3.23-33.53,3.05-49.29,10.86C468.25,1440.26,484.49,1436.68,500.73,1433.1z M425.89,1136.06
                c-1.74-4.74-3.5-9.52-5.19-14.12C417.92,1127.56,420,1133.43,425.89,1136.06z M498.54,1183.45c0,0.59,0.01,1.18,0.01,1.77
                c4.97,0.43,9.94,0.87,14.91,1.3c0.04-0.7,0.08-1.4,0.12-2.09C508.57,1184.1,503.55,1183.78,498.54,1183.45z M415.71,1467.36
                c-0.11-0.34-0.22-0.67-0.33-1.01c-5.41,0.5-10.82,1.01-16.23,1.51c0.04,0.35,0.07,0.71,0.11,1.06
                C404.74,1468.4,410.22,1467.88,415.71,1467.36z M760.36,1484.89c1.34-2.7,2.69-5.41,4.59-9.24
                C760.73,1478.37,758.44,1480.64,760.36,1484.89z M767.31,1489.99c0.46-0.34,0.93-0.68,1.39-1.02c-0.92-1.34-1.83-2.68-2.75-4.02
                c-0.41,0.29-0.83,0.58-1.24,0.87C765.58,1487.2,766.45,1488.6,767.31,1489.99z M364.19,1444.1c-0.28-0.1-0.45-0.16-0.62-0.22
                c0.03,0.09,0.05,0.25,0.09,0.26C363.79,1444.17,363.92,1444.13,364.19,1444.1z"/>
            <path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M111.75,1152.56c19.68,4.19,39.27,8.94,59.08,12.35c10.74,1.84,21.88,1.25,32.82,2.07
                c2.8,0.21,5.95,0.96,8.2,2.52c11.76,8.13,23.33,16.53,34.85,25c2.93,2.15,5.4,4.92,8.14,7.34c4.36,3.85,8.3,8.47,13.26,11.29
                c12.21,6.96,21.46,17.17,31.26,26.8c0.8,0.78,1.59,2.81,1.19,3.32c-0.91,1.16-2.5,2.3-3.92,2.45c-6.74,0.73-13.52,1.28-20.29,1.58
                c-6.38,0.29-9.07,2.57-9.28,9.03c-0.09,2.8,0.33,5.64,0.77,8.42c0.94,5.89,0.26,7.02-5.73,7.98c-3.76,0.6-7.58,0.85-11.35,1.41
                c-4.71,0.69-6.38,3.94-4.94,7.96c1.53,4.3,3.7,8.54,6.41,12.19c2.85,3.83,6.75,6.87,10.08,10.36
                c10.64,11.13,17.68,23.88,19.06,39.63c0.45,5.13,2.07,10.62,4.71,15.02c6.18,10.31,5.65,20.63,1.72,31.17
                c-1.02,2.73-3.53,4.91-5.35,7.35c-0.5-0.18-1-0.35-1.5-0.53c-0.15-1.85-0.5-3.71-0.43-5.55c0.61-17.67-3.41-34.73-6.88-51.85
                c-0.34-1.67-2.13-3.14-3.47-4.49c-2.56-2.58-5.81-4.65-7.84-7.57c-8.85-12.68-17.64-25.42-25.85-38.51
                c-2.63-4.2-4.17-9.44-4.92-14.4c-2.6-17.02-10.58-31.42-20.77-44.78c-3.98-5.22-8.69-9.9-13.07-14.82c-0.41,0.3-0.82,0.6-1.23,0.9
                c1.11,1.67,2.21,3.33,3.32,5c6.89,10.37,14.2,20.49,20.54,31.19c4.58,7.73,6.75,16.45,8.07,25.52c0.8,5.49,4.16,10.73,6.96,15.75
                c2.61,4.7,6,8.97,8.55,13.86c-6.96-4.52-14.29-8.6-18.21-16.25c-2.88-5.62-5.2-11.53-7.71-17.33c-2.47-5.7-6-10.32-12.04-12.65
                c-19.95-7.69-35.82-20.83-49.47-36.99c-2.47-2.93-5.41-5.47-8.53-8.58c-0.32,1.36-0.55,1.79-0.43,1.93
                c15.81,18.66,32.48,36.3,56.14,45.18c6.03,2.26,9.97,6.43,12.31,12.3c0.68,1.7,1.54,3.34,2.07,5.09
                c4.51,14.89,15.01,24.67,27.37,33.17c3.98,2.73,7.02,6.88,10.31,10.54c4.08,4.54,8.04,9.2,11.91,13.92
                c1.19,1.46,2.52,3.21,2.78,4.97c2.74,18.75,5.31,37.52,7.84,56.29c0.46,3.41-5.31,11.02-8.96,12.85
                c-2.28,1.15-4.48,2.75-6.25,4.59c-9.42,9.85-18.02,20.61-28.25,29.51c-6.57,5.72-15.15,9.56-23.39,12.85
                c-14.96,5.97-30.34,10.89-45.55,16.24c-0.61,0.21-1.25,0.31-2.9,0.7c1.99-6.88,3.11-13.47,5.75-19.36
                c8.79-19.57,11.53-39.75,8.22-60.93c-1.44-9.2-2.12-18.52-3.07-27.79c-1.14-11.15-4.77-21.34-10.98-30.72
                c-3.48-5.25-7.39-10.59-9.23-16.47c-2.23-7.11-2.48-14.85-3.58-22.31c0.44-0.31,0.87-0.63,1.31-0.94
                c2.85,1.47,5.66,3.02,8.56,4.39c2.25,1.06,4.56,2.06,6.92,2.81c3.1,0.98,4.64-0.32,5.94-3.43c4.63-11.12,2.07-20.92-3.93-30.75
                c-13.05-21.38-27.47-41.69-44.76-59.88c-0.61-0.64-1.35-1.17-2.97-1c1.94,2.78,3.6,5.8,5.85,8.29
                c14.01,15.52,25.67,32.74,36.4,50.59c2.9,4.81,5.38,9.92,7.64,15.06c2.4,5.44,0.65,10.87-1.38,15.98
                c-0.4,1.01-3.41,1.96-4.64,1.48c-4.17-1.63-8.05-3.96-12.09-5.93c-4.18-2.04-5.7-1.18-5.43,3.24
                c0.83,13.59,2.97,26.72,11.43,38.12c7,9.44,10.47,20.35,11.59,32.03c1.24,12.92,2.57,25.84,3.87,38.75
                c1.27,12.63-0.26,24.84-4.66,36.81c-3.55,9.66-6.49,19.56-9.65,29.36c-1.31,4.05-3.82,6.58-7.85,8.33
                c-10.99,4.76-21.75,10.06-32.7,14.94c-2.19,0.98-4.78,1.5-7.19,1.51c-34.63,0.1-69.26,0.07-103.88,0.06
                c-6.15,0-6.38-0.23-6.38-6.59c-0.01-122.4-0.01-244.81,0-367.21c0-6.64,0.05-6.36,6.82-6.24c7.3,0.12,13.86,2.54,19.63,6.47
                c8.87,6.04,18.57,8.4,29.05,9.37c15.92,1.46,30.17,7.64,43.23,16.78c9.94,6.96,20.62,12.41,32.95,13.28
                c12.43,0.87,24.21,4.06,35.84,8.28c6.72,2.44,13.59,4.48,20.4,6.67c8.82,2.83,11.45,6.32,12.26,15.65
                c0.14,1.65,1.21,3.34,2.19,4.77c1.02,1.48,2.67,2.53,3.69,4.02c2.79,4.04,1.94,5.87-2.9,6.52c-3.32,0.44-6.66,0.79-11.17,1.32
                c4.38,2.64,15.94,2.86,17.72,0.59c1.14-1.45,1.67-4.06,1.25-5.86c-0.54-2.3-1.91-4.76-3.65-6.35c-2.85-2.6-3.59-5.44-3.81-9.19
                c-0.48-8.02-6.53-12.03-13.16-14c-20.02-5.94-39.22-14.85-60.58-16.17c-7.1-0.44-14.63-1.77-20.01-7.67L111.75,1152.56z
                M168.48,1245.08c-0.34,0.25-0.68,0.51-1.03,0.76c1.38,1.63,2.83,3.21,4.12,4.9c8.89,11.61,17.83,23.19,26.57,34.92
                c2.97,3.98,4.43,8.38,2.61,13.58c-0.72,2.05-0.33,4.64,0.05,6.9c0.95,5.57,3.04,11.07,3.14,16.62c0.16,9.52,3.48,17.45,9.15,24.77
                c7.22,9.32,11.62,19.82,11.99,31.71c0.38,11.98,0.44,23.96,0.64,35.95c0.03,1.68,0,3.37,0,5.05c1.39-1.13,1.73-2.24,1.72-3.35
                c-0.04-8.81-0.83-17.67-0.08-26.41c1.54-17.88-3.84-33.02-14.15-47.59c-9.23-13.03-8.11-29.27-9.55-44.51
                c-0.07-0.77,0.59-1.6,0.59-2.4c0.02-1.92,0.46-4.13-0.32-5.71C195.29,1272.71,182.57,1258.34,168.48,1245.08z"/>
            <path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M649.93,1319.71c-0.42,1.36-0.84,2.73-1.26,4.09c0.56,0.29,1.12,0.59,1.68,0.88
                c1.94-3.67,5.05-7.18,5.55-11.04c0.74-5.62,0.5-11.74-4.54-16.13c-0.67-0.59-1.53-1.75-1.36-2.39c1.41-5.28-2.49-8.38-4.7-12.18
                c-1.06-1.82-1.98-3.72-2.96-5.58l-0.09,0.05c1.61-0.75,3.22-1.51,5.24-2.45c1.49,1.04,3.43,2.41,5.37,3.77c-1.06,0-2.12,0-4.25,0
                c2.33,3.84,4.37,6.79,5.98,9.96c1.55,3.02,5.35,5.22,3.54,9.71c-0.26,0.63,2.07,2.62,3.5,3.49c0.7,0.43,2.08-0.26,3.15-0.45
                c-0.37-1.3-0.61-2.66-1.14-3.89c-0.91-2.12-1.86-4.24-3.03-6.22c-2.54-4.28-5.24-8.46-7.87-12.69c2.65-4.4,2.14-5.1-4.84-7.16
                c1-6.19,1.82-12.34,3.14-18.39c0.23-1.04,2.67-2.45,3.87-2.26c3.47,0.55,6.86,1.7,10.2,2.85c3.22,1.1,6.78,1.89,9.44,3.84
                c8.87,6.5,16.5,14.25,21.55,24.22c0.38,0.75,0.69,1.55,1.78,1.7c-0.1-12.33-9.67-18.46-17.62-26.66c3.13-0.72,5.25-1.29,7.41-1.71
                c8.88-1.69,17.75-3.48,26.67-4.96c12.64-2.1,25.2-1.26,37.32,2.8c7.12,2.38,14.25,4.79,20.45,9.67c3.65,2.87,9.01,3.46,13.21,5.79
                c3.19,1.77,7.02,4.12,8.35,7.18c4.65,10.72,12.43,19,19.38,28.05c7.41,9.64,13.44,20.33,20.2,30.47
                c3.12,4.68,6.75,9.02,9.81,13.73c7.24,11.13,15.77,21.74,20.96,33.78c4.52,10.49,5.17,22.65,7.57,34.07
                c0.3,1.45,0.86,2.84,1.28,4.27c2.47,8.46,4.96,16.91,7.39,25.38c1.09,3.77-0.44,6.1-4.32,5.83c-6.4-0.45-12.79-1.28-19.13-2.28
                c-1.09-0.17-2.46-1.8-2.79-3c-1.57-5.6-3.86-10.76-9.28-13.45c-5.42-2.69-7.29-7.84-10.17-12.47c-1.6-2.58-1.79-7.9-7.35-5.69
                c-3.09-4.87-10.48-3.05-13.3-9.28c-1.75-3.86-6.39-6.37-9.6-9.63c-2.49-2.53-4.54-5.54-7.22-7.83c-1.41-1.2-3.75-1.29-5.68-1.88
                c-0.28,0.38-0.57,0.75-0.85,1.13c-0.34-1.4-0.68-2.81-1.16-4.8c-3.18,3.29-4.7,1.28-5.98-1.36c-0.8-1.64-1.43-3.38-2.33-4.97
                c-2.25-3.93-4.61-7.8-6.65-11.23c-8.54,6.41-15.99,12-23.64,17.75c-0.46-0.93-0.88-1.79-1.74-3.54
                c-2.74,6.82-7.58,8.68-13.73,9.04c-3.02,0.18-4.34-1.04-4.44-3.26c-0.47-10.87-6.51-18.93-12.96-26.85c1.05,0,2.09,0,2.87,0
                c-1.99-5.17-3.29-10.29-5.85-14.68c-2.7-4.63-2.94-9.02-2.67-14.19c0.38-7.24-3.12-13.51-7.98-18.97
                c0.6,5.72,4.07,10.32,2.8,16.97c-1.29,6.74,1.87,14.12,6.15,20.24c0.84,1.2,1.31,2.65,2,4.1c-1.17,0.54-2.16,0.99-3.66,1.67
                c2.51,1.92,4.48,3.42,6.45,4.93c0.02,1.41-0.33,2.96,0.12,4.22c3.08,8.69,6.45,17.27,9.48,25.97c3.04,8.73,5.49,17.69,8.79,26.32
                c2.68,7.02-0.14,14.48-5.85,16.41c-1.47,5.64-2.81,10.45-3.95,15.3c-1.03,4.35-2.1,8.73-2.65,13.15
                c-0.36,2.87-1.28,4.24-4.27,4.08c-7.48-0.39-14.96-0.68-22.44-1c-2.67-0.12-3.51-1.38-3.64-4.15
                c-0.62-13.29,0.02-26.91-2.81-39.74c-2.55-11.53-6.46-23.56-17.75-30.97c-4.23-2.77-7.31-7.26-11-10.89
                c-0.85-0.84-2.06-1.3-3.08-1.98c-0.8-0.53-2.1-1.04-2.23-1.73c-0.78-4.24-1.23-8.55-1.9-12.81c-0.27-1.72-0.82-3.39-1.24-5.08
                c-0.66-0.02-1.32-0.05-1.97-0.07c-0.59,2.53-1.97,5.18-1.61,7.56c0.71,4.66-0.73,8.33-2.98,12.16c-1.07,1.82-2.02,4.74-1.3,6.42
                c4.07,9.51-1.34,18.45-1.23,27.69c0.03,2.8-1.39,5.36-5.08,6.49c-2.39,0.74-3.97,3.91-6.08,5.8c-4.55,4.07-9.21,8.01-13.9,12.07
                c-1.25-1.73-1.8-2.49-2.61-3.61c-0.91,1.34-1.62,2.37-2.55,3.74c-0.67-0.91-1.27-1.72-2.17-2.95c-0.77,1.2-1.41,2.21-1.67,2.6
                c-1.64-0.69-3.5-2.21-4.67-1.79c-1.43,0.51-2.28,2.65-3.22,3.88c-1.73-4.86-5.01-5.22-11.37-0.83c-4.17-3.27-3.29-8.05-4.38-12.43
                c-1.15-4.62-0.79-7.57,3.42-10.61c-6.16,0.07-9.02,5.99-8.08,11.15c1.06,5.84,0.63,12.02,0.09,17.99c-0.1,1.11-4.04,2.96-5.8,2.58
                c-3.01-0.65-1.84-3.21-0.67-5.2c0.69-1.17,1.27-2.4,2.32-4.39c-6.89,3.24-7.42,7.23-2.8,13.8c-3.93,3.05-7.86,1.52-6.86-2.72
                c0.47-2,1.52-3.86,2.84-7.1c-2.43,1.27-3.6,1.89-4.96,2.6c0-0.52-0.1-0.98,0.02-1.37c0.71-2.38,2.03-4.72,2.11-7.11
                c0.52-16.57,8.78-29.11,19.75-40.7c5.07-5.36,9.08-11.83,12.93-18.19c1.51-2.5,1.26-6.06,1.82-9.13c-0.5-0.13-1-0.26-1.49-0.39
                c-2.28,4.97-4.56,9.93-6.84,14.9c-0.73-0.11-1.46-0.22-2.19-0.33c-1-3.87-2.3-7.69-2.92-11.61c-0.7-4.42-0.93-8.93-1.03-13.4
                c-0.02-1.04,1.4-3.07,2-3.02c5.09,0.46,10.31,0.74,15.16,2.19c5.36,1.61,10.36,4.44,15.54,6.68c2.96,1.29,5.97,2.47,8.96,3.7
                c-1.15,1.72-2.83,3.33-2.52,3.93c2.84,5.63,6.02,11.09,9.11,16.6c0.56-0.23,1.11-0.46,1.67-0.68c-0.58-1.99-1.16-3.97-2.03-6.97
                c2.2,0.82,3.7,1.39,6.33,2.37c-0.59-3.44-1.08-6.28-1.83-10.59c-1.61,2.25-2.26,3.16-2.91,4.07c-6.72-4.26-7.26-8.29-1.32-13.06
                c4.46-3.58,9.91-6.06,13.87-10.07c3.99-4.03,7.36-9.02,9.76-14.17c2.47-5.29,3.98-11.21,4.87-17.01
                c0.51-3.33-1.05-6.98-2.49-10.38c-0.88,18.49-4.1,35.19-23.94,43.13c-0.23-1.55-0.48-3.22-0.85-5.7
                c-3.34,3.73-6.07,7.05-9.1,10.09c-2.93,2.94-5.68,2.29-9.21,0.06c-4.31-2.72-9.46-4.09-14.17-6.23c-2.52-1.15-4.87-2.69-7.5-4.17
                c-0.63,1.03-1.27,2.09-2.6,4.26c-6.71-5.87-13.11-11.45-19.46-17.08c-0.72-0.64-1.51-1.56-1.63-2.44
                c-1.07-7.98-2.86-15.99-2.69-23.96c0.13-6.27,2.32-12.62,4.34-18.68c2.73-8.2,9.1-12.84,17.22-15.24c0.62-0.18,1.21-0.45,1.8-0.72
                c0.1-0.05,0.12-0.29,0.72-1.91c-2.4,0.47-4.22,0.54-5.79,1.19c-5.62,2.34-6.48,1.8-6.37-5.04c1.39,0,3.18-0.56,4.13,0.1
                c4.08,2.79,8.04,0.89,11.98,0.05c4.95-1.05,9.82-2.44,14.76-3.53c0.74-0.16,1.66,0.51,2.5,0.79c0.06,0.45,0.12,0.9,0.18,1.35
                c-4.71,1.32-9.42,2.63-14.12,3.95c0.01,0.4,0.01,0.79,0.02,1.19c1.58,0,3.37,0.5,4.7-0.08c9.54-4.15,17.41,0.65,25.41,4.57
                c0.78,0.38,0.95,2.08,1.33,3.18c0.55,1.58,0.72,4.36,1.6,4.61c8.49,2.32,9.49,9.16,9.76,16.08c0.5,13.04-3.37,24.76-11.77,34.87
                l0.07,0.1c0.82-4.62-0.55-9.49,1.94-14c0.65-1.18-0.47-3.34-0.77-5.05c-0.45,0.04-0.89,0.08-1.34,0.12
                c-0.52,1.22-1.04,2.44-1.88,4.39c-1.95-5.14-3.64-9.6-5.39-14.23c1.03-0.73,2.13-1.12,2.53-1.88c0.78-1.48,1.82-3.34,1.49-4.73
                c-0.27-1.11-2.54-2.6-3.7-2.43c-1.46,0.21-3.45,1.72-3.86,3.08c-0.56,1.86-0.19,4.2,0.4,6.15c1.5,4.93,3.34,9.76,5.19,15.06
                c-7.56-3.84-12.21-3.5-14.43,1.13c-0.95,1.97-1.62,4.68-1.01,6.62c0.53,1.68,3.02,2.75,4.66,4.11
                c-6.78,0.84-10.73-1.54-12.05-7.95c-0.62-3-0.14-6.23-0.15-9.36c-0.01-2.22,0-4.44,0-6.66c-0.86,0.04-1.72,0.08-2.57,0.12
                c-0.08,7.3-2,14.84,2.24,21.75c7,11.39,16.11,11.89,24.4,1.72c0.35-0.43,0.82-0.75,1.23-1.12c-0.33,2.53-1.3,4.58-2.42,6.56
                c-1.45,2.55-0.26,3.27,2.1,3.3c-2.78,1.47-5.84,2.56-8.25,4.48c-3.35,2.68-6.56,1.88-9.74,0.42c-5.35-2.46-10.55-5.24-15.82-7.89
                c-0.3,0.28-0.6,0.57-0.9,0.85c0.79,1.22,1.3,2.9,2.42,3.57c3.19,1.9,6.65,3.34,9.97,5.04c5.5,2.83,11.01,6.03,16.58,0.28
                c2.04-2.1,3.8-4.47,5.68-6.72c0.81-1.57,1.63-3.13,2.44-4.7l-0.08-0.03c-0.16,1.17-0.32,2.35-0.54,4
                c10.34-6.07,11.23-16.86,14.73-26.13c0.58,0.18,1.16,0.35,1.75,0.53c-0.49,2.18-0.9,4.38-1.49,6.53c-0.74,2.7-0.04,3.54,2.6,2.26
                L649.93,1319.71z M779,1327.78c-1.28-0.85-2.56-1.7-4.84-3.22c0.45,3.95,0.75,6.57,1.05,9.18c0.65,0.09,1.31,0.17,1.96,0.26
                c0.57-2.1,1.15-4.21,1.72-6.32c2.6-2.89,1.73-4.79-3.32-6.6C776.8,1323.49,777.9,1325.63,779,1327.78z M796.74,1379.61
                c1.93,2.93,3.85,5.87,5.78,8.8c0.6-0.41,1.21-0.82,1.81-1.22c-2.53-4.23-5.05-8.45-7.58-12.68c-0.45,0.23-0.89,0.45-1.34,0.68
                c0.47,1.51,0.94,3.02,1.41,4.53c-2.25-2.6-4.5-5.2-6.75-7.8c-0.39,0.28-0.79,0.57-1.18,0.85c1.69,2.73,3.15,5.63,5.13,8.12
                C795.69,1382.96,796.83,1382.18,796.74,1379.61z M719.83,1420.56c0.58-0.55,1.57-1.03,1.66-1.65c0.43-2.75-4.97-9.85-8.67-11.07
                c1.84,3.49,3.49,6.61,5.13,9.74c-0.95-0.33-1.91-0.67-2.4-0.84c1.28,6.55,2.52,12.88,3.75,19.21
                C721.36,1430.68,723.36,1425.59,719.83,1420.56z M592.77,1336.2c0.67-0.49,1.34-0.98,2.01-1.47c-2.74-3.95-4.73-8.9-8.38-11.63
                c-6.66-4.99-10.81-15.92-7.48-23.6c0.74-1.7,1.41-3.44,2.56-6.25c-6.91,4.94-9.45,12.96-6.49,19.02
                c1.91,3.92,4.17,7.76,6.81,11.23C585.17,1327.95,589.09,1331.99,592.77,1336.2z M631.83,1408.22c3.71-0.95,5.58-1.24,7.28-1.92
                c1.08-0.43,2.71-1.43,2.74-2.23c0.19-5.27,0.01-10.56-0.04-15.84c-0.69-0.06-1.38-0.11-2.08-0.17
                C637.21,1394.48,634.7,1400.89,631.83,1408.22z M764.74,1280.23c-1.62,0.51-3.66,1.15-5.69,1.79c0.97,1.59,1.95,3.18,2.92,4.76
                c0.43,0.7,0.85,1.41,1.31,2.1c3.1,4.72,6.21,9.44,9.31,14.15c0.42-0.22,0.84-0.44,1.26-0.66
                C770.99,1295.42,768.13,1288.45,764.74,1280.23z M689.86,1378.01c10.83-6.83,14.13-22.01,6.74-28.09c0.58,2.8,1.08,5.17,1.57,7.53
                c-0.42-0.16-0.83-0.32-1.25-0.48c-0.55,0.95-1.75,2.09-1.54,2.83c1.48,5.21-0.36,9.44-3.33,13.5
                C691.32,1374.29,690.98,1375.56,689.86,1378.01z M608.74,1410.71c-3.2,5.55-6.23,10.82-9.27,16.09c0.44,0.37,0.87,0.74,1.31,1.1
                c2.8-2.58,5.61-5.16,8.54-7.85c0.29,0.3,1.17,1.23,2.39,2.5C613.2,1417.68,613.83,1413.64,608.74,1410.71z M859.34,1440.24
                c1.82-10.2-4.08-14.66-9.51-20.71c1.72,6.46,2.92,11.82,4.67,16.99C854.96,1437.87,857.15,1438.63,859.34,1440.24z
                M597.75,1293.55c-0.24,1.95-1.12,4.13-0.51,5.75c0.47,1.24,2.8,2.4,4.33,2.43c1.22,0.03,3.7-2.09,3.54-2.55
                c-1.05-3.06-2.26-6.28-4.31-8.68c-0.69-0.81-3.98,0.62-6.09,1.02c0.09,0.55,0.17,1.11,0.26,1.66
                C596.16,1293.34,597.33,1293.49,597.75,1293.55z M805.82,1342.88c-1.4,2.54-2.75,4.56-3.66,6.76c-0.93,2.25-0.97,4.98-2.24,6.95
                c-1.28,2-3.53,3.34-1.4,6.09C804.81,1357.94,807.83,1349.88,805.82,1342.88z M584.83,1281.87c5.77-2.27,10.69-4.21,15.93-6.27
                C595.48,1271.31,593.2,1272.25,584.83,1281.87z M627.08,1289.17c2.62,1.85,4.73,3.34,6.84,4.83c-1.45-6.09-6.33-8.82-10.93-7.03
                c-0.98,0.38-1.86,1.7-2.21,2.78c-0.21,0.63,0.65,2.29,1.15,2.34c0.95,0.1,2.05-0.51,2.96-1.04
                C625.7,1290.57,626.36,1289.81,627.08,1289.17z M611.13,1356.73c-0.25,7.04,3.81,12.96,9.63,14.12
                C617.56,1366.15,614.51,1361.68,611.13,1356.73z M829.4,1403.44c0.7,7.24,4.69,12.6,9.31,12.98
                C835.73,1412.27,832.9,1408.32,829.4,1403.44z M606.94,1294.87c1.37-2.19,2.94-3.79,2.63-4.35c-0.93-1.67-2.6-2.92-3.98-4.33
                c-0.75,1.09-2.3,2.38-2.09,3.23C603.92,1291.05,605.32,1292.44,606.94,1294.87z M806.47,1391.04c0.74-0.23,1.47-0.46,2.21-0.69
                c-1.02-2.87-2.03-5.74-3.05-8.61c-0.62,0.21-1.24,0.43-1.86,0.64C804.67,1385.27,805.57,1388.15,806.47,1391.04z M792.96,1363.51
                c-0.39,0.69-0.77,1.37-1.16,2.06c1.26,0.79,2.44,1.84,3.81,2.25c0.48,0.14,1.42-1.28,2.15-1.99
                C796.16,1365.05,794.56,1364.28,792.96,1363.51z M846.03,1422.39c0.57-0.42,1.15-0.84,1.72-1.26c-1.77-2.38-3.55-4.75-5.32-7.13
                c-0.41,0.29-0.83,0.57-1.24,0.86C842.8,1417.37,844.42,1419.88,846.03,1422.39z M825.89,1406.98c0.63-0.4,1.26-0.79,1.88-1.19
                c-1.39-2.15-2.78-4.3-4.17-6.45c-0.51,0.33-1.02,0.66-1.53,0.99C823.35,1402.55,824.62,1404.77,825.89,1406.98z M622.05,1366.82
                c-0.38,0.37-0.75,0.74-1.13,1.11c1.79,1.83,3.58,3.66,5.36,5.5c0.46-0.45,0.91-0.9,1.37-1.35
                C625.79,1370.32,623.92,1368.57,622.05,1366.82z M623.03,1409.3c0.5,0.38,1,0.76,1.5,1.14c1.06-1.67,2.11-3.35,3.17-5.02
                c-0.42-0.29-0.83-0.57-1.25-0.86C625.31,1406.14,624.17,1407.72,623.03,1409.3z M758.6,1279.08c0.17-0.59,0.33-1.18,0.5-1.76
                c-1.69-0.45-3.38-0.9-5.08-1.35c-0.12,0.42-0.23,0.83-0.35,1.25C755.31,1277.84,756.96,1278.46,758.6,1279.08z M647.75,1355.88
                c0.51,0.09,1.02,0.19,1.53,0.28c0.13-1.04,0.26-2.07,0.39-3.11c-0.38-0.07-0.76-0.14-1.13-0.22
                C648.27,1353.85,648.01,1354.87,647.75,1355.88z M774.87,1337.54c-0.6,0-1.2,0-1.8,0.01c0.06,1.24,0.12,2.48,0.19,3.72
                c0.48-0.01,0.96-0.02,1.44-0.03C774.75,1340.01,774.81,1338.78,774.87,1337.54z M632.39,1274.01c-0.66-0.47-1.09-1-1.35-0.93
                c-0.56,0.16-1.03,0.63-1.53,0.97c0.4,0.37,0.81,1.03,1.21,1.03C631.2,1275.07,631.68,1274.5,632.39,1274.01z M627.17,1271.11
                c-0.58-0.23-1.03-0.58-1.35-0.49c-0.38,0.11-0.66,0.59-0.99,0.91c0.45,0.27,0.88,0.71,1.35,0.75
                C626.43,1272.3,626.74,1271.64,627.17,1271.11z M598.34,1266.11c-0.31,0.46-0.8,0.9-0.72,1.16c0.11,0.37,0.64,0.62,1,0.92
                c0.33-0.41,0.66-0.83,0.99-1.24C599.23,1266.7,598.85,1266.45,598.34,1266.11z M599.02,1419.73c0.34-0.66,0.54-1.03,0.73-1.41
                c-0.35-0.16-0.69-0.4-1.05-0.43c-0.15-0.01-0.5,0.49-0.45,0.69C598.32,1418.92,598.64,1419.2,599.02,1419.73z M719.49,1444.5
                c-0.21-0.16-0.4-0.35-0.63-0.47c-0.05-0.03-0.34,0.21-0.33,0.26c0.09,0.26,0.24,0.49,0.37,0.73
                C719.1,1444.85,719.3,1444.67,719.49,1444.5z M621.5,1269.98c-0.26-0.09-0.51-0.21-0.77-0.24c-0.09-0.01-0.31,0.28-0.3,0.3
                c0.17,0.2,0.36,0.38,0.55,0.56C621.16,1270.4,621.33,1270.19,621.5,1269.98z"/>
            <path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M891.17,975.36c-0.9,0.22-1.63,0.18-2.07,0.53c-14.33,11.39-28.85,22.45-37.55,39.42
                c-2.47,4.82-7.45,8.8-12.11,11.94c-5.12,3.45-9.99,6.97-12.31,12.66c-4.84,11.86-14.03,20.51-21.35,30.55
                c-6.76,9.27-15.12,15.21-26.43,17.82c-6.54,1.51-12.86,4.56-18.85,7.71c-4.16,2.19-8.09,5.3-11.27,8.78
                c-6.17,6.75-11.77,14.02-17.52,21.14c-2.19,2.71-4.37,3.7-8.14,2.75c-8.15-2.06-16.51-3.31-24.77-4.95
                c-1.1-0.22-2.14-0.75-3.78-1.34c1.63-3.15,3.29-5.94,4.55-8.91c1.42-3.35,0.23-5.45-3.54-5.91c-8.08-0.98-16.14-2.33-24.25-2.9
                c-4.09-0.29-8.27,0.78-13.38,1.34c4.11-4.83,7.75-9.71,12.02-13.97c7.54-7.53,10.42-16.06,6.98-26.35
                c-0.54-1.62-1.54-3.08-3.45-4.21c0.38,1.87,0.79,3.73,1.14,5.6c1.66,8.85-0.94,16.06-7.1,22.79c-5.21,5.69-9.17,12.54-13.58,18.94
                c-0.68,0.99-0.82,2.36-1.56,4.6c2.09-0.72,3.46-0.83,4.32-1.55c5.92-4.92,12.53-5.33,19.74-3.72c6.26,1.4,12.64,2.27,19.66,3.49
                c-2.54,5.04-4.04,9.98-10.09,11.74c-3.09,0.9-6.24,2.59-8.62,4.75c-5.91,5.32-11.28,11.24-17.1,16.67
                c-3.85,3.6-8.09,6.79-12.19,10.11c-4.97,4.03-9.98,8-15.56,12.47c-1.32-1.72-3.11-4.22-5.08-6.57c-4.4-5.25-8.54-10.37-16.84-9.54
                c-5.97,0.6-7.86-3.28-4.89-8.58c2.44-4.35,4.73-8.9,7.87-12.71c10.65-12.93,18.73-27.42,27.08-41.86
                c11.81-20.43,28.32-36.35,49.77-46.79c1.58-0.77,3.41-1.83,4.23-3.26c6.48-11.31,16.8-11.32,27.55-9.29
                c5.14,0.97,10.06,3.13,16.13,5.1c-5.41,5.49-10.36,10.52-15.32,15.55c0.41,0.5,0.81,0.99,1.22,1.49c1.28-0.69,2.71-1.18,3.8-2.09
                c4.47-3.73,9.29-7.18,13.15-11.49c6.01-6.72,12.74-4.44,20.41-3.11c-0.8,2.04-1.16,3.65-2,4.96c-6.12,9.52-12.07,19.17-18.7,28.33
                c-1.89,2.61-5.63,4.62-8.88,5.36c-6.99,1.61-13.39,3.57-17.97,9.67c-1.41,1.88-4.1,2.75-6.06,4.26
                c-5.89,4.56-11.77,9.13-17.52,13.87c-1.25,1.03-1.95,2.73-2.91,4.12c0.39,0.37,0.77,0.75,1.16,1.12c1.13-0.91,2.24-1.84,3.39-2.72
                c6.31-4.81,12.64-9.6,18.96-14.39c1.85-1.4,4.17-2.43,5.49-4.22c4.15-5.59,9.97-7.62,16.42-8.25c4.71-0.46,8.19-2.54,10.67-6.43
                c6.37-9.96,12.71-19.92,19.04-29.91c0.62-0.98,1.13-2.05,1.52-3.14c1.56-4.29,0.64-5.85-3.94-6.17
                c-7.41-0.52-14.85-0.67-22.25-1.33c-3.14-0.28-6.21-1.44-9.26-2.87c5.01-3.87,10.46-5.65,16.35-5.34c7,0.37,12.4-1.78,15.73-8.21
                c0.23-0.44,0.55-0.85,0.91-1.19c9.2-8.66,17.88-17.78,25.02-28.28c0.75-1.1,2.08-1.82,4.13-2.05c-1.17,2.83-2.08,5.8-3.55,8.47
                c-5.85,10.66-6.17,22.72-8.75,34.19c-2.58,11.48-8.29,20.96-16.98,28.65c-9.67,8.56-11.99,19.9-12.74,31.93
                c-0.02,0.36,0.51,0.75,1.35,1.9c0.64-1.61,1.15-2.64,1.45-3.72c1.44-5.12,2.22-10.54,4.42-15.31c2.08-4.53,5.04-9.01,8.64-12.42
                c12.56-11.9,17.99-26.76,19.9-43.46c0.58-5.08,1.25-10.44,3.34-15c9.45-20.56,14.43-42.5,20.71-64.06
                c4.71-16.17,13.24-30.03,26.4-40.85c2.02-1.66,3.91-3.75,5.17-6.03c4.74-8.55,11.11-15.75,18.46-21.99
                c3.3-2.8,7.33-5.28,11.44-6.49c9.36-2.75,18.97-4.62,28.91-6.96c0.19,4.37-1.8,7.2-4.88,8.95c-8,4.56-12.1,11.95-14.27,20.29
                c-4.06,15.61-7.21,31.45-11.23,47.07c-3.98,15.47-7.86,31.03-13.2,46.04c-2.34,6.59-8.11,12-12.53,17.79
                c-0.93,1.22-2.66,2.09-4.19,2.53c-7.58,2.16-15.27,3.97-22.81,6.26c-1.9,0.58-3.64,2.26-5.05,3.81
                c-7.89,8.71-13.74,18.68-18.87,29.26c-3.59,7.41-8.64,14.11-13.06,21.11c-5.13,8.14-10.55,16.12-15.3,24.47
                c-1.76,3.09-1.98,7.05-2.9,10.62c0.47,0.18,0.95,0.36,1.42,0.55c0.54-0.65,1.36-1.21,1.59-1.96c3.38-10.81,8.84-20.52,16.2-29.03
                c7.4-8.54,12.53-18.3,17.67-28.25c4.31-8.36,9.99-16.05,15.48-23.73c1.34-1.88,4.24-2.8,6.59-3.77c2.75-1.14,5.66-1.88,8.52-2.73
                c3.29-0.98,6.68-1.68,9.89-2.87c2.59-0.96,5.46-1.98,7.33-3.85c3.5-3.5,6.75-7.4,9.43-11.56c11.17-17.3,24.9-33.07,32.13-52.75
                c2.56-6.96,4.53-14.13,6.77-21.2c-0.61-0.25-1.21-0.49-1.82-0.74c-1.16,2.78-2.61,5.48-3.44,8.36
                c-4.4,15.24-12.14,28.78-20.91,41.82c-1.16,1.72-2.53,3.3-4.31,5.59c-0.12-1.5-0.28-2-0.18-2.44c6.35-25.67,12.63-51.36,19.14-77
                c2.22-8.73,6.15-16.36,14.45-21.3c2.58-1.54,4.16-5.01,5.81-7.82c2.5-4.25,4.66-8.7,7.7-14.44c3.52,3.52,6.43,6.17,9.02,9.1
                c4.05,4.58,9.38,6.22,15.09,6.74c3.96,0.36,7.99,0.2,11.97-0.04c7.15-0.43,13.95-1.92,21.54-0.44c6.67,1.3,14.23-2.12,21.42-3.29
                c2.75-0.45,5.58-0.43,8.38-0.62c0.21,0.52,0.42,1.04,0.63,1.57c-1.18,1.48-2.32,2.99-3.55,4.43c-5.21,6.13-5.02,7.19,2.3,10.99
                c-1.42,2.18-2.82,4.35-4.35,6.7c-6.54-1.77-12.56-3.68-14.65-11.52c-1.34-5.03-6.1-6.54-10.2-2.92
                c-4.12,3.63-7.93,8.21-10.29,13.14c-7.68,16.07-14.52,32.53-21.93,48.73c-3.9,8.53-10.51,13.9-20.11,14.77
                c-5.07,0.46-10.17,0.52-16.67,0.83c5.82,10.2,15.68,12.74,24.25,17.82c-7.7,11.97-15.61,23.58-19.72,38.37
                c7.69-1.28,11.19-6.86,16.91-9.53c-1.19,2.66-2.38,5.32-3.58,7.97c-3.43,7.59-7.03,15.1-10.21,22.79
                c-1.56,3.77-3.99,5.71-7.88,6.74c-9.84,2.58-19.72,4.36-30.6,3.45C875.66,1008.03,887.61,993.78,891.17,975.36z"/>
            <path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M473.94,1097.52c0,8.92,0.56,17.89-0.16,26.75c-0.69,8.49-2.8,16.86-4.15,25.31
                c-1.17,7.32-2.15,14.67-3.24,22.01c-0.16,1.1-0.43,2.19-0.87,4.33c-5.14-2.03-9.98-3.63-14.53-5.84
                c-4.32-2.11-8.32-4.88-12.48-7.33c-3.52-2.07-4.87-5.16-3.49-8.9c1.89-5.13,4.66-9.97,6.28-15.17c1.86-5.95,3.27-12.11,4.13-18.28
                c0.66-4.74,0.19-9.64,0.17-14.47c-0.05-12.33,2.28-24.02,7.95-35.16c3.14-6.18,4.6-13.19,7.42-19.56
                c1.41-3.19,3.75-6.29,6.42-8.53c10.01-8.37,22.53-10.08,34.78-12.59c5.66-1.16,11.16-3.17,16.65-5.02
                c11.47-3.87,22.73-8.5,34.38-11.7c14.55-3.98,27.84-9.47,37.95-21.32c5.17-6.06,11.16-11.42,16.7-17.17
                c5.42-5.63,11.84-7.08,19.17-4.77c11.9,3.74,23.89,7.25,35.58,11.56c2.72,1,5.46,4.47,6.38,7.38c2.25,7.11,3.16,14.63,5.12,21.85
                c2.13,7.85,4.64,15.61,7.37,23.27c1.07,2.99,1.31,4.72-2.01,6.3c-7.68,3.66-13.8,9.19-18.33,16.46c-0.88,1.41-1.79,2.9-3.03,3.94
                c-12.32,10.3-19.72,24.06-27.2,37.87c-3.71,6.85-8.28,13.25-12.65,19.72c-5.74,8.51-11.67,16.89-17.81,25.74
                c-0.89-0.55-2.24-1.41-3.61-2.23c-4.16-2.48-8.45-3.38-13.38-2.32c-2.21,0.48-4.92-0.42-7.15-1.32c-1.94-0.79-3.5-2.49-5.27-3.74
                c-5.35-3.77-8.88-3.67-14.02,0.36c-6.15,4.82-16.19,1.9-18.35-5.72c-1.48-5.22-1.82-10.75-3-16.07c-0.7-3.18-1.58-6.49-3.19-9.27
                c-2.2-3.79-5.61-4.39-9.93-2.76c-6.24,2.35-12.71,4.16-19.17,5.82c-1.2,0.31-2.98-0.92-4.25-1.8c-1.9-1.31-3.43-3.23-5.41-4.36
                c-5.18-2.95-9.17-1.12-10.23,4.69c-0.97,5.32-1.6,10.7-2.38,16.06c0.59,0.13,1.18,0.26,1.77,0.39c1.08-4.34,2.07-8.71,3.29-13.02
                c0.56-1.96,1.35-5.19,2.43-5.36c1.9-0.3,4.83,0.71,6.06,2.21c4.34,5.33,8.65,5.09,14.45,2.25c5.02-2.46,10.78-3.55,16.33-4.69
                c1.46-0.3,4.17,1.01,4.84,2.33c1.38,2.71,1.86,5.9,2.53,8.92c1.22,5.49,1.69,11.23,3.6,16.45c3.09,8.47,15.14,11.54,22.09,5.96
                c3.71-2.98,7.18-3.08,10.86-0.29c2.21,1.68,4.35,3.44,7.41,5.86c-3.15,1.62-5.69,2.48-7.65,4.04c-3.15,2.52-6.74,5.03-8.68,8.39
                c-4.54,7.89-8.19,16.29-12.25,24.45c-2.5,5.03-7.01,5.79-12.11,3.36c-8.65-4.12-17.42-8.19-26.51-11.11
                c-10.76-3.45-21.44-0.22-31.82,2.93c-7.5,2.27-8.29,2.18-8.02-5.57c0.2-5.73,1.12-11.57,2.68-17.09
                c4.59-16.28,6.65-32.71,4.58-49.56c-0.21-1.72-0.77-3.4-1.17-5.1C475.2,1097.4,474.57,1097.46,473.94,1097.52z M456.76,1091.62
                c0.17,0.49,0.33,0.98,0.5,1.47c3.15-0.76,6.89-2.98,9.34-1.99c6.2,2.49,10.06,1.43,14.26-3.79c1.78-2.22,3.86-3.71,3.82-6.79
                c-0.08-7.97-0.48-15.95-0.03-23.89c0.18-3.16,1.41-7.33,3.68-9.08c5-3.87,10.92-6.58,16.57-9.57c1.57-0.84,3.46-1.3,5.25-1.46
                c3.96-0.35,7.96-0.23,11.91-0.66c1.6-0.17,3.21-1.08,4.63-1.96c2.25-1.4,4.13-3.67,6.54-4.48c9.85-3.33,20.71-2.67,29.67-10.05
                c5.46-4.49,13.24,0.21,14.09,7.64c1.05,9.24,1.27,18.58,1.98,27.86c0.18,2.42,0.86,4.79,1.3,7.19c0.62-0.06,1.23-0.11,1.85-0.17
                c0.19-1.67,0.56-3.35,0.54-5.01c-0.14-11.65-0.36-23.3-0.57-34.94c-0.14-7.7-0.42-15.44,9.59-17.62c0.45-0.1,0.78-0.67,1.23-0.82
                c3.21-1.06,6.44-2.06,9.66-3.09c2.15-0.68,4.9-0.76,6.34-2.18c3.53-3.46,6.22-7.77,9.71-11.29c2.04-2.05,4.79-3.7,7.52-4.66
                c3.69-1.29,7.67-1.74,11.53-2.55c-0.11-0.59-0.22-1.18-0.33-1.77c-3.54,0.3-7.08,0.77-10.63,0.85c-5.36,0.11-8.63,2.73-11.68,7.11
                c-4.01,5.77-7.65,12.75-16.85,11.72c-1.8-0.2-3.87,1.66-5.75,2.68c-1.75,0.95-3.3,2.47-5.14,2.98c-5.14,1.42-7.07,5.12-7.88,9.86
                c-0.37,2.17-0.75,4.34-1.33,7.65c-9.02-8.92-16.47-5.57-24.08,0.63c-2.11,1.72-5.51,2.14-8.4,2.55
                c-7.81,1.1-15.91,1.08-21.64,7.95c-0.65,0.78-2.43,0.74-3.7,0.87c-8.72,0.84-17.79,0.56-24.17,8.3c-0.58,0.7-1.71,1-2.65,1.32
                c-10.78,3.79-12.22,6.01-11.88,17.45c0.2,6.64,0.36,13.31,0.06,19.94c-0.26,5.53-7.34,9.99-12.42,8.58
                C464.42,1087.08,460.42,1089,456.76,1091.62z M649.57,982.32c-0.57,0.01-1.14,0.02-1.71,0.03c-0.4,3.58-0.98,7.15-1.15,10.73
                c-0.39,8.31-0.68,16.64-0.79,24.96c-0.05,3.32-1.07,5.42-4.57,5.94c-12.41,1.84-19.06,9.85-22.69,21.17
                c-0.6,1.86-1.29,4.02-2.64,5.25c-7.38,6.72-10.29,15.45-11.32,24.9c-2.05,18.86-3.78,37.75-5.59,56.63
                c-0.06,0.61,0.56,1.28,1.47,3.24c1.03-3.22,2-5.13,2.23-7.13c1.3-11.24,2.39-22.5,3.61-33.74c0.93-8.59,1.3-17.33,3.19-25.71
                c1.15-5.12,4.62-9.74,7.24-14.49c1.56-2.82,4.16-5.22,5.13-8.19c3.34-10.24,9.4-17.12,20.52-18.55c5.02-0.65,7.11-3.44,7.12-8.45
                c0.02-10.82,0.32-21.64,0.45-32.46C650.08,985.07,649.75,983.7,649.57,982.32z"/>
            <path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M1143.37,807.95c-6.43-0.56-5.16,5.71-6.93,8.73c-1.72,2.95-3.11,3.69-6.03,3.45
                c-16.09-1.28-28.15-9.3-37.31-22.18c-2.7-3.8-5.16-7.76-7.8-11.61c-0.85-1.24-1.91-2.34-3.74-4.54c0,2.46-0.27,3.46,0.04,4.24
                c5.81,14.85,13.79,28.2,27.61,36.98c9.95,6.32,20.26,12.07,30.31,18.25c1.18,0.73,1.83,2.49,2.46,3.89
                c1.92,4.24,3.26,8.82,5.69,12.73c2.32,3.74,5.72,6.8,9.7,11.37c-3.47,0-5.87,0.65-7.66-0.12c-6.67-2.86-13.19-6.09-19.66-9.39
                c-2.27-1.16-4.14-4.1-6.28,0.23c-0.23,0.47-3.31,0.26-4.38-0.55c-5.67-4.29-11.28-8.7-16.59-13.41c-3.48-3.09-6.54-6.04-11.6-7.01
                c-6.29-1.2-9.88-6.47-12.52-12.04c-2.44-5.12-5.01-9.82-9.77-13.5c-3.24-2.51-5.09-6.84-7.51-10.39
                c-0.86-1.26-1.55-2.63-2.31-3.96c-0.4,0.12-0.8,0.24-1.19,0.37c0,1.17-0.25,2.4,0.04,3.49c1.64,6.2-0.45,9.82-6.25,12.35
                c-6.14,2.68-12.46,5.33-16.02,11.71c-0.53,0.96-1.58,1.71-2.53,2.34c-7.46,4.88-14.91,9.77-22.45,14.52
                c-4.6,2.9-9.44,4.78-15.12,3.3c-1.63-0.42-3.59,0.08-5.33,0.47c-5.19,1.17-10.29,2.97-15.54,3.58c-3.59,0.42-7.36-0.84-11.07-1.19
                c-2.08-0.19-4.19-0.17-6.27-0.02c-7.27,0.56-14.58,1.94-21.8,1.57c-4.43-0.22-11.41,0.88-11.26-7.55c0.01-0.71-2.27-1.66-3.6-2.17
                c-5.24-2.02-9.61-5.05-13.23-10.9c5.11-0.63,9.44-1.44,13.8-1.66c14.76-0.76,28.45-4.87,41.1-12.53c1.51-0.92,3.39-1.35,5.16-1.72
                c8.47-1.74,16.26-4.83,22.57-11c0.7-0.69,1.57-1.27,2.45-1.71c14.41-7.1,22.62-19.6,29.5-33.41c1.02-2.04,3.44-3.71,5.59-4.8
                c10.44-5.29,21.07-10.16,24.62-22.94c1.12-4.03,3.12-7.81,3.71-12.35c-0.76,0.67-1.77,1.2-2.24,2.03
                c-1.23,2.17-2.57,4.36-3.27,6.72c-4.16,14.05-14.74,20.86-27.87,24.25c-11.22,2.89-22.64,5.05-34.04,7.12
                c-3.07,0.56-6.43-0.41-9.65-0.68c-0.1-0.45-0.21-0.91-0.31-1.36c0.91-0.76,1.79-1.57,2.74-2.28c6.24-4.71,12.73-9.12,18.66-14.19
                c4.98-4.25,9.02-9.44,8.92-16.6c-0.04-2.75,5.35-5.4-0.64-8.03c3.16-2.3,5.63-4.26,8.25-5.98c8.02-5.28,11.71-13.42,14.56-22.05
                c0.5-1.52-0.18-3.42-0.32-5.15c-1.68,0.34-3.5,0.38-5.01,1.09c-4.05,1.91-7.86,5.62-11.95,5.91c-13.05,0.91-24.51,8.09-37.48,8.72
                c-6.11,0.3-12.24,0.05-18.76-0.56c20.23-5.86,34.21-20.74,49.97-33.04c-0.18-0.5-0.37-1-0.55-1.51
                c-4.03,1.17-8.05,2.34-12.21,3.55c8.96-7.04,18.17-13.32,26.21-20.86c9.55-8.96,18.06-19.04,27.08-28.58
                c4.26-4.51,3.92-10.02,3.29-15.47c-0.09-0.78-2.51-1.9-3.76-1.79c-2.29,0.2-4.5,1.2-7.47,1.1c12.14-21.12,27.07-40.47,33.3-64.58
                c1.03,2.74,1.92,5.54,3.1,8.22c6.21,14.15,14.2,27.12,25.2,38.11c2.97,2.97,3.38,8.05,0.95,12.12c-2.33,3.91-5.94,4.73-9.96,3.37
                c-3.13-1.06-6.06-2.71-9.12-4.02c-3.38-1.45-5.03-0.32-4.04,3.23c0.97,3.47,2.52,6.88,4.37,9.99c8.2,13.78,19.9,24.48,31.52,35.24
                c1.31,1.22,2.61,2.45,4.52,4.24c-6.3-0.2-5.65,2.95-4.09,6.41c1.18,2.62,2.53,5.17,4.24,8.64c-3.03-0.49-4.86-0.79-8.17-1.33
                c1.58,2.74,2.27,4.72,3.6,6.09c9.49,9.78,18.4,20.15,30.32,27.32c4.66,2.8,8.48,7,12.68,10.56c-0.44,0.63-0.89,1.25-1.33,1.88
                c-2.3-1.3-4.63-2.54-6.9-3.9c-5.71-3.44-5.96-3.38-8.91,3.03c-1.43-0.44-2.95-0.91-4.47-1.35c-1.11-0.33-2.58-1.23-3.29-0.83
                c-7.63,4.35-13.09-2-19.46-3.75c-2.53-0.7-5.05-1.43-7.58-2.15c-0.32,0.55-0.63,1.1-0.95,1.65c1.32,1.49,2.44,3.25,4,4.42
                c5.86,4.39,11.86,8.6,17.83,12.84c6.64,4.71,12.07,10.26,15.02,18.17c1.09,2.93,3.77,5.36,6,7.76c1.64,1.77,3.73,3.13,6.61,5.48
                c-1.92,0-2.53,0.14-3.06-0.02c-19.49-5.68-38.48-12.22-53.1-27.48c-3.66-3.82-8.28-6.71-12.46-10.03
                c-0.46,0.49-0.91,0.98-1.37,1.47c1.14,1.39,2.15,2.94,3.45,4.15c14.44,13.47,29.73,25.64,48.56,32.64
                c1.62,0.6,2.62,2.86,4.45,4.96c-7.41,3.05-13.15,2.01-18.23-2.24c-10.47-8.74-22.32-15.34-33.81-22.55
                c-9.09-5.7-15.97-13.5-19.85-23.71c-0.71-1.88-1.66-3.67-2.5-5.51c-0.44,0.11-0.87,0.21-1.31,0.32c0,1.33-0.3,2.74,0.05,3.97
                c3.81,13.36,11.88,23.2,24.51,29.17c0.9,0.43,1.87,0.76,2.68,1.3c9.6,6.45,19.3,12.76,28.72,19.46c5.24,3.72,10.68,5.4,16.89,3.77
                c3.07-0.8,5.28-0.14,7.62,2.07c3.12,2.94,6.74,5.35,9.65,7.61c-1.93,2.1-3.6,4.58-5.88,6.18c-1.69,1.19-4.15,1.42-6.32,1.73
                c-2.56,0.37-5.18,0.45-7.78,0.54c-2.94,0.1-6.02,0.65-8.81,0.01c-20.96-4.74-38.75-14.77-52.11-31.97
                c-1.02-1.31-2.16-2.53-3.28-3.75c-0.2-0.22-0.59-0.28-1.55-0.71c-0.72,1.29-1.39,2.68-2.22,3.97c-2.63,4.11-5.49,8.09-7.9,12.33
                c-1.35,2.38-1.91,5.2-2.96,7.76c-1.23,3-2.55,5.97-3.95,8.9c-0.64,1.34-1.98,2.46-2.28,3.84c-2.55,12.02-13.33,18.71-18.61,28.96
                c-0.73,1.41-2.16,2.47-3.57,4.02c-4.2-5.27-7.24-2.16-9.99,1.26c-5.93,7.37-13.36,11.84-22.9,12.68c-1.6,0.14-3.1,1.18-4.7,1.57
                c-5.21,1.25-10.45,2.39-16.09,4.43c2.78,0.33,5.83,1.58,8.28,0.84c13.9-4.2,29.65-4.82,38.72-18.94c0.25-0.39,0.78-0.6,1.16-0.88
                c5.72,3.66,6.36,3.65,10.91-2.13c2.46-3.12,3.88-7.23,6.73-9.86c6.64-6.15,9.81-14.14,13.96-21.81c2.72-5.02,5.51-9.63,6.25-15.32
                c0.1-0.79,0.48-1.63,0.96-2.27c3.27-4.44,6.6-8.83,10.23-13.67c1.28,1.44,3.13,3.26,4.68,5.31c8.61,11.43,20.48,18.21,33.2,24.01
                c5.5,2.51,10.8,5.69,15.71,9.24c6.06,4.38,11.61,9.46,17.38,14.24L1143.37,807.95z M1032.56,692.17c0.27,0.73,0.55,1.46,0.82,2.2
                c2.25-0.69,4.46-1.71,6.76-2.01c4.18-0.55,9.37-2.33,12.42-0.58c4.54,2.59,6.58,0.62,8.8-1.96c6.45-7.5,11.75-15.69,13.47-25.71
                c0.44-2.55,0.89-5.09,1.34-7.63c-0.55-0.13-1.1-0.25-1.65-0.38c-1.04,2.89-1.78,5.93-3.16,8.65c-3.58,7.05-7.32,14.04-11.29,20.88
                c-0.72,1.24-2.78,2.29-4.29,2.4c-4.67,0.33-9.45-0.32-14.06,0.35C1038.56,688.82,1035.61,690.85,1032.56,692.17z M1074.55,679.01
                c-2.3,9.11,10.4,23.74,21.83,22.35C1086.62,696.31,1078.35,689.86,1074.55,679.01z"/>
            <path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M1153.48,1019.75c-3.9-1.37-7.82-2.69-11.69-4.15c-2.62-0.99-4.7-1.54-5.79,2.14
                c-0.28,0.97-2.73,2.17-3.81,1.88c-7.86-2.11-15.73-4.31-22.09-9.9c-0.98-0.86-2.99-1.64-3.99-1.22
                c-4.84,2.07-8.76-0.03-11.88-2.96c-5.89-5.53-11.29-11.61-16.78-17.56c-0.72-0.78-0.85-2.13-1.25-3.21
                c-0.83,0.51-1.66,1.03-2.49,1.54c0.52,2.56,0.7,5.25,1.63,7.65c3.86,9.9,11.03,17.32,18.74,24.28c1.59,1.43,3.38,2.64,5.08,3.95
                c-0.34,0.54-0.68,1.07-1.02,1.61c-1.37-0.48-2.92-0.71-4.09-1.49c-5.76-3.86-11.41-7.91-17.15-11.81
                c-1.31-0.89-2.85-2.09-4.27-2.07c-6.92,0.08-11.09-3.66-14.18-9.3c-2.42-4.42-3.65-9.42-8.36-12.65
                c-1.74-1.19-2.15-5.13-2.21-7.83c-0.11-5.51,0.43-11.03,0.18-16.59c-6.19,8.84-1.7,19.45-4.36,29.51
                c-1.81-1.24-2.94-2.01-5.13-3.51c-0.45,3.42-1.04,6.06-1.11,8.7c-0.19,7.23-2.18,13.78-6.81,19.41c-0.84,1.02-1.9,2.64-2.85,2.64
                c-6.03-0.06-9.6,3.53-12.73,8.8c-0.99-2.13-1.64-3.55-2.7-5.83c-4.99,5.96-9.63,11.51-14.27,17.05c-0.41-0.23-0.82-0.46-1.23-0.69
                c0.1-0.66,0.26-1.31,0.29-1.97c0.05-1.25,0.03-2.5,0.04-3.75c-1.25,0.02-2.6-0.26-3.72,0.13c-2.81,0.97-5.28,1.71-7.62-1.19
                c-0.65-0.81-2.81-0.97-4.05-0.63c-6.87,1.91-13.67,4.07-20.91,6.27c-1.07-3.88-3.66-3.18-6.65-1.61
                c-3.98,2.09-7.98,4.15-12.11,5.9c-9.36,3.96-18.85,5.24-28.55,0.98c-1.45-0.64-3.26-0.69-4.89-0.63
                c-12.64,0.45-23.13-5.37-33.54-11.42c-0.35-0.2-0.5-0.75-1.03-1.58c4.61-0.33,8.92-0.27,13.1-0.99
                c27.23-4.65,52.24-15.26,74.23-31.59c10.2-7.57,17.67-18.8,26.5-28.26c1.48-1.59,3.47-2.78,5.39-3.85
                c6.51-3.66,13.04-7.29,19.68-10.71c2.1-1.08,4.53-1.58,6.85-2.17c1.92-0.49,4.01-0.45,5.85-1.12
                c15.83-5.74,24.98-18.41,33.46-31.75c-0.32-0.38-0.64-0.76-0.97-1.14c-1.27,1.18-2.6,2.3-3.78,3.56
                c-6.46,6.89-12.54,14.19-19.45,20.6c-4.61,4.27-10.3,7.3-17.3,5.98c-1.35-0.25-3.1,0.87-4.48,1.69
                c-4.72,2.81-9.29,5.85-14.02,8.64c-7.58,4.46-15.3,4.66-23.27,0.81c-1.46-0.71-3.51-0.35-5.27-0.22
                c-6.94,0.51-13.92,1.8-20.81,1.43c-3.45-0.19-6.68-3.47-10.13-5.07c-1.99-0.92-4.23-1.75-6.37-1.8
                c-10.33-0.25-18.64-4.94-26.31-12.09c2.2-0.13,4.4-0.34,6.61-0.38c6.73-0.14,12.67-2.02,17.73-6.79c1.97-1.86,4.7-3.25,7.33-3.99
                c17.67-4.97,30.78-16.86,43.78-28.82c4.04-3.72,7.18-8.28,6.87-14.4c-0.06-1.28,1.01-2.78,1.9-3.91
                c3.98-5.05,8.12-9.98,12.12-15.02c0.65-0.82,0.87-1.97,1.66-3.84c-3.51,0-6.3,0-9.09,0c-0.2-0.41-0.39-0.82-0.59-1.23
                c3.88-4.35,5.73-10.03,11.88-12.96c6.78-3.23,12.86-7.92,19.25-11.97c2.1-1.33,4.2-2.63,6.32-3.93c1.41-0.87,2.99-1.52,4.22-2.58
                c3.43-2.96,6.46-6.43,10.12-9.04c3.56-2.53,7.72-4.23,12.6-6.82c-0.58,7.81-1.14,14.84-1.62,21.87c-0.35,5.15-0.68,10.3-0.9,15.45
                c-0.33,7.6-4.06,10.94-11.47,9.72c-4.66-0.76-7.66,1.68-9.25,5.23c-2.97,6.62-9.33,11.19-14.59,12.48
                c-7.21,1.77-9.55,7-14.07,10.78c-4.73,3.95-10.48,6.67-16.24,10.21c1.49,0,3.15,0.38,4.54-0.06c7.34-2.34,13.25-6.65,17.81-12.98
                c1.13-1.57,3.41-2.35,5.25-3.32c0.69-0.36,1.88,0.05,2.41-0.4c5.02-4.33,12.27-5.82,15.67-12.18c2.46-4.59,5.2-7.55,11.4-6.08
                c5.36,1.28,11.82-6.06,12.18-12.6c0.68-12.47,1.36-24.94,2.06-37.4c0.03-0.61,0.28-1.21,0.49-2.04c4.1,1.86,3.97,5.11,3.65,8.49
                c-0.94,10.11-1.9,20.22-2.73,30.34c-0.15,1.8,0.27,3.65,1.47,5.53c3.31-12.23,3.78-24.75,5.53-37.64c0.95,1.74,1.74,3.58,2.87,5.2
                c3.4,4.85,6.93,9.6,10.39,14.4c1.15,1.6,2.37,3.18,3.31,4.91c0.96,1.74,1.51,3.7,2.42,5.48c9.02,17.61,20.8,32.98,36.85,44.79
                c5.76,4.24,11.7,8.22,17.53,12.35c0.74,0.52,1.37,1.21,2.56,2.28c-6.81,2-11.49-2.79-18-3.85c0.99,3.13,1.77,5.61,2.77,8.76
                c-1.69,0.16-3.16,0.67-4.42,0.36c-6.42-1.58-12.79-3.39-19.19-5.07c-3.22-0.85-6.5-1.49-9.7-2.42c-1.38-0.4-2.98-0.99-3.84-2.02
                c-4.86-5.86-9.67-11.76-14.17-17.89c-1.82-2.47-3.42-5.44-0.35-8.54c0.55-0.56,0.28-2.8-0.4-3.56c-2.26-2.53-4.88-4.74-7.36-7.07
                c-0.43,0.36-0.86,0.73-1.29,1.09c1.82,2.41,3.64,4.83,5.48,7.27c-6.89,3.81-7.84,6.21-4.33,13.44c1.79,3.68,4.37,7.08,7.08,10.18
                c5.91,6.76,12.18,13.22,18.2,19.89c1.06,1.17,1.92,2.7,2.38,4.22c2.98,9.79,6.72,19.19,14.58,26.18c2.36,2.1,3.01,4.41,1.14,6.33
                c-1.19,1.22-4.13,1.65-5.93,1.16c-2.81-0.78-5.36-2.59-7.96-4.06c-2.88-1.63-5.44-3.52-9.23-1.6c-1.25,0.63-4.17-0.78-5.62-2.06
                c-5.36-4.73-10.68-9.56-15.51-14.82c-2.48-2.7-3.9-6.37-6.05-10.03c-2.07,3.69-0.58,7.13,1.39,9.13
                c7.19,7.31,14.91,14.11,22.46,21.07c1.07,0.99,2.67,1.54,3.43,2.69c16.23,24.38,35.65,45.59,60.6,61.34L1153.48,1019.75z
                M931.22,939.95c4.06,5.56,12.74,6.76,18.27,3.97c1.72-0.86,4.04-1.17,5.96-0.9c11.85,1.68,23.27,2.09,34.47-3.98
                c4.28-2.32,9.33-6.8,15.4-2.67c0.89,0.6,3.02-0.04,4.28-0.68c10.08-5.11,18.81-11.91,25.54-21.1c0.41-0.55,0.45-1.37,0.66-2.07
                c-0.54-0.46-1.08-0.91-1.63-1.37c-1.11,1.63-1.92,3.64-3.38,4.84c-6.41,5.26-12.92,10.41-19.58,15.34
                c-2.51,1.86-5.4,3.54-8.29-0.12c-0.51-0.65-3.16-0.31-4.36,0.38c-13.85,7.99-28.31,12.12-44.34,7.93c-1.31-0.34-3.3-0.27-4.24,0.5
                c-4.39,3.63-8.97,2.69-13.71,1.2C934.63,940.71,932.91,940.38,931.22,939.95z M956.62,1022.98c0.08,0.53,0.15,1.06,0.23,1.6
                c1.59,0,3.32,0.41,4.75-0.07c5.76-1.94,11.39-4.24,17.12-6.25c7.46-2.61,14.95-5.11,22.47-7.53c0.88-0.28,2.58-0.08,2.96,0.51
                c3.27,4.97,6.12,2.16,8.92-0.36c10.46-9.46,17.63-21.12,22.66-34.19c0.22-0.58,0.03-1.33,0.03-2c-0.4-0.19-0.79-0.38-1.19-0.57
                c-6.7,13.45-14,26.45-26.41,36.19c-3.15-5.5-6.77-3.39-10.47-0.93c-1.08,0.72-2.31,1.27-3.55,1.71
                c-6.58,2.32-13.18,4.57-19.76,6.85c-3.59,1.24-7.13,2.62-10.76,3.7C961.35,1022.32,958.95,1022.55,956.62,1022.98z
                M1053.89,888.63c1.21,1.27,1.92,2.2,2.81,2.92c4.4,3.53,4.24,3.81,0.34,7.63c-2.15,2.11-3.53,5-5.28,7.53
                c-1.03,1.48-2.05,2.97-3.14,4.41c-5.2,6.87-8.91,14.95-16.35,19.99c-0.05,0.03,0.07,0.3,0.37,1.46
                c5.66-2.81,10.53-5.83,13.39-11.68c1.49-3.05,3.61-5.79,5.49-8.64c0.91-1.38,1.87-2.73,2.86-4.06c2-2.69,3.68-5.75,6.12-7.95
                c4.14-3.73,4.03-7.93-0.67-11.24c-1.08-0.76-1.68-2.27-2.38-3.51c-0.65-1.16-1.14-2.42-1.7-3.64c0.53,0.87,1.05,1.74,1.58,2.61
                c-6.35,5.95-12.7,11.89-17.89,16.75C1044.52,899.77,1049.03,894.32,1053.89,888.63z"/>
            <path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M1071.77,276.76c0.8,6.15,1.65,11.86,2.26,17.6c1.15,10.72,1.99,21.47,3.34,32.16
                c0.45,3.5,1.41,7.31,3.33,10.19c7.61,11.43,11.36,23.94,12.18,37.53c0.2,3.33-1.05,4.61-4.05,4.88c-2.98,0.26-6,0.37-8.94,0.86
                c-2.26,0.38-4.89,0.68-6.59,2c-10.73,8.38-21.53,16.44-22.09,32.28c-0.34,9.73-3.26,19.34-4.3,29.08
                c-0.7,6.55-0.01,13.23-0.16,19.85c-0.15,6.42-0.52,12.85-0.92,19.26c-0.07,1.11-0.55,2.34-1.22,3.24
                c-10.79,14.49-11.34,31.54-12.26,48.52c-0.63,11.62-1.15,23.26-2.34,34.82c-0.27,2.57-2.68,5.58-4.96,7.16
                c-4.57,3.16-6.67,6.67-5.56,12.14c0.43,2.12,1.13,4.18,1.6,6.29c1.9,8.47,0.69,10.28-8.16,11.13c-4.79,0.46-9.66,0.67-14.47,0.41
                c-12.51-0.68-24.82,0.29-37.27,1.95c-12.3,1.64-13.56-2.47-13.59-11.66c-0.03-9.66,0.13-19.32,0.41-28.97
                c0.2-6.86-1.29-13.18-4.8-19.09c-0.82-1.37-1.64-3.11-1.53-4.61c0.74-9.62,0.25-19.69,3.1-28.68c2.49-7.87,8.86-14.5,13.43-21.73
                c1.43-2.26,3.31-4.59,3.69-7.08c1.46-9.7,2.25-19.51,3.57-29.23c0.81-5.95,0.26-11.5-2.25-17.04c-1.62-3.58-2.58-7.52-3.34-11.4
                c-0.53-2.69,0.68-4.17,3.97-4.08c8.23,0.22,11.62-2.92,11.88-11.16c0.74-23.13,1.24-46.27,1.93-69.4
                c0.36-12.15,0.94-24.29,1.37-36.44c0.24-6.79-0.56-7.56-7.21-7.48c-3.42,0.04-6.85,0.01-10.85,0.01
                c3.92-3.38,7.23-6.26,10.57-9.09c0.49-0.42,1.13-0.73,1.74-0.95c6.33-2.24,22.71,5.17,25.25,11.38
                c3.78,9.22,3.08,18.94,3.24,28.57c0.12,7.3-0.3,14.68,0.69,21.87c0.63,4.61,2.73,9.38,5.4,13.23c2.09,3.01,6.02,4.77,9.21,6.98
                c1.74,1.21,3.92,1.89,5.44,3.31c6.93,6.48,18.37,4.95,23.49-3.1c3.27-5.15,4.87-10.45,5.42-16.7c1.33-15.03,3.91-29.96,5.85-44.94
                c0.73-5.61,1.04-11.27,1.61-16.91C1059.75,285.35,1063.71,279.38,1071.77,276.76z"/>
            <path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M3.63,803.79c2.88,3.12,5.68,6.31,8.64,9.35c18.92,19.43,37.23,39.52,57.03,58
                c13.78,12.85,27.85,25.16,39.13,40.41c7.32,9.91,12.47,20.5,16.24,32.3c3.29,10.3,8.58,20.21,14.37,29.42
                c7.85,12.49,15.25,25.53,26.8,35.23c7.1,5.96,14.61,11.42,21.76,17.33c3.89,3.21,7.23,7.09,11.14,10.27
                c2.58,2.1,5.53,4.27,8.66,5.06c16.12,4.07,26.8,15.15,37.74,27.61c-6.55,1.79-11.19,4.52-12.72,10.82
                c-0.16,0.64-3.17,1.46-4.12,0.91c-11.94-6.94-25.8-11.2-33.66-23.94c-4.63-7.5-11.71-11.68-20.05-13.82
                c-12.53-3.22-23.83-8.23-34.13-16.67c-8.09-6.63-18.39-10.56-27.73-15.64c-0.33-0.18-0.89,0.08-2.58,0.27
                c2.03,1.49,3.18,2.53,4.5,3.27c4.49,2.53,8.99,5.07,13.59,7.4c8.39,4.26,15.99,9.39,21.26,17.45c0.59,0.91,1.84,1.38,2.75,2.09
                c3.28,2.54,6.61,5.01,9.8,7.67c2.88,2.41,5.5,4.85,2.35,9.13c-0.9,1.22-0.43,3.55-0.35,5.36c0.32,7.24-2.1,9.86-9.15,8.21
                c-10.54-2.47-20.55-6-28.83-13.85c-12.07-11.45-24.81-22.19-37.3-33.19c-1.7-1.5-3.61-2.78-5.42-4.16
                c-0.35,0.34-0.69,0.68-1.04,1.02c1.22,1.27,2.36,2.64,3.67,3.81c12.75,11.46,25.55,22.86,38.28,34.35
                c2.46,2.22,4.84,4.6,6.91,7.19c5.73,7.14,11.78,13.53,19.95,18.4c5.47,3.26,8.9,9.97,13.24,15.13c-0.44,0.55-0.87,1.1-1.31,1.64
                c-2.58-1.87-6.22-3.18-7.55-5.71c-4.78-9.07-12.85-13.25-21.87-16.16c-14.07-4.54-26.93-11-38.4-20.57
                c-11.27-9.4-25.18-13.26-39.29-16.25c-7.97-1.69-15.89-3.68-23.92-4.99c-8.36-1.36-16.82-2.11-25.25-3.01
                c-2.54-0.27-4.64-0.65-4.63-3.87c0.04-13.82,0.04-27.64,0.1-41.46c0-0.6,0.47-1.19,0.97-2.36c15.96,9.92,31.56,19.92,47.46,29.42
                c15.93,9.51,27.65,24.7,44.06,33.51c0.64-0.49,1.28-0.98,1.91-1.47c-2.44-4.32-4.48-8.94-7.42-12.88
                c-4.97-6.66-10.62-12.81-15.77-19.35c-1.93-2.45-3.93-5.23-4.61-8.15c-0.55-2.4,0.16-5.71,1.6-7.67c0.71-0.97,4.56-0.33,6.58,0.54
                c2.53,1.08,4.63,3.18,6.95,4.78c6.84,4.72,13.68,9.47,20.59,14.08c1.22,0.81,2.81,1.06,4.23,1.57c0.27-0.4,0.55-0.8,0.82-1.2
                c-1.1-1.29-2.07-2.71-3.31-3.84c-7.83-7.1-12.9-15.55-13.48-26.38c-0.07-1.34-0.89-2.95-1.88-3.9
                c-6.61-6.33-13.35-12.52-20.08-18.72c-0.41-0.38-1.08-0.46-2.35,0.16c4.65,4.87,9.16,9.89,13.99,14.57
                c3.89,3.77,6.99,7.5,7.77,13.33c0.64,4.8,3.2,9.34,5.47,15.49c-4.07-3-7.1-5.32-10.23-7.5c-2.98-2.08-5.88-4.43-9.15-5.94
                c-6.21-2.88-11.38,0.48-10.93,7.47c0.23,3.58,1.88,7.48,4,10.45c4.74,6.62,10.22,12.7,15.3,19.08c2.14,2.69,4.02,5.57,6.01,8.37
                c-0.33,0.37-0.66,0.73-0.98,1.1c-1.66-1.14-3.53-2.06-4.94-3.45c-22.72-22.58-51.42-36.63-77.56-54.31
                c-3.79-2.56-5.33-5.19-5.25-9.7c0.25-13.15,0.07-26.31,0.1-39.46c0-1.76,0.24-3.53,0.5-7.06c21.77,16.33,42.42,31.81,63.07,47.29
                c0.6-0.58,1.21-1.17,1.81-1.75c-1.4-1.98-2.66-4.09-4.23-5.92c-3.72-4.34-7.25-8.38-6-15.05c0.4-2.14-2.39-5.28-4.34-7.39
                c-13.42-14.48-28.16-27.43-45.52-37.03c-4.09-2.26-5.65-4.95-5.4-9.5c0.33-5.88,0.08-11.79,0.08-17.69
                c0.52-0.2,1.05-0.39,1.57-0.58c4.06,3.6,8.31,7.01,12.13,10.84c8.47,8.48,16.59,17.31,25.1,25.74
                c6.26,6.2,12.81,11.77,21.96,14.07c4.05,1.02,7.57,5.19,10.74,8.5c10.82,11.29,21.36,22.84,32.04,34.26
                c0.52,0.55,1.32,0.84,3.12,0.58c-0.85-1.69-1.52-3.51-2.59-5.05c-4.72-6.84-9.3-13.8-14.43-20.32
                c-4.3-5.46-9.32-10.36-13.97-15.55c-3.99-4.45-8.39-8.63-11.7-13.54c-2.22-3.3-3.97-7.68-3.94-11.56
                c0.04-5.85-2.26-10.22-5.43-14.58c-9.39-12.93-18.35-26.2-28.25-38.72c-6.99-8.83-15.36-16.56-22.86-25.01
                c-1.7-1.92-3.16-4.73-3.32-7.23c-0.45-6.87-0.16-13.78-0.16-20.67C2.59,804.1,3.11,803.95,3.63,803.79z"/>
            <path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M209.32,932.97c-4.81-5.07-9.92-9.9-14.36-15.27c-9.96-12.05-23.37-18.76-37.08-25.24
                c-3.99-1.88-7.71-4.67-11-7.65c-14.92-13.52-29.89-27.02-44.33-41.04c-5.91-5.74-11.09-12.43-15.61-19.34
                c-6.72-10.28-14.55-19.34-23.89-27.31c-18.86-16.09-37.43-32.53-56.21-48.73c-3.57-3.08-5.08-6.54-4.87-11.31
                c0.35-8.14,0.08-16.31,0.11-24.47c0.01-1.77,0.2-3.55,0.39-6.58c3.29,2.07,5.92,3.35,8.12,5.17
                c12.93,10.72,26.1,21.19,38.54,32.46c13.61,12.34,27.68,23.96,43.47,33.4c2.31,1.38,4.87,2.34,7.81,3.73
                c-0.81-7.19-41.12-44.89-56.5-52.86c15.29,13.93,30.57,27.86,45.86,41.78c-0.28,0.42-0.55,0.84-0.83,1.25
                c-1.39-0.82-2.91-1.49-4.15-2.49c-11.53-9.28-22.99-18.63-34.5-27.93c-15-12.11-30.04-24.18-45.03-36.31
                c-1.11-0.9-2.76-2.08-2.81-3.19c-0.28-5.88-0.13-11.79-0.13-19.23c5.03,3.77,8.96,6.45,12.61,9.48
                c15.88,13.18,31.61,26.53,47.54,39.64c3.44,2.83,7.47,4.97,11.32,7.29c1.3,0.79,2.87,1.14,4.32,1.69
                c0.34-0.36,0.67-0.72,1.01-1.08c-1.04-1.67-1.87-3.54-3.16-4.99c-21.33-23.82-42.71-47.59-68.92-66.36
                c-5.57-3.99-6.68-9.95-3.5-16.92c18.17,8.38,35.3,18.55,51.49,30.19c16.08,11.55,31.69,23.75,47.48,35.65
                c0.48-0.55,0.92-0.86,0.88-1.03c-1.66-6.1,0.5-8.73,6.95-9.46c5.19-0.59,5.92-3.06,4.57-8.27c-0.92-3.53-1.68-7.12-2.25-10.72
                c-0.43-2.68,0.57-4.44,3.71-4.62c12.26-0.71,18.7-4.17,15.84-22.37c-1.03-6.57-2.49-13.07-3.45-19.64
                c-0.61-4.17,0.57-6.9,5.78-5.55c3.09,0.8,4.04-1.08,5.54-3.91c4.85-9.18,1.53-19.26,4.06-28.65c0.9-3.32-0.57-7.23-0.64-10.88
                c-0.15-6.91,1.15-8.15,7.81-8.08c7.1,0.07,8.54-1.52,7.73-8.55c-0.49-4.3-1.65-8.65-1.33-12.88c0.37-4.82,1.95-9.57,3.21-14.3
                c0.85-3.18,2.23-6.22,3.05-9.41c0.69-2.68,0.91-5.47,1.34-8.21c0.54,0.08,1.08,0.17,1.62,0.25c-1.41,8.74-3.07,17.45-4.16,26.23
                c-0.99,8.03-1.52,16.13-1.93,24.21c-0.41,8.02,4.27,13.3,10.26,17.78c3.43,2.56,6.71,5.32,10.06,7.99c0,0,0.09-0.06,0.09-0.06
                c-5.74-5.23-12.18-2.71-18.32-1.27c-1.23,0.29-2.79,3.35-2.58,4.92c0.8,6.09-0.5,10.88-5.44,15.01
                c-3.51,2.94-3.71,7.57-1.93,11.56c1.94,4.34,4.69,8.33,7.19,12.41c1.08,1.77,2.43,3.37,3.66,5.05c-0.2,0.42-0.4,0.84-0.6,1.26
                c-3.15-0.47-6.29-1.09-9.46-1.34c-1.83-0.15-3.7,0.29-5.55,0.46c0.55,1.83,0.68,3.96,1.74,5.42c2.83,3.88,6.06,7.46,9.12,11.16
                c1.15,1.4,2.26,2.82,3.4,4.24l0.07-0.07c-5.79-0.4-11.57-0.9-17.36-1.14c-2.29-0.1-7.89,7.48-7.5,10.34
                c0.54,3.89,0.3,7.17-2.04,10.73c-1.41,2.14-1.39,5.97-0.62,8.65c2.24,7.82,5.05,15.48,7.86,23.13c3.03,8.25,6.31,16.41,9.49,24.61
                c-0.31,0.41-0.62,0.82-0.93,1.22c-2.49-1.25-4.98-2.49-7.46-3.76c-1.33-0.68-2.58-1.58-3.98-2.08c-2.25-0.81-4.59-1.36-6.89-2.03
                c-0.33,2.52-1.35,5.19-0.86,7.55c2.89,13.8,6.13,27.52,9.25,41.28c0.22,0.96,0.91,2.22,0.54,2.85c-3.79,6.57-0.1,12.23,2.86,17.26
                c11.74,19.92,23.38,39.96,36.33,59.09c11.89,17.58,21.18,36.15,27.45,56.33c0.39,1.25,0.4,2.61,0.59,3.91L209.32,932.97z"/>
            <path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M203.26,304.89c8.96-8.3,4.1-17.12,0.98-25.77c0.55-0.5,0.87-1.04,1.27-1.11
                c8.13-1.49,10.8-4.44,9.82-12.61c-0.57-4.73,1.18-6.06,5.31-5.87c11.9,0.54,10.24-4.27,6.92-11.78
                c-3.15-7.13-7.35-13.79-10.95-20.72c-1.27-2.45-1.71-4.49,0.1-7.64c1.44-2.49,0.09-7-0.88-10.37c-1.08-3.72-3.15-7.16-4.85-10.86
                c7.05-1.27,10.15,0.47,12.43,5.81c1.49,3.49,3.39,6.83,5.4,10.06c0.59,0.94,2.27,1.89,3.23,1.71c0.82-0.16,1.76-1.88,1.9-3.01
                c0.44-3.45,0.45-6.95,0.76-10.42c0.36-3.97,0.64-7.97,1.42-11.86c0.41-2.03,1.34-4.83,2.87-5.6c8.43-4.24,6.68-11.88,6.3-18.65
                c-0.62-11.05-5.48-20.89-10.67-30.41c-7.28-13.35-8.15-28.35-11.52-42.7c-0.36-1.54-0.24-3.49,0.4-4.92
                c2.63-5.78,1.68-11.35-0.78-16.76c-4.53-9.97-8.82-20.08-14.14-29.62c-1.9-3.4-6.24-5.76-9.92-7.74
                c-3.22-1.72-5.26,0.12-5.56,3.87c-0.23,2.95-0.35,6.06-1.39,8.76c-1.91,4.93-6.01,5.57-10.31,2.5c-2.77-1.97-6.13-3.12-8.91-5.08
                c-1.55-1.09-3.09-2.91-3.57-4.69c-2.48-9.18-6.46-17.48-13.08-24.38c-2.97-3.1-6.15-6.01-9.14-9.09c-0.74-0.77-1.1-1.91-2.01-3.54
                c2.35-0.19,4.1-0.45,5.86-0.45c21.48-0.03,42.96,0.04,64.43-0.07c3.55-0.02,6.1,1.08,8.85,3.45
                c17.26,14.88,26.74,34.28,33.47,55.48c1.95,6.16,4.11,12.41,7.28,17.99c4.98,8.74,7.41,18.07,9.05,27.82
                c0.94,5.56,2.25,11.09,3.87,16.49c0.68,2.28,2.25,4.51,3.96,6.21c7.87,7.83,15.72,15.7,24,23.09c5.69,5.08,12.25,9.17,18.07,14.11
                c3.08,2.61,5.75,5.86,8.01,9.23c3.21,4.77,5.75,9.98,8.8,14.86c5.03,8.03,10.28,15.92,15.31,23.95c0.72,1.15,0.71,2.77,1.04,4.17
                c-1.53,0.34-3.05,0.76-4.6,1.01c-1.56,0.25-3.93-0.26-4.58,0.6c-3.67,4.88-7.23,2.11-11.01,0.43c-1.97-0.88-4.14-1.32-6.23-1.95
                c-0.32,2.38-0.78,4.75-0.92,7.14c-0.08,1.41,0.33,2.86,0.69,5.61c-2.4-1.54-3.99-2.27-5.21-3.4c-4.52-4.18-9.42-8.08-13.27-12.82
                c-8.67-10.68-20.62-16.04-32.6-21.51c-0.81-0.37-1.84-0.28-3.06-0.44c-0.07,0.93-0.39,1.75-0.12,2.13
                c6.89,9.55,5.84,22.06,11.95,31.92c2.07,3.33-0.06,4.83-3.11,4.53c-5.27-0.51-10.48-1.64-15.74-2.26
                c-2.16-0.25-4.4,0.15-6.61,0.25c0.24,2.25-0.11,4.8,0.84,6.69c1.7,3.38,4.14,6.39,6.35,9.49c1.02,1.43,2.25,2.73,3.73,4.5
                c-1.55,0.53-2.42,1.03-3.32,1.1c-6.12,0.44-7.17,1.62-4.83,7.14c4.32,10.21,9.04,20.26,13.59,30.37c0.26,0.59,0.46,1.2,1.22,3.18
                c-4.65-2.51-8.24-4.45-11.83-6.4c-0.45,0.35-0.9,0.7-1.35,1.05c0.88,10.85,10.22,17.71,13.67,28.26c-2.31-1.06-4.85-1.79-6.9-3.22
                c-8.04-5.63-15.87-11.55-23.89-17.21c-1.9-1.34-4.22-2.1-6.34-3.13c-0.43,0.39-0.86,0.78-1.29,1.17c1.21,2.54,1.99,5.42,3.7,7.56
                c5.2,6.49,10.76,12.69,16.18,19c3.15,3.66,6.37,7.26,9.42,11c1.08,1.32,1.76,2.96,2.63,4.45c-0.32,0.59-0.64,1.17-0.96,1.76
                c-3.92-1.16-7.95-2.07-11.75-3.54c-4.01-1.56-7.22-5.07-12.39-3.4c-1.79,0.58-4.56-0.88-6.54-2.02
                C229.31,320.32,216.49,312.69,203.26,304.89z"/>
            <path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M134.5,275.42c3.12,7.83,6.64,15.53,9.23,23.54c2.01,6.23,2.86,12.85,4.32,19.73
                c-1.24-0.15-2.2,0.01-2.73-0.38c-6.47-4.85-15.5-6.81-18.15-16.04c-0.28-0.98-1.35-1.73-3.07-3.84c-0.18,2.84-0.66,4.39-0.29,5.71
                c1.43,5.12,3.31,10.11,4.67,15.24c3.07,11.54,6.13,23.09,8.71,34.74c0.53,2.4-1.03,5.27-1.69,7.92c-0.68,2.72-1.67,5.39-2.07,8.14
                c-0.36,2.45,0.19,5.03-0.16,7.47c-0.45,3.11-1.41,6.14-2.15,9.21c-2.65-1.85-5.56-3.41-7.86-5.62c-2.5-2.4-4.4-5.42-6.62-8.12
                c-0.97-1.18-2.09-2.23-3.14-3.34c-0.43,0.19-0.86,0.37-1.29,0.56c0.16,2.01-0.08,4.16,0.55,6.02c2.03,5.98,4.05,12,6.68,17.73
                c5.31,11.57,5.87,23.81,5.77,36.18c-0.01,0.76-0.4,1.52-0.81,2.97c-8.11-4.72-9.93-13.75-15.1-20.04
                c-0.62,0.11-1.25,0.22-1.87,0.32c0,2.73-0.4,5.54,0.08,8.19c1.23,6.88,2.99,13.66,4.24,20.53c1,5.49,1.56,11.07,2.35,16.87
                c-7.37-1.2-7.49-1.11-8.14,5.66c-0.19,1.99-0.25,3.99-0.46,5.98c-0.52,4.99-3.19,6.7-7.33,3.92c-2.72-1.83-5.05-4.82-6.53-7.8
                c-1.97-3.99-2.83-8.51-4.38-12.73c-0.62-1.69-1.82-3.17-2.75-4.74c-0.54,0.13-1.08,0.27-1.62,0.4c-0.09,2.17-0.57,4.41-0.21,6.5
                c1.12,6.56,2.81,13.02,3.77,19.6c0.76,5.23,1.01,10.57,1,15.87c0,3.9-1.18,7.35-5.21,9.53c-1.53,0.83-2.52,3.46-2.91,5.43
                c-0.55,2.74-0.3,5.63-0.38,8.46c-0.18,6.13-3.66,10.45-9.67,10.48c-5.71,0.03-8.22,3.18-9.49,7.77c-1.19,4.31-1.76,8.8-2.51,13.22
                c-1.18,7.01-4.42,8.8-10.83,5.87c-1.06-0.48-2.07-1.08-3.09-1.64c-9.82-5.38-15.37-3.73-20.77,6.23
                c-6.1,11.25-12.16,22.52-18.25,33.77c-0.3,0.56-0.71,1.06-1.89,2.77c-0.19-2.91-0.39-4.59-0.39-6.27
                C2.06,539.6,2.11,491.8,1.99,444.01c-0.01-4.21,1.55-6.88,4.38-9.9c4.5-4.8,8.82-10.12,11.76-15.95
                c4.08-8.07,11.55-9.33,18.79-11.4c1.09-0.31,2.72,0.88,3.97,1.6c2.74,1.57,5.28,3.52,8.13,4.83c1.49,0.68,3.68,0.88,5.09,0.24
                c0.72-0.32,0.58-2.96,0.51-4.54c-0.2-4.81-0.77-9.61-0.78-14.41c-0.01-3.09,1.73-5.03,4.43-7.11c3.34-2.57,5.38-6.89,7.83-10.56
                c0.89-1.34,1.33-2.97,2.14-4.88c2.49,1.2,4.61,2.49,6.9,3.22c1.43,0.46,3.34,0.49,4.63-0.13c0.57-0.27,0.35-2.69,0.08-4.05
                c-0.4-2.08-1.15-4.1-0.81-6.44c1.4,4.8,2.88,9.58,4.17,14.4c3.1,11.57,5.83,23.26,9.29,34.72c1.52,5.03,4.35,9.68,6.81,14.38
                c0.78,1.49,2.3,2.58,4.6,5.05c0.31-3.33,0.84-5.2,0.58-6.95c-0.61-4.11-1.57-8.17-2.45-12.23c-2.21-10.24-4.92-20.4-6.58-30.73
                c-2.76-17.11-8.16-33.29-15.81-48.75c-2.06-4.17-2.19-7.62,0.49-11.57c1.88-2.76,3.5-4.88,8.32-2.46
                c-2.8-10.11-5.49-18.86-10-26.91c-4.51-8.05-9.42-15.88-14.16-23.8c0.47-0.45,0.95-0.91,1.42-1.36c1.44,0.83,3.11,1.43,4.29,2.55
                c3.62,3.42,7.37,6.78,10.45,10.65c2.81,3.54,6.04,5.82,10.32,7.14c3.64,1.12,4.49-0.52,3.66-3.37c-1.43-4.95-3.24-9.79-5.01-14.63
                c-1.01-2.76-2.27-5.44-3.41-8.16c0.41-0.3,0.82-0.6,1.24-0.9c4.12,4.8,8.17,9.67,12.39,14.38c3.31,3.7,6.43,7.73,10.35,10.67
                c5.73,4.29,8.18,3.01,9.53-3.93c0.12-0.63,0.37-1.24,0.78-2.53c2.81,1.42,5.28,3,7.98,3.91c2.08,0.7,4.44,0.56,6.67,0.79
                c0.05-2.04,0.17-4.08,0.12-6.12c-0.03-1.15-0.33-2.3-0.5-3.45L134.5,275.42z"/>
            <path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M905.26,1496.32c4.5-0.7,9.01-2.09,13.48-1.95c8.62,0.27,11.84-3.1,9.71-11.48
                c-0.44-1.74-1.17-3.41-1.93-5.61c4.49-1.27,8.6-3.08,12.63,0.7c0.59,0.55,1.39,1.07,2.16,1.2c11.01,1.87,22.05,3.37,33.1,0.44
                c1.83-0.49,3.42-1.89,5.24-2.94c5.59,6.01,12.73,8.19,20.65,8.37c7.81,0.18,10.71-2.96,9.76-10.16c9.1-1,18.25-1.68,27.29-3.12
                c4.28-0.68,8.31-2.89,12.51-4.23c1.64-0.52,3.58-1.07,5.16-0.69c12.08,2.85,24.12,1.87,36.23,0.31c4.35-0.56,7.01-2.03,5.81-7.65
                c3.41-0.33,6.51-0.46,9.55-0.98c3.75-0.64,7.61-1.19,11.13-2.54c4.89-1.88,5.98-5.68,2.33-9.37c-3.68-3.73-8.16-6.77-12.59-9.64
                c-2.89-1.87-3.78-3.37-2.26-6.79c3.12-7.03,1.44-12.91-5.91-15.51c-9.3-3.28-19.16-5.31-28.95-6.79
                c-5.21-0.79-6.81-2.62-6.65-7.51c0.8-24.11,1.54-48.22,2.08-72.34c0.13-5.94-0.71-11.9-0.83-17.86
                c-0.16-8.08-0.04-16.17-0.04-24.57c0.59,0.06,1.56,0,2.41,0.29c27.79,9.26,56.64,12.84,85.56,16c8.08,0.88,8.11,0.72,8.11,9.01
                c0,59.77,0,119.55,0,179.32c0,7.88-0.01,7.89-8.09,7.89c-80.73,0-161.45,0-242.18,0c-1.76,0-3.51,0-5.27,0
                C905.4,1497.53,905.33,1496.92,905.26,1496.32z M1084.83,1388.74c2.48,0.17,4.96,0.37,7.44,0.5c3.71,0.2,7.99-0.43,8-4.57
                c0-2.63-3.2-5.54-5.5-7.78c-1.1-1.07-3.47-0.77-5.21-1.28c-1.38-0.41-3.16-0.74-3.93-1.75c-3.7-4.85-8.17-4.13-12.97-2.33
                c-5.85,2.2-6.46,3.92-3.1,8.97c0.35,0.52,0.68,1.2,0.66,1.79c-0.17,5.84,3.34,6.89,8.16,6.48
                C1080.5,1388.6,1082.67,1388.75,1084.83,1388.74z M1086.17,1355.64c-3.46,2.52-6.79,3.9-8.34,6.4c-1.27,2.04,3.28,5.38,7.09,5.16
                c3.56-0.2,7.11-1.4,6.74-5.6C1091.49,1359.78,1088.66,1358.21,1086.17,1355.64z"/>
            <path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M929.58,1164.69c1.69-2.66,3.47-5.27,5.06-7.99c4.94-8.47,9.74-17.02,14.74-25.45
                c4.32-7.28,8.99-14.35,13.24-21.66c2.03-3.49,3.44-7.33,5.15-11.01c0.77-1.65,1.53-3.31,2.35-4.94
                c7.44-14.68,14.8-29.4,22.44-43.97c1.64-3.12,4.08-5.92,6.53-8.51c3.63-3.83,7.61-7.35,11.78-11.32c2.99,3.56,5.07,0.98,7.86-1.38
                c3.72-3.15,8.11-5.51,13.53-9.08c0,3.65,0.08,5.67-0.01,7.68c-0.33,6.87-0.31,13.36,4.77,19.21c2.81,3.23,3.79,8.07,5.53,12.21
                c4.29,10.24,9.76,19.69,18.15,27.18c2.85,2.55,5.79,4.81,9.97,1.66c3.83,6.41,7.91,12.29,10.99,18.66
                c3.24,6.7,7.88,11.86,13.88,16c3.84,2.65,7.63,5.36,11.5,7.96c1.37,0.92,2.93,2.29,4.37,2.26c5.62-0.13,9.62,2.73,13.37,6.31
                c2.3,2.2,4.99,4.14,6.81,6.7c8.37,11.78,16.53,23.7,24.62,35.68c0.73,1.08,1.1,3.53,0.46,4.17c-1.68,1.68-4.55,4.26-6,3.76
                c-8.59-2.98-17.25-6.1-25.19-10.44c-5.58-3.05-9.91-8.32-15.1-12.21c-3.75-2.81-7.94-5.05-12.01-7.41
                c-6.6-3.83-10.86-9.7-14.24-16.3c-1.53-2.99-2.61-5.82-6.81-6.35c-1.71-0.22-3.69-2.84-4.51-4.8c-3.06-7.35-5.71-14.88-8.48-22.35
                c-0.54-1.46-0.98-2.96-1.46-4.44c-0.46,0.06-0.92,0.11-1.39,0.17c0,1.96-0.31,3.98,0.05,5.86c1.73,8.86-3.35,15.19-7.82,21.77
                c-0.18,0.27-0.49,0.61-0.75,0.63c-7.9,0.44-9.74,6.87-12.58,12.46c-1.11,2.19-2.73,4.28-4.56,5.91
                c-6.75,5.99-11.21,13.55-15.13,21.47c-6.26,12.68-18.56,18.16-29.56,25.21c-11.35-1.51-15.08,9.46-22.76,13.97
                c-2.39,1.4-4.49,3.3-7.48,5.54c0.19-1.54,0.28-2.09,0.33-2.65c0.12-1.53,0.23-3.05,0.34-4.58c-1.58,0.07-3.32-0.25-4.73,0.28
                c-9.65,3.64-19.02,8.18-28.89,11.01c-9.69,2.78-19.86,3.9-29.83,5.7c-0.74,0.13-1.54-0.1-3.51-0.25c3.74-2.05,6.46-3.55,9.19-5.04
                c-0.12-0.55-0.24-1.11-0.36-1.66c-13.74,3.77-27.48,3.45-41.21,1.71c-0.12-0.45-0.24-0.9-0.36-1.34c1.65-0.85,3.23-1.91,4.97-2.52
                c9-3.19,16.07-9.09,23.07-15.39c6.63-5.96,14.2-10.86,21.13-16.51c6.69-5.46,13.13-11.23,19.57-16.99
                c2.08-1.86,2.14-3.74-1.06-4.63L929.58,1164.69z M936.84,1169.1c0.28,0.41,0.55,0.82,0.83,1.23c0.98-0.41,2.16-0.6,2.9-1.27
                c6.48-5.89,13.01-11.74,19.26-17.86c2.95-2.89,5.2-6.49,8.05-9.49c0.93-0.98,2.75-1.17,4.2-1.57c1.93-0.53,4.56-0.25,5.75-1.45
                c6.34-6.42,15.37-9.67,20.4-17.63c1.38-2.19,3.33-3.64,6.13-1.13c0.62,0.56,3.13-0.27,4.26-1.12c2.5-1.9,4.58-4.35,7.02-6.35
                c15.11-12.43,20.9-29.97,26.27-47.74c0.16-0.53-0.1-1.18-0.16-1.78c-0.41-0.14-0.82-0.29-1.23-0.43c-1.31,3.04-2.79,6.02-3.9,9.13
                c-5.72,16.04-13.17,30.94-27.13,41.56c-1.25,0.95-2.95,1.42-4.51,1.85c-2.02,0.57-4.76,0.19-6.07,1.42
                c-5.99,5.62-11.25,11.97-18.46,16.29c-2.94,1.76-4.67,5.77-9.27,3.51c-0.77-0.38-2.76,0.99-3.77,1.98
                c-2.6,2.56-4.86,5.46-7.43,8.06c-6.52,6.58-13.12,13.06-19.71,19.57C939.16,1166.98,937.99,1168.03,936.84,1169.1z
                M983.17,1163.24c8.29,4.97,11.02,5.07,16.32-2.77c7.97-11.8,14.92-24.3,22.19-36.56c0.78-1.31,0.82-3.06,1.21-4.6
                c-5.35,7.04-9.25,14.55-13.78,21.67c-4.35,6.85-9.23,13.37-14.13,19.85c-1.99,2.63-4.95,3.9-7.65,0.91
                c-3.6-3.98-6.03-2.32-8.05,1.34c-3.39,6.15-7.85,11.1-14.41,13.95c-0.63,0.27-1.03,1.07-1.74,1.84
                C968.89,1179.43,969.03,1179.32,983.17,1163.24z"/>
            <path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M351.27,875.9c-5.36-5.86-10.92-11.54-15.99-17.64c-2.75-3.31-5.38-5.51-9.94-6.43
                c-5.61-1.12-10.85-4.08-16.25-6.23c-7.43-2.95-7.81-2.68-7.17,5.69c-8.08-7.93-12.33-18.65-23.07-24.08
                c0.3,1.94,0.26,4.01,0.95,5.79c3.02,7.75,6.18,15.45,9.43,23.11c0.96,2.25,2.28,4.73-0.65,5.93c-1.69,0.69-4.69,0.42-6.02-0.7
                c-3.41-2.86-6.08-6.57-9.3-9.68c-3.09-2.98-6.36-5.84-9.82-8.37c-2.32-1.7-3.98-0.49-3.6,2.29c0.43,3.11,1.51,6.12,2.1,9.22
                c0.33,1.72,0.92,3.92,0.19,5.21c-2.68,4.71-5.7,9.25-8.91,13.6c-2.26,3.06-5.53,3.74-8.84,1.62c-2.64-1.69-5.14-3.64-7.51-5.7
                c-2.38-2.07-4.32-4.7-6.84-6.55c-2-1.47-4.59-2.14-6.92-3.16c-0.57,2.77-2.14,5.8-1.53,8.27c2.09,8.38,4.96,16.57,7.54,24.82
                c0.53,1.7,1.09,3.39,0.72,5.71c-1.28-1.36-2.68-2.64-3.83-4.11c-6.39-8.11-13.01-16.05-19-24.44
                c-17.4-24.37-34.58-48.9-51.72-73.45c-3.13-4.49-5.63-9.42-8.48-14.11c-2.06-3.39-0.68-5.22,2.77-5.28
                c4.06-0.07,7.51-1.74,11.21-2.76c4.21-1.17,9.55-0.21,13.83,1.38c5.06,1.88,9.47,5.48,14.22,8.23c1.72,0.99,3.57,1.79,5.43,2.48
                c3.06,1.12,5.15,0.21,4.75-3.31c-0.36-3.15,1.06-4.64,3.68-5.61c0.78-0.29,1.53-0.65,2.27-1.03c10.73-5.42,12.68-10.83,4.75-19.75
                c-9.47-10.64-20.3-20.09-30.8-29.77c-6.06-5.59-12.62-10.63-18.96-15.92c0.22-0.46,0.44-0.93,0.66-1.39
                c3.92,1.43,8.14,2.32,11.68,4.4c6.44,3.77,12.51,8.2,18.68,12.42c5.08,3.48,10.16,6.95,15.09,10.63
                c4.47,3.33,8.91,3.34,13.37,0.36c4.2-2.81,5.05-7.28,4.97-11.88c-0.04-2.47-0.61-4.96-1.23-7.37c-0.87-3.33,0.6-4.52,3.56-3.94
                c3.24,0.64,6.36,1.87,9.58,2.64c10.06,2.41,19.67,1.53,28.5-4.29c3.64-2.4,5.34-5.84,5.07-10.21c-0.35-5.66-0.01-10.86,3.05-16.23
                c2.24-3.92,1.84-9.35,2.61-14.1c0.29-1.76,0.59-3.53,1.12-6.73c4.74,8.36,7.46,15.96,8.08,24.06c0.4,5.19-0.14,10.54-0.95,15.7
                c-1.28,8.17,1.59,15.43,4.11,22.82c0.95,2.78,2.09,5.5,3.43,9c-2.43,0.2-4.17,0.61-5.85,0.42c-2.93-0.33-4.44,0.91-4.92,3.64
                c-2.77,15.8-1.8,30.87,8.72,44.04c0.87,1.09,1.9,2.06,3.22,3.47c6.15-10.56-2.12-20.45-0.36-30.26c0.5-0.24,1-0.49,1.5-0.73
                c2.07,2.41,4.29,4.72,6.15,7.28c1.15,1.59,1.97,3.51,2.55,5.4c6.77,22.08,18.36,41.65,31.21,60.58c0.8,1.18,1.41,2.47,2.11,3.72
                c-0.31,0.44-0.63,0.87-0.94,1.31c-4.34-2.27-8.69-4.54-14.37-7.51c1.05,2.79,1.32,4.54,2.25,5.79c2.79,3.72,5.69,7.38,8.81,10.83
                c8.34,9.22,14.8,19.61,19.8,30.95C351.21,875.96,351.27,875.9,351.27,875.9z M234.42,761.04c0,0,0.08-0.06,0.08-0.06
                c0.5,0.86,0.87,1.84,1.52,2.55c15.95,17.48,30.54,35.99,42.54,56.46c0.47,0.8,1.51,1.26,2.28,1.88c0.4-0.31,0.8-0.62,1.2-0.93
                c-2.01-3.29-3.99-6.6-6.03-9.87C264.41,792.51,251.24,775.26,234.42,761.04z M262.05,818.88c0.59-0.5,1.17-1.01,1.76-1.51
                c-5.22-5.91-10.44-11.82-15.66-17.73c-0.31,0.26-0.63,0.53-0.94,0.79C252.16,806.58,257.1,812.73,262.05,818.88z"/>
            <path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M719.42,595.1c-0.65-1.43-0.85-2.74-1.6-3.4c-9.69-8.49-10.3-20.35-11.87-31.83
                c-0.92-6.77-1.57-13.3,2.31-19.86c1.27-2.15-0.42-7.2-2.31-9.77c-2.21-3-2.42-5.46-1.65-8.71c1.63-6.89,3.51-13.78,4.27-20.78
                c0.29-2.68-2.37-5.61-3.35-8.53c-1.07-3.17-2.6-6.51-2.47-9.71c0.46-11.63,2.05-23.24,2.22-34.87c0.14-9.27-1.25-18.57-1.78-27.86
                c-0.49-8.68,1.54-11.28,11.34-14.44c-5.61,20.05-5.64,40.02-4.67,60.04c0.61,0.07,1.23,0.14,1.84,0.21
                c0.15-1.6,0.35-3.2,0.44-4.81c0.7-11.95-0.67-24.47,2.51-35.71c5.56-19.69,3.38-40.75,11.97-59.77c3.92-8.68,8-16.91,14.67-24.03
                c4.43-4.73,7.02-11.2,10.43-16.9c0.4-0.68,0.74-1.39,2-1.71c-0.51,1.24-1.04,2.47-1.54,3.71c-2.8,7.06-5.77,14.06-8.33,21.21
                c-2.64,7.35-4.85,14.85-7.29,22.28c-0.52,1.58-1.17,3.11-1.82,4.65c-4.39,10.42-0.64,19.47,5.16,27.88
                c1.09,1.58,4.28,1.91,6.58,2.36c1.31,0.25,2.8-0.44,5-0.86c-1.06,10.26-1.81,20-3.24,29.63c-0.35,2.33-2.8,4.44-4.53,6.43
                c-3.41,3.94-4.23,8.42-3.74,13.49c3.2,33.15,4.58,66.38,4.18,99.68c-0.03,2.65,0,5.45-0.78,7.93c-5.27,16.74-3.82,33.9-3.89,51.04
                c-0.09,20.62-0.95,41.24-1.51,61.86c-0.03,1.16,0.08,2.55-0.5,3.42c-5,7.6-1.26,14.8,0.08,22.49c2.42,13.87,3.91,27.98,4.62,42.06
                c1.4,27.63,0.5,55.23-3.16,82.71c-0.74,5.59-0.62,11.3-0.95,16.94c-0.09,1.53-0.36,3.05-0.55,4.58c-0.49,0.08-0.99,0.16-1.48,0.24
                c-0.55-1.35-1.09-2.7-1.66-4.04c-2.02-4.72-3.57-9.73-6.19-14.11c-6.57-10.98-16.72-14.49-29.22-10.55
                c-3.15,0.99-6.15,2.46-9.5,3.82c-0.33-0.73-0.97-1.46-0.91-2.13c1.52-17.54,2.83-35.11,4.88-52.59c0.56-4.76,3.53-9.21,5.16-13.88
                c1.08-3.07,2.28-6.26,2.48-9.45c0.37-5.97-0.07-11.98,0.08-17.97c0.12-5,1.84-8.71,7.83-8.86c4.17-0.1,4.8-2.25,2.41-5.51
                c-0.99-1.34-1.89-2.74-2.83-4.12c-5.65-8.27-9.24-16.82-8.51-27.43c1.24-17.92,0.88-35.95,1.92-53.89
                c0.31-5.41,3.01-10.68,4.67-16c0.39-1.25,1.21-2.38,1.51-3.65C710.4,596.56,710.38,596.55,719.42,595.1z"/>
            <path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M957.59,1214.48c-0.72,2.73-1.34,5.06-2.33,8.81c3.12-1.28,5.46-1.69,7.09-3
                c6.08-4.87,12.02-9.93,17.8-15.14c2.48-2.24,4.26-5.19,8.42-3.17c0.99,0.48,2.93-0.37,4.14-1.1c5.84-3.53,11.65-7.11,17.34-10.88
                c6.87-4.56,11.31-11.2,14.93-18.42c3.47-6.9,7.87-12.99,14.94-18.27c0.66,13.3,4.77,26.22-7.22,36.33
                c-4.56,3.84-8.5,8.4-13.03,12.27c-3.2,2.74-2.34,5.59-1.09,8.55c1.37,3.26,4.05,2.32,6.4,1.43c2.91-1.1,5.71-2.47,8.55-3.72
                c2.86,6.5,1.06,13.42-3.77,17.65c-3.6,3.15-6.62,6.95-10.1,10.25c-2.99,2.85-5.97,5.87-9.45,8
                c-10.51,6.44-18.18,15.62-24.76,25.68c-3.63,5.55-8.18,6.82-14,5.85c-1.34-0.22-3.18-0.89-3.73-1.91
                c-4.8-9.09-12.04-10.09-20.84-6.35c-2.41-4.43-4.61-8.67-6.99-12.8c-2.65-4.61-6.39-6.93-12.04-7.51
                c-6.37-0.66-12.67-2.63-18.81-4.63c-9.65-3.14-18.73-2.09-28.29,1.05c-10.91,3.58-19.83,9.04-25.93,18.92
                c-0.88,1.43-2.77,2.31-4.33,3.23c-3.59,2.1-7.5,3.73-10.85,6.15c-10.4,7.5-21.41,7.12-33.21,4.09
                c-6.08-1.56-11.29-3.76-16.22-7.72c-4.27-3.43-9.58-5.57-15.11-8.67c2.72-2.8,5.36-5.47,7.95-8.18c5.26-5.5,9.44-11.2,6.2-19.6
                c-0.63-1.63,0.6-4.61,1.88-6.24c6.18-7.84,12.7-15.34,15.55-25.32c0.81-2.84,3.72-5.03,5.42-7.68
                c3.95-6.14,7.82-12.34,11.59-18.59c2.11-3.5,0.95-5.93-2.83-7.36c-6.89-2.61-12.97-6.2-18.47-11.45
                c-2.76-2.64-8.26-2.41-13.18-3.65c1.72-1.02,3.3-2.42,5.13-2.93c2.86-0.8,5.88-1.32,8.84-1.38c9.45-0.19,18.97-0.79,28.32,0.16
                c4.53,0.46,9.64,3.13,12.93,6.39c8,7.93,14.93,16.92,22.62,25.17c2.66,2.86,6.39,4.7,9.49,7.19c3.62,2.91,7.05,6.05,10.62,9.02
                c2.63,2.19,2.28,4.29-0.26,6.05c-6.79,4.68-13.6,9.36-20.56,13.78c-3.79,2.4-7.91,4.26-11.62,7.17c9.42,5.23,19.3,3.69,29.28,4.28
                c-1.84,5.23,1.74,4.73,4.72,4.69c22.33-0.24,43.51-5.41,63.63-15.04c1.2-0.57,2.42-1.09,3.66-1.58
                C956.3,1214.23,956.66,1214.38,957.59,1214.48z"/>
            <path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M879.69,1327.48c-5.53-8.98-13.04-8.55-20.91-5.47c-7.18,2.82-15.35,4.42-18.28,14.64
                c-7.26-10.5-13.21-20.49-20.55-29.33c-7.43-8.93-14.66-17.8-20-29.4c2.47,0.37,4.27,0.3,5.77,0.92
                c6.58,2.73,13.29,2.32,20.16,1.64c2.76-0.27,5.7,0.61,8.48,1.29c9.01,2.2,17.07,7.74,27.35,5.55c5.77-1.23,12.17,0.49,18.3,0.89
                c-1.65,10.9,2.27,14.96,13.45,13.48c5.32-0.7,10.2-1.55,15.21,1.71c2.07,1.34,5.53,1.17,8.23,0.78c7.73-1.12,9.67-5.88,5.06-12.33
                c2.76-0.35,5.4-1.05,7.99-0.89c1.66,0.1,3.72,1.08,4.76,2.35c5.13,6.27,19.73,6.31,24.8-0.15c0.2-0.26,0.56-0.57,0.53-0.82
                c-0.77-6.46,5.98-7.59,8.45-11.79c0.56-0.96,2.55-1.57,3.85-1.52c17.11,0.57,34.22,1.3,51.33,1.95c4.03,0.15,5.58,1.73,5.69,6.15
                c0.23,9.09-5.02,13.67-12,17.81c-10.42,6.18-20.55,12.85-30.69,19.49c-0.88,0.58-1.59,3.29-1.19,3.63
                c1.87,1.61,4.05,3.02,6.34,3.91c0.91,0.35,2.44-0.79,3.65-1.32c9.87-4.29,19.74-8.59,30.56-13.3c1.17,26.31-2.19,51.99-2.2,78.8
                c-6.56-2.3-12.02-4.36-17.59-6.1c-2.26-0.7-5.51-1.79-6.97-0.78c-3.88,2.72-8.01,2.43-12.22,3.08c-3.47,0.53-4.59-0.71-6.1-3.25
                c-3.02-5.07-6.35-9.87-13.65-8.63c-1.56,0.26-3.4-1.11-5.1-1.75c-5.59-2.12-10.64-0.62-13,4.78c-2.02,4.63-2.99,9.72-4.41,14.61
                c-0.26,0.91-0.46,1.84-0.99,4.01c-6.17-2.7-11.89-5.36-17.73-7.7c-2.67-1.07-5.59-1.52-8.42-2.26c-2.1-2.83-4.42-5.95-6.88-9.26
                c4.58-7.38,2.1-11.84-7.64-12.59c-6.83-0.52-12.87,1.46-18.54,5.28c-2.42,1.63-5.5,2.27-8.26,3.4c-4.55,1.85-5.82,5.56-2.29,8.88
                c3.05,2.86,7.13,4.62,10.77,6.82c2.95,1.78,5.93,3.51,8.89,5.26c-0.26,0.47-0.53,0.94-0.79,1.41c-6.45-2.54-12.9-5.08-18.89-7.44
                c-7.48-13.8-15.17-28-23.45-43.29c6.99,4.23,13.86,5.9,20.6,2.88c5.83-2.61,14.04-1.54,16.65-9.97c2.41,0,4.85,0,7.78,0
                c-1.22,1.64-2.61,2.81-2.99,4.24c-0.38,1.42-0.28,4.04,0.56,4.55c5.83,3.51,11.85,6.71,17.95,9.73c1.15,0.57,2.97,0.07,4.41-0.25
                c4.49-0.99,5.57-3.13,4.06-7.48c-0.31-0.9-0.37-1.89-0.61-3.14c1.63-0.37,3.06-0.61,4.44-1.01c6.2-1.8,7.48-6.05,3.34-10.82
                c-0.55-0.64-1.04-1.74-0.91-2.52c1.45-8.53-1.57-16.05-5.25-23.39c-0.8-1.6-1.95-3.9-3.31-4.21c-7.03-1.58-14.28-3.85-21.27-3.33
                c-3.77,0.28-7.75,5.2-10.57,8.85C882.97,1318.04,881.9,1322.44,879.69,1327.48z M939.18,1351.55c5.99-0.78,10.18-0.93,14.15-1.96
                c3.38-0.87,6.84-2.74,5.84-7.22c-2.16-9.72-15.8-14.09-23.19-7.43c-3.73,3.36-4.47,8.03-2.43,12.26
                C934.63,1349.45,937.91,1350.63,939.18,1351.55z M935.51,1387.3c3.22-6.72,1.49-9.36-5.96-9.38c-2.31-0.01-4.81-0.24-6.89,0.53
                c-1.73,0.64-2.99,2.59-4.45,3.95c1.26,1.33,2.32,2.98,3.82,3.9c1.47,0.89,3.4,1.03,5.06,1.48c-0.04,5.27,3.42,8.44,8.99,7.86
                c1.79-0.19,3.42-1.97,5.12-3.02c-1.08-1.29-2.09-2.65-3.27-3.84C937.28,1388.14,936.32,1387.78,935.51,1387.3z M943.02,1311.97
                c2.84-0.34,6.15-1.15,6.65-5.3c0.42-3.55-5.88-7.97-10.95-7.09c-1.95,0.34-4.57,2.85-4.97,4.77
                C932.97,1308.15,937.92,1312.07,943.02,1311.97z M878.16,1355.86c-5.37,0.15-9.76,3.78-8.6,6.28c0.84,1.8,3.76,3.41,5.92,3.65
                c3.29,0.36,6.72-1.44,6.55-5.11C881.94,1358.73,879.06,1356.91,878.16,1355.86z"/>
            <path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M572.15,287.27c-1.41-0.13-2.81-0.25-5.76-0.52c1.98,3.42,3.35,5.8,5.49,9.49
                c-3.61-0.38-6.71-0.03-8.94-1.26c-0.96-0.53-0.55-4.45,0-6.64c1.46-5.77,3.35-11.43,5.06-17.13c0,0-0.1,0.11-0.1,0.11
                c0.98-1.04,2.56-1.94,2.83-3.14c1.03-4.53,2.29-9.18,2.18-13.75c-0.07-2.85-1.9-6.13-3.89-8.36c-2.93-3.27-2.21-6.04-0.1-8.88
                c1.25-1.68,3.09-2.92,5.34-4.96c2.43,5.38,5.09,10.67,7.22,16.16c2.48,6.4,4.9,12.25,13.08,13.33c2.24,0.29,4.09,3.51,6.39,5.63
                c4.05-4.33,10.41-6.72,9.9-13.93c-0.21-2.97-0.55-6.26-1.97-8.75c-5.34-9.39-11.13-18.53-16.81-27.72
                c-2.49-4.02-2.25-7.96-0.89-12.41c2.07-6.82,3.41-13.86,5.05-20.81c1.49-6.35,1.32-13.58,9.3-16.35c0.87-0.3,1.35-2.18,1.71-3.41
                c0.68-2.33,1.13-4.73,1.78-7.52c0.96,4.08,2.32,7.51,2.43,10.99c0.08,2.81-0.98,5.84-2.21,8.46c-1.38,2.96-1.77,5.77,0.93,7.62
                c7.11,4.86,9.2,11.69,8.84,19.77c-0.02,0.42,0.39,0.85,0.63,1.35c3.11-6.14,1.35-14.91-3.74-18.92c-3.8-3-6.26-5.88-3.18-11.33
                c1.45-2.57,0.47-6.57,0.44-9.93c-0.05-5.77-0.44-11.54-0.25-17.29c0.08-2.41,4.75-3.32,6.48-1.36c0.75,0.85,1.28,1.98,1.62,3.07
                c1.92,6.04,4.27,11.55,9.59,15.78c2.41,1.93,3.43,5.98,4.4,9.26c1.8,6.04,2.53,12.59,9.89,14.52c-0.17,0.81-0.19,1.38-0.31,1.41
                c-6.6,1.22-9.81,5.77-11.84,11.67c-0.47,1.35-1.49,2.82-2.68,3.55c-6.36,3.89-5.62,9.71-5.06,15.87
                c1.09,11.97,1.42,23.95-2.64,35.57c-0.22,0.63-0.42,1.28-0.47,1.94c-1.03,12.73-2.06,25.45-3.02,38.18
                c-0.07,0.92,0.33,1.88,0.52,2.86c0.57-0.18,1.1-0.25,1.11-0.36c0.82-9.06,1.98-18.12,2.24-27.2c0.2-6.89,1.96-13.32,3.24-19.96
                c2.01-10.45,1.87-21.39,1.8-32.12c-0.03-5.11-0.93-9.48,4.12-13.21c2.8-2.07,3.96-6.3,6.07-9.41c0.99-1.46,2.5-2.57,3.78-3.84
                c0.48,0.36,0.96,0.72,1.44,1.07c-2.65,8.95-5.46,17.85-7.88,26.86c-1.11,4.12-2.26,8.48-2.04,12.66
                c1.02,19.44,2.51,38.85,3.83,58.27c0.32,4.77,0.64,9.53,2.14,14.48c0.27-1.05,0.83-2.12,0.77-3.16
                c-0.63-11.45-1.45-22.9-2.04-34.35c-0.64-12.46-1.35-24.93-1.45-37.4c-0.03-3.78,1.88-7.57,2.89-11.36
                c0.59-2.24,1.12-4.49,1.73-6.72c2.35-8.63,4.71-17.27,7.12-25.88c0.2-0.72,0.9-1.3,2.18-2.12c-2.58,7.9,3.89,12.48,5.86,18.65
                c0.84,2.64,1.03,5.53,1.22,8.33c1.08,16.07,2.16,32.14,3.04,48.22c0.31,5.77,0.05,11.58,0.05,17.37
                c-2.55-1.11-5.06-2.46-7.72-3.32c-4.64-1.5-7.93,0.68-8.01,5.64c-0.05,3.26,0.81,6.57,1.47,9.81c2.18,10.75,4.81,21.43,6.55,32.25
                c0.88,5.49-0.12,11.26,0.34,16.86c0.83,10.17,2.12,20.3,3.22,30.44c0.57,0,1.13,0.01,1.7,0.01c0.23-0.92,0.75-1.88,0.64-2.77
                c-0.55-4.46-1.47-8.88-1.86-13.35c-0.47-5.43-1.18-10.96-0.62-16.33c1.33-12.68-1.09-24.86-3.84-37.03
                c-1.21-5.36-3.09-10.58-3.95-15.98c-0.34-2.15,0.81-6.28,2.03-6.59c3.19-0.82,6.75,0.21,7.88,4.22c2.05,7.35,4,14.73,5.91,22.12
                c6.37,24.54,4.75,49.9,7.65,74.8c0.41,3.52-1.48,4.31-4.11,3.67c-5.45-1.32-10.59-1.06-16.18-0.02
                c-4.37,0.81-9.35-1.17-13.93-2.42c-0.91-0.25-1.59-2.76-1.69-4.28c-0.67-9.63-1.02-19.28-1.76-28.91
                c-0.24-3.06-0.93-6.24-2.17-9.03c-2.7-6.07-3.73-12.17-3.52-18.89c0.23-7.6-0.92-15.24-1.57-22.86
                c-0.42-4.89-1.01-9.76-1.53-14.64c-0.44,0.01-0.88,0.01-1.32,0.02c0,3.69-0.07,7.38,0.01,11.06c0.22,10.13,0.35,20.26,0.82,30.38
                c0.17,3.78,0.29,7.98,1.97,11.18c3.82,7.26,4.07,14.91,4.51,22.72c0.36,6.3,1.07,12.6,1.99,18.85c0.51,3.43-0.18,5.19-3.84,6.02
                c-3.65,0.83-7.11,2.48-10.48,3.71c-2-4.99-3.74-10.01-6.03-14.77c-1.37-2.85-3.96-4.64-7.44-3.4c-3.25,1.16-5.1-0.29-7.24-2.65
                c-2.58-2.84-5.99-4.91-8.83-7.55c-0.92-0.86-1.94-2.86-1.54-3.63c1.02-1.98,2.47-4.23,4.35-5.19c4.14-2.11,8.65-3.5,13.02-5.18
                c-0.11-0.52-0.22-1.04-0.33-1.56c-1.46-0.47-2.92-0.94-4.76-1.53c0.37-1,0.73-1.98,1.22-3.31c-1.47-0.49-2.92-0.97-4.43-1.46
                c1.39-2.19,2.8-4.09,3.79-6.19c0.28-0.59-0.57-1.86-1.13-2.67c-5.52-7.98-5.54-7.97,0.52-16.18c-1.63-0.09-2.99-0.16-4.4-0.24
                c-1.61-5.96-8.57-11.76,0.65-17.41c-3.26-0.94-5.3-1.53-7.34-2.12c1.28-1.99,2.52-4,3.85-5.94c0.76-1.11,1.69-2.12,3.19-3.97
                c-4.51,0.79-7.82,1.38-11.14,1.96c-0.14-0.55-0.27-1.09-0.41-1.64c2.23-3.22,8.42-3.3,7.58-9.65c-10.2,2.98-9.71-4.71-10.52-12.61
                c-4.75,5.85-0.66,16.34-11.5,15.24c0.89,4.81-3.3,9.51,1.72,13.3L572.15,287.27z M616.43,246.13c1.14-11.58,2.29-23.17,3.43-34.75
                C615.46,222.73,614.25,234.35,616.43,246.13z"/>
            <path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M1066.8,1182.4c4.98,3.67,9.12,6.7,13.22,9.76c10.53,7.85,20.97,15.82,31.64,23.47
                c1.59,1.14,5.2,1.83,6.25,0.89c2.58-2.28,1.92-5.15-0.81-7.67c-13.2-12.2-26.16-24.65-39.45-36.73
                c-6.25-5.68-9.51-11.98-8.33-20.67c0.7-5.18,0.13-10.54,0.13-16.22c6.54,3.65,11.43,7.5,14.87,13.78
                c3.64,6.65,8.86,12.33,17.16,14.24c2.61,0.6,4.79,3.45,6.97,5.47c10.34,9.59,21.4,17.87,35.29,21.79
                c5.99,1.69,11.64,3.69,16.58-4.32c0.34,2.99,0.67,4.51,0.67,6.04c0.03,34.3-0.03,68.59,0.08,102.89c0.02,4.43-1.5,5.87-5.88,5.35
                c-28.22-3.32-56.43-6.61-83.71-15.1c-7.86-2.44-7.52-2.52-7.54-10.68c-0.06-29.59,4.86-59.04,2.31-88.69
                C1066.18,1185.25,1066.47,1184.46,1066.8,1182.4z"/>
            <path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M1160.62,246.83c0.11,1.53,0.37,3.47,0.37,5.4c0.02,36.95,0.02,73.91,0.01,110.86
                c0,7.1-1.63,8.76-8.7,7.02c-4.75-1.17-9.58-3.14-13.59-5.9c-6.62-4.55-13-4.52-19.7-1.04c-5.89,3.06-11.65,6.39-17.49,9.55
                c-1.69,0.92-3.48,1.66-5.81,2.77c-0.39-2.12-0.88-3.53-0.87-4.94c0.06-12.55-3.86-23.76-10.37-34.42
                c-2.19-3.59-3.56-8.41-3.39-12.59c0.51-12.02-2.35-23.46-4.6-35.04c-2.1-10.75-0.77-13.11,8.36-18.57
                c3.93-2.35,6.98-6.14,10.78-8.77c3.43-2.38,7.13-5.17,11.06-5.87C1124.31,252.15,1142.06,249.68,1160.62,246.83z"/>
            <path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M333.34,173.38c10.48,9.37,20.93,18.77,31.45,28.09c2.1,1.86,4.46,3.44,6.74,5.09
                c15.58,11.3,31.27,22.46,46.68,33.99c3.64,2.72,6.3,6.73,9.47,10.1c1.24,1.32,2.57,2.57,3.99,3.71
                c11.09,8.89,22.22,17.75,33.02,26.37c-9.14,9.18-17.56,17.12-25.32,25.64c-3.92,4.3-6.89,9.55-9.85,14.62
                c-1.62,2.77-3.18,3.44-5.97,2.05c-2.67-1.34-5.4-2.62-8.21-3.64c-6.13-2.23-7.03-1.66-8.15,4.6c-4.65-0.97-9.33-1.83-13.94-2.96
                c-3.87-0.95-7.49-2.33-11.48,0.3c-0.91,0.6-3.66-1.46-5.51-2.4c-4.55-2.33-8.94-5-13.63-6.98c-3.16-1.34-4.42,0.38-3.21,3.57
                c0.71,1.86,1.71,3.61,2.38,5.49c3.35,9.48,6.63,18.98,9.93,28.47c-0.46,0.25-0.93,0.49-1.39,0.74c-2-2.64-3.92-5.34-6-7.92
                c-5.54-6.85-10.6-14.19-16.85-20.32c-8.06-7.9-6.91-16.97-5.32-26.41c0.54-3.22,1.45-6.38,2.16-9.58
                c0.25-1.14,0.02-2.61,0.67-3.39c4.67-5.63,2.16-11.07-0.12-16.6c-0.5-1.21-0.67-2.58-0.9-3.89c-1.58-8.87,0.61-11.14,9.62-9.86
                c5.39,0.77,10.37,4.99,16.27,1.86c0.42-0.22,1.58,0.99,2.43,1.49c1.14,0.68,2.27,1.81,3.46,1.88
                c13.77,0.8,21.68,11.16,31.16,18.95c3.4,2.79,6.63,5.79,10.92,7.99c-0.07-0.71,0.1-1.58-0.25-2.11
                c-7.62-11.68-14.66-23.82-23.17-34.82c-6.66-8.6-15.01-15.94-22.89-23.54c-5.25-5.07-9.03-10.58-11.43-17.71
                c-1.69-5.03-6.22-9.26-9.98-13.39c-2.77-3.05-6.21-5.53-9.56-7.98c-4-2.92-6.13-6.84-7.13-11.53L333.34,173.38z"/>
            <path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M229.95,211.21c-0.74-1.24-1.7-2.41-2.2-3.74c-6.64-17.77-13.16-35.58-19.83-53.34
                c-1.51-4.02-1.36-6.56,3.71-7.25c3.64-0.49,5.48-3.31,5.44-6.77c-0.03-3.11-0.24-6.45-1.41-9.26
                c-4.14-9.99-8.65-19.82-13.14-29.65c-2.51-5.5-6.74-9.04-12.19-11.92c-5.78-3.05-11.07-7.24-16-11.58
                c-2.17-1.91-2.96-5.41-4.33-8.21c-0.58-1.18-0.71-2.76-1.59-3.58c-1.36-1.27-3.19-2.06-4.82-3.04c-0.8,1.69-2.03,3.31-2.32,5.09
                c-2.24,14.07,1.88,26.62,9.49,38.28c1.53,2.34,2.91,4.78,4.36,7.17c-0.3,0.28-0.61,0.57-0.91,0.85c-1.59-1.63-3.74-3-4.68-4.95
                c-4.22-8.81-8.18-17.74-12.06-26.71c-3.03-6.99-5.63-14.16-8.79-21.09c-0.9-1.98-2.94-3.58-4.73-4.99c-2.91-2.3-5.28-2.4-6.7,1.91
                c-0.4,1.21-2.64,2.77-3.81,2.6c-4.52-0.65-5.53,2.13-6.11,5.45c-0.69,3.89-1.17,7.81-1.84,12.42c-2.73-1.97-4.77-3.44-6.68-4.81
                c-4.08,7.02-7.9,13.6-12.26,21.12c-1.19-5.17-2.41-9.98-3.26-14.85c-0.11-0.66,1.58-2.08,2.66-2.42
                c7.82-2.46,8.21-13.61,3.22-16.8c-8.74-5.59-11.36-14.49-15.29-23.05c-2.45-5.33-6.55-9.96-10.27-14.62
                c-5.48-6.87-11.29-13.48-16.96-20.2c0.29-0.48,0.58-0.96,0.87-1.44c1.78,1.31,3.76,2.42,5.29,3.98c4.16,4.24,7.48,9.66,12.31,12.8
                c6.97,4.53,13.03,9.54,17.92,16.28c1.96,2.7,4.99,5.29,8.08,6.34c2.89,0.98,7.88,1.1,9.43-0.64c1.88-2.12,1.32-6.57,1.46-10.03
                c0.13-3.13-0.04-6.29-0.41-9.4c-0.27-2.27-0.46-4.97-1.77-6.6c-2.15-2.68-5.19-4.63-7.82-6.93c-1.54-1.35-3.01-2.78-4.51-4.18
                c0.16-0.49,0.31-0.99,0.47-1.48c3.23,0,6.45,0,9.68,0c2.83,0,5.69,0.28,8.49-0.05c9.45-1.12,17.22,0.51,21.7,10.31
                c1.26,2.76,4.93,4.34,7.15,6.77c6.02,6.59,8.94,14.74,10.98,23.23c0.86,3.55,1.88,5.91,6.55,5.59c2.29-0.16,5.01,2.2,7.13,3.91
                c5.1,4.13,10.88,3.47,13.87-2.44c1.43-2.82,1.84-6.32,2.03-9.55c0.2-3.45,1.6-4.47,4.29-2.7c2.59,1.7,5.2,3.76,6.96,6.26
                c7.69,10.91,11.63,23.5,15.4,36.12c0.3,1.02,0.11,2.37-0.29,3.39c-2.49,6.36-1.38,12.6-0.35,19.13
                c2.61,16.47,8.18,31.76,15.69,46.59c4.38,8.64,6.68,18.14,4.52,28.07c-0.27,1.25-0.58,2.99-1.46,3.53
                c-7.77,4.74-7.99,12.51-8.33,20.21c-0.16,3.55-0.03,7.11-0.03,10.66C231.28,211.05,230.61,211.13,229.95,211.21z"/>
            <path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M414.23,390.91c-0.43-2.44-0.84-4.8-1.49-8.52c-2.39,2.18-4.16,3.49-5.54,5.13
                c-4.52,5.35-9.95,8.22-17.16,8.7c-4.43,0.3-8.72,2.55-13.31,4c0-0.36-0.36-1.56,0.06-2.34c1.96-3.66,3.97-7.31,6.28-10.76
                c4.78-7.16,9.62-14.29,14.69-21.24c7.54-10.34,15.26-20.55,23.04-30.71c1.28-1.67,3.28-2.78,4.86-4.24
                c1.82-1.68,4.48-3.16,5.15-5.25c4.58-14.45,17.68-22.07,26.16-33.35c0.4-0.53,0.91-0.97,1.38-1.45
                c7.71-7.92,15.45-6.69,20.71,3.35c0.52,0.99,1.19,2.43,0.83,3.25c-2.76,6.38,1.49,9.99,5.24,13.77c1.87,1.89,4.27,3.31,5.89,5.37
                c1.44,1.83,1.85,4.46,3.23,6.35c2.86,3.9,5.83,7.78,9.24,11.2c2.02,2.03,3.88,3.6,2.7,6.71c-6.49,17.11-7.76,35.81-16.28,52.33
                c-3.46,6.7-3.24,6.82-10.92,6.02c-3.25-0.34-6.62-0.08-9.87,0.4c-4.16,0.62-7.37,0.27-9.62-4.02c-0.95-1.81-3.38-3.68-5.36-3.95
                c-9.15-1.24-18.37-2.23-27.59-2.69C422.68,388.78,418.75,390.14,414.23,390.91z M473.06,313.34c-2.68,3.53-3.24,7.54-1.91,13.58
                c0.37,1.7,0.84,3.93,0.09,5.24c-5.07,8.71-10.45,17.24-15.81,25.94c-3.88-4.03-5.12-1.68-6.12,1.91c1.69,1.36,3.31,2.67,5.1,4.1
                c0.74-0.66,1.49-1.09,1.89-1.74c5.84-9.5,11.68-18.99,17.37-28.57c0.8-1.34,1.27-3.17,1.15-4.72
                C474.39,323.74,473.65,318.43,473.06,313.34z M450.16,368.6c-0.45-0.32-0.91-0.65-1.36-0.97c-0.45,0.41-0.97,0.77-1.35,1.25
                c-2.32,2.92-4.3,7.83-7,8.26c-2.68,0.42-6.05-3.69-9.16-5.75c-2.89-1.92-5.91-2.8-9.27-1.47c5.16,3.39,9.69,6.84,14.66,9.4
                c1.88,0.97,5.81,0.86,7.05-0.44C446.47,376.02,448.09,372.09,450.16,368.6z M420.92,359.08c0.48-0.38,0.97-0.76,1.45-1.15
                c-1.4-1.8-2.8-3.61-4.2-5.41c-0.5,0.39-1.01,0.79-1.51,1.18C418.08,355.5,419.5,357.29,420.92,359.08z"/>
            <path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M843.93,873.01c-3.63-1.99-5.16-4.51-5.48-8.75c-0.97-12.84-3.41-25.19-16.16-32.35
                c-1.1-0.62-1.84-2.72-1.92-4.18c-0.61-10.96-1.54-21.95-1.28-32.9c0.14-5.95,2.49-11.83,3.69-17.78c0.88-4.37,2.1-8.77,2.19-13.18
                c0.67-33.33-0.54-66.55-6.37-99.47c-0.56-3.18-0.78-6.43-0.16-9.76c1.65,11.65,3.48,23.28,4.9,34.95
                c1.13,9.29,5.03,16.99,11.14,24.01c7.86,9.01,15.02,18.63,22.8,27.71c3.85,4.49,8.66,8.16,12.59,12.6
                c3.24,3.66,6.43,7.6,8.53,11.95c3.24,6.74,5.56,13.86,11.43,19.08c1.21,1.08,1.76,3.73,1.51,5.49c-1.5,10.5-3.03,21.01-5.12,31.4
                c-1.68,8.3-4.08,16.46-6.4,24.61c-0.43,1.52-1.87,3.81-3.02,3.93c-14.03,1.39-21.34,12.53-30.7,20.69
                C845.37,871.7,844.66,872.35,843.93,873.01z"/>
            <path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M900.38,151.31c0.55,3.69,1.46,7.36,1.56,11.06c0.13,4.8-0.66,9.62-0.58,14.43
                c0.13,7.48-1.08,14.53-4.68,21.19c-2.68,4.96-3.57,10.16-2.38,15.92c0.64,3.09,0.03,6.62-0.73,9.78c-0.84,3.48-0.54,5.83,2.6,8.16
                c2.77,2.06,4.77,5.16,6.44,7.04c-5.3,2.02-10.76,2.85-14.43,5.86c-3.58,2.94-5.32,8.11-8.06,12.61c-1.87-2.25-4.59-5.31-7.08-8.55
                c-4.15-5.38-7.93-5.26-11.51,0.57c-1.03,1.68-1.84,3.5-2.88,5.49c-5.2-2.08-8.65,0.4-11.6,4.27c-0.8,1.05-1.62,2.24-2.72,2.9
                c-10.39,6.24-13.07,16.03-11.9,27.11c2.12,20.12,2.13,40.56,9.22,59.99c1.78,4.88,1.61,10.47,2.33,15.74
                c0.04,0.33,0.06,0.67,0.01,1c-2.33,13.24-4.66,26.47-7.01,39.81c-0.7-0.63-1.62-1.85-2.85-2.5c-3.56-1.91-7.2-3.7-10.9-5.33
                c-1.96-0.86-4.23-1.05-6.15-1.98c-4.34-2.1-6.2-5.89-4.29-10.3c4.48-10.34,4.01-20.17-0.76-30.34c-1.42-3.02-1.72-7.6-0.5-10.64
                c5.71-14.25,6.33-29.17,7.09-44.14c0.32-6.32,1.07-12.62,1.08-18.93c0.01-2.58-1.83-5.13-2.05-7.76
                c-0.16-1.96,0.29-4.66,1.56-5.93c8.47-8.39,12.78-19.1,16.52-29.91c3.74-10.81,6.6-21.98,9.11-33.16
                c2-8.92,5.69-15.93,13.99-20.66c7.17-4.09,13.72-9.29,20.42-14.17c1.83-1.34,3.2-3.31,4.81-4.97c4.7-4.82,9.42-9.62,14.14-14.43
                C898.92,150.8,899.65,151.06,900.38,151.31z"/>
            <path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M1032.5,1309.63c0.62,1.74,1.23,3.48,1.85,5.22c1.74-0.76,3.84-1.14,5.14-2.37
                c3.83-3.62,3.51-8.65-0.36-12.4c-1.04-1-1.89-2.2-3.45-4.05c0,2.87,0,4.85,0,7.41c-0.85-1.62-1.45-2.78-2.45-4.69
                c-3.04,12.49-12.31,17.14-22.34,21.09c-5.57,2.19-10.93,4.91-16.44,7.25c-1.46,0.62-3.13,0.72-5,1.13
                c0.17-1.22,0.1-1.71,0.27-1.82c9.57-5.96,19.06-12.08,28.79-17.78c8.13-4.76,14.03-10.38,14.65-20.78
                c1.4-23.42,3.47-46.8,5.41-70.19c0.58-7.01-2.14-14.84,6.1-20.12c-0.64-0.35-1.28-0.69-1.92-1.04c-1.43,1.08-2.81,2.24-4.29,3.24
                c-4.39,2.95-8.75,5.96-13.3,8.66c-0.93,0.55-2.62-0.2-3.97-0.34c0.51-1.38,0.67-3.1,1.6-4.09c5.23-5.56,10.65-10.94,15.99-16.41
                c4.63-4.74,6.06-10.55,5.69-16.98c-0.32-5.49-1.06-11.01-0.72-16.45c0.29-4.49,1.78-8.92,3.01-13.3c0.31-1.11,1.65-2.68,2.59-2.74
                c6.46-0.39,8.79-5.3,11.49-9.9c0.39-0.67,1.01-1.21,2.56-3.02c1.09,3.47,2.47,5.95,2.54,8.46c0.18,6.65,0.1,13.33-0.46,19.95
                c-0.6,7.03,1.47,12.76,6.55,17.53c13.34,12.54,26.68,25.07,40.01,37.62c1.45,1.37,2.79,2.85,4.19,4.28
                c-0.35,0.41-0.69,0.83-1.04,1.24c-1.23-0.82-2.51-1.58-3.69-2.47c-7.95-6.02-15.95-11.99-23.76-18.19
                c-1.84-1.46-2.76-4.03-4.53-5.61c-3.52-3.13-7.14-7.97-11.1-8.36c-5.11-0.51-4.63-3.89-6.19-6.39c-1.53,0.94-2.94,1.8-4.34,2.66
                c-0.35-0.99-1.15-2.09-0.96-2.96c0.4-1.84,1.32-3.56,2.03-5.33c-0.42-0.36-0.83-0.73-1.25-1.09c-1.49,1.36-4.17,2.63-4.27,4.08
                c-0.39,5.58-0.04,11.22,0.22,16.83c0.05,1.16,0.88,2.29,2.53,3.38c0.31-1.67,0.62-3.35,0.92-5.02c0.35-0.02,0.7-0.04,1.05-0.06
                c0.2,1.31,0.54,2.62,0.57,3.94c0.27,15.14,0.95,30.3,0.6,45.43c-0.3,12.94-2.11,25.84-2.66,38.79
                c-0.39,9.27,1.23,18.71,0.06,27.84c-1.31,10.17-3,20.72-10.41,28.94c-0.66,0.73-1.2,1.6-1.66,2.47c-0.2,0.38-0.08,0.94-0.18,2.91
                c8.79-6.43,11.48-14.81,13.98-23.82c0,4.87,0.13,9.75-0.02,14.62c-0.64,20.12-1.79,40.24-1.89,60.37
                c-0.06,11.57,1.46,23.16,2.41,34.73c0.35,4.25-1.41,5.62-10.03,6.46c2.1-13.3,4.17-26.35,6.23-39.39
                c-0.64-0.16-1.29-0.32-1.93-0.47c-0.52,1.54-1.28,3.05-1.52,4.63c-1.62,10.82-3.12,21.65-4.71,32.47
                c-0.24,1.63-0.39,3.53-1.33,4.71c-1.04,1.31-2.85,2.33-4.49,2.66c-0.66,0.13-2.16-1.78-2.58-3c-2.46-7.06-6.89-12.54-13.15-16.45
                c-2.77-1.73-3.58-3.72-3.33-6.98c1.69-22.05,3.35-44.12,4.57-66.2c0.42-7.61-0.08-15.33-0.82-22.93c-0.33-3.46,0.26-5.81,3.02-7.7
                L1032.5,1309.63z M1054.58,1217.66c-0.45,1.42-0.91,2.84-1.6,5c4.88-3.3,5.04-3.83,2.49-5.94c0.65-3.02,1.3-6.05,1.96-9.07
                l0.08,0.06c1.27-3.82,2.53-7.65,3.8-11.47c-0.61-0.24-1.22-0.47-1.83-0.71c-2.53,5.27-5.42,10.42-7.36,15.89
                C1051.56,1213.01,1053.68,1215.56,1054.58,1217.66z M1058.49,1266.23c-1,0.66-1.57,0.83-1.71,1.17
                c-3.97,9.25-9.68,17.77-7.79,28.93c0.98,5.8,1.19,7.54,8.11,8.06c0-1.74,0.47-3.65-0.09-5.18c-1.42-3.84-3.77-7.37-4.82-11.28
                c-0.57-2.12,0.12-5.15,1.34-7.06C1056.28,1276.62,1058.75,1272.44,1058.49,1266.23z M1042.93,1422.35
                c2.87-6.56,5.79-12.92,8.42-19.39c1.29-3.18,3.04-6.5,0.5-10.18C1048.68,1402.67,1041.38,1411.32,1042.93,1422.35z
                M1044.95,1184.16c0,5.86,0,11.72,0,17.53C1051.45,1198.44,1051.95,1191.42,1044.95,1184.16z M1039.09,1318.59
                c-7.53,4.75-10.63,17.47-5.85,24.37c0.78-3.89,0.7-7.93,1.54-11.76C1035.68,1327.12,1037.46,1323.23,1039.09,1318.59z
                M1053.25,1346.11c-0.46-0.16-0.92-0.32-1.39-0.48c-4.6,4.32-7.27,13.79-3.99,17.93C1049.8,1357.3,1051.52,1351.7,1053.25,1346.11
                z M1044.26,1215.16c-5.25,3.54-5.95,10.65-1.43,13.51C1043.3,1224.18,1043.78,1219.72,1044.26,1215.16z M1062.74,1136.9
                c-0.44-0.27-0.88-0.54-1.32-0.8c-1.78,3.04-3.56,6.07-5.87,9.99C1062.45,1145.2,1062.91,1141.26,1062.74,1136.9z M1051.84,1238.41
                c0.59,0.16,1.17,0.32,1.76,0.48c1.29-3.82,2.59-7.65,3.89-11.47c-0.43-0.15-0.87-0.3-1.3-0.45
                C1053.29,1230.25,1051.25,1233.85,1051.84,1238.41z M1051.09,1370.03c0.72-4.38,1.35-8.17,2.11-12.77
                C1048.41,1361.6,1047.69,1365.87,1051.09,1370.03z M1052.8,1338.57c-0.68-0.4-1.36-0.8-2.04-1.2c-2.04,3.72-4.07,7.44-6.11,11.16
                c0.68,0.37,1.36,0.75,2.05,1.12C1048.73,1345.96,1050.76,1342.27,1052.8,1338.57z M1063.07,1158.75
                c-0.52-0.42-1.03-0.85-1.55-1.27c-1.62,1.89-3.24,3.78-4.86,5.67c0.5,0.43,1.01,0.86,1.51,1.28
                C1059.81,1162.54,1061.44,1160.64,1063.07,1158.75z M1064.06,1148c-0.48-0.44-0.97-0.88-1.45-1.31c-1.83,1.63-3.66,3.25-5.49,4.88
                c0.59,0.59,1.19,1.18,1.78,1.77C1060.62,1151.56,1062.34,1149.78,1064.06,1148z M1056.05,1251.27c-0.6-0.08-1.19-0.16-1.79-0.24
                c-0.11,1.57-0.22,3.15-0.33,4.72c0.53,0.03,1.06,0.06,1.59,0.09C1055.69,1254.32,1055.87,1252.79,1056.05,1251.27z
                M1056.14,1372.06c-0.36,0.02-0.71,0.03-1.07,0.05c0,1.58,0,3.16,0,4.74c0.36-0.01,0.71-0.02,1.07-0.03
                C1056.14,1375.24,1056.14,1373.65,1056.14,1372.06z M1045.92,1205.19c-0.36-0.48-0.72-0.97-1.08-1.45
                c-1.26,0.99-2.53,1.98-3.79,2.97c0.33,0.43,0.67,0.87,1,1.3C1043.34,1207.07,1044.63,1206.13,1045.92,1205.19z"/>
            <path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M2.08,1060.3c0-5.66,0.11-11.32-0.05-16.98c-0.11-3.77,1.12-5.63,5.19-5.17c4.46,0.5,9.06,0.21,13.41,1.14
                c16.89,3.62,33.8,7.23,50.52,11.54c10.06,2.6,18.33,8.73,26.84,14.8c9.6,6.85,20.99,11.21,31.61,16.63
                c6.16,3.14,12.78,5.62,18.4,9.54c5.23,3.64,4.26,5.83-0.9,9.46c-2.88,2.03-5.4,5.32-6.76,8.59c-2.34,5.61-5.5,9.4-11.89,9.63
                c-0.5,0.02-1.09-0.09-1.48,0.14c-12.89,7.34-23.06-1.38-33.47-6.26c-8.27-3.87-15.92-7.78-25.54-6.24
                c-3.7,0.59-8.06-0.62-11.65-2.12c-17.34-7.22-34.55-14.76-51.69-22.44c-1.3-0.58-2.33-3.12-2.42-4.81
                C1.9,1071.96,2.08,1066.13,2.08,1060.3z"/>
            <path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M753.82,1174.48c-0.21-2.42-0.02-4.15-0.57-5.6c-1.02-2.73-1.81-6.15-3.87-7.78
                c-6.23-4.9-12.78-9.51-19.66-13.42c-6.48-3.69-10.78-9.08-15.09-14.66c0.14-0.53,0.29-1.06,0.43-1.59
                c2.95,0.16,6.14-0.35,8.79,0.63c4.04,1.5,6.64,0.4,9.11-2.64c6.3-7.75,12.49-15.6,19.09-23.08c2.58-2.92,5.84-5.63,9.34-7.25
                c7.67-3.54,15.69-6.35,23.58-9.4c5.43-2.1,11.11-3.68,16.28-6.28c8.1-4.08,16.32-4.23,25.19-3.81
                c10.55,0.51,17.6,6.96,26.67,12.43c-2.02,1.91-3.54,4.16-5.66,5.22c-8.78,4.39-15.73,11-23.05,17.31
                c-2.78,2.4-7.83,4.17-11.26,3.46c-17.14-3.59-24.02,3.68-31.54,16.52c-3.89,6.64-8.62,10.68-15.97,12.14
                c-2.77,0.55-5.6,1.18-8.15,2.33c-5.84,2.63-10.13,0.93-14.05-3.75c-2.66-3.17-5.81-5.95-8.88-8.74c-1.23-1.12-2.86-1.81-4.31-2.69
                c-0.26,0.38-0.53,0.77-0.79,1.15c6.12,5.62,12.08,11.44,18.49,16.71c1.39,1.15,4.51,0.8,6.64,0.32
                c5.51-1.25,10.91-2.96,16.36-4.48c5.25-1.47,8.99-4.72,11.78-9.39c2.21-3.69,4.75-7.21,7.35-10.65
                c4.94-6.51,11.58-8.74,19.46-6.96c7.66,1.73,13.71-0.19,19.45-5.83c5.15-5.06,11.65-8.72,17.29-13.32
                c3.37-2.75,6.45-5.92,9.26-9.24c2.52-2.97,4.93-2.22,7.37-0.46c3.34,2.4,7.16,16.36,5.66,20.06c-0.18,0.44-0.45,1.08-0.81,1.19
                c-7.41,2.16-11.47,9.59-19.58,11.36c-8.24,1.79-14.87,7.19-17.92,15.93c-0.5,1.44-2.68,3.08-4.22,3.23
                c-4.92,0.46-9.93,0.6-14.86,0.23c-10.1-0.74-19.45,0.36-27.65,7.24C774.1,1158.81,764.29,1166.21,753.82,1174.48z"/>
            <path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M293.84,1367.27c-6.31-9.43-9.86-18.32-10.61-29c-0.91-12.87-7.68-23.92-16.53-33.39
                c-2.5-2.67-5.33-5.02-7.87-7.66c-2.53-2.64-5.39-5.15-7.15-8.27c-1.71-3.02-3.51-7.08-2.73-10.01c0.46-1.72,5.82-2.25,9.04-3.09
                c1.75-0.45,3.65-0.3,5.44-0.64c7.06-1.3,8.57-3.61,7.19-10.68c-0.32-1.63-0.68-3.27-0.83-4.92c-0.71-7.66,0.87-9.16,8.55-9.3
                c6.29-0.12,12.64-0.27,18.81-1.33c2.98-0.51,5.61-3.12,8.27-5.49c4.98,1.03,9.62,3.32,12.98,8.13
                c4.17,5.97,8.29,12.14,13.42,17.24c8.07,8.03,12.13,17.99,14.5,28.51c1.14,5.05,0.33,11.21-1.52,16.15
                c-4.44,11.79-11.32,21.96-23.98,26.61c-1.39,0.51-3.08,0.83-4.03,1.82c-7.44,7.72-17.45,13.06-21.96,23.51
                C294.63,1365.93,294.35,1366.35,293.84,1367.27z"/>
            <path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M1084.48,1002.89c2.56,1.81,5.12,3.63,7.69,5.43c4.01,2.8,8.07,5.19,13.39,4.14
                c1.36-0.27,3.33,0.54,4.45,1.51c6.73,5.79,15.24,7.29,23.33,9.52c2.22,0.61,5.22-1.57,7.86-2.47c-0.15-0.44-0.29-0.88-0.44-1.32
                c6.03,1.57,12.09,3.06,18.08,4.79c0.85,0.24,1.71,1.54,1.96,2.51c0.36,1.42,0.21,2.97,0.21,4.47
                c0.01,47.11,0.01,94.23-0.02,141.34c0,2.05-0.34,4.1-0.66,7.72c-12.8-13.26-20.61-28-28.64-42.8c1.85,1.28,3.7,2.56,6.89,4.77
                c-0.46-3.32-0.29-5.34-1.03-6.92c-5.06-10.81-10.03-21.68-15.59-32.22c-3.95-7.49-8.87-14.46-13.18-21.77
                c-1.56-2.64-3.21-5.29,0.95-7.91c2.03-1.28,2.76-4.62,4.8-8.34c-0.48-0.48-1.91-2.12-3.56-3.52
                c-15.32-12.97-29.58-26.86-39.44-44.64c-0.78-1.41-1.46-2.89-2.89-5.75c2.83,0.62,4.91,0.43,5.83,1.37
                c7.66,7.86,17.4,12.08,27.26,16.17c2.96,1.23,5.92,2.45,8.87,3.67c0.29-0.44,0.58-0.87,0.88-1.31c-1.1-1.25-2.12-2.58-3.31-3.74
                c-6.55-6.39-13.21-12.67-19.68-19.14c-1.59-1.59-2.64-3.72-3.94-5.61C1084.53,1002.83,1084.48,1002.89,1084.48,1002.89z"/>
            <path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M973.71,1080.86c-0.73-0.66-1.36-1.57-2.22-1.94c-9.71-4.27-24.85-0.67-31.13,7.89
                c-4.44,6.07-9.96,9.4-17.11,11.88c-7.67,2.66-14.62,7.6-21.58,12.02c-4.18,2.65-7.7,6.32-11.72,9.26
                c-0.91,0.66-2.82,0.62-3.98,0.17c-4.02-1.58-8.05-3.24-11.77-5.41c-1.34-0.78-2.28-2.98-2.59-4.68c-0.81-4.4-0.71-9-1.81-13.3
                c-1.94-7.53-6.28-10.1-13.91-9.87c-2.47,0.07-5.26-0.69-7.4-1.93c-3.55-2.06-6.7-4.81-10.88-7.91
                c6.01-7.78,11.46-15.48,17.61-22.57c3.81-4.39,7.39-8.05,6.05-14.72c-0.96-4.79,1.99-8.8,5.51-12.31
                c9.92,9.11,21.67,13.9,35.49,17.94c-4.77,8.82-9.23,17.05-13.68,25.3c-3.47,6.42-7.11,12.75-10.26,19.32
                c-1.03,2.14-0.78,4.89-1.11,7.36c0.43,0.17,0.87,0.33,1.3,0.5c0.84-0.61,1.77-1.13,2.52-1.83c5.3-4.98,10.47-10.09,15.87-14.96
                c4.55-4.11,8.87-8.89,14.19-11.63c5.41-2.8,9.93-6.27,13.15-11.04c4.94-7.33,10.94-12.53,19.79-14.37
                c2.51-0.52,4.67-2.63,7.18-3.4c1.71-0.52,3.75,0.11,5.65,0.1c1.9-0.01,3.89,0.18,5.67-0.33c6.44-1.85,12.79-3.98,19.21-5.92
                c0.68-0.2,1.6,0.01,2.29,0.3c2.34,1,4.62,2.12,7.46,3.44c-0.62,0.92-1.85,2.1-2.28,3.52c-0.49,1.6-0.31,3.42-0.32,5.15
                c-0.01,0.91,0.59,2.02,0.25,2.71c-5.09,10.43-10.3,20.81-15.48,31.2L973.71,1080.86z"/>
            <path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M814.31,250.57c-0.35-2.86-0.8-5.72-1.03-8.59c-0.75-9.75-1.22-19.52-2.19-29.25
                c-0.49-4.9-2.02-9.7-2.67-14.6c-0.19-1.42,1.03-3.03,1.6-4.55c1.11,1.15,2.67,2.11,3.25,3.48c1.75,4.12,3.16,8.38,4.62,12.61
                c2.12,6.12,4.3,12.13,3.14,18.94c-0.61,3.58,0.28,7.41,1.34,11.03c0-9.58,0-19.16,0-29.61c2.41-1.63,4.68-0.55,6.59,2.83
                c1.77,3.13,4.06,5.97,5.73,9.15c0.82,1.57,1.45,3.82,0.97,5.4c-4.8,15.9-10.45,32.25-23.33,42.78
                c-13.17,10.77-16.93,24.71-20.91,39.48c-2.46,9.15-5.51,18.15-8.6,27.11c-0.62,1.81-2.3,3.94-3.99,4.57
                c-5.77,2.15-9.77,6.18-11.74,11.58c-4.74,12.94-8.76,26.13-13.28,39.15c-1,2.88-2.34,5.94-4.38,8.09
                c-3.18,3.34-6.06,1.91-8.67-1.76c-6.95-9.78-4.89-19.17-0.5-29.11c2.87-6.51,4.48-13.56,6.92-20.28
                c2.59-7.13,6.03-13.99,8.11-21.26c1.66-5.78,2.44-11.98,2.5-18.01c0.06-6.59,0.77-12.27,4.41-18.34
                c4.53-7.57,6.74-16.53,9.88-24.92c1.22-3.27,1.81-6.86,3.48-9.85c5.07-9.05,9.06-18.42,11.65-28.51c0.72-2.81,3.87-7.12,5.78-7.06
                c4.23,0.13,8.63,2.11,10.45,7.09c2.55,7.01,5.34,13.93,8.07,20.87c0.28,0.71,0.82,1.31,1.23,1.96
                C813.27,250.85,813.79,250.71,814.31,250.57z M819.24,251.11c0.6-0.14,1.2-0.28,1.79-0.42c-1.55-7.76-3.1-15.51-4.65-23.27
                c-0.42,0.08-0.84,0.15-1.26,0.23C816.49,235.47,817.87,243.29,819.24,251.11z"/>
            <path onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M663.72,1222.23c-2.66-11.6-9.55-14.63-18.95-15.9c-9.07-1.23-17.85-4.61-27.56-7.27
                c1.08-1.71,1.93-3.16,2.89-4.54c5.81-8.31,11.41-16.78,17.57-24.83c3.07-4,6.64-8.1,10.9-10.61
                c10.51-6.19,19.29-14.44,26.69-23.65c10.48-13.06,22.33-9.54,34.53-4.63c1.33,0.53,2.18,2.4,3.1,3.76
                c4.89,7.25,10.42,13.51,18.69,17.43c5.9,2.79,11.09,7.21,16.29,11.31c1.49,1.17,2.26,3.64,2.69,5.66
                c0.69,3.24-0.58,4.28-4.19,3.74c-8.8-1.3-17.67-2.31-26.54-2.77c-2.48-0.13-5.67,1.43-7.53,3.25
                c-13.06,12.76-28.09,23.51-39,38.47C670.82,1215.05,667.64,1217.95,663.72,1222.23z"/>
            <path onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M973.64,1080.78c-1.82,3.35-3.87,6.61-5.41,10.09c-1.51,3.39-2.31,7.1-3.79,10.51
                c-1.04,2.39-2.63,4.56-4.06,6.77c-3.88,5.99-7.99,11.84-11.66,17.95c-6.93,11.52-13.7,23.15-20.38,34.82
                c-1.75,3.06-2.78,6.51-4.35,9.68c-0.64,1.29-1.65,2.53-2.79,3.4c-5.53,4.2-11.16,8.28-16.76,12.39c-4.26,3.12-8.55,6.2-11.87,8.61
                c-7.37-5.79-14.66-10.9-21.2-16.85c-4.99-4.53-9.18-10-13.34-15.35c-0.73-0.94-0.15-4.11,0.89-5c7.06-5.99,14.5-11.53,21.62-17.45
                c2.8-2.33,6.63-4.95,7.32-8.04c2.8-12.45,13.07-17.63,22.11-23.65c5.68-3.78,12.89-5.19,19.07-8.34c4.15-2.12,8.78-4.7,11.29-8.38
                c5.61-8.2,12.8-11.61,22.43-11.01c3.64,0.23,7.31-0.03,10.97-0.06C973.71,1080.86,973.64,1080.78,973.64,1080.78z"/>
            <path onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M664.92,1223.54c15.69-15.26,31.39-30.51,47.04-45.8c3.7-3.62,7.88-4.4,13.09-4.45
                c11.37-0.13,21.98,2.5,32.55,6.12c2.25,0.77,4.92,0.38,7.39,0.42c2.83,0.05,5.66-0.14,8.48,0.01c9.95,0.52,10.92,1.61,10.91,11.66
                c0,1.32-0.12,2.76,0.31,3.96c2.24,6.18-0.21,11.25-4.09,15.58c-2.73,3.04-6.05,5.8-9.6,7.8c-10.21,5.74-18.45,13.56-25.71,22.6
                c-4.32,5.38-9.93,6.39-16.26,3.8c-7.04-2.88-14.33-5.13-21.41-7.92c-4.78-1.88-9.61-3.83-14.02-6.43
                c-5.97-3.51-12.1-5.14-19.02-4.58c-3.11,0.25-6.31-0.53-9.47-0.84C665.06,1224.83,664.99,1224.18,664.92,1223.54z"/>
            <path onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="M1132.71,1131.61c-2.22,1.36-4.01,2.45-6.29,3.85c-1.41-1.15-2.88-2.77-4.69-3.74
                c-2.03-1.09-4.37-1.57-6.56-2.36c-2.29-0.82-4.65-1.49-6.81-2.57c-15.89-7.96-24.77-22.1-32.53-37.2
                c-0.61-1.18-1.18-2.39-1.77-3.58c-2.91-5.9-3.22-5.94-7.79-0.54c-1.49-1.1-3.14-2.1-4.54-3.38c-8.2-7.5-12.83-17.23-17.16-27.22
                c-2.09-4.81-4.99-9.27-7.46-13.92c-0.61-1.15-1.42-2.49-1.33-3.68c0.67-9.06,1.52-18.12,2.39-27.17c0.09-0.96,0.87-1.86,0.95-2.82
                c0.63-6.86,3.37-12.49,10.33-16.54c0.8,0.97,2.33,2.2,3.05,3.8c2.75,6.13,5.56,12.11,11.68,15.75c0.9,0.54,1.54,1.75,2,2.78
                c8.78,19.26,23.28,33.86,38.74,47.67c4.21,3.76,4.12,6.9,0.12,11.22c-1.16,1.25-1.8,4.09-1.21,5.63c1.84,4.78,4.18,9.4,6.7,13.87
                c6.78,12,13.78,23.89,20.63,35.85C1131.88,1128.57,1132.17,1130.09,1132.71,1131.61z"/>
            <path onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="M710.71,358.01c-7.36,3.62-14.71,7.25-22.1,10.88c-0.48-0.55-1.02-0.87-1.09-1.29
                c-2.98-15.81-8.65-30.98-9.56-47.34c-0.59-10.69-3.63-21.28-5.99-31.82c-0.8-3.58-3.78-6.78-4.16-10.33
                c-0.94-8.77,2.71-10.92,10.59-6.97c2.39,1.2,4.82,2.32,7.23,3.48c0.38-0.62,0.76-1.23,1.14-1.85c-1.93-1.45-3.7-3.19-5.82-4.27
                c-3.04-1.55-4.2-3.09-2.62-6.66c0.77-1.74,0.4-4.71-0.64-6.41c-4.17-6.85-8.8-13.41-14.08-19.97c1.33,3.25,2.34,6.69,4.07,9.71
                c1.8,3.16,4.37,5.86,6.46,8.86c4.48,6.43,2.77,10.96-4.56,13.26c-1.56,0.49-3.4,2.3-3.81,3.85c-1.47,5.58-1.84,11.14,2.47,16.03
                c1.12,1.27,1.6,3.3,1.9,5.06c1.92,11.3,3.44,22.67,5.61,33.92c2.57,13.37,4.98,26.83,8.81,39.85c1.69,5.75-0.18,8.15-4.06,10.7
                c-1.33,0.88-2.9,1.38-5.11,2.4c-0.45-2.66-0.99-4.68-1.09-6.71c-0.72-14.46-1.31-28.92-2.02-43.38c-0.12-2.48-0.34-5.02-1-7.39
                c-4.9-17.58-6.83-35.46-7.1-53.72c-0.2-13.09-2.34-26.15-3.56-39.22c-0.26-2.81-0.74-5.69-0.43-8.46
                c1.04-9.25,0.07-17.94-5.76-25.61c-0.35-0.46-0.32-1.21-0.49-1.92c5.14,0.9,8.38,5.67,8.66,11.97c0.34,7.78,0.9,15.55,1.4,23.33
                c0.05,0.72,0.32,1.42,1.7,2.06c0-4.65,0.03-9.29-0.01-13.94c-0.02-2.32-0.65-4.73-0.21-6.94c1.26-6.31-2.07-11.21-4.5-16.42
                c-0.27-0.58-0.72-1.35-1.23-1.49c-7.99-2.2-4.21-8.67-5.93-14.4c6.77,2.39,8.76,7.54,11.27,11.97c3.42,6.04,6.34,12.36,9.55,18.52
                c0.42,0.81,1.28,1.88,2.03,1.97c6.97,0.76,7.61,6.4,8.27,11.34c1.23,9.23,1.79,18.55,2.58,27.83c0.79,9.24,3.96,17.72,7.29,26.31
                c2.85,7.34,4.03,15.32,5.94,23.03c1.47,5.93,3.1,11.83,4.34,17.8c1.89,9.11,3.67,18.25,5.02,27.44c0.16,1.08-2.6,2.67-4.12,3.88
                c-1.77,1.39-3.66,2.62-5.5,3.92c2.01,1.54,3.92,3.23,6.06,4.57c1.12,0.7,2.62,0.79,3.96,1.16
                C710.57,357.09,710.64,357.55,710.71,358.01z"/>
            <path onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="M921.3,530.97c-1.96-2.34-4.12-4.56-5.85-7.06c-3.89-5.61-7.66-11.3-11.31-17.07
                c-4.05-6.41-10.53-7.14-17.08-7.3c-3.45-0.09-3.1,2.96-1.95,4.71c3.54,5.34,7.38,10.49,11.33,15.54
                c3.02,3.87,6.09,7.79,9.68,11.09c3.69,3.4,7.72,6.7,12.15,8.94c7.62,3.85,7.67,3.45,5.34,11.42c-2.57,8.78-5.06,17.59-7.21,26.48
                c-0.96,3.96-2.99,5.21-6.37,3.57c-3.25-1.57-6.59-3.38-9.13-5.86c-3.91-3.81-7.4-8.13-10.67-12.52
                c-4.04-5.43-4.58-11.54-0.98-17.32c2.37-3.82,2.43-7.41,0.11-10.72c-4-5.7-8.47-11.08-12.81-16.53c-0.47-0.59-1.41-1.15-2.14-1.15
                c-6.33-0.05-7.82-4.48-8.98-9.47c-0.6-2.57-1.31-5.24-2.62-7.48c-1.9-3.28-0.75-4.69,2.27-6.04c7.15-3.19,7.93-6.2,3.59-12.94
                c-0.72-1.11-1.57-2.15-2.45-3.15c-8.74-10.02-7.77-21.52,2.44-29.95c1.63-1.34,2.98-3.03,5.26-5.39c0.17,3.32,0.3,5.7,0.42,8.08
                c0.4,8.07,3.76,13.65,11.82,16.67c4.69,1.76,8.95,5,12.95,8.14c5.88,4.61,11.46,9.62,16.98,14.67c4.89,4.47,9.74,9.02,14.11,13.98
                c1.23,1.4,1.5,4.61,0.85,6.53c-2.28,6.7-5.16,13.19-7.81,19.76C922.59,530.7,921.94,530.83,921.3,530.97z"/>
            <path onClick={() => props.onFill(43)} fill={props.fillColors[43]} d="M316.75,951.81c-19.44-2.84-19.62-2.71-23.26,14.81c-4.3,0.74-9.33,0.24-12.18,2.49
                c-2.83,2.23-3.46,7.24-5.26,11.51c-4.86-2.66-10.39-3.77-13.9,4.21c-1.74-2.16-2.88-3.54-3.98-4.96
                c-6.91-8.94-18.73-13.26-28.32-8.89c-2.48,1.13-3.61,0.15-4.44-2.18c-3.27-9.14,2.38-16.34,12.05-14.93
                c4.21,0.61,8.3,2.02,13.49,3.34c-0.96-2.4-1.64-4.12-2.33-5.84c-3.55-8.9,0.65-15.81,10.25-16.41c3.28-0.2,6.62,0.62,9.93,0.55
                c1.32-0.03,3.39-0.86,3.8-1.87c4.07-9.92,5.5-10.53,14.63-5.61c0.38,0.21,0.94,0.08,2.17,0.15c-0.45-1.96-0.79-3.64-1.22-5.31
                c-2.91-11.17,1.09-18.01,12.29-20.99c14.46-3.85,28.01,2.76,32.28,15.97c0.52,1.6,0.45,4.52-0.55,5.31
                C322.98,930.45,317.14,939.49,316.75,951.81z"/>
            <path onClick={() => props.onFill(44)} fill={props.fillColors[44]} d="M645.14,386.22c-6.69,5.15-13.38,10.28-20.06,15.45c-15.67,12.12-29.52,25.55-33.26,46.27
                c-0.17,0.94-0.73,1.84-1.23,2.69c-4.71,8.01-10.02,15.74-14.01,24.09c-3.4,7.12-5.27,14.96-7.86,22.47
                c-2.39,6.93-4.85,13.84-7.27,20.76c-0.87-0.21-1.74-0.42-2.61-0.63c0.47-4.12,0.84-8.25,1.44-12.35
                c0.53-3.61,1.85-7.17,1.89-10.77c0.19-16.79,0.18-33.52-3.17-50.2c-1.48-7.37,0.35-15.4,0.66-23.14c0.05-1.13,0.15-2.29-0.03-3.4
                c-0.65-4.11-1.47-8.18-2.1-12.29c-0.87-5.75,1.09-7.84,6.43-5.39c10.6,4.85,18.18-1.8,26.74-5.54c7.33-3.21,14.53-7.05,23.24-5.26
                c3.1,0.63,6.99-0.07,9.89-1.46c6.76-3.24,13.64-3.18,20.65-2.94C644.71,385.12,644.92,385.67,645.14,386.22z"/>
            <path onClick={() => props.onFill(45)} fill={props.fillColors[45]} d="M483.1,201.45c2.18,6.95,5.32,13.76,6.37,20.88c3.92,26.66,7.15,53.41,10.64,80.13
                c0.02,0.16,0.14,0.37,0.08,0.49c-2.81,5.68,0.25,11.07,0.71,16.59c0.07,0.81-0.8,1.69,0.18-0.57c-4.57-3.3-9.29-6.4-13.65-9.97
                c-3.25-2.66-7.1-5.41-3.76-10.77c0.59-0.94-0.35-3.29-1.2-4.58c-1.43-2.2-3.12-4.35-5.11-6.05
                c-12.25-10.46-24.02-21.63-37.13-30.88c-9.38-6.63-15.19-16.54-24.9-22.47c0.14-0.45,0.27-0.89,0.41-1.34
                c1.96,0,3.97-0.29,5.87,0.06c4.58,0.83,8.77,2.02,12.95-2.01c2.97-2.86,6.27-0.53,8.9,6.3c-3.48,0.99-6.91,1.89-10.29,2.98
                c-1.16,0.37-2.91,1.08-3.05,1.86c-0.17,0.98,0.8,2.83,1.72,3.21c3.76,1.54,7.69,2.66,13.49,4.57c-2.37,0.98-4.29,1.77-6.21,2.56
                c5.14,1.26,7.38-0.53,11.19-7.47c-5.57,0-10.63,0-15.68,0c-0.07-0.48-0.15-0.96-0.22-1.44c4.57-2.23,9.14-4.46,13.95-6.8
                c-8.1-11.07,4.17-12.79,8.19-19.09c-4-0.31-7-0.55-9.98-0.78c0.96-2.4,2.26-4.33,2.37-6.33c0.1-1.87-1.07-3.8-1.81-6.12
                c1.04-1.88,2.79-4.44,3.91-7.26c0.88-2.23,5.5-5.27-1.49-6.69c1.41-1.19,2.21-1.87,1.97-1.67c7.9,5.77,15.29,11.17,23.17,16.92
                c-4.7,0.62-9.3,1.22-13.91,1.83c-0.54,5.66,3.44,9.84,13.26,13.74c-1.05,1.18-1.89,2.69-3.17,3.46c-3.85,2.29-4.12,3.62-0.78,6.52
                c1,0.87,2.19,1.5,3.29,2.25c0.93,0.64,1.85,1.31,4.03,2.86c-5.88,1.62-10.67,2.94-16.02,4.41c2.19,5.02,6.26,8.34,11.07,11.07
                c2.45,1.39,7.25,2.46,1.64,6.17c-0.39,0.26-0.62,1.31-0.44,1.8c2.19,5.7,0.51,12.98,6.86,16.99c0.27,0.17,0.56,0.42,0.69,0.71
                c0.73,1.57,2.05,3.24,1.87,4.73c-0.1,0.88-2.59,1.48-4.31,2.36c1.03,2.71,3.91,4.21,5.88,2.46c1.62-1.44,2.42-5.58,1.53-7.63
                c-1.37-3.17-4.5-5.58-7.06-8.52c1.75-1.72,5.46-2.82,1.65-5.64c1.46-0.89,2.66-1.62,4.49-2.74c-1.77-0.75-2.89-1.22-4.31-1.82
                c1.39-1.04,2.69-2.01,4.43-3.31c-5.86-4.19-11.3-8.08-17.54-12.54c2.85-0.19,4.74-0.31,6.62-0.43c-0.22-0.34-0.45-0.67-0.67-1.01
                c3.39-2.88,6.78-5.76,10.38-8.82c-4.4-1.74-8.21-3.26-12.55-4.98c4.75-3.85,8.6-6.96,12.44-10.07c-0.12-0.59-0.23-1.17-0.35-1.76
                c-7.35,0.74-14.46,0.89-19.24-6.47c6.33-3.79,15.73-1.08,18.65-9.97L483.1,201.45z M454.15,245.13
                c-3.78,5.22-2.51,10.07,3.61,15.87c3.72,3.52,11.97,4.83,14.96,2.45c-4.65-5.42-9.29-10.84-13.94-16.26
                c0.1,2.17,0.65,4.2,1.48,6.1c0.87,2,2.01,3.88,3.21,6.15C457.97,259.07,455.02,254.34,454.15,245.13z M468.34,234.7
                c0.07-0.41,0.15-0.82,0.22-1.23c-3.02-3.64-6.03-7.28-9.72-11.73c0.33,3.23,0.56,5.42,0.93,9.06c-3-2.76-5.03-4.63-7.63-7.02
                c-1.72,2.68-3.08,4.8-3.96,6.16c4.26,1.27,7.57,2.42,10.96,3.22C462.16,233.87,465.27,234.2,468.34,234.7z"/>
            <path onClick={() => props.onFill(46)} fill={props.fillColors[46]} d="M986.38,159.15c-10.37-4.69-18.61-10.63-26.42-17.39c-3.04-2.63-6.68-5.35-10.45-6.18
                c-6.87-1.51-10.13-5.72-10.93-11.92c-1.26-9.72-2.01-19.51-2.7-29.3c-1-14.27-1.68-28.57-2.66-42.84
                c-0.27-3.95-1.25-6.96-5.67-9.41c-6.02-3.35-7.34-10.47-8.95-17.66c5.1,0.83,9.02,2.01,11.1,6.08c1.84,3.59,4.58,5.63,8.12,7.56
                c3.05,1.66,5.62,4.96,7.32,8.11c1.61,2.97,1.96,6.67,2.59,10.1c0.64,3.51,2.63,5.03,5.9,6.49c3.01,1.35,6.3,4.2,7.46,7.17
                c5.18,13.31,10.23,26.72,14.18,40.43c4.23,14.69,7.19,29.74,10.68,44.65C986.18,155.99,986.16,156.97,986.38,159.15z"/>
            <path onClick={() => props.onFill(47)} fill={props.fillColors[47]} d="M921.79,1457.09c1.83-5.81,1.18-6.95-4.38-8.27c-1.94-0.46-4.08-0.34-5.84-1.14
                c-1.55-0.7-2.67-2.35-4.04-3.49c-1.97-1.64-3.86-3.44-6.05-4.72c-2.35-1.38-5-2.23-8.62-3.79c2.35-1.99,3.85-3.1,5.16-4.41
                c1.36-1.36,3.25-2.75,3.64-4.42c0.98-4.19,3.51-5.23,7.3-5.02c2.51,0.14,5.04,0.03,8.6,0.03c-7.3-5.41-15.77-3.43-18.74,2.99
                c-0.96,2.08-3.01,3.79-4.87,5.29c-4.67,3.75-4.53,7.23,0.64,9.92c3.4,1.77,8.03,1.7,8.57,7.44c0.12,1.3,4.75,2.37,7.42,3.19
                c2.5,0.77,5.15,1.07,8.17,1.66c-2.72,7.04,2.56,7.96,6.73,9.33c-3.02,2.52-5.91,4.91-8.78,7.32c-2.87,2.42-5.95,1.32-6.82-2.45
                c-1.68-7.36-6.15-9.73-13.33-7.06c-7.23,2.68-12.73-1.71-11.7-9.41c0.57-4.25,0.99-8.56,2.01-12.72
                c3.5-14.16,14.03-20.21,27.25-22.57c5.04-0.9,10.33-1.27,15.4-0.78c4.45,0.43,8.78,2.32,13.11,3.73c4.85,1.58,9.52,4.02,14.48,4.9
                c17.31,3.07,19.46,14.5,18.79,28.32c-0.15,3.17-2.4,5.33-6.25,6c-14.04,2.42-28.1,2.7-42.23,1.37
                C925.52,1458.18,923.69,1457.53,921.79,1457.09z"/>
            <path onClick={() => props.onFill(48)} fill={props.fillColors[48]} d="M2.92,1085.31c5.21,2.5,9.33,4.61,13.55,6.49c11.84,5.27,23.63,10.67,35.63,15.56
                c6.78,2.76,13.83,4.21,21.45,2.69c3.31-0.66,7.42,0.36,10.61,1.81c12.23,5.58,24.27,11.61,36.33,17.57
                c4.94,2.44,7.75,6.29,10.07,11.59c2.31,5.27,7.47,9.3,11.44,13.93c-2.02-0.19-4.75-0.17-7.37-0.72c-17.79-3.7-35.29-8.23-51.44-17
                c-9.53-5.17-20.17-6.3-30.75-7.84c-6.09-0.89-12.3-2.88-17.77-5.7c-8.66-4.47-17.24-8.66-27.13-9.19
                c-3.87-0.21-5.67-1.83-5.51-5.91c0.22-5.82,0.01-11.65,0.08-17.47C2.12,1089.55,2.53,1087.97,2.92,1085.31z"/>
            <path onClick={() => props.onFill(49)} fill={props.fillColors[49]} d="M878.21,396.62c-6.19-5.22-12.69-10.12-18.44-15.79c-3.63-3.58-3.73-8.76-2.6-13.69
                c0.9-3.89,2.01-7.73,3.01-11.59c2.31-8.87,2.22-17.73,0.35-26.69c-3.36-16.16-5.92-32.46-4.57-49.02
                c0.37-4.49,2.32-8.85,3.59-13.25c0.82-2.85,1.68-5.69,2.64-8.49c0.96-2.8,1.8-5.69,3.25-8.22c0.45-0.79,3.6-1.22,3.98-0.7
                c6.26,8.61,16.52,13.43,21.49,23.36c3.76,7.51,4.36,9.58-1.03,16.25c-8.11,10.04-11.09,21.77-12.41,34.18
                c-0.14,1.31-0.39,2.82,0.08,3.96c2.98,7.1-0.21,13.6-1.38,20.41c-1.02,5.95-0.96,12.18-0.65,18.24
                c0.45,8.77,1.61,17.49,2.33,26.25c0.17,2.05-0.24,4.14-0.39,6.22C877.71,397.57,877.96,397.09,878.21,396.62z"/>
            <path onClick={() => props.onFill(50)} fill={props.fillColors[50]} d="M1110.46,916.1c7.67,0.84,13.87,1.83,20.11,2.11c4.6,0.2,5.71-1.41,5.5-6.68
                c6.64,2.92,12.93,2.33,19.71-1.89c-1.71-1.07-2.91-2-4.25-2.62c-19.66-9.1-34.54-23.98-48.48-40
                c-0.97-9.06-10.14-14.01-10.92-24.03c1.88,1.07,3.96,1.88,5.59,3.25c6.1,5.09,11.72,10.85,18.22,15.34
                c3.06,2.12,7.72,1.89,11.64,2.81c1.4,0.33,2.83,0.75,4.11,1.4c7.89,3.99,15.71,8.11,25,7.33c2.52-0.21,4.26,0.68,4.25,3.55
                c-0.06,23.96-0.12,47.92-0.2,71.88c0,0.29-0.28,0.59-0.75,1.52C1140.24,944.04,1125.47,930.89,1110.46,916.1z"/>
            <path onClick={() => props.onFill(51)} fill={props.fillColors[51]} d="M745.36,1246.6c7.07-9.3,14.69-17.14,24.34-23.02c4.37-2.66,8.82-5.53,12.37-9.15
                c5.27-5.38,8.8-11.74,6.06-19.87c-0.51-1.5-0.22-3.3-0.19-4.96c0.16-8.99-2.36-12.13-11.37-12.89
                c-5.54-0.47-11.16-0.09-17.95-0.09c1.49-2.06,1.96-3.09,2.74-3.73c6.19-5.05,12.3-10.21,18.7-14.97
                c6.34-4.71,17.86-2.96,23.82,2.18c3.93,3.39,8.84,5.81,13.61,8.01c3.59,1.65,4.64,3.41,2.47,6.77c-0.18,0.28-0.27,0.61-0.45,0.89
                c-9.81,15.31-19.47,30.72-29.55,45.84c-2.91,4.36-5.29,7.94-3.22,13.64c0.92,2.54-0.3,6.73-1.99,9.16
                c-3.1,4.46-7.22,8.23-11.06,12.13c-0.63,0.64-2.22,1-3.04,0.67C762.39,1253.88,754.2,1250.35,745.36,1246.6z"/>
            <path onClick={() => props.onFill(52)} fill={props.fillColors[52]} d="M159.54,1041.85c5.6,1.59,11.23,3.07,16.79,4.8c6.92,2.15,12.08,6.48,16.34,12.44
                c3.02,4.23,6.76,8.41,11.1,11.11c9.09,5.66,17.49,12.8,28.55,14.94c1.43,0.28,2.84,3.11,3.25,4.98c0.17,0.76-2.19,2.7-3.67,3.09
                c-4.65,1.23-9.39,2.34-14.16,2.84c-10.26,1.08-18.46,7.13-27.63,10.83c-1.04,0.42-2.29,0.97-3.28,0.75
                c-10.02-2.24-19.86-4.74-24.77-15.38c-2.82-6.11-7.62-10.27-13.56-13.47c-3.67-1.98-6.8-4.97-10.17-7.5
                c0.27-0.54,0.53-1.09,0.8-1.63c1.76,0.47,3.52,0.9,5.26,1.41c3.82,1.11,7.56,2.71,11.46,3.31c8.01,1.25,11.77-2.62,11.72-10.71
                c-0.01-2.57,1.62-5.1,2.04-7.73c0.26-1.65,0.27-3.97-0.68-5.06c-2.82-3.25-6.17-6.05-9.31-9.02
                C159.63,1041.84,159.54,1041.85,159.54,1041.85z"/>
            <path onClick={() => props.onFill(53)} fill={props.fillColors[53]} d="M481.64,825.23c-0.45-7.53-0.82-13.84-1.2-20.14c-0.21-3.43,0.34-7.15-0.79-10.24
                c-7.94-21.71-16.13-43.33-24.44-64.9c-4.37-11.34-9.17-22.51-13.72-33.77c-0.84-2.09-1.43-4.28-2.51-7.55
                c3.37,1.84,5.55,3.03,9.04,4.93c-0.58-3.07-0.87-4.73-1.21-6.38c-0.75-3.63-3.88-7-0.47-11.09c0.7-0.85-0.85-3.67-1.51-5.54
                c-0.92-2.61-1.97-5.16-2.21-8.17c2.16,1.83,4.33,3.65,6.49,5.48c0.44-0.24,0.88-0.47,1.32-0.71
                c-3.69-12.99-7.38-25.97-11.06-38.96c0.43-0.16,0.86-0.31,1.29-0.47c0.85,1.15,1.9,2.2,2.51,3.46
                c7.38,15.26,15.11,30.38,21.8,45.94c2.44,5.68,2.37,12.45,3.36,18.29c-4.09-2.73-8.76-5.85-13.43-8.97
                c-0.41,0.37-0.82,0.74-1.23,1.11c0,1.07-0.29,2.25,0.04,3.2c4.28,12.05,8.5,24.13,13.09,36.07c1.11,2.88,2.56,5.88,6.9,6.25
                c1.53,0.13,3.15,3.6,4.08,5.82c4.22,10.14,7.45,20.77,12.49,30.46c5.21,10.03,4.05,19.31,0.71,29.25
                c-1.68,4.98-2.34,10.34-3.16,15.57C487.22,817.97,486.79,821.67,481.64,825.23z"/>
            <path onClick={() => props.onFill(54)} fill={props.fillColors[54]} d="M714.35,591.82c-5.91,1.76-7.43,6-8.53,10.74c-0.62,2.69-2.05,5.18-2.85,7.85
                c-1.46,4.89-3.68,9.8-3.91,14.78c-0.97,20.78-1.95,41.59-1.53,62.37c0.13,6.3,4.65,12.54,7.35,18.72c0.96,2.2,2.49,4.14,4.11,6.79
                c-10.49,1.56-10.96,9.03-11.2,16.59c-0.08,2.33-0.01,4.66,0.04,6.99c0.15,6.5-0.13,12.63-3.91,18.62
                c-2.73,4.32-3.37,9.97-5.06,15.41c-0.22-0.6-0.55-1.08-0.53-1.54c0.88-23.59-0.03-47.45,3.31-70.7
                c2.45-17.08,3.64-33.72,3.25-50.9c-0.53-23.76,1.31-47.56,1.55-71.35c0.17-16.44-0.77-32.89-0.96-49.33
                c-0.14-12.48,0.22-24.96,0.18-37.43c-0.06-18.14-0.96-36.3-0.22-54.41c0.75-18.41,2-37.07,13-53.04c1.85-2.69,5.03-4.46,7.59-6.66
                c0.38,0.39,0.77,0.79,1.15,1.18c-1.48,7.41-2.8,14.86-4.6,22.2c-0.34,1.37-2.47,2.57-4.03,3.32c-7.33,3.53-11.09,8.55-10.11,17.35
                c2.3,20.72,1.85,41.53,0.23,62.3c-0.37,4.71,0.59,8.88,3.84,12.47c2.92,3.22,3.43,6.8,2.2,11.1c-1.66,5.84-3.07,11.84-3.69,17.86
                c-0.28,2.74,0.89,6.23,2.61,8.45c2.59,3.35,2.79,5.98,1.35,9.96c-1.57,4.35-2.28,9.31-2.06,13.94c0.34,7.28,1.63,14.53,2.82,21.74
                c0.36,2.21,1.49,4.38,2.67,6.33C710.04,586.17,712.04,588.62,714.35,591.82z"/>
            <path onClick={() => props.onFill(55)} fill={props.fillColors[55]} d="M246.24,1132.31c-0.8-2.13-1.3-3.46-1.8-4.79c0.41-0.29,0.83-0.59,1.24-0.88
                c3.89,5.01,7.91,9.94,11.55,15.13c0.54,0.78-0.24,3.58-1.17,4.11c-4.52,2.58-9.1,5.75-14.05,6.77c-8.2,1.68-15.67,4.38-21.99,9.83
                c-2.68,2.31-5.05,2.81-8.56,1.55c-3.83-1.38-8.09-1.65-12.2-2.15c-3.29-0.4-7.01,0.46-9.88-0.77
                c-11.64-4.99-24.34-7.93-33.78-17.37c-3.98-3.98-8.78-7.12-12.97-10.9c-2.98-2.69-5.12-5.98-4.45-10.68
                c1.33,0.09,2.29,0.05,3.21,0.22c23.82,4.52,47.62,9.19,71.48,13.53c6.83,1.24,13.26,2.82,18.61,7.64
                c1.26,1.14,3.52,1.18,5.32,1.72c0.1-1.74,0.63-3.59,0.21-5.2c-0.98-3.78,0.33-5.73,4.02-6.46
                C242.58,1133.32,244.13,1132.85,246.24,1132.31z"/>
            <path onClick={() => props.onFill(56)} fill={props.fillColors[56]} d="M613.1,1196.19c-16.87-18.21-38.52-17.75-62.57-13.35c2.78-5.71,4.77-9.96,6.91-14.13
                c0.98-1.91,2.92-3.54,3.31-5.51c2.61-13.27,14.28-15.53,24.04-19.87c4.49-1.99,8.92,0.18,12.33,3.41
                c5.17,4.9,10.25,9.09,18.26,8.56c2.54-0.17,5.67,3.07,7.95,5.34c10.44,10.4,10.39,10.5,1.34,22.32
                c-3.13,4.09-6.39,8.08-9.63,12.09C614.76,1195.42,614.21,1195.56,613.1,1196.19z"/>
            <path onClick={() => props.onFill(57)} fill={props.fillColors[57]} d="M91.62,956.76c-3.82-3.65-7.74-7.19-11.42-10.97c-4.05-4.16-7.82-8.59-11.85-12.77
                c-1.13-1.17-2.61-2.37-4.12-2.74c-11.04-2.65-18.05-10.87-25.67-18.24C27.21,901.07,15.85,890.1,4.6,879.02
                c-1.19-1.17-2.36-3.05-2.38-4.61c-0.18-12.81-0.08-25.63-0.04-38.44c0-0.44,0.37-0.88,0.75-1.74c1.01,0.67,2.07,1.09,2.74,1.85
                c6.92,7.85,14.2,15.44,20.55,23.73c9.71,12.67,18.93,25.72,27.97,38.89c2.22,3.23,2.76,7.57,4.31,11.3
                c2.46,5.91,4.45,12.17,7.9,17.47c3.11,4.78,8.12,8.29,11.9,12.69c4.63,5.38,8.85,11.11,13.25,16.69
                C91.57,956.84,91.62,956.76,91.62,956.76z"/>
            <path onClick={() => props.onFill(58)} fill={props.fillColors[58]} d="M1159.67,1019.11c-28.22-14.7-47-38.15-64.65-62.74c0.27-0.39,0.54-0.79,0.81-1.18
                c1.01,0.24,2.07,0.38,3.03,0.74c8.79,3.33,17.51,6.82,26.36,9.96c2.6,0.92,5.54,1.05,8.35,1.18c1.6,0.08,3.98-0.01,4.72-1
                c3.78-5.03,8.43-4.15,13.57-3.08c4.26,0.88,5.78-2.13,5.8-6.22c0.47-0.07,0.95-0.14,1.42-0.21c0.63,1.28,1.79,2.55,1.8,3.83
                c0.12,18.3,0.09,36.61,0.03,54.91C1160.91,1016.36,1160.25,1017.4,1159.67,1019.11z"/>
            <path onClick={() => props.onFill(59)} fill={props.fillColors[59]} d="M568.01,271.21c-1.52,3.06-3.38,6.01-4.46,9.21c-1.27,3.76-1.78,7.77-2.78,11.64
                c-1.93,7.45-1.93,7.53,4.66,11.48c1.67,1,3.25,2.14,5.81,3.83c-2.55,0.56-4.01,0.88-6.2,1.36c1.12,1.99,2.02,3.58,3.07,5.44
                c-0.95,0.3-1.7,0.5-2.44,0.77c-1.67,0.61-3.33,1.24-5,1.87c1.27,1.61,2.46,3.28,3.84,4.79c0.73,0.8,1.77,1.3,3.66,2.62
                c-3.81,1.12-6.65,1.96-10.94,3.23c3.47,2.34,5.92,4,7.5,5.07c-0.89,4.27-1.95,8.05-2.3,11.89c-0.1,1.1,1.68,3.11,2.94,3.47
                c4.26,1.2,8.64,2.2,13.04,2.6c3.66,0.33,5.32,1.41,3.93,6.36c-6.01,0-12.29-0.15-18.55,0.08c-2.08,0.08-4.19,0.96-6.17,1.76
                c-2.95,1.19-5.08,0.61-6.8-2.12c-1.24-1.96-3.25-3.54-4.09-5.62c-0.98-2.42-1.18-5.19-1.47-7.84c-0.33-2.97-0.38-5.97-0.59-8.96
                c-0.16-2.27-0.84-4.61-0.52-6.8c1.32-9.14,3.01-18.22,4.38-27.34c0.49-3.28,0.34-6.65,0.64-9.96c0.17-1.81,0.15-3.99,1.14-5.31
                c5.61-7.49,3.18-15.85,3.08-23.99c-0.05-4.15,0.26-8.3,0.56-12.44c0.33-4.62,1.88-8.29,5.91-11.43c2.74-2.14,3.95-6.33,5.64-9.7
                c0.93-1.85,1.46-3.9,2.25-6.09c3.78,3.5,3.75,9.21,0.39,13c-5.46,6.16-4.65,8.87,0.42,15.1c2.36,2.9,2.14,8.36,2.07,12.63
                c-0.05,3.2-1.75,6.37-2.71,9.55C567.91,271.33,568.01,271.21,568.01,271.21z M553.94,336.89c1.06-0.23,1.5-0.28,1.5-0.33
                c0.03-16.45,0.03-32.9,0.03-49.35c-0.51,0.01-1.02,0.01-1.53,0.02C553.94,303.71,553.94,320.19,553.94,336.89z"/>
            <path onClick={() => props.onFill(60)} fill={props.fillColors[60]} d="M424.24,174.41c-2.44-0.79-4.88-1.58-8.44-2.73c0.74,3.16,1.27,5.42,1.88,8.05
                c-1.49,0.36-3.13,0.76-4.78,1.16c2.22,8.29,8.93,12.88,18.11,13.12c-3.28,0.99-6.56,1.98-11.62,3.51c3.81,2.41,6.46,4.08,9.1,5.74
                c0.1,0.47,0.2,0.94,0.3,1.41c-4.09,0.84-8.18,1.68-13.49,2.76c7.01,6.38,10.25,14.71,14.98,23.09
                c-18.15,0.57-30.82-8.4-43.24-18.5c3.33-2.18,6.54-4.28,10.47-6.86c-2.39-1.27-4.02-2.14-5.73-3.05c3.21-2.96,6.3-5.81,10.59-9.78
                c-4.36-0.29-7.21-0.48-10.07-0.68c-0.08-0.4-0.17-0.79-0.25-1.19c3.98-1.92,7.97-3.84,10.59-5.11c0-3.5,0-5.61,0-8.33
                c-2.34-0.32-5.65-0.77-9.44-1.29c8.36-6.7,16.83-12.19,23.14-21.76c-5,0.64-8.74,1.11-12.77,1.63
                c3.87-5.75,7.29-10.85,11.05-16.43c-2.19-0.52-3.55-0.85-5.37-1.28c3.33-3.54,6.47-6.87,9.61-10.2c9.2,6.74,9.56,8.46,1.84,13.06
                c1.86,2.3,3.64,4.51,6.18,7.65c-1.93,2.16-4.2,4.7-7.47,8.36c2.14,2.29,5.23,5.61,8.33,8.92c-7.56,1.12-7.56,1.12-3.57,8.8
                L424.24,174.41z M402.5,207.41c-0.35,0.19-0.7,0.38-1.05,0.57c1.64,2.28,2.87,5.11,5.02,6.73c3.57,2.7,7.76,4.25,12.72,1.5
                c-3.12-4.18-6.05-8.09-8.97-12.01c-0.3,0.15-0.6,0.31-0.91,0.46c1.07,2.94,2.15,5.88,3.22,8.82c-0.44,0.41-0.87,0.82-1.31,1.24
                C408.32,212.28,405.41,209.85,402.5,207.41z"/>
            <path onClick={() => props.onFill(61)} fill={props.fillColors[61]} d="M886.57,981.78c-1.52,4.19-2.22,7.52-3.82,10.33c-6.84,11.98-13.79,23.9-21.04,35.63
                c-2.83,4.57-4.37,9.12-4.26,14.53c0.05,2.36-1.13,5.06-2.52,7.06c-5.69,8.15-11.84,15.98-17.48,24.17
                c-1.92,2.79-3.5,4.22-7.19,3.54c-8.32-1.52-16.74-2.78-25.4,0.15c4.13-5.26,8.5-10.36,12.33-15.83
                c3.98-5.68,7.8-11.54,11.01-17.68c3.19-6.1,7-11.13,13.35-14.23c5.47-2.67,9.78-6.72,12.47-12.42
                C860.95,1002.35,871.8,991.16,886.57,981.78z"/>
            <path onClick={() => props.onFill(62)} fill={props.fillColors[62]} d="M936.06,1281.44c-3.09-2.72-6.01-4.68-8.09-7.3c-1.9-2.39-3.56-5.38-4.1-8.33
                c-0.65-3.57-1.5-6.53-5.63-9.2c2.95,8.36,0.63,17.66,10.18,22.12c1.72,0.8,2.51,4.4,2.81,6.82c0.08,0.61-3.32,1.99-5.25,2.42
                c-1.89,0.42-3.97,0.24-5.94,0.06c-12.55-1.17-25.09-2.38-37.63-3.65c-2.3-0.23-4.72-0.41-6.81-1.26c-1.66-0.67-3.95-2.24-4.08-3.6
                c-0.17-1.76,1.3-3.86,2.49-5.51c0.7-0.98,2.15-1.67,3.39-1.97c8.27-2.06,12.85-8.53,15.97-15.34c4.05-8.85,14.6-13.51,25.68-6.7
                c1.41,0.87,2.6,2.22,4.11,2.8c5.79,2.2,6.69,6.95,7.43,12.3c0.4,2.88,2.46,5.53,3.77,8.28c0.41-0.22,0.82-0.44,1.23-0.66
                c-1.79-7.19-3.57-14.39-5.44-21.91c6.9,1.49,8.41,7.86,11.14,13.04C945.41,1271.6,940.11,1276.44,936.06,1281.44z"/>
            <path onClick={() => props.onFill(63)} fill={props.fillColors[63]} d="M777.83,235.44c-0.18-1.4-0.36-2.79-0.74-5.78c-9.65,9.64-20.34,10.25-31.57,7.26
                c-1.01-0.27-1.58-2.22-2.72-3.93c8.62-2.48,16.55-4.76,24.48-7.04c-0.04-0.2-0.07-0.39-0.11-0.59c-3.13,0.31-6.3,1.04-9.39,0.82
                c-3-0.21-5.93-1.4-9.28-2.26c4.36-6.44,10.45-8.62,16.61-10.49c0.86-0.26,2.69,1.02,3.32,2.04c0.99,1.6,1.35,3.6,1.98,5.43
                c8.28-0.84,14.93-11.95,10.62-20.07c-0.39,6.76-1.61,12.18-8.4,15.79c-1.61-2.83-3.25-5.69-5.16-9.04
                c-4.73,6.92-12.36,5.82-19.73,5.72l0.06,0.08c2.14-2.73,4.14-5.6,6.5-8.12c0.93-0.99,2.63-1.59,4.04-1.73
                c3.93-0.38,6.73-1.89,7.56-6.5c-4.06,4.37-8.64,4.49-14.66,2.12c5.56-3.53,5.79-11.37,13.83-12.03c2.32-0.19,4.37-3.78,6-6.71
                c-5.13,4.33-10.43,6.79-17.05,4.57c2.22-2.34,4.37-4.57,6.48-6.84c3.99-4.31,12.01-3.3,14.81,1.7c1.44,2.57,3.37,4.86,5.99,8.55
                c-3.61-0.88-5.93-1.45-8.27-2.03c1.7,3.91,6.84,5.69,13.03,4.17c1.11-0.27,1.87-1.95,3.06-3.28c0.23,0.11,1.39,0.74,2.6,1.24
                c1.2,0.5,2.45,0.87,3.74,1.33c-3.59,5.15-8.91,5.5-21.16,1.6c1.63,3.21,3.82,4.07,8.5,5.14c3.77,0.86,7.43,2.54,10.87,4.36
                c1.08,0.57,2.24,3.31,1.76,4.15c-5.72,10.2-11.74,20.23-17.69,30.3C777.76,235.37,777.83,235.44,777.83,235.44z"/>
            <path onClick={() => props.onFill(64)} fill={props.fillColors[64]} d="M233.79,1140.99c-10.7-9.13-22.66-8.39-33.82-10.45c-18.13-3.35-36.29-6.53-54.42-9.85
                c-1.88-0.34-3.68-1.13-5.4-1.68c1.51-3.85,2.74-7.23,4.15-10.53c2.1-4.94,8.95-7.19,13.93-5.59c18.8,6.06,37.56,12.31,57.6,13.23
                c5.07,0.23,10.15,1.54,15.09,2.85c5.54,1.46,8.36,6.05,11.49,11.27C237.36,1131.74,232.57,1133.32,233.79,1140.99z"/>
            <path onClick={() => props.onFill(65)} fill={props.fillColors[65]} d="M561.09,376.76c-2.38,1.77-4.68,3.48-6.98,5.18c0.3,0.4,0.61,0.81,0.91,1.21
                c1.24-0.69,2.43-1.85,3.72-1.97c3.32-0.31,8.02-1.54,9.74,0.12c3.22,3.1,7.17,4.03,10.73,5.94c1.5,0.81,3.73,0.28,5.62,0.32
                c1.77,0.03,3.55,0.01,5.32,0.01c0.22,0.57,0.43,1.14,0.65,1.7c-2.31,1.61-4.48,3.51-6.98,4.75c-3.11,1.55-6.49,2.55-9.75,3.79
                c-0.15,0.06-0.32,0.23-0.45,0.2c-7.48-1.57-15.38-0.61-22.62-3.58c-3.21-1.32-6.48-2.61-9.44-4.38c-2.07-1.23-4.31-2.95-5.31-5.01
                c-1.57-3.24-2.06-6.99-3.18-10.46c-0.86-2.67-1.9-5.29-3.04-7.85c-0.85-1.91-3.91-3.49-0.94-5.86c2.74-2.18,6.69-4.07,8.98-1.04
                c6.49,8.55,13.42,2.59,20.28,1.63c2.93-0.41,5.92-0.32,8.89-0.43c0.98-0.03,2.07,0.34,2.92,0.02c5.26-2.03,10.4-1.52,15.93-0.79
                c4.46,0.59,7.48,1.94,9.8,5.47c2.45,3.73,5.52,5.24,10.19,4.22c1.49-0.33,4.04,1.54,5.2,3.08c1.08,1.44,0.97,3.77,1.48,6.13
                c-1.31,0-2.48,0.29-3.42-0.05c-5.94-2.12-12.09-3.86-17.67-6.72c-6.26-3.2-10.05-3.03-15.61,1.93c-1.19,1.07-3.58,1.52-5.23,1.22
                c-6.63-1.18-13.14-3.25-19.8-3.94c-2.49-0.26-5.86,1.85-7.72,3.89c-1.04,1.14,0.22,4.36,1.11,6.54c1.21-8.62,3.21-9.76,11.61-6.54
                C557.66,376.12,559.4,376.35,561.09,376.76z"/>
            <path onClick={() => props.onFill(66)} fill={props.fillColors[66]} d="M1072.72,1466.3c-2.83,0-5.66-0.01-8.49,0c-9.05,0.05-15.71-4.37-20.88-11.38
                c-0.7-0.95-0.92-2.45-0.9-3.69c0.02-1.65,0.89-3.33,0.7-4.92c-1.04-8.47,3.84-12.52,10.94-14.7c5.53-1.7,11.12-3.65,16.81-4.22
                c9.59-0.96,17.12,3.62,22.51,11.33c1.22,1.74,1.43,4.73,1.02,6.94c-0.96,5.25-2.52,10.32-0.25,15.69
                c0.29,0.67-1.84,3.34-3.09,3.54c-6.06,0.94-12.21,1.36-18.33,1.96C1072.75,1466.67,1072.73,1466.48,1072.72,1466.3z
                M1065.27,1436.24c-0.12-0.58-0.24-1.16-0.36-1.74c-2.76,0.76-5.72,1.13-8.23,2.39c-2.01,1.01-3.47,3.1-5.17,4.71
                c1.57,1.54,2.88,3.81,4.76,4.49c5.43,1.98,11.07,3.43,16.68,4.87c3.23,0.83,6.56,1.25,9.84,1.86c0.13-0.58,0.26-1.16,0.39-1.74
                c-1.8-0.46-3.6-0.91-5.39-1.38c-6.57-1.71-13.18-3.31-19.69-5.23c-1.35-0.4-2.34-2-3.5-3.04c1.24-0.96,2.35-2.2,3.74-2.83
                C1060.56,1437.6,1062.95,1437,1065.27,1436.24z"/>
            <path onClick={() => props.onFill(67)} fill={props.fillColors[67]} d="M438.12,470.44c-7.21-0.23-12.83,0.21-17.4-4.82c-2.66-2.92-6.49-4.44-10.95-3.09
                c-1.27,0.39-3.01-0.92-4.57-1.34c-5.67-1.5-11.37-2.86-17-4.49c-1.83-0.53-3.59-1.55-5.17-2.66c-6.37-4.47-12.49-9.33-19.03-13.54
                c-6.83-4.4-14.44-6.6-22.71-6.48c-4.17,0.06-12.8-7.17-13.41-11.11c-0.15-0.97,1.01-2.73,1.99-3.16c3.48-1.53,6.92-1.8,10.25,0.95
                c2.42,2,5.18,3.64,7.95,5.13c5.23,2.81,10.76,3.73,16.27,0.98c3.24-1.62,5.95-1.35,8.83,0.64c1.35,0.93,2.94,1.78,4.52,2.03
                c4.62,0.72,7.69,3.03,9.98,7.19c2.2,4.01,6.08,5.38,10.71,4.51c8.03-1.51,14.37,0.79,19.62,7.49c4.8,6.13,10.61,11.46,15.96,17.15
                C435.07,466.99,436.12,468.21,438.12,470.44z"/>
            <path onClick={() => props.onFill(68)} fill={props.fillColors[68]} d="M1107.47,817.02c7.79,2.18,15.52,4.66,23.4,6.41c3.71,0.82,7.45,0.41,9.14-4.64
                c0.74-2.23,3.79-3.69,6.33-5.98c1.51-0.22,3.97-0.59,6.44-0.95c0.09-0.45,0.19-0.91,0.28-1.36c-8.2-6.21-16.4-12.41-24.59-18.62
                c0.12-0.44,0.24-0.89,0.36-1.33c4.76-0.56,9.53-1.66,14.27-1.54c4.65,0.12,8.48-0.09,11.17-4.6c0.7-1.17,3.08-1.97,4.57-1.81
                c0.84,0.09,2.06,2.46,2.06,3.81c0.12,25.47,0.1,50.94,0.06,76.42c0,0.91-0.43,1.81-0.98,4.02c-2.88-4.71-8.41-6-7.46-12.32
                c0.25-1.64-2.26-3.69-4.01-6.32c3.6,0,6.55,0,9.5,0c0.13-0.46,0.27-0.91,0.4-1.37c-1.43-0.81-2.8-1.76-4.3-2.4
                c-15.37-6.52-30.18-14.04-43.4-24.38c-1.15-0.9-2.11-2.06-3.16-3.1L1107.47,817.02z"/>
            <path onClick={() => props.onFill(69)} fill={props.fillColors[69]} d="M773.84,236.44c-0.34,1.39-0.67,2.78-1.32,5.47c3.19-1.15,5.32-1.92,8.5-3.07
                c-1.54,3.3-2.83,5.87-3.95,8.52c-5.56,13.12-11.24,26.19-16.52,39.43c-1.95,4.89-13.69,12.79-18.41,10.75
                c-4.19-1.82-7.69-5.25-11.62-8.05c4.85-2.87,9.07-6.26,13.88-7.94c4.5-1.57,9.63-1.32,14.48-1.87c-0.06-0.46-0.13-0.91-0.19-1.37
                c-5.42,0-10.87-0.39-16.23,0.15c-3.18,0.32-5.33,0.19-7.16-2.74c-0.73-1.16-2.72-1.53-4.19-2.29c2.58-3.38,5.48-4.5,10.01-2.64
                c5.06,2.08,11.07-0.44,14.4-5.59c-10.32,6.1-19,3.69-27.39-5.18c12.34,0.22,22.43-4.89,33.03-8.42c-0.16-0.53-0.32-1.06-0.49-1.59
                c-1.33,0.39-2.65,0.81-3.99,1.18c-5.11,1.41-10.02,3.03-15.35-0.19c-1.85-1.12-5.13,0.13-7.94-0.52c0.79-0.26,1.58-0.74,2.38-0.74
                c5.87,0.03,10.44-2.76,14.79-6.33c1.58-1.3,3.55-2.39,5.52-2.89c5.89-1.51,11.86-2.69,17.81-4
                C773.89,236.52,773.84,236.44,773.84,236.44z"/>
            <path onClick={() => props.onFill(70)} fill={props.fillColors[70]} d="M448.31,502.98c3.33,1.15,6.82,1.97,9.95,3.52c7.15,3.54,14.3,7.15,21.13,11.27
                c8.35,5.04,17.91,4.98,26.92,6.53c12.81,2.2,14.29,7.33,13.71,18.43c-0.28,5.41-0.03,10.88,0.39,16.28
                c0.47,5.93-4.57,10.77-9.54,7.89c-5.47-3.17-10.03-7.96-14.78-12.27c-0.63-0.57-0.31-2.51-0.01-3.71
                c1.5-5.99-0.44-9.78-6.38-10.33c-5.57-0.51-9.58-3.22-12.29-7.51c-6.55-10.38-15.56-18.09-25.48-24.95
                c-1.56-1.08-2.91-2.46-4.35-3.69C447.82,503.96,448.06,503.47,448.31,502.98z"/>
            <path onClick={() => props.onFill(71)} fill={props.fillColors[71]} d="M989.29,876.99c-1.29,4.79-2.28,9.17-3.68,13.41c-0.66,2-1.75,4.15-3.27,5.53
                c-11.96,10.77-23.12,22.72-39.05,27.93c-1.19,0.39-2.43,0.62-4.56,1.16c1-2.63,1.61-4.47,2.4-6.23
                c6.48-14.43,12.83-28.92,19.59-43.22c1.95-4.14,4.66-8.1,7.76-11.46c3.19-3.45,5.67-2.7,7.31,1.71c2.01,5.43,5.35,9.1,11.23,10.17
                C988.07,876.19,989.04,876.88,989.29,876.99z"/>
            <path onClick={() => props.onFill(72)} fill={props.fillColors[72]} d="M985.17,964.89c-17.63,28.46-45.12,42.37-75.67,53.67c0.38-1.8,0.41-2.73,0.76-3.51
                c4.2-9.06,8.48-18.07,12.64-27.14c1.12-2.43,2.29-3.33,5.38-3.74c4.73-0.63,9.35-2.97,13.72-5.18c3.03-1.53,5.68-2.03,8.28,0.13
                c3.82,3.17,7.64,2.33,10.95-0.25c3.37-2.63,6.48-5.74,9.12-9.1C975.91,962.7,975.75,962.57,985.17,964.89z"/>
            <path onClick={() => props.onFill(73)} fill={props.fillColors[73]} d="M733.87,331.19c-5.59,0.26-9.79,6.67-16.62,2.54c5.41-4.8,13.35-5.9,16.06-13.55
                c-5.11-0.4-10.12-0.78-16.41-1.27c6.57-3.2,11.58-5.64,16.59-8.09c0.11-0.72,0.22-1.45,0.33-2.17c-1.1-0.59-2.2-1.17-4.31-2.29
                c8.72-3.3,16.47-6.24,25.62-9.71c-3.05,10.78-3.13,21.18-8.75,29.87c-5.73,8.86-12.46,17.07-18.28,25.88
                c-2.9,4.39-4.82,9.45-7.02,14.28c-2.25,4.95-6.37,6.35-11.34,4.37c-3.38-1.34-7.13-1.72-11.84-2.78
                c7.08-5.43,16.91-5.5,19.25-15.98c-4.02,0-8.09,0-12.16,0C714.96,345.03,725.68,340,733.87,331.19z"/>
            <path onClick={() => props.onFill(74)} fill={props.fillColors[74]} d="M437.83,215.17c4.21,2.22,7.45,3.93,11.04,5.83c-4.05,1.82-7.93,3.56-13.52,6.07
                c-2.15-1.71-5.68-4.5-9.41-7.47c4.42-2.45,7.6-4.2,10.18-5.63c-2.47-8.79-0.73-15.98,2.68-23.07c0.76-1.59,0.61-3.61,0.87-5.44
                c0.5-3.56,0.54-7.27,1.61-10.66c1.18-3.74,3.06-7.33,5.09-10.71c1.92-3.2,4.6-5.93,6.6-9.09c1.45-2.3,2.14-5.08,3.53-7.43
                c0.97-1.64,2.4-3.94,3.88-4.15c2.55-0.37,5.34,0.54,5.71,4.12c0.26,2.48,0.73,4.93,1.1,7.4c1.24,8.25,3.4,16.1,8.07,23.25
                c1.19,1.83,1.4,6.2,0.17,7.32c-2.96,2.69-7.13,1.61-9.1-1.61c-2.25-3.67-3.33-8.08-4.7-12.25c-0.57-1.74-0.56-3.66-1.57-5.69
                c-3.48,9.27-6.76,18.39-15.41,23.89c4.83,7.59,4.83,7.59-3.76,15.07C445.92,211.58,445.92,211.58,437.83,215.17z"/>
            <path onClick={() => props.onFill(75)} fill={props.fillColors[75]} d="M853.2,1157.64c-1.84-1.99-3.48-4.31-5.63-5.97c-3.5-2.7-7.11-5.46-11.09-7.26
                c-3.46-1.56-3.67-3.29-2.17-6.1c3.37-6.29,9.06-9.7,15.58-11.5c5.11-1.41,9.47-3.5,13.29-7.28c1.49-1.47,4.67-2.55,6.58-2.02
                c5.4,1.48,10.57,3.83,15.77,6c0.67,0.28,1.53,1.77,1.31,2.23c-1.66,3.5-2.7,8.42-5.55,10.04c-9.96,5.64-18.59,12.68-26.38,20.92
                C854.61,1157.02,854.09,1157.16,853.2,1157.64z"/>
            <path onClick={() => props.onFill(76)} fill={props.fillColors[76]} d="M1033.78,1225.98c0,6.9,0.46,13.84-0.11,20.7c-0.79,9.6-2.27,19.14-3.68,28.67
                c-0.16,1.07-1.81,2.79-2.71,2.75c-6.42-0.27-12.82-0.91-19.22-1.44c-0.25-0.47-0.49-0.95-0.74-1.42
                c4.54-4.42,8.81-9.18,13.71-13.16c4.38-3.57,7.42-7.66,9.26-13.02c-0.9-0.18-1.37-0.4-1.5-0.27
                c-8.12,8.13-16.19,16.31-24.35,24.41c-1,0.99-2.39,2.14-3.66,2.21c-4.56,0.27-9.15,0.1-15.3,0.1
                c6.81-7.83,12.22-15.18,18.78-21.29c8-7.46,17.08-13.74,22.86-23.38c1.33-2.22,3.19-4.13,4.8-6.19
                C1032.54,1225.1,1033.16,1225.54,1033.78,1225.98z"/>
            <path onClick={() => props.onFill(77)} fill={props.fillColors[77]} d="M1155.26,951.97c-1.01,2.67-1.85,4.93-2.97,7.9c-2.57-0.59-5.22-1.55-7.91-1.7
                c-1.62-0.09-4.18,0.61-4.82,1.77c-1.78,3.27-4.42,3.9-7.53,3.57c-2.63-0.28-5.31-0.71-7.78-1.6c-1.82-0.66-3.32-2.17-4.97-3.31
                c1.33-1.2,2.66-2.4,4.58-4.13c-0.84-0.79-1.91-2.07-3.23-2.99c-9.69-6.78-14.07-16.82-17.26-27.66c-0.49-1.68-1.37-3.4-2.53-4.69
                c-6.54-7.31-13.23-14.49-19.84-21.73c-3.68-4.03-6.61-8.42-5.87-14.45c2.25,4.13,4.12,8.55,6.86,12.33
                c3.4,4.67,7.42,8.91,11.41,13.12c1.17,1.23,3.32,2.65,4.71,2.37c5.17-1.02,6.75,2.61,9.28,5.74
                c11.37,14.03,25.53,24.63,41.62,32.7C1151.05,950.23,1153.19,951.06,1155.26,951.97z"/>
            <path onClick={() => props.onFill(78)} fill={props.fillColors[78]} d="M979.92,1437.34c-2.76-4.11-5.47-7.82-7.77-11.77c-0.63-1.08-0.6-3.39,0.14-4.25
                c1.81-2.09,3.96-4.54,6.44-5.29c11.23-3.39,22.49-7.3,34.55-5.01c11.3,2.15,14.01,9.7,7.04,18.69c-2.21,2.85-3.62,6.3-5.56,9.38
                c-0.89,1.42-2.18,2.59-3.29,3.87c-1.06-1.09-2.41-2.02-3.14-3.3c-4.24-7.49-10.99-9.57-18.87-5.9
                C986.21,1435.27,982.73,1436.3,979.92,1437.34z"/>
            <path onClick={() => props.onFill(79)} fill={props.fillColors[79]} d="M1016.12,1472.2c-5.06-2.36-10.6-4.04-15.05-7.24c-6.08-4.38-4.62-13.01,2.9-15.86
                c8.95-3.39,18.45-4.77,27.3,0.8c4.63,2.91,9.11,6.13,13.28,9.66c1.53,1.3,3.09,4.22,2.61,5.78c-0.5,1.61-3.33,3.18-5.35,3.52
                c-5.06,0.85-10.24,0.97-15.37,1.41c-3.3,0.28-6.6,0.62-9.9,0.93C1016.4,1471.53,1016.26,1471.86,1016.12,1472.2z"/>
            <path onClick={() => props.onFill(80)} fill={props.fillColors[80]} d="M908.9,986.78c5.46-9.82,10.84-19.68,16.42-29.43c1.25-2.18,9.35-2.6,11.43-0.35
                c6.97,7.54,15.75,6.64,24.57,5.82c3.38-0.31,6.75-0.79,11.32-1.34c-3.95,7.65-8.2,13.35-15.15,16.43c-1.33,0.59-3.76,0.16-5-0.74
                c-4.42-3.2-8.42-2.98-12.74,0.13c-2.13,1.53-4.58,2.79-7.07,3.59c-1.74,0.55-4.16,0.78-5.65-0.02c-4.34-2.34-7.53-1.13-10.66,2.08
                c-1.8,1.85-3.92,3.38-5.9,5.06C909.95,987.59,909.43,987.19,908.9,986.78z"/>
            <path onClick={() => props.onFill(81)} fill={props.fillColors[81]} d="M548.8,361.36c-1.76-0.29-4.3,0.06-5.15-0.98c-5.47-6.63-11.28-5.99-17.47-1.07
                c-1.19-4.24-2.37-7.96-3.18-11.77c-0.17-0.8,0.79-2.31,1.62-2.77c7.59-4.22,10.62-10.92,12.12-19.21
                c1.18-6.52,4.27-12.68,5.96-19.14c0.98-3.76,1.15-7.81,1.17-11.73c0.05-6.98-0.58-13.97-0.33-20.93c0.11-2.99,1.79-5.9,2.63-8.88
                c0.62-2.19,1.41-4.43,1.49-6.67c0.21-5.98,0.13-11.98,0.08-17.97c-0.03-3.41,0.8-5.95,4.28-7.64c2.07-1,3.44-3.4,5.17-5.13
                c1.33-1.33,2.73-2.59,4.57-3.46c0.97,5.15-1.52,8.32-4.46,11.64c-2.4,2.7-5.19,6.09-5.51,9.4c-0.88,9.05-0.43,18.21-0.75,27.33
                c-0.09,2.52-1.02,5.02-1.63,7.52c-1.19,4.79-2.92,9.5-3.54,14.36c-1.11,8.73-1.44,17.56-2.37,26.32c-0.24,2.24-2.1,4.36-2.13,6.55
                c-0.13,7.38,2.25,14.63,1.17,22.14c-0.19,1.32,1.89,2.97,2.94,4.45c1.4,1.98,2.82,3.94,4.23,5.91
                C549.39,360.19,549.09,360.77,548.8,361.36z"/>
            <path onClick={() => props.onFill(82)} fill={props.fillColors[82]} d="M409.96,389.87c1.14,5.71,4.13,4.82,8.32,3.91c6.22-1.35,12.77-1.69,19.17-1.57
                c5.06,0.09,10.14,1.3,15.12,2.44c1.73,0.4,3.34,1.88,4.71,3.18c1.87,1.77,3.46,3.83,6.18,6.9c-4.02-0.57-7.66-0.32-10.6-1.63
                c-12.15-5.44-24.81-5.48-37.16-2.47c-5.73,1.39-11.02,5.93-15.64,10.02c-2.94,2.6,0.62,4.96,2.83,6.59
                c3.88,2.84,8.03,5.32,11.78,8.31c2.41,1.93,4.3,4.51,6.42,6.8c-0.26,0.35-0.52,0.7-0.78,1.05c-4.81-3.22-9.56-6.51-14.43-9.63
                c-8.03-5.16-16.06-10.32-24.23-15.25c-3.44-2.08-4.04-3.93-0.6-6.24c2.52-1.69,5.7-2.75,8.73-3.26
                C400.35,397.24,402.08,396.63,409.96,389.87z"/>
            <path onClick={() => props.onFill(83)} fill={props.fillColors[83]} d="M564.59,338.71c2.79-1.85,4.38-2.9,6.61-4.38c-2.19-2.01-3.94-3.62-5.78-5.3c2.43-2.02,4.71-3.92,7.58-6.3
                c-3.23-1.6-5.48-2.72-8.59-4.26c4.21-1.48,7.53-2.15,8.23-6.94c0.31-2.08,4.61-3.56,7.1-5.31c-0.19-0.45-0.39-0.9-0.58-1.35
                c2.29,0.13,4.58,0.25,6.87,0.38c6.04,0.33,6.42,0.95,5.97,6.95c-0.3,4-0.06,8.05-0.06,13.54c-3.25-2.02-4.99-3.1-7.28-4.52
                c0.07,1.61,0.13,2.88,0.24,5.35c-2.13-2.03-3.65-3.49-5.38-5.14c-2.53,1.83-5.09,3.69-7.65,5.54c0.25,0.48,0.51,0.96,0.76,1.45
                c1.98-0.93,3.96-1.86,6.35-2.99c4.04,4.78,9.47,4.15,15.13,2.67c0.83-0.22,2.14,0.44,2.83,1.11c0.31,0.29-0.12,1.84-0.65,2.44
                c-4.1,4.61-3.75,7.92,2.41,12.41c-3.2,0.85-5.64,1.49-8.64,2.29c0.21-2.83,0.34-4.69,0.48-6.55c-6.44,9.64-15.59,6.18-25.58,6.62
                C568.22,342.88,569.54,340.44,564.59,338.71z M575.03,342.57c0.45,0.33,0.9,0.66,1.35,0.99c1.4-2.08,2.81-4.16,4.21-6.24
                c-0.43-0.3-0.86-0.59-1.3-0.89C577.88,338.49,576.45,340.53,575.03,342.57z"/>
            <path onClick={() => props.onFill(84)} fill={props.fillColors[84]} d="M74.72,2.2c9.89,0,18.78-0.12,27.67,0.13c1.35,0.04,2.78,1.64,3.94,2.76c2.16,2.06,4.09,4.36,6.22,6.44
                c3.51,3.43,7.79,6.22,4.59,12.5c-0.9,1.77,1.32,4.95,1.51,7.52c0.17,2.29,0.13,5.04-1.03,6.79c-0.58,0.88-4.31,0.72-5.79-0.25
                c-2.98-1.94-5.38-4.77-8.06-7.18c-4.88-4.38-9.46-9.2-14.79-12.94C83.29,13.98,78.43,9.57,74.72,2.2z"/>
            <path onClick={() => props.onFill(85)} fill={props.fillColors[85]} d="M3.37,911.38c4.14,2.59,8.55,4.7,12.16,7.74c11.8,9.95,23.16,20.42,34.99,30.33
                c5.04,4.22,1.62,10.2,4.62,14.64c-0.29,0.3-0.58,0.59-0.86,0.89c-1.41-0.87-2.89-1.64-4.21-2.63
                c-15.03-11.28-30.04-22.56-45.02-33.9C1.58,925.81,0.79,918.22,3.37,911.38z"/>
            <path onClick={() => props.onFill(86)} fill={props.fillColors[86]} d="M867.83,1283.18c-3.06,0-5.76,0-9.29,0c3.56-5.82,6.74-11.11,10.05-16.33c0.6-0.94,1.63-2.12,2.59-2.24
                c9.01-1.18,9.81-11.77,17.87-13.94c-0.52-0.67-1.03-1.34-1.55-2.01c-2.92,2.52-6.18,4.74-8.66,7.64
                c-2.21,2.58-4.39,4.24-7.67,5.63c-3.67,1.55-6.37,5.47-9.35,8.5c-0.76,0.77-0.74,2.25-1.42,3.15c-2.67,3.53-5.22,9.54-8.3,9.86
                c-5.07,0.52-10.52-2.66-15.81-4.28c-0.04-0.5-0.08-1-0.12-1.5c3.95-2.32,7.75-4.97,11.9-6.87c5.46-2.49,9.7-6.01,13.22-10.93
                c8.43-11.79,21.78-13.59,34.63-16.2c0.88-0.18,1.89,0.26,2.51,0.37c-4.74,6.57-9.3,13.14-14.17,19.48
                c-1.44,1.87-3.53,3.7-5.71,4.46C871.38,1270.48,866.61,1274.47,867.83,1283.18z"/>
            <path onClick={() => props.onFill(87)} fill={props.fillColors[87]} d="M740.72,1390.62c0.09,1.97,0.48,3.6,2.89,1.89c2.53-1.79,4.94-3.75,7.45-5.55
                c3.2-2.3,5.87-5.38,10.51-5.25c1.44,0.04,3.09-2.17,4.38-3.6c1.71-1.9,3.16-4.04,4.72-6.07c0.51,0.31,1.01,0.61,1.52,0.92
                c-2.41,4.3-4.8,8.61-7.24,12.89c-3.52,6.17-7.51,12.12-10.52,18.54c-5.64,12.01-10.74,24.27-16,36.45
                c-1.51,3.51-2.58,7.23-4.31,10.62c-0.62,1.22-2.61,1.74-3.97,2.58c-0.39-1.4-1.49-3.1-1.05-4.16c3.13-7.6,6.64-15.05,9.89-22.6
                c1.91-4.45,3.03-8.73,0.54-13.73c-2.32-4.67-3.69-9.94-4.64-15.1c-0.75-4.07,3.08-5.79,5.91-7.74
                C740.8,1390.69,740.72,1390.62,740.72,1390.62z"/>
            <path onClick={() => props.onFill(88)} fill={props.fillColors[88]} d="M509.09,327.35c1.01-5.08,1.01-5.08-6.83-3.43c1.04-1.41,1.67-2.43,2.46-3.3c2.41-2.66,3.22-5.45,0.65-8.4
                c-2.49-2.85-1.77-4.85,1.11-6.93c2.37-1.7,4.15-3.81,2.17-7.2c-0.45-0.77,0.43-2.72,1.22-3.71c1.21-1.54,3.04-2.6,4.31-4.1
                c4.07-4.82,3.38-7.12-2.76-8.88c2.56-3.54,5.08-7.04,8.18-11.33c-2.94-0.48-4.34-0.71-7.02-1.15c2.18-1.74,3.56-2.85,5.16-4.14
                c2.77,3.66,5.3,7.01,7.85,10.38c-6.68,3.12-7,6.07-1.14,11.77c2.2,2.14,4.46,3.7,2.19,7.46c-0.63,1.04,1.74,3.81,2.61,5.85
                c0.7,1.64,2.14,3.74,1.64,4.99c-2.79,6.92-2.96,6.86,0.77,12.92c-2.07,2.01-5.6-4.28-7.06,1.23c-0.66,2.48-1.29,4.96-2.32,8.9
                c-2.77-1.86-5.36-3.6-7.66-5.14c-1.65,1.22-3.64,2.69-5.63,4.15L509.09,327.35z M521.13,312.34c-3.08-0.51-6.16-1.02-9.26-1.53
                c-0.17-1.16-0.34-2.31-0.5-3.46c-0.5-0.08-1-0.16-1.5-0.24c-0.42,2.05-1.04,4.09-1.12,6.16c-0.02,0.55,1.54,1.58,2.45,1.69
                c3.19,0.38,6.42,0.58,9.63,0.61c1.93,0.01,3.86-0.43,5.79-0.67c0.11-0.54,0.21-1.08,0.32-1.62c-2.1-1.73-4.2-3.46-6.3-5.18
                c-0.27,0.24-0.53,0.48-0.8,0.72c0.41,1.2,0.82,2.41,1.23,3.61L521.13,312.34z M520.57,296.22c-0.34,0.03-0.69,0.06-1.03,0.08
                c0.27,1.85,0.53,3.7,0.8,5.51c-4.97,1.92-4.55-3.5-6.9-5.24c-2.35,6.33,3.41,11.33,13.2,9.75
                C524.57,302.89,522.57,299.56,520.57,296.22z"/>
            <path onClick={() => props.onFill(89)} fill={props.fillColors[89]} d="M1120.58,717.05c4.39,1.14,8.57,3.81,13.41,0.83c1.05-0.65,3.4,0.71,5.12,1.27
                c3.6,1.16,7.17,2.38,7.71-4.05c4.64,2.65,8.69,4.84,12.58,7.28c0.81,0.51,1.46,1.94,1.46,2.95c0.07,9.82,0,19.64-0.07,29.46
                c0,0.43-0.42,0.86-1.35,2.66c-4.74-4.8-10.66-8.55-13.23-13.91c-4.32-9-10.48-15.63-18.45-21.07c-2.46-1.67-4.73-3.61-7.09-5.43
                L1120.58,717.05z"/>
            <path onClick={() => props.onFill(90)} fill={props.fillColors[90]} d="M851.83,1475.32c-0.75-0.86-1.5-1.72-2.39-2.75c-2.38,8.14,7.75,13.05,21.87,10.56
                c-1.83-0.79-3.03-1.31-4.27-1.85c7.01-4.5,8.24-4.24,12.98,2.34c1.84,2.56,1.26,4.6-1.48,5.5c-2.47,0.81-5.2,0.86-7.83,1.16
                c-4.06,0.46-7.95,0.94-9.64,5.68c-0.35,1-2.26,1.87-3.53,2.02c-2.8,0.31-5.67,0.31-8.47,0.05c-1.43-0.13-3.99-1.18-3.95-1.66
                c0.15-1.97,0.97-3.94,1.81-5.78c0.28-0.61,1.4-0.81,2.1-1.27c4.78-3.16,4.66-5.34-0.44-8.06c-0.59-0.31-1.38-0.46-1.74-0.94
                c-2.12-2.78-5.15-5.44-5.77-8.56c-0.3-1.49,3.76-4.49,6.36-5.73c2.9-1.38,6.35-1.8,9.62-2.17c2.96-0.34,6.07,0.4,8.95-0.17
                c2.36-0.46,4.83-1.8,6.5-3.5c0.48-0.49-1.67-3.56-2.79-5.8c2.94-0.24,6.43-0.53,9.45-0.78c3.3,7.06-3.6,10.2-4.23,14.86
                c-4.74-1.46-9.15-3.53-13.75-4.09c-7.5-0.92-10.98,3.55-9.42,11.01C851.75,1475.41,851.83,1475.32,851.83,1475.32z"/>
            <path onClick={() => props.onFill(91)} fill={props.fillColors[91]} d="M1069.21,1408.95c8.71,2,17.65,3.31,26.06,6.18c10.74,3.66,12.04,11.88,4.03,20
                c-1.79,1.82-3.36,2.6-5.36,0.3c-6.52-7.51-14.72-11-24.72-10.67c-1.39,0.05-3.37-1.69-4.13-3.11c-0.98-1.82-1.11-4.14-1.4-6.28
                C1063.03,1410.39,1063.86,1409.57,1069.21,1408.95z"/>
            <path onClick={() => props.onFill(92)} fill={props.fillColors[92]} d="M981.58,805.92c-2.24,1.31-4.36,2.93-6.75,3.88c-10.45,4.15-20.83,8.57-31.53,11.97
                c-7.94,2.52-16.34,3.6-25.12,5.45c1.99-10.27,9.52-17.68,19.68-18.86c11.21-1.3,22.55-1.43,33.83-2.03
                c3.32-0.18,6.64-0.22,9.96-0.33L981.58,805.92z"/>
            <path onClick={() => props.onFill(93)} fill={props.fillColors[93]} d="M971.81,1402.2c-5.01,0.93-11.16,1.88-17.21,3.28c-4.14,0.95-6.01-0.6-6.38-4.51
                c-0.6-6.24,1.43-11.82,4.65-16.98c1.84-2.94,4.53-3.33,7.84-2.31c3.22,0.99,6.66,1.25,9.96,2.03c1.38,0.33,2.95,0.88,3.87,1.86
                c1.79,1.92,3.45,4.06,4.7,6.36C983.78,1400.32,982.42,1402.45,971.81,1402.2z"/>
            <path onClick={() => props.onFill(94)} fill={props.fillColors[94]} d="M882.69,1087.8c2.06-4.14,4.02-8.34,6.22-12.41c4.72-8.75,9.84-17.29,14.23-26.2
                c1.87-3.81,4.31-4.78,7.88-4.01c5.31,1.15,10.56,2.54,17.27,4.17c-13.55,15.34-30.24,26.15-45.68,38.38
                C882.61,1087.74,882.69,1087.8,882.69,1087.8z"/>
            <path onClick={() => props.onFill(95)} fill={props.fillColors[95]} d="M68.66,730.97c-4.26-2.84-8.82-5.32-12.73-8.58c-13.16-10.96-26.07-22.22-39.14-33.29
                c-3.17-2.68-6.54-5.13-9.92-7.55c-5.29-3.8-6.03-6.05-3.67-12.96c25.06,17.53,45.88,39.29,65.38,62.45L68.66,730.97z"/>
            <path onClick={() => props.onFill(96)} fill={props.fillColors[96]} d="M590.92,220.75c5.47,9.27,11.18,18.41,16.31,27.86c2.45,4.51-0.43,10.08-5.6,13.56
                c-3.73-1.6-7.24-2.78-10.38-4.6c-1.71-0.99-3.99-3.1-3.96-4.66c0.21-10.71,0.97-21.4,1.55-32.1
                C589.54,220.79,590.23,220.77,590.92,220.75z"/>
            <path onClick={() => props.onFill(97)} fill={props.fillColors[97]} d="M176.54,1159.3c-12.82-0.69-25.69-0.74-36.11-9.81c-3.84-3.34-6.97-6.67-8.1-12.05
                c-0.6-2.88-3.43-5.45-5.66-7.77c-1.54-1.6-3.76-2.55-6.58-4.37c2.97-1.25,5.08-2.55,7.37-3.01c3.92-0.8,7.34-0.86,8.04,4.88
                c0.34,2.83,2.44,6.05,4.71,7.94c10.15,8.44,19.49,18.15,32.55,22.4C174.08,1157.93,175.28,1158.69,176.54,1159.3z"/>
            <path onClick={() => props.onFill(98)} fill={props.fillColors[98]} d="M1038.59,1451.44c-3.23-1.87-6.39-3.95-9.77-5.56c-3.39-1.62-7-2.76-11.32-4.43
                c1.08-2.41,1.68-5.34,3.39-7.26c4.78-5.37,8.18-10.92,5.13-18.62C1038.48,1418.89,1045.65,1439.45,1038.59,1451.44z"/>
            <path onClick={() => props.onFill(99)} fill={props.fillColors[99]} d="M911.12,1341.37c-2.8-0.39-5.6-0.73-8.39-1.16c-5.37-0.83-7.63-4.84-4.96-9.65c1.66-3,3.98-5.77,6.51-8.1
                c1.1-1.02,3.54-1.11,5.23-0.83c2.58,0.44,5.12,1.41,7.55,2.45c4.24,1.81,6.78,5,6.03,9.77c-0.77,4.91-4.53,6.64-8.96,7.24
                c-0.98,0.13-1.99,0.02-2.99,0.02C911.14,1341.2,911.13,1341.28,911.12,1341.37z"/>
            <path onClick={() => props.onFill(100)} fill={props.fillColors[100]} d="M964.07,1478.2c-7.25-0.49-12.9-0.65-18.47-1.38c-1.86-0.25-4.08-1.64-5.02-3.19
                c-0.47-0.77,1.28-3.4,2.56-4.62c6.71-6.42,25.12-5.46,31.42,1.45c2.48,2.72,1.98,5.04-1.36,6.06
                C969.75,1477.56,966.08,1477.85,964.07,1478.2z"/>
            <path onClick={() => props.onFill(101)} fill={props.fillColors[101]} d="M167.57,510.68c-1.33-4.97-0.44-10.23,2.06-15.15c3.59-7.08,7.75-13.86,11.23-20.99
                c1.71-3.5,2.31-7.53,3.63-11.24c1.6-4.52,3.43-8.96,6.27-12.99C185.88,471.6,185.73,494.63,167.57,510.68z"/>
            <path onClick={() => props.onFill(102)} fill={props.fillColors[102]} d="M869.92,1350.41c-2.73-2.56-5.06-4.35-6.84-6.59c-1.11-1.4-2.26-3.7-1.87-5.19
                c1.15-4.32,3.05-7.99,7.96-9.99c4.23-1.72,6.51-0.97,8.57,2.58c0.25,0.43,0.56,0.82,0.8,1.26
                C886.46,1346.67,885.07,1346.13,869.92,1350.41z"/>
            <path onClick={() => props.onFill(103)} fill={props.fillColors[103]} d="M707.22,1475.8c-0.36,4.37-0.67,8.04-0.98,11.79c-2.68,0.82-5.47,2.19-8.34,2.39
                c-2.6,0.18-5.49-0.47-7.89-1.56c-2.5-1.14-4.55-3.26-6.8-4.93c-3.25-2.41-3.37-5.86,0.25-7.4
                C691.49,1472.66,699.69,1472.41,707.22,1475.8z"/>
            <path onClick={() => props.onFill(104)} fill={props.fillColors[104]} d="M988.61,1455.41c-4.23-1.32-7.92-1.21-8.56-6.05c-0.67-5.05,1.61-9.11,6.04-10.66
                c1.87-0.66,3.91-1.12,5.5-2.21c4.13-2.84,7.61-1.25,10.92,1.28c4.04,3.08,3.74,5.24-0.49,7.94c-2.06,1.32-3.69,3.31-5.69,4.76
                C993.86,1452.24,991.2,1453.77,988.61,1455.41z"/>
            <path onClick={() => props.onFill(105)} fill={props.fillColors[105]} d="M359.89,157.84c1.92,6.97,5.85,12.09,3.18,18.75c-0.59,1.48,1.18,3.99,2.02,5.95
                c1.98,4.65,6.52,8.55,3.63,14.58c-0.44,0.91,1.28,2.86,2.62,5.59c-6.67-4.43-7.5-10.1-8.16-16.11c-0.39-3.48-1.81-6.85-2.77-10.27
                c-0.57,0.14-1.14,0.27-1.71,0.41c0.64,4.15,1.28,8.29,1.92,12.44c-0.35,0.24-0.69,0.48-1.04,0.72c-3.54-2.59-7.2-5.05-10.52-7.9
                c-0.67-0.58-0.51-2.9,0.06-4.01C352.4,171.55,355.91,165.24,359.89,157.84z"/>
            <path onClick={() => props.onFill(106)} fill={props.fillColors[106]} d="M1102.03,1456.73c-7.36,0.23-7.76-0.28-6.05-7.63c0.04-0.16,0.08-0.32,0.12-0.48
                c3.25-13.94,4.57-14.54,16.28-5.83c2.83,2.11,4.63,5.61,6.9,8.47c-3.27,1.57-6.44,3.43-9.85,4.6
                C1107.14,1456.65,1104.51,1456.47,1102.03,1456.73z"/>
            <path onClick={() => props.onFill(107)} fill={props.fillColors[107]} d="M952.05,1418.31c-2.33-0.89-4.09-1.13-5.27-2.09c-7.8-6.33-16.83-6.23-26.11-5.49
                c-2.05,0.16-4.19-0.71-6.28-1.11c1.36-2.34,2.24-6.06,4.18-6.73c4.06-1.4,8.82-2.1,13.02-1.41c5.14,0.84,10.08,3.19,14.94,5.28
                C950.98,1408.66,951.62,1412.86,952.05,1418.31z"/>
            <path onClick={() => props.onFill(108)} fill={props.fillColors[108]} d="M903.38,1318.05c-2.94,3.73-5.59,6.51-7.52,9.72c-1.28,2.14-1.52,4.9-2.23,7.38
                c-0.46,1.64-0.92,3.28-1.39,4.91c-2.41-0.75-5.68-0.77-7.03-2.4c-2.35-2.85-3.81-6.58-1.57-10.57c2.57-4.58,4.7-9.44,7.5-13.87
                c1.81-2.86,4.32-3.17,6.79-0.08C899.36,1314.93,901.35,1316.26,903.38,1318.05z"/>
            <path onClick={() => props.onFill(109)} fill={props.fillColors[109]} d="M892.46,1478.18c-3.62-0.44-6.31-0.55-8.88-1.16c-1.27-0.3-3.05-1.37-3.28-2.39
                c-0.3-1.34,0.26-3.9,1.2-4.34c6.42-3,12.94-5.84,19.67-8.03c1.12-0.36,4.32,2.43,4.87,4.27c1,3.34,1.87,7.68-2.88,9.29
                C899.43,1477.08,895.43,1477.55,892.46,1478.18z"/>
            <path onClick={() => props.onFill(110)} fill={props.fillColors[110]} d="M613.04,385.28c-3.44,0.38-6.97,0.42-10.31,1.23c-4.2,1.02-8.14,2.83-11.87-1.3
                c-0.83-0.91-3.37-1.17-4.64-0.62c-5.27,2.28-8.5-2.17-12.67-3.44c-0.24-0.07-0.25-0.87-0.21-0.71c2.25-2.07,4.21-3.84,6.14-5.65
                c3.09-2.9,6.3-1.97,9.71-0.56c6.57,2.71,13.27,5.13,19.82,7.88c1.5,0.63,2.64,2.13,3.94,3.24L613.04,385.28z"/>
            <path onClick={() => props.onFill(111)} fill={props.fillColors[111]} d="M554.65,512.25c-5.83-3.69-11.24-7.17-16.72-10.55c-3.32-2.05-4.12-4.31-1.56-7.67
                c1.36-1.78,2.03-4.08,3.3-6.77c2.1,1.29,4.14,2.28,5.85,3.67c2.05,1.67,3.53,4.19,5.76,5.44
                C558.31,500.31,556.94,505.82,554.65,512.25z"/>
            <path onClick={() => props.onFill(112)} fill={props.fillColors[112]} d="M571.89,1284.91c-4.32-2.7-8.26-5.68-8.36-11.65c-0.01-0.65-0.02-1.45-0.37-1.91
                c-2.78-3.63-2.43-6.19,0.84-9.98c4.65-5.39,10.66-5.88,14.02,0.55c1.56,2.99,2.25,6.43,3.56,10.33c-4.22-2.34-7.61-4.22-12.27-6.8
                c1.6,7.27,2.9,13.15,4.19,19.02C572.96,1284.62,572.43,1284.76,571.89,1284.91z"/>
            <path onClick={() => props.onFill(113)} fill={props.fillColors[113]} d="M779.11,1488.21c-2.78-0.58-7.09,0.55-6.96-5c0.14-6.11,5.66-12.02,11.18-11.9
                c4.62,0.1,7.37,3.77,7.45,9.94C790.84,1486.46,787.98,1488.22,779.11,1488.21z"/>
            <path onClick={() => props.onFill(114)} fill={props.fillColors[114]} d="M947.1,1277.01c7.06,0.29,8.79,3.2,5.94,9.22c-0.28,0.59-0.52,1.21-0.75,1.83
                c-2.43,6.39-3.92,7.37-9.35,6.59c-4.06-0.58-6.18-2.51-6.13-6.85c0.06-5.69,2.56-9.3,7.82-10.51
                C945.58,1277.08,946.58,1277.07,947.1,1277.01z"/>
            <path onClick={() => props.onFill(115)} fill={props.fillColors[115]} d="M588.05,301.32c-5.46,0-10.92,0-16.52,0c0.24,0.51,0.85,1.84,1.8,3.87c-3.02-1.94-5.5-3.53-8.26-5.3
                c11.39-3.96,11.39-3.96,8.64-8.73c1.01-0.7,2.04-1.41,3.42-2.36c-1.46-1.83-2.74-3.43-4.2-5.25c2.37-0.27,4.5-0.96,6.43-0.61
                c1.62,0.3,3.64,1.46,4.41,2.83c1.73,3.12,2.81,6.6,4.76,9.49c-0.19,2.04-0.37,4.09-0.56,6.13L588.05,301.32z"/>
            <path onClick={() => props.onFill(116)} fill={props.fillColors[116]} d="M970.94,1462.61c2.99-2.45,5.37-5.98,8.11-6.29c4.42-0.5,9.39,0.4,13.51,2.17c2.03,0.87,2.66,5,3.8,7.38
                c-5.44,1.67-10.62,3.27-16.47,5.07C977.62,1468.83,974.52,1465.95,970.94,1462.61z"/>
            <path onClick={() => props.onFill(117)} fill={props.fillColors[117]} d="M805.33,202.34c4.49,9.53,3.65,18.88,2.6,28.6c-2.31-7.31-7.9-11.09-14.87-13.74
                C796.93,211.5,797.47,204.38,805.33,202.34z"/>
            <path onClick={() => props.onFill(118)} fill={props.fillColors[118]} d="M156.86,156.97c-12.57-15.1-17.46-33.4-21.9-51.88c0.38-0.16,0.76-0.32,1.15-0.49
                c7.49,17.21,14.98,34.43,22.48,51.64C158.01,156.48,157.43,156.73,156.86,156.97z"/>
            <path onClick={() => props.onFill(119)} fill={props.fillColors[119]} d="M636.39,1251.78c1.83-1.48,4.5-4.81,5.71-4.35c2.3,0.88,4.68,3.67,5.41,6.14
                c2.14,7.16-2.37,13.05-5.07,19.15c-0.31,0.69-3.36,0.17-5.13,0.21c-0.03-1.66-0.77-4.08,0.05-4.84c3.89-3.63,2.73-8.18,2.7-12.44
                C640.05,1254.63,638.2,1253.61,636.39,1251.78z"/>
            <path onClick={() => props.onFill(120)} fill={props.fillColors[120]} d="M999.24,1406.68c4.02-9.16,12.07-12.32,20.01-8.65c3.6,1.67,3.48,4.27,2.96,7.41
                c-0.6,3.64-3.03,2.66-5.3,2.35c-2.95-0.41-5.9-0.88-8.87-1.07C1005.29,1406.54,1002.51,1406.68,999.24,1406.68z"/>
            <path onClick={() => props.onFill(121)} fill={props.fillColors[121]} d="M856.06,1336.35c-1.83-3.78-4.9-4.06-7.85-2.34c-3.14,1.82-2.88,4.51-0.44,7.04
                c2.26,2.35,4.47,4.75,6.41,7.6c-3.82-1.28-8.37-2.57-9.44-6.73c-0.72-2.8,0.85-6.69,2.48-9.45c2.04-3.45,5.67-3.29,9.34-2.03
                c2.67,0.92,3.6,2.29,2.28,4.78c-2.58,4.88-2.31,8.75,3.09,11.62c1.21,0.64,1.64,2.74,2.43,4.17c-0.34,0.38-0.69,0.76-1.03,1.14
                c-1.52-0.6-3.46-0.79-4.47-1.87c-2.87-3.04-5.45-6.38-7.97-9.73c-0.89-1.18-1.27-2.74-1.88-4.13c1.8-0.23,3.6-0.52,5.4-0.65
                C854.94,1335.75,855.51,1336.15,856.06,1336.35z"/>
            <path onClick={() => props.onFill(122)} fill={props.fillColors[122]} d="M896.41,1495.39c-2.96-1.6-8.01-2.77-8.43-4.87c-0.91-4.51,4.31-5.28,7.56-6.94
                c0.72-0.37,1.57-0.54,2.38-0.66c4.55-0.68,11.45,2.99,11.06,6.28c-0.64,5.3-5.53,4.07-8.98,4.9c-0.94,0.23-1.97,0.03-2.96,0.03
                C896.82,1494.55,896.62,1494.97,896.41,1495.39z"/>
            <path onClick={() => props.onFill(123)} fill={props.fillColors[123]} d="M998.54,1482.27c-4.33-1.5-8.77-2.75-12.94-4.6c-2.98-1.32-2.64-3.38-0.04-5.24
                c4.51-3.22,16.39-1.34,19.84,3.23c1.9,2.52,1.21,4.43-1.63,5.36c-1.53,0.5-3.27,0.37-4.92,0.53
                C998.75,1481.79,998.64,1482.03,998.54,1482.27z"/>
            <path onClick={() => props.onFill(124)} fill={props.fillColors[124]} d="M919.77,1490.95c-4.76-0.19-9.53-4.62-9.4-8.74c0.12-3.64,3.26-7.34,6.17-7.28
                c4.32,0.1,8.89,6.29,8.84,11.63C925.34,1490.58,922.91,1491.11,919.77,1490.95z"/>
            <path onClick={() => props.onFill(125)} fill={props.fillColors[125]} d="M959.61,1406.8c3.2,2.08,6.56,3.95,9.54,6.3c2.29,1.81,1.95,4.14,0.37,6.64
                c-1.63,2.58-3.32,1.62-5.51,1.16c-4.79-1.02-8.58-2.34-9.48-8.24C953.88,1408.37,955.44,1407.62,959.61,1406.8z"/>
            <path onClick={() => props.onFill(126)} fill={props.fillColors[126]} d="M947.91,1272.24c2.66-2.93,5.42-5.02,9.47-3.71c4.56,1.47,8.05,4.08,7.87,9.42
                c-0.04,1.21-0.99,2.91-2,3.43c-1,0.51-2.93,0.26-3.92-0.42C955.59,1278.37,952.07,1275.46,947.91,1272.24z"/>
            <path onClick={() => props.onFill(127)} fill={props.fillColors[127]} d="M768.13,163.23c5.23,2.82,10.19,5.49,15.15,8.16c-0.16,0.64-0.31,1.29-0.47,1.93c-7.27,0-14.54,0-22.82,0
                C762.97,169.63,765.55,166.43,768.13,163.23z"/>
            <path onClick={() => props.onFill(128)} fill={props.fillColors[128]} d="M1158.79,775.02c-5.38-4.52-10.75-9.04-16.13-13.56c0.34-0.6,0.68-1.19,1.02-1.79
                c5.28,1.35,10.97,1.92,15.62,4.42c1.85,0.99,1.41,6.25,2.02,9.57C1160.48,774.12,1159.63,774.57,1158.79,775.02z"/>
            <path onClick={() => props.onFill(129)} fill={props.fillColors[129]} d="M991.59,1408.19c-5.53,1.81-10.58,4.28-15.83,4.82c-2.33,0.24-5.07-3.39-8.13-5.65
                C978.24,1402.83,987.6,1403.31,991.59,1408.19z"/>
            <path onClick={() => props.onFill(130)} fill={props.fillColors[130]} d="M891.99,1299.44c-6.09-0.88-10.01-2.99-9.12-5.94c1.4-4.65,5.64-4.48,9.07-3.48
                c3.42,0.99,6.46,3.3,9.67,5.02C898.4,1296.51,895.19,1297.98,891.99,1299.44z"/>
            <path onClick={() => props.onFill(131)} fill={props.fillColors[131]} d="M125.2,137.19c-9.89-6.64-11.86-17.43-14.84-27.68C115.71,118.52,124.2,125.83,125.2,137.19z"/>
            <path onClick={() => props.onFill(132)} fill={props.fillColors[132]} d="M860.2,1467c3.65,1.35,6.93,2.07,9.66,3.72c2.56,1.54,2.5,4.32-0.65,5.05c-3.37,0.77-7.15,0.99-10.47,0.24
                c-1.63-0.37-2.64-3.48-3.94-5.36C856.56,1469.46,858.31,1468.27,860.2,1467z"/>
            <path onClick={() => props.onFill(133)} fill={props.fillColors[133]} d="M938.47,1467.07c-1.77,5.06-12.03,8.62-16.38,5.44c-0.75-0.54-1.03-3.12-0.43-3.88
                C924.87,1464.6,933.99,1463.92,938.47,1467.07z"/>
            <path onClick={() => props.onFill(134)} fill={props.fillColors[134]} d="M775.63,151.95c0.51,1.02,1.15,2.11,1.62,3.28c0.92,2.28,1.77,4.59,2.61,6.91
                c0.31,0.85,0.53,1.73,0.79,2.59c-1,0.22-2.06,0.73-3,0.57c-1.27-0.21-2.54-0.79-3.65-1.48c-2.85-1.76-5.39-4.23-9.26-3.15
                c-0.47,0.13-1.25-0.81-2.6-1.75c2.57-1.44,4.59-2.64,6.68-3.72C770.8,154.17,772.85,153.27,775.63,151.95z"/>
            <path onClick={() => props.onFill(135)} fill={props.fillColors[135]} d="M918.9,1321.38c-6.51-3.07-13.03-6.14-19.54-9.21C909.34,1307.18,917.33,1310.88,918.9,1321.38z"/>
            <path onClick={() => props.onFill(136)} fill={props.fillColors[136]} d="M994.6,1404.83c-2.85-1.36-5.43-2.43-7.82-3.82c-0.82-0.48-1.71-1.81-1.61-2.63
                c0.1-0.84,1.47-2.28,2.12-2.2c5.42,0.67,10.31-0.6,14.96-3.29c0.36-0.21,1.16,0.35,2.19,0.69
                C1001.02,1397.49,997.84,1401.12,994.6,1404.83z"/>
            <path onClick={() => props.onFill(137)} fill={props.fillColors[137]} d="M890.51,1417.19c1.37-1.02,2.61-2.39,4.14-3c4.03-1.59,8.08-2.78,9.86-7.54c0.37-0.99,3.11-1.27,4.8-1.47
                c0.52-0.06,1.78,1.33,1.69,1.89c-0.28,1.69-0.63,3.73-1.74,4.83c-1.19,1.18-3.34,1.32-4.97,2.12c-2.61,1.28-5.07,2.92-7.74,4.05
                c-1.67,0.7-3.62,0.73-5.44,1.07C890.9,1418.5,890.71,1417.84,890.51,1417.19z"/>
            <path onClick={() => props.onFill(138)} fill={props.fillColors[138]} d="M483.18,201.36c-5.53,0.89-10.81,0.83-15.59-4.19c3.4-2.69,6.55-5.18,10-7.89
                c1.94,4.28,3.73,8.23,5.52,12.17C483.1,201.45,483.18,201.36,483.18,201.36z"/>
            <path onClick={() => props.onFill(139)} fill={props.fillColors[139]} d="M724.53,1481.64c-3.38,0.2-7.67,0.45-7.5-4.01c0.15-4.08,4.56-4.08,7.84-4.05
                c3.3,0.03,7.58,0.23,7.42,4.39c-0.07,1.71-4.51,3.25-6.97,4.87C725.05,1482.43,724.79,1482.03,724.53,1481.64z"/>
            <path onClick={() => props.onFill(140)} fill={props.fillColors[140]} d="M890.58,1377.73c4.28-4.27,13.81-5.32,17.63-2.49c2.55,1.89,1.65,3.57-0.76,4.25
                C901.88,1381.05,896.45,1380.44,890.58,1377.73z"/>
            <path onClick={() => props.onFill(141)} fill={props.fillColors[141]} d="M631.96,1253.62c1.81,5.08-0.64,8.57-3.41,11.08c-1.17,1.06-5.62,0.41-6.94-1
                c-2.46-2.62-0.07-5.39,2.32-6.9C626.2,1255.36,628.99,1254.75,631.96,1253.62z"/>
            <path onClick={() => props.onFill(142)} fill={props.fillColors[142]} d="M518.98,343.68c0.21-4.25,0.21-7.79,0.72-11.26c0.08-0.58,2.51-1.43,3.55-1.12
                c2.01,0.6,3.79,1.94,5.72,3.01C528.6,339.83,524.98,341.97,518.98,343.68z"/>
            <path onClick={() => props.onFill(143)} fill={props.fillColors[143]} d="M707.01,374.19c-8.01,1.58-15.31,3.03-22.86,4.52C690.04,369.9,697.49,369.03,707.01,374.19z"/>
            <path onClick={() => props.onFill(144)} fill={props.fillColors[144]} d="M890.63,1392.17c3.79-0.49,7.59,1.32,9.16,5.38c2.32,5.98,1.73,8.38-3.06,11.55
                c-0.27-3.32,0.14-6.72-0.95-9.55C894.63,1396.57,892.06,1394.14,890.63,1392.17z"/>
            <path onClick={() => props.onFill(145)} fill={props.fillColors[145]} d="M880.25,1430.77c-0.43-2.14-0.86-4.28-1.29-6.42c0.32-0.11,0.64-0.22,0.97-0.34
                c0.66,1.23,1.32,2.47,1.98,3.7c0.48-0.08,0.97-0.15,1.45-0.23c-0.2-1.42,0.06-3.73-0.68-4.11c-6.17-3.21-6.36-8.96-6.71-14.68
                c4.05,1.14,5.41,3.98,4.35,6.54c2.12,3.7,4.6,6.4,5.07,9.41c0.32,2.05-2.18,4.54-3.42,6.83
                C881.39,1431.24,880.82,1431,880.25,1430.77z"/>
            <path onClick={() => props.onFill(146)} fill={props.fillColors[146]} d="M469.09,190.8c-3.44,1.94-6.9,2.4-8.94-3c-0.89,1.04-1.47,1.71-2.02,2.35c-2.6-0.81-2.13-8.29,0.27-10.81
                C461.89,183.08,465.41,186.85,469.09,190.8z"/>
            <path onClick={() => props.onFill(147)} fill={props.fillColors[147]} d="M905.63,1297.17c2.53-2.64,12.31-3.58,14.34-1.05c0.52,0.64-0.29,3.38-1.23,3.99
                C914.82,1302.61,908.93,1301.1,905.63,1297.17z"/>
            <path onClick={() => props.onFill(148)} fill={props.fillColors[148]} d="M916.2,1357.44c-4.76,2.18-10.9,1.39-11.09-1.52c-0.11-1.61,1.45-4.2,2.93-4.89
                C912.19,1349.1,914.86,1351.34,916.2,1357.44z"/>
            <path onClick={() => props.onFill(149)} fill={props.fillColors[149]} d="M909.85,1393.79c2.46,2.02,5.87,3.55,5.88,5.11c0.03,3.13-2.92,3.92-5.94,3.01
                c-0.32-0.1-0.66-0.09-0.98-0.19c-1.93-0.62-5.71,1.02-5.09-2.65C904.04,1397.24,907.26,1395.91,909.85,1393.79z"/>
            <path onClick={() => props.onFill(150)} fill={props.fillColors[150]} d="M895.42,1385.91c-5,3.66-10.12,3.04-14.66-1.5C885.1,1381.38,891.38,1381.92,895.42,1385.91z"/>
            <path onClick={() => props.onFill(151)} fill={props.fillColors[151]} d="M911.71,1390.73c-2.93,0.97-5.79,2.18-8.77,2.77c-1.05,0.21-2.84-0.78-3.47-1.75
                c-0.46-0.7,0.08-2.61,0.79-3.42C903.07,1385.16,909.47,1386.5,911.71,1390.73z"/>
            <path onClick={() => props.onFill(152)} fill={props.fillColors[152]} d="M419.06,184.38c5.52,0.43,10.93,0.85,16.52,1.28C431.89,192.13,425.74,191.54,419.06,184.38z"/>
            <path onClick={() => props.onFill(153)} fill={props.fillColors[153]} d="M698.07,1494.2c3.31-0.72,6.59-1.84,9.93-2.06c5.63-0.38,10.61-4.92,16.63-2.51
                c0.69,0.28,1.41,0.45,3.49,1.1c-10.86,1.75-20.28,3.27-29.7,4.8C698.31,1495.09,698.19,1494.65,698.07,1494.2z"/>
            <path onClick={() => props.onFill(154)} fill={props.fillColors[154]} d="M201.33,419.59c2.84,6.89-2.35,9.58-6.55,12.78C193.79,426.55,196.1,421.95,201.33,419.59z"/>
            <path onClick={() => props.onFill(155)} fill={props.fillColors[155]} d="M886.43,1405.38c-3.32-0.27-6.73-0.19-9.9-1.02c-1.03-0.27-1.43-2.93-2.12-4.5
                c1.55-0.34,3.4-1.38,4.58-0.88c2.77,1.19,5.21,3.13,7.79,4.76C886.67,1404.29,886.55,1404.84,886.43,1405.38z"/>
            <path onClick={() => props.onFill(156)} fill={props.fillColors[156]} d="M1005.98,280.64c8.08,4.68,11.81,12.63,15.87,20.33c-0.33,0.25-0.67,0.49-1,0.74
                C1015.89,294.68,1010.94,287.66,1005.98,280.64z"/>
            <path onClick={() => props.onFill(157)} fill={props.fillColors[157]} d="M890.72,1499.13c-3.89-1.74-7.78-3.49-12.78-5.72c2.58-1.8,3.94-2.74,4.63-3.23
                c3.03,2.8,5.67,5.24,8.3,7.67C890.82,1498.28,890.77,1498.71,890.72,1499.13z"/>
            <path onClick={() => props.onFill(158)} fill={props.fillColors[158]} d="M786.82,184.03c-3.27-1.6-6.55-3.19-10.43-5.09c5.29-3.87,9.1-2.16,11.34,3.68
                C787.43,183.08,787.13,183.55,786.82,184.03z"/>
            <path onClick={() => props.onFill(159)} fill={props.fillColors[159]} d="M580.96,272.01c1.65,1.25,3.06,2.32,4.48,3.39c-4.26,4.92-5.84,5.12-10.53,1.37
                C576.83,275.26,578.73,273.77,580.96,272.01z"/>
            <path onClick={() => props.onFill(160)} fill={props.fillColors[160]} d="M899.29,1287.89c6.31,0.95,11.31,1.71,16.32,2.46C910.58,1289.22,904.71,1295.61,899.29,1287.89z"/>
            <path onClick={() => props.onFill(161)} fill={props.fillColors[161]} d="M564.01,1445.53c1.92,2.5,3.5,4.55,5.94,7.74c-1.93,0.39-3.98,1.5-5.06,0.9
                C561.27,1452.14,561.38,1449.11,564.01,1445.53z"/>
            <path onClick={() => props.onFill(162)} fill={props.fillColors[162]} d="M715.87,1483.94c-1.56,1.65-2.55,2.71-3.55,3.76c-1.01-1.55-2.57-3-2.86-4.67
                c-0.2-1.16,1.34-2.62,2.11-3.94C712.86,1480.54,714.14,1481.99,715.87,1483.94z"/>
            <path onClick={() => props.onFill(163)} fill={props.fillColors[163]} d="M903.45,1344.37c7.35-0.89,10.34-0.2,11.78,2.71C905.16,1347.35,906.13,1349.34,903.45,1344.37z"/>
            <path onClick={() => props.onFill(164)} fill={props.fillColors[164]} d="M733.8,1483.79c-2.18,2.79-5.65,3.33-14.74,1.52C724.89,1484.72,729.48,1484.24,733.8,1483.79z"/>
            <path onClick={() => props.onFill(165)} fill={props.fillColors[165]} d="M901.83,1351.26c-2.03-0.31-4.05-0.62-6.08-0.93c0.32-0.97,0.67-2.8,0.94-2.78
                c1.91,0.09,3.8,0.55,5.7,0.89C902.21,1349.38,902.02,1350.32,901.83,1351.26z"/>
            <path onClick={() => props.onFill(166)} fill={props.fillColors[166]} d="M859.17,1326.42c3.19-0.85,6.38-1.7,9.57-2.55c0.17,0.59,0.34,1.18,0.51,1.77
                c-3.15,1.01-6.3,2.03-9.45,3.04C859.59,1327.92,859.38,1327.17,859.17,1326.42z"/>
            <path onClick={() => props.onFill(167)} fill={props.fillColors[167]} d="M513.89,329.97c-0.16,2.14-0.31,4.29-0.47,6.43c-0.4,0.15-0.81,0.3-1.21,0.45
                c-1.05-1.26-2.98-2.57-2.91-3.77c0.07-1.32,1.98-2.54,3.09-3.8C512.89,329.51,513.39,329.74,513.89,329.97z"/>
            <path onClick={() => props.onFill(168)} fill={props.fillColors[168]} d="M423.13,209.7c2.43-0.77,4.85-1.53,8.11-2.56c-0.27,1.76-0.34,3.87-0.63,3.9
                c-2.42,0.27-4.87,0.15-7.32,0.15C423.23,210.69,423.18,210.19,423.13,209.7z"/>
            <path onClick={() => props.onFill(169)} fill={props.fillColors[169]} d="M884.27,1485.26c-0.28-0.63-0.92-2.06-1.95-4.37c2.64,0.2,4.58,0.34,6.52,0.49c0.2,0.33,0.41,0.67,0.61,1
                C887.75,1483.32,886.05,1484.27,884.27,1485.26z"/>
            <path onClick={() => props.onFill(170)} fill={props.fillColors[170]} d="M349.33,196.52c2.81,3.41,5.62,6.82,8.44,10.23c-0.64,0.45-1.28,0.91-1.92,1.36
                c-2.15-3.89-4.3-7.77-6.44-11.66C349.41,196.45,349.33,196.52,349.33,196.52z"/>
            <path onClick={() => props.onFill(171)} fill={props.fillColors[171]} d="M886.89,1463.02c-2.57,1.42-4.66,2.58-7.2,3.98C881.69,1461.7,883.81,1460.45,886.89,1463.02z"/>
            <path onClick={() => props.onFill(172)} fill={props.fillColors[172]} d="M870.6,1496.68c2.32,0,4.63,0,6.95,0c0,0.33,0,0.66,0.01,1c-2.32,0-4.63,0-6.95,0
                C870.61,1497.35,870.61,1497.02,870.6,1496.68z"/>
            <path onClick={() => props.onFill(173)} fill={props.fillColors[173]} d="M772.24,139.89c-0.37,1.32-0.65,2.68-1.22,3.9c-0.07,0.16-1.64-0.37-2.51-0.58
                c0.59-1.32,1.18-2.64,1.77-3.96C770.94,139.46,771.59,139.68,772.24,139.89z"/>
            <path onClick={() => props.onFill(174)} fill={props.fillColors[174]} d="M520.64,259.28c1.33,1.1,2.48,2.07,3.92,3.26C519.82,263.65,519.59,263.45,520.64,259.28z"/>
            <path onClick={() => props.onFill(175)} fill={props.fillColors[175]} d="M920.03,1344.39c1.58-0.48,3.17-0.96,4.75-1.44c0.21,0.59,0.42,1.18,0.63,1.77
                c-1.56,0.61-3.12,1.22-4.68,1.83C920.5,1345.83,920.26,1345.11,920.03,1344.39z"/>
            <path onClick={() => props.onFill(176)} fill={props.fillColors[176]} d="M882.68,1408.93c0.97,0.27,1.93,0.54,2.9,0.82c-0.2,0.56-0.4,1.11-0.61,1.67
                c-0.94-0.38-1.88-0.76-2.82-1.14C882.32,1409.82,882.5,1409.38,882.68,1408.93z"/>
            <path onClick={() => props.onFill(177)} fill={props.fillColors[177]} d="M433.15,198.29c-1.26,0.4-2.52,0.8-3.78,1.2c-0.16-0.43-0.31-0.87-0.47-1.3c1.24-0.45,2.49-0.89,3.73-1.34
                C432.81,197.33,432.98,197.81,433.15,198.29z"/>
            <path onClick={() => props.onFill(178)} fill={props.fillColors[178]} d="M881.39,1438.02c0.32-1.25,0.64-2.5,0.97-3.75c0.47,0.13,0.94,0.25,1.41,0.38
                c-0.35,1.24-0.69,2.48-1.04,3.73C882.27,1438.26,881.83,1438.14,881.39,1438.02z"/>
            <path onClick={() => props.onFill(179)} fill={props.fillColors[179]} d="M729.44,1458.24c-0.24,1.12-0.48,2.25-0.72,3.37c-0.43-0.06-0.87-0.12-1.3-0.18
                c0.12-1.14,0.25-2.28,0.37-3.42C728.34,1458.09,728.89,1458.16,729.44,1458.24z"/>
            <path onClick={() => props.onFill(180)} fill={props.fillColors[180]} d="M908.3,1478.55c-0.64,0.76-1.28,1.52-1.92,2.28c-0.44-0.41-0.89-0.82-1.33-1.23
                c0.79-0.61,1.58-1.23,2.37-1.84C907.72,1478.02,908.01,1478.29,908.3,1478.55z"/>
            <path onClick={() => props.onFill(181)} fill={props.fillColors[181]} d="M532.39,328.45c-1.06-0.33-2.13-0.66-3.19-0.99c0.07-0.37,0.15-0.75,0.22-1.12
                c1.1,0.19,2.21,0.38,3.31,0.57C532.61,327.43,532.5,327.94,532.39,328.45z"/>
            <path onClick={() => props.onFill(182)} fill={props.fillColors[182]} d="M942.86,1463.84c-1.08,0-2.17,0-3.25,0c0-0.36,0-0.72,0-1.09c1.08,0,2.17,0,3.25,0
                C942.86,1463.12,942.86,1463.48,942.86,1463.84z"/>
            <path onClick={() => props.onFill(183)} fill={props.fillColors[183]} d="M209.23,403.16c-0.27,0.98-0.53,1.96-0.8,2.94c-0.31-0.06-0.62-0.13-0.93-0.19
                c0.17-0.97,0.34-1.95,0.52-2.92C208.43,403.05,208.83,403.1,209.23,403.16z"/>
            <path onClick={() => props.onFill(184)} fill={props.fillColors[184]} d="M651.56,1315.08c0.2-0.85,0.4-1.7,0.6-2.54c0.22,0.05,0.45,0.1,0.67,0.14c-0.15,0.85-0.31,1.7-0.46,2.55
                C652.1,1315.17,651.83,1315.13,651.56,1315.08z"/>
            <path onClick={() => props.onFill(185)} fill={props.fillColors[185]} d="M1061.27,1179.64c0.24,0.38,0.48,0.77,0.72,1.15c-0.34,0.2-0.69,0.4-1.03,0.6
                c-0.18-0.41-0.41-0.81-0.51-1.24C1060.43,1180.06,1060.91,1179.86,1061.27,1179.64z"/>
            <path onClick={() => props.onFill(186)} fill={props.fillColors[186]} d="M848.34,1448.71c0.24,0.63,0.38,1,0.53,1.36c-0.29,0.05-0.61,0.19-0.87,0.13
                c-0.25-0.06-0.45-0.34-0.67-0.52C847.59,1449.42,847.87,1449.16,848.34,1448.71z"/>
            <path onClick={() => props.onFill(187)} fill={props.fillColors[187]} d="M855.58,1479.54c-1.25-1.4-2.5-2.81-3.75-4.21c0,0-0.07,0.08-0.07,0.08c1.25,1.4,2.5,2.8,3.75,4.2
                L855.58,1479.54z"/>
            <path onClick={() => props.onFill(188)} fill={props.fillColors[188]} d="M956.01,1291.15c-0.29-0.38-0.67-0.73-0.62-0.83c0.2-0.36,0.55-0.64,0.84-0.96
                c0.18,0.24,0.56,0.54,0.51,0.69C956.62,1290.44,956.29,1290.76,956.01,1291.15z"/>
            <path onClick={() => props.onFill(189)} fill={props.fillColors[189]} d="M642.36,1277.36c-1.21,0.19-2.41,0.39-3.62,0.58c0.01-0.31,0.02-0.62,0.03-0.93
                c1.16,0.14,2.33,0.28,3.5,0.41C642.27,1277.41,642.36,1277.36,642.36,1277.36z"/>
            <path onClick={() => props.onFill(190)} fill={props.fillColors[190]} d="M921.47,1398.01c-0.44-0.37-0.77-0.65-1.1-0.92c0.31-0.27,0.63-0.75,0.93-0.74
                c0.37,0.01,0.74,0.43,1.11,0.67C922.12,1397.32,921.83,1397.62,921.47,1398.01z"/>
            <path onClick={() => props.onFill(191)} fill={props.fillColors[191]} d="M966.85,1461.45c-0.65,0.05-1.3,0.11-1.94,0.12c-0.03,0-0.06-0.49-0.09-0.75c0.65-0.07,1.31-0.13,1.96-0.2
                C966.8,1460.9,966.83,1461.18,966.85,1461.45z"/>
            <path onClick={() => props.onFill(192)} fill={props.fillColors[192]} d="M649.57,1306.8c-0.07-0.3-0.19-0.59-0.18-0.88c0-0.08,0.39-0.14,0.6-0.21c0.04,0.15,0.16,0.34,0.11,0.44
                C649.97,1306.38,649.76,1306.57,649.57,1306.8z"/>
            <path onClick={() => props.onFill(193)} fill={props.fillColors[193]} d="M685.49,1488.32c0.36,0.31,0.73,0.62,1.09,0.93c-0.13,0.15-0.25,0.3-0.38,0.45
                c-0.37-0.3-0.74-0.61-1.11-0.91C685.23,1488.63,685.36,1488.47,685.49,1488.32z"/>
            <path onClick={() => props.onFill(194)} fill={props.fillColors[194]} d="M892.08,1405.92c0.26-0.02,0.52-0.04,0.78-0.07c-0.02,0.15,0,0.41-0.06,0.42c-0.24,0.07-0.5,0.07-0.75,0.1
                C892.06,1406.22,892.07,1406.07,892.08,1405.92z"/>
            <path onClick={() => props.onFill(195)} fill={props.fillColors[195]} d="M187.37,609.98c-0.95-0.59-1.89-1.19-2.84-1.78c0,0-0.07,0.08-0.07,0.08c0.94,0.59,1.89,1.19,2.83,1.78
                C187.3,610.06,187.37,609.98,187.37,609.98z"/>
            <path onClick={() => props.onFill(196)} fill={props.fillColors[196]} d="M1107.55,816.94c-0.99-0.62-1.98-1.24-2.96-1.86c0,0-0.07,0.08-0.07,0.08c0.99,0.62,1.97,1.24,2.96,1.86
                C1107.47,817.02,1107.55,816.94,1107.55,816.94z"/>
            <path onClick={() => props.onFill(197)} fill={props.fillColors[197]} d="M532.57,322.8c0.11,0.24,0.23,0.48,0.3,0.73c0.01,0.03-0.23,0.14-0.35,0.21
                c-0.11-0.25-0.23-0.49-0.31-0.75C532.2,322.97,532.44,322.87,532.57,322.8z"/>
            <path onClick={() => props.onFill(198)} fill={props.fillColors[198]} d="M888.78,1422.29c0.21,0.1,0.4,0.13,0.43,0.22c0.04,0.09-0.08,0.25-0.13,0.38
                C888.99,1422.71,888.9,1422.54,888.78,1422.29z"/>
            <path onClick={() => props.onFill(199)} fill={props.fillColors[199]} d="M774.1,146.57c-0.08-0.22-0.19-0.37-0.16-0.47c0.03-0.09,0.23-0.12,0.35-0.17
                C774.24,146.12,774.18,146.31,774.1,146.57z"/>
            <path onClick={() => props.onFill(200)} fill={props.fillColors[200]} d="M1029.74,328.05c-0.19-0.21-0.38-0.42-0.41-0.45c0.02,0.03,0.19,0.28,0.35,0.52L1029.74,328.05z"/>
            <polygon onClick={() => props.onFill(201)} fill={props.fillColors[201]} points="881.82,1390.57 882.05,1390.75 881.91,1390.58 		"/>
            <path onClick={() => props.onFill(202)} fill={props.fillColors[202]} d="M1029.69,328.13l0,0.29c0,0,0.04-0.35,0.05-0.36C1029.74,328.05,1029.69,328.13,1029.69,328.13z"/>
            <path onClick={() => props.onFill(203)} fill={props.fillColors[203]} d="M865.65,1494.38c-0.1,0.2-0.2,0.41-0.3,0.61c0-0.11-0.05-0.25,0-0.31c0.09-0.1,0.23-0.15,0.36-0.22
                C865.71,1494.47,865.65,1494.38,865.65,1494.38z"/>
            <path onClick={() => props.onFill(204)} fill={props.fillColors[204]} d="M633.93,1330.53c-0.34,0.7-0.68,1.39-1.01,2.09c0,0,0.08,0.03,0.08,0.03c0.33-0.67,0.67-1.34,1-2.01
                C634,1330.63,633.93,1330.53,633.93,1330.53z"/>
            <polygon onClick={() => props.onFill(205)} fill={props.fillColors[205]} points="1038.51,1306.49 1038.63,1306.26 1038.45,1306.41 		"/>
            <path onClick={() => props.onFill(206)} fill={props.fillColors[206]} d="M855.5,1479.6c0.62,0.38,1.23,0.76,1.85,1.13l0.09,0.03c-0.62-0.41-1.23-0.82-1.85-1.22
                C855.58,1479.54,855.5,1479.6,855.5,1479.6z"/>
            <path onClick={() => props.onFill(207)} fill={props.fillColors[207]} d="M188.49,611.31c0.58,0.26,1.16,0.52,1.73,0.77l0.08-0.07c-0.58-0.26-1.16-0.52-1.74-0.79L188.49,611.31z"
                />
            <path onClick={() => props.onFill(208)} fill={props.fillColors[208]} d="M1038.45,1306.41c-0.34,0.69-0.69,1.39-1.03,2.08l0.05,0.08c0.35-0.69,0.7-1.39,1.04-2.08
                C1038.51,1306.49,1038.45,1306.41,1038.45,1306.41z"/>
            <polygon onClick={() => props.onFill(209)} fill={props.fillColors[209]} points="888.64,1412.5 888.93,1412.31 888.6,1412.41 		"/>
            <path onClick={() => props.onFill(210)} fill={props.fillColors[210]} d="M111.84,1152.53l-0.41,0.09c0,0,0.32-0.04,0.32-0.05C111.75,1152.56,111.84,1152.53,111.84,1152.53z"/>
            <path onClick={() => props.onFill(211)} fill={props.fillColors[211]} d="M181.13,606.31c0.74,0.24,1.48,0.48,2.21,0.72c0,0,0.07-0.08,0.07-0.08c-0.73-0.24-1.46-0.47-2.19-0.71
                C181.22,606.25,181.13,606.31,181.13,606.31z"/>
            <path onClick={() => props.onFill(212)} fill={props.fillColors[212]} d="M857.35,1480.74l0.43-0.05c0,0-0.32,0.07-0.33,0.07C857.44,1480.76,857.35,1480.74,857.35,1480.74z"/>
            <path onClick={() => props.onFill(213)} fill={props.fillColors[213]} d="M184.53,608.2c-0.37-0.41-0.74-0.83-1.11-1.24c0,0-0.07,0.08-0.07,0.08c0.37,0.41,0.74,0.82,1.11,1.24
                C184.46,608.27,184.53,608.2,184.53,608.2z"/>
            <path onClick={() => props.onFill(214)} fill={props.fillColors[214]} d="M188.57,611.22c-0.4-0.41-0.79-0.83-1.19-1.24c0,0-0.08,0.07-0.08,0.07c0.4,0.41,0.79,0.83,1.19,1.25
                C188.49,611.31,188.57,611.22,188.57,611.22z"/>
            <path onClick={() => props.onFill(215)} fill={props.fillColors[215]} d="M888.6,1412.41c0,0,0.05,0.09,0.05,0.09C888.64,1412.5,888.6,1412.41,888.6,1412.41z"/>
            <path onClick={() => props.onFill(216)} fill={props.fillColors[216]} d="M190.22,612.08c0.37,0.3,0.74,0.6,1.11,0.9c0,0,0.07-0.08,0.07-0.08c-0.37-0.3-0.73-0.59-1.1-0.89
                C190.3,612.01,190.22,612.08,190.22,612.08z"/>
            <path onClick={() => props.onFill(217)} fill={props.fillColors[217]} d="M612.97,385.35c0,0,0.07-0.08,0.07-0.07C613.04,385.28,612.97,385.35,612.97,385.35z"/>
            <path onClick={() => props.onFill(218)} fill={props.fillColors[218]} d="M192.3,614.14c-0.3-0.41-0.59-0.82-0.89-1.24c0,0-0.07,0.08-0.07,0.08c0.29,0.38,0.59,0.76,0.88,1.15
                L192.3,614.14z"/>
            <path onClick={() => props.onFill(219)} fill={props.fillColors[219]} d="M829.69,1417.49l-0.05,0.03L829.69,1417.49z"/>
            <polygon onClick={() => props.onFill(220)} fill={props.fillColors[220]} points="192.22,614.13 192.41,614.24 192.3,614.14 		"/>
            <polygon onClick={() => props.onFill(221)} fill={props.fillColors[221]} points="829.64,1417.52 829.79,1417.64 829.69,1417.49 		"/>
            <path onClick={() => props.onFill(222)} fill={props.fillColors[222]} d="M162.44,675.13c0,0,0.07-0.07,0.07-0.07S162.44,675.13,162.44,675.13z"/>
            <path onClick={() => props.onFill(223)} fill={props.fillColors[223]} d="M586.03,349.31l0.08,0.06L586.03,349.31z"/>
            <path onClick={() => props.onFill(224)} fill={props.fillColors[224]} d="M586.11,349.37c0,0-0.09-0.06-0.08-0.06C586.03,349.31,586.11,349.37,586.11,349.37z"/>
            <path onClick={() => props.onFill(225)} fill={props.fillColors[225]} d="M1120.67,717.03c0,0-0.1,0.02-0.1,0.02C1120.58,717.05,1120.67,717.03,1120.67,717.03z"/>
            <path onClick={() => props.onFill(226)} fill={props.fillColors[226]} d="M68.58,731.04c0,0,0.07-0.06,0.08-0.06C68.66,730.97,68.58,731.04,68.58,731.04z"/>
            <polygon onClick={() => props.onFill(227)} fill={props.fillColors[227]} points="881.91,1390.58 881.69,1390.51 881.82,1390.57 		"/>
            <path onClick={() => props.onFill(228)} fill={props.fillColors[228]} d="M509.01,327.29c0,0,0.09,0.06,0.09,0.06S509.01,327.29,509.01,327.29z"/>
            <path onClick={() => props.onFill(229)} fill={props.fillColors[229]} d="M741.67,1389.58c-0.31,0.35-0.63,0.69-0.94,1.04c0,0,0.08,0.07,0.07,0.07c0.31-0.35,0.63-0.69,0.95-1.04
                C741.75,1389.65,741.67,1389.58,741.67,1389.58z"/>
            <path onClick={() => props.onFill(230)} fill={props.fillColors[230]} d="M742.87,1388.72c-0.4,0.29-0.8,0.57-1.2,0.86c0,0,0.08,0.07,0.07,0.07c0.37-0.28,0.75-0.56,1.12-0.85
                C742.86,1388.81,742.87,1388.72,742.87,1388.72z"/>
            <path onClick={() => props.onFill(231)} fill={props.fillColors[231]} d="M877.78,1387.74l0.08-0.08L877.78,1387.74z"/>
            <polygon onClick={() => props.onFill(232)} fill={props.fillColors[232]} points="742.86,1388.81 742.96,1388.6 742.87,1388.72 		"/>
            <path onClick={() => props.onFill(233)} fill={props.fillColors[233]} d="M877.86,1387.66c-0.33-0.36-0.65-0.73-0.98-1.09l-0.08,0.03c0.33,0.38,0.65,0.75,0.98,1.13
                C877.78,1387.74,877.86,1387.66,877.86,1387.66z"/>
            <path onClick={() => props.onFill(234)} fill={props.fillColors[234]} d="M981.65,806c0,0-0.08-0.08-0.07-0.08C981.58,805.92,981.65,806,981.65,806z"/>
            <path onClick={() => props.onFill(235)} fill={props.fillColors[235]} d="M876.88,1386.57c0,0-0.09,0.03-0.08,0.03C876.8,1386.6,876.88,1386.57,876.88,1386.57z"/>
            <path onClick={() => props.onFill(236)} fill={props.fillColors[236]} d="M1143.28,807.97c0,0,0.09-0.03,0.09-0.02C1143.37,807.95,1143.28,807.97,1143.28,807.97z"/>
            <path onClick={() => props.onFill(237)} fill={props.fillColors[237]} d="M129.65,312.28c0,0-0.09,0.04-0.09,0.04C129.56,312.32,129.65,312.28,129.65,312.28z"/>
            <path onClick={() => props.onFill(238)} fill={props.fillColors[238]} d="M1103.39,813.83c0,0-0.09,0.07-0.08,0.08C1103.31,813.91,1103.39,813.83,1103.39,813.83z"/>
            <path onClick={() => props.onFill(239)} fill={props.fillColors[239]} d="M587.98,301.39c0,0,0.07-0.08,0.07-0.08S587.98,301.39,587.98,301.39z"/>
            <path onClick={() => props.onFill(240)} fill={props.fillColors[240]} d="M1104.58,815.08c-0.4-0.42-0.79-0.83-1.19-1.25c0,0-0.08,0.08-0.08,0.07c0.4,0.41,0.8,0.83,1.21,1.25
                C1104.51,815.16,1104.58,815.08,1104.58,815.08z"/>
            <path onClick={() => props.onFill(241)} fill={props.fillColors[241]} d="M247.41,297.29c0,0-0.09,0.1-0.09,0.1C247.31,297.38,247.41,297.29,247.41,297.29z"/>
            <path onClick={() => props.onFill(242)} fill={props.fillColors[242]} d="M284.38,834.89c0,0-0.07,0.09-0.07,0.08C284.31,834.97,284.38,834.89,284.38,834.89z"/>
            <path onClick={() => props.onFill(243)} fill={props.fillColors[243]} d="M572.08,287.34c0,0,0.06-0.07,0.06-0.07C572.15,287.27,572.08,287.34,572.08,287.34z"/>
            <path onClick={() => props.onFill(244)} fill={props.fillColors[244]} d="M134.57,275.35c0,0-0.07,0.06-0.07,0.06C134.5,275.42,134.57,275.35,134.57,275.35z"/>
            <path onClick={() => props.onFill(245)} fill={props.fillColors[245]} d="M406.08,271.42c0,0,0.08-0.06,0.08-0.05S406.08,271.42,406.08,271.42z"/>
            <polygon onClick={() => props.onFill(246)} fill={props.fillColors[246]} points="1087.34,264.47 1087.25,264.63 1087.41,264.55 		"/>
            <path onClick={() => props.onFill(247)} fill={props.fillColors[247]} d="M352.37,876.77c-0.37-0.29-0.74-0.58-1.11-0.87c0,0-0.06,0.06-0.06,0.06c0.38,0.3,0.75,0.6,1.13,0.9
                L352.37,876.77z"/>
            <path onClick={() => props.onFill(248)} fill={props.fillColors[248]} d="M1087.41,264.55c0.33-0.34,0.67-0.67,1-1.01c0,0-0.08-0.08-0.08-0.08c-0.33,0.33-0.66,0.67-1,1.01
                C1087.34,264.47,1087.41,264.55,1087.41,264.55z"/>
            <path onClick={() => props.onFill(249)} fill={props.fillColors[249]} d="M352.34,876.86c0,0,0.03-0.09,0.03-0.09C352.37,876.77,352.34,876.86,352.34,876.86z"/>
            <path onClick={() => props.onFill(250)} fill={props.fillColors[250]} d="M1088.41,263.54c0.33-0.34,0.66-0.68,0.98-1.02c0,0-0.08-0.07-0.08-0.07c-0.33,0.34-0.65,0.68-0.98,1.02
                C1088.34,263.46,1088.41,263.54,1088.41,263.54z"/>
            <path onClick={() => props.onFill(251)} fill={props.fillColors[251]} d="M1089.4,262.51c0.34-0.35,0.68-0.7,1.02-1.06l-0.02-0.05c-0.36,0.34-0.72,0.69-1.08,1.03
                C1089.32,262.44,1089.4,262.51,1089.4,262.51z"/>
            <path onClick={() => props.onFill(252)} fill={props.fillColors[252]} d="M209.23,933.01c0,0,0.1-0.04,0.1-0.04C209.32,932.97,209.23,933.01,209.23,933.01z"/>
            <path onClick={() => props.onFill(253)} fill={props.fillColors[253]} d="M91.57,956.84c0,0,0.05-0.08,0.05-0.08C91.62,956.76,91.57,956.84,91.57,956.84z"/>
            <path onClick={() => props.onFill(254)} fill={props.fillColors[254]} d="M1083.41,1001.78l-0.08,0.08L1083.41,1001.78z"/>
            <polygon onClick={() => props.onFill(255)} fill={props.fillColors[255]} points="1090.42,261.46 1090.5,261.26 1090.4,261.41 		"/>
            <path onClick={() => props.onFill(256)} fill={props.fillColors[256]} d="M649.96,1319.8c0,0-0.03-0.09-0.03-0.09C649.93,1319.71,649.96,1319.8,649.96,1319.8z"/>
            <path onClick={() => props.onFill(257)} fill={props.fillColors[257]} d="M1083.33,1001.86c0.38,0.34,0.77,0.69,1.15,1.03c0,0,0.05-0.05,0.05-0.05c-0.37-0.35-0.74-0.7-1.12-1.05
                C1083.41,1001.78,1083.33,1001.86,1083.33,1001.86z"/>
            <path onClick={() => props.onFill(258)} fill={props.fillColors[258]} d="M1153.42,1019.83c0,0,0.06-0.08,0.06-0.08C1153.48,1019.75,1153.42,1019.83,1153.42,1019.83z"/>
            <polygon onClick={() => props.onFill(259)} fill={props.fillColors[259]} points="159.63,1041.84 159.42,1041.79 159.54,1041.85 		"/>
            <path onClick={() => props.onFill(260)} fill={props.fillColors[260]} d="M777.76,235.37c0,0,0.08,0.07,0.08,0.07C777.83,235.44,777.76,235.37,777.76,235.37z"/>
            <path onClick={() => props.onFill(261)} fill={props.fillColors[261]} d="M882.61,1087.74c0,0,0.09,0.06,0.09,0.06S882.61,1087.74,882.61,1087.74z"/>
            <path onClick={() => props.onFill(262)} fill={props.fillColors[262]} d="M773.89,236.52c0,0-0.06-0.08-0.06-0.08C773.84,236.44,773.89,236.52,773.89,236.52z"/>
            <path onClick={() => props.onFill(263)} fill={props.fillColors[263]} d="M747.75,213.31c0,0,0.07,0.08,0.07,0.08C747.81,213.38,747.75,213.31,747.75,213.31z"/>
            <path onClick={() => props.onFill(264)} fill={props.fillColors[264]} d="M929.52,1164.62c0,0,0.08,0.08,0.07,0.08C929.58,1164.69,929.52,1164.62,929.52,1164.62z"/>
            <path onClick={() => props.onFill(265)} fill={props.fillColors[265]} d="M349.41,196.45c-0.32-0.36-0.65-0.72-0.97-1.08c0,0-0.09,0.03-0.09,0.03c0.33,0.37,0.65,0.74,0.98,1.12
                C349.33,196.52,349.41,196.45,349.41,196.45z"/>
            <path onClick={() => props.onFill(266)} fill={props.fillColors[266]} d="M348.44,195.37c0,0-0.09,0.04-0.09,0.04C348.35,195.4,348.44,195.37,348.44,195.37z"/>
            <path onClick={() => props.onFill(267)} fill={props.fillColors[267]} d="M424.15,174.5c0,0,0.09-0.1,0.09-0.09C424.24,174.41,424.15,174.5,424.15,174.5z"/>
            <path onClick={() => props.onFill(268)} fill={props.fillColors[268]} d="M333.42,173.33c0,0-0.09,0.06-0.09,0.06C333.34,173.38,333.42,173.33,333.42,173.33z"/>
            <path onClick={() => props.onFill(269)} fill={props.fillColors[269]} d="M865.71,1494.47c0,0-0.06-0.08-0.06-0.08C865.65,1494.38,865.71,1494.47,865.71,1494.47z"/>
            <path onClick={() => props.onFill(270)} fill={props.fillColors[270]} d="M1032.57,1309.7c0,0-0.07-0.07-0.07-0.07C1032.5,1309.63,1032.57,1309.7,1032.57,1309.7z"/>
            <path onClick={() => props.onFill(271)} fill={props.fillColors[271]} d="M1037.42,1308.49c0,0,0.06,0.09,0.06,0.09C1037.47,1308.57,1037.42,1308.49,1037.42,1308.49z"/>
            <path d="M361.06,522.23c-5.31-3.68-10.96-6.97-15.87-11.13c-7.09-6.02-13.51-12.82-20.48-18.99c-4.71-4.17-9.66-8.16-14.91-11.63
                c-4.04-2.67-6.19-1.69-7.08,3.04c-0.9,4.86-1.31,9.9-1.19,14.84c0.24,10.21-1.23,19.67-7,28.55c-5.3,8.17-7.73,17.44-8.13,27.42
                c-0.53,13.44-2.15,26.83-3.06,40.26c-0.13,1.92,0.59,4.37,1.8,5.85c13.42,16.27,23.43,34.33,29.23,54.66
                c0.58,2.05,1.58,4.41,1.06,6.26c-2.1,7.52,0.24,14.46,1.75,21.62c0.65,3.08,0.55,6.52-0.08,9.61c-1.2,5.92-0.6,11.42,3.21,16.04
                c1.28,1.55,4.22,2.72,6.14,2.41c1.25-0.2,3.07-3.52,2.83-5.18c-0.82-5.54-2.44-10.96-3.77-16.42c-0.36-1.46-0.77-2.9-1.16-4.36
                c0.44-0.2,0.88-0.4,1.32-0.59c0.78,1.11,1.64,2.18,2.31,3.35c5.09,8.78,9.85,17.76,15.31,26.3c3.1,4.85,7.01,9.31,11.14,13.34
                c8.45,8.25,14.21,17.66,16.83,29.39c2.35,10.51,6.04,20.72,9.35,31c2.11,6.56,1.75,8.12-5.09,7.96
                c-4.94-0.11-9.83-2.63-14.74-4.08c0.03,0.18,0.08,1.43,0.43,2.6c1.61,5.4,2.38,10.57,1.69,16.51c-0.95,8.14,1.57,15.58,9.22,21.17
                c5.27,3.85,10.13,9.5,12.79,15.42c4.95,11.04,8.18,22.85,12.07,34.35c0.24,0.71,0.03,1.56-1.14,2.95
                c-3.89-6.84-7.62-13.78-11.73-20.48c-4.25-6.94-8.87-13.65-13.37-20.51c-0.93,1.47-1.78,3.92-2.65,3.93
                c-1.57,0.01-3.6-1.08-4.65-2.35c-4.23-5.11-8.15-10.46-12.2-15.72c-3.85-5-7.93-9.84-11.48-15.03c-2.58-3.77-5.47-7.78-6.49-12.1
                c-3.93-16.58-8.64-32.75-16.85-47.84c-2.14-3.94-2.29-8.93-3.52-13.39c-3.35-12.16-6.1-24.54-10.42-36.34
                c-3.14-8.6-2.23-16.44-0.31-24.83c0.68-2.98-0.5-6.4-0.9-9.61c-0.46-3.72-1.56-7.45-1.37-11.13c0.51-10.25-1.53-19.87-5.95-29.03
                c-3.47-7.2-7.03-14.35-10.57-21.51c-0.36-0.74-0.86-2.01-1.29-2.02c-6.06-0.04-4.09-4.57-3.91-7.47
                c1.16-18.77,2.18-37.55,4.06-56.25c0.99-9.91,3.62-19.67,9.23-28.24c2.63-4.02,3.3-8.19,1.87-12.84
                c-2.76-8.94-2.72-17.71,2.09-26.09c2.36-4.11,0.38-7.54-2.32-10.38c-9.62-10.11-19.37-20.11-29.19-30.03
                c-2.95-2.98-6.27-5.6-9.42-8.39c0.39-0.47,0.79-0.94,1.18-1.41c6.57,5.22,13.52,10.02,19.6,15.75
                c7.25,6.83,13.79,14.43,20.57,21.76c4.57,4.93,5.54,10.1,1.95,16.32c-4.3,7.46-2.86,15.33,0.56,23.06c0-2.52-0.19-5.05,0.04-7.55
                c0.42-4.45,0.76-8.96,1.75-13.31c1.26-5.55,4.63-7.38,9.59-4.51c5.7,3.3,11.02,7.42,16.01,11.76c6.4,5.56,11.99,12.06,18.51,17.45
                c5.74,4.74,12.38,8.38,18.42,12.77c4.78,3.48,9.27,7.36,13.88,11.07c0.54-0.11,1.09-0.23,1.63-0.34
                c-0.26-3.04,0.13-6.33-0.92-9.07c-1.77-4.63-4.57-8.85-6.71-13.35c-1.02-2.14-1.81-4.64,1.9-5.28c0,0-0.12-0.07-0.12-0.07
                c1.26,1.65,2.17,3.95,3.84,4.83c5.7,3.01,11.87,5.18,17.48,8.34c4.67,2.62,9.17,5.79,13.1,9.42c6.55,6.05,12.59,12.66,18.84,19.03
                c4.71,4.81,9.97,8.48,16.81,9.91c2.36,0.49,4.78,2.52,6.41,4.47c9.47,11.35,18.72,22.87,28,34.37c0.97,1.2,1.65,2.64,2.47,3.97
                c-0.28,0.38-0.55,0.75-0.83,1.13c-1.91-0.61-4.03-0.88-5.69-1.9c-6.38-3.9-12.63-8.04-18.91-12.1c-2.52-1.62-4.87-3.61-7.57-4.81
                c-2.34-1.04-5.06-1.2-7.62-1.75c0.05,2.86-0.69,6.04,0.33,8.5c1.36,3.28,4.23,5.9,6.17,8.98c3.87,6.15,7.64,12.38,11.24,18.69
                c0.82,1.44,0.77,3.38,1.11,5.09c-0.46,0.44-0.93,0.89-1.39,1.33c-2.49-1.76-5.28-3.21-7.4-5.34c-7.64-7.66-15.01-15.58-22.6-23.3
                c-1.16-1.18-3.69-2.85-4.24-2.45c-1.53,1.12-2.5,3.13-3.38,4.94c-0.56,1.16-0.6,2.58-0.79,3.9c-0.79,5.42-2.25,6.19-6.36,2.69
                c-3.01-2.56-5.74-5.58-8.09-8.76c-3.96-5.35-7.73-10.86-11.21-16.53c-14.89-24.23-34.82-43.57-57.73-60.06
                c-0.81-0.58-1.62-1.17-2.33-1.86c-0.31-0.3-0.39-0.85-0.01-2.61c9.71,3.99,19.43,7.97,29.15,11.95
                C361.16,522.2,361.06,522.23,361.06,522.23z M347.34,520.11l0.09-0.06c1.88,1.85,3.74,3.71,5.63,5.54
                c13.37,12.95,27.29,25.36,37.19,41.47c5.31,8.64,11.25,16.9,17.12,25.17c1.61,2.27,3.99,3.98,6.21,6.14
                c2.3-5.74,4.22-10.53,6.79-16.95c11.22,11.28,21.37,21.49,31.52,31.71c0.3-2.26-0.1-4.05-1.06-5.46
                c-3.18-4.66-6.87-8.99-9.77-13.8c-2.51-4.17-5.27-8.65-6-13.3c-1.01-6.42,3.8-9.31,9.32-6.01c7.56,4.51,14.78,9.6,22.17,14.4
                c1.52,0.99,3.14,1.81,4.72,2.7c-0.89-3.12-2.35-5.32-4.02-7.34c-6.68-8.04-13.35-16.08-20.19-23.97c-1.47-1.7-3.5-3.62-5.54-3.99
                c-7.43-1.36-12.98-5.56-18.09-10.66c-6.32-6.31-12.51-12.74-18.98-18.89c-3.08-2.93-6.38-5.87-10.09-7.82
                c-6.1-3.21-12.62-5.62-19.05-8.4c0.66,1.18,1.48,2.71,2.38,4.2c3,5,4.43,10.07,3.16,16.13c-0.95,4.53-2.33,4-5.59,2.38
                C366.04,528.71,356.66,524.47,347.34,520.11z M287.25,609.18l0.08-0.07c2.16,4.66,4.21,9.39,6.51,13.98
                c5.5,10.97,9.73,22.17,9.13,34.82c-0.21,4.49,1.36,9.04,1.77,13.6c0.31,3.5,0.54,7.13-0.02,10.56c-1.19,7.22-2.61,14.1,0.5,21.45
                c5.65,13.33,10.41,27.01,11.4,41.7c0.2,2.89,1.26,5.96,2.71,8.5c8.66,15.24,13.35,31.81,17.11,48.76
                c0.66,2.98,2.14,5.98,3.91,8.49c5.85,8.25,11.97,16.32,18.09,24.38c2.02,2.66,4.33,5.09,7.07,8.28c0.55-3.91,0.92-6.51,1.29-9.12
                c0.39-0.04,0.78-0.09,1.17-0.13c6.73,10.17,13.47,20.34,20.2,30.51l-0.07,0.05c-2.88-12.12-6.43-23.87-17.68-31.27
                c-7.86-5.17-12.09-16.03-10.39-25.55c0.48-2.66,0.53-5.71-0.29-8.23c-3.76-11.54-7.93-22.95-11.87-34.43
                c-0.64-1.86-0.84-3.87-1.24-5.81c0.57-0.21,1.15-0.43,1.72-0.64c1.36,2.65,2.8,5.26,4.07,7.95c2.76,5.85,4.76,12.21,8.38,17.46
                c2.55,3.7,7.07,6.25,11.12,8.61c1.39,0.81,4.24-0.13,5.96-1.1c0.57-0.32-0.1-3.3-0.59-4.95c-2.99-10.2-6.58-20.25-9-30.58
                c-2.32-9.93-6.41-18.7-14.1-25.25c-7.67-6.53-12.9-14.69-17.86-23.19c-0.84-1.44-1.77-2.82-2.66-4.23
                c-0.96,1.62-1.05,2.76-1.08,3.9c-0.08,2.68,0.18,5.67-3.4,6.29c-3.45,0.6-7,2.22-10.01-1.67c-4.66-6.02-6.72-12.4-5.16-20.03
                c0.6-2.95,0.6-6.23,0.01-9.19c-1.44-7.15-4.04-14.12-1.62-21.59c0.41-1.27-0.05-2.98-0.52-4.35c-2.21-6.45-3.84-13.25-7.04-19.19
                C299.54,628.02,293.17,618.72,287.25,609.18z M288.55,528.66c0.53,0.26,1.06,0.52,1.6,0.77c2.64-5.27,5.29-10.55,7.93-15.82
                c-0.57-0.28-1.14-0.56-1.71-0.84C293.76,518.07,291.15,523.36,288.55,528.66z"/>
            <path d="M431.16,526.17c0.46,2.36,0.93,4.72,1.68,8.55c-20.33-12.58-36.91-29.25-60.48-32.59c0,0,0.12,0.07,0.12,0.07
                c-5.1-2.15-10.06-5.79-15.72-0.95c-0.58,0.5-2.97-0.32-3.93-1.18c-9.21-8.34-18.58-16.55-27.32-25.38
                c-5.47-5.53-10.1-11.94-14.8-18.18c-2.98-3.96-5.16-8.51-8.14-12.47c-3.24-4.3-6.99-8.22-11.21-11.89
                c1.69,6.24,5.82,11.62,4.93,19.84c-2.29-2.15-3.77-3.29-4.96-4.68c-9.85-11.51-19.38-23.29-29.53-34.52
                c-7.46-8.26-15.7-15.81-23.57-23.69c-5.5-5.52-9.72-11.81-11.52-19.52c-0.9-3.85-1.02-7.36,1.8-10.82c0.84-1.02,0.06-4.27-1-5.79
                c-9-12.98-20.72-23.35-32.7-33.44c-0.94-0.8-1.82-1.67-2.73-2.51c0.17-0.41,0.34-0.83,0.51-1.24c1.53,0.41,3.32,0.45,4.55,1.29
                c12.02,8.3,24.35,16.23,35.77,25.31c8.52,6.77,13.41,16.28,15.19,27.35c0.46,2.89,2.58,6.22,4.95,7.99
                c13.96,10.43,26.76,21.76,36.19,36.76c4.35,6.93,11.79,10.93,19.81,12.48c8.69,1.67,14.96,6.07,19.26,13.59
                c1.74,3.03,3.93,5.96,4.91,9.24c2.77,9.31,9.62,14.93,17.11,20.02c7.43,5.05,14.91,10.04,22.54,14.77
                c2.84,1.76,6.16,2.74,9.98,3.42c-4.38-10.03-11.45-18.73-10.89-31.28c21,20.34,40.05,41.43,56.74,64.66
                c0.45-0.23,0.9-0.47,1.34-0.7c-0.63-1.65-1.05-3.42-1.94-4.92c-2.61-4.45-5.57-8.7-8.02-13.23c-0.72-1.32-0.71-3.57-0.05-4.9
                c0.35-0.71,2.97-0.86,4.29-0.42c3.92,1.3,8.12,2.41,11.41,4.74c2.73,1.93,4.71,5.26,6.33,8.34c5.15,9.85,7.69,21.2,18.37,27.62
                c6.1,3.66,5.86,5.94,1.87,11.76c-0.7,1.02-0.47,3.18,0.11,4.46c1.13,2.5,2.81,4.75,5.2,8.61c-3.39-0.53-5.6-0.32-7.19-1.21
                c-6.65-3.76-13.34-7.54-19.47-12.05c-1.59-1.17-1.96-5.1-1.63-7.6c0.97-7.45-1.21-12.08-8.26-15.65
                C431.08,526.23,431.16,526.17,431.16,526.17z M209.34,328.39c0,0,0.08-0.07,0.08-0.07c7.33,8.16,14.7,16.29,21.96,24.51
                c1.96,2.22,3.5,4.87,0.45,7.37c-2.98,2.44-2.77,5.42-2.07,8.63c1.62,7.45,5.61,13.64,11.18,18.53
                c17.72,15.59,32.33,33.98,47.58,51.81c0.76,0.89,1.71,1.61,2.57,2.41c0.3-0.25,0.6-0.5,0.9-0.75c-3.29-5.19-6.59-10.39-9.88-15.58
                c0.29-0.34,0.58-0.68,0.86-1.02c1.56,0.63,3.24,1.06,4.65,1.93c8.76,5.42,14.76,13.05,20.68,21.63
                c11.57,16.75,23.57,33.55,40.47,45.8c3.48,2.52,6.47,5.89,11.43,2.77c1.16-0.73,3.32-0.33,4.87,0.08
                c7.84,2.09,15.66,4.25,23.43,6.61c2.19,0.66,4.25,1.92,6.2,3.16c10.78,6.82,21.51,13.72,32.25,20.59c0.33-0.29,0.67-0.58,1-0.87
                c-14.26-22.09-31.81-41.44-51.26-59.38c0.06,2,0.32,3.93,1.2,5.52c2.5,4.51,5.38,8.81,7.85,13.33c0.7,1.28,1.24,3.58,0.59,4.44
                c-0.76,0.99-3.09,1.54-4.44,1.18c-3.49-0.94-7.1-2.02-10.15-3.88c-7.81-4.77-15.37-9.93-22.96-15.05
                c-8.23-5.55-15.7-11.72-18.66-21.89c-0.82-2.81-2.73-5.31-4.2-7.92c-3.81-6.78-9.37-10.65-17.21-12.27
                c-9.18-1.89-17.42-6.6-22.44-14.66c-8.47-13.59-19.9-24.04-32.59-33.34c-4.99-3.66-8.51-7.13-9.62-14.31
                C240.95,347.71,224.67,338.22,209.34,328.39z M459.18,558.02c0-3.99-1.29-8.41,0.25-9.9c3.78-3.66,2.46-5.82-0.81-7.77
                c-11.68-6.96-15.4-19.01-19.72-30.6c-1.8-4.84-10.98-11.26-16.88-10.43c0.88,1.74,1.62,3.3,2.45,4.8
                c2.89,5.2,5.83,10.37,8.72,15.57c1.14,2.04,1.83,4.46,3.36,6.12c4.45,4.83,8.08,9.65,5.85,16.87c-0.46,1.5,0.23,4.33,1.39,5.2
                C448.67,551.52,453.94,554.64,459.18,558.02z"/>
            <path d="M387.29,736.8c2.57,4.92,4.99,9.94,7.78,14.73c1.66,2.86,3.97,5.34,5.9,8.05c3.22,4.53,6.11,8.93,3.67,15.14
                c-0.99,2.53-0.46,6.04,0.34,8.82c4.51,15.58,7.09,31.77,14.59,46.42c0.66,1.3,0.39,3.07,0.55,4.63c-1.6,0.15-3.25,0.63-4.78,0.39
                c-6.72-1.06-11.41-3.88-13.01-11.52c-2.55-12.17-6.55-24.03-9.2-36.18c-1.79-8.23-2.11-16.76-3.5-25.09
                c-0.65-3.9-2.28-7.64-3.47-11.45c-0.52,0.13-1.04,0.25-1.56,0.38c0,2.19,0,4.37,0,7.9c-2.38-1.8-4.16-2.66-5.25-4.05
                c-7.03-8.99-11.67-18.95-11.58-30.65c0.05-6.68,2.61-9.09,9.29-8.43c2.28,0.22,4.54,0.98,6.72,1.74c2,0.69,3.88,1.71,6.64,2.96
                c0-3.18,0.29-5.75-0.05-8.22c-1.03-7.39-1.79-14.89-3.67-22.07c-1.53-5.85-3.83-11.75-7.03-16.84
                c-6.02-9.55-12.98-18.5-19.45-27.78c-1.54-2.21-2.65-4.72-3.95-7.1c0.32-0.27,0.65-0.53,0.97-0.8c0.84,0.55,1.91,0.93,2.48,1.69
                c10.19,13.33,20.8,26.38,30.29,40.19c5.51,8.01,9.22,17.26,13.69,25.97c0.45,0.87,0.92,1.94,0.81,2.84
                c-1.28,10.99,3.5,20.48,7.39,30.19c0.37,0.93,0.72,1.87,0.94,2.84c0.9,3.84-0.46,5.32-4.1,3.83c-3.82-1.56-7.3-3.92-10.99-5.81
                c-2.9-1.49-5.88-2.81-8.83-4.2C388.38,735.81,387.84,736.31,387.29,736.8z M416.62,831.68c-0.06-0.86,0.05-1.42-0.15-1.82
                c-6.78-13.83-9.85-28.83-13.64-43.59c-1.01-3.93-1.89-8.41-0.98-12.19c1.22-5.05-0.12-8.74-2.91-12.47
                c-1.8-2.4-3.9-4.63-5.31-7.23c-3.14-5.82-6.3-11.69-8.74-17.82c-1.59-4,0.13-5.61,4.07-4.04c4.91,1.95,9.53,4.65,14.29,6.98
                c1.78,0.87,3.62,1.63,6.71,3.01c-1.39-3.78-2.34-6.04-3.07-8.38c-1.86-6.02-5.08-12.06-5.11-18.1
                c-0.06-11.22-5.68-19.91-10.29-29.2c-0.5-1-1.42-1.8-2.14-2.69c0.88,9.41,2.59,18.37,4.02,27.37c0.49,3.08,0.55,6.29,0.3,9.4
                c-0.29,3.66-2.17,4.2-5.51,2.53c-3.81-1.9-7.87-3.54-12-4.4c-1.47-0.3-4.89,1.87-5.03,3.17c-1.14,10.67,2.77,19.9,9.2,29.34
                c0.64-3.52,1.08-5.93,1.57-8.61c5.18,3.19,9.46,10.39,10.51,17.51c1.31,8.85,1.84,17.86,3.73,26.59
                c2.7,12.48,6.25,24.79,9.59,37.13C407.37,830.2,409.28,831.41,416.62,831.68z"/>
            <path d="M165.42,350.11c1.99,3.83,3.29,7.35,5.42,10.26c6.88,9.4,14.08,18.56,21.12,27.85c1.12,1.48,2.04,3.12,3.06,4.68
                c-0.4,0.51-0.8,1.02-1.2,1.54c-2.99-1.68-5.93-3.47-8.98-5.01c-5.68-2.87-11.36-5.77-17.22-8.22c-0.92-0.39-3.25,1.13-4.15,2.34
                c-8.44,11.39-8.69,27.33-0.53,39.31c2.19,3.21,2.51,5.32,0.45,9.19c-5.23,9.84-9.27,21.35-5.07,31.68
                c4.59,11.27-3.64,18.52-4.66,27.77c-0.09,0.8-1.99,2.04-2.98,1.98c-4.29-0.27-6.4,2.45-8.09,5.58c-1.81,3.35-3.18,6.95-4.86,10.38
                c-3.28,6.67-7.1,13.12-9.83,20c-2.28,5.76-3.76,11.95-4.77,18.08c-0.72,4.37-0.08,8.96-0.05,13.46c0.04,8.23-2.67,15.53-6.62,22.7
                c-3.33,6.05-5.64,12.68-8.13,19.16c-1.6,4.18-3.03,8.48-3.93,12.86c-0.82,3.98-1.1,8.13-1.12,12.21c-0.02,3.45-0.45,5.53-4.4,3.35
                c-3.07-1.7-4.05,0.62-4.54,2.68c-1.82,7.58-3.43,15.22-5.01,22.86c-1.04,5.02-1.83,10.1-2.89,15.12
                c-0.24,1.12-1.11,2.11-2.13,3.97c-0.51-1.57-0.94-2.19-0.85-2.7c2.32-12.55,4.49-25.14,7.18-37.61c0.92-4.24,2.29-9.02,7.84-8.93
                c4.71-16.76,8.03-33.57,17.09-48.78c3.48-5.85,4.18-14.03,4.18-21.15c0-12.6,0.61-24.51,9.28-34.79c2.45-2.9,3.03-7.32,4.74-10.92
                c2.62-5.54,5.36-11.02,8.27-16.41c0.75-1.38,2.2-2.52,3.57-3.4c1.8-1.15,4.93-1.46,5.57-2.96c2.48-5.78,4.61-11.79,5.96-17.91
                c0.47-2.13-2.21-4.76-2.59-7.29c-1.69-11.26,1.17-21.85,5.8-31.95c1.28-2.8,1.42-4.57-0.24-7.15
                c-8.89-13.82-8.19-29.93,1.45-42.96c2.46-3.32,4.93-4.84,8.67-1.99c0.77,0.59,2.05,1.42,2.66,1.15c4.25-1.86,6.36,1.44,9.04,3.44
                c0.78,0.58,1.61,1.11,3.23,1.01c-4.25-5.72-8.59-11.37-12.7-17.18c-2.97-4.19-6.1-8.38-8.3-12.98c-2.74-5.74-1.18-7.56,5.19-8.29
                c1.9-0.22,3.63-1.73,5.51-2.45c2.52-0.96,5.05-2.11,7.68-2.49c3.91-0.56,5.26-1.6,3.36-5.78c-1.69-3.71-2.27-7.94-3.34-11.93
                c0.48-0.12,0.95-0.23,1.43-0.35c2.23,5.35,4.7,10.62,6.47,16.12c0.3,0.95-2.14,3.56-3.79,4.19c-3.98,1.53-8.59,0.83-11.85,5.04
                C173.54,350.05,169.53,349.49,165.42,350.11z"/>
            <path d="M390.59,646.85c1.02,1.2,2.21,2.3,3.03,3.62c3.93,6.36,7.9,12.71,11.58,19.22c5.89,10.42,11.61,20.95,17.32,31.47
                c2.06,3.8,3.84,7.74,5.86,11.56c3.91,7.4,4.33,14.63-0.01,22.13c-1.61,2.79-3.29,3.68-5.19,1.26c-3.15-3.99-6.57-8.11-8.38-12.76
                c-4.9-12.54-9.03-25.38-13.43-38.12c-3.63-10.53-7.24-21.07-10.78-31.63c-0.66-1.96-0.99-4.03-1.47-6.05
                C389.61,647.32,390.1,647.09,390.59,646.85z M424.4,735.18c3.27-5.83,6.1-9.86,3.58-15.28c-2.51-5.43-4.54-11.1-7.31-16.38
                c-6.27-11.93-12.87-23.69-19.33-35.52c-1.13-2.07-2.28-4.14-3.42-6.21c0.94,6.12,2.65,11.84,4.54,17.48
                c4.86,14.52,9.63,29.08,14.82,43.48C418.74,726.78,421.56,730.31,424.4,735.18z"/>
            <path d="M104.42,170.7c-1.6-1.96-3.23-3.91-4.81-5.89c-10.97-13.8-17.3-30.18-25.4-45.58c-1.23-2.35-2.94-4.49-3.88-6.94
                c-0.73-1.89-0.6-4.1-0.85-6.17c2.62,0.28,6.35-0.45,7.66,1.01c4.18,4.64,8.04,9.78,10.93,15.3c2.81,5.37,4.91,11.32,6.15,17.26
                c1.73,8.25,4.35,15.96,8.9,23.07c1.35,2.11,2.1,4.6,3.13,6.91C105.64,170.01,105.03,170.36,104.42,170.7z M90.83,146.68
                c0.39-0.16,0.78-0.32,1.17-0.47c-1.3-13.39-6.99-25.01-14.89-35.67c-0.92-1.24-2.98-1.63-4.51-2.42c-0.15,0.32-0.3,0.65-0.45,0.97
                C78.38,121.63,84.61,134.15,90.83,146.68z"/>
            <path d="M506,503.77c-6.35-2.96-12.71-5.93-19.06-8.9c-4.03-1.88-7.92-4.15-12.1-5.6c-13.19-4.59-19.15-12.3-20.44-26.26
                c-0.22-2.4-1.91-4.9-3.5-6.89c-3.5-4.37-7.37-8.44-10.57-13.13c6.47,4.72,12.7,9.68,16.88,16.68c0.56,0.93,0.76,2.25,0.67,3.36
                c-0.88,10.73,5.07,17.09,13.78,21.62c7.76,4.04,15.58,7.95,23.41,11.82c3.83,1.89,7.76,3.57,11.64,5.35
                C506.48,502.47,506.24,503.12,506,503.77z"/>
            <path d="M480.16,416.22c1.18,0.6,2.48,1.06,3.53,1.84c7.37,5.42,14.64,10.98,22.05,16.34c1.63,1.18,3.71,1.75,5.58,2.6
                c0.91-2.09,2.35-4.11,2.62-6.28c0.63-5.11,0.61-10.3,1.03-15.44c0.17-2.04,0.81-4.05,1.82-5.99c0.12,10,2.46,20.11-1.85,29.72
                c-0.52,1.16-2.89,2.1-4.37,2.06c-1.47-0.04-3.01-1.31-4.33-2.3c-7.98-5.99-15.93-12.03-23.84-18.12c-1.17-0.9-2.11-2.12-3.15-3.19
                C479.55,417.04,479.85,416.63,480.16,416.22z"/>
            <path d="M38.84,603.43c9.92,3.68,34.31,26.41,36.53,34.27C62.58,625.69,50.71,614.56,38.84,603.43z"/>
            <path d="M36.37,621.89c11.71,5.41,22.77,11.95,31.52,21.59c-0.4,0.55-0.81,1.1-1.21,1.65c-4.92-3.92-9.77-7.92-14.76-11.75
                C46.8,629.47,41.56,625.72,36.37,621.89z"/>
            <path d="M509.14,494.95c-4.38-4.43-8.76-8.86-13.14-13.28c0.07-0.09,0.15-0.18,0.22-0.28c4.6,4.21,9.2,8.42,13.8,12.63
                C509.73,494.33,509.43,494.64,509.14,494.95z"/>
            <path d="M608.98,708.07c0.92-29.43,2.53-58.85,2.53-88.28c0-22.63,2.26-44.86,6.91-66.89c3.25-15.4,4.62-30.87,4.69-46.58
                c0.13-28.07,3.88-55.77,10.58-83.01c1.67-6.79,4.61-13.27,6.97-19.9c0.5,0.15,1,0.3,1.5,0.45c0,0.86,0.23,1.79-0.03,2.56
                c-9.83,28.96-13.95,59-15.12,89.3c-0.85,21.91-3.79,43.37-7.76,64.82c-6.71,36.22-5.69,72.92-6.27,109.52
                c-0.13,8.32-0.2,16.66-0.9,24.93c-0.37,4.39-2.06,8.67-3.15,13L608.98,708.07z"/>
            <path d="M662.52,586.35c0.12,1.62,0.29,3.23,0.34,4.85c0.92,30.45,1.84,60.9,2.7,91.36c0.11,3.99-0.12,7.99-0.23,11.98
                c-0.52,18.46-1.71,36.9,0.76,55.37c0.82,6.16-0.19,12.62-0.72,18.91c-1.3,15.41-3.37,30.77-4.08,46.21
                c-0.59,12.77,0.33,25.61,0.57,38.42c0.04,1.94,0.01,3.88,0.01,5.82c-0.41,0.07-0.82,0.15-1.23,0.22c-0.36-0.9-0.94-1.78-1.04-2.7
                c-0.37-3.31-0.7-6.63-0.83-9.95c-1.07-27.14-0.37-54.18,4.23-81.05c0.94-5.52,0.59-11.29,0.5-16.94
                c-0.22-14.45-0.68-28.9-0.94-43.35c-0.53-29.29-1.03-58.58-1.45-87.87c-0.15-10.42-0.02-20.85-0.02-31.28
                C661.57,586.34,662.05,586.35,662.52,586.35z"/>
            <path d="M669.68,946.09c0-2.21,0.03-4.41-0.01-6.62c-0.2-12.55-12.06-21.34-24.21-17.96c-6.1,1.69-10.27,7.27-10.95,14.62
                c-0.57,6.22,2.31,10.63,8.73,13.6c1.49,0.69,2.86,1.67,5.03,2.96c-4.28,1.03-7.41,1.78-11.16,2.69
                c-1.92-4.49-5.39-9.77-6.36-15.48c-1.8-10.63,5.36-20.2,15.04-22.01c11.26-2.11,23.25,4.68,26.33,14.91
                c2.1,6.99,9.06,8.92,14.67,4.07c4.2-3.63,8.83-3.67,13.05,0.02c1.48,1.3,2.72,2.87,3.26,3.44c5.06-1.56,9.37-2.73,13.54-4.27
                c1.23-0.45,2.25-1.82,3.08-2.97c6.01-8.39,16.62-12.12,26.46-9.51c5.03,1.34,8.02,4.45,9.51,9.25c1.7,5.5,1.35,13.18-1.48,19.49
                c0.15-3.01,0.44-6.01,0.44-9.02c-0.01-10.36-2.57-15.27-8.8-17.14c-8.62-2.59-19.18,1-23.68,8.51c-1.88,3.14-3.61,4.26-7.59,4.58
                c-3.61,0.29-6.93,4.06-10.77,6.54c-1.04-1.31-2.73-3.95-4.9-6.1c-4.15-4.11-8.17-3.48-11.8,1.14c-0.98,1.25-3.28,2.78-4.35,2.41
                c-4.74-1.67-9.06-3.19-11.41,4.08C670.78,946.91,670.23,946.5,669.68,946.09z"/>
            <path d="M626.18,850.06c-0.32-2.88-0.93-5.77-0.91-8.65c0.08-12.8,0.3-25.6,0.54-38.4c0.26-13.79,0.45-27.59,0.94-41.38
                c0.26-7.46,1.07-14.9,1.62-22.35c1.28-17.23,2.85-34.44,3.72-51.69c0.56-11.04,0.11-22.14,0.11-33.21c0.8,0.02,1.6,0.03,2.4,0.05
                c-2.39,65.14-4.78,130.29-7.17,195.43C627,849.93,626.59,849.99,626.18,850.06z"/>
            <path d="M677.58,421.52c-3.4,12.15-7.64,24.13-10,36.47c-3.31,17.26-5.04,34.83-7.8,52.21c-4.99,31.4-3.34,63.06-4.09,94.63
                c-0.13,5.3-0.5,10.6-1.11,15.88c0-30.49-0.96-61.01,0.33-91.44c0.9-21.17,4.11-42.36,7.81-63.27c2.44-13.83,7.54-27.2,11.54-40.75
                c0.45-1.51,1.49-2.85,2.26-4.27C676.86,421.17,677.22,421.34,677.58,421.52z"/>
            <path d="M351.02,1007.2c-2.71-1.19-4.53-1.91-6.28-2.78c-6.77-3.33-13.74-3.57-20.9-1.55c-6.7,1.89-9.76,6.4-9.13,13.45
                c0.11,1.24,0.14,2.48,0.23,4.19c-4.17-3.89-3.85-10.85,0.3-15.33c5.14-5.56,12-6.21,18.88-5.85c3.73,0.2,7.36,1.95,11.11,2.51
                c1.57,0.24,4.07-0.24,4.88-1.33c3.45-4.65,8.08-5.92,13.49-6.28c2.79-0.18,5.52-1.42,8.27-2.22c1.43-0.41,2.82-1.26,4.25-1.29
                c5.18-0.13,9.48-0.99,10.66-7.29c0.2-1.08,3.65-2.61,5.15-2.22c8.93,2.34,17.29-3.78,21.36-13.41
                c4.53-10.72,18.45-16.08,28.41-9.47c-1.93,0-3.08,0.13-4.2-0.02c-10.52-1.43-20.47,3.43-22.05,14.4
                c-0.14,0.95-0.44,2.39-1.07,2.67c-6.58,2.88-10.88,10.8-19.68,8.86c-3.57-0.79-5.57,0.36-6.68,4.47
                c-0.53,1.95-4.37,3.22-6.91,4.36c-3.2,1.44-6.58,2.51-9.93,3.56c-1.37,0.43-2.91,0.62-4.34,0.52
                C359.44,996.62,352.88,997.4,351.02,1007.2z"/>
            <path d="M651.18,894.76c-11.03-13.81-20.4-17.19-33.76-11.93c-4.86,1.91-9.87,4.53-13.49,8.15c-3.6,3.59-6.68,8.56-7.88,13.44
                c-1.19,4.83,0.26,10.31,0.56,15.75c-1.93-0.61-4.01-1.77-6.11-1.82c-4.1-0.1-7.32,4.32-8.37,9.17c-0.91,4.21-2.76,8.25-4.47,12.24
                c-0.27,0.63-1.97,0.95-2.99,0.92c-7.94-0.25-11.99,4.95-9.62,12.53c0.54,1.71,1.21,3.38,2.3,6.41c-1.9-1.16-2.96-1.46-3.47-2.16
                c-5.75-7.86-2.33-17.97,7.17-19.89c5.26-1.07,6.78-4.47,7.17-8.62c0.68-7.28,3.85-12.09,10.98-14.4c1.26-0.41,2.75-2.92,2.63-4.33
                c-1.78-20.51,28.62-40.48,48.81-29.12C645.96,884.09,649.58,888.13,651.18,894.76z"/>
            <path d="M611.62,498.57c-0.29,1.94-0.56,3.89-0.88,5.83c-4.16,25.61-8.74,51.17-12.29,76.87c-1.7,12.31-0.56,25.01-2.29,37.32
                c-2.45,17.35-6.36,34.49-9.65,51.72c-0.77-0.13-1.53-0.26-2.3-0.39c0.69-4.05,0.81-8.3,2.17-12.11
                c7.64-21.43,7.15-43.78,8.46-66.03c0.95-16.05,3.93-32,6.42-47.93c2.11-13.46,4.79-26.84,7.3-40.23c0.35-1.87,1.21-3.66,1.83-5.48
                C610.8,498.28,611.21,498.43,611.62,498.57z"/>
            <path d="M776.44,923c-0.01-7.98-5.37-9.14-10.85-10.7c-3.53-1.01-7.32-2.47-5.98-8.01c0.35-1.43-2.22-3.57-3.84-5.95
                c6.9-5.77,4.11-12.74,0.61-19.58c-2.27-4.45-5.82-7.31-11.21-6.69c-5.52,0.63-7.82,4.45-8.98,9.34c-0.29,1.2-0.71,2.38-1.26,4.16
                c-3.22-3.78-5.8-7.19-8.82-10.14c-1.04-1.01-3.25-0.73-4.83-1.29c-1.6-0.57-4.19-1.19-4.45-2.25
                c-2.86-11.67-5.36-12.79-14.68-6.08c-0.34-0.26-0.69-0.51-1.03-0.77c1.54-1.73,2.79-3.93,4.67-5.1
                c4.32-2.66,10.31-1.17,12.45,3.49c1.88,4.11,3.02,8.26,8.63,8.14c0.27-0.01,0.68,0.29,0.8,0.55c3.75,7.95,6.75,4.45,10.93-0.06
                c4.41-4.76,12.53-4.13,16.97,1.17c5.78,6.9,10.08,14.5,5.7,24.12c-1.86,4.08,2.91,12.08,7.21,13.71c3,1.14,5.99,2.81,8.35,4.94
                c1.25,1.13,1.15,3.76,1.66,5.72C777.82,922.15,777.13,922.58,776.44,923z"/>
            <path d="M542.85,870.14c-10.1-43.68-3.81-87.76-1.84-131.72c0,14.18,0.27,28.38-0.06,42.55c-0.65,27.6-1.22,55.18,3.08,82.59
                c0.33,2.08,0.04,4.26,0.04,6.4C543.67,870.02,543.26,870.08,542.85,870.14z"/>
            <path d="M501.72,965.6c2.82-21.13,10-29.9,24.61-30.4c12.75-0.44,27.14,11.4,29.89,25.59c-2.08-3.38-3.47-5.89-5.1-8.22
                c-5.01-7.15-11.25-12.77-20.06-14.58c-13.78-2.83-25.66,7.39-26.08,22.36c-0.1,3.42,0.33,6.85,0.57,11.34
                c-2.59-1.34-4.42-2.22-6.18-3.21c-3.47-1.96-5.86-0.96-6.78,2.77c-0.68,2.77-1.37,5.1-4.71,5.49c-0.51,0.06-1.25,0.81-1.33,1.33
                c-0.88,5.52-3.79,3.87-7.45,2.77c-3.61-1.09-7.65-0.75-11.87-1.06c2.97-3.1,6.52-2.62,10.06-2.16c1.92,0.25,4.38,1.46,5.64,0.68
                c2.54-1.56,4.96-3.87,6.49-6.43C493.63,964.83,493.44,964.72,501.72,965.6z"/>
            <path d="M585.01,553.08c0-2.22-0.21-4.47,0.03-6.67c2.83-25.93,5.27-51.92,8.83-77.75c1.44-10.44,5.07-20.63,8.32-30.74
                c1.24-3.85,4.19-7.15,6.36-10.7c0.34,0.22,0.67,0.44,1.01,0.67c-2.15,5.31-4.5,10.55-6.43,15.94
                c-7.33,20.44-8.48,41.94-10.83,63.25c-1.64,14.86-3.63,29.69-5.49,44.52c-0.07,0.55-0.5,1.06-0.76,1.59
                C585.7,553.15,585.35,553.12,585.01,553.08z"/>
            <path d="M677.49,482.79c-0.78,10.07-2.25,20.15-2.14,30.21c0.11,10.57,1.31,21.18,2.78,31.66c3.21,22.77,5.61,45.54,3.15,68.55
                c-0.11,1.05-0.55,2.06-0.84,3.08c-0.49-0.01-0.97-0.03-1.46-0.04c0-2.15-0.17-4.32,0.03-6.45c1.84-20.32,0.72-40.52-2.09-60.68
                c-1.48-10.66-3.54-21.37-3.63-32.07c-0.1-11.4,1.81-22.81,2.84-34.22C676.59,482.81,677.04,482.8,677.49,482.79z"/>
            <path d="M608.32,746.03c-2.38,27.37-4.75,54.75-7.2,82.97c-3.29-6.05,1.93-76.91,5.57-83.12
                C607.24,745.93,607.78,745.98,608.32,746.03z"/>
            <path d="M246.73,1054.88c-5.74-3.22-8.22-9.93-6.46-14.74c1.76-4.79,7.99-8.5,13.97-7.15c4.85,1.09,7.17-0.24,9.23-4.55
                c2.88-6.02,9.3-5.47,14.65-6.9c2.88-0.77,5.91-1.85,8.25-3.62c4.68-3.53,13.4-3.52,19.44,1.62c-1.71-0.08-3.52,0.15-5.13-0.31
                c-5.39-1.51-10.39-2.35-14.23,3.28c-0.73,1.07-2.92,1.31-4.49,1.6c-2.6,0.48-5.26,0.7-7.89,1.02c-5.15,0.62-8.71,3.25-9.9,8.4
                c-0.75,3.27-2.44,3.91-5.41,2.95c-1.88-0.61-3.87-1.08-5.84-1.17c-8.14-0.35-12.38,5.62-9.35,13.11
                C244.47,1050.64,245.67,1052.73,246.73,1054.88z"/>
            <path d="M393.67,1027.08c-1.41-1.05-2.12-1.43-2.62-1.99c-3.73-4.17-4.2-4.06-6.53,0.81c-0.52,1.08-1.96,2.16-3.14,2.39
                c-2.7,0.53-5.49,0.53-9.72,0.86c1.86-1.56,2.93-3.2,4.15-3.32c3.39-0.33,5.89-1.18,7.26-4.74c0.44-1.13,2.86-2.45,4.02-2.17
                c3.7,0.86,4.46-1.39,5.67-3.93c3.94-8.27,12.33-13.25,20.43-12.4c7.72,0.81,12.18,6.74,10.37,15.22
                c-0.74-1.94-1.21-2.98-1.53-4.07c-1.78-6.14-6.03-8.91-12.39-8.05c-8.1,1.1-14.47,7.84-15.26,16.11
                C394.23,1023.32,393.98,1024.84,393.67,1027.08z"/>
            <path d="M742.26,989.95c-3.31-5.17-0.67-12.68,4.9-13.13c3.64-0.29,4.58-1.88,5.48-4.92c2.75-9.28,12.23-13.49,20.59-9.42
                c5.21,2.54,6.79,7.07,4.22,12.09c-2.67-8.77-4.84-11.16-10.89-10.66c-11.89,0.98-11.65,10.06-11.48,18.07
                c-3.5-0.57-7.55-2.48-9.39-1.2C743.56,982.24,743.4,986.53,742.26,989.95z"/>
            <path d="M365.48,1039.61c-3.36-6.48-6.38-12.67-13.96-13.54c-8.67-1-11.23,5.25-12.93,12.3c-3.49-1.52-6.51-3.91-9.44-3.78
                c-2.61,0.11-5.1,3-7.73,4.72c0.61-6.59,4.16-9.59,9.68-7.59c3.79,1.38,5.71,1.07,7.85-2.69c3.19-5.59,9.61-7.28,15.74-5.15
                C361.11,1026.11,365.83,1032.61,365.48,1039.61z"/>
            <path d="M398.09,960.36c-6.4-1.5-12.08-3.57-17.89-4.03c-14.12-1.14-19.9,6.29-16.25,20.07c0.69,2.61,1.63,5.14,1.73,7.94
                c-5.02-4.92-6.15-11.19-5.68-17.82c0.41-5.76,4.04-9.66,9.13-11.41C379.42,951.57,388.79,954.41,398.09,960.36z"/>
            <path d="M679.42,910.8c1.49-3.47,5.12-4.45,9.19-5.05c2.23-0.33,4.37-1.38,6.52-2.15c0.61-0.22,1.28-1.03,1.71-0.89
                c5.91,1.91,7.4-2.84,10.3-6.18c4.03-4.65,10.76-3.42,14.35,1.5c-3.36-0.25-7.9-1.93-10.69-0.41c-2.71,1.49-3.62,6.25-5.57,10.05
                c-0.67-0.37-1.91-1.03-3.12-1.75c-2.52-1.5-4.83-2.54-6.3,1.35c-0.32,0.85-1.54,1.79-2.45,1.92
                C688.79,909.85,684.18,910.27,679.42,910.8z"/>
            <path d="M268.72,1019.51c-1.91-4.08-3.06-7.15-4.71-9.92c-1.8-3.03-4.16-6.21-8.25-5.07c-4.18,1.16-4.89,5.03-5.06,8.84
                c-0.08,1.9-0.01,3.8-0.01,5.88c-3.83-2.82-4.24-8.19-1.25-13.25c2.51-4.25,6.47-5.79,10.53-4.11
                C265.37,1004.11,269.66,1011.95,268.72,1019.51z"/>
            <path d="M571.5,655.41c-2.59-6.83,1.26-45.44,5.03-53.85C574.86,619.51,573.18,637.46,571.5,655.41z"/>
            <path d="M555.5,911.85c-3.91-4.23-4.4-8.35-2.52-11.76c1.36-2.46,4.64-4.87,7.4-5.33c5.15-0.87,8.8,2.44,11.3,6.09
                c-4.25-1.03-9.06-3.77-12.9-2.76C552.62,899.69,555.86,906.07,555.5,911.85z"/>
            <path d="M723.47,975.1c-3.35,0-8.12-1.19-11.72,0.36c-3.18,1.37-5.05,5.78-7.29,8.59c-1.77-3.31-0.79-7.21,3.19-9.93
                C713.19,970.33,719.88,971.14,723.47,975.1z"/>
            <path d="M682.43,438.15c-1.25,11.32-2.51,22.65-3.76,33.97c-0.33-0.03-0.66-0.07-0.99-0.1c1.12-11.36,2.24-22.72,3.35-34.08
                C681.5,438,681.97,438.08,682.43,438.15z"/>
            <path d="M649.95,420.93c2.13-7.94,4.26-15.89,6.39-23.83c0.54,0.14,1.08,0.28,1.62,0.41c-1.9,7.97-3.81,15.94-5.71,23.91
                C651.48,421.27,650.71,421.1,649.95,420.93z"/>
            <path d="M346.46,984.85c-8.27-2.15-12.1,2.62-14.98,9.31c-1.16-4.78,0.69-7.92,4.13-10.2
                C339.26,981.56,342.93,981.7,346.46,984.85z"/>
            <path d="M595.5,962.92c-3.8-3.03-3.51-6.06-1.73-8.8c2.31-3.56,5.69-5.12,10.53-3.56C595.33,954.48,595.33,954.48,595.5,962.92z"
                />
            <path d="M463.06,897.17c-5.82-1.06-10.64,0.59-14.35,5.53c-0.43-0.55-0.86-1.1-1.29-1.64c1.54-1.71,2.79-3.91,4.69-5.01
                C455.71,893.94,459.53,894.11,463.06,897.17z"/>
            <path d="M608.91,707.99c0,0,0.08,0.07,0.07,0.07S608.91,707.99,608.91,707.99z"/>
            <path d="M446.41,1275.92c-1.36-11.23-2.41-21.83-4.04-32.34c-0.56-3.61-1.85-7.61-4.14-10.28c-1.38-1.61-5.34-1-8.14-1.39
                c-0.05,2.09-0.85,4.56-0.03,6.22c6.14,12.42,4.93,25.24,2.62,38.17c-0.24,1.36-0.81,2.66-1.22,3.99
                c-0.47,0.02-0.95,0.04-1.42,0.06c-0.41-1.53-0.96-3.04-1.21-4.6c-1.15-7.22-1.63-14.6-3.44-21.65
                c-2.51-9.78-7.35-12.47-17.33-11.41c-5.61,0.6-9.03-3.36-11.39-7.32c-3.3-5.54-5.66-11.67-7.96-17.73
                c-0.67-1.75,0.26-4.11,0.46-6.19c0.76-0.46,1.52-0.92,2.27-1.38c-4.6-3.35-9.21-6.71-13.81-10.06c0.5-0.69,0.99-1.39,1.49-2.08
                c1.85,0,3.77-0.31,5.54,0.06c5.37,1.12,10.84,2.09,15.99,3.9c8.79,3.08,17.65,4.42,26.95,3.6c2.67-0.23,5.47,1.11,8.21,1.7
                c2.39,0.52,4.78,1.01,7.17,1.51c0.27-0.44,0.54-0.88,0.81-1.32c-4.45-3.71-8.8-7.53-13.37-11.08c-4.48-3.49-9.18-6.7-13.78-10.04
                c0.21-0.47,0.43-0.94,0.64-1.4c2.02,0,4.13-0.38,6.05,0.07c6.31,1.47,12.71,2.79,18.79,4.97c14.42,5.17,29.08,7.8,44.46,7.16
                c4.3-0.18,8.68,2.01,13.06,2.97c2.96,0.65,5.97,1.08,9.37,1.67c-0.39-2.73-0.63-4.46-1.05-7.42c7.14,2.04,13.55,3.88,20.29,5.8
                c-0.22-1.54-0.38-2.67-0.72-5.08c6.81,2.75,13.08,4.56,18.63,7.68c10.72,6.02,21.22,12.48,31.44,19.32
                c9.08,6.08,10.82,15.71,11.06,25.7c-0.41,0.29-0.82,0.59-1.23,0.88c-1.17-1.49-2.59-2.85-3.46-4.5
                c-5.79-10.95-14.77-18.28-25.91-23.19c-4.17-1.83-8.41-3.45-12.78-0.33c-0.49,0.35-1.33,0.52-1.91,0.37
                c-8-2.1-13.33,2.37-18.38,7.44c-2.92,2.93-5.82,3.07-10.11,2.8c-6.78-0.42-10.14-4.83-14.68-8.15c-1.63-1.2-3.8-1.66-5.73-2.46
                c-0.69,1.77-1.99,3.55-1.95,5.31c0.2,11.2-5.87,19.33-13.42,26.28c-2.35,2.16-7.06,2.61-10.58,2.36
                c-2.71-0.19-5.11-1.93-6.22,2.44c-1.81-1.68-2.96-2.74-4.75-4.4C455.85,1261.46,458.07,1271.81,446.41,1275.92z M454.78,1245.87
                c1.17,0.48,2.14,0.57,2.55,1.09c4.51,5.78,10.89,4.75,16.95,4.69c1.06-0.01,2.43-0.5,3.12-1.26c5.69-6.27,11.99-12.22,11.96-21.7
                c-0.01-2.15,0.38-4.32,0.8-6.44c0.92-4.61,3.97-6.24,8.02-3.88c2.98,1.73,5.58,4.16,8.22,6.43c5.51,4.73,13.32,4.8,18.39-0.28
                c8.2-8.22,18.89-9.31,28.99-7.82c10.04,1.49,18.61,7.77,25.45,15.69c1.44,1.67,2.79,3.41,4.18,5.12c0.4-5.74-2.31-9.91-6.35-12.48
                c-12.05-7.66-24.42-14.83-36.72-22.09c-1.05-0.62-2.52-0.67-3.8-0.72c-3.84-0.15-7.76,0.27-11.51-0.37
                c-4.17-0.71-8.19-2.34-13.14-3.83c2.54,6.26-1.18,6.44-4.76,6.16c-2.96-0.23-5.88-1.05-8.8-1.69c-4.05-0.89-8.1-2.7-12.11-2.62
                c-14.83,0.3-29.12-1.98-43.03-7.08c-4.83-1.77-9.85-3.07-14.81-4.47c-1.12-0.32-2.38-0.15-4.4-0.24
                c8.99,7.25,17.24,13.89,25.48,20.54c-0.29,0.63-0.58,1.26-0.87,1.89c-10.77,2.26-21.01-3.44-31.98-2.91
                c-10.55,0.5-21.37-4.56-32.07-7.17c-0.18,0.37-0.36,0.73-0.54,1.1c4.54,4.24,9.07,8.48,14.56,13.62
                c-3.58,0.56-5.29,0.83-7.76,1.22c2.98,6.51,5.5,12.82,8.73,18.75c1.79,3.29,4.95,4.81,9.27,4.36
                c10.54-1.09,16.16,2.83,19.59,12.88c0.81,2.39,1.5,4.83,2.25,7.24c0.56-0.13,1.11-0.26,1.67-0.39c-0.93-4.78-1.67-9.61-2.85-14.33
                c-0.84-3.38-2.27-6.6-3.4-9.91c-1.17-3.42,0.04-5.28,3.6-6.13c9.14-2.17,15.41,4.25,15.97,15.24c0.44,8.77,2.12,17.48,3.25,26.22
                c0.68-0.02,1.35-0.05,2.03-0.07C456.27,1263.08,453.47,1254.27,454.78,1245.87z"/>
            <path d="M314.47,1213.72c18.37,12.56,31.3,29.5,40.71,49.53c7.42,15.79,9.15,32.18,7.15,49.22c-0.15,1.27-0.67,2.5-1.79,3.85
                c0.73-21.57-2.17-42.02-13.03-61.23c-10.65-18.83-25.7-33.02-42.83-45.78c14.42-0.72,23.41,10.35,35.94,15.65
                c-1.14-2.64-1.89-4.35-3.16-7.29c3.53,0.96,6.03,1.64,9.32,2.53c-2.02-8.74-9.85-13.03-14.53-21.04c3.41,0,5.69-0.45,7.72,0.07
                c14.21,3.67,27.23,9.62,37.92,20.07c22.84,22.35,31,49.71,28.23,81.01c-0.97,10.95-3.32,21.38-9.26,30.77
                c-0.89,1.41-2.06,2.66-3.93,3.69c2.51-15.64,7.06-30.93,3.19-46.84c-1.24,7.67-2.48,15.34-3.72,23.02
                c-0.75-0.01-1.5-0.01-2.25-0.02c-0.55-3.49-1.09-6.98-1.71-10.92c-0.75,0.82-1.28,1.12-1.35,1.5
                c-2.72,14.43-14.1,24.24-19.86,37.06c-0.92,2.05-3.73,3.26-5.67,4.86c-0.28-2.47-1.13-5.04-0.73-7.4
                c0.98-5.9,2.53-11.7,3.85-17.55c0.69,0.13,1.38,0.27,2.07,0.4c-1.09,6.24-2.17,12.48-3.26,18.71c0.33,0.13,0.66,0.25,0.99,0.38
                c1.33-2.21,2.61-4.46,4.01-6.62c3.87-6,7.84-11.92,11.64-17.96c1.04-1.65,1.71-3.55,2.36-5.41c1.32-3.76,2.25-7.68,3.81-11.33
                c1.48-3.49,2.04-3.45,5.62-0.96c0.89-5.36,1.76-10.6,2.72-16.4c6.47,5.21,6.92,12.7,3.44,41.84c8.64-10.15,7.58-50.71-2.55-72.51
                c-11.35-24.43-29.86-40.61-57.01-46.41c4.61,6.94,11.5,12.64,12.15,22.49c-2.62-0.53-4.29-0.86-6.5-1.31
                c1.07,2.51,1.99,4.65,2.9,6.79c-0.3,0.35-0.61,0.7-0.91,1.05c-5.51-3.06-11.02-6.14-16.54-9.19c-5.06-2.8-10.15-5.57-15.22-8.35
                c0,0-0.13-0.05-0.13-0.05L314.47,1213.72z"/>
            <path d="M325.47,1139.34c0.7,0.96,1.51,1.85,2.07,2.88c11.7,21.2,30.54,31.41,53.8,34.02c9.84,1.1,18.61,3.91,26.85,9.83
                c-0.72,0.48-1.14,0.99-1.46,0.93c-14.23-2.56-28.55-4.71-42.65-7.88c-19.01-4.26-31.34-16.64-38.71-34.31
                c-0.64-1.53-0.77-3.28-1.13-4.93C324.66,1139.7,325.06,1139.52,325.47,1139.34z M344.23,1165.56c0.37,0.4,0.74,0.8,1.11,1.2
                c3.84,4.63,9.1,6.58,14.87,7.55c-4.98-2.5-9.95-5-14.93-7.5c-0.38-0.39-0.76-0.78-1.13-1.17
                C344.15,1165.64,344.23,1165.55,344.23,1165.56z"/>
            <path d="M356.53,1075.04c-3.75,2.26-6.35,3.83-10.06,6.08c2.75,0.32,4.22,0.49,6.02,0.69c-8.54,5.46-16.89,10.57-25.01,16.04
                c-8.68,5.85-13.46,14.74-17.71,24.35c-2.38-5.33-3.47-10.33-1.75-15.8c1.03-3.28,0.99-6.96,2.36-10.04
                c1.84-4.11,3.79-8.77,7.09-11.51c10.12-8.41,22.29-11.5,35.38-11C353.61,1073.88,354.34,1074.32,356.53,1075.04z M346.36,1078.98
                c-0.3-0.53-0.61-1.06-0.91-1.59c-18.51-2.14-35.5,14.11-35.64,34.14c0,0.19,0.45,0.39-0.24-0.17c6.67-6.58,12.99-13.3,19.84-19.41
                C334.71,1087.22,340.68,1083.27,346.36,1078.98z"/>
            <path d="M224.65,1471.62c10.05-9.44,20.02-18.96,30.2-28.26c2.03-1.86,4.69-3.59,7.32-4.15c11.93-2.51,17.95-11.56,23.15-21.05
                c3.81-6.95,8.34-12.65,15.29-16.55c4.57-2.57,6.66-6.82,6.44-12.12c-0.01-0.17,0.03-0.34,0-0.5
                c-3.01-14.25,3.69-23.34,15.39-30.31c5.67-3.38,10.62-7.97,15.9-12.01c0,0-0.07-0.07-0.07-0.07c-4.03,4.11-7.99,8.29-12.11,12.31
                c-1.65,1.61-3.53,3.1-5.53,4.24c-9.13,5.21-12.94,12.63-11.12,23.27c1.49,8.73-2.74,14.63-10.24,19.24
                c-4.17,2.56-7.51,6.82-10.55,10.82c-3.8,5-6.71,10.67-10.41,15.76c-1.59,2.19-3.93,4.53-6.39,5.28
                c-13.61,4.11-23.22,13.85-33.45,22.81c-4.48,3.92-9.25,7.51-13.89,11.25C224.56,1471.56,224.65,1471.62,224.65,1471.62z"/>
            <path d="M639.21,1488.54c-9.5,0.54-18.66-0.81-27.43-4.96c-2.74-1.3-5.71-2.3-8.69-2.84c-4.24-0.76-8.62-0.78-12.87-1.51
                c-1.81-0.31-3.45-1.6-5.57-2.65c1.3-1.82,2.21-3.08,3.7-5.17c-26.05-3.52-51.78-2.21-77.62-3.98c7.61-3.24,74.73-0.34,81.73,3.64
                c-1.07,1.57-2.12,3.11-3.82,5.6c4.35,0.55,8.14,1.14,11.96,1.48c5.01,0.45,9.54,0.54,14.25,4.08c4.11,3.09,10.67,3.02,16.2,4.07
                c2.68,0.51,5.48,0.42,8.23,0.6C639.26,1487.45,639.23,1487.99,639.21,1488.54z"/>
            <path d="M428.56,1095.45c-2.72,0.52-5.48,0.9-8.16,1.6c-2.4,0.62-4.87,1.27-7.03,2.43c-9.34,4.99-10.07,9.69-2.94,17.52
                c4.24,4.65,4.26,7.37-1.04,10.87c-3.91,2.59-4.72,5.51-3.65,9.71c0.44,1.71,0.37,3.64,0.14,5.42c-1.28,10.19,1.7,16,10.82,20.76
                c1.87,0.98,3.75,1.92,5.44,3.78c-0.81,0-1.68,0.19-2.43-0.03c-12.08-3.46-17.9-12.15-16.68-24.76c0.13-1.31,0.36-2.73,0.02-3.96
                c-1.57-5.74-0.87-10.4,4.95-13.55c2.51-1.35,2.63-3.68,0.42-5.78c-0.12-0.11-0.22-0.25-0.34-0.36
                c-10.26-10.47-5.85-17.97,5.97-23.12c4.31-1.88,9.44-1.86,14.19-2.7C428.35,1094.01,428.46,1094.73,428.56,1095.45z"/>
            <path d="M273.01,1202.4c-8.29-5.43-16.78-10.57-24.75-16.43c-2.92-2.14-5.05-5.76-6.71-9.12c-3.01-6.11-1.74-9.11,3.96-12.91
                c2.32-1.54,4.22-3.69,6.43-5.41c1.92-1.49,3.84-3.25,6.06-4.03c9.75-3.47,12.39-13.73,5.2-21.49c-2.22-2.4-4.88-4.39-7.68-6.86
                c10.7,1.89,18.06,14.1,13.8,23.4c-1.34,2.93-5.34,5.62-8.63,6.48c-7.58,1.97-11.43,8.83-17.79,12.31
                c-1.01,0.55-1.01,4.4-0.33,6.31c2.76,7.82,8.98,12.52,15.87,16.54c5.27,3.07,10.42,6.36,15.62,9.55
                C273.71,1201.29,273.36,1201.85,273.01,1202.4z"/>
            <path d="M764.15,1450.59c-5.01-0.98-11.13-2.17-17.25-3.36c0-0.42,0.01-0.83,0.01-1.25c1.14-0.14,2.31-0.53,3.41-0.37
                c5.92,0.81,11.82,1.73,17.72,2.67c2.72,0.43,4.02,1.39,1.93,4.27c-1.32,1.82-2.03,4.07-3.46,7.07c20.13,2.6,39.58,1.86,58.19,6.87
                c-0.05,0.54-0.1,1.08-0.15,1.62c-1.31-0.08-2.63-0.11-3.93-0.24c-15.02-1.51-30.03-3.04-45.05-4.56
                c-1.82-0.18-3.74,0.01-5.44-0.53c-2.09-0.67-5.09-1.47-5.73-3.03C763.43,1457.4,764.15,1454.35,764.15,1450.59z"/>
            <path d="M269.87,1159.42c-0.15,1.14-0.15,2.08-0.41,2.95c-2.18,7.29-0.3,13.67,5,18.75c5.15,4.93,8.24,11.09,12.04,16.92
                c4.76,7.29,11.05,13.61,16.88,20.17c3.32,3.74,7.06,7.12,10.6,10.66c-0.41,0.42-0.81,0.83-1.22,1.25
                c-2.09-1.95-4.12-3.97-6.29-5.84c-10.93-9.43-20.49-19.87-26.86-33.11c-1.79-3.73-5.77-6.35-8.54-9.66
                c-4.8-5.75-6.79-12.16-3.83-19.48c0.31-0.76,0.8-1.46,1.28-2.12C268.7,1159.7,269.12,1159.68,269.87,1159.42z"/>
            <path d="M645.05,1231c-8.55-9-19.6-12.13-31.2-14.43c-4.79-0.95-9.64-3.01-13.73-5.69c-3.29-2.15-6.86-5.61-7.8-9.15
                c-1.19-4.49-3.6-6.14-7.29-6.94c-7.93-1.71-15.91-3.16-24.22-4.79c10.33-1.33,20.02,0.17,29.27,4.09c1.8,0.76,3.82,3.11,4.1,4.97
                c0.75,5.08,3.73,8.32,7.85,10.25c5.39,2.52,11.05,4.61,16.79,6.17c8.27,2.24,16.28,4.79,22.92,10.48
                c1.47,1.26,2.88,2.58,4.32,3.88C645.71,1230.22,645.38,1230.61,645.05,1231z"/>
            <path d="M660.19,1465.8c-4.52-0.68-9.05-1.26-13.55-2.05c-6.51-1.14-13.07-2.16-19.46-3.82c-2.04-0.53-3.51-3.03-5.39-4.42
                c-1.45-1.07-3.07-2.49-4.71-2.62c-7.44-0.58-14.91-0.75-22.37-1.1c-2.22-0.1-4.43-0.32-6.65-0.49c0.01-0.51,0.01-1.02,0.02-1.53
                c2.18,0,4.36-0.08,6.54,0.01c8.61,0.35,17.24,0.57,25.82,1.32c1.65,0.15,3.1,2.51,4.66,3.83c1.29,1.09,2.47,2.82,3.93,3.12
                c7.78,1.6,15.63,2.8,23.45,4.21c2.69,0.48,5.33,1.18,8,1.78C660.38,1464.64,660.28,1465.22,660.19,1465.8z"/>
            <path d="M321.44,1421.39c5.72-6.92,8.79-14.84,10.08-23.57c0.14-0.92,0.73-1.92,1.4-2.6c3.13-3.17,3.18-6.73,1.67-10.6
                c-3.6-9.25-1.97-16.82,6.72-22.57c2.82-1.87,4.97-4.76,7.43-7.18c0.4,0.36,0.81,0.72,1.21,1.07c-2.85,3.2-5.3,6.94-8.65,9.48
                c-5.9,4.47-7.08,9.93-4.63,16.31c2.31,6.01,2.31,11.17-2.65,15.96c-1.26,1.21-1.46,3.62-1.93,5.53
                C330.36,1410.35,328.07,1417.13,321.44,1421.39z"/>
            <path d="M537.98,1385.61c-7.23,10.31-17.93,15.42-29.58,17.99c-12.31,2.72-24.97,3.77-37.42,5.92c-8.15,1.4-16.19,3.45-24.57,4.21
                c6.77-1.72,13.47-3.83,20.33-5.06c12.43-2.23,24.99-3.74,37.43-5.91c11.58-2.01,21.68-7.28,30.48-15.08
                c0.96-0.85,2.25-1.35,3.38-2.01L537.98,1385.61z"/>
            <path d="M816.69,1456.69c-11.07-0.44-23.4,0.14-32.12-6.29c-8.82-6.51-18.05-5.8-27.25-7.79c-1.28-0.28-2.53-0.65-3.74-2.19
                c2.92,0.21,5.84,0.4,8.75,0.64c5.14,0.43,10.32,0.62,15.38,1.49c1.79,0.31,3.27,2.36,4.92,3.58c1.66,1.23,3.2,3.09,5.05,3.5
                c8.1,1.79,16.3,3.15,24.46,4.65c1.57,0.29,3.14,0.55,4.71,0.83C816.8,1455.64,816.75,1456.17,816.69,1456.69z"/>
            <path d="M788.35,1496.56c-10.17,0-20.36,0.4-30.49-0.24c-3.03-0.19-6.24-2.9-8.6-5.23c-0.86-0.85,0.52-3.96,1.04-6.98
                c-1.34-0.66-3.72-1.83-6.1-3c0.12-0.43,0.24-0.85,0.36-1.28c2.69,0.7,5.56,1.04,7.97,2.28c0.98,0.5,1.68,3.19,1.26,4.48
                c-1.46,4.46-0.69,6.69,3.86,7.6c2.72,0.55,5.6,0.55,8.4,0.45c5.47-0.19,10.92-0.74,16.39-0.92c2-0.06,4.01,0.53,6.02,0.83
                C788.41,1495.22,788.38,1495.89,788.35,1496.56z"/>
            <path d="M474.37,1372.74c3.38-2.38,6.52-5.28,10.19-7.05c14.93-7.17,25.31-19.05,34.46-32.31c0.46-0.66,0.97-1.28,1.76-1.61
                c-9.01,20.94-26.39,32.46-46.49,40.9C474.29,1372.67,474.37,1372.74,474.37,1372.74z"/>
            <path d="M451.21,1360.52c1.92-2.24,3.4-5.32,5.83-6.59c13.35-6.99,19.77-18.7,23.33-32.58c0.33-1.27,0.79-2.5,1.6-3.54
                c-1.82,13.73-5.95,26.21-18.64,34.19c-4.18,2.63-8.14,5.62-12.2,8.45C451.13,1360.44,451.21,1360.52,451.21,1360.52z"/>
            <path d="M500.73,1433.1c-16.24,3.58-32.47,7.15-49.29,10.86C467.19,1436.15,484.35,1436.34,500.73,1433.1z"/>
            <path d="M416.94,1438.41c-5.47,1.24-10.89,3.05-16.44,3.6c-9.57,0.95-19.22,1.11-28.83,1.48c15.12-1.67,30.23-3.34,45.35-5.02
                L416.94,1438.41z"/>
            <path d="M517.09,1249.68c6.11,6.31,7.75,14.51,4.56,19.67c-1.41-6.18-2.95-12.96-4.49-19.74L517.09,1249.68z"/>
            <path d="M425.89,1136.06c-5.88-2.63-7.97-8.5-5.19-14.12C422.39,1126.55,424.15,1131.33,425.89,1136.06z"/>
            <path d="M209.22,1479.44c-7.91,6.88-17.84,8.67-27.59,10.91c-0.64,0.15-1.3,0.22-1.94,0.33c0,0,0.09,0.04,0.09,0.04
                c2.92-1.07,5.85-2.11,8.75-3.22c6.93-2.65,13.84-5.33,20.76-7.99C209.29,1479.5,209.22,1479.44,209.22,1479.44z"/>
            <path d="M451.13,1360.44c-3.98,7.22-10.9,11.74-22.08,13.47c2.53-1.08,5.1-2.06,7.57-3.26c2.67-1.3,5.4-2.6,7.82-4.29
                c2.43-1.69,4.52-3.87,6.76-5.84C451.21,1360.52,451.13,1360.44,451.13,1360.44z"/>
            <path d="M448.29,1382.64c7.62-6.13,17.04-7.51,26.08-9.9c0,0-0.07-0.06-0.07-0.06c-8.69,3.3-17.38,6.6-26.07,9.9
                C448.22,1382.57,448.29,1382.64,448.29,1382.64z"/>
            <path d="M498.54,1183.45c5.01,0.33,10.03,0.65,15.04,0.97c-0.04,0.7-0.08,1.4-0.12,2.09c-4.97-0.43-9.94-0.87-14.91-1.3
                C498.55,1184.63,498.54,1184.04,498.54,1183.45z"/>
            <path d="M415.71,1467.36c-5.48,0.52-10.97,1.04-16.45,1.56c-0.04-0.35-0.07-0.71-0.11-1.06c5.41-0.5,10.82-1.01,16.23-1.51
                C415.49,1466.69,415.6,1467.03,415.71,1467.36z"/>
            <path d="M760.36,1484.89c-1.92-4.25,0.37-6.53,4.59-9.24C763.05,1479.49,761.71,1482.19,760.36,1484.89z"/>
            <path d="M767.31,1489.99c-0.87-1.39-1.73-2.78-2.6-4.17c0.41-0.29,0.83-0.58,1.24-0.87c0.92,1.34,1.83,2.68,2.75,4.02
                C768.24,1489.31,767.78,1489.65,767.31,1489.99z"/>
            <path d="M330.47,1461.6c2.57-2.02,5.14-4.04,7.71-6.05c0,0-0.08-0.07-0.08-0.07c-2.57,2.02-5.14,4.03-7.71,6.05L330.47,1461.6z"/>
            <path d="M353.01,1446.37c-2.56,1.37-5.11,2.74-7.67,4.12l0.07,0.08c2.56-1.38,5.12-2.75,7.67-4.13L353.01,1446.37z"/>
            <path d="M345.33,1450.49c-2.41,1.66-4.82,3.32-7.23,4.98c0,0,0.08,0.07,0.07,0.07c2.41-1.66,4.82-3.32,7.23-4.98
                C345.41,1450.57,345.33,1450.49,345.33,1450.49z"/>
            <path d="M330.39,1461.52c-1.99,1.34-3.99,2.68-5.98,4.02c0,0,0.07,0.06,0.07,0.06c2-1.34,3.99-2.67,5.99-4.01
                C330.47,1461.6,330.39,1461.52,330.39,1461.52z"/>
            <path d="M417.02,1438.47c1.74-0.6,3.48-1.21,5.22-1.81l-0.05-0.08c-1.74,0.61-3.49,1.22-5.24,1.83
                C416.94,1438.41,417.02,1438.47,417.02,1438.47z"/>
            <path d="M364.19,1444.1c-0.27,0.03-0.4,0.07-0.53,0.05c-0.05-0.01-0.06-0.17-0.09-0.26C363.74,1443.94,363.91,1444,364.19,1444.1z
                "/>
            <path d="M445.27,1384.51c1.01-0.63,2.01-1.25,3.02-1.88c0,0-0.07-0.07-0.06-0.07c-1.01,0.62-2.01,1.25-3.02,1.87L445.27,1384.51z"
                />
            <path d="M356.53,1445.72c-0.02-0.02-0.05-0.03-0.08-0.05C356.45,1445.66,356.53,1445.72,356.53,1445.72z"/>
            <path d="M179.69,1490.68c-0.23,0-0.45,0-0.68,0c0,0.01,0,0.02,0,0.03c0.25,0,0.5,0,0.76,0
                C179.78,1490.72,179.69,1490.68,179.69,1490.68z"/>
            <path d="M353.08,1446.43c1.15-0.24,2.29-0.48,3.45-0.72c0,0-0.07-0.05-0.07-0.05c-1.14,0.24-2.29,0.48-3.45,0.71
                C353.01,1446.37,353.08,1446.43,353.08,1446.43z"/>
            <path d="M209.29,1479.5c0.64-0.35,1.29-0.7,1.93-1.05c0,0-0.08-0.06-0.08-0.06c-0.64,0.35-1.28,0.7-1.92,1.05
                C209.22,1479.44,209.29,1479.5,209.29,1479.5z"/>
            <path d="M211.22,1478.45c0.74-0.27,1.48-0.53,2.23-0.8l-0.05-0.08c-0.75,0.28-1.5,0.55-2.26,0.83
                C211.14,1478.39,211.22,1478.45,211.22,1478.45z"/>
            <path d="M322.5,1466.59c0.66-0.33,1.31-0.66,1.97-0.98c0,0-0.07-0.06-0.07-0.06c-0.66,0.33-1.32,0.66-1.98,0.98
                C322.42,1466.53,322.5,1466.59,322.5,1466.59z"/>
            <path d="M320.6,1467.68c0.63-0.36,1.26-0.73,1.9-1.09c0,0-0.07-0.06-0.07-0.06c-0.64,0.38-1.28,0.75-1.92,1.13
                C320.51,1467.65,320.6,1467.68,320.6,1467.68z"/>
            <path d="M444.04,1385.66c-0.63,0.25-1.25,0.51-1.88,0.76c0,0,0.04,0.08,0.04,0.08c0.64-0.25,1.27-0.51,1.91-0.76
                C444.11,1385.73,444.04,1385.66,444.04,1385.66z"/>
            <path d="M320.51,1467.65l-0.28-0.01c0,0,0.36,0.03,0.37,0.03C320.6,1467.68,320.51,1467.65,320.51,1467.65z"/>
            <path d="M224.56,1471.56c-0.66,0.35-1.32,0.69-1.98,1.04c0,0,0.08,0.05,0.08,0.05c0.66-0.34,1.31-0.69,1.97-1.03
                C224.65,1471.62,224.56,1471.56,224.56,1471.56z"/>
            <path d="M222.59,1472.59c-0.74,0.27-1.48,0.55-2.22,0.82c0,0,0.06,0.08,0.06,0.08c0.74-0.28,1.49-0.57,2.24-0.85
                C222.67,1472.65,222.59,1472.59,222.59,1472.59z"/>
            <path d="M422.24,1436.66c0,0-0.06-0.08-0.06-0.08S422.24,1436.66,422.24,1436.66z"/>
            <path d="M442.16,1386.42c0,0,0.04,0.09,0.04,0.08C442.2,1386.5,442.16,1386.42,442.16,1386.42z"/>
            <path d="M538.04,1385.67c0.35-0.37,0.7-0.73,1.05-1.1l-0.06-0.08c-0.35,0.37-0.69,0.74-1.04,1.11
                C537.98,1385.61,538.04,1385.67,538.04,1385.67z"/>
            <path d="M445.2,1384.44c-0.39,0.4-0.77,0.81-1.16,1.21c0,0,0.07,0.08,0.07,0.08c0.39-0.41,0.77-0.81,1.16-1.22
                C445.27,1384.51,445.2,1384.44,445.2,1384.44z"/>
            <path d="M539.09,1384.58c0,0-0.07-0.07-0.07-0.07C539.02,1384.5,539.09,1384.58,539.09,1384.58z"/>
            <path d="M220.37,1473.42c0,0,0.06,0.08,0.06,0.08S220.37,1473.42,220.37,1473.42z"/>
            <path d="M213.45,1477.65c0,0-0.05-0.08-0.05-0.08C213.4,1477.57,213.45,1477.65,213.45,1477.65z"/>
            <path d="M338.33,1346.67c0,0-0.07-0.07-0.07-0.07C338.26,1346.59,338.33,1346.67,338.33,1346.67z"/>
            <path d="M517.17,1249.62c0,0-0.07,0.07-0.07,0.07C517.09,1249.68,517.17,1249.62,517.17,1249.62z"/>
            <path d="M168.48,1245.08c14.09,13.26,26.81,27.63,35.47,45.2c0.78,1.58,0.34,3.8,0.32,5.71c-0.01,0.8-0.67,1.63-0.59,2.4
                c1.44,15.24,0.32,31.48,9.55,44.51c10.31,14.56,15.69,29.71,14.15,47.59c-0.75,8.73,0.04,17.6,0.08,26.41
                c0,1.11-0.33,2.22-1.72,3.35c0-1.68,0.02-3.37,0-5.05c-0.19-11.98-0.26-23.97-0.64-35.95c-0.37-11.89-4.78-22.39-11.99-31.71
                c-5.67-7.33-8.99-15.26-9.15-24.77c-0.09-5.55-2.18-11.06-3.14-16.62c-0.39-2.26-0.77-4.85-0.05-6.9c1.83-5.2,0.36-9.6-2.61-13.58
                c-8.74-11.72-17.68-23.3-26.57-34.92c-1.3-1.69-2.74-3.27-4.12-4.9C167.8,1245.59,168.14,1245.33,168.48,1245.08z"/>
            <path d="M634,1330.63c-0.33,0.67-0.67,1.34-1,2.01c-0.81,1.57-1.63,3.13-2.44,4.7l0.06-0.03c-2.37-0.02-3.56-0.74-2.11-3.29
                c1.12-1.97,2.1-4.03,2.42-6.56c-0.41,0.37-0.89,0.69-1.23,1.12c-8.29,10.18-17.4,9.67-24.4-1.72c-4.25-6.91-2.32-14.45-2.24-21.75
                c0.86-0.04,1.72-0.08,2.57-0.12c0,2.22-0.01,4.44,0,6.66c0.01,3.13-0.47,6.36,0.15,9.36c1.33,6.4,5.28,8.78,12.05,7.95
                c-1.64-1.35-4.14-2.42-4.66-4.11c-0.6-1.94,0.06-4.64,1.01-6.62c2.22-4.63,6.87-4.97,14.43-1.13c-1.85-5.29-3.69-10.12-5.19-15.06
                c-0.59-1.95-0.96-4.29-0.4-6.15c0.41-1.36,2.4-2.87,3.86-3.08c1.16-0.17,3.44,1.31,3.7,2.43c0.34,1.39-0.71,3.25-1.49,4.73
                c-0.4,0.76-1.5,1.15-2.53,1.88c1.76,4.63,3.44,9.09,5.39,14.23c0.83-1.95,1.35-3.17,1.88-4.39c0.45-0.04,0.89-0.08,1.34-0.12
                c0.31,1.71,1.42,3.87,0.77,5.05C633.45,1321.15,634.81,1326.01,634,1330.63z M617.11,1318.15c0.01,0.6,0.02,1.21,0.03,1.81
                c2.7,2.37,5.26,3.18,8.21-1.81C622.07,1318.15,619.59,1318.15,617.11,1318.15z"/>
            <path d="M718.06,1357.61c-1.97-1.5-3.94-3.01-6.45-4.93c1.5-0.69,2.49-1.14,3.66-1.67c-0.69-1.45-1.16-2.9-2-4.1
                c-4.28-6.12-7.44-13.51-6.15-20.24c1.27-6.64-2.2-11.24-2.8-16.97c4.86,5.46,8.36,11.73,7.98,18.97
                c-0.27,5.17-0.03,9.56,2.67,14.19c2.56,4.39,3.85,9.52,5.85,14.68c-0.77,0-1.82,0-2.87,0
                C717.96,1357.54,718.06,1357.61,718.06,1357.61z"/>
            <path d="M592.77,1336.2c-3.68-4.21-7.59-8.25-10.97-12.7c-2.64-3.47-4.89-7.31-6.81-11.23c-2.96-6.06-0.43-14.08,6.49-19.02
                c-1.15,2.81-1.82,4.55-2.56,6.25c-3.33,7.68,0.82,18.6,7.48,23.6c3.65,2.73,5.64,7.68,8.38,11.63
                C594.1,1335.22,593.44,1335.71,592.77,1336.2z"/>
            <path d="M630.56,1337.34c-1.89,2.25-3.65,4.62-5.68,6.72c-5.57,5.75-11.08,2.56-16.58-0.28c-3.31-1.7-6.77-3.14-9.97-5.04
                c-1.12-0.67-1.63-2.35-2.42-3.57c0.3-0.28,0.6-0.57,0.9-0.85c5.27,2.65,10.47,5.43,15.82,7.89c3.18,1.46,6.39,2.26,9.74-0.42
                c2.4-1.92,5.47-3.01,8.25-4.48C630.62,1337.32,630.56,1337.34,630.56,1337.34z"/>
            <path d="M652.77,1278.65c2.63,4.22,5.33,8.41,7.87,12.69c1.17,1.98,2.12,4.1,3.03,6.22c0.53,1.23,0.77,2.59,1.14,3.89
                c-1.07,0.18-2.45,0.87-3.15,0.45c-1.43-0.87-3.76-2.86-3.5-3.49c1.81-4.49-1.99-6.69-3.54-9.71c-1.62-3.17-3.65-6.12-5.98-9.96
                c2.13,0,3.2,0,4.25,0C652.88,1278.73,652.77,1278.65,652.77,1278.65z"/>
            <path d="M631.83,1408.22c2.87-7.33,5.38-13.74,7.9-20.15c0.69,0.06,1.38,0.11,2.08,0.17c0.06,5.28,0.23,10.57,0.04,15.84
                c-0.03,0.79-1.66,1.79-2.74,2.23C637.41,1406.98,635.54,1407.27,631.83,1408.22z"/>
            <path d="M764.74,1280.23c3.38,8.22,6.25,15.19,9.12,22.15c-0.42,0.22-0.84,0.44-1.26,0.66c-3.11-4.72-6.21-9.43-9.31-14.15
                c-0.45-0.69-0.87-1.4-1.31-2.1c-0.98-1.59-1.95-3.18-2.92-4.76C761.09,1281.38,763.12,1280.74,764.74,1280.23z"/>
            <path d="M689.86,1378.01c1.12-2.45,1.46-3.72,2.19-4.71c2.97-4.06,4.8-8.29,3.33-13.5c-0.21-0.74,0.99-1.88,1.54-2.83
                c0.42,0.16,0.83,0.32,1.25,0.48c-0.49-2.37-0.99-4.74-1.57-7.53C703.99,1356.01,700.69,1371.19,689.86,1378.01z"/>
            <path d="M608.74,1410.71c5.09,2.93,4.46,6.97,2.96,11.85c-1.22-1.27-2.1-2.2-2.39-2.5c-2.93,2.7-5.74,5.28-8.54,7.85
                c-0.44-0.37-0.87-0.74-1.31-1.1C602.51,1421.53,605.55,1416.26,608.74,1410.71z"/>
            <path d="M859.34,1440.24c-2.18-1.61-4.38-2.37-4.84-3.72c-1.76-5.17-2.95-10.53-4.67-16.99
                C855.26,1425.58,861.15,1430.04,859.34,1440.24z"/>
            <path d="M597.75,1293.55c-0.42-0.06-1.6-0.21-2.77-0.36c-0.09-0.55-0.17-1.11-0.26-1.66c2.1-0.4,5.4-1.82,6.09-1.02
                c2.05,2.4,3.26,5.61,4.31,8.68c0.16,0.45-2.32,2.58-3.54,2.55c-1.53-0.04-3.86-1.19-4.33-2.43
                C596.63,1297.68,597.51,1295.5,597.75,1293.55z"/>
            <path d="M805.82,1342.88c2.01,7-1,15.06-7.3,19.8c-2.13-2.75,0.12-4.09,1.4-6.09c1.27-1.98,1.31-4.7,2.24-6.95
                C803.07,1347.44,804.42,1345.43,805.82,1342.88z"/>
            <path d="M719.83,1420.56c3.53,5.03,1.53,10.12-0.52,15.38c-1.24-6.33-2.47-12.65-3.75-19.21c0.49,0.17,1.45,0.51,2.4,0.84
                l-0.12-0.09c0.36,0.71,0.71,1.43,1.07,2.14l-0.09-0.12c0.37,0.37,0.74,0.74,1.11,1.11L719.83,1420.56z"/>
            <path d="M584.83,1281.87c8.37-9.62,10.65-10.56,15.93-6.27C595.52,1277.67,590.6,1279.6,584.83,1281.87z"/>
            <path d="M627.08,1289.17c-0.72,0.63-1.38,1.4-2.2,1.88c-0.91,0.53-2.01,1.14-2.96,1.04c-0.5-0.05-1.36-1.7-1.15-2.34
                c0.35-1.08,1.23-2.39,2.21-2.78c4.61-1.79,9.48,0.94,10.93,7.03C631.81,1292.51,629.7,1291.02,627.08,1289.17z"/>
            <path d="M611.13,1356.73c3.38,4.96,6.43,9.42,9.63,14.12C614.94,1369.69,610.88,1363.77,611.13,1356.73z"/>
            <path d="M829.4,1403.44c3.5,4.88,6.33,8.83,9.31,12.98C834.08,1416.04,830.1,1410.68,829.4,1403.44z"/>
            <path d="M796.82,1379.72c-0.47-1.51-0.94-3.02-1.41-4.53c0.45-0.23,0.89-0.45,1.34-0.68c2.53,4.23,5.05,8.45,7.58,12.68
                c-0.6,0.41-1.21,0.82-1.81,1.22c-1.93-2.93-3.85-5.87-5.78-8.8L796.82,1379.72z"/>
            <path d="M717.96,1417.58c-1.65-3.12-3.29-6.24-5.13-9.74c3.69,1.22,9.1,8.32,8.67,11.07c-0.1,0.62-1.09,1.1-1.66,1.65
                c0,0,0.11,0.06,0.11,0.06c-0.37-0.37-0.74-0.74-1.11-1.11c0,0,0.09,0.12,0.09,0.12c-0.36-0.71-0.71-1.43-1.07-2.14L717.96,1417.58
                z"/>
            <path d="M606.94,1294.87c-1.62-2.42-3.02-3.81-3.44-5.45c-0.21-0.85,1.34-2.14,2.09-3.23c1.38,1.42,3.04,2.67,3.98,4.33
                C609.88,1291.07,608.31,1292.68,606.94,1294.87z"/>
            <path d="M778.89,1327.69c-0.57,2.1-1.15,4.21-1.72,6.31c-0.65-0.09-1.31-0.17-1.96-0.26c-0.3-2.61-0.6-5.23-1.05-9.18
                c2.28,1.51,3.56,2.37,4.84,3.22C779,1327.78,778.89,1327.69,778.89,1327.69z"/>
            <path d="M796.74,1379.61c0.09,2.57-1.06,3.35-2.71,1.27c-1.98-2.49-3.45-5.4-5.13-8.12c0.39-0.28,0.79-0.57,1.18-0.85
                c2.25,2.6,4.5,5.2,6.75,7.8C796.82,1379.72,796.74,1379.61,796.74,1379.61z"/>
            <path d="M806.47,1391.04c-0.9-2.89-1.8-5.77-2.7-8.66c0.62-0.21,1.24-0.43,1.86-0.64c1.02,2.87,2.03,5.74,3.05,8.61
                C807.94,1390.58,807.21,1390.81,806.47,1391.04z"/>
            <path d="M792.96,1363.51c1.6,0.77,3.2,1.54,4.8,2.31c-0.73,0.71-1.68,2.13-2.15,1.99c-1.37-0.41-2.55-1.45-3.81-2.25
                C792.18,1364.88,792.57,1364.2,792.96,1363.51z"/>
            <path d="M846.03,1422.39c-1.62-2.51-3.23-5.02-4.85-7.53c0.41-0.29,0.83-0.57,1.24-0.86c1.77,2.38,3.55,4.75,5.32,7.13
                C847.18,1421.55,846.61,1421.97,846.03,1422.39z"/>
            <path d="M825.89,1406.98c-1.27-2.22-2.54-4.43-3.82-6.65c0.51-0.33,1.02-0.66,1.53-0.99c1.39,2.15,2.78,4.3,4.17,6.45
                C827.15,1406.19,826.52,1406.59,825.89,1406.98z"/>
            <path d="M622.05,1366.82c1.87,1.75,3.74,3.5,5.61,5.25c-0.46,0.45-0.91,0.9-1.37,1.35c-1.79-1.83-3.58-3.66-5.36-5.5
                C621.3,1367.55,621.68,1367.19,622.05,1366.82z"/>
            <path d="M779,1327.78c-1.1-2.15-2.21-4.29-3.44-6.69c5.05,1.81,5.92,3.71,3.33,6.61C778.89,1327.69,779,1327.78,779,1327.78z"/>
            <path d="M623.03,1409.3c1.14-1.58,2.28-3.16,3.42-4.74c0.42,0.29,0.83,0.57,1.25,0.86c-1.06,1.67-2.11,3.35-3.17,5.02
                C624.03,1410.06,623.53,1409.68,623.03,1409.3z"/>
            <path d="M758.6,1279.08c-1.64-0.62-3.28-1.24-4.93-1.87c0.12-0.42,0.23-0.83,0.35-1.25c1.69,0.45,3.38,0.9,5.08,1.35
                C758.93,1277.9,758.76,1278.49,758.6,1279.08z"/>
            <path d="M647.75,1355.88c0.26-1.01,0.52-2.03,0.79-3.04c0.38,0.07,0.76,0.14,1.13,0.22c-0.13,1.04-0.26,2.07-0.39,3.11
                C648.77,1356.07,648.26,1355.97,647.75,1355.88z"/>
            <path d="M774.87,1337.54c-0.06,1.23-0.12,2.47-0.18,3.7c-0.48,0.01-0.96,0.02-1.44,0.03c-0.06-1.24-0.12-2.48-0.19-3.72
                C773.67,1337.55,774.27,1337.55,774.87,1337.54z"/>
            <path d="M632.39,1274.01c-0.71,0.49-1.19,1.06-1.67,1.07c-0.4,0.01-0.81-0.66-1.21-1.03c0.51-0.34,0.97-0.81,1.53-0.97
                C631.3,1273.01,631.73,1273.53,632.39,1274.01z"/>
            <path d="M627.17,1271.11c-0.43,0.53-0.74,1.19-0.99,1.17c-0.47-0.04-0.9-0.48-1.35-0.75c0.33-0.32,0.6-0.81,0.99-0.91
                C626.14,1270.53,626.59,1270.88,627.17,1271.11z"/>
            <path d="M598.34,1266.11c0.51,0.34,0.89,0.59,1.27,0.84c-0.33,0.41-0.66,0.83-0.99,1.24c-0.35-0.3-0.89-0.55-1-0.92
                C597.54,1267,598.03,1266.57,598.34,1266.11z"/>
            <path d="M599.02,1419.73c-0.38-0.53-0.7-0.81-0.78-1.15c-0.05-0.2,0.31-0.7,0.45-0.69c0.36,0.03,0.7,0.27,1.05,0.43
                C599.55,1418.7,599.36,1419.08,599.02,1419.73z"/>
            <path d="M719.49,1444.5c-0.2,0.18-0.39,0.35-0.59,0.52c-0.13-0.24-0.28-0.48-0.37-0.73c-0.02-0.05,0.27-0.29,0.33-0.26
                C719.09,1444.14,719.28,1444.33,719.49,1444.5z"/>
            <path d="M621.5,1269.99c-0.17,0.21-0.35,0.41-0.52,0.62c-0.19-0.18-0.38-0.36-0.55-0.56c-0.01-0.01,0.21-0.31,0.3-0.3
                C621,1269.78,621.25,1269.9,621.5,1269.99z"/>
            <path d="M456.76,1091.62c3.66-2.62,7.66-4.53,12.45-3.21c5.08,1.41,12.16-3.04,12.42-8.58c0.31-6.63,0.14-13.3-0.06-19.94
                c-0.34-11.44,1.1-13.66,11.88-17.45c0.93-0.33,2.07-0.63,2.65-1.32c6.38-7.74,15.46-7.46,24.17-8.3c1.28-0.12,3.05-0.09,3.7-0.87
                c5.73-6.88,13.83-6.85,21.64-7.95c2.89-0.41,6.29-0.83,8.4-2.55c7.61-6.21,15.06-9.55,24.08-0.63c0.58-3.31,0.96-5.48,1.33-7.65
                c0.81-4.73,2.75-8.44,7.88-9.86c1.85-0.51,3.39-2.03,5.14-2.98c1.88-1.02,3.95-2.88,5.75-2.68c9.2,1.03,12.84-5.95,16.85-11.72
                c3.04-4.38,6.32-7,11.68-7.11c3.55-0.07,7.09-0.55,10.63-0.85c0.11,0.59,0.22,1.18,0.33,1.77c-3.86,0.81-7.84,1.26-11.53,2.55
                c-2.73,0.96-5.48,2.61-7.52,4.66c-3.49,3.51-6.18,7.82-9.71,11.29c-1.44,1.41-4.18,1.49-6.34,2.18c-3.22,1.02-6.45,2.03-9.66,3.09
                c-0.45,0.15-0.78,0.73-1.23,0.82c-10.01,2.18-9.73,9.92-9.59,17.62c0.21,11.65,0.42,23.3,0.57,34.94
                c0.02,1.67-0.35,3.34-0.54,5.01c-0.62,0.06-1.23,0.11-1.85,0.17c-0.45-2.4-1.12-4.77-1.3-7.19c-0.71-9.28-0.93-18.62-1.98-27.86
                c-0.85-7.42-8.63-12.13-14.09-7.64c-8.96,7.38-19.82,6.71-29.67,10.05c-2.41,0.81-4.29,3.09-6.54,4.48
                c-1.42,0.88-3.03,1.79-4.63,1.96c-3.95,0.42-7.95,0.31-11.91,0.66c-1.79,0.16-3.68,0.62-5.25,1.46c-5.64,3-11.56,5.71-16.57,9.57
                c-2.27,1.75-3.5,5.92-3.68,9.08c-0.45,7.94-0.06,15.92,0.03,23.89c0.03,3.08-2.04,4.57-3.82,6.79c-4.19,5.22-8.06,6.28-14.26,3.79
                c-2.45-0.98-6.19,1.23-9.34,1.99C457.09,1092.6,456.92,1092.11,456.76,1091.62z"/>
            <path d="M649.57,982.32c0.17,1.38,0.51,2.75,0.49,4.13c-0.13,10.82-0.43,21.64-0.45,32.46c-0.01,5.01-2.1,7.81-7.12,8.45
                c-11.12,1.43-17.18,8.31-20.52,18.55c-0.97,2.97-3.57,5.37-5.13,8.19c-2.62,4.74-6.08,9.37-7.24,14.49
                c-1.89,8.38-2.26,17.12-3.19,25.71c-1.22,11.25-2.31,22.51-3.61,33.74c-0.23,1.99-1.19,3.91-2.23,7.13
                c-0.92-1.96-1.53-2.63-1.47-3.24c1.8-18.88,3.54-37.77,5.59-56.63c1.03-9.45,3.94-18.18,11.32-24.9c1.36-1.24,2.05-3.39,2.64-5.25
                c3.63-11.32,10.28-19.33,22.69-21.17c3.5-0.52,4.53-2.62,4.57-5.94c0.12-8.32,0.4-16.64,0.79-24.96
                c0.17-3.59,0.75-7.16,1.15-10.73C648.44,982.34,649.01,982.33,649.57,982.32z"/>
            <path d="M1032.56,692.17c3.05-1.32,6-3.35,9.17-3.81c4.6-0.67,9.38-0.02,14.06-0.35c1.51-0.11,3.57-1.15,4.29-2.4
                c3.97-6.84,7.71-13.82,11.29-20.88c1.38-2.72,2.13-5.76,3.16-8.65c0.55,0.13,1.1,0.25,1.65,0.38c-0.45,2.54-0.9,5.09-1.34,7.63
                c-1.72,10.02-7.02,18.21-13.47,25.71c-2.22,2.58-4.26,4.55-8.8,1.96c-3.05-1.74-8.24,0.04-12.42,0.58
                c-2.3,0.3-4.51,1.32-6.76,2.01C1033.11,693.63,1032.84,692.9,1032.56,692.17z"/>
            <path d="M1074.55,679.01c3.8,10.85,12.07,17.31,21.83,22.35C1084.95,702.75,1072.26,688.11,1074.55,679.01z"/>
            <path d="M931.22,939.95c1.69,0.42,3.41,0.76,5.07,1.28c4.74,1.49,9.32,2.43,13.71-1.2c0.93-0.77,2.92-0.84,4.24-0.5
                c16.03,4.19,30.49,0.06,44.34-7.93c1.2-0.69,3.85-1.03,4.36-0.38c2.89,3.66,5.78,1.98,8.29,0.12
                c6.66-4.94,13.17-10.08,19.58-15.34c1.46-1.2,2.27-3.21,3.38-4.84c0.54,0.46,1.08,0.91,1.63,1.37c-0.21,0.7-0.26,1.52-0.66,2.07
                c-6.73,9.19-15.46,15.99-25.54,21.1c-1.26,0.64-3.4,1.28-4.28,0.68c-6.06-4.13-11.11,0.35-15.4,2.67
                c-11.2,6.07-22.62,5.66-34.47,3.98c-1.93-0.27-4.24,0.03-5.96,0.9C943.95,946.71,935.27,945.51,931.22,939.95z"/>
            <path d="M956.62,1022.98c2.34-0.43,4.73-0.67,7-1.34c3.63-1.08,7.18-2.46,10.76-3.7c6.59-2.28,13.19-4.53,19.76-6.85
                c1.23-0.43,2.46-0.99,3.55-1.71c3.7-2.46,7.32-4.57,10.47,0.93c12.41-9.75,19.71-22.74,26.41-36.19c0.4,0.19,0.79,0.38,1.19,0.57
                c0,0.67,0.2,1.42-0.03,2c-5.02,13.07-12.2,24.73-22.66,34.19c-2.8,2.53-5.65,5.33-8.92,0.36c-0.39-0.59-2.09-0.79-2.96-0.51
                c-7.52,2.42-15.01,4.92-22.47,7.53c-5.73,2.01-11.37,4.31-17.12,6.25c-1.43,0.48-3.16,0.07-4.75,0.07
                C956.77,1024.05,956.69,1023.51,956.62,1022.98z"/>
            <path d="M1053.89,888.63c-4.86,5.69-9.37,11.13-14.45,12.59c5.18-4.86,11.54-10.8,17.89-16.75c-0.53-0.87-1.05-1.74-1.58-2.61
                c0.56,1.22,1.05,2.47,1.7,3.64c0.7,1.24,1.3,2.75,2.38,3.51c4.7,3.31,4.81,7.51,0.67,11.24c-2.44,2.2-4.12,5.26-6.12,7.95
                c-0.99,1.33-1.95,2.67-2.86,4.06c-1.87,2.85-4,5.59-5.49,8.64c-2.86,5.85-7.74,8.87-13.39,11.68c-0.3-1.16-0.42-1.43-0.37-1.46
                c7.44-5.04,11.15-13.13,16.35-19.99c1.09-1.44,2.12-2.93,3.14-4.41c1.75-2.53,3.12-5.43,5.28-7.53c3.91-3.82,4.06-4.11-0.34-7.63
                C1055.81,890.84,1055.1,889.9,1053.89,888.63z"/>
            <path d="M1084.82,1388.74c-2.16,0-4.32-0.15-6.46,0.03c-4.82,0.41-8.32-0.64-8.16-6.48c0.02-0.59-0.32-1.27-0.66-1.79
                c-3.36-5.06-2.75-6.77,3.1-8.97c4.81-1.8,9.27-2.52,12.97,2.33c0.77,1,2.54,1.34,3.93,1.75c1.74,0.51,4.11,0.21,5.21,1.28
                c2.3,2.24,5.51,5.15,5.5,7.78c-0.01,4.14-4.29,4.77-8,4.57C1089.78,1389.11,1087.3,1388.91,1084.82,1388.74z M1071.76,1375.54
                c0.75,2.97,1.09,5.25,1.95,7.33c0.44,1.07,1.74,2.42,2.75,2.5c5.46,0.44,10.95,0.67,16.42,0.62c1.38-0.01,2.75-1.33,4.12-2.05
                c-1.03-1.36-1.8-3.18-3.17-3.95c-1.48-0.84-3.56-0.54-5.3-1.03c-1.85-0.53-4.23-0.9-5.29-2.22
                C1079.38,1371.97,1075.47,1373.78,1071.76,1375.54z"/>
            <path d="M1086.17,1355.64c2.49,2.58,5.32,4.15,5.48,5.96c0.38,4.21-3.17,5.4-6.74,5.6c-3.82,0.22-8.36-3.12-7.09-5.16
                C1079.38,1359.54,1082.71,1358.15,1086.17,1355.64z M1082.48,1361.23c0,0.63,0,1.26,0,1.89c1.57-0.12,3.13-0.24,4.7-0.37
                c-0.03-0.51-0.06-1.02-0.09-1.53C1085.55,1361.23,1084.01,1361.23,1082.48,1361.23z"/>
            <path d="M936.84,1169.1c1.15-1.07,2.32-2.12,3.43-3.23c6.58-6.51,13.19-13,19.71-19.57c2.57-2.59,4.83-5.49,7.43-8.06
                c1-0.99,3-2.36,3.77-1.98c4.6,2.26,6.32-1.74,9.27-3.51c7.21-4.32,12.48-10.67,18.46-16.29c1.31-1.23,4.04-0.85,6.07-1.42
                c1.56-0.44,3.26-0.9,4.51-1.85c13.96-10.62,21.41-25.52,27.13-41.56c1.11-3.11,2.59-6.09,3.9-9.13c0.41,0.14,0.82,0.29,1.23,0.43
                c0.06,0.6,0.32,1.25,0.16,1.78c-5.37,17.77-11.16,35.31-26.27,47.74c-2.43,2-4.51,4.45-7.02,6.35c-1.13,0.86-3.63,1.68-4.26,1.12
                c-2.8-2.52-4.75-1.07-6.13,1.13c-5.02,7.96-14.05,11.21-20.4,17.63c-1.19,1.2-3.82,0.93-5.75,1.45c-1.45,0.4-3.27,0.59-4.2,1.57
                c-2.85,3-5.1,6.6-8.05,9.49c-6.25,6.13-12.78,11.98-19.26,17.86c-0.74,0.67-1.92,0.86-2.9,1.27
                C937.4,1169.92,937.12,1169.51,936.84,1169.1z"/>
            <path d="M983.17,1163.24c-14.13,16.08-14.27,16.19-20.05,15.62c0.71-0.77,1.11-1.56,1.74-1.84c6.56-2.86,11.02-7.8,14.41-13.95
                c2.02-3.66,4.45-5.32,8.05-1.34c2.7,2.99,5.66,1.72,7.65-0.91c4.89-6.48,9.77-13.01,14.13-19.85c4.53-7.12,8.42-14.63,13.78-21.67
                c-0.38,1.55-0.43,3.29-1.21,4.6c-7.27,12.27-14.21,24.76-22.19,36.56C994.18,1168.31,991.46,1168.2,983.17,1163.24z"/>
            <path d="M234.42,761.04c16.81,14.22,29.99,31.47,41.59,50.02c2.04,3.27,4.02,6.58,6.03,9.87c-0.4,0.31-0.8,0.62-1.2,0.93
                c-0.77-0.62-1.81-1.08-2.28-1.88c-12-20.47-26.59-38.97-42.54-56.46c-0.65-0.72-1.02-1.7-1.52-2.55L234.42,761.04z"/>
            <path d="M262.05,818.88c-4.95-6.15-9.9-12.3-14.84-18.46c0.31-0.26,0.63-0.53,0.94-0.79c5.22,5.91,10.44,11.82,15.66,17.73
                C263.22,817.87,262.64,818.38,262.05,818.88z"/>
            <path d="M234.5,760.98c0,0-0.08,0.06-0.08,0.06S234.5,760.98,234.5,760.98z"/>
            <path d="M939.18,1351.55c-1.27-0.92-4.56-2.1-5.64-4.35c-2.04-4.23-1.3-8.9,2.43-12.26c7.4-6.66,21.03-2.29,23.19,7.43
                c1,4.49-2.46,6.35-5.84,7.22C949.36,1350.62,945.17,1350.78,939.18,1351.55z M940.17,1347.9c4.56-0.65,8.1-0.8,11.39-1.78
                c1.34-0.4,3.21-3.63,3.06-3.79c-2.47-2.47-4.93-5.69-8.02-6.58c-2.58-0.74-6.52,0.63-8.8,2.42c-1.49,1.17-1.62,4.83-1.29,7.22
                C936.68,1346.56,939.32,1347.36,940.17,1347.9z"/>
            <path d="M935.51,1387.3c0.8,0.48,1.76,0.83,2.42,1.49c1.19,1.18,2.19,2.55,3.27,3.84c-1.7,1.05-3.33,2.83-5.12,3.02
                c-5.57,0.58-9.03-2.59-8.99-7.86c-1.66-0.45-3.59-0.59-5.06-1.48c-1.51-0.91-2.57-2.57-3.82-3.9c1.47-1.37,2.72-3.31,4.45-3.95
                c2.08-0.77,4.57-0.54,6.89-0.53C937,1377.94,938.73,1380.58,935.51,1387.3z M922.04,1381.26c4.99,3.99,8.98,3.75,11.9,0
                C929.64,1381.26,925.84,1381.26,922.04,1381.26z M931.95,1390.27c-0.05,0.59-0.09,1.19-0.14,1.78c1.45,0.12,2.9,0.25,4.35,0.37
                c0.05-0.49,0.1-0.98,0.15-1.46C934.87,1390.73,933.41,1390.5,931.95,1390.27z"/>
            <path d="M943.02,1311.97c-5.1,0.09-10.05-3.83-9.26-7.62c0.4-1.92,3.02-4.43,4.97-4.77c5.06-0.88,11.37,3.54,10.95,7.09
                C949.17,1310.82,945.86,1311.63,943.02,1311.97z M941.61,1309.67c1.88-1.66,2.99-2.64,4.11-3.62c-1.99-1.16-3.91-2.54-6.03-3.35
                c-0.57-0.22-1.79,1.27-2.71,1.97C938.37,1306.19,939.78,1307.7,941.61,1309.67z"/>
            <path d="M878.16,1355.86c0.9,1.04,3.78,2.87,3.87,4.82c0.17,3.67-3.26,5.47-6.55,5.11c-2.15-0.24-5.08-1.84-5.92-3.65
                C868.4,1359.65,872.79,1356.01,878.16,1355.86z M872.95,1360.46c0.14,0.58,0.28,1.16,0.42,1.74c1.43-0.33,2.86-0.65,4.3-0.98
                c-0.14-0.6-0.29-1.2-0.43-1.8C875.81,1359.77,874.38,1360.11,872.95,1360.46z"/>
            <path d="M616.43,246.13c-2.19-11.78-0.98-23.4,3.43-34.75C618.72,222.97,617.57,234.55,616.43,246.13z"/>
            <path d="M473.06,313.34c0.59,5.09,1.34,10.4,1.75,15.73c0.12,1.55-0.35,3.38-1.15,4.72c-5.69,9.58-11.53,19.08-17.37,28.57
                c-0.4,0.65-1.16,1.08-1.89,1.74c-1.78-1.43-3.4-2.74-5.1-4.1c1-3.59,2.24-5.94,6.12-1.91c5.35-8.7,10.74-17.23,15.81-25.94
                c0.76-1.3,0.29-3.54-0.09-5.24C469.82,320.88,470.37,316.88,473.06,313.34z"/>
            <path d="M450.16,368.6c-2.07,3.49-3.69,7.41-6.43,10.27c-1.24,1.3-5.17,1.41-7.05,0.44c-4.98-2.56-9.5-6.01-14.66-9.4
                c3.36-1.33,6.38-0.46,9.27,1.47c3.11,2.06,6.47,6.17,9.16,5.75c2.7-0.42,4.68-5.33,7-8.26c0.38-0.47,0.9-0.83,1.35-1.25
                C449.25,367.95,449.71,368.28,450.16,368.6z"/>
            <path d="M420.92,359.08c-1.42-1.79-2.84-3.58-4.26-5.37c0.5-0.39,1.01-0.79,1.51-1.18c1.4,1.8,2.8,3.61,4.2,5.41
                C421.89,358.32,421.4,358.7,420.92,359.08z"/>
            <path d="M1058.49,1266.23c0.25,6.21-2.21,10.38-4.96,14.65c-1.23,1.91-1.92,4.94-1.34,7.06c1.05,3.91,3.4,7.44,4.82,11.28
                c0.56,1.52,0.09,3.43,0.09,5.18c-6.92-0.52-7.13-2.26-8.11-8.06c-1.89-11.16,3.83-19.68,7.79-28.93
                C1056.93,1267.06,1057.5,1266.89,1058.49,1266.23z"/>
            <path d="M1042.93,1422.35c-1.54-11.03,5.76-19.68,8.92-29.58c2.54,3.68,0.79,7-0.5,10.18
                C1048.72,1409.43,1045.8,1415.78,1042.93,1422.35z"/>
            <path d="M1057.43,1207.65c-0.65,3.02-1.31,6.05-1.96,9.07l0.11-0.08c-0.37,0.37-0.74,0.75-1.12,1.12l0.11-0.1
                c-0.89-2.1-3.02-4.65-2.46-6.23c1.95-5.48,4.83-10.62,7.36-15.89c0.61,0.23,1.22,0.47,1.83,0.71c-1.27,3.82-2.53,7.65-3.8,11.47
                C1057.52,1207.71,1057.43,1207.65,1057.43,1207.65z"/>
            <path d="M1044.95,1184.16c7,7.26,6.5,14.28,0,17.53C1044.95,1195.88,1044.95,1190.02,1044.95,1184.16z"/>
            <path d="M1039.09,1318.59c-1.63,4.64-3.41,8.53-4.31,12.61c-0.84,3.84-0.76,7.87-1.54,11.76
                C1028.45,1336.06,1031.56,1323.34,1039.09,1318.59z"/>
            <path d="M1053.25,1346.11c-1.72,5.59-3.45,11.18-5.38,17.45c-3.27-4.14-0.6-13.61,3.99-17.93
                C1052.32,1345.8,1052.78,1345.96,1053.25,1346.11z"/>
            <path d="M1044.26,1215.16c-0.48,4.56-0.96,9.03-1.43,13.51C1038.31,1225.81,1039.01,1218.7,1044.26,1215.16z"/>
            <path d="M1062.74,1136.9c0.17,4.36-0.29,8.3-7.19,9.19c2.3-3.92,4.08-6.96,5.87-9.99
                C1061.86,1136.37,1062.3,1136.63,1062.74,1136.9z"/>
            <path d="M1051.84,1238.41c-0.59-4.56,1.45-8.16,4.34-11.44c0.43,0.15,0.87,0.3,1.3,0.45c-1.3,3.82-2.59,7.65-3.89,11.47
                C1053.02,1238.73,1052.43,1238.57,1051.84,1238.41z"/>
            <path d="M1051.09,1370.03c-3.4-4.16-2.69-8.43,2.11-12.77C1052.44,1361.86,1051.82,1365.65,1051.09,1370.03z"/>
            <path d="M1052.8,1338.57c-2.03,3.69-4.07,7.39-6.1,11.08c-0.68-0.37-1.36-0.75-2.05-1.12c2.04-3.72,4.07-7.44,6.11-11.16
                C1051.44,1337.77,1052.12,1338.17,1052.8,1338.57z"/>
            <path d="M1063.07,1158.75c-1.63,1.89-3.26,3.79-4.89,5.68c-0.5-0.43-1.01-0.86-1.51-1.28c1.62-1.89,3.24-3.78,4.86-5.67
                C1062.04,1157.9,1062.55,1158.33,1063.07,1158.75z"/>
            <path d="M1064.06,1148c-1.72,1.78-3.44,3.56-5.16,5.33c-0.59-0.59-1.19-1.18-1.78-1.77c1.83-1.63,3.66-3.25,5.49-4.88
                C1063.09,1147.12,1063.58,1147.56,1064.06,1148z"/>
            <path d="M1055.47,1216.72c2.55,2.11,2.38,2.65-2.49,5.94c0.69-2.16,1.14-3.58,1.6-5l-0.11,0.1c0.37-0.37,0.74-0.75,1.12-1.12
                L1055.47,1216.72z"/>
            <path d="M1056.05,1251.27c-0.18,1.52-0.36,3.05-0.53,4.57c-0.53-0.03-1.06-0.06-1.59-0.09c0.11-1.57,0.22-3.15,0.33-4.72
                C1054.85,1251.11,1055.45,1251.19,1056.05,1251.27z"/>
            <path d="M1056.14,1372.06c0,1.59,0,3.17,0,4.76c-0.36,0.01-0.71,0.02-1.07,0.03c0-1.58,0-3.16,0-4.74
                C1055.43,1372.09,1055.78,1372.08,1056.14,1372.06z"/>
            <path d="M1045.92,1205.19c-1.29,0.94-2.58,1.88-3.87,2.82c-0.33-0.43-0.67-0.87-1-1.3c1.26-0.99,2.53-1.98,3.79-2.97
                C1045.2,1204.23,1045.56,1204.71,1045.92,1205.19z"/>
            <path d="M819.24,251.11c-1.37-7.82-2.75-15.64-4.12-23.47c0.42-0.08,0.84-0.15,1.26-0.23c1.55,7.76,3.1,15.51,4.65,23.27
                C820.43,250.83,819.84,250.97,819.24,251.11z"/>
            <path d="M454.15,245.13c0.87,9.21,3.81,13.94,9.32,14.31c-1.2-2.27-2.34-4.15-3.21-6.15c-0.83-1.91-1.38-3.93-1.48-6.1
                c4.64,5.42,9.29,10.84,13.94,16.26c-2.99,2.37-11.25,1.07-14.96-2.45C451.65,255.2,450.37,250.35,454.15,245.13z M466.2,261.41
                l-0.13-0.08l0.21,0.07l0.15,0.17C466.43,261.58,466.2,261.41,466.2,261.41z"/>
            <path d="M468.34,234.7c-3.07-0.5-6.18-0.83-9.2-1.54c-3.39-0.8-6.7-1.95-10.96-3.22c0.87-1.36,2.23-3.48,3.96-6.16
                c2.6,2.39,4.63,4.26,7.63,7.02c-0.38-3.65-0.6-5.83-0.93-9.06c3.69,4.45,6.71,8.09,9.72,11.73
                C468.48,233.88,468.41,234.29,468.34,234.7z"/>
            <path d="M553.94,336.89c0-16.7,0-33.18,0-49.66c0.51-0.01,1.02-0.01,1.53-0.02c0,16.45,0,32.9-0.03,49.35
                C555.44,336.61,555.01,336.66,553.94,336.89z"/>
            <path d="M402.5,207.41c2.91,2.44,5.81,4.87,8.72,7.31c0.44-0.41,0.87-0.82,1.31-1.24c-1.07-2.94-2.15-5.88-3.22-8.82
                c0.3-0.15,0.6-0.31,0.91-0.46c2.92,3.91,5.85,7.83,8.97,12.01c-4.95,2.76-9.15,1.2-12.72-1.5c-2.14-1.62-3.37-4.44-5.02-6.73
                C401.81,207.79,402.15,207.6,402.5,207.41z"/>
            <path d="M1065.27,1436.24c-2.32,0.76-4.71,1.36-6.92,2.36c-1.39,0.63-2.5,1.87-3.74,2.83c1.16,1.04,2.15,2.64,3.5,3.04
                c6.51,1.92,13.12,3.52,19.69,5.23c1.8,0.47,3.6,0.92,5.39,1.38c-0.13,0.58-0.26,1.16-0.39,1.74c-3.28-0.61-6.61-1.03-9.84-1.86
                c-5.61-1.45-11.25-2.89-16.68-4.87c-1.88-0.69-3.19-2.95-4.76-4.49c1.7-1.61,3.16-3.7,5.17-4.71c2.51-1.26,5.47-1.63,8.23-2.39
                C1065.03,1435.08,1065.15,1435.66,1065.27,1436.24z"/>
            <path d="M575.03,342.57c1.42-2.04,2.85-4.09,4.27-6.13c0.43,0.3,0.86,0.59,1.3,0.89c-1.4,2.08-2.81,4.16-4.21,6.24
                C575.93,343.24,575.48,342.91,575.03,342.57z"/>
            <path d="M521.07,312.42c-0.41-1.2-0.82-2.41-1.23-3.61c0.27-0.24,0.53-0.48,0.8-0.72c2.1,1.73,4.2,3.46,6.3,5.18
                c-0.11,0.54-0.21,1.08-0.32,1.62c-1.93,0.24-3.86,0.68-5.79,0.67c-3.22-0.02-6.44-0.22-9.63-0.61c-0.92-0.11-2.48-1.14-2.45-1.69
                c0.09-2.07,0.71-4.11,1.12-6.16c0.5,0.08,1,0.16,1.5,0.24c0.17,1.15,0.33,2.3,0.5,3.46c3.1,0.51,6.18,1.02,9.26,1.53
                C521.13,312.34,521.07,312.42,521.07,312.42z"/>
            <path d="M520.57,296.22c2,3.34,4,6.67,6.07,10.11c-9.79,1.57-15.54-3.43-13.2-9.75c2.35,1.74,1.93,7.16,6.9,5.24
                c-0.26-1.82-0.53-3.67-0.8-5.51C519.88,296.28,520.23,296.25,520.57,296.22z"/>
            <path onClick={() => props.onFill(272)} fill={props.fillColors[272]} d="M388.16,864.89c-6.73-10.17-13.47-20.34-20.2-30.51c-0.39,0.04-0.78,0.09-1.17,0.13
                c-0.37,2.61-0.73,5.22-1.29,9.12c-2.74-3.19-5.05-5.62-7.07-8.28c-6.12-8.06-12.24-16.13-18.09-24.38
                c-1.78-2.51-3.25-5.5-3.91-8.49c-3.76-16.95-8.45-33.52-17.11-48.76c-1.44-2.54-2.51-5.61-2.71-8.5c-1-14.7-5.75-28.37-11.4-41.7
                c-3.11-7.34-1.69-14.23-0.5-21.45c0.57-3.43,0.33-7.06,0.02-10.56c-0.41-4.56-1.98-9.11-1.77-13.6
                c0.6-12.66-3.63-23.85-9.13-34.82c-2.3-4.6-4.35-9.32-6.51-13.98l-0.08,0.07c5.92,9.54,12.29,18.85,17.61,28.72
                c3.2,5.94,4.82,12.74,7.04,19.19c0.47,1.37,0.93,3.08,0.52,4.35c-2.41,7.48,0.18,14.44,1.62,21.59c0.59,2.96,0.6,6.23-0.01,9.19
                c-1.56,7.63,0.5,14.02,5.16,20.03c3.01,3.89,6.55,2.27,10.01,1.67c3.58-0.62,3.32-3.61,3.4-6.29c0.04-1.14,0.12-2.28,1.08-3.9
                c0.89,1.41,1.82,2.79,2.66,4.23c4.96,8.5,10.19,16.66,17.86,23.19c7.69,6.55,11.78,15.33,14.1,25.25
                c2.41,10.33,6.01,20.38,9,30.58c0.48,1.65,1.16,4.63,0.59,4.95c-1.72,0.97-4.57,1.91-5.96,1.1c-4.05-2.36-8.56-4.91-11.12-8.61
                c-3.62-5.25-5.62-11.61-8.38-17.46c-1.27-2.69-2.71-5.3-4.07-7.95c-0.57,0.21-1.15,0.43-1.72,0.64c0.4,1.94,0.61,3.95,1.24,5.81
                c3.94,11.48,8.11,22.89,11.87,34.43c0.82,2.52,0.77,5.57,0.29,8.23c-1.71,9.52,2.52,20.38,10.39,25.55
                c11.25,7.4,14.79,19.15,17.68,31.27L388.16,864.89z"/>
            <path onClick={() => props.onFill(273)} fill={props.fillColors[273]} d="M347.34,520.1c9.32,4.38,18.7,8.62,27.91,13.2c3.26,1.62,4.64,2.15,5.59-2.38
                c1.27-6.06-0.16-11.13-3.16-16.13c-0.89-1.49-1.71-3.02-2.38-4.2c6.42,2.78,12.95,5.19,19.05,8.4c3.72,1.95,7.02,4.9,10.09,7.82
                c6.47,6.15,12.66,12.58,18.98,18.89c5.11,5.1,10.66,9.29,18.09,10.66c2.04,0.37,4.06,2.29,5.54,3.99
                c6.84,7.89,13.52,15.93,20.19,23.97c1.68,2.02,3.14,4.22,4.02,7.34c-1.57-0.9-3.2-1.72-4.72-2.7c-7.39-4.8-14.61-9.88-22.17-14.4
                c-5.53-3.3-10.34-0.4-9.32,6.01c0.73,4.65,3.49,9.14,6,13.3c2.9,4.82,6.59,9.15,9.77,13.8c0.96,1.4,1.36,3.19,1.06,5.46
                c-10.15-10.21-20.31-20.43-31.52-31.71c-2.57,6.42-4.49,11.21-6.79,16.95c-2.22-2.16-4.6-3.87-6.21-6.14
                c-5.87-8.28-11.81-16.54-17.12-25.17c-9.91-16.11-23.82-28.53-37.19-41.47c-1.89-1.83-3.75-3.7-5.63-5.54L347.34,520.1z"/>
            <path onClick={() => props.onFill(274)} fill={props.fillColors[274]} d="M288.55,528.66c2.6-5.29,5.21-10.59,7.81-15.88c0.57,0.28,1.14,0.56,1.71,0.84
                c-2.64,5.27-5.29,10.55-7.93,15.82C289.61,529.17,289.08,528.92,288.55,528.66z"/>
            <path onClick={() => props.onFill(275)} fill={props.fillColors[275]} d="M361.06,522.23c0,0,0.1-0.04,0.1-0.03C361.15,522.21,361.06,522.23,361.06,522.23z"/>
            <path onClick={() => props.onFill(276)} fill={props.fillColors[276]} d="M287.33,609.11c0,0-0.08,0.07-0.08,0.07C287.25,609.18,287.33,609.11,287.33,609.11z"/>
            <path onClick={() => props.onFill(277)} fill={props.fillColors[277]} d="M347.43,520.05c0,0-0.09,0.06-0.09,0.06S347.43,520.05,347.43,520.05z"/>
            <path onClick={() => props.onFill(278)} fill={props.fillColors[278]} d="M388.09,864.94c0,0,0.07-0.05,0.07-0.05C388.16,864.89,388.09,864.94,388.09,864.94z"/>
            <path onClick={() => props.onFill(279)} fill={props.fillColors[279]} d="M209.34,328.39c15.33,9.83,31.61,19.32,34.72,39.31c1.11,7.18,4.63,10.66,9.62,14.31
                c12.7,9.3,24.13,19.74,32.59,33.34c5.02,8.06,13.27,12.76,22.44,14.66c7.83,1.61,13.39,5.49,17.21,12.27
                c1.47,2.61,3.38,5.11,4.2,7.92c2.97,10.17,10.43,16.34,18.66,21.89c7.59,5.12,15.16,10.28,22.96,15.05
                c3.05,1.86,6.66,2.94,10.15,3.88c1.35,0.36,3.68-0.19,4.44-1.18c0.66-0.86,0.11-3.15-0.59-4.44c-2.48-4.52-5.35-8.82-7.85-13.33
                c-0.88-1.59-1.13-3.53-1.2-5.52c19.46,17.95,37,37.3,51.26,59.38c-0.33,0.29-0.67,0.58-1,0.87
                c-10.74-6.87-21.47-13.77-32.25-20.59c-1.96-1.24-4.01-2.49-6.2-3.16c-7.76-2.36-15.59-4.52-23.43-6.61
                c-1.56-0.41-3.71-0.81-4.87-0.08c-4.96,3.11-7.95-0.25-11.43-2.77c-16.9-12.25-28.9-29.05-40.47-45.8
                c-5.92-8.58-11.92-16.2-20.68-21.63c-1.41-0.87-3.09-1.3-4.65-1.93c-0.29,0.34-0.58,0.68-0.86,1.02
                c3.29,5.19,6.59,10.39,9.88,15.58c-0.3,0.25-0.6,0.5-0.9,0.75c-0.86-0.8-1.81-1.52-2.57-2.41
                c-15.25-17.82-29.87-36.22-47.58-51.81c-5.57-4.9-9.56-11.09-11.18-18.53c-0.7-3.21-0.91-6.19,2.07-8.63
                c3.04-2.5,1.5-5.15-0.45-7.37c-7.26-8.22-14.63-16.35-21.96-24.51L209.34,328.39z"/>
            <path onClick={() => props.onFill(280)} fill={props.fillColors[280]} d="M459.18,558.02c-5.24-3.38-10.5-6.5-15.38-10.13c-1.16-0.87-1.86-3.7-1.39-5.2
                c2.23-7.22-1.4-12.04-5.85-16.87c-1.53-1.66-2.23-4.08-3.36-6.12c-2.89-5.2-5.83-10.37-8.72-15.57c-0.84-1.51-1.57-3.07-2.45-4.8
                c5.91-0.83,15.08,5.59,16.88,10.43c4.32,11.59,8.03,23.64,19.72,30.6c3.28,1.95,4.59,4.11,0.81,7.77
                C457.89,549.62,459.18,554.03,459.18,558.02z"/>
            <path onClick={() => props.onFill(281)} fill={props.fillColors[281]} d="M431.16,526.17c0,0-0.08,0.06-0.08,0.06C431.08,526.23,431.16,526.17,431.16,526.17z"/>
            <path onClick={() => props.onFill(282)} fill={props.fillColors[282]} d="M209.42,328.32c0,0-0.08,0.07-0.08,0.07C209.34,328.39,209.42,328.32,209.42,328.32z"/>
            <path onClick={() => props.onFill(283)} fill={props.fillColors[283]} d="M416.62,831.68c-7.33-0.27-9.25-1.48-10.88-7.51c-3.34-12.34-6.89-24.65-9.59-37.13
                c-1.89-8.72-2.42-17.73-3.73-26.59c-1.05-7.12-5.33-14.32-10.51-17.51c-0.49,2.68-0.93,5.09-1.57,8.61
                c-6.44-9.44-10.34-18.67-9.2-29.34c0.14-1.3,3.56-3.47,5.03-3.17c4.14,0.86,8.2,2.49,12,4.4c3.34,1.67,5.22,1.13,5.51-2.53
                c0.25-3.11,0.19-6.32-0.3-9.4c-1.43-9-3.14-17.96-4.02-27.37c0.72,0.89,1.64,1.69,2.14,2.69c4.62,9.29,10.23,17.98,10.29,29.2
                c0.03,6.04,3.25,12.09,5.11,18.1c0.72,2.34,1.67,4.6,3.07,8.38c-3.08-1.38-4.92-2.14-6.71-3.01c-4.76-2.33-9.38-5.03-14.29-6.98
                c-3.94-1.57-5.66,0.04-4.07,4.04c2.44,6.13,5.59,12,8.74,17.82c1.41,2.61,3.52,4.84,5.31,7.23c2.79,3.73,4.13,7.41,2.91,12.47
                c-0.91,3.78-0.03,8.26,0.98,12.19c3.79,14.76,6.86,29.76,13.64,43.59C416.67,830.27,416.56,830.82,416.62,831.68z"/>
            <path onClick={() => props.onFill(284)} fill={props.fillColors[284]} d="M424.4,735.18c-2.84-4.87-5.66-8.4-7.11-12.43c-5.2-14.4-9.97-28.96-14.82-43.48
                c-1.89-5.65-3.59-11.36-4.54-17.48c1.14,2.07,2.28,4.13,3.42,6.21c6.47,11.83,13.06,23.59,19.33,35.52
                c2.77,5.28,4.8,10.95,7.31,16.38C430.5,725.31,427.67,729.35,424.4,735.18z"/>
            <path onClick={() => props.onFill(285)} fill={props.fillColors[285]} d="M90.83,146.68c-6.23-12.53-12.45-25.06-18.68-37.59c0.15-0.32,0.3-0.65,0.45-0.97
                c1.53,0.78,3.59,1.18,4.51,2.42c7.9,10.65,13.59,22.27,14.89,35.67C91.61,146.37,91.22,146.53,90.83,146.68z"/>
            <path onClick={() => props.onFill(286)} fill={props.fillColors[286]} d="M454.78,1245.87c-1.31,8.39,1.49,17.21-3.85,24.37c-0.68,0.02-1.35,0.05-2.03,0.07
                c-1.13-8.74-2.81-17.45-3.25-26.22c-0.55-10.99-6.83-17.41-15.97-15.24c-3.56,0.84-4.77,2.71-3.6,6.13
                c1.13,3.3,2.56,6.53,3.4,9.91c1.17,4.72,1.92,9.55,2.85,14.33c-0.56,0.13-1.11,0.26-1.67,0.39c-0.75-2.41-1.44-4.85-2.25-7.24
                c-3.42-10.05-9.04-13.98-19.59-12.88c-4.32,0.45-7.48-1.08-9.27-4.36c-3.23-5.92-5.75-12.24-8.73-18.75
                c2.47-0.39,4.18-0.66,7.76-1.22c-5.49-5.13-10.02-9.37-14.56-13.62c0.18-0.37,0.36-0.73,0.54-1.1c10.7,2.62,21.52,7.68,32.07,7.17
                c10.97-0.52,21.2,5.17,31.98,2.91c0.29-0.63,0.58-1.26,0.87-1.89c-8.24-6.64-16.49-13.29-25.48-20.54
                c2.02,0.09,3.28-0.08,4.4,0.24c4.96,1.4,9.97,2.7,14.81,4.47c13.91,5.1,28.2,7.37,43.03,7.08c4.02-0.08,8.07,1.74,12.11,2.62
                c2.92,0.64,5.84,1.46,8.8,1.69c3.59,0.28,7.3,0.1,4.76-6.16c4.95,1.49,8.97,3.12,13.14,3.83c3.75,0.64,7.67,0.21,11.51,0.37
                c1.28,0.05,2.75,0.1,3.8,0.72c12.3,7.26,24.66,14.43,36.72,22.09c4.05,2.57,6.76,6.74,6.35,12.48c-1.39-1.71-2.74-3.46-4.18-5.12
                c-6.84-7.92-15.41-14.2-25.45-15.69c-10.1-1.5-20.79-0.4-28.99,7.82c-5.07,5.08-12.88,5.01-18.39,0.28
                c-2.64-2.27-5.24-4.7-8.22-6.43c-4.06-2.36-7.1-0.73-8.02,3.88c-0.42,2.12-0.81,4.29-0.8,6.44c0.02,9.48-6.27,15.43-11.96,21.7
                c-0.69,0.76-2.06,1.25-3.12,1.26c-6.07,0.06-12.45,1.09-16.95-4.69C456.91,1246.44,455.94,1246.35,454.78,1245.87z"/>
            <polygon onClick={() => props.onFill(287)} fill={props.fillColors[287]} points="314.47,1213.72 314.25,1213.67 314.38,1213.72 		"/>
            <path onClick={() => props.onFill(288)} fill={props.fillColors[288]} d="M345.29,1166.81c4.98,2.5,9.95,5,14.93,7.49c-5.77-0.97-11.03-2.92-14.87-7.55
                C345.34,1166.75,345.29,1166.81,345.29,1166.81z"/>
            <path onClick={() => props.onFill(289)} fill={props.fillColors[289]} d="M344.23,1165.55l-0.08,0.09L344.23,1165.55z"/>
            <path onClick={() => props.onFill(290)} fill={props.fillColors[290]} d="M344.15,1165.64c0.38,0.39,0.76,0.78,1.14,1.17c0,0,0.06-0.06,0.06-0.06c-0.37-0.4-0.74-0.8-1.11-1.2
                C344.23,1165.55,344.15,1165.64,344.15,1165.64z"/>
            <path onClick={() => props.onFill(291)} fill={props.fillColors[291]} d="M346.36,1078.98c-5.67,4.29-11.65,8.25-16.95,12.96c-6.85,6.11-13.17,12.83-19.84,19.41
                c0.69,0.56,0.24,0.37,0.24,0.17c0.14-20.03,17.13-36.28,35.64-34.14C345.75,1077.92,346.05,1078.45,346.36,1078.98z"/>
            <path onClick={() => props.onFill(292)} fill={props.fillColors[292]} d="M617.11,1318.15c2.48,0,4.95,0,8.23,0c-2.95,4.99-5.51,4.18-8.21,1.81
                C617.13,1319.36,617.12,1318.76,617.11,1318.15z"/>
            <path onClick={() => props.onFill(293)} fill={props.fillColors[293]} d="M717.84,1417.49c0.36,0.71,0.71,1.43,1.07,2.14C718.55,1418.91,718.2,1418.2,717.84,1417.49z"/>
            <path onClick={() => props.onFill(294)} fill={props.fillColors[294]} d="M718.82,1419.51c0.37,0.37,0.74,0.74,1.11,1.11C719.56,1420.25,719.19,1419.88,718.82,1419.51z"/>
            <path onClick={() => props.onFill(295)} fill={props.fillColors[295]} d="M1071.76,1375.54c3.71-1.76,7.62-3.56,11.48,1.2c1.07,1.32,3.44,1.69,5.29,2.22
                c1.73,0.49,3.82,0.19,5.3,1.03c1.36,0.77,2.14,2.59,3.17,3.95c-1.37,0.72-2.74,2.04-4.12,2.05c-5.47,0.05-10.96-0.18-16.42-0.62
                c-1.01-0.08-2.31-1.44-2.75-2.5C1072.86,1380.79,1072.51,1378.51,1071.76,1375.54z"/>
            <path onClick={() => props.onFill(296)} fill={props.fillColors[296]} d="M1082.48,1361.23c1.54,0,3.07,0,4.61,0c0.03,0.51,0.06,1.02,0.09,1.53c-1.57,0.12-3.13,0.24-4.7,0.37
                C1082.48,1362.49,1082.48,1361.86,1082.48,1361.23z"/>
            <path onClick={() => props.onFill(297)} fill={props.fillColors[297]} d="M940.17,1347.9c-0.85-0.54-3.49-1.35-3.65-2.5c-0.33-2.39-0.2-6.05,1.29-7.22
                c2.28-1.79,6.22-3.16,8.8-2.42c3.08,0.88,5.55,4.1,8.02,6.58c0.16,0.16-1.71,3.39-3.06,3.79
                C948.27,1347.1,944.73,1347.26,940.17,1347.9z"/>
            <path onClick={() => props.onFill(298)} fill={props.fillColors[298]} d="M922.04,1381.26c3.8,0,7.6,0,11.9,0C931.02,1385.01,927.04,1385.25,922.04,1381.26z"/>
            <path onClick={() => props.onFill(299)} fill={props.fillColors[299]} d="M931.95,1390.27c1.46,0.23,2.91,0.46,4.37,0.69c-0.05,0.49-0.1,0.98-0.15,1.46
                c-1.45-0.12-2.9-0.25-4.35-0.37C931.86,1391.46,931.91,1390.86,931.95,1390.27z"/>
            <path onClick={() => props.onFill(300)} fill={props.fillColors[300]} d="M941.61,1309.67c-1.83-1.97-3.23-3.49-4.64-5c0.92-0.7,2.14-2.19,2.71-1.97c2.13,0.82,4.04,2.19,6.03,3.35
                C944.6,1307.03,943.49,1308.02,941.61,1309.67z"/>
            <path onClick={() => props.onFill(301)} fill={props.fillColors[301]} d="M872.95,1360.46c1.43-0.35,2.85-0.69,4.28-1.04c0.14,0.6,0.29,1.2,0.43,1.8c-1.43,0.33-2.86,0.65-4.3,0.98
                C873.23,1361.62,873.09,1361.04,872.95,1360.46z"/>
            <path onClick={() => props.onFill(302)} fill={props.fillColors[302]} d="M1057.43,1207.65c0,0,0.09,0.06,0.09,0.06C1057.52,1207.71,1057.43,1207.65,1057.43,1207.65z"/>
            <path onClick={() => props.onFill(303)} fill={props.fillColors[303]} d="M1055.58,1216.64c-0.37,0.37-0.74,0.75-1.12,1.12C1054.84,1217.39,1055.21,1217.01,1055.58,1216.64z"/>
            <polygon onClick={() => props.onFill(304)} fill={props.fillColors[304]} points="466.2,261.41 466.43,261.58 466.28,261.41 		"/>
            <polygon onClick={() => props.onFill(305)} fill={props.fillColors[305]} points="466.28,261.41 466.06,261.34 466.2,261.41 		"/>
            <path onClick={() => props.onFill(306)} fill={props.fillColors[306]} d="M521.07,312.42c0,0,0.06-0.08,0.06-0.08C521.13,312.34,521.07,312.42,521.07,312.42z"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1161.91,778.01c0-19.32,0-38.64,0-57.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1161.91,714.06c0-237.5,0-475,0-713c-2.16,0-4.08,0-5.99,0c-307.68,0-615.36,0-923.04,0.02c-1.66,0-3.33,0.31-4.99,0.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1.63,1117.27c-0.17,1.67-0.48,3.33-0.48,5C1.12,1245.85,1.13,1369.43,1.13,1493c0,1.92,0,3.83,0,6c230.76,0,461.02,0,691.28,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M703.89,1499c152.67,0,305.35,0,458.02,0c0-238.5-0.01-477,0.09-715.49c0-4.15-1.58-5.89-4.92-8.22
                c-5.74-4-10.52-9.39-15.59-14.32c-0.58-0.57-0.39-1.94-0.56-2.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M719.88,373.8c-6.6-0.85-11.95,1.87-14.47,7.5c-3.57,7.95-7.74,15.46-9.26,24.43c-2.28,13.49-3.53,26.83-2.88,40.52
                c0.65,13.96,0.14,27.97,0.14,41.96c0,3,0,6,0,8.99c0,10.99,0,21.98,0,32.98c0,26.15,0.68,52.32-0.22,78.44
                c-0.69,19.73-1.19,39.33,1.14,58.98c0.8,6.78-0.36,13.47-2.83,20c-4.19,11.07-3.81,22.88-4.5,34.45
                c-0.79,13.31-1.23,26.64-1.47,39.97c-0.08,4.49,0.91,8.99,1.41,13.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M685.66,817.98c-0.08,1.33-0.17,2.66-0.25,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M684.41,829.97c-1.83,5.5-3.66,10.99-5.49,16.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M109.01,105.48c-0.33,0.83-1.13,1.81-0.93,2.48c3.03,9.82,5.26,20.03,12.46,27.97c1.43,1.58,3.37,2.7,5.16,4.09
                c2-3.82,1.73-8.4-0.73-12.03c-5.24-7.72-11.23-15-15.75-23.12c-3.09-5.55-4.24-12.2-6.18-18.38c-0.68-2.16-1.15-4.38-1.89-7.26
                c1.17-0.73,2.66-2.05,4.39-2.67c7.15-2.58,6.61-10.22,2.96-14.53c-0.91-1.08-1.81-2.43-3.01-2.94c-6.32-2.65-8.87-8.28-10.31-14.1
                c-2.03-8.18-7.1-14.29-12.2-20.4c-5.89-7.05-12.13-13.83-18.51-20.45c-1.54-1.6-4.23-2.93-6.4-2.95
                C39.75,0.96,21.44,1.05,3.12,1.08c-0.5,0-1,0.32-2,0.66c0,5.81-0.1,11.81,0.02,17.81c0.26,12.33,0.7,24.65,0.92,36.98
                c0.04,2.33-0.9,4.66-0.9,6.99C1.12,185.43,1.12,307.34,1.15,429.26c0,1.5,0.31,3,0.48,4.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M805.79,414.77c-0.02,11.13-2.27,21.87-5.37,32.51c-0.66,2.26-0.39,5.2,0.5,7.41c2.13,5.31,4.47,10.62,7.45,15.49
                c6.83,11.16,7.91,22.76,3.91,35.02c-0.28,0.85-0.92,1.66-0.95,2.5c-0.72,19.65-2.63,39.37-1.63,58.94
                c0.75,14.76,4.97,29.39,8.15,43.97c4.1,18.83,10.5,37.16,12.44,56.47c0.2,2.01,0.5,4.04,1.01,5.99c1.94,7.38,2.58,6.78,10.39,8.79
                c6.03,1.56,11.6,5.39,17.02,8.77c12.27,7.66,22.45,17.74,31.44,28.97c4.1,5.12,5.19,11.5,6.16,17.92
                c0.86,5.73,2.09,11.51,4.03,16.96c2.09,5.87,5.2,11.37,7.86,17.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M838.75,409.77c-1-0.83-2.16-1.53-2.97-2.52c-4.05-4.91-9.18-7.47-15.49-8.58c-8.69-1.53-11.93-7.47-8.43-15.35
                c3.95-8.89,3.45-17.6-0.7-25.95c-2.84-5.72-2.56-11.17-0.28-16.51c3.65-8.53,4.6-17.51,5.34-26.53
                c0.92-11.3,1.39-22.65,2.07-33.97c0.16-2.6-0.01-4.54-2.91-6.16c-3.4-1.9-5.27,0.23-7.01,1.75c-7.7,6.67-10.23,16.16-13.03,25.41
                c-3.61,11.96-6.81,24.04-10.69,35.91c-0.78,2.4-3.47,4.82-5.88,5.92c-9.02,4.1-11.6,12.56-14.3,20.66
                c-3.49,10.49-5.7,21.42-9.23,31.9c-3.26,9.68-2.29,19.73-3.94,29.51c-0.63,3.73-0.84,8.38-3.14,10.81
                c-7.1,7.5-6.69,15.99-5.9,25.19c1.39,16.12,2.35,32.29,3.03,48.46c0.55,13.14,0.86,26.33,0.41,39.46
                c-0.28,8.19-2.53,16.3-3.24,24.5c-0.88,10.13-1.34,20.31-1.54,30.47c-0.36,18.32-0.34,36.64-0.59,54.96
                c-0.05,3.83-0.96,7.67-0.87,11.49c0.1,4.52-1.63,8.42-2.7,12.59c-1.08,4.22,0.07,9.59,1.79,13.82c3.77,9.26,3.94,18.95,4.72,28.56
                c0.76,9.46,0.71,18.98,1.15,28.47c0.06,1.19,0.94,2.33,1.44,3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M84.04,450.74c2.18,13.59,5.39,27.09,4.87,40.97c-0.11,3.03-1.03,7.7-3.02,8.69c-6.43,3.18-6.25,8.51-5.71,13.79
                c0.64,6.19-1.81,10.04-7.14,12.47c-0.89,0.41-2.01,0.61-2.98,0.49c-6.13-0.77-8.33,3.15-9.45,8.05c-1.05,4.61-1.48,9.37-2.6,13.97
                c-1.49,6.11-5.35,7.94-10.92,5.49c-1.56-0.69-3.01-1.64-4.5-2.48c-8.6-4.83-14.67-4.03-18.25,4.6
                c-5.78,13.96-14.09,26.38-21.29,39.47c-1.75,3.19-1.77,7.56-1.84,11.4c-0.22,11.65-0.05,23.32-0.1,34.97
                c-0.01,3.38,0.96,5.38,4.44,7.14c28.1,14.16,54.71,30.7,78.98,50.83c2.72,2.26,5.3,4.69,8.01,6.97c2.37,2,4.83,3.89,7.89,6.33
                c0.35-3.13,0.62-5.51,0.87-7.79c3.21-0.53,6.28-0.79,9.19-1.59c3.25-0.89,4.16-2.98,3.11-6.46c-1.26-4.2-1.95-8.61-2.54-12.97
                c-0.41-3.04,1.35-4.49,4.43-4.52c2.33-0.02,4.77,0.08,6.97-0.55c5.25-1.51,9.25-4.65,8.47-11.41c-0.98-8.52-2.44-16.98-3.38-25.5
                c-0.38-3.45,0.09-6.99,0.19-10.81c2.41,0.42,3.6,0.52,4.73,0.84c3.62,1.03,4.78,0.27,6.29-3.91c4.49-12.4,3.37-24.97,3.18-37.58
                c-0.12-7.81,1.87-9.99,9.51-9.99c6.87,0,7.18-0.49,6.02-7.5c-1.73-10.47-3.31-20.85,3.33-30.57c1.61-2.35,1.41-5.92,2.16-8.89
                c0.75-3.02,1.65-6,2.48-9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1161.41,371.8c-8.92,1.73-16.96-0.76-23.9-6.09c-6.24-4.8-12.44-3.69-18.51-0.8c-7.86,3.74-15.86,7.54-22.86,12.61
                c-5.2,3.76-10.7,3.19-16.15,4.25c-0.96,0.19-2.34-0.4-2.94,0.09c-9.66,7.84-21.81,13.33-23.2,28.4
                c-1.18,12.9-4.32,25.61-5.63,38.51c-0.74,7.22,0.44,14.64,0.82,21.96c0.36,6.98-0.48,13.45-5.45,19.04
                c-5.49,6.17-6.23,14.37-7.34,21.96c-1.49,10.2-1.51,20.62-2.21,30.95c-0.62,9.16-1.01,18.36-2.19,27.45
                c-0.33,2.51-2.65,5.45-4.89,6.87c-5.45,3.46-7.1,5.88-5.88,11.64c0.36,1.7,1,3.33,1.47,5c3.05,10.73,0.65,13.85-10.99,13.97
                c-9.99,0.1-19.99-0.22-29.97,0.1c-6.84,0.23-13.64,1.51-20.48,1.86c-11.22,0.57-14.61-0.8-14.91-11.93
                c-0.32-12.15,0.24-24.33-0.26-36.46c-0.16-3.88-2.24-7.78-4-11.41c-0.38-0.79-3.91-1.19-4.66-0.44c-2.09,2.07-4.25,4.63-5.02,7.38
                c-6.21,22.09-11.89,44.33-18.23,66.38c-1.99,6.9-5.93,13.23-8.28,20.05c-4.56,13.25-9.35,26.65-4.49,40.91
                c1.26,3.69,2.78,7.94,5.56,10.34c7.35,6.36,5.04,14.41,5.39,22.16c0.47,10.5,1.6,20.98,1.85,31.48
                c0.18,7.85,1.85,16.31-5.39,22.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M877.46,401.28c-6.24-6.16-12.93-11.95-18.6-18.6c-5.2-6.1-4.68-13.69-2.07-20.85c4.51-12.4,4.36-24.93,1.38-37.48
                c-3.75-15.82-4.47-31.87-3.74-47.97c0.17-3.72,2.58-7.45,4.37-10.97c1.51-2.96,2.27-5.59-0.58-8.01
                c-3.11-2.64-5.82-1.74-8.51,0.95c-2.67,2.66-5.83,4.84-8.41,7.57c-5.53,5.84-7.85,12.78-7.11,20.95
                c0.96,10.63,0.91,21.37,2.23,31.95c1.02,8.1,2.98,16.18,5.46,23.96c3.25,10.2,4.18,20.53,3.21,31c-0.33,3.59-3.19,6.92-3.69,10.53
                c-0.87,6.42-0.86,12.97-1.13,19.47c-0.12,2.99-0.02,5.99-0.02,8.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M642.46,402.77c-6.34,14.71-9.56,30.18-12.13,45.94c-3.88,23.87-5.64,47.81-6.1,71.97c-0.2,10.69-3.19,21.31-4.72,31.99
                c-2.26,15.81-5.13,31.58-6.3,47.48c-1.16,15.75-0.54,31.63-0.72,47.45c-0.15,13.16,0.03,26.33-0.64,39.46
                c-0.37,7.2-2.19,14.33-3.35,21.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M686.41,1038.83c-7.17-13.64-9.92-28.56-13.14-43.44c-2.22-10.26-0.34-14.1-16.78-17.73c-10.9-2.4-21.37-6.75-32.01-10.3
                c-5.32-1.77-10.27-1.37-14.46,2.54c-5.76,5.39-11.32,11-17.02,16.45c-5.62,5.37-10.68,11.7-17.16,15.7
                c-6.14,3.8-13.69,5.56-20.82,7.45c-15.63,4.15-30.51,10.22-45.49,16.17c-6.96,2.76-14.78,3.29-21.86,5.8
                c-7.56,2.68-15,6-21.95,9.99c-7.42,4.27-7.46,13.32-11.01,20.12c-3.27,6.27-6.49,12.64-8.89,19.26
                c-1.52,4.19-1.68,8.92-2.11,13.44c-0.67,6.98-1.04,13.99-1.54,20.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M431.43,1156.74c1.1,5.89,5.81,8.58,10.25,11.47c1.74,1.13,3.65,2.02,5.48,3.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M454.15,1174.98c3.5,1.08,7.01,2.12,10.49,3.26c6.85,2.23,13.28-0.39,19.43-2.41c13.81-4.53,26.8-3.11,39.51,3.4
                c3.51,1.8,7,3.64,10.49,5.5c3.16,1.68,5.98,2.7,10.04,1.7c12.89-3.18,26.24-4.47,39.37-2.88c6.88,0.84,14.51,3.62,19.77,7.97
                c9.62,7.98,20.13,12.15,32.2,13.78c5.74,0.78,11.44,2.24,16.96,4c5.08,1.62,7.92,5.75,8.99,10.9c1.29,6.2,3.19,8.37,9.51,7.88
                c10.46-0.81,19.37,2.13,28.14,7.85c4.73,3.09,11.18,3.51,16.81,5.28c2.56,0.81,5.66,1.32,7.38,3.09
                c5.03,5.16,11.21,3.62,17.11,3.8c0.82,0.03,1.89-0.74,2.46-1.45c9.21-11.58,19.61-21.61,32.92-28.61
                c5.48-2.88,9.53-8.18,10.88-14.37c0.86-3.94-0.26-8.29-0.28-12.45c-0.07-10.74-2.27-12.89-13.01-13.02
                c-7.31-0.09-14.93,1.46-21.48-3.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M121.5,1208.71c7.33,8.83,15.29,17.21,21.83,26.59c10.06,14.43,19.39,29.39,28.51,44.43c4.31,7.11,1.83,14.78-1.13,21.82
                c-0.44,1.06-4.47,1.66-6.18,0.93c-4.98-2.11-9.64-5-16.04-8.46c1.09,9.36,1.12,17.75,3.28,25.54c1.69,6.07,5.83,11.53,9.24,17.04
                c6.1,9.86,10.23,20.22,11.07,32c0.75,10.53,2.61,20.99,3.86,31.49c2.23,18.86-0.86,36.93-7.91,54.49
                c-2.74,6.83-4.75,13.96-7.03,20.97c-0.24,0.75-0.15,2.35-0.01,2.38c1.13,0.2,2.47,0.48,3.45,0.05c5.05-2.2,9.86-5.07,15.04-6.84
                c10.71-3.65,21.71-6.47,32.41-10.16c15.63-5.38,28.25-14.86,38.1-28.33c4.63-6.34,9.51-13.9,16.11-17.05
                c9.21-4.4,12.4-13.51,19.65-19.12c6.31-4.89,4.95-13.14,7.1-19.87c2.2-6.88-2.35-11.85-5.23-16.88
                c-3.39-5.94-5.03-11.92-5.34-18.6c-0.53-11.59-6.04-21.31-12.55-30.41c-2.7-3.78-6.72-6.59-9.84-10.1
                c-4-4.51-8.19-8.98-11.41-14.03c-4.47-7.02-1.47-11.76,6.87-11.93c2.5-0.05,5.09,0.05,7.48-0.55c6.71-1.68,7.83-2.21,6.59-9.44
                c-0.62-3.59-0.63-7.32-0.51-10.98c0.11-3.22,1.99-4.89,5.43-5.05c7.5-0.35,14.98-1.29,22.48-1.33c4.6-0.02,5.55-2.8,6.49-6.12"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M661.94,584.65c0.67,30.81,1.51,61.62,1.91,92.44c0.17,13.32-0.77,26.65-0.84,39.97c-0.05,7.99-0.01,16.07,1.06,23.96
                c1.88,13.87,0.27,27.39-2.01,41.01c-0.9,5.37-0.26,10.99-0.67,16.46c-0.42,5.51-1.89,11-1.83,16.49
                c0.16,15.16,0.88,30.31,1.38,45.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M874.71,440.75c0.5,3.5,1.25,6.98,1.44,10.5c0.35,6.38,2.3,11.28,9.02,13.56c6.85,2.33,12.52,6.58,17.57,11.85
                c3.83,3.99,8.32,7.32,12.4,11.07c5.72,5.26,11.96,10.16,16.68,16.22c1.79,2.3,0.83,7.7-0.37,11.18
                c-2.09,6.09-5.33,11.79-8.61,18.75c-2.52-2.52-4.91-4.39-6.63-6.74c-4.67-6.37-9.51-12.69-13.41-19.52
                c-3.48-6.1-9.36-5.72-14.65-6.75c-2.89-0.56-2.22,2.16-1.44,3.82c2.9,6.15,6.77,11.51,12.41,15.57c2.45,1.76,5.42,3.89,6.34,6.5
                c2.65,7.49,9.01,9.93,15.18,12.94c2.28,1.11,4.48,2.39,6.39,3.42c-3.22,12.01-6.35,23.51-9.36,35.04
                c-1.56,5.97-5.3,7.46-11.45,3.4c-8.5-5.6-15.25-13.25-19.25-22.52c-1.61-3.73-1.44-9.73,0.55-13.16c3.06-5.27,1.7-9.19-0.94-13.12
                c-2.89-4.29-6.49-8.12-9.94-12.02c-0.84-0.95-2.29-1.96-3.45-1.94c-5.62,0.06-7.38-4.08-8.92-8.12
                c-1.75-4.61-2.93-9.44-4.65-15.15c1.81-0.97,4.32-2.74,7.1-3.72c3.16-1.11,3.88-2.83,2.44-5.57c-1.36-2.57-2.47-5.57-4.55-7.41
                c-9.72-8.61-7.03-23.81,2.58-31.58c6.64-5.37,10.67-17.45,7.81-25.41c-0.76-2.11-3.57-4.24-5.86-4.89
                c-4.49-1.27-9.26-1.65-13.94-2.08c-1.28-0.12-3.29,0.51-3.89,1.47c-4.26,6.83-7.86,13.94-6.9,23.02
                c-10.4-1.45-21.26-2.78-28.78,8.04c-4.06-2.39-6.4-7.34-7.42-16.12c-0.25-2.19-0.96-4.33-1.46-6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M175.94,382.29c-3.2-1.04-6.41-2.08-9.88-3.21c-10.77,7.98-16.54,30.17-1.99,48.34c-6.43,10.49-8.97,22.01-8.11,34.32
                c0.08,1.17-0.1,2.73,0.56,3.43c3.7,3.98,2.29,7.85,0.51,12.09c-1.69,4.01-2.68,8.32-4.13,12.44c-0.28,0.8-1.29,1.94-1.94,1.93
                c-8.64-0.19-9.77,7.37-12.89,12.64c-1.69,2.85-2.21,6.38-3.64,9.41c-2.31,4.92-4.65,9.86-7.51,14.47
                c-5.51,8.91-6.41,18.24-5.2,28.55c0.86,7.36-0.08,15.55-4.41,21.83c-7,10.16-10.07,21.61-13.25,33.12
                c-1.61,5.83-2.69,11.81-4.02,17.75c-4.98-1.12-5.99-0.24-8.07,6.2c-2.99,9.29-3.7,18.92-5.02,28.49
                c-0.54,3.89-1.92,7.67-2.92,11.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M345.77,192.92c-2.33-2.33-5.09-4.38-6.9-7.06c-2.86-4.25-5.06-8.95-7.61-13.42c-1.07-1.88-1.88-4.42-3.55-5.39
                c-14.17-8.23-25.45-19.7-36.4-31.59c-0.96-1.04-1.85-2.23-3.01-2.97c-10.77-6.81-10.67-18.33-13.13-28.98
                c-2.04-8.84-4.35-17.99-8.64-25.85c-4.76-8.72-6.53-18.22-10.24-27.12c-2.35-5.63-5.11-11.1-7.98-16.49
                c-2.97-5.6-5.84-11.34-9.57-16.42c-3.86-5.24-8.44-10.03-13.25-14.43c-1.74-1.59-5.02-2.04-7.61-2.06
                C193.76,1,169.62,1.06,145.48,1.08c-0.5,0-1,0.31-1.5,0.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M2.62,801.49c8.66,8.99,17.48,17.83,25.94,27.01c16.95,18.41,34.69,35.91,53.87,52.06c16.15,13.6,29.38,29.92,38.98,48.88
                c1.78,3.52,2.59,7.57,3.55,11.46c3.27,13.23,10.75,24.28,18.01,35.47c7.78,11.99,15.38,24.16,26.52,33.42
                c6.58,5.46,13.41,10.67,20.51,15.43c4.11,2.76,8.09,5,10.14,10.01c0.78,1.9,3.95,3.46,6.29,3.97
                c13.22,2.92,23.05,11.11,32.44,20.1c4.45,4.26,7.85,9.2,9.99,14.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M808.78,607.63c2.33,11.66,4.66,23.32,6.99,34.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M816.27,650.1c0,4.16-0.4,8.37,0.08,12.48c1.07,9.19,3.2,18.29,3.79,27.5c1.39,21.46,2.12,42.96,3.12,64.45
                c0.47,10.03-1.54,19.49-4.68,29.08c-2,6.11-1.34,13.23-1.23,19.88c0.15,9.17,0.91,18.32,1.41,27.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1036.04,346.31c-2.27-5.85-3.72-11.91-7.73-17.19c-1.89-2.49-0.72-7.16-1.31-10.78c-0.94-5.74-2.89-10.82-6.76-15.65
                c-6.98-8.72-13.24-18.09-19.06-27.65c-6.15-10.11-11.92-20.53-16.89-31.25c-2.62-5.64-3.58-12.17-4.6-18.41
                c-1.32-8.09-4.53-15.46-9.83-21.25c-3.37-3.68-8.85-6.3-13.82-7.38c-4.26-0.93-7.91,2.62-9.4,7.17c-1.06,3.26-2.31,5.1-6.56,4.18
                c-3.95-0.86-6.93,1.88-7.9,5.82c-2.63,10.63-5.45,21.23-7.42,31.99c-1.84,10.06-2.68,20.31-3.97,30.46
                c-5.49-0.09-8.66,3.25-9.14,9.49c-0.5,6.48,2.26,11.41,7.48,11.23c3.38-0.11,6.97-2.31,9.9-4.37c2.31-1.63,4.01-1.92,5.46,0.23
                c0.51,0.76-0.36,2.65-0.9,3.89c-1.4,3.21-3.43,6.22-4.34,9.54c-1.05,3.84-2.72,5.03-6.64,4.05c-4.13-1.03-5.55-0.13-14.11,6.85
                c-2.81-4.02-5.5-8.03-8.38-11.9c-1.47-1.98-3.83-5.47-4.86-5.17c-2.49,0.74-4.72,3.06-6.55,5.19c-6.17,7.16-2.52,15.54-2.35,23.43
                c0.02,0.87,2.83,2.44,4.29,2.38c9.93-0.42,16.23,3.68,18.52,13.61c0.83,3.61,4.46,3.5,6.98,3.76c1.53,0.16,3.65-1.8,4.89-3.32
                c1.82-2.24,3.08-4.93,4.58-7.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1.63,667.59c0.17,4.5,0.54,9,0.45,13.49c-0.1,5.17-0.85,10.32-0.9,15.49c-0.13,14.49-0.04,28.98-0.05,43.47
                c0,3.32,0.53,6.09,3.43,8.57c20.11,17.17,40.23,34.34,59.89,52.01c6.71,6.03,13.04,12.93,17.88,20.49
                c7.98,12.47,17.51,23.24,28.62,32.92c11.45,9.98,22.53,20.42,33.48,30.95c9.07,8.73,20.97,12.37,31.51,18.44
                c2,1.15,4.36,1.97,5.93,3.56c7.96,8.03,15.66,16.31,23.58,24.38c1.73,1.76,3.96,3.04,5.95,4.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M73.55,350.81c1.89,7.78,3.78,15.56,5.67,23.35c-0.39,0.38-0.78,0.76-1.17,1.14c-3.34-1.84-6.68-3.67-10.24-5.63
                c-1.92,3.87-3.78,7.78-5.78,11.6c-0.83,1.59-1.64,3.81-3.02,4.38c-10.57,4.37-6.34,13.15-6.41,20.61
                c-0.02,1.99,0.37,3.99,0.74,7.77c-4.63-2.58-8.25-4.05-11.18-6.38c-3.08-2.45-5.28-1.96-9.15-1.36
                c-10.67,1.66-14.55,9.41-19.5,16.89c-2.65,4-6.4,7.26-9.33,11.11c-1.41,1.85-2.93,4.27-2.94,6.44
                c-0.16,50.8-0.13,101.59-0.11,152.39c0,0.5,0.31,1,0.48,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M536.07,434.75c0.17-5,0.66-10.01,0.41-14.98c-0.27-5.35-1.35-10.65-1.86-16c-0.26-2.64-0.46-5.41,0.05-7.97
                c0.94-4.77,2.92-5.92,7.37-3.95c10.49,4.64,13.87,7.27,14.53,19.42c0.05,1.01,0.25,2.02,0.5,3c0.72,2.89,0.59,5.41-0.05,8.59
                c-1.35,6.69-1.26,14.16,0.26,20.84c3.97,17.41,3.49,34.96,3.11,52.51c-0.13,5.85-2.29,11.64-3.27,17.5
                c-0.38,2.27-0.06,4.65-0.06,6.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M559.29,529.19c-1.25,13.32-2.5,26.65-3.75,39.97c-0.33,3.5-0.53,7.01-1.02,10.49c-1.46,10.37-7.09,19.04-12.65,27.36
                c-7.03,10.51-9.61,22.2-11.12,34.13c-1.82,14.36-4.7,28.4-7.9,42.51c-4.26,18.74-6.57,37.92-9.77,56.9
                c-1.24,7.34-2.49,14.69-4.09,21.96c-0.41,1.83-1.94,3.67-3.43,4.97c-5.52,4.81-8.5,10.63-9.06,18.03c-0.33,4.38-1.92,8.66-2.86,13
                c-1.05,4.82-1.82,9.71-3.06,14.47c-1.06,4.07-1.73,8.67-4.13,11.87c-4.68,6.23-7.58,13.21-10.83,20.11
                c-3.87,8.22-7.96,16.34-11.96,24.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M916.17,58.02c1.66,7.66,3.7,15.27,4.84,23.01c0.57,3.86-0.54,7.97-0.84,11.97c-0.69,9.33-1.44,18.65-1.97,27.98
                c-0.48,8.49-0.53,17-1.18,25.47c-0.12,1.58-2,3.22-3.39,4.46c-1.97,1.75-4.25,3.15-7.51,5.52c4.15,0.88,6.92,1.25,9.54,2.08
                c2.91,0.93,6.52,1.53,8.35,3.59c5.1,5.73,11.69,5.18,17.59,4.08c7.4-1.38,12.46,1.45,17.54,5.76c9.9,8.37,15.53,19.59,21.02,30.97
                c1.52,3.15,3.15,6.47,5.54,8.93c6.18,6.39,9.95,13.75,11.17,23.19c-4.74-1.04-6.54,1.12-5.73,5.37c0.64,3.37,1.36,6.77,2.52,9.98
                c2.13,5.9,4.72,11.64,6.85,17.54c0.53,1.47,0.08,3.3,0.08,4.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M677.42,419.76c-5.89,18.41-12.44,36.66-14.95,55.97c-1.53,11.8-2.11,23.75-4.15,35.45c-5.17,29.7-2.79,59.62-3.38,89.46
                c-0.15,7.66-0.33,15.32-0.5,22.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M817.77,650.1c1.5,6.66,3.23,13.28,4.43,20c1.42,7.95,1.77,16.14,3.76,23.92c1.43,5.6,3.97,11.33,7.44,15.92
                c8.84,11.69,18.38,22.87,27.89,34.03c2.49,2.93,5.82,5.2,8.94,7.53c5.04,3.76,8.42,8.57,10.51,14.5
                c2.33,6.61,5.45,12.77,10.85,17.61c1.28,1.15,1.9,3.53,2.01,5.4c0.97,15.36-4.25,29.55-8.32,43.99c-1.6,5.66-2.1,11.63-3.09,17.46
                "/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M633.47,651.6c-0.83,26.65-0.24,53.44-2.96,79.9c-2.11,20.58-1.21,41.02-2.04,61.5c-0.64,15.66-1.39,31.31-1.95,46.97
                c-0.14,3.82,0.29,7.66,0.45,11.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M176.94,116.48c-1.33-3.16-2.59-6.36-4.01-9.49c-3.46-7.61-9.52-14.15-9.92-22.99c-0.29-6.5-0.06-13.02-0.06-19.53
                c0.69-0.18,1.39-0.36,2.08-0.54c1.3,2.53,2.88,4.96,3.86,7.6c3.16,8.58,9.52,13.95,17.56,17.41c7.96,3.43,13.9,8.47,16.53,17.03
                c0.71,2.31,2.44,4.28,3.39,6.53c3.08,7.29,6.22,14.57,8.96,21.99c2.3,6.22,0.03,9.76-6.42,11.52c-2.6,0.71-4.85,1.2-3.18,5.52
                c5.14,13.31,9.43,26.94,14.19,40.39c1.97,5.57,4.12,11.08,6.54,16.46c1.04,2.32,2.96,4.24,4.48,6.34
                c0.48-0.06,0.95-0.13,1.43-0.19c0.33-3.04,0.69-6.07,1-9.11c0.52-5.16,0.81-10.35,1.57-15.48c0.55-3.76,2.15-6.51,6.25-8.4
                c1.89-0.87,2.34-5.9,2.59-9.11c0.89-11.57-3.54-21.81-8.33-32c-3.36-7.16-6.87-14.41-8.85-22.01c-2.38-9.1-3.21-18.6-4.69-27.93
                c-0.13-0.81-0.3-1.8,0.03-2.47c4.84-9.95-1.6-18.3-4.3-26.92c-2.01-6.41-6.1-12.27-9.85-17.99c-1.95-2.97-4.91-5.54-7.94-7.46
                c-3.86-2.45-5.48-1.29-5.38,3.38c0.11,4.95-0.56,9.53-5.06,12.45c-2.63,1.71-5.26,2.74-8.04-0.4c-2.67-3.01-5.81-5.48-10.43-5.26
                c-0.93,0.04-2.41-2.32-2.92-3.82c-3.04-8.91-5.67-17.93-12.51-25.01c-4.42-4.58-8.31-9.67-12.59-14.39
                c-0.77-0.85-2.28-1.49-3.44-1.48c-11.16,0.05-22.31,0.27-33.47,0.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M206.91,325.83c1,0.17,2.22,0.02,2.96,0.55c12.16,8.71,25.33,16.47,32.4,30.48c2.11,4.19,4.01,8.88,4.32,13.46
                c0.29,4.29,2.35,6.41,5.27,8.47c14.37,10.14,26.89,21.98,36.61,36.88c4.91,7.53,12.95,11.36,21.82,13.1
                c7.33,1.43,12.92,5.35,16.47,11.99c1.75,3.28,3.68,6.53,4.95,10.01c3.23,8.83,9.87,14.7,17.1,19.88
                c7.91,5.67,16.16,10.91,24.47,15.99c3.7,2.26,7.84,4.16,13.2,2.21c-3.1-4.83-6.13-9.11-8.69-13.66
                c-2.05-3.64-4.79-7.39-2.06-11.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M190.43,446.24c-4.49,6.69-7.73,13.83-8.56,21.98c-0.19,1.88-1.05,3.79-1.97,5.48c-3.91,7.21-8.17,14.23-11.88,21.54
                c-3.23,6.37-2.73,13.19-1.07,19.95c0.16,0.67,0.48,1.33,0.48,2c0,12.93,0.52,25.77-3.27,38.53c-2.91,9.8-3.44,20.26-1.01,30.37
                c0.75,3.13,3.31,6.32,5.9,8.42c6.02,4.88,12.89,8.72,18.79,13.72c6.08,5.17,11.38,11.25,17.1,16.85c1.35,1.32,2.97,2.35,4.47,3.52
                "/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M275.34,663.59c-0.83,6-1.48,12.03-2.58,17.97c-0.48,2.59-2.16,4.95-2.85,7.53c-0.78,2.92-1.89,6.12-1.41,8.95
                c1.37,8.01-3.03,15.95-11.17,17.38c-5.87,1.03-11.99,1.31-17.95,0.96c-4.05-0.24-7.95-2.4-12.01-3.27
                c-6.49-1.39-6.65-0.77-5.62,5.46c0.72,4.38,1.19,9.3-0.1,13.39c-2.04,6.48-8.09,8.23-13.7,4.5c-7.85-5.22-15.12-11.31-23.1-16.3
                c-6.8-4.25-14.11-7.75-21.47-10.97c-3.12-1.36-6.93-1.14-10.43-1.64"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M611.49,496.71c-2.66,14.32-5.41,28.63-7.97,42.97c-2.44,13.64-4.93,27.27-6.91,40.98c-0.95,6.57-1.18,13.32-1.03,19.97
                c0.43,19.46-2.12,38.42-8,56.98c-1.43,4.53-2.06,9.31-3.06,13.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M661.44,283.36c0.33-7.83,1.12-15.67,0.84-23.48c-0.2-5.52-2.25-10.96-2.74-16.5c-0.71-7.96-0.83-15.98-1.07-23.98
                c-0.14-4.49,0.09-9-0.09-13.49c-0.05-1.35-0.79-2.74-1.45-3.99c-2.42-4.55-5.94-8.3-4.31-14.59c0.92-3.56-2.16-8.02,1.18-12.37
                c6.83,2.54,10.09,8.38,13.15,14.45c2.78,5.52,5.43,11.13,8.62,16.41c0.93,1.54,3.76,1.81,5.31,3.13
                c5.67,4.84,5.78,11.78,6.54,18.44c1.32,11.66,2.13,23.41,4.22,34.93c1.04,5.73,5.07,10.86,6.62,16.57
                c2.25,8.34,3.45,16.95,5.16,25.44c0.57,2.85,0.7,6.03,2.1,8.43c4.04,6.91,3.29,14.71,4.91,22.05c0.71,3.21,1.8,6.34,2.71,9.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M648.95,981.37c-0.33,12.32-0.67,24.65-0.99,36.97c-0.1,3.98-0.87,6.53-5.97,7.19c-10.72,1.39-17.54,8.14-20.99,18.3
                c-1.33,3.9-3.05,7.12-6.3,10.21c-5.5,5.22-7.33,12.85-8.11,20.28c-1.31,12.46-1.89,24.99-3.16,37.46
                c-0.77,7.54-2.26,15-3.42,22.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1.63,986.36c0.17,16.32,0.37,32.64,0.45,48.97c0.01,2-0.89,3.99-0.91,6c-0.09,12.32,0.01,24.65-0.08,36.97
                c-0.03,3.71,1.15,5.6,4.96,7.17c10.53,4.33,20.57,9.85,31.09,14.2c8.59,3.56,17.54,6.33,26.47,8.97c2.56,0.75,5.71-0.71,8.43-0.25
                c5.72,0.98,11.57,1.96,16.95,4.02c9.18,3.51,18.65,6.9,26.82,12.18c5.91,3.82,14.1,5.7,15.74,15.58
                c0.78,4.7,7.45,8.41,11.43,12.59c1.22,1.28,2.34,2.66,3.5,3.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M949.14,374.79c3.85,8.41,7.7,16.83,12.16,26.6c-4.61,0.15-8.18,0.66-11.66,0.31c-11.3-1.12-11.16-1.27-14.54-12.9
                c-1.74-5.97-4.59-11.28-10.4-14.56c-3.85-2.17-7.39-4.89-11.32-7.53c-4.52,2.02-4.27,6.74-5.1,10.61
                c-1.34,6.25-1.8,12.7-3.16,18.95c-1.83,8.43-0.92,11.29,7.1,13.35c6.02,1.55,10.34,4.97,14.45,9.14
                c4.41,4.47,8.41,9.54,15.47,10.1c0.89,0.07,1.67,1.56,2.51,2.39"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1074.5,273.87c-10.23,2.25-16.61,9.4-17.49,19.49c-1.15,13.28-2.32,26.48-5.68,39.55c-2.16,8.4-1.28,17.58-1.82,26.4
                c-0.66,10.81-6.56,17.5-17.48,18.34c-2.25,0.17-4.8-1.24-6.94-2.43c-2.04-1.13-3.53-3.89-5.57-4.26
                c-10.88-1.96-13.63-10.55-15.23-19.19c-1.29-6.95-0.48-14.28-0.74-21.44c-0.36-9.92,1.17-20.22-3.66-29.38
                c-1.89-3.58-5.53-6.48-8.91-8.96c-2.32-1.7-5.51-2.33-8.4-3.09c-5.67-1.48-10.46-1.41-15.24,3.71c-3.49,3.75-9.39,5.24-14.22,7.74
                "/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M579.02,1022.84c-0.67-0.5-1.34-0.99-2-1.5c-8.65-6.63-12.08-6.79-20.42,0.07c-3.65,3-7.6,3.52-12.03,4.08
                c-6.9,0.89-14.52,0.63-19.11,7.76c-0.54,0.84-2.54,0.94-3.89,1.03c-9,0.6-18.26,0.24-25.02,7.99c-0.83,0.96-2.21,1.62-3.46,2
                c-9.13,2.76-11.31,6.75-10.1,16.04c0.83,6.41,0.58,12.97,0.59,19.47c0.01,6.06-6.67,7.09-9.06,11.44
                c-0.23,0.42-2.19,0.47-2.85-0.01c-5.38-3.88-9.93-1.17-14.53,1.56"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M916.67,26.04c1.5,3.5,2.56,7.29,4.62,10.41c2.11,3.2,5.54,5.51,7.76,8.66c1.58,2.25,2.81,5.2,3,7.92
                c1.53,21.97,2.81,43.96,4.09,65.95c0.59,10.21,4.49,15.06,13.97,18.09c3.05,0.98,6.26,2.69,8.42,4.98
                c8.01,8.51,18.19,13.65,27.99,19.53c5.63,3.38,11.3,7.46,15.41,12.48c13.68,16.76,23.44,35.79,28.44,56.87
                c1.79,7.53,4.72,13.85,10.73,18.37c8.82,6.63,12.52,16.65,17.9,25.57c1.09,1.81,1.86,3.82,2.77,5.73"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M414.19,440.75c-2.5-0.5-4.98-1.1-7.5-1.47c-2.14-0.31-4.4-0.82-6.47-0.45c-8.05,1.42-7.99,1.51-12.52-5.56
                c-1.71-2.67-3.77-4.5-7.46-4.71c-2.55-0.14-4.92-2.39-7.53-3.22c-2.21-0.71-5.31-1.84-6.85-0.89c-7.8,4.8-14.4,1.42-21.07-2.22
                c-1.64-0.9-3.09-2.22-4.5-3.47c-3.89-3.45-8.08-1.43-11.97-0.36c-2.95,0.81-3.53,3.28-1.52,5.87c2.47,3.19,4.7,6.64,7.6,9.37
                c1.48,1.39,4.25,2.15,6.37,2.01c12.42-0.82,22.09,5.68,31.94,11.67c2.59,1.58,4.76,3.87,7.02,5.95c3.41,3.14,6.68,5.06,12.01,4.89
                c4.6-0.14,9.31,3.25,13.97,5.09c1.67,0.66,3.33,1.33,5,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M988.09,161.45c-1.17-7-2.02-14.06-3.56-20.97c-4.43-19.94-8.93-39.88-16.46-58.96c-2.08-5.26-4.33-10.5-7.09-15.43
                c-1.19-2.12-3.71-4.55-5.89-4.84c-4.77-0.63-5.2-3.66-6.04-7.21c-1.03-4.4-1.26-10.48-4.19-12.65c-7.1-5.25-13-11.92-20.31-16.71
                c-6.14-4.03-8.01-2.9-11.42,3.85c-2.55,5.05-5.41,9.95-7.92,15.03c-0.99,2.01-1.98,4.31-1.96,6.47
                c0.15,14.82,0.72,29.65,0.84,44.47c0.06,7.41,0.17,14.82-4.25,21.6c-1.6,2.45-1.52,6.2-1.6,9.38c-0.13,4.99-0.05,10.02,0.5,14.98
                c0.67,6.06-0.9,10.69-6.57,13.51c-0.53,0.27-1.14,0.55-1.48,1.01c-10.44,13.77-25.55,21.84-38.96,31.98
                c-5.74,4.34-6.94,11.02-8.51,17.47c-4.22,17.3-7.89,34.81-17.02,50.44c-2.04,3.5-4.64,6.67-6.94,10.02
                c-1.87,2.72-3.67,5.49-5.5,8.24"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M154.97,756.03c-5.83-3-11.63-6.04-17.5-8.95c-1-0.5-2.96-0.91-3.34-0.44c-0.78,0.97-1.29,2.65-1.05,3.87
                c1.51,7.69,3.09,15.38,4.96,22.99c1.78,7.22,3.96,14.33,5.94,21.5c0.25,0.89,0.82,2.27,0.44,2.68
                c-6.43,6.91,0.22,12.43,2.84,17.15c12.06,21.74,24.64,43.15,39.48,63.26c11.93,16.16,18.89,35.08,24.72,54.3
                c0.93,3.07,2.3,6.01,3.46,9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M136.98,1119.27c1.83-3.66,3.21-7.66,5.63-10.89c2.21-2.96,5.21-5.69,8.44-7.44c3.73-2.01,3.27-3.92,1.42-6.66
                c-4.63-6.83-11.48-10.69-19.02-12.87c-12.94-3.74-25.14-9.1-35.29-17.77c-10.39-8.89-22.31-13.98-35.13-17.21
                c-13.86-3.49-27.89-6.38-41.95-8.97c-6.02-1.11-12.3-0.8-18.46-1.13"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M338.77,1346.11c-6.99,6-13.62,12.5-21.08,17.84c-7.06,5.05-11.26,10.62-9.45,19.65c0.45,2.24,0.12,4.65,0.06,6.98
                c-0.14,4.96-1.83,8.82-6.43,11.58c-6.75,4.06-12.45,9.11-15.71,16.83c-2.07,4.89-5.56,9.27-8.92,13.48
                c-1.72,2.15-4.34,4.33-6.92,4.92c-11.36,2.6-19.19,10.36-27.45,17.63c-6.92,6.08-13.96,12.02-20.98,17.99
                c-0.55,0.47-1.32,0.68-1.98,1.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M285.33,606.13c1.83,5,3,10.37,5.63,14.91c6.14,10.63,10.96,21.86,10.5,34.04c-0.29,7.68,1.19,14.43,3.11,21.56
                c0.79,2.94-0.7,6.74-1.84,9.9c-2.1,5.77-1.13,10.95,0.96,16.57c4.35,11.71,9.81,23.26,10.48,35.94c0.64,12,7.72,21.34,11.49,32.02
                c3.62,10.27,5.9,21.01,9.24,31.39c1.3,4.05,3.5,7.97,5.96,11.47c5.06,7.18,10.55,14.06,15.91,21.04
                c2.94,3.83,5.97,7.58,8.97,11.37c0.76-0.31,1.52-0.61,2.28-0.92c-0.26-3.65-0.51-7.29-0.77-10.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M396.71,1325.62c5.2-2.35,5.58-7.89,6.25-12.03c4.03-25.18,3.81-50.05-9.78-72.89c-11.79-19.83-28.67-33.4-50.94-39.91
                c-1.99-0.58-4.31-0.08-6.47-0.08"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1.63,930.4c-0.17,9.66-0.37,19.32-0.49,28.98c-0.07,6.33,0.13,12.66-0.07,18.98c-0.12,3.86,0.97,6.37,4.49,8.59
                c18.94,11.95,37.89,23.92,56.41,36.51c8.37,5.69,15.62,13.01,23.63,19.28c3.14,2.46,6.93,4.07,10.43,6.07"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1.63,1085.29c-0.17,8.83-0.4,17.65-0.46,26.48c-0.02,2.78,1.67,4.46,4.46,4.47c11.49,0.03,20.99,5.04,30.64,10.73
                c5.03,2.97,11.76,3.51,17.82,4.18c14.44,1.59,28.12,5.85,40.3,13.34c9.8,6.02,20.68,7.04,31.17,9.52
                c15.77,3.73,31.9,5.94,47.9,8.68c2.1,0.36,4.32,0.05,6.48,0.05"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M187.43,1163.99c4.16,0.08,8.32,0.25,12.49,0.23c7.35-0.03,13.78,1.91,19.05,7.45c2.42,2.55,5.61,4.38,8.42,6.56
                c18.65,14.48,37.34,28.92,55.9,43.52c3.31,2.6,6.11,5.87,9.02,8.95c4.28,4.55,8.37,8.82,14.92,10.75
                c4.95,1.46,9.47,4.49,12.79,9.57c5.15,7.88,11.78,14.79,17.75,22.13c6.06,7.45,9.81,16.51,10.54,25.52
                c0.81,9.99-0.54,20.68-9,28.48c-2.41,2.23-4.42,4.91-6.52,7.46c-2.66,3.23-5.17,6.12-9.97,6.72c-2.83,0.36-5.7,2.73-7.96,4.83
                c-5.86,5.45-11.49,11.15-16.99,16.97c-1.87,1.98-3.06,4.61-4.55,6.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M156.46,709.06c4.16,3.83,8.35,7.63,12.48,11.5c13.33,12.48,27.27,24.39,39.73,37.69c7.57,8.08,5.51,11.42-4.75,17.28
                c-3.17,1.81-8.7,1.77-6.5,7.98c-1,0.33-2.23,1.21-2.96,0.91c-3.42-1.46-6.69-3.26-10.03-4.9c-4.82-2.35-9.62-4.74-14.49-6.98
                c-4.71-2.17-9-1.19-13.01,1.94c-0.78,0.61-2.04,1.12-2.95,0.97c-5.39-0.9-9.45,1.37-13,5.07"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M939.65,547.67c0.5-6.16,1.09-12.32,1.48-18.49c0.57-9.17,0.83-18.27,7.93-25.56c3.95-4.05,7.24-8.8,10.48-13.48
                c1.1-1.59,1.33-3.91,1.55-5.93c1.09-9.82,1.76-19.69,3.12-29.46c1.09-7.82-3.35-14.02-5.05-21c-0.52-2.12-0.85-4.31-0.99-6.49
                c-0.26-3.91,2.07-4.99,5.46-4.48c6.23,0.94,10.39-2.44,10.5-8.51c0.29-14.82,0.64-29.65,0.99-44.47
                c0.31-13.16,0.5-26.32,1.05-39.47c0.34-8.01,1.29-15.99,1.93-23.99c0.26-3.2-0.9-5.2-4.49-5c-3.5,0.19-7,0.52-10.49,0.43
                c-1.68-0.04-3.33-0.92-5-1.42"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M909.68,838.47c-3.27,9.73-12.31,9.4-19.98,11.5c-8.34,2.29-16.66,4.61-24.96,7.03c-1.25,0.36-2.56,1.07-3.47,1.99
                c-5.57,5.59-11.19,11.14-16.44,17.02c-2.3,2.58-3.37,6.25-5.65,8.86c-4.57,5.23-9.78,9.88-14.37,15.09
                c-14.7,16.7-19.63,37.55-24.56,58.41c-1.16,4.91-2.5,9.89-4.64,14.41c-1.04,2.2-4.44,3.15-6.24,5.14
                c-4.35,4.83-8.22,10.09-12.59,14.9c-7.52,8.28-15.17,16.46-23.06,24.4c-1.16,1.17-3.88,1.06-5.88,1.07
                c-8.14,0.02-15.66,2-22.51,6.5c-0.77,0.51-1.96,0.49-2.97,0.53c-8.01,0.29-16.34-1.39-23.47,4.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M667.43,1053.32c-10.74,12.36-23.47,22.97-30.66,38.38c-4.43,9.49-10.63,18.18-16.33,27.05c-5.97,9.29-12.28,18.35-18.44,27.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M907.68,245.89c-5.56-5.56-12.42-5.88-18-1.02c-6.27,5.47-8.82,10.85-3.03,18.54c2.1,2.79,3.45,6.16,5.59,8.92
                c4.49,5.78,4.04,11.18-0.56,16.53c-8.65,10.06-11.4,22.3-12.98,34.98c-0.06,0.49-0.15,1.05,0.01,1.49
                c3.1,8.07,0.07,15.66-1.31,23.53c-1.05,5.98-0.86,12.37-0.07,18.43c1.47,11.2,2.53,22.28,0.49,33.52
                c-0.27,1.5,0.83,3.62,1.93,4.92c5.49,6.5,10.53,13.03,11.58,22.02c0.52,4.42,3.33,8.02,7.81,10.06
                c9.22,4.18,18.26,8.79,27.53,12.85c3.24,1.42,6.95,1.76,10.45,2.59"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M438.17,687.07c1.17,4.83,1.76,9.89,3.59,14.45c8.77,21.74,18.11,43.25,26.77,65.03c4.08,10.26,7.18,20.92,10.51,31.46
                c0.57,1.82,0.29,4,0.04,5.97c-0.99,7.78-0.08,15.28,3.03,22.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M560.54,297.85c0.17-0.17,0.31-0.37,0.51-0.49c0.12-0.07,0.47-0.01,0.47-0.01c0.02-2-0.37-4.09,0.09-5.98
                c1.47-6.04,1.97-12.87,5.24-17.79c4.41-6.64,4.94-13.47,5.08-20.7c0.03-1.65-0.78-3.83-1.97-4.91c-5.7-5.17-5.98-7.3-0.92-12.58
                c5.28-5.52,5.11-11.11-1.6-17.54c-1.11,2.88-1.81,5.31-2.94,7.52c-2.01,3.91-3.58,8.46-6.67,11.27c-2.58,2.35-4.58,4.49-4.71,7.74
                c-0.37,9.32-0.4,18.65-0.57,27.98c-0.06,3.21,0.16,6.21-2.32,9.14c-1.74,2.06-2.3,5.47-2.59,8.35
                c-1.04,10.3-1.72,20.65-2.61,30.97c-0.1,1.19-0.67,2.33-0.96,3.5c-0.53,2.16-1.74,4.47-1.36,6.47c0.99,5.2,3.05,10.13,0.56,15.61
                c-0.55,1.2,1.3,3.67,2.33,5.38c1.87,3.08,3.96,6.02,5.96,9.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M107.02,1151.25c4.98,6.27,12.24,9.15,19.5,9.76c20.14,1.69,39.01,7.97,57.88,14.34c5.59,1.89,10.77,5.08,15.97,7.96
                c1.33,0.74,3.04,2.7,2.89,3.89c-0.99,7.62,4.59,12.23,7.6,18.04c2.21,4.25,1.18,5.76-3.44,5.96c-4.16,0.19-8.32,0.34-12.49,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M677.42,480.72c-0.83,6.83-1.86,13.64-2.44,20.49c-0.58,6.81-1.25,13.68-0.91,20.47c0.39,7.69,1.86,15.33,2.86,22.99
                c1,7.66,2.63,15.3,2.86,22.99c0.46,15.14,0.12,30.31,0.16,45.46c0,1.5,0.31,3,0.48,4.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M540.56,735.54c-0.67,24.98-1.51,49.96-1.91,74.95c-0.17,10.65,0.12,21.36,1.05,31.96c0.84,9.55,2.86,19,4.36,28.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M869.72,1114.77c1.11-8.17,0.84-16.13-4.53-22.96c-4.34-5.52-8.32-4.59-11.66,1.36c-1.67,2.98-5.4,4.85-8.3,7.09
                c-6.12,4.72-12.78,8.85-18.32,14.16c-4.71,4.5-9.46,6.42-15.62,4.83c-11.97-3.09-19.86,2.49-25.99,12
                c-0.9,1.4-2.38,2.53-2.92,4.03c-2.48,6.96-7.77,10.28-14.55,11.99c-4.03,1.02-8.12,2-11.96,3.55c-5.02,2.02-8.14-0.06-11.61-3.48
                c-4.62-4.55-9.9-8.42-14.89-12.59"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M271.84,654.6c4.16,8.16,9.43,15.97,12.17,24.58c2.71,8.52,4.24,17.47,1.14,26.99c-2.36,7.24,1.82,14.73,4.19,21.87
                c0.35,1.05,1.17,1.96,1.44,3.01c0.62,2.47,1.04,4.99,1.54,7.48c-2.81,0.21-5.63,0.42-8.98,0.67c-7.17,9.95-2.58,35.83,8.73,45.79"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M705.89,377.79c-2.33,0.17-4.68,0.22-6.99,0.52c-6,0.77-11.99,1.64-17.98,2.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M674.42,383.79c-2.66,2.99-5.71,1.27-8.48,0.45c-8.35-2.48-15.57-0.27-21.96,5.09c-4.66,3.91-9.16,7.81-14.32,11.25
                c-10.68,7.12-20.81,15.49-27.54,26.76c-3.6,6.04-5.77,12.92-8.64,19.4c-1.78,4.02-3.65,8-5.48,12"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M587.51,459.24c-2,3.33-4.12,6.6-5.97,10.01c-5.71,10.5-9.66,21.62-11.55,33.46c-0.38,2.38-1.29,4.67-1.96,7"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M442.66,502.7c12.19,9.92,26.05,17.96,34.14,32.38c2.22,3.96,6.28,6.79,11.81,6.73c5.24-0.05,7.73,4.45,6.03,9.37
                c-0.43,1.24-0.98,3.46-0.45,3.86c6.06,4.59,9.72,12.4,17.91,13.98c2.15,0.42,6.64-0.86,6.64-1.36c0.01-3.4,5.46-4.32,3.13-9.14
                c-1.73-3.58-0.86-8.52-0.75-12.84c0.12-5.09,1.71-10.24-1.49-15.04c-0.33-0.5,0.27-1.62,0.43-2.45"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M364.75,524.19c-1.83-1.83-3.4-4.08-5.54-5.42c-9.32-5.83-18-12.2-25.62-20.37c-6.72-7.21-14.92-13.11-22.85-19.07
                c-4.89-3.67-8.1-2.19-9.32,3.92c-0.95,4.74-1.42,9.64-1.52,14.47c-0.2,10.38,0.06,21-7.15,29.4c-8.47,9.87-8.75,21.65-9.44,33.55
                c-0.64,11-1.27,22.03-2.62,32.96c-0.59,4.83,0.81,8.28,4.2,10.95c7.3,5.77,11.97,13.5,15.81,21.6
                c4.86,10.24,8.81,20.92,13.08,31.43c0.43,1.06,0.85,2.52,0.46,3.45c-3.55,8.41,0.61,16.08,2,24.03c0.33,1.91,0.07,4.08-0.48,5.97
                c-2.25,7.72-0.21,14.33,4.51,20.53c3.31,4.35,6.72-0.19,9.96,1.01c0.57-4.53,1.07-8.53,1.57-12.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M785.81,1153.24c10.67-0.79,18.55,5.07,26.52,10.93c2.36,1.74,5.16,3.23,7.98,3.93c3.69,0.92,3.62,2.48,1.98,5.13
                c-4.02,6.48-7.64,13.25-12.12,19.39c-3,4.1-5.12,8.32-7.19,12.94c-2.87,6.41-7.75,12.01-12.26,17.56
                c-2.84,3.5-4.49,6.68-2.43,11.08c2.8,5.97-1.28,9.94-4.51,13.95c-3.48,4.31-7.28,8.36-10.95,12.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M2.62,831.47c10.57,9.7,20.27,20.13,28.51,31.95c8.8,12.62,17.97,24.99,26.88,37.54c1.01,1.42,2.02,3.33,1.94,4.96
                c-0.55,10.57,4.66,18.34,11.57,25.51c6.45,6.71,13.53,13.04,18.76,20.63c4.96,7.2,9.21,14.86,15.19,21.35
                c0.97,1.05,1.37,2.63,2.04,3.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M514.59,326.83c-0.67,0.33-1.36,0.62-1.99,1.01c-8.48,5.08-13.12,4.02-16.25-5.08c-1.42-4.15-3.64-6.82-6.69-9.45
                c-2.67-2.3-5.32-4.74-7.41-7.54c-1.14-1.52-2.2-4.83-1.42-5.75c2.98-3.54,0.32-6.04-1.33-8.04c-2.28-2.78-5.49-4.81-8.36-7.1
                c-3.99-3.19-7.74-0.92-10.38,1.6c-8.6,8.2-16.77,16.86-25.05,25.4c-0.74,0.77-1.11,1.94-1.52,2.97
                c-2.48,6.31-6.3,11.36-11.29,16.21c-7.17,6.96-13.24,15.17-19.11,23.32c-9.31,12.93-18.15,26.19-26.99,39.45
                c-2.14,3.21-4.11,6.95-0.06,10.45"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M610.49,426.76c-7.35,7.7-9.18,17.81-11.97,27.48c-4.37,15.17-5.33,30.81-6.64,46.45c-1,11.88-3.31,23.65-4.81,35.49
                c-0.78,6.13-1.05,12.32-1.55,18.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M166.95,1244.18c11.36,12.76,23.47,24.9,32.02,39.94c2.62,4.61,4.93,8.39,3.4,14.63c-1.34,5.46,1.24,11.88,2.03,17.88
                c0.4,2.99,0.93,5.99,0.96,8.99c0.08,8.58,4.19,15.57,9.13,21.91c8.22,10.55,11.98,22.32,11.91,35.55
                c-0.07,12.99-0.02,25.98-0.02,38.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M716.38,593.64c-8.12,1.37-6.71,1.28-9.08,9.47c-1.15,3.98-4.06,7.55-4.74,11.55c-1.53,9.07-2.62,18.27-3.06,27.46
                c-0.72,14.97-1.45,30.01-0.76,44.95c0.27,5.92,4.13,11.76,6.73,17.47c1.6,3.51,3.92,6.7,5.92,10.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1069.01,1131.76c-0.33,1-0.92,1.99-0.96,3c-0.25,6.83-0.13,13.67-0.62,20.48c-0.4,5.56,1.18,10.24,5.1,13.97
                c14.08,13.4,28.27,26.68,42.43,39.99c0.77,0.73,2.22,1.23,2.37,2.03c0.27,1.42-0.21,2.97-0.38,4.47
                c-1.17-0.17-2.62,0.06-3.45-0.56c-7.4-5.56-14.7-11.27-22.02-16.93c-5.83-4.5-11.58-9.1-17.52-13.44
                c-2.45-1.79-5.29-3.05-7.96-4.55"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M995.34,1033.83c-3.98,15.19-10.09,29.55-17.28,43.44c-2.67,5.16-7.24,9.68-8.67,15.09c-3.16,11.94-11.98,20.36-17.33,30.86
                c-5.28,10.36-11.15,20.41-16.95,30.49c-1.83,3.19-4.42,5.93-6.38,9.06c-0.89,1.42-1.06,3.29-1.56,4.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M143.48,780.51c11.32,16.82,22.48,33.76,34.01,50.43c11.27,16.3,22.78,32.45,34.49,48.45c5.09,6.96,10.87,13.42,16.47,19.99
                c0.97,1.14,2.61,1.71,3.94,2.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M917.67,59.02c5.94,3.93,6.88,10.46,7.33,16.5c1.16,15.62,1.63,31.29,2.1,46.96c0.2,6.62,0.71,12.12,7.9,15.78
                c4.79,2.44,8.09,7.75,12.16,11.67c2.04,1.96,4.05,4.23,6.52,5.42c7.51,3.61,13.55,8.89,18.93,15.09"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M193.92,317.34c9.63,4.61,15.7,13.27,22.88,20.58c5.72,5.82,10.57,12.51,15.63,18.61c-6.65,9.52-5.05,18.96,4.47,28.74
                c8.21,8.44,16.96,16.37,24.86,25.09c9.04,9.98,17.35,20.62,26.12,30.85c1.43,1.67,3.61,2.71,5.44,4.05"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M902.18,847.96c0.34,5.29-1.84,9.89-6.08,12.34c-9.87,5.71-13.36,15.17-15.81,25.15c-4.31,17.58-7.91,35.33-12.15,52.92
                c-1.87,7.77-5.96,15.23-6.6,23.03c-1.03,12.51-8.78,20.65-15.89,29.38c-2.45,3-5.66,5.46-10.44,4.27
                c-1.53-0.38-3.57,1.59-5.46,2.28c-2.96,1.1-5.95,2.68-8.99,2.86c-3.92,0.24-6.63,1.85-8.98,4.66
                c-7.96,9.51-14.36,19.98-18.95,31.49c-3.79,9.5-11.18,16.42-16.96,24.51c-6.14,8.61-12.05,17.43-13.04,28.44"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1060.52,812.49c-0.17,8.66-0.25,17.32-0.53,25.98c-0.15,4.67-0.87,9.33-0.9,13.99c-0.03,5.09-2.82,8.35-6.61,10.91
                c-1.3,0.87-3.62,1.52-4.87,0.95c-4.39-2.01-7.81-0.95-9.44,3.18c-2.86,7.22-10.11,9.27-15.09,13.95
                c-0.34,0.32-0.75,1.01-0.99,0.95c-6.92-1.65-8.59,4.24-12.11,7.94c-5.04,5.28-10.93,9.66-18.87,9.6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M715.38,401.28c-0.67,0.33-1.33,0.66-2,1c-12.54,6.43-14.15,8.62-12.39,22.47c2.53,19.9,0.84,39.66-0.47,59.48
                c-0.16,2.43,1.05,5.31,2.46,7.42c3.19,4.76,5.53,8.75,2.76,15.21c-2.08,4.85-1,10.99-3.16,16.41c-1.02,2.55-0.61,5.69,2.6,8.18
                c1.79,1.38,3.06,6.5,1.94,8.05c-5.55,7.67-2.62,15.74-2.17,23.67c0.26,4.52,1.05,9.04,2.01,13.48c0.5,2.27,1.7,4.48,2.95,6.49
                c2.34,3.76,4.91,7.38,7.5,10.98c0.31,0.43,1.28,0.37,1.95,0.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M433.17,200.92c0.5-1.67,1.06-3.32,1.48-5c0.28-1.14-0.03-2.61,0.59-3.44c2.26-3.03,2.23-6.6,2.98-10.03
                c2.36-10.71,5.96-20.8,14.33-28.6c1.35-1.26,1.56-3.86,2.06-5.9c0.77-3.13,1.44-6.66,5.52-6.36c3.35,0.24,6.76,1.15,7.09,5.86
                c0.24,3.53,1.21,7.01,1.92,10.5c1.14,5.56,2.54,10.91,5.29,16.09c2.86,5.37,4.15,11.57,6.2,17.38c0.72,2.04,1.78,3.96,2.46,6.01
                c2.89,8.81,6.42,17.49,8.32,26.51c2.17,10.32,2.87,20.94,4.19,31.44c1.84,14.66,3.64,29.32,5.53,43.96
                c0.28,2.19,0.97,4.33,1.47,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M587.51,1247.18c-0.33-3,0.04-6.32-1.15-8.92c-2.32-5.07-4.38-11.35-8.55-14.25c-12.05-8.37-25.07-15.34-37.78-22.73
                c-1.39-0.81-3.6-1.47-4.91-0.94c-6.51,2.66-12.16-0.33-18.03-2.12c-2.29-0.7-4.58-1.41-8.15-2.51c1.22,2.83,1.91,4.43,2.9,6.75
                c-2.04,0.09-4.01,0.63-5.73,0.18c-10.82-2.84-21.75-4.41-32.98-4.51c-15.61-0.14-29.63-7.26-44.47-10.86
                c-2.24-0.54-4.65-0.39-6.98-0.55"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M325.29,684.58c6.87,17.5,17,32.81,30.43,46.01c7.66,7.53,12.08,16.62,14.27,27.39c2.43,11.92,6.77,23.46,10.32,35.27
                c-4.84,2.76-10.52,2.17-15.08-1.72c-1.9-1.62-3.98-3.02-5.98-4.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M147.47,1216.7c4.99,5.33,10.1,10.56,14.96,16.01c11.96,13.41,26.58,23.11,43.05,29.78c6.78,2.75,9.65,7.92,12.38,13.69
                c1.69,3.57,2.99,7.33,4.54,10.97c4.74,11.14,13.42,18,23.81,23.77c4.76,2.65,7.85,8.34,11.64,12.71
                c3.71,4.29,7.42,8.58,10.96,13.02c1.43,1.79,2.92,3.83,3.45,5.98c4.4,17.87,6.78,36.02,7.12,54.44c0.02,0.84,0.63,1.67,0.96,2.5"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M609.99,151.95c0.83-2.33,1.02-5.37,2.63-6.85c3.63-3.35,7.92-1.98,9.72,3.4c1.94,5.79,4.42,10.96,9.12,14.95
                c4.29,3.65,4.49,9.1,6.04,13.97c1.25,3.95,1.93,8.44,7.44,9.15c0.64,0.08,1.66,2.35,1.41,3.32c-1.81,7.04-3.49,14.16-5.98,20.98
                c-4.5,12.33-7.24,24.83-5.89,38c1.28,12.5,2.85,24.97,3.91,37.48c0.6,7.13,0.41,14.32,0.58,21.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M644.45,192.42c-4.37-0.27-7.15,0.62-8.29,5.93c-0.75,3.5-3.72,7.6-6.85,9.27c-4.46,2.37-3.36,5.65-3.25,8.78
                c0.2,6.01,1.63,12.04,1.23,17.98c-0.51,7.39-0.07,15.08-3.76,22.03c-0.87,1.64-1.39,3.62-1.5,5.47
                c-0.77,12.98-1.39,25.98-2.05,38.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M84.54,316.34c-2.66,3.33-5.79,6.41-7.78,10.11c-0.9,1.67-0.1,4.83,0.88,6.84c6.59,13.44,11.79,27.37,14.85,42.02
                c2.08,9.94,3.57,20.01,5.6,29.96c1.2,5.89,3.08,11.64,4.36,17.52c0.37,1.71-0.25,3.65-0.42,5.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M373.24,507.2c8.16,3.66,16.51,6.96,24.38,11.16c3.38,1.8,6.26,5.14,8.48,8.39c5.13,7.51,12.53,12.48,18.94,18.56
                c5.04,4.78,10.31,8.1,17.09,9.49c2.02,0.41,4.03,2.23,5.47,3.9c7.78,9.06,15.44,18.22,23,27.46c2.57,3.14,4.7,6.63,7.03,9.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M675.92,267.87c-0.17,0-0.34-0.04-0.5,0.01c-2.83,0.82-7.78,1.17-8.11,2.58c-1.18,5.05-1.59,10.17,1.35,15.54
                c3.02,5.51,4.54,12.07,5.63,18.37c2.11,12.25,3.11,24.69,5.24,36.93c1.56,8.93,4.15,17.69,6.44,26.48
                c0.34,1.29,1.6,2.35,2.43,3.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M674.92,381.29c-0.83-3.5-2.2-6.96-2.4-10.5c-0.88-15.31-1.06-30.67-2.25-45.95c-0.54-6.9-2.92-13.65-4.3-20.5
                c-1.14-5.64-1.44-11.51-3.2-16.93c-1.61-4.96-3.72-10.16-10.31-11.12c-4.22-0.61-6.41,0.38-5.86,4.55
                c0.78,5.9,2.69,11.65,3.81,17.51c1.5,7.8,3.26,15.6,3.89,23.49c0.77,9.61,0.15,19.33,0.75,28.96c0.42,6.7,1.89,13.33,2.89,19.99"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M792.8,977.37c1.36,4.87-1.58,8.58-3.54,12.47c-5.07,10.09-6.06,21.19-7.83,32.02c-2.06,12.63-8.06,23.04-17.26,31.28
                c-5.88,5.27-9.13,11.34-10.83,18.66c-1.04,4.49-2.01,8.99-3,13.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M890.7,975.37c-4.99,4.33-9.66,9.14-15.06,12.89c-9.7,6.72-15.97,16-21.4,26.09c-3.38,6.28-8.15,10.9-14.34,14.72
                c-4.56,2.82-9.08,7.14-11.38,11.88c-5.82,12-14.38,21.79-22.68,31.9c-1.83,2.23-3.05,4.96-4.54,7.45"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M306.81,1209.71c17.63,12.43,33.23,26.73,42.95,46.47c4.12,8.36,8.26,16.61,10.12,25.96c2.39,12.03,1.94,23.97,0.87,36"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M587.01,463.73c0.33,1.67,1.12,3.38,0.93,4.99c-1.71,14.67-3.34,29.36-5.5,43.97c-1.78,12.05-4.44,23.96-6.35,36
                c-1.2,7.6-1.73,15.31-2.57,22.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1161.41,243.89c-10.32,2.17-20.6,4.6-30.98,6.41c-8.92,1.56-17.99,2.27-26.95,3.64c-4.42,0.68-8.22,2.38-10.64,6.86
                c-1.64,3.02-4.03,5.37-8.29,6.33c-8.4,1.89-12.15,11.14-10.15,20.26c1.98,9.05,4.27,18.29,4.29,27.46
                c0.03,10.39,1.27,19.9,7.3,28.48c5.81,8.26,7.14,17.71,7.57,27.48c0.1,2.19,1.25,4.33,1.91,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M220.4,196.42c-3.82-0.2-7.63-0.4-12.07-0.63c2.52,5.98,4.44,10.85,6.61,15.6c1.45,3.16,1.69,5.87-0.96,8.58
                c-0.66,0.68-0.93,2.52-0.49,3.38c2.66,5.25,5.93,10.21,8.31,15.57c2.6,5.87,7.06,11.3,5.82,18.94c-3.21,0-6.29-0.42-9.2,0.12
                c-2.82,0.53-7.19,0.23-4.73,5.97c0.97,2.27-0.45,5.57-0.78,8.4c-0.36,3.03-2.29,3.58-4.99,3.58c-2.05,0-4.09,0.74-6,1.12
                c1.83,5.41,3.93,10,4.86,14.82c0.99,5.18-0.98,9.68-6.45,12.54c8.62,5.13,16.82,10.06,25.08,14.9
                c8.18,4.79,15.62,12.23,25.46,12.18c8.98-0.04,15.32,10.33,25.74,4.98c-8.1-15.71-23.98-25.39-32.28-40.72
                c0.44-0.56,0.88-1.12,1.32-1.68c4.9,3.25,9.89,6.39,14.68,9.8c4.45,3.17,8.66,6.65,13.02,9.94c1.25,0.94,2.56,2.06,4.01,2.42
                c1.73,0.42,3.64,0.1,5.47,0.1c-0.33-1.67-0.22-3.62-1.08-4.95c-3.82-5.94-8.01-11.65-11.83-17.59c-0.73-1.14-0.41-2.95-0.58-4.44"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M367.74,251.38c-1.07,2.16-2.41,2.71-5.02,2.12c-6.37-1.43-12.88-2.26-19.7-3.4c-1.47,7.45-0.97,13.99,1.81,20.24
                c1.4,3.15,2.45,6.01-0.04,9.04c-0.46,0.56-0.9,1.29-0.99,1.99c-1.31,10.64-5.93,20.94-3.47,31.96c0.43,1.92,1.28,3.94,2.49,5.47
                c8.56,10.74,17.24,21.38,25.96,31.99c0.88,1.07,2.29,1.7,3.46,2.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M564.04,222.4c-1.83,0.33-4.32,0.03-5.37,1.12c-3.54,3.65-5.72,8.62-11.06,10.5c-0.66,0.23-0.91,2.19-1,3.37
                c-0.16,1.99,0.08,4-0.07,5.99c-0.6,8.28,0.77,16.36-3.45,24.75c-3.01,5.98-2.45,14.62-1.22,21.67c1.83,10.48-0.42,19.4-5.79,28.03
                "/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M625.47,279.86c0.5,9.16,1.08,18.32,1.46,27.48c0.19,4.66,0.63,9.42-0.11,13.96c-0.96,5.91,1.26,10.82,3.08,16.04
                c1.18,3.38,2.12,6.93,2.49,10.48c1.19,11.31,2.07,22.64,3.07,33.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M448.16,1273.16c-0.65-10.94-5.85-21.15-4.68-32.5c0.55-5.34-3.99-10.11-8.3-10.97c-6.5-1.29-8.97,1.41-7.01,7.5
                c1.6,4.96,3.21,9.94,4.46,15c1.05,4.24,0.27,8.24-1.16,12.57c-1.45,4.41-0.62,9.57-0.78,14.4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M167.95,678.58c-5.69-0.38-10.77-6.01-16.98-2c-5.94-4.07-7.36,2.98-10.89,4.7c-0.99,0.48-1.45,3.67-0.95,5.24
                c0.97,3.05,1.23,4.93-1.36,7.8c-1.81,2-2.07,6.79-1.09,9.67c4.91,14.46,10.52,28.69,15.74,43.05c0.86,2.37,1.05,4.98,1.55,7.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M332.78,511.7c8.01,3.42,13.79,9.8,19.91,15.56c15.11,14.21,30.03,28.61,40.62,46.84c4.07,7.01,9.08,13.49,13.96,19.99
                c1.93,2.56,4.7,4.49,7.35,6.95c3.21-5.38,1.26-11.95,6.56-15.39"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1062.02,1106.28c0.67,4.5,1.33,8.99,2,13.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1070.01,1131.26c3.05,2.74,4.27,7.96,10.01,7.18c0.48-0.06,1.22,2.54,1.99,3.8c4.95,8.12,9.14,16.75,19.91,19.24
                c3.2,0.74,5.65,4.5,8.56,6.73c6.79,5.21,13.29,10.92,20.58,15.31c5.23,3.16,11.56,4.46,17.36,6.71c3.93,1.53,6.77,0.62,9.07-2.98
                c0.92-1.43,2.6-2.38,3.93-3.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1061.02,1168.23c-3.97,6.01-3.61,7.03,3.5,11.2c0,2.47,0,4.63,0,6.79c0,16.49,0.39,32.99-0.11,49.46
                c-0.46,15.17-2.5,30.31-2.69,45.47c-0.15,11.98,1.63,23.97,2.23,35.98c0.27,5.47-0.25,10.99-0.43,16.48
                c-0.49,14.82-1.37,29.65-1.39,44.47c-0.01,13.99,0.84,27.98,1.45,41.97c0.08,1.85,0.94,3.67,1.44,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1062.52,1283.65c13.32,3.5,26.48,7.93,40,10.27c19.5,3.37,39.25,5.22,58.9,7.72"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M118,1124.77c10.7-6.75,21.72-4.31,32.99-1.6c11.55,2.78,23.26,4.93,34.95,7.05c12.12,2.2,24.38,3.67,36.41,6.24
                c4.42,0.95,8.37,4.13,12.53,6.3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M127.49,309.84c0.83,3.33,1.22,6.85,2.58,9.96c5.22,12,8.68,24.35,8.91,37.51c0.02,1,0.43,2.4-0.06,2.94
                c-6.12,6.53-3.3,14.59-3.93,22.05c-0.03,0.33,0.02,0.67-0.01,1c-0.36,5.5-3.13,7.09-7.43,3.92c-2.63-1.93-4.66-4.65-7.07-6.9
                c-1.55-1.45-3.31-2.69-4.97-4.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M899.19,148.45c3.39,4.66,4.21,9.82,3.59,15.5c-0.36,3.27,0.2,6.65,0.41,9.98c0.58,8.94-1.02,17.47-5.04,25.47
                c-2.18,4.34-3.33,8.48-2.18,13.56c0.78,3.41-0.13,7.35-0.89,10.92c-0.62,2.95-0.71,4.89,1.99,7.15c3.68,3.09,8.13,6.07,8.1,11.86"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M195.92,1215.2c14.34,14.68,25.87,31.12,31.95,50.97c0.65,2.12,1.46,4.33,1.44,6.49c-0.1,11.3,7.03,19.25,12.51,28.01
                c1.97,3.14,3.38,6.63,5.04,9.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M508.6,337.32c-3,11.66-5.59,23.44-9.12,34.94c-2.67,8.7-6.48,17.05-9.88,25.51c-1.58,3.95-4.53,4.5-8.49,3.59
                c-5.13-1.18-9.9-1.53-14.63,2.72c-4.54,4.08-10.58,2.33-15.8,0.15c-12.19-5.11-24.66-4.98-36.91-1.22
                c-4.92,1.51-9.04,5.65-14.67,9.35c3.4,2.26,5.97,3.59,8.06,5.45c5.45,4.85,10.74,9.9,15.97,15c0.58,0.57,0.39,1.94,0.56,2.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M797.29,200.92c0.33,1,1.25,2.3,0.91,2.95c-3.69,7.07-7.8,13.92-11.31,21.08c-2,4.08-3.1,8.61-4.58,12.95
                c-1.02,2.99-1.82,6.07-3.04,8.98c-2.88,6.88-5.64,13.83-9.03,20.46c-4.52,8.84-6.93,18.57-12.19,27.15
                c-2.16,3.52-1.9,8.52-2.71,12.86c-0.22,1.16-0.34,2.33-0.51,3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M755.09,318.33c-0.25,2.5,0.31,5.51-0.89,7.41c-5.28,8.35-7.39,17.71-9.98,27.03c-2.59,9.32-6.46,18.28-9.25,27.55
                c-1.36,4.51-1.73,9.38,1.87,13.47c0.63,0.72,1.06,1.63,1.51,2.48c4.9,9.19,5,9.23,13.99,4.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M382.73,744.53c1.33,1.83,2.54,3.77,4.02,5.48c4.89,5.64,4.47,12.94,5.81,19.54c2.27,11.2,3.1,22.7,5.31,33.91
                c1.38,6.98,4.72,13.57,6.21,20.54c1.77,8.31,9.03,10,15.62,9.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M539.56,1384.08c-10.02,10.56-22.03,17.53-36.47,19.96c-7.96,1.34-16.03,2.07-23.96,3.6c-11.54,2.23-22.99,4.93-34.48,7.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M429.68,1094.79c-7.85-0.12-15.42,0.8-21.46,6.51c-5.02,4.75-6.47,6.15-1.48,13.95c0.94,1.48,2.21,2.78,3.48,4
                c2.17,2.07,2.48,4.46-0.07,5.93c-6,3.46-7.46,8.07-5.6,14.62c0.78,2.73-0.51,5.98-0.28,8.94c0.76,9.93,7.6,14.66,15.92,18
                c0.69,0.28,1.33,0.66,2,0.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M106.02,169.44c-5.42-6.92-11.35-13.27-12.15-22.97c-1.17-14.31-7.76-26.67-16.39-37.94c-0.67-0.88-2.23-1.25-3.44-1.49
                c-1.28-0.25-2.65-0.06-3.98-0.06c0.33,1.5,0.36,3.16,1.05,4.47c3.72,7.05,7.68,13.98,11.41,21.02c2.62,4.93,4.9,10.05,7.6,14.93
                c0.71,1.29,2.42,2.04,3.67,3.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M753.34,663.59c-0.83,8.16-2.05,16.3-2.41,24.49c-0.52,11.98-0.28,23.98-0.63,35.97c-0.28,9.67-0.88,19.33-1.53,28.98
                c-0.1,1.54-1.25,3-1.92,4.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M387.22,680.08c2.83,3.83,5.94,7.49,8.41,11.54c2.08,3.41,2.88,7.67,5.21,10.85c3.43,4.66,0.79,9.88,2.37,14.58
                c2.67,7.99,5.35,15.98,7.96,23.99c0.27,0.84,0.13,1.82,0.23,3.8c-8.57-4.2-16.59-8.12-24.61-12.04c-0.37,0.49-0.73,0.98-1.1,1.47
                c1.51,7.48,5.26,13.99,9.54,20.24c1.2,1.76,2.78,3.25,3.95,5.03c2.98,4.54,7.07,8.66,3.7,15.07c-0.99,1.89-0.13,4.96,0.38,7.39
                c1.51,7.19,2.82,14.48,5.05,21.46c2.87,8.98,6.56,17.69,9.86,26.52c0.29,0.79,0.35,1.66,0.52,2.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M646.45,1231.19c-8.67-9.27-19.96-12.84-31.91-15.73c-6.15-1.49-11.98-5-17.4-8.47c-2.15-1.38-2.59-5.25-4.2-7.74
                c-1.13-1.74-2.66-3.67-4.47-4.44c-9.54-4.08-19.7-4.64-29.92-4.6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M2.13,748.03c-0.33,1.83-0.94,3.66-0.95,5.5c-0.08,13.99-0.04,27.98-0.04,41.97c0,9.66,0,19.32,0,28.98
                c0,16.32-0.08,32.64,0.09,48.96c0.02,2.34,0.52,5.39,2.02,6.88c10.44,10.38,21.18,20.45,31.86,30.59
                c4.96,4.7,9.73,9.65,15.07,13.88c3.14,2.49,7.13,4.04,10.92,5.56c6.88,2.76,11.43,8.03,16.02,13.49c4.55,5.4,9.58,10.4,14.47,15.5
                c0.32,0.33,1.29,0.04,1.95,0.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M887.7,979.87c-0.41,8.12-4.55,14.78-8.51,21.47c-5.93,10.03-12.06,19.94-17.89,30.03c-1.18,2.04-2.39,4.86-1.89,6.91
                c1.69,6.89-2.21,10.91-6.05,15.63c-5.79,7.12-11.02,14.74-15.98,22.46c-1.91,2.97-3.57,3.31-6.64,2.51
                c-3.39-0.89-6.96-1.23-10.47-1.56c-8.5-0.79-16.06,2.27-23.49,5.93c-3.21,1.58-6.63,2.72-9.97,4.04
                c-8.49,3.34-16.98,6.66-25.47,10c-8.98,3.53-13.5,11.8-19.45,18.52c-3.84,4.34-7.33,8.98-11.03,13.45
                c-1.66,2.01-3.24,2.63-6.09,1.22c-2.14-1.06-5.26,0.05-7.88-0.28c-2.7-0.34-5.37-1.16-7.99-1.95c-5.35-1.6-10.66-3.31-15.99-4.98"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M712.88,1130.76c3,7.74,8.34,13.29,15.44,17.56c6.87,4.14,13.54,8.69,19.92,13.55c1.86,1.42,2.93,4.41,3.51,6.88
                c1.39,5.95-0.59,7.92-5.83,5.34c-6.89-3.4-14.12-1.15-21.03-2.45c-5.57-1.05-9.68,0.68-13.54,4.55
                c-12.22,12.25-24.55,24.4-36.96,36.48c-3.8,3.7-7.96,7.03-11.96,10.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M725.87,1026.83c3.83,1.5,7.56,3.81,11.51,4.31c6.57,0.84,13.3,0.46,19.95,0.72c2.02,0.08,4.02,0.55,6.87,0.96
                c-1.04,2.7-1.6,5.32-2.94,7.47c-5.85,9.41-12.12,18.57-17.84,28.06c-3.22,5.34-8.39,5.99-13.57,6.92
                c-5.14,0.92-9.22,3.36-13.09,6.91c-3.49,3.21-8.11,5.17-11.83,8.17c-5.69,4.59-11.04,9.6-16.53,14.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M657.44,1109.28c0.17,0,0.38,0.07,0.49-0.01c10.05-7.16,20.3-1.49,30.48-0.5c2.49,0.24,5.08,0.02,7.45,0.66
                c1.07,0.29,2.64,2.93,2.4,3.24c-3.74,4.73-4.19,12.71-12.84,12.77c-2.01,0.01-4.31,1.83-5.94,3.39
                c-5.97,5.68-11.6,11.72-17.58,17.38c-3.6,3.41-7.3,6.92-11.52,9.41c-6.47,3.83-11.47,8.6-15.56,15.02
                c-6.27,9.85-13.51,19.08-20.33,28.57"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1.63,649.6c-0.17,4.16-0.57,8.34-0.38,12.49c0.07,1.53,1.2,3.41,2.43,4.44c10.4,8.77,21.56,16.74,31.26,26.21
                c12.63,12.33,24.14,25.81,36.09,38.84c0.41,0.44,0.36,1.3,0.53,1.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M2.62,704.56c12.99,9.99,26.38,19.51,38.85,30.1c14.62,12.41,27.93,26.42,44.58,36.32c4.86,2.89,9.97,5.36,14.96,8.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M745.6,774.01c-1.58,20.15-3.13,40.31-4.77,60.46c-0.34,4.18-0.98,8.33-1.48,12.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M578.02,1142.25c-1.66-1.5-3.24-3.11-5.01-4.48c-8-6.18-8.81-6.05-17.02-0.09c-2.11,1.53-4.3,1.7-7.52,0.88
                c-12.25-3.14-12.93-12.61-13.92-22.29c-0.26-2.51-0.19-5.18-1.07-7.46c-3.12-8.12-5.15-8.52-12.97-4.67
                c-2.54,1.25-6.03,0.43-8.89,1.24c-3.42,0.98-6.64,2.69-10.32,4.25c-2.41-2.29-4.67-4.72-7.22-6.81c-4.07-3.32-8.46-1.68-9.44,3.46
                c-1.08,5.65-2.02,11.32-3.02,16.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1066.51,709.56c3.16,15.01,11.8,25.68,25.45,32.52c2.66,1.34,5.04,3.26,7.51,4.95c8.5,5.82,16.92,11.75,25.5,17.45
                c6.04,4.02,12.4,2.67,18.71,0.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M973.61,1080.8c-14.46-3.08-26.56-2.83-33.54,8.95c-3.37,5.7-8.92,8.41-15.37,10.19c-8.1,2.25-15.72,5.91-22.06,11.79
                c-2.26,2.09-4.83,3.91-7.48,5.5c-5.73,3.44-6.4,9.76-9.02,15c-1,2-2.21,4.53-4.01,5.39c-11.16,5.32-19.92,13.46-27.91,22.62"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M253.86,425.76c15.49,11.06,28.62,24.59,41,38.94c3.74,4.33,4.47,8.49,1.5,13.55c-5.41,9.23-2.48,18.61-0.12,27.97
                c0.35,1.37,0.09,3.14-0.49,4.45c-2.52,5.73-5.41,11.29-7.84,17.05c-0.74,1.76-0.42,3.96-0.59,5.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M521.58,1330.12c-7.23,13.82-17,25.47-30.52,33.39c-11.29,6.61-23.22,11.94-35.85,15.77c-4.86,1.47-9.05,5.14-13.54,7.8"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1059.02,917.91c-3.66,4.5-7.24,9.07-11.01,13.47c-6.45,7.53-13.45,14.35-23.45,17.02c-0.2,0.05-0.35,0.48-0.5,0.46
                c-9.98-1.03-16.23,6.43-24.01,10.45c-2.77,1.43-5.99,1.99-8.94,3.11c-1.09,0.41-2.24,1.1-2.98,1.99
                c-5.73,6.94-11.29,14.02-17.04,20.94c-6.84,8.24-15.74,14.12-25.06,18.8c-12.52,6.29-25.04,12.5-38.72,16.64
                c-11.81,3.57-23.54,5.45-35.61,6.46c-1.61,0.13-3.32-0.91-4.99-1.41"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M305.81,1018.84c-2.83-0.67-5.68-1.25-8.49-2.02c-4.15-1.14-7.44-0.09-10.59,2.92c-1.63,1.56-4.14,2.57-6.42,3
                c-6.05,1.16-13.5,0.42-16.21,7.19c-2.32,5.77-4.72,5.73-9.81,4.12c-4.16-1.32-8.4,0.79-11.41,4.29c-3,3.49-2.35,7.34-0.44,10.94
                c1.09,2.05,2.92,3.7,4.42,5.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M462.64,559.16c-0.67-1.5-1.47-2.95-1.97-4.51c-0.82-2.55-2.67-5.1,1.41-7.1c3.19-1.57,1.86-4.41,0-6.32
                c-1.72-1.77-3.95-3.04-5.93-4.56c-8.92-6.83-11.55-17.27-15.52-26.96c-0.73-1.78-1.57-3.92-3.03-4.9c-3.95-2.65-8.17-4.94-12.46-7
                c-1.15-0.55-2.94,0.22-4.44,0.39c0.33,1.33,0.4,2.81,1.05,3.97c2.55,4.56,5,9.23,8.05,13.44c1.95,2.69,2.34,5.13,1.4,8.07"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M441.67,957.88c-4-0.33-8.06-1.28-11.97-0.87c-8.59,0.89-14.52,7.29-16.06,15.35c-0.21,1.09-1.11,2.19-1.98,2.96
                c-0.98,0.87-2.62,1.12-3.4,2.09c-4.27,5.34-9.74,6.2-16.05,5.02c-2.06-0.39-4.28-0.57-4.57,2.92c-0.49,5.97-5.53,6.36-9.91,6.91
                c-2.75,0.35-5.43,0.01-7.1,3.02c-0.35,0.62-2.26,0.69-3.37,0.52c-7.84-1.2-14.07,0.76-17.33,8.68
                c-5.17-1.41-10.09-3.43-15.17-3.94c-4.4-0.45-9.2,0.1-13.41,1.46c-6.97,2.25-9.32,9.24-7.04,18.33"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M633.47,1169.73c-3.83-4.33-7.62-8.7-11.5-12.98c-2.52-2.78-5.58-4.18-9.48-3.54c-4.36,0.71-7.72-1.63-10.44-4.51
                c-8.74-9.25-16.05-9.9-26.04-3.48c-3.89,2.5-9.49,3.74-11.68,7.21c-5.85,9.28-10.3,19.46-15.23,29.32
                c-0.33,0.67-0.04,1.64-0.04,2.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M439.67,441.75c4.5,5,8.88,10.1,13.53,14.95c2.53,2.64,3.41,5.18,3.14,9.05c-0.6,8.76,6.55,17.1,16.27,21.04
                c5.65,2.29,10.97,5.36,16.52,7.9c6.11,2.79,12.31,5.36,18.47,8.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M606.99,745.53c-0.83,7.83-1.8,15.64-2.47,23.49c-1.27,14.81-2.42,29.64-3.49,44.47c-0.38,5.31-0.37,10.66-0.53,15.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1026.55,1314.63c0.5,1,1.46,2.01,1.43,3c-0.4,13.16-0.97,26.32-1.42,39.47c-0.39,11.49-0.4,23-1.12,34.47
                c-0.42,6.7-1.89,13.33-2.89,19.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M347.76,760.52c3.5,8.33,7.34,16.53,10.37,25.02c2.12,5.94,3.12,12.28,4.6,18.45c0.11,0.47,0.21,1.11-0.01,1.48
                c-4.68,7.84,0.13,14.8,2.12,21.98c0.33,1.19,1.73,2.37,2.93,2.97c12.59,6.36,17.42,18,20.91,30.54c0.63,2.25,0.71,4.65,1.04,6.98"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M327.29,835.47c1.83,0.33,3.66,0.72,5.5,0.98c1.65,0.24,3.33,0.35,4.99,0.51c-0.5-1.5-0.74-3.16-1.54-4.47
                c-4.23-6.89-8.64-13.67-12.94-20.52c-7.69-12.26-14.21-25.08-18.5-38.96c-1.95-6.31-4.67-12.19-10.48-15.98
                c-0.87-0.57-1.88-0.91-3.64-1.74c0.75,10.91,1.45,21.06,2.15,31.2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M775.82,1373.59c-6.62,3.18-6.81,10.8-10.83,15.86c-3.72,4.67-5.86,10.56-9.2,15.58c-8.29,12.47-11.77,26.94-17.38,40.53
                c-1.32,3.2-2.05,6.64-3.04,9.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M640.46,1487.51c-8.17,0.4-16.23-0.19-23.95-3.07c-1.84-0.69-3.25-2.49-5.05-3.36c-1.68-0.81-3.6-1.33-5.47-1.51
                c-4.98-0.48-10-0.57-14.97-1.14c-1.61-0.18-3.11-1.36-5.21-2.34c1.98-1.64,3.53-2.92,6.22-5.14c-6.6-0.94-11.78-2.06-17-2.35
                c-10.81-0.59-21.64-0.82-32.46-1.06c-11.32-0.25-22.64-0.35-33.96-0.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M475.13,1096.29c0.5,4.66,2.24,9.61,1.24,13.93c-2.19,9.46,0.24,18.98-2.72,28.7c-3.26,10.73-7.87,21.88-5.58,33.83
                c0.3,1.58-0.27,3.32-0.44,4.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M234.88,1082.3c-3.14,0.92-5.98,0.96-9.04-0.92c-8.19-5.05-16.71-9.56-24.86-14.68c-2.29-1.44-4.13-4-5.49-6.42
                c-5.25-9.36-13.77-14.01-23.55-16.93c-3.79-1.13-7.64-2.06-11.49-3c-1.3-0.31-2.66-0.36-3.99-0.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M389.72,646.6c4.83,8.16,9.79,16.25,14.45,24.5c7.11,12.59,14.14,25.23,20.94,37.99c2.27,4.26,3.94,8.89,5.44,13.49
                c0.46,1.41-0.35,3.36-0.92,4.94c-0.98,2.74-2.21,5.38-3.44,8.31c-4.75-1.22-6.91-5.01-8.41-8.83
                c-3.78-9.55-7.22-19.24-10.55-28.95c-4.14-12.11-8.05-24.3-11.98-36.48c-0.34-1.06-0.05-2.32-0.05-3.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M431.18,532.68c-8.66-6-17.29-12.03-25.99-17.97c-3.58-2.45-7.21-4.86-11.01-6.96c-3.18-1.76-6.53-3.34-9.98-4.49
                c-5.75-1.91-11.62-3.45-17.47-5.04c-3.25-0.89-6.47-2.02-9.61,0.84c-0.65,0.6-3.13-0.14-4.33-0.92
                c-14.22-9.26-25-21.93-35.06-35.38c-4.97-6.64-10.51-12.65-14.27-20.36c-2.82-5.76-7.64-11.4-15.14-12.66"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M661.44,1465.02c-10.66-1.83-21.32-3.59-31.96-5.54c-2.95-0.54-6.19-0.91-6.26-5.44c-0.01-0.86-3.37-2.3-5.24-2.4
                c-10.48-0.55-20.97-0.77-31.46-1.1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M651.45,895.43c-7.21-16.79-23.84-20.64-40.36-11.81c-12.39,6.62-19.93,17.14-16.98,30.77c0.43,1.99,2.23,3.69,3.39,5.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1072.51,869.95c1.83,1.67,3.84,3.18,5.45,5.03c2.29,2.63,1.73,4.61-1.96,4.93c-4.45,0.39-5.13,2.35-3.02,6.04
                c2.44,4.27,4.08,9.15,7.15,12.87c5.7,6.88,12.33,12.97,18.26,19.68c1.75,1.98,2.46,4.9,3.58,7.42
                c5.06,11.38,8.63,23.89,21.98,28.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M191.93,446.24c0,4.66,0.67,9.45-0.15,13.96c-1.47,8.09-3.34,16.19-6.01,23.95c-1.99,5.79-5.34,11.14-8.37,16.52
                c-2.78,4.95-5.95,9.69-8.95,14.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M556.55,961.38c-3.01-9.07-8.46-16.53-16.57-21.34c-7.68-4.56-16.17-5.39-24.38-0.63c-8.21,4.76-11.56,12.31-12,21.47
                c-0.12,2.5-0.33,5-0.49,7.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1152.92,810.49c-2.81,0.11-4.82-0.5-7.17-2.82c-5.27-5.22-12.1-8.92-17.14-14.32c-5.3-5.69-11.76-8.2-18.6-10.96
                c-14.53-5.86-26.86-14.88-36.01-27.86c-0.76-1.08-1.44-2.23-1.78-2.78c-4.46,5.95-8.47,11.01-12.11,16.34
                c-0.96,1.4-0.53,3.68-1.14,5.41c-1.16,3.23-2.55,6.38-3.99,9.49c-0.37,0.8-1.77,1.28-1.88,2.03c-1.61,10.41-9.66,16.9-15.1,24.94
                c-2.84,4.19-6.18,8.04-9.69,12.55c-1.63-2.21-2.67-3.63-3.92-5.32c-1.81,1.63-4.06,2.91-5.23,4.85
                c-4.08,6.74-10.54,10.7-17.61,11.63c-8.88,1.16-16.84,8.04-26.43,4.31"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M864.22,1454.03c1.17,0.83,2.4,1.59,3.48,2.52c1.24,1.08,2.35,2.31,3.51,3.48c-1.66,0.83-3.28,2.22-5.01,2.39
                c-4.8,0.47-9.69,0.12-14.46,0.72c-2.91,0.37-5.85,1.56-8.47,2.95c-4.47,2.37-6.23,6.18-1.9,11.31c2.62,3.1,6.53,5.11,10.7,8.24
                c-6,2.46-8.87,7.16-10.33,12.86"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1041.04,1150.75c0.6,10.69,5.47,21.87-1.19,31.85c-4.67,6.99-10.53,13.52-17,18.86c-4.28,3.53-4.53,5.87-1.63,10.09
                c4.85-1.94,9.72-3.89,14.58-5.84"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1040.04,993.86c-2.75,14.39-8.17,28.4-5.99,43.47c0.12,0.84,0.15,1.75,0.52,2.49c4.29,8.51,8.21,17.25,13.09,25.42
                c4.02,6.71,9.07,12.83,13.9,19.03c2.73,3.49,5.94,4.56,9.03-0.54c3.62,6.11,7.73,11.54,10.25,17.62
                c5.55,13.38,16.93,20.5,28.19,27.81c1.99,1.29,5.03,0.82,7.39,1.7c1.99,0.74,3.69,2.26,5.53,3.41c2.67,1.66,4.94,5.94,8.61,1.1
                c0.41-0.54,2.54,0.24,3.87,0.4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M98.03,1012.34c-3.83-2-7.76-3.82-11.46-6.05c-3.47-2.09-6.52-4.93-10.09-6.82c-1.96-1.04-6.1-1.77-6.64-0.94
                c-1.62,2.48-3.06,6.04-2.51,8.75c0.75,3.72,2.96,7.38,5.31,10.48c5.21,6.88,11.05,13.27,16.31,20.12
                c1.72,2.24,2.42,5.26,3.58,7.93"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M556.05,552.67c-3.66-1.33-7.35-2.6-10.97-4.03c-1.09-0.43-2.45-1.06-2.95-2c-7.64-14.28-20.9-21.37-35.59-23.53
                c-17.41-2.56-32.55-9.32-47.45-17.8c-4.44-2.53-9.35-5.27-14.92-2.6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1020.56,766.02c-2.66,5.5-5.14,11.1-8.03,16.47c-5.64,10.46-16.03,15.53-25.57,21.28c-2.76,1.66-7.19,0.6-10.85,0.72
                c-10.82,0.35-21.64,0.7-32.47,1c-14.09,0.4-25.3,9.19-27.97,21.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M439.17,624.12c3.83,5.5,8.35,10.64,11.34,16.56c5.83,11.57,11.36,23.37,15.92,35.48c2.72,7.23,3.27,15.27,5.03,22.89
                c0.37,1.62,1.83,2.98,2.65,4.52c2.21,4.14,4.34,8.32,6.51,12.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M481.62,1315.13c-0.98,16.15-7.44,29.19-21.46,38c-2.62,1.64-5.78,3.13-7.37,5.56c-5.49,8.36-13.79,12.18-22.61,15.4
                c-0.84,0.31-1.67,0.66-2.5,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1029.3,694.07c-5.41,2.5-10.69,5.37-16.28,7.35c-3.21,1.13-6.96,0.71-10.43,1.19c-1.54,0.22-3.1,0.76-4.49,1.48
                c-12.62,6.6-26.29,6.59-39.97,6.4c-1.34-0.02-2.66-0.93-4-1.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M638.46,978.37c-4.5,0.67-9.07,1.01-13.45,2.12c-2.51,0.64-5.19,2.08-6.92,3.97c-3.51,3.84-6.22,8.43-9.71,12.29
                c-1.31,1.45-4.49,2.99-5.76,2.36c-3.21-1.58-5.27,0.08-7.6,1.27c-3.38,1.73-6.88,3.36-9.92,5.58c-1.63,1.19-3.3,3.49-3.43,5.39
                c-0.79,11.47-1.32,22.97-1.57,34.47c-0.12,5.49,0.59,10.99,0.92,16.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1036.04,611.63c3.88,3.04,7.91,2.41,12.11,0.23c3.02,5.18,2.25,9.79-1.11,14.26c-9.79,13-21.13,24.51-34.02,34.41
                c-6.55,5.03-13.6,9.4-20.43,14.05c-2.48,1.69-4.99,3.33-7.49,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M2.13,880.94c-0.33,8.66-0.72,17.32-0.98,25.98c-0.13,4.49,0.25,9.01-0.1,13.48c-0.39,4.96,1.28,8.1,5.47,11.13
                c15.22,11,30.03,22.56,45.09,33.79c1.5,1.12,3.62,1.39,5.45,2.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1065.51,925.41c0.21,4.74,2.06,8.71,5.49,12c5.02,4.81,9.25,11.13,15.17,14.14c8.89,4.53,11.72,13.42,17.77,19.86
                c9.44,10.05,18.3,20.68,28.14,30.31c6.07,5.94,13.52,10.47,20.34,15.64c1.51,1.15,3,2.33,4.5,3.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M478.63,1019.34c-1.79-7.6-3.72-14.81-12.96-17.08c-12-2.93-22.98,4.96-23,17.08c0,1.5,0,3.01,0,4.4
                c-13.21,2.45-11.93,4.66-10.88,11.82c-2.59,1.04-5.31,2.27-8.11,3.24c-8.16,2.84-12.06,7.46-5.49,17.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M816.77,1498.5c0.88-1.47,1.77-2.94,3.01-5c-2.32-1.07-4.49-1.9-6.49-3.03c-2.05-1.16-3.94-2.6-7.12-4.74
                c2.72-1.08,4.12-1.86,5.61-2.16c1.44-0.28,3.04,0.2,4.47-0.11c3.24-0.71,7.12-1.26,7.38-5.45c0.23-3.65-3.22-3.6-5.84-4.58
                c-11.68-4.37-23.73-5.17-36-4.41c-6.83,0.42-12.84,10.52-11.43,16.98c0.55,2.54,8.25,4.97,11.92,3.98
                c0.55-0.15,0.96-0.82,1.51-0.95c9.51-2.28,11.72-8.35,5.99-16.52c-0.56-0.79-1.01-1.66-1.51-2.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M927.16,1168.73c-4.38,7.95-11.81,12.54-18.95,17.52c-5.95,4.15-12.17,8.11-17.39,13.07c-7.91,7.52-15.69,14.84-26.55,17.99
                c-2.24,0.65-4.03,2.87-6.03,4.37"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1113.96,1066.31c-3.07-0.43-4.95,0.54-5.04,4c-0.03,1.01-0.53,2.83-0.97,2.87c-6.08,0.68-2.88,4.74-1.84,6.54
                c6.72,11.65,14.11,22.91,20.73,34.62c3.76,6.64,7.73,13.36,8.2,21.42c0.11,1.87,1.56,3.67,2.39,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M269.85,1158.24c-5.32,11.84-2.35,18.88,5.11,25.35c6.29,5.46,7.85,13.69,12.98,19.52c8.08,9.19,16.87,17.75,25.37,26.58"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M335.78,922.41c-2.66-6.05-4.39-12.47-9.92-17.07c-10.65-8.83-27-6.94-34.98-0.37c-5.36,4.42-5.94,10.49-4.57,16.94
                c0.2,0.96,0.03,1.99,0.03,2.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M652.95,1374.59c7.52,0.34,10.34,7.35,15.36,11.16c4.91,3.73,9.68,8.26,12.93,13.44c6.98,11.12,9.72,23.69,9.71,36.86
                c-0.01,8.49,0.3,16.99,0.47,25.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1036.04,911.42c-6.45,9.7-15.41,16.44-25.44,22.04c-2.86,1.6-5.2,2.98-8.13,0.06c-0.75-0.75-3.08-0.57-4.35-0.03
                c-5.9,2.49-11.47,6.65-17.57,7.58c-8.62,1.32-17.6,0.34-26.42,0.34c-1.17,0-2.78-0.5-3.43,0.08c-5.34,4.81-10.97,2.09-16.55,0.91
                c-1.34-0.28-2.67-0.66-4-0.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M388.72,1296.64c-1,1.5-2.6,2.89-2.9,4.51c-2.71,14.65-14.61,24.32-20.12,37.43c-0.52,1.24-1.75,2.19-2.65,3.28
                c-0.45-0.12-0.91-0.24-1.36-0.36c0.36-3.13,0.6-6.28,1.09-9.39c0.74-4.68,1.64-9.33,2.47-13.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M992.84,877.19c-2.08-0.92-4.21-1.75-6.23-2.77c-3.89-1.96-7.49-3.62-8.73-8.92c-1.5-6.37-6.93-7.61-11.07-2.4
                c-4.64,5.85-8.34,12.61-11.57,19.39c-6.03,12.65-11.48,25.57-17.03,38.45c-0.78,1.81-0.73,3.97-1.06,5.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M456.65,865.95c-4.1-5.77-14.3-9.25-21.98-7.49c-17.93,4.1-24.61,14.66-21.05,32.99c0.34,1.73-0.27,3.65-0.43,5.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1072.51,542.68c-2.5,6.66-4.94,13.34-7.5,19.98c-4.36,11.3-10.11,21.79-17.37,31.55c-4.04,5.43-7.09,11.59-10.59,17.42"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M985.1,804.99c-3.16,2.17-6.06,5.4-9.55,6.28c-7.53,1.89-14.59,4.52-21.46,8.14c-12.75,6.71-26.81,8.11-40.91,9.1
                c-2.53,0.18-5.03,0.75-7.7,1.16c1.46,5.95,5.53,9.13,10.2,11.8c2.69,1.54,4.51,3.53,6.45,6.16c2.42,3.25,8.13,5.32,12.54,5.54
                c7.6,0.39,15.29-1.16,22.97-1.63c2.3-0.14,4.65,0.81,6.99,0.88c4.66,0.15,9.38,0.5,13.97-0.09c4.24-0.54,8.33-2.24,12.51-3.34
                c1.29-0.34,2.89-0.9,3.95-0.44c6.13,2.66,11.1-0.05,16.01-3.1c7.69-4.78,15.33-9.64,22.98-14.49c0.89-0.57,2.03-1.15,2.45-2.01
                c3.4-7.01,10.51-8.98,16.5-12.53c3.04-1.8,5.7-4.27,8.53-6.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1041.54,1062.31c-4.9,12.59-7.84,26.14-16.57,36.9c-5.63,6.94-12.11,13.18-18.1,19.61c-4.9-3.39-5.36-3.36-9.27,0.47
                c-1.26,1.24-2.2,2.81-3.54,3.95c-6.11,5.23-12.22,10.46-18.56,15.39c-0.78,0.61-2.96-0.58-5.62-1.2
                c-10.18,10.4-18.51,24.14-32.24,32.31"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M2.62,680.58c10.66,8.66,21.27,17.37,31.98,25.97c10.44,8.39,20.88,16.78,31.5,24.93c3.53,2.72,7.61,4.72,11.44,7.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M349.76,1354.1c-3.16,3.5-5.9,7.56-9.59,10.37c-6.37,4.84-7.26,10.8-4.82,17.59c2.05,5.68,2.17,10.14-2.3,15.26
                c-2.8,3.21-2.45,8.98-4.41,13.19c-1.84,3.97-4.85,7.39-7.35,11.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M67.06,975.87c-6.37-3.33-11.99-9.28-11.47-15.45c0.96-11.35-7.32-15.56-13.16-21.38c-11.79-11.73-24.92-21.93-39.81-29.63"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M77.55,360.3c2.33,6.16,5.05,12.21,6.91,18.51c3.61,12.24,6.3,24.77,10.27,36.89c1.87,5.7,5.8,10.72,8.8,16.05"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M959.62,1212.2c-6.16,2.17-12.41,4.11-18.46,6.54c-12.4,4.97-25.06,8.79-38.48,9.4c-3.81,0.17-7.66-0.28-11.49-0.45"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M444.16,1082.3c-3.83,0.17-8.15,1.57-11.4,0.28c-12.73-5.02-25.35-3.32-38.03-0.72c-5.57,1.14-10.99,2.98-16.51,4.39
                c-0.74,0.19-1.65-0.28-2.48-0.45"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M585.02,1241.18c-6.16-5.83-11.98-12.1-18.62-17.32c-3.75-2.95-8.75-4.48-13.4-6.02c-2.05-0.68-4.62,0.11-6.94,0.39
                c-1.02,0.12-2.07,1.04-2.99,0.9c-8.37-1.32-14.19,2.96-19.51,8.54c-1.22,1.28-2.63,2.37-3.24,2.91c-3.67-0.53-6.75-0.77-9.71-1.48
                c-1.45-0.35-2.78-1.47-4-2.44c-3.05-2.43-5.77-5.38-9.09-7.34c-2.49-1.46-5.47-1.33-5.94,2.86c-0.19,1.68-1.06,3.37-0.91,4.99
                c0.99,10.87-6,17.75-12.75,24.29c-2.57,2.49-7.36,3.04-11.28,1.16c-4.16-1.99-5.04-0.23-4.51,3.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M2.62,57.02c5.83,6.83,11.49,13.81,17.52,20.45c11.79,13,17.38,28.76,20.94,45.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1002.58,865.2c-2.16,2.58-4.38,5.12-6.48,7.76c-2.07,2.61-4.27,5.17-5.93,8.03c-1.28,2.2-3.49,3.79-2.48,7.55
                c0.65,2.42-1.88,6.7-4.21,8.75c-13.05,11.46-25.07,24.4-42.78,29.3c-4.51,1.25-8.53,4.59-12.5,7.37
                c-4.43,3.1-9.09,4.78-14.53,4.48c-3.49-0.19-6.99-0.04-11.54-0.04c4.65,7.31,11.03,10.15,17.13,12.8c4.57,1.98,9.91,2.18,14.9,3.2
                c1.34,0.27,3.34,0.22,3.89,1.06c4.22,6.51,10.72,6.23,17.09,5.85c5.35-0.31,10.66-1.27,15.99-1.92c0.66-0.08,1.53-0.28,1.97,0.03
                c4.54,3.28,9.93,2.67,15.01,3.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M608.99,152.95c-0.5,1.17-1.11,2.3-1.48,3.5c-1.42,4.68-1.39,9.63-6.89,12.71c-5.43,3.04-4.66,9.92-6.08,15.28
                c-1.83,6.95-3.27,14-5.07,20.97c-1.25,4.85-1.54,8.9,1.83,13.64c6.52,9.17,11.92,19.14,17.64,28.86
                c1.67,2.83,0.02,10.15-2.46,12.46c-1.81,1.69-3.66,3.33-5.53,5.03c-2.99-2.1-4.41-5.99-9.46-5.97c-2.15,0.01-4.76-4.64-6.41-7.6
                c-4.09-7.35-7.74-14.94-11.57-22.44"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M709.89,714.55c-5.35,0.84-9.59,2.99-10.47,9c-0.17,1.14-0.02,2.33-0.02,3.49c0,5-0.21,10,0.06,14.99
                c0.24,4.4-0.92,8.14-2.91,12.07c-2.29,4.51-3.47,9.59-5.14,14.42c-0.81,2.34-1.66,4.66-2.49,7"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M74.05,1.56c9.32-0.17,18.65-0.36,27.97-0.45c1-0.01,2.25,0.35,2.96,1c4.58,4.24,9.21,8.45,13.42,13.05
                c1.22,1.34,2.15,4.51,1.41,5.77c-1.98,3.35-1.45,6.18,0.23,9.1c2.03,3.52,1.87,7.27-0.67,9.87c-2.62,2.68-6.03,1.23-8.86-0.85
                c-0.78-0.57-1.92-0.82-2.45-1.54c-7.7-10.52-18.68-17.34-28.46-25.52c-1.87-1.56-3.19-3.85-4.52-5.95
                c-2.38-3.77-5.25-6.19-10.02-4.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M753.84,317.34c-2,3.5-3.78,7.14-6.04,10.46c-5.7,8.41-11.71,16.62-17.42,25.03c-7.28,10.71-10,23.15-12.99,35.46
                c-0.6,2.47-1.01,4.99-1.51,7.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M716.13,402.28c-0.08,3.16,0.74,6.71-0.41,9.43c-4.3,10.18-4.1,20.94-4.71,31.55c-0.45,7.79,0.21,15.65,0.38,23.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M162.46,165.94c-3.83-10.66-7.35-21.44-11.57-31.94c-4.28-10.65-9.08-21.09-13.97-31.48c-2.06-4.37-5.05-8.29-7.39-12.54
                c-4.75-8.61-1.6-17.31,0.02-25.96c0.14-0.74,0.91-1.37,1.38-2.03c5.69,2.05,6.84,1.4,9.07-4.97c5.69,1.28,8.42,4.09,11.93,13.51
                c2.94,7.89,4.82,16.22,8.22,23.89c3.03,6.85,7.21,13.26,11.47,19.46c1.58,2.3,4.85,3.43,7.34,5.1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M692.4,380.79c-2.66,4.33-5.3,8.68-8.02,12.98c-0.49,0.78-1.19,1.57-1.99,1.98c-18.17,9.51-23.54,26.81-27.48,44.99
                c-0.12,0.55-0.64,1.01-0.97,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M745.35,1446.04c7.87,1.32,15.75,2.63,23.89,3.99c-1.42,2.5-2.9,4.14-3.31,6.02c-0.9,4.18,1.19,5.38,5.39,5.6
                c12.34,0.65,24.66,1.71,36.96,2.94c5.7,0.57,11.33,1.93,16.99,2.93"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M903.68,1244.18c-5.6-4.33-12.06-2.53-17.39-0.73c-11.19,3.77-22.97,7.06-29.32,19.08c-1.62,3.07-6.44,4.42-9.72,6.64
                c-4.61,3.12-9.16,6.32-13.74,9.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M372.24,457.74c6.99,7.49,14.32,14.71,20.91,22.55c11.58,13.77,22.81,27.85,33.93,42c1.47,1.87,1.44,4.91,2.11,7.41"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M126.99,260.88c3.66,5.83,7.31,11.67,10.99,17.48c6.29,9.92,7.2,21.49,10,32.48c0.79,3.09,1.74,6.14,2.62,9.24
                c-8.89,0.45-24.34-7.28-26.11-20.23"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M233.88,760.52c9.99,11.66,20.3,23.06,29.85,35.07c5.96,7.49,10.76,15.9,16.12,23.88c0.52,0.77,1.31,1.35,1.98,2.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M754.34,954.39c0.17-6.16,1.15-12.44,0.31-18.46c-1.28-9.15-8.27-13.74-17.27-11.93c-7.08,1.42-13.64,4.09-17.06,11.37
                c-0.86,1.84-2.14,2.76-4.97,2.23c-5.89-1.11-11.15,4.28-10.45,9.79"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M349.26,1076.3c-4.33,0.17-9.07-0.74-12.9,0.73c-6.97,2.68-13.69,6.33-19.96,10.43c-3.56,2.33-5.43,6.38-4.77,11.35
                c0.31,2.37-1.42,4.94-1.77,7.48c-0.43,3.21-0.5,6.49-0.53,9.73c-0.02,2.08,0.3,4.16,0.46,6.25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M602,1362.1c-1.09,6.95-4.51,12.34-8.54,18.29c-8.86,13.07-22.65,23.25-25.42,40.17c-0.16,1-0.58,2.03-0.46,2.99
                c0.99,8-2.87,14.43-6.48,21.02c-1.92,3.5-3.39,7.55,0.01,10.9c3.68,3.62,9.08,1.52,10.92-3.93"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M732.86,855.46c3.62-12.25-1.94-22.06-9.12-30.87c-6.63-8.14-19.97-8.78-30.35-2.63c-2.24,1.33-4.65,2.36-6.98,3.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M194.92,616.62c-1-0.17-2.21-0.03-2.96-0.55c-2.92-2.03-6-3.98-8.47-6.5c-5.57-5.68-12.27-4.78-19.04-3.92
                c-2.33,0.3-2.76,1.89-2.52,3.98c0.75,6.47-0.24,12.11-5.89,16.6c-4.2,3.33-2.68,8.49-0.89,12.29c2.15,4.56,5.86,8.38,8.76,12.61
                c1.04,1.52,1.7,3.31,2.53,4.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M133.99,100.49c-1.97,4.89,1.03,9.05,2.03,13.48c3.42,15.18,8.88,29.5,18.5,41.94c1.35,1.74,3.29,3.03,4.95,4.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1043.04,1196.21c-9.7,7.08-5.78,17.79-6.82,27c-1.09,9.57-0.5,19.33-1.25,28.95c-1,12.85-2.85,25.63-3.81,38.49
                c-0.58,7.7-5.75,12.8-11.26,15.08c-9.85,4.08-17.09,11.71-26.3,16.4c-1.19,0.61-2.47,1.17-3.47,2.03
                c-1.15,0.99-3.06,2.56-2.84,3.41c0.73,2.81,3.19,3.56,5.79,2.51c4.56-1.85,9.13-3.73,13.48-6c8.16-4.26,17.89-6.16,23.41-14.5
                c1.73-2.62,2.42-5.94,3.59-8.93"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M360.75,215.91c11.99,12.16,24.33,23.99,35.81,36.61c5.75,6.31,10.22,13.75,12.89,22.27c1.19,3.79,5.08,6.74,7.74,10.07"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1064.02,1122.27c-1.66,1.67-3.69,3.1-4.92,5.04c-2.5,3.94-4.03,8.46-10.06,8.69c-1.56,0.06-3.64,2.85-4.38,4.8
                c-1.85,4.92-4.2,9.49-8.16,12.89c-6.46,5.55-10.71,12.57-14.45,20.03c-4.03,8.06-10.57,13.73-17.99,18.46
                c-3.39,2.16-7.16,3.76-10.41,6.11c-2.31,1.67-4.35,1.68-7.05,1.72c-2.52,0.04-5.42,2.26-7.42,4.26
                c-6.14,6.14-12.16,12.32-20.56,15.41"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M893.69,906.92c-2.5,7-4.32,14.33-7.64,20.91c-5.79,11.43-12.42,22.44-18.91,33.5c-1.08,1.84-3.25,3.05-4.91,4.55"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M279.84,828.47c1.66,4.16,3.34,8.32,4.99,12.49c2.52,6.38,5.02,12.78,7.74,19.69c-1.53,1.35-3.57,3.14-6.49,5.72
                c-7.64-7.36-15.22-14.66-22.8-21.95c-0.62,0.42-1.25,0.83-1.87,1.25c0.81,3.26,1.73,6.5,2.39,9.79c0.29,1.44,0.62,3.33-0.05,4.43
                c-3.11,5.13-6.19,10.36-10,14.96c-3.81,4.61-6.32,4.76-11.32,1c-4.79-3.6-8.98-7.99-13.59-11.85c-1.39-1.16-3.29-1.72-4.95-2.55
                c-0.33,1.83-1.32,3.84-0.88,5.47c1.72,6.4,3.96,12.66,5.85,19.02c1.78,5.96,3.35,11.98,5.02,17.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M398.71,961.38c-6.42-4.02-13.25-6.73-20.98-6.49c-13.16,0.4-18.34,7.3-15.83,20.45c0.61,3.2,3.17,6.03,4.84,9.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1035.55,1223.2c-4.16,3.33-9.39,5.94-12.23,10.17c-3.88,5.79-9.14,9.53-14.11,13.97c-7.35,6.57-14.59,13.59-20.36,21.52
                c-4.66,6.4-9.52,9.55-17.24,8.34c-1.14-0.18-2.33-0.03-3.49-0.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1075.5,655.6c-3.75,17.93-10.15,28.78-18.48,35.47c-8.05-3.79-15.57-1.58-22.98,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M113.51,371.8c4.16,12.49,9,24.81,12.22,37.54c1.8,7.12,0.91,14.92,1.27,22.42c0.19,3.94-2.48,4.01-4.39,2.37
                c-2.92-2.51-5.11-5.86-7.6-8.87c-1.51-1.82-3-3.66-4.5-5.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M288.33,928.4c-1.66-5.21-6.05-4.75-9.98-4.4c-3.71,0.33-6.25,4.36-7,9.9c-4.33,0-8.68-0.29-12.98,0.06
                c-10.26,0.83-14.48,7.63-10.96,17.42c0.62,1.74,1.64,3.34,2.47,5.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M672.42,940.89c-0.33-1.33-0.79-2.65-0.98-4c-1.34-9.44-7.3-14.44-15.99-17.01c-6.76-2-14.7-0.03-18.93,5.57
                c-5.4,7.15-6.41,17.83,1.53,23.82c2.58,1.94,6.23,2.45,9.4,3.62"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M254.86,1125.26c2.5,1.83,4.92,3.78,7.51,5.47c5.9,3.87,6.82,9.71,6.45,16.01c-0.39,6.48-6.09,6.99-10.45,9.03
                c-2.09,0.98-4.15,2.12-5.98,3.51c-2.63,2-4.7,5.08-7.6,6.28c-5.24,2.18-4.72,6.04-3.3,9.62c2.93,7.37,8.74,12.45,15.38,16.51
                c5.92,3.62,11.97,7.02,17.97,10.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M387.22,1064.81c-18.48-2.85-36.96-4.5-55.44,0.97c-0.25-0.59-0.5-1.19-0.74-1.78c2.41-2.23,4.83-4.45,8.46-7.81
                c-8.4,0.76-14.48-6.11-22.16-2.76c-3.11,1.36-5.67,3.95-8.55,5.87c-3.11,2.07-6.09,4.77-9.54,5.82c-4.02,1.22-5.67-1.43-3.93-5.3
                c0.58-1.29,1.18-2.58,1.77-3.86c-7.03-3.71-23.47-2.95-29.07,2.53c-5.74,5.61-11.43,1.99-17.14,1.74c-4.02-0.18-8-1.24-11.99-1.9"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M428.68,1155.74c-3.75,5.68-0.68,15.42,6.52,19.44c11.6,6.48,23.99,10.46,37.43,10.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M246.37,1072.05c-7.44,0.49-11.11,3.4-10.87,9.24c0.08,2.03,1.56,4,2.38,6c2.02,4.98,1.33,6.68-4.01,7.94
                c-7.94,1.88-16.18,2.74-23.89,5.26c-6,1.96-11.39,5.79-17.05,8.79"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M576.53,599.64c-1.17,8.99-2.57,17.96-3.43,26.99c-0.95,9.97-1.4,19.98-2.06,29.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1067.51,1010.85c4,7.16,6.97,15.2,12.21,21.29c9.18,10.69,19.77,20.16,29.72,30.19c1,1,1.69,2.31,2.52,3.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M357.25,637.61c6.33,8.83,12.66,17.65,18.98,26.48c2.85,3.98,6.35,7.69,8.31,12.07c2.14,4.8,3.09,10.2,4.12,15.42
                c1.4,7.11,2.55,14.29,3.48,21.48c0.41,3.12,0.01,6.35-0.02,10.43c-6.6-3.03-11.19-6.82-17.39-6c-3.62,0.48-6.26,2.95-5.97,6.56
                c0.73,9.09,3.52,17.61,8.09,25.42c1.61,2.75,4.55,4.73,6.88,7.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M593.01,915.91c-8.43,0.74-12.64,6.26-13.18,13.98c-0.23,3.29-2.61,6.43-4.09,9.81c-4.39-2.86-7.81-0.13-9.99,2.38
                c-2,2.3-2.98,6.15-2.97,9.31c0.01,2.67,2.09,5.33,3.26,8"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1022.56,1118.77c-6.76,14.22-13.82,28.29-24.01,40.44c-8.06,9.6-8.36,6.83-15.34,1.21c-5.65,7.21-9.63,16.44-20.08,18.31"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1101.98,1434.55c0.17-0.33,0.32-0.67,0.5-1c7.36-12.94,0.77-17.56-10.56-20.75c-9.34-2.63-19.25-3.23-28.9-4.73"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M135.99,1120.77c-0.3,6.23,2.4,11.23,7.04,14.93c10.29,8.2,19.73,17.69,32.87,21.69c1.99,0.61,3.37,3.18,5.04,4.85"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1073.51,527.69c-0.17,2.66-0.65,5.35-0.45,7.99c1.07,14.03,7.75,25.99,15.11,37.36c5.62,8.69,12.82,16.36,19.29,24.45
                c-0.73,2.41-1.28,5.43-2.55,8.11c-2.46,5.16-9.13,6.5-15.91,3.53c-2.22-0.97-4.45-1.91-6.67-2.86c-0.46,0.43-0.91,0.86-1.37,1.3
                c1.52,3.19,2.74,6.56,4.6,9.53c7.93,12.66,18.4,23.07,29.39,33.04c3.04,2.76,5.69,5.96,8.52,8.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M742.85,1248.18c7.99,3,16.61,4.95,23.82,9.25c9.74,5.82,22.17,7.47,29.08,17.76c3.71,5.52,7.02,11.31,10.53,16.96
                c0.95,1.53,1.92,3.06,3,4.49c7.97,10.51,16.12,20.89,23.88,31.55c2.6,3.58,4.24,7.86,6.85,12.85c1.65-3.28,2.49-5.48,3.78-7.38
                c1.24-1.84,2.63-4.13,4.5-4.89c6.18-2.53,12.47-5.08,18.96-6.44c5.1-1.06,8.76,2.9,11.35,6.86c2.43,3.71,4.29,7.85,6.02,11.96
                c1.27,3.02,1.47,5.89-2.92,7.02c-5.55,1.43-10.92,3.81-16.53,4.73c-3.14,0.51-6.7-0.88-9.94-1.84
                c-6.14-1.82-12.29-3.83-14.5-10.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1036.04,973.37c-6.5,15.18-14.56,29.21-28.21,39.3c-4.22-5.4-4.4-5.31-9.8-1.91c-1.66,1.04-3.94,1.11-5.94,1.57
                c-0.47,0.11-1.06-0.14-1.48,0.04c-5.34,2.3-10.77,4.42-15.96,7.03c-5.87,2.95-12.04,4.21-18.51,4.44"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M467.64,402.77c-3.91-1.32-7.93-2.11-9.71-6.91c-0.57-1.53-3.68-2.55-5.79-2.99c-4.77-0.97-9.79-0.9-14.43-2.22
                c-6.61-1.87-12.86-0.58-18.98,1.24c-6.08,1.81-6.06,2.17-7.53-4.11"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1079.5,1371.59c3.89,3.83,8.56,5.83,13.98,6.57c3.43,0.47,6.4,2.89,5.26,6.34c-0.95,2.86-3.77,4.06-7.83,2.85
                c-4.13-1.23-8.91-0.27-13.41-0.27c-5.87-0.01-5.87-0.01-4.98-6.61c-1.29-0.97-2.62-1.97-4.11-3.08c1.54-5.39,6.85-4.21,10.58-5.8"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1076.5,880.94c3.32,10.94,10.15,19.49,18.54,26.92c1.09,0.96,3.08,1.72,4.41,1.44c5-1.05,6.54,2.18,9.21,5.46
                c5.17,6.36,11.18,12.13,17.36,17.57c8.72,7.68,18.47,13.87,29.38,18.12c1.49,0.58,2.69,1.94,4.02,2.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M422.69,1436.05c-7.26,4.21-15.41,5.23-23.48,5.88c-10.12,0.81-20.3,0.78-30.46,1.11"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M554.55,284.86c-0.17,5.16-0.55,10.33-0.45,15.49c0.17,9.5,0.65,18.99,0.94,28.48c0.1,3.33,0.02,6.66,0.02,9.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M930.16,1047.32c-4.5,7.75-9.34,15.14-17.96,19.03c-1.62,0.73-3.13,1.82-4.49,2.98c-8.36,7.12-16.66,14.31-25.02,21.43
                c-0.47,0.4-1.31,0.36-1.97,0.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1075,987.36c4.56,11.75,14.47,17.75,25.03,22.87c1.93,0.94,4.55,0.45,7-0.49c3.31,2.2,6.4,4.89,9.98,6.48
                c5.47,2.43,11.22,4.26,16.95,6.05c2.85,0.89,3.41,0.05,4.59-5.76c7.49,2.24,14.51,7.13,22.86,4.83"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1058.52,799.49c1.8,8.13,6.72,13.79,13.33,18.69c2.59,1.92,3.17,6.35,5.22,9.24c2.87,4.04,6.31,7.67,9.38,11.57
                c1.05,1.33,1.78,2.93,2.51,4.48c0.76,1.62,1.18,3.42,2.05,4.97c8.78,15.72,18.18,30.97,32.95,41.97c8.6,6.41,17.3,12.67,25.96,19"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M279.84,197.42c3.33,11.25,6.66,22.5,10.25,34.63c-8.25,5.48-17.24-1.8-26.76-1.09c0.1,6.39,3.97,10.65,7.96,14.98
                c1.53,1.66,2.39,3.94,3.56,5.94c-2.66,0.33-5.34,0.6-9.04,1.36c-0.16,0.03-0.03,3.49,0.61,5.11c4.13,10.4,6.5,21.59,13.85,30.56
                c1.02,1.24,1.07,3.27,1.57,4.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M82.54,1020.84c12.82,11.16,25.85,22.09,38.37,33.57c6.49,5.95,11.93,13.04,18.14,19.32c2.57,2.59,6.11,4.18,8.88,6.62
                c4.33,3.81,8.62,7.72,12.45,12.02c2.77,3.11,4.75,6.92,7.08,10.42"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M276.84,194.92c4.5,2,8.96,4.08,13.49,5.98c7.71,3.22,13.82,8.52,19.5,14.48c4.71,4.95,9.52,9.81,14.52,14.45
                c1.81,1.68,4.32,2.62,7.57,4.52c-0.5-5.77-0.87-10.07-1.35-15.63c3.33,2.16,5.13,3.77,7.24,4.6c3.62,1.42,7.5,3.53,10.58-1.34
                c0.46-0.72,2.68-0.85,3.85-0.49c3.14,0.97,5.52,0.23,6.97-2.61c1.32-2.57,0.47-4.7-1.37-7.04c-4.42-5.62-8.43-11.58-12.49-17.48
                c-0.17-0.25,0.84-1.33,1.87-2.86c4.75,6.61,13.75,10.05,11.04,20.4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M502.6,1432.05c-12.32,2.5-24.69,4.77-36.94,7.6c-5.14,1.19-10.01,3.56-15.01,5.39"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M43.08,726.55c15.15,13.49,30.34,26.94,45.4,40.53c1.73,1.56,2.72,3.94,4.05,5.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M414.19,897.93c-2.16-0.83-4.32-1.7-6.5-2.49c-6.01-2.2-10.55,0.18-11.38,6.5c-0.49,3.71-1.87,5.76-5.59,6.54
                c-3.62,0.76-6.29,2.74-6.57,6.93c-0.05,0.7-0.83,1.47-1.45,1.98c-3.19,2.61-5.36,5.74-5.48,10.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1055.03,885.93c1.5,1.67,2.76,3.67,4.55,4.92c3.05,2.15,3.34,4.78,0.46,7.58c-6.53,6.35-10.84,14.26-15.68,21.85
                c-2.77,4.34-7.47,7.45-11.3,11.11"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1095.48,1459.03c5.66-0.83,11.33-1.64,16.98-2.52c1.19-0.19,2.36-0.57,3.5-0.99c5.8-2.12,6.79-4.86,2.41-8.88
                c-4.92-4.5-10.56-8.22-15.62-12.08c-8.19,3.51-6.5,10.85-8.12,16.51c-0.65,2.26-0.18,5.06,0.44,7.44
                c1.61,6.19,0.65,7.21-6.06,8.09c-13.11,1.71-26.07,1.24-38.97-1.57"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M474.63,311.84c-3.59,4.96-3.24,10.39-2.05,16c0.34,1.59,0.68,3.69-0.05,4.93c-5.64,9.6-11.55,19.05-17.38,28.54
                c-1.5-0.5-3-1-4.5-1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1100.48,811.49c0.67,1.33,1,3.05,2.05,3.94c11.39,9.67,23.57,18.12,37.35,24.18c2.41,1.06,4.05,3.86,6.05,5.86"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M63.56,266.37c-1.29,5.43,2.14,9.56,4.66,13.38c6.87,10.38,13.02,20.98,16.36,33.08c0.53,1.92,1.62,3.68,2.45,5.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M673.42,1060.31c6.37,10.02,4.63,20.94-2.45,28.53c-4.43,4.75-8.54,9.82-12.46,15c-1.77,2.33-2.74,5.27-4.07,7.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M470.64,699.07c-2.83-2.17-5.6-4.41-8.51-6.46c-1.82-1.28-3.85-2.26-6.47-3.78c0,2.04-0.35,3.51,0.06,4.73
                c2.55,7.7,5.37,15.31,7.9,23.01c1.91,5.8,6.28,9.42,10.24,13.74c4.11,4.49,6.13,10.98,8.73,16.75c4.48,9.93,8.7,19.97,13.03,29.96
                "/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1116.96,715.55c2.33,2.17,4.45,4.63,7.03,6.44c8.65,6.09,16.93,12.25,20.64,22.97c1.02,2.95,4.46,5.06,6.79,7.56
                c2.17,2.33,4.33,4.66,6.49,6.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M154.47,1098.78c7.99,2.83,15.91,5.92,24.01,8.39c4.15,1.27,8.67,1.23,12.94,2.19c2.43,0.55,4.6,2.4,7.03,2.82
                c5.61,0.97,11.29,1.64,16.97,2.05c8.72,0.62,16.42,2.8,24.22,7.64c8.14,5.05,14.26,11.14,19.19,18.92
                c0.75,1.18,1.03,2.64,1.46,3.78c-5.44,9.14-14.97,10.37-23.92,12.35c-7.95,1.75-14.04,6.14-19.46,11.83"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1161.41,953.89c-3.12,0.74-7.55,0.05-6.13,5.78c0.08,0.32-1.9,1.68-2.85,1.61c-2.52-0.19-4.99-1.08-7.51-1.31
                c-1.29-0.12-3.57,0.25-3.84,0.98c-1.7,4.57-5.4,5.17-9.11,4.32c-5.11-1.17-10.02-3.21-15.01-4.87"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1084.99,722.05c7.66,6.16,15.19,12.49,23.01,18.45c10.62,8.08,22.17,14.28,35.42,17.1c5.96,1.27,11.67,3.97,18,2.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M776.82,922.41c1.01-5.66-1.32-8.6-7.98-10.53c-2.97-0.86-5.82-2.16-8.72-3.26c0.48-0.36,0.95-0.71,1.43-1.07
                c-0.96-3.13-1.91-6.25-3.01-9.83c4.01-4.07,3.97-12.95-0.69-19.78c-5.97-8.76-16.03-11.52-22.51,0.5
                c-0.36,0.66-0.98,1.18-1.48,1.76"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M936.15,1283.65c2.5-2.66,5.19-5.18,7.41-8.06c1.13-1.47,2.48-4.1,1.9-5.35c-2.94-6.34-6.22-12.58-9.98-18.46
                c-1.01-1.57-4.09-2.05-6.33-2.55c-3.95-0.88-8.39-0.53-11.9-2.22c-11.23-5.41-20.52-1.06-25.38,8.99
                c-3.03,6.27-7.39,12.57-15.59,14.87c-6.96,1.95-8.28,7.01-5.06,13.27"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M604.5,1304.14c-0.17,4.66-0.49,9.33-0.46,13.99c0.05,7.05,4.23,12.01,10.93,14.06c3.37,1.03,6.61,4.42,9.49-0.58
                c0.14-0.25,0.02-0.66,0.02-0.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M627.47,1320.63c-1.51-4.47-4.68-6.12-8.94-4.36c-1.67,0.69-3.38,2.71-3.8,4.43c-0.24,1,1.84,3.03,3.3,3.83
                c1.74,0.96,3.94,1.11,5.93,1.61"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M68.06,966.38c6.83,6.5,13.9,12.76,20.33,19.63c1.96,2.1,2.16,5.85,3.14,8.85c3.26,9.94,6.47,19.91,16.48,25.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M652.45,1354.1c-0.5,4.83-1.08,9.65-1.44,14.49c-0.08,1.14,0.6,2.33,0.94,3.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M648.45,1381.08c-0.17,0.5-0.62,1.14-0.46,1.48c4.67,9.74-1.58,18.66-2.18,27.99c-0.39,5.95-6.71,8.17-9.91,12.45
                c-2.17,2.91-4.93,5.38-7.43,8.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M425.68,326.33c-3.83-1.83-7.72-3.55-11.47-5.53c-3.62-1.91-5.08-1.15-4.55,3.04c0.12,0.96-0.21,1.98-0.33,2.93
                c-4.78-1.33-9.38-3.18-14.14-3.77c-4.41-0.54-9.42-5.59-13.11,1.11c-5.37-3.24-10.29-6.36-15.37-9.2
                c-1.61-0.9-3.63-1.06-5.46-1.56"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1066.01,1435.55c-3.5,1-7.05,1.83-10.46,3.09c-1.2,0.44-2.81,1.91-2.81,2.91s1.6,2.39,2.81,2.94c9.03,4.11,18.61,6.12,28.44,7.06
                "/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M429.18,524.19c8.51,1.81,13.02,8.67,11.55,17c-0.66,3.77-0.46,7.43,3.91,9.53c6.51,3.13,11.56,9.24,19.5,9.51
                c0.35,0.01,0.67,0.94,1,1.44"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M447.66,1209.21c-3.5-0.17-7.18,0.32-10.45-0.63c-7.14-2.08-14.37-1.05-21.49-1.69c-8.62-0.78-16.99-4.09-25.51-6.13
                c-3.77-0.9-7.65-1.37-11.48-2.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M903.18,1044.32c-3.66,7.83-6.57,16.14-11.18,23.36c-5.68,8.88-11.01,17.71-13.29,28.1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1055.52,730.04c-1.83,4.16-3.96,8.22-5.44,12.51c-3.84,11.19-13.34,16.04-23.11,19.76c-9.47,3.6-19.44,6.59-29.43,7.85
                c-7.71,0.97-16.47,2.72-23.43-3.65"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M111.01,1010.85c22.4,10.16,41.62,24.61,57.96,42.92c-2.66,2.89-3.74,5.9-3.17,10.07c0.98,7.11-3.1,10.51-10.32,8.9
                c-4.92-1.1-9.65-3-14.51-4.38c-1.59-0.45-3.32-0.38-4.98-0.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1117.96,661.09c0.5,1.83,1.06,3.65,1.48,5.5c0.56,2.48,2.27,5.78,1.28,7.35c-3.18,5.07,0.88,6.83,3.2,9.66
                c8.5,10.37,20.29,16.7,30.47,24.99c2.46,2,4.68,4.31,7.01,6.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M614.98,382.29c-4.33-1.67-8.57-4.34-13.01-4.74c-5.63-0.51-9.8-3.35-14.54-5.58c-3.45-1.63-7.11-0.77-10.05,2.71
                c-1.54,1.83-4.52,2.45-6.85,3.61"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1129.45,1138.76c7.16,11.16,14.09,22.47,21.6,33.39c2.76,4.01,6.87,7.08,10.37,10.57"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M890.7,1197.21c-5.99-5.33-12.18-10.46-17.93-16.04c-5.91-5.74-11.4-11.91-17.03-17.94c-3.38-3.62-6.62-7.37-10.01-10.97
                c-6.53-6.94-14.25-8.62-23.52-6.22c-3.66,0.95-7.93-0.66-11.93-0.75c-9.35-0.21-18.04,0.02-25.71,7.71
                c-7.73,7.75-17.43,13.54-26.2,20.27c-0.9,0.69-1.36,1.96-2.03,2.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M917.17,1421.06c-2.5-0.33-4.99-0.69-7.49-0.99c-5.43-0.65-7.51,1.03-9.64,5.93c-0.96,2.21-3.36,3.92-5.38,5.52
                c-3.61,2.86-3.55,4.96,0.54,7.03c3.65,1.85,7.32,3.67,10.99,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1081,784.01c2.16,3,4.48,5.9,6.45,9.02c2.02,3.21,3.71,6.64,5.54,9.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1101.48,810.99c1.33,0.67,2.8,1.15,3.97,2.03c7.88,5.85,16.86,8.43,26.5,8.92c2.99,0.15,6.32,0.53,6.23-4.45
                c-0.02-0.97,3.09-1.99,4.75-2.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M423.68,1018.34c-0.67-3.16-0.65-6.7-2.15-9.41c-2.58-4.68-7.49-4.79-12.34-4.62c-8.02,0.28-18.83,11.09-16.48,21.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M320.79,829.97c2.66,3.33,5.03,6.96,8.04,9.94c10.73,10.63,18.58,23.41,25.03,36.72c2.96,6.12,7.31,10.98,10.38,16.8"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M888.7,1123.27c-5.83-2.33-11.76-4.43-17.44-7.08c-3.97-1.85-7.04-0.87-9.47,2.12c-3.31,4.07-7.71,5.6-12.51,7.05
                c-9.48,2.86-16.62,8.4-18.51,18.89"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1006.08,674.58c-9.05,2.79-14.12,11-21.13,16.43c-8.38,6.48-17.44,10.52-26.8,14.57c-1.43,0.62-2.68,1.64-4.01,2.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1000.08,1468.52c-0.17-0.67-0.13-1.51-0.53-1.97c-3.2-3.63-5.66-7.71-3.36-12.47c2.8-5.79,8.05-8.04,14.36-9.16
                c15.56-2.77,26.17,5.83,36.41,15.18c4.29,3.92,2.84,8.22-2.94,9.34c-5.26,1.02-10.64,1.42-15.97,2.07
                c-4.33,0.52-8.65,1.25-12.99,1.42c-2.98,0.11-5.99-0.58-8.99-0.91"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1057.52,1388.08c-2,13.66-4.1,27.3-5.93,40.98c-0.55,4.14-4.46,3.91-6.56,5.99c-1.17-1.5-2.76-2.83-3.43-4.52
                c-5.24-13.24-15.7-19.47-29.05-21.33c-5.4-0.75-10.98-0.12-16.64-0.12c4.73-13.19,14.45-18.99,27.65-10.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M925.16,335.82c5.64,6.51,2.77,14.21,3.06,21.48c0.07,1.85,0.38,4.19,1.53,5.41c5.26,5.55,10.87,10.78,17.2,16.95
                c0.74-1.15,1.46-2.26,2.19-3.37"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M889.2,1249.18c-3.66,3.33-7.23,6.77-11.03,9.94c-1.75,1.46-4.12,2.17-5.91,3.59c-3.13,2.5-6.62,4.86-8.88,8.05
                c-3.08,4.34-4.89,9.56-7.83,14.01c-0.65,0.98-4,0.88-5.76,0.27c-5.92-2.05-11.63-4.72-17.56-6.78c-1.58-0.55-3.64,0.15-5.45,0.4
                c-7.44,1.05-14.74,0.74-22.01-1.41c-2.14-0.63-4.63-0.1-6.96-0.1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M325.29,1139.25c2.13,9.37,7.3,17.17,14.65,22.76c5.82,4.43,13.84,5.98,19.66,10.41c10.22,7.8,22.62,5.24,33.59,8.86
                c4.8,1.58,9.35,3.93,14.01,5.93"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M777.32,976.37c2.01-10.24-1.85-12.29-8.52-13.88c-7.22-1.72-14.35,4.37-15.48,12.38c-0.19,1.35-0.65,2.67-0.98,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M474.63,593.64c-4.66-2.33-9.48-4.4-13.95-7.06c-5.66-3.39-10.92-7.44-16.59-10.81c-1.55-0.92-4.57-1.29-5.76-0.39
                c-1.38,1.05-2.57,4.21-1.97,5.69c1.97,4.88,4.57,9.55,7.37,14.03c2.32,3.71,5.63,6.83,7.8,10.61c1.62,2.81,1.95,6.33,3.21,9.39
                c0.44,1.06,1.91,1.7,2.91,2.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M109.01,414.77c-0.12,10.61,2.97,20.7,4.86,31c0.95,5.19,0.47,10.63,0.64,15.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M947.14,1462.53c-2.5,2.33-5.2,4.49-7.44,7.04c-2.94,3.34-1.79,7.16,2.47,8.39c2.4,0.69,4.97,0.96,7.47,1.02
                c6.49,0.14,13.02,0.45,19.46-0.12c3.4-0.3,6.65-2.3,9.98-3.53c0.12-0.76,0.24-1.53,0.35-2.29c-3.6-3-7.21-6-10.81-9.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M377.73,930.4c-0.5-1-0.75-2.31-1.54-2.95c-12.25-9.84-25.72-10.77-39.91-5.02c-10.13,4.1-19.84,20.79-18.42,31.44
                c0.08,0.58,1.26,1.01,1.92,1.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M397.21,1284.65c0.67,5.5,2.18,11.05,1.81,16.48c-0.67,9.71-2.41,19.34-3.87,28.98c-0.26,1.73-1.27,3.34-1.94,5.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M970.61,1460.03c7.73-5.91,8.92-12.48,6.7-21.54c-3.23-13.18-12.86-16.51-24.17-18.51c-2.92-0.52-6.23-1.25-8.4-3.05
                c-6.97-5.77-15.14-5.48-23.06-4.65c-5.85,0.61-12.17-0.05-17.54,3.72c-3.35,2.35-7.1,4.14-11,6.37c-1.51-1.06-3.48-2.44-5.44-3.81
                "/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M782.81,851.96c4.86-10.23,12.06-17.86,23.48-20.46c14.2-3.23,24.72,5.63,28.26,17.52c1.98,6.65,1.87,13.93,2.7,20.92"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1069.51,817.98c-0.83,6.83-1.85,13.64-2.44,20.49c-0.58,6.64-0.72,13.32-1.05,19.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M743.35,1480.51c3.42,1.05,6.83,2.1,11.01,3.39c-2.02,2.41-3.33,3.97-4.58,5.47c2.91,4.88,7.04,6.75,12.56,6.17
                c5.14-0.54,10.32-0.93,15.48-0.97c3.83-0.03,7.66,0.59,11.49,0.93"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1001.08,728.05c2.29,0.2,4.59,0.39,7.28,0.62c-0.62,1.88-1.31,2.9-1.23,3.87c0.79,10.23-6.51,15.51-13.12,20.9
                c-5.46,4.45-11.59,8.08-17.39,12.1c-0.52,0.36-0.85,0.98-1.26,1.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M358.75,1445.04c-8.85,1.45-15.48,7.02-22.52,11.94c-5.64,3.94-11.62,7.38-17.44,11.05"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M247.37,954.39c-4.33-0.67-8.64-1.74-12.99-1.91c-7.23-0.29-12.05,4.68-11.34,11.89c0.32,3.25,2.19,6.34,3.35,9.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M360.75,313.84c2.33,6,5.06,11.87,6.89,18.02c2.25,7.54,3.75,15.29,5.61,22.95c0.25,1.02,0.65,2,0.99,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M160.46,1478.52c-9.99,4.83-20.03,9.56-29.94,14.55c-2.86,1.44-5.36,3.6-8.02,5.44"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M653.44,189.43c1.93,2.82,6.08,2.31,7.76,6.38c1.95,4.69,3.27,8.9,2.9,14.12c-0.52,7.28,0.16,14.64,0.33,21.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M751.34,1441.04c8.82,0.83,17.65,1.64,26.47,2.53c0.86,0.09,2.18,0.42,2.42,1c3.44,8.29,11.49,7.38,18.09,8.83
                c6.53,1.43,13.29,1.8,19.95,2.63"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M526.08,361.3c8.06-6.16,8.94-6.01,16.58,0.39c1.34,1.12,3.99,1.32,5.88,0.99c3.4-0.58,6.72-1.76,10-2.91
                c3.05-1.08,5.89-3.03,9.1-0.07c0.64,0.59,2.56-0.25,3.9-0.39c4.83-0.51,9.68-1.56,14.48-1.35c4.9,0.21,8.91,2.89,11.51,7.33
                c1.95,3.35,4.09,5.21,8.56,3.18c3.23-1.47,5.85,1.42,6.8,4.35c1.42,4.36,2.11,8.96,3.11,13.45"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M268.35,1019.34c-1.33-4-2.09-8.33-4.18-11.89c-1.68-2.88-4.4-5.83-8.82-4.62c-4.93,1.34-5.78,5.56-6.34,9.53
                c-0.31,2.22,0.85,4.64,1.35,6.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M682.41,435.75c-1.33,12.99-2.66,25.98-4,38.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M619.48,208.91c-0.83,6.66-1.91,13.31-2.43,19.99c-0.49,6.3-0.4,12.65-0.57,18.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M344.27,1083.79c-5.66,4-11.26,8.09-17,11.97c-7.69,5.19-12.21,12.93-16.97,20.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M115.01,462.73c-1.84-0.82-3.67-1.64-6.66-2.97c-0.47,3.52-1.06,6.73-1.28,9.97c-0.21,3.03,0.56,6.89-3.56,7.29
                c-2.56,0.25-5.77-0.86-7.85-2.46c-2.36-1.81-3.76-4.86-5.63-7.33c-0.93-1.22-1.99-2.34-2.98-3.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M184.43,321.83c-0.25,4.77,0.18,9.29,2.93,13.54c3.09,4.79,1.59,7.13-4.43,7.42c-3.31,0.16-6.96-0.85-8.17,3.98
                c-0.17,0.67-2.81,0.97-4.31,1c-6.62,0.14-7.6,1.49-4.42,7.5c1.95,3.7,4.46,7.13,6.95,10.51c5.08,6.89,10.32,13.66,15.49,20.48
                c-2.79,2.6-3.06,2.48-12.52-5.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1161.41,779.01c-1.33,0.5-2.7,1.49-3.99,1.4c-2.87-0.2-4.03,1.21-5.06,3.57c-1.27,2.91-3.85,4.2-6.92,3.47
                c-4.02-0.96-7.65-0.6-11.56,0.89c-2.5,0.96-5.6,0.33-8.43,0.41"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M365.75,1040.32c-1.05-5.92-3.48-10.96-8.99-13.98c-5.8-3.19-13.05-3.11-16.99,4.49c-0.87,1.68-1.89,3.3-2.98,5.18
                c-0.78-0.52-1.59-1.18-2.51-1.66c-6.34-3.26-7.05-3.01-11.52,3.46c-0.37,0.54-1.29,0.69-1.96,1.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M717.88,1359.6c6.84,7.13,8.76,16.49,11.48,25.48c3.02,9.98,6.06,19.97,8.97,29.98c1.39,4.8,2.39,9.41-2.86,13.14
                c-1.88,1.33-2.97,4.39-3.52,6.86c-2.09,9.51-7.31,18.31-6.59,28.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M608.99,152.2c0.67,0.25,1.93,0.66,1.91,0.73c-2,7.64,5.55,15.4-0.36,23.05c-1.94,2.5-1.71,5.83,1.01,7.34
                c9.41,5.24,8.51,14,8.43,22.6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M263.1,986.86c-2.25-2.33-4.67-4.52-6.71-7.02c-5.07-6.22-11.6-9.79-19.51-9.35c-13.65,0.75-18.61,13.42-17.43,22.36
                c1.43,10.83,7.39,19.01,15.92,25.5c1.03,0.79,2,1.66,3,2.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M648.45,385.79c-1.33-1-2.62-2.79-4-2.87c-7.75-0.45-15.39-0.55-22.58,3.68c-2.3,1.35-5.88,0.53-8.88,0.68
                c-3.33,0.17-6.69,0.11-9.98,0.54c-10.06,1.32-17.81,8.4-27.37,11.3c-3.66,1.11-5.94,1.06-9.14-0.26
                c-4.09-1.69-8.45-2.31-12.45,0.65"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M38.09,602.63c13.58,9.79,25.8,21.05,36.46,33.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M436.67,619.62c0.5,2.83,0.82,5.71,1.53,8.49c2.41,9.51,4.89,19.01,7.5,28.48c0.93,3.39,2.29,6.67,3.46,10"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1161.41,869.95c-10.16,4.65-18.76-1.13-27.36-4.76c-3.45-1.45-5.89-2.83-10.11-2.77c-4.46,0.06-9.31-3.67-13.38-6.59
                c-3.78-2.71-6.92-6.39-10.04-9.9c-3.02-3.4-6.43-5.26-11.03-4.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M983.6,1401.57c0.07-7.37-4.11-12.79-8.61-17.89c-1.05-1.19-3.53-1.17-5.38-1.59c-2.48-0.56-5.17-0.6-7.45-1.6
                c-5.2-2.28-7.87-1.87-10.97,3.12c-2.63,4.22-4.77,8.7-4.61,13.96c0.06,1.99-0.6,4-0.94,5.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M213.9,1477.02c-11.31,7.34-23.84,11.45-36.96,13.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M923.66,982.87c-5.49,11.66-11,23.31-16.45,34.99c-0.26,0.55-0.03,1.32-0.03,1.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M648.95,1301.14c-2.66,7.33-4.92,14.84-8.14,21.92c-2.05,4.52-5.51,8.39-8.35,12.56"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M537.07,486.22c-1.35,4.04-2.69,8.08-4.42,13.26c7.19,5.09,15.04,10.66,22.9,16.22"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M848.24,1349.61c0.17,0.83,0.13,1.79,0.53,2.48c5.95,10.19,12.21,20.2,17.82,30.57c2.01,3.71,2.39,8.28,3.68,12.4
                c0.34,1.1,1.28,2.01,1.95,3.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M822.77,240.89c-1.8-6.5,0.14-13.1-0.37-19.46c-0.56-7.12-3.93-14.03-6.14-21.01c-0.54-1.71-1.88-3.34-1.87-5
                c0.02-5.12-3.8-3.77-6.07-3.33c-3.06,0.6-4.21,3.94-2.9,6.25c5.85,10.31,5.21,21.52,4.67,32.54c-0.33,6.76,2.26,12.66,3.19,19"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M925.16,954.39c-4.99,9.16-10.12,18.25-14.92,27.51c-1.88,3.63-3.06,7.63-4.56,11.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M710.88,1476.52c-6.09-6.62-14.12-4.22-21.48-4.33c-2.48-0.04-5.02,1.77-7.48,2.85c-2.93,1.29-3.54,3.92-2.38,6.42
                c0.84,1.81,2.87,3.06,4.37,4.56c1.83,1.83,3.86,3.51,5.43,5.54c1.29,1.67,1.39,3.17,4.65,1.96c5.48-2.03,11.56-2.42,17.39-3.51
                c0.83-0.16,1.86-0.07,2.47-0.54c5.42-4.15,11-0.99,16.51-0.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M393.72,1213.2c-4.21,0.68-5.28,2.54-3.07,6.54c2.48,4.49,4.3,9.34,6.59,13.94c2.33,4.67,5.58,7.72,11.46,7.59
                c12.67-0.28,18.61,5.43,20.02,18.4c0.15,1.37,0.95,2.67,1.45,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M933.65,1288.15c-7.83,3.31-15.8,1.74-23.43-0.17c-8.08-2.01-16.04-0.6-24.01-0.51c-1.85,0.02-5.45,4.41-5.08,6.09
                c0.56,2.52,3.75,6.04,6.11,6.27c6.21,0.61,13.01,1.71,17.57-5.1c1.92-2.87,14.96-2.16,17.34,0.43c2.41,2.62,0.63,4.44-1.49,5.96
                c-4.9,3.54-10.96,2.47-17.47-2.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M317.3,953.39c-3.5-0.83-6.99-2.33-10.49-2.36c-6.8-0.06-10.84,4.54-11,11.35c-0.06,2.5-0.32,5-0.48,7.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M588.51,1346.61c-2.13,2.11-2.79,4.31-2.13,7.52c1.56,7.58,2.39,15.31,3.69,22.95c0.21,1.24,1.27,2.34,1.93,3.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M639.46,1452.03c9.49,0.33,18.98,0.63,28.47,1.03c2.1,0.09,4.18,0.52,7.32,0.92c-2.29,1.76-3.64,2.8-6.04,4.64
                c3.5,1.17,6.06,2.59,8.72,2.81c14.47,1.19,28.95,2.36,43.45,2.99c8.47,0.36,9.41-0.79,9.99-7.39"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1029.05,695.57c-0.55,8.63-4.92,15.32-11.03,20.94c-4.09,3.76-8.61,7.04-12.94,10.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M132.49,272.37c0.33,2.17,0.69,4.33,0.99,6.5c0.21,1.49,0.34,3,0.51,4.5c-1.5-0.17-3.17,0.03-4.46-0.57
                c-3.17-1.49-6.16-3.35-10.06-5.52c-0.87,3.26-1.78,6.7-2.69,10.11c-6.71-0.42-10.09-5.15-13.76-9.51
                c-4.8-5.69-9.59-11.38-14.53-16.95c-1-1.13-2.62-1.71-3.95-2.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M704.39,943.39c-0.83-0.83-1.7-1.64-2.49-2.5c-7.26-7.93-11.57-7.56-17.49,1.5c-8.58-3.89-12.12-2.84-13.49,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M918.17,1257.17c2.83,2.54,4.53,5.63,3.95,9.49c-0.58,3.86,1.14,6.82,4.1,8.38c5.48,2.88,7.46,7.16,8.07,13.1
                c0.66,6.39,8.93,10.28,15.82,7.97c2.25-0.75,4.38-1.88,6.5-2.98c3.15-1.64,3.28-2.97,1.01-7.98c0.17-0.17,0.31-0.45,0.5-0.48
                c3.76-0.61,7.95-0.61,8.96-5.52c0.89-4.32-1.52-7.47-4.53-9.92c-3.33-2.71-7.29-3.51-11.43-1.56c-1.68,0.79-3.33,1.66-5,2.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M583.52,1272.16c-0.83-2.66-2.03-5.28-2.42-8.01c-0.77-5.45-4.99-7.31-9.11-8.87c-3.78-1.42-6.64,1.13-8.91,3.91
                c-1.51,1.85-4.08,4.22-3.77,5.92c0.96,5.29,2.14,10.96,4.94,15.41c5.02,7.95,7.54,10.27,5.98,18.56
                c-1.71,9.14,1.46,17.68,0.91,26.55c-0.17,2.77,0.79,5.12,3.75,7.19c5.17,3.61,9.31,8.65,14.19,12.72
                c2.75,2.29,5.85,5.15,9.93,2.07"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M724.37,1246.68c-8.66,2.17-17.25,4.64-26,6.37c-4.52,0.89-9.33,0.18-13.94,0.75c-2.09,0.26-4.01,1.87-6.01,2.87"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M454.15,615.62c-1.66-1-3.61-1.71-4.95-3.04c-7.75-7.73-15.33-15.64-23.05-23.41c-1.66-1.67-3.64-3.02-5.47-4.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M870.72,1028.33c11.21,8.66,23.28,15.12,37.96,15.01c1.5-0.01,3.14-0.04,4.47,0.53c11.04,4.7,21.58,2.75,31.99-2.04
                c4.13-1.9,8.32-3.68,12.48-5.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M780.81,862.45c1.18-5.96,0.42-11.52-2.39-17.04c-1.9-3.72-4.04-3.8-6.54-1.38c-2.45,2.37-4.39,5.27-6.55,7.93"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M411.7,384.79c-5.07,6.39-10.56,11.97-19.48,12.54c-6.36,0.41-12.62,1.42-16.03,9.14c16.71,10.45,33.35,20.87,49.99,31.28"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1008.07,1444.54c-0.64-6.72-5.43-9.51-11-11.45c-0.89-0.31-2.16-0.39-2.95,0.01c-5.05,2.55-10.02,5.27-15.01,7.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M922.66,1340.11c2.76-5.26,2.97-10.18-0.34-15.59c-2.02-3.3-2.24-7.67-3.73-11.36c-1.35-3.34-4.33-4.48-7.92-4.58
                c-4-0.11-8.02-1.09-11.98-0.85c-9.8,0.57-12.5,8.43-15.53,15.88c-0.92,2.26-2.29,4.35-3.46,6.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M479.63,417.26c8.82,6.5,17.7,12.92,26.45,19.51c6.16,4.64,8.48,3.98,9.38-4.04c0.92-8.26,0.8-16.64,1.13-24.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M35.09,620.62c9.66,6.83,19.34,13.62,28.93,20.54c1.1,0.79,1.7,2.28,2.53,3.45"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M974.61,960.88c-3.65,6.5-7.41,12.85-13.96,17.03c-3.49,2.24-6.67,2.84-10.02-0.04c-2.68-2.31-5.51-2.45-8.5-0.52
                c-1.44,0.93-3.11,1.52-4.46,2.55c-3.8,2.89-8.06,4.24-12.46,2.36c-4.07-1.73-6.43-0.13-9.09,2.55c-2.48,2.5-4.53,6.03-8.93,5.55"
                />
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M449.66,366.8c-1.66,3-3.11,6.15-5.05,8.96c-3.11,4.51-5.65,4.94-9.35,0.95c-3.79-4.09-8.03-6.44-13.58-6.41"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1052.03,1430.55c2.16-0.67,4.37-1.22,6.49-2.02c14.41-5.42,26.57-2.34,36.47,9.51c0.45,0.54,0.99,1,1.49,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M342.77,1226.69c-4-1.83-8.09-3.47-11.96-5.54c-5.59-2.99-10.99-6.32-16.53-9.4c-0.64-0.35-1.64-0.05-2.47-0.05"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1023.06,305.34c0.33-0.83,1.19-1.92,0.92-2.46c-4.37-8.89-8.19-18.21-16.94-23.97c-0.89-0.59-2.29-0.39-3.46-0.56"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1029.55,1248.18c-2.39,5.4-5.81,9.66-10.85,13.19c-4.82,3.39-8.67,8.29-12.55,12.86c-2.03,2.4-4.12,3.22-7.08,2.96
                c-5.15-0.45-10.32-0.7-15.48-1.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M399.21,422.26c-1.72,4.02-0.07,6.84,3.52,8.45c6.8,3.05,11.09,8.2,15.21,14.37c4.93,7.37,11.76,13.47,17.72,20.16
                c3.32,3.72,5.55,8.61,11,9.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M906.68,1382.58c2-1.63,4-3.26,5.96-4.86c-5.16-7.07-15.2-7.72-23.44-2.13"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1052.03,1394.07c-2,5.33-4.37,10.56-5.88,16.02c-1.24,4.51-1.45,9.29-2.12,13.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1023.56,1413.56c4.23,7.32,1.66,13.14-3.33,19.13c-2.46,2.95-3.16,7.36-4.66,11.1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M856.23,1222.2c8.99,0.67,17.99,1.28,26.97,2.05c1.7,0.15,3.33,0.95,5,1.45"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M516.84,329.33c-0.67,6.39-1.16,12.62,3.66,18.06c2.33,2.64,3.52,5.86,2.86,9.96c-0.36,2.25,1.5,5.51,3.34,7.33
                c3.65,3.61,4.06,8.01,5.02,12.58c1.02,4.8,3.18,9.36,4.85,14.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M876.21,1492.51c2.44-1.68,4.88-3.36,7.52-5.17c-2.72-4.83-5.16-9.19-8.16-14.51c2.9-2.05,6.06-4.82,9.69-6.69
                c4.09-2.12,8.6-3.45,12.93-5.1c5.52-2.1,9.71,0.3,9.93,6c0.17,4.54,1.23,6.55,6.6,5.8c2.76-0.39,6.67,1.67,8.79,3.85
                c3.38,3.49,4.58,8.4,3.5,13.3c-0.27,1.23-2.44,2.74-3.85,2.88c-2.44,0.23-5.01-0.91-7.47-0.75c-3.59,0.24-3.98-2.06-5.09-4.55
                c-3.49-7.88-12.63-9.94-18.9-4.54c-0.81,0.7-1.92,1.18-2.44,2.03c-1.17,1.9-3.02,4.11-2.75,5.92c0.23,1.55,2.95,2.84,4.72,4
                c1.54,1.02,3.3,1.7,4.97,2.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M369.24,839.97c4.66,6.66,9.32,13.32,13.99,19.99c5.34,7.63,10.84,15.16,12.99,24.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M589.01,217.91c-0.83,4.5-1.71,8.99-2.49,13.49c-0.89,5.15-1.68,10.32-2.51,15.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M62.56,263.87c1.5,1,3.15,1.83,4.47,3.03c5.06,4.59,10.31,9.03,14.9,14.06c2.76,3.03,5.25,5.5,9.6,4.9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M306.81,857.45c-1.2-3.57-2.4-7.15-3.6-10.72c0.47-0.35,0.94-0.7,1.4-1.05c3.56,1.42,7.12,2.86,10.69,4.27
                c2.16,0.85,4.28,2.2,6.5,2.41c8.75,0.8,12.39,7.46,17.15,13.45c4.35,5.47,10.15,9.8,15.31,14.63"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M466.64,875.44c-5.59-9.35-10.98-11.38-18.41-6.4c-2.48,1.66-4.19,4.48-6.52,7.07c-3.27-1.11-5.86,1.07-8.06,4.3
                c-0.65,0.96-1.49,2.1-2.49,2.45c-8.69,3.06-8.7,3.02-8.97,11.56"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1058.02,1265.17c-0.38,6.74-3.81,12.14-7.46,17.51c-1.78,2.62,0.32,9.52,2.96,10.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M699.4,1283.15c-1.33-2-2.64-4.01-4-5.99c-7.11-10.33-14.62-19.69-27.31-24.4c-5.85-2.17-11.27-2.79-17.13-3.19
                c-1.71-0.12-3.46-1.38-4.98-2.42c-3.38-2.33-6.17-2.81-9.63,0.41c-2.95,2.75-6.85,4.5-10.39,6.6c-2.31,1.37-4.72,2.57-7.23,3.93
                c0,1.91,0,3.75,0,5.63c-5.4,0.98-10.82,1.96-16.23,2.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1062.02,1308.14c-4.2,0.4-2.56,4.08-2.82,6.02c-1.16,8.33-4.95,15-11.16,20.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M725.37,977.37c-3.23-4.1-8.08-5.77-12.4-4.25c-6.5,2.29-8.62,3.23-8.08,12.24"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M415.69,1054.81c-1.83-0.33-3.8-0.34-5.47-1.05c-9.43-4.04-20,4.71-22.71,13.98c-1.31,4.5-3.69,8.85-7.15,12.67
                c-6.9,7.63-16.64,10.59-24.61,16.37"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M916.67,1350.61c0.17,1.17,0.36,2.33,0.49,3.5c0.19,1.66,1.08,4.29,0.38,4.83c-3.41,2.65-9.37,2.47-12.73-0.48
                c-1.27-1.11-1.74-3.94-1.47-5.83c0.37-2.62,6.42-4.31,10.35-3.59c1.42,0.26,2.98-0.26,4.48-0.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M439.17,471.73c-4.48-0.2-8.97-0.4-13.86-0.61c-2.85-2.04-6.09-4.7-9.66-6.8c-2.54-1.49-3.09-0.94-3.95,2.42"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M592.51,389.78c-1.24-3.87-3.28-5.68-7.52-3.56c-2.29,1.14-4.1,0.24-6.65-1.06c-4.27-2.18-7.64-4.03-9.6-8.75
                c-0.54-1.31-4.69-1.14-7.2-1.62c-2.66-0.51-5.51-0.57-7.95-1.61c-3.42-1.45-6.73-1.22-8.3,1.73c-1.07,2.01,0.1,5.2,0.26,7.87"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M816.27,224.9c1.33,9.33,2.66,18.65,4,27.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M947.14,431.25c-5.04,1.36-10.07,2.71-15.07,4.06c1.08,5.77,2.08,11.1,3.08,16.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M818.77,877.44c-0.5-0.83-1.13-1.61-1.48-2.51c-1.77-4.62-5-6.01-8.98-3.43c-2.16,1.4-3.96,3.36-5.89,5.03
                c-11.05-6.87-18.03-2.78-20.62,11.9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M709.39,1489.01c-0.5-1.5-1.18-2.97-1.45-4.51c-0.25-1.46-0.67-3.84,0.04-4.35c6.07-4.41,11.52-10.12,20.41-8.26
                c5.43,1.13,6.59,5.31,7.79,9.18c1,3.22-2.29,5.12-4.07,7.62c-4.26,6-9.62,4.26-15.22,4.57c-4.23,0.23-8.34,2.75-12.5,4.25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M580.52,1293.15c-1.33,3-3.05,5.89-3.92,9.01c-2.2,7.91,2.37,17.11,8.74,22.18c3.48,2.77,5.17,7.77,7.67,11.77"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1074.5,680.08c2.53,11.85,11.25,17.66,21.48,21.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1047.53,987.36c3.55,2.34,6.17,4.99,7.73,9.41c1.85,5.22,5.43,9.97,10.79,12.49c2.02,0.95,4.51,0.88,6.65,1.25
                c7.56,4.82,15.05,10.65,23.44,14.47c4.1,1.87,8.95,2.54,12.81,5.38c0.64,0.47,1.02,1.31,1.52,1.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1081,998.85c1.33,2.66,2.19,5.74,4.09,7.91c4.18,4.77,8.98,9,13.34,13.62c1.46,1.55,2.38,3.62,3.54,5.45"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M658.44,395.78c-2.33,8.99-4.66,17.99-6.99,26.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M82.04,254.88c3.66,10.99,7.33,21.98,10.99,32.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M296.82,971.37c-1.83-0.83-3.63-2.27-5.5-2.36c-3.14-0.16-6.82-0.35-9.36,1.08c-3.43,1.93-3.82,5.9-2.67,9.79
                c0.32,1.07,0.05,2.32,0.05,3.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M856.23,1337.11c-3.61-4.73-4.56-4.73-10.11,0.59c2.72,2.48,5.65,4.74,8.02,7.48c1.34,1.56,1.76,3.92,2.59,5.92"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M504.6,969.87c-0.67-0.5-1.28-1.1-2.01-1.48c-8.53-4.43-10.31-2.57-12.91,5.11c-2.09,2.24-4.19,4.47-6.78,7.25
                c-4.54-2.23-10.33-3.89-16.26-0.38"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M830.26,1443.54c-4.16,0.33-8.33,0.94-12.49,0.93c-4.5-0.01-8.99-0.59-13.49-0.93"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M416.69,1185.22c7.66,5.66,15.37,11.26,22.95,17.03c2.52,1.92,4.69,4.29,7.02,6.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1004.58,1277.16c3.16,0.67,6.3,1.61,9.5,1.94c5.47,0.56,10.98,0.73,16.48,1.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M664.43,233.89c0.69,8.3,6.67,13.71,11.05,19.94c2.22,3.17,3.81,5.82,1.04,9.61c-1.41,1.94-1.05,4.23,1.87,5.48
                c2.79,1.2,5.36,2.93,8.02,4.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M282.83,831.97c5.33,6.16,10.58,12.4,16.04,18.44c1.49,1.64,3.61,2.71,5.44,4.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1132.94,909.92c0.49,1.67,0.98,3.34,1.69,5.74c-6.19,1.41-12.16,1.03-18.18-0.71c-2.44-0.7-4.98-1.03-7.48-1.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M413.7,155.45c-5.49,5.33-10.84,10.83-16.58,15.88c-1.73,1.52-4.57,1.77-6.9,2.6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M341.27,1163.74c4.39,8.2,12.3,11.08,20.5,13.41c0.84,0.24,1.98-0.59,2.98-0.92"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M360.25,148.95c0.72,5.1-1.74,8.97-4.4,13.05c-3.87,5.92-7.09,12.26-10.58,18.42"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M734.36,885.43c0.22-3.22,0.15-6.25-3.99-7.03c-0.54-0.1-1.39-0.58-1.45-0.98c-1-6.47-5.96-4.84-10.5-4.99
                c0.31-5.08,0.09-10.43-6.06-11.88c-4.61-1.09-8.42,0.77-10.46,5.39"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M626.97,1296.64c-2.88,1.62-2.96,3.49-1.63,6.55c2.3,5.29,3.79,10.94,5.62,16.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M358.25,233.89c4.39,7.14,6.95,15.58,14.49,20.47c1.44,0.93,3.06,2.51,4.47,2.38c11.87-1.05,17.62,8.91,25.98,14.13
                c1.72,1.08,3.34,2.32,5,3.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M371.24,504.7c2.5,4.83,5.12,9.6,7.46,14.51c2.14,4.49,1.53,9.04-0.46,13.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1039.04,1318.13c-5.23,6.26-6.92,13.49-6.03,21.49c0.13,1.13-0.29,2.33-0.46,3.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1048.03,963.38c-1.81,6.76-2.85,13.43-1.23,20.54c0.61,2.67-1.25,6.02-2.4,8.89c-0.27,0.69-2.52,1.3-3.27,0.89
                c-0.75-0.42-0.76-2.17-1.09-3.33"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M456.15,1250.18c-0.33,4.16-0.65,8.33-1,12.49c-0.57,6.68-4.1,11.08-9.49,11.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M994.09,1035.33c-2.98-1.04-7.36,2.22-9.11-2.96c-0.1-0.3-1.95-0.27-2.87,0c-6.51,1.93-13.09,3.7-19.44,6.09
                c-2.38,0.9-3.88,1.72-5.04-1.13"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M126.5,82c-2.35-1.81-4.69-3.62-7.72-5.96c-0.46,2.53-0.24,6.24-1.37,6.72c-7.4,3.13-6.77,10.77-9.89,16.23"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1012.07,1022.34c-2.6,6.38-6.35,11.94-12.03,15.93c-1.92,1.35-4.29,2.05-6.45,3.05"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M775.32,233.89c-6.59,4.03-13.75,6-21.48,6.02c-0.83,0-2.02,0.01-2.44,0.52c-4.83,5.87-11.01,8.42-18.54,7.98
                c-1.15-0.07-2.33,0.3-3.49,0.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M589.01,1410.56c-3.43,3.34-7.86,5.89-3.74,12.15c1.78,2.71,0.91,7.17,1.24,10.83"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M558.54,327.33c3.5,2.06,7.01,4.12,10.75,6.33c-2.56,1.81-4.3,3.05-6.3,4.46c1.24,1.64,2.18,2.9,3.01,4
                c-0.89,0.77-1.7,1.47-2.48,2.14c0.39,4.58,12.52,5.67,22.98,2.56"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M722.37,900.42c-2.19-4.33-6-6.28-10.46-5.36c-5.21,1.08-6.38,5.73-7.02,10.36"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M924.66,1250.18c5.28,4.24,7.73,9.52,7.22,16.5c-0.14,1.89,2.44,3.98,3.77,5.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M932.65,1386.08c1.17-1.33,2.33-2.66,3.5-4c-1.66-1-3.27-2.72-5.01-2.85c-4.18-0.32-8.56-0.47-12.2,2.85
                c2.92,4.8,8.42,2.84,12.21,5.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M805.79,1342.11c0,6.43-2.25,12.05-6.41,17.05c-0.72,0.87-0.41,2.6-0.58,3.93"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M873.71,1472.02c-3.6-4.96-8.84-6.4-14.47-5.82c-2.11,0.22-4.76,2.09-5.75,3.95c-0.68,1.28,0.54,4.34,1.84,5.77
                c4.54,5.05,10.26,4.76,17.89-0.41"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M558.54,500.71c-2.83-2.66-5.41-5.68-8.55-7.91c-4.25-3.01-8.93-5.42-13.42-8.08"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M212.9,382.79c-0.67,4-1.17,8.03-2.07,11.97c-0.4,1.76-1.47,3.43-2.45,5c-1.74,2.78-3.97,5-3.19,9.05c0.58,2.99-0.77,6.24-4.25,8
                c-6.55,3.31-10.05,13.17-8.47,18.92c0.14,0.51,0.95,0.85,1.45,1.26"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M648.95,1250.18c2.24,8.28-1.63,15.54-4.08,22.96c-1.37,4.14-5.07,2.06-7.91,2.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M872.72,1324.62c-0.5,0.5-0.92,1.28-1.51,1.46c-7.54,2.26-11.83,7.27-12.45,14.44c2.73,3.97,5.35,7.78,7.97,11.58"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M516.59,1249.18c5.34,6.54,6.68,13.9,4.5,21.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M791.8,218.41c5.9,2.18,11.88,4.13,13.6,11.47c0.4,1.68,2.22,3.02,3.38,4.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M905.68,1319.13c-2.33,2.33-4.73,4.6-6.98,7.01c-3.52,3.77-4.63,8.16-2.43,12.94c0.62,1.34,2.41,3.27,3.39,3.12
                c7.15-1.09,14.46,3.32,21.62-0.87c1.32-0.77,5.57,0.59,6.09,1.9c1.14,2.86-1.68,4.1-4.21,4.9c-2.95,0.93-5.14,0.7-5.52-3.01
                c-0.05-0.51-0.32-1-0.48-1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M391.22,1021.84c-5.59-2.46-5.59-2.46-10,4.24c-2.66,1.08-5.32,2.17-7.98,3.25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M842.75,874.94c-4.16-5.27-6.23-6.06-13.43-2.87c-4.68,2.07-10.4,3.71-11.04,10.37"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M755.34,264.87c-4.11,4.75-10.36,6.21-15.51,4.59c-7.03-2.2-11.79-7.5-17.45-11.59"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M820.27,207.91c5.93-0.83,9.48,1.24,11.23,7.43c0.72,2.57,4.09,4.4,6.25,6.56"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M412.7,181.43c2.33,2.17,4.94,4.1,6.93,6.55c3.09,3.81,6.45,5.07,11.09,3.03c1.26-0.55,2.96-0.08,4.46-0.08"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M802.79,1398.57c4.33,3.83,8.56,7.79,13.03,11.43c1.87,1.52,4.49,2.13,6.4,3.61c4.3,3.33,8.52,6.78,12.49,10.48
                c4.82,4.49,9.44,9.21,13.99,13.98c2.62,2.74,4.51,5.89,3.03,9.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M347.76,985.36c-8.89-4.25-13.84-1.55-16.48,8.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M717.38,353.31c-0.83,0.33-2.13,0.43-2.43,1.03c-3.86,7.66-11.56,8.95-18.53,11.53c-0.36,0.13-0.36,1.26-0.52,1.93"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M202.92,416.27c2.35,7.1,1.41,10.49-3.98,15.01c-1.62,1.36-3.02,2.98-4.51,4.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M334.78,1200.21c3.66,4.66,7.57,9.16,10.88,14.07c1.49,2.22,1.78,5.25,2.61,7.92"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M885.7,1431.55c0,1.17,0.36,2.49-0.06,3.47c-2.95,6.92-3.84,14-3.15,21.53c0.2,2.21-1.76,4.94-3.38,6.9
                c-1.82,2.21-2.53,4.11-0.9,6.57"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M165.95,656.6c-4.99-0.34-9.98-0.68-14.97-1.02c-0.33,0.37-0.66,0.74-0.99,1.11c1.82,2.63,3.54,5.34,5.49,7.88
                c3.25,4.23,6.64,8.35,9.97,12.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1001.08,850.46c-2.72,4.13-5.43,8.26-8.7,13.23c3.18,1.01,5.94,1.88,8.7,2.76"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M981.1,1474.52c2.84,8.19,18.6,9.94,24.47,7.99c3.42-1.13,3.47-3.69,2.46-6.47c-0.45-1.24-1.3-2.34-1.96-3.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M999.08,1469.02c-2,0-4,0.08-5.99-0.02c-3.82-0.18-7.34,0.12-10.07,3.45c-0.78,0.95-2.58,1.07-3.91,1.57"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M417.69,1466.02c-6.99,0.67-13.99,1.33-20.98,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M902.68,1220.2c-1,0.67-1.93,1.5-3.01,1.97c-5.47,2.39-10.98,4.69-16.47,7.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M994.59,1409.56c-3,0.83-5.95,1.96-9,2.44c-7.91,1.24-13.81,4.98-16.97,12.55"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1090.49,1361.6c-3.24-4.96-7.21-1.94-12.49-0.79c2.17,1.86,3.41,3.69,5.04,4.14c2.72,0.75,5.9,1.12,6.95-2.86"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M454.15,244.89c-3.03,6.36,0.5,11.14,4.66,14.8c2.79,2.46,7.17,3.12,11.31,3.73c-5.61-4.99-9.53-10.6-11.48-17.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M635.46,1277.16c0.33,0.67,0.5,1.72,1.02,1.94c10.05,4.21,7.91,5.1,11.96,13.55"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M246.87,798.5c5.66,6.66,11.32,13.32,16.98,19.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M360.25,155.95c1.83,6,4.23,11.89,5.34,18.01c1.27,7.01,3.63,15.27,7.81,21.72c-0.89,0.58-1.78,1.16-2.67,1.74"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M760.83,250.38c-1.33,0.67-2.6,1.65-4.01,1.95c-10.29,2.18-20.03,7.15-30.96,6.07c-0.31-0.03-0.66,0.31-1,0.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M961.12,1460.53c2.87-0.8,5.74-1.59,9.79-2.72c-3.41,7.32-4.71,7.58-10.79,3.72"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M959.62,1461.53c-6.33-0.33-12.65-0.74-18.98-0.94c-1.49-0.05-3,0.61-4.49,0.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M929.66,1461.03c-3.59-1.18-7.19-2.36-11.09-3.64c1.29-2.17,2.06-3.45,3.17-5.31c-2.26-0.76-4.14-1.47-6.07-2.02
                c-3.15-0.9-6.37-1.55-9.45-2.63c-0.61-0.21-0.71-1.89-1.04-2.88"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M378.73,533.68c-0.5-0.33-1.07-0.6-1.49-1.01c-8.37-8.19-20.24-9.12-30.01-14.42c-0.77-0.42-1.97-0.06-2.97-0.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1093.48,954.39c2,0,4.21-0.56,5.95,0.12c3.16,1.24,5.95,3.42,9.06,4.83c5.41,2.45,7.23,1.53,10.97-4.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M533.57,274.87c-3.82,1.2-7.63,2.4-11.98,3.77c-0.29,6.1,5.88,8.01,8.79,12.38c-6.62,6.38,0.94,8.3,3.69,11.82"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M757.34,188.43c-2.64,7.7-8.67,12.41-15.98,12.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1008.07,1394.57c-4.5-5.03-5.45-5.07-11.04-1.57c-1.35,0.84-3.28,0.76-4.95,1.07c-2.83,0.52-5.66,1-8.49,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M503.6,280.36c4,1.5,7.99,2.99,12.97,4.86c-2.84,3.07-5.44,5.82-7.95,8.65c-0.79,0.89-2.16,2.36-1.89,2.92
                c2.93,6.19-1.68,8.16-6.04,10.55c2.45,5.98,6.22,12.17-1.58,16.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M781.81,200.92c1.41,9.04-3.32,14.69-10.49,18.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M744.35,1450.04c0.33,0.33,0.65,0.69,1,0.99c0.99,0.85,1.99,1.67,2.99,2.5c-1.17,0.83-2.32,2.36-3.5,2.38
                c-5.82,0.09-11.65-0.22-17.48-0.38"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M994.09,1407.57c-4.55-4.74-9.44-7.04-16.06-3.65c-1.98,1.01-4.97-0.16-7.4,0.26c-1.92,0.33-3.68,1.56-5.51,2.4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M912.67,1412.06c-1.57-5.98,2.77-9.23,7.35-13.86c-3.18-1.95-5.75-4.78-8.35-4.82c-3.31-0.04-6.83,1.9-9.81,3.72
                c-0.49,0.3,1.32,4.38,2.07,6.71"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M572.53,902.42c-2.33-1.83-4.54-3.85-7.02-5.45c-3.14-2.02-6.52-1.67-9.43,0.5c-3.29,2.45-3.01,5.93-2.04,9.45
                c0.41,1.48,0.68,2.99,1.01,4.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M710.39,1328.62c-1.89,5-0.25,9.13,2.32,13.59c2.01,3.5,4.97,7.17,3.68,11.89"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M511.09,306.84c-0.58,2.12-1.15,4.23-1.73,6.35c0.24,0.33,0.49,0.65,0.73,0.98c5.49-4.44,10.99,2.28,16.48-0.83"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M420.69,197.92c3.5,1.33,7.12,2.41,10.41,4.15c1.01,0.53,1.27,2.82,1.53,4.35c0.36,2.13,0.38,4.32,0.55,6.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M914.17,1393.08c1.45-3.64-1.04-5.34-3.51-6.98c-1.43-0.94-2.98-1.69-4.48-2.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M898.44,1385.58c-2.06,5.72-6.99,4.5-11.24,4.43c-4-0.07-7.88-2.11-9.24-5.53c-1.43-3.59,2.8-3.91,5.25-4.9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1055.03,883.44c-3.03,6.52-8.2,11.04-13.99,14.99c-0.99,0.68-2,1.33-3,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M461.65,207.91c2.19,6.99,10.3,13.15,17.48,12.88c1.53-0.06,3-1.55,4.5-2.39"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M918.17,1395.57c1.83-0.17,4.63-1.17,5.33-0.36c3.83,4.44,9.29,4.31,14.15,5.86c2.51,0.8,5.09,1.45,7.48,2.53
                c6.8,3.11,9.07,8.9,9.01,15.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M890.2,1404.07c-4-2.66-7.79-5.72-12.06-7.86c-2.95-1.48-7.17,2.9-6.4,6.35c2.59,11.67,5.27,23.33,8.02,34.97
                c0.3,1.25,1.28,2.34,1.95,3.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M281.33,987.36c-2.18-5.41-7.41-6.04-12.01-7.42c-3.24-0.97-7.23,5.51-5.42,10.9c0.87,2.6,2.28,5.01,3.45,7.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M223.39,1006.85c-0.5,0.17-1.05,0.57-1.49,0.46c-3.84-0.9-7.25-0.86-9,3.53c-1.7,4.25-1.76,8.09,2.48,11.03
                c0.35,0.24,0.36,0.97,0.52,1.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M514.59,1186.22c-5.99-0.33-11.99-0.67-17.98-1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1045.53,1183.22c0.67,4,1.7,7.98,1.84,12c0.07,1.97-1.51,3.99-2.34,5.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M395.71,1281.15c-1.17,6-2.33,11.99-3.5,17.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M461.65,240.39c1.17,0.83,2.42,1.56,3.48,2.52c2.88,2.61,5.27,6.23,8.62,7.73c3.64,1.62,5.17,3.64,5.38,7.24"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M741.85,1033.33c-5.49,5.33-10.99,10.66-16.48,15.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1043.54,1435.55c-0.67,4.5-1.26,9.01-2.04,13.48c-0.24,1.38-0.96,2.67-1.46,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M734.86,1479.51c-4.91,5.73-11.56,2.81-17.48,3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M754.34,981.37c-2.2-2.78-5.07-4.81-8.43-2.89c-4.1,2.35-4.76,6.47-3.56,10.88"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M460.15,165.94c-2.46,9.13,1.43,20.05,12.99,23.48"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1052.53,1345.61c-1.83,5.83-3.66,11.66-5.49,17.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M836.75,1078.8c5.83,3.66,11.65,7.33,17.48,10.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M859.73,256.88c2.96-3.57,2.32-9.91,8.81-10.76c3.55,3.9,7.28,8.15,11.22,12.21c0.98,1.01,2.61,1.38,3.94,2.05"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M733.36,320.33c-4,2.83-7.96,5.71-12,8.47c-2.03,1.38-4.21,2.53-7.3,4.37c2.75,1.64,5.28,3.14,7.81,4.65"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M796.3,179.43c-4.83,2.84-8.27,0.46-11.52-2.97c-0.8-0.85-1.98-1.95-2.97-1.93c-6.83,0.15-13.65,0.6-20.48,0.88
                c-0.99,0.04-2-0.3-3-0.47c0.33-1.33,0.34-2.89,1.06-3.95c1.8-2.63,3.93-5.04,5.93-7.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M703.89,1309.63c2,4,4.2,7.91,5.88,12.04c0.56,1.37-0.22,3.28-0.39,4.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M410.7,140.96c-1.9,5.83-5.25,10.52-10.74,13.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M531.57,332.82c-1.43,4.56-2.27,9.48-7.97,11.07c-1.14,0.32-2.02,1.59-3.02,2.42"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M432.18,228.9c-0.47,3.78-2.53,4.36-6.01,3.59c-4.56-1.01-9.2-3.43-13.97-0.59"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M898.19,1344.61c-1.77,1.37-3.53,2.75-5.23,4.07c1.57,6.1,6.15,4.05,9.72,4.43"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M401.71,206.91c2.33,2.66,4.31,5.83,7.13,7.8c1.57,1.09,4.52,0.2,6.86,0.2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M881.21,1452.03c-5.66,0.5-11.32,1-16.98,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M863.72,1453.53c-6.33-1-12.65-2-18.98-3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M421.69,1120.27c-2.5,7.18-1.31,10.88,4.99,15.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M756.34,295.35c-2.16,0.67-4.46,1.07-6.47,2.05c-6.34,3.08-8.04,2.76-13.06-2c-2.74-2.6-5.95-4.71-8.95-7.04"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M772.82,1364.1c1,2.5,1.76,5.13,3.04,7.47c4.15,7.58,8.9,14.72,15.94,20.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M331.78,170.94c2.83,1.83,5.83,3.45,8.46,5.54c5.45,4.35,10.81,8.82,15.98,13.49c3.22,2.91,5.87,6.46,9.09,9.38
                c2.2,1.99,4.94,3.39,7.44,5.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M729.86,310.34c-1.89,4.54-6.23,4.17-9.99,5c-1.68,0.37-3.33,0.82-5,1.24"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M978.6,1453.03c4.78,3.94,10.48,2.91,15.98,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M413.7,139.46c-2.47,0.15-4.94,0.29-8.41,0.5c5.81-5.25,10.36-9.37,14.12-12.78c1.49,1.88,4.3,5.4,7.11,8.92
                c0.9-0.57,1.8-1.14,2.7-1.71c-1.85,2.44-3.69,4.88-6,7.93c1.44,2.17,3.62,5.3,8.45,3.13"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M441.67,228.9c1.34,2.49,2.68,4.99,4.52,8.42c-4.88,1.73-9.61,3.4-15.5,5.48c2.21,1.33,3.97,2.97,6.01,3.47
                c3.22,0.78,6.63,0.79,9.96,1.12"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M464.64,897.43c-6.09-2.34-11.71-2.49-15.98,3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1130.95,907.42c6.33,2.27,12.23,8.02,19.57,2.11c0.83-0.67,2.91,0.22,4.4,0.39"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1060.52,1195.22c-1.83,5.16-3.66,10.33-5.49,15.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M436.17,651.6c2.5,6.5,6.78,12.94,6.98,19.5c0.19,6.31,1.82,12.28,2.01,18.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M710.39,314.84c0.33,0,0.83-0.14,0.97,0.02c4.73,5.57,11.44,6.35,18.01,7.47"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M482.62,202.42c-5.82,4.73-12.91,5.83-19.98,7"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M415.69,182.43c1.5-0.33,3.34-1.39,4.44-0.88c5.49,2.56,11.26,2.38,17.04,2.38"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M697.9,1361.6c-0.55,5.97-2.31,11.34-7.49,14.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M241.87,1124.27c0.66,2.17,1.32,4.33,2.12,6.98c-8.12,0.85-10.35,5.6-8.11,13"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1117.96,714.55c1.66,0.33,3.42,0.44,4.98,1.05c3.39,1.31,6.49,3.71,10.17,0.12c0.69-0.67,3.6,0.6,5.31,1.38
                c3.89,1.77,6.31,1.26,6.42-3.99c6.34-0.38,9.79,8.04,16.57,5.44"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M773.82,191.42c3.71,2.75,7.76,3.85,12.46,4.26c3.45,0.3,6.68,3.06,10.01,4.73"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M662.44,1315.13c2.61,2.75-0.55,4.7-0.83,7.02c-0.76,6.36-2.76,12.33-6.33,17.35c-3.32,4.67-7.33,9.34-13.82,10.11"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M768.32,224.9c-3.94,2.05-8.15,1.92-12.46,2.32c-5.29,0.49-10.35,3.34-15.51,5.18"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M853.24,1450.53c-6.17-5.89-13.63-7.99-21.98-7.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M730.36,249.88c2.18,2.59,4.14,4.98,8.05,2.08c3.22-2.39,4.08,2.32,6.43,2.91"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1045.04,1215.2c-5.01,3.92-4.03,8.9-2.5,13.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M945.64,1274.16c3.66,2.17,7.43,4.18,10.91,6.61c0.85,0.59,0.74,2.55,1.08,3.89"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M884.2,1287.65c-4.66-0.83-9.29-1.99-13.99-2.41c-4.62-0.42-9.32-0.09-13.98-0.09"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M758.34,1282.65c1.66,0.83,4.08,1.23,4.87,2.57c3.62,6.16,6.79,12.58,10.12,18.91"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M494.61,480.72c4.83,4.83,9.66,9.66,14.48,14.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M896.69,1308.64c3.23,7.16,9.81,9.91,16.49,12.47c2.6,0.99,5.32,1.69,7.98,2.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M283.83,425.76c4.79,5.52,8.86,11.35,9.56,18.98c0.16,1.71,1.26,3.34,1.92,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M647.45,1294.15c0.33,1.33,0.22,3.18,1.07,3.92c8.8,7.66,0.84,14.76-0.57,22.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M705.39,906.42c-2.15-1.19-4.3-2.39-7.1-3.95c-1.54,1.66-3.14,3.39-5.02,5.42c-5.04-1.62-10.19-1.62-13.36,4.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M649.45,1278.16c4.69,6.35,10.16,12.26,11.49,20.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M770.82,180.43c-6.38,7.02-14.38,8.92-20.73,5.14c3.05-3.02,6.15-6.08,9.24-9.14"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M519.09,250.88c2.06,8.52-2.41,13.78-8.99,17.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M609.49,1415.06c-3.33,3.83-6.66,7.66-9.99,11.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M211.41,1017.34c-8.99-0.48-10.06,0.73-9.59,10c0.07,1.46-1.23,2.99-1.89,4.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M795.3,1374.09c2.5,4.66,4.99,9.33,7.49,13.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M381.73,1200.21c0.67,1.17,1.1,2.58,2.04,3.45c3.9,3.6,7.95,7.04,11.95,10.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M454.15,185.43c0.92,4.4,2.54,8.05,7.99,8.06c0.83,0,2.16,0.75,2.42,1.46c2.38,6.49,8.79,6.95,13.81,9.46"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M478.63,267.87c-3.47,2.75-0.31,4.81,1.1,6.4c2.1,2.35,4.91,4.07,6.67,5.48c-1.59,5.59-3.61,7.78-7.78,4.61"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M929.66,1462.53c-2.33,1.17-4.92,2.01-6.92,3.59c-2,1.58-3.4,3.91-5.07,5.9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M605.49,950.39c-2.16,0.17-4.49-0.12-6.46,0.59c-4.49,1.63-5.9,6.11-4.03,11.4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M617.98,1258.67c-4.99,0.67-10.14,0.79-14.94,2.14c-5.55,1.56-10.73,4.16-16.58,0.96c-1.28-0.7-3.27-0.1-4.94-0.1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1145.93,853.46c1,0.33,2.78,0.52,2.88,1.02c1.24,6.46,6.88,9.48,10.61,13.97"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M577.02,1433.55c-0.83,1.17-1.69,2.32-2.49,3.5c-1.67,2.45-2.43,4.74,0.47,7.03c2.8,2.21,5.38,1.52,7.43-0.62
                c1.31-1.37,1.85-3.54,2.56-5.42c1.05-2.77,2.11-5.44,5.52-5.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M611.49,1357.6c1.29,5.34,3.95,9.78,8.49,12.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M754.34,202.42c-3.33,4-6.66,7.99-9.99,11.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M829.26,1403.07c2.83,4.5,5.66,8.99,8.49,13.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M937.15,1474.02c-4.33,0.5-8.66,1-12.99,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1058.02,1227.19c-4.48,2.78-4.23,7.73-5.49,11.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M674.42,1488.01c1.51,5.21,7.46,8.19,12.99,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1052.53,1357.1c-0.67,4.33-1.33,8.66-2,12.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M877.71,1381.33c-1.33,0.42-3.17,0.46-3.84,1.36c-0.58,0.78-0.32,3.12,0.43,3.78c2.77,2.42,5.79,4.62,8.93,6.55
                c3.36,2.06,7.21,3.37,10.39,5.64c3.28,2.35,1.59,4.13-1.4,5.46c-1.48,0.66-2.69,1.94-4.02,2.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M882.21,1498.5c-6.35-7.44-10.83-7.44-15.98,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M849.24,1472.02c0.6,6.91,6.11,11.27,11.99,9.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M742.85,852.46c-0.9-5.47,3.21-8.36,6.64-10.79c2.58-1.83,6.12-1.11,8.91,1.72c3.95,4.01,6.92,8.32,7.42,14.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M759.33,277.86c-4.16,0.83-8.33,1.65-12.49,2.5c-2.34,0.48-5.2,0.3-6.9,1.62c-6.69,5.17-13.52,7.71-21.57,3.38"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M713.38,1407.07c2.5,4.33,4.99,8.66,7.49,12.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M880.21,1479.01c4.33,0.67,8.66,1.33,12.99,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M568.53,287.86c1.66,2.17,3.33,4.33,5.16,6.71c-2.75,5.01-8.03,1.41-12.49,4.04c4.23,3,8.28,5.87,12.32,8.73"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M880.71,1360.6c-1.75-4.34-5.01-3.19-7.94-1.88c-0.99,0.44-1.38,2.22-2.05,3.38c1.33,0.67,2.82,2.07,3.96,1.83
                c1.95-0.4,3.7-1.83,5.53-2.83"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M739.35,299.35c-4,2.33-7.99,4.66-11.99,7"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M913.17,1404.57c-7.3-2.63-8.14-2.38-12.17,3.37c-1.37,1.96-4.82,2.46-7.31,3.62"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M455.65,218.41c-4.5,2.83-9.11,5.49-13.45,8.55c-3.85,2.71-8.54,2.76-11.5-0.57c-1.96-2.21-3.92-4.51-5.39-7.05
                c-0.49-0.86,0.54-2.59,0.87-3.92"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M601,343.32c-3.76,4.02-8.73,3.33-14.35,5.05c1.51-4.28,2.44-6.91,3.37-9.55"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M779.31,1469.02c-2.61-4.47-7.49-1.81-10.99-3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1052.03,1337.11c-2.33,3.83-4.66,7.66-6.99,11.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M209.91,399.28c1.95,5.91,1.35,7.49-4,10.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M765.83,197.42c-5.04,5.8-7.48,6.25-16.48,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M574.53,1274.91c0.67,1.08,2.11,2.4,1.86,3.21c-1.01,3.27-2.53,6.37-3.86,9.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M403.71,183.43c-4.8,2.06-9.6,4.12-15.69,6.73c3.23,1.73,5.84,3.12,8.45,4.52"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M450.66,193.92c-2.05,3.89-4.1,7.77-6.16,11.66c-0.45-0.22-0.89-0.45-1.34-0.67c0,0.17-0.07,0.41,0.01,0.49
                c1.15,1.02,2.32,2.01,3.49,3.01"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M973.86,1416.56c-3.74-4.87-6.7-10.92-14.23-10.41c-2.37,0.16-4.67,1.25-7,1.92"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M513.59,295.85c0.81,7.58,2.1,8.8,8.99,8.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M734.36,330.83c-7.66,5.16-15.22,10.47-23.03,15.41c-2.73,1.72-6.04,2.52-9.14,3.76c1.33,6.67,7.89,2.66,10.69,5.81"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M601.5,1297.14c-0.13-2.02-0.25-4.04-0.41-6.63c-2.68,1.07-4.63,1.85-6.58,2.63"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M740.35,233.39c2.37,4.35,6.54,5.66,10.99,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M507.6,268.37c2.97,1.06,5.95,2.13,9.95,3.56c-2.78,2.78-5.37,5.36-7.95,7.94c-0.5,0.5-1,1-1.5,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M431.18,204.42c-4,1.5-7.99,3-11.99,4.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M420.69,153.95c0.05,3.89,2.62,5.97,5.45,8.05c1.88,1.38,3.37,3.28,5.03,4.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M758.34,154.95c1.54,5.93,5.66,8.61,9.66,6.66c3.23,1.85,6.28,3.59,9.32,5.33c2.33,1.33,4.87,2.41,6.94,4.06
                c2.63,2.1,5.12,2.7,8.04,0.93"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M409.7,202.92c2.66,4.33,5.33,8.66,7.99,12.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M569.53,378.29c-3.33,0.33-6.8,0.21-9.94,1.17c-1.94,0.59-3.38,2.83-5.05,4.33"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1035.05,1296.14c1.83,2.5,3.7,4.97,5.48,7.5c1.68,2.39-0.57,6.17-6.48,9.27c1.65-4.05,2.82-6.91,3.99-9.78"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M680.42,380.29c3.5-3.5,6.69-7.41,10.62-10.32c1.9-1.41,4.99-2.51,7.94-0.29c1.52,1.14,3.92,1.55,5.91,1.5
                c2.57-0.05,4.06,0.89,5,3.11"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M887.7,1379.09c3.91,1.67,7.83,3.33,11.74,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M565.54,309.34c1.84,1.25,3.67,2.5,5.51,3.75c-0.08,0.54-0.17,1.08-0.25,1.62c-2.71,0.82-5.43,1.63-9.65,2.9
                c3.09,1.85,5.49,3.29,7.89,4.73"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M628.47,1323.62c1.7,0.56,3.4,1.12,6.28,2.07c-2.66,3.99-4.97,7.45-7.28,10.92"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M267.85,287.86c5.16,2.5,10.32,5,15.48,7.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M932.15,1387.08c-1.36,1.15-2.72,2.29-4.11,3.47c5.15,4.42,7.78,4.66,11.86,1.32c-2.31-1.47-4.52-2.88-6.74-4.29"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M478.63,221.4c-3,2-5.99,4-8.99,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M911.18,1491.51c-1.83,1.17-3.61,2.43-5.51,3.47c-1.76,0.96-3.65,1.69-5.48,2.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M483.62,234.39c-3.66,2-7.33,4-10.99,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1033.05,1017.34c-3.11,1.46-5.34,4.18-9.47,4.37c-2.26,0.1-4.53,3.81-6.46,6.17c-2.32,2.83-3.91,1.79-5.54-0.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1146.93,844.96c3.66,0.67,7.33,1.33,10.99,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M398.71,153.95c3.37,4,8.3,2.43,12.49,3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M789.3,1371.09c2.16,3.66,4.33,7.33,6.49,10.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M620.48,1264.67c3.5,0.83,6.99,1.67,10.49,2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M760.33,144.46c2.83,0.5,5.76,0.7,8.45,1.62c1.4,0.48,3.02,2.07,3.31,3.42c0.19,0.91-1.56,2.64-2.81,3.38
                c-3.04,1.8-6.28,3.23-9.45,4.82"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M391.72,1300.14c-0.17,3.66-0.33,7.33-0.5,10.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1092.99,258.88c-4.84,0.6-7.55,3.58-8.99,7.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M507.6,767.52c0,3.66,0,7.33,0,10.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M594.51,292.85c-1.83,2-3.66,4-5.03,5.49c1.75,5.05,3.39,9.77,5.03,14.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M714.88,1416.56c1.75,3.66,3.5,7.33,5.24,10.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M896.19,1340.61c-3.5,0.33-6.99,0.67-10.49,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M869.72,1493.5c-5.38-2.05-6.43-1.25-6.49,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M661.44,1305.14c0.77,3.48,4.51,6.39,2,10.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M459.15,174.94c-1,1.33-2.05,2.63-2.99,4c-2.74,3.99-5.43,8.01-8.47,12.5c0.76,0.28,2.36,0.88,3.96,1.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M776.32,176.43c-1.49,3.66,1.82,4.93,3.51,6.99c1.27,1.55,2.65,3.01,3.98,4.51"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M580.77,269.37c-2.87,2.55-5.73,5.1-8.81,7.83c0.12,0.31,0.59,1.48,1.07,2.66"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M521.58,266.37c2.5,3,4.99,6,7.49,8.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M804.79,1381.08c1,3.33,2,6.66,3,9.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M599,311.34c-2.06,1.03-4.13,2.06-6.28,3.14c0.57,1.05,1.24,1.71,1.23,2.36c-0.04,2.67-1.2,5.77-0.19,7.87
                c1.02,2.14,4.11,3.29,6.84,5.26c-2.43,1.89-4.16,2.99-5.49,4.44c-0.35,0.38,0.56,1.91,0.9,2.9"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M637.46,1343.11c-0.67,3.33-1.33,6.66-2,9.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M452.4,219.4c-3.08-1.17-6.16-2.33-9.24-3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M459.65,220.9c0.8,5.75,1.58,11.51,8.99,12.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M468.64,226.9c3.33,2.83,6.66,5.66,9.99,8.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M595.51,281.36c-2.5,1.5-5.09,2.87-7.47,4.53c-2.41,1.68-1.63,5.4,1.49,7.44c0.83,0.55,1.82,0.85,2.73,1.27"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M696.9,1349.61c1.24,3.7,2.47,7.4,3.71,11.1c-0.71,0.31-1.42,0.62-2.12,0.93c-0.86-1.51-1.72-3.02-2.58-4.53"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M953.13,708.56c-3.33,0.17-6.66,0.33-9.99,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M653.44,1308.64c1.97,5.96,0.45,10.54-4.99,14.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M733.36,308.84c-2.69,1.2-4.77,0.36-7.08-1.38c-1.52-1.14-3.9-1.12-5.9-1.62"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M633.97,1311.13c2.07,5.28-0.36,8.41-4.99,10.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M607.99,1349.61c5.66,2.66,11.31,5.35,16.99,7.97c0.75,0.35,1.78,0.72,2.46,0.47c4.44-1.62,4.17,1.43,3.98,4.05
                c-0.49,6.93,0.86,8.91,6.98,11.12c1.31,0.47,2.04,2.54,3.04,3.87"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M740.1,281.61c-0.75-0.42-1.62-0.71-2.23-1.27c-2.6-2.41-4.61-5.74-9.03-5.07c-0.63,0.1-1.46-1.06-2.21-1.65
                c2.76-1.88,5.24-3.57,7.71-5.26"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M765.33,1476.02c-1.83,3-3.66,6-5.49,8.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M566.04,1443.54c0.45,3.42,1.47,6.53,5.5,6.95c3.24,0.33,6.01-0.51,6.49-4.45"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M475.63,243.39c-3.16-1.76-6.33-1.77-9.49,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M452.65,225.9c3.09,1.8,3.84,8.05,9.49,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M670.93,386.29c-2.5,3.16-4.99,6.33-7.49,9.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M877.21,1422.06c4.95-0.59,5.87,1.84,4.5,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M396.71,205.41c-0.83,0-1.85-0.31-2.47,0.05c-3.39,1.97-6.69,4.11-10.02,6.19"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M570.03,1266.66c2,2.66,4,5.33,5.99,7.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1028.55,323.33c2.48,2.54,4.02,5.34,2.5,8.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M772.82,1369.09c-3,2.33-5.99,4.66-8.99,7"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M439.17,165.94c-5.29,0.78-10.57,1.57-15.96,2.37c0.1,3.1,4.73,8,8.46,8.13"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M586.51,285.36c-2.42-5.8-8.46-3.35-12.56-5.36c-0.68-0.33-2.79,0.94-3.26,1.94c-0.36,0.76,0.58,2.48,1.39,3.36
                c0.87,0.94,2.26,1.39,3.43,2.06c-0.31,0.47-0.62,0.95-0.93,1.42c-1.35,0.19-2.71,0.38-4.06,0.58"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M744.85,1387.58c-1-0.17-2.18-0.76-2.96-0.42c-3.08,1.31-6.03,2.92-9.02,4.42"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M957.13,1285.65c-3.59,1.69-5.21,4.13-3,7.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M682.41,1486.51c0.53,4.11,3.27,5.83,6.99,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M570.53,322.83c-2.83,1.83-5.66,3.66-8.49,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M872.22,1483.01c-3-0.5-5.99-1-8.99-1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M399.71,192.92c-3.66,2.83-7.33,5.66-10.99,8.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M519.58,294.85c2.26,3.87,1.21,9.76,6.99,11.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M595.51,1268.66c-5.1-1.11-8.37,2.87-12.5,4.47c-2.11,0.82-4.32,1.36-6.48,2.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M581.02,259.88c-1.26,8.73-0.22,11.77,7.99,14.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M783.81,185.93c2,0,4.11-0.42,5.97,0.1c2.93,0.81,5.69,2.24,8.52,3.4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M772.82,186.43c3.05,3.01,6.33,4.06,10.55,2.14c1.25-0.57,3.27,0.53,4.93,0.86"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M849.24,1420.06c2.66,3.33,5.63,6.48,7.84,10.08c0.89,1.45,0.15,3.91,0.15,5.91"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M768.32,206.91c-2.01,4.56-4.87,7.68-10.5,7.11c-1.25-0.13-3.22,0.86-3.89,1.94c-3.21,5.14-7.76,7.03-13.58,6.44"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M753.34,215.41c-4-0.17-7.99-0.33-11.99-0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M580.52,335.82c-2,2.66-4,5.33-5.99,7.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M774.82,1325.62c3.12,2.5,1.74,5.54,1,8.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M415.69,207.41c2.83,1.83,5.72,3.58,8.48,5.52c3.23,2.27,6.28,3.09,9.56,0.04c0.62-0.58,1.94-0.39,2.94-0.56"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M841.25,1413.06c1.83,2.83,3.66,5.66,5.49,8.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M357.75,175.94c4.33,4.83,5.47,10.33,3.5,16.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M591.51,272.87c-3,2.66-5.99,5.33-8.99,7.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M798.29,1392.08c4.09,2.31,4.27,5.64,2.5,9.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M478.13,186.43c-4.16,3-8.32,6-12.49,8.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M433.67,194.92c-2.83,1.17-5.66,2.33-8.49,3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M419.19,174.94c2.83,0.33,5.66,0.67,8.49,1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M798.29,190.42c-2.83,2-5.66,4-8.49,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M761.83,1377.59c-2.5,1.5-5.2,2.74-7.45,4.55c-4.31,3.48-8.37,7.27-12.53,10.94"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M514.59,321.83c0.06,6.09,1.21,7.18,6.99,7.55c2.68,0.17,5.74,0.27,7.91,1.59c3.83,2.33,4.62,0.41,5.06-2.65
                c0.45-3.15,0.69-6.33,1.03-9.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1063.02,1183.72c-1.48-0.17-2.96-0.33-4.8-0.54c0.3,3.04,0.55,5.53,0.8,8.03"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M606.49,1438.04c-2,0.77-3.46,2.26-6.16,0.23c-2.15-1.62-5.83-1.21-8.82-1.73c-1.67-0.29-3.33-0.66-5-0.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M771.82,132.97c1,3.5,1.93,7.01,3.01,10.49c1.61,5.18,3.08,10.42,5.09,15.45c0.84,2.1,2.89,3.71,4.39,5.54"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M449.16,244.89c-2.82,3.22-4.06,8.2-9.49,8.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M745.85,222.9c2.33,1.67,4.66,3.33,6.99,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M629.97,1289.15c-3.56-3.33-5.54-0.72-7.49,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M721.37,1428.05c-0.67,1-1.76,1.94-1.9,3.01c-0.21,1.61,0.23,3.32,0.4,4.98"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M740.85,1483.51c3.59,3.37-0.02,4.08-2.04,4.9c-1.29,0.52-2.96,0.1-4.46,0.1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M579.52,320.83c0.88,5.48,3.25,7.26,7.99,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M639.46,1394.07c-0.5,2.66-1,5.33-1.5,7.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M388.72,172.94c3,1.83,5.99,3.66,8.99,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M597,1334.12c0.75,3.2,2.47,3.68,5.89,4.44c5.19,1.15,9.72,5.28,14.38,8.06c3.34-1.9,6.59-3.58,9.65-5.57
                c1.23-0.8,2.04-2.27,3.04-3.44"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M600.5,1275.66c-6.74-2.08-10.54,3.43-15.48,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M771.32,136.46c-2.33,3.16-4.66,6.33-6.99,9.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M579.02,323.33c-2.5,1.17-4.99,2.33-7.49,3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1064.02,1146.25c-2.33,1.83-4.66,3.66-6.99,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M822.77,1399.57c1.33,2.5,2.66,5,4,7.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M896.69,1288.65c2.5,2,4.99,4,7.49,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M355.76,1075.3c-5.66-0.67-8.34,3.55-11.54,7.46c2.75-0.03,5.54,1.85,8.04-0.96"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M416.69,353.31c1.66,2.17,3.33,4.33,4.99,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M781.31,167.44c1.66-2.15,3.36-3.77,6.53-2.6c1.27,0.47,2.96-0.23,4.46-0.4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M889.7,1463.53c-2.16-2.17-4.33-4.33-6.49-6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M767.33,211.41c1.5,2.5,3,5,4.5,7.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M775.32,1320.13c1.5,2.33,3,4.66,4.5,6.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M886.2,1415.56c0.67,3.83,2.17,7.75,1.76,11.47c-0.4,3.7-4.2,5.22-7.76,6.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M537.57,303.35c-1.33,0.33-3.63,0.37-3.83,1.05c-1.09,3.54-5.4,6.6-2.14,10.92c0.84,1.12,1.97,2.02,2.97,3.02"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M621.48,1366.09c1.83,2.17,3.66,4.33,5.49,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M935.65,1462.03c2,1.58,4,3.16,5.99,4.75"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M873.71,1406.07c4.16,0.33,8.37,0.41,12.47,1.11c1.95,0.34,3.68,1.88,5.51,2.88"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M428.68,146.96c-0.82,4.27-2.63,7.83-6.99,9.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M572.03,299.85c1.44,3.07,3,4.6,7.07,3.22c3.29-1.11,7.25-0.23,10.91-0.23"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M520.08,256.88c2.33,2,4.66,4,6.99,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1065.51,1172.23c-0.17,2.33-0.33,4.66-0.5,7"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M830.26,1442.54c-2.33-0.67-4.66-1.33-6.99-2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1055.52,1371.09c-0.17,2.33-0.33,4.66-0.5,6.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1062.52,1157.74c-1.67,2.17-3.33,4.33-4.99,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M576.53,304.34c0,5.08-5.07,4.07-7.49,6.25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M896.19,1389.58c1.5,2,3,4,4.5,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M611.99,1430.05c0.17,2.17,0.33,4.33,0.5,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M634.96,1373.09c-2.27,4.09,2.75,5.75,3,8.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M758.83,1278.66c-2.16-0.83-4.33-1.67-6.49-2.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M961.12,1211.2c-1.33,3.33-2.66,6.66-4,9.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M601,1348.11c2.16,0,4.33,0,6.49,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M804.79,186.93c-1.83,1-3.66,2-5.49,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M608.99,1410.06c0.83,3.66,1.66,7.33,2.5,10.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1055.52,1250.18c-0.33,2-0.67,4-1,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M900.19,1344.61c1.5,1.83,3,3.66,4.5,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M505.1,331.33c2.49,3.99,5.14,7.76,10.49,7.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M749.84,1379.59c-0.5,2-1,4-1.5,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M436.17,682.58c0.7,4.58,5.26,4.74,7.99,6.99c0.82,0.68,1.66,1.33,2.5,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M713.88,1477.02c1,3.33,2,6.66,3,9.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M911.67,1473.52c-1.33,3.33-2.66,6.66-4,9.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M894.19,1404.07c0.61,5-1.24,8.82-5.49,11.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M627.97,1429.55c-2.83,1.67-5.61,3.42-8.52,4.93c-0.79,0.41-1.97,0.06-2.96,0.06"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M641.46,1346.11c0.53,3.64-1.08,5.9-4.5,7"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M633.47,399.78c-0.83,2-1.66,4-2.5,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M639.46,1367.09c0.17,2,0.33,4,0.5,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M417.19,171.94c1.33,3.16,2.66,6.33,4,9.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M525.58,318.83c3.16,0.67,6.33,1.33,9.49,2"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M570.53,1438.54c-2.58,0.67-2.75,6.17-6.99,3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M601.5,337.32c-1,0.17-2.05,0.19-2.98,0.55c-0.6,0.23-1.41,0.95-1.41,1.45c0,0.5,0.81,1.29,1.4,1.45c2.3,0.62,4.65,1.05,6.98,1.55
                "/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1052.03,1294.15c0.83,2.5,1.66,5,2.5,7.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M786.31,156.45c-1.83,0.17-3.66,0.33-5.49,0.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M440.67,659.09c1.83,1,3.66,2,5.49,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M459.65,186.93c0.33,1.83,0.67,3.66,1,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M584.52,320.83c2.21,3.08,3.22,8.32,8.99,5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M855.73,1327.62c1.83,1,3.66,2,5.49,3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M765.33,1484.01c0.83,1.83,1.66,3.66,2.5,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M634.46,1294.15c-1.33-1.33-2.66-2.66-4-4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M527.58,1196.71c1.83,1.17,3.66,2.33,5.49,3.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M627.47,1404.07c-1.17,1.83-2.33,3.66-3.5,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M650.95,181.93c-1.33,1.5-2.66,3-4,4.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1058.02,1217.2c-1.5,1.5-3,3-4.5,4.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M369.74,196.92c1.17,2.33,2.33,4.66,3.5,7"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M639.96,1387.58c0,1.83,0,3.66,0,5.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M645.95,1272.66c2.5,1.5,4.99,3,7.49,4.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M455.65,1247.18c2,2.17,4,4.33,5.99,6.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M711.38,1352.1c3.33,1.5,6.66,3,9.99,4.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1057.02,1210.2c-1.17,2.33-2.33,4.66-3.5,6.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M597.5,1342.11c1.08,2.5,2.16,5,3.25,7.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M530.57,262.87c-2.5,0.83-4.97,1.75-7.51,2.44c-0.73,0.2-1.65-0.28-2.48-0.44"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M888.7,1410.06c-1.09,1.6-2.18,3.2-3.95,5.79c-1.57-2.87-3.06-5.58-4.54-8.28"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M616.98,1429.05c0.17,3.81-0.9,6.56-4.96,8.09c-4.59,1.73-5.85,1.05-5.53-4.1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M484.62,257.38c-3.33,0.5-6.66,1-9.99,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M337.77,1219.2c1.33,1.17,2.93,2.14,3.94,3.54c1.56,2.17,2.72,4.62,4.06,6.95"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M447.66,207.91c-1.01,6.72-1.78,7.49-6.99,7"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M585.52,352.81c0,1.5,0,3,0,4.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M387.72,200.92c1.5,1.58,3,3.16,4.5,4.75"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M763.83,1371.09c-1,3.66-2,7.33-3,10.99"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M634.46,1270.66c0.26,2.1,5.65,5.05-1,6"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M829.26,1413.06c0.83,2.5,1.66,5,2.5,7.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M776.82,230.9c-0.17,2.5-0.33,5-0.5,7.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1062.52,1136.76c-1.83,2.83-3.66,5.66-5.49,8.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M476.63,260.38c0.44,3.75,0.71,7.62,5.99,7.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M741.85,849.46c-1.67-3.53-4.31-0.8-6.49-1"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M365.75,1444.04c-1.33,0-2.66,0-4,0"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M706.89,1324.12c1.5,1.33,3,2.66,4.5,4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M400.46,184.43c-1.91-1-3.83-2-5.74-3"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M594.51,1438.04c0.53-3.12,0.76-6.66,5.49-4"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M899.19,1342.61c0,0.5,0,1,0,1.5"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M1057.52,1207.71c0.17,0.33,0.39,0.65,0.47,1.01c0.03,0.12-0.3,0.33-0.47,0.49"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M626.72,1295.14c0.58,0.42,1.17,0.83,1.75,1.25"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M937.15,1301.14c-3.54,2.09-2.27,4.61-0.47,6.98c2.45,3.23,5.85,2.39,8.93,1.4c1.16-0.37,1.91-2.04,2.95-3.25
                c-2.76-4.23-6.46-5.46-10.91-5.13"/>
            <path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                M943.14,1333.12c-7.61,3.27-10.05,6.16-8.87,11.46c0.37,1.68,2.25,4.34,3.37,4.3c5.51-0.18,11.04-0.86,16.48-1.85
                c4.65-0.85,4.16-3.91,1.98-6.9c-3.05-4.18-7.04-6.93-12.46-7.02"/>
        
            </g>
            </svg>
        </div>
        )
    }